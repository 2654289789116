export const customerDetails = [
	{
		customer_id: 0,
		customer_name: 'Mitchell Hutchinson',
		phone_number: '+44(0)117 496 0230',
		email: 'jilltucker@example.org',
		location: { lat: 51.651, lng: -3.283 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 1,
		num_previous_breakdowns: 4,
		experience_score: 9,
		preferred_contact_method: 'post',
		create_date: '07/03/2023',
	},
	{
		customer_id: 1,
		customer_name: 'Mr Darren Wells',
		phone_number: '+4420 7496 0821',
		email: 'patelanne@example.com',
		location: { lat: 53.35, lng: -2.216 },
		customer_since: 2023,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 3,
		experience_score: 8,
		preferred_contact_method: 'post',
		create_date: '12/05/2023',
	},
	{
		customer_id: 2,
		customer_name: 'Nicholas Wells',
		phone_number: '+44(0)131 496 0064',
		email: 'callum29@example.com',
		location: { lat: 51.4828, lng: -0.195 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 5,
		num_previous_breakdowns: 1,
		experience_score: 6,
		preferred_contact_method: 'post',
		create_date: '13/06/2023',
	},
	{
		customer_id: 3,
		customer_name: 'Bethany Mann',
		phone_number: '(0306)9990890',
		email: 'butchercarolyn@example.org',
		location: { lat: 53.592, lng: -2.54 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 16,
		num_previous_breakdowns: 2,
		experience_score: 1,
		preferred_contact_method: 'email',
		create_date: '07/06/2023',
	},
	{
		customer_id: 4,
		customer_name: 'Nicola Davison',
		phone_number: '4.42075E+11',
		email: 'dyerbrandon@example.net',
		location: { lat: 51.743, lng: -3.378 },
		customer_since: 2011,
		'net_promoter_score?': '?',
		num_garage_visits: 20,
		num_previous_breakdowns: 3,
		experience_score: 1,
		preferred_contact_method: 'post',
		create_date: '28/01/2023',
	},
	{
		customer_id: 5,
		customer_name: 'Charlie Lawson',
		phone_number: '0114 496 0167',
		email: 'griffinkayleigh@example.net',
		location: { lat: 51.3286, lng: -0.5617 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 14,
		num_previous_breakdowns: 5,
		experience_score: 4,
		preferred_contact_method: 'post',
		create_date: '31/01/2023',
	},
	{
		customer_id: 6,
		customer_name: 'Mr Joel Smith',
		phone_number: '0141 4960184',
		email: 'abigail98@example.com',
		location: { lat: 54.5585, lng: -1.3025 },
		customer_since: 2012,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 3,
		experience_score: 7,
		preferred_contact_method: 'email',
		create_date: '04/03/2023',
	},
	{
		customer_id: 7,
		customer_name: 'Joshua Hopkins-Humphreys',
		phone_number: '0161 4960230',
		email: 'leonardelliott@example.net',
		location: { lat: 51.401, lng: -1.323 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 4,
		experience_score: 10,
		preferred_contact_method: 'post',
		create_date: '14/05/2023',
	},
	{
		customer_id: 8,
		customer_name: 'Ben Baxter',
		phone_number: '(0121)4960618',
		email: 'jeffreyreynolds@example.net',
		location: { lat: 54.076, lng: -2.837 },
		customer_since: 2017,
		'net_promoter_score?': '?',
		num_garage_visits: 9,
		num_previous_breakdowns: 0,
		experience_score: 2,
		preferred_contact_method: 'email',
		create_date: '30/03/2023',
	},
	{
		customer_id: 9,
		customer_name: 'Bernard Bell',
		phone_number: '+44(0)118 496 0992',
		email: 'elizabethfreeman@example.com',
		location: { lat: 53.48, lng: -3.05 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 8,
		num_previous_breakdowns: 3,
		experience_score: 6,
		preferred_contact_method: 'phone',
		create_date: '02/05/2023',
	},
	{
		customer_id: 10,
		customer_name: 'Diana Johnson',
		phone_number: '+44(0)1414960189',
		email: 'stoneallan@example.net',
		location: { lat: 51.401, lng: -0.363 },
		customer_since: 2013,
		'net_promoter_score?': '?',
		num_garage_visits: 12,
		num_previous_breakdowns: 0,
		experience_score: 6,
		preferred_contact_method: 'post',
		create_date: '04/05/2023',
	},
	{
		customer_id: 11,
		customer_name: 'Rhys Clark',
		phone_number: '+44115 496 0411',
		email: 'mohamed54@example.org',
		location: { lat: 51.433, lng: -0.933 },
		customer_since: 2023,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 2,
		experience_score: 10,
		preferred_contact_method: 'phone',
		create_date: '24/04/2023',
	},
	{
		customer_id: 12,
		customer_name: 'Anne Sullivan',
		phone_number: '+44161 4960393',
		email: 'wturner@example.net',
		location: { lat: 52.5708, lng: -2.0457 },
		customer_since: 2011,
		'net_promoter_score?': '?',
		num_garage_visits: 20,
		num_previous_breakdowns: 0,
		experience_score: 5,
		preferred_contact_method: 'email',
		create_date: '14/04/2023',
	},
	{
		customer_id: 13,
		customer_name: 'Adam Bennett',
		phone_number: '+44306 9990483',
		email: 'clarkdenis@example.com',
		location: { lat: 53.708, lng: -1.67 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 2,
		num_previous_breakdowns: 3,
		experience_score: 3,
		preferred_contact_method: 'post',
		create_date: '03/06/2023',
	},
	{
		customer_id: 14,
		customer_name: 'Adam Thornton',
		phone_number: '+44131 4960642',
		email: 'jemmaosborne@example.net',
		location: { lat: 54.0489, lng: -2.8014 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 24,
		num_previous_breakdowns: 5,
		experience_score: 9,
		preferred_contact_method: 'email',
		create_date: '16/04/2023',
	},
	{
		customer_id: 15,
		customer_name: 'Dr Russell Pritchard',
		phone_number: '+44(0)113 4960873',
		email: 'simonrichardson@example.com',
		location: { lat: 53.5122, lng: -2.3412 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 13,
		num_previous_breakdowns: 1,
		experience_score: 5,
		preferred_contact_method: 'phone',
		create_date: '22/06/2023',
	},
	{
		customer_id: 16,
		customer_name: 'Norman Allen',
		phone_number: '44292018753',
		email: 'hchadwick@example.com',
		location: { lat: 51.6578, lng: -0.2723 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 13,
		num_previous_breakdowns: 5,
		experience_score: 4,
		preferred_contact_method: 'email',
		create_date: '22/05/2023',
	},
	{
		customer_id: 17,
		customer_name: 'Jack Thompson',
		phone_number: '0113 496 0011',
		email: 'sheila40@example.net',
		location: { lat: 51.38, lng: -2.36 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 20,
		num_previous_breakdowns: 5,
		experience_score: 5,
		preferred_contact_method: 'post',
		create_date: '22/07/2023',
	},
	{
		customer_id: 18,
		customer_name: 'Ms Rosie Jenkins',
		phone_number: '+44(0)808 157 0972',
		email: 'katherine87@example.com',
		location: { lat: 51.459, lng: 0.138 },
		customer_since: 2013,
		'net_promoter_score?': '?',
		num_garage_visits: 21,
		num_previous_breakdowns: 1,
		experience_score: 8,
		preferred_contact_method: 'post',
		create_date: '24/06/2023',
	},
	{
		customer_id: 19,
		customer_name: 'Marc Thomas-Ball',
		phone_number: '+44(0)1144960811',
		email: 'doylenaomi@example.org',
		location: { lat: 53.523, lng: -2.495 },
		customer_since: 2010,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 2,
		experience_score: 10,
		preferred_contact_method: 'phone',
		create_date: '10/06/2023',
	},
	{
		customer_id: 20,
		customer_name: 'Ms Jessica Morris',
		phone_number: '(0118)4960374',
		email: 'katherine11@example.org',
		location: { lat: 51.599, lng: -1.125 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 10,
		num_previous_breakdowns: 3,
		experience_score: 6,
		preferred_contact_method: 'email',
		create_date: '06/03/2023',
	},
	{
		customer_id: 21,
		customer_name: 'Joanna Brown-Warren',
		phone_number: '+44118 4960701',
		email: 'johnsonrhys@example.net',
		location: { lat: 51.6185, lng: -0.2729 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 23,
		num_previous_breakdowns: 2,
		experience_score: 6,
		preferred_contact_method: 'post',
		create_date: '13/03/2023',
	},
	{
		customer_id: 22,
		customer_name: 'Denise White-Hughes',
		phone_number: '(0808) 1570204',
		email: 'lydiadouglas@example.org',
		location: { lat: 51.3652, lng: -0.1676 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 20,
		num_previous_breakdowns: 4,
		experience_score: 3,
		preferred_contact_method: 'phone',
		create_date: '09/01/2023',
	},
	{
		customer_id: 23,
		customer_name: 'Glenn Gibson-Taylor',
		phone_number: '4.41315E+11',
		email: 'shannonnorton@example.net',
		location: { lat: 55.8474, lng: -4.1147 },
		customer_since: 2010,
		'net_promoter_score?': '?',
		num_garage_visits: 2,
		num_previous_breakdowns: 4,
		experience_score: 8,
		preferred_contact_method: 'post',
		create_date: '18/02/2023',
	},
	{
		customer_id: 24,
		customer_name: 'Leanne Simpson',
		phone_number: '0115 496 0002',
		email: 'ewillis@example.net',
		location: { lat: 53.05, lng: -2.142 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 25,
		num_previous_breakdowns: 0,
		experience_score: 7,
		preferred_contact_method: 'post',
		create_date: '02/04/2023',
	},
	{
		customer_id: 25,
		customer_name: 'Shaun Phillips',
		phone_number: '029 2018255',
		email: 'peternicholls@example.com',
		location: { lat: 51.7526, lng: -0.4692 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 6,
		num_previous_breakdowns: 5,
		experience_score: 4,
		preferred_contact_method: 'email',
		create_date: '08/04/2023',
	},
	{
		customer_id: 26,
		customer_name: 'Mrs Barbara Williams',
		phone_number: '(0151) 4960941',
		email: 'nicholsonalexander@example.org',
		location: { lat: 51.4279, lng: -0.1235 },
		customer_since: 2011,
		'net_promoter_score?': '?',
		num_garage_visits: 21,
		num_previous_breakdowns: 2,
		experience_score: 7,
		preferred_contact_method: 'post',
		create_date: '01/02/2023',
	},
	{
		customer_id: 27,
		customer_name: 'Dr Norman Carter',
		phone_number: '+44(0)1314960521',
		email: 'nicole19@example.org',
		location: { lat: 54.5828, lng: -0.9732 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 16,
		num_previous_breakdowns: 1,
		experience_score: 6,
		preferred_contact_method: 'email',
		create_date: '23/03/2023',
	},
	{
		customer_id: 28,
		customer_name: 'Dr Matthew Lewis',
		phone_number: '+44141 496 0674',
		email: 'jane67@example.net',
		location: { lat: 50.412, lng: -5.0757 },
		customer_since: 2010,
		'net_promoter_score?': '?',
		num_garage_visits: 15,
		num_previous_breakdowns: 2,
		experience_score: 6,
		preferred_contact_method: 'post',
		create_date: '06/03/2023',
	},
	{
		customer_id: 29,
		customer_name: 'Charlotte Kemp',
		phone_number: '+44(0)808 157 0676',
		email: 'bwilliams@example.com',
		location: { lat: 51.2226, lng: 1.4006 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 1,
		num_previous_breakdowns: 0,
		experience_score: 7,
		preferred_contact_method: 'phone',
		create_date: '04/06/2023',
	},
	{
		customer_id: 30,
		customer_name: 'Reece Kirk',
		phone_number: '+44(0)28 9018212',
		email: 'wilkinsonjemma@example.net',
		location: { lat: 51.3814, lng: 1.3381 },
		customer_since: 2020,
		'net_promoter_score?': '?',
		num_garage_visits: 7,
		num_previous_breakdowns: 3,
		experience_score: 5,
		preferred_contact_method: 'post',
		create_date: '10/02/2023',
	},
	{
		customer_id: 31,
		customer_name: "Mr Nicholas O'Connor",
		phone_number: '+44(0)29 2018021',
		email: 'daveyfiona@example.org',
		location: { lat: 55.0318, lng: -1.5557 },
		customer_since: 2013,
		'net_promoter_score?': '?',
		num_garage_visits: 22,
		num_previous_breakdowns: 5,
		experience_score: 8,
		preferred_contact_method: 'email',
		create_date: '07/02/2023',
	},
	{
		customer_id: 32,
		customer_name: 'Mr Matthew Smith',
		phone_number: '+44(0)141 4960979',
		email: 'evansstephanie@example.org',
		location: { lat: 55.737, lng: -3.972 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 5,
		experience_score: 3,
		preferred_contact_method: 'email',
		create_date: '25/01/2023',
	},
	{
		customer_id: 33,
		customer_name: 'Peter Bell',
		phone_number: '(029)2018729',
		email: 'joyce49@example.org',
		location: { lat: 51.37, lng: 0.52 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 10,
		num_previous_breakdowns: 2,
		experience_score: 10,
		preferred_contact_method: 'phone',
		create_date: '03/07/2023',
	},
	{
		customer_id: 34,
		customer_name: 'Nigel Robertson-West',
		phone_number: '0141 4960590',
		email: 'jennifer66@example.net',
		location: { lat: 53.6027, lng: -1.7921 },
		customer_since: 2015,
		'net_promoter_score?': '?',
		num_garage_visits: 7,
		num_previous_breakdowns: 0,
		experience_score: 3,
		preferred_contact_method: 'post',
		create_date: '21/01/2023',
	},
	{
		customer_id: 35,
		customer_name: 'Joe Woods',
		phone_number: '(0909) 8790277',
		email: 'joneseleanor@example.org',
		location: { lat: 53.816, lng: -1.602 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 2,
		num_previous_breakdowns: 4,
		experience_score: 10,
		preferred_contact_method: 'post',
		create_date: '11/01/2023',
	},
	{
		customer_id: 36,
		customer_name: 'Bethan Holland',
		phone_number: '(0115) 4960670',
		email: 'deborahroberts@example.net',
		location: { lat: 52.0626, lng: -0.8102 },
		customer_since: 2015,
		'net_promoter_score?': '?',
		num_garage_visits: 20,
		num_previous_breakdowns: 1,
		experience_score: 4,
		preferred_contact_method: 'phone',
		create_date: '04/06/2023',
	},
	{
		customer_id: 37,
		customer_name: 'Dr Hilary Williams',
		phone_number: '(0114) 4960450',
		email: 'ashleigh24@example.net',
		location: { lat: 51.5121, lng: -0.3779 },
		customer_since: 2011,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 3,
		experience_score: 2,
		preferred_contact_method: 'post',
		create_date: '23/07/2023',
	},
	{
		customer_id: 38,
		customer_name: 'Dr Billy Fisher',
		phone_number: '01632 960 899',
		email: 'mark88@example.org',
		location: { lat: 54.881, lng: -1.534 },
		customer_since: 2012,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 2,
		experience_score: 5,
		preferred_contact_method: 'post',
		create_date: '02/02/2023',
	},
	{
		customer_id: 39,
		customer_name: 'Ms Danielle Morris',
		phone_number: '+44(0)117 496 0588',
		email: 'thomasmarion@example.net',
		location: { lat: 51.57, lng: -0.78 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 23,
		num_previous_breakdowns: 2,
		experience_score: 4,
		preferred_contact_method: 'email',
		create_date: '10/02/2023',
	},
	{
		customer_id: 40,
		customer_name: 'Jordan Kirby-Cooper',
		phone_number: '44292018916',
		email: 'fpowell@example.com',
		location: { lat: 51.5323, lng: 0.0554 },
		customer_since: 2020,
		'net_promoter_score?': '?',
		num_garage_visits: 8,
		num_previous_breakdowns: 1,
		experience_score: 9,
		preferred_contact_method: 'post',
		create_date: '12/04/2023',
	},
	{
		customer_id: 41,
		customer_name: 'Jemma Porter',
		phone_number: '029 2018375',
		email: 'leighkelly@example.net',
		location: { lat: 53.0056, lng: -1.4254 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 20,
		num_previous_breakdowns: 4,
		experience_score: 7,
		preferred_contact_method: 'post',
		create_date: '02/03/2023',
	},
	{
		customer_id: 42,
		customer_name: 'Natalie Miah',
		phone_number: '029 2018628',
		email: 'shaunakhtar@example.net',
		location: { lat: 54.5964, lng: -5.93 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 6,
		num_previous_breakdowns: 2,
		experience_score: 7,
		preferred_contact_method: 'phone',
		create_date: '02/02/2023',
	},
	{
		customer_id: 43,
		customer_name: 'Timothy Phillips',
		phone_number: '028 9018 0002',
		email: 'gordon12@example.net',
		location: { lat: 51.36, lng: 1.103 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 22,
		num_previous_breakdowns: 1,
		experience_score: 4,
		preferred_contact_method: 'phone',
		create_date: '01/06/2023',
	},
	{
		customer_id: 44,
		customer_name: 'Mrs Bethan Bailey',
		phone_number: '+44(0)161 496 0772',
		email: 'bartongeorgina@example.com',
		location: { lat: 51.128, lng: -2.993 },
		customer_since: 2023,
		'net_promoter_score?': '?',
		num_garage_visits: 2,
		num_previous_breakdowns: 5,
		experience_score: 3,
		preferred_contact_method: 'email',
		create_date: '01/05/2023',
	},
	{
		customer_id: 45,
		customer_name: 'Josephine Lord',
		phone_number: '+44114 4960753',
		email: 'harrisjoseph@example.com',
		location: { lat: 51.8904, lng: -0.1765 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 12,
		num_previous_breakdowns: 5,
		experience_score: 3,
		preferred_contact_method: 'post',
		create_date: '31/03/2023',
	},
	{
		customer_id: 46,
		customer_name: 'Mr Nathan Smith',
		phone_number: '0131 496 0408',
		email: 'chapmankimberley@example.com',
		location: { lat: 52.2577, lng: -0.8222 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 8,
		num_previous_breakdowns: 1,
		experience_score: 8,
		preferred_contact_method: 'post',
		create_date: '07/04/2023',
	},
	{
		customer_id: 47,
		customer_name: 'Dr Owen Graham',
		phone_number: '+44(0)117 496 0866',
		email: 'susan23@example.org',
		location: { lat: 56.07, lng: -3.17 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 21,
		num_previous_breakdowns: 3,
		experience_score: 1,
		preferred_contact_method: 'post',
		create_date: '21/04/2023',
	},
	{
		customer_id: 48,
		customer_name: 'Dr Melanie Nicholls',
		phone_number: '(0808)1570317',
		email: 'jasmine13@example.com',
		location: { lat: 51.8644, lng: -2.2444 },
		customer_since: 2015,
		'net_promoter_score?': '?',
		num_garage_visits: 1,
		num_previous_breakdowns: 5,
		experience_score: 3,
		preferred_contact_method: 'email',
		create_date: '22/06/2023',
	},
	{
		customer_id: 49,
		customer_name: 'Joan Atkins',
		phone_number: '(0151)4960971',
		email: 'nprice@example.net',
		location: { lat: 51.2944, lng: -1.1098 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 6,
		num_previous_breakdowns: 1,
		experience_score: 7,
		preferred_contact_method: 'email',
		create_date: '19/02/2023',
	},
	{
		customer_id: 50,
		customer_name: 'Graeme Simmons',
		phone_number: '(0909) 879 0775',
		email: 'annette92@example.net',
		location: { lat: 53.4985, lng: -1.4406 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 1,
		experience_score: 8,
		preferred_contact_method: 'phone',
		create_date: '20/02/2023',
	},
	{
		customer_id: 51,
		customer_name: 'Sarah Lewis',
		phone_number: '+44115 496 0701',
		email: 'guy62@example.org',
		location: { lat: 53.478, lng: -1.588 },
		customer_since: 2020,
		'net_promoter_score?': '?',
		num_garage_visits: 9,
		num_previous_breakdowns: 5,
		experience_score: 3,
		preferred_contact_method: 'email',
		create_date: '24/07/2023',
	},
	{
		customer_id: 52,
		customer_name: 'Christian Moore',
		phone_number: '+44141 4960608',
		email: 'louis36@example.com',
		location: { lat: 50.213, lng: -5.3 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 25,
		num_previous_breakdowns: 1,
		experience_score: 9,
		preferred_contact_method: 'post',
		create_date: '08/03/2023',
	},
	{
		customer_id: 53,
		customer_name: 'Leon Bird',
		phone_number: '4.41915E+11',
		email: 'aaron57@example.com',
		location: { lat: 51.947, lng: -0.283 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 13,
		num_previous_breakdowns: 2,
		experience_score: 9,
		preferred_contact_method: 'post',
		create_date: '12/01/2023',
	},
	{
		customer_id: 54,
		customer_name: 'Alexander Jones',
		phone_number: '289018488',
		email: 'tevans@example.com',
		location: { lat: 52.3594, lng: -1.29 },
		customer_since: 2013,
		'net_promoter_score?': '?',
		num_garage_visits: 12,
		num_previous_breakdowns: 1,
		experience_score: 6,
		preferred_contact_method: 'email',
		create_date: '14/01/2023',
	},
	{
		customer_id: 55,
		customer_name: 'Miss Frances Rose',
		phone_number: '(0117) 4960755',
		email: 'jarvislynda@example.org',
		location: { lat: 50.7876, lng: -0.977 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 22,
		num_previous_breakdowns: 1,
		experience_score: 8,
		preferred_contact_method: 'post',
		create_date: '19/05/2023',
	},
	{
		customer_id: 56,
		customer_name: 'Danielle Lynch-Watson',
		phone_number: '+44808 1570037',
		email: 'bradley83@example.com',
		location: { lat: 50.8094, lng: -0.5409 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 13,
		num_previous_breakdowns: 1,
		experience_score: 5,
		preferred_contact_method: 'post',
		create_date: '07/05/2023',
	},
	{
		customer_id: 57,
		customer_name: 'Kieran Barrett',
		phone_number: '0131 496 0899',
		email: 'woodjack@example.net',
		location: { lat: 51.5423, lng: -0.0026 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 13,
		num_previous_breakdowns: 1,
		experience_score: 5,
		preferred_contact_method: 'email',
		create_date: '04/07/2023',
	},
	{
		customer_id: 58,
		customer_name: 'Trevor Lyons',
		phone_number: '0161 4960571',
		email: 'cartwrightrosemary@example.com',
		location: { lat: 51.6814, lng: -3.3792 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 19,
		num_previous_breakdowns: 4,
		experience_score: 9,
		preferred_contact_method: 'email',
		create_date: '01/07/2023',
	},
	{
		customer_id: 59,
		customer_name: 'Dr Mohamed Newton',
		phone_number: '0115 496 0305',
		email: 'alanfisher@example.net',
		location: { lat: 53.4794, lng: -2.2453 },
		customer_since: 2017,
		'net_promoter_score?': '?',
		num_garage_visits: 7,
		num_previous_breakdowns: 2,
		experience_score: 10,
		preferred_contact_method: 'post',
		create_date: '29/07/2023',
	},
	{
		customer_id: 60,
		customer_name: 'Laura Lynch',
		phone_number: '+44131 496 0488',
		email: 'jlambert@example.com',
		location: { lat: 53.108, lng: -2.0234 },
		customer_since: 2012,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 1,
		experience_score: 1,
		preferred_contact_method: 'post',
		create_date: '16/04/2023',
	},
	{
		customer_id: 61,
		customer_name: 'Danielle Walker-Gibbons',
		phone_number: '+44(0)1314960102',
		email: 'xwilson@example.net',
		location: { lat: 54.995, lng: -1.43 },
		customer_since: 2015,
		'net_promoter_score?': '?',
		num_garage_visits: 1,
		num_previous_breakdowns: 0,
		experience_score: 7,
		preferred_contact_method: 'post',
		create_date: '22/06/2023',
	},
	{
		customer_id: 62,
		customer_name: 'Jack Jackson-Turner',
		phone_number: '+44161 496 0261',
		email: 'aaron78@example.net',
		location: { lat: 51.6034, lng: -0.478 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 24,
		num_previous_breakdowns: 0,
		experience_score: 2,
		preferred_contact_method: 'phone',
		create_date: '22/02/2023',
	},
	{
		customer_id: 63,
		customer_name: 'Mr Ronald Wood',
		phone_number: '(0118)4960933',
		email: 'froststephanie@example.org',
		location: { lat: 53.68, lng: -1.58 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 23,
		num_previous_breakdowns: 0,
		experience_score: 3,
		preferred_contact_method: 'phone',
		create_date: '29/07/2023',
	},
	{
		customer_id: 64,
		customer_name: 'Amelia Wilkinson',
		phone_number: '+44115 496 0637',
		email: 'jaynerichardson@example.net',
		location: { lat: 51.5043, lng: -0.4646 },
		customer_since: 2017,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 5,
		experience_score: 1,
		preferred_contact_method: 'phone',
		create_date: '10/03/2023',
	},
	{
		customer_id: 65,
		customer_name: 'Dr Albert Evans',
		phone_number: '+44808 1570351',
		email: 'katiewalsh@example.net',
		location: { lat: 53.833, lng: -1.777 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 5,
		experience_score: 4,
		preferred_contact_method: 'email',
		create_date: '27/06/2023',
	},
	{
		customer_id: 66,
		customer_name: 'Chloe Gray-Hawkins',
		phone_number: '0306 9990609',
		email: 'pamelasingh@example.org',
		location: { lat: 51.6223, lng: -3.4512 },
		customer_since: 2014,
		'net_promoter_score?': '?',
		num_garage_visits: 18,
		num_previous_breakdowns: 3,
		experience_score: 5,
		preferred_contact_method: 'phone',
		create_date: '18/06/2023',
	},
	{
		customer_id: 67,
		customer_name: 'Stephanie Gibson',
		phone_number: '+44115 4960779',
		email: 'hollybarnes@example.com',
		location: { lat: 50.9679, lng: -1.3278 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 10,
		num_previous_breakdowns: 4,
		experience_score: 8,
		preferred_contact_method: 'email',
		create_date: '23/07/2023',
	},
	{
		customer_id: 68,
		customer_name: 'Danielle Wood',
		phone_number: '+44(0)161 496 0270',
		email: 'donald03@example.net',
		location: { lat: 52.5757, lng: 1.7235 },
		customer_since: 2015,
		'net_promoter_score?': '?',
		num_garage_visits: 24,
		num_previous_breakdowns: 0,
		experience_score: 2,
		preferred_contact_method: 'email',
		create_date: '27/07/2023',
	},
	{
		customer_id: 69,
		customer_name: 'George May',
		phone_number: '(0191) 496 0086',
		email: 'charlotte77@example.org',
		location: { lat: 54.0819, lng: -0.1923 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 18,
		num_previous_breakdowns: 4,
		experience_score: 8,
		preferred_contact_method: 'phone',
		create_date: '01/05/2023',
	},
	{
		customer_id: 70,
		customer_name: 'Mrs Natalie Evans',
		phone_number: '+44(0)808 1570978',
		email: 'robinsonbrandon@example.org',
		location: { lat: 53.5809, lng: -0.6502 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 19,
		num_previous_breakdowns: 0,
		experience_score: 6,
		preferred_contact_method: 'phone',
		create_date: '15/05/2023',
	},
	{
		customer_id: 71,
		customer_name: 'Carl Burton-Shah',
		phone_number: '+44306 9990292',
		email: 'marcus98@example.net',
		location: { lat: 51.2376, lng: -2.9935 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 12,
		num_previous_breakdowns: 3,
		experience_score: 5,
		preferred_contact_method: 'phone',
		create_date: '07/07/2023',
	},
	{
		customer_id: 72,
		customer_name: 'Miss Eileen Harris',
		phone_number: '+441632 960216',
		email: 'nicolalambert@example.com',
		location: { lat: 52.5236, lng: -1.8378 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 12,
		num_previous_breakdowns: 2,
		experience_score: 6,
		preferred_contact_method: 'post',
		create_date: '29/07/2023',
	},
	{
		customer_id: 73,
		customer_name: 'Mr Damian Collier',
		phone_number: '029 2018 0995',
		email: 'lewisnicola@example.org',
		location: { lat: 52.45, lng: -1.7666 },
		customer_since: 2012,
		'net_promoter_score?': '?',
		num_garage_visits: 9,
		num_previous_breakdowns: 4,
		experience_score: 6,
		preferred_contact_method: 'phone',
		create_date: '30/03/2023',
	},
	{
		customer_id: 74,
		customer_name: 'Iain Jackson',
		phone_number: '+44(0)161 496 0669',
		email: 'wingram@example.com',
		location: { lat: 51.9017, lng: -0.2019 },
		customer_since: 2023,
		'net_promoter_score?': '?',
		num_garage_visits: 8,
		num_previous_breakdowns: 2,
		experience_score: 9,
		preferred_contact_method: 'post',
		create_date: '04/02/2023',
	},
	{
		customer_id: 75,
		customer_name: 'Alan Jones',
		phone_number: '+44(0)29 2018 0346',
		email: 'ashah@example.net',
		location: { lat: 55.181, lng: -1.568 },
		customer_since: 2023,
		'net_promoter_score?': '?',
		num_garage_visits: 20,
		num_previous_breakdowns: 3,
		experience_score: 9,
		preferred_contact_method: 'email',
		create_date: '21/06/2023',
	},
	{
		customer_id: 76,
		customer_name: 'Lynda Baldwin',
		phone_number: '(0131) 496 0472',
		email: 'chloethompson@example.org',
		location: { lat: 52.519, lng: -1.995 },
		customer_since: 2020,
		'net_promoter_score?': '?',
		num_garage_visits: 7,
		num_previous_breakdowns: 4,
		experience_score: 5,
		preferred_contact_method: 'post',
		create_date: '30/04/2023',
	},
	{
		customer_id: 77,
		customer_name: 'Karl Hill',
		phone_number: '+44(0)1154960698',
		email: 'phillip22@example.org',
		location: { lat: 51.755, lng: -0.336 },
		customer_since: 2011,
		'net_promoter_score?': '?',
		num_garage_visits: 15,
		num_previous_breakdowns: 2,
		experience_score: 6,
		preferred_contact_method: 'phone',
		create_date: '23/05/2023',
	},
	{
		customer_id: 78,
		customer_name: 'Mrs Sharon Khan',
		phone_number: '(0121) 4960858',
		email: 'mandy13@example.com',
		location: { lat: 51.3143, lng: -0.7387 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 13,
		num_previous_breakdowns: 3,
		experience_score: 1,
		preferred_contact_method: 'email',
		create_date: '10/06/2023',
	},
	{
		customer_id: 79,
		customer_name: 'Kerry Hanson',
		phone_number: '(028)9018333',
		email: 'geoffrey77@example.com',
		location: { lat: 53.4429, lng: -1.4698 },
		customer_since: 2013,
		'net_promoter_score?': '?',
		num_garage_visits: 19,
		num_previous_breakdowns: 1,
		experience_score: 7,
		preferred_contact_method: 'email',
		create_date: '11/04/2023',
	},
	{
		customer_id: 80,
		customer_name: 'Bernard Wilkins',
		phone_number: '(028) 9018 0452',
		email: 'mariewade@example.com',
		location: { lat: 53.43, lng: -1.357 },
		customer_since: 2017,
		'net_promoter_score?': '?',
		num_garage_visits: 1,
		num_previous_breakdowns: 4,
		experience_score: 2,
		preferred_contact_method: 'email',
		create_date: '02/03/2023',
	},
	{
		customer_id: 81,
		customer_name: 'Mr Simon Jones',
		phone_number: '01632 960288',
		email: 'smithscott@example.net',
		location: { lat: 53.789, lng: -2.248 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 9,
		num_previous_breakdowns: 2,
		experience_score: 6,
		preferred_contact_method: 'post',
		create_date: '28/04/2023',
	},
	{
		customer_id: 82,
		customer_name: 'Craig Sinclair',
		phone_number: '+44(0)29 2018 0515',
		email: 'aaronjohnson@example.org',
		location: { lat: 51.438, lng: -2.854 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 6,
		num_previous_breakdowns: 3,
		experience_score: 10,
		preferred_contact_method: 'phone',
		create_date: '05/01/2023',
	},
	{
		customer_id: 83,
		customer_name: 'Sarah Price',
		phone_number: '+44(0)1914960799',
		email: 'williamthompson@example.org',
		location: { lat: 53.549, lng: -2.529 },
		customer_since: 2012,
		'net_promoter_score?': '?',
		num_garage_visits: 14,
		num_previous_breakdowns: 1,
		experience_score: 4,
		preferred_contact_method: 'email',
		create_date: '01/01/2023',
	},
	{
		customer_id: 84,
		customer_name: 'Chloe Allen',
		phone_number: '292018684',
		email: 'kfoster@example.net',
		location: { lat: 53.005, lng: -1.127 },
		customer_since: 2011,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 4,
		experience_score: 10,
		preferred_contact_method: 'email',
		create_date: '23/07/2023',
	},
	{
		customer_id: 85,
		customer_name: 'Grace Thornton',
		phone_number: '4.49099E+11',
		email: 'shawirene@example.com',
		location: { lat: 53.4872, lng: -3.0343 },
		customer_since: 2013,
		'net_promoter_score?': '?',
		num_garage_visits: 4,
		num_previous_breakdowns: 1,
		experience_score: 10,
		preferred_contact_method: 'email',
		create_date: '22/02/2023',
	},
	{
		customer_id: 86,
		customer_name: 'Mr Gary Flynn',
		phone_number: '(0115) 496 0062',
		email: 'brennanlynda@example.com',
		location: { lat: 50.8815, lng: -1.8785 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 18,
		num_previous_breakdowns: 0,
		experience_score: 9,
		preferred_contact_method: 'post',
		create_date: '14/05/2023',
	},
	{
		customer_id: 87,
		customer_name: 'Allan Ahmed',
		phone_number: '0114 4960034',
		email: 'athompson@example.net',
		location: { lat: 51.37, lng: 1.13 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 3,
		num_previous_breakdowns: 4,
		experience_score: 3,
		preferred_contact_method: 'post',
		create_date: '22/04/2023',
	},
	{
		customer_id: 88,
		customer_name: 'Alex Burgess',
		phone_number: '0306 999 0244',
		email: 'caroleperry@example.org',
		location: { lat: 52.451, lng: 1.728 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 3,
		experience_score: 5,
		preferred_contact_method: 'phone',
		create_date: '02/07/2023',
	},
	{
		customer_id: 89,
		customer_name: 'Oliver Elliott',
		phone_number: '0116 4960043',
		email: 'mohammed57@example.net',
		location: { lat: 51.449, lng: -0.337 },
		customer_since: 2015,
		'net_promoter_score?': '?',
		num_garage_visits: 10,
		num_previous_breakdowns: 3,
		experience_score: 6,
		preferred_contact_method: 'post',
		create_date: '27/07/2023',
	},
	{
		customer_id: 90,
		customer_name: 'Georgia Peacock',
		phone_number: '0306 999 0805',
		email: 'wilsonemma@example.org',
		location: { lat: 51.5175, lng: -0.2988 },
		customer_since: 2022,
		'net_promoter_score?': '?',
		num_garage_visits: 11,
		num_previous_breakdowns: 4,
		experience_score: 6,
		preferred_contact_method: 'email',
		create_date: '04/01/2023',
	},
	{
		customer_id: 91,
		customer_name: 'Neil Brown',
		phone_number: '(0808) 1570719',
		email: 'brownzoe@example.net',
		location: { lat: 51.643, lng: -0.163 },
		customer_since: 2018,
		'net_promoter_score?': '?',
		num_garage_visits: 4,
		num_previous_breakdowns: 0,
		experience_score: 9,
		preferred_contact_method: 'phone',
		create_date: '11/07/2023',
	},
	{
		customer_id: 92,
		customer_name: 'Aimee Hunter',
		phone_number: '+44909 879 0838',
		email: 'mmason@example.org',
		location: { lat: 51.4668, lng: -0.375 },
		customer_since: 2019,
		'net_promoter_score?': '?',
		num_garage_visits: 17,
		num_previous_breakdowns: 4,
		experience_score: 8,
		preferred_contact_method: 'email',
		create_date: '05/07/2023',
	},
	{
		customer_id: 93,
		customer_name: 'Rita Hawkins',
		phone_number: '(0141) 496 0384',
		email: 'alicepoole@example.net',
		location: { lat: 52.647, lng: -1.933 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 9,
		num_previous_breakdowns: 2,
		experience_score: 8,
		preferred_contact_method: 'email',
		create_date: '27/04/2023',
	},
	{
		customer_id: 94,
		customer_name: 'Emily Webb-Heath',
		phone_number: '+44(0)29 2018 0066',
		email: 'shawstacey@example.net',
		location: { lat: 53.4676, lng: -2.9408 },
		customer_since: 2020,
		'net_promoter_score?': '?',
		num_garage_visits: 18,
		num_previous_breakdowns: 3,
		experience_score: 2,
		preferred_contact_method: 'post',
		create_date: '04/01/2023',
	},
	{
		customer_id: 95,
		customer_name: 'Jennifer Collins-Thomas',
		phone_number: '+44(0)141 4960580',
		email: 'greenkaty@example.org',
		location: { lat: 54.95, lng: -1.6 },
		customer_since: 2021,
		'net_promoter_score?': '?',
		num_garage_visits: 19,
		num_previous_breakdowns: 5,
		experience_score: 7,
		preferred_contact_method: 'phone',
		create_date: '17/06/2023',
	},
	{
		customer_id: 96,
		customer_name: 'Carole Wood-Anderson',
		phone_number: '(0113) 496 0439',
		email: 'wheelerjosephine@example.net',
		location: { lat: 52.068, lng: -0.7637 },
		customer_since: 2010,
		'net_promoter_score?': '?',
		num_garage_visits: 9,
		num_previous_breakdowns: 1,
		experience_score: 10,
		preferred_contact_method: 'post',
		create_date: '24/04/2023',
	},
	{
		customer_id: 97,
		customer_name: 'Dr Amelia Turner',
		phone_number: '+44909 879 0735',
		email: 'xbishop@example.org',
		location: { lat: 55.126, lng: -1.514 },
		customer_since: 2012,
		'net_promoter_score?': '?',
		num_garage_visits: 23,
		num_previous_breakdowns: 3,
		experience_score: 5,
		preferred_contact_method: 'post',
		create_date: '31/03/2023',
	},
	{
		customer_id: 98,
		customer_name: 'Dr Eleanor Banks',
		phone_number: '0306 9990989',
		email: 'zwilson@example.com',
		location: { lat: 52.9533, lng: -1.15 },
		customer_since: 2016,
		'net_promoter_score?': '?',
		num_garage_visits: 15,
		num_previous_breakdowns: 0,
		experience_score: 8,
		preferred_contact_method: 'phone',
		create_date: '03/01/2023',
	},
	{
		customer_id: 99,
		customer_name: 'Nicole Brown',
		phone_number: '1174960347',
		email: 'zdodd@example.net',
		location: { lat: 51.4015, lng: -0.1949 },
		customer_since: 2020,
		'net_promoter_score?': '?',
		num_garage_visits: 13,
		num_previous_breakdowns: 5,
		experience_score: 3,
		preferred_contact_method: 'email',
		create_date: '26/05/2023',
	},
];
