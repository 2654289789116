import React from 'react';

const SELECTED_CLASS = 'live-fleet-table-filters--selected';
const FILTER_TYPES = ['Brakes', 'Battery', 'Engine', 'Steering'];

function LiveFleetTableFilters({ activeFilters, setActiveFilters }) {
	const handleFilterChange = (e) => {
		const filter = e.target;

		if (activeFilters.length) {
			const filterIndex = activeFilters.indexOf(filter.textContent);

			if (filterIndex <= -1) {
				setActiveFilters([...activeFilters, filter.textContent]);
			} else {
				setActiveFilters(
					activeFilters.filter((item) => item !== filter.textContent)
				);
			}
		} else {
			setActiveFilters([filter.textContent]);
		}
	};

	return (
		<div className='live-fleet-table-filters'>
			<span className='live-fleet-table-filters__text'>
				Filter by car part:{' '}
			</span>
			{FILTER_TYPES.map((filter) => {
				const isActive = activeFilters.some(
					(activeFilter) => filter === activeFilter
				);

				return (
					<button
						key={filter}
						onClick={handleFilterChange}
						className={`live-fleet-table-filters__button button button--secondary ${
							isActive && SELECTED_CLASS
						}`}
					>
						{filter}
					</button>
				);
			})}
		</div>
	);
}

export default LiveFleetTableFilters;
