import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
	Auth: {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		identityPoolId: 'eu-west-1:0bd5c12b-c4e0-46b2-a41c-a0495d196002',

		// REQUIRED - Amazon Cognito Region
		region: 'eu-west-1',

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region
		// Required only if it's different from Amazon Cognito Region
		identityPoolRegion: 'eu-west-1',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'eu-west-1_WyHkNkc8F',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '77cdkcl1seuerola5o5bj8f5s',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
		// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
		signUpVerificationMethod: 'code', // 'code' | 'link'

		// OPTIONAL - Configuration for cookie storage
		// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
		// cookieStorage: {
		// 	// REQUIRED - Cookie domain (only required if cookieStorage is provided)
		// 	domain: '.yourdomain.com',
		// 	// OPTIONAL - Cookie path
		// 	path: '/',
		// 	// OPTIONAL - Cookie expiration in days
		// 	expires: 365,
		// 	// OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
		// 	sameSite: 'strict' | 'lax',
		// 	// OPTIONAL - Cookie secure flag
		// 	// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
		// 	secure: true,
		// },

		// OPTIONAL - customized storage object
		// storage: MyStorage,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		// authenticationFlowType: 'USER_PASSWORD_AUTH',
		authenticationFlowType: 'USER_SRP_AUTH',

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		clientMetadata: { myCustomKey: 'myCustomValue' },

		// OPTIONAL - Hosted UI configuration
		oauth: {
			domain: 'https://idp-portal-demo-dev.auth.eu-west-1.amazoncognito.com',
			scope: [
				'phone',
				'email',
				'profile',
				'openid',
				'aws.cognito.signin.user.admin',
			],
			redirectSignIn: 'http://localhost:3000/',
			redirectSignOut: 'http://localhost:3000/',
			responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
		},
	},
});

// You can get the current config object
const currentConfig = Auth.configure();

export default currentConfig;
