export const carModel = [
	{
		model_id: 0,
		car_make: 'Tesla',
		car_model: 'Model Y',
		year: 2013,
	},
	{
		model_id: 1,
		car_make: 'Tesla',
		car_model: 'Model S',
		year: 2016,
	},
	{
		model_id: 2,
		car_make: 'Tesla',
		car_model: 'Model 3',
		year: 2018,
	},
	{
		model_id: 3,
		car_make: 'Tesla',
		car_model: 'Model S',
		year: 2016,
	},
	{
		model_id: 4,
		car_make: 'Tesla',
		car_model: 'Model 3',
		year: 2016,
	},
	{
		model_id: 5,
		car_make: 'Tesla',
		car_model: 'Model Y',
		year: 2013,
	},
	{
		model_id: 6,
		car_make: 'Tesla',
		car_model: 'Model Y',
		year: 2012,
	},
	{
		model_id: 7,
		car_make: 'Tesla',
		car_model: 'Model 3',
		year: 2021,
	},
	{
		model_id: 8,
		car_make: 'Tesla',
		car_model: 'Model X',
		year: 2021,
	},
	{
		model_id: 9,
		car_make: 'Tesla',
		car_model: 'Model 3',
		year: 2020,
	},
	{
		model_id: 10,
		car_make: 'Tesla',
		car_model: 'Model X',
		year: 2016,
	},
	{
		model_id: 11,
		car_make: 'Tesla',
		car_model: 'Model S',
		year: 2023,
	},
	{
		model_id: 12,
		car_make: 'Tesla',
		car_model: 'Model S',
		year: 2016,
	},
	{
		model_id: 13,
		car_make: 'Tesla',
		car_model: 'Model S',
		year: 2013,
	},
	{
		model_id: 14,
		car_make: 'Tesla',
		car_model: 'Model Y',
		year: 2014,
	},
	{
		model_id: 15,
		car_make: 'Tesla',
		car_model: 'Model S',
		year: 2011,
	},
	{
		model_id: 16,
		car_make: 'Tesla',
		car_model: 'Model X',
		year: 2016,
	},
	{
		model_id: 17,
		car_make: 'Tesla',
		car_model: 'Model S',
		year: 2020,
	},
	{
		model_id: 18,
		car_make: 'Tesla',
		car_model: 'Model 3',
		year: 2013,
	},
	{
		model_id: 19,
		car_make: 'Tesla',
		car_model: 'Model 3',
		year: 2018,
	},
];
