import React from 'react';
import { NavLink } from 'react-router-dom';
import { Table } from 'antd';
import ServiceStatusBar from './ServiceStatusBar';
import { ArrowDown, ArrowRight, ArrowUp } from './Icons';

const columns = [
	{
		title: 'Car model',
		dataIndex: 'carModel',
		key: 'carModel',
	},
	{
		title: 'Part issue',
		dataIndex: 'partIssue',
		key: 'partIssue',
	},
	{
		title: 'Time remaining',
		dataIndex: 'timeRemaining',
		key: 'timeRemaining',
	},
	Table.EXPAND_COLUMN,
	{
		title: '',
		dataIndex: 'vehicleDetail',
		key: 'vehicleDetail',
		render: (vehicleDetail) => (
			<NavLink
				to={`/vehicle-detail/${vehicleDetail}`}
				className='button button--secondary'
			>
				View vehicle
				<ArrowRight />
			</NavLink>
		),
	},
];

const TableRowExpanded = ({ formattedTableData }) => {
	const {
		serviceReport,
		customerName,
		lastServiced,
		urgency,
		vehicleDetail,
	} = formattedTableData;

	return (
		<div className='live-fleet-table__row-expanded'>
			<hr className='live-fleet-table__row-expanded-divider'></hr>
			<div className='live-fleet-table__row-1'>
				<div className='live-fleet-table__customer-details'>
					<p className='live-fleet-table__customer-name'>
						{customerName}
					</p>
					<NavLink
						to={`/vehicle-detail/${vehicleDetail}`}
						className='live-fleet-table__customer-link link'
					>
						Customer details
					</NavLink>
				</div>
				<div className='live-fleet-table__service-details'>
					<p className='live-fleet-table__service-report'>
						{serviceReport}
					</p>
				</div>
			</div>

			<div className='live-fleet-table__row-2'>
				<p className='live-fleet-table__service-last'>
					Last service {lastServiced}
				</p>
				<div className='live-fleet-table__urgency'>
					<ServiceStatusBar urgency={urgency} />
				</div>
			</div>
		</div>
	);
};

function LiveFleetTable({ tableData }) {
	const formattedTableData = tableData.map((item) => {
		const {
			key,
			car_make,
			car_model,
			parts,
			time_period,
			service_report,
			customer_name,
			create_date,
			urgency,
			vin,
		} = item;

		const partNames = parts.map((item) => item.name);

		return {
			key,
			carModel: `${car_make}, ${car_model}`,
			partIssue: partNames.join(', '),
			timeRemaining: `${time_period} days`,
			serviceReport: service_report,
			customerName: customer_name,
			lastServiced: create_date,
			urgency,
			vehicleDetail: vin,
		};
	});

	if (!tableData) {
		return false;
	}

	return (
		<Table
			dataSource={formattedTableData}
			columns={columns}
			showHeader={false}
			expandable={{
				expandedRowRender: (formattedTableData) => (
					<TableRowExpanded {...{ formattedTableData }} />
				),
				rowExpandable: (formattedTableData) =>
					formattedTableData.name !== '',
				expandIcon: ({ expanded, onExpand, record }) =>
					expanded ? (
						<button
							onClick={(e) => onExpand(record, e)}
							className='button button--primary'
						>
							<ArrowUp onClick={(e) => onExpand(record, e)} />
						</button>
					) : (
						<button
							onClick={(e) => onExpand(record, e)}
							className='button button--secondary'
						>
							<ArrowDown onClick={(e) => onExpand(record, e)} />
						</button>
					),
			}}
			locale={{
				emptyText:
					'There are no cars in need of service within this cateogory',
			}}
		/>
	);
}

export default LiveFleetTable;
