export const ArrowDown = () => {
	return (
		<svg
			width='10'
			height='7'
			viewBox='0 0 10 7'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M1 1L5.11429 5L9 1'
				stroke='currentColor'
				strokeWidth='2'
			/>
		</svg>
	);
};

export const ArrowLeft = () => {
	return (
		<svg
			width='7'
			height='10'
			viewBox='0 0 7 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6 1L2 5.11429L6 9'
				stroke='currentColor'
				strokeWidth='2'
			/>
		</svg>
	);
};

export const ArrowRight = () => {
	return (
		<svg
			width='8'
			height='13'
			viewBox='0 0 8 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M1 11.5L6 6.35714L1 1.5'
				stroke='currentColor'
				strokeWidth='2'
			/>
		</svg>
	);
};

export const ArrowUp = () => {
	return (
		<svg
			width='10'
			height='7'
			viewBox='0 0 10 7'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9 6L4.88571 2L1 6'
				stroke='currentColor'
				strokeWidth='2'
			/>
		</svg>
	);
};
