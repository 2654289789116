export const timeSlots = [
	{
		time: '8:00am - 9:00am',
		available: true,
	},
	{
		time: '9:00am - 10:00am',
		available: true,
	},
	{
		time: '10:00am - 11:00am',
		available: true,
	},
	{
		time: '11:00am - 12:00pm',
		available: true,
	},
	{
		time: '12:00pm - 1:00pm',
		available: false,
	},
	{
		time: '1:00pm - 2:00pm',
		available: true,
	},
	{
		time: '2:00pm - 3:00pm',
		available: true,
	},
	{
		time: '3:00pm - 4:00pm',
		available: true,
	},
	{
		time: '4:00pm - 5:00pm',
		available: true,
	},
];

// export const timeSlots = [
// 	{
// 		time: '08:30',
// 		available: true,
// 	},
// 	{
// 		time: '09:00',
// 		available: true,
// 	},
// 	{
// 		time: '09:30',
// 		available: true,
// 	},
// 	{
// 		time: '10:00',
// 		available: true,
// 	},
// 	{
// 		time: '10:30',
// 		available: false,
// 	},
// 	{
// 		time: '11:00',
// 		available: true,
// 	},
// 	{
// 		time: '11:30',
// 		available: true,
// 	},
// 	{
// 		time: '12:00',
// 		available: true,
// 	},
// 	{
// 		time: '12:30',
// 		available: true,
// 	},
// 	{
// 		time: '13:00',
// 		available: true,
// 	},
// 	{
// 		time: '13:30',
// 		available: true,
// 	},
// 	{
// 		time: '14:00',
// 		available: false,
// 	},
// 	{
// 		time: '14:30',
// 		available: true,
// 	},
// 	{
// 		time: '15:00',
// 		available: true,
// 	},
// 	{
// 		time: '15:30',
// 		available: true,
// 	},
// 	{
// 		time: '16:00',
// 		available: true,
// 	},
// 	{
// 		time: '16:30',
// 		available: true,
// 	},
// 	{
// 		time: '17:00',
// 		available: false,
// 	},

// 	{
// 		time: '17:30',
// 		available: false,
// 	},

// 	{
// 		time: '18:00',
// 		available: false,
// 	},
// 	{
// 		time: '18:30',
// 		available: true,
// 	},
// 	{
// 		time: '19:00',
// 		available: true,
// 	},
// 	{
// 		time: '19:30',
// 		available: true,
// 	},
// 	{
// 		time: '20:00',
// 		available: true,
// 	},
// 	{
// 		time: '20:30',
// 		available: true,
// 	},
// 	{
// 		time: '21:00',
// 		available: true,
// 	},
// 	{
// 		time: '21:30',
// 		available: true,
// 	},
// 	{
// 		time: '22:00',
// 		available: true,
// 	},
// 	{
// 		time: '22:30',
// 		available: true,
// 	},
// 	{
// 		time: '23:00',
// 		available: true,
// 	},
// 	{
// 		time: '23:30',
// 		available: true,
// 	},
// ];
