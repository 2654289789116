import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import ServiceUrgencyChart from '../components/ServiceUrgencyChart';
import LiveFleetMap from '../components/LiveFleetMap';
import LiveFleetTable from '../components/LiveFleetTable';
import LiveFleetTableFilters from '../components/LiveFleetTableFilters';

// FIXME: Only pass required data to components
function ServiceOverview({ pageData }) {
	const [activeFilters, setActiveFilters] = useState([]);
	const [tableData, setTableData] = useState(pageData);

	useEffect(() => {
		if (activeFilters.length) {
			const filteredData = pageData.filter((dataItem) => {
				const hasFilteredPart = activeFilters.some((activeFilter) => {
					const partNames = dataItem.parts.map((item) => item.name);

					return partNames.some((item) => item === activeFilter);
				});

				return hasFilteredPart;
			});

			setTableData(filteredData);
		} else {
			setTableData(pageData);
		}
	}, [activeFilters, pageData]);

	const TabContent = ({ type }) => {
		let tabData = tableData.filter(
			(item) => type === item.urgency.toLowerCase()
		);

		return (
			<>
				<LiveFleetTableFilters
					activeFilters={activeFilters}
					setActiveFilters={setActiveFilters}
				/>
				<LiveFleetTable tableData={tabData} />
			</>
		);
	};

	const items = [
		{
			key: '1',
			label: `Critical`,
			children: <TabContent type={'critical'} />,
		},
		{
			key: '2',
			label: `High urgency`,
			children: <TabContent type={'high'} />,
		},
		{
			key: '3',
			label: `Medium urgency`,
			children: <TabContent type={'medium'} />,
		},
	];

	if (!pageData) {
		return null;
	}

	return (
		<div className='service-overview'>
			<header className='service-overview__header'>
				<h2 className='service-overview__heading'>
					Customer Service Monitoring
				</h2>
				<a
					href='/dashboard'
					className='service-overview__dashboard-link button button--primary'
				>
					View Quicksight dashboard
				</a>
			</header>

			<div className='service-overview__content app__main'>
				<div className='service-overview__sidebar app__sidebar'>
					<div className='service-overview__urgency'>
						<ServiceUrgencyChart />
					</div>
					<div className='service-overview__map'>
						<LiveFleetMap pageData={pageData} />
					</div>
				</div>

				<div className='service-overview__fleet-monitor app__content'>
					<div className='service-overview__table'>
						<Tabs
							defaultActiveKey='1'
							items={items}
							type='card'
							animated={true}
							centered={true}
							popupClassName='service-overview__tabs'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServiceOverview;
