import React from 'react';
import {
	carAll,
	carBrakes,
	carBattery,
	carEngine,
	carSteering,
} from '../../consts';

function CarVisualisation({ lastServiced, tabName }) {
	let carImage;

	switch (tabName) {
		case 'all':
			carImage = carAll;
			break;
		case 'brakes':
			carImage = carBrakes;
			break;
		case 'battery':
			carImage = carBattery;
			break;
		case 'engine':
			carImage = carEngine;
			break;
		case 'steering':
			carImage = carSteering;
			break;
		default:
			carImage = carAll;
	}

	return (
		<div className='car-visualisation'>
			<img
				src={carImage}
				alt='Car visualisation'
				className='car-visualisation__image'
				width={980}
				height={301}
			/>
			<p className='car-visualisation__date'>
				Last service {lastServiced}
			</p>
		</div>
	);
}

export default CarVisualisation;
