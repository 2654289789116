// Grouped images together to be exported as variables from one file
import mapMarker from './assets/images/map-marker.png';
import carAll from './assets/images/car-visualisation/car-all.png';
import carBrakes from './assets/images/car-visualisation/car-brakes.png';
import carBattery from './assets/images/car-visualisation/car-battery.png';
import carEngine from './assets/images/car-visualisation/car-engine.png';
import carSteering from './assets/images/car-visualisation/car-steering.png';
import carBase from './assets/images/car-visualisation/car-visualisation-white.png';
import S1F0BVK1 from './assets/images/quicksight/S1F0BVK1.png';
import S1F0S4EG from './assets/images/quicksight/S1F0S4EG.png';
import S1F03YZM from './assets/images/quicksight/S1F03YZM.png';
import S1F13H80 from './assets/images/quicksight/S1F13H80.png';
import S1F135TN from './assets/images/quicksight/S1F135TN.png';
import W1F1B0KF from './assets/images/quicksight/W1F1B0KF.png';
import W1F1CJ1K from './assets/images/quicksight/W1F1CJ1K.png';
import Z1F14BGY from './assets/images/quicksight/Z1F14BGY.png';

export {
	carAll,
	carBrakes,
	carBattery,
	carEngine,
	carSteering,
	carBase,
	mapMarker,
	S1F0BVK1,
	S1F0S4EG,
	S1F03YZM,
	S1F13H80,
	S1F135TN,
	W1F1B0KF,
	W1F1CJ1K,
	Z1F14BGY,
};

// Urgency variables
export const CRITICAL_URGENCY = 'critical';
export const HIGH_URGENCY = 'high';
export const MEDIUM_URGENCY = 'medium';
export const LOW_URGENCY = 'low';

// Colour variables
export const BASE_HEX = '#ffffff';
export const CRITICAL_HEX = '#FF3838';
export const HIGH_HEX = '#ECB800';
export const MEDIUM_HEX = '#E7EC00';
export const LOW_HEX = '#D7FD6A';
