import React from 'react';

function ServiceStatusBar({ urgency }) {
	if (!urgency) {
		return null;
	}

	return (
		<div className='service-status-bar'>
			<span className='service-status-bar__text'>
				Service {urgency.toLowerCase()}:
			</span>

			<div className='service-status-bar__chart'>
				<div className='service-status-bar__base'></div>

				<div
					className={`service-status-bar__gradient service-status-bar--${urgency.toLowerCase()}`}
				></div>
			</div>
		</div>
	);
}

export default ServiceStatusBar;
