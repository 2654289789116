import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import VehicleDetailHeader from '../components/VehicleDetailHeader';
import RecommendedUpgrades from '../components/RecommendedUpgrades';
import ServiceStatusBar from '../components/ServiceStatusBar';
import BookingWidget from '../components/BookingWidget';
import { ArrowLeft } from '../components/Icons';

// FIXME: Only pass required data to components
function VehicleDetail({ pageData }) {
	const { vin } = useParams();
	const [customerCarData, setCustomerCarData] = useState({});

	useEffect(() => {
		if (vin) {
			const selectedDetailData = pageData.find(
				(item) => item.vin === vin
			);

			if (selectedDetailData) {
				setCustomerCarData(selectedDetailData);
			}
		}
	}, [setCustomerCarData, vin, pageData, customerCarData]);

	if (!customerCarData) {
		return null;
	}

	return (
		<div className='vehicle-detail'>
			<div className='vehicle-detail__nav-link'>
				<NavLink to='/' className='link'>
					<ArrowLeft />
					Return to customer service overview
				</NavLink>
			</div>

			<VehicleDetailHeader customerDetails={customerCarData} />

			<div className='vehicle-detail__content app__main'>
				<div className='vehicle-detail__upgrades app_content'>
					<RecommendedUpgrades carDetails={customerCarData} />
				</div>
				<div className='vehicle-detail__booking app__sidebar'>
					<ServiceStatusBar urgency={customerCarData.urgency} />
					<BookingWidget bookingDetails={customerCarData} />
				</div>
			</div>
		</div>
	);
}

export default VehicleDetail;
