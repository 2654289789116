export const garageStock = [
	{
		garage_id: 0,
		part_id: 0,
		quantity: 265,
	},
	{
		garage_id: 0,
		part_id: 1,
		quantity: 140,
	},
	{
		garage_id: 0,
		part_id: 2,
		quantity: 45,
	},
	{
		garage_id: 0,
		part_id: 3,
		quantity: 317,
	},
	{
		garage_id: 0,
		part_id: 4,
		quantity: 389,
	},
	{
		garage_id: 0,
		part_id: 5,
		quantity: 425,
	},
	{
		garage_id: 0,
		part_id: 6,
		quantity: 283,
	},
	{
		garage_id: 0,
		part_id: 7,
		quantity: 414,
	},
	{
		garage_id: 0,
		part_id: 8,
		quantity: 470,
	},
	{
		garage_id: 0,
		part_id: 9,
		quantity: 172,
	},
	{
		garage_id: 0,
		part_id: 10,
		quantity: 188,
	},
	{
		garage_id: 0,
		part_id: 11,
		quantity: 466,
	},
	{
		garage_id: 0,
		part_id: 12,
		quantity: 100,
	},
	{
		garage_id: 0,
		part_id: 13,
		quantity: 270,
	},
	{
		garage_id: 0,
		part_id: 14,
		quantity: 293,
	},
	{
		garage_id: 0,
		part_id: 15,
		quantity: 117,
	},
	{
		garage_id: 0,
		part_id: 16,
		quantity: 414,
	},
	{
		garage_id: 0,
		part_id: 17,
		quantity: 50,
	},
	{
		garage_id: 0,
		part_id: 18,
		quantity: 498,
	},
	{
		garage_id: 0,
		part_id: 19,
		quantity: 345,
	},
	{
		garage_id: 0,
		part_id: 20,
		quantity: 412,
	},
	{
		garage_id: 0,
		part_id: 21,
		quantity: 332,
	},
	{
		garage_id: 0,
		part_id: 22,
		quantity: 250,
	},
	{
		garage_id: 0,
		part_id: 23,
		quantity: 175,
	},
	{
		garage_id: 0,
		part_id: 24,
		quantity: 480,
	},
	{
		garage_id: 0,
		part_id: 25,
		quantity: 326,
	},
	{
		garage_id: 0,
		part_id: 26,
		quantity: 28,
	},
	{
		garage_id: 0,
		part_id: 27,
		quantity: 322,
	},
	{
		garage_id: 0,
		part_id: 28,
		quantity: 466,
	},
	{
		garage_id: 0,
		part_id: 29,
		quantity: 6,
	},
	{
		garage_id: 0,
		part_id: 30,
		quantity: 432,
	},
	{
		garage_id: 0,
		part_id: 31,
		quantity: 377,
	},
	{
		garage_id: 0,
		part_id: 32,
		quantity: 297,
	},
	{
		garage_id: 0,
		part_id: 33,
		quantity: 171,
	},
	{
		garage_id: 0,
		part_id: 34,
		quantity: 209,
	},
	{
		garage_id: 0,
		part_id: 35,
		quantity: 323,
	},
	{
		garage_id: 0,
		part_id: 36,
		quantity: 40,
	},
	{
		garage_id: 0,
		part_id: 37,
		quantity: 443,
	},
	{
		garage_id: 0,
		part_id: 38,
		quantity: 132,
	},
	{
		garage_id: 0,
		part_id: 39,
		quantity: 286,
	},
	{
		garage_id: 0,
		part_id: 40,
		quantity: 414,
	},
	{
		garage_id: 0,
		part_id: 41,
		quantity: 272,
	},
	{
		garage_id: 0,
		part_id: 42,
		quantity: 11,
	},
	{
		garage_id: 0,
		part_id: 43,
		quantity: 500,
	},
	{
		garage_id: 0,
		part_id: 44,
		quantity: 339,
	},
	{
		garage_id: 0,
		part_id: 45,
		quantity: 38,
	},
	{
		garage_id: 0,
		part_id: 46,
		quantity: 301,
	},
	{
		garage_id: 0,
		part_id: 47,
		quantity: 108,
	},
	{
		garage_id: 0,
		part_id: 48,
		quantity: 319,
	},
	{
		garage_id: 0,
		part_id: 49,
		quantity: 140,
	},
	{
		garage_id: 0,
		part_id: 50,
		quantity: 103,
	},
	{
		garage_id: 0,
		part_id: 51,
		quantity: 148,
	},
	{
		garage_id: 0,
		part_id: 52,
		quantity: 304,
	},
	{
		garage_id: 0,
		part_id: 53,
		quantity: 122,
	},
	{
		garage_id: 0,
		part_id: 54,
		quantity: 271,
	},
	{
		garage_id: 0,
		part_id: 55,
		quantity: 380,
	},
	{
		garage_id: 0,
		part_id: 56,
		quantity: 378,
	},
	{
		garage_id: 0,
		part_id: 57,
		quantity: 244,
	},
	{
		garage_id: 0,
		part_id: 58,
		quantity: 471,
	},
	{
		garage_id: 0,
		part_id: 59,
		quantity: 40,
	},
	{
		garage_id: 0,
		part_id: 60,
		quantity: 119,
	},
	{
		garage_id: 0,
		part_id: 61,
		quantity: 384,
	},
	{
		garage_id: 0,
		part_id: 62,
		quantity: 129,
	},
	{
		garage_id: 0,
		part_id: 63,
		quantity: 153,
	},
	{
		garage_id: 0,
		part_id: 64,
		quantity: 123,
	},
	{
		garage_id: 0,
		part_id: 65,
		quantity: 289,
	},
	{
		garage_id: 0,
		part_id: 66,
		quantity: 59,
	},
	{
		garage_id: 0,
		part_id: 67,
		quantity: 174,
	},
	{
		garage_id: 0,
		part_id: 68,
		quantity: 338,
	},
	{
		garage_id: 0,
		part_id: 69,
		quantity: 397,
	},
	{
		garage_id: 0,
		part_id: 70,
		quantity: 304,
	},
	{
		garage_id: 0,
		part_id: 71,
		quantity: 108,
	},
	{
		garage_id: 0,
		part_id: 72,
		quantity: 331,
	},
	{
		garage_id: 0,
		part_id: 73,
		quantity: 427,
	},
	{
		garage_id: 0,
		part_id: 74,
		quantity: 209,
	},
	{
		garage_id: 0,
		part_id: 75,
		quantity: 255,
	},
	{
		garage_id: 0,
		part_id: 76,
		quantity: 180,
	},
	{
		garage_id: 0,
		part_id: 77,
		quantity: 119,
	},
	{
		garage_id: 0,
		part_id: 78,
		quantity: 181,
	},
	{
		garage_id: 0,
		part_id: 79,
		quantity: 317,
	},
	{
		garage_id: 0,
		part_id: 80,
		quantity: 134,
	},
	{
		garage_id: 0,
		part_id: 81,
		quantity: 480,
	},
	{
		garage_id: 0,
		part_id: 82,
		quantity: 223,
	},
	{
		garage_id: 0,
		part_id: 83,
		quantity: 156,
	},
	{
		garage_id: 0,
		part_id: 84,
		quantity: 163,
	},
	{
		garage_id: 0,
		part_id: 85,
		quantity: 244,
	},
	{
		garage_id: 0,
		part_id: 86,
		quantity: 182,
	},
	{
		garage_id: 0,
		part_id: 87,
		quantity: 93,
	},
	{
		garage_id: 0,
		part_id: 88,
		quantity: 360,
	},
	{
		garage_id: 0,
		part_id: 89,
		quantity: 219,
	},
	{
		garage_id: 0,
		part_id: 90,
		quantity: 370,
	},
	{
		garage_id: 0,
		part_id: 91,
		quantity: 81,
	},
	{
		garage_id: 0,
		part_id: 92,
		quantity: 455,
	},
	{
		garage_id: 0,
		part_id: 93,
		quantity: 409,
	},
	{
		garage_id: 0,
		part_id: 94,
		quantity: 397,
	},
	{
		garage_id: 0,
		part_id: 95,
		quantity: 61,
	},
	{
		garage_id: 0,
		part_id: 96,
		quantity: 442,
	},
	{
		garage_id: 0,
		part_id: 97,
		quantity: 386,
	},
	{
		garage_id: 0,
		part_id: 98,
		quantity: 327,
	},
	{
		garage_id: 0,
		part_id: 99,
		quantity: 323,
	},
	{
		garage_id: 0,
		part_id: 100,
		quantity: 99,
	},
	{
		garage_id: 0,
		part_id: 101,
		quantity: 469,
	},
	{
		garage_id: 0,
		part_id: 102,
		quantity: 108,
	},
	{
		garage_id: 0,
		part_id: 103,
		quantity: 390,
	},
	{
		garage_id: 0,
		part_id: 104,
		quantity: 460,
	},
	{
		garage_id: 0,
		part_id: 105,
		quantity: 283,
	},
	{
		garage_id: 0,
		part_id: 106,
		quantity: 342,
	},
	{
		garage_id: 0,
		part_id: 107,
		quantity: 424,
	},
	{
		garage_id: 0,
		part_id: 108,
		quantity: 246,
	},
	{
		garage_id: 0,
		part_id: 109,
		quantity: 384,
	},
	{
		garage_id: 0,
		part_id: 110,
		quantity: 319,
	},
	{
		garage_id: 0,
		part_id: 111,
		quantity: 206,
	},
	{
		garage_id: 0,
		part_id: 112,
		quantity: 3,
	},
	{
		garage_id: 0,
		part_id: 113,
		quantity: 73,
	},
	{
		garage_id: 0,
		part_id: 114,
		quantity: 5,
	},
	{
		garage_id: 0,
		part_id: 115,
		quantity: 157,
	},
	{
		garage_id: 0,
		part_id: 116,
		quantity: 59,
	},
	{
		garage_id: 0,
		part_id: 117,
		quantity: 165,
	},
	{
		garage_id: 0,
		part_id: 118,
		quantity: 172,
	},
	{
		garage_id: 0,
		part_id: 119,
		quantity: 328,
	},
	{
		garage_id: 0,
		part_id: 120,
		quantity: 268,
	},
	{
		garage_id: 0,
		part_id: 121,
		quantity: 127,
	},
	{
		garage_id: 0,
		part_id: 122,
		quantity: 387,
	},
	{
		garage_id: 0,
		part_id: 123,
		quantity: 26,
	},
	{
		garage_id: 0,
		part_id: 124,
		quantity: 57,
	},
	{
		garage_id: 0,
		part_id: 125,
		quantity: 343,
	},
	{
		garage_id: 0,
		part_id: 126,
		quantity: 307,
	},
	{
		garage_id: 0,
		part_id: 127,
		quantity: 438,
	},
	{
		garage_id: 0,
		part_id: 128,
		quantity: 49,
	},
	{
		garage_id: 0,
		part_id: 129,
		quantity: 356,
	},
	{
		garage_id: 0,
		part_id: 130,
		quantity: 401,
	},
	{
		garage_id: 0,
		part_id: 131,
		quantity: 423,
	},
	{
		garage_id: 0,
		part_id: 132,
		quantity: 211,
	},
	{
		garage_id: 0,
		part_id: 133,
		quantity: 151,
	},
	{
		garage_id: 0,
		part_id: 134,
		quantity: 411,
	},
	{
		garage_id: 0,
		part_id: 135,
		quantity: 384,
	},
	{
		garage_id: 0,
		part_id: 136,
		quantity: 223,
	},
	{
		garage_id: 0,
		part_id: 137,
		quantity: 115,
	},
	{
		garage_id: 0,
		part_id: 138,
		quantity: 63,
	},
	{
		garage_id: 0,
		part_id: 139,
		quantity: 94,
	},
	{
		garage_id: 0,
		part_id: 140,
		quantity: 32,
	},
	{
		garage_id: 0,
		part_id: 141,
		quantity: 379,
	},
	{
		garage_id: 0,
		part_id: 142,
		quantity: 76,
	},
	{
		garage_id: 0,
		part_id: 143,
		quantity: 273,
	},
	{
		garage_id: 0,
		part_id: 144,
		quantity: 127,
	},
	{
		garage_id: 0,
		part_id: 145,
		quantity: 335,
	},
	{
		garage_id: 0,
		part_id: 146,
		quantity: 430,
	},
	{
		garage_id: 0,
		part_id: 147,
		quantity: 114,
	},
	{
		garage_id: 0,
		part_id: 148,
		quantity: 457,
	},
	{
		garage_id: 0,
		part_id: 149,
		quantity: 371,
	},
	{
		garage_id: 0,
		part_id: 150,
		quantity: 41,
	},
	{
		garage_id: 0,
		part_id: 151,
		quantity: 495,
	},
	{
		garage_id: 0,
		part_id: 152,
		quantity: 0,
	},
	{
		garage_id: 0,
		part_id: 153,
		quantity: 361,
	},
	{
		garage_id: 0,
		part_id: 154,
		quantity: 313,
	},
	{
		garage_id: 0,
		part_id: 155,
		quantity: 430,
	},
	{
		garage_id: 0,
		part_id: 156,
		quantity: 25,
	},
	{
		garage_id: 0,
		part_id: 157,
		quantity: 331,
	},
	{
		garage_id: 0,
		part_id: 158,
		quantity: 202,
	},
	{
		garage_id: 0,
		part_id: 159,
		quantity: 408,
	},
	{
		garage_id: 0,
		part_id: 160,
		quantity: 461,
	},
	{
		garage_id: 0,
		part_id: 161,
		quantity: 301,
	},
	{
		garage_id: 0,
		part_id: 162,
		quantity: 75,
	},
	{
		garage_id: 0,
		part_id: 163,
		quantity: 300,
	},
	{
		garage_id: 0,
		part_id: 164,
		quantity: 160,
	},
	{
		garage_id: 0,
		part_id: 165,
		quantity: 495,
	},
	{
		garage_id: 0,
		part_id: 166,
		quantity: 59,
	},
	{
		garage_id: 0,
		part_id: 167,
		quantity: 71,
	},
	{
		garage_id: 0,
		part_id: 168,
		quantity: 473,
	},
	{
		garage_id: 0,
		part_id: 169,
		quantity: 326,
	},
	{
		garage_id: 0,
		part_id: 170,
		quantity: 138,
	},
	{
		garage_id: 0,
		part_id: 171,
		quantity: 348,
	},
	{
		garage_id: 0,
		part_id: 172,
		quantity: 332,
	},
	{
		garage_id: 0,
		part_id: 173,
		quantity: 312,
	},
	{
		garage_id: 0,
		part_id: 174,
		quantity: 6,
	},
	{
		garage_id: 0,
		part_id: 175,
		quantity: 303,
	},
	{
		garage_id: 0,
		part_id: 176,
		quantity: 91,
	},
	{
		garage_id: 0,
		part_id: 177,
		quantity: 324,
	},
	{
		garage_id: 0,
		part_id: 178,
		quantity: 495,
	},
	{
		garage_id: 0,
		part_id: 179,
		quantity: 347,
	},
	{
		garage_id: 0,
		part_id: 180,
		quantity: 34,
	},
	{
		garage_id: 0,
		part_id: 181,
		quantity: 267,
	},
	{
		garage_id: 0,
		part_id: 182,
		quantity: 460,
	},
	{
		garage_id: 0,
		part_id: 183,
		quantity: 309,
	},
	{
		garage_id: 0,
		part_id: 184,
		quantity: 480,
	},
	{
		garage_id: 0,
		part_id: 185,
		quantity: 396,
	},
	{
		garage_id: 0,
		part_id: 186,
		quantity: 403,
	},
	{
		garage_id: 0,
		part_id: 187,
		quantity: 21,
	},
	{
		garage_id: 0,
		part_id: 188,
		quantity: 31,
	},
	{
		garage_id: 0,
		part_id: 189,
		quantity: 341,
	},
	{
		garage_id: 0,
		part_id: 190,
		quantity: 14,
	},
	{
		garage_id: 0,
		part_id: 191,
		quantity: 298,
	},
	{
		garage_id: 0,
		part_id: 192,
		quantity: 34,
	},
	{
		garage_id: 0,
		part_id: 193,
		quantity: 125,
	},
	{
		garage_id: 0,
		part_id: 194,
		quantity: 395,
	},
	{
		garage_id: 0,
		part_id: 195,
		quantity: 66,
	},
	{
		garage_id: 0,
		part_id: 196,
		quantity: 190,
	},
	{
		garage_id: 0,
		part_id: 197,
		quantity: 496,
	},
	{
		garage_id: 0,
		part_id: 198,
		quantity: 97,
	},
	{
		garage_id: 0,
		part_id: 199,
		quantity: 37,
	},
	{
		garage_id: 0,
		part_id: 200,
		quantity: 410,
	},
	{
		garage_id: 0,
		part_id: 201,
		quantity: 52,
	},
	{
		garage_id: 0,
		part_id: 202,
		quantity: 76,
	},
	{
		garage_id: 0,
		part_id: 203,
		quantity: 438,
	},
	{
		garage_id: 0,
		part_id: 204,
		quantity: 301,
	},
	{
		garage_id: 0,
		part_id: 205,
		quantity: 369,
	},
	{
		garage_id: 0,
		part_id: 206,
		quantity: 25,
	},
	{
		garage_id: 0,
		part_id: 207,
		quantity: 354,
	},
	{
		garage_id: 0,
		part_id: 208,
		quantity: 130,
	},
	{
		garage_id: 0,
		part_id: 209,
		quantity: 237,
	},
	{
		garage_id: 0,
		part_id: 210,
		quantity: 458,
	},
	{
		garage_id: 0,
		part_id: 211,
		quantity: 352,
	},
	{
		garage_id: 0,
		part_id: 212,
		quantity: 86,
	},
	{
		garage_id: 0,
		part_id: 213,
		quantity: 452,
	},
	{
		garage_id: 0,
		part_id: 214,
		quantity: 396,
	},
	{
		garage_id: 0,
		part_id: 215,
		quantity: 237,
	},
	{
		garage_id: 0,
		part_id: 216,
		quantity: 371,
	},
	{
		garage_id: 0,
		part_id: 217,
		quantity: 6,
	},
	{
		garage_id: 0,
		part_id: 218,
		quantity: 6,
	},
	{
		garage_id: 0,
		part_id: 219,
		quantity: 122,
	},
	{
		garage_id: 0,
		part_id: 220,
		quantity: 354,
	},
	{
		garage_id: 0,
		part_id: 221,
		quantity: 334,
	},
	{
		garage_id: 0,
		part_id: 222,
		quantity: 483,
	},
	{
		garage_id: 0,
		part_id: 223,
		quantity: 472,
	},
	{
		garage_id: 0,
		part_id: 224,
		quantity: 52,
	},
	{
		garage_id: 0,
		part_id: 225,
		quantity: 402,
	},
	{
		garage_id: 0,
		part_id: 226,
		quantity: 387,
	},
	{
		garage_id: 0,
		part_id: 227,
		quantity: 259,
	},
	{
		garage_id: 0,
		part_id: 228,
		quantity: 183,
	},
	{
		garage_id: 0,
		part_id: 229,
		quantity: 37,
	},
	{
		garage_id: 0,
		part_id: 230,
		quantity: 98,
	},
	{
		garage_id: 0,
		part_id: 231,
		quantity: 440,
	},
	{
		garage_id: 0,
		part_id: 232,
		quantity: 87,
	},
	{
		garage_id: 0,
		part_id: 233,
		quantity: 186,
	},
	{
		garage_id: 0,
		part_id: 234,
		quantity: 342,
	},
	{
		garage_id: 0,
		part_id: 235,
		quantity: 454,
	},
	{
		garage_id: 0,
		part_id: 236,
		quantity: 13,
	},
	{
		garage_id: 0,
		part_id: 237,
		quantity: 421,
	},
	{
		garage_id: 0,
		part_id: 238,
		quantity: 185,
	},
	{
		garage_id: 0,
		part_id: 239,
		quantity: 123,
	},
	{
		garage_id: 0,
		part_id: 240,
		quantity: 436,
	},
	{
		garage_id: 0,
		part_id: 241,
		quantity: 15,
	},
	{
		garage_id: 0,
		part_id: 242,
		quantity: 112,
	},
	{
		garage_id: 0,
		part_id: 243,
		quantity: 132,
	},
	{
		garage_id: 0,
		part_id: 244,
		quantity: 206,
	},
	{
		garage_id: 0,
		part_id: 245,
		quantity: 172,
	},
	{
		garage_id: 0,
		part_id: 246,
		quantity: 262,
	},
	{
		garage_id: 0,
		part_id: 247,
		quantity: 164,
	},
	{
		garage_id: 0,
		part_id: 248,
		quantity: 186,
	},
	{
		garage_id: 0,
		part_id: 249,
		quantity: 185,
	},
	{
		garage_id: 0,
		part_id: 250,
		quantity: 383,
	},
	{
		garage_id: 0,
		part_id: 251,
		quantity: 12,
	},
	{
		garage_id: 0,
		part_id: 252,
		quantity: 389,
	},
	{
		garage_id: 0,
		part_id: 253,
		quantity: 428,
	},
	{
		garage_id: 0,
		part_id: 254,
		quantity: 91,
	},
	{
		garage_id: 0,
		part_id: 255,
		quantity: 422,
	},
	{
		garage_id: 0,
		part_id: 256,
		quantity: 144,
	},
	{
		garage_id: 0,
		part_id: 257,
		quantity: 119,
	},
	{
		garage_id: 0,
		part_id: 258,
		quantity: 375,
	},
	{
		garage_id: 0,
		part_id: 259,
		quantity: 222,
	},
	{
		garage_id: 0,
		part_id: 260,
		quantity: 17,
	},
	{
		garage_id: 0,
		part_id: 261,
		quantity: 157,
	},
	{
		garage_id: 0,
		part_id: 262,
		quantity: 52,
	},
	{
		garage_id: 0,
		part_id: 263,
		quantity: 52,
	},
	{
		garage_id: 0,
		part_id: 264,
		quantity: 420,
	},
	{
		garage_id: 0,
		part_id: 265,
		quantity: 17,
	},
	{
		garage_id: 0,
		part_id: 266,
		quantity: 125,
	},
	{
		garage_id: 0,
		part_id: 267,
		quantity: 226,
	},
	{
		garage_id: 0,
		part_id: 268,
		quantity: 421,
	},
	{
		garage_id: 0,
		part_id: 269,
		quantity: 342,
	},
	{
		garage_id: 0,
		part_id: 270,
		quantity: 129,
	},
	{
		garage_id: 0,
		part_id: 271,
		quantity: 21,
	},
	{
		garage_id: 0,
		part_id: 272,
		quantity: 287,
	},
	{
		garage_id: 0,
		part_id: 273,
		quantity: 219,
	},
	{
		garage_id: 0,
		part_id: 274,
		quantity: 399,
	},
	{
		garage_id: 0,
		part_id: 275,
		quantity: 403,
	},
	{
		garage_id: 0,
		part_id: 276,
		quantity: 25,
	},
	{
		garage_id: 0,
		part_id: 277,
		quantity: 409,
	},
	{
		garage_id: 0,
		part_id: 278,
		quantity: 392,
	},
	{
		garage_id: 0,
		part_id: 279,
		quantity: 440,
	},
	{
		garage_id: 0,
		part_id: 280,
		quantity: 24,
	},
	{
		garage_id: 0,
		part_id: 281,
		quantity: 151,
	},
	{
		garage_id: 0,
		part_id: 282,
		quantity: 325,
	},
	{
		garage_id: 0,
		part_id: 283,
		quantity: 433,
	},
	{
		garage_id: 0,
		part_id: 284,
		quantity: 166,
	},
	{
		garage_id: 0,
		part_id: 285,
		quantity: 63,
	},
	{
		garage_id: 0,
		part_id: 286,
		quantity: 70,
	},
	{
		garage_id: 0,
		part_id: 287,
		quantity: 483,
	},
	{
		garage_id: 0,
		part_id: 288,
		quantity: 198,
	},
	{
		garage_id: 0,
		part_id: 289,
		quantity: 464,
	},
	{
		garage_id: 0,
		part_id: 290,
		quantity: 362,
	},
	{
		garage_id: 0,
		part_id: 291,
		quantity: 216,
	},
	{
		garage_id: 0,
		part_id: 292,
		quantity: 137,
	},
	{
		garage_id: 0,
		part_id: 293,
		quantity: 304,
	},
	{
		garage_id: 0,
		part_id: 294,
		quantity: 245,
	},
	{
		garage_id: 0,
		part_id: 295,
		quantity: 284,
	},
	{
		garage_id: 0,
		part_id: 296,
		quantity: 362,
	},
	{
		garage_id: 0,
		part_id: 297,
		quantity: 139,
	},
	{
		garage_id: 0,
		part_id: 298,
		quantity: 155,
	},
	{
		garage_id: 0,
		part_id: 299,
		quantity: 456,
	},
	{
		garage_id: 0,
		part_id: 300,
		quantity: 346,
	},
	{
		garage_id: 0,
		part_id: 301,
		quantity: 113,
	},
	{
		garage_id: 0,
		part_id: 302,
		quantity: 247,
	},
	{
		garage_id: 0,
		part_id: 303,
		quantity: 371,
	},
	{
		garage_id: 0,
		part_id: 304,
		quantity: 196,
	},
	{
		garage_id: 0,
		part_id: 305,
		quantity: 433,
	},
	{
		garage_id: 0,
		part_id: 306,
		quantity: 59,
	},
	{
		garage_id: 0,
		part_id: 307,
		quantity: 18,
	},
	{
		garage_id: 0,
		part_id: 308,
		quantity: 297,
	},
	{
		garage_id: 0,
		part_id: 309,
		quantity: 396,
	},
	{
		garage_id: 0,
		part_id: 310,
		quantity: 76,
	},
	{
		garage_id: 0,
		part_id: 311,
		quantity: 186,
	},
	{
		garage_id: 0,
		part_id: 312,
		quantity: 398,
	},
	{
		garage_id: 0,
		part_id: 313,
		quantity: 290,
	},
	{
		garage_id: 0,
		part_id: 314,
		quantity: 142,
	},
	{
		garage_id: 0,
		part_id: 315,
		quantity: 146,
	},
	{
		garage_id: 0,
		part_id: 316,
		quantity: 190,
	},
	{
		garage_id: 0,
		part_id: 317,
		quantity: 80,
	},
	{
		garage_id: 0,
		part_id: 318,
		quantity: 272,
	},
	{
		garage_id: 0,
		part_id: 319,
		quantity: 413,
	},
	{
		garage_id: 0,
		part_id: 320,
		quantity: 226,
	},
	{
		garage_id: 0,
		part_id: 321,
		quantity: 427,
	},
	{
		garage_id: 0,
		part_id: 322,
		quantity: 300,
	},
	{
		garage_id: 0,
		part_id: 323,
		quantity: 345,
	},
	{
		garage_id: 0,
		part_id: 324,
		quantity: 327,
	},
	{
		garage_id: 0,
		part_id: 325,
		quantity: 421,
	},
	{
		garage_id: 0,
		part_id: 326,
		quantity: 55,
	},
	{
		garage_id: 0,
		part_id: 327,
		quantity: 137,
	},
	{
		garage_id: 0,
		part_id: 328,
		quantity: 267,
	},
	{
		garage_id: 0,
		part_id: 329,
		quantity: 264,
	},
	{
		garage_id: 0,
		part_id: 330,
		quantity: 37,
	},
	{
		garage_id: 0,
		part_id: 331,
		quantity: 338,
	},
	{
		garage_id: 0,
		part_id: 332,
		quantity: 364,
	},
	{
		garage_id: 0,
		part_id: 333,
		quantity: 450,
	},
	{
		garage_id: 0,
		part_id: 334,
		quantity: 376,
	},
	{
		garage_id: 0,
		part_id: 335,
		quantity: 106,
	},
	{
		garage_id: 0,
		part_id: 336,
		quantity: 152,
	},
	{
		garage_id: 0,
		part_id: 337,
		quantity: 401,
	},
	{
		garage_id: 0,
		part_id: 338,
		quantity: 481,
	},
	{
		garage_id: 0,
		part_id: 339,
		quantity: 490,
	},
	{
		garage_id: 0,
		part_id: 340,
		quantity: 48,
	},
	{
		garage_id: 0,
		part_id: 341,
		quantity: 191,
	},
	{
		garage_id: 0,
		part_id: 342,
		quantity: 249,
	},
	{
		garage_id: 0,
		part_id: 343,
		quantity: 371,
	},
	{
		garage_id: 0,
		part_id: 344,
		quantity: 303,
	},
	{
		garage_id: 0,
		part_id: 345,
		quantity: 51,
	},
	{
		garage_id: 0,
		part_id: 346,
		quantity: 313,
	},
	{
		garage_id: 0,
		part_id: 347,
		quantity: 446,
	},
	{
		garage_id: 0,
		part_id: 348,
		quantity: 71,
	},
	{
		garage_id: 0,
		part_id: 349,
		quantity: 302,
	},
	{
		garage_id: 0,
		part_id: 350,
		quantity: 41,
	},
	{
		garage_id: 0,
		part_id: 351,
		quantity: 89,
	},
	{
		garage_id: 0,
		part_id: 352,
		quantity: 216,
	},
	{
		garage_id: 0,
		part_id: 353,
		quantity: 284,
	},
	{
		garage_id: 0,
		part_id: 354,
		quantity: 137,
	},
	{
		garage_id: 0,
		part_id: 355,
		quantity: 250,
	},
	{
		garage_id: 0,
		part_id: 356,
		quantity: 358,
	},
	{
		garage_id: 0,
		part_id: 357,
		quantity: 56,
	},
	{
		garage_id: 0,
		part_id: 358,
		quantity: 198,
	},
	{
		garage_id: 0,
		part_id: 359,
		quantity: 323,
	},
	{
		garage_id: 0,
		part_id: 360,
		quantity: 251,
	},
	{
		garage_id: 0,
		part_id: 361,
		quantity: 53,
	},
	{
		garage_id: 0,
		part_id: 362,
		quantity: 37,
	},
	{
		garage_id: 0,
		part_id: 363,
		quantity: 19,
	},
	{
		garage_id: 0,
		part_id: 364,
		quantity: 20,
	},
	{
		garage_id: 0,
		part_id: 365,
		quantity: 231,
	},
	{
		garage_id: 0,
		part_id: 366,
		quantity: 314,
	},
	{
		garage_id: 0,
		part_id: 367,
		quantity: 287,
	},
	{
		garage_id: 0,
		part_id: 368,
		quantity: 181,
	},
	{
		garage_id: 0,
		part_id: 369,
		quantity: 39,
	},
	{
		garage_id: 0,
		part_id: 370,
		quantity: 457,
	},
	{
		garage_id: 0,
		part_id: 371,
		quantity: 294,
	},
	{
		garage_id: 0,
		part_id: 372,
		quantity: 91,
	},
	{
		garage_id: 0,
		part_id: 373,
		quantity: 392,
	},
	{
		garage_id: 0,
		part_id: 374,
		quantity: 442,
	},
	{
		garage_id: 0,
		part_id: 375,
		quantity: 466,
	},
	{
		garage_id: 0,
		part_id: 376,
		quantity: 328,
	},
	{
		garage_id: 0,
		part_id: 377,
		quantity: 53,
	},
	{
		garage_id: 0,
		part_id: 378,
		quantity: 275,
	},
	{
		garage_id: 0,
		part_id: 379,
		quantity: 30,
	},
	{
		garage_id: 0,
		part_id: 380,
		quantity: 137,
	},
	{
		garage_id: 0,
		part_id: 381,
		quantity: 436,
	},
	{
		garage_id: 0,
		part_id: 382,
		quantity: 122,
	},
	{
		garage_id: 0,
		part_id: 383,
		quantity: 297,
	},
	{
		garage_id: 0,
		part_id: 384,
		quantity: 403,
	},
	{
		garage_id: 0,
		part_id: 385,
		quantity: 302,
	},
	{
		garage_id: 0,
		part_id: 386,
		quantity: 223,
	},
	{
		garage_id: 0,
		part_id: 387,
		quantity: 298,
	},
	{
		garage_id: 0,
		part_id: 388,
		quantity: 80,
	},
	{
		garage_id: 0,
		part_id: 389,
		quantity: 323,
	},
	{
		garage_id: 0,
		part_id: 390,
		quantity: 250,
	},
	{
		garage_id: 0,
		part_id: 391,
		quantity: 414,
	},
	{
		garage_id: 0,
		part_id: 392,
		quantity: 60,
	},
	{
		garage_id: 0,
		part_id: 393,
		quantity: 228,
	},
	{
		garage_id: 0,
		part_id: 394,
		quantity: 223,
	},
	{
		garage_id: 0,
		part_id: 395,
		quantity: 296,
	},
	{
		garage_id: 0,
		part_id: 396,
		quantity: 100,
	},
	{
		garage_id: 0,
		part_id: 397,
		quantity: 131,
	},
	{
		garage_id: 0,
		part_id: 398,
		quantity: 111,
	},
	{
		garage_id: 0,
		part_id: 399,
		quantity: 57,
	},
	{
		garage_id: 1,
		part_id: 0,
		quantity: 452,
	},
	{
		garage_id: 1,
		part_id: 1,
		quantity: 244,
	},
	{
		garage_id: 1,
		part_id: 2,
		quantity: 188,
	},
	{
		garage_id: 1,
		part_id: 3,
		quantity: 72,
	},
	{
		garage_id: 1,
		part_id: 4,
		quantity: 115,
	},
	{
		garage_id: 1,
		part_id: 5,
		quantity: 16,
	},
	{
		garage_id: 1,
		part_id: 6,
		quantity: 102,
	},
	{
		garage_id: 1,
		part_id: 7,
		quantity: 372,
	},
	{
		garage_id: 1,
		part_id: 8,
		quantity: 472,
	},
	{
		garage_id: 1,
		part_id: 9,
		quantity: 189,
	},
	{
		garage_id: 1,
		part_id: 10,
		quantity: 342,
	},
	{
		garage_id: 1,
		part_id: 11,
		quantity: 78,
	},
	{
		garage_id: 1,
		part_id: 12,
		quantity: 213,
	},
	{
		garage_id: 1,
		part_id: 13,
		quantity: 356,
	},
	{
		garage_id: 1,
		part_id: 14,
		quantity: 4,
	},
	{
		garage_id: 1,
		part_id: 15,
		quantity: 95,
	},
	{
		garage_id: 1,
		part_id: 16,
		quantity: 465,
	},
	{
		garage_id: 1,
		part_id: 17,
		quantity: 401,
	},
	{
		garage_id: 1,
		part_id: 18,
		quantity: 140,
	},
	{
		garage_id: 1,
		part_id: 19,
		quantity: 257,
	},
	{
		garage_id: 1,
		part_id: 20,
		quantity: 220,
	},
	{
		garage_id: 1,
		part_id: 21,
		quantity: 310,
	},
	{
		garage_id: 1,
		part_id: 22,
		quantity: 195,
	},
	{
		garage_id: 1,
		part_id: 23,
		quantity: 208,
	},
	{
		garage_id: 1,
		part_id: 24,
		quantity: 467,
	},
	{
		garage_id: 1,
		part_id: 25,
		quantity: 122,
	},
	{
		garage_id: 1,
		part_id: 26,
		quantity: 338,
	},
	{
		garage_id: 1,
		part_id: 27,
		quantity: 177,
	},
	{
		garage_id: 1,
		part_id: 28,
		quantity: 462,
	},
	{
		garage_id: 1,
		part_id: 29,
		quantity: 254,
	},
	{
		garage_id: 1,
		part_id: 30,
		quantity: 100,
	},
	{
		garage_id: 1,
		part_id: 31,
		quantity: 386,
	},
	{
		garage_id: 1,
		part_id: 32,
		quantity: 159,
	},
	{
		garage_id: 1,
		part_id: 33,
		quantity: 396,
	},
	{
		garage_id: 1,
		part_id: 34,
		quantity: 375,
	},
	{
		garage_id: 1,
		part_id: 35,
		quantity: 214,
	},
	{
		garage_id: 1,
		part_id: 36,
		quantity: 73,
	},
	{
		garage_id: 1,
		part_id: 37,
		quantity: 119,
	},
	{
		garage_id: 1,
		part_id: 38,
		quantity: 94,
	},
	{
		garage_id: 1,
		part_id: 39,
		quantity: 400,
	},
	{
		garage_id: 1,
		part_id: 40,
		quantity: 497,
	},
	{
		garage_id: 1,
		part_id: 41,
		quantity: 453,
	},
	{
		garage_id: 1,
		part_id: 42,
		quantity: 344,
	},
	{
		garage_id: 1,
		part_id: 43,
		quantity: 383,
	},
	{
		garage_id: 1,
		part_id: 44,
		quantity: 44,
	},
	{
		garage_id: 1,
		part_id: 45,
		quantity: 202,
	},
	{
		garage_id: 1,
		part_id: 46,
		quantity: 270,
	},
	{
		garage_id: 1,
		part_id: 47,
		quantity: 484,
	},
	{
		garage_id: 1,
		part_id: 48,
		quantity: 401,
	},
	{
		garage_id: 1,
		part_id: 49,
		quantity: 86,
	},
	{
		garage_id: 1,
		part_id: 50,
		quantity: 147,
	},
	{
		garage_id: 1,
		part_id: 51,
		quantity: 471,
	},
	{
		garage_id: 1,
		part_id: 52,
		quantity: 394,
	},
	{
		garage_id: 1,
		part_id: 53,
		quantity: 50,
	},
	{
		garage_id: 1,
		part_id: 54,
		quantity: 298,
	},
	{
		garage_id: 1,
		part_id: 55,
		quantity: 225,
	},
	{
		garage_id: 1,
		part_id: 56,
		quantity: 168,
	},
	{
		garage_id: 1,
		part_id: 57,
		quantity: 127,
	},
	{
		garage_id: 1,
		part_id: 58,
		quantity: 108,
	},
	{
		garage_id: 1,
		part_id: 59,
		quantity: 448,
	},
	{
		garage_id: 1,
		part_id: 60,
		quantity: 70,
	},
	{
		garage_id: 1,
		part_id: 61,
		quantity: 285,
	},
	{
		garage_id: 1,
		part_id: 62,
		quantity: 400,
	},
	{
		garage_id: 1,
		part_id: 63,
		quantity: 420,
	},
	{
		garage_id: 1,
		part_id: 64,
		quantity: 484,
	},
	{
		garage_id: 1,
		part_id: 65,
		quantity: 142,
	},
	{
		garage_id: 1,
		part_id: 66,
		quantity: 16,
	},
	{
		garage_id: 1,
		part_id: 67,
		quantity: 299,
	},
	{
		garage_id: 1,
		part_id: 68,
		quantity: 484,
	},
	{
		garage_id: 1,
		part_id: 69,
		quantity: 113,
	},
	{
		garage_id: 1,
		part_id: 70,
		quantity: 492,
	},
	{
		garage_id: 1,
		part_id: 71,
		quantity: 308,
	},
	{
		garage_id: 1,
		part_id: 72,
		quantity: 371,
	},
	{
		garage_id: 1,
		part_id: 73,
		quantity: 131,
	},
	{
		garage_id: 1,
		part_id: 74,
		quantity: 73,
	},
	{
		garage_id: 1,
		part_id: 75,
		quantity: 403,
	},
	{
		garage_id: 1,
		part_id: 76,
		quantity: 124,
	},
	{
		garage_id: 1,
		part_id: 77,
		quantity: 186,
	},
	{
		garage_id: 1,
		part_id: 78,
		quantity: 420,
	},
	{
		garage_id: 1,
		part_id: 79,
		quantity: 15,
	},
	{
		garage_id: 1,
		part_id: 80,
		quantity: 107,
	},
	{
		garage_id: 1,
		part_id: 81,
		quantity: 117,
	},
	{
		garage_id: 1,
		part_id: 82,
		quantity: 96,
	},
	{
		garage_id: 1,
		part_id: 83,
		quantity: 216,
	},
	{
		garage_id: 1,
		part_id: 84,
		quantity: 166,
	},
	{
		garage_id: 1,
		part_id: 85,
		quantity: 455,
	},
	{
		garage_id: 1,
		part_id: 86,
		quantity: 439,
	},
	{
		garage_id: 1,
		part_id: 87,
		quantity: 208,
	},
	{
		garage_id: 1,
		part_id: 88,
		quantity: 140,
	},
	{
		garage_id: 1,
		part_id: 89,
		quantity: 45,
	},
	{
		garage_id: 1,
		part_id: 90,
		quantity: 276,
	},
	{
		garage_id: 1,
		part_id: 91,
		quantity: 446,
	},
	{
		garage_id: 1,
		part_id: 92,
		quantity: 451,
	},
	{
		garage_id: 1,
		part_id: 93,
		quantity: 63,
	},
	{
		garage_id: 1,
		part_id: 94,
		quantity: 196,
	},
	{
		garage_id: 1,
		part_id: 95,
		quantity: 494,
	},
	{
		garage_id: 1,
		part_id: 96,
		quantity: 78,
	},
	{
		garage_id: 1,
		part_id: 97,
		quantity: 106,
	},
	{
		garage_id: 1,
		part_id: 98,
		quantity: 2,
	},
	{
		garage_id: 1,
		part_id: 99,
		quantity: 473,
	},
	{
		garage_id: 1,
		part_id: 100,
		quantity: 304,
	},
	{
		garage_id: 1,
		part_id: 101,
		quantity: 84,
	},
	{
		garage_id: 1,
		part_id: 102,
		quantity: 231,
	},
	{
		garage_id: 1,
		part_id: 103,
		quantity: 20,
	},
	{
		garage_id: 1,
		part_id: 104,
		quantity: 255,
	},
	{
		garage_id: 1,
		part_id: 105,
		quantity: 261,
	},
	{
		garage_id: 1,
		part_id: 106,
		quantity: 459,
	},
	{
		garage_id: 1,
		part_id: 107,
		quantity: 325,
	},
	{
		garage_id: 1,
		part_id: 108,
		quantity: 424,
	},
	{
		garage_id: 1,
		part_id: 109,
		quantity: 346,
	},
	{
		garage_id: 1,
		part_id: 110,
		quantity: 334,
	},
	{
		garage_id: 1,
		part_id: 111,
		quantity: 198,
	},
	{
		garage_id: 1,
		part_id: 112,
		quantity: 272,
	},
	{
		garage_id: 1,
		part_id: 113,
		quantity: 305,
	},
	{
		garage_id: 1,
		part_id: 114,
		quantity: 41,
	},
	{
		garage_id: 1,
		part_id: 115,
		quantity: 22,
	},
	{
		garage_id: 1,
		part_id: 116,
		quantity: 398,
	},
	{
		garage_id: 1,
		part_id: 117,
		quantity: 349,
	},
	{
		garage_id: 1,
		part_id: 118,
		quantity: 5,
	},
	{
		garage_id: 1,
		part_id: 119,
		quantity: 286,
	},
	{
		garage_id: 1,
		part_id: 120,
		quantity: 403,
	},
	{
		garage_id: 1,
		part_id: 121,
		quantity: 237,
	},
	{
		garage_id: 1,
		part_id: 122,
		quantity: 81,
	},
	{
		garage_id: 1,
		part_id: 123,
		quantity: 308,
	},
	{
		garage_id: 1,
		part_id: 124,
		quantity: 388,
	},
	{
		garage_id: 1,
		part_id: 125,
		quantity: 92,
	},
	{
		garage_id: 1,
		part_id: 126,
		quantity: 131,
	},
	{
		garage_id: 1,
		part_id: 127,
		quantity: 400,
	},
	{
		garage_id: 1,
		part_id: 128,
		quantity: 68,
	},
	{
		garage_id: 1,
		part_id: 129,
		quantity: 296,
	},
	{
		garage_id: 1,
		part_id: 130,
		quantity: 294,
	},
	{
		garage_id: 1,
		part_id: 131,
		quantity: 298,
	},
	{
		garage_id: 1,
		part_id: 132,
		quantity: 365,
	},
	{
		garage_id: 1,
		part_id: 133,
		quantity: 24,
	},
	{
		garage_id: 1,
		part_id: 134,
		quantity: 451,
	},
	{
		garage_id: 1,
		part_id: 135,
		quantity: 441,
	},
	{
		garage_id: 1,
		part_id: 136,
		quantity: 411,
	},
	{
		garage_id: 1,
		part_id: 137,
		quantity: 194,
	},
	{
		garage_id: 1,
		part_id: 138,
		quantity: 102,
	},
	{
		garage_id: 1,
		part_id: 139,
		quantity: 371,
	},
	{
		garage_id: 1,
		part_id: 140,
		quantity: 410,
	},
	{
		garage_id: 1,
		part_id: 141,
		quantity: 413,
	},
	{
		garage_id: 1,
		part_id: 142,
		quantity: 292,
	},
	{
		garage_id: 1,
		part_id: 143,
		quantity: 424,
	},
	{
		garage_id: 1,
		part_id: 144,
		quantity: 214,
	},
	{
		garage_id: 1,
		part_id: 145,
		quantity: 250,
	},
	{
		garage_id: 1,
		part_id: 146,
		quantity: 231,
	},
	{
		garage_id: 1,
		part_id: 147,
		quantity: 114,
	},
	{
		garage_id: 1,
		part_id: 148,
		quantity: 306,
	},
	{
		garage_id: 1,
		part_id: 149,
		quantity: 441,
	},
	{
		garage_id: 1,
		part_id: 150,
		quantity: 90,
	},
	{
		garage_id: 1,
		part_id: 151,
		quantity: 402,
	},
	{
		garage_id: 1,
		part_id: 152,
		quantity: 332,
	},
	{
		garage_id: 1,
		part_id: 153,
		quantity: 114,
	},
	{
		garage_id: 1,
		part_id: 154,
		quantity: 334,
	},
	{
		garage_id: 1,
		part_id: 155,
		quantity: 478,
	},
	{
		garage_id: 1,
		part_id: 156,
		quantity: 421,
	},
	{
		garage_id: 1,
		part_id: 157,
		quantity: 93,
	},
	{
		garage_id: 1,
		part_id: 158,
		quantity: 327,
	},
	{
		garage_id: 1,
		part_id: 159,
		quantity: 171,
	},
	{
		garage_id: 1,
		part_id: 160,
		quantity: 60,
	},
	{
		garage_id: 1,
		part_id: 161,
		quantity: 63,
	},
	{
		garage_id: 1,
		part_id: 162,
		quantity: 85,
	},
	{
		garage_id: 1,
		part_id: 163,
		quantity: 212,
	},
	{
		garage_id: 1,
		part_id: 164,
		quantity: 398,
	},
	{
		garage_id: 1,
		part_id: 165,
		quantity: 258,
	},
	{
		garage_id: 1,
		part_id: 166,
		quantity: 346,
	},
	{
		garage_id: 1,
		part_id: 167,
		quantity: 109,
	},
	{
		garage_id: 1,
		part_id: 168,
		quantity: 123,
	},
	{
		garage_id: 1,
		part_id: 169,
		quantity: 400,
	},
	{
		garage_id: 1,
		part_id: 170,
		quantity: 47,
	},
	{
		garage_id: 1,
		part_id: 171,
		quantity: 447,
	},
	{
		garage_id: 1,
		part_id: 172,
		quantity: 227,
	},
	{
		garage_id: 1,
		part_id: 173,
		quantity: 410,
	},
	{
		garage_id: 1,
		part_id: 174,
		quantity: 358,
	},
	{
		garage_id: 1,
		part_id: 175,
		quantity: 144,
	},
	{
		garage_id: 1,
		part_id: 176,
		quantity: 117,
	},
	{
		garage_id: 1,
		part_id: 177,
		quantity: 22,
	},
	{
		garage_id: 1,
		part_id: 178,
		quantity: 44,
	},
	{
		garage_id: 1,
		part_id: 179,
		quantity: 249,
	},
	{
		garage_id: 1,
		part_id: 180,
		quantity: 37,
	},
	{
		garage_id: 1,
		part_id: 181,
		quantity: 413,
	},
	{
		garage_id: 1,
		part_id: 182,
		quantity: 440,
	},
	{
		garage_id: 1,
		part_id: 183,
		quantity: 421,
	},
	{
		garage_id: 1,
		part_id: 184,
		quantity: 291,
	},
	{
		garage_id: 1,
		part_id: 185,
		quantity: 305,
	},
	{
		garage_id: 1,
		part_id: 186,
		quantity: 440,
	},
	{
		garage_id: 1,
		part_id: 187,
		quantity: 368,
	},
	{
		garage_id: 1,
		part_id: 188,
		quantity: 35,
	},
	{
		garage_id: 1,
		part_id: 189,
		quantity: 429,
	},
	{
		garage_id: 1,
		part_id: 190,
		quantity: 22,
	},
	{
		garage_id: 1,
		part_id: 191,
		quantity: 208,
	},
	{
		garage_id: 1,
		part_id: 192,
		quantity: 164,
	},
	{
		garage_id: 1,
		part_id: 193,
		quantity: 32,
	},
	{
		garage_id: 1,
		part_id: 194,
		quantity: 379,
	},
	{
		garage_id: 1,
		part_id: 195,
		quantity: 357,
	},
	{
		garage_id: 1,
		part_id: 196,
		quantity: 240,
	},
	{
		garage_id: 1,
		part_id: 197,
		quantity: 373,
	},
	{
		garage_id: 1,
		part_id: 198,
		quantity: 428,
	},
	{
		garage_id: 1,
		part_id: 199,
		quantity: 154,
	},
	{
		garage_id: 1,
		part_id: 200,
		quantity: 174,
	},
	{
		garage_id: 1,
		part_id: 201,
		quantity: 428,
	},
	{
		garage_id: 1,
		part_id: 202,
		quantity: 426,
	},
	{
		garage_id: 1,
		part_id: 203,
		quantity: 283,
	},
	{
		garage_id: 1,
		part_id: 204,
		quantity: 20,
	},
	{
		garage_id: 1,
		part_id: 205,
		quantity: 314,
	},
	{
		garage_id: 1,
		part_id: 206,
		quantity: 401,
	},
	{
		garage_id: 1,
		part_id: 207,
		quantity: 311,
	},
	{
		garage_id: 1,
		part_id: 208,
		quantity: 240,
	},
	{
		garage_id: 1,
		part_id: 209,
		quantity: 153,
	},
	{
		garage_id: 1,
		part_id: 210,
		quantity: 445,
	},
	{
		garage_id: 1,
		part_id: 211,
		quantity: 461,
	},
	{
		garage_id: 1,
		part_id: 212,
		quantity: 43,
	},
	{
		garage_id: 1,
		part_id: 213,
		quantity: 455,
	},
	{
		garage_id: 1,
		part_id: 214,
		quantity: 216,
	},
	{
		garage_id: 1,
		part_id: 215,
		quantity: 145,
	},
	{
		garage_id: 1,
		part_id: 216,
		quantity: 379,
	},
	{
		garage_id: 1,
		part_id: 217,
		quantity: 312,
	},
	{
		garage_id: 1,
		part_id: 218,
		quantity: 89,
	},
	{
		garage_id: 1,
		part_id: 219,
		quantity: 229,
	},
	{
		garage_id: 1,
		part_id: 220,
		quantity: 116,
	},
	{
		garage_id: 1,
		part_id: 221,
		quantity: 12,
	},
	{
		garage_id: 1,
		part_id: 222,
		quantity: 175,
	},
	{
		garage_id: 1,
		part_id: 223,
		quantity: 479,
	},
	{
		garage_id: 1,
		part_id: 224,
		quantity: 176,
	},
	{
		garage_id: 1,
		part_id: 225,
		quantity: 299,
	},
	{
		garage_id: 1,
		part_id: 226,
		quantity: 107,
	},
	{
		garage_id: 1,
		part_id: 227,
		quantity: 58,
	},
	{
		garage_id: 1,
		part_id: 228,
		quantity: 314,
	},
	{
		garage_id: 1,
		part_id: 229,
		quantity: 146,
	},
	{
		garage_id: 1,
		part_id: 230,
		quantity: 64,
	},
	{
		garage_id: 1,
		part_id: 231,
		quantity: 464,
	},
	{
		garage_id: 1,
		part_id: 232,
		quantity: 472,
	},
	{
		garage_id: 1,
		part_id: 233,
		quantity: 210,
	},
	{
		garage_id: 1,
		part_id: 234,
		quantity: 28,
	},
	{
		garage_id: 1,
		part_id: 235,
		quantity: 324,
	},
	{
		garage_id: 1,
		part_id: 236,
		quantity: 74,
	},
	{
		garage_id: 1,
		part_id: 237,
		quantity: 53,
	},
	{
		garage_id: 1,
		part_id: 238,
		quantity: 283,
	},
	{
		garage_id: 1,
		part_id: 239,
		quantity: 48,
	},
	{
		garage_id: 1,
		part_id: 240,
		quantity: 371,
	},
	{
		garage_id: 1,
		part_id: 241,
		quantity: 91,
	},
	{
		garage_id: 1,
		part_id: 242,
		quantity: 301,
	},
	{
		garage_id: 1,
		part_id: 243,
		quantity: 300,
	},
	{
		garage_id: 1,
		part_id: 244,
		quantity: 186,
	},
	{
		garage_id: 1,
		part_id: 245,
		quantity: 334,
	},
	{
		garage_id: 1,
		part_id: 246,
		quantity: 238,
	},
	{
		garage_id: 1,
		part_id: 247,
		quantity: 441,
	},
	{
		garage_id: 1,
		part_id: 248,
		quantity: 263,
	},
	{
		garage_id: 1,
		part_id: 249,
		quantity: 181,
	},
	{
		garage_id: 1,
		part_id: 250,
		quantity: 281,
	},
	{
		garage_id: 1,
		part_id: 251,
		quantity: 314,
	},
	{
		garage_id: 1,
		part_id: 252,
		quantity: 469,
	},
	{
		garage_id: 1,
		part_id: 253,
		quantity: 2,
	},
	{
		garage_id: 1,
		part_id: 254,
		quantity: 18,
	},
	{
		garage_id: 1,
		part_id: 255,
		quantity: 349,
	},
	{
		garage_id: 1,
		part_id: 256,
		quantity: 38,
	},
	{
		garage_id: 1,
		part_id: 257,
		quantity: 215,
	},
	{
		garage_id: 1,
		part_id: 258,
		quantity: 231,
	},
	{
		garage_id: 1,
		part_id: 259,
		quantity: 287,
	},
	{
		garage_id: 1,
		part_id: 260,
		quantity: 8,
	},
	{
		garage_id: 1,
		part_id: 261,
		quantity: 490,
	},
	{
		garage_id: 1,
		part_id: 262,
		quantity: 419,
	},
	{
		garage_id: 1,
		part_id: 263,
		quantity: 225,
	},
	{
		garage_id: 1,
		part_id: 264,
		quantity: 104,
	},
	{
		garage_id: 1,
		part_id: 265,
		quantity: 311,
	},
	{
		garage_id: 1,
		part_id: 266,
		quantity: 173,
	},
	{
		garage_id: 1,
		part_id: 267,
		quantity: 485,
	},
	{
		garage_id: 1,
		part_id: 268,
		quantity: 205,
	},
	{
		garage_id: 1,
		part_id: 269,
		quantity: 324,
	},
	{
		garage_id: 1,
		part_id: 270,
		quantity: 387,
	},
	{
		garage_id: 1,
		part_id: 271,
		quantity: 74,
	},
	{
		garage_id: 1,
		part_id: 272,
		quantity: 114,
	},
	{
		garage_id: 1,
		part_id: 273,
		quantity: 387,
	},
	{
		garage_id: 1,
		part_id: 274,
		quantity: 431,
	},
	{
		garage_id: 1,
		part_id: 275,
		quantity: 395,
	},
	{
		garage_id: 1,
		part_id: 276,
		quantity: 277,
	},
	{
		garage_id: 1,
		part_id: 277,
		quantity: 49,
	},
	{
		garage_id: 1,
		part_id: 278,
		quantity: 399,
	},
	{
		garage_id: 1,
		part_id: 279,
		quantity: 85,
	},
	{
		garage_id: 1,
		part_id: 280,
		quantity: 494,
	},
	{
		garage_id: 1,
		part_id: 281,
		quantity: 240,
	},
	{
		garage_id: 1,
		part_id: 282,
		quantity: 386,
	},
	{
		garage_id: 1,
		part_id: 283,
		quantity: 349,
	},
	{
		garage_id: 1,
		part_id: 284,
		quantity: 406,
	},
	{
		garage_id: 1,
		part_id: 285,
		quantity: 253,
	},
	{
		garage_id: 1,
		part_id: 286,
		quantity: 277,
	},
	{
		garage_id: 1,
		part_id: 287,
		quantity: 136,
	},
	{
		garage_id: 1,
		part_id: 288,
		quantity: 494,
	},
	{
		garage_id: 1,
		part_id: 289,
		quantity: 404,
	},
	{
		garage_id: 1,
		part_id: 290,
		quantity: 25,
	},
	{
		garage_id: 1,
		part_id: 291,
		quantity: 57,
	},
	{
		garage_id: 1,
		part_id: 292,
		quantity: 492,
	},
	{
		garage_id: 1,
		part_id: 293,
		quantity: 203,
	},
	{
		garage_id: 1,
		part_id: 294,
		quantity: 392,
	},
	{
		garage_id: 1,
		part_id: 295,
		quantity: 379,
	},
	{
		garage_id: 1,
		part_id: 296,
		quantity: 286,
	},
	{
		garage_id: 1,
		part_id: 297,
		quantity: 485,
	},
	{
		garage_id: 1,
		part_id: 298,
		quantity: 257,
	},
	{
		garage_id: 1,
		part_id: 299,
		quantity: 356,
	},
	{
		garage_id: 1,
		part_id: 300,
		quantity: 315,
	},
	{
		garage_id: 1,
		part_id: 301,
		quantity: 434,
	},
	{
		garage_id: 1,
		part_id: 302,
		quantity: 247,
	},
	{
		garage_id: 1,
		part_id: 303,
		quantity: 459,
	},
	{
		garage_id: 1,
		part_id: 304,
		quantity: 73,
	},
	{
		garage_id: 1,
		part_id: 305,
		quantity: 351,
	},
	{
		garage_id: 1,
		part_id: 306,
		quantity: 399,
	},
	{
		garage_id: 1,
		part_id: 307,
		quantity: 228,
	},
	{
		garage_id: 1,
		part_id: 308,
		quantity: 158,
	},
	{
		garage_id: 1,
		part_id: 309,
		quantity: 275,
	},
	{
		garage_id: 1,
		part_id: 310,
		quantity: 182,
	},
	{
		garage_id: 1,
		part_id: 311,
		quantity: 194,
	},
	{
		garage_id: 1,
		part_id: 312,
		quantity: 41,
	},
	{
		garage_id: 1,
		part_id: 313,
		quantity: 112,
	},
	{
		garage_id: 1,
		part_id: 314,
		quantity: 198,
	},
	{
		garage_id: 1,
		part_id: 315,
		quantity: 196,
	},
	{
		garage_id: 1,
		part_id: 316,
		quantity: 82,
	},
	{
		garage_id: 1,
		part_id: 317,
		quantity: 210,
	},
	{
		garage_id: 1,
		part_id: 318,
		quantity: 90,
	},
	{
		garage_id: 1,
		part_id: 319,
		quantity: 287,
	},
	{
		garage_id: 1,
		part_id: 320,
		quantity: 226,
	},
	{
		garage_id: 1,
		part_id: 321,
		quantity: 97,
	},
	{
		garage_id: 1,
		part_id: 322,
		quantity: 151,
	},
	{
		garage_id: 1,
		part_id: 323,
		quantity: 464,
	},
	{
		garage_id: 1,
		part_id: 324,
		quantity: 144,
	},
	{
		garage_id: 1,
		part_id: 325,
		quantity: 276,
	},
	{
		garage_id: 1,
		part_id: 326,
		quantity: 238,
	},
	{
		garage_id: 1,
		part_id: 327,
		quantity: 200,
	},
	{
		garage_id: 1,
		part_id: 328,
		quantity: 401,
	},
	{
		garage_id: 1,
		part_id: 329,
		quantity: 67,
	},
	{
		garage_id: 1,
		part_id: 330,
		quantity: 44,
	},
	{
		garage_id: 1,
		part_id: 331,
		quantity: 289,
	},
	{
		garage_id: 1,
		part_id: 332,
		quantity: 464,
	},
	{
		garage_id: 1,
		part_id: 333,
		quantity: 491,
	},
	{
		garage_id: 1,
		part_id: 334,
		quantity: 346,
	},
	{
		garage_id: 1,
		part_id: 335,
		quantity: 267,
	},
	{
		garage_id: 1,
		part_id: 336,
		quantity: 67,
	},
	{
		garage_id: 1,
		part_id: 337,
		quantity: 395,
	},
	{
		garage_id: 1,
		part_id: 338,
		quantity: 38,
	},
	{
		garage_id: 1,
		part_id: 339,
		quantity: 42,
	},
	{
		garage_id: 1,
		part_id: 340,
		quantity: 346,
	},
	{
		garage_id: 1,
		part_id: 341,
		quantity: 491,
	},
	{
		garage_id: 1,
		part_id: 342,
		quantity: 77,
	},
	{
		garage_id: 1,
		part_id: 343,
		quantity: 51,
	},
	{
		garage_id: 1,
		part_id: 344,
		quantity: 252,
	},
	{
		garage_id: 1,
		part_id: 345,
		quantity: 430,
	},
	{
		garage_id: 1,
		part_id: 346,
		quantity: 258,
	},
	{
		garage_id: 1,
		part_id: 347,
		quantity: 480,
	},
	{
		garage_id: 1,
		part_id: 348,
		quantity: 172,
	},
	{
		garage_id: 1,
		part_id: 349,
		quantity: 301,
	},
	{
		garage_id: 1,
		part_id: 350,
		quantity: 366,
	},
	{
		garage_id: 1,
		part_id: 351,
		quantity: 5,
	},
	{
		garage_id: 1,
		part_id: 352,
		quantity: 341,
	},
	{
		garage_id: 1,
		part_id: 353,
		quantity: 358,
	},
	{
		garage_id: 1,
		part_id: 354,
		quantity: 50,
	},
	{
		garage_id: 1,
		part_id: 355,
		quantity: 68,
	},
	{
		garage_id: 1,
		part_id: 356,
		quantity: 432,
	},
	{
		garage_id: 1,
		part_id: 357,
		quantity: 207,
	},
	{
		garage_id: 1,
		part_id: 358,
		quantity: 339,
	},
	{
		garage_id: 1,
		part_id: 359,
		quantity: 5,
	},
	{
		garage_id: 1,
		part_id: 360,
		quantity: 75,
	},
	{
		garage_id: 1,
		part_id: 361,
		quantity: 163,
	},
	{
		garage_id: 1,
		part_id: 362,
		quantity: 111,
	},
	{
		garage_id: 1,
		part_id: 363,
		quantity: 80,
	},
	{
		garage_id: 1,
		part_id: 364,
		quantity: 407,
	},
	{
		garage_id: 1,
		part_id: 365,
		quantity: 137,
	},
	{
		garage_id: 1,
		part_id: 366,
		quantity: 282,
	},
	{
		garage_id: 1,
		part_id: 367,
		quantity: 148,
	},
	{
		garage_id: 1,
		part_id: 368,
		quantity: 8,
	},
	{
		garage_id: 1,
		part_id: 369,
		quantity: 179,
	},
	{
		garage_id: 1,
		part_id: 370,
		quantity: 299,
	},
	{
		garage_id: 1,
		part_id: 371,
		quantity: 386,
	},
	{
		garage_id: 1,
		part_id: 372,
		quantity: 301,
	},
	{
		garage_id: 1,
		part_id: 373,
		quantity: 439,
	},
	{
		garage_id: 1,
		part_id: 374,
		quantity: 61,
	},
	{
		garage_id: 1,
		part_id: 375,
		quantity: 130,
	},
	{
		garage_id: 1,
		part_id: 376,
		quantity: 255,
	},
	{
		garage_id: 1,
		part_id: 377,
		quantity: 454,
	},
	{
		garage_id: 1,
		part_id: 378,
		quantity: 14,
	},
	{
		garage_id: 1,
		part_id: 379,
		quantity: 303,
	},
	{
		garage_id: 1,
		part_id: 380,
		quantity: 482,
	},
	{
		garage_id: 1,
		part_id: 381,
		quantity: 391,
	},
	{
		garage_id: 1,
		part_id: 382,
		quantity: 76,
	},
	{
		garage_id: 1,
		part_id: 383,
		quantity: 380,
	},
	{
		garage_id: 1,
		part_id: 384,
		quantity: 203,
	},
	{
		garage_id: 1,
		part_id: 385,
		quantity: 476,
	},
	{
		garage_id: 1,
		part_id: 386,
		quantity: 191,
	},
	{
		garage_id: 1,
		part_id: 387,
		quantity: 28,
	},
	{
		garage_id: 1,
		part_id: 388,
		quantity: 193,
	},
	{
		garage_id: 1,
		part_id: 389,
		quantity: 141,
	},
	{
		garage_id: 1,
		part_id: 390,
		quantity: 394,
	},
	{
		garage_id: 1,
		part_id: 391,
		quantity: 277,
	},
	{
		garage_id: 1,
		part_id: 392,
		quantity: 419,
	},
	{
		garage_id: 1,
		part_id: 393,
		quantity: 457,
	},
	{
		garage_id: 1,
		part_id: 394,
		quantity: 76,
	},
	{
		garage_id: 1,
		part_id: 395,
		quantity: 478,
	},
	{
		garage_id: 1,
		part_id: 396,
		quantity: 102,
	},
	{
		garage_id: 1,
		part_id: 397,
		quantity: 345,
	},
	{
		garage_id: 1,
		part_id: 398,
		quantity: 252,
	},
	{
		garage_id: 1,
		part_id: 399,
		quantity: 310,
	},
	{
		garage_id: 2,
		part_id: 0,
		quantity: 192,
	},
	{
		garage_id: 2,
		part_id: 1,
		quantity: 48,
	},
	{
		garage_id: 2,
		part_id: 2,
		quantity: 491,
	},
	{
		garage_id: 2,
		part_id: 3,
		quantity: 442,
	},
	{
		garage_id: 2,
		part_id: 4,
		quantity: 353,
	},
	{
		garage_id: 2,
		part_id: 5,
		quantity: 113,
	},
	{
		garage_id: 2,
		part_id: 6,
		quantity: 190,
	},
	{
		garage_id: 2,
		part_id: 7,
		quantity: 251,
	},
	{
		garage_id: 2,
		part_id: 8,
		quantity: 264,
	},
	{
		garage_id: 2,
		part_id: 9,
		quantity: 92,
	},
	{
		garage_id: 2,
		part_id: 10,
		quantity: 208,
	},
	{
		garage_id: 2,
		part_id: 11,
		quantity: 377,
	},
	{
		garage_id: 2,
		part_id: 12,
		quantity: 487,
	},
	{
		garage_id: 2,
		part_id: 13,
		quantity: 43,
	},
	{
		garage_id: 2,
		part_id: 14,
		quantity: 354,
	},
	{
		garage_id: 2,
		part_id: 15,
		quantity: 479,
	},
	{
		garage_id: 2,
		part_id: 16,
		quantity: 500,
	},
	{
		garage_id: 2,
		part_id: 17,
		quantity: 428,
	},
	{
		garage_id: 2,
		part_id: 18,
		quantity: 286,
	},
	{
		garage_id: 2,
		part_id: 19,
		quantity: 99,
	},
	{
		garage_id: 2,
		part_id: 20,
		quantity: 82,
	},
	{
		garage_id: 2,
		part_id: 21,
		quantity: 295,
	},
	{
		garage_id: 2,
		part_id: 22,
		quantity: 146,
	},
	{
		garage_id: 2,
		part_id: 23,
		quantity: 41,
	},
	{
		garage_id: 2,
		part_id: 24,
		quantity: 38,
	},
	{
		garage_id: 2,
		part_id: 25,
		quantity: 46,
	},
	{
		garage_id: 2,
		part_id: 26,
		quantity: 365,
	},
	{
		garage_id: 2,
		part_id: 27,
		quantity: 394,
	},
	{
		garage_id: 2,
		part_id: 28,
		quantity: 413,
	},
	{
		garage_id: 2,
		part_id: 29,
		quantity: 379,
	},
	{
		garage_id: 2,
		part_id: 30,
		quantity: 210,
	},
	{
		garage_id: 2,
		part_id: 31,
		quantity: 270,
	},
	{
		garage_id: 2,
		part_id: 32,
		quantity: 216,
	},
	{
		garage_id: 2,
		part_id: 33,
		quantity: 196,
	},
	{
		garage_id: 2,
		part_id: 34,
		quantity: 479,
	},
	{
		garage_id: 2,
		part_id: 35,
		quantity: 327,
	},
	{
		garage_id: 2,
		part_id: 36,
		quantity: 472,
	},
	{
		garage_id: 2,
		part_id: 37,
		quantity: 405,
	},
	{
		garage_id: 2,
		part_id: 38,
		quantity: 313,
	},
	{
		garage_id: 2,
		part_id: 39,
		quantity: 499,
	},
	{
		garage_id: 2,
		part_id: 40,
		quantity: 268,
	},
	{
		garage_id: 2,
		part_id: 41,
		quantity: 371,
	},
	{
		garage_id: 2,
		part_id: 42,
		quantity: 309,
	},
	{
		garage_id: 2,
		part_id: 43,
		quantity: 13,
	},
	{
		garage_id: 2,
		part_id: 44,
		quantity: 289,
	},
	{
		garage_id: 2,
		part_id: 45,
		quantity: 158,
	},
	{
		garage_id: 2,
		part_id: 46,
		quantity: 441,
	},
	{
		garage_id: 2,
		part_id: 47,
		quantity: 306,
	},
	{
		garage_id: 2,
		part_id: 48,
		quantity: 435,
	},
	{
		garage_id: 2,
		part_id: 49,
		quantity: 178,
	},
	{
		garage_id: 2,
		part_id: 50,
		quantity: 406,
	},
	{
		garage_id: 2,
		part_id: 51,
		quantity: 420,
	},
	{
		garage_id: 2,
		part_id: 52,
		quantity: 360,
	},
	{
		garage_id: 2,
		part_id: 53,
		quantity: 39,
	},
	{
		garage_id: 2,
		part_id: 54,
		quantity: 221,
	},
	{
		garage_id: 2,
		part_id: 55,
		quantity: 261,
	},
	{
		garage_id: 2,
		part_id: 56,
		quantity: 374,
	},
	{
		garage_id: 2,
		part_id: 57,
		quantity: 26,
	},
	{
		garage_id: 2,
		part_id: 58,
		quantity: 418,
	},
	{
		garage_id: 2,
		part_id: 59,
		quantity: 438,
	},
	{
		garage_id: 2,
		part_id: 60,
		quantity: 153,
	},
	{
		garage_id: 2,
		part_id: 61,
		quantity: 11,
	},
	{
		garage_id: 2,
		part_id: 62,
		quantity: 176,
	},
	{
		garage_id: 2,
		part_id: 63,
		quantity: 20,
	},
	{
		garage_id: 2,
		part_id: 64,
		quantity: 497,
	},
	{
		garage_id: 2,
		part_id: 65,
		quantity: 360,
	},
	{
		garage_id: 2,
		part_id: 66,
		quantity: 313,
	},
	{
		garage_id: 2,
		part_id: 67,
		quantity: 85,
	},
	{
		garage_id: 2,
		part_id: 68,
		quantity: 362,
	},
	{
		garage_id: 2,
		part_id: 69,
		quantity: 81,
	},
	{
		garage_id: 2,
		part_id: 70,
		quantity: 250,
	},
	{
		garage_id: 2,
		part_id: 71,
		quantity: 490,
	},
	{
		garage_id: 2,
		part_id: 72,
		quantity: 231,
	},
	{
		garage_id: 2,
		part_id: 73,
		quantity: 86,
	},
	{
		garage_id: 2,
		part_id: 74,
		quantity: 273,
	},
	{
		garage_id: 2,
		part_id: 75,
		quantity: 200,
	},
	{
		garage_id: 2,
		part_id: 76,
		quantity: 496,
	},
	{
		garage_id: 2,
		part_id: 77,
		quantity: 223,
	},
	{
		garage_id: 2,
		part_id: 78,
		quantity: 127,
	},
	{
		garage_id: 2,
		part_id: 79,
		quantity: 433,
	},
	{
		garage_id: 2,
		part_id: 80,
		quantity: 452,
	},
	{
		garage_id: 2,
		part_id: 81,
		quantity: 397,
	},
	{
		garage_id: 2,
		part_id: 82,
		quantity: 146,
	},
	{
		garage_id: 2,
		part_id: 83,
		quantity: 180,
	},
	{
		garage_id: 2,
		part_id: 84,
		quantity: 456,
	},
	{
		garage_id: 2,
		part_id: 85,
		quantity: 327,
	},
	{
		garage_id: 2,
		part_id: 86,
		quantity: 117,
	},
	{
		garage_id: 2,
		part_id: 87,
		quantity: 480,
	},
	{
		garage_id: 2,
		part_id: 88,
		quantity: 16,
	},
	{
		garage_id: 2,
		part_id: 89,
		quantity: 4,
	},
	{
		garage_id: 2,
		part_id: 90,
		quantity: 420,
	},
	{
		garage_id: 2,
		part_id: 91,
		quantity: 140,
	},
	{
		garage_id: 2,
		part_id: 92,
		quantity: 252,
	},
	{
		garage_id: 2,
		part_id: 93,
		quantity: 23,
	},
	{
		garage_id: 2,
		part_id: 94,
		quantity: 112,
	},
	{
		garage_id: 2,
		part_id: 95,
		quantity: 404,
	},
	{
		garage_id: 2,
		part_id: 96,
		quantity: 62,
	},
	{
		garage_id: 2,
		part_id: 97,
		quantity: 365,
	},
	{
		garage_id: 2,
		part_id: 98,
		quantity: 292,
	},
	{
		garage_id: 2,
		part_id: 99,
		quantity: 144,
	},
	{
		garage_id: 2,
		part_id: 100,
		quantity: 354,
	},
	{
		garage_id: 2,
		part_id: 101,
		quantity: 471,
	},
	{
		garage_id: 2,
		part_id: 102,
		quantity: 54,
	},
	{
		garage_id: 2,
		part_id: 103,
		quantity: 194,
	},
	{
		garage_id: 2,
		part_id: 104,
		quantity: 154,
	},
	{
		garage_id: 2,
		part_id: 105,
		quantity: 370,
	},
	{
		garage_id: 2,
		part_id: 106,
		quantity: 369,
	},
	{
		garage_id: 2,
		part_id: 107,
		quantity: 139,
	},
	{
		garage_id: 2,
		part_id: 108,
		quantity: 133,
	},
	{
		garage_id: 2,
		part_id: 109,
		quantity: 486,
	},
	{
		garage_id: 2,
		part_id: 110,
		quantity: 99,
	},
	{
		garage_id: 2,
		part_id: 111,
		quantity: 116,
	},
	{
		garage_id: 2,
		part_id: 112,
		quantity: 428,
	},
	{
		garage_id: 2,
		part_id: 113,
		quantity: 424,
	},
	{
		garage_id: 2,
		part_id: 114,
		quantity: 167,
	},
	{
		garage_id: 2,
		part_id: 115,
		quantity: 281,
	},
	{
		garage_id: 2,
		part_id: 116,
		quantity: 421,
	},
	{
		garage_id: 2,
		part_id: 117,
		quantity: 395,
	},
	{
		garage_id: 2,
		part_id: 118,
		quantity: 152,
	},
	{
		garage_id: 2,
		part_id: 119,
		quantity: 95,
	},
	{
		garage_id: 2,
		part_id: 120,
		quantity: 154,
	},
	{
		garage_id: 2,
		part_id: 121,
		quantity: 220,
	},
	{
		garage_id: 2,
		part_id: 122,
		quantity: 225,
	},
	{
		garage_id: 2,
		part_id: 123,
		quantity: 378,
	},
	{
		garage_id: 2,
		part_id: 124,
		quantity: 29,
	},
	{
		garage_id: 2,
		part_id: 125,
		quantity: 408,
	},
	{
		garage_id: 2,
		part_id: 126,
		quantity: 401,
	},
	{
		garage_id: 2,
		part_id: 127,
		quantity: 281,
	},
	{
		garage_id: 2,
		part_id: 128,
		quantity: 461,
	},
	{
		garage_id: 2,
		part_id: 129,
		quantity: 140,
	},
	{
		garage_id: 2,
		part_id: 130,
		quantity: 360,
	},
	{
		garage_id: 2,
		part_id: 131,
		quantity: 160,
	},
	{
		garage_id: 2,
		part_id: 132,
		quantity: 6,
	},
	{
		garage_id: 2,
		part_id: 133,
		quantity: 140,
	},
	{
		garage_id: 2,
		part_id: 134,
		quantity: 121,
	},
	{
		garage_id: 2,
		part_id: 135,
		quantity: 249,
	},
	{
		garage_id: 2,
		part_id: 136,
		quantity: 415,
	},
	{
		garage_id: 2,
		part_id: 137,
		quantity: 101,
	},
	{
		garage_id: 2,
		part_id: 138,
		quantity: 468,
	},
	{
		garage_id: 2,
		part_id: 139,
		quantity: 247,
	},
	{
		garage_id: 2,
		part_id: 140,
		quantity: 35,
	},
	{
		garage_id: 2,
		part_id: 141,
		quantity: 336,
	},
	{
		garage_id: 2,
		part_id: 142,
		quantity: 64,
	},
	{
		garage_id: 2,
		part_id: 143,
		quantity: 167,
	},
	{
		garage_id: 2,
		part_id: 144,
		quantity: 203,
	},
	{
		garage_id: 2,
		part_id: 145,
		quantity: 438,
	},
	{
		garage_id: 2,
		part_id: 146,
		quantity: 487,
	},
	{
		garage_id: 2,
		part_id: 147,
		quantity: 294,
	},
	{
		garage_id: 2,
		part_id: 148,
		quantity: 370,
	},
	{
		garage_id: 2,
		part_id: 149,
		quantity: 462,
	},
	{
		garage_id: 2,
		part_id: 150,
		quantity: 279,
	},
	{
		garage_id: 2,
		part_id: 151,
		quantity: 43,
	},
	{
		garage_id: 2,
		part_id: 152,
		quantity: 50,
	},
	{
		garage_id: 2,
		part_id: 153,
		quantity: 5,
	},
	{
		garage_id: 2,
		part_id: 154,
		quantity: 362,
	},
	{
		garage_id: 2,
		part_id: 155,
		quantity: 128,
	},
	{
		garage_id: 2,
		part_id: 156,
		quantity: 240,
	},
	{
		garage_id: 2,
		part_id: 157,
		quantity: 327,
	},
	{
		garage_id: 2,
		part_id: 158,
		quantity: 148,
	},
	{
		garage_id: 2,
		part_id: 159,
		quantity: 415,
	},
	{
		garage_id: 2,
		part_id: 160,
		quantity: 275,
	},
	{
		garage_id: 2,
		part_id: 161,
		quantity: 417,
	},
	{
		garage_id: 2,
		part_id: 162,
		quantity: 173,
	},
	{
		garage_id: 2,
		part_id: 163,
		quantity: 442,
	},
	{
		garage_id: 2,
		part_id: 164,
		quantity: 458,
	},
	{
		garage_id: 2,
		part_id: 165,
		quantity: 121,
	},
	{
		garage_id: 2,
		part_id: 166,
		quantity: 393,
	},
	{
		garage_id: 2,
		part_id: 167,
		quantity: 54,
	},
	{
		garage_id: 2,
		part_id: 168,
		quantity: 261,
	},
	{
		garage_id: 2,
		part_id: 169,
		quantity: 457,
	},
	{
		garage_id: 2,
		part_id: 170,
		quantity: 65,
	},
	{
		garage_id: 2,
		part_id: 171,
		quantity: 154,
	},
	{
		garage_id: 2,
		part_id: 172,
		quantity: 356,
	},
	{
		garage_id: 2,
		part_id: 173,
		quantity: 212,
	},
	{
		garage_id: 2,
		part_id: 174,
		quantity: 406,
	},
	{
		garage_id: 2,
		part_id: 175,
		quantity: 357,
	},
	{
		garage_id: 2,
		part_id: 176,
		quantity: 423,
	},
	{
		garage_id: 2,
		part_id: 177,
		quantity: 241,
	},
	{
		garage_id: 2,
		part_id: 178,
		quantity: 30,
	},
	{
		garage_id: 2,
		part_id: 179,
		quantity: 52,
	},
	{
		garage_id: 2,
		part_id: 180,
		quantity: 234,
	},
	{
		garage_id: 2,
		part_id: 181,
		quantity: 257,
	},
	{
		garage_id: 2,
		part_id: 182,
		quantity: 287,
	},
	{
		garage_id: 2,
		part_id: 183,
		quantity: 50,
	},
	{
		garage_id: 2,
		part_id: 184,
		quantity: 118,
	},
	{
		garage_id: 2,
		part_id: 185,
		quantity: 464,
	},
	{
		garage_id: 2,
		part_id: 186,
		quantity: 499,
	},
	{
		garage_id: 2,
		part_id: 187,
		quantity: 411,
	},
	{
		garage_id: 2,
		part_id: 188,
		quantity: 183,
	},
	{
		garage_id: 2,
		part_id: 189,
		quantity: 321,
	},
	{
		garage_id: 2,
		part_id: 190,
		quantity: 135,
	},
	{
		garage_id: 2,
		part_id: 191,
		quantity: 111,
	},
	{
		garage_id: 2,
		part_id: 192,
		quantity: 69,
	},
	{
		garage_id: 2,
		part_id: 193,
		quantity: 40,
	},
	{
		garage_id: 2,
		part_id: 194,
		quantity: 273,
	},
	{
		garage_id: 2,
		part_id: 195,
		quantity: 436,
	},
	{
		garage_id: 2,
		part_id: 196,
		quantity: 394,
	},
	{
		garage_id: 2,
		part_id: 197,
		quantity: 37,
	},
	{
		garage_id: 2,
		part_id: 198,
		quantity: 421,
	},
	{
		garage_id: 2,
		part_id: 199,
		quantity: 67,
	},
	{
		garage_id: 2,
		part_id: 200,
		quantity: 215,
	},
	{
		garage_id: 2,
		part_id: 201,
		quantity: 148,
	},
	{
		garage_id: 2,
		part_id: 202,
		quantity: 57,
	},
	{
		garage_id: 2,
		part_id: 203,
		quantity: 177,
	},
	{
		garage_id: 2,
		part_id: 204,
		quantity: 22,
	},
	{
		garage_id: 2,
		part_id: 205,
		quantity: 458,
	},
	{
		garage_id: 2,
		part_id: 206,
		quantity: 233,
	},
	{
		garage_id: 2,
		part_id: 207,
		quantity: 313,
	},
	{
		garage_id: 2,
		part_id: 208,
		quantity: 168,
	},
	{
		garage_id: 2,
		part_id: 209,
		quantity: 209,
	},
	{
		garage_id: 2,
		part_id: 210,
		quantity: 250,
	},
	{
		garage_id: 2,
		part_id: 211,
		quantity: 439,
	},
	{
		garage_id: 2,
		part_id: 212,
		quantity: 391,
	},
	{
		garage_id: 2,
		part_id: 213,
		quantity: 491,
	},
	{
		garage_id: 2,
		part_id: 214,
		quantity: 419,
	},
	{
		garage_id: 2,
		part_id: 215,
		quantity: 482,
	},
	{
		garage_id: 2,
		part_id: 216,
		quantity: 184,
	},
	{
		garage_id: 2,
		part_id: 217,
		quantity: 324,
	},
	{
		garage_id: 2,
		part_id: 218,
		quantity: 325,
	},
	{
		garage_id: 2,
		part_id: 219,
		quantity: 237,
	},
	{
		garage_id: 2,
		part_id: 220,
		quantity: 218,
	},
	{
		garage_id: 2,
		part_id: 221,
		quantity: 291,
	},
	{
		garage_id: 2,
		part_id: 222,
		quantity: 396,
	},
	{
		garage_id: 2,
		part_id: 223,
		quantity: 487,
	},
	{
		garage_id: 2,
		part_id: 224,
		quantity: 35,
	},
	{
		garage_id: 2,
		part_id: 225,
		quantity: 426,
	},
	{
		garage_id: 2,
		part_id: 226,
		quantity: 352,
	},
	{
		garage_id: 2,
		part_id: 227,
		quantity: 91,
	},
	{
		garage_id: 2,
		part_id: 228,
		quantity: 23,
	},
	{
		garage_id: 2,
		part_id: 229,
		quantity: 53,
	},
	{
		garage_id: 2,
		part_id: 230,
		quantity: 115,
	},
	{
		garage_id: 2,
		part_id: 231,
		quantity: 445,
	},
	{
		garage_id: 2,
		part_id: 232,
		quantity: 168,
	},
	{
		garage_id: 2,
		part_id: 233,
		quantity: 260,
	},
	{
		garage_id: 2,
		part_id: 234,
		quantity: 110,
	},
	{
		garage_id: 2,
		part_id: 235,
		quantity: 148,
	},
	{
		garage_id: 2,
		part_id: 236,
		quantity: 82,
	},
	{
		garage_id: 2,
		part_id: 237,
		quantity: 73,
	},
	{
		garage_id: 2,
		part_id: 238,
		quantity: 448,
	},
	{
		garage_id: 2,
		part_id: 239,
		quantity: 292,
	},
	{
		garage_id: 2,
		part_id: 240,
		quantity: 260,
	},
	{
		garage_id: 2,
		part_id: 241,
		quantity: 73,
	},
	{
		garage_id: 2,
		part_id: 242,
		quantity: 470,
	},
	{
		garage_id: 2,
		part_id: 243,
		quantity: 195,
	},
	{
		garage_id: 2,
		part_id: 244,
		quantity: 400,
	},
	{
		garage_id: 2,
		part_id: 245,
		quantity: 348,
	},
	{
		garage_id: 2,
		part_id: 246,
		quantity: 305,
	},
	{
		garage_id: 2,
		part_id: 247,
		quantity: 209,
	},
	{
		garage_id: 2,
		part_id: 248,
		quantity: 49,
	},
	{
		garage_id: 2,
		part_id: 249,
		quantity: 303,
	},
	{
		garage_id: 2,
		part_id: 250,
		quantity: 334,
	},
	{
		garage_id: 2,
		part_id: 251,
		quantity: 164,
	},
	{
		garage_id: 2,
		part_id: 252,
		quantity: 440,
	},
	{
		garage_id: 2,
		part_id: 253,
		quantity: 454,
	},
	{
		garage_id: 2,
		part_id: 254,
		quantity: 320,
	},
	{
		garage_id: 2,
		part_id: 255,
		quantity: 87,
	},
	{
		garage_id: 2,
		part_id: 256,
		quantity: 170,
	},
	{
		garage_id: 2,
		part_id: 257,
		quantity: 130,
	},
	{
		garage_id: 2,
		part_id: 258,
		quantity: 191,
	},
	{
		garage_id: 2,
		part_id: 259,
		quantity: 479,
	},
	{
		garage_id: 2,
		part_id: 260,
		quantity: 268,
	},
	{
		garage_id: 2,
		part_id: 261,
		quantity: 225,
	},
	{
		garage_id: 2,
		part_id: 262,
		quantity: 71,
	},
	{
		garage_id: 2,
		part_id: 263,
		quantity: 259,
	},
	{
		garage_id: 2,
		part_id: 264,
		quantity: 51,
	},
	{
		garage_id: 2,
		part_id: 265,
		quantity: 98,
	},
	{
		garage_id: 2,
		part_id: 266,
		quantity: 220,
	},
	{
		garage_id: 2,
		part_id: 267,
		quantity: 65,
	},
	{
		garage_id: 2,
		part_id: 268,
		quantity: 488,
	},
	{
		garage_id: 2,
		part_id: 269,
		quantity: 75,
	},
	{
		garage_id: 2,
		part_id: 270,
		quantity: 225,
	},
	{
		garage_id: 2,
		part_id: 271,
		quantity: 485,
	},
	{
		garage_id: 2,
		part_id: 272,
		quantity: 217,
	},
	{
		garage_id: 2,
		part_id: 273,
		quantity: 238,
	},
	{
		garage_id: 2,
		part_id: 274,
		quantity: 295,
	},
	{
		garage_id: 2,
		part_id: 275,
		quantity: 87,
	},
	{
		garage_id: 2,
		part_id: 276,
		quantity: 228,
	},
	{
		garage_id: 2,
		part_id: 277,
		quantity: 365,
	},
	{
		garage_id: 2,
		part_id: 278,
		quantity: 294,
	},
	{
		garage_id: 2,
		part_id: 279,
		quantity: 23,
	},
	{
		garage_id: 2,
		part_id: 280,
		quantity: 491,
	},
	{
		garage_id: 2,
		part_id: 281,
		quantity: 343,
	},
	{
		garage_id: 2,
		part_id: 282,
		quantity: 172,
	},
	{
		garage_id: 2,
		part_id: 283,
		quantity: 480,
	},
	{
		garage_id: 2,
		part_id: 284,
		quantity: 131,
	},
	{
		garage_id: 2,
		part_id: 285,
		quantity: 37,
	},
	{
		garage_id: 2,
		part_id: 286,
		quantity: 465,
	},
	{
		garage_id: 2,
		part_id: 287,
		quantity: 108,
	},
	{
		garage_id: 2,
		part_id: 288,
		quantity: 451,
	},
	{
		garage_id: 2,
		part_id: 289,
		quantity: 96,
	},
	{
		garage_id: 2,
		part_id: 290,
		quantity: 59,
	},
	{
		garage_id: 2,
		part_id: 291,
		quantity: 224,
	},
	{
		garage_id: 2,
		part_id: 292,
		quantity: 300,
	},
	{
		garage_id: 2,
		part_id: 293,
		quantity: 379,
	},
	{
		garage_id: 2,
		part_id: 294,
		quantity: 144,
	},
	{
		garage_id: 2,
		part_id: 295,
		quantity: 382,
	},
	{
		garage_id: 2,
		part_id: 296,
		quantity: 219,
	},
	{
		garage_id: 2,
		part_id: 297,
		quantity: 78,
	},
	{
		garage_id: 2,
		part_id: 298,
		quantity: 124,
	},
	{
		garage_id: 2,
		part_id: 299,
		quantity: 415,
	},
	{
		garage_id: 2,
		part_id: 300,
		quantity: 270,
	},
	{
		garage_id: 2,
		part_id: 301,
		quantity: 123,
	},
	{
		garage_id: 2,
		part_id: 302,
		quantity: 350,
	},
	{
		garage_id: 2,
		part_id: 303,
		quantity: 208,
	},
	{
		garage_id: 2,
		part_id: 304,
		quantity: 323,
	},
	{
		garage_id: 2,
		part_id: 305,
		quantity: 104,
	},
	{
		garage_id: 2,
		part_id: 306,
		quantity: 209,
	},
	{
		garage_id: 2,
		part_id: 307,
		quantity: 295,
	},
	{
		garage_id: 2,
		part_id: 308,
		quantity: 437,
	},
	{
		garage_id: 2,
		part_id: 309,
		quantity: 1,
	},
	{
		garage_id: 2,
		part_id: 310,
		quantity: 175,
	},
	{
		garage_id: 2,
		part_id: 311,
		quantity: 0,
	},
	{
		garage_id: 2,
		part_id: 312,
		quantity: 248,
	},
	{
		garage_id: 2,
		part_id: 313,
		quantity: 112,
	},
	{
		garage_id: 2,
		part_id: 314,
		quantity: 34,
	},
	{
		garage_id: 2,
		part_id: 315,
		quantity: 158,
	},
	{
		garage_id: 2,
		part_id: 316,
		quantity: 139,
	},
	{
		garage_id: 2,
		part_id: 317,
		quantity: 378,
	},
	{
		garage_id: 2,
		part_id: 318,
		quantity: 48,
	},
	{
		garage_id: 2,
		part_id: 319,
		quantity: 72,
	},
	{
		garage_id: 2,
		part_id: 320,
		quantity: 12,
	},
	{
		garage_id: 2,
		part_id: 321,
		quantity: 149,
	},
	{
		garage_id: 2,
		part_id: 322,
		quantity: 224,
	},
	{
		garage_id: 2,
		part_id: 323,
		quantity: 152,
	},
	{
		garage_id: 2,
		part_id: 324,
		quantity: 206,
	},
	{
		garage_id: 2,
		part_id: 325,
		quantity: 127,
	},
	{
		garage_id: 2,
		part_id: 326,
		quantity: 304,
	},
	{
		garage_id: 2,
		part_id: 327,
		quantity: 168,
	},
	{
		garage_id: 2,
		part_id: 328,
		quantity: 92,
	},
	{
		garage_id: 2,
		part_id: 329,
		quantity: 131,
	},
	{
		garage_id: 2,
		part_id: 330,
		quantity: 434,
	},
	{
		garage_id: 2,
		part_id: 331,
		quantity: 206,
	},
	{
		garage_id: 2,
		part_id: 332,
		quantity: 412,
	},
	{
		garage_id: 2,
		part_id: 333,
		quantity: 323,
	},
	{
		garage_id: 2,
		part_id: 334,
		quantity: 332,
	},
	{
		garage_id: 2,
		part_id: 335,
		quantity: 197,
	},
	{
		garage_id: 2,
		part_id: 336,
		quantity: 97,
	},
	{
		garage_id: 2,
		part_id: 337,
		quantity: 370,
	},
	{
		garage_id: 2,
		part_id: 338,
		quantity: 73,
	},
	{
		garage_id: 2,
		part_id: 339,
		quantity: 43,
	},
	{
		garage_id: 2,
		part_id: 340,
		quantity: 392,
	},
	{
		garage_id: 2,
		part_id: 341,
		quantity: 488,
	},
	{
		garage_id: 2,
		part_id: 342,
		quantity: 199,
	},
	{
		garage_id: 2,
		part_id: 343,
		quantity: 10,
	},
	{
		garage_id: 2,
		part_id: 344,
		quantity: 106,
	},
	{
		garage_id: 2,
		part_id: 345,
		quantity: 471,
	},
	{
		garage_id: 2,
		part_id: 346,
		quantity: 257,
	},
	{
		garage_id: 2,
		part_id: 347,
		quantity: 1,
	},
	{
		garage_id: 2,
		part_id: 348,
		quantity: 265,
	},
	{
		garage_id: 2,
		part_id: 349,
		quantity: 451,
	},
	{
		garage_id: 2,
		part_id: 350,
		quantity: 0,
	},
	{
		garage_id: 2,
		part_id: 351,
		quantity: 228,
	},
	{
		garage_id: 2,
		part_id: 352,
		quantity: 111,
	},
	{
		garage_id: 2,
		part_id: 353,
		quantity: 410,
	},
	{
		garage_id: 2,
		part_id: 354,
		quantity: 201,
	},
	{
		garage_id: 2,
		part_id: 355,
		quantity: 328,
	},
	{
		garage_id: 2,
		part_id: 356,
		quantity: 339,
	},
	{
		garage_id: 2,
		part_id: 357,
		quantity: 448,
	},
	{
		garage_id: 2,
		part_id: 358,
		quantity: 164,
	},
	{
		garage_id: 2,
		part_id: 359,
		quantity: 50,
	},
	{
		garage_id: 2,
		part_id: 360,
		quantity: 43,
	},
	{
		garage_id: 2,
		part_id: 361,
		quantity: 486,
	},
	{
		garage_id: 2,
		part_id: 362,
		quantity: 458,
	},
	{
		garage_id: 2,
		part_id: 363,
		quantity: 107,
	},
	{
		garage_id: 2,
		part_id: 364,
		quantity: 100,
	},
	{
		garage_id: 2,
		part_id: 365,
		quantity: 81,
	},
	{
		garage_id: 2,
		part_id: 366,
		quantity: 235,
	},
	{
		garage_id: 2,
		part_id: 367,
		quantity: 466,
	},
	{
		garage_id: 2,
		part_id: 368,
		quantity: 136,
	},
	{
		garage_id: 2,
		part_id: 369,
		quantity: 135,
	},
	{
		garage_id: 2,
		part_id: 370,
		quantity: 309,
	},
	{
		garage_id: 2,
		part_id: 371,
		quantity: 240,
	},
	{
		garage_id: 2,
		part_id: 372,
		quantity: 327,
	},
	{
		garage_id: 2,
		part_id: 373,
		quantity: 190,
	},
	{
		garage_id: 2,
		part_id: 374,
		quantity: 20,
	},
	{
		garage_id: 2,
		part_id: 375,
		quantity: 61,
	},
	{
		garage_id: 2,
		part_id: 376,
		quantity: 464,
	},
	{
		garage_id: 2,
		part_id: 377,
		quantity: 156,
	},
	{
		garage_id: 2,
		part_id: 378,
		quantity: 489,
	},
	{
		garage_id: 2,
		part_id: 379,
		quantity: 268,
	},
	{
		garage_id: 2,
		part_id: 380,
		quantity: 95,
	},
	{
		garage_id: 2,
		part_id: 381,
		quantity: 497,
	},
	{
		garage_id: 2,
		part_id: 382,
		quantity: 480,
	},
	{
		garage_id: 2,
		part_id: 383,
		quantity: 385,
	},
	{
		garage_id: 2,
		part_id: 384,
		quantity: 46,
	},
	{
		garage_id: 2,
		part_id: 385,
		quantity: 383,
	},
	{
		garage_id: 2,
		part_id: 386,
		quantity: 256,
	},
	{
		garage_id: 2,
		part_id: 387,
		quantity: 273,
	},
	{
		garage_id: 2,
		part_id: 388,
		quantity: 20,
	},
	{
		garage_id: 2,
		part_id: 389,
		quantity: 356,
	},
	{
		garage_id: 2,
		part_id: 390,
		quantity: 450,
	},
	{
		garage_id: 2,
		part_id: 391,
		quantity: 401,
	},
	{
		garage_id: 2,
		part_id: 392,
		quantity: 290,
	},
	{
		garage_id: 2,
		part_id: 393,
		quantity: 225,
	},
	{
		garage_id: 2,
		part_id: 394,
		quantity: 313,
	},
	{
		garage_id: 2,
		part_id: 395,
		quantity: 17,
	},
	{
		garage_id: 2,
		part_id: 396,
		quantity: 325,
	},
	{
		garage_id: 2,
		part_id: 397,
		quantity: 34,
	},
	{
		garage_id: 2,
		part_id: 398,
		quantity: 138,
	},
	{
		garage_id: 2,
		part_id: 399,
		quantity: 433,
	},
	{
		garage_id: 3,
		part_id: 0,
		quantity: 124,
	},
	{
		garage_id: 3,
		part_id: 1,
		quantity: 444,
	},
	{
		garage_id: 3,
		part_id: 2,
		quantity: 472,
	},
	{
		garage_id: 3,
		part_id: 3,
		quantity: 252,
	},
	{
		garage_id: 3,
		part_id: 4,
		quantity: 127,
	},
	{
		garage_id: 3,
		part_id: 5,
		quantity: 494,
	},
	{
		garage_id: 3,
		part_id: 6,
		quantity: 375,
	},
	{
		garage_id: 3,
		part_id: 7,
		quantity: 394,
	},
	{
		garage_id: 3,
		part_id: 8,
		quantity: 185,
	},
	{
		garage_id: 3,
		part_id: 9,
		quantity: 231,
	},
	{
		garage_id: 3,
		part_id: 10,
		quantity: 137,
	},
	{
		garage_id: 3,
		part_id: 11,
		quantity: 6,
	},
	{
		garage_id: 3,
		part_id: 12,
		quantity: 5,
	},
	{
		garage_id: 3,
		part_id: 13,
		quantity: 466,
	},
	{
		garage_id: 3,
		part_id: 14,
		quantity: 325,
	},
	{
		garage_id: 3,
		part_id: 15,
		quantity: 51,
	},
	{
		garage_id: 3,
		part_id: 16,
		quantity: 470,
	},
	{
		garage_id: 3,
		part_id: 17,
		quantity: 68,
	},
	{
		garage_id: 3,
		part_id: 18,
		quantity: 79,
	},
	{
		garage_id: 3,
		part_id: 19,
		quantity: 219,
	},
	{
		garage_id: 3,
		part_id: 20,
		quantity: 115,
	},
	{
		garage_id: 3,
		part_id: 21,
		quantity: 500,
	},
	{
		garage_id: 3,
		part_id: 22,
		quantity: 119,
	},
	{
		garage_id: 3,
		part_id: 23,
		quantity: 406,
	},
	{
		garage_id: 3,
		part_id: 24,
		quantity: 342,
	},
	{
		garage_id: 3,
		part_id: 25,
		quantity: 455,
	},
	{
		garage_id: 3,
		part_id: 26,
		quantity: 24,
	},
	{
		garage_id: 3,
		part_id: 27,
		quantity: 13,
	},
	{
		garage_id: 3,
		part_id: 28,
		quantity: 385,
	},
	{
		garage_id: 3,
		part_id: 29,
		quantity: 39,
	},
	{
		garage_id: 3,
		part_id: 30,
		quantity: 308,
	},
	{
		garage_id: 3,
		part_id: 31,
		quantity: 396,
	},
	{
		garage_id: 3,
		part_id: 32,
		quantity: 475,
	},
	{
		garage_id: 3,
		part_id: 33,
		quantity: 371,
	},
	{
		garage_id: 3,
		part_id: 34,
		quantity: 84,
	},
	{
		garage_id: 3,
		part_id: 35,
		quantity: 302,
	},
	{
		garage_id: 3,
		part_id: 36,
		quantity: 379,
	},
	{
		garage_id: 3,
		part_id: 37,
		quantity: 462,
	},
	{
		garage_id: 3,
		part_id: 38,
		quantity: 366,
	},
	{
		garage_id: 3,
		part_id: 39,
		quantity: 286,
	},
	{
		garage_id: 3,
		part_id: 40,
		quantity: 455,
	},
	{
		garage_id: 3,
		part_id: 41,
		quantity: 490,
	},
	{
		garage_id: 3,
		part_id: 42,
		quantity: 270,
	},
	{
		garage_id: 3,
		part_id: 43,
		quantity: 21,
	},
	{
		garage_id: 3,
		part_id: 44,
		quantity: 319,
	},
	{
		garage_id: 3,
		part_id: 45,
		quantity: 439,
	},
	{
		garage_id: 3,
		part_id: 46,
		quantity: 73,
	},
	{
		garage_id: 3,
		part_id: 47,
		quantity: 282,
	},
	{
		garage_id: 3,
		part_id: 48,
		quantity: 262,
	},
	{
		garage_id: 3,
		part_id: 49,
		quantity: 353,
	},
	{
		garage_id: 3,
		part_id: 50,
		quantity: 440,
	},
	{
		garage_id: 3,
		part_id: 51,
		quantity: 425,
	},
	{
		garage_id: 3,
		part_id: 52,
		quantity: 66,
	},
	{
		garage_id: 3,
		part_id: 53,
		quantity: 18,
	},
	{
		garage_id: 3,
		part_id: 54,
		quantity: 20,
	},
	{
		garage_id: 3,
		part_id: 55,
		quantity: 482,
	},
	{
		garage_id: 3,
		part_id: 56,
		quantity: 183,
	},
	{
		garage_id: 3,
		part_id: 57,
		quantity: 168,
	},
	{
		garage_id: 3,
		part_id: 58,
		quantity: 12,
	},
	{
		garage_id: 3,
		part_id: 59,
		quantity: 278,
	},
	{
		garage_id: 3,
		part_id: 60,
		quantity: 180,
	},
	{
		garage_id: 3,
		part_id: 61,
		quantity: 494,
	},
	{
		garage_id: 3,
		part_id: 62,
		quantity: 202,
	},
	{
		garage_id: 3,
		part_id: 63,
		quantity: 314,
	},
	{
		garage_id: 3,
		part_id: 64,
		quantity: 46,
	},
	{
		garage_id: 3,
		part_id: 65,
		quantity: 256,
	},
	{
		garage_id: 3,
		part_id: 66,
		quantity: 224,
	},
	{
		garage_id: 3,
		part_id: 67,
		quantity: 418,
	},
	{
		garage_id: 3,
		part_id: 68,
		quantity: 359,
	},
	{
		garage_id: 3,
		part_id: 69,
		quantity: 346,
	},
	{
		garage_id: 3,
		part_id: 70,
		quantity: 188,
	},
	{
		garage_id: 3,
		part_id: 71,
		quantity: 389,
	},
	{
		garage_id: 3,
		part_id: 72,
		quantity: 313,
	},
	{
		garage_id: 3,
		part_id: 73,
		quantity: 325,
	},
	{
		garage_id: 3,
		part_id: 74,
		quantity: 431,
	},
	{
		garage_id: 3,
		part_id: 75,
		quantity: 135,
	},
	{
		garage_id: 3,
		part_id: 76,
		quantity: 193,
	},
	{
		garage_id: 3,
		part_id: 77,
		quantity: 95,
	},
	{
		garage_id: 3,
		part_id: 78,
		quantity: 213,
	},
	{
		garage_id: 3,
		part_id: 79,
		quantity: 37,
	},
	{
		garage_id: 3,
		part_id: 80,
		quantity: 110,
	},
	{
		garage_id: 3,
		part_id: 81,
		quantity: 85,
	},
	{
		garage_id: 3,
		part_id: 82,
		quantity: 76,
	},
	{
		garage_id: 3,
		part_id: 83,
		quantity: 53,
	},
	{
		garage_id: 3,
		part_id: 84,
		quantity: 222,
	},
	{
		garage_id: 3,
		part_id: 85,
		quantity: 406,
	},
	{
		garage_id: 3,
		part_id: 86,
		quantity: 270,
	},
	{
		garage_id: 3,
		part_id: 87,
		quantity: 92,
	},
	{
		garage_id: 3,
		part_id: 88,
		quantity: 272,
	},
	{
		garage_id: 3,
		part_id: 89,
		quantity: 7,
	},
	{
		garage_id: 3,
		part_id: 90,
		quantity: 490,
	},
	{
		garage_id: 3,
		part_id: 91,
		quantity: 345,
	},
	{
		garage_id: 3,
		part_id: 92,
		quantity: 287,
	},
	{
		garage_id: 3,
		part_id: 93,
		quantity: 41,
	},
	{
		garage_id: 3,
		part_id: 94,
		quantity: 194,
	},
	{
		garage_id: 3,
		part_id: 95,
		quantity: 91,
	},
	{
		garage_id: 3,
		part_id: 96,
		quantity: 48,
	},
	{
		garage_id: 3,
		part_id: 97,
		quantity: 241,
	},
	{
		garage_id: 3,
		part_id: 98,
		quantity: 122,
	},
	{
		garage_id: 3,
		part_id: 99,
		quantity: 368,
	},
	{
		garage_id: 3,
		part_id: 100,
		quantity: 8,
	},
	{
		garage_id: 3,
		part_id: 101,
		quantity: 1,
	},
	{
		garage_id: 3,
		part_id: 102,
		quantity: 81,
	},
	{
		garage_id: 3,
		part_id: 103,
		quantity: 232,
	},
	{
		garage_id: 3,
		part_id: 104,
		quantity: 35,
	},
	{
		garage_id: 3,
		part_id: 105,
		quantity: 417,
	},
	{
		garage_id: 3,
		part_id: 106,
		quantity: 120,
	},
	{
		garage_id: 3,
		part_id: 107,
		quantity: 146,
	},
	{
		garage_id: 3,
		part_id: 108,
		quantity: 493,
	},
	{
		garage_id: 3,
		part_id: 109,
		quantity: 385,
	},
	{
		garage_id: 3,
		part_id: 110,
		quantity: 441,
	},
	{
		garage_id: 3,
		part_id: 111,
		quantity: 468,
	},
	{
		garage_id: 3,
		part_id: 112,
		quantity: 223,
	},
	{
		garage_id: 3,
		part_id: 113,
		quantity: 65,
	},
	{
		garage_id: 3,
		part_id: 114,
		quantity: 375,
	},
	{
		garage_id: 3,
		part_id: 115,
		quantity: 171,
	},
	{
		garage_id: 3,
		part_id: 116,
		quantity: 125,
	},
	{
		garage_id: 3,
		part_id: 117,
		quantity: 275,
	},
	{
		garage_id: 3,
		part_id: 118,
		quantity: 141,
	},
	{
		garage_id: 3,
		part_id: 119,
		quantity: 147,
	},
	{
		garage_id: 3,
		part_id: 120,
		quantity: 112,
	},
	{
		garage_id: 3,
		part_id: 121,
		quantity: 221,
	},
	{
		garage_id: 3,
		part_id: 122,
		quantity: 221,
	},
	{
		garage_id: 3,
		part_id: 123,
		quantity: 169,
	},
	{
		garage_id: 3,
		part_id: 124,
		quantity: 435,
	},
	{
		garage_id: 3,
		part_id: 125,
		quantity: 75,
	},
	{
		garage_id: 3,
		part_id: 126,
		quantity: 383,
	},
	{
		garage_id: 3,
		part_id: 127,
		quantity: 29,
	},
	{
		garage_id: 3,
		part_id: 128,
		quantity: 130,
	},
	{
		garage_id: 3,
		part_id: 129,
		quantity: 54,
	},
	{
		garage_id: 3,
		part_id: 130,
		quantity: 2,
	},
	{
		garage_id: 3,
		part_id: 131,
		quantity: 384,
	},
	{
		garage_id: 3,
		part_id: 132,
		quantity: 90,
	},
	{
		garage_id: 3,
		part_id: 133,
		quantity: 205,
	},
	{
		garage_id: 3,
		part_id: 134,
		quantity: 450,
	},
	{
		garage_id: 3,
		part_id: 135,
		quantity: 265,
	},
	{
		garage_id: 3,
		part_id: 136,
		quantity: 9,
	},
	{
		garage_id: 3,
		part_id: 137,
		quantity: 114,
	},
	{
		garage_id: 3,
		part_id: 138,
		quantity: 126,
	},
	{
		garage_id: 3,
		part_id: 139,
		quantity: 423,
	},
	{
		garage_id: 3,
		part_id: 140,
		quantity: 16,
	},
	{
		garage_id: 3,
		part_id: 141,
		quantity: 325,
	},
	{
		garage_id: 3,
		part_id: 142,
		quantity: 458,
	},
	{
		garage_id: 3,
		part_id: 143,
		quantity: 233,
	},
	{
		garage_id: 3,
		part_id: 144,
		quantity: 387,
	},
	{
		garage_id: 3,
		part_id: 145,
		quantity: 195,
	},
	{
		garage_id: 3,
		part_id: 146,
		quantity: 368,
	},
	{
		garage_id: 3,
		part_id: 147,
		quantity: 312,
	},
	{
		garage_id: 3,
		part_id: 148,
		quantity: 181,
	},
	{
		garage_id: 3,
		part_id: 149,
		quantity: 22,
	},
	{
		garage_id: 3,
		part_id: 150,
		quantity: 9,
	},
	{
		garage_id: 3,
		part_id: 151,
		quantity: 450,
	},
	{
		garage_id: 3,
		part_id: 152,
		quantity: 286,
	},
	{
		garage_id: 3,
		part_id: 153,
		quantity: 238,
	},
	{
		garage_id: 3,
		part_id: 154,
		quantity: 87,
	},
	{
		garage_id: 3,
		part_id: 155,
		quantity: 366,
	},
	{
		garage_id: 3,
		part_id: 156,
		quantity: 122,
	},
	{
		garage_id: 3,
		part_id: 157,
		quantity: 105,
	},
	{
		garage_id: 3,
		part_id: 158,
		quantity: 467,
	},
	{
		garage_id: 3,
		part_id: 159,
		quantity: 427,
	},
	{
		garage_id: 3,
		part_id: 160,
		quantity: 355,
	},
	{
		garage_id: 3,
		part_id: 161,
		quantity: 289,
	},
	{
		garage_id: 3,
		part_id: 162,
		quantity: 461,
	},
	{
		garage_id: 3,
		part_id: 163,
		quantity: 190,
	},
	{
		garage_id: 3,
		part_id: 164,
		quantity: 53,
	},
	{
		garage_id: 3,
		part_id: 165,
		quantity: 243,
	},
	{
		garage_id: 3,
		part_id: 166,
		quantity: 274,
	},
	{
		garage_id: 3,
		part_id: 167,
		quantity: 360,
	},
	{
		garage_id: 3,
		part_id: 168,
		quantity: 331,
	},
	{
		garage_id: 3,
		part_id: 169,
		quantity: 286,
	},
	{
		garage_id: 3,
		part_id: 170,
		quantity: 9,
	},
	{
		garage_id: 3,
		part_id: 171,
		quantity: 376,
	},
	{
		garage_id: 3,
		part_id: 172,
		quantity: 33,
	},
	{
		garage_id: 3,
		part_id: 173,
		quantity: 133,
	},
	{
		garage_id: 3,
		part_id: 174,
		quantity: 496,
	},
	{
		garage_id: 3,
		part_id: 175,
		quantity: 435,
	},
	{
		garage_id: 3,
		part_id: 176,
		quantity: 104,
	},
	{
		garage_id: 3,
		part_id: 177,
		quantity: 39,
	},
	{
		garage_id: 3,
		part_id: 178,
		quantity: 89,
	},
	{
		garage_id: 3,
		part_id: 179,
		quantity: 404,
	},
	{
		garage_id: 3,
		part_id: 180,
		quantity: 352,
	},
	{
		garage_id: 3,
		part_id: 181,
		quantity: 77,
	},
	{
		garage_id: 3,
		part_id: 182,
		quantity: 69,
	},
	{
		garage_id: 3,
		part_id: 183,
		quantity: 420,
	},
	{
		garage_id: 3,
		part_id: 184,
		quantity: 447,
	},
	{
		garage_id: 3,
		part_id: 185,
		quantity: 274,
	},
	{
		garage_id: 3,
		part_id: 186,
		quantity: 256,
	},
	{
		garage_id: 3,
		part_id: 187,
		quantity: 56,
	},
	{
		garage_id: 3,
		part_id: 188,
		quantity: 308,
	},
	{
		garage_id: 3,
		part_id: 189,
		quantity: 283,
	},
	{
		garage_id: 3,
		part_id: 190,
		quantity: 66,
	},
	{
		garage_id: 3,
		part_id: 191,
		quantity: 398,
	},
	{
		garage_id: 3,
		part_id: 192,
		quantity: 203,
	},
	{
		garage_id: 3,
		part_id: 193,
		quantity: 490,
	},
	{
		garage_id: 3,
		part_id: 194,
		quantity: 145,
	},
	{
		garage_id: 3,
		part_id: 195,
		quantity: 361,
	},
	{
		garage_id: 3,
		part_id: 196,
		quantity: 64,
	},
	{
		garage_id: 3,
		part_id: 197,
		quantity: 179,
	},
	{
		garage_id: 3,
		part_id: 198,
		quantity: 268,
	},
	{
		garage_id: 3,
		part_id: 199,
		quantity: 76,
	},
	{
		garage_id: 3,
		part_id: 200,
		quantity: 219,
	},
	{
		garage_id: 3,
		part_id: 201,
		quantity: 76,
	},
	{
		garage_id: 3,
		part_id: 202,
		quantity: 143,
	},
	{
		garage_id: 3,
		part_id: 203,
		quantity: 141,
	},
	{
		garage_id: 3,
		part_id: 204,
		quantity: 337,
	},
	{
		garage_id: 3,
		part_id: 205,
		quantity: 399,
	},
	{
		garage_id: 3,
		part_id: 206,
		quantity: 269,
	},
	{
		garage_id: 3,
		part_id: 207,
		quantity: 200,
	},
	{
		garage_id: 3,
		part_id: 208,
		quantity: 97,
	},
	{
		garage_id: 3,
		part_id: 209,
		quantity: 307,
	},
	{
		garage_id: 3,
		part_id: 210,
		quantity: 323,
	},
	{
		garage_id: 3,
		part_id: 211,
		quantity: 485,
	},
	{
		garage_id: 3,
		part_id: 212,
		quantity: 261,
	},
	{
		garage_id: 3,
		part_id: 213,
		quantity: 363,
	},
	{
		garage_id: 3,
		part_id: 214,
		quantity: 365,
	},
	{
		garage_id: 3,
		part_id: 215,
		quantity: 224,
	},
	{
		garage_id: 3,
		part_id: 216,
		quantity: 240,
	},
	{
		garage_id: 3,
		part_id: 217,
		quantity: 364,
	},
	{
		garage_id: 3,
		part_id: 218,
		quantity: 49,
	},
	{
		garage_id: 3,
		part_id: 219,
		quantity: 161,
	},
	{
		garage_id: 3,
		part_id: 220,
		quantity: 192,
	},
	{
		garage_id: 3,
		part_id: 221,
		quantity: 168,
	},
	{
		garage_id: 3,
		part_id: 222,
		quantity: 63,
	},
	{
		garage_id: 3,
		part_id: 223,
		quantity: 31,
	},
	{
		garage_id: 3,
		part_id: 224,
		quantity: 390,
	},
	{
		garage_id: 3,
		part_id: 225,
		quantity: 428,
	},
	{
		garage_id: 3,
		part_id: 226,
		quantity: 23,
	},
	{
		garage_id: 3,
		part_id: 227,
		quantity: 450,
	},
	{
		garage_id: 3,
		part_id: 228,
		quantity: 456,
	},
	{
		garage_id: 3,
		part_id: 229,
		quantity: 60,
	},
	{
		garage_id: 3,
		part_id: 230,
		quantity: 267,
	},
	{
		garage_id: 3,
		part_id: 231,
		quantity: 1,
	},
	{
		garage_id: 3,
		part_id: 232,
		quantity: 393,
	},
	{
		garage_id: 3,
		part_id: 233,
		quantity: 372,
	},
	{
		garage_id: 3,
		part_id: 234,
		quantity: 475,
	},
	{
		garage_id: 3,
		part_id: 235,
		quantity: 118,
	},
	{
		garage_id: 3,
		part_id: 236,
		quantity: 160,
	},
	{
		garage_id: 3,
		part_id: 237,
		quantity: 219,
	},
	{
		garage_id: 3,
		part_id: 238,
		quantity: 240,
	},
	{
		garage_id: 3,
		part_id: 239,
		quantity: 352,
	},
	{
		garage_id: 3,
		part_id: 240,
		quantity: 370,
	},
	{
		garage_id: 3,
		part_id: 241,
		quantity: 378,
	},
	{
		garage_id: 3,
		part_id: 242,
		quantity: 76,
	},
	{
		garage_id: 3,
		part_id: 243,
		quantity: 44,
	},
	{
		garage_id: 3,
		part_id: 244,
		quantity: 169,
	},
	{
		garage_id: 3,
		part_id: 245,
		quantity: 93,
	},
	{
		garage_id: 3,
		part_id: 246,
		quantity: 142,
	},
	{
		garage_id: 3,
		part_id: 247,
		quantity: 11,
	},
	{
		garage_id: 3,
		part_id: 248,
		quantity: 294,
	},
	{
		garage_id: 3,
		part_id: 249,
		quantity: 457,
	},
	{
		garage_id: 3,
		part_id: 250,
		quantity: 115,
	},
	{
		garage_id: 3,
		part_id: 251,
		quantity: 361,
	},
	{
		garage_id: 3,
		part_id: 252,
		quantity: 273,
	},
	{
		garage_id: 3,
		part_id: 253,
		quantity: 120,
	},
	{
		garage_id: 3,
		part_id: 254,
		quantity: 434,
	},
	{
		garage_id: 3,
		part_id: 255,
		quantity: 182,
	},
	{
		garage_id: 3,
		part_id: 256,
		quantity: 68,
	},
	{
		garage_id: 3,
		part_id: 257,
		quantity: 254,
	},
	{
		garage_id: 3,
		part_id: 258,
		quantity: 438,
	},
	{
		garage_id: 3,
		part_id: 259,
		quantity: 162,
	},
	{
		garage_id: 3,
		part_id: 260,
		quantity: 207,
	},
	{
		garage_id: 3,
		part_id: 261,
		quantity: 41,
	},
	{
		garage_id: 3,
		part_id: 262,
		quantity: 230,
	},
	{
		garage_id: 3,
		part_id: 263,
		quantity: 41,
	},
	{
		garage_id: 3,
		part_id: 264,
		quantity: 337,
	},
	{
		garage_id: 3,
		part_id: 265,
		quantity: 103,
	},
	{
		garage_id: 3,
		part_id: 266,
		quantity: 293,
	},
	{
		garage_id: 3,
		part_id: 267,
		quantity: 425,
	},
	{
		garage_id: 3,
		part_id: 268,
		quantity: 235,
	},
	{
		garage_id: 3,
		part_id: 269,
		quantity: 466,
	},
	{
		garage_id: 3,
		part_id: 270,
		quantity: 448,
	},
	{
		garage_id: 3,
		part_id: 271,
		quantity: 349,
	},
	{
		garage_id: 3,
		part_id: 272,
		quantity: 314,
	},
	{
		garage_id: 3,
		part_id: 273,
		quantity: 354,
	},
	{
		garage_id: 3,
		part_id: 274,
		quantity: 193,
	},
	{
		garage_id: 3,
		part_id: 275,
		quantity: 250,
	},
	{
		garage_id: 3,
		part_id: 276,
		quantity: 67,
	},
	{
		garage_id: 3,
		part_id: 277,
		quantity: 386,
	},
	{
		garage_id: 3,
		part_id: 278,
		quantity: 237,
	},
	{
		garage_id: 3,
		part_id: 279,
		quantity: 228,
	},
	{
		garage_id: 3,
		part_id: 280,
		quantity: 7,
	},
	{
		garage_id: 3,
		part_id: 281,
		quantity: 479,
	},
	{
		garage_id: 3,
		part_id: 282,
		quantity: 137,
	},
	{
		garage_id: 3,
		part_id: 283,
		quantity: 40,
	},
	{
		garage_id: 3,
		part_id: 284,
		quantity: 459,
	},
	{
		garage_id: 3,
		part_id: 285,
		quantity: 93,
	},
	{
		garage_id: 3,
		part_id: 286,
		quantity: 448,
	},
	{
		garage_id: 3,
		part_id: 287,
		quantity: 164,
	},
	{
		garage_id: 3,
		part_id: 288,
		quantity: 496,
	},
	{
		garage_id: 3,
		part_id: 289,
		quantity: 205,
	},
	{
		garage_id: 3,
		part_id: 290,
		quantity: 79,
	},
	{
		garage_id: 3,
		part_id: 291,
		quantity: 391,
	},
	{
		garage_id: 3,
		part_id: 292,
		quantity: 135,
	},
	{
		garage_id: 3,
		part_id: 293,
		quantity: 437,
	},
	{
		garage_id: 3,
		part_id: 294,
		quantity: 410,
	},
	{
		garage_id: 3,
		part_id: 295,
		quantity: 261,
	},
	{
		garage_id: 3,
		part_id: 296,
		quantity: 392,
	},
	{
		garage_id: 3,
		part_id: 297,
		quantity: 230,
	},
	{
		garage_id: 3,
		part_id: 298,
		quantity: 401,
	},
	{
		garage_id: 3,
		part_id: 299,
		quantity: 266,
	},
	{
		garage_id: 3,
		part_id: 300,
		quantity: 36,
	},
	{
		garage_id: 3,
		part_id: 301,
		quantity: 463,
	},
	{
		garage_id: 3,
		part_id: 302,
		quantity: 304,
	},
	{
		garage_id: 3,
		part_id: 303,
		quantity: 310,
	},
	{
		garage_id: 3,
		part_id: 304,
		quantity: 446,
	},
	{
		garage_id: 3,
		part_id: 305,
		quantity: 489,
	},
	{
		garage_id: 3,
		part_id: 306,
		quantity: 103,
	},
	{
		garage_id: 3,
		part_id: 307,
		quantity: 182,
	},
	{
		garage_id: 3,
		part_id: 308,
		quantity: 399,
	},
	{
		garage_id: 3,
		part_id: 309,
		quantity: 242,
	},
	{
		garage_id: 3,
		part_id: 310,
		quantity: 453,
	},
	{
		garage_id: 3,
		part_id: 311,
		quantity: 84,
	},
	{
		garage_id: 3,
		part_id: 312,
		quantity: 411,
	},
	{
		garage_id: 3,
		part_id: 313,
		quantity: 181,
	},
	{
		garage_id: 3,
		part_id: 314,
		quantity: 211,
	},
	{
		garage_id: 3,
		part_id: 315,
		quantity: 338,
	},
	{
		garage_id: 3,
		part_id: 316,
		quantity: 124,
	},
	{
		garage_id: 3,
		part_id: 317,
		quantity: 251,
	},
	{
		garage_id: 3,
		part_id: 318,
		quantity: 413,
	},
	{
		garage_id: 3,
		part_id: 319,
		quantity: 4,
	},
	{
		garage_id: 3,
		part_id: 320,
		quantity: 22,
	},
	{
		garage_id: 3,
		part_id: 321,
		quantity: 259,
	},
	{
		garage_id: 3,
		part_id: 322,
		quantity: 77,
	},
	{
		garage_id: 3,
		part_id: 323,
		quantity: 163,
	},
	{
		garage_id: 3,
		part_id: 324,
		quantity: 60,
	},
	{
		garage_id: 3,
		part_id: 325,
		quantity: 468,
	},
	{
		garage_id: 3,
		part_id: 326,
		quantity: 81,
	},
	{
		garage_id: 3,
		part_id: 327,
		quantity: 99,
	},
	{
		garage_id: 3,
		part_id: 328,
		quantity: 272,
	},
	{
		garage_id: 3,
		part_id: 329,
		quantity: 406,
	},
	{
		garage_id: 3,
		part_id: 330,
		quantity: 32,
	},
	{
		garage_id: 3,
		part_id: 331,
		quantity: 383,
	},
	{
		garage_id: 3,
		part_id: 332,
		quantity: 303,
	},
	{
		garage_id: 3,
		part_id: 333,
		quantity: 200,
	},
	{
		garage_id: 3,
		part_id: 334,
		quantity: 95,
	},
	{
		garage_id: 3,
		part_id: 335,
		quantity: 406,
	},
	{
		garage_id: 3,
		part_id: 336,
		quantity: 217,
	},
	{
		garage_id: 3,
		part_id: 337,
		quantity: 372,
	},
	{
		garage_id: 3,
		part_id: 338,
		quantity: 103,
	},
	{
		garage_id: 3,
		part_id: 339,
		quantity: 334,
	},
	{
		garage_id: 3,
		part_id: 340,
		quantity: 116,
	},
	{
		garage_id: 3,
		part_id: 341,
		quantity: 218,
	},
	{
		garage_id: 3,
		part_id: 342,
		quantity: 179,
	},
	{
		garage_id: 3,
		part_id: 343,
		quantity: 399,
	},
	{
		garage_id: 3,
		part_id: 344,
		quantity: 368,
	},
	{
		garage_id: 3,
		part_id: 345,
		quantity: 395,
	},
	{
		garage_id: 3,
		part_id: 346,
		quantity: 108,
	},
	{
		garage_id: 3,
		part_id: 347,
		quantity: 346,
	},
	{
		garage_id: 3,
		part_id: 348,
		quantity: 266,
	},
	{
		garage_id: 3,
		part_id: 349,
		quantity: 399,
	},
	{
		garage_id: 3,
		part_id: 350,
		quantity: 141,
	},
	{
		garage_id: 3,
		part_id: 351,
		quantity: 248,
	},
	{
		garage_id: 3,
		part_id: 352,
		quantity: 236,
	},
	{
		garage_id: 3,
		part_id: 353,
		quantity: 228,
	},
	{
		garage_id: 3,
		part_id: 354,
		quantity: 120,
	},
	{
		garage_id: 3,
		part_id: 355,
		quantity: 123,
	},
	{
		garage_id: 3,
		part_id: 356,
		quantity: 335,
	},
	{
		garage_id: 3,
		part_id: 357,
		quantity: 197,
	},
	{
		garage_id: 3,
		part_id: 358,
		quantity: 82,
	},
	{
		garage_id: 3,
		part_id: 359,
		quantity: 106,
	},
	{
		garage_id: 3,
		part_id: 360,
		quantity: 145,
	},
	{
		garage_id: 3,
		part_id: 361,
		quantity: 91,
	},
	{
		garage_id: 3,
		part_id: 362,
		quantity: 320,
	},
	{
		garage_id: 3,
		part_id: 363,
		quantity: 179,
	},
	{
		garage_id: 3,
		part_id: 364,
		quantity: 449,
	},
	{
		garage_id: 3,
		part_id: 365,
		quantity: 336,
	},
	{
		garage_id: 3,
		part_id: 366,
		quantity: 394,
	},
	{
		garage_id: 3,
		part_id: 367,
		quantity: 76,
	},
	{
		garage_id: 3,
		part_id: 368,
		quantity: 275,
	},
	{
		garage_id: 3,
		part_id: 369,
		quantity: 305,
	},
	{
		garage_id: 3,
		part_id: 370,
		quantity: 219,
	},
	{
		garage_id: 3,
		part_id: 371,
		quantity: 217,
	},
	{
		garage_id: 3,
		part_id: 372,
		quantity: 378,
	},
	{
		garage_id: 3,
		part_id: 373,
		quantity: 0,
	},
	{
		garage_id: 3,
		part_id: 374,
		quantity: 206,
	},
	{
		garage_id: 3,
		part_id: 375,
		quantity: 132,
	},
	{
		garage_id: 3,
		part_id: 376,
		quantity: 230,
	},
	{
		garage_id: 3,
		part_id: 377,
		quantity: 373,
	},
	{
		garage_id: 3,
		part_id: 378,
		quantity: 172,
	},
	{
		garage_id: 3,
		part_id: 379,
		quantity: 474,
	},
	{
		garage_id: 3,
		part_id: 380,
		quantity: 111,
	},
	{
		garage_id: 3,
		part_id: 381,
		quantity: 79,
	},
	{
		garage_id: 3,
		part_id: 382,
		quantity: 177,
	},
	{
		garage_id: 3,
		part_id: 383,
		quantity: 348,
	},
	{
		garage_id: 3,
		part_id: 384,
		quantity: 182,
	},
	{
		garage_id: 3,
		part_id: 385,
		quantity: 428,
	},
	{
		garage_id: 3,
		part_id: 386,
		quantity: 395,
	},
	{
		garage_id: 3,
		part_id: 387,
		quantity: 235,
	},
	{
		garage_id: 3,
		part_id: 388,
		quantity: 104,
	},
	{
		garage_id: 3,
		part_id: 389,
		quantity: 284,
	},
	{
		garage_id: 3,
		part_id: 390,
		quantity: 67,
	},
	{
		garage_id: 3,
		part_id: 391,
		quantity: 147,
	},
	{
		garage_id: 3,
		part_id: 392,
		quantity: 10,
	},
	{
		garage_id: 3,
		part_id: 393,
		quantity: 420,
	},
	{
		garage_id: 3,
		part_id: 394,
		quantity: 230,
	},
	{
		garage_id: 3,
		part_id: 395,
		quantity: 302,
	},
	{
		garage_id: 3,
		part_id: 396,
		quantity: 488,
	},
	{
		garage_id: 3,
		part_id: 397,
		quantity: 356,
	},
	{
		garage_id: 3,
		part_id: 398,
		quantity: 98,
	},
	{
		garage_id: 3,
		part_id: 399,
		quantity: 408,
	},
	{
		garage_id: 4,
		part_id: 0,
		quantity: 192,
	},
	{
		garage_id: 4,
		part_id: 1,
		quantity: 170,
	},
	{
		garage_id: 4,
		part_id: 2,
		quantity: 95,
	},
	{
		garage_id: 4,
		part_id: 3,
		quantity: 391,
	},
	{
		garage_id: 4,
		part_id: 4,
		quantity: 432,
	},
	{
		garage_id: 4,
		part_id: 5,
		quantity: 409,
	},
	{
		garage_id: 4,
		part_id: 6,
		quantity: 466,
	},
	{
		garage_id: 4,
		part_id: 7,
		quantity: 431,
	},
	{
		garage_id: 4,
		part_id: 8,
		quantity: 436,
	},
	{
		garage_id: 4,
		part_id: 9,
		quantity: 259,
	},
	{
		garage_id: 4,
		part_id: 10,
		quantity: 305,
	},
	{
		garage_id: 4,
		part_id: 11,
		quantity: 457,
	},
	{
		garage_id: 4,
		part_id: 12,
		quantity: 296,
	},
	{
		garage_id: 4,
		part_id: 13,
		quantity: 258,
	},
	{
		garage_id: 4,
		part_id: 14,
		quantity: 466,
	},
	{
		garage_id: 4,
		part_id: 15,
		quantity: 277,
	},
	{
		garage_id: 4,
		part_id: 16,
		quantity: 464,
	},
	{
		garage_id: 4,
		part_id: 17,
		quantity: 359,
	},
	{
		garage_id: 4,
		part_id: 18,
		quantity: 185,
	},
	{
		garage_id: 4,
		part_id: 19,
		quantity: 497,
	},
	{
		garage_id: 4,
		part_id: 20,
		quantity: 465,
	},
	{
		garage_id: 4,
		part_id: 21,
		quantity: 495,
	},
	{
		garage_id: 4,
		part_id: 22,
		quantity: 327,
	},
	{
		garage_id: 4,
		part_id: 23,
		quantity: 167,
	},
	{
		garage_id: 4,
		part_id: 24,
		quantity: 20,
	},
	{
		garage_id: 4,
		part_id: 25,
		quantity: 448,
	},
	{
		garage_id: 4,
		part_id: 26,
		quantity: 184,
	},
	{
		garage_id: 4,
		part_id: 27,
		quantity: 391,
	},
	{
		garage_id: 4,
		part_id: 28,
		quantity: 298,
	},
	{
		garage_id: 4,
		part_id: 29,
		quantity: 82,
	},
	{
		garage_id: 4,
		part_id: 30,
		quantity: 348,
	},
	{
		garage_id: 4,
		part_id: 31,
		quantity: 21,
	},
	{
		garage_id: 4,
		part_id: 32,
		quantity: 202,
	},
	{
		garage_id: 4,
		part_id: 33,
		quantity: 367,
	},
	{
		garage_id: 4,
		part_id: 34,
		quantity: 471,
	},
	{
		garage_id: 4,
		part_id: 35,
		quantity: 104,
	},
	{
		garage_id: 4,
		part_id: 36,
		quantity: 126,
	},
	{
		garage_id: 4,
		part_id: 37,
		quantity: 249,
	},
	{
		garage_id: 4,
		part_id: 38,
		quantity: 30,
	},
	{
		garage_id: 4,
		part_id: 39,
		quantity: 198,
	},
	{
		garage_id: 4,
		part_id: 40,
		quantity: 331,
	},
	{
		garage_id: 4,
		part_id: 41,
		quantity: 477,
	},
	{
		garage_id: 4,
		part_id: 42,
		quantity: 418,
	},
	{
		garage_id: 4,
		part_id: 43,
		quantity: 233,
	},
	{
		garage_id: 4,
		part_id: 44,
		quantity: 316,
	},
	{
		garage_id: 4,
		part_id: 45,
		quantity: 420,
	},
	{
		garage_id: 4,
		part_id: 46,
		quantity: 59,
	},
	{
		garage_id: 4,
		part_id: 47,
		quantity: 14,
	},
	{
		garage_id: 4,
		part_id: 48,
		quantity: 140,
	},
	{
		garage_id: 4,
		part_id: 49,
		quantity: 69,
	},
	{
		garage_id: 4,
		part_id: 50,
		quantity: 286,
	},
	{
		garage_id: 4,
		part_id: 51,
		quantity: 404,
	},
	{
		garage_id: 4,
		part_id: 52,
		quantity: 166,
	},
	{
		garage_id: 4,
		part_id: 53,
		quantity: 477,
	},
	{
		garage_id: 4,
		part_id: 54,
		quantity: 161,
	},
	{
		garage_id: 4,
		part_id: 55,
		quantity: 414,
	},
	{
		garage_id: 4,
		part_id: 56,
		quantity: 248,
	},
	{
		garage_id: 4,
		part_id: 57,
		quantity: 315,
	},
	{
		garage_id: 4,
		part_id: 58,
		quantity: 284,
	},
	{
		garage_id: 4,
		part_id: 59,
		quantity: 245,
	},
	{
		garage_id: 4,
		part_id: 60,
		quantity: 102,
	},
	{
		garage_id: 4,
		part_id: 61,
		quantity: 367,
	},
	{
		garage_id: 4,
		part_id: 62,
		quantity: 458,
	},
	{
		garage_id: 4,
		part_id: 63,
		quantity: 76,
	},
	{
		garage_id: 4,
		part_id: 64,
		quantity: 404,
	},
	{
		garage_id: 4,
		part_id: 65,
		quantity: 61,
	},
	{
		garage_id: 4,
		part_id: 66,
		quantity: 365,
	},
	{
		garage_id: 4,
		part_id: 67,
		quantity: 432,
	},
	{
		garage_id: 4,
		part_id: 68,
		quantity: 205,
	},
	{
		garage_id: 4,
		part_id: 69,
		quantity: 76,
	},
	{
		garage_id: 4,
		part_id: 70,
		quantity: 391,
	},
	{
		garage_id: 4,
		part_id: 71,
		quantity: 287,
	},
	{
		garage_id: 4,
		part_id: 72,
		quantity: 91,
	},
	{
		garage_id: 4,
		part_id: 73,
		quantity: 108,
	},
	{
		garage_id: 4,
		part_id: 74,
		quantity: 15,
	},
	{
		garage_id: 4,
		part_id: 75,
		quantity: 400,
	},
	{
		garage_id: 4,
		part_id: 76,
		quantity: 274,
	},
	{
		garage_id: 4,
		part_id: 77,
		quantity: 272,
	},
	{
		garage_id: 4,
		part_id: 78,
		quantity: 341,
	},
	{
		garage_id: 4,
		part_id: 79,
		quantity: 17,
	},
	{
		garage_id: 4,
		part_id: 80,
		quantity: 460,
	},
	{
		garage_id: 4,
		part_id: 81,
		quantity: 363,
	},
	{
		garage_id: 4,
		part_id: 82,
		quantity: 451,
	},
	{
		garage_id: 4,
		part_id: 83,
		quantity: 118,
	},
	{
		garage_id: 4,
		part_id: 84,
		quantity: 187,
	},
	{
		garage_id: 4,
		part_id: 85,
		quantity: 188,
	},
	{
		garage_id: 4,
		part_id: 86,
		quantity: 315,
	},
	{
		garage_id: 4,
		part_id: 87,
		quantity: 283,
	},
	{
		garage_id: 4,
		part_id: 88,
		quantity: 285,
	},
	{
		garage_id: 4,
		part_id: 89,
		quantity: 228,
	},
	{
		garage_id: 4,
		part_id: 90,
		quantity: 44,
	},
	{
		garage_id: 4,
		part_id: 91,
		quantity: 135,
	},
	{
		garage_id: 4,
		part_id: 92,
		quantity: 397,
	},
	{
		garage_id: 4,
		part_id: 93,
		quantity: 441,
	},
	{
		garage_id: 4,
		part_id: 94,
		quantity: 87,
	},
	{
		garage_id: 4,
		part_id: 95,
		quantity: 63,
	},
	{
		garage_id: 4,
		part_id: 96,
		quantity: 314,
	},
	{
		garage_id: 4,
		part_id: 97,
		quantity: 86,
	},
	{
		garage_id: 4,
		part_id: 98,
		quantity: 119,
	},
	{
		garage_id: 4,
		part_id: 99,
		quantity: 375,
	},
	{
		garage_id: 4,
		part_id: 100,
		quantity: 411,
	},
	{
		garage_id: 4,
		part_id: 101,
		quantity: 424,
	},
	{
		garage_id: 4,
		part_id: 102,
		quantity: 433,
	},
	{
		garage_id: 4,
		part_id: 103,
		quantity: 461,
	},
	{
		garage_id: 4,
		part_id: 104,
		quantity: 413,
	},
	{
		garage_id: 4,
		part_id: 105,
		quantity: 384,
	},
	{
		garage_id: 4,
		part_id: 106,
		quantity: 218,
	},
	{
		garage_id: 4,
		part_id: 107,
		quantity: 133,
	},
	{
		garage_id: 4,
		part_id: 108,
		quantity: 198,
	},
	{
		garage_id: 4,
		part_id: 109,
		quantity: 203,
	},
	{
		garage_id: 4,
		part_id: 110,
		quantity: 204,
	},
	{
		garage_id: 4,
		part_id: 111,
		quantity: 29,
	},
	{
		garage_id: 4,
		part_id: 112,
		quantity: 144,
	},
	{
		garage_id: 4,
		part_id: 113,
		quantity: 354,
	},
	{
		garage_id: 4,
		part_id: 114,
		quantity: 188,
	},
	{
		garage_id: 4,
		part_id: 115,
		quantity: 283,
	},
	{
		garage_id: 4,
		part_id: 116,
		quantity: 305,
	},
	{
		garage_id: 4,
		part_id: 117,
		quantity: 128,
	},
	{
		garage_id: 4,
		part_id: 118,
		quantity: 171,
	},
	{
		garage_id: 4,
		part_id: 119,
		quantity: 126,
	},
	{
		garage_id: 4,
		part_id: 120,
		quantity: 413,
	},
	{
		garage_id: 4,
		part_id: 121,
		quantity: 77,
	},
	{
		garage_id: 4,
		part_id: 122,
		quantity: 279,
	},
	{
		garage_id: 4,
		part_id: 123,
		quantity: 137,
	},
	{
		garage_id: 4,
		part_id: 124,
		quantity: 369,
	},
	{
		garage_id: 4,
		part_id: 125,
		quantity: 455,
	},
	{
		garage_id: 4,
		part_id: 126,
		quantity: 372,
	},
	{
		garage_id: 4,
		part_id: 127,
		quantity: 408,
	},
	{
		garage_id: 4,
		part_id: 128,
		quantity: 472,
	},
	{
		garage_id: 4,
		part_id: 129,
		quantity: 115,
	},
	{
		garage_id: 4,
		part_id: 130,
		quantity: 187,
	},
	{
		garage_id: 4,
		part_id: 131,
		quantity: 228,
	},
	{
		garage_id: 4,
		part_id: 132,
		quantity: 409,
	},
	{
		garage_id: 4,
		part_id: 133,
		quantity: 438,
	},
	{
		garage_id: 4,
		part_id: 134,
		quantity: 261,
	},
	{
		garage_id: 4,
		part_id: 135,
		quantity: 413,
	},
	{
		garage_id: 4,
		part_id: 136,
		quantity: 412,
	},
	{
		garage_id: 4,
		part_id: 137,
		quantity: 402,
	},
	{
		garage_id: 4,
		part_id: 138,
		quantity: 358,
	},
	{
		garage_id: 4,
		part_id: 139,
		quantity: 270,
	},
	{
		garage_id: 4,
		part_id: 140,
		quantity: 134,
	},
	{
		garage_id: 4,
		part_id: 141,
		quantity: 232,
	},
	{
		garage_id: 4,
		part_id: 142,
		quantity: 395,
	},
	{
		garage_id: 4,
		part_id: 143,
		quantity: 381,
	},
	{
		garage_id: 4,
		part_id: 144,
		quantity: 57,
	},
	{
		garage_id: 4,
		part_id: 145,
		quantity: 190,
	},
	{
		garage_id: 4,
		part_id: 146,
		quantity: 354,
	},
	{
		garage_id: 4,
		part_id: 147,
		quantity: 390,
	},
	{
		garage_id: 4,
		part_id: 148,
		quantity: 142,
	},
	{
		garage_id: 4,
		part_id: 149,
		quantity: 57,
	},
	{
		garage_id: 4,
		part_id: 150,
		quantity: 184,
	},
	{
		garage_id: 4,
		part_id: 151,
		quantity: 311,
	},
	{
		garage_id: 4,
		part_id: 152,
		quantity: 254,
	},
	{
		garage_id: 4,
		part_id: 153,
		quantity: 0,
	},
	{
		garage_id: 4,
		part_id: 154,
		quantity: 332,
	},
	{
		garage_id: 4,
		part_id: 155,
		quantity: 180,
	},
	{
		garage_id: 4,
		part_id: 156,
		quantity: 442,
	},
	{
		garage_id: 4,
		part_id: 157,
		quantity: 441,
	},
	{
		garage_id: 4,
		part_id: 158,
		quantity: 130,
	},
	{
		garage_id: 4,
		part_id: 159,
		quantity: 397,
	},
	{
		garage_id: 4,
		part_id: 160,
		quantity: 43,
	},
	{
		garage_id: 4,
		part_id: 161,
		quantity: 441,
	},
	{
		garage_id: 4,
		part_id: 162,
		quantity: 112,
	},
	{
		garage_id: 4,
		part_id: 163,
		quantity: 156,
	},
	{
		garage_id: 4,
		part_id: 164,
		quantity: 179,
	},
	{
		garage_id: 4,
		part_id: 165,
		quantity: 116,
	},
	{
		garage_id: 4,
		part_id: 166,
		quantity: 19,
	},
	{
		garage_id: 4,
		part_id: 167,
		quantity: 152,
	},
	{
		garage_id: 4,
		part_id: 168,
		quantity: 368,
	},
	{
		garage_id: 4,
		part_id: 169,
		quantity: 182,
	},
	{
		garage_id: 4,
		part_id: 170,
		quantity: 331,
	},
	{
		garage_id: 4,
		part_id: 171,
		quantity: 116,
	},
	{
		garage_id: 4,
		part_id: 172,
		quantity: 122,
	},
	{
		garage_id: 4,
		part_id: 173,
		quantity: 444,
	},
	{
		garage_id: 4,
		part_id: 174,
		quantity: 439,
	},
	{
		garage_id: 4,
		part_id: 175,
		quantity: 356,
	},
	{
		garage_id: 4,
		part_id: 176,
		quantity: 489,
	},
	{
		garage_id: 4,
		part_id: 177,
		quantity: 435,
	},
	{
		garage_id: 4,
		part_id: 178,
		quantity: 161,
	},
	{
		garage_id: 4,
		part_id: 179,
		quantity: 461,
	},
	{
		garage_id: 4,
		part_id: 180,
		quantity: 223,
	},
	{
		garage_id: 4,
		part_id: 181,
		quantity: 215,
	},
	{
		garage_id: 4,
		part_id: 182,
		quantity: 362,
	},
	{
		garage_id: 4,
		part_id: 183,
		quantity: 188,
	},
	{
		garage_id: 4,
		part_id: 184,
		quantity: 417,
	},
	{
		garage_id: 4,
		part_id: 185,
		quantity: 233,
	},
	{
		garage_id: 4,
		part_id: 186,
		quantity: 396,
	},
	{
		garage_id: 4,
		part_id: 187,
		quantity: 442,
	},
	{
		garage_id: 4,
		part_id: 188,
		quantity: 75,
	},
	{
		garage_id: 4,
		part_id: 189,
		quantity: 218,
	},
	{
		garage_id: 4,
		part_id: 190,
		quantity: 406,
	},
	{
		garage_id: 4,
		part_id: 191,
		quantity: 349,
	},
	{
		garage_id: 4,
		part_id: 192,
		quantity: 95,
	},
	{
		garage_id: 4,
		part_id: 193,
		quantity: 438,
	},
	{
		garage_id: 4,
		part_id: 194,
		quantity: 436,
	},
	{
		garage_id: 4,
		part_id: 195,
		quantity: 181,
	},
	{
		garage_id: 4,
		part_id: 196,
		quantity: 390,
	},
	{
		garage_id: 4,
		part_id: 197,
		quantity: 211,
	},
	{
		garage_id: 4,
		part_id: 198,
		quantity: 87,
	},
	{
		garage_id: 4,
		part_id: 199,
		quantity: 203,
	},
	{
		garage_id: 4,
		part_id: 200,
		quantity: 402,
	},
	{
		garage_id: 4,
		part_id: 201,
		quantity: 183,
	},
	{
		garage_id: 4,
		part_id: 202,
		quantity: 275,
	},
	{
		garage_id: 4,
		part_id: 203,
		quantity: 170,
	},
	{
		garage_id: 4,
		part_id: 204,
		quantity: 292,
	},
	{
		garage_id: 4,
		part_id: 205,
		quantity: 435,
	},
	{
		garage_id: 4,
		part_id: 206,
		quantity: 198,
	},
	{
		garage_id: 4,
		part_id: 207,
		quantity: 413,
	},
	{
		garage_id: 4,
		part_id: 208,
		quantity: 75,
	},
	{
		garage_id: 4,
		part_id: 209,
		quantity: 338,
	},
	{
		garage_id: 4,
		part_id: 210,
		quantity: 415,
	},
	{
		garage_id: 4,
		part_id: 211,
		quantity: 168,
	},
	{
		garage_id: 4,
		part_id: 212,
		quantity: 201,
	},
	{
		garage_id: 4,
		part_id: 213,
		quantity: 337,
	},
	{
		garage_id: 4,
		part_id: 214,
		quantity: 22,
	},
	{
		garage_id: 4,
		part_id: 215,
		quantity: 260,
	},
	{
		garage_id: 4,
		part_id: 216,
		quantity: 46,
	},
	{
		garage_id: 4,
		part_id: 217,
		quantity: 2,
	},
	{
		garage_id: 4,
		part_id: 218,
		quantity: 287,
	},
	{
		garage_id: 4,
		part_id: 219,
		quantity: 164,
	},
	{
		garage_id: 4,
		part_id: 220,
		quantity: 226,
	},
	{
		garage_id: 4,
		part_id: 221,
		quantity: 418,
	},
	{
		garage_id: 4,
		part_id: 222,
		quantity: 407,
	},
	{
		garage_id: 4,
		part_id: 223,
		quantity: 283,
	},
	{
		garage_id: 4,
		part_id: 224,
		quantity: 418,
	},
	{
		garage_id: 4,
		part_id: 225,
		quantity: 257,
	},
	{
		garage_id: 4,
		part_id: 226,
		quantity: 25,
	},
	{
		garage_id: 4,
		part_id: 227,
		quantity: 4,
	},
	{
		garage_id: 4,
		part_id: 228,
		quantity: 156,
	},
	{
		garage_id: 4,
		part_id: 229,
		quantity: 180,
	},
	{
		garage_id: 4,
		part_id: 230,
		quantity: 465,
	},
	{
		garage_id: 4,
		part_id: 231,
		quantity: 301,
	},
	{
		garage_id: 4,
		part_id: 232,
		quantity: 257,
	},
	{
		garage_id: 4,
		part_id: 233,
		quantity: 378,
	},
	{
		garage_id: 4,
		part_id: 234,
		quantity: 261,
	},
	{
		garage_id: 4,
		part_id: 235,
		quantity: 299,
	},
	{
		garage_id: 4,
		part_id: 236,
		quantity: 101,
	},
	{
		garage_id: 4,
		part_id: 237,
		quantity: 140,
	},
	{
		garage_id: 4,
		part_id: 238,
		quantity: 41,
	},
	{
		garage_id: 4,
		part_id: 239,
		quantity: 306,
	},
	{
		garage_id: 4,
		part_id: 240,
		quantity: 306,
	},
	{
		garage_id: 4,
		part_id: 241,
		quantity: 202,
	},
	{
		garage_id: 4,
		part_id: 242,
		quantity: 431,
	},
	{
		garage_id: 4,
		part_id: 243,
		quantity: 32,
	},
	{
		garage_id: 4,
		part_id: 244,
		quantity: 228,
	},
	{
		garage_id: 4,
		part_id: 245,
		quantity: 342,
	},
	{
		garage_id: 4,
		part_id: 246,
		quantity: 210,
	},
	{
		garage_id: 4,
		part_id: 247,
		quantity: 147,
	},
	{
		garage_id: 4,
		part_id: 248,
		quantity: 471,
	},
	{
		garage_id: 4,
		part_id: 249,
		quantity: 136,
	},
	{
		garage_id: 4,
		part_id: 250,
		quantity: 121,
	},
	{
		garage_id: 4,
		part_id: 251,
		quantity: 66,
	},
	{
		garage_id: 4,
		part_id: 252,
		quantity: 310,
	},
	{
		garage_id: 4,
		part_id: 253,
		quantity: 2,
	},
	{
		garage_id: 4,
		part_id: 254,
		quantity: 240,
	},
	{
		garage_id: 4,
		part_id: 255,
		quantity: 208,
	},
	{
		garage_id: 4,
		part_id: 256,
		quantity: 165,
	},
	{
		garage_id: 4,
		part_id: 257,
		quantity: 286,
	},
	{
		garage_id: 4,
		part_id: 258,
		quantity: 67,
	},
	{
		garage_id: 4,
		part_id: 259,
		quantity: 128,
	},
	{
		garage_id: 4,
		part_id: 260,
		quantity: 449,
	},
	{
		garage_id: 4,
		part_id: 261,
		quantity: 363,
	},
	{
		garage_id: 4,
		part_id: 262,
		quantity: 146,
	},
	{
		garage_id: 4,
		part_id: 263,
		quantity: 376,
	},
	{
		garage_id: 4,
		part_id: 264,
		quantity: 272,
	},
	{
		garage_id: 4,
		part_id: 265,
		quantity: 303,
	},
	{
		garage_id: 4,
		part_id: 266,
		quantity: 461,
	},
	{
		garage_id: 4,
		part_id: 267,
		quantity: 260,
	},
	{
		garage_id: 4,
		part_id: 268,
		quantity: 248,
	},
	{
		garage_id: 4,
		part_id: 269,
		quantity: 461,
	},
	{
		garage_id: 4,
		part_id: 270,
		quantity: 239,
	},
	{
		garage_id: 4,
		part_id: 271,
		quantity: 403,
	},
	{
		garage_id: 4,
		part_id: 272,
		quantity: 375,
	},
	{
		garage_id: 4,
		part_id: 273,
		quantity: 137,
	},
	{
		garage_id: 4,
		part_id: 274,
		quantity: 290,
	},
	{
		garage_id: 4,
		part_id: 275,
		quantity: 461,
	},
	{
		garage_id: 4,
		part_id: 276,
		quantity: 287,
	},
	{
		garage_id: 4,
		part_id: 277,
		quantity: 400,
	},
	{
		garage_id: 4,
		part_id: 278,
		quantity: 174,
	},
	{
		garage_id: 4,
		part_id: 279,
		quantity: 131,
	},
	{
		garage_id: 4,
		part_id: 280,
		quantity: 383,
	},
	{
		garage_id: 4,
		part_id: 281,
		quantity: 275,
	},
	{
		garage_id: 4,
		part_id: 282,
		quantity: 180,
	},
	{
		garage_id: 4,
		part_id: 283,
		quantity: 29,
	},
	{
		garage_id: 4,
		part_id: 284,
		quantity: 204,
	},
	{
		garage_id: 4,
		part_id: 285,
		quantity: 495,
	},
	{
		garage_id: 4,
		part_id: 286,
		quantity: 237,
	},
	{
		garage_id: 4,
		part_id: 287,
		quantity: 342,
	},
	{
		garage_id: 4,
		part_id: 288,
		quantity: 262,
	},
	{
		garage_id: 4,
		part_id: 289,
		quantity: 406,
	},
	{
		garage_id: 4,
		part_id: 290,
		quantity: 34,
	},
	{
		garage_id: 4,
		part_id: 291,
		quantity: 131,
	},
	{
		garage_id: 4,
		part_id: 292,
		quantity: 150,
	},
	{
		garage_id: 4,
		part_id: 293,
		quantity: 493,
	},
	{
		garage_id: 4,
		part_id: 294,
		quantity: 222,
	},
	{
		garage_id: 4,
		part_id: 295,
		quantity: 428,
	},
	{
		garage_id: 4,
		part_id: 296,
		quantity: 472,
	},
	{
		garage_id: 4,
		part_id: 297,
		quantity: 136,
	},
	{
		garage_id: 4,
		part_id: 298,
		quantity: 429,
	},
	{
		garage_id: 4,
		part_id: 299,
		quantity: 347,
	},
	{
		garage_id: 4,
		part_id: 300,
		quantity: 298,
	},
	{
		garage_id: 4,
		part_id: 301,
		quantity: 477,
	},
	{
		garage_id: 4,
		part_id: 302,
		quantity: 468,
	},
	{
		garage_id: 4,
		part_id: 303,
		quantity: 150,
	},
	{
		garage_id: 4,
		part_id: 304,
		quantity: 329,
	},
	{
		garage_id: 4,
		part_id: 305,
		quantity: 180,
	},
	{
		garage_id: 4,
		part_id: 306,
		quantity: 410,
	},
	{
		garage_id: 4,
		part_id: 307,
		quantity: 302,
	},
	{
		garage_id: 4,
		part_id: 308,
		quantity: 304,
	},
	{
		garage_id: 4,
		part_id: 309,
		quantity: 77,
	},
	{
		garage_id: 4,
		part_id: 310,
		quantity: 134,
	},
	{
		garage_id: 4,
		part_id: 311,
		quantity: 280,
	},
	{
		garage_id: 4,
		part_id: 312,
		quantity: 331,
	},
	{
		garage_id: 4,
		part_id: 313,
		quantity: 176,
	},
	{
		garage_id: 4,
		part_id: 314,
		quantity: 304,
	},
	{
		garage_id: 4,
		part_id: 315,
		quantity: 384,
	},
	{
		garage_id: 4,
		part_id: 316,
		quantity: 488,
	},
	{
		garage_id: 4,
		part_id: 317,
		quantity: 365,
	},
	{
		garage_id: 4,
		part_id: 318,
		quantity: 475,
	},
	{
		garage_id: 4,
		part_id: 319,
		quantity: 343,
	},
	{
		garage_id: 4,
		part_id: 320,
		quantity: 425,
	},
	{
		garage_id: 4,
		part_id: 321,
		quantity: 340,
	},
	{
		garage_id: 4,
		part_id: 322,
		quantity: 62,
	},
	{
		garage_id: 4,
		part_id: 323,
		quantity: 458,
	},
	{
		garage_id: 4,
		part_id: 324,
		quantity: 21,
	},
	{
		garage_id: 4,
		part_id: 325,
		quantity: 222,
	},
	{
		garage_id: 4,
		part_id: 326,
		quantity: 42,
	},
	{
		garage_id: 4,
		part_id: 327,
		quantity: 491,
	},
	{
		garage_id: 4,
		part_id: 328,
		quantity: 176,
	},
	{
		garage_id: 4,
		part_id: 329,
		quantity: 339,
	},
	{
		garage_id: 4,
		part_id: 330,
		quantity: 116,
	},
	{
		garage_id: 4,
		part_id: 331,
		quantity: 413,
	},
	{
		garage_id: 4,
		part_id: 332,
		quantity: 143,
	},
	{
		garage_id: 4,
		part_id: 333,
		quantity: 112,
	},
	{
		garage_id: 4,
		part_id: 334,
		quantity: 118,
	},
	{
		garage_id: 4,
		part_id: 335,
		quantity: 490,
	},
	{
		garage_id: 4,
		part_id: 336,
		quantity: 206,
	},
	{
		garage_id: 4,
		part_id: 337,
		quantity: 197,
	},
	{
		garage_id: 4,
		part_id: 338,
		quantity: 243,
	},
	{
		garage_id: 4,
		part_id: 339,
		quantity: 434,
	},
	{
		garage_id: 4,
		part_id: 340,
		quantity: 53,
	},
	{
		garage_id: 4,
		part_id: 341,
		quantity: 343,
	},
	{
		garage_id: 4,
		part_id: 342,
		quantity: 89,
	},
	{
		garage_id: 4,
		part_id: 343,
		quantity: 282,
	},
	{
		garage_id: 4,
		part_id: 344,
		quantity: 375,
	},
	{
		garage_id: 4,
		part_id: 345,
		quantity: 372,
	},
	{
		garage_id: 4,
		part_id: 346,
		quantity: 271,
	},
	{
		garage_id: 4,
		part_id: 347,
		quantity: 412,
	},
	{
		garage_id: 4,
		part_id: 348,
		quantity: 443,
	},
	{
		garage_id: 4,
		part_id: 349,
		quantity: 40,
	},
	{
		garage_id: 4,
		part_id: 350,
		quantity: 496,
	},
	{
		garage_id: 4,
		part_id: 351,
		quantity: 479,
	},
	{
		garage_id: 4,
		part_id: 352,
		quantity: 24,
	},
	{
		garage_id: 4,
		part_id: 353,
		quantity: 350,
	},
	{
		garage_id: 4,
		part_id: 354,
		quantity: 224,
	},
	{
		garage_id: 4,
		part_id: 355,
		quantity: 439,
	},
	{
		garage_id: 4,
		part_id: 356,
		quantity: 293,
	},
	{
		garage_id: 4,
		part_id: 357,
		quantity: 37,
	},
	{
		garage_id: 4,
		part_id: 358,
		quantity: 9,
	},
	{
		garage_id: 4,
		part_id: 359,
		quantity: 409,
	},
	{
		garage_id: 4,
		part_id: 360,
		quantity: 406,
	},
	{
		garage_id: 4,
		part_id: 361,
		quantity: 454,
	},
	{
		garage_id: 4,
		part_id: 362,
		quantity: 267,
	},
	{
		garage_id: 4,
		part_id: 363,
		quantity: 493,
	},
	{
		garage_id: 4,
		part_id: 364,
		quantity: 129,
	},
	{
		garage_id: 4,
		part_id: 365,
		quantity: 420,
	},
	{
		garage_id: 4,
		part_id: 366,
		quantity: 261,
	},
	{
		garage_id: 4,
		part_id: 367,
		quantity: 448,
	},
	{
		garage_id: 4,
		part_id: 368,
		quantity: 69,
	},
	{
		garage_id: 4,
		part_id: 369,
		quantity: 154,
	},
	{
		garage_id: 4,
		part_id: 370,
		quantity: 55,
	},
	{
		garage_id: 4,
		part_id: 371,
		quantity: 254,
	},
	{
		garage_id: 4,
		part_id: 372,
		quantity: 289,
	},
	{
		garage_id: 4,
		part_id: 373,
		quantity: 144,
	},
	{
		garage_id: 4,
		part_id: 374,
		quantity: 192,
	},
	{
		garage_id: 4,
		part_id: 375,
		quantity: 349,
	},
	{
		garage_id: 4,
		part_id: 376,
		quantity: 384,
	},
	{
		garage_id: 4,
		part_id: 377,
		quantity: 191,
	},
	{
		garage_id: 4,
		part_id: 378,
		quantity: 94,
	},
	{
		garage_id: 4,
		part_id: 379,
		quantity: 424,
	},
	{
		garage_id: 4,
		part_id: 380,
		quantity: 407,
	},
	{
		garage_id: 4,
		part_id: 381,
		quantity: 477,
	},
	{
		garage_id: 4,
		part_id: 382,
		quantity: 92,
	},
	{
		garage_id: 4,
		part_id: 383,
		quantity: 179,
	},
	{
		garage_id: 4,
		part_id: 384,
		quantity: 196,
	},
	{
		garage_id: 4,
		part_id: 385,
		quantity: 203,
	},
	{
		garage_id: 4,
		part_id: 386,
		quantity: 84,
	},
	{
		garage_id: 4,
		part_id: 387,
		quantity: 312,
	},
	{
		garage_id: 4,
		part_id: 388,
		quantity: 186,
	},
	{
		garage_id: 4,
		part_id: 389,
		quantity: 88,
	},
	{
		garage_id: 4,
		part_id: 390,
		quantity: 485,
	},
	{
		garage_id: 4,
		part_id: 391,
		quantity: 346,
	},
	{
		garage_id: 4,
		part_id: 392,
		quantity: 106,
	},
	{
		garage_id: 4,
		part_id: 393,
		quantity: 386,
	},
	{
		garage_id: 4,
		part_id: 394,
		quantity: 1,
	},
	{
		garage_id: 4,
		part_id: 395,
		quantity: 353,
	},
	{
		garage_id: 4,
		part_id: 396,
		quantity: 186,
	},
	{
		garage_id: 4,
		part_id: 397,
		quantity: 462,
	},
	{
		garage_id: 4,
		part_id: 398,
		quantity: 112,
	},
	{
		garage_id: 4,
		part_id: 399,
		quantity: 462,
	},
	{
		garage_id: 5,
		part_id: 0,
		quantity: 3,
	},
	{
		garage_id: 5,
		part_id: 1,
		quantity: 176,
	},
	{
		garage_id: 5,
		part_id: 2,
		quantity: 43,
	},
	{
		garage_id: 5,
		part_id: 3,
		quantity: 490,
	},
	{
		garage_id: 5,
		part_id: 4,
		quantity: 411,
	},
	{
		garage_id: 5,
		part_id: 5,
		quantity: 119,
	},
	{
		garage_id: 5,
		part_id: 6,
		quantity: 90,
	},
	{
		garage_id: 5,
		part_id: 7,
		quantity: 253,
	},
	{
		garage_id: 5,
		part_id: 8,
		quantity: 5,
	},
	{
		garage_id: 5,
		part_id: 9,
		quantity: 256,
	},
	{
		garage_id: 5,
		part_id: 10,
		quantity: 250,
	},
	{
		garage_id: 5,
		part_id: 11,
		quantity: 423,
	},
	{
		garage_id: 5,
		part_id: 12,
		quantity: 94,
	},
	{
		garage_id: 5,
		part_id: 13,
		quantity: 166,
	},
	{
		garage_id: 5,
		part_id: 14,
		quantity: 346,
	},
	{
		garage_id: 5,
		part_id: 15,
		quantity: 350,
	},
	{
		garage_id: 5,
		part_id: 16,
		quantity: 154,
	},
	{
		garage_id: 5,
		part_id: 17,
		quantity: 479,
	},
	{
		garage_id: 5,
		part_id: 18,
		quantity: 205,
	},
	{
		garage_id: 5,
		part_id: 19,
		quantity: 203,
	},
	{
		garage_id: 5,
		part_id: 20,
		quantity: 79,
	},
	{
		garage_id: 5,
		part_id: 21,
		quantity: 84,
	},
	{
		garage_id: 5,
		part_id: 22,
		quantity: 119,
	},
	{
		garage_id: 5,
		part_id: 23,
		quantity: 20,
	},
	{
		garage_id: 5,
		part_id: 24,
		quantity: 277,
	},
	{
		garage_id: 5,
		part_id: 25,
		quantity: 263,
	},
	{
		garage_id: 5,
		part_id: 26,
		quantity: 198,
	},
	{
		garage_id: 5,
		part_id: 27,
		quantity: 401,
	},
	{
		garage_id: 5,
		part_id: 28,
		quantity: 59,
	},
	{
		garage_id: 5,
		part_id: 29,
		quantity: 480,
	},
	{
		garage_id: 5,
		part_id: 30,
		quantity: 171,
	},
	{
		garage_id: 5,
		part_id: 31,
		quantity: 265,
	},
	{
		garage_id: 5,
		part_id: 32,
		quantity: 486,
	},
	{
		garage_id: 5,
		part_id: 33,
		quantity: 476,
	},
	{
		garage_id: 5,
		part_id: 34,
		quantity: 400,
	},
	{
		garage_id: 5,
		part_id: 35,
		quantity: 291,
	},
	{
		garage_id: 5,
		part_id: 36,
		quantity: 82,
	},
	{
		garage_id: 5,
		part_id: 37,
		quantity: 169,
	},
	{
		garage_id: 5,
		part_id: 38,
		quantity: 24,
	},
	{
		garage_id: 5,
		part_id: 39,
		quantity: 243,
	},
	{
		garage_id: 5,
		part_id: 40,
		quantity: 46,
	},
	{
		garage_id: 5,
		part_id: 41,
		quantity: 96,
	},
	{
		garage_id: 5,
		part_id: 42,
		quantity: 96,
	},
	{
		garage_id: 5,
		part_id: 43,
		quantity: 102,
	},
	{
		garage_id: 5,
		part_id: 44,
		quantity: 304,
	},
	{
		garage_id: 5,
		part_id: 45,
		quantity: 384,
	},
	{
		garage_id: 5,
		part_id: 46,
		quantity: 416,
	},
	{
		garage_id: 5,
		part_id: 47,
		quantity: 173,
	},
	{
		garage_id: 5,
		part_id: 48,
		quantity: 307,
	},
	{
		garage_id: 5,
		part_id: 49,
		quantity: 350,
	},
	{
		garage_id: 5,
		part_id: 50,
		quantity: 236,
	},
	{
		garage_id: 5,
		part_id: 51,
		quantity: 162,
	},
	{
		garage_id: 5,
		part_id: 52,
		quantity: 65,
	},
	{
		garage_id: 5,
		part_id: 53,
		quantity: 445,
	},
	{
		garage_id: 5,
		part_id: 54,
		quantity: 110,
	},
	{
		garage_id: 5,
		part_id: 55,
		quantity: 432,
	},
	{
		garage_id: 5,
		part_id: 56,
		quantity: 493,
	},
	{
		garage_id: 5,
		part_id: 57,
		quantity: 184,
	},
	{
		garage_id: 5,
		part_id: 58,
		quantity: 388,
	},
	{
		garage_id: 5,
		part_id: 59,
		quantity: 232,
	},
	{
		garage_id: 5,
		part_id: 60,
		quantity: 296,
	},
	{
		garage_id: 5,
		part_id: 61,
		quantity: 196,
	},
	{
		garage_id: 5,
		part_id: 62,
		quantity: 188,
	},
	{
		garage_id: 5,
		part_id: 63,
		quantity: 297,
	},
	{
		garage_id: 5,
		part_id: 64,
		quantity: 106,
	},
	{
		garage_id: 5,
		part_id: 65,
		quantity: 41,
	},
	{
		garage_id: 5,
		part_id: 66,
		quantity: 156,
	},
	{
		garage_id: 5,
		part_id: 67,
		quantity: 308,
	},
	{
		garage_id: 5,
		part_id: 68,
		quantity: 44,
	},
	{
		garage_id: 5,
		part_id: 69,
		quantity: 382,
	},
	{
		garage_id: 5,
		part_id: 70,
		quantity: 499,
	},
	{
		garage_id: 5,
		part_id: 71,
		quantity: 235,
	},
	{
		garage_id: 5,
		part_id: 72,
		quantity: 27,
	},
	{
		garage_id: 5,
		part_id: 73,
		quantity: 236,
	},
	{
		garage_id: 5,
		part_id: 74,
		quantity: 242,
	},
	{
		garage_id: 5,
		part_id: 75,
		quantity: 183,
	},
	{
		garage_id: 5,
		part_id: 76,
		quantity: 331,
	},
	{
		garage_id: 5,
		part_id: 77,
		quantity: 239,
	},
	{
		garage_id: 5,
		part_id: 78,
		quantity: 372,
	},
	{
		garage_id: 5,
		part_id: 79,
		quantity: 143,
	},
	{
		garage_id: 5,
		part_id: 80,
		quantity: 19,
	},
	{
		garage_id: 5,
		part_id: 81,
		quantity: 316,
	},
	{
		garage_id: 5,
		part_id: 82,
		quantity: 112,
	},
	{
		garage_id: 5,
		part_id: 83,
		quantity: 198,
	},
	{
		garage_id: 5,
		part_id: 84,
		quantity: 450,
	},
	{
		garage_id: 5,
		part_id: 85,
		quantity: 489,
	},
	{
		garage_id: 5,
		part_id: 86,
		quantity: 363,
	},
	{
		garage_id: 5,
		part_id: 87,
		quantity: 1,
	},
	{
		garage_id: 5,
		part_id: 88,
		quantity: 92,
	},
	{
		garage_id: 5,
		part_id: 89,
		quantity: 155,
	},
	{
		garage_id: 5,
		part_id: 90,
		quantity: 72,
	},
	{
		garage_id: 5,
		part_id: 91,
		quantity: 16,
	},
	{
		garage_id: 5,
		part_id: 92,
		quantity: 38,
	},
	{
		garage_id: 5,
		part_id: 93,
		quantity: 44,
	},
	{
		garage_id: 5,
		part_id: 94,
		quantity: 303,
	},
	{
		garage_id: 5,
		part_id: 95,
		quantity: 224,
	},
	{
		garage_id: 5,
		part_id: 96,
		quantity: 345,
	},
	{
		garage_id: 5,
		part_id: 97,
		quantity: 16,
	},
	{
		garage_id: 5,
		part_id: 98,
		quantity: 68,
	},
	{
		garage_id: 5,
		part_id: 99,
		quantity: 163,
	},
	{
		garage_id: 5,
		part_id: 100,
		quantity: 494,
	},
	{
		garage_id: 5,
		part_id: 101,
		quantity: 385,
	},
	{
		garage_id: 5,
		part_id: 102,
		quantity: 199,
	},
	{
		garage_id: 5,
		part_id: 103,
		quantity: 72,
	},
	{
		garage_id: 5,
		part_id: 104,
		quantity: 111,
	},
	{
		garage_id: 5,
		part_id: 105,
		quantity: 194,
	},
	{
		garage_id: 5,
		part_id: 106,
		quantity: 156,
	},
	{
		garage_id: 5,
		part_id: 107,
		quantity: 23,
	},
	{
		garage_id: 5,
		part_id: 108,
		quantity: 209,
	},
	{
		garage_id: 5,
		part_id: 109,
		quantity: 377,
	},
	{
		garage_id: 5,
		part_id: 110,
		quantity: 421,
	},
	{
		garage_id: 5,
		part_id: 111,
		quantity: 105,
	},
	{
		garage_id: 5,
		part_id: 112,
		quantity: 262,
	},
	{
		garage_id: 5,
		part_id: 113,
		quantity: 210,
	},
	{
		garage_id: 5,
		part_id: 114,
		quantity: 483,
	},
	{
		garage_id: 5,
		part_id: 115,
		quantity: 105,
	},
	{
		garage_id: 5,
		part_id: 116,
		quantity: 217,
	},
	{
		garage_id: 5,
		part_id: 117,
		quantity: 5,
	},
	{
		garage_id: 5,
		part_id: 118,
		quantity: 470,
	},
	{
		garage_id: 5,
		part_id: 119,
		quantity: 190,
	},
	{
		garage_id: 5,
		part_id: 120,
		quantity: 247,
	},
	{
		garage_id: 5,
		part_id: 121,
		quantity: 153,
	},
	{
		garage_id: 5,
		part_id: 122,
		quantity: 242,
	},
	{
		garage_id: 5,
		part_id: 123,
		quantity: 431,
	},
	{
		garage_id: 5,
		part_id: 124,
		quantity: 20,
	},
	{
		garage_id: 5,
		part_id: 125,
		quantity: 392,
	},
	{
		garage_id: 5,
		part_id: 126,
		quantity: 416,
	},
	{
		garage_id: 5,
		part_id: 127,
		quantity: 225,
	},
	{
		garage_id: 5,
		part_id: 128,
		quantity: 211,
	},
	{
		garage_id: 5,
		part_id: 129,
		quantity: 494,
	},
	{
		garage_id: 5,
		part_id: 130,
		quantity: 242,
	},
	{
		garage_id: 5,
		part_id: 131,
		quantity: 25,
	},
	{
		garage_id: 5,
		part_id: 132,
		quantity: 126,
	},
	{
		garage_id: 5,
		part_id: 133,
		quantity: 43,
	},
	{
		garage_id: 5,
		part_id: 134,
		quantity: 20,
	},
	{
		garage_id: 5,
		part_id: 135,
		quantity: 22,
	},
	{
		garage_id: 5,
		part_id: 136,
		quantity: 359,
	},
	{
		garage_id: 5,
		part_id: 137,
		quantity: 168,
	},
	{
		garage_id: 5,
		part_id: 138,
		quantity: 61,
	},
	{
		garage_id: 5,
		part_id: 139,
		quantity: 57,
	},
	{
		garage_id: 5,
		part_id: 140,
		quantity: 42,
	},
	{
		garage_id: 5,
		part_id: 141,
		quantity: 451,
	},
	{
		garage_id: 5,
		part_id: 142,
		quantity: 341,
	},
	{
		garage_id: 5,
		part_id: 143,
		quantity: 87,
	},
	{
		garage_id: 5,
		part_id: 144,
		quantity: 250,
	},
	{
		garage_id: 5,
		part_id: 145,
		quantity: 368,
	},
	{
		garage_id: 5,
		part_id: 146,
		quantity: 233,
	},
	{
		garage_id: 5,
		part_id: 147,
		quantity: 222,
	},
	{
		garage_id: 5,
		part_id: 148,
		quantity: 392,
	},
	{
		garage_id: 5,
		part_id: 149,
		quantity: 54,
	},
	{
		garage_id: 5,
		part_id: 150,
		quantity: 103,
	},
	{
		garage_id: 5,
		part_id: 151,
		quantity: 260,
	},
	{
		garage_id: 5,
		part_id: 152,
		quantity: 207,
	},
	{
		garage_id: 5,
		part_id: 153,
		quantity: 396,
	},
	{
		garage_id: 5,
		part_id: 154,
		quantity: 232,
	},
	{
		garage_id: 5,
		part_id: 155,
		quantity: 73,
	},
	{
		garage_id: 5,
		part_id: 156,
		quantity: 298,
	},
	{
		garage_id: 5,
		part_id: 157,
		quantity: 500,
	},
	{
		garage_id: 5,
		part_id: 158,
		quantity: 114,
	},
	{
		garage_id: 5,
		part_id: 159,
		quantity: 325,
	},
	{
		garage_id: 5,
		part_id: 160,
		quantity: 285,
	},
	{
		garage_id: 5,
		part_id: 161,
		quantity: 334,
	},
	{
		garage_id: 5,
		part_id: 162,
		quantity: 379,
	},
	{
		garage_id: 5,
		part_id: 163,
		quantity: 498,
	},
	{
		garage_id: 5,
		part_id: 164,
		quantity: 415,
	},
	{
		garage_id: 5,
		part_id: 165,
		quantity: 441,
	},
	{
		garage_id: 5,
		part_id: 166,
		quantity: 102,
	},
	{
		garage_id: 5,
		part_id: 167,
		quantity: 348,
	},
	{
		garage_id: 5,
		part_id: 168,
		quantity: 497,
	},
	{
		garage_id: 5,
		part_id: 169,
		quantity: 341,
	},
	{
		garage_id: 5,
		part_id: 170,
		quantity: 183,
	},
	{
		garage_id: 5,
		part_id: 171,
		quantity: 283,
	},
	{
		garage_id: 5,
		part_id: 172,
		quantity: 458,
	},
	{
		garage_id: 5,
		part_id: 173,
		quantity: 220,
	},
	{
		garage_id: 5,
		part_id: 174,
		quantity: 175,
	},
	{
		garage_id: 5,
		part_id: 175,
		quantity: 184,
	},
	{
		garage_id: 5,
		part_id: 176,
		quantity: 275,
	},
	{
		garage_id: 5,
		part_id: 177,
		quantity: 361,
	},
	{
		garage_id: 5,
		part_id: 178,
		quantity: 33,
	},
	{
		garage_id: 5,
		part_id: 179,
		quantity: 404,
	},
	{
		garage_id: 5,
		part_id: 180,
		quantity: 420,
	},
	{
		garage_id: 5,
		part_id: 181,
		quantity: 66,
	},
	{
		garage_id: 5,
		part_id: 182,
		quantity: 457,
	},
	{
		garage_id: 5,
		part_id: 183,
		quantity: 259,
	},
	{
		garage_id: 5,
		part_id: 184,
		quantity: 136,
	},
	{
		garage_id: 5,
		part_id: 185,
		quantity: 274,
	},
	{
		garage_id: 5,
		part_id: 186,
		quantity: 469,
	},
	{
		garage_id: 5,
		part_id: 187,
		quantity: 343,
	},
	{
		garage_id: 5,
		part_id: 188,
		quantity: 167,
	},
	{
		garage_id: 5,
		part_id: 189,
		quantity: 366,
	},
	{
		garage_id: 5,
		part_id: 190,
		quantity: 171,
	},
	{
		garage_id: 5,
		part_id: 191,
		quantity: 16,
	},
	{
		garage_id: 5,
		part_id: 192,
		quantity: 457,
	},
	{
		garage_id: 5,
		part_id: 193,
		quantity: 166,
	},
	{
		garage_id: 5,
		part_id: 194,
		quantity: 221,
	},
	{
		garage_id: 5,
		part_id: 195,
		quantity: 127,
	},
	{
		garage_id: 5,
		part_id: 196,
		quantity: 190,
	},
	{
		garage_id: 5,
		part_id: 197,
		quantity: 437,
	},
	{
		garage_id: 5,
		part_id: 198,
		quantity: 431,
	},
	{
		garage_id: 5,
		part_id: 199,
		quantity: 361,
	},
	{
		garage_id: 5,
		part_id: 200,
		quantity: 115,
	},
	{
		garage_id: 5,
		part_id: 201,
		quantity: 416,
	},
	{
		garage_id: 5,
		part_id: 202,
		quantity: 2,
	},
	{
		garage_id: 5,
		part_id: 203,
		quantity: 352,
	},
	{
		garage_id: 5,
		part_id: 204,
		quantity: 210,
	},
	{
		garage_id: 5,
		part_id: 205,
		quantity: 226,
	},
	{
		garage_id: 5,
		part_id: 206,
		quantity: 267,
	},
	{
		garage_id: 5,
		part_id: 207,
		quantity: 225,
	},
	{
		garage_id: 5,
		part_id: 208,
		quantity: 113,
	},
	{
		garage_id: 5,
		part_id: 209,
		quantity: 286,
	},
	{
		garage_id: 5,
		part_id: 210,
		quantity: 150,
	},
	{
		garage_id: 5,
		part_id: 211,
		quantity: 37,
	},
	{
		garage_id: 5,
		part_id: 212,
		quantity: 39,
	},
	{
		garage_id: 5,
		part_id: 213,
		quantity: 12,
	},
	{
		garage_id: 5,
		part_id: 214,
		quantity: 448,
	},
	{
		garage_id: 5,
		part_id: 215,
		quantity: 324,
	},
	{
		garage_id: 5,
		part_id: 216,
		quantity: 224,
	},
	{
		garage_id: 5,
		part_id: 217,
		quantity: 102,
	},
	{
		garage_id: 5,
		part_id: 218,
		quantity: 339,
	},
	{
		garage_id: 5,
		part_id: 219,
		quantity: 156,
	},
	{
		garage_id: 5,
		part_id: 220,
		quantity: 75,
	},
	{
		garage_id: 5,
		part_id: 221,
		quantity: 303,
	},
	{
		garage_id: 5,
		part_id: 222,
		quantity: 140,
	},
	{
		garage_id: 5,
		part_id: 223,
		quantity: 400,
	},
	{
		garage_id: 5,
		part_id: 224,
		quantity: 495,
	},
	{
		garage_id: 5,
		part_id: 225,
		quantity: 193,
	},
	{
		garage_id: 5,
		part_id: 226,
		quantity: 392,
	},
	{
		garage_id: 5,
		part_id: 227,
		quantity: 147,
	},
	{
		garage_id: 5,
		part_id: 228,
		quantity: 384,
	},
	{
		garage_id: 5,
		part_id: 229,
		quantity: 39,
	},
	{
		garage_id: 5,
		part_id: 230,
		quantity: 378,
	},
	{
		garage_id: 5,
		part_id: 231,
		quantity: 47,
	},
	{
		garage_id: 5,
		part_id: 232,
		quantity: 417,
	},
	{
		garage_id: 5,
		part_id: 233,
		quantity: 250,
	},
	{
		garage_id: 5,
		part_id: 234,
		quantity: 419,
	},
	{
		garage_id: 5,
		part_id: 235,
		quantity: 453,
	},
	{
		garage_id: 5,
		part_id: 236,
		quantity: 58,
	},
	{
		garage_id: 5,
		part_id: 237,
		quantity: 433,
	},
	{
		garage_id: 5,
		part_id: 238,
		quantity: 118,
	},
	{
		garage_id: 5,
		part_id: 239,
		quantity: 290,
	},
	{
		garage_id: 5,
		part_id: 240,
		quantity: 97,
	},
	{
		garage_id: 5,
		part_id: 241,
		quantity: 278,
	},
	{
		garage_id: 5,
		part_id: 242,
		quantity: 200,
	},
	{
		garage_id: 5,
		part_id: 243,
		quantity: 110,
	},
	{
		garage_id: 5,
		part_id: 244,
		quantity: 452,
	},
	{
		garage_id: 5,
		part_id: 245,
		quantity: 28,
	},
	{
		garage_id: 5,
		part_id: 246,
		quantity: 470,
	},
	{
		garage_id: 5,
		part_id: 247,
		quantity: 395,
	},
	{
		garage_id: 5,
		part_id: 248,
		quantity: 257,
	},
	{
		garage_id: 5,
		part_id: 249,
		quantity: 486,
	},
	{
		garage_id: 5,
		part_id: 250,
		quantity: 344,
	},
	{
		garage_id: 5,
		part_id: 251,
		quantity: 185,
	},
	{
		garage_id: 5,
		part_id: 252,
		quantity: 257,
	},
	{
		garage_id: 5,
		part_id: 253,
		quantity: 69,
	},
	{
		garage_id: 5,
		part_id: 254,
		quantity: 382,
	},
	{
		garage_id: 5,
		part_id: 255,
		quantity: 408,
	},
	{
		garage_id: 5,
		part_id: 256,
		quantity: 179,
	},
	{
		garage_id: 5,
		part_id: 257,
		quantity: 295,
	},
	{
		garage_id: 5,
		part_id: 258,
		quantity: 73,
	},
	{
		garage_id: 5,
		part_id: 259,
		quantity: 317,
	},
	{
		garage_id: 5,
		part_id: 260,
		quantity: 267,
	},
	{
		garage_id: 5,
		part_id: 261,
		quantity: 72,
	},
	{
		garage_id: 5,
		part_id: 262,
		quantity: 404,
	},
	{
		garage_id: 5,
		part_id: 263,
		quantity: 267,
	},
	{
		garage_id: 5,
		part_id: 264,
		quantity: 60,
	},
	{
		garage_id: 5,
		part_id: 265,
		quantity: 83,
	},
	{
		garage_id: 5,
		part_id: 266,
		quantity: 72,
	},
	{
		garage_id: 5,
		part_id: 267,
		quantity: 266,
	},
	{
		garage_id: 5,
		part_id: 268,
		quantity: 33,
	},
	{
		garage_id: 5,
		part_id: 269,
		quantity: 219,
	},
	{
		garage_id: 5,
		part_id: 270,
		quantity: 376,
	},
	{
		garage_id: 5,
		part_id: 271,
		quantity: 59,
	},
	{
		garage_id: 5,
		part_id: 272,
		quantity: 346,
	},
	{
		garage_id: 5,
		part_id: 273,
		quantity: 272,
	},
	{
		garage_id: 5,
		part_id: 274,
		quantity: 84,
	},
	{
		garage_id: 5,
		part_id: 275,
		quantity: 465,
	},
	{
		garage_id: 5,
		part_id: 276,
		quantity: 339,
	},
	{
		garage_id: 5,
		part_id: 277,
		quantity: 108,
	},
	{
		garage_id: 5,
		part_id: 278,
		quantity: 105,
	},
	{
		garage_id: 5,
		part_id: 279,
		quantity: 399,
	},
	{
		garage_id: 5,
		part_id: 280,
		quantity: 84,
	},
	{
		garage_id: 5,
		part_id: 281,
		quantity: 290,
	},
	{
		garage_id: 5,
		part_id: 282,
		quantity: 131,
	},
	{
		garage_id: 5,
		part_id: 283,
		quantity: 336,
	},
	{
		garage_id: 5,
		part_id: 284,
		quantity: 58,
	},
	{
		garage_id: 5,
		part_id: 285,
		quantity: 294,
	},
	{
		garage_id: 5,
		part_id: 286,
		quantity: 463,
	},
	{
		garage_id: 5,
		part_id: 287,
		quantity: 142,
	},
	{
		garage_id: 5,
		part_id: 288,
		quantity: 499,
	},
	{
		garage_id: 5,
		part_id: 289,
		quantity: 197,
	},
	{
		garage_id: 5,
		part_id: 290,
		quantity: 428,
	},
	{
		garage_id: 5,
		part_id: 291,
		quantity: 1,
	},
	{
		garage_id: 5,
		part_id: 292,
		quantity: 358,
	},
	{
		garage_id: 5,
		part_id: 293,
		quantity: 2,
	},
	{
		garage_id: 5,
		part_id: 294,
		quantity: 431,
	},
	{
		garage_id: 5,
		part_id: 295,
		quantity: 244,
	},
	{
		garage_id: 5,
		part_id: 296,
		quantity: 283,
	},
	{
		garage_id: 5,
		part_id: 297,
		quantity: 333,
	},
	{
		garage_id: 5,
		part_id: 298,
		quantity: 169,
	},
	{
		garage_id: 5,
		part_id: 299,
		quantity: 55,
	},
	{
		garage_id: 5,
		part_id: 300,
		quantity: 267,
	},
	{
		garage_id: 5,
		part_id: 301,
		quantity: 387,
	},
	{
		garage_id: 5,
		part_id: 302,
		quantity: 390,
	},
	{
		garage_id: 5,
		part_id: 303,
		quantity: 444,
	},
	{
		garage_id: 5,
		part_id: 304,
		quantity: 54,
	},
	{
		garage_id: 5,
		part_id: 305,
		quantity: 167,
	},
	{
		garage_id: 5,
		part_id: 306,
		quantity: 367,
	},
	{
		garage_id: 5,
		part_id: 307,
		quantity: 232,
	},
	{
		garage_id: 5,
		part_id: 308,
		quantity: 370,
	},
	{
		garage_id: 5,
		part_id: 309,
		quantity: 248,
	},
	{
		garage_id: 5,
		part_id: 310,
		quantity: 184,
	},
	{
		garage_id: 5,
		part_id: 311,
		quantity: 17,
	},
	{
		garage_id: 5,
		part_id: 312,
		quantity: 483,
	},
	{
		garage_id: 5,
		part_id: 313,
		quantity: 138,
	},
	{
		garage_id: 5,
		part_id: 314,
		quantity: 44,
	},
	{
		garage_id: 5,
		part_id: 315,
		quantity: 55,
	},
	{
		garage_id: 5,
		part_id: 316,
		quantity: 316,
	},
	{
		garage_id: 5,
		part_id: 317,
		quantity: 7,
	},
	{
		garage_id: 5,
		part_id: 318,
		quantity: 167,
	},
	{
		garage_id: 5,
		part_id: 319,
		quantity: 3,
	},
	{
		garage_id: 5,
		part_id: 320,
		quantity: 254,
	},
	{
		garage_id: 5,
		part_id: 321,
		quantity: 266,
	},
	{
		garage_id: 5,
		part_id: 322,
		quantity: 111,
	},
	{
		garage_id: 5,
		part_id: 323,
		quantity: 59,
	},
	{
		garage_id: 5,
		part_id: 324,
		quantity: 87,
	},
	{
		garage_id: 5,
		part_id: 325,
		quantity: 202,
	},
	{
		garage_id: 5,
		part_id: 326,
		quantity: 308,
	},
	{
		garage_id: 5,
		part_id: 327,
		quantity: 86,
	},
	{
		garage_id: 5,
		part_id: 328,
		quantity: 70,
	},
	{
		garage_id: 5,
		part_id: 329,
		quantity: 370,
	},
	{
		garage_id: 5,
		part_id: 330,
		quantity: 101,
	},
	{
		garage_id: 5,
		part_id: 331,
		quantity: 295,
	},
	{
		garage_id: 5,
		part_id: 332,
		quantity: 466,
	},
	{
		garage_id: 5,
		part_id: 333,
		quantity: 217,
	},
	{
		garage_id: 5,
		part_id: 334,
		quantity: 209,
	},
	{
		garage_id: 5,
		part_id: 335,
		quantity: 279,
	},
	{
		garage_id: 5,
		part_id: 336,
		quantity: 482,
	},
	{
		garage_id: 5,
		part_id: 337,
		quantity: 246,
	},
	{
		garage_id: 5,
		part_id: 338,
		quantity: 343,
	},
	{
		garage_id: 5,
		part_id: 339,
		quantity: 66,
	},
	{
		garage_id: 5,
		part_id: 340,
		quantity: 297,
	},
	{
		garage_id: 5,
		part_id: 341,
		quantity: 107,
	},
	{
		garage_id: 5,
		part_id: 342,
		quantity: 209,
	},
	{
		garage_id: 5,
		part_id: 343,
		quantity: 126,
	},
	{
		garage_id: 5,
		part_id: 344,
		quantity: 385,
	},
	{
		garage_id: 5,
		part_id: 345,
		quantity: 63,
	},
	{
		garage_id: 5,
		part_id: 346,
		quantity: 373,
	},
	{
		garage_id: 5,
		part_id: 347,
		quantity: 449,
	},
	{
		garage_id: 5,
		part_id: 348,
		quantity: 434,
	},
	{
		garage_id: 5,
		part_id: 349,
		quantity: 245,
	},
	{
		garage_id: 5,
		part_id: 350,
		quantity: 262,
	},
	{
		garage_id: 5,
		part_id: 351,
		quantity: 223,
	},
	{
		garage_id: 5,
		part_id: 352,
		quantity: 482,
	},
	{
		garage_id: 5,
		part_id: 353,
		quantity: 279,
	},
	{
		garage_id: 5,
		part_id: 354,
		quantity: 29,
	},
	{
		garage_id: 5,
		part_id: 355,
		quantity: 281,
	},
	{
		garage_id: 5,
		part_id: 356,
		quantity: 336,
	},
	{
		garage_id: 5,
		part_id: 357,
		quantity: 259,
	},
	{
		garage_id: 5,
		part_id: 358,
		quantity: 359,
	},
	{
		garage_id: 5,
		part_id: 359,
		quantity: 267,
	},
	{
		garage_id: 5,
		part_id: 360,
		quantity: 286,
	},
	{
		garage_id: 5,
		part_id: 361,
		quantity: 65,
	},
	{
		garage_id: 5,
		part_id: 362,
		quantity: 332,
	},
	{
		garage_id: 5,
		part_id: 363,
		quantity: 109,
	},
	{
		garage_id: 5,
		part_id: 364,
		quantity: 429,
	},
	{
		garage_id: 5,
		part_id: 365,
		quantity: 313,
	},
	{
		garage_id: 5,
		part_id: 366,
		quantity: 435,
	},
	{
		garage_id: 5,
		part_id: 367,
		quantity: 28,
	},
	{
		garage_id: 5,
		part_id: 368,
		quantity: 371,
	},
	{
		garage_id: 5,
		part_id: 369,
		quantity: 24,
	},
	{
		garage_id: 5,
		part_id: 370,
		quantity: 470,
	},
	{
		garage_id: 5,
		part_id: 371,
		quantity: 181,
	},
	{
		garage_id: 5,
		part_id: 372,
		quantity: 74,
	},
	{
		garage_id: 5,
		part_id: 373,
		quantity: 80,
	},
	{
		garage_id: 5,
		part_id: 374,
		quantity: 120,
	},
	{
		garage_id: 5,
		part_id: 375,
		quantity: 262,
	},
	{
		garage_id: 5,
		part_id: 376,
		quantity: 293,
	},
	{
		garage_id: 5,
		part_id: 377,
		quantity: 81,
	},
	{
		garage_id: 5,
		part_id: 378,
		quantity: 461,
	},
	{
		garage_id: 5,
		part_id: 379,
		quantity: 150,
	},
	{
		garage_id: 5,
		part_id: 380,
		quantity: 37,
	},
	{
		garage_id: 5,
		part_id: 381,
		quantity: 461,
	},
	{
		garage_id: 5,
		part_id: 382,
		quantity: 379,
	},
	{
		garage_id: 5,
		part_id: 383,
		quantity: 82,
	},
	{
		garage_id: 5,
		part_id: 384,
		quantity: 326,
	},
	{
		garage_id: 5,
		part_id: 385,
		quantity: 190,
	},
	{
		garage_id: 5,
		part_id: 386,
		quantity: 413,
	},
	{
		garage_id: 5,
		part_id: 387,
		quantity: 388,
	},
	{
		garage_id: 5,
		part_id: 388,
		quantity: 214,
	},
	{
		garage_id: 5,
		part_id: 389,
		quantity: 99,
	},
	{
		garage_id: 5,
		part_id: 390,
		quantity: 235,
	},
	{
		garage_id: 5,
		part_id: 391,
		quantity: 129,
	},
	{
		garage_id: 5,
		part_id: 392,
		quantity: 247,
	},
	{
		garage_id: 5,
		part_id: 393,
		quantity: 477,
	},
	{
		garage_id: 5,
		part_id: 394,
		quantity: 207,
	},
	{
		garage_id: 5,
		part_id: 395,
		quantity: 428,
	},
	{
		garage_id: 5,
		part_id: 396,
		quantity: 72,
	},
	{
		garage_id: 5,
		part_id: 397,
		quantity: 221,
	},
	{
		garage_id: 5,
		part_id: 398,
		quantity: 214,
	},
	{
		garage_id: 5,
		part_id: 399,
		quantity: 34,
	},
	{
		garage_id: 6,
		part_id: 0,
		quantity: 261,
	},
	{
		garage_id: 6,
		part_id: 1,
		quantity: 153,
	},
	{
		garage_id: 6,
		part_id: 2,
		quantity: 370,
	},
	{
		garage_id: 6,
		part_id: 3,
		quantity: 22,
	},
	{
		garage_id: 6,
		part_id: 4,
		quantity: 382,
	},
	{
		garage_id: 6,
		part_id: 5,
		quantity: 327,
	},
	{
		garage_id: 6,
		part_id: 6,
		quantity: 255,
	},
	{
		garage_id: 6,
		part_id: 7,
		quantity: 424,
	},
	{
		garage_id: 6,
		part_id: 8,
		quantity: 357,
	},
	{
		garage_id: 6,
		part_id: 9,
		quantity: 24,
	},
	{
		garage_id: 6,
		part_id: 10,
		quantity: 416,
	},
	{
		garage_id: 6,
		part_id: 11,
		quantity: 485,
	},
	{
		garage_id: 6,
		part_id: 12,
		quantity: 275,
	},
	{
		garage_id: 6,
		part_id: 13,
		quantity: 373,
	},
	{
		garage_id: 6,
		part_id: 14,
		quantity: 252,
	},
	{
		garage_id: 6,
		part_id: 15,
		quantity: 342,
	},
	{
		garage_id: 6,
		part_id: 16,
		quantity: 496,
	},
	{
		garage_id: 6,
		part_id: 17,
		quantity: 99,
	},
	{
		garage_id: 6,
		part_id: 18,
		quantity: 235,
	},
	{
		garage_id: 6,
		part_id: 19,
		quantity: 34,
	},
	{
		garage_id: 6,
		part_id: 20,
		quantity: 49,
	},
	{
		garage_id: 6,
		part_id: 21,
		quantity: 195,
	},
	{
		garage_id: 6,
		part_id: 22,
		quantity: 37,
	},
	{
		garage_id: 6,
		part_id: 23,
		quantity: 250,
	},
	{
		garage_id: 6,
		part_id: 24,
		quantity: 131,
	},
	{
		garage_id: 6,
		part_id: 25,
		quantity: 60,
	},
	{
		garage_id: 6,
		part_id: 26,
		quantity: 124,
	},
	{
		garage_id: 6,
		part_id: 27,
		quantity: 186,
	},
	{
		garage_id: 6,
		part_id: 28,
		quantity: 354,
	},
	{
		garage_id: 6,
		part_id: 29,
		quantity: 331,
	},
	{
		garage_id: 6,
		part_id: 30,
		quantity: 79,
	},
	{
		garage_id: 6,
		part_id: 31,
		quantity: 229,
	},
	{
		garage_id: 6,
		part_id: 32,
		quantity: 2,
	},
	{
		garage_id: 6,
		part_id: 33,
		quantity: 426,
	},
	{
		garage_id: 6,
		part_id: 34,
		quantity: 9,
	},
	{
		garage_id: 6,
		part_id: 35,
		quantity: 500,
	},
	{
		garage_id: 6,
		part_id: 36,
		quantity: 270,
	},
	{
		garage_id: 6,
		part_id: 37,
		quantity: 446,
	},
	{
		garage_id: 6,
		part_id: 38,
		quantity: 386,
	},
	{
		garage_id: 6,
		part_id: 39,
		quantity: 243,
	},
	{
		garage_id: 6,
		part_id: 40,
		quantity: 260,
	},
	{
		garage_id: 6,
		part_id: 41,
		quantity: 4,
	},
	{
		garage_id: 6,
		part_id: 42,
		quantity: 438,
	},
	{
		garage_id: 6,
		part_id: 43,
		quantity: 453,
	},
	{
		garage_id: 6,
		part_id: 44,
		quantity: 109,
	},
	{
		garage_id: 6,
		part_id: 45,
		quantity: 93,
	},
	{
		garage_id: 6,
		part_id: 46,
		quantity: 144,
	},
	{
		garage_id: 6,
		part_id: 47,
		quantity: 3,
	},
	{
		garage_id: 6,
		part_id: 48,
		quantity: 349,
	},
	{
		garage_id: 6,
		part_id: 49,
		quantity: 480,
	},
	{
		garage_id: 6,
		part_id: 50,
		quantity: 397,
	},
	{
		garage_id: 6,
		part_id: 51,
		quantity: 436,
	},
	{
		garage_id: 6,
		part_id: 52,
		quantity: 297,
	},
	{
		garage_id: 6,
		part_id: 53,
		quantity: 247,
	},
	{
		garage_id: 6,
		part_id: 54,
		quantity: 463,
	},
	{
		garage_id: 6,
		part_id: 55,
		quantity: 407,
	},
	{
		garage_id: 6,
		part_id: 56,
		quantity: 55,
	},
	{
		garage_id: 6,
		part_id: 57,
		quantity: 214,
	},
	{
		garage_id: 6,
		part_id: 58,
		quantity: 95,
	},
	{
		garage_id: 6,
		part_id: 59,
		quantity: 271,
	},
	{
		garage_id: 6,
		part_id: 60,
		quantity: 122,
	},
	{
		garage_id: 6,
		part_id: 61,
		quantity: 140,
	},
	{
		garage_id: 6,
		part_id: 62,
		quantity: 487,
	},
	{
		garage_id: 6,
		part_id: 63,
		quantity: 489,
	},
	{
		garage_id: 6,
		part_id: 64,
		quantity: 253,
	},
	{
		garage_id: 6,
		part_id: 65,
		quantity: 173,
	},
	{
		garage_id: 6,
		part_id: 66,
		quantity: 159,
	},
	{
		garage_id: 6,
		part_id: 67,
		quantity: 273,
	},
	{
		garage_id: 6,
		part_id: 68,
		quantity: 10,
	},
	{
		garage_id: 6,
		part_id: 69,
		quantity: 2,
	},
	{
		garage_id: 6,
		part_id: 70,
		quantity: 435,
	},
	{
		garage_id: 6,
		part_id: 71,
		quantity: 464,
	},
	{
		garage_id: 6,
		part_id: 72,
		quantity: 453,
	},
	{
		garage_id: 6,
		part_id: 73,
		quantity: 75,
	},
	{
		garage_id: 6,
		part_id: 74,
		quantity: 312,
	},
	{
		garage_id: 6,
		part_id: 75,
		quantity: 47,
	},
	{
		garage_id: 6,
		part_id: 76,
		quantity: 138,
	},
	{
		garage_id: 6,
		part_id: 77,
		quantity: 414,
	},
	{
		garage_id: 6,
		part_id: 78,
		quantity: 221,
	},
	{
		garage_id: 6,
		part_id: 79,
		quantity: 454,
	},
	{
		garage_id: 6,
		part_id: 80,
		quantity: 254,
	},
	{
		garage_id: 6,
		part_id: 81,
		quantity: 84,
	},
	{
		garage_id: 6,
		part_id: 82,
		quantity: 208,
	},
	{
		garage_id: 6,
		part_id: 83,
		quantity: 488,
	},
	{
		garage_id: 6,
		part_id: 84,
		quantity: 7,
	},
	{
		garage_id: 6,
		part_id: 85,
		quantity: 276,
	},
	{
		garage_id: 6,
		part_id: 86,
		quantity: 284,
	},
	{
		garage_id: 6,
		part_id: 87,
		quantity: 215,
	},
	{
		garage_id: 6,
		part_id: 88,
		quantity: 28,
	},
	{
		garage_id: 6,
		part_id: 89,
		quantity: 39,
	},
	{
		garage_id: 6,
		part_id: 90,
		quantity: 427,
	},
	{
		garage_id: 6,
		part_id: 91,
		quantity: 199,
	},
	{
		garage_id: 6,
		part_id: 92,
		quantity: 12,
	},
	{
		garage_id: 6,
		part_id: 93,
		quantity: 245,
	},
	{
		garage_id: 6,
		part_id: 94,
		quantity: 480,
	},
	{
		garage_id: 6,
		part_id: 95,
		quantity: 68,
	},
	{
		garage_id: 6,
		part_id: 96,
		quantity: 92,
	},
	{
		garage_id: 6,
		part_id: 97,
		quantity: 106,
	},
	{
		garage_id: 6,
		part_id: 98,
		quantity: 262,
	},
	{
		garage_id: 6,
		part_id: 99,
		quantity: 394,
	},
	{
		garage_id: 6,
		part_id: 100,
		quantity: 430,
	},
	{
		garage_id: 6,
		part_id: 101,
		quantity: 394,
	},
	{
		garage_id: 6,
		part_id: 102,
		quantity: 305,
	},
	{
		garage_id: 6,
		part_id: 103,
		quantity: 228,
	},
	{
		garage_id: 6,
		part_id: 104,
		quantity: 18,
	},
	{
		garage_id: 6,
		part_id: 105,
		quantity: 278,
	},
	{
		garage_id: 6,
		part_id: 106,
		quantity: 481,
	},
	{
		garage_id: 6,
		part_id: 107,
		quantity: 407,
	},
	{
		garage_id: 6,
		part_id: 108,
		quantity: 316,
	},
	{
		garage_id: 6,
		part_id: 109,
		quantity: 373,
	},
	{
		garage_id: 6,
		part_id: 110,
		quantity: 400,
	},
	{
		garage_id: 6,
		part_id: 111,
		quantity: 173,
	},
	{
		garage_id: 6,
		part_id: 112,
		quantity: 73,
	},
	{
		garage_id: 6,
		part_id: 113,
		quantity: 371,
	},
	{
		garage_id: 6,
		part_id: 114,
		quantity: 425,
	},
	{
		garage_id: 6,
		part_id: 115,
		quantity: 20,
	},
	{
		garage_id: 6,
		part_id: 116,
		quantity: 320,
	},
	{
		garage_id: 6,
		part_id: 117,
		quantity: 456,
	},
	{
		garage_id: 6,
		part_id: 118,
		quantity: 346,
	},
	{
		garage_id: 6,
		part_id: 119,
		quantity: 312,
	},
	{
		garage_id: 6,
		part_id: 120,
		quantity: 490,
	},
	{
		garage_id: 6,
		part_id: 121,
		quantity: 361,
	},
	{
		garage_id: 6,
		part_id: 122,
		quantity: 262,
	},
	{
		garage_id: 6,
		part_id: 123,
		quantity: 416,
	},
	{
		garage_id: 6,
		part_id: 124,
		quantity: 54,
	},
	{
		garage_id: 6,
		part_id: 125,
		quantity: 383,
	},
	{
		garage_id: 6,
		part_id: 126,
		quantity: 59,
	},
	{
		garage_id: 6,
		part_id: 127,
		quantity: 461,
	},
	{
		garage_id: 6,
		part_id: 128,
		quantity: 324,
	},
	{
		garage_id: 6,
		part_id: 129,
		quantity: 397,
	},
	{
		garage_id: 6,
		part_id: 130,
		quantity: 157,
	},
	{
		garage_id: 6,
		part_id: 131,
		quantity: 317,
	},
	{
		garage_id: 6,
		part_id: 132,
		quantity: 25,
	},
	{
		garage_id: 6,
		part_id: 133,
		quantity: 447,
	},
	{
		garage_id: 6,
		part_id: 134,
		quantity: 329,
	},
	{
		garage_id: 6,
		part_id: 135,
		quantity: 23,
	},
	{
		garage_id: 6,
		part_id: 136,
		quantity: 466,
	},
	{
		garage_id: 6,
		part_id: 137,
		quantity: 414,
	},
	{
		garage_id: 6,
		part_id: 138,
		quantity: 47,
	},
	{
		garage_id: 6,
		part_id: 139,
		quantity: 370,
	},
	{
		garage_id: 6,
		part_id: 140,
		quantity: 405,
	},
	{
		garage_id: 6,
		part_id: 141,
		quantity: 281,
	},
	{
		garage_id: 6,
		part_id: 142,
		quantity: 229,
	},
	{
		garage_id: 6,
		part_id: 143,
		quantity: 396,
	},
	{
		garage_id: 6,
		part_id: 144,
		quantity: 45,
	},
	{
		garage_id: 6,
		part_id: 145,
		quantity: 30,
	},
	{
		garage_id: 6,
		part_id: 146,
		quantity: 412,
	},
	{
		garage_id: 6,
		part_id: 147,
		quantity: 309,
	},
	{
		garage_id: 6,
		part_id: 148,
		quantity: 58,
	},
	{
		garage_id: 6,
		part_id: 149,
		quantity: 93,
	},
	{
		garage_id: 6,
		part_id: 150,
		quantity: 324,
	},
	{
		garage_id: 6,
		part_id: 151,
		quantity: 214,
	},
	{
		garage_id: 6,
		part_id: 152,
		quantity: 374,
	},
	{
		garage_id: 6,
		part_id: 153,
		quantity: 332,
	},
	{
		garage_id: 6,
		part_id: 154,
		quantity: 224,
	},
	{
		garage_id: 6,
		part_id: 155,
		quantity: 203,
	},
	{
		garage_id: 6,
		part_id: 156,
		quantity: 37,
	},
	{
		garage_id: 6,
		part_id: 157,
		quantity: 452,
	},
	{
		garage_id: 6,
		part_id: 158,
		quantity: 455,
	},
	{
		garage_id: 6,
		part_id: 159,
		quantity: 278,
	},
	{
		garage_id: 6,
		part_id: 160,
		quantity: 59,
	},
	{
		garage_id: 6,
		part_id: 161,
		quantity: 284,
	},
	{
		garage_id: 6,
		part_id: 162,
		quantity: 84,
	},
	{
		garage_id: 6,
		part_id: 163,
		quantity: 268,
	},
	{
		garage_id: 6,
		part_id: 164,
		quantity: 355,
	},
	{
		garage_id: 6,
		part_id: 165,
		quantity: 352,
	},
	{
		garage_id: 6,
		part_id: 166,
		quantity: 97,
	},
	{
		garage_id: 6,
		part_id: 167,
		quantity: 340,
	},
	{
		garage_id: 6,
		part_id: 168,
		quantity: 44,
	},
	{
		garage_id: 6,
		part_id: 169,
		quantity: 7,
	},
	{
		garage_id: 6,
		part_id: 170,
		quantity: 355,
	},
	{
		garage_id: 6,
		part_id: 171,
		quantity: 105,
	},
	{
		garage_id: 6,
		part_id: 172,
		quantity: 50,
	},
	{
		garage_id: 6,
		part_id: 173,
		quantity: 293,
	},
	{
		garage_id: 6,
		part_id: 174,
		quantity: 105,
	},
	{
		garage_id: 6,
		part_id: 175,
		quantity: 305,
	},
	{
		garage_id: 6,
		part_id: 176,
		quantity: 378,
	},
	{
		garage_id: 6,
		part_id: 177,
		quantity: 285,
	},
	{
		garage_id: 6,
		part_id: 178,
		quantity: 320,
	},
	{
		garage_id: 6,
		part_id: 179,
		quantity: 332,
	},
	{
		garage_id: 6,
		part_id: 180,
		quantity: 37,
	},
	{
		garage_id: 6,
		part_id: 181,
		quantity: 7,
	},
	{
		garage_id: 6,
		part_id: 182,
		quantity: 439,
	},
	{
		garage_id: 6,
		part_id: 183,
		quantity: 350,
	},
	{
		garage_id: 6,
		part_id: 184,
		quantity: 492,
	},
	{
		garage_id: 6,
		part_id: 185,
		quantity: 123,
	},
	{
		garage_id: 6,
		part_id: 186,
		quantity: 164,
	},
	{
		garage_id: 6,
		part_id: 187,
		quantity: 246,
	},
	{
		garage_id: 6,
		part_id: 188,
		quantity: 159,
	},
	{
		garage_id: 6,
		part_id: 189,
		quantity: 58,
	},
	{
		garage_id: 6,
		part_id: 190,
		quantity: 93,
	},
	{
		garage_id: 6,
		part_id: 191,
		quantity: 109,
	},
	{
		garage_id: 6,
		part_id: 192,
		quantity: 32,
	},
	{
		garage_id: 6,
		part_id: 193,
		quantity: 168,
	},
	{
		garage_id: 6,
		part_id: 194,
		quantity: 468,
	},
	{
		garage_id: 6,
		part_id: 195,
		quantity: 337,
	},
	{
		garage_id: 6,
		part_id: 196,
		quantity: 254,
	},
	{
		garage_id: 6,
		part_id: 197,
		quantity: 228,
	},
	{
		garage_id: 6,
		part_id: 198,
		quantity: 0,
	},
	{
		garage_id: 6,
		part_id: 199,
		quantity: 249,
	},
	{
		garage_id: 6,
		part_id: 200,
		quantity: 115,
	},
	{
		garage_id: 6,
		part_id: 201,
		quantity: 119,
	},
	{
		garage_id: 6,
		part_id: 202,
		quantity: 476,
	},
	{
		garage_id: 6,
		part_id: 203,
		quantity: 1,
	},
	{
		garage_id: 6,
		part_id: 204,
		quantity: 38,
	},
	{
		garage_id: 6,
		part_id: 205,
		quantity: 450,
	},
	{
		garage_id: 6,
		part_id: 206,
		quantity: 324,
	},
	{
		garage_id: 6,
		part_id: 207,
		quantity: 316,
	},
	{
		garage_id: 6,
		part_id: 208,
		quantity: 93,
	},
	{
		garage_id: 6,
		part_id: 209,
		quantity: 282,
	},
	{
		garage_id: 6,
		part_id: 210,
		quantity: 455,
	},
	{
		garage_id: 6,
		part_id: 211,
		quantity: 250,
	},
	{
		garage_id: 6,
		part_id: 212,
		quantity: 323,
	},
	{
		garage_id: 6,
		part_id: 213,
		quantity: 329,
	},
	{
		garage_id: 6,
		part_id: 214,
		quantity: 71,
	},
	{
		garage_id: 6,
		part_id: 215,
		quantity: 314,
	},
	{
		garage_id: 6,
		part_id: 216,
		quantity: 36,
	},
	{
		garage_id: 6,
		part_id: 217,
		quantity: 126,
	},
	{
		garage_id: 6,
		part_id: 218,
		quantity: 499,
	},
	{
		garage_id: 6,
		part_id: 219,
		quantity: 56,
	},
	{
		garage_id: 6,
		part_id: 220,
		quantity: 373,
	},
	{
		garage_id: 6,
		part_id: 221,
		quantity: 20,
	},
	{
		garage_id: 6,
		part_id: 222,
		quantity: 112,
	},
	{
		garage_id: 6,
		part_id: 223,
		quantity: 451,
	},
	{
		garage_id: 6,
		part_id: 224,
		quantity: 47,
	},
	{
		garage_id: 6,
		part_id: 225,
		quantity: 196,
	},
	{
		garage_id: 6,
		part_id: 226,
		quantity: 99,
	},
	{
		garage_id: 6,
		part_id: 227,
		quantity: 459,
	},
	{
		garage_id: 6,
		part_id: 228,
		quantity: 12,
	},
	{
		garage_id: 6,
		part_id: 229,
		quantity: 323,
	},
	{
		garage_id: 6,
		part_id: 230,
		quantity: 306,
	},
	{
		garage_id: 6,
		part_id: 231,
		quantity: 278,
	},
	{
		garage_id: 6,
		part_id: 232,
		quantity: 145,
	},
	{
		garage_id: 6,
		part_id: 233,
		quantity: 378,
	},
	{
		garage_id: 6,
		part_id: 234,
		quantity: 27,
	},
	{
		garage_id: 6,
		part_id: 235,
		quantity: 256,
	},
	{
		garage_id: 6,
		part_id: 236,
		quantity: 216,
	},
	{
		garage_id: 6,
		part_id: 237,
		quantity: 105,
	},
	{
		garage_id: 6,
		part_id: 238,
		quantity: 20,
	},
	{
		garage_id: 6,
		part_id: 239,
		quantity: 376,
	},
	{
		garage_id: 6,
		part_id: 240,
		quantity: 460,
	},
	{
		garage_id: 6,
		part_id: 241,
		quantity: 358,
	},
	{
		garage_id: 6,
		part_id: 242,
		quantity: 350,
	},
	{
		garage_id: 6,
		part_id: 243,
		quantity: 304,
	},
	{
		garage_id: 6,
		part_id: 244,
		quantity: 321,
	},
	{
		garage_id: 6,
		part_id: 245,
		quantity: 125,
	},
	{
		garage_id: 6,
		part_id: 246,
		quantity: 205,
	},
	{
		garage_id: 6,
		part_id: 247,
		quantity: 394,
	},
	{
		garage_id: 6,
		part_id: 248,
		quantity: 144,
	},
	{
		garage_id: 6,
		part_id: 249,
		quantity: 340,
	},
	{
		garage_id: 6,
		part_id: 250,
		quantity: 161,
	},
	{
		garage_id: 6,
		part_id: 251,
		quantity: 287,
	},
	{
		garage_id: 6,
		part_id: 252,
		quantity: 411,
	},
	{
		garage_id: 6,
		part_id: 253,
		quantity: 386,
	},
	{
		garage_id: 6,
		part_id: 254,
		quantity: 282,
	},
	{
		garage_id: 6,
		part_id: 255,
		quantity: 174,
	},
	{
		garage_id: 6,
		part_id: 256,
		quantity: 219,
	},
	{
		garage_id: 6,
		part_id: 257,
		quantity: 302,
	},
	{
		garage_id: 6,
		part_id: 258,
		quantity: 99,
	},
	{
		garage_id: 6,
		part_id: 259,
		quantity: 140,
	},
	{
		garage_id: 6,
		part_id: 260,
		quantity: 133,
	},
	{
		garage_id: 6,
		part_id: 261,
		quantity: 419,
	},
	{
		garage_id: 6,
		part_id: 262,
		quantity: 19,
	},
	{
		garage_id: 6,
		part_id: 263,
		quantity: 224,
	},
	{
		garage_id: 6,
		part_id: 264,
		quantity: 257,
	},
	{
		garage_id: 6,
		part_id: 265,
		quantity: 275,
	},
	{
		garage_id: 6,
		part_id: 266,
		quantity: 166,
	},
	{
		garage_id: 6,
		part_id: 267,
		quantity: 274,
	},
	{
		garage_id: 6,
		part_id: 268,
		quantity: 214,
	},
	{
		garage_id: 6,
		part_id: 269,
		quantity: 342,
	},
	{
		garage_id: 6,
		part_id: 270,
		quantity: 55,
	},
	{
		garage_id: 6,
		part_id: 271,
		quantity: 356,
	},
	{
		garage_id: 6,
		part_id: 272,
		quantity: 363,
	},
	{
		garage_id: 6,
		part_id: 273,
		quantity: 273,
	},
	{
		garage_id: 6,
		part_id: 274,
		quantity: 307,
	},
	{
		garage_id: 6,
		part_id: 275,
		quantity: 127,
	},
	{
		garage_id: 6,
		part_id: 276,
		quantity: 406,
	},
	{
		garage_id: 6,
		part_id: 277,
		quantity: 61,
	},
	{
		garage_id: 6,
		part_id: 278,
		quantity: 485,
	},
	{
		garage_id: 6,
		part_id: 279,
		quantity: 289,
	},
	{
		garage_id: 6,
		part_id: 280,
		quantity: 79,
	},
	{
		garage_id: 6,
		part_id: 281,
		quantity: 299,
	},
	{
		garage_id: 6,
		part_id: 282,
		quantity: 102,
	},
	{
		garage_id: 6,
		part_id: 283,
		quantity: 40,
	},
	{
		garage_id: 6,
		part_id: 284,
		quantity: 296,
	},
	{
		garage_id: 6,
		part_id: 285,
		quantity: 437,
	},
	{
		garage_id: 6,
		part_id: 286,
		quantity: 355,
	},
	{
		garage_id: 6,
		part_id: 287,
		quantity: 260,
	},
	{
		garage_id: 6,
		part_id: 288,
		quantity: 67,
	},
	{
		garage_id: 6,
		part_id: 289,
		quantity: 218,
	},
	{
		garage_id: 6,
		part_id: 290,
		quantity: 333,
	},
	{
		garage_id: 6,
		part_id: 291,
		quantity: 107,
	},
	{
		garage_id: 6,
		part_id: 292,
		quantity: 20,
	},
	{
		garage_id: 6,
		part_id: 293,
		quantity: 96,
	},
	{
		garage_id: 6,
		part_id: 294,
		quantity: 173,
	},
	{
		garage_id: 6,
		part_id: 295,
		quantity: 306,
	},
	{
		garage_id: 6,
		part_id: 296,
		quantity: 488,
	},
	{
		garage_id: 6,
		part_id: 297,
		quantity: 291,
	},
	{
		garage_id: 6,
		part_id: 298,
		quantity: 459,
	},
	{
		garage_id: 6,
		part_id: 299,
		quantity: 132,
	},
	{
		garage_id: 6,
		part_id: 300,
		quantity: 349,
	},
	{
		garage_id: 6,
		part_id: 301,
		quantity: 2,
	},
	{
		garage_id: 6,
		part_id: 302,
		quantity: 479,
	},
	{
		garage_id: 6,
		part_id: 303,
		quantity: 446,
	},
	{
		garage_id: 6,
		part_id: 304,
		quantity: 351,
	},
	{
		garage_id: 6,
		part_id: 305,
		quantity: 55,
	},
	{
		garage_id: 6,
		part_id: 306,
		quantity: 79,
	},
	{
		garage_id: 6,
		part_id: 307,
		quantity: 116,
	},
	{
		garage_id: 6,
		part_id: 308,
		quantity: 222,
	},
	{
		garage_id: 6,
		part_id: 309,
		quantity: 49,
	},
	{
		garage_id: 6,
		part_id: 310,
		quantity: 413,
	},
	{
		garage_id: 6,
		part_id: 311,
		quantity: 217,
	},
	{
		garage_id: 6,
		part_id: 312,
		quantity: 201,
	},
	{
		garage_id: 6,
		part_id: 313,
		quantity: 19,
	},
	{
		garage_id: 6,
		part_id: 314,
		quantity: 104,
	},
	{
		garage_id: 6,
		part_id: 315,
		quantity: 112,
	},
	{
		garage_id: 6,
		part_id: 316,
		quantity: 127,
	},
	{
		garage_id: 6,
		part_id: 317,
		quantity: 493,
	},
	{
		garage_id: 6,
		part_id: 318,
		quantity: 289,
	},
	{
		garage_id: 6,
		part_id: 319,
		quantity: 230,
	},
	{
		garage_id: 6,
		part_id: 320,
		quantity: 345,
	},
	{
		garage_id: 6,
		part_id: 321,
		quantity: 296,
	},
	{
		garage_id: 6,
		part_id: 322,
		quantity: 351,
	},
	{
		garage_id: 6,
		part_id: 323,
		quantity: 7,
	},
	{
		garage_id: 6,
		part_id: 324,
		quantity: 393,
	},
	{
		garage_id: 6,
		part_id: 325,
		quantity: 357,
	},
	{
		garage_id: 6,
		part_id: 326,
		quantity: 500,
	},
	{
		garage_id: 6,
		part_id: 327,
		quantity: 160,
	},
	{
		garage_id: 6,
		part_id: 328,
		quantity: 344,
	},
	{
		garage_id: 6,
		part_id: 329,
		quantity: 270,
	},
	{
		garage_id: 6,
		part_id: 330,
		quantity: 256,
	},
	{
		garage_id: 6,
		part_id: 331,
		quantity: 209,
	},
	{
		garage_id: 6,
		part_id: 332,
		quantity: 47,
	},
	{
		garage_id: 6,
		part_id: 333,
		quantity: 415,
	},
	{
		garage_id: 6,
		part_id: 334,
		quantity: 470,
	},
	{
		garage_id: 6,
		part_id: 335,
		quantity: 421,
	},
	{
		garage_id: 6,
		part_id: 336,
		quantity: 173,
	},
	{
		garage_id: 6,
		part_id: 337,
		quantity: 214,
	},
	{
		garage_id: 6,
		part_id: 338,
		quantity: 360,
	},
	{
		garage_id: 6,
		part_id: 339,
		quantity: 363,
	},
	{
		garage_id: 6,
		part_id: 340,
		quantity: 414,
	},
	{
		garage_id: 6,
		part_id: 341,
		quantity: 170,
	},
	{
		garage_id: 6,
		part_id: 342,
		quantity: 102,
	},
	{
		garage_id: 6,
		part_id: 343,
		quantity: 332,
	},
	{
		garage_id: 6,
		part_id: 344,
		quantity: 51,
	},
	{
		garage_id: 6,
		part_id: 345,
		quantity: 70,
	},
	{
		garage_id: 6,
		part_id: 346,
		quantity: 104,
	},
	{
		garage_id: 6,
		part_id: 347,
		quantity: 151,
	},
	{
		garage_id: 6,
		part_id: 348,
		quantity: 39,
	},
	{
		garage_id: 6,
		part_id: 349,
		quantity: 169,
	},
	{
		garage_id: 6,
		part_id: 350,
		quantity: 248,
	},
	{
		garage_id: 6,
		part_id: 351,
		quantity: 244,
	},
	{
		garage_id: 6,
		part_id: 352,
		quantity: 173,
	},
	{
		garage_id: 6,
		part_id: 353,
		quantity: 434,
	},
	{
		garage_id: 6,
		part_id: 354,
		quantity: 380,
	},
	{
		garage_id: 6,
		part_id: 355,
		quantity: 444,
	},
	{
		garage_id: 6,
		part_id: 356,
		quantity: 3,
	},
	{
		garage_id: 6,
		part_id: 357,
		quantity: 419,
	},
	{
		garage_id: 6,
		part_id: 358,
		quantity: 57,
	},
	{
		garage_id: 6,
		part_id: 359,
		quantity: 131,
	},
	{
		garage_id: 6,
		part_id: 360,
		quantity: 347,
	},
	{
		garage_id: 6,
		part_id: 361,
		quantity: 435,
	},
	{
		garage_id: 6,
		part_id: 362,
		quantity: 352,
	},
	{
		garage_id: 6,
		part_id: 363,
		quantity: 424,
	},
	{
		garage_id: 6,
		part_id: 364,
		quantity: 328,
	},
	{
		garage_id: 6,
		part_id: 365,
		quantity: 358,
	},
	{
		garage_id: 6,
		part_id: 366,
		quantity: 137,
	},
	{
		garage_id: 6,
		part_id: 367,
		quantity: 63,
	},
	{
		garage_id: 6,
		part_id: 368,
		quantity: 430,
	},
	{
		garage_id: 6,
		part_id: 369,
		quantity: 210,
	},
	{
		garage_id: 6,
		part_id: 370,
		quantity: 288,
	},
	{
		garage_id: 6,
		part_id: 371,
		quantity: 432,
	},
	{
		garage_id: 6,
		part_id: 372,
		quantity: 354,
	},
	{
		garage_id: 6,
		part_id: 373,
		quantity: 291,
	},
	{
		garage_id: 6,
		part_id: 374,
		quantity: 113,
	},
	{
		garage_id: 6,
		part_id: 375,
		quantity: 19,
	},
	{
		garage_id: 6,
		part_id: 376,
		quantity: 427,
	},
	{
		garage_id: 6,
		part_id: 377,
		quantity: 438,
	},
	{
		garage_id: 6,
		part_id: 378,
		quantity: 461,
	},
	{
		garage_id: 6,
		part_id: 379,
		quantity: 230,
	},
	{
		garage_id: 6,
		part_id: 380,
		quantity: 390,
	},
	{
		garage_id: 6,
		part_id: 381,
		quantity: 252,
	},
	{
		garage_id: 6,
		part_id: 382,
		quantity: 357,
	},
	{
		garage_id: 6,
		part_id: 383,
		quantity: 40,
	},
	{
		garage_id: 6,
		part_id: 384,
		quantity: 84,
	},
	{
		garage_id: 6,
		part_id: 385,
		quantity: 101,
	},
	{
		garage_id: 6,
		part_id: 386,
		quantity: 88,
	},
	{
		garage_id: 6,
		part_id: 387,
		quantity: 60,
	},
	{
		garage_id: 6,
		part_id: 388,
		quantity: 85,
	},
	{
		garage_id: 6,
		part_id: 389,
		quantity: 224,
	},
	{
		garage_id: 6,
		part_id: 390,
		quantity: 184,
	},
	{
		garage_id: 6,
		part_id: 391,
		quantity: 322,
	},
	{
		garage_id: 6,
		part_id: 392,
		quantity: 424,
	},
	{
		garage_id: 6,
		part_id: 393,
		quantity: 19,
	},
	{
		garage_id: 6,
		part_id: 394,
		quantity: 441,
	},
	{
		garage_id: 6,
		part_id: 395,
		quantity: 293,
	},
	{
		garage_id: 6,
		part_id: 396,
		quantity: 388,
	},
	{
		garage_id: 6,
		part_id: 397,
		quantity: 363,
	},
	{
		garage_id: 6,
		part_id: 398,
		quantity: 405,
	},
	{
		garage_id: 6,
		part_id: 399,
		quantity: 417,
	},
	{
		garage_id: 7,
		part_id: 0,
		quantity: 71,
	},
	{
		garage_id: 7,
		part_id: 1,
		quantity: 455,
	},
	{
		garage_id: 7,
		part_id: 2,
		quantity: 419,
	},
	{
		garage_id: 7,
		part_id: 3,
		quantity: 139,
	},
	{
		garage_id: 7,
		part_id: 4,
		quantity: 431,
	},
	{
		garage_id: 7,
		part_id: 5,
		quantity: 150,
	},
	{
		garage_id: 7,
		part_id: 6,
		quantity: 258,
	},
	{
		garage_id: 7,
		part_id: 7,
		quantity: 255,
	},
	{
		garage_id: 7,
		part_id: 8,
		quantity: 328,
	},
	{
		garage_id: 7,
		part_id: 9,
		quantity: 349,
	},
	{
		garage_id: 7,
		part_id: 10,
		quantity: 443,
	},
	{
		garage_id: 7,
		part_id: 11,
		quantity: 342,
	},
	{
		garage_id: 7,
		part_id: 12,
		quantity: 104,
	},
	{
		garage_id: 7,
		part_id: 13,
		quantity: 485,
	},
	{
		garage_id: 7,
		part_id: 14,
		quantity: 247,
	},
	{
		garage_id: 7,
		part_id: 15,
		quantity: 94,
	},
	{
		garage_id: 7,
		part_id: 16,
		quantity: 495,
	},
	{
		garage_id: 7,
		part_id: 17,
		quantity: 75,
	},
	{
		garage_id: 7,
		part_id: 18,
		quantity: 234,
	},
	{
		garage_id: 7,
		part_id: 19,
		quantity: 242,
	},
	{
		garage_id: 7,
		part_id: 20,
		quantity: 329,
	},
	{
		garage_id: 7,
		part_id: 21,
		quantity: 21,
	},
	{
		garage_id: 7,
		part_id: 22,
		quantity: 328,
	},
	{
		garage_id: 7,
		part_id: 23,
		quantity: 47,
	},
	{
		garage_id: 7,
		part_id: 24,
		quantity: 486,
	},
	{
		garage_id: 7,
		part_id: 25,
		quantity: 426,
	},
	{
		garage_id: 7,
		part_id: 26,
		quantity: 431,
	},
	{
		garage_id: 7,
		part_id: 27,
		quantity: 166,
	},
	{
		garage_id: 7,
		part_id: 28,
		quantity: 186,
	},
	{
		garage_id: 7,
		part_id: 29,
		quantity: 316,
	},
	{
		garage_id: 7,
		part_id: 30,
		quantity: 18,
	},
	{
		garage_id: 7,
		part_id: 31,
		quantity: 446,
	},
	{
		garage_id: 7,
		part_id: 32,
		quantity: 27,
	},
	{
		garage_id: 7,
		part_id: 33,
		quantity: 348,
	},
	{
		garage_id: 7,
		part_id: 34,
		quantity: 164,
	},
	{
		garage_id: 7,
		part_id: 35,
		quantity: 102,
	},
	{
		garage_id: 7,
		part_id: 36,
		quantity: 388,
	},
	{
		garage_id: 7,
		part_id: 37,
		quantity: 251,
	},
	{
		garage_id: 7,
		part_id: 38,
		quantity: 216,
	},
	{
		garage_id: 7,
		part_id: 39,
		quantity: 331,
	},
	{
		garage_id: 7,
		part_id: 40,
		quantity: 50,
	},
	{
		garage_id: 7,
		part_id: 41,
		quantity: 116,
	},
	{
		garage_id: 7,
		part_id: 42,
		quantity: 16,
	},
	{
		garage_id: 7,
		part_id: 43,
		quantity: 376,
	},
	{
		garage_id: 7,
		part_id: 44,
		quantity: 357,
	},
	{
		garage_id: 7,
		part_id: 45,
		quantity: 285,
	},
	{
		garage_id: 7,
		part_id: 46,
		quantity: 53,
	},
	{
		garage_id: 7,
		part_id: 47,
		quantity: 438,
	},
	{
		garage_id: 7,
		part_id: 48,
		quantity: 223,
	},
	{
		garage_id: 7,
		part_id: 49,
		quantity: 224,
	},
	{
		garage_id: 7,
		part_id: 50,
		quantity: 250,
	},
	{
		garage_id: 7,
		part_id: 51,
		quantity: 340,
	},
	{
		garage_id: 7,
		part_id: 52,
		quantity: 417,
	},
	{
		garage_id: 7,
		part_id: 53,
		quantity: 410,
	},
	{
		garage_id: 7,
		part_id: 54,
		quantity: 483,
	},
	{
		garage_id: 7,
		part_id: 55,
		quantity: 416,
	},
	{
		garage_id: 7,
		part_id: 56,
		quantity: 65,
	},
	{
		garage_id: 7,
		part_id: 57,
		quantity: 496,
	},
	{
		garage_id: 7,
		part_id: 58,
		quantity: 364,
	},
	{
		garage_id: 7,
		part_id: 59,
		quantity: 358,
	},
	{
		garage_id: 7,
		part_id: 60,
		quantity: 476,
	},
	{
		garage_id: 7,
		part_id: 61,
		quantity: 12,
	},
	{
		garage_id: 7,
		part_id: 62,
		quantity: 362,
	},
	{
		garage_id: 7,
		part_id: 63,
		quantity: 257,
	},
	{
		garage_id: 7,
		part_id: 64,
		quantity: 2,
	},
	{
		garage_id: 7,
		part_id: 65,
		quantity: 317,
	},
	{
		garage_id: 7,
		part_id: 66,
		quantity: 198,
	},
	{
		garage_id: 7,
		part_id: 67,
		quantity: 138,
	},
	{
		garage_id: 7,
		part_id: 68,
		quantity: 295,
	},
	{
		garage_id: 7,
		part_id: 69,
		quantity: 83,
	},
	{
		garage_id: 7,
		part_id: 70,
		quantity: 351,
	},
	{
		garage_id: 7,
		part_id: 71,
		quantity: 464,
	},
	{
		garage_id: 7,
		part_id: 72,
		quantity: 246,
	},
	{
		garage_id: 7,
		part_id: 73,
		quantity: 461,
	},
	{
		garage_id: 7,
		part_id: 74,
		quantity: 49,
	},
	{
		garage_id: 7,
		part_id: 75,
		quantity: 194,
	},
	{
		garage_id: 7,
		part_id: 76,
		quantity: 237,
	},
	{
		garage_id: 7,
		part_id: 77,
		quantity: 78,
	},
	{
		garage_id: 7,
		part_id: 78,
		quantity: 360,
	},
	{
		garage_id: 7,
		part_id: 79,
		quantity: 310,
	},
	{
		garage_id: 7,
		part_id: 80,
		quantity: 214,
	},
	{
		garage_id: 7,
		part_id: 81,
		quantity: 294,
	},
	{
		garage_id: 7,
		part_id: 82,
		quantity: 61,
	},
	{
		garage_id: 7,
		part_id: 83,
		quantity: 114,
	},
	{
		garage_id: 7,
		part_id: 84,
		quantity: 389,
	},
	{
		garage_id: 7,
		part_id: 85,
		quantity: 197,
	},
	{
		garage_id: 7,
		part_id: 86,
		quantity: 24,
	},
	{
		garage_id: 7,
		part_id: 87,
		quantity: 288,
	},
	{
		garage_id: 7,
		part_id: 88,
		quantity: 163,
	},
	{
		garage_id: 7,
		part_id: 89,
		quantity: 323,
	},
	{
		garage_id: 7,
		part_id: 90,
		quantity: 107,
	},
	{
		garage_id: 7,
		part_id: 91,
		quantity: 313,
	},
	{
		garage_id: 7,
		part_id: 92,
		quantity: 107,
	},
	{
		garage_id: 7,
		part_id: 93,
		quantity: 137,
	},
	{
		garage_id: 7,
		part_id: 94,
		quantity: 309,
	},
	{
		garage_id: 7,
		part_id: 95,
		quantity: 94,
	},
	{
		garage_id: 7,
		part_id: 96,
		quantity: 109,
	},
	{
		garage_id: 7,
		part_id: 97,
		quantity: 2,
	},
	{
		garage_id: 7,
		part_id: 98,
		quantity: 496,
	},
	{
		garage_id: 7,
		part_id: 99,
		quantity: 201,
	},
	{
		garage_id: 7,
		part_id: 100,
		quantity: 241,
	},
	{
		garage_id: 7,
		part_id: 101,
		quantity: 307,
	},
	{
		garage_id: 7,
		part_id: 102,
		quantity: 148,
	},
	{
		garage_id: 7,
		part_id: 103,
		quantity: 213,
	},
	{
		garage_id: 7,
		part_id: 104,
		quantity: 494,
	},
	{
		garage_id: 7,
		part_id: 105,
		quantity: 231,
	},
	{
		garage_id: 7,
		part_id: 106,
		quantity: 109,
	},
	{
		garage_id: 7,
		part_id: 107,
		quantity: 102,
	},
	{
		garage_id: 7,
		part_id: 108,
		quantity: 32,
	},
	{
		garage_id: 7,
		part_id: 109,
		quantity: 199,
	},
	{
		garage_id: 7,
		part_id: 110,
		quantity: 246,
	},
	{
		garage_id: 7,
		part_id: 111,
		quantity: 442,
	},
	{
		garage_id: 7,
		part_id: 112,
		quantity: 38,
	},
	{
		garage_id: 7,
		part_id: 113,
		quantity: 326,
	},
	{
		garage_id: 7,
		part_id: 114,
		quantity: 295,
	},
	{
		garage_id: 7,
		part_id: 115,
		quantity: 493,
	},
	{
		garage_id: 7,
		part_id: 116,
		quantity: 477,
	},
	{
		garage_id: 7,
		part_id: 117,
		quantity: 275,
	},
	{
		garage_id: 7,
		part_id: 118,
		quantity: 389,
	},
	{
		garage_id: 7,
		part_id: 119,
		quantity: 471,
	},
	{
		garage_id: 7,
		part_id: 120,
		quantity: 279,
	},
	{
		garage_id: 7,
		part_id: 121,
		quantity: 462,
	},
	{
		garage_id: 7,
		part_id: 122,
		quantity: 156,
	},
	{
		garage_id: 7,
		part_id: 123,
		quantity: 201,
	},
	{
		garage_id: 7,
		part_id: 124,
		quantity: 488,
	},
	{
		garage_id: 7,
		part_id: 125,
		quantity: 213,
	},
	{
		garage_id: 7,
		part_id: 126,
		quantity: 58,
	},
	{
		garage_id: 7,
		part_id: 127,
		quantity: 418,
	},
	{
		garage_id: 7,
		part_id: 128,
		quantity: 460,
	},
	{
		garage_id: 7,
		part_id: 129,
		quantity: 118,
	},
	{
		garage_id: 7,
		part_id: 130,
		quantity: 123,
	},
	{
		garage_id: 7,
		part_id: 131,
		quantity: 377,
	},
	{
		garage_id: 7,
		part_id: 132,
		quantity: 294,
	},
	{
		garage_id: 7,
		part_id: 133,
		quantity: 69,
	},
	{
		garage_id: 7,
		part_id: 134,
		quantity: 400,
	},
	{
		garage_id: 7,
		part_id: 135,
		quantity: 214,
	},
	{
		garage_id: 7,
		part_id: 136,
		quantity: 76,
	},
	{
		garage_id: 7,
		part_id: 137,
		quantity: 267,
	},
	{
		garage_id: 7,
		part_id: 138,
		quantity: 306,
	},
	{
		garage_id: 7,
		part_id: 139,
		quantity: 62,
	},
	{
		garage_id: 7,
		part_id: 140,
		quantity: 366,
	},
	{
		garage_id: 7,
		part_id: 141,
		quantity: 346,
	},
	{
		garage_id: 7,
		part_id: 142,
		quantity: 265,
	},
	{
		garage_id: 7,
		part_id: 143,
		quantity: 100,
	},
	{
		garage_id: 7,
		part_id: 144,
		quantity: 375,
	},
	{
		garage_id: 7,
		part_id: 145,
		quantity: 244,
	},
	{
		garage_id: 7,
		part_id: 146,
		quantity: 438,
	},
	{
		garage_id: 7,
		part_id: 147,
		quantity: 276,
	},
	{
		garage_id: 7,
		part_id: 148,
		quantity: 372,
	},
	{
		garage_id: 7,
		part_id: 149,
		quantity: 317,
	},
	{
		garage_id: 7,
		part_id: 150,
		quantity: 365,
	},
	{
		garage_id: 7,
		part_id: 151,
		quantity: 172,
	},
	{
		garage_id: 7,
		part_id: 152,
		quantity: 95,
	},
	{
		garage_id: 7,
		part_id: 153,
		quantity: 194,
	},
	{
		garage_id: 7,
		part_id: 154,
		quantity: 143,
	},
	{
		garage_id: 7,
		part_id: 155,
		quantity: 314,
	},
	{
		garage_id: 7,
		part_id: 156,
		quantity: 68,
	},
	{
		garage_id: 7,
		part_id: 157,
		quantity: 330,
	},
	{
		garage_id: 7,
		part_id: 158,
		quantity: 365,
	},
	{
		garage_id: 7,
		part_id: 159,
		quantity: 430,
	},
	{
		garage_id: 7,
		part_id: 160,
		quantity: 373,
	},
	{
		garage_id: 7,
		part_id: 161,
		quantity: 388,
	},
	{
		garage_id: 7,
		part_id: 162,
		quantity: 188,
	},
	{
		garage_id: 7,
		part_id: 163,
		quantity: 342,
	},
	{
		garage_id: 7,
		part_id: 164,
		quantity: 129,
	},
	{
		garage_id: 7,
		part_id: 165,
		quantity: 188,
	},
	{
		garage_id: 7,
		part_id: 166,
		quantity: 378,
	},
	{
		garage_id: 7,
		part_id: 167,
		quantity: 92,
	},
	{
		garage_id: 7,
		part_id: 168,
		quantity: 359,
	},
	{
		garage_id: 7,
		part_id: 169,
		quantity: 182,
	},
	{
		garage_id: 7,
		part_id: 170,
		quantity: 68,
	},
	{
		garage_id: 7,
		part_id: 171,
		quantity: 130,
	},
	{
		garage_id: 7,
		part_id: 172,
		quantity: 37,
	},
	{
		garage_id: 7,
		part_id: 173,
		quantity: 195,
	},
	{
		garage_id: 7,
		part_id: 174,
		quantity: 39,
	},
	{
		garage_id: 7,
		part_id: 175,
		quantity: 198,
	},
	{
		garage_id: 7,
		part_id: 176,
		quantity: 399,
	},
	{
		garage_id: 7,
		part_id: 177,
		quantity: 424,
	},
	{
		garage_id: 7,
		part_id: 178,
		quantity: 434,
	},
	{
		garage_id: 7,
		part_id: 179,
		quantity: 198,
	},
	{
		garage_id: 7,
		part_id: 180,
		quantity: 388,
	},
	{
		garage_id: 7,
		part_id: 181,
		quantity: 124,
	},
	{
		garage_id: 7,
		part_id: 182,
		quantity: 257,
	},
	{
		garage_id: 7,
		part_id: 183,
		quantity: 249,
	},
	{
		garage_id: 7,
		part_id: 184,
		quantity: 94,
	},
	{
		garage_id: 7,
		part_id: 185,
		quantity: 401,
	},
	{
		garage_id: 7,
		part_id: 186,
		quantity: 120,
	},
	{
		garage_id: 7,
		part_id: 187,
		quantity: 291,
	},
	{
		garage_id: 7,
		part_id: 188,
		quantity: 183,
	},
	{
		garage_id: 7,
		part_id: 189,
		quantity: 403,
	},
	{
		garage_id: 7,
		part_id: 190,
		quantity: 43,
	},
	{
		garage_id: 7,
		part_id: 191,
		quantity: 247,
	},
	{
		garage_id: 7,
		part_id: 192,
		quantity: 352,
	},
	{
		garage_id: 7,
		part_id: 193,
		quantity: 137,
	},
	{
		garage_id: 7,
		part_id: 194,
		quantity: 163,
	},
	{
		garage_id: 7,
		part_id: 195,
		quantity: 320,
	},
	{
		garage_id: 7,
		part_id: 196,
		quantity: 35,
	},
	{
		garage_id: 7,
		part_id: 197,
		quantity: 404,
	},
	{
		garage_id: 7,
		part_id: 198,
		quantity: 272,
	},
	{
		garage_id: 7,
		part_id: 199,
		quantity: 136,
	},
	{
		garage_id: 7,
		part_id: 200,
		quantity: 270,
	},
	{
		garage_id: 7,
		part_id: 201,
		quantity: 448,
	},
	{
		garage_id: 7,
		part_id: 202,
		quantity: 39,
	},
	{
		garage_id: 7,
		part_id: 203,
		quantity: 120,
	},
	{
		garage_id: 7,
		part_id: 204,
		quantity: 75,
	},
	{
		garage_id: 7,
		part_id: 205,
		quantity: 381,
	},
	{
		garage_id: 7,
		part_id: 206,
		quantity: 344,
	},
	{
		garage_id: 7,
		part_id: 207,
		quantity: 492,
	},
	{
		garage_id: 7,
		part_id: 208,
		quantity: 221,
	},
	{
		garage_id: 7,
		part_id: 209,
		quantity: 237,
	},
	{
		garage_id: 7,
		part_id: 210,
		quantity: 331,
	},
	{
		garage_id: 7,
		part_id: 211,
		quantity: 462,
	},
	{
		garage_id: 7,
		part_id: 212,
		quantity: 103,
	},
	{
		garage_id: 7,
		part_id: 213,
		quantity: 107,
	},
	{
		garage_id: 7,
		part_id: 214,
		quantity: 128,
	},
	{
		garage_id: 7,
		part_id: 215,
		quantity: 146,
	},
	{
		garage_id: 7,
		part_id: 216,
		quantity: 440,
	},
	{
		garage_id: 7,
		part_id: 217,
		quantity: 63,
	},
	{
		garage_id: 7,
		part_id: 218,
		quantity: 215,
	},
	{
		garage_id: 7,
		part_id: 219,
		quantity: 277,
	},
	{
		garage_id: 7,
		part_id: 220,
		quantity: 106,
	},
	{
		garage_id: 7,
		part_id: 221,
		quantity: 227,
	},
	{
		garage_id: 7,
		part_id: 222,
		quantity: 286,
	},
	{
		garage_id: 7,
		part_id: 223,
		quantity: 428,
	},
	{
		garage_id: 7,
		part_id: 224,
		quantity: 437,
	},
	{
		garage_id: 7,
		part_id: 225,
		quantity: 327,
	},
	{
		garage_id: 7,
		part_id: 226,
		quantity: 302,
	},
	{
		garage_id: 7,
		part_id: 227,
		quantity: 413,
	},
	{
		garage_id: 7,
		part_id: 228,
		quantity: 74,
	},
	{
		garage_id: 7,
		part_id: 229,
		quantity: 202,
	},
	{
		garage_id: 7,
		part_id: 230,
		quantity: 184,
	},
	{
		garage_id: 7,
		part_id: 231,
		quantity: 147,
	},
	{
		garage_id: 7,
		part_id: 232,
		quantity: 449,
	},
	{
		garage_id: 7,
		part_id: 233,
		quantity: 308,
	},
	{
		garage_id: 7,
		part_id: 234,
		quantity: 186,
	},
	{
		garage_id: 7,
		part_id: 235,
		quantity: 456,
	},
	{
		garage_id: 7,
		part_id: 236,
		quantity: 81,
	},
	{
		garage_id: 7,
		part_id: 237,
		quantity: 251,
	},
	{
		garage_id: 7,
		part_id: 238,
		quantity: 406,
	},
	{
		garage_id: 7,
		part_id: 239,
		quantity: 298,
	},
	{
		garage_id: 7,
		part_id: 240,
		quantity: 248,
	},
	{
		garage_id: 7,
		part_id: 241,
		quantity: 57,
	},
	{
		garage_id: 7,
		part_id: 242,
		quantity: 349,
	},
	{
		garage_id: 7,
		part_id: 243,
		quantity: 326,
	},
	{
		garage_id: 7,
		part_id: 244,
		quantity: 374,
	},
	{
		garage_id: 7,
		part_id: 245,
		quantity: 403,
	},
	{
		garage_id: 7,
		part_id: 246,
		quantity: 103,
	},
	{
		garage_id: 7,
		part_id: 247,
		quantity: 88,
	},
	{
		garage_id: 7,
		part_id: 248,
		quantity: 15,
	},
	{
		garage_id: 7,
		part_id: 249,
		quantity: 351,
	},
	{
		garage_id: 7,
		part_id: 250,
		quantity: 103,
	},
	{
		garage_id: 7,
		part_id: 251,
		quantity: 34,
	},
	{
		garage_id: 7,
		part_id: 252,
		quantity: 114,
	},
	{
		garage_id: 7,
		part_id: 253,
		quantity: 464,
	},
	{
		garage_id: 7,
		part_id: 254,
		quantity: 119,
	},
	{
		garage_id: 7,
		part_id: 255,
		quantity: 57,
	},
	{
		garage_id: 7,
		part_id: 256,
		quantity: 0,
	},
	{
		garage_id: 7,
		part_id: 257,
		quantity: 125,
	},
	{
		garage_id: 7,
		part_id: 258,
		quantity: 107,
	},
	{
		garage_id: 7,
		part_id: 259,
		quantity: 231,
	},
	{
		garage_id: 7,
		part_id: 260,
		quantity: 126,
	},
	{
		garage_id: 7,
		part_id: 261,
		quantity: 370,
	},
	{
		garage_id: 7,
		part_id: 262,
		quantity: 379,
	},
	{
		garage_id: 7,
		part_id: 263,
		quantity: 118,
	},
	{
		garage_id: 7,
		part_id: 264,
		quantity: 394,
	},
	{
		garage_id: 7,
		part_id: 265,
		quantity: 196,
	},
	{
		garage_id: 7,
		part_id: 266,
		quantity: 45,
	},
	{
		garage_id: 7,
		part_id: 267,
		quantity: 300,
	},
	{
		garage_id: 7,
		part_id: 268,
		quantity: 39,
	},
	{
		garage_id: 7,
		part_id: 269,
		quantity: 408,
	},
	{
		garage_id: 7,
		part_id: 270,
		quantity: 111,
	},
	{
		garage_id: 7,
		part_id: 271,
		quantity: 430,
	},
	{
		garage_id: 7,
		part_id: 272,
		quantity: 158,
	},
	{
		garage_id: 7,
		part_id: 273,
		quantity: 348,
	},
	{
		garage_id: 7,
		part_id: 274,
		quantity: 119,
	},
	{
		garage_id: 7,
		part_id: 275,
		quantity: 60,
	},
	{
		garage_id: 7,
		part_id: 276,
		quantity: 220,
	},
	{
		garage_id: 7,
		part_id: 277,
		quantity: 473,
	},
	{
		garage_id: 7,
		part_id: 278,
		quantity: 307,
	},
	{
		garage_id: 7,
		part_id: 279,
		quantity: 495,
	},
	{
		garage_id: 7,
		part_id: 280,
		quantity: 492,
	},
	{
		garage_id: 7,
		part_id: 281,
		quantity: 59,
	},
	{
		garage_id: 7,
		part_id: 282,
		quantity: 367,
	},
	{
		garage_id: 7,
		part_id: 283,
		quantity: 245,
	},
	{
		garage_id: 7,
		part_id: 284,
		quantity: 409,
	},
	{
		garage_id: 7,
		part_id: 285,
		quantity: 111,
	},
	{
		garage_id: 7,
		part_id: 286,
		quantity: 97,
	},
	{
		garage_id: 7,
		part_id: 287,
		quantity: 299,
	},
	{
		garage_id: 7,
		part_id: 288,
		quantity: 452,
	},
	{
		garage_id: 7,
		part_id: 289,
		quantity: 273,
	},
	{
		garage_id: 7,
		part_id: 290,
		quantity: 272,
	},
	{
		garage_id: 7,
		part_id: 291,
		quantity: 121,
	},
	{
		garage_id: 7,
		part_id: 292,
		quantity: 85,
	},
	{
		garage_id: 7,
		part_id: 293,
		quantity: 176,
	},
	{
		garage_id: 7,
		part_id: 294,
		quantity: 164,
	},
	{
		garage_id: 7,
		part_id: 295,
		quantity: 27,
	},
	{
		garage_id: 7,
		part_id: 296,
		quantity: 51,
	},
	{
		garage_id: 7,
		part_id: 297,
		quantity: 38,
	},
	{
		garage_id: 7,
		part_id: 298,
		quantity: 282,
	},
	{
		garage_id: 7,
		part_id: 299,
		quantity: 399,
	},
	{
		garage_id: 7,
		part_id: 300,
		quantity: 340,
	},
	{
		garage_id: 7,
		part_id: 301,
		quantity: 427,
	},
	{
		garage_id: 7,
		part_id: 302,
		quantity: 188,
	},
	{
		garage_id: 7,
		part_id: 303,
		quantity: 78,
	},
	{
		garage_id: 7,
		part_id: 304,
		quantity: 279,
	},
	{
		garage_id: 7,
		part_id: 305,
		quantity: 232,
	},
	{
		garage_id: 7,
		part_id: 306,
		quantity: 409,
	},
	{
		garage_id: 7,
		part_id: 307,
		quantity: 249,
	},
	{
		garage_id: 7,
		part_id: 308,
		quantity: 494,
	},
	{
		garage_id: 7,
		part_id: 309,
		quantity: 199,
	},
	{
		garage_id: 7,
		part_id: 310,
		quantity: 344,
	},
	{
		garage_id: 7,
		part_id: 311,
		quantity: 120,
	},
	{
		garage_id: 7,
		part_id: 312,
		quantity: 256,
	},
	{
		garage_id: 7,
		part_id: 313,
		quantity: 69,
	},
	{
		garage_id: 7,
		part_id: 314,
		quantity: 169,
	},
	{
		garage_id: 7,
		part_id: 315,
		quantity: 103,
	},
	{
		garage_id: 7,
		part_id: 316,
		quantity: 417,
	},
	{
		garage_id: 7,
		part_id: 317,
		quantity: 398,
	},
	{
		garage_id: 7,
		part_id: 318,
		quantity: 17,
	},
	{
		garage_id: 7,
		part_id: 319,
		quantity: 240,
	},
	{
		garage_id: 7,
		part_id: 320,
		quantity: 396,
	},
	{
		garage_id: 7,
		part_id: 321,
		quantity: 238,
	},
	{
		garage_id: 7,
		part_id: 322,
		quantity: 349,
	},
	{
		garage_id: 7,
		part_id: 323,
		quantity: 363,
	},
	{
		garage_id: 7,
		part_id: 324,
		quantity: 378,
	},
	{
		garage_id: 7,
		part_id: 325,
		quantity: 217,
	},
	{
		garage_id: 7,
		part_id: 326,
		quantity: 0,
	},
	{
		garage_id: 7,
		part_id: 327,
		quantity: 50,
	},
	{
		garage_id: 7,
		part_id: 328,
		quantity: 116,
	},
	{
		garage_id: 7,
		part_id: 329,
		quantity: 86,
	},
	{
		garage_id: 7,
		part_id: 330,
		quantity: 455,
	},
	{
		garage_id: 7,
		part_id: 331,
		quantity: 212,
	},
	{
		garage_id: 7,
		part_id: 332,
		quantity: 371,
	},
	{
		garage_id: 7,
		part_id: 333,
		quantity: 274,
	},
	{
		garage_id: 7,
		part_id: 334,
		quantity: 139,
	},
	{
		garage_id: 7,
		part_id: 335,
		quantity: 119,
	},
	{
		garage_id: 7,
		part_id: 336,
		quantity: 54,
	},
	{
		garage_id: 7,
		part_id: 337,
		quantity: 40,
	},
	{
		garage_id: 7,
		part_id: 338,
		quantity: 320,
	},
	{
		garage_id: 7,
		part_id: 339,
		quantity: 140,
	},
	{
		garage_id: 7,
		part_id: 340,
		quantity: 304,
	},
	{
		garage_id: 7,
		part_id: 341,
		quantity: 498,
	},
	{
		garage_id: 7,
		part_id: 342,
		quantity: 494,
	},
	{
		garage_id: 7,
		part_id: 343,
		quantity: 7,
	},
	{
		garage_id: 7,
		part_id: 344,
		quantity: 410,
	},
	{
		garage_id: 7,
		part_id: 345,
		quantity: 215,
	},
	{
		garage_id: 7,
		part_id: 346,
		quantity: 402,
	},
	{
		garage_id: 7,
		part_id: 347,
		quantity: 22,
	},
	{
		garage_id: 7,
		part_id: 348,
		quantity: 137,
	},
	{
		garage_id: 7,
		part_id: 349,
		quantity: 194,
	},
	{
		garage_id: 7,
		part_id: 350,
		quantity: 238,
	},
	{
		garage_id: 7,
		part_id: 351,
		quantity: 409,
	},
	{
		garage_id: 7,
		part_id: 352,
		quantity: 405,
	},
	{
		garage_id: 7,
		part_id: 353,
		quantity: 318,
	},
	{
		garage_id: 7,
		part_id: 354,
		quantity: 125,
	},
	{
		garage_id: 7,
		part_id: 355,
		quantity: 89,
	},
	{
		garage_id: 7,
		part_id: 356,
		quantity: 475,
	},
	{
		garage_id: 7,
		part_id: 357,
		quantity: 464,
	},
	{
		garage_id: 7,
		part_id: 358,
		quantity: 99,
	},
	{
		garage_id: 7,
		part_id: 359,
		quantity: 210,
	},
	{
		garage_id: 7,
		part_id: 360,
		quantity: 241,
	},
	{
		garage_id: 7,
		part_id: 361,
		quantity: 10,
	},
	{
		garage_id: 7,
		part_id: 362,
		quantity: 189,
	},
	{
		garage_id: 7,
		part_id: 363,
		quantity: 483,
	},
	{
		garage_id: 7,
		part_id: 364,
		quantity: 126,
	},
	{
		garage_id: 7,
		part_id: 365,
		quantity: 487,
	},
	{
		garage_id: 7,
		part_id: 366,
		quantity: 213,
	},
	{
		garage_id: 7,
		part_id: 367,
		quantity: 365,
	},
	{
		garage_id: 7,
		part_id: 368,
		quantity: 326,
	},
	{
		garage_id: 7,
		part_id: 369,
		quantity: 55,
	},
	{
		garage_id: 7,
		part_id: 370,
		quantity: 490,
	},
	{
		garage_id: 7,
		part_id: 371,
		quantity: 498,
	},
	{
		garage_id: 7,
		part_id: 372,
		quantity: 354,
	},
	{
		garage_id: 7,
		part_id: 373,
		quantity: 9,
	},
	{
		garage_id: 7,
		part_id: 374,
		quantity: 171,
	},
	{
		garage_id: 7,
		part_id: 375,
		quantity: 58,
	},
	{
		garage_id: 7,
		part_id: 376,
		quantity: 329,
	},
	{
		garage_id: 7,
		part_id: 377,
		quantity: 440,
	},
	{
		garage_id: 7,
		part_id: 378,
		quantity: 323,
	},
	{
		garage_id: 7,
		part_id: 379,
		quantity: 188,
	},
	{
		garage_id: 7,
		part_id: 380,
		quantity: 47,
	},
	{
		garage_id: 7,
		part_id: 381,
		quantity: 372,
	},
	{
		garage_id: 7,
		part_id: 382,
		quantity: 466,
	},
	{
		garage_id: 7,
		part_id: 383,
		quantity: 101,
	},
	{
		garage_id: 7,
		part_id: 384,
		quantity: 133,
	},
	{
		garage_id: 7,
		part_id: 385,
		quantity: 315,
	},
	{
		garage_id: 7,
		part_id: 386,
		quantity: 493,
	},
	{
		garage_id: 7,
		part_id: 387,
		quantity: 31,
	},
	{
		garage_id: 7,
		part_id: 388,
		quantity: 471,
	},
	{
		garage_id: 7,
		part_id: 389,
		quantity: 234,
	},
	{
		garage_id: 7,
		part_id: 390,
		quantity: 367,
	},
	{
		garage_id: 7,
		part_id: 391,
		quantity: 74,
	},
	{
		garage_id: 7,
		part_id: 392,
		quantity: 271,
	},
	{
		garage_id: 7,
		part_id: 393,
		quantity: 465,
	},
	{
		garage_id: 7,
		part_id: 394,
		quantity: 86,
	},
	{
		garage_id: 7,
		part_id: 395,
		quantity: 180,
	},
	{
		garage_id: 7,
		part_id: 396,
		quantity: 170,
	},
	{
		garage_id: 7,
		part_id: 397,
		quantity: 346,
	},
	{
		garage_id: 7,
		part_id: 398,
		quantity: 41,
	},
	{
		garage_id: 7,
		part_id: 399,
		quantity: 159,
	},
	{
		garage_id: 8,
		part_id: 0,
		quantity: 197,
	},
	{
		garage_id: 8,
		part_id: 1,
		quantity: 327,
	},
	{
		garage_id: 8,
		part_id: 2,
		quantity: 291,
	},
	{
		garage_id: 8,
		part_id: 3,
		quantity: 431,
	},
	{
		garage_id: 8,
		part_id: 4,
		quantity: 22,
	},
	{
		garage_id: 8,
		part_id: 5,
		quantity: 373,
	},
	{
		garage_id: 8,
		part_id: 6,
		quantity: 420,
	},
	{
		garage_id: 8,
		part_id: 7,
		quantity: 208,
	},
	{
		garage_id: 8,
		part_id: 8,
		quantity: 47,
	},
	{
		garage_id: 8,
		part_id: 9,
		quantity: 465,
	},
	{
		garage_id: 8,
		part_id: 10,
		quantity: 171,
	},
	{
		garage_id: 8,
		part_id: 11,
		quantity: 341,
	},
	{
		garage_id: 8,
		part_id: 12,
		quantity: 394,
	},
	{
		garage_id: 8,
		part_id: 13,
		quantity: 471,
	},
	{
		garage_id: 8,
		part_id: 14,
		quantity: 472,
	},
	{
		garage_id: 8,
		part_id: 15,
		quantity: 121,
	},
	{
		garage_id: 8,
		part_id: 16,
		quantity: 494,
	},
	{
		garage_id: 8,
		part_id: 17,
		quantity: 98,
	},
	{
		garage_id: 8,
		part_id: 18,
		quantity: 370,
	},
	{
		garage_id: 8,
		part_id: 19,
		quantity: 319,
	},
	{
		garage_id: 8,
		part_id: 20,
		quantity: 408,
	},
	{
		garage_id: 8,
		part_id: 21,
		quantity: 316,
	},
	{
		garage_id: 8,
		part_id: 22,
		quantity: 201,
	},
	{
		garage_id: 8,
		part_id: 23,
		quantity: 460,
	},
	{
		garage_id: 8,
		part_id: 24,
		quantity: 58,
	},
	{
		garage_id: 8,
		part_id: 25,
		quantity: 98,
	},
	{
		garage_id: 8,
		part_id: 26,
		quantity: 117,
	},
	{
		garage_id: 8,
		part_id: 27,
		quantity: 50,
	},
	{
		garage_id: 8,
		part_id: 28,
		quantity: 233,
	},
	{
		garage_id: 8,
		part_id: 29,
		quantity: 484,
	},
	{
		garage_id: 8,
		part_id: 30,
		quantity: 92,
	},
	{
		garage_id: 8,
		part_id: 31,
		quantity: 170,
	},
	{
		garage_id: 8,
		part_id: 32,
		quantity: 103,
	},
	{
		garage_id: 8,
		part_id: 33,
		quantity: 104,
	},
	{
		garage_id: 8,
		part_id: 34,
		quantity: 313,
	},
	{
		garage_id: 8,
		part_id: 35,
		quantity: 254,
	},
	{
		garage_id: 8,
		part_id: 36,
		quantity: 327,
	},
	{
		garage_id: 8,
		part_id: 37,
		quantity: 58,
	},
	{
		garage_id: 8,
		part_id: 38,
		quantity: 288,
	},
	{
		garage_id: 8,
		part_id: 39,
		quantity: 472,
	},
	{
		garage_id: 8,
		part_id: 40,
		quantity: 421,
	},
	{
		garage_id: 8,
		part_id: 41,
		quantity: 440,
	},
	{
		garage_id: 8,
		part_id: 42,
		quantity: 31,
	},
	{
		garage_id: 8,
		part_id: 43,
		quantity: 77,
	},
	{
		garage_id: 8,
		part_id: 44,
		quantity: 473,
	},
	{
		garage_id: 8,
		part_id: 45,
		quantity: 357,
	},
	{
		garage_id: 8,
		part_id: 46,
		quantity: 460,
	},
	{
		garage_id: 8,
		part_id: 47,
		quantity: 71,
	},
	{
		garage_id: 8,
		part_id: 48,
		quantity: 408,
	},
	{
		garage_id: 8,
		part_id: 49,
		quantity: 333,
	},
	{
		garage_id: 8,
		part_id: 50,
		quantity: 110,
	},
	{
		garage_id: 8,
		part_id: 51,
		quantity: 103,
	},
	{
		garage_id: 8,
		part_id: 52,
		quantity: 97,
	},
	{
		garage_id: 8,
		part_id: 53,
		quantity: 438,
	},
	{
		garage_id: 8,
		part_id: 54,
		quantity: 408,
	},
	{
		garage_id: 8,
		part_id: 55,
		quantity: 142,
	},
	{
		garage_id: 8,
		part_id: 56,
		quantity: 279,
	},
	{
		garage_id: 8,
		part_id: 57,
		quantity: 134,
	},
	{
		garage_id: 8,
		part_id: 58,
		quantity: 329,
	},
	{
		garage_id: 8,
		part_id: 59,
		quantity: 223,
	},
	{
		garage_id: 8,
		part_id: 60,
		quantity: 9,
	},
	{
		garage_id: 8,
		part_id: 61,
		quantity: 31,
	},
	{
		garage_id: 8,
		part_id: 62,
		quantity: 479,
	},
	{
		garage_id: 8,
		part_id: 63,
		quantity: 216,
	},
	{
		garage_id: 8,
		part_id: 64,
		quantity: 20,
	},
	{
		garage_id: 8,
		part_id: 65,
		quantity: 436,
	},
	{
		garage_id: 8,
		part_id: 66,
		quantity: 107,
	},
	{
		garage_id: 8,
		part_id: 67,
		quantity: 0,
	},
	{
		garage_id: 8,
		part_id: 68,
		quantity: 402,
	},
	{
		garage_id: 8,
		part_id: 69,
		quantity: 4,
	},
	{
		garage_id: 8,
		part_id: 70,
		quantity: 273,
	},
	{
		garage_id: 8,
		part_id: 71,
		quantity: 198,
	},
	{
		garage_id: 8,
		part_id: 72,
		quantity: 114,
	},
	{
		garage_id: 8,
		part_id: 73,
		quantity: 262,
	},
	{
		garage_id: 8,
		part_id: 74,
		quantity: 213,
	},
	{
		garage_id: 8,
		part_id: 75,
		quantity: 133,
	},
	{
		garage_id: 8,
		part_id: 76,
		quantity: 300,
	},
	{
		garage_id: 8,
		part_id: 77,
		quantity: 324,
	},
	{
		garage_id: 8,
		part_id: 78,
		quantity: 494,
	},
	{
		garage_id: 8,
		part_id: 79,
		quantity: 314,
	},
	{
		garage_id: 8,
		part_id: 80,
		quantity: 296,
	},
	{
		garage_id: 8,
		part_id: 81,
		quantity: 393,
	},
	{
		garage_id: 8,
		part_id: 82,
		quantity: 86,
	},
	{
		garage_id: 8,
		part_id: 83,
		quantity: 254,
	},
	{
		garage_id: 8,
		part_id: 84,
		quantity: 315,
	},
	{
		garage_id: 8,
		part_id: 85,
		quantity: 73,
	},
	{
		garage_id: 8,
		part_id: 86,
		quantity: 135,
	},
	{
		garage_id: 8,
		part_id: 87,
		quantity: 67,
	},
	{
		garage_id: 8,
		part_id: 88,
		quantity: 330,
	},
	{
		garage_id: 8,
		part_id: 89,
		quantity: 269,
	},
	{
		garage_id: 8,
		part_id: 90,
		quantity: 349,
	},
	{
		garage_id: 8,
		part_id: 91,
		quantity: 311,
	},
	{
		garage_id: 8,
		part_id: 92,
		quantity: 357,
	},
	{
		garage_id: 8,
		part_id: 93,
		quantity: 365,
	},
	{
		garage_id: 8,
		part_id: 94,
		quantity: 281,
	},
	{
		garage_id: 8,
		part_id: 95,
		quantity: 52,
	},
	{
		garage_id: 8,
		part_id: 96,
		quantity: 256,
	},
	{
		garage_id: 8,
		part_id: 97,
		quantity: 189,
	},
	{
		garage_id: 8,
		part_id: 98,
		quantity: 394,
	},
	{
		garage_id: 8,
		part_id: 99,
		quantity: 350,
	},
	{
		garage_id: 8,
		part_id: 100,
		quantity: 199,
	},
	{
		garage_id: 8,
		part_id: 101,
		quantity: 423,
	},
	{
		garage_id: 8,
		part_id: 102,
		quantity: 26,
	},
	{
		garage_id: 8,
		part_id: 103,
		quantity: 309,
	},
	{
		garage_id: 8,
		part_id: 104,
		quantity: 483,
	},
	{
		garage_id: 8,
		part_id: 105,
		quantity: 473,
	},
	{
		garage_id: 8,
		part_id: 106,
		quantity: 53,
	},
	{
		garage_id: 8,
		part_id: 107,
		quantity: 444,
	},
	{
		garage_id: 8,
		part_id: 108,
		quantity: 349,
	},
	{
		garage_id: 8,
		part_id: 109,
		quantity: 278,
	},
	{
		garage_id: 8,
		part_id: 110,
		quantity: 449,
	},
	{
		garage_id: 8,
		part_id: 111,
		quantity: 308,
	},
	{
		garage_id: 8,
		part_id: 112,
		quantity: 137,
	},
	{
		garage_id: 8,
		part_id: 113,
		quantity: 133,
	},
	{
		garage_id: 8,
		part_id: 114,
		quantity: 91,
	},
	{
		garage_id: 8,
		part_id: 115,
		quantity: 13,
	},
	{
		garage_id: 8,
		part_id: 116,
		quantity: 202,
	},
	{
		garage_id: 8,
		part_id: 117,
		quantity: 291,
	},
	{
		garage_id: 8,
		part_id: 118,
		quantity: 70,
	},
	{
		garage_id: 8,
		part_id: 119,
		quantity: 326,
	},
	{
		garage_id: 8,
		part_id: 120,
		quantity: 372,
	},
	{
		garage_id: 8,
		part_id: 121,
		quantity: 185,
	},
	{
		garage_id: 8,
		part_id: 122,
		quantity: 196,
	},
	{
		garage_id: 8,
		part_id: 123,
		quantity: 186,
	},
	{
		garage_id: 8,
		part_id: 124,
		quantity: 65,
	},
	{
		garage_id: 8,
		part_id: 125,
		quantity: 308,
	},
	{
		garage_id: 8,
		part_id: 126,
		quantity: 53,
	},
	{
		garage_id: 8,
		part_id: 127,
		quantity: 296,
	},
	{
		garage_id: 8,
		part_id: 128,
		quantity: 103,
	},
	{
		garage_id: 8,
		part_id: 129,
		quantity: 0,
	},
	{
		garage_id: 8,
		part_id: 130,
		quantity: 190,
	},
	{
		garage_id: 8,
		part_id: 131,
		quantity: 357,
	},
	{
		garage_id: 8,
		part_id: 132,
		quantity: 193,
	},
	{
		garage_id: 8,
		part_id: 133,
		quantity: 247,
	},
	{
		garage_id: 8,
		part_id: 134,
		quantity: 350,
	},
	{
		garage_id: 8,
		part_id: 135,
		quantity: 170,
	},
	{
		garage_id: 8,
		part_id: 136,
		quantity: 54,
	},
	{
		garage_id: 8,
		part_id: 137,
		quantity: 132,
	},
	{
		garage_id: 8,
		part_id: 138,
		quantity: 367,
	},
	{
		garage_id: 8,
		part_id: 139,
		quantity: 470,
	},
	{
		garage_id: 8,
		part_id: 140,
		quantity: 490,
	},
	{
		garage_id: 8,
		part_id: 141,
		quantity: 234,
	},
	{
		garage_id: 8,
		part_id: 142,
		quantity: 90,
	},
	{
		garage_id: 8,
		part_id: 143,
		quantity: 389,
	},
	{
		garage_id: 8,
		part_id: 144,
		quantity: 217,
	},
	{
		garage_id: 8,
		part_id: 145,
		quantity: 351,
	},
	{
		garage_id: 8,
		part_id: 146,
		quantity: 255,
	},
	{
		garage_id: 8,
		part_id: 147,
		quantity: 279,
	},
	{
		garage_id: 8,
		part_id: 148,
		quantity: 366,
	},
	{
		garage_id: 8,
		part_id: 149,
		quantity: 291,
	},
	{
		garage_id: 8,
		part_id: 150,
		quantity: 28,
	},
	{
		garage_id: 8,
		part_id: 151,
		quantity: 142,
	},
	{
		garage_id: 8,
		part_id: 152,
		quantity: 131,
	},
	{
		garage_id: 8,
		part_id: 153,
		quantity: 86,
	},
	{
		garage_id: 8,
		part_id: 154,
		quantity: 77,
	},
	{
		garage_id: 8,
		part_id: 155,
		quantity: 7,
	},
	{
		garage_id: 8,
		part_id: 156,
		quantity: 317,
	},
	{
		garage_id: 8,
		part_id: 157,
		quantity: 224,
	},
	{
		garage_id: 8,
		part_id: 158,
		quantity: 392,
	},
	{
		garage_id: 8,
		part_id: 159,
		quantity: 203,
	},
	{
		garage_id: 8,
		part_id: 160,
		quantity: 287,
	},
	{
		garage_id: 8,
		part_id: 161,
		quantity: 286,
	},
	{
		garage_id: 8,
		part_id: 162,
		quantity: 128,
	},
	{
		garage_id: 8,
		part_id: 163,
		quantity: 262,
	},
	{
		garage_id: 8,
		part_id: 164,
		quantity: 407,
	},
	{
		garage_id: 8,
		part_id: 165,
		quantity: 48,
	},
	{
		garage_id: 8,
		part_id: 166,
		quantity: 299,
	},
	{
		garage_id: 8,
		part_id: 167,
		quantity: 359,
	},
	{
		garage_id: 8,
		part_id: 168,
		quantity: 436,
	},
	{
		garage_id: 8,
		part_id: 169,
		quantity: 11,
	},
	{
		garage_id: 8,
		part_id: 170,
		quantity: 99,
	},
	{
		garage_id: 8,
		part_id: 171,
		quantity: 109,
	},
	{
		garage_id: 8,
		part_id: 172,
		quantity: 33,
	},
	{
		garage_id: 8,
		part_id: 173,
		quantity: 236,
	},
	{
		garage_id: 8,
		part_id: 174,
		quantity: 356,
	},
	{
		garage_id: 8,
		part_id: 175,
		quantity: 55,
	},
	{
		garage_id: 8,
		part_id: 176,
		quantity: 417,
	},
	{
		garage_id: 8,
		part_id: 177,
		quantity: 264,
	},
	{
		garage_id: 8,
		part_id: 178,
		quantity: 372,
	},
	{
		garage_id: 8,
		part_id: 179,
		quantity: 21,
	},
	{
		garage_id: 8,
		part_id: 180,
		quantity: 97,
	},
	{
		garage_id: 8,
		part_id: 181,
		quantity: 446,
	},
	{
		garage_id: 8,
		part_id: 182,
		quantity: 350,
	},
	{
		garage_id: 8,
		part_id: 183,
		quantity: 185,
	},
	{
		garage_id: 8,
		part_id: 184,
		quantity: 382,
	},
	{
		garage_id: 8,
		part_id: 185,
		quantity: 409,
	},
	{
		garage_id: 8,
		part_id: 186,
		quantity: 481,
	},
	{
		garage_id: 8,
		part_id: 187,
		quantity: 471,
	},
	{
		garage_id: 8,
		part_id: 188,
		quantity: 54,
	},
	{
		garage_id: 8,
		part_id: 189,
		quantity: 466,
	},
	{
		garage_id: 8,
		part_id: 190,
		quantity: 219,
	},
	{
		garage_id: 8,
		part_id: 191,
		quantity: 133,
	},
	{
		garage_id: 8,
		part_id: 192,
		quantity: 456,
	},
	{
		garage_id: 8,
		part_id: 193,
		quantity: 426,
	},
	{
		garage_id: 8,
		part_id: 194,
		quantity: 185,
	},
	{
		garage_id: 8,
		part_id: 195,
		quantity: 131,
	},
	{
		garage_id: 8,
		part_id: 196,
		quantity: 202,
	},
	{
		garage_id: 8,
		part_id: 197,
		quantity: 439,
	},
	{
		garage_id: 8,
		part_id: 198,
		quantity: 167,
	},
	{
		garage_id: 8,
		part_id: 199,
		quantity: 490,
	},
	{
		garage_id: 8,
		part_id: 200,
		quantity: 403,
	},
	{
		garage_id: 8,
		part_id: 201,
		quantity: 112,
	},
	{
		garage_id: 8,
		part_id: 202,
		quantity: 115,
	},
	{
		garage_id: 8,
		part_id: 203,
		quantity: 314,
	},
	{
		garage_id: 8,
		part_id: 204,
		quantity: 344,
	},
	{
		garage_id: 8,
		part_id: 205,
		quantity: 328,
	},
	{
		garage_id: 8,
		part_id: 206,
		quantity: 335,
	},
	{
		garage_id: 8,
		part_id: 207,
		quantity: 303,
	},
	{
		garage_id: 8,
		part_id: 208,
		quantity: 36,
	},
	{
		garage_id: 8,
		part_id: 209,
		quantity: 437,
	},
	{
		garage_id: 8,
		part_id: 210,
		quantity: 203,
	},
	{
		garage_id: 8,
		part_id: 211,
		quantity: 13,
	},
	{
		garage_id: 8,
		part_id: 212,
		quantity: 108,
	},
	{
		garage_id: 8,
		part_id: 213,
		quantity: 431,
	},
	{
		garage_id: 8,
		part_id: 214,
		quantity: 237,
	},
	{
		garage_id: 8,
		part_id: 215,
		quantity: 331,
	},
	{
		garage_id: 8,
		part_id: 216,
		quantity: 354,
	},
	{
		garage_id: 8,
		part_id: 217,
		quantity: 105,
	},
	{
		garage_id: 8,
		part_id: 218,
		quantity: 454,
	},
	{
		garage_id: 8,
		part_id: 219,
		quantity: 352,
	},
	{
		garage_id: 8,
		part_id: 220,
		quantity: 55,
	},
	{
		garage_id: 8,
		part_id: 221,
		quantity: 7,
	},
	{
		garage_id: 8,
		part_id: 222,
		quantity: 90,
	},
	{
		garage_id: 8,
		part_id: 223,
		quantity: 484,
	},
	{
		garage_id: 8,
		part_id: 224,
		quantity: 190,
	},
	{
		garage_id: 8,
		part_id: 225,
		quantity: 336,
	},
	{
		garage_id: 8,
		part_id: 226,
		quantity: 63,
	},
	{
		garage_id: 8,
		part_id: 227,
		quantity: 43,
	},
	{
		garage_id: 8,
		part_id: 228,
		quantity: 354,
	},
	{
		garage_id: 8,
		part_id: 229,
		quantity: 229,
	},
	{
		garage_id: 8,
		part_id: 230,
		quantity: 398,
	},
	{
		garage_id: 8,
		part_id: 231,
		quantity: 105,
	},
	{
		garage_id: 8,
		part_id: 232,
		quantity: 456,
	},
	{
		garage_id: 8,
		part_id: 233,
		quantity: 162,
	},
	{
		garage_id: 8,
		part_id: 234,
		quantity: 100,
	},
	{
		garage_id: 8,
		part_id: 235,
		quantity: 241,
	},
	{
		garage_id: 8,
		part_id: 236,
		quantity: 252,
	},
	{
		garage_id: 8,
		part_id: 237,
		quantity: 468,
	},
	{
		garage_id: 8,
		part_id: 238,
		quantity: 244,
	},
	{
		garage_id: 8,
		part_id: 239,
		quantity: 409,
	},
	{
		garage_id: 8,
		part_id: 240,
		quantity: 459,
	},
	{
		garage_id: 8,
		part_id: 241,
		quantity: 361,
	},
	{
		garage_id: 8,
		part_id: 242,
		quantity: 41,
	},
	{
		garage_id: 8,
		part_id: 243,
		quantity: 94,
	},
	{
		garage_id: 8,
		part_id: 244,
		quantity: 158,
	},
	{
		garage_id: 8,
		part_id: 245,
		quantity: 387,
	},
	{
		garage_id: 8,
		part_id: 246,
		quantity: 312,
	},
	{
		garage_id: 8,
		part_id: 247,
		quantity: 412,
	},
	{
		garage_id: 8,
		part_id: 248,
		quantity: 66,
	},
	{
		garage_id: 8,
		part_id: 249,
		quantity: 16,
	},
	{
		garage_id: 8,
		part_id: 250,
		quantity: 364,
	},
	{
		garage_id: 8,
		part_id: 251,
		quantity: 438,
	},
	{
		garage_id: 8,
		part_id: 252,
		quantity: 263,
	},
	{
		garage_id: 8,
		part_id: 253,
		quantity: 166,
	},
	{
		garage_id: 8,
		part_id: 254,
		quantity: 163,
	},
	{
		garage_id: 8,
		part_id: 255,
		quantity: 174,
	},
	{
		garage_id: 8,
		part_id: 256,
		quantity: 156,
	},
	{
		garage_id: 8,
		part_id: 257,
		quantity: 180,
	},
	{
		garage_id: 8,
		part_id: 258,
		quantity: 315,
	},
	{
		garage_id: 8,
		part_id: 259,
		quantity: 185,
	},
	{
		garage_id: 8,
		part_id: 260,
		quantity: 6,
	},
	{
		garage_id: 8,
		part_id: 261,
		quantity: 150,
	},
	{
		garage_id: 8,
		part_id: 262,
		quantity: 43,
	},
	{
		garage_id: 8,
		part_id: 263,
		quantity: 287,
	},
	{
		garage_id: 8,
		part_id: 264,
		quantity: 45,
	},
	{
		garage_id: 8,
		part_id: 265,
		quantity: 22,
	},
	{
		garage_id: 8,
		part_id: 266,
		quantity: 336,
	},
	{
		garage_id: 8,
		part_id: 267,
		quantity: 343,
	},
	{
		garage_id: 8,
		part_id: 268,
		quantity: 483,
	},
	{
		garage_id: 8,
		part_id: 269,
		quantity: 251,
	},
	{
		garage_id: 8,
		part_id: 270,
		quantity: 418,
	},
	{
		garage_id: 8,
		part_id: 271,
		quantity: 191,
	},
	{
		garage_id: 8,
		part_id: 272,
		quantity: 141,
	},
	{
		garage_id: 8,
		part_id: 273,
		quantity: 404,
	},
	{
		garage_id: 8,
		part_id: 274,
		quantity: 127,
	},
	{
		garage_id: 8,
		part_id: 275,
		quantity: 275,
	},
	{
		garage_id: 8,
		part_id: 276,
		quantity: 331,
	},
	{
		garage_id: 8,
		part_id: 277,
		quantity: 238,
	},
	{
		garage_id: 8,
		part_id: 278,
		quantity: 353,
	},
	{
		garage_id: 8,
		part_id: 279,
		quantity: 6,
	},
	{
		garage_id: 8,
		part_id: 280,
		quantity: 296,
	},
	{
		garage_id: 8,
		part_id: 281,
		quantity: 490,
	},
	{
		garage_id: 8,
		part_id: 282,
		quantity: 426,
	},
	{
		garage_id: 8,
		part_id: 283,
		quantity: 258,
	},
	{
		garage_id: 8,
		part_id: 284,
		quantity: 336,
	},
	{
		garage_id: 8,
		part_id: 285,
		quantity: 100,
	},
	{
		garage_id: 8,
		part_id: 286,
		quantity: 492,
	},
	{
		garage_id: 8,
		part_id: 287,
		quantity: 253,
	},
	{
		garage_id: 8,
		part_id: 288,
		quantity: 280,
	},
	{
		garage_id: 8,
		part_id: 289,
		quantity: 111,
	},
	{
		garage_id: 8,
		part_id: 290,
		quantity: 79,
	},
	{
		garage_id: 8,
		part_id: 291,
		quantity: 45,
	},
	{
		garage_id: 8,
		part_id: 292,
		quantity: 295,
	},
	{
		garage_id: 8,
		part_id: 293,
		quantity: 480,
	},
	{
		garage_id: 8,
		part_id: 294,
		quantity: 276,
	},
	{
		garage_id: 8,
		part_id: 295,
		quantity: 461,
	},
	{
		garage_id: 8,
		part_id: 296,
		quantity: 9,
	},
	{
		garage_id: 8,
		part_id: 297,
		quantity: 10,
	},
	{
		garage_id: 8,
		part_id: 298,
		quantity: 310,
	},
	{
		garage_id: 8,
		part_id: 299,
		quantity: 115,
	},
	{
		garage_id: 8,
		part_id: 300,
		quantity: 225,
	},
	{
		garage_id: 8,
		part_id: 301,
		quantity: 114,
	},
	{
		garage_id: 8,
		part_id: 302,
		quantity: 260,
	},
	{
		garage_id: 8,
		part_id: 303,
		quantity: 340,
	},
	{
		garage_id: 8,
		part_id: 304,
		quantity: 499,
	},
	{
		garage_id: 8,
		part_id: 305,
		quantity: 444,
	},
	{
		garage_id: 8,
		part_id: 306,
		quantity: 322,
	},
	{
		garage_id: 8,
		part_id: 307,
		quantity: 407,
	},
	{
		garage_id: 8,
		part_id: 308,
		quantity: 28,
	},
	{
		garage_id: 8,
		part_id: 309,
		quantity: 122,
	},
	{
		garage_id: 8,
		part_id: 310,
		quantity: 125,
	},
	{
		garage_id: 8,
		part_id: 311,
		quantity: 393,
	},
	{
		garage_id: 8,
		part_id: 312,
		quantity: 285,
	},
	{
		garage_id: 8,
		part_id: 313,
		quantity: 338,
	},
	{
		garage_id: 8,
		part_id: 314,
		quantity: 119,
	},
	{
		garage_id: 8,
		part_id: 315,
		quantity: 243,
	},
	{
		garage_id: 8,
		part_id: 316,
		quantity: 63,
	},
	{
		garage_id: 8,
		part_id: 317,
		quantity: 447,
	},
	{
		garage_id: 8,
		part_id: 318,
		quantity: 102,
	},
	{
		garage_id: 8,
		part_id: 319,
		quantity: 93,
	},
	{
		garage_id: 8,
		part_id: 320,
		quantity: 250,
	},
	{
		garage_id: 8,
		part_id: 321,
		quantity: 289,
	},
	{
		garage_id: 8,
		part_id: 322,
		quantity: 20,
	},
	{
		garage_id: 8,
		part_id: 323,
		quantity: 473,
	},
	{
		garage_id: 8,
		part_id: 324,
		quantity: 106,
	},
	{
		garage_id: 8,
		part_id: 325,
		quantity: 356,
	},
	{
		garage_id: 8,
		part_id: 326,
		quantity: 350,
	},
	{
		garage_id: 8,
		part_id: 327,
		quantity: 118,
	},
	{
		garage_id: 8,
		part_id: 328,
		quantity: 85,
	},
	{
		garage_id: 8,
		part_id: 329,
		quantity: 486,
	},
	{
		garage_id: 8,
		part_id: 330,
		quantity: 87,
	},
	{
		garage_id: 8,
		part_id: 331,
		quantity: 173,
	},
	{
		garage_id: 8,
		part_id: 332,
		quantity: 348,
	},
	{
		garage_id: 8,
		part_id: 333,
		quantity: 490,
	},
	{
		garage_id: 8,
		part_id: 334,
		quantity: 108,
	},
	{
		garage_id: 8,
		part_id: 335,
		quantity: 322,
	},
	{
		garage_id: 8,
		part_id: 336,
		quantity: 404,
	},
	{
		garage_id: 8,
		part_id: 337,
		quantity: 469,
	},
	{
		garage_id: 8,
		part_id: 338,
		quantity: 23,
	},
	{
		garage_id: 8,
		part_id: 339,
		quantity: 152,
	},
	{
		garage_id: 8,
		part_id: 340,
		quantity: 310,
	},
	{
		garage_id: 8,
		part_id: 341,
		quantity: 488,
	},
	{
		garage_id: 8,
		part_id: 342,
		quantity: 35,
	},
	{
		garage_id: 8,
		part_id: 343,
		quantity: 17,
	},
	{
		garage_id: 8,
		part_id: 344,
		quantity: 11,
	},
	{
		garage_id: 8,
		part_id: 345,
		quantity: 272,
	},
	{
		garage_id: 8,
		part_id: 346,
		quantity: 459,
	},
	{
		garage_id: 8,
		part_id: 347,
		quantity: 308,
	},
	{
		garage_id: 8,
		part_id: 348,
		quantity: 421,
	},
	{
		garage_id: 8,
		part_id: 349,
		quantity: 57,
	},
	{
		garage_id: 8,
		part_id: 350,
		quantity: 147,
	},
	{
		garage_id: 8,
		part_id: 351,
		quantity: 340,
	},
	{
		garage_id: 8,
		part_id: 352,
		quantity: 435,
	},
	{
		garage_id: 8,
		part_id: 353,
		quantity: 395,
	},
	{
		garage_id: 8,
		part_id: 354,
		quantity: 186,
	},
	{
		garage_id: 8,
		part_id: 355,
		quantity: 260,
	},
	{
		garage_id: 8,
		part_id: 356,
		quantity: 474,
	},
	{
		garage_id: 8,
		part_id: 357,
		quantity: 352,
	},
	{
		garage_id: 8,
		part_id: 358,
		quantity: 80,
	},
	{
		garage_id: 8,
		part_id: 359,
		quantity: 484,
	},
	{
		garage_id: 8,
		part_id: 360,
		quantity: 103,
	},
	{
		garage_id: 8,
		part_id: 361,
		quantity: 12,
	},
	{
		garage_id: 8,
		part_id: 362,
		quantity: 343,
	},
	{
		garage_id: 8,
		part_id: 363,
		quantity: 343,
	},
	{
		garage_id: 8,
		part_id: 364,
		quantity: 281,
	},
	{
		garage_id: 8,
		part_id: 365,
		quantity: 197,
	},
	{
		garage_id: 8,
		part_id: 366,
		quantity: 427,
	},
	{
		garage_id: 8,
		part_id: 367,
		quantity: 465,
	},
	{
		garage_id: 8,
		part_id: 368,
		quantity: 384,
	},
	{
		garage_id: 8,
		part_id: 369,
		quantity: 494,
	},
	{
		garage_id: 8,
		part_id: 370,
		quantity: 420,
	},
	{
		garage_id: 8,
		part_id: 371,
		quantity: 10,
	},
	{
		garage_id: 8,
		part_id: 372,
		quantity: 197,
	},
	{
		garage_id: 8,
		part_id: 373,
		quantity: 152,
	},
	{
		garage_id: 8,
		part_id: 374,
		quantity: 463,
	},
	{
		garage_id: 8,
		part_id: 375,
		quantity: 381,
	},
	{
		garage_id: 8,
		part_id: 376,
		quantity: 469,
	},
	{
		garage_id: 8,
		part_id: 377,
		quantity: 355,
	},
	{
		garage_id: 8,
		part_id: 378,
		quantity: 76,
	},
	{
		garage_id: 8,
		part_id: 379,
		quantity: 228,
	},
	{
		garage_id: 8,
		part_id: 380,
		quantity: 245,
	},
	{
		garage_id: 8,
		part_id: 381,
		quantity: 74,
	},
	{
		garage_id: 8,
		part_id: 382,
		quantity: 191,
	},
	{
		garage_id: 8,
		part_id: 383,
		quantity: 295,
	},
	{
		garage_id: 8,
		part_id: 384,
		quantity: 142,
	},
	{
		garage_id: 8,
		part_id: 385,
		quantity: 250,
	},
	{
		garage_id: 8,
		part_id: 386,
		quantity: 93,
	},
	{
		garage_id: 8,
		part_id: 387,
		quantity: 38,
	},
	{
		garage_id: 8,
		part_id: 388,
		quantity: 148,
	},
	{
		garage_id: 8,
		part_id: 389,
		quantity: 468,
	},
	{
		garage_id: 8,
		part_id: 390,
		quantity: 52,
	},
	{
		garage_id: 8,
		part_id: 391,
		quantity: 92,
	},
	{
		garage_id: 8,
		part_id: 392,
		quantity: 385,
	},
	{
		garage_id: 8,
		part_id: 393,
		quantity: 251,
	},
	{
		garage_id: 8,
		part_id: 394,
		quantity: 12,
	},
	{
		garage_id: 8,
		part_id: 395,
		quantity: 451,
	},
	{
		garage_id: 8,
		part_id: 396,
		quantity: 85,
	},
	{
		garage_id: 8,
		part_id: 397,
		quantity: 415,
	},
	{
		garage_id: 8,
		part_id: 398,
		quantity: 426,
	},
	{
		garage_id: 8,
		part_id: 399,
		quantity: 320,
	},
	{
		garage_id: 9,
		part_id: 0,
		quantity: 72,
	},
	{
		garage_id: 9,
		part_id: 1,
		quantity: 289,
	},
	{
		garage_id: 9,
		part_id: 2,
		quantity: 347,
	},
	{
		garage_id: 9,
		part_id: 3,
		quantity: 176,
	},
	{
		garage_id: 9,
		part_id: 4,
		quantity: 339,
	},
	{
		garage_id: 9,
		part_id: 5,
		quantity: 338,
	},
	{
		garage_id: 9,
		part_id: 6,
		quantity: 406,
	},
	{
		garage_id: 9,
		part_id: 7,
		quantity: 237,
	},
	{
		garage_id: 9,
		part_id: 8,
		quantity: 494,
	},
	{
		garage_id: 9,
		part_id: 9,
		quantity: 437,
	},
	{
		garage_id: 9,
		part_id: 10,
		quantity: 331,
	},
	{
		garage_id: 9,
		part_id: 11,
		quantity: 426,
	},
	{
		garage_id: 9,
		part_id: 12,
		quantity: 68,
	},
	{
		garage_id: 9,
		part_id: 13,
		quantity: 462,
	},
	{
		garage_id: 9,
		part_id: 14,
		quantity: 278,
	},
	{
		garage_id: 9,
		part_id: 15,
		quantity: 192,
	},
	{
		garage_id: 9,
		part_id: 16,
		quantity: 170,
	},
	{
		garage_id: 9,
		part_id: 17,
		quantity: 171,
	},
	{
		garage_id: 9,
		part_id: 18,
		quantity: 313,
	},
	{
		garage_id: 9,
		part_id: 19,
		quantity: 13,
	},
	{
		garage_id: 9,
		part_id: 20,
		quantity: 87,
	},
	{
		garage_id: 9,
		part_id: 21,
		quantity: 231,
	},
	{
		garage_id: 9,
		part_id: 22,
		quantity: 361,
	},
	{
		garage_id: 9,
		part_id: 23,
		quantity: 388,
	},
	{
		garage_id: 9,
		part_id: 24,
		quantity: 203,
	},
	{
		garage_id: 9,
		part_id: 25,
		quantity: 410,
	},
	{
		garage_id: 9,
		part_id: 26,
		quantity: 231,
	},
	{
		garage_id: 9,
		part_id: 27,
		quantity: 431,
	},
	{
		garage_id: 9,
		part_id: 28,
		quantity: 455,
	},
	{
		garage_id: 9,
		part_id: 29,
		quantity: 58,
	},
	{
		garage_id: 9,
		part_id: 30,
		quantity: 456,
	},
	{
		garage_id: 9,
		part_id: 31,
		quantity: 446,
	},
	{
		garage_id: 9,
		part_id: 32,
		quantity: 379,
	},
	{
		garage_id: 9,
		part_id: 33,
		quantity: 332,
	},
	{
		garage_id: 9,
		part_id: 34,
		quantity: 399,
	},
	{
		garage_id: 9,
		part_id: 35,
		quantity: 209,
	},
	{
		garage_id: 9,
		part_id: 36,
		quantity: 258,
	},
	{
		garage_id: 9,
		part_id: 37,
		quantity: 166,
	},
	{
		garage_id: 9,
		part_id: 38,
		quantity: 266,
	},
	{
		garage_id: 9,
		part_id: 39,
		quantity: 31,
	},
	{
		garage_id: 9,
		part_id: 40,
		quantity: 286,
	},
	{
		garage_id: 9,
		part_id: 41,
		quantity: 147,
	},
	{
		garage_id: 9,
		part_id: 42,
		quantity: 436,
	},
	{
		garage_id: 9,
		part_id: 43,
		quantity: 427,
	},
	{
		garage_id: 9,
		part_id: 44,
		quantity: 353,
	},
	{
		garage_id: 9,
		part_id: 45,
		quantity: 414,
	},
	{
		garage_id: 9,
		part_id: 46,
		quantity: 454,
	},
	{
		garage_id: 9,
		part_id: 47,
		quantity: 267,
	},
	{
		garage_id: 9,
		part_id: 48,
		quantity: 311,
	},
	{
		garage_id: 9,
		part_id: 49,
		quantity: 56,
	},
	{
		garage_id: 9,
		part_id: 50,
		quantity: 240,
	},
	{
		garage_id: 9,
		part_id: 51,
		quantity: 472,
	},
	{
		garage_id: 9,
		part_id: 52,
		quantity: 72,
	},
	{
		garage_id: 9,
		part_id: 53,
		quantity: 452,
	},
	{
		garage_id: 9,
		part_id: 54,
		quantity: 261,
	},
	{
		garage_id: 9,
		part_id: 55,
		quantity: 203,
	},
	{
		garage_id: 9,
		part_id: 56,
		quantity: 136,
	},
	{
		garage_id: 9,
		part_id: 57,
		quantity: 500,
	},
	{
		garage_id: 9,
		part_id: 58,
		quantity: 436,
	},
	{
		garage_id: 9,
		part_id: 59,
		quantity: 188,
	},
	{
		garage_id: 9,
		part_id: 60,
		quantity: 177,
	},
	{
		garage_id: 9,
		part_id: 61,
		quantity: 41,
	},
	{
		garage_id: 9,
		part_id: 62,
		quantity: 260,
	},
	{
		garage_id: 9,
		part_id: 63,
		quantity: 264,
	},
	{
		garage_id: 9,
		part_id: 64,
		quantity: 479,
	},
	{
		garage_id: 9,
		part_id: 65,
		quantity: 32,
	},
	{
		garage_id: 9,
		part_id: 66,
		quantity: 145,
	},
	{
		garage_id: 9,
		part_id: 67,
		quantity: 346,
	},
	{
		garage_id: 9,
		part_id: 68,
		quantity: 105,
	},
	{
		garage_id: 9,
		part_id: 69,
		quantity: 354,
	},
	{
		garage_id: 9,
		part_id: 70,
		quantity: 32,
	},
	{
		garage_id: 9,
		part_id: 71,
		quantity: 100,
	},
	{
		garage_id: 9,
		part_id: 72,
		quantity: 122,
	},
	{
		garage_id: 9,
		part_id: 73,
		quantity: 80,
	},
	{
		garage_id: 9,
		part_id: 74,
		quantity: 394,
	},
	{
		garage_id: 9,
		part_id: 75,
		quantity: 280,
	},
	{
		garage_id: 9,
		part_id: 76,
		quantity: 79,
	},
	{
		garage_id: 9,
		part_id: 77,
		quantity: 317,
	},
	{
		garage_id: 9,
		part_id: 78,
		quantity: 44,
	},
	{
		garage_id: 9,
		part_id: 79,
		quantity: 356,
	},
	{
		garage_id: 9,
		part_id: 80,
		quantity: 375,
	},
	{
		garage_id: 9,
		part_id: 81,
		quantity: 496,
	},
	{
		garage_id: 9,
		part_id: 82,
		quantity: 320,
	},
	{
		garage_id: 9,
		part_id: 83,
		quantity: 39,
	},
	{
		garage_id: 9,
		part_id: 84,
		quantity: 320,
	},
	{
		garage_id: 9,
		part_id: 85,
		quantity: 240,
	},
	{
		garage_id: 9,
		part_id: 86,
		quantity: 36,
	},
	{
		garage_id: 9,
		part_id: 87,
		quantity: 31,
	},
	{
		garage_id: 9,
		part_id: 88,
		quantity: 229,
	},
	{
		garage_id: 9,
		part_id: 89,
		quantity: 99,
	},
	{
		garage_id: 9,
		part_id: 90,
		quantity: 28,
	},
	{
		garage_id: 9,
		part_id: 91,
		quantity: 387,
	},
	{
		garage_id: 9,
		part_id: 92,
		quantity: 160,
	},
	{
		garage_id: 9,
		part_id: 93,
		quantity: 174,
	},
	{
		garage_id: 9,
		part_id: 94,
		quantity: 337,
	},
	{
		garage_id: 9,
		part_id: 95,
		quantity: 191,
	},
	{
		garage_id: 9,
		part_id: 96,
		quantity: 154,
	},
	{
		garage_id: 9,
		part_id: 97,
		quantity: 448,
	},
	{
		garage_id: 9,
		part_id: 98,
		quantity: 427,
	},
	{
		garage_id: 9,
		part_id: 99,
		quantity: 475,
	},
	{
		garage_id: 9,
		part_id: 100,
		quantity: 55,
	},
	{
		garage_id: 9,
		part_id: 101,
		quantity: 486,
	},
	{
		garage_id: 9,
		part_id: 102,
		quantity: 324,
	},
	{
		garage_id: 9,
		part_id: 103,
		quantity: 448,
	},
	{
		garage_id: 9,
		part_id: 104,
		quantity: 458,
	},
	{
		garage_id: 9,
		part_id: 105,
		quantity: 125,
	},
	{
		garage_id: 9,
		part_id: 106,
		quantity: 298,
	},
	{
		garage_id: 9,
		part_id: 107,
		quantity: 287,
	},
	{
		garage_id: 9,
		part_id: 108,
		quantity: 43,
	},
	{
		garage_id: 9,
		part_id: 109,
		quantity: 251,
	},
	{
		garage_id: 9,
		part_id: 110,
		quantity: 51,
	},
	{
		garage_id: 9,
		part_id: 111,
		quantity: 138,
	},
	{
		garage_id: 9,
		part_id: 112,
		quantity: 410,
	},
	{
		garage_id: 9,
		part_id: 113,
		quantity: 217,
	},
	{
		garage_id: 9,
		part_id: 114,
		quantity: 138,
	},
	{
		garage_id: 9,
		part_id: 115,
		quantity: 85,
	},
	{
		garage_id: 9,
		part_id: 116,
		quantity: 46,
	},
	{
		garage_id: 9,
		part_id: 117,
		quantity: 367,
	},
	{
		garage_id: 9,
		part_id: 118,
		quantity: 98,
	},
	{
		garage_id: 9,
		part_id: 119,
		quantity: 311,
	},
	{
		garage_id: 9,
		part_id: 120,
		quantity: 233,
	},
	{
		garage_id: 9,
		part_id: 121,
		quantity: 396,
	},
	{
		garage_id: 9,
		part_id: 122,
		quantity: 154,
	},
	{
		garage_id: 9,
		part_id: 123,
		quantity: 71,
	},
	{
		garage_id: 9,
		part_id: 124,
		quantity: 440,
	},
	{
		garage_id: 9,
		part_id: 125,
		quantity: 128,
	},
	{
		garage_id: 9,
		part_id: 126,
		quantity: 179,
	},
	{
		garage_id: 9,
		part_id: 127,
		quantity: 259,
	},
	{
		garage_id: 9,
		part_id: 128,
		quantity: 463,
	},
	{
		garage_id: 9,
		part_id: 129,
		quantity: 274,
	},
	{
		garage_id: 9,
		part_id: 130,
		quantity: 347,
	},
	{
		garage_id: 9,
		part_id: 131,
		quantity: 267,
	},
	{
		garage_id: 9,
		part_id: 132,
		quantity: 73,
	},
	{
		garage_id: 9,
		part_id: 133,
		quantity: 65,
	},
	{
		garage_id: 9,
		part_id: 134,
		quantity: 436,
	},
	{
		garage_id: 9,
		part_id: 135,
		quantity: 225,
	},
	{
		garage_id: 9,
		part_id: 136,
		quantity: 381,
	},
	{
		garage_id: 9,
		part_id: 137,
		quantity: 347,
	},
	{
		garage_id: 9,
		part_id: 138,
		quantity: 28,
	},
	{
		garage_id: 9,
		part_id: 139,
		quantity: 376,
	},
	{
		garage_id: 9,
		part_id: 140,
		quantity: 340,
	},
	{
		garage_id: 9,
		part_id: 141,
		quantity: 252,
	},
	{
		garage_id: 9,
		part_id: 142,
		quantity: 112,
	},
	{
		garage_id: 9,
		part_id: 143,
		quantity: 132,
	},
	{
		garage_id: 9,
		part_id: 144,
		quantity: 101,
	},
	{
		garage_id: 9,
		part_id: 145,
		quantity: 488,
	},
	{
		garage_id: 9,
		part_id: 146,
		quantity: 419,
	},
	{
		garage_id: 9,
		part_id: 147,
		quantity: 115,
	},
	{
		garage_id: 9,
		part_id: 148,
		quantity: 90,
	},
	{
		garage_id: 9,
		part_id: 149,
		quantity: 251,
	},
	{
		garage_id: 9,
		part_id: 150,
		quantity: 347,
	},
	{
		garage_id: 9,
		part_id: 151,
		quantity: 430,
	},
	{
		garage_id: 9,
		part_id: 152,
		quantity: 435,
	},
	{
		garage_id: 9,
		part_id: 153,
		quantity: 481,
	},
	{
		garage_id: 9,
		part_id: 154,
		quantity: 385,
	},
	{
		garage_id: 9,
		part_id: 155,
		quantity: 79,
	},
	{
		garage_id: 9,
		part_id: 156,
		quantity: 66,
	},
	{
		garage_id: 9,
		part_id: 157,
		quantity: 64,
	},
	{
		garage_id: 9,
		part_id: 158,
		quantity: 17,
	},
	{
		garage_id: 9,
		part_id: 159,
		quantity: 418,
	},
	{
		garage_id: 9,
		part_id: 160,
		quantity: 127,
	},
	{
		garage_id: 9,
		part_id: 161,
		quantity: 314,
	},
	{
		garage_id: 9,
		part_id: 162,
		quantity: 26,
	},
	{
		garage_id: 9,
		part_id: 163,
		quantity: 339,
	},
	{
		garage_id: 9,
		part_id: 164,
		quantity: 271,
	},
	{
		garage_id: 9,
		part_id: 165,
		quantity: 405,
	},
	{
		garage_id: 9,
		part_id: 166,
		quantity: 376,
	},
	{
		garage_id: 9,
		part_id: 167,
		quantity: 236,
	},
	{
		garage_id: 9,
		part_id: 168,
		quantity: 195,
	},
	{
		garage_id: 9,
		part_id: 169,
		quantity: 336,
	},
	{
		garage_id: 9,
		part_id: 170,
		quantity: 492,
	},
	{
		garage_id: 9,
		part_id: 171,
		quantity: 229,
	},
	{
		garage_id: 9,
		part_id: 172,
		quantity: 82,
	},
	{
		garage_id: 9,
		part_id: 173,
		quantity: 167,
	},
	{
		garage_id: 9,
		part_id: 174,
		quantity: 144,
	},
	{
		garage_id: 9,
		part_id: 175,
		quantity: 500,
	},
	{
		garage_id: 9,
		part_id: 176,
		quantity: 345,
	},
	{
		garage_id: 9,
		part_id: 177,
		quantity: 381,
	},
	{
		garage_id: 9,
		part_id: 178,
		quantity: 158,
	},
	{
		garage_id: 9,
		part_id: 179,
		quantity: 299,
	},
	{
		garage_id: 9,
		part_id: 180,
		quantity: 53,
	},
	{
		garage_id: 9,
		part_id: 181,
		quantity: 422,
	},
	{
		garage_id: 9,
		part_id: 182,
		quantity: 225,
	},
	{
		garage_id: 9,
		part_id: 183,
		quantity: 421,
	},
	{
		garage_id: 9,
		part_id: 184,
		quantity: 303,
	},
	{
		garage_id: 9,
		part_id: 185,
		quantity: 84,
	},
	{
		garage_id: 9,
		part_id: 186,
		quantity: 69,
	},
	{
		garage_id: 9,
		part_id: 187,
		quantity: 273,
	},
	{
		garage_id: 9,
		part_id: 188,
		quantity: 30,
	},
	{
		garage_id: 9,
		part_id: 189,
		quantity: 108,
	},
	{
		garage_id: 9,
		part_id: 190,
		quantity: 191,
	},
	{
		garage_id: 9,
		part_id: 191,
		quantity: 381,
	},
	{
		garage_id: 9,
		part_id: 192,
		quantity: 54,
	},
	{
		garage_id: 9,
		part_id: 193,
		quantity: 143,
	},
	{
		garage_id: 9,
		part_id: 194,
		quantity: 182,
	},
	{
		garage_id: 9,
		part_id: 195,
		quantity: 454,
	},
	{
		garage_id: 9,
		part_id: 196,
		quantity: 281,
	},
	{
		garage_id: 9,
		part_id: 197,
		quantity: 202,
	},
	{
		garage_id: 9,
		part_id: 198,
		quantity: 64,
	},
	{
		garage_id: 9,
		part_id: 199,
		quantity: 144,
	},
	{
		garage_id: 9,
		part_id: 200,
		quantity: 234,
	},
	{
		garage_id: 9,
		part_id: 201,
		quantity: 433,
	},
	{
		garage_id: 9,
		part_id: 202,
		quantity: 166,
	},
	{
		garage_id: 9,
		part_id: 203,
		quantity: 382,
	},
	{
		garage_id: 9,
		part_id: 204,
		quantity: 235,
	},
	{
		garage_id: 9,
		part_id: 205,
		quantity: 132,
	},
	{
		garage_id: 9,
		part_id: 206,
		quantity: 13,
	},
	{
		garage_id: 9,
		part_id: 207,
		quantity: 315,
	},
	{
		garage_id: 9,
		part_id: 208,
		quantity: 425,
	},
	{
		garage_id: 9,
		part_id: 209,
		quantity: 222,
	},
	{
		garage_id: 9,
		part_id: 210,
		quantity: 404,
	},
	{
		garage_id: 9,
		part_id: 211,
		quantity: 57,
	},
	{
		garage_id: 9,
		part_id: 212,
		quantity: 213,
	},
	{
		garage_id: 9,
		part_id: 213,
		quantity: 256,
	},
	{
		garage_id: 9,
		part_id: 214,
		quantity: 195,
	},
	{
		garage_id: 9,
		part_id: 215,
		quantity: 95,
	},
	{
		garage_id: 9,
		part_id: 216,
		quantity: 361,
	},
	{
		garage_id: 9,
		part_id: 217,
		quantity: 224,
	},
	{
		garage_id: 9,
		part_id: 218,
		quantity: 329,
	},
	{
		garage_id: 9,
		part_id: 219,
		quantity: 234,
	},
	{
		garage_id: 9,
		part_id: 220,
		quantity: 190,
	},
	{
		garage_id: 9,
		part_id: 221,
		quantity: 320,
	},
	{
		garage_id: 9,
		part_id: 222,
		quantity: 217,
	},
	{
		garage_id: 9,
		part_id: 223,
		quantity: 144,
	},
	{
		garage_id: 9,
		part_id: 224,
		quantity: 465,
	},
	{
		garage_id: 9,
		part_id: 225,
		quantity: 121,
	},
	{
		garage_id: 9,
		part_id: 226,
		quantity: 142,
	},
	{
		garage_id: 9,
		part_id: 227,
		quantity: 198,
	},
	{
		garage_id: 9,
		part_id: 228,
		quantity: 286,
	},
	{
		garage_id: 9,
		part_id: 229,
		quantity: 65,
	},
	{
		garage_id: 9,
		part_id: 230,
		quantity: 15,
	},
	{
		garage_id: 9,
		part_id: 231,
		quantity: 226,
	},
	{
		garage_id: 9,
		part_id: 232,
		quantity: 383,
	},
	{
		garage_id: 9,
		part_id: 233,
		quantity: 80,
	},
	{
		garage_id: 9,
		part_id: 234,
		quantity: 184,
	},
	{
		garage_id: 9,
		part_id: 235,
		quantity: 44,
	},
	{
		garage_id: 9,
		part_id: 236,
		quantity: 5,
	},
	{
		garage_id: 9,
		part_id: 237,
		quantity: 135,
	},
	{
		garage_id: 9,
		part_id: 238,
		quantity: 108,
	},
	{
		garage_id: 9,
		part_id: 239,
		quantity: 128,
	},
	{
		garage_id: 9,
		part_id: 240,
		quantity: 401,
	},
	{
		garage_id: 9,
		part_id: 241,
		quantity: 432,
	},
	{
		garage_id: 9,
		part_id: 242,
		quantity: 329,
	},
	{
		garage_id: 9,
		part_id: 243,
		quantity: 220,
	},
	{
		garage_id: 9,
		part_id: 244,
		quantity: 272,
	},
	{
		garage_id: 9,
		part_id: 245,
		quantity: 132,
	},
	{
		garage_id: 9,
		part_id: 246,
		quantity: 183,
	},
	{
		garage_id: 9,
		part_id: 247,
		quantity: 210,
	},
	{
		garage_id: 9,
		part_id: 248,
		quantity: 407,
	},
	{
		garage_id: 9,
		part_id: 249,
		quantity: 428,
	},
	{
		garage_id: 9,
		part_id: 250,
		quantity: 389,
	},
	{
		garage_id: 9,
		part_id: 251,
		quantity: 285,
	},
	{
		garage_id: 9,
		part_id: 252,
		quantity: 202,
	},
	{
		garage_id: 9,
		part_id: 253,
		quantity: 10,
	},
	{
		garage_id: 9,
		part_id: 254,
		quantity: 410,
	},
	{
		garage_id: 9,
		part_id: 255,
		quantity: 336,
	},
	{
		garage_id: 9,
		part_id: 256,
		quantity: 341,
	},
	{
		garage_id: 9,
		part_id: 257,
		quantity: 0,
	},
	{
		garage_id: 9,
		part_id: 258,
		quantity: 389,
	},
	{
		garage_id: 9,
		part_id: 259,
		quantity: 152,
	},
	{
		garage_id: 9,
		part_id: 260,
		quantity: 69,
	},
	{
		garage_id: 9,
		part_id: 261,
		quantity: 231,
	},
	{
		garage_id: 9,
		part_id: 262,
		quantity: 132,
	},
	{
		garage_id: 9,
		part_id: 263,
		quantity: 317,
	},
	{
		garage_id: 9,
		part_id: 264,
		quantity: 288,
	},
	{
		garage_id: 9,
		part_id: 265,
		quantity: 284,
	},
	{
		garage_id: 9,
		part_id: 266,
		quantity: 209,
	},
	{
		garage_id: 9,
		part_id: 267,
		quantity: 46,
	},
	{
		garage_id: 9,
		part_id: 268,
		quantity: 122,
	},
	{
		garage_id: 9,
		part_id: 269,
		quantity: 326,
	},
	{
		garage_id: 9,
		part_id: 270,
		quantity: 115,
	},
	{
		garage_id: 9,
		part_id: 271,
		quantity: 384,
	},
	{
		garage_id: 9,
		part_id: 272,
		quantity: 236,
	},
	{
		garage_id: 9,
		part_id: 273,
		quantity: 13,
	},
	{
		garage_id: 9,
		part_id: 274,
		quantity: 263,
	},
	{
		garage_id: 9,
		part_id: 275,
		quantity: 287,
	},
	{
		garage_id: 9,
		part_id: 276,
		quantity: 353,
	},
	{
		garage_id: 9,
		part_id: 277,
		quantity: 414,
	},
	{
		garage_id: 9,
		part_id: 278,
		quantity: 15,
	},
	{
		garage_id: 9,
		part_id: 279,
		quantity: 133,
	},
	{
		garage_id: 9,
		part_id: 280,
		quantity: 406,
	},
	{
		garage_id: 9,
		part_id: 281,
		quantity: 321,
	},
	{
		garage_id: 9,
		part_id: 282,
		quantity: 441,
	},
	{
		garage_id: 9,
		part_id: 283,
		quantity: 16,
	},
	{
		garage_id: 9,
		part_id: 284,
		quantity: 139,
	},
	{
		garage_id: 9,
		part_id: 285,
		quantity: 399,
	},
	{
		garage_id: 9,
		part_id: 286,
		quantity: 476,
	},
	{
		garage_id: 9,
		part_id: 287,
		quantity: 186,
	},
	{
		garage_id: 9,
		part_id: 288,
		quantity: 470,
	},
	{
		garage_id: 9,
		part_id: 289,
		quantity: 24,
	},
	{
		garage_id: 9,
		part_id: 290,
		quantity: 207,
	},
	{
		garage_id: 9,
		part_id: 291,
		quantity: 344,
	},
	{
		garage_id: 9,
		part_id: 292,
		quantity: 246,
	},
	{
		garage_id: 9,
		part_id: 293,
		quantity: 117,
	},
	{
		garage_id: 9,
		part_id: 294,
		quantity: 482,
	},
	{
		garage_id: 9,
		part_id: 295,
		quantity: 211,
	},
	{
		garage_id: 9,
		part_id: 296,
		quantity: 100,
	},
	{
		garage_id: 9,
		part_id: 297,
		quantity: 87,
	},
	{
		garage_id: 9,
		part_id: 298,
		quantity: 437,
	},
	{
		garage_id: 9,
		part_id: 299,
		quantity: 469,
	},
	{
		garage_id: 9,
		part_id: 300,
		quantity: 370,
	},
	{
		garage_id: 9,
		part_id: 301,
		quantity: 344,
	},
	{
		garage_id: 9,
		part_id: 302,
		quantity: 406,
	},
	{
		garage_id: 9,
		part_id: 303,
		quantity: 156,
	},
	{
		garage_id: 9,
		part_id: 304,
		quantity: 364,
	},
	{
		garage_id: 9,
		part_id: 305,
		quantity: 445,
	},
	{
		garage_id: 9,
		part_id: 306,
		quantity: 494,
	},
	{
		garage_id: 9,
		part_id: 307,
		quantity: 487,
	},
	{
		garage_id: 9,
		part_id: 308,
		quantity: 94,
	},
	{
		garage_id: 9,
		part_id: 309,
		quantity: 7,
	},
	{
		garage_id: 9,
		part_id: 310,
		quantity: 106,
	},
	{
		garage_id: 9,
		part_id: 311,
		quantity: 223,
	},
	{
		garage_id: 9,
		part_id: 312,
		quantity: 196,
	},
	{
		garage_id: 9,
		part_id: 313,
		quantity: 228,
	},
	{
		garage_id: 9,
		part_id: 314,
		quantity: 57,
	},
	{
		garage_id: 9,
		part_id: 315,
		quantity: 135,
	},
	{
		garage_id: 9,
		part_id: 316,
		quantity: 266,
	},
	{
		garage_id: 9,
		part_id: 317,
		quantity: 75,
	},
	{
		garage_id: 9,
		part_id: 318,
		quantity: 16,
	},
	{
		garage_id: 9,
		part_id: 319,
		quantity: 35,
	},
	{
		garage_id: 9,
		part_id: 320,
		quantity: 379,
	},
	{
		garage_id: 9,
		part_id: 321,
		quantity: 475,
	},
	{
		garage_id: 9,
		part_id: 322,
		quantity: 468,
	},
	{
		garage_id: 9,
		part_id: 323,
		quantity: 218,
	},
	{
		garage_id: 9,
		part_id: 324,
		quantity: 330,
	},
	{
		garage_id: 9,
		part_id: 325,
		quantity: 480,
	},
	{
		garage_id: 9,
		part_id: 326,
		quantity: 168,
	},
	{
		garage_id: 9,
		part_id: 327,
		quantity: 245,
	},
	{
		garage_id: 9,
		part_id: 328,
		quantity: 216,
	},
	{
		garage_id: 9,
		part_id: 329,
		quantity: 91,
	},
	{
		garage_id: 9,
		part_id: 330,
		quantity: 408,
	},
	{
		garage_id: 9,
		part_id: 331,
		quantity: 257,
	},
	{
		garage_id: 9,
		part_id: 332,
		quantity: 358,
	},
	{
		garage_id: 9,
		part_id: 333,
		quantity: 161,
	},
	{
		garage_id: 9,
		part_id: 334,
		quantity: 472,
	},
	{
		garage_id: 9,
		part_id: 335,
		quantity: 405,
	},
	{
		garage_id: 9,
		part_id: 336,
		quantity: 250,
	},
	{
		garage_id: 9,
		part_id: 337,
		quantity: 383,
	},
	{
		garage_id: 9,
		part_id: 338,
		quantity: 253,
	},
	{
		garage_id: 9,
		part_id: 339,
		quantity: 228,
	},
	{
		garage_id: 9,
		part_id: 340,
		quantity: 360,
	},
	{
		garage_id: 9,
		part_id: 341,
		quantity: 101,
	},
	{
		garage_id: 9,
		part_id: 342,
		quantity: 284,
	},
	{
		garage_id: 9,
		part_id: 343,
		quantity: 441,
	},
	{
		garage_id: 9,
		part_id: 344,
		quantity: 363,
	},
	{
		garage_id: 9,
		part_id: 345,
		quantity: 416,
	},
	{
		garage_id: 9,
		part_id: 346,
		quantity: 36,
	},
	{
		garage_id: 9,
		part_id: 347,
		quantity: 337,
	},
	{
		garage_id: 9,
		part_id: 348,
		quantity: 112,
	},
	{
		garage_id: 9,
		part_id: 349,
		quantity: 401,
	},
	{
		garage_id: 9,
		part_id: 350,
		quantity: 145,
	},
	{
		garage_id: 9,
		part_id: 351,
		quantity: 61,
	},
	{
		garage_id: 9,
		part_id: 352,
		quantity: 211,
	},
	{
		garage_id: 9,
		part_id: 353,
		quantity: 146,
	},
	{
		garage_id: 9,
		part_id: 354,
		quantity: 93,
	},
	{
		garage_id: 9,
		part_id: 355,
		quantity: 252,
	},
	{
		garage_id: 9,
		part_id: 356,
		quantity: 62,
	},
	{
		garage_id: 9,
		part_id: 357,
		quantity: 209,
	},
	{
		garage_id: 9,
		part_id: 358,
		quantity: 393,
	},
	{
		garage_id: 9,
		part_id: 359,
		quantity: 178,
	},
	{
		garage_id: 9,
		part_id: 360,
		quantity: 470,
	},
	{
		garage_id: 9,
		part_id: 361,
		quantity: 330,
	},
	{
		garage_id: 9,
		part_id: 362,
		quantity: 245,
	},
	{
		garage_id: 9,
		part_id: 363,
		quantity: 423,
	},
	{
		garage_id: 9,
		part_id: 364,
		quantity: 36,
	},
	{
		garage_id: 9,
		part_id: 365,
		quantity: 314,
	},
	{
		garage_id: 9,
		part_id: 366,
		quantity: 99,
	},
	{
		garage_id: 9,
		part_id: 367,
		quantity: 323,
	},
	{
		garage_id: 9,
		part_id: 368,
		quantity: 82,
	},
	{
		garage_id: 9,
		part_id: 369,
		quantity: 412,
	},
	{
		garage_id: 9,
		part_id: 370,
		quantity: 273,
	},
	{
		garage_id: 9,
		part_id: 371,
		quantity: 341,
	},
	{
		garage_id: 9,
		part_id: 372,
		quantity: 422,
	},
	{
		garage_id: 9,
		part_id: 373,
		quantity: 404,
	},
	{
		garage_id: 9,
		part_id: 374,
		quantity: 357,
	},
	{
		garage_id: 9,
		part_id: 375,
		quantity: 379,
	},
	{
		garage_id: 9,
		part_id: 376,
		quantity: 377,
	},
	{
		garage_id: 9,
		part_id: 377,
		quantity: 397,
	},
	{
		garage_id: 9,
		part_id: 378,
		quantity: 128,
	},
	{
		garage_id: 9,
		part_id: 379,
		quantity: 150,
	},
	{
		garage_id: 9,
		part_id: 380,
		quantity: 102,
	},
	{
		garage_id: 9,
		part_id: 381,
		quantity: 279,
	},
	{
		garage_id: 9,
		part_id: 382,
		quantity: 68,
	},
	{
		garage_id: 9,
		part_id: 383,
		quantity: 230,
	},
	{
		garage_id: 9,
		part_id: 384,
		quantity: 276,
	},
	{
		garage_id: 9,
		part_id: 385,
		quantity: 186,
	},
	{
		garage_id: 9,
		part_id: 386,
		quantity: 343,
	},
	{
		garage_id: 9,
		part_id: 387,
		quantity: 426,
	},
	{
		garage_id: 9,
		part_id: 388,
		quantity: 449,
	},
	{
		garage_id: 9,
		part_id: 389,
		quantity: 312,
	},
	{
		garage_id: 9,
		part_id: 390,
		quantity: 487,
	},
	{
		garage_id: 9,
		part_id: 391,
		quantity: 193,
	},
	{
		garage_id: 9,
		part_id: 392,
		quantity: 263,
	},
	{
		garage_id: 9,
		part_id: 393,
		quantity: 118,
	},
	{
		garage_id: 9,
		part_id: 394,
		quantity: 36,
	},
	{
		garage_id: 9,
		part_id: 395,
		quantity: 467,
	},
	{
		garage_id: 9,
		part_id: 396,
		quantity: 241,
	},
	{
		garage_id: 9,
		part_id: 397,
		quantity: 141,
	},
	{
		garage_id: 9,
		part_id: 398,
		quantity: 279,
	},
	{
		garage_id: 9,
		part_id: 399,
		quantity: 82,
	},
	{
		garage_id: 10,
		part_id: 0,
		quantity: 253,
	},
	{
		garage_id: 10,
		part_id: 1,
		quantity: 193,
	},
	{
		garage_id: 10,
		part_id: 2,
		quantity: 353,
	},
	{
		garage_id: 10,
		part_id: 3,
		quantity: 445,
	},
	{
		garage_id: 10,
		part_id: 4,
		quantity: 283,
	},
	{
		garage_id: 10,
		part_id: 5,
		quantity: 393,
	},
	{
		garage_id: 10,
		part_id: 6,
		quantity: 61,
	},
	{
		garage_id: 10,
		part_id: 7,
		quantity: 448,
	},
	{
		garage_id: 10,
		part_id: 8,
		quantity: 397,
	},
	{
		garage_id: 10,
		part_id: 9,
		quantity: 29,
	},
	{
		garage_id: 10,
		part_id: 10,
		quantity: 36,
	},
	{
		garage_id: 10,
		part_id: 11,
		quantity: 151,
	},
	{
		garage_id: 10,
		part_id: 12,
		quantity: 318,
	},
	{
		garage_id: 10,
		part_id: 13,
		quantity: 278,
	},
	{
		garage_id: 10,
		part_id: 14,
		quantity: 401,
	},
	{
		garage_id: 10,
		part_id: 15,
		quantity: 387,
	},
	{
		garage_id: 10,
		part_id: 16,
		quantity: 67,
	},
	{
		garage_id: 10,
		part_id: 17,
		quantity: 457,
	},
	{
		garage_id: 10,
		part_id: 18,
		quantity: 453,
	},
	{
		garage_id: 10,
		part_id: 19,
		quantity: 2,
	},
	{
		garage_id: 10,
		part_id: 20,
		quantity: 75,
	},
	{
		garage_id: 10,
		part_id: 21,
		quantity: 92,
	},
	{
		garage_id: 10,
		part_id: 22,
		quantity: 174,
	},
	{
		garage_id: 10,
		part_id: 23,
		quantity: 321,
	},
	{
		garage_id: 10,
		part_id: 24,
		quantity: 1,
	},
	{
		garage_id: 10,
		part_id: 25,
		quantity: 226,
	},
	{
		garage_id: 10,
		part_id: 26,
		quantity: 270,
	},
	{
		garage_id: 10,
		part_id: 27,
		quantity: 352,
	},
	{
		garage_id: 10,
		part_id: 28,
		quantity: 449,
	},
	{
		garage_id: 10,
		part_id: 29,
		quantity: 355,
	},
	{
		garage_id: 10,
		part_id: 30,
		quantity: 304,
	},
	{
		garage_id: 10,
		part_id: 31,
		quantity: 168,
	},
	{
		garage_id: 10,
		part_id: 32,
		quantity: 208,
	},
	{
		garage_id: 10,
		part_id: 33,
		quantity: 182,
	},
	{
		garage_id: 10,
		part_id: 34,
		quantity: 53,
	},
	{
		garage_id: 10,
		part_id: 35,
		quantity: 406,
	},
	{
		garage_id: 10,
		part_id: 36,
		quantity: 407,
	},
	{
		garage_id: 10,
		part_id: 37,
		quantity: 272,
	},
	{
		garage_id: 10,
		part_id: 38,
		quantity: 412,
	},
	{
		garage_id: 10,
		part_id: 39,
		quantity: 482,
	},
	{
		garage_id: 10,
		part_id: 40,
		quantity: 190,
	},
	{
		garage_id: 10,
		part_id: 41,
		quantity: 39,
	},
	{
		garage_id: 10,
		part_id: 42,
		quantity: 213,
	},
	{
		garage_id: 10,
		part_id: 43,
		quantity: 194,
	},
	{
		garage_id: 10,
		part_id: 44,
		quantity: 419,
	},
	{
		garage_id: 10,
		part_id: 45,
		quantity: 347,
	},
	{
		garage_id: 10,
		part_id: 46,
		quantity: 100,
	},
	{
		garage_id: 10,
		part_id: 47,
		quantity: 401,
	},
	{
		garage_id: 10,
		part_id: 48,
		quantity: 129,
	},
	{
		garage_id: 10,
		part_id: 49,
		quantity: 338,
	},
	{
		garage_id: 10,
		part_id: 50,
		quantity: 78,
	},
	{
		garage_id: 10,
		part_id: 51,
		quantity: 184,
	},
	{
		garage_id: 10,
		part_id: 52,
		quantity: 167,
	},
	{
		garage_id: 10,
		part_id: 53,
		quantity: 132,
	},
	{
		garage_id: 10,
		part_id: 54,
		quantity: 99,
	},
	{
		garage_id: 10,
		part_id: 55,
		quantity: 298,
	},
	{
		garage_id: 10,
		part_id: 56,
		quantity: 347,
	},
	{
		garage_id: 10,
		part_id: 57,
		quantity: 453,
	},
	{
		garage_id: 10,
		part_id: 58,
		quantity: 144,
	},
	{
		garage_id: 10,
		part_id: 59,
		quantity: 36,
	},
	{
		garage_id: 10,
		part_id: 60,
		quantity: 249,
	},
	{
		garage_id: 10,
		part_id: 61,
		quantity: 386,
	},
	{
		garage_id: 10,
		part_id: 62,
		quantity: 3,
	},
	{
		garage_id: 10,
		part_id: 63,
		quantity: 451,
	},
	{
		garage_id: 10,
		part_id: 64,
		quantity: 414,
	},
	{
		garage_id: 10,
		part_id: 65,
		quantity: 472,
	},
	{
		garage_id: 10,
		part_id: 66,
		quantity: 173,
	},
	{
		garage_id: 10,
		part_id: 67,
		quantity: 92,
	},
	{
		garage_id: 10,
		part_id: 68,
		quantity: 464,
	},
	{
		garage_id: 10,
		part_id: 69,
		quantity: 270,
	},
	{
		garage_id: 10,
		part_id: 70,
		quantity: 253,
	},
	{
		garage_id: 10,
		part_id: 71,
		quantity: 106,
	},
	{
		garage_id: 10,
		part_id: 72,
		quantity: 394,
	},
	{
		garage_id: 10,
		part_id: 73,
		quantity: 8,
	},
	{
		garage_id: 10,
		part_id: 74,
		quantity: 41,
	},
	{
		garage_id: 10,
		part_id: 75,
		quantity: 477,
	},
	{
		garage_id: 10,
		part_id: 76,
		quantity: 412,
	},
	{
		garage_id: 10,
		part_id: 77,
		quantity: 138,
	},
	{
		garage_id: 10,
		part_id: 78,
		quantity: 369,
	},
	{
		garage_id: 10,
		part_id: 79,
		quantity: 196,
	},
	{
		garage_id: 10,
		part_id: 80,
		quantity: 282,
	},
	{
		garage_id: 10,
		part_id: 81,
		quantity: 83,
	},
	{
		garage_id: 10,
		part_id: 82,
		quantity: 34,
	},
	{
		garage_id: 10,
		part_id: 83,
		quantity: 73,
	},
	{
		garage_id: 10,
		part_id: 84,
		quantity: 144,
	},
	{
		garage_id: 10,
		part_id: 85,
		quantity: 326,
	},
	{
		garage_id: 10,
		part_id: 86,
		quantity: 334,
	},
	{
		garage_id: 10,
		part_id: 87,
		quantity: 421,
	},
	{
		garage_id: 10,
		part_id: 88,
		quantity: 105,
	},
	{
		garage_id: 10,
		part_id: 89,
		quantity: 339,
	},
	{
		garage_id: 10,
		part_id: 90,
		quantity: 381,
	},
	{
		garage_id: 10,
		part_id: 91,
		quantity: 76,
	},
	{
		garage_id: 10,
		part_id: 92,
		quantity: 9,
	},
	{
		garage_id: 10,
		part_id: 93,
		quantity: 301,
	},
	{
		garage_id: 10,
		part_id: 94,
		quantity: 481,
	},
	{
		garage_id: 10,
		part_id: 95,
		quantity: 126,
	},
	{
		garage_id: 10,
		part_id: 96,
		quantity: 34,
	},
	{
		garage_id: 10,
		part_id: 97,
		quantity: 359,
	},
	{
		garage_id: 10,
		part_id: 98,
		quantity: 489,
	},
	{
		garage_id: 10,
		part_id: 99,
		quantity: 336,
	},
	{
		garage_id: 10,
		part_id: 100,
		quantity: 238,
	},
	{
		garage_id: 10,
		part_id: 101,
		quantity: 110,
	},
	{
		garage_id: 10,
		part_id: 102,
		quantity: 276,
	},
	{
		garage_id: 10,
		part_id: 103,
		quantity: 366,
	},
	{
		garage_id: 10,
		part_id: 104,
		quantity: 281,
	},
	{
		garage_id: 10,
		part_id: 105,
		quantity: 12,
	},
	{
		garage_id: 10,
		part_id: 106,
		quantity: 69,
	},
	{
		garage_id: 10,
		part_id: 107,
		quantity: 338,
	},
	{
		garage_id: 10,
		part_id: 108,
		quantity: 65,
	},
	{
		garage_id: 10,
		part_id: 109,
		quantity: 500,
	},
	{
		garage_id: 10,
		part_id: 110,
		quantity: 72,
	},
	{
		garage_id: 10,
		part_id: 111,
		quantity: 404,
	},
	{
		garage_id: 10,
		part_id: 112,
		quantity: 254,
	},
	{
		garage_id: 10,
		part_id: 113,
		quantity: 260,
	},
	{
		garage_id: 10,
		part_id: 114,
		quantity: 204,
	},
	{
		garage_id: 10,
		part_id: 115,
		quantity: 289,
	},
	{
		garage_id: 10,
		part_id: 116,
		quantity: 64,
	},
	{
		garage_id: 10,
		part_id: 117,
		quantity: 76,
	},
	{
		garage_id: 10,
		part_id: 118,
		quantity: 309,
	},
	{
		garage_id: 10,
		part_id: 119,
		quantity: 82,
	},
	{
		garage_id: 10,
		part_id: 120,
		quantity: 116,
	},
	{
		garage_id: 10,
		part_id: 121,
		quantity: 341,
	},
	{
		garage_id: 10,
		part_id: 122,
		quantity: 18,
	},
	{
		garage_id: 10,
		part_id: 123,
		quantity: 481,
	},
	{
		garage_id: 10,
		part_id: 124,
		quantity: 21,
	},
	{
		garage_id: 10,
		part_id: 125,
		quantity: 127,
	},
	{
		garage_id: 10,
		part_id: 126,
		quantity: 494,
	},
	{
		garage_id: 10,
		part_id: 127,
		quantity: 350,
	},
	{
		garage_id: 10,
		part_id: 128,
		quantity: 379,
	},
	{
		garage_id: 10,
		part_id: 129,
		quantity: 48,
	},
	{
		garage_id: 10,
		part_id: 130,
		quantity: 311,
	},
	{
		garage_id: 10,
		part_id: 131,
		quantity: 41,
	},
	{
		garage_id: 10,
		part_id: 132,
		quantity: 4,
	},
	{
		garage_id: 10,
		part_id: 133,
		quantity: 409,
	},
	{
		garage_id: 10,
		part_id: 134,
		quantity: 499,
	},
	{
		garage_id: 10,
		part_id: 135,
		quantity: 80,
	},
	{
		garage_id: 10,
		part_id: 136,
		quantity: 217,
	},
	{
		garage_id: 10,
		part_id: 137,
		quantity: 332,
	},
	{
		garage_id: 10,
		part_id: 138,
		quantity: 458,
	},
	{
		garage_id: 10,
		part_id: 139,
		quantity: 186,
	},
	{
		garage_id: 10,
		part_id: 140,
		quantity: 145,
	},
	{
		garage_id: 10,
		part_id: 141,
		quantity: 64,
	},
	{
		garage_id: 10,
		part_id: 142,
		quantity: 33,
	},
	{
		garage_id: 10,
		part_id: 143,
		quantity: 155,
	},
	{
		garage_id: 10,
		part_id: 144,
		quantity: 195,
	},
	{
		garage_id: 10,
		part_id: 145,
		quantity: 253,
	},
	{
		garage_id: 10,
		part_id: 146,
		quantity: 425,
	},
	{
		garage_id: 10,
		part_id: 147,
		quantity: 454,
	},
	{
		garage_id: 10,
		part_id: 148,
		quantity: 492,
	},
	{
		garage_id: 10,
		part_id: 149,
		quantity: 394,
	},
	{
		garage_id: 10,
		part_id: 150,
		quantity: 219,
	},
	{
		garage_id: 10,
		part_id: 151,
		quantity: 53,
	},
	{
		garage_id: 10,
		part_id: 152,
		quantity: 196,
	},
	{
		garage_id: 10,
		part_id: 153,
		quantity: 335,
	},
	{
		garage_id: 10,
		part_id: 154,
		quantity: 131,
	},
	{
		garage_id: 10,
		part_id: 155,
		quantity: 245,
	},
	{
		garage_id: 10,
		part_id: 156,
		quantity: 75,
	},
	{
		garage_id: 10,
		part_id: 157,
		quantity: 407,
	},
	{
		garage_id: 10,
		part_id: 158,
		quantity: 316,
	},
	{
		garage_id: 10,
		part_id: 159,
		quantity: 173,
	},
	{
		garage_id: 10,
		part_id: 160,
		quantity: 496,
	},
	{
		garage_id: 10,
		part_id: 161,
		quantity: 126,
	},
	{
		garage_id: 10,
		part_id: 162,
		quantity: 318,
	},
	{
		garage_id: 10,
		part_id: 163,
		quantity: 120,
	},
	{
		garage_id: 10,
		part_id: 164,
		quantity: 285,
	},
	{
		garage_id: 10,
		part_id: 165,
		quantity: 329,
	},
	{
		garage_id: 10,
		part_id: 166,
		quantity: 417,
	},
	{
		garage_id: 10,
		part_id: 167,
		quantity: 481,
	},
	{
		garage_id: 10,
		part_id: 168,
		quantity: 131,
	},
	{
		garage_id: 10,
		part_id: 169,
		quantity: 88,
	},
	{
		garage_id: 10,
		part_id: 170,
		quantity: 192,
	},
	{
		garage_id: 10,
		part_id: 171,
		quantity: 459,
	},
	{
		garage_id: 10,
		part_id: 172,
		quantity: 466,
	},
	{
		garage_id: 10,
		part_id: 173,
		quantity: 478,
	},
	{
		garage_id: 10,
		part_id: 174,
		quantity: 146,
	},
	{
		garage_id: 10,
		part_id: 175,
		quantity: 28,
	},
	{
		garage_id: 10,
		part_id: 176,
		quantity: 19,
	},
	{
		garage_id: 10,
		part_id: 177,
		quantity: 257,
	},
	{
		garage_id: 10,
		part_id: 178,
		quantity: 266,
	},
	{
		garage_id: 10,
		part_id: 179,
		quantity: 397,
	},
	{
		garage_id: 10,
		part_id: 180,
		quantity: 245,
	},
	{
		garage_id: 10,
		part_id: 181,
		quantity: 350,
	},
	{
		garage_id: 10,
		part_id: 182,
		quantity: 426,
	},
	{
		garage_id: 10,
		part_id: 183,
		quantity: 312,
	},
	{
		garage_id: 10,
		part_id: 184,
		quantity: 41,
	},
	{
		garage_id: 10,
		part_id: 185,
		quantity: 228,
	},
	{
		garage_id: 10,
		part_id: 186,
		quantity: 153,
	},
	{
		garage_id: 10,
		part_id: 187,
		quantity: 94,
	},
	{
		garage_id: 10,
		part_id: 188,
		quantity: 471,
	},
	{
		garage_id: 10,
		part_id: 189,
		quantity: 1,
	},
	{
		garage_id: 10,
		part_id: 190,
		quantity: 240,
	},
	{
		garage_id: 10,
		part_id: 191,
		quantity: 187,
	},
	{
		garage_id: 10,
		part_id: 192,
		quantity: 91,
	},
	{
		garage_id: 10,
		part_id: 193,
		quantity: 418,
	},
	{
		garage_id: 10,
		part_id: 194,
		quantity: 167,
	},
	{
		garage_id: 10,
		part_id: 195,
		quantity: 192,
	},
	{
		garage_id: 10,
		part_id: 196,
		quantity: 368,
	},
	{
		garage_id: 10,
		part_id: 197,
		quantity: 389,
	},
	{
		garage_id: 10,
		part_id: 198,
		quantity: 127,
	},
	{
		garage_id: 10,
		part_id: 199,
		quantity: 463,
	},
	{
		garage_id: 10,
		part_id: 200,
		quantity: 202,
	},
	{
		garage_id: 10,
		part_id: 201,
		quantity: 101,
	},
	{
		garage_id: 10,
		part_id: 202,
		quantity: 362,
	},
	{
		garage_id: 10,
		part_id: 203,
		quantity: 271,
	},
	{
		garage_id: 10,
		part_id: 204,
		quantity: 342,
	},
	{
		garage_id: 10,
		part_id: 205,
		quantity: 5,
	},
	{
		garage_id: 10,
		part_id: 206,
		quantity: 498,
	},
	{
		garage_id: 10,
		part_id: 207,
		quantity: 446,
	},
	{
		garage_id: 10,
		part_id: 208,
		quantity: 98,
	},
	{
		garage_id: 10,
		part_id: 209,
		quantity: 482,
	},
	{
		garage_id: 10,
		part_id: 210,
		quantity: 34,
	},
	{
		garage_id: 10,
		part_id: 211,
		quantity: 9,
	},
	{
		garage_id: 10,
		part_id: 212,
		quantity: 215,
	},
	{
		garage_id: 10,
		part_id: 213,
		quantity: 95,
	},
	{
		garage_id: 10,
		part_id: 214,
		quantity: 422,
	},
	{
		garage_id: 10,
		part_id: 215,
		quantity: 46,
	},
	{
		garage_id: 10,
		part_id: 216,
		quantity: 112,
	},
	{
		garage_id: 10,
		part_id: 217,
		quantity: 356,
	},
	{
		garage_id: 10,
		part_id: 218,
		quantity: 354,
	},
	{
		garage_id: 10,
		part_id: 219,
		quantity: 484,
	},
	{
		garage_id: 10,
		part_id: 220,
		quantity: 320,
	},
	{
		garage_id: 10,
		part_id: 221,
		quantity: 28,
	},
	{
		garage_id: 10,
		part_id: 222,
		quantity: 482,
	},
	{
		garage_id: 10,
		part_id: 223,
		quantity: 290,
	},
	{
		garage_id: 10,
		part_id: 224,
		quantity: 311,
	},
	{
		garage_id: 10,
		part_id: 225,
		quantity: 424,
	},
	{
		garage_id: 10,
		part_id: 226,
		quantity: 423,
	},
	{
		garage_id: 10,
		part_id: 227,
		quantity: 326,
	},
	{
		garage_id: 10,
		part_id: 228,
		quantity: 406,
	},
	{
		garage_id: 10,
		part_id: 229,
		quantity: 474,
	},
	{
		garage_id: 10,
		part_id: 230,
		quantity: 212,
	},
	{
		garage_id: 10,
		part_id: 231,
		quantity: 200,
	},
	{
		garage_id: 10,
		part_id: 232,
		quantity: 83,
	},
	{
		garage_id: 10,
		part_id: 233,
		quantity: 84,
	},
	{
		garage_id: 10,
		part_id: 234,
		quantity: 466,
	},
	{
		garage_id: 10,
		part_id: 235,
		quantity: 302,
	},
	{
		garage_id: 10,
		part_id: 236,
		quantity: 2,
	},
	{
		garage_id: 10,
		part_id: 237,
		quantity: 212,
	},
	{
		garage_id: 10,
		part_id: 238,
		quantity: 118,
	},
	{
		garage_id: 10,
		part_id: 239,
		quantity: 342,
	},
	{
		garage_id: 10,
		part_id: 240,
		quantity: 153,
	},
	{
		garage_id: 10,
		part_id: 241,
		quantity: 390,
	},
	{
		garage_id: 10,
		part_id: 242,
		quantity: 246,
	},
	{
		garage_id: 10,
		part_id: 243,
		quantity: 358,
	},
	{
		garage_id: 10,
		part_id: 244,
		quantity: 2,
	},
	{
		garage_id: 10,
		part_id: 245,
		quantity: 60,
	},
	{
		garage_id: 10,
		part_id: 246,
		quantity: 339,
	},
	{
		garage_id: 10,
		part_id: 247,
		quantity: 375,
	},
	{
		garage_id: 10,
		part_id: 248,
		quantity: 390,
	},
	{
		garage_id: 10,
		part_id: 249,
		quantity: 122,
	},
	{
		garage_id: 10,
		part_id: 250,
		quantity: 205,
	},
	{
		garage_id: 10,
		part_id: 251,
		quantity: 119,
	},
	{
		garage_id: 10,
		part_id: 252,
		quantity: 398,
	},
	{
		garage_id: 10,
		part_id: 253,
		quantity: 179,
	},
	{
		garage_id: 10,
		part_id: 254,
		quantity: 80,
	},
	{
		garage_id: 10,
		part_id: 255,
		quantity: 253,
	},
	{
		garage_id: 10,
		part_id: 256,
		quantity: 341,
	},
	{
		garage_id: 10,
		part_id: 257,
		quantity: 446,
	},
	{
		garage_id: 10,
		part_id: 258,
		quantity: 471,
	},
	{
		garage_id: 10,
		part_id: 259,
		quantity: 243,
	},
	{
		garage_id: 10,
		part_id: 260,
		quantity: 400,
	},
	{
		garage_id: 10,
		part_id: 261,
		quantity: 211,
	},
	{
		garage_id: 10,
		part_id: 262,
		quantity: 141,
	},
	{
		garage_id: 10,
		part_id: 263,
		quantity: 94,
	},
	{
		garage_id: 10,
		part_id: 264,
		quantity: 408,
	},
	{
		garage_id: 10,
		part_id: 265,
		quantity: 222,
	},
	{
		garage_id: 10,
		part_id: 266,
		quantity: 140,
	},
	{
		garage_id: 10,
		part_id: 267,
		quantity: 397,
	},
	{
		garage_id: 10,
		part_id: 268,
		quantity: 304,
	},
	{
		garage_id: 10,
		part_id: 269,
		quantity: 101,
	},
	{
		garage_id: 10,
		part_id: 270,
		quantity: 291,
	},
	{
		garage_id: 10,
		part_id: 271,
		quantity: 29,
	},
	{
		garage_id: 10,
		part_id: 272,
		quantity: 329,
	},
	{
		garage_id: 10,
		part_id: 273,
		quantity: 225,
	},
	{
		garage_id: 10,
		part_id: 274,
		quantity: 33,
	},
	{
		garage_id: 10,
		part_id: 275,
		quantity: 476,
	},
	{
		garage_id: 10,
		part_id: 276,
		quantity: 115,
	},
	{
		garage_id: 10,
		part_id: 277,
		quantity: 66,
	},
	{
		garage_id: 10,
		part_id: 278,
		quantity: 308,
	},
	{
		garage_id: 10,
		part_id: 279,
		quantity: 368,
	},
	{
		garage_id: 10,
		part_id: 280,
		quantity: 419,
	},
	{
		garage_id: 10,
		part_id: 281,
		quantity: 455,
	},
	{
		garage_id: 10,
		part_id: 282,
		quantity: 345,
	},
	{
		garage_id: 10,
		part_id: 283,
		quantity: 469,
	},
	{
		garage_id: 10,
		part_id: 284,
		quantity: 499,
	},
	{
		garage_id: 10,
		part_id: 285,
		quantity: 246,
	},
	{
		garage_id: 10,
		part_id: 286,
		quantity: 173,
	},
	{
		garage_id: 10,
		part_id: 287,
		quantity: 486,
	},
	{
		garage_id: 10,
		part_id: 288,
		quantity: 364,
	},
	{
		garage_id: 10,
		part_id: 289,
		quantity: 122,
	},
	{
		garage_id: 10,
		part_id: 290,
		quantity: 480,
	},
	{
		garage_id: 10,
		part_id: 291,
		quantity: 375,
	},
	{
		garage_id: 10,
		part_id: 292,
		quantity: 381,
	},
	{
		garage_id: 10,
		part_id: 293,
		quantity: 117,
	},
	{
		garage_id: 10,
		part_id: 294,
		quantity: 173,
	},
	{
		garage_id: 10,
		part_id: 295,
		quantity: 248,
	},
	{
		garage_id: 10,
		part_id: 296,
		quantity: 107,
	},
	{
		garage_id: 10,
		part_id: 297,
		quantity: 347,
	},
	{
		garage_id: 10,
		part_id: 298,
		quantity: 194,
	},
	{
		garage_id: 10,
		part_id: 299,
		quantity: 427,
	},
	{
		garage_id: 10,
		part_id: 300,
		quantity: 61,
	},
	{
		garage_id: 10,
		part_id: 301,
		quantity: 24,
	},
	{
		garage_id: 10,
		part_id: 302,
		quantity: 191,
	},
	{
		garage_id: 10,
		part_id: 303,
		quantity: 389,
	},
	{
		garage_id: 10,
		part_id: 304,
		quantity: 445,
	},
	{
		garage_id: 10,
		part_id: 305,
		quantity: 445,
	},
	{
		garage_id: 10,
		part_id: 306,
		quantity: 1,
	},
	{
		garage_id: 10,
		part_id: 307,
		quantity: 274,
	},
	{
		garage_id: 10,
		part_id: 308,
		quantity: 412,
	},
	{
		garage_id: 10,
		part_id: 309,
		quantity: 153,
	},
	{
		garage_id: 10,
		part_id: 310,
		quantity: 491,
	},
	{
		garage_id: 10,
		part_id: 311,
		quantity: 359,
	},
	{
		garage_id: 10,
		part_id: 312,
		quantity: 132,
	},
	{
		garage_id: 10,
		part_id: 313,
		quantity: 24,
	},
	{
		garage_id: 10,
		part_id: 314,
		quantity: 194,
	},
	{
		garage_id: 10,
		part_id: 315,
		quantity: 266,
	},
	{
		garage_id: 10,
		part_id: 316,
		quantity: 157,
	},
	{
		garage_id: 10,
		part_id: 317,
		quantity: 156,
	},
	{
		garage_id: 10,
		part_id: 318,
		quantity: 106,
	},
	{
		garage_id: 10,
		part_id: 319,
		quantity: 179,
	},
	{
		garage_id: 10,
		part_id: 320,
		quantity: 45,
	},
	{
		garage_id: 10,
		part_id: 321,
		quantity: 19,
	},
	{
		garage_id: 10,
		part_id: 322,
		quantity: 285,
	},
	{
		garage_id: 10,
		part_id: 323,
		quantity: 213,
	},
	{
		garage_id: 10,
		part_id: 324,
		quantity: 351,
	},
	{
		garage_id: 10,
		part_id: 325,
		quantity: 283,
	},
	{
		garage_id: 10,
		part_id: 326,
		quantity: 124,
	},
	{
		garage_id: 10,
		part_id: 327,
		quantity: 341,
	},
	{
		garage_id: 10,
		part_id: 328,
		quantity: 275,
	},
	{
		garage_id: 10,
		part_id: 329,
		quantity: 490,
	},
	{
		garage_id: 10,
		part_id: 330,
		quantity: 179,
	},
	{
		garage_id: 10,
		part_id: 331,
		quantity: 281,
	},
	{
		garage_id: 10,
		part_id: 332,
		quantity: 403,
	},
	{
		garage_id: 10,
		part_id: 333,
		quantity: 188,
	},
	{
		garage_id: 10,
		part_id: 334,
		quantity: 51,
	},
	{
		garage_id: 10,
		part_id: 335,
		quantity: 80,
	},
	{
		garage_id: 10,
		part_id: 336,
		quantity: 328,
	},
	{
		garage_id: 10,
		part_id: 337,
		quantity: 309,
	},
	{
		garage_id: 10,
		part_id: 338,
		quantity: 313,
	},
	{
		garage_id: 10,
		part_id: 339,
		quantity: 11,
	},
	{
		garage_id: 10,
		part_id: 340,
		quantity: 212,
	},
	{
		garage_id: 10,
		part_id: 341,
		quantity: 180,
	},
	{
		garage_id: 10,
		part_id: 342,
		quantity: 23,
	},
	{
		garage_id: 10,
		part_id: 343,
		quantity: 22,
	},
	{
		garage_id: 10,
		part_id: 344,
		quantity: 45,
	},
	{
		garage_id: 10,
		part_id: 345,
		quantity: 171,
	},
	{
		garage_id: 10,
		part_id: 346,
		quantity: 422,
	},
	{
		garage_id: 10,
		part_id: 347,
		quantity: 326,
	},
	{
		garage_id: 10,
		part_id: 348,
		quantity: 310,
	},
	{
		garage_id: 10,
		part_id: 349,
		quantity: 170,
	},
	{
		garage_id: 10,
		part_id: 350,
		quantity: 284,
	},
	{
		garage_id: 10,
		part_id: 351,
		quantity: 309,
	},
	{
		garage_id: 10,
		part_id: 352,
		quantity: 84,
	},
	{
		garage_id: 10,
		part_id: 353,
		quantity: 321,
	},
	{
		garage_id: 10,
		part_id: 354,
		quantity: 282,
	},
	{
		garage_id: 10,
		part_id: 355,
		quantity: 268,
	},
	{
		garage_id: 10,
		part_id: 356,
		quantity: 222,
	},
	{
		garage_id: 10,
		part_id: 357,
		quantity: 222,
	},
	{
		garage_id: 10,
		part_id: 358,
		quantity: 354,
	},
	{
		garage_id: 10,
		part_id: 359,
		quantity: 427,
	},
	{
		garage_id: 10,
		part_id: 360,
		quantity: 30,
	},
	{
		garage_id: 10,
		part_id: 361,
		quantity: 306,
	},
	{
		garage_id: 10,
		part_id: 362,
		quantity: 175,
	},
	{
		garage_id: 10,
		part_id: 363,
		quantity: 475,
	},
	{
		garage_id: 10,
		part_id: 364,
		quantity: 192,
	},
	{
		garage_id: 10,
		part_id: 365,
		quantity: 88,
	},
	{
		garage_id: 10,
		part_id: 366,
		quantity: 180,
	},
	{
		garage_id: 10,
		part_id: 367,
		quantity: 291,
	},
	{
		garage_id: 10,
		part_id: 368,
		quantity: 477,
	},
	{
		garage_id: 10,
		part_id: 369,
		quantity: 264,
	},
	{
		garage_id: 10,
		part_id: 370,
		quantity: 109,
	},
	{
		garage_id: 10,
		part_id: 371,
		quantity: 335,
	},
	{
		garage_id: 10,
		part_id: 372,
		quantity: 147,
	},
	{
		garage_id: 10,
		part_id: 373,
		quantity: 378,
	},
	{
		garage_id: 10,
		part_id: 374,
		quantity: 292,
	},
	{
		garage_id: 10,
		part_id: 375,
		quantity: 393,
	},
	{
		garage_id: 10,
		part_id: 376,
		quantity: 316,
	},
	{
		garage_id: 10,
		part_id: 377,
		quantity: 346,
	},
	{
		garage_id: 10,
		part_id: 378,
		quantity: 186,
	},
	{
		garage_id: 10,
		part_id: 379,
		quantity: 204,
	},
	{
		garage_id: 10,
		part_id: 380,
		quantity: 214,
	},
	{
		garage_id: 10,
		part_id: 381,
		quantity: 449,
	},
	{
		garage_id: 10,
		part_id: 382,
		quantity: 211,
	},
	{
		garage_id: 10,
		part_id: 383,
		quantity: 110,
	},
	{
		garage_id: 10,
		part_id: 384,
		quantity: 246,
	},
	{
		garage_id: 10,
		part_id: 385,
		quantity: 88,
	},
	{
		garage_id: 10,
		part_id: 386,
		quantity: 144,
	},
	{
		garage_id: 10,
		part_id: 387,
		quantity: 118,
	},
	{
		garage_id: 10,
		part_id: 388,
		quantity: 491,
	},
	{
		garage_id: 10,
		part_id: 389,
		quantity: 276,
	},
	{
		garage_id: 10,
		part_id: 390,
		quantity: 24,
	},
	{
		garage_id: 10,
		part_id: 391,
		quantity: 370,
	},
	{
		garage_id: 10,
		part_id: 392,
		quantity: 164,
	},
	{
		garage_id: 10,
		part_id: 393,
		quantity: 336,
	},
	{
		garage_id: 10,
		part_id: 394,
		quantity: 491,
	},
	{
		garage_id: 10,
		part_id: 395,
		quantity: 340,
	},
	{
		garage_id: 10,
		part_id: 396,
		quantity: 256,
	},
	{
		garage_id: 10,
		part_id: 397,
		quantity: 17,
	},
	{
		garage_id: 10,
		part_id: 398,
		quantity: 329,
	},
	{
		garage_id: 10,
		part_id: 399,
		quantity: 463,
	},
	{
		garage_id: 11,
		part_id: 0,
		quantity: 98,
	},
	{
		garage_id: 11,
		part_id: 1,
		quantity: 228,
	},
	{
		garage_id: 11,
		part_id: 2,
		quantity: 211,
	},
	{
		garage_id: 11,
		part_id: 3,
		quantity: 222,
	},
	{
		garage_id: 11,
		part_id: 4,
		quantity: 188,
	},
	{
		garage_id: 11,
		part_id: 5,
		quantity: 326,
	},
	{
		garage_id: 11,
		part_id: 6,
		quantity: 477,
	},
	{
		garage_id: 11,
		part_id: 7,
		quantity: 13,
	},
	{
		garage_id: 11,
		part_id: 8,
		quantity: 430,
	},
	{
		garage_id: 11,
		part_id: 9,
		quantity: 247,
	},
	{
		garage_id: 11,
		part_id: 10,
		quantity: 261,
	},
	{
		garage_id: 11,
		part_id: 11,
		quantity: 119,
	},
	{
		garage_id: 11,
		part_id: 12,
		quantity: 347,
	},
	{
		garage_id: 11,
		part_id: 13,
		quantity: 156,
	},
	{
		garage_id: 11,
		part_id: 14,
		quantity: 465,
	},
	{
		garage_id: 11,
		part_id: 15,
		quantity: 124,
	},
	{
		garage_id: 11,
		part_id: 16,
		quantity: 130,
	},
	{
		garage_id: 11,
		part_id: 17,
		quantity: 285,
	},
	{
		garage_id: 11,
		part_id: 18,
		quantity: 101,
	},
	{
		garage_id: 11,
		part_id: 19,
		quantity: 267,
	},
	{
		garage_id: 11,
		part_id: 20,
		quantity: 382,
	},
	{
		garage_id: 11,
		part_id: 21,
		quantity: 107,
	},
	{
		garage_id: 11,
		part_id: 22,
		quantity: 143,
	},
	{
		garage_id: 11,
		part_id: 23,
		quantity: 382,
	},
	{
		garage_id: 11,
		part_id: 24,
		quantity: 424,
	},
	{
		garage_id: 11,
		part_id: 25,
		quantity: 364,
	},
	{
		garage_id: 11,
		part_id: 26,
		quantity: 269,
	},
	{
		garage_id: 11,
		part_id: 27,
		quantity: 110,
	},
	{
		garage_id: 11,
		part_id: 28,
		quantity: 0,
	},
	{
		garage_id: 11,
		part_id: 29,
		quantity: 114,
	},
	{
		garage_id: 11,
		part_id: 30,
		quantity: 226,
	},
	{
		garage_id: 11,
		part_id: 31,
		quantity: 54,
	},
	{
		garage_id: 11,
		part_id: 32,
		quantity: 86,
	},
	{
		garage_id: 11,
		part_id: 33,
		quantity: 171,
	},
	{
		garage_id: 11,
		part_id: 34,
		quantity: 403,
	},
	{
		garage_id: 11,
		part_id: 35,
		quantity: 353,
	},
	{
		garage_id: 11,
		part_id: 36,
		quantity: 286,
	},
	{
		garage_id: 11,
		part_id: 37,
		quantity: 387,
	},
	{
		garage_id: 11,
		part_id: 38,
		quantity: 380,
	},
	{
		garage_id: 11,
		part_id: 39,
		quantity: 146,
	},
	{
		garage_id: 11,
		part_id: 40,
		quantity: 354,
	},
	{
		garage_id: 11,
		part_id: 41,
		quantity: 182,
	},
	{
		garage_id: 11,
		part_id: 42,
		quantity: 430,
	},
	{
		garage_id: 11,
		part_id: 43,
		quantity: 379,
	},
	{
		garage_id: 11,
		part_id: 44,
		quantity: 430,
	},
	{
		garage_id: 11,
		part_id: 45,
		quantity: 150,
	},
	{
		garage_id: 11,
		part_id: 46,
		quantity: 371,
	},
	{
		garage_id: 11,
		part_id: 47,
		quantity: 122,
	},
	{
		garage_id: 11,
		part_id: 48,
		quantity: 37,
	},
	{
		garage_id: 11,
		part_id: 49,
		quantity: 396,
	},
	{
		garage_id: 11,
		part_id: 50,
		quantity: 472,
	},
	{
		garage_id: 11,
		part_id: 51,
		quantity: 359,
	},
	{
		garage_id: 11,
		part_id: 52,
		quantity: 485,
	},
	{
		garage_id: 11,
		part_id: 53,
		quantity: 111,
	},
	{
		garage_id: 11,
		part_id: 54,
		quantity: 249,
	},
	{
		garage_id: 11,
		part_id: 55,
		quantity: 394,
	},
	{
		garage_id: 11,
		part_id: 56,
		quantity: 456,
	},
	{
		garage_id: 11,
		part_id: 57,
		quantity: 142,
	},
	{
		garage_id: 11,
		part_id: 58,
		quantity: 95,
	},
	{
		garage_id: 11,
		part_id: 59,
		quantity: 475,
	},
	{
		garage_id: 11,
		part_id: 60,
		quantity: 492,
	},
	{
		garage_id: 11,
		part_id: 61,
		quantity: 407,
	},
	{
		garage_id: 11,
		part_id: 62,
		quantity: 30,
	},
	{
		garage_id: 11,
		part_id: 63,
		quantity: 64,
	},
	{
		garage_id: 11,
		part_id: 64,
		quantity: 341,
	},
	{
		garage_id: 11,
		part_id: 65,
		quantity: 432,
	},
	{
		garage_id: 11,
		part_id: 66,
		quantity: 392,
	},
	{
		garage_id: 11,
		part_id: 67,
		quantity: 331,
	},
	{
		garage_id: 11,
		part_id: 68,
		quantity: 398,
	},
	{
		garage_id: 11,
		part_id: 69,
		quantity: 277,
	},
	{
		garage_id: 11,
		part_id: 70,
		quantity: 67,
	},
	{
		garage_id: 11,
		part_id: 71,
		quantity: 262,
	},
	{
		garage_id: 11,
		part_id: 72,
		quantity: 41,
	},
	{
		garage_id: 11,
		part_id: 73,
		quantity: 442,
	},
	{
		garage_id: 11,
		part_id: 74,
		quantity: 320,
	},
	{
		garage_id: 11,
		part_id: 75,
		quantity: 464,
	},
	{
		garage_id: 11,
		part_id: 76,
		quantity: 370,
	},
	{
		garage_id: 11,
		part_id: 77,
		quantity: 19,
	},
	{
		garage_id: 11,
		part_id: 78,
		quantity: 262,
	},
	{
		garage_id: 11,
		part_id: 79,
		quantity: 350,
	},
	{
		garage_id: 11,
		part_id: 80,
		quantity: 282,
	},
	{
		garage_id: 11,
		part_id: 81,
		quantity: 459,
	},
	{
		garage_id: 11,
		part_id: 82,
		quantity: 324,
	},
	{
		garage_id: 11,
		part_id: 83,
		quantity: 385,
	},
	{
		garage_id: 11,
		part_id: 84,
		quantity: 402,
	},
	{
		garage_id: 11,
		part_id: 85,
		quantity: 156,
	},
	{
		garage_id: 11,
		part_id: 86,
		quantity: 121,
	},
	{
		garage_id: 11,
		part_id: 87,
		quantity: 440,
	},
	{
		garage_id: 11,
		part_id: 88,
		quantity: 423,
	},
	{
		garage_id: 11,
		part_id: 89,
		quantity: 275,
	},
	{
		garage_id: 11,
		part_id: 90,
		quantity: 124,
	},
	{
		garage_id: 11,
		part_id: 91,
		quantity: 89,
	},
	{
		garage_id: 11,
		part_id: 92,
		quantity: 364,
	},
	{
		garage_id: 11,
		part_id: 93,
		quantity: 350,
	},
	{
		garage_id: 11,
		part_id: 94,
		quantity: 320,
	},
	{
		garage_id: 11,
		part_id: 95,
		quantity: 46,
	},
	{
		garage_id: 11,
		part_id: 96,
		quantity: 394,
	},
	{
		garage_id: 11,
		part_id: 97,
		quantity: 477,
	},
	{
		garage_id: 11,
		part_id: 98,
		quantity: 360,
	},
	{
		garage_id: 11,
		part_id: 99,
		quantity: 97,
	},
	{
		garage_id: 11,
		part_id: 100,
		quantity: 72,
	},
	{
		garage_id: 11,
		part_id: 101,
		quantity: 40,
	},
	{
		garage_id: 11,
		part_id: 102,
		quantity: 472,
	},
	{
		garage_id: 11,
		part_id: 103,
		quantity: 125,
	},
	{
		garage_id: 11,
		part_id: 104,
		quantity: 333,
	},
	{
		garage_id: 11,
		part_id: 105,
		quantity: 47,
	},
	{
		garage_id: 11,
		part_id: 106,
		quantity: 294,
	},
	{
		garage_id: 11,
		part_id: 107,
		quantity: 483,
	},
	{
		garage_id: 11,
		part_id: 108,
		quantity: 475,
	},
	{
		garage_id: 11,
		part_id: 109,
		quantity: 35,
	},
	{
		garage_id: 11,
		part_id: 110,
		quantity: 424,
	},
	{
		garage_id: 11,
		part_id: 111,
		quantity: 11,
	},
	{
		garage_id: 11,
		part_id: 112,
		quantity: 324,
	},
	{
		garage_id: 11,
		part_id: 113,
		quantity: 497,
	},
	{
		garage_id: 11,
		part_id: 114,
		quantity: 390,
	},
	{
		garage_id: 11,
		part_id: 115,
		quantity: 239,
	},
	{
		garage_id: 11,
		part_id: 116,
		quantity: 185,
	},
	{
		garage_id: 11,
		part_id: 117,
		quantity: 95,
	},
	{
		garage_id: 11,
		part_id: 118,
		quantity: 355,
	},
	{
		garage_id: 11,
		part_id: 119,
		quantity: 469,
	},
	{
		garage_id: 11,
		part_id: 120,
		quantity: 246,
	},
	{
		garage_id: 11,
		part_id: 121,
		quantity: 286,
	},
	{
		garage_id: 11,
		part_id: 122,
		quantity: 316,
	},
	{
		garage_id: 11,
		part_id: 123,
		quantity: 384,
	},
	{
		garage_id: 11,
		part_id: 124,
		quantity: 203,
	},
	{
		garage_id: 11,
		part_id: 125,
		quantity: 75,
	},
	{
		garage_id: 11,
		part_id: 126,
		quantity: 270,
	},
	{
		garage_id: 11,
		part_id: 127,
		quantity: 276,
	},
	{
		garage_id: 11,
		part_id: 128,
		quantity: 144,
	},
	{
		garage_id: 11,
		part_id: 129,
		quantity: 496,
	},
	{
		garage_id: 11,
		part_id: 130,
		quantity: 245,
	},
	{
		garage_id: 11,
		part_id: 131,
		quantity: 258,
	},
	{
		garage_id: 11,
		part_id: 132,
		quantity: 348,
	},
	{
		garage_id: 11,
		part_id: 133,
		quantity: 62,
	},
	{
		garage_id: 11,
		part_id: 134,
		quantity: 357,
	},
	{
		garage_id: 11,
		part_id: 135,
		quantity: 433,
	},
	{
		garage_id: 11,
		part_id: 136,
		quantity: 465,
	},
	{
		garage_id: 11,
		part_id: 137,
		quantity: 173,
	},
	{
		garage_id: 11,
		part_id: 138,
		quantity: 474,
	},
	{
		garage_id: 11,
		part_id: 139,
		quantity: 269,
	},
	{
		garage_id: 11,
		part_id: 140,
		quantity: 302,
	},
	{
		garage_id: 11,
		part_id: 141,
		quantity: 450,
	},
	{
		garage_id: 11,
		part_id: 142,
		quantity: 204,
	},
	{
		garage_id: 11,
		part_id: 143,
		quantity: 284,
	},
	{
		garage_id: 11,
		part_id: 144,
		quantity: 179,
	},
	{
		garage_id: 11,
		part_id: 145,
		quantity: 310,
	},
	{
		garage_id: 11,
		part_id: 146,
		quantity: 128,
	},
	{
		garage_id: 11,
		part_id: 147,
		quantity: 79,
	},
	{
		garage_id: 11,
		part_id: 148,
		quantity: 453,
	},
	{
		garage_id: 11,
		part_id: 149,
		quantity: 47,
	},
	{
		garage_id: 11,
		part_id: 150,
		quantity: 248,
	},
	{
		garage_id: 11,
		part_id: 151,
		quantity: 357,
	},
	{
		garage_id: 11,
		part_id: 152,
		quantity: 320,
	},
	{
		garage_id: 11,
		part_id: 153,
		quantity: 81,
	},
	{
		garage_id: 11,
		part_id: 154,
		quantity: 215,
	},
	{
		garage_id: 11,
		part_id: 155,
		quantity: 362,
	},
	{
		garage_id: 11,
		part_id: 156,
		quantity: 320,
	},
	{
		garage_id: 11,
		part_id: 157,
		quantity: 18,
	},
	{
		garage_id: 11,
		part_id: 158,
		quantity: 262,
	},
	{
		garage_id: 11,
		part_id: 159,
		quantity: 80,
	},
	{
		garage_id: 11,
		part_id: 160,
		quantity: 50,
	},
	{
		garage_id: 11,
		part_id: 161,
		quantity: 107,
	},
	{
		garage_id: 11,
		part_id: 162,
		quantity: 321,
	},
	{
		garage_id: 11,
		part_id: 163,
		quantity: 163,
	},
	{
		garage_id: 11,
		part_id: 164,
		quantity: 311,
	},
	{
		garage_id: 11,
		part_id: 165,
		quantity: 42,
	},
	{
		garage_id: 11,
		part_id: 166,
		quantity: 87,
	},
	{
		garage_id: 11,
		part_id: 167,
		quantity: 444,
	},
	{
		garage_id: 11,
		part_id: 168,
		quantity: 471,
	},
	{
		garage_id: 11,
		part_id: 169,
		quantity: 289,
	},
	{
		garage_id: 11,
		part_id: 170,
		quantity: 411,
	},
	{
		garage_id: 11,
		part_id: 171,
		quantity: 428,
	},
	{
		garage_id: 11,
		part_id: 172,
		quantity: 375,
	},
	{
		garage_id: 11,
		part_id: 173,
		quantity: 380,
	},
	{
		garage_id: 11,
		part_id: 174,
		quantity: 83,
	},
	{
		garage_id: 11,
		part_id: 175,
		quantity: 352,
	},
	{
		garage_id: 11,
		part_id: 176,
		quantity: 392,
	},
	{
		garage_id: 11,
		part_id: 177,
		quantity: 117,
	},
	{
		garage_id: 11,
		part_id: 178,
		quantity: 62,
	},
	{
		garage_id: 11,
		part_id: 179,
		quantity: 434,
	},
	{
		garage_id: 11,
		part_id: 180,
		quantity: 4,
	},
	{
		garage_id: 11,
		part_id: 181,
		quantity: 235,
	},
	{
		garage_id: 11,
		part_id: 182,
		quantity: 438,
	},
	{
		garage_id: 11,
		part_id: 183,
		quantity: 258,
	},
	{
		garage_id: 11,
		part_id: 184,
		quantity: 147,
	},
	{
		garage_id: 11,
		part_id: 185,
		quantity: 208,
	},
	{
		garage_id: 11,
		part_id: 186,
		quantity: 223,
	},
	{
		garage_id: 11,
		part_id: 187,
		quantity: 359,
	},
	{
		garage_id: 11,
		part_id: 188,
		quantity: 309,
	},
	{
		garage_id: 11,
		part_id: 189,
		quantity: 324,
	},
	{
		garage_id: 11,
		part_id: 190,
		quantity: 174,
	},
	{
		garage_id: 11,
		part_id: 191,
		quantity: 194,
	},
	{
		garage_id: 11,
		part_id: 192,
		quantity: 362,
	},
	{
		garage_id: 11,
		part_id: 193,
		quantity: 52,
	},
	{
		garage_id: 11,
		part_id: 194,
		quantity: 479,
	},
	{
		garage_id: 11,
		part_id: 195,
		quantity: 226,
	},
	{
		garage_id: 11,
		part_id: 196,
		quantity: 364,
	},
	{
		garage_id: 11,
		part_id: 197,
		quantity: 45,
	},
	{
		garage_id: 11,
		part_id: 198,
		quantity: 275,
	},
	{
		garage_id: 11,
		part_id: 199,
		quantity: 83,
	},
	{
		garage_id: 11,
		part_id: 200,
		quantity: 110,
	},
	{
		garage_id: 11,
		part_id: 201,
		quantity: 492,
	},
	{
		garage_id: 11,
		part_id: 202,
		quantity: 86,
	},
	{
		garage_id: 11,
		part_id: 203,
		quantity: 253,
	},
	{
		garage_id: 11,
		part_id: 204,
		quantity: 107,
	},
	{
		garage_id: 11,
		part_id: 205,
		quantity: 280,
	},
	{
		garage_id: 11,
		part_id: 206,
		quantity: 285,
	},
	{
		garage_id: 11,
		part_id: 207,
		quantity: 474,
	},
	{
		garage_id: 11,
		part_id: 208,
		quantity: 66,
	},
	{
		garage_id: 11,
		part_id: 209,
		quantity: 335,
	},
	{
		garage_id: 11,
		part_id: 210,
		quantity: 241,
	},
	{
		garage_id: 11,
		part_id: 211,
		quantity: 449,
	},
	{
		garage_id: 11,
		part_id: 212,
		quantity: 361,
	},
	{
		garage_id: 11,
		part_id: 213,
		quantity: 288,
	},
	{
		garage_id: 11,
		part_id: 214,
		quantity: 241,
	},
	{
		garage_id: 11,
		part_id: 215,
		quantity: 467,
	},
	{
		garage_id: 11,
		part_id: 216,
		quantity: 376,
	},
	{
		garage_id: 11,
		part_id: 217,
		quantity: 412,
	},
	{
		garage_id: 11,
		part_id: 218,
		quantity: 424,
	},
	{
		garage_id: 11,
		part_id: 219,
		quantity: 140,
	},
	{
		garage_id: 11,
		part_id: 220,
		quantity: 42,
	},
	{
		garage_id: 11,
		part_id: 221,
		quantity: 125,
	},
	{
		garage_id: 11,
		part_id: 222,
		quantity: 289,
	},
	{
		garage_id: 11,
		part_id: 223,
		quantity: 333,
	},
	{
		garage_id: 11,
		part_id: 224,
		quantity: 339,
	},
	{
		garage_id: 11,
		part_id: 225,
		quantity: 224,
	},
	{
		garage_id: 11,
		part_id: 226,
		quantity: 117,
	},
	{
		garage_id: 11,
		part_id: 227,
		quantity: 422,
	},
	{
		garage_id: 11,
		part_id: 228,
		quantity: 39,
	},
	{
		garage_id: 11,
		part_id: 229,
		quantity: 322,
	},
	{
		garage_id: 11,
		part_id: 230,
		quantity: 430,
	},
	{
		garage_id: 11,
		part_id: 231,
		quantity: 487,
	},
	{
		garage_id: 11,
		part_id: 232,
		quantity: 255,
	},
	{
		garage_id: 11,
		part_id: 233,
		quantity: 468,
	},
	{
		garage_id: 11,
		part_id: 234,
		quantity: 253,
	},
	{
		garage_id: 11,
		part_id: 235,
		quantity: 3,
	},
	{
		garage_id: 11,
		part_id: 236,
		quantity: 192,
	},
	{
		garage_id: 11,
		part_id: 237,
		quantity: 227,
	},
	{
		garage_id: 11,
		part_id: 238,
		quantity: 166,
	},
	{
		garage_id: 11,
		part_id: 239,
		quantity: 219,
	},
	{
		garage_id: 11,
		part_id: 240,
		quantity: 55,
	},
	{
		garage_id: 11,
		part_id: 241,
		quantity: 156,
	},
	{
		garage_id: 11,
		part_id: 242,
		quantity: 293,
	},
	{
		garage_id: 11,
		part_id: 243,
		quantity: 346,
	},
	{
		garage_id: 11,
		part_id: 244,
		quantity: 18,
	},
	{
		garage_id: 11,
		part_id: 245,
		quantity: 145,
	},
	{
		garage_id: 11,
		part_id: 246,
		quantity: 345,
	},
	{
		garage_id: 11,
		part_id: 247,
		quantity: 261,
	},
	{
		garage_id: 11,
		part_id: 248,
		quantity: 160,
	},
	{
		garage_id: 11,
		part_id: 249,
		quantity: 279,
	},
	{
		garage_id: 11,
		part_id: 250,
		quantity: 149,
	},
	{
		garage_id: 11,
		part_id: 251,
		quantity: 187,
	},
	{
		garage_id: 11,
		part_id: 252,
		quantity: 339,
	},
	{
		garage_id: 11,
		part_id: 253,
		quantity: 68,
	},
	{
		garage_id: 11,
		part_id: 254,
		quantity: 58,
	},
	{
		garage_id: 11,
		part_id: 255,
		quantity: 35,
	},
	{
		garage_id: 11,
		part_id: 256,
		quantity: 80,
	},
	{
		garage_id: 11,
		part_id: 257,
		quantity: 316,
	},
	{
		garage_id: 11,
		part_id: 258,
		quantity: 423,
	},
	{
		garage_id: 11,
		part_id: 259,
		quantity: 159,
	},
	{
		garage_id: 11,
		part_id: 260,
		quantity: 141,
	},
	{
		garage_id: 11,
		part_id: 261,
		quantity: 105,
	},
	{
		garage_id: 11,
		part_id: 262,
		quantity: 493,
	},
	{
		garage_id: 11,
		part_id: 263,
		quantity: 177,
	},
	{
		garage_id: 11,
		part_id: 264,
		quantity: 136,
	},
	{
		garage_id: 11,
		part_id: 265,
		quantity: 13,
	},
	{
		garage_id: 11,
		part_id: 266,
		quantity: 30,
	},
	{
		garage_id: 11,
		part_id: 267,
		quantity: 223,
	},
	{
		garage_id: 11,
		part_id: 268,
		quantity: 81,
	},
	{
		garage_id: 11,
		part_id: 269,
		quantity: 361,
	},
	{
		garage_id: 11,
		part_id: 270,
		quantity: 44,
	},
	{
		garage_id: 11,
		part_id: 271,
		quantity: 326,
	},
	{
		garage_id: 11,
		part_id: 272,
		quantity: 355,
	},
	{
		garage_id: 11,
		part_id: 273,
		quantity: 254,
	},
	{
		garage_id: 11,
		part_id: 274,
		quantity: 142,
	},
	{
		garage_id: 11,
		part_id: 275,
		quantity: 388,
	},
	{
		garage_id: 11,
		part_id: 276,
		quantity: 273,
	},
	{
		garage_id: 11,
		part_id: 277,
		quantity: 268,
	},
	{
		garage_id: 11,
		part_id: 278,
		quantity: 491,
	},
	{
		garage_id: 11,
		part_id: 279,
		quantity: 46,
	},
	{
		garage_id: 11,
		part_id: 280,
		quantity: 304,
	},
	{
		garage_id: 11,
		part_id: 281,
		quantity: 10,
	},
	{
		garage_id: 11,
		part_id: 282,
		quantity: 414,
	},
	{
		garage_id: 11,
		part_id: 283,
		quantity: 312,
	},
	{
		garage_id: 11,
		part_id: 284,
		quantity: 197,
	},
	{
		garage_id: 11,
		part_id: 285,
		quantity: 14,
	},
	{
		garage_id: 11,
		part_id: 286,
		quantity: 251,
	},
	{
		garage_id: 11,
		part_id: 287,
		quantity: 456,
	},
	{
		garage_id: 11,
		part_id: 288,
		quantity: 203,
	},
	{
		garage_id: 11,
		part_id: 289,
		quantity: 395,
	},
	{
		garage_id: 11,
		part_id: 290,
		quantity: 379,
	},
	{
		garage_id: 11,
		part_id: 291,
		quantity: 29,
	},
	{
		garage_id: 11,
		part_id: 292,
		quantity: 373,
	},
	{
		garage_id: 11,
		part_id: 293,
		quantity: 148,
	},
	{
		garage_id: 11,
		part_id: 294,
		quantity: 422,
	},
	{
		garage_id: 11,
		part_id: 295,
		quantity: 333,
	},
	{
		garage_id: 11,
		part_id: 296,
		quantity: 181,
	},
	{
		garage_id: 11,
		part_id: 297,
		quantity: 214,
	},
	{
		garage_id: 11,
		part_id: 298,
		quantity: 182,
	},
	{
		garage_id: 11,
		part_id: 299,
		quantity: 31,
	},
	{
		garage_id: 11,
		part_id: 300,
		quantity: 57,
	},
	{
		garage_id: 11,
		part_id: 301,
		quantity: 198,
	},
	{
		garage_id: 11,
		part_id: 302,
		quantity: 125,
	},
	{
		garage_id: 11,
		part_id: 303,
		quantity: 281,
	},
	{
		garage_id: 11,
		part_id: 304,
		quantity: 464,
	},
	{
		garage_id: 11,
		part_id: 305,
		quantity: 379,
	},
	{
		garage_id: 11,
		part_id: 306,
		quantity: 477,
	},
	{
		garage_id: 11,
		part_id: 307,
		quantity: 342,
	},
	{
		garage_id: 11,
		part_id: 308,
		quantity: 368,
	},
	{
		garage_id: 11,
		part_id: 309,
		quantity: 177,
	},
	{
		garage_id: 11,
		part_id: 310,
		quantity: 376,
	},
	{
		garage_id: 11,
		part_id: 311,
		quantity: 241,
	},
	{
		garage_id: 11,
		part_id: 312,
		quantity: 127,
	},
	{
		garage_id: 11,
		part_id: 313,
		quantity: 35,
	},
	{
		garage_id: 11,
		part_id: 314,
		quantity: 313,
	},
	{
		garage_id: 11,
		part_id: 315,
		quantity: 321,
	},
	{
		garage_id: 11,
		part_id: 316,
		quantity: 210,
	},
	{
		garage_id: 11,
		part_id: 317,
		quantity: 389,
	},
	{
		garage_id: 11,
		part_id: 318,
		quantity: 456,
	},
	{
		garage_id: 11,
		part_id: 319,
		quantity: 401,
	},
	{
		garage_id: 11,
		part_id: 320,
		quantity: 454,
	},
	{
		garage_id: 11,
		part_id: 321,
		quantity: 273,
	},
	{
		garage_id: 11,
		part_id: 322,
		quantity: 391,
	},
	{
		garage_id: 11,
		part_id: 323,
		quantity: 151,
	},
	{
		garage_id: 11,
		part_id: 324,
		quantity: 235,
	},
	{
		garage_id: 11,
		part_id: 325,
		quantity: 353,
	},
	{
		garage_id: 11,
		part_id: 326,
		quantity: 8,
	},
	{
		garage_id: 11,
		part_id: 327,
		quantity: 282,
	},
	{
		garage_id: 11,
		part_id: 328,
		quantity: 52,
	},
	{
		garage_id: 11,
		part_id: 329,
		quantity: 138,
	},
	{
		garage_id: 11,
		part_id: 330,
		quantity: 350,
	},
	{
		garage_id: 11,
		part_id: 331,
		quantity: 183,
	},
	{
		garage_id: 11,
		part_id: 332,
		quantity: 19,
	},
	{
		garage_id: 11,
		part_id: 333,
		quantity: 259,
	},
	{
		garage_id: 11,
		part_id: 334,
		quantity: 450,
	},
	{
		garage_id: 11,
		part_id: 335,
		quantity: 407,
	},
	{
		garage_id: 11,
		part_id: 336,
		quantity: 69,
	},
	{
		garage_id: 11,
		part_id: 337,
		quantity: 341,
	},
	{
		garage_id: 11,
		part_id: 338,
		quantity: 111,
	},
	{
		garage_id: 11,
		part_id: 339,
		quantity: 498,
	},
	{
		garage_id: 11,
		part_id: 340,
		quantity: 228,
	},
	{
		garage_id: 11,
		part_id: 341,
		quantity: 365,
	},
	{
		garage_id: 11,
		part_id: 342,
		quantity: 277,
	},
	{
		garage_id: 11,
		part_id: 343,
		quantity: 122,
	},
	{
		garage_id: 11,
		part_id: 344,
		quantity: 257,
	},
	{
		garage_id: 11,
		part_id: 345,
		quantity: 185,
	},
	{
		garage_id: 11,
		part_id: 346,
		quantity: 393,
	},
	{
		garage_id: 11,
		part_id: 347,
		quantity: 85,
	},
	{
		garage_id: 11,
		part_id: 348,
		quantity: 400,
	},
	{
		garage_id: 11,
		part_id: 349,
		quantity: 443,
	},
	{
		garage_id: 11,
		part_id: 350,
		quantity: 193,
	},
	{
		garage_id: 11,
		part_id: 351,
		quantity: 411,
	},
	{
		garage_id: 11,
		part_id: 352,
		quantity: 104,
	},
	{
		garage_id: 11,
		part_id: 353,
		quantity: 355,
	},
	{
		garage_id: 11,
		part_id: 354,
		quantity: 185,
	},
	{
		garage_id: 11,
		part_id: 355,
		quantity: 358,
	},
	{
		garage_id: 11,
		part_id: 356,
		quantity: 101,
	},
	{
		garage_id: 11,
		part_id: 357,
		quantity: 82,
	},
	{
		garage_id: 11,
		part_id: 358,
		quantity: 176,
	},
	{
		garage_id: 11,
		part_id: 359,
		quantity: 222,
	},
	{
		garage_id: 11,
		part_id: 360,
		quantity: 429,
	},
	{
		garage_id: 11,
		part_id: 361,
		quantity: 479,
	},
	{
		garage_id: 11,
		part_id: 362,
		quantity: 449,
	},
	{
		garage_id: 11,
		part_id: 363,
		quantity: 175,
	},
	{
		garage_id: 11,
		part_id: 364,
		quantity: 277,
	},
	{
		garage_id: 11,
		part_id: 365,
		quantity: 398,
	},
	{
		garage_id: 11,
		part_id: 366,
		quantity: 431,
	},
	{
		garage_id: 11,
		part_id: 367,
		quantity: 289,
	},
	{
		garage_id: 11,
		part_id: 368,
		quantity: 295,
	},
	{
		garage_id: 11,
		part_id: 369,
		quantity: 109,
	},
	{
		garage_id: 11,
		part_id: 370,
		quantity: 403,
	},
	{
		garage_id: 11,
		part_id: 371,
		quantity: 397,
	},
	{
		garage_id: 11,
		part_id: 372,
		quantity: 346,
	},
	{
		garage_id: 11,
		part_id: 373,
		quantity: 60,
	},
	{
		garage_id: 11,
		part_id: 374,
		quantity: 224,
	},
	{
		garage_id: 11,
		part_id: 375,
		quantity: 482,
	},
	{
		garage_id: 11,
		part_id: 376,
		quantity: 107,
	},
	{
		garage_id: 11,
		part_id: 377,
		quantity: 475,
	},
	{
		garage_id: 11,
		part_id: 378,
		quantity: 413,
	},
	{
		garage_id: 11,
		part_id: 379,
		quantity: 16,
	},
	{
		garage_id: 11,
		part_id: 380,
		quantity: 303,
	},
	{
		garage_id: 11,
		part_id: 381,
		quantity: 2,
	},
	{
		garage_id: 11,
		part_id: 382,
		quantity: 38,
	},
	{
		garage_id: 11,
		part_id: 383,
		quantity: 362,
	},
	{
		garage_id: 11,
		part_id: 384,
		quantity: 319,
	},
	{
		garage_id: 11,
		part_id: 385,
		quantity: 381,
	},
	{
		garage_id: 11,
		part_id: 386,
		quantity: 37,
	},
	{
		garage_id: 11,
		part_id: 387,
		quantity: 114,
	},
	{
		garage_id: 11,
		part_id: 388,
		quantity: 424,
	},
	{
		garage_id: 11,
		part_id: 389,
		quantity: 154,
	},
	{
		garage_id: 11,
		part_id: 390,
		quantity: 420,
	},
	{
		garage_id: 11,
		part_id: 391,
		quantity: 222,
	},
	{
		garage_id: 11,
		part_id: 392,
		quantity: 277,
	},
	{
		garage_id: 11,
		part_id: 393,
		quantity: 346,
	},
	{
		garage_id: 11,
		part_id: 394,
		quantity: 446,
	},
	{
		garage_id: 11,
		part_id: 395,
		quantity: 5,
	},
	{
		garage_id: 11,
		part_id: 396,
		quantity: 26,
	},
	{
		garage_id: 11,
		part_id: 397,
		quantity: 348,
	},
	{
		garage_id: 11,
		part_id: 398,
		quantity: 117,
	},
	{
		garage_id: 11,
		part_id: 399,
		quantity: 211,
	},
	{
		garage_id: 12,
		part_id: 0,
		quantity: 319,
	},
	{
		garage_id: 12,
		part_id: 1,
		quantity: 488,
	},
	{
		garage_id: 12,
		part_id: 2,
		quantity: 46,
	},
	{
		garage_id: 12,
		part_id: 3,
		quantity: 186,
	},
	{
		garage_id: 12,
		part_id: 4,
		quantity: 174,
	},
	{
		garage_id: 12,
		part_id: 5,
		quantity: 140,
	},
	{
		garage_id: 12,
		part_id: 6,
		quantity: 418,
	},
	{
		garage_id: 12,
		part_id: 7,
		quantity: 75,
	},
	{
		garage_id: 12,
		part_id: 8,
		quantity: 399,
	},
	{
		garage_id: 12,
		part_id: 9,
		quantity: 219,
	},
	{
		garage_id: 12,
		part_id: 10,
		quantity: 28,
	},
	{
		garage_id: 12,
		part_id: 11,
		quantity: 80,
	},
	{
		garage_id: 12,
		part_id: 12,
		quantity: 222,
	},
	{
		garage_id: 12,
		part_id: 13,
		quantity: 251,
	},
	{
		garage_id: 12,
		part_id: 14,
		quantity: 202,
	},
	{
		garage_id: 12,
		part_id: 15,
		quantity: 320,
	},
	{
		garage_id: 12,
		part_id: 16,
		quantity: 347,
	},
	{
		garage_id: 12,
		part_id: 17,
		quantity: 34,
	},
	{
		garage_id: 12,
		part_id: 18,
		quantity: 173,
	},
	{
		garage_id: 12,
		part_id: 19,
		quantity: 44,
	},
	{
		garage_id: 12,
		part_id: 20,
		quantity: 287,
	},
	{
		garage_id: 12,
		part_id: 21,
		quantity: 338,
	},
	{
		garage_id: 12,
		part_id: 22,
		quantity: 32,
	},
	{
		garage_id: 12,
		part_id: 23,
		quantity: 299,
	},
	{
		garage_id: 12,
		part_id: 24,
		quantity: 124,
	},
	{
		garage_id: 12,
		part_id: 25,
		quantity: 126,
	},
	{
		garage_id: 12,
		part_id: 26,
		quantity: 45,
	},
	{
		garage_id: 12,
		part_id: 27,
		quantity: 340,
	},
	{
		garage_id: 12,
		part_id: 28,
		quantity: 24,
	},
	{
		garage_id: 12,
		part_id: 29,
		quantity: 139,
	},
	{
		garage_id: 12,
		part_id: 30,
		quantity: 118,
	},
	{
		garage_id: 12,
		part_id: 31,
		quantity: 411,
	},
	{
		garage_id: 12,
		part_id: 32,
		quantity: 437,
	},
	{
		garage_id: 12,
		part_id: 33,
		quantity: 128,
	},
	{
		garage_id: 12,
		part_id: 34,
		quantity: 234,
	},
	{
		garage_id: 12,
		part_id: 35,
		quantity: 141,
	},
	{
		garage_id: 12,
		part_id: 36,
		quantity: 199,
	},
	{
		garage_id: 12,
		part_id: 37,
		quantity: 167,
	},
	{
		garage_id: 12,
		part_id: 38,
		quantity: 317,
	},
	{
		garage_id: 12,
		part_id: 39,
		quantity: 45,
	},
	{
		garage_id: 12,
		part_id: 40,
		quantity: 497,
	},
	{
		garage_id: 12,
		part_id: 41,
		quantity: 443,
	},
	{
		garage_id: 12,
		part_id: 42,
		quantity: 109,
	},
	{
		garage_id: 12,
		part_id: 43,
		quantity: 47,
	},
	{
		garage_id: 12,
		part_id: 44,
		quantity: 214,
	},
	{
		garage_id: 12,
		part_id: 45,
		quantity: 412,
	},
	{
		garage_id: 12,
		part_id: 46,
		quantity: 99,
	},
	{
		garage_id: 12,
		part_id: 47,
		quantity: 260,
	},
	{
		garage_id: 12,
		part_id: 48,
		quantity: 435,
	},
	{
		garage_id: 12,
		part_id: 49,
		quantity: 47,
	},
	{
		garage_id: 12,
		part_id: 50,
		quantity: 91,
	},
	{
		garage_id: 12,
		part_id: 51,
		quantity: 436,
	},
	{
		garage_id: 12,
		part_id: 52,
		quantity: 280,
	},
	{
		garage_id: 12,
		part_id: 53,
		quantity: 338,
	},
	{
		garage_id: 12,
		part_id: 54,
		quantity: 474,
	},
	{
		garage_id: 12,
		part_id: 55,
		quantity: 277,
	},
	{
		garage_id: 12,
		part_id: 56,
		quantity: 208,
	},
	{
		garage_id: 12,
		part_id: 57,
		quantity: 492,
	},
	{
		garage_id: 12,
		part_id: 58,
		quantity: 37,
	},
	{
		garage_id: 12,
		part_id: 59,
		quantity: 398,
	},
	{
		garage_id: 12,
		part_id: 60,
		quantity: 143,
	},
	{
		garage_id: 12,
		part_id: 61,
		quantity: 416,
	},
	{
		garage_id: 12,
		part_id: 62,
		quantity: 300,
	},
	{
		garage_id: 12,
		part_id: 63,
		quantity: 274,
	},
	{
		garage_id: 12,
		part_id: 64,
		quantity: 0,
	},
	{
		garage_id: 12,
		part_id: 65,
		quantity: 299,
	},
	{
		garage_id: 12,
		part_id: 66,
		quantity: 79,
	},
	{
		garage_id: 12,
		part_id: 67,
		quantity: 155,
	},
	{
		garage_id: 12,
		part_id: 68,
		quantity: 282,
	},
	{
		garage_id: 12,
		part_id: 69,
		quantity: 307,
	},
	{
		garage_id: 12,
		part_id: 70,
		quantity: 264,
	},
	{
		garage_id: 12,
		part_id: 71,
		quantity: 172,
	},
	{
		garage_id: 12,
		part_id: 72,
		quantity: 473,
	},
	{
		garage_id: 12,
		part_id: 73,
		quantity: 450,
	},
	{
		garage_id: 12,
		part_id: 74,
		quantity: 427,
	},
	{
		garage_id: 12,
		part_id: 75,
		quantity: 417,
	},
	{
		garage_id: 12,
		part_id: 76,
		quantity: 133,
	},
	{
		garage_id: 12,
		part_id: 77,
		quantity: 181,
	},
	{
		garage_id: 12,
		part_id: 78,
		quantity: 252,
	},
	{
		garage_id: 12,
		part_id: 79,
		quantity: 408,
	},
	{
		garage_id: 12,
		part_id: 80,
		quantity: 157,
	},
	{
		garage_id: 12,
		part_id: 81,
		quantity: 393,
	},
	{
		garage_id: 12,
		part_id: 82,
		quantity: 293,
	},
	{
		garage_id: 12,
		part_id: 83,
		quantity: 302,
	},
	{
		garage_id: 12,
		part_id: 84,
		quantity: 285,
	},
	{
		garage_id: 12,
		part_id: 85,
		quantity: 319,
	},
	{
		garage_id: 12,
		part_id: 86,
		quantity: 136,
	},
	{
		garage_id: 12,
		part_id: 87,
		quantity: 235,
	},
	{
		garage_id: 12,
		part_id: 88,
		quantity: 489,
	},
	{
		garage_id: 12,
		part_id: 89,
		quantity: 381,
	},
	{
		garage_id: 12,
		part_id: 90,
		quantity: 123,
	},
	{
		garage_id: 12,
		part_id: 91,
		quantity: 281,
	},
	{
		garage_id: 12,
		part_id: 92,
		quantity: 405,
	},
	{
		garage_id: 12,
		part_id: 93,
		quantity: 257,
	},
	{
		garage_id: 12,
		part_id: 94,
		quantity: 30,
	},
	{
		garage_id: 12,
		part_id: 95,
		quantity: 339,
	},
	{
		garage_id: 12,
		part_id: 96,
		quantity: 393,
	},
	{
		garage_id: 12,
		part_id: 97,
		quantity: 93,
	},
	{
		garage_id: 12,
		part_id: 98,
		quantity: 182,
	},
	{
		garage_id: 12,
		part_id: 99,
		quantity: 155,
	},
	{
		garage_id: 12,
		part_id: 100,
		quantity: 133,
	},
	{
		garage_id: 12,
		part_id: 101,
		quantity: 138,
	},
	{
		garage_id: 12,
		part_id: 102,
		quantity: 9,
	},
	{
		garage_id: 12,
		part_id: 103,
		quantity: 276,
	},
	{
		garage_id: 12,
		part_id: 104,
		quantity: 213,
	},
	{
		garage_id: 12,
		part_id: 105,
		quantity: 318,
	},
	{
		garage_id: 12,
		part_id: 106,
		quantity: 305,
	},
	{
		garage_id: 12,
		part_id: 107,
		quantity: 46,
	},
	{
		garage_id: 12,
		part_id: 108,
		quantity: 460,
	},
	{
		garage_id: 12,
		part_id: 109,
		quantity: 308,
	},
	{
		garage_id: 12,
		part_id: 110,
		quantity: 247,
	},
	{
		garage_id: 12,
		part_id: 111,
		quantity: 402,
	},
	{
		garage_id: 12,
		part_id: 112,
		quantity: 414,
	},
	{
		garage_id: 12,
		part_id: 113,
		quantity: 224,
	},
	{
		garage_id: 12,
		part_id: 114,
		quantity: 439,
	},
	{
		garage_id: 12,
		part_id: 115,
		quantity: 367,
	},
	{
		garage_id: 12,
		part_id: 116,
		quantity: 454,
	},
	{
		garage_id: 12,
		part_id: 117,
		quantity: 427,
	},
	{
		garage_id: 12,
		part_id: 118,
		quantity: 448,
	},
	{
		garage_id: 12,
		part_id: 119,
		quantity: 178,
	},
	{
		garage_id: 12,
		part_id: 120,
		quantity: 187,
	},
	{
		garage_id: 12,
		part_id: 121,
		quantity: 408,
	},
	{
		garage_id: 12,
		part_id: 122,
		quantity: 341,
	},
	{
		garage_id: 12,
		part_id: 123,
		quantity: 462,
	},
	{
		garage_id: 12,
		part_id: 124,
		quantity: 162,
	},
	{
		garage_id: 12,
		part_id: 125,
		quantity: 193,
	},
	{
		garage_id: 12,
		part_id: 126,
		quantity: 386,
	},
	{
		garage_id: 12,
		part_id: 127,
		quantity: 246,
	},
	{
		garage_id: 12,
		part_id: 128,
		quantity: 388,
	},
	{
		garage_id: 12,
		part_id: 129,
		quantity: 243,
	},
	{
		garage_id: 12,
		part_id: 130,
		quantity: 310,
	},
	{
		garage_id: 12,
		part_id: 131,
		quantity: 129,
	},
	{
		garage_id: 12,
		part_id: 132,
		quantity: 70,
	},
	{
		garage_id: 12,
		part_id: 133,
		quantity: 15,
	},
	{
		garage_id: 12,
		part_id: 134,
		quantity: 455,
	},
	{
		garage_id: 12,
		part_id: 135,
		quantity: 137,
	},
	{
		garage_id: 12,
		part_id: 136,
		quantity: 291,
	},
	{
		garage_id: 12,
		part_id: 137,
		quantity: 479,
	},
	{
		garage_id: 12,
		part_id: 138,
		quantity: 12,
	},
	{
		garage_id: 12,
		part_id: 139,
		quantity: 299,
	},
	{
		garage_id: 12,
		part_id: 140,
		quantity: 5,
	},
	{
		garage_id: 12,
		part_id: 141,
		quantity: 420,
	},
	{
		garage_id: 12,
		part_id: 142,
		quantity: 316,
	},
	{
		garage_id: 12,
		part_id: 143,
		quantity: 189,
	},
	{
		garage_id: 12,
		part_id: 144,
		quantity: 294,
	},
	{
		garage_id: 12,
		part_id: 145,
		quantity: 148,
	},
	{
		garage_id: 12,
		part_id: 146,
		quantity: 330,
	},
	{
		garage_id: 12,
		part_id: 147,
		quantity: 148,
	},
	{
		garage_id: 12,
		part_id: 148,
		quantity: 128,
	},
	{
		garage_id: 12,
		part_id: 149,
		quantity: 156,
	},
	{
		garage_id: 12,
		part_id: 150,
		quantity: 419,
	},
	{
		garage_id: 12,
		part_id: 151,
		quantity: 474,
	},
	{
		garage_id: 12,
		part_id: 152,
		quantity: 401,
	},
	{
		garage_id: 12,
		part_id: 153,
		quantity: 429,
	},
	{
		garage_id: 12,
		part_id: 154,
		quantity: 31,
	},
	{
		garage_id: 12,
		part_id: 155,
		quantity: 184,
	},
	{
		garage_id: 12,
		part_id: 156,
		quantity: 221,
	},
	{
		garage_id: 12,
		part_id: 157,
		quantity: 190,
	},
	{
		garage_id: 12,
		part_id: 158,
		quantity: 186,
	},
	{
		garage_id: 12,
		part_id: 159,
		quantity: 348,
	},
	{
		garage_id: 12,
		part_id: 160,
		quantity: 178,
	},
	{
		garage_id: 12,
		part_id: 161,
		quantity: 392,
	},
	{
		garage_id: 12,
		part_id: 162,
		quantity: 35,
	},
	{
		garage_id: 12,
		part_id: 163,
		quantity: 315,
	},
	{
		garage_id: 12,
		part_id: 164,
		quantity: 252,
	},
	{
		garage_id: 12,
		part_id: 165,
		quantity: 458,
	},
	{
		garage_id: 12,
		part_id: 166,
		quantity: 426,
	},
	{
		garage_id: 12,
		part_id: 167,
		quantity: 57,
	},
	{
		garage_id: 12,
		part_id: 168,
		quantity: 424,
	},
	{
		garage_id: 12,
		part_id: 169,
		quantity: 251,
	},
	{
		garage_id: 12,
		part_id: 170,
		quantity: 48,
	},
	{
		garage_id: 12,
		part_id: 171,
		quantity: 275,
	},
	{
		garage_id: 12,
		part_id: 172,
		quantity: 458,
	},
	{
		garage_id: 12,
		part_id: 173,
		quantity: 391,
	},
	{
		garage_id: 12,
		part_id: 174,
		quantity: 70,
	},
	{
		garage_id: 12,
		part_id: 175,
		quantity: 116,
	},
	{
		garage_id: 12,
		part_id: 176,
		quantity: 498,
	},
	{
		garage_id: 12,
		part_id: 177,
		quantity: 377,
	},
	{
		garage_id: 12,
		part_id: 178,
		quantity: 63,
	},
	{
		garage_id: 12,
		part_id: 179,
		quantity: 99,
	},
	{
		garage_id: 12,
		part_id: 180,
		quantity: 342,
	},
	{
		garage_id: 12,
		part_id: 181,
		quantity: 10,
	},
	{
		garage_id: 12,
		part_id: 182,
		quantity: 20,
	},
	{
		garage_id: 12,
		part_id: 183,
		quantity: 251,
	},
	{
		garage_id: 12,
		part_id: 184,
		quantity: 272,
	},
	{
		garage_id: 12,
		part_id: 185,
		quantity: 443,
	},
	{
		garage_id: 12,
		part_id: 186,
		quantity: 63,
	},
	{
		garage_id: 12,
		part_id: 187,
		quantity: 377,
	},
	{
		garage_id: 12,
		part_id: 188,
		quantity: 458,
	},
	{
		garage_id: 12,
		part_id: 189,
		quantity: 186,
	},
	{
		garage_id: 12,
		part_id: 190,
		quantity: 358,
	},
	{
		garage_id: 12,
		part_id: 191,
		quantity: 71,
	},
	{
		garage_id: 12,
		part_id: 192,
		quantity: 249,
	},
	{
		garage_id: 12,
		part_id: 193,
		quantity: 227,
	},
	{
		garage_id: 12,
		part_id: 194,
		quantity: 337,
	},
	{
		garage_id: 12,
		part_id: 195,
		quantity: 278,
	},
	{
		garage_id: 12,
		part_id: 196,
		quantity: 416,
	},
	{
		garage_id: 12,
		part_id: 197,
		quantity: 498,
	},
	{
		garage_id: 12,
		part_id: 198,
		quantity: 248,
	},
	{
		garage_id: 12,
		part_id: 199,
		quantity: 399,
	},
	{
		garage_id: 12,
		part_id: 200,
		quantity: 24,
	},
	{
		garage_id: 12,
		part_id: 201,
		quantity: 86,
	},
	{
		garage_id: 12,
		part_id: 202,
		quantity: 64,
	},
	{
		garage_id: 12,
		part_id: 203,
		quantity: 194,
	},
	{
		garage_id: 12,
		part_id: 204,
		quantity: 329,
	},
	{
		garage_id: 12,
		part_id: 205,
		quantity: 371,
	},
	{
		garage_id: 12,
		part_id: 206,
		quantity: 452,
	},
	{
		garage_id: 12,
		part_id: 207,
		quantity: 376,
	},
	{
		garage_id: 12,
		part_id: 208,
		quantity: 480,
	},
	{
		garage_id: 12,
		part_id: 209,
		quantity: 271,
	},
	{
		garage_id: 12,
		part_id: 210,
		quantity: 333,
	},
	{
		garage_id: 12,
		part_id: 211,
		quantity: 181,
	},
	{
		garage_id: 12,
		part_id: 212,
		quantity: 142,
	},
	{
		garage_id: 12,
		part_id: 213,
		quantity: 29,
	},
	{
		garage_id: 12,
		part_id: 214,
		quantity: 459,
	},
	{
		garage_id: 12,
		part_id: 215,
		quantity: 66,
	},
	{
		garage_id: 12,
		part_id: 216,
		quantity: 248,
	},
	{
		garage_id: 12,
		part_id: 217,
		quantity: 241,
	},
	{
		garage_id: 12,
		part_id: 218,
		quantity: 470,
	},
	{
		garage_id: 12,
		part_id: 219,
		quantity: 66,
	},
	{
		garage_id: 12,
		part_id: 220,
		quantity: 116,
	},
	{
		garage_id: 12,
		part_id: 221,
		quantity: 445,
	},
	{
		garage_id: 12,
		part_id: 222,
		quantity: 137,
	},
	{
		garage_id: 12,
		part_id: 223,
		quantity: 260,
	},
	{
		garage_id: 12,
		part_id: 224,
		quantity: 356,
	},
	{
		garage_id: 12,
		part_id: 225,
		quantity: 313,
	},
	{
		garage_id: 12,
		part_id: 226,
		quantity: 371,
	},
	{
		garage_id: 12,
		part_id: 227,
		quantity: 31,
	},
	{
		garage_id: 12,
		part_id: 228,
		quantity: 359,
	},
	{
		garage_id: 12,
		part_id: 229,
		quantity: 72,
	},
	{
		garage_id: 12,
		part_id: 230,
		quantity: 195,
	},
	{
		garage_id: 12,
		part_id: 231,
		quantity: 340,
	},
	{
		garage_id: 12,
		part_id: 232,
		quantity: 79,
	},
	{
		garage_id: 12,
		part_id: 233,
		quantity: 54,
	},
	{
		garage_id: 12,
		part_id: 234,
		quantity: 287,
	},
	{
		garage_id: 12,
		part_id: 235,
		quantity: 37,
	},
	{
		garage_id: 12,
		part_id: 236,
		quantity: 479,
	},
	{
		garage_id: 12,
		part_id: 237,
		quantity: 307,
	},
	{
		garage_id: 12,
		part_id: 238,
		quantity: 397,
	},
	{
		garage_id: 12,
		part_id: 239,
		quantity: 285,
	},
	{
		garage_id: 12,
		part_id: 240,
		quantity: 303,
	},
	{
		garage_id: 12,
		part_id: 241,
		quantity: 80,
	},
	{
		garage_id: 12,
		part_id: 242,
		quantity: 487,
	},
	{
		garage_id: 12,
		part_id: 243,
		quantity: 490,
	},
	{
		garage_id: 12,
		part_id: 244,
		quantity: 153,
	},
	{
		garage_id: 12,
		part_id: 245,
		quantity: 477,
	},
	{
		garage_id: 12,
		part_id: 246,
		quantity: 14,
	},
	{
		garage_id: 12,
		part_id: 247,
		quantity: 333,
	},
	{
		garage_id: 12,
		part_id: 248,
		quantity: 243,
	},
	{
		garage_id: 12,
		part_id: 249,
		quantity: 252,
	},
	{
		garage_id: 12,
		part_id: 250,
		quantity: 276,
	},
	{
		garage_id: 12,
		part_id: 251,
		quantity: 216,
	},
	{
		garage_id: 12,
		part_id: 252,
		quantity: 494,
	},
	{
		garage_id: 12,
		part_id: 253,
		quantity: 167,
	},
	{
		garage_id: 12,
		part_id: 254,
		quantity: 344,
	},
	{
		garage_id: 12,
		part_id: 255,
		quantity: 108,
	},
	{
		garage_id: 12,
		part_id: 256,
		quantity: 283,
	},
	{
		garage_id: 12,
		part_id: 257,
		quantity: 402,
	},
	{
		garage_id: 12,
		part_id: 258,
		quantity: 199,
	},
	{
		garage_id: 12,
		part_id: 259,
		quantity: 328,
	},
	{
		garage_id: 12,
		part_id: 260,
		quantity: 120,
	},
	{
		garage_id: 12,
		part_id: 261,
		quantity: 440,
	},
	{
		garage_id: 12,
		part_id: 262,
		quantity: 214,
	},
	{
		garage_id: 12,
		part_id: 263,
		quantity: 158,
	},
	{
		garage_id: 12,
		part_id: 264,
		quantity: 160,
	},
	{
		garage_id: 12,
		part_id: 265,
		quantity: 273,
	},
	{
		garage_id: 12,
		part_id: 266,
		quantity: 340,
	},
	{
		garage_id: 12,
		part_id: 267,
		quantity: 414,
	},
	{
		garage_id: 12,
		part_id: 268,
		quantity: 318,
	},
	{
		garage_id: 12,
		part_id: 269,
		quantity: 160,
	},
	{
		garage_id: 12,
		part_id: 270,
		quantity: 478,
	},
	{
		garage_id: 12,
		part_id: 271,
		quantity: 436,
	},
	{
		garage_id: 12,
		part_id: 272,
		quantity: 138,
	},
	{
		garage_id: 12,
		part_id: 273,
		quantity: 461,
	},
	{
		garage_id: 12,
		part_id: 274,
		quantity: 318,
	},
	{
		garage_id: 12,
		part_id: 275,
		quantity: 403,
	},
	{
		garage_id: 12,
		part_id: 276,
		quantity: 375,
	},
	{
		garage_id: 12,
		part_id: 277,
		quantity: 380,
	},
	{
		garage_id: 12,
		part_id: 278,
		quantity: 218,
	},
	{
		garage_id: 12,
		part_id: 279,
		quantity: 242,
	},
	{
		garage_id: 12,
		part_id: 280,
		quantity: 57,
	},
	{
		garage_id: 12,
		part_id: 281,
		quantity: 487,
	},
	{
		garage_id: 12,
		part_id: 282,
		quantity: 206,
	},
	{
		garage_id: 12,
		part_id: 283,
		quantity: 9,
	},
	{
		garage_id: 12,
		part_id: 284,
		quantity: 271,
	},
	{
		garage_id: 12,
		part_id: 285,
		quantity: 319,
	},
	{
		garage_id: 12,
		part_id: 286,
		quantity: 10,
	},
	{
		garage_id: 12,
		part_id: 287,
		quantity: 207,
	},
	{
		garage_id: 12,
		part_id: 288,
		quantity: 407,
	},
	{
		garage_id: 12,
		part_id: 289,
		quantity: 141,
	},
	{
		garage_id: 12,
		part_id: 290,
		quantity: 242,
	},
	{
		garage_id: 12,
		part_id: 291,
		quantity: 417,
	},
	{
		garage_id: 12,
		part_id: 292,
		quantity: 384,
	},
	{
		garage_id: 12,
		part_id: 293,
		quantity: 76,
	},
	{
		garage_id: 12,
		part_id: 294,
		quantity: 434,
	},
	{
		garage_id: 12,
		part_id: 295,
		quantity: 154,
	},
	{
		garage_id: 12,
		part_id: 296,
		quantity: 338,
	},
	{
		garage_id: 12,
		part_id: 297,
		quantity: 464,
	},
	{
		garage_id: 12,
		part_id: 298,
		quantity: 73,
	},
	{
		garage_id: 12,
		part_id: 299,
		quantity: 65,
	},
	{
		garage_id: 12,
		part_id: 300,
		quantity: 354,
	},
	{
		garage_id: 12,
		part_id: 301,
		quantity: 362,
	},
	{
		garage_id: 12,
		part_id: 302,
		quantity: 41,
	},
	{
		garage_id: 12,
		part_id: 303,
		quantity: 386,
	},
	{
		garage_id: 12,
		part_id: 304,
		quantity: 112,
	},
	{
		garage_id: 12,
		part_id: 305,
		quantity: 391,
	},
	{
		garage_id: 12,
		part_id: 306,
		quantity: 63,
	},
	{
		garage_id: 12,
		part_id: 307,
		quantity: 465,
	},
	{
		garage_id: 12,
		part_id: 308,
		quantity: 358,
	},
	{
		garage_id: 12,
		part_id: 309,
		quantity: 162,
	},
	{
		garage_id: 12,
		part_id: 310,
		quantity: 170,
	},
	{
		garage_id: 12,
		part_id: 311,
		quantity: 14,
	},
	{
		garage_id: 12,
		part_id: 312,
		quantity: 427,
	},
	{
		garage_id: 12,
		part_id: 313,
		quantity: 353,
	},
	{
		garage_id: 12,
		part_id: 314,
		quantity: 289,
	},
	{
		garage_id: 12,
		part_id: 315,
		quantity: 24,
	},
	{
		garage_id: 12,
		part_id: 316,
		quantity: 83,
	},
	{
		garage_id: 12,
		part_id: 317,
		quantity: 96,
	},
	{
		garage_id: 12,
		part_id: 318,
		quantity: 387,
	},
	{
		garage_id: 12,
		part_id: 319,
		quantity: 0,
	},
	{
		garage_id: 12,
		part_id: 320,
		quantity: 231,
	},
	{
		garage_id: 12,
		part_id: 321,
		quantity: 481,
	},
	{
		garage_id: 12,
		part_id: 322,
		quantity: 393,
	},
	{
		garage_id: 12,
		part_id: 323,
		quantity: 379,
	},
	{
		garage_id: 12,
		part_id: 324,
		quantity: 181,
	},
	{
		garage_id: 12,
		part_id: 325,
		quantity: 468,
	},
	{
		garage_id: 12,
		part_id: 326,
		quantity: 1,
	},
	{
		garage_id: 12,
		part_id: 327,
		quantity: 409,
	},
	{
		garage_id: 12,
		part_id: 328,
		quantity: 132,
	},
	{
		garage_id: 12,
		part_id: 329,
		quantity: 353,
	},
	{
		garage_id: 12,
		part_id: 330,
		quantity: 308,
	},
	{
		garage_id: 12,
		part_id: 331,
		quantity: 57,
	},
	{
		garage_id: 12,
		part_id: 332,
		quantity: 423,
	},
	{
		garage_id: 12,
		part_id: 333,
		quantity: 211,
	},
	{
		garage_id: 12,
		part_id: 334,
		quantity: 280,
	},
	{
		garage_id: 12,
		part_id: 335,
		quantity: 148,
	},
	{
		garage_id: 12,
		part_id: 336,
		quantity: 75,
	},
	{
		garage_id: 12,
		part_id: 337,
		quantity: 219,
	},
	{
		garage_id: 12,
		part_id: 338,
		quantity: 26,
	},
	{
		garage_id: 12,
		part_id: 339,
		quantity: 128,
	},
	{
		garage_id: 12,
		part_id: 340,
		quantity: 0,
	},
	{
		garage_id: 12,
		part_id: 341,
		quantity: 272,
	},
	{
		garage_id: 12,
		part_id: 342,
		quantity: 338,
	},
	{
		garage_id: 12,
		part_id: 343,
		quantity: 315,
	},
	{
		garage_id: 12,
		part_id: 344,
		quantity: 375,
	},
	{
		garage_id: 12,
		part_id: 345,
		quantity: 314,
	},
	{
		garage_id: 12,
		part_id: 346,
		quantity: 480,
	},
	{
		garage_id: 12,
		part_id: 347,
		quantity: 323,
	},
	{
		garage_id: 12,
		part_id: 348,
		quantity: 436,
	},
	{
		garage_id: 12,
		part_id: 349,
		quantity: 237,
	},
	{
		garage_id: 12,
		part_id: 350,
		quantity: 183,
	},
	{
		garage_id: 12,
		part_id: 351,
		quantity: 120,
	},
	{
		garage_id: 12,
		part_id: 352,
		quantity: 121,
	},
	{
		garage_id: 12,
		part_id: 353,
		quantity: 435,
	},
	{
		garage_id: 12,
		part_id: 354,
		quantity: 210,
	},
	{
		garage_id: 12,
		part_id: 355,
		quantity: 176,
	},
	{
		garage_id: 12,
		part_id: 356,
		quantity: 290,
	},
	{
		garage_id: 12,
		part_id: 357,
		quantity: 284,
	},
	{
		garage_id: 12,
		part_id: 358,
		quantity: 464,
	},
	{
		garage_id: 12,
		part_id: 359,
		quantity: 315,
	},
	{
		garage_id: 12,
		part_id: 360,
		quantity: 372,
	},
	{
		garage_id: 12,
		part_id: 361,
		quantity: 206,
	},
	{
		garage_id: 12,
		part_id: 362,
		quantity: 155,
	},
	{
		garage_id: 12,
		part_id: 363,
		quantity: 354,
	},
	{
		garage_id: 12,
		part_id: 364,
		quantity: 124,
	},
	{
		garage_id: 12,
		part_id: 365,
		quantity: 83,
	},
	{
		garage_id: 12,
		part_id: 366,
		quantity: 84,
	},
	{
		garage_id: 12,
		part_id: 367,
		quantity: 395,
	},
	{
		garage_id: 12,
		part_id: 368,
		quantity: 423,
	},
	{
		garage_id: 12,
		part_id: 369,
		quantity: 152,
	},
	{
		garage_id: 12,
		part_id: 370,
		quantity: 406,
	},
	{
		garage_id: 12,
		part_id: 371,
		quantity: 289,
	},
	{
		garage_id: 12,
		part_id: 372,
		quantity: 26,
	},
	{
		garage_id: 12,
		part_id: 373,
		quantity: 463,
	},
	{
		garage_id: 12,
		part_id: 374,
		quantity: 35,
	},
	{
		garage_id: 12,
		part_id: 375,
		quantity: 461,
	},
	{
		garage_id: 12,
		part_id: 376,
		quantity: 232,
	},
	{
		garage_id: 12,
		part_id: 377,
		quantity: 421,
	},
	{
		garage_id: 12,
		part_id: 378,
		quantity: 296,
	},
	{
		garage_id: 12,
		part_id: 379,
		quantity: 107,
	},
	{
		garage_id: 12,
		part_id: 380,
		quantity: 192,
	},
	{
		garage_id: 12,
		part_id: 381,
		quantity: 112,
	},
	{
		garage_id: 12,
		part_id: 382,
		quantity: 172,
	},
	{
		garage_id: 12,
		part_id: 383,
		quantity: 393,
	},
	{
		garage_id: 12,
		part_id: 384,
		quantity: 395,
	},
	{
		garage_id: 12,
		part_id: 385,
		quantity: 153,
	},
	{
		garage_id: 12,
		part_id: 386,
		quantity: 238,
	},
	{
		garage_id: 12,
		part_id: 387,
		quantity: 21,
	},
	{
		garage_id: 12,
		part_id: 388,
		quantity: 151,
	},
	{
		garage_id: 12,
		part_id: 389,
		quantity: 30,
	},
	{
		garage_id: 12,
		part_id: 390,
		quantity: 267,
	},
	{
		garage_id: 12,
		part_id: 391,
		quantity: 27,
	},
	{
		garage_id: 12,
		part_id: 392,
		quantity: 208,
	},
	{
		garage_id: 12,
		part_id: 393,
		quantity: 121,
	},
	{
		garage_id: 12,
		part_id: 394,
		quantity: 300,
	},
	{
		garage_id: 12,
		part_id: 395,
		quantity: 419,
	},
	{
		garage_id: 12,
		part_id: 396,
		quantity: 70,
	},
	{
		garage_id: 12,
		part_id: 397,
		quantity: 339,
	},
	{
		garage_id: 12,
		part_id: 398,
		quantity: 260,
	},
	{
		garage_id: 12,
		part_id: 399,
		quantity: 85,
	},
	{
		garage_id: 13,
		part_id: 0,
		quantity: 123,
	},
	{
		garage_id: 13,
		part_id: 1,
		quantity: 272,
	},
	{
		garage_id: 13,
		part_id: 2,
		quantity: 95,
	},
	{
		garage_id: 13,
		part_id: 3,
		quantity: 413,
	},
	{
		garage_id: 13,
		part_id: 4,
		quantity: 447,
	},
	{
		garage_id: 13,
		part_id: 5,
		quantity: 206,
	},
	{
		garage_id: 13,
		part_id: 6,
		quantity: 221,
	},
	{
		garage_id: 13,
		part_id: 7,
		quantity: 395,
	},
	{
		garage_id: 13,
		part_id: 8,
		quantity: 433,
	},
	{
		garage_id: 13,
		part_id: 9,
		quantity: 142,
	},
	{
		garage_id: 13,
		part_id: 10,
		quantity: 206,
	},
	{
		garage_id: 13,
		part_id: 11,
		quantity: 80,
	},
	{
		garage_id: 13,
		part_id: 12,
		quantity: 40,
	},
	{
		garage_id: 13,
		part_id: 13,
		quantity: 413,
	},
	{
		garage_id: 13,
		part_id: 14,
		quantity: 450,
	},
	{
		garage_id: 13,
		part_id: 15,
		quantity: 94,
	},
	{
		garage_id: 13,
		part_id: 16,
		quantity: 317,
	},
	{
		garage_id: 13,
		part_id: 17,
		quantity: 402,
	},
	{
		garage_id: 13,
		part_id: 18,
		quantity: 299,
	},
	{
		garage_id: 13,
		part_id: 19,
		quantity: 322,
	},
	{
		garage_id: 13,
		part_id: 20,
		quantity: 288,
	},
	{
		garage_id: 13,
		part_id: 21,
		quantity: 467,
	},
	{
		garage_id: 13,
		part_id: 22,
		quantity: 157,
	},
	{
		garage_id: 13,
		part_id: 23,
		quantity: 419,
	},
	{
		garage_id: 13,
		part_id: 24,
		quantity: 134,
	},
	{
		garage_id: 13,
		part_id: 25,
		quantity: 482,
	},
	{
		garage_id: 13,
		part_id: 26,
		quantity: 166,
	},
	{
		garage_id: 13,
		part_id: 27,
		quantity: 27,
	},
	{
		garage_id: 13,
		part_id: 28,
		quantity: 243,
	},
	{
		garage_id: 13,
		part_id: 29,
		quantity: 383,
	},
	{
		garage_id: 13,
		part_id: 30,
		quantity: 475,
	},
	{
		garage_id: 13,
		part_id: 31,
		quantity: 285,
	},
	{
		garage_id: 13,
		part_id: 32,
		quantity: 134,
	},
	{
		garage_id: 13,
		part_id: 33,
		quantity: 338,
	},
	{
		garage_id: 13,
		part_id: 34,
		quantity: 417,
	},
	{
		garage_id: 13,
		part_id: 35,
		quantity: 409,
	},
	{
		garage_id: 13,
		part_id: 36,
		quantity: 384,
	},
	{
		garage_id: 13,
		part_id: 37,
		quantity: 304,
	},
	{
		garage_id: 13,
		part_id: 38,
		quantity: 409,
	},
	{
		garage_id: 13,
		part_id: 39,
		quantity: 445,
	},
	{
		garage_id: 13,
		part_id: 40,
		quantity: 1,
	},
	{
		garage_id: 13,
		part_id: 41,
		quantity: 68,
	},
	{
		garage_id: 13,
		part_id: 42,
		quantity: 377,
	},
	{
		garage_id: 13,
		part_id: 43,
		quantity: 460,
	},
	{
		garage_id: 13,
		part_id: 44,
		quantity: 317,
	},
	{
		garage_id: 13,
		part_id: 45,
		quantity: 207,
	},
	{
		garage_id: 13,
		part_id: 46,
		quantity: 185,
	},
	{
		garage_id: 13,
		part_id: 47,
		quantity: 310,
	},
	{
		garage_id: 13,
		part_id: 48,
		quantity: 41,
	},
	{
		garage_id: 13,
		part_id: 49,
		quantity: 246,
	},
	{
		garage_id: 13,
		part_id: 50,
		quantity: 465,
	},
	{
		garage_id: 13,
		part_id: 51,
		quantity: 215,
	},
	{
		garage_id: 13,
		part_id: 52,
		quantity: 41,
	},
	{
		garage_id: 13,
		part_id: 53,
		quantity: 389,
	},
	{
		garage_id: 13,
		part_id: 54,
		quantity: 65,
	},
	{
		garage_id: 13,
		part_id: 55,
		quantity: 49,
	},
	{
		garage_id: 13,
		part_id: 56,
		quantity: 153,
	},
	{
		garage_id: 13,
		part_id: 57,
		quantity: 108,
	},
	{
		garage_id: 13,
		part_id: 58,
		quantity: 31,
	},
	{
		garage_id: 13,
		part_id: 59,
		quantity: 2,
	},
	{
		garage_id: 13,
		part_id: 60,
		quantity: 402,
	},
	{
		garage_id: 13,
		part_id: 61,
		quantity: 81,
	},
	{
		garage_id: 13,
		part_id: 62,
		quantity: 462,
	},
	{
		garage_id: 13,
		part_id: 63,
		quantity: 257,
	},
	{
		garage_id: 13,
		part_id: 64,
		quantity: 376,
	},
	{
		garage_id: 13,
		part_id: 65,
		quantity: 439,
	},
	{
		garage_id: 13,
		part_id: 66,
		quantity: 108,
	},
	{
		garage_id: 13,
		part_id: 67,
		quantity: 375,
	},
	{
		garage_id: 13,
		part_id: 68,
		quantity: 23,
	},
	{
		garage_id: 13,
		part_id: 69,
		quantity: 196,
	},
	{
		garage_id: 13,
		part_id: 70,
		quantity: 321,
	},
	{
		garage_id: 13,
		part_id: 71,
		quantity: 197,
	},
	{
		garage_id: 13,
		part_id: 72,
		quantity: 324,
	},
	{
		garage_id: 13,
		part_id: 73,
		quantity: 265,
	},
	{
		garage_id: 13,
		part_id: 74,
		quantity: 211,
	},
	{
		garage_id: 13,
		part_id: 75,
		quantity: 355,
	},
	{
		garage_id: 13,
		part_id: 76,
		quantity: 84,
	},
	{
		garage_id: 13,
		part_id: 77,
		quantity: 353,
	},
	{
		garage_id: 13,
		part_id: 78,
		quantity: 455,
	},
	{
		garage_id: 13,
		part_id: 79,
		quantity: 120,
	},
	{
		garage_id: 13,
		part_id: 80,
		quantity: 259,
	},
	{
		garage_id: 13,
		part_id: 81,
		quantity: 451,
	},
	{
		garage_id: 13,
		part_id: 82,
		quantity: 55,
	},
	{
		garage_id: 13,
		part_id: 83,
		quantity: 67,
	},
	{
		garage_id: 13,
		part_id: 84,
		quantity: 228,
	},
	{
		garage_id: 13,
		part_id: 85,
		quantity: 236,
	},
	{
		garage_id: 13,
		part_id: 86,
		quantity: 176,
	},
	{
		garage_id: 13,
		part_id: 87,
		quantity: 274,
	},
	{
		garage_id: 13,
		part_id: 88,
		quantity: 158,
	},
	{
		garage_id: 13,
		part_id: 89,
		quantity: 110,
	},
	{
		garage_id: 13,
		part_id: 90,
		quantity: 13,
	},
	{
		garage_id: 13,
		part_id: 91,
		quantity: 474,
	},
	{
		garage_id: 13,
		part_id: 92,
		quantity: 320,
	},
	{
		garage_id: 13,
		part_id: 93,
		quantity: 266,
	},
	{
		garage_id: 13,
		part_id: 94,
		quantity: 267,
	},
	{
		garage_id: 13,
		part_id: 95,
		quantity: 88,
	},
	{
		garage_id: 13,
		part_id: 96,
		quantity: 406,
	},
	{
		garage_id: 13,
		part_id: 97,
		quantity: 139,
	},
	{
		garage_id: 13,
		part_id: 98,
		quantity: 57,
	},
	{
		garage_id: 13,
		part_id: 99,
		quantity: 140,
	},
	{
		garage_id: 13,
		part_id: 100,
		quantity: 467,
	},
	{
		garage_id: 13,
		part_id: 101,
		quantity: 403,
	},
	{
		garage_id: 13,
		part_id: 102,
		quantity: 387,
	},
	{
		garage_id: 13,
		part_id: 103,
		quantity: 53,
	},
	{
		garage_id: 13,
		part_id: 104,
		quantity: 117,
	},
	{
		garage_id: 13,
		part_id: 105,
		quantity: 218,
	},
	{
		garage_id: 13,
		part_id: 106,
		quantity: 423,
	},
	{
		garage_id: 13,
		part_id: 107,
		quantity: 309,
	},
	{
		garage_id: 13,
		part_id: 108,
		quantity: 293,
	},
	{
		garage_id: 13,
		part_id: 109,
		quantity: 259,
	},
	{
		garage_id: 13,
		part_id: 110,
		quantity: 474,
	},
	{
		garage_id: 13,
		part_id: 111,
		quantity: 230,
	},
	{
		garage_id: 13,
		part_id: 112,
		quantity: 302,
	},
	{
		garage_id: 13,
		part_id: 113,
		quantity: 14,
	},
	{
		garage_id: 13,
		part_id: 114,
		quantity: 337,
	},
	{
		garage_id: 13,
		part_id: 115,
		quantity: 131,
	},
	{
		garage_id: 13,
		part_id: 116,
		quantity: 185,
	},
	{
		garage_id: 13,
		part_id: 117,
		quantity: 367,
	},
	{
		garage_id: 13,
		part_id: 118,
		quantity: 156,
	},
	{
		garage_id: 13,
		part_id: 119,
		quantity: 218,
	},
	{
		garage_id: 13,
		part_id: 120,
		quantity: 149,
	},
	{
		garage_id: 13,
		part_id: 121,
		quantity: 417,
	},
	{
		garage_id: 13,
		part_id: 122,
		quantity: 97,
	},
	{
		garage_id: 13,
		part_id: 123,
		quantity: 253,
	},
	{
		garage_id: 13,
		part_id: 124,
		quantity: 380,
	},
	{
		garage_id: 13,
		part_id: 125,
		quantity: 125,
	},
	{
		garage_id: 13,
		part_id: 126,
		quantity: 270,
	},
	{
		garage_id: 13,
		part_id: 127,
		quantity: 358,
	},
	{
		garage_id: 13,
		part_id: 128,
		quantity: 352,
	},
	{
		garage_id: 13,
		part_id: 129,
		quantity: 447,
	},
	{
		garage_id: 13,
		part_id: 130,
		quantity: 355,
	},
	{
		garage_id: 13,
		part_id: 131,
		quantity: 462,
	},
	{
		garage_id: 13,
		part_id: 132,
		quantity: 45,
	},
	{
		garage_id: 13,
		part_id: 133,
		quantity: 207,
	},
	{
		garage_id: 13,
		part_id: 134,
		quantity: 250,
	},
	{
		garage_id: 13,
		part_id: 135,
		quantity: 144,
	},
	{
		garage_id: 13,
		part_id: 136,
		quantity: 252,
	},
	{
		garage_id: 13,
		part_id: 137,
		quantity: 320,
	},
	{
		garage_id: 13,
		part_id: 138,
		quantity: 434,
	},
	{
		garage_id: 13,
		part_id: 139,
		quantity: 156,
	},
	{
		garage_id: 13,
		part_id: 140,
		quantity: 83,
	},
	{
		garage_id: 13,
		part_id: 141,
		quantity: 254,
	},
	{
		garage_id: 13,
		part_id: 142,
		quantity: 195,
	},
	{
		garage_id: 13,
		part_id: 143,
		quantity: 277,
	},
	{
		garage_id: 13,
		part_id: 144,
		quantity: 189,
	},
	{
		garage_id: 13,
		part_id: 145,
		quantity: 365,
	},
	{
		garage_id: 13,
		part_id: 146,
		quantity: 168,
	},
	{
		garage_id: 13,
		part_id: 147,
		quantity: 499,
	},
	{
		garage_id: 13,
		part_id: 148,
		quantity: 42,
	},
	{
		garage_id: 13,
		part_id: 149,
		quantity: 152,
	},
	{
		garage_id: 13,
		part_id: 150,
		quantity: 404,
	},
	{
		garage_id: 13,
		part_id: 151,
		quantity: 194,
	},
	{
		garage_id: 13,
		part_id: 152,
		quantity: 364,
	},
	{
		garage_id: 13,
		part_id: 153,
		quantity: 49,
	},
	{
		garage_id: 13,
		part_id: 154,
		quantity: 78,
	},
	{
		garage_id: 13,
		part_id: 155,
		quantity: 340,
	},
	{
		garage_id: 13,
		part_id: 156,
		quantity: 376,
	},
	{
		garage_id: 13,
		part_id: 157,
		quantity: 462,
	},
	{
		garage_id: 13,
		part_id: 158,
		quantity: 442,
	},
	{
		garage_id: 13,
		part_id: 159,
		quantity: 149,
	},
	{
		garage_id: 13,
		part_id: 160,
		quantity: 444,
	},
	{
		garage_id: 13,
		part_id: 161,
		quantity: 150,
	},
	{
		garage_id: 13,
		part_id: 162,
		quantity: 413,
	},
	{
		garage_id: 13,
		part_id: 163,
		quantity: 202,
	},
	{
		garage_id: 13,
		part_id: 164,
		quantity: 181,
	},
	{
		garage_id: 13,
		part_id: 165,
		quantity: 257,
	},
	{
		garage_id: 13,
		part_id: 166,
		quantity: 3,
	},
	{
		garage_id: 13,
		part_id: 167,
		quantity: 259,
	},
	{
		garage_id: 13,
		part_id: 168,
		quantity: 408,
	},
	{
		garage_id: 13,
		part_id: 169,
		quantity: 220,
	},
	{
		garage_id: 13,
		part_id: 170,
		quantity: 280,
	},
	{
		garage_id: 13,
		part_id: 171,
		quantity: 79,
	},
	{
		garage_id: 13,
		part_id: 172,
		quantity: 56,
	},
	{
		garage_id: 13,
		part_id: 173,
		quantity: 200,
	},
	{
		garage_id: 13,
		part_id: 174,
		quantity: 320,
	},
	{
		garage_id: 13,
		part_id: 175,
		quantity: 364,
	},
	{
		garage_id: 13,
		part_id: 176,
		quantity: 56,
	},
	{
		garage_id: 13,
		part_id: 177,
		quantity: 97,
	},
	{
		garage_id: 13,
		part_id: 178,
		quantity: 441,
	},
	{
		garage_id: 13,
		part_id: 179,
		quantity: 423,
	},
	{
		garage_id: 13,
		part_id: 180,
		quantity: 170,
	},
	{
		garage_id: 13,
		part_id: 181,
		quantity: 478,
	},
	{
		garage_id: 13,
		part_id: 182,
		quantity: 81,
	},
	{
		garage_id: 13,
		part_id: 183,
		quantity: 407,
	},
	{
		garage_id: 13,
		part_id: 184,
		quantity: 345,
	},
	{
		garage_id: 13,
		part_id: 185,
		quantity: 197,
	},
	{
		garage_id: 13,
		part_id: 186,
		quantity: 425,
	},
	{
		garage_id: 13,
		part_id: 187,
		quantity: 157,
	},
	{
		garage_id: 13,
		part_id: 188,
		quantity: 76,
	},
	{
		garage_id: 13,
		part_id: 189,
		quantity: 456,
	},
	{
		garage_id: 13,
		part_id: 190,
		quantity: 180,
	},
	{
		garage_id: 13,
		part_id: 191,
		quantity: 313,
	},
	{
		garage_id: 13,
		part_id: 192,
		quantity: 52,
	},
	{
		garage_id: 13,
		part_id: 193,
		quantity: 170,
	},
	{
		garage_id: 13,
		part_id: 194,
		quantity: 468,
	},
	{
		garage_id: 13,
		part_id: 195,
		quantity: 361,
	},
	{
		garage_id: 13,
		part_id: 196,
		quantity: 338,
	},
	{
		garage_id: 13,
		part_id: 197,
		quantity: 464,
	},
	{
		garage_id: 13,
		part_id: 198,
		quantity: 148,
	},
	{
		garage_id: 13,
		part_id: 199,
		quantity: 102,
	},
	{
		garage_id: 13,
		part_id: 200,
		quantity: 100,
	},
	{
		garage_id: 13,
		part_id: 201,
		quantity: 297,
	},
	{
		garage_id: 13,
		part_id: 202,
		quantity: 243,
	},
	{
		garage_id: 13,
		part_id: 203,
		quantity: 133,
	},
	{
		garage_id: 13,
		part_id: 204,
		quantity: 436,
	},
	{
		garage_id: 13,
		part_id: 205,
		quantity: 275,
	},
	{
		garage_id: 13,
		part_id: 206,
		quantity: 379,
	},
	{
		garage_id: 13,
		part_id: 207,
		quantity: 359,
	},
	{
		garage_id: 13,
		part_id: 208,
		quantity: 14,
	},
	{
		garage_id: 13,
		part_id: 209,
		quantity: 275,
	},
	{
		garage_id: 13,
		part_id: 210,
		quantity: 360,
	},
	{
		garage_id: 13,
		part_id: 211,
		quantity: 362,
	},
	{
		garage_id: 13,
		part_id: 212,
		quantity: 374,
	},
	{
		garage_id: 13,
		part_id: 213,
		quantity: 255,
	},
	{
		garage_id: 13,
		part_id: 214,
		quantity: 225,
	},
	{
		garage_id: 13,
		part_id: 215,
		quantity: 436,
	},
	{
		garage_id: 13,
		part_id: 216,
		quantity: 3,
	},
	{
		garage_id: 13,
		part_id: 217,
		quantity: 354,
	},
	{
		garage_id: 13,
		part_id: 218,
		quantity: 18,
	},
	{
		garage_id: 13,
		part_id: 219,
		quantity: 206,
	},
	{
		garage_id: 13,
		part_id: 220,
		quantity: 313,
	},
	{
		garage_id: 13,
		part_id: 221,
		quantity: 37,
	},
	{
		garage_id: 13,
		part_id: 222,
		quantity: 343,
	},
	{
		garage_id: 13,
		part_id: 223,
		quantity: 256,
	},
	{
		garage_id: 13,
		part_id: 224,
		quantity: 139,
	},
	{
		garage_id: 13,
		part_id: 225,
		quantity: 394,
	},
	{
		garage_id: 13,
		part_id: 226,
		quantity: 455,
	},
	{
		garage_id: 13,
		part_id: 227,
		quantity: 115,
	},
	{
		garage_id: 13,
		part_id: 228,
		quantity: 150,
	},
	{
		garage_id: 13,
		part_id: 229,
		quantity: 418,
	},
	{
		garage_id: 13,
		part_id: 230,
		quantity: 173,
	},
	{
		garage_id: 13,
		part_id: 231,
		quantity: 412,
	},
	{
		garage_id: 13,
		part_id: 232,
		quantity: 44,
	},
	{
		garage_id: 13,
		part_id: 233,
		quantity: 251,
	},
	{
		garage_id: 13,
		part_id: 234,
		quantity: 293,
	},
	{
		garage_id: 13,
		part_id: 235,
		quantity: 341,
	},
	{
		garage_id: 13,
		part_id: 236,
		quantity: 387,
	},
	{
		garage_id: 13,
		part_id: 237,
		quantity: 90,
	},
	{
		garage_id: 13,
		part_id: 238,
		quantity: 291,
	},
	{
		garage_id: 13,
		part_id: 239,
		quantity: 210,
	},
	{
		garage_id: 13,
		part_id: 240,
		quantity: 273,
	},
	{
		garage_id: 13,
		part_id: 241,
		quantity: 248,
	},
	{
		garage_id: 13,
		part_id: 242,
		quantity: 227,
	},
	{
		garage_id: 13,
		part_id: 243,
		quantity: 367,
	},
	{
		garage_id: 13,
		part_id: 244,
		quantity: 68,
	},
	{
		garage_id: 13,
		part_id: 245,
		quantity: 289,
	},
	{
		garage_id: 13,
		part_id: 246,
		quantity: 32,
	},
	{
		garage_id: 13,
		part_id: 247,
		quantity: 280,
	},
	{
		garage_id: 13,
		part_id: 248,
		quantity: 428,
	},
	{
		garage_id: 13,
		part_id: 249,
		quantity: 487,
	},
	{
		garage_id: 13,
		part_id: 250,
		quantity: 375,
	},
	{
		garage_id: 13,
		part_id: 251,
		quantity: 456,
	},
	{
		garage_id: 13,
		part_id: 252,
		quantity: 487,
	},
	{
		garage_id: 13,
		part_id: 253,
		quantity: 268,
	},
	{
		garage_id: 13,
		part_id: 254,
		quantity: 488,
	},
	{
		garage_id: 13,
		part_id: 255,
		quantity: 150,
	},
	{
		garage_id: 13,
		part_id: 256,
		quantity: 132,
	},
	{
		garage_id: 13,
		part_id: 257,
		quantity: 99,
	},
	{
		garage_id: 13,
		part_id: 258,
		quantity: 430,
	},
	{
		garage_id: 13,
		part_id: 259,
		quantity: 226,
	},
	{
		garage_id: 13,
		part_id: 260,
		quantity: 464,
	},
	{
		garage_id: 13,
		part_id: 261,
		quantity: 196,
	},
	{
		garage_id: 13,
		part_id: 262,
		quantity: 194,
	},
	{
		garage_id: 13,
		part_id: 263,
		quantity: 387,
	},
	{
		garage_id: 13,
		part_id: 264,
		quantity: 76,
	},
	{
		garage_id: 13,
		part_id: 265,
		quantity: 236,
	},
	{
		garage_id: 13,
		part_id: 266,
		quantity: 238,
	},
	{
		garage_id: 13,
		part_id: 267,
		quantity: 74,
	},
	{
		garage_id: 13,
		part_id: 268,
		quantity: 417,
	},
	{
		garage_id: 13,
		part_id: 269,
		quantity: 260,
	},
	{
		garage_id: 13,
		part_id: 270,
		quantity: 199,
	},
	{
		garage_id: 13,
		part_id: 271,
		quantity: 148,
	},
	{
		garage_id: 13,
		part_id: 272,
		quantity: 489,
	},
	{
		garage_id: 13,
		part_id: 273,
		quantity: 463,
	},
	{
		garage_id: 13,
		part_id: 274,
		quantity: 370,
	},
	{
		garage_id: 13,
		part_id: 275,
		quantity: 10,
	},
	{
		garage_id: 13,
		part_id: 276,
		quantity: 324,
	},
	{
		garage_id: 13,
		part_id: 277,
		quantity: 397,
	},
	{
		garage_id: 13,
		part_id: 278,
		quantity: 296,
	},
	{
		garage_id: 13,
		part_id: 279,
		quantity: 108,
	},
	{
		garage_id: 13,
		part_id: 280,
		quantity: 423,
	},
	{
		garage_id: 13,
		part_id: 281,
		quantity: 75,
	},
	{
		garage_id: 13,
		part_id: 282,
		quantity: 82,
	},
	{
		garage_id: 13,
		part_id: 283,
		quantity: 120,
	},
	{
		garage_id: 13,
		part_id: 284,
		quantity: 157,
	},
	{
		garage_id: 13,
		part_id: 285,
		quantity: 316,
	},
	{
		garage_id: 13,
		part_id: 286,
		quantity: 384,
	},
	{
		garage_id: 13,
		part_id: 287,
		quantity: 72,
	},
	{
		garage_id: 13,
		part_id: 288,
		quantity: 449,
	},
	{
		garage_id: 13,
		part_id: 289,
		quantity: 216,
	},
	{
		garage_id: 13,
		part_id: 290,
		quantity: 414,
	},
	{
		garage_id: 13,
		part_id: 291,
		quantity: 258,
	},
	{
		garage_id: 13,
		part_id: 292,
		quantity: 54,
	},
	{
		garage_id: 13,
		part_id: 293,
		quantity: 403,
	},
	{
		garage_id: 13,
		part_id: 294,
		quantity: 111,
	},
	{
		garage_id: 13,
		part_id: 295,
		quantity: 73,
	},
	{
		garage_id: 13,
		part_id: 296,
		quantity: 80,
	},
	{
		garage_id: 13,
		part_id: 297,
		quantity: 131,
	},
	{
		garage_id: 13,
		part_id: 298,
		quantity: 408,
	},
	{
		garage_id: 13,
		part_id: 299,
		quantity: 45,
	},
	{
		garage_id: 13,
		part_id: 300,
		quantity: 55,
	},
	{
		garage_id: 13,
		part_id: 301,
		quantity: 490,
	},
	{
		garage_id: 13,
		part_id: 302,
		quantity: 286,
	},
	{
		garage_id: 13,
		part_id: 303,
		quantity: 349,
	},
	{
		garage_id: 13,
		part_id: 304,
		quantity: 337,
	},
	{
		garage_id: 13,
		part_id: 305,
		quantity: 260,
	},
	{
		garage_id: 13,
		part_id: 306,
		quantity: 350,
	},
	{
		garage_id: 13,
		part_id: 307,
		quantity: 434,
	},
	{
		garage_id: 13,
		part_id: 308,
		quantity: 112,
	},
	{
		garage_id: 13,
		part_id: 309,
		quantity: 72,
	},
	{
		garage_id: 13,
		part_id: 310,
		quantity: 149,
	},
	{
		garage_id: 13,
		part_id: 311,
		quantity: 174,
	},
	{
		garage_id: 13,
		part_id: 312,
		quantity: 494,
	},
	{
		garage_id: 13,
		part_id: 313,
		quantity: 72,
	},
	{
		garage_id: 13,
		part_id: 314,
		quantity: 325,
	},
	{
		garage_id: 13,
		part_id: 315,
		quantity: 199,
	},
	{
		garage_id: 13,
		part_id: 316,
		quantity: 172,
	},
	{
		garage_id: 13,
		part_id: 317,
		quantity: 195,
	},
	{
		garage_id: 13,
		part_id: 318,
		quantity: 413,
	},
	{
		garage_id: 13,
		part_id: 319,
		quantity: 83,
	},
	{
		garage_id: 13,
		part_id: 320,
		quantity: 270,
	},
	{
		garage_id: 13,
		part_id: 321,
		quantity: 156,
	},
	{
		garage_id: 13,
		part_id: 322,
		quantity: 16,
	},
	{
		garage_id: 13,
		part_id: 323,
		quantity: 271,
	},
	{
		garage_id: 13,
		part_id: 324,
		quantity: 284,
	},
	{
		garage_id: 13,
		part_id: 325,
		quantity: 233,
	},
	{
		garage_id: 13,
		part_id: 326,
		quantity: 219,
	},
	{
		garage_id: 13,
		part_id: 327,
		quantity: 343,
	},
	{
		garage_id: 13,
		part_id: 328,
		quantity: 145,
	},
	{
		garage_id: 13,
		part_id: 329,
		quantity: 249,
	},
	{
		garage_id: 13,
		part_id: 330,
		quantity: 152,
	},
	{
		garage_id: 13,
		part_id: 331,
		quantity: 310,
	},
	{
		garage_id: 13,
		part_id: 332,
		quantity: 151,
	},
	{
		garage_id: 13,
		part_id: 333,
		quantity: 216,
	},
	{
		garage_id: 13,
		part_id: 334,
		quantity: 175,
	},
	{
		garage_id: 13,
		part_id: 335,
		quantity: 305,
	},
	{
		garage_id: 13,
		part_id: 336,
		quantity: 485,
	},
	{
		garage_id: 13,
		part_id: 337,
		quantity: 400,
	},
	{
		garage_id: 13,
		part_id: 338,
		quantity: 181,
	},
	{
		garage_id: 13,
		part_id: 339,
		quantity: 292,
	},
	{
		garage_id: 13,
		part_id: 340,
		quantity: 382,
	},
	{
		garage_id: 13,
		part_id: 341,
		quantity: 107,
	},
	{
		garage_id: 13,
		part_id: 342,
		quantity: 31,
	},
	{
		garage_id: 13,
		part_id: 343,
		quantity: 24,
	},
	{
		garage_id: 13,
		part_id: 344,
		quantity: 223,
	},
	{
		garage_id: 13,
		part_id: 345,
		quantity: 406,
	},
	{
		garage_id: 13,
		part_id: 346,
		quantity: 453,
	},
	{
		garage_id: 13,
		part_id: 347,
		quantity: 309,
	},
	{
		garage_id: 13,
		part_id: 348,
		quantity: 268,
	},
	{
		garage_id: 13,
		part_id: 349,
		quantity: 428,
	},
	{
		garage_id: 13,
		part_id: 350,
		quantity: 375,
	},
	{
		garage_id: 13,
		part_id: 351,
		quantity: 421,
	},
	{
		garage_id: 13,
		part_id: 352,
		quantity: 172,
	},
	{
		garage_id: 13,
		part_id: 353,
		quantity: 286,
	},
	{
		garage_id: 13,
		part_id: 354,
		quantity: 379,
	},
	{
		garage_id: 13,
		part_id: 355,
		quantity: 286,
	},
	{
		garage_id: 13,
		part_id: 356,
		quantity: 370,
	},
	{
		garage_id: 13,
		part_id: 357,
		quantity: 402,
	},
	{
		garage_id: 13,
		part_id: 358,
		quantity: 224,
	},
	{
		garage_id: 13,
		part_id: 359,
		quantity: 414,
	},
	{
		garage_id: 13,
		part_id: 360,
		quantity: 61,
	},
	{
		garage_id: 13,
		part_id: 361,
		quantity: 438,
	},
	{
		garage_id: 13,
		part_id: 362,
		quantity: 453,
	},
	{
		garage_id: 13,
		part_id: 363,
		quantity: 109,
	},
	{
		garage_id: 13,
		part_id: 364,
		quantity: 267,
	},
	{
		garage_id: 13,
		part_id: 365,
		quantity: 58,
	},
	{
		garage_id: 13,
		part_id: 366,
		quantity: 55,
	},
	{
		garage_id: 13,
		part_id: 367,
		quantity: 499,
	},
	{
		garage_id: 13,
		part_id: 368,
		quantity: 51,
	},
	{
		garage_id: 13,
		part_id: 369,
		quantity: 271,
	},
	{
		garage_id: 13,
		part_id: 370,
		quantity: 149,
	},
	{
		garage_id: 13,
		part_id: 371,
		quantity: 142,
	},
	{
		garage_id: 13,
		part_id: 372,
		quantity: 468,
	},
	{
		garage_id: 13,
		part_id: 373,
		quantity: 307,
	},
	{
		garage_id: 13,
		part_id: 374,
		quantity: 354,
	},
	{
		garage_id: 13,
		part_id: 375,
		quantity: 322,
	},
	{
		garage_id: 13,
		part_id: 376,
		quantity: 87,
	},
	{
		garage_id: 13,
		part_id: 377,
		quantity: 100,
	},
	{
		garage_id: 13,
		part_id: 378,
		quantity: 116,
	},
	{
		garage_id: 13,
		part_id: 379,
		quantity: 219,
	},
	{
		garage_id: 13,
		part_id: 380,
		quantity: 166,
	},
	{
		garage_id: 13,
		part_id: 381,
		quantity: 467,
	},
	{
		garage_id: 13,
		part_id: 382,
		quantity: 163,
	},
	{
		garage_id: 13,
		part_id: 383,
		quantity: 469,
	},
	{
		garage_id: 13,
		part_id: 384,
		quantity: 375,
	},
	{
		garage_id: 13,
		part_id: 385,
		quantity: 18,
	},
	{
		garage_id: 13,
		part_id: 386,
		quantity: 261,
	},
	{
		garage_id: 13,
		part_id: 387,
		quantity: 56,
	},
	{
		garage_id: 13,
		part_id: 388,
		quantity: 496,
	},
	{
		garage_id: 13,
		part_id: 389,
		quantity: 474,
	},
	{
		garage_id: 13,
		part_id: 390,
		quantity: 189,
	},
	{
		garage_id: 13,
		part_id: 391,
		quantity: 127,
	},
	{
		garage_id: 13,
		part_id: 392,
		quantity: 128,
	},
	{
		garage_id: 13,
		part_id: 393,
		quantity: 60,
	},
	{
		garage_id: 13,
		part_id: 394,
		quantity: 107,
	},
	{
		garage_id: 13,
		part_id: 395,
		quantity: 16,
	},
	{
		garage_id: 13,
		part_id: 396,
		quantity: 370,
	},
	{
		garage_id: 13,
		part_id: 397,
		quantity: 53,
	},
	{
		garage_id: 13,
		part_id: 398,
		quantity: 201,
	},
	{
		garage_id: 13,
		part_id: 399,
		quantity: 226,
	},
	{
		garage_id: 14,
		part_id: 0,
		quantity: 87,
	},
	{
		garage_id: 14,
		part_id: 1,
		quantity: 169,
	},
	{
		garage_id: 14,
		part_id: 2,
		quantity: 23,
	},
	{
		garage_id: 14,
		part_id: 3,
		quantity: 468,
	},
	{
		garage_id: 14,
		part_id: 4,
		quantity: 239,
	},
	{
		garage_id: 14,
		part_id: 5,
		quantity: 497,
	},
	{
		garage_id: 14,
		part_id: 6,
		quantity: 421,
	},
	{
		garage_id: 14,
		part_id: 7,
		quantity: 115,
	},
	{
		garage_id: 14,
		part_id: 8,
		quantity: 324,
	},
	{
		garage_id: 14,
		part_id: 9,
		quantity: 292,
	},
	{
		garage_id: 14,
		part_id: 10,
		quantity: 100,
	},
	{
		garage_id: 14,
		part_id: 11,
		quantity: 37,
	},
	{
		garage_id: 14,
		part_id: 12,
		quantity: 363,
	},
	{
		garage_id: 14,
		part_id: 13,
		quantity: 267,
	},
	{
		garage_id: 14,
		part_id: 14,
		quantity: 406,
	},
	{
		garage_id: 14,
		part_id: 15,
		quantity: 96,
	},
	{
		garage_id: 14,
		part_id: 16,
		quantity: 222,
	},
	{
		garage_id: 14,
		part_id: 17,
		quantity: 165,
	},
	{
		garage_id: 14,
		part_id: 18,
		quantity: 463,
	},
	{
		garage_id: 14,
		part_id: 19,
		quantity: 48,
	},
	{
		garage_id: 14,
		part_id: 20,
		quantity: 382,
	},
	{
		garage_id: 14,
		part_id: 21,
		quantity: 486,
	},
	{
		garage_id: 14,
		part_id: 22,
		quantity: 433,
	},
	{
		garage_id: 14,
		part_id: 23,
		quantity: 176,
	},
	{
		garage_id: 14,
		part_id: 24,
		quantity: 372,
	},
	{
		garage_id: 14,
		part_id: 25,
		quantity: 216,
	},
	{
		garage_id: 14,
		part_id: 26,
		quantity: 74,
	},
	{
		garage_id: 14,
		part_id: 27,
		quantity: 106,
	},
	{
		garage_id: 14,
		part_id: 28,
		quantity: 442,
	},
	{
		garage_id: 14,
		part_id: 29,
		quantity: 431,
	},
	{
		garage_id: 14,
		part_id: 30,
		quantity: 429,
	},
	{
		garage_id: 14,
		part_id: 31,
		quantity: 270,
	},
	{
		garage_id: 14,
		part_id: 32,
		quantity: 255,
	},
	{
		garage_id: 14,
		part_id: 33,
		quantity: 25,
	},
	{
		garage_id: 14,
		part_id: 34,
		quantity: 352,
	},
	{
		garage_id: 14,
		part_id: 35,
		quantity: 246,
	},
	{
		garage_id: 14,
		part_id: 36,
		quantity: 342,
	},
	{
		garage_id: 14,
		part_id: 37,
		quantity: 400,
	},
	{
		garage_id: 14,
		part_id: 38,
		quantity: 3,
	},
	{
		garage_id: 14,
		part_id: 39,
		quantity: 109,
	},
	{
		garage_id: 14,
		part_id: 40,
		quantity: 104,
	},
	{
		garage_id: 14,
		part_id: 41,
		quantity: 222,
	},
	{
		garage_id: 14,
		part_id: 42,
		quantity: 290,
	},
	{
		garage_id: 14,
		part_id: 43,
		quantity: 80,
	},
	{
		garage_id: 14,
		part_id: 44,
		quantity: 484,
	},
	{
		garage_id: 14,
		part_id: 45,
		quantity: 83,
	},
	{
		garage_id: 14,
		part_id: 46,
		quantity: 345,
	},
	{
		garage_id: 14,
		part_id: 47,
		quantity: 392,
	},
	{
		garage_id: 14,
		part_id: 48,
		quantity: 136,
	},
	{
		garage_id: 14,
		part_id: 49,
		quantity: 352,
	},
	{
		garage_id: 14,
		part_id: 50,
		quantity: 452,
	},
	{
		garage_id: 14,
		part_id: 51,
		quantity: 293,
	},
	{
		garage_id: 14,
		part_id: 52,
		quantity: 381,
	},
	{
		garage_id: 14,
		part_id: 53,
		quantity: 159,
	},
	{
		garage_id: 14,
		part_id: 54,
		quantity: 229,
	},
	{
		garage_id: 14,
		part_id: 55,
		quantity: 353,
	},
	{
		garage_id: 14,
		part_id: 56,
		quantity: 77,
	},
	{
		garage_id: 14,
		part_id: 57,
		quantity: 276,
	},
	{
		garage_id: 14,
		part_id: 58,
		quantity: 449,
	},
	{
		garage_id: 14,
		part_id: 59,
		quantity: 384,
	},
	{
		garage_id: 14,
		part_id: 60,
		quantity: 341,
	},
	{
		garage_id: 14,
		part_id: 61,
		quantity: 195,
	},
	{
		garage_id: 14,
		part_id: 62,
		quantity: 187,
	},
	{
		garage_id: 14,
		part_id: 63,
		quantity: 386,
	},
	{
		garage_id: 14,
		part_id: 64,
		quantity: 1,
	},
	{
		garage_id: 14,
		part_id: 65,
		quantity: 57,
	},
	{
		garage_id: 14,
		part_id: 66,
		quantity: 332,
	},
	{
		garage_id: 14,
		part_id: 67,
		quantity: 265,
	},
	{
		garage_id: 14,
		part_id: 68,
		quantity: 278,
	},
	{
		garage_id: 14,
		part_id: 69,
		quantity: 175,
	},
	{
		garage_id: 14,
		part_id: 70,
		quantity: 21,
	},
	{
		garage_id: 14,
		part_id: 71,
		quantity: 459,
	},
	{
		garage_id: 14,
		part_id: 72,
		quantity: 189,
	},
	{
		garage_id: 14,
		part_id: 73,
		quantity: 126,
	},
	{
		garage_id: 14,
		part_id: 74,
		quantity: 189,
	},
	{
		garage_id: 14,
		part_id: 75,
		quantity: 137,
	},
	{
		garage_id: 14,
		part_id: 76,
		quantity: 187,
	},
	{
		garage_id: 14,
		part_id: 77,
		quantity: 494,
	},
	{
		garage_id: 14,
		part_id: 78,
		quantity: 87,
	},
	{
		garage_id: 14,
		part_id: 79,
		quantity: 185,
	},
	{
		garage_id: 14,
		part_id: 80,
		quantity: 186,
	},
	{
		garage_id: 14,
		part_id: 81,
		quantity: 104,
	},
	{
		garage_id: 14,
		part_id: 82,
		quantity: 205,
	},
	{
		garage_id: 14,
		part_id: 83,
		quantity: 90,
	},
	{
		garage_id: 14,
		part_id: 84,
		quantity: 465,
	},
	{
		garage_id: 14,
		part_id: 85,
		quantity: 379,
	},
	{
		garage_id: 14,
		part_id: 86,
		quantity: 360,
	},
	{
		garage_id: 14,
		part_id: 87,
		quantity: 429,
	},
	{
		garage_id: 14,
		part_id: 88,
		quantity: 85,
	},
	{
		garage_id: 14,
		part_id: 89,
		quantity: 287,
	},
	{
		garage_id: 14,
		part_id: 90,
		quantity: 14,
	},
	{
		garage_id: 14,
		part_id: 91,
		quantity: 300,
	},
	{
		garage_id: 14,
		part_id: 92,
		quantity: 108,
	},
	{
		garage_id: 14,
		part_id: 93,
		quantity: 232,
	},
	{
		garage_id: 14,
		part_id: 94,
		quantity: 186,
	},
	{
		garage_id: 14,
		part_id: 95,
		quantity: 483,
	},
	{
		garage_id: 14,
		part_id: 96,
		quantity: 42,
	},
	{
		garage_id: 14,
		part_id: 97,
		quantity: 132,
	},
	{
		garage_id: 14,
		part_id: 98,
		quantity: 49,
	},
	{
		garage_id: 14,
		part_id: 99,
		quantity: 438,
	},
	{
		garage_id: 14,
		part_id: 100,
		quantity: 5,
	},
	{
		garage_id: 14,
		part_id: 101,
		quantity: 162,
	},
	{
		garage_id: 14,
		part_id: 102,
		quantity: 371,
	},
	{
		garage_id: 14,
		part_id: 103,
		quantity: 271,
	},
	{
		garage_id: 14,
		part_id: 104,
		quantity: 324,
	},
	{
		garage_id: 14,
		part_id: 105,
		quantity: 326,
	},
	{
		garage_id: 14,
		part_id: 106,
		quantity: 337,
	},
	{
		garage_id: 14,
		part_id: 107,
		quantity: 251,
	},
	{
		garage_id: 14,
		part_id: 108,
		quantity: 83,
	},
	{
		garage_id: 14,
		part_id: 109,
		quantity: 187,
	},
	{
		garage_id: 14,
		part_id: 110,
		quantity: 136,
	},
	{
		garage_id: 14,
		part_id: 111,
		quantity: 88,
	},
	{
		garage_id: 14,
		part_id: 112,
		quantity: 298,
	},
	{
		garage_id: 14,
		part_id: 113,
		quantity: 21,
	},
	{
		garage_id: 14,
		part_id: 114,
		quantity: 392,
	},
	{
		garage_id: 14,
		part_id: 115,
		quantity: 239,
	},
	{
		garage_id: 14,
		part_id: 116,
		quantity: 476,
	},
	{
		garage_id: 14,
		part_id: 117,
		quantity: 136,
	},
	{
		garage_id: 14,
		part_id: 118,
		quantity: 121,
	},
	{
		garage_id: 14,
		part_id: 119,
		quantity: 114,
	},
	{
		garage_id: 14,
		part_id: 120,
		quantity: 377,
	},
	{
		garage_id: 14,
		part_id: 121,
		quantity: 372,
	},
	{
		garage_id: 14,
		part_id: 122,
		quantity: 455,
	},
	{
		garage_id: 14,
		part_id: 123,
		quantity: 264,
	},
	{
		garage_id: 14,
		part_id: 124,
		quantity: 374,
	},
	{
		garage_id: 14,
		part_id: 125,
		quantity: 321,
	},
	{
		garage_id: 14,
		part_id: 126,
		quantity: 409,
	},
	{
		garage_id: 14,
		part_id: 127,
		quantity: 304,
	},
	{
		garage_id: 14,
		part_id: 128,
		quantity: 64,
	},
	{
		garage_id: 14,
		part_id: 129,
		quantity: 407,
	},
	{
		garage_id: 14,
		part_id: 130,
		quantity: 201,
	},
	{
		garage_id: 14,
		part_id: 131,
		quantity: 162,
	},
	{
		garage_id: 14,
		part_id: 132,
		quantity: 330,
	},
	{
		garage_id: 14,
		part_id: 133,
		quantity: 195,
	},
	{
		garage_id: 14,
		part_id: 134,
		quantity: 88,
	},
	{
		garage_id: 14,
		part_id: 135,
		quantity: 493,
	},
	{
		garage_id: 14,
		part_id: 136,
		quantity: 99,
	},
	{
		garage_id: 14,
		part_id: 137,
		quantity: 61,
	},
	{
		garage_id: 14,
		part_id: 138,
		quantity: 298,
	},
	{
		garage_id: 14,
		part_id: 139,
		quantity: 103,
	},
	{
		garage_id: 14,
		part_id: 140,
		quantity: 298,
	},
	{
		garage_id: 14,
		part_id: 141,
		quantity: 315,
	},
	{
		garage_id: 14,
		part_id: 142,
		quantity: 78,
	},
	{
		garage_id: 14,
		part_id: 143,
		quantity: 382,
	},
	{
		garage_id: 14,
		part_id: 144,
		quantity: 0,
	},
	{
		garage_id: 14,
		part_id: 145,
		quantity: 34,
	},
	{
		garage_id: 14,
		part_id: 146,
		quantity: 169,
	},
	{
		garage_id: 14,
		part_id: 147,
		quantity: 373,
	},
	{
		garage_id: 14,
		part_id: 148,
		quantity: 369,
	},
	{
		garage_id: 14,
		part_id: 149,
		quantity: 27,
	},
	{
		garage_id: 14,
		part_id: 150,
		quantity: 325,
	},
	{
		garage_id: 14,
		part_id: 151,
		quantity: 357,
	},
	{
		garage_id: 14,
		part_id: 152,
		quantity: 135,
	},
	{
		garage_id: 14,
		part_id: 153,
		quantity: 42,
	},
	{
		garage_id: 14,
		part_id: 154,
		quantity: 69,
	},
	{
		garage_id: 14,
		part_id: 155,
		quantity: 352,
	},
	{
		garage_id: 14,
		part_id: 156,
		quantity: 46,
	},
	{
		garage_id: 14,
		part_id: 157,
		quantity: 173,
	},
	{
		garage_id: 14,
		part_id: 158,
		quantity: 11,
	},
	{
		garage_id: 14,
		part_id: 159,
		quantity: 208,
	},
	{
		garage_id: 14,
		part_id: 160,
		quantity: 446,
	},
	{
		garage_id: 14,
		part_id: 161,
		quantity: 406,
	},
	{
		garage_id: 14,
		part_id: 162,
		quantity: 278,
	},
	{
		garage_id: 14,
		part_id: 163,
		quantity: 145,
	},
	{
		garage_id: 14,
		part_id: 164,
		quantity: 380,
	},
	{
		garage_id: 14,
		part_id: 165,
		quantity: 294,
	},
	{
		garage_id: 14,
		part_id: 166,
		quantity: 287,
	},
	{
		garage_id: 14,
		part_id: 167,
		quantity: 456,
	},
	{
		garage_id: 14,
		part_id: 168,
		quantity: 80,
	},
	{
		garage_id: 14,
		part_id: 169,
		quantity: 355,
	},
	{
		garage_id: 14,
		part_id: 170,
		quantity: 405,
	},
	{
		garage_id: 14,
		part_id: 171,
		quantity: 365,
	},
	{
		garage_id: 14,
		part_id: 172,
		quantity: 406,
	},
	{
		garage_id: 14,
		part_id: 173,
		quantity: 331,
	},
	{
		garage_id: 14,
		part_id: 174,
		quantity: 405,
	},
	{
		garage_id: 14,
		part_id: 175,
		quantity: 340,
	},
	{
		garage_id: 14,
		part_id: 176,
		quantity: 491,
	},
	{
		garage_id: 14,
		part_id: 177,
		quantity: 51,
	},
	{
		garage_id: 14,
		part_id: 178,
		quantity: 158,
	},
	{
		garage_id: 14,
		part_id: 179,
		quantity: 297,
	},
	{
		garage_id: 14,
		part_id: 180,
		quantity: 381,
	},
	{
		garage_id: 14,
		part_id: 181,
		quantity: 190,
	},
	{
		garage_id: 14,
		part_id: 182,
		quantity: 60,
	},
	{
		garage_id: 14,
		part_id: 183,
		quantity: 421,
	},
	{
		garage_id: 14,
		part_id: 184,
		quantity: 95,
	},
	{
		garage_id: 14,
		part_id: 185,
		quantity: 303,
	},
	{
		garage_id: 14,
		part_id: 186,
		quantity: 135,
	},
	{
		garage_id: 14,
		part_id: 187,
		quantity: 442,
	},
	{
		garage_id: 14,
		part_id: 188,
		quantity: 481,
	},
	{
		garage_id: 14,
		part_id: 189,
		quantity: 405,
	},
	{
		garage_id: 14,
		part_id: 190,
		quantity: 364,
	},
	{
		garage_id: 14,
		part_id: 191,
		quantity: 374,
	},
	{
		garage_id: 14,
		part_id: 192,
		quantity: 327,
	},
	{
		garage_id: 14,
		part_id: 193,
		quantity: 90,
	},
	{
		garage_id: 14,
		part_id: 194,
		quantity: 468,
	},
	{
		garage_id: 14,
		part_id: 195,
		quantity: 79,
	},
	{
		garage_id: 14,
		part_id: 196,
		quantity: 208,
	},
	{
		garage_id: 14,
		part_id: 197,
		quantity: 213,
	},
	{
		garage_id: 14,
		part_id: 198,
		quantity: 396,
	},
	{
		garage_id: 14,
		part_id: 199,
		quantity: 294,
	},
	{
		garage_id: 14,
		part_id: 200,
		quantity: 350,
	},
	{
		garage_id: 14,
		part_id: 201,
		quantity: 332,
	},
	{
		garage_id: 14,
		part_id: 202,
		quantity: 56,
	},
	{
		garage_id: 14,
		part_id: 203,
		quantity: 296,
	},
	{
		garage_id: 14,
		part_id: 204,
		quantity: 424,
	},
	{
		garage_id: 14,
		part_id: 205,
		quantity: 257,
	},
	{
		garage_id: 14,
		part_id: 206,
		quantity: 206,
	},
	{
		garage_id: 14,
		part_id: 207,
		quantity: 86,
	},
	{
		garage_id: 14,
		part_id: 208,
		quantity: 444,
	},
	{
		garage_id: 14,
		part_id: 209,
		quantity: 211,
	},
	{
		garage_id: 14,
		part_id: 210,
		quantity: 131,
	},
	{
		garage_id: 14,
		part_id: 211,
		quantity: 5,
	},
	{
		garage_id: 14,
		part_id: 212,
		quantity: 169,
	},
	{
		garage_id: 14,
		part_id: 213,
		quantity: 334,
	},
	{
		garage_id: 14,
		part_id: 214,
		quantity: 345,
	},
	{
		garage_id: 14,
		part_id: 215,
		quantity: 311,
	},
	{
		garage_id: 14,
		part_id: 216,
		quantity: 69,
	},
	{
		garage_id: 14,
		part_id: 217,
		quantity: 263,
	},
	{
		garage_id: 14,
		part_id: 218,
		quantity: 86,
	},
	{
		garage_id: 14,
		part_id: 219,
		quantity: 209,
	},
	{
		garage_id: 14,
		part_id: 220,
		quantity: 168,
	},
	{
		garage_id: 14,
		part_id: 221,
		quantity: 160,
	},
	{
		garage_id: 14,
		part_id: 222,
		quantity: 380,
	},
	{
		garage_id: 14,
		part_id: 223,
		quantity: 22,
	},
	{
		garage_id: 14,
		part_id: 224,
		quantity: 448,
	},
	{
		garage_id: 14,
		part_id: 225,
		quantity: 449,
	},
	{
		garage_id: 14,
		part_id: 226,
		quantity: 437,
	},
	{
		garage_id: 14,
		part_id: 227,
		quantity: 336,
	},
	{
		garage_id: 14,
		part_id: 228,
		quantity: 499,
	},
	{
		garage_id: 14,
		part_id: 229,
		quantity: 473,
	},
	{
		garage_id: 14,
		part_id: 230,
		quantity: 289,
	},
	{
		garage_id: 14,
		part_id: 231,
		quantity: 121,
	},
	{
		garage_id: 14,
		part_id: 232,
		quantity: 407,
	},
	{
		garage_id: 14,
		part_id: 233,
		quantity: 141,
	},
	{
		garage_id: 14,
		part_id: 234,
		quantity: 264,
	},
	{
		garage_id: 14,
		part_id: 235,
		quantity: 432,
	},
	{
		garage_id: 14,
		part_id: 236,
		quantity: 268,
	},
	{
		garage_id: 14,
		part_id: 237,
		quantity: 73,
	},
	{
		garage_id: 14,
		part_id: 238,
		quantity: 245,
	},
	{
		garage_id: 14,
		part_id: 239,
		quantity: 58,
	},
	{
		garage_id: 14,
		part_id: 240,
		quantity: 259,
	},
	{
		garage_id: 14,
		part_id: 241,
		quantity: 159,
	},
	{
		garage_id: 14,
		part_id: 242,
		quantity: 207,
	},
	{
		garage_id: 14,
		part_id: 243,
		quantity: 321,
	},
	{
		garage_id: 14,
		part_id: 244,
		quantity: 253,
	},
	{
		garage_id: 14,
		part_id: 245,
		quantity: 28,
	},
	{
		garage_id: 14,
		part_id: 246,
		quantity: 454,
	},
	{
		garage_id: 14,
		part_id: 247,
		quantity: 253,
	},
	{
		garage_id: 14,
		part_id: 248,
		quantity: 442,
	},
	{
		garage_id: 14,
		part_id: 249,
		quantity: 337,
	},
	{
		garage_id: 14,
		part_id: 250,
		quantity: 74,
	},
	{
		garage_id: 14,
		part_id: 251,
		quantity: 228,
	},
	{
		garage_id: 14,
		part_id: 252,
		quantity: 77,
	},
	{
		garage_id: 14,
		part_id: 253,
		quantity: 423,
	},
	{
		garage_id: 14,
		part_id: 254,
		quantity: 309,
	},
	{
		garage_id: 14,
		part_id: 255,
		quantity: 399,
	},
	{
		garage_id: 14,
		part_id: 256,
		quantity: 370,
	},
	{
		garage_id: 14,
		part_id: 257,
		quantity: 248,
	},
	{
		garage_id: 14,
		part_id: 258,
		quantity: 158,
	},
	{
		garage_id: 14,
		part_id: 259,
		quantity: 334,
	},
	{
		garage_id: 14,
		part_id: 260,
		quantity: 148,
	},
	{
		garage_id: 14,
		part_id: 261,
		quantity: 28,
	},
	{
		garage_id: 14,
		part_id: 262,
		quantity: 435,
	},
	{
		garage_id: 14,
		part_id: 263,
		quantity: 38,
	},
	{
		garage_id: 14,
		part_id: 264,
		quantity: 89,
	},
	{
		garage_id: 14,
		part_id: 265,
		quantity: 343,
	},
	{
		garage_id: 14,
		part_id: 266,
		quantity: 497,
	},
	{
		garage_id: 14,
		part_id: 267,
		quantity: 35,
	},
	{
		garage_id: 14,
		part_id: 268,
		quantity: 222,
	},
	{
		garage_id: 14,
		part_id: 269,
		quantity: 32,
	},
	{
		garage_id: 14,
		part_id: 270,
		quantity: 4,
	},
	{
		garage_id: 14,
		part_id: 271,
		quantity: 439,
	},
	{
		garage_id: 14,
		part_id: 272,
		quantity: 203,
	},
	{
		garage_id: 14,
		part_id: 273,
		quantity: 9,
	},
	{
		garage_id: 14,
		part_id: 274,
		quantity: 347,
	},
	{
		garage_id: 14,
		part_id: 275,
		quantity: 221,
	},
	{
		garage_id: 14,
		part_id: 276,
		quantity: 175,
	},
	{
		garage_id: 14,
		part_id: 277,
		quantity: 175,
	},
	{
		garage_id: 14,
		part_id: 278,
		quantity: 274,
	},
	{
		garage_id: 14,
		part_id: 279,
		quantity: 301,
	},
	{
		garage_id: 14,
		part_id: 280,
		quantity: 348,
	},
	{
		garage_id: 14,
		part_id: 281,
		quantity: 268,
	},
	{
		garage_id: 14,
		part_id: 282,
		quantity: 261,
	},
	{
		garage_id: 14,
		part_id: 283,
		quantity: 373,
	},
	{
		garage_id: 14,
		part_id: 284,
		quantity: 365,
	},
	{
		garage_id: 14,
		part_id: 285,
		quantity: 142,
	},
	{
		garage_id: 14,
		part_id: 286,
		quantity: 472,
	},
	{
		garage_id: 14,
		part_id: 287,
		quantity: 2,
	},
	{
		garage_id: 14,
		part_id: 288,
		quantity: 381,
	},
	{
		garage_id: 14,
		part_id: 289,
		quantity: 386,
	},
	{
		garage_id: 14,
		part_id: 290,
		quantity: 196,
	},
	{
		garage_id: 14,
		part_id: 291,
		quantity: 293,
	},
	{
		garage_id: 14,
		part_id: 292,
		quantity: 428,
	},
	{
		garage_id: 14,
		part_id: 293,
		quantity: 335,
	},
	{
		garage_id: 14,
		part_id: 294,
		quantity: 406,
	},
	{
		garage_id: 14,
		part_id: 295,
		quantity: 140,
	},
	{
		garage_id: 14,
		part_id: 296,
		quantity: 79,
	},
	{
		garage_id: 14,
		part_id: 297,
		quantity: 369,
	},
	{
		garage_id: 14,
		part_id: 298,
		quantity: 86,
	},
	{
		garage_id: 14,
		part_id: 299,
		quantity: 38,
	},
	{
		garage_id: 14,
		part_id: 300,
		quantity: 273,
	},
	{
		garage_id: 14,
		part_id: 301,
		quantity: 454,
	},
	{
		garage_id: 14,
		part_id: 302,
		quantity: 40,
	},
	{
		garage_id: 14,
		part_id: 303,
		quantity: 115,
	},
	{
		garage_id: 14,
		part_id: 304,
		quantity: 130,
	},
	{
		garage_id: 14,
		part_id: 305,
		quantity: 32,
	},
	{
		garage_id: 14,
		part_id: 306,
		quantity: 495,
	},
	{
		garage_id: 14,
		part_id: 307,
		quantity: 5,
	},
	{
		garage_id: 14,
		part_id: 308,
		quantity: 165,
	},
	{
		garage_id: 14,
		part_id: 309,
		quantity: 466,
	},
	{
		garage_id: 14,
		part_id: 310,
		quantity: 306,
	},
	{
		garage_id: 14,
		part_id: 311,
		quantity: 250,
	},
	{
		garage_id: 14,
		part_id: 312,
		quantity: 13,
	},
	{
		garage_id: 14,
		part_id: 313,
		quantity: 271,
	},
	{
		garage_id: 14,
		part_id: 314,
		quantity: 270,
	},
	{
		garage_id: 14,
		part_id: 315,
		quantity: 39,
	},
	{
		garage_id: 14,
		part_id: 316,
		quantity: 87,
	},
	{
		garage_id: 14,
		part_id: 317,
		quantity: 111,
	},
	{
		garage_id: 14,
		part_id: 318,
		quantity: 325,
	},
	{
		garage_id: 14,
		part_id: 319,
		quantity: 469,
	},
	{
		garage_id: 14,
		part_id: 320,
		quantity: 455,
	},
	{
		garage_id: 14,
		part_id: 321,
		quantity: 397,
	},
	{
		garage_id: 14,
		part_id: 322,
		quantity: 210,
	},
	{
		garage_id: 14,
		part_id: 323,
		quantity: 94,
	},
	{
		garage_id: 14,
		part_id: 324,
		quantity: 397,
	},
	{
		garage_id: 14,
		part_id: 325,
		quantity: 343,
	},
	{
		garage_id: 14,
		part_id: 326,
		quantity: 150,
	},
	{
		garage_id: 14,
		part_id: 327,
		quantity: 184,
	},
	{
		garage_id: 14,
		part_id: 328,
		quantity: 66,
	},
	{
		garage_id: 14,
		part_id: 329,
		quantity: 457,
	},
	{
		garage_id: 14,
		part_id: 330,
		quantity: 230,
	},
	{
		garage_id: 14,
		part_id: 331,
		quantity: 434,
	},
	{
		garage_id: 14,
		part_id: 332,
		quantity: 383,
	},
	{
		garage_id: 14,
		part_id: 333,
		quantity: 388,
	},
	{
		garage_id: 14,
		part_id: 334,
		quantity: 222,
	},
	{
		garage_id: 14,
		part_id: 335,
		quantity: 34,
	},
	{
		garage_id: 14,
		part_id: 336,
		quantity: 8,
	},
	{
		garage_id: 14,
		part_id: 337,
		quantity: 202,
	},
	{
		garage_id: 14,
		part_id: 338,
		quantity: 332,
	},
	{
		garage_id: 14,
		part_id: 339,
		quantity: 168,
	},
	{
		garage_id: 14,
		part_id: 340,
		quantity: 440,
	},
	{
		garage_id: 14,
		part_id: 341,
		quantity: 15,
	},
	{
		garage_id: 14,
		part_id: 342,
		quantity: 435,
	},
	{
		garage_id: 14,
		part_id: 343,
		quantity: 344,
	},
	{
		garage_id: 14,
		part_id: 344,
		quantity: 265,
	},
	{
		garage_id: 14,
		part_id: 345,
		quantity: 252,
	},
	{
		garage_id: 14,
		part_id: 346,
		quantity: 68,
	},
	{
		garage_id: 14,
		part_id: 347,
		quantity: 266,
	},
	{
		garage_id: 14,
		part_id: 348,
		quantity: 29,
	},
	{
		garage_id: 14,
		part_id: 349,
		quantity: 449,
	},
	{
		garage_id: 14,
		part_id: 350,
		quantity: 405,
	},
	{
		garage_id: 14,
		part_id: 351,
		quantity: 1,
	},
	{
		garage_id: 14,
		part_id: 352,
		quantity: 287,
	},
	{
		garage_id: 14,
		part_id: 353,
		quantity: 268,
	},
	{
		garage_id: 14,
		part_id: 354,
		quantity: 358,
	},
	{
		garage_id: 14,
		part_id: 355,
		quantity: 107,
	},
	{
		garage_id: 14,
		part_id: 356,
		quantity: 302,
	},
	{
		garage_id: 14,
		part_id: 357,
		quantity: 426,
	},
	{
		garage_id: 14,
		part_id: 358,
		quantity: 376,
	},
	{
		garage_id: 14,
		part_id: 359,
		quantity: 319,
	},
	{
		garage_id: 14,
		part_id: 360,
		quantity: 99,
	},
	{
		garage_id: 14,
		part_id: 361,
		quantity: 468,
	},
	{
		garage_id: 14,
		part_id: 362,
		quantity: 150,
	},
	{
		garage_id: 14,
		part_id: 363,
		quantity: 362,
	},
	{
		garage_id: 14,
		part_id: 364,
		quantity: 468,
	},
	{
		garage_id: 14,
		part_id: 365,
		quantity: 338,
	},
	{
		garage_id: 14,
		part_id: 366,
		quantity: 482,
	},
	{
		garage_id: 14,
		part_id: 367,
		quantity: 150,
	},
	{
		garage_id: 14,
		part_id: 368,
		quantity: 353,
	},
	{
		garage_id: 14,
		part_id: 369,
		quantity: 244,
	},
	{
		garage_id: 14,
		part_id: 370,
		quantity: 368,
	},
	{
		garage_id: 14,
		part_id: 371,
		quantity: 489,
	},
	{
		garage_id: 14,
		part_id: 372,
		quantity: 447,
	},
	{
		garage_id: 14,
		part_id: 373,
		quantity: 95,
	},
	{
		garage_id: 14,
		part_id: 374,
		quantity: 307,
	},
	{
		garage_id: 14,
		part_id: 375,
		quantity: 499,
	},
	{
		garage_id: 14,
		part_id: 376,
		quantity: 354,
	},
	{
		garage_id: 14,
		part_id: 377,
		quantity: 295,
	},
	{
		garage_id: 14,
		part_id: 378,
		quantity: 316,
	},
	{
		garage_id: 14,
		part_id: 379,
		quantity: 77,
	},
	{
		garage_id: 14,
		part_id: 380,
		quantity: 101,
	},
	{
		garage_id: 14,
		part_id: 381,
		quantity: 102,
	},
	{
		garage_id: 14,
		part_id: 382,
		quantity: 455,
	},
	{
		garage_id: 14,
		part_id: 383,
		quantity: 397,
	},
	{
		garage_id: 14,
		part_id: 384,
		quantity: 169,
	},
	{
		garage_id: 14,
		part_id: 385,
		quantity: 101,
	},
	{
		garage_id: 14,
		part_id: 386,
		quantity: 197,
	},
	{
		garage_id: 14,
		part_id: 387,
		quantity: 454,
	},
	{
		garage_id: 14,
		part_id: 388,
		quantity: 134,
	},
	{
		garage_id: 14,
		part_id: 389,
		quantity: 120,
	},
	{
		garage_id: 14,
		part_id: 390,
		quantity: 266,
	},
	{
		garage_id: 14,
		part_id: 391,
		quantity: 430,
	},
	{
		garage_id: 14,
		part_id: 392,
		quantity: 211,
	},
	{
		garage_id: 14,
		part_id: 393,
		quantity: 44,
	},
	{
		garage_id: 14,
		part_id: 394,
		quantity: 418,
	},
	{
		garage_id: 14,
		part_id: 395,
		quantity: 19,
	},
	{
		garage_id: 14,
		part_id: 396,
		quantity: 361,
	},
	{
		garage_id: 14,
		part_id: 397,
		quantity: 123,
	},
	{
		garage_id: 14,
		part_id: 398,
		quantity: 83,
	},
	{
		garage_id: 14,
		part_id: 399,
		quantity: 500,
	},
	{
		garage_id: 15,
		part_id: 0,
		quantity: 430,
	},
	{
		garage_id: 15,
		part_id: 1,
		quantity: 92,
	},
	{
		garage_id: 15,
		part_id: 2,
		quantity: 166,
	},
	{
		garage_id: 15,
		part_id: 3,
		quantity: 209,
	},
	{
		garage_id: 15,
		part_id: 4,
		quantity: 386,
	},
	{
		garage_id: 15,
		part_id: 5,
		quantity: 151,
	},
	{
		garage_id: 15,
		part_id: 6,
		quantity: 264,
	},
	{
		garage_id: 15,
		part_id: 7,
		quantity: 469,
	},
	{
		garage_id: 15,
		part_id: 8,
		quantity: 360,
	},
	{
		garage_id: 15,
		part_id: 9,
		quantity: 104,
	},
	{
		garage_id: 15,
		part_id: 10,
		quantity: 204,
	},
	{
		garage_id: 15,
		part_id: 11,
		quantity: 271,
	},
	{
		garage_id: 15,
		part_id: 12,
		quantity: 141,
	},
	{
		garage_id: 15,
		part_id: 13,
		quantity: 110,
	},
	{
		garage_id: 15,
		part_id: 14,
		quantity: 245,
	},
	{
		garage_id: 15,
		part_id: 15,
		quantity: 477,
	},
	{
		garage_id: 15,
		part_id: 16,
		quantity: 37,
	},
	{
		garage_id: 15,
		part_id: 17,
		quantity: 352,
	},
	{
		garage_id: 15,
		part_id: 18,
		quantity: 219,
	},
	{
		garage_id: 15,
		part_id: 19,
		quantity: 147,
	},
	{
		garage_id: 15,
		part_id: 20,
		quantity: 18,
	},
	{
		garage_id: 15,
		part_id: 21,
		quantity: 200,
	},
	{
		garage_id: 15,
		part_id: 22,
		quantity: 335,
	},
	{
		garage_id: 15,
		part_id: 23,
		quantity: 37,
	},
	{
		garage_id: 15,
		part_id: 24,
		quantity: 64,
	},
	{
		garage_id: 15,
		part_id: 25,
		quantity: 294,
	},
	{
		garage_id: 15,
		part_id: 26,
		quantity: 129,
	},
	{
		garage_id: 15,
		part_id: 27,
		quantity: 398,
	},
	{
		garage_id: 15,
		part_id: 28,
		quantity: 178,
	},
	{
		garage_id: 15,
		part_id: 29,
		quantity: 58,
	},
	{
		garage_id: 15,
		part_id: 30,
		quantity: 0,
	},
	{
		garage_id: 15,
		part_id: 31,
		quantity: 180,
	},
	{
		garage_id: 15,
		part_id: 32,
		quantity: 173,
	},
	{
		garage_id: 15,
		part_id: 33,
		quantity: 488,
	},
	{
		garage_id: 15,
		part_id: 34,
		quantity: 50,
	},
	{
		garage_id: 15,
		part_id: 35,
		quantity: 239,
	},
	{
		garage_id: 15,
		part_id: 36,
		quantity: 374,
	},
	{
		garage_id: 15,
		part_id: 37,
		quantity: 247,
	},
	{
		garage_id: 15,
		part_id: 38,
		quantity: 408,
	},
	{
		garage_id: 15,
		part_id: 39,
		quantity: 331,
	},
	{
		garage_id: 15,
		part_id: 40,
		quantity: 451,
	},
	{
		garage_id: 15,
		part_id: 41,
		quantity: 494,
	},
	{
		garage_id: 15,
		part_id: 42,
		quantity: 392,
	},
	{
		garage_id: 15,
		part_id: 43,
		quantity: 453,
	},
	{
		garage_id: 15,
		part_id: 44,
		quantity: 168,
	},
	{
		garage_id: 15,
		part_id: 45,
		quantity: 251,
	},
	{
		garage_id: 15,
		part_id: 46,
		quantity: 170,
	},
	{
		garage_id: 15,
		part_id: 47,
		quantity: 72,
	},
	{
		garage_id: 15,
		part_id: 48,
		quantity: 414,
	},
	{
		garage_id: 15,
		part_id: 49,
		quantity: 381,
	},
	{
		garage_id: 15,
		part_id: 50,
		quantity: 180,
	},
	{
		garage_id: 15,
		part_id: 51,
		quantity: 56,
	},
	{
		garage_id: 15,
		part_id: 52,
		quantity: 169,
	},
	{
		garage_id: 15,
		part_id: 53,
		quantity: 222,
	},
	{
		garage_id: 15,
		part_id: 54,
		quantity: 190,
	},
	{
		garage_id: 15,
		part_id: 55,
		quantity: 321,
	},
	{
		garage_id: 15,
		part_id: 56,
		quantity: 218,
	},
	{
		garage_id: 15,
		part_id: 57,
		quantity: 33,
	},
	{
		garage_id: 15,
		part_id: 58,
		quantity: 268,
	},
	{
		garage_id: 15,
		part_id: 59,
		quantity: 77,
	},
	{
		garage_id: 15,
		part_id: 60,
		quantity: 79,
	},
	{
		garage_id: 15,
		part_id: 61,
		quantity: 85,
	},
	{
		garage_id: 15,
		part_id: 62,
		quantity: 344,
	},
	{
		garage_id: 15,
		part_id: 63,
		quantity: 276,
	},
	{
		garage_id: 15,
		part_id: 64,
		quantity: 51,
	},
	{
		garage_id: 15,
		part_id: 65,
		quantity: 4,
	},
	{
		garage_id: 15,
		part_id: 66,
		quantity: 248,
	},
	{
		garage_id: 15,
		part_id: 67,
		quantity: 112,
	},
	{
		garage_id: 15,
		part_id: 68,
		quantity: 97,
	},
	{
		garage_id: 15,
		part_id: 69,
		quantity: 219,
	},
	{
		garage_id: 15,
		part_id: 70,
		quantity: 254,
	},
	{
		garage_id: 15,
		part_id: 71,
		quantity: 96,
	},
	{
		garage_id: 15,
		part_id: 72,
		quantity: 370,
	},
	{
		garage_id: 15,
		part_id: 73,
		quantity: 116,
	},
	{
		garage_id: 15,
		part_id: 74,
		quantity: 96,
	},
	{
		garage_id: 15,
		part_id: 75,
		quantity: 306,
	},
	{
		garage_id: 15,
		part_id: 76,
		quantity: 38,
	},
	{
		garage_id: 15,
		part_id: 77,
		quantity: 69,
	},
	{
		garage_id: 15,
		part_id: 78,
		quantity: 90,
	},
	{
		garage_id: 15,
		part_id: 79,
		quantity: 383,
	},
	{
		garage_id: 15,
		part_id: 80,
		quantity: 411,
	},
	{
		garage_id: 15,
		part_id: 81,
		quantity: 88,
	},
	{
		garage_id: 15,
		part_id: 82,
		quantity: 154,
	},
	{
		garage_id: 15,
		part_id: 83,
		quantity: 195,
	},
	{
		garage_id: 15,
		part_id: 84,
		quantity: 391,
	},
	{
		garage_id: 15,
		part_id: 85,
		quantity: 80,
	},
	{
		garage_id: 15,
		part_id: 86,
		quantity: 243,
	},
	{
		garage_id: 15,
		part_id: 87,
		quantity: 60,
	},
	{
		garage_id: 15,
		part_id: 88,
		quantity: 385,
	},
	{
		garage_id: 15,
		part_id: 89,
		quantity: 278,
	},
	{
		garage_id: 15,
		part_id: 90,
		quantity: 60,
	},
	{
		garage_id: 15,
		part_id: 91,
		quantity: 420,
	},
	{
		garage_id: 15,
		part_id: 92,
		quantity: 53,
	},
	{
		garage_id: 15,
		part_id: 93,
		quantity: 0,
	},
	{
		garage_id: 15,
		part_id: 94,
		quantity: 29,
	},
	{
		garage_id: 15,
		part_id: 95,
		quantity: 443,
	},
	{
		garage_id: 15,
		part_id: 96,
		quantity: 497,
	},
	{
		garage_id: 15,
		part_id: 97,
		quantity: 407,
	},
	{
		garage_id: 15,
		part_id: 98,
		quantity: 327,
	},
	{
		garage_id: 15,
		part_id: 99,
		quantity: 449,
	},
	{
		garage_id: 15,
		part_id: 100,
		quantity: 241,
	},
	{
		garage_id: 15,
		part_id: 101,
		quantity: 50,
	},
	{
		garage_id: 15,
		part_id: 102,
		quantity: 352,
	},
	{
		garage_id: 15,
		part_id: 103,
		quantity: 253,
	},
	{
		garage_id: 15,
		part_id: 104,
		quantity: 301,
	},
	{
		garage_id: 15,
		part_id: 105,
		quantity: 45,
	},
	{
		garage_id: 15,
		part_id: 106,
		quantity: 235,
	},
	{
		garage_id: 15,
		part_id: 107,
		quantity: 12,
	},
	{
		garage_id: 15,
		part_id: 108,
		quantity: 497,
	},
	{
		garage_id: 15,
		part_id: 109,
		quantity: 246,
	},
	{
		garage_id: 15,
		part_id: 110,
		quantity: 314,
	},
	{
		garage_id: 15,
		part_id: 111,
		quantity: 190,
	},
	{
		garage_id: 15,
		part_id: 112,
		quantity: 383,
	},
	{
		garage_id: 15,
		part_id: 113,
		quantity: 470,
	},
	{
		garage_id: 15,
		part_id: 114,
		quantity: 327,
	},
	{
		garage_id: 15,
		part_id: 115,
		quantity: 378,
	},
	{
		garage_id: 15,
		part_id: 116,
		quantity: 357,
	},
	{
		garage_id: 15,
		part_id: 117,
		quantity: 117,
	},
	{
		garage_id: 15,
		part_id: 118,
		quantity: 363,
	},
	{
		garage_id: 15,
		part_id: 119,
		quantity: 219,
	},
	{
		garage_id: 15,
		part_id: 120,
		quantity: 286,
	},
	{
		garage_id: 15,
		part_id: 121,
		quantity: 226,
	},
	{
		garage_id: 15,
		part_id: 122,
		quantity: 390,
	},
	{
		garage_id: 15,
		part_id: 123,
		quantity: 11,
	},
	{
		garage_id: 15,
		part_id: 124,
		quantity: 67,
	},
	{
		garage_id: 15,
		part_id: 125,
		quantity: 371,
	},
	{
		garage_id: 15,
		part_id: 126,
		quantity: 269,
	},
	{
		garage_id: 15,
		part_id: 127,
		quantity: 371,
	},
	{
		garage_id: 15,
		part_id: 128,
		quantity: 272,
	},
	{
		garage_id: 15,
		part_id: 129,
		quantity: 300,
	},
	{
		garage_id: 15,
		part_id: 130,
		quantity: 248,
	},
	{
		garage_id: 15,
		part_id: 131,
		quantity: 237,
	},
	{
		garage_id: 15,
		part_id: 132,
		quantity: 247,
	},
	{
		garage_id: 15,
		part_id: 133,
		quantity: 309,
	},
	{
		garage_id: 15,
		part_id: 134,
		quantity: 246,
	},
	{
		garage_id: 15,
		part_id: 135,
		quantity: 494,
	},
	{
		garage_id: 15,
		part_id: 136,
		quantity: 326,
	},
	{
		garage_id: 15,
		part_id: 137,
		quantity: 206,
	},
	{
		garage_id: 15,
		part_id: 138,
		quantity: 240,
	},
	{
		garage_id: 15,
		part_id: 139,
		quantity: 185,
	},
	{
		garage_id: 15,
		part_id: 140,
		quantity: 395,
	},
	{
		garage_id: 15,
		part_id: 141,
		quantity: 25,
	},
	{
		garage_id: 15,
		part_id: 142,
		quantity: 364,
	},
	{
		garage_id: 15,
		part_id: 143,
		quantity: 169,
	},
	{
		garage_id: 15,
		part_id: 144,
		quantity: 218,
	},
	{
		garage_id: 15,
		part_id: 145,
		quantity: 482,
	},
	{
		garage_id: 15,
		part_id: 146,
		quantity: 464,
	},
	{
		garage_id: 15,
		part_id: 147,
		quantity: 253,
	},
	{
		garage_id: 15,
		part_id: 148,
		quantity: 164,
	},
	{
		garage_id: 15,
		part_id: 149,
		quantity: 357,
	},
	{
		garage_id: 15,
		part_id: 150,
		quantity: 119,
	},
	{
		garage_id: 15,
		part_id: 151,
		quantity: 326,
	},
	{
		garage_id: 15,
		part_id: 152,
		quantity: 310,
	},
	{
		garage_id: 15,
		part_id: 153,
		quantity: 189,
	},
	{
		garage_id: 15,
		part_id: 154,
		quantity: 455,
	},
	{
		garage_id: 15,
		part_id: 155,
		quantity: 66,
	},
	{
		garage_id: 15,
		part_id: 156,
		quantity: 250,
	},
	{
		garage_id: 15,
		part_id: 157,
		quantity: 148,
	},
	{
		garage_id: 15,
		part_id: 158,
		quantity: 173,
	},
	{
		garage_id: 15,
		part_id: 159,
		quantity: 1,
	},
	{
		garage_id: 15,
		part_id: 160,
		quantity: 99,
	},
	{
		garage_id: 15,
		part_id: 161,
		quantity: 197,
	},
	{
		garage_id: 15,
		part_id: 162,
		quantity: 109,
	},
	{
		garage_id: 15,
		part_id: 163,
		quantity: 363,
	},
	{
		garage_id: 15,
		part_id: 164,
		quantity: 174,
	},
	{
		garage_id: 15,
		part_id: 165,
		quantity: 100,
	},
	{
		garage_id: 15,
		part_id: 166,
		quantity: 458,
	},
	{
		garage_id: 15,
		part_id: 167,
		quantity: 93,
	},
	{
		garage_id: 15,
		part_id: 168,
		quantity: 151,
	},
	{
		garage_id: 15,
		part_id: 169,
		quantity: 103,
	},
	{
		garage_id: 15,
		part_id: 170,
		quantity: 286,
	},
	{
		garage_id: 15,
		part_id: 171,
		quantity: 133,
	},
	{
		garage_id: 15,
		part_id: 172,
		quantity: 163,
	},
	{
		garage_id: 15,
		part_id: 173,
		quantity: 234,
	},
	{
		garage_id: 15,
		part_id: 174,
		quantity: 59,
	},
	{
		garage_id: 15,
		part_id: 175,
		quantity: 48,
	},
	{
		garage_id: 15,
		part_id: 176,
		quantity: 489,
	},
	{
		garage_id: 15,
		part_id: 177,
		quantity: 423,
	},
	{
		garage_id: 15,
		part_id: 178,
		quantity: 196,
	},
	{
		garage_id: 15,
		part_id: 179,
		quantity: 436,
	},
	{
		garage_id: 15,
		part_id: 180,
		quantity: 97,
	},
	{
		garage_id: 15,
		part_id: 181,
		quantity: 241,
	},
	{
		garage_id: 15,
		part_id: 182,
		quantity: 332,
	},
	{
		garage_id: 15,
		part_id: 183,
		quantity: 476,
	},
	{
		garage_id: 15,
		part_id: 184,
		quantity: 28,
	},
	{
		garage_id: 15,
		part_id: 185,
		quantity: 450,
	},
	{
		garage_id: 15,
		part_id: 186,
		quantity: 331,
	},
	{
		garage_id: 15,
		part_id: 187,
		quantity: 428,
	},
	{
		garage_id: 15,
		part_id: 188,
		quantity: 405,
	},
	{
		garage_id: 15,
		part_id: 189,
		quantity: 498,
	},
	{
		garage_id: 15,
		part_id: 190,
		quantity: 124,
	},
	{
		garage_id: 15,
		part_id: 191,
		quantity: 294,
	},
	{
		garage_id: 15,
		part_id: 192,
		quantity: 494,
	},
	{
		garage_id: 15,
		part_id: 193,
		quantity: 178,
	},
	{
		garage_id: 15,
		part_id: 194,
		quantity: 128,
	},
	{
		garage_id: 15,
		part_id: 195,
		quantity: 460,
	},
	{
		garage_id: 15,
		part_id: 196,
		quantity: 434,
	},
	{
		garage_id: 15,
		part_id: 197,
		quantity: 256,
	},
	{
		garage_id: 15,
		part_id: 198,
		quantity: 101,
	},
	{
		garage_id: 15,
		part_id: 199,
		quantity: 494,
	},
	{
		garage_id: 15,
		part_id: 200,
		quantity: 90,
	},
	{
		garage_id: 15,
		part_id: 201,
		quantity: 466,
	},
	{
		garage_id: 15,
		part_id: 202,
		quantity: 324,
	},
	{
		garage_id: 15,
		part_id: 203,
		quantity: 159,
	},
	{
		garage_id: 15,
		part_id: 204,
		quantity: 292,
	},
	{
		garage_id: 15,
		part_id: 205,
		quantity: 498,
	},
	{
		garage_id: 15,
		part_id: 206,
		quantity: 77,
	},
	{
		garage_id: 15,
		part_id: 207,
		quantity: 105,
	},
	{
		garage_id: 15,
		part_id: 208,
		quantity: 414,
	},
	{
		garage_id: 15,
		part_id: 209,
		quantity: 217,
	},
	{
		garage_id: 15,
		part_id: 210,
		quantity: 228,
	},
	{
		garage_id: 15,
		part_id: 211,
		quantity: 293,
	},
	{
		garage_id: 15,
		part_id: 212,
		quantity: 480,
	},
	{
		garage_id: 15,
		part_id: 213,
		quantity: 4,
	},
	{
		garage_id: 15,
		part_id: 214,
		quantity: 487,
	},
	{
		garage_id: 15,
		part_id: 215,
		quantity: 272,
	},
	{
		garage_id: 15,
		part_id: 216,
		quantity: 82,
	},
	{
		garage_id: 15,
		part_id: 217,
		quantity: 51,
	},
	{
		garage_id: 15,
		part_id: 218,
		quantity: 36,
	},
	{
		garage_id: 15,
		part_id: 219,
		quantity: 92,
	},
	{
		garage_id: 15,
		part_id: 220,
		quantity: 225,
	},
	{
		garage_id: 15,
		part_id: 221,
		quantity: 76,
	},
	{
		garage_id: 15,
		part_id: 222,
		quantity: 303,
	},
	{
		garage_id: 15,
		part_id: 223,
		quantity: 104,
	},
	{
		garage_id: 15,
		part_id: 224,
		quantity: 292,
	},
	{
		garage_id: 15,
		part_id: 225,
		quantity: 128,
	},
	{
		garage_id: 15,
		part_id: 226,
		quantity: 60,
	},
	{
		garage_id: 15,
		part_id: 227,
		quantity: 448,
	},
	{
		garage_id: 15,
		part_id: 228,
		quantity: 153,
	},
	{
		garage_id: 15,
		part_id: 229,
		quantity: 195,
	},
	{
		garage_id: 15,
		part_id: 230,
		quantity: 388,
	},
	{
		garage_id: 15,
		part_id: 231,
		quantity: 75,
	},
	{
		garage_id: 15,
		part_id: 232,
		quantity: 229,
	},
	{
		garage_id: 15,
		part_id: 233,
		quantity: 208,
	},
	{
		garage_id: 15,
		part_id: 234,
		quantity: 22,
	},
	{
		garage_id: 15,
		part_id: 235,
		quantity: 85,
	},
	{
		garage_id: 15,
		part_id: 236,
		quantity: 282,
	},
	{
		garage_id: 15,
		part_id: 237,
		quantity: 456,
	},
	{
		garage_id: 15,
		part_id: 238,
		quantity: 0,
	},
	{
		garage_id: 15,
		part_id: 239,
		quantity: 349,
	},
	{
		garage_id: 15,
		part_id: 240,
		quantity: 155,
	},
	{
		garage_id: 15,
		part_id: 241,
		quantity: 101,
	},
	{
		garage_id: 15,
		part_id: 242,
		quantity: 492,
	},
	{
		garage_id: 15,
		part_id: 243,
		quantity: 383,
	},
	{
		garage_id: 15,
		part_id: 244,
		quantity: 10,
	},
	{
		garage_id: 15,
		part_id: 245,
		quantity: 496,
	},
	{
		garage_id: 15,
		part_id: 246,
		quantity: 201,
	},
	{
		garage_id: 15,
		part_id: 247,
		quantity: 377,
	},
	{
		garage_id: 15,
		part_id: 248,
		quantity: 206,
	},
	{
		garage_id: 15,
		part_id: 249,
		quantity: 494,
	},
	{
		garage_id: 15,
		part_id: 250,
		quantity: 398,
	},
	{
		garage_id: 15,
		part_id: 251,
		quantity: 120,
	},
	{
		garage_id: 15,
		part_id: 252,
		quantity: 260,
	},
	{
		garage_id: 15,
		part_id: 253,
		quantity: 459,
	},
	{
		garage_id: 15,
		part_id: 254,
		quantity: 33,
	},
	{
		garage_id: 15,
		part_id: 255,
		quantity: 252,
	},
	{
		garage_id: 15,
		part_id: 256,
		quantity: 233,
	},
	{
		garage_id: 15,
		part_id: 257,
		quantity: 277,
	},
	{
		garage_id: 15,
		part_id: 258,
		quantity: 32,
	},
	{
		garage_id: 15,
		part_id: 259,
		quantity: 243,
	},
	{
		garage_id: 15,
		part_id: 260,
		quantity: 460,
	},
	{
		garage_id: 15,
		part_id: 261,
		quantity: 10,
	},
	{
		garage_id: 15,
		part_id: 262,
		quantity: 407,
	},
	{
		garage_id: 15,
		part_id: 263,
		quantity: 105,
	},
	{
		garage_id: 15,
		part_id: 264,
		quantity: 348,
	},
	{
		garage_id: 15,
		part_id: 265,
		quantity: 447,
	},
	{
		garage_id: 15,
		part_id: 266,
		quantity: 480,
	},
	{
		garage_id: 15,
		part_id: 267,
		quantity: 47,
	},
	{
		garage_id: 15,
		part_id: 268,
		quantity: 131,
	},
	{
		garage_id: 15,
		part_id: 269,
		quantity: 496,
	},
	{
		garage_id: 15,
		part_id: 270,
		quantity: 185,
	},
	{
		garage_id: 15,
		part_id: 271,
		quantity: 388,
	},
	{
		garage_id: 15,
		part_id: 272,
		quantity: 417,
	},
	{
		garage_id: 15,
		part_id: 273,
		quantity: 39,
	},
	{
		garage_id: 15,
		part_id: 274,
		quantity: 256,
	},
	{
		garage_id: 15,
		part_id: 275,
		quantity: 327,
	},
	{
		garage_id: 15,
		part_id: 276,
		quantity: 298,
	},
	{
		garage_id: 15,
		part_id: 277,
		quantity: 485,
	},
	{
		garage_id: 15,
		part_id: 278,
		quantity: 44,
	},
	{
		garage_id: 15,
		part_id: 279,
		quantity: 218,
	},
	{
		garage_id: 15,
		part_id: 280,
		quantity: 277,
	},
	{
		garage_id: 15,
		part_id: 281,
		quantity: 215,
	},
	{
		garage_id: 15,
		part_id: 282,
		quantity: 245,
	},
	{
		garage_id: 15,
		part_id: 283,
		quantity: 488,
	},
	{
		garage_id: 15,
		part_id: 284,
		quantity: 350,
	},
	{
		garage_id: 15,
		part_id: 285,
		quantity: 313,
	},
	{
		garage_id: 15,
		part_id: 286,
		quantity: 194,
	},
	{
		garage_id: 15,
		part_id: 287,
		quantity: 251,
	},
	{
		garage_id: 15,
		part_id: 288,
		quantity: 44,
	},
	{
		garage_id: 15,
		part_id: 289,
		quantity: 379,
	},
	{
		garage_id: 15,
		part_id: 290,
		quantity: 117,
	},
	{
		garage_id: 15,
		part_id: 291,
		quantity: 64,
	},
	{
		garage_id: 15,
		part_id: 292,
		quantity: 447,
	},
	{
		garage_id: 15,
		part_id: 293,
		quantity: 358,
	},
	{
		garage_id: 15,
		part_id: 294,
		quantity: 181,
	},
	{
		garage_id: 15,
		part_id: 295,
		quantity: 462,
	},
	{
		garage_id: 15,
		part_id: 296,
		quantity: 465,
	},
	{
		garage_id: 15,
		part_id: 297,
		quantity: 205,
	},
	{
		garage_id: 15,
		part_id: 298,
		quantity: 85,
	},
	{
		garage_id: 15,
		part_id: 299,
		quantity: 407,
	},
	{
		garage_id: 15,
		part_id: 300,
		quantity: 319,
	},
	{
		garage_id: 15,
		part_id: 301,
		quantity: 74,
	},
	{
		garage_id: 15,
		part_id: 302,
		quantity: 355,
	},
	{
		garage_id: 15,
		part_id: 303,
		quantity: 269,
	},
	{
		garage_id: 15,
		part_id: 304,
		quantity: 300,
	},
	{
		garage_id: 15,
		part_id: 305,
		quantity: 402,
	},
	{
		garage_id: 15,
		part_id: 306,
		quantity: 68,
	},
	{
		garage_id: 15,
		part_id: 307,
		quantity: 122,
	},
	{
		garage_id: 15,
		part_id: 308,
		quantity: 338,
	},
	{
		garage_id: 15,
		part_id: 309,
		quantity: 248,
	},
	{
		garage_id: 15,
		part_id: 310,
		quantity: 32,
	},
	{
		garage_id: 15,
		part_id: 311,
		quantity: 333,
	},
	{
		garage_id: 15,
		part_id: 312,
		quantity: 456,
	},
	{
		garage_id: 15,
		part_id: 313,
		quantity: 20,
	},
	{
		garage_id: 15,
		part_id: 314,
		quantity: 201,
	},
	{
		garage_id: 15,
		part_id: 315,
		quantity: 0,
	},
	{
		garage_id: 15,
		part_id: 316,
		quantity: 112,
	},
	{
		garage_id: 15,
		part_id: 317,
		quantity: 308,
	},
	{
		garage_id: 15,
		part_id: 318,
		quantity: 329,
	},
	{
		garage_id: 15,
		part_id: 319,
		quantity: 433,
	},
	{
		garage_id: 15,
		part_id: 320,
		quantity: 353,
	},
	{
		garage_id: 15,
		part_id: 321,
		quantity: 492,
	},
	{
		garage_id: 15,
		part_id: 322,
		quantity: 476,
	},
	{
		garage_id: 15,
		part_id: 323,
		quantity: 148,
	},
	{
		garage_id: 15,
		part_id: 324,
		quantity: 305,
	},
	{
		garage_id: 15,
		part_id: 325,
		quantity: 83,
	},
	{
		garage_id: 15,
		part_id: 326,
		quantity: 446,
	},
	{
		garage_id: 15,
		part_id: 327,
		quantity: 406,
	},
	{
		garage_id: 15,
		part_id: 328,
		quantity: 161,
	},
	{
		garage_id: 15,
		part_id: 329,
		quantity: 283,
	},
	{
		garage_id: 15,
		part_id: 330,
		quantity: 110,
	},
	{
		garage_id: 15,
		part_id: 331,
		quantity: 404,
	},
	{
		garage_id: 15,
		part_id: 332,
		quantity: 417,
	},
	{
		garage_id: 15,
		part_id: 333,
		quantity: 295,
	},
	{
		garage_id: 15,
		part_id: 334,
		quantity: 62,
	},
	{
		garage_id: 15,
		part_id: 335,
		quantity: 266,
	},
	{
		garage_id: 15,
		part_id: 336,
		quantity: 15,
	},
	{
		garage_id: 15,
		part_id: 337,
		quantity: 248,
	},
	{
		garage_id: 15,
		part_id: 338,
		quantity: 119,
	},
	{
		garage_id: 15,
		part_id: 339,
		quantity: 451,
	},
	{
		garage_id: 15,
		part_id: 340,
		quantity: 68,
	},
	{
		garage_id: 15,
		part_id: 341,
		quantity: 24,
	},
	{
		garage_id: 15,
		part_id: 342,
		quantity: 479,
	},
	{
		garage_id: 15,
		part_id: 343,
		quantity: 307,
	},
	{
		garage_id: 15,
		part_id: 344,
		quantity: 308,
	},
	{
		garage_id: 15,
		part_id: 345,
		quantity: 287,
	},
	{
		garage_id: 15,
		part_id: 346,
		quantity: 458,
	},
	{
		garage_id: 15,
		part_id: 347,
		quantity: 15,
	},
	{
		garage_id: 15,
		part_id: 348,
		quantity: 331,
	},
	{
		garage_id: 15,
		part_id: 349,
		quantity: 336,
	},
	{
		garage_id: 15,
		part_id: 350,
		quantity: 237,
	},
	{
		garage_id: 15,
		part_id: 351,
		quantity: 41,
	},
	{
		garage_id: 15,
		part_id: 352,
		quantity: 128,
	},
	{
		garage_id: 15,
		part_id: 353,
		quantity: 271,
	},
	{
		garage_id: 15,
		part_id: 354,
		quantity: 272,
	},
	{
		garage_id: 15,
		part_id: 355,
		quantity: 492,
	},
	{
		garage_id: 15,
		part_id: 356,
		quantity: 462,
	},
	{
		garage_id: 15,
		part_id: 357,
		quantity: 336,
	},
	{
		garage_id: 15,
		part_id: 358,
		quantity: 157,
	},
	{
		garage_id: 15,
		part_id: 359,
		quantity: 382,
	},
	{
		garage_id: 15,
		part_id: 360,
		quantity: 351,
	},
	{
		garage_id: 15,
		part_id: 361,
		quantity: 211,
	},
	{
		garage_id: 15,
		part_id: 362,
		quantity: 216,
	},
	{
		garage_id: 15,
		part_id: 363,
		quantity: 11,
	},
	{
		garage_id: 15,
		part_id: 364,
		quantity: 218,
	},
	{
		garage_id: 15,
		part_id: 365,
		quantity: 44,
	},
	{
		garage_id: 15,
		part_id: 366,
		quantity: 413,
	},
	{
		garage_id: 15,
		part_id: 367,
		quantity: 413,
	},
	{
		garage_id: 15,
		part_id: 368,
		quantity: 82,
	},
	{
		garage_id: 15,
		part_id: 369,
		quantity: 138,
	},
	{
		garage_id: 15,
		part_id: 370,
		quantity: 361,
	},
	{
		garage_id: 15,
		part_id: 371,
		quantity: 15,
	},
	{
		garage_id: 15,
		part_id: 372,
		quantity: 172,
	},
	{
		garage_id: 15,
		part_id: 373,
		quantity: 207,
	},
	{
		garage_id: 15,
		part_id: 374,
		quantity: 257,
	},
	{
		garage_id: 15,
		part_id: 375,
		quantity: 239,
	},
	{
		garage_id: 15,
		part_id: 376,
		quantity: 193,
	},
	{
		garage_id: 15,
		part_id: 377,
		quantity: 13,
	},
	{
		garage_id: 15,
		part_id: 378,
		quantity: 314,
	},
	{
		garage_id: 15,
		part_id: 379,
		quantity: 130,
	},
	{
		garage_id: 15,
		part_id: 380,
		quantity: 240,
	},
	{
		garage_id: 15,
		part_id: 381,
		quantity: 157,
	},
	{
		garage_id: 15,
		part_id: 382,
		quantity: 81,
	},
	{
		garage_id: 15,
		part_id: 383,
		quantity: 105,
	},
	{
		garage_id: 15,
		part_id: 384,
		quantity: 340,
	},
	{
		garage_id: 15,
		part_id: 385,
		quantity: 2,
	},
	{
		garage_id: 15,
		part_id: 386,
		quantity: 194,
	},
	{
		garage_id: 15,
		part_id: 387,
		quantity: 465,
	},
	{
		garage_id: 15,
		part_id: 388,
		quantity: 178,
	},
	{
		garage_id: 15,
		part_id: 389,
		quantity: 131,
	},
	{
		garage_id: 15,
		part_id: 390,
		quantity: 430,
	},
	{
		garage_id: 15,
		part_id: 391,
		quantity: 445,
	},
	{
		garage_id: 15,
		part_id: 392,
		quantity: 350,
	},
	{
		garage_id: 15,
		part_id: 393,
		quantity: 340,
	},
	{
		garage_id: 15,
		part_id: 394,
		quantity: 211,
	},
	{
		garage_id: 15,
		part_id: 395,
		quantity: 112,
	},
	{
		garage_id: 15,
		part_id: 396,
		quantity: 468,
	},
	{
		garage_id: 15,
		part_id: 397,
		quantity: 382,
	},
	{
		garage_id: 15,
		part_id: 398,
		quantity: 290,
	},
	{
		garage_id: 15,
		part_id: 399,
		quantity: 192,
	},
	{
		garage_id: 16,
		part_id: 0,
		quantity: 6,
	},
	{
		garage_id: 16,
		part_id: 1,
		quantity: 478,
	},
	{
		garage_id: 16,
		part_id: 2,
		quantity: 350,
	},
	{
		garage_id: 16,
		part_id: 3,
		quantity: 317,
	},
	{
		garage_id: 16,
		part_id: 4,
		quantity: 463,
	},
	{
		garage_id: 16,
		part_id: 5,
		quantity: 243,
	},
	{
		garage_id: 16,
		part_id: 6,
		quantity: 295,
	},
	{
		garage_id: 16,
		part_id: 7,
		quantity: 175,
	},
	{
		garage_id: 16,
		part_id: 8,
		quantity: 79,
	},
	{
		garage_id: 16,
		part_id: 9,
		quantity: 398,
	},
	{
		garage_id: 16,
		part_id: 10,
		quantity: 331,
	},
	{
		garage_id: 16,
		part_id: 11,
		quantity: 185,
	},
	{
		garage_id: 16,
		part_id: 12,
		quantity: 457,
	},
	{
		garage_id: 16,
		part_id: 13,
		quantity: 182,
	},
	{
		garage_id: 16,
		part_id: 14,
		quantity: 93,
	},
	{
		garage_id: 16,
		part_id: 15,
		quantity: 38,
	},
	{
		garage_id: 16,
		part_id: 16,
		quantity: 159,
	},
	{
		garage_id: 16,
		part_id: 17,
		quantity: 439,
	},
	{
		garage_id: 16,
		part_id: 18,
		quantity: 84,
	},
	{
		garage_id: 16,
		part_id: 19,
		quantity: 381,
	},
	{
		garage_id: 16,
		part_id: 20,
		quantity: 470,
	},
	{
		garage_id: 16,
		part_id: 21,
		quantity: 233,
	},
	{
		garage_id: 16,
		part_id: 22,
		quantity: 260,
	},
	{
		garage_id: 16,
		part_id: 23,
		quantity: 187,
	},
	{
		garage_id: 16,
		part_id: 24,
		quantity: 62,
	},
	{
		garage_id: 16,
		part_id: 25,
		quantity: 480,
	},
	{
		garage_id: 16,
		part_id: 26,
		quantity: 79,
	},
	{
		garage_id: 16,
		part_id: 27,
		quantity: 161,
	},
	{
		garage_id: 16,
		part_id: 28,
		quantity: 311,
	},
	{
		garage_id: 16,
		part_id: 29,
		quantity: 1,
	},
	{
		garage_id: 16,
		part_id: 30,
		quantity: 21,
	},
	{
		garage_id: 16,
		part_id: 31,
		quantity: 374,
	},
	{
		garage_id: 16,
		part_id: 32,
		quantity: 245,
	},
	{
		garage_id: 16,
		part_id: 33,
		quantity: 475,
	},
	{
		garage_id: 16,
		part_id: 34,
		quantity: 59,
	},
	{
		garage_id: 16,
		part_id: 35,
		quantity: 23,
	},
	{
		garage_id: 16,
		part_id: 36,
		quantity: 305,
	},
	{
		garage_id: 16,
		part_id: 37,
		quantity: 324,
	},
	{
		garage_id: 16,
		part_id: 38,
		quantity: 255,
	},
	{
		garage_id: 16,
		part_id: 39,
		quantity: 82,
	},
	{
		garage_id: 16,
		part_id: 40,
		quantity: 261,
	},
	{
		garage_id: 16,
		part_id: 41,
		quantity: 475,
	},
	{
		garage_id: 16,
		part_id: 42,
		quantity: 131,
	},
	{
		garage_id: 16,
		part_id: 43,
		quantity: 41,
	},
	{
		garage_id: 16,
		part_id: 44,
		quantity: 253,
	},
	{
		garage_id: 16,
		part_id: 45,
		quantity: 117,
	},
	{
		garage_id: 16,
		part_id: 46,
		quantity: 196,
	},
	{
		garage_id: 16,
		part_id: 47,
		quantity: 75,
	},
	{
		garage_id: 16,
		part_id: 48,
		quantity: 446,
	},
	{
		garage_id: 16,
		part_id: 49,
		quantity: 257,
	},
	{
		garage_id: 16,
		part_id: 50,
		quantity: 433,
	},
	{
		garage_id: 16,
		part_id: 51,
		quantity: 27,
	},
	{
		garage_id: 16,
		part_id: 52,
		quantity: 173,
	},
	{
		garage_id: 16,
		part_id: 53,
		quantity: 109,
	},
	{
		garage_id: 16,
		part_id: 54,
		quantity: 348,
	},
	{
		garage_id: 16,
		part_id: 55,
		quantity: 462,
	},
	{
		garage_id: 16,
		part_id: 56,
		quantity: 355,
	},
	{
		garage_id: 16,
		part_id: 57,
		quantity: 413,
	},
	{
		garage_id: 16,
		part_id: 58,
		quantity: 381,
	},
	{
		garage_id: 16,
		part_id: 59,
		quantity: 72,
	},
	{
		garage_id: 16,
		part_id: 60,
		quantity: 154,
	},
	{
		garage_id: 16,
		part_id: 61,
		quantity: 98,
	},
	{
		garage_id: 16,
		part_id: 62,
		quantity: 260,
	},
	{
		garage_id: 16,
		part_id: 63,
		quantity: 21,
	},
	{
		garage_id: 16,
		part_id: 64,
		quantity: 26,
	},
	{
		garage_id: 16,
		part_id: 65,
		quantity: 148,
	},
	{
		garage_id: 16,
		part_id: 66,
		quantity: 207,
	},
	{
		garage_id: 16,
		part_id: 67,
		quantity: 320,
	},
	{
		garage_id: 16,
		part_id: 68,
		quantity: 4,
	},
	{
		garage_id: 16,
		part_id: 69,
		quantity: 244,
	},
	{
		garage_id: 16,
		part_id: 70,
		quantity: 176,
	},
	{
		garage_id: 16,
		part_id: 71,
		quantity: 322,
	},
	{
		garage_id: 16,
		part_id: 72,
		quantity: 255,
	},
	{
		garage_id: 16,
		part_id: 73,
		quantity: 51,
	},
	{
		garage_id: 16,
		part_id: 74,
		quantity: 303,
	},
	{
		garage_id: 16,
		part_id: 75,
		quantity: 200,
	},
	{
		garage_id: 16,
		part_id: 76,
		quantity: 362,
	},
	{
		garage_id: 16,
		part_id: 77,
		quantity: 277,
	},
	{
		garage_id: 16,
		part_id: 78,
		quantity: 213,
	},
	{
		garage_id: 16,
		part_id: 79,
		quantity: 56,
	},
	{
		garage_id: 16,
		part_id: 80,
		quantity: 228,
	},
	{
		garage_id: 16,
		part_id: 81,
		quantity: 488,
	},
	{
		garage_id: 16,
		part_id: 82,
		quantity: 455,
	},
	{
		garage_id: 16,
		part_id: 83,
		quantity: 484,
	},
	{
		garage_id: 16,
		part_id: 84,
		quantity: 212,
	},
	{
		garage_id: 16,
		part_id: 85,
		quantity: 362,
	},
	{
		garage_id: 16,
		part_id: 86,
		quantity: 229,
	},
	{
		garage_id: 16,
		part_id: 87,
		quantity: 18,
	},
	{
		garage_id: 16,
		part_id: 88,
		quantity: 142,
	},
	{
		garage_id: 16,
		part_id: 89,
		quantity: 119,
	},
	{
		garage_id: 16,
		part_id: 90,
		quantity: 312,
	},
	{
		garage_id: 16,
		part_id: 91,
		quantity: 321,
	},
	{
		garage_id: 16,
		part_id: 92,
		quantity: 50,
	},
	{
		garage_id: 16,
		part_id: 93,
		quantity: 420,
	},
	{
		garage_id: 16,
		part_id: 94,
		quantity: 480,
	},
	{
		garage_id: 16,
		part_id: 95,
		quantity: 108,
	},
	{
		garage_id: 16,
		part_id: 96,
		quantity: 465,
	},
	{
		garage_id: 16,
		part_id: 97,
		quantity: 68,
	},
	{
		garage_id: 16,
		part_id: 98,
		quantity: 415,
	},
	{
		garage_id: 16,
		part_id: 99,
		quantity: 149,
	},
	{
		garage_id: 16,
		part_id: 100,
		quantity: 404,
	},
	{
		garage_id: 16,
		part_id: 101,
		quantity: 357,
	},
	{
		garage_id: 16,
		part_id: 102,
		quantity: 366,
	},
	{
		garage_id: 16,
		part_id: 103,
		quantity: 419,
	},
	{
		garage_id: 16,
		part_id: 104,
		quantity: 218,
	},
	{
		garage_id: 16,
		part_id: 105,
		quantity: 254,
	},
	{
		garage_id: 16,
		part_id: 106,
		quantity: 403,
	},
	{
		garage_id: 16,
		part_id: 107,
		quantity: 206,
	},
	{
		garage_id: 16,
		part_id: 108,
		quantity: 425,
	},
	{
		garage_id: 16,
		part_id: 109,
		quantity: 166,
	},
	{
		garage_id: 16,
		part_id: 110,
		quantity: 259,
	},
	{
		garage_id: 16,
		part_id: 111,
		quantity: 41,
	},
	{
		garage_id: 16,
		part_id: 112,
		quantity: 406,
	},
	{
		garage_id: 16,
		part_id: 113,
		quantity: 411,
	},
	{
		garage_id: 16,
		part_id: 114,
		quantity: 238,
	},
	{
		garage_id: 16,
		part_id: 115,
		quantity: 296,
	},
	{
		garage_id: 16,
		part_id: 116,
		quantity: 292,
	},
	{
		garage_id: 16,
		part_id: 117,
		quantity: 146,
	},
	{
		garage_id: 16,
		part_id: 118,
		quantity: 256,
	},
	{
		garage_id: 16,
		part_id: 119,
		quantity: 296,
	},
	{
		garage_id: 16,
		part_id: 120,
		quantity: 317,
	},
	{
		garage_id: 16,
		part_id: 121,
		quantity: 162,
	},
	{
		garage_id: 16,
		part_id: 122,
		quantity: 132,
	},
	{
		garage_id: 16,
		part_id: 123,
		quantity: 176,
	},
	{
		garage_id: 16,
		part_id: 124,
		quantity: 322,
	},
	{
		garage_id: 16,
		part_id: 125,
		quantity: 146,
	},
	{
		garage_id: 16,
		part_id: 126,
		quantity: 460,
	},
	{
		garage_id: 16,
		part_id: 127,
		quantity: 266,
	},
	{
		garage_id: 16,
		part_id: 128,
		quantity: 408,
	},
	{
		garage_id: 16,
		part_id: 129,
		quantity: 232,
	},
	{
		garage_id: 16,
		part_id: 130,
		quantity: 360,
	},
	{
		garage_id: 16,
		part_id: 131,
		quantity: 313,
	},
	{
		garage_id: 16,
		part_id: 132,
		quantity: 393,
	},
	{
		garage_id: 16,
		part_id: 133,
		quantity: 77,
	},
	{
		garage_id: 16,
		part_id: 134,
		quantity: 370,
	},
	{
		garage_id: 16,
		part_id: 135,
		quantity: 169,
	},
	{
		garage_id: 16,
		part_id: 136,
		quantity: 114,
	},
	{
		garage_id: 16,
		part_id: 137,
		quantity: 437,
	},
	{
		garage_id: 16,
		part_id: 138,
		quantity: 439,
	},
	{
		garage_id: 16,
		part_id: 139,
		quantity: 46,
	},
	{
		garage_id: 16,
		part_id: 140,
		quantity: 159,
	},
	{
		garage_id: 16,
		part_id: 141,
		quantity: 38,
	},
	{
		garage_id: 16,
		part_id: 142,
		quantity: 350,
	},
	{
		garage_id: 16,
		part_id: 143,
		quantity: 429,
	},
	{
		garage_id: 16,
		part_id: 144,
		quantity: 32,
	},
	{
		garage_id: 16,
		part_id: 145,
		quantity: 496,
	},
	{
		garage_id: 16,
		part_id: 146,
		quantity: 70,
	},
	{
		garage_id: 16,
		part_id: 147,
		quantity: 405,
	},
	{
		garage_id: 16,
		part_id: 148,
		quantity: 143,
	},
	{
		garage_id: 16,
		part_id: 149,
		quantity: 229,
	},
	{
		garage_id: 16,
		part_id: 150,
		quantity: 287,
	},
	{
		garage_id: 16,
		part_id: 151,
		quantity: 285,
	},
	{
		garage_id: 16,
		part_id: 152,
		quantity: 229,
	},
	{
		garage_id: 16,
		part_id: 153,
		quantity: 412,
	},
	{
		garage_id: 16,
		part_id: 154,
		quantity: 136,
	},
	{
		garage_id: 16,
		part_id: 155,
		quantity: 295,
	},
	{
		garage_id: 16,
		part_id: 156,
		quantity: 184,
	},
	{
		garage_id: 16,
		part_id: 157,
		quantity: 268,
	},
	{
		garage_id: 16,
		part_id: 158,
		quantity: 199,
	},
	{
		garage_id: 16,
		part_id: 159,
		quantity: 490,
	},
	{
		garage_id: 16,
		part_id: 160,
		quantity: 225,
	},
	{
		garage_id: 16,
		part_id: 161,
		quantity: 70,
	},
	{
		garage_id: 16,
		part_id: 162,
		quantity: 320,
	},
	{
		garage_id: 16,
		part_id: 163,
		quantity: 104,
	},
	{
		garage_id: 16,
		part_id: 164,
		quantity: 244,
	},
	{
		garage_id: 16,
		part_id: 165,
		quantity: 270,
	},
	{
		garage_id: 16,
		part_id: 166,
		quantity: 36,
	},
	{
		garage_id: 16,
		part_id: 167,
		quantity: 49,
	},
	{
		garage_id: 16,
		part_id: 168,
		quantity: 146,
	},
	{
		garage_id: 16,
		part_id: 169,
		quantity: 33,
	},
	{
		garage_id: 16,
		part_id: 170,
		quantity: 351,
	},
	{
		garage_id: 16,
		part_id: 171,
		quantity: 386,
	},
	{
		garage_id: 16,
		part_id: 172,
		quantity: 51,
	},
	{
		garage_id: 16,
		part_id: 173,
		quantity: 46,
	},
	{
		garage_id: 16,
		part_id: 174,
		quantity: 176,
	},
	{
		garage_id: 16,
		part_id: 175,
		quantity: 61,
	},
	{
		garage_id: 16,
		part_id: 176,
		quantity: 263,
	},
	{
		garage_id: 16,
		part_id: 177,
		quantity: 436,
	},
	{
		garage_id: 16,
		part_id: 178,
		quantity: 140,
	},
	{
		garage_id: 16,
		part_id: 179,
		quantity: 27,
	},
	{
		garage_id: 16,
		part_id: 180,
		quantity: 194,
	},
	{
		garage_id: 16,
		part_id: 181,
		quantity: 476,
	},
	{
		garage_id: 16,
		part_id: 182,
		quantity: 479,
	},
	{
		garage_id: 16,
		part_id: 183,
		quantity: 106,
	},
	{
		garage_id: 16,
		part_id: 184,
		quantity: 166,
	},
	{
		garage_id: 16,
		part_id: 185,
		quantity: 399,
	},
	{
		garage_id: 16,
		part_id: 186,
		quantity: 358,
	},
	{
		garage_id: 16,
		part_id: 187,
		quantity: 16,
	},
	{
		garage_id: 16,
		part_id: 188,
		quantity: 323,
	},
	{
		garage_id: 16,
		part_id: 189,
		quantity: 258,
	},
	{
		garage_id: 16,
		part_id: 190,
		quantity: 243,
	},
	{
		garage_id: 16,
		part_id: 191,
		quantity: 285,
	},
	{
		garage_id: 16,
		part_id: 192,
		quantity: 462,
	},
	{
		garage_id: 16,
		part_id: 193,
		quantity: 372,
	},
	{
		garage_id: 16,
		part_id: 194,
		quantity: 215,
	},
	{
		garage_id: 16,
		part_id: 195,
		quantity: 375,
	},
	{
		garage_id: 16,
		part_id: 196,
		quantity: 287,
	},
	{
		garage_id: 16,
		part_id: 197,
		quantity: 224,
	},
	{
		garage_id: 16,
		part_id: 198,
		quantity: 265,
	},
	{
		garage_id: 16,
		part_id: 199,
		quantity: 368,
	},
	{
		garage_id: 16,
		part_id: 200,
		quantity: 406,
	},
	{
		garage_id: 16,
		part_id: 201,
		quantity: 222,
	},
	{
		garage_id: 16,
		part_id: 202,
		quantity: 261,
	},
	{
		garage_id: 16,
		part_id: 203,
		quantity: 437,
	},
	{
		garage_id: 16,
		part_id: 204,
		quantity: 317,
	},
	{
		garage_id: 16,
		part_id: 205,
		quantity: 178,
	},
	{
		garage_id: 16,
		part_id: 206,
		quantity: 113,
	},
	{
		garage_id: 16,
		part_id: 207,
		quantity: 321,
	},
	{
		garage_id: 16,
		part_id: 208,
		quantity: 178,
	},
	{
		garage_id: 16,
		part_id: 209,
		quantity: 164,
	},
	{
		garage_id: 16,
		part_id: 210,
		quantity: 172,
	},
	{
		garage_id: 16,
		part_id: 211,
		quantity: 439,
	},
	{
		garage_id: 16,
		part_id: 212,
		quantity: 249,
	},
	{
		garage_id: 16,
		part_id: 213,
		quantity: 10,
	},
	{
		garage_id: 16,
		part_id: 214,
		quantity: 480,
	},
	{
		garage_id: 16,
		part_id: 215,
		quantity: 332,
	},
	{
		garage_id: 16,
		part_id: 216,
		quantity: 160,
	},
	{
		garage_id: 16,
		part_id: 217,
		quantity: 479,
	},
	{
		garage_id: 16,
		part_id: 218,
		quantity: 306,
	},
	{
		garage_id: 16,
		part_id: 219,
		quantity: 493,
	},
	{
		garage_id: 16,
		part_id: 220,
		quantity: 390,
	},
	{
		garage_id: 16,
		part_id: 221,
		quantity: 119,
	},
	{
		garage_id: 16,
		part_id: 222,
		quantity: 100,
	},
	{
		garage_id: 16,
		part_id: 223,
		quantity: 309,
	},
	{
		garage_id: 16,
		part_id: 224,
		quantity: 100,
	},
	{
		garage_id: 16,
		part_id: 225,
		quantity: 4,
	},
	{
		garage_id: 16,
		part_id: 226,
		quantity: 301,
	},
	{
		garage_id: 16,
		part_id: 227,
		quantity: 96,
	},
	{
		garage_id: 16,
		part_id: 228,
		quantity: 213,
	},
	{
		garage_id: 16,
		part_id: 229,
		quantity: 233,
	},
	{
		garage_id: 16,
		part_id: 230,
		quantity: 213,
	},
	{
		garage_id: 16,
		part_id: 231,
		quantity: 12,
	},
	{
		garage_id: 16,
		part_id: 232,
		quantity: 228,
	},
	{
		garage_id: 16,
		part_id: 233,
		quantity: 276,
	},
	{
		garage_id: 16,
		part_id: 234,
		quantity: 438,
	},
	{
		garage_id: 16,
		part_id: 235,
		quantity: 384,
	},
	{
		garage_id: 16,
		part_id: 236,
		quantity: 121,
	},
	{
		garage_id: 16,
		part_id: 237,
		quantity: 221,
	},
	{
		garage_id: 16,
		part_id: 238,
		quantity: 498,
	},
	{
		garage_id: 16,
		part_id: 239,
		quantity: 281,
	},
	{
		garage_id: 16,
		part_id: 240,
		quantity: 385,
	},
	{
		garage_id: 16,
		part_id: 241,
		quantity: 90,
	},
	{
		garage_id: 16,
		part_id: 242,
		quantity: 315,
	},
	{
		garage_id: 16,
		part_id: 243,
		quantity: 414,
	},
	{
		garage_id: 16,
		part_id: 244,
		quantity: 156,
	},
	{
		garage_id: 16,
		part_id: 245,
		quantity: 13,
	},
	{
		garage_id: 16,
		part_id: 246,
		quantity: 472,
	},
	{
		garage_id: 16,
		part_id: 247,
		quantity: 98,
	},
	{
		garage_id: 16,
		part_id: 248,
		quantity: 206,
	},
	{
		garage_id: 16,
		part_id: 249,
		quantity: 350,
	},
	{
		garage_id: 16,
		part_id: 250,
		quantity: 360,
	},
	{
		garage_id: 16,
		part_id: 251,
		quantity: 412,
	},
	{
		garage_id: 16,
		part_id: 252,
		quantity: 431,
	},
	{
		garage_id: 16,
		part_id: 253,
		quantity: 282,
	},
	{
		garage_id: 16,
		part_id: 254,
		quantity: 333,
	},
	{
		garage_id: 16,
		part_id: 255,
		quantity: 490,
	},
	{
		garage_id: 16,
		part_id: 256,
		quantity: 340,
	},
	{
		garage_id: 16,
		part_id: 257,
		quantity: 496,
	},
	{
		garage_id: 16,
		part_id: 258,
		quantity: 327,
	},
	{
		garage_id: 16,
		part_id: 259,
		quantity: 111,
	},
	{
		garage_id: 16,
		part_id: 260,
		quantity: 153,
	},
	{
		garage_id: 16,
		part_id: 261,
		quantity: 207,
	},
	{
		garage_id: 16,
		part_id: 262,
		quantity: 340,
	},
	{
		garage_id: 16,
		part_id: 263,
		quantity: 445,
	},
	{
		garage_id: 16,
		part_id: 264,
		quantity: 316,
	},
	{
		garage_id: 16,
		part_id: 265,
		quantity: 52,
	},
	{
		garage_id: 16,
		part_id: 266,
		quantity: 209,
	},
	{
		garage_id: 16,
		part_id: 267,
		quantity: 71,
	},
	{
		garage_id: 16,
		part_id: 268,
		quantity: 228,
	},
	{
		garage_id: 16,
		part_id: 269,
		quantity: 115,
	},
	{
		garage_id: 16,
		part_id: 270,
		quantity: 346,
	},
	{
		garage_id: 16,
		part_id: 271,
		quantity: 17,
	},
	{
		garage_id: 16,
		part_id: 272,
		quantity: 283,
	},
	{
		garage_id: 16,
		part_id: 273,
		quantity: 103,
	},
	{
		garage_id: 16,
		part_id: 274,
		quantity: 349,
	},
	{
		garage_id: 16,
		part_id: 275,
		quantity: 45,
	},
	{
		garage_id: 16,
		part_id: 276,
		quantity: 469,
	},
	{
		garage_id: 16,
		part_id: 277,
		quantity: 81,
	},
	{
		garage_id: 16,
		part_id: 278,
		quantity: 330,
	},
	{
		garage_id: 16,
		part_id: 279,
		quantity: 8,
	},
	{
		garage_id: 16,
		part_id: 280,
		quantity: 398,
	},
	{
		garage_id: 16,
		part_id: 281,
		quantity: 443,
	},
	{
		garage_id: 16,
		part_id: 282,
		quantity: 392,
	},
	{
		garage_id: 16,
		part_id: 283,
		quantity: 473,
	},
	{
		garage_id: 16,
		part_id: 284,
		quantity: 421,
	},
	{
		garage_id: 16,
		part_id: 285,
		quantity: 317,
	},
	{
		garage_id: 16,
		part_id: 286,
		quantity: 207,
	},
	{
		garage_id: 16,
		part_id: 287,
		quantity: 469,
	},
	{
		garage_id: 16,
		part_id: 288,
		quantity: 447,
	},
	{
		garage_id: 16,
		part_id: 289,
		quantity: 249,
	},
	{
		garage_id: 16,
		part_id: 290,
		quantity: 32,
	},
	{
		garage_id: 16,
		part_id: 291,
		quantity: 471,
	},
	{
		garage_id: 16,
		part_id: 292,
		quantity: 134,
	},
	{
		garage_id: 16,
		part_id: 293,
		quantity: 180,
	},
	{
		garage_id: 16,
		part_id: 294,
		quantity: 67,
	},
	{
		garage_id: 16,
		part_id: 295,
		quantity: 227,
	},
	{
		garage_id: 16,
		part_id: 296,
		quantity: 116,
	},
	{
		garage_id: 16,
		part_id: 297,
		quantity: 66,
	},
	{
		garage_id: 16,
		part_id: 298,
		quantity: 341,
	},
	{
		garage_id: 16,
		part_id: 299,
		quantity: 213,
	},
	{
		garage_id: 16,
		part_id: 300,
		quantity: 436,
	},
	{
		garage_id: 16,
		part_id: 301,
		quantity: 409,
	},
	{
		garage_id: 16,
		part_id: 302,
		quantity: 53,
	},
	{
		garage_id: 16,
		part_id: 303,
		quantity: 313,
	},
	{
		garage_id: 16,
		part_id: 304,
		quantity: 456,
	},
	{
		garage_id: 16,
		part_id: 305,
		quantity: 141,
	},
	{
		garage_id: 16,
		part_id: 306,
		quantity: 40,
	},
	{
		garage_id: 16,
		part_id: 307,
		quantity: 40,
	},
	{
		garage_id: 16,
		part_id: 308,
		quantity: 352,
	},
	{
		garage_id: 16,
		part_id: 309,
		quantity: 73,
	},
	{
		garage_id: 16,
		part_id: 310,
		quantity: 125,
	},
	{
		garage_id: 16,
		part_id: 311,
		quantity: 221,
	},
	{
		garage_id: 16,
		part_id: 312,
		quantity: 102,
	},
	{
		garage_id: 16,
		part_id: 313,
		quantity: 129,
	},
	{
		garage_id: 16,
		part_id: 314,
		quantity: 263,
	},
	{
		garage_id: 16,
		part_id: 315,
		quantity: 487,
	},
	{
		garage_id: 16,
		part_id: 316,
		quantity: 359,
	},
	{
		garage_id: 16,
		part_id: 317,
		quantity: 107,
	},
	{
		garage_id: 16,
		part_id: 318,
		quantity: 146,
	},
	{
		garage_id: 16,
		part_id: 319,
		quantity: 166,
	},
	{
		garage_id: 16,
		part_id: 320,
		quantity: 402,
	},
	{
		garage_id: 16,
		part_id: 321,
		quantity: 77,
	},
	{
		garage_id: 16,
		part_id: 322,
		quantity: 247,
	},
	{
		garage_id: 16,
		part_id: 323,
		quantity: 26,
	},
	{
		garage_id: 16,
		part_id: 324,
		quantity: 442,
	},
	{
		garage_id: 16,
		part_id: 325,
		quantity: 382,
	},
	{
		garage_id: 16,
		part_id: 326,
		quantity: 360,
	},
	{
		garage_id: 16,
		part_id: 327,
		quantity: 215,
	},
	{
		garage_id: 16,
		part_id: 328,
		quantity: 79,
	},
	{
		garage_id: 16,
		part_id: 329,
		quantity: 382,
	},
	{
		garage_id: 16,
		part_id: 330,
		quantity: 134,
	},
	{
		garage_id: 16,
		part_id: 331,
		quantity: 450,
	},
	{
		garage_id: 16,
		part_id: 332,
		quantity: 351,
	},
	{
		garage_id: 16,
		part_id: 333,
		quantity: 370,
	},
	{
		garage_id: 16,
		part_id: 334,
		quantity: 125,
	},
	{
		garage_id: 16,
		part_id: 335,
		quantity: 351,
	},
	{
		garage_id: 16,
		part_id: 336,
		quantity: 475,
	},
	{
		garage_id: 16,
		part_id: 337,
		quantity: 125,
	},
	{
		garage_id: 16,
		part_id: 338,
		quantity: 205,
	},
	{
		garage_id: 16,
		part_id: 339,
		quantity: 358,
	},
	{
		garage_id: 16,
		part_id: 340,
		quantity: 492,
	},
	{
		garage_id: 16,
		part_id: 341,
		quantity: 176,
	},
	{
		garage_id: 16,
		part_id: 342,
		quantity: 120,
	},
	{
		garage_id: 16,
		part_id: 343,
		quantity: 477,
	},
	{
		garage_id: 16,
		part_id: 344,
		quantity: 110,
	},
	{
		garage_id: 16,
		part_id: 345,
		quantity: 493,
	},
	{
		garage_id: 16,
		part_id: 346,
		quantity: 124,
	},
	{
		garage_id: 16,
		part_id: 347,
		quantity: 306,
	},
	{
		garage_id: 16,
		part_id: 348,
		quantity: 7,
	},
	{
		garage_id: 16,
		part_id: 349,
		quantity: 344,
	},
	{
		garage_id: 16,
		part_id: 350,
		quantity: 375,
	},
	{
		garage_id: 16,
		part_id: 351,
		quantity: 457,
	},
	{
		garage_id: 16,
		part_id: 352,
		quantity: 414,
	},
	{
		garage_id: 16,
		part_id: 353,
		quantity: 461,
	},
	{
		garage_id: 16,
		part_id: 354,
		quantity: 80,
	},
	{
		garage_id: 16,
		part_id: 355,
		quantity: 305,
	},
	{
		garage_id: 16,
		part_id: 356,
		quantity: 396,
	},
	{
		garage_id: 16,
		part_id: 357,
		quantity: 205,
	},
	{
		garage_id: 16,
		part_id: 358,
		quantity: 464,
	},
	{
		garage_id: 16,
		part_id: 359,
		quantity: 188,
	},
	{
		garage_id: 16,
		part_id: 360,
		quantity: 345,
	},
	{
		garage_id: 16,
		part_id: 361,
		quantity: 46,
	},
	{
		garage_id: 16,
		part_id: 362,
		quantity: 154,
	},
	{
		garage_id: 16,
		part_id: 363,
		quantity: 220,
	},
	{
		garage_id: 16,
		part_id: 364,
		quantity: 94,
	},
	{
		garage_id: 16,
		part_id: 365,
		quantity: 61,
	},
	{
		garage_id: 16,
		part_id: 366,
		quantity: 115,
	},
	{
		garage_id: 16,
		part_id: 367,
		quantity: 307,
	},
	{
		garage_id: 16,
		part_id: 368,
		quantity: 117,
	},
	{
		garage_id: 16,
		part_id: 369,
		quantity: 197,
	},
	{
		garage_id: 16,
		part_id: 370,
		quantity: 418,
	},
	{
		garage_id: 16,
		part_id: 371,
		quantity: 82,
	},
	{
		garage_id: 16,
		part_id: 372,
		quantity: 56,
	},
	{
		garage_id: 16,
		part_id: 373,
		quantity: 412,
	},
	{
		garage_id: 16,
		part_id: 374,
		quantity: 212,
	},
	{
		garage_id: 16,
		part_id: 375,
		quantity: 389,
	},
	{
		garage_id: 16,
		part_id: 376,
		quantity: 290,
	},
	{
		garage_id: 16,
		part_id: 377,
		quantity: 178,
	},
	{
		garage_id: 16,
		part_id: 378,
		quantity: 267,
	},
	{
		garage_id: 16,
		part_id: 379,
		quantity: 189,
	},
	{
		garage_id: 16,
		part_id: 380,
		quantity: 163,
	},
	{
		garage_id: 16,
		part_id: 381,
		quantity: 335,
	},
	{
		garage_id: 16,
		part_id: 382,
		quantity: 202,
	},
	{
		garage_id: 16,
		part_id: 383,
		quantity: 116,
	},
	{
		garage_id: 16,
		part_id: 384,
		quantity: 291,
	},
	{
		garage_id: 16,
		part_id: 385,
		quantity: 391,
	},
	{
		garage_id: 16,
		part_id: 386,
		quantity: 7,
	},
	{
		garage_id: 16,
		part_id: 387,
		quantity: 416,
	},
	{
		garage_id: 16,
		part_id: 388,
		quantity: 403,
	},
	{
		garage_id: 16,
		part_id: 389,
		quantity: 378,
	},
	{
		garage_id: 16,
		part_id: 390,
		quantity: 113,
	},
	{
		garage_id: 16,
		part_id: 391,
		quantity: 327,
	},
	{
		garage_id: 16,
		part_id: 392,
		quantity: 105,
	},
	{
		garage_id: 16,
		part_id: 393,
		quantity: 373,
	},
	{
		garage_id: 16,
		part_id: 394,
		quantity: 147,
	},
	{
		garage_id: 16,
		part_id: 395,
		quantity: 261,
	},
	{
		garage_id: 16,
		part_id: 396,
		quantity: 78,
	},
	{
		garage_id: 16,
		part_id: 397,
		quantity: 209,
	},
	{
		garage_id: 16,
		part_id: 398,
		quantity: 131,
	},
	{
		garage_id: 16,
		part_id: 399,
		quantity: 170,
	},
	{
		garage_id: 17,
		part_id: 0,
		quantity: 410,
	},
	{
		garage_id: 17,
		part_id: 1,
		quantity: 429,
	},
	{
		garage_id: 17,
		part_id: 2,
		quantity: 476,
	},
	{
		garage_id: 17,
		part_id: 3,
		quantity: 90,
	},
	{
		garage_id: 17,
		part_id: 4,
		quantity: 140,
	},
	{
		garage_id: 17,
		part_id: 5,
		quantity: 449,
	},
	{
		garage_id: 17,
		part_id: 6,
		quantity: 7,
	},
	{
		garage_id: 17,
		part_id: 7,
		quantity: 414,
	},
	{
		garage_id: 17,
		part_id: 8,
		quantity: 13,
	},
	{
		garage_id: 17,
		part_id: 9,
		quantity: 173,
	},
	{
		garage_id: 17,
		part_id: 10,
		quantity: 246,
	},
	{
		garage_id: 17,
		part_id: 11,
		quantity: 430,
	},
	{
		garage_id: 17,
		part_id: 12,
		quantity: 109,
	},
	{
		garage_id: 17,
		part_id: 13,
		quantity: 358,
	},
	{
		garage_id: 17,
		part_id: 14,
		quantity: 183,
	},
	{
		garage_id: 17,
		part_id: 15,
		quantity: 250,
	},
	{
		garage_id: 17,
		part_id: 16,
		quantity: 109,
	},
	{
		garage_id: 17,
		part_id: 17,
		quantity: 368,
	},
	{
		garage_id: 17,
		part_id: 18,
		quantity: 338,
	},
	{
		garage_id: 17,
		part_id: 19,
		quantity: 245,
	},
	{
		garage_id: 17,
		part_id: 20,
		quantity: 291,
	},
	{
		garage_id: 17,
		part_id: 21,
		quantity: 166,
	},
	{
		garage_id: 17,
		part_id: 22,
		quantity: 65,
	},
	{
		garage_id: 17,
		part_id: 23,
		quantity: 236,
	},
	{
		garage_id: 17,
		part_id: 24,
		quantity: 14,
	},
	{
		garage_id: 17,
		part_id: 25,
		quantity: 237,
	},
	{
		garage_id: 17,
		part_id: 26,
		quantity: 318,
	},
	{
		garage_id: 17,
		part_id: 27,
		quantity: 172,
	},
	{
		garage_id: 17,
		part_id: 28,
		quantity: 32,
	},
	{
		garage_id: 17,
		part_id: 29,
		quantity: 480,
	},
	{
		garage_id: 17,
		part_id: 30,
		quantity: 164,
	},
	{
		garage_id: 17,
		part_id: 31,
		quantity: 85,
	},
	{
		garage_id: 17,
		part_id: 32,
		quantity: 351,
	},
	{
		garage_id: 17,
		part_id: 33,
		quantity: 238,
	},
	{
		garage_id: 17,
		part_id: 34,
		quantity: 24,
	},
	{
		garage_id: 17,
		part_id: 35,
		quantity: 350,
	},
	{
		garage_id: 17,
		part_id: 36,
		quantity: 427,
	},
	{
		garage_id: 17,
		part_id: 37,
		quantity: 293,
	},
	{
		garage_id: 17,
		part_id: 38,
		quantity: 260,
	},
	{
		garage_id: 17,
		part_id: 39,
		quantity: 86,
	},
	{
		garage_id: 17,
		part_id: 40,
		quantity: 322,
	},
	{
		garage_id: 17,
		part_id: 41,
		quantity: 402,
	},
	{
		garage_id: 17,
		part_id: 42,
		quantity: 123,
	},
	{
		garage_id: 17,
		part_id: 43,
		quantity: 497,
	},
	{
		garage_id: 17,
		part_id: 44,
		quantity: 271,
	},
	{
		garage_id: 17,
		part_id: 45,
		quantity: 6,
	},
	{
		garage_id: 17,
		part_id: 46,
		quantity: 232,
	},
	{
		garage_id: 17,
		part_id: 47,
		quantity: 475,
	},
	{
		garage_id: 17,
		part_id: 48,
		quantity: 247,
	},
	{
		garage_id: 17,
		part_id: 49,
		quantity: 99,
	},
	{
		garage_id: 17,
		part_id: 50,
		quantity: 187,
	},
	{
		garage_id: 17,
		part_id: 51,
		quantity: 284,
	},
	{
		garage_id: 17,
		part_id: 52,
		quantity: 137,
	},
	{
		garage_id: 17,
		part_id: 53,
		quantity: 369,
	},
	{
		garage_id: 17,
		part_id: 54,
		quantity: 212,
	},
	{
		garage_id: 17,
		part_id: 55,
		quantity: 324,
	},
	{
		garage_id: 17,
		part_id: 56,
		quantity: 241,
	},
	{
		garage_id: 17,
		part_id: 57,
		quantity: 245,
	},
	{
		garage_id: 17,
		part_id: 58,
		quantity: 423,
	},
	{
		garage_id: 17,
		part_id: 59,
		quantity: 354,
	},
	{
		garage_id: 17,
		part_id: 60,
		quantity: 449,
	},
	{
		garage_id: 17,
		part_id: 61,
		quantity: 400,
	},
	{
		garage_id: 17,
		part_id: 62,
		quantity: 184,
	},
	{
		garage_id: 17,
		part_id: 63,
		quantity: 239,
	},
	{
		garage_id: 17,
		part_id: 64,
		quantity: 207,
	},
	{
		garage_id: 17,
		part_id: 65,
		quantity: 444,
	},
	{
		garage_id: 17,
		part_id: 66,
		quantity: 18,
	},
	{
		garage_id: 17,
		part_id: 67,
		quantity: 413,
	},
	{
		garage_id: 17,
		part_id: 68,
		quantity: 384,
	},
	{
		garage_id: 17,
		part_id: 69,
		quantity: 57,
	},
	{
		garage_id: 17,
		part_id: 70,
		quantity: 265,
	},
	{
		garage_id: 17,
		part_id: 71,
		quantity: 287,
	},
	{
		garage_id: 17,
		part_id: 72,
		quantity: 494,
	},
	{
		garage_id: 17,
		part_id: 73,
		quantity: 208,
	},
	{
		garage_id: 17,
		part_id: 74,
		quantity: 343,
	},
	{
		garage_id: 17,
		part_id: 75,
		quantity: 435,
	},
	{
		garage_id: 17,
		part_id: 76,
		quantity: 252,
	},
	{
		garage_id: 17,
		part_id: 77,
		quantity: 284,
	},
	{
		garage_id: 17,
		part_id: 78,
		quantity: 90,
	},
	{
		garage_id: 17,
		part_id: 79,
		quantity: 347,
	},
	{
		garage_id: 17,
		part_id: 80,
		quantity: 324,
	},
	{
		garage_id: 17,
		part_id: 81,
		quantity: 428,
	},
	{
		garage_id: 17,
		part_id: 82,
		quantity: 208,
	},
	{
		garage_id: 17,
		part_id: 83,
		quantity: 169,
	},
	{
		garage_id: 17,
		part_id: 84,
		quantity: 37,
	},
	{
		garage_id: 17,
		part_id: 85,
		quantity: 191,
	},
	{
		garage_id: 17,
		part_id: 86,
		quantity: 1,
	},
	{
		garage_id: 17,
		part_id: 87,
		quantity: 463,
	},
	{
		garage_id: 17,
		part_id: 88,
		quantity: 333,
	},
	{
		garage_id: 17,
		part_id: 89,
		quantity: 182,
	},
	{
		garage_id: 17,
		part_id: 90,
		quantity: 473,
	},
	{
		garage_id: 17,
		part_id: 91,
		quantity: 260,
	},
	{
		garage_id: 17,
		part_id: 92,
		quantity: 304,
	},
	{
		garage_id: 17,
		part_id: 93,
		quantity: 390,
	},
	{
		garage_id: 17,
		part_id: 94,
		quantity: 126,
	},
	{
		garage_id: 17,
		part_id: 95,
		quantity: 178,
	},
	{
		garage_id: 17,
		part_id: 96,
		quantity: 30,
	},
	{
		garage_id: 17,
		part_id: 97,
		quantity: 296,
	},
	{
		garage_id: 17,
		part_id: 98,
		quantity: 401,
	},
	{
		garage_id: 17,
		part_id: 99,
		quantity: 324,
	},
	{
		garage_id: 17,
		part_id: 100,
		quantity: 468,
	},
	{
		garage_id: 17,
		part_id: 101,
		quantity: 112,
	},
	{
		garage_id: 17,
		part_id: 102,
		quantity: 314,
	},
	{
		garage_id: 17,
		part_id: 103,
		quantity: 238,
	},
	{
		garage_id: 17,
		part_id: 104,
		quantity: 419,
	},
	{
		garage_id: 17,
		part_id: 105,
		quantity: 454,
	},
	{
		garage_id: 17,
		part_id: 106,
		quantity: 387,
	},
	{
		garage_id: 17,
		part_id: 107,
		quantity: 398,
	},
	{
		garage_id: 17,
		part_id: 108,
		quantity: 53,
	},
	{
		garage_id: 17,
		part_id: 109,
		quantity: 54,
	},
	{
		garage_id: 17,
		part_id: 110,
		quantity: 468,
	},
	{
		garage_id: 17,
		part_id: 111,
		quantity: 403,
	},
	{
		garage_id: 17,
		part_id: 112,
		quantity: 143,
	},
	{
		garage_id: 17,
		part_id: 113,
		quantity: 229,
	},
	{
		garage_id: 17,
		part_id: 114,
		quantity: 456,
	},
	{
		garage_id: 17,
		part_id: 115,
		quantity: 468,
	},
	{
		garage_id: 17,
		part_id: 116,
		quantity: 236,
	},
	{
		garage_id: 17,
		part_id: 117,
		quantity: 210,
	},
	{
		garage_id: 17,
		part_id: 118,
		quantity: 327,
	},
	{
		garage_id: 17,
		part_id: 119,
		quantity: 219,
	},
	{
		garage_id: 17,
		part_id: 120,
		quantity: 264,
	},
	{
		garage_id: 17,
		part_id: 121,
		quantity: 425,
	},
	{
		garage_id: 17,
		part_id: 122,
		quantity: 149,
	},
	{
		garage_id: 17,
		part_id: 123,
		quantity: 175,
	},
	{
		garage_id: 17,
		part_id: 124,
		quantity: 425,
	},
	{
		garage_id: 17,
		part_id: 125,
		quantity: 132,
	},
	{
		garage_id: 17,
		part_id: 126,
		quantity: 414,
	},
	{
		garage_id: 17,
		part_id: 127,
		quantity: 343,
	},
	{
		garage_id: 17,
		part_id: 128,
		quantity: 327,
	},
	{
		garage_id: 17,
		part_id: 129,
		quantity: 89,
	},
	{
		garage_id: 17,
		part_id: 130,
		quantity: 252,
	},
	{
		garage_id: 17,
		part_id: 131,
		quantity: 239,
	},
	{
		garage_id: 17,
		part_id: 132,
		quantity: 498,
	},
	{
		garage_id: 17,
		part_id: 133,
		quantity: 455,
	},
	{
		garage_id: 17,
		part_id: 134,
		quantity: 69,
	},
	{
		garage_id: 17,
		part_id: 135,
		quantity: 363,
	},
	{
		garage_id: 17,
		part_id: 136,
		quantity: 95,
	},
	{
		garage_id: 17,
		part_id: 137,
		quantity: 42,
	},
	{
		garage_id: 17,
		part_id: 138,
		quantity: 131,
	},
	{
		garage_id: 17,
		part_id: 139,
		quantity: 138,
	},
	{
		garage_id: 17,
		part_id: 140,
		quantity: 159,
	},
	{
		garage_id: 17,
		part_id: 141,
		quantity: 406,
	},
	{
		garage_id: 17,
		part_id: 142,
		quantity: 457,
	},
	{
		garage_id: 17,
		part_id: 143,
		quantity: 349,
	},
	{
		garage_id: 17,
		part_id: 144,
		quantity: 104,
	},
	{
		garage_id: 17,
		part_id: 145,
		quantity: 276,
	},
	{
		garage_id: 17,
		part_id: 146,
		quantity: 241,
	},
	{
		garage_id: 17,
		part_id: 147,
		quantity: 442,
	},
	{
		garage_id: 17,
		part_id: 148,
		quantity: 156,
	},
	{
		garage_id: 17,
		part_id: 149,
		quantity: 251,
	},
	{
		garage_id: 17,
		part_id: 150,
		quantity: 172,
	},
	{
		garage_id: 17,
		part_id: 151,
		quantity: 341,
	},
	{
		garage_id: 17,
		part_id: 152,
		quantity: 324,
	},
	{
		garage_id: 17,
		part_id: 153,
		quantity: 45,
	},
	{
		garage_id: 17,
		part_id: 154,
		quantity: 480,
	},
	{
		garage_id: 17,
		part_id: 155,
		quantity: 199,
	},
	{
		garage_id: 17,
		part_id: 156,
		quantity: 433,
	},
	{
		garage_id: 17,
		part_id: 157,
		quantity: 494,
	},
	{
		garage_id: 17,
		part_id: 158,
		quantity: 90,
	},
	{
		garage_id: 17,
		part_id: 159,
		quantity: 181,
	},
	{
		garage_id: 17,
		part_id: 160,
		quantity: 156,
	},
	{
		garage_id: 17,
		part_id: 161,
		quantity: 236,
	},
	{
		garage_id: 17,
		part_id: 162,
		quantity: 326,
	},
	{
		garage_id: 17,
		part_id: 163,
		quantity: 52,
	},
	{
		garage_id: 17,
		part_id: 164,
		quantity: 494,
	},
	{
		garage_id: 17,
		part_id: 165,
		quantity: 420,
	},
	{
		garage_id: 17,
		part_id: 166,
		quantity: 74,
	},
	{
		garage_id: 17,
		part_id: 167,
		quantity: 297,
	},
	{
		garage_id: 17,
		part_id: 168,
		quantity: 389,
	},
	{
		garage_id: 17,
		part_id: 169,
		quantity: 318,
	},
	{
		garage_id: 17,
		part_id: 170,
		quantity: 106,
	},
	{
		garage_id: 17,
		part_id: 171,
		quantity: 67,
	},
	{
		garage_id: 17,
		part_id: 172,
		quantity: 274,
	},
	{
		garage_id: 17,
		part_id: 173,
		quantity: 316,
	},
	{
		garage_id: 17,
		part_id: 174,
		quantity: 288,
	},
	{
		garage_id: 17,
		part_id: 175,
		quantity: 303,
	},
	{
		garage_id: 17,
		part_id: 176,
		quantity: 278,
	},
	{
		garage_id: 17,
		part_id: 177,
		quantity: 330,
	},
	{
		garage_id: 17,
		part_id: 178,
		quantity: 50,
	},
	{
		garage_id: 17,
		part_id: 179,
		quantity: 159,
	},
	{
		garage_id: 17,
		part_id: 180,
		quantity: 300,
	},
	{
		garage_id: 17,
		part_id: 181,
		quantity: 176,
	},
	{
		garage_id: 17,
		part_id: 182,
		quantity: 353,
	},
	{
		garage_id: 17,
		part_id: 183,
		quantity: 90,
	},
	{
		garage_id: 17,
		part_id: 184,
		quantity: 309,
	},
	{
		garage_id: 17,
		part_id: 185,
		quantity: 72,
	},
	{
		garage_id: 17,
		part_id: 186,
		quantity: 141,
	},
	{
		garage_id: 17,
		part_id: 187,
		quantity: 246,
	},
	{
		garage_id: 17,
		part_id: 188,
		quantity: 51,
	},
	{
		garage_id: 17,
		part_id: 189,
		quantity: 303,
	},
	{
		garage_id: 17,
		part_id: 190,
		quantity: 202,
	},
	{
		garage_id: 17,
		part_id: 191,
		quantity: 482,
	},
	{
		garage_id: 17,
		part_id: 192,
		quantity: 2,
	},
	{
		garage_id: 17,
		part_id: 193,
		quantity: 370,
	},
	{
		garage_id: 17,
		part_id: 194,
		quantity: 146,
	},
	{
		garage_id: 17,
		part_id: 195,
		quantity: 63,
	},
	{
		garage_id: 17,
		part_id: 196,
		quantity: 231,
	},
	{
		garage_id: 17,
		part_id: 197,
		quantity: 215,
	},
	{
		garage_id: 17,
		part_id: 198,
		quantity: 266,
	},
	{
		garage_id: 17,
		part_id: 199,
		quantity: 381,
	},
	{
		garage_id: 17,
		part_id: 200,
		quantity: 148,
	},
	{
		garage_id: 17,
		part_id: 201,
		quantity: 54,
	},
	{
		garage_id: 17,
		part_id: 202,
		quantity: 170,
	},
	{
		garage_id: 17,
		part_id: 203,
		quantity: 33,
	},
	{
		garage_id: 17,
		part_id: 204,
		quantity: 229,
	},
	{
		garage_id: 17,
		part_id: 205,
		quantity: 20,
	},
	{
		garage_id: 17,
		part_id: 206,
		quantity: 471,
	},
	{
		garage_id: 17,
		part_id: 207,
		quantity: 15,
	},
	{
		garage_id: 17,
		part_id: 208,
		quantity: 9,
	},
	{
		garage_id: 17,
		part_id: 209,
		quantity: 213,
	},
	{
		garage_id: 17,
		part_id: 210,
		quantity: 315,
	},
	{
		garage_id: 17,
		part_id: 211,
		quantity: 386,
	},
	{
		garage_id: 17,
		part_id: 212,
		quantity: 199,
	},
	{
		garage_id: 17,
		part_id: 213,
		quantity: 355,
	},
	{
		garage_id: 17,
		part_id: 214,
		quantity: 80,
	},
	{
		garage_id: 17,
		part_id: 215,
		quantity: 218,
	},
	{
		garage_id: 17,
		part_id: 216,
		quantity: 262,
	},
	{
		garage_id: 17,
		part_id: 217,
		quantity: 175,
	},
	{
		garage_id: 17,
		part_id: 218,
		quantity: 177,
	},
	{
		garage_id: 17,
		part_id: 219,
		quantity: 11,
	},
	{
		garage_id: 17,
		part_id: 220,
		quantity: 261,
	},
	{
		garage_id: 17,
		part_id: 221,
		quantity: 385,
	},
	{
		garage_id: 17,
		part_id: 222,
		quantity: 157,
	},
	{
		garage_id: 17,
		part_id: 223,
		quantity: 157,
	},
	{
		garage_id: 17,
		part_id: 224,
		quantity: 32,
	},
	{
		garage_id: 17,
		part_id: 225,
		quantity: 408,
	},
	{
		garage_id: 17,
		part_id: 226,
		quantity: 4,
	},
	{
		garage_id: 17,
		part_id: 227,
		quantity: 57,
	},
	{
		garage_id: 17,
		part_id: 228,
		quantity: 465,
	},
	{
		garage_id: 17,
		part_id: 229,
		quantity: 373,
	},
	{
		garage_id: 17,
		part_id: 230,
		quantity: 367,
	},
	{
		garage_id: 17,
		part_id: 231,
		quantity: 220,
	},
	{
		garage_id: 17,
		part_id: 232,
		quantity: 253,
	},
	{
		garage_id: 17,
		part_id: 233,
		quantity: 85,
	},
	{
		garage_id: 17,
		part_id: 234,
		quantity: 426,
	},
	{
		garage_id: 17,
		part_id: 235,
		quantity: 80,
	},
	{
		garage_id: 17,
		part_id: 236,
		quantity: 412,
	},
	{
		garage_id: 17,
		part_id: 237,
		quantity: 438,
	},
	{
		garage_id: 17,
		part_id: 238,
		quantity: 401,
	},
	{
		garage_id: 17,
		part_id: 239,
		quantity: 448,
	},
	{
		garage_id: 17,
		part_id: 240,
		quantity: 67,
	},
	{
		garage_id: 17,
		part_id: 241,
		quantity: 47,
	},
	{
		garage_id: 17,
		part_id: 242,
		quantity: 477,
	},
	{
		garage_id: 17,
		part_id: 243,
		quantity: 218,
	},
	{
		garage_id: 17,
		part_id: 244,
		quantity: 221,
	},
	{
		garage_id: 17,
		part_id: 245,
		quantity: 174,
	},
	{
		garage_id: 17,
		part_id: 246,
		quantity: 148,
	},
	{
		garage_id: 17,
		part_id: 247,
		quantity: 268,
	},
	{
		garage_id: 17,
		part_id: 248,
		quantity: 239,
	},
	{
		garage_id: 17,
		part_id: 249,
		quantity: 262,
	},
	{
		garage_id: 17,
		part_id: 250,
		quantity: 207,
	},
	{
		garage_id: 17,
		part_id: 251,
		quantity: 348,
	},
	{
		garage_id: 17,
		part_id: 252,
		quantity: 139,
	},
	{
		garage_id: 17,
		part_id: 253,
		quantity: 320,
	},
	{
		garage_id: 17,
		part_id: 254,
		quantity: 206,
	},
	{
		garage_id: 17,
		part_id: 255,
		quantity: 175,
	},
	{
		garage_id: 17,
		part_id: 256,
		quantity: 470,
	},
	{
		garage_id: 17,
		part_id: 257,
		quantity: 409,
	},
	{
		garage_id: 17,
		part_id: 258,
		quantity: 277,
	},
	{
		garage_id: 17,
		part_id: 259,
		quantity: 43,
	},
	{
		garage_id: 17,
		part_id: 260,
		quantity: 330,
	},
	{
		garage_id: 17,
		part_id: 261,
		quantity: 395,
	},
	{
		garage_id: 17,
		part_id: 262,
		quantity: 400,
	},
	{
		garage_id: 17,
		part_id: 263,
		quantity: 469,
	},
	{
		garage_id: 17,
		part_id: 264,
		quantity: 180,
	},
	{
		garage_id: 17,
		part_id: 265,
		quantity: 314,
	},
	{
		garage_id: 17,
		part_id: 266,
		quantity: 471,
	},
	{
		garage_id: 17,
		part_id: 267,
		quantity: 197,
	},
	{
		garage_id: 17,
		part_id: 268,
		quantity: 203,
	},
	{
		garage_id: 17,
		part_id: 269,
		quantity: 427,
	},
	{
		garage_id: 17,
		part_id: 270,
		quantity: 287,
	},
	{
		garage_id: 17,
		part_id: 271,
		quantity: 145,
	},
	{
		garage_id: 17,
		part_id: 272,
		quantity: 314,
	},
	{
		garage_id: 17,
		part_id: 273,
		quantity: 80,
	},
	{
		garage_id: 17,
		part_id: 274,
		quantity: 232,
	},
	{
		garage_id: 17,
		part_id: 275,
		quantity: 10,
	},
	{
		garage_id: 17,
		part_id: 276,
		quantity: 455,
	},
	{
		garage_id: 17,
		part_id: 277,
		quantity: 448,
	},
	{
		garage_id: 17,
		part_id: 278,
		quantity: 413,
	},
	{
		garage_id: 17,
		part_id: 279,
		quantity: 374,
	},
	{
		garage_id: 17,
		part_id: 280,
		quantity: 189,
	},
	{
		garage_id: 17,
		part_id: 281,
		quantity: 196,
	},
	{
		garage_id: 17,
		part_id: 282,
		quantity: 87,
	},
	{
		garage_id: 17,
		part_id: 283,
		quantity: 80,
	},
	{
		garage_id: 17,
		part_id: 284,
		quantity: 163,
	},
	{
		garage_id: 17,
		part_id: 285,
		quantity: 152,
	},
	{
		garage_id: 17,
		part_id: 286,
		quantity: 238,
	},
	{
		garage_id: 17,
		part_id: 287,
		quantity: 18,
	},
	{
		garage_id: 17,
		part_id: 288,
		quantity: 231,
	},
	{
		garage_id: 17,
		part_id: 289,
		quantity: 388,
	},
	{
		garage_id: 17,
		part_id: 290,
		quantity: 459,
	},
	{
		garage_id: 17,
		part_id: 291,
		quantity: 356,
	},
	{
		garage_id: 17,
		part_id: 292,
		quantity: 350,
	},
	{
		garage_id: 17,
		part_id: 293,
		quantity: 186,
	},
	{
		garage_id: 17,
		part_id: 294,
		quantity: 289,
	},
	{
		garage_id: 17,
		part_id: 295,
		quantity: 353,
	},
	{
		garage_id: 17,
		part_id: 296,
		quantity: 387,
	},
	{
		garage_id: 17,
		part_id: 297,
		quantity: 358,
	},
	{
		garage_id: 17,
		part_id: 298,
		quantity: 256,
	},
	{
		garage_id: 17,
		part_id: 299,
		quantity: 119,
	},
	{
		garage_id: 17,
		part_id: 300,
		quantity: 165,
	},
	{
		garage_id: 17,
		part_id: 301,
		quantity: 498,
	},
	{
		garage_id: 17,
		part_id: 302,
		quantity: 66,
	},
	{
		garage_id: 17,
		part_id: 303,
		quantity: 428,
	},
	{
		garage_id: 17,
		part_id: 304,
		quantity: 163,
	},
	{
		garage_id: 17,
		part_id: 305,
		quantity: 238,
	},
	{
		garage_id: 17,
		part_id: 306,
		quantity: 272,
	},
	{
		garage_id: 17,
		part_id: 307,
		quantity: 301,
	},
	{
		garage_id: 17,
		part_id: 308,
		quantity: 5,
	},
	{
		garage_id: 17,
		part_id: 309,
		quantity: 206,
	},
	{
		garage_id: 17,
		part_id: 310,
		quantity: 215,
	},
	{
		garage_id: 17,
		part_id: 311,
		quantity: 343,
	},
	{
		garage_id: 17,
		part_id: 312,
		quantity: 414,
	},
	{
		garage_id: 17,
		part_id: 313,
		quantity: 87,
	},
	{
		garage_id: 17,
		part_id: 314,
		quantity: 170,
	},
	{
		garage_id: 17,
		part_id: 315,
		quantity: 418,
	},
	{
		garage_id: 17,
		part_id: 316,
		quantity: 292,
	},
	{
		garage_id: 17,
		part_id: 317,
		quantity: 479,
	},
	{
		garage_id: 17,
		part_id: 318,
		quantity: 335,
	},
	{
		garage_id: 17,
		part_id: 319,
		quantity: 169,
	},
	{
		garage_id: 17,
		part_id: 320,
		quantity: 264,
	},
	{
		garage_id: 17,
		part_id: 321,
		quantity: 74,
	},
	{
		garage_id: 17,
		part_id: 322,
		quantity: 426,
	},
	{
		garage_id: 17,
		part_id: 323,
		quantity: 333,
	},
	{
		garage_id: 17,
		part_id: 324,
		quantity: 44,
	},
	{
		garage_id: 17,
		part_id: 325,
		quantity: 478,
	},
	{
		garage_id: 17,
		part_id: 326,
		quantity: 408,
	},
	{
		garage_id: 17,
		part_id: 327,
		quantity: 390,
	},
	{
		garage_id: 17,
		part_id: 328,
		quantity: 131,
	},
	{
		garage_id: 17,
		part_id: 329,
		quantity: 105,
	},
	{
		garage_id: 17,
		part_id: 330,
		quantity: 22,
	},
	{
		garage_id: 17,
		part_id: 331,
		quantity: 258,
	},
	{
		garage_id: 17,
		part_id: 332,
		quantity: 381,
	},
	{
		garage_id: 17,
		part_id: 333,
		quantity: 223,
	},
	{
		garage_id: 17,
		part_id: 334,
		quantity: 197,
	},
	{
		garage_id: 17,
		part_id: 335,
		quantity: 290,
	},
	{
		garage_id: 17,
		part_id: 336,
		quantity: 114,
	},
	{
		garage_id: 17,
		part_id: 337,
		quantity: 155,
	},
	{
		garage_id: 17,
		part_id: 338,
		quantity: 47,
	},
	{
		garage_id: 17,
		part_id: 339,
		quantity: 249,
	},
	{
		garage_id: 17,
		part_id: 340,
		quantity: 140,
	},
	{
		garage_id: 17,
		part_id: 341,
		quantity: 413,
	},
	{
		garage_id: 17,
		part_id: 342,
		quantity: 386,
	},
	{
		garage_id: 17,
		part_id: 343,
		quantity: 360,
	},
	{
		garage_id: 17,
		part_id: 344,
		quantity: 73,
	},
	{
		garage_id: 17,
		part_id: 345,
		quantity: 237,
	},
	{
		garage_id: 17,
		part_id: 346,
		quantity: 293,
	},
	{
		garage_id: 17,
		part_id: 347,
		quantity: 251,
	},
	{
		garage_id: 17,
		part_id: 348,
		quantity: 296,
	},
	{
		garage_id: 17,
		part_id: 349,
		quantity: 352,
	},
	{
		garage_id: 17,
		part_id: 350,
		quantity: 86,
	},
	{
		garage_id: 17,
		part_id: 351,
		quantity: 181,
	},
	{
		garage_id: 17,
		part_id: 352,
		quantity: 446,
	},
	{
		garage_id: 17,
		part_id: 353,
		quantity: 52,
	},
	{
		garage_id: 17,
		part_id: 354,
		quantity: 315,
	},
	{
		garage_id: 17,
		part_id: 355,
		quantity: 282,
	},
	{
		garage_id: 17,
		part_id: 356,
		quantity: 468,
	},
	{
		garage_id: 17,
		part_id: 357,
		quantity: 462,
	},
	{
		garage_id: 17,
		part_id: 358,
		quantity: 447,
	},
	{
		garage_id: 17,
		part_id: 359,
		quantity: 351,
	},
	{
		garage_id: 17,
		part_id: 360,
		quantity: 211,
	},
	{
		garage_id: 17,
		part_id: 361,
		quantity: 361,
	},
	{
		garage_id: 17,
		part_id: 362,
		quantity: 93,
	},
	{
		garage_id: 17,
		part_id: 363,
		quantity: 481,
	},
	{
		garage_id: 17,
		part_id: 364,
		quantity: 494,
	},
	{
		garage_id: 17,
		part_id: 365,
		quantity: 237,
	},
	{
		garage_id: 17,
		part_id: 366,
		quantity: 284,
	},
	{
		garage_id: 17,
		part_id: 367,
		quantity: 214,
	},
	{
		garage_id: 17,
		part_id: 368,
		quantity: 447,
	},
	{
		garage_id: 17,
		part_id: 369,
		quantity: 90,
	},
	{
		garage_id: 17,
		part_id: 370,
		quantity: 300,
	},
	{
		garage_id: 17,
		part_id: 371,
		quantity: 47,
	},
	{
		garage_id: 17,
		part_id: 372,
		quantity: 423,
	},
	{
		garage_id: 17,
		part_id: 373,
		quantity: 499,
	},
	{
		garage_id: 17,
		part_id: 374,
		quantity: 480,
	},
	{
		garage_id: 17,
		part_id: 375,
		quantity: 168,
	},
	{
		garage_id: 17,
		part_id: 376,
		quantity: 457,
	},
	{
		garage_id: 17,
		part_id: 377,
		quantity: 442,
	},
	{
		garage_id: 17,
		part_id: 378,
		quantity: 435,
	},
	{
		garage_id: 17,
		part_id: 379,
		quantity: 452,
	},
	{
		garage_id: 17,
		part_id: 380,
		quantity: 458,
	},
	{
		garage_id: 17,
		part_id: 381,
		quantity: 276,
	},
	{
		garage_id: 17,
		part_id: 382,
		quantity: 500,
	},
	{
		garage_id: 17,
		part_id: 383,
		quantity: 398,
	},
	{
		garage_id: 17,
		part_id: 384,
		quantity: 35,
	},
	{
		garage_id: 17,
		part_id: 385,
		quantity: 148,
	},
	{
		garage_id: 17,
		part_id: 386,
		quantity: 253,
	},
	{
		garage_id: 17,
		part_id: 387,
		quantity: 478,
	},
	{
		garage_id: 17,
		part_id: 388,
		quantity: 155,
	},
	{
		garage_id: 17,
		part_id: 389,
		quantity: 163,
	},
	{
		garage_id: 17,
		part_id: 390,
		quantity: 417,
	},
	{
		garage_id: 17,
		part_id: 391,
		quantity: 235,
	},
	{
		garage_id: 17,
		part_id: 392,
		quantity: 126,
	},
	{
		garage_id: 17,
		part_id: 393,
		quantity: 247,
	},
	{
		garage_id: 17,
		part_id: 394,
		quantity: 131,
	},
	{
		garage_id: 17,
		part_id: 395,
		quantity: 145,
	},
	{
		garage_id: 17,
		part_id: 396,
		quantity: 81,
	},
	{
		garage_id: 17,
		part_id: 397,
		quantity: 431,
	},
	{
		garage_id: 17,
		part_id: 398,
		quantity: 230,
	},
	{
		garage_id: 17,
		part_id: 399,
		quantity: 180,
	},
	{
		garage_id: 18,
		part_id: 0,
		quantity: 36,
	},
	{
		garage_id: 18,
		part_id: 1,
		quantity: 104,
	},
	{
		garage_id: 18,
		part_id: 2,
		quantity: 55,
	},
	{
		garage_id: 18,
		part_id: 3,
		quantity: 401,
	},
	{
		garage_id: 18,
		part_id: 4,
		quantity: 393,
	},
	{
		garage_id: 18,
		part_id: 5,
		quantity: 16,
	},
	{
		garage_id: 18,
		part_id: 6,
		quantity: 48,
	},
	{
		garage_id: 18,
		part_id: 7,
		quantity: 404,
	},
	{
		garage_id: 18,
		part_id: 8,
		quantity: 419,
	},
	{
		garage_id: 18,
		part_id: 9,
		quantity: 465,
	},
	{
		garage_id: 18,
		part_id: 10,
		quantity: 6,
	},
	{
		garage_id: 18,
		part_id: 11,
		quantity: 434,
	},
	{
		garage_id: 18,
		part_id: 12,
		quantity: 380,
	},
	{
		garage_id: 18,
		part_id: 13,
		quantity: 57,
	},
	{
		garage_id: 18,
		part_id: 14,
		quantity: 28,
	},
	{
		garage_id: 18,
		part_id: 15,
		quantity: 7,
	},
	{
		garage_id: 18,
		part_id: 16,
		quantity: 242,
	},
	{
		garage_id: 18,
		part_id: 17,
		quantity: 404,
	},
	{
		garage_id: 18,
		part_id: 18,
		quantity: 58,
	},
	{
		garage_id: 18,
		part_id: 19,
		quantity: 213,
	},
	{
		garage_id: 18,
		part_id: 20,
		quantity: 112,
	},
	{
		garage_id: 18,
		part_id: 21,
		quantity: 40,
	},
	{
		garage_id: 18,
		part_id: 22,
		quantity: 495,
	},
	{
		garage_id: 18,
		part_id: 23,
		quantity: 116,
	},
	{
		garage_id: 18,
		part_id: 24,
		quantity: 37,
	},
	{
		garage_id: 18,
		part_id: 25,
		quantity: 59,
	},
	{
		garage_id: 18,
		part_id: 26,
		quantity: 100,
	},
	{
		garage_id: 18,
		part_id: 27,
		quantity: 178,
	},
	{
		garage_id: 18,
		part_id: 28,
		quantity: 391,
	},
	{
		garage_id: 18,
		part_id: 29,
		quantity: 408,
	},
	{
		garage_id: 18,
		part_id: 30,
		quantity: 213,
	},
	{
		garage_id: 18,
		part_id: 31,
		quantity: 234,
	},
	{
		garage_id: 18,
		part_id: 32,
		quantity: 234,
	},
	{
		garage_id: 18,
		part_id: 33,
		quantity: 241,
	},
	{
		garage_id: 18,
		part_id: 34,
		quantity: 32,
	},
	{
		garage_id: 18,
		part_id: 35,
		quantity: 37,
	},
	{
		garage_id: 18,
		part_id: 36,
		quantity: 367,
	},
	{
		garage_id: 18,
		part_id: 37,
		quantity: 479,
	},
	{
		garage_id: 18,
		part_id: 38,
		quantity: 117,
	},
	{
		garage_id: 18,
		part_id: 39,
		quantity: 115,
	},
	{
		garage_id: 18,
		part_id: 40,
		quantity: 338,
	},
	{
		garage_id: 18,
		part_id: 41,
		quantity: 67,
	},
	{
		garage_id: 18,
		part_id: 42,
		quantity: 466,
	},
	{
		garage_id: 18,
		part_id: 43,
		quantity: 29,
	},
	{
		garage_id: 18,
		part_id: 44,
		quantity: 132,
	},
	{
		garage_id: 18,
		part_id: 45,
		quantity: 77,
	},
	{
		garage_id: 18,
		part_id: 46,
		quantity: 287,
	},
	{
		garage_id: 18,
		part_id: 47,
		quantity: 226,
	},
	{
		garage_id: 18,
		part_id: 48,
		quantity: 4,
	},
	{
		garage_id: 18,
		part_id: 49,
		quantity: 117,
	},
	{
		garage_id: 18,
		part_id: 50,
		quantity: 292,
	},
	{
		garage_id: 18,
		part_id: 51,
		quantity: 197,
	},
	{
		garage_id: 18,
		part_id: 52,
		quantity: 453,
	},
	{
		garage_id: 18,
		part_id: 53,
		quantity: 335,
	},
	{
		garage_id: 18,
		part_id: 54,
		quantity: 425,
	},
	{
		garage_id: 18,
		part_id: 55,
		quantity: 192,
	},
	{
		garage_id: 18,
		part_id: 56,
		quantity: 496,
	},
	{
		garage_id: 18,
		part_id: 57,
		quantity: 126,
	},
	{
		garage_id: 18,
		part_id: 58,
		quantity: 119,
	},
	{
		garage_id: 18,
		part_id: 59,
		quantity: 77,
	},
	{
		garage_id: 18,
		part_id: 60,
		quantity: 204,
	},
	{
		garage_id: 18,
		part_id: 61,
		quantity: 210,
	},
	{
		garage_id: 18,
		part_id: 62,
		quantity: 72,
	},
	{
		garage_id: 18,
		part_id: 63,
		quantity: 9,
	},
	{
		garage_id: 18,
		part_id: 64,
		quantity: 189,
	},
	{
		garage_id: 18,
		part_id: 65,
		quantity: 74,
	},
	{
		garage_id: 18,
		part_id: 66,
		quantity: 187,
	},
	{
		garage_id: 18,
		part_id: 67,
		quantity: 194,
	},
	{
		garage_id: 18,
		part_id: 68,
		quantity: 78,
	},
	{
		garage_id: 18,
		part_id: 69,
		quantity: 65,
	},
	{
		garage_id: 18,
		part_id: 70,
		quantity: 483,
	},
	{
		garage_id: 18,
		part_id: 71,
		quantity: 376,
	},
	{
		garage_id: 18,
		part_id: 72,
		quantity: 295,
	},
	{
		garage_id: 18,
		part_id: 73,
		quantity: 455,
	},
	{
		garage_id: 18,
		part_id: 74,
		quantity: 436,
	},
	{
		garage_id: 18,
		part_id: 75,
		quantity: 203,
	},
	{
		garage_id: 18,
		part_id: 76,
		quantity: 170,
	},
	{
		garage_id: 18,
		part_id: 77,
		quantity: 41,
	},
	{
		garage_id: 18,
		part_id: 78,
		quantity: 173,
	},
	{
		garage_id: 18,
		part_id: 79,
		quantity: 460,
	},
	{
		garage_id: 18,
		part_id: 80,
		quantity: 121,
	},
	{
		garage_id: 18,
		part_id: 81,
		quantity: 439,
	},
	{
		garage_id: 18,
		part_id: 82,
		quantity: 333,
	},
	{
		garage_id: 18,
		part_id: 83,
		quantity: 315,
	},
	{
		garage_id: 18,
		part_id: 84,
		quantity: 248,
	},
	{
		garage_id: 18,
		part_id: 85,
		quantity: 72,
	},
	{
		garage_id: 18,
		part_id: 86,
		quantity: 262,
	},
	{
		garage_id: 18,
		part_id: 87,
		quantity: 30,
	},
	{
		garage_id: 18,
		part_id: 88,
		quantity: 215,
	},
	{
		garage_id: 18,
		part_id: 89,
		quantity: 334,
	},
	{
		garage_id: 18,
		part_id: 90,
		quantity: 46,
	},
	{
		garage_id: 18,
		part_id: 91,
		quantity: 180,
	},
	{
		garage_id: 18,
		part_id: 92,
		quantity: 292,
	},
	{
		garage_id: 18,
		part_id: 93,
		quantity: 383,
	},
	{
		garage_id: 18,
		part_id: 94,
		quantity: 395,
	},
	{
		garage_id: 18,
		part_id: 95,
		quantity: 54,
	},
	{
		garage_id: 18,
		part_id: 96,
		quantity: 197,
	},
	{
		garage_id: 18,
		part_id: 97,
		quantity: 286,
	},
	{
		garage_id: 18,
		part_id: 98,
		quantity: 216,
	},
	{
		garage_id: 18,
		part_id: 99,
		quantity: 212,
	},
	{
		garage_id: 18,
		part_id: 100,
		quantity: 58,
	},
	{
		garage_id: 18,
		part_id: 101,
		quantity: 351,
	},
	{
		garage_id: 18,
		part_id: 102,
		quantity: 466,
	},
	{
		garage_id: 18,
		part_id: 103,
		quantity: 443,
	},
	{
		garage_id: 18,
		part_id: 104,
		quantity: 65,
	},
	{
		garage_id: 18,
		part_id: 105,
		quantity: 273,
	},
	{
		garage_id: 18,
		part_id: 106,
		quantity: 179,
	},
	{
		garage_id: 18,
		part_id: 107,
		quantity: 341,
	},
	{
		garage_id: 18,
		part_id: 108,
		quantity: 190,
	},
	{
		garage_id: 18,
		part_id: 109,
		quantity: 402,
	},
	{
		garage_id: 18,
		part_id: 110,
		quantity: 349,
	},
	{
		garage_id: 18,
		part_id: 111,
		quantity: 372,
	},
	{
		garage_id: 18,
		part_id: 112,
		quantity: 29,
	},
	{
		garage_id: 18,
		part_id: 113,
		quantity: 82,
	},
	{
		garage_id: 18,
		part_id: 114,
		quantity: 289,
	},
	{
		garage_id: 18,
		part_id: 115,
		quantity: 148,
	},
	{
		garage_id: 18,
		part_id: 116,
		quantity: 19,
	},
	{
		garage_id: 18,
		part_id: 117,
		quantity: 211,
	},
	{
		garage_id: 18,
		part_id: 118,
		quantity: 77,
	},
	{
		garage_id: 18,
		part_id: 119,
		quantity: 384,
	},
	{
		garage_id: 18,
		part_id: 120,
		quantity: 322,
	},
	{
		garage_id: 18,
		part_id: 121,
		quantity: 257,
	},
	{
		garage_id: 18,
		part_id: 122,
		quantity: 311,
	},
	{
		garage_id: 18,
		part_id: 123,
		quantity: 351,
	},
	{
		garage_id: 18,
		part_id: 124,
		quantity: 427,
	},
	{
		garage_id: 18,
		part_id: 125,
		quantity: 483,
	},
	{
		garage_id: 18,
		part_id: 126,
		quantity: 435,
	},
	{
		garage_id: 18,
		part_id: 127,
		quantity: 269,
	},
	{
		garage_id: 18,
		part_id: 128,
		quantity: 71,
	},
	{
		garage_id: 18,
		part_id: 129,
		quantity: 397,
	},
	{
		garage_id: 18,
		part_id: 130,
		quantity: 135,
	},
	{
		garage_id: 18,
		part_id: 131,
		quantity: 465,
	},
	{
		garage_id: 18,
		part_id: 132,
		quantity: 390,
	},
	{
		garage_id: 18,
		part_id: 133,
		quantity: 17,
	},
	{
		garage_id: 18,
		part_id: 134,
		quantity: 405,
	},
	{
		garage_id: 18,
		part_id: 135,
		quantity: 137,
	},
	{
		garage_id: 18,
		part_id: 136,
		quantity: 77,
	},
	{
		garage_id: 18,
		part_id: 137,
		quantity: 190,
	},
	{
		garage_id: 18,
		part_id: 138,
		quantity: 364,
	},
	{
		garage_id: 18,
		part_id: 139,
		quantity: 455,
	},
	{
		garage_id: 18,
		part_id: 140,
		quantity: 495,
	},
	{
		garage_id: 18,
		part_id: 141,
		quantity: 54,
	},
	{
		garage_id: 18,
		part_id: 142,
		quantity: 156,
	},
	{
		garage_id: 18,
		part_id: 143,
		quantity: 310,
	},
	{
		garage_id: 18,
		part_id: 144,
		quantity: 249,
	},
	{
		garage_id: 18,
		part_id: 145,
		quantity: 135,
	},
	{
		garage_id: 18,
		part_id: 146,
		quantity: 209,
	},
	{
		garage_id: 18,
		part_id: 147,
		quantity: 106,
	},
	{
		garage_id: 18,
		part_id: 148,
		quantity: 388,
	},
	{
		garage_id: 18,
		part_id: 149,
		quantity: 136,
	},
	{
		garage_id: 18,
		part_id: 150,
		quantity: 293,
	},
	{
		garage_id: 18,
		part_id: 151,
		quantity: 232,
	},
	{
		garage_id: 18,
		part_id: 152,
		quantity: 160,
	},
	{
		garage_id: 18,
		part_id: 153,
		quantity: 117,
	},
	{
		garage_id: 18,
		part_id: 154,
		quantity: 168,
	},
	{
		garage_id: 18,
		part_id: 155,
		quantity: 124,
	},
	{
		garage_id: 18,
		part_id: 156,
		quantity: 234,
	},
	{
		garage_id: 18,
		part_id: 157,
		quantity: 144,
	},
	{
		garage_id: 18,
		part_id: 158,
		quantity: 290,
	},
	{
		garage_id: 18,
		part_id: 159,
		quantity: 169,
	},
	{
		garage_id: 18,
		part_id: 160,
		quantity: 450,
	},
	{
		garage_id: 18,
		part_id: 161,
		quantity: 64,
	},
	{
		garage_id: 18,
		part_id: 162,
		quantity: 190,
	},
	{
		garage_id: 18,
		part_id: 163,
		quantity: 95,
	},
	{
		garage_id: 18,
		part_id: 164,
		quantity: 432,
	},
	{
		garage_id: 18,
		part_id: 165,
		quantity: 450,
	},
	{
		garage_id: 18,
		part_id: 166,
		quantity: 333,
	},
	{
		garage_id: 18,
		part_id: 167,
		quantity: 479,
	},
	{
		garage_id: 18,
		part_id: 168,
		quantity: 174,
	},
	{
		garage_id: 18,
		part_id: 169,
		quantity: 220,
	},
	{
		garage_id: 18,
		part_id: 170,
		quantity: 6,
	},
	{
		garage_id: 18,
		part_id: 171,
		quantity: 261,
	},
	{
		garage_id: 18,
		part_id: 172,
		quantity: 262,
	},
	{
		garage_id: 18,
		part_id: 173,
		quantity: 150,
	},
	{
		garage_id: 18,
		part_id: 174,
		quantity: 250,
	},
	{
		garage_id: 18,
		part_id: 175,
		quantity: 350,
	},
	{
		garage_id: 18,
		part_id: 176,
		quantity: 337,
	},
	{
		garage_id: 18,
		part_id: 177,
		quantity: 1,
	},
	{
		garage_id: 18,
		part_id: 178,
		quantity: 248,
	},
	{
		garage_id: 18,
		part_id: 179,
		quantity: 332,
	},
	{
		garage_id: 18,
		part_id: 180,
		quantity: 44,
	},
	{
		garage_id: 18,
		part_id: 181,
		quantity: 56,
	},
	{
		garage_id: 18,
		part_id: 182,
		quantity: 237,
	},
	{
		garage_id: 18,
		part_id: 183,
		quantity: 473,
	},
	{
		garage_id: 18,
		part_id: 184,
		quantity: 201,
	},
	{
		garage_id: 18,
		part_id: 185,
		quantity: 106,
	},
	{
		garage_id: 18,
		part_id: 186,
		quantity: 405,
	},
	{
		garage_id: 18,
		part_id: 187,
		quantity: 104,
	},
	{
		garage_id: 18,
		part_id: 188,
		quantity: 221,
	},
	{
		garage_id: 18,
		part_id: 189,
		quantity: 469,
	},
	{
		garage_id: 18,
		part_id: 190,
		quantity: 382,
	},
	{
		garage_id: 18,
		part_id: 191,
		quantity: 193,
	},
	{
		garage_id: 18,
		part_id: 192,
		quantity: 51,
	},
	{
		garage_id: 18,
		part_id: 193,
		quantity: 283,
	},
	{
		garage_id: 18,
		part_id: 194,
		quantity: 336,
	},
	{
		garage_id: 18,
		part_id: 195,
		quantity: 333,
	},
	{
		garage_id: 18,
		part_id: 196,
		quantity: 33,
	},
	{
		garage_id: 18,
		part_id: 197,
		quantity: 188,
	},
	{
		garage_id: 18,
		part_id: 198,
		quantity: 33,
	},
	{
		garage_id: 18,
		part_id: 199,
		quantity: 172,
	},
	{
		garage_id: 18,
		part_id: 200,
		quantity: 300,
	},
	{
		garage_id: 18,
		part_id: 201,
		quantity: 468,
	},
	{
		garage_id: 18,
		part_id: 202,
		quantity: 150,
	},
	{
		garage_id: 18,
		part_id: 203,
		quantity: 244,
	},
	{
		garage_id: 18,
		part_id: 204,
		quantity: 74,
	},
	{
		garage_id: 18,
		part_id: 205,
		quantity: 280,
	},
	{
		garage_id: 18,
		part_id: 206,
		quantity: 135,
	},
	{
		garage_id: 18,
		part_id: 207,
		quantity: 454,
	},
	{
		garage_id: 18,
		part_id: 208,
		quantity: 45,
	},
	{
		garage_id: 18,
		part_id: 209,
		quantity: 92,
	},
	{
		garage_id: 18,
		part_id: 210,
		quantity: 291,
	},
	{
		garage_id: 18,
		part_id: 211,
		quantity: 467,
	},
	{
		garage_id: 18,
		part_id: 212,
		quantity: 163,
	},
	{
		garage_id: 18,
		part_id: 213,
		quantity: 460,
	},
	{
		garage_id: 18,
		part_id: 214,
		quantity: 264,
	},
	{
		garage_id: 18,
		part_id: 215,
		quantity: 185,
	},
	{
		garage_id: 18,
		part_id: 216,
		quantity: 170,
	},
	{
		garage_id: 18,
		part_id: 217,
		quantity: 408,
	},
	{
		garage_id: 18,
		part_id: 218,
		quantity: 390,
	},
	{
		garage_id: 18,
		part_id: 219,
		quantity: 346,
	},
	{
		garage_id: 18,
		part_id: 220,
		quantity: 107,
	},
	{
		garage_id: 18,
		part_id: 221,
		quantity: 10,
	},
	{
		garage_id: 18,
		part_id: 222,
		quantity: 11,
	},
	{
		garage_id: 18,
		part_id: 223,
		quantity: 36,
	},
	{
		garage_id: 18,
		part_id: 224,
		quantity: 291,
	},
	{
		garage_id: 18,
		part_id: 225,
		quantity: 281,
	},
	{
		garage_id: 18,
		part_id: 226,
		quantity: 107,
	},
	{
		garage_id: 18,
		part_id: 227,
		quantity: 205,
	},
	{
		garage_id: 18,
		part_id: 228,
		quantity: 96,
	},
	{
		garage_id: 18,
		part_id: 229,
		quantity: 449,
	},
	{
		garage_id: 18,
		part_id: 230,
		quantity: 495,
	},
	{
		garage_id: 18,
		part_id: 231,
		quantity: 224,
	},
	{
		garage_id: 18,
		part_id: 232,
		quantity: 459,
	},
	{
		garage_id: 18,
		part_id: 233,
		quantity: 201,
	},
	{
		garage_id: 18,
		part_id: 234,
		quantity: 417,
	},
	{
		garage_id: 18,
		part_id: 235,
		quantity: 14,
	},
	{
		garage_id: 18,
		part_id: 236,
		quantity: 448,
	},
	{
		garage_id: 18,
		part_id: 237,
		quantity: 16,
	},
	{
		garage_id: 18,
		part_id: 238,
		quantity: 173,
	},
	{
		garage_id: 18,
		part_id: 239,
		quantity: 262,
	},
	{
		garage_id: 18,
		part_id: 240,
		quantity: 305,
	},
	{
		garage_id: 18,
		part_id: 241,
		quantity: 152,
	},
	{
		garage_id: 18,
		part_id: 242,
		quantity: 188,
	},
	{
		garage_id: 18,
		part_id: 243,
		quantity: 480,
	},
	{
		garage_id: 18,
		part_id: 244,
		quantity: 179,
	},
	{
		garage_id: 18,
		part_id: 245,
		quantity: 390,
	},
	{
		garage_id: 18,
		part_id: 246,
		quantity: 39,
	},
	{
		garage_id: 18,
		part_id: 247,
		quantity: 209,
	},
	{
		garage_id: 18,
		part_id: 248,
		quantity: 15,
	},
	{
		garage_id: 18,
		part_id: 249,
		quantity: 293,
	},
	{
		garage_id: 18,
		part_id: 250,
		quantity: 35,
	},
	{
		garage_id: 18,
		part_id: 251,
		quantity: 42,
	},
	{
		garage_id: 18,
		part_id: 252,
		quantity: 237,
	},
	{
		garage_id: 18,
		part_id: 253,
		quantity: 361,
	},
	{
		garage_id: 18,
		part_id: 254,
		quantity: 413,
	},
	{
		garage_id: 18,
		part_id: 255,
		quantity: 315,
	},
	{
		garage_id: 18,
		part_id: 256,
		quantity: 390,
	},
	{
		garage_id: 18,
		part_id: 257,
		quantity: 142,
	},
	{
		garage_id: 18,
		part_id: 258,
		quantity: 312,
	},
	{
		garage_id: 18,
		part_id: 259,
		quantity: 380,
	},
	{
		garage_id: 18,
		part_id: 260,
		quantity: 107,
	},
	{
		garage_id: 18,
		part_id: 261,
		quantity: 496,
	},
	{
		garage_id: 18,
		part_id: 262,
		quantity: 268,
	},
	{
		garage_id: 18,
		part_id: 263,
		quantity: 89,
	},
	{
		garage_id: 18,
		part_id: 264,
		quantity: 98,
	},
	{
		garage_id: 18,
		part_id: 265,
		quantity: 81,
	},
	{
		garage_id: 18,
		part_id: 266,
		quantity: 360,
	},
	{
		garage_id: 18,
		part_id: 267,
		quantity: 283,
	},
	{
		garage_id: 18,
		part_id: 268,
		quantity: 231,
	},
	{
		garage_id: 18,
		part_id: 269,
		quantity: 489,
	},
	{
		garage_id: 18,
		part_id: 270,
		quantity: 456,
	},
	{
		garage_id: 18,
		part_id: 271,
		quantity: 48,
	},
	{
		garage_id: 18,
		part_id: 272,
		quantity: 179,
	},
	{
		garage_id: 18,
		part_id: 273,
		quantity: 310,
	},
	{
		garage_id: 18,
		part_id: 274,
		quantity: 376,
	},
	{
		garage_id: 18,
		part_id: 275,
		quantity: 378,
	},
	{
		garage_id: 18,
		part_id: 276,
		quantity: 177,
	},
	{
		garage_id: 18,
		part_id: 277,
		quantity: 181,
	},
	{
		garage_id: 18,
		part_id: 278,
		quantity: 131,
	},
	{
		garage_id: 18,
		part_id: 279,
		quantity: 247,
	},
	{
		garage_id: 18,
		part_id: 280,
		quantity: 57,
	},
	{
		garage_id: 18,
		part_id: 281,
		quantity: 248,
	},
	{
		garage_id: 18,
		part_id: 282,
		quantity: 175,
	},
	{
		garage_id: 18,
		part_id: 283,
		quantity: 274,
	},
	{
		garage_id: 18,
		part_id: 284,
		quantity: 154,
	},
	{
		garage_id: 18,
		part_id: 285,
		quantity: 336,
	},
	{
		garage_id: 18,
		part_id: 286,
		quantity: 225,
	},
	{
		garage_id: 18,
		part_id: 287,
		quantity: 25,
	},
	{
		garage_id: 18,
		part_id: 288,
		quantity: 38,
	},
	{
		garage_id: 18,
		part_id: 289,
		quantity: 296,
	},
	{
		garage_id: 18,
		part_id: 290,
		quantity: 390,
	},
	{
		garage_id: 18,
		part_id: 291,
		quantity: 250,
	},
	{
		garage_id: 18,
		part_id: 292,
		quantity: 269,
	},
	{
		garage_id: 18,
		part_id: 293,
		quantity: 412,
	},
	{
		garage_id: 18,
		part_id: 294,
		quantity: 281,
	},
	{
		garage_id: 18,
		part_id: 295,
		quantity: 53,
	},
	{
		garage_id: 18,
		part_id: 296,
		quantity: 47,
	},
	{
		garage_id: 18,
		part_id: 297,
		quantity: 16,
	},
	{
		garage_id: 18,
		part_id: 298,
		quantity: 296,
	},
	{
		garage_id: 18,
		part_id: 299,
		quantity: 13,
	},
	{
		garage_id: 18,
		part_id: 300,
		quantity: 363,
	},
	{
		garage_id: 18,
		part_id: 301,
		quantity: 307,
	},
	{
		garage_id: 18,
		part_id: 302,
		quantity: 6,
	},
	{
		garage_id: 18,
		part_id: 303,
		quantity: 391,
	},
	{
		garage_id: 18,
		part_id: 304,
		quantity: 340,
	},
	{
		garage_id: 18,
		part_id: 305,
		quantity: 156,
	},
	{
		garage_id: 18,
		part_id: 306,
		quantity: 102,
	},
	{
		garage_id: 18,
		part_id: 307,
		quantity: 127,
	},
	{
		garage_id: 18,
		part_id: 308,
		quantity: 255,
	},
	{
		garage_id: 18,
		part_id: 309,
		quantity: 30,
	},
	{
		garage_id: 18,
		part_id: 310,
		quantity: 462,
	},
	{
		garage_id: 18,
		part_id: 311,
		quantity: 195,
	},
	{
		garage_id: 18,
		part_id: 312,
		quantity: 470,
	},
	{
		garage_id: 18,
		part_id: 313,
		quantity: 37,
	},
	{
		garage_id: 18,
		part_id: 314,
		quantity: 423,
	},
	{
		garage_id: 18,
		part_id: 315,
		quantity: 313,
	},
	{
		garage_id: 18,
		part_id: 316,
		quantity: 447,
	},
	{
		garage_id: 18,
		part_id: 317,
		quantity: 465,
	},
	{
		garage_id: 18,
		part_id: 318,
		quantity: 474,
	},
	{
		garage_id: 18,
		part_id: 319,
		quantity: 229,
	},
	{
		garage_id: 18,
		part_id: 320,
		quantity: 277,
	},
	{
		garage_id: 18,
		part_id: 321,
		quantity: 228,
	},
	{
		garage_id: 18,
		part_id: 322,
		quantity: 94,
	},
	{
		garage_id: 18,
		part_id: 323,
		quantity: 284,
	},
	{
		garage_id: 18,
		part_id: 324,
		quantity: 354,
	},
	{
		garage_id: 18,
		part_id: 325,
		quantity: 88,
	},
	{
		garage_id: 18,
		part_id: 326,
		quantity: 329,
	},
	{
		garage_id: 18,
		part_id: 327,
		quantity: 49,
	},
	{
		garage_id: 18,
		part_id: 328,
		quantity: 233,
	},
	{
		garage_id: 18,
		part_id: 329,
		quantity: 147,
	},
	{
		garage_id: 18,
		part_id: 330,
		quantity: 248,
	},
	{
		garage_id: 18,
		part_id: 331,
		quantity: 246,
	},
	{
		garage_id: 18,
		part_id: 332,
		quantity: 317,
	},
	{
		garage_id: 18,
		part_id: 333,
		quantity: 433,
	},
	{
		garage_id: 18,
		part_id: 334,
		quantity: 419,
	},
	{
		garage_id: 18,
		part_id: 335,
		quantity: 121,
	},
	{
		garage_id: 18,
		part_id: 336,
		quantity: 499,
	},
	{
		garage_id: 18,
		part_id: 337,
		quantity: 361,
	},
	{
		garage_id: 18,
		part_id: 338,
		quantity: 113,
	},
	{
		garage_id: 18,
		part_id: 339,
		quantity: 201,
	},
	{
		garage_id: 18,
		part_id: 340,
		quantity: 226,
	},
	{
		garage_id: 18,
		part_id: 341,
		quantity: 414,
	},
	{
		garage_id: 18,
		part_id: 342,
		quantity: 220,
	},
	{
		garage_id: 18,
		part_id: 343,
		quantity: 45,
	},
	{
		garage_id: 18,
		part_id: 344,
		quantity: 56,
	},
	{
		garage_id: 18,
		part_id: 345,
		quantity: 347,
	},
	{
		garage_id: 18,
		part_id: 346,
		quantity: 255,
	},
	{
		garage_id: 18,
		part_id: 347,
		quantity: 300,
	},
	{
		garage_id: 18,
		part_id: 348,
		quantity: 356,
	},
	{
		garage_id: 18,
		part_id: 349,
		quantity: 214,
	},
	{
		garage_id: 18,
		part_id: 350,
		quantity: 59,
	},
	{
		garage_id: 18,
		part_id: 351,
		quantity: 208,
	},
	{
		garage_id: 18,
		part_id: 352,
		quantity: 361,
	},
	{
		garage_id: 18,
		part_id: 353,
		quantity: 371,
	},
	{
		garage_id: 18,
		part_id: 354,
		quantity: 226,
	},
	{
		garage_id: 18,
		part_id: 355,
		quantity: 220,
	},
	{
		garage_id: 18,
		part_id: 356,
		quantity: 192,
	},
	{
		garage_id: 18,
		part_id: 357,
		quantity: 243,
	},
	{
		garage_id: 18,
		part_id: 358,
		quantity: 353,
	},
	{
		garage_id: 18,
		part_id: 359,
		quantity: 53,
	},
	{
		garage_id: 18,
		part_id: 360,
		quantity: 500,
	},
	{
		garage_id: 18,
		part_id: 361,
		quantity: 182,
	},
	{
		garage_id: 18,
		part_id: 362,
		quantity: 453,
	},
	{
		garage_id: 18,
		part_id: 363,
		quantity: 405,
	},
	{
		garage_id: 18,
		part_id: 364,
		quantity: 241,
	},
	{
		garage_id: 18,
		part_id: 365,
		quantity: 54,
	},
	{
		garage_id: 18,
		part_id: 366,
		quantity: 496,
	},
	{
		garage_id: 18,
		part_id: 367,
		quantity: 412,
	},
	{
		garage_id: 18,
		part_id: 368,
		quantity: 494,
	},
	{
		garage_id: 18,
		part_id: 369,
		quantity: 265,
	},
	{
		garage_id: 18,
		part_id: 370,
		quantity: 235,
	},
	{
		garage_id: 18,
		part_id: 371,
		quantity: 61,
	},
	{
		garage_id: 18,
		part_id: 372,
		quantity: 287,
	},
	{
		garage_id: 18,
		part_id: 373,
		quantity: 404,
	},
	{
		garage_id: 18,
		part_id: 374,
		quantity: 72,
	},
	{
		garage_id: 18,
		part_id: 375,
		quantity: 23,
	},
	{
		garage_id: 18,
		part_id: 376,
		quantity: 62,
	},
	{
		garage_id: 18,
		part_id: 377,
		quantity: 226,
	},
	{
		garage_id: 18,
		part_id: 378,
		quantity: 273,
	},
	{
		garage_id: 18,
		part_id: 379,
		quantity: 431,
	},
	{
		garage_id: 18,
		part_id: 380,
		quantity: 409,
	},
	{
		garage_id: 18,
		part_id: 381,
		quantity: 262,
	},
	{
		garage_id: 18,
		part_id: 382,
		quantity: 489,
	},
	{
		garage_id: 18,
		part_id: 383,
		quantity: 119,
	},
	{
		garage_id: 18,
		part_id: 384,
		quantity: 248,
	},
	{
		garage_id: 18,
		part_id: 385,
		quantity: 172,
	},
	{
		garage_id: 18,
		part_id: 386,
		quantity: 280,
	},
	{
		garage_id: 18,
		part_id: 387,
		quantity: 250,
	},
	{
		garage_id: 18,
		part_id: 388,
		quantity: 167,
	},
	{
		garage_id: 18,
		part_id: 389,
		quantity: 154,
	},
	{
		garage_id: 18,
		part_id: 390,
		quantity: 160,
	},
	{
		garage_id: 18,
		part_id: 391,
		quantity: 302,
	},
	{
		garage_id: 18,
		part_id: 392,
		quantity: 192,
	},
	{
		garage_id: 18,
		part_id: 393,
		quantity: 43,
	},
	{
		garage_id: 18,
		part_id: 394,
		quantity: 454,
	},
	{
		garage_id: 18,
		part_id: 395,
		quantity: 346,
	},
	{
		garage_id: 18,
		part_id: 396,
		quantity: 288,
	},
	{
		garage_id: 18,
		part_id: 397,
		quantity: 24,
	},
	{
		garage_id: 18,
		part_id: 398,
		quantity: 80,
	},
	{
		garage_id: 18,
		part_id: 399,
		quantity: 86,
	},
	{
		garage_id: 19,
		part_id: 0,
		quantity: 253,
	},
	{
		garage_id: 19,
		part_id: 1,
		quantity: 476,
	},
	{
		garage_id: 19,
		part_id: 2,
		quantity: 306,
	},
	{
		garage_id: 19,
		part_id: 3,
		quantity: 125,
	},
	{
		garage_id: 19,
		part_id: 4,
		quantity: 152,
	},
	{
		garage_id: 19,
		part_id: 5,
		quantity: 361,
	},
	{
		garage_id: 19,
		part_id: 6,
		quantity: 217,
	},
	{
		garage_id: 19,
		part_id: 7,
		quantity: 241,
	},
	{
		garage_id: 19,
		part_id: 8,
		quantity: 491,
	},
	{
		garage_id: 19,
		part_id: 9,
		quantity: 465,
	},
	{
		garage_id: 19,
		part_id: 10,
		quantity: 154,
	},
	{
		garage_id: 19,
		part_id: 11,
		quantity: 266,
	},
	{
		garage_id: 19,
		part_id: 12,
		quantity: 354,
	},
	{
		garage_id: 19,
		part_id: 13,
		quantity: 271,
	},
	{
		garage_id: 19,
		part_id: 14,
		quantity: 167,
	},
	{
		garage_id: 19,
		part_id: 15,
		quantity: 58,
	},
	{
		garage_id: 19,
		part_id: 16,
		quantity: 278,
	},
	{
		garage_id: 19,
		part_id: 17,
		quantity: 261,
	},
	{
		garage_id: 19,
		part_id: 18,
		quantity: 179,
	},
	{
		garage_id: 19,
		part_id: 19,
		quantity: 44,
	},
	{
		garage_id: 19,
		part_id: 20,
		quantity: 96,
	},
	{
		garage_id: 19,
		part_id: 21,
		quantity: 485,
	},
	{
		garage_id: 19,
		part_id: 22,
		quantity: 196,
	},
	{
		garage_id: 19,
		part_id: 23,
		quantity: 113,
	},
	{
		garage_id: 19,
		part_id: 24,
		quantity: 215,
	},
	{
		garage_id: 19,
		part_id: 25,
		quantity: 455,
	},
	{
		garage_id: 19,
		part_id: 26,
		quantity: 480,
	},
	{
		garage_id: 19,
		part_id: 27,
		quantity: 82,
	},
	{
		garage_id: 19,
		part_id: 28,
		quantity: 39,
	},
	{
		garage_id: 19,
		part_id: 29,
		quantity: 109,
	},
	{
		garage_id: 19,
		part_id: 30,
		quantity: 251,
	},
	{
		garage_id: 19,
		part_id: 31,
		quantity: 316,
	},
	{
		garage_id: 19,
		part_id: 32,
		quantity: 201,
	},
	{
		garage_id: 19,
		part_id: 33,
		quantity: 312,
	},
	{
		garage_id: 19,
		part_id: 34,
		quantity: 103,
	},
	{
		garage_id: 19,
		part_id: 35,
		quantity: 454,
	},
	{
		garage_id: 19,
		part_id: 36,
		quantity: 51,
	},
	{
		garage_id: 19,
		part_id: 37,
		quantity: 49,
	},
	{
		garage_id: 19,
		part_id: 38,
		quantity: 251,
	},
	{
		garage_id: 19,
		part_id: 39,
		quantity: 245,
	},
	{
		garage_id: 19,
		part_id: 40,
		quantity: 402,
	},
	{
		garage_id: 19,
		part_id: 41,
		quantity: 101,
	},
	{
		garage_id: 19,
		part_id: 42,
		quantity: 417,
	},
	{
		garage_id: 19,
		part_id: 43,
		quantity: 161,
	},
	{
		garage_id: 19,
		part_id: 44,
		quantity: 175,
	},
	{
		garage_id: 19,
		part_id: 45,
		quantity: 177,
	},
	{
		garage_id: 19,
		part_id: 46,
		quantity: 407,
	},
	{
		garage_id: 19,
		part_id: 47,
		quantity: 215,
	},
	{
		garage_id: 19,
		part_id: 48,
		quantity: 118,
	},
	{
		garage_id: 19,
		part_id: 49,
		quantity: 194,
	},
	{
		garage_id: 19,
		part_id: 50,
		quantity: 291,
	},
	{
		garage_id: 19,
		part_id: 51,
		quantity: 106,
	},
	{
		garage_id: 19,
		part_id: 52,
		quantity: 131,
	},
	{
		garage_id: 19,
		part_id: 53,
		quantity: 246,
	},
	{
		garage_id: 19,
		part_id: 54,
		quantity: 361,
	},
	{
		garage_id: 19,
		part_id: 55,
		quantity: 500,
	},
	{
		garage_id: 19,
		part_id: 56,
		quantity: 292,
	},
	{
		garage_id: 19,
		part_id: 57,
		quantity: 345,
	},
	{
		garage_id: 19,
		part_id: 58,
		quantity: 474,
	},
	{
		garage_id: 19,
		part_id: 59,
		quantity: 136,
	},
	{
		garage_id: 19,
		part_id: 60,
		quantity: 128,
	},
	{
		garage_id: 19,
		part_id: 61,
		quantity: 25,
	},
	{
		garage_id: 19,
		part_id: 62,
		quantity: 359,
	},
	{
		garage_id: 19,
		part_id: 63,
		quantity: 99,
	},
	{
		garage_id: 19,
		part_id: 64,
		quantity: 105,
	},
	{
		garage_id: 19,
		part_id: 65,
		quantity: 124,
	},
	{
		garage_id: 19,
		part_id: 66,
		quantity: 213,
	},
	{
		garage_id: 19,
		part_id: 67,
		quantity: 453,
	},
	{
		garage_id: 19,
		part_id: 68,
		quantity: 232,
	},
	{
		garage_id: 19,
		part_id: 69,
		quantity: 20,
	},
	{
		garage_id: 19,
		part_id: 70,
		quantity: 86,
	},
	{
		garage_id: 19,
		part_id: 71,
		quantity: 197,
	},
	{
		garage_id: 19,
		part_id: 72,
		quantity: 52,
	},
	{
		garage_id: 19,
		part_id: 73,
		quantity: 358,
	},
	{
		garage_id: 19,
		part_id: 74,
		quantity: 103,
	},
	{
		garage_id: 19,
		part_id: 75,
		quantity: 480,
	},
	{
		garage_id: 19,
		part_id: 76,
		quantity: 438,
	},
	{
		garage_id: 19,
		part_id: 77,
		quantity: 255,
	},
	{
		garage_id: 19,
		part_id: 78,
		quantity: 202,
	},
	{
		garage_id: 19,
		part_id: 79,
		quantity: 34,
	},
	{
		garage_id: 19,
		part_id: 80,
		quantity: 31,
	},
	{
		garage_id: 19,
		part_id: 81,
		quantity: 447,
	},
	{
		garage_id: 19,
		part_id: 82,
		quantity: 114,
	},
	{
		garage_id: 19,
		part_id: 83,
		quantity: 164,
	},
	{
		garage_id: 19,
		part_id: 84,
		quantity: 93,
	},
	{
		garage_id: 19,
		part_id: 85,
		quantity: 96,
	},
	{
		garage_id: 19,
		part_id: 86,
		quantity: 320,
	},
	{
		garage_id: 19,
		part_id: 87,
		quantity: 460,
	},
	{
		garage_id: 19,
		part_id: 88,
		quantity: 109,
	},
	{
		garage_id: 19,
		part_id: 89,
		quantity: 41,
	},
	{
		garage_id: 19,
		part_id: 90,
		quantity: 307,
	},
	{
		garage_id: 19,
		part_id: 91,
		quantity: 5,
	},
	{
		garage_id: 19,
		part_id: 92,
		quantity: 252,
	},
	{
		garage_id: 19,
		part_id: 93,
		quantity: 358,
	},
	{
		garage_id: 19,
		part_id: 94,
		quantity: 88,
	},
	{
		garage_id: 19,
		part_id: 95,
		quantity: 438,
	},
	{
		garage_id: 19,
		part_id: 96,
		quantity: 302,
	},
	{
		garage_id: 19,
		part_id: 97,
		quantity: 239,
	},
	{
		garage_id: 19,
		part_id: 98,
		quantity: 0,
	},
	{
		garage_id: 19,
		part_id: 99,
		quantity: 59,
	},
	{
		garage_id: 19,
		part_id: 100,
		quantity: 476,
	},
	{
		garage_id: 19,
		part_id: 101,
		quantity: 16,
	},
	{
		garage_id: 19,
		part_id: 102,
		quantity: 127,
	},
	{
		garage_id: 19,
		part_id: 103,
		quantity: 392,
	},
	{
		garage_id: 19,
		part_id: 104,
		quantity: 340,
	},
	{
		garage_id: 19,
		part_id: 105,
		quantity: 120,
	},
	{
		garage_id: 19,
		part_id: 106,
		quantity: 488,
	},
	{
		garage_id: 19,
		part_id: 107,
		quantity: 440,
	},
	{
		garage_id: 19,
		part_id: 108,
		quantity: 455,
	},
	{
		garage_id: 19,
		part_id: 109,
		quantity: 263,
	},
	{
		garage_id: 19,
		part_id: 110,
		quantity: 290,
	},
	{
		garage_id: 19,
		part_id: 111,
		quantity: 155,
	},
	{
		garage_id: 19,
		part_id: 112,
		quantity: 280,
	},
	{
		garage_id: 19,
		part_id: 113,
		quantity: 11,
	},
	{
		garage_id: 19,
		part_id: 114,
		quantity: 121,
	},
	{
		garage_id: 19,
		part_id: 115,
		quantity: 243,
	},
	{
		garage_id: 19,
		part_id: 116,
		quantity: 100,
	},
	{
		garage_id: 19,
		part_id: 117,
		quantity: 205,
	},
	{
		garage_id: 19,
		part_id: 118,
		quantity: 33,
	},
	{
		garage_id: 19,
		part_id: 119,
		quantity: 2,
	},
	{
		garage_id: 19,
		part_id: 120,
		quantity: 40,
	},
	{
		garage_id: 19,
		part_id: 121,
		quantity: 279,
	},
	{
		garage_id: 19,
		part_id: 122,
		quantity: 25,
	},
	{
		garage_id: 19,
		part_id: 123,
		quantity: 59,
	},
	{
		garage_id: 19,
		part_id: 124,
		quantity: 310,
	},
	{
		garage_id: 19,
		part_id: 125,
		quantity: 356,
	},
	{
		garage_id: 19,
		part_id: 126,
		quantity: 217,
	},
	{
		garage_id: 19,
		part_id: 127,
		quantity: 73,
	},
	{
		garage_id: 19,
		part_id: 128,
		quantity: 79,
	},
	{
		garage_id: 19,
		part_id: 129,
		quantity: 209,
	},
	{
		garage_id: 19,
		part_id: 130,
		quantity: 456,
	},
	{
		garage_id: 19,
		part_id: 131,
		quantity: 6,
	},
	{
		garage_id: 19,
		part_id: 132,
		quantity: 344,
	},
	{
		garage_id: 19,
		part_id: 133,
		quantity: 429,
	},
	{
		garage_id: 19,
		part_id: 134,
		quantity: 383,
	},
	{
		garage_id: 19,
		part_id: 135,
		quantity: 283,
	},
	{
		garage_id: 19,
		part_id: 136,
		quantity: 43,
	},
	{
		garage_id: 19,
		part_id: 137,
		quantity: 359,
	},
	{
		garage_id: 19,
		part_id: 138,
		quantity: 297,
	},
	{
		garage_id: 19,
		part_id: 139,
		quantity: 173,
	},
	{
		garage_id: 19,
		part_id: 140,
		quantity: 352,
	},
	{
		garage_id: 19,
		part_id: 141,
		quantity: 360,
	},
	{
		garage_id: 19,
		part_id: 142,
		quantity: 455,
	},
	{
		garage_id: 19,
		part_id: 143,
		quantity: 301,
	},
	{
		garage_id: 19,
		part_id: 144,
		quantity: 484,
	},
	{
		garage_id: 19,
		part_id: 145,
		quantity: 379,
	},
	{
		garage_id: 19,
		part_id: 146,
		quantity: 283,
	},
	{
		garage_id: 19,
		part_id: 147,
		quantity: 187,
	},
	{
		garage_id: 19,
		part_id: 148,
		quantity: 55,
	},
	{
		garage_id: 19,
		part_id: 149,
		quantity: 264,
	},
	{
		garage_id: 19,
		part_id: 150,
		quantity: 481,
	},
	{
		garage_id: 19,
		part_id: 151,
		quantity: 223,
	},
	{
		garage_id: 19,
		part_id: 152,
		quantity: 404,
	},
	{
		garage_id: 19,
		part_id: 153,
		quantity: 230,
	},
	{
		garage_id: 19,
		part_id: 154,
		quantity: 388,
	},
	{
		garage_id: 19,
		part_id: 155,
		quantity: 198,
	},
	{
		garage_id: 19,
		part_id: 156,
		quantity: 206,
	},
	{
		garage_id: 19,
		part_id: 157,
		quantity: 452,
	},
	{
		garage_id: 19,
		part_id: 158,
		quantity: 249,
	},
	{
		garage_id: 19,
		part_id: 159,
		quantity: 153,
	},
	{
		garage_id: 19,
		part_id: 160,
		quantity: 292,
	},
	{
		garage_id: 19,
		part_id: 161,
		quantity: 325,
	},
	{
		garage_id: 19,
		part_id: 162,
		quantity: 170,
	},
	{
		garage_id: 19,
		part_id: 163,
		quantity: 56,
	},
	{
		garage_id: 19,
		part_id: 164,
		quantity: 110,
	},
	{
		garage_id: 19,
		part_id: 165,
		quantity: 138,
	},
	{
		garage_id: 19,
		part_id: 166,
		quantity: 383,
	},
	{
		garage_id: 19,
		part_id: 167,
		quantity: 310,
	},
	{
		garage_id: 19,
		part_id: 168,
		quantity: 286,
	},
	{
		garage_id: 19,
		part_id: 169,
		quantity: 175,
	},
	{
		garage_id: 19,
		part_id: 170,
		quantity: 407,
	},
	{
		garage_id: 19,
		part_id: 171,
		quantity: 319,
	},
	{
		garage_id: 19,
		part_id: 172,
		quantity: 273,
	},
	{
		garage_id: 19,
		part_id: 173,
		quantity: 436,
	},
	{
		garage_id: 19,
		part_id: 174,
		quantity: 70,
	},
	{
		garage_id: 19,
		part_id: 175,
		quantity: 234,
	},
	{
		garage_id: 19,
		part_id: 176,
		quantity: 409,
	},
	{
		garage_id: 19,
		part_id: 177,
		quantity: 438,
	},
	{
		garage_id: 19,
		part_id: 178,
		quantity: 324,
	},
	{
		garage_id: 19,
		part_id: 179,
		quantity: 113,
	},
	{
		garage_id: 19,
		part_id: 180,
		quantity: 68,
	},
	{
		garage_id: 19,
		part_id: 181,
		quantity: 106,
	},
	{
		garage_id: 19,
		part_id: 182,
		quantity: 234,
	},
	{
		garage_id: 19,
		part_id: 183,
		quantity: 228,
	},
	{
		garage_id: 19,
		part_id: 184,
		quantity: 215,
	},
	{
		garage_id: 19,
		part_id: 185,
		quantity: 347,
	},
	{
		garage_id: 19,
		part_id: 186,
		quantity: 358,
	},
	{
		garage_id: 19,
		part_id: 187,
		quantity: 281,
	},
	{
		garage_id: 19,
		part_id: 188,
		quantity: 418,
	},
	{
		garage_id: 19,
		part_id: 189,
		quantity: 112,
	},
	{
		garage_id: 19,
		part_id: 190,
		quantity: 451,
	},
	{
		garage_id: 19,
		part_id: 191,
		quantity: 84,
	},
	{
		garage_id: 19,
		part_id: 192,
		quantity: 352,
	},
	{
		garage_id: 19,
		part_id: 193,
		quantity: 207,
	},
	{
		garage_id: 19,
		part_id: 194,
		quantity: 275,
	},
	{
		garage_id: 19,
		part_id: 195,
		quantity: 282,
	},
	{
		garage_id: 19,
		part_id: 196,
		quantity: 385,
	},
	{
		garage_id: 19,
		part_id: 197,
		quantity: 205,
	},
	{
		garage_id: 19,
		part_id: 198,
		quantity: 88,
	},
	{
		garage_id: 19,
		part_id: 199,
		quantity: 62,
	},
	{
		garage_id: 19,
		part_id: 200,
		quantity: 364,
	},
	{
		garage_id: 19,
		part_id: 201,
		quantity: 294,
	},
	{
		garage_id: 19,
		part_id: 202,
		quantity: 374,
	},
	{
		garage_id: 19,
		part_id: 203,
		quantity: 462,
	},
	{
		garage_id: 19,
		part_id: 204,
		quantity: 426,
	},
	{
		garage_id: 19,
		part_id: 205,
		quantity: 143,
	},
	{
		garage_id: 19,
		part_id: 206,
		quantity: 197,
	},
	{
		garage_id: 19,
		part_id: 207,
		quantity: 60,
	},
	{
		garage_id: 19,
		part_id: 208,
		quantity: 380,
	},
	{
		garage_id: 19,
		part_id: 209,
		quantity: 32,
	},
	{
		garage_id: 19,
		part_id: 210,
		quantity: 10,
	},
	{
		garage_id: 19,
		part_id: 211,
		quantity: 199,
	},
	{
		garage_id: 19,
		part_id: 212,
		quantity: 371,
	},
	{
		garage_id: 19,
		part_id: 213,
		quantity: 402,
	},
	{
		garage_id: 19,
		part_id: 214,
		quantity: 174,
	},
	{
		garage_id: 19,
		part_id: 215,
		quantity: 4,
	},
	{
		garage_id: 19,
		part_id: 216,
		quantity: 331,
	},
	{
		garage_id: 19,
		part_id: 217,
		quantity: 435,
	},
	{
		garage_id: 19,
		part_id: 218,
		quantity: 361,
	},
	{
		garage_id: 19,
		part_id: 219,
		quantity: 275,
	},
	{
		garage_id: 19,
		part_id: 220,
		quantity: 453,
	},
	{
		garage_id: 19,
		part_id: 221,
		quantity: 91,
	},
	{
		garage_id: 19,
		part_id: 222,
		quantity: 178,
	},
	{
		garage_id: 19,
		part_id: 223,
		quantity: 363,
	},
	{
		garage_id: 19,
		part_id: 224,
		quantity: 234,
	},
	{
		garage_id: 19,
		part_id: 225,
		quantity: 86,
	},
	{
		garage_id: 19,
		part_id: 226,
		quantity: 346,
	},
	{
		garage_id: 19,
		part_id: 227,
		quantity: 202,
	},
	{
		garage_id: 19,
		part_id: 228,
		quantity: 312,
	},
	{
		garage_id: 19,
		part_id: 229,
		quantity: 216,
	},
	{
		garage_id: 19,
		part_id: 230,
		quantity: 236,
	},
	{
		garage_id: 19,
		part_id: 231,
		quantity: 458,
	},
	{
		garage_id: 19,
		part_id: 232,
		quantity: 497,
	},
	{
		garage_id: 19,
		part_id: 233,
		quantity: 241,
	},
	{
		garage_id: 19,
		part_id: 234,
		quantity: 146,
	},
	{
		garage_id: 19,
		part_id: 235,
		quantity: 169,
	},
	{
		garage_id: 19,
		part_id: 236,
		quantity: 214,
	},
	{
		garage_id: 19,
		part_id: 237,
		quantity: 164,
	},
	{
		garage_id: 19,
		part_id: 238,
		quantity: 344,
	},
	{
		garage_id: 19,
		part_id: 239,
		quantity: 258,
	},
	{
		garage_id: 19,
		part_id: 240,
		quantity: 402,
	},
	{
		garage_id: 19,
		part_id: 241,
		quantity: 430,
	},
	{
		garage_id: 19,
		part_id: 242,
		quantity: 300,
	},
	{
		garage_id: 19,
		part_id: 243,
		quantity: 288,
	},
	{
		garage_id: 19,
		part_id: 244,
		quantity: 211,
	},
	{
		garage_id: 19,
		part_id: 245,
		quantity: 384,
	},
	{
		garage_id: 19,
		part_id: 246,
		quantity: 1,
	},
	{
		garage_id: 19,
		part_id: 247,
		quantity: 127,
	},
	{
		garage_id: 19,
		part_id: 248,
		quantity: 401,
	},
	{
		garage_id: 19,
		part_id: 249,
		quantity: 456,
	},
	{
		garage_id: 19,
		part_id: 250,
		quantity: 377,
	},
	{
		garage_id: 19,
		part_id: 251,
		quantity: 334,
	},
	{
		garage_id: 19,
		part_id: 252,
		quantity: 105,
	},
	{
		garage_id: 19,
		part_id: 253,
		quantity: 177,
	},
	{
		garage_id: 19,
		part_id: 254,
		quantity: 222,
	},
	{
		garage_id: 19,
		part_id: 255,
		quantity: 103,
	},
	{
		garage_id: 19,
		part_id: 256,
		quantity: 480,
	},
	{
		garage_id: 19,
		part_id: 257,
		quantity: 384,
	},
	{
		garage_id: 19,
		part_id: 258,
		quantity: 129,
	},
	{
		garage_id: 19,
		part_id: 259,
		quantity: 282,
	},
	{
		garage_id: 19,
		part_id: 260,
		quantity: 111,
	},
	{
		garage_id: 19,
		part_id: 261,
		quantity: 227,
	},
	{
		garage_id: 19,
		part_id: 262,
		quantity: 391,
	},
	{
		garage_id: 19,
		part_id: 263,
		quantity: 498,
	},
	{
		garage_id: 19,
		part_id: 264,
		quantity: 319,
	},
	{
		garage_id: 19,
		part_id: 265,
		quantity: 374,
	},
	{
		garage_id: 19,
		part_id: 266,
		quantity: 191,
	},
	{
		garage_id: 19,
		part_id: 267,
		quantity: 85,
	},
	{
		garage_id: 19,
		part_id: 268,
		quantity: 118,
	},
	{
		garage_id: 19,
		part_id: 269,
		quantity: 98,
	},
	{
		garage_id: 19,
		part_id: 270,
		quantity: 217,
	},
	{
		garage_id: 19,
		part_id: 271,
		quantity: 303,
	},
	{
		garage_id: 19,
		part_id: 272,
		quantity: 360,
	},
	{
		garage_id: 19,
		part_id: 273,
		quantity: 310,
	},
	{
		garage_id: 19,
		part_id: 274,
		quantity: 231,
	},
	{
		garage_id: 19,
		part_id: 275,
		quantity: 476,
	},
	{
		garage_id: 19,
		part_id: 276,
		quantity: 376,
	},
	{
		garage_id: 19,
		part_id: 277,
		quantity: 47,
	},
	{
		garage_id: 19,
		part_id: 278,
		quantity: 228,
	},
	{
		garage_id: 19,
		part_id: 279,
		quantity: 429,
	},
	{
		garage_id: 19,
		part_id: 280,
		quantity: 144,
	},
	{
		garage_id: 19,
		part_id: 281,
		quantity: 189,
	},
	{
		garage_id: 19,
		part_id: 282,
		quantity: 2,
	},
	{
		garage_id: 19,
		part_id: 283,
		quantity: 455,
	},
	{
		garage_id: 19,
		part_id: 284,
		quantity: 117,
	},
	{
		garage_id: 19,
		part_id: 285,
		quantity: 113,
	},
	{
		garage_id: 19,
		part_id: 286,
		quantity: 440,
	},
	{
		garage_id: 19,
		part_id: 287,
		quantity: 172,
	},
	{
		garage_id: 19,
		part_id: 288,
		quantity: 251,
	},
	{
		garage_id: 19,
		part_id: 289,
		quantity: 355,
	},
	{
		garage_id: 19,
		part_id: 290,
		quantity: 42,
	},
	{
		garage_id: 19,
		part_id: 291,
		quantity: 252,
	},
	{
		garage_id: 19,
		part_id: 292,
		quantity: 487,
	},
	{
		garage_id: 19,
		part_id: 293,
		quantity: 234,
	},
	{
		garage_id: 19,
		part_id: 294,
		quantity: 75,
	},
	{
		garage_id: 19,
		part_id: 295,
		quantity: 211,
	},
	{
		garage_id: 19,
		part_id: 296,
		quantity: 462,
	},
	{
		garage_id: 19,
		part_id: 297,
		quantity: 193,
	},
	{
		garage_id: 19,
		part_id: 298,
		quantity: 499,
	},
	{
		garage_id: 19,
		part_id: 299,
		quantity: 314,
	},
	{
		garage_id: 19,
		part_id: 300,
		quantity: 49,
	},
	{
		garage_id: 19,
		part_id: 301,
		quantity: 200,
	},
	{
		garage_id: 19,
		part_id: 302,
		quantity: 442,
	},
	{
		garage_id: 19,
		part_id: 303,
		quantity: 144,
	},
	{
		garage_id: 19,
		part_id: 304,
		quantity: 416,
	},
	{
		garage_id: 19,
		part_id: 305,
		quantity: 195,
	},
	{
		garage_id: 19,
		part_id: 306,
		quantity: 92,
	},
	{
		garage_id: 19,
		part_id: 307,
		quantity: 92,
	},
	{
		garage_id: 19,
		part_id: 308,
		quantity: 452,
	},
	{
		garage_id: 19,
		part_id: 309,
		quantity: 417,
	},
	{
		garage_id: 19,
		part_id: 310,
		quantity: 31,
	},
	{
		garage_id: 19,
		part_id: 311,
		quantity: 174,
	},
	{
		garage_id: 19,
		part_id: 312,
		quantity: 321,
	},
	{
		garage_id: 19,
		part_id: 313,
		quantity: 214,
	},
	{
		garage_id: 19,
		part_id: 314,
		quantity: 431,
	},
	{
		garage_id: 19,
		part_id: 315,
		quantity: 421,
	},
	{
		garage_id: 19,
		part_id: 316,
		quantity: 391,
	},
	{
		garage_id: 19,
		part_id: 317,
		quantity: 57,
	},
	{
		garage_id: 19,
		part_id: 318,
		quantity: 181,
	},
	{
		garage_id: 19,
		part_id: 319,
		quantity: 310,
	},
	{
		garage_id: 19,
		part_id: 320,
		quantity: 300,
	},
	{
		garage_id: 19,
		part_id: 321,
		quantity: 257,
	},
	{
		garage_id: 19,
		part_id: 322,
		quantity: 340,
	},
	{
		garage_id: 19,
		part_id: 323,
		quantity: 480,
	},
	{
		garage_id: 19,
		part_id: 324,
		quantity: 384,
	},
	{
		garage_id: 19,
		part_id: 325,
		quantity: 17,
	},
	{
		garage_id: 19,
		part_id: 326,
		quantity: 328,
	},
	{
		garage_id: 19,
		part_id: 327,
		quantity: 419,
	},
	{
		garage_id: 19,
		part_id: 328,
		quantity: 113,
	},
	{
		garage_id: 19,
		part_id: 329,
		quantity: 252,
	},
	{
		garage_id: 19,
		part_id: 330,
		quantity: 187,
	},
	{
		garage_id: 19,
		part_id: 331,
		quantity: 397,
	},
	{
		garage_id: 19,
		part_id: 332,
		quantity: 75,
	},
	{
		garage_id: 19,
		part_id: 333,
		quantity: 473,
	},
	{
		garage_id: 19,
		part_id: 334,
		quantity: 113,
	},
	{
		garage_id: 19,
		part_id: 335,
		quantity: 378,
	},
	{
		garage_id: 19,
		part_id: 336,
		quantity: 431,
	},
	{
		garage_id: 19,
		part_id: 337,
		quantity: 29,
	},
	{
		garage_id: 19,
		part_id: 338,
		quantity: 364,
	},
	{
		garage_id: 19,
		part_id: 339,
		quantity: 119,
	},
	{
		garage_id: 19,
		part_id: 340,
		quantity: 322,
	},
	{
		garage_id: 19,
		part_id: 341,
		quantity: 336,
	},
	{
		garage_id: 19,
		part_id: 342,
		quantity: 317,
	},
	{
		garage_id: 19,
		part_id: 343,
		quantity: 106,
	},
	{
		garage_id: 19,
		part_id: 344,
		quantity: 495,
	},
	{
		garage_id: 19,
		part_id: 345,
		quantity: 480,
	},
	{
		garage_id: 19,
		part_id: 346,
		quantity: 387,
	},
	{
		garage_id: 19,
		part_id: 347,
		quantity: 39,
	},
	{
		garage_id: 19,
		part_id: 348,
		quantity: 48,
	},
	{
		garage_id: 19,
		part_id: 349,
		quantity: 12,
	},
	{
		garage_id: 19,
		part_id: 350,
		quantity: 74,
	},
	{
		garage_id: 19,
		part_id: 351,
		quantity: 196,
	},
	{
		garage_id: 19,
		part_id: 352,
		quantity: 413,
	},
	{
		garage_id: 19,
		part_id: 353,
		quantity: 358,
	},
	{
		garage_id: 19,
		part_id: 354,
		quantity: 128,
	},
	{
		garage_id: 19,
		part_id: 355,
		quantity: 101,
	},
	{
		garage_id: 19,
		part_id: 356,
		quantity: 489,
	},
	{
		garage_id: 19,
		part_id: 357,
		quantity: 485,
	},
	{
		garage_id: 19,
		part_id: 358,
		quantity: 55,
	},
	{
		garage_id: 19,
		part_id: 359,
		quantity: 247,
	},
	{
		garage_id: 19,
		part_id: 360,
		quantity: 308,
	},
	{
		garage_id: 19,
		part_id: 361,
		quantity: 336,
	},
	{
		garage_id: 19,
		part_id: 362,
		quantity: 188,
	},
	{
		garage_id: 19,
		part_id: 363,
		quantity: 495,
	},
	{
		garage_id: 19,
		part_id: 364,
		quantity: 351,
	},
	{
		garage_id: 19,
		part_id: 365,
		quantity: 272,
	},
	{
		garage_id: 19,
		part_id: 366,
		quantity: 402,
	},
	{
		garage_id: 19,
		part_id: 367,
		quantity: 73,
	},
	{
		garage_id: 19,
		part_id: 368,
		quantity: 367,
	},
	{
		garage_id: 19,
		part_id: 369,
		quantity: 389,
	},
	{
		garage_id: 19,
		part_id: 370,
		quantity: 178,
	},
	{
		garage_id: 19,
		part_id: 371,
		quantity: 459,
	},
	{
		garage_id: 19,
		part_id: 372,
		quantity: 448,
	},
	{
		garage_id: 19,
		part_id: 373,
		quantity: 439,
	},
	{
		garage_id: 19,
		part_id: 374,
		quantity: 73,
	},
	{
		garage_id: 19,
		part_id: 375,
		quantity: 92,
	},
	{
		garage_id: 19,
		part_id: 376,
		quantity: 82,
	},
	{
		garage_id: 19,
		part_id: 377,
		quantity: 393,
	},
	{
		garage_id: 19,
		part_id: 378,
		quantity: 309,
	},
	{
		garage_id: 19,
		part_id: 379,
		quantity: 289,
	},
	{
		garage_id: 19,
		part_id: 380,
		quantity: 34,
	},
	{
		garage_id: 19,
		part_id: 381,
		quantity: 495,
	},
	{
		garage_id: 19,
		part_id: 382,
		quantity: 377,
	},
	{
		garage_id: 19,
		part_id: 383,
		quantity: 447,
	},
	{
		garage_id: 19,
		part_id: 384,
		quantity: 58,
	},
	{
		garage_id: 19,
		part_id: 385,
		quantity: 495,
	},
	{
		garage_id: 19,
		part_id: 386,
		quantity: 438,
	},
	{
		garage_id: 19,
		part_id: 387,
		quantity: 341,
	},
	{
		garage_id: 19,
		part_id: 388,
		quantity: 397,
	},
	{
		garage_id: 19,
		part_id: 389,
		quantity: 273,
	},
	{
		garage_id: 19,
		part_id: 390,
		quantity: 351,
	},
	{
		garage_id: 19,
		part_id: 391,
		quantity: 137,
	},
	{
		garage_id: 19,
		part_id: 392,
		quantity: 406,
	},
	{
		garage_id: 19,
		part_id: 393,
		quantity: 26,
	},
	{
		garage_id: 19,
		part_id: 394,
		quantity: 471,
	},
	{
		garage_id: 19,
		part_id: 395,
		quantity: 2,
	},
	{
		garage_id: 19,
		part_id: 396,
		quantity: 413,
	},
	{
		garage_id: 19,
		part_id: 397,
		quantity: 68,
	},
	{
		garage_id: 19,
		part_id: 398,
		quantity: 418,
	},
	{
		garage_id: 19,
		part_id: 399,
		quantity: 184,
	},
	{
		garage_id: 20,
		part_id: 0,
		quantity: 75,
	},
	{
		garage_id: 20,
		part_id: 1,
		quantity: 16,
	},
	{
		garage_id: 20,
		part_id: 2,
		quantity: 252,
	},
	{
		garage_id: 20,
		part_id: 3,
		quantity: 136,
	},
	{
		garage_id: 20,
		part_id: 4,
		quantity: 420,
	},
	{
		garage_id: 20,
		part_id: 5,
		quantity: 98,
	},
	{
		garage_id: 20,
		part_id: 6,
		quantity: 381,
	},
	{
		garage_id: 20,
		part_id: 7,
		quantity: 146,
	},
	{
		garage_id: 20,
		part_id: 8,
		quantity: 222,
	},
	{
		garage_id: 20,
		part_id: 9,
		quantity: 139,
	},
	{
		garage_id: 20,
		part_id: 10,
		quantity: 420,
	},
	{
		garage_id: 20,
		part_id: 11,
		quantity: 274,
	},
	{
		garage_id: 20,
		part_id: 12,
		quantity: 332,
	},
	{
		garage_id: 20,
		part_id: 13,
		quantity: 63,
	},
	{
		garage_id: 20,
		part_id: 14,
		quantity: 258,
	},
	{
		garage_id: 20,
		part_id: 15,
		quantity: 207,
	},
	{
		garage_id: 20,
		part_id: 16,
		quantity: 372,
	},
	{
		garage_id: 20,
		part_id: 17,
		quantity: 269,
	},
	{
		garage_id: 20,
		part_id: 18,
		quantity: 476,
	},
	{
		garage_id: 20,
		part_id: 19,
		quantity: 131,
	},
	{
		garage_id: 20,
		part_id: 20,
		quantity: 65,
	},
	{
		garage_id: 20,
		part_id: 21,
		quantity: 433,
	},
	{
		garage_id: 20,
		part_id: 22,
		quantity: 481,
	},
	{
		garage_id: 20,
		part_id: 23,
		quantity: 183,
	},
	{
		garage_id: 20,
		part_id: 24,
		quantity: 203,
	},
	{
		garage_id: 20,
		part_id: 25,
		quantity: 486,
	},
	{
		garage_id: 20,
		part_id: 26,
		quantity: 110,
	},
	{
		garage_id: 20,
		part_id: 27,
		quantity: 227,
	},
	{
		garage_id: 20,
		part_id: 28,
		quantity: 362,
	},
	{
		garage_id: 20,
		part_id: 29,
		quantity: 297,
	},
	{
		garage_id: 20,
		part_id: 30,
		quantity: 500,
	},
	{
		garage_id: 20,
		part_id: 31,
		quantity: 301,
	},
	{
		garage_id: 20,
		part_id: 32,
		quantity: 236,
	},
	{
		garage_id: 20,
		part_id: 33,
		quantity: 384,
	},
	{
		garage_id: 20,
		part_id: 34,
		quantity: 390,
	},
	{
		garage_id: 20,
		part_id: 35,
		quantity: 467,
	},
	{
		garage_id: 20,
		part_id: 36,
		quantity: 120,
	},
	{
		garage_id: 20,
		part_id: 37,
		quantity: 275,
	},
	{
		garage_id: 20,
		part_id: 38,
		quantity: 283,
	},
	{
		garage_id: 20,
		part_id: 39,
		quantity: 10,
	},
	{
		garage_id: 20,
		part_id: 40,
		quantity: 434,
	},
	{
		garage_id: 20,
		part_id: 41,
		quantity: 433,
	},
	{
		garage_id: 20,
		part_id: 42,
		quantity: 181,
	},
	{
		garage_id: 20,
		part_id: 43,
		quantity: 86,
	},
	{
		garage_id: 20,
		part_id: 44,
		quantity: 282,
	},
	{
		garage_id: 20,
		part_id: 45,
		quantity: 175,
	},
	{
		garage_id: 20,
		part_id: 46,
		quantity: 447,
	},
	{
		garage_id: 20,
		part_id: 47,
		quantity: 2,
	},
	{
		garage_id: 20,
		part_id: 48,
		quantity: 315,
	},
	{
		garage_id: 20,
		part_id: 49,
		quantity: 370,
	},
	{
		garage_id: 20,
		part_id: 50,
		quantity: 399,
	},
	{
		garage_id: 20,
		part_id: 51,
		quantity: 470,
	},
	{
		garage_id: 20,
		part_id: 52,
		quantity: 208,
	},
	{
		garage_id: 20,
		part_id: 53,
		quantity: 178,
	},
	{
		garage_id: 20,
		part_id: 54,
		quantity: 202,
	},
	{
		garage_id: 20,
		part_id: 55,
		quantity: 276,
	},
	{
		garage_id: 20,
		part_id: 56,
		quantity: 194,
	},
	{
		garage_id: 20,
		part_id: 57,
		quantity: 161,
	},
	{
		garage_id: 20,
		part_id: 58,
		quantity: 58,
	},
	{
		garage_id: 20,
		part_id: 59,
		quantity: 278,
	},
	{
		garage_id: 20,
		part_id: 60,
		quantity: 319,
	},
	{
		garage_id: 20,
		part_id: 61,
		quantity: 9,
	},
	{
		garage_id: 20,
		part_id: 62,
		quantity: 64,
	},
	{
		garage_id: 20,
		part_id: 63,
		quantity: 398,
	},
	{
		garage_id: 20,
		part_id: 64,
		quantity: 344,
	},
	{
		garage_id: 20,
		part_id: 65,
		quantity: 355,
	},
	{
		garage_id: 20,
		part_id: 66,
		quantity: 148,
	},
	{
		garage_id: 20,
		part_id: 67,
		quantity: 226,
	},
	{
		garage_id: 20,
		part_id: 68,
		quantity: 497,
	},
	{
		garage_id: 20,
		part_id: 69,
		quantity: 86,
	},
	{
		garage_id: 20,
		part_id: 70,
		quantity: 112,
	},
	{
		garage_id: 20,
		part_id: 71,
		quantity: 439,
	},
	{
		garage_id: 20,
		part_id: 72,
		quantity: 84,
	},
	{
		garage_id: 20,
		part_id: 73,
		quantity: 463,
	},
	{
		garage_id: 20,
		part_id: 74,
		quantity: 112,
	},
	{
		garage_id: 20,
		part_id: 75,
		quantity: 265,
	},
	{
		garage_id: 20,
		part_id: 76,
		quantity: 272,
	},
	{
		garage_id: 20,
		part_id: 77,
		quantity: 424,
	},
	{
		garage_id: 20,
		part_id: 78,
		quantity: 230,
	},
	{
		garage_id: 20,
		part_id: 79,
		quantity: 229,
	},
	{
		garage_id: 20,
		part_id: 80,
		quantity: 216,
	},
	{
		garage_id: 20,
		part_id: 81,
		quantity: 493,
	},
	{
		garage_id: 20,
		part_id: 82,
		quantity: 294,
	},
	{
		garage_id: 20,
		part_id: 83,
		quantity: 119,
	},
	{
		garage_id: 20,
		part_id: 84,
		quantity: 301,
	},
	{
		garage_id: 20,
		part_id: 85,
		quantity: 448,
	},
	{
		garage_id: 20,
		part_id: 86,
		quantity: 318,
	},
	{
		garage_id: 20,
		part_id: 87,
		quantity: 264,
	},
	{
		garage_id: 20,
		part_id: 88,
		quantity: 29,
	},
	{
		garage_id: 20,
		part_id: 89,
		quantity: 201,
	},
	{
		garage_id: 20,
		part_id: 90,
		quantity: 265,
	},
	{
		garage_id: 20,
		part_id: 91,
		quantity: 68,
	},
	{
		garage_id: 20,
		part_id: 92,
		quantity: 33,
	},
	{
		garage_id: 20,
		part_id: 93,
		quantity: 109,
	},
	{
		garage_id: 20,
		part_id: 94,
		quantity: 75,
	},
	{
		garage_id: 20,
		part_id: 95,
		quantity: 186,
	},
	{
		garage_id: 20,
		part_id: 96,
		quantity: 481,
	},
	{
		garage_id: 20,
		part_id: 97,
		quantity: 168,
	},
	{
		garage_id: 20,
		part_id: 98,
		quantity: 38,
	},
	{
		garage_id: 20,
		part_id: 99,
		quantity: 443,
	},
	{
		garage_id: 20,
		part_id: 100,
		quantity: 426,
	},
	{
		garage_id: 20,
		part_id: 101,
		quantity: 233,
	},
	{
		garage_id: 20,
		part_id: 102,
		quantity: 2,
	},
	{
		garage_id: 20,
		part_id: 103,
		quantity: 291,
	},
	{
		garage_id: 20,
		part_id: 104,
		quantity: 486,
	},
	{
		garage_id: 20,
		part_id: 105,
		quantity: 62,
	},
	{
		garage_id: 20,
		part_id: 106,
		quantity: 185,
	},
	{
		garage_id: 20,
		part_id: 107,
		quantity: 295,
	},
	{
		garage_id: 20,
		part_id: 108,
		quantity: 343,
	},
	{
		garage_id: 20,
		part_id: 109,
		quantity: 111,
	},
	{
		garage_id: 20,
		part_id: 110,
		quantity: 33,
	},
	{
		garage_id: 20,
		part_id: 111,
		quantity: 488,
	},
	{
		garage_id: 20,
		part_id: 112,
		quantity: 198,
	},
	{
		garage_id: 20,
		part_id: 113,
		quantity: 455,
	},
	{
		garage_id: 20,
		part_id: 114,
		quantity: 148,
	},
	{
		garage_id: 20,
		part_id: 115,
		quantity: 308,
	},
	{
		garage_id: 20,
		part_id: 116,
		quantity: 327,
	},
	{
		garage_id: 20,
		part_id: 117,
		quantity: 99,
	},
	{
		garage_id: 20,
		part_id: 118,
		quantity: 370,
	},
	{
		garage_id: 20,
		part_id: 119,
		quantity: 237,
	},
	{
		garage_id: 20,
		part_id: 120,
		quantity: 276,
	},
	{
		garage_id: 20,
		part_id: 121,
		quantity: 30,
	},
	{
		garage_id: 20,
		part_id: 122,
		quantity: 271,
	},
	{
		garage_id: 20,
		part_id: 123,
		quantity: 183,
	},
	{
		garage_id: 20,
		part_id: 124,
		quantity: 12,
	},
	{
		garage_id: 20,
		part_id: 125,
		quantity: 240,
	},
	{
		garage_id: 20,
		part_id: 126,
		quantity: 307,
	},
	{
		garage_id: 20,
		part_id: 127,
		quantity: 478,
	},
	{
		garage_id: 20,
		part_id: 128,
		quantity: 26,
	},
	{
		garage_id: 20,
		part_id: 129,
		quantity: 416,
	},
	{
		garage_id: 20,
		part_id: 130,
		quantity: 406,
	},
	{
		garage_id: 20,
		part_id: 131,
		quantity: 334,
	},
	{
		garage_id: 20,
		part_id: 132,
		quantity: 15,
	},
	{
		garage_id: 20,
		part_id: 133,
		quantity: 271,
	},
	{
		garage_id: 20,
		part_id: 134,
		quantity: 323,
	},
	{
		garage_id: 20,
		part_id: 135,
		quantity: 299,
	},
	{
		garage_id: 20,
		part_id: 136,
		quantity: 362,
	},
	{
		garage_id: 20,
		part_id: 137,
		quantity: 131,
	},
	{
		garage_id: 20,
		part_id: 138,
		quantity: 382,
	},
	{
		garage_id: 20,
		part_id: 139,
		quantity: 35,
	},
	{
		garage_id: 20,
		part_id: 140,
		quantity: 167,
	},
	{
		garage_id: 20,
		part_id: 141,
		quantity: 253,
	},
	{
		garage_id: 20,
		part_id: 142,
		quantity: 94,
	},
	{
		garage_id: 20,
		part_id: 143,
		quantity: 474,
	},
	{
		garage_id: 20,
		part_id: 144,
		quantity: 73,
	},
	{
		garage_id: 20,
		part_id: 145,
		quantity: 480,
	},
	{
		garage_id: 20,
		part_id: 146,
		quantity: 48,
	},
	{
		garage_id: 20,
		part_id: 147,
		quantity: 317,
	},
	{
		garage_id: 20,
		part_id: 148,
		quantity: 215,
	},
	{
		garage_id: 20,
		part_id: 149,
		quantity: 458,
	},
	{
		garage_id: 20,
		part_id: 150,
		quantity: 315,
	},
	{
		garage_id: 20,
		part_id: 151,
		quantity: 99,
	},
	{
		garage_id: 20,
		part_id: 152,
		quantity: 486,
	},
	{
		garage_id: 20,
		part_id: 153,
		quantity: 285,
	},
	{
		garage_id: 20,
		part_id: 154,
		quantity: 469,
	},
	{
		garage_id: 20,
		part_id: 155,
		quantity: 50,
	},
	{
		garage_id: 20,
		part_id: 156,
		quantity: 343,
	},
	{
		garage_id: 20,
		part_id: 157,
		quantity: 5,
	},
	{
		garage_id: 20,
		part_id: 158,
		quantity: 494,
	},
	{
		garage_id: 20,
		part_id: 159,
		quantity: 86,
	},
	{
		garage_id: 20,
		part_id: 160,
		quantity: 62,
	},
	{
		garage_id: 20,
		part_id: 161,
		quantity: 12,
	},
	{
		garage_id: 20,
		part_id: 162,
		quantity: 83,
	},
	{
		garage_id: 20,
		part_id: 163,
		quantity: 176,
	},
	{
		garage_id: 20,
		part_id: 164,
		quantity: 45,
	},
	{
		garage_id: 20,
		part_id: 165,
		quantity: 267,
	},
	{
		garage_id: 20,
		part_id: 166,
		quantity: 162,
	},
	{
		garage_id: 20,
		part_id: 167,
		quantity: 265,
	},
	{
		garage_id: 20,
		part_id: 168,
		quantity: 82,
	},
	{
		garage_id: 20,
		part_id: 169,
		quantity: 402,
	},
	{
		garage_id: 20,
		part_id: 170,
		quantity: 451,
	},
	{
		garage_id: 20,
		part_id: 171,
		quantity: 381,
	},
	{
		garage_id: 20,
		part_id: 172,
		quantity: 459,
	},
	{
		garage_id: 20,
		part_id: 173,
		quantity: 116,
	},
	{
		garage_id: 20,
		part_id: 174,
		quantity: 243,
	},
	{
		garage_id: 20,
		part_id: 175,
		quantity: 308,
	},
	{
		garage_id: 20,
		part_id: 176,
		quantity: 51,
	},
	{
		garage_id: 20,
		part_id: 177,
		quantity: 282,
	},
	{
		garage_id: 20,
		part_id: 178,
		quantity: 415,
	},
	{
		garage_id: 20,
		part_id: 179,
		quantity: 187,
	},
	{
		garage_id: 20,
		part_id: 180,
		quantity: 286,
	},
	{
		garage_id: 20,
		part_id: 181,
		quantity: 133,
	},
	{
		garage_id: 20,
		part_id: 182,
		quantity: 492,
	},
	{
		garage_id: 20,
		part_id: 183,
		quantity: 220,
	},
	{
		garage_id: 20,
		part_id: 184,
		quantity: 235,
	},
	{
		garage_id: 20,
		part_id: 185,
		quantity: 265,
	},
	{
		garage_id: 20,
		part_id: 186,
		quantity: 139,
	},
	{
		garage_id: 20,
		part_id: 187,
		quantity: 92,
	},
	{
		garage_id: 20,
		part_id: 188,
		quantity: 393,
	},
	{
		garage_id: 20,
		part_id: 189,
		quantity: 473,
	},
	{
		garage_id: 20,
		part_id: 190,
		quantity: 246,
	},
	{
		garage_id: 20,
		part_id: 191,
		quantity: 478,
	},
	{
		garage_id: 20,
		part_id: 192,
		quantity: 81,
	},
	{
		garage_id: 20,
		part_id: 193,
		quantity: 41,
	},
	{
		garage_id: 20,
		part_id: 194,
		quantity: 323,
	},
	{
		garage_id: 20,
		part_id: 195,
		quantity: 230,
	},
	{
		garage_id: 20,
		part_id: 196,
		quantity: 327,
	},
	{
		garage_id: 20,
		part_id: 197,
		quantity: 308,
	},
	{
		garage_id: 20,
		part_id: 198,
		quantity: 259,
	},
	{
		garage_id: 20,
		part_id: 199,
		quantity: 430,
	},
	{
		garage_id: 20,
		part_id: 200,
		quantity: 79,
	},
	{
		garage_id: 20,
		part_id: 201,
		quantity: 463,
	},
	{
		garage_id: 20,
		part_id: 202,
		quantity: 175,
	},
	{
		garage_id: 20,
		part_id: 203,
		quantity: 436,
	},
	{
		garage_id: 20,
		part_id: 204,
		quantity: 22,
	},
	{
		garage_id: 20,
		part_id: 205,
		quantity: 471,
	},
	{
		garage_id: 20,
		part_id: 206,
		quantity: 236,
	},
	{
		garage_id: 20,
		part_id: 207,
		quantity: 169,
	},
	{
		garage_id: 20,
		part_id: 208,
		quantity: 477,
	},
	{
		garage_id: 20,
		part_id: 209,
		quantity: 113,
	},
	{
		garage_id: 20,
		part_id: 210,
		quantity: 85,
	},
	{
		garage_id: 20,
		part_id: 211,
		quantity: 5,
	},
	{
		garage_id: 20,
		part_id: 212,
		quantity: 341,
	},
	{
		garage_id: 20,
		part_id: 213,
		quantity: 394,
	},
	{
		garage_id: 20,
		part_id: 214,
		quantity: 393,
	},
	{
		garage_id: 20,
		part_id: 215,
		quantity: 359,
	},
	{
		garage_id: 20,
		part_id: 216,
		quantity: 498,
	},
	{
		garage_id: 20,
		part_id: 217,
		quantity: 487,
	},
	{
		garage_id: 20,
		part_id: 218,
		quantity: 273,
	},
	{
		garage_id: 20,
		part_id: 219,
		quantity: 40,
	},
	{
		garage_id: 20,
		part_id: 220,
		quantity: 160,
	},
	{
		garage_id: 20,
		part_id: 221,
		quantity: 418,
	},
	{
		garage_id: 20,
		part_id: 222,
		quantity: 439,
	},
	{
		garage_id: 20,
		part_id: 223,
		quantity: 308,
	},
	{
		garage_id: 20,
		part_id: 224,
		quantity: 113,
	},
	{
		garage_id: 20,
		part_id: 225,
		quantity: 324,
	},
	{
		garage_id: 20,
		part_id: 226,
		quantity: 241,
	},
	{
		garage_id: 20,
		part_id: 227,
		quantity: 106,
	},
	{
		garage_id: 20,
		part_id: 228,
		quantity: 137,
	},
	{
		garage_id: 20,
		part_id: 229,
		quantity: 24,
	},
	{
		garage_id: 20,
		part_id: 230,
		quantity: 60,
	},
	{
		garage_id: 20,
		part_id: 231,
		quantity: 336,
	},
	{
		garage_id: 20,
		part_id: 232,
		quantity: 433,
	},
	{
		garage_id: 20,
		part_id: 233,
		quantity: 101,
	},
	{
		garage_id: 20,
		part_id: 234,
		quantity: 370,
	},
	{
		garage_id: 20,
		part_id: 235,
		quantity: 119,
	},
	{
		garage_id: 20,
		part_id: 236,
		quantity: 436,
	},
	{
		garage_id: 20,
		part_id: 237,
		quantity: 183,
	},
	{
		garage_id: 20,
		part_id: 238,
		quantity: 236,
	},
	{
		garage_id: 20,
		part_id: 239,
		quantity: 144,
	},
	{
		garage_id: 20,
		part_id: 240,
		quantity: 331,
	},
	{
		garage_id: 20,
		part_id: 241,
		quantity: 211,
	},
	{
		garage_id: 20,
		part_id: 242,
		quantity: 168,
	},
	{
		garage_id: 20,
		part_id: 243,
		quantity: 106,
	},
	{
		garage_id: 20,
		part_id: 244,
		quantity: 412,
	},
	{
		garage_id: 20,
		part_id: 245,
		quantity: 479,
	},
	{
		garage_id: 20,
		part_id: 246,
		quantity: 214,
	},
	{
		garage_id: 20,
		part_id: 247,
		quantity: 463,
	},
	{
		garage_id: 20,
		part_id: 248,
		quantity: 483,
	},
	{
		garage_id: 20,
		part_id: 249,
		quantity: 427,
	},
	{
		garage_id: 20,
		part_id: 250,
		quantity: 189,
	},
	{
		garage_id: 20,
		part_id: 251,
		quantity: 81,
	},
	{
		garage_id: 20,
		part_id: 252,
		quantity: 362,
	},
	{
		garage_id: 20,
		part_id: 253,
		quantity: 24,
	},
	{
		garage_id: 20,
		part_id: 254,
		quantity: 365,
	},
	{
		garage_id: 20,
		part_id: 255,
		quantity: 175,
	},
	{
		garage_id: 20,
		part_id: 256,
		quantity: 455,
	},
	{
		garage_id: 20,
		part_id: 257,
		quantity: 383,
	},
	{
		garage_id: 20,
		part_id: 258,
		quantity: 125,
	},
	{
		garage_id: 20,
		part_id: 259,
		quantity: 292,
	},
	{
		garage_id: 20,
		part_id: 260,
		quantity: 19,
	},
	{
		garage_id: 20,
		part_id: 261,
		quantity: 191,
	},
	{
		garage_id: 20,
		part_id: 262,
		quantity: 23,
	},
	{
		garage_id: 20,
		part_id: 263,
		quantity: 137,
	},
	{
		garage_id: 20,
		part_id: 264,
		quantity: 340,
	},
	{
		garage_id: 20,
		part_id: 265,
		quantity: 171,
	},
	{
		garage_id: 20,
		part_id: 266,
		quantity: 259,
	},
	{
		garage_id: 20,
		part_id: 267,
		quantity: 6,
	},
	{
		garage_id: 20,
		part_id: 268,
		quantity: 144,
	},
	{
		garage_id: 20,
		part_id: 269,
		quantity: 232,
	},
	{
		garage_id: 20,
		part_id: 270,
		quantity: 14,
	},
	{
		garage_id: 20,
		part_id: 271,
		quantity: 294,
	},
	{
		garage_id: 20,
		part_id: 272,
		quantity: 81,
	},
	{
		garage_id: 20,
		part_id: 273,
		quantity: 446,
	},
	{
		garage_id: 20,
		part_id: 274,
		quantity: 276,
	},
	{
		garage_id: 20,
		part_id: 275,
		quantity: 277,
	},
	{
		garage_id: 20,
		part_id: 276,
		quantity: 448,
	},
	{
		garage_id: 20,
		part_id: 277,
		quantity: 297,
	},
	{
		garage_id: 20,
		part_id: 278,
		quantity: 107,
	},
	{
		garage_id: 20,
		part_id: 279,
		quantity: 24,
	},
	{
		garage_id: 20,
		part_id: 280,
		quantity: 162,
	},
	{
		garage_id: 20,
		part_id: 281,
		quantity: 299,
	},
	{
		garage_id: 20,
		part_id: 282,
		quantity: 312,
	},
	{
		garage_id: 20,
		part_id: 283,
		quantity: 226,
	},
	{
		garage_id: 20,
		part_id: 284,
		quantity: 184,
	},
	{
		garage_id: 20,
		part_id: 285,
		quantity: 161,
	},
	{
		garage_id: 20,
		part_id: 286,
		quantity: 302,
	},
	{
		garage_id: 20,
		part_id: 287,
		quantity: 182,
	},
	{
		garage_id: 20,
		part_id: 288,
		quantity: 391,
	},
	{
		garage_id: 20,
		part_id: 289,
		quantity: 321,
	},
	{
		garage_id: 20,
		part_id: 290,
		quantity: 33,
	},
	{
		garage_id: 20,
		part_id: 291,
		quantity: 67,
	},
	{
		garage_id: 20,
		part_id: 292,
		quantity: 38,
	},
	{
		garage_id: 20,
		part_id: 293,
		quantity: 396,
	},
	{
		garage_id: 20,
		part_id: 294,
		quantity: 87,
	},
	{
		garage_id: 20,
		part_id: 295,
		quantity: 452,
	},
	{
		garage_id: 20,
		part_id: 296,
		quantity: 256,
	},
	{
		garage_id: 20,
		part_id: 297,
		quantity: 205,
	},
	{
		garage_id: 20,
		part_id: 298,
		quantity: 421,
	},
	{
		garage_id: 20,
		part_id: 299,
		quantity: 342,
	},
	{
		garage_id: 20,
		part_id: 300,
		quantity: 298,
	},
	{
		garage_id: 20,
		part_id: 301,
		quantity: 279,
	},
	{
		garage_id: 20,
		part_id: 302,
		quantity: 422,
	},
	{
		garage_id: 20,
		part_id: 303,
		quantity: 78,
	},
	{
		garage_id: 20,
		part_id: 304,
		quantity: 406,
	},
	{
		garage_id: 20,
		part_id: 305,
		quantity: 373,
	},
	{
		garage_id: 20,
		part_id: 306,
		quantity: 20,
	},
	{
		garage_id: 20,
		part_id: 307,
		quantity: 421,
	},
	{
		garage_id: 20,
		part_id: 308,
		quantity: 86,
	},
	{
		garage_id: 20,
		part_id: 309,
		quantity: 185,
	},
	{
		garage_id: 20,
		part_id: 310,
		quantity: 149,
	},
	{
		garage_id: 20,
		part_id: 311,
		quantity: 82,
	},
	{
		garage_id: 20,
		part_id: 312,
		quantity: 23,
	},
	{
		garage_id: 20,
		part_id: 313,
		quantity: 9,
	},
	{
		garage_id: 20,
		part_id: 314,
		quantity: 114,
	},
	{
		garage_id: 20,
		part_id: 315,
		quantity: 43,
	},
	{
		garage_id: 20,
		part_id: 316,
		quantity: 174,
	},
	{
		garage_id: 20,
		part_id: 317,
		quantity: 11,
	},
	{
		garage_id: 20,
		part_id: 318,
		quantity: 253,
	},
	{
		garage_id: 20,
		part_id: 319,
		quantity: 257,
	},
	{
		garage_id: 20,
		part_id: 320,
		quantity: 482,
	},
	{
		garage_id: 20,
		part_id: 321,
		quantity: 17,
	},
	{
		garage_id: 20,
		part_id: 322,
		quantity: 44,
	},
	{
		garage_id: 20,
		part_id: 323,
		quantity: 389,
	},
	{
		garage_id: 20,
		part_id: 324,
		quantity: 175,
	},
	{
		garage_id: 20,
		part_id: 325,
		quantity: 203,
	},
	{
		garage_id: 20,
		part_id: 326,
		quantity: 42,
	},
	{
		garage_id: 20,
		part_id: 327,
		quantity: 206,
	},
	{
		garage_id: 20,
		part_id: 328,
		quantity: 368,
	},
	{
		garage_id: 20,
		part_id: 329,
		quantity: 390,
	},
	{
		garage_id: 20,
		part_id: 330,
		quantity: 464,
	},
	{
		garage_id: 20,
		part_id: 331,
		quantity: 129,
	},
	{
		garage_id: 20,
		part_id: 332,
		quantity: 336,
	},
	{
		garage_id: 20,
		part_id: 333,
		quantity: 356,
	},
	{
		garage_id: 20,
		part_id: 334,
		quantity: 60,
	},
	{
		garage_id: 20,
		part_id: 335,
		quantity: 105,
	},
	{
		garage_id: 20,
		part_id: 336,
		quantity: 40,
	},
	{
		garage_id: 20,
		part_id: 337,
		quantity: 337,
	},
	{
		garage_id: 20,
		part_id: 338,
		quantity: 278,
	},
	{
		garage_id: 20,
		part_id: 339,
		quantity: 140,
	},
	{
		garage_id: 20,
		part_id: 340,
		quantity: 257,
	},
	{
		garage_id: 20,
		part_id: 341,
		quantity: 342,
	},
	{
		garage_id: 20,
		part_id: 342,
		quantity: 488,
	},
	{
		garage_id: 20,
		part_id: 343,
		quantity: 18,
	},
	{
		garage_id: 20,
		part_id: 344,
		quantity: 102,
	},
	{
		garage_id: 20,
		part_id: 345,
		quantity: 66,
	},
	{
		garage_id: 20,
		part_id: 346,
		quantity: 259,
	},
	{
		garage_id: 20,
		part_id: 347,
		quantity: 75,
	},
	{
		garage_id: 20,
		part_id: 348,
		quantity: 63,
	},
	{
		garage_id: 20,
		part_id: 349,
		quantity: 448,
	},
	{
		garage_id: 20,
		part_id: 350,
		quantity: 376,
	},
	{
		garage_id: 20,
		part_id: 351,
		quantity: 145,
	},
	{
		garage_id: 20,
		part_id: 352,
		quantity: 499,
	},
	{
		garage_id: 20,
		part_id: 353,
		quantity: 408,
	},
	{
		garage_id: 20,
		part_id: 354,
		quantity: 466,
	},
	{
		garage_id: 20,
		part_id: 355,
		quantity: 64,
	},
	{
		garage_id: 20,
		part_id: 356,
		quantity: 248,
	},
	{
		garage_id: 20,
		part_id: 357,
		quantity: 248,
	},
	{
		garage_id: 20,
		part_id: 358,
		quantity: 499,
	},
	{
		garage_id: 20,
		part_id: 359,
		quantity: 279,
	},
	{
		garage_id: 20,
		part_id: 360,
		quantity: 40,
	},
	{
		garage_id: 20,
		part_id: 361,
		quantity: 17,
	},
	{
		garage_id: 20,
		part_id: 362,
		quantity: 256,
	},
	{
		garage_id: 20,
		part_id: 363,
		quantity: 144,
	},
	{
		garage_id: 20,
		part_id: 364,
		quantity: 467,
	},
	{
		garage_id: 20,
		part_id: 365,
		quantity: 217,
	},
	{
		garage_id: 20,
		part_id: 366,
		quantity: 276,
	},
	{
		garage_id: 20,
		part_id: 367,
		quantity: 458,
	},
	{
		garage_id: 20,
		part_id: 368,
		quantity: 129,
	},
	{
		garage_id: 20,
		part_id: 369,
		quantity: 340,
	},
	{
		garage_id: 20,
		part_id: 370,
		quantity: 74,
	},
	{
		garage_id: 20,
		part_id: 371,
		quantity: 170,
	},
	{
		garage_id: 20,
		part_id: 372,
		quantity: 55,
	},
	{
		garage_id: 20,
		part_id: 373,
		quantity: 466,
	},
	{
		garage_id: 20,
		part_id: 374,
		quantity: 196,
	},
	{
		garage_id: 20,
		part_id: 375,
		quantity: 277,
	},
	{
		garage_id: 20,
		part_id: 376,
		quantity: 109,
	},
	{
		garage_id: 20,
		part_id: 377,
		quantity: 457,
	},
	{
		garage_id: 20,
		part_id: 378,
		quantity: 378,
	},
	{
		garage_id: 20,
		part_id: 379,
		quantity: 51,
	},
	{
		garage_id: 20,
		part_id: 380,
		quantity: 9,
	},
	{
		garage_id: 20,
		part_id: 381,
		quantity: 440,
	},
	{
		garage_id: 20,
		part_id: 382,
		quantity: 280,
	},
	{
		garage_id: 20,
		part_id: 383,
		quantity: 151,
	},
	{
		garage_id: 20,
		part_id: 384,
		quantity: 486,
	},
	{
		garage_id: 20,
		part_id: 385,
		quantity: 203,
	},
	{
		garage_id: 20,
		part_id: 386,
		quantity: 176,
	},
	{
		garage_id: 20,
		part_id: 387,
		quantity: 195,
	},
	{
		garage_id: 20,
		part_id: 388,
		quantity: 347,
	},
	{
		garage_id: 20,
		part_id: 389,
		quantity: 135,
	},
	{
		garage_id: 20,
		part_id: 390,
		quantity: 321,
	},
	{
		garage_id: 20,
		part_id: 391,
		quantity: 364,
	},
	{
		garage_id: 20,
		part_id: 392,
		quantity: 93,
	},
	{
		garage_id: 20,
		part_id: 393,
		quantity: 98,
	},
	{
		garage_id: 20,
		part_id: 394,
		quantity: 90,
	},
	{
		garage_id: 20,
		part_id: 395,
		quantity: 158,
	},
	{
		garage_id: 20,
		part_id: 396,
		quantity: 375,
	},
	{
		garage_id: 20,
		part_id: 397,
		quantity: 475,
	},
	{
		garage_id: 20,
		part_id: 398,
		quantity: 445,
	},
	{
		garage_id: 20,
		part_id: 399,
		quantity: 189,
	},
	{
		garage_id: 21,
		part_id: 0,
		quantity: 462,
	},
	{
		garage_id: 21,
		part_id: 1,
		quantity: 128,
	},
	{
		garage_id: 21,
		part_id: 2,
		quantity: 471,
	},
	{
		garage_id: 21,
		part_id: 3,
		quantity: 165,
	},
	{
		garage_id: 21,
		part_id: 4,
		quantity: 437,
	},
	{
		garage_id: 21,
		part_id: 5,
		quantity: 130,
	},
	{
		garage_id: 21,
		part_id: 6,
		quantity: 44,
	},
	{
		garage_id: 21,
		part_id: 7,
		quantity: 361,
	},
	{
		garage_id: 21,
		part_id: 8,
		quantity: 413,
	},
	{
		garage_id: 21,
		part_id: 9,
		quantity: 310,
	},
	{
		garage_id: 21,
		part_id: 10,
		quantity: 41,
	},
	{
		garage_id: 21,
		part_id: 11,
		quantity: 106,
	},
	{
		garage_id: 21,
		part_id: 12,
		quantity: 409,
	},
	{
		garage_id: 21,
		part_id: 13,
		quantity: 173,
	},
	{
		garage_id: 21,
		part_id: 14,
		quantity: 400,
	},
	{
		garage_id: 21,
		part_id: 15,
		quantity: 151,
	},
	{
		garage_id: 21,
		part_id: 16,
		quantity: 189,
	},
	{
		garage_id: 21,
		part_id: 17,
		quantity: 368,
	},
	{
		garage_id: 21,
		part_id: 18,
		quantity: 156,
	},
	{
		garage_id: 21,
		part_id: 19,
		quantity: 94,
	},
	{
		garage_id: 21,
		part_id: 20,
		quantity: 95,
	},
	{
		garage_id: 21,
		part_id: 21,
		quantity: 226,
	},
	{
		garage_id: 21,
		part_id: 22,
		quantity: 72,
	},
	{
		garage_id: 21,
		part_id: 23,
		quantity: 205,
	},
	{
		garage_id: 21,
		part_id: 24,
		quantity: 57,
	},
	{
		garage_id: 21,
		part_id: 25,
		quantity: 280,
	},
	{
		garage_id: 21,
		part_id: 26,
		quantity: 437,
	},
	{
		garage_id: 21,
		part_id: 27,
		quantity: 124,
	},
	{
		garage_id: 21,
		part_id: 28,
		quantity: 72,
	},
	{
		garage_id: 21,
		part_id: 29,
		quantity: 269,
	},
	{
		garage_id: 21,
		part_id: 30,
		quantity: 157,
	},
	{
		garage_id: 21,
		part_id: 31,
		quantity: 371,
	},
	{
		garage_id: 21,
		part_id: 32,
		quantity: 282,
	},
	{
		garage_id: 21,
		part_id: 33,
		quantity: 289,
	},
	{
		garage_id: 21,
		part_id: 34,
		quantity: 451,
	},
	{
		garage_id: 21,
		part_id: 35,
		quantity: 432,
	},
	{
		garage_id: 21,
		part_id: 36,
		quantity: 102,
	},
	{
		garage_id: 21,
		part_id: 37,
		quantity: 470,
	},
	{
		garage_id: 21,
		part_id: 38,
		quantity: 456,
	},
	{
		garage_id: 21,
		part_id: 39,
		quantity: 181,
	},
	{
		garage_id: 21,
		part_id: 40,
		quantity: 289,
	},
	{
		garage_id: 21,
		part_id: 41,
		quantity: 299,
	},
	{
		garage_id: 21,
		part_id: 42,
		quantity: 333,
	},
	{
		garage_id: 21,
		part_id: 43,
		quantity: 257,
	},
	{
		garage_id: 21,
		part_id: 44,
		quantity: 191,
	},
	{
		garage_id: 21,
		part_id: 45,
		quantity: 57,
	},
	{
		garage_id: 21,
		part_id: 46,
		quantity: 30,
	},
	{
		garage_id: 21,
		part_id: 47,
		quantity: 390,
	},
	{
		garage_id: 21,
		part_id: 48,
		quantity: 46,
	},
	{
		garage_id: 21,
		part_id: 49,
		quantity: 233,
	},
	{
		garage_id: 21,
		part_id: 50,
		quantity: 240,
	},
	{
		garage_id: 21,
		part_id: 51,
		quantity: 33,
	},
	{
		garage_id: 21,
		part_id: 52,
		quantity: 63,
	},
	{
		garage_id: 21,
		part_id: 53,
		quantity: 142,
	},
	{
		garage_id: 21,
		part_id: 54,
		quantity: 221,
	},
	{
		garage_id: 21,
		part_id: 55,
		quantity: 229,
	},
	{
		garage_id: 21,
		part_id: 56,
		quantity: 72,
	},
	{
		garage_id: 21,
		part_id: 57,
		quantity: 439,
	},
	{
		garage_id: 21,
		part_id: 58,
		quantity: 202,
	},
	{
		garage_id: 21,
		part_id: 59,
		quantity: 485,
	},
	{
		garage_id: 21,
		part_id: 60,
		quantity: 161,
	},
	{
		garage_id: 21,
		part_id: 61,
		quantity: 475,
	},
	{
		garage_id: 21,
		part_id: 62,
		quantity: 372,
	},
	{
		garage_id: 21,
		part_id: 63,
		quantity: 240,
	},
	{
		garage_id: 21,
		part_id: 64,
		quantity: 309,
	},
	{
		garage_id: 21,
		part_id: 65,
		quantity: 97,
	},
	{
		garage_id: 21,
		part_id: 66,
		quantity: 255,
	},
	{
		garage_id: 21,
		part_id: 67,
		quantity: 90,
	},
	{
		garage_id: 21,
		part_id: 68,
		quantity: 486,
	},
	{
		garage_id: 21,
		part_id: 69,
		quantity: 364,
	},
	{
		garage_id: 21,
		part_id: 70,
		quantity: 376,
	},
	{
		garage_id: 21,
		part_id: 71,
		quantity: 53,
	},
	{
		garage_id: 21,
		part_id: 72,
		quantity: 283,
	},
	{
		garage_id: 21,
		part_id: 73,
		quantity: 377,
	},
	{
		garage_id: 21,
		part_id: 74,
		quantity: 244,
	},
	{
		garage_id: 21,
		part_id: 75,
		quantity: 434,
	},
	{
		garage_id: 21,
		part_id: 76,
		quantity: 376,
	},
	{
		garage_id: 21,
		part_id: 77,
		quantity: 248,
	},
	{
		garage_id: 21,
		part_id: 78,
		quantity: 333,
	},
	{
		garage_id: 21,
		part_id: 79,
		quantity: 127,
	},
	{
		garage_id: 21,
		part_id: 80,
		quantity: 179,
	},
	{
		garage_id: 21,
		part_id: 81,
		quantity: 315,
	},
	{
		garage_id: 21,
		part_id: 82,
		quantity: 184,
	},
	{
		garage_id: 21,
		part_id: 83,
		quantity: 350,
	},
	{
		garage_id: 21,
		part_id: 84,
		quantity: 4,
	},
	{
		garage_id: 21,
		part_id: 85,
		quantity: 259,
	},
	{
		garage_id: 21,
		part_id: 86,
		quantity: 141,
	},
	{
		garage_id: 21,
		part_id: 87,
		quantity: 362,
	},
	{
		garage_id: 21,
		part_id: 88,
		quantity: 456,
	},
	{
		garage_id: 21,
		part_id: 89,
		quantity: 356,
	},
	{
		garage_id: 21,
		part_id: 90,
		quantity: 305,
	},
	{
		garage_id: 21,
		part_id: 91,
		quantity: 114,
	},
	{
		garage_id: 21,
		part_id: 92,
		quantity: 445,
	},
	{
		garage_id: 21,
		part_id: 93,
		quantity: 194,
	},
	{
		garage_id: 21,
		part_id: 94,
		quantity: 147,
	},
	{
		garage_id: 21,
		part_id: 95,
		quantity: 221,
	},
	{
		garage_id: 21,
		part_id: 96,
		quantity: 194,
	},
	{
		garage_id: 21,
		part_id: 97,
		quantity: 312,
	},
	{
		garage_id: 21,
		part_id: 98,
		quantity: 207,
	},
	{
		garage_id: 21,
		part_id: 99,
		quantity: 19,
	},
	{
		garage_id: 21,
		part_id: 100,
		quantity: 58,
	},
	{
		garage_id: 21,
		part_id: 101,
		quantity: 498,
	},
	{
		garage_id: 21,
		part_id: 102,
		quantity: 245,
	},
	{
		garage_id: 21,
		part_id: 103,
		quantity: 156,
	},
	{
		garage_id: 21,
		part_id: 104,
		quantity: 2,
	},
	{
		garage_id: 21,
		part_id: 105,
		quantity: 289,
	},
	{
		garage_id: 21,
		part_id: 106,
		quantity: 194,
	},
	{
		garage_id: 21,
		part_id: 107,
		quantity: 422,
	},
	{
		garage_id: 21,
		part_id: 108,
		quantity: 192,
	},
	{
		garage_id: 21,
		part_id: 109,
		quantity: 216,
	},
	{
		garage_id: 21,
		part_id: 110,
		quantity: 93,
	},
	{
		garage_id: 21,
		part_id: 111,
		quantity: 78,
	},
	{
		garage_id: 21,
		part_id: 112,
		quantity: 127,
	},
	{
		garage_id: 21,
		part_id: 113,
		quantity: 137,
	},
	{
		garage_id: 21,
		part_id: 114,
		quantity: 484,
	},
	{
		garage_id: 21,
		part_id: 115,
		quantity: 212,
	},
	{
		garage_id: 21,
		part_id: 116,
		quantity: 64,
	},
	{
		garage_id: 21,
		part_id: 117,
		quantity: 293,
	},
	{
		garage_id: 21,
		part_id: 118,
		quantity: 376,
	},
	{
		garage_id: 21,
		part_id: 119,
		quantity: 350,
	},
	{
		garage_id: 21,
		part_id: 120,
		quantity: 230,
	},
	{
		garage_id: 21,
		part_id: 121,
		quantity: 304,
	},
	{
		garage_id: 21,
		part_id: 122,
		quantity: 57,
	},
	{
		garage_id: 21,
		part_id: 123,
		quantity: 169,
	},
	{
		garage_id: 21,
		part_id: 124,
		quantity: 60,
	},
	{
		garage_id: 21,
		part_id: 125,
		quantity: 363,
	},
	{
		garage_id: 21,
		part_id: 126,
		quantity: 370,
	},
	{
		garage_id: 21,
		part_id: 127,
		quantity: 134,
	},
	{
		garage_id: 21,
		part_id: 128,
		quantity: 98,
	},
	{
		garage_id: 21,
		part_id: 129,
		quantity: 13,
	},
	{
		garage_id: 21,
		part_id: 130,
		quantity: 308,
	},
	{
		garage_id: 21,
		part_id: 131,
		quantity: 377,
	},
	{
		garage_id: 21,
		part_id: 132,
		quantity: 38,
	},
	{
		garage_id: 21,
		part_id: 133,
		quantity: 452,
	},
	{
		garage_id: 21,
		part_id: 134,
		quantity: 254,
	},
	{
		garage_id: 21,
		part_id: 135,
		quantity: 383,
	},
	{
		garage_id: 21,
		part_id: 136,
		quantity: 377,
	},
	{
		garage_id: 21,
		part_id: 137,
		quantity: 229,
	},
	{
		garage_id: 21,
		part_id: 138,
		quantity: 461,
	},
	{
		garage_id: 21,
		part_id: 139,
		quantity: 321,
	},
	{
		garage_id: 21,
		part_id: 140,
		quantity: 281,
	},
	{
		garage_id: 21,
		part_id: 141,
		quantity: 478,
	},
	{
		garage_id: 21,
		part_id: 142,
		quantity: 281,
	},
	{
		garage_id: 21,
		part_id: 143,
		quantity: 461,
	},
	{
		garage_id: 21,
		part_id: 144,
		quantity: 431,
	},
	{
		garage_id: 21,
		part_id: 145,
		quantity: 469,
	},
	{
		garage_id: 21,
		part_id: 146,
		quantity: 459,
	},
	{
		garage_id: 21,
		part_id: 147,
		quantity: 364,
	},
	{
		garage_id: 21,
		part_id: 148,
		quantity: 462,
	},
	{
		garage_id: 21,
		part_id: 149,
		quantity: 380,
	},
	{
		garage_id: 21,
		part_id: 150,
		quantity: 212,
	},
	{
		garage_id: 21,
		part_id: 151,
		quantity: 457,
	},
	{
		garage_id: 21,
		part_id: 152,
		quantity: 361,
	},
	{
		garage_id: 21,
		part_id: 153,
		quantity: 1,
	},
	{
		garage_id: 21,
		part_id: 154,
		quantity: 3,
	},
	{
		garage_id: 21,
		part_id: 155,
		quantity: 312,
	},
	{
		garage_id: 21,
		part_id: 156,
		quantity: 106,
	},
	{
		garage_id: 21,
		part_id: 157,
		quantity: 311,
	},
	{
		garage_id: 21,
		part_id: 158,
		quantity: 41,
	},
	{
		garage_id: 21,
		part_id: 159,
		quantity: 321,
	},
	{
		garage_id: 21,
		part_id: 160,
		quantity: 218,
	},
	{
		garage_id: 21,
		part_id: 161,
		quantity: 62,
	},
	{
		garage_id: 21,
		part_id: 162,
		quantity: 253,
	},
	{
		garage_id: 21,
		part_id: 163,
		quantity: 276,
	},
	{
		garage_id: 21,
		part_id: 164,
		quantity: 394,
	},
	{
		garage_id: 21,
		part_id: 165,
		quantity: 450,
	},
	{
		garage_id: 21,
		part_id: 166,
		quantity: 497,
	},
	{
		garage_id: 21,
		part_id: 167,
		quantity: 218,
	},
	{
		garage_id: 21,
		part_id: 168,
		quantity: 53,
	},
	{
		garage_id: 21,
		part_id: 169,
		quantity: 18,
	},
	{
		garage_id: 21,
		part_id: 170,
		quantity: 213,
	},
	{
		garage_id: 21,
		part_id: 171,
		quantity: 367,
	},
	{
		garage_id: 21,
		part_id: 172,
		quantity: 128,
	},
	{
		garage_id: 21,
		part_id: 173,
		quantity: 303,
	},
	{
		garage_id: 21,
		part_id: 174,
		quantity: 183,
	},
	{
		garage_id: 21,
		part_id: 175,
		quantity: 155,
	},
	{
		garage_id: 21,
		part_id: 176,
		quantity: 138,
	},
	{
		garage_id: 21,
		part_id: 177,
		quantity: 42,
	},
	{
		garage_id: 21,
		part_id: 178,
		quantity: 219,
	},
	{
		garage_id: 21,
		part_id: 179,
		quantity: 289,
	},
	{
		garage_id: 21,
		part_id: 180,
		quantity: 95,
	},
	{
		garage_id: 21,
		part_id: 181,
		quantity: 415,
	},
	{
		garage_id: 21,
		part_id: 182,
		quantity: 491,
	},
	{
		garage_id: 21,
		part_id: 183,
		quantity: 103,
	},
	{
		garage_id: 21,
		part_id: 184,
		quantity: 336,
	},
	{
		garage_id: 21,
		part_id: 185,
		quantity: 267,
	},
	{
		garage_id: 21,
		part_id: 186,
		quantity: 34,
	},
	{
		garage_id: 21,
		part_id: 187,
		quantity: 443,
	},
	{
		garage_id: 21,
		part_id: 188,
		quantity: 481,
	},
	{
		garage_id: 21,
		part_id: 189,
		quantity: 68,
	},
	{
		garage_id: 21,
		part_id: 190,
		quantity: 304,
	},
	{
		garage_id: 21,
		part_id: 191,
		quantity: 348,
	},
	{
		garage_id: 21,
		part_id: 192,
		quantity: 348,
	},
	{
		garage_id: 21,
		part_id: 193,
		quantity: 399,
	},
	{
		garage_id: 21,
		part_id: 194,
		quantity: 27,
	},
	{
		garage_id: 21,
		part_id: 195,
		quantity: 134,
	},
	{
		garage_id: 21,
		part_id: 196,
		quantity: 71,
	},
	{
		garage_id: 21,
		part_id: 197,
		quantity: 375,
	},
	{
		garage_id: 21,
		part_id: 198,
		quantity: 50,
	},
	{
		garage_id: 21,
		part_id: 199,
		quantity: 144,
	},
	{
		garage_id: 21,
		part_id: 200,
		quantity: 365,
	},
	{
		garage_id: 21,
		part_id: 201,
		quantity: 28,
	},
	{
		garage_id: 21,
		part_id: 202,
		quantity: 480,
	},
	{
		garage_id: 21,
		part_id: 203,
		quantity: 268,
	},
	{
		garage_id: 21,
		part_id: 204,
		quantity: 10,
	},
	{
		garage_id: 21,
		part_id: 205,
		quantity: 51,
	},
	{
		garage_id: 21,
		part_id: 206,
		quantity: 235,
	},
	{
		garage_id: 21,
		part_id: 207,
		quantity: 159,
	},
	{
		garage_id: 21,
		part_id: 208,
		quantity: 462,
	},
	{
		garage_id: 21,
		part_id: 209,
		quantity: 263,
	},
	{
		garage_id: 21,
		part_id: 210,
		quantity: 422,
	},
	{
		garage_id: 21,
		part_id: 211,
		quantity: 106,
	},
	{
		garage_id: 21,
		part_id: 212,
		quantity: 268,
	},
	{
		garage_id: 21,
		part_id: 213,
		quantity: 487,
	},
	{
		garage_id: 21,
		part_id: 214,
		quantity: 416,
	},
	{
		garage_id: 21,
		part_id: 215,
		quantity: 496,
	},
	{
		garage_id: 21,
		part_id: 216,
		quantity: 111,
	},
	{
		garage_id: 21,
		part_id: 217,
		quantity: 217,
	},
	{
		garage_id: 21,
		part_id: 218,
		quantity: 45,
	},
	{
		garage_id: 21,
		part_id: 219,
		quantity: 79,
	},
	{
		garage_id: 21,
		part_id: 220,
		quantity: 355,
	},
	{
		garage_id: 21,
		part_id: 221,
		quantity: 264,
	},
	{
		garage_id: 21,
		part_id: 222,
		quantity: 61,
	},
	{
		garage_id: 21,
		part_id: 223,
		quantity: 112,
	},
	{
		garage_id: 21,
		part_id: 224,
		quantity: 71,
	},
	{
		garage_id: 21,
		part_id: 225,
		quantity: 235,
	},
	{
		garage_id: 21,
		part_id: 226,
		quantity: 329,
	},
	{
		garage_id: 21,
		part_id: 227,
		quantity: 146,
	},
	{
		garage_id: 21,
		part_id: 228,
		quantity: 320,
	},
	{
		garage_id: 21,
		part_id: 229,
		quantity: 344,
	},
	{
		garage_id: 21,
		part_id: 230,
		quantity: 266,
	},
	{
		garage_id: 21,
		part_id: 231,
		quantity: 30,
	},
	{
		garage_id: 21,
		part_id: 232,
		quantity: 253,
	},
	{
		garage_id: 21,
		part_id: 233,
		quantity: 111,
	},
	{
		garage_id: 21,
		part_id: 234,
		quantity: 380,
	},
	{
		garage_id: 21,
		part_id: 235,
		quantity: 349,
	},
	{
		garage_id: 21,
		part_id: 236,
		quantity: 1,
	},
	{
		garage_id: 21,
		part_id: 237,
		quantity: 145,
	},
	{
		garage_id: 21,
		part_id: 238,
		quantity: 442,
	},
	{
		garage_id: 21,
		part_id: 239,
		quantity: 212,
	},
	{
		garage_id: 21,
		part_id: 240,
		quantity: 363,
	},
	{
		garage_id: 21,
		part_id: 241,
		quantity: 112,
	},
	{
		garage_id: 21,
		part_id: 242,
		quantity: 487,
	},
	{
		garage_id: 21,
		part_id: 243,
		quantity: 245,
	},
	{
		garage_id: 21,
		part_id: 244,
		quantity: 157,
	},
	{
		garage_id: 21,
		part_id: 245,
		quantity: 234,
	},
	{
		garage_id: 21,
		part_id: 246,
		quantity: 177,
	},
	{
		garage_id: 21,
		part_id: 247,
		quantity: 99,
	},
	{
		garage_id: 21,
		part_id: 248,
		quantity: 135,
	},
	{
		garage_id: 21,
		part_id: 249,
		quantity: 343,
	},
	{
		garage_id: 21,
		part_id: 250,
		quantity: 103,
	},
	{
		garage_id: 21,
		part_id: 251,
		quantity: 164,
	},
	{
		garage_id: 21,
		part_id: 252,
		quantity: 255,
	},
	{
		garage_id: 21,
		part_id: 253,
		quantity: 468,
	},
	{
		garage_id: 21,
		part_id: 254,
		quantity: 477,
	},
	{
		garage_id: 21,
		part_id: 255,
		quantity: 210,
	},
	{
		garage_id: 21,
		part_id: 256,
		quantity: 3,
	},
	{
		garage_id: 21,
		part_id: 257,
		quantity: 190,
	},
	{
		garage_id: 21,
		part_id: 258,
		quantity: 133,
	},
	{
		garage_id: 21,
		part_id: 259,
		quantity: 28,
	},
	{
		garage_id: 21,
		part_id: 260,
		quantity: 399,
	},
	{
		garage_id: 21,
		part_id: 261,
		quantity: 461,
	},
	{
		garage_id: 21,
		part_id: 262,
		quantity: 44,
	},
	{
		garage_id: 21,
		part_id: 263,
		quantity: 190,
	},
	{
		garage_id: 21,
		part_id: 264,
		quantity: 10,
	},
	{
		garage_id: 21,
		part_id: 265,
		quantity: 104,
	},
	{
		garage_id: 21,
		part_id: 266,
		quantity: 187,
	},
	{
		garage_id: 21,
		part_id: 267,
		quantity: 126,
	},
	{
		garage_id: 21,
		part_id: 268,
		quantity: 498,
	},
	{
		garage_id: 21,
		part_id: 269,
		quantity: 465,
	},
	{
		garage_id: 21,
		part_id: 270,
		quantity: 305,
	},
	{
		garage_id: 21,
		part_id: 271,
		quantity: 128,
	},
	{
		garage_id: 21,
		part_id: 272,
		quantity: 439,
	},
	{
		garage_id: 21,
		part_id: 273,
		quantity: 353,
	},
	{
		garage_id: 21,
		part_id: 274,
		quantity: 382,
	},
	{
		garage_id: 21,
		part_id: 275,
		quantity: 487,
	},
	{
		garage_id: 21,
		part_id: 276,
		quantity: 475,
	},
	{
		garage_id: 21,
		part_id: 277,
		quantity: 97,
	},
	{
		garage_id: 21,
		part_id: 278,
		quantity: 396,
	},
	{
		garage_id: 21,
		part_id: 279,
		quantity: 99,
	},
	{
		garage_id: 21,
		part_id: 280,
		quantity: 266,
	},
	{
		garage_id: 21,
		part_id: 281,
		quantity: 393,
	},
	{
		garage_id: 21,
		part_id: 282,
		quantity: 483,
	},
	{
		garage_id: 21,
		part_id: 283,
		quantity: 343,
	},
	{
		garage_id: 21,
		part_id: 284,
		quantity: 390,
	},
	{
		garage_id: 21,
		part_id: 285,
		quantity: 142,
	},
	{
		garage_id: 21,
		part_id: 286,
		quantity: 60,
	},
	{
		garage_id: 21,
		part_id: 287,
		quantity: 384,
	},
	{
		garage_id: 21,
		part_id: 288,
		quantity: 298,
	},
	{
		garage_id: 21,
		part_id: 289,
		quantity: 154,
	},
	{
		garage_id: 21,
		part_id: 290,
		quantity: 297,
	},
	{
		garage_id: 21,
		part_id: 291,
		quantity: 164,
	},
	{
		garage_id: 21,
		part_id: 292,
		quantity: 485,
	},
	{
		garage_id: 21,
		part_id: 293,
		quantity: 211,
	},
	{
		garage_id: 21,
		part_id: 294,
		quantity: 110,
	},
	{
		garage_id: 21,
		part_id: 295,
		quantity: 385,
	},
	{
		garage_id: 21,
		part_id: 296,
		quantity: 250,
	},
	{
		garage_id: 21,
		part_id: 297,
		quantity: 474,
	},
	{
		garage_id: 21,
		part_id: 298,
		quantity: 280,
	},
	{
		garage_id: 21,
		part_id: 299,
		quantity: 29,
	},
	{
		garage_id: 21,
		part_id: 300,
		quantity: 235,
	},
	{
		garage_id: 21,
		part_id: 301,
		quantity: 144,
	},
	{
		garage_id: 21,
		part_id: 302,
		quantity: 411,
	},
	{
		garage_id: 21,
		part_id: 303,
		quantity: 186,
	},
	{
		garage_id: 21,
		part_id: 304,
		quantity: 34,
	},
	{
		garage_id: 21,
		part_id: 305,
		quantity: 176,
	},
	{
		garage_id: 21,
		part_id: 306,
		quantity: 323,
	},
	{
		garage_id: 21,
		part_id: 307,
		quantity: 470,
	},
	{
		garage_id: 21,
		part_id: 308,
		quantity: 317,
	},
	{
		garage_id: 21,
		part_id: 309,
		quantity: 287,
	},
	{
		garage_id: 21,
		part_id: 310,
		quantity: 204,
	},
	{
		garage_id: 21,
		part_id: 311,
		quantity: 340,
	},
	{
		garage_id: 21,
		part_id: 312,
		quantity: 169,
	},
	{
		garage_id: 21,
		part_id: 313,
		quantity: 395,
	},
	{
		garage_id: 21,
		part_id: 314,
		quantity: 337,
	},
	{
		garage_id: 21,
		part_id: 315,
		quantity: 227,
	},
	{
		garage_id: 21,
		part_id: 316,
		quantity: 118,
	},
	{
		garage_id: 21,
		part_id: 317,
		quantity: 434,
	},
	{
		garage_id: 21,
		part_id: 318,
		quantity: 41,
	},
	{
		garage_id: 21,
		part_id: 319,
		quantity: 237,
	},
	{
		garage_id: 21,
		part_id: 320,
		quantity: 402,
	},
	{
		garage_id: 21,
		part_id: 321,
		quantity: 300,
	},
	{
		garage_id: 21,
		part_id: 322,
		quantity: 288,
	},
	{
		garage_id: 21,
		part_id: 323,
		quantity: 276,
	},
	{
		garage_id: 21,
		part_id: 324,
		quantity: 375,
	},
	{
		garage_id: 21,
		part_id: 325,
		quantity: 487,
	},
	{
		garage_id: 21,
		part_id: 326,
		quantity: 185,
	},
	{
		garage_id: 21,
		part_id: 327,
		quantity: 388,
	},
	{
		garage_id: 21,
		part_id: 328,
		quantity: 51,
	},
	{
		garage_id: 21,
		part_id: 329,
		quantity: 151,
	},
	{
		garage_id: 21,
		part_id: 330,
		quantity: 224,
	},
	{
		garage_id: 21,
		part_id: 331,
		quantity: 216,
	},
	{
		garage_id: 21,
		part_id: 332,
		quantity: 75,
	},
	{
		garage_id: 21,
		part_id: 333,
		quantity: 51,
	},
	{
		garage_id: 21,
		part_id: 334,
		quantity: 87,
	},
	{
		garage_id: 21,
		part_id: 335,
		quantity: 91,
	},
	{
		garage_id: 21,
		part_id: 336,
		quantity: 285,
	},
	{
		garage_id: 21,
		part_id: 337,
		quantity: 198,
	},
	{
		garage_id: 21,
		part_id: 338,
		quantity: 207,
	},
	{
		garage_id: 21,
		part_id: 339,
		quantity: 494,
	},
	{
		garage_id: 21,
		part_id: 340,
		quantity: 174,
	},
	{
		garage_id: 21,
		part_id: 341,
		quantity: 490,
	},
	{
		garage_id: 21,
		part_id: 342,
		quantity: 38,
	},
	{
		garage_id: 21,
		part_id: 343,
		quantity: 221,
	},
	{
		garage_id: 21,
		part_id: 344,
		quantity: 48,
	},
	{
		garage_id: 21,
		part_id: 345,
		quantity: 210,
	},
	{
		garage_id: 21,
		part_id: 346,
		quantity: 210,
	},
	{
		garage_id: 21,
		part_id: 347,
		quantity: 29,
	},
	{
		garage_id: 21,
		part_id: 348,
		quantity: 45,
	},
	{
		garage_id: 21,
		part_id: 349,
		quantity: 436,
	},
	{
		garage_id: 21,
		part_id: 350,
		quantity: 327,
	},
	{
		garage_id: 21,
		part_id: 351,
		quantity: 307,
	},
	{
		garage_id: 21,
		part_id: 352,
		quantity: 357,
	},
	{
		garage_id: 21,
		part_id: 353,
		quantity: 98,
	},
	{
		garage_id: 21,
		part_id: 354,
		quantity: 31,
	},
	{
		garage_id: 21,
		part_id: 355,
		quantity: 356,
	},
	{
		garage_id: 21,
		part_id: 356,
		quantity: 198,
	},
	{
		garage_id: 21,
		part_id: 357,
		quantity: 120,
	},
	{
		garage_id: 21,
		part_id: 358,
		quantity: 200,
	},
	{
		garage_id: 21,
		part_id: 359,
		quantity: 8,
	},
	{
		garage_id: 21,
		part_id: 360,
		quantity: 97,
	},
	{
		garage_id: 21,
		part_id: 361,
		quantity: 25,
	},
	{
		garage_id: 21,
		part_id: 362,
		quantity: 141,
	},
	{
		garage_id: 21,
		part_id: 363,
		quantity: 268,
	},
	{
		garage_id: 21,
		part_id: 364,
		quantity: 64,
	},
	{
		garage_id: 21,
		part_id: 365,
		quantity: 33,
	},
	{
		garage_id: 21,
		part_id: 366,
		quantity: 404,
	},
	{
		garage_id: 21,
		part_id: 367,
		quantity: 4,
	},
	{
		garage_id: 21,
		part_id: 368,
		quantity: 70,
	},
	{
		garage_id: 21,
		part_id: 369,
		quantity: 112,
	},
	{
		garage_id: 21,
		part_id: 370,
		quantity: 195,
	},
	{
		garage_id: 21,
		part_id: 371,
		quantity: 419,
	},
	{
		garage_id: 21,
		part_id: 372,
		quantity: 271,
	},
	{
		garage_id: 21,
		part_id: 373,
		quantity: 325,
	},
	{
		garage_id: 21,
		part_id: 374,
		quantity: 488,
	},
	{
		garage_id: 21,
		part_id: 375,
		quantity: 302,
	},
	{
		garage_id: 21,
		part_id: 376,
		quantity: 306,
	},
	{
		garage_id: 21,
		part_id: 377,
		quantity: 480,
	},
	{
		garage_id: 21,
		part_id: 378,
		quantity: 47,
	},
	{
		garage_id: 21,
		part_id: 379,
		quantity: 272,
	},
	{
		garage_id: 21,
		part_id: 380,
		quantity: 293,
	},
	{
		garage_id: 21,
		part_id: 381,
		quantity: 85,
	},
	{
		garage_id: 21,
		part_id: 382,
		quantity: 322,
	},
	{
		garage_id: 21,
		part_id: 383,
		quantity: 240,
	},
	{
		garage_id: 21,
		part_id: 384,
		quantity: 420,
	},
	{
		garage_id: 21,
		part_id: 385,
		quantity: 349,
	},
	{
		garage_id: 21,
		part_id: 386,
		quantity: 447,
	},
	{
		garage_id: 21,
		part_id: 387,
		quantity: 248,
	},
	{
		garage_id: 21,
		part_id: 388,
		quantity: 181,
	},
	{
		garage_id: 21,
		part_id: 389,
		quantity: 422,
	},
	{
		garage_id: 21,
		part_id: 390,
		quantity: 224,
	},
	{
		garage_id: 21,
		part_id: 391,
		quantity: 303,
	},
	{
		garage_id: 21,
		part_id: 392,
		quantity: 6,
	},
	{
		garage_id: 21,
		part_id: 393,
		quantity: 334,
	},
	{
		garage_id: 21,
		part_id: 394,
		quantity: 416,
	},
	{
		garage_id: 21,
		part_id: 395,
		quantity: 453,
	},
	{
		garage_id: 21,
		part_id: 396,
		quantity: 195,
	},
	{
		garage_id: 21,
		part_id: 397,
		quantity: 3,
	},
	{
		garage_id: 21,
		part_id: 398,
		quantity: 400,
	},
	{
		garage_id: 21,
		part_id: 399,
		quantity: 98,
	},
	{
		garage_id: 22,
		part_id: 0,
		quantity: 371,
	},
	{
		garage_id: 22,
		part_id: 1,
		quantity: 74,
	},
	{
		garage_id: 22,
		part_id: 2,
		quantity: 9,
	},
	{
		garage_id: 22,
		part_id: 3,
		quantity: 290,
	},
	{
		garage_id: 22,
		part_id: 4,
		quantity: 310,
	},
	{
		garage_id: 22,
		part_id: 5,
		quantity: 45,
	},
	{
		garage_id: 22,
		part_id: 6,
		quantity: 172,
	},
	{
		garage_id: 22,
		part_id: 7,
		quantity: 414,
	},
	{
		garage_id: 22,
		part_id: 8,
		quantity: 81,
	},
	{
		garage_id: 22,
		part_id: 9,
		quantity: 40,
	},
	{
		garage_id: 22,
		part_id: 10,
		quantity: 169,
	},
	{
		garage_id: 22,
		part_id: 11,
		quantity: 294,
	},
	{
		garage_id: 22,
		part_id: 12,
		quantity: 490,
	},
	{
		garage_id: 22,
		part_id: 13,
		quantity: 174,
	},
	{
		garage_id: 22,
		part_id: 14,
		quantity: 192,
	},
	{
		garage_id: 22,
		part_id: 15,
		quantity: 135,
	},
	{
		garage_id: 22,
		part_id: 16,
		quantity: 194,
	},
	{
		garage_id: 22,
		part_id: 17,
		quantity: 486,
	},
	{
		garage_id: 22,
		part_id: 18,
		quantity: 18,
	},
	{
		garage_id: 22,
		part_id: 19,
		quantity: 424,
	},
	{
		garage_id: 22,
		part_id: 20,
		quantity: 350,
	},
	{
		garage_id: 22,
		part_id: 21,
		quantity: 299,
	},
	{
		garage_id: 22,
		part_id: 22,
		quantity: 422,
	},
	{
		garage_id: 22,
		part_id: 23,
		quantity: 472,
	},
	{
		garage_id: 22,
		part_id: 24,
		quantity: 72,
	},
	{
		garage_id: 22,
		part_id: 25,
		quantity: 120,
	},
	{
		garage_id: 22,
		part_id: 26,
		quantity: 270,
	},
	{
		garage_id: 22,
		part_id: 27,
		quantity: 160,
	},
	{
		garage_id: 22,
		part_id: 28,
		quantity: 180,
	},
	{
		garage_id: 22,
		part_id: 29,
		quantity: 451,
	},
	{
		garage_id: 22,
		part_id: 30,
		quantity: 98,
	},
	{
		garage_id: 22,
		part_id: 31,
		quantity: 92,
	},
	{
		garage_id: 22,
		part_id: 32,
		quantity: 276,
	},
	{
		garage_id: 22,
		part_id: 33,
		quantity: 351,
	},
	{
		garage_id: 22,
		part_id: 34,
		quantity: 420,
	},
	{
		garage_id: 22,
		part_id: 35,
		quantity: 405,
	},
	{
		garage_id: 22,
		part_id: 36,
		quantity: 83,
	},
	{
		garage_id: 22,
		part_id: 37,
		quantity: 92,
	},
	{
		garage_id: 22,
		part_id: 38,
		quantity: 257,
	},
	{
		garage_id: 22,
		part_id: 39,
		quantity: 195,
	},
	{
		garage_id: 22,
		part_id: 40,
		quantity: 130,
	},
	{
		garage_id: 22,
		part_id: 41,
		quantity: 135,
	},
	{
		garage_id: 22,
		part_id: 42,
		quantity: 363,
	},
	{
		garage_id: 22,
		part_id: 43,
		quantity: 336,
	},
	{
		garage_id: 22,
		part_id: 44,
		quantity: 404,
	},
	{
		garage_id: 22,
		part_id: 45,
		quantity: 56,
	},
	{
		garage_id: 22,
		part_id: 46,
		quantity: 133,
	},
	{
		garage_id: 22,
		part_id: 47,
		quantity: 467,
	},
	{
		garage_id: 22,
		part_id: 48,
		quantity: 50,
	},
	{
		garage_id: 22,
		part_id: 49,
		quantity: 283,
	},
	{
		garage_id: 22,
		part_id: 50,
		quantity: 103,
	},
	{
		garage_id: 22,
		part_id: 51,
		quantity: 290,
	},
	{
		garage_id: 22,
		part_id: 52,
		quantity: 497,
	},
	{
		garage_id: 22,
		part_id: 53,
		quantity: 378,
	},
	{
		garage_id: 22,
		part_id: 54,
		quantity: 477,
	},
	{
		garage_id: 22,
		part_id: 55,
		quantity: 135,
	},
	{
		garage_id: 22,
		part_id: 56,
		quantity: 489,
	},
	{
		garage_id: 22,
		part_id: 57,
		quantity: 206,
	},
	{
		garage_id: 22,
		part_id: 58,
		quantity: 220,
	},
	{
		garage_id: 22,
		part_id: 59,
		quantity: 294,
	},
	{
		garage_id: 22,
		part_id: 60,
		quantity: 171,
	},
	{
		garage_id: 22,
		part_id: 61,
		quantity: 404,
	},
	{
		garage_id: 22,
		part_id: 62,
		quantity: 350,
	},
	{
		garage_id: 22,
		part_id: 63,
		quantity: 12,
	},
	{
		garage_id: 22,
		part_id: 64,
		quantity: 259,
	},
	{
		garage_id: 22,
		part_id: 65,
		quantity: 145,
	},
	{
		garage_id: 22,
		part_id: 66,
		quantity: 55,
	},
	{
		garage_id: 22,
		part_id: 67,
		quantity: 276,
	},
	{
		garage_id: 22,
		part_id: 68,
		quantity: 156,
	},
	{
		garage_id: 22,
		part_id: 69,
		quantity: 330,
	},
	{
		garage_id: 22,
		part_id: 70,
		quantity: 465,
	},
	{
		garage_id: 22,
		part_id: 71,
		quantity: 115,
	},
	{
		garage_id: 22,
		part_id: 72,
		quantity: 436,
	},
	{
		garage_id: 22,
		part_id: 73,
		quantity: 196,
	},
	{
		garage_id: 22,
		part_id: 74,
		quantity: 361,
	},
	{
		garage_id: 22,
		part_id: 75,
		quantity: 23,
	},
	{
		garage_id: 22,
		part_id: 76,
		quantity: 194,
	},
	{
		garage_id: 22,
		part_id: 77,
		quantity: 387,
	},
	{
		garage_id: 22,
		part_id: 78,
		quantity: 154,
	},
	{
		garage_id: 22,
		part_id: 79,
		quantity: 97,
	},
	{
		garage_id: 22,
		part_id: 80,
		quantity: 281,
	},
	{
		garage_id: 22,
		part_id: 81,
		quantity: 17,
	},
	{
		garage_id: 22,
		part_id: 82,
		quantity: 305,
	},
	{
		garage_id: 22,
		part_id: 83,
		quantity: 99,
	},
	{
		garage_id: 22,
		part_id: 84,
		quantity: 416,
	},
	{
		garage_id: 22,
		part_id: 85,
		quantity: 66,
	},
	{
		garage_id: 22,
		part_id: 86,
		quantity: 224,
	},
	{
		garage_id: 22,
		part_id: 87,
		quantity: 494,
	},
	{
		garage_id: 22,
		part_id: 88,
		quantity: 159,
	},
	{
		garage_id: 22,
		part_id: 89,
		quantity: 106,
	},
	{
		garage_id: 22,
		part_id: 90,
		quantity: 184,
	},
	{
		garage_id: 22,
		part_id: 91,
		quantity: 12,
	},
	{
		garage_id: 22,
		part_id: 92,
		quantity: 278,
	},
	{
		garage_id: 22,
		part_id: 93,
		quantity: 104,
	},
	{
		garage_id: 22,
		part_id: 94,
		quantity: 149,
	},
	{
		garage_id: 22,
		part_id: 95,
		quantity: 10,
	},
	{
		garage_id: 22,
		part_id: 96,
		quantity: 485,
	},
	{
		garage_id: 22,
		part_id: 97,
		quantity: 4,
	},
	{
		garage_id: 22,
		part_id: 98,
		quantity: 446,
	},
	{
		garage_id: 22,
		part_id: 99,
		quantity: 389,
	},
	{
		garage_id: 22,
		part_id: 100,
		quantity: 116,
	},
	{
		garage_id: 22,
		part_id: 101,
		quantity: 387,
	},
	{
		garage_id: 22,
		part_id: 102,
		quantity: 12,
	},
	{
		garage_id: 22,
		part_id: 103,
		quantity: 277,
	},
	{
		garage_id: 22,
		part_id: 104,
		quantity: 127,
	},
	{
		garage_id: 22,
		part_id: 105,
		quantity: 5,
	},
	{
		garage_id: 22,
		part_id: 106,
		quantity: 488,
	},
	{
		garage_id: 22,
		part_id: 107,
		quantity: 31,
	},
	{
		garage_id: 22,
		part_id: 108,
		quantity: 392,
	},
	{
		garage_id: 22,
		part_id: 109,
		quantity: 148,
	},
	{
		garage_id: 22,
		part_id: 110,
		quantity: 374,
	},
	{
		garage_id: 22,
		part_id: 111,
		quantity: 25,
	},
	{
		garage_id: 22,
		part_id: 112,
		quantity: 308,
	},
	{
		garage_id: 22,
		part_id: 113,
		quantity: 277,
	},
	{
		garage_id: 22,
		part_id: 114,
		quantity: 413,
	},
	{
		garage_id: 22,
		part_id: 115,
		quantity: 305,
	},
	{
		garage_id: 22,
		part_id: 116,
		quantity: 383,
	},
	{
		garage_id: 22,
		part_id: 117,
		quantity: 175,
	},
	{
		garage_id: 22,
		part_id: 118,
		quantity: 419,
	},
	{
		garage_id: 22,
		part_id: 119,
		quantity: 217,
	},
	{
		garage_id: 22,
		part_id: 120,
		quantity: 68,
	},
	{
		garage_id: 22,
		part_id: 121,
		quantity: 170,
	},
	{
		garage_id: 22,
		part_id: 122,
		quantity: 424,
	},
	{
		garage_id: 22,
		part_id: 123,
		quantity: 269,
	},
	{
		garage_id: 22,
		part_id: 124,
		quantity: 318,
	},
	{
		garage_id: 22,
		part_id: 125,
		quantity: 451,
	},
	{
		garage_id: 22,
		part_id: 126,
		quantity: 129,
	},
	{
		garage_id: 22,
		part_id: 127,
		quantity: 135,
	},
	{
		garage_id: 22,
		part_id: 128,
		quantity: 391,
	},
	{
		garage_id: 22,
		part_id: 129,
		quantity: 153,
	},
	{
		garage_id: 22,
		part_id: 130,
		quantity: 356,
	},
	{
		garage_id: 22,
		part_id: 131,
		quantity: 172,
	},
	{
		garage_id: 22,
		part_id: 132,
		quantity: 422,
	},
	{
		garage_id: 22,
		part_id: 133,
		quantity: 166,
	},
	{
		garage_id: 22,
		part_id: 134,
		quantity: 103,
	},
	{
		garage_id: 22,
		part_id: 135,
		quantity: 47,
	},
	{
		garage_id: 22,
		part_id: 136,
		quantity: 317,
	},
	{
		garage_id: 22,
		part_id: 137,
		quantity: 23,
	},
	{
		garage_id: 22,
		part_id: 138,
		quantity: 189,
	},
	{
		garage_id: 22,
		part_id: 139,
		quantity: 178,
	},
	{
		garage_id: 22,
		part_id: 140,
		quantity: 313,
	},
	{
		garage_id: 22,
		part_id: 141,
		quantity: 350,
	},
	{
		garage_id: 22,
		part_id: 142,
		quantity: 434,
	},
	{
		garage_id: 22,
		part_id: 143,
		quantity: 317,
	},
	{
		garage_id: 22,
		part_id: 144,
		quantity: 274,
	},
	{
		garage_id: 22,
		part_id: 145,
		quantity: 42,
	},
	{
		garage_id: 22,
		part_id: 146,
		quantity: 256,
	},
	{
		garage_id: 22,
		part_id: 147,
		quantity: 258,
	},
	{
		garage_id: 22,
		part_id: 148,
		quantity: 182,
	},
	{
		garage_id: 22,
		part_id: 149,
		quantity: 126,
	},
	{
		garage_id: 22,
		part_id: 150,
		quantity: 436,
	},
	{
		garage_id: 22,
		part_id: 151,
		quantity: 366,
	},
	{
		garage_id: 22,
		part_id: 152,
		quantity: 158,
	},
	{
		garage_id: 22,
		part_id: 153,
		quantity: 130,
	},
	{
		garage_id: 22,
		part_id: 154,
		quantity: 418,
	},
	{
		garage_id: 22,
		part_id: 155,
		quantity: 370,
	},
	{
		garage_id: 22,
		part_id: 156,
		quantity: 149,
	},
	{
		garage_id: 22,
		part_id: 157,
		quantity: 287,
	},
	{
		garage_id: 22,
		part_id: 158,
		quantity: 386,
	},
	{
		garage_id: 22,
		part_id: 159,
		quantity: 190,
	},
	{
		garage_id: 22,
		part_id: 160,
		quantity: 397,
	},
	{
		garage_id: 22,
		part_id: 161,
		quantity: 444,
	},
	{
		garage_id: 22,
		part_id: 162,
		quantity: 309,
	},
	{
		garage_id: 22,
		part_id: 163,
		quantity: 12,
	},
	{
		garage_id: 22,
		part_id: 164,
		quantity: 422,
	},
	{
		garage_id: 22,
		part_id: 165,
		quantity: 13,
	},
	{
		garage_id: 22,
		part_id: 166,
		quantity: 237,
	},
	{
		garage_id: 22,
		part_id: 167,
		quantity: 64,
	},
	{
		garage_id: 22,
		part_id: 168,
		quantity: 243,
	},
	{
		garage_id: 22,
		part_id: 169,
		quantity: 471,
	},
	{
		garage_id: 22,
		part_id: 170,
		quantity: 325,
	},
	{
		garage_id: 22,
		part_id: 171,
		quantity: 140,
	},
	{
		garage_id: 22,
		part_id: 172,
		quantity: 466,
	},
	{
		garage_id: 22,
		part_id: 173,
		quantity: 292,
	},
	{
		garage_id: 22,
		part_id: 174,
		quantity: 179,
	},
	{
		garage_id: 22,
		part_id: 175,
		quantity: 290,
	},
	{
		garage_id: 22,
		part_id: 176,
		quantity: 37,
	},
	{
		garage_id: 22,
		part_id: 177,
		quantity: 209,
	},
	{
		garage_id: 22,
		part_id: 178,
		quantity: 296,
	},
	{
		garage_id: 22,
		part_id: 179,
		quantity: 223,
	},
	{
		garage_id: 22,
		part_id: 180,
		quantity: 5,
	},
	{
		garage_id: 22,
		part_id: 181,
		quantity: 330,
	},
	{
		garage_id: 22,
		part_id: 182,
		quantity: 495,
	},
	{
		garage_id: 22,
		part_id: 183,
		quantity: 259,
	},
	{
		garage_id: 22,
		part_id: 184,
		quantity: 157,
	},
	{
		garage_id: 22,
		part_id: 185,
		quantity: 51,
	},
	{
		garage_id: 22,
		part_id: 186,
		quantity: 37,
	},
	{
		garage_id: 22,
		part_id: 187,
		quantity: 52,
	},
	{
		garage_id: 22,
		part_id: 188,
		quantity: 385,
	},
	{
		garage_id: 22,
		part_id: 189,
		quantity: 493,
	},
	{
		garage_id: 22,
		part_id: 190,
		quantity: 152,
	},
	{
		garage_id: 22,
		part_id: 191,
		quantity: 142,
	},
	{
		garage_id: 22,
		part_id: 192,
		quantity: 335,
	},
	{
		garage_id: 22,
		part_id: 193,
		quantity: 382,
	},
	{
		garage_id: 22,
		part_id: 194,
		quantity: 27,
	},
	{
		garage_id: 22,
		part_id: 195,
		quantity: 350,
	},
	{
		garage_id: 22,
		part_id: 196,
		quantity: 243,
	},
	{
		garage_id: 22,
		part_id: 197,
		quantity: 159,
	},
	{
		garage_id: 22,
		part_id: 198,
		quantity: 15,
	},
	{
		garage_id: 22,
		part_id: 199,
		quantity: 169,
	},
	{
		garage_id: 22,
		part_id: 200,
		quantity: 88,
	},
	{
		garage_id: 22,
		part_id: 201,
		quantity: 448,
	},
	{
		garage_id: 22,
		part_id: 202,
		quantity: 76,
	},
	{
		garage_id: 22,
		part_id: 203,
		quantity: 182,
	},
	{
		garage_id: 22,
		part_id: 204,
		quantity: 225,
	},
	{
		garage_id: 22,
		part_id: 205,
		quantity: 447,
	},
	{
		garage_id: 22,
		part_id: 206,
		quantity: 66,
	},
	{
		garage_id: 22,
		part_id: 207,
		quantity: 183,
	},
	{
		garage_id: 22,
		part_id: 208,
		quantity: 301,
	},
	{
		garage_id: 22,
		part_id: 209,
		quantity: 38,
	},
	{
		garage_id: 22,
		part_id: 210,
		quantity: 379,
	},
	{
		garage_id: 22,
		part_id: 211,
		quantity: 122,
	},
	{
		garage_id: 22,
		part_id: 212,
		quantity: 400,
	},
	{
		garage_id: 22,
		part_id: 213,
		quantity: 9,
	},
	{
		garage_id: 22,
		part_id: 214,
		quantity: 391,
	},
	{
		garage_id: 22,
		part_id: 215,
		quantity: 5,
	},
	{
		garage_id: 22,
		part_id: 216,
		quantity: 8,
	},
	{
		garage_id: 22,
		part_id: 217,
		quantity: 378,
	},
	{
		garage_id: 22,
		part_id: 218,
		quantity: 425,
	},
	{
		garage_id: 22,
		part_id: 219,
		quantity: 490,
	},
	{
		garage_id: 22,
		part_id: 220,
		quantity: 107,
	},
	{
		garage_id: 22,
		part_id: 221,
		quantity: 336,
	},
	{
		garage_id: 22,
		part_id: 222,
		quantity: 366,
	},
	{
		garage_id: 22,
		part_id: 223,
		quantity: 238,
	},
	{
		garage_id: 22,
		part_id: 224,
		quantity: 170,
	},
	{
		garage_id: 22,
		part_id: 225,
		quantity: 90,
	},
	{
		garage_id: 22,
		part_id: 226,
		quantity: 409,
	},
	{
		garage_id: 22,
		part_id: 227,
		quantity: 358,
	},
	{
		garage_id: 22,
		part_id: 228,
		quantity: 294,
	},
	{
		garage_id: 22,
		part_id: 229,
		quantity: 308,
	},
	{
		garage_id: 22,
		part_id: 230,
		quantity: 148,
	},
	{
		garage_id: 22,
		part_id: 231,
		quantity: 392,
	},
	{
		garage_id: 22,
		part_id: 232,
		quantity: 453,
	},
	{
		garage_id: 22,
		part_id: 233,
		quantity: 164,
	},
	{
		garage_id: 22,
		part_id: 234,
		quantity: 170,
	},
	{
		garage_id: 22,
		part_id: 235,
		quantity: 268,
	},
	{
		garage_id: 22,
		part_id: 236,
		quantity: 75,
	},
	{
		garage_id: 22,
		part_id: 237,
		quantity: 230,
	},
	{
		garage_id: 22,
		part_id: 238,
		quantity: 165,
	},
	{
		garage_id: 22,
		part_id: 239,
		quantity: 117,
	},
	{
		garage_id: 22,
		part_id: 240,
		quantity: 74,
	},
	{
		garage_id: 22,
		part_id: 241,
		quantity: 393,
	},
	{
		garage_id: 22,
		part_id: 242,
		quantity: 218,
	},
	{
		garage_id: 22,
		part_id: 243,
		quantity: 395,
	},
	{
		garage_id: 22,
		part_id: 244,
		quantity: 80,
	},
	{
		garage_id: 22,
		part_id: 245,
		quantity: 114,
	},
	{
		garage_id: 22,
		part_id: 246,
		quantity: 378,
	},
	{
		garage_id: 22,
		part_id: 247,
		quantity: 325,
	},
	{
		garage_id: 22,
		part_id: 248,
		quantity: 234,
	},
	{
		garage_id: 22,
		part_id: 249,
		quantity: 367,
	},
	{
		garage_id: 22,
		part_id: 250,
		quantity: 404,
	},
	{
		garage_id: 22,
		part_id: 251,
		quantity: 33,
	},
	{
		garage_id: 22,
		part_id: 252,
		quantity: 93,
	},
	{
		garage_id: 22,
		part_id: 253,
		quantity: 237,
	},
	{
		garage_id: 22,
		part_id: 254,
		quantity: 369,
	},
	{
		garage_id: 22,
		part_id: 255,
		quantity: 163,
	},
	{
		garage_id: 22,
		part_id: 256,
		quantity: 60,
	},
	{
		garage_id: 22,
		part_id: 257,
		quantity: 471,
	},
	{
		garage_id: 22,
		part_id: 258,
		quantity: 308,
	},
	{
		garage_id: 22,
		part_id: 259,
		quantity: 377,
	},
	{
		garage_id: 22,
		part_id: 260,
		quantity: 298,
	},
	{
		garage_id: 22,
		part_id: 261,
		quantity: 318,
	},
	{
		garage_id: 22,
		part_id: 262,
		quantity: 348,
	},
	{
		garage_id: 22,
		part_id: 263,
		quantity: 486,
	},
	{
		garage_id: 22,
		part_id: 264,
		quantity: 121,
	},
	{
		garage_id: 22,
		part_id: 265,
		quantity: 220,
	},
	{
		garage_id: 22,
		part_id: 266,
		quantity: 313,
	},
	{
		garage_id: 22,
		part_id: 267,
		quantity: 183,
	},
	{
		garage_id: 22,
		part_id: 268,
		quantity: 258,
	},
	{
		garage_id: 22,
		part_id: 269,
		quantity: 354,
	},
	{
		garage_id: 22,
		part_id: 270,
		quantity: 146,
	},
	{
		garage_id: 22,
		part_id: 271,
		quantity: 51,
	},
	{
		garage_id: 22,
		part_id: 272,
		quantity: 216,
	},
	{
		garage_id: 22,
		part_id: 273,
		quantity: 399,
	},
	{
		garage_id: 22,
		part_id: 274,
		quantity: 106,
	},
	{
		garage_id: 22,
		part_id: 275,
		quantity: 162,
	},
	{
		garage_id: 22,
		part_id: 276,
		quantity: 295,
	},
	{
		garage_id: 22,
		part_id: 277,
		quantity: 211,
	},
	{
		garage_id: 22,
		part_id: 278,
		quantity: 245,
	},
	{
		garage_id: 22,
		part_id: 279,
		quantity: 424,
	},
	{
		garage_id: 22,
		part_id: 280,
		quantity: 299,
	},
	{
		garage_id: 22,
		part_id: 281,
		quantity: 191,
	},
	{
		garage_id: 22,
		part_id: 282,
		quantity: 419,
	},
	{
		garage_id: 22,
		part_id: 283,
		quantity: 474,
	},
	{
		garage_id: 22,
		part_id: 284,
		quantity: 206,
	},
	{
		garage_id: 22,
		part_id: 285,
		quantity: 38,
	},
	{
		garage_id: 22,
		part_id: 286,
		quantity: 177,
	},
	{
		garage_id: 22,
		part_id: 287,
		quantity: 40,
	},
	{
		garage_id: 22,
		part_id: 288,
		quantity: 22,
	},
	{
		garage_id: 22,
		part_id: 289,
		quantity: 311,
	},
	{
		garage_id: 22,
		part_id: 290,
		quantity: 6,
	},
	{
		garage_id: 22,
		part_id: 291,
		quantity: 34,
	},
	{
		garage_id: 22,
		part_id: 292,
		quantity: 18,
	},
	{
		garage_id: 22,
		part_id: 293,
		quantity: 75,
	},
	{
		garage_id: 22,
		part_id: 294,
		quantity: 71,
	},
	{
		garage_id: 22,
		part_id: 295,
		quantity: 394,
	},
	{
		garage_id: 22,
		part_id: 296,
		quantity: 286,
	},
	{
		garage_id: 22,
		part_id: 297,
		quantity: 131,
	},
	{
		garage_id: 22,
		part_id: 298,
		quantity: 472,
	},
	{
		garage_id: 22,
		part_id: 299,
		quantity: 440,
	},
	{
		garage_id: 22,
		part_id: 300,
		quantity: 284,
	},
	{
		garage_id: 22,
		part_id: 301,
		quantity: 225,
	},
	{
		garage_id: 22,
		part_id: 302,
		quantity: 407,
	},
	{
		garage_id: 22,
		part_id: 303,
		quantity: 489,
	},
	{
		garage_id: 22,
		part_id: 304,
		quantity: 314,
	},
	{
		garage_id: 22,
		part_id: 305,
		quantity: 122,
	},
	{
		garage_id: 22,
		part_id: 306,
		quantity: 12,
	},
	{
		garage_id: 22,
		part_id: 307,
		quantity: 471,
	},
	{
		garage_id: 22,
		part_id: 308,
		quantity: 246,
	},
	{
		garage_id: 22,
		part_id: 309,
		quantity: 141,
	},
	{
		garage_id: 22,
		part_id: 310,
		quantity: 421,
	},
	{
		garage_id: 22,
		part_id: 311,
		quantity: 274,
	},
	{
		garage_id: 22,
		part_id: 312,
		quantity: 109,
	},
	{
		garage_id: 22,
		part_id: 313,
		quantity: 244,
	},
	{
		garage_id: 22,
		part_id: 314,
		quantity: 342,
	},
	{
		garage_id: 22,
		part_id: 315,
		quantity: 398,
	},
	{
		garage_id: 22,
		part_id: 316,
		quantity: 232,
	},
	{
		garage_id: 22,
		part_id: 317,
		quantity: 499,
	},
	{
		garage_id: 22,
		part_id: 318,
		quantity: 196,
	},
	{
		garage_id: 22,
		part_id: 319,
		quantity: 280,
	},
	{
		garage_id: 22,
		part_id: 320,
		quantity: 412,
	},
	{
		garage_id: 22,
		part_id: 321,
		quantity: 58,
	},
	{
		garage_id: 22,
		part_id: 322,
		quantity: 16,
	},
	{
		garage_id: 22,
		part_id: 323,
		quantity: 123,
	},
	{
		garage_id: 22,
		part_id: 324,
		quantity: 95,
	},
	{
		garage_id: 22,
		part_id: 325,
		quantity: 440,
	},
	{
		garage_id: 22,
		part_id: 326,
		quantity: 435,
	},
	{
		garage_id: 22,
		part_id: 327,
		quantity: 429,
	},
	{
		garage_id: 22,
		part_id: 328,
		quantity: 461,
	},
	{
		garage_id: 22,
		part_id: 329,
		quantity: 143,
	},
	{
		garage_id: 22,
		part_id: 330,
		quantity: 244,
	},
	{
		garage_id: 22,
		part_id: 331,
		quantity: 497,
	},
	{
		garage_id: 22,
		part_id: 332,
		quantity: 46,
	},
	{
		garage_id: 22,
		part_id: 333,
		quantity: 231,
	},
	{
		garage_id: 22,
		part_id: 334,
		quantity: 112,
	},
	{
		garage_id: 22,
		part_id: 335,
		quantity: 457,
	},
	{
		garage_id: 22,
		part_id: 336,
		quantity: 163,
	},
	{
		garage_id: 22,
		part_id: 337,
		quantity: 205,
	},
	{
		garage_id: 22,
		part_id: 338,
		quantity: 166,
	},
	{
		garage_id: 22,
		part_id: 339,
		quantity: 274,
	},
	{
		garage_id: 22,
		part_id: 340,
		quantity: 215,
	},
	{
		garage_id: 22,
		part_id: 341,
		quantity: 425,
	},
	{
		garage_id: 22,
		part_id: 342,
		quantity: 500,
	},
	{
		garage_id: 22,
		part_id: 343,
		quantity: 17,
	},
	{
		garage_id: 22,
		part_id: 344,
		quantity: 500,
	},
	{
		garage_id: 22,
		part_id: 345,
		quantity: 265,
	},
	{
		garage_id: 22,
		part_id: 346,
		quantity: 431,
	},
	{
		garage_id: 22,
		part_id: 347,
		quantity: 449,
	},
	{
		garage_id: 22,
		part_id: 348,
		quantity: 369,
	},
	{
		garage_id: 22,
		part_id: 349,
		quantity: 453,
	},
	{
		garage_id: 22,
		part_id: 350,
		quantity: 282,
	},
	{
		garage_id: 22,
		part_id: 351,
		quantity: 93,
	},
	{
		garage_id: 22,
		part_id: 352,
		quantity: 74,
	},
	{
		garage_id: 22,
		part_id: 353,
		quantity: 109,
	},
	{
		garage_id: 22,
		part_id: 354,
		quantity: 171,
	},
	{
		garage_id: 22,
		part_id: 355,
		quantity: 450,
	},
	{
		garage_id: 22,
		part_id: 356,
		quantity: 58,
	},
	{
		garage_id: 22,
		part_id: 357,
		quantity: 208,
	},
	{
		garage_id: 22,
		part_id: 358,
		quantity: 252,
	},
	{
		garage_id: 22,
		part_id: 359,
		quantity: 25,
	},
	{
		garage_id: 22,
		part_id: 360,
		quantity: 302,
	},
	{
		garage_id: 22,
		part_id: 361,
		quantity: 442,
	},
	{
		garage_id: 22,
		part_id: 362,
		quantity: 83,
	},
	{
		garage_id: 22,
		part_id: 363,
		quantity: 183,
	},
	{
		garage_id: 22,
		part_id: 364,
		quantity: 330,
	},
	{
		garage_id: 22,
		part_id: 365,
		quantity: 491,
	},
	{
		garage_id: 22,
		part_id: 366,
		quantity: 224,
	},
	{
		garage_id: 22,
		part_id: 367,
		quantity: 227,
	},
	{
		garage_id: 22,
		part_id: 368,
		quantity: 404,
	},
	{
		garage_id: 22,
		part_id: 369,
		quantity: 493,
	},
	{
		garage_id: 22,
		part_id: 370,
		quantity: 117,
	},
	{
		garage_id: 22,
		part_id: 371,
		quantity: 156,
	},
	{
		garage_id: 22,
		part_id: 372,
		quantity: 255,
	},
	{
		garage_id: 22,
		part_id: 373,
		quantity: 163,
	},
	{
		garage_id: 22,
		part_id: 374,
		quantity: 450,
	},
	{
		garage_id: 22,
		part_id: 375,
		quantity: 270,
	},
	{
		garage_id: 22,
		part_id: 376,
		quantity: 307,
	},
	{
		garage_id: 22,
		part_id: 377,
		quantity: 477,
	},
	{
		garage_id: 22,
		part_id: 378,
		quantity: 401,
	},
	{
		garage_id: 22,
		part_id: 379,
		quantity: 46,
	},
	{
		garage_id: 22,
		part_id: 380,
		quantity: 203,
	},
	{
		garage_id: 22,
		part_id: 381,
		quantity: 63,
	},
	{
		garage_id: 22,
		part_id: 382,
		quantity: 375,
	},
	{
		garage_id: 22,
		part_id: 383,
		quantity: 495,
	},
	{
		garage_id: 22,
		part_id: 384,
		quantity: 308,
	},
	{
		garage_id: 22,
		part_id: 385,
		quantity: 252,
	},
	{
		garage_id: 22,
		part_id: 386,
		quantity: 83,
	},
	{
		garage_id: 22,
		part_id: 387,
		quantity: 101,
	},
	{
		garage_id: 22,
		part_id: 388,
		quantity: 459,
	},
	{
		garage_id: 22,
		part_id: 389,
		quantity: 328,
	},
	{
		garage_id: 22,
		part_id: 390,
		quantity: 255,
	},
	{
		garage_id: 22,
		part_id: 391,
		quantity: 454,
	},
	{
		garage_id: 22,
		part_id: 392,
		quantity: 40,
	},
	{
		garage_id: 22,
		part_id: 393,
		quantity: 260,
	},
	{
		garage_id: 22,
		part_id: 394,
		quantity: 113,
	},
	{
		garage_id: 22,
		part_id: 395,
		quantity: 404,
	},
	{
		garage_id: 22,
		part_id: 396,
		quantity: 105,
	},
	{
		garage_id: 22,
		part_id: 397,
		quantity: 161,
	},
	{
		garage_id: 22,
		part_id: 398,
		quantity: 95,
	},
	{
		garage_id: 22,
		part_id: 399,
		quantity: 269,
	},
	{
		garage_id: 23,
		part_id: 0,
		quantity: 10,
	},
	{
		garage_id: 23,
		part_id: 1,
		quantity: 291,
	},
	{
		garage_id: 23,
		part_id: 2,
		quantity: 273,
	},
	{
		garage_id: 23,
		part_id: 3,
		quantity: 240,
	},
	{
		garage_id: 23,
		part_id: 4,
		quantity: 212,
	},
	{
		garage_id: 23,
		part_id: 5,
		quantity: 301,
	},
	{
		garage_id: 23,
		part_id: 6,
		quantity: 425,
	},
	{
		garage_id: 23,
		part_id: 7,
		quantity: 432,
	},
	{
		garage_id: 23,
		part_id: 8,
		quantity: 63,
	},
	{
		garage_id: 23,
		part_id: 9,
		quantity: 224,
	},
	{
		garage_id: 23,
		part_id: 10,
		quantity: 133,
	},
	{
		garage_id: 23,
		part_id: 11,
		quantity: 307,
	},
	{
		garage_id: 23,
		part_id: 12,
		quantity: 229,
	},
	{
		garage_id: 23,
		part_id: 13,
		quantity: 496,
	},
	{
		garage_id: 23,
		part_id: 14,
		quantity: 461,
	},
	{
		garage_id: 23,
		part_id: 15,
		quantity: 402,
	},
	{
		garage_id: 23,
		part_id: 16,
		quantity: 454,
	},
	{
		garage_id: 23,
		part_id: 17,
		quantity: 4,
	},
	{
		garage_id: 23,
		part_id: 18,
		quantity: 197,
	},
	{
		garage_id: 23,
		part_id: 19,
		quantity: 188,
	},
	{
		garage_id: 23,
		part_id: 20,
		quantity: 89,
	},
	{
		garage_id: 23,
		part_id: 21,
		quantity: 439,
	},
	{
		garage_id: 23,
		part_id: 22,
		quantity: 446,
	},
	{
		garage_id: 23,
		part_id: 23,
		quantity: 402,
	},
	{
		garage_id: 23,
		part_id: 24,
		quantity: 450,
	},
	{
		garage_id: 23,
		part_id: 25,
		quantity: 440,
	},
	{
		garage_id: 23,
		part_id: 26,
		quantity: 225,
	},
	{
		garage_id: 23,
		part_id: 27,
		quantity: 307,
	},
	{
		garage_id: 23,
		part_id: 28,
		quantity: 418,
	},
	{
		garage_id: 23,
		part_id: 29,
		quantity: 222,
	},
	{
		garage_id: 23,
		part_id: 30,
		quantity: 53,
	},
	{
		garage_id: 23,
		part_id: 31,
		quantity: 78,
	},
	{
		garage_id: 23,
		part_id: 32,
		quantity: 128,
	},
	{
		garage_id: 23,
		part_id: 33,
		quantity: 413,
	},
	{
		garage_id: 23,
		part_id: 34,
		quantity: 324,
	},
	{
		garage_id: 23,
		part_id: 35,
		quantity: 384,
	},
	{
		garage_id: 23,
		part_id: 36,
		quantity: 337,
	},
	{
		garage_id: 23,
		part_id: 37,
		quantity: 86,
	},
	{
		garage_id: 23,
		part_id: 38,
		quantity: 75,
	},
	{
		garage_id: 23,
		part_id: 39,
		quantity: 366,
	},
	{
		garage_id: 23,
		part_id: 40,
		quantity: 4,
	},
	{
		garage_id: 23,
		part_id: 41,
		quantity: 101,
	},
	{
		garage_id: 23,
		part_id: 42,
		quantity: 364,
	},
	{
		garage_id: 23,
		part_id: 43,
		quantity: 308,
	},
	{
		garage_id: 23,
		part_id: 44,
		quantity: 480,
	},
	{
		garage_id: 23,
		part_id: 45,
		quantity: 71,
	},
	{
		garage_id: 23,
		part_id: 46,
		quantity: 473,
	},
	{
		garage_id: 23,
		part_id: 47,
		quantity: 47,
	},
	{
		garage_id: 23,
		part_id: 48,
		quantity: 28,
	},
	{
		garage_id: 23,
		part_id: 49,
		quantity: 51,
	},
	{
		garage_id: 23,
		part_id: 50,
		quantity: 343,
	},
	{
		garage_id: 23,
		part_id: 51,
		quantity: 193,
	},
	{
		garage_id: 23,
		part_id: 52,
		quantity: 44,
	},
	{
		garage_id: 23,
		part_id: 53,
		quantity: 158,
	},
	{
		garage_id: 23,
		part_id: 54,
		quantity: 256,
	},
	{
		garage_id: 23,
		part_id: 55,
		quantity: 384,
	},
	{
		garage_id: 23,
		part_id: 56,
		quantity: 466,
	},
	{
		garage_id: 23,
		part_id: 57,
		quantity: 43,
	},
	{
		garage_id: 23,
		part_id: 58,
		quantity: 449,
	},
	{
		garage_id: 23,
		part_id: 59,
		quantity: 63,
	},
	{
		garage_id: 23,
		part_id: 60,
		quantity: 241,
	},
	{
		garage_id: 23,
		part_id: 61,
		quantity: 204,
	},
	{
		garage_id: 23,
		part_id: 62,
		quantity: 457,
	},
	{
		garage_id: 23,
		part_id: 63,
		quantity: 289,
	},
	{
		garage_id: 23,
		part_id: 64,
		quantity: 132,
	},
	{
		garage_id: 23,
		part_id: 65,
		quantity: 482,
	},
	{
		garage_id: 23,
		part_id: 66,
		quantity: 161,
	},
	{
		garage_id: 23,
		part_id: 67,
		quantity: 340,
	},
	{
		garage_id: 23,
		part_id: 68,
		quantity: 84,
	},
	{
		garage_id: 23,
		part_id: 69,
		quantity: 253,
	},
	{
		garage_id: 23,
		part_id: 70,
		quantity: 204,
	},
	{
		garage_id: 23,
		part_id: 71,
		quantity: 339,
	},
	{
		garage_id: 23,
		part_id: 72,
		quantity: 103,
	},
	{
		garage_id: 23,
		part_id: 73,
		quantity: 7,
	},
	{
		garage_id: 23,
		part_id: 74,
		quantity: 312,
	},
	{
		garage_id: 23,
		part_id: 75,
		quantity: 215,
	},
	{
		garage_id: 23,
		part_id: 76,
		quantity: 190,
	},
	{
		garage_id: 23,
		part_id: 77,
		quantity: 20,
	},
	{
		garage_id: 23,
		part_id: 78,
		quantity: 18,
	},
	{
		garage_id: 23,
		part_id: 79,
		quantity: 473,
	},
	{
		garage_id: 23,
		part_id: 80,
		quantity: 154,
	},
	{
		garage_id: 23,
		part_id: 81,
		quantity: 393,
	},
	{
		garage_id: 23,
		part_id: 82,
		quantity: 17,
	},
	{
		garage_id: 23,
		part_id: 83,
		quantity: 54,
	},
	{
		garage_id: 23,
		part_id: 84,
		quantity: 43,
	},
	{
		garage_id: 23,
		part_id: 85,
		quantity: 222,
	},
	{
		garage_id: 23,
		part_id: 86,
		quantity: 151,
	},
	{
		garage_id: 23,
		part_id: 87,
		quantity: 179,
	},
	{
		garage_id: 23,
		part_id: 88,
		quantity: 268,
	},
	{
		garage_id: 23,
		part_id: 89,
		quantity: 485,
	},
	{
		garage_id: 23,
		part_id: 90,
		quantity: 49,
	},
	{
		garage_id: 23,
		part_id: 91,
		quantity: 409,
	},
	{
		garage_id: 23,
		part_id: 92,
		quantity: 230,
	},
	{
		garage_id: 23,
		part_id: 93,
		quantity: 323,
	},
	{
		garage_id: 23,
		part_id: 94,
		quantity: 373,
	},
	{
		garage_id: 23,
		part_id: 95,
		quantity: 317,
	},
	{
		garage_id: 23,
		part_id: 96,
		quantity: 231,
	},
	{
		garage_id: 23,
		part_id: 97,
		quantity: 423,
	},
	{
		garage_id: 23,
		part_id: 98,
		quantity: 396,
	},
	{
		garage_id: 23,
		part_id: 99,
		quantity: 266,
	},
	{
		garage_id: 23,
		part_id: 100,
		quantity: 451,
	},
	{
		garage_id: 23,
		part_id: 101,
		quantity: 66,
	},
	{
		garage_id: 23,
		part_id: 102,
		quantity: 65,
	},
	{
		garage_id: 23,
		part_id: 103,
		quantity: 300,
	},
	{
		garage_id: 23,
		part_id: 104,
		quantity: 139,
	},
	{
		garage_id: 23,
		part_id: 105,
		quantity: 363,
	},
	{
		garage_id: 23,
		part_id: 106,
		quantity: 62,
	},
	{
		garage_id: 23,
		part_id: 107,
		quantity: 140,
	},
	{
		garage_id: 23,
		part_id: 108,
		quantity: 9,
	},
	{
		garage_id: 23,
		part_id: 109,
		quantity: 216,
	},
	{
		garage_id: 23,
		part_id: 110,
		quantity: 43,
	},
	{
		garage_id: 23,
		part_id: 111,
		quantity: 400,
	},
	{
		garage_id: 23,
		part_id: 112,
		quantity: 78,
	},
	{
		garage_id: 23,
		part_id: 113,
		quantity: 367,
	},
	{
		garage_id: 23,
		part_id: 114,
		quantity: 351,
	},
	{
		garage_id: 23,
		part_id: 115,
		quantity: 104,
	},
	{
		garage_id: 23,
		part_id: 116,
		quantity: 322,
	},
	{
		garage_id: 23,
		part_id: 117,
		quantity: 42,
	},
	{
		garage_id: 23,
		part_id: 118,
		quantity: 138,
	},
	{
		garage_id: 23,
		part_id: 119,
		quantity: 362,
	},
	{
		garage_id: 23,
		part_id: 120,
		quantity: 77,
	},
	{
		garage_id: 23,
		part_id: 121,
		quantity: 132,
	},
	{
		garage_id: 23,
		part_id: 122,
		quantity: 474,
	},
	{
		garage_id: 23,
		part_id: 123,
		quantity: 45,
	},
	{
		garage_id: 23,
		part_id: 124,
		quantity: 436,
	},
	{
		garage_id: 23,
		part_id: 125,
		quantity: 363,
	},
	{
		garage_id: 23,
		part_id: 126,
		quantity: 120,
	},
	{
		garage_id: 23,
		part_id: 127,
		quantity: 466,
	},
	{
		garage_id: 23,
		part_id: 128,
		quantity: 481,
	},
	{
		garage_id: 23,
		part_id: 129,
		quantity: 11,
	},
	{
		garage_id: 23,
		part_id: 130,
		quantity: 479,
	},
	{
		garage_id: 23,
		part_id: 131,
		quantity: 122,
	},
	{
		garage_id: 23,
		part_id: 132,
		quantity: 264,
	},
	{
		garage_id: 23,
		part_id: 133,
		quantity: 297,
	},
	{
		garage_id: 23,
		part_id: 134,
		quantity: 244,
	},
	{
		garage_id: 23,
		part_id: 135,
		quantity: 227,
	},
	{
		garage_id: 23,
		part_id: 136,
		quantity: 115,
	},
	{
		garage_id: 23,
		part_id: 137,
		quantity: 400,
	},
	{
		garage_id: 23,
		part_id: 138,
		quantity: 55,
	},
	{
		garage_id: 23,
		part_id: 139,
		quantity: 82,
	},
	{
		garage_id: 23,
		part_id: 140,
		quantity: 197,
	},
	{
		garage_id: 23,
		part_id: 141,
		quantity: 178,
	},
	{
		garage_id: 23,
		part_id: 142,
		quantity: 12,
	},
	{
		garage_id: 23,
		part_id: 143,
		quantity: 28,
	},
	{
		garage_id: 23,
		part_id: 144,
		quantity: 231,
	},
	{
		garage_id: 23,
		part_id: 145,
		quantity: 483,
	},
	{
		garage_id: 23,
		part_id: 146,
		quantity: 355,
	},
	{
		garage_id: 23,
		part_id: 147,
		quantity: 168,
	},
	{
		garage_id: 23,
		part_id: 148,
		quantity: 150,
	},
	{
		garage_id: 23,
		part_id: 149,
		quantity: 21,
	},
	{
		garage_id: 23,
		part_id: 150,
		quantity: 213,
	},
	{
		garage_id: 23,
		part_id: 151,
		quantity: 369,
	},
	{
		garage_id: 23,
		part_id: 152,
		quantity: 117,
	},
	{
		garage_id: 23,
		part_id: 153,
		quantity: 55,
	},
	{
		garage_id: 23,
		part_id: 154,
		quantity: 65,
	},
	{
		garage_id: 23,
		part_id: 155,
		quantity: 406,
	},
	{
		garage_id: 23,
		part_id: 156,
		quantity: 78,
	},
	{
		garage_id: 23,
		part_id: 157,
		quantity: 167,
	},
	{
		garage_id: 23,
		part_id: 158,
		quantity: 176,
	},
	{
		garage_id: 23,
		part_id: 159,
		quantity: 321,
	},
	{
		garage_id: 23,
		part_id: 160,
		quantity: 170,
	},
	{
		garage_id: 23,
		part_id: 161,
		quantity: 489,
	},
	{
		garage_id: 23,
		part_id: 162,
		quantity: 413,
	},
	{
		garage_id: 23,
		part_id: 163,
		quantity: 49,
	},
	{
		garage_id: 23,
		part_id: 164,
		quantity: 62,
	},
	{
		garage_id: 23,
		part_id: 165,
		quantity: 462,
	},
	{
		garage_id: 23,
		part_id: 166,
		quantity: 294,
	},
	{
		garage_id: 23,
		part_id: 167,
		quantity: 237,
	},
	{
		garage_id: 23,
		part_id: 168,
		quantity: 87,
	},
	{
		garage_id: 23,
		part_id: 169,
		quantity: 212,
	},
	{
		garage_id: 23,
		part_id: 170,
		quantity: 109,
	},
	{
		garage_id: 23,
		part_id: 171,
		quantity: 50,
	},
	{
		garage_id: 23,
		part_id: 172,
		quantity: 225,
	},
	{
		garage_id: 23,
		part_id: 173,
		quantity: 341,
	},
	{
		garage_id: 23,
		part_id: 174,
		quantity: 88,
	},
	{
		garage_id: 23,
		part_id: 175,
		quantity: 356,
	},
	{
		garage_id: 23,
		part_id: 176,
		quantity: 226,
	},
	{
		garage_id: 23,
		part_id: 177,
		quantity: 483,
	},
	{
		garage_id: 23,
		part_id: 178,
		quantity: 70,
	},
	{
		garage_id: 23,
		part_id: 179,
		quantity: 7,
	},
	{
		garage_id: 23,
		part_id: 180,
		quantity: 198,
	},
	{
		garage_id: 23,
		part_id: 181,
		quantity: 439,
	},
	{
		garage_id: 23,
		part_id: 182,
		quantity: 187,
	},
	{
		garage_id: 23,
		part_id: 183,
		quantity: 328,
	},
	{
		garage_id: 23,
		part_id: 184,
		quantity: 459,
	},
	{
		garage_id: 23,
		part_id: 185,
		quantity: 179,
	},
	{
		garage_id: 23,
		part_id: 186,
		quantity: 422,
	},
	{
		garage_id: 23,
		part_id: 187,
		quantity: 61,
	},
	{
		garage_id: 23,
		part_id: 188,
		quantity: 467,
	},
	{
		garage_id: 23,
		part_id: 189,
		quantity: 373,
	},
	{
		garage_id: 23,
		part_id: 190,
		quantity: 319,
	},
	{
		garage_id: 23,
		part_id: 191,
		quantity: 443,
	},
	{
		garage_id: 23,
		part_id: 192,
		quantity: 151,
	},
	{
		garage_id: 23,
		part_id: 193,
		quantity: 297,
	},
	{
		garage_id: 23,
		part_id: 194,
		quantity: 420,
	},
	{
		garage_id: 23,
		part_id: 195,
		quantity: 253,
	},
	{
		garage_id: 23,
		part_id: 196,
		quantity: 107,
	},
	{
		garage_id: 23,
		part_id: 197,
		quantity: 258,
	},
	{
		garage_id: 23,
		part_id: 198,
		quantity: 209,
	},
	{
		garage_id: 23,
		part_id: 199,
		quantity: 99,
	},
	{
		garage_id: 23,
		part_id: 200,
		quantity: 217,
	},
	{
		garage_id: 23,
		part_id: 201,
		quantity: 73,
	},
	{
		garage_id: 23,
		part_id: 202,
		quantity: 361,
	},
	{
		garage_id: 23,
		part_id: 203,
		quantity: 27,
	},
	{
		garage_id: 23,
		part_id: 204,
		quantity: 81,
	},
	{
		garage_id: 23,
		part_id: 205,
		quantity: 220,
	},
	{
		garage_id: 23,
		part_id: 206,
		quantity: 48,
	},
	{
		garage_id: 23,
		part_id: 207,
		quantity: 102,
	},
	{
		garage_id: 23,
		part_id: 208,
		quantity: 418,
	},
	{
		garage_id: 23,
		part_id: 209,
		quantity: 285,
	},
	{
		garage_id: 23,
		part_id: 210,
		quantity: 443,
	},
	{
		garage_id: 23,
		part_id: 211,
		quantity: 204,
	},
	{
		garage_id: 23,
		part_id: 212,
		quantity: 243,
	},
	{
		garage_id: 23,
		part_id: 213,
		quantity: 452,
	},
	{
		garage_id: 23,
		part_id: 214,
		quantity: 335,
	},
	{
		garage_id: 23,
		part_id: 215,
		quantity: 487,
	},
	{
		garage_id: 23,
		part_id: 216,
		quantity: 356,
	},
	{
		garage_id: 23,
		part_id: 217,
		quantity: 74,
	},
	{
		garage_id: 23,
		part_id: 218,
		quantity: 476,
	},
	{
		garage_id: 23,
		part_id: 219,
		quantity: 470,
	},
	{
		garage_id: 23,
		part_id: 220,
		quantity: 165,
	},
	{
		garage_id: 23,
		part_id: 221,
		quantity: 92,
	},
	{
		garage_id: 23,
		part_id: 222,
		quantity: 337,
	},
	{
		garage_id: 23,
		part_id: 223,
		quantity: 279,
	},
	{
		garage_id: 23,
		part_id: 224,
		quantity: 475,
	},
	{
		garage_id: 23,
		part_id: 225,
		quantity: 97,
	},
	{
		garage_id: 23,
		part_id: 226,
		quantity: 57,
	},
	{
		garage_id: 23,
		part_id: 227,
		quantity: 374,
	},
	{
		garage_id: 23,
		part_id: 228,
		quantity: 283,
	},
	{
		garage_id: 23,
		part_id: 229,
		quantity: 227,
	},
	{
		garage_id: 23,
		part_id: 230,
		quantity: 427,
	},
	{
		garage_id: 23,
		part_id: 231,
		quantity: 414,
	},
	{
		garage_id: 23,
		part_id: 232,
		quantity: 389,
	},
	{
		garage_id: 23,
		part_id: 233,
		quantity: 355,
	},
	{
		garage_id: 23,
		part_id: 234,
		quantity: 16,
	},
	{
		garage_id: 23,
		part_id: 235,
		quantity: 436,
	},
	{
		garage_id: 23,
		part_id: 236,
		quantity: 365,
	},
	{
		garage_id: 23,
		part_id: 237,
		quantity: 146,
	},
	{
		garage_id: 23,
		part_id: 238,
		quantity: 190,
	},
	{
		garage_id: 23,
		part_id: 239,
		quantity: 29,
	},
	{
		garage_id: 23,
		part_id: 240,
		quantity: 363,
	},
	{
		garage_id: 23,
		part_id: 241,
		quantity: 100,
	},
	{
		garage_id: 23,
		part_id: 242,
		quantity: 403,
	},
	{
		garage_id: 23,
		part_id: 243,
		quantity: 417,
	},
	{
		garage_id: 23,
		part_id: 244,
		quantity: 254,
	},
	{
		garage_id: 23,
		part_id: 245,
		quantity: 359,
	},
	{
		garage_id: 23,
		part_id: 246,
		quantity: 456,
	},
	{
		garage_id: 23,
		part_id: 247,
		quantity: 403,
	},
	{
		garage_id: 23,
		part_id: 248,
		quantity: 415,
	},
	{
		garage_id: 23,
		part_id: 249,
		quantity: 403,
	},
	{
		garage_id: 23,
		part_id: 250,
		quantity: 149,
	},
	{
		garage_id: 23,
		part_id: 251,
		quantity: 396,
	},
	{
		garage_id: 23,
		part_id: 252,
		quantity: 475,
	},
	{
		garage_id: 23,
		part_id: 253,
		quantity: 27,
	},
	{
		garage_id: 23,
		part_id: 254,
		quantity: 116,
	},
	{
		garage_id: 23,
		part_id: 255,
		quantity: 398,
	},
	{
		garage_id: 23,
		part_id: 256,
		quantity: 129,
	},
	{
		garage_id: 23,
		part_id: 257,
		quantity: 244,
	},
	{
		garage_id: 23,
		part_id: 258,
		quantity: 193,
	},
	{
		garage_id: 23,
		part_id: 259,
		quantity: 112,
	},
	{
		garage_id: 23,
		part_id: 260,
		quantity: 99,
	},
	{
		garage_id: 23,
		part_id: 261,
		quantity: 381,
	},
	{
		garage_id: 23,
		part_id: 262,
		quantity: 154,
	},
	{
		garage_id: 23,
		part_id: 263,
		quantity: 232,
	},
	{
		garage_id: 23,
		part_id: 264,
		quantity: 422,
	},
	{
		garage_id: 23,
		part_id: 265,
		quantity: 179,
	},
	{
		garage_id: 23,
		part_id: 266,
		quantity: 360,
	},
	{
		garage_id: 23,
		part_id: 267,
		quantity: 484,
	},
	{
		garage_id: 23,
		part_id: 268,
		quantity: 488,
	},
	{
		garage_id: 23,
		part_id: 269,
		quantity: 305,
	},
	{
		garage_id: 23,
		part_id: 270,
		quantity: 337,
	},
	{
		garage_id: 23,
		part_id: 271,
		quantity: 152,
	},
	{
		garage_id: 23,
		part_id: 272,
		quantity: 489,
	},
	{
		garage_id: 23,
		part_id: 273,
		quantity: 360,
	},
	{
		garage_id: 23,
		part_id: 274,
		quantity: 305,
	},
	{
		garage_id: 23,
		part_id: 275,
		quantity: 106,
	},
	{
		garage_id: 23,
		part_id: 276,
		quantity: 106,
	},
	{
		garage_id: 23,
		part_id: 277,
		quantity: 113,
	},
	{
		garage_id: 23,
		part_id: 278,
		quantity: 34,
	},
	{
		garage_id: 23,
		part_id: 279,
		quantity: 450,
	},
	{
		garage_id: 23,
		part_id: 280,
		quantity: 222,
	},
	{
		garage_id: 23,
		part_id: 281,
		quantity: 308,
	},
	{
		garage_id: 23,
		part_id: 282,
		quantity: 209,
	},
	{
		garage_id: 23,
		part_id: 283,
		quantity: 187,
	},
	{
		garage_id: 23,
		part_id: 284,
		quantity: 12,
	},
	{
		garage_id: 23,
		part_id: 285,
		quantity: 399,
	},
	{
		garage_id: 23,
		part_id: 286,
		quantity: 117,
	},
	{
		garage_id: 23,
		part_id: 287,
		quantity: 286,
	},
	{
		garage_id: 23,
		part_id: 288,
		quantity: 428,
	},
	{
		garage_id: 23,
		part_id: 289,
		quantity: 89,
	},
	{
		garage_id: 23,
		part_id: 290,
		quantity: 338,
	},
	{
		garage_id: 23,
		part_id: 291,
		quantity: 9,
	},
	{
		garage_id: 23,
		part_id: 292,
		quantity: 162,
	},
	{
		garage_id: 23,
		part_id: 293,
		quantity: 79,
	},
	{
		garage_id: 23,
		part_id: 294,
		quantity: 254,
	},
	{
		garage_id: 23,
		part_id: 295,
		quantity: 458,
	},
	{
		garage_id: 23,
		part_id: 296,
		quantity: 424,
	},
	{
		garage_id: 23,
		part_id: 297,
		quantity: 29,
	},
	{
		garage_id: 23,
		part_id: 298,
		quantity: 4,
	},
	{
		garage_id: 23,
		part_id: 299,
		quantity: 465,
	},
	{
		garage_id: 23,
		part_id: 300,
		quantity: 342,
	},
	{
		garage_id: 23,
		part_id: 301,
		quantity: 22,
	},
	{
		garage_id: 23,
		part_id: 302,
		quantity: 277,
	},
	{
		garage_id: 23,
		part_id: 303,
		quantity: 203,
	},
	{
		garage_id: 23,
		part_id: 304,
		quantity: 237,
	},
	{
		garage_id: 23,
		part_id: 305,
		quantity: 206,
	},
	{
		garage_id: 23,
		part_id: 306,
		quantity: 356,
	},
	{
		garage_id: 23,
		part_id: 307,
		quantity: 158,
	},
	{
		garage_id: 23,
		part_id: 308,
		quantity: 121,
	},
	{
		garage_id: 23,
		part_id: 309,
		quantity: 457,
	},
	{
		garage_id: 23,
		part_id: 310,
		quantity: 211,
	},
	{
		garage_id: 23,
		part_id: 311,
		quantity: 318,
	},
	{
		garage_id: 23,
		part_id: 312,
		quantity: 500,
	},
	{
		garage_id: 23,
		part_id: 313,
		quantity: 361,
	},
	{
		garage_id: 23,
		part_id: 314,
		quantity: 372,
	},
	{
		garage_id: 23,
		part_id: 315,
		quantity: 141,
	},
	{
		garage_id: 23,
		part_id: 316,
		quantity: 372,
	},
	{
		garage_id: 23,
		part_id: 317,
		quantity: 376,
	},
	{
		garage_id: 23,
		part_id: 318,
		quantity: 343,
	},
	{
		garage_id: 23,
		part_id: 319,
		quantity: 382,
	},
	{
		garage_id: 23,
		part_id: 320,
		quantity: 264,
	},
	{
		garage_id: 23,
		part_id: 321,
		quantity: 390,
	},
	{
		garage_id: 23,
		part_id: 322,
		quantity: 395,
	},
	{
		garage_id: 23,
		part_id: 323,
		quantity: 271,
	},
	{
		garage_id: 23,
		part_id: 324,
		quantity: 94,
	},
	{
		garage_id: 23,
		part_id: 325,
		quantity: 160,
	},
	{
		garage_id: 23,
		part_id: 326,
		quantity: 290,
	},
	{
		garage_id: 23,
		part_id: 327,
		quantity: 460,
	},
	{
		garage_id: 23,
		part_id: 328,
		quantity: 449,
	},
	{
		garage_id: 23,
		part_id: 329,
		quantity: 177,
	},
	{
		garage_id: 23,
		part_id: 330,
		quantity: 61,
	},
	{
		garage_id: 23,
		part_id: 331,
		quantity: 444,
	},
	{
		garage_id: 23,
		part_id: 332,
		quantity: 291,
	},
	{
		garage_id: 23,
		part_id: 333,
		quantity: 54,
	},
	{
		garage_id: 23,
		part_id: 334,
		quantity: 350,
	},
	{
		garage_id: 23,
		part_id: 335,
		quantity: 39,
	},
	{
		garage_id: 23,
		part_id: 336,
		quantity: 7,
	},
	{
		garage_id: 23,
		part_id: 337,
		quantity: 206,
	},
	{
		garage_id: 23,
		part_id: 338,
		quantity: 274,
	},
	{
		garage_id: 23,
		part_id: 339,
		quantity: 447,
	},
	{
		garage_id: 23,
		part_id: 340,
		quantity: 335,
	},
	{
		garage_id: 23,
		part_id: 341,
		quantity: 292,
	},
	{
		garage_id: 23,
		part_id: 342,
		quantity: 368,
	},
	{
		garage_id: 23,
		part_id: 343,
		quantity: 63,
	},
	{
		garage_id: 23,
		part_id: 344,
		quantity: 58,
	},
	{
		garage_id: 23,
		part_id: 345,
		quantity: 21,
	},
	{
		garage_id: 23,
		part_id: 346,
		quantity: 347,
	},
	{
		garage_id: 23,
		part_id: 347,
		quantity: 168,
	},
	{
		garage_id: 23,
		part_id: 348,
		quantity: 494,
	},
	{
		garage_id: 23,
		part_id: 349,
		quantity: 293,
	},
	{
		garage_id: 23,
		part_id: 350,
		quantity: 421,
	},
	{
		garage_id: 23,
		part_id: 351,
		quantity: 126,
	},
	{
		garage_id: 23,
		part_id: 352,
		quantity: 24,
	},
	{
		garage_id: 23,
		part_id: 353,
		quantity: 421,
	},
	{
		garage_id: 23,
		part_id: 354,
		quantity: 487,
	},
	{
		garage_id: 23,
		part_id: 355,
		quantity: 240,
	},
	{
		garage_id: 23,
		part_id: 356,
		quantity: 260,
	},
	{
		garage_id: 23,
		part_id: 357,
		quantity: 404,
	},
	{
		garage_id: 23,
		part_id: 358,
		quantity: 118,
	},
	{
		garage_id: 23,
		part_id: 359,
		quantity: 356,
	},
	{
		garage_id: 23,
		part_id: 360,
		quantity: 13,
	},
	{
		garage_id: 23,
		part_id: 361,
		quantity: 55,
	},
	{
		garage_id: 23,
		part_id: 362,
		quantity: 207,
	},
	{
		garage_id: 23,
		part_id: 363,
		quantity: 89,
	},
	{
		garage_id: 23,
		part_id: 364,
		quantity: 304,
	},
	{
		garage_id: 23,
		part_id: 365,
		quantity: 261,
	},
	{
		garage_id: 23,
		part_id: 366,
		quantity: 265,
	},
	{
		garage_id: 23,
		part_id: 367,
		quantity: 167,
	},
	{
		garage_id: 23,
		part_id: 368,
		quantity: 380,
	},
	{
		garage_id: 23,
		part_id: 369,
		quantity: 233,
	},
	{
		garage_id: 23,
		part_id: 370,
		quantity: 245,
	},
	{
		garage_id: 23,
		part_id: 371,
		quantity: 140,
	},
	{
		garage_id: 23,
		part_id: 372,
		quantity: 155,
	},
	{
		garage_id: 23,
		part_id: 373,
		quantity: 274,
	},
	{
		garage_id: 23,
		part_id: 374,
		quantity: 371,
	},
	{
		garage_id: 23,
		part_id: 375,
		quantity: 109,
	},
	{
		garage_id: 23,
		part_id: 376,
		quantity: 9,
	},
	{
		garage_id: 23,
		part_id: 377,
		quantity: 432,
	},
	{
		garage_id: 23,
		part_id: 378,
		quantity: 375,
	},
	{
		garage_id: 23,
		part_id: 379,
		quantity: 236,
	},
	{
		garage_id: 23,
		part_id: 380,
		quantity: 180,
	},
	{
		garage_id: 23,
		part_id: 381,
		quantity: 352,
	},
	{
		garage_id: 23,
		part_id: 382,
		quantity: 426,
	},
	{
		garage_id: 23,
		part_id: 383,
		quantity: 183,
	},
	{
		garage_id: 23,
		part_id: 384,
		quantity: 271,
	},
	{
		garage_id: 23,
		part_id: 385,
		quantity: 401,
	},
	{
		garage_id: 23,
		part_id: 386,
		quantity: 356,
	},
	{
		garage_id: 23,
		part_id: 387,
		quantity: 158,
	},
	{
		garage_id: 23,
		part_id: 388,
		quantity: 338,
	},
	{
		garage_id: 23,
		part_id: 389,
		quantity: 257,
	},
	{
		garage_id: 23,
		part_id: 390,
		quantity: 398,
	},
	{
		garage_id: 23,
		part_id: 391,
		quantity: 174,
	},
	{
		garage_id: 23,
		part_id: 392,
		quantity: 288,
	},
	{
		garage_id: 23,
		part_id: 393,
		quantity: 494,
	},
	{
		garage_id: 23,
		part_id: 394,
		quantity: 95,
	},
	{
		garage_id: 23,
		part_id: 395,
		quantity: 373,
	},
	{
		garage_id: 23,
		part_id: 396,
		quantity: 111,
	},
	{
		garage_id: 23,
		part_id: 397,
		quantity: 61,
	},
	{
		garage_id: 23,
		part_id: 398,
		quantity: 122,
	},
	{
		garage_id: 23,
		part_id: 399,
		quantity: 282,
	},
	{
		garage_id: 24,
		part_id: 0,
		quantity: 238,
	},
	{
		garage_id: 24,
		part_id: 1,
		quantity: 9,
	},
	{
		garage_id: 24,
		part_id: 2,
		quantity: 132,
	},
	{
		garage_id: 24,
		part_id: 3,
		quantity: 325,
	},
	{
		garage_id: 24,
		part_id: 4,
		quantity: 443,
	},
	{
		garage_id: 24,
		part_id: 5,
		quantity: 93,
	},
	{
		garage_id: 24,
		part_id: 6,
		quantity: 190,
	},
	{
		garage_id: 24,
		part_id: 7,
		quantity: 470,
	},
	{
		garage_id: 24,
		part_id: 8,
		quantity: 63,
	},
	{
		garage_id: 24,
		part_id: 9,
		quantity: 383,
	},
	{
		garage_id: 24,
		part_id: 10,
		quantity: 467,
	},
	{
		garage_id: 24,
		part_id: 11,
		quantity: 167,
	},
	{
		garage_id: 24,
		part_id: 12,
		quantity: 275,
	},
	{
		garage_id: 24,
		part_id: 13,
		quantity: 250,
	},
	{
		garage_id: 24,
		part_id: 14,
		quantity: 18,
	},
	{
		garage_id: 24,
		part_id: 15,
		quantity: 364,
	},
	{
		garage_id: 24,
		part_id: 16,
		quantity: 307,
	},
	{
		garage_id: 24,
		part_id: 17,
		quantity: 447,
	},
	{
		garage_id: 24,
		part_id: 18,
		quantity: 227,
	},
	{
		garage_id: 24,
		part_id: 19,
		quantity: 266,
	},
	{
		garage_id: 24,
		part_id: 20,
		quantity: 226,
	},
	{
		garage_id: 24,
		part_id: 21,
		quantity: 67,
	},
	{
		garage_id: 24,
		part_id: 22,
		quantity: 346,
	},
	{
		garage_id: 24,
		part_id: 23,
		quantity: 79,
	},
	{
		garage_id: 24,
		part_id: 24,
		quantity: 109,
	},
	{
		garage_id: 24,
		part_id: 25,
		quantity: 166,
	},
	{
		garage_id: 24,
		part_id: 26,
		quantity: 259,
	},
	{
		garage_id: 24,
		part_id: 27,
		quantity: 290,
	},
	{
		garage_id: 24,
		part_id: 28,
		quantity: 62,
	},
	{
		garage_id: 24,
		part_id: 29,
		quantity: 148,
	},
	{
		garage_id: 24,
		part_id: 30,
		quantity: 350,
	},
	{
		garage_id: 24,
		part_id: 31,
		quantity: 23,
	},
	{
		garage_id: 24,
		part_id: 32,
		quantity: 317,
	},
	{
		garage_id: 24,
		part_id: 33,
		quantity: 82,
	},
	{
		garage_id: 24,
		part_id: 34,
		quantity: 370,
	},
	{
		garage_id: 24,
		part_id: 35,
		quantity: 321,
	},
	{
		garage_id: 24,
		part_id: 36,
		quantity: 208,
	},
	{
		garage_id: 24,
		part_id: 37,
		quantity: 436,
	},
	{
		garage_id: 24,
		part_id: 38,
		quantity: 471,
	},
	{
		garage_id: 24,
		part_id: 39,
		quantity: 176,
	},
	{
		garage_id: 24,
		part_id: 40,
		quantity: 40,
	},
	{
		garage_id: 24,
		part_id: 41,
		quantity: 38,
	},
	{
		garage_id: 24,
		part_id: 42,
		quantity: 361,
	},
	{
		garage_id: 24,
		part_id: 43,
		quantity: 314,
	},
	{
		garage_id: 24,
		part_id: 44,
		quantity: 70,
	},
	{
		garage_id: 24,
		part_id: 45,
		quantity: 418,
	},
	{
		garage_id: 24,
		part_id: 46,
		quantity: 12,
	},
	{
		garage_id: 24,
		part_id: 47,
		quantity: 158,
	},
	{
		garage_id: 24,
		part_id: 48,
		quantity: 184,
	},
	{
		garage_id: 24,
		part_id: 49,
		quantity: 110,
	},
	{
		garage_id: 24,
		part_id: 50,
		quantity: 475,
	},
	{
		garage_id: 24,
		part_id: 51,
		quantity: 47,
	},
	{
		garage_id: 24,
		part_id: 52,
		quantity: 175,
	},
	{
		garage_id: 24,
		part_id: 53,
		quantity: 366,
	},
	{
		garage_id: 24,
		part_id: 54,
		quantity: 471,
	},
	{
		garage_id: 24,
		part_id: 55,
		quantity: 483,
	},
	{
		garage_id: 24,
		part_id: 56,
		quantity: 237,
	},
	{
		garage_id: 24,
		part_id: 57,
		quantity: 328,
	},
	{
		garage_id: 24,
		part_id: 58,
		quantity: 411,
	},
	{
		garage_id: 24,
		part_id: 59,
		quantity: 390,
	},
	{
		garage_id: 24,
		part_id: 60,
		quantity: 15,
	},
	{
		garage_id: 24,
		part_id: 61,
		quantity: 97,
	},
	{
		garage_id: 24,
		part_id: 62,
		quantity: 482,
	},
	{
		garage_id: 24,
		part_id: 63,
		quantity: 392,
	},
	{
		garage_id: 24,
		part_id: 64,
		quantity: 119,
	},
	{
		garage_id: 24,
		part_id: 65,
		quantity: 449,
	},
	{
		garage_id: 24,
		part_id: 66,
		quantity: 453,
	},
	{
		garage_id: 24,
		part_id: 67,
		quantity: 297,
	},
	{
		garage_id: 24,
		part_id: 68,
		quantity: 355,
	},
	{
		garage_id: 24,
		part_id: 69,
		quantity: 8,
	},
	{
		garage_id: 24,
		part_id: 70,
		quantity: 27,
	},
	{
		garage_id: 24,
		part_id: 71,
		quantity: 137,
	},
	{
		garage_id: 24,
		part_id: 72,
		quantity: 205,
	},
	{
		garage_id: 24,
		part_id: 73,
		quantity: 369,
	},
	{
		garage_id: 24,
		part_id: 74,
		quantity: 131,
	},
	{
		garage_id: 24,
		part_id: 75,
		quantity: 172,
	},
	{
		garage_id: 24,
		part_id: 76,
		quantity: 221,
	},
	{
		garage_id: 24,
		part_id: 77,
		quantity: 438,
	},
	{
		garage_id: 24,
		part_id: 78,
		quantity: 398,
	},
	{
		garage_id: 24,
		part_id: 79,
		quantity: 478,
	},
	{
		garage_id: 24,
		part_id: 80,
		quantity: 244,
	},
	{
		garage_id: 24,
		part_id: 81,
		quantity: 282,
	},
	{
		garage_id: 24,
		part_id: 82,
		quantity: 444,
	},
	{
		garage_id: 24,
		part_id: 83,
		quantity: 319,
	},
	{
		garage_id: 24,
		part_id: 84,
		quantity: 393,
	},
	{
		garage_id: 24,
		part_id: 85,
		quantity: 253,
	},
	{
		garage_id: 24,
		part_id: 86,
		quantity: 180,
	},
	{
		garage_id: 24,
		part_id: 87,
		quantity: 149,
	},
	{
		garage_id: 24,
		part_id: 88,
		quantity: 202,
	},
	{
		garage_id: 24,
		part_id: 89,
		quantity: 152,
	},
	{
		garage_id: 24,
		part_id: 90,
		quantity: 5,
	},
	{
		garage_id: 24,
		part_id: 91,
		quantity: 110,
	},
	{
		garage_id: 24,
		part_id: 92,
		quantity: 478,
	},
	{
		garage_id: 24,
		part_id: 93,
		quantity: 336,
	},
	{
		garage_id: 24,
		part_id: 94,
		quantity: 11,
	},
	{
		garage_id: 24,
		part_id: 95,
		quantity: 438,
	},
	{
		garage_id: 24,
		part_id: 96,
		quantity: 125,
	},
	{
		garage_id: 24,
		part_id: 97,
		quantity: 492,
	},
	{
		garage_id: 24,
		part_id: 98,
		quantity: 237,
	},
	{
		garage_id: 24,
		part_id: 99,
		quantity: 493,
	},
	{
		garage_id: 24,
		part_id: 100,
		quantity: 426,
	},
	{
		garage_id: 24,
		part_id: 101,
		quantity: 363,
	},
	{
		garage_id: 24,
		part_id: 102,
		quantity: 89,
	},
	{
		garage_id: 24,
		part_id: 103,
		quantity: 224,
	},
	{
		garage_id: 24,
		part_id: 104,
		quantity: 211,
	},
	{
		garage_id: 24,
		part_id: 105,
		quantity: 474,
	},
	{
		garage_id: 24,
		part_id: 106,
		quantity: 194,
	},
	{
		garage_id: 24,
		part_id: 107,
		quantity: 111,
	},
	{
		garage_id: 24,
		part_id: 108,
		quantity: 296,
	},
	{
		garage_id: 24,
		part_id: 109,
		quantity: 230,
	},
	{
		garage_id: 24,
		part_id: 110,
		quantity: 480,
	},
	{
		garage_id: 24,
		part_id: 111,
		quantity: 175,
	},
	{
		garage_id: 24,
		part_id: 112,
		quantity: 314,
	},
	{
		garage_id: 24,
		part_id: 113,
		quantity: 298,
	},
	{
		garage_id: 24,
		part_id: 114,
		quantity: 275,
	},
	{
		garage_id: 24,
		part_id: 115,
		quantity: 156,
	},
	{
		garage_id: 24,
		part_id: 116,
		quantity: 125,
	},
	{
		garage_id: 24,
		part_id: 117,
		quantity: 92,
	},
	{
		garage_id: 24,
		part_id: 118,
		quantity: 351,
	},
	{
		garage_id: 24,
		part_id: 119,
		quantity: 329,
	},
	{
		garage_id: 24,
		part_id: 120,
		quantity: 31,
	},
	{
		garage_id: 24,
		part_id: 121,
		quantity: 212,
	},
	{
		garage_id: 24,
		part_id: 122,
		quantity: 283,
	},
	{
		garage_id: 24,
		part_id: 123,
		quantity: 295,
	},
	{
		garage_id: 24,
		part_id: 124,
		quantity: 147,
	},
	{
		garage_id: 24,
		part_id: 125,
		quantity: 230,
	},
	{
		garage_id: 24,
		part_id: 126,
		quantity: 304,
	},
	{
		garage_id: 24,
		part_id: 127,
		quantity: 20,
	},
	{
		garage_id: 24,
		part_id: 128,
		quantity: 271,
	},
	{
		garage_id: 24,
		part_id: 129,
		quantity: 350,
	},
	{
		garage_id: 24,
		part_id: 130,
		quantity: 149,
	},
	{
		garage_id: 24,
		part_id: 131,
		quantity: 436,
	},
	{
		garage_id: 24,
		part_id: 132,
		quantity: 5,
	},
	{
		garage_id: 24,
		part_id: 133,
		quantity: 485,
	},
	{
		garage_id: 24,
		part_id: 134,
		quantity: 161,
	},
	{
		garage_id: 24,
		part_id: 135,
		quantity: 274,
	},
	{
		garage_id: 24,
		part_id: 136,
		quantity: 332,
	},
	{
		garage_id: 24,
		part_id: 137,
		quantity: 246,
	},
	{
		garage_id: 24,
		part_id: 138,
		quantity: 236,
	},
	{
		garage_id: 24,
		part_id: 139,
		quantity: 106,
	},
	{
		garage_id: 24,
		part_id: 140,
		quantity: 233,
	},
	{
		garage_id: 24,
		part_id: 141,
		quantity: 159,
	},
	{
		garage_id: 24,
		part_id: 142,
		quantity: 341,
	},
	{
		garage_id: 24,
		part_id: 143,
		quantity: 324,
	},
	{
		garage_id: 24,
		part_id: 144,
		quantity: 170,
	},
	{
		garage_id: 24,
		part_id: 145,
		quantity: 400,
	},
	{
		garage_id: 24,
		part_id: 146,
		quantity: 318,
	},
	{
		garage_id: 24,
		part_id: 147,
		quantity: 59,
	},
	{
		garage_id: 24,
		part_id: 148,
		quantity: 89,
	},
	{
		garage_id: 24,
		part_id: 149,
		quantity: 16,
	},
	{
		garage_id: 24,
		part_id: 150,
		quantity: 337,
	},
	{
		garage_id: 24,
		part_id: 151,
		quantity: 440,
	},
	{
		garage_id: 24,
		part_id: 152,
		quantity: 407,
	},
	{
		garage_id: 24,
		part_id: 153,
		quantity: 75,
	},
	{
		garage_id: 24,
		part_id: 154,
		quantity: 45,
	},
	{
		garage_id: 24,
		part_id: 155,
		quantity: 1,
	},
	{
		garage_id: 24,
		part_id: 156,
		quantity: 298,
	},
	{
		garage_id: 24,
		part_id: 157,
		quantity: 472,
	},
	{
		garage_id: 24,
		part_id: 158,
		quantity: 61,
	},
	{
		garage_id: 24,
		part_id: 159,
		quantity: 142,
	},
	{
		garage_id: 24,
		part_id: 160,
		quantity: 308,
	},
	{
		garage_id: 24,
		part_id: 161,
		quantity: 36,
	},
	{
		garage_id: 24,
		part_id: 162,
		quantity: 461,
	},
	{
		garage_id: 24,
		part_id: 163,
		quantity: 207,
	},
	{
		garage_id: 24,
		part_id: 164,
		quantity: 365,
	},
	{
		garage_id: 24,
		part_id: 165,
		quantity: 330,
	},
	{
		garage_id: 24,
		part_id: 166,
		quantity: 396,
	},
	{
		garage_id: 24,
		part_id: 167,
		quantity: 276,
	},
	{
		garage_id: 24,
		part_id: 168,
		quantity: 394,
	},
	{
		garage_id: 24,
		part_id: 169,
		quantity: 255,
	},
	{
		garage_id: 24,
		part_id: 170,
		quantity: 164,
	},
	{
		garage_id: 24,
		part_id: 171,
		quantity: 320,
	},
	{
		garage_id: 24,
		part_id: 172,
		quantity: 382,
	},
	{
		garage_id: 24,
		part_id: 173,
		quantity: 226,
	},
	{
		garage_id: 24,
		part_id: 174,
		quantity: 135,
	},
	{
		garage_id: 24,
		part_id: 175,
		quantity: 375,
	},
	{
		garage_id: 24,
		part_id: 176,
		quantity: 367,
	},
	{
		garage_id: 24,
		part_id: 177,
		quantity: 299,
	},
	{
		garage_id: 24,
		part_id: 178,
		quantity: 85,
	},
	{
		garage_id: 24,
		part_id: 179,
		quantity: 76,
	},
	{
		garage_id: 24,
		part_id: 180,
		quantity: 67,
	},
	{
		garage_id: 24,
		part_id: 181,
		quantity: 21,
	},
	{
		garage_id: 24,
		part_id: 182,
		quantity: 3,
	},
	{
		garage_id: 24,
		part_id: 183,
		quantity: 449,
	},
	{
		garage_id: 24,
		part_id: 184,
		quantity: 328,
	},
	{
		garage_id: 24,
		part_id: 185,
		quantity: 279,
	},
	{
		garage_id: 24,
		part_id: 186,
		quantity: 134,
	},
	{
		garage_id: 24,
		part_id: 187,
		quantity: 132,
	},
	{
		garage_id: 24,
		part_id: 188,
		quantity: 450,
	},
	{
		garage_id: 24,
		part_id: 189,
		quantity: 49,
	},
	{
		garage_id: 24,
		part_id: 190,
		quantity: 195,
	},
	{
		garage_id: 24,
		part_id: 191,
		quantity: 287,
	},
	{
		garage_id: 24,
		part_id: 192,
		quantity: 355,
	},
	{
		garage_id: 24,
		part_id: 193,
		quantity: 458,
	},
	{
		garage_id: 24,
		part_id: 194,
		quantity: 218,
	},
	{
		garage_id: 24,
		part_id: 195,
		quantity: 138,
	},
	{
		garage_id: 24,
		part_id: 196,
		quantity: 191,
	},
	{
		garage_id: 24,
		part_id: 197,
		quantity: 424,
	},
	{
		garage_id: 24,
		part_id: 198,
		quantity: 237,
	},
	{
		garage_id: 24,
		part_id: 199,
		quantity: 452,
	},
	{
		garage_id: 24,
		part_id: 200,
		quantity: 133,
	},
	{
		garage_id: 24,
		part_id: 201,
		quantity: 335,
	},
	{
		garage_id: 24,
		part_id: 202,
		quantity: 195,
	},
	{
		garage_id: 24,
		part_id: 203,
		quantity: 453,
	},
	{
		garage_id: 24,
		part_id: 204,
		quantity: 215,
	},
	{
		garage_id: 24,
		part_id: 205,
		quantity: 242,
	},
	{
		garage_id: 24,
		part_id: 206,
		quantity: 82,
	},
	{
		garage_id: 24,
		part_id: 207,
		quantity: 428,
	},
	{
		garage_id: 24,
		part_id: 208,
		quantity: 291,
	},
	{
		garage_id: 24,
		part_id: 209,
		quantity: 33,
	},
	{
		garage_id: 24,
		part_id: 210,
		quantity: 384,
	},
	{
		garage_id: 24,
		part_id: 211,
		quantity: 164,
	},
	{
		garage_id: 24,
		part_id: 212,
		quantity: 185,
	},
	{
		garage_id: 24,
		part_id: 213,
		quantity: 143,
	},
	{
		garage_id: 24,
		part_id: 214,
		quantity: 261,
	},
	{
		garage_id: 24,
		part_id: 215,
		quantity: 244,
	},
	{
		garage_id: 24,
		part_id: 216,
		quantity: 68,
	},
	{
		garage_id: 24,
		part_id: 217,
		quantity: 370,
	},
	{
		garage_id: 24,
		part_id: 218,
		quantity: 271,
	},
	{
		garage_id: 24,
		part_id: 219,
		quantity: 311,
	},
	{
		garage_id: 24,
		part_id: 220,
		quantity: 464,
	},
	{
		garage_id: 24,
		part_id: 221,
		quantity: 90,
	},
	{
		garage_id: 24,
		part_id: 222,
		quantity: 418,
	},
	{
		garage_id: 24,
		part_id: 223,
		quantity: 150,
	},
	{
		garage_id: 24,
		part_id: 224,
		quantity: 341,
	},
	{
		garage_id: 24,
		part_id: 225,
		quantity: 219,
	},
	{
		garage_id: 24,
		part_id: 226,
		quantity: 247,
	},
	{
		garage_id: 24,
		part_id: 227,
		quantity: 115,
	},
	{
		garage_id: 24,
		part_id: 228,
		quantity: 371,
	},
	{
		garage_id: 24,
		part_id: 229,
		quantity: 230,
	},
	{
		garage_id: 24,
		part_id: 230,
		quantity: 180,
	},
	{
		garage_id: 24,
		part_id: 231,
		quantity: 383,
	},
	{
		garage_id: 24,
		part_id: 232,
		quantity: 388,
	},
	{
		garage_id: 24,
		part_id: 233,
		quantity: 208,
	},
	{
		garage_id: 24,
		part_id: 234,
		quantity: 233,
	},
	{
		garage_id: 24,
		part_id: 235,
		quantity: 305,
	},
	{
		garage_id: 24,
		part_id: 236,
		quantity: 386,
	},
	{
		garage_id: 24,
		part_id: 237,
		quantity: 287,
	},
	{
		garage_id: 24,
		part_id: 238,
		quantity: 139,
	},
	{
		garage_id: 24,
		part_id: 239,
		quantity: 428,
	},
	{
		garage_id: 24,
		part_id: 240,
		quantity: 253,
	},
	{
		garage_id: 24,
		part_id: 241,
		quantity: 490,
	},
	{
		garage_id: 24,
		part_id: 242,
		quantity: 356,
	},
	{
		garage_id: 24,
		part_id: 243,
		quantity: 169,
	},
	{
		garage_id: 24,
		part_id: 244,
		quantity: 12,
	},
	{
		garage_id: 24,
		part_id: 245,
		quantity: 256,
	},
	{
		garage_id: 24,
		part_id: 246,
		quantity: 264,
	},
	{
		garage_id: 24,
		part_id: 247,
		quantity: 68,
	},
	{
		garage_id: 24,
		part_id: 248,
		quantity: 420,
	},
	{
		garage_id: 24,
		part_id: 249,
		quantity: 136,
	},
	{
		garage_id: 24,
		part_id: 250,
		quantity: 193,
	},
	{
		garage_id: 24,
		part_id: 251,
		quantity: 477,
	},
	{
		garage_id: 24,
		part_id: 252,
		quantity: 95,
	},
	{
		garage_id: 24,
		part_id: 253,
		quantity: 56,
	},
	{
		garage_id: 24,
		part_id: 254,
		quantity: 222,
	},
	{
		garage_id: 24,
		part_id: 255,
		quantity: 170,
	},
	{
		garage_id: 24,
		part_id: 256,
		quantity: 322,
	},
	{
		garage_id: 24,
		part_id: 257,
		quantity: 241,
	},
	{
		garage_id: 24,
		part_id: 258,
		quantity: 16,
	},
	{
		garage_id: 24,
		part_id: 259,
		quantity: 26,
	},
	{
		garage_id: 24,
		part_id: 260,
		quantity: 323,
	},
	{
		garage_id: 24,
		part_id: 261,
		quantity: 274,
	},
	{
		garage_id: 24,
		part_id: 262,
		quantity: 106,
	},
	{
		garage_id: 24,
		part_id: 263,
		quantity: 49,
	},
	{
		garage_id: 24,
		part_id: 264,
		quantity: 32,
	},
	{
		garage_id: 24,
		part_id: 265,
		quantity: 280,
	},
	{
		garage_id: 24,
		part_id: 266,
		quantity: 498,
	},
	{
		garage_id: 24,
		part_id: 267,
		quantity: 381,
	},
	{
		garage_id: 24,
		part_id: 268,
		quantity: 117,
	},
	{
		garage_id: 24,
		part_id: 269,
		quantity: 93,
	},
	{
		garage_id: 24,
		part_id: 270,
		quantity: 242,
	},
	{
		garage_id: 24,
		part_id: 271,
		quantity: 87,
	},
	{
		garage_id: 24,
		part_id: 272,
		quantity: 95,
	},
	{
		garage_id: 24,
		part_id: 273,
		quantity: 123,
	},
	{
		garage_id: 24,
		part_id: 274,
		quantity: 462,
	},
	{
		garage_id: 24,
		part_id: 275,
		quantity: 35,
	},
	{
		garage_id: 24,
		part_id: 276,
		quantity: 362,
	},
	{
		garage_id: 24,
		part_id: 277,
		quantity: 241,
	},
	{
		garage_id: 24,
		part_id: 278,
		quantity: 171,
	},
	{
		garage_id: 24,
		part_id: 279,
		quantity: 175,
	},
	{
		garage_id: 24,
		part_id: 280,
		quantity: 389,
	},
	{
		garage_id: 24,
		part_id: 281,
		quantity: 329,
	},
	{
		garage_id: 24,
		part_id: 282,
		quantity: 360,
	},
	{
		garage_id: 24,
		part_id: 283,
		quantity: 478,
	},
	{
		garage_id: 24,
		part_id: 284,
		quantity: 57,
	},
	{
		garage_id: 24,
		part_id: 285,
		quantity: 115,
	},
	{
		garage_id: 24,
		part_id: 286,
		quantity: 144,
	},
	{
		garage_id: 24,
		part_id: 287,
		quantity: 283,
	},
	{
		garage_id: 24,
		part_id: 288,
		quantity: 87,
	},
	{
		garage_id: 24,
		part_id: 289,
		quantity: 253,
	},
	{
		garage_id: 24,
		part_id: 290,
		quantity: 205,
	},
	{
		garage_id: 24,
		part_id: 291,
		quantity: 475,
	},
	{
		garage_id: 24,
		part_id: 292,
		quantity: 394,
	},
	{
		garage_id: 24,
		part_id: 293,
		quantity: 362,
	},
	{
		garage_id: 24,
		part_id: 294,
		quantity: 226,
	},
	{
		garage_id: 24,
		part_id: 295,
		quantity: 338,
	},
	{
		garage_id: 24,
		part_id: 296,
		quantity: 477,
	},
	{
		garage_id: 24,
		part_id: 297,
		quantity: 222,
	},
	{
		garage_id: 24,
		part_id: 298,
		quantity: 439,
	},
	{
		garage_id: 24,
		part_id: 299,
		quantity: 400,
	},
	{
		garage_id: 24,
		part_id: 300,
		quantity: 294,
	},
	{
		garage_id: 24,
		part_id: 301,
		quantity: 327,
	},
	{
		garage_id: 24,
		part_id: 302,
		quantity: 299,
	},
	{
		garage_id: 24,
		part_id: 303,
		quantity: 489,
	},
	{
		garage_id: 24,
		part_id: 304,
		quantity: 478,
	},
	{
		garage_id: 24,
		part_id: 305,
		quantity: 152,
	},
	{
		garage_id: 24,
		part_id: 306,
		quantity: 465,
	},
	{
		garage_id: 24,
		part_id: 307,
		quantity: 46,
	},
	{
		garage_id: 24,
		part_id: 308,
		quantity: 393,
	},
	{
		garage_id: 24,
		part_id: 309,
		quantity: 244,
	},
	{
		garage_id: 24,
		part_id: 310,
		quantity: 4,
	},
	{
		garage_id: 24,
		part_id: 311,
		quantity: 245,
	},
	{
		garage_id: 24,
		part_id: 312,
		quantity: 430,
	},
	{
		garage_id: 24,
		part_id: 313,
		quantity: 26,
	},
	{
		garage_id: 24,
		part_id: 314,
		quantity: 306,
	},
	{
		garage_id: 24,
		part_id: 315,
		quantity: 218,
	},
	{
		garage_id: 24,
		part_id: 316,
		quantity: 480,
	},
	{
		garage_id: 24,
		part_id: 317,
		quantity: 110,
	},
	{
		garage_id: 24,
		part_id: 318,
		quantity: 303,
	},
	{
		garage_id: 24,
		part_id: 319,
		quantity: 426,
	},
	{
		garage_id: 24,
		part_id: 320,
		quantity: 230,
	},
	{
		garage_id: 24,
		part_id: 321,
		quantity: 212,
	},
	{
		garage_id: 24,
		part_id: 322,
		quantity: 142,
	},
	{
		garage_id: 24,
		part_id: 323,
		quantity: 4,
	},
	{
		garage_id: 24,
		part_id: 324,
		quantity: 250,
	},
	{
		garage_id: 24,
		part_id: 325,
		quantity: 256,
	},
	{
		garage_id: 24,
		part_id: 326,
		quantity: 68,
	},
	{
		garage_id: 24,
		part_id: 327,
		quantity: 345,
	},
	{
		garage_id: 24,
		part_id: 328,
		quantity: 290,
	},
	{
		garage_id: 24,
		part_id: 329,
		quantity: 497,
	},
	{
		garage_id: 24,
		part_id: 330,
		quantity: 303,
	},
	{
		garage_id: 24,
		part_id: 331,
		quantity: 432,
	},
	{
		garage_id: 24,
		part_id: 332,
		quantity: 123,
	},
	{
		garage_id: 24,
		part_id: 333,
		quantity: 200,
	},
	{
		garage_id: 24,
		part_id: 334,
		quantity: 458,
	},
	{
		garage_id: 24,
		part_id: 335,
		quantity: 204,
	},
	{
		garage_id: 24,
		part_id: 336,
		quantity: 237,
	},
	{
		garage_id: 24,
		part_id: 337,
		quantity: 441,
	},
	{
		garage_id: 24,
		part_id: 338,
		quantity: 32,
	},
	{
		garage_id: 24,
		part_id: 339,
		quantity: 121,
	},
	{
		garage_id: 24,
		part_id: 340,
		quantity: 326,
	},
	{
		garage_id: 24,
		part_id: 341,
		quantity: 159,
	},
	{
		garage_id: 24,
		part_id: 342,
		quantity: 21,
	},
	{
		garage_id: 24,
		part_id: 343,
		quantity: 318,
	},
	{
		garage_id: 24,
		part_id: 344,
		quantity: 426,
	},
	{
		garage_id: 24,
		part_id: 345,
		quantity: 188,
	},
	{
		garage_id: 24,
		part_id: 346,
		quantity: 22,
	},
	{
		garage_id: 24,
		part_id: 347,
		quantity: 457,
	},
	{
		garage_id: 24,
		part_id: 348,
		quantity: 448,
	},
	{
		garage_id: 24,
		part_id: 349,
		quantity: 73,
	},
	{
		garage_id: 24,
		part_id: 350,
		quantity: 212,
	},
	{
		garage_id: 24,
		part_id: 351,
		quantity: 142,
	},
	{
		garage_id: 24,
		part_id: 352,
		quantity: 482,
	},
	{
		garage_id: 24,
		part_id: 353,
		quantity: 350,
	},
	{
		garage_id: 24,
		part_id: 354,
		quantity: 39,
	},
	{
		garage_id: 24,
		part_id: 355,
		quantity: 456,
	},
	{
		garage_id: 24,
		part_id: 356,
		quantity: 413,
	},
	{
		garage_id: 24,
		part_id: 357,
		quantity: 395,
	},
	{
		garage_id: 24,
		part_id: 358,
		quantity: 276,
	},
	{
		garage_id: 24,
		part_id: 359,
		quantity: 226,
	},
	{
		garage_id: 24,
		part_id: 360,
		quantity: 422,
	},
	{
		garage_id: 24,
		part_id: 361,
		quantity: 95,
	},
	{
		garage_id: 24,
		part_id: 362,
		quantity: 416,
	},
	{
		garage_id: 24,
		part_id: 363,
		quantity: 112,
	},
	{
		garage_id: 24,
		part_id: 364,
		quantity: 273,
	},
	{
		garage_id: 24,
		part_id: 365,
		quantity: 496,
	},
	{
		garage_id: 24,
		part_id: 366,
		quantity: 305,
	},
	{
		garage_id: 24,
		part_id: 367,
		quantity: 195,
	},
	{
		garage_id: 24,
		part_id: 368,
		quantity: 357,
	},
	{
		garage_id: 24,
		part_id: 369,
		quantity: 292,
	},
	{
		garage_id: 24,
		part_id: 370,
		quantity: 407,
	},
	{
		garage_id: 24,
		part_id: 371,
		quantity: 218,
	},
	{
		garage_id: 24,
		part_id: 372,
		quantity: 342,
	},
	{
		garage_id: 24,
		part_id: 373,
		quantity: 268,
	},
	{
		garage_id: 24,
		part_id: 374,
		quantity: 209,
	},
	{
		garage_id: 24,
		part_id: 375,
		quantity: 36,
	},
	{
		garage_id: 24,
		part_id: 376,
		quantity: 176,
	},
	{
		garage_id: 24,
		part_id: 377,
		quantity: 486,
	},
	{
		garage_id: 24,
		part_id: 378,
		quantity: 307,
	},
	{
		garage_id: 24,
		part_id: 379,
		quantity: 440,
	},
	{
		garage_id: 24,
		part_id: 380,
		quantity: 86,
	},
	{
		garage_id: 24,
		part_id: 381,
		quantity: 494,
	},
	{
		garage_id: 24,
		part_id: 382,
		quantity: 402,
	},
	{
		garage_id: 24,
		part_id: 383,
		quantity: 14,
	},
	{
		garage_id: 24,
		part_id: 384,
		quantity: 211,
	},
	{
		garage_id: 24,
		part_id: 385,
		quantity: 483,
	},
	{
		garage_id: 24,
		part_id: 386,
		quantity: 416,
	},
	{
		garage_id: 24,
		part_id: 387,
		quantity: 370,
	},
	{
		garage_id: 24,
		part_id: 388,
		quantity: 158,
	},
	{
		garage_id: 24,
		part_id: 389,
		quantity: 86,
	},
	{
		garage_id: 24,
		part_id: 390,
		quantity: 99,
	},
	{
		garage_id: 24,
		part_id: 391,
		quantity: 403,
	},
	{
		garage_id: 24,
		part_id: 392,
		quantity: 81,
	},
	{
		garage_id: 24,
		part_id: 393,
		quantity: 406,
	},
	{
		garage_id: 24,
		part_id: 394,
		quantity: 60,
	},
	{
		garage_id: 24,
		part_id: 395,
		quantity: 285,
	},
	{
		garage_id: 24,
		part_id: 396,
		quantity: 26,
	},
	{
		garage_id: 24,
		part_id: 397,
		quantity: 282,
	},
	{
		garage_id: 24,
		part_id: 398,
		quantity: 56,
	},
	{
		garage_id: 24,
		part_id: 399,
		quantity: 331,
	},
	{
		garage_id: 25,
		part_id: 0,
		quantity: 371,
	},
	{
		garage_id: 25,
		part_id: 1,
		quantity: 59,
	},
	{
		garage_id: 25,
		part_id: 2,
		quantity: 205,
	},
	{
		garage_id: 25,
		part_id: 3,
		quantity: 217,
	},
	{
		garage_id: 25,
		part_id: 4,
		quantity: 410,
	},
	{
		garage_id: 25,
		part_id: 5,
		quantity: 275,
	},
	{
		garage_id: 25,
		part_id: 6,
		quantity: 494,
	},
	{
		garage_id: 25,
		part_id: 7,
		quantity: 14,
	},
	{
		garage_id: 25,
		part_id: 8,
		quantity: 314,
	},
	{
		garage_id: 25,
		part_id: 9,
		quantity: 222,
	},
	{
		garage_id: 25,
		part_id: 10,
		quantity: 331,
	},
	{
		garage_id: 25,
		part_id: 11,
		quantity: 418,
	},
	{
		garage_id: 25,
		part_id: 12,
		quantity: 158,
	},
	{
		garage_id: 25,
		part_id: 13,
		quantity: 422,
	},
	{
		garage_id: 25,
		part_id: 14,
		quantity: 346,
	},
	{
		garage_id: 25,
		part_id: 15,
		quantity: 266,
	},
	{
		garage_id: 25,
		part_id: 16,
		quantity: 207,
	},
	{
		garage_id: 25,
		part_id: 17,
		quantity: 5,
	},
	{
		garage_id: 25,
		part_id: 18,
		quantity: 474,
	},
	{
		garage_id: 25,
		part_id: 19,
		quantity: 380,
	},
	{
		garage_id: 25,
		part_id: 20,
		quantity: 469,
	},
	{
		garage_id: 25,
		part_id: 21,
		quantity: 123,
	},
	{
		garage_id: 25,
		part_id: 22,
		quantity: 384,
	},
	{
		garage_id: 25,
		part_id: 23,
		quantity: 315,
	},
	{
		garage_id: 25,
		part_id: 24,
		quantity: 344,
	},
	{
		garage_id: 25,
		part_id: 25,
		quantity: 111,
	},
	{
		garage_id: 25,
		part_id: 26,
		quantity: 77,
	},
	{
		garage_id: 25,
		part_id: 27,
		quantity: 77,
	},
	{
		garage_id: 25,
		part_id: 28,
		quantity: 99,
	},
	{
		garage_id: 25,
		part_id: 29,
		quantity: 154,
	},
	{
		garage_id: 25,
		part_id: 30,
		quantity: 292,
	},
	{
		garage_id: 25,
		part_id: 31,
		quantity: 399,
	},
	{
		garage_id: 25,
		part_id: 32,
		quantity: 231,
	},
	{
		garage_id: 25,
		part_id: 33,
		quantity: 360,
	},
	{
		garage_id: 25,
		part_id: 34,
		quantity: 85,
	},
	{
		garage_id: 25,
		part_id: 35,
		quantity: 477,
	},
	{
		garage_id: 25,
		part_id: 36,
		quantity: 230,
	},
	{
		garage_id: 25,
		part_id: 37,
		quantity: 450,
	},
	{
		garage_id: 25,
		part_id: 38,
		quantity: 292,
	},
	{
		garage_id: 25,
		part_id: 39,
		quantity: 6,
	},
	{
		garage_id: 25,
		part_id: 40,
		quantity: 261,
	},
	{
		garage_id: 25,
		part_id: 41,
		quantity: 397,
	},
	{
		garage_id: 25,
		part_id: 42,
		quantity: 367,
	},
	{
		garage_id: 25,
		part_id: 43,
		quantity: 127,
	},
	{
		garage_id: 25,
		part_id: 44,
		quantity: 129,
	},
	{
		garage_id: 25,
		part_id: 45,
		quantity: 347,
	},
	{
		garage_id: 25,
		part_id: 46,
		quantity: 403,
	},
	{
		garage_id: 25,
		part_id: 47,
		quantity: 249,
	},
	{
		garage_id: 25,
		part_id: 48,
		quantity: 21,
	},
	{
		garage_id: 25,
		part_id: 49,
		quantity: 487,
	},
	{
		garage_id: 25,
		part_id: 50,
		quantity: 270,
	},
	{
		garage_id: 25,
		part_id: 51,
		quantity: 4,
	},
	{
		garage_id: 25,
		part_id: 52,
		quantity: 478,
	},
	{
		garage_id: 25,
		part_id: 53,
		quantity: 497,
	},
	{
		garage_id: 25,
		part_id: 54,
		quantity: 144,
	},
	{
		garage_id: 25,
		part_id: 55,
		quantity: 406,
	},
	{
		garage_id: 25,
		part_id: 56,
		quantity: 93,
	},
	{
		garage_id: 25,
		part_id: 57,
		quantity: 312,
	},
	{
		garage_id: 25,
		part_id: 58,
		quantity: 276,
	},
	{
		garage_id: 25,
		part_id: 59,
		quantity: 195,
	},
	{
		garage_id: 25,
		part_id: 60,
		quantity: 478,
	},
	{
		garage_id: 25,
		part_id: 61,
		quantity: 23,
	},
	{
		garage_id: 25,
		part_id: 62,
		quantity: 93,
	},
	{
		garage_id: 25,
		part_id: 63,
		quantity: 17,
	},
	{
		garage_id: 25,
		part_id: 64,
		quantity: 428,
	},
	{
		garage_id: 25,
		part_id: 65,
		quantity: 126,
	},
	{
		garage_id: 25,
		part_id: 66,
		quantity: 436,
	},
	{
		garage_id: 25,
		part_id: 67,
		quantity: 132,
	},
	{
		garage_id: 25,
		part_id: 68,
		quantity: 386,
	},
	{
		garage_id: 25,
		part_id: 69,
		quantity: 307,
	},
	{
		garage_id: 25,
		part_id: 70,
		quantity: 127,
	},
	{
		garage_id: 25,
		part_id: 71,
		quantity: 148,
	},
	{
		garage_id: 25,
		part_id: 72,
		quantity: 93,
	},
	{
		garage_id: 25,
		part_id: 73,
		quantity: 126,
	},
	{
		garage_id: 25,
		part_id: 74,
		quantity: 375,
	},
	{
		garage_id: 25,
		part_id: 75,
		quantity: 369,
	},
	{
		garage_id: 25,
		part_id: 76,
		quantity: 108,
	},
	{
		garage_id: 25,
		part_id: 77,
		quantity: 22,
	},
	{
		garage_id: 25,
		part_id: 78,
		quantity: 164,
	},
	{
		garage_id: 25,
		part_id: 79,
		quantity: 382,
	},
	{
		garage_id: 25,
		part_id: 80,
		quantity: 196,
	},
	{
		garage_id: 25,
		part_id: 81,
		quantity: 256,
	},
	{
		garage_id: 25,
		part_id: 82,
		quantity: 374,
	},
	{
		garage_id: 25,
		part_id: 83,
		quantity: 72,
	},
	{
		garage_id: 25,
		part_id: 84,
		quantity: 63,
	},
	{
		garage_id: 25,
		part_id: 85,
		quantity: 325,
	},
	{
		garage_id: 25,
		part_id: 86,
		quantity: 364,
	},
	{
		garage_id: 25,
		part_id: 87,
		quantity: 63,
	},
	{
		garage_id: 25,
		part_id: 88,
		quantity: 29,
	},
	{
		garage_id: 25,
		part_id: 89,
		quantity: 457,
	},
	{
		garage_id: 25,
		part_id: 90,
		quantity: 415,
	},
	{
		garage_id: 25,
		part_id: 91,
		quantity: 93,
	},
	{
		garage_id: 25,
		part_id: 92,
		quantity: 105,
	},
	{
		garage_id: 25,
		part_id: 93,
		quantity: 33,
	},
	{
		garage_id: 25,
		part_id: 94,
		quantity: 19,
	},
	{
		garage_id: 25,
		part_id: 95,
		quantity: 407,
	},
	{
		garage_id: 25,
		part_id: 96,
		quantity: 120,
	},
	{
		garage_id: 25,
		part_id: 97,
		quantity: 348,
	},
	{
		garage_id: 25,
		part_id: 98,
		quantity: 464,
	},
	{
		garage_id: 25,
		part_id: 99,
		quantity: 279,
	},
	{
		garage_id: 25,
		part_id: 100,
		quantity: 460,
	},
	{
		garage_id: 25,
		part_id: 101,
		quantity: 3,
	},
	{
		garage_id: 25,
		part_id: 102,
		quantity: 256,
	},
	{
		garage_id: 25,
		part_id: 103,
		quantity: 488,
	},
	{
		garage_id: 25,
		part_id: 104,
		quantity: 331,
	},
	{
		garage_id: 25,
		part_id: 105,
		quantity: 477,
	},
	{
		garage_id: 25,
		part_id: 106,
		quantity: 198,
	},
	{
		garage_id: 25,
		part_id: 107,
		quantity: 308,
	},
	{
		garage_id: 25,
		part_id: 108,
		quantity: 34,
	},
	{
		garage_id: 25,
		part_id: 109,
		quantity: 311,
	},
	{
		garage_id: 25,
		part_id: 110,
		quantity: 100,
	},
	{
		garage_id: 25,
		part_id: 111,
		quantity: 216,
	},
	{
		garage_id: 25,
		part_id: 112,
		quantity: 145,
	},
	{
		garage_id: 25,
		part_id: 113,
		quantity: 299,
	},
	{
		garage_id: 25,
		part_id: 114,
		quantity: 62,
	},
	{
		garage_id: 25,
		part_id: 115,
		quantity: 116,
	},
	{
		garage_id: 25,
		part_id: 116,
		quantity: 487,
	},
	{
		garage_id: 25,
		part_id: 117,
		quantity: 386,
	},
	{
		garage_id: 25,
		part_id: 118,
		quantity: 98,
	},
	{
		garage_id: 25,
		part_id: 119,
		quantity: 383,
	},
	{
		garage_id: 25,
		part_id: 120,
		quantity: 194,
	},
	{
		garage_id: 25,
		part_id: 121,
		quantity: 436,
	},
	{
		garage_id: 25,
		part_id: 122,
		quantity: 155,
	},
	{
		garage_id: 25,
		part_id: 123,
		quantity: 24,
	},
	{
		garage_id: 25,
		part_id: 124,
		quantity: 470,
	},
	{
		garage_id: 25,
		part_id: 125,
		quantity: 352,
	},
	{
		garage_id: 25,
		part_id: 126,
		quantity: 66,
	},
	{
		garage_id: 25,
		part_id: 127,
		quantity: 82,
	},
	{
		garage_id: 25,
		part_id: 128,
		quantity: 30,
	},
	{
		garage_id: 25,
		part_id: 129,
		quantity: 259,
	},
	{
		garage_id: 25,
		part_id: 130,
		quantity: 80,
	},
	{
		garage_id: 25,
		part_id: 131,
		quantity: 174,
	},
	{
		garage_id: 25,
		part_id: 132,
		quantity: 248,
	},
	{
		garage_id: 25,
		part_id: 133,
		quantity: 491,
	},
	{
		garage_id: 25,
		part_id: 134,
		quantity: 457,
	},
	{
		garage_id: 25,
		part_id: 135,
		quantity: 313,
	},
	{
		garage_id: 25,
		part_id: 136,
		quantity: 44,
	},
	{
		garage_id: 25,
		part_id: 137,
		quantity: 22,
	},
	{
		garage_id: 25,
		part_id: 138,
		quantity: 24,
	},
	{
		garage_id: 25,
		part_id: 139,
		quantity: 329,
	},
	{
		garage_id: 25,
		part_id: 140,
		quantity: 83,
	},
	{
		garage_id: 25,
		part_id: 141,
		quantity: 214,
	},
	{
		garage_id: 25,
		part_id: 142,
		quantity: 89,
	},
	{
		garage_id: 25,
		part_id: 143,
		quantity: 371,
	},
	{
		garage_id: 25,
		part_id: 144,
		quantity: 120,
	},
	{
		garage_id: 25,
		part_id: 145,
		quantity: 413,
	},
	{
		garage_id: 25,
		part_id: 146,
		quantity: 434,
	},
	{
		garage_id: 25,
		part_id: 147,
		quantity: 479,
	},
	{
		garage_id: 25,
		part_id: 148,
		quantity: 45,
	},
	{
		garage_id: 25,
		part_id: 149,
		quantity: 497,
	},
	{
		garage_id: 25,
		part_id: 150,
		quantity: 64,
	},
	{
		garage_id: 25,
		part_id: 151,
		quantity: 203,
	},
	{
		garage_id: 25,
		part_id: 152,
		quantity: 252,
	},
	{
		garage_id: 25,
		part_id: 153,
		quantity: 403,
	},
	{
		garage_id: 25,
		part_id: 154,
		quantity: 357,
	},
	{
		garage_id: 25,
		part_id: 155,
		quantity: 442,
	},
	{
		garage_id: 25,
		part_id: 156,
		quantity: 166,
	},
	{
		garage_id: 25,
		part_id: 157,
		quantity: 327,
	},
	{
		garage_id: 25,
		part_id: 158,
		quantity: 489,
	},
	{
		garage_id: 25,
		part_id: 159,
		quantity: 121,
	},
	{
		garage_id: 25,
		part_id: 160,
		quantity: 206,
	},
	{
		garage_id: 25,
		part_id: 161,
		quantity: 70,
	},
	{
		garage_id: 25,
		part_id: 162,
		quantity: 157,
	},
	{
		garage_id: 25,
		part_id: 163,
		quantity: 271,
	},
	{
		garage_id: 25,
		part_id: 164,
		quantity: 350,
	},
	{
		garage_id: 25,
		part_id: 165,
		quantity: 225,
	},
	{
		garage_id: 25,
		part_id: 166,
		quantity: 256,
	},
	{
		garage_id: 25,
		part_id: 167,
		quantity: 467,
	},
	{
		garage_id: 25,
		part_id: 168,
		quantity: 136,
	},
	{
		garage_id: 25,
		part_id: 169,
		quantity: 260,
	},
	{
		garage_id: 25,
		part_id: 170,
		quantity: 186,
	},
	{
		garage_id: 25,
		part_id: 171,
		quantity: 202,
	},
	{
		garage_id: 25,
		part_id: 172,
		quantity: 63,
	},
	{
		garage_id: 25,
		part_id: 173,
		quantity: 495,
	},
	{
		garage_id: 25,
		part_id: 174,
		quantity: 81,
	},
	{
		garage_id: 25,
		part_id: 175,
		quantity: 286,
	},
	{
		garage_id: 25,
		part_id: 176,
		quantity: 226,
	},
	{
		garage_id: 25,
		part_id: 177,
		quantity: 452,
	},
	{
		garage_id: 25,
		part_id: 178,
		quantity: 151,
	},
	{
		garage_id: 25,
		part_id: 179,
		quantity: 298,
	},
	{
		garage_id: 25,
		part_id: 180,
		quantity: 310,
	},
	{
		garage_id: 25,
		part_id: 181,
		quantity: 45,
	},
	{
		garage_id: 25,
		part_id: 182,
		quantity: 241,
	},
	{
		garage_id: 25,
		part_id: 183,
		quantity: 441,
	},
	{
		garage_id: 25,
		part_id: 184,
		quantity: 201,
	},
	{
		garage_id: 25,
		part_id: 185,
		quantity: 452,
	},
	{
		garage_id: 25,
		part_id: 186,
		quantity: 22,
	},
	{
		garage_id: 25,
		part_id: 187,
		quantity: 223,
	},
	{
		garage_id: 25,
		part_id: 188,
		quantity: 220,
	},
	{
		garage_id: 25,
		part_id: 189,
		quantity: 97,
	},
	{
		garage_id: 25,
		part_id: 190,
		quantity: 162,
	},
	{
		garage_id: 25,
		part_id: 191,
		quantity: 400,
	},
	{
		garage_id: 25,
		part_id: 192,
		quantity: 181,
	},
	{
		garage_id: 25,
		part_id: 193,
		quantity: 132,
	},
	{
		garage_id: 25,
		part_id: 194,
		quantity: 88,
	},
	{
		garage_id: 25,
		part_id: 195,
		quantity: 12,
	},
	{
		garage_id: 25,
		part_id: 196,
		quantity: 392,
	},
	{
		garage_id: 25,
		part_id: 197,
		quantity: 51,
	},
	{
		garage_id: 25,
		part_id: 198,
		quantity: 58,
	},
	{
		garage_id: 25,
		part_id: 199,
		quantity: 242,
	},
	{
		garage_id: 25,
		part_id: 200,
		quantity: 419,
	},
	{
		garage_id: 25,
		part_id: 201,
		quantity: 340,
	},
	{
		garage_id: 25,
		part_id: 202,
		quantity: 104,
	},
	{
		garage_id: 25,
		part_id: 203,
		quantity: 279,
	},
	{
		garage_id: 25,
		part_id: 204,
		quantity: 304,
	},
	{
		garage_id: 25,
		part_id: 205,
		quantity: 311,
	},
	{
		garage_id: 25,
		part_id: 206,
		quantity: 64,
	},
	{
		garage_id: 25,
		part_id: 207,
		quantity: 46,
	},
	{
		garage_id: 25,
		part_id: 208,
		quantity: 142,
	},
	{
		garage_id: 25,
		part_id: 209,
		quantity: 212,
	},
	{
		garage_id: 25,
		part_id: 210,
		quantity: 443,
	},
	{
		garage_id: 25,
		part_id: 211,
		quantity: 63,
	},
	{
		garage_id: 25,
		part_id: 212,
		quantity: 44,
	},
	{
		garage_id: 25,
		part_id: 213,
		quantity: 298,
	},
	{
		garage_id: 25,
		part_id: 214,
		quantity: 63,
	},
	{
		garage_id: 25,
		part_id: 215,
		quantity: 294,
	},
	{
		garage_id: 25,
		part_id: 216,
		quantity: 404,
	},
	{
		garage_id: 25,
		part_id: 217,
		quantity: 261,
	},
	{
		garage_id: 25,
		part_id: 218,
		quantity: 103,
	},
	{
		garage_id: 25,
		part_id: 219,
		quantity: 292,
	},
	{
		garage_id: 25,
		part_id: 220,
		quantity: 59,
	},
	{
		garage_id: 25,
		part_id: 221,
		quantity: 470,
	},
	{
		garage_id: 25,
		part_id: 222,
		quantity: 445,
	},
	{
		garage_id: 25,
		part_id: 223,
		quantity: 111,
	},
	{
		garage_id: 25,
		part_id: 224,
		quantity: 484,
	},
	{
		garage_id: 25,
		part_id: 225,
		quantity: 150,
	},
	{
		garage_id: 25,
		part_id: 226,
		quantity: 85,
	},
	{
		garage_id: 25,
		part_id: 227,
		quantity: 440,
	},
	{
		garage_id: 25,
		part_id: 228,
		quantity: 336,
	},
	{
		garage_id: 25,
		part_id: 229,
		quantity: 440,
	},
	{
		garage_id: 25,
		part_id: 230,
		quantity: 477,
	},
	{
		garage_id: 25,
		part_id: 231,
		quantity: 116,
	},
	{
		garage_id: 25,
		part_id: 232,
		quantity: 60,
	},
	{
		garage_id: 25,
		part_id: 233,
		quantity: 167,
	},
	{
		garage_id: 25,
		part_id: 234,
		quantity: 108,
	},
	{
		garage_id: 25,
		part_id: 235,
		quantity: 18,
	},
	{
		garage_id: 25,
		part_id: 236,
		quantity: 219,
	},
	{
		garage_id: 25,
		part_id: 237,
		quantity: 51,
	},
	{
		garage_id: 25,
		part_id: 238,
		quantity: 1,
	},
	{
		garage_id: 25,
		part_id: 239,
		quantity: 271,
	},
	{
		garage_id: 25,
		part_id: 240,
		quantity: 137,
	},
	{
		garage_id: 25,
		part_id: 241,
		quantity: 321,
	},
	{
		garage_id: 25,
		part_id: 242,
		quantity: 381,
	},
	{
		garage_id: 25,
		part_id: 243,
		quantity: 102,
	},
	{
		garage_id: 25,
		part_id: 244,
		quantity: 396,
	},
	{
		garage_id: 25,
		part_id: 245,
		quantity: 480,
	},
	{
		garage_id: 25,
		part_id: 246,
		quantity: 426,
	},
	{
		garage_id: 25,
		part_id: 247,
		quantity: 81,
	},
	{
		garage_id: 25,
		part_id: 248,
		quantity: 125,
	},
	{
		garage_id: 25,
		part_id: 249,
		quantity: 380,
	},
	{
		garage_id: 25,
		part_id: 250,
		quantity: 435,
	},
	{
		garage_id: 25,
		part_id: 251,
		quantity: 338,
	},
	{
		garage_id: 25,
		part_id: 252,
		quantity: 56,
	},
	{
		garage_id: 25,
		part_id: 253,
		quantity: 413,
	},
	{
		garage_id: 25,
		part_id: 254,
		quantity: 430,
	},
	{
		garage_id: 25,
		part_id: 255,
		quantity: 492,
	},
	{
		garage_id: 25,
		part_id: 256,
		quantity: 342,
	},
	{
		garage_id: 25,
		part_id: 257,
		quantity: 80,
	},
	{
		garage_id: 25,
		part_id: 258,
		quantity: 26,
	},
	{
		garage_id: 25,
		part_id: 259,
		quantity: 40,
	},
	{
		garage_id: 25,
		part_id: 260,
		quantity: 154,
	},
	{
		garage_id: 25,
		part_id: 261,
		quantity: 461,
	},
	{
		garage_id: 25,
		part_id: 262,
		quantity: 270,
	},
	{
		garage_id: 25,
		part_id: 263,
		quantity: 238,
	},
	{
		garage_id: 25,
		part_id: 264,
		quantity: 392,
	},
	{
		garage_id: 25,
		part_id: 265,
		quantity: 116,
	},
	{
		garage_id: 25,
		part_id: 266,
		quantity: 179,
	},
	{
		garage_id: 25,
		part_id: 267,
		quantity: 148,
	},
	{
		garage_id: 25,
		part_id: 268,
		quantity: 144,
	},
	{
		garage_id: 25,
		part_id: 269,
		quantity: 423,
	},
	{
		garage_id: 25,
		part_id: 270,
		quantity: 196,
	},
	{
		garage_id: 25,
		part_id: 271,
		quantity: 72,
	},
	{
		garage_id: 25,
		part_id: 272,
		quantity: 191,
	},
	{
		garage_id: 25,
		part_id: 273,
		quantity: 499,
	},
	{
		garage_id: 25,
		part_id: 274,
		quantity: 484,
	},
	{
		garage_id: 25,
		part_id: 275,
		quantity: 315,
	},
	{
		garage_id: 25,
		part_id: 276,
		quantity: 230,
	},
	{
		garage_id: 25,
		part_id: 277,
		quantity: 500,
	},
	{
		garage_id: 25,
		part_id: 278,
		quantity: 340,
	},
	{
		garage_id: 25,
		part_id: 279,
		quantity: 143,
	},
	{
		garage_id: 25,
		part_id: 280,
		quantity: 449,
	},
	{
		garage_id: 25,
		part_id: 281,
		quantity: 8,
	},
	{
		garage_id: 25,
		part_id: 282,
		quantity: 61,
	},
	{
		garage_id: 25,
		part_id: 283,
		quantity: 354,
	},
	{
		garage_id: 25,
		part_id: 284,
		quantity: 161,
	},
	{
		garage_id: 25,
		part_id: 285,
		quantity: 52,
	},
	{
		garage_id: 25,
		part_id: 286,
		quantity: 251,
	},
	{
		garage_id: 25,
		part_id: 287,
		quantity: 324,
	},
	{
		garage_id: 25,
		part_id: 288,
		quantity: 63,
	},
	{
		garage_id: 25,
		part_id: 289,
		quantity: 151,
	},
	{
		garage_id: 25,
		part_id: 290,
		quantity: 356,
	},
	{
		garage_id: 25,
		part_id: 291,
		quantity: 108,
	},
	{
		garage_id: 25,
		part_id: 292,
		quantity: 183,
	},
	{
		garage_id: 25,
		part_id: 293,
		quantity: 203,
	},
	{
		garage_id: 25,
		part_id: 294,
		quantity: 76,
	},
	{
		garage_id: 25,
		part_id: 295,
		quantity: 256,
	},
	{
		garage_id: 25,
		part_id: 296,
		quantity: 83,
	},
	{
		garage_id: 25,
		part_id: 297,
		quantity: 177,
	},
	{
		garage_id: 25,
		part_id: 298,
		quantity: 441,
	},
	{
		garage_id: 25,
		part_id: 299,
		quantity: 34,
	},
	{
		garage_id: 25,
		part_id: 300,
		quantity: 185,
	},
	{
		garage_id: 25,
		part_id: 301,
		quantity: 420,
	},
	{
		garage_id: 25,
		part_id: 302,
		quantity: 140,
	},
	{
		garage_id: 25,
		part_id: 303,
		quantity: 9,
	},
	{
		garage_id: 25,
		part_id: 304,
		quantity: 286,
	},
	{
		garage_id: 25,
		part_id: 305,
		quantity: 123,
	},
	{
		garage_id: 25,
		part_id: 306,
		quantity: 175,
	},
	{
		garage_id: 25,
		part_id: 307,
		quantity: 221,
	},
	{
		garage_id: 25,
		part_id: 308,
		quantity: 110,
	},
	{
		garage_id: 25,
		part_id: 309,
		quantity: 22,
	},
	{
		garage_id: 25,
		part_id: 310,
		quantity: 380,
	},
	{
		garage_id: 25,
		part_id: 311,
		quantity: 61,
	},
	{
		garage_id: 25,
		part_id: 312,
		quantity: 145,
	},
	{
		garage_id: 25,
		part_id: 313,
		quantity: 322,
	},
	{
		garage_id: 25,
		part_id: 314,
		quantity: 327,
	},
	{
		garage_id: 25,
		part_id: 315,
		quantity: 178,
	},
	{
		garage_id: 25,
		part_id: 316,
		quantity: 52,
	},
	{
		garage_id: 25,
		part_id: 317,
		quantity: 206,
	},
	{
		garage_id: 25,
		part_id: 318,
		quantity: 134,
	},
	{
		garage_id: 25,
		part_id: 319,
		quantity: 500,
	},
	{
		garage_id: 25,
		part_id: 320,
		quantity: 313,
	},
	{
		garage_id: 25,
		part_id: 321,
		quantity: 275,
	},
	{
		garage_id: 25,
		part_id: 322,
		quantity: 341,
	},
	{
		garage_id: 25,
		part_id: 323,
		quantity: 457,
	},
	{
		garage_id: 25,
		part_id: 324,
		quantity: 445,
	},
	{
		garage_id: 25,
		part_id: 325,
		quantity: 249,
	},
	{
		garage_id: 25,
		part_id: 326,
		quantity: 212,
	},
	{
		garage_id: 25,
		part_id: 327,
		quantity: 232,
	},
	{
		garage_id: 25,
		part_id: 328,
		quantity: 136,
	},
	{
		garage_id: 25,
		part_id: 329,
		quantity: 220,
	},
	{
		garage_id: 25,
		part_id: 330,
		quantity: 261,
	},
	{
		garage_id: 25,
		part_id: 331,
		quantity: 141,
	},
	{
		garage_id: 25,
		part_id: 332,
		quantity: 232,
	},
	{
		garage_id: 25,
		part_id: 333,
		quantity: 463,
	},
	{
		garage_id: 25,
		part_id: 334,
		quantity: 334,
	},
	{
		garage_id: 25,
		part_id: 335,
		quantity: 306,
	},
	{
		garage_id: 25,
		part_id: 336,
		quantity: 315,
	},
	{
		garage_id: 25,
		part_id: 337,
		quantity: 25,
	},
	{
		garage_id: 25,
		part_id: 338,
		quantity: 207,
	},
	{
		garage_id: 25,
		part_id: 339,
		quantity: 439,
	},
	{
		garage_id: 25,
		part_id: 340,
		quantity: 394,
	},
	{
		garage_id: 25,
		part_id: 341,
		quantity: 426,
	},
	{
		garage_id: 25,
		part_id: 342,
		quantity: 67,
	},
	{
		garage_id: 25,
		part_id: 343,
		quantity: 191,
	},
	{
		garage_id: 25,
		part_id: 344,
		quantity: 367,
	},
	{
		garage_id: 25,
		part_id: 345,
		quantity: 399,
	},
	{
		garage_id: 25,
		part_id: 346,
		quantity: 36,
	},
	{
		garage_id: 25,
		part_id: 347,
		quantity: 149,
	},
	{
		garage_id: 25,
		part_id: 348,
		quantity: 185,
	},
	{
		garage_id: 25,
		part_id: 349,
		quantity: 307,
	},
	{
		garage_id: 25,
		part_id: 350,
		quantity: 111,
	},
	{
		garage_id: 25,
		part_id: 351,
		quantity: 378,
	},
	{
		garage_id: 25,
		part_id: 352,
		quantity: 417,
	},
	{
		garage_id: 25,
		part_id: 353,
		quantity: 442,
	},
	{
		garage_id: 25,
		part_id: 354,
		quantity: 14,
	},
	{
		garage_id: 25,
		part_id: 355,
		quantity: 319,
	},
	{
		garage_id: 25,
		part_id: 356,
		quantity: 38,
	},
	{
		garage_id: 25,
		part_id: 357,
		quantity: 260,
	},
	{
		garage_id: 25,
		part_id: 358,
		quantity: 479,
	},
	{
		garage_id: 25,
		part_id: 359,
		quantity: 450,
	},
	{
		garage_id: 25,
		part_id: 360,
		quantity: 144,
	},
	{
		garage_id: 25,
		part_id: 361,
		quantity: 479,
	},
	{
		garage_id: 25,
		part_id: 362,
		quantity: 437,
	},
	{
		garage_id: 25,
		part_id: 363,
		quantity: 347,
	},
	{
		garage_id: 25,
		part_id: 364,
		quantity: 477,
	},
	{
		garage_id: 25,
		part_id: 365,
		quantity: 273,
	},
	{
		garage_id: 25,
		part_id: 366,
		quantity: 263,
	},
	{
		garage_id: 25,
		part_id: 367,
		quantity: 81,
	},
	{
		garage_id: 25,
		part_id: 368,
		quantity: 131,
	},
	{
		garage_id: 25,
		part_id: 369,
		quantity: 76,
	},
	{
		garage_id: 25,
		part_id: 370,
		quantity: 33,
	},
	{
		garage_id: 25,
		part_id: 371,
		quantity: 37,
	},
	{
		garage_id: 25,
		part_id: 372,
		quantity: 378,
	},
	{
		garage_id: 25,
		part_id: 373,
		quantity: 11,
	},
	{
		garage_id: 25,
		part_id: 374,
		quantity: 252,
	},
	{
		garage_id: 25,
		part_id: 375,
		quantity: 17,
	},
	{
		garage_id: 25,
		part_id: 376,
		quantity: 68,
	},
	{
		garage_id: 25,
		part_id: 377,
		quantity: 277,
	},
	{
		garage_id: 25,
		part_id: 378,
		quantity: 489,
	},
	{
		garage_id: 25,
		part_id: 379,
		quantity: 267,
	},
	{
		garage_id: 25,
		part_id: 380,
		quantity: 120,
	},
	{
		garage_id: 25,
		part_id: 381,
		quantity: 213,
	},
	{
		garage_id: 25,
		part_id: 382,
		quantity: 62,
	},
	{
		garage_id: 25,
		part_id: 383,
		quantity: 372,
	},
	{
		garage_id: 25,
		part_id: 384,
		quantity: 297,
	},
	{
		garage_id: 25,
		part_id: 385,
		quantity: 162,
	},
	{
		garage_id: 25,
		part_id: 386,
		quantity: 198,
	},
	{
		garage_id: 25,
		part_id: 387,
		quantity: 452,
	},
	{
		garage_id: 25,
		part_id: 388,
		quantity: 101,
	},
	{
		garage_id: 25,
		part_id: 389,
		quantity: 115,
	},
	{
		garage_id: 25,
		part_id: 390,
		quantity: 356,
	},
	{
		garage_id: 25,
		part_id: 391,
		quantity: 192,
	},
	{
		garage_id: 25,
		part_id: 392,
		quantity: 136,
	},
	{
		garage_id: 25,
		part_id: 393,
		quantity: 167,
	},
	{
		garage_id: 25,
		part_id: 394,
		quantity: 476,
	},
	{
		garage_id: 25,
		part_id: 395,
		quantity: 323,
	},
	{
		garage_id: 25,
		part_id: 396,
		quantity: 134,
	},
	{
		garage_id: 25,
		part_id: 397,
		quantity: 317,
	},
	{
		garage_id: 25,
		part_id: 398,
		quantity: 400,
	},
	{
		garage_id: 25,
		part_id: 399,
		quantity: 480,
	},
	{
		garage_id: 26,
		part_id: 0,
		quantity: 161,
	},
	{
		garage_id: 26,
		part_id: 1,
		quantity: 460,
	},
	{
		garage_id: 26,
		part_id: 2,
		quantity: 9,
	},
	{
		garage_id: 26,
		part_id: 3,
		quantity: 407,
	},
	{
		garage_id: 26,
		part_id: 4,
		quantity: 288,
	},
	{
		garage_id: 26,
		part_id: 5,
		quantity: 68,
	},
	{
		garage_id: 26,
		part_id: 6,
		quantity: 148,
	},
	{
		garage_id: 26,
		part_id: 7,
		quantity: 104,
	},
	{
		garage_id: 26,
		part_id: 8,
		quantity: 51,
	},
	{
		garage_id: 26,
		part_id: 9,
		quantity: 157,
	},
	{
		garage_id: 26,
		part_id: 10,
		quantity: 397,
	},
	{
		garage_id: 26,
		part_id: 11,
		quantity: 381,
	},
	{
		garage_id: 26,
		part_id: 12,
		quantity: 274,
	},
	{
		garage_id: 26,
		part_id: 13,
		quantity: 41,
	},
	{
		garage_id: 26,
		part_id: 14,
		quantity: 281,
	},
	{
		garage_id: 26,
		part_id: 15,
		quantity: 222,
	},
	{
		garage_id: 26,
		part_id: 16,
		quantity: 132,
	},
	{
		garage_id: 26,
		part_id: 17,
		quantity: 170,
	},
	{
		garage_id: 26,
		part_id: 18,
		quantity: 320,
	},
	{
		garage_id: 26,
		part_id: 19,
		quantity: 328,
	},
	{
		garage_id: 26,
		part_id: 20,
		quantity: 347,
	},
	{
		garage_id: 26,
		part_id: 21,
		quantity: 44,
	},
	{
		garage_id: 26,
		part_id: 22,
		quantity: 339,
	},
	{
		garage_id: 26,
		part_id: 23,
		quantity: 202,
	},
	{
		garage_id: 26,
		part_id: 24,
		quantity: 95,
	},
	{
		garage_id: 26,
		part_id: 25,
		quantity: 191,
	},
	{
		garage_id: 26,
		part_id: 26,
		quantity: 33,
	},
	{
		garage_id: 26,
		part_id: 27,
		quantity: 14,
	},
	{
		garage_id: 26,
		part_id: 28,
		quantity: 134,
	},
	{
		garage_id: 26,
		part_id: 29,
		quantity: 32,
	},
	{
		garage_id: 26,
		part_id: 30,
		quantity: 81,
	},
	{
		garage_id: 26,
		part_id: 31,
		quantity: 75,
	},
	{
		garage_id: 26,
		part_id: 32,
		quantity: 239,
	},
	{
		garage_id: 26,
		part_id: 33,
		quantity: 242,
	},
	{
		garage_id: 26,
		part_id: 34,
		quantity: 158,
	},
	{
		garage_id: 26,
		part_id: 35,
		quantity: 48,
	},
	{
		garage_id: 26,
		part_id: 36,
		quantity: 155,
	},
	{
		garage_id: 26,
		part_id: 37,
		quantity: 424,
	},
	{
		garage_id: 26,
		part_id: 38,
		quantity: 144,
	},
	{
		garage_id: 26,
		part_id: 39,
		quantity: 0,
	},
	{
		garage_id: 26,
		part_id: 40,
		quantity: 428,
	},
	{
		garage_id: 26,
		part_id: 41,
		quantity: 84,
	},
	{
		garage_id: 26,
		part_id: 42,
		quantity: 84,
	},
	{
		garage_id: 26,
		part_id: 43,
		quantity: 59,
	},
	{
		garage_id: 26,
		part_id: 44,
		quantity: 433,
	},
	{
		garage_id: 26,
		part_id: 45,
		quantity: 52,
	},
	{
		garage_id: 26,
		part_id: 46,
		quantity: 348,
	},
	{
		garage_id: 26,
		part_id: 47,
		quantity: 31,
	},
	{
		garage_id: 26,
		part_id: 48,
		quantity: 450,
	},
	{
		garage_id: 26,
		part_id: 49,
		quantity: 261,
	},
	{
		garage_id: 26,
		part_id: 50,
		quantity: 55,
	},
	{
		garage_id: 26,
		part_id: 51,
		quantity: 60,
	},
	{
		garage_id: 26,
		part_id: 52,
		quantity: 61,
	},
	{
		garage_id: 26,
		part_id: 53,
		quantity: 299,
	},
	{
		garage_id: 26,
		part_id: 54,
		quantity: 68,
	},
	{
		garage_id: 26,
		part_id: 55,
		quantity: 352,
	},
	{
		garage_id: 26,
		part_id: 56,
		quantity: 58,
	},
	{
		garage_id: 26,
		part_id: 57,
		quantity: 185,
	},
	{
		garage_id: 26,
		part_id: 58,
		quantity: 76,
	},
	{
		garage_id: 26,
		part_id: 59,
		quantity: 16,
	},
	{
		garage_id: 26,
		part_id: 60,
		quantity: 1,
	},
	{
		garage_id: 26,
		part_id: 61,
		quantity: 7,
	},
	{
		garage_id: 26,
		part_id: 62,
		quantity: 301,
	},
	{
		garage_id: 26,
		part_id: 63,
		quantity: 466,
	},
	{
		garage_id: 26,
		part_id: 64,
		quantity: 58,
	},
	{
		garage_id: 26,
		part_id: 65,
		quantity: 376,
	},
	{
		garage_id: 26,
		part_id: 66,
		quantity: 289,
	},
	{
		garage_id: 26,
		part_id: 67,
		quantity: 221,
	},
	{
		garage_id: 26,
		part_id: 68,
		quantity: 177,
	},
	{
		garage_id: 26,
		part_id: 69,
		quantity: 389,
	},
	{
		garage_id: 26,
		part_id: 70,
		quantity: 216,
	},
	{
		garage_id: 26,
		part_id: 71,
		quantity: 363,
	},
	{
		garage_id: 26,
		part_id: 72,
		quantity: 261,
	},
	{
		garage_id: 26,
		part_id: 73,
		quantity: 164,
	},
	{
		garage_id: 26,
		part_id: 74,
		quantity: 134,
	},
	{
		garage_id: 26,
		part_id: 75,
		quantity: 418,
	},
	{
		garage_id: 26,
		part_id: 76,
		quantity: 194,
	},
	{
		garage_id: 26,
		part_id: 77,
		quantity: 375,
	},
	{
		garage_id: 26,
		part_id: 78,
		quantity: 270,
	},
	{
		garage_id: 26,
		part_id: 79,
		quantity: 101,
	},
	{
		garage_id: 26,
		part_id: 80,
		quantity: 156,
	},
	{
		garage_id: 26,
		part_id: 81,
		quantity: 141,
	},
	{
		garage_id: 26,
		part_id: 82,
		quantity: 441,
	},
	{
		garage_id: 26,
		part_id: 83,
		quantity: 241,
	},
	{
		garage_id: 26,
		part_id: 84,
		quantity: 263,
	},
	{
		garage_id: 26,
		part_id: 85,
		quantity: 488,
	},
	{
		garage_id: 26,
		part_id: 86,
		quantity: 498,
	},
	{
		garage_id: 26,
		part_id: 87,
		quantity: 377,
	},
	{
		garage_id: 26,
		part_id: 88,
		quantity: 307,
	},
	{
		garage_id: 26,
		part_id: 89,
		quantity: 483,
	},
	{
		garage_id: 26,
		part_id: 90,
		quantity: 241,
	},
	{
		garage_id: 26,
		part_id: 91,
		quantity: 62,
	},
	{
		garage_id: 26,
		part_id: 92,
		quantity: 92,
	},
	{
		garage_id: 26,
		part_id: 93,
		quantity: 453,
	},
	{
		garage_id: 26,
		part_id: 94,
		quantity: 245,
	},
	{
		garage_id: 26,
		part_id: 95,
		quantity: 442,
	},
	{
		garage_id: 26,
		part_id: 96,
		quantity: 225,
	},
	{
		garage_id: 26,
		part_id: 97,
		quantity: 328,
	},
	{
		garage_id: 26,
		part_id: 98,
		quantity: 203,
	},
	{
		garage_id: 26,
		part_id: 99,
		quantity: 239,
	},
	{
		garage_id: 26,
		part_id: 100,
		quantity: 92,
	},
	{
		garage_id: 26,
		part_id: 101,
		quantity: 244,
	},
	{
		garage_id: 26,
		part_id: 102,
		quantity: 33,
	},
	{
		garage_id: 26,
		part_id: 103,
		quantity: 362,
	},
	{
		garage_id: 26,
		part_id: 104,
		quantity: 16,
	},
	{
		garage_id: 26,
		part_id: 105,
		quantity: 320,
	},
	{
		garage_id: 26,
		part_id: 106,
		quantity: 217,
	},
	{
		garage_id: 26,
		part_id: 107,
		quantity: 230,
	},
	{
		garage_id: 26,
		part_id: 108,
		quantity: 263,
	},
	{
		garage_id: 26,
		part_id: 109,
		quantity: 257,
	},
	{
		garage_id: 26,
		part_id: 110,
		quantity: 174,
	},
	{
		garage_id: 26,
		part_id: 111,
		quantity: 101,
	},
	{
		garage_id: 26,
		part_id: 112,
		quantity: 227,
	},
	{
		garage_id: 26,
		part_id: 113,
		quantity: 211,
	},
	{
		garage_id: 26,
		part_id: 114,
		quantity: 279,
	},
	{
		garage_id: 26,
		part_id: 115,
		quantity: 96,
	},
	{
		garage_id: 26,
		part_id: 116,
		quantity: 387,
	},
	{
		garage_id: 26,
		part_id: 117,
		quantity: 204,
	},
	{
		garage_id: 26,
		part_id: 118,
		quantity: 397,
	},
	{
		garage_id: 26,
		part_id: 119,
		quantity: 38,
	},
	{
		garage_id: 26,
		part_id: 120,
		quantity: 199,
	},
	{
		garage_id: 26,
		part_id: 121,
		quantity: 269,
	},
	{
		garage_id: 26,
		part_id: 122,
		quantity: 258,
	},
	{
		garage_id: 26,
		part_id: 123,
		quantity: 159,
	},
	{
		garage_id: 26,
		part_id: 124,
		quantity: 477,
	},
	{
		garage_id: 26,
		part_id: 125,
		quantity: 206,
	},
	{
		garage_id: 26,
		part_id: 126,
		quantity: 435,
	},
	{
		garage_id: 26,
		part_id: 127,
		quantity: 79,
	},
	{
		garage_id: 26,
		part_id: 128,
		quantity: 93,
	},
	{
		garage_id: 26,
		part_id: 129,
		quantity: 22,
	},
	{
		garage_id: 26,
		part_id: 130,
		quantity: 242,
	},
	{
		garage_id: 26,
		part_id: 131,
		quantity: 328,
	},
	{
		garage_id: 26,
		part_id: 132,
		quantity: 209,
	},
	{
		garage_id: 26,
		part_id: 133,
		quantity: 302,
	},
	{
		garage_id: 26,
		part_id: 134,
		quantity: 105,
	},
	{
		garage_id: 26,
		part_id: 135,
		quantity: 368,
	},
	{
		garage_id: 26,
		part_id: 136,
		quantity: 480,
	},
	{
		garage_id: 26,
		part_id: 137,
		quantity: 241,
	},
	{
		garage_id: 26,
		part_id: 138,
		quantity: 237,
	},
	{
		garage_id: 26,
		part_id: 139,
		quantity: 133,
	},
	{
		garage_id: 26,
		part_id: 140,
		quantity: 163,
	},
	{
		garage_id: 26,
		part_id: 141,
		quantity: 151,
	},
	{
		garage_id: 26,
		part_id: 142,
		quantity: 382,
	},
	{
		garage_id: 26,
		part_id: 143,
		quantity: 75,
	},
	{
		garage_id: 26,
		part_id: 144,
		quantity: 376,
	},
	{
		garage_id: 26,
		part_id: 145,
		quantity: 13,
	},
	{
		garage_id: 26,
		part_id: 146,
		quantity: 303,
	},
	{
		garage_id: 26,
		part_id: 147,
		quantity: 295,
	},
	{
		garage_id: 26,
		part_id: 148,
		quantity: 441,
	},
	{
		garage_id: 26,
		part_id: 149,
		quantity: 339,
	},
	{
		garage_id: 26,
		part_id: 150,
		quantity: 463,
	},
	{
		garage_id: 26,
		part_id: 151,
		quantity: 243,
	},
	{
		garage_id: 26,
		part_id: 152,
		quantity: 90,
	},
	{
		garage_id: 26,
		part_id: 153,
		quantity: 113,
	},
	{
		garage_id: 26,
		part_id: 154,
		quantity: 340,
	},
	{
		garage_id: 26,
		part_id: 155,
		quantity: 212,
	},
	{
		garage_id: 26,
		part_id: 156,
		quantity: 379,
	},
	{
		garage_id: 26,
		part_id: 157,
		quantity: 464,
	},
	{
		garage_id: 26,
		part_id: 158,
		quantity: 161,
	},
	{
		garage_id: 26,
		part_id: 159,
		quantity: 256,
	},
	{
		garage_id: 26,
		part_id: 160,
		quantity: 489,
	},
	{
		garage_id: 26,
		part_id: 161,
		quantity: 231,
	},
	{
		garage_id: 26,
		part_id: 162,
		quantity: 277,
	},
	{
		garage_id: 26,
		part_id: 163,
		quantity: 141,
	},
	{
		garage_id: 26,
		part_id: 164,
		quantity: 204,
	},
	{
		garage_id: 26,
		part_id: 165,
		quantity: 239,
	},
	{
		garage_id: 26,
		part_id: 166,
		quantity: 273,
	},
	{
		garage_id: 26,
		part_id: 167,
		quantity: 207,
	},
	{
		garage_id: 26,
		part_id: 168,
		quantity: 369,
	},
	{
		garage_id: 26,
		part_id: 169,
		quantity: 303,
	},
	{
		garage_id: 26,
		part_id: 170,
		quantity: 52,
	},
	{
		garage_id: 26,
		part_id: 171,
		quantity: 124,
	},
	{
		garage_id: 26,
		part_id: 172,
		quantity: 133,
	},
	{
		garage_id: 26,
		part_id: 173,
		quantity: 16,
	},
	{
		garage_id: 26,
		part_id: 174,
		quantity: 277,
	},
	{
		garage_id: 26,
		part_id: 175,
		quantity: 323,
	},
	{
		garage_id: 26,
		part_id: 176,
		quantity: 427,
	},
	{
		garage_id: 26,
		part_id: 177,
		quantity: 80,
	},
	{
		garage_id: 26,
		part_id: 178,
		quantity: 74,
	},
	{
		garage_id: 26,
		part_id: 179,
		quantity: 155,
	},
	{
		garage_id: 26,
		part_id: 180,
		quantity: 49,
	},
	{
		garage_id: 26,
		part_id: 181,
		quantity: 13,
	},
	{
		garage_id: 26,
		part_id: 182,
		quantity: 294,
	},
	{
		garage_id: 26,
		part_id: 183,
		quantity: 482,
	},
	{
		garage_id: 26,
		part_id: 184,
		quantity: 9,
	},
	{
		garage_id: 26,
		part_id: 185,
		quantity: 70,
	},
	{
		garage_id: 26,
		part_id: 186,
		quantity: 42,
	},
	{
		garage_id: 26,
		part_id: 187,
		quantity: 39,
	},
	{
		garage_id: 26,
		part_id: 188,
		quantity: 119,
	},
	{
		garage_id: 26,
		part_id: 189,
		quantity: 462,
	},
	{
		garage_id: 26,
		part_id: 190,
		quantity: 222,
	},
	{
		garage_id: 26,
		part_id: 191,
		quantity: 445,
	},
	{
		garage_id: 26,
		part_id: 192,
		quantity: 369,
	},
	{
		garage_id: 26,
		part_id: 193,
		quantity: 423,
	},
	{
		garage_id: 26,
		part_id: 194,
		quantity: 388,
	},
	{
		garage_id: 26,
		part_id: 195,
		quantity: 346,
	},
	{
		garage_id: 26,
		part_id: 196,
		quantity: 19,
	},
	{
		garage_id: 26,
		part_id: 197,
		quantity: 29,
	},
	{
		garage_id: 26,
		part_id: 198,
		quantity: 172,
	},
	{
		garage_id: 26,
		part_id: 199,
		quantity: 360,
	},
	{
		garage_id: 26,
		part_id: 200,
		quantity: 493,
	},
	{
		garage_id: 26,
		part_id: 201,
		quantity: 231,
	},
	{
		garage_id: 26,
		part_id: 202,
		quantity: 259,
	},
	{
		garage_id: 26,
		part_id: 203,
		quantity: 459,
	},
	{
		garage_id: 26,
		part_id: 204,
		quantity: 264,
	},
	{
		garage_id: 26,
		part_id: 205,
		quantity: 120,
	},
	{
		garage_id: 26,
		part_id: 206,
		quantity: 367,
	},
	{
		garage_id: 26,
		part_id: 207,
		quantity: 410,
	},
	{
		garage_id: 26,
		part_id: 208,
		quantity: 345,
	},
	{
		garage_id: 26,
		part_id: 209,
		quantity: 459,
	},
	{
		garage_id: 26,
		part_id: 210,
		quantity: 474,
	},
	{
		garage_id: 26,
		part_id: 211,
		quantity: 284,
	},
	{
		garage_id: 26,
		part_id: 212,
		quantity: 429,
	},
	{
		garage_id: 26,
		part_id: 213,
		quantity: 190,
	},
	{
		garage_id: 26,
		part_id: 214,
		quantity: 19,
	},
	{
		garage_id: 26,
		part_id: 215,
		quantity: 78,
	},
	{
		garage_id: 26,
		part_id: 216,
		quantity: 225,
	},
	{
		garage_id: 26,
		part_id: 217,
		quantity: 154,
	},
	{
		garage_id: 26,
		part_id: 218,
		quantity: 338,
	},
	{
		garage_id: 26,
		part_id: 219,
		quantity: 416,
	},
	{
		garage_id: 26,
		part_id: 220,
		quantity: 2,
	},
	{
		garage_id: 26,
		part_id: 221,
		quantity: 292,
	},
	{
		garage_id: 26,
		part_id: 222,
		quantity: 493,
	},
	{
		garage_id: 26,
		part_id: 223,
		quantity: 348,
	},
	{
		garage_id: 26,
		part_id: 224,
		quantity: 153,
	},
	{
		garage_id: 26,
		part_id: 225,
		quantity: 6,
	},
	{
		garage_id: 26,
		part_id: 226,
		quantity: 277,
	},
	{
		garage_id: 26,
		part_id: 227,
		quantity: 249,
	},
	{
		garage_id: 26,
		part_id: 228,
		quantity: 79,
	},
	{
		garage_id: 26,
		part_id: 229,
		quantity: 29,
	},
	{
		garage_id: 26,
		part_id: 230,
		quantity: 158,
	},
	{
		garage_id: 26,
		part_id: 231,
		quantity: 251,
	},
	{
		garage_id: 26,
		part_id: 232,
		quantity: 384,
	},
	{
		garage_id: 26,
		part_id: 233,
		quantity: 430,
	},
	{
		garage_id: 26,
		part_id: 234,
		quantity: 198,
	},
	{
		garage_id: 26,
		part_id: 235,
		quantity: 367,
	},
	{
		garage_id: 26,
		part_id: 236,
		quantity: 325,
	},
	{
		garage_id: 26,
		part_id: 237,
		quantity: 184,
	},
	{
		garage_id: 26,
		part_id: 238,
		quantity: 409,
	},
	{
		garage_id: 26,
		part_id: 239,
		quantity: 443,
	},
	{
		garage_id: 26,
		part_id: 240,
		quantity: 293,
	},
	{
		garage_id: 26,
		part_id: 241,
		quantity: 9,
	},
	{
		garage_id: 26,
		part_id: 242,
		quantity: 285,
	},
	{
		garage_id: 26,
		part_id: 243,
		quantity: 287,
	},
	{
		garage_id: 26,
		part_id: 244,
		quantity: 382,
	},
	{
		garage_id: 26,
		part_id: 245,
		quantity: 435,
	},
	{
		garage_id: 26,
		part_id: 246,
		quantity: 283,
	},
	{
		garage_id: 26,
		part_id: 247,
		quantity: 91,
	},
	{
		garage_id: 26,
		part_id: 248,
		quantity: 179,
	},
	{
		garage_id: 26,
		part_id: 249,
		quantity: 28,
	},
	{
		garage_id: 26,
		part_id: 250,
		quantity: 221,
	},
	{
		garage_id: 26,
		part_id: 251,
		quantity: 207,
	},
	{
		garage_id: 26,
		part_id: 252,
		quantity: 23,
	},
	{
		garage_id: 26,
		part_id: 253,
		quantity: 394,
	},
	{
		garage_id: 26,
		part_id: 254,
		quantity: 473,
	},
	{
		garage_id: 26,
		part_id: 255,
		quantity: 307,
	},
	{
		garage_id: 26,
		part_id: 256,
		quantity: 219,
	},
	{
		garage_id: 26,
		part_id: 257,
		quantity: 144,
	},
	{
		garage_id: 26,
		part_id: 258,
		quantity: 206,
	},
	{
		garage_id: 26,
		part_id: 259,
		quantity: 434,
	},
	{
		garage_id: 26,
		part_id: 260,
		quantity: 255,
	},
	{
		garage_id: 26,
		part_id: 261,
		quantity: 277,
	},
	{
		garage_id: 26,
		part_id: 262,
		quantity: 446,
	},
	{
		garage_id: 26,
		part_id: 263,
		quantity: 353,
	},
	{
		garage_id: 26,
		part_id: 264,
		quantity: 77,
	},
	{
		garage_id: 26,
		part_id: 265,
		quantity: 204,
	},
	{
		garage_id: 26,
		part_id: 266,
		quantity: 259,
	},
	{
		garage_id: 26,
		part_id: 267,
		quantity: 31,
	},
	{
		garage_id: 26,
		part_id: 268,
		quantity: 104,
	},
	{
		garage_id: 26,
		part_id: 269,
		quantity: 357,
	},
	{
		garage_id: 26,
		part_id: 270,
		quantity: 440,
	},
	{
		garage_id: 26,
		part_id: 271,
		quantity: 253,
	},
	{
		garage_id: 26,
		part_id: 272,
		quantity: 387,
	},
	{
		garage_id: 26,
		part_id: 273,
		quantity: 375,
	},
	{
		garage_id: 26,
		part_id: 274,
		quantity: 435,
	},
	{
		garage_id: 26,
		part_id: 275,
		quantity: 302,
	},
	{
		garage_id: 26,
		part_id: 276,
		quantity: 142,
	},
	{
		garage_id: 26,
		part_id: 277,
		quantity: 436,
	},
	{
		garage_id: 26,
		part_id: 278,
		quantity: 415,
	},
	{
		garage_id: 26,
		part_id: 279,
		quantity: 468,
	},
	{
		garage_id: 26,
		part_id: 280,
		quantity: 51,
	},
	{
		garage_id: 26,
		part_id: 281,
		quantity: 416,
	},
	{
		garage_id: 26,
		part_id: 282,
		quantity: 454,
	},
	{
		garage_id: 26,
		part_id: 283,
		quantity: 227,
	},
	{
		garage_id: 26,
		part_id: 284,
		quantity: 455,
	},
	{
		garage_id: 26,
		part_id: 285,
		quantity: 389,
	},
	{
		garage_id: 26,
		part_id: 286,
		quantity: 132,
	},
	{
		garage_id: 26,
		part_id: 287,
		quantity: 421,
	},
	{
		garage_id: 26,
		part_id: 288,
		quantity: 168,
	},
	{
		garage_id: 26,
		part_id: 289,
		quantity: 186,
	},
	{
		garage_id: 26,
		part_id: 290,
		quantity: 108,
	},
	{
		garage_id: 26,
		part_id: 291,
		quantity: 289,
	},
	{
		garage_id: 26,
		part_id: 292,
		quantity: 104,
	},
	{
		garage_id: 26,
		part_id: 293,
		quantity: 352,
	},
	{
		garage_id: 26,
		part_id: 294,
		quantity: 128,
	},
	{
		garage_id: 26,
		part_id: 295,
		quantity: 187,
	},
	{
		garage_id: 26,
		part_id: 296,
		quantity: 256,
	},
	{
		garage_id: 26,
		part_id: 297,
		quantity: 295,
	},
	{
		garage_id: 26,
		part_id: 298,
		quantity: 495,
	},
	{
		garage_id: 26,
		part_id: 299,
		quantity: 47,
	},
	{
		garage_id: 26,
		part_id: 300,
		quantity: 144,
	},
	{
		garage_id: 26,
		part_id: 301,
		quantity: 117,
	},
	{
		garage_id: 26,
		part_id: 302,
		quantity: 111,
	},
	{
		garage_id: 26,
		part_id: 303,
		quantity: 414,
	},
	{
		garage_id: 26,
		part_id: 304,
		quantity: 238,
	},
	{
		garage_id: 26,
		part_id: 305,
		quantity: 141,
	},
	{
		garage_id: 26,
		part_id: 306,
		quantity: 34,
	},
	{
		garage_id: 26,
		part_id: 307,
		quantity: 449,
	},
	{
		garage_id: 26,
		part_id: 308,
		quantity: 434,
	},
	{
		garage_id: 26,
		part_id: 309,
		quantity: 188,
	},
	{
		garage_id: 26,
		part_id: 310,
		quantity: 328,
	},
	{
		garage_id: 26,
		part_id: 311,
		quantity: 228,
	},
	{
		garage_id: 26,
		part_id: 312,
		quantity: 77,
	},
	{
		garage_id: 26,
		part_id: 313,
		quantity: 271,
	},
	{
		garage_id: 26,
		part_id: 314,
		quantity: 54,
	},
	{
		garage_id: 26,
		part_id: 315,
		quantity: 89,
	},
	{
		garage_id: 26,
		part_id: 316,
		quantity: 72,
	},
	{
		garage_id: 26,
		part_id: 317,
		quantity: 209,
	},
	{
		garage_id: 26,
		part_id: 318,
		quantity: 134,
	},
	{
		garage_id: 26,
		part_id: 319,
		quantity: 235,
	},
	{
		garage_id: 26,
		part_id: 320,
		quantity: 267,
	},
	{
		garage_id: 26,
		part_id: 321,
		quantity: 226,
	},
	{
		garage_id: 26,
		part_id: 322,
		quantity: 157,
	},
	{
		garage_id: 26,
		part_id: 323,
		quantity: 440,
	},
	{
		garage_id: 26,
		part_id: 324,
		quantity: 270,
	},
	{
		garage_id: 26,
		part_id: 325,
		quantity: 462,
	},
	{
		garage_id: 26,
		part_id: 326,
		quantity: 224,
	},
	{
		garage_id: 26,
		part_id: 327,
		quantity: 192,
	},
	{
		garage_id: 26,
		part_id: 328,
		quantity: 97,
	},
	{
		garage_id: 26,
		part_id: 329,
		quantity: 442,
	},
	{
		garage_id: 26,
		part_id: 330,
		quantity: 75,
	},
	{
		garage_id: 26,
		part_id: 331,
		quantity: 452,
	},
	{
		garage_id: 26,
		part_id: 332,
		quantity: 391,
	},
	{
		garage_id: 26,
		part_id: 333,
		quantity: 383,
	},
	{
		garage_id: 26,
		part_id: 334,
		quantity: 312,
	},
	{
		garage_id: 26,
		part_id: 335,
		quantity: 133,
	},
	{
		garage_id: 26,
		part_id: 336,
		quantity: 399,
	},
	{
		garage_id: 26,
		part_id: 337,
		quantity: 486,
	},
	{
		garage_id: 26,
		part_id: 338,
		quantity: 75,
	},
	{
		garage_id: 26,
		part_id: 339,
		quantity: 432,
	},
	{
		garage_id: 26,
		part_id: 340,
		quantity: 375,
	},
	{
		garage_id: 26,
		part_id: 341,
		quantity: 427,
	},
	{
		garage_id: 26,
		part_id: 342,
		quantity: 141,
	},
	{
		garage_id: 26,
		part_id: 343,
		quantity: 250,
	},
	{
		garage_id: 26,
		part_id: 344,
		quantity: 478,
	},
	{
		garage_id: 26,
		part_id: 345,
		quantity: 155,
	},
	{
		garage_id: 26,
		part_id: 346,
		quantity: 409,
	},
	{
		garage_id: 26,
		part_id: 347,
		quantity: 402,
	},
	{
		garage_id: 26,
		part_id: 348,
		quantity: 314,
	},
	{
		garage_id: 26,
		part_id: 349,
		quantity: 123,
	},
	{
		garage_id: 26,
		part_id: 350,
		quantity: 364,
	},
	{
		garage_id: 26,
		part_id: 351,
		quantity: 274,
	},
	{
		garage_id: 26,
		part_id: 352,
		quantity: 287,
	},
	{
		garage_id: 26,
		part_id: 353,
		quantity: 79,
	},
	{
		garage_id: 26,
		part_id: 354,
		quantity: 236,
	},
	{
		garage_id: 26,
		part_id: 355,
		quantity: 493,
	},
	{
		garage_id: 26,
		part_id: 356,
		quantity: 295,
	},
	{
		garage_id: 26,
		part_id: 357,
		quantity: 219,
	},
	{
		garage_id: 26,
		part_id: 358,
		quantity: 98,
	},
	{
		garage_id: 26,
		part_id: 359,
		quantity: 34,
	},
	{
		garage_id: 26,
		part_id: 360,
		quantity: 487,
	},
	{
		garage_id: 26,
		part_id: 361,
		quantity: 0,
	},
	{
		garage_id: 26,
		part_id: 362,
		quantity: 56,
	},
	{
		garage_id: 26,
		part_id: 363,
		quantity: 81,
	},
	{
		garage_id: 26,
		part_id: 364,
		quantity: 115,
	},
	{
		garage_id: 26,
		part_id: 365,
		quantity: 147,
	},
	{
		garage_id: 26,
		part_id: 366,
		quantity: 108,
	},
	{
		garage_id: 26,
		part_id: 367,
		quantity: 391,
	},
	{
		garage_id: 26,
		part_id: 368,
		quantity: 187,
	},
	{
		garage_id: 26,
		part_id: 369,
		quantity: 487,
	},
	{
		garage_id: 26,
		part_id: 370,
		quantity: 156,
	},
	{
		garage_id: 26,
		part_id: 371,
		quantity: 94,
	},
	{
		garage_id: 26,
		part_id: 372,
		quantity: 131,
	},
	{
		garage_id: 26,
		part_id: 373,
		quantity: 456,
	},
	{
		garage_id: 26,
		part_id: 374,
		quantity: 406,
	},
	{
		garage_id: 26,
		part_id: 375,
		quantity: 319,
	},
	{
		garage_id: 26,
		part_id: 376,
		quantity: 179,
	},
	{
		garage_id: 26,
		part_id: 377,
		quantity: 176,
	},
	{
		garage_id: 26,
		part_id: 378,
		quantity: 319,
	},
	{
		garage_id: 26,
		part_id: 379,
		quantity: 77,
	},
	{
		garage_id: 26,
		part_id: 380,
		quantity: 206,
	},
	{
		garage_id: 26,
		part_id: 381,
		quantity: 362,
	},
	{
		garage_id: 26,
		part_id: 382,
		quantity: 330,
	},
	{
		garage_id: 26,
		part_id: 383,
		quantity: 300,
	},
	{
		garage_id: 26,
		part_id: 384,
		quantity: 442,
	},
	{
		garage_id: 26,
		part_id: 385,
		quantity: 154,
	},
	{
		garage_id: 26,
		part_id: 386,
		quantity: 213,
	},
	{
		garage_id: 26,
		part_id: 387,
		quantity: 303,
	},
	{
		garage_id: 26,
		part_id: 388,
		quantity: 134,
	},
	{
		garage_id: 26,
		part_id: 389,
		quantity: 215,
	},
	{
		garage_id: 26,
		part_id: 390,
		quantity: 279,
	},
	{
		garage_id: 26,
		part_id: 391,
		quantity: 127,
	},
	{
		garage_id: 26,
		part_id: 392,
		quantity: 114,
	},
	{
		garage_id: 26,
		part_id: 393,
		quantity: 401,
	},
	{
		garage_id: 26,
		part_id: 394,
		quantity: 298,
	},
	{
		garage_id: 26,
		part_id: 395,
		quantity: 255,
	},
	{
		garage_id: 26,
		part_id: 396,
		quantity: 377,
	},
	{
		garage_id: 26,
		part_id: 397,
		quantity: 56,
	},
	{
		garage_id: 26,
		part_id: 398,
		quantity: 101,
	},
	{
		garage_id: 26,
		part_id: 399,
		quantity: 465,
	},
	{
		garage_id: 27,
		part_id: 0,
		quantity: 52,
	},
	{
		garage_id: 27,
		part_id: 1,
		quantity: 32,
	},
	{
		garage_id: 27,
		part_id: 2,
		quantity: 213,
	},
	{
		garage_id: 27,
		part_id: 3,
		quantity: 310,
	},
	{
		garage_id: 27,
		part_id: 4,
		quantity: 48,
	},
	{
		garage_id: 27,
		part_id: 5,
		quantity: 127,
	},
	{
		garage_id: 27,
		part_id: 6,
		quantity: 173,
	},
	{
		garage_id: 27,
		part_id: 7,
		quantity: 285,
	},
	{
		garage_id: 27,
		part_id: 8,
		quantity: 282,
	},
	{
		garage_id: 27,
		part_id: 9,
		quantity: 43,
	},
	{
		garage_id: 27,
		part_id: 10,
		quantity: 475,
	},
	{
		garage_id: 27,
		part_id: 11,
		quantity: 220,
	},
	{
		garage_id: 27,
		part_id: 12,
		quantity: 210,
	},
	{
		garage_id: 27,
		part_id: 13,
		quantity: 177,
	},
	{
		garage_id: 27,
		part_id: 14,
		quantity: 319,
	},
	{
		garage_id: 27,
		part_id: 15,
		quantity: 276,
	},
	{
		garage_id: 27,
		part_id: 16,
		quantity: 45,
	},
	{
		garage_id: 27,
		part_id: 17,
		quantity: 258,
	},
	{
		garage_id: 27,
		part_id: 18,
		quantity: 150,
	},
	{
		garage_id: 27,
		part_id: 19,
		quantity: 233,
	},
	{
		garage_id: 27,
		part_id: 20,
		quantity: 464,
	},
	{
		garage_id: 27,
		part_id: 21,
		quantity: 19,
	},
	{
		garage_id: 27,
		part_id: 22,
		quantity: 251,
	},
	{
		garage_id: 27,
		part_id: 23,
		quantity: 482,
	},
	{
		garage_id: 27,
		part_id: 24,
		quantity: 37,
	},
	{
		garage_id: 27,
		part_id: 25,
		quantity: 24,
	},
	{
		garage_id: 27,
		part_id: 26,
		quantity: 396,
	},
	{
		garage_id: 27,
		part_id: 27,
		quantity: 264,
	},
	{
		garage_id: 27,
		part_id: 28,
		quantity: 188,
	},
	{
		garage_id: 27,
		part_id: 29,
		quantity: 161,
	},
	{
		garage_id: 27,
		part_id: 30,
		quantity: 183,
	},
	{
		garage_id: 27,
		part_id: 31,
		quantity: 429,
	},
	{
		garage_id: 27,
		part_id: 32,
		quantity: 132,
	},
	{
		garage_id: 27,
		part_id: 33,
		quantity: 30,
	},
	{
		garage_id: 27,
		part_id: 34,
		quantity: 452,
	},
	{
		garage_id: 27,
		part_id: 35,
		quantity: 401,
	},
	{
		garage_id: 27,
		part_id: 36,
		quantity: 354,
	},
	{
		garage_id: 27,
		part_id: 37,
		quantity: 343,
	},
	{
		garage_id: 27,
		part_id: 38,
		quantity: 433,
	},
	{
		garage_id: 27,
		part_id: 39,
		quantity: 435,
	},
	{
		garage_id: 27,
		part_id: 40,
		quantity: 237,
	},
	{
		garage_id: 27,
		part_id: 41,
		quantity: 139,
	},
	{
		garage_id: 27,
		part_id: 42,
		quantity: 96,
	},
	{
		garage_id: 27,
		part_id: 43,
		quantity: 442,
	},
	{
		garage_id: 27,
		part_id: 44,
		quantity: 393,
	},
	{
		garage_id: 27,
		part_id: 45,
		quantity: 490,
	},
	{
		garage_id: 27,
		part_id: 46,
		quantity: 149,
	},
	{
		garage_id: 27,
		part_id: 47,
		quantity: 274,
	},
	{
		garage_id: 27,
		part_id: 48,
		quantity: 217,
	},
	{
		garage_id: 27,
		part_id: 49,
		quantity: 235,
	},
	{
		garage_id: 27,
		part_id: 50,
		quantity: 313,
	},
	{
		garage_id: 27,
		part_id: 51,
		quantity: 35,
	},
	{
		garage_id: 27,
		part_id: 52,
		quantity: 285,
	},
	{
		garage_id: 27,
		part_id: 53,
		quantity: 102,
	},
	{
		garage_id: 27,
		part_id: 54,
		quantity: 316,
	},
	{
		garage_id: 27,
		part_id: 55,
		quantity: 202,
	},
	{
		garage_id: 27,
		part_id: 56,
		quantity: 127,
	},
	{
		garage_id: 27,
		part_id: 57,
		quantity: 394,
	},
	{
		garage_id: 27,
		part_id: 58,
		quantity: 334,
	},
	{
		garage_id: 27,
		part_id: 59,
		quantity: 416,
	},
	{
		garage_id: 27,
		part_id: 60,
		quantity: 378,
	},
	{
		garage_id: 27,
		part_id: 61,
		quantity: 129,
	},
	{
		garage_id: 27,
		part_id: 62,
		quantity: 429,
	},
	{
		garage_id: 27,
		part_id: 63,
		quantity: 33,
	},
	{
		garage_id: 27,
		part_id: 64,
		quantity: 437,
	},
	{
		garage_id: 27,
		part_id: 65,
		quantity: 204,
	},
	{
		garage_id: 27,
		part_id: 66,
		quantity: 76,
	},
	{
		garage_id: 27,
		part_id: 67,
		quantity: 431,
	},
	{
		garage_id: 27,
		part_id: 68,
		quantity: 228,
	},
	{
		garage_id: 27,
		part_id: 69,
		quantity: 355,
	},
	{
		garage_id: 27,
		part_id: 70,
		quantity: 194,
	},
	{
		garage_id: 27,
		part_id: 71,
		quantity: 94,
	},
	{
		garage_id: 27,
		part_id: 72,
		quantity: 79,
	},
	{
		garage_id: 27,
		part_id: 73,
		quantity: 198,
	},
	{
		garage_id: 27,
		part_id: 74,
		quantity: 254,
	},
	{
		garage_id: 27,
		part_id: 75,
		quantity: 431,
	},
	{
		garage_id: 27,
		part_id: 76,
		quantity: 478,
	},
	{
		garage_id: 27,
		part_id: 77,
		quantity: 376,
	},
	{
		garage_id: 27,
		part_id: 78,
		quantity: 296,
	},
	{
		garage_id: 27,
		part_id: 79,
		quantity: 314,
	},
	{
		garage_id: 27,
		part_id: 80,
		quantity: 36,
	},
	{
		garage_id: 27,
		part_id: 81,
		quantity: 475,
	},
	{
		garage_id: 27,
		part_id: 82,
		quantity: 444,
	},
	{
		garage_id: 27,
		part_id: 83,
		quantity: 55,
	},
	{
		garage_id: 27,
		part_id: 84,
		quantity: 326,
	},
	{
		garage_id: 27,
		part_id: 85,
		quantity: 449,
	},
	{
		garage_id: 27,
		part_id: 86,
		quantity: 203,
	},
	{
		garage_id: 27,
		part_id: 87,
		quantity: 424,
	},
	{
		garage_id: 27,
		part_id: 88,
		quantity: 483,
	},
	{
		garage_id: 27,
		part_id: 89,
		quantity: 213,
	},
	{
		garage_id: 27,
		part_id: 90,
		quantity: 44,
	},
	{
		garage_id: 27,
		part_id: 91,
		quantity: 500,
	},
	{
		garage_id: 27,
		part_id: 92,
		quantity: 258,
	},
	{
		garage_id: 27,
		part_id: 93,
		quantity: 341,
	},
	{
		garage_id: 27,
		part_id: 94,
		quantity: 293,
	},
	{
		garage_id: 27,
		part_id: 95,
		quantity: 476,
	},
	{
		garage_id: 27,
		part_id: 96,
		quantity: 276,
	},
	{
		garage_id: 27,
		part_id: 97,
		quantity: 120,
	},
	{
		garage_id: 27,
		part_id: 98,
		quantity: 493,
	},
	{
		garage_id: 27,
		part_id: 99,
		quantity: 350,
	},
	{
		garage_id: 27,
		part_id: 100,
		quantity: 47,
	},
	{
		garage_id: 27,
		part_id: 101,
		quantity: 47,
	},
	{
		garage_id: 27,
		part_id: 102,
		quantity: 225,
	},
	{
		garage_id: 27,
		part_id: 103,
		quantity: 326,
	},
	{
		garage_id: 27,
		part_id: 104,
		quantity: 140,
	},
	{
		garage_id: 27,
		part_id: 105,
		quantity: 54,
	},
	{
		garage_id: 27,
		part_id: 106,
		quantity: 12,
	},
	{
		garage_id: 27,
		part_id: 107,
		quantity: 336,
	},
	{
		garage_id: 27,
		part_id: 108,
		quantity: 469,
	},
	{
		garage_id: 27,
		part_id: 109,
		quantity: 327,
	},
	{
		garage_id: 27,
		part_id: 110,
		quantity: 157,
	},
	{
		garage_id: 27,
		part_id: 111,
		quantity: 448,
	},
	{
		garage_id: 27,
		part_id: 112,
		quantity: 101,
	},
	{
		garage_id: 27,
		part_id: 113,
		quantity: 100,
	},
	{
		garage_id: 27,
		part_id: 114,
		quantity: 398,
	},
	{
		garage_id: 27,
		part_id: 115,
		quantity: 293,
	},
	{
		garage_id: 27,
		part_id: 116,
		quantity: 66,
	},
	{
		garage_id: 27,
		part_id: 117,
		quantity: 209,
	},
	{
		garage_id: 27,
		part_id: 118,
		quantity: 13,
	},
	{
		garage_id: 27,
		part_id: 119,
		quantity: 258,
	},
	{
		garage_id: 27,
		part_id: 120,
		quantity: 208,
	},
	{
		garage_id: 27,
		part_id: 121,
		quantity: 91,
	},
	{
		garage_id: 27,
		part_id: 122,
		quantity: 78,
	},
	{
		garage_id: 27,
		part_id: 123,
		quantity: 213,
	},
	{
		garage_id: 27,
		part_id: 124,
		quantity: 336,
	},
	{
		garage_id: 27,
		part_id: 125,
		quantity: 253,
	},
	{
		garage_id: 27,
		part_id: 126,
		quantity: 277,
	},
	{
		garage_id: 27,
		part_id: 127,
		quantity: 269,
	},
	{
		garage_id: 27,
		part_id: 128,
		quantity: 417,
	},
	{
		garage_id: 27,
		part_id: 129,
		quantity: 244,
	},
	{
		garage_id: 27,
		part_id: 130,
		quantity: 4,
	},
	{
		garage_id: 27,
		part_id: 131,
		quantity: 289,
	},
	{
		garage_id: 27,
		part_id: 132,
		quantity: 12,
	},
	{
		garage_id: 27,
		part_id: 133,
		quantity: 62,
	},
	{
		garage_id: 27,
		part_id: 134,
		quantity: 364,
	},
	{
		garage_id: 27,
		part_id: 135,
		quantity: 354,
	},
	{
		garage_id: 27,
		part_id: 136,
		quantity: 19,
	},
	{
		garage_id: 27,
		part_id: 137,
		quantity: 282,
	},
	{
		garage_id: 27,
		part_id: 138,
		quantity: 41,
	},
	{
		garage_id: 27,
		part_id: 139,
		quantity: 488,
	},
	{
		garage_id: 27,
		part_id: 140,
		quantity: 434,
	},
	{
		garage_id: 27,
		part_id: 141,
		quantity: 198,
	},
	{
		garage_id: 27,
		part_id: 142,
		quantity: 396,
	},
	{
		garage_id: 27,
		part_id: 143,
		quantity: 332,
	},
	{
		garage_id: 27,
		part_id: 144,
		quantity: 126,
	},
	{
		garage_id: 27,
		part_id: 145,
		quantity: 412,
	},
	{
		garage_id: 27,
		part_id: 146,
		quantity: 399,
	},
	{
		garage_id: 27,
		part_id: 147,
		quantity: 219,
	},
	{
		garage_id: 27,
		part_id: 148,
		quantity: 17,
	},
	{
		garage_id: 27,
		part_id: 149,
		quantity: 432,
	},
	{
		garage_id: 27,
		part_id: 150,
		quantity: 448,
	},
	{
		garage_id: 27,
		part_id: 151,
		quantity: 11,
	},
	{
		garage_id: 27,
		part_id: 152,
		quantity: 187,
	},
	{
		garage_id: 27,
		part_id: 153,
		quantity: 89,
	},
	{
		garage_id: 27,
		part_id: 154,
		quantity: 391,
	},
	{
		garage_id: 27,
		part_id: 155,
		quantity: 153,
	},
	{
		garage_id: 27,
		part_id: 156,
		quantity: 323,
	},
	{
		garage_id: 27,
		part_id: 157,
		quantity: 344,
	},
	{
		garage_id: 27,
		part_id: 158,
		quantity: 293,
	},
	{
		garage_id: 27,
		part_id: 159,
		quantity: 449,
	},
	{
		garage_id: 27,
		part_id: 160,
		quantity: 100,
	},
	{
		garage_id: 27,
		part_id: 161,
		quantity: 284,
	},
	{
		garage_id: 27,
		part_id: 162,
		quantity: 101,
	},
	{
		garage_id: 27,
		part_id: 163,
		quantity: 184,
	},
	{
		garage_id: 27,
		part_id: 164,
		quantity: 473,
	},
	{
		garage_id: 27,
		part_id: 165,
		quantity: 24,
	},
	{
		garage_id: 27,
		part_id: 166,
		quantity: 317,
	},
	{
		garage_id: 27,
		part_id: 167,
		quantity: 0,
	},
	{
		garage_id: 27,
		part_id: 168,
		quantity: 106,
	},
	{
		garage_id: 27,
		part_id: 169,
		quantity: 256,
	},
	{
		garage_id: 27,
		part_id: 170,
		quantity: 87,
	},
	{
		garage_id: 27,
		part_id: 171,
		quantity: 46,
	},
	{
		garage_id: 27,
		part_id: 172,
		quantity: 454,
	},
	{
		garage_id: 27,
		part_id: 173,
		quantity: 184,
	},
	{
		garage_id: 27,
		part_id: 174,
		quantity: 438,
	},
	{
		garage_id: 27,
		part_id: 175,
		quantity: 271,
	},
	{
		garage_id: 27,
		part_id: 176,
		quantity: 376,
	},
	{
		garage_id: 27,
		part_id: 177,
		quantity: 216,
	},
	{
		garage_id: 27,
		part_id: 178,
		quantity: 86,
	},
	{
		garage_id: 27,
		part_id: 179,
		quantity: 343,
	},
	{
		garage_id: 27,
		part_id: 180,
		quantity: 171,
	},
	{
		garage_id: 27,
		part_id: 181,
		quantity: 52,
	},
	{
		garage_id: 27,
		part_id: 182,
		quantity: 30,
	},
	{
		garage_id: 27,
		part_id: 183,
		quantity: 404,
	},
	{
		garage_id: 27,
		part_id: 184,
		quantity: 60,
	},
	{
		garage_id: 27,
		part_id: 185,
		quantity: 469,
	},
	{
		garage_id: 27,
		part_id: 186,
		quantity: 472,
	},
	{
		garage_id: 27,
		part_id: 187,
		quantity: 472,
	},
	{
		garage_id: 27,
		part_id: 188,
		quantity: 117,
	},
	{
		garage_id: 27,
		part_id: 189,
		quantity: 85,
	},
	{
		garage_id: 27,
		part_id: 190,
		quantity: 48,
	},
	{
		garage_id: 27,
		part_id: 191,
		quantity: 454,
	},
	{
		garage_id: 27,
		part_id: 192,
		quantity: 464,
	},
	{
		garage_id: 27,
		part_id: 193,
		quantity: 347,
	},
	{
		garage_id: 27,
		part_id: 194,
		quantity: 457,
	},
	{
		garage_id: 27,
		part_id: 195,
		quantity: 369,
	},
	{
		garage_id: 27,
		part_id: 196,
		quantity: 469,
	},
	{
		garage_id: 27,
		part_id: 197,
		quantity: 181,
	},
	{
		garage_id: 27,
		part_id: 198,
		quantity: 165,
	},
	{
		garage_id: 27,
		part_id: 199,
		quantity: 242,
	},
	{
		garage_id: 27,
		part_id: 200,
		quantity: 412,
	},
	{
		garage_id: 27,
		part_id: 201,
		quantity: 321,
	},
	{
		garage_id: 27,
		part_id: 202,
		quantity: 488,
	},
	{
		garage_id: 27,
		part_id: 203,
		quantity: 100,
	},
	{
		garage_id: 27,
		part_id: 204,
		quantity: 66,
	},
	{
		garage_id: 27,
		part_id: 205,
		quantity: 403,
	},
	{
		garage_id: 27,
		part_id: 206,
		quantity: 242,
	},
	{
		garage_id: 27,
		part_id: 207,
		quantity: 307,
	},
	{
		garage_id: 27,
		part_id: 208,
		quantity: 432,
	},
	{
		garage_id: 27,
		part_id: 209,
		quantity: 22,
	},
	{
		garage_id: 27,
		part_id: 210,
		quantity: 355,
	},
	{
		garage_id: 27,
		part_id: 211,
		quantity: 323,
	},
	{
		garage_id: 27,
		part_id: 212,
		quantity: 241,
	},
	{
		garage_id: 27,
		part_id: 213,
		quantity: 27,
	},
	{
		garage_id: 27,
		part_id: 214,
		quantity: 391,
	},
	{
		garage_id: 27,
		part_id: 215,
		quantity: 231,
	},
	{
		garage_id: 27,
		part_id: 216,
		quantity: 48,
	},
	{
		garage_id: 27,
		part_id: 217,
		quantity: 143,
	},
	{
		garage_id: 27,
		part_id: 218,
		quantity: 404,
	},
	{
		garage_id: 27,
		part_id: 219,
		quantity: 168,
	},
	{
		garage_id: 27,
		part_id: 220,
		quantity: 371,
	},
	{
		garage_id: 27,
		part_id: 221,
		quantity: 324,
	},
	{
		garage_id: 27,
		part_id: 222,
		quantity: 472,
	},
	{
		garage_id: 27,
		part_id: 223,
		quantity: 479,
	},
	{
		garage_id: 27,
		part_id: 224,
		quantity: 271,
	},
	{
		garage_id: 27,
		part_id: 225,
		quantity: 394,
	},
	{
		garage_id: 27,
		part_id: 226,
		quantity: 69,
	},
	{
		garage_id: 27,
		part_id: 227,
		quantity: 90,
	},
	{
		garage_id: 27,
		part_id: 228,
		quantity: 106,
	},
	{
		garage_id: 27,
		part_id: 229,
		quantity: 109,
	},
	{
		garage_id: 27,
		part_id: 230,
		quantity: 240,
	},
	{
		garage_id: 27,
		part_id: 231,
		quantity: 21,
	},
	{
		garage_id: 27,
		part_id: 232,
		quantity: 106,
	},
	{
		garage_id: 27,
		part_id: 233,
		quantity: 470,
	},
	{
		garage_id: 27,
		part_id: 234,
		quantity: 483,
	},
	{
		garage_id: 27,
		part_id: 235,
		quantity: 303,
	},
	{
		garage_id: 27,
		part_id: 236,
		quantity: 13,
	},
	{
		garage_id: 27,
		part_id: 237,
		quantity: 307,
	},
	{
		garage_id: 27,
		part_id: 238,
		quantity: 186,
	},
	{
		garage_id: 27,
		part_id: 239,
		quantity: 30,
	},
	{
		garage_id: 27,
		part_id: 240,
		quantity: 361,
	},
	{
		garage_id: 27,
		part_id: 241,
		quantity: 439,
	},
	{
		garage_id: 27,
		part_id: 242,
		quantity: 276,
	},
	{
		garage_id: 27,
		part_id: 243,
		quantity: 1,
	},
	{
		garage_id: 27,
		part_id: 244,
		quantity: 458,
	},
	{
		garage_id: 27,
		part_id: 245,
		quantity: 408,
	},
	{
		garage_id: 27,
		part_id: 246,
		quantity: 349,
	},
	{
		garage_id: 27,
		part_id: 247,
		quantity: 249,
	},
	{
		garage_id: 27,
		part_id: 248,
		quantity: 417,
	},
	{
		garage_id: 27,
		part_id: 249,
		quantity: 411,
	},
	{
		garage_id: 27,
		part_id: 250,
		quantity: 53,
	},
	{
		garage_id: 27,
		part_id: 251,
		quantity: 437,
	},
	{
		garage_id: 27,
		part_id: 252,
		quantity: 348,
	},
	{
		garage_id: 27,
		part_id: 253,
		quantity: 5,
	},
	{
		garage_id: 27,
		part_id: 254,
		quantity: 60,
	},
	{
		garage_id: 27,
		part_id: 255,
		quantity: 422,
	},
	{
		garage_id: 27,
		part_id: 256,
		quantity: 181,
	},
	{
		garage_id: 27,
		part_id: 257,
		quantity: 311,
	},
	{
		garage_id: 27,
		part_id: 258,
		quantity: 169,
	},
	{
		garage_id: 27,
		part_id: 259,
		quantity: 164,
	},
	{
		garage_id: 27,
		part_id: 260,
		quantity: 314,
	},
	{
		garage_id: 27,
		part_id: 261,
		quantity: 50,
	},
	{
		garage_id: 27,
		part_id: 262,
		quantity: 297,
	},
	{
		garage_id: 27,
		part_id: 263,
		quantity: 281,
	},
	{
		garage_id: 27,
		part_id: 264,
		quantity: 392,
	},
	{
		garage_id: 27,
		part_id: 265,
		quantity: 456,
	},
	{
		garage_id: 27,
		part_id: 266,
		quantity: 167,
	},
	{
		garage_id: 27,
		part_id: 267,
		quantity: 289,
	},
	{
		garage_id: 27,
		part_id: 268,
		quantity: 241,
	},
	{
		garage_id: 27,
		part_id: 269,
		quantity: 29,
	},
	{
		garage_id: 27,
		part_id: 270,
		quantity: 365,
	},
	{
		garage_id: 27,
		part_id: 271,
		quantity: 413,
	},
	{
		garage_id: 27,
		part_id: 272,
		quantity: 109,
	},
	{
		garage_id: 27,
		part_id: 273,
		quantity: 239,
	},
	{
		garage_id: 27,
		part_id: 274,
		quantity: 81,
	},
	{
		garage_id: 27,
		part_id: 275,
		quantity: 62,
	},
	{
		garage_id: 27,
		part_id: 276,
		quantity: 332,
	},
	{
		garage_id: 27,
		part_id: 277,
		quantity: 329,
	},
	{
		garage_id: 27,
		part_id: 278,
		quantity: 369,
	},
	{
		garage_id: 27,
		part_id: 279,
		quantity: 139,
	},
	{
		garage_id: 27,
		part_id: 280,
		quantity: 4,
	},
	{
		garage_id: 27,
		part_id: 281,
		quantity: 265,
	},
	{
		garage_id: 27,
		part_id: 282,
		quantity: 428,
	},
	{
		garage_id: 27,
		part_id: 283,
		quantity: 71,
	},
	{
		garage_id: 27,
		part_id: 284,
		quantity: 414,
	},
	{
		garage_id: 27,
		part_id: 285,
		quantity: 312,
	},
	{
		garage_id: 27,
		part_id: 286,
		quantity: 183,
	},
	{
		garage_id: 27,
		part_id: 287,
		quantity: 337,
	},
	{
		garage_id: 27,
		part_id: 288,
		quantity: 307,
	},
	{
		garage_id: 27,
		part_id: 289,
		quantity: 479,
	},
	{
		garage_id: 27,
		part_id: 290,
		quantity: 145,
	},
	{
		garage_id: 27,
		part_id: 291,
		quantity: 495,
	},
	{
		garage_id: 27,
		part_id: 292,
		quantity: 215,
	},
	{
		garage_id: 27,
		part_id: 293,
		quantity: 6,
	},
	{
		garage_id: 27,
		part_id: 294,
		quantity: 199,
	},
	{
		garage_id: 27,
		part_id: 295,
		quantity: 388,
	},
	{
		garage_id: 27,
		part_id: 296,
		quantity: 438,
	},
	{
		garage_id: 27,
		part_id: 297,
		quantity: 144,
	},
	{
		garage_id: 27,
		part_id: 298,
		quantity: 470,
	},
	{
		garage_id: 27,
		part_id: 299,
		quantity: 59,
	},
	{
		garage_id: 27,
		part_id: 300,
		quantity: 298,
	},
	{
		garage_id: 27,
		part_id: 301,
		quantity: 473,
	},
	{
		garage_id: 27,
		part_id: 302,
		quantity: 379,
	},
	{
		garage_id: 27,
		part_id: 303,
		quantity: 378,
	},
	{
		garage_id: 27,
		part_id: 304,
		quantity: 123,
	},
	{
		garage_id: 27,
		part_id: 305,
		quantity: 450,
	},
	{
		garage_id: 27,
		part_id: 306,
		quantity: 112,
	},
	{
		garage_id: 27,
		part_id: 307,
		quantity: 467,
	},
	{
		garage_id: 27,
		part_id: 308,
		quantity: 394,
	},
	{
		garage_id: 27,
		part_id: 309,
		quantity: 485,
	},
	{
		garage_id: 27,
		part_id: 310,
		quantity: 229,
	},
	{
		garage_id: 27,
		part_id: 311,
		quantity: 381,
	},
	{
		garage_id: 27,
		part_id: 312,
		quantity: 171,
	},
	{
		garage_id: 27,
		part_id: 313,
		quantity: 171,
	},
	{
		garage_id: 27,
		part_id: 314,
		quantity: 82,
	},
	{
		garage_id: 27,
		part_id: 315,
		quantity: 32,
	},
	{
		garage_id: 27,
		part_id: 316,
		quantity: 184,
	},
	{
		garage_id: 27,
		part_id: 317,
		quantity: 190,
	},
	{
		garage_id: 27,
		part_id: 318,
		quantity: 28,
	},
	{
		garage_id: 27,
		part_id: 319,
		quantity: 415,
	},
	{
		garage_id: 27,
		part_id: 320,
		quantity: 333,
	},
	{
		garage_id: 27,
		part_id: 321,
		quantity: 497,
	},
	{
		garage_id: 27,
		part_id: 322,
		quantity: 87,
	},
	{
		garage_id: 27,
		part_id: 323,
		quantity: 225,
	},
	{
		garage_id: 27,
		part_id: 324,
		quantity: 231,
	},
	{
		garage_id: 27,
		part_id: 325,
		quantity: 57,
	},
	{
		garage_id: 27,
		part_id: 326,
		quantity: 96,
	},
	{
		garage_id: 27,
		part_id: 327,
		quantity: 199,
	},
	{
		garage_id: 27,
		part_id: 328,
		quantity: 473,
	},
	{
		garage_id: 27,
		part_id: 329,
		quantity: 313,
	},
	{
		garage_id: 27,
		part_id: 330,
		quantity: 328,
	},
	{
		garage_id: 27,
		part_id: 331,
		quantity: 343,
	},
	{
		garage_id: 27,
		part_id: 332,
		quantity: 245,
	},
	{
		garage_id: 27,
		part_id: 333,
		quantity: 33,
	},
	{
		garage_id: 27,
		part_id: 334,
		quantity: 292,
	},
	{
		garage_id: 27,
		part_id: 335,
		quantity: 422,
	},
	{
		garage_id: 27,
		part_id: 336,
		quantity: 417,
	},
	{
		garage_id: 27,
		part_id: 337,
		quantity: 258,
	},
	{
		garage_id: 27,
		part_id: 338,
		quantity: 139,
	},
	{
		garage_id: 27,
		part_id: 339,
		quantity: 310,
	},
	{
		garage_id: 27,
		part_id: 340,
		quantity: 163,
	},
	{
		garage_id: 27,
		part_id: 341,
		quantity: 37,
	},
	{
		garage_id: 27,
		part_id: 342,
		quantity: 108,
	},
	{
		garage_id: 27,
		part_id: 343,
		quantity: 484,
	},
	{
		garage_id: 27,
		part_id: 344,
		quantity: 139,
	},
	{
		garage_id: 27,
		part_id: 345,
		quantity: 47,
	},
	{
		garage_id: 27,
		part_id: 346,
		quantity: 113,
	},
	{
		garage_id: 27,
		part_id: 347,
		quantity: 16,
	},
	{
		garage_id: 27,
		part_id: 348,
		quantity: 223,
	},
	{
		garage_id: 27,
		part_id: 349,
		quantity: 110,
	},
	{
		garage_id: 27,
		part_id: 350,
		quantity: 110,
	},
	{
		garage_id: 27,
		part_id: 351,
		quantity: 206,
	},
	{
		garage_id: 27,
		part_id: 352,
		quantity: 394,
	},
	{
		garage_id: 27,
		part_id: 353,
		quantity: 431,
	},
	{
		garage_id: 27,
		part_id: 354,
		quantity: 414,
	},
	{
		garage_id: 27,
		part_id: 355,
		quantity: 277,
	},
	{
		garage_id: 27,
		part_id: 356,
		quantity: 257,
	},
	{
		garage_id: 27,
		part_id: 357,
		quantity: 460,
	},
	{
		garage_id: 27,
		part_id: 358,
		quantity: 102,
	},
	{
		garage_id: 27,
		part_id: 359,
		quantity: 326,
	},
	{
		garage_id: 27,
		part_id: 360,
		quantity: 318,
	},
	{
		garage_id: 27,
		part_id: 361,
		quantity: 100,
	},
	{
		garage_id: 27,
		part_id: 362,
		quantity: 303,
	},
	{
		garage_id: 27,
		part_id: 363,
		quantity: 352,
	},
	{
		garage_id: 27,
		part_id: 364,
		quantity: 195,
	},
	{
		garage_id: 27,
		part_id: 365,
		quantity: 341,
	},
	{
		garage_id: 27,
		part_id: 366,
		quantity: 119,
	},
	{
		garage_id: 27,
		part_id: 367,
		quantity: 392,
	},
	{
		garage_id: 27,
		part_id: 368,
		quantity: 4,
	},
	{
		garage_id: 27,
		part_id: 369,
		quantity: 392,
	},
	{
		garage_id: 27,
		part_id: 370,
		quantity: 437,
	},
	{
		garage_id: 27,
		part_id: 371,
		quantity: 130,
	},
	{
		garage_id: 27,
		part_id: 372,
		quantity: 135,
	},
	{
		garage_id: 27,
		part_id: 373,
		quantity: 355,
	},
	{
		garage_id: 27,
		part_id: 374,
		quantity: 192,
	},
	{
		garage_id: 27,
		part_id: 375,
		quantity: 33,
	},
	{
		garage_id: 27,
		part_id: 376,
		quantity: 189,
	},
	{
		garage_id: 27,
		part_id: 377,
		quantity: 190,
	},
	{
		garage_id: 27,
		part_id: 378,
		quantity: 320,
	},
	{
		garage_id: 27,
		part_id: 379,
		quantity: 193,
	},
	{
		garage_id: 27,
		part_id: 380,
		quantity: 110,
	},
	{
		garage_id: 27,
		part_id: 381,
		quantity: 254,
	},
	{
		garage_id: 27,
		part_id: 382,
		quantity: 372,
	},
	{
		garage_id: 27,
		part_id: 383,
		quantity: 29,
	},
	{
		garage_id: 27,
		part_id: 384,
		quantity: 85,
	},
	{
		garage_id: 27,
		part_id: 385,
		quantity: 235,
	},
	{
		garage_id: 27,
		part_id: 386,
		quantity: 255,
	},
	{
		garage_id: 27,
		part_id: 387,
		quantity: 20,
	},
	{
		garage_id: 27,
		part_id: 388,
		quantity: 81,
	},
	{
		garage_id: 27,
		part_id: 389,
		quantity: 166,
	},
	{
		garage_id: 27,
		part_id: 390,
		quantity: 127,
	},
	{
		garage_id: 27,
		part_id: 391,
		quantity: 256,
	},
	{
		garage_id: 27,
		part_id: 392,
		quantity: 469,
	},
	{
		garage_id: 27,
		part_id: 393,
		quantity: 48,
	},
	{
		garage_id: 27,
		part_id: 394,
		quantity: 441,
	},
	{
		garage_id: 27,
		part_id: 395,
		quantity: 178,
	},
	{
		garage_id: 27,
		part_id: 396,
		quantity: 81,
	},
	{
		garage_id: 27,
		part_id: 397,
		quantity: 272,
	},
	{
		garage_id: 27,
		part_id: 398,
		quantity: 399,
	},
	{
		garage_id: 27,
		part_id: 399,
		quantity: 291,
	},
	{
		garage_id: 28,
		part_id: 0,
		quantity: 234,
	},
	{
		garage_id: 28,
		part_id: 1,
		quantity: 217,
	},
	{
		garage_id: 28,
		part_id: 2,
		quantity: 53,
	},
	{
		garage_id: 28,
		part_id: 3,
		quantity: 487,
	},
	{
		garage_id: 28,
		part_id: 4,
		quantity: 264,
	},
	{
		garage_id: 28,
		part_id: 5,
		quantity: 141,
	},
	{
		garage_id: 28,
		part_id: 6,
		quantity: 243,
	},
	{
		garage_id: 28,
		part_id: 7,
		quantity: 452,
	},
	{
		garage_id: 28,
		part_id: 8,
		quantity: 385,
	},
	{
		garage_id: 28,
		part_id: 9,
		quantity: 149,
	},
	{
		garage_id: 28,
		part_id: 10,
		quantity: 193,
	},
	{
		garage_id: 28,
		part_id: 11,
		quantity: 41,
	},
	{
		garage_id: 28,
		part_id: 12,
		quantity: 373,
	},
	{
		garage_id: 28,
		part_id: 13,
		quantity: 441,
	},
	{
		garage_id: 28,
		part_id: 14,
		quantity: 89,
	},
	{
		garage_id: 28,
		part_id: 15,
		quantity: 224,
	},
	{
		garage_id: 28,
		part_id: 16,
		quantity: 170,
	},
	{
		garage_id: 28,
		part_id: 17,
		quantity: 3,
	},
	{
		garage_id: 28,
		part_id: 18,
		quantity: 173,
	},
	{
		garage_id: 28,
		part_id: 19,
		quantity: 14,
	},
	{
		garage_id: 28,
		part_id: 20,
		quantity: 403,
	},
	{
		garage_id: 28,
		part_id: 21,
		quantity: 323,
	},
	{
		garage_id: 28,
		part_id: 22,
		quantity: 103,
	},
	{
		garage_id: 28,
		part_id: 23,
		quantity: 420,
	},
	{
		garage_id: 28,
		part_id: 24,
		quantity: 479,
	},
	{
		garage_id: 28,
		part_id: 25,
		quantity: 215,
	},
	{
		garage_id: 28,
		part_id: 26,
		quantity: 483,
	},
	{
		garage_id: 28,
		part_id: 27,
		quantity: 29,
	},
	{
		garage_id: 28,
		part_id: 28,
		quantity: 406,
	},
	{
		garage_id: 28,
		part_id: 29,
		quantity: 483,
	},
	{
		garage_id: 28,
		part_id: 30,
		quantity: 311,
	},
	{
		garage_id: 28,
		part_id: 31,
		quantity: 97,
	},
	{
		garage_id: 28,
		part_id: 32,
		quantity: 198,
	},
	{
		garage_id: 28,
		part_id: 33,
		quantity: 436,
	},
	{
		garage_id: 28,
		part_id: 34,
		quantity: 58,
	},
	{
		garage_id: 28,
		part_id: 35,
		quantity: 87,
	},
	{
		garage_id: 28,
		part_id: 36,
		quantity: 97,
	},
	{
		garage_id: 28,
		part_id: 37,
		quantity: 21,
	},
	{
		garage_id: 28,
		part_id: 38,
		quantity: 429,
	},
	{
		garage_id: 28,
		part_id: 39,
		quantity: 39,
	},
	{
		garage_id: 28,
		part_id: 40,
		quantity: 448,
	},
	{
		garage_id: 28,
		part_id: 41,
		quantity: 486,
	},
	{
		garage_id: 28,
		part_id: 42,
		quantity: 249,
	},
	{
		garage_id: 28,
		part_id: 43,
		quantity: 216,
	},
	{
		garage_id: 28,
		part_id: 44,
		quantity: 184,
	},
	{
		garage_id: 28,
		part_id: 45,
		quantity: 16,
	},
	{
		garage_id: 28,
		part_id: 46,
		quantity: 63,
	},
	{
		garage_id: 28,
		part_id: 47,
		quantity: 450,
	},
	{
		garage_id: 28,
		part_id: 48,
		quantity: 408,
	},
	{
		garage_id: 28,
		part_id: 49,
		quantity: 90,
	},
	{
		garage_id: 28,
		part_id: 50,
		quantity: 270,
	},
	{
		garage_id: 28,
		part_id: 51,
		quantity: 324,
	},
	{
		garage_id: 28,
		part_id: 52,
		quantity: 24,
	},
	{
		garage_id: 28,
		part_id: 53,
		quantity: 408,
	},
	{
		garage_id: 28,
		part_id: 54,
		quantity: 325,
	},
	{
		garage_id: 28,
		part_id: 55,
		quantity: 235,
	},
	{
		garage_id: 28,
		part_id: 56,
		quantity: 350,
	},
	{
		garage_id: 28,
		part_id: 57,
		quantity: 358,
	},
	{
		garage_id: 28,
		part_id: 58,
		quantity: 233,
	},
	{
		garage_id: 28,
		part_id: 59,
		quantity: 82,
	},
	{
		garage_id: 28,
		part_id: 60,
		quantity: 46,
	},
	{
		garage_id: 28,
		part_id: 61,
		quantity: 79,
	},
	{
		garage_id: 28,
		part_id: 62,
		quantity: 43,
	},
	{
		garage_id: 28,
		part_id: 63,
		quantity: 73,
	},
	{
		garage_id: 28,
		part_id: 64,
		quantity: 435,
	},
	{
		garage_id: 28,
		part_id: 65,
		quantity: 65,
	},
	{
		garage_id: 28,
		part_id: 66,
		quantity: 240,
	},
	{
		garage_id: 28,
		part_id: 67,
		quantity: 222,
	},
	{
		garage_id: 28,
		part_id: 68,
		quantity: 140,
	},
	{
		garage_id: 28,
		part_id: 69,
		quantity: 77,
	},
	{
		garage_id: 28,
		part_id: 70,
		quantity: 175,
	},
	{
		garage_id: 28,
		part_id: 71,
		quantity: 40,
	},
	{
		garage_id: 28,
		part_id: 72,
		quantity: 89,
	},
	{
		garage_id: 28,
		part_id: 73,
		quantity: 158,
	},
	{
		garage_id: 28,
		part_id: 74,
		quantity: 75,
	},
	{
		garage_id: 28,
		part_id: 75,
		quantity: 218,
	},
	{
		garage_id: 28,
		part_id: 76,
		quantity: 226,
	},
	{
		garage_id: 28,
		part_id: 77,
		quantity: 367,
	},
	{
		garage_id: 28,
		part_id: 78,
		quantity: 125,
	},
	{
		garage_id: 28,
		part_id: 79,
		quantity: 291,
	},
	{
		garage_id: 28,
		part_id: 80,
		quantity: 10,
	},
	{
		garage_id: 28,
		part_id: 81,
		quantity: 349,
	},
	{
		garage_id: 28,
		part_id: 82,
		quantity: 129,
	},
	{
		garage_id: 28,
		part_id: 83,
		quantity: 400,
	},
	{
		garage_id: 28,
		part_id: 84,
		quantity: 56,
	},
	{
		garage_id: 28,
		part_id: 85,
		quantity: 110,
	},
	{
		garage_id: 28,
		part_id: 86,
		quantity: 496,
	},
	{
		garage_id: 28,
		part_id: 87,
		quantity: 330,
	},
	{
		garage_id: 28,
		part_id: 88,
		quantity: 60,
	},
	{
		garage_id: 28,
		part_id: 89,
		quantity: 90,
	},
	{
		garage_id: 28,
		part_id: 90,
		quantity: 69,
	},
	{
		garage_id: 28,
		part_id: 91,
		quantity: 355,
	},
	{
		garage_id: 28,
		part_id: 92,
		quantity: 143,
	},
	{
		garage_id: 28,
		part_id: 93,
		quantity: 202,
	},
	{
		garage_id: 28,
		part_id: 94,
		quantity: 48,
	},
	{
		garage_id: 28,
		part_id: 95,
		quantity: 240,
	},
	{
		garage_id: 28,
		part_id: 96,
		quantity: 147,
	},
	{
		garage_id: 28,
		part_id: 97,
		quantity: 369,
	},
	{
		garage_id: 28,
		part_id: 98,
		quantity: 206,
	},
	{
		garage_id: 28,
		part_id: 99,
		quantity: 373,
	},
	{
		garage_id: 28,
		part_id: 100,
		quantity: 313,
	},
	{
		garage_id: 28,
		part_id: 101,
		quantity: 449,
	},
	{
		garage_id: 28,
		part_id: 102,
		quantity: 370,
	},
	{
		garage_id: 28,
		part_id: 103,
		quantity: 352,
	},
	{
		garage_id: 28,
		part_id: 104,
		quantity: 219,
	},
	{
		garage_id: 28,
		part_id: 105,
		quantity: 243,
	},
	{
		garage_id: 28,
		part_id: 106,
		quantity: 46,
	},
	{
		garage_id: 28,
		part_id: 107,
		quantity: 417,
	},
	{
		garage_id: 28,
		part_id: 108,
		quantity: 427,
	},
	{
		garage_id: 28,
		part_id: 109,
		quantity: 214,
	},
	{
		garage_id: 28,
		part_id: 110,
		quantity: 7,
	},
	{
		garage_id: 28,
		part_id: 111,
		quantity: 105,
	},
	{
		garage_id: 28,
		part_id: 112,
		quantity: 355,
	},
	{
		garage_id: 28,
		part_id: 113,
		quantity: 169,
	},
	{
		garage_id: 28,
		part_id: 114,
		quantity: 218,
	},
	{
		garage_id: 28,
		part_id: 115,
		quantity: 299,
	},
	{
		garage_id: 28,
		part_id: 116,
		quantity: 212,
	},
	{
		garage_id: 28,
		part_id: 117,
		quantity: 207,
	},
	{
		garage_id: 28,
		part_id: 118,
		quantity: 43,
	},
	{
		garage_id: 28,
		part_id: 119,
		quantity: 464,
	},
	{
		garage_id: 28,
		part_id: 120,
		quantity: 370,
	},
	{
		garage_id: 28,
		part_id: 121,
		quantity: 172,
	},
	{
		garage_id: 28,
		part_id: 122,
		quantity: 148,
	},
	{
		garage_id: 28,
		part_id: 123,
		quantity: 203,
	},
	{
		garage_id: 28,
		part_id: 124,
		quantity: 66,
	},
	{
		garage_id: 28,
		part_id: 125,
		quantity: 84,
	},
	{
		garage_id: 28,
		part_id: 126,
		quantity: 264,
	},
	{
		garage_id: 28,
		part_id: 127,
		quantity: 273,
	},
	{
		garage_id: 28,
		part_id: 128,
		quantity: 172,
	},
	{
		garage_id: 28,
		part_id: 129,
		quantity: 352,
	},
	{
		garage_id: 28,
		part_id: 130,
		quantity: 411,
	},
	{
		garage_id: 28,
		part_id: 131,
		quantity: 62,
	},
	{
		garage_id: 28,
		part_id: 132,
		quantity: 157,
	},
	{
		garage_id: 28,
		part_id: 133,
		quantity: 459,
	},
	{
		garage_id: 28,
		part_id: 134,
		quantity: 396,
	},
	{
		garage_id: 28,
		part_id: 135,
		quantity: 423,
	},
	{
		garage_id: 28,
		part_id: 136,
		quantity: 48,
	},
	{
		garage_id: 28,
		part_id: 137,
		quantity: 487,
	},
	{
		garage_id: 28,
		part_id: 138,
		quantity: 448,
	},
	{
		garage_id: 28,
		part_id: 139,
		quantity: 85,
	},
	{
		garage_id: 28,
		part_id: 140,
		quantity: 310,
	},
	{
		garage_id: 28,
		part_id: 141,
		quantity: 380,
	},
	{
		garage_id: 28,
		part_id: 142,
		quantity: 79,
	},
	{
		garage_id: 28,
		part_id: 143,
		quantity: 236,
	},
	{
		garage_id: 28,
		part_id: 144,
		quantity: 413,
	},
	{
		garage_id: 28,
		part_id: 145,
		quantity: 186,
	},
	{
		garage_id: 28,
		part_id: 146,
		quantity: 194,
	},
	{
		garage_id: 28,
		part_id: 147,
		quantity: 190,
	},
	{
		garage_id: 28,
		part_id: 148,
		quantity: 226,
	},
	{
		garage_id: 28,
		part_id: 149,
		quantity: 51,
	},
	{
		garage_id: 28,
		part_id: 150,
		quantity: 419,
	},
	{
		garage_id: 28,
		part_id: 151,
		quantity: 40,
	},
	{
		garage_id: 28,
		part_id: 152,
		quantity: 317,
	},
	{
		garage_id: 28,
		part_id: 153,
		quantity: 418,
	},
	{
		garage_id: 28,
		part_id: 154,
		quantity: 310,
	},
	{
		garage_id: 28,
		part_id: 155,
		quantity: 401,
	},
	{
		garage_id: 28,
		part_id: 156,
		quantity: 180,
	},
	{
		garage_id: 28,
		part_id: 157,
		quantity: 275,
	},
	{
		garage_id: 28,
		part_id: 158,
		quantity: 453,
	},
	{
		garage_id: 28,
		part_id: 159,
		quantity: 234,
	},
	{
		garage_id: 28,
		part_id: 160,
		quantity: 341,
	},
	{
		garage_id: 28,
		part_id: 161,
		quantity: 303,
	},
	{
		garage_id: 28,
		part_id: 162,
		quantity: 24,
	},
	{
		garage_id: 28,
		part_id: 163,
		quantity: 41,
	},
	{
		garage_id: 28,
		part_id: 164,
		quantity: 500,
	},
	{
		garage_id: 28,
		part_id: 165,
		quantity: 104,
	},
	{
		garage_id: 28,
		part_id: 166,
		quantity: 100,
	},
	{
		garage_id: 28,
		part_id: 167,
		quantity: 323,
	},
	{
		garage_id: 28,
		part_id: 168,
		quantity: 178,
	},
	{
		garage_id: 28,
		part_id: 169,
		quantity: 326,
	},
	{
		garage_id: 28,
		part_id: 170,
		quantity: 28,
	},
	{
		garage_id: 28,
		part_id: 171,
		quantity: 205,
	},
	{
		garage_id: 28,
		part_id: 172,
		quantity: 240,
	},
	{
		garage_id: 28,
		part_id: 173,
		quantity: 257,
	},
	{
		garage_id: 28,
		part_id: 174,
		quantity: 356,
	},
	{
		garage_id: 28,
		part_id: 175,
		quantity: 203,
	},
	{
		garage_id: 28,
		part_id: 176,
		quantity: 141,
	},
	{
		garage_id: 28,
		part_id: 177,
		quantity: 350,
	},
	{
		garage_id: 28,
		part_id: 178,
		quantity: 340,
	},
	{
		garage_id: 28,
		part_id: 179,
		quantity: 398,
	},
	{
		garage_id: 28,
		part_id: 180,
		quantity: 392,
	},
	{
		garage_id: 28,
		part_id: 181,
		quantity: 95,
	},
	{
		garage_id: 28,
		part_id: 182,
		quantity: 98,
	},
	{
		garage_id: 28,
		part_id: 183,
		quantity: 399,
	},
	{
		garage_id: 28,
		part_id: 184,
		quantity: 436,
	},
	{
		garage_id: 28,
		part_id: 185,
		quantity: 179,
	},
	{
		garage_id: 28,
		part_id: 186,
		quantity: 315,
	},
	{
		garage_id: 28,
		part_id: 187,
		quantity: 36,
	},
	{
		garage_id: 28,
		part_id: 188,
		quantity: 103,
	},
	{
		garage_id: 28,
		part_id: 189,
		quantity: 366,
	},
	{
		garage_id: 28,
		part_id: 190,
		quantity: 185,
	},
	{
		garage_id: 28,
		part_id: 191,
		quantity: 207,
	},
	{
		garage_id: 28,
		part_id: 192,
		quantity: 226,
	},
	{
		garage_id: 28,
		part_id: 193,
		quantity: 282,
	},
	{
		garage_id: 28,
		part_id: 194,
		quantity: 242,
	},
	{
		garage_id: 28,
		part_id: 195,
		quantity: 463,
	},
	{
		garage_id: 28,
		part_id: 196,
		quantity: 453,
	},
	{
		garage_id: 28,
		part_id: 197,
		quantity: 248,
	},
	{
		garage_id: 28,
		part_id: 198,
		quantity: 36,
	},
	{
		garage_id: 28,
		part_id: 199,
		quantity: 493,
	},
	{
		garage_id: 28,
		part_id: 200,
		quantity: 484,
	},
	{
		garage_id: 28,
		part_id: 201,
		quantity: 155,
	},
	{
		garage_id: 28,
		part_id: 202,
		quantity: 65,
	},
	{
		garage_id: 28,
		part_id: 203,
		quantity: 238,
	},
	{
		garage_id: 28,
		part_id: 204,
		quantity: 307,
	},
	{
		garage_id: 28,
		part_id: 205,
		quantity: 436,
	},
	{
		garage_id: 28,
		part_id: 206,
		quantity: 249,
	},
	{
		garage_id: 28,
		part_id: 207,
		quantity: 113,
	},
	{
		garage_id: 28,
		part_id: 208,
		quantity: 24,
	},
	{
		garage_id: 28,
		part_id: 209,
		quantity: 244,
	},
	{
		garage_id: 28,
		part_id: 210,
		quantity: 33,
	},
	{
		garage_id: 28,
		part_id: 211,
		quantity: 163,
	},
	{
		garage_id: 28,
		part_id: 212,
		quantity: 243,
	},
	{
		garage_id: 28,
		part_id: 213,
		quantity: 138,
	},
	{
		garage_id: 28,
		part_id: 214,
		quantity: 64,
	},
	{
		garage_id: 28,
		part_id: 215,
		quantity: 215,
	},
	{
		garage_id: 28,
		part_id: 216,
		quantity: 306,
	},
	{
		garage_id: 28,
		part_id: 217,
		quantity: 347,
	},
	{
		garage_id: 28,
		part_id: 218,
		quantity: 199,
	},
	{
		garage_id: 28,
		part_id: 219,
		quantity: 308,
	},
	{
		garage_id: 28,
		part_id: 220,
		quantity: 101,
	},
	{
		garage_id: 28,
		part_id: 221,
		quantity: 133,
	},
	{
		garage_id: 28,
		part_id: 222,
		quantity: 153,
	},
	{
		garage_id: 28,
		part_id: 223,
		quantity: 178,
	},
	{
		garage_id: 28,
		part_id: 224,
		quantity: 472,
	},
	{
		garage_id: 28,
		part_id: 225,
		quantity: 336,
	},
	{
		garage_id: 28,
		part_id: 226,
		quantity: 103,
	},
	{
		garage_id: 28,
		part_id: 227,
		quantity: 198,
	},
	{
		garage_id: 28,
		part_id: 228,
		quantity: 374,
	},
	{
		garage_id: 28,
		part_id: 229,
		quantity: 43,
	},
	{
		garage_id: 28,
		part_id: 230,
		quantity: 444,
	},
	{
		garage_id: 28,
		part_id: 231,
		quantity: 14,
	},
	{
		garage_id: 28,
		part_id: 232,
		quantity: 161,
	},
	{
		garage_id: 28,
		part_id: 233,
		quantity: 41,
	},
	{
		garage_id: 28,
		part_id: 234,
		quantity: 99,
	},
	{
		garage_id: 28,
		part_id: 235,
		quantity: 279,
	},
	{
		garage_id: 28,
		part_id: 236,
		quantity: 42,
	},
	{
		garage_id: 28,
		part_id: 237,
		quantity: 14,
	},
	{
		garage_id: 28,
		part_id: 238,
		quantity: 52,
	},
	{
		garage_id: 28,
		part_id: 239,
		quantity: 488,
	},
	{
		garage_id: 28,
		part_id: 240,
		quantity: 171,
	},
	{
		garage_id: 28,
		part_id: 241,
		quantity: 368,
	},
	{
		garage_id: 28,
		part_id: 242,
		quantity: 176,
	},
	{
		garage_id: 28,
		part_id: 243,
		quantity: 451,
	},
	{
		garage_id: 28,
		part_id: 244,
		quantity: 57,
	},
	{
		garage_id: 28,
		part_id: 245,
		quantity: 196,
	},
	{
		garage_id: 28,
		part_id: 246,
		quantity: 441,
	},
	{
		garage_id: 28,
		part_id: 247,
		quantity: 120,
	},
	{
		garage_id: 28,
		part_id: 248,
		quantity: 159,
	},
	{
		garage_id: 28,
		part_id: 249,
		quantity: 38,
	},
	{
		garage_id: 28,
		part_id: 250,
		quantity: 322,
	},
	{
		garage_id: 28,
		part_id: 251,
		quantity: 171,
	},
	{
		garage_id: 28,
		part_id: 252,
		quantity: 176,
	},
	{
		garage_id: 28,
		part_id: 253,
		quantity: 169,
	},
	{
		garage_id: 28,
		part_id: 254,
		quantity: 61,
	},
	{
		garage_id: 28,
		part_id: 255,
		quantity: 65,
	},
	{
		garage_id: 28,
		part_id: 256,
		quantity: 102,
	},
	{
		garage_id: 28,
		part_id: 257,
		quantity: 395,
	},
	{
		garage_id: 28,
		part_id: 258,
		quantity: 147,
	},
	{
		garage_id: 28,
		part_id: 259,
		quantity: 84,
	},
	{
		garage_id: 28,
		part_id: 260,
		quantity: 412,
	},
	{
		garage_id: 28,
		part_id: 261,
		quantity: 93,
	},
	{
		garage_id: 28,
		part_id: 262,
		quantity: 411,
	},
	{
		garage_id: 28,
		part_id: 263,
		quantity: 294,
	},
	{
		garage_id: 28,
		part_id: 264,
		quantity: 405,
	},
	{
		garage_id: 28,
		part_id: 265,
		quantity: 432,
	},
	{
		garage_id: 28,
		part_id: 266,
		quantity: 357,
	},
	{
		garage_id: 28,
		part_id: 267,
		quantity: 215,
	},
	{
		garage_id: 28,
		part_id: 268,
		quantity: 140,
	},
	{
		garage_id: 28,
		part_id: 269,
		quantity: 83,
	},
	{
		garage_id: 28,
		part_id: 270,
		quantity: 276,
	},
	{
		garage_id: 28,
		part_id: 271,
		quantity: 306,
	},
	{
		garage_id: 28,
		part_id: 272,
		quantity: 85,
	},
	{
		garage_id: 28,
		part_id: 273,
		quantity: 60,
	},
	{
		garage_id: 28,
		part_id: 274,
		quantity: 215,
	},
	{
		garage_id: 28,
		part_id: 275,
		quantity: 170,
	},
	{
		garage_id: 28,
		part_id: 276,
		quantity: 34,
	},
	{
		garage_id: 28,
		part_id: 277,
		quantity: 435,
	},
	{
		garage_id: 28,
		part_id: 278,
		quantity: 420,
	},
	{
		garage_id: 28,
		part_id: 279,
		quantity: 219,
	},
	{
		garage_id: 28,
		part_id: 280,
		quantity: 11,
	},
	{
		garage_id: 28,
		part_id: 281,
		quantity: 438,
	},
	{
		garage_id: 28,
		part_id: 282,
		quantity: 175,
	},
	{
		garage_id: 28,
		part_id: 283,
		quantity: 2,
	},
	{
		garage_id: 28,
		part_id: 284,
		quantity: 230,
	},
	{
		garage_id: 28,
		part_id: 285,
		quantity: 419,
	},
	{
		garage_id: 28,
		part_id: 286,
		quantity: 133,
	},
	{
		garage_id: 28,
		part_id: 287,
		quantity: 45,
	},
	{
		garage_id: 28,
		part_id: 288,
		quantity: 367,
	},
	{
		garage_id: 28,
		part_id: 289,
		quantity: 185,
	},
	{
		garage_id: 28,
		part_id: 290,
		quantity: 2,
	},
	{
		garage_id: 28,
		part_id: 291,
		quantity: 236,
	},
	{
		garage_id: 28,
		part_id: 292,
		quantity: 480,
	},
	{
		garage_id: 28,
		part_id: 293,
		quantity: 90,
	},
	{
		garage_id: 28,
		part_id: 294,
		quantity: 115,
	},
	{
		garage_id: 28,
		part_id: 295,
		quantity: 267,
	},
	{
		garage_id: 28,
		part_id: 296,
		quantity: 335,
	},
	{
		garage_id: 28,
		part_id: 297,
		quantity: 454,
	},
	{
		garage_id: 28,
		part_id: 298,
		quantity: 334,
	},
	{
		garage_id: 28,
		part_id: 299,
		quantity: 302,
	},
	{
		garage_id: 28,
		part_id: 300,
		quantity: 173,
	},
	{
		garage_id: 28,
		part_id: 301,
		quantity: 468,
	},
	{
		garage_id: 28,
		part_id: 302,
		quantity: 149,
	},
	{
		garage_id: 28,
		part_id: 303,
		quantity: 84,
	},
	{
		garage_id: 28,
		part_id: 304,
		quantity: 278,
	},
	{
		garage_id: 28,
		part_id: 305,
		quantity: 226,
	},
	{
		garage_id: 28,
		part_id: 306,
		quantity: 167,
	},
	{
		garage_id: 28,
		part_id: 307,
		quantity: 383,
	},
	{
		garage_id: 28,
		part_id: 308,
		quantity: 257,
	},
	{
		garage_id: 28,
		part_id: 309,
		quantity: 71,
	},
	{
		garage_id: 28,
		part_id: 310,
		quantity: 291,
	},
	{
		garage_id: 28,
		part_id: 311,
		quantity: 377,
	},
	{
		garage_id: 28,
		part_id: 312,
		quantity: 497,
	},
	{
		garage_id: 28,
		part_id: 313,
		quantity: 442,
	},
	{
		garage_id: 28,
		part_id: 314,
		quantity: 126,
	},
	{
		garage_id: 28,
		part_id: 315,
		quantity: 377,
	},
	{
		garage_id: 28,
		part_id: 316,
		quantity: 9,
	},
	{
		garage_id: 28,
		part_id: 317,
		quantity: 428,
	},
	{
		garage_id: 28,
		part_id: 318,
		quantity: 350,
	},
	{
		garage_id: 28,
		part_id: 319,
		quantity: 414,
	},
	{
		garage_id: 28,
		part_id: 320,
		quantity: 422,
	},
	{
		garage_id: 28,
		part_id: 321,
		quantity: 354,
	},
	{
		garage_id: 28,
		part_id: 322,
		quantity: 147,
	},
	{
		garage_id: 28,
		part_id: 323,
		quantity: 491,
	},
	{
		garage_id: 28,
		part_id: 324,
		quantity: 354,
	},
	{
		garage_id: 28,
		part_id: 325,
		quantity: 76,
	},
	{
		garage_id: 28,
		part_id: 326,
		quantity: 392,
	},
	{
		garage_id: 28,
		part_id: 327,
		quantity: 329,
	},
	{
		garage_id: 28,
		part_id: 328,
		quantity: 16,
	},
	{
		garage_id: 28,
		part_id: 329,
		quantity: 254,
	},
	{
		garage_id: 28,
		part_id: 330,
		quantity: 466,
	},
	{
		garage_id: 28,
		part_id: 331,
		quantity: 360,
	},
	{
		garage_id: 28,
		part_id: 332,
		quantity: 40,
	},
	{
		garage_id: 28,
		part_id: 333,
		quantity: 6,
	},
	{
		garage_id: 28,
		part_id: 334,
		quantity: 126,
	},
	{
		garage_id: 28,
		part_id: 335,
		quantity: 62,
	},
	{
		garage_id: 28,
		part_id: 336,
		quantity: 475,
	},
	{
		garage_id: 28,
		part_id: 337,
		quantity: 138,
	},
	{
		garage_id: 28,
		part_id: 338,
		quantity: 78,
	},
	{
		garage_id: 28,
		part_id: 339,
		quantity: 63,
	},
	{
		garage_id: 28,
		part_id: 340,
		quantity: 471,
	},
	{
		garage_id: 28,
		part_id: 341,
		quantity: 16,
	},
	{
		garage_id: 28,
		part_id: 342,
		quantity: 196,
	},
	{
		garage_id: 28,
		part_id: 343,
		quantity: 177,
	},
	{
		garage_id: 28,
		part_id: 344,
		quantity: 113,
	},
	{
		garage_id: 28,
		part_id: 345,
		quantity: 396,
	},
	{
		garage_id: 28,
		part_id: 346,
		quantity: 319,
	},
	{
		garage_id: 28,
		part_id: 347,
		quantity: 16,
	},
	{
		garage_id: 28,
		part_id: 348,
		quantity: 118,
	},
	{
		garage_id: 28,
		part_id: 349,
		quantity: 305,
	},
	{
		garage_id: 28,
		part_id: 350,
		quantity: 39,
	},
	{
		garage_id: 28,
		part_id: 351,
		quantity: 166,
	},
	{
		garage_id: 28,
		part_id: 352,
		quantity: 499,
	},
	{
		garage_id: 28,
		part_id: 353,
		quantity: 307,
	},
	{
		garage_id: 28,
		part_id: 354,
		quantity: 168,
	},
	{
		garage_id: 28,
		part_id: 355,
		quantity: 316,
	},
	{
		garage_id: 28,
		part_id: 356,
		quantity: 207,
	},
	{
		garage_id: 28,
		part_id: 357,
		quantity: 424,
	},
	{
		garage_id: 28,
		part_id: 358,
		quantity: 27,
	},
	{
		garage_id: 28,
		part_id: 359,
		quantity: 327,
	},
	{
		garage_id: 28,
		part_id: 360,
		quantity: 122,
	},
	{
		garage_id: 28,
		part_id: 361,
		quantity: 390,
	},
	{
		garage_id: 28,
		part_id: 362,
		quantity: 275,
	},
	{
		garage_id: 28,
		part_id: 363,
		quantity: 144,
	},
	{
		garage_id: 28,
		part_id: 364,
		quantity: 147,
	},
	{
		garage_id: 28,
		part_id: 365,
		quantity: 179,
	},
	{
		garage_id: 28,
		part_id: 366,
		quantity: 250,
	},
	{
		garage_id: 28,
		part_id: 367,
		quantity: 338,
	},
	{
		garage_id: 28,
		part_id: 368,
		quantity: 19,
	},
	{
		garage_id: 28,
		part_id: 369,
		quantity: 446,
	},
	{
		garage_id: 28,
		part_id: 370,
		quantity: 430,
	},
	{
		garage_id: 28,
		part_id: 371,
		quantity: 362,
	},
	{
		garage_id: 28,
		part_id: 372,
		quantity: 160,
	},
	{
		garage_id: 28,
		part_id: 373,
		quantity: 160,
	},
	{
		garage_id: 28,
		part_id: 374,
		quantity: 270,
	},
	{
		garage_id: 28,
		part_id: 375,
		quantity: 338,
	},
	{
		garage_id: 28,
		part_id: 376,
		quantity: 316,
	},
	{
		garage_id: 28,
		part_id: 377,
		quantity: 378,
	},
	{
		garage_id: 28,
		part_id: 378,
		quantity: 323,
	},
	{
		garage_id: 28,
		part_id: 379,
		quantity: 124,
	},
	{
		garage_id: 28,
		part_id: 380,
		quantity: 383,
	},
	{
		garage_id: 28,
		part_id: 381,
		quantity: 180,
	},
	{
		garage_id: 28,
		part_id: 382,
		quantity: 38,
	},
	{
		garage_id: 28,
		part_id: 383,
		quantity: 221,
	},
	{
		garage_id: 28,
		part_id: 384,
		quantity: 236,
	},
	{
		garage_id: 28,
		part_id: 385,
		quantity: 175,
	},
	{
		garage_id: 28,
		part_id: 386,
		quantity: 9,
	},
	{
		garage_id: 28,
		part_id: 387,
		quantity: 24,
	},
	{
		garage_id: 28,
		part_id: 388,
		quantity: 146,
	},
	{
		garage_id: 28,
		part_id: 389,
		quantity: 489,
	},
	{
		garage_id: 28,
		part_id: 390,
		quantity: 461,
	},
	{
		garage_id: 28,
		part_id: 391,
		quantity: 181,
	},
	{
		garage_id: 28,
		part_id: 392,
		quantity: 465,
	},
	{
		garage_id: 28,
		part_id: 393,
		quantity: 209,
	},
	{
		garage_id: 28,
		part_id: 394,
		quantity: 455,
	},
	{
		garage_id: 28,
		part_id: 395,
		quantity: 42,
	},
	{
		garage_id: 28,
		part_id: 396,
		quantity: 194,
	},
	{
		garage_id: 28,
		part_id: 397,
		quantity: 480,
	},
	{
		garage_id: 28,
		part_id: 398,
		quantity: 450,
	},
	{
		garage_id: 28,
		part_id: 399,
		quantity: 192,
	},
	{
		garage_id: 29,
		part_id: 0,
		quantity: 142,
	},
	{
		garage_id: 29,
		part_id: 1,
		quantity: 284,
	},
	{
		garage_id: 29,
		part_id: 2,
		quantity: 235,
	},
	{
		garage_id: 29,
		part_id: 3,
		quantity: 108,
	},
	{
		garage_id: 29,
		part_id: 4,
		quantity: 349,
	},
	{
		garage_id: 29,
		part_id: 5,
		quantity: 137,
	},
	{
		garage_id: 29,
		part_id: 6,
		quantity: 379,
	},
	{
		garage_id: 29,
		part_id: 7,
		quantity: 111,
	},
	{
		garage_id: 29,
		part_id: 8,
		quantity: 69,
	},
	{
		garage_id: 29,
		part_id: 9,
		quantity: 343,
	},
	{
		garage_id: 29,
		part_id: 10,
		quantity: 407,
	},
	{
		garage_id: 29,
		part_id: 11,
		quantity: 151,
	},
	{
		garage_id: 29,
		part_id: 12,
		quantity: 308,
	},
	{
		garage_id: 29,
		part_id: 13,
		quantity: 92,
	},
	{
		garage_id: 29,
		part_id: 14,
		quantity: 366,
	},
	{
		garage_id: 29,
		part_id: 15,
		quantity: 414,
	},
	{
		garage_id: 29,
		part_id: 16,
		quantity: 126,
	},
	{
		garage_id: 29,
		part_id: 17,
		quantity: 489,
	},
	{
		garage_id: 29,
		part_id: 18,
		quantity: 277,
	},
	{
		garage_id: 29,
		part_id: 19,
		quantity: 265,
	},
	{
		garage_id: 29,
		part_id: 20,
		quantity: 444,
	},
	{
		garage_id: 29,
		part_id: 21,
		quantity: 425,
	},
	{
		garage_id: 29,
		part_id: 22,
		quantity: 336,
	},
	{
		garage_id: 29,
		part_id: 23,
		quantity: 376,
	},
	{
		garage_id: 29,
		part_id: 24,
		quantity: 306,
	},
	{
		garage_id: 29,
		part_id: 25,
		quantity: 84,
	},
	{
		garage_id: 29,
		part_id: 26,
		quantity: 446,
	},
	{
		garage_id: 29,
		part_id: 27,
		quantity: 478,
	},
	{
		garage_id: 29,
		part_id: 28,
		quantity: 452,
	},
	{
		garage_id: 29,
		part_id: 29,
		quantity: 183,
	},
	{
		garage_id: 29,
		part_id: 30,
		quantity: 7,
	},
	{
		garage_id: 29,
		part_id: 31,
		quantity: 380,
	},
	{
		garage_id: 29,
		part_id: 32,
		quantity: 9,
	},
	{
		garage_id: 29,
		part_id: 33,
		quantity: 357,
	},
	{
		garage_id: 29,
		part_id: 34,
		quantity: 5,
	},
	{
		garage_id: 29,
		part_id: 35,
		quantity: 442,
	},
	{
		garage_id: 29,
		part_id: 36,
		quantity: 5,
	},
	{
		garage_id: 29,
		part_id: 37,
		quantity: 154,
	},
	{
		garage_id: 29,
		part_id: 38,
		quantity: 209,
	},
	{
		garage_id: 29,
		part_id: 39,
		quantity: 109,
	},
	{
		garage_id: 29,
		part_id: 40,
		quantity: 209,
	},
	{
		garage_id: 29,
		part_id: 41,
		quantity: 352,
	},
	{
		garage_id: 29,
		part_id: 42,
		quantity: 239,
	},
	{
		garage_id: 29,
		part_id: 43,
		quantity: 40,
	},
	{
		garage_id: 29,
		part_id: 44,
		quantity: 351,
	},
	{
		garage_id: 29,
		part_id: 45,
		quantity: 139,
	},
	{
		garage_id: 29,
		part_id: 46,
		quantity: 173,
	},
	{
		garage_id: 29,
		part_id: 47,
		quantity: 439,
	},
	{
		garage_id: 29,
		part_id: 48,
		quantity: 333,
	},
	{
		garage_id: 29,
		part_id: 49,
		quantity: 417,
	},
	{
		garage_id: 29,
		part_id: 50,
		quantity: 253,
	},
	{
		garage_id: 29,
		part_id: 51,
		quantity: 260,
	},
	{
		garage_id: 29,
		part_id: 52,
		quantity: 322,
	},
	{
		garage_id: 29,
		part_id: 53,
		quantity: 296,
	},
	{
		garage_id: 29,
		part_id: 54,
		quantity: 203,
	},
	{
		garage_id: 29,
		part_id: 55,
		quantity: 116,
	},
	{
		garage_id: 29,
		part_id: 56,
		quantity: 81,
	},
	{
		garage_id: 29,
		part_id: 57,
		quantity: 429,
	},
	{
		garage_id: 29,
		part_id: 58,
		quantity: 25,
	},
	{
		garage_id: 29,
		part_id: 59,
		quantity: 218,
	},
	{
		garage_id: 29,
		part_id: 60,
		quantity: 234,
	},
	{
		garage_id: 29,
		part_id: 61,
		quantity: 245,
	},
	{
		garage_id: 29,
		part_id: 62,
		quantity: 49,
	},
	{
		garage_id: 29,
		part_id: 63,
		quantity: 184,
	},
	{
		garage_id: 29,
		part_id: 64,
		quantity: 10,
	},
	{
		garage_id: 29,
		part_id: 65,
		quantity: 140,
	},
	{
		garage_id: 29,
		part_id: 66,
		quantity: 398,
	},
	{
		garage_id: 29,
		part_id: 67,
		quantity: 39,
	},
	{
		garage_id: 29,
		part_id: 68,
		quantity: 213,
	},
	{
		garage_id: 29,
		part_id: 69,
		quantity: 364,
	},
	{
		garage_id: 29,
		part_id: 70,
		quantity: 69,
	},
	{
		garage_id: 29,
		part_id: 71,
		quantity: 202,
	},
	{
		garage_id: 29,
		part_id: 72,
		quantity: 310,
	},
	{
		garage_id: 29,
		part_id: 73,
		quantity: 65,
	},
	{
		garage_id: 29,
		part_id: 74,
		quantity: 464,
	},
	{
		garage_id: 29,
		part_id: 75,
		quantity: 85,
	},
	{
		garage_id: 29,
		part_id: 76,
		quantity: 82,
	},
	{
		garage_id: 29,
		part_id: 77,
		quantity: 365,
	},
	{
		garage_id: 29,
		part_id: 78,
		quantity: 163,
	},
	{
		garage_id: 29,
		part_id: 79,
		quantity: 457,
	},
	{
		garage_id: 29,
		part_id: 80,
		quantity: 166,
	},
	{
		garage_id: 29,
		part_id: 81,
		quantity: 96,
	},
	{
		garage_id: 29,
		part_id: 82,
		quantity: 409,
	},
	{
		garage_id: 29,
		part_id: 83,
		quantity: 312,
	},
	{
		garage_id: 29,
		part_id: 84,
		quantity: 263,
	},
	{
		garage_id: 29,
		part_id: 85,
		quantity: 114,
	},
	{
		garage_id: 29,
		part_id: 86,
		quantity: 445,
	},
	{
		garage_id: 29,
		part_id: 87,
		quantity: 33,
	},
	{
		garage_id: 29,
		part_id: 88,
		quantity: 345,
	},
	{
		garage_id: 29,
		part_id: 89,
		quantity: 410,
	},
	{
		garage_id: 29,
		part_id: 90,
		quantity: 179,
	},
	{
		garage_id: 29,
		part_id: 91,
		quantity: 4,
	},
	{
		garage_id: 29,
		part_id: 92,
		quantity: 486,
	},
	{
		garage_id: 29,
		part_id: 93,
		quantity: 21,
	},
	{
		garage_id: 29,
		part_id: 94,
		quantity: 240,
	},
	{
		garage_id: 29,
		part_id: 95,
		quantity: 388,
	},
	{
		garage_id: 29,
		part_id: 96,
		quantity: 322,
	},
	{
		garage_id: 29,
		part_id: 97,
		quantity: 241,
	},
	{
		garage_id: 29,
		part_id: 98,
		quantity: 360,
	},
	{
		garage_id: 29,
		part_id: 99,
		quantity: 481,
	},
	{
		garage_id: 29,
		part_id: 100,
		quantity: 354,
	},
	{
		garage_id: 29,
		part_id: 101,
		quantity: 373,
	},
	{
		garage_id: 29,
		part_id: 102,
		quantity: 466,
	},
	{
		garage_id: 29,
		part_id: 103,
		quantity: 196,
	},
	{
		garage_id: 29,
		part_id: 104,
		quantity: 109,
	},
	{
		garage_id: 29,
		part_id: 105,
		quantity: 51,
	},
	{
		garage_id: 29,
		part_id: 106,
		quantity: 83,
	},
	{
		garage_id: 29,
		part_id: 107,
		quantity: 13,
	},
	{
		garage_id: 29,
		part_id: 108,
		quantity: 130,
	},
	{
		garage_id: 29,
		part_id: 109,
		quantity: 93,
	},
	{
		garage_id: 29,
		part_id: 110,
		quantity: 194,
	},
	{
		garage_id: 29,
		part_id: 111,
		quantity: 84,
	},
	{
		garage_id: 29,
		part_id: 112,
		quantity: 430,
	},
	{
		garage_id: 29,
		part_id: 113,
		quantity: 270,
	},
	{
		garage_id: 29,
		part_id: 114,
		quantity: 178,
	},
	{
		garage_id: 29,
		part_id: 115,
		quantity: 423,
	},
	{
		garage_id: 29,
		part_id: 116,
		quantity: 233,
	},
	{
		garage_id: 29,
		part_id: 117,
		quantity: 121,
	},
	{
		garage_id: 29,
		part_id: 118,
		quantity: 229,
	},
	{
		garage_id: 29,
		part_id: 119,
		quantity: 162,
	},
	{
		garage_id: 29,
		part_id: 120,
		quantity: 498,
	},
	{
		garage_id: 29,
		part_id: 121,
		quantity: 490,
	},
	{
		garage_id: 29,
		part_id: 122,
		quantity: 200,
	},
	{
		garage_id: 29,
		part_id: 123,
		quantity: 262,
	},
	{
		garage_id: 29,
		part_id: 124,
		quantity: 356,
	},
	{
		garage_id: 29,
		part_id: 125,
		quantity: 138,
	},
	{
		garage_id: 29,
		part_id: 126,
		quantity: 209,
	},
	{
		garage_id: 29,
		part_id: 127,
		quantity: 311,
	},
	{
		garage_id: 29,
		part_id: 128,
		quantity: 457,
	},
	{
		garage_id: 29,
		part_id: 129,
		quantity: 206,
	},
	{
		garage_id: 29,
		part_id: 130,
		quantity: 69,
	},
	{
		garage_id: 29,
		part_id: 131,
		quantity: 183,
	},
	{
		garage_id: 29,
		part_id: 132,
		quantity: 42,
	},
	{
		garage_id: 29,
		part_id: 133,
		quantity: 365,
	},
	{
		garage_id: 29,
		part_id: 134,
		quantity: 95,
	},
	{
		garage_id: 29,
		part_id: 135,
		quantity: 32,
	},
	{
		garage_id: 29,
		part_id: 136,
		quantity: 266,
	},
	{
		garage_id: 29,
		part_id: 137,
		quantity: 329,
	},
	{
		garage_id: 29,
		part_id: 138,
		quantity: 462,
	},
	{
		garage_id: 29,
		part_id: 139,
		quantity: 29,
	},
	{
		garage_id: 29,
		part_id: 140,
		quantity: 74,
	},
	{
		garage_id: 29,
		part_id: 141,
		quantity: 149,
	},
	{
		garage_id: 29,
		part_id: 142,
		quantity: 0,
	},
	{
		garage_id: 29,
		part_id: 143,
		quantity: 160,
	},
	{
		garage_id: 29,
		part_id: 144,
		quantity: 335,
	},
	{
		garage_id: 29,
		part_id: 145,
		quantity: 351,
	},
	{
		garage_id: 29,
		part_id: 146,
		quantity: 39,
	},
	{
		garage_id: 29,
		part_id: 147,
		quantity: 216,
	},
	{
		garage_id: 29,
		part_id: 148,
		quantity: 463,
	},
	{
		garage_id: 29,
		part_id: 149,
		quantity: 81,
	},
	{
		garage_id: 29,
		part_id: 150,
		quantity: 348,
	},
	{
		garage_id: 29,
		part_id: 151,
		quantity: 145,
	},
	{
		garage_id: 29,
		part_id: 152,
		quantity: 450,
	},
	{
		garage_id: 29,
		part_id: 153,
		quantity: 123,
	},
	{
		garage_id: 29,
		part_id: 154,
		quantity: 421,
	},
	{
		garage_id: 29,
		part_id: 155,
		quantity: 75,
	},
	{
		garage_id: 29,
		part_id: 156,
		quantity: 236,
	},
	{
		garage_id: 29,
		part_id: 157,
		quantity: 387,
	},
	{
		garage_id: 29,
		part_id: 158,
		quantity: 51,
	},
	{
		garage_id: 29,
		part_id: 159,
		quantity: 304,
	},
	{
		garage_id: 29,
		part_id: 160,
		quantity: 189,
	},
	{
		garage_id: 29,
		part_id: 161,
		quantity: 310,
	},
	{
		garage_id: 29,
		part_id: 162,
		quantity: 100,
	},
	{
		garage_id: 29,
		part_id: 163,
		quantity: 64,
	},
	{
		garage_id: 29,
		part_id: 164,
		quantity: 280,
	},
	{
		garage_id: 29,
		part_id: 165,
		quantity: 62,
	},
	{
		garage_id: 29,
		part_id: 166,
		quantity: 293,
	},
	{
		garage_id: 29,
		part_id: 167,
		quantity: 12,
	},
	{
		garage_id: 29,
		part_id: 168,
		quantity: 468,
	},
	{
		garage_id: 29,
		part_id: 169,
		quantity: 420,
	},
	{
		garage_id: 29,
		part_id: 170,
		quantity: 424,
	},
	{
		garage_id: 29,
		part_id: 171,
		quantity: 482,
	},
	{
		garage_id: 29,
		part_id: 172,
		quantity: 394,
	},
	{
		garage_id: 29,
		part_id: 173,
		quantity: 494,
	},
	{
		garage_id: 29,
		part_id: 174,
		quantity: 138,
	},
	{
		garage_id: 29,
		part_id: 175,
		quantity: 200,
	},
	{
		garage_id: 29,
		part_id: 176,
		quantity: 210,
	},
	{
		garage_id: 29,
		part_id: 177,
		quantity: 354,
	},
	{
		garage_id: 29,
		part_id: 178,
		quantity: 26,
	},
	{
		garage_id: 29,
		part_id: 179,
		quantity: 459,
	},
	{
		garage_id: 29,
		part_id: 180,
		quantity: 469,
	},
	{
		garage_id: 29,
		part_id: 181,
		quantity: 78,
	},
	{
		garage_id: 29,
		part_id: 182,
		quantity: 382,
	},
	{
		garage_id: 29,
		part_id: 183,
		quantity: 375,
	},
	{
		garage_id: 29,
		part_id: 184,
		quantity: 259,
	},
	{
		garage_id: 29,
		part_id: 185,
		quantity: 363,
	},
	{
		garage_id: 29,
		part_id: 186,
		quantity: 336,
	},
	{
		garage_id: 29,
		part_id: 187,
		quantity: 240,
	},
	{
		garage_id: 29,
		part_id: 188,
		quantity: 297,
	},
	{
		garage_id: 29,
		part_id: 189,
		quantity: 11,
	},
	{
		garage_id: 29,
		part_id: 190,
		quantity: 74,
	},
	{
		garage_id: 29,
		part_id: 191,
		quantity: 117,
	},
	{
		garage_id: 29,
		part_id: 192,
		quantity: 62,
	},
	{
		garage_id: 29,
		part_id: 193,
		quantity: 133,
	},
	{
		garage_id: 29,
		part_id: 194,
		quantity: 293,
	},
	{
		garage_id: 29,
		part_id: 195,
		quantity: 305,
	},
	{
		garage_id: 29,
		part_id: 196,
		quantity: 450,
	},
	{
		garage_id: 29,
		part_id: 197,
		quantity: 188,
	},
	{
		garage_id: 29,
		part_id: 198,
		quantity: 49,
	},
	{
		garage_id: 29,
		part_id: 199,
		quantity: 360,
	},
	{
		garage_id: 29,
		part_id: 200,
		quantity: 236,
	},
	{
		garage_id: 29,
		part_id: 201,
		quantity: 243,
	},
	{
		garage_id: 29,
		part_id: 202,
		quantity: 182,
	},
	{
		garage_id: 29,
		part_id: 203,
		quantity: 489,
	},
	{
		garage_id: 29,
		part_id: 204,
		quantity: 354,
	},
	{
		garage_id: 29,
		part_id: 205,
		quantity: 198,
	},
	{
		garage_id: 29,
		part_id: 206,
		quantity: 471,
	},
	{
		garage_id: 29,
		part_id: 207,
		quantity: 191,
	},
	{
		garage_id: 29,
		part_id: 208,
		quantity: 242,
	},
	{
		garage_id: 29,
		part_id: 209,
		quantity: 367,
	},
	{
		garage_id: 29,
		part_id: 210,
		quantity: 14,
	},
	{
		garage_id: 29,
		part_id: 211,
		quantity: 64,
	},
	{
		garage_id: 29,
		part_id: 212,
		quantity: 122,
	},
	{
		garage_id: 29,
		part_id: 213,
		quantity: 42,
	},
	{
		garage_id: 29,
		part_id: 214,
		quantity: 203,
	},
	{
		garage_id: 29,
		part_id: 215,
		quantity: 80,
	},
	{
		garage_id: 29,
		part_id: 216,
		quantity: 276,
	},
	{
		garage_id: 29,
		part_id: 217,
		quantity: 113,
	},
	{
		garage_id: 29,
		part_id: 218,
		quantity: 345,
	},
	{
		garage_id: 29,
		part_id: 219,
		quantity: 95,
	},
	{
		garage_id: 29,
		part_id: 220,
		quantity: 318,
	},
	{
		garage_id: 29,
		part_id: 221,
		quantity: 273,
	},
	{
		garage_id: 29,
		part_id: 222,
		quantity: 336,
	},
	{
		garage_id: 29,
		part_id: 223,
		quantity: 116,
	},
	{
		garage_id: 29,
		part_id: 224,
		quantity: 54,
	},
	{
		garage_id: 29,
		part_id: 225,
		quantity: 357,
	},
	{
		garage_id: 29,
		part_id: 226,
		quantity: 89,
	},
	{
		garage_id: 29,
		part_id: 227,
		quantity: 434,
	},
	{
		garage_id: 29,
		part_id: 228,
		quantity: 125,
	},
	{
		garage_id: 29,
		part_id: 229,
		quantity: 172,
	},
	{
		garage_id: 29,
		part_id: 230,
		quantity: 396,
	},
	{
		garage_id: 29,
		part_id: 231,
		quantity: 282,
	},
	{
		garage_id: 29,
		part_id: 232,
		quantity: 349,
	},
	{
		garage_id: 29,
		part_id: 233,
		quantity: 458,
	},
	{
		garage_id: 29,
		part_id: 234,
		quantity: 182,
	},
	{
		garage_id: 29,
		part_id: 235,
		quantity: 69,
	},
	{
		garage_id: 29,
		part_id: 236,
		quantity: 392,
	},
	{
		garage_id: 29,
		part_id: 237,
		quantity: 389,
	},
	{
		garage_id: 29,
		part_id: 238,
		quantity: 24,
	},
	{
		garage_id: 29,
		part_id: 239,
		quantity: 442,
	},
	{
		garage_id: 29,
		part_id: 240,
		quantity: 277,
	},
	{
		garage_id: 29,
		part_id: 241,
		quantity: 339,
	},
	{
		garage_id: 29,
		part_id: 242,
		quantity: 34,
	},
	{
		garage_id: 29,
		part_id: 243,
		quantity: 35,
	},
	{
		garage_id: 29,
		part_id: 244,
		quantity: 303,
	},
	{
		garage_id: 29,
		part_id: 245,
		quantity: 236,
	},
	{
		garage_id: 29,
		part_id: 246,
		quantity: 314,
	},
	{
		garage_id: 29,
		part_id: 247,
		quantity: 243,
	},
	{
		garage_id: 29,
		part_id: 248,
		quantity: 412,
	},
	{
		garage_id: 29,
		part_id: 249,
		quantity: 173,
	},
	{
		garage_id: 29,
		part_id: 250,
		quantity: 11,
	},
	{
		garage_id: 29,
		part_id: 251,
		quantity: 251,
	},
	{
		garage_id: 29,
		part_id: 252,
		quantity: 392,
	},
	{
		garage_id: 29,
		part_id: 253,
		quantity: 163,
	},
	{
		garage_id: 29,
		part_id: 254,
		quantity: 281,
	},
	{
		garage_id: 29,
		part_id: 255,
		quantity: 397,
	},
	{
		garage_id: 29,
		part_id: 256,
		quantity: 9,
	},
	{
		garage_id: 29,
		part_id: 257,
		quantity: 259,
	},
	{
		garage_id: 29,
		part_id: 258,
		quantity: 188,
	},
	{
		garage_id: 29,
		part_id: 259,
		quantity: 309,
	},
	{
		garage_id: 29,
		part_id: 260,
		quantity: 350,
	},
	{
		garage_id: 29,
		part_id: 261,
		quantity: 304,
	},
	{
		garage_id: 29,
		part_id: 262,
		quantity: 247,
	},
	{
		garage_id: 29,
		part_id: 263,
		quantity: 497,
	},
	{
		garage_id: 29,
		part_id: 264,
		quantity: 417,
	},
	{
		garage_id: 29,
		part_id: 265,
		quantity: 120,
	},
	{
		garage_id: 29,
		part_id: 266,
		quantity: 187,
	},
	{
		garage_id: 29,
		part_id: 267,
		quantity: 422,
	},
	{
		garage_id: 29,
		part_id: 268,
		quantity: 483,
	},
	{
		garage_id: 29,
		part_id: 269,
		quantity: 286,
	},
	{
		garage_id: 29,
		part_id: 270,
		quantity: 266,
	},
	{
		garage_id: 29,
		part_id: 271,
		quantity: 428,
	},
	{
		garage_id: 29,
		part_id: 272,
		quantity: 463,
	},
	{
		garage_id: 29,
		part_id: 273,
		quantity: 484,
	},
	{
		garage_id: 29,
		part_id: 274,
		quantity: 341,
	},
	{
		garage_id: 29,
		part_id: 275,
		quantity: 71,
	},
	{
		garage_id: 29,
		part_id: 276,
		quantity: 360,
	},
	{
		garage_id: 29,
		part_id: 277,
		quantity: 142,
	},
	{
		garage_id: 29,
		part_id: 278,
		quantity: 327,
	},
	{
		garage_id: 29,
		part_id: 279,
		quantity: 150,
	},
	{
		garage_id: 29,
		part_id: 280,
		quantity: 330,
	},
	{
		garage_id: 29,
		part_id: 281,
		quantity: 55,
	},
	{
		garage_id: 29,
		part_id: 282,
		quantity: 222,
	},
	{
		garage_id: 29,
		part_id: 283,
		quantity: 245,
	},
	{
		garage_id: 29,
		part_id: 284,
		quantity: 381,
	},
	{
		garage_id: 29,
		part_id: 285,
		quantity: 67,
	},
	{
		garage_id: 29,
		part_id: 286,
		quantity: 302,
	},
	{
		garage_id: 29,
		part_id: 287,
		quantity: 328,
	},
	{
		garage_id: 29,
		part_id: 288,
		quantity: 453,
	},
	{
		garage_id: 29,
		part_id: 289,
		quantity: 257,
	},
	{
		garage_id: 29,
		part_id: 290,
		quantity: 352,
	},
	{
		garage_id: 29,
		part_id: 291,
		quantity: 34,
	},
	{
		garage_id: 29,
		part_id: 292,
		quantity: 116,
	},
	{
		garage_id: 29,
		part_id: 293,
		quantity: 372,
	},
	{
		garage_id: 29,
		part_id: 294,
		quantity: 383,
	},
	{
		garage_id: 29,
		part_id: 295,
		quantity: 450,
	},
	{
		garage_id: 29,
		part_id: 296,
		quantity: 316,
	},
	{
		garage_id: 29,
		part_id: 297,
		quantity: 137,
	},
	{
		garage_id: 29,
		part_id: 298,
		quantity: 19,
	},
	{
		garage_id: 29,
		part_id: 299,
		quantity: 468,
	},
	{
		garage_id: 29,
		part_id: 300,
		quantity: 171,
	},
	{
		garage_id: 29,
		part_id: 301,
		quantity: 116,
	},
	{
		garage_id: 29,
		part_id: 302,
		quantity: 180,
	},
	{
		garage_id: 29,
		part_id: 303,
		quantity: 475,
	},
	{
		garage_id: 29,
		part_id: 304,
		quantity: 494,
	},
	{
		garage_id: 29,
		part_id: 305,
		quantity: 236,
	},
	{
		garage_id: 29,
		part_id: 306,
		quantity: 92,
	},
	{
		garage_id: 29,
		part_id: 307,
		quantity: 64,
	},
	{
		garage_id: 29,
		part_id: 308,
		quantity: 165,
	},
	{
		garage_id: 29,
		part_id: 309,
		quantity: 229,
	},
	{
		garage_id: 29,
		part_id: 310,
		quantity: 408,
	},
	{
		garage_id: 29,
		part_id: 311,
		quantity: 147,
	},
	{
		garage_id: 29,
		part_id: 312,
		quantity: 467,
	},
	{
		garage_id: 29,
		part_id: 313,
		quantity: 73,
	},
	{
		garage_id: 29,
		part_id: 314,
		quantity: 102,
	},
	{
		garage_id: 29,
		part_id: 315,
		quantity: 200,
	},
	{
		garage_id: 29,
		part_id: 316,
		quantity: 227,
	},
	{
		garage_id: 29,
		part_id: 317,
		quantity: 186,
	},
	{
		garage_id: 29,
		part_id: 318,
		quantity: 410,
	},
	{
		garage_id: 29,
		part_id: 319,
		quantity: 399,
	},
	{
		garage_id: 29,
		part_id: 320,
		quantity: 353,
	},
	{
		garage_id: 29,
		part_id: 321,
		quantity: 412,
	},
	{
		garage_id: 29,
		part_id: 322,
		quantity: 366,
	},
	{
		garage_id: 29,
		part_id: 323,
		quantity: 488,
	},
	{
		garage_id: 29,
		part_id: 324,
		quantity: 259,
	},
	{
		garage_id: 29,
		part_id: 325,
		quantity: 447,
	},
	{
		garage_id: 29,
		part_id: 326,
		quantity: 336,
	},
	{
		garage_id: 29,
		part_id: 327,
		quantity: 10,
	},
	{
		garage_id: 29,
		part_id: 328,
		quantity: 181,
	},
	{
		garage_id: 29,
		part_id: 329,
		quantity: 163,
	},
	{
		garage_id: 29,
		part_id: 330,
		quantity: 451,
	},
	{
		garage_id: 29,
		part_id: 331,
		quantity: 86,
	},
	{
		garage_id: 29,
		part_id: 332,
		quantity: 18,
	},
	{
		garage_id: 29,
		part_id: 333,
		quantity: 332,
	},
	{
		garage_id: 29,
		part_id: 334,
		quantity: 349,
	},
	{
		garage_id: 29,
		part_id: 335,
		quantity: 474,
	},
	{
		garage_id: 29,
		part_id: 336,
		quantity: 436,
	},
	{
		garage_id: 29,
		part_id: 337,
		quantity: 130,
	},
	{
		garage_id: 29,
		part_id: 338,
		quantity: 377,
	},
	{
		garage_id: 29,
		part_id: 339,
		quantity: 363,
	},
	{
		garage_id: 29,
		part_id: 340,
		quantity: 407,
	},
	{
		garage_id: 29,
		part_id: 341,
		quantity: 49,
	},
	{
		garage_id: 29,
		part_id: 342,
		quantity: 415,
	},
	{
		garage_id: 29,
		part_id: 343,
		quantity: 297,
	},
	{
		garage_id: 29,
		part_id: 344,
		quantity: 402,
	},
	{
		garage_id: 29,
		part_id: 345,
		quantity: 232,
	},
	{
		garage_id: 29,
		part_id: 346,
		quantity: 499,
	},
	{
		garage_id: 29,
		part_id: 347,
		quantity: 297,
	},
	{
		garage_id: 29,
		part_id: 348,
		quantity: 390,
	},
	{
		garage_id: 29,
		part_id: 349,
		quantity: 255,
	},
	{
		garage_id: 29,
		part_id: 350,
		quantity: 162,
	},
	{
		garage_id: 29,
		part_id: 351,
		quantity: 478,
	},
	{
		garage_id: 29,
		part_id: 352,
		quantity: 239,
	},
	{
		garage_id: 29,
		part_id: 353,
		quantity: 214,
	},
	{
		garage_id: 29,
		part_id: 354,
		quantity: 6,
	},
	{
		garage_id: 29,
		part_id: 355,
		quantity: 495,
	},
	{
		garage_id: 29,
		part_id: 356,
		quantity: 56,
	},
	{
		garage_id: 29,
		part_id: 357,
		quantity: 38,
	},
	{
		garage_id: 29,
		part_id: 358,
		quantity: 334,
	},
	{
		garage_id: 29,
		part_id: 359,
		quantity: 4,
	},
	{
		garage_id: 29,
		part_id: 360,
		quantity: 495,
	},
	{
		garage_id: 29,
		part_id: 361,
		quantity: 465,
	},
	{
		garage_id: 29,
		part_id: 362,
		quantity: 476,
	},
	{
		garage_id: 29,
		part_id: 363,
		quantity: 257,
	},
	{
		garage_id: 29,
		part_id: 364,
		quantity: 187,
	},
	{
		garage_id: 29,
		part_id: 365,
		quantity: 76,
	},
	{
		garage_id: 29,
		part_id: 366,
		quantity: 467,
	},
	{
		garage_id: 29,
		part_id: 367,
		quantity: 278,
	},
	{
		garage_id: 29,
		part_id: 368,
		quantity: 35,
	},
	{
		garage_id: 29,
		part_id: 369,
		quantity: 292,
	},
	{
		garage_id: 29,
		part_id: 370,
		quantity: 376,
	},
	{
		garage_id: 29,
		part_id: 371,
		quantity: 405,
	},
	{
		garage_id: 29,
		part_id: 372,
		quantity: 146,
	},
	{
		garage_id: 29,
		part_id: 373,
		quantity: 197,
	},
	{
		garage_id: 29,
		part_id: 374,
		quantity: 325,
	},
	{
		garage_id: 29,
		part_id: 375,
		quantity: 161,
	},
	{
		garage_id: 29,
		part_id: 376,
		quantity: 307,
	},
	{
		garage_id: 29,
		part_id: 377,
		quantity: 157,
	},
	{
		garage_id: 29,
		part_id: 378,
		quantity: 2,
	},
	{
		garage_id: 29,
		part_id: 379,
		quantity: 490,
	},
	{
		garage_id: 29,
		part_id: 380,
		quantity: 459,
	},
	{
		garage_id: 29,
		part_id: 381,
		quantity: 72,
	},
	{
		garage_id: 29,
		part_id: 382,
		quantity: 375,
	},
	{
		garage_id: 29,
		part_id: 383,
		quantity: 33,
	},
	{
		garage_id: 29,
		part_id: 384,
		quantity: 403,
	},
	{
		garage_id: 29,
		part_id: 385,
		quantity: 370,
	},
	{
		garage_id: 29,
		part_id: 386,
		quantity: 194,
	},
	{
		garage_id: 29,
		part_id: 387,
		quantity: 431,
	},
	{
		garage_id: 29,
		part_id: 388,
		quantity: 36,
	},
	{
		garage_id: 29,
		part_id: 389,
		quantity: 141,
	},
	{
		garage_id: 29,
		part_id: 390,
		quantity: 320,
	},
	{
		garage_id: 29,
		part_id: 391,
		quantity: 120,
	},
	{
		garage_id: 29,
		part_id: 392,
		quantity: 420,
	},
	{
		garage_id: 29,
		part_id: 393,
		quantity: 460,
	},
	{
		garage_id: 29,
		part_id: 394,
		quantity: 365,
	},
	{
		garage_id: 29,
		part_id: 395,
		quantity: 241,
	},
	{
		garage_id: 29,
		part_id: 396,
		quantity: 412,
	},
	{
		garage_id: 29,
		part_id: 397,
		quantity: 11,
	},
	{
		garage_id: 29,
		part_id: 398,
		quantity: 480,
	},
	{
		garage_id: 29,
		part_id: 399,
		quantity: 295,
	},
	{
		garage_id: 30,
		part_id: 0,
		quantity: 50,
	},
	{
		garage_id: 30,
		part_id: 1,
		quantity: 486,
	},
	{
		garage_id: 30,
		part_id: 2,
		quantity: 93,
	},
	{
		garage_id: 30,
		part_id: 3,
		quantity: 189,
	},
	{
		garage_id: 30,
		part_id: 4,
		quantity: 197,
	},
	{
		garage_id: 30,
		part_id: 5,
		quantity: 303,
	},
	{
		garage_id: 30,
		part_id: 6,
		quantity: 332,
	},
	{
		garage_id: 30,
		part_id: 7,
		quantity: 188,
	},
	{
		garage_id: 30,
		part_id: 8,
		quantity: 75,
	},
	{
		garage_id: 30,
		part_id: 9,
		quantity: 437,
	},
	{
		garage_id: 30,
		part_id: 10,
		quantity: 160,
	},
	{
		garage_id: 30,
		part_id: 11,
		quantity: 319,
	},
	{
		garage_id: 30,
		part_id: 12,
		quantity: 218,
	},
	{
		garage_id: 30,
		part_id: 13,
		quantity: 109,
	},
	{
		garage_id: 30,
		part_id: 14,
		quantity: 345,
	},
	{
		garage_id: 30,
		part_id: 15,
		quantity: 40,
	},
	{
		garage_id: 30,
		part_id: 16,
		quantity: 252,
	},
	{
		garage_id: 30,
		part_id: 17,
		quantity: 211,
	},
	{
		garage_id: 30,
		part_id: 18,
		quantity: 59,
	},
	{
		garage_id: 30,
		part_id: 19,
		quantity: 403,
	},
	{
		garage_id: 30,
		part_id: 20,
		quantity: 203,
	},
	{
		garage_id: 30,
		part_id: 21,
		quantity: 252,
	},
	{
		garage_id: 30,
		part_id: 22,
		quantity: 158,
	},
	{
		garage_id: 30,
		part_id: 23,
		quantity: 468,
	},
	{
		garage_id: 30,
		part_id: 24,
		quantity: 45,
	},
	{
		garage_id: 30,
		part_id: 25,
		quantity: 383,
	},
	{
		garage_id: 30,
		part_id: 26,
		quantity: 135,
	},
	{
		garage_id: 30,
		part_id: 27,
		quantity: 265,
	},
	{
		garage_id: 30,
		part_id: 28,
		quantity: 470,
	},
	{
		garage_id: 30,
		part_id: 29,
		quantity: 119,
	},
	{
		garage_id: 30,
		part_id: 30,
		quantity: 50,
	},
	{
		garage_id: 30,
		part_id: 31,
		quantity: 171,
	},
	{
		garage_id: 30,
		part_id: 32,
		quantity: 235,
	},
	{
		garage_id: 30,
		part_id: 33,
		quantity: 429,
	},
	{
		garage_id: 30,
		part_id: 34,
		quantity: 258,
	},
	{
		garage_id: 30,
		part_id: 35,
		quantity: 43,
	},
	{
		garage_id: 30,
		part_id: 36,
		quantity: 422,
	},
	{
		garage_id: 30,
		part_id: 37,
		quantity: 366,
	},
	{
		garage_id: 30,
		part_id: 38,
		quantity: 183,
	},
	{
		garage_id: 30,
		part_id: 39,
		quantity: 375,
	},
	{
		garage_id: 30,
		part_id: 40,
		quantity: 495,
	},
	{
		garage_id: 30,
		part_id: 41,
		quantity: 70,
	},
	{
		garage_id: 30,
		part_id: 42,
		quantity: 213,
	},
	{
		garage_id: 30,
		part_id: 43,
		quantity: 30,
	},
	{
		garage_id: 30,
		part_id: 44,
		quantity: 424,
	},
	{
		garage_id: 30,
		part_id: 45,
		quantity: 202,
	},
	{
		garage_id: 30,
		part_id: 46,
		quantity: 159,
	},
	{
		garage_id: 30,
		part_id: 47,
		quantity: 129,
	},
	{
		garage_id: 30,
		part_id: 48,
		quantity: 311,
	},
	{
		garage_id: 30,
		part_id: 49,
		quantity: 17,
	},
	{
		garage_id: 30,
		part_id: 50,
		quantity: 23,
	},
	{
		garage_id: 30,
		part_id: 51,
		quantity: 114,
	},
	{
		garage_id: 30,
		part_id: 52,
		quantity: 404,
	},
	{
		garage_id: 30,
		part_id: 53,
		quantity: 281,
	},
	{
		garage_id: 30,
		part_id: 54,
		quantity: 313,
	},
	{
		garage_id: 30,
		part_id: 55,
		quantity: 57,
	},
	{
		garage_id: 30,
		part_id: 56,
		quantity: 21,
	},
	{
		garage_id: 30,
		part_id: 57,
		quantity: 230,
	},
	{
		garage_id: 30,
		part_id: 58,
		quantity: 319,
	},
	{
		garage_id: 30,
		part_id: 59,
		quantity: 404,
	},
	{
		garage_id: 30,
		part_id: 60,
		quantity: 422,
	},
	{
		garage_id: 30,
		part_id: 61,
		quantity: 146,
	},
	{
		garage_id: 30,
		part_id: 62,
		quantity: 133,
	},
	{
		garage_id: 30,
		part_id: 63,
		quantity: 192,
	},
	{
		garage_id: 30,
		part_id: 64,
		quantity: 228,
	},
	{
		garage_id: 30,
		part_id: 65,
		quantity: 315,
	},
	{
		garage_id: 30,
		part_id: 66,
		quantity: 455,
	},
	{
		garage_id: 30,
		part_id: 67,
		quantity: 448,
	},
	{
		garage_id: 30,
		part_id: 68,
		quantity: 186,
	},
	{
		garage_id: 30,
		part_id: 69,
		quantity: 355,
	},
	{
		garage_id: 30,
		part_id: 70,
		quantity: 313,
	},
	{
		garage_id: 30,
		part_id: 71,
		quantity: 223,
	},
	{
		garage_id: 30,
		part_id: 72,
		quantity: 500,
	},
	{
		garage_id: 30,
		part_id: 73,
		quantity: 463,
	},
	{
		garage_id: 30,
		part_id: 74,
		quantity: 499,
	},
	{
		garage_id: 30,
		part_id: 75,
		quantity: 41,
	},
	{
		garage_id: 30,
		part_id: 76,
		quantity: 155,
	},
	{
		garage_id: 30,
		part_id: 77,
		quantity: 416,
	},
	{
		garage_id: 30,
		part_id: 78,
		quantity: 251,
	},
	{
		garage_id: 30,
		part_id: 79,
		quantity: 472,
	},
	{
		garage_id: 30,
		part_id: 80,
		quantity: 323,
	},
	{
		garage_id: 30,
		part_id: 81,
		quantity: 239,
	},
	{
		garage_id: 30,
		part_id: 82,
		quantity: 276,
	},
	{
		garage_id: 30,
		part_id: 83,
		quantity: 443,
	},
	{
		garage_id: 30,
		part_id: 84,
		quantity: 471,
	},
	{
		garage_id: 30,
		part_id: 85,
		quantity: 45,
	},
	{
		garage_id: 30,
		part_id: 86,
		quantity: 337,
	},
	{
		garage_id: 30,
		part_id: 87,
		quantity: 152,
	},
	{
		garage_id: 30,
		part_id: 88,
		quantity: 433,
	},
	{
		garage_id: 30,
		part_id: 89,
		quantity: 221,
	},
	{
		garage_id: 30,
		part_id: 90,
		quantity: 395,
	},
	{
		garage_id: 30,
		part_id: 91,
		quantity: 375,
	},
	{
		garage_id: 30,
		part_id: 92,
		quantity: 295,
	},
	{
		garage_id: 30,
		part_id: 93,
		quantity: 334,
	},
	{
		garage_id: 30,
		part_id: 94,
		quantity: 0,
	},
	{
		garage_id: 30,
		part_id: 95,
		quantity: 79,
	},
	{
		garage_id: 30,
		part_id: 96,
		quantity: 127,
	},
	{
		garage_id: 30,
		part_id: 97,
		quantity: 126,
	},
	{
		garage_id: 30,
		part_id: 98,
		quantity: 4,
	},
	{
		garage_id: 30,
		part_id: 99,
		quantity: 474,
	},
	{
		garage_id: 30,
		part_id: 100,
		quantity: 486,
	},
	{
		garage_id: 30,
		part_id: 101,
		quantity: 176,
	},
	{
		garage_id: 30,
		part_id: 102,
		quantity: 85,
	},
	{
		garage_id: 30,
		part_id: 103,
		quantity: 40,
	},
	{
		garage_id: 30,
		part_id: 104,
		quantity: 247,
	},
	{
		garage_id: 30,
		part_id: 105,
		quantity: 288,
	},
	{
		garage_id: 30,
		part_id: 106,
		quantity: 396,
	},
	{
		garage_id: 30,
		part_id: 107,
		quantity: 481,
	},
	{
		garage_id: 30,
		part_id: 108,
		quantity: 186,
	},
	{
		garage_id: 30,
		part_id: 109,
		quantity: 324,
	},
	{
		garage_id: 30,
		part_id: 110,
		quantity: 279,
	},
	{
		garage_id: 30,
		part_id: 111,
		quantity: 106,
	},
	{
		garage_id: 30,
		part_id: 112,
		quantity: 477,
	},
	{
		garage_id: 30,
		part_id: 113,
		quantity: 368,
	},
	{
		garage_id: 30,
		part_id: 114,
		quantity: 78,
	},
	{
		garage_id: 30,
		part_id: 115,
		quantity: 71,
	},
	{
		garage_id: 30,
		part_id: 116,
		quantity: 5,
	},
	{
		garage_id: 30,
		part_id: 117,
		quantity: 238,
	},
	{
		garage_id: 30,
		part_id: 118,
		quantity: 327,
	},
	{
		garage_id: 30,
		part_id: 119,
		quantity: 444,
	},
	{
		garage_id: 30,
		part_id: 120,
		quantity: 247,
	},
	{
		garage_id: 30,
		part_id: 121,
		quantity: 409,
	},
	{
		garage_id: 30,
		part_id: 122,
		quantity: 230,
	},
	{
		garage_id: 30,
		part_id: 123,
		quantity: 374,
	},
	{
		garage_id: 30,
		part_id: 124,
		quantity: 146,
	},
	{
		garage_id: 30,
		part_id: 125,
		quantity: 389,
	},
	{
		garage_id: 30,
		part_id: 126,
		quantity: 263,
	},
	{
		garage_id: 30,
		part_id: 127,
		quantity: 338,
	},
	{
		garage_id: 30,
		part_id: 128,
		quantity: 64,
	},
	{
		garage_id: 30,
		part_id: 129,
		quantity: 36,
	},
	{
		garage_id: 30,
		part_id: 130,
		quantity: 347,
	},
	{
		garage_id: 30,
		part_id: 131,
		quantity: 68,
	},
	{
		garage_id: 30,
		part_id: 132,
		quantity: 191,
	},
	{
		garage_id: 30,
		part_id: 133,
		quantity: 252,
	},
	{
		garage_id: 30,
		part_id: 134,
		quantity: 379,
	},
	{
		garage_id: 30,
		part_id: 135,
		quantity: 17,
	},
	{
		garage_id: 30,
		part_id: 136,
		quantity: 422,
	},
	{
		garage_id: 30,
		part_id: 137,
		quantity: 80,
	},
	{
		garage_id: 30,
		part_id: 138,
		quantity: 197,
	},
	{
		garage_id: 30,
		part_id: 139,
		quantity: 444,
	},
	{
		garage_id: 30,
		part_id: 140,
		quantity: 181,
	},
	{
		garage_id: 30,
		part_id: 141,
		quantity: 286,
	},
	{
		garage_id: 30,
		part_id: 142,
		quantity: 11,
	},
	{
		garage_id: 30,
		part_id: 143,
		quantity: 336,
	},
	{
		garage_id: 30,
		part_id: 144,
		quantity: 351,
	},
	{
		garage_id: 30,
		part_id: 145,
		quantity: 53,
	},
	{
		garage_id: 30,
		part_id: 146,
		quantity: 232,
	},
	{
		garage_id: 30,
		part_id: 147,
		quantity: 158,
	},
	{
		garage_id: 30,
		part_id: 148,
		quantity: 338,
	},
	{
		garage_id: 30,
		part_id: 149,
		quantity: 283,
	},
	{
		garage_id: 30,
		part_id: 150,
		quantity: 209,
	},
	{
		garage_id: 30,
		part_id: 151,
		quantity: 106,
	},
	{
		garage_id: 30,
		part_id: 152,
		quantity: 265,
	},
	{
		garage_id: 30,
		part_id: 153,
		quantity: 462,
	},
	{
		garage_id: 30,
		part_id: 154,
		quantity: 225,
	},
	{
		garage_id: 30,
		part_id: 155,
		quantity: 268,
	},
	{
		garage_id: 30,
		part_id: 156,
		quantity: 173,
	},
	{
		garage_id: 30,
		part_id: 157,
		quantity: 447,
	},
	{
		garage_id: 30,
		part_id: 158,
		quantity: 96,
	},
	{
		garage_id: 30,
		part_id: 159,
		quantity: 307,
	},
	{
		garage_id: 30,
		part_id: 160,
		quantity: 276,
	},
	{
		garage_id: 30,
		part_id: 161,
		quantity: 321,
	},
	{
		garage_id: 30,
		part_id: 162,
		quantity: 23,
	},
	{
		garage_id: 30,
		part_id: 163,
		quantity: 202,
	},
	{
		garage_id: 30,
		part_id: 164,
		quantity: 237,
	},
	{
		garage_id: 30,
		part_id: 165,
		quantity: 494,
	},
	{
		garage_id: 30,
		part_id: 166,
		quantity: 454,
	},
	{
		garage_id: 30,
		part_id: 167,
		quantity: 426,
	},
	{
		garage_id: 30,
		part_id: 168,
		quantity: 465,
	},
	{
		garage_id: 30,
		part_id: 169,
		quantity: 293,
	},
	{
		garage_id: 30,
		part_id: 170,
		quantity: 68,
	},
	{
		garage_id: 30,
		part_id: 171,
		quantity: 403,
	},
	{
		garage_id: 30,
		part_id: 172,
		quantity: 426,
	},
	{
		garage_id: 30,
		part_id: 173,
		quantity: 129,
	},
	{
		garage_id: 30,
		part_id: 174,
		quantity: 385,
	},
	{
		garage_id: 30,
		part_id: 175,
		quantity: 276,
	},
	{
		garage_id: 30,
		part_id: 176,
		quantity: 59,
	},
	{
		garage_id: 30,
		part_id: 177,
		quantity: 311,
	},
	{
		garage_id: 30,
		part_id: 178,
		quantity: 214,
	},
	{
		garage_id: 30,
		part_id: 179,
		quantity: 210,
	},
	{
		garage_id: 30,
		part_id: 180,
		quantity: 272,
	},
	{
		garage_id: 30,
		part_id: 181,
		quantity: 156,
	},
	{
		garage_id: 30,
		part_id: 182,
		quantity: 367,
	},
	{
		garage_id: 30,
		part_id: 183,
		quantity: 78,
	},
	{
		garage_id: 30,
		part_id: 184,
		quantity: 306,
	},
	{
		garage_id: 30,
		part_id: 185,
		quantity: 468,
	},
	{
		garage_id: 30,
		part_id: 186,
		quantity: 74,
	},
	{
		garage_id: 30,
		part_id: 187,
		quantity: 122,
	},
	{
		garage_id: 30,
		part_id: 188,
		quantity: 288,
	},
	{
		garage_id: 30,
		part_id: 189,
		quantity: 392,
	},
	{
		garage_id: 30,
		part_id: 190,
		quantity: 39,
	},
	{
		garage_id: 30,
		part_id: 191,
		quantity: 419,
	},
	{
		garage_id: 30,
		part_id: 192,
		quantity: 88,
	},
	{
		garage_id: 30,
		part_id: 193,
		quantity: 87,
	},
	{
		garage_id: 30,
		part_id: 194,
		quantity: 96,
	},
	{
		garage_id: 30,
		part_id: 195,
		quantity: 168,
	},
	{
		garage_id: 30,
		part_id: 196,
		quantity: 207,
	},
	{
		garage_id: 30,
		part_id: 197,
		quantity: 109,
	},
	{
		garage_id: 30,
		part_id: 198,
		quantity: 433,
	},
	{
		garage_id: 30,
		part_id: 199,
		quantity: 29,
	},
	{
		garage_id: 30,
		part_id: 200,
		quantity: 318,
	},
	{
		garage_id: 30,
		part_id: 201,
		quantity: 241,
	},
	{
		garage_id: 30,
		part_id: 202,
		quantity: 183,
	},
	{
		garage_id: 30,
		part_id: 203,
		quantity: 340,
	},
	{
		garage_id: 30,
		part_id: 204,
		quantity: 490,
	},
	{
		garage_id: 30,
		part_id: 205,
		quantity: 184,
	},
	{
		garage_id: 30,
		part_id: 206,
		quantity: 305,
	},
	{
		garage_id: 30,
		part_id: 207,
		quantity: 419,
	},
	{
		garage_id: 30,
		part_id: 208,
		quantity: 248,
	},
	{
		garage_id: 30,
		part_id: 209,
		quantity: 303,
	},
	{
		garage_id: 30,
		part_id: 210,
		quantity: 15,
	},
	{
		garage_id: 30,
		part_id: 211,
		quantity: 201,
	},
	{
		garage_id: 30,
		part_id: 212,
		quantity: 411,
	},
	{
		garage_id: 30,
		part_id: 213,
		quantity: 107,
	},
	{
		garage_id: 30,
		part_id: 214,
		quantity: 260,
	},
	{
		garage_id: 30,
		part_id: 215,
		quantity: 381,
	},
	{
		garage_id: 30,
		part_id: 216,
		quantity: 21,
	},
	{
		garage_id: 30,
		part_id: 217,
		quantity: 373,
	},
	{
		garage_id: 30,
		part_id: 218,
		quantity: 206,
	},
	{
		garage_id: 30,
		part_id: 219,
		quantity: 383,
	},
	{
		garage_id: 30,
		part_id: 220,
		quantity: 38,
	},
	{
		garage_id: 30,
		part_id: 221,
		quantity: 228,
	},
	{
		garage_id: 30,
		part_id: 222,
		quantity: 117,
	},
	{
		garage_id: 30,
		part_id: 223,
		quantity: 104,
	},
	{
		garage_id: 30,
		part_id: 224,
		quantity: 435,
	},
	{
		garage_id: 30,
		part_id: 225,
		quantity: 469,
	},
	{
		garage_id: 30,
		part_id: 226,
		quantity: 293,
	},
	{
		garage_id: 30,
		part_id: 227,
		quantity: 47,
	},
	{
		garage_id: 30,
		part_id: 228,
		quantity: 59,
	},
	{
		garage_id: 30,
		part_id: 229,
		quantity: 417,
	},
	{
		garage_id: 30,
		part_id: 230,
		quantity: 484,
	},
	{
		garage_id: 30,
		part_id: 231,
		quantity: 292,
	},
	{
		garage_id: 30,
		part_id: 232,
		quantity: 263,
	},
	{
		garage_id: 30,
		part_id: 233,
		quantity: 341,
	},
	{
		garage_id: 30,
		part_id: 234,
		quantity: 378,
	},
	{
		garage_id: 30,
		part_id: 235,
		quantity: 100,
	},
	{
		garage_id: 30,
		part_id: 236,
		quantity: 115,
	},
	{
		garage_id: 30,
		part_id: 237,
		quantity: 486,
	},
	{
		garage_id: 30,
		part_id: 238,
		quantity: 479,
	},
	{
		garage_id: 30,
		part_id: 239,
		quantity: 90,
	},
	{
		garage_id: 30,
		part_id: 240,
		quantity: 155,
	},
	{
		garage_id: 30,
		part_id: 241,
		quantity: 371,
	},
	{
		garage_id: 30,
		part_id: 242,
		quantity: 329,
	},
	{
		garage_id: 30,
		part_id: 243,
		quantity: 446,
	},
	{
		garage_id: 30,
		part_id: 244,
		quantity: 266,
	},
	{
		garage_id: 30,
		part_id: 245,
		quantity: 365,
	},
	{
		garage_id: 30,
		part_id: 246,
		quantity: 490,
	},
	{
		garage_id: 30,
		part_id: 247,
		quantity: 332,
	},
	{
		garage_id: 30,
		part_id: 248,
		quantity: 74,
	},
	{
		garage_id: 30,
		part_id: 249,
		quantity: 114,
	},
	{
		garage_id: 30,
		part_id: 250,
		quantity: 222,
	},
	{
		garage_id: 30,
		part_id: 251,
		quantity: 385,
	},
	{
		garage_id: 30,
		part_id: 252,
		quantity: 282,
	},
	{
		garage_id: 30,
		part_id: 253,
		quantity: 267,
	},
	{
		garage_id: 30,
		part_id: 254,
		quantity: 403,
	},
	{
		garage_id: 30,
		part_id: 255,
		quantity: 105,
	},
	{
		garage_id: 30,
		part_id: 256,
		quantity: 35,
	},
	{
		garage_id: 30,
		part_id: 257,
		quantity: 75,
	},
	{
		garage_id: 30,
		part_id: 258,
		quantity: 260,
	},
	{
		garage_id: 30,
		part_id: 259,
		quantity: 258,
	},
	{
		garage_id: 30,
		part_id: 260,
		quantity: 91,
	},
	{
		garage_id: 30,
		part_id: 261,
		quantity: 341,
	},
	{
		garage_id: 30,
		part_id: 262,
		quantity: 372,
	},
	{
		garage_id: 30,
		part_id: 263,
		quantity: 346,
	},
	{
		garage_id: 30,
		part_id: 264,
		quantity: 298,
	},
	{
		garage_id: 30,
		part_id: 265,
		quantity: 418,
	},
	{
		garage_id: 30,
		part_id: 266,
		quantity: 447,
	},
	{
		garage_id: 30,
		part_id: 267,
		quantity: 269,
	},
	{
		garage_id: 30,
		part_id: 268,
		quantity: 125,
	},
	{
		garage_id: 30,
		part_id: 269,
		quantity: 350,
	},
	{
		garage_id: 30,
		part_id: 270,
		quantity: 72,
	},
	{
		garage_id: 30,
		part_id: 271,
		quantity: 6,
	},
	{
		garage_id: 30,
		part_id: 272,
		quantity: 137,
	},
	{
		garage_id: 30,
		part_id: 273,
		quantity: 133,
	},
	{
		garage_id: 30,
		part_id: 274,
		quantity: 448,
	},
	{
		garage_id: 30,
		part_id: 275,
		quantity: 494,
	},
	{
		garage_id: 30,
		part_id: 276,
		quantity: 16,
	},
	{
		garage_id: 30,
		part_id: 277,
		quantity: 264,
	},
	{
		garage_id: 30,
		part_id: 278,
		quantity: 419,
	},
	{
		garage_id: 30,
		part_id: 279,
		quantity: 471,
	},
	{
		garage_id: 30,
		part_id: 280,
		quantity: 105,
	},
	{
		garage_id: 30,
		part_id: 281,
		quantity: 436,
	},
	{
		garage_id: 30,
		part_id: 282,
		quantity: 153,
	},
	{
		garage_id: 30,
		part_id: 283,
		quantity: 156,
	},
	{
		garage_id: 30,
		part_id: 284,
		quantity: 249,
	},
	{
		garage_id: 30,
		part_id: 285,
		quantity: 147,
	},
	{
		garage_id: 30,
		part_id: 286,
		quantity: 131,
	},
	{
		garage_id: 30,
		part_id: 287,
		quantity: 272,
	},
	{
		garage_id: 30,
		part_id: 288,
		quantity: 223,
	},
	{
		garage_id: 30,
		part_id: 289,
		quantity: 275,
	},
	{
		garage_id: 30,
		part_id: 290,
		quantity: 312,
	},
	{
		garage_id: 30,
		part_id: 291,
		quantity: 333,
	},
	{
		garage_id: 30,
		part_id: 292,
		quantity: 216,
	},
	{
		garage_id: 30,
		part_id: 293,
		quantity: 283,
	},
	{
		garage_id: 30,
		part_id: 294,
		quantity: 115,
	},
	{
		garage_id: 30,
		part_id: 295,
		quantity: 493,
	},
	{
		garage_id: 30,
		part_id: 296,
		quantity: 440,
	},
	{
		garage_id: 30,
		part_id: 297,
		quantity: 413,
	},
	{
		garage_id: 30,
		part_id: 298,
		quantity: 309,
	},
	{
		garage_id: 30,
		part_id: 299,
		quantity: 472,
	},
	{
		garage_id: 30,
		part_id: 300,
		quantity: 460,
	},
	{
		garage_id: 30,
		part_id: 301,
		quantity: 87,
	},
	{
		garage_id: 30,
		part_id: 302,
		quantity: 293,
	},
	{
		garage_id: 30,
		part_id: 303,
		quantity: 400,
	},
	{
		garage_id: 30,
		part_id: 304,
		quantity: 73,
	},
	{
		garage_id: 30,
		part_id: 305,
		quantity: 199,
	},
	{
		garage_id: 30,
		part_id: 306,
		quantity: 357,
	},
	{
		garage_id: 30,
		part_id: 307,
		quantity: 454,
	},
	{
		garage_id: 30,
		part_id: 308,
		quantity: 251,
	},
	{
		garage_id: 30,
		part_id: 309,
		quantity: 61,
	},
	{
		garage_id: 30,
		part_id: 310,
		quantity: 363,
	},
	{
		garage_id: 30,
		part_id: 311,
		quantity: 98,
	},
	{
		garage_id: 30,
		part_id: 312,
		quantity: 423,
	},
	{
		garage_id: 30,
		part_id: 313,
		quantity: 465,
	},
	{
		garage_id: 30,
		part_id: 314,
		quantity: 380,
	},
	{
		garage_id: 30,
		part_id: 315,
		quantity: 133,
	},
	{
		garage_id: 30,
		part_id: 316,
		quantity: 445,
	},
	{
		garage_id: 30,
		part_id: 317,
		quantity: 259,
	},
	{
		garage_id: 30,
		part_id: 318,
		quantity: 283,
	},
	{
		garage_id: 30,
		part_id: 319,
		quantity: 52,
	},
	{
		garage_id: 30,
		part_id: 320,
		quantity: 403,
	},
	{
		garage_id: 30,
		part_id: 321,
		quantity: 372,
	},
	{
		garage_id: 30,
		part_id: 322,
		quantity: 158,
	},
	{
		garage_id: 30,
		part_id: 323,
		quantity: 399,
	},
	{
		garage_id: 30,
		part_id: 324,
		quantity: 432,
	},
	{
		garage_id: 30,
		part_id: 325,
		quantity: 267,
	},
	{
		garage_id: 30,
		part_id: 326,
		quantity: 363,
	},
	{
		garage_id: 30,
		part_id: 327,
		quantity: 396,
	},
	{
		garage_id: 30,
		part_id: 328,
		quantity: 151,
	},
	{
		garage_id: 30,
		part_id: 329,
		quantity: 140,
	},
	{
		garage_id: 30,
		part_id: 330,
		quantity: 89,
	},
	{
		garage_id: 30,
		part_id: 331,
		quantity: 460,
	},
	{
		garage_id: 30,
		part_id: 332,
		quantity: 415,
	},
	{
		garage_id: 30,
		part_id: 333,
		quantity: 109,
	},
	{
		garage_id: 30,
		part_id: 334,
		quantity: 135,
	},
	{
		garage_id: 30,
		part_id: 335,
		quantity: 351,
	},
	{
		garage_id: 30,
		part_id: 336,
		quantity: 85,
	},
	{
		garage_id: 30,
		part_id: 337,
		quantity: 75,
	},
	{
		garage_id: 30,
		part_id: 338,
		quantity: 474,
	},
	{
		garage_id: 30,
		part_id: 339,
		quantity: 412,
	},
	{
		garage_id: 30,
		part_id: 340,
		quantity: 380,
	},
	{
		garage_id: 30,
		part_id: 341,
		quantity: 110,
	},
	{
		garage_id: 30,
		part_id: 342,
		quantity: 272,
	},
	{
		garage_id: 30,
		part_id: 343,
		quantity: 302,
	},
	{
		garage_id: 30,
		part_id: 344,
		quantity: 103,
	},
	{
		garage_id: 30,
		part_id: 345,
		quantity: 212,
	},
	{
		garage_id: 30,
		part_id: 346,
		quantity: 447,
	},
	{
		garage_id: 30,
		part_id: 347,
		quantity: 247,
	},
	{
		garage_id: 30,
		part_id: 348,
		quantity: 31,
	},
	{
		garage_id: 30,
		part_id: 349,
		quantity: 413,
	},
	{
		garage_id: 30,
		part_id: 350,
		quantity: 1,
	},
	{
		garage_id: 30,
		part_id: 351,
		quantity: 99,
	},
	{
		garage_id: 30,
		part_id: 352,
		quantity: 212,
	},
	{
		garage_id: 30,
		part_id: 353,
		quantity: 292,
	},
	{
		garage_id: 30,
		part_id: 354,
		quantity: 10,
	},
	{
		garage_id: 30,
		part_id: 355,
		quantity: 129,
	},
	{
		garage_id: 30,
		part_id: 356,
		quantity: 486,
	},
	{
		garage_id: 30,
		part_id: 357,
		quantity: 367,
	},
	{
		garage_id: 30,
		part_id: 358,
		quantity: 86,
	},
	{
		garage_id: 30,
		part_id: 359,
		quantity: 194,
	},
	{
		garage_id: 30,
		part_id: 360,
		quantity: 28,
	},
	{
		garage_id: 30,
		part_id: 361,
		quantity: 168,
	},
	{
		garage_id: 30,
		part_id: 362,
		quantity: 19,
	},
	{
		garage_id: 30,
		part_id: 363,
		quantity: 187,
	},
	{
		garage_id: 30,
		part_id: 364,
		quantity: 240,
	},
	{
		garage_id: 30,
		part_id: 365,
		quantity: 84,
	},
	{
		garage_id: 30,
		part_id: 366,
		quantity: 168,
	},
	{
		garage_id: 30,
		part_id: 367,
		quantity: 43,
	},
	{
		garage_id: 30,
		part_id: 368,
		quantity: 404,
	},
	{
		garage_id: 30,
		part_id: 369,
		quantity: 208,
	},
	{
		garage_id: 30,
		part_id: 370,
		quantity: 256,
	},
	{
		garage_id: 30,
		part_id: 371,
		quantity: 140,
	},
	{
		garage_id: 30,
		part_id: 372,
		quantity: 132,
	},
	{
		garage_id: 30,
		part_id: 373,
		quantity: 394,
	},
	{
		garage_id: 30,
		part_id: 374,
		quantity: 232,
	},
	{
		garage_id: 30,
		part_id: 375,
		quantity: 336,
	},
	{
		garage_id: 30,
		part_id: 376,
		quantity: 127,
	},
	{
		garage_id: 30,
		part_id: 377,
		quantity: 38,
	},
	{
		garage_id: 30,
		part_id: 378,
		quantity: 480,
	},
	{
		garage_id: 30,
		part_id: 379,
		quantity: 128,
	},
	{
		garage_id: 30,
		part_id: 380,
		quantity: 450,
	},
	{
		garage_id: 30,
		part_id: 381,
		quantity: 215,
	},
	{
		garage_id: 30,
		part_id: 382,
		quantity: 443,
	},
	{
		garage_id: 30,
		part_id: 383,
		quantity: 442,
	},
	{
		garage_id: 30,
		part_id: 384,
		quantity: 285,
	},
	{
		garage_id: 30,
		part_id: 385,
		quantity: 450,
	},
	{
		garage_id: 30,
		part_id: 386,
		quantity: 85,
	},
	{
		garage_id: 30,
		part_id: 387,
		quantity: 394,
	},
	{
		garage_id: 30,
		part_id: 388,
		quantity: 178,
	},
	{
		garage_id: 30,
		part_id: 389,
		quantity: 255,
	},
	{
		garage_id: 30,
		part_id: 390,
		quantity: 399,
	},
	{
		garage_id: 30,
		part_id: 391,
		quantity: 349,
	},
	{
		garage_id: 30,
		part_id: 392,
		quantity: 27,
	},
	{
		garage_id: 30,
		part_id: 393,
		quantity: 219,
	},
	{
		garage_id: 30,
		part_id: 394,
		quantity: 164,
	},
	{
		garage_id: 30,
		part_id: 395,
		quantity: 214,
	},
	{
		garage_id: 30,
		part_id: 396,
		quantity: 124,
	},
	{
		garage_id: 30,
		part_id: 397,
		quantity: 89,
	},
	{
		garage_id: 30,
		part_id: 398,
		quantity: 15,
	},
	{
		garage_id: 30,
		part_id: 399,
		quantity: 12,
	},
	{
		garage_id: 31,
		part_id: 0,
		quantity: 110,
	},
	{
		garage_id: 31,
		part_id: 1,
		quantity: 232,
	},
	{
		garage_id: 31,
		part_id: 2,
		quantity: 150,
	},
	{
		garage_id: 31,
		part_id: 3,
		quantity: 457,
	},
	{
		garage_id: 31,
		part_id: 4,
		quantity: 454,
	},
	{
		garage_id: 31,
		part_id: 5,
		quantity: 181,
	},
	{
		garage_id: 31,
		part_id: 6,
		quantity: 73,
	},
	{
		garage_id: 31,
		part_id: 7,
		quantity: 399,
	},
	{
		garage_id: 31,
		part_id: 8,
		quantity: 31,
	},
	{
		garage_id: 31,
		part_id: 9,
		quantity: 351,
	},
	{
		garage_id: 31,
		part_id: 10,
		quantity: 319,
	},
	{
		garage_id: 31,
		part_id: 11,
		quantity: 324,
	},
	{
		garage_id: 31,
		part_id: 12,
		quantity: 419,
	},
	{
		garage_id: 31,
		part_id: 13,
		quantity: 51,
	},
	{
		garage_id: 31,
		part_id: 14,
		quantity: 392,
	},
	{
		garage_id: 31,
		part_id: 15,
		quantity: 142,
	},
	{
		garage_id: 31,
		part_id: 16,
		quantity: 251,
	},
	{
		garage_id: 31,
		part_id: 17,
		quantity: 302,
	},
	{
		garage_id: 31,
		part_id: 18,
		quantity: 397,
	},
	{
		garage_id: 31,
		part_id: 19,
		quantity: 25,
	},
	{
		garage_id: 31,
		part_id: 20,
		quantity: 284,
	},
	{
		garage_id: 31,
		part_id: 21,
		quantity: 40,
	},
	{
		garage_id: 31,
		part_id: 22,
		quantity: 176,
	},
	{
		garage_id: 31,
		part_id: 23,
		quantity: 260,
	},
	{
		garage_id: 31,
		part_id: 24,
		quantity: 389,
	},
	{
		garage_id: 31,
		part_id: 25,
		quantity: 323,
	},
	{
		garage_id: 31,
		part_id: 26,
		quantity: 308,
	},
	{
		garage_id: 31,
		part_id: 27,
		quantity: 395,
	},
	{
		garage_id: 31,
		part_id: 28,
		quantity: 110,
	},
	{
		garage_id: 31,
		part_id: 29,
		quantity: 410,
	},
	{
		garage_id: 31,
		part_id: 30,
		quantity: 288,
	},
	{
		garage_id: 31,
		part_id: 31,
		quantity: 71,
	},
	{
		garage_id: 31,
		part_id: 32,
		quantity: 347,
	},
	{
		garage_id: 31,
		part_id: 33,
		quantity: 16,
	},
	{
		garage_id: 31,
		part_id: 34,
		quantity: 307,
	},
	{
		garage_id: 31,
		part_id: 35,
		quantity: 86,
	},
	{
		garage_id: 31,
		part_id: 36,
		quantity: 83,
	},
	{
		garage_id: 31,
		part_id: 37,
		quantity: 381,
	},
	{
		garage_id: 31,
		part_id: 38,
		quantity: 368,
	},
	{
		garage_id: 31,
		part_id: 39,
		quantity: 235,
	},
	{
		garage_id: 31,
		part_id: 40,
		quantity: 41,
	},
	{
		garage_id: 31,
		part_id: 41,
		quantity: 225,
	},
	{
		garage_id: 31,
		part_id: 42,
		quantity: 86,
	},
	{
		garage_id: 31,
		part_id: 43,
		quantity: 155,
	},
	{
		garage_id: 31,
		part_id: 44,
		quantity: 130,
	},
	{
		garage_id: 31,
		part_id: 45,
		quantity: 78,
	},
	{
		garage_id: 31,
		part_id: 46,
		quantity: 69,
	},
	{
		garage_id: 31,
		part_id: 47,
		quantity: 121,
	},
	{
		garage_id: 31,
		part_id: 48,
		quantity: 65,
	},
	{
		garage_id: 31,
		part_id: 49,
		quantity: 338,
	},
	{
		garage_id: 31,
		part_id: 50,
		quantity: 375,
	},
	{
		garage_id: 31,
		part_id: 51,
		quantity: 124,
	},
	{
		garage_id: 31,
		part_id: 52,
		quantity: 188,
	},
	{
		garage_id: 31,
		part_id: 53,
		quantity: 424,
	},
	{
		garage_id: 31,
		part_id: 54,
		quantity: 424,
	},
	{
		garage_id: 31,
		part_id: 55,
		quantity: 323,
	},
	{
		garage_id: 31,
		part_id: 56,
		quantity: 318,
	},
	{
		garage_id: 31,
		part_id: 57,
		quantity: 86,
	},
	{
		garage_id: 31,
		part_id: 58,
		quantity: 229,
	},
	{
		garage_id: 31,
		part_id: 59,
		quantity: 293,
	},
	{
		garage_id: 31,
		part_id: 60,
		quantity: 33,
	},
	{
		garage_id: 31,
		part_id: 61,
		quantity: 464,
	},
	{
		garage_id: 31,
		part_id: 62,
		quantity: 197,
	},
	{
		garage_id: 31,
		part_id: 63,
		quantity: 18,
	},
	{
		garage_id: 31,
		part_id: 64,
		quantity: 26,
	},
	{
		garage_id: 31,
		part_id: 65,
		quantity: 176,
	},
	{
		garage_id: 31,
		part_id: 66,
		quantity: 465,
	},
	{
		garage_id: 31,
		part_id: 67,
		quantity: 108,
	},
	{
		garage_id: 31,
		part_id: 68,
		quantity: 97,
	},
	{
		garage_id: 31,
		part_id: 69,
		quantity: 29,
	},
	{
		garage_id: 31,
		part_id: 70,
		quantity: 379,
	},
	{
		garage_id: 31,
		part_id: 71,
		quantity: 9,
	},
	{
		garage_id: 31,
		part_id: 72,
		quantity: 185,
	},
	{
		garage_id: 31,
		part_id: 73,
		quantity: 175,
	},
	{
		garage_id: 31,
		part_id: 74,
		quantity: 425,
	},
	{
		garage_id: 31,
		part_id: 75,
		quantity: 336,
	},
	{
		garage_id: 31,
		part_id: 76,
		quantity: 204,
	},
	{
		garage_id: 31,
		part_id: 77,
		quantity: 119,
	},
	{
		garage_id: 31,
		part_id: 78,
		quantity: 344,
	},
	{
		garage_id: 31,
		part_id: 79,
		quantity: 28,
	},
	{
		garage_id: 31,
		part_id: 80,
		quantity: 92,
	},
	{
		garage_id: 31,
		part_id: 81,
		quantity: 96,
	},
	{
		garage_id: 31,
		part_id: 82,
		quantity: 270,
	},
	{
		garage_id: 31,
		part_id: 83,
		quantity: 392,
	},
	{
		garage_id: 31,
		part_id: 84,
		quantity: 420,
	},
	{
		garage_id: 31,
		part_id: 85,
		quantity: 460,
	},
	{
		garage_id: 31,
		part_id: 86,
		quantity: 358,
	},
	{
		garage_id: 31,
		part_id: 87,
		quantity: 388,
	},
	{
		garage_id: 31,
		part_id: 88,
		quantity: 163,
	},
	{
		garage_id: 31,
		part_id: 89,
		quantity: 337,
	},
	{
		garage_id: 31,
		part_id: 90,
		quantity: 16,
	},
	{
		garage_id: 31,
		part_id: 91,
		quantity: 300,
	},
	{
		garage_id: 31,
		part_id: 92,
		quantity: 298,
	},
	{
		garage_id: 31,
		part_id: 93,
		quantity: 68,
	},
	{
		garage_id: 31,
		part_id: 94,
		quantity: 446,
	},
	{
		garage_id: 31,
		part_id: 95,
		quantity: 295,
	},
	{
		garage_id: 31,
		part_id: 96,
		quantity: 432,
	},
	{
		garage_id: 31,
		part_id: 97,
		quantity: 2,
	},
	{
		garage_id: 31,
		part_id: 98,
		quantity: 36,
	},
	{
		garage_id: 31,
		part_id: 99,
		quantity: 0,
	},
	{
		garage_id: 31,
		part_id: 100,
		quantity: 359,
	},
	{
		garage_id: 31,
		part_id: 101,
		quantity: 402,
	},
	{
		garage_id: 31,
		part_id: 102,
		quantity: 170,
	},
	{
		garage_id: 31,
		part_id: 103,
		quantity: 298,
	},
	{
		garage_id: 31,
		part_id: 104,
		quantity: 461,
	},
	{
		garage_id: 31,
		part_id: 105,
		quantity: 432,
	},
	{
		garage_id: 31,
		part_id: 106,
		quantity: 493,
	},
	{
		garage_id: 31,
		part_id: 107,
		quantity: 222,
	},
	{
		garage_id: 31,
		part_id: 108,
		quantity: 351,
	},
	{
		garage_id: 31,
		part_id: 109,
		quantity: 336,
	},
	{
		garage_id: 31,
		part_id: 110,
		quantity: 390,
	},
	{
		garage_id: 31,
		part_id: 111,
		quantity: 386,
	},
	{
		garage_id: 31,
		part_id: 112,
		quantity: 453,
	},
	{
		garage_id: 31,
		part_id: 113,
		quantity: 233,
	},
	{
		garage_id: 31,
		part_id: 114,
		quantity: 274,
	},
	{
		garage_id: 31,
		part_id: 115,
		quantity: 286,
	},
	{
		garage_id: 31,
		part_id: 116,
		quantity: 25,
	},
	{
		garage_id: 31,
		part_id: 117,
		quantity: 221,
	},
	{
		garage_id: 31,
		part_id: 118,
		quantity: 73,
	},
	{
		garage_id: 31,
		part_id: 119,
		quantity: 458,
	},
	{
		garage_id: 31,
		part_id: 120,
		quantity: 160,
	},
	{
		garage_id: 31,
		part_id: 121,
		quantity: 447,
	},
	{
		garage_id: 31,
		part_id: 122,
		quantity: 89,
	},
	{
		garage_id: 31,
		part_id: 123,
		quantity: 291,
	},
	{
		garage_id: 31,
		part_id: 124,
		quantity: 45,
	},
	{
		garage_id: 31,
		part_id: 125,
		quantity: 259,
	},
	{
		garage_id: 31,
		part_id: 126,
		quantity: 227,
	},
	{
		garage_id: 31,
		part_id: 127,
		quantity: 356,
	},
	{
		garage_id: 31,
		part_id: 128,
		quantity: 225,
	},
	{
		garage_id: 31,
		part_id: 129,
		quantity: 107,
	},
	{
		garage_id: 31,
		part_id: 130,
		quantity: 135,
	},
	{
		garage_id: 31,
		part_id: 131,
		quantity: 81,
	},
	{
		garage_id: 31,
		part_id: 132,
		quantity: 420,
	},
	{
		garage_id: 31,
		part_id: 133,
		quantity: 72,
	},
	{
		garage_id: 31,
		part_id: 134,
		quantity: 410,
	},
	{
		garage_id: 31,
		part_id: 135,
		quantity: 25,
	},
	{
		garage_id: 31,
		part_id: 136,
		quantity: 110,
	},
	{
		garage_id: 31,
		part_id: 137,
		quantity: 245,
	},
	{
		garage_id: 31,
		part_id: 138,
		quantity: 252,
	},
	{
		garage_id: 31,
		part_id: 139,
		quantity: 393,
	},
	{
		garage_id: 31,
		part_id: 140,
		quantity: 18,
	},
	{
		garage_id: 31,
		part_id: 141,
		quantity: 127,
	},
	{
		garage_id: 31,
		part_id: 142,
		quantity: 75,
	},
	{
		garage_id: 31,
		part_id: 143,
		quantity: 293,
	},
	{
		garage_id: 31,
		part_id: 144,
		quantity: 183,
	},
	{
		garage_id: 31,
		part_id: 145,
		quantity: 45,
	},
	{
		garage_id: 31,
		part_id: 146,
		quantity: 243,
	},
	{
		garage_id: 31,
		part_id: 147,
		quantity: 438,
	},
	{
		garage_id: 31,
		part_id: 148,
		quantity: 5,
	},
	{
		garage_id: 31,
		part_id: 149,
		quantity: 365,
	},
	{
		garage_id: 31,
		part_id: 150,
		quantity: 242,
	},
	{
		garage_id: 31,
		part_id: 151,
		quantity: 341,
	},
	{
		garage_id: 31,
		part_id: 152,
		quantity: 210,
	},
	{
		garage_id: 31,
		part_id: 153,
		quantity: 226,
	},
	{
		garage_id: 31,
		part_id: 154,
		quantity: 475,
	},
	{
		garage_id: 31,
		part_id: 155,
		quantity: 497,
	},
	{
		garage_id: 31,
		part_id: 156,
		quantity: 363,
	},
	{
		garage_id: 31,
		part_id: 157,
		quantity: 486,
	},
	{
		garage_id: 31,
		part_id: 158,
		quantity: 235,
	},
	{
		garage_id: 31,
		part_id: 159,
		quantity: 179,
	},
	{
		garage_id: 31,
		part_id: 160,
		quantity: 293,
	},
	{
		garage_id: 31,
		part_id: 161,
		quantity: 188,
	},
	{
		garage_id: 31,
		part_id: 162,
		quantity: 255,
	},
	{
		garage_id: 31,
		part_id: 163,
		quantity: 237,
	},
	{
		garage_id: 31,
		part_id: 164,
		quantity: 0,
	},
	{
		garage_id: 31,
		part_id: 165,
		quantity: 159,
	},
	{
		garage_id: 31,
		part_id: 166,
		quantity: 269,
	},
	{
		garage_id: 31,
		part_id: 167,
		quantity: 341,
	},
	{
		garage_id: 31,
		part_id: 168,
		quantity: 123,
	},
	{
		garage_id: 31,
		part_id: 169,
		quantity: 464,
	},
	{
		garage_id: 31,
		part_id: 170,
		quantity: 86,
	},
	{
		garage_id: 31,
		part_id: 171,
		quantity: 48,
	},
	{
		garage_id: 31,
		part_id: 172,
		quantity: 186,
	},
	{
		garage_id: 31,
		part_id: 173,
		quantity: 323,
	},
	{
		garage_id: 31,
		part_id: 174,
		quantity: 217,
	},
	{
		garage_id: 31,
		part_id: 175,
		quantity: 97,
	},
	{
		garage_id: 31,
		part_id: 176,
		quantity: 71,
	},
	{
		garage_id: 31,
		part_id: 177,
		quantity: 463,
	},
	{
		garage_id: 31,
		part_id: 178,
		quantity: 365,
	},
	{
		garage_id: 31,
		part_id: 179,
		quantity: 127,
	},
	{
		garage_id: 31,
		part_id: 180,
		quantity: 227,
	},
	{
		garage_id: 31,
		part_id: 181,
		quantity: 124,
	},
	{
		garage_id: 31,
		part_id: 182,
		quantity: 343,
	},
	{
		garage_id: 31,
		part_id: 183,
		quantity: 314,
	},
	{
		garage_id: 31,
		part_id: 184,
		quantity: 33,
	},
	{
		garage_id: 31,
		part_id: 185,
		quantity: 89,
	},
	{
		garage_id: 31,
		part_id: 186,
		quantity: 371,
	},
	{
		garage_id: 31,
		part_id: 187,
		quantity: 440,
	},
	{
		garage_id: 31,
		part_id: 188,
		quantity: 207,
	},
	{
		garage_id: 31,
		part_id: 189,
		quantity: 458,
	},
	{
		garage_id: 31,
		part_id: 190,
		quantity: 223,
	},
	{
		garage_id: 31,
		part_id: 191,
		quantity: 76,
	},
	{
		garage_id: 31,
		part_id: 192,
		quantity: 198,
	},
	{
		garage_id: 31,
		part_id: 193,
		quantity: 325,
	},
	{
		garage_id: 31,
		part_id: 194,
		quantity: 431,
	},
	{
		garage_id: 31,
		part_id: 195,
		quantity: 304,
	},
	{
		garage_id: 31,
		part_id: 196,
		quantity: 20,
	},
	{
		garage_id: 31,
		part_id: 197,
		quantity: 247,
	},
	{
		garage_id: 31,
		part_id: 198,
		quantity: 453,
	},
	{
		garage_id: 31,
		part_id: 199,
		quantity: 86,
	},
	{
		garage_id: 31,
		part_id: 200,
		quantity: 192,
	},
	{
		garage_id: 31,
		part_id: 201,
		quantity: 398,
	},
	{
		garage_id: 31,
		part_id: 202,
		quantity: 97,
	},
	{
		garage_id: 31,
		part_id: 203,
		quantity: 436,
	},
	{
		garage_id: 31,
		part_id: 204,
		quantity: 479,
	},
	{
		garage_id: 31,
		part_id: 205,
		quantity: 59,
	},
	{
		garage_id: 31,
		part_id: 206,
		quantity: 495,
	},
	{
		garage_id: 31,
		part_id: 207,
		quantity: 30,
	},
	{
		garage_id: 31,
		part_id: 208,
		quantity: 326,
	},
	{
		garage_id: 31,
		part_id: 209,
		quantity: 216,
	},
	{
		garage_id: 31,
		part_id: 210,
		quantity: 256,
	},
	{
		garage_id: 31,
		part_id: 211,
		quantity: 63,
	},
	{
		garage_id: 31,
		part_id: 212,
		quantity: 336,
	},
	{
		garage_id: 31,
		part_id: 213,
		quantity: 165,
	},
	{
		garage_id: 31,
		part_id: 214,
		quantity: 381,
	},
	{
		garage_id: 31,
		part_id: 215,
		quantity: 159,
	},
	{
		garage_id: 31,
		part_id: 216,
		quantity: 425,
	},
	{
		garage_id: 31,
		part_id: 217,
		quantity: 128,
	},
	{
		garage_id: 31,
		part_id: 218,
		quantity: 341,
	},
	{
		garage_id: 31,
		part_id: 219,
		quantity: 146,
	},
	{
		garage_id: 31,
		part_id: 220,
		quantity: 336,
	},
	{
		garage_id: 31,
		part_id: 221,
		quantity: 396,
	},
	{
		garage_id: 31,
		part_id: 222,
		quantity: 143,
	},
	{
		garage_id: 31,
		part_id: 223,
		quantity: 376,
	},
	{
		garage_id: 31,
		part_id: 224,
		quantity: 492,
	},
	{
		garage_id: 31,
		part_id: 225,
		quantity: 136,
	},
	{
		garage_id: 31,
		part_id: 226,
		quantity: 339,
	},
	{
		garage_id: 31,
		part_id: 227,
		quantity: 179,
	},
	{
		garage_id: 31,
		part_id: 228,
		quantity: 337,
	},
	{
		garage_id: 31,
		part_id: 229,
		quantity: 73,
	},
	{
		garage_id: 31,
		part_id: 230,
		quantity: 467,
	},
	{
		garage_id: 31,
		part_id: 231,
		quantity: 334,
	},
	{
		garage_id: 31,
		part_id: 232,
		quantity: 320,
	},
	{
		garage_id: 31,
		part_id: 233,
		quantity: 396,
	},
	{
		garage_id: 31,
		part_id: 234,
		quantity: 380,
	},
	{
		garage_id: 31,
		part_id: 235,
		quantity: 430,
	},
	{
		garage_id: 31,
		part_id: 236,
		quantity: 334,
	},
	{
		garage_id: 31,
		part_id: 237,
		quantity: 381,
	},
	{
		garage_id: 31,
		part_id: 238,
		quantity: 98,
	},
	{
		garage_id: 31,
		part_id: 239,
		quantity: 225,
	},
	{
		garage_id: 31,
		part_id: 240,
		quantity: 421,
	},
	{
		garage_id: 31,
		part_id: 241,
		quantity: 321,
	},
	{
		garage_id: 31,
		part_id: 242,
		quantity: 219,
	},
	{
		garage_id: 31,
		part_id: 243,
		quantity: 456,
	},
	{
		garage_id: 31,
		part_id: 244,
		quantity: 156,
	},
	{
		garage_id: 31,
		part_id: 245,
		quantity: 468,
	},
	{
		garage_id: 31,
		part_id: 246,
		quantity: 200,
	},
	{
		garage_id: 31,
		part_id: 247,
		quantity: 284,
	},
	{
		garage_id: 31,
		part_id: 248,
		quantity: 116,
	},
	{
		garage_id: 31,
		part_id: 249,
		quantity: 54,
	},
	{
		garage_id: 31,
		part_id: 250,
		quantity: 286,
	},
	{
		garage_id: 31,
		part_id: 251,
		quantity: 302,
	},
	{
		garage_id: 31,
		part_id: 252,
		quantity: 253,
	},
	{
		garage_id: 31,
		part_id: 253,
		quantity: 60,
	},
	{
		garage_id: 31,
		part_id: 254,
		quantity: 72,
	},
	{
		garage_id: 31,
		part_id: 255,
		quantity: 178,
	},
	{
		garage_id: 31,
		part_id: 256,
		quantity: 411,
	},
	{
		garage_id: 31,
		part_id: 257,
		quantity: 55,
	},
	{
		garage_id: 31,
		part_id: 258,
		quantity: 201,
	},
	{
		garage_id: 31,
		part_id: 259,
		quantity: 423,
	},
	{
		garage_id: 31,
		part_id: 260,
		quantity: 450,
	},
	{
		garage_id: 31,
		part_id: 261,
		quantity: 191,
	},
	{
		garage_id: 31,
		part_id: 262,
		quantity: 410,
	},
	{
		garage_id: 31,
		part_id: 263,
		quantity: 217,
	},
	{
		garage_id: 31,
		part_id: 264,
		quantity: 178,
	},
	{
		garage_id: 31,
		part_id: 265,
		quantity: 117,
	},
	{
		garage_id: 31,
		part_id: 266,
		quantity: 154,
	},
	{
		garage_id: 31,
		part_id: 267,
		quantity: 413,
	},
	{
		garage_id: 31,
		part_id: 268,
		quantity: 383,
	},
	{
		garage_id: 31,
		part_id: 269,
		quantity: 128,
	},
	{
		garage_id: 31,
		part_id: 270,
		quantity: 134,
	},
	{
		garage_id: 31,
		part_id: 271,
		quantity: 440,
	},
	{
		garage_id: 31,
		part_id: 272,
		quantity: 22,
	},
	{
		garage_id: 31,
		part_id: 273,
		quantity: 298,
	},
	{
		garage_id: 31,
		part_id: 274,
		quantity: 77,
	},
	{
		garage_id: 31,
		part_id: 275,
		quantity: 402,
	},
	{
		garage_id: 31,
		part_id: 276,
		quantity: 106,
	},
	{
		garage_id: 31,
		part_id: 277,
		quantity: 5,
	},
	{
		garage_id: 31,
		part_id: 278,
		quantity: 448,
	},
	{
		garage_id: 31,
		part_id: 279,
		quantity: 60,
	},
	{
		garage_id: 31,
		part_id: 280,
		quantity: 396,
	},
	{
		garage_id: 31,
		part_id: 281,
		quantity: 116,
	},
	{
		garage_id: 31,
		part_id: 282,
		quantity: 454,
	},
	{
		garage_id: 31,
		part_id: 283,
		quantity: 84,
	},
	{
		garage_id: 31,
		part_id: 284,
		quantity: 360,
	},
	{
		garage_id: 31,
		part_id: 285,
		quantity: 341,
	},
	{
		garage_id: 31,
		part_id: 286,
		quantity: 365,
	},
	{
		garage_id: 31,
		part_id: 287,
		quantity: 250,
	},
	{
		garage_id: 31,
		part_id: 288,
		quantity: 483,
	},
	{
		garage_id: 31,
		part_id: 289,
		quantity: 6,
	},
	{
		garage_id: 31,
		part_id: 290,
		quantity: 256,
	},
	{
		garage_id: 31,
		part_id: 291,
		quantity: 373,
	},
	{
		garage_id: 31,
		part_id: 292,
		quantity: 297,
	},
	{
		garage_id: 31,
		part_id: 293,
		quantity: 369,
	},
	{
		garage_id: 31,
		part_id: 294,
		quantity: 324,
	},
	{
		garage_id: 31,
		part_id: 295,
		quantity: 110,
	},
	{
		garage_id: 31,
		part_id: 296,
		quantity: 18,
	},
	{
		garage_id: 31,
		part_id: 297,
		quantity: 431,
	},
	{
		garage_id: 31,
		part_id: 298,
		quantity: 486,
	},
	{
		garage_id: 31,
		part_id: 299,
		quantity: 434,
	},
	{
		garage_id: 31,
		part_id: 300,
		quantity: 428,
	},
	{
		garage_id: 31,
		part_id: 301,
		quantity: 371,
	},
	{
		garage_id: 31,
		part_id: 302,
		quantity: 23,
	},
	{
		garage_id: 31,
		part_id: 303,
		quantity: 228,
	},
	{
		garage_id: 31,
		part_id: 304,
		quantity: 41,
	},
	{
		garage_id: 31,
		part_id: 305,
		quantity: 147,
	},
	{
		garage_id: 31,
		part_id: 306,
		quantity: 75,
	},
	{
		garage_id: 31,
		part_id: 307,
		quantity: 151,
	},
	{
		garage_id: 31,
		part_id: 308,
		quantity: 156,
	},
	{
		garage_id: 31,
		part_id: 309,
		quantity: 397,
	},
	{
		garage_id: 31,
		part_id: 310,
		quantity: 465,
	},
	{
		garage_id: 31,
		part_id: 311,
		quantity: 337,
	},
	{
		garage_id: 31,
		part_id: 312,
		quantity: 233,
	},
	{
		garage_id: 31,
		part_id: 313,
		quantity: 470,
	},
	{
		garage_id: 31,
		part_id: 314,
		quantity: 200,
	},
	{
		garage_id: 31,
		part_id: 315,
		quantity: 50,
	},
	{
		garage_id: 31,
		part_id: 316,
		quantity: 234,
	},
	{
		garage_id: 31,
		part_id: 317,
		quantity: 447,
	},
	{
		garage_id: 31,
		part_id: 318,
		quantity: 238,
	},
	{
		garage_id: 31,
		part_id: 319,
		quantity: 11,
	},
	{
		garage_id: 31,
		part_id: 320,
		quantity: 368,
	},
	{
		garage_id: 31,
		part_id: 321,
		quantity: 131,
	},
	{
		garage_id: 31,
		part_id: 322,
		quantity: 163,
	},
	{
		garage_id: 31,
		part_id: 323,
		quantity: 272,
	},
	{
		garage_id: 31,
		part_id: 324,
		quantity: 481,
	},
	{
		garage_id: 31,
		part_id: 325,
		quantity: 214,
	},
	{
		garage_id: 31,
		part_id: 326,
		quantity: 189,
	},
	{
		garage_id: 31,
		part_id: 327,
		quantity: 318,
	},
	{
		garage_id: 31,
		part_id: 328,
		quantity: 325,
	},
	{
		garage_id: 31,
		part_id: 329,
		quantity: 379,
	},
	{
		garage_id: 31,
		part_id: 330,
		quantity: 90,
	},
	{
		garage_id: 31,
		part_id: 331,
		quantity: 131,
	},
	{
		garage_id: 31,
		part_id: 332,
		quantity: 323,
	},
	{
		garage_id: 31,
		part_id: 333,
		quantity: 129,
	},
	{
		garage_id: 31,
		part_id: 334,
		quantity: 439,
	},
	{
		garage_id: 31,
		part_id: 335,
		quantity: 197,
	},
	{
		garage_id: 31,
		part_id: 336,
		quantity: 27,
	},
	{
		garage_id: 31,
		part_id: 337,
		quantity: 73,
	},
	{
		garage_id: 31,
		part_id: 338,
		quantity: 206,
	},
	{
		garage_id: 31,
		part_id: 339,
		quantity: 14,
	},
	{
		garage_id: 31,
		part_id: 340,
		quantity: 301,
	},
	{
		garage_id: 31,
		part_id: 341,
		quantity: 434,
	},
	{
		garage_id: 31,
		part_id: 342,
		quantity: 390,
	},
	{
		garage_id: 31,
		part_id: 343,
		quantity: 440,
	},
	{
		garage_id: 31,
		part_id: 344,
		quantity: 493,
	},
	{
		garage_id: 31,
		part_id: 345,
		quantity: 105,
	},
	{
		garage_id: 31,
		part_id: 346,
		quantity: 3,
	},
	{
		garage_id: 31,
		part_id: 347,
		quantity: 138,
	},
	{
		garage_id: 31,
		part_id: 348,
		quantity: 269,
	},
	{
		garage_id: 31,
		part_id: 349,
		quantity: 475,
	},
	{
		garage_id: 31,
		part_id: 350,
		quantity: 85,
	},
	{
		garage_id: 31,
		part_id: 351,
		quantity: 44,
	},
	{
		garage_id: 31,
		part_id: 352,
		quantity: 473,
	},
	{
		garage_id: 31,
		part_id: 353,
		quantity: 493,
	},
	{
		garage_id: 31,
		part_id: 354,
		quantity: 98,
	},
	{
		garage_id: 31,
		part_id: 355,
		quantity: 104,
	},
	{
		garage_id: 31,
		part_id: 356,
		quantity: 128,
	},
	{
		garage_id: 31,
		part_id: 357,
		quantity: 242,
	},
	{
		garage_id: 31,
		part_id: 358,
		quantity: 2,
	},
	{
		garage_id: 31,
		part_id: 359,
		quantity: 278,
	},
	{
		garage_id: 31,
		part_id: 360,
		quantity: 144,
	},
	{
		garage_id: 31,
		part_id: 361,
		quantity: 457,
	},
	{
		garage_id: 31,
		part_id: 362,
		quantity: 244,
	},
	{
		garage_id: 31,
		part_id: 363,
		quantity: 142,
	},
	{
		garage_id: 31,
		part_id: 364,
		quantity: 478,
	},
	{
		garage_id: 31,
		part_id: 365,
		quantity: 286,
	},
	{
		garage_id: 31,
		part_id: 366,
		quantity: 389,
	},
	{
		garage_id: 31,
		part_id: 367,
		quantity: 124,
	},
	{
		garage_id: 31,
		part_id: 368,
		quantity: 155,
	},
	{
		garage_id: 31,
		part_id: 369,
		quantity: 290,
	},
	{
		garage_id: 31,
		part_id: 370,
		quantity: 58,
	},
	{
		garage_id: 31,
		part_id: 371,
		quantity: 480,
	},
	{
		garage_id: 31,
		part_id: 372,
		quantity: 260,
	},
	{
		garage_id: 31,
		part_id: 373,
		quantity: 284,
	},
	{
		garage_id: 31,
		part_id: 374,
		quantity: 241,
	},
	{
		garage_id: 31,
		part_id: 375,
		quantity: 130,
	},
	{
		garage_id: 31,
		part_id: 376,
		quantity: 371,
	},
	{
		garage_id: 31,
		part_id: 377,
		quantity: 229,
	},
	{
		garage_id: 31,
		part_id: 378,
		quantity: 141,
	},
	{
		garage_id: 31,
		part_id: 379,
		quantity: 399,
	},
	{
		garage_id: 31,
		part_id: 380,
		quantity: 237,
	},
	{
		garage_id: 31,
		part_id: 381,
		quantity: 136,
	},
	{
		garage_id: 31,
		part_id: 382,
		quantity: 420,
	},
	{
		garage_id: 31,
		part_id: 383,
		quantity: 294,
	},
	{
		garage_id: 31,
		part_id: 384,
		quantity: 88,
	},
	{
		garage_id: 31,
		part_id: 385,
		quantity: 222,
	},
	{
		garage_id: 31,
		part_id: 386,
		quantity: 357,
	},
	{
		garage_id: 31,
		part_id: 387,
		quantity: 230,
	},
	{
		garage_id: 31,
		part_id: 388,
		quantity: 56,
	},
	{
		garage_id: 31,
		part_id: 389,
		quantity: 328,
	},
	{
		garage_id: 31,
		part_id: 390,
		quantity: 498,
	},
	{
		garage_id: 31,
		part_id: 391,
		quantity: 124,
	},
	{
		garage_id: 31,
		part_id: 392,
		quantity: 91,
	},
	{
		garage_id: 31,
		part_id: 393,
		quantity: 319,
	},
	{
		garage_id: 31,
		part_id: 394,
		quantity: 24,
	},
	{
		garage_id: 31,
		part_id: 395,
		quantity: 269,
	},
	{
		garage_id: 31,
		part_id: 396,
		quantity: 295,
	},
	{
		garage_id: 31,
		part_id: 397,
		quantity: 423,
	},
	{
		garage_id: 31,
		part_id: 398,
		quantity: 370,
	},
	{
		garage_id: 31,
		part_id: 399,
		quantity: 279,
	},
	{
		garage_id: 32,
		part_id: 0,
		quantity: 371,
	},
	{
		garage_id: 32,
		part_id: 1,
		quantity: 133,
	},
	{
		garage_id: 32,
		part_id: 2,
		quantity: 60,
	},
	{
		garage_id: 32,
		part_id: 3,
		quantity: 429,
	},
	{
		garage_id: 32,
		part_id: 4,
		quantity: 435,
	},
	{
		garage_id: 32,
		part_id: 5,
		quantity: 184,
	},
	{
		garage_id: 32,
		part_id: 6,
		quantity: 244,
	},
	{
		garage_id: 32,
		part_id: 7,
		quantity: 38,
	},
	{
		garage_id: 32,
		part_id: 8,
		quantity: 371,
	},
	{
		garage_id: 32,
		part_id: 9,
		quantity: 263,
	},
	{
		garage_id: 32,
		part_id: 10,
		quantity: 409,
	},
	{
		garage_id: 32,
		part_id: 11,
		quantity: 113,
	},
	{
		garage_id: 32,
		part_id: 12,
		quantity: 157,
	},
	{
		garage_id: 32,
		part_id: 13,
		quantity: 86,
	},
	{
		garage_id: 32,
		part_id: 14,
		quantity: 156,
	},
	{
		garage_id: 32,
		part_id: 15,
		quantity: 45,
	},
	{
		garage_id: 32,
		part_id: 16,
		quantity: 35,
	},
	{
		garage_id: 32,
		part_id: 17,
		quantity: 23,
	},
	{
		garage_id: 32,
		part_id: 18,
		quantity: 401,
	},
	{
		garage_id: 32,
		part_id: 19,
		quantity: 397,
	},
	{
		garage_id: 32,
		part_id: 20,
		quantity: 191,
	},
	{
		garage_id: 32,
		part_id: 21,
		quantity: 281,
	},
	{
		garage_id: 32,
		part_id: 22,
		quantity: 271,
	},
	{
		garage_id: 32,
		part_id: 23,
		quantity: 277,
	},
	{
		garage_id: 32,
		part_id: 24,
		quantity: 274,
	},
	{
		garage_id: 32,
		part_id: 25,
		quantity: 90,
	},
	{
		garage_id: 32,
		part_id: 26,
		quantity: 441,
	},
	{
		garage_id: 32,
		part_id: 27,
		quantity: 260,
	},
	{
		garage_id: 32,
		part_id: 28,
		quantity: 430,
	},
	{
		garage_id: 32,
		part_id: 29,
		quantity: 282,
	},
	{
		garage_id: 32,
		part_id: 30,
		quantity: 401,
	},
	{
		garage_id: 32,
		part_id: 31,
		quantity: 242,
	},
	{
		garage_id: 32,
		part_id: 32,
		quantity: 72,
	},
	{
		garage_id: 32,
		part_id: 33,
		quantity: 132,
	},
	{
		garage_id: 32,
		part_id: 34,
		quantity: 287,
	},
	{
		garage_id: 32,
		part_id: 35,
		quantity: 9,
	},
	{
		garage_id: 32,
		part_id: 36,
		quantity: 422,
	},
	{
		garage_id: 32,
		part_id: 37,
		quantity: 208,
	},
	{
		garage_id: 32,
		part_id: 38,
		quantity: 455,
	},
	{
		garage_id: 32,
		part_id: 39,
		quantity: 341,
	},
	{
		garage_id: 32,
		part_id: 40,
		quantity: 456,
	},
	{
		garage_id: 32,
		part_id: 41,
		quantity: 177,
	},
	{
		garage_id: 32,
		part_id: 42,
		quantity: 414,
	},
	{
		garage_id: 32,
		part_id: 43,
		quantity: 200,
	},
	{
		garage_id: 32,
		part_id: 44,
		quantity: 338,
	},
	{
		garage_id: 32,
		part_id: 45,
		quantity: 208,
	},
	{
		garage_id: 32,
		part_id: 46,
		quantity: 249,
	},
	{
		garage_id: 32,
		part_id: 47,
		quantity: 425,
	},
	{
		garage_id: 32,
		part_id: 48,
		quantity: 208,
	},
	{
		garage_id: 32,
		part_id: 49,
		quantity: 281,
	},
	{
		garage_id: 32,
		part_id: 50,
		quantity: 486,
	},
	{
		garage_id: 32,
		part_id: 51,
		quantity: 149,
	},
	{
		garage_id: 32,
		part_id: 52,
		quantity: 350,
	},
	{
		garage_id: 32,
		part_id: 53,
		quantity: 13,
	},
	{
		garage_id: 32,
		part_id: 54,
		quantity: 291,
	},
	{
		garage_id: 32,
		part_id: 55,
		quantity: 112,
	},
	{
		garage_id: 32,
		part_id: 56,
		quantity: 277,
	},
	{
		garage_id: 32,
		part_id: 57,
		quantity: 7,
	},
	{
		garage_id: 32,
		part_id: 58,
		quantity: 37,
	},
	{
		garage_id: 32,
		part_id: 59,
		quantity: 347,
	},
	{
		garage_id: 32,
		part_id: 60,
		quantity: 374,
	},
	{
		garage_id: 32,
		part_id: 61,
		quantity: 92,
	},
	{
		garage_id: 32,
		part_id: 62,
		quantity: 221,
	},
	{
		garage_id: 32,
		part_id: 63,
		quantity: 208,
	},
	{
		garage_id: 32,
		part_id: 64,
		quantity: 7,
	},
	{
		garage_id: 32,
		part_id: 65,
		quantity: 383,
	},
	{
		garage_id: 32,
		part_id: 66,
		quantity: 56,
	},
	{
		garage_id: 32,
		part_id: 67,
		quantity: 375,
	},
	{
		garage_id: 32,
		part_id: 68,
		quantity: 258,
	},
	{
		garage_id: 32,
		part_id: 69,
		quantity: 108,
	},
	{
		garage_id: 32,
		part_id: 70,
		quantity: 134,
	},
	{
		garage_id: 32,
		part_id: 71,
		quantity: 492,
	},
	{
		garage_id: 32,
		part_id: 72,
		quantity: 155,
	},
	{
		garage_id: 32,
		part_id: 73,
		quantity: 30,
	},
	{
		garage_id: 32,
		part_id: 74,
		quantity: 314,
	},
	{
		garage_id: 32,
		part_id: 75,
		quantity: 467,
	},
	{
		garage_id: 32,
		part_id: 76,
		quantity: 424,
	},
	{
		garage_id: 32,
		part_id: 77,
		quantity: 103,
	},
	{
		garage_id: 32,
		part_id: 78,
		quantity: 82,
	},
	{
		garage_id: 32,
		part_id: 79,
		quantity: 492,
	},
	{
		garage_id: 32,
		part_id: 80,
		quantity: 181,
	},
	{
		garage_id: 32,
		part_id: 81,
		quantity: 35,
	},
	{
		garage_id: 32,
		part_id: 82,
		quantity: 145,
	},
	{
		garage_id: 32,
		part_id: 83,
		quantity: 186,
	},
	{
		garage_id: 32,
		part_id: 84,
		quantity: 333,
	},
	{
		garage_id: 32,
		part_id: 85,
		quantity: 363,
	},
	{
		garage_id: 32,
		part_id: 86,
		quantity: 14,
	},
	{
		garage_id: 32,
		part_id: 87,
		quantity: 68,
	},
	{
		garage_id: 32,
		part_id: 88,
		quantity: 29,
	},
	{
		garage_id: 32,
		part_id: 89,
		quantity: 307,
	},
	{
		garage_id: 32,
		part_id: 90,
		quantity: 15,
	},
	{
		garage_id: 32,
		part_id: 91,
		quantity: 344,
	},
	{
		garage_id: 32,
		part_id: 92,
		quantity: 187,
	},
	{
		garage_id: 32,
		part_id: 93,
		quantity: 144,
	},
	{
		garage_id: 32,
		part_id: 94,
		quantity: 92,
	},
	{
		garage_id: 32,
		part_id: 95,
		quantity: 129,
	},
	{
		garage_id: 32,
		part_id: 96,
		quantity: 121,
	},
	{
		garage_id: 32,
		part_id: 97,
		quantity: 58,
	},
	{
		garage_id: 32,
		part_id: 98,
		quantity: 7,
	},
	{
		garage_id: 32,
		part_id: 99,
		quantity: 234,
	},
	{
		garage_id: 32,
		part_id: 100,
		quantity: 30,
	},
	{
		garage_id: 32,
		part_id: 101,
		quantity: 489,
	},
	{
		garage_id: 32,
		part_id: 102,
		quantity: 53,
	},
	{
		garage_id: 32,
		part_id: 103,
		quantity: 134,
	},
	{
		garage_id: 32,
		part_id: 104,
		quantity: 372,
	},
	{
		garage_id: 32,
		part_id: 105,
		quantity: 56,
	},
	{
		garage_id: 32,
		part_id: 106,
		quantity: 67,
	},
	{
		garage_id: 32,
		part_id: 107,
		quantity: 333,
	},
	{
		garage_id: 32,
		part_id: 108,
		quantity: 229,
	},
	{
		garage_id: 32,
		part_id: 109,
		quantity: 259,
	},
	{
		garage_id: 32,
		part_id: 110,
		quantity: 13,
	},
	{
		garage_id: 32,
		part_id: 111,
		quantity: 382,
	},
	{
		garage_id: 32,
		part_id: 112,
		quantity: 484,
	},
	{
		garage_id: 32,
		part_id: 113,
		quantity: 293,
	},
	{
		garage_id: 32,
		part_id: 114,
		quantity: 433,
	},
	{
		garage_id: 32,
		part_id: 115,
		quantity: 244,
	},
	{
		garage_id: 32,
		part_id: 116,
		quantity: 82,
	},
	{
		garage_id: 32,
		part_id: 117,
		quantity: 262,
	},
	{
		garage_id: 32,
		part_id: 118,
		quantity: 51,
	},
	{
		garage_id: 32,
		part_id: 119,
		quantity: 17,
	},
	{
		garage_id: 32,
		part_id: 120,
		quantity: 311,
	},
	{
		garage_id: 32,
		part_id: 121,
		quantity: 27,
	},
	{
		garage_id: 32,
		part_id: 122,
		quantity: 397,
	},
	{
		garage_id: 32,
		part_id: 123,
		quantity: 199,
	},
	{
		garage_id: 32,
		part_id: 124,
		quantity: 474,
	},
	{
		garage_id: 32,
		part_id: 125,
		quantity: 92,
	},
	{
		garage_id: 32,
		part_id: 126,
		quantity: 1,
	},
	{
		garage_id: 32,
		part_id: 127,
		quantity: 126,
	},
	{
		garage_id: 32,
		part_id: 128,
		quantity: 448,
	},
	{
		garage_id: 32,
		part_id: 129,
		quantity: 407,
	},
	{
		garage_id: 32,
		part_id: 130,
		quantity: 80,
	},
	{
		garage_id: 32,
		part_id: 131,
		quantity: 244,
	},
	{
		garage_id: 32,
		part_id: 132,
		quantity: 108,
	},
	{
		garage_id: 32,
		part_id: 133,
		quantity: 25,
	},
	{
		garage_id: 32,
		part_id: 134,
		quantity: 223,
	},
	{
		garage_id: 32,
		part_id: 135,
		quantity: 122,
	},
	{
		garage_id: 32,
		part_id: 136,
		quantity: 129,
	},
	{
		garage_id: 32,
		part_id: 137,
		quantity: 396,
	},
	{
		garage_id: 32,
		part_id: 138,
		quantity: 141,
	},
	{
		garage_id: 32,
		part_id: 139,
		quantity: 124,
	},
	{
		garage_id: 32,
		part_id: 140,
		quantity: 218,
	},
	{
		garage_id: 32,
		part_id: 141,
		quantity: 391,
	},
	{
		garage_id: 32,
		part_id: 142,
		quantity: 76,
	},
	{
		garage_id: 32,
		part_id: 143,
		quantity: 290,
	},
	{
		garage_id: 32,
		part_id: 144,
		quantity: 299,
	},
	{
		garage_id: 32,
		part_id: 145,
		quantity: 21,
	},
	{
		garage_id: 32,
		part_id: 146,
		quantity: 97,
	},
	{
		garage_id: 32,
		part_id: 147,
		quantity: 379,
	},
	{
		garage_id: 32,
		part_id: 148,
		quantity: 299,
	},
	{
		garage_id: 32,
		part_id: 149,
		quantity: 151,
	},
	{
		garage_id: 32,
		part_id: 150,
		quantity: 277,
	},
	{
		garage_id: 32,
		part_id: 151,
		quantity: 59,
	},
	{
		garage_id: 32,
		part_id: 152,
		quantity: 49,
	},
	{
		garage_id: 32,
		part_id: 153,
		quantity: 337,
	},
	{
		garage_id: 32,
		part_id: 154,
		quantity: 43,
	},
	{
		garage_id: 32,
		part_id: 155,
		quantity: 80,
	},
	{
		garage_id: 32,
		part_id: 156,
		quantity: 294,
	},
	{
		garage_id: 32,
		part_id: 157,
		quantity: 246,
	},
	{
		garage_id: 32,
		part_id: 158,
		quantity: 434,
	},
	{
		garage_id: 32,
		part_id: 159,
		quantity: 216,
	},
	{
		garage_id: 32,
		part_id: 160,
		quantity: 129,
	},
	{
		garage_id: 32,
		part_id: 161,
		quantity: 3,
	},
	{
		garage_id: 32,
		part_id: 162,
		quantity: 419,
	},
	{
		garage_id: 32,
		part_id: 163,
		quantity: 238,
	},
	{
		garage_id: 32,
		part_id: 164,
		quantity: 66,
	},
	{
		garage_id: 32,
		part_id: 165,
		quantity: 447,
	},
	{
		garage_id: 32,
		part_id: 166,
		quantity: 22,
	},
	{
		garage_id: 32,
		part_id: 167,
		quantity: 333,
	},
	{
		garage_id: 32,
		part_id: 168,
		quantity: 173,
	},
	{
		garage_id: 32,
		part_id: 169,
		quantity: 55,
	},
	{
		garage_id: 32,
		part_id: 170,
		quantity: 169,
	},
	{
		garage_id: 32,
		part_id: 171,
		quantity: 273,
	},
	{
		garage_id: 32,
		part_id: 172,
		quantity: 188,
	},
	{
		garage_id: 32,
		part_id: 173,
		quantity: 117,
	},
	{
		garage_id: 32,
		part_id: 174,
		quantity: 109,
	},
	{
		garage_id: 32,
		part_id: 175,
		quantity: 434,
	},
	{
		garage_id: 32,
		part_id: 176,
		quantity: 32,
	},
	{
		garage_id: 32,
		part_id: 177,
		quantity: 449,
	},
	{
		garage_id: 32,
		part_id: 178,
		quantity: 346,
	},
	{
		garage_id: 32,
		part_id: 179,
		quantity: 162,
	},
	{
		garage_id: 32,
		part_id: 180,
		quantity: 146,
	},
	{
		garage_id: 32,
		part_id: 181,
		quantity: 406,
	},
	{
		garage_id: 32,
		part_id: 182,
		quantity: 44,
	},
	{
		garage_id: 32,
		part_id: 183,
		quantity: 290,
	},
	{
		garage_id: 32,
		part_id: 184,
		quantity: 129,
	},
	{
		garage_id: 32,
		part_id: 185,
		quantity: 476,
	},
	{
		garage_id: 32,
		part_id: 186,
		quantity: 147,
	},
	{
		garage_id: 32,
		part_id: 187,
		quantity: 209,
	},
	{
		garage_id: 32,
		part_id: 188,
		quantity: 469,
	},
	{
		garage_id: 32,
		part_id: 189,
		quantity: 281,
	},
	{
		garage_id: 32,
		part_id: 190,
		quantity: 385,
	},
	{
		garage_id: 32,
		part_id: 191,
		quantity: 465,
	},
	{
		garage_id: 32,
		part_id: 192,
		quantity: 278,
	},
	{
		garage_id: 32,
		part_id: 193,
		quantity: 408,
	},
	{
		garage_id: 32,
		part_id: 194,
		quantity: 42,
	},
	{
		garage_id: 32,
		part_id: 195,
		quantity: 100,
	},
	{
		garage_id: 32,
		part_id: 196,
		quantity: 443,
	},
	{
		garage_id: 32,
		part_id: 197,
		quantity: 18,
	},
	{
		garage_id: 32,
		part_id: 198,
		quantity: 52,
	},
	{
		garage_id: 32,
		part_id: 199,
		quantity: 4,
	},
	{
		garage_id: 32,
		part_id: 200,
		quantity: 23,
	},
	{
		garage_id: 32,
		part_id: 201,
		quantity: 419,
	},
	{
		garage_id: 32,
		part_id: 202,
		quantity: 268,
	},
	{
		garage_id: 32,
		part_id: 203,
		quantity: 114,
	},
	{
		garage_id: 32,
		part_id: 204,
		quantity: 27,
	},
	{
		garage_id: 32,
		part_id: 205,
		quantity: 270,
	},
	{
		garage_id: 32,
		part_id: 206,
		quantity: 495,
	},
	{
		garage_id: 32,
		part_id: 207,
		quantity: 214,
	},
	{
		garage_id: 32,
		part_id: 208,
		quantity: 277,
	},
	{
		garage_id: 32,
		part_id: 209,
		quantity: 306,
	},
	{
		garage_id: 32,
		part_id: 210,
		quantity: 341,
	},
	{
		garage_id: 32,
		part_id: 211,
		quantity: 289,
	},
	{
		garage_id: 32,
		part_id: 212,
		quantity: 28,
	},
	{
		garage_id: 32,
		part_id: 213,
		quantity: 453,
	},
	{
		garage_id: 32,
		part_id: 214,
		quantity: 120,
	},
	{
		garage_id: 32,
		part_id: 215,
		quantity: 209,
	},
	{
		garage_id: 32,
		part_id: 216,
		quantity: 104,
	},
	{
		garage_id: 32,
		part_id: 217,
		quantity: 326,
	},
	{
		garage_id: 32,
		part_id: 218,
		quantity: 482,
	},
	{
		garage_id: 32,
		part_id: 219,
		quantity: 235,
	},
	{
		garage_id: 32,
		part_id: 220,
		quantity: 14,
	},
	{
		garage_id: 32,
		part_id: 221,
		quantity: 111,
	},
	{
		garage_id: 32,
		part_id: 222,
		quantity: 386,
	},
	{
		garage_id: 32,
		part_id: 223,
		quantity: 350,
	},
	{
		garage_id: 32,
		part_id: 224,
		quantity: 394,
	},
	{
		garage_id: 32,
		part_id: 225,
		quantity: 210,
	},
	{
		garage_id: 32,
		part_id: 226,
		quantity: 400,
	},
	{
		garage_id: 32,
		part_id: 227,
		quantity: 471,
	},
	{
		garage_id: 32,
		part_id: 228,
		quantity: 46,
	},
	{
		garage_id: 32,
		part_id: 229,
		quantity: 220,
	},
	{
		garage_id: 32,
		part_id: 230,
		quantity: 78,
	},
	{
		garage_id: 32,
		part_id: 231,
		quantity: 72,
	},
	{
		garage_id: 32,
		part_id: 232,
		quantity: 117,
	},
	{
		garage_id: 32,
		part_id: 233,
		quantity: 173,
	},
	{
		garage_id: 32,
		part_id: 234,
		quantity: 295,
	},
	{
		garage_id: 32,
		part_id: 235,
		quantity: 125,
	},
	{
		garage_id: 32,
		part_id: 236,
		quantity: 221,
	},
	{
		garage_id: 32,
		part_id: 237,
		quantity: 408,
	},
	{
		garage_id: 32,
		part_id: 238,
		quantity: 427,
	},
	{
		garage_id: 32,
		part_id: 239,
		quantity: 201,
	},
	{
		garage_id: 32,
		part_id: 240,
		quantity: 79,
	},
	{
		garage_id: 32,
		part_id: 241,
		quantity: 293,
	},
	{
		garage_id: 32,
		part_id: 242,
		quantity: 106,
	},
	{
		garage_id: 32,
		part_id: 243,
		quantity: 292,
	},
	{
		garage_id: 32,
		part_id: 244,
		quantity: 469,
	},
	{
		garage_id: 32,
		part_id: 245,
		quantity: 313,
	},
	{
		garage_id: 32,
		part_id: 246,
		quantity: 488,
	},
	{
		garage_id: 32,
		part_id: 247,
		quantity: 343,
	},
	{
		garage_id: 32,
		part_id: 248,
		quantity: 320,
	},
	{
		garage_id: 32,
		part_id: 249,
		quantity: 281,
	},
	{
		garage_id: 32,
		part_id: 250,
		quantity: 252,
	},
	{
		garage_id: 32,
		part_id: 251,
		quantity: 136,
	},
	{
		garage_id: 32,
		part_id: 252,
		quantity: 448,
	},
	{
		garage_id: 32,
		part_id: 253,
		quantity: 4,
	},
	{
		garage_id: 32,
		part_id: 254,
		quantity: 325,
	},
	{
		garage_id: 32,
		part_id: 255,
		quantity: 479,
	},
	{
		garage_id: 32,
		part_id: 256,
		quantity: 272,
	},
	{
		garage_id: 32,
		part_id: 257,
		quantity: 10,
	},
	{
		garage_id: 32,
		part_id: 258,
		quantity: 65,
	},
	{
		garage_id: 32,
		part_id: 259,
		quantity: 412,
	},
	{
		garage_id: 32,
		part_id: 260,
		quantity: 363,
	},
	{
		garage_id: 32,
		part_id: 261,
		quantity: 448,
	},
	{
		garage_id: 32,
		part_id: 262,
		quantity: 433,
	},
	{
		garage_id: 32,
		part_id: 263,
		quantity: 387,
	},
	{
		garage_id: 32,
		part_id: 264,
		quantity: 400,
	},
	{
		garage_id: 32,
		part_id: 265,
		quantity: 23,
	},
	{
		garage_id: 32,
		part_id: 266,
		quantity: 260,
	},
	{
		garage_id: 32,
		part_id: 267,
		quantity: 438,
	},
	{
		garage_id: 32,
		part_id: 268,
		quantity: 168,
	},
	{
		garage_id: 32,
		part_id: 269,
		quantity: 498,
	},
	{
		garage_id: 32,
		part_id: 270,
		quantity: 333,
	},
	{
		garage_id: 32,
		part_id: 271,
		quantity: 90,
	},
	{
		garage_id: 32,
		part_id: 272,
		quantity: 190,
	},
	{
		garage_id: 32,
		part_id: 273,
		quantity: 151,
	},
	{
		garage_id: 32,
		part_id: 274,
		quantity: 161,
	},
	{
		garage_id: 32,
		part_id: 275,
		quantity: 435,
	},
	{
		garage_id: 32,
		part_id: 276,
		quantity: 216,
	},
	{
		garage_id: 32,
		part_id: 277,
		quantity: 428,
	},
	{
		garage_id: 32,
		part_id: 278,
		quantity: 270,
	},
	{
		garage_id: 32,
		part_id: 279,
		quantity: 150,
	},
	{
		garage_id: 32,
		part_id: 280,
		quantity: 235,
	},
	{
		garage_id: 32,
		part_id: 281,
		quantity: 304,
	},
	{
		garage_id: 32,
		part_id: 282,
		quantity: 307,
	},
	{
		garage_id: 32,
		part_id: 283,
		quantity: 445,
	},
	{
		garage_id: 32,
		part_id: 284,
		quantity: 48,
	},
	{
		garage_id: 32,
		part_id: 285,
		quantity: 380,
	},
	{
		garage_id: 32,
		part_id: 286,
		quantity: 246,
	},
	{
		garage_id: 32,
		part_id: 287,
		quantity: 83,
	},
	{
		garage_id: 32,
		part_id: 288,
		quantity: 237,
	},
	{
		garage_id: 32,
		part_id: 289,
		quantity: 456,
	},
	{
		garage_id: 32,
		part_id: 290,
		quantity: 364,
	},
	{
		garage_id: 32,
		part_id: 291,
		quantity: 264,
	},
	{
		garage_id: 32,
		part_id: 292,
		quantity: 361,
	},
	{
		garage_id: 32,
		part_id: 293,
		quantity: 13,
	},
	{
		garage_id: 32,
		part_id: 294,
		quantity: 91,
	},
	{
		garage_id: 32,
		part_id: 295,
		quantity: 398,
	},
	{
		garage_id: 32,
		part_id: 296,
		quantity: 348,
	},
	{
		garage_id: 32,
		part_id: 297,
		quantity: 402,
	},
	{
		garage_id: 32,
		part_id: 298,
		quantity: 232,
	},
	{
		garage_id: 32,
		part_id: 299,
		quantity: 412,
	},
	{
		garage_id: 32,
		part_id: 300,
		quantity: 84,
	},
	{
		garage_id: 32,
		part_id: 301,
		quantity: 129,
	},
	{
		garage_id: 32,
		part_id: 302,
		quantity: 38,
	},
	{
		garage_id: 32,
		part_id: 303,
		quantity: 399,
	},
	{
		garage_id: 32,
		part_id: 304,
		quantity: 298,
	},
	{
		garage_id: 32,
		part_id: 305,
		quantity: 268,
	},
	{
		garage_id: 32,
		part_id: 306,
		quantity: 437,
	},
	{
		garage_id: 32,
		part_id: 307,
		quantity: 494,
	},
	{
		garage_id: 32,
		part_id: 308,
		quantity: 355,
	},
	{
		garage_id: 32,
		part_id: 309,
		quantity: 233,
	},
	{
		garage_id: 32,
		part_id: 310,
		quantity: 188,
	},
	{
		garage_id: 32,
		part_id: 311,
		quantity: 193,
	},
	{
		garage_id: 32,
		part_id: 312,
		quantity: 73,
	},
	{
		garage_id: 32,
		part_id: 313,
		quantity: 397,
	},
	{
		garage_id: 32,
		part_id: 314,
		quantity: 78,
	},
	{
		garage_id: 32,
		part_id: 315,
		quantity: 463,
	},
	{
		garage_id: 32,
		part_id: 316,
		quantity: 173,
	},
	{
		garage_id: 32,
		part_id: 317,
		quantity: 223,
	},
	{
		garage_id: 32,
		part_id: 318,
		quantity: 242,
	},
	{
		garage_id: 32,
		part_id: 319,
		quantity: 424,
	},
	{
		garage_id: 32,
		part_id: 320,
		quantity: 124,
	},
	{
		garage_id: 32,
		part_id: 321,
		quantity: 156,
	},
	{
		garage_id: 32,
		part_id: 322,
		quantity: 70,
	},
	{
		garage_id: 32,
		part_id: 323,
		quantity: 41,
	},
	{
		garage_id: 32,
		part_id: 324,
		quantity: 138,
	},
	{
		garage_id: 32,
		part_id: 325,
		quantity: 79,
	},
	{
		garage_id: 32,
		part_id: 326,
		quantity: 440,
	},
	{
		garage_id: 32,
		part_id: 327,
		quantity: 197,
	},
	{
		garage_id: 32,
		part_id: 328,
		quantity: 244,
	},
	{
		garage_id: 32,
		part_id: 329,
		quantity: 254,
	},
	{
		garage_id: 32,
		part_id: 330,
		quantity: 145,
	},
	{
		garage_id: 32,
		part_id: 331,
		quantity: 361,
	},
	{
		garage_id: 32,
		part_id: 332,
		quantity: 269,
	},
	{
		garage_id: 32,
		part_id: 333,
		quantity: 338,
	},
	{
		garage_id: 32,
		part_id: 334,
		quantity: 224,
	},
	{
		garage_id: 32,
		part_id: 335,
		quantity: 52,
	},
	{
		garage_id: 32,
		part_id: 336,
		quantity: 446,
	},
	{
		garage_id: 32,
		part_id: 337,
		quantity: 483,
	},
	{
		garage_id: 32,
		part_id: 338,
		quantity: 389,
	},
	{
		garage_id: 32,
		part_id: 339,
		quantity: 37,
	},
	{
		garage_id: 32,
		part_id: 340,
		quantity: 339,
	},
	{
		garage_id: 32,
		part_id: 341,
		quantity: 13,
	},
	{
		garage_id: 32,
		part_id: 342,
		quantity: 160,
	},
	{
		garage_id: 32,
		part_id: 343,
		quantity: 245,
	},
	{
		garage_id: 32,
		part_id: 344,
		quantity: 225,
	},
	{
		garage_id: 32,
		part_id: 345,
		quantity: 122,
	},
	{
		garage_id: 32,
		part_id: 346,
		quantity: 9,
	},
	{
		garage_id: 32,
		part_id: 347,
		quantity: 371,
	},
	{
		garage_id: 32,
		part_id: 348,
		quantity: 483,
	},
	{
		garage_id: 32,
		part_id: 349,
		quantity: 414,
	},
	{
		garage_id: 32,
		part_id: 350,
		quantity: 257,
	},
	{
		garage_id: 32,
		part_id: 351,
		quantity: 328,
	},
	{
		garage_id: 32,
		part_id: 352,
		quantity: 482,
	},
	{
		garage_id: 32,
		part_id: 353,
		quantity: 464,
	},
	{
		garage_id: 32,
		part_id: 354,
		quantity: 187,
	},
	{
		garage_id: 32,
		part_id: 355,
		quantity: 280,
	},
	{
		garage_id: 32,
		part_id: 356,
		quantity: 84,
	},
	{
		garage_id: 32,
		part_id: 357,
		quantity: 137,
	},
	{
		garage_id: 32,
		part_id: 358,
		quantity: 406,
	},
	{
		garage_id: 32,
		part_id: 359,
		quantity: 114,
	},
	{
		garage_id: 32,
		part_id: 360,
		quantity: 23,
	},
	{
		garage_id: 32,
		part_id: 361,
		quantity: 161,
	},
	{
		garage_id: 32,
		part_id: 362,
		quantity: 187,
	},
	{
		garage_id: 32,
		part_id: 363,
		quantity: 348,
	},
	{
		garage_id: 32,
		part_id: 364,
		quantity: 488,
	},
	{
		garage_id: 32,
		part_id: 365,
		quantity: 443,
	},
	{
		garage_id: 32,
		part_id: 366,
		quantity: 257,
	},
	{
		garage_id: 32,
		part_id: 367,
		quantity: 487,
	},
	{
		garage_id: 32,
		part_id: 368,
		quantity: 473,
	},
	{
		garage_id: 32,
		part_id: 369,
		quantity: 41,
	},
	{
		garage_id: 32,
		part_id: 370,
		quantity: 264,
	},
	{
		garage_id: 32,
		part_id: 371,
		quantity: 200,
	},
	{
		garage_id: 32,
		part_id: 372,
		quantity: 98,
	},
	{
		garage_id: 32,
		part_id: 373,
		quantity: 258,
	},
	{
		garage_id: 32,
		part_id: 374,
		quantity: 66,
	},
	{
		garage_id: 32,
		part_id: 375,
		quantity: 191,
	},
	{
		garage_id: 32,
		part_id: 376,
		quantity: 144,
	},
	{
		garage_id: 32,
		part_id: 377,
		quantity: 102,
	},
	{
		garage_id: 32,
		part_id: 378,
		quantity: 202,
	},
	{
		garage_id: 32,
		part_id: 379,
		quantity: 441,
	},
	{
		garage_id: 32,
		part_id: 380,
		quantity: 180,
	},
	{
		garage_id: 32,
		part_id: 381,
		quantity: 478,
	},
	{
		garage_id: 32,
		part_id: 382,
		quantity: 291,
	},
	{
		garage_id: 32,
		part_id: 383,
		quantity: 245,
	},
	{
		garage_id: 32,
		part_id: 384,
		quantity: 188,
	},
	{
		garage_id: 32,
		part_id: 385,
		quantity: 57,
	},
	{
		garage_id: 32,
		part_id: 386,
		quantity: 2,
	},
	{
		garage_id: 32,
		part_id: 387,
		quantity: 244,
	},
	{
		garage_id: 32,
		part_id: 388,
		quantity: 188,
	},
	{
		garage_id: 32,
		part_id: 389,
		quantity: 198,
	},
	{
		garage_id: 32,
		part_id: 390,
		quantity: 68,
	},
	{
		garage_id: 32,
		part_id: 391,
		quantity: 202,
	},
	{
		garage_id: 32,
		part_id: 392,
		quantity: 313,
	},
	{
		garage_id: 32,
		part_id: 393,
		quantity: 86,
	},
	{
		garage_id: 32,
		part_id: 394,
		quantity: 66,
	},
	{
		garage_id: 32,
		part_id: 395,
		quantity: 198,
	},
	{
		garage_id: 32,
		part_id: 396,
		quantity: 461,
	},
	{
		garage_id: 32,
		part_id: 397,
		quantity: 286,
	},
	{
		garage_id: 32,
		part_id: 398,
		quantity: 428,
	},
	{
		garage_id: 32,
		part_id: 399,
		quantity: 159,
	},
	{
		garage_id: 33,
		part_id: 0,
		quantity: 79,
	},
	{
		garage_id: 33,
		part_id: 1,
		quantity: 13,
	},
	{
		garage_id: 33,
		part_id: 2,
		quantity: 304,
	},
	{
		garage_id: 33,
		part_id: 3,
		quantity: 434,
	},
	{
		garage_id: 33,
		part_id: 4,
		quantity: 73,
	},
	{
		garage_id: 33,
		part_id: 5,
		quantity: 90,
	},
	{
		garage_id: 33,
		part_id: 6,
		quantity: 411,
	},
	{
		garage_id: 33,
		part_id: 7,
		quantity: 495,
	},
	{
		garage_id: 33,
		part_id: 8,
		quantity: 331,
	},
	{
		garage_id: 33,
		part_id: 9,
		quantity: 45,
	},
	{
		garage_id: 33,
		part_id: 10,
		quantity: 117,
	},
	{
		garage_id: 33,
		part_id: 11,
		quantity: 219,
	},
	{
		garage_id: 33,
		part_id: 12,
		quantity: 28,
	},
	{
		garage_id: 33,
		part_id: 13,
		quantity: 172,
	},
	{
		garage_id: 33,
		part_id: 14,
		quantity: 243,
	},
	{
		garage_id: 33,
		part_id: 15,
		quantity: 342,
	},
	{
		garage_id: 33,
		part_id: 16,
		quantity: 313,
	},
	{
		garage_id: 33,
		part_id: 17,
		quantity: 232,
	},
	{
		garage_id: 33,
		part_id: 18,
		quantity: 313,
	},
	{
		garage_id: 33,
		part_id: 19,
		quantity: 350,
	},
	{
		garage_id: 33,
		part_id: 20,
		quantity: 82,
	},
	{
		garage_id: 33,
		part_id: 21,
		quantity: 245,
	},
	{
		garage_id: 33,
		part_id: 22,
		quantity: 25,
	},
	{
		garage_id: 33,
		part_id: 23,
		quantity: 67,
	},
	{
		garage_id: 33,
		part_id: 24,
		quantity: 118,
	},
	{
		garage_id: 33,
		part_id: 25,
		quantity: 253,
	},
	{
		garage_id: 33,
		part_id: 26,
		quantity: 323,
	},
	{
		garage_id: 33,
		part_id: 27,
		quantity: 68,
	},
	{
		garage_id: 33,
		part_id: 28,
		quantity: 319,
	},
	{
		garage_id: 33,
		part_id: 29,
		quantity: 167,
	},
	{
		garage_id: 33,
		part_id: 30,
		quantity: 100,
	},
	{
		garage_id: 33,
		part_id: 31,
		quantity: 134,
	},
	{
		garage_id: 33,
		part_id: 32,
		quantity: 424,
	},
	{
		garage_id: 33,
		part_id: 33,
		quantity: 462,
	},
	{
		garage_id: 33,
		part_id: 34,
		quantity: 110,
	},
	{
		garage_id: 33,
		part_id: 35,
		quantity: 398,
	},
	{
		garage_id: 33,
		part_id: 36,
		quantity: 480,
	},
	{
		garage_id: 33,
		part_id: 37,
		quantity: 356,
	},
	{
		garage_id: 33,
		part_id: 38,
		quantity: 82,
	},
	{
		garage_id: 33,
		part_id: 39,
		quantity: 73,
	},
	{
		garage_id: 33,
		part_id: 40,
		quantity: 377,
	},
	{
		garage_id: 33,
		part_id: 41,
		quantity: 12,
	},
	{
		garage_id: 33,
		part_id: 42,
		quantity: 132,
	},
	{
		garage_id: 33,
		part_id: 43,
		quantity: 239,
	},
	{
		garage_id: 33,
		part_id: 44,
		quantity: 472,
	},
	{
		garage_id: 33,
		part_id: 45,
		quantity: 209,
	},
	{
		garage_id: 33,
		part_id: 46,
		quantity: 112,
	},
	{
		garage_id: 33,
		part_id: 47,
		quantity: 312,
	},
	{
		garage_id: 33,
		part_id: 48,
		quantity: 140,
	},
	{
		garage_id: 33,
		part_id: 49,
		quantity: 142,
	},
	{
		garage_id: 33,
		part_id: 50,
		quantity: 190,
	},
	{
		garage_id: 33,
		part_id: 51,
		quantity: 232,
	},
	{
		garage_id: 33,
		part_id: 52,
		quantity: 76,
	},
	{
		garage_id: 33,
		part_id: 53,
		quantity: 416,
	},
	{
		garage_id: 33,
		part_id: 54,
		quantity: 286,
	},
	{
		garage_id: 33,
		part_id: 55,
		quantity: 81,
	},
	{
		garage_id: 33,
		part_id: 56,
		quantity: 198,
	},
	{
		garage_id: 33,
		part_id: 57,
		quantity: 466,
	},
	{
		garage_id: 33,
		part_id: 58,
		quantity: 58,
	},
	{
		garage_id: 33,
		part_id: 59,
		quantity: 496,
	},
	{
		garage_id: 33,
		part_id: 60,
		quantity: 259,
	},
	{
		garage_id: 33,
		part_id: 61,
		quantity: 210,
	},
	{
		garage_id: 33,
		part_id: 62,
		quantity: 242,
	},
	{
		garage_id: 33,
		part_id: 63,
		quantity: 299,
	},
	{
		garage_id: 33,
		part_id: 64,
		quantity: 206,
	},
	{
		garage_id: 33,
		part_id: 65,
		quantity: 252,
	},
	{
		garage_id: 33,
		part_id: 66,
		quantity: 319,
	},
	{
		garage_id: 33,
		part_id: 67,
		quantity: 87,
	},
	{
		garage_id: 33,
		part_id: 68,
		quantity: 251,
	},
	{
		garage_id: 33,
		part_id: 69,
		quantity: 66,
	},
	{
		garage_id: 33,
		part_id: 70,
		quantity: 176,
	},
	{
		garage_id: 33,
		part_id: 71,
		quantity: 291,
	},
	{
		garage_id: 33,
		part_id: 72,
		quantity: 289,
	},
	{
		garage_id: 33,
		part_id: 73,
		quantity: 436,
	},
	{
		garage_id: 33,
		part_id: 74,
		quantity: 259,
	},
	{
		garage_id: 33,
		part_id: 75,
		quantity: 145,
	},
	{
		garage_id: 33,
		part_id: 76,
		quantity: 308,
	},
	{
		garage_id: 33,
		part_id: 77,
		quantity: 282,
	},
	{
		garage_id: 33,
		part_id: 78,
		quantity: 194,
	},
	{
		garage_id: 33,
		part_id: 79,
		quantity: 1,
	},
	{
		garage_id: 33,
		part_id: 80,
		quantity: 41,
	},
	{
		garage_id: 33,
		part_id: 81,
		quantity: 255,
	},
	{
		garage_id: 33,
		part_id: 82,
		quantity: 242,
	},
	{
		garage_id: 33,
		part_id: 83,
		quantity: 179,
	},
	{
		garage_id: 33,
		part_id: 84,
		quantity: 335,
	},
	{
		garage_id: 33,
		part_id: 85,
		quantity: 386,
	},
	{
		garage_id: 33,
		part_id: 86,
		quantity: 423,
	},
	{
		garage_id: 33,
		part_id: 87,
		quantity: 363,
	},
	{
		garage_id: 33,
		part_id: 88,
		quantity: 422,
	},
	{
		garage_id: 33,
		part_id: 89,
		quantity: 186,
	},
	{
		garage_id: 33,
		part_id: 90,
		quantity: 222,
	},
	{
		garage_id: 33,
		part_id: 91,
		quantity: 76,
	},
	{
		garage_id: 33,
		part_id: 92,
		quantity: 83,
	},
	{
		garage_id: 33,
		part_id: 93,
		quantity: 376,
	},
	{
		garage_id: 33,
		part_id: 94,
		quantity: 63,
	},
	{
		garage_id: 33,
		part_id: 95,
		quantity: 358,
	},
	{
		garage_id: 33,
		part_id: 96,
		quantity: 228,
	},
	{
		garage_id: 33,
		part_id: 97,
		quantity: 36,
	},
	{
		garage_id: 33,
		part_id: 98,
		quantity: 151,
	},
	{
		garage_id: 33,
		part_id: 99,
		quantity: 217,
	},
	{
		garage_id: 33,
		part_id: 100,
		quantity: 254,
	},
	{
		garage_id: 33,
		part_id: 101,
		quantity: 134,
	},
	{
		garage_id: 33,
		part_id: 102,
		quantity: 333,
	},
	{
		garage_id: 33,
		part_id: 103,
		quantity: 446,
	},
	{
		garage_id: 33,
		part_id: 104,
		quantity: 61,
	},
	{
		garage_id: 33,
		part_id: 105,
		quantity: 276,
	},
	{
		garage_id: 33,
		part_id: 106,
		quantity: 276,
	},
	{
		garage_id: 33,
		part_id: 107,
		quantity: 11,
	},
	{
		garage_id: 33,
		part_id: 108,
		quantity: 160,
	},
	{
		garage_id: 33,
		part_id: 109,
		quantity: 15,
	},
	{
		garage_id: 33,
		part_id: 110,
		quantity: 174,
	},
	{
		garage_id: 33,
		part_id: 111,
		quantity: 271,
	},
	{
		garage_id: 33,
		part_id: 112,
		quantity: 101,
	},
	{
		garage_id: 33,
		part_id: 113,
		quantity: 473,
	},
	{
		garage_id: 33,
		part_id: 114,
		quantity: 276,
	},
	{
		garage_id: 33,
		part_id: 115,
		quantity: 307,
	},
	{
		garage_id: 33,
		part_id: 116,
		quantity: 61,
	},
	{
		garage_id: 33,
		part_id: 117,
		quantity: 141,
	},
	{
		garage_id: 33,
		part_id: 118,
		quantity: 207,
	},
	{
		garage_id: 33,
		part_id: 119,
		quantity: 81,
	},
	{
		garage_id: 33,
		part_id: 120,
		quantity: 49,
	},
	{
		garage_id: 33,
		part_id: 121,
		quantity: 335,
	},
	{
		garage_id: 33,
		part_id: 122,
		quantity: 260,
	},
	{
		garage_id: 33,
		part_id: 123,
		quantity: 494,
	},
	{
		garage_id: 33,
		part_id: 124,
		quantity: 316,
	},
	{
		garage_id: 33,
		part_id: 125,
		quantity: 147,
	},
	{
		garage_id: 33,
		part_id: 126,
		quantity: 458,
	},
	{
		garage_id: 33,
		part_id: 127,
		quantity: 460,
	},
	{
		garage_id: 33,
		part_id: 128,
		quantity: 50,
	},
	{
		garage_id: 33,
		part_id: 129,
		quantity: 245,
	},
	{
		garage_id: 33,
		part_id: 130,
		quantity: 290,
	},
	{
		garage_id: 33,
		part_id: 131,
		quantity: 178,
	},
	{
		garage_id: 33,
		part_id: 132,
		quantity: 255,
	},
	{
		garage_id: 33,
		part_id: 133,
		quantity: 189,
	},
	{
		garage_id: 33,
		part_id: 134,
		quantity: 268,
	},
	{
		garage_id: 33,
		part_id: 135,
		quantity: 45,
	},
	{
		garage_id: 33,
		part_id: 136,
		quantity: 327,
	},
	{
		garage_id: 33,
		part_id: 137,
		quantity: 52,
	},
	{
		garage_id: 33,
		part_id: 138,
		quantity: 281,
	},
	{
		garage_id: 33,
		part_id: 139,
		quantity: 203,
	},
	{
		garage_id: 33,
		part_id: 140,
		quantity: 113,
	},
	{
		garage_id: 33,
		part_id: 141,
		quantity: 219,
	},
	{
		garage_id: 33,
		part_id: 142,
		quantity: 479,
	},
	{
		garage_id: 33,
		part_id: 143,
		quantity: 308,
	},
	{
		garage_id: 33,
		part_id: 144,
		quantity: 337,
	},
	{
		garage_id: 33,
		part_id: 145,
		quantity: 443,
	},
	{
		garage_id: 33,
		part_id: 146,
		quantity: 10,
	},
	{
		garage_id: 33,
		part_id: 147,
		quantity: 234,
	},
	{
		garage_id: 33,
		part_id: 148,
		quantity: 203,
	},
	{
		garage_id: 33,
		part_id: 149,
		quantity: 209,
	},
	{
		garage_id: 33,
		part_id: 150,
		quantity: 328,
	},
	{
		garage_id: 33,
		part_id: 151,
		quantity: 290,
	},
	{
		garage_id: 33,
		part_id: 152,
		quantity: 313,
	},
	{
		garage_id: 33,
		part_id: 153,
		quantity: 101,
	},
	{
		garage_id: 33,
		part_id: 154,
		quantity: 341,
	},
	{
		garage_id: 33,
		part_id: 155,
		quantity: 222,
	},
	{
		garage_id: 33,
		part_id: 156,
		quantity: 497,
	},
	{
		garage_id: 33,
		part_id: 157,
		quantity: 56,
	},
	{
		garage_id: 33,
		part_id: 158,
		quantity: 395,
	},
	{
		garage_id: 33,
		part_id: 159,
		quantity: 235,
	},
	{
		garage_id: 33,
		part_id: 160,
		quantity: 374,
	},
	{
		garage_id: 33,
		part_id: 161,
		quantity: 440,
	},
	{
		garage_id: 33,
		part_id: 162,
		quantity: 102,
	},
	{
		garage_id: 33,
		part_id: 163,
		quantity: 75,
	},
	{
		garage_id: 33,
		part_id: 164,
		quantity: 379,
	},
	{
		garage_id: 33,
		part_id: 165,
		quantity: 325,
	},
	{
		garage_id: 33,
		part_id: 166,
		quantity: 240,
	},
	{
		garage_id: 33,
		part_id: 167,
		quantity: 51,
	},
	{
		garage_id: 33,
		part_id: 168,
		quantity: 154,
	},
	{
		garage_id: 33,
		part_id: 169,
		quantity: 182,
	},
	{
		garage_id: 33,
		part_id: 170,
		quantity: 277,
	},
	{
		garage_id: 33,
		part_id: 171,
		quantity: 402,
	},
	{
		garage_id: 33,
		part_id: 172,
		quantity: 363,
	},
	{
		garage_id: 33,
		part_id: 173,
		quantity: 108,
	},
	{
		garage_id: 33,
		part_id: 174,
		quantity: 291,
	},
	{
		garage_id: 33,
		part_id: 175,
		quantity: 416,
	},
	{
		garage_id: 33,
		part_id: 176,
		quantity: 42,
	},
	{
		garage_id: 33,
		part_id: 177,
		quantity: 276,
	},
	{
		garage_id: 33,
		part_id: 178,
		quantity: 473,
	},
	{
		garage_id: 33,
		part_id: 179,
		quantity: 339,
	},
	{
		garage_id: 33,
		part_id: 180,
		quantity: 418,
	},
	{
		garage_id: 33,
		part_id: 181,
		quantity: 430,
	},
	{
		garage_id: 33,
		part_id: 182,
		quantity: 236,
	},
	{
		garage_id: 33,
		part_id: 183,
		quantity: 118,
	},
	{
		garage_id: 33,
		part_id: 184,
		quantity: 77,
	},
	{
		garage_id: 33,
		part_id: 185,
		quantity: 412,
	},
	{
		garage_id: 33,
		part_id: 186,
		quantity: 166,
	},
	{
		garage_id: 33,
		part_id: 187,
		quantity: 36,
	},
	{
		garage_id: 33,
		part_id: 188,
		quantity: 137,
	},
	{
		garage_id: 33,
		part_id: 189,
		quantity: 164,
	},
	{
		garage_id: 33,
		part_id: 190,
		quantity: 458,
	},
	{
		garage_id: 33,
		part_id: 191,
		quantity: 145,
	},
	{
		garage_id: 33,
		part_id: 192,
		quantity: 213,
	},
	{
		garage_id: 33,
		part_id: 193,
		quantity: 456,
	},
	{
		garage_id: 33,
		part_id: 194,
		quantity: 9,
	},
	{
		garage_id: 33,
		part_id: 195,
		quantity: 219,
	},
	{
		garage_id: 33,
		part_id: 196,
		quantity: 341,
	},
	{
		garage_id: 33,
		part_id: 197,
		quantity: 24,
	},
	{
		garage_id: 33,
		part_id: 198,
		quantity: 481,
	},
	{
		garage_id: 33,
		part_id: 199,
		quantity: 238,
	},
	{
		garage_id: 33,
		part_id: 200,
		quantity: 400,
	},
	{
		garage_id: 33,
		part_id: 201,
		quantity: 220,
	},
	{
		garage_id: 33,
		part_id: 202,
		quantity: 135,
	},
	{
		garage_id: 33,
		part_id: 203,
		quantity: 472,
	},
	{
		garage_id: 33,
		part_id: 204,
		quantity: 462,
	},
	{
		garage_id: 33,
		part_id: 205,
		quantity: 481,
	},
	{
		garage_id: 33,
		part_id: 206,
		quantity: 328,
	},
	{
		garage_id: 33,
		part_id: 207,
		quantity: 312,
	},
	{
		garage_id: 33,
		part_id: 208,
		quantity: 12,
	},
	{
		garage_id: 33,
		part_id: 209,
		quantity: 113,
	},
	{
		garage_id: 33,
		part_id: 210,
		quantity: 359,
	},
	{
		garage_id: 33,
		part_id: 211,
		quantity: 471,
	},
	{
		garage_id: 33,
		part_id: 212,
		quantity: 241,
	},
	{
		garage_id: 33,
		part_id: 213,
		quantity: 290,
	},
	{
		garage_id: 33,
		part_id: 214,
		quantity: 418,
	},
	{
		garage_id: 33,
		part_id: 215,
		quantity: 36,
	},
	{
		garage_id: 33,
		part_id: 216,
		quantity: 264,
	},
	{
		garage_id: 33,
		part_id: 217,
		quantity: 311,
	},
	{
		garage_id: 33,
		part_id: 218,
		quantity: 159,
	},
	{
		garage_id: 33,
		part_id: 219,
		quantity: 155,
	},
	{
		garage_id: 33,
		part_id: 220,
		quantity: 307,
	},
	{
		garage_id: 33,
		part_id: 221,
		quantity: 315,
	},
	{
		garage_id: 33,
		part_id: 222,
		quantity: 253,
	},
	{
		garage_id: 33,
		part_id: 223,
		quantity: 19,
	},
	{
		garage_id: 33,
		part_id: 224,
		quantity: 234,
	},
	{
		garage_id: 33,
		part_id: 225,
		quantity: 13,
	},
	{
		garage_id: 33,
		part_id: 226,
		quantity: 158,
	},
	{
		garage_id: 33,
		part_id: 227,
		quantity: 393,
	},
	{
		garage_id: 33,
		part_id: 228,
		quantity: 172,
	},
	{
		garage_id: 33,
		part_id: 229,
		quantity: 467,
	},
	{
		garage_id: 33,
		part_id: 230,
		quantity: 362,
	},
	{
		garage_id: 33,
		part_id: 231,
		quantity: 141,
	},
	{
		garage_id: 33,
		part_id: 232,
		quantity: 286,
	},
	{
		garage_id: 33,
		part_id: 233,
		quantity: 405,
	},
	{
		garage_id: 33,
		part_id: 234,
		quantity: 403,
	},
	{
		garage_id: 33,
		part_id: 235,
		quantity: 89,
	},
	{
		garage_id: 33,
		part_id: 236,
		quantity: 62,
	},
	{
		garage_id: 33,
		part_id: 237,
		quantity: 120,
	},
	{
		garage_id: 33,
		part_id: 238,
		quantity: 167,
	},
	{
		garage_id: 33,
		part_id: 239,
		quantity: 474,
	},
	{
		garage_id: 33,
		part_id: 240,
		quantity: 341,
	},
	{
		garage_id: 33,
		part_id: 241,
		quantity: 144,
	},
	{
		garage_id: 33,
		part_id: 242,
		quantity: 351,
	},
	{
		garage_id: 33,
		part_id: 243,
		quantity: 485,
	},
	{
		garage_id: 33,
		part_id: 244,
		quantity: 297,
	},
	{
		garage_id: 33,
		part_id: 245,
		quantity: 487,
	},
	{
		garage_id: 33,
		part_id: 246,
		quantity: 243,
	},
	{
		garage_id: 33,
		part_id: 247,
		quantity: 399,
	},
	{
		garage_id: 33,
		part_id: 248,
		quantity: 362,
	},
	{
		garage_id: 33,
		part_id: 249,
		quantity: 181,
	},
	{
		garage_id: 33,
		part_id: 250,
		quantity: 288,
	},
	{
		garage_id: 33,
		part_id: 251,
		quantity: 362,
	},
	{
		garage_id: 33,
		part_id: 252,
		quantity: 297,
	},
	{
		garage_id: 33,
		part_id: 253,
		quantity: 421,
	},
	{
		garage_id: 33,
		part_id: 254,
		quantity: 97,
	},
	{
		garage_id: 33,
		part_id: 255,
		quantity: 190,
	},
	{
		garage_id: 33,
		part_id: 256,
		quantity: 101,
	},
	{
		garage_id: 33,
		part_id: 257,
		quantity: 256,
	},
	{
		garage_id: 33,
		part_id: 258,
		quantity: 157,
	},
	{
		garage_id: 33,
		part_id: 259,
		quantity: 288,
	},
	{
		garage_id: 33,
		part_id: 260,
		quantity: 444,
	},
	{
		garage_id: 33,
		part_id: 261,
		quantity: 214,
	},
	{
		garage_id: 33,
		part_id: 262,
		quantity: 388,
	},
	{
		garage_id: 33,
		part_id: 263,
		quantity: 42,
	},
	{
		garage_id: 33,
		part_id: 264,
		quantity: 89,
	},
	{
		garage_id: 33,
		part_id: 265,
		quantity: 394,
	},
	{
		garage_id: 33,
		part_id: 266,
		quantity: 326,
	},
	{
		garage_id: 33,
		part_id: 267,
		quantity: 429,
	},
	{
		garage_id: 33,
		part_id: 268,
		quantity: 356,
	},
	{
		garage_id: 33,
		part_id: 269,
		quantity: 379,
	},
	{
		garage_id: 33,
		part_id: 270,
		quantity: 432,
	},
	{
		garage_id: 33,
		part_id: 271,
		quantity: 175,
	},
	{
		garage_id: 33,
		part_id: 272,
		quantity: 167,
	},
	{
		garage_id: 33,
		part_id: 273,
		quantity: 174,
	},
	{
		garage_id: 33,
		part_id: 274,
		quantity: 338,
	},
	{
		garage_id: 33,
		part_id: 275,
		quantity: 310,
	},
	{
		garage_id: 33,
		part_id: 276,
		quantity: 435,
	},
	{
		garage_id: 33,
		part_id: 277,
		quantity: 91,
	},
	{
		garage_id: 33,
		part_id: 278,
		quantity: 95,
	},
	{
		garage_id: 33,
		part_id: 279,
		quantity: 37,
	},
	{
		garage_id: 33,
		part_id: 280,
		quantity: 356,
	},
	{
		garage_id: 33,
		part_id: 281,
		quantity: 154,
	},
	{
		garage_id: 33,
		part_id: 282,
		quantity: 204,
	},
	{
		garage_id: 33,
		part_id: 283,
		quantity: 101,
	},
	{
		garage_id: 33,
		part_id: 284,
		quantity: 328,
	},
	{
		garage_id: 33,
		part_id: 285,
		quantity: 469,
	},
	{
		garage_id: 33,
		part_id: 286,
		quantity: 387,
	},
	{
		garage_id: 33,
		part_id: 287,
		quantity: 361,
	},
	{
		garage_id: 33,
		part_id: 288,
		quantity: 67,
	},
	{
		garage_id: 33,
		part_id: 289,
		quantity: 417,
	},
	{
		garage_id: 33,
		part_id: 290,
		quantity: 451,
	},
	{
		garage_id: 33,
		part_id: 291,
		quantity: 472,
	},
	{
		garage_id: 33,
		part_id: 292,
		quantity: 274,
	},
	{
		garage_id: 33,
		part_id: 293,
		quantity: 240,
	},
	{
		garage_id: 33,
		part_id: 294,
		quantity: 416,
	},
	{
		garage_id: 33,
		part_id: 295,
		quantity: 279,
	},
	{
		garage_id: 33,
		part_id: 296,
		quantity: 355,
	},
	{
		garage_id: 33,
		part_id: 297,
		quantity: 446,
	},
	{
		garage_id: 33,
		part_id: 298,
		quantity: 456,
	},
	{
		garage_id: 33,
		part_id: 299,
		quantity: 63,
	},
	{
		garage_id: 33,
		part_id: 300,
		quantity: 474,
	},
	{
		garage_id: 33,
		part_id: 301,
		quantity: 38,
	},
	{
		garage_id: 33,
		part_id: 302,
		quantity: 216,
	},
	{
		garage_id: 33,
		part_id: 303,
		quantity: 66,
	},
	{
		garage_id: 33,
		part_id: 304,
		quantity: 165,
	},
	{
		garage_id: 33,
		part_id: 305,
		quantity: 325,
	},
	{
		garage_id: 33,
		part_id: 306,
		quantity: 442,
	},
	{
		garage_id: 33,
		part_id: 307,
		quantity: 308,
	},
	{
		garage_id: 33,
		part_id: 308,
		quantity: 443,
	},
	{
		garage_id: 33,
		part_id: 309,
		quantity: 277,
	},
	{
		garage_id: 33,
		part_id: 310,
		quantity: 331,
	},
	{
		garage_id: 33,
		part_id: 311,
		quantity: 118,
	},
	{
		garage_id: 33,
		part_id: 312,
		quantity: 333,
	},
	{
		garage_id: 33,
		part_id: 313,
		quantity: 391,
	},
	{
		garage_id: 33,
		part_id: 314,
		quantity: 415,
	},
	{
		garage_id: 33,
		part_id: 315,
		quantity: 295,
	},
	{
		garage_id: 33,
		part_id: 316,
		quantity: 52,
	},
	{
		garage_id: 33,
		part_id: 317,
		quantity: 111,
	},
	{
		garage_id: 33,
		part_id: 318,
		quantity: 178,
	},
	{
		garage_id: 33,
		part_id: 319,
		quantity: 29,
	},
	{
		garage_id: 33,
		part_id: 320,
		quantity: 258,
	},
	{
		garage_id: 33,
		part_id: 321,
		quantity: 439,
	},
	{
		garage_id: 33,
		part_id: 322,
		quantity: 481,
	},
	{
		garage_id: 33,
		part_id: 323,
		quantity: 124,
	},
	{
		garage_id: 33,
		part_id: 324,
		quantity: 80,
	},
	{
		garage_id: 33,
		part_id: 325,
		quantity: 466,
	},
	{
		garage_id: 33,
		part_id: 326,
		quantity: 328,
	},
	{
		garage_id: 33,
		part_id: 327,
		quantity: 102,
	},
	{
		garage_id: 33,
		part_id: 328,
		quantity: 400,
	},
	{
		garage_id: 33,
		part_id: 329,
		quantity: 74,
	},
	{
		garage_id: 33,
		part_id: 330,
		quantity: 116,
	},
	{
		garage_id: 33,
		part_id: 331,
		quantity: 345,
	},
	{
		garage_id: 33,
		part_id: 332,
		quantity: 168,
	},
	{
		garage_id: 33,
		part_id: 333,
		quantity: 302,
	},
	{
		garage_id: 33,
		part_id: 334,
		quantity: 40,
	},
	{
		garage_id: 33,
		part_id: 335,
		quantity: 373,
	},
	{
		garage_id: 33,
		part_id: 336,
		quantity: 210,
	},
	{
		garage_id: 33,
		part_id: 337,
		quantity: 325,
	},
	{
		garage_id: 33,
		part_id: 338,
		quantity: 197,
	},
	{
		garage_id: 33,
		part_id: 339,
		quantity: 337,
	},
	{
		garage_id: 33,
		part_id: 340,
		quantity: 449,
	},
	{
		garage_id: 33,
		part_id: 341,
		quantity: 433,
	},
	{
		garage_id: 33,
		part_id: 342,
		quantity: 52,
	},
	{
		garage_id: 33,
		part_id: 343,
		quantity: 28,
	},
	{
		garage_id: 33,
		part_id: 344,
		quantity: 239,
	},
	{
		garage_id: 33,
		part_id: 345,
		quantity: 486,
	},
	{
		garage_id: 33,
		part_id: 346,
		quantity: 448,
	},
	{
		garage_id: 33,
		part_id: 347,
		quantity: 146,
	},
	{
		garage_id: 33,
		part_id: 348,
		quantity: 196,
	},
	{
		garage_id: 33,
		part_id: 349,
		quantity: 148,
	},
	{
		garage_id: 33,
		part_id: 350,
		quantity: 385,
	},
	{
		garage_id: 33,
		part_id: 351,
		quantity: 129,
	},
	{
		garage_id: 33,
		part_id: 352,
		quantity: 108,
	},
	{
		garage_id: 33,
		part_id: 353,
		quantity: 314,
	},
	{
		garage_id: 33,
		part_id: 354,
		quantity: 402,
	},
	{
		garage_id: 33,
		part_id: 355,
		quantity: 318,
	},
	{
		garage_id: 33,
		part_id: 356,
		quantity: 397,
	},
	{
		garage_id: 33,
		part_id: 357,
		quantity: 71,
	},
	{
		garage_id: 33,
		part_id: 358,
		quantity: 5,
	},
	{
		garage_id: 33,
		part_id: 359,
		quantity: 431,
	},
	{
		garage_id: 33,
		part_id: 360,
		quantity: 416,
	},
	{
		garage_id: 33,
		part_id: 361,
		quantity: 218,
	},
	{
		garage_id: 33,
		part_id: 362,
		quantity: 62,
	},
	{
		garage_id: 33,
		part_id: 363,
		quantity: 407,
	},
	{
		garage_id: 33,
		part_id: 364,
		quantity: 34,
	},
	{
		garage_id: 33,
		part_id: 365,
		quantity: 28,
	},
	{
		garage_id: 33,
		part_id: 366,
		quantity: 137,
	},
	{
		garage_id: 33,
		part_id: 367,
		quantity: 486,
	},
	{
		garage_id: 33,
		part_id: 368,
		quantity: 386,
	},
	{
		garage_id: 33,
		part_id: 369,
		quantity: 339,
	},
	{
		garage_id: 33,
		part_id: 370,
		quantity: 355,
	},
	{
		garage_id: 33,
		part_id: 371,
		quantity: 282,
	},
	{
		garage_id: 33,
		part_id: 372,
		quantity: 207,
	},
	{
		garage_id: 33,
		part_id: 373,
		quantity: 164,
	},
	{
		garage_id: 33,
		part_id: 374,
		quantity: 253,
	},
	{
		garage_id: 33,
		part_id: 375,
		quantity: 274,
	},
	{
		garage_id: 33,
		part_id: 376,
		quantity: 91,
	},
	{
		garage_id: 33,
		part_id: 377,
		quantity: 317,
	},
	{
		garage_id: 33,
		part_id: 378,
		quantity: 475,
	},
	{
		garage_id: 33,
		part_id: 379,
		quantity: 351,
	},
	{
		garage_id: 33,
		part_id: 380,
		quantity: 160,
	},
	{
		garage_id: 33,
		part_id: 381,
		quantity: 233,
	},
	{
		garage_id: 33,
		part_id: 382,
		quantity: 272,
	},
	{
		garage_id: 33,
		part_id: 383,
		quantity: 452,
	},
	{
		garage_id: 33,
		part_id: 384,
		quantity: 431,
	},
	{
		garage_id: 33,
		part_id: 385,
		quantity: 249,
	},
	{
		garage_id: 33,
		part_id: 386,
		quantity: 245,
	},
	{
		garage_id: 33,
		part_id: 387,
		quantity: 170,
	},
	{
		garage_id: 33,
		part_id: 388,
		quantity: 167,
	},
	{
		garage_id: 33,
		part_id: 389,
		quantity: 3,
	},
	{
		garage_id: 33,
		part_id: 390,
		quantity: 233,
	},
	{
		garage_id: 33,
		part_id: 391,
		quantity: 53,
	},
	{
		garage_id: 33,
		part_id: 392,
		quantity: 263,
	},
	{
		garage_id: 33,
		part_id: 393,
		quantity: 39,
	},
	{
		garage_id: 33,
		part_id: 394,
		quantity: 346,
	},
	{
		garage_id: 33,
		part_id: 395,
		quantity: 7,
	},
	{
		garage_id: 33,
		part_id: 396,
		quantity: 91,
	},
	{
		garage_id: 33,
		part_id: 397,
		quantity: 191,
	},
	{
		garage_id: 33,
		part_id: 398,
		quantity: 174,
	},
	{
		garage_id: 33,
		part_id: 399,
		quantity: 331,
	},
	{
		garage_id: 34,
		part_id: 0,
		quantity: 366,
	},
	{
		garage_id: 34,
		part_id: 1,
		quantity: 20,
	},
	{
		garage_id: 34,
		part_id: 2,
		quantity: 277,
	},
	{
		garage_id: 34,
		part_id: 3,
		quantity: 153,
	},
	{
		garage_id: 34,
		part_id: 4,
		quantity: 387,
	},
	{
		garage_id: 34,
		part_id: 5,
		quantity: 116,
	},
	{
		garage_id: 34,
		part_id: 6,
		quantity: 83,
	},
	{
		garage_id: 34,
		part_id: 7,
		quantity: 101,
	},
	{
		garage_id: 34,
		part_id: 8,
		quantity: 148,
	},
	{
		garage_id: 34,
		part_id: 9,
		quantity: 139,
	},
	{
		garage_id: 34,
		part_id: 10,
		quantity: 296,
	},
	{
		garage_id: 34,
		part_id: 11,
		quantity: 238,
	},
	{
		garage_id: 34,
		part_id: 12,
		quantity: 400,
	},
	{
		garage_id: 34,
		part_id: 13,
		quantity: 80,
	},
	{
		garage_id: 34,
		part_id: 14,
		quantity: 251,
	},
	{
		garage_id: 34,
		part_id: 15,
		quantity: 419,
	},
	{
		garage_id: 34,
		part_id: 16,
		quantity: 179,
	},
	{
		garage_id: 34,
		part_id: 17,
		quantity: 329,
	},
	{
		garage_id: 34,
		part_id: 18,
		quantity: 467,
	},
	{
		garage_id: 34,
		part_id: 19,
		quantity: 188,
	},
	{
		garage_id: 34,
		part_id: 20,
		quantity: 166,
	},
	{
		garage_id: 34,
		part_id: 21,
		quantity: 220,
	},
	{
		garage_id: 34,
		part_id: 22,
		quantity: 231,
	},
	{
		garage_id: 34,
		part_id: 23,
		quantity: 419,
	},
	{
		garage_id: 34,
		part_id: 24,
		quantity: 344,
	},
	{
		garage_id: 34,
		part_id: 25,
		quantity: 363,
	},
	{
		garage_id: 34,
		part_id: 26,
		quantity: 31,
	},
	{
		garage_id: 34,
		part_id: 27,
		quantity: 247,
	},
	{
		garage_id: 34,
		part_id: 28,
		quantity: 410,
	},
	{
		garage_id: 34,
		part_id: 29,
		quantity: 31,
	},
	{
		garage_id: 34,
		part_id: 30,
		quantity: 300,
	},
	{
		garage_id: 34,
		part_id: 31,
		quantity: 43,
	},
	{
		garage_id: 34,
		part_id: 32,
		quantity: 364,
	},
	{
		garage_id: 34,
		part_id: 33,
		quantity: 329,
	},
	{
		garage_id: 34,
		part_id: 34,
		quantity: 213,
	},
	{
		garage_id: 34,
		part_id: 35,
		quantity: 38,
	},
	{
		garage_id: 34,
		part_id: 36,
		quantity: 246,
	},
	{
		garage_id: 34,
		part_id: 37,
		quantity: 21,
	},
	{
		garage_id: 34,
		part_id: 38,
		quantity: 475,
	},
	{
		garage_id: 34,
		part_id: 39,
		quantity: 401,
	},
	{
		garage_id: 34,
		part_id: 40,
		quantity: 272,
	},
	{
		garage_id: 34,
		part_id: 41,
		quantity: 414,
	},
	{
		garage_id: 34,
		part_id: 42,
		quantity: 469,
	},
	{
		garage_id: 34,
		part_id: 43,
		quantity: 436,
	},
	{
		garage_id: 34,
		part_id: 44,
		quantity: 455,
	},
	{
		garage_id: 34,
		part_id: 45,
		quantity: 380,
	},
	{
		garage_id: 34,
		part_id: 46,
		quantity: 141,
	},
	{
		garage_id: 34,
		part_id: 47,
		quantity: 491,
	},
	{
		garage_id: 34,
		part_id: 48,
		quantity: 422,
	},
	{
		garage_id: 34,
		part_id: 49,
		quantity: 499,
	},
	{
		garage_id: 34,
		part_id: 50,
		quantity: 477,
	},
	{
		garage_id: 34,
		part_id: 51,
		quantity: 222,
	},
	{
		garage_id: 34,
		part_id: 52,
		quantity: 15,
	},
	{
		garage_id: 34,
		part_id: 53,
		quantity: 311,
	},
	{
		garage_id: 34,
		part_id: 54,
		quantity: 151,
	},
	{
		garage_id: 34,
		part_id: 55,
		quantity: 494,
	},
	{
		garage_id: 34,
		part_id: 56,
		quantity: 397,
	},
	{
		garage_id: 34,
		part_id: 57,
		quantity: 177,
	},
	{
		garage_id: 34,
		part_id: 58,
		quantity: 202,
	},
	{
		garage_id: 34,
		part_id: 59,
		quantity: 248,
	},
	{
		garage_id: 34,
		part_id: 60,
		quantity: 186,
	},
	{
		garage_id: 34,
		part_id: 61,
		quantity: 284,
	},
	{
		garage_id: 34,
		part_id: 62,
		quantity: 10,
	},
	{
		garage_id: 34,
		part_id: 63,
		quantity: 410,
	},
	{
		garage_id: 34,
		part_id: 64,
		quantity: 330,
	},
	{
		garage_id: 34,
		part_id: 65,
		quantity: 297,
	},
	{
		garage_id: 34,
		part_id: 66,
		quantity: 383,
	},
	{
		garage_id: 34,
		part_id: 67,
		quantity: 159,
	},
	{
		garage_id: 34,
		part_id: 68,
		quantity: 457,
	},
	{
		garage_id: 34,
		part_id: 69,
		quantity: 279,
	},
	{
		garage_id: 34,
		part_id: 70,
		quantity: 111,
	},
	{
		garage_id: 34,
		part_id: 71,
		quantity: 350,
	},
	{
		garage_id: 34,
		part_id: 72,
		quantity: 11,
	},
	{
		garage_id: 34,
		part_id: 73,
		quantity: 465,
	},
	{
		garage_id: 34,
		part_id: 74,
		quantity: 33,
	},
	{
		garage_id: 34,
		part_id: 75,
		quantity: 103,
	},
	{
		garage_id: 34,
		part_id: 76,
		quantity: 102,
	},
	{
		garage_id: 34,
		part_id: 77,
		quantity: 306,
	},
	{
		garage_id: 34,
		part_id: 78,
		quantity: 436,
	},
	{
		garage_id: 34,
		part_id: 79,
		quantity: 173,
	},
	{
		garage_id: 34,
		part_id: 80,
		quantity: 453,
	},
	{
		garage_id: 34,
		part_id: 81,
		quantity: 293,
	},
	{
		garage_id: 34,
		part_id: 82,
		quantity: 21,
	},
	{
		garage_id: 34,
		part_id: 83,
		quantity: 238,
	},
	{
		garage_id: 34,
		part_id: 84,
		quantity: 346,
	},
	{
		garage_id: 34,
		part_id: 85,
		quantity: 172,
	},
	{
		garage_id: 34,
		part_id: 86,
		quantity: 151,
	},
	{
		garage_id: 34,
		part_id: 87,
		quantity: 446,
	},
	{
		garage_id: 34,
		part_id: 88,
		quantity: 357,
	},
	{
		garage_id: 34,
		part_id: 89,
		quantity: 372,
	},
	{
		garage_id: 34,
		part_id: 90,
		quantity: 43,
	},
	{
		garage_id: 34,
		part_id: 91,
		quantity: 37,
	},
	{
		garage_id: 34,
		part_id: 92,
		quantity: 458,
	},
	{
		garage_id: 34,
		part_id: 93,
		quantity: 286,
	},
	{
		garage_id: 34,
		part_id: 94,
		quantity: 396,
	},
	{
		garage_id: 34,
		part_id: 95,
		quantity: 97,
	},
	{
		garage_id: 34,
		part_id: 96,
		quantity: 78,
	},
	{
		garage_id: 34,
		part_id: 97,
		quantity: 145,
	},
	{
		garage_id: 34,
		part_id: 98,
		quantity: 58,
	},
	{
		garage_id: 34,
		part_id: 99,
		quantity: 10,
	},
	{
		garage_id: 34,
		part_id: 100,
		quantity: 475,
	},
	{
		garage_id: 34,
		part_id: 101,
		quantity: 362,
	},
	{
		garage_id: 34,
		part_id: 102,
		quantity: 67,
	},
	{
		garage_id: 34,
		part_id: 103,
		quantity: 388,
	},
	{
		garage_id: 34,
		part_id: 104,
		quantity: 139,
	},
	{
		garage_id: 34,
		part_id: 105,
		quantity: 216,
	},
	{
		garage_id: 34,
		part_id: 106,
		quantity: 150,
	},
	{
		garage_id: 34,
		part_id: 107,
		quantity: 5,
	},
	{
		garage_id: 34,
		part_id: 108,
		quantity: 193,
	},
	{
		garage_id: 34,
		part_id: 109,
		quantity: 114,
	},
	{
		garage_id: 34,
		part_id: 110,
		quantity: 149,
	},
	{
		garage_id: 34,
		part_id: 111,
		quantity: 313,
	},
	{
		garage_id: 34,
		part_id: 112,
		quantity: 268,
	},
	{
		garage_id: 34,
		part_id: 113,
		quantity: 418,
	},
	{
		garage_id: 34,
		part_id: 114,
		quantity: 426,
	},
	{
		garage_id: 34,
		part_id: 115,
		quantity: 325,
	},
	{
		garage_id: 34,
		part_id: 116,
		quantity: 325,
	},
	{
		garage_id: 34,
		part_id: 117,
		quantity: 309,
	},
	{
		garage_id: 34,
		part_id: 118,
		quantity: 26,
	},
	{
		garage_id: 34,
		part_id: 119,
		quantity: 332,
	},
	{
		garage_id: 34,
		part_id: 120,
		quantity: 128,
	},
	{
		garage_id: 34,
		part_id: 121,
		quantity: 474,
	},
	{
		garage_id: 34,
		part_id: 122,
		quantity: 32,
	},
	{
		garage_id: 34,
		part_id: 123,
		quantity: 311,
	},
	{
		garage_id: 34,
		part_id: 124,
		quantity: 20,
	},
	{
		garage_id: 34,
		part_id: 125,
		quantity: 369,
	},
	{
		garage_id: 34,
		part_id: 126,
		quantity: 71,
	},
	{
		garage_id: 34,
		part_id: 127,
		quantity: 185,
	},
	{
		garage_id: 34,
		part_id: 128,
		quantity: 47,
	},
	{
		garage_id: 34,
		part_id: 129,
		quantity: 51,
	},
	{
		garage_id: 34,
		part_id: 130,
		quantity: 290,
	},
	{
		garage_id: 34,
		part_id: 131,
		quantity: 6,
	},
	{
		garage_id: 34,
		part_id: 132,
		quantity: 72,
	},
	{
		garage_id: 34,
		part_id: 133,
		quantity: 151,
	},
	{
		garage_id: 34,
		part_id: 134,
		quantity: 475,
	},
	{
		garage_id: 34,
		part_id: 135,
		quantity: 142,
	},
	{
		garage_id: 34,
		part_id: 136,
		quantity: 423,
	},
	{
		garage_id: 34,
		part_id: 137,
		quantity: 459,
	},
	{
		garage_id: 34,
		part_id: 138,
		quantity: 434,
	},
	{
		garage_id: 34,
		part_id: 139,
		quantity: 496,
	},
	{
		garage_id: 34,
		part_id: 140,
		quantity: 40,
	},
	{
		garage_id: 34,
		part_id: 141,
		quantity: 96,
	},
	{
		garage_id: 34,
		part_id: 142,
		quantity: 201,
	},
	{
		garage_id: 34,
		part_id: 143,
		quantity: 361,
	},
	{
		garage_id: 34,
		part_id: 144,
		quantity: 41,
	},
	{
		garage_id: 34,
		part_id: 145,
		quantity: 215,
	},
	{
		garage_id: 34,
		part_id: 146,
		quantity: 239,
	},
	{
		garage_id: 34,
		part_id: 147,
		quantity: 70,
	},
	{
		garage_id: 34,
		part_id: 148,
		quantity: 443,
	},
	{
		garage_id: 34,
		part_id: 149,
		quantity: 220,
	},
	{
		garage_id: 34,
		part_id: 150,
		quantity: 268,
	},
	{
		garage_id: 34,
		part_id: 151,
		quantity: 334,
	},
	{
		garage_id: 34,
		part_id: 152,
		quantity: 469,
	},
	{
		garage_id: 34,
		part_id: 153,
		quantity: 244,
	},
	{
		garage_id: 34,
		part_id: 154,
		quantity: 240,
	},
	{
		garage_id: 34,
		part_id: 155,
		quantity: 436,
	},
	{
		garage_id: 34,
		part_id: 156,
		quantity: 431,
	},
	{
		garage_id: 34,
		part_id: 157,
		quantity: 93,
	},
	{
		garage_id: 34,
		part_id: 158,
		quantity: 445,
	},
	{
		garage_id: 34,
		part_id: 159,
		quantity: 0,
	},
	{
		garage_id: 34,
		part_id: 160,
		quantity: 385,
	},
	{
		garage_id: 34,
		part_id: 161,
		quantity: 336,
	},
	{
		garage_id: 34,
		part_id: 162,
		quantity: 253,
	},
	{
		garage_id: 34,
		part_id: 163,
		quantity: 461,
	},
	{
		garage_id: 34,
		part_id: 164,
		quantity: 366,
	},
	{
		garage_id: 34,
		part_id: 165,
		quantity: 378,
	},
	{
		garage_id: 34,
		part_id: 166,
		quantity: 287,
	},
	{
		garage_id: 34,
		part_id: 167,
		quantity: 406,
	},
	{
		garage_id: 34,
		part_id: 168,
		quantity: 127,
	},
	{
		garage_id: 34,
		part_id: 169,
		quantity: 323,
	},
	{
		garage_id: 34,
		part_id: 170,
		quantity: 65,
	},
	{
		garage_id: 34,
		part_id: 171,
		quantity: 148,
	},
	{
		garage_id: 34,
		part_id: 172,
		quantity: 466,
	},
	{
		garage_id: 34,
		part_id: 173,
		quantity: 276,
	},
	{
		garage_id: 34,
		part_id: 174,
		quantity: 111,
	},
	{
		garage_id: 34,
		part_id: 175,
		quantity: 181,
	},
	{
		garage_id: 34,
		part_id: 176,
		quantity: 209,
	},
	{
		garage_id: 34,
		part_id: 177,
		quantity: 414,
	},
	{
		garage_id: 34,
		part_id: 178,
		quantity: 58,
	},
	{
		garage_id: 34,
		part_id: 179,
		quantity: 51,
	},
	{
		garage_id: 34,
		part_id: 180,
		quantity: 483,
	},
	{
		garage_id: 34,
		part_id: 181,
		quantity: 198,
	},
	{
		garage_id: 34,
		part_id: 182,
		quantity: 370,
	},
	{
		garage_id: 34,
		part_id: 183,
		quantity: 353,
	},
	{
		garage_id: 34,
		part_id: 184,
		quantity: 321,
	},
	{
		garage_id: 34,
		part_id: 185,
		quantity: 62,
	},
	{
		garage_id: 34,
		part_id: 186,
		quantity: 230,
	},
	{
		garage_id: 34,
		part_id: 187,
		quantity: 113,
	},
	{
		garage_id: 34,
		part_id: 188,
		quantity: 119,
	},
	{
		garage_id: 34,
		part_id: 189,
		quantity: 331,
	},
	{
		garage_id: 34,
		part_id: 190,
		quantity: 262,
	},
	{
		garage_id: 34,
		part_id: 191,
		quantity: 239,
	},
	{
		garage_id: 34,
		part_id: 192,
		quantity: 481,
	},
	{
		garage_id: 34,
		part_id: 193,
		quantity: 353,
	},
	{
		garage_id: 34,
		part_id: 194,
		quantity: 46,
	},
	{
		garage_id: 34,
		part_id: 195,
		quantity: 289,
	},
	{
		garage_id: 34,
		part_id: 196,
		quantity: 61,
	},
	{
		garage_id: 34,
		part_id: 197,
		quantity: 264,
	},
	{
		garage_id: 34,
		part_id: 198,
		quantity: 483,
	},
	{
		garage_id: 34,
		part_id: 199,
		quantity: 392,
	},
	{
		garage_id: 34,
		part_id: 200,
		quantity: 122,
	},
	{
		garage_id: 34,
		part_id: 201,
		quantity: 259,
	},
	{
		garage_id: 34,
		part_id: 202,
		quantity: 238,
	},
	{
		garage_id: 34,
		part_id: 203,
		quantity: 281,
	},
	{
		garage_id: 34,
		part_id: 204,
		quantity: 124,
	},
	{
		garage_id: 34,
		part_id: 205,
		quantity: 96,
	},
	{
		garage_id: 34,
		part_id: 206,
		quantity: 87,
	},
	{
		garage_id: 34,
		part_id: 207,
		quantity: 223,
	},
	{
		garage_id: 34,
		part_id: 208,
		quantity: 261,
	},
	{
		garage_id: 34,
		part_id: 209,
		quantity: 243,
	},
	{
		garage_id: 34,
		part_id: 210,
		quantity: 368,
	},
	{
		garage_id: 34,
		part_id: 211,
		quantity: 209,
	},
	{
		garage_id: 34,
		part_id: 212,
		quantity: 410,
	},
	{
		garage_id: 34,
		part_id: 213,
		quantity: 109,
	},
	{
		garage_id: 34,
		part_id: 214,
		quantity: 230,
	},
	{
		garage_id: 34,
		part_id: 215,
		quantity: 238,
	},
	{
		garage_id: 34,
		part_id: 216,
		quantity: 122,
	},
	{
		garage_id: 34,
		part_id: 217,
		quantity: 29,
	},
	{
		garage_id: 34,
		part_id: 218,
		quantity: 203,
	},
	{
		garage_id: 34,
		part_id: 219,
		quantity: 16,
	},
	{
		garage_id: 34,
		part_id: 220,
		quantity: 377,
	},
	{
		garage_id: 34,
		part_id: 221,
		quantity: 99,
	},
	{
		garage_id: 34,
		part_id: 222,
		quantity: 202,
	},
	{
		garage_id: 34,
		part_id: 223,
		quantity: 357,
	},
	{
		garage_id: 34,
		part_id: 224,
		quantity: 60,
	},
	{
		garage_id: 34,
		part_id: 225,
		quantity: 247,
	},
	{
		garage_id: 34,
		part_id: 226,
		quantity: 461,
	},
	{
		garage_id: 34,
		part_id: 227,
		quantity: 288,
	},
	{
		garage_id: 34,
		part_id: 228,
		quantity: 67,
	},
	{
		garage_id: 34,
		part_id: 229,
		quantity: 265,
	},
	{
		garage_id: 34,
		part_id: 230,
		quantity: 215,
	},
	{
		garage_id: 34,
		part_id: 231,
		quantity: 4,
	},
	{
		garage_id: 34,
		part_id: 232,
		quantity: 436,
	},
	{
		garage_id: 34,
		part_id: 233,
		quantity: 454,
	},
	{
		garage_id: 34,
		part_id: 234,
		quantity: 465,
	},
	{
		garage_id: 34,
		part_id: 235,
		quantity: 349,
	},
	{
		garage_id: 34,
		part_id: 236,
		quantity: 462,
	},
	{
		garage_id: 34,
		part_id: 237,
		quantity: 157,
	},
	{
		garage_id: 34,
		part_id: 238,
		quantity: 228,
	},
	{
		garage_id: 34,
		part_id: 239,
		quantity: 238,
	},
	{
		garage_id: 34,
		part_id: 240,
		quantity: 456,
	},
	{
		garage_id: 34,
		part_id: 241,
		quantity: 121,
	},
	{
		garage_id: 34,
		part_id: 242,
		quantity: 466,
	},
	{
		garage_id: 34,
		part_id: 243,
		quantity: 66,
	},
	{
		garage_id: 34,
		part_id: 244,
		quantity: 248,
	},
	{
		garage_id: 34,
		part_id: 245,
		quantity: 493,
	},
	{
		garage_id: 34,
		part_id: 246,
		quantity: 362,
	},
	{
		garage_id: 34,
		part_id: 247,
		quantity: 179,
	},
	{
		garage_id: 34,
		part_id: 248,
		quantity: 92,
	},
	{
		garage_id: 34,
		part_id: 249,
		quantity: 268,
	},
	{
		garage_id: 34,
		part_id: 250,
		quantity: 323,
	},
	{
		garage_id: 34,
		part_id: 251,
		quantity: 372,
	},
	{
		garage_id: 34,
		part_id: 252,
		quantity: 264,
	},
	{
		garage_id: 34,
		part_id: 253,
		quantity: 79,
	},
	{
		garage_id: 34,
		part_id: 254,
		quantity: 395,
	},
	{
		garage_id: 34,
		part_id: 255,
		quantity: 5,
	},
	{
		garage_id: 34,
		part_id: 256,
		quantity: 303,
	},
	{
		garage_id: 34,
		part_id: 257,
		quantity: 176,
	},
	{
		garage_id: 34,
		part_id: 258,
		quantity: 27,
	},
	{
		garage_id: 34,
		part_id: 259,
		quantity: 317,
	},
	{
		garage_id: 34,
		part_id: 260,
		quantity: 467,
	},
	{
		garage_id: 34,
		part_id: 261,
		quantity: 238,
	},
	{
		garage_id: 34,
		part_id: 262,
		quantity: 487,
	},
	{
		garage_id: 34,
		part_id: 263,
		quantity: 156,
	},
	{
		garage_id: 34,
		part_id: 264,
		quantity: 429,
	},
	{
		garage_id: 34,
		part_id: 265,
		quantity: 303,
	},
	{
		garage_id: 34,
		part_id: 266,
		quantity: 470,
	},
	{
		garage_id: 34,
		part_id: 267,
		quantity: 312,
	},
	{
		garage_id: 34,
		part_id: 268,
		quantity: 356,
	},
	{
		garage_id: 34,
		part_id: 269,
		quantity: 435,
	},
	{
		garage_id: 34,
		part_id: 270,
		quantity: 2,
	},
	{
		garage_id: 34,
		part_id: 271,
		quantity: 169,
	},
	{
		garage_id: 34,
		part_id: 272,
		quantity: 259,
	},
	{
		garage_id: 34,
		part_id: 273,
		quantity: 21,
	},
	{
		garage_id: 34,
		part_id: 274,
		quantity: 290,
	},
	{
		garage_id: 34,
		part_id: 275,
		quantity: 285,
	},
	{
		garage_id: 34,
		part_id: 276,
		quantity: 321,
	},
	{
		garage_id: 34,
		part_id: 277,
		quantity: 240,
	},
	{
		garage_id: 34,
		part_id: 278,
		quantity: 30,
	},
	{
		garage_id: 34,
		part_id: 279,
		quantity: 325,
	},
	{
		garage_id: 34,
		part_id: 280,
		quantity: 236,
	},
	{
		garage_id: 34,
		part_id: 281,
		quantity: 396,
	},
	{
		garage_id: 34,
		part_id: 282,
		quantity: 392,
	},
	{
		garage_id: 34,
		part_id: 283,
		quantity: 457,
	},
	{
		garage_id: 34,
		part_id: 284,
		quantity: 304,
	},
	{
		garage_id: 34,
		part_id: 285,
		quantity: 470,
	},
	{
		garage_id: 34,
		part_id: 286,
		quantity: 35,
	},
	{
		garage_id: 34,
		part_id: 287,
		quantity: 451,
	},
	{
		garage_id: 34,
		part_id: 288,
		quantity: 307,
	},
	{
		garage_id: 34,
		part_id: 289,
		quantity: 391,
	},
	{
		garage_id: 34,
		part_id: 290,
		quantity: 46,
	},
	{
		garage_id: 34,
		part_id: 291,
		quantity: 390,
	},
	{
		garage_id: 34,
		part_id: 292,
		quantity: 321,
	},
	{
		garage_id: 34,
		part_id: 293,
		quantity: 182,
	},
	{
		garage_id: 34,
		part_id: 294,
		quantity: 383,
	},
	{
		garage_id: 34,
		part_id: 295,
		quantity: 269,
	},
	{
		garage_id: 34,
		part_id: 296,
		quantity: 283,
	},
	{
		garage_id: 34,
		part_id: 297,
		quantity: 111,
	},
	{
		garage_id: 34,
		part_id: 298,
		quantity: 428,
	},
	{
		garage_id: 34,
		part_id: 299,
		quantity: 497,
	},
	{
		garage_id: 34,
		part_id: 300,
		quantity: 130,
	},
	{
		garage_id: 34,
		part_id: 301,
		quantity: 183,
	},
	{
		garage_id: 34,
		part_id: 302,
		quantity: 93,
	},
	{
		garage_id: 34,
		part_id: 303,
		quantity: 133,
	},
	{
		garage_id: 34,
		part_id: 304,
		quantity: 428,
	},
	{
		garage_id: 34,
		part_id: 305,
		quantity: 160,
	},
	{
		garage_id: 34,
		part_id: 306,
		quantity: 141,
	},
	{
		garage_id: 34,
		part_id: 307,
		quantity: 208,
	},
	{
		garage_id: 34,
		part_id: 308,
		quantity: 238,
	},
	{
		garage_id: 34,
		part_id: 309,
		quantity: 352,
	},
	{
		garage_id: 34,
		part_id: 310,
		quantity: 249,
	},
	{
		garage_id: 34,
		part_id: 311,
		quantity: 172,
	},
	{
		garage_id: 34,
		part_id: 312,
		quantity: 444,
	},
	{
		garage_id: 34,
		part_id: 313,
		quantity: 76,
	},
	{
		garage_id: 34,
		part_id: 314,
		quantity: 47,
	},
	{
		garage_id: 34,
		part_id: 315,
		quantity: 7,
	},
	{
		garage_id: 34,
		part_id: 316,
		quantity: 271,
	},
	{
		garage_id: 34,
		part_id: 317,
		quantity: 92,
	},
	{
		garage_id: 34,
		part_id: 318,
		quantity: 406,
	},
	{
		garage_id: 34,
		part_id: 319,
		quantity: 52,
	},
	{
		garage_id: 34,
		part_id: 320,
		quantity: 301,
	},
	{
		garage_id: 34,
		part_id: 321,
		quantity: 52,
	},
	{
		garage_id: 34,
		part_id: 322,
		quantity: 462,
	},
	{
		garage_id: 34,
		part_id: 323,
		quantity: 496,
	},
	{
		garage_id: 34,
		part_id: 324,
		quantity: 144,
	},
	{
		garage_id: 34,
		part_id: 325,
		quantity: 109,
	},
	{
		garage_id: 34,
		part_id: 326,
		quantity: 403,
	},
	{
		garage_id: 34,
		part_id: 327,
		quantity: 347,
	},
	{
		garage_id: 34,
		part_id: 328,
		quantity: 279,
	},
	{
		garage_id: 34,
		part_id: 329,
		quantity: 328,
	},
	{
		garage_id: 34,
		part_id: 330,
		quantity: 9,
	},
	{
		garage_id: 34,
		part_id: 331,
		quantity: 89,
	},
	{
		garage_id: 34,
		part_id: 332,
		quantity: 12,
	},
	{
		garage_id: 34,
		part_id: 333,
		quantity: 490,
	},
	{
		garage_id: 34,
		part_id: 334,
		quantity: 272,
	},
	{
		garage_id: 34,
		part_id: 335,
		quantity: 315,
	},
	{
		garage_id: 34,
		part_id: 336,
		quantity: 393,
	},
	{
		garage_id: 34,
		part_id: 337,
		quantity: 211,
	},
	{
		garage_id: 34,
		part_id: 338,
		quantity: 246,
	},
	{
		garage_id: 34,
		part_id: 339,
		quantity: 405,
	},
	{
		garage_id: 34,
		part_id: 340,
		quantity: 58,
	},
	{
		garage_id: 34,
		part_id: 341,
		quantity: 419,
	},
	{
		garage_id: 34,
		part_id: 342,
		quantity: 316,
	},
	{
		garage_id: 34,
		part_id: 343,
		quantity: 49,
	},
	{
		garage_id: 34,
		part_id: 344,
		quantity: 395,
	},
	{
		garage_id: 34,
		part_id: 345,
		quantity: 374,
	},
	{
		garage_id: 34,
		part_id: 346,
		quantity: 302,
	},
	{
		garage_id: 34,
		part_id: 347,
		quantity: 390,
	},
	{
		garage_id: 34,
		part_id: 348,
		quantity: 54,
	},
	{
		garage_id: 34,
		part_id: 349,
		quantity: 208,
	},
	{
		garage_id: 34,
		part_id: 350,
		quantity: 25,
	},
	{
		garage_id: 34,
		part_id: 351,
		quantity: 461,
	},
	{
		garage_id: 34,
		part_id: 352,
		quantity: 302,
	},
	{
		garage_id: 34,
		part_id: 353,
		quantity: 59,
	},
	{
		garage_id: 34,
		part_id: 354,
		quantity: 402,
	},
	{
		garage_id: 34,
		part_id: 355,
		quantity: 69,
	},
	{
		garage_id: 34,
		part_id: 356,
		quantity: 198,
	},
	{
		garage_id: 34,
		part_id: 357,
		quantity: 285,
	},
	{
		garage_id: 34,
		part_id: 358,
		quantity: 294,
	},
	{
		garage_id: 34,
		part_id: 359,
		quantity: 224,
	},
	{
		garage_id: 34,
		part_id: 360,
		quantity: 405,
	},
	{
		garage_id: 34,
		part_id: 361,
		quantity: 412,
	},
	{
		garage_id: 34,
		part_id: 362,
		quantity: 173,
	},
	{
		garage_id: 34,
		part_id: 363,
		quantity: 123,
	},
	{
		garage_id: 34,
		part_id: 364,
		quantity: 297,
	},
	{
		garage_id: 34,
		part_id: 365,
		quantity: 375,
	},
	{
		garage_id: 34,
		part_id: 366,
		quantity: 425,
	},
	{
		garage_id: 34,
		part_id: 367,
		quantity: 345,
	},
	{
		garage_id: 34,
		part_id: 368,
		quantity: 166,
	},
	{
		garage_id: 34,
		part_id: 369,
		quantity: 111,
	},
	{
		garage_id: 34,
		part_id: 370,
		quantity: 67,
	},
	{
		garage_id: 34,
		part_id: 371,
		quantity: 434,
	},
	{
		garage_id: 34,
		part_id: 372,
		quantity: 452,
	},
	{
		garage_id: 34,
		part_id: 373,
		quantity: 477,
	},
	{
		garage_id: 34,
		part_id: 374,
		quantity: 86,
	},
	{
		garage_id: 34,
		part_id: 375,
		quantity: 489,
	},
	{
		garage_id: 34,
		part_id: 376,
		quantity: 247,
	},
	{
		garage_id: 34,
		part_id: 377,
		quantity: 12,
	},
	{
		garage_id: 34,
		part_id: 378,
		quantity: 372,
	},
	{
		garage_id: 34,
		part_id: 379,
		quantity: 281,
	},
	{
		garage_id: 34,
		part_id: 380,
		quantity: 221,
	},
	{
		garage_id: 34,
		part_id: 381,
		quantity: 422,
	},
	{
		garage_id: 34,
		part_id: 382,
		quantity: 176,
	},
	{
		garage_id: 34,
		part_id: 383,
		quantity: 259,
	},
	{
		garage_id: 34,
		part_id: 384,
		quantity: 87,
	},
	{
		garage_id: 34,
		part_id: 385,
		quantity: 239,
	},
	{
		garage_id: 34,
		part_id: 386,
		quantity: 41,
	},
	{
		garage_id: 34,
		part_id: 387,
		quantity: 370,
	},
	{
		garage_id: 34,
		part_id: 388,
		quantity: 107,
	},
	{
		garage_id: 34,
		part_id: 389,
		quantity: 365,
	},
	{
		garage_id: 34,
		part_id: 390,
		quantity: 121,
	},
	{
		garage_id: 34,
		part_id: 391,
		quantity: 496,
	},
	{
		garage_id: 34,
		part_id: 392,
		quantity: 211,
	},
	{
		garage_id: 34,
		part_id: 393,
		quantity: 246,
	},
	{
		garage_id: 34,
		part_id: 394,
		quantity: 220,
	},
	{
		garage_id: 34,
		part_id: 395,
		quantity: 416,
	},
	{
		garage_id: 34,
		part_id: 396,
		quantity: 299,
	},
	{
		garage_id: 34,
		part_id: 397,
		quantity: 186,
	},
	{
		garage_id: 34,
		part_id: 398,
		quantity: 467,
	},
	{
		garage_id: 34,
		part_id: 399,
		quantity: 63,
	},
	{
		garage_id: 35,
		part_id: 0,
		quantity: 302,
	},
	{
		garage_id: 35,
		part_id: 1,
		quantity: 154,
	},
	{
		garage_id: 35,
		part_id: 2,
		quantity: 316,
	},
	{
		garage_id: 35,
		part_id: 3,
		quantity: 500,
	},
	{
		garage_id: 35,
		part_id: 4,
		quantity: 62,
	},
	{
		garage_id: 35,
		part_id: 5,
		quantity: 497,
	},
	{
		garage_id: 35,
		part_id: 6,
		quantity: 293,
	},
	{
		garage_id: 35,
		part_id: 7,
		quantity: 419,
	},
	{
		garage_id: 35,
		part_id: 8,
		quantity: 288,
	},
	{
		garage_id: 35,
		part_id: 9,
		quantity: 319,
	},
	{
		garage_id: 35,
		part_id: 10,
		quantity: 215,
	},
	{
		garage_id: 35,
		part_id: 11,
		quantity: 355,
	},
	{
		garage_id: 35,
		part_id: 12,
		quantity: 151,
	},
	{
		garage_id: 35,
		part_id: 13,
		quantity: 326,
	},
	{
		garage_id: 35,
		part_id: 14,
		quantity: 267,
	},
	{
		garage_id: 35,
		part_id: 15,
		quantity: 497,
	},
	{
		garage_id: 35,
		part_id: 16,
		quantity: 488,
	},
	{
		garage_id: 35,
		part_id: 17,
		quantity: 62,
	},
	{
		garage_id: 35,
		part_id: 18,
		quantity: 399,
	},
	{
		garage_id: 35,
		part_id: 19,
		quantity: 313,
	},
	{
		garage_id: 35,
		part_id: 20,
		quantity: 60,
	},
	{
		garage_id: 35,
		part_id: 21,
		quantity: 324,
	},
	{
		garage_id: 35,
		part_id: 22,
		quantity: 404,
	},
	{
		garage_id: 35,
		part_id: 23,
		quantity: 162,
	},
	{
		garage_id: 35,
		part_id: 24,
		quantity: 308,
	},
	{
		garage_id: 35,
		part_id: 25,
		quantity: 451,
	},
	{
		garage_id: 35,
		part_id: 26,
		quantity: 252,
	},
	{
		garage_id: 35,
		part_id: 27,
		quantity: 254,
	},
	{
		garage_id: 35,
		part_id: 28,
		quantity: 498,
	},
	{
		garage_id: 35,
		part_id: 29,
		quantity: 263,
	},
	{
		garage_id: 35,
		part_id: 30,
		quantity: 41,
	},
	{
		garage_id: 35,
		part_id: 31,
		quantity: 479,
	},
	{
		garage_id: 35,
		part_id: 32,
		quantity: 370,
	},
	{
		garage_id: 35,
		part_id: 33,
		quantity: 129,
	},
	{
		garage_id: 35,
		part_id: 34,
		quantity: 202,
	},
	{
		garage_id: 35,
		part_id: 35,
		quantity: 190,
	},
	{
		garage_id: 35,
		part_id: 36,
		quantity: 385,
	},
	{
		garage_id: 35,
		part_id: 37,
		quantity: 476,
	},
	{
		garage_id: 35,
		part_id: 38,
		quantity: 285,
	},
	{
		garage_id: 35,
		part_id: 39,
		quantity: 315,
	},
	{
		garage_id: 35,
		part_id: 40,
		quantity: 234,
	},
	{
		garage_id: 35,
		part_id: 41,
		quantity: 390,
	},
	{
		garage_id: 35,
		part_id: 42,
		quantity: 107,
	},
	{
		garage_id: 35,
		part_id: 43,
		quantity: 233,
	},
	{
		garage_id: 35,
		part_id: 44,
		quantity: 183,
	},
	{
		garage_id: 35,
		part_id: 45,
		quantity: 414,
	},
	{
		garage_id: 35,
		part_id: 46,
		quantity: 23,
	},
	{
		garage_id: 35,
		part_id: 47,
		quantity: 234,
	},
	{
		garage_id: 35,
		part_id: 48,
		quantity: 10,
	},
	{
		garage_id: 35,
		part_id: 49,
		quantity: 428,
	},
	{
		garage_id: 35,
		part_id: 50,
		quantity: 2,
	},
	{
		garage_id: 35,
		part_id: 51,
		quantity: 246,
	},
	{
		garage_id: 35,
		part_id: 52,
		quantity: 360,
	},
	{
		garage_id: 35,
		part_id: 53,
		quantity: 267,
	},
	{
		garage_id: 35,
		part_id: 54,
		quantity: 88,
	},
	{
		garage_id: 35,
		part_id: 55,
		quantity: 266,
	},
	{
		garage_id: 35,
		part_id: 56,
		quantity: 58,
	},
	{
		garage_id: 35,
		part_id: 57,
		quantity: 301,
	},
	{
		garage_id: 35,
		part_id: 58,
		quantity: 372,
	},
	{
		garage_id: 35,
		part_id: 59,
		quantity: 254,
	},
	{
		garage_id: 35,
		part_id: 60,
		quantity: 170,
	},
	{
		garage_id: 35,
		part_id: 61,
		quantity: 307,
	},
	{
		garage_id: 35,
		part_id: 62,
		quantity: 298,
	},
	{
		garage_id: 35,
		part_id: 63,
		quantity: 66,
	},
	{
		garage_id: 35,
		part_id: 64,
		quantity: 324,
	},
	{
		garage_id: 35,
		part_id: 65,
		quantity: 97,
	},
	{
		garage_id: 35,
		part_id: 66,
		quantity: 373,
	},
	{
		garage_id: 35,
		part_id: 67,
		quantity: 6,
	},
	{
		garage_id: 35,
		part_id: 68,
		quantity: 128,
	},
	{
		garage_id: 35,
		part_id: 69,
		quantity: 211,
	},
	{
		garage_id: 35,
		part_id: 70,
		quantity: 175,
	},
	{
		garage_id: 35,
		part_id: 71,
		quantity: 456,
	},
	{
		garage_id: 35,
		part_id: 72,
		quantity: 380,
	},
	{
		garage_id: 35,
		part_id: 73,
		quantity: 454,
	},
	{
		garage_id: 35,
		part_id: 74,
		quantity: 272,
	},
	{
		garage_id: 35,
		part_id: 75,
		quantity: 258,
	},
	{
		garage_id: 35,
		part_id: 76,
		quantity: 220,
	},
	{
		garage_id: 35,
		part_id: 77,
		quantity: 111,
	},
	{
		garage_id: 35,
		part_id: 78,
		quantity: 294,
	},
	{
		garage_id: 35,
		part_id: 79,
		quantity: 126,
	},
	{
		garage_id: 35,
		part_id: 80,
		quantity: 46,
	},
	{
		garage_id: 35,
		part_id: 81,
		quantity: 92,
	},
	{
		garage_id: 35,
		part_id: 82,
		quantity: 428,
	},
	{
		garage_id: 35,
		part_id: 83,
		quantity: 156,
	},
	{
		garage_id: 35,
		part_id: 84,
		quantity: 276,
	},
	{
		garage_id: 35,
		part_id: 85,
		quantity: 228,
	},
	{
		garage_id: 35,
		part_id: 86,
		quantity: 339,
	},
	{
		garage_id: 35,
		part_id: 87,
		quantity: 410,
	},
	{
		garage_id: 35,
		part_id: 88,
		quantity: 13,
	},
	{
		garage_id: 35,
		part_id: 89,
		quantity: 366,
	},
	{
		garage_id: 35,
		part_id: 90,
		quantity: 318,
	},
	{
		garage_id: 35,
		part_id: 91,
		quantity: 495,
	},
	{
		garage_id: 35,
		part_id: 92,
		quantity: 241,
	},
	{
		garage_id: 35,
		part_id: 93,
		quantity: 377,
	},
	{
		garage_id: 35,
		part_id: 94,
		quantity: 93,
	},
	{
		garage_id: 35,
		part_id: 95,
		quantity: 481,
	},
	{
		garage_id: 35,
		part_id: 96,
		quantity: 17,
	},
	{
		garage_id: 35,
		part_id: 97,
		quantity: 393,
	},
	{
		garage_id: 35,
		part_id: 98,
		quantity: 205,
	},
	{
		garage_id: 35,
		part_id: 99,
		quantity: 425,
	},
	{
		garage_id: 35,
		part_id: 100,
		quantity: 269,
	},
	{
		garage_id: 35,
		part_id: 101,
		quantity: 273,
	},
	{
		garage_id: 35,
		part_id: 102,
		quantity: 381,
	},
	{
		garage_id: 35,
		part_id: 103,
		quantity: 474,
	},
	{
		garage_id: 35,
		part_id: 104,
		quantity: 32,
	},
	{
		garage_id: 35,
		part_id: 105,
		quantity: 343,
	},
	{
		garage_id: 35,
		part_id: 106,
		quantity: 499,
	},
	{
		garage_id: 35,
		part_id: 107,
		quantity: 151,
	},
	{
		garage_id: 35,
		part_id: 108,
		quantity: 237,
	},
	{
		garage_id: 35,
		part_id: 109,
		quantity: 307,
	},
	{
		garage_id: 35,
		part_id: 110,
		quantity: 441,
	},
	{
		garage_id: 35,
		part_id: 111,
		quantity: 478,
	},
	{
		garage_id: 35,
		part_id: 112,
		quantity: 259,
	},
	{
		garage_id: 35,
		part_id: 113,
		quantity: 148,
	},
	{
		garage_id: 35,
		part_id: 114,
		quantity: 398,
	},
	{
		garage_id: 35,
		part_id: 115,
		quantity: 96,
	},
	{
		garage_id: 35,
		part_id: 116,
		quantity: 422,
	},
	{
		garage_id: 35,
		part_id: 117,
		quantity: 415,
	},
	{
		garage_id: 35,
		part_id: 118,
		quantity: 55,
	},
	{
		garage_id: 35,
		part_id: 119,
		quantity: 334,
	},
	{
		garage_id: 35,
		part_id: 120,
		quantity: 91,
	},
	{
		garage_id: 35,
		part_id: 121,
		quantity: 399,
	},
	{
		garage_id: 35,
		part_id: 122,
		quantity: 203,
	},
	{
		garage_id: 35,
		part_id: 123,
		quantity: 485,
	},
	{
		garage_id: 35,
		part_id: 124,
		quantity: 149,
	},
	{
		garage_id: 35,
		part_id: 125,
		quantity: 246,
	},
	{
		garage_id: 35,
		part_id: 126,
		quantity: 419,
	},
	{
		garage_id: 35,
		part_id: 127,
		quantity: 126,
	},
	{
		garage_id: 35,
		part_id: 128,
		quantity: 285,
	},
	{
		garage_id: 35,
		part_id: 129,
		quantity: 344,
	},
	{
		garage_id: 35,
		part_id: 130,
		quantity: 418,
	},
	{
		garage_id: 35,
		part_id: 131,
		quantity: 484,
	},
	{
		garage_id: 35,
		part_id: 132,
		quantity: 223,
	},
	{
		garage_id: 35,
		part_id: 133,
		quantity: 489,
	},
	{
		garage_id: 35,
		part_id: 134,
		quantity: 472,
	},
	{
		garage_id: 35,
		part_id: 135,
		quantity: 451,
	},
	{
		garage_id: 35,
		part_id: 136,
		quantity: 478,
	},
	{
		garage_id: 35,
		part_id: 137,
		quantity: 267,
	},
	{
		garage_id: 35,
		part_id: 138,
		quantity: 262,
	},
	{
		garage_id: 35,
		part_id: 139,
		quantity: 2,
	},
	{
		garage_id: 35,
		part_id: 140,
		quantity: 287,
	},
	{
		garage_id: 35,
		part_id: 141,
		quantity: 53,
	},
	{
		garage_id: 35,
		part_id: 142,
		quantity: 64,
	},
	{
		garage_id: 35,
		part_id: 143,
		quantity: 209,
	},
	{
		garage_id: 35,
		part_id: 144,
		quantity: 382,
	},
	{
		garage_id: 35,
		part_id: 145,
		quantity: 345,
	},
	{
		garage_id: 35,
		part_id: 146,
		quantity: 45,
	},
	{
		garage_id: 35,
		part_id: 147,
		quantity: 265,
	},
	{
		garage_id: 35,
		part_id: 148,
		quantity: 417,
	},
	{
		garage_id: 35,
		part_id: 149,
		quantity: 342,
	},
	{
		garage_id: 35,
		part_id: 150,
		quantity: 1,
	},
	{
		garage_id: 35,
		part_id: 151,
		quantity: 235,
	},
	{
		garage_id: 35,
		part_id: 152,
		quantity: 289,
	},
	{
		garage_id: 35,
		part_id: 153,
		quantity: 19,
	},
	{
		garage_id: 35,
		part_id: 154,
		quantity: 440,
	},
	{
		garage_id: 35,
		part_id: 155,
		quantity: 23,
	},
	{
		garage_id: 35,
		part_id: 156,
		quantity: 225,
	},
	{
		garage_id: 35,
		part_id: 157,
		quantity: 226,
	},
	{
		garage_id: 35,
		part_id: 158,
		quantity: 486,
	},
	{
		garage_id: 35,
		part_id: 159,
		quantity: 152,
	},
	{
		garage_id: 35,
		part_id: 160,
		quantity: 265,
	},
	{
		garage_id: 35,
		part_id: 161,
		quantity: 178,
	},
	{
		garage_id: 35,
		part_id: 162,
		quantity: 54,
	},
	{
		garage_id: 35,
		part_id: 163,
		quantity: 415,
	},
	{
		garage_id: 35,
		part_id: 164,
		quantity: 333,
	},
	{
		garage_id: 35,
		part_id: 165,
		quantity: 16,
	},
	{
		garage_id: 35,
		part_id: 166,
		quantity: 39,
	},
	{
		garage_id: 35,
		part_id: 167,
		quantity: 231,
	},
	{
		garage_id: 35,
		part_id: 168,
		quantity: 347,
	},
	{
		garage_id: 35,
		part_id: 169,
		quantity: 460,
	},
	{
		garage_id: 35,
		part_id: 170,
		quantity: 164,
	},
	{
		garage_id: 35,
		part_id: 171,
		quantity: 54,
	},
	{
		garage_id: 35,
		part_id: 172,
		quantity: 222,
	},
	{
		garage_id: 35,
		part_id: 173,
		quantity: 438,
	},
	{
		garage_id: 35,
		part_id: 174,
		quantity: 233,
	},
	{
		garage_id: 35,
		part_id: 175,
		quantity: 196,
	},
	{
		garage_id: 35,
		part_id: 176,
		quantity: 400,
	},
	{
		garage_id: 35,
		part_id: 177,
		quantity: 336,
	},
	{
		garage_id: 35,
		part_id: 178,
		quantity: 280,
	},
	{
		garage_id: 35,
		part_id: 179,
		quantity: 126,
	},
	{
		garage_id: 35,
		part_id: 180,
		quantity: 150,
	},
	{
		garage_id: 35,
		part_id: 181,
		quantity: 393,
	},
	{
		garage_id: 35,
		part_id: 182,
		quantity: 65,
	},
	{
		garage_id: 35,
		part_id: 183,
		quantity: 440,
	},
	{
		garage_id: 35,
		part_id: 184,
		quantity: 480,
	},
	{
		garage_id: 35,
		part_id: 185,
		quantity: 194,
	},
	{
		garage_id: 35,
		part_id: 186,
		quantity: 479,
	},
	{
		garage_id: 35,
		part_id: 187,
		quantity: 315,
	},
	{
		garage_id: 35,
		part_id: 188,
		quantity: 370,
	},
	{
		garage_id: 35,
		part_id: 189,
		quantity: 416,
	},
	{
		garage_id: 35,
		part_id: 190,
		quantity: 445,
	},
	{
		garage_id: 35,
		part_id: 191,
		quantity: 366,
	},
	{
		garage_id: 35,
		part_id: 192,
		quantity: 1,
	},
	{
		garage_id: 35,
		part_id: 193,
		quantity: 124,
	},
	{
		garage_id: 35,
		part_id: 194,
		quantity: 186,
	},
	{
		garage_id: 35,
		part_id: 195,
		quantity: 293,
	},
	{
		garage_id: 35,
		part_id: 196,
		quantity: 43,
	},
	{
		garage_id: 35,
		part_id: 197,
		quantity: 38,
	},
	{
		garage_id: 35,
		part_id: 198,
		quantity: 27,
	},
	{
		garage_id: 35,
		part_id: 199,
		quantity: 46,
	},
	{
		garage_id: 35,
		part_id: 200,
		quantity: 485,
	},
	{
		garage_id: 35,
		part_id: 201,
		quantity: 143,
	},
	{
		garage_id: 35,
		part_id: 202,
		quantity: 489,
	},
	{
		garage_id: 35,
		part_id: 203,
		quantity: 497,
	},
	{
		garage_id: 35,
		part_id: 204,
		quantity: 45,
	},
	{
		garage_id: 35,
		part_id: 205,
		quantity: 164,
	},
	{
		garage_id: 35,
		part_id: 206,
		quantity: 86,
	},
	{
		garage_id: 35,
		part_id: 207,
		quantity: 3,
	},
	{
		garage_id: 35,
		part_id: 208,
		quantity: 440,
	},
	{
		garage_id: 35,
		part_id: 209,
		quantity: 115,
	},
	{
		garage_id: 35,
		part_id: 210,
		quantity: 261,
	},
	{
		garage_id: 35,
		part_id: 211,
		quantity: 258,
	},
	{
		garage_id: 35,
		part_id: 212,
		quantity: 76,
	},
	{
		garage_id: 35,
		part_id: 213,
		quantity: 291,
	},
	{
		garage_id: 35,
		part_id: 214,
		quantity: 479,
	},
	{
		garage_id: 35,
		part_id: 215,
		quantity: 81,
	},
	{
		garage_id: 35,
		part_id: 216,
		quantity: 210,
	},
	{
		garage_id: 35,
		part_id: 217,
		quantity: 168,
	},
	{
		garage_id: 35,
		part_id: 218,
		quantity: 226,
	},
	{
		garage_id: 35,
		part_id: 219,
		quantity: 432,
	},
	{
		garage_id: 35,
		part_id: 220,
		quantity: 61,
	},
	{
		garage_id: 35,
		part_id: 221,
		quantity: 472,
	},
	{
		garage_id: 35,
		part_id: 222,
		quantity: 205,
	},
	{
		garage_id: 35,
		part_id: 223,
		quantity: 458,
	},
	{
		garage_id: 35,
		part_id: 224,
		quantity: 74,
	},
	{
		garage_id: 35,
		part_id: 225,
		quantity: 39,
	},
	{
		garage_id: 35,
		part_id: 226,
		quantity: 109,
	},
	{
		garage_id: 35,
		part_id: 227,
		quantity: 5,
	},
	{
		garage_id: 35,
		part_id: 228,
		quantity: 133,
	},
	{
		garage_id: 35,
		part_id: 229,
		quantity: 388,
	},
	{
		garage_id: 35,
		part_id: 230,
		quantity: 384,
	},
	{
		garage_id: 35,
		part_id: 231,
		quantity: 146,
	},
	{
		garage_id: 35,
		part_id: 232,
		quantity: 459,
	},
	{
		garage_id: 35,
		part_id: 233,
		quantity: 29,
	},
	{
		garage_id: 35,
		part_id: 234,
		quantity: 145,
	},
	{
		garage_id: 35,
		part_id: 235,
		quantity: 263,
	},
	{
		garage_id: 35,
		part_id: 236,
		quantity: 283,
	},
	{
		garage_id: 35,
		part_id: 237,
		quantity: 488,
	},
	{
		garage_id: 35,
		part_id: 238,
		quantity: 327,
	},
	{
		garage_id: 35,
		part_id: 239,
		quantity: 225,
	},
	{
		garage_id: 35,
		part_id: 240,
		quantity: 477,
	},
	{
		garage_id: 35,
		part_id: 241,
		quantity: 148,
	},
	{
		garage_id: 35,
		part_id: 242,
		quantity: 200,
	},
	{
		garage_id: 35,
		part_id: 243,
		quantity: 304,
	},
	{
		garage_id: 35,
		part_id: 244,
		quantity: 221,
	},
	{
		garage_id: 35,
		part_id: 245,
		quantity: 50,
	},
	{
		garage_id: 35,
		part_id: 246,
		quantity: 399,
	},
	{
		garage_id: 35,
		part_id: 247,
		quantity: 3,
	},
	{
		garage_id: 35,
		part_id: 248,
		quantity: 332,
	},
	{
		garage_id: 35,
		part_id: 249,
		quantity: 164,
	},
	{
		garage_id: 35,
		part_id: 250,
		quantity: 302,
	},
	{
		garage_id: 35,
		part_id: 251,
		quantity: 317,
	},
	{
		garage_id: 35,
		part_id: 252,
		quantity: 33,
	},
	{
		garage_id: 35,
		part_id: 253,
		quantity: 88,
	},
	{
		garage_id: 35,
		part_id: 254,
		quantity: 124,
	},
	{
		garage_id: 35,
		part_id: 255,
		quantity: 459,
	},
	{
		garage_id: 35,
		part_id: 256,
		quantity: 251,
	},
	{
		garage_id: 35,
		part_id: 257,
		quantity: 210,
	},
	{
		garage_id: 35,
		part_id: 258,
		quantity: 240,
	},
	{
		garage_id: 35,
		part_id: 259,
		quantity: 243,
	},
	{
		garage_id: 35,
		part_id: 260,
		quantity: 69,
	},
	{
		garage_id: 35,
		part_id: 261,
		quantity: 255,
	},
	{
		garage_id: 35,
		part_id: 262,
		quantity: 367,
	},
	{
		garage_id: 35,
		part_id: 263,
		quantity: 16,
	},
	{
		garage_id: 35,
		part_id: 264,
		quantity: 166,
	},
	{
		garage_id: 35,
		part_id: 265,
		quantity: 232,
	},
	{
		garage_id: 35,
		part_id: 266,
		quantity: 402,
	},
	{
		garage_id: 35,
		part_id: 267,
		quantity: 265,
	},
	{
		garage_id: 35,
		part_id: 268,
		quantity: 375,
	},
	{
		garage_id: 35,
		part_id: 269,
		quantity: 223,
	},
	{
		garage_id: 35,
		part_id: 270,
		quantity: 177,
	},
	{
		garage_id: 35,
		part_id: 271,
		quantity: 175,
	},
	{
		garage_id: 35,
		part_id: 272,
		quantity: 423,
	},
	{
		garage_id: 35,
		part_id: 273,
		quantity: 354,
	},
	{
		garage_id: 35,
		part_id: 274,
		quantity: 411,
	},
	{
		garage_id: 35,
		part_id: 275,
		quantity: 78,
	},
	{
		garage_id: 35,
		part_id: 276,
		quantity: 2,
	},
	{
		garage_id: 35,
		part_id: 277,
		quantity: 167,
	},
	{
		garage_id: 35,
		part_id: 278,
		quantity: 137,
	},
	{
		garage_id: 35,
		part_id: 279,
		quantity: 465,
	},
	{
		garage_id: 35,
		part_id: 280,
		quantity: 285,
	},
	{
		garage_id: 35,
		part_id: 281,
		quantity: 111,
	},
	{
		garage_id: 35,
		part_id: 282,
		quantity: 235,
	},
	{
		garage_id: 35,
		part_id: 283,
		quantity: 412,
	},
	{
		garage_id: 35,
		part_id: 284,
		quantity: 398,
	},
	{
		garage_id: 35,
		part_id: 285,
		quantity: 443,
	},
	{
		garage_id: 35,
		part_id: 286,
		quantity: 158,
	},
	{
		garage_id: 35,
		part_id: 287,
		quantity: 204,
	},
	{
		garage_id: 35,
		part_id: 288,
		quantity: 468,
	},
	{
		garage_id: 35,
		part_id: 289,
		quantity: 310,
	},
	{
		garage_id: 35,
		part_id: 290,
		quantity: 367,
	},
	{
		garage_id: 35,
		part_id: 291,
		quantity: 347,
	},
	{
		garage_id: 35,
		part_id: 292,
		quantity: 22,
	},
	{
		garage_id: 35,
		part_id: 293,
		quantity: 217,
	},
	{
		garage_id: 35,
		part_id: 294,
		quantity: 387,
	},
	{
		garage_id: 35,
		part_id: 295,
		quantity: 154,
	},
	{
		garage_id: 35,
		part_id: 296,
		quantity: 254,
	},
	{
		garage_id: 35,
		part_id: 297,
		quantity: 396,
	},
	{
		garage_id: 35,
		part_id: 298,
		quantity: 211,
	},
	{
		garage_id: 35,
		part_id: 299,
		quantity: 128,
	},
	{
		garage_id: 35,
		part_id: 300,
		quantity: 30,
	},
	{
		garage_id: 35,
		part_id: 301,
		quantity: 190,
	},
	{
		garage_id: 35,
		part_id: 302,
		quantity: 315,
	},
	{
		garage_id: 35,
		part_id: 303,
		quantity: 0,
	},
	{
		garage_id: 35,
		part_id: 304,
		quantity: 397,
	},
	{
		garage_id: 35,
		part_id: 305,
		quantity: 326,
	},
	{
		garage_id: 35,
		part_id: 306,
		quantity: 353,
	},
	{
		garage_id: 35,
		part_id: 307,
		quantity: 146,
	},
	{
		garage_id: 35,
		part_id: 308,
		quantity: 135,
	},
	{
		garage_id: 35,
		part_id: 309,
		quantity: 6,
	},
	{
		garage_id: 35,
		part_id: 310,
		quantity: 359,
	},
	{
		garage_id: 35,
		part_id: 311,
		quantity: 282,
	},
	{
		garage_id: 35,
		part_id: 312,
		quantity: 414,
	},
	{
		garage_id: 35,
		part_id: 313,
		quantity: 127,
	},
	{
		garage_id: 35,
		part_id: 314,
		quantity: 96,
	},
	{
		garage_id: 35,
		part_id: 315,
		quantity: 376,
	},
	{
		garage_id: 35,
		part_id: 316,
		quantity: 376,
	},
	{
		garage_id: 35,
		part_id: 317,
		quantity: 199,
	},
	{
		garage_id: 35,
		part_id: 318,
		quantity: 299,
	},
	{
		garage_id: 35,
		part_id: 319,
		quantity: 481,
	},
	{
		garage_id: 35,
		part_id: 320,
		quantity: 75,
	},
	{
		garage_id: 35,
		part_id: 321,
		quantity: 38,
	},
	{
		garage_id: 35,
		part_id: 322,
		quantity: 170,
	},
	{
		garage_id: 35,
		part_id: 323,
		quantity: 429,
	},
	{
		garage_id: 35,
		part_id: 324,
		quantity: 347,
	},
	{
		garage_id: 35,
		part_id: 325,
		quantity: 70,
	},
	{
		garage_id: 35,
		part_id: 326,
		quantity: 209,
	},
	{
		garage_id: 35,
		part_id: 327,
		quantity: 20,
	},
	{
		garage_id: 35,
		part_id: 328,
		quantity: 439,
	},
	{
		garage_id: 35,
		part_id: 329,
		quantity: 333,
	},
	{
		garage_id: 35,
		part_id: 330,
		quantity: 205,
	},
	{
		garage_id: 35,
		part_id: 331,
		quantity: 408,
	},
	{
		garage_id: 35,
		part_id: 332,
		quantity: 152,
	},
	{
		garage_id: 35,
		part_id: 333,
		quantity: 157,
	},
	{
		garage_id: 35,
		part_id: 334,
		quantity: 343,
	},
	{
		garage_id: 35,
		part_id: 335,
		quantity: 51,
	},
	{
		garage_id: 35,
		part_id: 336,
		quantity: 216,
	},
	{
		garage_id: 35,
		part_id: 337,
		quantity: 71,
	},
	{
		garage_id: 35,
		part_id: 338,
		quantity: 94,
	},
	{
		garage_id: 35,
		part_id: 339,
		quantity: 5,
	},
	{
		garage_id: 35,
		part_id: 340,
		quantity: 99,
	},
	{
		garage_id: 35,
		part_id: 341,
		quantity: 133,
	},
	{
		garage_id: 35,
		part_id: 342,
		quantity: 192,
	},
	{
		garage_id: 35,
		part_id: 343,
		quantity: 275,
	},
	{
		garage_id: 35,
		part_id: 344,
		quantity: 327,
	},
	{
		garage_id: 35,
		part_id: 345,
		quantity: 145,
	},
	{
		garage_id: 35,
		part_id: 346,
		quantity: 117,
	},
	{
		garage_id: 35,
		part_id: 347,
		quantity: 424,
	},
	{
		garage_id: 35,
		part_id: 348,
		quantity: 437,
	},
	{
		garage_id: 35,
		part_id: 349,
		quantity: 267,
	},
	{
		garage_id: 35,
		part_id: 350,
		quantity: 473,
	},
	{
		garage_id: 35,
		part_id: 351,
		quantity: 468,
	},
	{
		garage_id: 35,
		part_id: 352,
		quantity: 342,
	},
	{
		garage_id: 35,
		part_id: 353,
		quantity: 434,
	},
	{
		garage_id: 35,
		part_id: 354,
		quantity: 302,
	},
	{
		garage_id: 35,
		part_id: 355,
		quantity: 173,
	},
	{
		garage_id: 35,
		part_id: 356,
		quantity: 128,
	},
	{
		garage_id: 35,
		part_id: 357,
		quantity: 254,
	},
	{
		garage_id: 35,
		part_id: 358,
		quantity: 228,
	},
	{
		garage_id: 35,
		part_id: 359,
		quantity: 369,
	},
	{
		garage_id: 35,
		part_id: 360,
		quantity: 39,
	},
	{
		garage_id: 35,
		part_id: 361,
		quantity: 330,
	},
	{
		garage_id: 35,
		part_id: 362,
		quantity: 500,
	},
	{
		garage_id: 35,
		part_id: 363,
		quantity: 197,
	},
	{
		garage_id: 35,
		part_id: 364,
		quantity: 500,
	},
	{
		garage_id: 35,
		part_id: 365,
		quantity: 499,
	},
	{
		garage_id: 35,
		part_id: 366,
		quantity: 396,
	},
	{
		garage_id: 35,
		part_id: 367,
		quantity: 16,
	},
	{
		garage_id: 35,
		part_id: 368,
		quantity: 134,
	},
	{
		garage_id: 35,
		part_id: 369,
		quantity: 269,
	},
	{
		garage_id: 35,
		part_id: 370,
		quantity: 15,
	},
	{
		garage_id: 35,
		part_id: 371,
		quantity: 188,
	},
	{
		garage_id: 35,
		part_id: 372,
		quantity: 239,
	},
	{
		garage_id: 35,
		part_id: 373,
		quantity: 433,
	},
	{
		garage_id: 35,
		part_id: 374,
		quantity: 460,
	},
	{
		garage_id: 35,
		part_id: 375,
		quantity: 455,
	},
	{
		garage_id: 35,
		part_id: 376,
		quantity: 488,
	},
	{
		garage_id: 35,
		part_id: 377,
		quantity: 120,
	},
	{
		garage_id: 35,
		part_id: 378,
		quantity: 167,
	},
	{
		garage_id: 35,
		part_id: 379,
		quantity: 278,
	},
	{
		garage_id: 35,
		part_id: 380,
		quantity: 256,
	},
	{
		garage_id: 35,
		part_id: 381,
		quantity: 134,
	},
	{
		garage_id: 35,
		part_id: 382,
		quantity: 135,
	},
	{
		garage_id: 35,
		part_id: 383,
		quantity: 176,
	},
	{
		garage_id: 35,
		part_id: 384,
		quantity: 135,
	},
	{
		garage_id: 35,
		part_id: 385,
		quantity: 284,
	},
	{
		garage_id: 35,
		part_id: 386,
		quantity: 63,
	},
	{
		garage_id: 35,
		part_id: 387,
		quantity: 64,
	},
	{
		garage_id: 35,
		part_id: 388,
		quantity: 159,
	},
	{
		garage_id: 35,
		part_id: 389,
		quantity: 207,
	},
	{
		garage_id: 35,
		part_id: 390,
		quantity: 48,
	},
	{
		garage_id: 35,
		part_id: 391,
		quantity: 343,
	},
	{
		garage_id: 35,
		part_id: 392,
		quantity: 415,
	},
	{
		garage_id: 35,
		part_id: 393,
		quantity: 400,
	},
	{
		garage_id: 35,
		part_id: 394,
		quantity: 166,
	},
	{
		garage_id: 35,
		part_id: 395,
		quantity: 235,
	},
	{
		garage_id: 35,
		part_id: 396,
		quantity: 23,
	},
	{
		garage_id: 35,
		part_id: 397,
		quantity: 299,
	},
	{
		garage_id: 35,
		part_id: 398,
		quantity: 273,
	},
	{
		garage_id: 35,
		part_id: 399,
		quantity: 67,
	},
	{
		garage_id: 36,
		part_id: 0,
		quantity: 243,
	},
	{
		garage_id: 36,
		part_id: 1,
		quantity: 67,
	},
	{
		garage_id: 36,
		part_id: 2,
		quantity: 290,
	},
	{
		garage_id: 36,
		part_id: 3,
		quantity: 3,
	},
	{
		garage_id: 36,
		part_id: 4,
		quantity: 269,
	},
	{
		garage_id: 36,
		part_id: 5,
		quantity: 290,
	},
	{
		garage_id: 36,
		part_id: 6,
		quantity: 87,
	},
	{
		garage_id: 36,
		part_id: 7,
		quantity: 301,
	},
	{
		garage_id: 36,
		part_id: 8,
		quantity: 15,
	},
	{
		garage_id: 36,
		part_id: 9,
		quantity: 108,
	},
	{
		garage_id: 36,
		part_id: 10,
		quantity: 61,
	},
	{
		garage_id: 36,
		part_id: 11,
		quantity: 386,
	},
	{
		garage_id: 36,
		part_id: 12,
		quantity: 281,
	},
	{
		garage_id: 36,
		part_id: 13,
		quantity: 274,
	},
	{
		garage_id: 36,
		part_id: 14,
		quantity: 54,
	},
	{
		garage_id: 36,
		part_id: 15,
		quantity: 96,
	},
	{
		garage_id: 36,
		part_id: 16,
		quantity: 184,
	},
	{
		garage_id: 36,
		part_id: 17,
		quantity: 186,
	},
	{
		garage_id: 36,
		part_id: 18,
		quantity: 393,
	},
	{
		garage_id: 36,
		part_id: 19,
		quantity: 390,
	},
	{
		garage_id: 36,
		part_id: 20,
		quantity: 484,
	},
	{
		garage_id: 36,
		part_id: 21,
		quantity: 277,
	},
	{
		garage_id: 36,
		part_id: 22,
		quantity: 333,
	},
	{
		garage_id: 36,
		part_id: 23,
		quantity: 430,
	},
	{
		garage_id: 36,
		part_id: 24,
		quantity: 48,
	},
	{
		garage_id: 36,
		part_id: 25,
		quantity: 10,
	},
	{
		garage_id: 36,
		part_id: 26,
		quantity: 351,
	},
	{
		garage_id: 36,
		part_id: 27,
		quantity: 346,
	},
	{
		garage_id: 36,
		part_id: 28,
		quantity: 413,
	},
	{
		garage_id: 36,
		part_id: 29,
		quantity: 114,
	},
	{
		garage_id: 36,
		part_id: 30,
		quantity: 393,
	},
	{
		garage_id: 36,
		part_id: 31,
		quantity: 291,
	},
	{
		garage_id: 36,
		part_id: 32,
		quantity: 28,
	},
	{
		garage_id: 36,
		part_id: 33,
		quantity: 441,
	},
	{
		garage_id: 36,
		part_id: 34,
		quantity: 426,
	},
	{
		garage_id: 36,
		part_id: 35,
		quantity: 458,
	},
	{
		garage_id: 36,
		part_id: 36,
		quantity: 294,
	},
	{
		garage_id: 36,
		part_id: 37,
		quantity: 274,
	},
	{
		garage_id: 36,
		part_id: 38,
		quantity: 271,
	},
	{
		garage_id: 36,
		part_id: 39,
		quantity: 1,
	},
	{
		garage_id: 36,
		part_id: 40,
		quantity: 366,
	},
	{
		garage_id: 36,
		part_id: 41,
		quantity: 118,
	},
	{
		garage_id: 36,
		part_id: 42,
		quantity: 87,
	},
	{
		garage_id: 36,
		part_id: 43,
		quantity: 402,
	},
	{
		garage_id: 36,
		part_id: 44,
		quantity: 327,
	},
	{
		garage_id: 36,
		part_id: 45,
		quantity: 183,
	},
	{
		garage_id: 36,
		part_id: 46,
		quantity: 454,
	},
	{
		garage_id: 36,
		part_id: 47,
		quantity: 151,
	},
	{
		garage_id: 36,
		part_id: 48,
		quantity: 489,
	},
	{
		garage_id: 36,
		part_id: 49,
		quantity: 84,
	},
	{
		garage_id: 36,
		part_id: 50,
		quantity: 258,
	},
	{
		garage_id: 36,
		part_id: 51,
		quantity: 219,
	},
	{
		garage_id: 36,
		part_id: 52,
		quantity: 389,
	},
	{
		garage_id: 36,
		part_id: 53,
		quantity: 381,
	},
	{
		garage_id: 36,
		part_id: 54,
		quantity: 153,
	},
	{
		garage_id: 36,
		part_id: 55,
		quantity: 327,
	},
	{
		garage_id: 36,
		part_id: 56,
		quantity: 349,
	},
	{
		garage_id: 36,
		part_id: 57,
		quantity: 447,
	},
	{
		garage_id: 36,
		part_id: 58,
		quantity: 135,
	},
	{
		garage_id: 36,
		part_id: 59,
		quantity: 130,
	},
	{
		garage_id: 36,
		part_id: 60,
		quantity: 440,
	},
	{
		garage_id: 36,
		part_id: 61,
		quantity: 443,
	},
	{
		garage_id: 36,
		part_id: 62,
		quantity: 312,
	},
	{
		garage_id: 36,
		part_id: 63,
		quantity: 410,
	},
	{
		garage_id: 36,
		part_id: 64,
		quantity: 475,
	},
	{
		garage_id: 36,
		part_id: 65,
		quantity: 424,
	},
	{
		garage_id: 36,
		part_id: 66,
		quantity: 151,
	},
	{
		garage_id: 36,
		part_id: 67,
		quantity: 451,
	},
	{
		garage_id: 36,
		part_id: 68,
		quantity: 247,
	},
	{
		garage_id: 36,
		part_id: 69,
		quantity: 398,
	},
	{
		garage_id: 36,
		part_id: 70,
		quantity: 207,
	},
	{
		garage_id: 36,
		part_id: 71,
		quantity: 338,
	},
	{
		garage_id: 36,
		part_id: 72,
		quantity: 461,
	},
	{
		garage_id: 36,
		part_id: 73,
		quantity: 342,
	},
	{
		garage_id: 36,
		part_id: 74,
		quantity: 329,
	},
	{
		garage_id: 36,
		part_id: 75,
		quantity: 220,
	},
	{
		garage_id: 36,
		part_id: 76,
		quantity: 335,
	},
	{
		garage_id: 36,
		part_id: 77,
		quantity: 45,
	},
	{
		garage_id: 36,
		part_id: 78,
		quantity: 456,
	},
	{
		garage_id: 36,
		part_id: 79,
		quantity: 454,
	},
	{
		garage_id: 36,
		part_id: 80,
		quantity: 151,
	},
	{
		garage_id: 36,
		part_id: 81,
		quantity: 93,
	},
	{
		garage_id: 36,
		part_id: 82,
		quantity: 169,
	},
	{
		garage_id: 36,
		part_id: 83,
		quantity: 271,
	},
	{
		garage_id: 36,
		part_id: 84,
		quantity: 169,
	},
	{
		garage_id: 36,
		part_id: 85,
		quantity: 248,
	},
	{
		garage_id: 36,
		part_id: 86,
		quantity: 313,
	},
	{
		garage_id: 36,
		part_id: 87,
		quantity: 454,
	},
	{
		garage_id: 36,
		part_id: 88,
		quantity: 123,
	},
	{
		garage_id: 36,
		part_id: 89,
		quantity: 155,
	},
	{
		garage_id: 36,
		part_id: 90,
		quantity: 235,
	},
	{
		garage_id: 36,
		part_id: 91,
		quantity: 27,
	},
	{
		garage_id: 36,
		part_id: 92,
		quantity: 295,
	},
	{
		garage_id: 36,
		part_id: 93,
		quantity: 227,
	},
	{
		garage_id: 36,
		part_id: 94,
		quantity: 178,
	},
	{
		garage_id: 36,
		part_id: 95,
		quantity: 47,
	},
	{
		garage_id: 36,
		part_id: 96,
		quantity: 72,
	},
	{
		garage_id: 36,
		part_id: 97,
		quantity: 130,
	},
	{
		garage_id: 36,
		part_id: 98,
		quantity: 37,
	},
	{
		garage_id: 36,
		part_id: 99,
		quantity: 330,
	},
	{
		garage_id: 36,
		part_id: 100,
		quantity: 430,
	},
	{
		garage_id: 36,
		part_id: 101,
		quantity: 226,
	},
	{
		garage_id: 36,
		part_id: 102,
		quantity: 405,
	},
	{
		garage_id: 36,
		part_id: 103,
		quantity: 386,
	},
	{
		garage_id: 36,
		part_id: 104,
		quantity: 310,
	},
	{
		garage_id: 36,
		part_id: 105,
		quantity: 158,
	},
	{
		garage_id: 36,
		part_id: 106,
		quantity: 90,
	},
	{
		garage_id: 36,
		part_id: 107,
		quantity: 420,
	},
	{
		garage_id: 36,
		part_id: 108,
		quantity: 147,
	},
	{
		garage_id: 36,
		part_id: 109,
		quantity: 170,
	},
	{
		garage_id: 36,
		part_id: 110,
		quantity: 20,
	},
	{
		garage_id: 36,
		part_id: 111,
		quantity: 301,
	},
	{
		garage_id: 36,
		part_id: 112,
		quantity: 71,
	},
	{
		garage_id: 36,
		part_id: 113,
		quantity: 23,
	},
	{
		garage_id: 36,
		part_id: 114,
		quantity: 122,
	},
	{
		garage_id: 36,
		part_id: 115,
		quantity: 31,
	},
	{
		garage_id: 36,
		part_id: 116,
		quantity: 486,
	},
	{
		garage_id: 36,
		part_id: 117,
		quantity: 44,
	},
	{
		garage_id: 36,
		part_id: 118,
		quantity: 432,
	},
	{
		garage_id: 36,
		part_id: 119,
		quantity: 319,
	},
	{
		garage_id: 36,
		part_id: 120,
		quantity: 179,
	},
	{
		garage_id: 36,
		part_id: 121,
		quantity: 86,
	},
	{
		garage_id: 36,
		part_id: 122,
		quantity: 404,
	},
	{
		garage_id: 36,
		part_id: 123,
		quantity: 70,
	},
	{
		garage_id: 36,
		part_id: 124,
		quantity: 276,
	},
	{
		garage_id: 36,
		part_id: 125,
		quantity: 473,
	},
	{
		garage_id: 36,
		part_id: 126,
		quantity: 174,
	},
	{
		garage_id: 36,
		part_id: 127,
		quantity: 0,
	},
	{
		garage_id: 36,
		part_id: 128,
		quantity: 374,
	},
	{
		garage_id: 36,
		part_id: 129,
		quantity: 354,
	},
	{
		garage_id: 36,
		part_id: 130,
		quantity: 75,
	},
	{
		garage_id: 36,
		part_id: 131,
		quantity: 401,
	},
	{
		garage_id: 36,
		part_id: 132,
		quantity: 153,
	},
	{
		garage_id: 36,
		part_id: 133,
		quantity: 234,
	},
	{
		garage_id: 36,
		part_id: 134,
		quantity: 51,
	},
	{
		garage_id: 36,
		part_id: 135,
		quantity: 185,
	},
	{
		garage_id: 36,
		part_id: 136,
		quantity: 85,
	},
	{
		garage_id: 36,
		part_id: 137,
		quantity: 253,
	},
	{
		garage_id: 36,
		part_id: 138,
		quantity: 473,
	},
	{
		garage_id: 36,
		part_id: 139,
		quantity: 408,
	},
	{
		garage_id: 36,
		part_id: 140,
		quantity: 193,
	},
	{
		garage_id: 36,
		part_id: 141,
		quantity: 125,
	},
	{
		garage_id: 36,
		part_id: 142,
		quantity: 362,
	},
	{
		garage_id: 36,
		part_id: 143,
		quantity: 197,
	},
	{
		garage_id: 36,
		part_id: 144,
		quantity: 194,
	},
	{
		garage_id: 36,
		part_id: 145,
		quantity: 481,
	},
	{
		garage_id: 36,
		part_id: 146,
		quantity: 442,
	},
	{
		garage_id: 36,
		part_id: 147,
		quantity: 268,
	},
	{
		garage_id: 36,
		part_id: 148,
		quantity: 418,
	},
	{
		garage_id: 36,
		part_id: 149,
		quantity: 247,
	},
	{
		garage_id: 36,
		part_id: 150,
		quantity: 12,
	},
	{
		garage_id: 36,
		part_id: 151,
		quantity: 299,
	},
	{
		garage_id: 36,
		part_id: 152,
		quantity: 282,
	},
	{
		garage_id: 36,
		part_id: 153,
		quantity: 299,
	},
	{
		garage_id: 36,
		part_id: 154,
		quantity: 5,
	},
	{
		garage_id: 36,
		part_id: 155,
		quantity: 146,
	},
	{
		garage_id: 36,
		part_id: 156,
		quantity: 181,
	},
	{
		garage_id: 36,
		part_id: 157,
		quantity: 21,
	},
	{
		garage_id: 36,
		part_id: 158,
		quantity: 276,
	},
	{
		garage_id: 36,
		part_id: 159,
		quantity: 100,
	},
	{
		garage_id: 36,
		part_id: 160,
		quantity: 415,
	},
	{
		garage_id: 36,
		part_id: 161,
		quantity: 317,
	},
	{
		garage_id: 36,
		part_id: 162,
		quantity: 486,
	},
	{
		garage_id: 36,
		part_id: 163,
		quantity: 128,
	},
	{
		garage_id: 36,
		part_id: 164,
		quantity: 423,
	},
	{
		garage_id: 36,
		part_id: 165,
		quantity: 241,
	},
	{
		garage_id: 36,
		part_id: 166,
		quantity: 92,
	},
	{
		garage_id: 36,
		part_id: 167,
		quantity: 420,
	},
	{
		garage_id: 36,
		part_id: 168,
		quantity: 27,
	},
	{
		garage_id: 36,
		part_id: 169,
		quantity: 237,
	},
	{
		garage_id: 36,
		part_id: 170,
		quantity: 215,
	},
	{
		garage_id: 36,
		part_id: 171,
		quantity: 294,
	},
	{
		garage_id: 36,
		part_id: 172,
		quantity: 186,
	},
	{
		garage_id: 36,
		part_id: 173,
		quantity: 459,
	},
	{
		garage_id: 36,
		part_id: 174,
		quantity: 433,
	},
	{
		garage_id: 36,
		part_id: 175,
		quantity: 465,
	},
	{
		garage_id: 36,
		part_id: 176,
		quantity: 102,
	},
	{
		garage_id: 36,
		part_id: 177,
		quantity: 412,
	},
	{
		garage_id: 36,
		part_id: 178,
		quantity: 442,
	},
	{
		garage_id: 36,
		part_id: 179,
		quantity: 204,
	},
	{
		garage_id: 36,
		part_id: 180,
		quantity: 277,
	},
	{
		garage_id: 36,
		part_id: 181,
		quantity: 403,
	},
	{
		garage_id: 36,
		part_id: 182,
		quantity: 314,
	},
	{
		garage_id: 36,
		part_id: 183,
		quantity: 77,
	},
	{
		garage_id: 36,
		part_id: 184,
		quantity: 95,
	},
	{
		garage_id: 36,
		part_id: 185,
		quantity: 84,
	},
	{
		garage_id: 36,
		part_id: 186,
		quantity: 270,
	},
	{
		garage_id: 36,
		part_id: 187,
		quantity: 292,
	},
	{
		garage_id: 36,
		part_id: 188,
		quantity: 351,
	},
	{
		garage_id: 36,
		part_id: 189,
		quantity: 259,
	},
	{
		garage_id: 36,
		part_id: 190,
		quantity: 239,
	},
	{
		garage_id: 36,
		part_id: 191,
		quantity: 267,
	},
	{
		garage_id: 36,
		part_id: 192,
		quantity: 98,
	},
	{
		garage_id: 36,
		part_id: 193,
		quantity: 401,
	},
	{
		garage_id: 36,
		part_id: 194,
		quantity: 92,
	},
	{
		garage_id: 36,
		part_id: 195,
		quantity: 430,
	},
	{
		garage_id: 36,
		part_id: 196,
		quantity: 196,
	},
	{
		garage_id: 36,
		part_id: 197,
		quantity: 296,
	},
	{
		garage_id: 36,
		part_id: 198,
		quantity: 245,
	},
	{
		garage_id: 36,
		part_id: 199,
		quantity: 22,
	},
	{
		garage_id: 36,
		part_id: 200,
		quantity: 113,
	},
	{
		garage_id: 36,
		part_id: 201,
		quantity: 401,
	},
	{
		garage_id: 36,
		part_id: 202,
		quantity: 207,
	},
	{
		garage_id: 36,
		part_id: 203,
		quantity: 238,
	},
	{
		garage_id: 36,
		part_id: 204,
		quantity: 182,
	},
	{
		garage_id: 36,
		part_id: 205,
		quantity: 329,
	},
	{
		garage_id: 36,
		part_id: 206,
		quantity: 256,
	},
	{
		garage_id: 36,
		part_id: 207,
		quantity: 268,
	},
	{
		garage_id: 36,
		part_id: 208,
		quantity: 32,
	},
	{
		garage_id: 36,
		part_id: 209,
		quantity: 108,
	},
	{
		garage_id: 36,
		part_id: 210,
		quantity: 487,
	},
	{
		garage_id: 36,
		part_id: 211,
		quantity: 186,
	},
	{
		garage_id: 36,
		part_id: 212,
		quantity: 88,
	},
	{
		garage_id: 36,
		part_id: 213,
		quantity: 418,
	},
	{
		garage_id: 36,
		part_id: 214,
		quantity: 281,
	},
	{
		garage_id: 36,
		part_id: 215,
		quantity: 345,
	},
	{
		garage_id: 36,
		part_id: 216,
		quantity: 473,
	},
	{
		garage_id: 36,
		part_id: 217,
		quantity: 179,
	},
	{
		garage_id: 36,
		part_id: 218,
		quantity: 317,
	},
	{
		garage_id: 36,
		part_id: 219,
		quantity: 15,
	},
	{
		garage_id: 36,
		part_id: 220,
		quantity: 473,
	},
	{
		garage_id: 36,
		part_id: 221,
		quantity: 247,
	},
	{
		garage_id: 36,
		part_id: 222,
		quantity: 213,
	},
	{
		garage_id: 36,
		part_id: 223,
		quantity: 182,
	},
	{
		garage_id: 36,
		part_id: 224,
		quantity: 305,
	},
	{
		garage_id: 36,
		part_id: 225,
		quantity: 389,
	},
	{
		garage_id: 36,
		part_id: 226,
		quantity: 476,
	},
	{
		garage_id: 36,
		part_id: 227,
		quantity: 456,
	},
	{
		garage_id: 36,
		part_id: 228,
		quantity: 182,
	},
	{
		garage_id: 36,
		part_id: 229,
		quantity: 162,
	},
	{
		garage_id: 36,
		part_id: 230,
		quantity: 289,
	},
	{
		garage_id: 36,
		part_id: 231,
		quantity: 29,
	},
	{
		garage_id: 36,
		part_id: 232,
		quantity: 29,
	},
	{
		garage_id: 36,
		part_id: 233,
		quantity: 303,
	},
	{
		garage_id: 36,
		part_id: 234,
		quantity: 181,
	},
	{
		garage_id: 36,
		part_id: 235,
		quantity: 109,
	},
	{
		garage_id: 36,
		part_id: 236,
		quantity: 296,
	},
	{
		garage_id: 36,
		part_id: 237,
		quantity: 362,
	},
	{
		garage_id: 36,
		part_id: 238,
		quantity: 260,
	},
	{
		garage_id: 36,
		part_id: 239,
		quantity: 150,
	},
	{
		garage_id: 36,
		part_id: 240,
		quantity: 328,
	},
	{
		garage_id: 36,
		part_id: 241,
		quantity: 308,
	},
	{
		garage_id: 36,
		part_id: 242,
		quantity: 410,
	},
	{
		garage_id: 36,
		part_id: 243,
		quantity: 245,
	},
	{
		garage_id: 36,
		part_id: 244,
		quantity: 4,
	},
	{
		garage_id: 36,
		part_id: 245,
		quantity: 151,
	},
	{
		garage_id: 36,
		part_id: 246,
		quantity: 491,
	},
	{
		garage_id: 36,
		part_id: 247,
		quantity: 242,
	},
	{
		garage_id: 36,
		part_id: 248,
		quantity: 470,
	},
	{
		garage_id: 36,
		part_id: 249,
		quantity: 31,
	},
	{
		garage_id: 36,
		part_id: 250,
		quantity: 366,
	},
	{
		garage_id: 36,
		part_id: 251,
		quantity: 113,
	},
	{
		garage_id: 36,
		part_id: 252,
		quantity: 368,
	},
	{
		garage_id: 36,
		part_id: 253,
		quantity: 144,
	},
	{
		garage_id: 36,
		part_id: 254,
		quantity: 1,
	},
	{
		garage_id: 36,
		part_id: 255,
		quantity: 171,
	},
	{
		garage_id: 36,
		part_id: 256,
		quantity: 68,
	},
	{
		garage_id: 36,
		part_id: 257,
		quantity: 350,
	},
	{
		garage_id: 36,
		part_id: 258,
		quantity: 498,
	},
	{
		garage_id: 36,
		part_id: 259,
		quantity: 297,
	},
	{
		garage_id: 36,
		part_id: 260,
		quantity: 404,
	},
	{
		garage_id: 36,
		part_id: 261,
		quantity: 136,
	},
	{
		garage_id: 36,
		part_id: 262,
		quantity: 35,
	},
	{
		garage_id: 36,
		part_id: 263,
		quantity: 297,
	},
	{
		garage_id: 36,
		part_id: 264,
		quantity: 56,
	},
	{
		garage_id: 36,
		part_id: 265,
		quantity: 412,
	},
	{
		garage_id: 36,
		part_id: 266,
		quantity: 435,
	},
	{
		garage_id: 36,
		part_id: 267,
		quantity: 492,
	},
	{
		garage_id: 36,
		part_id: 268,
		quantity: 144,
	},
	{
		garage_id: 36,
		part_id: 269,
		quantity: 476,
	},
	{
		garage_id: 36,
		part_id: 270,
		quantity: 170,
	},
	{
		garage_id: 36,
		part_id: 271,
		quantity: 480,
	},
	{
		garage_id: 36,
		part_id: 272,
		quantity: 274,
	},
	{
		garage_id: 36,
		part_id: 273,
		quantity: 412,
	},
	{
		garage_id: 36,
		part_id: 274,
		quantity: 317,
	},
	{
		garage_id: 36,
		part_id: 275,
		quantity: 89,
	},
	{
		garage_id: 36,
		part_id: 276,
		quantity: 319,
	},
	{
		garage_id: 36,
		part_id: 277,
		quantity: 231,
	},
	{
		garage_id: 36,
		part_id: 278,
		quantity: 15,
	},
	{
		garage_id: 36,
		part_id: 279,
		quantity: 329,
	},
	{
		garage_id: 36,
		part_id: 280,
		quantity: 275,
	},
	{
		garage_id: 36,
		part_id: 281,
		quantity: 432,
	},
	{
		garage_id: 36,
		part_id: 282,
		quantity: 401,
	},
	{
		garage_id: 36,
		part_id: 283,
		quantity: 86,
	},
	{
		garage_id: 36,
		part_id: 284,
		quantity: 329,
	},
	{
		garage_id: 36,
		part_id: 285,
		quantity: 474,
	},
	{
		garage_id: 36,
		part_id: 286,
		quantity: 360,
	},
	{
		garage_id: 36,
		part_id: 287,
		quantity: 16,
	},
	{
		garage_id: 36,
		part_id: 288,
		quantity: 446,
	},
	{
		garage_id: 36,
		part_id: 289,
		quantity: 208,
	},
	{
		garage_id: 36,
		part_id: 290,
		quantity: 400,
	},
	{
		garage_id: 36,
		part_id: 291,
		quantity: 483,
	},
	{
		garage_id: 36,
		part_id: 292,
		quantity: 287,
	},
	{
		garage_id: 36,
		part_id: 293,
		quantity: 17,
	},
	{
		garage_id: 36,
		part_id: 294,
		quantity: 265,
	},
	{
		garage_id: 36,
		part_id: 295,
		quantity: 495,
	},
	{
		garage_id: 36,
		part_id: 296,
		quantity: 409,
	},
	{
		garage_id: 36,
		part_id: 297,
		quantity: 207,
	},
	{
		garage_id: 36,
		part_id: 298,
		quantity: 395,
	},
	{
		garage_id: 36,
		part_id: 299,
		quantity: 492,
	},
	{
		garage_id: 36,
		part_id: 300,
		quantity: 109,
	},
	{
		garage_id: 36,
		part_id: 301,
		quantity: 200,
	},
	{
		garage_id: 36,
		part_id: 302,
		quantity: 479,
	},
	{
		garage_id: 36,
		part_id: 303,
		quantity: 218,
	},
	{
		garage_id: 36,
		part_id: 304,
		quantity: 80,
	},
	{
		garage_id: 36,
		part_id: 305,
		quantity: 364,
	},
	{
		garage_id: 36,
		part_id: 306,
		quantity: 330,
	},
	{
		garage_id: 36,
		part_id: 307,
		quantity: 62,
	},
	{
		garage_id: 36,
		part_id: 308,
		quantity: 118,
	},
	{
		garage_id: 36,
		part_id: 309,
		quantity: 361,
	},
	{
		garage_id: 36,
		part_id: 310,
		quantity: 489,
	},
	{
		garage_id: 36,
		part_id: 311,
		quantity: 55,
	},
	{
		garage_id: 36,
		part_id: 312,
		quantity: 117,
	},
	{
		garage_id: 36,
		part_id: 313,
		quantity: 20,
	},
	{
		garage_id: 36,
		part_id: 314,
		quantity: 233,
	},
	{
		garage_id: 36,
		part_id: 315,
		quantity: 39,
	},
	{
		garage_id: 36,
		part_id: 316,
		quantity: 382,
	},
	{
		garage_id: 36,
		part_id: 317,
		quantity: 208,
	},
	{
		garage_id: 36,
		part_id: 318,
		quantity: 304,
	},
	{
		garage_id: 36,
		part_id: 319,
		quantity: 182,
	},
	{
		garage_id: 36,
		part_id: 320,
		quantity: 83,
	},
	{
		garage_id: 36,
		part_id: 321,
		quantity: 36,
	},
	{
		garage_id: 36,
		part_id: 322,
		quantity: 180,
	},
	{
		garage_id: 36,
		part_id: 323,
		quantity: 203,
	},
	{
		garage_id: 36,
		part_id: 324,
		quantity: 351,
	},
	{
		garage_id: 36,
		part_id: 325,
		quantity: 12,
	},
	{
		garage_id: 36,
		part_id: 326,
		quantity: 456,
	},
	{
		garage_id: 36,
		part_id: 327,
		quantity: 467,
	},
	{
		garage_id: 36,
		part_id: 328,
		quantity: 179,
	},
	{
		garage_id: 36,
		part_id: 329,
		quantity: 195,
	},
	{
		garage_id: 36,
		part_id: 330,
		quantity: 296,
	},
	{
		garage_id: 36,
		part_id: 331,
		quantity: 273,
	},
	{
		garage_id: 36,
		part_id: 332,
		quantity: 241,
	},
	{
		garage_id: 36,
		part_id: 333,
		quantity: 117,
	},
	{
		garage_id: 36,
		part_id: 334,
		quantity: 169,
	},
	{
		garage_id: 36,
		part_id: 335,
		quantity: 340,
	},
	{
		garage_id: 36,
		part_id: 336,
		quantity: 70,
	},
	{
		garage_id: 36,
		part_id: 337,
		quantity: 209,
	},
	{
		garage_id: 36,
		part_id: 338,
		quantity: 288,
	},
	{
		garage_id: 36,
		part_id: 339,
		quantity: 31,
	},
	{
		garage_id: 36,
		part_id: 340,
		quantity: 289,
	},
	{
		garage_id: 36,
		part_id: 341,
		quantity: 447,
	},
	{
		garage_id: 36,
		part_id: 342,
		quantity: 21,
	},
	{
		garage_id: 36,
		part_id: 343,
		quantity: 35,
	},
	{
		garage_id: 36,
		part_id: 344,
		quantity: 155,
	},
	{
		garage_id: 36,
		part_id: 345,
		quantity: 258,
	},
	{
		garage_id: 36,
		part_id: 346,
		quantity: 317,
	},
	{
		garage_id: 36,
		part_id: 347,
		quantity: 324,
	},
	{
		garage_id: 36,
		part_id: 348,
		quantity: 249,
	},
	{
		garage_id: 36,
		part_id: 349,
		quantity: 445,
	},
	{
		garage_id: 36,
		part_id: 350,
		quantity: 184,
	},
	{
		garage_id: 36,
		part_id: 351,
		quantity: 151,
	},
	{
		garage_id: 36,
		part_id: 352,
		quantity: 257,
	},
	{
		garage_id: 36,
		part_id: 353,
		quantity: 142,
	},
	{
		garage_id: 36,
		part_id: 354,
		quantity: 42,
	},
	{
		garage_id: 36,
		part_id: 355,
		quantity: 55,
	},
	{
		garage_id: 36,
		part_id: 356,
		quantity: 300,
	},
	{
		garage_id: 36,
		part_id: 357,
		quantity: 292,
	},
	{
		garage_id: 36,
		part_id: 358,
		quantity: 146,
	},
	{
		garage_id: 36,
		part_id: 359,
		quantity: 416,
	},
	{
		garage_id: 36,
		part_id: 360,
		quantity: 390,
	},
	{
		garage_id: 36,
		part_id: 361,
		quantity: 250,
	},
	{
		garage_id: 36,
		part_id: 362,
		quantity: 170,
	},
	{
		garage_id: 36,
		part_id: 363,
		quantity: 405,
	},
	{
		garage_id: 36,
		part_id: 364,
		quantity: 223,
	},
	{
		garage_id: 36,
		part_id: 365,
		quantity: 461,
	},
	{
		garage_id: 36,
		part_id: 366,
		quantity: 117,
	},
	{
		garage_id: 36,
		part_id: 367,
		quantity: 363,
	},
	{
		garage_id: 36,
		part_id: 368,
		quantity: 445,
	},
	{
		garage_id: 36,
		part_id: 369,
		quantity: 121,
	},
	{
		garage_id: 36,
		part_id: 370,
		quantity: 263,
	},
	{
		garage_id: 36,
		part_id: 371,
		quantity: 471,
	},
	{
		garage_id: 36,
		part_id: 372,
		quantity: 460,
	},
	{
		garage_id: 36,
		part_id: 373,
		quantity: 227,
	},
	{
		garage_id: 36,
		part_id: 374,
		quantity: 301,
	},
	{
		garage_id: 36,
		part_id: 375,
		quantity: 116,
	},
	{
		garage_id: 36,
		part_id: 376,
		quantity: 420,
	},
	{
		garage_id: 36,
		part_id: 377,
		quantity: 110,
	},
	{
		garage_id: 36,
		part_id: 378,
		quantity: 93,
	},
	{
		garage_id: 36,
		part_id: 379,
		quantity: 115,
	},
	{
		garage_id: 36,
		part_id: 380,
		quantity: 177,
	},
	{
		garage_id: 36,
		part_id: 381,
		quantity: 207,
	},
	{
		garage_id: 36,
		part_id: 382,
		quantity: 315,
	},
	{
		garage_id: 36,
		part_id: 383,
		quantity: 297,
	},
	{
		garage_id: 36,
		part_id: 384,
		quantity: 441,
	},
	{
		garage_id: 36,
		part_id: 385,
		quantity: 48,
	},
	{
		garage_id: 36,
		part_id: 386,
		quantity: 374,
	},
	{
		garage_id: 36,
		part_id: 387,
		quantity: 386,
	},
	{
		garage_id: 36,
		part_id: 388,
		quantity: 26,
	},
	{
		garage_id: 36,
		part_id: 389,
		quantity: 232,
	},
	{
		garage_id: 36,
		part_id: 390,
		quantity: 53,
	},
	{
		garage_id: 36,
		part_id: 391,
		quantity: 18,
	},
	{
		garage_id: 36,
		part_id: 392,
		quantity: 254,
	},
	{
		garage_id: 36,
		part_id: 393,
		quantity: 77,
	},
	{
		garage_id: 36,
		part_id: 394,
		quantity: 321,
	},
	{
		garage_id: 36,
		part_id: 395,
		quantity: 209,
	},
	{
		garage_id: 36,
		part_id: 396,
		quantity: 129,
	},
	{
		garage_id: 36,
		part_id: 397,
		quantity: 191,
	},
	{
		garage_id: 36,
		part_id: 398,
		quantity: 193,
	},
	{
		garage_id: 36,
		part_id: 399,
		quantity: 483,
	},
	{
		garage_id: 37,
		part_id: 0,
		quantity: 94,
	},
	{
		garage_id: 37,
		part_id: 1,
		quantity: 304,
	},
	{
		garage_id: 37,
		part_id: 2,
		quantity: 85,
	},
	{
		garage_id: 37,
		part_id: 3,
		quantity: 387,
	},
	{
		garage_id: 37,
		part_id: 4,
		quantity: 470,
	},
	{
		garage_id: 37,
		part_id: 5,
		quantity: 49,
	},
	{
		garage_id: 37,
		part_id: 6,
		quantity: 70,
	},
	{
		garage_id: 37,
		part_id: 7,
		quantity: 70,
	},
	{
		garage_id: 37,
		part_id: 8,
		quantity: 41,
	},
	{
		garage_id: 37,
		part_id: 9,
		quantity: 29,
	},
	{
		garage_id: 37,
		part_id: 10,
		quantity: 201,
	},
	{
		garage_id: 37,
		part_id: 11,
		quantity: 316,
	},
	{
		garage_id: 37,
		part_id: 12,
		quantity: 151,
	},
	{
		garage_id: 37,
		part_id: 13,
		quantity: 105,
	},
	{
		garage_id: 37,
		part_id: 14,
		quantity: 236,
	},
	{
		garage_id: 37,
		part_id: 15,
		quantity: 313,
	},
	{
		garage_id: 37,
		part_id: 16,
		quantity: 465,
	},
	{
		garage_id: 37,
		part_id: 17,
		quantity: 436,
	},
	{
		garage_id: 37,
		part_id: 18,
		quantity: 496,
	},
	{
		garage_id: 37,
		part_id: 19,
		quantity: 393,
	},
	{
		garage_id: 37,
		part_id: 20,
		quantity: 392,
	},
	{
		garage_id: 37,
		part_id: 21,
		quantity: 194,
	},
	{
		garage_id: 37,
		part_id: 22,
		quantity: 71,
	},
	{
		garage_id: 37,
		part_id: 23,
		quantity: 224,
	},
	{
		garage_id: 37,
		part_id: 24,
		quantity: 26,
	},
	{
		garage_id: 37,
		part_id: 25,
		quantity: 128,
	},
	{
		garage_id: 37,
		part_id: 26,
		quantity: 209,
	},
	{
		garage_id: 37,
		part_id: 27,
		quantity: 138,
	},
	{
		garage_id: 37,
		part_id: 28,
		quantity: 455,
	},
	{
		garage_id: 37,
		part_id: 29,
		quantity: 266,
	},
	{
		garage_id: 37,
		part_id: 30,
		quantity: 250,
	},
	{
		garage_id: 37,
		part_id: 31,
		quantity: 264,
	},
	{
		garage_id: 37,
		part_id: 32,
		quantity: 413,
	},
	{
		garage_id: 37,
		part_id: 33,
		quantity: 71,
	},
	{
		garage_id: 37,
		part_id: 34,
		quantity: 235,
	},
	{
		garage_id: 37,
		part_id: 35,
		quantity: 335,
	},
	{
		garage_id: 37,
		part_id: 36,
		quantity: 500,
	},
	{
		garage_id: 37,
		part_id: 37,
		quantity: 262,
	},
	{
		garage_id: 37,
		part_id: 38,
		quantity: 228,
	},
	{
		garage_id: 37,
		part_id: 39,
		quantity: 118,
	},
	{
		garage_id: 37,
		part_id: 40,
		quantity: 165,
	},
	{
		garage_id: 37,
		part_id: 41,
		quantity: 423,
	},
	{
		garage_id: 37,
		part_id: 42,
		quantity: 414,
	},
	{
		garage_id: 37,
		part_id: 43,
		quantity: 277,
	},
	{
		garage_id: 37,
		part_id: 44,
		quantity: 348,
	},
	{
		garage_id: 37,
		part_id: 45,
		quantity: 215,
	},
	{
		garage_id: 37,
		part_id: 46,
		quantity: 488,
	},
	{
		garage_id: 37,
		part_id: 47,
		quantity: 182,
	},
	{
		garage_id: 37,
		part_id: 48,
		quantity: 357,
	},
	{
		garage_id: 37,
		part_id: 49,
		quantity: 283,
	},
	{
		garage_id: 37,
		part_id: 50,
		quantity: 475,
	},
	{
		garage_id: 37,
		part_id: 51,
		quantity: 495,
	},
	{
		garage_id: 37,
		part_id: 52,
		quantity: 92,
	},
	{
		garage_id: 37,
		part_id: 53,
		quantity: 107,
	},
	{
		garage_id: 37,
		part_id: 54,
		quantity: 354,
	},
	{
		garage_id: 37,
		part_id: 55,
		quantity: 192,
	},
	{
		garage_id: 37,
		part_id: 56,
		quantity: 481,
	},
	{
		garage_id: 37,
		part_id: 57,
		quantity: 97,
	},
	{
		garage_id: 37,
		part_id: 58,
		quantity: 419,
	},
	{
		garage_id: 37,
		part_id: 59,
		quantity: 469,
	},
	{
		garage_id: 37,
		part_id: 60,
		quantity: 17,
	},
	{
		garage_id: 37,
		part_id: 61,
		quantity: 472,
	},
	{
		garage_id: 37,
		part_id: 62,
		quantity: 443,
	},
	{
		garage_id: 37,
		part_id: 63,
		quantity: 212,
	},
	{
		garage_id: 37,
		part_id: 64,
		quantity: 66,
	},
	{
		garage_id: 37,
		part_id: 65,
		quantity: 231,
	},
	{
		garage_id: 37,
		part_id: 66,
		quantity: 232,
	},
	{
		garage_id: 37,
		part_id: 67,
		quantity: 232,
	},
	{
		garage_id: 37,
		part_id: 68,
		quantity: 439,
	},
	{
		garage_id: 37,
		part_id: 69,
		quantity: 323,
	},
	{
		garage_id: 37,
		part_id: 70,
		quantity: 428,
	},
	{
		garage_id: 37,
		part_id: 71,
		quantity: 260,
	},
	{
		garage_id: 37,
		part_id: 72,
		quantity: 306,
	},
	{
		garage_id: 37,
		part_id: 73,
		quantity: 92,
	},
	{
		garage_id: 37,
		part_id: 74,
		quantity: 280,
	},
	{
		garage_id: 37,
		part_id: 75,
		quantity: 100,
	},
	{
		garage_id: 37,
		part_id: 76,
		quantity: 287,
	},
	{
		garage_id: 37,
		part_id: 77,
		quantity: 277,
	},
	{
		garage_id: 37,
		part_id: 78,
		quantity: 50,
	},
	{
		garage_id: 37,
		part_id: 79,
		quantity: 78,
	},
	{
		garage_id: 37,
		part_id: 80,
		quantity: 304,
	},
	{
		garage_id: 37,
		part_id: 81,
		quantity: 274,
	},
	{
		garage_id: 37,
		part_id: 82,
		quantity: 445,
	},
	{
		garage_id: 37,
		part_id: 83,
		quantity: 161,
	},
	{
		garage_id: 37,
		part_id: 84,
		quantity: 198,
	},
	{
		garage_id: 37,
		part_id: 85,
		quantity: 71,
	},
	{
		garage_id: 37,
		part_id: 86,
		quantity: 122,
	},
	{
		garage_id: 37,
		part_id: 87,
		quantity: 50,
	},
	{
		garage_id: 37,
		part_id: 88,
		quantity: 374,
	},
	{
		garage_id: 37,
		part_id: 89,
		quantity: 41,
	},
	{
		garage_id: 37,
		part_id: 90,
		quantity: 192,
	},
	{
		garage_id: 37,
		part_id: 91,
		quantity: 190,
	},
	{
		garage_id: 37,
		part_id: 92,
		quantity: 449,
	},
	{
		garage_id: 37,
		part_id: 93,
		quantity: 220,
	},
	{
		garage_id: 37,
		part_id: 94,
		quantity: 429,
	},
	{
		garage_id: 37,
		part_id: 95,
		quantity: 305,
	},
	{
		garage_id: 37,
		part_id: 96,
		quantity: 188,
	},
	{
		garage_id: 37,
		part_id: 97,
		quantity: 28,
	},
	{
		garage_id: 37,
		part_id: 98,
		quantity: 65,
	},
	{
		garage_id: 37,
		part_id: 99,
		quantity: 66,
	},
	{
		garage_id: 37,
		part_id: 100,
		quantity: 421,
	},
	{
		garage_id: 37,
		part_id: 101,
		quantity: 208,
	},
	{
		garage_id: 37,
		part_id: 102,
		quantity: 249,
	},
	{
		garage_id: 37,
		part_id: 103,
		quantity: 289,
	},
	{
		garage_id: 37,
		part_id: 104,
		quantity: 206,
	},
	{
		garage_id: 37,
		part_id: 105,
		quantity: 405,
	},
	{
		garage_id: 37,
		part_id: 106,
		quantity: 321,
	},
	{
		garage_id: 37,
		part_id: 107,
		quantity: 333,
	},
	{
		garage_id: 37,
		part_id: 108,
		quantity: 300,
	},
	{
		garage_id: 37,
		part_id: 109,
		quantity: 291,
	},
	{
		garage_id: 37,
		part_id: 110,
		quantity: 403,
	},
	{
		garage_id: 37,
		part_id: 111,
		quantity: 35,
	},
	{
		garage_id: 37,
		part_id: 112,
		quantity: 230,
	},
	{
		garage_id: 37,
		part_id: 113,
		quantity: 290,
	},
	{
		garage_id: 37,
		part_id: 114,
		quantity: 36,
	},
	{
		garage_id: 37,
		part_id: 115,
		quantity: 399,
	},
	{
		garage_id: 37,
		part_id: 116,
		quantity: 197,
	},
	{
		garage_id: 37,
		part_id: 117,
		quantity: 375,
	},
	{
		garage_id: 37,
		part_id: 118,
		quantity: 134,
	},
	{
		garage_id: 37,
		part_id: 119,
		quantity: 442,
	},
	{
		garage_id: 37,
		part_id: 120,
		quantity: 206,
	},
	{
		garage_id: 37,
		part_id: 121,
		quantity: 308,
	},
	{
		garage_id: 37,
		part_id: 122,
		quantity: 149,
	},
	{
		garage_id: 37,
		part_id: 123,
		quantity: 275,
	},
	{
		garage_id: 37,
		part_id: 124,
		quantity: 248,
	},
	{
		garage_id: 37,
		part_id: 125,
		quantity: 437,
	},
	{
		garage_id: 37,
		part_id: 126,
		quantity: 48,
	},
	{
		garage_id: 37,
		part_id: 127,
		quantity: 44,
	},
	{
		garage_id: 37,
		part_id: 128,
		quantity: 90,
	},
	{
		garage_id: 37,
		part_id: 129,
		quantity: 165,
	},
	{
		garage_id: 37,
		part_id: 130,
		quantity: 205,
	},
	{
		garage_id: 37,
		part_id: 131,
		quantity: 410,
	},
	{
		garage_id: 37,
		part_id: 132,
		quantity: 229,
	},
	{
		garage_id: 37,
		part_id: 133,
		quantity: 120,
	},
	{
		garage_id: 37,
		part_id: 134,
		quantity: 331,
	},
	{
		garage_id: 37,
		part_id: 135,
		quantity: 278,
	},
	{
		garage_id: 37,
		part_id: 136,
		quantity: 192,
	},
	{
		garage_id: 37,
		part_id: 137,
		quantity: 66,
	},
	{
		garage_id: 37,
		part_id: 138,
		quantity: 234,
	},
	{
		garage_id: 37,
		part_id: 139,
		quantity: 248,
	},
	{
		garage_id: 37,
		part_id: 140,
		quantity: 152,
	},
	{
		garage_id: 37,
		part_id: 141,
		quantity: 97,
	},
	{
		garage_id: 37,
		part_id: 142,
		quantity: 178,
	},
	{
		garage_id: 37,
		part_id: 143,
		quantity: 431,
	},
	{
		garage_id: 37,
		part_id: 144,
		quantity: 232,
	},
	{
		garage_id: 37,
		part_id: 145,
		quantity: 222,
	},
	{
		garage_id: 37,
		part_id: 146,
		quantity: 175,
	},
	{
		garage_id: 37,
		part_id: 147,
		quantity: 315,
	},
	{
		garage_id: 37,
		part_id: 148,
		quantity: 113,
	},
	{
		garage_id: 37,
		part_id: 149,
		quantity: 290,
	},
	{
		garage_id: 37,
		part_id: 150,
		quantity: 453,
	},
	{
		garage_id: 37,
		part_id: 151,
		quantity: 344,
	},
	{
		garage_id: 37,
		part_id: 152,
		quantity: 324,
	},
	{
		garage_id: 37,
		part_id: 153,
		quantity: 210,
	},
	{
		garage_id: 37,
		part_id: 154,
		quantity: 77,
	},
	{
		garage_id: 37,
		part_id: 155,
		quantity: 40,
	},
	{
		garage_id: 37,
		part_id: 156,
		quantity: 441,
	},
	{
		garage_id: 37,
		part_id: 157,
		quantity: 262,
	},
	{
		garage_id: 37,
		part_id: 158,
		quantity: 115,
	},
	{
		garage_id: 37,
		part_id: 159,
		quantity: 174,
	},
	{
		garage_id: 37,
		part_id: 160,
		quantity: 308,
	},
	{
		garage_id: 37,
		part_id: 161,
		quantity: 479,
	},
	{
		garage_id: 37,
		part_id: 162,
		quantity: 470,
	},
	{
		garage_id: 37,
		part_id: 163,
		quantity: 435,
	},
	{
		garage_id: 37,
		part_id: 164,
		quantity: 73,
	},
	{
		garage_id: 37,
		part_id: 165,
		quantity: 378,
	},
	{
		garage_id: 37,
		part_id: 166,
		quantity: 135,
	},
	{
		garage_id: 37,
		part_id: 167,
		quantity: 296,
	},
	{
		garage_id: 37,
		part_id: 168,
		quantity: 39,
	},
	{
		garage_id: 37,
		part_id: 169,
		quantity: 194,
	},
	{
		garage_id: 37,
		part_id: 170,
		quantity: 351,
	},
	{
		garage_id: 37,
		part_id: 171,
		quantity: 53,
	},
	{
		garage_id: 37,
		part_id: 172,
		quantity: 197,
	},
	{
		garage_id: 37,
		part_id: 173,
		quantity: 46,
	},
	{
		garage_id: 37,
		part_id: 174,
		quantity: 358,
	},
	{
		garage_id: 37,
		part_id: 175,
		quantity: 95,
	},
	{
		garage_id: 37,
		part_id: 176,
		quantity: 419,
	},
	{
		garage_id: 37,
		part_id: 177,
		quantity: 416,
	},
	{
		garage_id: 37,
		part_id: 178,
		quantity: 202,
	},
	{
		garage_id: 37,
		part_id: 179,
		quantity: 212,
	},
	{
		garage_id: 37,
		part_id: 180,
		quantity: 266,
	},
	{
		garage_id: 37,
		part_id: 181,
		quantity: 44,
	},
	{
		garage_id: 37,
		part_id: 182,
		quantity: 264,
	},
	{
		garage_id: 37,
		part_id: 183,
		quantity: 66,
	},
	{
		garage_id: 37,
		part_id: 184,
		quantity: 114,
	},
	{
		garage_id: 37,
		part_id: 185,
		quantity: 312,
	},
	{
		garage_id: 37,
		part_id: 186,
		quantity: 88,
	},
	{
		garage_id: 37,
		part_id: 187,
		quantity: 465,
	},
	{
		garage_id: 37,
		part_id: 188,
		quantity: 131,
	},
	{
		garage_id: 37,
		part_id: 189,
		quantity: 181,
	},
	{
		garage_id: 37,
		part_id: 190,
		quantity: 237,
	},
	{
		garage_id: 37,
		part_id: 191,
		quantity: 207,
	},
	{
		garage_id: 37,
		part_id: 192,
		quantity: 152,
	},
	{
		garage_id: 37,
		part_id: 193,
		quantity: 396,
	},
	{
		garage_id: 37,
		part_id: 194,
		quantity: 298,
	},
	{
		garage_id: 37,
		part_id: 195,
		quantity: 85,
	},
	{
		garage_id: 37,
		part_id: 196,
		quantity: 328,
	},
	{
		garage_id: 37,
		part_id: 197,
		quantity: 332,
	},
	{
		garage_id: 37,
		part_id: 198,
		quantity: 322,
	},
	{
		garage_id: 37,
		part_id: 199,
		quantity: 467,
	},
	{
		garage_id: 37,
		part_id: 200,
		quantity: 93,
	},
	{
		garage_id: 37,
		part_id: 201,
		quantity: 310,
	},
	{
		garage_id: 37,
		part_id: 202,
		quantity: 88,
	},
	{
		garage_id: 37,
		part_id: 203,
		quantity: 21,
	},
	{
		garage_id: 37,
		part_id: 204,
		quantity: 444,
	},
	{
		garage_id: 37,
		part_id: 205,
		quantity: 465,
	},
	{
		garage_id: 37,
		part_id: 206,
		quantity: 197,
	},
	{
		garage_id: 37,
		part_id: 207,
		quantity: 226,
	},
	{
		garage_id: 37,
		part_id: 208,
		quantity: 318,
	},
	{
		garage_id: 37,
		part_id: 209,
		quantity: 421,
	},
	{
		garage_id: 37,
		part_id: 210,
		quantity: 387,
	},
	{
		garage_id: 37,
		part_id: 211,
		quantity: 402,
	},
	{
		garage_id: 37,
		part_id: 212,
		quantity: 126,
	},
	{
		garage_id: 37,
		part_id: 213,
		quantity: 440,
	},
	{
		garage_id: 37,
		part_id: 214,
		quantity: 279,
	},
	{
		garage_id: 37,
		part_id: 215,
		quantity: 188,
	},
	{
		garage_id: 37,
		part_id: 216,
		quantity: 255,
	},
	{
		garage_id: 37,
		part_id: 217,
		quantity: 100,
	},
	{
		garage_id: 37,
		part_id: 218,
		quantity: 184,
	},
	{
		garage_id: 37,
		part_id: 219,
		quantity: 384,
	},
	{
		garage_id: 37,
		part_id: 220,
		quantity: 308,
	},
	{
		garage_id: 37,
		part_id: 221,
		quantity: 334,
	},
	{
		garage_id: 37,
		part_id: 222,
		quantity: 384,
	},
	{
		garage_id: 37,
		part_id: 223,
		quantity: 152,
	},
	{
		garage_id: 37,
		part_id: 224,
		quantity: 17,
	},
	{
		garage_id: 37,
		part_id: 225,
		quantity: 162,
	},
	{
		garage_id: 37,
		part_id: 226,
		quantity: 113,
	},
	{
		garage_id: 37,
		part_id: 227,
		quantity: 288,
	},
	{
		garage_id: 37,
		part_id: 228,
		quantity: 433,
	},
	{
		garage_id: 37,
		part_id: 229,
		quantity: 282,
	},
	{
		garage_id: 37,
		part_id: 230,
		quantity: 336,
	},
	{
		garage_id: 37,
		part_id: 231,
		quantity: 464,
	},
	{
		garage_id: 37,
		part_id: 232,
		quantity: 354,
	},
	{
		garage_id: 37,
		part_id: 233,
		quantity: 218,
	},
	{
		garage_id: 37,
		part_id: 234,
		quantity: 395,
	},
	{
		garage_id: 37,
		part_id: 235,
		quantity: 169,
	},
	{
		garage_id: 37,
		part_id: 236,
		quantity: 183,
	},
	{
		garage_id: 37,
		part_id: 237,
		quantity: 172,
	},
	{
		garage_id: 37,
		part_id: 238,
		quantity: 60,
	},
	{
		garage_id: 37,
		part_id: 239,
		quantity: 443,
	},
	{
		garage_id: 37,
		part_id: 240,
		quantity: 7,
	},
	{
		garage_id: 37,
		part_id: 241,
		quantity: 486,
	},
	{
		garage_id: 37,
		part_id: 242,
		quantity: 201,
	},
	{
		garage_id: 37,
		part_id: 243,
		quantity: 48,
	},
	{
		garage_id: 37,
		part_id: 244,
		quantity: 37,
	},
	{
		garage_id: 37,
		part_id: 245,
		quantity: 221,
	},
	{
		garage_id: 37,
		part_id: 246,
		quantity: 121,
	},
	{
		garage_id: 37,
		part_id: 247,
		quantity: 287,
	},
	{
		garage_id: 37,
		part_id: 248,
		quantity: 293,
	},
	{
		garage_id: 37,
		part_id: 249,
		quantity: 146,
	},
	{
		garage_id: 37,
		part_id: 250,
		quantity: 421,
	},
	{
		garage_id: 37,
		part_id: 251,
		quantity: 349,
	},
	{
		garage_id: 37,
		part_id: 252,
		quantity: 137,
	},
	{
		garage_id: 37,
		part_id: 253,
		quantity: 296,
	},
	{
		garage_id: 37,
		part_id: 254,
		quantity: 407,
	},
	{
		garage_id: 37,
		part_id: 255,
		quantity: 413,
	},
	{
		garage_id: 37,
		part_id: 256,
		quantity: 385,
	},
	{
		garage_id: 37,
		part_id: 257,
		quantity: 157,
	},
	{
		garage_id: 37,
		part_id: 258,
		quantity: 59,
	},
	{
		garage_id: 37,
		part_id: 259,
		quantity: 125,
	},
	{
		garage_id: 37,
		part_id: 260,
		quantity: 332,
	},
	{
		garage_id: 37,
		part_id: 261,
		quantity: 0,
	},
	{
		garage_id: 37,
		part_id: 262,
		quantity: 437,
	},
	{
		garage_id: 37,
		part_id: 263,
		quantity: 90,
	},
	{
		garage_id: 37,
		part_id: 264,
		quantity: 147,
	},
	{
		garage_id: 37,
		part_id: 265,
		quantity: 434,
	},
	{
		garage_id: 37,
		part_id: 266,
		quantity: 308,
	},
	{
		garage_id: 37,
		part_id: 267,
		quantity: 467,
	},
	{
		garage_id: 37,
		part_id: 268,
		quantity: 104,
	},
	{
		garage_id: 37,
		part_id: 269,
		quantity: 236,
	},
	{
		garage_id: 37,
		part_id: 270,
		quantity: 354,
	},
	{
		garage_id: 37,
		part_id: 271,
		quantity: 69,
	},
	{
		garage_id: 37,
		part_id: 272,
		quantity: 454,
	},
	{
		garage_id: 37,
		part_id: 273,
		quantity: 488,
	},
	{
		garage_id: 37,
		part_id: 274,
		quantity: 491,
	},
	{
		garage_id: 37,
		part_id: 275,
		quantity: 394,
	},
	{
		garage_id: 37,
		part_id: 276,
		quantity: 217,
	},
	{
		garage_id: 37,
		part_id: 277,
		quantity: 50,
	},
	{
		garage_id: 37,
		part_id: 278,
		quantity: 95,
	},
	{
		garage_id: 37,
		part_id: 279,
		quantity: 329,
	},
	{
		garage_id: 37,
		part_id: 280,
		quantity: 285,
	},
	{
		garage_id: 37,
		part_id: 281,
		quantity: 367,
	},
	{
		garage_id: 37,
		part_id: 282,
		quantity: 340,
	},
	{
		garage_id: 37,
		part_id: 283,
		quantity: 269,
	},
	{
		garage_id: 37,
		part_id: 284,
		quantity: 71,
	},
	{
		garage_id: 37,
		part_id: 285,
		quantity: 83,
	},
	{
		garage_id: 37,
		part_id: 286,
		quantity: 247,
	},
	{
		garage_id: 37,
		part_id: 287,
		quantity: 400,
	},
	{
		garage_id: 37,
		part_id: 288,
		quantity: 165,
	},
	{
		garage_id: 37,
		part_id: 289,
		quantity: 108,
	},
	{
		garage_id: 37,
		part_id: 290,
		quantity: 84,
	},
	{
		garage_id: 37,
		part_id: 291,
		quantity: 362,
	},
	{
		garage_id: 37,
		part_id: 292,
		quantity: 340,
	},
	{
		garage_id: 37,
		part_id: 293,
		quantity: 437,
	},
	{
		garage_id: 37,
		part_id: 294,
		quantity: 211,
	},
	{
		garage_id: 37,
		part_id: 295,
		quantity: 361,
	},
	{
		garage_id: 37,
		part_id: 296,
		quantity: 82,
	},
	{
		garage_id: 37,
		part_id: 297,
		quantity: 201,
	},
	{
		garage_id: 37,
		part_id: 298,
		quantity: 444,
	},
	{
		garage_id: 37,
		part_id: 299,
		quantity: 32,
	},
	{
		garage_id: 37,
		part_id: 300,
		quantity: 461,
	},
	{
		garage_id: 37,
		part_id: 301,
		quantity: 490,
	},
	{
		garage_id: 37,
		part_id: 302,
		quantity: 95,
	},
	{
		garage_id: 37,
		part_id: 303,
		quantity: 16,
	},
	{
		garage_id: 37,
		part_id: 304,
		quantity: 403,
	},
	{
		garage_id: 37,
		part_id: 305,
		quantity: 166,
	},
	{
		garage_id: 37,
		part_id: 306,
		quantity: 222,
	},
	{
		garage_id: 37,
		part_id: 307,
		quantity: 296,
	},
	{
		garage_id: 37,
		part_id: 308,
		quantity: 24,
	},
	{
		garage_id: 37,
		part_id: 309,
		quantity: 375,
	},
	{
		garage_id: 37,
		part_id: 310,
		quantity: 374,
	},
	{
		garage_id: 37,
		part_id: 311,
		quantity: 227,
	},
	{
		garage_id: 37,
		part_id: 312,
		quantity: 203,
	},
	{
		garage_id: 37,
		part_id: 313,
		quantity: 153,
	},
	{
		garage_id: 37,
		part_id: 314,
		quantity: 237,
	},
	{
		garage_id: 37,
		part_id: 315,
		quantity: 198,
	},
	{
		garage_id: 37,
		part_id: 316,
		quantity: 479,
	},
	{
		garage_id: 37,
		part_id: 317,
		quantity: 473,
	},
	{
		garage_id: 37,
		part_id: 318,
		quantity: 339,
	},
	{
		garage_id: 37,
		part_id: 319,
		quantity: 490,
	},
	{
		garage_id: 37,
		part_id: 320,
		quantity: 230,
	},
	{
		garage_id: 37,
		part_id: 321,
		quantity: 68,
	},
	{
		garage_id: 37,
		part_id: 322,
		quantity: 447,
	},
	{
		garage_id: 37,
		part_id: 323,
		quantity: 323,
	},
	{
		garage_id: 37,
		part_id: 324,
		quantity: 476,
	},
	{
		garage_id: 37,
		part_id: 325,
		quantity: 80,
	},
	{
		garage_id: 37,
		part_id: 326,
		quantity: 156,
	},
	{
		garage_id: 37,
		part_id: 327,
		quantity: 177,
	},
	{
		garage_id: 37,
		part_id: 328,
		quantity: 152,
	},
	{
		garage_id: 37,
		part_id: 329,
		quantity: 266,
	},
	{
		garage_id: 37,
		part_id: 330,
		quantity: 159,
	},
	{
		garage_id: 37,
		part_id: 331,
		quantity: 203,
	},
	{
		garage_id: 37,
		part_id: 332,
		quantity: 217,
	},
	{
		garage_id: 37,
		part_id: 333,
		quantity: 98,
	},
	{
		garage_id: 37,
		part_id: 334,
		quantity: 11,
	},
	{
		garage_id: 37,
		part_id: 335,
		quantity: 276,
	},
	{
		garage_id: 37,
		part_id: 336,
		quantity: 156,
	},
	{
		garage_id: 37,
		part_id: 337,
		quantity: 256,
	},
	{
		garage_id: 37,
		part_id: 338,
		quantity: 114,
	},
	{
		garage_id: 37,
		part_id: 339,
		quantity: 19,
	},
	{
		garage_id: 37,
		part_id: 340,
		quantity: 344,
	},
	{
		garage_id: 37,
		part_id: 341,
		quantity: 356,
	},
	{
		garage_id: 37,
		part_id: 342,
		quantity: 46,
	},
	{
		garage_id: 37,
		part_id: 343,
		quantity: 100,
	},
	{
		garage_id: 37,
		part_id: 344,
		quantity: 75,
	},
	{
		garage_id: 37,
		part_id: 345,
		quantity: 40,
	},
	{
		garage_id: 37,
		part_id: 346,
		quantity: 137,
	},
	{
		garage_id: 37,
		part_id: 347,
		quantity: 74,
	},
	{
		garage_id: 37,
		part_id: 348,
		quantity: 342,
	},
	{
		garage_id: 37,
		part_id: 349,
		quantity: 96,
	},
	{
		garage_id: 37,
		part_id: 350,
		quantity: 121,
	},
	{
		garage_id: 37,
		part_id: 351,
		quantity: 135,
	},
	{
		garage_id: 37,
		part_id: 352,
		quantity: 410,
	},
	{
		garage_id: 37,
		part_id: 353,
		quantity: 172,
	},
	{
		garage_id: 37,
		part_id: 354,
		quantity: 343,
	},
	{
		garage_id: 37,
		part_id: 355,
		quantity: 401,
	},
	{
		garage_id: 37,
		part_id: 356,
		quantity: 124,
	},
	{
		garage_id: 37,
		part_id: 357,
		quantity: 356,
	},
	{
		garage_id: 37,
		part_id: 358,
		quantity: 248,
	},
	{
		garage_id: 37,
		part_id: 359,
		quantity: 406,
	},
	{
		garage_id: 37,
		part_id: 360,
		quantity: 187,
	},
	{
		garage_id: 37,
		part_id: 361,
		quantity: 6,
	},
	{
		garage_id: 37,
		part_id: 362,
		quantity: 76,
	},
	{
		garage_id: 37,
		part_id: 363,
		quantity: 367,
	},
	{
		garage_id: 37,
		part_id: 364,
		quantity: 51,
	},
	{
		garage_id: 37,
		part_id: 365,
		quantity: 434,
	},
	{
		garage_id: 37,
		part_id: 366,
		quantity: 318,
	},
	{
		garage_id: 37,
		part_id: 367,
		quantity: 0,
	},
	{
		garage_id: 37,
		part_id: 368,
		quantity: 120,
	},
	{
		garage_id: 37,
		part_id: 369,
		quantity: 75,
	},
	{
		garage_id: 37,
		part_id: 370,
		quantity: 135,
	},
	{
		garage_id: 37,
		part_id: 371,
		quantity: 458,
	},
	{
		garage_id: 37,
		part_id: 372,
		quantity: 128,
	},
	{
		garage_id: 37,
		part_id: 373,
		quantity: 49,
	},
	{
		garage_id: 37,
		part_id: 374,
		quantity: 470,
	},
	{
		garage_id: 37,
		part_id: 375,
		quantity: 429,
	},
	{
		garage_id: 37,
		part_id: 376,
		quantity: 121,
	},
	{
		garage_id: 37,
		part_id: 377,
		quantity: 117,
	},
	{
		garage_id: 37,
		part_id: 378,
		quantity: 75,
	},
	{
		garage_id: 37,
		part_id: 379,
		quantity: 406,
	},
	{
		garage_id: 37,
		part_id: 380,
		quantity: 323,
	},
	{
		garage_id: 37,
		part_id: 381,
		quantity: 91,
	},
	{
		garage_id: 37,
		part_id: 382,
		quantity: 134,
	},
	{
		garage_id: 37,
		part_id: 383,
		quantity: 385,
	},
	{
		garage_id: 37,
		part_id: 384,
		quantity: 410,
	},
	{
		garage_id: 37,
		part_id: 385,
		quantity: 356,
	},
	{
		garage_id: 37,
		part_id: 386,
		quantity: 167,
	},
	{
		garage_id: 37,
		part_id: 387,
		quantity: 241,
	},
	{
		garage_id: 37,
		part_id: 388,
		quantity: 247,
	},
	{
		garage_id: 37,
		part_id: 389,
		quantity: 284,
	},
	{
		garage_id: 37,
		part_id: 390,
		quantity: 108,
	},
	{
		garage_id: 37,
		part_id: 391,
		quantity: 108,
	},
	{
		garage_id: 37,
		part_id: 392,
		quantity: 468,
	},
	{
		garage_id: 37,
		part_id: 393,
		quantity: 248,
	},
	{
		garage_id: 37,
		part_id: 394,
		quantity: 303,
	},
	{
		garage_id: 37,
		part_id: 395,
		quantity: 126,
	},
	{
		garage_id: 37,
		part_id: 396,
		quantity: 244,
	},
	{
		garage_id: 37,
		part_id: 397,
		quantity: 1,
	},
	{
		garage_id: 37,
		part_id: 398,
		quantity: 273,
	},
	{
		garage_id: 37,
		part_id: 399,
		quantity: 203,
	},
	{
		garage_id: 38,
		part_id: 0,
		quantity: 101,
	},
	{
		garage_id: 38,
		part_id: 1,
		quantity: 262,
	},
	{
		garage_id: 38,
		part_id: 2,
		quantity: 379,
	},
	{
		garage_id: 38,
		part_id: 3,
		quantity: 72,
	},
	{
		garage_id: 38,
		part_id: 4,
		quantity: 299,
	},
	{
		garage_id: 38,
		part_id: 5,
		quantity: 427,
	},
	{
		garage_id: 38,
		part_id: 6,
		quantity: 277,
	},
	{
		garage_id: 38,
		part_id: 7,
		quantity: 279,
	},
	{
		garage_id: 38,
		part_id: 8,
		quantity: 492,
	},
	{
		garage_id: 38,
		part_id: 9,
		quantity: 179,
	},
	{
		garage_id: 38,
		part_id: 10,
		quantity: 481,
	},
	{
		garage_id: 38,
		part_id: 11,
		quantity: 68,
	},
	{
		garage_id: 38,
		part_id: 12,
		quantity: 460,
	},
	{
		garage_id: 38,
		part_id: 13,
		quantity: 467,
	},
	{
		garage_id: 38,
		part_id: 14,
		quantity: 144,
	},
	{
		garage_id: 38,
		part_id: 15,
		quantity: 331,
	},
	{
		garage_id: 38,
		part_id: 16,
		quantity: 293,
	},
	{
		garage_id: 38,
		part_id: 17,
		quantity: 453,
	},
	{
		garage_id: 38,
		part_id: 18,
		quantity: 203,
	},
	{
		garage_id: 38,
		part_id: 19,
		quantity: 426,
	},
	{
		garage_id: 38,
		part_id: 20,
		quantity: 232,
	},
	{
		garage_id: 38,
		part_id: 21,
		quantity: 472,
	},
	{
		garage_id: 38,
		part_id: 22,
		quantity: 374,
	},
	{
		garage_id: 38,
		part_id: 23,
		quantity: 90,
	},
	{
		garage_id: 38,
		part_id: 24,
		quantity: 45,
	},
	{
		garage_id: 38,
		part_id: 25,
		quantity: 41,
	},
	{
		garage_id: 38,
		part_id: 26,
		quantity: 48,
	},
	{
		garage_id: 38,
		part_id: 27,
		quantity: 103,
	},
	{
		garage_id: 38,
		part_id: 28,
		quantity: 485,
	},
	{
		garage_id: 38,
		part_id: 29,
		quantity: 89,
	},
	{
		garage_id: 38,
		part_id: 30,
		quantity: 0,
	},
	{
		garage_id: 38,
		part_id: 31,
		quantity: 281,
	},
	{
		garage_id: 38,
		part_id: 32,
		quantity: 53,
	},
	{
		garage_id: 38,
		part_id: 33,
		quantity: 496,
	},
	{
		garage_id: 38,
		part_id: 34,
		quantity: 165,
	},
	{
		garage_id: 38,
		part_id: 35,
		quantity: 129,
	},
	{
		garage_id: 38,
		part_id: 36,
		quantity: 78,
	},
	{
		garage_id: 38,
		part_id: 37,
		quantity: 205,
	},
	{
		garage_id: 38,
		part_id: 38,
		quantity: 296,
	},
	{
		garage_id: 38,
		part_id: 39,
		quantity: 386,
	},
	{
		garage_id: 38,
		part_id: 40,
		quantity: 181,
	},
	{
		garage_id: 38,
		part_id: 41,
		quantity: 10,
	},
	{
		garage_id: 38,
		part_id: 42,
		quantity: 7,
	},
	{
		garage_id: 38,
		part_id: 43,
		quantity: 427,
	},
	{
		garage_id: 38,
		part_id: 44,
		quantity: 37,
	},
	{
		garage_id: 38,
		part_id: 45,
		quantity: 409,
	},
	{
		garage_id: 38,
		part_id: 46,
		quantity: 319,
	},
	{
		garage_id: 38,
		part_id: 47,
		quantity: 353,
	},
	{
		garage_id: 38,
		part_id: 48,
		quantity: 220,
	},
	{
		garage_id: 38,
		part_id: 49,
		quantity: 263,
	},
	{
		garage_id: 38,
		part_id: 50,
		quantity: 155,
	},
	{
		garage_id: 38,
		part_id: 51,
		quantity: 442,
	},
	{
		garage_id: 38,
		part_id: 52,
		quantity: 218,
	},
	{
		garage_id: 38,
		part_id: 53,
		quantity: 276,
	},
	{
		garage_id: 38,
		part_id: 54,
		quantity: 486,
	},
	{
		garage_id: 38,
		part_id: 55,
		quantity: 244,
	},
	{
		garage_id: 38,
		part_id: 56,
		quantity: 119,
	},
	{
		garage_id: 38,
		part_id: 57,
		quantity: 226,
	},
	{
		garage_id: 38,
		part_id: 58,
		quantity: 384,
	},
	{
		garage_id: 38,
		part_id: 59,
		quantity: 170,
	},
	{
		garage_id: 38,
		part_id: 60,
		quantity: 219,
	},
	{
		garage_id: 38,
		part_id: 61,
		quantity: 422,
	},
	{
		garage_id: 38,
		part_id: 62,
		quantity: 45,
	},
	{
		garage_id: 38,
		part_id: 63,
		quantity: 214,
	},
	{
		garage_id: 38,
		part_id: 64,
		quantity: 447,
	},
	{
		garage_id: 38,
		part_id: 65,
		quantity: 285,
	},
	{
		garage_id: 38,
		part_id: 66,
		quantity: 385,
	},
	{
		garage_id: 38,
		part_id: 67,
		quantity: 62,
	},
	{
		garage_id: 38,
		part_id: 68,
		quantity: 158,
	},
	{
		garage_id: 38,
		part_id: 69,
		quantity: 477,
	},
	{
		garage_id: 38,
		part_id: 70,
		quantity: 358,
	},
	{
		garage_id: 38,
		part_id: 71,
		quantity: 99,
	},
	{
		garage_id: 38,
		part_id: 72,
		quantity: 366,
	},
	{
		garage_id: 38,
		part_id: 73,
		quantity: 324,
	},
	{
		garage_id: 38,
		part_id: 74,
		quantity: 237,
	},
	{
		garage_id: 38,
		part_id: 75,
		quantity: 334,
	},
	{
		garage_id: 38,
		part_id: 76,
		quantity: 178,
	},
	{
		garage_id: 38,
		part_id: 77,
		quantity: 129,
	},
	{
		garage_id: 38,
		part_id: 78,
		quantity: 389,
	},
	{
		garage_id: 38,
		part_id: 79,
		quantity: 237,
	},
	{
		garage_id: 38,
		part_id: 80,
		quantity: 140,
	},
	{
		garage_id: 38,
		part_id: 81,
		quantity: 2,
	},
	{
		garage_id: 38,
		part_id: 82,
		quantity: 63,
	},
	{
		garage_id: 38,
		part_id: 83,
		quantity: 362,
	},
	{
		garage_id: 38,
		part_id: 84,
		quantity: 156,
	},
	{
		garage_id: 38,
		part_id: 85,
		quantity: 24,
	},
	{
		garage_id: 38,
		part_id: 86,
		quantity: 190,
	},
	{
		garage_id: 38,
		part_id: 87,
		quantity: 331,
	},
	{
		garage_id: 38,
		part_id: 88,
		quantity: 348,
	},
	{
		garage_id: 38,
		part_id: 89,
		quantity: 68,
	},
	{
		garage_id: 38,
		part_id: 90,
		quantity: 459,
	},
	{
		garage_id: 38,
		part_id: 91,
		quantity: 173,
	},
	{
		garage_id: 38,
		part_id: 92,
		quantity: 134,
	},
	{
		garage_id: 38,
		part_id: 93,
		quantity: 113,
	},
	{
		garage_id: 38,
		part_id: 94,
		quantity: 79,
	},
	{
		garage_id: 38,
		part_id: 95,
		quantity: 201,
	},
	{
		garage_id: 38,
		part_id: 96,
		quantity: 119,
	},
	{
		garage_id: 38,
		part_id: 97,
		quantity: 187,
	},
	{
		garage_id: 38,
		part_id: 98,
		quantity: 434,
	},
	{
		garage_id: 38,
		part_id: 99,
		quantity: 443,
	},
	{
		garage_id: 38,
		part_id: 100,
		quantity: 26,
	},
	{
		garage_id: 38,
		part_id: 101,
		quantity: 450,
	},
	{
		garage_id: 38,
		part_id: 102,
		quantity: 385,
	},
	{
		garage_id: 38,
		part_id: 103,
		quantity: 313,
	},
	{
		garage_id: 38,
		part_id: 104,
		quantity: 88,
	},
	{
		garage_id: 38,
		part_id: 105,
		quantity: 220,
	},
	{
		garage_id: 38,
		part_id: 106,
		quantity: 105,
	},
	{
		garage_id: 38,
		part_id: 107,
		quantity: 206,
	},
	{
		garage_id: 38,
		part_id: 108,
		quantity: 302,
	},
	{
		garage_id: 38,
		part_id: 109,
		quantity: 30,
	},
	{
		garage_id: 38,
		part_id: 110,
		quantity: 429,
	},
	{
		garage_id: 38,
		part_id: 111,
		quantity: 122,
	},
	{
		garage_id: 38,
		part_id: 112,
		quantity: 479,
	},
	{
		garage_id: 38,
		part_id: 113,
		quantity: 194,
	},
	{
		garage_id: 38,
		part_id: 114,
		quantity: 280,
	},
	{
		garage_id: 38,
		part_id: 115,
		quantity: 438,
	},
	{
		garage_id: 38,
		part_id: 116,
		quantity: 24,
	},
	{
		garage_id: 38,
		part_id: 117,
		quantity: 407,
	},
	{
		garage_id: 38,
		part_id: 118,
		quantity: 233,
	},
	{
		garage_id: 38,
		part_id: 119,
		quantity: 244,
	},
	{
		garage_id: 38,
		part_id: 120,
		quantity: 429,
	},
	{
		garage_id: 38,
		part_id: 121,
		quantity: 384,
	},
	{
		garage_id: 38,
		part_id: 122,
		quantity: 385,
	},
	{
		garage_id: 38,
		part_id: 123,
		quantity: 196,
	},
	{
		garage_id: 38,
		part_id: 124,
		quantity: 120,
	},
	{
		garage_id: 38,
		part_id: 125,
		quantity: 13,
	},
	{
		garage_id: 38,
		part_id: 126,
		quantity: 391,
	},
	{
		garage_id: 38,
		part_id: 127,
		quantity: 357,
	},
	{
		garage_id: 38,
		part_id: 128,
		quantity: 115,
	},
	{
		garage_id: 38,
		part_id: 129,
		quantity: 90,
	},
	{
		garage_id: 38,
		part_id: 130,
		quantity: 252,
	},
	{
		garage_id: 38,
		part_id: 131,
		quantity: 60,
	},
	{
		garage_id: 38,
		part_id: 132,
		quantity: 268,
	},
	{
		garage_id: 38,
		part_id: 133,
		quantity: 360,
	},
	{
		garage_id: 38,
		part_id: 134,
		quantity: 129,
	},
	{
		garage_id: 38,
		part_id: 135,
		quantity: 108,
	},
	{
		garage_id: 38,
		part_id: 136,
		quantity: 278,
	},
	{
		garage_id: 38,
		part_id: 137,
		quantity: 370,
	},
	{
		garage_id: 38,
		part_id: 138,
		quantity: 124,
	},
	{
		garage_id: 38,
		part_id: 139,
		quantity: 76,
	},
	{
		garage_id: 38,
		part_id: 140,
		quantity: 233,
	},
	{
		garage_id: 38,
		part_id: 141,
		quantity: 177,
	},
	{
		garage_id: 38,
		part_id: 142,
		quantity: 354,
	},
	{
		garage_id: 38,
		part_id: 143,
		quantity: 4,
	},
	{
		garage_id: 38,
		part_id: 144,
		quantity: 350,
	},
	{
		garage_id: 38,
		part_id: 145,
		quantity: 226,
	},
	{
		garage_id: 38,
		part_id: 146,
		quantity: 332,
	},
	{
		garage_id: 38,
		part_id: 147,
		quantity: 21,
	},
	{
		garage_id: 38,
		part_id: 148,
		quantity: 281,
	},
	{
		garage_id: 38,
		part_id: 149,
		quantity: 414,
	},
	{
		garage_id: 38,
		part_id: 150,
		quantity: 210,
	},
	{
		garage_id: 38,
		part_id: 151,
		quantity: 8,
	},
	{
		garage_id: 38,
		part_id: 152,
		quantity: 148,
	},
	{
		garage_id: 38,
		part_id: 153,
		quantity: 146,
	},
	{
		garage_id: 38,
		part_id: 154,
		quantity: 496,
	},
	{
		garage_id: 38,
		part_id: 155,
		quantity: 84,
	},
	{
		garage_id: 38,
		part_id: 156,
		quantity: 318,
	},
	{
		garage_id: 38,
		part_id: 157,
		quantity: 236,
	},
	{
		garage_id: 38,
		part_id: 158,
		quantity: 206,
	},
	{
		garage_id: 38,
		part_id: 159,
		quantity: 2,
	},
	{
		garage_id: 38,
		part_id: 160,
		quantity: 494,
	},
	{
		garage_id: 38,
		part_id: 161,
		quantity: 109,
	},
	{
		garage_id: 38,
		part_id: 162,
		quantity: 130,
	},
	{
		garage_id: 38,
		part_id: 163,
		quantity: 216,
	},
	{
		garage_id: 38,
		part_id: 164,
		quantity: 51,
	},
	{
		garage_id: 38,
		part_id: 165,
		quantity: 338,
	},
	{
		garage_id: 38,
		part_id: 166,
		quantity: 37,
	},
	{
		garage_id: 38,
		part_id: 167,
		quantity: 255,
	},
	{
		garage_id: 38,
		part_id: 168,
		quantity: 403,
	},
	{
		garage_id: 38,
		part_id: 169,
		quantity: 124,
	},
	{
		garage_id: 38,
		part_id: 170,
		quantity: 278,
	},
	{
		garage_id: 38,
		part_id: 171,
		quantity: 205,
	},
	{
		garage_id: 38,
		part_id: 172,
		quantity: 104,
	},
	{
		garage_id: 38,
		part_id: 173,
		quantity: 367,
	},
	{
		garage_id: 38,
		part_id: 174,
		quantity: 370,
	},
	{
		garage_id: 38,
		part_id: 175,
		quantity: 179,
	},
	{
		garage_id: 38,
		part_id: 176,
		quantity: 465,
	},
	{
		garage_id: 38,
		part_id: 177,
		quantity: 172,
	},
	{
		garage_id: 38,
		part_id: 178,
		quantity: 333,
	},
	{
		garage_id: 38,
		part_id: 179,
		quantity: 214,
	},
	{
		garage_id: 38,
		part_id: 180,
		quantity: 457,
	},
	{
		garage_id: 38,
		part_id: 181,
		quantity: 492,
	},
	{
		garage_id: 38,
		part_id: 182,
		quantity: 103,
	},
	{
		garage_id: 38,
		part_id: 183,
		quantity: 48,
	},
	{
		garage_id: 38,
		part_id: 184,
		quantity: 491,
	},
	{
		garage_id: 38,
		part_id: 185,
		quantity: 181,
	},
	{
		garage_id: 38,
		part_id: 186,
		quantity: 162,
	},
	{
		garage_id: 38,
		part_id: 187,
		quantity: 436,
	},
	{
		garage_id: 38,
		part_id: 188,
		quantity: 323,
	},
	{
		garage_id: 38,
		part_id: 189,
		quantity: 313,
	},
	{
		garage_id: 38,
		part_id: 190,
		quantity: 458,
	},
	{
		garage_id: 38,
		part_id: 191,
		quantity: 479,
	},
	{
		garage_id: 38,
		part_id: 192,
		quantity: 97,
	},
	{
		garage_id: 38,
		part_id: 193,
		quantity: 13,
	},
	{
		garage_id: 38,
		part_id: 194,
		quantity: 153,
	},
	{
		garage_id: 38,
		part_id: 195,
		quantity: 39,
	},
	{
		garage_id: 38,
		part_id: 196,
		quantity: 399,
	},
	{
		garage_id: 38,
		part_id: 197,
		quantity: 266,
	},
	{
		garage_id: 38,
		part_id: 198,
		quantity: 196,
	},
	{
		garage_id: 38,
		part_id: 199,
		quantity: 46,
	},
	{
		garage_id: 38,
		part_id: 200,
		quantity: 397,
	},
	{
		garage_id: 38,
		part_id: 201,
		quantity: 14,
	},
	{
		garage_id: 38,
		part_id: 202,
		quantity: 416,
	},
	{
		garage_id: 38,
		part_id: 203,
		quantity: 246,
	},
	{
		garage_id: 38,
		part_id: 204,
		quantity: 466,
	},
	{
		garage_id: 38,
		part_id: 205,
		quantity: 335,
	},
	{
		garage_id: 38,
		part_id: 206,
		quantity: 487,
	},
	{
		garage_id: 38,
		part_id: 207,
		quantity: 329,
	},
	{
		garage_id: 38,
		part_id: 208,
		quantity: 396,
	},
	{
		garage_id: 38,
		part_id: 209,
		quantity: 474,
	},
	{
		garage_id: 38,
		part_id: 210,
		quantity: 194,
	},
	{
		garage_id: 38,
		part_id: 211,
		quantity: 236,
	},
	{
		garage_id: 38,
		part_id: 212,
		quantity: 91,
	},
	{
		garage_id: 38,
		part_id: 213,
		quantity: 260,
	},
	{
		garage_id: 38,
		part_id: 214,
		quantity: 31,
	},
	{
		garage_id: 38,
		part_id: 215,
		quantity: 313,
	},
	{
		garage_id: 38,
		part_id: 216,
		quantity: 330,
	},
	{
		garage_id: 38,
		part_id: 217,
		quantity: 442,
	},
	{
		garage_id: 38,
		part_id: 218,
		quantity: 459,
	},
	{
		garage_id: 38,
		part_id: 219,
		quantity: 171,
	},
	{
		garage_id: 38,
		part_id: 220,
		quantity: 25,
	},
	{
		garage_id: 38,
		part_id: 221,
		quantity: 473,
	},
	{
		garage_id: 38,
		part_id: 222,
		quantity: 143,
	},
	{
		garage_id: 38,
		part_id: 223,
		quantity: 409,
	},
	{
		garage_id: 38,
		part_id: 224,
		quantity: 479,
	},
	{
		garage_id: 38,
		part_id: 225,
		quantity: 419,
	},
	{
		garage_id: 38,
		part_id: 226,
		quantity: 442,
	},
	{
		garage_id: 38,
		part_id: 227,
		quantity: 284,
	},
	{
		garage_id: 38,
		part_id: 228,
		quantity: 386,
	},
	{
		garage_id: 38,
		part_id: 229,
		quantity: 117,
	},
	{
		garage_id: 38,
		part_id: 230,
		quantity: 231,
	},
	{
		garage_id: 38,
		part_id: 231,
		quantity: 35,
	},
	{
		garage_id: 38,
		part_id: 232,
		quantity: 35,
	},
	{
		garage_id: 38,
		part_id: 233,
		quantity: 347,
	},
	{
		garage_id: 38,
		part_id: 234,
		quantity: 74,
	},
	{
		garage_id: 38,
		part_id: 235,
		quantity: 303,
	},
	{
		garage_id: 38,
		part_id: 236,
		quantity: 16,
	},
	{
		garage_id: 38,
		part_id: 237,
		quantity: 361,
	},
	{
		garage_id: 38,
		part_id: 238,
		quantity: 437,
	},
	{
		garage_id: 38,
		part_id: 239,
		quantity: 192,
	},
	{
		garage_id: 38,
		part_id: 240,
		quantity: 121,
	},
	{
		garage_id: 38,
		part_id: 241,
		quantity: 63,
	},
	{
		garage_id: 38,
		part_id: 242,
		quantity: 136,
	},
	{
		garage_id: 38,
		part_id: 243,
		quantity: 328,
	},
	{
		garage_id: 38,
		part_id: 244,
		quantity: 235,
	},
	{
		garage_id: 38,
		part_id: 245,
		quantity: 444,
	},
	{
		garage_id: 38,
		part_id: 246,
		quantity: 289,
	},
	{
		garage_id: 38,
		part_id: 247,
		quantity: 34,
	},
	{
		garage_id: 38,
		part_id: 248,
		quantity: 158,
	},
	{
		garage_id: 38,
		part_id: 249,
		quantity: 117,
	},
	{
		garage_id: 38,
		part_id: 250,
		quantity: 296,
	},
	{
		garage_id: 38,
		part_id: 251,
		quantity: 134,
	},
	{
		garage_id: 38,
		part_id: 252,
		quantity: 73,
	},
	{
		garage_id: 38,
		part_id: 253,
		quantity: 170,
	},
	{
		garage_id: 38,
		part_id: 254,
		quantity: 377,
	},
	{
		garage_id: 38,
		part_id: 255,
		quantity: 247,
	},
	{
		garage_id: 38,
		part_id: 256,
		quantity: 254,
	},
	{
		garage_id: 38,
		part_id: 257,
		quantity: 335,
	},
	{
		garage_id: 38,
		part_id: 258,
		quantity: 179,
	},
	{
		garage_id: 38,
		part_id: 259,
		quantity: 428,
	},
	{
		garage_id: 38,
		part_id: 260,
		quantity: 44,
	},
	{
		garage_id: 38,
		part_id: 261,
		quantity: 111,
	},
	{
		garage_id: 38,
		part_id: 262,
		quantity: 266,
	},
	{
		garage_id: 38,
		part_id: 263,
		quantity: 449,
	},
	{
		garage_id: 38,
		part_id: 264,
		quantity: 327,
	},
	{
		garage_id: 38,
		part_id: 265,
		quantity: 367,
	},
	{
		garage_id: 38,
		part_id: 266,
		quantity: 91,
	},
	{
		garage_id: 38,
		part_id: 267,
		quantity: 424,
	},
	{
		garage_id: 38,
		part_id: 268,
		quantity: 345,
	},
	{
		garage_id: 38,
		part_id: 269,
		quantity: 440,
	},
	{
		garage_id: 38,
		part_id: 270,
		quantity: 405,
	},
	{
		garage_id: 38,
		part_id: 271,
		quantity: 147,
	},
	{
		garage_id: 38,
		part_id: 272,
		quantity: 146,
	},
	{
		garage_id: 38,
		part_id: 273,
		quantity: 154,
	},
	{
		garage_id: 38,
		part_id: 274,
		quantity: 490,
	},
	{
		garage_id: 38,
		part_id: 275,
		quantity: 82,
	},
	{
		garage_id: 38,
		part_id: 276,
		quantity: 319,
	},
	{
		garage_id: 38,
		part_id: 277,
		quantity: 119,
	},
	{
		garage_id: 38,
		part_id: 278,
		quantity: 128,
	},
	{
		garage_id: 38,
		part_id: 279,
		quantity: 411,
	},
	{
		garage_id: 38,
		part_id: 280,
		quantity: 416,
	},
	{
		garage_id: 38,
		part_id: 281,
		quantity: 166,
	},
	{
		garage_id: 38,
		part_id: 282,
		quantity: 134,
	},
	{
		garage_id: 38,
		part_id: 283,
		quantity: 408,
	},
	{
		garage_id: 38,
		part_id: 284,
		quantity: 208,
	},
	{
		garage_id: 38,
		part_id: 285,
		quantity: 83,
	},
	{
		garage_id: 38,
		part_id: 286,
		quantity: 393,
	},
	{
		garage_id: 38,
		part_id: 287,
		quantity: 212,
	},
	{
		garage_id: 38,
		part_id: 288,
		quantity: 346,
	},
	{
		garage_id: 38,
		part_id: 289,
		quantity: 391,
	},
	{
		garage_id: 38,
		part_id: 290,
		quantity: 468,
	},
	{
		garage_id: 38,
		part_id: 291,
		quantity: 462,
	},
	{
		garage_id: 38,
		part_id: 292,
		quantity: 208,
	},
	{
		garage_id: 38,
		part_id: 293,
		quantity: 493,
	},
	{
		garage_id: 38,
		part_id: 294,
		quantity: 190,
	},
	{
		garage_id: 38,
		part_id: 295,
		quantity: 482,
	},
	{
		garage_id: 38,
		part_id: 296,
		quantity: 474,
	},
	{
		garage_id: 38,
		part_id: 297,
		quantity: 404,
	},
	{
		garage_id: 38,
		part_id: 298,
		quantity: 240,
	},
	{
		garage_id: 38,
		part_id: 299,
		quantity: 162,
	},
	{
		garage_id: 38,
		part_id: 300,
		quantity: 145,
	},
	{
		garage_id: 38,
		part_id: 301,
		quantity: 5,
	},
	{
		garage_id: 38,
		part_id: 302,
		quantity: 46,
	},
	{
		garage_id: 38,
		part_id: 303,
		quantity: 237,
	},
	{
		garage_id: 38,
		part_id: 304,
		quantity: 454,
	},
	{
		garage_id: 38,
		part_id: 305,
		quantity: 84,
	},
	{
		garage_id: 38,
		part_id: 306,
		quantity: 22,
	},
	{
		garage_id: 38,
		part_id: 307,
		quantity: 310,
	},
	{
		garage_id: 38,
		part_id: 308,
		quantity: 158,
	},
	{
		garage_id: 38,
		part_id: 309,
		quantity: 145,
	},
	{
		garage_id: 38,
		part_id: 310,
		quantity: 2,
	},
	{
		garage_id: 38,
		part_id: 311,
		quantity: 120,
	},
	{
		garage_id: 38,
		part_id: 312,
		quantity: 24,
	},
	{
		garage_id: 38,
		part_id: 313,
		quantity: 76,
	},
	{
		garage_id: 38,
		part_id: 314,
		quantity: 425,
	},
	{
		garage_id: 38,
		part_id: 315,
		quantity: 11,
	},
	{
		garage_id: 38,
		part_id: 316,
		quantity: 165,
	},
	{
		garage_id: 38,
		part_id: 317,
		quantity: 497,
	},
	{
		garage_id: 38,
		part_id: 318,
		quantity: 181,
	},
	{
		garage_id: 38,
		part_id: 319,
		quantity: 308,
	},
	{
		garage_id: 38,
		part_id: 320,
		quantity: 145,
	},
	{
		garage_id: 38,
		part_id: 321,
		quantity: 462,
	},
	{
		garage_id: 38,
		part_id: 322,
		quantity: 70,
	},
	{
		garage_id: 38,
		part_id: 323,
		quantity: 458,
	},
	{
		garage_id: 38,
		part_id: 324,
		quantity: 56,
	},
	{
		garage_id: 38,
		part_id: 325,
		quantity: 159,
	},
	{
		garage_id: 38,
		part_id: 326,
		quantity: 54,
	},
	{
		garage_id: 38,
		part_id: 327,
		quantity: 5,
	},
	{
		garage_id: 38,
		part_id: 328,
		quantity: 212,
	},
	{
		garage_id: 38,
		part_id: 329,
		quantity: 240,
	},
	{
		garage_id: 38,
		part_id: 330,
		quantity: 351,
	},
	{
		garage_id: 38,
		part_id: 331,
		quantity: 374,
	},
	{
		garage_id: 38,
		part_id: 332,
		quantity: 297,
	},
	{
		garage_id: 38,
		part_id: 333,
		quantity: 131,
	},
	{
		garage_id: 38,
		part_id: 334,
		quantity: 494,
	},
	{
		garage_id: 38,
		part_id: 335,
		quantity: 115,
	},
	{
		garage_id: 38,
		part_id: 336,
		quantity: 98,
	},
	{
		garage_id: 38,
		part_id: 337,
		quantity: 69,
	},
	{
		garage_id: 38,
		part_id: 338,
		quantity: 29,
	},
	{
		garage_id: 38,
		part_id: 339,
		quantity: 498,
	},
	{
		garage_id: 38,
		part_id: 340,
		quantity: 113,
	},
	{
		garage_id: 38,
		part_id: 341,
		quantity: 62,
	},
	{
		garage_id: 38,
		part_id: 342,
		quantity: 321,
	},
	{
		garage_id: 38,
		part_id: 343,
		quantity: 4,
	},
	{
		garage_id: 38,
		part_id: 344,
		quantity: 399,
	},
	{
		garage_id: 38,
		part_id: 345,
		quantity: 8,
	},
	{
		garage_id: 38,
		part_id: 346,
		quantity: 372,
	},
	{
		garage_id: 38,
		part_id: 347,
		quantity: 457,
	},
	{
		garage_id: 38,
		part_id: 348,
		quantity: 201,
	},
	{
		garage_id: 38,
		part_id: 349,
		quantity: 329,
	},
	{
		garage_id: 38,
		part_id: 350,
		quantity: 167,
	},
	{
		garage_id: 38,
		part_id: 351,
		quantity: 79,
	},
	{
		garage_id: 38,
		part_id: 352,
		quantity: 423,
	},
	{
		garage_id: 38,
		part_id: 353,
		quantity: 98,
	},
	{
		garage_id: 38,
		part_id: 354,
		quantity: 78,
	},
	{
		garage_id: 38,
		part_id: 355,
		quantity: 33,
	},
	{
		garage_id: 38,
		part_id: 356,
		quantity: 49,
	},
	{
		garage_id: 38,
		part_id: 357,
		quantity: 117,
	},
	{
		garage_id: 38,
		part_id: 358,
		quantity: 83,
	},
	{
		garage_id: 38,
		part_id: 359,
		quantity: 126,
	},
	{
		garage_id: 38,
		part_id: 360,
		quantity: 7,
	},
	{
		garage_id: 38,
		part_id: 361,
		quantity: 145,
	},
	{
		garage_id: 38,
		part_id: 362,
		quantity: 379,
	},
	{
		garage_id: 38,
		part_id: 363,
		quantity: 483,
	},
	{
		garage_id: 38,
		part_id: 364,
		quantity: 85,
	},
	{
		garage_id: 38,
		part_id: 365,
		quantity: 156,
	},
	{
		garage_id: 38,
		part_id: 366,
		quantity: 157,
	},
	{
		garage_id: 38,
		part_id: 367,
		quantity: 368,
	},
	{
		garage_id: 38,
		part_id: 368,
		quantity: 36,
	},
	{
		garage_id: 38,
		part_id: 369,
		quantity: 142,
	},
	{
		garage_id: 38,
		part_id: 370,
		quantity: 293,
	},
	{
		garage_id: 38,
		part_id: 371,
		quantity: 52,
	},
	{
		garage_id: 38,
		part_id: 372,
		quantity: 231,
	},
	{
		garage_id: 38,
		part_id: 373,
		quantity: 57,
	},
	{
		garage_id: 38,
		part_id: 374,
		quantity: 253,
	},
	{
		garage_id: 38,
		part_id: 375,
		quantity: 297,
	},
	{
		garage_id: 38,
		part_id: 376,
		quantity: 95,
	},
	{
		garage_id: 38,
		part_id: 377,
		quantity: 323,
	},
	{
		garage_id: 38,
		part_id: 378,
		quantity: 103,
	},
	{
		garage_id: 38,
		part_id: 379,
		quantity: 123,
	},
	{
		garage_id: 38,
		part_id: 380,
		quantity: 333,
	},
	{
		garage_id: 38,
		part_id: 381,
		quantity: 304,
	},
	{
		garage_id: 38,
		part_id: 382,
		quantity: 365,
	},
	{
		garage_id: 38,
		part_id: 383,
		quantity: 115,
	},
	{
		garage_id: 38,
		part_id: 384,
		quantity: 314,
	},
	{
		garage_id: 38,
		part_id: 385,
		quantity: 300,
	},
	{
		garage_id: 38,
		part_id: 386,
		quantity: 67,
	},
	{
		garage_id: 38,
		part_id: 387,
		quantity: 368,
	},
	{
		garage_id: 38,
		part_id: 388,
		quantity: 405,
	},
	{
		garage_id: 38,
		part_id: 389,
		quantity: 189,
	},
	{
		garage_id: 38,
		part_id: 390,
		quantity: 102,
	},
	{
		garage_id: 38,
		part_id: 391,
		quantity: 188,
	},
	{
		garage_id: 38,
		part_id: 392,
		quantity: 475,
	},
	{
		garage_id: 38,
		part_id: 393,
		quantity: 387,
	},
	{
		garage_id: 38,
		part_id: 394,
		quantity: 281,
	},
	{
		garage_id: 38,
		part_id: 395,
		quantity: 53,
	},
	{
		garage_id: 38,
		part_id: 396,
		quantity: 201,
	},
	{
		garage_id: 38,
		part_id: 397,
		quantity: 484,
	},
	{
		garage_id: 38,
		part_id: 398,
		quantity: 49,
	},
	{
		garage_id: 38,
		part_id: 399,
		quantity: 417,
	},
	{
		garage_id: 39,
		part_id: 0,
		quantity: 301,
	},
	{
		garage_id: 39,
		part_id: 1,
		quantity: 393,
	},
	{
		garage_id: 39,
		part_id: 2,
		quantity: 85,
	},
	{
		garage_id: 39,
		part_id: 3,
		quantity: 431,
	},
	{
		garage_id: 39,
		part_id: 4,
		quantity: 8,
	},
	{
		garage_id: 39,
		part_id: 5,
		quantity: 405,
	},
	{
		garage_id: 39,
		part_id: 6,
		quantity: 98,
	},
	{
		garage_id: 39,
		part_id: 7,
		quantity: 214,
	},
	{
		garage_id: 39,
		part_id: 8,
		quantity: 184,
	},
	{
		garage_id: 39,
		part_id: 9,
		quantity: 285,
	},
	{
		garage_id: 39,
		part_id: 10,
		quantity: 484,
	},
	{
		garage_id: 39,
		part_id: 11,
		quantity: 140,
	},
	{
		garage_id: 39,
		part_id: 12,
		quantity: 58,
	},
	{
		garage_id: 39,
		part_id: 13,
		quantity: 379,
	},
	{
		garage_id: 39,
		part_id: 14,
		quantity: 28,
	},
	{
		garage_id: 39,
		part_id: 15,
		quantity: 468,
	},
	{
		garage_id: 39,
		part_id: 16,
		quantity: 234,
	},
	{
		garage_id: 39,
		part_id: 17,
		quantity: 182,
	},
	{
		garage_id: 39,
		part_id: 18,
		quantity: 66,
	},
	{
		garage_id: 39,
		part_id: 19,
		quantity: 267,
	},
	{
		garage_id: 39,
		part_id: 20,
		quantity: 319,
	},
	{
		garage_id: 39,
		part_id: 21,
		quantity: 42,
	},
	{
		garage_id: 39,
		part_id: 22,
		quantity: 375,
	},
	{
		garage_id: 39,
		part_id: 23,
		quantity: 339,
	},
	{
		garage_id: 39,
		part_id: 24,
		quantity: 424,
	},
	{
		garage_id: 39,
		part_id: 25,
		quantity: 33,
	},
	{
		garage_id: 39,
		part_id: 26,
		quantity: 328,
	},
	{
		garage_id: 39,
		part_id: 27,
		quantity: 220,
	},
	{
		garage_id: 39,
		part_id: 28,
		quantity: 398,
	},
	{
		garage_id: 39,
		part_id: 29,
		quantity: 463,
	},
	{
		garage_id: 39,
		part_id: 30,
		quantity: 391,
	},
	{
		garage_id: 39,
		part_id: 31,
		quantity: 75,
	},
	{
		garage_id: 39,
		part_id: 32,
		quantity: 206,
	},
	{
		garage_id: 39,
		part_id: 33,
		quantity: 58,
	},
	{
		garage_id: 39,
		part_id: 34,
		quantity: 416,
	},
	{
		garage_id: 39,
		part_id: 35,
		quantity: 56,
	},
	{
		garage_id: 39,
		part_id: 36,
		quantity: 246,
	},
	{
		garage_id: 39,
		part_id: 37,
		quantity: 44,
	},
	{
		garage_id: 39,
		part_id: 38,
		quantity: 343,
	},
	{
		garage_id: 39,
		part_id: 39,
		quantity: 112,
	},
	{
		garage_id: 39,
		part_id: 40,
		quantity: 71,
	},
	{
		garage_id: 39,
		part_id: 41,
		quantity: 445,
	},
	{
		garage_id: 39,
		part_id: 42,
		quantity: 84,
	},
	{
		garage_id: 39,
		part_id: 43,
		quantity: 361,
	},
	{
		garage_id: 39,
		part_id: 44,
		quantity: 99,
	},
	{
		garage_id: 39,
		part_id: 45,
		quantity: 103,
	},
	{
		garage_id: 39,
		part_id: 46,
		quantity: 367,
	},
	{
		garage_id: 39,
		part_id: 47,
		quantity: 40,
	},
	{
		garage_id: 39,
		part_id: 48,
		quantity: 218,
	},
	{
		garage_id: 39,
		part_id: 49,
		quantity: 257,
	},
	{
		garage_id: 39,
		part_id: 50,
		quantity: 278,
	},
	{
		garage_id: 39,
		part_id: 51,
		quantity: 267,
	},
	{
		garage_id: 39,
		part_id: 52,
		quantity: 187,
	},
	{
		garage_id: 39,
		part_id: 53,
		quantity: 61,
	},
	{
		garage_id: 39,
		part_id: 54,
		quantity: 346,
	},
	{
		garage_id: 39,
		part_id: 55,
		quantity: 61,
	},
	{
		garage_id: 39,
		part_id: 56,
		quantity: 205,
	},
	{
		garage_id: 39,
		part_id: 57,
		quantity: 103,
	},
	{
		garage_id: 39,
		part_id: 58,
		quantity: 75,
	},
	{
		garage_id: 39,
		part_id: 59,
		quantity: 238,
	},
	{
		garage_id: 39,
		part_id: 60,
		quantity: 50,
	},
	{
		garage_id: 39,
		part_id: 61,
		quantity: 187,
	},
	{
		garage_id: 39,
		part_id: 62,
		quantity: 155,
	},
	{
		garage_id: 39,
		part_id: 63,
		quantity: 344,
	},
	{
		garage_id: 39,
		part_id: 64,
		quantity: 317,
	},
	{
		garage_id: 39,
		part_id: 65,
		quantity: 69,
	},
	{
		garage_id: 39,
		part_id: 66,
		quantity: 424,
	},
	{
		garage_id: 39,
		part_id: 67,
		quantity: 244,
	},
	{
		garage_id: 39,
		part_id: 68,
		quantity: 208,
	},
	{
		garage_id: 39,
		part_id: 69,
		quantity: 320,
	},
	{
		garage_id: 39,
		part_id: 70,
		quantity: 160,
	},
	{
		garage_id: 39,
		part_id: 71,
		quantity: 58,
	},
	{
		garage_id: 39,
		part_id: 72,
		quantity: 200,
	},
	{
		garage_id: 39,
		part_id: 73,
		quantity: 396,
	},
	{
		garage_id: 39,
		part_id: 74,
		quantity: 20,
	},
	{
		garage_id: 39,
		part_id: 75,
		quantity: 108,
	},
	{
		garage_id: 39,
		part_id: 76,
		quantity: 182,
	},
	{
		garage_id: 39,
		part_id: 77,
		quantity: 14,
	},
	{
		garage_id: 39,
		part_id: 78,
		quantity: 29,
	},
	{
		garage_id: 39,
		part_id: 79,
		quantity: 263,
	},
	{
		garage_id: 39,
		part_id: 80,
		quantity: 386,
	},
	{
		garage_id: 39,
		part_id: 81,
		quantity: 360,
	},
	{
		garage_id: 39,
		part_id: 82,
		quantity: 427,
	},
	{
		garage_id: 39,
		part_id: 83,
		quantity: 499,
	},
	{
		garage_id: 39,
		part_id: 84,
		quantity: 205,
	},
	{
		garage_id: 39,
		part_id: 85,
		quantity: 295,
	},
	{
		garage_id: 39,
		part_id: 86,
		quantity: 243,
	},
	{
		garage_id: 39,
		part_id: 87,
		quantity: 156,
	},
	{
		garage_id: 39,
		part_id: 88,
		quantity: 152,
	},
	{
		garage_id: 39,
		part_id: 89,
		quantity: 222,
	},
	{
		garage_id: 39,
		part_id: 90,
		quantity: 69,
	},
	{
		garage_id: 39,
		part_id: 91,
		quantity: 189,
	},
	{
		garage_id: 39,
		part_id: 92,
		quantity: 222,
	},
	{
		garage_id: 39,
		part_id: 93,
		quantity: 167,
	},
	{
		garage_id: 39,
		part_id: 94,
		quantity: 97,
	},
	{
		garage_id: 39,
		part_id: 95,
		quantity: 68,
	},
	{
		garage_id: 39,
		part_id: 96,
		quantity: 359,
	},
	{
		garage_id: 39,
		part_id: 97,
		quantity: 16,
	},
	{
		garage_id: 39,
		part_id: 98,
		quantity: 111,
	},
	{
		garage_id: 39,
		part_id: 99,
		quantity: 264,
	},
	{
		garage_id: 39,
		part_id: 100,
		quantity: 143,
	},
	{
		garage_id: 39,
		part_id: 101,
		quantity: 488,
	},
	{
		garage_id: 39,
		part_id: 102,
		quantity: 110,
	},
	{
		garage_id: 39,
		part_id: 103,
		quantity: 340,
	},
	{
		garage_id: 39,
		part_id: 104,
		quantity: 494,
	},
	{
		garage_id: 39,
		part_id: 105,
		quantity: 175,
	},
	{
		garage_id: 39,
		part_id: 106,
		quantity: 137,
	},
	{
		garage_id: 39,
		part_id: 107,
		quantity: 9,
	},
	{
		garage_id: 39,
		part_id: 108,
		quantity: 289,
	},
	{
		garage_id: 39,
		part_id: 109,
		quantity: 225,
	},
	{
		garage_id: 39,
		part_id: 110,
		quantity: 139,
	},
	{
		garage_id: 39,
		part_id: 111,
		quantity: 417,
	},
	{
		garage_id: 39,
		part_id: 112,
		quantity: 120,
	},
	{
		garage_id: 39,
		part_id: 113,
		quantity: 145,
	},
	{
		garage_id: 39,
		part_id: 114,
		quantity: 155,
	},
	{
		garage_id: 39,
		part_id: 115,
		quantity: 45,
	},
	{
		garage_id: 39,
		part_id: 116,
		quantity: 235,
	},
	{
		garage_id: 39,
		part_id: 117,
		quantity: 496,
	},
	{
		garage_id: 39,
		part_id: 118,
		quantity: 425,
	},
	{
		garage_id: 39,
		part_id: 119,
		quantity: 279,
	},
	{
		garage_id: 39,
		part_id: 120,
		quantity: 259,
	},
	{
		garage_id: 39,
		part_id: 121,
		quantity: 382,
	},
	{
		garage_id: 39,
		part_id: 122,
		quantity: 438,
	},
	{
		garage_id: 39,
		part_id: 123,
		quantity: 332,
	},
	{
		garage_id: 39,
		part_id: 124,
		quantity: 7,
	},
	{
		garage_id: 39,
		part_id: 125,
		quantity: 24,
	},
	{
		garage_id: 39,
		part_id: 126,
		quantity: 430,
	},
	{
		garage_id: 39,
		part_id: 127,
		quantity: 248,
	},
	{
		garage_id: 39,
		part_id: 128,
		quantity: 35,
	},
	{
		garage_id: 39,
		part_id: 129,
		quantity: 449,
	},
	{
		garage_id: 39,
		part_id: 130,
		quantity: 490,
	},
	{
		garage_id: 39,
		part_id: 131,
		quantity: 470,
	},
	{
		garage_id: 39,
		part_id: 132,
		quantity: 369,
	},
	{
		garage_id: 39,
		part_id: 133,
		quantity: 311,
	},
	{
		garage_id: 39,
		part_id: 134,
		quantity: 460,
	},
	{
		garage_id: 39,
		part_id: 135,
		quantity: 497,
	},
	{
		garage_id: 39,
		part_id: 136,
		quantity: 94,
	},
	{
		garage_id: 39,
		part_id: 137,
		quantity: 35,
	},
	{
		garage_id: 39,
		part_id: 138,
		quantity: 11,
	},
	{
		garage_id: 39,
		part_id: 139,
		quantity: 432,
	},
	{
		garage_id: 39,
		part_id: 140,
		quantity: 487,
	},
	{
		garage_id: 39,
		part_id: 141,
		quantity: 231,
	},
	{
		garage_id: 39,
		part_id: 142,
		quantity: 52,
	},
	{
		garage_id: 39,
		part_id: 143,
		quantity: 129,
	},
	{
		garage_id: 39,
		part_id: 144,
		quantity: 428,
	},
	{
		garage_id: 39,
		part_id: 145,
		quantity: 318,
	},
	{
		garage_id: 39,
		part_id: 146,
		quantity: 39,
	},
	{
		garage_id: 39,
		part_id: 147,
		quantity: 390,
	},
	{
		garage_id: 39,
		part_id: 148,
		quantity: 466,
	},
	{
		garage_id: 39,
		part_id: 149,
		quantity: 382,
	},
	{
		garage_id: 39,
		part_id: 150,
		quantity: 467,
	},
	{
		garage_id: 39,
		part_id: 151,
		quantity: 278,
	},
	{
		garage_id: 39,
		part_id: 152,
		quantity: 76,
	},
	{
		garage_id: 39,
		part_id: 153,
		quantity: 368,
	},
	{
		garage_id: 39,
		part_id: 154,
		quantity: 105,
	},
	{
		garage_id: 39,
		part_id: 155,
		quantity: 470,
	},
	{
		garage_id: 39,
		part_id: 156,
		quantity: 273,
	},
	{
		garage_id: 39,
		part_id: 157,
		quantity: 212,
	},
	{
		garage_id: 39,
		part_id: 158,
		quantity: 336,
	},
	{
		garage_id: 39,
		part_id: 159,
		quantity: 20,
	},
	{
		garage_id: 39,
		part_id: 160,
		quantity: 488,
	},
	{
		garage_id: 39,
		part_id: 161,
		quantity: 370,
	},
	{
		garage_id: 39,
		part_id: 162,
		quantity: 442,
	},
	{
		garage_id: 39,
		part_id: 163,
		quantity: 134,
	},
	{
		garage_id: 39,
		part_id: 164,
		quantity: 79,
	},
	{
		garage_id: 39,
		part_id: 165,
		quantity: 348,
	},
	{
		garage_id: 39,
		part_id: 166,
		quantity: 29,
	},
	{
		garage_id: 39,
		part_id: 167,
		quantity: 457,
	},
	{
		garage_id: 39,
		part_id: 168,
		quantity: 35,
	},
	{
		garage_id: 39,
		part_id: 169,
		quantity: 469,
	},
	{
		garage_id: 39,
		part_id: 170,
		quantity: 368,
	},
	{
		garage_id: 39,
		part_id: 171,
		quantity: 292,
	},
	{
		garage_id: 39,
		part_id: 172,
		quantity: 27,
	},
	{
		garage_id: 39,
		part_id: 173,
		quantity: 464,
	},
	{
		garage_id: 39,
		part_id: 174,
		quantity: 11,
	},
	{
		garage_id: 39,
		part_id: 175,
		quantity: 212,
	},
	{
		garage_id: 39,
		part_id: 176,
		quantity: 494,
	},
	{
		garage_id: 39,
		part_id: 177,
		quantity: 397,
	},
	{
		garage_id: 39,
		part_id: 178,
		quantity: 394,
	},
	{
		garage_id: 39,
		part_id: 179,
		quantity: 311,
	},
	{
		garage_id: 39,
		part_id: 180,
		quantity: 165,
	},
	{
		garage_id: 39,
		part_id: 181,
		quantity: 382,
	},
	{
		garage_id: 39,
		part_id: 182,
		quantity: 469,
	},
	{
		garage_id: 39,
		part_id: 183,
		quantity: 154,
	},
	{
		garage_id: 39,
		part_id: 184,
		quantity: 253,
	},
	{
		garage_id: 39,
		part_id: 185,
		quantity: 401,
	},
	{
		garage_id: 39,
		part_id: 186,
		quantity: 406,
	},
	{
		garage_id: 39,
		part_id: 187,
		quantity: 409,
	},
	{
		garage_id: 39,
		part_id: 188,
		quantity: 120,
	},
	{
		garage_id: 39,
		part_id: 189,
		quantity: 305,
	},
	{
		garage_id: 39,
		part_id: 190,
		quantity: 156,
	},
	{
		garage_id: 39,
		part_id: 191,
		quantity: 484,
	},
	{
		garage_id: 39,
		part_id: 192,
		quantity: 460,
	},
	{
		garage_id: 39,
		part_id: 193,
		quantity: 30,
	},
	{
		garage_id: 39,
		part_id: 194,
		quantity: 194,
	},
	{
		garage_id: 39,
		part_id: 195,
		quantity: 44,
	},
	{
		garage_id: 39,
		part_id: 196,
		quantity: 222,
	},
	{
		garage_id: 39,
		part_id: 197,
		quantity: 154,
	},
	{
		garage_id: 39,
		part_id: 198,
		quantity: 267,
	},
	{
		garage_id: 39,
		part_id: 199,
		quantity: 482,
	},
	{
		garage_id: 39,
		part_id: 200,
		quantity: 57,
	},
	{
		garage_id: 39,
		part_id: 201,
		quantity: 431,
	},
	{
		garage_id: 39,
		part_id: 202,
		quantity: 262,
	},
	{
		garage_id: 39,
		part_id: 203,
		quantity: 261,
	},
	{
		garage_id: 39,
		part_id: 204,
		quantity: 299,
	},
	{
		garage_id: 39,
		part_id: 205,
		quantity: 256,
	},
	{
		garage_id: 39,
		part_id: 206,
		quantity: 381,
	},
	{
		garage_id: 39,
		part_id: 207,
		quantity: 234,
	},
	{
		garage_id: 39,
		part_id: 208,
		quantity: 140,
	},
	{
		garage_id: 39,
		part_id: 209,
		quantity: 115,
	},
	{
		garage_id: 39,
		part_id: 210,
		quantity: 52,
	},
	{
		garage_id: 39,
		part_id: 211,
		quantity: 160,
	},
	{
		garage_id: 39,
		part_id: 212,
		quantity: 241,
	},
	{
		garage_id: 39,
		part_id: 213,
		quantity: 232,
	},
	{
		garage_id: 39,
		part_id: 214,
		quantity: 338,
	},
	{
		garage_id: 39,
		part_id: 215,
		quantity: 326,
	},
	{
		garage_id: 39,
		part_id: 216,
		quantity: 81,
	},
	{
		garage_id: 39,
		part_id: 217,
		quantity: 103,
	},
	{
		garage_id: 39,
		part_id: 218,
		quantity: 282,
	},
	{
		garage_id: 39,
		part_id: 219,
		quantity: 183,
	},
	{
		garage_id: 39,
		part_id: 220,
		quantity: 71,
	},
	{
		garage_id: 39,
		part_id: 221,
		quantity: 385,
	},
	{
		garage_id: 39,
		part_id: 222,
		quantity: 42,
	},
	{
		garage_id: 39,
		part_id: 223,
		quantity: 101,
	},
	{
		garage_id: 39,
		part_id: 224,
		quantity: 90,
	},
	{
		garage_id: 39,
		part_id: 225,
		quantity: 307,
	},
	{
		garage_id: 39,
		part_id: 226,
		quantity: 35,
	},
	{
		garage_id: 39,
		part_id: 227,
		quantity: 19,
	},
	{
		garage_id: 39,
		part_id: 228,
		quantity: 439,
	},
	{
		garage_id: 39,
		part_id: 229,
		quantity: 206,
	},
	{
		garage_id: 39,
		part_id: 230,
		quantity: 474,
	},
	{
		garage_id: 39,
		part_id: 231,
		quantity: 204,
	},
	{
		garage_id: 39,
		part_id: 232,
		quantity: 314,
	},
	{
		garage_id: 39,
		part_id: 233,
		quantity: 304,
	},
	{
		garage_id: 39,
		part_id: 234,
		quantity: 154,
	},
	{
		garage_id: 39,
		part_id: 235,
		quantity: 493,
	},
	{
		garage_id: 39,
		part_id: 236,
		quantity: 243,
	},
	{
		garage_id: 39,
		part_id: 237,
		quantity: 170,
	},
	{
		garage_id: 39,
		part_id: 238,
		quantity: 318,
	},
	{
		garage_id: 39,
		part_id: 239,
		quantity: 125,
	},
	{
		garage_id: 39,
		part_id: 240,
		quantity: 376,
	},
	{
		garage_id: 39,
		part_id: 241,
		quantity: 388,
	},
	{
		garage_id: 39,
		part_id: 242,
		quantity: 413,
	},
	{
		garage_id: 39,
		part_id: 243,
		quantity: 468,
	},
	{
		garage_id: 39,
		part_id: 244,
		quantity: 231,
	},
	{
		garage_id: 39,
		part_id: 245,
		quantity: 241,
	},
	{
		garage_id: 39,
		part_id: 246,
		quantity: 332,
	},
	{
		garage_id: 39,
		part_id: 247,
		quantity: 241,
	},
	{
		garage_id: 39,
		part_id: 248,
		quantity: 177,
	},
	{
		garage_id: 39,
		part_id: 249,
		quantity: 233,
	},
	{
		garage_id: 39,
		part_id: 250,
		quantity: 97,
	},
	{
		garage_id: 39,
		part_id: 251,
		quantity: 29,
	},
	{
		garage_id: 39,
		part_id: 252,
		quantity: 185,
	},
	{
		garage_id: 39,
		part_id: 253,
		quantity: 296,
	},
	{
		garage_id: 39,
		part_id: 254,
		quantity: 198,
	},
	{
		garage_id: 39,
		part_id: 255,
		quantity: 394,
	},
	{
		garage_id: 39,
		part_id: 256,
		quantity: 72,
	},
	{
		garage_id: 39,
		part_id: 257,
		quantity: 466,
	},
	{
		garage_id: 39,
		part_id: 258,
		quantity: 38,
	},
	{
		garage_id: 39,
		part_id: 259,
		quantity: 500,
	},
	{
		garage_id: 39,
		part_id: 260,
		quantity: 353,
	},
	{
		garage_id: 39,
		part_id: 261,
		quantity: 385,
	},
	{
		garage_id: 39,
		part_id: 262,
		quantity: 439,
	},
	{
		garage_id: 39,
		part_id: 263,
		quantity: 5,
	},
	{
		garage_id: 39,
		part_id: 264,
		quantity: 449,
	},
	{
		garage_id: 39,
		part_id: 265,
		quantity: 129,
	},
	{
		garage_id: 39,
		part_id: 266,
		quantity: 246,
	},
	{
		garage_id: 39,
		part_id: 267,
		quantity: 476,
	},
	{
		garage_id: 39,
		part_id: 268,
		quantity: 245,
	},
	{
		garage_id: 39,
		part_id: 269,
		quantity: 53,
	},
	{
		garage_id: 39,
		part_id: 270,
		quantity: 219,
	},
	{
		garage_id: 39,
		part_id: 271,
		quantity: 345,
	},
	{
		garage_id: 39,
		part_id: 272,
		quantity: 432,
	},
	{
		garage_id: 39,
		part_id: 273,
		quantity: 433,
	},
	{
		garage_id: 39,
		part_id: 274,
		quantity: 249,
	},
	{
		garage_id: 39,
		part_id: 275,
		quantity: 457,
	},
	{
		garage_id: 39,
		part_id: 276,
		quantity: 85,
	},
	{
		garage_id: 39,
		part_id: 277,
		quantity: 209,
	},
	{
		garage_id: 39,
		part_id: 278,
		quantity: 370,
	},
	{
		garage_id: 39,
		part_id: 279,
		quantity: 117,
	},
	{
		garage_id: 39,
		part_id: 280,
		quantity: 123,
	},
	{
		garage_id: 39,
		part_id: 281,
		quantity: 498,
	},
	{
		garage_id: 39,
		part_id: 282,
		quantity: 461,
	},
	{
		garage_id: 39,
		part_id: 283,
		quantity: 70,
	},
	{
		garage_id: 39,
		part_id: 284,
		quantity: 396,
	},
	{
		garage_id: 39,
		part_id: 285,
		quantity: 478,
	},
	{
		garage_id: 39,
		part_id: 286,
		quantity: 427,
	},
	{
		garage_id: 39,
		part_id: 287,
		quantity: 153,
	},
	{
		garage_id: 39,
		part_id: 288,
		quantity: 277,
	},
	{
		garage_id: 39,
		part_id: 289,
		quantity: 82,
	},
	{
		garage_id: 39,
		part_id: 290,
		quantity: 248,
	},
	{
		garage_id: 39,
		part_id: 291,
		quantity: 66,
	},
	{
		garage_id: 39,
		part_id: 292,
		quantity: 61,
	},
	{
		garage_id: 39,
		part_id: 293,
		quantity: 17,
	},
	{
		garage_id: 39,
		part_id: 294,
		quantity: 170,
	},
	{
		garage_id: 39,
		part_id: 295,
		quantity: 267,
	},
	{
		garage_id: 39,
		part_id: 296,
		quantity: 201,
	},
	{
		garage_id: 39,
		part_id: 297,
		quantity: 276,
	},
	{
		garage_id: 39,
		part_id: 298,
		quantity: 99,
	},
	{
		garage_id: 39,
		part_id: 299,
		quantity: 25,
	},
	{
		garage_id: 39,
		part_id: 300,
		quantity: 256,
	},
	{
		garage_id: 39,
		part_id: 301,
		quantity: 65,
	},
	{
		garage_id: 39,
		part_id: 302,
		quantity: 202,
	},
	{
		garage_id: 39,
		part_id: 303,
		quantity: 362,
	},
	{
		garage_id: 39,
		part_id: 304,
		quantity: 18,
	},
	{
		garage_id: 39,
		part_id: 305,
		quantity: 50,
	},
	{
		garage_id: 39,
		part_id: 306,
		quantity: 463,
	},
	{
		garage_id: 39,
		part_id: 307,
		quantity: 491,
	},
	{
		garage_id: 39,
		part_id: 308,
		quantity: 176,
	},
	{
		garage_id: 39,
		part_id: 309,
		quantity: 179,
	},
	{
		garage_id: 39,
		part_id: 310,
		quantity: 184,
	},
	{
		garage_id: 39,
		part_id: 311,
		quantity: 466,
	},
	{
		garage_id: 39,
		part_id: 312,
		quantity: 17,
	},
	{
		garage_id: 39,
		part_id: 313,
		quantity: 45,
	},
	{
		garage_id: 39,
		part_id: 314,
		quantity: 185,
	},
	{
		garage_id: 39,
		part_id: 315,
		quantity: 357,
	},
	{
		garage_id: 39,
		part_id: 316,
		quantity: 414,
	},
	{
		garage_id: 39,
		part_id: 317,
		quantity: 492,
	},
	{
		garage_id: 39,
		part_id: 318,
		quantity: 165,
	},
	{
		garage_id: 39,
		part_id: 319,
		quantity: 477,
	},
	{
		garage_id: 39,
		part_id: 320,
		quantity: 500,
	},
	{
		garage_id: 39,
		part_id: 321,
		quantity: 390,
	},
	{
		garage_id: 39,
		part_id: 322,
		quantity: 108,
	},
	{
		garage_id: 39,
		part_id: 323,
		quantity: 261,
	},
	{
		garage_id: 39,
		part_id: 324,
		quantity: 475,
	},
	{
		garage_id: 39,
		part_id: 325,
		quantity: 160,
	},
	{
		garage_id: 39,
		part_id: 326,
		quantity: 106,
	},
	{
		garage_id: 39,
		part_id: 327,
		quantity: 56,
	},
	{
		garage_id: 39,
		part_id: 328,
		quantity: 456,
	},
	{
		garage_id: 39,
		part_id: 329,
		quantity: 321,
	},
	{
		garage_id: 39,
		part_id: 330,
		quantity: 315,
	},
	{
		garage_id: 39,
		part_id: 331,
		quantity: 98,
	},
	{
		garage_id: 39,
		part_id: 332,
		quantity: 155,
	},
	{
		garage_id: 39,
		part_id: 333,
		quantity: 261,
	},
	{
		garage_id: 39,
		part_id: 334,
		quantity: 193,
	},
	{
		garage_id: 39,
		part_id: 335,
		quantity: 336,
	},
	{
		garage_id: 39,
		part_id: 336,
		quantity: 444,
	},
	{
		garage_id: 39,
		part_id: 337,
		quantity: 157,
	},
	{
		garage_id: 39,
		part_id: 338,
		quantity: 476,
	},
	{
		garage_id: 39,
		part_id: 339,
		quantity: 367,
	},
	{
		garage_id: 39,
		part_id: 340,
		quantity: 251,
	},
	{
		garage_id: 39,
		part_id: 341,
		quantity: 341,
	},
	{
		garage_id: 39,
		part_id: 342,
		quantity: 193,
	},
	{
		garage_id: 39,
		part_id: 343,
		quantity: 371,
	},
	{
		garage_id: 39,
		part_id: 344,
		quantity: 316,
	},
	{
		garage_id: 39,
		part_id: 345,
		quantity: 207,
	},
	{
		garage_id: 39,
		part_id: 346,
		quantity: 350,
	},
	{
		garage_id: 39,
		part_id: 347,
		quantity: 124,
	},
	{
		garage_id: 39,
		part_id: 348,
		quantity: 428,
	},
	{
		garage_id: 39,
		part_id: 349,
		quantity: 485,
	},
	{
		garage_id: 39,
		part_id: 350,
		quantity: 140,
	},
	{
		garage_id: 39,
		part_id: 351,
		quantity: 360,
	},
	{
		garage_id: 39,
		part_id: 352,
		quantity: 62,
	},
	{
		garage_id: 39,
		part_id: 353,
		quantity: 226,
	},
	{
		garage_id: 39,
		part_id: 354,
		quantity: 94,
	},
	{
		garage_id: 39,
		part_id: 355,
		quantity: 54,
	},
	{
		garage_id: 39,
		part_id: 356,
		quantity: 308,
	},
	{
		garage_id: 39,
		part_id: 357,
		quantity: 37,
	},
	{
		garage_id: 39,
		part_id: 358,
		quantity: 171,
	},
	{
		garage_id: 39,
		part_id: 359,
		quantity: 393,
	},
	{
		garage_id: 39,
		part_id: 360,
		quantity: 273,
	},
	{
		garage_id: 39,
		part_id: 361,
		quantity: 226,
	},
	{
		garage_id: 39,
		part_id: 362,
		quantity: 358,
	},
	{
		garage_id: 39,
		part_id: 363,
		quantity: 310,
	},
	{
		garage_id: 39,
		part_id: 364,
		quantity: 92,
	},
	{
		garage_id: 39,
		part_id: 365,
		quantity: 485,
	},
	{
		garage_id: 39,
		part_id: 366,
		quantity: 488,
	},
	{
		garage_id: 39,
		part_id: 367,
		quantity: 449,
	},
	{
		garage_id: 39,
		part_id: 368,
		quantity: 405,
	},
	{
		garage_id: 39,
		part_id: 369,
		quantity: 2,
	},
	{
		garage_id: 39,
		part_id: 370,
		quantity: 7,
	},
	{
		garage_id: 39,
		part_id: 371,
		quantity: 475,
	},
	{
		garage_id: 39,
		part_id: 372,
		quantity: 283,
	},
	{
		garage_id: 39,
		part_id: 373,
		quantity: 420,
	},
	{
		garage_id: 39,
		part_id: 374,
		quantity: 222,
	},
	{
		garage_id: 39,
		part_id: 375,
		quantity: 256,
	},
	{
		garage_id: 39,
		part_id: 376,
		quantity: 230,
	},
	{
		garage_id: 39,
		part_id: 377,
		quantity: 83,
	},
	{
		garage_id: 39,
		part_id: 378,
		quantity: 202,
	},
	{
		garage_id: 39,
		part_id: 379,
		quantity: 128,
	},
	{
		garage_id: 39,
		part_id: 380,
		quantity: 63,
	},
	{
		garage_id: 39,
		part_id: 381,
		quantity: 307,
	},
	{
		garage_id: 39,
		part_id: 382,
		quantity: 383,
	},
	{
		garage_id: 39,
		part_id: 383,
		quantity: 133,
	},
	{
		garage_id: 39,
		part_id: 384,
		quantity: 61,
	},
	{
		garage_id: 39,
		part_id: 385,
		quantity: 296,
	},
	{
		garage_id: 39,
		part_id: 386,
		quantity: 235,
	},
	{
		garage_id: 39,
		part_id: 387,
		quantity: 363,
	},
	{
		garage_id: 39,
		part_id: 388,
		quantity: 157,
	},
	{
		garage_id: 39,
		part_id: 389,
		quantity: 110,
	},
	{
		garage_id: 39,
		part_id: 390,
		quantity: 223,
	},
	{
		garage_id: 39,
		part_id: 391,
		quantity: 336,
	},
	{
		garage_id: 39,
		part_id: 392,
		quantity: 89,
	},
	{
		garage_id: 39,
		part_id: 393,
		quantity: 480,
	},
	{
		garage_id: 39,
		part_id: 394,
		quantity: 231,
	},
	{
		garage_id: 39,
		part_id: 395,
		quantity: 401,
	},
	{
		garage_id: 39,
		part_id: 396,
		quantity: 42,
	},
	{
		garage_id: 39,
		part_id: 397,
		quantity: 384,
	},
	{
		garage_id: 39,
		part_id: 398,
		quantity: 77,
	},
	{
		garage_id: 39,
		part_id: 399,
		quantity: 36,
	},
	{
		garage_id: 40,
		part_id: 0,
		quantity: 121,
	},
	{
		garage_id: 40,
		part_id: 1,
		quantity: 475,
	},
	{
		garage_id: 40,
		part_id: 2,
		quantity: 242,
	},
	{
		garage_id: 40,
		part_id: 3,
		quantity: 52,
	},
	{
		garage_id: 40,
		part_id: 4,
		quantity: 152,
	},
	{
		garage_id: 40,
		part_id: 5,
		quantity: 191,
	},
	{
		garage_id: 40,
		part_id: 6,
		quantity: 306,
	},
	{
		garage_id: 40,
		part_id: 7,
		quantity: 367,
	},
	{
		garage_id: 40,
		part_id: 8,
		quantity: 45,
	},
	{
		garage_id: 40,
		part_id: 9,
		quantity: 432,
	},
	{
		garage_id: 40,
		part_id: 10,
		quantity: 313,
	},
	{
		garage_id: 40,
		part_id: 11,
		quantity: 102,
	},
	{
		garage_id: 40,
		part_id: 12,
		quantity: 72,
	},
	{
		garage_id: 40,
		part_id: 13,
		quantity: 94,
	},
	{
		garage_id: 40,
		part_id: 14,
		quantity: 234,
	},
	{
		garage_id: 40,
		part_id: 15,
		quantity: 296,
	},
	{
		garage_id: 40,
		part_id: 16,
		quantity: 257,
	},
	{
		garage_id: 40,
		part_id: 17,
		quantity: 160,
	},
	{
		garage_id: 40,
		part_id: 18,
		quantity: 299,
	},
	{
		garage_id: 40,
		part_id: 19,
		quantity: 145,
	},
	{
		garage_id: 40,
		part_id: 20,
		quantity: 37,
	},
	{
		garage_id: 40,
		part_id: 21,
		quantity: 207,
	},
	{
		garage_id: 40,
		part_id: 22,
		quantity: 206,
	},
	{
		garage_id: 40,
		part_id: 23,
		quantity: 435,
	},
	{
		garage_id: 40,
		part_id: 24,
		quantity: 467,
	},
	{
		garage_id: 40,
		part_id: 25,
		quantity: 333,
	},
	{
		garage_id: 40,
		part_id: 26,
		quantity: 493,
	},
	{
		garage_id: 40,
		part_id: 27,
		quantity: 32,
	},
	{
		garage_id: 40,
		part_id: 28,
		quantity: 86,
	},
	{
		garage_id: 40,
		part_id: 29,
		quantity: 392,
	},
	{
		garage_id: 40,
		part_id: 30,
		quantity: 451,
	},
	{
		garage_id: 40,
		part_id: 31,
		quantity: 34,
	},
	{
		garage_id: 40,
		part_id: 32,
		quantity: 362,
	},
	{
		garage_id: 40,
		part_id: 33,
		quantity: 170,
	},
	{
		garage_id: 40,
		part_id: 34,
		quantity: 341,
	},
	{
		garage_id: 40,
		part_id: 35,
		quantity: 210,
	},
	{
		garage_id: 40,
		part_id: 36,
		quantity: 475,
	},
	{
		garage_id: 40,
		part_id: 37,
		quantity: 300,
	},
	{
		garage_id: 40,
		part_id: 38,
		quantity: 461,
	},
	{
		garage_id: 40,
		part_id: 39,
		quantity: 384,
	},
	{
		garage_id: 40,
		part_id: 40,
		quantity: 55,
	},
	{
		garage_id: 40,
		part_id: 41,
		quantity: 22,
	},
	{
		garage_id: 40,
		part_id: 42,
		quantity: 320,
	},
	{
		garage_id: 40,
		part_id: 43,
		quantity: 285,
	},
	{
		garage_id: 40,
		part_id: 44,
		quantity: 171,
	},
	{
		garage_id: 40,
		part_id: 45,
		quantity: 51,
	},
	{
		garage_id: 40,
		part_id: 46,
		quantity: 348,
	},
	{
		garage_id: 40,
		part_id: 47,
		quantity: 42,
	},
	{
		garage_id: 40,
		part_id: 48,
		quantity: 316,
	},
	{
		garage_id: 40,
		part_id: 49,
		quantity: 444,
	},
	{
		garage_id: 40,
		part_id: 50,
		quantity: 226,
	},
	{
		garage_id: 40,
		part_id: 51,
		quantity: 410,
	},
	{
		garage_id: 40,
		part_id: 52,
		quantity: 216,
	},
	{
		garage_id: 40,
		part_id: 53,
		quantity: 332,
	},
	{
		garage_id: 40,
		part_id: 54,
		quantity: 210,
	},
	{
		garage_id: 40,
		part_id: 55,
		quantity: 467,
	},
	{
		garage_id: 40,
		part_id: 56,
		quantity: 354,
	},
	{
		garage_id: 40,
		part_id: 57,
		quantity: 500,
	},
	{
		garage_id: 40,
		part_id: 58,
		quantity: 143,
	},
	{
		garage_id: 40,
		part_id: 59,
		quantity: 480,
	},
	{
		garage_id: 40,
		part_id: 60,
		quantity: 313,
	},
	{
		garage_id: 40,
		part_id: 61,
		quantity: 205,
	},
	{
		garage_id: 40,
		part_id: 62,
		quantity: 245,
	},
	{
		garage_id: 40,
		part_id: 63,
		quantity: 61,
	},
	{
		garage_id: 40,
		part_id: 64,
		quantity: 444,
	},
	{
		garage_id: 40,
		part_id: 65,
		quantity: 387,
	},
	{
		garage_id: 40,
		part_id: 66,
		quantity: 42,
	},
	{
		garage_id: 40,
		part_id: 67,
		quantity: 294,
	},
	{
		garage_id: 40,
		part_id: 68,
		quantity: 213,
	},
	{
		garage_id: 40,
		part_id: 69,
		quantity: 38,
	},
	{
		garage_id: 40,
		part_id: 70,
		quantity: 150,
	},
	{
		garage_id: 40,
		part_id: 71,
		quantity: 19,
	},
	{
		garage_id: 40,
		part_id: 72,
		quantity: 364,
	},
	{
		garage_id: 40,
		part_id: 73,
		quantity: 25,
	},
	{
		garage_id: 40,
		part_id: 74,
		quantity: 155,
	},
	{
		garage_id: 40,
		part_id: 75,
		quantity: 163,
	},
	{
		garage_id: 40,
		part_id: 76,
		quantity: 450,
	},
	{
		garage_id: 40,
		part_id: 77,
		quantity: 195,
	},
	{
		garage_id: 40,
		part_id: 78,
		quantity: 259,
	},
	{
		garage_id: 40,
		part_id: 79,
		quantity: 404,
	},
	{
		garage_id: 40,
		part_id: 80,
		quantity: 46,
	},
	{
		garage_id: 40,
		part_id: 81,
		quantity: 172,
	},
	{
		garage_id: 40,
		part_id: 82,
		quantity: 226,
	},
	{
		garage_id: 40,
		part_id: 83,
		quantity: 327,
	},
	{
		garage_id: 40,
		part_id: 84,
		quantity: 152,
	},
	{
		garage_id: 40,
		part_id: 85,
		quantity: 385,
	},
	{
		garage_id: 40,
		part_id: 86,
		quantity: 117,
	},
	{
		garage_id: 40,
		part_id: 87,
		quantity: 329,
	},
	{
		garage_id: 40,
		part_id: 88,
		quantity: 201,
	},
	{
		garage_id: 40,
		part_id: 89,
		quantity: 232,
	},
	{
		garage_id: 40,
		part_id: 90,
		quantity: 497,
	},
	{
		garage_id: 40,
		part_id: 91,
		quantity: 368,
	},
	{
		garage_id: 40,
		part_id: 92,
		quantity: 336,
	},
	{
		garage_id: 40,
		part_id: 93,
		quantity: 57,
	},
	{
		garage_id: 40,
		part_id: 94,
		quantity: 169,
	},
	{
		garage_id: 40,
		part_id: 95,
		quantity: 69,
	},
	{
		garage_id: 40,
		part_id: 96,
		quantity: 264,
	},
	{
		garage_id: 40,
		part_id: 97,
		quantity: 377,
	},
	{
		garage_id: 40,
		part_id: 98,
		quantity: 105,
	},
	{
		garage_id: 40,
		part_id: 99,
		quantity: 328,
	},
	{
		garage_id: 40,
		part_id: 100,
		quantity: 76,
	},
	{
		garage_id: 40,
		part_id: 101,
		quantity: 218,
	},
	{
		garage_id: 40,
		part_id: 102,
		quantity: 145,
	},
	{
		garage_id: 40,
		part_id: 103,
		quantity: 367,
	},
	{
		garage_id: 40,
		part_id: 104,
		quantity: 80,
	},
	{
		garage_id: 40,
		part_id: 105,
		quantity: 221,
	},
	{
		garage_id: 40,
		part_id: 106,
		quantity: 399,
	},
	{
		garage_id: 40,
		part_id: 107,
		quantity: 313,
	},
	{
		garage_id: 40,
		part_id: 108,
		quantity: 78,
	},
	{
		garage_id: 40,
		part_id: 109,
		quantity: 93,
	},
	{
		garage_id: 40,
		part_id: 110,
		quantity: 195,
	},
	{
		garage_id: 40,
		part_id: 111,
		quantity: 456,
	},
	{
		garage_id: 40,
		part_id: 112,
		quantity: 213,
	},
	{
		garage_id: 40,
		part_id: 113,
		quantity: 152,
	},
	{
		garage_id: 40,
		part_id: 114,
		quantity: 2,
	},
	{
		garage_id: 40,
		part_id: 115,
		quantity: 16,
	},
	{
		garage_id: 40,
		part_id: 116,
		quantity: 304,
	},
	{
		garage_id: 40,
		part_id: 117,
		quantity: 35,
	},
	{
		garage_id: 40,
		part_id: 118,
		quantity: 133,
	},
	{
		garage_id: 40,
		part_id: 119,
		quantity: 473,
	},
	{
		garage_id: 40,
		part_id: 120,
		quantity: 472,
	},
	{
		garage_id: 40,
		part_id: 121,
		quantity: 486,
	},
	{
		garage_id: 40,
		part_id: 122,
		quantity: 52,
	},
	{
		garage_id: 40,
		part_id: 123,
		quantity: 245,
	},
	{
		garage_id: 40,
		part_id: 124,
		quantity: 206,
	},
	{
		garage_id: 40,
		part_id: 125,
		quantity: 89,
	},
	{
		garage_id: 40,
		part_id: 126,
		quantity: 178,
	},
	{
		garage_id: 40,
		part_id: 127,
		quantity: 221,
	},
	{
		garage_id: 40,
		part_id: 128,
		quantity: 364,
	},
	{
		garage_id: 40,
		part_id: 129,
		quantity: 98,
	},
	{
		garage_id: 40,
		part_id: 130,
		quantity: 79,
	},
	{
		garage_id: 40,
		part_id: 131,
		quantity: 1,
	},
	{
		garage_id: 40,
		part_id: 132,
		quantity: 46,
	},
	{
		garage_id: 40,
		part_id: 133,
		quantity: 300,
	},
	{
		garage_id: 40,
		part_id: 134,
		quantity: 8,
	},
	{
		garage_id: 40,
		part_id: 135,
		quantity: 34,
	},
	{
		garage_id: 40,
		part_id: 136,
		quantity: 296,
	},
	{
		garage_id: 40,
		part_id: 137,
		quantity: 23,
	},
	{
		garage_id: 40,
		part_id: 138,
		quantity: 304,
	},
	{
		garage_id: 40,
		part_id: 139,
		quantity: 333,
	},
	{
		garage_id: 40,
		part_id: 140,
		quantity: 141,
	},
	{
		garage_id: 40,
		part_id: 141,
		quantity: 168,
	},
	{
		garage_id: 40,
		part_id: 142,
		quantity: 453,
	},
	{
		garage_id: 40,
		part_id: 143,
		quantity: 307,
	},
	{
		garage_id: 40,
		part_id: 144,
		quantity: 194,
	},
	{
		garage_id: 40,
		part_id: 145,
		quantity: 250,
	},
	{
		garage_id: 40,
		part_id: 146,
		quantity: 198,
	},
	{
		garage_id: 40,
		part_id: 147,
		quantity: 185,
	},
	{
		garage_id: 40,
		part_id: 148,
		quantity: 298,
	},
	{
		garage_id: 40,
		part_id: 149,
		quantity: 78,
	},
	{
		garage_id: 40,
		part_id: 150,
		quantity: 314,
	},
	{
		garage_id: 40,
		part_id: 151,
		quantity: 405,
	},
	{
		garage_id: 40,
		part_id: 152,
		quantity: 203,
	},
	{
		garage_id: 40,
		part_id: 153,
		quantity: 207,
	},
	{
		garage_id: 40,
		part_id: 154,
		quantity: 254,
	},
	{
		garage_id: 40,
		part_id: 155,
		quantity: 54,
	},
	{
		garage_id: 40,
		part_id: 156,
		quantity: 138,
	},
	{
		garage_id: 40,
		part_id: 157,
		quantity: 383,
	},
	{
		garage_id: 40,
		part_id: 158,
		quantity: 306,
	},
	{
		garage_id: 40,
		part_id: 159,
		quantity: 377,
	},
	{
		garage_id: 40,
		part_id: 160,
		quantity: 200,
	},
	{
		garage_id: 40,
		part_id: 161,
		quantity: 96,
	},
	{
		garage_id: 40,
		part_id: 162,
		quantity: 310,
	},
	{
		garage_id: 40,
		part_id: 163,
		quantity: 225,
	},
	{
		garage_id: 40,
		part_id: 164,
		quantity: 222,
	},
	{
		garage_id: 40,
		part_id: 165,
		quantity: 262,
	},
	{
		garage_id: 40,
		part_id: 166,
		quantity: 494,
	},
	{
		garage_id: 40,
		part_id: 167,
		quantity: 157,
	},
	{
		garage_id: 40,
		part_id: 168,
		quantity: 169,
	},
	{
		garage_id: 40,
		part_id: 169,
		quantity: 248,
	},
	{
		garage_id: 40,
		part_id: 170,
		quantity: 145,
	},
	{
		garage_id: 40,
		part_id: 171,
		quantity: 401,
	},
	{
		garage_id: 40,
		part_id: 172,
		quantity: 290,
	},
	{
		garage_id: 40,
		part_id: 173,
		quantity: 133,
	},
	{
		garage_id: 40,
		part_id: 174,
		quantity: 62,
	},
	{
		garage_id: 40,
		part_id: 175,
		quantity: 67,
	},
	{
		garage_id: 40,
		part_id: 176,
		quantity: 358,
	},
	{
		garage_id: 40,
		part_id: 177,
		quantity: 177,
	},
	{
		garage_id: 40,
		part_id: 178,
		quantity: 57,
	},
	{
		garage_id: 40,
		part_id: 179,
		quantity: 342,
	},
	{
		garage_id: 40,
		part_id: 180,
		quantity: 235,
	},
	{
		garage_id: 40,
		part_id: 181,
		quantity: 112,
	},
	{
		garage_id: 40,
		part_id: 182,
		quantity: 4,
	},
	{
		garage_id: 40,
		part_id: 183,
		quantity: 449,
	},
	{
		garage_id: 40,
		part_id: 184,
		quantity: 247,
	},
	{
		garage_id: 40,
		part_id: 185,
		quantity: 342,
	},
	{
		garage_id: 40,
		part_id: 186,
		quantity: 41,
	},
	{
		garage_id: 40,
		part_id: 187,
		quantity: 252,
	},
	{
		garage_id: 40,
		part_id: 188,
		quantity: 286,
	},
	{
		garage_id: 40,
		part_id: 189,
		quantity: 354,
	},
	{
		garage_id: 40,
		part_id: 190,
		quantity: 370,
	},
	{
		garage_id: 40,
		part_id: 191,
		quantity: 426,
	},
	{
		garage_id: 40,
		part_id: 192,
		quantity: 183,
	},
	{
		garage_id: 40,
		part_id: 193,
		quantity: 115,
	},
	{
		garage_id: 40,
		part_id: 194,
		quantity: 179,
	},
	{
		garage_id: 40,
		part_id: 195,
		quantity: 325,
	},
	{
		garage_id: 40,
		part_id: 196,
		quantity: 200,
	},
	{
		garage_id: 40,
		part_id: 197,
		quantity: 125,
	},
	{
		garage_id: 40,
		part_id: 198,
		quantity: 449,
	},
	{
		garage_id: 40,
		part_id: 199,
		quantity: 104,
	},
	{
		garage_id: 40,
		part_id: 200,
		quantity: 5,
	},
	{
		garage_id: 40,
		part_id: 201,
		quantity: 308,
	},
	{
		garage_id: 40,
		part_id: 202,
		quantity: 258,
	},
	{
		garage_id: 40,
		part_id: 203,
		quantity: 361,
	},
	{
		garage_id: 40,
		part_id: 204,
		quantity: 421,
	},
	{
		garage_id: 40,
		part_id: 205,
		quantity: 330,
	},
	{
		garage_id: 40,
		part_id: 206,
		quantity: 20,
	},
	{
		garage_id: 40,
		part_id: 207,
		quantity: 7,
	},
	{
		garage_id: 40,
		part_id: 208,
		quantity: 95,
	},
	{
		garage_id: 40,
		part_id: 209,
		quantity: 392,
	},
	{
		garage_id: 40,
		part_id: 210,
		quantity: 386,
	},
	{
		garage_id: 40,
		part_id: 211,
		quantity: 197,
	},
	{
		garage_id: 40,
		part_id: 212,
		quantity: 383,
	},
	{
		garage_id: 40,
		part_id: 213,
		quantity: 387,
	},
	{
		garage_id: 40,
		part_id: 214,
		quantity: 184,
	},
	{
		garage_id: 40,
		part_id: 215,
		quantity: 457,
	},
	{
		garage_id: 40,
		part_id: 216,
		quantity: 17,
	},
	{
		garage_id: 40,
		part_id: 217,
		quantity: 47,
	},
	{
		garage_id: 40,
		part_id: 218,
		quantity: 280,
	},
	{
		garage_id: 40,
		part_id: 219,
		quantity: 19,
	},
	{
		garage_id: 40,
		part_id: 220,
		quantity: 316,
	},
	{
		garage_id: 40,
		part_id: 221,
		quantity: 436,
	},
	{
		garage_id: 40,
		part_id: 222,
		quantity: 324,
	},
	{
		garage_id: 40,
		part_id: 223,
		quantity: 289,
	},
	{
		garage_id: 40,
		part_id: 224,
		quantity: 28,
	},
	{
		garage_id: 40,
		part_id: 225,
		quantity: 165,
	},
	{
		garage_id: 40,
		part_id: 226,
		quantity: 343,
	},
	{
		garage_id: 40,
		part_id: 227,
		quantity: 161,
	},
	{
		garage_id: 40,
		part_id: 228,
		quantity: 286,
	},
	{
		garage_id: 40,
		part_id: 229,
		quantity: 347,
	},
	{
		garage_id: 40,
		part_id: 230,
		quantity: 480,
	},
	{
		garage_id: 40,
		part_id: 231,
		quantity: 307,
	},
	{
		garage_id: 40,
		part_id: 232,
		quantity: 398,
	},
	{
		garage_id: 40,
		part_id: 233,
		quantity: 465,
	},
	{
		garage_id: 40,
		part_id: 234,
		quantity: 472,
	},
	{
		garage_id: 40,
		part_id: 235,
		quantity: 165,
	},
	{
		garage_id: 40,
		part_id: 236,
		quantity: 319,
	},
	{
		garage_id: 40,
		part_id: 237,
		quantity: 347,
	},
	{
		garage_id: 40,
		part_id: 238,
		quantity: 213,
	},
	{
		garage_id: 40,
		part_id: 239,
		quantity: 487,
	},
	{
		garage_id: 40,
		part_id: 240,
		quantity: 112,
	},
	{
		garage_id: 40,
		part_id: 241,
		quantity: 413,
	},
	{
		garage_id: 40,
		part_id: 242,
		quantity: 334,
	},
	{
		garage_id: 40,
		part_id: 243,
		quantity: 183,
	},
	{
		garage_id: 40,
		part_id: 244,
		quantity: 410,
	},
	{
		garage_id: 40,
		part_id: 245,
		quantity: 180,
	},
	{
		garage_id: 40,
		part_id: 246,
		quantity: 410,
	},
	{
		garage_id: 40,
		part_id: 247,
		quantity: 96,
	},
	{
		garage_id: 40,
		part_id: 248,
		quantity: 269,
	},
	{
		garage_id: 40,
		part_id: 249,
		quantity: 231,
	},
	{
		garage_id: 40,
		part_id: 250,
		quantity: 327,
	},
	{
		garage_id: 40,
		part_id: 251,
		quantity: 92,
	},
	{
		garage_id: 40,
		part_id: 252,
		quantity: 48,
	},
	{
		garage_id: 40,
		part_id: 253,
		quantity: 145,
	},
	{
		garage_id: 40,
		part_id: 254,
		quantity: 232,
	},
	{
		garage_id: 40,
		part_id: 255,
		quantity: 230,
	},
	{
		garage_id: 40,
		part_id: 256,
		quantity: 460,
	},
	{
		garage_id: 40,
		part_id: 257,
		quantity: 486,
	},
	{
		garage_id: 40,
		part_id: 258,
		quantity: 479,
	},
	{
		garage_id: 40,
		part_id: 259,
		quantity: 8,
	},
	{
		garage_id: 40,
		part_id: 260,
		quantity: 15,
	},
	{
		garage_id: 40,
		part_id: 261,
		quantity: 459,
	},
	{
		garage_id: 40,
		part_id: 262,
		quantity: 397,
	},
	{
		garage_id: 40,
		part_id: 263,
		quantity: 439,
	},
	{
		garage_id: 40,
		part_id: 264,
		quantity: 369,
	},
	{
		garage_id: 40,
		part_id: 265,
		quantity: 445,
	},
	{
		garage_id: 40,
		part_id: 266,
		quantity: 259,
	},
	{
		garage_id: 40,
		part_id: 267,
		quantity: 68,
	},
	{
		garage_id: 40,
		part_id: 268,
		quantity: 264,
	},
	{
		garage_id: 40,
		part_id: 269,
		quantity: 432,
	},
	{
		garage_id: 40,
		part_id: 270,
		quantity: 370,
	},
	{
		garage_id: 40,
		part_id: 271,
		quantity: 209,
	},
	{
		garage_id: 40,
		part_id: 272,
		quantity: 485,
	},
	{
		garage_id: 40,
		part_id: 273,
		quantity: 109,
	},
	{
		garage_id: 40,
		part_id: 274,
		quantity: 298,
	},
	{
		garage_id: 40,
		part_id: 275,
		quantity: 6,
	},
	{
		garage_id: 40,
		part_id: 276,
		quantity: 11,
	},
	{
		garage_id: 40,
		part_id: 277,
		quantity: 1,
	},
	{
		garage_id: 40,
		part_id: 278,
		quantity: 459,
	},
	{
		garage_id: 40,
		part_id: 279,
		quantity: 104,
	},
	{
		garage_id: 40,
		part_id: 280,
		quantity: 209,
	},
	{
		garage_id: 40,
		part_id: 281,
		quantity: 420,
	},
	{
		garage_id: 40,
		part_id: 282,
		quantity: 341,
	},
	{
		garage_id: 40,
		part_id: 283,
		quantity: 405,
	},
	{
		garage_id: 40,
		part_id: 284,
		quantity: 219,
	},
	{
		garage_id: 40,
		part_id: 285,
		quantity: 129,
	},
	{
		garage_id: 40,
		part_id: 286,
		quantity: 236,
	},
	{
		garage_id: 40,
		part_id: 287,
		quantity: 137,
	},
	{
		garage_id: 40,
		part_id: 288,
		quantity: 220,
	},
	{
		garage_id: 40,
		part_id: 289,
		quantity: 260,
	},
	{
		garage_id: 40,
		part_id: 290,
		quantity: 86,
	},
	{
		garage_id: 40,
		part_id: 291,
		quantity: 39,
	},
	{
		garage_id: 40,
		part_id: 292,
		quantity: 70,
	},
	{
		garage_id: 40,
		part_id: 293,
		quantity: 452,
	},
	{
		garage_id: 40,
		part_id: 294,
		quantity: 74,
	},
	{
		garage_id: 40,
		part_id: 295,
		quantity: 250,
	},
	{
		garage_id: 40,
		part_id: 296,
		quantity: 427,
	},
	{
		garage_id: 40,
		part_id: 297,
		quantity: 333,
	},
	{
		garage_id: 40,
		part_id: 298,
		quantity: 301,
	},
	{
		garage_id: 40,
		part_id: 299,
		quantity: 356,
	},
	{
		garage_id: 40,
		part_id: 300,
		quantity: 237,
	},
	{
		garage_id: 40,
		part_id: 301,
		quantity: 216,
	},
	{
		garage_id: 40,
		part_id: 302,
		quantity: 431,
	},
	{
		garage_id: 40,
		part_id: 303,
		quantity: 160,
	},
	{
		garage_id: 40,
		part_id: 304,
		quantity: 395,
	},
	{
		garage_id: 40,
		part_id: 305,
		quantity: 360,
	},
	{
		garage_id: 40,
		part_id: 306,
		quantity: 106,
	},
	{
		garage_id: 40,
		part_id: 307,
		quantity: 20,
	},
	{
		garage_id: 40,
		part_id: 308,
		quantity: 58,
	},
	{
		garage_id: 40,
		part_id: 309,
		quantity: 248,
	},
	{
		garage_id: 40,
		part_id: 310,
		quantity: 242,
	},
	{
		garage_id: 40,
		part_id: 311,
		quantity: 21,
	},
	{
		garage_id: 40,
		part_id: 312,
		quantity: 29,
	},
	{
		garage_id: 40,
		part_id: 313,
		quantity: 477,
	},
	{
		garage_id: 40,
		part_id: 314,
		quantity: 349,
	},
	{
		garage_id: 40,
		part_id: 315,
		quantity: 305,
	},
	{
		garage_id: 40,
		part_id: 316,
		quantity: 390,
	},
	{
		garage_id: 40,
		part_id: 317,
		quantity: 330,
	},
	{
		garage_id: 40,
		part_id: 318,
		quantity: 119,
	},
	{
		garage_id: 40,
		part_id: 319,
		quantity: 136,
	},
	{
		garage_id: 40,
		part_id: 320,
		quantity: 184,
	},
	{
		garage_id: 40,
		part_id: 321,
		quantity: 371,
	},
	{
		garage_id: 40,
		part_id: 322,
		quantity: 241,
	},
	{
		garage_id: 40,
		part_id: 323,
		quantity: 140,
	},
	{
		garage_id: 40,
		part_id: 324,
		quantity: 421,
	},
	{
		garage_id: 40,
		part_id: 325,
		quantity: 222,
	},
	{
		garage_id: 40,
		part_id: 326,
		quantity: 162,
	},
	{
		garage_id: 40,
		part_id: 327,
		quantity: 86,
	},
	{
		garage_id: 40,
		part_id: 328,
		quantity: 366,
	},
	{
		garage_id: 40,
		part_id: 329,
		quantity: 124,
	},
	{
		garage_id: 40,
		part_id: 330,
		quantity: 399,
	},
	{
		garage_id: 40,
		part_id: 331,
		quantity: 479,
	},
	{
		garage_id: 40,
		part_id: 332,
		quantity: 81,
	},
	{
		garage_id: 40,
		part_id: 333,
		quantity: 18,
	},
	{
		garage_id: 40,
		part_id: 334,
		quantity: 275,
	},
	{
		garage_id: 40,
		part_id: 335,
		quantity: 396,
	},
	{
		garage_id: 40,
		part_id: 336,
		quantity: 17,
	},
	{
		garage_id: 40,
		part_id: 337,
		quantity: 90,
	},
	{
		garage_id: 40,
		part_id: 338,
		quantity: 347,
	},
	{
		garage_id: 40,
		part_id: 339,
		quantity: 178,
	},
	{
		garage_id: 40,
		part_id: 340,
		quantity: 222,
	},
	{
		garage_id: 40,
		part_id: 341,
		quantity: 61,
	},
	{
		garage_id: 40,
		part_id: 342,
		quantity: 115,
	},
	{
		garage_id: 40,
		part_id: 343,
		quantity: 112,
	},
	{
		garage_id: 40,
		part_id: 344,
		quantity: 101,
	},
	{
		garage_id: 40,
		part_id: 345,
		quantity: 121,
	},
	{
		garage_id: 40,
		part_id: 346,
		quantity: 382,
	},
	{
		garage_id: 40,
		part_id: 347,
		quantity: 196,
	},
	{
		garage_id: 40,
		part_id: 348,
		quantity: 189,
	},
	{
		garage_id: 40,
		part_id: 349,
		quantity: 404,
	},
	{
		garage_id: 40,
		part_id: 350,
		quantity: 246,
	},
	{
		garage_id: 40,
		part_id: 351,
		quantity: 222,
	},
	{
		garage_id: 40,
		part_id: 352,
		quantity: 39,
	},
	{
		garage_id: 40,
		part_id: 353,
		quantity: 20,
	},
	{
		garage_id: 40,
		part_id: 354,
		quantity: 140,
	},
	{
		garage_id: 40,
		part_id: 355,
		quantity: 441,
	},
	{
		garage_id: 40,
		part_id: 356,
		quantity: 15,
	},
	{
		garage_id: 40,
		part_id: 357,
		quantity: 469,
	},
	{
		garage_id: 40,
		part_id: 358,
		quantity: 339,
	},
	{
		garage_id: 40,
		part_id: 359,
		quantity: 383,
	},
	{
		garage_id: 40,
		part_id: 360,
		quantity: 175,
	},
	{
		garage_id: 40,
		part_id: 361,
		quantity: 276,
	},
	{
		garage_id: 40,
		part_id: 362,
		quantity: 244,
	},
	{
		garage_id: 40,
		part_id: 363,
		quantity: 53,
	},
	{
		garage_id: 40,
		part_id: 364,
		quantity: 413,
	},
	{
		garage_id: 40,
		part_id: 365,
		quantity: 175,
	},
	{
		garage_id: 40,
		part_id: 366,
		quantity: 335,
	},
	{
		garage_id: 40,
		part_id: 367,
		quantity: 62,
	},
	{
		garage_id: 40,
		part_id: 368,
		quantity: 385,
	},
	{
		garage_id: 40,
		part_id: 369,
		quantity: 113,
	},
	{
		garage_id: 40,
		part_id: 370,
		quantity: 292,
	},
	{
		garage_id: 40,
		part_id: 371,
		quantity: 358,
	},
	{
		garage_id: 40,
		part_id: 372,
		quantity: 69,
	},
	{
		garage_id: 40,
		part_id: 373,
		quantity: 101,
	},
	{
		garage_id: 40,
		part_id: 374,
		quantity: 154,
	},
	{
		garage_id: 40,
		part_id: 375,
		quantity: 39,
	},
	{
		garage_id: 40,
		part_id: 376,
		quantity: 263,
	},
	{
		garage_id: 40,
		part_id: 377,
		quantity: 217,
	},
	{
		garage_id: 40,
		part_id: 378,
		quantity: 277,
	},
	{
		garage_id: 40,
		part_id: 379,
		quantity: 222,
	},
	{
		garage_id: 40,
		part_id: 380,
		quantity: 381,
	},
	{
		garage_id: 40,
		part_id: 381,
		quantity: 461,
	},
	{
		garage_id: 40,
		part_id: 382,
		quantity: 228,
	},
	{
		garage_id: 40,
		part_id: 383,
		quantity: 139,
	},
	{
		garage_id: 40,
		part_id: 384,
		quantity: 434,
	},
	{
		garage_id: 40,
		part_id: 385,
		quantity: 447,
	},
	{
		garage_id: 40,
		part_id: 386,
		quantity: 441,
	},
	{
		garage_id: 40,
		part_id: 387,
		quantity: 94,
	},
	{
		garage_id: 40,
		part_id: 388,
		quantity: 131,
	},
	{
		garage_id: 40,
		part_id: 389,
		quantity: 166,
	},
	{
		garage_id: 40,
		part_id: 390,
		quantity: 296,
	},
	{
		garage_id: 40,
		part_id: 391,
		quantity: 413,
	},
	{
		garage_id: 40,
		part_id: 392,
		quantity: 30,
	},
	{
		garage_id: 40,
		part_id: 393,
		quantity: 161,
	},
	{
		garage_id: 40,
		part_id: 394,
		quantity: 202,
	},
	{
		garage_id: 40,
		part_id: 395,
		quantity: 405,
	},
	{
		garage_id: 40,
		part_id: 396,
		quantity: 321,
	},
	{
		garage_id: 40,
		part_id: 397,
		quantity: 149,
	},
	{
		garage_id: 40,
		part_id: 398,
		quantity: 32,
	},
	{
		garage_id: 40,
		part_id: 399,
		quantity: 221,
	},
	{
		garage_id: 41,
		part_id: 0,
		quantity: 393,
	},
	{
		garage_id: 41,
		part_id: 1,
		quantity: 336,
	},
	{
		garage_id: 41,
		part_id: 2,
		quantity: 161,
	},
	{
		garage_id: 41,
		part_id: 3,
		quantity: 28,
	},
	{
		garage_id: 41,
		part_id: 4,
		quantity: 184,
	},
	{
		garage_id: 41,
		part_id: 5,
		quantity: 444,
	},
	{
		garage_id: 41,
		part_id: 6,
		quantity: 224,
	},
	{
		garage_id: 41,
		part_id: 7,
		quantity: 33,
	},
	{
		garage_id: 41,
		part_id: 8,
		quantity: 332,
	},
	{
		garage_id: 41,
		part_id: 9,
		quantity: 152,
	},
	{
		garage_id: 41,
		part_id: 10,
		quantity: 114,
	},
	{
		garage_id: 41,
		part_id: 11,
		quantity: 437,
	},
	{
		garage_id: 41,
		part_id: 12,
		quantity: 243,
	},
	{
		garage_id: 41,
		part_id: 13,
		quantity: 133,
	},
	{
		garage_id: 41,
		part_id: 14,
		quantity: 263,
	},
	{
		garage_id: 41,
		part_id: 15,
		quantity: 384,
	},
	{
		garage_id: 41,
		part_id: 16,
		quantity: 97,
	},
	{
		garage_id: 41,
		part_id: 17,
		quantity: 90,
	},
	{
		garage_id: 41,
		part_id: 18,
		quantity: 79,
	},
	{
		garage_id: 41,
		part_id: 19,
		quantity: 323,
	},
	{
		garage_id: 41,
		part_id: 20,
		quantity: 434,
	},
	{
		garage_id: 41,
		part_id: 21,
		quantity: 168,
	},
	{
		garage_id: 41,
		part_id: 22,
		quantity: 140,
	},
	{
		garage_id: 41,
		part_id: 23,
		quantity: 407,
	},
	{
		garage_id: 41,
		part_id: 24,
		quantity: 174,
	},
	{
		garage_id: 41,
		part_id: 25,
		quantity: 116,
	},
	{
		garage_id: 41,
		part_id: 26,
		quantity: 261,
	},
	{
		garage_id: 41,
		part_id: 27,
		quantity: 331,
	},
	{
		garage_id: 41,
		part_id: 28,
		quantity: 0,
	},
	{
		garage_id: 41,
		part_id: 29,
		quantity: 2,
	},
	{
		garage_id: 41,
		part_id: 30,
		quantity: 194,
	},
	{
		garage_id: 41,
		part_id: 31,
		quantity: 316,
	},
	{
		garage_id: 41,
		part_id: 32,
		quantity: 155,
	},
	{
		garage_id: 41,
		part_id: 33,
		quantity: 210,
	},
	{
		garage_id: 41,
		part_id: 34,
		quantity: 194,
	},
	{
		garage_id: 41,
		part_id: 35,
		quantity: 207,
	},
	{
		garage_id: 41,
		part_id: 36,
		quantity: 248,
	},
	{
		garage_id: 41,
		part_id: 37,
		quantity: 342,
	},
	{
		garage_id: 41,
		part_id: 38,
		quantity: 6,
	},
	{
		garage_id: 41,
		part_id: 39,
		quantity: 153,
	},
	{
		garage_id: 41,
		part_id: 40,
		quantity: 6,
	},
	{
		garage_id: 41,
		part_id: 41,
		quantity: 174,
	},
	{
		garage_id: 41,
		part_id: 42,
		quantity: 405,
	},
	{
		garage_id: 41,
		part_id: 43,
		quantity: 355,
	},
	{
		garage_id: 41,
		part_id: 44,
		quantity: 232,
	},
	{
		garage_id: 41,
		part_id: 45,
		quantity: 44,
	},
	{
		garage_id: 41,
		part_id: 46,
		quantity: 387,
	},
	{
		garage_id: 41,
		part_id: 47,
		quantity: 284,
	},
	{
		garage_id: 41,
		part_id: 48,
		quantity: 269,
	},
	{
		garage_id: 41,
		part_id: 49,
		quantity: 216,
	},
	{
		garage_id: 41,
		part_id: 50,
		quantity: 207,
	},
	{
		garage_id: 41,
		part_id: 51,
		quantity: 265,
	},
	{
		garage_id: 41,
		part_id: 52,
		quantity: 306,
	},
	{
		garage_id: 41,
		part_id: 53,
		quantity: 473,
	},
	{
		garage_id: 41,
		part_id: 54,
		quantity: 445,
	},
	{
		garage_id: 41,
		part_id: 55,
		quantity: 461,
	},
	{
		garage_id: 41,
		part_id: 56,
		quantity: 77,
	},
	{
		garage_id: 41,
		part_id: 57,
		quantity: 323,
	},
	{
		garage_id: 41,
		part_id: 58,
		quantity: 219,
	},
	{
		garage_id: 41,
		part_id: 59,
		quantity: 176,
	},
	{
		garage_id: 41,
		part_id: 60,
		quantity: 477,
	},
	{
		garage_id: 41,
		part_id: 61,
		quantity: 261,
	},
	{
		garage_id: 41,
		part_id: 62,
		quantity: 308,
	},
	{
		garage_id: 41,
		part_id: 63,
		quantity: 459,
	},
	{
		garage_id: 41,
		part_id: 64,
		quantity: 172,
	},
	{
		garage_id: 41,
		part_id: 65,
		quantity: 46,
	},
	{
		garage_id: 41,
		part_id: 66,
		quantity: 251,
	},
	{
		garage_id: 41,
		part_id: 67,
		quantity: 379,
	},
	{
		garage_id: 41,
		part_id: 68,
		quantity: 84,
	},
	{
		garage_id: 41,
		part_id: 69,
		quantity: 404,
	},
	{
		garage_id: 41,
		part_id: 70,
		quantity: 431,
	},
	{
		garage_id: 41,
		part_id: 71,
		quantity: 389,
	},
	{
		garage_id: 41,
		part_id: 72,
		quantity: 441,
	},
	{
		garage_id: 41,
		part_id: 73,
		quantity: 165,
	},
	{
		garage_id: 41,
		part_id: 74,
		quantity: 68,
	},
	{
		garage_id: 41,
		part_id: 75,
		quantity: 349,
	},
	{
		garage_id: 41,
		part_id: 76,
		quantity: 16,
	},
	{
		garage_id: 41,
		part_id: 77,
		quantity: 94,
	},
	{
		garage_id: 41,
		part_id: 78,
		quantity: 98,
	},
	{
		garage_id: 41,
		part_id: 79,
		quantity: 197,
	},
	{
		garage_id: 41,
		part_id: 80,
		quantity: 219,
	},
	{
		garage_id: 41,
		part_id: 81,
		quantity: 52,
	},
	{
		garage_id: 41,
		part_id: 82,
		quantity: 302,
	},
	{
		garage_id: 41,
		part_id: 83,
		quantity: 51,
	},
	{
		garage_id: 41,
		part_id: 84,
		quantity: 347,
	},
	{
		garage_id: 41,
		part_id: 85,
		quantity: 90,
	},
	{
		garage_id: 41,
		part_id: 86,
		quantity: 73,
	},
	{
		garage_id: 41,
		part_id: 87,
		quantity: 433,
	},
	{
		garage_id: 41,
		part_id: 88,
		quantity: 258,
	},
	{
		garage_id: 41,
		part_id: 89,
		quantity: 416,
	},
	{
		garage_id: 41,
		part_id: 90,
		quantity: 330,
	},
	{
		garage_id: 41,
		part_id: 91,
		quantity: 223,
	},
	{
		garage_id: 41,
		part_id: 92,
		quantity: 462,
	},
	{
		garage_id: 41,
		part_id: 93,
		quantity: 209,
	},
	{
		garage_id: 41,
		part_id: 94,
		quantity: 305,
	},
	{
		garage_id: 41,
		part_id: 95,
		quantity: 431,
	},
	{
		garage_id: 41,
		part_id: 96,
		quantity: 271,
	},
	{
		garage_id: 41,
		part_id: 97,
		quantity: 274,
	},
	{
		garage_id: 41,
		part_id: 98,
		quantity: 88,
	},
	{
		garage_id: 41,
		part_id: 99,
		quantity: 407,
	},
	{
		garage_id: 41,
		part_id: 100,
		quantity: 216,
	},
	{
		garage_id: 41,
		part_id: 101,
		quantity: 132,
	},
	{
		garage_id: 41,
		part_id: 102,
		quantity: 99,
	},
	{
		garage_id: 41,
		part_id: 103,
		quantity: 376,
	},
	{
		garage_id: 41,
		part_id: 104,
		quantity: 477,
	},
	{
		garage_id: 41,
		part_id: 105,
		quantity: 431,
	},
	{
		garage_id: 41,
		part_id: 106,
		quantity: 344,
	},
	{
		garage_id: 41,
		part_id: 107,
		quantity: 24,
	},
	{
		garage_id: 41,
		part_id: 108,
		quantity: 327,
	},
	{
		garage_id: 41,
		part_id: 109,
		quantity: 86,
	},
	{
		garage_id: 41,
		part_id: 110,
		quantity: 451,
	},
	{
		garage_id: 41,
		part_id: 111,
		quantity: 107,
	},
	{
		garage_id: 41,
		part_id: 112,
		quantity: 248,
	},
	{
		garage_id: 41,
		part_id: 113,
		quantity: 475,
	},
	{
		garage_id: 41,
		part_id: 114,
		quantity: 65,
	},
	{
		garage_id: 41,
		part_id: 115,
		quantity: 47,
	},
	{
		garage_id: 41,
		part_id: 116,
		quantity: 241,
	},
	{
		garage_id: 41,
		part_id: 117,
		quantity: 447,
	},
	{
		garage_id: 41,
		part_id: 118,
		quantity: 342,
	},
	{
		garage_id: 41,
		part_id: 119,
		quantity: 375,
	},
	{
		garage_id: 41,
		part_id: 120,
		quantity: 398,
	},
	{
		garage_id: 41,
		part_id: 121,
		quantity: 44,
	},
	{
		garage_id: 41,
		part_id: 122,
		quantity: 88,
	},
	{
		garage_id: 41,
		part_id: 123,
		quantity: 231,
	},
	{
		garage_id: 41,
		part_id: 124,
		quantity: 122,
	},
	{
		garage_id: 41,
		part_id: 125,
		quantity: 102,
	},
	{
		garage_id: 41,
		part_id: 126,
		quantity: 199,
	},
	{
		garage_id: 41,
		part_id: 127,
		quantity: 132,
	},
	{
		garage_id: 41,
		part_id: 128,
		quantity: 237,
	},
	{
		garage_id: 41,
		part_id: 129,
		quantity: 433,
	},
	{
		garage_id: 41,
		part_id: 130,
		quantity: 81,
	},
	{
		garage_id: 41,
		part_id: 131,
		quantity: 76,
	},
	{
		garage_id: 41,
		part_id: 132,
		quantity: 119,
	},
	{
		garage_id: 41,
		part_id: 133,
		quantity: 169,
	},
	{
		garage_id: 41,
		part_id: 134,
		quantity: 101,
	},
	{
		garage_id: 41,
		part_id: 135,
		quantity: 439,
	},
	{
		garage_id: 41,
		part_id: 136,
		quantity: 114,
	},
	{
		garage_id: 41,
		part_id: 137,
		quantity: 382,
	},
	{
		garage_id: 41,
		part_id: 138,
		quantity: 375,
	},
	{
		garage_id: 41,
		part_id: 139,
		quantity: 309,
	},
	{
		garage_id: 41,
		part_id: 140,
		quantity: 81,
	},
	{
		garage_id: 41,
		part_id: 141,
		quantity: 330,
	},
	{
		garage_id: 41,
		part_id: 142,
		quantity: 347,
	},
	{
		garage_id: 41,
		part_id: 143,
		quantity: 277,
	},
	{
		garage_id: 41,
		part_id: 144,
		quantity: 413,
	},
	{
		garage_id: 41,
		part_id: 145,
		quantity: 495,
	},
	{
		garage_id: 41,
		part_id: 146,
		quantity: 448,
	},
	{
		garage_id: 41,
		part_id: 147,
		quantity: 425,
	},
	{
		garage_id: 41,
		part_id: 148,
		quantity: 470,
	},
	{
		garage_id: 41,
		part_id: 149,
		quantity: 66,
	},
	{
		garage_id: 41,
		part_id: 150,
		quantity: 268,
	},
	{
		garage_id: 41,
		part_id: 151,
		quantity: 339,
	},
	{
		garage_id: 41,
		part_id: 152,
		quantity: 273,
	},
	{
		garage_id: 41,
		part_id: 153,
		quantity: 104,
	},
	{
		garage_id: 41,
		part_id: 154,
		quantity: 147,
	},
	{
		garage_id: 41,
		part_id: 155,
		quantity: 292,
	},
	{
		garage_id: 41,
		part_id: 156,
		quantity: 164,
	},
	{
		garage_id: 41,
		part_id: 157,
		quantity: 362,
	},
	{
		garage_id: 41,
		part_id: 158,
		quantity: 97,
	},
	{
		garage_id: 41,
		part_id: 159,
		quantity: 279,
	},
	{
		garage_id: 41,
		part_id: 160,
		quantity: 243,
	},
	{
		garage_id: 41,
		part_id: 161,
		quantity: 206,
	},
	{
		garage_id: 41,
		part_id: 162,
		quantity: 496,
	},
	{
		garage_id: 41,
		part_id: 163,
		quantity: 28,
	},
	{
		garage_id: 41,
		part_id: 164,
		quantity: 423,
	},
	{
		garage_id: 41,
		part_id: 165,
		quantity: 121,
	},
	{
		garage_id: 41,
		part_id: 166,
		quantity: 71,
	},
	{
		garage_id: 41,
		part_id: 167,
		quantity: 441,
	},
	{
		garage_id: 41,
		part_id: 168,
		quantity: 443,
	},
	{
		garage_id: 41,
		part_id: 169,
		quantity: 318,
	},
	{
		garage_id: 41,
		part_id: 170,
		quantity: 298,
	},
	{
		garage_id: 41,
		part_id: 171,
		quantity: 55,
	},
	{
		garage_id: 41,
		part_id: 172,
		quantity: 447,
	},
	{
		garage_id: 41,
		part_id: 173,
		quantity: 131,
	},
	{
		garage_id: 41,
		part_id: 174,
		quantity: 258,
	},
	{
		garage_id: 41,
		part_id: 175,
		quantity: 305,
	},
	{
		garage_id: 41,
		part_id: 176,
		quantity: 24,
	},
	{
		garage_id: 41,
		part_id: 177,
		quantity: 75,
	},
	{
		garage_id: 41,
		part_id: 178,
		quantity: 393,
	},
	{
		garage_id: 41,
		part_id: 179,
		quantity: 353,
	},
	{
		garage_id: 41,
		part_id: 180,
		quantity: 499,
	},
	{
		garage_id: 41,
		part_id: 181,
		quantity: 142,
	},
	{
		garage_id: 41,
		part_id: 182,
		quantity: 457,
	},
	{
		garage_id: 41,
		part_id: 183,
		quantity: 77,
	},
	{
		garage_id: 41,
		part_id: 184,
		quantity: 379,
	},
	{
		garage_id: 41,
		part_id: 185,
		quantity: 346,
	},
	{
		garage_id: 41,
		part_id: 186,
		quantity: 104,
	},
	{
		garage_id: 41,
		part_id: 187,
		quantity: 496,
	},
	{
		garage_id: 41,
		part_id: 188,
		quantity: 379,
	},
	{
		garage_id: 41,
		part_id: 189,
		quantity: 317,
	},
	{
		garage_id: 41,
		part_id: 190,
		quantity: 431,
	},
	{
		garage_id: 41,
		part_id: 191,
		quantity: 163,
	},
	{
		garage_id: 41,
		part_id: 192,
		quantity: 94,
	},
	{
		garage_id: 41,
		part_id: 193,
		quantity: 256,
	},
	{
		garage_id: 41,
		part_id: 194,
		quantity: 299,
	},
	{
		garage_id: 41,
		part_id: 195,
		quantity: 194,
	},
	{
		garage_id: 41,
		part_id: 196,
		quantity: 197,
	},
	{
		garage_id: 41,
		part_id: 197,
		quantity: 293,
	},
	{
		garage_id: 41,
		part_id: 198,
		quantity: 288,
	},
	{
		garage_id: 41,
		part_id: 199,
		quantity: 314,
	},
	{
		garage_id: 41,
		part_id: 200,
		quantity: 468,
	},
	{
		garage_id: 41,
		part_id: 201,
		quantity: 162,
	},
	{
		garage_id: 41,
		part_id: 202,
		quantity: 376,
	},
	{
		garage_id: 41,
		part_id: 203,
		quantity: 189,
	},
	{
		garage_id: 41,
		part_id: 204,
		quantity: 269,
	},
	{
		garage_id: 41,
		part_id: 205,
		quantity: 485,
	},
	{
		garage_id: 41,
		part_id: 206,
		quantity: 359,
	},
	{
		garage_id: 41,
		part_id: 207,
		quantity: 54,
	},
	{
		garage_id: 41,
		part_id: 208,
		quantity: 104,
	},
	{
		garage_id: 41,
		part_id: 209,
		quantity: 75,
	},
	{
		garage_id: 41,
		part_id: 210,
		quantity: 138,
	},
	{
		garage_id: 41,
		part_id: 211,
		quantity: 416,
	},
	{
		garage_id: 41,
		part_id: 212,
		quantity: 477,
	},
	{
		garage_id: 41,
		part_id: 213,
		quantity: 116,
	},
	{
		garage_id: 41,
		part_id: 214,
		quantity: 116,
	},
	{
		garage_id: 41,
		part_id: 215,
		quantity: 395,
	},
	{
		garage_id: 41,
		part_id: 216,
		quantity: 354,
	},
	{
		garage_id: 41,
		part_id: 217,
		quantity: 199,
	},
	{
		garage_id: 41,
		part_id: 218,
		quantity: 437,
	},
	{
		garage_id: 41,
		part_id: 219,
		quantity: 172,
	},
	{
		garage_id: 41,
		part_id: 220,
		quantity: 252,
	},
	{
		garage_id: 41,
		part_id: 221,
		quantity: 38,
	},
	{
		garage_id: 41,
		part_id: 222,
		quantity: 203,
	},
	{
		garage_id: 41,
		part_id: 223,
		quantity: 358,
	},
	{
		garage_id: 41,
		part_id: 224,
		quantity: 245,
	},
	{
		garage_id: 41,
		part_id: 225,
		quantity: 108,
	},
	{
		garage_id: 41,
		part_id: 226,
		quantity: 19,
	},
	{
		garage_id: 41,
		part_id: 227,
		quantity: 326,
	},
	{
		garage_id: 41,
		part_id: 228,
		quantity: 121,
	},
	{
		garage_id: 41,
		part_id: 229,
		quantity: 87,
	},
	{
		garage_id: 41,
		part_id: 230,
		quantity: 248,
	},
	{
		garage_id: 41,
		part_id: 231,
		quantity: 117,
	},
	{
		garage_id: 41,
		part_id: 232,
		quantity: 126,
	},
	{
		garage_id: 41,
		part_id: 233,
		quantity: 43,
	},
	{
		garage_id: 41,
		part_id: 234,
		quantity: 236,
	},
	{
		garage_id: 41,
		part_id: 235,
		quantity: 481,
	},
	{
		garage_id: 41,
		part_id: 236,
		quantity: 272,
	},
	{
		garage_id: 41,
		part_id: 237,
		quantity: 362,
	},
	{
		garage_id: 41,
		part_id: 238,
		quantity: 144,
	},
	{
		garage_id: 41,
		part_id: 239,
		quantity: 379,
	},
	{
		garage_id: 41,
		part_id: 240,
		quantity: 337,
	},
	{
		garage_id: 41,
		part_id: 241,
		quantity: 41,
	},
	{
		garage_id: 41,
		part_id: 242,
		quantity: 386,
	},
	{
		garage_id: 41,
		part_id: 243,
		quantity: 442,
	},
	{
		garage_id: 41,
		part_id: 244,
		quantity: 383,
	},
	{
		garage_id: 41,
		part_id: 245,
		quantity: 277,
	},
	{
		garage_id: 41,
		part_id: 246,
		quantity: 402,
	},
	{
		garage_id: 41,
		part_id: 247,
		quantity: 92,
	},
	{
		garage_id: 41,
		part_id: 248,
		quantity: 202,
	},
	{
		garage_id: 41,
		part_id: 249,
		quantity: 9,
	},
	{
		garage_id: 41,
		part_id: 250,
		quantity: 152,
	},
	{
		garage_id: 41,
		part_id: 251,
		quantity: 332,
	},
	{
		garage_id: 41,
		part_id: 252,
		quantity: 271,
	},
	{
		garage_id: 41,
		part_id: 253,
		quantity: 175,
	},
	{
		garage_id: 41,
		part_id: 254,
		quantity: 387,
	},
	{
		garage_id: 41,
		part_id: 255,
		quantity: 291,
	},
	{
		garage_id: 41,
		part_id: 256,
		quantity: 360,
	},
	{
		garage_id: 41,
		part_id: 257,
		quantity: 53,
	},
	{
		garage_id: 41,
		part_id: 258,
		quantity: 351,
	},
	{
		garage_id: 41,
		part_id: 259,
		quantity: 410,
	},
	{
		garage_id: 41,
		part_id: 260,
		quantity: 427,
	},
	{
		garage_id: 41,
		part_id: 261,
		quantity: 113,
	},
	{
		garage_id: 41,
		part_id: 262,
		quantity: 48,
	},
	{
		garage_id: 41,
		part_id: 263,
		quantity: 127,
	},
	{
		garage_id: 41,
		part_id: 264,
		quantity: 23,
	},
	{
		garage_id: 41,
		part_id: 265,
		quantity: 108,
	},
	{
		garage_id: 41,
		part_id: 266,
		quantity: 264,
	},
	{
		garage_id: 41,
		part_id: 267,
		quantity: 458,
	},
	{
		garage_id: 41,
		part_id: 268,
		quantity: 278,
	},
	{
		garage_id: 41,
		part_id: 269,
		quantity: 440,
	},
	{
		garage_id: 41,
		part_id: 270,
		quantity: 456,
	},
	{
		garage_id: 41,
		part_id: 271,
		quantity: 500,
	},
	{
		garage_id: 41,
		part_id: 272,
		quantity: 306,
	},
	{
		garage_id: 41,
		part_id: 273,
		quantity: 307,
	},
	{
		garage_id: 41,
		part_id: 274,
		quantity: 209,
	},
	{
		garage_id: 41,
		part_id: 275,
		quantity: 168,
	},
	{
		garage_id: 41,
		part_id: 276,
		quantity: 177,
	},
	{
		garage_id: 41,
		part_id: 277,
		quantity: 307,
	},
	{
		garage_id: 41,
		part_id: 278,
		quantity: 301,
	},
	{
		garage_id: 41,
		part_id: 279,
		quantity: 231,
	},
	{
		garage_id: 41,
		part_id: 280,
		quantity: 211,
	},
	{
		garage_id: 41,
		part_id: 281,
		quantity: 385,
	},
	{
		garage_id: 41,
		part_id: 282,
		quantity: 227,
	},
	{
		garage_id: 41,
		part_id: 283,
		quantity: 475,
	},
	{
		garage_id: 41,
		part_id: 284,
		quantity: 484,
	},
	{
		garage_id: 41,
		part_id: 285,
		quantity: 454,
	},
	{
		garage_id: 41,
		part_id: 286,
		quantity: 306,
	},
	{
		garage_id: 41,
		part_id: 287,
		quantity: 63,
	},
	{
		garage_id: 41,
		part_id: 288,
		quantity: 20,
	},
	{
		garage_id: 41,
		part_id: 289,
		quantity: 196,
	},
	{
		garage_id: 41,
		part_id: 290,
		quantity: 469,
	},
	{
		garage_id: 41,
		part_id: 291,
		quantity: 490,
	},
	{
		garage_id: 41,
		part_id: 292,
		quantity: 90,
	},
	{
		garage_id: 41,
		part_id: 293,
		quantity: 411,
	},
	{
		garage_id: 41,
		part_id: 294,
		quantity: 15,
	},
	{
		garage_id: 41,
		part_id: 295,
		quantity: 125,
	},
	{
		garage_id: 41,
		part_id: 296,
		quantity: 442,
	},
	{
		garage_id: 41,
		part_id: 297,
		quantity: 212,
	},
	{
		garage_id: 41,
		part_id: 298,
		quantity: 146,
	},
	{
		garage_id: 41,
		part_id: 299,
		quantity: 204,
	},
	{
		garage_id: 41,
		part_id: 300,
		quantity: 429,
	},
	{
		garage_id: 41,
		part_id: 301,
		quantity: 490,
	},
	{
		garage_id: 41,
		part_id: 302,
		quantity: 16,
	},
	{
		garage_id: 41,
		part_id: 303,
		quantity: 18,
	},
	{
		garage_id: 41,
		part_id: 304,
		quantity: 472,
	},
	{
		garage_id: 41,
		part_id: 305,
		quantity: 460,
	},
	{
		garage_id: 41,
		part_id: 306,
		quantity: 406,
	},
	{
		garage_id: 41,
		part_id: 307,
		quantity: 429,
	},
	{
		garage_id: 41,
		part_id: 308,
		quantity: 396,
	},
	{
		garage_id: 41,
		part_id: 309,
		quantity: 294,
	},
	{
		garage_id: 41,
		part_id: 310,
		quantity: 367,
	},
	{
		garage_id: 41,
		part_id: 311,
		quantity: 279,
	},
	{
		garage_id: 41,
		part_id: 312,
		quantity: 237,
	},
	{
		garage_id: 41,
		part_id: 313,
		quantity: 244,
	},
	{
		garage_id: 41,
		part_id: 314,
		quantity: 235,
	},
	{
		garage_id: 41,
		part_id: 315,
		quantity: 455,
	},
	{
		garage_id: 41,
		part_id: 316,
		quantity: 121,
	},
	{
		garage_id: 41,
		part_id: 317,
		quantity: 351,
	},
	{
		garage_id: 41,
		part_id: 318,
		quantity: 499,
	},
	{
		garage_id: 41,
		part_id: 319,
		quantity: 182,
	},
	{
		garage_id: 41,
		part_id: 320,
		quantity: 208,
	},
	{
		garage_id: 41,
		part_id: 321,
		quantity: 396,
	},
	{
		garage_id: 41,
		part_id: 322,
		quantity: 138,
	},
	{
		garage_id: 41,
		part_id: 323,
		quantity: 485,
	},
	{
		garage_id: 41,
		part_id: 324,
		quantity: 132,
	},
	{
		garage_id: 41,
		part_id: 325,
		quantity: 286,
	},
	{
		garage_id: 41,
		part_id: 326,
		quantity: 135,
	},
	{
		garage_id: 41,
		part_id: 327,
		quantity: 329,
	},
	{
		garage_id: 41,
		part_id: 328,
		quantity: 284,
	},
	{
		garage_id: 41,
		part_id: 329,
		quantity: 229,
	},
	{
		garage_id: 41,
		part_id: 330,
		quantity: 354,
	},
	{
		garage_id: 41,
		part_id: 331,
		quantity: 323,
	},
	{
		garage_id: 41,
		part_id: 332,
		quantity: 278,
	},
	{
		garage_id: 41,
		part_id: 333,
		quantity: 314,
	},
	{
		garage_id: 41,
		part_id: 334,
		quantity: 297,
	},
	{
		garage_id: 41,
		part_id: 335,
		quantity: 312,
	},
	{
		garage_id: 41,
		part_id: 336,
		quantity: 189,
	},
	{
		garage_id: 41,
		part_id: 337,
		quantity: 256,
	},
	{
		garage_id: 41,
		part_id: 338,
		quantity: 342,
	},
	{
		garage_id: 41,
		part_id: 339,
		quantity: 266,
	},
	{
		garage_id: 41,
		part_id: 340,
		quantity: 213,
	},
	{
		garage_id: 41,
		part_id: 341,
		quantity: 28,
	},
	{
		garage_id: 41,
		part_id: 342,
		quantity: 120,
	},
	{
		garage_id: 41,
		part_id: 343,
		quantity: 293,
	},
	{
		garage_id: 41,
		part_id: 344,
		quantity: 360,
	},
	{
		garage_id: 41,
		part_id: 345,
		quantity: 443,
	},
	{
		garage_id: 41,
		part_id: 346,
		quantity: 322,
	},
	{
		garage_id: 41,
		part_id: 347,
		quantity: 227,
	},
	{
		garage_id: 41,
		part_id: 348,
		quantity: 316,
	},
	{
		garage_id: 41,
		part_id: 349,
		quantity: 23,
	},
	{
		garage_id: 41,
		part_id: 350,
		quantity: 223,
	},
	{
		garage_id: 41,
		part_id: 351,
		quantity: 366,
	},
	{
		garage_id: 41,
		part_id: 352,
		quantity: 395,
	},
	{
		garage_id: 41,
		part_id: 353,
		quantity: 137,
	},
	{
		garage_id: 41,
		part_id: 354,
		quantity: 498,
	},
	{
		garage_id: 41,
		part_id: 355,
		quantity: 457,
	},
	{
		garage_id: 41,
		part_id: 356,
		quantity: 460,
	},
	{
		garage_id: 41,
		part_id: 357,
		quantity: 347,
	},
	{
		garage_id: 41,
		part_id: 358,
		quantity: 189,
	},
	{
		garage_id: 41,
		part_id: 359,
		quantity: 334,
	},
	{
		garage_id: 41,
		part_id: 360,
		quantity: 371,
	},
	{
		garage_id: 41,
		part_id: 361,
		quantity: 133,
	},
	{
		garage_id: 41,
		part_id: 362,
		quantity: 456,
	},
	{
		garage_id: 41,
		part_id: 363,
		quantity: 207,
	},
	{
		garage_id: 41,
		part_id: 364,
		quantity: 203,
	},
	{
		garage_id: 41,
		part_id: 365,
		quantity: 455,
	},
	{
		garage_id: 41,
		part_id: 366,
		quantity: 5,
	},
	{
		garage_id: 41,
		part_id: 367,
		quantity: 408,
	},
	{
		garage_id: 41,
		part_id: 368,
		quantity: 295,
	},
	{
		garage_id: 41,
		part_id: 369,
		quantity: 221,
	},
	{
		garage_id: 41,
		part_id: 370,
		quantity: 477,
	},
	{
		garage_id: 41,
		part_id: 371,
		quantity: 130,
	},
	{
		garage_id: 41,
		part_id: 372,
		quantity: 477,
	},
	{
		garage_id: 41,
		part_id: 373,
		quantity: 270,
	},
	{
		garage_id: 41,
		part_id: 374,
		quantity: 443,
	},
	{
		garage_id: 41,
		part_id: 375,
		quantity: 129,
	},
	{
		garage_id: 41,
		part_id: 376,
		quantity: 420,
	},
	{
		garage_id: 41,
		part_id: 377,
		quantity: 244,
	},
	{
		garage_id: 41,
		part_id: 378,
		quantity: 497,
	},
	{
		garage_id: 41,
		part_id: 379,
		quantity: 142,
	},
	{
		garage_id: 41,
		part_id: 380,
		quantity: 386,
	},
	{
		garage_id: 41,
		part_id: 381,
		quantity: 161,
	},
	{
		garage_id: 41,
		part_id: 382,
		quantity: 500,
	},
	{
		garage_id: 41,
		part_id: 383,
		quantity: 61,
	},
	{
		garage_id: 41,
		part_id: 384,
		quantity: 191,
	},
	{
		garage_id: 41,
		part_id: 385,
		quantity: 209,
	},
	{
		garage_id: 41,
		part_id: 386,
		quantity: 212,
	},
	{
		garage_id: 41,
		part_id: 387,
		quantity: 372,
	},
	{
		garage_id: 41,
		part_id: 388,
		quantity: 451,
	},
	{
		garage_id: 41,
		part_id: 389,
		quantity: 394,
	},
	{
		garage_id: 41,
		part_id: 390,
		quantity: 406,
	},
	{
		garage_id: 41,
		part_id: 391,
		quantity: 453,
	},
	{
		garage_id: 41,
		part_id: 392,
		quantity: 29,
	},
	{
		garage_id: 41,
		part_id: 393,
		quantity: 366,
	},
	{
		garage_id: 41,
		part_id: 394,
		quantity: 363,
	},
	{
		garage_id: 41,
		part_id: 395,
		quantity: 29,
	},
	{
		garage_id: 41,
		part_id: 396,
		quantity: 302,
	},
	{
		garage_id: 41,
		part_id: 397,
		quantity: 252,
	},
	{
		garage_id: 41,
		part_id: 398,
		quantity: 499,
	},
	{
		garage_id: 41,
		part_id: 399,
		quantity: 358,
	},
	{
		garage_id: 42,
		part_id: 0,
		quantity: 418,
	},
	{
		garage_id: 42,
		part_id: 1,
		quantity: 361,
	},
	{
		garage_id: 42,
		part_id: 2,
		quantity: 479,
	},
	{
		garage_id: 42,
		part_id: 3,
		quantity: 255,
	},
	{
		garage_id: 42,
		part_id: 4,
		quantity: 161,
	},
	{
		garage_id: 42,
		part_id: 5,
		quantity: 322,
	},
	{
		garage_id: 42,
		part_id: 6,
		quantity: 466,
	},
	{
		garage_id: 42,
		part_id: 7,
		quantity: 99,
	},
	{
		garage_id: 42,
		part_id: 8,
		quantity: 235,
	},
	{
		garage_id: 42,
		part_id: 9,
		quantity: 329,
	},
	{
		garage_id: 42,
		part_id: 10,
		quantity: 126,
	},
	{
		garage_id: 42,
		part_id: 11,
		quantity: 273,
	},
	{
		garage_id: 42,
		part_id: 12,
		quantity: 299,
	},
	{
		garage_id: 42,
		part_id: 13,
		quantity: 99,
	},
	{
		garage_id: 42,
		part_id: 14,
		quantity: 181,
	},
	{
		garage_id: 42,
		part_id: 15,
		quantity: 349,
	},
	{
		garage_id: 42,
		part_id: 16,
		quantity: 404,
	},
	{
		garage_id: 42,
		part_id: 17,
		quantity: 29,
	},
	{
		garage_id: 42,
		part_id: 18,
		quantity: 357,
	},
	{
		garage_id: 42,
		part_id: 19,
		quantity: 354,
	},
	{
		garage_id: 42,
		part_id: 20,
		quantity: 435,
	},
	{
		garage_id: 42,
		part_id: 21,
		quantity: 164,
	},
	{
		garage_id: 42,
		part_id: 22,
		quantity: 402,
	},
	{
		garage_id: 42,
		part_id: 23,
		quantity: 233,
	},
	{
		garage_id: 42,
		part_id: 24,
		quantity: 282,
	},
	{
		garage_id: 42,
		part_id: 25,
		quantity: 192,
	},
	{
		garage_id: 42,
		part_id: 26,
		quantity: 336,
	},
	{
		garage_id: 42,
		part_id: 27,
		quantity: 134,
	},
	{
		garage_id: 42,
		part_id: 28,
		quantity: 275,
	},
	{
		garage_id: 42,
		part_id: 29,
		quantity: 63,
	},
	{
		garage_id: 42,
		part_id: 30,
		quantity: 12,
	},
	{
		garage_id: 42,
		part_id: 31,
		quantity: 246,
	},
	{
		garage_id: 42,
		part_id: 32,
		quantity: 106,
	},
	{
		garage_id: 42,
		part_id: 33,
		quantity: 399,
	},
	{
		garage_id: 42,
		part_id: 34,
		quantity: 254,
	},
	{
		garage_id: 42,
		part_id: 35,
		quantity: 341,
	},
	{
		garage_id: 42,
		part_id: 36,
		quantity: 277,
	},
	{
		garage_id: 42,
		part_id: 37,
		quantity: 209,
	},
	{
		garage_id: 42,
		part_id: 38,
		quantity: 497,
	},
	{
		garage_id: 42,
		part_id: 39,
		quantity: 119,
	},
	{
		garage_id: 42,
		part_id: 40,
		quantity: 372,
	},
	{
		garage_id: 42,
		part_id: 41,
		quantity: 282,
	},
	{
		garage_id: 42,
		part_id: 42,
		quantity: 195,
	},
	{
		garage_id: 42,
		part_id: 43,
		quantity: 449,
	},
	{
		garage_id: 42,
		part_id: 44,
		quantity: 301,
	},
	{
		garage_id: 42,
		part_id: 45,
		quantity: 35,
	},
	{
		garage_id: 42,
		part_id: 46,
		quantity: 9,
	},
	{
		garage_id: 42,
		part_id: 47,
		quantity: 18,
	},
	{
		garage_id: 42,
		part_id: 48,
		quantity: 175,
	},
	{
		garage_id: 42,
		part_id: 49,
		quantity: 447,
	},
	{
		garage_id: 42,
		part_id: 50,
		quantity: 232,
	},
	{
		garage_id: 42,
		part_id: 51,
		quantity: 310,
	},
	{
		garage_id: 42,
		part_id: 52,
		quantity: 223,
	},
	{
		garage_id: 42,
		part_id: 53,
		quantity: 489,
	},
	{
		garage_id: 42,
		part_id: 54,
		quantity: 150,
	},
	{
		garage_id: 42,
		part_id: 55,
		quantity: 279,
	},
	{
		garage_id: 42,
		part_id: 56,
		quantity: 117,
	},
	{
		garage_id: 42,
		part_id: 57,
		quantity: 436,
	},
	{
		garage_id: 42,
		part_id: 58,
		quantity: 133,
	},
	{
		garage_id: 42,
		part_id: 59,
		quantity: 380,
	},
	{
		garage_id: 42,
		part_id: 60,
		quantity: 158,
	},
	{
		garage_id: 42,
		part_id: 61,
		quantity: 36,
	},
	{
		garage_id: 42,
		part_id: 62,
		quantity: 59,
	},
	{
		garage_id: 42,
		part_id: 63,
		quantity: 384,
	},
	{
		garage_id: 42,
		part_id: 64,
		quantity: 141,
	},
	{
		garage_id: 42,
		part_id: 65,
		quantity: 452,
	},
	{
		garage_id: 42,
		part_id: 66,
		quantity: 385,
	},
	{
		garage_id: 42,
		part_id: 67,
		quantity: 408,
	},
	{
		garage_id: 42,
		part_id: 68,
		quantity: 116,
	},
	{
		garage_id: 42,
		part_id: 69,
		quantity: 120,
	},
	{
		garage_id: 42,
		part_id: 70,
		quantity: 241,
	},
	{
		garage_id: 42,
		part_id: 71,
		quantity: 223,
	},
	{
		garage_id: 42,
		part_id: 72,
		quantity: 212,
	},
	{
		garage_id: 42,
		part_id: 73,
		quantity: 217,
	},
	{
		garage_id: 42,
		part_id: 74,
		quantity: 393,
	},
	{
		garage_id: 42,
		part_id: 75,
		quantity: 289,
	},
	{
		garage_id: 42,
		part_id: 76,
		quantity: 365,
	},
	{
		garage_id: 42,
		part_id: 77,
		quantity: 361,
	},
	{
		garage_id: 42,
		part_id: 78,
		quantity: 299,
	},
	{
		garage_id: 42,
		part_id: 79,
		quantity: 44,
	},
	{
		garage_id: 42,
		part_id: 80,
		quantity: 219,
	},
	{
		garage_id: 42,
		part_id: 81,
		quantity: 404,
	},
	{
		garage_id: 42,
		part_id: 82,
		quantity: 228,
	},
	{
		garage_id: 42,
		part_id: 83,
		quantity: 68,
	},
	{
		garage_id: 42,
		part_id: 84,
		quantity: 439,
	},
	{
		garage_id: 42,
		part_id: 85,
		quantity: 71,
	},
	{
		garage_id: 42,
		part_id: 86,
		quantity: 142,
	},
	{
		garage_id: 42,
		part_id: 87,
		quantity: 97,
	},
	{
		garage_id: 42,
		part_id: 88,
		quantity: 314,
	},
	{
		garage_id: 42,
		part_id: 89,
		quantity: 246,
	},
	{
		garage_id: 42,
		part_id: 90,
		quantity: 209,
	},
	{
		garage_id: 42,
		part_id: 91,
		quantity: 82,
	},
	{
		garage_id: 42,
		part_id: 92,
		quantity: 414,
	},
	{
		garage_id: 42,
		part_id: 93,
		quantity: 349,
	},
	{
		garage_id: 42,
		part_id: 94,
		quantity: 121,
	},
	{
		garage_id: 42,
		part_id: 95,
		quantity: 306,
	},
	{
		garage_id: 42,
		part_id: 96,
		quantity: 243,
	},
	{
		garage_id: 42,
		part_id: 97,
		quantity: 285,
	},
	{
		garage_id: 42,
		part_id: 98,
		quantity: 125,
	},
	{
		garage_id: 42,
		part_id: 99,
		quantity: 469,
	},
	{
		garage_id: 42,
		part_id: 100,
		quantity: 41,
	},
	{
		garage_id: 42,
		part_id: 101,
		quantity: 460,
	},
	{
		garage_id: 42,
		part_id: 102,
		quantity: 332,
	},
	{
		garage_id: 42,
		part_id: 103,
		quantity: 37,
	},
	{
		garage_id: 42,
		part_id: 104,
		quantity: 424,
	},
	{
		garage_id: 42,
		part_id: 105,
		quantity: 58,
	},
	{
		garage_id: 42,
		part_id: 106,
		quantity: 165,
	},
	{
		garage_id: 42,
		part_id: 107,
		quantity: 485,
	},
	{
		garage_id: 42,
		part_id: 108,
		quantity: 9,
	},
	{
		garage_id: 42,
		part_id: 109,
		quantity: 68,
	},
	{
		garage_id: 42,
		part_id: 110,
		quantity: 124,
	},
	{
		garage_id: 42,
		part_id: 111,
		quantity: 266,
	},
	{
		garage_id: 42,
		part_id: 112,
		quantity: 487,
	},
	{
		garage_id: 42,
		part_id: 113,
		quantity: 41,
	},
	{
		garage_id: 42,
		part_id: 114,
		quantity: 263,
	},
	{
		garage_id: 42,
		part_id: 115,
		quantity: 158,
	},
	{
		garage_id: 42,
		part_id: 116,
		quantity: 148,
	},
	{
		garage_id: 42,
		part_id: 117,
		quantity: 358,
	},
	{
		garage_id: 42,
		part_id: 118,
		quantity: 489,
	},
	{
		garage_id: 42,
		part_id: 119,
		quantity: 457,
	},
	{
		garage_id: 42,
		part_id: 120,
		quantity: 194,
	},
	{
		garage_id: 42,
		part_id: 121,
		quantity: 230,
	},
	{
		garage_id: 42,
		part_id: 122,
		quantity: 441,
	},
	{
		garage_id: 42,
		part_id: 123,
		quantity: 187,
	},
	{
		garage_id: 42,
		part_id: 124,
		quantity: 122,
	},
	{
		garage_id: 42,
		part_id: 125,
		quantity: 104,
	},
	{
		garage_id: 42,
		part_id: 126,
		quantity: 128,
	},
	{
		garage_id: 42,
		part_id: 127,
		quantity: 406,
	},
	{
		garage_id: 42,
		part_id: 128,
		quantity: 17,
	},
	{
		garage_id: 42,
		part_id: 129,
		quantity: 150,
	},
	{
		garage_id: 42,
		part_id: 130,
		quantity: 141,
	},
	{
		garage_id: 42,
		part_id: 131,
		quantity: 28,
	},
	{
		garage_id: 42,
		part_id: 132,
		quantity: 400,
	},
	{
		garage_id: 42,
		part_id: 133,
		quantity: 48,
	},
	{
		garage_id: 42,
		part_id: 134,
		quantity: 438,
	},
	{
		garage_id: 42,
		part_id: 135,
		quantity: 62,
	},
	{
		garage_id: 42,
		part_id: 136,
		quantity: 86,
	},
	{
		garage_id: 42,
		part_id: 137,
		quantity: 344,
	},
	{
		garage_id: 42,
		part_id: 138,
		quantity: 287,
	},
	{
		garage_id: 42,
		part_id: 139,
		quantity: 167,
	},
	{
		garage_id: 42,
		part_id: 140,
		quantity: 23,
	},
	{
		garage_id: 42,
		part_id: 141,
		quantity: 250,
	},
	{
		garage_id: 42,
		part_id: 142,
		quantity: 390,
	},
	{
		garage_id: 42,
		part_id: 143,
		quantity: 288,
	},
	{
		garage_id: 42,
		part_id: 144,
		quantity: 477,
	},
	{
		garage_id: 42,
		part_id: 145,
		quantity: 323,
	},
	{
		garage_id: 42,
		part_id: 146,
		quantity: 207,
	},
	{
		garage_id: 42,
		part_id: 147,
		quantity: 302,
	},
	{
		garage_id: 42,
		part_id: 148,
		quantity: 46,
	},
	{
		garage_id: 42,
		part_id: 149,
		quantity: 137,
	},
	{
		garage_id: 42,
		part_id: 150,
		quantity: 228,
	},
	{
		garage_id: 42,
		part_id: 151,
		quantity: 212,
	},
	{
		garage_id: 42,
		part_id: 152,
		quantity: 12,
	},
	{
		garage_id: 42,
		part_id: 153,
		quantity: 436,
	},
	{
		garage_id: 42,
		part_id: 154,
		quantity: 330,
	},
	{
		garage_id: 42,
		part_id: 155,
		quantity: 146,
	},
	{
		garage_id: 42,
		part_id: 156,
		quantity: 412,
	},
	{
		garage_id: 42,
		part_id: 157,
		quantity: 464,
	},
	{
		garage_id: 42,
		part_id: 158,
		quantity: 338,
	},
	{
		garage_id: 42,
		part_id: 159,
		quantity: 289,
	},
	{
		garage_id: 42,
		part_id: 160,
		quantity: 93,
	},
	{
		garage_id: 42,
		part_id: 161,
		quantity: 339,
	},
	{
		garage_id: 42,
		part_id: 162,
		quantity: 278,
	},
	{
		garage_id: 42,
		part_id: 163,
		quantity: 350,
	},
	{
		garage_id: 42,
		part_id: 164,
		quantity: 148,
	},
	{
		garage_id: 42,
		part_id: 165,
		quantity: 192,
	},
	{
		garage_id: 42,
		part_id: 166,
		quantity: 34,
	},
	{
		garage_id: 42,
		part_id: 167,
		quantity: 248,
	},
	{
		garage_id: 42,
		part_id: 168,
		quantity: 274,
	},
	{
		garage_id: 42,
		part_id: 169,
		quantity: 363,
	},
	{
		garage_id: 42,
		part_id: 170,
		quantity: 284,
	},
	{
		garage_id: 42,
		part_id: 171,
		quantity: 394,
	},
	{
		garage_id: 42,
		part_id: 172,
		quantity: 14,
	},
	{
		garage_id: 42,
		part_id: 173,
		quantity: 159,
	},
	{
		garage_id: 42,
		part_id: 174,
		quantity: 54,
	},
	{
		garage_id: 42,
		part_id: 175,
		quantity: 121,
	},
	{
		garage_id: 42,
		part_id: 176,
		quantity: 466,
	},
	{
		garage_id: 42,
		part_id: 177,
		quantity: 414,
	},
	{
		garage_id: 42,
		part_id: 178,
		quantity: 104,
	},
	{
		garage_id: 42,
		part_id: 179,
		quantity: 242,
	},
	{
		garage_id: 42,
		part_id: 180,
		quantity: 450,
	},
	{
		garage_id: 42,
		part_id: 181,
		quantity: 437,
	},
	{
		garage_id: 42,
		part_id: 182,
		quantity: 366,
	},
	{
		garage_id: 42,
		part_id: 183,
		quantity: 457,
	},
	{
		garage_id: 42,
		part_id: 184,
		quantity: 467,
	},
	{
		garage_id: 42,
		part_id: 185,
		quantity: 282,
	},
	{
		garage_id: 42,
		part_id: 186,
		quantity: 25,
	},
	{
		garage_id: 42,
		part_id: 187,
		quantity: 57,
	},
	{
		garage_id: 42,
		part_id: 188,
		quantity: 133,
	},
	{
		garage_id: 42,
		part_id: 189,
		quantity: 97,
	},
	{
		garage_id: 42,
		part_id: 190,
		quantity: 366,
	},
	{
		garage_id: 42,
		part_id: 191,
		quantity: 185,
	},
	{
		garage_id: 42,
		part_id: 192,
		quantity: 169,
	},
	{
		garage_id: 42,
		part_id: 193,
		quantity: 171,
	},
	{
		garage_id: 42,
		part_id: 194,
		quantity: 29,
	},
	{
		garage_id: 42,
		part_id: 195,
		quantity: 301,
	},
	{
		garage_id: 42,
		part_id: 196,
		quantity: 339,
	},
	{
		garage_id: 42,
		part_id: 197,
		quantity: 170,
	},
	{
		garage_id: 42,
		part_id: 198,
		quantity: 137,
	},
	{
		garage_id: 42,
		part_id: 199,
		quantity: 155,
	},
	{
		garage_id: 42,
		part_id: 200,
		quantity: 173,
	},
	{
		garage_id: 42,
		part_id: 201,
		quantity: 154,
	},
	{
		garage_id: 42,
		part_id: 202,
		quantity: 291,
	},
	{
		garage_id: 42,
		part_id: 203,
		quantity: 300,
	},
	{
		garage_id: 42,
		part_id: 204,
		quantity: 171,
	},
	{
		garage_id: 42,
		part_id: 205,
		quantity: 75,
	},
	{
		garage_id: 42,
		part_id: 206,
		quantity: 335,
	},
	{
		garage_id: 42,
		part_id: 207,
		quantity: 164,
	},
	{
		garage_id: 42,
		part_id: 208,
		quantity: 296,
	},
	{
		garage_id: 42,
		part_id: 209,
		quantity: 272,
	},
	{
		garage_id: 42,
		part_id: 210,
		quantity: 198,
	},
	{
		garage_id: 42,
		part_id: 211,
		quantity: 148,
	},
	{
		garage_id: 42,
		part_id: 212,
		quantity: 368,
	},
	{
		garage_id: 42,
		part_id: 213,
		quantity: 299,
	},
	{
		garage_id: 42,
		part_id: 214,
		quantity: 358,
	},
	{
		garage_id: 42,
		part_id: 215,
		quantity: 348,
	},
	{
		garage_id: 42,
		part_id: 216,
		quantity: 67,
	},
	{
		garage_id: 42,
		part_id: 217,
		quantity: 142,
	},
	{
		garage_id: 42,
		part_id: 218,
		quantity: 135,
	},
	{
		garage_id: 42,
		part_id: 219,
		quantity: 306,
	},
	{
		garage_id: 42,
		part_id: 220,
		quantity: 284,
	},
	{
		garage_id: 42,
		part_id: 221,
		quantity: 103,
	},
	{
		garage_id: 42,
		part_id: 222,
		quantity: 350,
	},
	{
		garage_id: 42,
		part_id: 223,
		quantity: 311,
	},
	{
		garage_id: 42,
		part_id: 224,
		quantity: 67,
	},
	{
		garage_id: 42,
		part_id: 225,
		quantity: 356,
	},
	{
		garage_id: 42,
		part_id: 226,
		quantity: 496,
	},
	{
		garage_id: 42,
		part_id: 227,
		quantity: 66,
	},
	{
		garage_id: 42,
		part_id: 228,
		quantity: 392,
	},
	{
		garage_id: 42,
		part_id: 229,
		quantity: 429,
	},
	{
		garage_id: 42,
		part_id: 230,
		quantity: 298,
	},
	{
		garage_id: 42,
		part_id: 231,
		quantity: 111,
	},
	{
		garage_id: 42,
		part_id: 232,
		quantity: 465,
	},
	{
		garage_id: 42,
		part_id: 233,
		quantity: 465,
	},
	{
		garage_id: 42,
		part_id: 234,
		quantity: 484,
	},
	{
		garage_id: 42,
		part_id: 235,
		quantity: 240,
	},
	{
		garage_id: 42,
		part_id: 236,
		quantity: 134,
	},
	{
		garage_id: 42,
		part_id: 237,
		quantity: 463,
	},
	{
		garage_id: 42,
		part_id: 238,
		quantity: 204,
	},
	{
		garage_id: 42,
		part_id: 239,
		quantity: 439,
	},
	{
		garage_id: 42,
		part_id: 240,
		quantity: 210,
	},
	{
		garage_id: 42,
		part_id: 241,
		quantity: 133,
	},
	{
		garage_id: 42,
		part_id: 242,
		quantity: 281,
	},
	{
		garage_id: 42,
		part_id: 243,
		quantity: 370,
	},
	{
		garage_id: 42,
		part_id: 244,
		quantity: 495,
	},
	{
		garage_id: 42,
		part_id: 245,
		quantity: 352,
	},
	{
		garage_id: 42,
		part_id: 246,
		quantity: 227,
	},
	{
		garage_id: 42,
		part_id: 247,
		quantity: 161,
	},
	{
		garage_id: 42,
		part_id: 248,
		quantity: 414,
	},
	{
		garage_id: 42,
		part_id: 249,
		quantity: 355,
	},
	{
		garage_id: 42,
		part_id: 250,
		quantity: 387,
	},
	{
		garage_id: 42,
		part_id: 251,
		quantity: 19,
	},
	{
		garage_id: 42,
		part_id: 252,
		quantity: 101,
	},
	{
		garage_id: 42,
		part_id: 253,
		quantity: 364,
	},
	{
		garage_id: 42,
		part_id: 254,
		quantity: 94,
	},
	{
		garage_id: 42,
		part_id: 255,
		quantity: 187,
	},
	{
		garage_id: 42,
		part_id: 256,
		quantity: 291,
	},
	{
		garage_id: 42,
		part_id: 257,
		quantity: 158,
	},
	{
		garage_id: 42,
		part_id: 258,
		quantity: 57,
	},
	{
		garage_id: 42,
		part_id: 259,
		quantity: 421,
	},
	{
		garage_id: 42,
		part_id: 260,
		quantity: 133,
	},
	{
		garage_id: 42,
		part_id: 261,
		quantity: 21,
	},
	{
		garage_id: 42,
		part_id: 262,
		quantity: 410,
	},
	{
		garage_id: 42,
		part_id: 263,
		quantity: 177,
	},
	{
		garage_id: 42,
		part_id: 264,
		quantity: 240,
	},
	{
		garage_id: 42,
		part_id: 265,
		quantity: 178,
	},
	{
		garage_id: 42,
		part_id: 266,
		quantity: 314,
	},
	{
		garage_id: 42,
		part_id: 267,
		quantity: 396,
	},
	{
		garage_id: 42,
		part_id: 268,
		quantity: 481,
	},
	{
		garage_id: 42,
		part_id: 269,
		quantity: 298,
	},
	{
		garage_id: 42,
		part_id: 270,
		quantity: 178,
	},
	{
		garage_id: 42,
		part_id: 271,
		quantity: 363,
	},
	{
		garage_id: 42,
		part_id: 272,
		quantity: 426,
	},
	{
		garage_id: 42,
		part_id: 273,
		quantity: 160,
	},
	{
		garage_id: 42,
		part_id: 274,
		quantity: 304,
	},
	{
		garage_id: 42,
		part_id: 275,
		quantity: 200,
	},
	{
		garage_id: 42,
		part_id: 276,
		quantity: 452,
	},
	{
		garage_id: 42,
		part_id: 277,
		quantity: 177,
	},
	{
		garage_id: 42,
		part_id: 278,
		quantity: 54,
	},
	{
		garage_id: 42,
		part_id: 279,
		quantity: 97,
	},
	{
		garage_id: 42,
		part_id: 280,
		quantity: 63,
	},
	{
		garage_id: 42,
		part_id: 281,
		quantity: 88,
	},
	{
		garage_id: 42,
		part_id: 282,
		quantity: 419,
	},
	{
		garage_id: 42,
		part_id: 283,
		quantity: 271,
	},
	{
		garage_id: 42,
		part_id: 284,
		quantity: 17,
	},
	{
		garage_id: 42,
		part_id: 285,
		quantity: 467,
	},
	{
		garage_id: 42,
		part_id: 286,
		quantity: 48,
	},
	{
		garage_id: 42,
		part_id: 287,
		quantity: 476,
	},
	{
		garage_id: 42,
		part_id: 288,
		quantity: 367,
	},
	{
		garage_id: 42,
		part_id: 289,
		quantity: 427,
	},
	{
		garage_id: 42,
		part_id: 290,
		quantity: 179,
	},
	{
		garage_id: 42,
		part_id: 291,
		quantity: 370,
	},
	{
		garage_id: 42,
		part_id: 292,
		quantity: 57,
	},
	{
		garage_id: 42,
		part_id: 293,
		quantity: 49,
	},
	{
		garage_id: 42,
		part_id: 294,
		quantity: 161,
	},
	{
		garage_id: 42,
		part_id: 295,
		quantity: 107,
	},
	{
		garage_id: 42,
		part_id: 296,
		quantity: 271,
	},
	{
		garage_id: 42,
		part_id: 297,
		quantity: 186,
	},
	{
		garage_id: 42,
		part_id: 298,
		quantity: 392,
	},
	{
		garage_id: 42,
		part_id: 299,
		quantity: 96,
	},
	{
		garage_id: 42,
		part_id: 300,
		quantity: 201,
	},
	{
		garage_id: 42,
		part_id: 301,
		quantity: 21,
	},
	{
		garage_id: 42,
		part_id: 302,
		quantity: 101,
	},
	{
		garage_id: 42,
		part_id: 303,
		quantity: 277,
	},
	{
		garage_id: 42,
		part_id: 304,
		quantity: 151,
	},
	{
		garage_id: 42,
		part_id: 305,
		quantity: 212,
	},
	{
		garage_id: 42,
		part_id: 306,
		quantity: 405,
	},
	{
		garage_id: 42,
		part_id: 307,
		quantity: 474,
	},
	{
		garage_id: 42,
		part_id: 308,
		quantity: 242,
	},
	{
		garage_id: 42,
		part_id: 309,
		quantity: 188,
	},
	{
		garage_id: 42,
		part_id: 310,
		quantity: 334,
	},
	{
		garage_id: 42,
		part_id: 311,
		quantity: 436,
	},
	{
		garage_id: 42,
		part_id: 312,
		quantity: 230,
	},
	{
		garage_id: 42,
		part_id: 313,
		quantity: 141,
	},
	{
		garage_id: 42,
		part_id: 314,
		quantity: 430,
	},
	{
		garage_id: 42,
		part_id: 315,
		quantity: 325,
	},
	{
		garage_id: 42,
		part_id: 316,
		quantity: 62,
	},
	{
		garage_id: 42,
		part_id: 317,
		quantity: 296,
	},
	{
		garage_id: 42,
		part_id: 318,
		quantity: 417,
	},
	{
		garage_id: 42,
		part_id: 319,
		quantity: 90,
	},
	{
		garage_id: 42,
		part_id: 320,
		quantity: 134,
	},
	{
		garage_id: 42,
		part_id: 321,
		quantity: 195,
	},
	{
		garage_id: 42,
		part_id: 322,
		quantity: 465,
	},
	{
		garage_id: 42,
		part_id: 323,
		quantity: 181,
	},
	{
		garage_id: 42,
		part_id: 324,
		quantity: 313,
	},
	{
		garage_id: 42,
		part_id: 325,
		quantity: 134,
	},
	{
		garage_id: 42,
		part_id: 326,
		quantity: 32,
	},
	{
		garage_id: 42,
		part_id: 327,
		quantity: 166,
	},
	{
		garage_id: 42,
		part_id: 328,
		quantity: 39,
	},
	{
		garage_id: 42,
		part_id: 329,
		quantity: 258,
	},
	{
		garage_id: 42,
		part_id: 330,
		quantity: 39,
	},
	{
		garage_id: 42,
		part_id: 331,
		quantity: 466,
	},
	{
		garage_id: 42,
		part_id: 332,
		quantity: 74,
	},
	{
		garage_id: 42,
		part_id: 333,
		quantity: 73,
	},
	{
		garage_id: 42,
		part_id: 334,
		quantity: 360,
	},
	{
		garage_id: 42,
		part_id: 335,
		quantity: 377,
	},
	{
		garage_id: 42,
		part_id: 336,
		quantity: 87,
	},
	{
		garage_id: 42,
		part_id: 337,
		quantity: 71,
	},
	{
		garage_id: 42,
		part_id: 338,
		quantity: 360,
	},
	{
		garage_id: 42,
		part_id: 339,
		quantity: 292,
	},
	{
		garage_id: 42,
		part_id: 340,
		quantity: 31,
	},
	{
		garage_id: 42,
		part_id: 341,
		quantity: 309,
	},
	{
		garage_id: 42,
		part_id: 342,
		quantity: 175,
	},
	{
		garage_id: 42,
		part_id: 343,
		quantity: 253,
	},
	{
		garage_id: 42,
		part_id: 344,
		quantity: 189,
	},
	{
		garage_id: 42,
		part_id: 345,
		quantity: 30,
	},
	{
		garage_id: 42,
		part_id: 346,
		quantity: 86,
	},
	{
		garage_id: 42,
		part_id: 347,
		quantity: 82,
	},
	{
		garage_id: 42,
		part_id: 348,
		quantity: 493,
	},
	{
		garage_id: 42,
		part_id: 349,
		quantity: 230,
	},
	{
		garage_id: 42,
		part_id: 350,
		quantity: 384,
	},
	{
		garage_id: 42,
		part_id: 351,
		quantity: 412,
	},
	{
		garage_id: 42,
		part_id: 352,
		quantity: 37,
	},
	{
		garage_id: 42,
		part_id: 353,
		quantity: 309,
	},
	{
		garage_id: 42,
		part_id: 354,
		quantity: 397,
	},
	{
		garage_id: 42,
		part_id: 355,
		quantity: 251,
	},
	{
		garage_id: 42,
		part_id: 356,
		quantity: 393,
	},
	{
		garage_id: 42,
		part_id: 357,
		quantity: 168,
	},
	{
		garage_id: 42,
		part_id: 358,
		quantity: 400,
	},
	{
		garage_id: 42,
		part_id: 359,
		quantity: 212,
	},
	{
		garage_id: 42,
		part_id: 360,
		quantity: 329,
	},
	{
		garage_id: 42,
		part_id: 361,
		quantity: 425,
	},
	{
		garage_id: 42,
		part_id: 362,
		quantity: 6,
	},
	{
		garage_id: 42,
		part_id: 363,
		quantity: 318,
	},
	{
		garage_id: 42,
		part_id: 364,
		quantity: 107,
	},
	{
		garage_id: 42,
		part_id: 365,
		quantity: 99,
	},
	{
		garage_id: 42,
		part_id: 366,
		quantity: 231,
	},
	{
		garage_id: 42,
		part_id: 367,
		quantity: 229,
	},
	{
		garage_id: 42,
		part_id: 368,
		quantity: 126,
	},
	{
		garage_id: 42,
		part_id: 369,
		quantity: 479,
	},
	{
		garage_id: 42,
		part_id: 370,
		quantity: 89,
	},
	{
		garage_id: 42,
		part_id: 371,
		quantity: 257,
	},
	{
		garage_id: 42,
		part_id: 372,
		quantity: 209,
	},
	{
		garage_id: 42,
		part_id: 373,
		quantity: 156,
	},
	{
		garage_id: 42,
		part_id: 374,
		quantity: 225,
	},
	{
		garage_id: 42,
		part_id: 375,
		quantity: 267,
	},
	{
		garage_id: 42,
		part_id: 376,
		quantity: 323,
	},
	{
		garage_id: 42,
		part_id: 377,
		quantity: 490,
	},
	{
		garage_id: 42,
		part_id: 378,
		quantity: 192,
	},
	{
		garage_id: 42,
		part_id: 379,
		quantity: 88,
	},
	{
		garage_id: 42,
		part_id: 380,
		quantity: 393,
	},
	{
		garage_id: 42,
		part_id: 381,
		quantity: 289,
	},
	{
		garage_id: 42,
		part_id: 382,
		quantity: 377,
	},
	{
		garage_id: 42,
		part_id: 383,
		quantity: 331,
	},
	{
		garage_id: 42,
		part_id: 384,
		quantity: 227,
	},
	{
		garage_id: 42,
		part_id: 385,
		quantity: 385,
	},
	{
		garage_id: 42,
		part_id: 386,
		quantity: 36,
	},
	{
		garage_id: 42,
		part_id: 387,
		quantity: 469,
	},
	{
		garage_id: 42,
		part_id: 388,
		quantity: 91,
	},
	{
		garage_id: 42,
		part_id: 389,
		quantity: 258,
	},
	{
		garage_id: 42,
		part_id: 390,
		quantity: 132,
	},
	{
		garage_id: 42,
		part_id: 391,
		quantity: 10,
	},
	{
		garage_id: 42,
		part_id: 392,
		quantity: 167,
	},
	{
		garage_id: 42,
		part_id: 393,
		quantity: 387,
	},
	{
		garage_id: 42,
		part_id: 394,
		quantity: 191,
	},
	{
		garage_id: 42,
		part_id: 395,
		quantity: 175,
	},
	{
		garage_id: 42,
		part_id: 396,
		quantity: 135,
	},
	{
		garage_id: 42,
		part_id: 397,
		quantity: 473,
	},
	{
		garage_id: 42,
		part_id: 398,
		quantity: 78,
	},
	{
		garage_id: 42,
		part_id: 399,
		quantity: 121,
	},
	{
		garage_id: 43,
		part_id: 0,
		quantity: 66,
	},
	{
		garage_id: 43,
		part_id: 1,
		quantity: 372,
	},
	{
		garage_id: 43,
		part_id: 2,
		quantity: 379,
	},
	{
		garage_id: 43,
		part_id: 3,
		quantity: 240,
	},
	{
		garage_id: 43,
		part_id: 4,
		quantity: 80,
	},
	{
		garage_id: 43,
		part_id: 5,
		quantity: 471,
	},
	{
		garage_id: 43,
		part_id: 6,
		quantity: 308,
	},
	{
		garage_id: 43,
		part_id: 7,
		quantity: 241,
	},
	{
		garage_id: 43,
		part_id: 8,
		quantity: 5,
	},
	{
		garage_id: 43,
		part_id: 9,
		quantity: 94,
	},
	{
		garage_id: 43,
		part_id: 10,
		quantity: 241,
	},
	{
		garage_id: 43,
		part_id: 11,
		quantity: 272,
	},
	{
		garage_id: 43,
		part_id: 12,
		quantity: 465,
	},
	{
		garage_id: 43,
		part_id: 13,
		quantity: 204,
	},
	{
		garage_id: 43,
		part_id: 14,
		quantity: 138,
	},
	{
		garage_id: 43,
		part_id: 15,
		quantity: 296,
	},
	{
		garage_id: 43,
		part_id: 16,
		quantity: 57,
	},
	{
		garage_id: 43,
		part_id: 17,
		quantity: 117,
	},
	{
		garage_id: 43,
		part_id: 18,
		quantity: 337,
	},
	{
		garage_id: 43,
		part_id: 19,
		quantity: 493,
	},
	{
		garage_id: 43,
		part_id: 20,
		quantity: 117,
	},
	{
		garage_id: 43,
		part_id: 21,
		quantity: 473,
	},
	{
		garage_id: 43,
		part_id: 22,
		quantity: 59,
	},
	{
		garage_id: 43,
		part_id: 23,
		quantity: 397,
	},
	{
		garage_id: 43,
		part_id: 24,
		quantity: 370,
	},
	{
		garage_id: 43,
		part_id: 25,
		quantity: 202,
	},
	{
		garage_id: 43,
		part_id: 26,
		quantity: 407,
	},
	{
		garage_id: 43,
		part_id: 27,
		quantity: 500,
	},
	{
		garage_id: 43,
		part_id: 28,
		quantity: 36,
	},
	{
		garage_id: 43,
		part_id: 29,
		quantity: 383,
	},
	{
		garage_id: 43,
		part_id: 30,
		quantity: 466,
	},
	{
		garage_id: 43,
		part_id: 31,
		quantity: 147,
	},
	{
		garage_id: 43,
		part_id: 32,
		quantity: 183,
	},
	{
		garage_id: 43,
		part_id: 33,
		quantity: 481,
	},
	{
		garage_id: 43,
		part_id: 34,
		quantity: 102,
	},
	{
		garage_id: 43,
		part_id: 35,
		quantity: 368,
	},
	{
		garage_id: 43,
		part_id: 36,
		quantity: 378,
	},
	{
		garage_id: 43,
		part_id: 37,
		quantity: 446,
	},
	{
		garage_id: 43,
		part_id: 38,
		quantity: 275,
	},
	{
		garage_id: 43,
		part_id: 39,
		quantity: 464,
	},
	{
		garage_id: 43,
		part_id: 40,
		quantity: 332,
	},
	{
		garage_id: 43,
		part_id: 41,
		quantity: 224,
	},
	{
		garage_id: 43,
		part_id: 42,
		quantity: 133,
	},
	{
		garage_id: 43,
		part_id: 43,
		quantity: 249,
	},
	{
		garage_id: 43,
		part_id: 44,
		quantity: 475,
	},
	{
		garage_id: 43,
		part_id: 45,
		quantity: 484,
	},
	{
		garage_id: 43,
		part_id: 46,
		quantity: 56,
	},
	{
		garage_id: 43,
		part_id: 47,
		quantity: 163,
	},
	{
		garage_id: 43,
		part_id: 48,
		quantity: 169,
	},
	{
		garage_id: 43,
		part_id: 49,
		quantity: 256,
	},
	{
		garage_id: 43,
		part_id: 50,
		quantity: 314,
	},
	{
		garage_id: 43,
		part_id: 51,
		quantity: 455,
	},
	{
		garage_id: 43,
		part_id: 52,
		quantity: 297,
	},
	{
		garage_id: 43,
		part_id: 53,
		quantity: 384,
	},
	{
		garage_id: 43,
		part_id: 54,
		quantity: 94,
	},
	{
		garage_id: 43,
		part_id: 55,
		quantity: 429,
	},
	{
		garage_id: 43,
		part_id: 56,
		quantity: 462,
	},
	{
		garage_id: 43,
		part_id: 57,
		quantity: 180,
	},
	{
		garage_id: 43,
		part_id: 58,
		quantity: 140,
	},
	{
		garage_id: 43,
		part_id: 59,
		quantity: 451,
	},
	{
		garage_id: 43,
		part_id: 60,
		quantity: 26,
	},
	{
		garage_id: 43,
		part_id: 61,
		quantity: 95,
	},
	{
		garage_id: 43,
		part_id: 62,
		quantity: 221,
	},
	{
		garage_id: 43,
		part_id: 63,
		quantity: 144,
	},
	{
		garage_id: 43,
		part_id: 64,
		quantity: 378,
	},
	{
		garage_id: 43,
		part_id: 65,
		quantity: 73,
	},
	{
		garage_id: 43,
		part_id: 66,
		quantity: 235,
	},
	{
		garage_id: 43,
		part_id: 67,
		quantity: 418,
	},
	{
		garage_id: 43,
		part_id: 68,
		quantity: 348,
	},
	{
		garage_id: 43,
		part_id: 69,
		quantity: 46,
	},
	{
		garage_id: 43,
		part_id: 70,
		quantity: 427,
	},
	{
		garage_id: 43,
		part_id: 71,
		quantity: 258,
	},
	{
		garage_id: 43,
		part_id: 72,
		quantity: 216,
	},
	{
		garage_id: 43,
		part_id: 73,
		quantity: 135,
	},
	{
		garage_id: 43,
		part_id: 74,
		quantity: 455,
	},
	{
		garage_id: 43,
		part_id: 75,
		quantity: 56,
	},
	{
		garage_id: 43,
		part_id: 76,
		quantity: 144,
	},
	{
		garage_id: 43,
		part_id: 77,
		quantity: 461,
	},
	{
		garage_id: 43,
		part_id: 78,
		quantity: 500,
	},
	{
		garage_id: 43,
		part_id: 79,
		quantity: 187,
	},
	{
		garage_id: 43,
		part_id: 80,
		quantity: 127,
	},
	{
		garage_id: 43,
		part_id: 81,
		quantity: 386,
	},
	{
		garage_id: 43,
		part_id: 82,
		quantity: 249,
	},
	{
		garage_id: 43,
		part_id: 83,
		quantity: 460,
	},
	{
		garage_id: 43,
		part_id: 84,
		quantity: 99,
	},
	{
		garage_id: 43,
		part_id: 85,
		quantity: 115,
	},
	{
		garage_id: 43,
		part_id: 86,
		quantity: 223,
	},
	{
		garage_id: 43,
		part_id: 87,
		quantity: 270,
	},
	{
		garage_id: 43,
		part_id: 88,
		quantity: 192,
	},
	{
		garage_id: 43,
		part_id: 89,
		quantity: 25,
	},
	{
		garage_id: 43,
		part_id: 90,
		quantity: 80,
	},
	{
		garage_id: 43,
		part_id: 91,
		quantity: 17,
	},
	{
		garage_id: 43,
		part_id: 92,
		quantity: 352,
	},
	{
		garage_id: 43,
		part_id: 93,
		quantity: 499,
	},
	{
		garage_id: 43,
		part_id: 94,
		quantity: 129,
	},
	{
		garage_id: 43,
		part_id: 95,
		quantity: 0,
	},
	{
		garage_id: 43,
		part_id: 96,
		quantity: 379,
	},
	{
		garage_id: 43,
		part_id: 97,
		quantity: 414,
	},
	{
		garage_id: 43,
		part_id: 98,
		quantity: 64,
	},
	{
		garage_id: 43,
		part_id: 99,
		quantity: 179,
	},
	{
		garage_id: 43,
		part_id: 100,
		quantity: 35,
	},
	{
		garage_id: 43,
		part_id: 101,
		quantity: 121,
	},
	{
		garage_id: 43,
		part_id: 102,
		quantity: 138,
	},
	{
		garage_id: 43,
		part_id: 103,
		quantity: 495,
	},
	{
		garage_id: 43,
		part_id: 104,
		quantity: 176,
	},
	{
		garage_id: 43,
		part_id: 105,
		quantity: 238,
	},
	{
		garage_id: 43,
		part_id: 106,
		quantity: 356,
	},
	{
		garage_id: 43,
		part_id: 107,
		quantity: 297,
	},
	{
		garage_id: 43,
		part_id: 108,
		quantity: 414,
	},
	{
		garage_id: 43,
		part_id: 109,
		quantity: 192,
	},
	{
		garage_id: 43,
		part_id: 110,
		quantity: 459,
	},
	{
		garage_id: 43,
		part_id: 111,
		quantity: 142,
	},
	{
		garage_id: 43,
		part_id: 112,
		quantity: 278,
	},
	{
		garage_id: 43,
		part_id: 113,
		quantity: 262,
	},
	{
		garage_id: 43,
		part_id: 114,
		quantity: 260,
	},
	{
		garage_id: 43,
		part_id: 115,
		quantity: 239,
	},
	{
		garage_id: 43,
		part_id: 116,
		quantity: 54,
	},
	{
		garage_id: 43,
		part_id: 117,
		quantity: 317,
	},
	{
		garage_id: 43,
		part_id: 118,
		quantity: 310,
	},
	{
		garage_id: 43,
		part_id: 119,
		quantity: 52,
	},
	{
		garage_id: 43,
		part_id: 120,
		quantity: 315,
	},
	{
		garage_id: 43,
		part_id: 121,
		quantity: 411,
	},
	{
		garage_id: 43,
		part_id: 122,
		quantity: 133,
	},
	{
		garage_id: 43,
		part_id: 123,
		quantity: 56,
	},
	{
		garage_id: 43,
		part_id: 124,
		quantity: 492,
	},
	{
		garage_id: 43,
		part_id: 125,
		quantity: 48,
	},
	{
		garage_id: 43,
		part_id: 126,
		quantity: 15,
	},
	{
		garage_id: 43,
		part_id: 127,
		quantity: 493,
	},
	{
		garage_id: 43,
		part_id: 128,
		quantity: 381,
	},
	{
		garage_id: 43,
		part_id: 129,
		quantity: 46,
	},
	{
		garage_id: 43,
		part_id: 130,
		quantity: 384,
	},
	{
		garage_id: 43,
		part_id: 131,
		quantity: 136,
	},
	{
		garage_id: 43,
		part_id: 132,
		quantity: 163,
	},
	{
		garage_id: 43,
		part_id: 133,
		quantity: 7,
	},
	{
		garage_id: 43,
		part_id: 134,
		quantity: 221,
	},
	{
		garage_id: 43,
		part_id: 135,
		quantity: 42,
	},
	{
		garage_id: 43,
		part_id: 136,
		quantity: 205,
	},
	{
		garage_id: 43,
		part_id: 137,
		quantity: 414,
	},
	{
		garage_id: 43,
		part_id: 138,
		quantity: 413,
	},
	{
		garage_id: 43,
		part_id: 139,
		quantity: 459,
	},
	{
		garage_id: 43,
		part_id: 140,
		quantity: 364,
	},
	{
		garage_id: 43,
		part_id: 141,
		quantity: 334,
	},
	{
		garage_id: 43,
		part_id: 142,
		quantity: 38,
	},
	{
		garage_id: 43,
		part_id: 143,
		quantity: 169,
	},
	{
		garage_id: 43,
		part_id: 144,
		quantity: 22,
	},
	{
		garage_id: 43,
		part_id: 145,
		quantity: 255,
	},
	{
		garage_id: 43,
		part_id: 146,
		quantity: 94,
	},
	{
		garage_id: 43,
		part_id: 147,
		quantity: 487,
	},
	{
		garage_id: 43,
		part_id: 148,
		quantity: 362,
	},
	{
		garage_id: 43,
		part_id: 149,
		quantity: 291,
	},
	{
		garage_id: 43,
		part_id: 150,
		quantity: 53,
	},
	{
		garage_id: 43,
		part_id: 151,
		quantity: 456,
	},
	{
		garage_id: 43,
		part_id: 152,
		quantity: 310,
	},
	{
		garage_id: 43,
		part_id: 153,
		quantity: 214,
	},
	{
		garage_id: 43,
		part_id: 154,
		quantity: 31,
	},
	{
		garage_id: 43,
		part_id: 155,
		quantity: 328,
	},
	{
		garage_id: 43,
		part_id: 156,
		quantity: 493,
	},
	{
		garage_id: 43,
		part_id: 157,
		quantity: 212,
	},
	{
		garage_id: 43,
		part_id: 158,
		quantity: 471,
	},
	{
		garage_id: 43,
		part_id: 159,
		quantity: 55,
	},
	{
		garage_id: 43,
		part_id: 160,
		quantity: 138,
	},
	{
		garage_id: 43,
		part_id: 161,
		quantity: 477,
	},
	{
		garage_id: 43,
		part_id: 162,
		quantity: 149,
	},
	{
		garage_id: 43,
		part_id: 163,
		quantity: 310,
	},
	{
		garage_id: 43,
		part_id: 164,
		quantity: 149,
	},
	{
		garage_id: 43,
		part_id: 165,
		quantity: 358,
	},
	{
		garage_id: 43,
		part_id: 166,
		quantity: 263,
	},
	{
		garage_id: 43,
		part_id: 167,
		quantity: 69,
	},
	{
		garage_id: 43,
		part_id: 168,
		quantity: 74,
	},
	{
		garage_id: 43,
		part_id: 169,
		quantity: 6,
	},
	{
		garage_id: 43,
		part_id: 170,
		quantity: 78,
	},
	{
		garage_id: 43,
		part_id: 171,
		quantity: 495,
	},
	{
		garage_id: 43,
		part_id: 172,
		quantity: 241,
	},
	{
		garage_id: 43,
		part_id: 173,
		quantity: 235,
	},
	{
		garage_id: 43,
		part_id: 174,
		quantity: 187,
	},
	{
		garage_id: 43,
		part_id: 175,
		quantity: 129,
	},
	{
		garage_id: 43,
		part_id: 176,
		quantity: 136,
	},
	{
		garage_id: 43,
		part_id: 177,
		quantity: 373,
	},
	{
		garage_id: 43,
		part_id: 178,
		quantity: 52,
	},
	{
		garage_id: 43,
		part_id: 179,
		quantity: 418,
	},
	{
		garage_id: 43,
		part_id: 180,
		quantity: 16,
	},
	{
		garage_id: 43,
		part_id: 181,
		quantity: 296,
	},
	{
		garage_id: 43,
		part_id: 182,
		quantity: 295,
	},
	{
		garage_id: 43,
		part_id: 183,
		quantity: 122,
	},
	{
		garage_id: 43,
		part_id: 184,
		quantity: 460,
	},
	{
		garage_id: 43,
		part_id: 185,
		quantity: 493,
	},
	{
		garage_id: 43,
		part_id: 186,
		quantity: 174,
	},
	{
		garage_id: 43,
		part_id: 187,
		quantity: 268,
	},
	{
		garage_id: 43,
		part_id: 188,
		quantity: 185,
	},
	{
		garage_id: 43,
		part_id: 189,
		quantity: 78,
	},
	{
		garage_id: 43,
		part_id: 190,
		quantity: 67,
	},
	{
		garage_id: 43,
		part_id: 191,
		quantity: 230,
	},
	{
		garage_id: 43,
		part_id: 192,
		quantity: 143,
	},
	{
		garage_id: 43,
		part_id: 193,
		quantity: 445,
	},
	{
		garage_id: 43,
		part_id: 194,
		quantity: 436,
	},
	{
		garage_id: 43,
		part_id: 195,
		quantity: 61,
	},
	{
		garage_id: 43,
		part_id: 196,
		quantity: 430,
	},
	{
		garage_id: 43,
		part_id: 197,
		quantity: 207,
	},
	{
		garage_id: 43,
		part_id: 198,
		quantity: 128,
	},
	{
		garage_id: 43,
		part_id: 199,
		quantity: 439,
	},
	{
		garage_id: 43,
		part_id: 200,
		quantity: 247,
	},
	{
		garage_id: 43,
		part_id: 201,
		quantity: 35,
	},
	{
		garage_id: 43,
		part_id: 202,
		quantity: 485,
	},
	{
		garage_id: 43,
		part_id: 203,
		quantity: 289,
	},
	{
		garage_id: 43,
		part_id: 204,
		quantity: 374,
	},
	{
		garage_id: 43,
		part_id: 205,
		quantity: 489,
	},
	{
		garage_id: 43,
		part_id: 206,
		quantity: 444,
	},
	{
		garage_id: 43,
		part_id: 207,
		quantity: 305,
	},
	{
		garage_id: 43,
		part_id: 208,
		quantity: 90,
	},
	{
		garage_id: 43,
		part_id: 209,
		quantity: 41,
	},
	{
		garage_id: 43,
		part_id: 210,
		quantity: 69,
	},
	{
		garage_id: 43,
		part_id: 211,
		quantity: 365,
	},
	{
		garage_id: 43,
		part_id: 212,
		quantity: 263,
	},
	{
		garage_id: 43,
		part_id: 213,
		quantity: 34,
	},
	{
		garage_id: 43,
		part_id: 214,
		quantity: 323,
	},
	{
		garage_id: 43,
		part_id: 215,
		quantity: 345,
	},
	{
		garage_id: 43,
		part_id: 216,
		quantity: 271,
	},
	{
		garage_id: 43,
		part_id: 217,
		quantity: 345,
	},
	{
		garage_id: 43,
		part_id: 218,
		quantity: 489,
	},
	{
		garage_id: 43,
		part_id: 219,
		quantity: 485,
	},
	{
		garage_id: 43,
		part_id: 220,
		quantity: 170,
	},
	{
		garage_id: 43,
		part_id: 221,
		quantity: 255,
	},
	{
		garage_id: 43,
		part_id: 222,
		quantity: 464,
	},
	{
		garage_id: 43,
		part_id: 223,
		quantity: 417,
	},
	{
		garage_id: 43,
		part_id: 224,
		quantity: 47,
	},
	{
		garage_id: 43,
		part_id: 225,
		quantity: 197,
	},
	{
		garage_id: 43,
		part_id: 226,
		quantity: 472,
	},
	{
		garage_id: 43,
		part_id: 227,
		quantity: 116,
	},
	{
		garage_id: 43,
		part_id: 228,
		quantity: 433,
	},
	{
		garage_id: 43,
		part_id: 229,
		quantity: 38,
	},
	{
		garage_id: 43,
		part_id: 230,
		quantity: 21,
	},
	{
		garage_id: 43,
		part_id: 231,
		quantity: 113,
	},
	{
		garage_id: 43,
		part_id: 232,
		quantity: 9,
	},
	{
		garage_id: 43,
		part_id: 233,
		quantity: 48,
	},
	{
		garage_id: 43,
		part_id: 234,
		quantity: 20,
	},
	{
		garage_id: 43,
		part_id: 235,
		quantity: 416,
	},
	{
		garage_id: 43,
		part_id: 236,
		quantity: 386,
	},
	{
		garage_id: 43,
		part_id: 237,
		quantity: 108,
	},
	{
		garage_id: 43,
		part_id: 238,
		quantity: 99,
	},
	{
		garage_id: 43,
		part_id: 239,
		quantity: 333,
	},
	{
		garage_id: 43,
		part_id: 240,
		quantity: 288,
	},
	{
		garage_id: 43,
		part_id: 241,
		quantity: 498,
	},
	{
		garage_id: 43,
		part_id: 242,
		quantity: 434,
	},
	{
		garage_id: 43,
		part_id: 243,
		quantity: 335,
	},
	{
		garage_id: 43,
		part_id: 244,
		quantity: 394,
	},
	{
		garage_id: 43,
		part_id: 245,
		quantity: 302,
	},
	{
		garage_id: 43,
		part_id: 246,
		quantity: 249,
	},
	{
		garage_id: 43,
		part_id: 247,
		quantity: 0,
	},
	{
		garage_id: 43,
		part_id: 248,
		quantity: 112,
	},
	{
		garage_id: 43,
		part_id: 249,
		quantity: 466,
	},
	{
		garage_id: 43,
		part_id: 250,
		quantity: 168,
	},
	{
		garage_id: 43,
		part_id: 251,
		quantity: 389,
	},
	{
		garage_id: 43,
		part_id: 252,
		quantity: 53,
	},
	{
		garage_id: 43,
		part_id: 253,
		quantity: 464,
	},
	{
		garage_id: 43,
		part_id: 254,
		quantity: 191,
	},
	{
		garage_id: 43,
		part_id: 255,
		quantity: 187,
	},
	{
		garage_id: 43,
		part_id: 256,
		quantity: 248,
	},
	{
		garage_id: 43,
		part_id: 257,
		quantity: 315,
	},
	{
		garage_id: 43,
		part_id: 258,
		quantity: 406,
	},
	{
		garage_id: 43,
		part_id: 259,
		quantity: 173,
	},
	{
		garage_id: 43,
		part_id: 260,
		quantity: 119,
	},
	{
		garage_id: 43,
		part_id: 261,
		quantity: 353,
	},
	{
		garage_id: 43,
		part_id: 262,
		quantity: 407,
	},
	{
		garage_id: 43,
		part_id: 263,
		quantity: 206,
	},
	{
		garage_id: 43,
		part_id: 264,
		quantity: 134,
	},
	{
		garage_id: 43,
		part_id: 265,
		quantity: 377,
	},
	{
		garage_id: 43,
		part_id: 266,
		quantity: 77,
	},
	{
		garage_id: 43,
		part_id: 267,
		quantity: 442,
	},
	{
		garage_id: 43,
		part_id: 268,
		quantity: 34,
	},
	{
		garage_id: 43,
		part_id: 269,
		quantity: 373,
	},
	{
		garage_id: 43,
		part_id: 270,
		quantity: 106,
	},
	{
		garage_id: 43,
		part_id: 271,
		quantity: 347,
	},
	{
		garage_id: 43,
		part_id: 272,
		quantity: 249,
	},
	{
		garage_id: 43,
		part_id: 273,
		quantity: 315,
	},
	{
		garage_id: 43,
		part_id: 274,
		quantity: 33,
	},
	{
		garage_id: 43,
		part_id: 275,
		quantity: 56,
	},
	{
		garage_id: 43,
		part_id: 276,
		quantity: 372,
	},
	{
		garage_id: 43,
		part_id: 277,
		quantity: 412,
	},
	{
		garage_id: 43,
		part_id: 278,
		quantity: 161,
	},
	{
		garage_id: 43,
		part_id: 279,
		quantity: 199,
	},
	{
		garage_id: 43,
		part_id: 280,
		quantity: 483,
	},
	{
		garage_id: 43,
		part_id: 281,
		quantity: 489,
	},
	{
		garage_id: 43,
		part_id: 282,
		quantity: 296,
	},
	{
		garage_id: 43,
		part_id: 283,
		quantity: 309,
	},
	{
		garage_id: 43,
		part_id: 284,
		quantity: 446,
	},
	{
		garage_id: 43,
		part_id: 285,
		quantity: 230,
	},
	{
		garage_id: 43,
		part_id: 286,
		quantity: 474,
	},
	{
		garage_id: 43,
		part_id: 287,
		quantity: 43,
	},
	{
		garage_id: 43,
		part_id: 288,
		quantity: 184,
	},
	{
		garage_id: 43,
		part_id: 289,
		quantity: 478,
	},
	{
		garage_id: 43,
		part_id: 290,
		quantity: 17,
	},
	{
		garage_id: 43,
		part_id: 291,
		quantity: 447,
	},
	{
		garage_id: 43,
		part_id: 292,
		quantity: 287,
	},
	{
		garage_id: 43,
		part_id: 293,
		quantity: 476,
	},
	{
		garage_id: 43,
		part_id: 294,
		quantity: 397,
	},
	{
		garage_id: 43,
		part_id: 295,
		quantity: 132,
	},
	{
		garage_id: 43,
		part_id: 296,
		quantity: 17,
	},
	{
		garage_id: 43,
		part_id: 297,
		quantity: 228,
	},
	{
		garage_id: 43,
		part_id: 298,
		quantity: 208,
	},
	{
		garage_id: 43,
		part_id: 299,
		quantity: 214,
	},
	{
		garage_id: 43,
		part_id: 300,
		quantity: 417,
	},
	{
		garage_id: 43,
		part_id: 301,
		quantity: 316,
	},
	{
		garage_id: 43,
		part_id: 302,
		quantity: 238,
	},
	{
		garage_id: 43,
		part_id: 303,
		quantity: 310,
	},
	{
		garage_id: 43,
		part_id: 304,
		quantity: 365,
	},
	{
		garage_id: 43,
		part_id: 305,
		quantity: 409,
	},
	{
		garage_id: 43,
		part_id: 306,
		quantity: 55,
	},
	{
		garage_id: 43,
		part_id: 307,
		quantity: 472,
	},
	{
		garage_id: 43,
		part_id: 308,
		quantity: 495,
	},
	{
		garage_id: 43,
		part_id: 309,
		quantity: 424,
	},
	{
		garage_id: 43,
		part_id: 310,
		quantity: 244,
	},
	{
		garage_id: 43,
		part_id: 311,
		quantity: 271,
	},
	{
		garage_id: 43,
		part_id: 312,
		quantity: 115,
	},
	{
		garage_id: 43,
		part_id: 313,
		quantity: 224,
	},
	{
		garage_id: 43,
		part_id: 314,
		quantity: 156,
	},
	{
		garage_id: 43,
		part_id: 315,
		quantity: 202,
	},
	{
		garage_id: 43,
		part_id: 316,
		quantity: 343,
	},
	{
		garage_id: 43,
		part_id: 317,
		quantity: 405,
	},
	{
		garage_id: 43,
		part_id: 318,
		quantity: 159,
	},
	{
		garage_id: 43,
		part_id: 319,
		quantity: 482,
	},
	{
		garage_id: 43,
		part_id: 320,
		quantity: 124,
	},
	{
		garage_id: 43,
		part_id: 321,
		quantity: 257,
	},
	{
		garage_id: 43,
		part_id: 322,
		quantity: 373,
	},
	{
		garage_id: 43,
		part_id: 323,
		quantity: 382,
	},
	{
		garage_id: 43,
		part_id: 324,
		quantity: 417,
	},
	{
		garage_id: 43,
		part_id: 325,
		quantity: 340,
	},
	{
		garage_id: 43,
		part_id: 326,
		quantity: 413,
	},
	{
		garage_id: 43,
		part_id: 327,
		quantity: 435,
	},
	{
		garage_id: 43,
		part_id: 328,
		quantity: 1,
	},
	{
		garage_id: 43,
		part_id: 329,
		quantity: 110,
	},
	{
		garage_id: 43,
		part_id: 330,
		quantity: 479,
	},
	{
		garage_id: 43,
		part_id: 331,
		quantity: 214,
	},
	{
		garage_id: 43,
		part_id: 332,
		quantity: 323,
	},
	{
		garage_id: 43,
		part_id: 333,
		quantity: 237,
	},
	{
		garage_id: 43,
		part_id: 334,
		quantity: 329,
	},
	{
		garage_id: 43,
		part_id: 335,
		quantity: 73,
	},
	{
		garage_id: 43,
		part_id: 336,
		quantity: 98,
	},
	{
		garage_id: 43,
		part_id: 337,
		quantity: 31,
	},
	{
		garage_id: 43,
		part_id: 338,
		quantity: 20,
	},
	{
		garage_id: 43,
		part_id: 339,
		quantity: 464,
	},
	{
		garage_id: 43,
		part_id: 340,
		quantity: 239,
	},
	{
		garage_id: 43,
		part_id: 341,
		quantity: 313,
	},
	{
		garage_id: 43,
		part_id: 342,
		quantity: 55,
	},
	{
		garage_id: 43,
		part_id: 343,
		quantity: 172,
	},
	{
		garage_id: 43,
		part_id: 344,
		quantity: 452,
	},
	{
		garage_id: 43,
		part_id: 345,
		quantity: 128,
	},
	{
		garage_id: 43,
		part_id: 346,
		quantity: 192,
	},
	{
		garage_id: 43,
		part_id: 347,
		quantity: 470,
	},
	{
		garage_id: 43,
		part_id: 348,
		quantity: 336,
	},
	{
		garage_id: 43,
		part_id: 349,
		quantity: 410,
	},
	{
		garage_id: 43,
		part_id: 350,
		quantity: 6,
	},
	{
		garage_id: 43,
		part_id: 351,
		quantity: 210,
	},
	{
		garage_id: 43,
		part_id: 352,
		quantity: 334,
	},
	{
		garage_id: 43,
		part_id: 353,
		quantity: 3,
	},
	{
		garage_id: 43,
		part_id: 354,
		quantity: 49,
	},
	{
		garage_id: 43,
		part_id: 355,
		quantity: 174,
	},
	{
		garage_id: 43,
		part_id: 356,
		quantity: 146,
	},
	{
		garage_id: 43,
		part_id: 357,
		quantity: 490,
	},
	{
		garage_id: 43,
		part_id: 358,
		quantity: 347,
	},
	{
		garage_id: 43,
		part_id: 359,
		quantity: 262,
	},
	{
		garage_id: 43,
		part_id: 360,
		quantity: 401,
	},
	{
		garage_id: 43,
		part_id: 361,
		quantity: 80,
	},
	{
		garage_id: 43,
		part_id: 362,
		quantity: 230,
	},
	{
		garage_id: 43,
		part_id: 363,
		quantity: 482,
	},
	{
		garage_id: 43,
		part_id: 364,
		quantity: 422,
	},
	{
		garage_id: 43,
		part_id: 365,
		quantity: 80,
	},
	{
		garage_id: 43,
		part_id: 366,
		quantity: 93,
	},
	{
		garage_id: 43,
		part_id: 367,
		quantity: 4,
	},
	{
		garage_id: 43,
		part_id: 368,
		quantity: 8,
	},
	{
		garage_id: 43,
		part_id: 369,
		quantity: 220,
	},
	{
		garage_id: 43,
		part_id: 370,
		quantity: 350,
	},
	{
		garage_id: 43,
		part_id: 371,
		quantity: 317,
	},
	{
		garage_id: 43,
		part_id: 372,
		quantity: 239,
	},
	{
		garage_id: 43,
		part_id: 373,
		quantity: 358,
	},
	{
		garage_id: 43,
		part_id: 374,
		quantity: 427,
	},
	{
		garage_id: 43,
		part_id: 375,
		quantity: 453,
	},
	{
		garage_id: 43,
		part_id: 376,
		quantity: 256,
	},
	{
		garage_id: 43,
		part_id: 377,
		quantity: 395,
	},
	{
		garage_id: 43,
		part_id: 378,
		quantity: 248,
	},
	{
		garage_id: 43,
		part_id: 379,
		quantity: 403,
	},
	{
		garage_id: 43,
		part_id: 380,
		quantity: 256,
	},
	{
		garage_id: 43,
		part_id: 381,
		quantity: 144,
	},
	{
		garage_id: 43,
		part_id: 382,
		quantity: 272,
	},
	{
		garage_id: 43,
		part_id: 383,
		quantity: 442,
	},
	{
		garage_id: 43,
		part_id: 384,
		quantity: 289,
	},
	{
		garage_id: 43,
		part_id: 385,
		quantity: 324,
	},
	{
		garage_id: 43,
		part_id: 386,
		quantity: 422,
	},
	{
		garage_id: 43,
		part_id: 387,
		quantity: 497,
	},
	{
		garage_id: 43,
		part_id: 388,
		quantity: 354,
	},
	{
		garage_id: 43,
		part_id: 389,
		quantity: 306,
	},
	{
		garage_id: 43,
		part_id: 390,
		quantity: 27,
	},
	{
		garage_id: 43,
		part_id: 391,
		quantity: 390,
	},
	{
		garage_id: 43,
		part_id: 392,
		quantity: 29,
	},
	{
		garage_id: 43,
		part_id: 393,
		quantity: 336,
	},
	{
		garage_id: 43,
		part_id: 394,
		quantity: 341,
	},
	{
		garage_id: 43,
		part_id: 395,
		quantity: 272,
	},
	{
		garage_id: 43,
		part_id: 396,
		quantity: 445,
	},
	{
		garage_id: 43,
		part_id: 397,
		quantity: 375,
	},
	{
		garage_id: 43,
		part_id: 398,
		quantity: 205,
	},
	{
		garage_id: 43,
		part_id: 399,
		quantity: 445,
	},
	{
		garage_id: 44,
		part_id: 0,
		quantity: 128,
	},
	{
		garage_id: 44,
		part_id: 1,
		quantity: 219,
	},
	{
		garage_id: 44,
		part_id: 2,
		quantity: 245,
	},
	{
		garage_id: 44,
		part_id: 3,
		quantity: 182,
	},
	{
		garage_id: 44,
		part_id: 4,
		quantity: 92,
	},
	{
		garage_id: 44,
		part_id: 5,
		quantity: 281,
	},
	{
		garage_id: 44,
		part_id: 6,
		quantity: 19,
	},
	{
		garage_id: 44,
		part_id: 7,
		quantity: 234,
	},
	{
		garage_id: 44,
		part_id: 8,
		quantity: 162,
	},
	{
		garage_id: 44,
		part_id: 9,
		quantity: 145,
	},
	{
		garage_id: 44,
		part_id: 10,
		quantity: 92,
	},
	{
		garage_id: 44,
		part_id: 11,
		quantity: 443,
	},
	{
		garage_id: 44,
		part_id: 12,
		quantity: 14,
	},
	{
		garage_id: 44,
		part_id: 13,
		quantity: 495,
	},
	{
		garage_id: 44,
		part_id: 14,
		quantity: 411,
	},
	{
		garage_id: 44,
		part_id: 15,
		quantity: 52,
	},
	{
		garage_id: 44,
		part_id: 16,
		quantity: 360,
	},
	{
		garage_id: 44,
		part_id: 17,
		quantity: 159,
	},
	{
		garage_id: 44,
		part_id: 18,
		quantity: 88,
	},
	{
		garage_id: 44,
		part_id: 19,
		quantity: 403,
	},
	{
		garage_id: 44,
		part_id: 20,
		quantity: 297,
	},
	{
		garage_id: 44,
		part_id: 21,
		quantity: 394,
	},
	{
		garage_id: 44,
		part_id: 22,
		quantity: 210,
	},
	{
		garage_id: 44,
		part_id: 23,
		quantity: 291,
	},
	{
		garage_id: 44,
		part_id: 24,
		quantity: 299,
	},
	{
		garage_id: 44,
		part_id: 25,
		quantity: 270,
	},
	{
		garage_id: 44,
		part_id: 26,
		quantity: 278,
	},
	{
		garage_id: 44,
		part_id: 27,
		quantity: 175,
	},
	{
		garage_id: 44,
		part_id: 28,
		quantity: 236,
	},
	{
		garage_id: 44,
		part_id: 29,
		quantity: 325,
	},
	{
		garage_id: 44,
		part_id: 30,
		quantity: 300,
	},
	{
		garage_id: 44,
		part_id: 31,
		quantity: 359,
	},
	{
		garage_id: 44,
		part_id: 32,
		quantity: 441,
	},
	{
		garage_id: 44,
		part_id: 33,
		quantity: 111,
	},
	{
		garage_id: 44,
		part_id: 34,
		quantity: 276,
	},
	{
		garage_id: 44,
		part_id: 35,
		quantity: 348,
	},
	{
		garage_id: 44,
		part_id: 36,
		quantity: 412,
	},
	{
		garage_id: 44,
		part_id: 37,
		quantity: 114,
	},
	{
		garage_id: 44,
		part_id: 38,
		quantity: 138,
	},
	{
		garage_id: 44,
		part_id: 39,
		quantity: 63,
	},
	{
		garage_id: 44,
		part_id: 40,
		quantity: 117,
	},
	{
		garage_id: 44,
		part_id: 41,
		quantity: 285,
	},
	{
		garage_id: 44,
		part_id: 42,
		quantity: 60,
	},
	{
		garage_id: 44,
		part_id: 43,
		quantity: 444,
	},
	{
		garage_id: 44,
		part_id: 44,
		quantity: 478,
	},
	{
		garage_id: 44,
		part_id: 45,
		quantity: 112,
	},
	{
		garage_id: 44,
		part_id: 46,
		quantity: 500,
	},
	{
		garage_id: 44,
		part_id: 47,
		quantity: 315,
	},
	{
		garage_id: 44,
		part_id: 48,
		quantity: 57,
	},
	{
		garage_id: 44,
		part_id: 49,
		quantity: 146,
	},
	{
		garage_id: 44,
		part_id: 50,
		quantity: 13,
	},
	{
		garage_id: 44,
		part_id: 51,
		quantity: 187,
	},
	{
		garage_id: 44,
		part_id: 52,
		quantity: 420,
	},
	{
		garage_id: 44,
		part_id: 53,
		quantity: 141,
	},
	{
		garage_id: 44,
		part_id: 54,
		quantity: 180,
	},
	{
		garage_id: 44,
		part_id: 55,
		quantity: 220,
	},
	{
		garage_id: 44,
		part_id: 56,
		quantity: 279,
	},
	{
		garage_id: 44,
		part_id: 57,
		quantity: 48,
	},
	{
		garage_id: 44,
		part_id: 58,
		quantity: 81,
	},
	{
		garage_id: 44,
		part_id: 59,
		quantity: 368,
	},
	{
		garage_id: 44,
		part_id: 60,
		quantity: 404,
	},
	{
		garage_id: 44,
		part_id: 61,
		quantity: 167,
	},
	{
		garage_id: 44,
		part_id: 62,
		quantity: 317,
	},
	{
		garage_id: 44,
		part_id: 63,
		quantity: 326,
	},
	{
		garage_id: 44,
		part_id: 64,
		quantity: 134,
	},
	{
		garage_id: 44,
		part_id: 65,
		quantity: 24,
	},
	{
		garage_id: 44,
		part_id: 66,
		quantity: 62,
	},
	{
		garage_id: 44,
		part_id: 67,
		quantity: 389,
	},
	{
		garage_id: 44,
		part_id: 68,
		quantity: 278,
	},
	{
		garage_id: 44,
		part_id: 69,
		quantity: 311,
	},
	{
		garage_id: 44,
		part_id: 70,
		quantity: 339,
	},
	{
		garage_id: 44,
		part_id: 71,
		quantity: 346,
	},
	{
		garage_id: 44,
		part_id: 72,
		quantity: 361,
	},
	{
		garage_id: 44,
		part_id: 73,
		quantity: 416,
	},
	{
		garage_id: 44,
		part_id: 74,
		quantity: 258,
	},
	{
		garage_id: 44,
		part_id: 75,
		quantity: 10,
	},
	{
		garage_id: 44,
		part_id: 76,
		quantity: 494,
	},
	{
		garage_id: 44,
		part_id: 77,
		quantity: 191,
	},
	{
		garage_id: 44,
		part_id: 78,
		quantity: 108,
	},
	{
		garage_id: 44,
		part_id: 79,
		quantity: 262,
	},
	{
		garage_id: 44,
		part_id: 80,
		quantity: 347,
	},
	{
		garage_id: 44,
		part_id: 81,
		quantity: 371,
	},
	{
		garage_id: 44,
		part_id: 82,
		quantity: 258,
	},
	{
		garage_id: 44,
		part_id: 83,
		quantity: 73,
	},
	{
		garage_id: 44,
		part_id: 84,
		quantity: 446,
	},
	{
		garage_id: 44,
		part_id: 85,
		quantity: 301,
	},
	{
		garage_id: 44,
		part_id: 86,
		quantity: 248,
	},
	{
		garage_id: 44,
		part_id: 87,
		quantity: 69,
	},
	{
		garage_id: 44,
		part_id: 88,
		quantity: 107,
	},
	{
		garage_id: 44,
		part_id: 89,
		quantity: 211,
	},
	{
		garage_id: 44,
		part_id: 90,
		quantity: 41,
	},
	{
		garage_id: 44,
		part_id: 91,
		quantity: 61,
	},
	{
		garage_id: 44,
		part_id: 92,
		quantity: 470,
	},
	{
		garage_id: 44,
		part_id: 93,
		quantity: 280,
	},
	{
		garage_id: 44,
		part_id: 94,
		quantity: 168,
	},
	{
		garage_id: 44,
		part_id: 95,
		quantity: 232,
	},
	{
		garage_id: 44,
		part_id: 96,
		quantity: 428,
	},
	{
		garage_id: 44,
		part_id: 97,
		quantity: 345,
	},
	{
		garage_id: 44,
		part_id: 98,
		quantity: 252,
	},
	{
		garage_id: 44,
		part_id: 99,
		quantity: 404,
	},
	{
		garage_id: 44,
		part_id: 100,
		quantity: 249,
	},
	{
		garage_id: 44,
		part_id: 101,
		quantity: 302,
	},
	{
		garage_id: 44,
		part_id: 102,
		quantity: 457,
	},
	{
		garage_id: 44,
		part_id: 103,
		quantity: 164,
	},
	{
		garage_id: 44,
		part_id: 104,
		quantity: 48,
	},
	{
		garage_id: 44,
		part_id: 105,
		quantity: 80,
	},
	{
		garage_id: 44,
		part_id: 106,
		quantity: 339,
	},
	{
		garage_id: 44,
		part_id: 107,
		quantity: 151,
	},
	{
		garage_id: 44,
		part_id: 108,
		quantity: 489,
	},
	{
		garage_id: 44,
		part_id: 109,
		quantity: 44,
	},
	{
		garage_id: 44,
		part_id: 110,
		quantity: 247,
	},
	{
		garage_id: 44,
		part_id: 111,
		quantity: 469,
	},
	{
		garage_id: 44,
		part_id: 112,
		quantity: 345,
	},
	{
		garage_id: 44,
		part_id: 113,
		quantity: 315,
	},
	{
		garage_id: 44,
		part_id: 114,
		quantity: 346,
	},
	{
		garage_id: 44,
		part_id: 115,
		quantity: 447,
	},
	{
		garage_id: 44,
		part_id: 116,
		quantity: 282,
	},
	{
		garage_id: 44,
		part_id: 117,
		quantity: 401,
	},
	{
		garage_id: 44,
		part_id: 118,
		quantity: 135,
	},
	{
		garage_id: 44,
		part_id: 119,
		quantity: 111,
	},
	{
		garage_id: 44,
		part_id: 120,
		quantity: 110,
	},
	{
		garage_id: 44,
		part_id: 121,
		quantity: 336,
	},
	{
		garage_id: 44,
		part_id: 122,
		quantity: 442,
	},
	{
		garage_id: 44,
		part_id: 123,
		quantity: 302,
	},
	{
		garage_id: 44,
		part_id: 124,
		quantity: 287,
	},
	{
		garage_id: 44,
		part_id: 125,
		quantity: 91,
	},
	{
		garage_id: 44,
		part_id: 126,
		quantity: 51,
	},
	{
		garage_id: 44,
		part_id: 127,
		quantity: 331,
	},
	{
		garage_id: 44,
		part_id: 128,
		quantity: 395,
	},
	{
		garage_id: 44,
		part_id: 129,
		quantity: 19,
	},
	{
		garage_id: 44,
		part_id: 130,
		quantity: 154,
	},
	{
		garage_id: 44,
		part_id: 131,
		quantity: 362,
	},
	{
		garage_id: 44,
		part_id: 132,
		quantity: 426,
	},
	{
		garage_id: 44,
		part_id: 133,
		quantity: 459,
	},
	{
		garage_id: 44,
		part_id: 134,
		quantity: 57,
	},
	{
		garage_id: 44,
		part_id: 135,
		quantity: 294,
	},
	{
		garage_id: 44,
		part_id: 136,
		quantity: 85,
	},
	{
		garage_id: 44,
		part_id: 137,
		quantity: 423,
	},
	{
		garage_id: 44,
		part_id: 138,
		quantity: 423,
	},
	{
		garage_id: 44,
		part_id: 139,
		quantity: 87,
	},
	{
		garage_id: 44,
		part_id: 140,
		quantity: 143,
	},
	{
		garage_id: 44,
		part_id: 141,
		quantity: 371,
	},
	{
		garage_id: 44,
		part_id: 142,
		quantity: 185,
	},
	{
		garage_id: 44,
		part_id: 143,
		quantity: 6,
	},
	{
		garage_id: 44,
		part_id: 144,
		quantity: 364,
	},
	{
		garage_id: 44,
		part_id: 145,
		quantity: 349,
	},
	{
		garage_id: 44,
		part_id: 146,
		quantity: 481,
	},
	{
		garage_id: 44,
		part_id: 147,
		quantity: 391,
	},
	{
		garage_id: 44,
		part_id: 148,
		quantity: 424,
	},
	{
		garage_id: 44,
		part_id: 149,
		quantity: 369,
	},
	{
		garage_id: 44,
		part_id: 150,
		quantity: 433,
	},
	{
		garage_id: 44,
		part_id: 151,
		quantity: 200,
	},
	{
		garage_id: 44,
		part_id: 152,
		quantity: 130,
	},
	{
		garage_id: 44,
		part_id: 153,
		quantity: 137,
	},
	{
		garage_id: 44,
		part_id: 154,
		quantity: 93,
	},
	{
		garage_id: 44,
		part_id: 155,
		quantity: 122,
	},
	{
		garage_id: 44,
		part_id: 156,
		quantity: 406,
	},
	{
		garage_id: 44,
		part_id: 157,
		quantity: 325,
	},
	{
		garage_id: 44,
		part_id: 158,
		quantity: 158,
	},
	{
		garage_id: 44,
		part_id: 159,
		quantity: 62,
	},
	{
		garage_id: 44,
		part_id: 160,
		quantity: 214,
	},
	{
		garage_id: 44,
		part_id: 161,
		quantity: 283,
	},
	{
		garage_id: 44,
		part_id: 162,
		quantity: 231,
	},
	{
		garage_id: 44,
		part_id: 163,
		quantity: 490,
	},
	{
		garage_id: 44,
		part_id: 164,
		quantity: 16,
	},
	{
		garage_id: 44,
		part_id: 165,
		quantity: 86,
	},
	{
		garage_id: 44,
		part_id: 166,
		quantity: 257,
	},
	{
		garage_id: 44,
		part_id: 167,
		quantity: 51,
	},
	{
		garage_id: 44,
		part_id: 168,
		quantity: 37,
	},
	{
		garage_id: 44,
		part_id: 169,
		quantity: 200,
	},
	{
		garage_id: 44,
		part_id: 170,
		quantity: 417,
	},
	{
		garage_id: 44,
		part_id: 171,
		quantity: 249,
	},
	{
		garage_id: 44,
		part_id: 172,
		quantity: 171,
	},
	{
		garage_id: 44,
		part_id: 173,
		quantity: 493,
	},
	{
		garage_id: 44,
		part_id: 174,
		quantity: 322,
	},
	{
		garage_id: 44,
		part_id: 175,
		quantity: 345,
	},
	{
		garage_id: 44,
		part_id: 176,
		quantity: 495,
	},
	{
		garage_id: 44,
		part_id: 177,
		quantity: 376,
	},
	{
		garage_id: 44,
		part_id: 178,
		quantity: 18,
	},
	{
		garage_id: 44,
		part_id: 179,
		quantity: 441,
	},
	{
		garage_id: 44,
		part_id: 180,
		quantity: 65,
	},
	{
		garage_id: 44,
		part_id: 181,
		quantity: 75,
	},
	{
		garage_id: 44,
		part_id: 182,
		quantity: 7,
	},
	{
		garage_id: 44,
		part_id: 183,
		quantity: 389,
	},
	{
		garage_id: 44,
		part_id: 184,
		quantity: 304,
	},
	{
		garage_id: 44,
		part_id: 185,
		quantity: 354,
	},
	{
		garage_id: 44,
		part_id: 186,
		quantity: 375,
	},
	{
		garage_id: 44,
		part_id: 187,
		quantity: 317,
	},
	{
		garage_id: 44,
		part_id: 188,
		quantity: 90,
	},
	{
		garage_id: 44,
		part_id: 189,
		quantity: 131,
	},
	{
		garage_id: 44,
		part_id: 190,
		quantity: 201,
	},
	{
		garage_id: 44,
		part_id: 191,
		quantity: 26,
	},
	{
		garage_id: 44,
		part_id: 192,
		quantity: 236,
	},
	{
		garage_id: 44,
		part_id: 193,
		quantity: 425,
	},
	{
		garage_id: 44,
		part_id: 194,
		quantity: 213,
	},
	{
		garage_id: 44,
		part_id: 195,
		quantity: 92,
	},
	{
		garage_id: 44,
		part_id: 196,
		quantity: 435,
	},
	{
		garage_id: 44,
		part_id: 197,
		quantity: 218,
	},
	{
		garage_id: 44,
		part_id: 198,
		quantity: 105,
	},
	{
		garage_id: 44,
		part_id: 199,
		quantity: 417,
	},
	{
		garage_id: 44,
		part_id: 200,
		quantity: 79,
	},
	{
		garage_id: 44,
		part_id: 201,
		quantity: 230,
	},
	{
		garage_id: 44,
		part_id: 202,
		quantity: 128,
	},
	{
		garage_id: 44,
		part_id: 203,
		quantity: 290,
	},
	{
		garage_id: 44,
		part_id: 204,
		quantity: 469,
	},
	{
		garage_id: 44,
		part_id: 205,
		quantity: 70,
	},
	{
		garage_id: 44,
		part_id: 206,
		quantity: 377,
	},
	{
		garage_id: 44,
		part_id: 207,
		quantity: 176,
	},
	{
		garage_id: 44,
		part_id: 208,
		quantity: 192,
	},
	{
		garage_id: 44,
		part_id: 209,
		quantity: 61,
	},
	{
		garage_id: 44,
		part_id: 210,
		quantity: 86,
	},
	{
		garage_id: 44,
		part_id: 211,
		quantity: 275,
	},
	{
		garage_id: 44,
		part_id: 212,
		quantity: 165,
	},
	{
		garage_id: 44,
		part_id: 213,
		quantity: 120,
	},
	{
		garage_id: 44,
		part_id: 214,
		quantity: 299,
	},
	{
		garage_id: 44,
		part_id: 215,
		quantity: 125,
	},
	{
		garage_id: 44,
		part_id: 216,
		quantity: 105,
	},
	{
		garage_id: 44,
		part_id: 217,
		quantity: 59,
	},
	{
		garage_id: 44,
		part_id: 218,
		quantity: 51,
	},
	{
		garage_id: 44,
		part_id: 219,
		quantity: 124,
	},
	{
		garage_id: 44,
		part_id: 220,
		quantity: 88,
	},
	{
		garage_id: 44,
		part_id: 221,
		quantity: 25,
	},
	{
		garage_id: 44,
		part_id: 222,
		quantity: 20,
	},
	{
		garage_id: 44,
		part_id: 223,
		quantity: 287,
	},
	{
		garage_id: 44,
		part_id: 224,
		quantity: 66,
	},
	{
		garage_id: 44,
		part_id: 225,
		quantity: 21,
	},
	{
		garage_id: 44,
		part_id: 226,
		quantity: 457,
	},
	{
		garage_id: 44,
		part_id: 227,
		quantity: 487,
	},
	{
		garage_id: 44,
		part_id: 228,
		quantity: 375,
	},
	{
		garage_id: 44,
		part_id: 229,
		quantity: 366,
	},
	{
		garage_id: 44,
		part_id: 230,
		quantity: 389,
	},
	{
		garage_id: 44,
		part_id: 231,
		quantity: 494,
	},
	{
		garage_id: 44,
		part_id: 232,
		quantity: 189,
	},
	{
		garage_id: 44,
		part_id: 233,
		quantity: 280,
	},
	{
		garage_id: 44,
		part_id: 234,
		quantity: 52,
	},
	{
		garage_id: 44,
		part_id: 235,
		quantity: 14,
	},
	{
		garage_id: 44,
		part_id: 236,
		quantity: 345,
	},
	{
		garage_id: 44,
		part_id: 237,
		quantity: 342,
	},
	{
		garage_id: 44,
		part_id: 238,
		quantity: 227,
	},
	{
		garage_id: 44,
		part_id: 239,
		quantity: 192,
	},
	{
		garage_id: 44,
		part_id: 240,
		quantity: 304,
	},
	{
		garage_id: 44,
		part_id: 241,
		quantity: 119,
	},
	{
		garage_id: 44,
		part_id: 242,
		quantity: 277,
	},
	{
		garage_id: 44,
		part_id: 243,
		quantity: 44,
	},
	{
		garage_id: 44,
		part_id: 244,
		quantity: 348,
	},
	{
		garage_id: 44,
		part_id: 245,
		quantity: 127,
	},
	{
		garage_id: 44,
		part_id: 246,
		quantity: 195,
	},
	{
		garage_id: 44,
		part_id: 247,
		quantity: 29,
	},
	{
		garage_id: 44,
		part_id: 248,
		quantity: 330,
	},
	{
		garage_id: 44,
		part_id: 249,
		quantity: 65,
	},
	{
		garage_id: 44,
		part_id: 250,
		quantity: 308,
	},
	{
		garage_id: 44,
		part_id: 251,
		quantity: 52,
	},
	{
		garage_id: 44,
		part_id: 252,
		quantity: 401,
	},
	{
		garage_id: 44,
		part_id: 253,
		quantity: 424,
	},
	{
		garage_id: 44,
		part_id: 254,
		quantity: 402,
	},
	{
		garage_id: 44,
		part_id: 255,
		quantity: 496,
	},
	{
		garage_id: 44,
		part_id: 256,
		quantity: 495,
	},
	{
		garage_id: 44,
		part_id: 257,
		quantity: 423,
	},
	{
		garage_id: 44,
		part_id: 258,
		quantity: 316,
	},
	{
		garage_id: 44,
		part_id: 259,
		quantity: 216,
	},
	{
		garage_id: 44,
		part_id: 260,
		quantity: 58,
	},
	{
		garage_id: 44,
		part_id: 261,
		quantity: 217,
	},
	{
		garage_id: 44,
		part_id: 262,
		quantity: 51,
	},
	{
		garage_id: 44,
		part_id: 263,
		quantity: 270,
	},
	{
		garage_id: 44,
		part_id: 264,
		quantity: 165,
	},
	{
		garage_id: 44,
		part_id: 265,
		quantity: 239,
	},
	{
		garage_id: 44,
		part_id: 266,
		quantity: 198,
	},
	{
		garage_id: 44,
		part_id: 267,
		quantity: 79,
	},
	{
		garage_id: 44,
		part_id: 268,
		quantity: 155,
	},
	{
		garage_id: 44,
		part_id: 269,
		quantity: 253,
	},
	{
		garage_id: 44,
		part_id: 270,
		quantity: 407,
	},
	{
		garage_id: 44,
		part_id: 271,
		quantity: 77,
	},
	{
		garage_id: 44,
		part_id: 272,
		quantity: 360,
	},
	{
		garage_id: 44,
		part_id: 273,
		quantity: 357,
	},
	{
		garage_id: 44,
		part_id: 274,
		quantity: 242,
	},
	{
		garage_id: 44,
		part_id: 275,
		quantity: 355,
	},
	{
		garage_id: 44,
		part_id: 276,
		quantity: 168,
	},
	{
		garage_id: 44,
		part_id: 277,
		quantity: 341,
	},
	{
		garage_id: 44,
		part_id: 278,
		quantity: 494,
	},
	{
		garage_id: 44,
		part_id: 279,
		quantity: 280,
	},
	{
		garage_id: 44,
		part_id: 280,
		quantity: 353,
	},
	{
		garage_id: 44,
		part_id: 281,
		quantity: 41,
	},
	{
		garage_id: 44,
		part_id: 282,
		quantity: 452,
	},
	{
		garage_id: 44,
		part_id: 283,
		quantity: 495,
	},
	{
		garage_id: 44,
		part_id: 284,
		quantity: 310,
	},
	{
		garage_id: 44,
		part_id: 285,
		quantity: 401,
	},
	{
		garage_id: 44,
		part_id: 286,
		quantity: 19,
	},
	{
		garage_id: 44,
		part_id: 287,
		quantity: 265,
	},
	{
		garage_id: 44,
		part_id: 288,
		quantity: 354,
	},
	{
		garage_id: 44,
		part_id: 289,
		quantity: 44,
	},
	{
		garage_id: 44,
		part_id: 290,
		quantity: 168,
	},
	{
		garage_id: 44,
		part_id: 291,
		quantity: 397,
	},
	{
		garage_id: 44,
		part_id: 292,
		quantity: 150,
	},
	{
		garage_id: 44,
		part_id: 293,
		quantity: 264,
	},
	{
		garage_id: 44,
		part_id: 294,
		quantity: 174,
	},
	{
		garage_id: 44,
		part_id: 295,
		quantity: 402,
	},
	{
		garage_id: 44,
		part_id: 296,
		quantity: 401,
	},
	{
		garage_id: 44,
		part_id: 297,
		quantity: 24,
	},
	{
		garage_id: 44,
		part_id: 298,
		quantity: 330,
	},
	{
		garage_id: 44,
		part_id: 299,
		quantity: 489,
	},
	{
		garage_id: 44,
		part_id: 300,
		quantity: 15,
	},
	{
		garage_id: 44,
		part_id: 301,
		quantity: 499,
	},
	{
		garage_id: 44,
		part_id: 302,
		quantity: 106,
	},
	{
		garage_id: 44,
		part_id: 303,
		quantity: 38,
	},
	{
		garage_id: 44,
		part_id: 304,
		quantity: 25,
	},
	{
		garage_id: 44,
		part_id: 305,
		quantity: 284,
	},
	{
		garage_id: 44,
		part_id: 306,
		quantity: 80,
	},
	{
		garage_id: 44,
		part_id: 307,
		quantity: 224,
	},
	{
		garage_id: 44,
		part_id: 308,
		quantity: 381,
	},
	{
		garage_id: 44,
		part_id: 309,
		quantity: 228,
	},
	{
		garage_id: 44,
		part_id: 310,
		quantity: 125,
	},
	{
		garage_id: 44,
		part_id: 311,
		quantity: 56,
	},
	{
		garage_id: 44,
		part_id: 312,
		quantity: 198,
	},
	{
		garage_id: 44,
		part_id: 313,
		quantity: 146,
	},
	{
		garage_id: 44,
		part_id: 314,
		quantity: 453,
	},
	{
		garage_id: 44,
		part_id: 315,
		quantity: 8,
	},
	{
		garage_id: 44,
		part_id: 316,
		quantity: 298,
	},
	{
		garage_id: 44,
		part_id: 317,
		quantity: 442,
	},
	{
		garage_id: 44,
		part_id: 318,
		quantity: 254,
	},
	{
		garage_id: 44,
		part_id: 319,
		quantity: 167,
	},
	{
		garage_id: 44,
		part_id: 320,
		quantity: 188,
	},
	{
		garage_id: 44,
		part_id: 321,
		quantity: 421,
	},
	{
		garage_id: 44,
		part_id: 322,
		quantity: 497,
	},
	{
		garage_id: 44,
		part_id: 323,
		quantity: 192,
	},
	{
		garage_id: 44,
		part_id: 324,
		quantity: 109,
	},
	{
		garage_id: 44,
		part_id: 325,
		quantity: 152,
	},
	{
		garage_id: 44,
		part_id: 326,
		quantity: 451,
	},
	{
		garage_id: 44,
		part_id: 327,
		quantity: 11,
	},
	{
		garage_id: 44,
		part_id: 328,
		quantity: 8,
	},
	{
		garage_id: 44,
		part_id: 329,
		quantity: 475,
	},
	{
		garage_id: 44,
		part_id: 330,
		quantity: 9,
	},
	{
		garage_id: 44,
		part_id: 331,
		quantity: 101,
	},
	{
		garage_id: 44,
		part_id: 332,
		quantity: 145,
	},
	{
		garage_id: 44,
		part_id: 333,
		quantity: 8,
	},
	{
		garage_id: 44,
		part_id: 334,
		quantity: 462,
	},
	{
		garage_id: 44,
		part_id: 335,
		quantity: 305,
	},
	{
		garage_id: 44,
		part_id: 336,
		quantity: 136,
	},
	{
		garage_id: 44,
		part_id: 337,
		quantity: 260,
	},
	{
		garage_id: 44,
		part_id: 338,
		quantity: 480,
	},
	{
		garage_id: 44,
		part_id: 339,
		quantity: 258,
	},
	{
		garage_id: 44,
		part_id: 340,
		quantity: 154,
	},
	{
		garage_id: 44,
		part_id: 341,
		quantity: 157,
	},
	{
		garage_id: 44,
		part_id: 342,
		quantity: 374,
	},
	{
		garage_id: 44,
		part_id: 343,
		quantity: 273,
	},
	{
		garage_id: 44,
		part_id: 344,
		quantity: 303,
	},
	{
		garage_id: 44,
		part_id: 345,
		quantity: 333,
	},
	{
		garage_id: 44,
		part_id: 346,
		quantity: 388,
	},
	{
		garage_id: 44,
		part_id: 347,
		quantity: 134,
	},
	{
		garage_id: 44,
		part_id: 348,
		quantity: 326,
	},
	{
		garage_id: 44,
		part_id: 349,
		quantity: 225,
	},
	{
		garage_id: 44,
		part_id: 350,
		quantity: 115,
	},
	{
		garage_id: 44,
		part_id: 351,
		quantity: 41,
	},
	{
		garage_id: 44,
		part_id: 352,
		quantity: 290,
	},
	{
		garage_id: 44,
		part_id: 353,
		quantity: 240,
	},
	{
		garage_id: 44,
		part_id: 354,
		quantity: 264,
	},
	{
		garage_id: 44,
		part_id: 355,
		quantity: 260,
	},
	{
		garage_id: 44,
		part_id: 356,
		quantity: 423,
	},
	{
		garage_id: 44,
		part_id: 357,
		quantity: 235,
	},
	{
		garage_id: 44,
		part_id: 358,
		quantity: 283,
	},
	{
		garage_id: 44,
		part_id: 359,
		quantity: 419,
	},
	{
		garage_id: 44,
		part_id: 360,
		quantity: 165,
	},
	{
		garage_id: 44,
		part_id: 361,
		quantity: 107,
	},
	{
		garage_id: 44,
		part_id: 362,
		quantity: 101,
	},
	{
		garage_id: 44,
		part_id: 363,
		quantity: 288,
	},
	{
		garage_id: 44,
		part_id: 364,
		quantity: 176,
	},
	{
		garage_id: 44,
		part_id: 365,
		quantity: 221,
	},
	{
		garage_id: 44,
		part_id: 366,
		quantity: 408,
	},
	{
		garage_id: 44,
		part_id: 367,
		quantity: 267,
	},
	{
		garage_id: 44,
		part_id: 368,
		quantity: 94,
	},
	{
		garage_id: 44,
		part_id: 369,
		quantity: 117,
	},
	{
		garage_id: 44,
		part_id: 370,
		quantity: 229,
	},
	{
		garage_id: 44,
		part_id: 371,
		quantity: 38,
	},
	{
		garage_id: 44,
		part_id: 372,
		quantity: 38,
	},
	{
		garage_id: 44,
		part_id: 373,
		quantity: 341,
	},
	{
		garage_id: 44,
		part_id: 374,
		quantity: 404,
	},
	{
		garage_id: 44,
		part_id: 375,
		quantity: 362,
	},
	{
		garage_id: 44,
		part_id: 376,
		quantity: 245,
	},
	{
		garage_id: 44,
		part_id: 377,
		quantity: 190,
	},
	{
		garage_id: 44,
		part_id: 378,
		quantity: 442,
	},
	{
		garage_id: 44,
		part_id: 379,
		quantity: 498,
	},
	{
		garage_id: 44,
		part_id: 380,
		quantity: 352,
	},
	{
		garage_id: 44,
		part_id: 381,
		quantity: 244,
	},
	{
		garage_id: 44,
		part_id: 382,
		quantity: 201,
	},
	{
		garage_id: 44,
		part_id: 383,
		quantity: 112,
	},
	{
		garage_id: 44,
		part_id: 384,
		quantity: 331,
	},
	{
		garage_id: 44,
		part_id: 385,
		quantity: 302,
	},
	{
		garage_id: 44,
		part_id: 386,
		quantity: 185,
	},
	{
		garage_id: 44,
		part_id: 387,
		quantity: 107,
	},
	{
		garage_id: 44,
		part_id: 388,
		quantity: 340,
	},
	{
		garage_id: 44,
		part_id: 389,
		quantity: 77,
	},
	{
		garage_id: 44,
		part_id: 390,
		quantity: 375,
	},
	{
		garage_id: 44,
		part_id: 391,
		quantity: 44,
	},
	{
		garage_id: 44,
		part_id: 392,
		quantity: 53,
	},
	{
		garage_id: 44,
		part_id: 393,
		quantity: 160,
	},
	{
		garage_id: 44,
		part_id: 394,
		quantity: 374,
	},
	{
		garage_id: 44,
		part_id: 395,
		quantity: 327,
	},
	{
		garage_id: 44,
		part_id: 396,
		quantity: 368,
	},
	{
		garage_id: 44,
		part_id: 397,
		quantity: 47,
	},
	{
		garage_id: 44,
		part_id: 398,
		quantity: 109,
	},
	{
		garage_id: 44,
		part_id: 399,
		quantity: 17,
	},
	{
		garage_id: 45,
		part_id: 0,
		quantity: 385,
	},
	{
		garage_id: 45,
		part_id: 1,
		quantity: 73,
	},
	{
		garage_id: 45,
		part_id: 2,
		quantity: 430,
	},
	{
		garage_id: 45,
		part_id: 3,
		quantity: 245,
	},
	{
		garage_id: 45,
		part_id: 4,
		quantity: 155,
	},
	{
		garage_id: 45,
		part_id: 5,
		quantity: 375,
	},
	{
		garage_id: 45,
		part_id: 6,
		quantity: 217,
	},
	{
		garage_id: 45,
		part_id: 7,
		quantity: 450,
	},
	{
		garage_id: 45,
		part_id: 8,
		quantity: 200,
	},
	{
		garage_id: 45,
		part_id: 9,
		quantity: 154,
	},
	{
		garage_id: 45,
		part_id: 10,
		quantity: 309,
	},
	{
		garage_id: 45,
		part_id: 11,
		quantity: 292,
	},
	{
		garage_id: 45,
		part_id: 12,
		quantity: 283,
	},
	{
		garage_id: 45,
		part_id: 13,
		quantity: 295,
	},
	{
		garage_id: 45,
		part_id: 14,
		quantity: 167,
	},
	{
		garage_id: 45,
		part_id: 15,
		quantity: 314,
	},
	{
		garage_id: 45,
		part_id: 16,
		quantity: 347,
	},
	{
		garage_id: 45,
		part_id: 17,
		quantity: 163,
	},
	{
		garage_id: 45,
		part_id: 18,
		quantity: 214,
	},
	{
		garage_id: 45,
		part_id: 19,
		quantity: 77,
	},
	{
		garage_id: 45,
		part_id: 20,
		quantity: 262,
	},
	{
		garage_id: 45,
		part_id: 21,
		quantity: 233,
	},
	{
		garage_id: 45,
		part_id: 22,
		quantity: 190,
	},
	{
		garage_id: 45,
		part_id: 23,
		quantity: 32,
	},
	{
		garage_id: 45,
		part_id: 24,
		quantity: 134,
	},
	{
		garage_id: 45,
		part_id: 25,
		quantity: 119,
	},
	{
		garage_id: 45,
		part_id: 26,
		quantity: 143,
	},
	{
		garage_id: 45,
		part_id: 27,
		quantity: 76,
	},
	{
		garage_id: 45,
		part_id: 28,
		quantity: 263,
	},
	{
		garage_id: 45,
		part_id: 29,
		quantity: 374,
	},
	{
		garage_id: 45,
		part_id: 30,
		quantity: 221,
	},
	{
		garage_id: 45,
		part_id: 31,
		quantity: 373,
	},
	{
		garage_id: 45,
		part_id: 32,
		quantity: 213,
	},
	{
		garage_id: 45,
		part_id: 33,
		quantity: 426,
	},
	{
		garage_id: 45,
		part_id: 34,
		quantity: 32,
	},
	{
		garage_id: 45,
		part_id: 35,
		quantity: 312,
	},
	{
		garage_id: 45,
		part_id: 36,
		quantity: 79,
	},
	{
		garage_id: 45,
		part_id: 37,
		quantity: 359,
	},
	{
		garage_id: 45,
		part_id: 38,
		quantity: 465,
	},
	{
		garage_id: 45,
		part_id: 39,
		quantity: 385,
	},
	{
		garage_id: 45,
		part_id: 40,
		quantity: 396,
	},
	{
		garage_id: 45,
		part_id: 41,
		quantity: 380,
	},
	{
		garage_id: 45,
		part_id: 42,
		quantity: 57,
	},
	{
		garage_id: 45,
		part_id: 43,
		quantity: 5,
	},
	{
		garage_id: 45,
		part_id: 44,
		quantity: 402,
	},
	{
		garage_id: 45,
		part_id: 45,
		quantity: 89,
	},
	{
		garage_id: 45,
		part_id: 46,
		quantity: 412,
	},
	{
		garage_id: 45,
		part_id: 47,
		quantity: 317,
	},
	{
		garage_id: 45,
		part_id: 48,
		quantity: 373,
	},
	{
		garage_id: 45,
		part_id: 49,
		quantity: 298,
	},
	{
		garage_id: 45,
		part_id: 50,
		quantity: 388,
	},
	{
		garage_id: 45,
		part_id: 51,
		quantity: 160,
	},
	{
		garage_id: 45,
		part_id: 52,
		quantity: 251,
	},
	{
		garage_id: 45,
		part_id: 53,
		quantity: 69,
	},
	{
		garage_id: 45,
		part_id: 54,
		quantity: 75,
	},
	{
		garage_id: 45,
		part_id: 55,
		quantity: 313,
	},
	{
		garage_id: 45,
		part_id: 56,
		quantity: 98,
	},
	{
		garage_id: 45,
		part_id: 57,
		quantity: 167,
	},
	{
		garage_id: 45,
		part_id: 58,
		quantity: 169,
	},
	{
		garage_id: 45,
		part_id: 59,
		quantity: 103,
	},
	{
		garage_id: 45,
		part_id: 60,
		quantity: 473,
	},
	{
		garage_id: 45,
		part_id: 61,
		quantity: 103,
	},
	{
		garage_id: 45,
		part_id: 62,
		quantity: 361,
	},
	{
		garage_id: 45,
		part_id: 63,
		quantity: 212,
	},
	{
		garage_id: 45,
		part_id: 64,
		quantity: 345,
	},
	{
		garage_id: 45,
		part_id: 65,
		quantity: 7,
	},
	{
		garage_id: 45,
		part_id: 66,
		quantity: 333,
	},
	{
		garage_id: 45,
		part_id: 67,
		quantity: 336,
	},
	{
		garage_id: 45,
		part_id: 68,
		quantity: 171,
	},
	{
		garage_id: 45,
		part_id: 69,
		quantity: 112,
	},
	{
		garage_id: 45,
		part_id: 70,
		quantity: 203,
	},
	{
		garage_id: 45,
		part_id: 71,
		quantity: 320,
	},
	{
		garage_id: 45,
		part_id: 72,
		quantity: 439,
	},
	{
		garage_id: 45,
		part_id: 73,
		quantity: 407,
	},
	{
		garage_id: 45,
		part_id: 74,
		quantity: 454,
	},
	{
		garage_id: 45,
		part_id: 75,
		quantity: 309,
	},
	{
		garage_id: 45,
		part_id: 76,
		quantity: 467,
	},
	{
		garage_id: 45,
		part_id: 77,
		quantity: 361,
	},
	{
		garage_id: 45,
		part_id: 78,
		quantity: 262,
	},
	{
		garage_id: 45,
		part_id: 79,
		quantity: 178,
	},
	{
		garage_id: 45,
		part_id: 80,
		quantity: 483,
	},
	{
		garage_id: 45,
		part_id: 81,
		quantity: 255,
	},
	{
		garage_id: 45,
		part_id: 82,
		quantity: 418,
	},
	{
		garage_id: 45,
		part_id: 83,
		quantity: 22,
	},
	{
		garage_id: 45,
		part_id: 84,
		quantity: 451,
	},
	{
		garage_id: 45,
		part_id: 85,
		quantity: 144,
	},
	{
		garage_id: 45,
		part_id: 86,
		quantity: 124,
	},
	{
		garage_id: 45,
		part_id: 87,
		quantity: 2,
	},
	{
		garage_id: 45,
		part_id: 88,
		quantity: 332,
	},
	{
		garage_id: 45,
		part_id: 89,
		quantity: 69,
	},
	{
		garage_id: 45,
		part_id: 90,
		quantity: 459,
	},
	{
		garage_id: 45,
		part_id: 91,
		quantity: 327,
	},
	{
		garage_id: 45,
		part_id: 92,
		quantity: 385,
	},
	{
		garage_id: 45,
		part_id: 93,
		quantity: 107,
	},
	{
		garage_id: 45,
		part_id: 94,
		quantity: 400,
	},
	{
		garage_id: 45,
		part_id: 95,
		quantity: 286,
	},
	{
		garage_id: 45,
		part_id: 96,
		quantity: 183,
	},
	{
		garage_id: 45,
		part_id: 97,
		quantity: 475,
	},
	{
		garage_id: 45,
		part_id: 98,
		quantity: 152,
	},
	{
		garage_id: 45,
		part_id: 99,
		quantity: 163,
	},
	{
		garage_id: 45,
		part_id: 100,
		quantity: 33,
	},
	{
		garage_id: 45,
		part_id: 101,
		quantity: 156,
	},
	{
		garage_id: 45,
		part_id: 102,
		quantity: 266,
	},
	{
		garage_id: 45,
		part_id: 103,
		quantity: 33,
	},
	{
		garage_id: 45,
		part_id: 104,
		quantity: 281,
	},
	{
		garage_id: 45,
		part_id: 105,
		quantity: 419,
	},
	{
		garage_id: 45,
		part_id: 106,
		quantity: 402,
	},
	{
		garage_id: 45,
		part_id: 107,
		quantity: 55,
	},
	{
		garage_id: 45,
		part_id: 108,
		quantity: 147,
	},
	{
		garage_id: 45,
		part_id: 109,
		quantity: 232,
	},
	{
		garage_id: 45,
		part_id: 110,
		quantity: 467,
	},
	{
		garage_id: 45,
		part_id: 111,
		quantity: 373,
	},
	{
		garage_id: 45,
		part_id: 112,
		quantity: 291,
	},
	{
		garage_id: 45,
		part_id: 113,
		quantity: 68,
	},
	{
		garage_id: 45,
		part_id: 114,
		quantity: 344,
	},
	{
		garage_id: 45,
		part_id: 115,
		quantity: 481,
	},
	{
		garage_id: 45,
		part_id: 116,
		quantity: 67,
	},
	{
		garage_id: 45,
		part_id: 117,
		quantity: 61,
	},
	{
		garage_id: 45,
		part_id: 118,
		quantity: 216,
	},
	{
		garage_id: 45,
		part_id: 119,
		quantity: 404,
	},
	{
		garage_id: 45,
		part_id: 120,
		quantity: 489,
	},
	{
		garage_id: 45,
		part_id: 121,
		quantity: 0,
	},
	{
		garage_id: 45,
		part_id: 122,
		quantity: 476,
	},
	{
		garage_id: 45,
		part_id: 123,
		quantity: 321,
	},
	{
		garage_id: 45,
		part_id: 124,
		quantity: 399,
	},
	{
		garage_id: 45,
		part_id: 125,
		quantity: 16,
	},
	{
		garage_id: 45,
		part_id: 126,
		quantity: 289,
	},
	{
		garage_id: 45,
		part_id: 127,
		quantity: 23,
	},
	{
		garage_id: 45,
		part_id: 128,
		quantity: 365,
	},
	{
		garage_id: 45,
		part_id: 129,
		quantity: 361,
	},
	{
		garage_id: 45,
		part_id: 130,
		quantity: 26,
	},
	{
		garage_id: 45,
		part_id: 131,
		quantity: 28,
	},
	{
		garage_id: 45,
		part_id: 132,
		quantity: 470,
	},
	{
		garage_id: 45,
		part_id: 133,
		quantity: 376,
	},
	{
		garage_id: 45,
		part_id: 134,
		quantity: 108,
	},
	{
		garage_id: 45,
		part_id: 135,
		quantity: 238,
	},
	{
		garage_id: 45,
		part_id: 136,
		quantity: 255,
	},
	{
		garage_id: 45,
		part_id: 137,
		quantity: 126,
	},
	{
		garage_id: 45,
		part_id: 138,
		quantity: 361,
	},
	{
		garage_id: 45,
		part_id: 139,
		quantity: 11,
	},
	{
		garage_id: 45,
		part_id: 140,
		quantity: 281,
	},
	{
		garage_id: 45,
		part_id: 141,
		quantity: 227,
	},
	{
		garage_id: 45,
		part_id: 142,
		quantity: 34,
	},
	{
		garage_id: 45,
		part_id: 143,
		quantity: 40,
	},
	{
		garage_id: 45,
		part_id: 144,
		quantity: 139,
	},
	{
		garage_id: 45,
		part_id: 145,
		quantity: 477,
	},
	{
		garage_id: 45,
		part_id: 146,
		quantity: 34,
	},
	{
		garage_id: 45,
		part_id: 147,
		quantity: 460,
	},
	{
		garage_id: 45,
		part_id: 148,
		quantity: 93,
	},
	{
		garage_id: 45,
		part_id: 149,
		quantity: 184,
	},
	{
		garage_id: 45,
		part_id: 150,
		quantity: 334,
	},
	{
		garage_id: 45,
		part_id: 151,
		quantity: 121,
	},
	{
		garage_id: 45,
		part_id: 152,
		quantity: 415,
	},
	{
		garage_id: 45,
		part_id: 153,
		quantity: 450,
	},
	{
		garage_id: 45,
		part_id: 154,
		quantity: 384,
	},
	{
		garage_id: 45,
		part_id: 155,
		quantity: 274,
	},
	{
		garage_id: 45,
		part_id: 156,
		quantity: 107,
	},
	{
		garage_id: 45,
		part_id: 157,
		quantity: 297,
	},
	{
		garage_id: 45,
		part_id: 158,
		quantity: 344,
	},
	{
		garage_id: 45,
		part_id: 159,
		quantity: 252,
	},
	{
		garage_id: 45,
		part_id: 160,
		quantity: 143,
	},
	{
		garage_id: 45,
		part_id: 161,
		quantity: 218,
	},
	{
		garage_id: 45,
		part_id: 162,
		quantity: 373,
	},
	{
		garage_id: 45,
		part_id: 163,
		quantity: 295,
	},
	{
		garage_id: 45,
		part_id: 164,
		quantity: 187,
	},
	{
		garage_id: 45,
		part_id: 165,
		quantity: 305,
	},
	{
		garage_id: 45,
		part_id: 166,
		quantity: 221,
	},
	{
		garage_id: 45,
		part_id: 167,
		quantity: 64,
	},
	{
		garage_id: 45,
		part_id: 168,
		quantity: 126,
	},
	{
		garage_id: 45,
		part_id: 169,
		quantity: 253,
	},
	{
		garage_id: 45,
		part_id: 170,
		quantity: 496,
	},
	{
		garage_id: 45,
		part_id: 171,
		quantity: 165,
	},
	{
		garage_id: 45,
		part_id: 172,
		quantity: 184,
	},
	{
		garage_id: 45,
		part_id: 173,
		quantity: 271,
	},
	{
		garage_id: 45,
		part_id: 174,
		quantity: 459,
	},
	{
		garage_id: 45,
		part_id: 175,
		quantity: 419,
	},
	{
		garage_id: 45,
		part_id: 176,
		quantity: 70,
	},
	{
		garage_id: 45,
		part_id: 177,
		quantity: 84,
	},
	{
		garage_id: 45,
		part_id: 178,
		quantity: 19,
	},
	{
		garage_id: 45,
		part_id: 179,
		quantity: 141,
	},
	{
		garage_id: 45,
		part_id: 180,
		quantity: 96,
	},
	{
		garage_id: 45,
		part_id: 181,
		quantity: 156,
	},
	{
		garage_id: 45,
		part_id: 182,
		quantity: 122,
	},
	{
		garage_id: 45,
		part_id: 183,
		quantity: 192,
	},
	{
		garage_id: 45,
		part_id: 184,
		quantity: 135,
	},
	{
		garage_id: 45,
		part_id: 185,
		quantity: 281,
	},
	{
		garage_id: 45,
		part_id: 186,
		quantity: 114,
	},
	{
		garage_id: 45,
		part_id: 187,
		quantity: 9,
	},
	{
		garage_id: 45,
		part_id: 188,
		quantity: 336,
	},
	{
		garage_id: 45,
		part_id: 189,
		quantity: 77,
	},
	{
		garage_id: 45,
		part_id: 190,
		quantity: 150,
	},
	{
		garage_id: 45,
		part_id: 191,
		quantity: 124,
	},
	{
		garage_id: 45,
		part_id: 192,
		quantity: 311,
	},
	{
		garage_id: 45,
		part_id: 193,
		quantity: 20,
	},
	{
		garage_id: 45,
		part_id: 194,
		quantity: 294,
	},
	{
		garage_id: 45,
		part_id: 195,
		quantity: 340,
	},
	{
		garage_id: 45,
		part_id: 196,
		quantity: 111,
	},
	{
		garage_id: 45,
		part_id: 197,
		quantity: 8,
	},
	{
		garage_id: 45,
		part_id: 198,
		quantity: 188,
	},
	{
		garage_id: 45,
		part_id: 199,
		quantity: 388,
	},
	{
		garage_id: 45,
		part_id: 200,
		quantity: 331,
	},
	{
		garage_id: 45,
		part_id: 201,
		quantity: 472,
	},
	{
		garage_id: 45,
		part_id: 202,
		quantity: 73,
	},
	{
		garage_id: 45,
		part_id: 203,
		quantity: 433,
	},
	{
		garage_id: 45,
		part_id: 204,
		quantity: 357,
	},
	{
		garage_id: 45,
		part_id: 205,
		quantity: 225,
	},
	{
		garage_id: 45,
		part_id: 206,
		quantity: 465,
	},
	{
		garage_id: 45,
		part_id: 207,
		quantity: 112,
	},
	{
		garage_id: 45,
		part_id: 208,
		quantity: 135,
	},
	{
		garage_id: 45,
		part_id: 209,
		quantity: 228,
	},
	{
		garage_id: 45,
		part_id: 210,
		quantity: 314,
	},
	{
		garage_id: 45,
		part_id: 211,
		quantity: 409,
	},
	{
		garage_id: 45,
		part_id: 212,
		quantity: 49,
	},
	{
		garage_id: 45,
		part_id: 213,
		quantity: 256,
	},
	{
		garage_id: 45,
		part_id: 214,
		quantity: 293,
	},
	{
		garage_id: 45,
		part_id: 215,
		quantity: 14,
	},
	{
		garage_id: 45,
		part_id: 216,
		quantity: 485,
	},
	{
		garage_id: 45,
		part_id: 217,
		quantity: 89,
	},
	{
		garage_id: 45,
		part_id: 218,
		quantity: 66,
	},
	{
		garage_id: 45,
		part_id: 219,
		quantity: 149,
	},
	{
		garage_id: 45,
		part_id: 220,
		quantity: 372,
	},
	{
		garage_id: 45,
		part_id: 221,
		quantity: 465,
	},
	{
		garage_id: 45,
		part_id: 222,
		quantity: 398,
	},
	{
		garage_id: 45,
		part_id: 223,
		quantity: 434,
	},
	{
		garage_id: 45,
		part_id: 224,
		quantity: 286,
	},
	{
		garage_id: 45,
		part_id: 225,
		quantity: 149,
	},
	{
		garage_id: 45,
		part_id: 226,
		quantity: 294,
	},
	{
		garage_id: 45,
		part_id: 227,
		quantity: 476,
	},
	{
		garage_id: 45,
		part_id: 228,
		quantity: 116,
	},
	{
		garage_id: 45,
		part_id: 229,
		quantity: 249,
	},
	{
		garage_id: 45,
		part_id: 230,
		quantity: 15,
	},
	{
		garage_id: 45,
		part_id: 231,
		quantity: 234,
	},
	{
		garage_id: 45,
		part_id: 232,
		quantity: 415,
	},
	{
		garage_id: 45,
		part_id: 233,
		quantity: 352,
	},
	{
		garage_id: 45,
		part_id: 234,
		quantity: 408,
	},
	{
		garage_id: 45,
		part_id: 235,
		quantity: 479,
	},
	{
		garage_id: 45,
		part_id: 236,
		quantity: 254,
	},
	{
		garage_id: 45,
		part_id: 237,
		quantity: 141,
	},
	{
		garage_id: 45,
		part_id: 238,
		quantity: 137,
	},
	{
		garage_id: 45,
		part_id: 239,
		quantity: 70,
	},
	{
		garage_id: 45,
		part_id: 240,
		quantity: 341,
	},
	{
		garage_id: 45,
		part_id: 241,
		quantity: 480,
	},
	{
		garage_id: 45,
		part_id: 242,
		quantity: 461,
	},
	{
		garage_id: 45,
		part_id: 243,
		quantity: 477,
	},
	{
		garage_id: 45,
		part_id: 244,
		quantity: 15,
	},
	{
		garage_id: 45,
		part_id: 245,
		quantity: 279,
	},
	{
		garage_id: 45,
		part_id: 246,
		quantity: 140,
	},
	{
		garage_id: 45,
		part_id: 247,
		quantity: 138,
	},
	{
		garage_id: 45,
		part_id: 248,
		quantity: 148,
	},
	{
		garage_id: 45,
		part_id: 249,
		quantity: 137,
	},
	{
		garage_id: 45,
		part_id: 250,
		quantity: 16,
	},
	{
		garage_id: 45,
		part_id: 251,
		quantity: 337,
	},
	{
		garage_id: 45,
		part_id: 252,
		quantity: 101,
	},
	{
		garage_id: 45,
		part_id: 253,
		quantity: 500,
	},
	{
		garage_id: 45,
		part_id: 254,
		quantity: 233,
	},
	{
		garage_id: 45,
		part_id: 255,
		quantity: 43,
	},
	{
		garage_id: 45,
		part_id: 256,
		quantity: 28,
	},
	{
		garage_id: 45,
		part_id: 257,
		quantity: 432,
	},
	{
		garage_id: 45,
		part_id: 258,
		quantity: 470,
	},
	{
		garage_id: 45,
		part_id: 259,
		quantity: 357,
	},
	{
		garage_id: 45,
		part_id: 260,
		quantity: 385,
	},
	{
		garage_id: 45,
		part_id: 261,
		quantity: 113,
	},
	{
		garage_id: 45,
		part_id: 262,
		quantity: 394,
	},
	{
		garage_id: 45,
		part_id: 263,
		quantity: 254,
	},
	{
		garage_id: 45,
		part_id: 264,
		quantity: 291,
	},
	{
		garage_id: 45,
		part_id: 265,
		quantity: 467,
	},
	{
		garage_id: 45,
		part_id: 266,
		quantity: 333,
	},
	{
		garage_id: 45,
		part_id: 267,
		quantity: 173,
	},
	{
		garage_id: 45,
		part_id: 268,
		quantity: 321,
	},
	{
		garage_id: 45,
		part_id: 269,
		quantity: 464,
	},
	{
		garage_id: 45,
		part_id: 270,
		quantity: 232,
	},
	{
		garage_id: 45,
		part_id: 271,
		quantity: 424,
	},
	{
		garage_id: 45,
		part_id: 272,
		quantity: 42,
	},
	{
		garage_id: 45,
		part_id: 273,
		quantity: 337,
	},
	{
		garage_id: 45,
		part_id: 274,
		quantity: 393,
	},
	{
		garage_id: 45,
		part_id: 275,
		quantity: 84,
	},
	{
		garage_id: 45,
		part_id: 276,
		quantity: 276,
	},
	{
		garage_id: 45,
		part_id: 277,
		quantity: 78,
	},
	{
		garage_id: 45,
		part_id: 278,
		quantity: 34,
	},
	{
		garage_id: 45,
		part_id: 279,
		quantity: 241,
	},
	{
		garage_id: 45,
		part_id: 280,
		quantity: 441,
	},
	{
		garage_id: 45,
		part_id: 281,
		quantity: 105,
	},
	{
		garage_id: 45,
		part_id: 282,
		quantity: 388,
	},
	{
		garage_id: 45,
		part_id: 283,
		quantity: 185,
	},
	{
		garage_id: 45,
		part_id: 284,
		quantity: 96,
	},
	{
		garage_id: 45,
		part_id: 285,
		quantity: 431,
	},
	{
		garage_id: 45,
		part_id: 286,
		quantity: 134,
	},
	{
		garage_id: 45,
		part_id: 287,
		quantity: 442,
	},
	{
		garage_id: 45,
		part_id: 288,
		quantity: 382,
	},
	{
		garage_id: 45,
		part_id: 289,
		quantity: 452,
	},
	{
		garage_id: 45,
		part_id: 290,
		quantity: 187,
	},
	{
		garage_id: 45,
		part_id: 291,
		quantity: 423,
	},
	{
		garage_id: 45,
		part_id: 292,
		quantity: 459,
	},
	{
		garage_id: 45,
		part_id: 293,
		quantity: 270,
	},
	{
		garage_id: 45,
		part_id: 294,
		quantity: 434,
	},
	{
		garage_id: 45,
		part_id: 295,
		quantity: 40,
	},
	{
		garage_id: 45,
		part_id: 296,
		quantity: 34,
	},
	{
		garage_id: 45,
		part_id: 297,
		quantity: 418,
	},
	{
		garage_id: 45,
		part_id: 298,
		quantity: 197,
	},
	{
		garage_id: 45,
		part_id: 299,
		quantity: 212,
	},
	{
		garage_id: 45,
		part_id: 300,
		quantity: 384,
	},
	{
		garage_id: 45,
		part_id: 301,
		quantity: 469,
	},
	{
		garage_id: 45,
		part_id: 302,
		quantity: 170,
	},
	{
		garage_id: 45,
		part_id: 303,
		quantity: 415,
	},
	{
		garage_id: 45,
		part_id: 304,
		quantity: 489,
	},
	{
		garage_id: 45,
		part_id: 305,
		quantity: 451,
	},
	{
		garage_id: 45,
		part_id: 306,
		quantity: 232,
	},
	{
		garage_id: 45,
		part_id: 307,
		quantity: 316,
	},
	{
		garage_id: 45,
		part_id: 308,
		quantity: 477,
	},
	{
		garage_id: 45,
		part_id: 309,
		quantity: 88,
	},
	{
		garage_id: 45,
		part_id: 310,
		quantity: 248,
	},
	{
		garage_id: 45,
		part_id: 311,
		quantity: 25,
	},
	{
		garage_id: 45,
		part_id: 312,
		quantity: 272,
	},
	{
		garage_id: 45,
		part_id: 313,
		quantity: 60,
	},
	{
		garage_id: 45,
		part_id: 314,
		quantity: 15,
	},
	{
		garage_id: 45,
		part_id: 315,
		quantity: 194,
	},
	{
		garage_id: 45,
		part_id: 316,
		quantity: 155,
	},
	{
		garage_id: 45,
		part_id: 317,
		quantity: 481,
	},
	{
		garage_id: 45,
		part_id: 318,
		quantity: 118,
	},
	{
		garage_id: 45,
		part_id: 319,
		quantity: 290,
	},
	{
		garage_id: 45,
		part_id: 320,
		quantity: 161,
	},
	{
		garage_id: 45,
		part_id: 321,
		quantity: 314,
	},
	{
		garage_id: 45,
		part_id: 322,
		quantity: 440,
	},
	{
		garage_id: 45,
		part_id: 323,
		quantity: 171,
	},
	{
		garage_id: 45,
		part_id: 324,
		quantity: 53,
	},
	{
		garage_id: 45,
		part_id: 325,
		quantity: 67,
	},
	{
		garage_id: 45,
		part_id: 326,
		quantity: 366,
	},
	{
		garage_id: 45,
		part_id: 327,
		quantity: 117,
	},
	{
		garage_id: 45,
		part_id: 328,
		quantity: 210,
	},
	{
		garage_id: 45,
		part_id: 329,
		quantity: 221,
	},
	{
		garage_id: 45,
		part_id: 330,
		quantity: 414,
	},
	{
		garage_id: 45,
		part_id: 331,
		quantity: 355,
	},
	{
		garage_id: 45,
		part_id: 332,
		quantity: 84,
	},
	{
		garage_id: 45,
		part_id: 333,
		quantity: 117,
	},
	{
		garage_id: 45,
		part_id: 334,
		quantity: 248,
	},
	{
		garage_id: 45,
		part_id: 335,
		quantity: 138,
	},
	{
		garage_id: 45,
		part_id: 336,
		quantity: 28,
	},
	{
		garage_id: 45,
		part_id: 337,
		quantity: 128,
	},
	{
		garage_id: 45,
		part_id: 338,
		quantity: 17,
	},
	{
		garage_id: 45,
		part_id: 339,
		quantity: 378,
	},
	{
		garage_id: 45,
		part_id: 340,
		quantity: 205,
	},
	{
		garage_id: 45,
		part_id: 341,
		quantity: 292,
	},
	{
		garage_id: 45,
		part_id: 342,
		quantity: 331,
	},
	{
		garage_id: 45,
		part_id: 343,
		quantity: 156,
	},
	{
		garage_id: 45,
		part_id: 344,
		quantity: 165,
	},
	{
		garage_id: 45,
		part_id: 345,
		quantity: 243,
	},
	{
		garage_id: 45,
		part_id: 346,
		quantity: 174,
	},
	{
		garage_id: 45,
		part_id: 347,
		quantity: 325,
	},
	{
		garage_id: 45,
		part_id: 348,
		quantity: 232,
	},
	{
		garage_id: 45,
		part_id: 349,
		quantity: 37,
	},
	{
		garage_id: 45,
		part_id: 350,
		quantity: 442,
	},
	{
		garage_id: 45,
		part_id: 351,
		quantity: 435,
	},
	{
		garage_id: 45,
		part_id: 352,
		quantity: 182,
	},
	{
		garage_id: 45,
		part_id: 353,
		quantity: 331,
	},
	{
		garage_id: 45,
		part_id: 354,
		quantity: 272,
	},
	{
		garage_id: 45,
		part_id: 355,
		quantity: 333,
	},
	{
		garage_id: 45,
		part_id: 356,
		quantity: 236,
	},
	{
		garage_id: 45,
		part_id: 357,
		quantity: 490,
	},
	{
		garage_id: 45,
		part_id: 358,
		quantity: 396,
	},
	{
		garage_id: 45,
		part_id: 359,
		quantity: 238,
	},
	{
		garage_id: 45,
		part_id: 360,
		quantity: 9,
	},
	{
		garage_id: 45,
		part_id: 361,
		quantity: 407,
	},
	{
		garage_id: 45,
		part_id: 362,
		quantity: 410,
	},
	{
		garage_id: 45,
		part_id: 363,
		quantity: 390,
	},
	{
		garage_id: 45,
		part_id: 364,
		quantity: 326,
	},
	{
		garage_id: 45,
		part_id: 365,
		quantity: 351,
	},
	{
		garage_id: 45,
		part_id: 366,
		quantity: 19,
	},
	{
		garage_id: 45,
		part_id: 367,
		quantity: 424,
	},
	{
		garage_id: 45,
		part_id: 368,
		quantity: 192,
	},
	{
		garage_id: 45,
		part_id: 369,
		quantity: 298,
	},
	{
		garage_id: 45,
		part_id: 370,
		quantity: 104,
	},
	{
		garage_id: 45,
		part_id: 371,
		quantity: 176,
	},
	{
		garage_id: 45,
		part_id: 372,
		quantity: 51,
	},
	{
		garage_id: 45,
		part_id: 373,
		quantity: 235,
	},
	{
		garage_id: 45,
		part_id: 374,
		quantity: 131,
	},
	{
		garage_id: 45,
		part_id: 375,
		quantity: 377,
	},
	{
		garage_id: 45,
		part_id: 376,
		quantity: 51,
	},
	{
		garage_id: 45,
		part_id: 377,
		quantity: 91,
	},
	{
		garage_id: 45,
		part_id: 378,
		quantity: 207,
	},
	{
		garage_id: 45,
		part_id: 379,
		quantity: 497,
	},
	{
		garage_id: 45,
		part_id: 380,
		quantity: 279,
	},
	{
		garage_id: 45,
		part_id: 381,
		quantity: 125,
	},
	{
		garage_id: 45,
		part_id: 382,
		quantity: 34,
	},
	{
		garage_id: 45,
		part_id: 383,
		quantity: 260,
	},
	{
		garage_id: 45,
		part_id: 384,
		quantity: 320,
	},
	{
		garage_id: 45,
		part_id: 385,
		quantity: 499,
	},
	{
		garage_id: 45,
		part_id: 386,
		quantity: 95,
	},
	{
		garage_id: 45,
		part_id: 387,
		quantity: 416,
	},
	{
		garage_id: 45,
		part_id: 388,
		quantity: 72,
	},
	{
		garage_id: 45,
		part_id: 389,
		quantity: 122,
	},
	{
		garage_id: 45,
		part_id: 390,
		quantity: 347,
	},
	{
		garage_id: 45,
		part_id: 391,
		quantity: 304,
	},
	{
		garage_id: 45,
		part_id: 392,
		quantity: 288,
	},
	{
		garage_id: 45,
		part_id: 393,
		quantity: 173,
	},
	{
		garage_id: 45,
		part_id: 394,
		quantity: 62,
	},
	{
		garage_id: 45,
		part_id: 395,
		quantity: 405,
	},
	{
		garage_id: 45,
		part_id: 396,
		quantity: 86,
	},
	{
		garage_id: 45,
		part_id: 397,
		quantity: 364,
	},
	{
		garage_id: 45,
		part_id: 398,
		quantity: 272,
	},
	{
		garage_id: 45,
		part_id: 399,
		quantity: 353,
	},
	{
		garage_id: 46,
		part_id: 0,
		quantity: 111,
	},
	{
		garage_id: 46,
		part_id: 1,
		quantity: 395,
	},
	{
		garage_id: 46,
		part_id: 2,
		quantity: 181,
	},
	{
		garage_id: 46,
		part_id: 3,
		quantity: 393,
	},
	{
		garage_id: 46,
		part_id: 4,
		quantity: 104,
	},
	{
		garage_id: 46,
		part_id: 5,
		quantity: 42,
	},
	{
		garage_id: 46,
		part_id: 6,
		quantity: 285,
	},
	{
		garage_id: 46,
		part_id: 7,
		quantity: 160,
	},
	{
		garage_id: 46,
		part_id: 8,
		quantity: 174,
	},
	{
		garage_id: 46,
		part_id: 9,
		quantity: 255,
	},
	{
		garage_id: 46,
		part_id: 10,
		quantity: 224,
	},
	{
		garage_id: 46,
		part_id: 11,
		quantity: 499,
	},
	{
		garage_id: 46,
		part_id: 12,
		quantity: 186,
	},
	{
		garage_id: 46,
		part_id: 13,
		quantity: 36,
	},
	{
		garage_id: 46,
		part_id: 14,
		quantity: 389,
	},
	{
		garage_id: 46,
		part_id: 15,
		quantity: 376,
	},
	{
		garage_id: 46,
		part_id: 16,
		quantity: 436,
	},
	{
		garage_id: 46,
		part_id: 17,
		quantity: 169,
	},
	{
		garage_id: 46,
		part_id: 18,
		quantity: 149,
	},
	{
		garage_id: 46,
		part_id: 19,
		quantity: 228,
	},
	{
		garage_id: 46,
		part_id: 20,
		quantity: 105,
	},
	{
		garage_id: 46,
		part_id: 21,
		quantity: 257,
	},
	{
		garage_id: 46,
		part_id: 22,
		quantity: 408,
	},
	{
		garage_id: 46,
		part_id: 23,
		quantity: 335,
	},
	{
		garage_id: 46,
		part_id: 24,
		quantity: 338,
	},
	{
		garage_id: 46,
		part_id: 25,
		quantity: 64,
	},
	{
		garage_id: 46,
		part_id: 26,
		quantity: 131,
	},
	{
		garage_id: 46,
		part_id: 27,
		quantity: 390,
	},
	{
		garage_id: 46,
		part_id: 28,
		quantity: 459,
	},
	{
		garage_id: 46,
		part_id: 29,
		quantity: 287,
	},
	{
		garage_id: 46,
		part_id: 30,
		quantity: 138,
	},
	{
		garage_id: 46,
		part_id: 31,
		quantity: 107,
	},
	{
		garage_id: 46,
		part_id: 32,
		quantity: 183,
	},
	{
		garage_id: 46,
		part_id: 33,
		quantity: 269,
	},
	{
		garage_id: 46,
		part_id: 34,
		quantity: 340,
	},
	{
		garage_id: 46,
		part_id: 35,
		quantity: 17,
	},
	{
		garage_id: 46,
		part_id: 36,
		quantity: 254,
	},
	{
		garage_id: 46,
		part_id: 37,
		quantity: 73,
	},
	{
		garage_id: 46,
		part_id: 38,
		quantity: 499,
	},
	{
		garage_id: 46,
		part_id: 39,
		quantity: 117,
	},
	{
		garage_id: 46,
		part_id: 40,
		quantity: 399,
	},
	{
		garage_id: 46,
		part_id: 41,
		quantity: 397,
	},
	{
		garage_id: 46,
		part_id: 42,
		quantity: 391,
	},
	{
		garage_id: 46,
		part_id: 43,
		quantity: 40,
	},
	{
		garage_id: 46,
		part_id: 44,
		quantity: 332,
	},
	{
		garage_id: 46,
		part_id: 45,
		quantity: 252,
	},
	{
		garage_id: 46,
		part_id: 46,
		quantity: 177,
	},
	{
		garage_id: 46,
		part_id: 47,
		quantity: 5,
	},
	{
		garage_id: 46,
		part_id: 48,
		quantity: 129,
	},
	{
		garage_id: 46,
		part_id: 49,
		quantity: 479,
	},
	{
		garage_id: 46,
		part_id: 50,
		quantity: 21,
	},
	{
		garage_id: 46,
		part_id: 51,
		quantity: 409,
	},
	{
		garage_id: 46,
		part_id: 52,
		quantity: 343,
	},
	{
		garage_id: 46,
		part_id: 53,
		quantity: 55,
	},
	{
		garage_id: 46,
		part_id: 54,
		quantity: 447,
	},
	{
		garage_id: 46,
		part_id: 55,
		quantity: 344,
	},
	{
		garage_id: 46,
		part_id: 56,
		quantity: 441,
	},
	{
		garage_id: 46,
		part_id: 57,
		quantity: 331,
	},
	{
		garage_id: 46,
		part_id: 58,
		quantity: 74,
	},
	{
		garage_id: 46,
		part_id: 59,
		quantity: 265,
	},
	{
		garage_id: 46,
		part_id: 60,
		quantity: 25,
	},
	{
		garage_id: 46,
		part_id: 61,
		quantity: 316,
	},
	{
		garage_id: 46,
		part_id: 62,
		quantity: 153,
	},
	{
		garage_id: 46,
		part_id: 63,
		quantity: 247,
	},
	{
		garage_id: 46,
		part_id: 64,
		quantity: 299,
	},
	{
		garage_id: 46,
		part_id: 65,
		quantity: 473,
	},
	{
		garage_id: 46,
		part_id: 66,
		quantity: 113,
	},
	{
		garage_id: 46,
		part_id: 67,
		quantity: 348,
	},
	{
		garage_id: 46,
		part_id: 68,
		quantity: 421,
	},
	{
		garage_id: 46,
		part_id: 69,
		quantity: 472,
	},
	{
		garage_id: 46,
		part_id: 70,
		quantity: 325,
	},
	{
		garage_id: 46,
		part_id: 71,
		quantity: 131,
	},
	{
		garage_id: 46,
		part_id: 72,
		quantity: 435,
	},
	{
		garage_id: 46,
		part_id: 73,
		quantity: 75,
	},
	{
		garage_id: 46,
		part_id: 74,
		quantity: 398,
	},
	{
		garage_id: 46,
		part_id: 75,
		quantity: 2,
	},
	{
		garage_id: 46,
		part_id: 76,
		quantity: 179,
	},
	{
		garage_id: 46,
		part_id: 77,
		quantity: 33,
	},
	{
		garage_id: 46,
		part_id: 78,
		quantity: 331,
	},
	{
		garage_id: 46,
		part_id: 79,
		quantity: 251,
	},
	{
		garage_id: 46,
		part_id: 80,
		quantity: 231,
	},
	{
		garage_id: 46,
		part_id: 81,
		quantity: 271,
	},
	{
		garage_id: 46,
		part_id: 82,
		quantity: 440,
	},
	{
		garage_id: 46,
		part_id: 83,
		quantity: 160,
	},
	{
		garage_id: 46,
		part_id: 84,
		quantity: 444,
	},
	{
		garage_id: 46,
		part_id: 85,
		quantity: 91,
	},
	{
		garage_id: 46,
		part_id: 86,
		quantity: 196,
	},
	{
		garage_id: 46,
		part_id: 87,
		quantity: 103,
	},
	{
		garage_id: 46,
		part_id: 88,
		quantity: 237,
	},
	{
		garage_id: 46,
		part_id: 89,
		quantity: 123,
	},
	{
		garage_id: 46,
		part_id: 90,
		quantity: 31,
	},
	{
		garage_id: 46,
		part_id: 91,
		quantity: 73,
	},
	{
		garage_id: 46,
		part_id: 92,
		quantity: 113,
	},
	{
		garage_id: 46,
		part_id: 93,
		quantity: 170,
	},
	{
		garage_id: 46,
		part_id: 94,
		quantity: 174,
	},
	{
		garage_id: 46,
		part_id: 95,
		quantity: 479,
	},
	{
		garage_id: 46,
		part_id: 96,
		quantity: 458,
	},
	{
		garage_id: 46,
		part_id: 97,
		quantity: 98,
	},
	{
		garage_id: 46,
		part_id: 98,
		quantity: 97,
	},
	{
		garage_id: 46,
		part_id: 99,
		quantity: 252,
	},
	{
		garage_id: 46,
		part_id: 100,
		quantity: 68,
	},
	{
		garage_id: 46,
		part_id: 101,
		quantity: 99,
	},
	{
		garage_id: 46,
		part_id: 102,
		quantity: 41,
	},
	{
		garage_id: 46,
		part_id: 103,
		quantity: 162,
	},
	{
		garage_id: 46,
		part_id: 104,
		quantity: 255,
	},
	{
		garage_id: 46,
		part_id: 105,
		quantity: 458,
	},
	{
		garage_id: 46,
		part_id: 106,
		quantity: 22,
	},
	{
		garage_id: 46,
		part_id: 107,
		quantity: 94,
	},
	{
		garage_id: 46,
		part_id: 108,
		quantity: 305,
	},
	{
		garage_id: 46,
		part_id: 109,
		quantity: 259,
	},
	{
		garage_id: 46,
		part_id: 110,
		quantity: 131,
	},
	{
		garage_id: 46,
		part_id: 111,
		quantity: 42,
	},
	{
		garage_id: 46,
		part_id: 112,
		quantity: 165,
	},
	{
		garage_id: 46,
		part_id: 113,
		quantity: 395,
	},
	{
		garage_id: 46,
		part_id: 114,
		quantity: 2,
	},
	{
		garage_id: 46,
		part_id: 115,
		quantity: 292,
	},
	{
		garage_id: 46,
		part_id: 116,
		quantity: 51,
	},
	{
		garage_id: 46,
		part_id: 117,
		quantity: 381,
	},
	{
		garage_id: 46,
		part_id: 118,
		quantity: 69,
	},
	{
		garage_id: 46,
		part_id: 119,
		quantity: 209,
	},
	{
		garage_id: 46,
		part_id: 120,
		quantity: 48,
	},
	{
		garage_id: 46,
		part_id: 121,
		quantity: 61,
	},
	{
		garage_id: 46,
		part_id: 122,
		quantity: 422,
	},
	{
		garage_id: 46,
		part_id: 123,
		quantity: 127,
	},
	{
		garage_id: 46,
		part_id: 124,
		quantity: 104,
	},
	{
		garage_id: 46,
		part_id: 125,
		quantity: 250,
	},
	{
		garage_id: 46,
		part_id: 126,
		quantity: 119,
	},
	{
		garage_id: 46,
		part_id: 127,
		quantity: 296,
	},
	{
		garage_id: 46,
		part_id: 128,
		quantity: 313,
	},
	{
		garage_id: 46,
		part_id: 129,
		quantity: 73,
	},
	{
		garage_id: 46,
		part_id: 130,
		quantity: 0,
	},
	{
		garage_id: 46,
		part_id: 131,
		quantity: 53,
	},
	{
		garage_id: 46,
		part_id: 132,
		quantity: 223,
	},
	{
		garage_id: 46,
		part_id: 133,
		quantity: 9,
	},
	{
		garage_id: 46,
		part_id: 134,
		quantity: 53,
	},
	{
		garage_id: 46,
		part_id: 135,
		quantity: 131,
	},
	{
		garage_id: 46,
		part_id: 136,
		quantity: 389,
	},
	{
		garage_id: 46,
		part_id: 137,
		quantity: 11,
	},
	{
		garage_id: 46,
		part_id: 138,
		quantity: 152,
	},
	{
		garage_id: 46,
		part_id: 139,
		quantity: 473,
	},
	{
		garage_id: 46,
		part_id: 140,
		quantity: 115,
	},
	{
		garage_id: 46,
		part_id: 141,
		quantity: 27,
	},
	{
		garage_id: 46,
		part_id: 142,
		quantity: 133,
	},
	{
		garage_id: 46,
		part_id: 143,
		quantity: 130,
	},
	{
		garage_id: 46,
		part_id: 144,
		quantity: 179,
	},
	{
		garage_id: 46,
		part_id: 145,
		quantity: 432,
	},
	{
		garage_id: 46,
		part_id: 146,
		quantity: 350,
	},
	{
		garage_id: 46,
		part_id: 147,
		quantity: 465,
	},
	{
		garage_id: 46,
		part_id: 148,
		quantity: 228,
	},
	{
		garage_id: 46,
		part_id: 149,
		quantity: 192,
	},
	{
		garage_id: 46,
		part_id: 150,
		quantity: 342,
	},
	{
		garage_id: 46,
		part_id: 151,
		quantity: 460,
	},
	{
		garage_id: 46,
		part_id: 152,
		quantity: 448,
	},
	{
		garage_id: 46,
		part_id: 153,
		quantity: 68,
	},
	{
		garage_id: 46,
		part_id: 154,
		quantity: 104,
	},
	{
		garage_id: 46,
		part_id: 155,
		quantity: 370,
	},
	{
		garage_id: 46,
		part_id: 156,
		quantity: 356,
	},
	{
		garage_id: 46,
		part_id: 157,
		quantity: 66,
	},
	{
		garage_id: 46,
		part_id: 158,
		quantity: 104,
	},
	{
		garage_id: 46,
		part_id: 159,
		quantity: 429,
	},
	{
		garage_id: 46,
		part_id: 160,
		quantity: 472,
	},
	{
		garage_id: 46,
		part_id: 161,
		quantity: 81,
	},
	{
		garage_id: 46,
		part_id: 162,
		quantity: 198,
	},
	{
		garage_id: 46,
		part_id: 163,
		quantity: 359,
	},
	{
		garage_id: 46,
		part_id: 164,
		quantity: 379,
	},
	{
		garage_id: 46,
		part_id: 165,
		quantity: 15,
	},
	{
		garage_id: 46,
		part_id: 166,
		quantity: 309,
	},
	{
		garage_id: 46,
		part_id: 167,
		quantity: 32,
	},
	{
		garage_id: 46,
		part_id: 168,
		quantity: 226,
	},
	{
		garage_id: 46,
		part_id: 169,
		quantity: 122,
	},
	{
		garage_id: 46,
		part_id: 170,
		quantity: 58,
	},
	{
		garage_id: 46,
		part_id: 171,
		quantity: 403,
	},
	{
		garage_id: 46,
		part_id: 172,
		quantity: 29,
	},
	{
		garage_id: 46,
		part_id: 173,
		quantity: 385,
	},
	{
		garage_id: 46,
		part_id: 174,
		quantity: 269,
	},
	{
		garage_id: 46,
		part_id: 175,
		quantity: 24,
	},
	{
		garage_id: 46,
		part_id: 176,
		quantity: 301,
	},
	{
		garage_id: 46,
		part_id: 177,
		quantity: 145,
	},
	{
		garage_id: 46,
		part_id: 178,
		quantity: 189,
	},
	{
		garage_id: 46,
		part_id: 179,
		quantity: 31,
	},
	{
		garage_id: 46,
		part_id: 180,
		quantity: 66,
	},
	{
		garage_id: 46,
		part_id: 181,
		quantity: 191,
	},
	{
		garage_id: 46,
		part_id: 182,
		quantity: 136,
	},
	{
		garage_id: 46,
		part_id: 183,
		quantity: 287,
	},
	{
		garage_id: 46,
		part_id: 184,
		quantity: 84,
	},
	{
		garage_id: 46,
		part_id: 185,
		quantity: 405,
	},
	{
		garage_id: 46,
		part_id: 186,
		quantity: 425,
	},
	{
		garage_id: 46,
		part_id: 187,
		quantity: 452,
	},
	{
		garage_id: 46,
		part_id: 188,
		quantity: 288,
	},
	{
		garage_id: 46,
		part_id: 189,
		quantity: 438,
	},
	{
		garage_id: 46,
		part_id: 190,
		quantity: 333,
	},
	{
		garage_id: 46,
		part_id: 191,
		quantity: 409,
	},
	{
		garage_id: 46,
		part_id: 192,
		quantity: 240,
	},
	{
		garage_id: 46,
		part_id: 193,
		quantity: 331,
	},
	{
		garage_id: 46,
		part_id: 194,
		quantity: 149,
	},
	{
		garage_id: 46,
		part_id: 195,
		quantity: 223,
	},
	{
		garage_id: 46,
		part_id: 196,
		quantity: 322,
	},
	{
		garage_id: 46,
		part_id: 197,
		quantity: 45,
	},
	{
		garage_id: 46,
		part_id: 198,
		quantity: 191,
	},
	{
		garage_id: 46,
		part_id: 199,
		quantity: 163,
	},
	{
		garage_id: 46,
		part_id: 200,
		quantity: 446,
	},
	{
		garage_id: 46,
		part_id: 201,
		quantity: 328,
	},
	{
		garage_id: 46,
		part_id: 202,
		quantity: 356,
	},
	{
		garage_id: 46,
		part_id: 203,
		quantity: 118,
	},
	{
		garage_id: 46,
		part_id: 204,
		quantity: 226,
	},
	{
		garage_id: 46,
		part_id: 205,
		quantity: 159,
	},
	{
		garage_id: 46,
		part_id: 206,
		quantity: 457,
	},
	{
		garage_id: 46,
		part_id: 207,
		quantity: 61,
	},
	{
		garage_id: 46,
		part_id: 208,
		quantity: 30,
	},
	{
		garage_id: 46,
		part_id: 209,
		quantity: 118,
	},
	{
		garage_id: 46,
		part_id: 210,
		quantity: 4,
	},
	{
		garage_id: 46,
		part_id: 211,
		quantity: 61,
	},
	{
		garage_id: 46,
		part_id: 212,
		quantity: 371,
	},
	{
		garage_id: 46,
		part_id: 213,
		quantity: 331,
	},
	{
		garage_id: 46,
		part_id: 214,
		quantity: 338,
	},
	{
		garage_id: 46,
		part_id: 215,
		quantity: 440,
	},
	{
		garage_id: 46,
		part_id: 216,
		quantity: 361,
	},
	{
		garage_id: 46,
		part_id: 217,
		quantity: 49,
	},
	{
		garage_id: 46,
		part_id: 218,
		quantity: 338,
	},
	{
		garage_id: 46,
		part_id: 219,
		quantity: 111,
	},
	{
		garage_id: 46,
		part_id: 220,
		quantity: 162,
	},
	{
		garage_id: 46,
		part_id: 221,
		quantity: 378,
	},
	{
		garage_id: 46,
		part_id: 222,
		quantity: 299,
	},
	{
		garage_id: 46,
		part_id: 223,
		quantity: 309,
	},
	{
		garage_id: 46,
		part_id: 224,
		quantity: 377,
	},
	{
		garage_id: 46,
		part_id: 225,
		quantity: 402,
	},
	{
		garage_id: 46,
		part_id: 226,
		quantity: 76,
	},
	{
		garage_id: 46,
		part_id: 227,
		quantity: 403,
	},
	{
		garage_id: 46,
		part_id: 228,
		quantity: 72,
	},
	{
		garage_id: 46,
		part_id: 229,
		quantity: 239,
	},
	{
		garage_id: 46,
		part_id: 230,
		quantity: 324,
	},
	{
		garage_id: 46,
		part_id: 231,
		quantity: 33,
	},
	{
		garage_id: 46,
		part_id: 232,
		quantity: 190,
	},
	{
		garage_id: 46,
		part_id: 233,
		quantity: 310,
	},
	{
		garage_id: 46,
		part_id: 234,
		quantity: 176,
	},
	{
		garage_id: 46,
		part_id: 235,
		quantity: 249,
	},
	{
		garage_id: 46,
		part_id: 236,
		quantity: 361,
	},
	{
		garage_id: 46,
		part_id: 237,
		quantity: 200,
	},
	{
		garage_id: 46,
		part_id: 238,
		quantity: 311,
	},
	{
		garage_id: 46,
		part_id: 239,
		quantity: 213,
	},
	{
		garage_id: 46,
		part_id: 240,
		quantity: 407,
	},
	{
		garage_id: 46,
		part_id: 241,
		quantity: 103,
	},
	{
		garage_id: 46,
		part_id: 242,
		quantity: 390,
	},
	{
		garage_id: 46,
		part_id: 243,
		quantity: 378,
	},
	{
		garage_id: 46,
		part_id: 244,
		quantity: 169,
	},
	{
		garage_id: 46,
		part_id: 245,
		quantity: 145,
	},
	{
		garage_id: 46,
		part_id: 246,
		quantity: 337,
	},
	{
		garage_id: 46,
		part_id: 247,
		quantity: 459,
	},
	{
		garage_id: 46,
		part_id: 248,
		quantity: 343,
	},
	{
		garage_id: 46,
		part_id: 249,
		quantity: 3,
	},
	{
		garage_id: 46,
		part_id: 250,
		quantity: 115,
	},
	{
		garage_id: 46,
		part_id: 251,
		quantity: 206,
	},
	{
		garage_id: 46,
		part_id: 252,
		quantity: 95,
	},
	{
		garage_id: 46,
		part_id: 253,
		quantity: 151,
	},
	{
		garage_id: 46,
		part_id: 254,
		quantity: 19,
	},
	{
		garage_id: 46,
		part_id: 255,
		quantity: 274,
	},
	{
		garage_id: 46,
		part_id: 256,
		quantity: 311,
	},
	{
		garage_id: 46,
		part_id: 257,
		quantity: 216,
	},
	{
		garage_id: 46,
		part_id: 258,
		quantity: 460,
	},
	{
		garage_id: 46,
		part_id: 259,
		quantity: 362,
	},
	{
		garage_id: 46,
		part_id: 260,
		quantity: 395,
	},
	{
		garage_id: 46,
		part_id: 261,
		quantity: 81,
	},
	{
		garage_id: 46,
		part_id: 262,
		quantity: 117,
	},
	{
		garage_id: 46,
		part_id: 263,
		quantity: 11,
	},
	{
		garage_id: 46,
		part_id: 264,
		quantity: 95,
	},
	{
		garage_id: 46,
		part_id: 265,
		quantity: 405,
	},
	{
		garage_id: 46,
		part_id: 266,
		quantity: 234,
	},
	{
		garage_id: 46,
		part_id: 267,
		quantity: 398,
	},
	{
		garage_id: 46,
		part_id: 268,
		quantity: 448,
	},
	{
		garage_id: 46,
		part_id: 269,
		quantity: 44,
	},
	{
		garage_id: 46,
		part_id: 270,
		quantity: 476,
	},
	{
		garage_id: 46,
		part_id: 271,
		quantity: 360,
	},
	{
		garage_id: 46,
		part_id: 272,
		quantity: 140,
	},
	{
		garage_id: 46,
		part_id: 273,
		quantity: 124,
	},
	{
		garage_id: 46,
		part_id: 274,
		quantity: 54,
	},
	{
		garage_id: 46,
		part_id: 275,
		quantity: 16,
	},
	{
		garage_id: 46,
		part_id: 276,
		quantity: 19,
	},
	{
		garage_id: 46,
		part_id: 277,
		quantity: 260,
	},
	{
		garage_id: 46,
		part_id: 278,
		quantity: 69,
	},
	{
		garage_id: 46,
		part_id: 279,
		quantity: 405,
	},
	{
		garage_id: 46,
		part_id: 280,
		quantity: 473,
	},
	{
		garage_id: 46,
		part_id: 281,
		quantity: 77,
	},
	{
		garage_id: 46,
		part_id: 282,
		quantity: 141,
	},
	{
		garage_id: 46,
		part_id: 283,
		quantity: 180,
	},
	{
		garage_id: 46,
		part_id: 284,
		quantity: 13,
	},
	{
		garage_id: 46,
		part_id: 285,
		quantity: 88,
	},
	{
		garage_id: 46,
		part_id: 286,
		quantity: 366,
	},
	{
		garage_id: 46,
		part_id: 287,
		quantity: 173,
	},
	{
		garage_id: 46,
		part_id: 288,
		quantity: 441,
	},
	{
		garage_id: 46,
		part_id: 289,
		quantity: 428,
	},
	{
		garage_id: 46,
		part_id: 290,
		quantity: 410,
	},
	{
		garage_id: 46,
		part_id: 291,
		quantity: 310,
	},
	{
		garage_id: 46,
		part_id: 292,
		quantity: 47,
	},
	{
		garage_id: 46,
		part_id: 293,
		quantity: 93,
	},
	{
		garage_id: 46,
		part_id: 294,
		quantity: 296,
	},
	{
		garage_id: 46,
		part_id: 295,
		quantity: 265,
	},
	{
		garage_id: 46,
		part_id: 296,
		quantity: 243,
	},
	{
		garage_id: 46,
		part_id: 297,
		quantity: 364,
	},
	{
		garage_id: 46,
		part_id: 298,
		quantity: 486,
	},
	{
		garage_id: 46,
		part_id: 299,
		quantity: 110,
	},
	{
		garage_id: 46,
		part_id: 300,
		quantity: 271,
	},
	{
		garage_id: 46,
		part_id: 301,
		quantity: 139,
	},
	{
		garage_id: 46,
		part_id: 302,
		quantity: 150,
	},
	{
		garage_id: 46,
		part_id: 303,
		quantity: 174,
	},
	{
		garage_id: 46,
		part_id: 304,
		quantity: 40,
	},
	{
		garage_id: 46,
		part_id: 305,
		quantity: 139,
	},
	{
		garage_id: 46,
		part_id: 306,
		quantity: 307,
	},
	{
		garage_id: 46,
		part_id: 307,
		quantity: 386,
	},
	{
		garage_id: 46,
		part_id: 308,
		quantity: 452,
	},
	{
		garage_id: 46,
		part_id: 309,
		quantity: 126,
	},
	{
		garage_id: 46,
		part_id: 310,
		quantity: 53,
	},
	{
		garage_id: 46,
		part_id: 311,
		quantity: 277,
	},
	{
		garage_id: 46,
		part_id: 312,
		quantity: 259,
	},
	{
		garage_id: 46,
		part_id: 313,
		quantity: 484,
	},
	{
		garage_id: 46,
		part_id: 314,
		quantity: 172,
	},
	{
		garage_id: 46,
		part_id: 315,
		quantity: 218,
	},
	{
		garage_id: 46,
		part_id: 316,
		quantity: 44,
	},
	{
		garage_id: 46,
		part_id: 317,
		quantity: 197,
	},
	{
		garage_id: 46,
		part_id: 318,
		quantity: 369,
	},
	{
		garage_id: 46,
		part_id: 319,
		quantity: 393,
	},
	{
		garage_id: 46,
		part_id: 320,
		quantity: 476,
	},
	{
		garage_id: 46,
		part_id: 321,
		quantity: 482,
	},
	{
		garage_id: 46,
		part_id: 322,
		quantity: 307,
	},
	{
		garage_id: 46,
		part_id: 323,
		quantity: 339,
	},
	{
		garage_id: 46,
		part_id: 324,
		quantity: 245,
	},
	{
		garage_id: 46,
		part_id: 325,
		quantity: 389,
	},
	{
		garage_id: 46,
		part_id: 326,
		quantity: 50,
	},
	{
		garage_id: 46,
		part_id: 327,
		quantity: 348,
	},
	{
		garage_id: 46,
		part_id: 328,
		quantity: 404,
	},
	{
		garage_id: 46,
		part_id: 329,
		quantity: 320,
	},
	{
		garage_id: 46,
		part_id: 330,
		quantity: 90,
	},
	{
		garage_id: 46,
		part_id: 331,
		quantity: 157,
	},
	{
		garage_id: 46,
		part_id: 332,
		quantity: 133,
	},
	{
		garage_id: 46,
		part_id: 333,
		quantity: 146,
	},
	{
		garage_id: 46,
		part_id: 334,
		quantity: 119,
	},
	{
		garage_id: 46,
		part_id: 335,
		quantity: 114,
	},
	{
		garage_id: 46,
		part_id: 336,
		quantity: 286,
	},
	{
		garage_id: 46,
		part_id: 337,
		quantity: 68,
	},
	{
		garage_id: 46,
		part_id: 338,
		quantity: 263,
	},
	{
		garage_id: 46,
		part_id: 339,
		quantity: 483,
	},
	{
		garage_id: 46,
		part_id: 340,
		quantity: 169,
	},
	{
		garage_id: 46,
		part_id: 341,
		quantity: 144,
	},
	{
		garage_id: 46,
		part_id: 342,
		quantity: 186,
	},
	{
		garage_id: 46,
		part_id: 343,
		quantity: 280,
	},
	{
		garage_id: 46,
		part_id: 344,
		quantity: 88,
	},
	{
		garage_id: 46,
		part_id: 345,
		quantity: 337,
	},
	{
		garage_id: 46,
		part_id: 346,
		quantity: 205,
	},
	{
		garage_id: 46,
		part_id: 347,
		quantity: 94,
	},
	{
		garage_id: 46,
		part_id: 348,
		quantity: 237,
	},
	{
		garage_id: 46,
		part_id: 349,
		quantity: 38,
	},
	{
		garage_id: 46,
		part_id: 350,
		quantity: 172,
	},
	{
		garage_id: 46,
		part_id: 351,
		quantity: 279,
	},
	{
		garage_id: 46,
		part_id: 352,
		quantity: 286,
	},
	{
		garage_id: 46,
		part_id: 353,
		quantity: 79,
	},
	{
		garage_id: 46,
		part_id: 354,
		quantity: 408,
	},
	{
		garage_id: 46,
		part_id: 355,
		quantity: 349,
	},
	{
		garage_id: 46,
		part_id: 356,
		quantity: 309,
	},
	{
		garage_id: 46,
		part_id: 357,
		quantity: 351,
	},
	{
		garage_id: 46,
		part_id: 358,
		quantity: 208,
	},
	{
		garage_id: 46,
		part_id: 359,
		quantity: 58,
	},
	{
		garage_id: 46,
		part_id: 360,
		quantity: 388,
	},
	{
		garage_id: 46,
		part_id: 361,
		quantity: 417,
	},
	{
		garage_id: 46,
		part_id: 362,
		quantity: 46,
	},
	{
		garage_id: 46,
		part_id: 363,
		quantity: 266,
	},
	{
		garage_id: 46,
		part_id: 364,
		quantity: 177,
	},
	{
		garage_id: 46,
		part_id: 365,
		quantity: 12,
	},
	{
		garage_id: 46,
		part_id: 366,
		quantity: 95,
	},
	{
		garage_id: 46,
		part_id: 367,
		quantity: 56,
	},
	{
		garage_id: 46,
		part_id: 368,
		quantity: 238,
	},
	{
		garage_id: 46,
		part_id: 369,
		quantity: 441,
	},
	{
		garage_id: 46,
		part_id: 370,
		quantity: 252,
	},
	{
		garage_id: 46,
		part_id: 371,
		quantity: 30,
	},
	{
		garage_id: 46,
		part_id: 372,
		quantity: 358,
	},
	{
		garage_id: 46,
		part_id: 373,
		quantity: 405,
	},
	{
		garage_id: 46,
		part_id: 374,
		quantity: 390,
	},
	{
		garage_id: 46,
		part_id: 375,
		quantity: 115,
	},
	{
		garage_id: 46,
		part_id: 376,
		quantity: 27,
	},
	{
		garage_id: 46,
		part_id: 377,
		quantity: 178,
	},
	{
		garage_id: 46,
		part_id: 378,
		quantity: 488,
	},
	{
		garage_id: 46,
		part_id: 379,
		quantity: 136,
	},
	{
		garage_id: 46,
		part_id: 380,
		quantity: 128,
	},
	{
		garage_id: 46,
		part_id: 381,
		quantity: 399,
	},
	{
		garage_id: 46,
		part_id: 382,
		quantity: 179,
	},
	{
		garage_id: 46,
		part_id: 383,
		quantity: 334,
	},
	{
		garage_id: 46,
		part_id: 384,
		quantity: 288,
	},
	{
		garage_id: 46,
		part_id: 385,
		quantity: 146,
	},
	{
		garage_id: 46,
		part_id: 386,
		quantity: 8,
	},
	{
		garage_id: 46,
		part_id: 387,
		quantity: 204,
	},
	{
		garage_id: 46,
		part_id: 388,
		quantity: 147,
	},
	{
		garage_id: 46,
		part_id: 389,
		quantity: 471,
	},
	{
		garage_id: 46,
		part_id: 390,
		quantity: 191,
	},
	{
		garage_id: 46,
		part_id: 391,
		quantity: 63,
	},
	{
		garage_id: 46,
		part_id: 392,
		quantity: 71,
	},
	{
		garage_id: 46,
		part_id: 393,
		quantity: 166,
	},
	{
		garage_id: 46,
		part_id: 394,
		quantity: 283,
	},
	{
		garage_id: 46,
		part_id: 395,
		quantity: 53,
	},
	{
		garage_id: 46,
		part_id: 396,
		quantity: 320,
	},
	{
		garage_id: 46,
		part_id: 397,
		quantity: 153,
	},
	{
		garage_id: 46,
		part_id: 398,
		quantity: 144,
	},
	{
		garage_id: 46,
		part_id: 399,
		quantity: 173,
	},
	{
		garage_id: 47,
		part_id: 0,
		quantity: 132,
	},
	{
		garage_id: 47,
		part_id: 1,
		quantity: 75,
	},
	{
		garage_id: 47,
		part_id: 2,
		quantity: 91,
	},
	{
		garage_id: 47,
		part_id: 3,
		quantity: 25,
	},
	{
		garage_id: 47,
		part_id: 4,
		quantity: 489,
	},
	{
		garage_id: 47,
		part_id: 5,
		quantity: 126,
	},
	{
		garage_id: 47,
		part_id: 6,
		quantity: 145,
	},
	{
		garage_id: 47,
		part_id: 7,
		quantity: 457,
	},
	{
		garage_id: 47,
		part_id: 8,
		quantity: 10,
	},
	{
		garage_id: 47,
		part_id: 9,
		quantity: 425,
	},
	{
		garage_id: 47,
		part_id: 10,
		quantity: 405,
	},
	{
		garage_id: 47,
		part_id: 11,
		quantity: 371,
	},
	{
		garage_id: 47,
		part_id: 12,
		quantity: 155,
	},
	{
		garage_id: 47,
		part_id: 13,
		quantity: 0,
	},
	{
		garage_id: 47,
		part_id: 14,
		quantity: 63,
	},
	{
		garage_id: 47,
		part_id: 15,
		quantity: 33,
	},
	{
		garage_id: 47,
		part_id: 16,
		quantity: 321,
	},
	{
		garage_id: 47,
		part_id: 17,
		quantity: 111,
	},
	{
		garage_id: 47,
		part_id: 18,
		quantity: 389,
	},
	{
		garage_id: 47,
		part_id: 19,
		quantity: 458,
	},
	{
		garage_id: 47,
		part_id: 20,
		quantity: 158,
	},
	{
		garage_id: 47,
		part_id: 21,
		quantity: 308,
	},
	{
		garage_id: 47,
		part_id: 22,
		quantity: 312,
	},
	{
		garage_id: 47,
		part_id: 23,
		quantity: 249,
	},
	{
		garage_id: 47,
		part_id: 24,
		quantity: 201,
	},
	{
		garage_id: 47,
		part_id: 25,
		quantity: 80,
	},
	{
		garage_id: 47,
		part_id: 26,
		quantity: 454,
	},
	{
		garage_id: 47,
		part_id: 27,
		quantity: 70,
	},
	{
		garage_id: 47,
		part_id: 28,
		quantity: 131,
	},
	{
		garage_id: 47,
		part_id: 29,
		quantity: 178,
	},
	{
		garage_id: 47,
		part_id: 30,
		quantity: 442,
	},
	{
		garage_id: 47,
		part_id: 31,
		quantity: 365,
	},
	{
		garage_id: 47,
		part_id: 32,
		quantity: 195,
	},
	{
		garage_id: 47,
		part_id: 33,
		quantity: 282,
	},
	{
		garage_id: 47,
		part_id: 34,
		quantity: 383,
	},
	{
		garage_id: 47,
		part_id: 35,
		quantity: 251,
	},
	{
		garage_id: 47,
		part_id: 36,
		quantity: 262,
	},
	{
		garage_id: 47,
		part_id: 37,
		quantity: 372,
	},
	{
		garage_id: 47,
		part_id: 38,
		quantity: 440,
	},
	{
		garage_id: 47,
		part_id: 39,
		quantity: 355,
	},
	{
		garage_id: 47,
		part_id: 40,
		quantity: 436,
	},
	{
		garage_id: 47,
		part_id: 41,
		quantity: 50,
	},
	{
		garage_id: 47,
		part_id: 42,
		quantity: 336,
	},
	{
		garage_id: 47,
		part_id: 43,
		quantity: 433,
	},
	{
		garage_id: 47,
		part_id: 44,
		quantity: 352,
	},
	{
		garage_id: 47,
		part_id: 45,
		quantity: 12,
	},
	{
		garage_id: 47,
		part_id: 46,
		quantity: 163,
	},
	{
		garage_id: 47,
		part_id: 47,
		quantity: 145,
	},
	{
		garage_id: 47,
		part_id: 48,
		quantity: 493,
	},
	{
		garage_id: 47,
		part_id: 49,
		quantity: 12,
	},
	{
		garage_id: 47,
		part_id: 50,
		quantity: 458,
	},
	{
		garage_id: 47,
		part_id: 51,
		quantity: 144,
	},
	{
		garage_id: 47,
		part_id: 52,
		quantity: 224,
	},
	{
		garage_id: 47,
		part_id: 53,
		quantity: 256,
	},
	{
		garage_id: 47,
		part_id: 54,
		quantity: 297,
	},
	{
		garage_id: 47,
		part_id: 55,
		quantity: 157,
	},
	{
		garage_id: 47,
		part_id: 56,
		quantity: 321,
	},
	{
		garage_id: 47,
		part_id: 57,
		quantity: 98,
	},
	{
		garage_id: 47,
		part_id: 58,
		quantity: 299,
	},
	{
		garage_id: 47,
		part_id: 59,
		quantity: 91,
	},
	{
		garage_id: 47,
		part_id: 60,
		quantity: 256,
	},
	{
		garage_id: 47,
		part_id: 61,
		quantity: 96,
	},
	{
		garage_id: 47,
		part_id: 62,
		quantity: 440,
	},
	{
		garage_id: 47,
		part_id: 63,
		quantity: 442,
	},
	{
		garage_id: 47,
		part_id: 64,
		quantity: 311,
	},
	{
		garage_id: 47,
		part_id: 65,
		quantity: 67,
	},
	{
		garage_id: 47,
		part_id: 66,
		quantity: 257,
	},
	{
		garage_id: 47,
		part_id: 67,
		quantity: 168,
	},
	{
		garage_id: 47,
		part_id: 68,
		quantity: 426,
	},
	{
		garage_id: 47,
		part_id: 69,
		quantity: 443,
	},
	{
		garage_id: 47,
		part_id: 70,
		quantity: 335,
	},
	{
		garage_id: 47,
		part_id: 71,
		quantity: 475,
	},
	{
		garage_id: 47,
		part_id: 72,
		quantity: 114,
	},
	{
		garage_id: 47,
		part_id: 73,
		quantity: 268,
	},
	{
		garage_id: 47,
		part_id: 74,
		quantity: 242,
	},
	{
		garage_id: 47,
		part_id: 75,
		quantity: 449,
	},
	{
		garage_id: 47,
		part_id: 76,
		quantity: 458,
	},
	{
		garage_id: 47,
		part_id: 77,
		quantity: 416,
	},
	{
		garage_id: 47,
		part_id: 78,
		quantity: 468,
	},
	{
		garage_id: 47,
		part_id: 79,
		quantity: 3,
	},
	{
		garage_id: 47,
		part_id: 80,
		quantity: 111,
	},
	{
		garage_id: 47,
		part_id: 81,
		quantity: 253,
	},
	{
		garage_id: 47,
		part_id: 82,
		quantity: 161,
	},
	{
		garage_id: 47,
		part_id: 83,
		quantity: 6,
	},
	{
		garage_id: 47,
		part_id: 84,
		quantity: 448,
	},
	{
		garage_id: 47,
		part_id: 85,
		quantity: 341,
	},
	{
		garage_id: 47,
		part_id: 86,
		quantity: 440,
	},
	{
		garage_id: 47,
		part_id: 87,
		quantity: 232,
	},
	{
		garage_id: 47,
		part_id: 88,
		quantity: 246,
	},
	{
		garage_id: 47,
		part_id: 89,
		quantity: 328,
	},
	{
		garage_id: 47,
		part_id: 90,
		quantity: 84,
	},
	{
		garage_id: 47,
		part_id: 91,
		quantity: 250,
	},
	{
		garage_id: 47,
		part_id: 92,
		quantity: 469,
	},
	{
		garage_id: 47,
		part_id: 93,
		quantity: 299,
	},
	{
		garage_id: 47,
		part_id: 94,
		quantity: 296,
	},
	{
		garage_id: 47,
		part_id: 95,
		quantity: 6,
	},
	{
		garage_id: 47,
		part_id: 96,
		quantity: 428,
	},
	{
		garage_id: 47,
		part_id: 97,
		quantity: 287,
	},
	{
		garage_id: 47,
		part_id: 98,
		quantity: 256,
	},
	{
		garage_id: 47,
		part_id: 99,
		quantity: 338,
	},
	{
		garage_id: 47,
		part_id: 100,
		quantity: 49,
	},
	{
		garage_id: 47,
		part_id: 101,
		quantity: 7,
	},
	{
		garage_id: 47,
		part_id: 102,
		quantity: 292,
	},
	{
		garage_id: 47,
		part_id: 103,
		quantity: 54,
	},
	{
		garage_id: 47,
		part_id: 104,
		quantity: 59,
	},
	{
		garage_id: 47,
		part_id: 105,
		quantity: 410,
	},
	{
		garage_id: 47,
		part_id: 106,
		quantity: 224,
	},
	{
		garage_id: 47,
		part_id: 107,
		quantity: 435,
	},
	{
		garage_id: 47,
		part_id: 108,
		quantity: 207,
	},
	{
		garage_id: 47,
		part_id: 109,
		quantity: 65,
	},
	{
		garage_id: 47,
		part_id: 110,
		quantity: 351,
	},
	{
		garage_id: 47,
		part_id: 111,
		quantity: 197,
	},
	{
		garage_id: 47,
		part_id: 112,
		quantity: 328,
	},
	{
		garage_id: 47,
		part_id: 113,
		quantity: 123,
	},
	{
		garage_id: 47,
		part_id: 114,
		quantity: 369,
	},
	{
		garage_id: 47,
		part_id: 115,
		quantity: 243,
	},
	{
		garage_id: 47,
		part_id: 116,
		quantity: 468,
	},
	{
		garage_id: 47,
		part_id: 117,
		quantity: 260,
	},
	{
		garage_id: 47,
		part_id: 118,
		quantity: 317,
	},
	{
		garage_id: 47,
		part_id: 119,
		quantity: 93,
	},
	{
		garage_id: 47,
		part_id: 120,
		quantity: 327,
	},
	{
		garage_id: 47,
		part_id: 121,
		quantity: 4,
	},
	{
		garage_id: 47,
		part_id: 122,
		quantity: 20,
	},
	{
		garage_id: 47,
		part_id: 123,
		quantity: 48,
	},
	{
		garage_id: 47,
		part_id: 124,
		quantity: 345,
	},
	{
		garage_id: 47,
		part_id: 125,
		quantity: 7,
	},
	{
		garage_id: 47,
		part_id: 126,
		quantity: 430,
	},
	{
		garage_id: 47,
		part_id: 127,
		quantity: 309,
	},
	{
		garage_id: 47,
		part_id: 128,
		quantity: 47,
	},
	{
		garage_id: 47,
		part_id: 129,
		quantity: 486,
	},
	{
		garage_id: 47,
		part_id: 130,
		quantity: 296,
	},
	{
		garage_id: 47,
		part_id: 131,
		quantity: 157,
	},
	{
		garage_id: 47,
		part_id: 132,
		quantity: 9,
	},
	{
		garage_id: 47,
		part_id: 133,
		quantity: 297,
	},
	{
		garage_id: 47,
		part_id: 134,
		quantity: 397,
	},
	{
		garage_id: 47,
		part_id: 135,
		quantity: 97,
	},
	{
		garage_id: 47,
		part_id: 136,
		quantity: 392,
	},
	{
		garage_id: 47,
		part_id: 137,
		quantity: 449,
	},
	{
		garage_id: 47,
		part_id: 138,
		quantity: 375,
	},
	{
		garage_id: 47,
		part_id: 139,
		quantity: 370,
	},
	{
		garage_id: 47,
		part_id: 140,
		quantity: 211,
	},
	{
		garage_id: 47,
		part_id: 141,
		quantity: 396,
	},
	{
		garage_id: 47,
		part_id: 142,
		quantity: 429,
	},
	{
		garage_id: 47,
		part_id: 143,
		quantity: 256,
	},
	{
		garage_id: 47,
		part_id: 144,
		quantity: 167,
	},
	{
		garage_id: 47,
		part_id: 145,
		quantity: 56,
	},
	{
		garage_id: 47,
		part_id: 146,
		quantity: 122,
	},
	{
		garage_id: 47,
		part_id: 147,
		quantity: 143,
	},
	{
		garage_id: 47,
		part_id: 148,
		quantity: 309,
	},
	{
		garage_id: 47,
		part_id: 149,
		quantity: 274,
	},
	{
		garage_id: 47,
		part_id: 150,
		quantity: 83,
	},
	{
		garage_id: 47,
		part_id: 151,
		quantity: 253,
	},
	{
		garage_id: 47,
		part_id: 152,
		quantity: 102,
	},
	{
		garage_id: 47,
		part_id: 153,
		quantity: 139,
	},
	{
		garage_id: 47,
		part_id: 154,
		quantity: 304,
	},
	{
		garage_id: 47,
		part_id: 155,
		quantity: 67,
	},
	{
		garage_id: 47,
		part_id: 156,
		quantity: 322,
	},
	{
		garage_id: 47,
		part_id: 157,
		quantity: 293,
	},
	{
		garage_id: 47,
		part_id: 158,
		quantity: 346,
	},
	{
		garage_id: 47,
		part_id: 159,
		quantity: 158,
	},
	{
		garage_id: 47,
		part_id: 160,
		quantity: 133,
	},
	{
		garage_id: 47,
		part_id: 161,
		quantity: 212,
	},
	{
		garage_id: 47,
		part_id: 162,
		quantity: 409,
	},
	{
		garage_id: 47,
		part_id: 163,
		quantity: 337,
	},
	{
		garage_id: 47,
		part_id: 164,
		quantity: 135,
	},
	{
		garage_id: 47,
		part_id: 165,
		quantity: 351,
	},
	{
		garage_id: 47,
		part_id: 166,
		quantity: 105,
	},
	{
		garage_id: 47,
		part_id: 167,
		quantity: 372,
	},
	{
		garage_id: 47,
		part_id: 168,
		quantity: 303,
	},
	{
		garage_id: 47,
		part_id: 169,
		quantity: 304,
	},
	{
		garage_id: 47,
		part_id: 170,
		quantity: 139,
	},
	{
		garage_id: 47,
		part_id: 171,
		quantity: 144,
	},
	{
		garage_id: 47,
		part_id: 172,
		quantity: 444,
	},
	{
		garage_id: 47,
		part_id: 173,
		quantity: 315,
	},
	{
		garage_id: 47,
		part_id: 174,
		quantity: 367,
	},
	{
		garage_id: 47,
		part_id: 175,
		quantity: 367,
	},
	{
		garage_id: 47,
		part_id: 176,
		quantity: 308,
	},
	{
		garage_id: 47,
		part_id: 177,
		quantity: 423,
	},
	{
		garage_id: 47,
		part_id: 178,
		quantity: 339,
	},
	{
		garage_id: 47,
		part_id: 179,
		quantity: 242,
	},
	{
		garage_id: 47,
		part_id: 180,
		quantity: 467,
	},
	{
		garage_id: 47,
		part_id: 181,
		quantity: 268,
	},
	{
		garage_id: 47,
		part_id: 182,
		quantity: 17,
	},
	{
		garage_id: 47,
		part_id: 183,
		quantity: 416,
	},
	{
		garage_id: 47,
		part_id: 184,
		quantity: 146,
	},
	{
		garage_id: 47,
		part_id: 185,
		quantity: 279,
	},
	{
		garage_id: 47,
		part_id: 186,
		quantity: 452,
	},
	{
		garage_id: 47,
		part_id: 187,
		quantity: 35,
	},
	{
		garage_id: 47,
		part_id: 188,
		quantity: 435,
	},
	{
		garage_id: 47,
		part_id: 189,
		quantity: 5,
	},
	{
		garage_id: 47,
		part_id: 190,
		quantity: 308,
	},
	{
		garage_id: 47,
		part_id: 191,
		quantity: 460,
	},
	{
		garage_id: 47,
		part_id: 192,
		quantity: 25,
	},
	{
		garage_id: 47,
		part_id: 193,
		quantity: 473,
	},
	{
		garage_id: 47,
		part_id: 194,
		quantity: 177,
	},
	{
		garage_id: 47,
		part_id: 195,
		quantity: 427,
	},
	{
		garage_id: 47,
		part_id: 196,
		quantity: 217,
	},
	{
		garage_id: 47,
		part_id: 197,
		quantity: 176,
	},
	{
		garage_id: 47,
		part_id: 198,
		quantity: 152,
	},
	{
		garage_id: 47,
		part_id: 199,
		quantity: 193,
	},
	{
		garage_id: 47,
		part_id: 200,
		quantity: 118,
	},
	{
		garage_id: 47,
		part_id: 201,
		quantity: 212,
	},
	{
		garage_id: 47,
		part_id: 202,
		quantity: 131,
	},
	{
		garage_id: 47,
		part_id: 203,
		quantity: 43,
	},
	{
		garage_id: 47,
		part_id: 204,
		quantity: 444,
	},
	{
		garage_id: 47,
		part_id: 205,
		quantity: 308,
	},
	{
		garage_id: 47,
		part_id: 206,
		quantity: 316,
	},
	{
		garage_id: 47,
		part_id: 207,
		quantity: 380,
	},
	{
		garage_id: 47,
		part_id: 208,
		quantity: 209,
	},
	{
		garage_id: 47,
		part_id: 209,
		quantity: 209,
	},
	{
		garage_id: 47,
		part_id: 210,
		quantity: 325,
	},
	{
		garage_id: 47,
		part_id: 211,
		quantity: 456,
	},
	{
		garage_id: 47,
		part_id: 212,
		quantity: 151,
	},
	{
		garage_id: 47,
		part_id: 213,
		quantity: 460,
	},
	{
		garage_id: 47,
		part_id: 214,
		quantity: 190,
	},
	{
		garage_id: 47,
		part_id: 215,
		quantity: 186,
	},
	{
		garage_id: 47,
		part_id: 216,
		quantity: 329,
	},
	{
		garage_id: 47,
		part_id: 217,
		quantity: 141,
	},
	{
		garage_id: 47,
		part_id: 218,
		quantity: 368,
	},
	{
		garage_id: 47,
		part_id: 219,
		quantity: 114,
	},
	{
		garage_id: 47,
		part_id: 220,
		quantity: 214,
	},
	{
		garage_id: 47,
		part_id: 221,
		quantity: 488,
	},
	{
		garage_id: 47,
		part_id: 222,
		quantity: 325,
	},
	{
		garage_id: 47,
		part_id: 223,
		quantity: 435,
	},
	{
		garage_id: 47,
		part_id: 224,
		quantity: 399,
	},
	{
		garage_id: 47,
		part_id: 225,
		quantity: 248,
	},
	{
		garage_id: 47,
		part_id: 226,
		quantity: 56,
	},
	{
		garage_id: 47,
		part_id: 227,
		quantity: 25,
	},
	{
		garage_id: 47,
		part_id: 228,
		quantity: 456,
	},
	{
		garage_id: 47,
		part_id: 229,
		quantity: 254,
	},
	{
		garage_id: 47,
		part_id: 230,
		quantity: 245,
	},
	{
		garage_id: 47,
		part_id: 231,
		quantity: 4,
	},
	{
		garage_id: 47,
		part_id: 232,
		quantity: 63,
	},
	{
		garage_id: 47,
		part_id: 233,
		quantity: 244,
	},
	{
		garage_id: 47,
		part_id: 234,
		quantity: 229,
	},
	{
		garage_id: 47,
		part_id: 235,
		quantity: 30,
	},
	{
		garage_id: 47,
		part_id: 236,
		quantity: 40,
	},
	{
		garage_id: 47,
		part_id: 237,
		quantity: 261,
	},
	{
		garage_id: 47,
		part_id: 238,
		quantity: 50,
	},
	{
		garage_id: 47,
		part_id: 239,
		quantity: 372,
	},
	{
		garage_id: 47,
		part_id: 240,
		quantity: 93,
	},
	{
		garage_id: 47,
		part_id: 241,
		quantity: 115,
	},
	{
		garage_id: 47,
		part_id: 242,
		quantity: 136,
	},
	{
		garage_id: 47,
		part_id: 243,
		quantity: 351,
	},
	{
		garage_id: 47,
		part_id: 244,
		quantity: 154,
	},
	{
		garage_id: 47,
		part_id: 245,
		quantity: 344,
	},
	{
		garage_id: 47,
		part_id: 246,
		quantity: 213,
	},
	{
		garage_id: 47,
		part_id: 247,
		quantity: 195,
	},
	{
		garage_id: 47,
		part_id: 248,
		quantity: 170,
	},
	{
		garage_id: 47,
		part_id: 249,
		quantity: 287,
	},
	{
		garage_id: 47,
		part_id: 250,
		quantity: 295,
	},
	{
		garage_id: 47,
		part_id: 251,
		quantity: 34,
	},
	{
		garage_id: 47,
		part_id: 252,
		quantity: 91,
	},
	{
		garage_id: 47,
		part_id: 253,
		quantity: 102,
	},
	{
		garage_id: 47,
		part_id: 254,
		quantity: 330,
	},
	{
		garage_id: 47,
		part_id: 255,
		quantity: 279,
	},
	{
		garage_id: 47,
		part_id: 256,
		quantity: 243,
	},
	{
		garage_id: 47,
		part_id: 257,
		quantity: 42,
	},
	{
		garage_id: 47,
		part_id: 258,
		quantity: 302,
	},
	{
		garage_id: 47,
		part_id: 259,
		quantity: 232,
	},
	{
		garage_id: 47,
		part_id: 260,
		quantity: 95,
	},
	{
		garage_id: 47,
		part_id: 261,
		quantity: 232,
	},
	{
		garage_id: 47,
		part_id: 262,
		quantity: 203,
	},
	{
		garage_id: 47,
		part_id: 263,
		quantity: 404,
	},
	{
		garage_id: 47,
		part_id: 264,
		quantity: 311,
	},
	{
		garage_id: 47,
		part_id: 265,
		quantity: 237,
	},
	{
		garage_id: 47,
		part_id: 266,
		quantity: 204,
	},
	{
		garage_id: 47,
		part_id: 267,
		quantity: 241,
	},
	{
		garage_id: 47,
		part_id: 268,
		quantity: 390,
	},
	{
		garage_id: 47,
		part_id: 269,
		quantity: 266,
	},
	{
		garage_id: 47,
		part_id: 270,
		quantity: 398,
	},
	{
		garage_id: 47,
		part_id: 271,
		quantity: 143,
	},
	{
		garage_id: 47,
		part_id: 272,
		quantity: 446,
	},
	{
		garage_id: 47,
		part_id: 273,
		quantity: 56,
	},
	{
		garage_id: 47,
		part_id: 274,
		quantity: 340,
	},
	{
		garage_id: 47,
		part_id: 275,
		quantity: 78,
	},
	{
		garage_id: 47,
		part_id: 276,
		quantity: 408,
	},
	{
		garage_id: 47,
		part_id: 277,
		quantity: 127,
	},
	{
		garage_id: 47,
		part_id: 278,
		quantity: 57,
	},
	{
		garage_id: 47,
		part_id: 279,
		quantity: 493,
	},
	{
		garage_id: 47,
		part_id: 280,
		quantity: 486,
	},
	{
		garage_id: 47,
		part_id: 281,
		quantity: 173,
	},
	{
		garage_id: 47,
		part_id: 282,
		quantity: 192,
	},
	{
		garage_id: 47,
		part_id: 283,
		quantity: 54,
	},
	{
		garage_id: 47,
		part_id: 284,
		quantity: 34,
	},
	{
		garage_id: 47,
		part_id: 285,
		quantity: 314,
	},
	{
		garage_id: 47,
		part_id: 286,
		quantity: 491,
	},
	{
		garage_id: 47,
		part_id: 287,
		quantity: 352,
	},
	{
		garage_id: 47,
		part_id: 288,
		quantity: 115,
	},
	{
		garage_id: 47,
		part_id: 289,
		quantity: 112,
	},
	{
		garage_id: 47,
		part_id: 290,
		quantity: 143,
	},
	{
		garage_id: 47,
		part_id: 291,
		quantity: 494,
	},
	{
		garage_id: 47,
		part_id: 292,
		quantity: 273,
	},
	{
		garage_id: 47,
		part_id: 293,
		quantity: 156,
	},
	{
		garage_id: 47,
		part_id: 294,
		quantity: 204,
	},
	{
		garage_id: 47,
		part_id: 295,
		quantity: 113,
	},
	{
		garage_id: 47,
		part_id: 296,
		quantity: 393,
	},
	{
		garage_id: 47,
		part_id: 297,
		quantity: 230,
	},
	{
		garage_id: 47,
		part_id: 298,
		quantity: 28,
	},
	{
		garage_id: 47,
		part_id: 299,
		quantity: 471,
	},
	{
		garage_id: 47,
		part_id: 300,
		quantity: 344,
	},
	{
		garage_id: 47,
		part_id: 301,
		quantity: 20,
	},
	{
		garage_id: 47,
		part_id: 302,
		quantity: 139,
	},
	{
		garage_id: 47,
		part_id: 303,
		quantity: 353,
	},
	{
		garage_id: 47,
		part_id: 304,
		quantity: 134,
	},
	{
		garage_id: 47,
		part_id: 305,
		quantity: 360,
	},
	{
		garage_id: 47,
		part_id: 306,
		quantity: 211,
	},
	{
		garage_id: 47,
		part_id: 307,
		quantity: 138,
	},
	{
		garage_id: 47,
		part_id: 308,
		quantity: 390,
	},
	{
		garage_id: 47,
		part_id: 309,
		quantity: 410,
	},
	{
		garage_id: 47,
		part_id: 310,
		quantity: 387,
	},
	{
		garage_id: 47,
		part_id: 311,
		quantity: 427,
	},
	{
		garage_id: 47,
		part_id: 312,
		quantity: 371,
	},
	{
		garage_id: 47,
		part_id: 313,
		quantity: 48,
	},
	{
		garage_id: 47,
		part_id: 314,
		quantity: 413,
	},
	{
		garage_id: 47,
		part_id: 315,
		quantity: 296,
	},
	{
		garage_id: 47,
		part_id: 316,
		quantity: 272,
	},
	{
		garage_id: 47,
		part_id: 317,
		quantity: 439,
	},
	{
		garage_id: 47,
		part_id: 318,
		quantity: 485,
	},
	{
		garage_id: 47,
		part_id: 319,
		quantity: 338,
	},
	{
		garage_id: 47,
		part_id: 320,
		quantity: 356,
	},
	{
		garage_id: 47,
		part_id: 321,
		quantity: 7,
	},
	{
		garage_id: 47,
		part_id: 322,
		quantity: 270,
	},
	{
		garage_id: 47,
		part_id: 323,
		quantity: 36,
	},
	{
		garage_id: 47,
		part_id: 324,
		quantity: 449,
	},
	{
		garage_id: 47,
		part_id: 325,
		quantity: 185,
	},
	{
		garage_id: 47,
		part_id: 326,
		quantity: 490,
	},
	{
		garage_id: 47,
		part_id: 327,
		quantity: 491,
	},
	{
		garage_id: 47,
		part_id: 328,
		quantity: 196,
	},
	{
		garage_id: 47,
		part_id: 329,
		quantity: 439,
	},
	{
		garage_id: 47,
		part_id: 330,
		quantity: 159,
	},
	{
		garage_id: 47,
		part_id: 331,
		quantity: 121,
	},
	{
		garage_id: 47,
		part_id: 332,
		quantity: 421,
	},
	{
		garage_id: 47,
		part_id: 333,
		quantity: 303,
	},
	{
		garage_id: 47,
		part_id: 334,
		quantity: 365,
	},
	{
		garage_id: 47,
		part_id: 335,
		quantity: 86,
	},
	{
		garage_id: 47,
		part_id: 336,
		quantity: 49,
	},
	{
		garage_id: 47,
		part_id: 337,
		quantity: 53,
	},
	{
		garage_id: 47,
		part_id: 338,
		quantity: 300,
	},
	{
		garage_id: 47,
		part_id: 339,
		quantity: 94,
	},
	{
		garage_id: 47,
		part_id: 340,
		quantity: 52,
	},
	{
		garage_id: 47,
		part_id: 341,
		quantity: 105,
	},
	{
		garage_id: 47,
		part_id: 342,
		quantity: 113,
	},
	{
		garage_id: 47,
		part_id: 343,
		quantity: 30,
	},
	{
		garage_id: 47,
		part_id: 344,
		quantity: 315,
	},
	{
		garage_id: 47,
		part_id: 345,
		quantity: 56,
	},
	{
		garage_id: 47,
		part_id: 346,
		quantity: 479,
	},
	{
		garage_id: 47,
		part_id: 347,
		quantity: 393,
	},
	{
		garage_id: 47,
		part_id: 348,
		quantity: 312,
	},
	{
		garage_id: 47,
		part_id: 349,
		quantity: 354,
	},
	{
		garage_id: 47,
		part_id: 350,
		quantity: 26,
	},
	{
		garage_id: 47,
		part_id: 351,
		quantity: 197,
	},
	{
		garage_id: 47,
		part_id: 352,
		quantity: 250,
	},
	{
		garage_id: 47,
		part_id: 353,
		quantity: 483,
	},
	{
		garage_id: 47,
		part_id: 354,
		quantity: 226,
	},
	{
		garage_id: 47,
		part_id: 355,
		quantity: 131,
	},
	{
		garage_id: 47,
		part_id: 356,
		quantity: 192,
	},
	{
		garage_id: 47,
		part_id: 357,
		quantity: 256,
	},
	{
		garage_id: 47,
		part_id: 358,
		quantity: 365,
	},
	{
		garage_id: 47,
		part_id: 359,
		quantity: 483,
	},
	{
		garage_id: 47,
		part_id: 360,
		quantity: 452,
	},
	{
		garage_id: 47,
		part_id: 361,
		quantity: 26,
	},
	{
		garage_id: 47,
		part_id: 362,
		quantity: 103,
	},
	{
		garage_id: 47,
		part_id: 363,
		quantity: 407,
	},
	{
		garage_id: 47,
		part_id: 364,
		quantity: 310,
	},
	{
		garage_id: 47,
		part_id: 365,
		quantity: 0,
	},
	{
		garage_id: 47,
		part_id: 366,
		quantity: 243,
	},
	{
		garage_id: 47,
		part_id: 367,
		quantity: 327,
	},
	{
		garage_id: 47,
		part_id: 368,
		quantity: 465,
	},
	{
		garage_id: 47,
		part_id: 369,
		quantity: 179,
	},
	{
		garage_id: 47,
		part_id: 370,
		quantity: 209,
	},
	{
		garage_id: 47,
		part_id: 371,
		quantity: 465,
	},
	{
		garage_id: 47,
		part_id: 372,
		quantity: 416,
	},
	{
		garage_id: 47,
		part_id: 373,
		quantity: 63,
	},
	{
		garage_id: 47,
		part_id: 374,
		quantity: 46,
	},
	{
		garage_id: 47,
		part_id: 375,
		quantity: 112,
	},
	{
		garage_id: 47,
		part_id: 376,
		quantity: 263,
	},
	{
		garage_id: 47,
		part_id: 377,
		quantity: 337,
	},
	{
		garage_id: 47,
		part_id: 378,
		quantity: 462,
	},
	{
		garage_id: 47,
		part_id: 379,
		quantity: 358,
	},
	{
		garage_id: 47,
		part_id: 380,
		quantity: 321,
	},
	{
		garage_id: 47,
		part_id: 381,
		quantity: 294,
	},
	{
		garage_id: 47,
		part_id: 382,
		quantity: 21,
	},
	{
		garage_id: 47,
		part_id: 383,
		quantity: 341,
	},
	{
		garage_id: 47,
		part_id: 384,
		quantity: 401,
	},
	{
		garage_id: 47,
		part_id: 385,
		quantity: 340,
	},
	{
		garage_id: 47,
		part_id: 386,
		quantity: 156,
	},
	{
		garage_id: 47,
		part_id: 387,
		quantity: 376,
	},
	{
		garage_id: 47,
		part_id: 388,
		quantity: 468,
	},
	{
		garage_id: 47,
		part_id: 389,
		quantity: 30,
	},
	{
		garage_id: 47,
		part_id: 390,
		quantity: 209,
	},
	{
		garage_id: 47,
		part_id: 391,
		quantity: 74,
	},
	{
		garage_id: 47,
		part_id: 392,
		quantity: 213,
	},
	{
		garage_id: 47,
		part_id: 393,
		quantity: 485,
	},
	{
		garage_id: 47,
		part_id: 394,
		quantity: 249,
	},
	{
		garage_id: 47,
		part_id: 395,
		quantity: 468,
	},
	{
		garage_id: 47,
		part_id: 396,
		quantity: 154,
	},
	{
		garage_id: 47,
		part_id: 397,
		quantity: 390,
	},
	{
		garage_id: 47,
		part_id: 398,
		quantity: 301,
	},
	{
		garage_id: 47,
		part_id: 399,
		quantity: 40,
	},
	{
		garage_id: 48,
		part_id: 0,
		quantity: 226,
	},
	{
		garage_id: 48,
		part_id: 1,
		quantity: 142,
	},
	{
		garage_id: 48,
		part_id: 2,
		quantity: 354,
	},
	{
		garage_id: 48,
		part_id: 3,
		quantity: 425,
	},
	{
		garage_id: 48,
		part_id: 4,
		quantity: 185,
	},
	{
		garage_id: 48,
		part_id: 5,
		quantity: 164,
	},
	{
		garage_id: 48,
		part_id: 6,
		quantity: 147,
	},
	{
		garage_id: 48,
		part_id: 7,
		quantity: 211,
	},
	{
		garage_id: 48,
		part_id: 8,
		quantity: 263,
	},
	{
		garage_id: 48,
		part_id: 9,
		quantity: 40,
	},
	{
		garage_id: 48,
		part_id: 10,
		quantity: 400,
	},
	{
		garage_id: 48,
		part_id: 11,
		quantity: 173,
	},
	{
		garage_id: 48,
		part_id: 12,
		quantity: 306,
	},
	{
		garage_id: 48,
		part_id: 13,
		quantity: 408,
	},
	{
		garage_id: 48,
		part_id: 14,
		quantity: 166,
	},
	{
		garage_id: 48,
		part_id: 15,
		quantity: 225,
	},
	{
		garage_id: 48,
		part_id: 16,
		quantity: 448,
	},
	{
		garage_id: 48,
		part_id: 17,
		quantity: 203,
	},
	{
		garage_id: 48,
		part_id: 18,
		quantity: 317,
	},
	{
		garage_id: 48,
		part_id: 19,
		quantity: 210,
	},
	{
		garage_id: 48,
		part_id: 20,
		quantity: 455,
	},
	{
		garage_id: 48,
		part_id: 21,
		quantity: 409,
	},
	{
		garage_id: 48,
		part_id: 22,
		quantity: 411,
	},
	{
		garage_id: 48,
		part_id: 23,
		quantity: 83,
	},
	{
		garage_id: 48,
		part_id: 24,
		quantity: 180,
	},
	{
		garage_id: 48,
		part_id: 25,
		quantity: 262,
	},
	{
		garage_id: 48,
		part_id: 26,
		quantity: 192,
	},
	{
		garage_id: 48,
		part_id: 27,
		quantity: 215,
	},
	{
		garage_id: 48,
		part_id: 28,
		quantity: 348,
	},
	{
		garage_id: 48,
		part_id: 29,
		quantity: 193,
	},
	{
		garage_id: 48,
		part_id: 30,
		quantity: 99,
	},
	{
		garage_id: 48,
		part_id: 31,
		quantity: 376,
	},
	{
		garage_id: 48,
		part_id: 32,
		quantity: 17,
	},
	{
		garage_id: 48,
		part_id: 33,
		quantity: 128,
	},
	{
		garage_id: 48,
		part_id: 34,
		quantity: 373,
	},
	{
		garage_id: 48,
		part_id: 35,
		quantity: 297,
	},
	{
		garage_id: 48,
		part_id: 36,
		quantity: 221,
	},
	{
		garage_id: 48,
		part_id: 37,
		quantity: 405,
	},
	{
		garage_id: 48,
		part_id: 38,
		quantity: 350,
	},
	{
		garage_id: 48,
		part_id: 39,
		quantity: 479,
	},
	{
		garage_id: 48,
		part_id: 40,
		quantity: 197,
	},
	{
		garage_id: 48,
		part_id: 41,
		quantity: 369,
	},
	{
		garage_id: 48,
		part_id: 42,
		quantity: 49,
	},
	{
		garage_id: 48,
		part_id: 43,
		quantity: 272,
	},
	{
		garage_id: 48,
		part_id: 44,
		quantity: 324,
	},
	{
		garage_id: 48,
		part_id: 45,
		quantity: 468,
	},
	{
		garage_id: 48,
		part_id: 46,
		quantity: 414,
	},
	{
		garage_id: 48,
		part_id: 47,
		quantity: 468,
	},
	{
		garage_id: 48,
		part_id: 48,
		quantity: 29,
	},
	{
		garage_id: 48,
		part_id: 49,
		quantity: 116,
	},
	{
		garage_id: 48,
		part_id: 50,
		quantity: 409,
	},
	{
		garage_id: 48,
		part_id: 51,
		quantity: 76,
	},
	{
		garage_id: 48,
		part_id: 52,
		quantity: 473,
	},
	{
		garage_id: 48,
		part_id: 53,
		quantity: 14,
	},
	{
		garage_id: 48,
		part_id: 54,
		quantity: 417,
	},
	{
		garage_id: 48,
		part_id: 55,
		quantity: 181,
	},
	{
		garage_id: 48,
		part_id: 56,
		quantity: 109,
	},
	{
		garage_id: 48,
		part_id: 57,
		quantity: 372,
	},
	{
		garage_id: 48,
		part_id: 58,
		quantity: 24,
	},
	{
		garage_id: 48,
		part_id: 59,
		quantity: 196,
	},
	{
		garage_id: 48,
		part_id: 60,
		quantity: 465,
	},
	{
		garage_id: 48,
		part_id: 61,
		quantity: 157,
	},
	{
		garage_id: 48,
		part_id: 62,
		quantity: 289,
	},
	{
		garage_id: 48,
		part_id: 63,
		quantity: 16,
	},
	{
		garage_id: 48,
		part_id: 64,
		quantity: 256,
	},
	{
		garage_id: 48,
		part_id: 65,
		quantity: 175,
	},
	{
		garage_id: 48,
		part_id: 66,
		quantity: 258,
	},
	{
		garage_id: 48,
		part_id: 67,
		quantity: 358,
	},
	{
		garage_id: 48,
		part_id: 68,
		quantity: 376,
	},
	{
		garage_id: 48,
		part_id: 69,
		quantity: 234,
	},
	{
		garage_id: 48,
		part_id: 70,
		quantity: 293,
	},
	{
		garage_id: 48,
		part_id: 71,
		quantity: 132,
	},
	{
		garage_id: 48,
		part_id: 72,
		quantity: 459,
	},
	{
		garage_id: 48,
		part_id: 73,
		quantity: 46,
	},
	{
		garage_id: 48,
		part_id: 74,
		quantity: 385,
	},
	{
		garage_id: 48,
		part_id: 75,
		quantity: 152,
	},
	{
		garage_id: 48,
		part_id: 76,
		quantity: 260,
	},
	{
		garage_id: 48,
		part_id: 77,
		quantity: 20,
	},
	{
		garage_id: 48,
		part_id: 78,
		quantity: 15,
	},
	{
		garage_id: 48,
		part_id: 79,
		quantity: 264,
	},
	{
		garage_id: 48,
		part_id: 80,
		quantity: 110,
	},
	{
		garage_id: 48,
		part_id: 81,
		quantity: 15,
	},
	{
		garage_id: 48,
		part_id: 82,
		quantity: 74,
	},
	{
		garage_id: 48,
		part_id: 83,
		quantity: 371,
	},
	{
		garage_id: 48,
		part_id: 84,
		quantity: 326,
	},
	{
		garage_id: 48,
		part_id: 85,
		quantity: 138,
	},
	{
		garage_id: 48,
		part_id: 86,
		quantity: 478,
	},
	{
		garage_id: 48,
		part_id: 87,
		quantity: 282,
	},
	{
		garage_id: 48,
		part_id: 88,
		quantity: 354,
	},
	{
		garage_id: 48,
		part_id: 89,
		quantity: 472,
	},
	{
		garage_id: 48,
		part_id: 90,
		quantity: 273,
	},
	{
		garage_id: 48,
		part_id: 91,
		quantity: 389,
	},
	{
		garage_id: 48,
		part_id: 92,
		quantity: 446,
	},
	{
		garage_id: 48,
		part_id: 93,
		quantity: 440,
	},
	{
		garage_id: 48,
		part_id: 94,
		quantity: 234,
	},
	{
		garage_id: 48,
		part_id: 95,
		quantity: 215,
	},
	{
		garage_id: 48,
		part_id: 96,
		quantity: 151,
	},
	{
		garage_id: 48,
		part_id: 97,
		quantity: 431,
	},
	{
		garage_id: 48,
		part_id: 98,
		quantity: 197,
	},
	{
		garage_id: 48,
		part_id: 99,
		quantity: 47,
	},
	{
		garage_id: 48,
		part_id: 100,
		quantity: 43,
	},
	{
		garage_id: 48,
		part_id: 101,
		quantity: 117,
	},
	{
		garage_id: 48,
		part_id: 102,
		quantity: 83,
	},
	{
		garage_id: 48,
		part_id: 103,
		quantity: 498,
	},
	{
		garage_id: 48,
		part_id: 104,
		quantity: 292,
	},
	{
		garage_id: 48,
		part_id: 105,
		quantity: 264,
	},
	{
		garage_id: 48,
		part_id: 106,
		quantity: 282,
	},
	{
		garage_id: 48,
		part_id: 107,
		quantity: 108,
	},
	{
		garage_id: 48,
		part_id: 108,
		quantity: 104,
	},
	{
		garage_id: 48,
		part_id: 109,
		quantity: 112,
	},
	{
		garage_id: 48,
		part_id: 110,
		quantity: 479,
	},
	{
		garage_id: 48,
		part_id: 111,
		quantity: 481,
	},
	{
		garage_id: 48,
		part_id: 112,
		quantity: 218,
	},
	{
		garage_id: 48,
		part_id: 113,
		quantity: 451,
	},
	{
		garage_id: 48,
		part_id: 114,
		quantity: 62,
	},
	{
		garage_id: 48,
		part_id: 115,
		quantity: 208,
	},
	{
		garage_id: 48,
		part_id: 116,
		quantity: 226,
	},
	{
		garage_id: 48,
		part_id: 117,
		quantity: 382,
	},
	{
		garage_id: 48,
		part_id: 118,
		quantity: 301,
	},
	{
		garage_id: 48,
		part_id: 119,
		quantity: 30,
	},
	{
		garage_id: 48,
		part_id: 120,
		quantity: 10,
	},
	{
		garage_id: 48,
		part_id: 121,
		quantity: 450,
	},
	{
		garage_id: 48,
		part_id: 122,
		quantity: 242,
	},
	{
		garage_id: 48,
		part_id: 123,
		quantity: 485,
	},
	{
		garage_id: 48,
		part_id: 124,
		quantity: 483,
	},
	{
		garage_id: 48,
		part_id: 125,
		quantity: 321,
	},
	{
		garage_id: 48,
		part_id: 126,
		quantity: 381,
	},
	{
		garage_id: 48,
		part_id: 127,
		quantity: 32,
	},
	{
		garage_id: 48,
		part_id: 128,
		quantity: 472,
	},
	{
		garage_id: 48,
		part_id: 129,
		quantity: 40,
	},
	{
		garage_id: 48,
		part_id: 130,
		quantity: 377,
	},
	{
		garage_id: 48,
		part_id: 131,
		quantity: 446,
	},
	{
		garage_id: 48,
		part_id: 132,
		quantity: 245,
	},
	{
		garage_id: 48,
		part_id: 133,
		quantity: 226,
	},
	{
		garage_id: 48,
		part_id: 134,
		quantity: 362,
	},
	{
		garage_id: 48,
		part_id: 135,
		quantity: 173,
	},
	{
		garage_id: 48,
		part_id: 136,
		quantity: 423,
	},
	{
		garage_id: 48,
		part_id: 137,
		quantity: 261,
	},
	{
		garage_id: 48,
		part_id: 138,
		quantity: 13,
	},
	{
		garage_id: 48,
		part_id: 139,
		quantity: 442,
	},
	{
		garage_id: 48,
		part_id: 140,
		quantity: 81,
	},
	{
		garage_id: 48,
		part_id: 141,
		quantity: 418,
	},
	{
		garage_id: 48,
		part_id: 142,
		quantity: 500,
	},
	{
		garage_id: 48,
		part_id: 143,
		quantity: 368,
	},
	{
		garage_id: 48,
		part_id: 144,
		quantity: 53,
	},
	{
		garage_id: 48,
		part_id: 145,
		quantity: 274,
	},
	{
		garage_id: 48,
		part_id: 146,
		quantity: 450,
	},
	{
		garage_id: 48,
		part_id: 147,
		quantity: 269,
	},
	{
		garage_id: 48,
		part_id: 148,
		quantity: 392,
	},
	{
		garage_id: 48,
		part_id: 149,
		quantity: 103,
	},
	{
		garage_id: 48,
		part_id: 150,
		quantity: 139,
	},
	{
		garage_id: 48,
		part_id: 151,
		quantity: 90,
	},
	{
		garage_id: 48,
		part_id: 152,
		quantity: 339,
	},
	{
		garage_id: 48,
		part_id: 153,
		quantity: 486,
	},
	{
		garage_id: 48,
		part_id: 154,
		quantity: 291,
	},
	{
		garage_id: 48,
		part_id: 155,
		quantity: 17,
	},
	{
		garage_id: 48,
		part_id: 156,
		quantity: 423,
	},
	{
		garage_id: 48,
		part_id: 157,
		quantity: 109,
	},
	{
		garage_id: 48,
		part_id: 158,
		quantity: 272,
	},
	{
		garage_id: 48,
		part_id: 159,
		quantity: 6,
	},
	{
		garage_id: 48,
		part_id: 160,
		quantity: 208,
	},
	{
		garage_id: 48,
		part_id: 161,
		quantity: 297,
	},
	{
		garage_id: 48,
		part_id: 162,
		quantity: 414,
	},
	{
		garage_id: 48,
		part_id: 163,
		quantity: 429,
	},
	{
		garage_id: 48,
		part_id: 164,
		quantity: 188,
	},
	{
		garage_id: 48,
		part_id: 165,
		quantity: 407,
	},
	{
		garage_id: 48,
		part_id: 166,
		quantity: 46,
	},
	{
		garage_id: 48,
		part_id: 167,
		quantity: 102,
	},
	{
		garage_id: 48,
		part_id: 168,
		quantity: 46,
	},
	{
		garage_id: 48,
		part_id: 169,
		quantity: 473,
	},
	{
		garage_id: 48,
		part_id: 170,
		quantity: 311,
	},
	{
		garage_id: 48,
		part_id: 171,
		quantity: 352,
	},
	{
		garage_id: 48,
		part_id: 172,
		quantity: 57,
	},
	{
		garage_id: 48,
		part_id: 173,
		quantity: 467,
	},
	{
		garage_id: 48,
		part_id: 174,
		quantity: 45,
	},
	{
		garage_id: 48,
		part_id: 175,
		quantity: 251,
	},
	{
		garage_id: 48,
		part_id: 176,
		quantity: 404,
	},
	{
		garage_id: 48,
		part_id: 177,
		quantity: 204,
	},
	{
		garage_id: 48,
		part_id: 178,
		quantity: 307,
	},
	{
		garage_id: 48,
		part_id: 179,
		quantity: 474,
	},
	{
		garage_id: 48,
		part_id: 180,
		quantity: 114,
	},
	{
		garage_id: 48,
		part_id: 181,
		quantity: 54,
	},
	{
		garage_id: 48,
		part_id: 182,
		quantity: 445,
	},
	{
		garage_id: 48,
		part_id: 183,
		quantity: 58,
	},
	{
		garage_id: 48,
		part_id: 184,
		quantity: 17,
	},
	{
		garage_id: 48,
		part_id: 185,
		quantity: 115,
	},
	{
		garage_id: 48,
		part_id: 186,
		quantity: 55,
	},
	{
		garage_id: 48,
		part_id: 187,
		quantity: 455,
	},
	{
		garage_id: 48,
		part_id: 188,
		quantity: 324,
	},
	{
		garage_id: 48,
		part_id: 189,
		quantity: 7,
	},
	{
		garage_id: 48,
		part_id: 190,
		quantity: 9,
	},
	{
		garage_id: 48,
		part_id: 191,
		quantity: 86,
	},
	{
		garage_id: 48,
		part_id: 192,
		quantity: 451,
	},
	{
		garage_id: 48,
		part_id: 193,
		quantity: 199,
	},
	{
		garage_id: 48,
		part_id: 194,
		quantity: 242,
	},
	{
		garage_id: 48,
		part_id: 195,
		quantity: 305,
	},
	{
		garage_id: 48,
		part_id: 196,
		quantity: 290,
	},
	{
		garage_id: 48,
		part_id: 197,
		quantity: 102,
	},
	{
		garage_id: 48,
		part_id: 198,
		quantity: 161,
	},
	{
		garage_id: 48,
		part_id: 199,
		quantity: 355,
	},
	{
		garage_id: 48,
		part_id: 200,
		quantity: 237,
	},
	{
		garage_id: 48,
		part_id: 201,
		quantity: 16,
	},
	{
		garage_id: 48,
		part_id: 202,
		quantity: 56,
	},
	{
		garage_id: 48,
		part_id: 203,
		quantity: 342,
	},
	{
		garage_id: 48,
		part_id: 204,
		quantity: 344,
	},
	{
		garage_id: 48,
		part_id: 205,
		quantity: 229,
	},
	{
		garage_id: 48,
		part_id: 206,
		quantity: 222,
	},
	{
		garage_id: 48,
		part_id: 207,
		quantity: 500,
	},
	{
		garage_id: 48,
		part_id: 208,
		quantity: 356,
	},
	{
		garage_id: 48,
		part_id: 209,
		quantity: 106,
	},
	{
		garage_id: 48,
		part_id: 210,
		quantity: 41,
	},
	{
		garage_id: 48,
		part_id: 211,
		quantity: 472,
	},
	{
		garage_id: 48,
		part_id: 212,
		quantity: 347,
	},
	{
		garage_id: 48,
		part_id: 213,
		quantity: 298,
	},
	{
		garage_id: 48,
		part_id: 214,
		quantity: 242,
	},
	{
		garage_id: 48,
		part_id: 215,
		quantity: 487,
	},
	{
		garage_id: 48,
		part_id: 216,
		quantity: 210,
	},
	{
		garage_id: 48,
		part_id: 217,
		quantity: 36,
	},
	{
		garage_id: 48,
		part_id: 218,
		quantity: 101,
	},
	{
		garage_id: 48,
		part_id: 219,
		quantity: 267,
	},
	{
		garage_id: 48,
		part_id: 220,
		quantity: 201,
	},
	{
		garage_id: 48,
		part_id: 221,
		quantity: 415,
	},
	{
		garage_id: 48,
		part_id: 222,
		quantity: 339,
	},
	{
		garage_id: 48,
		part_id: 223,
		quantity: 292,
	},
	{
		garage_id: 48,
		part_id: 224,
		quantity: 448,
	},
	{
		garage_id: 48,
		part_id: 225,
		quantity: 31,
	},
	{
		garage_id: 48,
		part_id: 226,
		quantity: 500,
	},
	{
		garage_id: 48,
		part_id: 227,
		quantity: 415,
	},
	{
		garage_id: 48,
		part_id: 228,
		quantity: 378,
	},
	{
		garage_id: 48,
		part_id: 229,
		quantity: 193,
	},
	{
		garage_id: 48,
		part_id: 230,
		quantity: 10,
	},
	{
		garage_id: 48,
		part_id: 231,
		quantity: 59,
	},
	{
		garage_id: 48,
		part_id: 232,
		quantity: 255,
	},
	{
		garage_id: 48,
		part_id: 233,
		quantity: 412,
	},
	{
		garage_id: 48,
		part_id: 234,
		quantity: 417,
	},
	{
		garage_id: 48,
		part_id: 235,
		quantity: 396,
	},
	{
		garage_id: 48,
		part_id: 236,
		quantity: 155,
	},
	{
		garage_id: 48,
		part_id: 237,
		quantity: 83,
	},
	{
		garage_id: 48,
		part_id: 238,
		quantity: 309,
	},
	{
		garage_id: 48,
		part_id: 239,
		quantity: 20,
	},
	{
		garage_id: 48,
		part_id: 240,
		quantity: 485,
	},
	{
		garage_id: 48,
		part_id: 241,
		quantity: 371,
	},
	{
		garage_id: 48,
		part_id: 242,
		quantity: 426,
	},
	{
		garage_id: 48,
		part_id: 243,
		quantity: 198,
	},
	{
		garage_id: 48,
		part_id: 244,
		quantity: 66,
	},
	{
		garage_id: 48,
		part_id: 245,
		quantity: 299,
	},
	{
		garage_id: 48,
		part_id: 246,
		quantity: 273,
	},
	{
		garage_id: 48,
		part_id: 247,
		quantity: 47,
	},
	{
		garage_id: 48,
		part_id: 248,
		quantity: 130,
	},
	{
		garage_id: 48,
		part_id: 249,
		quantity: 486,
	},
	{
		garage_id: 48,
		part_id: 250,
		quantity: 165,
	},
	{
		garage_id: 48,
		part_id: 251,
		quantity: 47,
	},
	{
		garage_id: 48,
		part_id: 252,
		quantity: 21,
	},
	{
		garage_id: 48,
		part_id: 253,
		quantity: 162,
	},
	{
		garage_id: 48,
		part_id: 254,
		quantity: 299,
	},
	{
		garage_id: 48,
		part_id: 255,
		quantity: 193,
	},
	{
		garage_id: 48,
		part_id: 256,
		quantity: 410,
	},
	{
		garage_id: 48,
		part_id: 257,
		quantity: 65,
	},
	{
		garage_id: 48,
		part_id: 258,
		quantity: 177,
	},
	{
		garage_id: 48,
		part_id: 259,
		quantity: 136,
	},
	{
		garage_id: 48,
		part_id: 260,
		quantity: 339,
	},
	{
		garage_id: 48,
		part_id: 261,
		quantity: 154,
	},
	{
		garage_id: 48,
		part_id: 262,
		quantity: 462,
	},
	{
		garage_id: 48,
		part_id: 263,
		quantity: 296,
	},
	{
		garage_id: 48,
		part_id: 264,
		quantity: 15,
	},
	{
		garage_id: 48,
		part_id: 265,
		quantity: 280,
	},
	{
		garage_id: 48,
		part_id: 266,
		quantity: 326,
	},
	{
		garage_id: 48,
		part_id: 267,
		quantity: 247,
	},
	{
		garage_id: 48,
		part_id: 268,
		quantity: 285,
	},
	{
		garage_id: 48,
		part_id: 269,
		quantity: 22,
	},
	{
		garage_id: 48,
		part_id: 270,
		quantity: 36,
	},
	{
		garage_id: 48,
		part_id: 271,
		quantity: 162,
	},
	{
		garage_id: 48,
		part_id: 272,
		quantity: 257,
	},
	{
		garage_id: 48,
		part_id: 273,
		quantity: 261,
	},
	{
		garage_id: 48,
		part_id: 274,
		quantity: 134,
	},
	{
		garage_id: 48,
		part_id: 275,
		quantity: 280,
	},
	{
		garage_id: 48,
		part_id: 276,
		quantity: 11,
	},
	{
		garage_id: 48,
		part_id: 277,
		quantity: 447,
	},
	{
		garage_id: 48,
		part_id: 278,
		quantity: 53,
	},
	{
		garage_id: 48,
		part_id: 279,
		quantity: 129,
	},
	{
		garage_id: 48,
		part_id: 280,
		quantity: 27,
	},
	{
		garage_id: 48,
		part_id: 281,
		quantity: 259,
	},
	{
		garage_id: 48,
		part_id: 282,
		quantity: 12,
	},
	{
		garage_id: 48,
		part_id: 283,
		quantity: 302,
	},
	{
		garage_id: 48,
		part_id: 284,
		quantity: 302,
	},
	{
		garage_id: 48,
		part_id: 285,
		quantity: 394,
	},
	{
		garage_id: 48,
		part_id: 286,
		quantity: 461,
	},
	{
		garage_id: 48,
		part_id: 287,
		quantity: 34,
	},
	{
		garage_id: 48,
		part_id: 288,
		quantity: 70,
	},
	{
		garage_id: 48,
		part_id: 289,
		quantity: 343,
	},
	{
		garage_id: 48,
		part_id: 290,
		quantity: 168,
	},
	{
		garage_id: 48,
		part_id: 291,
		quantity: 6,
	},
	{
		garage_id: 48,
		part_id: 292,
		quantity: 115,
	},
	{
		garage_id: 48,
		part_id: 293,
		quantity: 238,
	},
	{
		garage_id: 48,
		part_id: 294,
		quantity: 170,
	},
	{
		garage_id: 48,
		part_id: 295,
		quantity: 152,
	},
	{
		garage_id: 48,
		part_id: 296,
		quantity: 170,
	},
	{
		garage_id: 48,
		part_id: 297,
		quantity: 28,
	},
	{
		garage_id: 48,
		part_id: 298,
		quantity: 189,
	},
	{
		garage_id: 48,
		part_id: 299,
		quantity: 280,
	},
	{
		garage_id: 48,
		part_id: 300,
		quantity: 22,
	},
	{
		garage_id: 48,
		part_id: 301,
		quantity: 342,
	},
	{
		garage_id: 48,
		part_id: 302,
		quantity: 286,
	},
	{
		garage_id: 48,
		part_id: 303,
		quantity: 29,
	},
	{
		garage_id: 48,
		part_id: 304,
		quantity: 54,
	},
	{
		garage_id: 48,
		part_id: 305,
		quantity: 170,
	},
	{
		garage_id: 48,
		part_id: 306,
		quantity: 189,
	},
	{
		garage_id: 48,
		part_id: 307,
		quantity: 341,
	},
	{
		garage_id: 48,
		part_id: 308,
		quantity: 160,
	},
	{
		garage_id: 48,
		part_id: 309,
		quantity: 175,
	},
	{
		garage_id: 48,
		part_id: 310,
		quantity: 325,
	},
	{
		garage_id: 48,
		part_id: 311,
		quantity: 44,
	},
	{
		garage_id: 48,
		part_id: 312,
		quantity: 128,
	},
	{
		garage_id: 48,
		part_id: 313,
		quantity: 316,
	},
	{
		garage_id: 48,
		part_id: 314,
		quantity: 24,
	},
	{
		garage_id: 48,
		part_id: 315,
		quantity: 325,
	},
	{
		garage_id: 48,
		part_id: 316,
		quantity: 101,
	},
	{
		garage_id: 48,
		part_id: 317,
		quantity: 130,
	},
	{
		garage_id: 48,
		part_id: 318,
		quantity: 251,
	},
	{
		garage_id: 48,
		part_id: 319,
		quantity: 464,
	},
	{
		garage_id: 48,
		part_id: 320,
		quantity: 458,
	},
	{
		garage_id: 48,
		part_id: 321,
		quantity: 289,
	},
	{
		garage_id: 48,
		part_id: 322,
		quantity: 423,
	},
	{
		garage_id: 48,
		part_id: 323,
		quantity: 418,
	},
	{
		garage_id: 48,
		part_id: 324,
		quantity: 148,
	},
	{
		garage_id: 48,
		part_id: 325,
		quantity: 243,
	},
	{
		garage_id: 48,
		part_id: 326,
		quantity: 233,
	},
	{
		garage_id: 48,
		part_id: 327,
		quantity: 192,
	},
	{
		garage_id: 48,
		part_id: 328,
		quantity: 142,
	},
	{
		garage_id: 48,
		part_id: 329,
		quantity: 206,
	},
	{
		garage_id: 48,
		part_id: 330,
		quantity: 113,
	},
	{
		garage_id: 48,
		part_id: 331,
		quantity: 253,
	},
	{
		garage_id: 48,
		part_id: 332,
		quantity: 245,
	},
	{
		garage_id: 48,
		part_id: 333,
		quantity: 449,
	},
	{
		garage_id: 48,
		part_id: 334,
		quantity: 464,
	},
	{
		garage_id: 48,
		part_id: 335,
		quantity: 75,
	},
	{
		garage_id: 48,
		part_id: 336,
		quantity: 69,
	},
	{
		garage_id: 48,
		part_id: 337,
		quantity: 252,
	},
	{
		garage_id: 48,
		part_id: 338,
		quantity: 455,
	},
	{
		garage_id: 48,
		part_id: 339,
		quantity: 144,
	},
	{
		garage_id: 48,
		part_id: 340,
		quantity: 163,
	},
	{
		garage_id: 48,
		part_id: 341,
		quantity: 396,
	},
	{
		garage_id: 48,
		part_id: 342,
		quantity: 396,
	},
	{
		garage_id: 48,
		part_id: 343,
		quantity: 404,
	},
	{
		garage_id: 48,
		part_id: 344,
		quantity: 42,
	},
	{
		garage_id: 48,
		part_id: 345,
		quantity: 333,
	},
	{
		garage_id: 48,
		part_id: 346,
		quantity: 409,
	},
	{
		garage_id: 48,
		part_id: 347,
		quantity: 239,
	},
	{
		garage_id: 48,
		part_id: 348,
		quantity: 231,
	},
	{
		garage_id: 48,
		part_id: 349,
		quantity: 200,
	},
	{
		garage_id: 48,
		part_id: 350,
		quantity: 123,
	},
	{
		garage_id: 48,
		part_id: 351,
		quantity: 494,
	},
	{
		garage_id: 48,
		part_id: 352,
		quantity: 20,
	},
	{
		garage_id: 48,
		part_id: 353,
		quantity: 496,
	},
	{
		garage_id: 48,
		part_id: 354,
		quantity: 146,
	},
	{
		garage_id: 48,
		part_id: 355,
		quantity: 310,
	},
	{
		garage_id: 48,
		part_id: 356,
		quantity: 121,
	},
	{
		garage_id: 48,
		part_id: 357,
		quantity: 32,
	},
	{
		garage_id: 48,
		part_id: 358,
		quantity: 496,
	},
	{
		garage_id: 48,
		part_id: 359,
		quantity: 460,
	},
	{
		garage_id: 48,
		part_id: 360,
		quantity: 172,
	},
	{
		garage_id: 48,
		part_id: 361,
		quantity: 99,
	},
	{
		garage_id: 48,
		part_id: 362,
		quantity: 58,
	},
	{
		garage_id: 48,
		part_id: 363,
		quantity: 43,
	},
	{
		garage_id: 48,
		part_id: 364,
		quantity: 129,
	},
	{
		garage_id: 48,
		part_id: 365,
		quantity: 53,
	},
	{
		garage_id: 48,
		part_id: 366,
		quantity: 69,
	},
	{
		garage_id: 48,
		part_id: 367,
		quantity: 331,
	},
	{
		garage_id: 48,
		part_id: 368,
		quantity: 124,
	},
	{
		garage_id: 48,
		part_id: 369,
		quantity: 460,
	},
	{
		garage_id: 48,
		part_id: 370,
		quantity: 120,
	},
	{
		garage_id: 48,
		part_id: 371,
		quantity: 56,
	},
	{
		garage_id: 48,
		part_id: 372,
		quantity: 241,
	},
	{
		garage_id: 48,
		part_id: 373,
		quantity: 349,
	},
	{
		garage_id: 48,
		part_id: 374,
		quantity: 109,
	},
	{
		garage_id: 48,
		part_id: 375,
		quantity: 273,
	},
	{
		garage_id: 48,
		part_id: 376,
		quantity: 324,
	},
	{
		garage_id: 48,
		part_id: 377,
		quantity: 111,
	},
	{
		garage_id: 48,
		part_id: 378,
		quantity: 203,
	},
	{
		garage_id: 48,
		part_id: 379,
		quantity: 320,
	},
	{
		garage_id: 48,
		part_id: 380,
		quantity: 150,
	},
	{
		garage_id: 48,
		part_id: 381,
		quantity: 438,
	},
	{
		garage_id: 48,
		part_id: 382,
		quantity: 429,
	},
	{
		garage_id: 48,
		part_id: 383,
		quantity: 64,
	},
	{
		garage_id: 48,
		part_id: 384,
		quantity: 321,
	},
	{
		garage_id: 48,
		part_id: 385,
		quantity: 447,
	},
	{
		garage_id: 48,
		part_id: 386,
		quantity: 260,
	},
	{
		garage_id: 48,
		part_id: 387,
		quantity: 407,
	},
	{
		garage_id: 48,
		part_id: 388,
		quantity: 167,
	},
	{
		garage_id: 48,
		part_id: 389,
		quantity: 498,
	},
	{
		garage_id: 48,
		part_id: 390,
		quantity: 459,
	},
	{
		garage_id: 48,
		part_id: 391,
		quantity: 164,
	},
	{
		garage_id: 48,
		part_id: 392,
		quantity: 401,
	},
	{
		garage_id: 48,
		part_id: 393,
		quantity: 40,
	},
	{
		garage_id: 48,
		part_id: 394,
		quantity: 162,
	},
	{
		garage_id: 48,
		part_id: 395,
		quantity: 114,
	},
	{
		garage_id: 48,
		part_id: 396,
		quantity: 335,
	},
	{
		garage_id: 48,
		part_id: 397,
		quantity: 368,
	},
	{
		garage_id: 48,
		part_id: 398,
		quantity: 28,
	},
	{
		garage_id: 48,
		part_id: 399,
		quantity: 386,
	},
	{
		garage_id: 49,
		part_id: 0,
		quantity: 217,
	},
	{
		garage_id: 49,
		part_id: 1,
		quantity: 98,
	},
	{
		garage_id: 49,
		part_id: 2,
		quantity: 99,
	},
	{
		garage_id: 49,
		part_id: 3,
		quantity: 408,
	},
	{
		garage_id: 49,
		part_id: 4,
		quantity: 369,
	},
	{
		garage_id: 49,
		part_id: 5,
		quantity: 243,
	},
	{
		garage_id: 49,
		part_id: 6,
		quantity: 254,
	},
	{
		garage_id: 49,
		part_id: 7,
		quantity: 122,
	},
	{
		garage_id: 49,
		part_id: 8,
		quantity: 440,
	},
	{
		garage_id: 49,
		part_id: 9,
		quantity: 52,
	},
	{
		garage_id: 49,
		part_id: 10,
		quantity: 281,
	},
	{
		garage_id: 49,
		part_id: 11,
		quantity: 4,
	},
	{
		garage_id: 49,
		part_id: 12,
		quantity: 450,
	},
	{
		garage_id: 49,
		part_id: 13,
		quantity: 485,
	},
	{
		garage_id: 49,
		part_id: 14,
		quantity: 292,
	},
	{
		garage_id: 49,
		part_id: 15,
		quantity: 100,
	},
	{
		garage_id: 49,
		part_id: 16,
		quantity: 344,
	},
	{
		garage_id: 49,
		part_id: 17,
		quantity: 103,
	},
	{
		garage_id: 49,
		part_id: 18,
		quantity: 487,
	},
	{
		garage_id: 49,
		part_id: 19,
		quantity: 373,
	},
	{
		garage_id: 49,
		part_id: 20,
		quantity: 78,
	},
	{
		garage_id: 49,
		part_id: 21,
		quantity: 274,
	},
	{
		garage_id: 49,
		part_id: 22,
		quantity: 41,
	},
	{
		garage_id: 49,
		part_id: 23,
		quantity: 449,
	},
	{
		garage_id: 49,
		part_id: 24,
		quantity: 321,
	},
	{
		garage_id: 49,
		part_id: 25,
		quantity: 114,
	},
	{
		garage_id: 49,
		part_id: 26,
		quantity: 258,
	},
	{
		garage_id: 49,
		part_id: 27,
		quantity: 349,
	},
	{
		garage_id: 49,
		part_id: 28,
		quantity: 464,
	},
	{
		garage_id: 49,
		part_id: 29,
		quantity: 422,
	},
	{
		garage_id: 49,
		part_id: 30,
		quantity: 155,
	},
	{
		garage_id: 49,
		part_id: 31,
		quantity: 14,
	},
	{
		garage_id: 49,
		part_id: 32,
		quantity: 430,
	},
	{
		garage_id: 49,
		part_id: 33,
		quantity: 111,
	},
	{
		garage_id: 49,
		part_id: 34,
		quantity: 287,
	},
	{
		garage_id: 49,
		part_id: 35,
		quantity: 108,
	},
	{
		garage_id: 49,
		part_id: 36,
		quantity: 459,
	},
	{
		garage_id: 49,
		part_id: 37,
		quantity: 391,
	},
	{
		garage_id: 49,
		part_id: 38,
		quantity: 217,
	},
	{
		garage_id: 49,
		part_id: 39,
		quantity: 156,
	},
	{
		garage_id: 49,
		part_id: 40,
		quantity: 82,
	},
	{
		garage_id: 49,
		part_id: 41,
		quantity: 181,
	},
	{
		garage_id: 49,
		part_id: 42,
		quantity: 337,
	},
	{
		garage_id: 49,
		part_id: 43,
		quantity: 33,
	},
	{
		garage_id: 49,
		part_id: 44,
		quantity: 46,
	},
	{
		garage_id: 49,
		part_id: 45,
		quantity: 229,
	},
	{
		garage_id: 49,
		part_id: 46,
		quantity: 321,
	},
	{
		garage_id: 49,
		part_id: 47,
		quantity: 430,
	},
	{
		garage_id: 49,
		part_id: 48,
		quantity: 308,
	},
	{
		garage_id: 49,
		part_id: 49,
		quantity: 5,
	},
	{
		garage_id: 49,
		part_id: 50,
		quantity: 475,
	},
	{
		garage_id: 49,
		part_id: 51,
		quantity: 445,
	},
	{
		garage_id: 49,
		part_id: 52,
		quantity: 377,
	},
	{
		garage_id: 49,
		part_id: 53,
		quantity: 268,
	},
	{
		garage_id: 49,
		part_id: 54,
		quantity: 413,
	},
	{
		garage_id: 49,
		part_id: 55,
		quantity: 346,
	},
	{
		garage_id: 49,
		part_id: 56,
		quantity: 408,
	},
	{
		garage_id: 49,
		part_id: 57,
		quantity: 235,
	},
	{
		garage_id: 49,
		part_id: 58,
		quantity: 490,
	},
	{
		garage_id: 49,
		part_id: 59,
		quantity: 167,
	},
	{
		garage_id: 49,
		part_id: 60,
		quantity: 485,
	},
	{
		garage_id: 49,
		part_id: 61,
		quantity: 497,
	},
	{
		garage_id: 49,
		part_id: 62,
		quantity: 472,
	},
	{
		garage_id: 49,
		part_id: 63,
		quantity: 250,
	},
	{
		garage_id: 49,
		part_id: 64,
		quantity: 236,
	},
	{
		garage_id: 49,
		part_id: 65,
		quantity: 420,
	},
	{
		garage_id: 49,
		part_id: 66,
		quantity: 430,
	},
	{
		garage_id: 49,
		part_id: 67,
		quantity: 443,
	},
	{
		garage_id: 49,
		part_id: 68,
		quantity: 252,
	},
	{
		garage_id: 49,
		part_id: 69,
		quantity: 326,
	},
	{
		garage_id: 49,
		part_id: 70,
		quantity: 237,
	},
	{
		garage_id: 49,
		part_id: 71,
		quantity: 153,
	},
	{
		garage_id: 49,
		part_id: 72,
		quantity: 157,
	},
	{
		garage_id: 49,
		part_id: 73,
		quantity: 197,
	},
	{
		garage_id: 49,
		part_id: 74,
		quantity: 86,
	},
	{
		garage_id: 49,
		part_id: 75,
		quantity: 332,
	},
	{
		garage_id: 49,
		part_id: 76,
		quantity: 3,
	},
	{
		garage_id: 49,
		part_id: 77,
		quantity: 196,
	},
	{
		garage_id: 49,
		part_id: 78,
		quantity: 1,
	},
	{
		garage_id: 49,
		part_id: 79,
		quantity: 331,
	},
	{
		garage_id: 49,
		part_id: 80,
		quantity: 301,
	},
	{
		garage_id: 49,
		part_id: 81,
		quantity: 23,
	},
	{
		garage_id: 49,
		part_id: 82,
		quantity: 469,
	},
	{
		garage_id: 49,
		part_id: 83,
		quantity: 41,
	},
	{
		garage_id: 49,
		part_id: 84,
		quantity: 1,
	},
	{
		garage_id: 49,
		part_id: 85,
		quantity: 441,
	},
	{
		garage_id: 49,
		part_id: 86,
		quantity: 29,
	},
	{
		garage_id: 49,
		part_id: 87,
		quantity: 418,
	},
	{
		garage_id: 49,
		part_id: 88,
		quantity: 472,
	},
	{
		garage_id: 49,
		part_id: 89,
		quantity: 316,
	},
	{
		garage_id: 49,
		part_id: 90,
		quantity: 302,
	},
	{
		garage_id: 49,
		part_id: 91,
		quantity: 498,
	},
	{
		garage_id: 49,
		part_id: 92,
		quantity: 181,
	},
	{
		garage_id: 49,
		part_id: 93,
		quantity: 278,
	},
	{
		garage_id: 49,
		part_id: 94,
		quantity: 392,
	},
	{
		garage_id: 49,
		part_id: 95,
		quantity: 189,
	},
	{
		garage_id: 49,
		part_id: 96,
		quantity: 2,
	},
	{
		garage_id: 49,
		part_id: 97,
		quantity: 294,
	},
	{
		garage_id: 49,
		part_id: 98,
		quantity: 44,
	},
	{
		garage_id: 49,
		part_id: 99,
		quantity: 362,
	},
	{
		garage_id: 49,
		part_id: 100,
		quantity: 55,
	},
	{
		garage_id: 49,
		part_id: 101,
		quantity: 166,
	},
	{
		garage_id: 49,
		part_id: 102,
		quantity: 444,
	},
	{
		garage_id: 49,
		part_id: 103,
		quantity: 341,
	},
	{
		garage_id: 49,
		part_id: 104,
		quantity: 103,
	},
	{
		garage_id: 49,
		part_id: 105,
		quantity: 77,
	},
	{
		garage_id: 49,
		part_id: 106,
		quantity: 361,
	},
	{
		garage_id: 49,
		part_id: 107,
		quantity: 72,
	},
	{
		garage_id: 49,
		part_id: 108,
		quantity: 245,
	},
	{
		garage_id: 49,
		part_id: 109,
		quantity: 197,
	},
	{
		garage_id: 49,
		part_id: 110,
		quantity: 331,
	},
	{
		garage_id: 49,
		part_id: 111,
		quantity: 339,
	},
	{
		garage_id: 49,
		part_id: 112,
		quantity: 334,
	},
	{
		garage_id: 49,
		part_id: 113,
		quantity: 21,
	},
	{
		garage_id: 49,
		part_id: 114,
		quantity: 106,
	},
	{
		garage_id: 49,
		part_id: 115,
		quantity: 156,
	},
	{
		garage_id: 49,
		part_id: 116,
		quantity: 280,
	},
	{
		garage_id: 49,
		part_id: 117,
		quantity: 138,
	},
	{
		garage_id: 49,
		part_id: 118,
		quantity: 231,
	},
	{
		garage_id: 49,
		part_id: 119,
		quantity: 382,
	},
	{
		garage_id: 49,
		part_id: 120,
		quantity: 271,
	},
	{
		garage_id: 49,
		part_id: 121,
		quantity: 391,
	},
	{
		garage_id: 49,
		part_id: 122,
		quantity: 374,
	},
	{
		garage_id: 49,
		part_id: 123,
		quantity: 259,
	},
	{
		garage_id: 49,
		part_id: 124,
		quantity: 24,
	},
	{
		garage_id: 49,
		part_id: 125,
		quantity: 433,
	},
	{
		garage_id: 49,
		part_id: 126,
		quantity: 412,
	},
	{
		garage_id: 49,
		part_id: 127,
		quantity: 6,
	},
	{
		garage_id: 49,
		part_id: 128,
		quantity: 134,
	},
	{
		garage_id: 49,
		part_id: 129,
		quantity: 235,
	},
	{
		garage_id: 49,
		part_id: 130,
		quantity: 89,
	},
	{
		garage_id: 49,
		part_id: 131,
		quantity: 343,
	},
	{
		garage_id: 49,
		part_id: 132,
		quantity: 365,
	},
	{
		garage_id: 49,
		part_id: 133,
		quantity: 131,
	},
	{
		garage_id: 49,
		part_id: 134,
		quantity: 473,
	},
	{
		garage_id: 49,
		part_id: 135,
		quantity: 443,
	},
	{
		garage_id: 49,
		part_id: 136,
		quantity: 189,
	},
	{
		garage_id: 49,
		part_id: 137,
		quantity: 245,
	},
	{
		garage_id: 49,
		part_id: 138,
		quantity: 36,
	},
	{
		garage_id: 49,
		part_id: 139,
		quantity: 89,
	},
	{
		garage_id: 49,
		part_id: 140,
		quantity: 323,
	},
	{
		garage_id: 49,
		part_id: 141,
		quantity: 203,
	},
	{
		garage_id: 49,
		part_id: 142,
		quantity: 252,
	},
	{
		garage_id: 49,
		part_id: 143,
		quantity: 239,
	},
	{
		garage_id: 49,
		part_id: 144,
		quantity: 86,
	},
	{
		garage_id: 49,
		part_id: 145,
		quantity: 270,
	},
	{
		garage_id: 49,
		part_id: 146,
		quantity: 3,
	},
	{
		garage_id: 49,
		part_id: 147,
		quantity: 227,
	},
	{
		garage_id: 49,
		part_id: 148,
		quantity: 374,
	},
	{
		garage_id: 49,
		part_id: 149,
		quantity: 465,
	},
	{
		garage_id: 49,
		part_id: 150,
		quantity: 474,
	},
	{
		garage_id: 49,
		part_id: 151,
		quantity: 87,
	},
	{
		garage_id: 49,
		part_id: 152,
		quantity: 26,
	},
	{
		garage_id: 49,
		part_id: 153,
		quantity: 25,
	},
	{
		garage_id: 49,
		part_id: 154,
		quantity: 422,
	},
	{
		garage_id: 49,
		part_id: 155,
		quantity: 306,
	},
	{
		garage_id: 49,
		part_id: 156,
		quantity: 27,
	},
	{
		garage_id: 49,
		part_id: 157,
		quantity: 32,
	},
	{
		garage_id: 49,
		part_id: 158,
		quantity: 177,
	},
	{
		garage_id: 49,
		part_id: 159,
		quantity: 143,
	},
	{
		garage_id: 49,
		part_id: 160,
		quantity: 221,
	},
	{
		garage_id: 49,
		part_id: 161,
		quantity: 193,
	},
	{
		garage_id: 49,
		part_id: 162,
		quantity: 363,
	},
	{
		garage_id: 49,
		part_id: 163,
		quantity: 498,
	},
	{
		garage_id: 49,
		part_id: 164,
		quantity: 282,
	},
	{
		garage_id: 49,
		part_id: 165,
		quantity: 18,
	},
	{
		garage_id: 49,
		part_id: 166,
		quantity: 249,
	},
	{
		garage_id: 49,
		part_id: 167,
		quantity: 387,
	},
	{
		garage_id: 49,
		part_id: 168,
		quantity: 307,
	},
	{
		garage_id: 49,
		part_id: 169,
		quantity: 232,
	},
	{
		garage_id: 49,
		part_id: 170,
		quantity: 448,
	},
	{
		garage_id: 49,
		part_id: 171,
		quantity: 278,
	},
	{
		garage_id: 49,
		part_id: 172,
		quantity: 134,
	},
	{
		garage_id: 49,
		part_id: 173,
		quantity: 90,
	},
	{
		garage_id: 49,
		part_id: 174,
		quantity: 322,
	},
	{
		garage_id: 49,
		part_id: 175,
		quantity: 184,
	},
	{
		garage_id: 49,
		part_id: 176,
		quantity: 161,
	},
	{
		garage_id: 49,
		part_id: 177,
		quantity: 106,
	},
	{
		garage_id: 49,
		part_id: 178,
		quantity: 416,
	},
	{
		garage_id: 49,
		part_id: 179,
		quantity: 296,
	},
	{
		garage_id: 49,
		part_id: 180,
		quantity: 353,
	},
	{
		garage_id: 49,
		part_id: 181,
		quantity: 203,
	},
	{
		garage_id: 49,
		part_id: 182,
		quantity: 44,
	},
	{
		garage_id: 49,
		part_id: 183,
		quantity: 42,
	},
	{
		garage_id: 49,
		part_id: 184,
		quantity: 206,
	},
	{
		garage_id: 49,
		part_id: 185,
		quantity: 398,
	},
	{
		garage_id: 49,
		part_id: 186,
		quantity: 281,
	},
	{
		garage_id: 49,
		part_id: 187,
		quantity: 412,
	},
	{
		garage_id: 49,
		part_id: 188,
		quantity: 500,
	},
	{
		garage_id: 49,
		part_id: 189,
		quantity: 145,
	},
	{
		garage_id: 49,
		part_id: 190,
		quantity: 430,
	},
	{
		garage_id: 49,
		part_id: 191,
		quantity: 426,
	},
	{
		garage_id: 49,
		part_id: 192,
		quantity: 225,
	},
	{
		garage_id: 49,
		part_id: 193,
		quantity: 471,
	},
	{
		garage_id: 49,
		part_id: 194,
		quantity: 360,
	},
	{
		garage_id: 49,
		part_id: 195,
		quantity: 261,
	},
	{
		garage_id: 49,
		part_id: 196,
		quantity: 21,
	},
	{
		garage_id: 49,
		part_id: 197,
		quantity: 158,
	},
	{
		garage_id: 49,
		part_id: 198,
		quantity: 3,
	},
	{
		garage_id: 49,
		part_id: 199,
		quantity: 181,
	},
	{
		garage_id: 49,
		part_id: 200,
		quantity: 243,
	},
	{
		garage_id: 49,
		part_id: 201,
		quantity: 92,
	},
	{
		garage_id: 49,
		part_id: 202,
		quantity: 450,
	},
	{
		garage_id: 49,
		part_id: 203,
		quantity: 146,
	},
	{
		garage_id: 49,
		part_id: 204,
		quantity: 399,
	},
	{
		garage_id: 49,
		part_id: 205,
		quantity: 397,
	},
	{
		garage_id: 49,
		part_id: 206,
		quantity: 325,
	},
	{
		garage_id: 49,
		part_id: 207,
		quantity: 173,
	},
	{
		garage_id: 49,
		part_id: 208,
		quantity: 267,
	},
	{
		garage_id: 49,
		part_id: 209,
		quantity: 300,
	},
	{
		garage_id: 49,
		part_id: 210,
		quantity: 255,
	},
	{
		garage_id: 49,
		part_id: 211,
		quantity: 154,
	},
	{
		garage_id: 49,
		part_id: 212,
		quantity: 381,
	},
	{
		garage_id: 49,
		part_id: 213,
		quantity: 424,
	},
	{
		garage_id: 49,
		part_id: 214,
		quantity: 455,
	},
	{
		garage_id: 49,
		part_id: 215,
		quantity: 48,
	},
	{
		garage_id: 49,
		part_id: 216,
		quantity: 305,
	},
	{
		garage_id: 49,
		part_id: 217,
		quantity: 203,
	},
	{
		garage_id: 49,
		part_id: 218,
		quantity: 322,
	},
	{
		garage_id: 49,
		part_id: 219,
		quantity: 136,
	},
	{
		garage_id: 49,
		part_id: 220,
		quantity: 170,
	},
	{
		garage_id: 49,
		part_id: 221,
		quantity: 101,
	},
	{
		garage_id: 49,
		part_id: 222,
		quantity: 241,
	},
	{
		garage_id: 49,
		part_id: 223,
		quantity: 162,
	},
	{
		garage_id: 49,
		part_id: 224,
		quantity: 249,
	},
	{
		garage_id: 49,
		part_id: 225,
		quantity: 102,
	},
	{
		garage_id: 49,
		part_id: 226,
		quantity: 308,
	},
	{
		garage_id: 49,
		part_id: 227,
		quantity: 417,
	},
	{
		garage_id: 49,
		part_id: 228,
		quantity: 471,
	},
	{
		garage_id: 49,
		part_id: 229,
		quantity: 130,
	},
	{
		garage_id: 49,
		part_id: 230,
		quantity: 69,
	},
	{
		garage_id: 49,
		part_id: 231,
		quantity: 448,
	},
	{
		garage_id: 49,
		part_id: 232,
		quantity: 193,
	},
	{
		garage_id: 49,
		part_id: 233,
		quantity: 194,
	},
	{
		garage_id: 49,
		part_id: 234,
		quantity: 34,
	},
	{
		garage_id: 49,
		part_id: 235,
		quantity: 20,
	},
	{
		garage_id: 49,
		part_id: 236,
		quantity: 494,
	},
	{
		garage_id: 49,
		part_id: 237,
		quantity: 306,
	},
	{
		garage_id: 49,
		part_id: 238,
		quantity: 310,
	},
	{
		garage_id: 49,
		part_id: 239,
		quantity: 452,
	},
	{
		garage_id: 49,
		part_id: 240,
		quantity: 185,
	},
	{
		garage_id: 49,
		part_id: 241,
		quantity: 494,
	},
	{
		garage_id: 49,
		part_id: 242,
		quantity: 274,
	},
	{
		garage_id: 49,
		part_id: 243,
		quantity: 404,
	},
	{
		garage_id: 49,
		part_id: 244,
		quantity: 112,
	},
	{
		garage_id: 49,
		part_id: 245,
		quantity: 349,
	},
	{
		garage_id: 49,
		part_id: 246,
		quantity: 122,
	},
	{
		garage_id: 49,
		part_id: 247,
		quantity: 2,
	},
	{
		garage_id: 49,
		part_id: 248,
		quantity: 187,
	},
	{
		garage_id: 49,
		part_id: 249,
		quantity: 347,
	},
	{
		garage_id: 49,
		part_id: 250,
		quantity: 121,
	},
	{
		garage_id: 49,
		part_id: 251,
		quantity: 301,
	},
	{
		garage_id: 49,
		part_id: 252,
		quantity: 280,
	},
	{
		garage_id: 49,
		part_id: 253,
		quantity: 45,
	},
	{
		garage_id: 49,
		part_id: 254,
		quantity: 337,
	},
	{
		garage_id: 49,
		part_id: 255,
		quantity: 358,
	},
	{
		garage_id: 49,
		part_id: 256,
		quantity: 296,
	},
	{
		garage_id: 49,
		part_id: 257,
		quantity: 472,
	},
	{
		garage_id: 49,
		part_id: 258,
		quantity: 101,
	},
	{
		garage_id: 49,
		part_id: 259,
		quantity: 230,
	},
	{
		garage_id: 49,
		part_id: 260,
		quantity: 451,
	},
	{
		garage_id: 49,
		part_id: 261,
		quantity: 63,
	},
	{
		garage_id: 49,
		part_id: 262,
		quantity: 245,
	},
	{
		garage_id: 49,
		part_id: 263,
		quantity: 180,
	},
	{
		garage_id: 49,
		part_id: 264,
		quantity: 318,
	},
	{
		garage_id: 49,
		part_id: 265,
		quantity: 367,
	},
	{
		garage_id: 49,
		part_id: 266,
		quantity: 294,
	},
	{
		garage_id: 49,
		part_id: 267,
		quantity: 377,
	},
	{
		garage_id: 49,
		part_id: 268,
		quantity: 492,
	},
	{
		garage_id: 49,
		part_id: 269,
		quantity: 79,
	},
	{
		garage_id: 49,
		part_id: 270,
		quantity: 438,
	},
	{
		garage_id: 49,
		part_id: 271,
		quantity: 347,
	},
	{
		garage_id: 49,
		part_id: 272,
		quantity: 250,
	},
	{
		garage_id: 49,
		part_id: 273,
		quantity: 471,
	},
	{
		garage_id: 49,
		part_id: 274,
		quantity: 33,
	},
	{
		garage_id: 49,
		part_id: 275,
		quantity: 372,
	},
	{
		garage_id: 49,
		part_id: 276,
		quantity: 38,
	},
	{
		garage_id: 49,
		part_id: 277,
		quantity: 462,
	},
	{
		garage_id: 49,
		part_id: 278,
		quantity: 154,
	},
	{
		garage_id: 49,
		part_id: 279,
		quantity: 230,
	},
	{
		garage_id: 49,
		part_id: 280,
		quantity: 397,
	},
	{
		garage_id: 49,
		part_id: 281,
		quantity: 410,
	},
	{
		garage_id: 49,
		part_id: 282,
		quantity: 393,
	},
	{
		garage_id: 49,
		part_id: 283,
		quantity: 301,
	},
	{
		garage_id: 49,
		part_id: 284,
		quantity: 99,
	},
	{
		garage_id: 49,
		part_id: 285,
		quantity: 148,
	},
	{
		garage_id: 49,
		part_id: 286,
		quantity: 156,
	},
	{
		garage_id: 49,
		part_id: 287,
		quantity: 80,
	},
	{
		garage_id: 49,
		part_id: 288,
		quantity: 358,
	},
	{
		garage_id: 49,
		part_id: 289,
		quantity: 107,
	},
	{
		garage_id: 49,
		part_id: 290,
		quantity: 200,
	},
	{
		garage_id: 49,
		part_id: 291,
		quantity: 377,
	},
	{
		garage_id: 49,
		part_id: 292,
		quantity: 211,
	},
	{
		garage_id: 49,
		part_id: 293,
		quantity: 410,
	},
	{
		garage_id: 49,
		part_id: 294,
		quantity: 184,
	},
	{
		garage_id: 49,
		part_id: 295,
		quantity: 363,
	},
	{
		garage_id: 49,
		part_id: 296,
		quantity: 377,
	},
	{
		garage_id: 49,
		part_id: 297,
		quantity: 418,
	},
	{
		garage_id: 49,
		part_id: 298,
		quantity: 425,
	},
	{
		garage_id: 49,
		part_id: 299,
		quantity: 495,
	},
	{
		garage_id: 49,
		part_id: 300,
		quantity: 338,
	},
	{
		garage_id: 49,
		part_id: 301,
		quantity: 298,
	},
	{
		garage_id: 49,
		part_id: 302,
		quantity: 159,
	},
	{
		garage_id: 49,
		part_id: 303,
		quantity: 82,
	},
	{
		garage_id: 49,
		part_id: 304,
		quantity: 113,
	},
	{
		garage_id: 49,
		part_id: 305,
		quantity: 434,
	},
	{
		garage_id: 49,
		part_id: 306,
		quantity: 69,
	},
	{
		garage_id: 49,
		part_id: 307,
		quantity: 123,
	},
	{
		garage_id: 49,
		part_id: 308,
		quantity: 196,
	},
	{
		garage_id: 49,
		part_id: 309,
		quantity: 33,
	},
	{
		garage_id: 49,
		part_id: 310,
		quantity: 333,
	},
	{
		garage_id: 49,
		part_id: 311,
		quantity: 345,
	},
	{
		garage_id: 49,
		part_id: 312,
		quantity: 229,
	},
	{
		garage_id: 49,
		part_id: 313,
		quantity: 420,
	},
	{
		garage_id: 49,
		part_id: 314,
		quantity: 383,
	},
	{
		garage_id: 49,
		part_id: 315,
		quantity: 263,
	},
	{
		garage_id: 49,
		part_id: 316,
		quantity: 316,
	},
	{
		garage_id: 49,
		part_id: 317,
		quantity: 399,
	},
	{
		garage_id: 49,
		part_id: 318,
		quantity: 193,
	},
	{
		garage_id: 49,
		part_id: 319,
		quantity: 435,
	},
	{
		garage_id: 49,
		part_id: 320,
		quantity: 385,
	},
	{
		garage_id: 49,
		part_id: 321,
		quantity: 326,
	},
	{
		garage_id: 49,
		part_id: 322,
		quantity: 45,
	},
	{
		garage_id: 49,
		part_id: 323,
		quantity: 128,
	},
	{
		garage_id: 49,
		part_id: 324,
		quantity: 40,
	},
	{
		garage_id: 49,
		part_id: 325,
		quantity: 303,
	},
	{
		garage_id: 49,
		part_id: 326,
		quantity: 478,
	},
	{
		garage_id: 49,
		part_id: 327,
		quantity: 140,
	},
	{
		garage_id: 49,
		part_id: 328,
		quantity: 145,
	},
	{
		garage_id: 49,
		part_id: 329,
		quantity: 222,
	},
	{
		garage_id: 49,
		part_id: 330,
		quantity: 402,
	},
	{
		garage_id: 49,
		part_id: 331,
		quantity: 470,
	},
	{
		garage_id: 49,
		part_id: 332,
		quantity: 221,
	},
	{
		garage_id: 49,
		part_id: 333,
		quantity: 147,
	},
	{
		garage_id: 49,
		part_id: 334,
		quantity: 157,
	},
	{
		garage_id: 49,
		part_id: 335,
		quantity: 169,
	},
	{
		garage_id: 49,
		part_id: 336,
		quantity: 100,
	},
	{
		garage_id: 49,
		part_id: 337,
		quantity: 327,
	},
	{
		garage_id: 49,
		part_id: 338,
		quantity: 233,
	},
	{
		garage_id: 49,
		part_id: 339,
		quantity: 12,
	},
	{
		garage_id: 49,
		part_id: 340,
		quantity: 332,
	},
	{
		garage_id: 49,
		part_id: 341,
		quantity: 248,
	},
	{
		garage_id: 49,
		part_id: 342,
		quantity: 188,
	},
	{
		garage_id: 49,
		part_id: 343,
		quantity: 85,
	},
	{
		garage_id: 49,
		part_id: 344,
		quantity: 69,
	},
	{
		garage_id: 49,
		part_id: 345,
		quantity: 56,
	},
	{
		garage_id: 49,
		part_id: 346,
		quantity: 273,
	},
	{
		garage_id: 49,
		part_id: 347,
		quantity: 179,
	},
	{
		garage_id: 49,
		part_id: 348,
		quantity: 20,
	},
	{
		garage_id: 49,
		part_id: 349,
		quantity: 294,
	},
	{
		garage_id: 49,
		part_id: 350,
		quantity: 113,
	},
	{
		garage_id: 49,
		part_id: 351,
		quantity: 386,
	},
	{
		garage_id: 49,
		part_id: 352,
		quantity: 94,
	},
	{
		garage_id: 49,
		part_id: 353,
		quantity: 10,
	},
	{
		garage_id: 49,
		part_id: 354,
		quantity: 405,
	},
	{
		garage_id: 49,
		part_id: 355,
		quantity: 194,
	},
	{
		garage_id: 49,
		part_id: 356,
		quantity: 170,
	},
	{
		garage_id: 49,
		part_id: 357,
		quantity: 196,
	},
	{
		garage_id: 49,
		part_id: 358,
		quantity: 233,
	},
	{
		garage_id: 49,
		part_id: 359,
		quantity: 13,
	},
	{
		garage_id: 49,
		part_id: 360,
		quantity: 328,
	},
	{
		garage_id: 49,
		part_id: 361,
		quantity: 83,
	},
	{
		garage_id: 49,
		part_id: 362,
		quantity: 254,
	},
	{
		garage_id: 49,
		part_id: 363,
		quantity: 124,
	},
	{
		garage_id: 49,
		part_id: 364,
		quantity: 412,
	},
	{
		garage_id: 49,
		part_id: 365,
		quantity: 485,
	},
	{
		garage_id: 49,
		part_id: 366,
		quantity: 254,
	},
	{
		garage_id: 49,
		part_id: 367,
		quantity: 415,
	},
	{
		garage_id: 49,
		part_id: 368,
		quantity: 204,
	},
	{
		garage_id: 49,
		part_id: 369,
		quantity: 442,
	},
	{
		garage_id: 49,
		part_id: 370,
		quantity: 40,
	},
	{
		garage_id: 49,
		part_id: 371,
		quantity: 133,
	},
	{
		garage_id: 49,
		part_id: 372,
		quantity: 309,
	},
	{
		garage_id: 49,
		part_id: 373,
		quantity: 311,
	},
	{
		garage_id: 49,
		part_id: 374,
		quantity: 499,
	},
	{
		garage_id: 49,
		part_id: 375,
		quantity: 267,
	},
	{
		garage_id: 49,
		part_id: 376,
		quantity: 331,
	},
	{
		garage_id: 49,
		part_id: 377,
		quantity: 46,
	},
	{
		garage_id: 49,
		part_id: 378,
		quantity: 83,
	},
	{
		garage_id: 49,
		part_id: 379,
		quantity: 289,
	},
	{
		garage_id: 49,
		part_id: 380,
		quantity: 45,
	},
	{
		garage_id: 49,
		part_id: 381,
		quantity: 247,
	},
	{
		garage_id: 49,
		part_id: 382,
		quantity: 309,
	},
	{
		garage_id: 49,
		part_id: 383,
		quantity: 500,
	},
	{
		garage_id: 49,
		part_id: 384,
		quantity: 395,
	},
	{
		garage_id: 49,
		part_id: 385,
		quantity: 302,
	},
	{
		garage_id: 49,
		part_id: 386,
		quantity: 296,
	},
	{
		garage_id: 49,
		part_id: 387,
		quantity: 280,
	},
	{
		garage_id: 49,
		part_id: 388,
		quantity: 300,
	},
	{
		garage_id: 49,
		part_id: 389,
		quantity: 22,
	},
	{
		garage_id: 49,
		part_id: 390,
		quantity: 434,
	},
	{
		garage_id: 49,
		part_id: 391,
		quantity: 346,
	},
	{
		garage_id: 49,
		part_id: 392,
		quantity: 258,
	},
	{
		garage_id: 49,
		part_id: 393,
		quantity: 261,
	},
	{
		garage_id: 49,
		part_id: 394,
		quantity: 123,
	},
	{
		garage_id: 49,
		part_id: 395,
		quantity: 340,
	},
	{
		garage_id: 49,
		part_id: 396,
		quantity: 325,
	},
	{
		garage_id: 49,
		part_id: 397,
		quantity: 312,
	},
	{
		garage_id: 49,
		part_id: 398,
		quantity: 28,
	},
	{
		garage_id: 49,
		part_id: 399,
		quantity: 214,
	},
	{
		garage_id: 50,
		part_id: 0,
		quantity: 352,
	},
	{
		garage_id: 50,
		part_id: 1,
		quantity: 248,
	},
	{
		garage_id: 50,
		part_id: 2,
		quantity: 360,
	},
	{
		garage_id: 50,
		part_id: 3,
		quantity: 444,
	},
	{
		garage_id: 50,
		part_id: 4,
		quantity: 325,
	},
	{
		garage_id: 50,
		part_id: 5,
		quantity: 222,
	},
	{
		garage_id: 50,
		part_id: 6,
		quantity: 397,
	},
	{
		garage_id: 50,
		part_id: 7,
		quantity: 226,
	},
	{
		garage_id: 50,
		part_id: 8,
		quantity: 91,
	},
	{
		garage_id: 50,
		part_id: 9,
		quantity: 253,
	},
	{
		garage_id: 50,
		part_id: 10,
		quantity: 21,
	},
	{
		garage_id: 50,
		part_id: 11,
		quantity: 470,
	},
	{
		garage_id: 50,
		part_id: 12,
		quantity: 211,
	},
	{
		garage_id: 50,
		part_id: 13,
		quantity: 487,
	},
	{
		garage_id: 50,
		part_id: 14,
		quantity: 85,
	},
	{
		garage_id: 50,
		part_id: 15,
		quantity: 464,
	},
	{
		garage_id: 50,
		part_id: 16,
		quantity: 39,
	},
	{
		garage_id: 50,
		part_id: 17,
		quantity: 299,
	},
	{
		garage_id: 50,
		part_id: 18,
		quantity: 286,
	},
	{
		garage_id: 50,
		part_id: 19,
		quantity: 228,
	},
	{
		garage_id: 50,
		part_id: 20,
		quantity: 348,
	},
	{
		garage_id: 50,
		part_id: 21,
		quantity: 335,
	},
	{
		garage_id: 50,
		part_id: 22,
		quantity: 47,
	},
	{
		garage_id: 50,
		part_id: 23,
		quantity: 199,
	},
	{
		garage_id: 50,
		part_id: 24,
		quantity: 392,
	},
	{
		garage_id: 50,
		part_id: 25,
		quantity: 417,
	},
	{
		garage_id: 50,
		part_id: 26,
		quantity: 33,
	},
	{
		garage_id: 50,
		part_id: 27,
		quantity: 22,
	},
	{
		garage_id: 50,
		part_id: 28,
		quantity: 65,
	},
	{
		garage_id: 50,
		part_id: 29,
		quantity: 23,
	},
	{
		garage_id: 50,
		part_id: 30,
		quantity: 33,
	},
	{
		garage_id: 50,
		part_id: 31,
		quantity: 137,
	},
	{
		garage_id: 50,
		part_id: 32,
		quantity: 366,
	},
	{
		garage_id: 50,
		part_id: 33,
		quantity: 163,
	},
	{
		garage_id: 50,
		part_id: 34,
		quantity: 321,
	},
	{
		garage_id: 50,
		part_id: 35,
		quantity: 464,
	},
	{
		garage_id: 50,
		part_id: 36,
		quantity: 426,
	},
	{
		garage_id: 50,
		part_id: 37,
		quantity: 451,
	},
	{
		garage_id: 50,
		part_id: 38,
		quantity: 346,
	},
	{
		garage_id: 50,
		part_id: 39,
		quantity: 6,
	},
	{
		garage_id: 50,
		part_id: 40,
		quantity: 186,
	},
	{
		garage_id: 50,
		part_id: 41,
		quantity: 458,
	},
	{
		garage_id: 50,
		part_id: 42,
		quantity: 44,
	},
	{
		garage_id: 50,
		part_id: 43,
		quantity: 385,
	},
	{
		garage_id: 50,
		part_id: 44,
		quantity: 363,
	},
	{
		garage_id: 50,
		part_id: 45,
		quantity: 141,
	},
	{
		garage_id: 50,
		part_id: 46,
		quantity: 433,
	},
	{
		garage_id: 50,
		part_id: 47,
		quantity: 309,
	},
	{
		garage_id: 50,
		part_id: 48,
		quantity: 309,
	},
	{
		garage_id: 50,
		part_id: 49,
		quantity: 476,
	},
	{
		garage_id: 50,
		part_id: 50,
		quantity: 87,
	},
	{
		garage_id: 50,
		part_id: 51,
		quantity: 66,
	},
	{
		garage_id: 50,
		part_id: 52,
		quantity: 353,
	},
	{
		garage_id: 50,
		part_id: 53,
		quantity: 274,
	},
	{
		garage_id: 50,
		part_id: 54,
		quantity: 289,
	},
	{
		garage_id: 50,
		part_id: 55,
		quantity: 500,
	},
	{
		garage_id: 50,
		part_id: 56,
		quantity: 346,
	},
	{
		garage_id: 50,
		part_id: 57,
		quantity: 67,
	},
	{
		garage_id: 50,
		part_id: 58,
		quantity: 40,
	},
	{
		garage_id: 50,
		part_id: 59,
		quantity: 138,
	},
	{
		garage_id: 50,
		part_id: 60,
		quantity: 43,
	},
	{
		garage_id: 50,
		part_id: 61,
		quantity: 187,
	},
	{
		garage_id: 50,
		part_id: 62,
		quantity: 180,
	},
	{
		garage_id: 50,
		part_id: 63,
		quantity: 259,
	},
	{
		garage_id: 50,
		part_id: 64,
		quantity: 154,
	},
	{
		garage_id: 50,
		part_id: 65,
		quantity: 478,
	},
	{
		garage_id: 50,
		part_id: 66,
		quantity: 338,
	},
	{
		garage_id: 50,
		part_id: 67,
		quantity: 59,
	},
	{
		garage_id: 50,
		part_id: 68,
		quantity: 402,
	},
	{
		garage_id: 50,
		part_id: 69,
		quantity: 181,
	},
	{
		garage_id: 50,
		part_id: 70,
		quantity: 101,
	},
	{
		garage_id: 50,
		part_id: 71,
		quantity: 55,
	},
	{
		garage_id: 50,
		part_id: 72,
		quantity: 345,
	},
	{
		garage_id: 50,
		part_id: 73,
		quantity: 234,
	},
	{
		garage_id: 50,
		part_id: 74,
		quantity: 139,
	},
	{
		garage_id: 50,
		part_id: 75,
		quantity: 478,
	},
	{
		garage_id: 50,
		part_id: 76,
		quantity: 247,
	},
	{
		garage_id: 50,
		part_id: 77,
		quantity: 451,
	},
	{
		garage_id: 50,
		part_id: 78,
		quantity: 5,
	},
	{
		garage_id: 50,
		part_id: 79,
		quantity: 342,
	},
	{
		garage_id: 50,
		part_id: 80,
		quantity: 182,
	},
	{
		garage_id: 50,
		part_id: 81,
		quantity: 440,
	},
	{
		garage_id: 50,
		part_id: 82,
		quantity: 291,
	},
	{
		garage_id: 50,
		part_id: 83,
		quantity: 336,
	},
	{
		garage_id: 50,
		part_id: 84,
		quantity: 465,
	},
	{
		garage_id: 50,
		part_id: 85,
		quantity: 452,
	},
	{
		garage_id: 50,
		part_id: 86,
		quantity: 11,
	},
	{
		garage_id: 50,
		part_id: 87,
		quantity: 262,
	},
	{
		garage_id: 50,
		part_id: 88,
		quantity: 359,
	},
	{
		garage_id: 50,
		part_id: 89,
		quantity: 177,
	},
	{
		garage_id: 50,
		part_id: 90,
		quantity: 443,
	},
	{
		garage_id: 50,
		part_id: 91,
		quantity: 211,
	},
	{
		garage_id: 50,
		part_id: 92,
		quantity: 478,
	},
	{
		garage_id: 50,
		part_id: 93,
		quantity: 158,
	},
	{
		garage_id: 50,
		part_id: 94,
		quantity: 38,
	},
	{
		garage_id: 50,
		part_id: 95,
		quantity: 225,
	},
	{
		garage_id: 50,
		part_id: 96,
		quantity: 176,
	},
	{
		garage_id: 50,
		part_id: 97,
		quantity: 474,
	},
	{
		garage_id: 50,
		part_id: 98,
		quantity: 11,
	},
	{
		garage_id: 50,
		part_id: 99,
		quantity: 146,
	},
	{
		garage_id: 50,
		part_id: 100,
		quantity: 406,
	},
	{
		garage_id: 50,
		part_id: 101,
		quantity: 220,
	},
	{
		garage_id: 50,
		part_id: 102,
		quantity: 187,
	},
	{
		garage_id: 50,
		part_id: 103,
		quantity: 441,
	},
	{
		garage_id: 50,
		part_id: 104,
		quantity: 275,
	},
	{
		garage_id: 50,
		part_id: 105,
		quantity: 140,
	},
	{
		garage_id: 50,
		part_id: 106,
		quantity: 235,
	},
	{
		garage_id: 50,
		part_id: 107,
		quantity: 164,
	},
	{
		garage_id: 50,
		part_id: 108,
		quantity: 46,
	},
	{
		garage_id: 50,
		part_id: 109,
		quantity: 156,
	},
	{
		garage_id: 50,
		part_id: 110,
		quantity: 486,
	},
	{
		garage_id: 50,
		part_id: 111,
		quantity: 66,
	},
	{
		garage_id: 50,
		part_id: 112,
		quantity: 66,
	},
	{
		garage_id: 50,
		part_id: 113,
		quantity: 5,
	},
	{
		garage_id: 50,
		part_id: 114,
		quantity: 71,
	},
	{
		garage_id: 50,
		part_id: 115,
		quantity: 51,
	},
	{
		garage_id: 50,
		part_id: 116,
		quantity: 470,
	},
	{
		garage_id: 50,
		part_id: 117,
		quantity: 408,
	},
	{
		garage_id: 50,
		part_id: 118,
		quantity: 468,
	},
	{
		garage_id: 50,
		part_id: 119,
		quantity: 90,
	},
	{
		garage_id: 50,
		part_id: 120,
		quantity: 336,
	},
	{
		garage_id: 50,
		part_id: 121,
		quantity: 293,
	},
	{
		garage_id: 50,
		part_id: 122,
		quantity: 293,
	},
	{
		garage_id: 50,
		part_id: 123,
		quantity: 347,
	},
	{
		garage_id: 50,
		part_id: 124,
		quantity: 29,
	},
	{
		garage_id: 50,
		part_id: 125,
		quantity: 409,
	},
	{
		garage_id: 50,
		part_id: 126,
		quantity: 245,
	},
	{
		garage_id: 50,
		part_id: 127,
		quantity: 360,
	},
	{
		garage_id: 50,
		part_id: 128,
		quantity: 163,
	},
	{
		garage_id: 50,
		part_id: 129,
		quantity: 393,
	},
	{
		garage_id: 50,
		part_id: 130,
		quantity: 215,
	},
	{
		garage_id: 50,
		part_id: 131,
		quantity: 51,
	},
	{
		garage_id: 50,
		part_id: 132,
		quantity: 208,
	},
	{
		garage_id: 50,
		part_id: 133,
		quantity: 87,
	},
	{
		garage_id: 50,
		part_id: 134,
		quantity: 211,
	},
	{
		garage_id: 50,
		part_id: 135,
		quantity: 184,
	},
	{
		garage_id: 50,
		part_id: 136,
		quantity: 358,
	},
	{
		garage_id: 50,
		part_id: 137,
		quantity: 234,
	},
	{
		garage_id: 50,
		part_id: 138,
		quantity: 246,
	},
	{
		garage_id: 50,
		part_id: 139,
		quantity: 36,
	},
	{
		garage_id: 50,
		part_id: 140,
		quantity: 79,
	},
	{
		garage_id: 50,
		part_id: 141,
		quantity: 481,
	},
	{
		garage_id: 50,
		part_id: 142,
		quantity: 432,
	},
	{
		garage_id: 50,
		part_id: 143,
		quantity: 85,
	},
	{
		garage_id: 50,
		part_id: 144,
		quantity: 307,
	},
	{
		garage_id: 50,
		part_id: 145,
		quantity: 486,
	},
	{
		garage_id: 50,
		part_id: 146,
		quantity: 23,
	},
	{
		garage_id: 50,
		part_id: 147,
		quantity: 437,
	},
	{
		garage_id: 50,
		part_id: 148,
		quantity: 480,
	},
	{
		garage_id: 50,
		part_id: 149,
		quantity: 375,
	},
	{
		garage_id: 50,
		part_id: 150,
		quantity: 240,
	},
	{
		garage_id: 50,
		part_id: 151,
		quantity: 468,
	},
	{
		garage_id: 50,
		part_id: 152,
		quantity: 332,
	},
	{
		garage_id: 50,
		part_id: 153,
		quantity: 14,
	},
	{
		garage_id: 50,
		part_id: 154,
		quantity: 29,
	},
	{
		garage_id: 50,
		part_id: 155,
		quantity: 94,
	},
	{
		garage_id: 50,
		part_id: 156,
		quantity: 417,
	},
	{
		garage_id: 50,
		part_id: 157,
		quantity: 418,
	},
	{
		garage_id: 50,
		part_id: 158,
		quantity: 266,
	},
	{
		garage_id: 50,
		part_id: 159,
		quantity: 452,
	},
	{
		garage_id: 50,
		part_id: 160,
		quantity: 177,
	},
	{
		garage_id: 50,
		part_id: 161,
		quantity: 404,
	},
	{
		garage_id: 50,
		part_id: 162,
		quantity: 172,
	},
	{
		garage_id: 50,
		part_id: 163,
		quantity: 326,
	},
	{
		garage_id: 50,
		part_id: 164,
		quantity: 257,
	},
	{
		garage_id: 50,
		part_id: 165,
		quantity: 68,
	},
	{
		garage_id: 50,
		part_id: 166,
		quantity: 421,
	},
	{
		garage_id: 50,
		part_id: 167,
		quantity: 127,
	},
	{
		garage_id: 50,
		part_id: 168,
		quantity: 86,
	},
	{
		garage_id: 50,
		part_id: 169,
		quantity: 116,
	},
	{
		garage_id: 50,
		part_id: 170,
		quantity: 260,
	},
	{
		garage_id: 50,
		part_id: 171,
		quantity: 173,
	},
	{
		garage_id: 50,
		part_id: 172,
		quantity: 477,
	},
	{
		garage_id: 50,
		part_id: 173,
		quantity: 63,
	},
	{
		garage_id: 50,
		part_id: 174,
		quantity: 472,
	},
	{
		garage_id: 50,
		part_id: 175,
		quantity: 204,
	},
	{
		garage_id: 50,
		part_id: 176,
		quantity: 28,
	},
	{
		garage_id: 50,
		part_id: 177,
		quantity: 451,
	},
	{
		garage_id: 50,
		part_id: 178,
		quantity: 57,
	},
	{
		garage_id: 50,
		part_id: 179,
		quantity: 295,
	},
	{
		garage_id: 50,
		part_id: 180,
		quantity: 475,
	},
	{
		garage_id: 50,
		part_id: 181,
		quantity: 2,
	},
	{
		garage_id: 50,
		part_id: 182,
		quantity: 126,
	},
	{
		garage_id: 50,
		part_id: 183,
		quantity: 450,
	},
	{
		garage_id: 50,
		part_id: 184,
		quantity: 121,
	},
	{
		garage_id: 50,
		part_id: 185,
		quantity: 69,
	},
	{
		garage_id: 50,
		part_id: 186,
		quantity: 359,
	},
	{
		garage_id: 50,
		part_id: 187,
		quantity: 243,
	},
	{
		garage_id: 50,
		part_id: 188,
		quantity: 46,
	},
	{
		garage_id: 50,
		part_id: 189,
		quantity: 454,
	},
	{
		garage_id: 50,
		part_id: 190,
		quantity: 378,
	},
	{
		garage_id: 50,
		part_id: 191,
		quantity: 227,
	},
	{
		garage_id: 50,
		part_id: 192,
		quantity: 484,
	},
	{
		garage_id: 50,
		part_id: 193,
		quantity: 432,
	},
	{
		garage_id: 50,
		part_id: 194,
		quantity: 197,
	},
	{
		garage_id: 50,
		part_id: 195,
		quantity: 401,
	},
	{
		garage_id: 50,
		part_id: 196,
		quantity: 448,
	},
	{
		garage_id: 50,
		part_id: 197,
		quantity: 219,
	},
	{
		garage_id: 50,
		part_id: 198,
		quantity: 194,
	},
	{
		garage_id: 50,
		part_id: 199,
		quantity: 142,
	},
	{
		garage_id: 50,
		part_id: 200,
		quantity: 428,
	},
	{
		garage_id: 50,
		part_id: 201,
		quantity: 44,
	},
	{
		garage_id: 50,
		part_id: 202,
		quantity: 487,
	},
	{
		garage_id: 50,
		part_id: 203,
		quantity: 219,
	},
	{
		garage_id: 50,
		part_id: 204,
		quantity: 107,
	},
	{
		garage_id: 50,
		part_id: 205,
		quantity: 153,
	},
	{
		garage_id: 50,
		part_id: 206,
		quantity: 71,
	},
	{
		garage_id: 50,
		part_id: 207,
		quantity: 446,
	},
	{
		garage_id: 50,
		part_id: 208,
		quantity: 104,
	},
	{
		garage_id: 50,
		part_id: 209,
		quantity: 16,
	},
	{
		garage_id: 50,
		part_id: 210,
		quantity: 321,
	},
	{
		garage_id: 50,
		part_id: 211,
		quantity: 307,
	},
	{
		garage_id: 50,
		part_id: 212,
		quantity: 230,
	},
	{
		garage_id: 50,
		part_id: 213,
		quantity: 267,
	},
	{
		garage_id: 50,
		part_id: 214,
		quantity: 62,
	},
	{
		garage_id: 50,
		part_id: 215,
		quantity: 489,
	},
	{
		garage_id: 50,
		part_id: 216,
		quantity: 59,
	},
	{
		garage_id: 50,
		part_id: 217,
		quantity: 479,
	},
	{
		garage_id: 50,
		part_id: 218,
		quantity: 296,
	},
	{
		garage_id: 50,
		part_id: 219,
		quantity: 380,
	},
	{
		garage_id: 50,
		part_id: 220,
		quantity: 386,
	},
	{
		garage_id: 50,
		part_id: 221,
		quantity: 420,
	},
	{
		garage_id: 50,
		part_id: 222,
		quantity: 133,
	},
	{
		garage_id: 50,
		part_id: 223,
		quantity: 183,
	},
	{
		garage_id: 50,
		part_id: 224,
		quantity: 133,
	},
	{
		garage_id: 50,
		part_id: 225,
		quantity: 363,
	},
	{
		garage_id: 50,
		part_id: 226,
		quantity: 138,
	},
	{
		garage_id: 50,
		part_id: 227,
		quantity: 262,
	},
	{
		garage_id: 50,
		part_id: 228,
		quantity: 276,
	},
	{
		garage_id: 50,
		part_id: 229,
		quantity: 21,
	},
	{
		garage_id: 50,
		part_id: 230,
		quantity: 276,
	},
	{
		garage_id: 50,
		part_id: 231,
		quantity: 430,
	},
	{
		garage_id: 50,
		part_id: 232,
		quantity: 471,
	},
	{
		garage_id: 50,
		part_id: 233,
		quantity: 348,
	},
	{
		garage_id: 50,
		part_id: 234,
		quantity: 246,
	},
	{
		garage_id: 50,
		part_id: 235,
		quantity: 117,
	},
	{
		garage_id: 50,
		part_id: 236,
		quantity: 383,
	},
	{
		garage_id: 50,
		part_id: 237,
		quantity: 198,
	},
	{
		garage_id: 50,
		part_id: 238,
		quantity: 33,
	},
	{
		garage_id: 50,
		part_id: 239,
		quantity: 92,
	},
	{
		garage_id: 50,
		part_id: 240,
		quantity: 484,
	},
	{
		garage_id: 50,
		part_id: 241,
		quantity: 323,
	},
	{
		garage_id: 50,
		part_id: 242,
		quantity: 215,
	},
	{
		garage_id: 50,
		part_id: 243,
		quantity: 434,
	},
	{
		garage_id: 50,
		part_id: 244,
		quantity: 52,
	},
	{
		garage_id: 50,
		part_id: 245,
		quantity: 91,
	},
	{
		garage_id: 50,
		part_id: 246,
		quantity: 369,
	},
	{
		garage_id: 50,
		part_id: 247,
		quantity: 206,
	},
	{
		garage_id: 50,
		part_id: 248,
		quantity: 408,
	},
	{
		garage_id: 50,
		part_id: 249,
		quantity: 98,
	},
	{
		garage_id: 50,
		part_id: 250,
		quantity: 294,
	},
	{
		garage_id: 50,
		part_id: 251,
		quantity: 270,
	},
	{
		garage_id: 50,
		part_id: 252,
		quantity: 98,
	},
	{
		garage_id: 50,
		part_id: 253,
		quantity: 101,
	},
	{
		garage_id: 50,
		part_id: 254,
		quantity: 191,
	},
	{
		garage_id: 50,
		part_id: 255,
		quantity: 30,
	},
	{
		garage_id: 50,
		part_id: 256,
		quantity: 133,
	},
	{
		garage_id: 50,
		part_id: 257,
		quantity: 423,
	},
	{
		garage_id: 50,
		part_id: 258,
		quantity: 365,
	},
	{
		garage_id: 50,
		part_id: 259,
		quantity: 308,
	},
	{
		garage_id: 50,
		part_id: 260,
		quantity: 113,
	},
	{
		garage_id: 50,
		part_id: 261,
		quantity: 198,
	},
	{
		garage_id: 50,
		part_id: 262,
		quantity: 242,
	},
	{
		garage_id: 50,
		part_id: 263,
		quantity: 228,
	},
	{
		garage_id: 50,
		part_id: 264,
		quantity: 237,
	},
	{
		garage_id: 50,
		part_id: 265,
		quantity: 219,
	},
	{
		garage_id: 50,
		part_id: 266,
		quantity: 108,
	},
	{
		garage_id: 50,
		part_id: 267,
		quantity: 382,
	},
	{
		garage_id: 50,
		part_id: 268,
		quantity: 452,
	},
	{
		garage_id: 50,
		part_id: 269,
		quantity: 311,
	},
	{
		garage_id: 50,
		part_id: 270,
		quantity: 275,
	},
	{
		garage_id: 50,
		part_id: 271,
		quantity: 170,
	},
	{
		garage_id: 50,
		part_id: 272,
		quantity: 241,
	},
	{
		garage_id: 50,
		part_id: 273,
		quantity: 425,
	},
	{
		garage_id: 50,
		part_id: 274,
		quantity: 496,
	},
	{
		garage_id: 50,
		part_id: 275,
		quantity: 67,
	},
	{
		garage_id: 50,
		part_id: 276,
		quantity: 223,
	},
	{
		garage_id: 50,
		part_id: 277,
		quantity: 77,
	},
	{
		garage_id: 50,
		part_id: 278,
		quantity: 209,
	},
	{
		garage_id: 50,
		part_id: 279,
		quantity: 24,
	},
	{
		garage_id: 50,
		part_id: 280,
		quantity: 191,
	},
	{
		garage_id: 50,
		part_id: 281,
		quantity: 216,
	},
	{
		garage_id: 50,
		part_id: 282,
		quantity: 35,
	},
	{
		garage_id: 50,
		part_id: 283,
		quantity: 139,
	},
	{
		garage_id: 50,
		part_id: 284,
		quantity: 60,
	},
	{
		garage_id: 50,
		part_id: 285,
		quantity: 284,
	},
	{
		garage_id: 50,
		part_id: 286,
		quantity: 315,
	},
	{
		garage_id: 50,
		part_id: 287,
		quantity: 76,
	},
	{
		garage_id: 50,
		part_id: 288,
		quantity: 113,
	},
	{
		garage_id: 50,
		part_id: 289,
		quantity: 154,
	},
	{
		garage_id: 50,
		part_id: 290,
		quantity: 33,
	},
	{
		garage_id: 50,
		part_id: 291,
		quantity: 322,
	},
	{
		garage_id: 50,
		part_id: 292,
		quantity: 380,
	},
	{
		garage_id: 50,
		part_id: 293,
		quantity: 33,
	},
	{
		garage_id: 50,
		part_id: 294,
		quantity: 45,
	},
	{
		garage_id: 50,
		part_id: 295,
		quantity: 297,
	},
	{
		garage_id: 50,
		part_id: 296,
		quantity: 392,
	},
	{
		garage_id: 50,
		part_id: 297,
		quantity: 324,
	},
	{
		garage_id: 50,
		part_id: 298,
		quantity: 209,
	},
	{
		garage_id: 50,
		part_id: 299,
		quantity: 364,
	},
	{
		garage_id: 50,
		part_id: 300,
		quantity: 450,
	},
	{
		garage_id: 50,
		part_id: 301,
		quantity: 84,
	},
	{
		garage_id: 50,
		part_id: 302,
		quantity: 290,
	},
	{
		garage_id: 50,
		part_id: 303,
		quantity: 353,
	},
	{
		garage_id: 50,
		part_id: 304,
		quantity: 364,
	},
	{
		garage_id: 50,
		part_id: 305,
		quantity: 298,
	},
	{
		garage_id: 50,
		part_id: 306,
		quantity: 85,
	},
	{
		garage_id: 50,
		part_id: 307,
		quantity: 271,
	},
	{
		garage_id: 50,
		part_id: 308,
		quantity: 343,
	},
	{
		garage_id: 50,
		part_id: 309,
		quantity: 159,
	},
	{
		garage_id: 50,
		part_id: 310,
		quantity: 443,
	},
	{
		garage_id: 50,
		part_id: 311,
		quantity: 78,
	},
	{
		garage_id: 50,
		part_id: 312,
		quantity: 170,
	},
	{
		garage_id: 50,
		part_id: 313,
		quantity: 25,
	},
	{
		garage_id: 50,
		part_id: 314,
		quantity: 266,
	},
	{
		garage_id: 50,
		part_id: 315,
		quantity: 405,
	},
	{
		garage_id: 50,
		part_id: 316,
		quantity: 247,
	},
	{
		garage_id: 50,
		part_id: 317,
		quantity: 214,
	},
	{
		garage_id: 50,
		part_id: 318,
		quantity: 119,
	},
	{
		garage_id: 50,
		part_id: 319,
		quantity: 308,
	},
	{
		garage_id: 50,
		part_id: 320,
		quantity: 229,
	},
	{
		garage_id: 50,
		part_id: 321,
		quantity: 412,
	},
	{
		garage_id: 50,
		part_id: 322,
		quantity: 447,
	},
	{
		garage_id: 50,
		part_id: 323,
		quantity: 329,
	},
	{
		garage_id: 50,
		part_id: 324,
		quantity: 48,
	},
	{
		garage_id: 50,
		part_id: 325,
		quantity: 313,
	},
	{
		garage_id: 50,
		part_id: 326,
		quantity: 359,
	},
	{
		garage_id: 50,
		part_id: 327,
		quantity: 447,
	},
	{
		garage_id: 50,
		part_id: 328,
		quantity: 58,
	},
	{
		garage_id: 50,
		part_id: 329,
		quantity: 16,
	},
	{
		garage_id: 50,
		part_id: 330,
		quantity: 122,
	},
	{
		garage_id: 50,
		part_id: 331,
		quantity: 417,
	},
	{
		garage_id: 50,
		part_id: 332,
		quantity: 113,
	},
	{
		garage_id: 50,
		part_id: 333,
		quantity: 256,
	},
	{
		garage_id: 50,
		part_id: 334,
		quantity: 293,
	},
	{
		garage_id: 50,
		part_id: 335,
		quantity: 83,
	},
	{
		garage_id: 50,
		part_id: 336,
		quantity: 275,
	},
	{
		garage_id: 50,
		part_id: 337,
		quantity: 404,
	},
	{
		garage_id: 50,
		part_id: 338,
		quantity: 6,
	},
	{
		garage_id: 50,
		part_id: 339,
		quantity: 255,
	},
	{
		garage_id: 50,
		part_id: 340,
		quantity: 310,
	},
	{
		garage_id: 50,
		part_id: 341,
		quantity: 475,
	},
	{
		garage_id: 50,
		part_id: 342,
		quantity: 260,
	},
	{
		garage_id: 50,
		part_id: 343,
		quantity: 142,
	},
	{
		garage_id: 50,
		part_id: 344,
		quantity: 498,
	},
	{
		garage_id: 50,
		part_id: 345,
		quantity: 63,
	},
	{
		garage_id: 50,
		part_id: 346,
		quantity: 319,
	},
	{
		garage_id: 50,
		part_id: 347,
		quantity: 239,
	},
	{
		garage_id: 50,
		part_id: 348,
		quantity: 40,
	},
	{
		garage_id: 50,
		part_id: 349,
		quantity: 12,
	},
	{
		garage_id: 50,
		part_id: 350,
		quantity: 367,
	},
	{
		garage_id: 50,
		part_id: 351,
		quantity: 483,
	},
	{
		garage_id: 50,
		part_id: 352,
		quantity: 79,
	},
	{
		garage_id: 50,
		part_id: 353,
		quantity: 437,
	},
	{
		garage_id: 50,
		part_id: 354,
		quantity: 331,
	},
	{
		garage_id: 50,
		part_id: 355,
		quantity: 148,
	},
	{
		garage_id: 50,
		part_id: 356,
		quantity: 439,
	},
	{
		garage_id: 50,
		part_id: 357,
		quantity: 395,
	},
	{
		garage_id: 50,
		part_id: 358,
		quantity: 353,
	},
	{
		garage_id: 50,
		part_id: 359,
		quantity: 170,
	},
	{
		garage_id: 50,
		part_id: 360,
		quantity: 441,
	},
	{
		garage_id: 50,
		part_id: 361,
		quantity: 36,
	},
	{
		garage_id: 50,
		part_id: 362,
		quantity: 296,
	},
	{
		garage_id: 50,
		part_id: 363,
		quantity: 488,
	},
	{
		garage_id: 50,
		part_id: 364,
		quantity: 296,
	},
	{
		garage_id: 50,
		part_id: 365,
		quantity: 56,
	},
	{
		garage_id: 50,
		part_id: 366,
		quantity: 244,
	},
	{
		garage_id: 50,
		part_id: 367,
		quantity: 118,
	},
	{
		garage_id: 50,
		part_id: 368,
		quantity: 479,
	},
	{
		garage_id: 50,
		part_id: 369,
		quantity: 305,
	},
	{
		garage_id: 50,
		part_id: 370,
		quantity: 123,
	},
	{
		garage_id: 50,
		part_id: 371,
		quantity: 35,
	},
	{
		garage_id: 50,
		part_id: 372,
		quantity: 213,
	},
	{
		garage_id: 50,
		part_id: 373,
		quantity: 295,
	},
	{
		garage_id: 50,
		part_id: 374,
		quantity: 234,
	},
	{
		garage_id: 50,
		part_id: 375,
		quantity: 450,
	},
	{
		garage_id: 50,
		part_id: 376,
		quantity: 358,
	},
	{
		garage_id: 50,
		part_id: 377,
		quantity: 365,
	},
	{
		garage_id: 50,
		part_id: 378,
		quantity: 134,
	},
	{
		garage_id: 50,
		part_id: 379,
		quantity: 336,
	},
	{
		garage_id: 50,
		part_id: 380,
		quantity: 213,
	},
	{
		garage_id: 50,
		part_id: 381,
		quantity: 433,
	},
	{
		garage_id: 50,
		part_id: 382,
		quantity: 364,
	},
	{
		garage_id: 50,
		part_id: 383,
		quantity: 87,
	},
	{
		garage_id: 50,
		part_id: 384,
		quantity: 236,
	},
	{
		garage_id: 50,
		part_id: 385,
		quantity: 377,
	},
	{
		garage_id: 50,
		part_id: 386,
		quantity: 292,
	},
	{
		garage_id: 50,
		part_id: 387,
		quantity: 282,
	},
	{
		garage_id: 50,
		part_id: 388,
		quantity: 472,
	},
	{
		garage_id: 50,
		part_id: 389,
		quantity: 278,
	},
	{
		garage_id: 50,
		part_id: 390,
		quantity: 229,
	},
	{
		garage_id: 50,
		part_id: 391,
		quantity: 236,
	},
	{
		garage_id: 50,
		part_id: 392,
		quantity: 343,
	},
	{
		garage_id: 50,
		part_id: 393,
		quantity: 313,
	},
	{
		garage_id: 50,
		part_id: 394,
		quantity: 18,
	},
	{
		garage_id: 50,
		part_id: 395,
		quantity: 441,
	},
	{
		garage_id: 50,
		part_id: 396,
		quantity: 293,
	},
	{
		garage_id: 50,
		part_id: 397,
		quantity: 388,
	},
	{
		garage_id: 50,
		part_id: 398,
		quantity: 45,
	},
	{
		garage_id: 50,
		part_id: 399,
		quantity: 361,
	},
	{
		garage_id: 51,
		part_id: 0,
		quantity: 257,
	},
	{
		garage_id: 51,
		part_id: 1,
		quantity: 372,
	},
	{
		garage_id: 51,
		part_id: 2,
		quantity: 86,
	},
	{
		garage_id: 51,
		part_id: 3,
		quantity: 477,
	},
	{
		garage_id: 51,
		part_id: 4,
		quantity: 395,
	},
	{
		garage_id: 51,
		part_id: 5,
		quantity: 410,
	},
	{
		garage_id: 51,
		part_id: 6,
		quantity: 149,
	},
	{
		garage_id: 51,
		part_id: 7,
		quantity: 347,
	},
	{
		garage_id: 51,
		part_id: 8,
		quantity: 295,
	},
	{
		garage_id: 51,
		part_id: 9,
		quantity: 320,
	},
	{
		garage_id: 51,
		part_id: 10,
		quantity: 17,
	},
	{
		garage_id: 51,
		part_id: 11,
		quantity: 197,
	},
	{
		garage_id: 51,
		part_id: 12,
		quantity: 231,
	},
	{
		garage_id: 51,
		part_id: 13,
		quantity: 199,
	},
	{
		garage_id: 51,
		part_id: 14,
		quantity: 363,
	},
	{
		garage_id: 51,
		part_id: 15,
		quantity: 171,
	},
	{
		garage_id: 51,
		part_id: 16,
		quantity: 105,
	},
	{
		garage_id: 51,
		part_id: 17,
		quantity: 121,
	},
	{
		garage_id: 51,
		part_id: 18,
		quantity: 403,
	},
	{
		garage_id: 51,
		part_id: 19,
		quantity: 205,
	},
	{
		garage_id: 51,
		part_id: 20,
		quantity: 114,
	},
	{
		garage_id: 51,
		part_id: 21,
		quantity: 368,
	},
	{
		garage_id: 51,
		part_id: 22,
		quantity: 124,
	},
	{
		garage_id: 51,
		part_id: 23,
		quantity: 441,
	},
	{
		garage_id: 51,
		part_id: 24,
		quantity: 11,
	},
	{
		garage_id: 51,
		part_id: 25,
		quantity: 47,
	},
	{
		garage_id: 51,
		part_id: 26,
		quantity: 292,
	},
	{
		garage_id: 51,
		part_id: 27,
		quantity: 334,
	},
	{
		garage_id: 51,
		part_id: 28,
		quantity: 483,
	},
	{
		garage_id: 51,
		part_id: 29,
		quantity: 316,
	},
	{
		garage_id: 51,
		part_id: 30,
		quantity: 218,
	},
	{
		garage_id: 51,
		part_id: 31,
		quantity: 184,
	},
	{
		garage_id: 51,
		part_id: 32,
		quantity: 224,
	},
	{
		garage_id: 51,
		part_id: 33,
		quantity: 92,
	},
	{
		garage_id: 51,
		part_id: 34,
		quantity: 154,
	},
	{
		garage_id: 51,
		part_id: 35,
		quantity: 437,
	},
	{
		garage_id: 51,
		part_id: 36,
		quantity: 384,
	},
	{
		garage_id: 51,
		part_id: 37,
		quantity: 214,
	},
	{
		garage_id: 51,
		part_id: 38,
		quantity: 382,
	},
	{
		garage_id: 51,
		part_id: 39,
		quantity: 422,
	},
	{
		garage_id: 51,
		part_id: 40,
		quantity: 237,
	},
	{
		garage_id: 51,
		part_id: 41,
		quantity: 143,
	},
	{
		garage_id: 51,
		part_id: 42,
		quantity: 338,
	},
	{
		garage_id: 51,
		part_id: 43,
		quantity: 396,
	},
	{
		garage_id: 51,
		part_id: 44,
		quantity: 194,
	},
	{
		garage_id: 51,
		part_id: 45,
		quantity: 271,
	},
	{
		garage_id: 51,
		part_id: 46,
		quantity: 424,
	},
	{
		garage_id: 51,
		part_id: 47,
		quantity: 262,
	},
	{
		garage_id: 51,
		part_id: 48,
		quantity: 186,
	},
	{
		garage_id: 51,
		part_id: 49,
		quantity: 17,
	},
	{
		garage_id: 51,
		part_id: 50,
		quantity: 368,
	},
	{
		garage_id: 51,
		part_id: 51,
		quantity: 50,
	},
	{
		garage_id: 51,
		part_id: 52,
		quantity: 451,
	},
	{
		garage_id: 51,
		part_id: 53,
		quantity: 222,
	},
	{
		garage_id: 51,
		part_id: 54,
		quantity: 91,
	},
	{
		garage_id: 51,
		part_id: 55,
		quantity: 334,
	},
	{
		garage_id: 51,
		part_id: 56,
		quantity: 213,
	},
	{
		garage_id: 51,
		part_id: 57,
		quantity: 34,
	},
	{
		garage_id: 51,
		part_id: 58,
		quantity: 346,
	},
	{
		garage_id: 51,
		part_id: 59,
		quantity: 488,
	},
	{
		garage_id: 51,
		part_id: 60,
		quantity: 141,
	},
	{
		garage_id: 51,
		part_id: 61,
		quantity: 406,
	},
	{
		garage_id: 51,
		part_id: 62,
		quantity: 449,
	},
	{
		garage_id: 51,
		part_id: 63,
		quantity: 262,
	},
	{
		garage_id: 51,
		part_id: 64,
		quantity: 57,
	},
	{
		garage_id: 51,
		part_id: 65,
		quantity: 160,
	},
	{
		garage_id: 51,
		part_id: 66,
		quantity: 69,
	},
	{
		garage_id: 51,
		part_id: 67,
		quantity: 361,
	},
	{
		garage_id: 51,
		part_id: 68,
		quantity: 61,
	},
	{
		garage_id: 51,
		part_id: 69,
		quantity: 127,
	},
	{
		garage_id: 51,
		part_id: 70,
		quantity: 435,
	},
	{
		garage_id: 51,
		part_id: 71,
		quantity: 265,
	},
	{
		garage_id: 51,
		part_id: 72,
		quantity: 25,
	},
	{
		garage_id: 51,
		part_id: 73,
		quantity: 89,
	},
	{
		garage_id: 51,
		part_id: 74,
		quantity: 90,
	},
	{
		garage_id: 51,
		part_id: 75,
		quantity: 171,
	},
	{
		garage_id: 51,
		part_id: 76,
		quantity: 444,
	},
	{
		garage_id: 51,
		part_id: 77,
		quantity: 333,
	},
	{
		garage_id: 51,
		part_id: 78,
		quantity: 36,
	},
	{
		garage_id: 51,
		part_id: 79,
		quantity: 248,
	},
	{
		garage_id: 51,
		part_id: 80,
		quantity: 182,
	},
	{
		garage_id: 51,
		part_id: 81,
		quantity: 386,
	},
	{
		garage_id: 51,
		part_id: 82,
		quantity: 25,
	},
	{
		garage_id: 51,
		part_id: 83,
		quantity: 314,
	},
	{
		garage_id: 51,
		part_id: 84,
		quantity: 141,
	},
	{
		garage_id: 51,
		part_id: 85,
		quantity: 91,
	},
	{
		garage_id: 51,
		part_id: 86,
		quantity: 68,
	},
	{
		garage_id: 51,
		part_id: 87,
		quantity: 41,
	},
	{
		garage_id: 51,
		part_id: 88,
		quantity: 171,
	},
	{
		garage_id: 51,
		part_id: 89,
		quantity: 436,
	},
	{
		garage_id: 51,
		part_id: 90,
		quantity: 1,
	},
	{
		garage_id: 51,
		part_id: 91,
		quantity: 172,
	},
	{
		garage_id: 51,
		part_id: 92,
		quantity: 453,
	},
	{
		garage_id: 51,
		part_id: 93,
		quantity: 372,
	},
	{
		garage_id: 51,
		part_id: 94,
		quantity: 254,
	},
	{
		garage_id: 51,
		part_id: 95,
		quantity: 129,
	},
	{
		garage_id: 51,
		part_id: 96,
		quantity: 402,
	},
	{
		garage_id: 51,
		part_id: 97,
		quantity: 137,
	},
	{
		garage_id: 51,
		part_id: 98,
		quantity: 496,
	},
	{
		garage_id: 51,
		part_id: 99,
		quantity: 171,
	},
	{
		garage_id: 51,
		part_id: 100,
		quantity: 168,
	},
	{
		garage_id: 51,
		part_id: 101,
		quantity: 8,
	},
	{
		garage_id: 51,
		part_id: 102,
		quantity: 349,
	},
	{
		garage_id: 51,
		part_id: 103,
		quantity: 27,
	},
	{
		garage_id: 51,
		part_id: 104,
		quantity: 317,
	},
	{
		garage_id: 51,
		part_id: 105,
		quantity: 279,
	},
	{
		garage_id: 51,
		part_id: 106,
		quantity: 193,
	},
	{
		garage_id: 51,
		part_id: 107,
		quantity: 50,
	},
	{
		garage_id: 51,
		part_id: 108,
		quantity: 404,
	},
	{
		garage_id: 51,
		part_id: 109,
		quantity: 399,
	},
	{
		garage_id: 51,
		part_id: 110,
		quantity: 305,
	},
	{
		garage_id: 51,
		part_id: 111,
		quantity: 477,
	},
	{
		garage_id: 51,
		part_id: 112,
		quantity: 32,
	},
	{
		garage_id: 51,
		part_id: 113,
		quantity: 500,
	},
	{
		garage_id: 51,
		part_id: 114,
		quantity: 280,
	},
	{
		garage_id: 51,
		part_id: 115,
		quantity: 282,
	},
	{
		garage_id: 51,
		part_id: 116,
		quantity: 19,
	},
	{
		garage_id: 51,
		part_id: 117,
		quantity: 444,
	},
	{
		garage_id: 51,
		part_id: 118,
		quantity: 380,
	},
	{
		garage_id: 51,
		part_id: 119,
		quantity: 474,
	},
	{
		garage_id: 51,
		part_id: 120,
		quantity: 70,
	},
	{
		garage_id: 51,
		part_id: 121,
		quantity: 160,
	},
	{
		garage_id: 51,
		part_id: 122,
		quantity: 395,
	},
	{
		garage_id: 51,
		part_id: 123,
		quantity: 478,
	},
	{
		garage_id: 51,
		part_id: 124,
		quantity: 141,
	},
	{
		garage_id: 51,
		part_id: 125,
		quantity: 465,
	},
	{
		garage_id: 51,
		part_id: 126,
		quantity: 11,
	},
	{
		garage_id: 51,
		part_id: 127,
		quantity: 170,
	},
	{
		garage_id: 51,
		part_id: 128,
		quantity: 234,
	},
	{
		garage_id: 51,
		part_id: 129,
		quantity: 234,
	},
	{
		garage_id: 51,
		part_id: 130,
		quantity: 487,
	},
	{
		garage_id: 51,
		part_id: 131,
		quantity: 414,
	},
	{
		garage_id: 51,
		part_id: 132,
		quantity: 437,
	},
	{
		garage_id: 51,
		part_id: 133,
		quantity: 247,
	},
	{
		garage_id: 51,
		part_id: 134,
		quantity: 466,
	},
	{
		garage_id: 51,
		part_id: 135,
		quantity: 341,
	},
	{
		garage_id: 51,
		part_id: 136,
		quantity: 451,
	},
	{
		garage_id: 51,
		part_id: 137,
		quantity: 417,
	},
	{
		garage_id: 51,
		part_id: 138,
		quantity: 317,
	},
	{
		garage_id: 51,
		part_id: 139,
		quantity: 0,
	},
	{
		garage_id: 51,
		part_id: 140,
		quantity: 400,
	},
	{
		garage_id: 51,
		part_id: 141,
		quantity: 166,
	},
	{
		garage_id: 51,
		part_id: 142,
		quantity: 165,
	},
	{
		garage_id: 51,
		part_id: 143,
		quantity: 159,
	},
	{
		garage_id: 51,
		part_id: 144,
		quantity: 312,
	},
	{
		garage_id: 51,
		part_id: 145,
		quantity: 204,
	},
	{
		garage_id: 51,
		part_id: 146,
		quantity: 16,
	},
	{
		garage_id: 51,
		part_id: 147,
		quantity: 414,
	},
	{
		garage_id: 51,
		part_id: 148,
		quantity: 56,
	},
	{
		garage_id: 51,
		part_id: 149,
		quantity: 162,
	},
	{
		garage_id: 51,
		part_id: 150,
		quantity: 161,
	},
	{
		garage_id: 51,
		part_id: 151,
		quantity: 297,
	},
	{
		garage_id: 51,
		part_id: 152,
		quantity: 415,
	},
	{
		garage_id: 51,
		part_id: 153,
		quantity: 260,
	},
	{
		garage_id: 51,
		part_id: 154,
		quantity: 296,
	},
	{
		garage_id: 51,
		part_id: 155,
		quantity: 337,
	},
	{
		garage_id: 51,
		part_id: 156,
		quantity: 394,
	},
	{
		garage_id: 51,
		part_id: 157,
		quantity: 80,
	},
	{
		garage_id: 51,
		part_id: 158,
		quantity: 253,
	},
	{
		garage_id: 51,
		part_id: 159,
		quantity: 461,
	},
	{
		garage_id: 51,
		part_id: 160,
		quantity: 412,
	},
	{
		garage_id: 51,
		part_id: 161,
		quantity: 17,
	},
	{
		garage_id: 51,
		part_id: 162,
		quantity: 182,
	},
	{
		garage_id: 51,
		part_id: 163,
		quantity: 428,
	},
	{
		garage_id: 51,
		part_id: 164,
		quantity: 491,
	},
	{
		garage_id: 51,
		part_id: 165,
		quantity: 430,
	},
	{
		garage_id: 51,
		part_id: 166,
		quantity: 285,
	},
	{
		garage_id: 51,
		part_id: 167,
		quantity: 285,
	},
	{
		garage_id: 51,
		part_id: 168,
		quantity: 446,
	},
	{
		garage_id: 51,
		part_id: 169,
		quantity: 249,
	},
	{
		garage_id: 51,
		part_id: 170,
		quantity: 134,
	},
	{
		garage_id: 51,
		part_id: 171,
		quantity: 395,
	},
	{
		garage_id: 51,
		part_id: 172,
		quantity: 124,
	},
	{
		garage_id: 51,
		part_id: 173,
		quantity: 117,
	},
	{
		garage_id: 51,
		part_id: 174,
		quantity: 461,
	},
	{
		garage_id: 51,
		part_id: 175,
		quantity: 99,
	},
	{
		garage_id: 51,
		part_id: 176,
		quantity: 186,
	},
	{
		garage_id: 51,
		part_id: 177,
		quantity: 352,
	},
	{
		garage_id: 51,
		part_id: 178,
		quantity: 212,
	},
	{
		garage_id: 51,
		part_id: 179,
		quantity: 162,
	},
	{
		garage_id: 51,
		part_id: 180,
		quantity: 347,
	},
	{
		garage_id: 51,
		part_id: 181,
		quantity: 478,
	},
	{
		garage_id: 51,
		part_id: 182,
		quantity: 187,
	},
	{
		garage_id: 51,
		part_id: 183,
		quantity: 294,
	},
	{
		garage_id: 51,
		part_id: 184,
		quantity: 148,
	},
	{
		garage_id: 51,
		part_id: 185,
		quantity: 476,
	},
	{
		garage_id: 51,
		part_id: 186,
		quantity: 330,
	},
	{
		garage_id: 51,
		part_id: 187,
		quantity: 262,
	},
	{
		garage_id: 51,
		part_id: 188,
		quantity: 275,
	},
	{
		garage_id: 51,
		part_id: 189,
		quantity: 142,
	},
	{
		garage_id: 51,
		part_id: 190,
		quantity: 248,
	},
	{
		garage_id: 51,
		part_id: 191,
		quantity: 177,
	},
	{
		garage_id: 51,
		part_id: 192,
		quantity: 169,
	},
	{
		garage_id: 51,
		part_id: 193,
		quantity: 188,
	},
	{
		garage_id: 51,
		part_id: 194,
		quantity: 382,
	},
	{
		garage_id: 51,
		part_id: 195,
		quantity: 405,
	},
	{
		garage_id: 51,
		part_id: 196,
		quantity: 132,
	},
	{
		garage_id: 51,
		part_id: 197,
		quantity: 49,
	},
	{
		garage_id: 51,
		part_id: 198,
		quantity: 124,
	},
	{
		garage_id: 51,
		part_id: 199,
		quantity: 368,
	},
	{
		garage_id: 51,
		part_id: 200,
		quantity: 347,
	},
	{
		garage_id: 51,
		part_id: 201,
		quantity: 95,
	},
	{
		garage_id: 51,
		part_id: 202,
		quantity: 432,
	},
	{
		garage_id: 51,
		part_id: 203,
		quantity: 193,
	},
	{
		garage_id: 51,
		part_id: 204,
		quantity: 419,
	},
	{
		garage_id: 51,
		part_id: 205,
		quantity: 8,
	},
	{
		garage_id: 51,
		part_id: 206,
		quantity: 423,
	},
	{
		garage_id: 51,
		part_id: 207,
		quantity: 151,
	},
	{
		garage_id: 51,
		part_id: 208,
		quantity: 421,
	},
	{
		garage_id: 51,
		part_id: 209,
		quantity: 306,
	},
	{
		garage_id: 51,
		part_id: 210,
		quantity: 427,
	},
	{
		garage_id: 51,
		part_id: 211,
		quantity: 149,
	},
	{
		garage_id: 51,
		part_id: 212,
		quantity: 116,
	},
	{
		garage_id: 51,
		part_id: 213,
		quantity: 449,
	},
	{
		garage_id: 51,
		part_id: 214,
		quantity: 405,
	},
	{
		garage_id: 51,
		part_id: 215,
		quantity: 393,
	},
	{
		garage_id: 51,
		part_id: 216,
		quantity: 160,
	},
	{
		garage_id: 51,
		part_id: 217,
		quantity: 78,
	},
	{
		garage_id: 51,
		part_id: 218,
		quantity: 317,
	},
	{
		garage_id: 51,
		part_id: 219,
		quantity: 75,
	},
	{
		garage_id: 51,
		part_id: 220,
		quantity: 127,
	},
	{
		garage_id: 51,
		part_id: 221,
		quantity: 29,
	},
	{
		garage_id: 51,
		part_id: 222,
		quantity: 332,
	},
	{
		garage_id: 51,
		part_id: 223,
		quantity: 163,
	},
	{
		garage_id: 51,
		part_id: 224,
		quantity: 207,
	},
	{
		garage_id: 51,
		part_id: 225,
		quantity: 33,
	},
	{
		garage_id: 51,
		part_id: 226,
		quantity: 355,
	},
	{
		garage_id: 51,
		part_id: 227,
		quantity: 323,
	},
	{
		garage_id: 51,
		part_id: 228,
		quantity: 496,
	},
	{
		garage_id: 51,
		part_id: 229,
		quantity: 357,
	},
	{
		garage_id: 51,
		part_id: 230,
		quantity: 424,
	},
	{
		garage_id: 51,
		part_id: 231,
		quantity: 50,
	},
	{
		garage_id: 51,
		part_id: 232,
		quantity: 460,
	},
	{
		garage_id: 51,
		part_id: 233,
		quantity: 180,
	},
	{
		garage_id: 51,
		part_id: 234,
		quantity: 90,
	},
	{
		garage_id: 51,
		part_id: 235,
		quantity: 249,
	},
	{
		garage_id: 51,
		part_id: 236,
		quantity: 73,
	},
	{
		garage_id: 51,
		part_id: 237,
		quantity: 249,
	},
	{
		garage_id: 51,
		part_id: 238,
		quantity: 472,
	},
	{
		garage_id: 51,
		part_id: 239,
		quantity: 459,
	},
	{
		garage_id: 51,
		part_id: 240,
		quantity: 231,
	},
	{
		garage_id: 51,
		part_id: 241,
		quantity: 107,
	},
	{
		garage_id: 51,
		part_id: 242,
		quantity: 265,
	},
	{
		garage_id: 51,
		part_id: 243,
		quantity: 88,
	},
	{
		garage_id: 51,
		part_id: 244,
		quantity: 452,
	},
	{
		garage_id: 51,
		part_id: 245,
		quantity: 1,
	},
	{
		garage_id: 51,
		part_id: 246,
		quantity: 74,
	},
	{
		garage_id: 51,
		part_id: 247,
		quantity: 360,
	},
	{
		garage_id: 51,
		part_id: 248,
		quantity: 432,
	},
	{
		garage_id: 51,
		part_id: 249,
		quantity: 204,
	},
	{
		garage_id: 51,
		part_id: 250,
		quantity: 433,
	},
	{
		garage_id: 51,
		part_id: 251,
		quantity: 131,
	},
	{
		garage_id: 51,
		part_id: 252,
		quantity: 13,
	},
	{
		garage_id: 51,
		part_id: 253,
		quantity: 238,
	},
	{
		garage_id: 51,
		part_id: 254,
		quantity: 478,
	},
	{
		garage_id: 51,
		part_id: 255,
		quantity: 419,
	},
	{
		garage_id: 51,
		part_id: 256,
		quantity: 451,
	},
	{
		garage_id: 51,
		part_id: 257,
		quantity: 211,
	},
	{
		garage_id: 51,
		part_id: 258,
		quantity: 436,
	},
	{
		garage_id: 51,
		part_id: 259,
		quantity: 181,
	},
	{
		garage_id: 51,
		part_id: 260,
		quantity: 375,
	},
	{
		garage_id: 51,
		part_id: 261,
		quantity: 176,
	},
	{
		garage_id: 51,
		part_id: 262,
		quantity: 446,
	},
	{
		garage_id: 51,
		part_id: 263,
		quantity: 279,
	},
	{
		garage_id: 51,
		part_id: 264,
		quantity: 391,
	},
	{
		garage_id: 51,
		part_id: 265,
		quantity: 18,
	},
	{
		garage_id: 51,
		part_id: 266,
		quantity: 118,
	},
	{
		garage_id: 51,
		part_id: 267,
		quantity: 319,
	},
	{
		garage_id: 51,
		part_id: 268,
		quantity: 498,
	},
	{
		garage_id: 51,
		part_id: 269,
		quantity: 495,
	},
	{
		garage_id: 51,
		part_id: 270,
		quantity: 270,
	},
	{
		garage_id: 51,
		part_id: 271,
		quantity: 168,
	},
	{
		garage_id: 51,
		part_id: 272,
		quantity: 358,
	},
	{
		garage_id: 51,
		part_id: 273,
		quantity: 259,
	},
	{
		garage_id: 51,
		part_id: 274,
		quantity: 355,
	},
	{
		garage_id: 51,
		part_id: 275,
		quantity: 413,
	},
	{
		garage_id: 51,
		part_id: 276,
		quantity: 225,
	},
	{
		garage_id: 51,
		part_id: 277,
		quantity: 85,
	},
	{
		garage_id: 51,
		part_id: 278,
		quantity: 329,
	},
	{
		garage_id: 51,
		part_id: 279,
		quantity: 4,
	},
	{
		garage_id: 51,
		part_id: 280,
		quantity: 461,
	},
	{
		garage_id: 51,
		part_id: 281,
		quantity: 30,
	},
	{
		garage_id: 51,
		part_id: 282,
		quantity: 7,
	},
	{
		garage_id: 51,
		part_id: 283,
		quantity: 3,
	},
	{
		garage_id: 51,
		part_id: 284,
		quantity: 448,
	},
	{
		garage_id: 51,
		part_id: 285,
		quantity: 95,
	},
	{
		garage_id: 51,
		part_id: 286,
		quantity: 104,
	},
	{
		garage_id: 51,
		part_id: 287,
		quantity: 390,
	},
	{
		garage_id: 51,
		part_id: 288,
		quantity: 414,
	},
	{
		garage_id: 51,
		part_id: 289,
		quantity: 375,
	},
	{
		garage_id: 51,
		part_id: 290,
		quantity: 146,
	},
	{
		garage_id: 51,
		part_id: 291,
		quantity: 45,
	},
	{
		garage_id: 51,
		part_id: 292,
		quantity: 146,
	},
	{
		garage_id: 51,
		part_id: 293,
		quantity: 105,
	},
	{
		garage_id: 51,
		part_id: 294,
		quantity: 75,
	},
	{
		garage_id: 51,
		part_id: 295,
		quantity: 102,
	},
	{
		garage_id: 51,
		part_id: 296,
		quantity: 177,
	},
	{
		garage_id: 51,
		part_id: 297,
		quantity: 109,
	},
	{
		garage_id: 51,
		part_id: 298,
		quantity: 434,
	},
	{
		garage_id: 51,
		part_id: 299,
		quantity: 189,
	},
	{
		garage_id: 51,
		part_id: 300,
		quantity: 180,
	},
	{
		garage_id: 51,
		part_id: 301,
		quantity: 323,
	},
	{
		garage_id: 51,
		part_id: 302,
		quantity: 226,
	},
	{
		garage_id: 51,
		part_id: 303,
		quantity: 185,
	},
	{
		garage_id: 51,
		part_id: 304,
		quantity: 181,
	},
	{
		garage_id: 51,
		part_id: 305,
		quantity: 55,
	},
	{
		garage_id: 51,
		part_id: 306,
		quantity: 457,
	},
	{
		garage_id: 51,
		part_id: 307,
		quantity: 148,
	},
	{
		garage_id: 51,
		part_id: 308,
		quantity: 379,
	},
	{
		garage_id: 51,
		part_id: 309,
		quantity: 71,
	},
	{
		garage_id: 51,
		part_id: 310,
		quantity: 366,
	},
	{
		garage_id: 51,
		part_id: 311,
		quantity: 8,
	},
	{
		garage_id: 51,
		part_id: 312,
		quantity: 400,
	},
	{
		garage_id: 51,
		part_id: 313,
		quantity: 116,
	},
	{
		garage_id: 51,
		part_id: 314,
		quantity: 123,
	},
	{
		garage_id: 51,
		part_id: 315,
		quantity: 278,
	},
	{
		garage_id: 51,
		part_id: 316,
		quantity: 458,
	},
	{
		garage_id: 51,
		part_id: 317,
		quantity: 120,
	},
	{
		garage_id: 51,
		part_id: 318,
		quantity: 116,
	},
	{
		garage_id: 51,
		part_id: 319,
		quantity: 247,
	},
	{
		garage_id: 51,
		part_id: 320,
		quantity: 306,
	},
	{
		garage_id: 51,
		part_id: 321,
		quantity: 6,
	},
	{
		garage_id: 51,
		part_id: 322,
		quantity: 260,
	},
	{
		garage_id: 51,
		part_id: 323,
		quantity: 471,
	},
	{
		garage_id: 51,
		part_id: 324,
		quantity: 400,
	},
	{
		garage_id: 51,
		part_id: 325,
		quantity: 137,
	},
	{
		garage_id: 51,
		part_id: 326,
		quantity: 194,
	},
	{
		garage_id: 51,
		part_id: 327,
		quantity: 6,
	},
	{
		garage_id: 51,
		part_id: 328,
		quantity: 299,
	},
	{
		garage_id: 51,
		part_id: 329,
		quantity: 359,
	},
	{
		garage_id: 51,
		part_id: 330,
		quantity: 454,
	},
	{
		garage_id: 51,
		part_id: 331,
		quantity: 421,
	},
	{
		garage_id: 51,
		part_id: 332,
		quantity: 120,
	},
	{
		garage_id: 51,
		part_id: 333,
		quantity: 372,
	},
	{
		garage_id: 51,
		part_id: 334,
		quantity: 212,
	},
	{
		garage_id: 51,
		part_id: 335,
		quantity: 382,
	},
	{
		garage_id: 51,
		part_id: 336,
		quantity: 361,
	},
	{
		garage_id: 51,
		part_id: 337,
		quantity: 226,
	},
	{
		garage_id: 51,
		part_id: 338,
		quantity: 160,
	},
	{
		garage_id: 51,
		part_id: 339,
		quantity: 456,
	},
	{
		garage_id: 51,
		part_id: 340,
		quantity: 470,
	},
	{
		garage_id: 51,
		part_id: 341,
		quantity: 382,
	},
	{
		garage_id: 51,
		part_id: 342,
		quantity: 261,
	},
	{
		garage_id: 51,
		part_id: 343,
		quantity: 347,
	},
	{
		garage_id: 51,
		part_id: 344,
		quantity: 449,
	},
	{
		garage_id: 51,
		part_id: 345,
		quantity: 145,
	},
	{
		garage_id: 51,
		part_id: 346,
		quantity: 163,
	},
	{
		garage_id: 51,
		part_id: 347,
		quantity: 384,
	},
	{
		garage_id: 51,
		part_id: 348,
		quantity: 355,
	},
	{
		garage_id: 51,
		part_id: 349,
		quantity: 252,
	},
	{
		garage_id: 51,
		part_id: 350,
		quantity: 440,
	},
	{
		garage_id: 51,
		part_id: 351,
		quantity: 426,
	},
	{
		garage_id: 51,
		part_id: 352,
		quantity: 473,
	},
	{
		garage_id: 51,
		part_id: 353,
		quantity: 220,
	},
	{
		garage_id: 51,
		part_id: 354,
		quantity: 296,
	},
	{
		garage_id: 51,
		part_id: 355,
		quantity: 156,
	},
	{
		garage_id: 51,
		part_id: 356,
		quantity: 41,
	},
	{
		garage_id: 51,
		part_id: 357,
		quantity: 430,
	},
	{
		garage_id: 51,
		part_id: 358,
		quantity: 417,
	},
	{
		garage_id: 51,
		part_id: 359,
		quantity: 160,
	},
	{
		garage_id: 51,
		part_id: 360,
		quantity: 280,
	},
	{
		garage_id: 51,
		part_id: 361,
		quantity: 211,
	},
	{
		garage_id: 51,
		part_id: 362,
		quantity: 440,
	},
	{
		garage_id: 51,
		part_id: 363,
		quantity: 237,
	},
	{
		garage_id: 51,
		part_id: 364,
		quantity: 336,
	},
	{
		garage_id: 51,
		part_id: 365,
		quantity: 215,
	},
	{
		garage_id: 51,
		part_id: 366,
		quantity: 406,
	},
	{
		garage_id: 51,
		part_id: 367,
		quantity: 181,
	},
	{
		garage_id: 51,
		part_id: 368,
		quantity: 55,
	},
	{
		garage_id: 51,
		part_id: 369,
		quantity: 51,
	},
	{
		garage_id: 51,
		part_id: 370,
		quantity: 320,
	},
	{
		garage_id: 51,
		part_id: 371,
		quantity: 42,
	},
	{
		garage_id: 51,
		part_id: 372,
		quantity: 324,
	},
	{
		garage_id: 51,
		part_id: 373,
		quantity: 249,
	},
	{
		garage_id: 51,
		part_id: 374,
		quantity: 39,
	},
	{
		garage_id: 51,
		part_id: 375,
		quantity: 55,
	},
	{
		garage_id: 51,
		part_id: 376,
		quantity: 280,
	},
	{
		garage_id: 51,
		part_id: 377,
		quantity: 396,
	},
	{
		garage_id: 51,
		part_id: 378,
		quantity: 40,
	},
	{
		garage_id: 51,
		part_id: 379,
		quantity: 88,
	},
	{
		garage_id: 51,
		part_id: 380,
		quantity: 397,
	},
	{
		garage_id: 51,
		part_id: 381,
		quantity: 306,
	},
	{
		garage_id: 51,
		part_id: 382,
		quantity: 259,
	},
	{
		garage_id: 51,
		part_id: 383,
		quantity: 487,
	},
	{
		garage_id: 51,
		part_id: 384,
		quantity: 255,
	},
	{
		garage_id: 51,
		part_id: 385,
		quantity: 35,
	},
	{
		garage_id: 51,
		part_id: 386,
		quantity: 195,
	},
	{
		garage_id: 51,
		part_id: 387,
		quantity: 308,
	},
	{
		garage_id: 51,
		part_id: 388,
		quantity: 143,
	},
	{
		garage_id: 51,
		part_id: 389,
		quantity: 184,
	},
	{
		garage_id: 51,
		part_id: 390,
		quantity: 422,
	},
	{
		garage_id: 51,
		part_id: 391,
		quantity: 175,
	},
	{
		garage_id: 51,
		part_id: 392,
		quantity: 184,
	},
	{
		garage_id: 51,
		part_id: 393,
		quantity: 421,
	},
	{
		garage_id: 51,
		part_id: 394,
		quantity: 336,
	},
	{
		garage_id: 51,
		part_id: 395,
		quantity: 142,
	},
	{
		garage_id: 51,
		part_id: 396,
		quantity: 272,
	},
	{
		garage_id: 51,
		part_id: 397,
		quantity: 49,
	},
	{
		garage_id: 51,
		part_id: 398,
		quantity: 460,
	},
	{
		garage_id: 51,
		part_id: 399,
		quantity: 430,
	},
	{
		garage_id: 52,
		part_id: 0,
		quantity: 68,
	},
	{
		garage_id: 52,
		part_id: 1,
		quantity: 356,
	},
	{
		garage_id: 52,
		part_id: 2,
		quantity: 147,
	},
	{
		garage_id: 52,
		part_id: 3,
		quantity: 144,
	},
	{
		garage_id: 52,
		part_id: 4,
		quantity: 125,
	},
	{
		garage_id: 52,
		part_id: 5,
		quantity: 71,
	},
	{
		garage_id: 52,
		part_id: 6,
		quantity: 82,
	},
	{
		garage_id: 52,
		part_id: 7,
		quantity: 84,
	},
	{
		garage_id: 52,
		part_id: 8,
		quantity: 445,
	},
	{
		garage_id: 52,
		part_id: 9,
		quantity: 363,
	},
	{
		garage_id: 52,
		part_id: 10,
		quantity: 340,
	},
	{
		garage_id: 52,
		part_id: 11,
		quantity: 459,
	},
	{
		garage_id: 52,
		part_id: 12,
		quantity: 476,
	},
	{
		garage_id: 52,
		part_id: 13,
		quantity: 353,
	},
	{
		garage_id: 52,
		part_id: 14,
		quantity: 11,
	},
	{
		garage_id: 52,
		part_id: 15,
		quantity: 358,
	},
	{
		garage_id: 52,
		part_id: 16,
		quantity: 494,
	},
	{
		garage_id: 52,
		part_id: 17,
		quantity: 321,
	},
	{
		garage_id: 52,
		part_id: 18,
		quantity: 333,
	},
	{
		garage_id: 52,
		part_id: 19,
		quantity: 270,
	},
	{
		garage_id: 52,
		part_id: 20,
		quantity: 487,
	},
	{
		garage_id: 52,
		part_id: 21,
		quantity: 249,
	},
	{
		garage_id: 52,
		part_id: 22,
		quantity: 231,
	},
	{
		garage_id: 52,
		part_id: 23,
		quantity: 342,
	},
	{
		garage_id: 52,
		part_id: 24,
		quantity: 176,
	},
	{
		garage_id: 52,
		part_id: 25,
		quantity: 149,
	},
	{
		garage_id: 52,
		part_id: 26,
		quantity: 205,
	},
	{
		garage_id: 52,
		part_id: 27,
		quantity: 340,
	},
	{
		garage_id: 52,
		part_id: 28,
		quantity: 356,
	},
	{
		garage_id: 52,
		part_id: 29,
		quantity: 64,
	},
	{
		garage_id: 52,
		part_id: 30,
		quantity: 298,
	},
	{
		garage_id: 52,
		part_id: 31,
		quantity: 192,
	},
	{
		garage_id: 52,
		part_id: 32,
		quantity: 304,
	},
	{
		garage_id: 52,
		part_id: 33,
		quantity: 411,
	},
	{
		garage_id: 52,
		part_id: 34,
		quantity: 111,
	},
	{
		garage_id: 52,
		part_id: 35,
		quantity: 418,
	},
	{
		garage_id: 52,
		part_id: 36,
		quantity: 150,
	},
	{
		garage_id: 52,
		part_id: 37,
		quantity: 230,
	},
	{
		garage_id: 52,
		part_id: 38,
		quantity: 88,
	},
	{
		garage_id: 52,
		part_id: 39,
		quantity: 47,
	},
	{
		garage_id: 52,
		part_id: 40,
		quantity: 430,
	},
	{
		garage_id: 52,
		part_id: 41,
		quantity: 170,
	},
	{
		garage_id: 52,
		part_id: 42,
		quantity: 402,
	},
	{
		garage_id: 52,
		part_id: 43,
		quantity: 14,
	},
	{
		garage_id: 52,
		part_id: 44,
		quantity: 271,
	},
	{
		garage_id: 52,
		part_id: 45,
		quantity: 138,
	},
	{
		garage_id: 52,
		part_id: 46,
		quantity: 75,
	},
	{
		garage_id: 52,
		part_id: 47,
		quantity: 56,
	},
	{
		garage_id: 52,
		part_id: 48,
		quantity: 129,
	},
	{
		garage_id: 52,
		part_id: 49,
		quantity: 176,
	},
	{
		garage_id: 52,
		part_id: 50,
		quantity: 36,
	},
	{
		garage_id: 52,
		part_id: 51,
		quantity: 494,
	},
	{
		garage_id: 52,
		part_id: 52,
		quantity: 24,
	},
	{
		garage_id: 52,
		part_id: 53,
		quantity: 395,
	},
	{
		garage_id: 52,
		part_id: 54,
		quantity: 18,
	},
	{
		garage_id: 52,
		part_id: 55,
		quantity: 306,
	},
	{
		garage_id: 52,
		part_id: 56,
		quantity: 250,
	},
	{
		garage_id: 52,
		part_id: 57,
		quantity: 371,
	},
	{
		garage_id: 52,
		part_id: 58,
		quantity: 469,
	},
	{
		garage_id: 52,
		part_id: 59,
		quantity: 106,
	},
	{
		garage_id: 52,
		part_id: 60,
		quantity: 166,
	},
	{
		garage_id: 52,
		part_id: 61,
		quantity: 90,
	},
	{
		garage_id: 52,
		part_id: 62,
		quantity: 7,
	},
	{
		garage_id: 52,
		part_id: 63,
		quantity: 18,
	},
	{
		garage_id: 52,
		part_id: 64,
		quantity: 18,
	},
	{
		garage_id: 52,
		part_id: 65,
		quantity: 199,
	},
	{
		garage_id: 52,
		part_id: 66,
		quantity: 99,
	},
	{
		garage_id: 52,
		part_id: 67,
		quantity: 462,
	},
	{
		garage_id: 52,
		part_id: 68,
		quantity: 101,
	},
	{
		garage_id: 52,
		part_id: 69,
		quantity: 124,
	},
	{
		garage_id: 52,
		part_id: 70,
		quantity: 285,
	},
	{
		garage_id: 52,
		part_id: 71,
		quantity: 337,
	},
	{
		garage_id: 52,
		part_id: 72,
		quantity: 498,
	},
	{
		garage_id: 52,
		part_id: 73,
		quantity: 276,
	},
	{
		garage_id: 52,
		part_id: 74,
		quantity: 240,
	},
	{
		garage_id: 52,
		part_id: 75,
		quantity: 438,
	},
	{
		garage_id: 52,
		part_id: 76,
		quantity: 4,
	},
	{
		garage_id: 52,
		part_id: 77,
		quantity: 29,
	},
	{
		garage_id: 52,
		part_id: 78,
		quantity: 421,
	},
	{
		garage_id: 52,
		part_id: 79,
		quantity: 477,
	},
	{
		garage_id: 52,
		part_id: 80,
		quantity: 21,
	},
	{
		garage_id: 52,
		part_id: 81,
		quantity: 492,
	},
	{
		garage_id: 52,
		part_id: 82,
		quantity: 250,
	},
	{
		garage_id: 52,
		part_id: 83,
		quantity: 422,
	},
	{
		garage_id: 52,
		part_id: 84,
		quantity: 302,
	},
	{
		garage_id: 52,
		part_id: 85,
		quantity: 385,
	},
	{
		garage_id: 52,
		part_id: 86,
		quantity: 27,
	},
	{
		garage_id: 52,
		part_id: 87,
		quantity: 42,
	},
	{
		garage_id: 52,
		part_id: 88,
		quantity: 384,
	},
	{
		garage_id: 52,
		part_id: 89,
		quantity: 448,
	},
	{
		garage_id: 52,
		part_id: 90,
		quantity: 108,
	},
	{
		garage_id: 52,
		part_id: 91,
		quantity: 490,
	},
	{
		garage_id: 52,
		part_id: 92,
		quantity: 59,
	},
	{
		garage_id: 52,
		part_id: 93,
		quantity: 316,
	},
	{
		garage_id: 52,
		part_id: 94,
		quantity: 437,
	},
	{
		garage_id: 52,
		part_id: 95,
		quantity: 285,
	},
	{
		garage_id: 52,
		part_id: 96,
		quantity: 191,
	},
	{
		garage_id: 52,
		part_id: 97,
		quantity: 418,
	},
	{
		garage_id: 52,
		part_id: 98,
		quantity: 472,
	},
	{
		garage_id: 52,
		part_id: 99,
		quantity: 407,
	},
	{
		garage_id: 52,
		part_id: 100,
		quantity: 484,
	},
	{
		garage_id: 52,
		part_id: 101,
		quantity: 31,
	},
	{
		garage_id: 52,
		part_id: 102,
		quantity: 143,
	},
	{
		garage_id: 52,
		part_id: 103,
		quantity: 190,
	},
	{
		garage_id: 52,
		part_id: 104,
		quantity: 71,
	},
	{
		garage_id: 52,
		part_id: 105,
		quantity: 409,
	},
	{
		garage_id: 52,
		part_id: 106,
		quantity: 8,
	},
	{
		garage_id: 52,
		part_id: 107,
		quantity: 409,
	},
	{
		garage_id: 52,
		part_id: 108,
		quantity: 177,
	},
	{
		garage_id: 52,
		part_id: 109,
		quantity: 352,
	},
	{
		garage_id: 52,
		part_id: 110,
		quantity: 341,
	},
	{
		garage_id: 52,
		part_id: 111,
		quantity: 210,
	},
	{
		garage_id: 52,
		part_id: 112,
		quantity: 50,
	},
	{
		garage_id: 52,
		part_id: 113,
		quantity: 278,
	},
	{
		garage_id: 52,
		part_id: 114,
		quantity: 236,
	},
	{
		garage_id: 52,
		part_id: 115,
		quantity: 322,
	},
	{
		garage_id: 52,
		part_id: 116,
		quantity: 345,
	},
	{
		garage_id: 52,
		part_id: 117,
		quantity: 146,
	},
	{
		garage_id: 52,
		part_id: 118,
		quantity: 478,
	},
	{
		garage_id: 52,
		part_id: 119,
		quantity: 439,
	},
	{
		garage_id: 52,
		part_id: 120,
		quantity: 46,
	},
	{
		garage_id: 52,
		part_id: 121,
		quantity: 278,
	},
	{
		garage_id: 52,
		part_id: 122,
		quantity: 181,
	},
	{
		garage_id: 52,
		part_id: 123,
		quantity: 396,
	},
	{
		garage_id: 52,
		part_id: 124,
		quantity: 62,
	},
	{
		garage_id: 52,
		part_id: 125,
		quantity: 317,
	},
	{
		garage_id: 52,
		part_id: 126,
		quantity: 365,
	},
	{
		garage_id: 52,
		part_id: 127,
		quantity: 181,
	},
	{
		garage_id: 52,
		part_id: 128,
		quantity: 150,
	},
	{
		garage_id: 52,
		part_id: 129,
		quantity: 255,
	},
	{
		garage_id: 52,
		part_id: 130,
		quantity: 157,
	},
	{
		garage_id: 52,
		part_id: 131,
		quantity: 197,
	},
	{
		garage_id: 52,
		part_id: 132,
		quantity: 269,
	},
	{
		garage_id: 52,
		part_id: 133,
		quantity: 135,
	},
	{
		garage_id: 52,
		part_id: 134,
		quantity: 249,
	},
	{
		garage_id: 52,
		part_id: 135,
		quantity: 127,
	},
	{
		garage_id: 52,
		part_id: 136,
		quantity: 415,
	},
	{
		garage_id: 52,
		part_id: 137,
		quantity: 466,
	},
	{
		garage_id: 52,
		part_id: 138,
		quantity: 469,
	},
	{
		garage_id: 52,
		part_id: 139,
		quantity: 392,
	},
	{
		garage_id: 52,
		part_id: 140,
		quantity: 126,
	},
	{
		garage_id: 52,
		part_id: 141,
		quantity: 254,
	},
	{
		garage_id: 52,
		part_id: 142,
		quantity: 434,
	},
	{
		garage_id: 52,
		part_id: 143,
		quantity: 497,
	},
	{
		garage_id: 52,
		part_id: 144,
		quantity: 178,
	},
	{
		garage_id: 52,
		part_id: 145,
		quantity: 14,
	},
	{
		garage_id: 52,
		part_id: 146,
		quantity: 457,
	},
	{
		garage_id: 52,
		part_id: 147,
		quantity: 319,
	},
	{
		garage_id: 52,
		part_id: 148,
		quantity: 429,
	},
	{
		garage_id: 52,
		part_id: 149,
		quantity: 264,
	},
	{
		garage_id: 52,
		part_id: 150,
		quantity: 444,
	},
	{
		garage_id: 52,
		part_id: 151,
		quantity: 245,
	},
	{
		garage_id: 52,
		part_id: 152,
		quantity: 413,
	},
	{
		garage_id: 52,
		part_id: 153,
		quantity: 465,
	},
	{
		garage_id: 52,
		part_id: 154,
		quantity: 343,
	},
	{
		garage_id: 52,
		part_id: 155,
		quantity: 330,
	},
	{
		garage_id: 52,
		part_id: 156,
		quantity: 35,
	},
	{
		garage_id: 52,
		part_id: 157,
		quantity: 29,
	},
	{
		garage_id: 52,
		part_id: 158,
		quantity: 159,
	},
	{
		garage_id: 52,
		part_id: 159,
		quantity: 70,
	},
	{
		garage_id: 52,
		part_id: 160,
		quantity: 262,
	},
	{
		garage_id: 52,
		part_id: 161,
		quantity: 338,
	},
	{
		garage_id: 52,
		part_id: 162,
		quantity: 269,
	},
	{
		garage_id: 52,
		part_id: 163,
		quantity: 104,
	},
	{
		garage_id: 52,
		part_id: 164,
		quantity: 66,
	},
	{
		garage_id: 52,
		part_id: 165,
		quantity: 87,
	},
	{
		garage_id: 52,
		part_id: 166,
		quantity: 88,
	},
	{
		garage_id: 52,
		part_id: 167,
		quantity: 377,
	},
	{
		garage_id: 52,
		part_id: 168,
		quantity: 239,
	},
	{
		garage_id: 52,
		part_id: 169,
		quantity: 408,
	},
	{
		garage_id: 52,
		part_id: 170,
		quantity: 144,
	},
	{
		garage_id: 52,
		part_id: 171,
		quantity: 235,
	},
	{
		garage_id: 52,
		part_id: 172,
		quantity: 450,
	},
	{
		garage_id: 52,
		part_id: 173,
		quantity: 335,
	},
	{
		garage_id: 52,
		part_id: 174,
		quantity: 380,
	},
	{
		garage_id: 52,
		part_id: 175,
		quantity: 236,
	},
	{
		garage_id: 52,
		part_id: 176,
		quantity: 471,
	},
	{
		garage_id: 52,
		part_id: 177,
		quantity: 360,
	},
	{
		garage_id: 52,
		part_id: 178,
		quantity: 77,
	},
	{
		garage_id: 52,
		part_id: 179,
		quantity: 26,
	},
	{
		garage_id: 52,
		part_id: 180,
		quantity: 381,
	},
	{
		garage_id: 52,
		part_id: 181,
		quantity: 117,
	},
	{
		garage_id: 52,
		part_id: 182,
		quantity: 114,
	},
	{
		garage_id: 52,
		part_id: 183,
		quantity: 158,
	},
	{
		garage_id: 52,
		part_id: 184,
		quantity: 256,
	},
	{
		garage_id: 52,
		part_id: 185,
		quantity: 377,
	},
	{
		garage_id: 52,
		part_id: 186,
		quantity: 88,
	},
	{
		garage_id: 52,
		part_id: 187,
		quantity: 266,
	},
	{
		garage_id: 52,
		part_id: 188,
		quantity: 455,
	},
	{
		garage_id: 52,
		part_id: 189,
		quantity: 314,
	},
	{
		garage_id: 52,
		part_id: 190,
		quantity: 317,
	},
	{
		garage_id: 52,
		part_id: 191,
		quantity: 32,
	},
	{
		garage_id: 52,
		part_id: 192,
		quantity: 49,
	},
	{
		garage_id: 52,
		part_id: 193,
		quantity: 353,
	},
	{
		garage_id: 52,
		part_id: 194,
		quantity: 470,
	},
	{
		garage_id: 52,
		part_id: 195,
		quantity: 344,
	},
	{
		garage_id: 52,
		part_id: 196,
		quantity: 273,
	},
	{
		garage_id: 52,
		part_id: 197,
		quantity: 414,
	},
	{
		garage_id: 52,
		part_id: 198,
		quantity: 82,
	},
	{
		garage_id: 52,
		part_id: 199,
		quantity: 248,
	},
	{
		garage_id: 52,
		part_id: 200,
		quantity: 223,
	},
	{
		garage_id: 52,
		part_id: 201,
		quantity: 319,
	},
	{
		garage_id: 52,
		part_id: 202,
		quantity: 237,
	},
	{
		garage_id: 52,
		part_id: 203,
		quantity: 494,
	},
	{
		garage_id: 52,
		part_id: 204,
		quantity: 488,
	},
	{
		garage_id: 52,
		part_id: 205,
		quantity: 89,
	},
	{
		garage_id: 52,
		part_id: 206,
		quantity: 84,
	},
	{
		garage_id: 52,
		part_id: 207,
		quantity: 427,
	},
	{
		garage_id: 52,
		part_id: 208,
		quantity: 143,
	},
	{
		garage_id: 52,
		part_id: 209,
		quantity: 366,
	},
	{
		garage_id: 52,
		part_id: 210,
		quantity: 213,
	},
	{
		garage_id: 52,
		part_id: 211,
		quantity: 463,
	},
	{
		garage_id: 52,
		part_id: 212,
		quantity: 11,
	},
	{
		garage_id: 52,
		part_id: 213,
		quantity: 428,
	},
	{
		garage_id: 52,
		part_id: 214,
		quantity: 431,
	},
	{
		garage_id: 52,
		part_id: 215,
		quantity: 402,
	},
	{
		garage_id: 52,
		part_id: 216,
		quantity: 57,
	},
	{
		garage_id: 52,
		part_id: 217,
		quantity: 336,
	},
	{
		garage_id: 52,
		part_id: 218,
		quantity: 480,
	},
	{
		garage_id: 52,
		part_id: 219,
		quantity: 302,
	},
	{
		garage_id: 52,
		part_id: 220,
		quantity: 127,
	},
	{
		garage_id: 52,
		part_id: 221,
		quantity: 419,
	},
	{
		garage_id: 52,
		part_id: 222,
		quantity: 11,
	},
	{
		garage_id: 52,
		part_id: 223,
		quantity: 286,
	},
	{
		garage_id: 52,
		part_id: 224,
		quantity: 130,
	},
	{
		garage_id: 52,
		part_id: 225,
		quantity: 134,
	},
	{
		garage_id: 52,
		part_id: 226,
		quantity: 345,
	},
	{
		garage_id: 52,
		part_id: 227,
		quantity: 236,
	},
	{
		garage_id: 52,
		part_id: 228,
		quantity: 484,
	},
	{
		garage_id: 52,
		part_id: 229,
		quantity: 359,
	},
	{
		garage_id: 52,
		part_id: 230,
		quantity: 4,
	},
	{
		garage_id: 52,
		part_id: 231,
		quantity: 295,
	},
	{
		garage_id: 52,
		part_id: 232,
		quantity: 154,
	},
	{
		garage_id: 52,
		part_id: 233,
		quantity: 190,
	},
	{
		garage_id: 52,
		part_id: 234,
		quantity: 71,
	},
	{
		garage_id: 52,
		part_id: 235,
		quantity: 395,
	},
	{
		garage_id: 52,
		part_id: 236,
		quantity: 76,
	},
	{
		garage_id: 52,
		part_id: 237,
		quantity: 421,
	},
	{
		garage_id: 52,
		part_id: 238,
		quantity: 166,
	},
	{
		garage_id: 52,
		part_id: 239,
		quantity: 119,
	},
	{
		garage_id: 52,
		part_id: 240,
		quantity: 415,
	},
	{
		garage_id: 52,
		part_id: 241,
		quantity: 215,
	},
	{
		garage_id: 52,
		part_id: 242,
		quantity: 418,
	},
	{
		garage_id: 52,
		part_id: 243,
		quantity: 58,
	},
	{
		garage_id: 52,
		part_id: 244,
		quantity: 341,
	},
	{
		garage_id: 52,
		part_id: 245,
		quantity: 363,
	},
	{
		garage_id: 52,
		part_id: 246,
		quantity: 363,
	},
	{
		garage_id: 52,
		part_id: 247,
		quantity: 141,
	},
	{
		garage_id: 52,
		part_id: 248,
		quantity: 253,
	},
	{
		garage_id: 52,
		part_id: 249,
		quantity: 210,
	},
	{
		garage_id: 52,
		part_id: 250,
		quantity: 422,
	},
	{
		garage_id: 52,
		part_id: 251,
		quantity: 279,
	},
	{
		garage_id: 52,
		part_id: 252,
		quantity: 147,
	},
	{
		garage_id: 52,
		part_id: 253,
		quantity: 84,
	},
	{
		garage_id: 52,
		part_id: 254,
		quantity: 273,
	},
	{
		garage_id: 52,
		part_id: 255,
		quantity: 109,
	},
	{
		garage_id: 52,
		part_id: 256,
		quantity: 370,
	},
	{
		garage_id: 52,
		part_id: 257,
		quantity: 275,
	},
	{
		garage_id: 52,
		part_id: 258,
		quantity: 282,
	},
	{
		garage_id: 52,
		part_id: 259,
		quantity: 281,
	},
	{
		garage_id: 52,
		part_id: 260,
		quantity: 99,
	},
	{
		garage_id: 52,
		part_id: 261,
		quantity: 10,
	},
	{
		garage_id: 52,
		part_id: 262,
		quantity: 394,
	},
	{
		garage_id: 52,
		part_id: 263,
		quantity: 38,
	},
	{
		garage_id: 52,
		part_id: 264,
		quantity: 191,
	},
	{
		garage_id: 52,
		part_id: 265,
		quantity: 151,
	},
	{
		garage_id: 52,
		part_id: 266,
		quantity: 122,
	},
	{
		garage_id: 52,
		part_id: 267,
		quantity: 112,
	},
	{
		garage_id: 52,
		part_id: 268,
		quantity: 145,
	},
	{
		garage_id: 52,
		part_id: 269,
		quantity: 237,
	},
	{
		garage_id: 52,
		part_id: 270,
		quantity: 2,
	},
	{
		garage_id: 52,
		part_id: 271,
		quantity: 161,
	},
	{
		garage_id: 52,
		part_id: 272,
		quantity: 68,
	},
	{
		garage_id: 52,
		part_id: 273,
		quantity: 155,
	},
	{
		garage_id: 52,
		part_id: 274,
		quantity: 113,
	},
	{
		garage_id: 52,
		part_id: 275,
		quantity: 128,
	},
	{
		garage_id: 52,
		part_id: 276,
		quantity: 169,
	},
	{
		garage_id: 52,
		part_id: 277,
		quantity: 469,
	},
	{
		garage_id: 52,
		part_id: 278,
		quantity: 343,
	},
	{
		garage_id: 52,
		part_id: 279,
		quantity: 85,
	},
	{
		garage_id: 52,
		part_id: 280,
		quantity: 353,
	},
	{
		garage_id: 52,
		part_id: 281,
		quantity: 298,
	},
	{
		garage_id: 52,
		part_id: 282,
		quantity: 84,
	},
	{
		garage_id: 52,
		part_id: 283,
		quantity: 240,
	},
	{
		garage_id: 52,
		part_id: 284,
		quantity: 287,
	},
	{
		garage_id: 52,
		part_id: 285,
		quantity: 199,
	},
	{
		garage_id: 52,
		part_id: 286,
		quantity: 411,
	},
	{
		garage_id: 52,
		part_id: 287,
		quantity: 484,
	},
	{
		garage_id: 52,
		part_id: 288,
		quantity: 113,
	},
	{
		garage_id: 52,
		part_id: 289,
		quantity: 77,
	},
	{
		garage_id: 52,
		part_id: 290,
		quantity: 32,
	},
	{
		garage_id: 52,
		part_id: 291,
		quantity: 33,
	},
	{
		garage_id: 52,
		part_id: 292,
		quantity: 423,
	},
	{
		garage_id: 52,
		part_id: 293,
		quantity: 395,
	},
	{
		garage_id: 52,
		part_id: 294,
		quantity: 262,
	},
	{
		garage_id: 52,
		part_id: 295,
		quantity: 481,
	},
	{
		garage_id: 52,
		part_id: 296,
		quantity: 171,
	},
	{
		garage_id: 52,
		part_id: 297,
		quantity: 35,
	},
	{
		garage_id: 52,
		part_id: 298,
		quantity: 402,
	},
	{
		garage_id: 52,
		part_id: 299,
		quantity: 419,
	},
	{
		garage_id: 52,
		part_id: 300,
		quantity: 339,
	},
	{
		garage_id: 52,
		part_id: 301,
		quantity: 120,
	},
	{
		garage_id: 52,
		part_id: 302,
		quantity: 329,
	},
	{
		garage_id: 52,
		part_id: 303,
		quantity: 121,
	},
	{
		garage_id: 52,
		part_id: 304,
		quantity: 331,
	},
	{
		garage_id: 52,
		part_id: 305,
		quantity: 308,
	},
	{
		garage_id: 52,
		part_id: 306,
		quantity: 372,
	},
	{
		garage_id: 52,
		part_id: 307,
		quantity: 491,
	},
	{
		garage_id: 52,
		part_id: 308,
		quantity: 111,
	},
	{
		garage_id: 52,
		part_id: 309,
		quantity: 291,
	},
	{
		garage_id: 52,
		part_id: 310,
		quantity: 280,
	},
	{
		garage_id: 52,
		part_id: 311,
		quantity: 98,
	},
	{
		garage_id: 52,
		part_id: 312,
		quantity: 133,
	},
	{
		garage_id: 52,
		part_id: 313,
		quantity: 436,
	},
	{
		garage_id: 52,
		part_id: 314,
		quantity: 392,
	},
	{
		garage_id: 52,
		part_id: 315,
		quantity: 102,
	},
	{
		garage_id: 52,
		part_id: 316,
		quantity: 350,
	},
	{
		garage_id: 52,
		part_id: 317,
		quantity: 245,
	},
	{
		garage_id: 52,
		part_id: 318,
		quantity: 264,
	},
	{
		garage_id: 52,
		part_id: 319,
		quantity: 416,
	},
	{
		garage_id: 52,
		part_id: 320,
		quantity: 417,
	},
	{
		garage_id: 52,
		part_id: 321,
		quantity: 364,
	},
	{
		garage_id: 52,
		part_id: 322,
		quantity: 136,
	},
	{
		garage_id: 52,
		part_id: 323,
		quantity: 485,
	},
	{
		garage_id: 52,
		part_id: 324,
		quantity: 427,
	},
	{
		garage_id: 52,
		part_id: 325,
		quantity: 162,
	},
	{
		garage_id: 52,
		part_id: 326,
		quantity: 126,
	},
	{
		garage_id: 52,
		part_id: 327,
		quantity: 246,
	},
	{
		garage_id: 52,
		part_id: 328,
		quantity: 152,
	},
	{
		garage_id: 52,
		part_id: 329,
		quantity: 477,
	},
	{
		garage_id: 52,
		part_id: 330,
		quantity: 498,
	},
	{
		garage_id: 52,
		part_id: 331,
		quantity: 271,
	},
	{
		garage_id: 52,
		part_id: 332,
		quantity: 110,
	},
	{
		garage_id: 52,
		part_id: 333,
		quantity: 394,
	},
	{
		garage_id: 52,
		part_id: 334,
		quantity: 151,
	},
	{
		garage_id: 52,
		part_id: 335,
		quantity: 400,
	},
	{
		garage_id: 52,
		part_id: 336,
		quantity: 151,
	},
	{
		garage_id: 52,
		part_id: 337,
		quantity: 258,
	},
	{
		garage_id: 52,
		part_id: 338,
		quantity: 103,
	},
	{
		garage_id: 52,
		part_id: 339,
		quantity: 148,
	},
	{
		garage_id: 52,
		part_id: 340,
		quantity: 415,
	},
	{
		garage_id: 52,
		part_id: 341,
		quantity: 175,
	},
	{
		garage_id: 52,
		part_id: 342,
		quantity: 361,
	},
	{
		garage_id: 52,
		part_id: 343,
		quantity: 306,
	},
	{
		garage_id: 52,
		part_id: 344,
		quantity: 300,
	},
	{
		garage_id: 52,
		part_id: 345,
		quantity: 324,
	},
	{
		garage_id: 52,
		part_id: 346,
		quantity: 210,
	},
	{
		garage_id: 52,
		part_id: 347,
		quantity: 49,
	},
	{
		garage_id: 52,
		part_id: 348,
		quantity: 316,
	},
	{
		garage_id: 52,
		part_id: 349,
		quantity: 159,
	},
	{
		garage_id: 52,
		part_id: 350,
		quantity: 370,
	},
	{
		garage_id: 52,
		part_id: 351,
		quantity: 486,
	},
	{
		garage_id: 52,
		part_id: 352,
		quantity: 0,
	},
	{
		garage_id: 52,
		part_id: 353,
		quantity: 125,
	},
	{
		garage_id: 52,
		part_id: 354,
		quantity: 264,
	},
	{
		garage_id: 52,
		part_id: 355,
		quantity: 224,
	},
	{
		garage_id: 52,
		part_id: 356,
		quantity: 379,
	},
	{
		garage_id: 52,
		part_id: 357,
		quantity: 370,
	},
	{
		garage_id: 52,
		part_id: 358,
		quantity: 120,
	},
	{
		garage_id: 52,
		part_id: 359,
		quantity: 30,
	},
	{
		garage_id: 52,
		part_id: 360,
		quantity: 417,
	},
	{
		garage_id: 52,
		part_id: 361,
		quantity: 236,
	},
	{
		garage_id: 52,
		part_id: 362,
		quantity: 494,
	},
	{
		garage_id: 52,
		part_id: 363,
		quantity: 466,
	},
	{
		garage_id: 52,
		part_id: 364,
		quantity: 77,
	},
	{
		garage_id: 52,
		part_id: 365,
		quantity: 52,
	},
	{
		garage_id: 52,
		part_id: 366,
		quantity: 25,
	},
	{
		garage_id: 52,
		part_id: 367,
		quantity: 82,
	},
	{
		garage_id: 52,
		part_id: 368,
		quantity: 217,
	},
	{
		garage_id: 52,
		part_id: 369,
		quantity: 314,
	},
	{
		garage_id: 52,
		part_id: 370,
		quantity: 413,
	},
	{
		garage_id: 52,
		part_id: 371,
		quantity: 398,
	},
	{
		garage_id: 52,
		part_id: 372,
		quantity: 271,
	},
	{
		garage_id: 52,
		part_id: 373,
		quantity: 310,
	},
	{
		garage_id: 52,
		part_id: 374,
		quantity: 128,
	},
	{
		garage_id: 52,
		part_id: 375,
		quantity: 409,
	},
	{
		garage_id: 52,
		part_id: 376,
		quantity: 188,
	},
	{
		garage_id: 52,
		part_id: 377,
		quantity: 3,
	},
	{
		garage_id: 52,
		part_id: 378,
		quantity: 442,
	},
	{
		garage_id: 52,
		part_id: 379,
		quantity: 195,
	},
	{
		garage_id: 52,
		part_id: 380,
		quantity: 243,
	},
	{
		garage_id: 52,
		part_id: 381,
		quantity: 190,
	},
	{
		garage_id: 52,
		part_id: 382,
		quantity: 452,
	},
	{
		garage_id: 52,
		part_id: 383,
		quantity: 426,
	},
	{
		garage_id: 52,
		part_id: 384,
		quantity: 414,
	},
	{
		garage_id: 52,
		part_id: 385,
		quantity: 450,
	},
	{
		garage_id: 52,
		part_id: 386,
		quantity: 163,
	},
	{
		garage_id: 52,
		part_id: 387,
		quantity: 359,
	},
	{
		garage_id: 52,
		part_id: 388,
		quantity: 15,
	},
	{
		garage_id: 52,
		part_id: 389,
		quantity: 168,
	},
	{
		garage_id: 52,
		part_id: 390,
		quantity: 460,
	},
	{
		garage_id: 52,
		part_id: 391,
		quantity: 232,
	},
	{
		garage_id: 52,
		part_id: 392,
		quantity: 219,
	},
	{
		garage_id: 52,
		part_id: 393,
		quantity: 276,
	},
	{
		garage_id: 52,
		part_id: 394,
		quantity: 418,
	},
	{
		garage_id: 52,
		part_id: 395,
		quantity: 167,
	},
	{
		garage_id: 52,
		part_id: 396,
		quantity: 124,
	},
	{
		garage_id: 52,
		part_id: 397,
		quantity: 393,
	},
	{
		garage_id: 52,
		part_id: 398,
		quantity: 137,
	},
	{
		garage_id: 52,
		part_id: 399,
		quantity: 151,
	},
	{
		garage_id: 53,
		part_id: 0,
		quantity: 104,
	},
	{
		garage_id: 53,
		part_id: 1,
		quantity: 491,
	},
	{
		garage_id: 53,
		part_id: 2,
		quantity: 152,
	},
	{
		garage_id: 53,
		part_id: 3,
		quantity: 199,
	},
	{
		garage_id: 53,
		part_id: 4,
		quantity: 241,
	},
	{
		garage_id: 53,
		part_id: 5,
		quantity: 129,
	},
	{
		garage_id: 53,
		part_id: 6,
		quantity: 282,
	},
	{
		garage_id: 53,
		part_id: 7,
		quantity: 270,
	},
	{
		garage_id: 53,
		part_id: 8,
		quantity: 328,
	},
	{
		garage_id: 53,
		part_id: 9,
		quantity: 23,
	},
	{
		garage_id: 53,
		part_id: 10,
		quantity: 79,
	},
	{
		garage_id: 53,
		part_id: 11,
		quantity: 477,
	},
	{
		garage_id: 53,
		part_id: 12,
		quantity: 95,
	},
	{
		garage_id: 53,
		part_id: 13,
		quantity: 78,
	},
	{
		garage_id: 53,
		part_id: 14,
		quantity: 176,
	},
	{
		garage_id: 53,
		part_id: 15,
		quantity: 413,
	},
	{
		garage_id: 53,
		part_id: 16,
		quantity: 306,
	},
	{
		garage_id: 53,
		part_id: 17,
		quantity: 438,
	},
	{
		garage_id: 53,
		part_id: 18,
		quantity: 192,
	},
	{
		garage_id: 53,
		part_id: 19,
		quantity: 329,
	},
	{
		garage_id: 53,
		part_id: 20,
		quantity: 83,
	},
	{
		garage_id: 53,
		part_id: 21,
		quantity: 172,
	},
	{
		garage_id: 53,
		part_id: 22,
		quantity: 492,
	},
	{
		garage_id: 53,
		part_id: 23,
		quantity: 200,
	},
	{
		garage_id: 53,
		part_id: 24,
		quantity: 47,
	},
	{
		garage_id: 53,
		part_id: 25,
		quantity: 154,
	},
	{
		garage_id: 53,
		part_id: 26,
		quantity: 241,
	},
	{
		garage_id: 53,
		part_id: 27,
		quantity: 201,
	},
	{
		garage_id: 53,
		part_id: 28,
		quantity: 387,
	},
	{
		garage_id: 53,
		part_id: 29,
		quantity: 369,
	},
	{
		garage_id: 53,
		part_id: 30,
		quantity: 263,
	},
	{
		garage_id: 53,
		part_id: 31,
		quantity: 198,
	},
	{
		garage_id: 53,
		part_id: 32,
		quantity: 421,
	},
	{
		garage_id: 53,
		part_id: 33,
		quantity: 339,
	},
	{
		garage_id: 53,
		part_id: 34,
		quantity: 72,
	},
	{
		garage_id: 53,
		part_id: 35,
		quantity: 130,
	},
	{
		garage_id: 53,
		part_id: 36,
		quantity: 293,
	},
	{
		garage_id: 53,
		part_id: 37,
		quantity: 384,
	},
	{
		garage_id: 53,
		part_id: 38,
		quantity: 440,
	},
	{
		garage_id: 53,
		part_id: 39,
		quantity: 273,
	},
	{
		garage_id: 53,
		part_id: 40,
		quantity: 94,
	},
	{
		garage_id: 53,
		part_id: 41,
		quantity: 214,
	},
	{
		garage_id: 53,
		part_id: 42,
		quantity: 470,
	},
	{
		garage_id: 53,
		part_id: 43,
		quantity: 429,
	},
	{
		garage_id: 53,
		part_id: 44,
		quantity: 496,
	},
	{
		garage_id: 53,
		part_id: 45,
		quantity: 251,
	},
	{
		garage_id: 53,
		part_id: 46,
		quantity: 247,
	},
	{
		garage_id: 53,
		part_id: 47,
		quantity: 191,
	},
	{
		garage_id: 53,
		part_id: 48,
		quantity: 107,
	},
	{
		garage_id: 53,
		part_id: 49,
		quantity: 298,
	},
	{
		garage_id: 53,
		part_id: 50,
		quantity: 100,
	},
	{
		garage_id: 53,
		part_id: 51,
		quantity: 293,
	},
	{
		garage_id: 53,
		part_id: 52,
		quantity: 100,
	},
	{
		garage_id: 53,
		part_id: 53,
		quantity: 7,
	},
	{
		garage_id: 53,
		part_id: 54,
		quantity: 368,
	},
	{
		garage_id: 53,
		part_id: 55,
		quantity: 6,
	},
	{
		garage_id: 53,
		part_id: 56,
		quantity: 109,
	},
	{
		garage_id: 53,
		part_id: 57,
		quantity: 414,
	},
	{
		garage_id: 53,
		part_id: 58,
		quantity: 344,
	},
	{
		garage_id: 53,
		part_id: 59,
		quantity: 155,
	},
	{
		garage_id: 53,
		part_id: 60,
		quantity: 448,
	},
	{
		garage_id: 53,
		part_id: 61,
		quantity: 141,
	},
	{
		garage_id: 53,
		part_id: 62,
		quantity: 200,
	},
	{
		garage_id: 53,
		part_id: 63,
		quantity: 138,
	},
	{
		garage_id: 53,
		part_id: 64,
		quantity: 252,
	},
	{
		garage_id: 53,
		part_id: 65,
		quantity: 404,
	},
	{
		garage_id: 53,
		part_id: 66,
		quantity: 479,
	},
	{
		garage_id: 53,
		part_id: 67,
		quantity: 315,
	},
	{
		garage_id: 53,
		part_id: 68,
		quantity: 58,
	},
	{
		garage_id: 53,
		part_id: 69,
		quantity: 322,
	},
	{
		garage_id: 53,
		part_id: 70,
		quantity: 50,
	},
	{
		garage_id: 53,
		part_id: 71,
		quantity: 322,
	},
	{
		garage_id: 53,
		part_id: 72,
		quantity: 151,
	},
	{
		garage_id: 53,
		part_id: 73,
		quantity: 426,
	},
	{
		garage_id: 53,
		part_id: 74,
		quantity: 426,
	},
	{
		garage_id: 53,
		part_id: 75,
		quantity: 78,
	},
	{
		garage_id: 53,
		part_id: 76,
		quantity: 142,
	},
	{
		garage_id: 53,
		part_id: 77,
		quantity: 344,
	},
	{
		garage_id: 53,
		part_id: 78,
		quantity: 223,
	},
	{
		garage_id: 53,
		part_id: 79,
		quantity: 128,
	},
	{
		garage_id: 53,
		part_id: 80,
		quantity: 239,
	},
	{
		garage_id: 53,
		part_id: 81,
		quantity: 320,
	},
	{
		garage_id: 53,
		part_id: 82,
		quantity: 130,
	},
	{
		garage_id: 53,
		part_id: 83,
		quantity: 19,
	},
	{
		garage_id: 53,
		part_id: 84,
		quantity: 30,
	},
	{
		garage_id: 53,
		part_id: 85,
		quantity: 365,
	},
	{
		garage_id: 53,
		part_id: 86,
		quantity: 402,
	},
	{
		garage_id: 53,
		part_id: 87,
		quantity: 8,
	},
	{
		garage_id: 53,
		part_id: 88,
		quantity: 188,
	},
	{
		garage_id: 53,
		part_id: 89,
		quantity: 209,
	},
	{
		garage_id: 53,
		part_id: 90,
		quantity: 51,
	},
	{
		garage_id: 53,
		part_id: 91,
		quantity: 207,
	},
	{
		garage_id: 53,
		part_id: 92,
		quantity: 54,
	},
	{
		garage_id: 53,
		part_id: 93,
		quantity: 268,
	},
	{
		garage_id: 53,
		part_id: 94,
		quantity: 147,
	},
	{
		garage_id: 53,
		part_id: 95,
		quantity: 193,
	},
	{
		garage_id: 53,
		part_id: 96,
		quantity: 150,
	},
	{
		garage_id: 53,
		part_id: 97,
		quantity: 425,
	},
	{
		garage_id: 53,
		part_id: 98,
		quantity: 269,
	},
	{
		garage_id: 53,
		part_id: 99,
		quantity: 225,
	},
	{
		garage_id: 53,
		part_id: 100,
		quantity: 5,
	},
	{
		garage_id: 53,
		part_id: 101,
		quantity: 401,
	},
	{
		garage_id: 53,
		part_id: 102,
		quantity: 343,
	},
	{
		garage_id: 53,
		part_id: 103,
		quantity: 224,
	},
	{
		garage_id: 53,
		part_id: 104,
		quantity: 321,
	},
	{
		garage_id: 53,
		part_id: 105,
		quantity: 27,
	},
	{
		garage_id: 53,
		part_id: 106,
		quantity: 236,
	},
	{
		garage_id: 53,
		part_id: 107,
		quantity: 280,
	},
	{
		garage_id: 53,
		part_id: 108,
		quantity: 262,
	},
	{
		garage_id: 53,
		part_id: 109,
		quantity: 293,
	},
	{
		garage_id: 53,
		part_id: 110,
		quantity: 208,
	},
	{
		garage_id: 53,
		part_id: 111,
		quantity: 405,
	},
	{
		garage_id: 53,
		part_id: 112,
		quantity: 22,
	},
	{
		garage_id: 53,
		part_id: 113,
		quantity: 271,
	},
	{
		garage_id: 53,
		part_id: 114,
		quantity: 191,
	},
	{
		garage_id: 53,
		part_id: 115,
		quantity: 402,
	},
	{
		garage_id: 53,
		part_id: 116,
		quantity: 81,
	},
	{
		garage_id: 53,
		part_id: 117,
		quantity: 79,
	},
	{
		garage_id: 53,
		part_id: 118,
		quantity: 115,
	},
	{
		garage_id: 53,
		part_id: 119,
		quantity: 64,
	},
	{
		garage_id: 53,
		part_id: 120,
		quantity: 313,
	},
	{
		garage_id: 53,
		part_id: 121,
		quantity: 465,
	},
	{
		garage_id: 53,
		part_id: 122,
		quantity: 218,
	},
	{
		garage_id: 53,
		part_id: 123,
		quantity: 333,
	},
	{
		garage_id: 53,
		part_id: 124,
		quantity: 93,
	},
	{
		garage_id: 53,
		part_id: 125,
		quantity: 324,
	},
	{
		garage_id: 53,
		part_id: 126,
		quantity: 113,
	},
	{
		garage_id: 53,
		part_id: 127,
		quantity: 134,
	},
	{
		garage_id: 53,
		part_id: 128,
		quantity: 327,
	},
	{
		garage_id: 53,
		part_id: 129,
		quantity: 492,
	},
	{
		garage_id: 53,
		part_id: 130,
		quantity: 479,
	},
	{
		garage_id: 53,
		part_id: 131,
		quantity: 28,
	},
	{
		garage_id: 53,
		part_id: 132,
		quantity: 349,
	},
	{
		garage_id: 53,
		part_id: 133,
		quantity: 319,
	},
	{
		garage_id: 53,
		part_id: 134,
		quantity: 57,
	},
	{
		garage_id: 53,
		part_id: 135,
		quantity: 6,
	},
	{
		garage_id: 53,
		part_id: 136,
		quantity: 133,
	},
	{
		garage_id: 53,
		part_id: 137,
		quantity: 204,
	},
	{
		garage_id: 53,
		part_id: 138,
		quantity: 85,
	},
	{
		garage_id: 53,
		part_id: 139,
		quantity: 482,
	},
	{
		garage_id: 53,
		part_id: 140,
		quantity: 105,
	},
	{
		garage_id: 53,
		part_id: 141,
		quantity: 274,
	},
	{
		garage_id: 53,
		part_id: 142,
		quantity: 457,
	},
	{
		garage_id: 53,
		part_id: 143,
		quantity: 264,
	},
	{
		garage_id: 53,
		part_id: 144,
		quantity: 187,
	},
	{
		garage_id: 53,
		part_id: 145,
		quantity: 374,
	},
	{
		garage_id: 53,
		part_id: 146,
		quantity: 14,
	},
	{
		garage_id: 53,
		part_id: 147,
		quantity: 196,
	},
	{
		garage_id: 53,
		part_id: 148,
		quantity: 179,
	},
	{
		garage_id: 53,
		part_id: 149,
		quantity: 445,
	},
	{
		garage_id: 53,
		part_id: 150,
		quantity: 20,
	},
	{
		garage_id: 53,
		part_id: 151,
		quantity: 309,
	},
	{
		garage_id: 53,
		part_id: 152,
		quantity: 149,
	},
	{
		garage_id: 53,
		part_id: 153,
		quantity: 120,
	},
	{
		garage_id: 53,
		part_id: 154,
		quantity: 33,
	},
	{
		garage_id: 53,
		part_id: 155,
		quantity: 15,
	},
	{
		garage_id: 53,
		part_id: 156,
		quantity: 197,
	},
	{
		garage_id: 53,
		part_id: 157,
		quantity: 417,
	},
	{
		garage_id: 53,
		part_id: 158,
		quantity: 371,
	},
	{
		garage_id: 53,
		part_id: 159,
		quantity: 392,
	},
	{
		garage_id: 53,
		part_id: 160,
		quantity: 466,
	},
	{
		garage_id: 53,
		part_id: 161,
		quantity: 16,
	},
	{
		garage_id: 53,
		part_id: 162,
		quantity: 140,
	},
	{
		garage_id: 53,
		part_id: 163,
		quantity: 275,
	},
	{
		garage_id: 53,
		part_id: 164,
		quantity: 206,
	},
	{
		garage_id: 53,
		part_id: 165,
		quantity: 289,
	},
	{
		garage_id: 53,
		part_id: 166,
		quantity: 99,
	},
	{
		garage_id: 53,
		part_id: 167,
		quantity: 482,
	},
	{
		garage_id: 53,
		part_id: 168,
		quantity: 177,
	},
	{
		garage_id: 53,
		part_id: 169,
		quantity: 247,
	},
	{
		garage_id: 53,
		part_id: 170,
		quantity: 92,
	},
	{
		garage_id: 53,
		part_id: 171,
		quantity: 122,
	},
	{
		garage_id: 53,
		part_id: 172,
		quantity: 395,
	},
	{
		garage_id: 53,
		part_id: 173,
		quantity: 157,
	},
	{
		garage_id: 53,
		part_id: 174,
		quantity: 235,
	},
	{
		garage_id: 53,
		part_id: 175,
		quantity: 293,
	},
	{
		garage_id: 53,
		part_id: 176,
		quantity: 336,
	},
	{
		garage_id: 53,
		part_id: 177,
		quantity: 409,
	},
	{
		garage_id: 53,
		part_id: 178,
		quantity: 205,
	},
	{
		garage_id: 53,
		part_id: 179,
		quantity: 147,
	},
	{
		garage_id: 53,
		part_id: 180,
		quantity: 460,
	},
	{
		garage_id: 53,
		part_id: 181,
		quantity: 204,
	},
	{
		garage_id: 53,
		part_id: 182,
		quantity: 474,
	},
	{
		garage_id: 53,
		part_id: 183,
		quantity: 297,
	},
	{
		garage_id: 53,
		part_id: 184,
		quantity: 425,
	},
	{
		garage_id: 53,
		part_id: 185,
		quantity: 370,
	},
	{
		garage_id: 53,
		part_id: 186,
		quantity: 6,
	},
	{
		garage_id: 53,
		part_id: 187,
		quantity: 475,
	},
	{
		garage_id: 53,
		part_id: 188,
		quantity: 2,
	},
	{
		garage_id: 53,
		part_id: 189,
		quantity: 405,
	},
	{
		garage_id: 53,
		part_id: 190,
		quantity: 315,
	},
	{
		garage_id: 53,
		part_id: 191,
		quantity: 134,
	},
	{
		garage_id: 53,
		part_id: 192,
		quantity: 255,
	},
	{
		garage_id: 53,
		part_id: 193,
		quantity: 282,
	},
	{
		garage_id: 53,
		part_id: 194,
		quantity: 166,
	},
	{
		garage_id: 53,
		part_id: 195,
		quantity: 495,
	},
	{
		garage_id: 53,
		part_id: 196,
		quantity: 290,
	},
	{
		garage_id: 53,
		part_id: 197,
		quantity: 293,
	},
	{
		garage_id: 53,
		part_id: 198,
		quantity: 58,
	},
	{
		garage_id: 53,
		part_id: 199,
		quantity: 50,
	},
	{
		garage_id: 53,
		part_id: 200,
		quantity: 111,
	},
	{
		garage_id: 53,
		part_id: 201,
		quantity: 447,
	},
	{
		garage_id: 53,
		part_id: 202,
		quantity: 201,
	},
	{
		garage_id: 53,
		part_id: 203,
		quantity: 297,
	},
	{
		garage_id: 53,
		part_id: 204,
		quantity: 216,
	},
	{
		garage_id: 53,
		part_id: 205,
		quantity: 206,
	},
	{
		garage_id: 53,
		part_id: 206,
		quantity: 70,
	},
	{
		garage_id: 53,
		part_id: 207,
		quantity: 209,
	},
	{
		garage_id: 53,
		part_id: 208,
		quantity: 343,
	},
	{
		garage_id: 53,
		part_id: 209,
		quantity: 5,
	},
	{
		garage_id: 53,
		part_id: 210,
		quantity: 303,
	},
	{
		garage_id: 53,
		part_id: 211,
		quantity: 102,
	},
	{
		garage_id: 53,
		part_id: 212,
		quantity: 330,
	},
	{
		garage_id: 53,
		part_id: 213,
		quantity: 96,
	},
	{
		garage_id: 53,
		part_id: 214,
		quantity: 110,
	},
	{
		garage_id: 53,
		part_id: 215,
		quantity: 54,
	},
	{
		garage_id: 53,
		part_id: 216,
		quantity: 225,
	},
	{
		garage_id: 53,
		part_id: 217,
		quantity: 97,
	},
	{
		garage_id: 53,
		part_id: 218,
		quantity: 206,
	},
	{
		garage_id: 53,
		part_id: 219,
		quantity: 62,
	},
	{
		garage_id: 53,
		part_id: 220,
		quantity: 413,
	},
	{
		garage_id: 53,
		part_id: 221,
		quantity: 388,
	},
	{
		garage_id: 53,
		part_id: 222,
		quantity: 94,
	},
	{
		garage_id: 53,
		part_id: 223,
		quantity: 108,
	},
	{
		garage_id: 53,
		part_id: 224,
		quantity: 248,
	},
	{
		garage_id: 53,
		part_id: 225,
		quantity: 228,
	},
	{
		garage_id: 53,
		part_id: 226,
		quantity: 260,
	},
	{
		garage_id: 53,
		part_id: 227,
		quantity: 252,
	},
	{
		garage_id: 53,
		part_id: 228,
		quantity: 134,
	},
	{
		garage_id: 53,
		part_id: 229,
		quantity: 407,
	},
	{
		garage_id: 53,
		part_id: 230,
		quantity: 214,
	},
	{
		garage_id: 53,
		part_id: 231,
		quantity: 371,
	},
	{
		garage_id: 53,
		part_id: 232,
		quantity: 43,
	},
	{
		garage_id: 53,
		part_id: 233,
		quantity: 323,
	},
	{
		garage_id: 53,
		part_id: 234,
		quantity: 408,
	},
	{
		garage_id: 53,
		part_id: 235,
		quantity: 271,
	},
	{
		garage_id: 53,
		part_id: 236,
		quantity: 480,
	},
	{
		garage_id: 53,
		part_id: 237,
		quantity: 398,
	},
	{
		garage_id: 53,
		part_id: 238,
		quantity: 253,
	},
	{
		garage_id: 53,
		part_id: 239,
		quantity: 71,
	},
	{
		garage_id: 53,
		part_id: 240,
		quantity: 386,
	},
	{
		garage_id: 53,
		part_id: 241,
		quantity: 482,
	},
	{
		garage_id: 53,
		part_id: 242,
		quantity: 85,
	},
	{
		garage_id: 53,
		part_id: 243,
		quantity: 326,
	},
	{
		garage_id: 53,
		part_id: 244,
		quantity: 133,
	},
	{
		garage_id: 53,
		part_id: 245,
		quantity: 292,
	},
	{
		garage_id: 53,
		part_id: 246,
		quantity: 374,
	},
	{
		garage_id: 53,
		part_id: 247,
		quantity: 227,
	},
	{
		garage_id: 53,
		part_id: 248,
		quantity: 299,
	},
	{
		garage_id: 53,
		part_id: 249,
		quantity: 173,
	},
	{
		garage_id: 53,
		part_id: 250,
		quantity: 486,
	},
	{
		garage_id: 53,
		part_id: 251,
		quantity: 284,
	},
	{
		garage_id: 53,
		part_id: 252,
		quantity: 456,
	},
	{
		garage_id: 53,
		part_id: 253,
		quantity: 149,
	},
	{
		garage_id: 53,
		part_id: 254,
		quantity: 93,
	},
	{
		garage_id: 53,
		part_id: 255,
		quantity: 367,
	},
	{
		garage_id: 53,
		part_id: 256,
		quantity: 325,
	},
	{
		garage_id: 53,
		part_id: 257,
		quantity: 403,
	},
	{
		garage_id: 53,
		part_id: 258,
		quantity: 406,
	},
	{
		garage_id: 53,
		part_id: 259,
		quantity: 123,
	},
	{
		garage_id: 53,
		part_id: 260,
		quantity: 267,
	},
	{
		garage_id: 53,
		part_id: 261,
		quantity: 369,
	},
	{
		garage_id: 53,
		part_id: 262,
		quantity: 89,
	},
	{
		garage_id: 53,
		part_id: 263,
		quantity: 103,
	},
	{
		garage_id: 53,
		part_id: 264,
		quantity: 219,
	},
	{
		garage_id: 53,
		part_id: 265,
		quantity: 439,
	},
	{
		garage_id: 53,
		part_id: 266,
		quantity: 173,
	},
	{
		garage_id: 53,
		part_id: 267,
		quantity: 207,
	},
	{
		garage_id: 53,
		part_id: 268,
		quantity: 251,
	},
	{
		garage_id: 53,
		part_id: 269,
		quantity: 343,
	},
	{
		garage_id: 53,
		part_id: 270,
		quantity: 331,
	},
	{
		garage_id: 53,
		part_id: 271,
		quantity: 166,
	},
	{
		garage_id: 53,
		part_id: 272,
		quantity: 440,
	},
	{
		garage_id: 53,
		part_id: 273,
		quantity: 469,
	},
	{
		garage_id: 53,
		part_id: 274,
		quantity: 145,
	},
	{
		garage_id: 53,
		part_id: 275,
		quantity: 120,
	},
	{
		garage_id: 53,
		part_id: 276,
		quantity: 2,
	},
	{
		garage_id: 53,
		part_id: 277,
		quantity: 441,
	},
	{
		garage_id: 53,
		part_id: 278,
		quantity: 8,
	},
	{
		garage_id: 53,
		part_id: 279,
		quantity: 161,
	},
	{
		garage_id: 53,
		part_id: 280,
		quantity: 349,
	},
	{
		garage_id: 53,
		part_id: 281,
		quantity: 100,
	},
	{
		garage_id: 53,
		part_id: 282,
		quantity: 195,
	},
	{
		garage_id: 53,
		part_id: 283,
		quantity: 270,
	},
	{
		garage_id: 53,
		part_id: 284,
		quantity: 183,
	},
	{
		garage_id: 53,
		part_id: 285,
		quantity: 395,
	},
	{
		garage_id: 53,
		part_id: 286,
		quantity: 381,
	},
	{
		garage_id: 53,
		part_id: 287,
		quantity: 131,
	},
	{
		garage_id: 53,
		part_id: 288,
		quantity: 187,
	},
	{
		garage_id: 53,
		part_id: 289,
		quantity: 450,
	},
	{
		garage_id: 53,
		part_id: 290,
		quantity: 48,
	},
	{
		garage_id: 53,
		part_id: 291,
		quantity: 94,
	},
	{
		garage_id: 53,
		part_id: 292,
		quantity: 449,
	},
	{
		garage_id: 53,
		part_id: 293,
		quantity: 344,
	},
	{
		garage_id: 53,
		part_id: 294,
		quantity: 299,
	},
	{
		garage_id: 53,
		part_id: 295,
		quantity: 363,
	},
	{
		garage_id: 53,
		part_id: 296,
		quantity: 449,
	},
	{
		garage_id: 53,
		part_id: 297,
		quantity: 240,
	},
	{
		garage_id: 53,
		part_id: 298,
		quantity: 230,
	},
	{
		garage_id: 53,
		part_id: 299,
		quantity: 66,
	},
	{
		garage_id: 53,
		part_id: 300,
		quantity: 149,
	},
	{
		garage_id: 53,
		part_id: 301,
		quantity: 257,
	},
	{
		garage_id: 53,
		part_id: 302,
		quantity: 114,
	},
	{
		garage_id: 53,
		part_id: 303,
		quantity: 22,
	},
	{
		garage_id: 53,
		part_id: 304,
		quantity: 105,
	},
	{
		garage_id: 53,
		part_id: 305,
		quantity: 450,
	},
	{
		garage_id: 53,
		part_id: 306,
		quantity: 246,
	},
	{
		garage_id: 53,
		part_id: 307,
		quantity: 172,
	},
	{
		garage_id: 53,
		part_id: 308,
		quantity: 430,
	},
	{
		garage_id: 53,
		part_id: 309,
		quantity: 291,
	},
	{
		garage_id: 53,
		part_id: 310,
		quantity: 246,
	},
	{
		garage_id: 53,
		part_id: 311,
		quantity: 160,
	},
	{
		garage_id: 53,
		part_id: 312,
		quantity: 177,
	},
	{
		garage_id: 53,
		part_id: 313,
		quantity: 109,
	},
	{
		garage_id: 53,
		part_id: 314,
		quantity: 321,
	},
	{
		garage_id: 53,
		part_id: 315,
		quantity: 143,
	},
	{
		garage_id: 53,
		part_id: 316,
		quantity: 43,
	},
	{
		garage_id: 53,
		part_id: 317,
		quantity: 74,
	},
	{
		garage_id: 53,
		part_id: 318,
		quantity: 45,
	},
	{
		garage_id: 53,
		part_id: 319,
		quantity: 98,
	},
	{
		garage_id: 53,
		part_id: 320,
		quantity: 89,
	},
	{
		garage_id: 53,
		part_id: 321,
		quantity: 362,
	},
	{
		garage_id: 53,
		part_id: 322,
		quantity: 488,
	},
	{
		garage_id: 53,
		part_id: 323,
		quantity: 306,
	},
	{
		garage_id: 53,
		part_id: 324,
		quantity: 465,
	},
	{
		garage_id: 53,
		part_id: 325,
		quantity: 465,
	},
	{
		garage_id: 53,
		part_id: 326,
		quantity: 430,
	},
	{
		garage_id: 53,
		part_id: 327,
		quantity: 40,
	},
	{
		garage_id: 53,
		part_id: 328,
		quantity: 133,
	},
	{
		garage_id: 53,
		part_id: 329,
		quantity: 443,
	},
	{
		garage_id: 53,
		part_id: 330,
		quantity: 23,
	},
	{
		garage_id: 53,
		part_id: 331,
		quantity: 108,
	},
	{
		garage_id: 53,
		part_id: 332,
		quantity: 230,
	},
	{
		garage_id: 53,
		part_id: 333,
		quantity: 88,
	},
	{
		garage_id: 53,
		part_id: 334,
		quantity: 89,
	},
	{
		garage_id: 53,
		part_id: 335,
		quantity: 301,
	},
	{
		garage_id: 53,
		part_id: 336,
		quantity: 34,
	},
	{
		garage_id: 53,
		part_id: 337,
		quantity: 358,
	},
	{
		garage_id: 53,
		part_id: 338,
		quantity: 139,
	},
	{
		garage_id: 53,
		part_id: 339,
		quantity: 200,
	},
	{
		garage_id: 53,
		part_id: 340,
		quantity: 238,
	},
	{
		garage_id: 53,
		part_id: 341,
		quantity: 38,
	},
	{
		garage_id: 53,
		part_id: 342,
		quantity: 169,
	},
	{
		garage_id: 53,
		part_id: 343,
		quantity: 220,
	},
	{
		garage_id: 53,
		part_id: 344,
		quantity: 174,
	},
	{
		garage_id: 53,
		part_id: 345,
		quantity: 260,
	},
	{
		garage_id: 53,
		part_id: 346,
		quantity: 199,
	},
	{
		garage_id: 53,
		part_id: 347,
		quantity: 498,
	},
	{
		garage_id: 53,
		part_id: 348,
		quantity: 176,
	},
	{
		garage_id: 53,
		part_id: 349,
		quantity: 322,
	},
	{
		garage_id: 53,
		part_id: 350,
		quantity: 415,
	},
	{
		garage_id: 53,
		part_id: 351,
		quantity: 435,
	},
	{
		garage_id: 53,
		part_id: 352,
		quantity: 124,
	},
	{
		garage_id: 53,
		part_id: 353,
		quantity: 143,
	},
	{
		garage_id: 53,
		part_id: 354,
		quantity: 201,
	},
	{
		garage_id: 53,
		part_id: 355,
		quantity: 305,
	},
	{
		garage_id: 53,
		part_id: 356,
		quantity: 456,
	},
	{
		garage_id: 53,
		part_id: 357,
		quantity: 125,
	},
	{
		garage_id: 53,
		part_id: 358,
		quantity: 330,
	},
	{
		garage_id: 53,
		part_id: 359,
		quantity: 75,
	},
	{
		garage_id: 53,
		part_id: 360,
		quantity: 269,
	},
	{
		garage_id: 53,
		part_id: 361,
		quantity: 424,
	},
	{
		garage_id: 53,
		part_id: 362,
		quantity: 316,
	},
	{
		garage_id: 53,
		part_id: 363,
		quantity: 473,
	},
	{
		garage_id: 53,
		part_id: 364,
		quantity: 269,
	},
	{
		garage_id: 53,
		part_id: 365,
		quantity: 404,
	},
	{
		garage_id: 53,
		part_id: 366,
		quantity: 95,
	},
	{
		garage_id: 53,
		part_id: 367,
		quantity: 279,
	},
	{
		garage_id: 53,
		part_id: 368,
		quantity: 62,
	},
	{
		garage_id: 53,
		part_id: 369,
		quantity: 119,
	},
	{
		garage_id: 53,
		part_id: 370,
		quantity: 198,
	},
	{
		garage_id: 53,
		part_id: 371,
		quantity: 258,
	},
	{
		garage_id: 53,
		part_id: 372,
		quantity: 117,
	},
	{
		garage_id: 53,
		part_id: 373,
		quantity: 116,
	},
	{
		garage_id: 53,
		part_id: 374,
		quantity: 345,
	},
	{
		garage_id: 53,
		part_id: 375,
		quantity: 199,
	},
	{
		garage_id: 53,
		part_id: 376,
		quantity: 426,
	},
	{
		garage_id: 53,
		part_id: 377,
		quantity: 161,
	},
	{
		garage_id: 53,
		part_id: 378,
		quantity: 78,
	},
	{
		garage_id: 53,
		part_id: 379,
		quantity: 453,
	},
	{
		garage_id: 53,
		part_id: 380,
		quantity: 263,
	},
	{
		garage_id: 53,
		part_id: 381,
		quantity: 0,
	},
	{
		garage_id: 53,
		part_id: 382,
		quantity: 265,
	},
	{
		garage_id: 53,
		part_id: 383,
		quantity: 129,
	},
	{
		garage_id: 53,
		part_id: 384,
		quantity: 248,
	},
	{
		garage_id: 53,
		part_id: 385,
		quantity: 458,
	},
	{
		garage_id: 53,
		part_id: 386,
		quantity: 442,
	},
	{
		garage_id: 53,
		part_id: 387,
		quantity: 190,
	},
	{
		garage_id: 53,
		part_id: 388,
		quantity: 149,
	},
	{
		garage_id: 53,
		part_id: 389,
		quantity: 476,
	},
	{
		garage_id: 53,
		part_id: 390,
		quantity: 330,
	},
	{
		garage_id: 53,
		part_id: 391,
		quantity: 12,
	},
	{
		garage_id: 53,
		part_id: 392,
		quantity: 45,
	},
	{
		garage_id: 53,
		part_id: 393,
		quantity: 500,
	},
	{
		garage_id: 53,
		part_id: 394,
		quantity: 242,
	},
	{
		garage_id: 53,
		part_id: 395,
		quantity: 91,
	},
	{
		garage_id: 53,
		part_id: 396,
		quantity: 203,
	},
	{
		garage_id: 53,
		part_id: 397,
		quantity: 111,
	},
	{
		garage_id: 53,
		part_id: 398,
		quantity: 231,
	},
	{
		garage_id: 53,
		part_id: 399,
		quantity: 8,
	},
	{
		garage_id: 54,
		part_id: 0,
		quantity: 356,
	},
	{
		garage_id: 54,
		part_id: 1,
		quantity: 87,
	},
	{
		garage_id: 54,
		part_id: 2,
		quantity: 426,
	},
	{
		garage_id: 54,
		part_id: 3,
		quantity: 239,
	},
	{
		garage_id: 54,
		part_id: 4,
		quantity: 373,
	},
	{
		garage_id: 54,
		part_id: 5,
		quantity: 483,
	},
	{
		garage_id: 54,
		part_id: 6,
		quantity: 125,
	},
	{
		garage_id: 54,
		part_id: 7,
		quantity: 446,
	},
	{
		garage_id: 54,
		part_id: 8,
		quantity: 383,
	},
	{
		garage_id: 54,
		part_id: 9,
		quantity: 46,
	},
	{
		garage_id: 54,
		part_id: 10,
		quantity: 437,
	},
	{
		garage_id: 54,
		part_id: 11,
		quantity: 383,
	},
	{
		garage_id: 54,
		part_id: 12,
		quantity: 461,
	},
	{
		garage_id: 54,
		part_id: 13,
		quantity: 354,
	},
	{
		garage_id: 54,
		part_id: 14,
		quantity: 56,
	},
	{
		garage_id: 54,
		part_id: 15,
		quantity: 124,
	},
	{
		garage_id: 54,
		part_id: 16,
		quantity: 225,
	},
	{
		garage_id: 54,
		part_id: 17,
		quantity: 157,
	},
	{
		garage_id: 54,
		part_id: 18,
		quantity: 293,
	},
	{
		garage_id: 54,
		part_id: 19,
		quantity: 170,
	},
	{
		garage_id: 54,
		part_id: 20,
		quantity: 317,
	},
	{
		garage_id: 54,
		part_id: 21,
		quantity: 205,
	},
	{
		garage_id: 54,
		part_id: 22,
		quantity: 204,
	},
	{
		garage_id: 54,
		part_id: 23,
		quantity: 348,
	},
	{
		garage_id: 54,
		part_id: 24,
		quantity: 79,
	},
	{
		garage_id: 54,
		part_id: 25,
		quantity: 401,
	},
	{
		garage_id: 54,
		part_id: 26,
		quantity: 148,
	},
	{
		garage_id: 54,
		part_id: 27,
		quantity: 116,
	},
	{
		garage_id: 54,
		part_id: 28,
		quantity: 72,
	},
	{
		garage_id: 54,
		part_id: 29,
		quantity: 380,
	},
	{
		garage_id: 54,
		part_id: 30,
		quantity: 374,
	},
	{
		garage_id: 54,
		part_id: 31,
		quantity: 359,
	},
	{
		garage_id: 54,
		part_id: 32,
		quantity: 482,
	},
	{
		garage_id: 54,
		part_id: 33,
		quantity: 142,
	},
	{
		garage_id: 54,
		part_id: 34,
		quantity: 156,
	},
	{
		garage_id: 54,
		part_id: 35,
		quantity: 363,
	},
	{
		garage_id: 54,
		part_id: 36,
		quantity: 318,
	},
	{
		garage_id: 54,
		part_id: 37,
		quantity: 291,
	},
	{
		garage_id: 54,
		part_id: 38,
		quantity: 190,
	},
	{
		garage_id: 54,
		part_id: 39,
		quantity: 296,
	},
	{
		garage_id: 54,
		part_id: 40,
		quantity: 203,
	},
	{
		garage_id: 54,
		part_id: 41,
		quantity: 415,
	},
	{
		garage_id: 54,
		part_id: 42,
		quantity: 400,
	},
	{
		garage_id: 54,
		part_id: 43,
		quantity: 245,
	},
	{
		garage_id: 54,
		part_id: 44,
		quantity: 228,
	},
	{
		garage_id: 54,
		part_id: 45,
		quantity: 375,
	},
	{
		garage_id: 54,
		part_id: 46,
		quantity: 328,
	},
	{
		garage_id: 54,
		part_id: 47,
		quantity: 218,
	},
	{
		garage_id: 54,
		part_id: 48,
		quantity: 56,
	},
	{
		garage_id: 54,
		part_id: 49,
		quantity: 346,
	},
	{
		garage_id: 54,
		part_id: 50,
		quantity: 423,
	},
	{
		garage_id: 54,
		part_id: 51,
		quantity: 413,
	},
	{
		garage_id: 54,
		part_id: 52,
		quantity: 223,
	},
	{
		garage_id: 54,
		part_id: 53,
		quantity: 424,
	},
	{
		garage_id: 54,
		part_id: 54,
		quantity: 457,
	},
	{
		garage_id: 54,
		part_id: 55,
		quantity: 47,
	},
	{
		garage_id: 54,
		part_id: 56,
		quantity: 491,
	},
	{
		garage_id: 54,
		part_id: 57,
		quantity: 189,
	},
	{
		garage_id: 54,
		part_id: 58,
		quantity: 27,
	},
	{
		garage_id: 54,
		part_id: 59,
		quantity: 107,
	},
	{
		garage_id: 54,
		part_id: 60,
		quantity: 406,
	},
	{
		garage_id: 54,
		part_id: 61,
		quantity: 59,
	},
	{
		garage_id: 54,
		part_id: 62,
		quantity: 337,
	},
	{
		garage_id: 54,
		part_id: 63,
		quantity: 463,
	},
	{
		garage_id: 54,
		part_id: 64,
		quantity: 191,
	},
	{
		garage_id: 54,
		part_id: 65,
		quantity: 362,
	},
	{
		garage_id: 54,
		part_id: 66,
		quantity: 86,
	},
	{
		garage_id: 54,
		part_id: 67,
		quantity: 371,
	},
	{
		garage_id: 54,
		part_id: 68,
		quantity: 52,
	},
	{
		garage_id: 54,
		part_id: 69,
		quantity: 444,
	},
	{
		garage_id: 54,
		part_id: 70,
		quantity: 296,
	},
	{
		garage_id: 54,
		part_id: 71,
		quantity: 251,
	},
	{
		garage_id: 54,
		part_id: 72,
		quantity: 72,
	},
	{
		garage_id: 54,
		part_id: 73,
		quantity: 129,
	},
	{
		garage_id: 54,
		part_id: 74,
		quantity: 488,
	},
	{
		garage_id: 54,
		part_id: 75,
		quantity: 385,
	},
	{
		garage_id: 54,
		part_id: 76,
		quantity: 191,
	},
	{
		garage_id: 54,
		part_id: 77,
		quantity: 467,
	},
	{
		garage_id: 54,
		part_id: 78,
		quantity: 197,
	},
	{
		garage_id: 54,
		part_id: 79,
		quantity: 236,
	},
	{
		garage_id: 54,
		part_id: 80,
		quantity: 327,
	},
	{
		garage_id: 54,
		part_id: 81,
		quantity: 58,
	},
	{
		garage_id: 54,
		part_id: 82,
		quantity: 384,
	},
	{
		garage_id: 54,
		part_id: 83,
		quantity: 7,
	},
	{
		garage_id: 54,
		part_id: 84,
		quantity: 194,
	},
	{
		garage_id: 54,
		part_id: 85,
		quantity: 350,
	},
	{
		garage_id: 54,
		part_id: 86,
		quantity: 286,
	},
	{
		garage_id: 54,
		part_id: 87,
		quantity: 51,
	},
	{
		garage_id: 54,
		part_id: 88,
		quantity: 183,
	},
	{
		garage_id: 54,
		part_id: 89,
		quantity: 29,
	},
	{
		garage_id: 54,
		part_id: 90,
		quantity: 395,
	},
	{
		garage_id: 54,
		part_id: 91,
		quantity: 260,
	},
	{
		garage_id: 54,
		part_id: 92,
		quantity: 484,
	},
	{
		garage_id: 54,
		part_id: 93,
		quantity: 319,
	},
	{
		garage_id: 54,
		part_id: 94,
		quantity: 71,
	},
	{
		garage_id: 54,
		part_id: 95,
		quantity: 361,
	},
	{
		garage_id: 54,
		part_id: 96,
		quantity: 54,
	},
	{
		garage_id: 54,
		part_id: 97,
		quantity: 236,
	},
	{
		garage_id: 54,
		part_id: 98,
		quantity: 263,
	},
	{
		garage_id: 54,
		part_id: 99,
		quantity: 402,
	},
	{
		garage_id: 54,
		part_id: 100,
		quantity: 317,
	},
	{
		garage_id: 54,
		part_id: 101,
		quantity: 9,
	},
	{
		garage_id: 54,
		part_id: 102,
		quantity: 226,
	},
	{
		garage_id: 54,
		part_id: 103,
		quantity: 21,
	},
	{
		garage_id: 54,
		part_id: 104,
		quantity: 328,
	},
	{
		garage_id: 54,
		part_id: 105,
		quantity: 435,
	},
	{
		garage_id: 54,
		part_id: 106,
		quantity: 465,
	},
	{
		garage_id: 54,
		part_id: 107,
		quantity: 302,
	},
	{
		garage_id: 54,
		part_id: 108,
		quantity: 353,
	},
	{
		garage_id: 54,
		part_id: 109,
		quantity: 421,
	},
	{
		garage_id: 54,
		part_id: 110,
		quantity: 468,
	},
	{
		garage_id: 54,
		part_id: 111,
		quantity: 50,
	},
	{
		garage_id: 54,
		part_id: 112,
		quantity: 196,
	},
	{
		garage_id: 54,
		part_id: 113,
		quantity: 27,
	},
	{
		garage_id: 54,
		part_id: 114,
		quantity: 129,
	},
	{
		garage_id: 54,
		part_id: 115,
		quantity: 354,
	},
	{
		garage_id: 54,
		part_id: 116,
		quantity: 327,
	},
	{
		garage_id: 54,
		part_id: 117,
		quantity: 141,
	},
	{
		garage_id: 54,
		part_id: 118,
		quantity: 460,
	},
	{
		garage_id: 54,
		part_id: 119,
		quantity: 159,
	},
	{
		garage_id: 54,
		part_id: 120,
		quantity: 249,
	},
	{
		garage_id: 54,
		part_id: 121,
		quantity: 128,
	},
	{
		garage_id: 54,
		part_id: 122,
		quantity: 215,
	},
	{
		garage_id: 54,
		part_id: 123,
		quantity: 46,
	},
	{
		garage_id: 54,
		part_id: 124,
		quantity: 403,
	},
	{
		garage_id: 54,
		part_id: 125,
		quantity: 415,
	},
	{
		garage_id: 54,
		part_id: 126,
		quantity: 9,
	},
	{
		garage_id: 54,
		part_id: 127,
		quantity: 282,
	},
	{
		garage_id: 54,
		part_id: 128,
		quantity: 77,
	},
	{
		garage_id: 54,
		part_id: 129,
		quantity: 271,
	},
	{
		garage_id: 54,
		part_id: 130,
		quantity: 320,
	},
	{
		garage_id: 54,
		part_id: 131,
		quantity: 424,
	},
	{
		garage_id: 54,
		part_id: 132,
		quantity: 482,
	},
	{
		garage_id: 54,
		part_id: 133,
		quantity: 3,
	},
	{
		garage_id: 54,
		part_id: 134,
		quantity: 267,
	},
	{
		garage_id: 54,
		part_id: 135,
		quantity: 344,
	},
	{
		garage_id: 54,
		part_id: 136,
		quantity: 427,
	},
	{
		garage_id: 54,
		part_id: 137,
		quantity: 41,
	},
	{
		garage_id: 54,
		part_id: 138,
		quantity: 14,
	},
	{
		garage_id: 54,
		part_id: 139,
		quantity: 70,
	},
	{
		garage_id: 54,
		part_id: 140,
		quantity: 220,
	},
	{
		garage_id: 54,
		part_id: 141,
		quantity: 305,
	},
	{
		garage_id: 54,
		part_id: 142,
		quantity: 86,
	},
	{
		garage_id: 54,
		part_id: 143,
		quantity: 336,
	},
	{
		garage_id: 54,
		part_id: 144,
		quantity: 193,
	},
	{
		garage_id: 54,
		part_id: 145,
		quantity: 369,
	},
	{
		garage_id: 54,
		part_id: 146,
		quantity: 211,
	},
	{
		garage_id: 54,
		part_id: 147,
		quantity: 314,
	},
	{
		garage_id: 54,
		part_id: 148,
		quantity: 288,
	},
	{
		garage_id: 54,
		part_id: 149,
		quantity: 72,
	},
	{
		garage_id: 54,
		part_id: 150,
		quantity: 308,
	},
	{
		garage_id: 54,
		part_id: 151,
		quantity: 176,
	},
	{
		garage_id: 54,
		part_id: 152,
		quantity: 13,
	},
	{
		garage_id: 54,
		part_id: 153,
		quantity: 382,
	},
	{
		garage_id: 54,
		part_id: 154,
		quantity: 404,
	},
	{
		garage_id: 54,
		part_id: 155,
		quantity: 123,
	},
	{
		garage_id: 54,
		part_id: 156,
		quantity: 291,
	},
	{
		garage_id: 54,
		part_id: 157,
		quantity: 347,
	},
	{
		garage_id: 54,
		part_id: 158,
		quantity: 273,
	},
	{
		garage_id: 54,
		part_id: 159,
		quantity: 392,
	},
	{
		garage_id: 54,
		part_id: 160,
		quantity: 74,
	},
	{
		garage_id: 54,
		part_id: 161,
		quantity: 7,
	},
	{
		garage_id: 54,
		part_id: 162,
		quantity: 393,
	},
	{
		garage_id: 54,
		part_id: 163,
		quantity: 446,
	},
	{
		garage_id: 54,
		part_id: 164,
		quantity: 4,
	},
	{
		garage_id: 54,
		part_id: 165,
		quantity: 93,
	},
	{
		garage_id: 54,
		part_id: 166,
		quantity: 268,
	},
	{
		garage_id: 54,
		part_id: 167,
		quantity: 159,
	},
	{
		garage_id: 54,
		part_id: 168,
		quantity: 150,
	},
	{
		garage_id: 54,
		part_id: 169,
		quantity: 121,
	},
	{
		garage_id: 54,
		part_id: 170,
		quantity: 38,
	},
	{
		garage_id: 54,
		part_id: 171,
		quantity: 404,
	},
	{
		garage_id: 54,
		part_id: 172,
		quantity: 350,
	},
	{
		garage_id: 54,
		part_id: 173,
		quantity: 122,
	},
	{
		garage_id: 54,
		part_id: 174,
		quantity: 381,
	},
	{
		garage_id: 54,
		part_id: 175,
		quantity: 66,
	},
	{
		garage_id: 54,
		part_id: 176,
		quantity: 21,
	},
	{
		garage_id: 54,
		part_id: 177,
		quantity: 152,
	},
	{
		garage_id: 54,
		part_id: 178,
		quantity: 454,
	},
	{
		garage_id: 54,
		part_id: 179,
		quantity: 239,
	},
	{
		garage_id: 54,
		part_id: 180,
		quantity: 332,
	},
	{
		garage_id: 54,
		part_id: 181,
		quantity: 49,
	},
	{
		garage_id: 54,
		part_id: 182,
		quantity: 102,
	},
	{
		garage_id: 54,
		part_id: 183,
		quantity: 385,
	},
	{
		garage_id: 54,
		part_id: 184,
		quantity: 475,
	},
	{
		garage_id: 54,
		part_id: 185,
		quantity: 375,
	},
	{
		garage_id: 54,
		part_id: 186,
		quantity: 90,
	},
	{
		garage_id: 54,
		part_id: 187,
		quantity: 143,
	},
	{
		garage_id: 54,
		part_id: 188,
		quantity: 477,
	},
	{
		garage_id: 54,
		part_id: 189,
		quantity: 117,
	},
	{
		garage_id: 54,
		part_id: 190,
		quantity: 307,
	},
	{
		garage_id: 54,
		part_id: 191,
		quantity: 117,
	},
	{
		garage_id: 54,
		part_id: 192,
		quantity: 379,
	},
	{
		garage_id: 54,
		part_id: 193,
		quantity: 203,
	},
	{
		garage_id: 54,
		part_id: 194,
		quantity: 122,
	},
	{
		garage_id: 54,
		part_id: 195,
		quantity: 208,
	},
	{
		garage_id: 54,
		part_id: 196,
		quantity: 154,
	},
	{
		garage_id: 54,
		part_id: 197,
		quantity: 241,
	},
	{
		garage_id: 54,
		part_id: 198,
		quantity: 223,
	},
	{
		garage_id: 54,
		part_id: 199,
		quantity: 401,
	},
	{
		garage_id: 54,
		part_id: 200,
		quantity: 498,
	},
	{
		garage_id: 54,
		part_id: 201,
		quantity: 294,
	},
	{
		garage_id: 54,
		part_id: 202,
		quantity: 72,
	},
	{
		garage_id: 54,
		part_id: 203,
		quantity: 231,
	},
	{
		garage_id: 54,
		part_id: 204,
		quantity: 10,
	},
	{
		garage_id: 54,
		part_id: 205,
		quantity: 499,
	},
	{
		garage_id: 54,
		part_id: 206,
		quantity: 384,
	},
	{
		garage_id: 54,
		part_id: 207,
		quantity: 204,
	},
	{
		garage_id: 54,
		part_id: 208,
		quantity: 25,
	},
	{
		garage_id: 54,
		part_id: 209,
		quantity: 411,
	},
	{
		garage_id: 54,
		part_id: 210,
		quantity: 132,
	},
	{
		garage_id: 54,
		part_id: 211,
		quantity: 379,
	},
	{
		garage_id: 54,
		part_id: 212,
		quantity: 69,
	},
	{
		garage_id: 54,
		part_id: 213,
		quantity: 329,
	},
	{
		garage_id: 54,
		part_id: 214,
		quantity: 178,
	},
	{
		garage_id: 54,
		part_id: 215,
		quantity: 197,
	},
	{
		garage_id: 54,
		part_id: 216,
		quantity: 497,
	},
	{
		garage_id: 54,
		part_id: 217,
		quantity: 33,
	},
	{
		garage_id: 54,
		part_id: 218,
		quantity: 84,
	},
	{
		garage_id: 54,
		part_id: 219,
		quantity: 11,
	},
	{
		garage_id: 54,
		part_id: 220,
		quantity: 139,
	},
	{
		garage_id: 54,
		part_id: 221,
		quantity: 466,
	},
	{
		garage_id: 54,
		part_id: 222,
		quantity: 253,
	},
	{
		garage_id: 54,
		part_id: 223,
		quantity: 46,
	},
	{
		garage_id: 54,
		part_id: 224,
		quantity: 234,
	},
	{
		garage_id: 54,
		part_id: 225,
		quantity: 216,
	},
	{
		garage_id: 54,
		part_id: 226,
		quantity: 487,
	},
	{
		garage_id: 54,
		part_id: 227,
		quantity: 301,
	},
	{
		garage_id: 54,
		part_id: 228,
		quantity: 225,
	},
	{
		garage_id: 54,
		part_id: 229,
		quantity: 413,
	},
	{
		garage_id: 54,
		part_id: 230,
		quantity: 432,
	},
	{
		garage_id: 54,
		part_id: 231,
		quantity: 14,
	},
	{
		garage_id: 54,
		part_id: 232,
		quantity: 103,
	},
	{
		garage_id: 54,
		part_id: 233,
		quantity: 113,
	},
	{
		garage_id: 54,
		part_id: 234,
		quantity: 384,
	},
	{
		garage_id: 54,
		part_id: 235,
		quantity: 384,
	},
	{
		garage_id: 54,
		part_id: 236,
		quantity: 312,
	},
	{
		garage_id: 54,
		part_id: 237,
		quantity: 152,
	},
	{
		garage_id: 54,
		part_id: 238,
		quantity: 476,
	},
	{
		garage_id: 54,
		part_id: 239,
		quantity: 130,
	},
	{
		garage_id: 54,
		part_id: 240,
		quantity: 173,
	},
	{
		garage_id: 54,
		part_id: 241,
		quantity: 153,
	},
	{
		garage_id: 54,
		part_id: 242,
		quantity: 23,
	},
	{
		garage_id: 54,
		part_id: 243,
		quantity: 81,
	},
	{
		garage_id: 54,
		part_id: 244,
		quantity: 449,
	},
	{
		garage_id: 54,
		part_id: 245,
		quantity: 107,
	},
	{
		garage_id: 54,
		part_id: 246,
		quantity: 245,
	},
	{
		garage_id: 54,
		part_id: 247,
		quantity: 80,
	},
	{
		garage_id: 54,
		part_id: 248,
		quantity: 342,
	},
	{
		garage_id: 54,
		part_id: 249,
		quantity: 97,
	},
	{
		garage_id: 54,
		part_id: 250,
		quantity: 405,
	},
	{
		garage_id: 54,
		part_id: 251,
		quantity: 299,
	},
	{
		garage_id: 54,
		part_id: 252,
		quantity: 417,
	},
	{
		garage_id: 54,
		part_id: 253,
		quantity: 232,
	},
	{
		garage_id: 54,
		part_id: 254,
		quantity: 79,
	},
	{
		garage_id: 54,
		part_id: 255,
		quantity: 442,
	},
	{
		garage_id: 54,
		part_id: 256,
		quantity: 406,
	},
	{
		garage_id: 54,
		part_id: 257,
		quantity: 354,
	},
	{
		garage_id: 54,
		part_id: 258,
		quantity: 274,
	},
	{
		garage_id: 54,
		part_id: 259,
		quantity: 65,
	},
	{
		garage_id: 54,
		part_id: 260,
		quantity: 37,
	},
	{
		garage_id: 54,
		part_id: 261,
		quantity: 337,
	},
	{
		garage_id: 54,
		part_id: 262,
		quantity: 276,
	},
	{
		garage_id: 54,
		part_id: 263,
		quantity: 7,
	},
	{
		garage_id: 54,
		part_id: 264,
		quantity: 50,
	},
	{
		garage_id: 54,
		part_id: 265,
		quantity: 309,
	},
	{
		garage_id: 54,
		part_id: 266,
		quantity: 477,
	},
	{
		garage_id: 54,
		part_id: 267,
		quantity: 131,
	},
	{
		garage_id: 54,
		part_id: 268,
		quantity: 79,
	},
	{
		garage_id: 54,
		part_id: 269,
		quantity: 284,
	},
	{
		garage_id: 54,
		part_id: 270,
		quantity: 319,
	},
	{
		garage_id: 54,
		part_id: 271,
		quantity: 413,
	},
	{
		garage_id: 54,
		part_id: 272,
		quantity: 239,
	},
	{
		garage_id: 54,
		part_id: 273,
		quantity: 423,
	},
	{
		garage_id: 54,
		part_id: 274,
		quantity: 273,
	},
	{
		garage_id: 54,
		part_id: 275,
		quantity: 88,
	},
	{
		garage_id: 54,
		part_id: 276,
		quantity: 220,
	},
	{
		garage_id: 54,
		part_id: 277,
		quantity: 486,
	},
	{
		garage_id: 54,
		part_id: 278,
		quantity: 115,
	},
	{
		garage_id: 54,
		part_id: 279,
		quantity: 284,
	},
	{
		garage_id: 54,
		part_id: 280,
		quantity: 58,
	},
	{
		garage_id: 54,
		part_id: 281,
		quantity: 372,
	},
	{
		garage_id: 54,
		part_id: 282,
		quantity: 190,
	},
	{
		garage_id: 54,
		part_id: 283,
		quantity: 420,
	},
	{
		garage_id: 54,
		part_id: 284,
		quantity: 79,
	},
	{
		garage_id: 54,
		part_id: 285,
		quantity: 70,
	},
	{
		garage_id: 54,
		part_id: 286,
		quantity: 411,
	},
	{
		garage_id: 54,
		part_id: 287,
		quantity: 364,
	},
	{
		garage_id: 54,
		part_id: 288,
		quantity: 264,
	},
	{
		garage_id: 54,
		part_id: 289,
		quantity: 410,
	},
	{
		garage_id: 54,
		part_id: 290,
		quantity: 283,
	},
	{
		garage_id: 54,
		part_id: 291,
		quantity: 82,
	},
	{
		garage_id: 54,
		part_id: 292,
		quantity: 425,
	},
	{
		garage_id: 54,
		part_id: 293,
		quantity: 2,
	},
	{
		garage_id: 54,
		part_id: 294,
		quantity: 141,
	},
	{
		garage_id: 54,
		part_id: 295,
		quantity: 331,
	},
	{
		garage_id: 54,
		part_id: 296,
		quantity: 313,
	},
	{
		garage_id: 54,
		part_id: 297,
		quantity: 240,
	},
	{
		garage_id: 54,
		part_id: 298,
		quantity: 246,
	},
	{
		garage_id: 54,
		part_id: 299,
		quantity: 202,
	},
	{
		garage_id: 54,
		part_id: 300,
		quantity: 104,
	},
	{
		garage_id: 54,
		part_id: 301,
		quantity: 256,
	},
	{
		garage_id: 54,
		part_id: 302,
		quantity: 26,
	},
	{
		garage_id: 54,
		part_id: 303,
		quantity: 62,
	},
	{
		garage_id: 54,
		part_id: 304,
		quantity: 115,
	},
	{
		garage_id: 54,
		part_id: 305,
		quantity: 207,
	},
	{
		garage_id: 54,
		part_id: 306,
		quantity: 396,
	},
	{
		garage_id: 54,
		part_id: 307,
		quantity: 427,
	},
	{
		garage_id: 54,
		part_id: 308,
		quantity: 266,
	},
	{
		garage_id: 54,
		part_id: 309,
		quantity: 131,
	},
	{
		garage_id: 54,
		part_id: 310,
		quantity: 381,
	},
	{
		garage_id: 54,
		part_id: 311,
		quantity: 392,
	},
	{
		garage_id: 54,
		part_id: 312,
		quantity: 214,
	},
	{
		garage_id: 54,
		part_id: 313,
		quantity: 191,
	},
	{
		garage_id: 54,
		part_id: 314,
		quantity: 341,
	},
	{
		garage_id: 54,
		part_id: 315,
		quantity: 340,
	},
	{
		garage_id: 54,
		part_id: 316,
		quantity: 363,
	},
	{
		garage_id: 54,
		part_id: 317,
		quantity: 263,
	},
	{
		garage_id: 54,
		part_id: 318,
		quantity: 403,
	},
	{
		garage_id: 54,
		part_id: 319,
		quantity: 380,
	},
	{
		garage_id: 54,
		part_id: 320,
		quantity: 361,
	},
	{
		garage_id: 54,
		part_id: 321,
		quantity: 143,
	},
	{
		garage_id: 54,
		part_id: 322,
		quantity: 58,
	},
	{
		garage_id: 54,
		part_id: 323,
		quantity: 30,
	},
	{
		garage_id: 54,
		part_id: 324,
		quantity: 202,
	},
	{
		garage_id: 54,
		part_id: 325,
		quantity: 318,
	},
	{
		garage_id: 54,
		part_id: 326,
		quantity: 68,
	},
	{
		garage_id: 54,
		part_id: 327,
		quantity: 495,
	},
	{
		garage_id: 54,
		part_id: 328,
		quantity: 87,
	},
	{
		garage_id: 54,
		part_id: 329,
		quantity: 234,
	},
	{
		garage_id: 54,
		part_id: 330,
		quantity: 196,
	},
	{
		garage_id: 54,
		part_id: 331,
		quantity: 468,
	},
	{
		garage_id: 54,
		part_id: 332,
		quantity: 131,
	},
	{
		garage_id: 54,
		part_id: 333,
		quantity: 45,
	},
	{
		garage_id: 54,
		part_id: 334,
		quantity: 295,
	},
	{
		garage_id: 54,
		part_id: 335,
		quantity: 488,
	},
	{
		garage_id: 54,
		part_id: 336,
		quantity: 471,
	},
	{
		garage_id: 54,
		part_id: 337,
		quantity: 378,
	},
	{
		garage_id: 54,
		part_id: 338,
		quantity: 288,
	},
	{
		garage_id: 54,
		part_id: 339,
		quantity: 45,
	},
	{
		garage_id: 54,
		part_id: 340,
		quantity: 480,
	},
	{
		garage_id: 54,
		part_id: 341,
		quantity: 55,
	},
	{
		garage_id: 54,
		part_id: 342,
		quantity: 291,
	},
	{
		garage_id: 54,
		part_id: 343,
		quantity: 441,
	},
	{
		garage_id: 54,
		part_id: 344,
		quantity: 494,
	},
	{
		garage_id: 54,
		part_id: 345,
		quantity: 463,
	},
	{
		garage_id: 54,
		part_id: 346,
		quantity: 30,
	},
	{
		garage_id: 54,
		part_id: 347,
		quantity: 276,
	},
	{
		garage_id: 54,
		part_id: 348,
		quantity: 226,
	},
	{
		garage_id: 54,
		part_id: 349,
		quantity: 267,
	},
	{
		garage_id: 54,
		part_id: 350,
		quantity: 59,
	},
	{
		garage_id: 54,
		part_id: 351,
		quantity: 345,
	},
	{
		garage_id: 54,
		part_id: 352,
		quantity: 114,
	},
	{
		garage_id: 54,
		part_id: 353,
		quantity: 225,
	},
	{
		garage_id: 54,
		part_id: 354,
		quantity: 451,
	},
	{
		garage_id: 54,
		part_id: 355,
		quantity: 347,
	},
	{
		garage_id: 54,
		part_id: 356,
		quantity: 55,
	},
	{
		garage_id: 54,
		part_id: 357,
		quantity: 203,
	},
	{
		garage_id: 54,
		part_id: 358,
		quantity: 7,
	},
	{
		garage_id: 54,
		part_id: 359,
		quantity: 293,
	},
	{
		garage_id: 54,
		part_id: 360,
		quantity: 244,
	},
	{
		garage_id: 54,
		part_id: 361,
		quantity: 373,
	},
	{
		garage_id: 54,
		part_id: 362,
		quantity: 117,
	},
	{
		garage_id: 54,
		part_id: 363,
		quantity: 374,
	},
	{
		garage_id: 54,
		part_id: 364,
		quantity: 20,
	},
	{
		garage_id: 54,
		part_id: 365,
		quantity: 189,
	},
	{
		garage_id: 54,
		part_id: 366,
		quantity: 60,
	},
	{
		garage_id: 54,
		part_id: 367,
		quantity: 37,
	},
	{
		garage_id: 54,
		part_id: 368,
		quantity: 158,
	},
	{
		garage_id: 54,
		part_id: 369,
		quantity: 302,
	},
	{
		garage_id: 54,
		part_id: 370,
		quantity: 195,
	},
	{
		garage_id: 54,
		part_id: 371,
		quantity: 465,
	},
	{
		garage_id: 54,
		part_id: 372,
		quantity: 4,
	},
	{
		garage_id: 54,
		part_id: 373,
		quantity: 435,
	},
	{
		garage_id: 54,
		part_id: 374,
		quantity: 239,
	},
	{
		garage_id: 54,
		part_id: 375,
		quantity: 262,
	},
	{
		garage_id: 54,
		part_id: 376,
		quantity: 104,
	},
	{
		garage_id: 54,
		part_id: 377,
		quantity: 213,
	},
	{
		garage_id: 54,
		part_id: 378,
		quantity: 338,
	},
	{
		garage_id: 54,
		part_id: 379,
		quantity: 34,
	},
	{
		garage_id: 54,
		part_id: 380,
		quantity: 438,
	},
	{
		garage_id: 54,
		part_id: 381,
		quantity: 273,
	},
	{
		garage_id: 54,
		part_id: 382,
		quantity: 161,
	},
	{
		garage_id: 54,
		part_id: 383,
		quantity: 300,
	},
	{
		garage_id: 54,
		part_id: 384,
		quantity: 165,
	},
	{
		garage_id: 54,
		part_id: 385,
		quantity: 289,
	},
	{
		garage_id: 54,
		part_id: 386,
		quantity: 457,
	},
	{
		garage_id: 54,
		part_id: 387,
		quantity: 229,
	},
	{
		garage_id: 54,
		part_id: 388,
		quantity: 366,
	},
	{
		garage_id: 54,
		part_id: 389,
		quantity: 400,
	},
	{
		garage_id: 54,
		part_id: 390,
		quantity: 256,
	},
	{
		garage_id: 54,
		part_id: 391,
		quantity: 389,
	},
	{
		garage_id: 54,
		part_id: 392,
		quantity: 91,
	},
	{
		garage_id: 54,
		part_id: 393,
		quantity: 49,
	},
	{
		garage_id: 54,
		part_id: 394,
		quantity: 131,
	},
	{
		garage_id: 54,
		part_id: 395,
		quantity: 417,
	},
	{
		garage_id: 54,
		part_id: 396,
		quantity: 54,
	},
	{
		garage_id: 54,
		part_id: 397,
		quantity: 75,
	},
	{
		garage_id: 54,
		part_id: 398,
		quantity: 244,
	},
	{
		garage_id: 54,
		part_id: 399,
		quantity: 158,
	},
	{
		garage_id: 55,
		part_id: 0,
		quantity: 308,
	},
	{
		garage_id: 55,
		part_id: 1,
		quantity: 77,
	},
	{
		garage_id: 55,
		part_id: 2,
		quantity: 182,
	},
	{
		garage_id: 55,
		part_id: 3,
		quantity: 362,
	},
	{
		garage_id: 55,
		part_id: 4,
		quantity: 414,
	},
	{
		garage_id: 55,
		part_id: 5,
		quantity: 232,
	},
	{
		garage_id: 55,
		part_id: 6,
		quantity: 62,
	},
	{
		garage_id: 55,
		part_id: 7,
		quantity: 139,
	},
	{
		garage_id: 55,
		part_id: 8,
		quantity: 273,
	},
	{
		garage_id: 55,
		part_id: 9,
		quantity: 400,
	},
	{
		garage_id: 55,
		part_id: 10,
		quantity: 448,
	},
	{
		garage_id: 55,
		part_id: 11,
		quantity: 117,
	},
	{
		garage_id: 55,
		part_id: 12,
		quantity: 450,
	},
	{
		garage_id: 55,
		part_id: 13,
		quantity: 10,
	},
	{
		garage_id: 55,
		part_id: 14,
		quantity: 171,
	},
	{
		garage_id: 55,
		part_id: 15,
		quantity: 491,
	},
	{
		garage_id: 55,
		part_id: 16,
		quantity: 419,
	},
	{
		garage_id: 55,
		part_id: 17,
		quantity: 382,
	},
	{
		garage_id: 55,
		part_id: 18,
		quantity: 479,
	},
	{
		garage_id: 55,
		part_id: 19,
		quantity: 19,
	},
	{
		garage_id: 55,
		part_id: 20,
		quantity: 442,
	},
	{
		garage_id: 55,
		part_id: 21,
		quantity: 367,
	},
	{
		garage_id: 55,
		part_id: 22,
		quantity: 137,
	},
	{
		garage_id: 55,
		part_id: 23,
		quantity: 435,
	},
	{
		garage_id: 55,
		part_id: 24,
		quantity: 453,
	},
	{
		garage_id: 55,
		part_id: 25,
		quantity: 137,
	},
	{
		garage_id: 55,
		part_id: 26,
		quantity: 176,
	},
	{
		garage_id: 55,
		part_id: 27,
		quantity: 387,
	},
	{
		garage_id: 55,
		part_id: 28,
		quantity: 494,
	},
	{
		garage_id: 55,
		part_id: 29,
		quantity: 358,
	},
	{
		garage_id: 55,
		part_id: 30,
		quantity: 210,
	},
	{
		garage_id: 55,
		part_id: 31,
		quantity: 205,
	},
	{
		garage_id: 55,
		part_id: 32,
		quantity: 476,
	},
	{
		garage_id: 55,
		part_id: 33,
		quantity: 250,
	},
	{
		garage_id: 55,
		part_id: 34,
		quantity: 476,
	},
	{
		garage_id: 55,
		part_id: 35,
		quantity: 408,
	},
	{
		garage_id: 55,
		part_id: 36,
		quantity: 460,
	},
	{
		garage_id: 55,
		part_id: 37,
		quantity: 126,
	},
	{
		garage_id: 55,
		part_id: 38,
		quantity: 312,
	},
	{
		garage_id: 55,
		part_id: 39,
		quantity: 159,
	},
	{
		garage_id: 55,
		part_id: 40,
		quantity: 332,
	},
	{
		garage_id: 55,
		part_id: 41,
		quantity: 43,
	},
	{
		garage_id: 55,
		part_id: 42,
		quantity: 456,
	},
	{
		garage_id: 55,
		part_id: 43,
		quantity: 443,
	},
	{
		garage_id: 55,
		part_id: 44,
		quantity: 382,
	},
	{
		garage_id: 55,
		part_id: 45,
		quantity: 312,
	},
	{
		garage_id: 55,
		part_id: 46,
		quantity: 246,
	},
	{
		garage_id: 55,
		part_id: 47,
		quantity: 252,
	},
	{
		garage_id: 55,
		part_id: 48,
		quantity: 7,
	},
	{
		garage_id: 55,
		part_id: 49,
		quantity: 116,
	},
	{
		garage_id: 55,
		part_id: 50,
		quantity: 35,
	},
	{
		garage_id: 55,
		part_id: 51,
		quantity: 67,
	},
	{
		garage_id: 55,
		part_id: 52,
		quantity: 245,
	},
	{
		garage_id: 55,
		part_id: 53,
		quantity: 72,
	},
	{
		garage_id: 55,
		part_id: 54,
		quantity: 242,
	},
	{
		garage_id: 55,
		part_id: 55,
		quantity: 47,
	},
	{
		garage_id: 55,
		part_id: 56,
		quantity: 26,
	},
	{
		garage_id: 55,
		part_id: 57,
		quantity: 430,
	},
	{
		garage_id: 55,
		part_id: 58,
		quantity: 213,
	},
	{
		garage_id: 55,
		part_id: 59,
		quantity: 492,
	},
	{
		garage_id: 55,
		part_id: 60,
		quantity: 248,
	},
	{
		garage_id: 55,
		part_id: 61,
		quantity: 305,
	},
	{
		garage_id: 55,
		part_id: 62,
		quantity: 406,
	},
	{
		garage_id: 55,
		part_id: 63,
		quantity: 176,
	},
	{
		garage_id: 55,
		part_id: 64,
		quantity: 50,
	},
	{
		garage_id: 55,
		part_id: 65,
		quantity: 388,
	},
	{
		garage_id: 55,
		part_id: 66,
		quantity: 240,
	},
	{
		garage_id: 55,
		part_id: 67,
		quantity: 283,
	},
	{
		garage_id: 55,
		part_id: 68,
		quantity: 252,
	},
	{
		garage_id: 55,
		part_id: 69,
		quantity: 63,
	},
	{
		garage_id: 55,
		part_id: 70,
		quantity: 417,
	},
	{
		garage_id: 55,
		part_id: 71,
		quantity: 75,
	},
	{
		garage_id: 55,
		part_id: 72,
		quantity: 471,
	},
	{
		garage_id: 55,
		part_id: 73,
		quantity: 181,
	},
	{
		garage_id: 55,
		part_id: 74,
		quantity: 267,
	},
	{
		garage_id: 55,
		part_id: 75,
		quantity: 466,
	},
	{
		garage_id: 55,
		part_id: 76,
		quantity: 7,
	},
	{
		garage_id: 55,
		part_id: 77,
		quantity: 45,
	},
	{
		garage_id: 55,
		part_id: 78,
		quantity: 17,
	},
	{
		garage_id: 55,
		part_id: 79,
		quantity: 27,
	},
	{
		garage_id: 55,
		part_id: 80,
		quantity: 233,
	},
	{
		garage_id: 55,
		part_id: 81,
		quantity: 291,
	},
	{
		garage_id: 55,
		part_id: 82,
		quantity: 53,
	},
	{
		garage_id: 55,
		part_id: 83,
		quantity: 442,
	},
	{
		garage_id: 55,
		part_id: 84,
		quantity: 152,
	},
	{
		garage_id: 55,
		part_id: 85,
		quantity: 483,
	},
	{
		garage_id: 55,
		part_id: 86,
		quantity: 190,
	},
	{
		garage_id: 55,
		part_id: 87,
		quantity: 198,
	},
	{
		garage_id: 55,
		part_id: 88,
		quantity: 194,
	},
	{
		garage_id: 55,
		part_id: 89,
		quantity: 227,
	},
	{
		garage_id: 55,
		part_id: 90,
		quantity: 423,
	},
	{
		garage_id: 55,
		part_id: 91,
		quantity: 194,
	},
	{
		garage_id: 55,
		part_id: 92,
		quantity: 260,
	},
	{
		garage_id: 55,
		part_id: 93,
		quantity: 140,
	},
	{
		garage_id: 55,
		part_id: 94,
		quantity: 385,
	},
	{
		garage_id: 55,
		part_id: 95,
		quantity: 410,
	},
	{
		garage_id: 55,
		part_id: 96,
		quantity: 185,
	},
	{
		garage_id: 55,
		part_id: 97,
		quantity: 393,
	},
	{
		garage_id: 55,
		part_id: 98,
		quantity: 462,
	},
	{
		garage_id: 55,
		part_id: 99,
		quantity: 121,
	},
	{
		garage_id: 55,
		part_id: 100,
		quantity: 284,
	},
	{
		garage_id: 55,
		part_id: 101,
		quantity: 51,
	},
	{
		garage_id: 55,
		part_id: 102,
		quantity: 53,
	},
	{
		garage_id: 55,
		part_id: 103,
		quantity: 187,
	},
	{
		garage_id: 55,
		part_id: 104,
		quantity: 297,
	},
	{
		garage_id: 55,
		part_id: 105,
		quantity: 181,
	},
	{
		garage_id: 55,
		part_id: 106,
		quantity: 349,
	},
	{
		garage_id: 55,
		part_id: 107,
		quantity: 229,
	},
	{
		garage_id: 55,
		part_id: 108,
		quantity: 393,
	},
	{
		garage_id: 55,
		part_id: 109,
		quantity: 221,
	},
	{
		garage_id: 55,
		part_id: 110,
		quantity: 488,
	},
	{
		garage_id: 55,
		part_id: 111,
		quantity: 460,
	},
	{
		garage_id: 55,
		part_id: 112,
		quantity: 228,
	},
	{
		garage_id: 55,
		part_id: 113,
		quantity: 80,
	},
	{
		garage_id: 55,
		part_id: 114,
		quantity: 50,
	},
	{
		garage_id: 55,
		part_id: 115,
		quantity: 23,
	},
	{
		garage_id: 55,
		part_id: 116,
		quantity: 53,
	},
	{
		garage_id: 55,
		part_id: 117,
		quantity: 52,
	},
	{
		garage_id: 55,
		part_id: 118,
		quantity: 301,
	},
	{
		garage_id: 55,
		part_id: 119,
		quantity: 219,
	},
	{
		garage_id: 55,
		part_id: 120,
		quantity: 75,
	},
	{
		garage_id: 55,
		part_id: 121,
		quantity: 8,
	},
	{
		garage_id: 55,
		part_id: 122,
		quantity: 349,
	},
	{
		garage_id: 55,
		part_id: 123,
		quantity: 499,
	},
	{
		garage_id: 55,
		part_id: 124,
		quantity: 222,
	},
	{
		garage_id: 55,
		part_id: 125,
		quantity: 190,
	},
	{
		garage_id: 55,
		part_id: 126,
		quantity: 6,
	},
	{
		garage_id: 55,
		part_id: 127,
		quantity: 491,
	},
	{
		garage_id: 55,
		part_id: 128,
		quantity: 432,
	},
	{
		garage_id: 55,
		part_id: 129,
		quantity: 307,
	},
	{
		garage_id: 55,
		part_id: 130,
		quantity: 274,
	},
	{
		garage_id: 55,
		part_id: 131,
		quantity: 441,
	},
	{
		garage_id: 55,
		part_id: 132,
		quantity: 241,
	},
	{
		garage_id: 55,
		part_id: 133,
		quantity: 442,
	},
	{
		garage_id: 55,
		part_id: 134,
		quantity: 406,
	},
	{
		garage_id: 55,
		part_id: 135,
		quantity: 48,
	},
	{
		garage_id: 55,
		part_id: 136,
		quantity: 415,
	},
	{
		garage_id: 55,
		part_id: 137,
		quantity: 79,
	},
	{
		garage_id: 55,
		part_id: 138,
		quantity: 342,
	},
	{
		garage_id: 55,
		part_id: 139,
		quantity: 422,
	},
	{
		garage_id: 55,
		part_id: 140,
		quantity: 256,
	},
	{
		garage_id: 55,
		part_id: 141,
		quantity: 256,
	},
	{
		garage_id: 55,
		part_id: 142,
		quantity: 368,
	},
	{
		garage_id: 55,
		part_id: 143,
		quantity: 379,
	},
	{
		garage_id: 55,
		part_id: 144,
		quantity: 134,
	},
	{
		garage_id: 55,
		part_id: 145,
		quantity: 314,
	},
	{
		garage_id: 55,
		part_id: 146,
		quantity: 202,
	},
	{
		garage_id: 55,
		part_id: 147,
		quantity: 132,
	},
	{
		garage_id: 55,
		part_id: 148,
		quantity: 20,
	},
	{
		garage_id: 55,
		part_id: 149,
		quantity: 171,
	},
	{
		garage_id: 55,
		part_id: 150,
		quantity: 135,
	},
	{
		garage_id: 55,
		part_id: 151,
		quantity: 99,
	},
	{
		garage_id: 55,
		part_id: 152,
		quantity: 63,
	},
	{
		garage_id: 55,
		part_id: 153,
		quantity: 180,
	},
	{
		garage_id: 55,
		part_id: 154,
		quantity: 417,
	},
	{
		garage_id: 55,
		part_id: 155,
		quantity: 1,
	},
	{
		garage_id: 55,
		part_id: 156,
		quantity: 198,
	},
	{
		garage_id: 55,
		part_id: 157,
		quantity: 263,
	},
	{
		garage_id: 55,
		part_id: 158,
		quantity: 309,
	},
	{
		garage_id: 55,
		part_id: 159,
		quantity: 150,
	},
	{
		garage_id: 55,
		part_id: 160,
		quantity: 377,
	},
	{
		garage_id: 55,
		part_id: 161,
		quantity: 211,
	},
	{
		garage_id: 55,
		part_id: 162,
		quantity: 151,
	},
	{
		garage_id: 55,
		part_id: 163,
		quantity: 362,
	},
	{
		garage_id: 55,
		part_id: 164,
		quantity: 393,
	},
	{
		garage_id: 55,
		part_id: 165,
		quantity: 300,
	},
	{
		garage_id: 55,
		part_id: 166,
		quantity: 43,
	},
	{
		garage_id: 55,
		part_id: 167,
		quantity: 248,
	},
	{
		garage_id: 55,
		part_id: 168,
		quantity: 252,
	},
	{
		garage_id: 55,
		part_id: 169,
		quantity: 106,
	},
	{
		garage_id: 55,
		part_id: 170,
		quantity: 57,
	},
	{
		garage_id: 55,
		part_id: 171,
		quantity: 342,
	},
	{
		garage_id: 55,
		part_id: 172,
		quantity: 456,
	},
	{
		garage_id: 55,
		part_id: 173,
		quantity: 223,
	},
	{
		garage_id: 55,
		part_id: 174,
		quantity: 125,
	},
	{
		garage_id: 55,
		part_id: 175,
		quantity: 23,
	},
	{
		garage_id: 55,
		part_id: 176,
		quantity: 496,
	},
	{
		garage_id: 55,
		part_id: 177,
		quantity: 209,
	},
	{
		garage_id: 55,
		part_id: 178,
		quantity: 69,
	},
	{
		garage_id: 55,
		part_id: 179,
		quantity: 475,
	},
	{
		garage_id: 55,
		part_id: 180,
		quantity: 228,
	},
	{
		garage_id: 55,
		part_id: 181,
		quantity: 155,
	},
	{
		garage_id: 55,
		part_id: 182,
		quantity: 347,
	},
	{
		garage_id: 55,
		part_id: 183,
		quantity: 478,
	},
	{
		garage_id: 55,
		part_id: 184,
		quantity: 124,
	},
	{
		garage_id: 55,
		part_id: 185,
		quantity: 76,
	},
	{
		garage_id: 55,
		part_id: 186,
		quantity: 424,
	},
	{
		garage_id: 55,
		part_id: 187,
		quantity: 316,
	},
	{
		garage_id: 55,
		part_id: 188,
		quantity: 75,
	},
	{
		garage_id: 55,
		part_id: 189,
		quantity: 380,
	},
	{
		garage_id: 55,
		part_id: 190,
		quantity: 73,
	},
	{
		garage_id: 55,
		part_id: 191,
		quantity: 8,
	},
	{
		garage_id: 55,
		part_id: 192,
		quantity: 35,
	},
	{
		garage_id: 55,
		part_id: 193,
		quantity: 383,
	},
	{
		garage_id: 55,
		part_id: 194,
		quantity: 356,
	},
	{
		garage_id: 55,
		part_id: 195,
		quantity: 355,
	},
	{
		garage_id: 55,
		part_id: 196,
		quantity: 142,
	},
	{
		garage_id: 55,
		part_id: 197,
		quantity: 180,
	},
	{
		garage_id: 55,
		part_id: 198,
		quantity: 321,
	},
	{
		garage_id: 55,
		part_id: 199,
		quantity: 105,
	},
	{
		garage_id: 55,
		part_id: 200,
		quantity: 217,
	},
	{
		garage_id: 55,
		part_id: 201,
		quantity: 427,
	},
	{
		garage_id: 55,
		part_id: 202,
		quantity: 135,
	},
	{
		garage_id: 55,
		part_id: 203,
		quantity: 172,
	},
	{
		garage_id: 55,
		part_id: 204,
		quantity: 432,
	},
	{
		garage_id: 55,
		part_id: 205,
		quantity: 332,
	},
	{
		garage_id: 55,
		part_id: 206,
		quantity: 500,
	},
	{
		garage_id: 55,
		part_id: 207,
		quantity: 35,
	},
	{
		garage_id: 55,
		part_id: 208,
		quantity: 66,
	},
	{
		garage_id: 55,
		part_id: 209,
		quantity: 379,
	},
	{
		garage_id: 55,
		part_id: 210,
		quantity: 388,
	},
	{
		garage_id: 55,
		part_id: 211,
		quantity: 164,
	},
	{
		garage_id: 55,
		part_id: 212,
		quantity: 154,
	},
	{
		garage_id: 55,
		part_id: 213,
		quantity: 104,
	},
	{
		garage_id: 55,
		part_id: 214,
		quantity: 202,
	},
	{
		garage_id: 55,
		part_id: 215,
		quantity: 356,
	},
	{
		garage_id: 55,
		part_id: 216,
		quantity: 482,
	},
	{
		garage_id: 55,
		part_id: 217,
		quantity: 152,
	},
	{
		garage_id: 55,
		part_id: 218,
		quantity: 484,
	},
	{
		garage_id: 55,
		part_id: 219,
		quantity: 428,
	},
	{
		garage_id: 55,
		part_id: 220,
		quantity: 128,
	},
	{
		garage_id: 55,
		part_id: 221,
		quantity: 334,
	},
	{
		garage_id: 55,
		part_id: 222,
		quantity: 143,
	},
	{
		garage_id: 55,
		part_id: 223,
		quantity: 282,
	},
	{
		garage_id: 55,
		part_id: 224,
		quantity: 348,
	},
	{
		garage_id: 55,
		part_id: 225,
		quantity: 310,
	},
	{
		garage_id: 55,
		part_id: 226,
		quantity: 61,
	},
	{
		garage_id: 55,
		part_id: 227,
		quantity: 68,
	},
	{
		garage_id: 55,
		part_id: 228,
		quantity: 149,
	},
	{
		garage_id: 55,
		part_id: 229,
		quantity: 230,
	},
	{
		garage_id: 55,
		part_id: 230,
		quantity: 282,
	},
	{
		garage_id: 55,
		part_id: 231,
		quantity: 180,
	},
	{
		garage_id: 55,
		part_id: 232,
		quantity: 124,
	},
	{
		garage_id: 55,
		part_id: 233,
		quantity: 17,
	},
	{
		garage_id: 55,
		part_id: 234,
		quantity: 477,
	},
	{
		garage_id: 55,
		part_id: 235,
		quantity: 70,
	},
	{
		garage_id: 55,
		part_id: 236,
		quantity: 5,
	},
	{
		garage_id: 55,
		part_id: 237,
		quantity: 295,
	},
	{
		garage_id: 55,
		part_id: 238,
		quantity: 342,
	},
	{
		garage_id: 55,
		part_id: 239,
		quantity: 68,
	},
	{
		garage_id: 55,
		part_id: 240,
		quantity: 200,
	},
	{
		garage_id: 55,
		part_id: 241,
		quantity: 499,
	},
	{
		garage_id: 55,
		part_id: 242,
		quantity: 155,
	},
	{
		garage_id: 55,
		part_id: 243,
		quantity: 131,
	},
	{
		garage_id: 55,
		part_id: 244,
		quantity: 308,
	},
	{
		garage_id: 55,
		part_id: 245,
		quantity: 239,
	},
	{
		garage_id: 55,
		part_id: 246,
		quantity: 198,
	},
	{
		garage_id: 55,
		part_id: 247,
		quantity: 4,
	},
	{
		garage_id: 55,
		part_id: 248,
		quantity: 41,
	},
	{
		garage_id: 55,
		part_id: 249,
		quantity: 52,
	},
	{
		garage_id: 55,
		part_id: 250,
		quantity: 0,
	},
	{
		garage_id: 55,
		part_id: 251,
		quantity: 155,
	},
	{
		garage_id: 55,
		part_id: 252,
		quantity: 388,
	},
	{
		garage_id: 55,
		part_id: 253,
		quantity: 459,
	},
	{
		garage_id: 55,
		part_id: 254,
		quantity: 216,
	},
	{
		garage_id: 55,
		part_id: 255,
		quantity: 390,
	},
	{
		garage_id: 55,
		part_id: 256,
		quantity: 442,
	},
	{
		garage_id: 55,
		part_id: 257,
		quantity: 424,
	},
	{
		garage_id: 55,
		part_id: 258,
		quantity: 131,
	},
	{
		garage_id: 55,
		part_id: 259,
		quantity: 417,
	},
	{
		garage_id: 55,
		part_id: 260,
		quantity: 249,
	},
	{
		garage_id: 55,
		part_id: 261,
		quantity: 405,
	},
	{
		garage_id: 55,
		part_id: 262,
		quantity: 71,
	},
	{
		garage_id: 55,
		part_id: 263,
		quantity: 494,
	},
	{
		garage_id: 55,
		part_id: 264,
		quantity: 374,
	},
	{
		garage_id: 55,
		part_id: 265,
		quantity: 12,
	},
	{
		garage_id: 55,
		part_id: 266,
		quantity: 86,
	},
	{
		garage_id: 55,
		part_id: 267,
		quantity: 5,
	},
	{
		garage_id: 55,
		part_id: 268,
		quantity: 247,
	},
	{
		garage_id: 55,
		part_id: 269,
		quantity: 304,
	},
	{
		garage_id: 55,
		part_id: 270,
		quantity: 164,
	},
	{
		garage_id: 55,
		part_id: 271,
		quantity: 381,
	},
	{
		garage_id: 55,
		part_id: 272,
		quantity: 430,
	},
	{
		garage_id: 55,
		part_id: 273,
		quantity: 174,
	},
	{
		garage_id: 55,
		part_id: 274,
		quantity: 317,
	},
	{
		garage_id: 55,
		part_id: 275,
		quantity: 469,
	},
	{
		garage_id: 55,
		part_id: 276,
		quantity: 51,
	},
	{
		garage_id: 55,
		part_id: 277,
		quantity: 133,
	},
	{
		garage_id: 55,
		part_id: 278,
		quantity: 402,
	},
	{
		garage_id: 55,
		part_id: 279,
		quantity: 291,
	},
	{
		garage_id: 55,
		part_id: 280,
		quantity: 264,
	},
	{
		garage_id: 55,
		part_id: 281,
		quantity: 233,
	},
	{
		garage_id: 55,
		part_id: 282,
		quantity: 111,
	},
	{
		garage_id: 55,
		part_id: 283,
		quantity: 90,
	},
	{
		garage_id: 55,
		part_id: 284,
		quantity: 454,
	},
	{
		garage_id: 55,
		part_id: 285,
		quantity: 401,
	},
	{
		garage_id: 55,
		part_id: 286,
		quantity: 442,
	},
	{
		garage_id: 55,
		part_id: 287,
		quantity: 439,
	},
	{
		garage_id: 55,
		part_id: 288,
		quantity: 93,
	},
	{
		garage_id: 55,
		part_id: 289,
		quantity: 440,
	},
	{
		garage_id: 55,
		part_id: 290,
		quantity: 310,
	},
	{
		garage_id: 55,
		part_id: 291,
		quantity: 393,
	},
	{
		garage_id: 55,
		part_id: 292,
		quantity: 437,
	},
	{
		garage_id: 55,
		part_id: 293,
		quantity: 63,
	},
	{
		garage_id: 55,
		part_id: 294,
		quantity: 359,
	},
	{
		garage_id: 55,
		part_id: 295,
		quantity: 438,
	},
	{
		garage_id: 55,
		part_id: 296,
		quantity: 470,
	},
	{
		garage_id: 55,
		part_id: 297,
		quantity: 402,
	},
	{
		garage_id: 55,
		part_id: 298,
		quantity: 203,
	},
	{
		garage_id: 55,
		part_id: 299,
		quantity: 164,
	},
	{
		garage_id: 55,
		part_id: 300,
		quantity: 77,
	},
	{
		garage_id: 55,
		part_id: 301,
		quantity: 29,
	},
	{
		garage_id: 55,
		part_id: 302,
		quantity: 12,
	},
	{
		garage_id: 55,
		part_id: 303,
		quantity: 376,
	},
	{
		garage_id: 55,
		part_id: 304,
		quantity: 306,
	},
	{
		garage_id: 55,
		part_id: 305,
		quantity: 19,
	},
	{
		garage_id: 55,
		part_id: 306,
		quantity: 132,
	},
	{
		garage_id: 55,
		part_id: 307,
		quantity: 429,
	},
	{
		garage_id: 55,
		part_id: 308,
		quantity: 435,
	},
	{
		garage_id: 55,
		part_id: 309,
		quantity: 382,
	},
	{
		garage_id: 55,
		part_id: 310,
		quantity: 308,
	},
	{
		garage_id: 55,
		part_id: 311,
		quantity: 467,
	},
	{
		garage_id: 55,
		part_id: 312,
		quantity: 407,
	},
	{
		garage_id: 55,
		part_id: 313,
		quantity: 31,
	},
	{
		garage_id: 55,
		part_id: 314,
		quantity: 233,
	},
	{
		garage_id: 55,
		part_id: 315,
		quantity: 476,
	},
	{
		garage_id: 55,
		part_id: 316,
		quantity: 455,
	},
	{
		garage_id: 55,
		part_id: 317,
		quantity: 322,
	},
	{
		garage_id: 55,
		part_id: 318,
		quantity: 424,
	},
	{
		garage_id: 55,
		part_id: 319,
		quantity: 221,
	},
	{
		garage_id: 55,
		part_id: 320,
		quantity: 373,
	},
	{
		garage_id: 55,
		part_id: 321,
		quantity: 219,
	},
	{
		garage_id: 55,
		part_id: 322,
		quantity: 249,
	},
	{
		garage_id: 55,
		part_id: 323,
		quantity: 11,
	},
	{
		garage_id: 55,
		part_id: 324,
		quantity: 129,
	},
	{
		garage_id: 55,
		part_id: 325,
		quantity: 79,
	},
	{
		garage_id: 55,
		part_id: 326,
		quantity: 198,
	},
	{
		garage_id: 55,
		part_id: 327,
		quantity: 140,
	},
	{
		garage_id: 55,
		part_id: 328,
		quantity: 422,
	},
	{
		garage_id: 55,
		part_id: 329,
		quantity: 405,
	},
	{
		garage_id: 55,
		part_id: 330,
		quantity: 244,
	},
	{
		garage_id: 55,
		part_id: 331,
		quantity: 440,
	},
	{
		garage_id: 55,
		part_id: 332,
		quantity: 26,
	},
	{
		garage_id: 55,
		part_id: 333,
		quantity: 191,
	},
	{
		garage_id: 55,
		part_id: 334,
		quantity: 216,
	},
	{
		garage_id: 55,
		part_id: 335,
		quantity: 260,
	},
	{
		garage_id: 55,
		part_id: 336,
		quantity: 3,
	},
	{
		garage_id: 55,
		part_id: 337,
		quantity: 199,
	},
	{
		garage_id: 55,
		part_id: 338,
		quantity: 202,
	},
	{
		garage_id: 55,
		part_id: 339,
		quantity: 143,
	},
	{
		garage_id: 55,
		part_id: 340,
		quantity: 321,
	},
	{
		garage_id: 55,
		part_id: 341,
		quantity: 8,
	},
	{
		garage_id: 55,
		part_id: 342,
		quantity: 20,
	},
	{
		garage_id: 55,
		part_id: 343,
		quantity: 80,
	},
	{
		garage_id: 55,
		part_id: 344,
		quantity: 417,
	},
	{
		garage_id: 55,
		part_id: 345,
		quantity: 403,
	},
	{
		garage_id: 55,
		part_id: 346,
		quantity: 332,
	},
	{
		garage_id: 55,
		part_id: 347,
		quantity: 51,
	},
	{
		garage_id: 55,
		part_id: 348,
		quantity: 470,
	},
	{
		garage_id: 55,
		part_id: 349,
		quantity: 374,
	},
	{
		garage_id: 55,
		part_id: 350,
		quantity: 459,
	},
	{
		garage_id: 55,
		part_id: 351,
		quantity: 400,
	},
	{
		garage_id: 55,
		part_id: 352,
		quantity: 444,
	},
	{
		garage_id: 55,
		part_id: 353,
		quantity: 428,
	},
	{
		garage_id: 55,
		part_id: 354,
		quantity: 350,
	},
	{
		garage_id: 55,
		part_id: 355,
		quantity: 390,
	},
	{
		garage_id: 55,
		part_id: 356,
		quantity: 465,
	},
	{
		garage_id: 55,
		part_id: 357,
		quantity: 388,
	},
	{
		garage_id: 55,
		part_id: 358,
		quantity: 286,
	},
	{
		garage_id: 55,
		part_id: 359,
		quantity: 204,
	},
	{
		garage_id: 55,
		part_id: 360,
		quantity: 392,
	},
	{
		garage_id: 55,
		part_id: 361,
		quantity: 144,
	},
	{
		garage_id: 55,
		part_id: 362,
		quantity: 89,
	},
	{
		garage_id: 55,
		part_id: 363,
		quantity: 108,
	},
	{
		garage_id: 55,
		part_id: 364,
		quantity: 213,
	},
	{
		garage_id: 55,
		part_id: 365,
		quantity: 144,
	},
	{
		garage_id: 55,
		part_id: 366,
		quantity: 364,
	},
	{
		garage_id: 55,
		part_id: 367,
		quantity: 189,
	},
	{
		garage_id: 55,
		part_id: 368,
		quantity: 186,
	},
	{
		garage_id: 55,
		part_id: 369,
		quantity: 198,
	},
	{
		garage_id: 55,
		part_id: 370,
		quantity: 79,
	},
	{
		garage_id: 55,
		part_id: 371,
		quantity: 469,
	},
	{
		garage_id: 55,
		part_id: 372,
		quantity: 182,
	},
	{
		garage_id: 55,
		part_id: 373,
		quantity: 445,
	},
	{
		garage_id: 55,
		part_id: 374,
		quantity: 407,
	},
	{
		garage_id: 55,
		part_id: 375,
		quantity: 132,
	},
	{
		garage_id: 55,
		part_id: 376,
		quantity: 69,
	},
	{
		garage_id: 55,
		part_id: 377,
		quantity: 82,
	},
	{
		garage_id: 55,
		part_id: 378,
		quantity: 202,
	},
	{
		garage_id: 55,
		part_id: 379,
		quantity: 180,
	},
	{
		garage_id: 55,
		part_id: 380,
		quantity: 466,
	},
	{
		garage_id: 55,
		part_id: 381,
		quantity: 369,
	},
	{
		garage_id: 55,
		part_id: 382,
		quantity: 483,
	},
	{
		garage_id: 55,
		part_id: 383,
		quantity: 409,
	},
	{
		garage_id: 55,
		part_id: 384,
		quantity: 12,
	},
	{
		garage_id: 55,
		part_id: 385,
		quantity: 489,
	},
	{
		garage_id: 55,
		part_id: 386,
		quantity: 208,
	},
	{
		garage_id: 55,
		part_id: 387,
		quantity: 113,
	},
	{
		garage_id: 55,
		part_id: 388,
		quantity: 19,
	},
	{
		garage_id: 55,
		part_id: 389,
		quantity: 155,
	},
	{
		garage_id: 55,
		part_id: 390,
		quantity: 129,
	},
	{
		garage_id: 55,
		part_id: 391,
		quantity: 47,
	},
	{
		garage_id: 55,
		part_id: 392,
		quantity: 473,
	},
	{
		garage_id: 55,
		part_id: 393,
		quantity: 206,
	},
	{
		garage_id: 55,
		part_id: 394,
		quantity: 429,
	},
	{
		garage_id: 55,
		part_id: 395,
		quantity: 454,
	},
	{
		garage_id: 55,
		part_id: 396,
		quantity: 238,
	},
	{
		garage_id: 55,
		part_id: 397,
		quantity: 474,
	},
	{
		garage_id: 55,
		part_id: 398,
		quantity: 483,
	},
	{
		garage_id: 55,
		part_id: 399,
		quantity: 333,
	},
	{
		garage_id: 56,
		part_id: 0,
		quantity: 88,
	},
	{
		garage_id: 56,
		part_id: 1,
		quantity: 265,
	},
	{
		garage_id: 56,
		part_id: 2,
		quantity: 149,
	},
	{
		garage_id: 56,
		part_id: 3,
		quantity: 298,
	},
	{
		garage_id: 56,
		part_id: 4,
		quantity: 473,
	},
	{
		garage_id: 56,
		part_id: 5,
		quantity: 157,
	},
	{
		garage_id: 56,
		part_id: 6,
		quantity: 174,
	},
	{
		garage_id: 56,
		part_id: 7,
		quantity: 111,
	},
	{
		garage_id: 56,
		part_id: 8,
		quantity: 403,
	},
	{
		garage_id: 56,
		part_id: 9,
		quantity: 185,
	},
	{
		garage_id: 56,
		part_id: 10,
		quantity: 328,
	},
	{
		garage_id: 56,
		part_id: 11,
		quantity: 69,
	},
	{
		garage_id: 56,
		part_id: 12,
		quantity: 321,
	},
	{
		garage_id: 56,
		part_id: 13,
		quantity: 168,
	},
	{
		garage_id: 56,
		part_id: 14,
		quantity: 208,
	},
	{
		garage_id: 56,
		part_id: 15,
		quantity: 329,
	},
	{
		garage_id: 56,
		part_id: 16,
		quantity: 371,
	},
	{
		garage_id: 56,
		part_id: 17,
		quantity: 341,
	},
	{
		garage_id: 56,
		part_id: 18,
		quantity: 50,
	},
	{
		garage_id: 56,
		part_id: 19,
		quantity: 16,
	},
	{
		garage_id: 56,
		part_id: 20,
		quantity: 413,
	},
	{
		garage_id: 56,
		part_id: 21,
		quantity: 119,
	},
	{
		garage_id: 56,
		part_id: 22,
		quantity: 192,
	},
	{
		garage_id: 56,
		part_id: 23,
		quantity: 306,
	},
	{
		garage_id: 56,
		part_id: 24,
		quantity: 50,
	},
	{
		garage_id: 56,
		part_id: 25,
		quantity: 491,
	},
	{
		garage_id: 56,
		part_id: 26,
		quantity: 362,
	},
	{
		garage_id: 56,
		part_id: 27,
		quantity: 207,
	},
	{
		garage_id: 56,
		part_id: 28,
		quantity: 410,
	},
	{
		garage_id: 56,
		part_id: 29,
		quantity: 344,
	},
	{
		garage_id: 56,
		part_id: 30,
		quantity: 65,
	},
	{
		garage_id: 56,
		part_id: 31,
		quantity: 262,
	},
	{
		garage_id: 56,
		part_id: 32,
		quantity: 399,
	},
	{
		garage_id: 56,
		part_id: 33,
		quantity: 280,
	},
	{
		garage_id: 56,
		part_id: 34,
		quantity: 390,
	},
	{
		garage_id: 56,
		part_id: 35,
		quantity: 370,
	},
	{
		garage_id: 56,
		part_id: 36,
		quantity: 453,
	},
	{
		garage_id: 56,
		part_id: 37,
		quantity: 227,
	},
	{
		garage_id: 56,
		part_id: 38,
		quantity: 176,
	},
	{
		garage_id: 56,
		part_id: 39,
		quantity: 18,
	},
	{
		garage_id: 56,
		part_id: 40,
		quantity: 339,
	},
	{
		garage_id: 56,
		part_id: 41,
		quantity: 270,
	},
	{
		garage_id: 56,
		part_id: 42,
		quantity: 428,
	},
	{
		garage_id: 56,
		part_id: 43,
		quantity: 425,
	},
	{
		garage_id: 56,
		part_id: 44,
		quantity: 220,
	},
	{
		garage_id: 56,
		part_id: 45,
		quantity: 440,
	},
	{
		garage_id: 56,
		part_id: 46,
		quantity: 182,
	},
	{
		garage_id: 56,
		part_id: 47,
		quantity: 347,
	},
	{
		garage_id: 56,
		part_id: 48,
		quantity: 432,
	},
	{
		garage_id: 56,
		part_id: 49,
		quantity: 20,
	},
	{
		garage_id: 56,
		part_id: 50,
		quantity: 211,
	},
	{
		garage_id: 56,
		part_id: 51,
		quantity: 90,
	},
	{
		garage_id: 56,
		part_id: 52,
		quantity: 301,
	},
	{
		garage_id: 56,
		part_id: 53,
		quantity: 260,
	},
	{
		garage_id: 56,
		part_id: 54,
		quantity: 165,
	},
	{
		garage_id: 56,
		part_id: 55,
		quantity: 334,
	},
	{
		garage_id: 56,
		part_id: 56,
		quantity: 420,
	},
	{
		garage_id: 56,
		part_id: 57,
		quantity: 297,
	},
	{
		garage_id: 56,
		part_id: 58,
		quantity: 71,
	},
	{
		garage_id: 56,
		part_id: 59,
		quantity: 107,
	},
	{
		garage_id: 56,
		part_id: 60,
		quantity: 322,
	},
	{
		garage_id: 56,
		part_id: 61,
		quantity: 71,
	},
	{
		garage_id: 56,
		part_id: 62,
		quantity: 490,
	},
	{
		garage_id: 56,
		part_id: 63,
		quantity: 409,
	},
	{
		garage_id: 56,
		part_id: 64,
		quantity: 380,
	},
	{
		garage_id: 56,
		part_id: 65,
		quantity: 408,
	},
	{
		garage_id: 56,
		part_id: 66,
		quantity: 312,
	},
	{
		garage_id: 56,
		part_id: 67,
		quantity: 493,
	},
	{
		garage_id: 56,
		part_id: 68,
		quantity: 250,
	},
	{
		garage_id: 56,
		part_id: 69,
		quantity: 190,
	},
	{
		garage_id: 56,
		part_id: 70,
		quantity: 381,
	},
	{
		garage_id: 56,
		part_id: 71,
		quantity: 376,
	},
	{
		garage_id: 56,
		part_id: 72,
		quantity: 5,
	},
	{
		garage_id: 56,
		part_id: 73,
		quantity: 409,
	},
	{
		garage_id: 56,
		part_id: 74,
		quantity: 69,
	},
	{
		garage_id: 56,
		part_id: 75,
		quantity: 302,
	},
	{
		garage_id: 56,
		part_id: 76,
		quantity: 321,
	},
	{
		garage_id: 56,
		part_id: 77,
		quantity: 427,
	},
	{
		garage_id: 56,
		part_id: 78,
		quantity: 57,
	},
	{
		garage_id: 56,
		part_id: 79,
		quantity: 0,
	},
	{
		garage_id: 56,
		part_id: 80,
		quantity: 201,
	},
	{
		garage_id: 56,
		part_id: 81,
		quantity: 308,
	},
	{
		garage_id: 56,
		part_id: 82,
		quantity: 249,
	},
	{
		garage_id: 56,
		part_id: 83,
		quantity: 135,
	},
	{
		garage_id: 56,
		part_id: 84,
		quantity: 124,
	},
	{
		garage_id: 56,
		part_id: 85,
		quantity: 209,
	},
	{
		garage_id: 56,
		part_id: 86,
		quantity: 481,
	},
	{
		garage_id: 56,
		part_id: 87,
		quantity: 452,
	},
	{
		garage_id: 56,
		part_id: 88,
		quantity: 149,
	},
	{
		garage_id: 56,
		part_id: 89,
		quantity: 295,
	},
	{
		garage_id: 56,
		part_id: 90,
		quantity: 198,
	},
	{
		garage_id: 56,
		part_id: 91,
		quantity: 236,
	},
	{
		garage_id: 56,
		part_id: 92,
		quantity: 251,
	},
	{
		garage_id: 56,
		part_id: 93,
		quantity: 434,
	},
	{
		garage_id: 56,
		part_id: 94,
		quantity: 469,
	},
	{
		garage_id: 56,
		part_id: 95,
		quantity: 105,
	},
	{
		garage_id: 56,
		part_id: 96,
		quantity: 42,
	},
	{
		garage_id: 56,
		part_id: 97,
		quantity: 175,
	},
	{
		garage_id: 56,
		part_id: 98,
		quantity: 487,
	},
	{
		garage_id: 56,
		part_id: 99,
		quantity: 318,
	},
	{
		garage_id: 56,
		part_id: 100,
		quantity: 215,
	},
	{
		garage_id: 56,
		part_id: 101,
		quantity: 279,
	},
	{
		garage_id: 56,
		part_id: 102,
		quantity: 488,
	},
	{
		garage_id: 56,
		part_id: 103,
		quantity: 102,
	},
	{
		garage_id: 56,
		part_id: 104,
		quantity: 77,
	},
	{
		garage_id: 56,
		part_id: 105,
		quantity: 158,
	},
	{
		garage_id: 56,
		part_id: 106,
		quantity: 16,
	},
	{
		garage_id: 56,
		part_id: 107,
		quantity: 442,
	},
	{
		garage_id: 56,
		part_id: 108,
		quantity: 272,
	},
	{
		garage_id: 56,
		part_id: 109,
		quantity: 74,
	},
	{
		garage_id: 56,
		part_id: 110,
		quantity: 37,
	},
	{
		garage_id: 56,
		part_id: 111,
		quantity: 283,
	},
	{
		garage_id: 56,
		part_id: 112,
		quantity: 43,
	},
	{
		garage_id: 56,
		part_id: 113,
		quantity: 98,
	},
	{
		garage_id: 56,
		part_id: 114,
		quantity: 60,
	},
	{
		garage_id: 56,
		part_id: 115,
		quantity: 165,
	},
	{
		garage_id: 56,
		part_id: 116,
		quantity: 45,
	},
	{
		garage_id: 56,
		part_id: 117,
		quantity: 187,
	},
	{
		garage_id: 56,
		part_id: 118,
		quantity: 59,
	},
	{
		garage_id: 56,
		part_id: 119,
		quantity: 394,
	},
	{
		garage_id: 56,
		part_id: 120,
		quantity: 466,
	},
	{
		garage_id: 56,
		part_id: 121,
		quantity: 291,
	},
	{
		garage_id: 56,
		part_id: 122,
		quantity: 140,
	},
	{
		garage_id: 56,
		part_id: 123,
		quantity: 254,
	},
	{
		garage_id: 56,
		part_id: 124,
		quantity: 156,
	},
	{
		garage_id: 56,
		part_id: 125,
		quantity: 372,
	},
	{
		garage_id: 56,
		part_id: 126,
		quantity: 442,
	},
	{
		garage_id: 56,
		part_id: 127,
		quantity: 472,
	},
	{
		garage_id: 56,
		part_id: 128,
		quantity: 70,
	},
	{
		garage_id: 56,
		part_id: 129,
		quantity: 414,
	},
	{
		garage_id: 56,
		part_id: 130,
		quantity: 269,
	},
	{
		garage_id: 56,
		part_id: 131,
		quantity: 265,
	},
	{
		garage_id: 56,
		part_id: 132,
		quantity: 400,
	},
	{
		garage_id: 56,
		part_id: 133,
		quantity: 477,
	},
	{
		garage_id: 56,
		part_id: 134,
		quantity: 61,
	},
	{
		garage_id: 56,
		part_id: 135,
		quantity: 20,
	},
	{
		garage_id: 56,
		part_id: 136,
		quantity: 489,
	},
	{
		garage_id: 56,
		part_id: 137,
		quantity: 359,
	},
	{
		garage_id: 56,
		part_id: 138,
		quantity: 20,
	},
	{
		garage_id: 56,
		part_id: 139,
		quantity: 452,
	},
	{
		garage_id: 56,
		part_id: 140,
		quantity: 439,
	},
	{
		garage_id: 56,
		part_id: 141,
		quantity: 209,
	},
	{
		garage_id: 56,
		part_id: 142,
		quantity: 127,
	},
	{
		garage_id: 56,
		part_id: 143,
		quantity: 296,
	},
	{
		garage_id: 56,
		part_id: 144,
		quantity: 117,
	},
	{
		garage_id: 56,
		part_id: 145,
		quantity: 474,
	},
	{
		garage_id: 56,
		part_id: 146,
		quantity: 304,
	},
	{
		garage_id: 56,
		part_id: 147,
		quantity: 373,
	},
	{
		garage_id: 56,
		part_id: 148,
		quantity: 314,
	},
	{
		garage_id: 56,
		part_id: 149,
		quantity: 475,
	},
	{
		garage_id: 56,
		part_id: 150,
		quantity: 438,
	},
	{
		garage_id: 56,
		part_id: 151,
		quantity: 87,
	},
	{
		garage_id: 56,
		part_id: 152,
		quantity: 24,
	},
	{
		garage_id: 56,
		part_id: 153,
		quantity: 44,
	},
	{
		garage_id: 56,
		part_id: 154,
		quantity: 87,
	},
	{
		garage_id: 56,
		part_id: 155,
		quantity: 487,
	},
	{
		garage_id: 56,
		part_id: 156,
		quantity: 332,
	},
	{
		garage_id: 56,
		part_id: 157,
		quantity: 243,
	},
	{
		garage_id: 56,
		part_id: 158,
		quantity: 245,
	},
	{
		garage_id: 56,
		part_id: 159,
		quantity: 418,
	},
	{
		garage_id: 56,
		part_id: 160,
		quantity: 211,
	},
	{
		garage_id: 56,
		part_id: 161,
		quantity: 390,
	},
	{
		garage_id: 56,
		part_id: 162,
		quantity: 31,
	},
	{
		garage_id: 56,
		part_id: 163,
		quantity: 75,
	},
	{
		garage_id: 56,
		part_id: 164,
		quantity: 107,
	},
	{
		garage_id: 56,
		part_id: 165,
		quantity: 134,
	},
	{
		garage_id: 56,
		part_id: 166,
		quantity: 467,
	},
	{
		garage_id: 56,
		part_id: 167,
		quantity: 386,
	},
	{
		garage_id: 56,
		part_id: 168,
		quantity: 125,
	},
	{
		garage_id: 56,
		part_id: 169,
		quantity: 350,
	},
	{
		garage_id: 56,
		part_id: 170,
		quantity: 45,
	},
	{
		garage_id: 56,
		part_id: 171,
		quantity: 176,
	},
	{
		garage_id: 56,
		part_id: 172,
		quantity: 233,
	},
	{
		garage_id: 56,
		part_id: 173,
		quantity: 173,
	},
	{
		garage_id: 56,
		part_id: 174,
		quantity: 385,
	},
	{
		garage_id: 56,
		part_id: 175,
		quantity: 317,
	},
	{
		garage_id: 56,
		part_id: 176,
		quantity: 140,
	},
	{
		garage_id: 56,
		part_id: 177,
		quantity: 177,
	},
	{
		garage_id: 56,
		part_id: 178,
		quantity: 378,
	},
	{
		garage_id: 56,
		part_id: 179,
		quantity: 34,
	},
	{
		garage_id: 56,
		part_id: 180,
		quantity: 43,
	},
	{
		garage_id: 56,
		part_id: 181,
		quantity: 478,
	},
	{
		garage_id: 56,
		part_id: 182,
		quantity: 116,
	},
	{
		garage_id: 56,
		part_id: 183,
		quantity: 139,
	},
	{
		garage_id: 56,
		part_id: 184,
		quantity: 419,
	},
	{
		garage_id: 56,
		part_id: 185,
		quantity: 107,
	},
	{
		garage_id: 56,
		part_id: 186,
		quantity: 193,
	},
	{
		garage_id: 56,
		part_id: 187,
		quantity: 447,
	},
	{
		garage_id: 56,
		part_id: 188,
		quantity: 26,
	},
	{
		garage_id: 56,
		part_id: 189,
		quantity: 362,
	},
	{
		garage_id: 56,
		part_id: 190,
		quantity: 484,
	},
	{
		garage_id: 56,
		part_id: 191,
		quantity: 368,
	},
	{
		garage_id: 56,
		part_id: 192,
		quantity: 194,
	},
	{
		garage_id: 56,
		part_id: 193,
		quantity: 4,
	},
	{
		garage_id: 56,
		part_id: 194,
		quantity: 249,
	},
	{
		garage_id: 56,
		part_id: 195,
		quantity: 241,
	},
	{
		garage_id: 56,
		part_id: 196,
		quantity: 360,
	},
	{
		garage_id: 56,
		part_id: 197,
		quantity: 327,
	},
	{
		garage_id: 56,
		part_id: 198,
		quantity: 63,
	},
	{
		garage_id: 56,
		part_id: 199,
		quantity: 105,
	},
	{
		garage_id: 56,
		part_id: 200,
		quantity: 35,
	},
	{
		garage_id: 56,
		part_id: 201,
		quantity: 116,
	},
	{
		garage_id: 56,
		part_id: 202,
		quantity: 40,
	},
	{
		garage_id: 56,
		part_id: 203,
		quantity: 129,
	},
	{
		garage_id: 56,
		part_id: 204,
		quantity: 460,
	},
	{
		garage_id: 56,
		part_id: 205,
		quantity: 102,
	},
	{
		garage_id: 56,
		part_id: 206,
		quantity: 242,
	},
	{
		garage_id: 56,
		part_id: 207,
		quantity: 221,
	},
	{
		garage_id: 56,
		part_id: 208,
		quantity: 109,
	},
	{
		garage_id: 56,
		part_id: 209,
		quantity: 469,
	},
	{
		garage_id: 56,
		part_id: 210,
		quantity: 64,
	},
	{
		garage_id: 56,
		part_id: 211,
		quantity: 170,
	},
	{
		garage_id: 56,
		part_id: 212,
		quantity: 422,
	},
	{
		garage_id: 56,
		part_id: 213,
		quantity: 220,
	},
	{
		garage_id: 56,
		part_id: 214,
		quantity: 379,
	},
	{
		garage_id: 56,
		part_id: 215,
		quantity: 114,
	},
	{
		garage_id: 56,
		part_id: 216,
		quantity: 384,
	},
	{
		garage_id: 56,
		part_id: 217,
		quantity: 113,
	},
	{
		garage_id: 56,
		part_id: 218,
		quantity: 416,
	},
	{
		garage_id: 56,
		part_id: 219,
		quantity: 231,
	},
	{
		garage_id: 56,
		part_id: 220,
		quantity: 379,
	},
	{
		garage_id: 56,
		part_id: 221,
		quantity: 72,
	},
	{
		garage_id: 56,
		part_id: 222,
		quantity: 334,
	},
	{
		garage_id: 56,
		part_id: 223,
		quantity: 383,
	},
	{
		garage_id: 56,
		part_id: 224,
		quantity: 419,
	},
	{
		garage_id: 56,
		part_id: 225,
		quantity: 169,
	},
	{
		garage_id: 56,
		part_id: 226,
		quantity: 374,
	},
	{
		garage_id: 56,
		part_id: 227,
		quantity: 464,
	},
	{
		garage_id: 56,
		part_id: 228,
		quantity: 313,
	},
	{
		garage_id: 56,
		part_id: 229,
		quantity: 89,
	},
	{
		garage_id: 56,
		part_id: 230,
		quantity: 419,
	},
	{
		garage_id: 56,
		part_id: 231,
		quantity: 336,
	},
	{
		garage_id: 56,
		part_id: 232,
		quantity: 202,
	},
	{
		garage_id: 56,
		part_id: 233,
		quantity: 218,
	},
	{
		garage_id: 56,
		part_id: 234,
		quantity: 232,
	},
	{
		garage_id: 56,
		part_id: 235,
		quantity: 181,
	},
	{
		garage_id: 56,
		part_id: 236,
		quantity: 455,
	},
	{
		garage_id: 56,
		part_id: 237,
		quantity: 3,
	},
	{
		garage_id: 56,
		part_id: 238,
		quantity: 104,
	},
	{
		garage_id: 56,
		part_id: 239,
		quantity: 450,
	},
	{
		garage_id: 56,
		part_id: 240,
		quantity: 499,
	},
	{
		garage_id: 56,
		part_id: 241,
		quantity: 42,
	},
	{
		garage_id: 56,
		part_id: 242,
		quantity: 488,
	},
	{
		garage_id: 56,
		part_id: 243,
		quantity: 166,
	},
	{
		garage_id: 56,
		part_id: 244,
		quantity: 40,
	},
	{
		garage_id: 56,
		part_id: 245,
		quantity: 5,
	},
	{
		garage_id: 56,
		part_id: 246,
		quantity: 113,
	},
	{
		garage_id: 56,
		part_id: 247,
		quantity: 16,
	},
	{
		garage_id: 56,
		part_id: 248,
		quantity: 434,
	},
	{
		garage_id: 56,
		part_id: 249,
		quantity: 1,
	},
	{
		garage_id: 56,
		part_id: 250,
		quantity: 492,
	},
	{
		garage_id: 56,
		part_id: 251,
		quantity: 381,
	},
	{
		garage_id: 56,
		part_id: 252,
		quantity: 251,
	},
	{
		garage_id: 56,
		part_id: 253,
		quantity: 211,
	},
	{
		garage_id: 56,
		part_id: 254,
		quantity: 226,
	},
	{
		garage_id: 56,
		part_id: 255,
		quantity: 79,
	},
	{
		garage_id: 56,
		part_id: 256,
		quantity: 24,
	},
	{
		garage_id: 56,
		part_id: 257,
		quantity: 406,
	},
	{
		garage_id: 56,
		part_id: 258,
		quantity: 21,
	},
	{
		garage_id: 56,
		part_id: 259,
		quantity: 8,
	},
	{
		garage_id: 56,
		part_id: 260,
		quantity: 432,
	},
	{
		garage_id: 56,
		part_id: 261,
		quantity: 237,
	},
	{
		garage_id: 56,
		part_id: 262,
		quantity: 99,
	},
	{
		garage_id: 56,
		part_id: 263,
		quantity: 72,
	},
	{
		garage_id: 56,
		part_id: 264,
		quantity: 255,
	},
	{
		garage_id: 56,
		part_id: 265,
		quantity: 88,
	},
	{
		garage_id: 56,
		part_id: 266,
		quantity: 121,
	},
	{
		garage_id: 56,
		part_id: 267,
		quantity: 370,
	},
	{
		garage_id: 56,
		part_id: 268,
		quantity: 138,
	},
	{
		garage_id: 56,
		part_id: 269,
		quantity: 64,
	},
	{
		garage_id: 56,
		part_id: 270,
		quantity: 352,
	},
	{
		garage_id: 56,
		part_id: 271,
		quantity: 377,
	},
	{
		garage_id: 56,
		part_id: 272,
		quantity: 12,
	},
	{
		garage_id: 56,
		part_id: 273,
		quantity: 105,
	},
	{
		garage_id: 56,
		part_id: 274,
		quantity: 5,
	},
	{
		garage_id: 56,
		part_id: 275,
		quantity: 499,
	},
	{
		garage_id: 56,
		part_id: 276,
		quantity: 316,
	},
	{
		garage_id: 56,
		part_id: 277,
		quantity: 173,
	},
	{
		garage_id: 56,
		part_id: 278,
		quantity: 326,
	},
	{
		garage_id: 56,
		part_id: 279,
		quantity: 41,
	},
	{
		garage_id: 56,
		part_id: 280,
		quantity: 68,
	},
	{
		garage_id: 56,
		part_id: 281,
		quantity: 144,
	},
	{
		garage_id: 56,
		part_id: 282,
		quantity: 236,
	},
	{
		garage_id: 56,
		part_id: 283,
		quantity: 413,
	},
	{
		garage_id: 56,
		part_id: 284,
		quantity: 130,
	},
	{
		garage_id: 56,
		part_id: 285,
		quantity: 44,
	},
	{
		garage_id: 56,
		part_id: 286,
		quantity: 339,
	},
	{
		garage_id: 56,
		part_id: 287,
		quantity: 94,
	},
	{
		garage_id: 56,
		part_id: 288,
		quantity: 247,
	},
	{
		garage_id: 56,
		part_id: 289,
		quantity: 172,
	},
	{
		garage_id: 56,
		part_id: 290,
		quantity: 359,
	},
	{
		garage_id: 56,
		part_id: 291,
		quantity: 135,
	},
	{
		garage_id: 56,
		part_id: 292,
		quantity: 120,
	},
	{
		garage_id: 56,
		part_id: 293,
		quantity: 204,
	},
	{
		garage_id: 56,
		part_id: 294,
		quantity: 191,
	},
	{
		garage_id: 56,
		part_id: 295,
		quantity: 320,
	},
	{
		garage_id: 56,
		part_id: 296,
		quantity: 141,
	},
	{
		garage_id: 56,
		part_id: 297,
		quantity: 90,
	},
	{
		garage_id: 56,
		part_id: 298,
		quantity: 433,
	},
	{
		garage_id: 56,
		part_id: 299,
		quantity: 449,
	},
	{
		garage_id: 56,
		part_id: 300,
		quantity: 458,
	},
	{
		garage_id: 56,
		part_id: 301,
		quantity: 207,
	},
	{
		garage_id: 56,
		part_id: 302,
		quantity: 301,
	},
	{
		garage_id: 56,
		part_id: 303,
		quantity: 483,
	},
	{
		garage_id: 56,
		part_id: 304,
		quantity: 421,
	},
	{
		garage_id: 56,
		part_id: 305,
		quantity: 315,
	},
	{
		garage_id: 56,
		part_id: 306,
		quantity: 298,
	},
	{
		garage_id: 56,
		part_id: 307,
		quantity: 256,
	},
	{
		garage_id: 56,
		part_id: 308,
		quantity: 128,
	},
	{
		garage_id: 56,
		part_id: 309,
		quantity: 172,
	},
	{
		garage_id: 56,
		part_id: 310,
		quantity: 0,
	},
	{
		garage_id: 56,
		part_id: 311,
		quantity: 457,
	},
	{
		garage_id: 56,
		part_id: 312,
		quantity: 287,
	},
	{
		garage_id: 56,
		part_id: 313,
		quantity: 471,
	},
	{
		garage_id: 56,
		part_id: 314,
		quantity: 424,
	},
	{
		garage_id: 56,
		part_id: 315,
		quantity: 109,
	},
	{
		garage_id: 56,
		part_id: 316,
		quantity: 239,
	},
	{
		garage_id: 56,
		part_id: 317,
		quantity: 127,
	},
	{
		garage_id: 56,
		part_id: 318,
		quantity: 325,
	},
	{
		garage_id: 56,
		part_id: 319,
		quantity: 325,
	},
	{
		garage_id: 56,
		part_id: 320,
		quantity: 338,
	},
	{
		garage_id: 56,
		part_id: 321,
		quantity: 226,
	},
	{
		garage_id: 56,
		part_id: 322,
		quantity: 217,
	},
	{
		garage_id: 56,
		part_id: 323,
		quantity: 317,
	},
	{
		garage_id: 56,
		part_id: 324,
		quantity: 282,
	},
	{
		garage_id: 56,
		part_id: 325,
		quantity: 288,
	},
	{
		garage_id: 56,
		part_id: 326,
		quantity: 409,
	},
	{
		garage_id: 56,
		part_id: 327,
		quantity: 178,
	},
	{
		garage_id: 56,
		part_id: 328,
		quantity: 476,
	},
	{
		garage_id: 56,
		part_id: 329,
		quantity: 218,
	},
	{
		garage_id: 56,
		part_id: 330,
		quantity: 10,
	},
	{
		garage_id: 56,
		part_id: 331,
		quantity: 292,
	},
	{
		garage_id: 56,
		part_id: 332,
		quantity: 450,
	},
	{
		garage_id: 56,
		part_id: 333,
		quantity: 92,
	},
	{
		garage_id: 56,
		part_id: 334,
		quantity: 151,
	},
	{
		garage_id: 56,
		part_id: 335,
		quantity: 255,
	},
	{
		garage_id: 56,
		part_id: 336,
		quantity: 135,
	},
	{
		garage_id: 56,
		part_id: 337,
		quantity: 442,
	},
	{
		garage_id: 56,
		part_id: 338,
		quantity: 20,
	},
	{
		garage_id: 56,
		part_id: 339,
		quantity: 54,
	},
	{
		garage_id: 56,
		part_id: 340,
		quantity: 11,
	},
	{
		garage_id: 56,
		part_id: 341,
		quantity: 28,
	},
	{
		garage_id: 56,
		part_id: 342,
		quantity: 141,
	},
	{
		garage_id: 56,
		part_id: 343,
		quantity: 92,
	},
	{
		garage_id: 56,
		part_id: 344,
		quantity: 85,
	},
	{
		garage_id: 56,
		part_id: 345,
		quantity: 0,
	},
	{
		garage_id: 56,
		part_id: 346,
		quantity: 72,
	},
	{
		garage_id: 56,
		part_id: 347,
		quantity: 61,
	},
	{
		garage_id: 56,
		part_id: 348,
		quantity: 172,
	},
	{
		garage_id: 56,
		part_id: 349,
		quantity: 262,
	},
	{
		garage_id: 56,
		part_id: 350,
		quantity: 190,
	},
	{
		garage_id: 56,
		part_id: 351,
		quantity: 393,
	},
	{
		garage_id: 56,
		part_id: 352,
		quantity: 123,
	},
	{
		garage_id: 56,
		part_id: 353,
		quantity: 210,
	},
	{
		garage_id: 56,
		part_id: 354,
		quantity: 170,
	},
	{
		garage_id: 56,
		part_id: 355,
		quantity: 429,
	},
	{
		garage_id: 56,
		part_id: 356,
		quantity: 453,
	},
	{
		garage_id: 56,
		part_id: 357,
		quantity: 133,
	},
	{
		garage_id: 56,
		part_id: 358,
		quantity: 128,
	},
	{
		garage_id: 56,
		part_id: 359,
		quantity: 107,
	},
	{
		garage_id: 56,
		part_id: 360,
		quantity: 169,
	},
	{
		garage_id: 56,
		part_id: 361,
		quantity: 436,
	},
	{
		garage_id: 56,
		part_id: 362,
		quantity: 292,
	},
	{
		garage_id: 56,
		part_id: 363,
		quantity: 81,
	},
	{
		garage_id: 56,
		part_id: 364,
		quantity: 445,
	},
	{
		garage_id: 56,
		part_id: 365,
		quantity: 313,
	},
	{
		garage_id: 56,
		part_id: 366,
		quantity: 453,
	},
	{
		garage_id: 56,
		part_id: 367,
		quantity: 192,
	},
	{
		garage_id: 56,
		part_id: 368,
		quantity: 183,
	},
	{
		garage_id: 56,
		part_id: 369,
		quantity: 120,
	},
	{
		garage_id: 56,
		part_id: 370,
		quantity: 318,
	},
	{
		garage_id: 56,
		part_id: 371,
		quantity: 224,
	},
	{
		garage_id: 56,
		part_id: 372,
		quantity: 375,
	},
	{
		garage_id: 56,
		part_id: 373,
		quantity: 214,
	},
	{
		garage_id: 56,
		part_id: 374,
		quantity: 492,
	},
	{
		garage_id: 56,
		part_id: 375,
		quantity: 151,
	},
	{
		garage_id: 56,
		part_id: 376,
		quantity: 72,
	},
	{
		garage_id: 56,
		part_id: 377,
		quantity: 71,
	},
	{
		garage_id: 56,
		part_id: 378,
		quantity: 342,
	},
	{
		garage_id: 56,
		part_id: 379,
		quantity: 113,
	},
	{
		garage_id: 56,
		part_id: 380,
		quantity: 443,
	},
	{
		garage_id: 56,
		part_id: 381,
		quantity: 140,
	},
	{
		garage_id: 56,
		part_id: 382,
		quantity: 52,
	},
	{
		garage_id: 56,
		part_id: 383,
		quantity: 344,
	},
	{
		garage_id: 56,
		part_id: 384,
		quantity: 412,
	},
	{
		garage_id: 56,
		part_id: 385,
		quantity: 251,
	},
	{
		garage_id: 56,
		part_id: 386,
		quantity: 132,
	},
	{
		garage_id: 56,
		part_id: 387,
		quantity: 389,
	},
	{
		garage_id: 56,
		part_id: 388,
		quantity: 98,
	},
	{
		garage_id: 56,
		part_id: 389,
		quantity: 289,
	},
	{
		garage_id: 56,
		part_id: 390,
		quantity: 234,
	},
	{
		garage_id: 56,
		part_id: 391,
		quantity: 60,
	},
	{
		garage_id: 56,
		part_id: 392,
		quantity: 60,
	},
	{
		garage_id: 56,
		part_id: 393,
		quantity: 389,
	},
	{
		garage_id: 56,
		part_id: 394,
		quantity: 223,
	},
	{
		garage_id: 56,
		part_id: 395,
		quantity: 348,
	},
	{
		garage_id: 56,
		part_id: 396,
		quantity: 36,
	},
	{
		garage_id: 56,
		part_id: 397,
		quantity: 53,
	},
	{
		garage_id: 56,
		part_id: 398,
		quantity: 370,
	},
	{
		garage_id: 56,
		part_id: 399,
		quantity: 382,
	},
	{
		garage_id: 57,
		part_id: 0,
		quantity: 381,
	},
	{
		garage_id: 57,
		part_id: 1,
		quantity: 152,
	},
	{
		garage_id: 57,
		part_id: 2,
		quantity: 101,
	},
	{
		garage_id: 57,
		part_id: 3,
		quantity: 104,
	},
	{
		garage_id: 57,
		part_id: 4,
		quantity: 363,
	},
	{
		garage_id: 57,
		part_id: 5,
		quantity: 304,
	},
	{
		garage_id: 57,
		part_id: 6,
		quantity: 124,
	},
	{
		garage_id: 57,
		part_id: 7,
		quantity: 334,
	},
	{
		garage_id: 57,
		part_id: 8,
		quantity: 32,
	},
	{
		garage_id: 57,
		part_id: 9,
		quantity: 421,
	},
	{
		garage_id: 57,
		part_id: 10,
		quantity: 130,
	},
	{
		garage_id: 57,
		part_id: 11,
		quantity: 401,
	},
	{
		garage_id: 57,
		part_id: 12,
		quantity: 342,
	},
	{
		garage_id: 57,
		part_id: 13,
		quantity: 436,
	},
	{
		garage_id: 57,
		part_id: 14,
		quantity: 110,
	},
	{
		garage_id: 57,
		part_id: 15,
		quantity: 145,
	},
	{
		garage_id: 57,
		part_id: 16,
		quantity: 448,
	},
	{
		garage_id: 57,
		part_id: 17,
		quantity: 109,
	},
	{
		garage_id: 57,
		part_id: 18,
		quantity: 299,
	},
	{
		garage_id: 57,
		part_id: 19,
		quantity: 415,
	},
	{
		garage_id: 57,
		part_id: 20,
		quantity: 351,
	},
	{
		garage_id: 57,
		part_id: 21,
		quantity: 488,
	},
	{
		garage_id: 57,
		part_id: 22,
		quantity: 261,
	},
	{
		garage_id: 57,
		part_id: 23,
		quantity: 110,
	},
	{
		garage_id: 57,
		part_id: 24,
		quantity: 320,
	},
	{
		garage_id: 57,
		part_id: 25,
		quantity: 261,
	},
	{
		garage_id: 57,
		part_id: 26,
		quantity: 280,
	},
	{
		garage_id: 57,
		part_id: 27,
		quantity: 165,
	},
	{
		garage_id: 57,
		part_id: 28,
		quantity: 100,
	},
	{
		garage_id: 57,
		part_id: 29,
		quantity: 388,
	},
	{
		garage_id: 57,
		part_id: 30,
		quantity: 151,
	},
	{
		garage_id: 57,
		part_id: 31,
		quantity: 77,
	},
	{
		garage_id: 57,
		part_id: 32,
		quantity: 214,
	},
	{
		garage_id: 57,
		part_id: 33,
		quantity: 97,
	},
	{
		garage_id: 57,
		part_id: 34,
		quantity: 17,
	},
	{
		garage_id: 57,
		part_id: 35,
		quantity: 47,
	},
	{
		garage_id: 57,
		part_id: 36,
		quantity: 184,
	},
	{
		garage_id: 57,
		part_id: 37,
		quantity: 227,
	},
	{
		garage_id: 57,
		part_id: 38,
		quantity: 428,
	},
	{
		garage_id: 57,
		part_id: 39,
		quantity: 238,
	},
	{
		garage_id: 57,
		part_id: 40,
		quantity: 297,
	},
	{
		garage_id: 57,
		part_id: 41,
		quantity: 138,
	},
	{
		garage_id: 57,
		part_id: 42,
		quantity: 15,
	},
	{
		garage_id: 57,
		part_id: 43,
		quantity: 389,
	},
	{
		garage_id: 57,
		part_id: 44,
		quantity: 293,
	},
	{
		garage_id: 57,
		part_id: 45,
		quantity: 375,
	},
	{
		garage_id: 57,
		part_id: 46,
		quantity: 5,
	},
	{
		garage_id: 57,
		part_id: 47,
		quantity: 166,
	},
	{
		garage_id: 57,
		part_id: 48,
		quantity: 326,
	},
	{
		garage_id: 57,
		part_id: 49,
		quantity: 288,
	},
	{
		garage_id: 57,
		part_id: 50,
		quantity: 488,
	},
	{
		garage_id: 57,
		part_id: 51,
		quantity: 383,
	},
	{
		garage_id: 57,
		part_id: 52,
		quantity: 430,
	},
	{
		garage_id: 57,
		part_id: 53,
		quantity: 209,
	},
	{
		garage_id: 57,
		part_id: 54,
		quantity: 305,
	},
	{
		garage_id: 57,
		part_id: 55,
		quantity: 8,
	},
	{
		garage_id: 57,
		part_id: 56,
		quantity: 112,
	},
	{
		garage_id: 57,
		part_id: 57,
		quantity: 405,
	},
	{
		garage_id: 57,
		part_id: 58,
		quantity: 496,
	},
	{
		garage_id: 57,
		part_id: 59,
		quantity: 329,
	},
	{
		garage_id: 57,
		part_id: 60,
		quantity: 235,
	},
	{
		garage_id: 57,
		part_id: 61,
		quantity: 34,
	},
	{
		garage_id: 57,
		part_id: 62,
		quantity: 108,
	},
	{
		garage_id: 57,
		part_id: 63,
		quantity: 375,
	},
	{
		garage_id: 57,
		part_id: 64,
		quantity: 296,
	},
	{
		garage_id: 57,
		part_id: 65,
		quantity: 405,
	},
	{
		garage_id: 57,
		part_id: 66,
		quantity: 1,
	},
	{
		garage_id: 57,
		part_id: 67,
		quantity: 241,
	},
	{
		garage_id: 57,
		part_id: 68,
		quantity: 490,
	},
	{
		garage_id: 57,
		part_id: 69,
		quantity: 192,
	},
	{
		garage_id: 57,
		part_id: 70,
		quantity: 257,
	},
	{
		garage_id: 57,
		part_id: 71,
		quantity: 127,
	},
	{
		garage_id: 57,
		part_id: 72,
		quantity: 279,
	},
	{
		garage_id: 57,
		part_id: 73,
		quantity: 156,
	},
	{
		garage_id: 57,
		part_id: 74,
		quantity: 50,
	},
	{
		garage_id: 57,
		part_id: 75,
		quantity: 69,
	},
	{
		garage_id: 57,
		part_id: 76,
		quantity: 354,
	},
	{
		garage_id: 57,
		part_id: 77,
		quantity: 99,
	},
	{
		garage_id: 57,
		part_id: 78,
		quantity: 207,
	},
	{
		garage_id: 57,
		part_id: 79,
		quantity: 56,
	},
	{
		garage_id: 57,
		part_id: 80,
		quantity: 334,
	},
	{
		garage_id: 57,
		part_id: 81,
		quantity: 40,
	},
	{
		garage_id: 57,
		part_id: 82,
		quantity: 58,
	},
	{
		garage_id: 57,
		part_id: 83,
		quantity: 418,
	},
	{
		garage_id: 57,
		part_id: 84,
		quantity: 107,
	},
	{
		garage_id: 57,
		part_id: 85,
		quantity: 219,
	},
	{
		garage_id: 57,
		part_id: 86,
		quantity: 313,
	},
	{
		garage_id: 57,
		part_id: 87,
		quantity: 331,
	},
	{
		garage_id: 57,
		part_id: 88,
		quantity: 245,
	},
	{
		garage_id: 57,
		part_id: 89,
		quantity: 293,
	},
	{
		garage_id: 57,
		part_id: 90,
		quantity: 106,
	},
	{
		garage_id: 57,
		part_id: 91,
		quantity: 287,
	},
	{
		garage_id: 57,
		part_id: 92,
		quantity: 474,
	},
	{
		garage_id: 57,
		part_id: 93,
		quantity: 448,
	},
	{
		garage_id: 57,
		part_id: 94,
		quantity: 13,
	},
	{
		garage_id: 57,
		part_id: 95,
		quantity: 422,
	},
	{
		garage_id: 57,
		part_id: 96,
		quantity: 201,
	},
	{
		garage_id: 57,
		part_id: 97,
		quantity: 376,
	},
	{
		garage_id: 57,
		part_id: 98,
		quantity: 37,
	},
	{
		garage_id: 57,
		part_id: 99,
		quantity: 428,
	},
	{
		garage_id: 57,
		part_id: 100,
		quantity: 312,
	},
	{
		garage_id: 57,
		part_id: 101,
		quantity: 399,
	},
	{
		garage_id: 57,
		part_id: 102,
		quantity: 23,
	},
	{
		garage_id: 57,
		part_id: 103,
		quantity: 48,
	},
	{
		garage_id: 57,
		part_id: 104,
		quantity: 381,
	},
	{
		garage_id: 57,
		part_id: 105,
		quantity: 188,
	},
	{
		garage_id: 57,
		part_id: 106,
		quantity: 399,
	},
	{
		garage_id: 57,
		part_id: 107,
		quantity: 177,
	},
	{
		garage_id: 57,
		part_id: 108,
		quantity: 459,
	},
	{
		garage_id: 57,
		part_id: 109,
		quantity: 68,
	},
	{
		garage_id: 57,
		part_id: 110,
		quantity: 171,
	},
	{
		garage_id: 57,
		part_id: 111,
		quantity: 213,
	},
	{
		garage_id: 57,
		part_id: 112,
		quantity: 156,
	},
	{
		garage_id: 57,
		part_id: 113,
		quantity: 307,
	},
	{
		garage_id: 57,
		part_id: 114,
		quantity: 279,
	},
	{
		garage_id: 57,
		part_id: 115,
		quantity: 383,
	},
	{
		garage_id: 57,
		part_id: 116,
		quantity: 441,
	},
	{
		garage_id: 57,
		part_id: 117,
		quantity: 241,
	},
	{
		garage_id: 57,
		part_id: 118,
		quantity: 222,
	},
	{
		garage_id: 57,
		part_id: 119,
		quantity: 244,
	},
	{
		garage_id: 57,
		part_id: 120,
		quantity: 153,
	},
	{
		garage_id: 57,
		part_id: 121,
		quantity: 386,
	},
	{
		garage_id: 57,
		part_id: 122,
		quantity: 464,
	},
	{
		garage_id: 57,
		part_id: 123,
		quantity: 442,
	},
	{
		garage_id: 57,
		part_id: 124,
		quantity: 263,
	},
	{
		garage_id: 57,
		part_id: 125,
		quantity: 263,
	},
	{
		garage_id: 57,
		part_id: 126,
		quantity: 182,
	},
	{
		garage_id: 57,
		part_id: 127,
		quantity: 136,
	},
	{
		garage_id: 57,
		part_id: 128,
		quantity: 129,
	},
	{
		garage_id: 57,
		part_id: 129,
		quantity: 76,
	},
	{
		garage_id: 57,
		part_id: 130,
		quantity: 417,
	},
	{
		garage_id: 57,
		part_id: 131,
		quantity: 38,
	},
	{
		garage_id: 57,
		part_id: 132,
		quantity: 102,
	},
	{
		garage_id: 57,
		part_id: 133,
		quantity: 35,
	},
	{
		garage_id: 57,
		part_id: 134,
		quantity: 233,
	},
	{
		garage_id: 57,
		part_id: 135,
		quantity: 26,
	},
	{
		garage_id: 57,
		part_id: 136,
		quantity: 187,
	},
	{
		garage_id: 57,
		part_id: 137,
		quantity: 456,
	},
	{
		garage_id: 57,
		part_id: 138,
		quantity: 347,
	},
	{
		garage_id: 57,
		part_id: 139,
		quantity: 267,
	},
	{
		garage_id: 57,
		part_id: 140,
		quantity: 298,
	},
	{
		garage_id: 57,
		part_id: 141,
		quantity: 180,
	},
	{
		garage_id: 57,
		part_id: 142,
		quantity: 113,
	},
	{
		garage_id: 57,
		part_id: 143,
		quantity: 197,
	},
	{
		garage_id: 57,
		part_id: 144,
		quantity: 221,
	},
	{
		garage_id: 57,
		part_id: 145,
		quantity: 385,
	},
	{
		garage_id: 57,
		part_id: 146,
		quantity: 154,
	},
	{
		garage_id: 57,
		part_id: 147,
		quantity: 183,
	},
	{
		garage_id: 57,
		part_id: 148,
		quantity: 493,
	},
	{
		garage_id: 57,
		part_id: 149,
		quantity: 499,
	},
	{
		garage_id: 57,
		part_id: 150,
		quantity: 249,
	},
	{
		garage_id: 57,
		part_id: 151,
		quantity: 229,
	},
	{
		garage_id: 57,
		part_id: 152,
		quantity: 196,
	},
	{
		garage_id: 57,
		part_id: 153,
		quantity: 382,
	},
	{
		garage_id: 57,
		part_id: 154,
		quantity: 79,
	},
	{
		garage_id: 57,
		part_id: 155,
		quantity: 119,
	},
	{
		garage_id: 57,
		part_id: 156,
		quantity: 452,
	},
	{
		garage_id: 57,
		part_id: 157,
		quantity: 29,
	},
	{
		garage_id: 57,
		part_id: 158,
		quantity: 190,
	},
	{
		garage_id: 57,
		part_id: 159,
		quantity: 448,
	},
	{
		garage_id: 57,
		part_id: 160,
		quantity: 28,
	},
	{
		garage_id: 57,
		part_id: 161,
		quantity: 96,
	},
	{
		garage_id: 57,
		part_id: 162,
		quantity: 30,
	},
	{
		garage_id: 57,
		part_id: 163,
		quantity: 170,
	},
	{
		garage_id: 57,
		part_id: 164,
		quantity: 416,
	},
	{
		garage_id: 57,
		part_id: 165,
		quantity: 57,
	},
	{
		garage_id: 57,
		part_id: 166,
		quantity: 27,
	},
	{
		garage_id: 57,
		part_id: 167,
		quantity: 29,
	},
	{
		garage_id: 57,
		part_id: 168,
		quantity: 382,
	},
	{
		garage_id: 57,
		part_id: 169,
		quantity: 21,
	},
	{
		garage_id: 57,
		part_id: 170,
		quantity: 39,
	},
	{
		garage_id: 57,
		part_id: 171,
		quantity: 6,
	},
	{
		garage_id: 57,
		part_id: 172,
		quantity: 371,
	},
	{
		garage_id: 57,
		part_id: 173,
		quantity: 416,
	},
	{
		garage_id: 57,
		part_id: 174,
		quantity: 471,
	},
	{
		garage_id: 57,
		part_id: 175,
		quantity: 87,
	},
	{
		garage_id: 57,
		part_id: 176,
		quantity: 163,
	},
	{
		garage_id: 57,
		part_id: 177,
		quantity: 105,
	},
	{
		garage_id: 57,
		part_id: 178,
		quantity: 90,
	},
	{
		garage_id: 57,
		part_id: 179,
		quantity: 182,
	},
	{
		garage_id: 57,
		part_id: 180,
		quantity: 274,
	},
	{
		garage_id: 57,
		part_id: 181,
		quantity: 50,
	},
	{
		garage_id: 57,
		part_id: 182,
		quantity: 120,
	},
	{
		garage_id: 57,
		part_id: 183,
		quantity: 312,
	},
	{
		garage_id: 57,
		part_id: 184,
		quantity: 212,
	},
	{
		garage_id: 57,
		part_id: 185,
		quantity: 428,
	},
	{
		garage_id: 57,
		part_id: 186,
		quantity: 99,
	},
	{
		garage_id: 57,
		part_id: 187,
		quantity: 475,
	},
	{
		garage_id: 57,
		part_id: 188,
		quantity: 349,
	},
	{
		garage_id: 57,
		part_id: 189,
		quantity: 115,
	},
	{
		garage_id: 57,
		part_id: 190,
		quantity: 51,
	},
	{
		garage_id: 57,
		part_id: 191,
		quantity: 240,
	},
	{
		garage_id: 57,
		part_id: 192,
		quantity: 385,
	},
	{
		garage_id: 57,
		part_id: 193,
		quantity: 252,
	},
	{
		garage_id: 57,
		part_id: 194,
		quantity: 385,
	},
	{
		garage_id: 57,
		part_id: 195,
		quantity: 214,
	},
	{
		garage_id: 57,
		part_id: 196,
		quantity: 112,
	},
	{
		garage_id: 57,
		part_id: 197,
		quantity: 123,
	},
	{
		garage_id: 57,
		part_id: 198,
		quantity: 46,
	},
	{
		garage_id: 57,
		part_id: 199,
		quantity: 30,
	},
	{
		garage_id: 57,
		part_id: 200,
		quantity: 320,
	},
	{
		garage_id: 57,
		part_id: 201,
		quantity: 235,
	},
	{
		garage_id: 57,
		part_id: 202,
		quantity: 383,
	},
	{
		garage_id: 57,
		part_id: 203,
		quantity: 452,
	},
	{
		garage_id: 57,
		part_id: 204,
		quantity: 62,
	},
	{
		garage_id: 57,
		part_id: 205,
		quantity: 342,
	},
	{
		garage_id: 57,
		part_id: 206,
		quantity: 148,
	},
	{
		garage_id: 57,
		part_id: 207,
		quantity: 202,
	},
	{
		garage_id: 57,
		part_id: 208,
		quantity: 261,
	},
	{
		garage_id: 57,
		part_id: 209,
		quantity: 100,
	},
	{
		garage_id: 57,
		part_id: 210,
		quantity: 377,
	},
	{
		garage_id: 57,
		part_id: 211,
		quantity: 257,
	},
	{
		garage_id: 57,
		part_id: 212,
		quantity: 354,
	},
	{
		garage_id: 57,
		part_id: 213,
		quantity: 280,
	},
	{
		garage_id: 57,
		part_id: 214,
		quantity: 47,
	},
	{
		garage_id: 57,
		part_id: 215,
		quantity: 294,
	},
	{
		garage_id: 57,
		part_id: 216,
		quantity: 4,
	},
	{
		garage_id: 57,
		part_id: 217,
		quantity: 68,
	},
	{
		garage_id: 57,
		part_id: 218,
		quantity: 433,
	},
	{
		garage_id: 57,
		part_id: 219,
		quantity: 183,
	},
	{
		garage_id: 57,
		part_id: 220,
		quantity: 147,
	},
	{
		garage_id: 57,
		part_id: 221,
		quantity: 146,
	},
	{
		garage_id: 57,
		part_id: 222,
		quantity: 432,
	},
	{
		garage_id: 57,
		part_id: 223,
		quantity: 393,
	},
	{
		garage_id: 57,
		part_id: 224,
		quantity: 371,
	},
	{
		garage_id: 57,
		part_id: 225,
		quantity: 280,
	},
	{
		garage_id: 57,
		part_id: 226,
		quantity: 110,
	},
	{
		garage_id: 57,
		part_id: 227,
		quantity: 25,
	},
	{
		garage_id: 57,
		part_id: 228,
		quantity: 196,
	},
	{
		garage_id: 57,
		part_id: 229,
		quantity: 91,
	},
	{
		garage_id: 57,
		part_id: 230,
		quantity: 364,
	},
	{
		garage_id: 57,
		part_id: 231,
		quantity: 416,
	},
	{
		garage_id: 57,
		part_id: 232,
		quantity: 462,
	},
	{
		garage_id: 57,
		part_id: 233,
		quantity: 38,
	},
	{
		garage_id: 57,
		part_id: 234,
		quantity: 236,
	},
	{
		garage_id: 57,
		part_id: 235,
		quantity: 125,
	},
	{
		garage_id: 57,
		part_id: 236,
		quantity: 336,
	},
	{
		garage_id: 57,
		part_id: 237,
		quantity: 308,
	},
	{
		garage_id: 57,
		part_id: 238,
		quantity: 372,
	},
	{
		garage_id: 57,
		part_id: 239,
		quantity: 85,
	},
	{
		garage_id: 57,
		part_id: 240,
		quantity: 352,
	},
	{
		garage_id: 57,
		part_id: 241,
		quantity: 88,
	},
	{
		garage_id: 57,
		part_id: 242,
		quantity: 119,
	},
	{
		garage_id: 57,
		part_id: 243,
		quantity: 19,
	},
	{
		garage_id: 57,
		part_id: 244,
		quantity: 221,
	},
	{
		garage_id: 57,
		part_id: 245,
		quantity: 455,
	},
	{
		garage_id: 57,
		part_id: 246,
		quantity: 189,
	},
	{
		garage_id: 57,
		part_id: 247,
		quantity: 33,
	},
	{
		garage_id: 57,
		part_id: 248,
		quantity: 244,
	},
	{
		garage_id: 57,
		part_id: 249,
		quantity: 416,
	},
	{
		garage_id: 57,
		part_id: 250,
		quantity: 234,
	},
	{
		garage_id: 57,
		part_id: 251,
		quantity: 288,
	},
	{
		garage_id: 57,
		part_id: 252,
		quantity: 90,
	},
	{
		garage_id: 57,
		part_id: 253,
		quantity: 150,
	},
	{
		garage_id: 57,
		part_id: 254,
		quantity: 471,
	},
	{
		garage_id: 57,
		part_id: 255,
		quantity: 386,
	},
	{
		garage_id: 57,
		part_id: 256,
		quantity: 439,
	},
	{
		garage_id: 57,
		part_id: 257,
		quantity: 480,
	},
	{
		garage_id: 57,
		part_id: 258,
		quantity: 49,
	},
	{
		garage_id: 57,
		part_id: 259,
		quantity: 44,
	},
	{
		garage_id: 57,
		part_id: 260,
		quantity: 251,
	},
	{
		garage_id: 57,
		part_id: 261,
		quantity: 25,
	},
	{
		garage_id: 57,
		part_id: 262,
		quantity: 432,
	},
	{
		garage_id: 57,
		part_id: 263,
		quantity: 407,
	},
	{
		garage_id: 57,
		part_id: 264,
		quantity: 466,
	},
	{
		garage_id: 57,
		part_id: 265,
		quantity: 382,
	},
	{
		garage_id: 57,
		part_id: 266,
		quantity: 380,
	},
	{
		garage_id: 57,
		part_id: 267,
		quantity: 112,
	},
	{
		garage_id: 57,
		part_id: 268,
		quantity: 267,
	},
	{
		garage_id: 57,
		part_id: 269,
		quantity: 399,
	},
	{
		garage_id: 57,
		part_id: 270,
		quantity: 360,
	},
	{
		garage_id: 57,
		part_id: 271,
		quantity: 154,
	},
	{
		garage_id: 57,
		part_id: 272,
		quantity: 276,
	},
	{
		garage_id: 57,
		part_id: 273,
		quantity: 216,
	},
	{
		garage_id: 57,
		part_id: 274,
		quantity: 326,
	},
	{
		garage_id: 57,
		part_id: 275,
		quantity: 262,
	},
	{
		garage_id: 57,
		part_id: 276,
		quantity: 463,
	},
	{
		garage_id: 57,
		part_id: 277,
		quantity: 33,
	},
	{
		garage_id: 57,
		part_id: 278,
		quantity: 248,
	},
	{
		garage_id: 57,
		part_id: 279,
		quantity: 132,
	},
	{
		garage_id: 57,
		part_id: 280,
		quantity: 402,
	},
	{
		garage_id: 57,
		part_id: 281,
		quantity: 251,
	},
	{
		garage_id: 57,
		part_id: 282,
		quantity: 389,
	},
	{
		garage_id: 57,
		part_id: 283,
		quantity: 295,
	},
	{
		garage_id: 57,
		part_id: 284,
		quantity: 355,
	},
	{
		garage_id: 57,
		part_id: 285,
		quantity: 32,
	},
	{
		garage_id: 57,
		part_id: 286,
		quantity: 135,
	},
	{
		garage_id: 57,
		part_id: 287,
		quantity: 498,
	},
	{
		garage_id: 57,
		part_id: 288,
		quantity: 482,
	},
	{
		garage_id: 57,
		part_id: 289,
		quantity: 162,
	},
	{
		garage_id: 57,
		part_id: 290,
		quantity: 94,
	},
	{
		garage_id: 57,
		part_id: 291,
		quantity: 165,
	},
	{
		garage_id: 57,
		part_id: 292,
		quantity: 433,
	},
	{
		garage_id: 57,
		part_id: 293,
		quantity: 68,
	},
	{
		garage_id: 57,
		part_id: 294,
		quantity: 397,
	},
	{
		garage_id: 57,
		part_id: 295,
		quantity: 31,
	},
	{
		garage_id: 57,
		part_id: 296,
		quantity: 341,
	},
	{
		garage_id: 57,
		part_id: 297,
		quantity: 127,
	},
	{
		garage_id: 57,
		part_id: 298,
		quantity: 457,
	},
	{
		garage_id: 57,
		part_id: 299,
		quantity: 50,
	},
	{
		garage_id: 57,
		part_id: 300,
		quantity: 291,
	},
	{
		garage_id: 57,
		part_id: 301,
		quantity: 116,
	},
	{
		garage_id: 57,
		part_id: 302,
		quantity: 436,
	},
	{
		garage_id: 57,
		part_id: 303,
		quantity: 347,
	},
	{
		garage_id: 57,
		part_id: 304,
		quantity: 130,
	},
	{
		garage_id: 57,
		part_id: 305,
		quantity: 364,
	},
	{
		garage_id: 57,
		part_id: 306,
		quantity: 37,
	},
	{
		garage_id: 57,
		part_id: 307,
		quantity: 416,
	},
	{
		garage_id: 57,
		part_id: 308,
		quantity: 428,
	},
	{
		garage_id: 57,
		part_id: 309,
		quantity: 126,
	},
	{
		garage_id: 57,
		part_id: 310,
		quantity: 180,
	},
	{
		garage_id: 57,
		part_id: 311,
		quantity: 425,
	},
	{
		garage_id: 57,
		part_id: 312,
		quantity: 264,
	},
	{
		garage_id: 57,
		part_id: 313,
		quantity: 59,
	},
	{
		garage_id: 57,
		part_id: 314,
		quantity: 273,
	},
	{
		garage_id: 57,
		part_id: 315,
		quantity: 416,
	},
	{
		garage_id: 57,
		part_id: 316,
		quantity: 271,
	},
	{
		garage_id: 57,
		part_id: 317,
		quantity: 334,
	},
	{
		garage_id: 57,
		part_id: 318,
		quantity: 321,
	},
	{
		garage_id: 57,
		part_id: 319,
		quantity: 388,
	},
	{
		garage_id: 57,
		part_id: 320,
		quantity: 413,
	},
	{
		garage_id: 57,
		part_id: 321,
		quantity: 21,
	},
	{
		garage_id: 57,
		part_id: 322,
		quantity: 379,
	},
	{
		garage_id: 57,
		part_id: 323,
		quantity: 411,
	},
	{
		garage_id: 57,
		part_id: 324,
		quantity: 59,
	},
	{
		garage_id: 57,
		part_id: 325,
		quantity: 234,
	},
	{
		garage_id: 57,
		part_id: 326,
		quantity: 194,
	},
	{
		garage_id: 57,
		part_id: 327,
		quantity: 135,
	},
	{
		garage_id: 57,
		part_id: 328,
		quantity: 254,
	},
	{
		garage_id: 57,
		part_id: 329,
		quantity: 500,
	},
	{
		garage_id: 57,
		part_id: 330,
		quantity: 57,
	},
	{
		garage_id: 57,
		part_id: 331,
		quantity: 324,
	},
	{
		garage_id: 57,
		part_id: 332,
		quantity: 406,
	},
	{
		garage_id: 57,
		part_id: 333,
		quantity: 238,
	},
	{
		garage_id: 57,
		part_id: 334,
		quantity: 308,
	},
	{
		garage_id: 57,
		part_id: 335,
		quantity: 401,
	},
	{
		garage_id: 57,
		part_id: 336,
		quantity: 443,
	},
	{
		garage_id: 57,
		part_id: 337,
		quantity: 356,
	},
	{
		garage_id: 57,
		part_id: 338,
		quantity: 320,
	},
	{
		garage_id: 57,
		part_id: 339,
		quantity: 460,
	},
	{
		garage_id: 57,
		part_id: 340,
		quantity: 149,
	},
	{
		garage_id: 57,
		part_id: 341,
		quantity: 132,
	},
	{
		garage_id: 57,
		part_id: 342,
		quantity: 26,
	},
	{
		garage_id: 57,
		part_id: 343,
		quantity: 173,
	},
	{
		garage_id: 57,
		part_id: 344,
		quantity: 483,
	},
	{
		garage_id: 57,
		part_id: 345,
		quantity: 294,
	},
	{
		garage_id: 57,
		part_id: 346,
		quantity: 327,
	},
	{
		garage_id: 57,
		part_id: 347,
		quantity: 408,
	},
	{
		garage_id: 57,
		part_id: 348,
		quantity: 406,
	},
	{
		garage_id: 57,
		part_id: 349,
		quantity: 451,
	},
	{
		garage_id: 57,
		part_id: 350,
		quantity: 39,
	},
	{
		garage_id: 57,
		part_id: 351,
		quantity: 149,
	},
	{
		garage_id: 57,
		part_id: 352,
		quantity: 376,
	},
	{
		garage_id: 57,
		part_id: 353,
		quantity: 407,
	},
	{
		garage_id: 57,
		part_id: 354,
		quantity: 449,
	},
	{
		garage_id: 57,
		part_id: 355,
		quantity: 393,
	},
	{
		garage_id: 57,
		part_id: 356,
		quantity: 392,
	},
	{
		garage_id: 57,
		part_id: 357,
		quantity: 306,
	},
	{
		garage_id: 57,
		part_id: 358,
		quantity: 262,
	},
	{
		garage_id: 57,
		part_id: 359,
		quantity: 330,
	},
	{
		garage_id: 57,
		part_id: 360,
		quantity: 251,
	},
	{
		garage_id: 57,
		part_id: 361,
		quantity: 480,
	},
	{
		garage_id: 57,
		part_id: 362,
		quantity: 104,
	},
	{
		garage_id: 57,
		part_id: 363,
		quantity: 189,
	},
	{
		garage_id: 57,
		part_id: 364,
		quantity: 332,
	},
	{
		garage_id: 57,
		part_id: 365,
		quantity: 160,
	},
	{
		garage_id: 57,
		part_id: 366,
		quantity: 458,
	},
	{
		garage_id: 57,
		part_id: 367,
		quantity: 477,
	},
	{
		garage_id: 57,
		part_id: 368,
		quantity: 188,
	},
	{
		garage_id: 57,
		part_id: 369,
		quantity: 82,
	},
	{
		garage_id: 57,
		part_id: 370,
		quantity: 67,
	},
	{
		garage_id: 57,
		part_id: 371,
		quantity: 348,
	},
	{
		garage_id: 57,
		part_id: 372,
		quantity: 118,
	},
	{
		garage_id: 57,
		part_id: 373,
		quantity: 94,
	},
	{
		garage_id: 57,
		part_id: 374,
		quantity: 475,
	},
	{
		garage_id: 57,
		part_id: 375,
		quantity: 224,
	},
	{
		garage_id: 57,
		part_id: 376,
		quantity: 157,
	},
	{
		garage_id: 57,
		part_id: 377,
		quantity: 66,
	},
	{
		garage_id: 57,
		part_id: 378,
		quantity: 186,
	},
	{
		garage_id: 57,
		part_id: 379,
		quantity: 101,
	},
	{
		garage_id: 57,
		part_id: 380,
		quantity: 451,
	},
	{
		garage_id: 57,
		part_id: 381,
		quantity: 86,
	},
	{
		garage_id: 57,
		part_id: 382,
		quantity: 145,
	},
	{
		garage_id: 57,
		part_id: 383,
		quantity: 73,
	},
	{
		garage_id: 57,
		part_id: 384,
		quantity: 324,
	},
	{
		garage_id: 57,
		part_id: 385,
		quantity: 89,
	},
	{
		garage_id: 57,
		part_id: 386,
		quantity: 477,
	},
	{
		garage_id: 57,
		part_id: 387,
		quantity: 124,
	},
	{
		garage_id: 57,
		part_id: 388,
		quantity: 469,
	},
	{
		garage_id: 57,
		part_id: 389,
		quantity: 407,
	},
	{
		garage_id: 57,
		part_id: 390,
		quantity: 65,
	},
	{
		garage_id: 57,
		part_id: 391,
		quantity: 25,
	},
	{
		garage_id: 57,
		part_id: 392,
		quantity: 209,
	},
	{
		garage_id: 57,
		part_id: 393,
		quantity: 301,
	},
	{
		garage_id: 57,
		part_id: 394,
		quantity: 26,
	},
	{
		garage_id: 57,
		part_id: 395,
		quantity: 205,
	},
	{
		garage_id: 57,
		part_id: 396,
		quantity: 270,
	},
	{
		garage_id: 57,
		part_id: 397,
		quantity: 218,
	},
	{
		garage_id: 57,
		part_id: 398,
		quantity: 412,
	},
	{
		garage_id: 57,
		part_id: 399,
		quantity: 2,
	},
	{
		garage_id: 58,
		part_id: 0,
		quantity: 28,
	},
	{
		garage_id: 58,
		part_id: 1,
		quantity: 494,
	},
	{
		garage_id: 58,
		part_id: 2,
		quantity: 268,
	},
	{
		garage_id: 58,
		part_id: 3,
		quantity: 397,
	},
	{
		garage_id: 58,
		part_id: 4,
		quantity: 362,
	},
	{
		garage_id: 58,
		part_id: 5,
		quantity: 173,
	},
	{
		garage_id: 58,
		part_id: 6,
		quantity: 22,
	},
	{
		garage_id: 58,
		part_id: 7,
		quantity: 431,
	},
	{
		garage_id: 58,
		part_id: 8,
		quantity: 319,
	},
	{
		garage_id: 58,
		part_id: 9,
		quantity: 10,
	},
	{
		garage_id: 58,
		part_id: 10,
		quantity: 413,
	},
	{
		garage_id: 58,
		part_id: 11,
		quantity: 97,
	},
	{
		garage_id: 58,
		part_id: 12,
		quantity: 343,
	},
	{
		garage_id: 58,
		part_id: 13,
		quantity: 7,
	},
	{
		garage_id: 58,
		part_id: 14,
		quantity: 54,
	},
	{
		garage_id: 58,
		part_id: 15,
		quantity: 281,
	},
	{
		garage_id: 58,
		part_id: 16,
		quantity: 445,
	},
	{
		garage_id: 58,
		part_id: 17,
		quantity: 207,
	},
	{
		garage_id: 58,
		part_id: 18,
		quantity: 471,
	},
	{
		garage_id: 58,
		part_id: 19,
		quantity: 330,
	},
	{
		garage_id: 58,
		part_id: 20,
		quantity: 71,
	},
	{
		garage_id: 58,
		part_id: 21,
		quantity: 5,
	},
	{
		garage_id: 58,
		part_id: 22,
		quantity: 82,
	},
	{
		garage_id: 58,
		part_id: 23,
		quantity: 346,
	},
	{
		garage_id: 58,
		part_id: 24,
		quantity: 270,
	},
	{
		garage_id: 58,
		part_id: 25,
		quantity: 151,
	},
	{
		garage_id: 58,
		part_id: 26,
		quantity: 38,
	},
	{
		garage_id: 58,
		part_id: 27,
		quantity: 274,
	},
	{
		garage_id: 58,
		part_id: 28,
		quantity: 417,
	},
	{
		garage_id: 58,
		part_id: 29,
		quantity: 182,
	},
	{
		garage_id: 58,
		part_id: 30,
		quantity: 421,
	},
	{
		garage_id: 58,
		part_id: 31,
		quantity: 128,
	},
	{
		garage_id: 58,
		part_id: 32,
		quantity: 65,
	},
	{
		garage_id: 58,
		part_id: 33,
		quantity: 388,
	},
	{
		garage_id: 58,
		part_id: 34,
		quantity: 264,
	},
	{
		garage_id: 58,
		part_id: 35,
		quantity: 62,
	},
	{
		garage_id: 58,
		part_id: 36,
		quantity: 45,
	},
	{
		garage_id: 58,
		part_id: 37,
		quantity: 285,
	},
	{
		garage_id: 58,
		part_id: 38,
		quantity: 272,
	},
	{
		garage_id: 58,
		part_id: 39,
		quantity: 229,
	},
	{
		garage_id: 58,
		part_id: 40,
		quantity: 70,
	},
	{
		garage_id: 58,
		part_id: 41,
		quantity: 209,
	},
	{
		garage_id: 58,
		part_id: 42,
		quantity: 316,
	},
	{
		garage_id: 58,
		part_id: 43,
		quantity: 480,
	},
	{
		garage_id: 58,
		part_id: 44,
		quantity: 304,
	},
	{
		garage_id: 58,
		part_id: 45,
		quantity: 86,
	},
	{
		garage_id: 58,
		part_id: 46,
		quantity: 242,
	},
	{
		garage_id: 58,
		part_id: 47,
		quantity: 200,
	},
	{
		garage_id: 58,
		part_id: 48,
		quantity: 351,
	},
	{
		garage_id: 58,
		part_id: 49,
		quantity: 495,
	},
	{
		garage_id: 58,
		part_id: 50,
		quantity: 232,
	},
	{
		garage_id: 58,
		part_id: 51,
		quantity: 80,
	},
	{
		garage_id: 58,
		part_id: 52,
		quantity: 202,
	},
	{
		garage_id: 58,
		part_id: 53,
		quantity: 138,
	},
	{
		garage_id: 58,
		part_id: 54,
		quantity: 291,
	},
	{
		garage_id: 58,
		part_id: 55,
		quantity: 457,
	},
	{
		garage_id: 58,
		part_id: 56,
		quantity: 150,
	},
	{
		garage_id: 58,
		part_id: 57,
		quantity: 24,
	},
	{
		garage_id: 58,
		part_id: 58,
		quantity: 450,
	},
	{
		garage_id: 58,
		part_id: 59,
		quantity: 422,
	},
	{
		garage_id: 58,
		part_id: 60,
		quantity: 395,
	},
	{
		garage_id: 58,
		part_id: 61,
		quantity: 479,
	},
	{
		garage_id: 58,
		part_id: 62,
		quantity: 264,
	},
	{
		garage_id: 58,
		part_id: 63,
		quantity: 372,
	},
	{
		garage_id: 58,
		part_id: 64,
		quantity: 273,
	},
	{
		garage_id: 58,
		part_id: 65,
		quantity: 96,
	},
	{
		garage_id: 58,
		part_id: 66,
		quantity: 206,
	},
	{
		garage_id: 58,
		part_id: 67,
		quantity: 297,
	},
	{
		garage_id: 58,
		part_id: 68,
		quantity: 160,
	},
	{
		garage_id: 58,
		part_id: 69,
		quantity: 196,
	},
	{
		garage_id: 58,
		part_id: 70,
		quantity: 113,
	},
	{
		garage_id: 58,
		part_id: 71,
		quantity: 114,
	},
	{
		garage_id: 58,
		part_id: 72,
		quantity: 242,
	},
	{
		garage_id: 58,
		part_id: 73,
		quantity: 328,
	},
	{
		garage_id: 58,
		part_id: 74,
		quantity: 57,
	},
	{
		garage_id: 58,
		part_id: 75,
		quantity: 446,
	},
	{
		garage_id: 58,
		part_id: 76,
		quantity: 141,
	},
	{
		garage_id: 58,
		part_id: 77,
		quantity: 153,
	},
	{
		garage_id: 58,
		part_id: 78,
		quantity: 56,
	},
	{
		garage_id: 58,
		part_id: 79,
		quantity: 43,
	},
	{
		garage_id: 58,
		part_id: 80,
		quantity: 352,
	},
	{
		garage_id: 58,
		part_id: 81,
		quantity: 482,
	},
	{
		garage_id: 58,
		part_id: 82,
		quantity: 260,
	},
	{
		garage_id: 58,
		part_id: 83,
		quantity: 499,
	},
	{
		garage_id: 58,
		part_id: 84,
		quantity: 498,
	},
	{
		garage_id: 58,
		part_id: 85,
		quantity: 401,
	},
	{
		garage_id: 58,
		part_id: 86,
		quantity: 288,
	},
	{
		garage_id: 58,
		part_id: 87,
		quantity: 463,
	},
	{
		garage_id: 58,
		part_id: 88,
		quantity: 167,
	},
	{
		garage_id: 58,
		part_id: 89,
		quantity: 216,
	},
	{
		garage_id: 58,
		part_id: 90,
		quantity: 187,
	},
	{
		garage_id: 58,
		part_id: 91,
		quantity: 316,
	},
	{
		garage_id: 58,
		part_id: 92,
		quantity: 122,
	},
	{
		garage_id: 58,
		part_id: 93,
		quantity: 15,
	},
	{
		garage_id: 58,
		part_id: 94,
		quantity: 356,
	},
	{
		garage_id: 58,
		part_id: 95,
		quantity: 1,
	},
	{
		garage_id: 58,
		part_id: 96,
		quantity: 189,
	},
	{
		garage_id: 58,
		part_id: 97,
		quantity: 90,
	},
	{
		garage_id: 58,
		part_id: 98,
		quantity: 370,
	},
	{
		garage_id: 58,
		part_id: 99,
		quantity: 467,
	},
	{
		garage_id: 58,
		part_id: 100,
		quantity: 471,
	},
	{
		garage_id: 58,
		part_id: 101,
		quantity: 366,
	},
	{
		garage_id: 58,
		part_id: 102,
		quantity: 496,
	},
	{
		garage_id: 58,
		part_id: 103,
		quantity: 111,
	},
	{
		garage_id: 58,
		part_id: 104,
		quantity: 122,
	},
	{
		garage_id: 58,
		part_id: 105,
		quantity: 268,
	},
	{
		garage_id: 58,
		part_id: 106,
		quantity: 148,
	},
	{
		garage_id: 58,
		part_id: 107,
		quantity: 328,
	},
	{
		garage_id: 58,
		part_id: 108,
		quantity: 132,
	},
	{
		garage_id: 58,
		part_id: 109,
		quantity: 122,
	},
	{
		garage_id: 58,
		part_id: 110,
		quantity: 264,
	},
	{
		garage_id: 58,
		part_id: 111,
		quantity: 454,
	},
	{
		garage_id: 58,
		part_id: 112,
		quantity: 319,
	},
	{
		garage_id: 58,
		part_id: 113,
		quantity: 473,
	},
	{
		garage_id: 58,
		part_id: 114,
		quantity: 342,
	},
	{
		garage_id: 58,
		part_id: 115,
		quantity: 203,
	},
	{
		garage_id: 58,
		part_id: 116,
		quantity: 115,
	},
	{
		garage_id: 58,
		part_id: 117,
		quantity: 365,
	},
	{
		garage_id: 58,
		part_id: 118,
		quantity: 110,
	},
	{
		garage_id: 58,
		part_id: 119,
		quantity: 128,
	},
	{
		garage_id: 58,
		part_id: 120,
		quantity: 399,
	},
	{
		garage_id: 58,
		part_id: 121,
		quantity: 443,
	},
	{
		garage_id: 58,
		part_id: 122,
		quantity: 273,
	},
	{
		garage_id: 58,
		part_id: 123,
		quantity: 56,
	},
	{
		garage_id: 58,
		part_id: 124,
		quantity: 448,
	},
	{
		garage_id: 58,
		part_id: 125,
		quantity: 457,
	},
	{
		garage_id: 58,
		part_id: 126,
		quantity: 137,
	},
	{
		garage_id: 58,
		part_id: 127,
		quantity: 433,
	},
	{
		garage_id: 58,
		part_id: 128,
		quantity: 44,
	},
	{
		garage_id: 58,
		part_id: 129,
		quantity: 442,
	},
	{
		garage_id: 58,
		part_id: 130,
		quantity: 207,
	},
	{
		garage_id: 58,
		part_id: 131,
		quantity: 386,
	},
	{
		garage_id: 58,
		part_id: 132,
		quantity: 251,
	},
	{
		garage_id: 58,
		part_id: 133,
		quantity: 219,
	},
	{
		garage_id: 58,
		part_id: 134,
		quantity: 145,
	},
	{
		garage_id: 58,
		part_id: 135,
		quantity: 182,
	},
	{
		garage_id: 58,
		part_id: 136,
		quantity: 484,
	},
	{
		garage_id: 58,
		part_id: 137,
		quantity: 23,
	},
	{
		garage_id: 58,
		part_id: 138,
		quantity: 400,
	},
	{
		garage_id: 58,
		part_id: 139,
		quantity: 423,
	},
	{
		garage_id: 58,
		part_id: 140,
		quantity: 224,
	},
	{
		garage_id: 58,
		part_id: 141,
		quantity: 273,
	},
	{
		garage_id: 58,
		part_id: 142,
		quantity: 216,
	},
	{
		garage_id: 58,
		part_id: 143,
		quantity: 136,
	},
	{
		garage_id: 58,
		part_id: 144,
		quantity: 24,
	},
	{
		garage_id: 58,
		part_id: 145,
		quantity: 311,
	},
	{
		garage_id: 58,
		part_id: 146,
		quantity: 233,
	},
	{
		garage_id: 58,
		part_id: 147,
		quantity: 251,
	},
	{
		garage_id: 58,
		part_id: 148,
		quantity: 225,
	},
	{
		garage_id: 58,
		part_id: 149,
		quantity: 236,
	},
	{
		garage_id: 58,
		part_id: 150,
		quantity: 237,
	},
	{
		garage_id: 58,
		part_id: 151,
		quantity: 402,
	},
	{
		garage_id: 58,
		part_id: 152,
		quantity: 138,
	},
	{
		garage_id: 58,
		part_id: 153,
		quantity: 250,
	},
	{
		garage_id: 58,
		part_id: 154,
		quantity: 63,
	},
	{
		garage_id: 58,
		part_id: 155,
		quantity: 348,
	},
	{
		garage_id: 58,
		part_id: 156,
		quantity: 376,
	},
	{
		garage_id: 58,
		part_id: 157,
		quantity: 156,
	},
	{
		garage_id: 58,
		part_id: 158,
		quantity: 415,
	},
	{
		garage_id: 58,
		part_id: 159,
		quantity: 435,
	},
	{
		garage_id: 58,
		part_id: 160,
		quantity: 88,
	},
	{
		garage_id: 58,
		part_id: 161,
		quantity: 65,
	},
	{
		garage_id: 58,
		part_id: 162,
		quantity: 242,
	},
	{
		garage_id: 58,
		part_id: 163,
		quantity: 339,
	},
	{
		garage_id: 58,
		part_id: 164,
		quantity: 83,
	},
	{
		garage_id: 58,
		part_id: 165,
		quantity: 142,
	},
	{
		garage_id: 58,
		part_id: 166,
		quantity: 132,
	},
	{
		garage_id: 58,
		part_id: 167,
		quantity: 460,
	},
	{
		garage_id: 58,
		part_id: 168,
		quantity: 302,
	},
	{
		garage_id: 58,
		part_id: 169,
		quantity: 63,
	},
	{
		garage_id: 58,
		part_id: 170,
		quantity: 40,
	},
	{
		garage_id: 58,
		part_id: 171,
		quantity: 316,
	},
	{
		garage_id: 58,
		part_id: 172,
		quantity: 285,
	},
	{
		garage_id: 58,
		part_id: 173,
		quantity: 80,
	},
	{
		garage_id: 58,
		part_id: 174,
		quantity: 194,
	},
	{
		garage_id: 58,
		part_id: 175,
		quantity: 85,
	},
	{
		garage_id: 58,
		part_id: 176,
		quantity: 414,
	},
	{
		garage_id: 58,
		part_id: 177,
		quantity: 104,
	},
	{
		garage_id: 58,
		part_id: 178,
		quantity: 9,
	},
	{
		garage_id: 58,
		part_id: 179,
		quantity: 258,
	},
	{
		garage_id: 58,
		part_id: 180,
		quantity: 55,
	},
	{
		garage_id: 58,
		part_id: 181,
		quantity: 442,
	},
	{
		garage_id: 58,
		part_id: 182,
		quantity: 402,
	},
	{
		garage_id: 58,
		part_id: 183,
		quantity: 126,
	},
	{
		garage_id: 58,
		part_id: 184,
		quantity: 204,
	},
	{
		garage_id: 58,
		part_id: 185,
		quantity: 431,
	},
	{
		garage_id: 58,
		part_id: 186,
		quantity: 182,
	},
	{
		garage_id: 58,
		part_id: 187,
		quantity: 421,
	},
	{
		garage_id: 58,
		part_id: 188,
		quantity: 319,
	},
	{
		garage_id: 58,
		part_id: 189,
		quantity: 153,
	},
	{
		garage_id: 58,
		part_id: 190,
		quantity: 213,
	},
	{
		garage_id: 58,
		part_id: 191,
		quantity: 411,
	},
	{
		garage_id: 58,
		part_id: 192,
		quantity: 359,
	},
	{
		garage_id: 58,
		part_id: 193,
		quantity: 235,
	},
	{
		garage_id: 58,
		part_id: 194,
		quantity: 354,
	},
	{
		garage_id: 58,
		part_id: 195,
		quantity: 493,
	},
	{
		garage_id: 58,
		part_id: 196,
		quantity: 172,
	},
	{
		garage_id: 58,
		part_id: 197,
		quantity: 246,
	},
	{
		garage_id: 58,
		part_id: 198,
		quantity: 107,
	},
	{
		garage_id: 58,
		part_id: 199,
		quantity: 311,
	},
	{
		garage_id: 58,
		part_id: 200,
		quantity: 488,
	},
	{
		garage_id: 58,
		part_id: 201,
		quantity: 345,
	},
	{
		garage_id: 58,
		part_id: 202,
		quantity: 184,
	},
	{
		garage_id: 58,
		part_id: 203,
		quantity: 345,
	},
	{
		garage_id: 58,
		part_id: 204,
		quantity: 449,
	},
	{
		garage_id: 58,
		part_id: 205,
		quantity: 146,
	},
	{
		garage_id: 58,
		part_id: 206,
		quantity: 490,
	},
	{
		garage_id: 58,
		part_id: 207,
		quantity: 28,
	},
	{
		garage_id: 58,
		part_id: 208,
		quantity: 422,
	},
	{
		garage_id: 58,
		part_id: 209,
		quantity: 290,
	},
	{
		garage_id: 58,
		part_id: 210,
		quantity: 330,
	},
	{
		garage_id: 58,
		part_id: 211,
		quantity: 428,
	},
	{
		garage_id: 58,
		part_id: 212,
		quantity: 68,
	},
	{
		garage_id: 58,
		part_id: 213,
		quantity: 251,
	},
	{
		garage_id: 58,
		part_id: 214,
		quantity: 435,
	},
	{
		garage_id: 58,
		part_id: 215,
		quantity: 483,
	},
	{
		garage_id: 58,
		part_id: 216,
		quantity: 436,
	},
	{
		garage_id: 58,
		part_id: 217,
		quantity: 30,
	},
	{
		garage_id: 58,
		part_id: 218,
		quantity: 394,
	},
	{
		garage_id: 58,
		part_id: 219,
		quantity: 452,
	},
	{
		garage_id: 58,
		part_id: 220,
		quantity: 174,
	},
	{
		garage_id: 58,
		part_id: 221,
		quantity: 61,
	},
	{
		garage_id: 58,
		part_id: 222,
		quantity: 135,
	},
	{
		garage_id: 58,
		part_id: 223,
		quantity: 422,
	},
	{
		garage_id: 58,
		part_id: 224,
		quantity: 50,
	},
	{
		garage_id: 58,
		part_id: 225,
		quantity: 369,
	},
	{
		garage_id: 58,
		part_id: 226,
		quantity: 229,
	},
	{
		garage_id: 58,
		part_id: 227,
		quantity: 392,
	},
	{
		garage_id: 58,
		part_id: 228,
		quantity: 449,
	},
	{
		garage_id: 58,
		part_id: 229,
		quantity: 448,
	},
	{
		garage_id: 58,
		part_id: 230,
		quantity: 39,
	},
	{
		garage_id: 58,
		part_id: 231,
		quantity: 218,
	},
	{
		garage_id: 58,
		part_id: 232,
		quantity: 446,
	},
	{
		garage_id: 58,
		part_id: 233,
		quantity: 373,
	},
	{
		garage_id: 58,
		part_id: 234,
		quantity: 481,
	},
	{
		garage_id: 58,
		part_id: 235,
		quantity: 373,
	},
	{
		garage_id: 58,
		part_id: 236,
		quantity: 443,
	},
	{
		garage_id: 58,
		part_id: 237,
		quantity: 395,
	},
	{
		garage_id: 58,
		part_id: 238,
		quantity: 50,
	},
	{
		garage_id: 58,
		part_id: 239,
		quantity: 446,
	},
	{
		garage_id: 58,
		part_id: 240,
		quantity: 122,
	},
	{
		garage_id: 58,
		part_id: 241,
		quantity: 70,
	},
	{
		garage_id: 58,
		part_id: 242,
		quantity: 477,
	},
	{
		garage_id: 58,
		part_id: 243,
		quantity: 159,
	},
	{
		garage_id: 58,
		part_id: 244,
		quantity: 311,
	},
	{
		garage_id: 58,
		part_id: 245,
		quantity: 440,
	},
	{
		garage_id: 58,
		part_id: 246,
		quantity: 349,
	},
	{
		garage_id: 58,
		part_id: 247,
		quantity: 31,
	},
	{
		garage_id: 58,
		part_id: 248,
		quantity: 199,
	},
	{
		garage_id: 58,
		part_id: 249,
		quantity: 306,
	},
	{
		garage_id: 58,
		part_id: 250,
		quantity: 24,
	},
	{
		garage_id: 58,
		part_id: 251,
		quantity: 247,
	},
	{
		garage_id: 58,
		part_id: 252,
		quantity: 427,
	},
	{
		garage_id: 58,
		part_id: 253,
		quantity: 31,
	},
	{
		garage_id: 58,
		part_id: 254,
		quantity: 322,
	},
	{
		garage_id: 58,
		part_id: 255,
		quantity: 11,
	},
	{
		garage_id: 58,
		part_id: 256,
		quantity: 65,
	},
	{
		garage_id: 58,
		part_id: 257,
		quantity: 136,
	},
	{
		garage_id: 58,
		part_id: 258,
		quantity: 344,
	},
	{
		garage_id: 58,
		part_id: 259,
		quantity: 85,
	},
	{
		garage_id: 58,
		part_id: 260,
		quantity: 347,
	},
	{
		garage_id: 58,
		part_id: 261,
		quantity: 140,
	},
	{
		garage_id: 58,
		part_id: 262,
		quantity: 166,
	},
	{
		garage_id: 58,
		part_id: 263,
		quantity: 307,
	},
	{
		garage_id: 58,
		part_id: 264,
		quantity: 256,
	},
	{
		garage_id: 58,
		part_id: 265,
		quantity: 404,
	},
	{
		garage_id: 58,
		part_id: 266,
		quantity: 298,
	},
	{
		garage_id: 58,
		part_id: 267,
		quantity: 151,
	},
	{
		garage_id: 58,
		part_id: 268,
		quantity: 227,
	},
	{
		garage_id: 58,
		part_id: 269,
		quantity: 412,
	},
	{
		garage_id: 58,
		part_id: 270,
		quantity: 283,
	},
	{
		garage_id: 58,
		part_id: 271,
		quantity: 335,
	},
	{
		garage_id: 58,
		part_id: 272,
		quantity: 165,
	},
	{
		garage_id: 58,
		part_id: 273,
		quantity: 11,
	},
	{
		garage_id: 58,
		part_id: 274,
		quantity: 364,
	},
	{
		garage_id: 58,
		part_id: 275,
		quantity: 205,
	},
	{
		garage_id: 58,
		part_id: 276,
		quantity: 387,
	},
	{
		garage_id: 58,
		part_id: 277,
		quantity: 102,
	},
	{
		garage_id: 58,
		part_id: 278,
		quantity: 54,
	},
	{
		garage_id: 58,
		part_id: 279,
		quantity: 346,
	},
	{
		garage_id: 58,
		part_id: 280,
		quantity: 179,
	},
	{
		garage_id: 58,
		part_id: 281,
		quantity: 282,
	},
	{
		garage_id: 58,
		part_id: 282,
		quantity: 195,
	},
	{
		garage_id: 58,
		part_id: 283,
		quantity: 428,
	},
	{
		garage_id: 58,
		part_id: 284,
		quantity: 66,
	},
	{
		garage_id: 58,
		part_id: 285,
		quantity: 269,
	},
	{
		garage_id: 58,
		part_id: 286,
		quantity: 179,
	},
	{
		garage_id: 58,
		part_id: 287,
		quantity: 365,
	},
	{
		garage_id: 58,
		part_id: 288,
		quantity: 117,
	},
	{
		garage_id: 58,
		part_id: 289,
		quantity: 85,
	},
	{
		garage_id: 58,
		part_id: 290,
		quantity: 358,
	},
	{
		garage_id: 58,
		part_id: 291,
		quantity: 124,
	},
	{
		garage_id: 58,
		part_id: 292,
		quantity: 110,
	},
	{
		garage_id: 58,
		part_id: 293,
		quantity: 449,
	},
	{
		garage_id: 58,
		part_id: 294,
		quantity: 482,
	},
	{
		garage_id: 58,
		part_id: 295,
		quantity: 497,
	},
	{
		garage_id: 58,
		part_id: 296,
		quantity: 42,
	},
	{
		garage_id: 58,
		part_id: 297,
		quantity: 480,
	},
	{
		garage_id: 58,
		part_id: 298,
		quantity: 297,
	},
	{
		garage_id: 58,
		part_id: 299,
		quantity: 478,
	},
	{
		garage_id: 58,
		part_id: 300,
		quantity: 41,
	},
	{
		garage_id: 58,
		part_id: 301,
		quantity: 204,
	},
	{
		garage_id: 58,
		part_id: 302,
		quantity: 148,
	},
	{
		garage_id: 58,
		part_id: 303,
		quantity: 480,
	},
	{
		garage_id: 58,
		part_id: 304,
		quantity: 493,
	},
	{
		garage_id: 58,
		part_id: 305,
		quantity: 363,
	},
	{
		garage_id: 58,
		part_id: 306,
		quantity: 136,
	},
	{
		garage_id: 58,
		part_id: 307,
		quantity: 222,
	},
	{
		garage_id: 58,
		part_id: 308,
		quantity: 246,
	},
	{
		garage_id: 58,
		part_id: 309,
		quantity: 369,
	},
	{
		garage_id: 58,
		part_id: 310,
		quantity: 419,
	},
	{
		garage_id: 58,
		part_id: 311,
		quantity: 254,
	},
	{
		garage_id: 58,
		part_id: 312,
		quantity: 201,
	},
	{
		garage_id: 58,
		part_id: 313,
		quantity: 288,
	},
	{
		garage_id: 58,
		part_id: 314,
		quantity: 263,
	},
	{
		garage_id: 58,
		part_id: 315,
		quantity: 89,
	},
	{
		garage_id: 58,
		part_id: 316,
		quantity: 308,
	},
	{
		garage_id: 58,
		part_id: 317,
		quantity: 492,
	},
	{
		garage_id: 58,
		part_id: 318,
		quantity: 72,
	},
	{
		garage_id: 58,
		part_id: 319,
		quantity: 128,
	},
	{
		garage_id: 58,
		part_id: 320,
		quantity: 20,
	},
	{
		garage_id: 58,
		part_id: 321,
		quantity: 198,
	},
	{
		garage_id: 58,
		part_id: 322,
		quantity: 161,
	},
	{
		garage_id: 58,
		part_id: 323,
		quantity: 447,
	},
	{
		garage_id: 58,
		part_id: 324,
		quantity: 420,
	},
	{
		garage_id: 58,
		part_id: 325,
		quantity: 477,
	},
	{
		garage_id: 58,
		part_id: 326,
		quantity: 328,
	},
	{
		garage_id: 58,
		part_id: 327,
		quantity: 147,
	},
	{
		garage_id: 58,
		part_id: 328,
		quantity: 85,
	},
	{
		garage_id: 58,
		part_id: 329,
		quantity: 58,
	},
	{
		garage_id: 58,
		part_id: 330,
		quantity: 465,
	},
	{
		garage_id: 58,
		part_id: 331,
		quantity: 76,
	},
	{
		garage_id: 58,
		part_id: 332,
		quantity: 454,
	},
	{
		garage_id: 58,
		part_id: 333,
		quantity: 18,
	},
	{
		garage_id: 58,
		part_id: 334,
		quantity: 83,
	},
	{
		garage_id: 58,
		part_id: 335,
		quantity: 269,
	},
	{
		garage_id: 58,
		part_id: 336,
		quantity: 161,
	},
	{
		garage_id: 58,
		part_id: 337,
		quantity: 123,
	},
	{
		garage_id: 58,
		part_id: 338,
		quantity: 110,
	},
	{
		garage_id: 58,
		part_id: 339,
		quantity: 111,
	},
	{
		garage_id: 58,
		part_id: 340,
		quantity: 273,
	},
	{
		garage_id: 58,
		part_id: 341,
		quantity: 485,
	},
	{
		garage_id: 58,
		part_id: 342,
		quantity: 13,
	},
	{
		garage_id: 58,
		part_id: 343,
		quantity: 102,
	},
	{
		garage_id: 58,
		part_id: 344,
		quantity: 433,
	},
	{
		garage_id: 58,
		part_id: 345,
		quantity: 398,
	},
	{
		garage_id: 58,
		part_id: 346,
		quantity: 420,
	},
	{
		garage_id: 58,
		part_id: 347,
		quantity: 346,
	},
	{
		garage_id: 58,
		part_id: 348,
		quantity: 306,
	},
	{
		garage_id: 58,
		part_id: 349,
		quantity: 87,
	},
	{
		garage_id: 58,
		part_id: 350,
		quantity: 135,
	},
	{
		garage_id: 58,
		part_id: 351,
		quantity: 340,
	},
	{
		garage_id: 58,
		part_id: 352,
		quantity: 155,
	},
	{
		garage_id: 58,
		part_id: 353,
		quantity: 371,
	},
	{
		garage_id: 58,
		part_id: 354,
		quantity: 496,
	},
	{
		garage_id: 58,
		part_id: 355,
		quantity: 473,
	},
	{
		garage_id: 58,
		part_id: 356,
		quantity: 81,
	},
	{
		garage_id: 58,
		part_id: 357,
		quantity: 175,
	},
	{
		garage_id: 58,
		part_id: 358,
		quantity: 157,
	},
	{
		garage_id: 58,
		part_id: 359,
		quantity: 407,
	},
	{
		garage_id: 58,
		part_id: 360,
		quantity: 328,
	},
	{
		garage_id: 58,
		part_id: 361,
		quantity: 211,
	},
	{
		garage_id: 58,
		part_id: 362,
		quantity: 200,
	},
	{
		garage_id: 58,
		part_id: 363,
		quantity: 76,
	},
	{
		garage_id: 58,
		part_id: 364,
		quantity: 389,
	},
	{
		garage_id: 58,
		part_id: 365,
		quantity: 319,
	},
	{
		garage_id: 58,
		part_id: 366,
		quantity: 296,
	},
	{
		garage_id: 58,
		part_id: 367,
		quantity: 299,
	},
	{
		garage_id: 58,
		part_id: 368,
		quantity: 486,
	},
	{
		garage_id: 58,
		part_id: 369,
		quantity: 247,
	},
	{
		garage_id: 58,
		part_id: 370,
		quantity: 264,
	},
	{
		garage_id: 58,
		part_id: 371,
		quantity: 282,
	},
	{
		garage_id: 58,
		part_id: 372,
		quantity: 111,
	},
	{
		garage_id: 58,
		part_id: 373,
		quantity: 56,
	},
	{
		garage_id: 58,
		part_id: 374,
		quantity: 428,
	},
	{
		garage_id: 58,
		part_id: 375,
		quantity: 497,
	},
	{
		garage_id: 58,
		part_id: 376,
		quantity: 192,
	},
	{
		garage_id: 58,
		part_id: 377,
		quantity: 489,
	},
	{
		garage_id: 58,
		part_id: 378,
		quantity: 208,
	},
	{
		garage_id: 58,
		part_id: 379,
		quantity: 494,
	},
	{
		garage_id: 58,
		part_id: 380,
		quantity: 406,
	},
	{
		garage_id: 58,
		part_id: 381,
		quantity: 323,
	},
	{
		garage_id: 58,
		part_id: 382,
		quantity: 81,
	},
	{
		garage_id: 58,
		part_id: 383,
		quantity: 372,
	},
	{
		garage_id: 58,
		part_id: 384,
		quantity: 88,
	},
	{
		garage_id: 58,
		part_id: 385,
		quantity: 132,
	},
	{
		garage_id: 58,
		part_id: 386,
		quantity: 179,
	},
	{
		garage_id: 58,
		part_id: 387,
		quantity: 416,
	},
	{
		garage_id: 58,
		part_id: 388,
		quantity: 398,
	},
	{
		garage_id: 58,
		part_id: 389,
		quantity: 265,
	},
	{
		garage_id: 58,
		part_id: 390,
		quantity: 370,
	},
	{
		garage_id: 58,
		part_id: 391,
		quantity: 208,
	},
	{
		garage_id: 58,
		part_id: 392,
		quantity: 470,
	},
	{
		garage_id: 58,
		part_id: 393,
		quantity: 435,
	},
	{
		garage_id: 58,
		part_id: 394,
		quantity: 358,
	},
	{
		garage_id: 58,
		part_id: 395,
		quantity: 2,
	},
	{
		garage_id: 58,
		part_id: 396,
		quantity: 138,
	},
	{
		garage_id: 58,
		part_id: 397,
		quantity: 264,
	},
	{
		garage_id: 58,
		part_id: 398,
		quantity: 268,
	},
	{
		garage_id: 58,
		part_id: 399,
		quantity: 167,
	},
	{
		garage_id: 59,
		part_id: 0,
		quantity: 378,
	},
	{
		garage_id: 59,
		part_id: 1,
		quantity: 250,
	},
	{
		garage_id: 59,
		part_id: 2,
		quantity: 233,
	},
	{
		garage_id: 59,
		part_id: 3,
		quantity: 273,
	},
	{
		garage_id: 59,
		part_id: 4,
		quantity: 404,
	},
	{
		garage_id: 59,
		part_id: 5,
		quantity: 142,
	},
	{
		garage_id: 59,
		part_id: 6,
		quantity: 443,
	},
	{
		garage_id: 59,
		part_id: 7,
		quantity: 10,
	},
	{
		garage_id: 59,
		part_id: 8,
		quantity: 351,
	},
	{
		garage_id: 59,
		part_id: 9,
		quantity: 425,
	},
	{
		garage_id: 59,
		part_id: 10,
		quantity: 324,
	},
	{
		garage_id: 59,
		part_id: 11,
		quantity: 341,
	},
	{
		garage_id: 59,
		part_id: 12,
		quantity: 275,
	},
	{
		garage_id: 59,
		part_id: 13,
		quantity: 256,
	},
	{
		garage_id: 59,
		part_id: 14,
		quantity: 150,
	},
	{
		garage_id: 59,
		part_id: 15,
		quantity: 118,
	},
	{
		garage_id: 59,
		part_id: 16,
		quantity: 485,
	},
	{
		garage_id: 59,
		part_id: 17,
		quantity: 175,
	},
	{
		garage_id: 59,
		part_id: 18,
		quantity: 59,
	},
	{
		garage_id: 59,
		part_id: 19,
		quantity: 442,
	},
	{
		garage_id: 59,
		part_id: 20,
		quantity: 43,
	},
	{
		garage_id: 59,
		part_id: 21,
		quantity: 416,
	},
	{
		garage_id: 59,
		part_id: 22,
		quantity: 22,
	},
	{
		garage_id: 59,
		part_id: 23,
		quantity: 308,
	},
	{
		garage_id: 59,
		part_id: 24,
		quantity: 324,
	},
	{
		garage_id: 59,
		part_id: 25,
		quantity: 305,
	},
	{
		garage_id: 59,
		part_id: 26,
		quantity: 476,
	},
	{
		garage_id: 59,
		part_id: 27,
		quantity: 90,
	},
	{
		garage_id: 59,
		part_id: 28,
		quantity: 411,
	},
	{
		garage_id: 59,
		part_id: 29,
		quantity: 303,
	},
	{
		garage_id: 59,
		part_id: 30,
		quantity: 167,
	},
	{
		garage_id: 59,
		part_id: 31,
		quantity: 306,
	},
	{
		garage_id: 59,
		part_id: 32,
		quantity: 486,
	},
	{
		garage_id: 59,
		part_id: 33,
		quantity: 264,
	},
	{
		garage_id: 59,
		part_id: 34,
		quantity: 319,
	},
	{
		garage_id: 59,
		part_id: 35,
		quantity: 419,
	},
	{
		garage_id: 59,
		part_id: 36,
		quantity: 185,
	},
	{
		garage_id: 59,
		part_id: 37,
		quantity: 207,
	},
	{
		garage_id: 59,
		part_id: 38,
		quantity: 393,
	},
	{
		garage_id: 59,
		part_id: 39,
		quantity: 253,
	},
	{
		garage_id: 59,
		part_id: 40,
		quantity: 375,
	},
	{
		garage_id: 59,
		part_id: 41,
		quantity: 299,
	},
	{
		garage_id: 59,
		part_id: 42,
		quantity: 420,
	},
	{
		garage_id: 59,
		part_id: 43,
		quantity: 74,
	},
	{
		garage_id: 59,
		part_id: 44,
		quantity: 79,
	},
	{
		garage_id: 59,
		part_id: 45,
		quantity: 424,
	},
	{
		garage_id: 59,
		part_id: 46,
		quantity: 246,
	},
	{
		garage_id: 59,
		part_id: 47,
		quantity: 358,
	},
	{
		garage_id: 59,
		part_id: 48,
		quantity: 17,
	},
	{
		garage_id: 59,
		part_id: 49,
		quantity: 299,
	},
	{
		garage_id: 59,
		part_id: 50,
		quantity: 321,
	},
	{
		garage_id: 59,
		part_id: 51,
		quantity: 8,
	},
	{
		garage_id: 59,
		part_id: 52,
		quantity: 378,
	},
	{
		garage_id: 59,
		part_id: 53,
		quantity: 415,
	},
	{
		garage_id: 59,
		part_id: 54,
		quantity: 498,
	},
	{
		garage_id: 59,
		part_id: 55,
		quantity: 281,
	},
	{
		garage_id: 59,
		part_id: 56,
		quantity: 214,
	},
	{
		garage_id: 59,
		part_id: 57,
		quantity: 443,
	},
	{
		garage_id: 59,
		part_id: 58,
		quantity: 218,
	},
	{
		garage_id: 59,
		part_id: 59,
		quantity: 2,
	},
	{
		garage_id: 59,
		part_id: 60,
		quantity: 369,
	},
	{
		garage_id: 59,
		part_id: 61,
		quantity: 25,
	},
	{
		garage_id: 59,
		part_id: 62,
		quantity: 177,
	},
	{
		garage_id: 59,
		part_id: 63,
		quantity: 366,
	},
	{
		garage_id: 59,
		part_id: 64,
		quantity: 139,
	},
	{
		garage_id: 59,
		part_id: 65,
		quantity: 472,
	},
	{
		garage_id: 59,
		part_id: 66,
		quantity: 0,
	},
	{
		garage_id: 59,
		part_id: 67,
		quantity: 461,
	},
	{
		garage_id: 59,
		part_id: 68,
		quantity: 243,
	},
	{
		garage_id: 59,
		part_id: 69,
		quantity: 187,
	},
	{
		garage_id: 59,
		part_id: 70,
		quantity: 395,
	},
	{
		garage_id: 59,
		part_id: 71,
		quantity: 229,
	},
	{
		garage_id: 59,
		part_id: 72,
		quantity: 218,
	},
	{
		garage_id: 59,
		part_id: 73,
		quantity: 377,
	},
	{
		garage_id: 59,
		part_id: 74,
		quantity: 94,
	},
	{
		garage_id: 59,
		part_id: 75,
		quantity: 246,
	},
	{
		garage_id: 59,
		part_id: 76,
		quantity: 477,
	},
	{
		garage_id: 59,
		part_id: 77,
		quantity: 289,
	},
	{
		garage_id: 59,
		part_id: 78,
		quantity: 214,
	},
	{
		garage_id: 59,
		part_id: 79,
		quantity: 334,
	},
	{
		garage_id: 59,
		part_id: 80,
		quantity: 48,
	},
	{
		garage_id: 59,
		part_id: 81,
		quantity: 58,
	},
	{
		garage_id: 59,
		part_id: 82,
		quantity: 86,
	},
	{
		garage_id: 59,
		part_id: 83,
		quantity: 278,
	},
	{
		garage_id: 59,
		part_id: 84,
		quantity: 221,
	},
	{
		garage_id: 59,
		part_id: 85,
		quantity: 300,
	},
	{
		garage_id: 59,
		part_id: 86,
		quantity: 87,
	},
	{
		garage_id: 59,
		part_id: 87,
		quantity: 71,
	},
	{
		garage_id: 59,
		part_id: 88,
		quantity: 90,
	},
	{
		garage_id: 59,
		part_id: 89,
		quantity: 80,
	},
	{
		garage_id: 59,
		part_id: 90,
		quantity: 15,
	},
	{
		garage_id: 59,
		part_id: 91,
		quantity: 255,
	},
	{
		garage_id: 59,
		part_id: 92,
		quantity: 149,
	},
	{
		garage_id: 59,
		part_id: 93,
		quantity: 74,
	},
	{
		garage_id: 59,
		part_id: 94,
		quantity: 299,
	},
	{
		garage_id: 59,
		part_id: 95,
		quantity: 71,
	},
	{
		garage_id: 59,
		part_id: 96,
		quantity: 384,
	},
	{
		garage_id: 59,
		part_id: 97,
		quantity: 166,
	},
	{
		garage_id: 59,
		part_id: 98,
		quantity: 248,
	},
	{
		garage_id: 59,
		part_id: 99,
		quantity: 244,
	},
	{
		garage_id: 59,
		part_id: 100,
		quantity: 283,
	},
	{
		garage_id: 59,
		part_id: 101,
		quantity: 297,
	},
	{
		garage_id: 59,
		part_id: 102,
		quantity: 276,
	},
	{
		garage_id: 59,
		part_id: 103,
		quantity: 93,
	},
	{
		garage_id: 59,
		part_id: 104,
		quantity: 381,
	},
	{
		garage_id: 59,
		part_id: 105,
		quantity: 348,
	},
	{
		garage_id: 59,
		part_id: 106,
		quantity: 416,
	},
	{
		garage_id: 59,
		part_id: 107,
		quantity: 473,
	},
	{
		garage_id: 59,
		part_id: 108,
		quantity: 244,
	},
	{
		garage_id: 59,
		part_id: 109,
		quantity: 213,
	},
	{
		garage_id: 59,
		part_id: 110,
		quantity: 323,
	},
	{
		garage_id: 59,
		part_id: 111,
		quantity: 237,
	},
	{
		garage_id: 59,
		part_id: 112,
		quantity: 250,
	},
	{
		garage_id: 59,
		part_id: 113,
		quantity: 414,
	},
	{
		garage_id: 59,
		part_id: 114,
		quantity: 23,
	},
	{
		garage_id: 59,
		part_id: 115,
		quantity: 229,
	},
	{
		garage_id: 59,
		part_id: 116,
		quantity: 493,
	},
	{
		garage_id: 59,
		part_id: 117,
		quantity: 145,
	},
	{
		garage_id: 59,
		part_id: 118,
		quantity: 458,
	},
	{
		garage_id: 59,
		part_id: 119,
		quantity: 21,
	},
	{
		garage_id: 59,
		part_id: 120,
		quantity: 400,
	},
	{
		garage_id: 59,
		part_id: 121,
		quantity: 217,
	},
	{
		garage_id: 59,
		part_id: 122,
		quantity: 485,
	},
	{
		garage_id: 59,
		part_id: 123,
		quantity: 270,
	},
	{
		garage_id: 59,
		part_id: 124,
		quantity: 37,
	},
	{
		garage_id: 59,
		part_id: 125,
		quantity: 253,
	},
	{
		garage_id: 59,
		part_id: 126,
		quantity: 128,
	},
	{
		garage_id: 59,
		part_id: 127,
		quantity: 15,
	},
	{
		garage_id: 59,
		part_id: 128,
		quantity: 395,
	},
	{
		garage_id: 59,
		part_id: 129,
		quantity: 398,
	},
	{
		garage_id: 59,
		part_id: 130,
		quantity: 196,
	},
	{
		garage_id: 59,
		part_id: 131,
		quantity: 116,
	},
	{
		garage_id: 59,
		part_id: 132,
		quantity: 274,
	},
	{
		garage_id: 59,
		part_id: 133,
		quantity: 166,
	},
	{
		garage_id: 59,
		part_id: 134,
		quantity: 495,
	},
	{
		garage_id: 59,
		part_id: 135,
		quantity: 376,
	},
	{
		garage_id: 59,
		part_id: 136,
		quantity: 332,
	},
	{
		garage_id: 59,
		part_id: 137,
		quantity: 448,
	},
	{
		garage_id: 59,
		part_id: 138,
		quantity: 457,
	},
	{
		garage_id: 59,
		part_id: 139,
		quantity: 172,
	},
	{
		garage_id: 59,
		part_id: 140,
		quantity: 68,
	},
	{
		garage_id: 59,
		part_id: 141,
		quantity: 420,
	},
	{
		garage_id: 59,
		part_id: 142,
		quantity: 491,
	},
	{
		garage_id: 59,
		part_id: 143,
		quantity: 157,
	},
	{
		garage_id: 59,
		part_id: 144,
		quantity: 160,
	},
	{
		garage_id: 59,
		part_id: 145,
		quantity: 441,
	},
	{
		garage_id: 59,
		part_id: 146,
		quantity: 88,
	},
	{
		garage_id: 59,
		part_id: 147,
		quantity: 478,
	},
	{
		garage_id: 59,
		part_id: 148,
		quantity: 155,
	},
	{
		garage_id: 59,
		part_id: 149,
		quantity: 54,
	},
	{
		garage_id: 59,
		part_id: 150,
		quantity: 490,
	},
	{
		garage_id: 59,
		part_id: 151,
		quantity: 141,
	},
	{
		garage_id: 59,
		part_id: 152,
		quantity: 51,
	},
	{
		garage_id: 59,
		part_id: 153,
		quantity: 391,
	},
	{
		garage_id: 59,
		part_id: 154,
		quantity: 328,
	},
	{
		garage_id: 59,
		part_id: 155,
		quantity: 487,
	},
	{
		garage_id: 59,
		part_id: 156,
		quantity: 34,
	},
	{
		garage_id: 59,
		part_id: 157,
		quantity: 237,
	},
	{
		garage_id: 59,
		part_id: 158,
		quantity: 224,
	},
	{
		garage_id: 59,
		part_id: 159,
		quantity: 348,
	},
	{
		garage_id: 59,
		part_id: 160,
		quantity: 335,
	},
	{
		garage_id: 59,
		part_id: 161,
		quantity: 344,
	},
	{
		garage_id: 59,
		part_id: 162,
		quantity: 19,
	},
	{
		garage_id: 59,
		part_id: 163,
		quantity: 457,
	},
	{
		garage_id: 59,
		part_id: 164,
		quantity: 160,
	},
	{
		garage_id: 59,
		part_id: 165,
		quantity: 302,
	},
	{
		garage_id: 59,
		part_id: 166,
		quantity: 34,
	},
	{
		garage_id: 59,
		part_id: 167,
		quantity: 367,
	},
	{
		garage_id: 59,
		part_id: 168,
		quantity: 122,
	},
	{
		garage_id: 59,
		part_id: 169,
		quantity: 417,
	},
	{
		garage_id: 59,
		part_id: 170,
		quantity: 374,
	},
	{
		garage_id: 59,
		part_id: 171,
		quantity: 214,
	},
	{
		garage_id: 59,
		part_id: 172,
		quantity: 62,
	},
	{
		garage_id: 59,
		part_id: 173,
		quantity: 418,
	},
	{
		garage_id: 59,
		part_id: 174,
		quantity: 279,
	},
	{
		garage_id: 59,
		part_id: 175,
		quantity: 165,
	},
	{
		garage_id: 59,
		part_id: 176,
		quantity: 196,
	},
	{
		garage_id: 59,
		part_id: 177,
		quantity: 359,
	},
	{
		garage_id: 59,
		part_id: 178,
		quantity: 495,
	},
	{
		garage_id: 59,
		part_id: 179,
		quantity: 91,
	},
	{
		garage_id: 59,
		part_id: 180,
		quantity: 417,
	},
	{
		garage_id: 59,
		part_id: 181,
		quantity: 6,
	},
	{
		garage_id: 59,
		part_id: 182,
		quantity: 47,
	},
	{
		garage_id: 59,
		part_id: 183,
		quantity: 274,
	},
	{
		garage_id: 59,
		part_id: 184,
		quantity: 211,
	},
	{
		garage_id: 59,
		part_id: 185,
		quantity: 50,
	},
	{
		garage_id: 59,
		part_id: 186,
		quantity: 498,
	},
	{
		garage_id: 59,
		part_id: 187,
		quantity: 48,
	},
	{
		garage_id: 59,
		part_id: 188,
		quantity: 152,
	},
	{
		garage_id: 59,
		part_id: 189,
		quantity: 325,
	},
	{
		garage_id: 59,
		part_id: 190,
		quantity: 163,
	},
	{
		garage_id: 59,
		part_id: 191,
		quantity: 59,
	},
	{
		garage_id: 59,
		part_id: 192,
		quantity: 382,
	},
	{
		garage_id: 59,
		part_id: 193,
		quantity: 401,
	},
	{
		garage_id: 59,
		part_id: 194,
		quantity: 197,
	},
	{
		garage_id: 59,
		part_id: 195,
		quantity: 397,
	},
	{
		garage_id: 59,
		part_id: 196,
		quantity: 241,
	},
	{
		garage_id: 59,
		part_id: 197,
		quantity: 66,
	},
	{
		garage_id: 59,
		part_id: 198,
		quantity: 440,
	},
	{
		garage_id: 59,
		part_id: 199,
		quantity: 195,
	},
	{
		garage_id: 59,
		part_id: 200,
		quantity: 355,
	},
	{
		garage_id: 59,
		part_id: 201,
		quantity: 70,
	},
	{
		garage_id: 59,
		part_id: 202,
		quantity: 212,
	},
	{
		garage_id: 59,
		part_id: 203,
		quantity: 181,
	},
	{
		garage_id: 59,
		part_id: 204,
		quantity: 470,
	},
	{
		garage_id: 59,
		part_id: 205,
		quantity: 316,
	},
	{
		garage_id: 59,
		part_id: 206,
		quantity: 192,
	},
	{
		garage_id: 59,
		part_id: 207,
		quantity: 57,
	},
	{
		garage_id: 59,
		part_id: 208,
		quantity: 217,
	},
	{
		garage_id: 59,
		part_id: 209,
		quantity: 103,
	},
	{
		garage_id: 59,
		part_id: 210,
		quantity: 160,
	},
	{
		garage_id: 59,
		part_id: 211,
		quantity: 26,
	},
	{
		garage_id: 59,
		part_id: 212,
		quantity: 91,
	},
	{
		garage_id: 59,
		part_id: 213,
		quantity: 115,
	},
	{
		garage_id: 59,
		part_id: 214,
		quantity: 75,
	},
	{
		garage_id: 59,
		part_id: 215,
		quantity: 109,
	},
	{
		garage_id: 59,
		part_id: 216,
		quantity: 32,
	},
	{
		garage_id: 59,
		part_id: 217,
		quantity: 133,
	},
	{
		garage_id: 59,
		part_id: 218,
		quantity: 80,
	},
	{
		garage_id: 59,
		part_id: 219,
		quantity: 78,
	},
	{
		garage_id: 59,
		part_id: 220,
		quantity: 100,
	},
	{
		garage_id: 59,
		part_id: 221,
		quantity: 178,
	},
	{
		garage_id: 59,
		part_id: 222,
		quantity: 152,
	},
	{
		garage_id: 59,
		part_id: 223,
		quantity: 273,
	},
	{
		garage_id: 59,
		part_id: 224,
		quantity: 20,
	},
	{
		garage_id: 59,
		part_id: 225,
		quantity: 328,
	},
	{
		garage_id: 59,
		part_id: 226,
		quantity: 339,
	},
	{
		garage_id: 59,
		part_id: 227,
		quantity: 389,
	},
	{
		garage_id: 59,
		part_id: 228,
		quantity: 34,
	},
	{
		garage_id: 59,
		part_id: 229,
		quantity: 137,
	},
	{
		garage_id: 59,
		part_id: 230,
		quantity: 314,
	},
	{
		garage_id: 59,
		part_id: 231,
		quantity: 206,
	},
	{
		garage_id: 59,
		part_id: 232,
		quantity: 19,
	},
	{
		garage_id: 59,
		part_id: 233,
		quantity: 301,
	},
	{
		garage_id: 59,
		part_id: 234,
		quantity: 415,
	},
	{
		garage_id: 59,
		part_id: 235,
		quantity: 50,
	},
	{
		garage_id: 59,
		part_id: 236,
		quantity: 351,
	},
	{
		garage_id: 59,
		part_id: 237,
		quantity: 155,
	},
	{
		garage_id: 59,
		part_id: 238,
		quantity: 129,
	},
	{
		garage_id: 59,
		part_id: 239,
		quantity: 238,
	},
	{
		garage_id: 59,
		part_id: 240,
		quantity: 266,
	},
	{
		garage_id: 59,
		part_id: 241,
		quantity: 182,
	},
	{
		garage_id: 59,
		part_id: 242,
		quantity: 111,
	},
	{
		garage_id: 59,
		part_id: 243,
		quantity: 264,
	},
	{
		garage_id: 59,
		part_id: 244,
		quantity: 6,
	},
	{
		garage_id: 59,
		part_id: 245,
		quantity: 385,
	},
	{
		garage_id: 59,
		part_id: 246,
		quantity: 162,
	},
	{
		garage_id: 59,
		part_id: 247,
		quantity: 339,
	},
	{
		garage_id: 59,
		part_id: 248,
		quantity: 234,
	},
	{
		garage_id: 59,
		part_id: 249,
		quantity: 317,
	},
	{
		garage_id: 59,
		part_id: 250,
		quantity: 447,
	},
	{
		garage_id: 59,
		part_id: 251,
		quantity: 142,
	},
	{
		garage_id: 59,
		part_id: 252,
		quantity: 33,
	},
	{
		garage_id: 59,
		part_id: 253,
		quantity: 368,
	},
	{
		garage_id: 59,
		part_id: 254,
		quantity: 39,
	},
	{
		garage_id: 59,
		part_id: 255,
		quantity: 408,
	},
	{
		garage_id: 59,
		part_id: 256,
		quantity: 493,
	},
	{
		garage_id: 59,
		part_id: 257,
		quantity: 119,
	},
	{
		garage_id: 59,
		part_id: 258,
		quantity: 116,
	},
	{
		garage_id: 59,
		part_id: 259,
		quantity: 66,
	},
	{
		garage_id: 59,
		part_id: 260,
		quantity: 478,
	},
	{
		garage_id: 59,
		part_id: 261,
		quantity: 286,
	},
	{
		garage_id: 59,
		part_id: 262,
		quantity: 113,
	},
	{
		garage_id: 59,
		part_id: 263,
		quantity: 137,
	},
	{
		garage_id: 59,
		part_id: 264,
		quantity: 284,
	},
	{
		garage_id: 59,
		part_id: 265,
		quantity: 145,
	},
	{
		garage_id: 59,
		part_id: 266,
		quantity: 56,
	},
	{
		garage_id: 59,
		part_id: 267,
		quantity: 3,
	},
	{
		garage_id: 59,
		part_id: 268,
		quantity: 297,
	},
	{
		garage_id: 59,
		part_id: 269,
		quantity: 465,
	},
	{
		garage_id: 59,
		part_id: 270,
		quantity: 467,
	},
	{
		garage_id: 59,
		part_id: 271,
		quantity: 103,
	},
	{
		garage_id: 59,
		part_id: 272,
		quantity: 443,
	},
	{
		garage_id: 59,
		part_id: 273,
		quantity: 218,
	},
	{
		garage_id: 59,
		part_id: 274,
		quantity: 397,
	},
	{
		garage_id: 59,
		part_id: 275,
		quantity: 38,
	},
	{
		garage_id: 59,
		part_id: 276,
		quantity: 274,
	},
	{
		garage_id: 59,
		part_id: 277,
		quantity: 423,
	},
	{
		garage_id: 59,
		part_id: 278,
		quantity: 416,
	},
	{
		garage_id: 59,
		part_id: 279,
		quantity: 156,
	},
	{
		garage_id: 59,
		part_id: 280,
		quantity: 270,
	},
	{
		garage_id: 59,
		part_id: 281,
		quantity: 487,
	},
	{
		garage_id: 59,
		part_id: 282,
		quantity: 93,
	},
	{
		garage_id: 59,
		part_id: 283,
		quantity: 457,
	},
	{
		garage_id: 59,
		part_id: 284,
		quantity: 8,
	},
	{
		garage_id: 59,
		part_id: 285,
		quantity: 62,
	},
	{
		garage_id: 59,
		part_id: 286,
		quantity: 463,
	},
	{
		garage_id: 59,
		part_id: 287,
		quantity: 498,
	},
	{
		garage_id: 59,
		part_id: 288,
		quantity: 274,
	},
	{
		garage_id: 59,
		part_id: 289,
		quantity: 454,
	},
	{
		garage_id: 59,
		part_id: 290,
		quantity: 186,
	},
	{
		garage_id: 59,
		part_id: 291,
		quantity: 214,
	},
	{
		garage_id: 59,
		part_id: 292,
		quantity: 175,
	},
	{
		garage_id: 59,
		part_id: 293,
		quantity: 397,
	},
	{
		garage_id: 59,
		part_id: 294,
		quantity: 423,
	},
	{
		garage_id: 59,
		part_id: 295,
		quantity: 131,
	},
	{
		garage_id: 59,
		part_id: 296,
		quantity: 182,
	},
	{
		garage_id: 59,
		part_id: 297,
		quantity: 109,
	},
	{
		garage_id: 59,
		part_id: 298,
		quantity: 466,
	},
	{
		garage_id: 59,
		part_id: 299,
		quantity: 158,
	},
	{
		garage_id: 59,
		part_id: 300,
		quantity: 81,
	},
	{
		garage_id: 59,
		part_id: 301,
		quantity: 217,
	},
	{
		garage_id: 59,
		part_id: 302,
		quantity: 79,
	},
	{
		garage_id: 59,
		part_id: 303,
		quantity: 333,
	},
	{
		garage_id: 59,
		part_id: 304,
		quantity: 189,
	},
	{
		garage_id: 59,
		part_id: 305,
		quantity: 443,
	},
	{
		garage_id: 59,
		part_id: 306,
		quantity: 463,
	},
	{
		garage_id: 59,
		part_id: 307,
		quantity: 253,
	},
	{
		garage_id: 59,
		part_id: 308,
		quantity: 377,
	},
	{
		garage_id: 59,
		part_id: 309,
		quantity: 367,
	},
	{
		garage_id: 59,
		part_id: 310,
		quantity: 0,
	},
	{
		garage_id: 59,
		part_id: 311,
		quantity: 380,
	},
	{
		garage_id: 59,
		part_id: 312,
		quantity: 441,
	},
	{
		garage_id: 59,
		part_id: 313,
		quantity: 470,
	},
	{
		garage_id: 59,
		part_id: 314,
		quantity: 99,
	},
	{
		garage_id: 59,
		part_id: 315,
		quantity: 379,
	},
	{
		garage_id: 59,
		part_id: 316,
		quantity: 326,
	},
	{
		garage_id: 59,
		part_id: 317,
		quantity: 157,
	},
	{
		garage_id: 59,
		part_id: 318,
		quantity: 479,
	},
	{
		garage_id: 59,
		part_id: 319,
		quantity: 195,
	},
	{
		garage_id: 59,
		part_id: 320,
		quantity: 78,
	},
	{
		garage_id: 59,
		part_id: 321,
		quantity: 455,
	},
	{
		garage_id: 59,
		part_id: 322,
		quantity: 335,
	},
	{
		garage_id: 59,
		part_id: 323,
		quantity: 279,
	},
	{
		garage_id: 59,
		part_id: 324,
		quantity: 357,
	},
	{
		garage_id: 59,
		part_id: 325,
		quantity: 79,
	},
	{
		garage_id: 59,
		part_id: 326,
		quantity: 459,
	},
	{
		garage_id: 59,
		part_id: 327,
		quantity: 139,
	},
	{
		garage_id: 59,
		part_id: 328,
		quantity: 150,
	},
	{
		garage_id: 59,
		part_id: 329,
		quantity: 193,
	},
	{
		garage_id: 59,
		part_id: 330,
		quantity: 192,
	},
	{
		garage_id: 59,
		part_id: 331,
		quantity: 279,
	},
	{
		garage_id: 59,
		part_id: 332,
		quantity: 106,
	},
	{
		garage_id: 59,
		part_id: 333,
		quantity: 38,
	},
	{
		garage_id: 59,
		part_id: 334,
		quantity: 480,
	},
	{
		garage_id: 59,
		part_id: 335,
		quantity: 376,
	},
	{
		garage_id: 59,
		part_id: 336,
		quantity: 416,
	},
	{
		garage_id: 59,
		part_id: 337,
		quantity: 436,
	},
	{
		garage_id: 59,
		part_id: 338,
		quantity: 449,
	},
	{
		garage_id: 59,
		part_id: 339,
		quantity: 68,
	},
	{
		garage_id: 59,
		part_id: 340,
		quantity: 322,
	},
	{
		garage_id: 59,
		part_id: 341,
		quantity: 50,
	},
	{
		garage_id: 59,
		part_id: 342,
		quantity: 466,
	},
	{
		garage_id: 59,
		part_id: 343,
		quantity: 46,
	},
	{
		garage_id: 59,
		part_id: 344,
		quantity: 339,
	},
	{
		garage_id: 59,
		part_id: 345,
		quantity: 108,
	},
	{
		garage_id: 59,
		part_id: 346,
		quantity: 479,
	},
	{
		garage_id: 59,
		part_id: 347,
		quantity: 204,
	},
	{
		garage_id: 59,
		part_id: 348,
		quantity: 293,
	},
	{
		garage_id: 59,
		part_id: 349,
		quantity: 377,
	},
	{
		garage_id: 59,
		part_id: 350,
		quantity: 119,
	},
	{
		garage_id: 59,
		part_id: 351,
		quantity: 62,
	},
	{
		garage_id: 59,
		part_id: 352,
		quantity: 139,
	},
	{
		garage_id: 59,
		part_id: 353,
		quantity: 479,
	},
	{
		garage_id: 59,
		part_id: 354,
		quantity: 348,
	},
	{
		garage_id: 59,
		part_id: 355,
		quantity: 74,
	},
	{
		garage_id: 59,
		part_id: 356,
		quantity: 399,
	},
	{
		garage_id: 59,
		part_id: 357,
		quantity: 254,
	},
	{
		garage_id: 59,
		part_id: 358,
		quantity: 240,
	},
	{
		garage_id: 59,
		part_id: 359,
		quantity: 463,
	},
	{
		garage_id: 59,
		part_id: 360,
		quantity: 98,
	},
	{
		garage_id: 59,
		part_id: 361,
		quantity: 344,
	},
	{
		garage_id: 59,
		part_id: 362,
		quantity: 151,
	},
	{
		garage_id: 59,
		part_id: 363,
		quantity: 301,
	},
	{
		garage_id: 59,
		part_id: 364,
		quantity: 48,
	},
	{
		garage_id: 59,
		part_id: 365,
		quantity: 3,
	},
	{
		garage_id: 59,
		part_id: 366,
		quantity: 439,
	},
	{
		garage_id: 59,
		part_id: 367,
		quantity: 174,
	},
	{
		garage_id: 59,
		part_id: 368,
		quantity: 447,
	},
	{
		garage_id: 59,
		part_id: 369,
		quantity: 486,
	},
	{
		garage_id: 59,
		part_id: 370,
		quantity: 395,
	},
	{
		garage_id: 59,
		part_id: 371,
		quantity: 126,
	},
	{
		garage_id: 59,
		part_id: 372,
		quantity: 155,
	},
	{
		garage_id: 59,
		part_id: 373,
		quantity: 95,
	},
	{
		garage_id: 59,
		part_id: 374,
		quantity: 46,
	},
	{
		garage_id: 59,
		part_id: 375,
		quantity: 104,
	},
	{
		garage_id: 59,
		part_id: 376,
		quantity: 92,
	},
	{
		garage_id: 59,
		part_id: 377,
		quantity: 364,
	},
	{
		garage_id: 59,
		part_id: 378,
		quantity: 292,
	},
	{
		garage_id: 59,
		part_id: 379,
		quantity: 486,
	},
	{
		garage_id: 59,
		part_id: 380,
		quantity: 296,
	},
	{
		garage_id: 59,
		part_id: 381,
		quantity: 491,
	},
	{
		garage_id: 59,
		part_id: 382,
		quantity: 233,
	},
	{
		garage_id: 59,
		part_id: 383,
		quantity: 404,
	},
	{
		garage_id: 59,
		part_id: 384,
		quantity: 68,
	},
	{
		garage_id: 59,
		part_id: 385,
		quantity: 220,
	},
	{
		garage_id: 59,
		part_id: 386,
		quantity: 452,
	},
	{
		garage_id: 59,
		part_id: 387,
		quantity: 348,
	},
	{
		garage_id: 59,
		part_id: 388,
		quantity: 239,
	},
	{
		garage_id: 59,
		part_id: 389,
		quantity: 301,
	},
	{
		garage_id: 59,
		part_id: 390,
		quantity: 103,
	},
	{
		garage_id: 59,
		part_id: 391,
		quantity: 146,
	},
	{
		garage_id: 59,
		part_id: 392,
		quantity: 435,
	},
	{
		garage_id: 59,
		part_id: 393,
		quantity: 426,
	},
	{
		garage_id: 59,
		part_id: 394,
		quantity: 215,
	},
	{
		garage_id: 59,
		part_id: 395,
		quantity: 281,
	},
	{
		garage_id: 59,
		part_id: 396,
		quantity: 42,
	},
	{
		garage_id: 59,
		part_id: 397,
		quantity: 143,
	},
	{
		garage_id: 59,
		part_id: 398,
		quantity: 350,
	},
	{
		garage_id: 59,
		part_id: 399,
		quantity: 485,
	},
	{
		garage_id: 60,
		part_id: 0,
		quantity: 493,
	},
	{
		garage_id: 60,
		part_id: 1,
		quantity: 460,
	},
	{
		garage_id: 60,
		part_id: 2,
		quantity: 174,
	},
	{
		garage_id: 60,
		part_id: 3,
		quantity: 312,
	},
	{
		garage_id: 60,
		part_id: 4,
		quantity: 124,
	},
	{
		garage_id: 60,
		part_id: 5,
		quantity: 53,
	},
	{
		garage_id: 60,
		part_id: 6,
		quantity: 22,
	},
	{
		garage_id: 60,
		part_id: 7,
		quantity: 10,
	},
	{
		garage_id: 60,
		part_id: 8,
		quantity: 78,
	},
	{
		garage_id: 60,
		part_id: 9,
		quantity: 93,
	},
	{
		garage_id: 60,
		part_id: 10,
		quantity: 313,
	},
	{
		garage_id: 60,
		part_id: 11,
		quantity: 375,
	},
	{
		garage_id: 60,
		part_id: 12,
		quantity: 272,
	},
	{
		garage_id: 60,
		part_id: 13,
		quantity: 116,
	},
	{
		garage_id: 60,
		part_id: 14,
		quantity: 393,
	},
	{
		garage_id: 60,
		part_id: 15,
		quantity: 351,
	},
	{
		garage_id: 60,
		part_id: 16,
		quantity: 465,
	},
	{
		garage_id: 60,
		part_id: 17,
		quantity: 302,
	},
	{
		garage_id: 60,
		part_id: 18,
		quantity: 207,
	},
	{
		garage_id: 60,
		part_id: 19,
		quantity: 12,
	},
	{
		garage_id: 60,
		part_id: 20,
		quantity: 202,
	},
	{
		garage_id: 60,
		part_id: 21,
		quantity: 175,
	},
	{
		garage_id: 60,
		part_id: 22,
		quantity: 436,
	},
	{
		garage_id: 60,
		part_id: 23,
		quantity: 382,
	},
	{
		garage_id: 60,
		part_id: 24,
		quantity: 436,
	},
	{
		garage_id: 60,
		part_id: 25,
		quantity: 62,
	},
	{
		garage_id: 60,
		part_id: 26,
		quantity: 279,
	},
	{
		garage_id: 60,
		part_id: 27,
		quantity: 42,
	},
	{
		garage_id: 60,
		part_id: 28,
		quantity: 127,
	},
	{
		garage_id: 60,
		part_id: 29,
		quantity: 113,
	},
	{
		garage_id: 60,
		part_id: 30,
		quantity: 264,
	},
	{
		garage_id: 60,
		part_id: 31,
		quantity: 92,
	},
	{
		garage_id: 60,
		part_id: 32,
		quantity: 419,
	},
	{
		garage_id: 60,
		part_id: 33,
		quantity: 376,
	},
	{
		garage_id: 60,
		part_id: 34,
		quantity: 372,
	},
	{
		garage_id: 60,
		part_id: 35,
		quantity: 95,
	},
	{
		garage_id: 60,
		part_id: 36,
		quantity: 320,
	},
	{
		garage_id: 60,
		part_id: 37,
		quantity: 139,
	},
	{
		garage_id: 60,
		part_id: 38,
		quantity: 140,
	},
	{
		garage_id: 60,
		part_id: 39,
		quantity: 306,
	},
	{
		garage_id: 60,
		part_id: 40,
		quantity: 407,
	},
	{
		garage_id: 60,
		part_id: 41,
		quantity: 65,
	},
	{
		garage_id: 60,
		part_id: 42,
		quantity: 366,
	},
	{
		garage_id: 60,
		part_id: 43,
		quantity: 487,
	},
	{
		garage_id: 60,
		part_id: 44,
		quantity: 143,
	},
	{
		garage_id: 60,
		part_id: 45,
		quantity: 478,
	},
	{
		garage_id: 60,
		part_id: 46,
		quantity: 158,
	},
	{
		garage_id: 60,
		part_id: 47,
		quantity: 383,
	},
	{
		garage_id: 60,
		part_id: 48,
		quantity: 483,
	},
	{
		garage_id: 60,
		part_id: 49,
		quantity: 91,
	},
	{
		garage_id: 60,
		part_id: 50,
		quantity: 146,
	},
	{
		garage_id: 60,
		part_id: 51,
		quantity: 326,
	},
	{
		garage_id: 60,
		part_id: 52,
		quantity: 301,
	},
	{
		garage_id: 60,
		part_id: 53,
		quantity: 44,
	},
	{
		garage_id: 60,
		part_id: 54,
		quantity: 289,
	},
	{
		garage_id: 60,
		part_id: 55,
		quantity: 265,
	},
	{
		garage_id: 60,
		part_id: 56,
		quantity: 133,
	},
	{
		garage_id: 60,
		part_id: 57,
		quantity: 470,
	},
	{
		garage_id: 60,
		part_id: 58,
		quantity: 195,
	},
	{
		garage_id: 60,
		part_id: 59,
		quantity: 474,
	},
	{
		garage_id: 60,
		part_id: 60,
		quantity: 112,
	},
	{
		garage_id: 60,
		part_id: 61,
		quantity: 499,
	},
	{
		garage_id: 60,
		part_id: 62,
		quantity: 434,
	},
	{
		garage_id: 60,
		part_id: 63,
		quantity: 220,
	},
	{
		garage_id: 60,
		part_id: 64,
		quantity: 464,
	},
	{
		garage_id: 60,
		part_id: 65,
		quantity: 126,
	},
	{
		garage_id: 60,
		part_id: 66,
		quantity: 243,
	},
	{
		garage_id: 60,
		part_id: 67,
		quantity: 326,
	},
	{
		garage_id: 60,
		part_id: 68,
		quantity: 165,
	},
	{
		garage_id: 60,
		part_id: 69,
		quantity: 210,
	},
	{
		garage_id: 60,
		part_id: 70,
		quantity: 83,
	},
	{
		garage_id: 60,
		part_id: 71,
		quantity: 431,
	},
	{
		garage_id: 60,
		part_id: 72,
		quantity: 433,
	},
	{
		garage_id: 60,
		part_id: 73,
		quantity: 208,
	},
	{
		garage_id: 60,
		part_id: 74,
		quantity: 332,
	},
	{
		garage_id: 60,
		part_id: 75,
		quantity: 39,
	},
	{
		garage_id: 60,
		part_id: 76,
		quantity: 92,
	},
	{
		garage_id: 60,
		part_id: 77,
		quantity: 56,
	},
	{
		garage_id: 60,
		part_id: 78,
		quantity: 420,
	},
	{
		garage_id: 60,
		part_id: 79,
		quantity: 432,
	},
	{
		garage_id: 60,
		part_id: 80,
		quantity: 423,
	},
	{
		garage_id: 60,
		part_id: 81,
		quantity: 437,
	},
	{
		garage_id: 60,
		part_id: 82,
		quantity: 124,
	},
	{
		garage_id: 60,
		part_id: 83,
		quantity: 210,
	},
	{
		garage_id: 60,
		part_id: 84,
		quantity: 76,
	},
	{
		garage_id: 60,
		part_id: 85,
		quantity: 321,
	},
	{
		garage_id: 60,
		part_id: 86,
		quantity: 33,
	},
	{
		garage_id: 60,
		part_id: 87,
		quantity: 43,
	},
	{
		garage_id: 60,
		part_id: 88,
		quantity: 483,
	},
	{
		garage_id: 60,
		part_id: 89,
		quantity: 458,
	},
	{
		garage_id: 60,
		part_id: 90,
		quantity: 251,
	},
	{
		garage_id: 60,
		part_id: 91,
		quantity: 276,
	},
	{
		garage_id: 60,
		part_id: 92,
		quantity: 481,
	},
	{
		garage_id: 60,
		part_id: 93,
		quantity: 481,
	},
	{
		garage_id: 60,
		part_id: 94,
		quantity: 479,
	},
	{
		garage_id: 60,
		part_id: 95,
		quantity: 210,
	},
	{
		garage_id: 60,
		part_id: 96,
		quantity: 151,
	},
	{
		garage_id: 60,
		part_id: 97,
		quantity: 435,
	},
	{
		garage_id: 60,
		part_id: 98,
		quantity: 324,
	},
	{
		garage_id: 60,
		part_id: 99,
		quantity: 111,
	},
	{
		garage_id: 60,
		part_id: 100,
		quantity: 154,
	},
	{
		garage_id: 60,
		part_id: 101,
		quantity: 140,
	},
	{
		garage_id: 60,
		part_id: 102,
		quantity: 240,
	},
	{
		garage_id: 60,
		part_id: 103,
		quantity: 378,
	},
	{
		garage_id: 60,
		part_id: 104,
		quantity: 233,
	},
	{
		garage_id: 60,
		part_id: 105,
		quantity: 77,
	},
	{
		garage_id: 60,
		part_id: 106,
		quantity: 485,
	},
	{
		garage_id: 60,
		part_id: 107,
		quantity: 283,
	},
	{
		garage_id: 60,
		part_id: 108,
		quantity: 323,
	},
	{
		garage_id: 60,
		part_id: 109,
		quantity: 238,
	},
	{
		garage_id: 60,
		part_id: 110,
		quantity: 491,
	},
	{
		garage_id: 60,
		part_id: 111,
		quantity: 346,
	},
	{
		garage_id: 60,
		part_id: 112,
		quantity: 364,
	},
	{
		garage_id: 60,
		part_id: 113,
		quantity: 480,
	},
	{
		garage_id: 60,
		part_id: 114,
		quantity: 291,
	},
	{
		garage_id: 60,
		part_id: 115,
		quantity: 457,
	},
	{
		garage_id: 60,
		part_id: 116,
		quantity: 354,
	},
	{
		garage_id: 60,
		part_id: 117,
		quantity: 417,
	},
	{
		garage_id: 60,
		part_id: 118,
		quantity: 167,
	},
	{
		garage_id: 60,
		part_id: 119,
		quantity: 320,
	},
	{
		garage_id: 60,
		part_id: 120,
		quantity: 75,
	},
	{
		garage_id: 60,
		part_id: 121,
		quantity: 361,
	},
	{
		garage_id: 60,
		part_id: 122,
		quantity: 72,
	},
	{
		garage_id: 60,
		part_id: 123,
		quantity: 283,
	},
	{
		garage_id: 60,
		part_id: 124,
		quantity: 238,
	},
	{
		garage_id: 60,
		part_id: 125,
		quantity: 114,
	},
	{
		garage_id: 60,
		part_id: 126,
		quantity: 440,
	},
	{
		garage_id: 60,
		part_id: 127,
		quantity: 487,
	},
	{
		garage_id: 60,
		part_id: 128,
		quantity: 195,
	},
	{
		garage_id: 60,
		part_id: 129,
		quantity: 100,
	},
	{
		garage_id: 60,
		part_id: 130,
		quantity: 486,
	},
	{
		garage_id: 60,
		part_id: 131,
		quantity: 363,
	},
	{
		garage_id: 60,
		part_id: 132,
		quantity: 429,
	},
	{
		garage_id: 60,
		part_id: 133,
		quantity: 224,
	},
	{
		garage_id: 60,
		part_id: 134,
		quantity: 297,
	},
	{
		garage_id: 60,
		part_id: 135,
		quantity: 68,
	},
	{
		garage_id: 60,
		part_id: 136,
		quantity: 406,
	},
	{
		garage_id: 60,
		part_id: 137,
		quantity: 410,
	},
	{
		garage_id: 60,
		part_id: 138,
		quantity: 481,
	},
	{
		garage_id: 60,
		part_id: 139,
		quantity: 345,
	},
	{
		garage_id: 60,
		part_id: 140,
		quantity: 115,
	},
	{
		garage_id: 60,
		part_id: 141,
		quantity: 474,
	},
	{
		garage_id: 60,
		part_id: 142,
		quantity: 6,
	},
	{
		garage_id: 60,
		part_id: 143,
		quantity: 377,
	},
	{
		garage_id: 60,
		part_id: 144,
		quantity: 63,
	},
	{
		garage_id: 60,
		part_id: 145,
		quantity: 111,
	},
	{
		garage_id: 60,
		part_id: 146,
		quantity: 404,
	},
	{
		garage_id: 60,
		part_id: 147,
		quantity: 231,
	},
	{
		garage_id: 60,
		part_id: 148,
		quantity: 223,
	},
	{
		garage_id: 60,
		part_id: 149,
		quantity: 279,
	},
	{
		garage_id: 60,
		part_id: 150,
		quantity: 278,
	},
	{
		garage_id: 60,
		part_id: 151,
		quantity: 186,
	},
	{
		garage_id: 60,
		part_id: 152,
		quantity: 284,
	},
	{
		garage_id: 60,
		part_id: 153,
		quantity: 481,
	},
	{
		garage_id: 60,
		part_id: 154,
		quantity: 64,
	},
	{
		garage_id: 60,
		part_id: 155,
		quantity: 77,
	},
	{
		garage_id: 60,
		part_id: 156,
		quantity: 431,
	},
	{
		garage_id: 60,
		part_id: 157,
		quantity: 434,
	},
	{
		garage_id: 60,
		part_id: 158,
		quantity: 450,
	},
	{
		garage_id: 60,
		part_id: 159,
		quantity: 85,
	},
	{
		garage_id: 60,
		part_id: 160,
		quantity: 182,
	},
	{
		garage_id: 60,
		part_id: 161,
		quantity: 117,
	},
	{
		garage_id: 60,
		part_id: 162,
		quantity: 145,
	},
	{
		garage_id: 60,
		part_id: 163,
		quantity: 72,
	},
	{
		garage_id: 60,
		part_id: 164,
		quantity: 102,
	},
	{
		garage_id: 60,
		part_id: 165,
		quantity: 202,
	},
	{
		garage_id: 60,
		part_id: 166,
		quantity: 418,
	},
	{
		garage_id: 60,
		part_id: 167,
		quantity: 158,
	},
	{
		garage_id: 60,
		part_id: 168,
		quantity: 421,
	},
	{
		garage_id: 60,
		part_id: 169,
		quantity: 151,
	},
	{
		garage_id: 60,
		part_id: 170,
		quantity: 214,
	},
	{
		garage_id: 60,
		part_id: 171,
		quantity: 497,
	},
	{
		garage_id: 60,
		part_id: 172,
		quantity: 393,
	},
	{
		garage_id: 60,
		part_id: 173,
		quantity: 14,
	},
	{
		garage_id: 60,
		part_id: 174,
		quantity: 330,
	},
	{
		garage_id: 60,
		part_id: 175,
		quantity: 347,
	},
	{
		garage_id: 60,
		part_id: 176,
		quantity: 373,
	},
	{
		garage_id: 60,
		part_id: 177,
		quantity: 338,
	},
	{
		garage_id: 60,
		part_id: 178,
		quantity: 39,
	},
	{
		garage_id: 60,
		part_id: 179,
		quantity: 40,
	},
	{
		garage_id: 60,
		part_id: 180,
		quantity: 127,
	},
	{
		garage_id: 60,
		part_id: 181,
		quantity: 320,
	},
	{
		garage_id: 60,
		part_id: 182,
		quantity: 68,
	},
	{
		garage_id: 60,
		part_id: 183,
		quantity: 490,
	},
	{
		garage_id: 60,
		part_id: 184,
		quantity: 127,
	},
	{
		garage_id: 60,
		part_id: 185,
		quantity: 166,
	},
	{
		garage_id: 60,
		part_id: 186,
		quantity: 429,
	},
	{
		garage_id: 60,
		part_id: 187,
		quantity: 425,
	},
	{
		garage_id: 60,
		part_id: 188,
		quantity: 10,
	},
	{
		garage_id: 60,
		part_id: 189,
		quantity: 89,
	},
	{
		garage_id: 60,
		part_id: 190,
		quantity: 494,
	},
	{
		garage_id: 60,
		part_id: 191,
		quantity: 46,
	},
	{
		garage_id: 60,
		part_id: 192,
		quantity: 110,
	},
	{
		garage_id: 60,
		part_id: 193,
		quantity: 177,
	},
	{
		garage_id: 60,
		part_id: 194,
		quantity: 50,
	},
	{
		garage_id: 60,
		part_id: 195,
		quantity: 69,
	},
	{
		garage_id: 60,
		part_id: 196,
		quantity: 154,
	},
	{
		garage_id: 60,
		part_id: 197,
		quantity: 269,
	},
	{
		garage_id: 60,
		part_id: 198,
		quantity: 342,
	},
	{
		garage_id: 60,
		part_id: 199,
		quantity: 442,
	},
	{
		garage_id: 60,
		part_id: 200,
		quantity: 176,
	},
	{
		garage_id: 60,
		part_id: 201,
		quantity: 446,
	},
	{
		garage_id: 60,
		part_id: 202,
		quantity: 455,
	},
	{
		garage_id: 60,
		part_id: 203,
		quantity: 313,
	},
	{
		garage_id: 60,
		part_id: 204,
		quantity: 396,
	},
	{
		garage_id: 60,
		part_id: 205,
		quantity: 310,
	},
	{
		garage_id: 60,
		part_id: 206,
		quantity: 373,
	},
	{
		garage_id: 60,
		part_id: 207,
		quantity: 26,
	},
	{
		garage_id: 60,
		part_id: 208,
		quantity: 89,
	},
	{
		garage_id: 60,
		part_id: 209,
		quantity: 453,
	},
	{
		garage_id: 60,
		part_id: 210,
		quantity: 233,
	},
	{
		garage_id: 60,
		part_id: 211,
		quantity: 411,
	},
	{
		garage_id: 60,
		part_id: 212,
		quantity: 320,
	},
	{
		garage_id: 60,
		part_id: 213,
		quantity: 117,
	},
	{
		garage_id: 60,
		part_id: 214,
		quantity: 339,
	},
	{
		garage_id: 60,
		part_id: 215,
		quantity: 208,
	},
	{
		garage_id: 60,
		part_id: 216,
		quantity: 231,
	},
	{
		garage_id: 60,
		part_id: 217,
		quantity: 213,
	},
	{
		garage_id: 60,
		part_id: 218,
		quantity: 483,
	},
	{
		garage_id: 60,
		part_id: 219,
		quantity: 254,
	},
	{
		garage_id: 60,
		part_id: 220,
		quantity: 411,
	},
	{
		garage_id: 60,
		part_id: 221,
		quantity: 15,
	},
	{
		garage_id: 60,
		part_id: 222,
		quantity: 453,
	},
	{
		garage_id: 60,
		part_id: 223,
		quantity: 274,
	},
	{
		garage_id: 60,
		part_id: 224,
		quantity: 327,
	},
	{
		garage_id: 60,
		part_id: 225,
		quantity: 210,
	},
	{
		garage_id: 60,
		part_id: 226,
		quantity: 484,
	},
	{
		garage_id: 60,
		part_id: 227,
		quantity: 291,
	},
	{
		garage_id: 60,
		part_id: 228,
		quantity: 477,
	},
	{
		garage_id: 60,
		part_id: 229,
		quantity: 335,
	},
	{
		garage_id: 60,
		part_id: 230,
		quantity: 154,
	},
	{
		garage_id: 60,
		part_id: 231,
		quantity: 90,
	},
	{
		garage_id: 60,
		part_id: 232,
		quantity: 17,
	},
	{
		garage_id: 60,
		part_id: 233,
		quantity: 197,
	},
	{
		garage_id: 60,
		part_id: 234,
		quantity: 277,
	},
	{
		garage_id: 60,
		part_id: 235,
		quantity: 379,
	},
	{
		garage_id: 60,
		part_id: 236,
		quantity: 31,
	},
	{
		garage_id: 60,
		part_id: 237,
		quantity: 70,
	},
	{
		garage_id: 60,
		part_id: 238,
		quantity: 328,
	},
	{
		garage_id: 60,
		part_id: 239,
		quantity: 262,
	},
	{
		garage_id: 60,
		part_id: 240,
		quantity: 72,
	},
	{
		garage_id: 60,
		part_id: 241,
		quantity: 264,
	},
	{
		garage_id: 60,
		part_id: 242,
		quantity: 246,
	},
	{
		garage_id: 60,
		part_id: 243,
		quantity: 46,
	},
	{
		garage_id: 60,
		part_id: 244,
		quantity: 9,
	},
	{
		garage_id: 60,
		part_id: 245,
		quantity: 167,
	},
	{
		garage_id: 60,
		part_id: 246,
		quantity: 119,
	},
	{
		garage_id: 60,
		part_id: 247,
		quantity: 2,
	},
	{
		garage_id: 60,
		part_id: 248,
		quantity: 105,
	},
	{
		garage_id: 60,
		part_id: 249,
		quantity: 489,
	},
	{
		garage_id: 60,
		part_id: 250,
		quantity: 375,
	},
	{
		garage_id: 60,
		part_id: 251,
		quantity: 46,
	},
	{
		garage_id: 60,
		part_id: 252,
		quantity: 198,
	},
	{
		garage_id: 60,
		part_id: 253,
		quantity: 171,
	},
	{
		garage_id: 60,
		part_id: 254,
		quantity: 437,
	},
	{
		garage_id: 60,
		part_id: 255,
		quantity: 127,
	},
	{
		garage_id: 60,
		part_id: 256,
		quantity: 317,
	},
	{
		garage_id: 60,
		part_id: 257,
		quantity: 99,
	},
	{
		garage_id: 60,
		part_id: 258,
		quantity: 340,
	},
	{
		garage_id: 60,
		part_id: 259,
		quantity: 124,
	},
	{
		garage_id: 60,
		part_id: 260,
		quantity: 499,
	},
	{
		garage_id: 60,
		part_id: 261,
		quantity: 387,
	},
	{
		garage_id: 60,
		part_id: 262,
		quantity: 40,
	},
	{
		garage_id: 60,
		part_id: 263,
		quantity: 392,
	},
	{
		garage_id: 60,
		part_id: 264,
		quantity: 88,
	},
	{
		garage_id: 60,
		part_id: 265,
		quantity: 44,
	},
	{
		garage_id: 60,
		part_id: 266,
		quantity: 212,
	},
	{
		garage_id: 60,
		part_id: 267,
		quantity: 351,
	},
	{
		garage_id: 60,
		part_id: 268,
		quantity: 302,
	},
	{
		garage_id: 60,
		part_id: 269,
		quantity: 34,
	},
	{
		garage_id: 60,
		part_id: 270,
		quantity: 110,
	},
	{
		garage_id: 60,
		part_id: 271,
		quantity: 334,
	},
	{
		garage_id: 60,
		part_id: 272,
		quantity: 262,
	},
	{
		garage_id: 60,
		part_id: 273,
		quantity: 429,
	},
	{
		garage_id: 60,
		part_id: 274,
		quantity: 271,
	},
	{
		garage_id: 60,
		part_id: 275,
		quantity: 338,
	},
	{
		garage_id: 60,
		part_id: 276,
		quantity: 352,
	},
	{
		garage_id: 60,
		part_id: 277,
		quantity: 183,
	},
	{
		garage_id: 60,
		part_id: 278,
		quantity: 100,
	},
	{
		garage_id: 60,
		part_id: 279,
		quantity: 95,
	},
	{
		garage_id: 60,
		part_id: 280,
		quantity: 388,
	},
	{
		garage_id: 60,
		part_id: 281,
		quantity: 10,
	},
	{
		garage_id: 60,
		part_id: 282,
		quantity: 259,
	},
	{
		garage_id: 60,
		part_id: 283,
		quantity: 265,
	},
	{
		garage_id: 60,
		part_id: 284,
		quantity: 267,
	},
	{
		garage_id: 60,
		part_id: 285,
		quantity: 136,
	},
	{
		garage_id: 60,
		part_id: 286,
		quantity: 186,
	},
	{
		garage_id: 60,
		part_id: 287,
		quantity: 255,
	},
	{
		garage_id: 60,
		part_id: 288,
		quantity: 316,
	},
	{
		garage_id: 60,
		part_id: 289,
		quantity: 440,
	},
	{
		garage_id: 60,
		part_id: 290,
		quantity: 146,
	},
	{
		garage_id: 60,
		part_id: 291,
		quantity: 252,
	},
	{
		garage_id: 60,
		part_id: 292,
		quantity: 27,
	},
	{
		garage_id: 60,
		part_id: 293,
		quantity: 199,
	},
	{
		garage_id: 60,
		part_id: 294,
		quantity: 225,
	},
	{
		garage_id: 60,
		part_id: 295,
		quantity: 2,
	},
	{
		garage_id: 60,
		part_id: 296,
		quantity: 177,
	},
	{
		garage_id: 60,
		part_id: 297,
		quantity: 0,
	},
	{
		garage_id: 60,
		part_id: 298,
		quantity: 7,
	},
	{
		garage_id: 60,
		part_id: 299,
		quantity: 196,
	},
	{
		garage_id: 60,
		part_id: 300,
		quantity: 282,
	},
	{
		garage_id: 60,
		part_id: 301,
		quantity: 411,
	},
	{
		garage_id: 60,
		part_id: 302,
		quantity: 202,
	},
	{
		garage_id: 60,
		part_id: 303,
		quantity: 357,
	},
	{
		garage_id: 60,
		part_id: 304,
		quantity: 390,
	},
	{
		garage_id: 60,
		part_id: 305,
		quantity: 89,
	},
	{
		garage_id: 60,
		part_id: 306,
		quantity: 229,
	},
	{
		garage_id: 60,
		part_id: 307,
		quantity: 112,
	},
	{
		garage_id: 60,
		part_id: 308,
		quantity: 254,
	},
	{
		garage_id: 60,
		part_id: 309,
		quantity: 168,
	},
	{
		garage_id: 60,
		part_id: 310,
		quantity: 345,
	},
	{
		garage_id: 60,
		part_id: 311,
		quantity: 350,
	},
	{
		garage_id: 60,
		part_id: 312,
		quantity: 69,
	},
	{
		garage_id: 60,
		part_id: 313,
		quantity: 0,
	},
	{
		garage_id: 60,
		part_id: 314,
		quantity: 478,
	},
	{
		garage_id: 60,
		part_id: 315,
		quantity: 377,
	},
	{
		garage_id: 60,
		part_id: 316,
		quantity: 459,
	},
	{
		garage_id: 60,
		part_id: 317,
		quantity: 431,
	},
	{
		garage_id: 60,
		part_id: 318,
		quantity: 302,
	},
	{
		garage_id: 60,
		part_id: 319,
		quantity: 25,
	},
	{
		garage_id: 60,
		part_id: 320,
		quantity: 212,
	},
	{
		garage_id: 60,
		part_id: 321,
		quantity: 409,
	},
	{
		garage_id: 60,
		part_id: 322,
		quantity: 132,
	},
	{
		garage_id: 60,
		part_id: 323,
		quantity: 39,
	},
	{
		garage_id: 60,
		part_id: 324,
		quantity: 123,
	},
	{
		garage_id: 60,
		part_id: 325,
		quantity: 332,
	},
	{
		garage_id: 60,
		part_id: 326,
		quantity: 192,
	},
	{
		garage_id: 60,
		part_id: 327,
		quantity: 250,
	},
	{
		garage_id: 60,
		part_id: 328,
		quantity: 497,
	},
	{
		garage_id: 60,
		part_id: 329,
		quantity: 331,
	},
	{
		garage_id: 60,
		part_id: 330,
		quantity: 289,
	},
	{
		garage_id: 60,
		part_id: 331,
		quantity: 325,
	},
	{
		garage_id: 60,
		part_id: 332,
		quantity: 475,
	},
	{
		garage_id: 60,
		part_id: 333,
		quantity: 456,
	},
	{
		garage_id: 60,
		part_id: 334,
		quantity: 414,
	},
	{
		garage_id: 60,
		part_id: 335,
		quantity: 245,
	},
	{
		garage_id: 60,
		part_id: 336,
		quantity: 169,
	},
	{
		garage_id: 60,
		part_id: 337,
		quantity: 410,
	},
	{
		garage_id: 60,
		part_id: 338,
		quantity: 155,
	},
	{
		garage_id: 60,
		part_id: 339,
		quantity: 29,
	},
	{
		garage_id: 60,
		part_id: 340,
		quantity: 443,
	},
	{
		garage_id: 60,
		part_id: 341,
		quantity: 182,
	},
	{
		garage_id: 60,
		part_id: 342,
		quantity: 98,
	},
	{
		garage_id: 60,
		part_id: 343,
		quantity: 42,
	},
	{
		garage_id: 60,
		part_id: 344,
		quantity: 312,
	},
	{
		garage_id: 60,
		part_id: 345,
		quantity: 136,
	},
	{
		garage_id: 60,
		part_id: 346,
		quantity: 19,
	},
	{
		garage_id: 60,
		part_id: 347,
		quantity: 315,
	},
	{
		garage_id: 60,
		part_id: 348,
		quantity: 194,
	},
	{
		garage_id: 60,
		part_id: 349,
		quantity: 236,
	},
	{
		garage_id: 60,
		part_id: 350,
		quantity: 303,
	},
	{
		garage_id: 60,
		part_id: 351,
		quantity: 458,
	},
	{
		garage_id: 60,
		part_id: 352,
		quantity: 139,
	},
	{
		garage_id: 60,
		part_id: 353,
		quantity: 308,
	},
	{
		garage_id: 60,
		part_id: 354,
		quantity: 411,
	},
	{
		garage_id: 60,
		part_id: 355,
		quantity: 336,
	},
	{
		garage_id: 60,
		part_id: 356,
		quantity: 449,
	},
	{
		garage_id: 60,
		part_id: 357,
		quantity: 186,
	},
	{
		garage_id: 60,
		part_id: 358,
		quantity: 302,
	},
	{
		garage_id: 60,
		part_id: 359,
		quantity: 464,
	},
	{
		garage_id: 60,
		part_id: 360,
		quantity: 112,
	},
	{
		garage_id: 60,
		part_id: 361,
		quantity: 206,
	},
	{
		garage_id: 60,
		part_id: 362,
		quantity: 260,
	},
	{
		garage_id: 60,
		part_id: 363,
		quantity: 377,
	},
	{
		garage_id: 60,
		part_id: 364,
		quantity: 72,
	},
	{
		garage_id: 60,
		part_id: 365,
		quantity: 143,
	},
	{
		garage_id: 60,
		part_id: 366,
		quantity: 328,
	},
	{
		garage_id: 60,
		part_id: 367,
		quantity: 482,
	},
	{
		garage_id: 60,
		part_id: 368,
		quantity: 120,
	},
	{
		garage_id: 60,
		part_id: 369,
		quantity: 147,
	},
	{
		garage_id: 60,
		part_id: 370,
		quantity: 433,
	},
	{
		garage_id: 60,
		part_id: 371,
		quantity: 481,
	},
	{
		garage_id: 60,
		part_id: 372,
		quantity: 97,
	},
	{
		garage_id: 60,
		part_id: 373,
		quantity: 52,
	},
	{
		garage_id: 60,
		part_id: 374,
		quantity: 271,
	},
	{
		garage_id: 60,
		part_id: 375,
		quantity: 5,
	},
	{
		garage_id: 60,
		part_id: 376,
		quantity: 91,
	},
	{
		garage_id: 60,
		part_id: 377,
		quantity: 36,
	},
	{
		garage_id: 60,
		part_id: 378,
		quantity: 362,
	},
	{
		garage_id: 60,
		part_id: 379,
		quantity: 399,
	},
	{
		garage_id: 60,
		part_id: 380,
		quantity: 413,
	},
	{
		garage_id: 60,
		part_id: 381,
		quantity: 443,
	},
	{
		garage_id: 60,
		part_id: 382,
		quantity: 425,
	},
	{
		garage_id: 60,
		part_id: 383,
		quantity: 348,
	},
	{
		garage_id: 60,
		part_id: 384,
		quantity: 98,
	},
	{
		garage_id: 60,
		part_id: 385,
		quantity: 11,
	},
	{
		garage_id: 60,
		part_id: 386,
		quantity: 463,
	},
	{
		garage_id: 60,
		part_id: 387,
		quantity: 337,
	},
	{
		garage_id: 60,
		part_id: 388,
		quantity: 298,
	},
	{
		garage_id: 60,
		part_id: 389,
		quantity: 75,
	},
	{
		garage_id: 60,
		part_id: 390,
		quantity: 164,
	},
	{
		garage_id: 60,
		part_id: 391,
		quantity: 220,
	},
	{
		garage_id: 60,
		part_id: 392,
		quantity: 223,
	},
	{
		garage_id: 60,
		part_id: 393,
		quantity: 468,
	},
	{
		garage_id: 60,
		part_id: 394,
		quantity: 455,
	},
	{
		garage_id: 60,
		part_id: 395,
		quantity: 439,
	},
	{
		garage_id: 60,
		part_id: 396,
		quantity: 327,
	},
	{
		garage_id: 60,
		part_id: 397,
		quantity: 197,
	},
	{
		garage_id: 60,
		part_id: 398,
		quantity: 362,
	},
	{
		garage_id: 60,
		part_id: 399,
		quantity: 122,
	},
	{
		garage_id: 61,
		part_id: 0,
		quantity: 48,
	},
	{
		garage_id: 61,
		part_id: 1,
		quantity: 255,
	},
	{
		garage_id: 61,
		part_id: 2,
		quantity: 343,
	},
	{
		garage_id: 61,
		part_id: 3,
		quantity: 500,
	},
	{
		garage_id: 61,
		part_id: 4,
		quantity: 408,
	},
	{
		garage_id: 61,
		part_id: 5,
		quantity: 142,
	},
	{
		garage_id: 61,
		part_id: 6,
		quantity: 131,
	},
	{
		garage_id: 61,
		part_id: 7,
		quantity: 123,
	},
	{
		garage_id: 61,
		part_id: 8,
		quantity: 284,
	},
	{
		garage_id: 61,
		part_id: 9,
		quantity: 345,
	},
	{
		garage_id: 61,
		part_id: 10,
		quantity: 496,
	},
	{
		garage_id: 61,
		part_id: 11,
		quantity: 219,
	},
	{
		garage_id: 61,
		part_id: 12,
		quantity: 23,
	},
	{
		garage_id: 61,
		part_id: 13,
		quantity: 246,
	},
	{
		garage_id: 61,
		part_id: 14,
		quantity: 447,
	},
	{
		garage_id: 61,
		part_id: 15,
		quantity: 105,
	},
	{
		garage_id: 61,
		part_id: 16,
		quantity: 21,
	},
	{
		garage_id: 61,
		part_id: 17,
		quantity: 98,
	},
	{
		garage_id: 61,
		part_id: 18,
		quantity: 58,
	},
	{
		garage_id: 61,
		part_id: 19,
		quantity: 462,
	},
	{
		garage_id: 61,
		part_id: 20,
		quantity: 431,
	},
	{
		garage_id: 61,
		part_id: 21,
		quantity: 222,
	},
	{
		garage_id: 61,
		part_id: 22,
		quantity: 118,
	},
	{
		garage_id: 61,
		part_id: 23,
		quantity: 456,
	},
	{
		garage_id: 61,
		part_id: 24,
		quantity: 289,
	},
	{
		garage_id: 61,
		part_id: 25,
		quantity: 392,
	},
	{
		garage_id: 61,
		part_id: 26,
		quantity: 185,
	},
	{
		garage_id: 61,
		part_id: 27,
		quantity: 301,
	},
	{
		garage_id: 61,
		part_id: 28,
		quantity: 59,
	},
	{
		garage_id: 61,
		part_id: 29,
		quantity: 55,
	},
	{
		garage_id: 61,
		part_id: 30,
		quantity: 458,
	},
	{
		garage_id: 61,
		part_id: 31,
		quantity: 256,
	},
	{
		garage_id: 61,
		part_id: 32,
		quantity: 319,
	},
	{
		garage_id: 61,
		part_id: 33,
		quantity: 289,
	},
	{
		garage_id: 61,
		part_id: 34,
		quantity: 462,
	},
	{
		garage_id: 61,
		part_id: 35,
		quantity: 197,
	},
	{
		garage_id: 61,
		part_id: 36,
		quantity: 44,
	},
	{
		garage_id: 61,
		part_id: 37,
		quantity: 270,
	},
	{
		garage_id: 61,
		part_id: 38,
		quantity: 155,
	},
	{
		garage_id: 61,
		part_id: 39,
		quantity: 278,
	},
	{
		garage_id: 61,
		part_id: 40,
		quantity: 99,
	},
	{
		garage_id: 61,
		part_id: 41,
		quantity: 107,
	},
	{
		garage_id: 61,
		part_id: 42,
		quantity: 86,
	},
	{
		garage_id: 61,
		part_id: 43,
		quantity: 255,
	},
	{
		garage_id: 61,
		part_id: 44,
		quantity: 301,
	},
	{
		garage_id: 61,
		part_id: 45,
		quantity: 104,
	},
	{
		garage_id: 61,
		part_id: 46,
		quantity: 469,
	},
	{
		garage_id: 61,
		part_id: 47,
		quantity: 23,
	},
	{
		garage_id: 61,
		part_id: 48,
		quantity: 251,
	},
	{
		garage_id: 61,
		part_id: 49,
		quantity: 277,
	},
	{
		garage_id: 61,
		part_id: 50,
		quantity: 97,
	},
	{
		garage_id: 61,
		part_id: 51,
		quantity: 22,
	},
	{
		garage_id: 61,
		part_id: 52,
		quantity: 25,
	},
	{
		garage_id: 61,
		part_id: 53,
		quantity: 223,
	},
	{
		garage_id: 61,
		part_id: 54,
		quantity: 205,
	},
	{
		garage_id: 61,
		part_id: 55,
		quantity: 182,
	},
	{
		garage_id: 61,
		part_id: 56,
		quantity: 416,
	},
	{
		garage_id: 61,
		part_id: 57,
		quantity: 416,
	},
	{
		garage_id: 61,
		part_id: 58,
		quantity: 497,
	},
	{
		garage_id: 61,
		part_id: 59,
		quantity: 404,
	},
	{
		garage_id: 61,
		part_id: 60,
		quantity: 98,
	},
	{
		garage_id: 61,
		part_id: 61,
		quantity: 112,
	},
	{
		garage_id: 61,
		part_id: 62,
		quantity: 188,
	},
	{
		garage_id: 61,
		part_id: 63,
		quantity: 199,
	},
	{
		garage_id: 61,
		part_id: 64,
		quantity: 339,
	},
	{
		garage_id: 61,
		part_id: 65,
		quantity: 243,
	},
	{
		garage_id: 61,
		part_id: 66,
		quantity: 74,
	},
	{
		garage_id: 61,
		part_id: 67,
		quantity: 80,
	},
	{
		garage_id: 61,
		part_id: 68,
		quantity: 149,
	},
	{
		garage_id: 61,
		part_id: 69,
		quantity: 110,
	},
	{
		garage_id: 61,
		part_id: 70,
		quantity: 273,
	},
	{
		garage_id: 61,
		part_id: 71,
		quantity: 440,
	},
	{
		garage_id: 61,
		part_id: 72,
		quantity: 105,
	},
	{
		garage_id: 61,
		part_id: 73,
		quantity: 318,
	},
	{
		garage_id: 61,
		part_id: 74,
		quantity: 260,
	},
	{
		garage_id: 61,
		part_id: 75,
		quantity: 481,
	},
	{
		garage_id: 61,
		part_id: 76,
		quantity: 284,
	},
	{
		garage_id: 61,
		part_id: 77,
		quantity: 296,
	},
	{
		garage_id: 61,
		part_id: 78,
		quantity: 85,
	},
	{
		garage_id: 61,
		part_id: 79,
		quantity: 487,
	},
	{
		garage_id: 61,
		part_id: 80,
		quantity: 132,
	},
	{
		garage_id: 61,
		part_id: 81,
		quantity: 27,
	},
	{
		garage_id: 61,
		part_id: 82,
		quantity: 469,
	},
	{
		garage_id: 61,
		part_id: 83,
		quantity: 292,
	},
	{
		garage_id: 61,
		part_id: 84,
		quantity: 61,
	},
	{
		garage_id: 61,
		part_id: 85,
		quantity: 62,
	},
	{
		garage_id: 61,
		part_id: 86,
		quantity: 50,
	},
	{
		garage_id: 61,
		part_id: 87,
		quantity: 381,
	},
	{
		garage_id: 61,
		part_id: 88,
		quantity: 206,
	},
	{
		garage_id: 61,
		part_id: 89,
		quantity: 7,
	},
	{
		garage_id: 61,
		part_id: 90,
		quantity: 316,
	},
	{
		garage_id: 61,
		part_id: 91,
		quantity: 55,
	},
	{
		garage_id: 61,
		part_id: 92,
		quantity: 452,
	},
	{
		garage_id: 61,
		part_id: 93,
		quantity: 150,
	},
	{
		garage_id: 61,
		part_id: 94,
		quantity: 177,
	},
	{
		garage_id: 61,
		part_id: 95,
		quantity: 466,
	},
	{
		garage_id: 61,
		part_id: 96,
		quantity: 416,
	},
	{
		garage_id: 61,
		part_id: 97,
		quantity: 106,
	},
	{
		garage_id: 61,
		part_id: 98,
		quantity: 6,
	},
	{
		garage_id: 61,
		part_id: 99,
		quantity: 465,
	},
	{
		garage_id: 61,
		part_id: 100,
		quantity: 317,
	},
	{
		garage_id: 61,
		part_id: 101,
		quantity: 296,
	},
	{
		garage_id: 61,
		part_id: 102,
		quantity: 205,
	},
	{
		garage_id: 61,
		part_id: 103,
		quantity: 179,
	},
	{
		garage_id: 61,
		part_id: 104,
		quantity: 134,
	},
	{
		garage_id: 61,
		part_id: 105,
		quantity: 98,
	},
	{
		garage_id: 61,
		part_id: 106,
		quantity: 48,
	},
	{
		garage_id: 61,
		part_id: 107,
		quantity: 370,
	},
	{
		garage_id: 61,
		part_id: 108,
		quantity: 231,
	},
	{
		garage_id: 61,
		part_id: 109,
		quantity: 163,
	},
	{
		garage_id: 61,
		part_id: 110,
		quantity: 119,
	},
	{
		garage_id: 61,
		part_id: 111,
		quantity: 359,
	},
	{
		garage_id: 61,
		part_id: 112,
		quantity: 490,
	},
	{
		garage_id: 61,
		part_id: 113,
		quantity: 20,
	},
	{
		garage_id: 61,
		part_id: 114,
		quantity: 172,
	},
	{
		garage_id: 61,
		part_id: 115,
		quantity: 325,
	},
	{
		garage_id: 61,
		part_id: 116,
		quantity: 253,
	},
	{
		garage_id: 61,
		part_id: 117,
		quantity: 252,
	},
	{
		garage_id: 61,
		part_id: 118,
		quantity: 195,
	},
	{
		garage_id: 61,
		part_id: 119,
		quantity: 308,
	},
	{
		garage_id: 61,
		part_id: 120,
		quantity: 282,
	},
	{
		garage_id: 61,
		part_id: 121,
		quantity: 190,
	},
	{
		garage_id: 61,
		part_id: 122,
		quantity: 479,
	},
	{
		garage_id: 61,
		part_id: 123,
		quantity: 486,
	},
	{
		garage_id: 61,
		part_id: 124,
		quantity: 16,
	},
	{
		garage_id: 61,
		part_id: 125,
		quantity: 434,
	},
	{
		garage_id: 61,
		part_id: 126,
		quantity: 370,
	},
	{
		garage_id: 61,
		part_id: 127,
		quantity: 334,
	},
	{
		garage_id: 61,
		part_id: 128,
		quantity: 24,
	},
	{
		garage_id: 61,
		part_id: 129,
		quantity: 352,
	},
	{
		garage_id: 61,
		part_id: 130,
		quantity: 495,
	},
	{
		garage_id: 61,
		part_id: 131,
		quantity: 14,
	},
	{
		garage_id: 61,
		part_id: 132,
		quantity: 235,
	},
	{
		garage_id: 61,
		part_id: 133,
		quantity: 103,
	},
	{
		garage_id: 61,
		part_id: 134,
		quantity: 182,
	},
	{
		garage_id: 61,
		part_id: 135,
		quantity: 382,
	},
	{
		garage_id: 61,
		part_id: 136,
		quantity: 450,
	},
	{
		garage_id: 61,
		part_id: 137,
		quantity: 399,
	},
	{
		garage_id: 61,
		part_id: 138,
		quantity: 316,
	},
	{
		garage_id: 61,
		part_id: 139,
		quantity: 12,
	},
	{
		garage_id: 61,
		part_id: 140,
		quantity: 12,
	},
	{
		garage_id: 61,
		part_id: 141,
		quantity: 241,
	},
	{
		garage_id: 61,
		part_id: 142,
		quantity: 155,
	},
	{
		garage_id: 61,
		part_id: 143,
		quantity: 27,
	},
	{
		garage_id: 61,
		part_id: 144,
		quantity: 399,
	},
	{
		garage_id: 61,
		part_id: 145,
		quantity: 59,
	},
	{
		garage_id: 61,
		part_id: 146,
		quantity: 326,
	},
	{
		garage_id: 61,
		part_id: 147,
		quantity: 80,
	},
	{
		garage_id: 61,
		part_id: 148,
		quantity: 116,
	},
	{
		garage_id: 61,
		part_id: 149,
		quantity: 217,
	},
	{
		garage_id: 61,
		part_id: 150,
		quantity: 196,
	},
	{
		garage_id: 61,
		part_id: 151,
		quantity: 452,
	},
	{
		garage_id: 61,
		part_id: 152,
		quantity: 194,
	},
	{
		garage_id: 61,
		part_id: 153,
		quantity: 488,
	},
	{
		garage_id: 61,
		part_id: 154,
		quantity: 432,
	},
	{
		garage_id: 61,
		part_id: 155,
		quantity: 154,
	},
	{
		garage_id: 61,
		part_id: 156,
		quantity: 435,
	},
	{
		garage_id: 61,
		part_id: 157,
		quantity: 387,
	},
	{
		garage_id: 61,
		part_id: 158,
		quantity: 478,
	},
	{
		garage_id: 61,
		part_id: 159,
		quantity: 297,
	},
	{
		garage_id: 61,
		part_id: 160,
		quantity: 79,
	},
	{
		garage_id: 61,
		part_id: 161,
		quantity: 232,
	},
	{
		garage_id: 61,
		part_id: 162,
		quantity: 174,
	},
	{
		garage_id: 61,
		part_id: 163,
		quantity: 421,
	},
	{
		garage_id: 61,
		part_id: 164,
		quantity: 387,
	},
	{
		garage_id: 61,
		part_id: 165,
		quantity: 471,
	},
	{
		garage_id: 61,
		part_id: 166,
		quantity: 269,
	},
	{
		garage_id: 61,
		part_id: 167,
		quantity: 88,
	},
	{
		garage_id: 61,
		part_id: 168,
		quantity: 23,
	},
	{
		garage_id: 61,
		part_id: 169,
		quantity: 491,
	},
	{
		garage_id: 61,
		part_id: 170,
		quantity: 387,
	},
	{
		garage_id: 61,
		part_id: 171,
		quantity: 211,
	},
	{
		garage_id: 61,
		part_id: 172,
		quantity: 124,
	},
	{
		garage_id: 61,
		part_id: 173,
		quantity: 196,
	},
	{
		garage_id: 61,
		part_id: 174,
		quantity: 214,
	},
	{
		garage_id: 61,
		part_id: 175,
		quantity: 40,
	},
	{
		garage_id: 61,
		part_id: 176,
		quantity: 494,
	},
	{
		garage_id: 61,
		part_id: 177,
		quantity: 254,
	},
	{
		garage_id: 61,
		part_id: 178,
		quantity: 39,
	},
	{
		garage_id: 61,
		part_id: 179,
		quantity: 259,
	},
	{
		garage_id: 61,
		part_id: 180,
		quantity: 229,
	},
	{
		garage_id: 61,
		part_id: 181,
		quantity: 441,
	},
	{
		garage_id: 61,
		part_id: 182,
		quantity: 262,
	},
	{
		garage_id: 61,
		part_id: 183,
		quantity: 292,
	},
	{
		garage_id: 61,
		part_id: 184,
		quantity: 134,
	},
	{
		garage_id: 61,
		part_id: 185,
		quantity: 196,
	},
	{
		garage_id: 61,
		part_id: 186,
		quantity: 177,
	},
	{
		garage_id: 61,
		part_id: 187,
		quantity: 87,
	},
	{
		garage_id: 61,
		part_id: 188,
		quantity: 294,
	},
	{
		garage_id: 61,
		part_id: 189,
		quantity: 449,
	},
	{
		garage_id: 61,
		part_id: 190,
		quantity: 302,
	},
	{
		garage_id: 61,
		part_id: 191,
		quantity: 10,
	},
	{
		garage_id: 61,
		part_id: 192,
		quantity: 152,
	},
	{
		garage_id: 61,
		part_id: 193,
		quantity: 445,
	},
	{
		garage_id: 61,
		part_id: 194,
		quantity: 405,
	},
	{
		garage_id: 61,
		part_id: 195,
		quantity: 498,
	},
	{
		garage_id: 61,
		part_id: 196,
		quantity: 108,
	},
	{
		garage_id: 61,
		part_id: 197,
		quantity: 217,
	},
	{
		garage_id: 61,
		part_id: 198,
		quantity: 363,
	},
	{
		garage_id: 61,
		part_id: 199,
		quantity: 403,
	},
	{
		garage_id: 61,
		part_id: 200,
		quantity: 226,
	},
	{
		garage_id: 61,
		part_id: 201,
		quantity: 284,
	},
	{
		garage_id: 61,
		part_id: 202,
		quantity: 371,
	},
	{
		garage_id: 61,
		part_id: 203,
		quantity: 69,
	},
	{
		garage_id: 61,
		part_id: 204,
		quantity: 29,
	},
	{
		garage_id: 61,
		part_id: 205,
		quantity: 202,
	},
	{
		garage_id: 61,
		part_id: 206,
		quantity: 42,
	},
	{
		garage_id: 61,
		part_id: 207,
		quantity: 230,
	},
	{
		garage_id: 61,
		part_id: 208,
		quantity: 431,
	},
	{
		garage_id: 61,
		part_id: 209,
		quantity: 165,
	},
	{
		garage_id: 61,
		part_id: 210,
		quantity: 462,
	},
	{
		garage_id: 61,
		part_id: 211,
		quantity: 206,
	},
	{
		garage_id: 61,
		part_id: 212,
		quantity: 331,
	},
	{
		garage_id: 61,
		part_id: 213,
		quantity: 292,
	},
	{
		garage_id: 61,
		part_id: 214,
		quantity: 270,
	},
	{
		garage_id: 61,
		part_id: 215,
		quantity: 237,
	},
	{
		garage_id: 61,
		part_id: 216,
		quantity: 268,
	},
	{
		garage_id: 61,
		part_id: 217,
		quantity: 214,
	},
	{
		garage_id: 61,
		part_id: 218,
		quantity: 442,
	},
	{
		garage_id: 61,
		part_id: 219,
		quantity: 391,
	},
	{
		garage_id: 61,
		part_id: 220,
		quantity: 463,
	},
	{
		garage_id: 61,
		part_id: 221,
		quantity: 370,
	},
	{
		garage_id: 61,
		part_id: 222,
		quantity: 102,
	},
	{
		garage_id: 61,
		part_id: 223,
		quantity: 205,
	},
	{
		garage_id: 61,
		part_id: 224,
		quantity: 225,
	},
	{
		garage_id: 61,
		part_id: 225,
		quantity: 495,
	},
	{
		garage_id: 61,
		part_id: 226,
		quantity: 22,
	},
	{
		garage_id: 61,
		part_id: 227,
		quantity: 232,
	},
	{
		garage_id: 61,
		part_id: 228,
		quantity: 76,
	},
	{
		garage_id: 61,
		part_id: 229,
		quantity: 159,
	},
	{
		garage_id: 61,
		part_id: 230,
		quantity: 60,
	},
	{
		garage_id: 61,
		part_id: 231,
		quantity: 97,
	},
	{
		garage_id: 61,
		part_id: 232,
		quantity: 332,
	},
	{
		garage_id: 61,
		part_id: 233,
		quantity: 430,
	},
	{
		garage_id: 61,
		part_id: 234,
		quantity: 96,
	},
	{
		garage_id: 61,
		part_id: 235,
		quantity: 154,
	},
	{
		garage_id: 61,
		part_id: 236,
		quantity: 339,
	},
	{
		garage_id: 61,
		part_id: 237,
		quantity: 131,
	},
	{
		garage_id: 61,
		part_id: 238,
		quantity: 332,
	},
	{
		garage_id: 61,
		part_id: 239,
		quantity: 175,
	},
	{
		garage_id: 61,
		part_id: 240,
		quantity: 102,
	},
	{
		garage_id: 61,
		part_id: 241,
		quantity: 420,
	},
	{
		garage_id: 61,
		part_id: 242,
		quantity: 240,
	},
	{
		garage_id: 61,
		part_id: 243,
		quantity: 211,
	},
	{
		garage_id: 61,
		part_id: 244,
		quantity: 28,
	},
	{
		garage_id: 61,
		part_id: 245,
		quantity: 241,
	},
	{
		garage_id: 61,
		part_id: 246,
		quantity: 241,
	},
	{
		garage_id: 61,
		part_id: 247,
		quantity: 168,
	},
	{
		garage_id: 61,
		part_id: 248,
		quantity: 163,
	},
	{
		garage_id: 61,
		part_id: 249,
		quantity: 403,
	},
	{
		garage_id: 61,
		part_id: 250,
		quantity: 217,
	},
	{
		garage_id: 61,
		part_id: 251,
		quantity: 463,
	},
	{
		garage_id: 61,
		part_id: 252,
		quantity: 76,
	},
	{
		garage_id: 61,
		part_id: 253,
		quantity: 88,
	},
	{
		garage_id: 61,
		part_id: 254,
		quantity: 442,
	},
	{
		garage_id: 61,
		part_id: 255,
		quantity: 319,
	},
	{
		garage_id: 61,
		part_id: 256,
		quantity: 90,
	},
	{
		garage_id: 61,
		part_id: 257,
		quantity: 106,
	},
	{
		garage_id: 61,
		part_id: 258,
		quantity: 243,
	},
	{
		garage_id: 61,
		part_id: 259,
		quantity: 187,
	},
	{
		garage_id: 61,
		part_id: 260,
		quantity: 496,
	},
	{
		garage_id: 61,
		part_id: 261,
		quantity: 332,
	},
	{
		garage_id: 61,
		part_id: 262,
		quantity: 87,
	},
	{
		garage_id: 61,
		part_id: 263,
		quantity: 483,
	},
	{
		garage_id: 61,
		part_id: 264,
		quantity: 227,
	},
	{
		garage_id: 61,
		part_id: 265,
		quantity: 493,
	},
	{
		garage_id: 61,
		part_id: 266,
		quantity: 243,
	},
	{
		garage_id: 61,
		part_id: 267,
		quantity: 314,
	},
	{
		garage_id: 61,
		part_id: 268,
		quantity: 227,
	},
	{
		garage_id: 61,
		part_id: 269,
		quantity: 388,
	},
	{
		garage_id: 61,
		part_id: 270,
		quantity: 387,
	},
	{
		garage_id: 61,
		part_id: 271,
		quantity: 406,
	},
	{
		garage_id: 61,
		part_id: 272,
		quantity: 402,
	},
	{
		garage_id: 61,
		part_id: 273,
		quantity: 161,
	},
	{
		garage_id: 61,
		part_id: 274,
		quantity: 268,
	},
	{
		garage_id: 61,
		part_id: 275,
		quantity: 264,
	},
	{
		garage_id: 61,
		part_id: 276,
		quantity: 240,
	},
	{
		garage_id: 61,
		part_id: 277,
		quantity: 3,
	},
	{
		garage_id: 61,
		part_id: 278,
		quantity: 221,
	},
	{
		garage_id: 61,
		part_id: 279,
		quantity: 144,
	},
	{
		garage_id: 61,
		part_id: 280,
		quantity: 398,
	},
	{
		garage_id: 61,
		part_id: 281,
		quantity: 159,
	},
	{
		garage_id: 61,
		part_id: 282,
		quantity: 308,
	},
	{
		garage_id: 61,
		part_id: 283,
		quantity: 37,
	},
	{
		garage_id: 61,
		part_id: 284,
		quantity: 380,
	},
	{
		garage_id: 61,
		part_id: 285,
		quantity: 364,
	},
	{
		garage_id: 61,
		part_id: 286,
		quantity: 118,
	},
	{
		garage_id: 61,
		part_id: 287,
		quantity: 84,
	},
	{
		garage_id: 61,
		part_id: 288,
		quantity: 27,
	},
	{
		garage_id: 61,
		part_id: 289,
		quantity: 402,
	},
	{
		garage_id: 61,
		part_id: 290,
		quantity: 414,
	},
	{
		garage_id: 61,
		part_id: 291,
		quantity: 492,
	},
	{
		garage_id: 61,
		part_id: 292,
		quantity: 492,
	},
	{
		garage_id: 61,
		part_id: 293,
		quantity: 336,
	},
	{
		garage_id: 61,
		part_id: 294,
		quantity: 430,
	},
	{
		garage_id: 61,
		part_id: 295,
		quantity: 69,
	},
	{
		garage_id: 61,
		part_id: 296,
		quantity: 372,
	},
	{
		garage_id: 61,
		part_id: 297,
		quantity: 112,
	},
	{
		garage_id: 61,
		part_id: 298,
		quantity: 250,
	},
	{
		garage_id: 61,
		part_id: 299,
		quantity: 363,
	},
	{
		garage_id: 61,
		part_id: 300,
		quantity: 359,
	},
	{
		garage_id: 61,
		part_id: 301,
		quantity: 429,
	},
	{
		garage_id: 61,
		part_id: 302,
		quantity: 164,
	},
	{
		garage_id: 61,
		part_id: 303,
		quantity: 327,
	},
	{
		garage_id: 61,
		part_id: 304,
		quantity: 189,
	},
	{
		garage_id: 61,
		part_id: 305,
		quantity: 432,
	},
	{
		garage_id: 61,
		part_id: 306,
		quantity: 268,
	},
	{
		garage_id: 61,
		part_id: 307,
		quantity: 229,
	},
	{
		garage_id: 61,
		part_id: 308,
		quantity: 200,
	},
	{
		garage_id: 61,
		part_id: 309,
		quantity: 389,
	},
	{
		garage_id: 61,
		part_id: 310,
		quantity: 379,
	},
	{
		garage_id: 61,
		part_id: 311,
		quantity: 234,
	},
	{
		garage_id: 61,
		part_id: 312,
		quantity: 197,
	},
	{
		garage_id: 61,
		part_id: 313,
		quantity: 6,
	},
	{
		garage_id: 61,
		part_id: 314,
		quantity: 58,
	},
	{
		garage_id: 61,
		part_id: 315,
		quantity: 228,
	},
	{
		garage_id: 61,
		part_id: 316,
		quantity: 451,
	},
	{
		garage_id: 61,
		part_id: 317,
		quantity: 87,
	},
	{
		garage_id: 61,
		part_id: 318,
		quantity: 320,
	},
	{
		garage_id: 61,
		part_id: 319,
		quantity: 358,
	},
	{
		garage_id: 61,
		part_id: 320,
		quantity: 371,
	},
	{
		garage_id: 61,
		part_id: 321,
		quantity: 37,
	},
	{
		garage_id: 61,
		part_id: 322,
		quantity: 314,
	},
	{
		garage_id: 61,
		part_id: 323,
		quantity: 278,
	},
	{
		garage_id: 61,
		part_id: 324,
		quantity: 78,
	},
	{
		garage_id: 61,
		part_id: 325,
		quantity: 36,
	},
	{
		garage_id: 61,
		part_id: 326,
		quantity: 291,
	},
	{
		garage_id: 61,
		part_id: 327,
		quantity: 256,
	},
	{
		garage_id: 61,
		part_id: 328,
		quantity: 104,
	},
	{
		garage_id: 61,
		part_id: 329,
		quantity: 485,
	},
	{
		garage_id: 61,
		part_id: 330,
		quantity: 314,
	},
	{
		garage_id: 61,
		part_id: 331,
		quantity: 223,
	},
	{
		garage_id: 61,
		part_id: 332,
		quantity: 404,
	},
	{
		garage_id: 61,
		part_id: 333,
		quantity: 65,
	},
	{
		garage_id: 61,
		part_id: 334,
		quantity: 434,
	},
	{
		garage_id: 61,
		part_id: 335,
		quantity: 485,
	},
	{
		garage_id: 61,
		part_id: 336,
		quantity: 44,
	},
	{
		garage_id: 61,
		part_id: 337,
		quantity: 496,
	},
	{
		garage_id: 61,
		part_id: 338,
		quantity: 222,
	},
	{
		garage_id: 61,
		part_id: 339,
		quantity: 174,
	},
	{
		garage_id: 61,
		part_id: 340,
		quantity: 458,
	},
	{
		garage_id: 61,
		part_id: 341,
		quantity: 364,
	},
	{
		garage_id: 61,
		part_id: 342,
		quantity: 255,
	},
	{
		garage_id: 61,
		part_id: 343,
		quantity: 438,
	},
	{
		garage_id: 61,
		part_id: 344,
		quantity: 212,
	},
	{
		garage_id: 61,
		part_id: 345,
		quantity: 257,
	},
	{
		garage_id: 61,
		part_id: 346,
		quantity: 369,
	},
	{
		garage_id: 61,
		part_id: 347,
		quantity: 264,
	},
	{
		garage_id: 61,
		part_id: 348,
		quantity: 318,
	},
	{
		garage_id: 61,
		part_id: 349,
		quantity: 261,
	},
	{
		garage_id: 61,
		part_id: 350,
		quantity: 455,
	},
	{
		garage_id: 61,
		part_id: 351,
		quantity: 286,
	},
	{
		garage_id: 61,
		part_id: 352,
		quantity: 381,
	},
	{
		garage_id: 61,
		part_id: 353,
		quantity: 338,
	},
	{
		garage_id: 61,
		part_id: 354,
		quantity: 377,
	},
	{
		garage_id: 61,
		part_id: 355,
		quantity: 107,
	},
	{
		garage_id: 61,
		part_id: 356,
		quantity: 348,
	},
	{
		garage_id: 61,
		part_id: 357,
		quantity: 342,
	},
	{
		garage_id: 61,
		part_id: 358,
		quantity: 410,
	},
	{
		garage_id: 61,
		part_id: 359,
		quantity: 112,
	},
	{
		garage_id: 61,
		part_id: 360,
		quantity: 434,
	},
	{
		garage_id: 61,
		part_id: 361,
		quantity: 229,
	},
	{
		garage_id: 61,
		part_id: 362,
		quantity: 183,
	},
	{
		garage_id: 61,
		part_id: 363,
		quantity: 229,
	},
	{
		garage_id: 61,
		part_id: 364,
		quantity: 383,
	},
	{
		garage_id: 61,
		part_id: 365,
		quantity: 202,
	},
	{
		garage_id: 61,
		part_id: 366,
		quantity: 320,
	},
	{
		garage_id: 61,
		part_id: 367,
		quantity: 40,
	},
	{
		garage_id: 61,
		part_id: 368,
		quantity: 55,
	},
	{
		garage_id: 61,
		part_id: 369,
		quantity: 269,
	},
	{
		garage_id: 61,
		part_id: 370,
		quantity: 88,
	},
	{
		garage_id: 61,
		part_id: 371,
		quantity: 320,
	},
	{
		garage_id: 61,
		part_id: 372,
		quantity: 41,
	},
	{
		garage_id: 61,
		part_id: 373,
		quantity: 347,
	},
	{
		garage_id: 61,
		part_id: 374,
		quantity: 442,
	},
	{
		garage_id: 61,
		part_id: 375,
		quantity: 473,
	},
	{
		garage_id: 61,
		part_id: 376,
		quantity: 3,
	},
	{
		garage_id: 61,
		part_id: 377,
		quantity: 341,
	},
	{
		garage_id: 61,
		part_id: 378,
		quantity: 171,
	},
	{
		garage_id: 61,
		part_id: 379,
		quantity: 315,
	},
	{
		garage_id: 61,
		part_id: 380,
		quantity: 254,
	},
	{
		garage_id: 61,
		part_id: 381,
		quantity: 490,
	},
	{
		garage_id: 61,
		part_id: 382,
		quantity: 259,
	},
	{
		garage_id: 61,
		part_id: 383,
		quantity: 35,
	},
	{
		garage_id: 61,
		part_id: 384,
		quantity: 161,
	},
	{
		garage_id: 61,
		part_id: 385,
		quantity: 420,
	},
	{
		garage_id: 61,
		part_id: 386,
		quantity: 418,
	},
	{
		garage_id: 61,
		part_id: 387,
		quantity: 455,
	},
	{
		garage_id: 61,
		part_id: 388,
		quantity: 113,
	},
	{
		garage_id: 61,
		part_id: 389,
		quantity: 423,
	},
	{
		garage_id: 61,
		part_id: 390,
		quantity: 260,
	},
	{
		garage_id: 61,
		part_id: 391,
		quantity: 372,
	},
	{
		garage_id: 61,
		part_id: 392,
		quantity: 385,
	},
	{
		garage_id: 61,
		part_id: 393,
		quantity: 380,
	},
	{
		garage_id: 61,
		part_id: 394,
		quantity: 86,
	},
	{
		garage_id: 61,
		part_id: 395,
		quantity: 190,
	},
	{
		garage_id: 61,
		part_id: 396,
		quantity: 423,
	},
	{
		garage_id: 61,
		part_id: 397,
		quantity: 497,
	},
	{
		garage_id: 61,
		part_id: 398,
		quantity: 451,
	},
	{
		garage_id: 61,
		part_id: 399,
		quantity: 161,
	},
	{
		garage_id: 62,
		part_id: 0,
		quantity: 115,
	},
	{
		garage_id: 62,
		part_id: 1,
		quantity: 492,
	},
	{
		garage_id: 62,
		part_id: 2,
		quantity: 131,
	},
	{
		garage_id: 62,
		part_id: 3,
		quantity: 396,
	},
	{
		garage_id: 62,
		part_id: 4,
		quantity: 209,
	},
	{
		garage_id: 62,
		part_id: 5,
		quantity: 400,
	},
	{
		garage_id: 62,
		part_id: 6,
		quantity: 459,
	},
	{
		garage_id: 62,
		part_id: 7,
		quantity: 41,
	},
	{
		garage_id: 62,
		part_id: 8,
		quantity: 324,
	},
	{
		garage_id: 62,
		part_id: 9,
		quantity: 220,
	},
	{
		garage_id: 62,
		part_id: 10,
		quantity: 302,
	},
	{
		garage_id: 62,
		part_id: 11,
		quantity: 166,
	},
	{
		garage_id: 62,
		part_id: 12,
		quantity: 16,
	},
	{
		garage_id: 62,
		part_id: 13,
		quantity: 27,
	},
	{
		garage_id: 62,
		part_id: 14,
		quantity: 173,
	},
	{
		garage_id: 62,
		part_id: 15,
		quantity: 128,
	},
	{
		garage_id: 62,
		part_id: 16,
		quantity: 416,
	},
	{
		garage_id: 62,
		part_id: 17,
		quantity: 410,
	},
	{
		garage_id: 62,
		part_id: 18,
		quantity: 6,
	},
	{
		garage_id: 62,
		part_id: 19,
		quantity: 89,
	},
	{
		garage_id: 62,
		part_id: 20,
		quantity: 172,
	},
	{
		garage_id: 62,
		part_id: 21,
		quantity: 71,
	},
	{
		garage_id: 62,
		part_id: 22,
		quantity: 454,
	},
	{
		garage_id: 62,
		part_id: 23,
		quantity: 8,
	},
	{
		garage_id: 62,
		part_id: 24,
		quantity: 156,
	},
	{
		garage_id: 62,
		part_id: 25,
		quantity: 182,
	},
	{
		garage_id: 62,
		part_id: 26,
		quantity: 236,
	},
	{
		garage_id: 62,
		part_id: 27,
		quantity: 297,
	},
	{
		garage_id: 62,
		part_id: 28,
		quantity: 142,
	},
	{
		garage_id: 62,
		part_id: 29,
		quantity: 233,
	},
	{
		garage_id: 62,
		part_id: 30,
		quantity: 336,
	},
	{
		garage_id: 62,
		part_id: 31,
		quantity: 146,
	},
	{
		garage_id: 62,
		part_id: 32,
		quantity: 323,
	},
	{
		garage_id: 62,
		part_id: 33,
		quantity: 258,
	},
	{
		garage_id: 62,
		part_id: 34,
		quantity: 415,
	},
	{
		garage_id: 62,
		part_id: 35,
		quantity: 43,
	},
	{
		garage_id: 62,
		part_id: 36,
		quantity: 452,
	},
	{
		garage_id: 62,
		part_id: 37,
		quantity: 197,
	},
	{
		garage_id: 62,
		part_id: 38,
		quantity: 480,
	},
	{
		garage_id: 62,
		part_id: 39,
		quantity: 122,
	},
	{
		garage_id: 62,
		part_id: 40,
		quantity: 202,
	},
	{
		garage_id: 62,
		part_id: 41,
		quantity: 48,
	},
	{
		garage_id: 62,
		part_id: 42,
		quantity: 224,
	},
	{
		garage_id: 62,
		part_id: 43,
		quantity: 77,
	},
	{
		garage_id: 62,
		part_id: 44,
		quantity: 495,
	},
	{
		garage_id: 62,
		part_id: 45,
		quantity: 301,
	},
	{
		garage_id: 62,
		part_id: 46,
		quantity: 194,
	},
	{
		garage_id: 62,
		part_id: 47,
		quantity: 430,
	},
	{
		garage_id: 62,
		part_id: 48,
		quantity: 254,
	},
	{
		garage_id: 62,
		part_id: 49,
		quantity: 37,
	},
	{
		garage_id: 62,
		part_id: 50,
		quantity: 225,
	},
	{
		garage_id: 62,
		part_id: 51,
		quantity: 426,
	},
	{
		garage_id: 62,
		part_id: 52,
		quantity: 279,
	},
	{
		garage_id: 62,
		part_id: 53,
		quantity: 469,
	},
	{
		garage_id: 62,
		part_id: 54,
		quantity: 131,
	},
	{
		garage_id: 62,
		part_id: 55,
		quantity: 179,
	},
	{
		garage_id: 62,
		part_id: 56,
		quantity: 467,
	},
	{
		garage_id: 62,
		part_id: 57,
		quantity: 322,
	},
	{
		garage_id: 62,
		part_id: 58,
		quantity: 458,
	},
	{
		garage_id: 62,
		part_id: 59,
		quantity: 446,
	},
	{
		garage_id: 62,
		part_id: 60,
		quantity: 139,
	},
	{
		garage_id: 62,
		part_id: 61,
		quantity: 308,
	},
	{
		garage_id: 62,
		part_id: 62,
		quantity: 106,
	},
	{
		garage_id: 62,
		part_id: 63,
		quantity: 316,
	},
	{
		garage_id: 62,
		part_id: 64,
		quantity: 386,
	},
	{
		garage_id: 62,
		part_id: 65,
		quantity: 361,
	},
	{
		garage_id: 62,
		part_id: 66,
		quantity: 355,
	},
	{
		garage_id: 62,
		part_id: 67,
		quantity: 155,
	},
	{
		garage_id: 62,
		part_id: 68,
		quantity: 146,
	},
	{
		garage_id: 62,
		part_id: 69,
		quantity: 158,
	},
	{
		garage_id: 62,
		part_id: 70,
		quantity: 410,
	},
	{
		garage_id: 62,
		part_id: 71,
		quantity: 483,
	},
	{
		garage_id: 62,
		part_id: 72,
		quantity: 233,
	},
	{
		garage_id: 62,
		part_id: 73,
		quantity: 244,
	},
	{
		garage_id: 62,
		part_id: 74,
		quantity: 70,
	},
	{
		garage_id: 62,
		part_id: 75,
		quantity: 131,
	},
	{
		garage_id: 62,
		part_id: 76,
		quantity: 121,
	},
	{
		garage_id: 62,
		part_id: 77,
		quantity: 104,
	},
	{
		garage_id: 62,
		part_id: 78,
		quantity: 174,
	},
	{
		garage_id: 62,
		part_id: 79,
		quantity: 139,
	},
	{
		garage_id: 62,
		part_id: 80,
		quantity: 131,
	},
	{
		garage_id: 62,
		part_id: 81,
		quantity: 264,
	},
	{
		garage_id: 62,
		part_id: 82,
		quantity: 456,
	},
	{
		garage_id: 62,
		part_id: 83,
		quantity: 147,
	},
	{
		garage_id: 62,
		part_id: 84,
		quantity: 491,
	},
	{
		garage_id: 62,
		part_id: 85,
		quantity: 137,
	},
	{
		garage_id: 62,
		part_id: 86,
		quantity: 385,
	},
	{
		garage_id: 62,
		part_id: 87,
		quantity: 66,
	},
	{
		garage_id: 62,
		part_id: 88,
		quantity: 118,
	},
	{
		garage_id: 62,
		part_id: 89,
		quantity: 460,
	},
	{
		garage_id: 62,
		part_id: 90,
		quantity: 299,
	},
	{
		garage_id: 62,
		part_id: 91,
		quantity: 12,
	},
	{
		garage_id: 62,
		part_id: 92,
		quantity: 433,
	},
	{
		garage_id: 62,
		part_id: 93,
		quantity: 352,
	},
	{
		garage_id: 62,
		part_id: 94,
		quantity: 68,
	},
	{
		garage_id: 62,
		part_id: 95,
		quantity: 406,
	},
	{
		garage_id: 62,
		part_id: 96,
		quantity: 280,
	},
	{
		garage_id: 62,
		part_id: 97,
		quantity: 249,
	},
	{
		garage_id: 62,
		part_id: 98,
		quantity: 290,
	},
	{
		garage_id: 62,
		part_id: 99,
		quantity: 258,
	},
	{
		garage_id: 62,
		part_id: 100,
		quantity: 180,
	},
	{
		garage_id: 62,
		part_id: 101,
		quantity: 79,
	},
	{
		garage_id: 62,
		part_id: 102,
		quantity: 212,
	},
	{
		garage_id: 62,
		part_id: 103,
		quantity: 342,
	},
	{
		garage_id: 62,
		part_id: 104,
		quantity: 244,
	},
	{
		garage_id: 62,
		part_id: 105,
		quantity: 489,
	},
	{
		garage_id: 62,
		part_id: 106,
		quantity: 481,
	},
	{
		garage_id: 62,
		part_id: 107,
		quantity: 479,
	},
	{
		garage_id: 62,
		part_id: 108,
		quantity: 125,
	},
	{
		garage_id: 62,
		part_id: 109,
		quantity: 221,
	},
	{
		garage_id: 62,
		part_id: 110,
		quantity: 460,
	},
	{
		garage_id: 62,
		part_id: 111,
		quantity: 291,
	},
	{
		garage_id: 62,
		part_id: 112,
		quantity: 280,
	},
	{
		garage_id: 62,
		part_id: 113,
		quantity: 78,
	},
	{
		garage_id: 62,
		part_id: 114,
		quantity: 312,
	},
	{
		garage_id: 62,
		part_id: 115,
		quantity: 148,
	},
	{
		garage_id: 62,
		part_id: 116,
		quantity: 68,
	},
	{
		garage_id: 62,
		part_id: 117,
		quantity: 293,
	},
	{
		garage_id: 62,
		part_id: 118,
		quantity: 261,
	},
	{
		garage_id: 62,
		part_id: 119,
		quantity: 318,
	},
	{
		garage_id: 62,
		part_id: 120,
		quantity: 410,
	},
	{
		garage_id: 62,
		part_id: 121,
		quantity: 444,
	},
	{
		garage_id: 62,
		part_id: 122,
		quantity: 252,
	},
	{
		garage_id: 62,
		part_id: 123,
		quantity: 37,
	},
	{
		garage_id: 62,
		part_id: 124,
		quantity: 127,
	},
	{
		garage_id: 62,
		part_id: 125,
		quantity: 150,
	},
	{
		garage_id: 62,
		part_id: 126,
		quantity: 412,
	},
	{
		garage_id: 62,
		part_id: 127,
		quantity: 416,
	},
	{
		garage_id: 62,
		part_id: 128,
		quantity: 16,
	},
	{
		garage_id: 62,
		part_id: 129,
		quantity: 112,
	},
	{
		garage_id: 62,
		part_id: 130,
		quantity: 464,
	},
	{
		garage_id: 62,
		part_id: 131,
		quantity: 471,
	},
	{
		garage_id: 62,
		part_id: 132,
		quantity: 412,
	},
	{
		garage_id: 62,
		part_id: 133,
		quantity: 460,
	},
	{
		garage_id: 62,
		part_id: 134,
		quantity: 96,
	},
	{
		garage_id: 62,
		part_id: 135,
		quantity: 178,
	},
	{
		garage_id: 62,
		part_id: 136,
		quantity: 313,
	},
	{
		garage_id: 62,
		part_id: 137,
		quantity: 255,
	},
	{
		garage_id: 62,
		part_id: 138,
		quantity: 344,
	},
	{
		garage_id: 62,
		part_id: 139,
		quantity: 342,
	},
	{
		garage_id: 62,
		part_id: 140,
		quantity: 279,
	},
	{
		garage_id: 62,
		part_id: 141,
		quantity: 72,
	},
	{
		garage_id: 62,
		part_id: 142,
		quantity: 336,
	},
	{
		garage_id: 62,
		part_id: 143,
		quantity: 310,
	},
	{
		garage_id: 62,
		part_id: 144,
		quantity: 483,
	},
	{
		garage_id: 62,
		part_id: 145,
		quantity: 425,
	},
	{
		garage_id: 62,
		part_id: 146,
		quantity: 468,
	},
	{
		garage_id: 62,
		part_id: 147,
		quantity: 263,
	},
	{
		garage_id: 62,
		part_id: 148,
		quantity: 130,
	},
	{
		garage_id: 62,
		part_id: 149,
		quantity: 92,
	},
	{
		garage_id: 62,
		part_id: 150,
		quantity: 246,
	},
	{
		garage_id: 62,
		part_id: 151,
		quantity: 187,
	},
	{
		garage_id: 62,
		part_id: 152,
		quantity: 189,
	},
	{
		garage_id: 62,
		part_id: 153,
		quantity: 237,
	},
	{
		garage_id: 62,
		part_id: 154,
		quantity: 55,
	},
	{
		garage_id: 62,
		part_id: 155,
		quantity: 263,
	},
	{
		garage_id: 62,
		part_id: 156,
		quantity: 134,
	},
	{
		garage_id: 62,
		part_id: 157,
		quantity: 420,
	},
	{
		garage_id: 62,
		part_id: 158,
		quantity: 313,
	},
	{
		garage_id: 62,
		part_id: 159,
		quantity: 192,
	},
	{
		garage_id: 62,
		part_id: 160,
		quantity: 451,
	},
	{
		garage_id: 62,
		part_id: 161,
		quantity: 76,
	},
	{
		garage_id: 62,
		part_id: 162,
		quantity: 19,
	},
	{
		garage_id: 62,
		part_id: 163,
		quantity: 466,
	},
	{
		garage_id: 62,
		part_id: 164,
		quantity: 81,
	},
	{
		garage_id: 62,
		part_id: 165,
		quantity: 491,
	},
	{
		garage_id: 62,
		part_id: 166,
		quantity: 310,
	},
	{
		garage_id: 62,
		part_id: 167,
		quantity: 310,
	},
	{
		garage_id: 62,
		part_id: 168,
		quantity: 74,
	},
	{
		garage_id: 62,
		part_id: 169,
		quantity: 170,
	},
	{
		garage_id: 62,
		part_id: 170,
		quantity: 288,
	},
	{
		garage_id: 62,
		part_id: 171,
		quantity: 278,
	},
	{
		garage_id: 62,
		part_id: 172,
		quantity: 123,
	},
	{
		garage_id: 62,
		part_id: 173,
		quantity: 131,
	},
	{
		garage_id: 62,
		part_id: 174,
		quantity: 220,
	},
	{
		garage_id: 62,
		part_id: 175,
		quantity: 432,
	},
	{
		garage_id: 62,
		part_id: 176,
		quantity: 465,
	},
	{
		garage_id: 62,
		part_id: 177,
		quantity: 299,
	},
	{
		garage_id: 62,
		part_id: 178,
		quantity: 425,
	},
	{
		garage_id: 62,
		part_id: 179,
		quantity: 198,
	},
	{
		garage_id: 62,
		part_id: 180,
		quantity: 150,
	},
	{
		garage_id: 62,
		part_id: 181,
		quantity: 287,
	},
	{
		garage_id: 62,
		part_id: 182,
		quantity: 406,
	},
	{
		garage_id: 62,
		part_id: 183,
		quantity: 443,
	},
	{
		garage_id: 62,
		part_id: 184,
		quantity: 426,
	},
	{
		garage_id: 62,
		part_id: 185,
		quantity: 277,
	},
	{
		garage_id: 62,
		part_id: 186,
		quantity: 190,
	},
	{
		garage_id: 62,
		part_id: 187,
		quantity: 44,
	},
	{
		garage_id: 62,
		part_id: 188,
		quantity: 374,
	},
	{
		garage_id: 62,
		part_id: 189,
		quantity: 96,
	},
	{
		garage_id: 62,
		part_id: 190,
		quantity: 288,
	},
	{
		garage_id: 62,
		part_id: 191,
		quantity: 115,
	},
	{
		garage_id: 62,
		part_id: 192,
		quantity: 23,
	},
	{
		garage_id: 62,
		part_id: 193,
		quantity: 347,
	},
	{
		garage_id: 62,
		part_id: 194,
		quantity: 323,
	},
	{
		garage_id: 62,
		part_id: 195,
		quantity: 435,
	},
	{
		garage_id: 62,
		part_id: 196,
		quantity: 465,
	},
	{
		garage_id: 62,
		part_id: 197,
		quantity: 338,
	},
	{
		garage_id: 62,
		part_id: 198,
		quantity: 95,
	},
	{
		garage_id: 62,
		part_id: 199,
		quantity: 235,
	},
	{
		garage_id: 62,
		part_id: 200,
		quantity: 366,
	},
	{
		garage_id: 62,
		part_id: 201,
		quantity: 88,
	},
	{
		garage_id: 62,
		part_id: 202,
		quantity: 282,
	},
	{
		garage_id: 62,
		part_id: 203,
		quantity: 195,
	},
	{
		garage_id: 62,
		part_id: 204,
		quantity: 5,
	},
	{
		garage_id: 62,
		part_id: 205,
		quantity: 69,
	},
	{
		garage_id: 62,
		part_id: 206,
		quantity: 236,
	},
	{
		garage_id: 62,
		part_id: 207,
		quantity: 439,
	},
	{
		garage_id: 62,
		part_id: 208,
		quantity: 457,
	},
	{
		garage_id: 62,
		part_id: 209,
		quantity: 253,
	},
	{
		garage_id: 62,
		part_id: 210,
		quantity: 96,
	},
	{
		garage_id: 62,
		part_id: 211,
		quantity: 24,
	},
	{
		garage_id: 62,
		part_id: 212,
		quantity: 164,
	},
	{
		garage_id: 62,
		part_id: 213,
		quantity: 153,
	},
	{
		garage_id: 62,
		part_id: 214,
		quantity: 219,
	},
	{
		garage_id: 62,
		part_id: 215,
		quantity: 359,
	},
	{
		garage_id: 62,
		part_id: 216,
		quantity: 98,
	},
	{
		garage_id: 62,
		part_id: 217,
		quantity: 307,
	},
	{
		garage_id: 62,
		part_id: 218,
		quantity: 288,
	},
	{
		garage_id: 62,
		part_id: 219,
		quantity: 309,
	},
	{
		garage_id: 62,
		part_id: 220,
		quantity: 278,
	},
	{
		garage_id: 62,
		part_id: 221,
		quantity: 309,
	},
	{
		garage_id: 62,
		part_id: 222,
		quantity: 389,
	},
	{
		garage_id: 62,
		part_id: 223,
		quantity: 63,
	},
	{
		garage_id: 62,
		part_id: 224,
		quantity: 216,
	},
	{
		garage_id: 62,
		part_id: 225,
		quantity: 46,
	},
	{
		garage_id: 62,
		part_id: 226,
		quantity: 302,
	},
	{
		garage_id: 62,
		part_id: 227,
		quantity: 346,
	},
	{
		garage_id: 62,
		part_id: 228,
		quantity: 381,
	},
	{
		garage_id: 62,
		part_id: 229,
		quantity: 400,
	},
	{
		garage_id: 62,
		part_id: 230,
		quantity: 25,
	},
	{
		garage_id: 62,
		part_id: 231,
		quantity: 182,
	},
	{
		garage_id: 62,
		part_id: 232,
		quantity: 269,
	},
	{
		garage_id: 62,
		part_id: 233,
		quantity: 62,
	},
	{
		garage_id: 62,
		part_id: 234,
		quantity: 63,
	},
	{
		garage_id: 62,
		part_id: 235,
		quantity: 98,
	},
	{
		garage_id: 62,
		part_id: 236,
		quantity: 489,
	},
	{
		garage_id: 62,
		part_id: 237,
		quantity: 122,
	},
	{
		garage_id: 62,
		part_id: 238,
		quantity: 248,
	},
	{
		garage_id: 62,
		part_id: 239,
		quantity: 499,
	},
	{
		garage_id: 62,
		part_id: 240,
		quantity: 369,
	},
	{
		garage_id: 62,
		part_id: 241,
		quantity: 298,
	},
	{
		garage_id: 62,
		part_id: 242,
		quantity: 249,
	},
	{
		garage_id: 62,
		part_id: 243,
		quantity: 136,
	},
	{
		garage_id: 62,
		part_id: 244,
		quantity: 115,
	},
	{
		garage_id: 62,
		part_id: 245,
		quantity: 11,
	},
	{
		garage_id: 62,
		part_id: 246,
		quantity: 46,
	},
	{
		garage_id: 62,
		part_id: 247,
		quantity: 499,
	},
	{
		garage_id: 62,
		part_id: 248,
		quantity: 23,
	},
	{
		garage_id: 62,
		part_id: 249,
		quantity: 128,
	},
	{
		garage_id: 62,
		part_id: 250,
		quantity: 114,
	},
	{
		garage_id: 62,
		part_id: 251,
		quantity: 52,
	},
	{
		garage_id: 62,
		part_id: 252,
		quantity: 261,
	},
	{
		garage_id: 62,
		part_id: 253,
		quantity: 355,
	},
	{
		garage_id: 62,
		part_id: 254,
		quantity: 425,
	},
	{
		garage_id: 62,
		part_id: 255,
		quantity: 443,
	},
	{
		garage_id: 62,
		part_id: 256,
		quantity: 497,
	},
	{
		garage_id: 62,
		part_id: 257,
		quantity: 311,
	},
	{
		garage_id: 62,
		part_id: 258,
		quantity: 381,
	},
	{
		garage_id: 62,
		part_id: 259,
		quantity: 32,
	},
	{
		garage_id: 62,
		part_id: 260,
		quantity: 73,
	},
	{
		garage_id: 62,
		part_id: 261,
		quantity: 494,
	},
	{
		garage_id: 62,
		part_id: 262,
		quantity: 30,
	},
	{
		garage_id: 62,
		part_id: 263,
		quantity: 355,
	},
	{
		garage_id: 62,
		part_id: 264,
		quantity: 4,
	},
	{
		garage_id: 62,
		part_id: 265,
		quantity: 390,
	},
	{
		garage_id: 62,
		part_id: 266,
		quantity: 298,
	},
	{
		garage_id: 62,
		part_id: 267,
		quantity: 8,
	},
	{
		garage_id: 62,
		part_id: 268,
		quantity: 376,
	},
	{
		garage_id: 62,
		part_id: 269,
		quantity: 484,
	},
	{
		garage_id: 62,
		part_id: 270,
		quantity: 274,
	},
	{
		garage_id: 62,
		part_id: 271,
		quantity: 325,
	},
	{
		garage_id: 62,
		part_id: 272,
		quantity: 279,
	},
	{
		garage_id: 62,
		part_id: 273,
		quantity: 114,
	},
	{
		garage_id: 62,
		part_id: 274,
		quantity: 242,
	},
	{
		garage_id: 62,
		part_id: 275,
		quantity: 372,
	},
	{
		garage_id: 62,
		part_id: 276,
		quantity: 176,
	},
	{
		garage_id: 62,
		part_id: 277,
		quantity: 499,
	},
	{
		garage_id: 62,
		part_id: 278,
		quantity: 445,
	},
	{
		garage_id: 62,
		part_id: 279,
		quantity: 291,
	},
	{
		garage_id: 62,
		part_id: 280,
		quantity: 464,
	},
	{
		garage_id: 62,
		part_id: 281,
		quantity: 236,
	},
	{
		garage_id: 62,
		part_id: 282,
		quantity: 14,
	},
	{
		garage_id: 62,
		part_id: 283,
		quantity: 89,
	},
	{
		garage_id: 62,
		part_id: 284,
		quantity: 373,
	},
	{
		garage_id: 62,
		part_id: 285,
		quantity: 205,
	},
	{
		garage_id: 62,
		part_id: 286,
		quantity: 101,
	},
	{
		garage_id: 62,
		part_id: 287,
		quantity: 177,
	},
	{
		garage_id: 62,
		part_id: 288,
		quantity: 329,
	},
	{
		garage_id: 62,
		part_id: 289,
		quantity: 108,
	},
	{
		garage_id: 62,
		part_id: 290,
		quantity: 484,
	},
	{
		garage_id: 62,
		part_id: 291,
		quantity: 245,
	},
	{
		garage_id: 62,
		part_id: 292,
		quantity: 386,
	},
	{
		garage_id: 62,
		part_id: 293,
		quantity: 37,
	},
	{
		garage_id: 62,
		part_id: 294,
		quantity: 235,
	},
	{
		garage_id: 62,
		part_id: 295,
		quantity: 238,
	},
	{
		garage_id: 62,
		part_id: 296,
		quantity: 160,
	},
	{
		garage_id: 62,
		part_id: 297,
		quantity: 26,
	},
	{
		garage_id: 62,
		part_id: 298,
		quantity: 52,
	},
	{
		garage_id: 62,
		part_id: 299,
		quantity: 401,
	},
	{
		garage_id: 62,
		part_id: 300,
		quantity: 441,
	},
	{
		garage_id: 62,
		part_id: 301,
		quantity: 105,
	},
	{
		garage_id: 62,
		part_id: 302,
		quantity: 461,
	},
	{
		garage_id: 62,
		part_id: 303,
		quantity: 296,
	},
	{
		garage_id: 62,
		part_id: 304,
		quantity: 217,
	},
	{
		garage_id: 62,
		part_id: 305,
		quantity: 107,
	},
	{
		garage_id: 62,
		part_id: 306,
		quantity: 337,
	},
	{
		garage_id: 62,
		part_id: 307,
		quantity: 252,
	},
	{
		garage_id: 62,
		part_id: 308,
		quantity: 379,
	},
	{
		garage_id: 62,
		part_id: 309,
		quantity: 240,
	},
	{
		garage_id: 62,
		part_id: 310,
		quantity: 65,
	},
	{
		garage_id: 62,
		part_id: 311,
		quantity: 400,
	},
	{
		garage_id: 62,
		part_id: 312,
		quantity: 398,
	},
	{
		garage_id: 62,
		part_id: 313,
		quantity: 460,
	},
	{
		garage_id: 62,
		part_id: 314,
		quantity: 136,
	},
	{
		garage_id: 62,
		part_id: 315,
		quantity: 279,
	},
	{
		garage_id: 62,
		part_id: 316,
		quantity: 399,
	},
	{
		garage_id: 62,
		part_id: 317,
		quantity: 221,
	},
	{
		garage_id: 62,
		part_id: 318,
		quantity: 167,
	},
	{
		garage_id: 62,
		part_id: 319,
		quantity: 24,
	},
	{
		garage_id: 62,
		part_id: 320,
		quantity: 343,
	},
	{
		garage_id: 62,
		part_id: 321,
		quantity: 225,
	},
	{
		garage_id: 62,
		part_id: 322,
		quantity: 113,
	},
	{
		garage_id: 62,
		part_id: 323,
		quantity: 216,
	},
	{
		garage_id: 62,
		part_id: 324,
		quantity: 111,
	},
	{
		garage_id: 62,
		part_id: 325,
		quantity: 214,
	},
	{
		garage_id: 62,
		part_id: 326,
		quantity: 59,
	},
	{
		garage_id: 62,
		part_id: 327,
		quantity: 324,
	},
	{
		garage_id: 62,
		part_id: 328,
		quantity: 130,
	},
	{
		garage_id: 62,
		part_id: 329,
		quantity: 0,
	},
	{
		garage_id: 62,
		part_id: 330,
		quantity: 235,
	},
	{
		garage_id: 62,
		part_id: 331,
		quantity: 87,
	},
	{
		garage_id: 62,
		part_id: 332,
		quantity: 164,
	},
	{
		garage_id: 62,
		part_id: 333,
		quantity: 76,
	},
	{
		garage_id: 62,
		part_id: 334,
		quantity: 476,
	},
	{
		garage_id: 62,
		part_id: 335,
		quantity: 431,
	},
	{
		garage_id: 62,
		part_id: 336,
		quantity: 469,
	},
	{
		garage_id: 62,
		part_id: 337,
		quantity: 414,
	},
	{
		garage_id: 62,
		part_id: 338,
		quantity: 129,
	},
	{
		garage_id: 62,
		part_id: 339,
		quantity: 419,
	},
	{
		garage_id: 62,
		part_id: 340,
		quantity: 324,
	},
	{
		garage_id: 62,
		part_id: 341,
		quantity: 426,
	},
	{
		garage_id: 62,
		part_id: 342,
		quantity: 12,
	},
	{
		garage_id: 62,
		part_id: 343,
		quantity: 321,
	},
	{
		garage_id: 62,
		part_id: 344,
		quantity: 272,
	},
	{
		garage_id: 62,
		part_id: 345,
		quantity: 414,
	},
	{
		garage_id: 62,
		part_id: 346,
		quantity: 83,
	},
	{
		garage_id: 62,
		part_id: 347,
		quantity: 448,
	},
	{
		garage_id: 62,
		part_id: 348,
		quantity: 132,
	},
	{
		garage_id: 62,
		part_id: 349,
		quantity: 45,
	},
	{
		garage_id: 62,
		part_id: 350,
		quantity: 210,
	},
	{
		garage_id: 62,
		part_id: 351,
		quantity: 289,
	},
	{
		garage_id: 62,
		part_id: 352,
		quantity: 307,
	},
	{
		garage_id: 62,
		part_id: 353,
		quantity: 164,
	},
	{
		garage_id: 62,
		part_id: 354,
		quantity: 49,
	},
	{
		garage_id: 62,
		part_id: 355,
		quantity: 386,
	},
	{
		garage_id: 62,
		part_id: 356,
		quantity: 175,
	},
	{
		garage_id: 62,
		part_id: 357,
		quantity: 118,
	},
	{
		garage_id: 62,
		part_id: 358,
		quantity: 176,
	},
	{
		garage_id: 62,
		part_id: 359,
		quantity: 117,
	},
	{
		garage_id: 62,
		part_id: 360,
		quantity: 113,
	},
	{
		garage_id: 62,
		part_id: 361,
		quantity: 354,
	},
	{
		garage_id: 62,
		part_id: 362,
		quantity: 283,
	},
	{
		garage_id: 62,
		part_id: 363,
		quantity: 159,
	},
	{
		garage_id: 62,
		part_id: 364,
		quantity: 478,
	},
	{
		garage_id: 62,
		part_id: 365,
		quantity: 444,
	},
	{
		garage_id: 62,
		part_id: 366,
		quantity: 150,
	},
	{
		garage_id: 62,
		part_id: 367,
		quantity: 246,
	},
	{
		garage_id: 62,
		part_id: 368,
		quantity: 159,
	},
	{
		garage_id: 62,
		part_id: 369,
		quantity: 454,
	},
	{
		garage_id: 62,
		part_id: 370,
		quantity: 57,
	},
	{
		garage_id: 62,
		part_id: 371,
		quantity: 125,
	},
	{
		garage_id: 62,
		part_id: 372,
		quantity: 486,
	},
	{
		garage_id: 62,
		part_id: 373,
		quantity: 369,
	},
	{
		garage_id: 62,
		part_id: 374,
		quantity: 287,
	},
	{
		garage_id: 62,
		part_id: 375,
		quantity: 322,
	},
	{
		garage_id: 62,
		part_id: 376,
		quantity: 373,
	},
	{
		garage_id: 62,
		part_id: 377,
		quantity: 129,
	},
	{
		garage_id: 62,
		part_id: 378,
		quantity: 45,
	},
	{
		garage_id: 62,
		part_id: 379,
		quantity: 244,
	},
	{
		garage_id: 62,
		part_id: 380,
		quantity: 332,
	},
	{
		garage_id: 62,
		part_id: 381,
		quantity: 427,
	},
	{
		garage_id: 62,
		part_id: 382,
		quantity: 481,
	},
	{
		garage_id: 62,
		part_id: 383,
		quantity: 245,
	},
	{
		garage_id: 62,
		part_id: 384,
		quantity: 136,
	},
	{
		garage_id: 62,
		part_id: 385,
		quantity: 50,
	},
	{
		garage_id: 62,
		part_id: 386,
		quantity: 44,
	},
	{
		garage_id: 62,
		part_id: 387,
		quantity: 459,
	},
	{
		garage_id: 62,
		part_id: 388,
		quantity: 285,
	},
	{
		garage_id: 62,
		part_id: 389,
		quantity: 457,
	},
	{
		garage_id: 62,
		part_id: 390,
		quantity: 141,
	},
	{
		garage_id: 62,
		part_id: 391,
		quantity: 92,
	},
	{
		garage_id: 62,
		part_id: 392,
		quantity: 346,
	},
	{
		garage_id: 62,
		part_id: 393,
		quantity: 267,
	},
	{
		garage_id: 62,
		part_id: 394,
		quantity: 410,
	},
	{
		garage_id: 62,
		part_id: 395,
		quantity: 234,
	},
	{
		garage_id: 62,
		part_id: 396,
		quantity: 420,
	},
	{
		garage_id: 62,
		part_id: 397,
		quantity: 387,
	},
	{
		garage_id: 62,
		part_id: 398,
		quantity: 241,
	},
	{
		garage_id: 62,
		part_id: 399,
		quantity: 464,
	},
	{
		garage_id: 63,
		part_id: 0,
		quantity: 124,
	},
	{
		garage_id: 63,
		part_id: 1,
		quantity: 276,
	},
	{
		garage_id: 63,
		part_id: 2,
		quantity: 88,
	},
	{
		garage_id: 63,
		part_id: 3,
		quantity: 145,
	},
	{
		garage_id: 63,
		part_id: 4,
		quantity: 270,
	},
	{
		garage_id: 63,
		part_id: 5,
		quantity: 227,
	},
	{
		garage_id: 63,
		part_id: 6,
		quantity: 229,
	},
	{
		garage_id: 63,
		part_id: 7,
		quantity: 438,
	},
	{
		garage_id: 63,
		part_id: 8,
		quantity: 164,
	},
	{
		garage_id: 63,
		part_id: 9,
		quantity: 104,
	},
	{
		garage_id: 63,
		part_id: 10,
		quantity: 482,
	},
	{
		garage_id: 63,
		part_id: 11,
		quantity: 81,
	},
	{
		garage_id: 63,
		part_id: 12,
		quantity: 14,
	},
	{
		garage_id: 63,
		part_id: 13,
		quantity: 315,
	},
	{
		garage_id: 63,
		part_id: 14,
		quantity: 401,
	},
	{
		garage_id: 63,
		part_id: 15,
		quantity: 392,
	},
	{
		garage_id: 63,
		part_id: 16,
		quantity: 26,
	},
	{
		garage_id: 63,
		part_id: 17,
		quantity: 5,
	},
	{
		garage_id: 63,
		part_id: 18,
		quantity: 226,
	},
	{
		garage_id: 63,
		part_id: 19,
		quantity: 56,
	},
	{
		garage_id: 63,
		part_id: 20,
		quantity: 313,
	},
	{
		garage_id: 63,
		part_id: 21,
		quantity: 236,
	},
	{
		garage_id: 63,
		part_id: 22,
		quantity: 279,
	},
	{
		garage_id: 63,
		part_id: 23,
		quantity: 45,
	},
	{
		garage_id: 63,
		part_id: 24,
		quantity: 500,
	},
	{
		garage_id: 63,
		part_id: 25,
		quantity: 348,
	},
	{
		garage_id: 63,
		part_id: 26,
		quantity: 150,
	},
	{
		garage_id: 63,
		part_id: 27,
		quantity: 245,
	},
	{
		garage_id: 63,
		part_id: 28,
		quantity: 63,
	},
	{
		garage_id: 63,
		part_id: 29,
		quantity: 471,
	},
	{
		garage_id: 63,
		part_id: 30,
		quantity: 340,
	},
	{
		garage_id: 63,
		part_id: 31,
		quantity: 274,
	},
	{
		garage_id: 63,
		part_id: 32,
		quantity: 320,
	},
	{
		garage_id: 63,
		part_id: 33,
		quantity: 399,
	},
	{
		garage_id: 63,
		part_id: 34,
		quantity: 187,
	},
	{
		garage_id: 63,
		part_id: 35,
		quantity: 471,
	},
	{
		garage_id: 63,
		part_id: 36,
		quantity: 16,
	},
	{
		garage_id: 63,
		part_id: 37,
		quantity: 247,
	},
	{
		garage_id: 63,
		part_id: 38,
		quantity: 226,
	},
	{
		garage_id: 63,
		part_id: 39,
		quantity: 97,
	},
	{
		garage_id: 63,
		part_id: 40,
		quantity: 157,
	},
	{
		garage_id: 63,
		part_id: 41,
		quantity: 303,
	},
	{
		garage_id: 63,
		part_id: 42,
		quantity: 26,
	},
	{
		garage_id: 63,
		part_id: 43,
		quantity: 69,
	},
	{
		garage_id: 63,
		part_id: 44,
		quantity: 349,
	},
	{
		garage_id: 63,
		part_id: 45,
		quantity: 163,
	},
	{
		garage_id: 63,
		part_id: 46,
		quantity: 24,
	},
	{
		garage_id: 63,
		part_id: 47,
		quantity: 423,
	},
	{
		garage_id: 63,
		part_id: 48,
		quantity: 215,
	},
	{
		garage_id: 63,
		part_id: 49,
		quantity: 81,
	},
	{
		garage_id: 63,
		part_id: 50,
		quantity: 252,
	},
	{
		garage_id: 63,
		part_id: 51,
		quantity: 376,
	},
	{
		garage_id: 63,
		part_id: 52,
		quantity: 348,
	},
	{
		garage_id: 63,
		part_id: 53,
		quantity: 103,
	},
	{
		garage_id: 63,
		part_id: 54,
		quantity: 238,
	},
	{
		garage_id: 63,
		part_id: 55,
		quantity: 26,
	},
	{
		garage_id: 63,
		part_id: 56,
		quantity: 196,
	},
	{
		garage_id: 63,
		part_id: 57,
		quantity: 268,
	},
	{
		garage_id: 63,
		part_id: 58,
		quantity: 185,
	},
	{
		garage_id: 63,
		part_id: 59,
		quantity: 146,
	},
	{
		garage_id: 63,
		part_id: 60,
		quantity: 10,
	},
	{
		garage_id: 63,
		part_id: 61,
		quantity: 170,
	},
	{
		garage_id: 63,
		part_id: 62,
		quantity: 323,
	},
	{
		garage_id: 63,
		part_id: 63,
		quantity: 129,
	},
	{
		garage_id: 63,
		part_id: 64,
		quantity: 482,
	},
	{
		garage_id: 63,
		part_id: 65,
		quantity: 157,
	},
	{
		garage_id: 63,
		part_id: 66,
		quantity: 358,
	},
	{
		garage_id: 63,
		part_id: 67,
		quantity: 132,
	},
	{
		garage_id: 63,
		part_id: 68,
		quantity: 182,
	},
	{
		garage_id: 63,
		part_id: 69,
		quantity: 184,
	},
	{
		garage_id: 63,
		part_id: 70,
		quantity: 104,
	},
	{
		garage_id: 63,
		part_id: 71,
		quantity: 231,
	},
	{
		garage_id: 63,
		part_id: 72,
		quantity: 98,
	},
	{
		garage_id: 63,
		part_id: 73,
		quantity: 46,
	},
	{
		garage_id: 63,
		part_id: 74,
		quantity: 105,
	},
	{
		garage_id: 63,
		part_id: 75,
		quantity: 101,
	},
	{
		garage_id: 63,
		part_id: 76,
		quantity: 322,
	},
	{
		garage_id: 63,
		part_id: 77,
		quantity: 67,
	},
	{
		garage_id: 63,
		part_id: 78,
		quantity: 71,
	},
	{
		garage_id: 63,
		part_id: 79,
		quantity: 483,
	},
	{
		garage_id: 63,
		part_id: 80,
		quantity: 423,
	},
	{
		garage_id: 63,
		part_id: 81,
		quantity: 426,
	},
	{
		garage_id: 63,
		part_id: 82,
		quantity: 265,
	},
	{
		garage_id: 63,
		part_id: 83,
		quantity: 312,
	},
	{
		garage_id: 63,
		part_id: 84,
		quantity: 132,
	},
	{
		garage_id: 63,
		part_id: 85,
		quantity: 41,
	},
	{
		garage_id: 63,
		part_id: 86,
		quantity: 491,
	},
	{
		garage_id: 63,
		part_id: 87,
		quantity: 80,
	},
	{
		garage_id: 63,
		part_id: 88,
		quantity: 315,
	},
	{
		garage_id: 63,
		part_id: 89,
		quantity: 275,
	},
	{
		garage_id: 63,
		part_id: 90,
		quantity: 382,
	},
	{
		garage_id: 63,
		part_id: 91,
		quantity: 376,
	},
	{
		garage_id: 63,
		part_id: 92,
		quantity: 248,
	},
	{
		garage_id: 63,
		part_id: 93,
		quantity: 383,
	},
	{
		garage_id: 63,
		part_id: 94,
		quantity: 210,
	},
	{
		garage_id: 63,
		part_id: 95,
		quantity: 436,
	},
	{
		garage_id: 63,
		part_id: 96,
		quantity: 465,
	},
	{
		garage_id: 63,
		part_id: 97,
		quantity: 191,
	},
	{
		garage_id: 63,
		part_id: 98,
		quantity: 375,
	},
	{
		garage_id: 63,
		part_id: 99,
		quantity: 323,
	},
	{
		garage_id: 63,
		part_id: 100,
		quantity: 124,
	},
	{
		garage_id: 63,
		part_id: 101,
		quantity: 169,
	},
	{
		garage_id: 63,
		part_id: 102,
		quantity: 159,
	},
	{
		garage_id: 63,
		part_id: 103,
		quantity: 255,
	},
	{
		garage_id: 63,
		part_id: 104,
		quantity: 142,
	},
	{
		garage_id: 63,
		part_id: 105,
		quantity: 260,
	},
	{
		garage_id: 63,
		part_id: 106,
		quantity: 275,
	},
	{
		garage_id: 63,
		part_id: 107,
		quantity: 157,
	},
	{
		garage_id: 63,
		part_id: 108,
		quantity: 359,
	},
	{
		garage_id: 63,
		part_id: 109,
		quantity: 234,
	},
	{
		garage_id: 63,
		part_id: 110,
		quantity: 364,
	},
	{
		garage_id: 63,
		part_id: 111,
		quantity: 15,
	},
	{
		garage_id: 63,
		part_id: 112,
		quantity: 394,
	},
	{
		garage_id: 63,
		part_id: 113,
		quantity: 485,
	},
	{
		garage_id: 63,
		part_id: 114,
		quantity: 338,
	},
	{
		garage_id: 63,
		part_id: 115,
		quantity: 283,
	},
	{
		garage_id: 63,
		part_id: 116,
		quantity: 70,
	},
	{
		garage_id: 63,
		part_id: 117,
		quantity: 46,
	},
	{
		garage_id: 63,
		part_id: 118,
		quantity: 351,
	},
	{
		garage_id: 63,
		part_id: 119,
		quantity: 334,
	},
	{
		garage_id: 63,
		part_id: 120,
		quantity: 251,
	},
	{
		garage_id: 63,
		part_id: 121,
		quantity: 242,
	},
	{
		garage_id: 63,
		part_id: 122,
		quantity: 215,
	},
	{
		garage_id: 63,
		part_id: 123,
		quantity: 64,
	},
	{
		garage_id: 63,
		part_id: 124,
		quantity: 364,
	},
	{
		garage_id: 63,
		part_id: 125,
		quantity: 91,
	},
	{
		garage_id: 63,
		part_id: 126,
		quantity: 338,
	},
	{
		garage_id: 63,
		part_id: 127,
		quantity: 247,
	},
	{
		garage_id: 63,
		part_id: 128,
		quantity: 327,
	},
	{
		garage_id: 63,
		part_id: 129,
		quantity: 420,
	},
	{
		garage_id: 63,
		part_id: 130,
		quantity: 405,
	},
	{
		garage_id: 63,
		part_id: 131,
		quantity: 455,
	},
	{
		garage_id: 63,
		part_id: 132,
		quantity: 45,
	},
	{
		garage_id: 63,
		part_id: 133,
		quantity: 185,
	},
	{
		garage_id: 63,
		part_id: 134,
		quantity: 259,
	},
	{
		garage_id: 63,
		part_id: 135,
		quantity: 35,
	},
	{
		garage_id: 63,
		part_id: 136,
		quantity: 339,
	},
	{
		garage_id: 63,
		part_id: 137,
		quantity: 38,
	},
	{
		garage_id: 63,
		part_id: 138,
		quantity: 332,
	},
	{
		garage_id: 63,
		part_id: 139,
		quantity: 51,
	},
	{
		garage_id: 63,
		part_id: 140,
		quantity: 495,
	},
	{
		garage_id: 63,
		part_id: 141,
		quantity: 387,
	},
	{
		garage_id: 63,
		part_id: 142,
		quantity: 94,
	},
	{
		garage_id: 63,
		part_id: 143,
		quantity: 414,
	},
	{
		garage_id: 63,
		part_id: 144,
		quantity: 244,
	},
	{
		garage_id: 63,
		part_id: 145,
		quantity: 248,
	},
	{
		garage_id: 63,
		part_id: 146,
		quantity: 32,
	},
	{
		garage_id: 63,
		part_id: 147,
		quantity: 12,
	},
	{
		garage_id: 63,
		part_id: 148,
		quantity: 244,
	},
	{
		garage_id: 63,
		part_id: 149,
		quantity: 30,
	},
	{
		garage_id: 63,
		part_id: 150,
		quantity: 300,
	},
	{
		garage_id: 63,
		part_id: 151,
		quantity: 401,
	},
	{
		garage_id: 63,
		part_id: 152,
		quantity: 186,
	},
	{
		garage_id: 63,
		part_id: 153,
		quantity: 232,
	},
	{
		garage_id: 63,
		part_id: 154,
		quantity: 381,
	},
	{
		garage_id: 63,
		part_id: 155,
		quantity: 489,
	},
	{
		garage_id: 63,
		part_id: 156,
		quantity: 52,
	},
	{
		garage_id: 63,
		part_id: 157,
		quantity: 147,
	},
	{
		garage_id: 63,
		part_id: 158,
		quantity: 194,
	},
	{
		garage_id: 63,
		part_id: 159,
		quantity: 444,
	},
	{
		garage_id: 63,
		part_id: 160,
		quantity: 90,
	},
	{
		garage_id: 63,
		part_id: 161,
		quantity: 404,
	},
	{
		garage_id: 63,
		part_id: 162,
		quantity: 258,
	},
	{
		garage_id: 63,
		part_id: 163,
		quantity: 55,
	},
	{
		garage_id: 63,
		part_id: 164,
		quantity: 338,
	},
	{
		garage_id: 63,
		part_id: 165,
		quantity: 343,
	},
	{
		garage_id: 63,
		part_id: 166,
		quantity: 36,
	},
	{
		garage_id: 63,
		part_id: 167,
		quantity: 450,
	},
	{
		garage_id: 63,
		part_id: 168,
		quantity: 419,
	},
	{
		garage_id: 63,
		part_id: 169,
		quantity: 408,
	},
	{
		garage_id: 63,
		part_id: 170,
		quantity: 40,
	},
	{
		garage_id: 63,
		part_id: 171,
		quantity: 282,
	},
	{
		garage_id: 63,
		part_id: 172,
		quantity: 229,
	},
	{
		garage_id: 63,
		part_id: 173,
		quantity: 346,
	},
	{
		garage_id: 63,
		part_id: 174,
		quantity: 343,
	},
	{
		garage_id: 63,
		part_id: 175,
		quantity: 291,
	},
	{
		garage_id: 63,
		part_id: 176,
		quantity: 397,
	},
	{
		garage_id: 63,
		part_id: 177,
		quantity: 410,
	},
	{
		garage_id: 63,
		part_id: 178,
		quantity: 403,
	},
	{
		garage_id: 63,
		part_id: 179,
		quantity: 203,
	},
	{
		garage_id: 63,
		part_id: 180,
		quantity: 442,
	},
	{
		garage_id: 63,
		part_id: 181,
		quantity: 367,
	},
	{
		garage_id: 63,
		part_id: 182,
		quantity: 53,
	},
	{
		garage_id: 63,
		part_id: 183,
		quantity: 317,
	},
	{
		garage_id: 63,
		part_id: 184,
		quantity: 380,
	},
	{
		garage_id: 63,
		part_id: 185,
		quantity: 66,
	},
	{
		garage_id: 63,
		part_id: 186,
		quantity: 2,
	},
	{
		garage_id: 63,
		part_id: 187,
		quantity: 274,
	},
	{
		garage_id: 63,
		part_id: 188,
		quantity: 499,
	},
	{
		garage_id: 63,
		part_id: 189,
		quantity: 349,
	},
	{
		garage_id: 63,
		part_id: 190,
		quantity: 166,
	},
	{
		garage_id: 63,
		part_id: 191,
		quantity: 9,
	},
	{
		garage_id: 63,
		part_id: 192,
		quantity: 32,
	},
	{
		garage_id: 63,
		part_id: 193,
		quantity: 22,
	},
	{
		garage_id: 63,
		part_id: 194,
		quantity: 84,
	},
	{
		garage_id: 63,
		part_id: 195,
		quantity: 323,
	},
	{
		garage_id: 63,
		part_id: 196,
		quantity: 357,
	},
	{
		garage_id: 63,
		part_id: 197,
		quantity: 209,
	},
	{
		garage_id: 63,
		part_id: 198,
		quantity: 345,
	},
	{
		garage_id: 63,
		part_id: 199,
		quantity: 151,
	},
	{
		garage_id: 63,
		part_id: 200,
		quantity: 386,
	},
	{
		garage_id: 63,
		part_id: 201,
		quantity: 105,
	},
	{
		garage_id: 63,
		part_id: 202,
		quantity: 311,
	},
	{
		garage_id: 63,
		part_id: 203,
		quantity: 61,
	},
	{
		garage_id: 63,
		part_id: 204,
		quantity: 170,
	},
	{
		garage_id: 63,
		part_id: 205,
		quantity: 221,
	},
	{
		garage_id: 63,
		part_id: 206,
		quantity: 451,
	},
	{
		garage_id: 63,
		part_id: 207,
		quantity: 362,
	},
	{
		garage_id: 63,
		part_id: 208,
		quantity: 178,
	},
	{
		garage_id: 63,
		part_id: 209,
		quantity: 398,
	},
	{
		garage_id: 63,
		part_id: 210,
		quantity: 25,
	},
	{
		garage_id: 63,
		part_id: 211,
		quantity: 411,
	},
	{
		garage_id: 63,
		part_id: 212,
		quantity: 446,
	},
	{
		garage_id: 63,
		part_id: 213,
		quantity: 261,
	},
	{
		garage_id: 63,
		part_id: 214,
		quantity: 498,
	},
	{
		garage_id: 63,
		part_id: 215,
		quantity: 246,
	},
	{
		garage_id: 63,
		part_id: 216,
		quantity: 383,
	},
	{
		garage_id: 63,
		part_id: 217,
		quantity: 29,
	},
	{
		garage_id: 63,
		part_id: 218,
		quantity: 233,
	},
	{
		garage_id: 63,
		part_id: 219,
		quantity: 298,
	},
	{
		garage_id: 63,
		part_id: 220,
		quantity: 373,
	},
	{
		garage_id: 63,
		part_id: 221,
		quantity: 251,
	},
	{
		garage_id: 63,
		part_id: 222,
		quantity: 254,
	},
	{
		garage_id: 63,
		part_id: 223,
		quantity: 157,
	},
	{
		garage_id: 63,
		part_id: 224,
		quantity: 342,
	},
	{
		garage_id: 63,
		part_id: 225,
		quantity: 257,
	},
	{
		garage_id: 63,
		part_id: 226,
		quantity: 420,
	},
	{
		garage_id: 63,
		part_id: 227,
		quantity: 163,
	},
	{
		garage_id: 63,
		part_id: 228,
		quantity: 152,
	},
	{
		garage_id: 63,
		part_id: 229,
		quantity: 174,
	},
	{
		garage_id: 63,
		part_id: 230,
		quantity: 447,
	},
	{
		garage_id: 63,
		part_id: 231,
		quantity: 4,
	},
	{
		garage_id: 63,
		part_id: 232,
		quantity: 484,
	},
	{
		garage_id: 63,
		part_id: 233,
		quantity: 385,
	},
	{
		garage_id: 63,
		part_id: 234,
		quantity: 443,
	},
	{
		garage_id: 63,
		part_id: 235,
		quantity: 55,
	},
	{
		garage_id: 63,
		part_id: 236,
		quantity: 233,
	},
	{
		garage_id: 63,
		part_id: 237,
		quantity: 23,
	},
	{
		garage_id: 63,
		part_id: 238,
		quantity: 392,
	},
	{
		garage_id: 63,
		part_id: 239,
		quantity: 226,
	},
	{
		garage_id: 63,
		part_id: 240,
		quantity: 331,
	},
	{
		garage_id: 63,
		part_id: 241,
		quantity: 329,
	},
	{
		garage_id: 63,
		part_id: 242,
		quantity: 168,
	},
	{
		garage_id: 63,
		part_id: 243,
		quantity: 462,
	},
	{
		garage_id: 63,
		part_id: 244,
		quantity: 295,
	},
	{
		garage_id: 63,
		part_id: 245,
		quantity: 107,
	},
	{
		garage_id: 63,
		part_id: 246,
		quantity: 149,
	},
	{
		garage_id: 63,
		part_id: 247,
		quantity: 340,
	},
	{
		garage_id: 63,
		part_id: 248,
		quantity: 313,
	},
	{
		garage_id: 63,
		part_id: 249,
		quantity: 158,
	},
	{
		garage_id: 63,
		part_id: 250,
		quantity: 442,
	},
	{
		garage_id: 63,
		part_id: 251,
		quantity: 335,
	},
	{
		garage_id: 63,
		part_id: 252,
		quantity: 196,
	},
	{
		garage_id: 63,
		part_id: 253,
		quantity: 376,
	},
	{
		garage_id: 63,
		part_id: 254,
		quantity: 265,
	},
	{
		garage_id: 63,
		part_id: 255,
		quantity: 77,
	},
	{
		garage_id: 63,
		part_id: 256,
		quantity: 228,
	},
	{
		garage_id: 63,
		part_id: 257,
		quantity: 227,
	},
	{
		garage_id: 63,
		part_id: 258,
		quantity: 466,
	},
	{
		garage_id: 63,
		part_id: 259,
		quantity: 207,
	},
	{
		garage_id: 63,
		part_id: 260,
		quantity: 495,
	},
	{
		garage_id: 63,
		part_id: 261,
		quantity: 185,
	},
	{
		garage_id: 63,
		part_id: 262,
		quantity: 133,
	},
	{
		garage_id: 63,
		part_id: 263,
		quantity: 176,
	},
	{
		garage_id: 63,
		part_id: 264,
		quantity: 488,
	},
	{
		garage_id: 63,
		part_id: 265,
		quantity: 357,
	},
	{
		garage_id: 63,
		part_id: 266,
		quantity: 51,
	},
	{
		garage_id: 63,
		part_id: 267,
		quantity: 391,
	},
	{
		garage_id: 63,
		part_id: 268,
		quantity: 261,
	},
	{
		garage_id: 63,
		part_id: 269,
		quantity: 112,
	},
	{
		garage_id: 63,
		part_id: 270,
		quantity: 16,
	},
	{
		garage_id: 63,
		part_id: 271,
		quantity: 461,
	},
	{
		garage_id: 63,
		part_id: 272,
		quantity: 233,
	},
	{
		garage_id: 63,
		part_id: 273,
		quantity: 484,
	},
	{
		garage_id: 63,
		part_id: 274,
		quantity: 306,
	},
	{
		garage_id: 63,
		part_id: 275,
		quantity: 117,
	},
	{
		garage_id: 63,
		part_id: 276,
		quantity: 193,
	},
	{
		garage_id: 63,
		part_id: 277,
		quantity: 179,
	},
	{
		garage_id: 63,
		part_id: 278,
		quantity: 50,
	},
	{
		garage_id: 63,
		part_id: 279,
		quantity: 437,
	},
	{
		garage_id: 63,
		part_id: 280,
		quantity: 500,
	},
	{
		garage_id: 63,
		part_id: 281,
		quantity: 94,
	},
	{
		garage_id: 63,
		part_id: 282,
		quantity: 352,
	},
	{
		garage_id: 63,
		part_id: 283,
		quantity: 246,
	},
	{
		garage_id: 63,
		part_id: 284,
		quantity: 347,
	},
	{
		garage_id: 63,
		part_id: 285,
		quantity: 292,
	},
	{
		garage_id: 63,
		part_id: 286,
		quantity: 381,
	},
	{
		garage_id: 63,
		part_id: 287,
		quantity: 472,
	},
	{
		garage_id: 63,
		part_id: 288,
		quantity: 309,
	},
	{
		garage_id: 63,
		part_id: 289,
		quantity: 132,
	},
	{
		garage_id: 63,
		part_id: 290,
		quantity: 266,
	},
	{
		garage_id: 63,
		part_id: 291,
		quantity: 474,
	},
	{
		garage_id: 63,
		part_id: 292,
		quantity: 490,
	},
	{
		garage_id: 63,
		part_id: 293,
		quantity: 171,
	},
	{
		garage_id: 63,
		part_id: 294,
		quantity: 379,
	},
	{
		garage_id: 63,
		part_id: 295,
		quantity: 156,
	},
	{
		garage_id: 63,
		part_id: 296,
		quantity: 149,
	},
	{
		garage_id: 63,
		part_id: 297,
		quantity: 220,
	},
	{
		garage_id: 63,
		part_id: 298,
		quantity: 493,
	},
	{
		garage_id: 63,
		part_id: 299,
		quantity: 343,
	},
	{
		garage_id: 63,
		part_id: 300,
		quantity: 75,
	},
	{
		garage_id: 63,
		part_id: 301,
		quantity: 354,
	},
	{
		garage_id: 63,
		part_id: 302,
		quantity: 350,
	},
	{
		garage_id: 63,
		part_id: 303,
		quantity: 186,
	},
	{
		garage_id: 63,
		part_id: 304,
		quantity: 181,
	},
	{
		garage_id: 63,
		part_id: 305,
		quantity: 220,
	},
	{
		garage_id: 63,
		part_id: 306,
		quantity: 420,
	},
	{
		garage_id: 63,
		part_id: 307,
		quantity: 444,
	},
	{
		garage_id: 63,
		part_id: 308,
		quantity: 337,
	},
	{
		garage_id: 63,
		part_id: 309,
		quantity: 106,
	},
	{
		garage_id: 63,
		part_id: 310,
		quantity: 176,
	},
	{
		garage_id: 63,
		part_id: 311,
		quantity: 222,
	},
	{
		garage_id: 63,
		part_id: 312,
		quantity: 164,
	},
	{
		garage_id: 63,
		part_id: 313,
		quantity: 182,
	},
	{
		garage_id: 63,
		part_id: 314,
		quantity: 274,
	},
	{
		garage_id: 63,
		part_id: 315,
		quantity: 79,
	},
	{
		garage_id: 63,
		part_id: 316,
		quantity: 144,
	},
	{
		garage_id: 63,
		part_id: 317,
		quantity: 110,
	},
	{
		garage_id: 63,
		part_id: 318,
		quantity: 10,
	},
	{
		garage_id: 63,
		part_id: 319,
		quantity: 184,
	},
	{
		garage_id: 63,
		part_id: 320,
		quantity: 469,
	},
	{
		garage_id: 63,
		part_id: 321,
		quantity: 89,
	},
	{
		garage_id: 63,
		part_id: 322,
		quantity: 404,
	},
	{
		garage_id: 63,
		part_id: 323,
		quantity: 7,
	},
	{
		garage_id: 63,
		part_id: 324,
		quantity: 457,
	},
	{
		garage_id: 63,
		part_id: 325,
		quantity: 319,
	},
	{
		garage_id: 63,
		part_id: 326,
		quantity: 366,
	},
	{
		garage_id: 63,
		part_id: 327,
		quantity: 201,
	},
	{
		garage_id: 63,
		part_id: 328,
		quantity: 328,
	},
	{
		garage_id: 63,
		part_id: 329,
		quantity: 241,
	},
	{
		garage_id: 63,
		part_id: 330,
		quantity: 124,
	},
	{
		garage_id: 63,
		part_id: 331,
		quantity: 122,
	},
	{
		garage_id: 63,
		part_id: 332,
		quantity: 424,
	},
	{
		garage_id: 63,
		part_id: 333,
		quantity: 229,
	},
	{
		garage_id: 63,
		part_id: 334,
		quantity: 411,
	},
	{
		garage_id: 63,
		part_id: 335,
		quantity: 115,
	},
	{
		garage_id: 63,
		part_id: 336,
		quantity: 98,
	},
	{
		garage_id: 63,
		part_id: 337,
		quantity: 267,
	},
	{
		garage_id: 63,
		part_id: 338,
		quantity: 267,
	},
	{
		garage_id: 63,
		part_id: 339,
		quantity: 197,
	},
	{
		garage_id: 63,
		part_id: 340,
		quantity: 403,
	},
	{
		garage_id: 63,
		part_id: 341,
		quantity: 73,
	},
	{
		garage_id: 63,
		part_id: 342,
		quantity: 43,
	},
	{
		garage_id: 63,
		part_id: 343,
		quantity: 55,
	},
	{
		garage_id: 63,
		part_id: 344,
		quantity: 36,
	},
	{
		garage_id: 63,
		part_id: 345,
		quantity: 173,
	},
	{
		garage_id: 63,
		part_id: 346,
		quantity: 189,
	},
	{
		garage_id: 63,
		part_id: 347,
		quantity: 212,
	},
	{
		garage_id: 63,
		part_id: 348,
		quantity: 491,
	},
	{
		garage_id: 63,
		part_id: 349,
		quantity: 308,
	},
	{
		garage_id: 63,
		part_id: 350,
		quantity: 47,
	},
	{
		garage_id: 63,
		part_id: 351,
		quantity: 410,
	},
	{
		garage_id: 63,
		part_id: 352,
		quantity: 175,
	},
	{
		garage_id: 63,
		part_id: 353,
		quantity: 104,
	},
	{
		garage_id: 63,
		part_id: 354,
		quantity: 154,
	},
	{
		garage_id: 63,
		part_id: 355,
		quantity: 133,
	},
	{
		garage_id: 63,
		part_id: 356,
		quantity: 135,
	},
	{
		garage_id: 63,
		part_id: 357,
		quantity: 394,
	},
	{
		garage_id: 63,
		part_id: 358,
		quantity: 454,
	},
	{
		garage_id: 63,
		part_id: 359,
		quantity: 142,
	},
	{
		garage_id: 63,
		part_id: 360,
		quantity: 233,
	},
	{
		garage_id: 63,
		part_id: 361,
		quantity: 8,
	},
	{
		garage_id: 63,
		part_id: 362,
		quantity: 354,
	},
	{
		garage_id: 63,
		part_id: 363,
		quantity: 385,
	},
	{
		garage_id: 63,
		part_id: 364,
		quantity: 123,
	},
	{
		garage_id: 63,
		part_id: 365,
		quantity: 428,
	},
	{
		garage_id: 63,
		part_id: 366,
		quantity: 102,
	},
	{
		garage_id: 63,
		part_id: 367,
		quantity: 344,
	},
	{
		garage_id: 63,
		part_id: 368,
		quantity: 253,
	},
	{
		garage_id: 63,
		part_id: 369,
		quantity: 495,
	},
	{
		garage_id: 63,
		part_id: 370,
		quantity: 98,
	},
	{
		garage_id: 63,
		part_id: 371,
		quantity: 6,
	},
	{
		garage_id: 63,
		part_id: 372,
		quantity: 165,
	},
	{
		garage_id: 63,
		part_id: 373,
		quantity: 74,
	},
	{
		garage_id: 63,
		part_id: 374,
		quantity: 81,
	},
	{
		garage_id: 63,
		part_id: 375,
		quantity: 69,
	},
	{
		garage_id: 63,
		part_id: 376,
		quantity: 165,
	},
	{
		garage_id: 63,
		part_id: 377,
		quantity: 76,
	},
	{
		garage_id: 63,
		part_id: 378,
		quantity: 122,
	},
	{
		garage_id: 63,
		part_id: 379,
		quantity: 155,
	},
	{
		garage_id: 63,
		part_id: 380,
		quantity: 284,
	},
	{
		garage_id: 63,
		part_id: 381,
		quantity: 35,
	},
	{
		garage_id: 63,
		part_id: 382,
		quantity: 121,
	},
	{
		garage_id: 63,
		part_id: 383,
		quantity: 170,
	},
	{
		garage_id: 63,
		part_id: 384,
		quantity: 12,
	},
	{
		garage_id: 63,
		part_id: 385,
		quantity: 336,
	},
	{
		garage_id: 63,
		part_id: 386,
		quantity: 469,
	},
	{
		garage_id: 63,
		part_id: 387,
		quantity: 161,
	},
	{
		garage_id: 63,
		part_id: 388,
		quantity: 210,
	},
	{
		garage_id: 63,
		part_id: 389,
		quantity: 336,
	},
	{
		garage_id: 63,
		part_id: 390,
		quantity: 376,
	},
	{
		garage_id: 63,
		part_id: 391,
		quantity: 284,
	},
	{
		garage_id: 63,
		part_id: 392,
		quantity: 391,
	},
	{
		garage_id: 63,
		part_id: 393,
		quantity: 446,
	},
	{
		garage_id: 63,
		part_id: 394,
		quantity: 92,
	},
	{
		garage_id: 63,
		part_id: 395,
		quantity: 209,
	},
	{
		garage_id: 63,
		part_id: 396,
		quantity: 333,
	},
	{
		garage_id: 63,
		part_id: 397,
		quantity: 496,
	},
	{
		garage_id: 63,
		part_id: 398,
		quantity: 98,
	},
	{
		garage_id: 63,
		part_id: 399,
		quantity: 444,
	},
	{
		garage_id: 64,
		part_id: 0,
		quantity: 463,
	},
	{
		garage_id: 64,
		part_id: 1,
		quantity: 75,
	},
	{
		garage_id: 64,
		part_id: 2,
		quantity: 495,
	},
	{
		garage_id: 64,
		part_id: 3,
		quantity: 282,
	},
	{
		garage_id: 64,
		part_id: 4,
		quantity: 65,
	},
	{
		garage_id: 64,
		part_id: 5,
		quantity: 398,
	},
	{
		garage_id: 64,
		part_id: 6,
		quantity: 25,
	},
	{
		garage_id: 64,
		part_id: 7,
		quantity: 235,
	},
	{
		garage_id: 64,
		part_id: 8,
		quantity: 6,
	},
	{
		garage_id: 64,
		part_id: 9,
		quantity: 202,
	},
	{
		garage_id: 64,
		part_id: 10,
		quantity: 238,
	},
	{
		garage_id: 64,
		part_id: 11,
		quantity: 473,
	},
	{
		garage_id: 64,
		part_id: 12,
		quantity: 94,
	},
	{
		garage_id: 64,
		part_id: 13,
		quantity: 24,
	},
	{
		garage_id: 64,
		part_id: 14,
		quantity: 341,
	},
	{
		garage_id: 64,
		part_id: 15,
		quantity: 28,
	},
	{
		garage_id: 64,
		part_id: 16,
		quantity: 9,
	},
	{
		garage_id: 64,
		part_id: 17,
		quantity: 234,
	},
	{
		garage_id: 64,
		part_id: 18,
		quantity: 281,
	},
	{
		garage_id: 64,
		part_id: 19,
		quantity: 51,
	},
	{
		garage_id: 64,
		part_id: 20,
		quantity: 196,
	},
	{
		garage_id: 64,
		part_id: 21,
		quantity: 403,
	},
	{
		garage_id: 64,
		part_id: 22,
		quantity: 347,
	},
	{
		garage_id: 64,
		part_id: 23,
		quantity: 60,
	},
	{
		garage_id: 64,
		part_id: 24,
		quantity: 40,
	},
	{
		garage_id: 64,
		part_id: 25,
		quantity: 343,
	},
	{
		garage_id: 64,
		part_id: 26,
		quantity: 401,
	},
	{
		garage_id: 64,
		part_id: 27,
		quantity: 477,
	},
	{
		garage_id: 64,
		part_id: 28,
		quantity: 350,
	},
	{
		garage_id: 64,
		part_id: 29,
		quantity: 14,
	},
	{
		garage_id: 64,
		part_id: 30,
		quantity: 376,
	},
	{
		garage_id: 64,
		part_id: 31,
		quantity: 311,
	},
	{
		garage_id: 64,
		part_id: 32,
		quantity: 333,
	},
	{
		garage_id: 64,
		part_id: 33,
		quantity: 155,
	},
	{
		garage_id: 64,
		part_id: 34,
		quantity: 284,
	},
	{
		garage_id: 64,
		part_id: 35,
		quantity: 111,
	},
	{
		garage_id: 64,
		part_id: 36,
		quantity: 240,
	},
	{
		garage_id: 64,
		part_id: 37,
		quantity: 6,
	},
	{
		garage_id: 64,
		part_id: 38,
		quantity: 364,
	},
	{
		garage_id: 64,
		part_id: 39,
		quantity: 72,
	},
	{
		garage_id: 64,
		part_id: 40,
		quantity: 483,
	},
	{
		garage_id: 64,
		part_id: 41,
		quantity: 496,
	},
	{
		garage_id: 64,
		part_id: 42,
		quantity: 492,
	},
	{
		garage_id: 64,
		part_id: 43,
		quantity: 295,
	},
	{
		garage_id: 64,
		part_id: 44,
		quantity: 160,
	},
	{
		garage_id: 64,
		part_id: 45,
		quantity: 81,
	},
	{
		garage_id: 64,
		part_id: 46,
		quantity: 57,
	},
	{
		garage_id: 64,
		part_id: 47,
		quantity: 65,
	},
	{
		garage_id: 64,
		part_id: 48,
		quantity: 90,
	},
	{
		garage_id: 64,
		part_id: 49,
		quantity: 328,
	},
	{
		garage_id: 64,
		part_id: 50,
		quantity: 48,
	},
	{
		garage_id: 64,
		part_id: 51,
		quantity: 443,
	},
	{
		garage_id: 64,
		part_id: 52,
		quantity: 180,
	},
	{
		garage_id: 64,
		part_id: 53,
		quantity: 310,
	},
	{
		garage_id: 64,
		part_id: 54,
		quantity: 95,
	},
	{
		garage_id: 64,
		part_id: 55,
		quantity: 379,
	},
	{
		garage_id: 64,
		part_id: 56,
		quantity: 41,
	},
	{
		garage_id: 64,
		part_id: 57,
		quantity: 141,
	},
	{
		garage_id: 64,
		part_id: 58,
		quantity: 199,
	},
	{
		garage_id: 64,
		part_id: 59,
		quantity: 166,
	},
	{
		garage_id: 64,
		part_id: 60,
		quantity: 423,
	},
	{
		garage_id: 64,
		part_id: 61,
		quantity: 188,
	},
	{
		garage_id: 64,
		part_id: 62,
		quantity: 271,
	},
	{
		garage_id: 64,
		part_id: 63,
		quantity: 92,
	},
	{
		garage_id: 64,
		part_id: 64,
		quantity: 213,
	},
	{
		garage_id: 64,
		part_id: 65,
		quantity: 211,
	},
	{
		garage_id: 64,
		part_id: 66,
		quantity: 81,
	},
	{
		garage_id: 64,
		part_id: 67,
		quantity: 306,
	},
	{
		garage_id: 64,
		part_id: 68,
		quantity: 304,
	},
	{
		garage_id: 64,
		part_id: 69,
		quantity: 140,
	},
	{
		garage_id: 64,
		part_id: 70,
		quantity: 478,
	},
	{
		garage_id: 64,
		part_id: 71,
		quantity: 231,
	},
	{
		garage_id: 64,
		part_id: 72,
		quantity: 9,
	},
	{
		garage_id: 64,
		part_id: 73,
		quantity: 113,
	},
	{
		garage_id: 64,
		part_id: 74,
		quantity: 33,
	},
	{
		garage_id: 64,
		part_id: 75,
		quantity: 119,
	},
	{
		garage_id: 64,
		part_id: 76,
		quantity: 75,
	},
	{
		garage_id: 64,
		part_id: 77,
		quantity: 404,
	},
	{
		garage_id: 64,
		part_id: 78,
		quantity: 491,
	},
	{
		garage_id: 64,
		part_id: 79,
		quantity: 282,
	},
	{
		garage_id: 64,
		part_id: 80,
		quantity: 478,
	},
	{
		garage_id: 64,
		part_id: 81,
		quantity: 437,
	},
	{
		garage_id: 64,
		part_id: 82,
		quantity: 483,
	},
	{
		garage_id: 64,
		part_id: 83,
		quantity: 71,
	},
	{
		garage_id: 64,
		part_id: 84,
		quantity: 325,
	},
	{
		garage_id: 64,
		part_id: 85,
		quantity: 110,
	},
	{
		garage_id: 64,
		part_id: 86,
		quantity: 448,
	},
	{
		garage_id: 64,
		part_id: 87,
		quantity: 305,
	},
	{
		garage_id: 64,
		part_id: 88,
		quantity: 66,
	},
	{
		garage_id: 64,
		part_id: 89,
		quantity: 139,
	},
	{
		garage_id: 64,
		part_id: 90,
		quantity: 9,
	},
	{
		garage_id: 64,
		part_id: 91,
		quantity: 300,
	},
	{
		garage_id: 64,
		part_id: 92,
		quantity: 397,
	},
	{
		garage_id: 64,
		part_id: 93,
		quantity: 453,
	},
	{
		garage_id: 64,
		part_id: 94,
		quantity: 260,
	},
	{
		garage_id: 64,
		part_id: 95,
		quantity: 169,
	},
	{
		garage_id: 64,
		part_id: 96,
		quantity: 382,
	},
	{
		garage_id: 64,
		part_id: 97,
		quantity: 58,
	},
	{
		garage_id: 64,
		part_id: 98,
		quantity: 489,
	},
	{
		garage_id: 64,
		part_id: 99,
		quantity: 129,
	},
	{
		garage_id: 64,
		part_id: 100,
		quantity: 122,
	},
	{
		garage_id: 64,
		part_id: 101,
		quantity: 46,
	},
	{
		garage_id: 64,
		part_id: 102,
		quantity: 198,
	},
	{
		garage_id: 64,
		part_id: 103,
		quantity: 454,
	},
	{
		garage_id: 64,
		part_id: 104,
		quantity: 134,
	},
	{
		garage_id: 64,
		part_id: 105,
		quantity: 433,
	},
	{
		garage_id: 64,
		part_id: 106,
		quantity: 295,
	},
	{
		garage_id: 64,
		part_id: 107,
		quantity: 34,
	},
	{
		garage_id: 64,
		part_id: 108,
		quantity: 124,
	},
	{
		garage_id: 64,
		part_id: 109,
		quantity: 409,
	},
	{
		garage_id: 64,
		part_id: 110,
		quantity: 398,
	},
	{
		garage_id: 64,
		part_id: 111,
		quantity: 363,
	},
	{
		garage_id: 64,
		part_id: 112,
		quantity: 149,
	},
	{
		garage_id: 64,
		part_id: 113,
		quantity: 142,
	},
	{
		garage_id: 64,
		part_id: 114,
		quantity: 6,
	},
	{
		garage_id: 64,
		part_id: 115,
		quantity: 154,
	},
	{
		garage_id: 64,
		part_id: 116,
		quantity: 307,
	},
	{
		garage_id: 64,
		part_id: 117,
		quantity: 389,
	},
	{
		garage_id: 64,
		part_id: 118,
		quantity: 194,
	},
	{
		garage_id: 64,
		part_id: 119,
		quantity: 100,
	},
	{
		garage_id: 64,
		part_id: 120,
		quantity: 131,
	},
	{
		garage_id: 64,
		part_id: 121,
		quantity: 58,
	},
	{
		garage_id: 64,
		part_id: 122,
		quantity: 366,
	},
	{
		garage_id: 64,
		part_id: 123,
		quantity: 257,
	},
	{
		garage_id: 64,
		part_id: 124,
		quantity: 206,
	},
	{
		garage_id: 64,
		part_id: 125,
		quantity: 301,
	},
	{
		garage_id: 64,
		part_id: 126,
		quantity: 379,
	},
	{
		garage_id: 64,
		part_id: 127,
		quantity: 284,
	},
	{
		garage_id: 64,
		part_id: 128,
		quantity: 215,
	},
	{
		garage_id: 64,
		part_id: 129,
		quantity: 479,
	},
	{
		garage_id: 64,
		part_id: 130,
		quantity: 208,
	},
	{
		garage_id: 64,
		part_id: 131,
		quantity: 255,
	},
	{
		garage_id: 64,
		part_id: 132,
		quantity: 221,
	},
	{
		garage_id: 64,
		part_id: 133,
		quantity: 127,
	},
	{
		garage_id: 64,
		part_id: 134,
		quantity: 149,
	},
	{
		garage_id: 64,
		part_id: 135,
		quantity: 138,
	},
	{
		garage_id: 64,
		part_id: 136,
		quantity: 188,
	},
	{
		garage_id: 64,
		part_id: 137,
		quantity: 90,
	},
	{
		garage_id: 64,
		part_id: 138,
		quantity: 287,
	},
	{
		garage_id: 64,
		part_id: 139,
		quantity: 27,
	},
	{
		garage_id: 64,
		part_id: 140,
		quantity: 345,
	},
	{
		garage_id: 64,
		part_id: 141,
		quantity: 286,
	},
	{
		garage_id: 64,
		part_id: 142,
		quantity: 161,
	},
	{
		garage_id: 64,
		part_id: 143,
		quantity: 226,
	},
	{
		garage_id: 64,
		part_id: 144,
		quantity: 443,
	},
	{
		garage_id: 64,
		part_id: 145,
		quantity: 132,
	},
	{
		garage_id: 64,
		part_id: 146,
		quantity: 48,
	},
	{
		garage_id: 64,
		part_id: 147,
		quantity: 474,
	},
	{
		garage_id: 64,
		part_id: 148,
		quantity: 375,
	},
	{
		garage_id: 64,
		part_id: 149,
		quantity: 80,
	},
	{
		garage_id: 64,
		part_id: 150,
		quantity: 159,
	},
	{
		garage_id: 64,
		part_id: 151,
		quantity: 229,
	},
	{
		garage_id: 64,
		part_id: 152,
		quantity: 204,
	},
	{
		garage_id: 64,
		part_id: 153,
		quantity: 265,
	},
	{
		garage_id: 64,
		part_id: 154,
		quantity: 252,
	},
	{
		garage_id: 64,
		part_id: 155,
		quantity: 34,
	},
	{
		garage_id: 64,
		part_id: 156,
		quantity: 330,
	},
	{
		garage_id: 64,
		part_id: 157,
		quantity: 457,
	},
	{
		garage_id: 64,
		part_id: 158,
		quantity: 104,
	},
	{
		garage_id: 64,
		part_id: 159,
		quantity: 312,
	},
	{
		garage_id: 64,
		part_id: 160,
		quantity: 344,
	},
	{
		garage_id: 64,
		part_id: 161,
		quantity: 380,
	},
	{
		garage_id: 64,
		part_id: 162,
		quantity: 50,
	},
	{
		garage_id: 64,
		part_id: 163,
		quantity: 189,
	},
	{
		garage_id: 64,
		part_id: 164,
		quantity: 293,
	},
	{
		garage_id: 64,
		part_id: 165,
		quantity: 16,
	},
	{
		garage_id: 64,
		part_id: 166,
		quantity: 366,
	},
	{
		garage_id: 64,
		part_id: 167,
		quantity: 328,
	},
	{
		garage_id: 64,
		part_id: 168,
		quantity: 352,
	},
	{
		garage_id: 64,
		part_id: 169,
		quantity: 411,
	},
	{
		garage_id: 64,
		part_id: 170,
		quantity: 248,
	},
	{
		garage_id: 64,
		part_id: 171,
		quantity: 196,
	},
	{
		garage_id: 64,
		part_id: 172,
		quantity: 380,
	},
	{
		garage_id: 64,
		part_id: 173,
		quantity: 71,
	},
	{
		garage_id: 64,
		part_id: 174,
		quantity: 405,
	},
	{
		garage_id: 64,
		part_id: 175,
		quantity: 319,
	},
	{
		garage_id: 64,
		part_id: 176,
		quantity: 323,
	},
	{
		garage_id: 64,
		part_id: 177,
		quantity: 131,
	},
	{
		garage_id: 64,
		part_id: 178,
		quantity: 192,
	},
	{
		garage_id: 64,
		part_id: 179,
		quantity: 127,
	},
	{
		garage_id: 64,
		part_id: 180,
		quantity: 221,
	},
	{
		garage_id: 64,
		part_id: 181,
		quantity: 229,
	},
	{
		garage_id: 64,
		part_id: 182,
		quantity: 241,
	},
	{
		garage_id: 64,
		part_id: 183,
		quantity: 76,
	},
	{
		garage_id: 64,
		part_id: 184,
		quantity: 235,
	},
	{
		garage_id: 64,
		part_id: 185,
		quantity: 435,
	},
	{
		garage_id: 64,
		part_id: 186,
		quantity: 85,
	},
	{
		garage_id: 64,
		part_id: 187,
		quantity: 69,
	},
	{
		garage_id: 64,
		part_id: 188,
		quantity: 306,
	},
	{
		garage_id: 64,
		part_id: 189,
		quantity: 89,
	},
	{
		garage_id: 64,
		part_id: 190,
		quantity: 210,
	},
	{
		garage_id: 64,
		part_id: 191,
		quantity: 348,
	},
	{
		garage_id: 64,
		part_id: 192,
		quantity: 422,
	},
	{
		garage_id: 64,
		part_id: 193,
		quantity: 135,
	},
	{
		garage_id: 64,
		part_id: 194,
		quantity: 315,
	},
	{
		garage_id: 64,
		part_id: 195,
		quantity: 479,
	},
	{
		garage_id: 64,
		part_id: 196,
		quantity: 267,
	},
	{
		garage_id: 64,
		part_id: 197,
		quantity: 450,
	},
	{
		garage_id: 64,
		part_id: 198,
		quantity: 197,
	},
	{
		garage_id: 64,
		part_id: 199,
		quantity: 458,
	},
	{
		garage_id: 64,
		part_id: 200,
		quantity: 265,
	},
	{
		garage_id: 64,
		part_id: 201,
		quantity: 109,
	},
	{
		garage_id: 64,
		part_id: 202,
		quantity: 3,
	},
	{
		garage_id: 64,
		part_id: 203,
		quantity: 462,
	},
	{
		garage_id: 64,
		part_id: 204,
		quantity: 329,
	},
	{
		garage_id: 64,
		part_id: 205,
		quantity: 452,
	},
	{
		garage_id: 64,
		part_id: 206,
		quantity: 371,
	},
	{
		garage_id: 64,
		part_id: 207,
		quantity: 258,
	},
	{
		garage_id: 64,
		part_id: 208,
		quantity: 176,
	},
	{
		garage_id: 64,
		part_id: 209,
		quantity: 367,
	},
	{
		garage_id: 64,
		part_id: 210,
		quantity: 8,
	},
	{
		garage_id: 64,
		part_id: 211,
		quantity: 192,
	},
	{
		garage_id: 64,
		part_id: 212,
		quantity: 490,
	},
	{
		garage_id: 64,
		part_id: 213,
		quantity: 226,
	},
	{
		garage_id: 64,
		part_id: 214,
		quantity: 78,
	},
	{
		garage_id: 64,
		part_id: 215,
		quantity: 331,
	},
	{
		garage_id: 64,
		part_id: 216,
		quantity: 19,
	},
	{
		garage_id: 64,
		part_id: 217,
		quantity: 296,
	},
	{
		garage_id: 64,
		part_id: 218,
		quantity: 24,
	},
	{
		garage_id: 64,
		part_id: 219,
		quantity: 36,
	},
	{
		garage_id: 64,
		part_id: 220,
		quantity: 42,
	},
	{
		garage_id: 64,
		part_id: 221,
		quantity: 304,
	},
	{
		garage_id: 64,
		part_id: 222,
		quantity: 416,
	},
	{
		garage_id: 64,
		part_id: 223,
		quantity: 455,
	},
	{
		garage_id: 64,
		part_id: 224,
		quantity: 490,
	},
	{
		garage_id: 64,
		part_id: 225,
		quantity: 56,
	},
	{
		garage_id: 64,
		part_id: 226,
		quantity: 100,
	},
	{
		garage_id: 64,
		part_id: 227,
		quantity: 363,
	},
	{
		garage_id: 64,
		part_id: 228,
		quantity: 100,
	},
	{
		garage_id: 64,
		part_id: 229,
		quantity: 234,
	},
	{
		garage_id: 64,
		part_id: 230,
		quantity: 149,
	},
	{
		garage_id: 64,
		part_id: 231,
		quantity: 97,
	},
	{
		garage_id: 64,
		part_id: 232,
		quantity: 493,
	},
	{
		garage_id: 64,
		part_id: 233,
		quantity: 298,
	},
	{
		garage_id: 64,
		part_id: 234,
		quantity: 289,
	},
	{
		garage_id: 64,
		part_id: 235,
		quantity: 81,
	},
	{
		garage_id: 64,
		part_id: 236,
		quantity: 373,
	},
	{
		garage_id: 64,
		part_id: 237,
		quantity: 130,
	},
	{
		garage_id: 64,
		part_id: 238,
		quantity: 262,
	},
	{
		garage_id: 64,
		part_id: 239,
		quantity: 112,
	},
	{
		garage_id: 64,
		part_id: 240,
		quantity: 448,
	},
	{
		garage_id: 64,
		part_id: 241,
		quantity: 0,
	},
	{
		garage_id: 64,
		part_id: 242,
		quantity: 94,
	},
	{
		garage_id: 64,
		part_id: 243,
		quantity: 374,
	},
	{
		garage_id: 64,
		part_id: 244,
		quantity: 333,
	},
	{
		garage_id: 64,
		part_id: 245,
		quantity: 50,
	},
	{
		garage_id: 64,
		part_id: 246,
		quantity: 3,
	},
	{
		garage_id: 64,
		part_id: 247,
		quantity: 269,
	},
	{
		garage_id: 64,
		part_id: 248,
		quantity: 230,
	},
	{
		garage_id: 64,
		part_id: 249,
		quantity: 264,
	},
	{
		garage_id: 64,
		part_id: 250,
		quantity: 225,
	},
	{
		garage_id: 64,
		part_id: 251,
		quantity: 0,
	},
	{
		garage_id: 64,
		part_id: 252,
		quantity: 145,
	},
	{
		garage_id: 64,
		part_id: 253,
		quantity: 134,
	},
	{
		garage_id: 64,
		part_id: 254,
		quantity: 366,
	},
	{
		garage_id: 64,
		part_id: 255,
		quantity: 274,
	},
	{
		garage_id: 64,
		part_id: 256,
		quantity: 323,
	},
	{
		garage_id: 64,
		part_id: 257,
		quantity: 191,
	},
	{
		garage_id: 64,
		part_id: 258,
		quantity: 452,
	},
	{
		garage_id: 64,
		part_id: 259,
		quantity: 191,
	},
	{
		garage_id: 64,
		part_id: 260,
		quantity: 316,
	},
	{
		garage_id: 64,
		part_id: 261,
		quantity: 360,
	},
	{
		garage_id: 64,
		part_id: 262,
		quantity: 217,
	},
	{
		garage_id: 64,
		part_id: 263,
		quantity: 340,
	},
	{
		garage_id: 64,
		part_id: 264,
		quantity: 202,
	},
	{
		garage_id: 64,
		part_id: 265,
		quantity: 423,
	},
	{
		garage_id: 64,
		part_id: 266,
		quantity: 97,
	},
	{
		garage_id: 64,
		part_id: 267,
		quantity: 126,
	},
	{
		garage_id: 64,
		part_id: 268,
		quantity: 364,
	},
	{
		garage_id: 64,
		part_id: 269,
		quantity: 103,
	},
	{
		garage_id: 64,
		part_id: 270,
		quantity: 272,
	},
	{
		garage_id: 64,
		part_id: 271,
		quantity: 251,
	},
	{
		garage_id: 64,
		part_id: 272,
		quantity: 139,
	},
	{
		garage_id: 64,
		part_id: 273,
		quantity: 221,
	},
	{
		garage_id: 64,
		part_id: 274,
		quantity: 311,
	},
	{
		garage_id: 64,
		part_id: 275,
		quantity: 398,
	},
	{
		garage_id: 64,
		part_id: 276,
		quantity: 319,
	},
	{
		garage_id: 64,
		part_id: 277,
		quantity: 61,
	},
	{
		garage_id: 64,
		part_id: 278,
		quantity: 332,
	},
	{
		garage_id: 64,
		part_id: 279,
		quantity: 479,
	},
	{
		garage_id: 64,
		part_id: 280,
		quantity: 295,
	},
	{
		garage_id: 64,
		part_id: 281,
		quantity: 99,
	},
	{
		garage_id: 64,
		part_id: 282,
		quantity: 15,
	},
	{
		garage_id: 64,
		part_id: 283,
		quantity: 398,
	},
	{
		garage_id: 64,
		part_id: 284,
		quantity: 436,
	},
	{
		garage_id: 64,
		part_id: 285,
		quantity: 109,
	},
	{
		garage_id: 64,
		part_id: 286,
		quantity: 47,
	},
	{
		garage_id: 64,
		part_id: 287,
		quantity: 188,
	},
	{
		garage_id: 64,
		part_id: 288,
		quantity: 266,
	},
	{
		garage_id: 64,
		part_id: 289,
		quantity: 158,
	},
	{
		garage_id: 64,
		part_id: 290,
		quantity: 395,
	},
	{
		garage_id: 64,
		part_id: 291,
		quantity: 397,
	},
	{
		garage_id: 64,
		part_id: 292,
		quantity: 67,
	},
	{
		garage_id: 64,
		part_id: 293,
		quantity: 174,
	},
	{
		garage_id: 64,
		part_id: 294,
		quantity: 210,
	},
	{
		garage_id: 64,
		part_id: 295,
		quantity: 33,
	},
	{
		garage_id: 64,
		part_id: 296,
		quantity: 444,
	},
	{
		garage_id: 64,
		part_id: 297,
		quantity: 446,
	},
	{
		garage_id: 64,
		part_id: 298,
		quantity: 133,
	},
	{
		garage_id: 64,
		part_id: 299,
		quantity: 76,
	},
	{
		garage_id: 64,
		part_id: 300,
		quantity: 390,
	},
	{
		garage_id: 64,
		part_id: 301,
		quantity: 305,
	},
	{
		garage_id: 64,
		part_id: 302,
		quantity: 113,
	},
	{
		garage_id: 64,
		part_id: 303,
		quantity: 283,
	},
	{
		garage_id: 64,
		part_id: 304,
		quantity: 118,
	},
	{
		garage_id: 64,
		part_id: 305,
		quantity: 57,
	},
	{
		garage_id: 64,
		part_id: 306,
		quantity: 442,
	},
	{
		garage_id: 64,
		part_id: 307,
		quantity: 37,
	},
	{
		garage_id: 64,
		part_id: 308,
		quantity: 161,
	},
	{
		garage_id: 64,
		part_id: 309,
		quantity: 305,
	},
	{
		garage_id: 64,
		part_id: 310,
		quantity: 383,
	},
	{
		garage_id: 64,
		part_id: 311,
		quantity: 90,
	},
	{
		garage_id: 64,
		part_id: 312,
		quantity: 142,
	},
	{
		garage_id: 64,
		part_id: 313,
		quantity: 242,
	},
	{
		garage_id: 64,
		part_id: 314,
		quantity: 18,
	},
	{
		garage_id: 64,
		part_id: 315,
		quantity: 386,
	},
	{
		garage_id: 64,
		part_id: 316,
		quantity: 426,
	},
	{
		garage_id: 64,
		part_id: 317,
		quantity: 46,
	},
	{
		garage_id: 64,
		part_id: 318,
		quantity: 166,
	},
	{
		garage_id: 64,
		part_id: 319,
		quantity: 302,
	},
	{
		garage_id: 64,
		part_id: 320,
		quantity: 269,
	},
	{
		garage_id: 64,
		part_id: 321,
		quantity: 25,
	},
	{
		garage_id: 64,
		part_id: 322,
		quantity: 358,
	},
	{
		garage_id: 64,
		part_id: 323,
		quantity: 423,
	},
	{
		garage_id: 64,
		part_id: 324,
		quantity: 333,
	},
	{
		garage_id: 64,
		part_id: 325,
		quantity: 481,
	},
	{
		garage_id: 64,
		part_id: 326,
		quantity: 231,
	},
	{
		garage_id: 64,
		part_id: 327,
		quantity: 95,
	},
	{
		garage_id: 64,
		part_id: 328,
		quantity: 268,
	},
	{
		garage_id: 64,
		part_id: 329,
		quantity: 174,
	},
	{
		garage_id: 64,
		part_id: 330,
		quantity: 453,
	},
	{
		garage_id: 64,
		part_id: 331,
		quantity: 331,
	},
	{
		garage_id: 64,
		part_id: 332,
		quantity: 341,
	},
	{
		garage_id: 64,
		part_id: 333,
		quantity: 153,
	},
	{
		garage_id: 64,
		part_id: 334,
		quantity: 389,
	},
	{
		garage_id: 64,
		part_id: 335,
		quantity: 496,
	},
	{
		garage_id: 64,
		part_id: 336,
		quantity: 229,
	},
	{
		garage_id: 64,
		part_id: 337,
		quantity: 478,
	},
	{
		garage_id: 64,
		part_id: 338,
		quantity: 267,
	},
	{
		garage_id: 64,
		part_id: 339,
		quantity: 390,
	},
	{
		garage_id: 64,
		part_id: 340,
		quantity: 309,
	},
	{
		garage_id: 64,
		part_id: 341,
		quantity: 159,
	},
	{
		garage_id: 64,
		part_id: 342,
		quantity: 36,
	},
	{
		garage_id: 64,
		part_id: 343,
		quantity: 12,
	},
	{
		garage_id: 64,
		part_id: 344,
		quantity: 445,
	},
	{
		garage_id: 64,
		part_id: 345,
		quantity: 308,
	},
	{
		garage_id: 64,
		part_id: 346,
		quantity: 246,
	},
	{
		garage_id: 64,
		part_id: 347,
		quantity: 252,
	},
	{
		garage_id: 64,
		part_id: 348,
		quantity: 439,
	},
	{
		garage_id: 64,
		part_id: 349,
		quantity: 71,
	},
	{
		garage_id: 64,
		part_id: 350,
		quantity: 439,
	},
	{
		garage_id: 64,
		part_id: 351,
		quantity: 304,
	},
	{
		garage_id: 64,
		part_id: 352,
		quantity: 384,
	},
	{
		garage_id: 64,
		part_id: 353,
		quantity: 242,
	},
	{
		garage_id: 64,
		part_id: 354,
		quantity: 42,
	},
	{
		garage_id: 64,
		part_id: 355,
		quantity: 118,
	},
	{
		garage_id: 64,
		part_id: 356,
		quantity: 5,
	},
	{
		garage_id: 64,
		part_id: 357,
		quantity: 373,
	},
	{
		garage_id: 64,
		part_id: 358,
		quantity: 368,
	},
	{
		garage_id: 64,
		part_id: 359,
		quantity: 364,
	},
	{
		garage_id: 64,
		part_id: 360,
		quantity: 202,
	},
	{
		garage_id: 64,
		part_id: 361,
		quantity: 335,
	},
	{
		garage_id: 64,
		part_id: 362,
		quantity: 435,
	},
	{
		garage_id: 64,
		part_id: 363,
		quantity: 201,
	},
	{
		garage_id: 64,
		part_id: 364,
		quantity: 215,
	},
	{
		garage_id: 64,
		part_id: 365,
		quantity: 238,
	},
	{
		garage_id: 64,
		part_id: 366,
		quantity: 355,
	},
	{
		garage_id: 64,
		part_id: 367,
		quantity: 290,
	},
	{
		garage_id: 64,
		part_id: 368,
		quantity: 387,
	},
	{
		garage_id: 64,
		part_id: 369,
		quantity: 284,
	},
	{
		garage_id: 64,
		part_id: 370,
		quantity: 333,
	},
	{
		garage_id: 64,
		part_id: 371,
		quantity: 60,
	},
	{
		garage_id: 64,
		part_id: 372,
		quantity: 88,
	},
	{
		garage_id: 64,
		part_id: 373,
		quantity: 472,
	},
	{
		garage_id: 64,
		part_id: 374,
		quantity: 392,
	},
	{
		garage_id: 64,
		part_id: 375,
		quantity: 427,
	},
	{
		garage_id: 64,
		part_id: 376,
		quantity: 214,
	},
	{
		garage_id: 64,
		part_id: 377,
		quantity: 241,
	},
	{
		garage_id: 64,
		part_id: 378,
		quantity: 176,
	},
	{
		garage_id: 64,
		part_id: 379,
		quantity: 435,
	},
	{
		garage_id: 64,
		part_id: 380,
		quantity: 310,
	},
	{
		garage_id: 64,
		part_id: 381,
		quantity: 306,
	},
	{
		garage_id: 64,
		part_id: 382,
		quantity: 278,
	},
	{
		garage_id: 64,
		part_id: 383,
		quantity: 484,
	},
	{
		garage_id: 64,
		part_id: 384,
		quantity: 277,
	},
	{
		garage_id: 64,
		part_id: 385,
		quantity: 78,
	},
	{
		garage_id: 64,
		part_id: 386,
		quantity: 346,
	},
	{
		garage_id: 64,
		part_id: 387,
		quantity: 440,
	},
	{
		garage_id: 64,
		part_id: 388,
		quantity: 80,
	},
	{
		garage_id: 64,
		part_id: 389,
		quantity: 318,
	},
	{
		garage_id: 64,
		part_id: 390,
		quantity: 241,
	},
	{
		garage_id: 64,
		part_id: 391,
		quantity: 411,
	},
	{
		garage_id: 64,
		part_id: 392,
		quantity: 321,
	},
	{
		garage_id: 64,
		part_id: 393,
		quantity: 40,
	},
	{
		garage_id: 64,
		part_id: 394,
		quantity: 220,
	},
	{
		garage_id: 64,
		part_id: 395,
		quantity: 474,
	},
	{
		garage_id: 64,
		part_id: 396,
		quantity: 336,
	},
	{
		garage_id: 64,
		part_id: 397,
		quantity: 378,
	},
	{
		garage_id: 64,
		part_id: 398,
		quantity: 25,
	},
	{
		garage_id: 64,
		part_id: 399,
		quantity: 268,
	},
	{
		garage_id: 65,
		part_id: 0,
		quantity: 236,
	},
	{
		garage_id: 65,
		part_id: 1,
		quantity: 230,
	},
	{
		garage_id: 65,
		part_id: 2,
		quantity: 198,
	},
	{
		garage_id: 65,
		part_id: 3,
		quantity: 275,
	},
	{
		garage_id: 65,
		part_id: 4,
		quantity: 223,
	},
	{
		garage_id: 65,
		part_id: 5,
		quantity: 431,
	},
	{
		garage_id: 65,
		part_id: 6,
		quantity: 478,
	},
	{
		garage_id: 65,
		part_id: 7,
		quantity: 173,
	},
	{
		garage_id: 65,
		part_id: 8,
		quantity: 344,
	},
	{
		garage_id: 65,
		part_id: 9,
		quantity: 342,
	},
	{
		garage_id: 65,
		part_id: 10,
		quantity: 456,
	},
	{
		garage_id: 65,
		part_id: 11,
		quantity: 159,
	},
	{
		garage_id: 65,
		part_id: 12,
		quantity: 258,
	},
	{
		garage_id: 65,
		part_id: 13,
		quantity: 203,
	},
	{
		garage_id: 65,
		part_id: 14,
		quantity: 211,
	},
	{
		garage_id: 65,
		part_id: 15,
		quantity: 75,
	},
	{
		garage_id: 65,
		part_id: 16,
		quantity: 311,
	},
	{
		garage_id: 65,
		part_id: 17,
		quantity: 309,
	},
	{
		garage_id: 65,
		part_id: 18,
		quantity: 266,
	},
	{
		garage_id: 65,
		part_id: 19,
		quantity: 464,
	},
	{
		garage_id: 65,
		part_id: 20,
		quantity: 404,
	},
	{
		garage_id: 65,
		part_id: 21,
		quantity: 33,
	},
	{
		garage_id: 65,
		part_id: 22,
		quantity: 263,
	},
	{
		garage_id: 65,
		part_id: 23,
		quantity: 48,
	},
	{
		garage_id: 65,
		part_id: 24,
		quantity: 168,
	},
	{
		garage_id: 65,
		part_id: 25,
		quantity: 500,
	},
	{
		garage_id: 65,
		part_id: 26,
		quantity: 359,
	},
	{
		garage_id: 65,
		part_id: 27,
		quantity: 280,
	},
	{
		garage_id: 65,
		part_id: 28,
		quantity: 360,
	},
	{
		garage_id: 65,
		part_id: 29,
		quantity: 397,
	},
	{
		garage_id: 65,
		part_id: 30,
		quantity: 435,
	},
	{
		garage_id: 65,
		part_id: 31,
		quantity: 90,
	},
	{
		garage_id: 65,
		part_id: 32,
		quantity: 195,
	},
	{
		garage_id: 65,
		part_id: 33,
		quantity: 423,
	},
	{
		garage_id: 65,
		part_id: 34,
		quantity: 308,
	},
	{
		garage_id: 65,
		part_id: 35,
		quantity: 73,
	},
	{
		garage_id: 65,
		part_id: 36,
		quantity: 149,
	},
	{
		garage_id: 65,
		part_id: 37,
		quantity: 111,
	},
	{
		garage_id: 65,
		part_id: 38,
		quantity: 307,
	},
	{
		garage_id: 65,
		part_id: 39,
		quantity: 466,
	},
	{
		garage_id: 65,
		part_id: 40,
		quantity: 355,
	},
	{
		garage_id: 65,
		part_id: 41,
		quantity: 116,
	},
	{
		garage_id: 65,
		part_id: 42,
		quantity: 160,
	},
	{
		garage_id: 65,
		part_id: 43,
		quantity: 301,
	},
	{
		garage_id: 65,
		part_id: 44,
		quantity: 152,
	},
	{
		garage_id: 65,
		part_id: 45,
		quantity: 43,
	},
	{
		garage_id: 65,
		part_id: 46,
		quantity: 25,
	},
	{
		garage_id: 65,
		part_id: 47,
		quantity: 302,
	},
	{
		garage_id: 65,
		part_id: 48,
		quantity: 365,
	},
	{
		garage_id: 65,
		part_id: 49,
		quantity: 442,
	},
	{
		garage_id: 65,
		part_id: 50,
		quantity: 17,
	},
	{
		garage_id: 65,
		part_id: 51,
		quantity: 268,
	},
	{
		garage_id: 65,
		part_id: 52,
		quantity: 105,
	},
	{
		garage_id: 65,
		part_id: 53,
		quantity: 343,
	},
	{
		garage_id: 65,
		part_id: 54,
		quantity: 321,
	},
	{
		garage_id: 65,
		part_id: 55,
		quantity: 243,
	},
	{
		garage_id: 65,
		part_id: 56,
		quantity: 251,
	},
	{
		garage_id: 65,
		part_id: 57,
		quantity: 231,
	},
	{
		garage_id: 65,
		part_id: 58,
		quantity: 163,
	},
	{
		garage_id: 65,
		part_id: 59,
		quantity: 165,
	},
	{
		garage_id: 65,
		part_id: 60,
		quantity: 428,
	},
	{
		garage_id: 65,
		part_id: 61,
		quantity: 483,
	},
	{
		garage_id: 65,
		part_id: 62,
		quantity: 227,
	},
	{
		garage_id: 65,
		part_id: 63,
		quantity: 295,
	},
	{
		garage_id: 65,
		part_id: 64,
		quantity: 317,
	},
	{
		garage_id: 65,
		part_id: 65,
		quantity: 333,
	},
	{
		garage_id: 65,
		part_id: 66,
		quantity: 499,
	},
	{
		garage_id: 65,
		part_id: 67,
		quantity: 445,
	},
	{
		garage_id: 65,
		part_id: 68,
		quantity: 425,
	},
	{
		garage_id: 65,
		part_id: 69,
		quantity: 116,
	},
	{
		garage_id: 65,
		part_id: 70,
		quantity: 341,
	},
	{
		garage_id: 65,
		part_id: 71,
		quantity: 289,
	},
	{
		garage_id: 65,
		part_id: 72,
		quantity: 391,
	},
	{
		garage_id: 65,
		part_id: 73,
		quantity: 344,
	},
	{
		garage_id: 65,
		part_id: 74,
		quantity: 199,
	},
	{
		garage_id: 65,
		part_id: 75,
		quantity: 491,
	},
	{
		garage_id: 65,
		part_id: 76,
		quantity: 480,
	},
	{
		garage_id: 65,
		part_id: 77,
		quantity: 260,
	},
	{
		garage_id: 65,
		part_id: 78,
		quantity: 114,
	},
	{
		garage_id: 65,
		part_id: 79,
		quantity: 405,
	},
	{
		garage_id: 65,
		part_id: 80,
		quantity: 120,
	},
	{
		garage_id: 65,
		part_id: 81,
		quantity: 10,
	},
	{
		garage_id: 65,
		part_id: 82,
		quantity: 463,
	},
	{
		garage_id: 65,
		part_id: 83,
		quantity: 339,
	},
	{
		garage_id: 65,
		part_id: 84,
		quantity: 368,
	},
	{
		garage_id: 65,
		part_id: 85,
		quantity: 303,
	},
	{
		garage_id: 65,
		part_id: 86,
		quantity: 97,
	},
	{
		garage_id: 65,
		part_id: 87,
		quantity: 113,
	},
	{
		garage_id: 65,
		part_id: 88,
		quantity: 183,
	},
	{
		garage_id: 65,
		part_id: 89,
		quantity: 116,
	},
	{
		garage_id: 65,
		part_id: 90,
		quantity: 88,
	},
	{
		garage_id: 65,
		part_id: 91,
		quantity: 293,
	},
	{
		garage_id: 65,
		part_id: 92,
		quantity: 326,
	},
	{
		garage_id: 65,
		part_id: 93,
		quantity: 196,
	},
	{
		garage_id: 65,
		part_id: 94,
		quantity: 52,
	},
	{
		garage_id: 65,
		part_id: 95,
		quantity: 405,
	},
	{
		garage_id: 65,
		part_id: 96,
		quantity: 31,
	},
	{
		garage_id: 65,
		part_id: 97,
		quantity: 487,
	},
	{
		garage_id: 65,
		part_id: 98,
		quantity: 468,
	},
	{
		garage_id: 65,
		part_id: 99,
		quantity: 290,
	},
	{
		garage_id: 65,
		part_id: 100,
		quantity: 41,
	},
	{
		garage_id: 65,
		part_id: 101,
		quantity: 320,
	},
	{
		garage_id: 65,
		part_id: 102,
		quantity: 354,
	},
	{
		garage_id: 65,
		part_id: 103,
		quantity: 452,
	},
	{
		garage_id: 65,
		part_id: 104,
		quantity: 339,
	},
	{
		garage_id: 65,
		part_id: 105,
		quantity: 240,
	},
	{
		garage_id: 65,
		part_id: 106,
		quantity: 332,
	},
	{
		garage_id: 65,
		part_id: 107,
		quantity: 442,
	},
	{
		garage_id: 65,
		part_id: 108,
		quantity: 180,
	},
	{
		garage_id: 65,
		part_id: 109,
		quantity: 342,
	},
	{
		garage_id: 65,
		part_id: 110,
		quantity: 110,
	},
	{
		garage_id: 65,
		part_id: 111,
		quantity: 106,
	},
	{
		garage_id: 65,
		part_id: 112,
		quantity: 386,
	},
	{
		garage_id: 65,
		part_id: 113,
		quantity: 250,
	},
	{
		garage_id: 65,
		part_id: 114,
		quantity: 103,
	},
	{
		garage_id: 65,
		part_id: 115,
		quantity: 357,
	},
	{
		garage_id: 65,
		part_id: 116,
		quantity: 258,
	},
	{
		garage_id: 65,
		part_id: 117,
		quantity: 366,
	},
	{
		garage_id: 65,
		part_id: 118,
		quantity: 296,
	},
	{
		garage_id: 65,
		part_id: 119,
		quantity: 351,
	},
	{
		garage_id: 65,
		part_id: 120,
		quantity: 373,
	},
	{
		garage_id: 65,
		part_id: 121,
		quantity: 448,
	},
	{
		garage_id: 65,
		part_id: 122,
		quantity: 298,
	},
	{
		garage_id: 65,
		part_id: 123,
		quantity: 376,
	},
	{
		garage_id: 65,
		part_id: 124,
		quantity: 118,
	},
	{
		garage_id: 65,
		part_id: 125,
		quantity: 3,
	},
	{
		garage_id: 65,
		part_id: 126,
		quantity: 332,
	},
	{
		garage_id: 65,
		part_id: 127,
		quantity: 265,
	},
	{
		garage_id: 65,
		part_id: 128,
		quantity: 89,
	},
	{
		garage_id: 65,
		part_id: 129,
		quantity: 252,
	},
	{
		garage_id: 65,
		part_id: 130,
		quantity: 214,
	},
	{
		garage_id: 65,
		part_id: 131,
		quantity: 431,
	},
	{
		garage_id: 65,
		part_id: 132,
		quantity: 139,
	},
	{
		garage_id: 65,
		part_id: 133,
		quantity: 404,
	},
	{
		garage_id: 65,
		part_id: 134,
		quantity: 370,
	},
	{
		garage_id: 65,
		part_id: 135,
		quantity: 32,
	},
	{
		garage_id: 65,
		part_id: 136,
		quantity: 443,
	},
	{
		garage_id: 65,
		part_id: 137,
		quantity: 477,
	},
	{
		garage_id: 65,
		part_id: 138,
		quantity: 95,
	},
	{
		garage_id: 65,
		part_id: 139,
		quantity: 254,
	},
	{
		garage_id: 65,
		part_id: 140,
		quantity: 240,
	},
	{
		garage_id: 65,
		part_id: 141,
		quantity: 260,
	},
	{
		garage_id: 65,
		part_id: 142,
		quantity: 369,
	},
	{
		garage_id: 65,
		part_id: 143,
		quantity: 324,
	},
	{
		garage_id: 65,
		part_id: 144,
		quantity: 34,
	},
	{
		garage_id: 65,
		part_id: 145,
		quantity: 355,
	},
	{
		garage_id: 65,
		part_id: 146,
		quantity: 163,
	},
	{
		garage_id: 65,
		part_id: 147,
		quantity: 336,
	},
	{
		garage_id: 65,
		part_id: 148,
		quantity: 233,
	},
	{
		garage_id: 65,
		part_id: 149,
		quantity: 361,
	},
	{
		garage_id: 65,
		part_id: 150,
		quantity: 178,
	},
	{
		garage_id: 65,
		part_id: 151,
		quantity: 301,
	},
	{
		garage_id: 65,
		part_id: 152,
		quantity: 90,
	},
	{
		garage_id: 65,
		part_id: 153,
		quantity: 31,
	},
	{
		garage_id: 65,
		part_id: 154,
		quantity: 290,
	},
	{
		garage_id: 65,
		part_id: 155,
		quantity: 445,
	},
	{
		garage_id: 65,
		part_id: 156,
		quantity: 416,
	},
	{
		garage_id: 65,
		part_id: 157,
		quantity: 262,
	},
	{
		garage_id: 65,
		part_id: 158,
		quantity: 454,
	},
	{
		garage_id: 65,
		part_id: 159,
		quantity: 492,
	},
	{
		garage_id: 65,
		part_id: 160,
		quantity: 170,
	},
	{
		garage_id: 65,
		part_id: 161,
		quantity: 323,
	},
	{
		garage_id: 65,
		part_id: 162,
		quantity: 82,
	},
	{
		garage_id: 65,
		part_id: 163,
		quantity: 465,
	},
	{
		garage_id: 65,
		part_id: 164,
		quantity: 28,
	},
	{
		garage_id: 65,
		part_id: 165,
		quantity: 95,
	},
	{
		garage_id: 65,
		part_id: 166,
		quantity: 252,
	},
	{
		garage_id: 65,
		part_id: 167,
		quantity: 312,
	},
	{
		garage_id: 65,
		part_id: 168,
		quantity: 4,
	},
	{
		garage_id: 65,
		part_id: 169,
		quantity: 90,
	},
	{
		garage_id: 65,
		part_id: 170,
		quantity: 100,
	},
	{
		garage_id: 65,
		part_id: 171,
		quantity: 65,
	},
	{
		garage_id: 65,
		part_id: 172,
		quantity: 294,
	},
	{
		garage_id: 65,
		part_id: 173,
		quantity: 393,
	},
	{
		garage_id: 65,
		part_id: 174,
		quantity: 192,
	},
	{
		garage_id: 65,
		part_id: 175,
		quantity: 55,
	},
	{
		garage_id: 65,
		part_id: 176,
		quantity: 72,
	},
	{
		garage_id: 65,
		part_id: 177,
		quantity: 165,
	},
	{
		garage_id: 65,
		part_id: 178,
		quantity: 42,
	},
	{
		garage_id: 65,
		part_id: 179,
		quantity: 22,
	},
	{
		garage_id: 65,
		part_id: 180,
		quantity: 127,
	},
	{
		garage_id: 65,
		part_id: 181,
		quantity: 37,
	},
	{
		garage_id: 65,
		part_id: 182,
		quantity: 208,
	},
	{
		garage_id: 65,
		part_id: 183,
		quantity: 234,
	},
	{
		garage_id: 65,
		part_id: 184,
		quantity: 426,
	},
	{
		garage_id: 65,
		part_id: 185,
		quantity: 13,
	},
	{
		garage_id: 65,
		part_id: 186,
		quantity: 277,
	},
	{
		garage_id: 65,
		part_id: 187,
		quantity: 208,
	},
	{
		garage_id: 65,
		part_id: 188,
		quantity: 62,
	},
	{
		garage_id: 65,
		part_id: 189,
		quantity: 378,
	},
	{
		garage_id: 65,
		part_id: 190,
		quantity: 267,
	},
	{
		garage_id: 65,
		part_id: 191,
		quantity: 306,
	},
	{
		garage_id: 65,
		part_id: 192,
		quantity: 488,
	},
	{
		garage_id: 65,
		part_id: 193,
		quantity: 26,
	},
	{
		garage_id: 65,
		part_id: 194,
		quantity: 243,
	},
	{
		garage_id: 65,
		part_id: 195,
		quantity: 333,
	},
	{
		garage_id: 65,
		part_id: 196,
		quantity: 287,
	},
	{
		garage_id: 65,
		part_id: 197,
		quantity: 236,
	},
	{
		garage_id: 65,
		part_id: 198,
		quantity: 234,
	},
	{
		garage_id: 65,
		part_id: 199,
		quantity: 53,
	},
	{
		garage_id: 65,
		part_id: 200,
		quantity: 297,
	},
	{
		garage_id: 65,
		part_id: 201,
		quantity: 466,
	},
	{
		garage_id: 65,
		part_id: 202,
		quantity: 324,
	},
	{
		garage_id: 65,
		part_id: 203,
		quantity: 276,
	},
	{
		garage_id: 65,
		part_id: 204,
		quantity: 160,
	},
	{
		garage_id: 65,
		part_id: 205,
		quantity: 276,
	},
	{
		garage_id: 65,
		part_id: 206,
		quantity: 275,
	},
	{
		garage_id: 65,
		part_id: 207,
		quantity: 438,
	},
	{
		garage_id: 65,
		part_id: 208,
		quantity: 386,
	},
	{
		garage_id: 65,
		part_id: 209,
		quantity: 401,
	},
	{
		garage_id: 65,
		part_id: 210,
		quantity: 447,
	},
	{
		garage_id: 65,
		part_id: 211,
		quantity: 464,
	},
	{
		garage_id: 65,
		part_id: 212,
		quantity: 218,
	},
	{
		garage_id: 65,
		part_id: 213,
		quantity: 335,
	},
	{
		garage_id: 65,
		part_id: 214,
		quantity: 216,
	},
	{
		garage_id: 65,
		part_id: 215,
		quantity: 38,
	},
	{
		garage_id: 65,
		part_id: 216,
		quantity: 191,
	},
	{
		garage_id: 65,
		part_id: 217,
		quantity: 450,
	},
	{
		garage_id: 65,
		part_id: 218,
		quantity: 389,
	},
	{
		garage_id: 65,
		part_id: 219,
		quantity: 396,
	},
	{
		garage_id: 65,
		part_id: 220,
		quantity: 372,
	},
	{
		garage_id: 65,
		part_id: 221,
		quantity: 131,
	},
	{
		garage_id: 65,
		part_id: 222,
		quantity: 185,
	},
	{
		garage_id: 65,
		part_id: 223,
		quantity: 181,
	},
	{
		garage_id: 65,
		part_id: 224,
		quantity: 19,
	},
	{
		garage_id: 65,
		part_id: 225,
		quantity: 490,
	},
	{
		garage_id: 65,
		part_id: 226,
		quantity: 160,
	},
	{
		garage_id: 65,
		part_id: 227,
		quantity: 319,
	},
	{
		garage_id: 65,
		part_id: 228,
		quantity: 454,
	},
	{
		garage_id: 65,
		part_id: 229,
		quantity: 166,
	},
	{
		garage_id: 65,
		part_id: 230,
		quantity: 160,
	},
	{
		garage_id: 65,
		part_id: 231,
		quantity: 384,
	},
	{
		garage_id: 65,
		part_id: 232,
		quantity: 13,
	},
	{
		garage_id: 65,
		part_id: 233,
		quantity: 46,
	},
	{
		garage_id: 65,
		part_id: 234,
		quantity: 493,
	},
	{
		garage_id: 65,
		part_id: 235,
		quantity: 232,
	},
	{
		garage_id: 65,
		part_id: 236,
		quantity: 221,
	},
	{
		garage_id: 65,
		part_id: 237,
		quantity: 162,
	},
	{
		garage_id: 65,
		part_id: 238,
		quantity: 252,
	},
	{
		garage_id: 65,
		part_id: 239,
		quantity: 260,
	},
	{
		garage_id: 65,
		part_id: 240,
		quantity: 352,
	},
	{
		garage_id: 65,
		part_id: 241,
		quantity: 148,
	},
	{
		garage_id: 65,
		part_id: 242,
		quantity: 324,
	},
	{
		garage_id: 65,
		part_id: 243,
		quantity: 309,
	},
	{
		garage_id: 65,
		part_id: 244,
		quantity: 198,
	},
	{
		garage_id: 65,
		part_id: 245,
		quantity: 390,
	},
	{
		garage_id: 65,
		part_id: 246,
		quantity: 344,
	},
	{
		garage_id: 65,
		part_id: 247,
		quantity: 301,
	},
	{
		garage_id: 65,
		part_id: 248,
		quantity: 296,
	},
	{
		garage_id: 65,
		part_id: 249,
		quantity: 229,
	},
	{
		garage_id: 65,
		part_id: 250,
		quantity: 299,
	},
	{
		garage_id: 65,
		part_id: 251,
		quantity: 462,
	},
	{
		garage_id: 65,
		part_id: 252,
		quantity: 324,
	},
	{
		garage_id: 65,
		part_id: 253,
		quantity: 112,
	},
	{
		garage_id: 65,
		part_id: 254,
		quantity: 373,
	},
	{
		garage_id: 65,
		part_id: 255,
		quantity: 196,
	},
	{
		garage_id: 65,
		part_id: 256,
		quantity: 261,
	},
	{
		garage_id: 65,
		part_id: 257,
		quantity: 251,
	},
	{
		garage_id: 65,
		part_id: 258,
		quantity: 85,
	},
	{
		garage_id: 65,
		part_id: 259,
		quantity: 260,
	},
	{
		garage_id: 65,
		part_id: 260,
		quantity: 235,
	},
	{
		garage_id: 65,
		part_id: 261,
		quantity: 426,
	},
	{
		garage_id: 65,
		part_id: 262,
		quantity: 271,
	},
	{
		garage_id: 65,
		part_id: 263,
		quantity: 443,
	},
	{
		garage_id: 65,
		part_id: 264,
		quantity: 326,
	},
	{
		garage_id: 65,
		part_id: 265,
		quantity: 257,
	},
	{
		garage_id: 65,
		part_id: 266,
		quantity: 423,
	},
	{
		garage_id: 65,
		part_id: 267,
		quantity: 234,
	},
	{
		garage_id: 65,
		part_id: 268,
		quantity: 137,
	},
	{
		garage_id: 65,
		part_id: 269,
		quantity: 195,
	},
	{
		garage_id: 65,
		part_id: 270,
		quantity: 150,
	},
	{
		garage_id: 65,
		part_id: 271,
		quantity: 395,
	},
	{
		garage_id: 65,
		part_id: 272,
		quantity: 126,
	},
	{
		garage_id: 65,
		part_id: 273,
		quantity: 340,
	},
	{
		garage_id: 65,
		part_id: 274,
		quantity: 384,
	},
	{
		garage_id: 65,
		part_id: 275,
		quantity: 10,
	},
	{
		garage_id: 65,
		part_id: 276,
		quantity: 437,
	},
	{
		garage_id: 65,
		part_id: 277,
		quantity: 415,
	},
	{
		garage_id: 65,
		part_id: 278,
		quantity: 254,
	},
	{
		garage_id: 65,
		part_id: 279,
		quantity: 40,
	},
	{
		garage_id: 65,
		part_id: 280,
		quantity: 375,
	},
	{
		garage_id: 65,
		part_id: 281,
		quantity: 276,
	},
	{
		garage_id: 65,
		part_id: 282,
		quantity: 290,
	},
	{
		garage_id: 65,
		part_id: 283,
		quantity: 134,
	},
	{
		garage_id: 65,
		part_id: 284,
		quantity: 3,
	},
	{
		garage_id: 65,
		part_id: 285,
		quantity: 170,
	},
	{
		garage_id: 65,
		part_id: 286,
		quantity: 221,
	},
	{
		garage_id: 65,
		part_id: 287,
		quantity: 442,
	},
	{
		garage_id: 65,
		part_id: 288,
		quantity: 247,
	},
	{
		garage_id: 65,
		part_id: 289,
		quantity: 208,
	},
	{
		garage_id: 65,
		part_id: 290,
		quantity: 135,
	},
	{
		garage_id: 65,
		part_id: 291,
		quantity: 435,
	},
	{
		garage_id: 65,
		part_id: 292,
		quantity: 403,
	},
	{
		garage_id: 65,
		part_id: 293,
		quantity: 422,
	},
	{
		garage_id: 65,
		part_id: 294,
		quantity: 479,
	},
	{
		garage_id: 65,
		part_id: 295,
		quantity: 246,
	},
	{
		garage_id: 65,
		part_id: 296,
		quantity: 235,
	},
	{
		garage_id: 65,
		part_id: 297,
		quantity: 426,
	},
	{
		garage_id: 65,
		part_id: 298,
		quantity: 34,
	},
	{
		garage_id: 65,
		part_id: 299,
		quantity: 395,
	},
	{
		garage_id: 65,
		part_id: 300,
		quantity: 291,
	},
	{
		garage_id: 65,
		part_id: 301,
		quantity: 211,
	},
	{
		garage_id: 65,
		part_id: 302,
		quantity: 498,
	},
	{
		garage_id: 65,
		part_id: 303,
		quantity: 46,
	},
	{
		garage_id: 65,
		part_id: 304,
		quantity: 160,
	},
	{
		garage_id: 65,
		part_id: 305,
		quantity: 35,
	},
	{
		garage_id: 65,
		part_id: 306,
		quantity: 117,
	},
	{
		garage_id: 65,
		part_id: 307,
		quantity: 489,
	},
	{
		garage_id: 65,
		part_id: 308,
		quantity: 461,
	},
	{
		garage_id: 65,
		part_id: 309,
		quantity: 393,
	},
	{
		garage_id: 65,
		part_id: 310,
		quantity: 464,
	},
	{
		garage_id: 65,
		part_id: 311,
		quantity: 110,
	},
	{
		garage_id: 65,
		part_id: 312,
		quantity: 186,
	},
	{
		garage_id: 65,
		part_id: 313,
		quantity: 313,
	},
	{
		garage_id: 65,
		part_id: 314,
		quantity: 429,
	},
	{
		garage_id: 65,
		part_id: 315,
		quantity: 139,
	},
	{
		garage_id: 65,
		part_id: 316,
		quantity: 422,
	},
	{
		garage_id: 65,
		part_id: 317,
		quantity: 201,
	},
	{
		garage_id: 65,
		part_id: 318,
		quantity: 250,
	},
	{
		garage_id: 65,
		part_id: 319,
		quantity: 191,
	},
	{
		garage_id: 65,
		part_id: 320,
		quantity: 207,
	},
	{
		garage_id: 65,
		part_id: 321,
		quantity: 342,
	},
	{
		garage_id: 65,
		part_id: 322,
		quantity: 87,
	},
	{
		garage_id: 65,
		part_id: 323,
		quantity: 182,
	},
	{
		garage_id: 65,
		part_id: 324,
		quantity: 168,
	},
	{
		garage_id: 65,
		part_id: 325,
		quantity: 488,
	},
	{
		garage_id: 65,
		part_id: 326,
		quantity: 73,
	},
	{
		garage_id: 65,
		part_id: 327,
		quantity: 431,
	},
	{
		garage_id: 65,
		part_id: 328,
		quantity: 394,
	},
	{
		garage_id: 65,
		part_id: 329,
		quantity: 484,
	},
	{
		garage_id: 65,
		part_id: 330,
		quantity: 268,
	},
	{
		garage_id: 65,
		part_id: 331,
		quantity: 454,
	},
	{
		garage_id: 65,
		part_id: 332,
		quantity: 381,
	},
	{
		garage_id: 65,
		part_id: 333,
		quantity: 244,
	},
	{
		garage_id: 65,
		part_id: 334,
		quantity: 427,
	},
	{
		garage_id: 65,
		part_id: 335,
		quantity: 166,
	},
	{
		garage_id: 65,
		part_id: 336,
		quantity: 95,
	},
	{
		garage_id: 65,
		part_id: 337,
		quantity: 345,
	},
	{
		garage_id: 65,
		part_id: 338,
		quantity: 428,
	},
	{
		garage_id: 65,
		part_id: 339,
		quantity: 452,
	},
	{
		garage_id: 65,
		part_id: 340,
		quantity: 456,
	},
	{
		garage_id: 65,
		part_id: 341,
		quantity: 266,
	},
	{
		garage_id: 65,
		part_id: 342,
		quantity: 465,
	},
	{
		garage_id: 65,
		part_id: 343,
		quantity: 255,
	},
	{
		garage_id: 65,
		part_id: 344,
		quantity: 262,
	},
	{
		garage_id: 65,
		part_id: 345,
		quantity: 247,
	},
	{
		garage_id: 65,
		part_id: 346,
		quantity: 141,
	},
	{
		garage_id: 65,
		part_id: 347,
		quantity: 438,
	},
	{
		garage_id: 65,
		part_id: 348,
		quantity: 241,
	},
	{
		garage_id: 65,
		part_id: 349,
		quantity: 96,
	},
	{
		garage_id: 65,
		part_id: 350,
		quantity: 76,
	},
	{
		garage_id: 65,
		part_id: 351,
		quantity: 420,
	},
	{
		garage_id: 65,
		part_id: 352,
		quantity: 193,
	},
	{
		garage_id: 65,
		part_id: 353,
		quantity: 134,
	},
	{
		garage_id: 65,
		part_id: 354,
		quantity: 103,
	},
	{
		garage_id: 65,
		part_id: 355,
		quantity: 182,
	},
	{
		garage_id: 65,
		part_id: 356,
		quantity: 118,
	},
	{
		garage_id: 65,
		part_id: 357,
		quantity: 154,
	},
	{
		garage_id: 65,
		part_id: 358,
		quantity: 421,
	},
	{
		garage_id: 65,
		part_id: 359,
		quantity: 251,
	},
	{
		garage_id: 65,
		part_id: 360,
		quantity: 43,
	},
	{
		garage_id: 65,
		part_id: 361,
		quantity: 413,
	},
	{
		garage_id: 65,
		part_id: 362,
		quantity: 380,
	},
	{
		garage_id: 65,
		part_id: 363,
		quantity: 122,
	},
	{
		garage_id: 65,
		part_id: 364,
		quantity: 246,
	},
	{
		garage_id: 65,
		part_id: 365,
		quantity: 351,
	},
	{
		garage_id: 65,
		part_id: 366,
		quantity: 446,
	},
	{
		garage_id: 65,
		part_id: 367,
		quantity: 308,
	},
	{
		garage_id: 65,
		part_id: 368,
		quantity: 46,
	},
	{
		garage_id: 65,
		part_id: 369,
		quantity: 328,
	},
	{
		garage_id: 65,
		part_id: 370,
		quantity: 164,
	},
	{
		garage_id: 65,
		part_id: 371,
		quantity: 62,
	},
	{
		garage_id: 65,
		part_id: 372,
		quantity: 342,
	},
	{
		garage_id: 65,
		part_id: 373,
		quantity: 49,
	},
	{
		garage_id: 65,
		part_id: 374,
		quantity: 93,
	},
	{
		garage_id: 65,
		part_id: 375,
		quantity: 157,
	},
	{
		garage_id: 65,
		part_id: 376,
		quantity: 205,
	},
	{
		garage_id: 65,
		part_id: 377,
		quantity: 115,
	},
	{
		garage_id: 65,
		part_id: 378,
		quantity: 179,
	},
	{
		garage_id: 65,
		part_id: 379,
		quantity: 354,
	},
	{
		garage_id: 65,
		part_id: 380,
		quantity: 117,
	},
	{
		garage_id: 65,
		part_id: 381,
		quantity: 48,
	},
	{
		garage_id: 65,
		part_id: 382,
		quantity: 129,
	},
	{
		garage_id: 65,
		part_id: 383,
		quantity: 363,
	},
	{
		garage_id: 65,
		part_id: 384,
		quantity: 467,
	},
	{
		garage_id: 65,
		part_id: 385,
		quantity: 368,
	},
	{
		garage_id: 65,
		part_id: 386,
		quantity: 252,
	},
	{
		garage_id: 65,
		part_id: 387,
		quantity: 31,
	},
	{
		garage_id: 65,
		part_id: 388,
		quantity: 348,
	},
	{
		garage_id: 65,
		part_id: 389,
		quantity: 144,
	},
	{
		garage_id: 65,
		part_id: 390,
		quantity: 480,
	},
	{
		garage_id: 65,
		part_id: 391,
		quantity: 93,
	},
	{
		garage_id: 65,
		part_id: 392,
		quantity: 330,
	},
	{
		garage_id: 65,
		part_id: 393,
		quantity: 149,
	},
	{
		garage_id: 65,
		part_id: 394,
		quantity: 288,
	},
	{
		garage_id: 65,
		part_id: 395,
		quantity: 314,
	},
	{
		garage_id: 65,
		part_id: 396,
		quantity: 390,
	},
	{
		garage_id: 65,
		part_id: 397,
		quantity: 96,
	},
	{
		garage_id: 65,
		part_id: 398,
		quantity: 42,
	},
	{
		garage_id: 65,
		part_id: 399,
		quantity: 244,
	},
	{
		garage_id: 66,
		part_id: 0,
		quantity: 417,
	},
	{
		garage_id: 66,
		part_id: 1,
		quantity: 274,
	},
	{
		garage_id: 66,
		part_id: 2,
		quantity: 31,
	},
	{
		garage_id: 66,
		part_id: 3,
		quantity: 1,
	},
	{
		garage_id: 66,
		part_id: 4,
		quantity: 55,
	},
	{
		garage_id: 66,
		part_id: 5,
		quantity: 112,
	},
	{
		garage_id: 66,
		part_id: 6,
		quantity: 106,
	},
	{
		garage_id: 66,
		part_id: 7,
		quantity: 262,
	},
	{
		garage_id: 66,
		part_id: 8,
		quantity: 42,
	},
	{
		garage_id: 66,
		part_id: 9,
		quantity: 185,
	},
	{
		garage_id: 66,
		part_id: 10,
		quantity: 310,
	},
	{
		garage_id: 66,
		part_id: 11,
		quantity: 39,
	},
	{
		garage_id: 66,
		part_id: 12,
		quantity: 406,
	},
	{
		garage_id: 66,
		part_id: 13,
		quantity: 428,
	},
	{
		garage_id: 66,
		part_id: 14,
		quantity: 12,
	},
	{
		garage_id: 66,
		part_id: 15,
		quantity: 217,
	},
	{
		garage_id: 66,
		part_id: 16,
		quantity: 215,
	},
	{
		garage_id: 66,
		part_id: 17,
		quantity: 278,
	},
	{
		garage_id: 66,
		part_id: 18,
		quantity: 158,
	},
	{
		garage_id: 66,
		part_id: 19,
		quantity: 147,
	},
	{
		garage_id: 66,
		part_id: 20,
		quantity: 358,
	},
	{
		garage_id: 66,
		part_id: 21,
		quantity: 415,
	},
	{
		garage_id: 66,
		part_id: 22,
		quantity: 457,
	},
	{
		garage_id: 66,
		part_id: 23,
		quantity: 273,
	},
	{
		garage_id: 66,
		part_id: 24,
		quantity: 37,
	},
	{
		garage_id: 66,
		part_id: 25,
		quantity: 60,
	},
	{
		garage_id: 66,
		part_id: 26,
		quantity: 227,
	},
	{
		garage_id: 66,
		part_id: 27,
		quantity: 313,
	},
	{
		garage_id: 66,
		part_id: 28,
		quantity: 32,
	},
	{
		garage_id: 66,
		part_id: 29,
		quantity: 228,
	},
	{
		garage_id: 66,
		part_id: 30,
		quantity: 458,
	},
	{
		garage_id: 66,
		part_id: 31,
		quantity: 404,
	},
	{
		garage_id: 66,
		part_id: 32,
		quantity: 66,
	},
	{
		garage_id: 66,
		part_id: 33,
		quantity: 17,
	},
	{
		garage_id: 66,
		part_id: 34,
		quantity: 91,
	},
	{
		garage_id: 66,
		part_id: 35,
		quantity: 301,
	},
	{
		garage_id: 66,
		part_id: 36,
		quantity: 68,
	},
	{
		garage_id: 66,
		part_id: 37,
		quantity: 248,
	},
	{
		garage_id: 66,
		part_id: 38,
		quantity: 438,
	},
	{
		garage_id: 66,
		part_id: 39,
		quantity: 475,
	},
	{
		garage_id: 66,
		part_id: 40,
		quantity: 323,
	},
	{
		garage_id: 66,
		part_id: 41,
		quantity: 344,
	},
	{
		garage_id: 66,
		part_id: 42,
		quantity: 180,
	},
	{
		garage_id: 66,
		part_id: 43,
		quantity: 296,
	},
	{
		garage_id: 66,
		part_id: 44,
		quantity: 414,
	},
	{
		garage_id: 66,
		part_id: 45,
		quantity: 210,
	},
	{
		garage_id: 66,
		part_id: 46,
		quantity: 21,
	},
	{
		garage_id: 66,
		part_id: 47,
		quantity: 64,
	},
	{
		garage_id: 66,
		part_id: 48,
		quantity: 146,
	},
	{
		garage_id: 66,
		part_id: 49,
		quantity: 485,
	},
	{
		garage_id: 66,
		part_id: 50,
		quantity: 460,
	},
	{
		garage_id: 66,
		part_id: 51,
		quantity: 184,
	},
	{
		garage_id: 66,
		part_id: 52,
		quantity: 29,
	},
	{
		garage_id: 66,
		part_id: 53,
		quantity: 117,
	},
	{
		garage_id: 66,
		part_id: 54,
		quantity: 322,
	},
	{
		garage_id: 66,
		part_id: 55,
		quantity: 258,
	},
	{
		garage_id: 66,
		part_id: 56,
		quantity: 311,
	},
	{
		garage_id: 66,
		part_id: 57,
		quantity: 377,
	},
	{
		garage_id: 66,
		part_id: 58,
		quantity: 399,
	},
	{
		garage_id: 66,
		part_id: 59,
		quantity: 182,
	},
	{
		garage_id: 66,
		part_id: 60,
		quantity: 95,
	},
	{
		garage_id: 66,
		part_id: 61,
		quantity: 22,
	},
	{
		garage_id: 66,
		part_id: 62,
		quantity: 226,
	},
	{
		garage_id: 66,
		part_id: 63,
		quantity: 371,
	},
	{
		garage_id: 66,
		part_id: 64,
		quantity: 262,
	},
	{
		garage_id: 66,
		part_id: 65,
		quantity: 470,
	},
	{
		garage_id: 66,
		part_id: 66,
		quantity: 33,
	},
	{
		garage_id: 66,
		part_id: 67,
		quantity: 67,
	},
	{
		garage_id: 66,
		part_id: 68,
		quantity: 214,
	},
	{
		garage_id: 66,
		part_id: 69,
		quantity: 376,
	},
	{
		garage_id: 66,
		part_id: 70,
		quantity: 142,
	},
	{
		garage_id: 66,
		part_id: 71,
		quantity: 119,
	},
	{
		garage_id: 66,
		part_id: 72,
		quantity: 423,
	},
	{
		garage_id: 66,
		part_id: 73,
		quantity: 418,
	},
	{
		garage_id: 66,
		part_id: 74,
		quantity: 102,
	},
	{
		garage_id: 66,
		part_id: 75,
		quantity: 187,
	},
	{
		garage_id: 66,
		part_id: 76,
		quantity: 247,
	},
	{
		garage_id: 66,
		part_id: 77,
		quantity: 30,
	},
	{
		garage_id: 66,
		part_id: 78,
		quantity: 166,
	},
	{
		garage_id: 66,
		part_id: 79,
		quantity: 51,
	},
	{
		garage_id: 66,
		part_id: 80,
		quantity: 286,
	},
	{
		garage_id: 66,
		part_id: 81,
		quantity: 312,
	},
	{
		garage_id: 66,
		part_id: 82,
		quantity: 41,
	},
	{
		garage_id: 66,
		part_id: 83,
		quantity: 164,
	},
	{
		garage_id: 66,
		part_id: 84,
		quantity: 372,
	},
	{
		garage_id: 66,
		part_id: 85,
		quantity: 237,
	},
	{
		garage_id: 66,
		part_id: 86,
		quantity: 395,
	},
	{
		garage_id: 66,
		part_id: 87,
		quantity: 292,
	},
	{
		garage_id: 66,
		part_id: 88,
		quantity: 85,
	},
	{
		garage_id: 66,
		part_id: 89,
		quantity: 124,
	},
	{
		garage_id: 66,
		part_id: 90,
		quantity: 429,
	},
	{
		garage_id: 66,
		part_id: 91,
		quantity: 60,
	},
	{
		garage_id: 66,
		part_id: 92,
		quantity: 380,
	},
	{
		garage_id: 66,
		part_id: 93,
		quantity: 296,
	},
	{
		garage_id: 66,
		part_id: 94,
		quantity: 122,
	},
	{
		garage_id: 66,
		part_id: 95,
		quantity: 458,
	},
	{
		garage_id: 66,
		part_id: 96,
		quantity: 243,
	},
	{
		garage_id: 66,
		part_id: 97,
		quantity: 414,
	},
	{
		garage_id: 66,
		part_id: 98,
		quantity: 373,
	},
	{
		garage_id: 66,
		part_id: 99,
		quantity: 484,
	},
	{
		garage_id: 66,
		part_id: 100,
		quantity: 93,
	},
	{
		garage_id: 66,
		part_id: 101,
		quantity: 301,
	},
	{
		garage_id: 66,
		part_id: 102,
		quantity: 11,
	},
	{
		garage_id: 66,
		part_id: 103,
		quantity: 234,
	},
	{
		garage_id: 66,
		part_id: 104,
		quantity: 22,
	},
	{
		garage_id: 66,
		part_id: 105,
		quantity: 190,
	},
	{
		garage_id: 66,
		part_id: 106,
		quantity: 411,
	},
	{
		garage_id: 66,
		part_id: 107,
		quantity: 287,
	},
	{
		garage_id: 66,
		part_id: 108,
		quantity: 39,
	},
	{
		garage_id: 66,
		part_id: 109,
		quantity: 19,
	},
	{
		garage_id: 66,
		part_id: 110,
		quantity: 338,
	},
	{
		garage_id: 66,
		part_id: 111,
		quantity: 14,
	},
	{
		garage_id: 66,
		part_id: 112,
		quantity: 218,
	},
	{
		garage_id: 66,
		part_id: 113,
		quantity: 320,
	},
	{
		garage_id: 66,
		part_id: 114,
		quantity: 153,
	},
	{
		garage_id: 66,
		part_id: 115,
		quantity: 39,
	},
	{
		garage_id: 66,
		part_id: 116,
		quantity: 374,
	},
	{
		garage_id: 66,
		part_id: 117,
		quantity: 280,
	},
	{
		garage_id: 66,
		part_id: 118,
		quantity: 449,
	},
	{
		garage_id: 66,
		part_id: 119,
		quantity: 188,
	},
	{
		garage_id: 66,
		part_id: 120,
		quantity: 353,
	},
	{
		garage_id: 66,
		part_id: 121,
		quantity: 292,
	},
	{
		garage_id: 66,
		part_id: 122,
		quantity: 5,
	},
	{
		garage_id: 66,
		part_id: 123,
		quantity: 374,
	},
	{
		garage_id: 66,
		part_id: 124,
		quantity: 430,
	},
	{
		garage_id: 66,
		part_id: 125,
		quantity: 382,
	},
	{
		garage_id: 66,
		part_id: 126,
		quantity: 348,
	},
	{
		garage_id: 66,
		part_id: 127,
		quantity: 477,
	},
	{
		garage_id: 66,
		part_id: 128,
		quantity: 414,
	},
	{
		garage_id: 66,
		part_id: 129,
		quantity: 115,
	},
	{
		garage_id: 66,
		part_id: 130,
		quantity: 429,
	},
	{
		garage_id: 66,
		part_id: 131,
		quantity: 393,
	},
	{
		garage_id: 66,
		part_id: 132,
		quantity: 353,
	},
	{
		garage_id: 66,
		part_id: 133,
		quantity: 439,
	},
	{
		garage_id: 66,
		part_id: 134,
		quantity: 349,
	},
	{
		garage_id: 66,
		part_id: 135,
		quantity: 109,
	},
	{
		garage_id: 66,
		part_id: 136,
		quantity: 82,
	},
	{
		garage_id: 66,
		part_id: 137,
		quantity: 20,
	},
	{
		garage_id: 66,
		part_id: 138,
		quantity: 92,
	},
	{
		garage_id: 66,
		part_id: 139,
		quantity: 102,
	},
	{
		garage_id: 66,
		part_id: 140,
		quantity: 241,
	},
	{
		garage_id: 66,
		part_id: 141,
		quantity: 270,
	},
	{
		garage_id: 66,
		part_id: 142,
		quantity: 440,
	},
	{
		garage_id: 66,
		part_id: 143,
		quantity: 219,
	},
	{
		garage_id: 66,
		part_id: 144,
		quantity: 125,
	},
	{
		garage_id: 66,
		part_id: 145,
		quantity: 2,
	},
	{
		garage_id: 66,
		part_id: 146,
		quantity: 57,
	},
	{
		garage_id: 66,
		part_id: 147,
		quantity: 149,
	},
	{
		garage_id: 66,
		part_id: 148,
		quantity: 91,
	},
	{
		garage_id: 66,
		part_id: 149,
		quantity: 383,
	},
	{
		garage_id: 66,
		part_id: 150,
		quantity: 38,
	},
	{
		garage_id: 66,
		part_id: 151,
		quantity: 322,
	},
	{
		garage_id: 66,
		part_id: 152,
		quantity: 48,
	},
	{
		garage_id: 66,
		part_id: 153,
		quantity: 478,
	},
	{
		garage_id: 66,
		part_id: 154,
		quantity: 246,
	},
	{
		garage_id: 66,
		part_id: 155,
		quantity: 244,
	},
	{
		garage_id: 66,
		part_id: 156,
		quantity: 360,
	},
	{
		garage_id: 66,
		part_id: 157,
		quantity: 109,
	},
	{
		garage_id: 66,
		part_id: 158,
		quantity: 137,
	},
	{
		garage_id: 66,
		part_id: 159,
		quantity: 355,
	},
	{
		garage_id: 66,
		part_id: 160,
		quantity: 272,
	},
	{
		garage_id: 66,
		part_id: 161,
		quantity: 322,
	},
	{
		garage_id: 66,
		part_id: 162,
		quantity: 258,
	},
	{
		garage_id: 66,
		part_id: 163,
		quantity: 333,
	},
	{
		garage_id: 66,
		part_id: 164,
		quantity: 133,
	},
	{
		garage_id: 66,
		part_id: 165,
		quantity: 379,
	},
	{
		garage_id: 66,
		part_id: 166,
		quantity: 316,
	},
	{
		garage_id: 66,
		part_id: 167,
		quantity: 169,
	},
	{
		garage_id: 66,
		part_id: 168,
		quantity: 139,
	},
	{
		garage_id: 66,
		part_id: 169,
		quantity: 9,
	},
	{
		garage_id: 66,
		part_id: 170,
		quantity: 309,
	},
	{
		garage_id: 66,
		part_id: 171,
		quantity: 261,
	},
	{
		garage_id: 66,
		part_id: 172,
		quantity: 3,
	},
	{
		garage_id: 66,
		part_id: 173,
		quantity: 250,
	},
	{
		garage_id: 66,
		part_id: 174,
		quantity: 52,
	},
	{
		garage_id: 66,
		part_id: 175,
		quantity: 175,
	},
	{
		garage_id: 66,
		part_id: 176,
		quantity: 86,
	},
	{
		garage_id: 66,
		part_id: 177,
		quantity: 141,
	},
	{
		garage_id: 66,
		part_id: 178,
		quantity: 34,
	},
	{
		garage_id: 66,
		part_id: 179,
		quantity: 5,
	},
	{
		garage_id: 66,
		part_id: 180,
		quantity: 191,
	},
	{
		garage_id: 66,
		part_id: 181,
		quantity: 24,
	},
	{
		garage_id: 66,
		part_id: 182,
		quantity: 129,
	},
	{
		garage_id: 66,
		part_id: 183,
		quantity: 471,
	},
	{
		garage_id: 66,
		part_id: 184,
		quantity: 226,
	},
	{
		garage_id: 66,
		part_id: 185,
		quantity: 328,
	},
	{
		garage_id: 66,
		part_id: 186,
		quantity: 138,
	},
	{
		garage_id: 66,
		part_id: 187,
		quantity: 150,
	},
	{
		garage_id: 66,
		part_id: 188,
		quantity: 395,
	},
	{
		garage_id: 66,
		part_id: 189,
		quantity: 330,
	},
	{
		garage_id: 66,
		part_id: 190,
		quantity: 57,
	},
	{
		garage_id: 66,
		part_id: 191,
		quantity: 3,
	},
	{
		garage_id: 66,
		part_id: 192,
		quantity: 233,
	},
	{
		garage_id: 66,
		part_id: 193,
		quantity: 355,
	},
	{
		garage_id: 66,
		part_id: 194,
		quantity: 173,
	},
	{
		garage_id: 66,
		part_id: 195,
		quantity: 470,
	},
	{
		garage_id: 66,
		part_id: 196,
		quantity: 5,
	},
	{
		garage_id: 66,
		part_id: 197,
		quantity: 291,
	},
	{
		garage_id: 66,
		part_id: 198,
		quantity: 336,
	},
	{
		garage_id: 66,
		part_id: 199,
		quantity: 445,
	},
	{
		garage_id: 66,
		part_id: 200,
		quantity: 414,
	},
	{
		garage_id: 66,
		part_id: 201,
		quantity: 67,
	},
	{
		garage_id: 66,
		part_id: 202,
		quantity: 7,
	},
	{
		garage_id: 66,
		part_id: 203,
		quantity: 209,
	},
	{
		garage_id: 66,
		part_id: 204,
		quantity: 427,
	},
	{
		garage_id: 66,
		part_id: 205,
		quantity: 261,
	},
	{
		garage_id: 66,
		part_id: 206,
		quantity: 401,
	},
	{
		garage_id: 66,
		part_id: 207,
		quantity: 485,
	},
	{
		garage_id: 66,
		part_id: 208,
		quantity: 194,
	},
	{
		garage_id: 66,
		part_id: 209,
		quantity: 459,
	},
	{
		garage_id: 66,
		part_id: 210,
		quantity: 25,
	},
	{
		garage_id: 66,
		part_id: 211,
		quantity: 231,
	},
	{
		garage_id: 66,
		part_id: 212,
		quantity: 190,
	},
	{
		garage_id: 66,
		part_id: 213,
		quantity: 76,
	},
	{
		garage_id: 66,
		part_id: 214,
		quantity: 225,
	},
	{
		garage_id: 66,
		part_id: 215,
		quantity: 236,
	},
	{
		garage_id: 66,
		part_id: 216,
		quantity: 461,
	},
	{
		garage_id: 66,
		part_id: 217,
		quantity: 326,
	},
	{
		garage_id: 66,
		part_id: 218,
		quantity: 322,
	},
	{
		garage_id: 66,
		part_id: 219,
		quantity: 198,
	},
	{
		garage_id: 66,
		part_id: 220,
		quantity: 69,
	},
	{
		garage_id: 66,
		part_id: 221,
		quantity: 13,
	},
	{
		garage_id: 66,
		part_id: 222,
		quantity: 29,
	},
	{
		garage_id: 66,
		part_id: 223,
		quantity: 289,
	},
	{
		garage_id: 66,
		part_id: 224,
		quantity: 16,
	},
	{
		garage_id: 66,
		part_id: 225,
		quantity: 410,
	},
	{
		garage_id: 66,
		part_id: 226,
		quantity: 31,
	},
	{
		garage_id: 66,
		part_id: 227,
		quantity: 125,
	},
	{
		garage_id: 66,
		part_id: 228,
		quantity: 196,
	},
	{
		garage_id: 66,
		part_id: 229,
		quantity: 217,
	},
	{
		garage_id: 66,
		part_id: 230,
		quantity: 276,
	},
	{
		garage_id: 66,
		part_id: 231,
		quantity: 336,
	},
	{
		garage_id: 66,
		part_id: 232,
		quantity: 455,
	},
	{
		garage_id: 66,
		part_id: 233,
		quantity: 27,
	},
	{
		garage_id: 66,
		part_id: 234,
		quantity: 94,
	},
	{
		garage_id: 66,
		part_id: 235,
		quantity: 242,
	},
	{
		garage_id: 66,
		part_id: 236,
		quantity: 386,
	},
	{
		garage_id: 66,
		part_id: 237,
		quantity: 135,
	},
	{
		garage_id: 66,
		part_id: 238,
		quantity: 327,
	},
	{
		garage_id: 66,
		part_id: 239,
		quantity: 480,
	},
	{
		garage_id: 66,
		part_id: 240,
		quantity: 198,
	},
	{
		garage_id: 66,
		part_id: 241,
		quantity: 142,
	},
	{
		garage_id: 66,
		part_id: 242,
		quantity: 148,
	},
	{
		garage_id: 66,
		part_id: 243,
		quantity: 293,
	},
	{
		garage_id: 66,
		part_id: 244,
		quantity: 436,
	},
	{
		garage_id: 66,
		part_id: 245,
		quantity: 36,
	},
	{
		garage_id: 66,
		part_id: 246,
		quantity: 428,
	},
	{
		garage_id: 66,
		part_id: 247,
		quantity: 489,
	},
	{
		garage_id: 66,
		part_id: 248,
		quantity: 268,
	},
	{
		garage_id: 66,
		part_id: 249,
		quantity: 252,
	},
	{
		garage_id: 66,
		part_id: 250,
		quantity: 217,
	},
	{
		garage_id: 66,
		part_id: 251,
		quantity: 74,
	},
	{
		garage_id: 66,
		part_id: 252,
		quantity: 237,
	},
	{
		garage_id: 66,
		part_id: 253,
		quantity: 473,
	},
	{
		garage_id: 66,
		part_id: 254,
		quantity: 187,
	},
	{
		garage_id: 66,
		part_id: 255,
		quantity: 392,
	},
	{
		garage_id: 66,
		part_id: 256,
		quantity: 438,
	},
	{
		garage_id: 66,
		part_id: 257,
		quantity: 220,
	},
	{
		garage_id: 66,
		part_id: 258,
		quantity: 49,
	},
	{
		garage_id: 66,
		part_id: 259,
		quantity: 363,
	},
	{
		garage_id: 66,
		part_id: 260,
		quantity: 475,
	},
	{
		garage_id: 66,
		part_id: 261,
		quantity: 390,
	},
	{
		garage_id: 66,
		part_id: 262,
		quantity: 326,
	},
	{
		garage_id: 66,
		part_id: 263,
		quantity: 483,
	},
	{
		garage_id: 66,
		part_id: 264,
		quantity: 287,
	},
	{
		garage_id: 66,
		part_id: 265,
		quantity: 108,
	},
	{
		garage_id: 66,
		part_id: 266,
		quantity: 456,
	},
	{
		garage_id: 66,
		part_id: 267,
		quantity: 451,
	},
	{
		garage_id: 66,
		part_id: 268,
		quantity: 267,
	},
	{
		garage_id: 66,
		part_id: 269,
		quantity: 129,
	},
	{
		garage_id: 66,
		part_id: 270,
		quantity: 455,
	},
	{
		garage_id: 66,
		part_id: 271,
		quantity: 134,
	},
	{
		garage_id: 66,
		part_id: 272,
		quantity: 373,
	},
	{
		garage_id: 66,
		part_id: 273,
		quantity: 192,
	},
	{
		garage_id: 66,
		part_id: 274,
		quantity: 120,
	},
	{
		garage_id: 66,
		part_id: 275,
		quantity: 241,
	},
	{
		garage_id: 66,
		part_id: 276,
		quantity: 410,
	},
	{
		garage_id: 66,
		part_id: 277,
		quantity: 214,
	},
	{
		garage_id: 66,
		part_id: 278,
		quantity: 194,
	},
	{
		garage_id: 66,
		part_id: 279,
		quantity: 278,
	},
	{
		garage_id: 66,
		part_id: 280,
		quantity: 272,
	},
	{
		garage_id: 66,
		part_id: 281,
		quantity: 384,
	},
	{
		garage_id: 66,
		part_id: 282,
		quantity: 441,
	},
	{
		garage_id: 66,
		part_id: 283,
		quantity: 424,
	},
	{
		garage_id: 66,
		part_id: 284,
		quantity: 198,
	},
	{
		garage_id: 66,
		part_id: 285,
		quantity: 463,
	},
	{
		garage_id: 66,
		part_id: 286,
		quantity: 111,
	},
	{
		garage_id: 66,
		part_id: 287,
		quantity: 319,
	},
	{
		garage_id: 66,
		part_id: 288,
		quantity: 61,
	},
	{
		garage_id: 66,
		part_id: 289,
		quantity: 41,
	},
	{
		garage_id: 66,
		part_id: 290,
		quantity: 89,
	},
	{
		garage_id: 66,
		part_id: 291,
		quantity: 60,
	},
	{
		garage_id: 66,
		part_id: 292,
		quantity: 131,
	},
	{
		garage_id: 66,
		part_id: 293,
		quantity: 415,
	},
	{
		garage_id: 66,
		part_id: 294,
		quantity: 437,
	},
	{
		garage_id: 66,
		part_id: 295,
		quantity: 334,
	},
	{
		garage_id: 66,
		part_id: 296,
		quantity: 330,
	},
	{
		garage_id: 66,
		part_id: 297,
		quantity: 149,
	},
	{
		garage_id: 66,
		part_id: 298,
		quantity: 233,
	},
	{
		garage_id: 66,
		part_id: 299,
		quantity: 235,
	},
	{
		garage_id: 66,
		part_id: 300,
		quantity: 188,
	},
	{
		garage_id: 66,
		part_id: 301,
		quantity: 82,
	},
	{
		garage_id: 66,
		part_id: 302,
		quantity: 336,
	},
	{
		garage_id: 66,
		part_id: 303,
		quantity: 28,
	},
	{
		garage_id: 66,
		part_id: 304,
		quantity: 144,
	},
	{
		garage_id: 66,
		part_id: 305,
		quantity: 238,
	},
	{
		garage_id: 66,
		part_id: 306,
		quantity: 336,
	},
	{
		garage_id: 66,
		part_id: 307,
		quantity: 471,
	},
	{
		garage_id: 66,
		part_id: 308,
		quantity: 153,
	},
	{
		garage_id: 66,
		part_id: 309,
		quantity: 372,
	},
	{
		garage_id: 66,
		part_id: 310,
		quantity: 484,
	},
	{
		garage_id: 66,
		part_id: 311,
		quantity: 232,
	},
	{
		garage_id: 66,
		part_id: 312,
		quantity: 26,
	},
	{
		garage_id: 66,
		part_id: 313,
		quantity: 119,
	},
	{
		garage_id: 66,
		part_id: 314,
		quantity: 94,
	},
	{
		garage_id: 66,
		part_id: 315,
		quantity: 146,
	},
	{
		garage_id: 66,
		part_id: 316,
		quantity: 64,
	},
	{
		garage_id: 66,
		part_id: 317,
		quantity: 219,
	},
	{
		garage_id: 66,
		part_id: 318,
		quantity: 144,
	},
	{
		garage_id: 66,
		part_id: 319,
		quantity: 367,
	},
	{
		garage_id: 66,
		part_id: 320,
		quantity: 347,
	},
	{
		garage_id: 66,
		part_id: 321,
		quantity: 143,
	},
	{
		garage_id: 66,
		part_id: 322,
		quantity: 370,
	},
	{
		garage_id: 66,
		part_id: 323,
		quantity: 266,
	},
	{
		garage_id: 66,
		part_id: 324,
		quantity: 254,
	},
	{
		garage_id: 66,
		part_id: 325,
		quantity: 481,
	},
	{
		garage_id: 66,
		part_id: 326,
		quantity: 331,
	},
	{
		garage_id: 66,
		part_id: 327,
		quantity: 438,
	},
	{
		garage_id: 66,
		part_id: 328,
		quantity: 217,
	},
	{
		garage_id: 66,
		part_id: 329,
		quantity: 441,
	},
	{
		garage_id: 66,
		part_id: 330,
		quantity: 484,
	},
	{
		garage_id: 66,
		part_id: 331,
		quantity: 394,
	},
	{
		garage_id: 66,
		part_id: 332,
		quantity: 458,
	},
	{
		garage_id: 66,
		part_id: 333,
		quantity: 346,
	},
	{
		garage_id: 66,
		part_id: 334,
		quantity: 498,
	},
	{
		garage_id: 66,
		part_id: 335,
		quantity: 289,
	},
	{
		garage_id: 66,
		part_id: 336,
		quantity: 428,
	},
	{
		garage_id: 66,
		part_id: 337,
		quantity: 361,
	},
	{
		garage_id: 66,
		part_id: 338,
		quantity: 193,
	},
	{
		garage_id: 66,
		part_id: 339,
		quantity: 142,
	},
	{
		garage_id: 66,
		part_id: 340,
		quantity: 245,
	},
	{
		garage_id: 66,
		part_id: 341,
		quantity: 209,
	},
	{
		garage_id: 66,
		part_id: 342,
		quantity: 429,
	},
	{
		garage_id: 66,
		part_id: 343,
		quantity: 282,
	},
	{
		garage_id: 66,
		part_id: 344,
		quantity: 109,
	},
	{
		garage_id: 66,
		part_id: 345,
		quantity: 202,
	},
	{
		garage_id: 66,
		part_id: 346,
		quantity: 69,
	},
	{
		garage_id: 66,
		part_id: 347,
		quantity: 477,
	},
	{
		garage_id: 66,
		part_id: 348,
		quantity: 214,
	},
	{
		garage_id: 66,
		part_id: 349,
		quantity: 409,
	},
	{
		garage_id: 66,
		part_id: 350,
		quantity: 464,
	},
	{
		garage_id: 66,
		part_id: 351,
		quantity: 398,
	},
	{
		garage_id: 66,
		part_id: 352,
		quantity: 155,
	},
	{
		garage_id: 66,
		part_id: 353,
		quantity: 42,
	},
	{
		garage_id: 66,
		part_id: 354,
		quantity: 419,
	},
	{
		garage_id: 66,
		part_id: 355,
		quantity: 393,
	},
	{
		garage_id: 66,
		part_id: 356,
		quantity: 157,
	},
	{
		garage_id: 66,
		part_id: 357,
		quantity: 500,
	},
	{
		garage_id: 66,
		part_id: 358,
		quantity: 32,
	},
	{
		garage_id: 66,
		part_id: 359,
		quantity: 483,
	},
	{
		garage_id: 66,
		part_id: 360,
		quantity: 91,
	},
	{
		garage_id: 66,
		part_id: 361,
		quantity: 132,
	},
	{
		garage_id: 66,
		part_id: 362,
		quantity: 221,
	},
	{
		garage_id: 66,
		part_id: 363,
		quantity: 228,
	},
	{
		garage_id: 66,
		part_id: 364,
		quantity: 193,
	},
	{
		garage_id: 66,
		part_id: 365,
		quantity: 344,
	},
	{
		garage_id: 66,
		part_id: 366,
		quantity: 174,
	},
	{
		garage_id: 66,
		part_id: 367,
		quantity: 485,
	},
	{
		garage_id: 66,
		part_id: 368,
		quantity: 127,
	},
	{
		garage_id: 66,
		part_id: 369,
		quantity: 177,
	},
	{
		garage_id: 66,
		part_id: 370,
		quantity: 273,
	},
	{
		garage_id: 66,
		part_id: 371,
		quantity: 301,
	},
	{
		garage_id: 66,
		part_id: 372,
		quantity: 105,
	},
	{
		garage_id: 66,
		part_id: 373,
		quantity: 268,
	},
	{
		garage_id: 66,
		part_id: 374,
		quantity: 302,
	},
	{
		garage_id: 66,
		part_id: 375,
		quantity: 318,
	},
	{
		garage_id: 66,
		part_id: 376,
		quantity: 7,
	},
	{
		garage_id: 66,
		part_id: 377,
		quantity: 361,
	},
	{
		garage_id: 66,
		part_id: 378,
		quantity: 77,
	},
	{
		garage_id: 66,
		part_id: 379,
		quantity: 190,
	},
	{
		garage_id: 66,
		part_id: 380,
		quantity: 362,
	},
	{
		garage_id: 66,
		part_id: 381,
		quantity: 373,
	},
	{
		garage_id: 66,
		part_id: 382,
		quantity: 143,
	},
	{
		garage_id: 66,
		part_id: 383,
		quantity: 474,
	},
	{
		garage_id: 66,
		part_id: 384,
		quantity: 408,
	},
	{
		garage_id: 66,
		part_id: 385,
		quantity: 488,
	},
	{
		garage_id: 66,
		part_id: 386,
		quantity: 476,
	},
	{
		garage_id: 66,
		part_id: 387,
		quantity: 30,
	},
	{
		garage_id: 66,
		part_id: 388,
		quantity: 143,
	},
	{
		garage_id: 66,
		part_id: 389,
		quantity: 480,
	},
	{
		garage_id: 66,
		part_id: 390,
		quantity: 104,
	},
	{
		garage_id: 66,
		part_id: 391,
		quantity: 431,
	},
	{
		garage_id: 66,
		part_id: 392,
		quantity: 249,
	},
	{
		garage_id: 66,
		part_id: 393,
		quantity: 283,
	},
	{
		garage_id: 66,
		part_id: 394,
		quantity: 356,
	},
	{
		garage_id: 66,
		part_id: 395,
		quantity: 293,
	},
	{
		garage_id: 66,
		part_id: 396,
		quantity: 397,
	},
	{
		garage_id: 66,
		part_id: 397,
		quantity: 159,
	},
	{
		garage_id: 66,
		part_id: 398,
		quantity: 94,
	},
	{
		garage_id: 66,
		part_id: 399,
		quantity: 2,
	},
	{
		garage_id: 67,
		part_id: 0,
		quantity: 224,
	},
	{
		garage_id: 67,
		part_id: 1,
		quantity: 204,
	},
	{
		garage_id: 67,
		part_id: 2,
		quantity: 21,
	},
	{
		garage_id: 67,
		part_id: 3,
		quantity: 135,
	},
	{
		garage_id: 67,
		part_id: 4,
		quantity: 317,
	},
	{
		garage_id: 67,
		part_id: 5,
		quantity: 233,
	},
	{
		garage_id: 67,
		part_id: 6,
		quantity: 266,
	},
	{
		garage_id: 67,
		part_id: 7,
		quantity: 343,
	},
	{
		garage_id: 67,
		part_id: 8,
		quantity: 102,
	},
	{
		garage_id: 67,
		part_id: 9,
		quantity: 206,
	},
	{
		garage_id: 67,
		part_id: 10,
		quantity: 73,
	},
	{
		garage_id: 67,
		part_id: 11,
		quantity: 479,
	},
	{
		garage_id: 67,
		part_id: 12,
		quantity: 198,
	},
	{
		garage_id: 67,
		part_id: 13,
		quantity: 245,
	},
	{
		garage_id: 67,
		part_id: 14,
		quantity: 137,
	},
	{
		garage_id: 67,
		part_id: 15,
		quantity: 327,
	},
	{
		garage_id: 67,
		part_id: 16,
		quantity: 185,
	},
	{
		garage_id: 67,
		part_id: 17,
		quantity: 454,
	},
	{
		garage_id: 67,
		part_id: 18,
		quantity: 376,
	},
	{
		garage_id: 67,
		part_id: 19,
		quantity: 186,
	},
	{
		garage_id: 67,
		part_id: 20,
		quantity: 394,
	},
	{
		garage_id: 67,
		part_id: 21,
		quantity: 351,
	},
	{
		garage_id: 67,
		part_id: 22,
		quantity: 100,
	},
	{
		garage_id: 67,
		part_id: 23,
		quantity: 198,
	},
	{
		garage_id: 67,
		part_id: 24,
		quantity: 127,
	},
	{
		garage_id: 67,
		part_id: 25,
		quantity: 354,
	},
	{
		garage_id: 67,
		part_id: 26,
		quantity: 60,
	},
	{
		garage_id: 67,
		part_id: 27,
		quantity: 258,
	},
	{
		garage_id: 67,
		part_id: 28,
		quantity: 72,
	},
	{
		garage_id: 67,
		part_id: 29,
		quantity: 344,
	},
	{
		garage_id: 67,
		part_id: 30,
		quantity: 134,
	},
	{
		garage_id: 67,
		part_id: 31,
		quantity: 462,
	},
	{
		garage_id: 67,
		part_id: 32,
		quantity: 480,
	},
	{
		garage_id: 67,
		part_id: 33,
		quantity: 68,
	},
	{
		garage_id: 67,
		part_id: 34,
		quantity: 443,
	},
	{
		garage_id: 67,
		part_id: 35,
		quantity: 176,
	},
	{
		garage_id: 67,
		part_id: 36,
		quantity: 388,
	},
	{
		garage_id: 67,
		part_id: 37,
		quantity: 464,
	},
	{
		garage_id: 67,
		part_id: 38,
		quantity: 384,
	},
	{
		garage_id: 67,
		part_id: 39,
		quantity: 271,
	},
	{
		garage_id: 67,
		part_id: 40,
		quantity: 336,
	},
	{
		garage_id: 67,
		part_id: 41,
		quantity: 25,
	},
	{
		garage_id: 67,
		part_id: 42,
		quantity: 376,
	},
	{
		garage_id: 67,
		part_id: 43,
		quantity: 186,
	},
	{
		garage_id: 67,
		part_id: 44,
		quantity: 168,
	},
	{
		garage_id: 67,
		part_id: 45,
		quantity: 74,
	},
	{
		garage_id: 67,
		part_id: 46,
		quantity: 218,
	},
	{
		garage_id: 67,
		part_id: 47,
		quantity: 373,
	},
	{
		garage_id: 67,
		part_id: 48,
		quantity: 37,
	},
	{
		garage_id: 67,
		part_id: 49,
		quantity: 45,
	},
	{
		garage_id: 67,
		part_id: 50,
		quantity: 128,
	},
	{
		garage_id: 67,
		part_id: 51,
		quantity: 103,
	},
	{
		garage_id: 67,
		part_id: 52,
		quantity: 383,
	},
	{
		garage_id: 67,
		part_id: 53,
		quantity: 387,
	},
	{
		garage_id: 67,
		part_id: 54,
		quantity: 112,
	},
	{
		garage_id: 67,
		part_id: 55,
		quantity: 307,
	},
	{
		garage_id: 67,
		part_id: 56,
		quantity: 240,
	},
	{
		garage_id: 67,
		part_id: 57,
		quantity: 85,
	},
	{
		garage_id: 67,
		part_id: 58,
		quantity: 494,
	},
	{
		garage_id: 67,
		part_id: 59,
		quantity: 411,
	},
	{
		garage_id: 67,
		part_id: 60,
		quantity: 460,
	},
	{
		garage_id: 67,
		part_id: 61,
		quantity: 162,
	},
	{
		garage_id: 67,
		part_id: 62,
		quantity: 191,
	},
	{
		garage_id: 67,
		part_id: 63,
		quantity: 85,
	},
	{
		garage_id: 67,
		part_id: 64,
		quantity: 337,
	},
	{
		garage_id: 67,
		part_id: 65,
		quantity: 312,
	},
	{
		garage_id: 67,
		part_id: 66,
		quantity: 17,
	},
	{
		garage_id: 67,
		part_id: 67,
		quantity: 206,
	},
	{
		garage_id: 67,
		part_id: 68,
		quantity: 11,
	},
	{
		garage_id: 67,
		part_id: 69,
		quantity: 458,
	},
	{
		garage_id: 67,
		part_id: 70,
		quantity: 199,
	},
	{
		garage_id: 67,
		part_id: 71,
		quantity: 318,
	},
	{
		garage_id: 67,
		part_id: 72,
		quantity: 448,
	},
	{
		garage_id: 67,
		part_id: 73,
		quantity: 243,
	},
	{
		garage_id: 67,
		part_id: 74,
		quantity: 344,
	},
	{
		garage_id: 67,
		part_id: 75,
		quantity: 331,
	},
	{
		garage_id: 67,
		part_id: 76,
		quantity: 354,
	},
	{
		garage_id: 67,
		part_id: 77,
		quantity: 69,
	},
	{
		garage_id: 67,
		part_id: 78,
		quantity: 167,
	},
	{
		garage_id: 67,
		part_id: 79,
		quantity: 273,
	},
	{
		garage_id: 67,
		part_id: 80,
		quantity: 492,
	},
	{
		garage_id: 67,
		part_id: 81,
		quantity: 75,
	},
	{
		garage_id: 67,
		part_id: 82,
		quantity: 432,
	},
	{
		garage_id: 67,
		part_id: 83,
		quantity: 481,
	},
	{
		garage_id: 67,
		part_id: 84,
		quantity: 237,
	},
	{
		garage_id: 67,
		part_id: 85,
		quantity: 116,
	},
	{
		garage_id: 67,
		part_id: 86,
		quantity: 415,
	},
	{
		garage_id: 67,
		part_id: 87,
		quantity: 130,
	},
	{
		garage_id: 67,
		part_id: 88,
		quantity: 428,
	},
	{
		garage_id: 67,
		part_id: 89,
		quantity: 15,
	},
	{
		garage_id: 67,
		part_id: 90,
		quantity: 23,
	},
	{
		garage_id: 67,
		part_id: 91,
		quantity: 473,
	},
	{
		garage_id: 67,
		part_id: 92,
		quantity: 404,
	},
	{
		garage_id: 67,
		part_id: 93,
		quantity: 315,
	},
	{
		garage_id: 67,
		part_id: 94,
		quantity: 188,
	},
	{
		garage_id: 67,
		part_id: 95,
		quantity: 179,
	},
	{
		garage_id: 67,
		part_id: 96,
		quantity: 36,
	},
	{
		garage_id: 67,
		part_id: 97,
		quantity: 441,
	},
	{
		garage_id: 67,
		part_id: 98,
		quantity: 189,
	},
	{
		garage_id: 67,
		part_id: 99,
		quantity: 29,
	},
	{
		garage_id: 67,
		part_id: 100,
		quantity: 304,
	},
	{
		garage_id: 67,
		part_id: 101,
		quantity: 51,
	},
	{
		garage_id: 67,
		part_id: 102,
		quantity: 111,
	},
	{
		garage_id: 67,
		part_id: 103,
		quantity: 435,
	},
	{
		garage_id: 67,
		part_id: 104,
		quantity: 389,
	},
	{
		garage_id: 67,
		part_id: 105,
		quantity: 54,
	},
	{
		garage_id: 67,
		part_id: 106,
		quantity: 406,
	},
	{
		garage_id: 67,
		part_id: 107,
		quantity: 277,
	},
	{
		garage_id: 67,
		part_id: 108,
		quantity: 65,
	},
	{
		garage_id: 67,
		part_id: 109,
		quantity: 6,
	},
	{
		garage_id: 67,
		part_id: 110,
		quantity: 268,
	},
	{
		garage_id: 67,
		part_id: 111,
		quantity: 39,
	},
	{
		garage_id: 67,
		part_id: 112,
		quantity: 387,
	},
	{
		garage_id: 67,
		part_id: 113,
		quantity: 98,
	},
	{
		garage_id: 67,
		part_id: 114,
		quantity: 469,
	},
	{
		garage_id: 67,
		part_id: 115,
		quantity: 273,
	},
	{
		garage_id: 67,
		part_id: 116,
		quantity: 325,
	},
	{
		garage_id: 67,
		part_id: 117,
		quantity: 12,
	},
	{
		garage_id: 67,
		part_id: 118,
		quantity: 168,
	},
	{
		garage_id: 67,
		part_id: 119,
		quantity: 437,
	},
	{
		garage_id: 67,
		part_id: 120,
		quantity: 142,
	},
	{
		garage_id: 67,
		part_id: 121,
		quantity: 197,
	},
	{
		garage_id: 67,
		part_id: 122,
		quantity: 454,
	},
	{
		garage_id: 67,
		part_id: 123,
		quantity: 173,
	},
	{
		garage_id: 67,
		part_id: 124,
		quantity: 142,
	},
	{
		garage_id: 67,
		part_id: 125,
		quantity: 73,
	},
	{
		garage_id: 67,
		part_id: 126,
		quantity: 92,
	},
	{
		garage_id: 67,
		part_id: 127,
		quantity: 219,
	},
	{
		garage_id: 67,
		part_id: 128,
		quantity: 304,
	},
	{
		garage_id: 67,
		part_id: 129,
		quantity: 179,
	},
	{
		garage_id: 67,
		part_id: 130,
		quantity: 237,
	},
	{
		garage_id: 67,
		part_id: 131,
		quantity: 165,
	},
	{
		garage_id: 67,
		part_id: 132,
		quantity: 223,
	},
	{
		garage_id: 67,
		part_id: 133,
		quantity: 348,
	},
	{
		garage_id: 67,
		part_id: 134,
		quantity: 381,
	},
	{
		garage_id: 67,
		part_id: 135,
		quantity: 167,
	},
	{
		garage_id: 67,
		part_id: 136,
		quantity: 245,
	},
	{
		garage_id: 67,
		part_id: 137,
		quantity: 438,
	},
	{
		garage_id: 67,
		part_id: 138,
		quantity: 168,
	},
	{
		garage_id: 67,
		part_id: 139,
		quantity: 290,
	},
	{
		garage_id: 67,
		part_id: 140,
		quantity: 199,
	},
	{
		garage_id: 67,
		part_id: 141,
		quantity: 140,
	},
	{
		garage_id: 67,
		part_id: 142,
		quantity: 296,
	},
	{
		garage_id: 67,
		part_id: 143,
		quantity: 161,
	},
	{
		garage_id: 67,
		part_id: 144,
		quantity: 364,
	},
	{
		garage_id: 67,
		part_id: 145,
		quantity: 475,
	},
	{
		garage_id: 67,
		part_id: 146,
		quantity: 21,
	},
	{
		garage_id: 67,
		part_id: 147,
		quantity: 38,
	},
	{
		garage_id: 67,
		part_id: 148,
		quantity: 471,
	},
	{
		garage_id: 67,
		part_id: 149,
		quantity: 274,
	},
	{
		garage_id: 67,
		part_id: 150,
		quantity: 171,
	},
	{
		garage_id: 67,
		part_id: 151,
		quantity: 396,
	},
	{
		garage_id: 67,
		part_id: 152,
		quantity: 407,
	},
	{
		garage_id: 67,
		part_id: 153,
		quantity: 127,
	},
	{
		garage_id: 67,
		part_id: 154,
		quantity: 372,
	},
	{
		garage_id: 67,
		part_id: 155,
		quantity: 270,
	},
	{
		garage_id: 67,
		part_id: 156,
		quantity: 429,
	},
	{
		garage_id: 67,
		part_id: 157,
		quantity: 469,
	},
	{
		garage_id: 67,
		part_id: 158,
		quantity: 245,
	},
	{
		garage_id: 67,
		part_id: 159,
		quantity: 104,
	},
	{
		garage_id: 67,
		part_id: 160,
		quantity: 246,
	},
	{
		garage_id: 67,
		part_id: 161,
		quantity: 460,
	},
	{
		garage_id: 67,
		part_id: 162,
		quantity: 198,
	},
	{
		garage_id: 67,
		part_id: 163,
		quantity: 109,
	},
	{
		garage_id: 67,
		part_id: 164,
		quantity: 234,
	},
	{
		garage_id: 67,
		part_id: 165,
		quantity: 312,
	},
	{
		garage_id: 67,
		part_id: 166,
		quantity: 253,
	},
	{
		garage_id: 67,
		part_id: 167,
		quantity: 164,
	},
	{
		garage_id: 67,
		part_id: 168,
		quantity: 262,
	},
	{
		garage_id: 67,
		part_id: 169,
		quantity: 415,
	},
	{
		garage_id: 67,
		part_id: 170,
		quantity: 234,
	},
	{
		garage_id: 67,
		part_id: 171,
		quantity: 104,
	},
	{
		garage_id: 67,
		part_id: 172,
		quantity: 367,
	},
	{
		garage_id: 67,
		part_id: 173,
		quantity: 180,
	},
	{
		garage_id: 67,
		part_id: 174,
		quantity: 82,
	},
	{
		garage_id: 67,
		part_id: 175,
		quantity: 237,
	},
	{
		garage_id: 67,
		part_id: 176,
		quantity: 308,
	},
	{
		garage_id: 67,
		part_id: 177,
		quantity: 149,
	},
	{
		garage_id: 67,
		part_id: 178,
		quantity: 29,
	},
	{
		garage_id: 67,
		part_id: 179,
		quantity: 311,
	},
	{
		garage_id: 67,
		part_id: 180,
		quantity: 367,
	},
	{
		garage_id: 67,
		part_id: 181,
		quantity: 450,
	},
	{
		garage_id: 67,
		part_id: 182,
		quantity: 483,
	},
	{
		garage_id: 67,
		part_id: 183,
		quantity: 249,
	},
	{
		garage_id: 67,
		part_id: 184,
		quantity: 409,
	},
	{
		garage_id: 67,
		part_id: 185,
		quantity: 333,
	},
	{
		garage_id: 67,
		part_id: 186,
		quantity: 14,
	},
	{
		garage_id: 67,
		part_id: 187,
		quantity: 312,
	},
	{
		garage_id: 67,
		part_id: 188,
		quantity: 452,
	},
	{
		garage_id: 67,
		part_id: 189,
		quantity: 9,
	},
	{
		garage_id: 67,
		part_id: 190,
		quantity: 276,
	},
	{
		garage_id: 67,
		part_id: 191,
		quantity: 110,
	},
	{
		garage_id: 67,
		part_id: 192,
		quantity: 193,
	},
	{
		garage_id: 67,
		part_id: 193,
		quantity: 86,
	},
	{
		garage_id: 67,
		part_id: 194,
		quantity: 385,
	},
	{
		garage_id: 67,
		part_id: 195,
		quantity: 446,
	},
	{
		garage_id: 67,
		part_id: 196,
		quantity: 251,
	},
	{
		garage_id: 67,
		part_id: 197,
		quantity: 188,
	},
	{
		garage_id: 67,
		part_id: 198,
		quantity: 150,
	},
	{
		garage_id: 67,
		part_id: 199,
		quantity: 101,
	},
	{
		garage_id: 67,
		part_id: 200,
		quantity: 411,
	},
	{
		garage_id: 67,
		part_id: 201,
		quantity: 148,
	},
	{
		garage_id: 67,
		part_id: 202,
		quantity: 407,
	},
	{
		garage_id: 67,
		part_id: 203,
		quantity: 187,
	},
	{
		garage_id: 67,
		part_id: 204,
		quantity: 127,
	},
	{
		garage_id: 67,
		part_id: 205,
		quantity: 310,
	},
	{
		garage_id: 67,
		part_id: 206,
		quantity: 235,
	},
	{
		garage_id: 67,
		part_id: 207,
		quantity: 448,
	},
	{
		garage_id: 67,
		part_id: 208,
		quantity: 80,
	},
	{
		garage_id: 67,
		part_id: 209,
		quantity: 228,
	},
	{
		garage_id: 67,
		part_id: 210,
		quantity: 250,
	},
	{
		garage_id: 67,
		part_id: 211,
		quantity: 318,
	},
	{
		garage_id: 67,
		part_id: 212,
		quantity: 368,
	},
	{
		garage_id: 67,
		part_id: 213,
		quantity: 288,
	},
	{
		garage_id: 67,
		part_id: 214,
		quantity: 448,
	},
	{
		garage_id: 67,
		part_id: 215,
		quantity: 408,
	},
	{
		garage_id: 67,
		part_id: 216,
		quantity: 184,
	},
	{
		garage_id: 67,
		part_id: 217,
		quantity: 279,
	},
	{
		garage_id: 67,
		part_id: 218,
		quantity: 434,
	},
	{
		garage_id: 67,
		part_id: 219,
		quantity: 273,
	},
	{
		garage_id: 67,
		part_id: 220,
		quantity: 127,
	},
	{
		garage_id: 67,
		part_id: 221,
		quantity: 123,
	},
	{
		garage_id: 67,
		part_id: 222,
		quantity: 351,
	},
	{
		garage_id: 67,
		part_id: 223,
		quantity: 472,
	},
	{
		garage_id: 67,
		part_id: 224,
		quantity: 464,
	},
	{
		garage_id: 67,
		part_id: 225,
		quantity: 224,
	},
	{
		garage_id: 67,
		part_id: 226,
		quantity: 485,
	},
	{
		garage_id: 67,
		part_id: 227,
		quantity: 470,
	},
	{
		garage_id: 67,
		part_id: 228,
		quantity: 86,
	},
	{
		garage_id: 67,
		part_id: 229,
		quantity: 200,
	},
	{
		garage_id: 67,
		part_id: 230,
		quantity: 46,
	},
	{
		garage_id: 67,
		part_id: 231,
		quantity: 444,
	},
	{
		garage_id: 67,
		part_id: 232,
		quantity: 294,
	},
	{
		garage_id: 67,
		part_id: 233,
		quantity: 138,
	},
	{
		garage_id: 67,
		part_id: 234,
		quantity: 202,
	},
	{
		garage_id: 67,
		part_id: 235,
		quantity: 300,
	},
	{
		garage_id: 67,
		part_id: 236,
		quantity: 429,
	},
	{
		garage_id: 67,
		part_id: 237,
		quantity: 456,
	},
	{
		garage_id: 67,
		part_id: 238,
		quantity: 399,
	},
	{
		garage_id: 67,
		part_id: 239,
		quantity: 241,
	},
	{
		garage_id: 67,
		part_id: 240,
		quantity: 467,
	},
	{
		garage_id: 67,
		part_id: 241,
		quantity: 282,
	},
	{
		garage_id: 67,
		part_id: 242,
		quantity: 49,
	},
	{
		garage_id: 67,
		part_id: 243,
		quantity: 411,
	},
	{
		garage_id: 67,
		part_id: 244,
		quantity: 395,
	},
	{
		garage_id: 67,
		part_id: 245,
		quantity: 392,
	},
	{
		garage_id: 67,
		part_id: 246,
		quantity: 151,
	},
	{
		garage_id: 67,
		part_id: 247,
		quantity: 192,
	},
	{
		garage_id: 67,
		part_id: 248,
		quantity: 438,
	},
	{
		garage_id: 67,
		part_id: 249,
		quantity: 126,
	},
	{
		garage_id: 67,
		part_id: 250,
		quantity: 230,
	},
	{
		garage_id: 67,
		part_id: 251,
		quantity: 481,
	},
	{
		garage_id: 67,
		part_id: 252,
		quantity: 21,
	},
	{
		garage_id: 67,
		part_id: 253,
		quantity: 233,
	},
	{
		garage_id: 67,
		part_id: 254,
		quantity: 366,
	},
	{
		garage_id: 67,
		part_id: 255,
		quantity: 341,
	},
	{
		garage_id: 67,
		part_id: 256,
		quantity: 112,
	},
	{
		garage_id: 67,
		part_id: 257,
		quantity: 433,
	},
	{
		garage_id: 67,
		part_id: 258,
		quantity: 35,
	},
	{
		garage_id: 67,
		part_id: 259,
		quantity: 188,
	},
	{
		garage_id: 67,
		part_id: 260,
		quantity: 12,
	},
	{
		garage_id: 67,
		part_id: 261,
		quantity: 452,
	},
	{
		garage_id: 67,
		part_id: 262,
		quantity: 207,
	},
	{
		garage_id: 67,
		part_id: 263,
		quantity: 411,
	},
	{
		garage_id: 67,
		part_id: 264,
		quantity: 52,
	},
	{
		garage_id: 67,
		part_id: 265,
		quantity: 259,
	},
	{
		garage_id: 67,
		part_id: 266,
		quantity: 68,
	},
	{
		garage_id: 67,
		part_id: 267,
		quantity: 7,
	},
	{
		garage_id: 67,
		part_id: 268,
		quantity: 423,
	},
	{
		garage_id: 67,
		part_id: 269,
		quantity: 399,
	},
	{
		garage_id: 67,
		part_id: 270,
		quantity: 300,
	},
	{
		garage_id: 67,
		part_id: 271,
		quantity: 473,
	},
	{
		garage_id: 67,
		part_id: 272,
		quantity: 28,
	},
	{
		garage_id: 67,
		part_id: 273,
		quantity: 272,
	},
	{
		garage_id: 67,
		part_id: 274,
		quantity: 301,
	},
	{
		garage_id: 67,
		part_id: 275,
		quantity: 64,
	},
	{
		garage_id: 67,
		part_id: 276,
		quantity: 129,
	},
	{
		garage_id: 67,
		part_id: 277,
		quantity: 403,
	},
	{
		garage_id: 67,
		part_id: 278,
		quantity: 77,
	},
	{
		garage_id: 67,
		part_id: 279,
		quantity: 232,
	},
	{
		garage_id: 67,
		part_id: 280,
		quantity: 423,
	},
	{
		garage_id: 67,
		part_id: 281,
		quantity: 252,
	},
	{
		garage_id: 67,
		part_id: 282,
		quantity: 237,
	},
	{
		garage_id: 67,
		part_id: 283,
		quantity: 492,
	},
	{
		garage_id: 67,
		part_id: 284,
		quantity: 280,
	},
	{
		garage_id: 67,
		part_id: 285,
		quantity: 332,
	},
	{
		garage_id: 67,
		part_id: 286,
		quantity: 42,
	},
	{
		garage_id: 67,
		part_id: 287,
		quantity: 55,
	},
	{
		garage_id: 67,
		part_id: 288,
		quantity: 239,
	},
	{
		garage_id: 67,
		part_id: 289,
		quantity: 96,
	},
	{
		garage_id: 67,
		part_id: 290,
		quantity: 407,
	},
	{
		garage_id: 67,
		part_id: 291,
		quantity: 354,
	},
	{
		garage_id: 67,
		part_id: 292,
		quantity: 254,
	},
	{
		garage_id: 67,
		part_id: 293,
		quantity: 36,
	},
	{
		garage_id: 67,
		part_id: 294,
		quantity: 358,
	},
	{
		garage_id: 67,
		part_id: 295,
		quantity: 452,
	},
	{
		garage_id: 67,
		part_id: 296,
		quantity: 389,
	},
	{
		garage_id: 67,
		part_id: 297,
		quantity: 357,
	},
	{
		garage_id: 67,
		part_id: 298,
		quantity: 349,
	},
	{
		garage_id: 67,
		part_id: 299,
		quantity: 94,
	},
	{
		garage_id: 67,
		part_id: 300,
		quantity: 112,
	},
	{
		garage_id: 67,
		part_id: 301,
		quantity: 272,
	},
	{
		garage_id: 67,
		part_id: 302,
		quantity: 337,
	},
	{
		garage_id: 67,
		part_id: 303,
		quantity: 121,
	},
	{
		garage_id: 67,
		part_id: 304,
		quantity: 296,
	},
	{
		garage_id: 67,
		part_id: 305,
		quantity: 52,
	},
	{
		garage_id: 67,
		part_id: 306,
		quantity: 412,
	},
	{
		garage_id: 67,
		part_id: 307,
		quantity: 263,
	},
	{
		garage_id: 67,
		part_id: 308,
		quantity: 3,
	},
	{
		garage_id: 67,
		part_id: 309,
		quantity: 266,
	},
	{
		garage_id: 67,
		part_id: 310,
		quantity: 30,
	},
	{
		garage_id: 67,
		part_id: 311,
		quantity: 139,
	},
	{
		garage_id: 67,
		part_id: 312,
		quantity: 204,
	},
	{
		garage_id: 67,
		part_id: 313,
		quantity: 403,
	},
	{
		garage_id: 67,
		part_id: 314,
		quantity: 51,
	},
	{
		garage_id: 67,
		part_id: 315,
		quantity: 318,
	},
	{
		garage_id: 67,
		part_id: 316,
		quantity: 321,
	},
	{
		garage_id: 67,
		part_id: 317,
		quantity: 466,
	},
	{
		garage_id: 67,
		part_id: 318,
		quantity: 299,
	},
	{
		garage_id: 67,
		part_id: 319,
		quantity: 24,
	},
	{
		garage_id: 67,
		part_id: 320,
		quantity: 168,
	},
	{
		garage_id: 67,
		part_id: 321,
		quantity: 365,
	},
	{
		garage_id: 67,
		part_id: 322,
		quantity: 469,
	},
	{
		garage_id: 67,
		part_id: 323,
		quantity: 321,
	},
	{
		garage_id: 67,
		part_id: 324,
		quantity: 30,
	},
	{
		garage_id: 67,
		part_id: 325,
		quantity: 498,
	},
	{
		garage_id: 67,
		part_id: 326,
		quantity: 177,
	},
	{
		garage_id: 67,
		part_id: 327,
		quantity: 106,
	},
	{
		garage_id: 67,
		part_id: 328,
		quantity: 413,
	},
	{
		garage_id: 67,
		part_id: 329,
		quantity: 454,
	},
	{
		garage_id: 67,
		part_id: 330,
		quantity: 373,
	},
	{
		garage_id: 67,
		part_id: 331,
		quantity: 222,
	},
	{
		garage_id: 67,
		part_id: 332,
		quantity: 201,
	},
	{
		garage_id: 67,
		part_id: 333,
		quantity: 159,
	},
	{
		garage_id: 67,
		part_id: 334,
		quantity: 92,
	},
	{
		garage_id: 67,
		part_id: 335,
		quantity: 226,
	},
	{
		garage_id: 67,
		part_id: 336,
		quantity: 366,
	},
	{
		garage_id: 67,
		part_id: 337,
		quantity: 251,
	},
	{
		garage_id: 67,
		part_id: 338,
		quantity: 140,
	},
	{
		garage_id: 67,
		part_id: 339,
		quantity: 418,
	},
	{
		garage_id: 67,
		part_id: 340,
		quantity: 351,
	},
	{
		garage_id: 67,
		part_id: 341,
		quantity: 82,
	},
	{
		garage_id: 67,
		part_id: 342,
		quantity: 38,
	},
	{
		garage_id: 67,
		part_id: 343,
		quantity: 456,
	},
	{
		garage_id: 67,
		part_id: 344,
		quantity: 425,
	},
	{
		garage_id: 67,
		part_id: 345,
		quantity: 178,
	},
	{
		garage_id: 67,
		part_id: 346,
		quantity: 327,
	},
	{
		garage_id: 67,
		part_id: 347,
		quantity: 66,
	},
	{
		garage_id: 67,
		part_id: 348,
		quantity: 439,
	},
	{
		garage_id: 67,
		part_id: 349,
		quantity: 78,
	},
	{
		garage_id: 67,
		part_id: 350,
		quantity: 296,
	},
	{
		garage_id: 67,
		part_id: 351,
		quantity: 301,
	},
	{
		garage_id: 67,
		part_id: 352,
		quantity: 169,
	},
	{
		garage_id: 67,
		part_id: 353,
		quantity: 232,
	},
	{
		garage_id: 67,
		part_id: 354,
		quantity: 81,
	},
	{
		garage_id: 67,
		part_id: 355,
		quantity: 32,
	},
	{
		garage_id: 67,
		part_id: 356,
		quantity: 397,
	},
	{
		garage_id: 67,
		part_id: 357,
		quantity: 204,
	},
	{
		garage_id: 67,
		part_id: 358,
		quantity: 25,
	},
	{
		garage_id: 67,
		part_id: 359,
		quantity: 40,
	},
	{
		garage_id: 67,
		part_id: 360,
		quantity: 262,
	},
	{
		garage_id: 67,
		part_id: 361,
		quantity: 31,
	},
	{
		garage_id: 67,
		part_id: 362,
		quantity: 86,
	},
	{
		garage_id: 67,
		part_id: 363,
		quantity: 48,
	},
	{
		garage_id: 67,
		part_id: 364,
		quantity: 54,
	},
	{
		garage_id: 67,
		part_id: 365,
		quantity: 272,
	},
	{
		garage_id: 67,
		part_id: 366,
		quantity: 343,
	},
	{
		garage_id: 67,
		part_id: 367,
		quantity: 477,
	},
	{
		garage_id: 67,
		part_id: 368,
		quantity: 63,
	},
	{
		garage_id: 67,
		part_id: 369,
		quantity: 112,
	},
	{
		garage_id: 67,
		part_id: 370,
		quantity: 164,
	},
	{
		garage_id: 67,
		part_id: 371,
		quantity: 147,
	},
	{
		garage_id: 67,
		part_id: 372,
		quantity: 379,
	},
	{
		garage_id: 67,
		part_id: 373,
		quantity: 257,
	},
	{
		garage_id: 67,
		part_id: 374,
		quantity: 399,
	},
	{
		garage_id: 67,
		part_id: 375,
		quantity: 73,
	},
	{
		garage_id: 67,
		part_id: 376,
		quantity: 335,
	},
	{
		garage_id: 67,
		part_id: 377,
		quantity: 107,
	},
	{
		garage_id: 67,
		part_id: 378,
		quantity: 374,
	},
	{
		garage_id: 67,
		part_id: 379,
		quantity: 416,
	},
	{
		garage_id: 67,
		part_id: 380,
		quantity: 419,
	},
	{
		garage_id: 67,
		part_id: 381,
		quantity: 209,
	},
	{
		garage_id: 67,
		part_id: 382,
		quantity: 369,
	},
	{
		garage_id: 67,
		part_id: 383,
		quantity: 111,
	},
	{
		garage_id: 67,
		part_id: 384,
		quantity: 24,
	},
	{
		garage_id: 67,
		part_id: 385,
		quantity: 488,
	},
	{
		garage_id: 67,
		part_id: 386,
		quantity: 350,
	},
	{
		garage_id: 67,
		part_id: 387,
		quantity: 50,
	},
	{
		garage_id: 67,
		part_id: 388,
		quantity: 389,
	},
	{
		garage_id: 67,
		part_id: 389,
		quantity: 293,
	},
	{
		garage_id: 67,
		part_id: 390,
		quantity: 205,
	},
	{
		garage_id: 67,
		part_id: 391,
		quantity: 128,
	},
	{
		garage_id: 67,
		part_id: 392,
		quantity: 73,
	},
	{
		garage_id: 67,
		part_id: 393,
		quantity: 431,
	},
	{
		garage_id: 67,
		part_id: 394,
		quantity: 177,
	},
	{
		garage_id: 67,
		part_id: 395,
		quantity: 314,
	},
	{
		garage_id: 67,
		part_id: 396,
		quantity: 261,
	},
	{
		garage_id: 67,
		part_id: 397,
		quantity: 112,
	},
	{
		garage_id: 67,
		part_id: 398,
		quantity: 410,
	},
	{
		garage_id: 67,
		part_id: 399,
		quantity: 455,
	},
	{
		garage_id: 68,
		part_id: 0,
		quantity: 307,
	},
	{
		garage_id: 68,
		part_id: 1,
		quantity: 156,
	},
	{
		garage_id: 68,
		part_id: 2,
		quantity: 85,
	},
	{
		garage_id: 68,
		part_id: 3,
		quantity: 252,
	},
	{
		garage_id: 68,
		part_id: 4,
		quantity: 243,
	},
	{
		garage_id: 68,
		part_id: 5,
		quantity: 414,
	},
	{
		garage_id: 68,
		part_id: 6,
		quantity: 165,
	},
	{
		garage_id: 68,
		part_id: 7,
		quantity: 195,
	},
	{
		garage_id: 68,
		part_id: 8,
		quantity: 103,
	},
	{
		garage_id: 68,
		part_id: 9,
		quantity: 359,
	},
	{
		garage_id: 68,
		part_id: 10,
		quantity: 246,
	},
	{
		garage_id: 68,
		part_id: 11,
		quantity: 173,
	},
	{
		garage_id: 68,
		part_id: 12,
		quantity: 149,
	},
	{
		garage_id: 68,
		part_id: 13,
		quantity: 22,
	},
	{
		garage_id: 68,
		part_id: 14,
		quantity: 223,
	},
	{
		garage_id: 68,
		part_id: 15,
		quantity: 266,
	},
	{
		garage_id: 68,
		part_id: 16,
		quantity: 66,
	},
	{
		garage_id: 68,
		part_id: 17,
		quantity: 143,
	},
	{
		garage_id: 68,
		part_id: 18,
		quantity: 214,
	},
	{
		garage_id: 68,
		part_id: 19,
		quantity: 127,
	},
	{
		garage_id: 68,
		part_id: 20,
		quantity: 131,
	},
	{
		garage_id: 68,
		part_id: 21,
		quantity: 190,
	},
	{
		garage_id: 68,
		part_id: 22,
		quantity: 157,
	},
	{
		garage_id: 68,
		part_id: 23,
		quantity: 160,
	},
	{
		garage_id: 68,
		part_id: 24,
		quantity: 211,
	},
	{
		garage_id: 68,
		part_id: 25,
		quantity: 163,
	},
	{
		garage_id: 68,
		part_id: 26,
		quantity: 424,
	},
	{
		garage_id: 68,
		part_id: 27,
		quantity: 436,
	},
	{
		garage_id: 68,
		part_id: 28,
		quantity: 40,
	},
	{
		garage_id: 68,
		part_id: 29,
		quantity: 153,
	},
	{
		garage_id: 68,
		part_id: 30,
		quantity: 220,
	},
	{
		garage_id: 68,
		part_id: 31,
		quantity: 261,
	},
	{
		garage_id: 68,
		part_id: 32,
		quantity: 73,
	},
	{
		garage_id: 68,
		part_id: 33,
		quantity: 308,
	},
	{
		garage_id: 68,
		part_id: 34,
		quantity: 260,
	},
	{
		garage_id: 68,
		part_id: 35,
		quantity: 280,
	},
	{
		garage_id: 68,
		part_id: 36,
		quantity: 200,
	},
	{
		garage_id: 68,
		part_id: 37,
		quantity: 168,
	},
	{
		garage_id: 68,
		part_id: 38,
		quantity: 480,
	},
	{
		garage_id: 68,
		part_id: 39,
		quantity: 466,
	},
	{
		garage_id: 68,
		part_id: 40,
		quantity: 271,
	},
	{
		garage_id: 68,
		part_id: 41,
		quantity: 176,
	},
	{
		garage_id: 68,
		part_id: 42,
		quantity: 354,
	},
	{
		garage_id: 68,
		part_id: 43,
		quantity: 482,
	},
	{
		garage_id: 68,
		part_id: 44,
		quantity: 126,
	},
	{
		garage_id: 68,
		part_id: 45,
		quantity: 103,
	},
	{
		garage_id: 68,
		part_id: 46,
		quantity: 368,
	},
	{
		garage_id: 68,
		part_id: 47,
		quantity: 495,
	},
	{
		garage_id: 68,
		part_id: 48,
		quantity: 138,
	},
	{
		garage_id: 68,
		part_id: 49,
		quantity: 152,
	},
	{
		garage_id: 68,
		part_id: 50,
		quantity: 370,
	},
	{
		garage_id: 68,
		part_id: 51,
		quantity: 464,
	},
	{
		garage_id: 68,
		part_id: 52,
		quantity: 334,
	},
	{
		garage_id: 68,
		part_id: 53,
		quantity: 48,
	},
	{
		garage_id: 68,
		part_id: 54,
		quantity: 92,
	},
	{
		garage_id: 68,
		part_id: 55,
		quantity: 175,
	},
	{
		garage_id: 68,
		part_id: 56,
		quantity: 254,
	},
	{
		garage_id: 68,
		part_id: 57,
		quantity: 232,
	},
	{
		garage_id: 68,
		part_id: 58,
		quantity: 316,
	},
	{
		garage_id: 68,
		part_id: 59,
		quantity: 60,
	},
	{
		garage_id: 68,
		part_id: 60,
		quantity: 50,
	},
	{
		garage_id: 68,
		part_id: 61,
		quantity: 288,
	},
	{
		garage_id: 68,
		part_id: 62,
		quantity: 376,
	},
	{
		garage_id: 68,
		part_id: 63,
		quantity: 298,
	},
	{
		garage_id: 68,
		part_id: 64,
		quantity: 402,
	},
	{
		garage_id: 68,
		part_id: 65,
		quantity: 108,
	},
	{
		garage_id: 68,
		part_id: 66,
		quantity: 225,
	},
	{
		garage_id: 68,
		part_id: 67,
		quantity: 365,
	},
	{
		garage_id: 68,
		part_id: 68,
		quantity: 10,
	},
	{
		garage_id: 68,
		part_id: 69,
		quantity: 275,
	},
	{
		garage_id: 68,
		part_id: 70,
		quantity: 344,
	},
	{
		garage_id: 68,
		part_id: 71,
		quantity: 207,
	},
	{
		garage_id: 68,
		part_id: 72,
		quantity: 445,
	},
	{
		garage_id: 68,
		part_id: 73,
		quantity: 135,
	},
	{
		garage_id: 68,
		part_id: 74,
		quantity: 332,
	},
	{
		garage_id: 68,
		part_id: 75,
		quantity: 29,
	},
	{
		garage_id: 68,
		part_id: 76,
		quantity: 88,
	},
	{
		garage_id: 68,
		part_id: 77,
		quantity: 369,
	},
	{
		garage_id: 68,
		part_id: 78,
		quantity: 387,
	},
	{
		garage_id: 68,
		part_id: 79,
		quantity: 471,
	},
	{
		garage_id: 68,
		part_id: 80,
		quantity: 170,
	},
	{
		garage_id: 68,
		part_id: 81,
		quantity: 291,
	},
	{
		garage_id: 68,
		part_id: 82,
		quantity: 227,
	},
	{
		garage_id: 68,
		part_id: 83,
		quantity: 428,
	},
	{
		garage_id: 68,
		part_id: 84,
		quantity: 242,
	},
	{
		garage_id: 68,
		part_id: 85,
		quantity: 431,
	},
	{
		garage_id: 68,
		part_id: 86,
		quantity: 24,
	},
	{
		garage_id: 68,
		part_id: 87,
		quantity: 221,
	},
	{
		garage_id: 68,
		part_id: 88,
		quantity: 172,
	},
	{
		garage_id: 68,
		part_id: 89,
		quantity: 473,
	},
	{
		garage_id: 68,
		part_id: 90,
		quantity: 2,
	},
	{
		garage_id: 68,
		part_id: 91,
		quantity: 272,
	},
	{
		garage_id: 68,
		part_id: 92,
		quantity: 165,
	},
	{
		garage_id: 68,
		part_id: 93,
		quantity: 478,
	},
	{
		garage_id: 68,
		part_id: 94,
		quantity: 288,
	},
	{
		garage_id: 68,
		part_id: 95,
		quantity: 336,
	},
	{
		garage_id: 68,
		part_id: 96,
		quantity: 400,
	},
	{
		garage_id: 68,
		part_id: 97,
		quantity: 420,
	},
	{
		garage_id: 68,
		part_id: 98,
		quantity: 428,
	},
	{
		garage_id: 68,
		part_id: 99,
		quantity: 280,
	},
	{
		garage_id: 68,
		part_id: 100,
		quantity: 462,
	},
	{
		garage_id: 68,
		part_id: 101,
		quantity: 354,
	},
	{
		garage_id: 68,
		part_id: 102,
		quantity: 163,
	},
	{
		garage_id: 68,
		part_id: 103,
		quantity: 350,
	},
	{
		garage_id: 68,
		part_id: 104,
		quantity: 471,
	},
	{
		garage_id: 68,
		part_id: 105,
		quantity: 313,
	},
	{
		garage_id: 68,
		part_id: 106,
		quantity: 158,
	},
	{
		garage_id: 68,
		part_id: 107,
		quantity: 46,
	},
	{
		garage_id: 68,
		part_id: 108,
		quantity: 287,
	},
	{
		garage_id: 68,
		part_id: 109,
		quantity: 42,
	},
	{
		garage_id: 68,
		part_id: 110,
		quantity: 135,
	},
	{
		garage_id: 68,
		part_id: 111,
		quantity: 362,
	},
	{
		garage_id: 68,
		part_id: 112,
		quantity: 164,
	},
	{
		garage_id: 68,
		part_id: 113,
		quantity: 65,
	},
	{
		garage_id: 68,
		part_id: 114,
		quantity: 255,
	},
	{
		garage_id: 68,
		part_id: 115,
		quantity: 117,
	},
	{
		garage_id: 68,
		part_id: 116,
		quantity: 373,
	},
	{
		garage_id: 68,
		part_id: 117,
		quantity: 36,
	},
	{
		garage_id: 68,
		part_id: 118,
		quantity: 283,
	},
	{
		garage_id: 68,
		part_id: 119,
		quantity: 94,
	},
	{
		garage_id: 68,
		part_id: 120,
		quantity: 360,
	},
	{
		garage_id: 68,
		part_id: 121,
		quantity: 349,
	},
	{
		garage_id: 68,
		part_id: 122,
		quantity: 150,
	},
	{
		garage_id: 68,
		part_id: 123,
		quantity: 99,
	},
	{
		garage_id: 68,
		part_id: 124,
		quantity: 271,
	},
	{
		garage_id: 68,
		part_id: 125,
		quantity: 497,
	},
	{
		garage_id: 68,
		part_id: 126,
		quantity: 472,
	},
	{
		garage_id: 68,
		part_id: 127,
		quantity: 456,
	},
	{
		garage_id: 68,
		part_id: 128,
		quantity: 112,
	},
	{
		garage_id: 68,
		part_id: 129,
		quantity: 326,
	},
	{
		garage_id: 68,
		part_id: 130,
		quantity: 143,
	},
	{
		garage_id: 68,
		part_id: 131,
		quantity: 182,
	},
	{
		garage_id: 68,
		part_id: 132,
		quantity: 410,
	},
	{
		garage_id: 68,
		part_id: 133,
		quantity: 131,
	},
	{
		garage_id: 68,
		part_id: 134,
		quantity: 67,
	},
	{
		garage_id: 68,
		part_id: 135,
		quantity: 48,
	},
	{
		garage_id: 68,
		part_id: 136,
		quantity: 42,
	},
	{
		garage_id: 68,
		part_id: 137,
		quantity: 218,
	},
	{
		garage_id: 68,
		part_id: 138,
		quantity: 143,
	},
	{
		garage_id: 68,
		part_id: 139,
		quantity: 134,
	},
	{
		garage_id: 68,
		part_id: 140,
		quantity: 212,
	},
	{
		garage_id: 68,
		part_id: 141,
		quantity: 274,
	},
	{
		garage_id: 68,
		part_id: 142,
		quantity: 49,
	},
	{
		garage_id: 68,
		part_id: 143,
		quantity: 441,
	},
	{
		garage_id: 68,
		part_id: 144,
		quantity: 351,
	},
	{
		garage_id: 68,
		part_id: 145,
		quantity: 213,
	},
	{
		garage_id: 68,
		part_id: 146,
		quantity: 426,
	},
	{
		garage_id: 68,
		part_id: 147,
		quantity: 481,
	},
	{
		garage_id: 68,
		part_id: 148,
		quantity: 89,
	},
	{
		garage_id: 68,
		part_id: 149,
		quantity: 198,
	},
	{
		garage_id: 68,
		part_id: 150,
		quantity: 119,
	},
	{
		garage_id: 68,
		part_id: 151,
		quantity: 112,
	},
	{
		garage_id: 68,
		part_id: 152,
		quantity: 303,
	},
	{
		garage_id: 68,
		part_id: 153,
		quantity: 350,
	},
	{
		garage_id: 68,
		part_id: 154,
		quantity: 33,
	},
	{
		garage_id: 68,
		part_id: 155,
		quantity: 349,
	},
	{
		garage_id: 68,
		part_id: 156,
		quantity: 69,
	},
	{
		garage_id: 68,
		part_id: 157,
		quantity: 206,
	},
	{
		garage_id: 68,
		part_id: 158,
		quantity: 175,
	},
	{
		garage_id: 68,
		part_id: 159,
		quantity: 234,
	},
	{
		garage_id: 68,
		part_id: 160,
		quantity: 411,
	},
	{
		garage_id: 68,
		part_id: 161,
		quantity: 265,
	},
	{
		garage_id: 68,
		part_id: 162,
		quantity: 173,
	},
	{
		garage_id: 68,
		part_id: 163,
		quantity: 75,
	},
	{
		garage_id: 68,
		part_id: 164,
		quantity: 173,
	},
	{
		garage_id: 68,
		part_id: 165,
		quantity: 102,
	},
	{
		garage_id: 68,
		part_id: 166,
		quantity: 328,
	},
	{
		garage_id: 68,
		part_id: 167,
		quantity: 59,
	},
	{
		garage_id: 68,
		part_id: 168,
		quantity: 80,
	},
	{
		garage_id: 68,
		part_id: 169,
		quantity: 390,
	},
	{
		garage_id: 68,
		part_id: 170,
		quantity: 156,
	},
	{
		garage_id: 68,
		part_id: 171,
		quantity: 1,
	},
	{
		garage_id: 68,
		part_id: 172,
		quantity: 48,
	},
	{
		garage_id: 68,
		part_id: 173,
		quantity: 46,
	},
	{
		garage_id: 68,
		part_id: 174,
		quantity: 404,
	},
	{
		garage_id: 68,
		part_id: 175,
		quantity: 60,
	},
	{
		garage_id: 68,
		part_id: 176,
		quantity: 252,
	},
	{
		garage_id: 68,
		part_id: 177,
		quantity: 62,
	},
	{
		garage_id: 68,
		part_id: 178,
		quantity: 433,
	},
	{
		garage_id: 68,
		part_id: 179,
		quantity: 144,
	},
	{
		garage_id: 68,
		part_id: 180,
		quantity: 424,
	},
	{
		garage_id: 68,
		part_id: 181,
		quantity: 462,
	},
	{
		garage_id: 68,
		part_id: 182,
		quantity: 258,
	},
	{
		garage_id: 68,
		part_id: 183,
		quantity: 245,
	},
	{
		garage_id: 68,
		part_id: 184,
		quantity: 74,
	},
	{
		garage_id: 68,
		part_id: 185,
		quantity: 319,
	},
	{
		garage_id: 68,
		part_id: 186,
		quantity: 76,
	},
	{
		garage_id: 68,
		part_id: 187,
		quantity: 416,
	},
	{
		garage_id: 68,
		part_id: 188,
		quantity: 225,
	},
	{
		garage_id: 68,
		part_id: 189,
		quantity: 348,
	},
	{
		garage_id: 68,
		part_id: 190,
		quantity: 223,
	},
	{
		garage_id: 68,
		part_id: 191,
		quantity: 38,
	},
	{
		garage_id: 68,
		part_id: 192,
		quantity: 269,
	},
	{
		garage_id: 68,
		part_id: 193,
		quantity: 307,
	},
	{
		garage_id: 68,
		part_id: 194,
		quantity: 119,
	},
	{
		garage_id: 68,
		part_id: 195,
		quantity: 225,
	},
	{
		garage_id: 68,
		part_id: 196,
		quantity: 441,
	},
	{
		garage_id: 68,
		part_id: 197,
		quantity: 245,
	},
	{
		garage_id: 68,
		part_id: 198,
		quantity: 162,
	},
	{
		garage_id: 68,
		part_id: 199,
		quantity: 412,
	},
	{
		garage_id: 68,
		part_id: 200,
		quantity: 318,
	},
	{
		garage_id: 68,
		part_id: 201,
		quantity: 258,
	},
	{
		garage_id: 68,
		part_id: 202,
		quantity: 262,
	},
	{
		garage_id: 68,
		part_id: 203,
		quantity: 207,
	},
	{
		garage_id: 68,
		part_id: 204,
		quantity: 157,
	},
	{
		garage_id: 68,
		part_id: 205,
		quantity: 67,
	},
	{
		garage_id: 68,
		part_id: 206,
		quantity: 84,
	},
	{
		garage_id: 68,
		part_id: 207,
		quantity: 124,
	},
	{
		garage_id: 68,
		part_id: 208,
		quantity: 57,
	},
	{
		garage_id: 68,
		part_id: 209,
		quantity: 71,
	},
	{
		garage_id: 68,
		part_id: 210,
		quantity: 384,
	},
	{
		garage_id: 68,
		part_id: 211,
		quantity: 314,
	},
	{
		garage_id: 68,
		part_id: 212,
		quantity: 459,
	},
	{
		garage_id: 68,
		part_id: 213,
		quantity: 289,
	},
	{
		garage_id: 68,
		part_id: 214,
		quantity: 294,
	},
	{
		garage_id: 68,
		part_id: 215,
		quantity: 96,
	},
	{
		garage_id: 68,
		part_id: 216,
		quantity: 405,
	},
	{
		garage_id: 68,
		part_id: 217,
		quantity: 210,
	},
	{
		garage_id: 68,
		part_id: 218,
		quantity: 228,
	},
	{
		garage_id: 68,
		part_id: 219,
		quantity: 176,
	},
	{
		garage_id: 68,
		part_id: 220,
		quantity: 210,
	},
	{
		garage_id: 68,
		part_id: 221,
		quantity: 342,
	},
	{
		garage_id: 68,
		part_id: 222,
		quantity: 431,
	},
	{
		garage_id: 68,
		part_id: 223,
		quantity: 308,
	},
	{
		garage_id: 68,
		part_id: 224,
		quantity: 360,
	},
	{
		garage_id: 68,
		part_id: 225,
		quantity: 366,
	},
	{
		garage_id: 68,
		part_id: 226,
		quantity: 227,
	},
	{
		garage_id: 68,
		part_id: 227,
		quantity: 102,
	},
	{
		garage_id: 68,
		part_id: 228,
		quantity: 55,
	},
	{
		garage_id: 68,
		part_id: 229,
		quantity: 198,
	},
	{
		garage_id: 68,
		part_id: 230,
		quantity: 475,
	},
	{
		garage_id: 68,
		part_id: 231,
		quantity: 108,
	},
	{
		garage_id: 68,
		part_id: 232,
		quantity: 233,
	},
	{
		garage_id: 68,
		part_id: 233,
		quantity: 301,
	},
	{
		garage_id: 68,
		part_id: 234,
		quantity: 54,
	},
	{
		garage_id: 68,
		part_id: 235,
		quantity: 133,
	},
	{
		garage_id: 68,
		part_id: 236,
		quantity: 26,
	},
	{
		garage_id: 68,
		part_id: 237,
		quantity: 58,
	},
	{
		garage_id: 68,
		part_id: 238,
		quantity: 472,
	},
	{
		garage_id: 68,
		part_id: 239,
		quantity: 88,
	},
	{
		garage_id: 68,
		part_id: 240,
		quantity: 495,
	},
	{
		garage_id: 68,
		part_id: 241,
		quantity: 406,
	},
	{
		garage_id: 68,
		part_id: 242,
		quantity: 224,
	},
	{
		garage_id: 68,
		part_id: 243,
		quantity: 258,
	},
	{
		garage_id: 68,
		part_id: 244,
		quantity: 143,
	},
	{
		garage_id: 68,
		part_id: 245,
		quantity: 390,
	},
	{
		garage_id: 68,
		part_id: 246,
		quantity: 53,
	},
	{
		garage_id: 68,
		part_id: 247,
		quantity: 19,
	},
	{
		garage_id: 68,
		part_id: 248,
		quantity: 363,
	},
	{
		garage_id: 68,
		part_id: 249,
		quantity: 158,
	},
	{
		garage_id: 68,
		part_id: 250,
		quantity: 459,
	},
	{
		garage_id: 68,
		part_id: 251,
		quantity: 205,
	},
	{
		garage_id: 68,
		part_id: 252,
		quantity: 237,
	},
	{
		garage_id: 68,
		part_id: 253,
		quantity: 333,
	},
	{
		garage_id: 68,
		part_id: 254,
		quantity: 329,
	},
	{
		garage_id: 68,
		part_id: 255,
		quantity: 315,
	},
	{
		garage_id: 68,
		part_id: 256,
		quantity: 171,
	},
	{
		garage_id: 68,
		part_id: 257,
		quantity: 494,
	},
	{
		garage_id: 68,
		part_id: 258,
		quantity: 446,
	},
	{
		garage_id: 68,
		part_id: 259,
		quantity: 187,
	},
	{
		garage_id: 68,
		part_id: 260,
		quantity: 213,
	},
	{
		garage_id: 68,
		part_id: 261,
		quantity: 384,
	},
	{
		garage_id: 68,
		part_id: 262,
		quantity: 106,
	},
	{
		garage_id: 68,
		part_id: 263,
		quantity: 46,
	},
	{
		garage_id: 68,
		part_id: 264,
		quantity: 430,
	},
	{
		garage_id: 68,
		part_id: 265,
		quantity: 109,
	},
	{
		garage_id: 68,
		part_id: 266,
		quantity: 177,
	},
	{
		garage_id: 68,
		part_id: 267,
		quantity: 33,
	},
	{
		garage_id: 68,
		part_id: 268,
		quantity: 307,
	},
	{
		garage_id: 68,
		part_id: 269,
		quantity: 33,
	},
	{
		garage_id: 68,
		part_id: 270,
		quantity: 117,
	},
	{
		garage_id: 68,
		part_id: 271,
		quantity: 68,
	},
	{
		garage_id: 68,
		part_id: 272,
		quantity: 119,
	},
	{
		garage_id: 68,
		part_id: 273,
		quantity: 194,
	},
	{
		garage_id: 68,
		part_id: 274,
		quantity: 434,
	},
	{
		garage_id: 68,
		part_id: 275,
		quantity: 131,
	},
	{
		garage_id: 68,
		part_id: 276,
		quantity: 87,
	},
	{
		garage_id: 68,
		part_id: 277,
		quantity: 400,
	},
	{
		garage_id: 68,
		part_id: 278,
		quantity: 25,
	},
	{
		garage_id: 68,
		part_id: 279,
		quantity: 420,
	},
	{
		garage_id: 68,
		part_id: 280,
		quantity: 424,
	},
	{
		garage_id: 68,
		part_id: 281,
		quantity: 323,
	},
	{
		garage_id: 68,
		part_id: 282,
		quantity: 232,
	},
	{
		garage_id: 68,
		part_id: 283,
		quantity: 332,
	},
	{
		garage_id: 68,
		part_id: 284,
		quantity: 444,
	},
	{
		garage_id: 68,
		part_id: 285,
		quantity: 407,
	},
	{
		garage_id: 68,
		part_id: 286,
		quantity: 77,
	},
	{
		garage_id: 68,
		part_id: 287,
		quantity: 145,
	},
	{
		garage_id: 68,
		part_id: 288,
		quantity: 22,
	},
	{
		garage_id: 68,
		part_id: 289,
		quantity: 321,
	},
	{
		garage_id: 68,
		part_id: 290,
		quantity: 205,
	},
	{
		garage_id: 68,
		part_id: 291,
		quantity: 133,
	},
	{
		garage_id: 68,
		part_id: 292,
		quantity: 291,
	},
	{
		garage_id: 68,
		part_id: 293,
		quantity: 388,
	},
	{
		garage_id: 68,
		part_id: 294,
		quantity: 449,
	},
	{
		garage_id: 68,
		part_id: 295,
		quantity: 233,
	},
	{
		garage_id: 68,
		part_id: 296,
		quantity: 206,
	},
	{
		garage_id: 68,
		part_id: 297,
		quantity: 461,
	},
	{
		garage_id: 68,
		part_id: 298,
		quantity: 476,
	},
	{
		garage_id: 68,
		part_id: 299,
		quantity: 135,
	},
	{
		garage_id: 68,
		part_id: 300,
		quantity: 399,
	},
	{
		garage_id: 68,
		part_id: 301,
		quantity: 240,
	},
	{
		garage_id: 68,
		part_id: 302,
		quantity: 244,
	},
	{
		garage_id: 68,
		part_id: 303,
		quantity: 116,
	},
	{
		garage_id: 68,
		part_id: 304,
		quantity: 326,
	},
	{
		garage_id: 68,
		part_id: 305,
		quantity: 60,
	},
	{
		garage_id: 68,
		part_id: 306,
		quantity: 415,
	},
	{
		garage_id: 68,
		part_id: 307,
		quantity: 60,
	},
	{
		garage_id: 68,
		part_id: 308,
		quantity: 231,
	},
	{
		garage_id: 68,
		part_id: 309,
		quantity: 403,
	},
	{
		garage_id: 68,
		part_id: 310,
		quantity: 438,
	},
	{
		garage_id: 68,
		part_id: 311,
		quantity: 46,
	},
	{
		garage_id: 68,
		part_id: 312,
		quantity: 456,
	},
	{
		garage_id: 68,
		part_id: 313,
		quantity: 87,
	},
	{
		garage_id: 68,
		part_id: 314,
		quantity: 173,
	},
	{
		garage_id: 68,
		part_id: 315,
		quantity: 43,
	},
	{
		garage_id: 68,
		part_id: 316,
		quantity: 461,
	},
	{
		garage_id: 68,
		part_id: 317,
		quantity: 330,
	},
	{
		garage_id: 68,
		part_id: 318,
		quantity: 267,
	},
	{
		garage_id: 68,
		part_id: 319,
		quantity: 57,
	},
	{
		garage_id: 68,
		part_id: 320,
		quantity: 341,
	},
	{
		garage_id: 68,
		part_id: 321,
		quantity: 55,
	},
	{
		garage_id: 68,
		part_id: 322,
		quantity: 304,
	},
	{
		garage_id: 68,
		part_id: 323,
		quantity: 302,
	},
	{
		garage_id: 68,
		part_id: 324,
		quantity: 286,
	},
	{
		garage_id: 68,
		part_id: 325,
		quantity: 260,
	},
	{
		garage_id: 68,
		part_id: 326,
		quantity: 432,
	},
	{
		garage_id: 68,
		part_id: 327,
		quantity: 317,
	},
	{
		garage_id: 68,
		part_id: 328,
		quantity: 229,
	},
	{
		garage_id: 68,
		part_id: 329,
		quantity: 382,
	},
	{
		garage_id: 68,
		part_id: 330,
		quantity: 220,
	},
	{
		garage_id: 68,
		part_id: 331,
		quantity: 488,
	},
	{
		garage_id: 68,
		part_id: 332,
		quantity: 276,
	},
	{
		garage_id: 68,
		part_id: 333,
		quantity: 309,
	},
	{
		garage_id: 68,
		part_id: 334,
		quantity: 331,
	},
	{
		garage_id: 68,
		part_id: 335,
		quantity: 346,
	},
	{
		garage_id: 68,
		part_id: 336,
		quantity: 404,
	},
	{
		garage_id: 68,
		part_id: 337,
		quantity: 302,
	},
	{
		garage_id: 68,
		part_id: 338,
		quantity: 55,
	},
	{
		garage_id: 68,
		part_id: 339,
		quantity: 278,
	},
	{
		garage_id: 68,
		part_id: 340,
		quantity: 199,
	},
	{
		garage_id: 68,
		part_id: 341,
		quantity: 69,
	},
	{
		garage_id: 68,
		part_id: 342,
		quantity: 371,
	},
	{
		garage_id: 68,
		part_id: 343,
		quantity: 153,
	},
	{
		garage_id: 68,
		part_id: 344,
		quantity: 120,
	},
	{
		garage_id: 68,
		part_id: 345,
		quantity: 46,
	},
	{
		garage_id: 68,
		part_id: 346,
		quantity: 96,
	},
	{
		garage_id: 68,
		part_id: 347,
		quantity: 260,
	},
	{
		garage_id: 68,
		part_id: 348,
		quantity: 332,
	},
	{
		garage_id: 68,
		part_id: 349,
		quantity: 231,
	},
	{
		garage_id: 68,
		part_id: 350,
		quantity: 445,
	},
	{
		garage_id: 68,
		part_id: 351,
		quantity: 87,
	},
	{
		garage_id: 68,
		part_id: 352,
		quantity: 48,
	},
	{
		garage_id: 68,
		part_id: 353,
		quantity: 401,
	},
	{
		garage_id: 68,
		part_id: 354,
		quantity: 195,
	},
	{
		garage_id: 68,
		part_id: 355,
		quantity: 242,
	},
	{
		garage_id: 68,
		part_id: 356,
		quantity: 234,
	},
	{
		garage_id: 68,
		part_id: 357,
		quantity: 60,
	},
	{
		garage_id: 68,
		part_id: 358,
		quantity: 165,
	},
	{
		garage_id: 68,
		part_id: 359,
		quantity: 359,
	},
	{
		garage_id: 68,
		part_id: 360,
		quantity: 25,
	},
	{
		garage_id: 68,
		part_id: 361,
		quantity: 357,
	},
	{
		garage_id: 68,
		part_id: 362,
		quantity: 0,
	},
	{
		garage_id: 68,
		part_id: 363,
		quantity: 404,
	},
	{
		garage_id: 68,
		part_id: 364,
		quantity: 304,
	},
	{
		garage_id: 68,
		part_id: 365,
		quantity: 79,
	},
	{
		garage_id: 68,
		part_id: 366,
		quantity: 346,
	},
	{
		garage_id: 68,
		part_id: 367,
		quantity: 45,
	},
	{
		garage_id: 68,
		part_id: 368,
		quantity: 33,
	},
	{
		garage_id: 68,
		part_id: 369,
		quantity: 7,
	},
	{
		garage_id: 68,
		part_id: 370,
		quantity: 494,
	},
	{
		garage_id: 68,
		part_id: 371,
		quantity: 37,
	},
	{
		garage_id: 68,
		part_id: 372,
		quantity: 483,
	},
	{
		garage_id: 68,
		part_id: 373,
		quantity: 294,
	},
	{
		garage_id: 68,
		part_id: 374,
		quantity: 102,
	},
	{
		garage_id: 68,
		part_id: 375,
		quantity: 336,
	},
	{
		garage_id: 68,
		part_id: 376,
		quantity: 285,
	},
	{
		garage_id: 68,
		part_id: 377,
		quantity: 106,
	},
	{
		garage_id: 68,
		part_id: 378,
		quantity: 313,
	},
	{
		garage_id: 68,
		part_id: 379,
		quantity: 143,
	},
	{
		garage_id: 68,
		part_id: 380,
		quantity: 331,
	},
	{
		garage_id: 68,
		part_id: 381,
		quantity: 131,
	},
	{
		garage_id: 68,
		part_id: 382,
		quantity: 95,
	},
	{
		garage_id: 68,
		part_id: 383,
		quantity: 462,
	},
	{
		garage_id: 68,
		part_id: 384,
		quantity: 102,
	},
	{
		garage_id: 68,
		part_id: 385,
		quantity: 347,
	},
	{
		garage_id: 68,
		part_id: 386,
		quantity: 269,
	},
	{
		garage_id: 68,
		part_id: 387,
		quantity: 485,
	},
	{
		garage_id: 68,
		part_id: 388,
		quantity: 402,
	},
	{
		garage_id: 68,
		part_id: 389,
		quantity: 447,
	},
	{
		garage_id: 68,
		part_id: 390,
		quantity: 316,
	},
	{
		garage_id: 68,
		part_id: 391,
		quantity: 210,
	},
	{
		garage_id: 68,
		part_id: 392,
		quantity: 481,
	},
	{
		garage_id: 68,
		part_id: 393,
		quantity: 385,
	},
	{
		garage_id: 68,
		part_id: 394,
		quantity: 451,
	},
	{
		garage_id: 68,
		part_id: 395,
		quantity: 241,
	},
	{
		garage_id: 68,
		part_id: 396,
		quantity: 54,
	},
	{
		garage_id: 68,
		part_id: 397,
		quantity: 419,
	},
	{
		garage_id: 68,
		part_id: 398,
		quantity: 131,
	},
	{
		garage_id: 68,
		part_id: 399,
		quantity: 73,
	},
	{
		garage_id: 69,
		part_id: 0,
		quantity: 476,
	},
	{
		garage_id: 69,
		part_id: 1,
		quantity: 465,
	},
	{
		garage_id: 69,
		part_id: 2,
		quantity: 405,
	},
	{
		garage_id: 69,
		part_id: 3,
		quantity: 170,
	},
	{
		garage_id: 69,
		part_id: 4,
		quantity: 156,
	},
	{
		garage_id: 69,
		part_id: 5,
		quantity: 465,
	},
	{
		garage_id: 69,
		part_id: 6,
		quantity: 254,
	},
	{
		garage_id: 69,
		part_id: 7,
		quantity: 26,
	},
	{
		garage_id: 69,
		part_id: 8,
		quantity: 333,
	},
	{
		garage_id: 69,
		part_id: 9,
		quantity: 479,
	},
	{
		garage_id: 69,
		part_id: 10,
		quantity: 307,
	},
	{
		garage_id: 69,
		part_id: 11,
		quantity: 193,
	},
	{
		garage_id: 69,
		part_id: 12,
		quantity: 476,
	},
	{
		garage_id: 69,
		part_id: 13,
		quantity: 237,
	},
	{
		garage_id: 69,
		part_id: 14,
		quantity: 196,
	},
	{
		garage_id: 69,
		part_id: 15,
		quantity: 30,
	},
	{
		garage_id: 69,
		part_id: 16,
		quantity: 445,
	},
	{
		garage_id: 69,
		part_id: 17,
		quantity: 471,
	},
	{
		garage_id: 69,
		part_id: 18,
		quantity: 287,
	},
	{
		garage_id: 69,
		part_id: 19,
		quantity: 270,
	},
	{
		garage_id: 69,
		part_id: 20,
		quantity: 198,
	},
	{
		garage_id: 69,
		part_id: 21,
		quantity: 275,
	},
	{
		garage_id: 69,
		part_id: 22,
		quantity: 13,
	},
	{
		garage_id: 69,
		part_id: 23,
		quantity: 52,
	},
	{
		garage_id: 69,
		part_id: 24,
		quantity: 449,
	},
	{
		garage_id: 69,
		part_id: 25,
		quantity: 324,
	},
	{
		garage_id: 69,
		part_id: 26,
		quantity: 33,
	},
	{
		garage_id: 69,
		part_id: 27,
		quantity: 425,
	},
	{
		garage_id: 69,
		part_id: 28,
		quantity: 396,
	},
	{
		garage_id: 69,
		part_id: 29,
		quantity: 498,
	},
	{
		garage_id: 69,
		part_id: 30,
		quantity: 11,
	},
	{
		garage_id: 69,
		part_id: 31,
		quantity: 228,
	},
	{
		garage_id: 69,
		part_id: 32,
		quantity: 123,
	},
	{
		garage_id: 69,
		part_id: 33,
		quantity: 239,
	},
	{
		garage_id: 69,
		part_id: 34,
		quantity: 380,
	},
	{
		garage_id: 69,
		part_id: 35,
		quantity: 297,
	},
	{
		garage_id: 69,
		part_id: 36,
		quantity: 314,
	},
	{
		garage_id: 69,
		part_id: 37,
		quantity: 203,
	},
	{
		garage_id: 69,
		part_id: 38,
		quantity: 138,
	},
	{
		garage_id: 69,
		part_id: 39,
		quantity: 300,
	},
	{
		garage_id: 69,
		part_id: 40,
		quantity: 419,
	},
	{
		garage_id: 69,
		part_id: 41,
		quantity: 35,
	},
	{
		garage_id: 69,
		part_id: 42,
		quantity: 80,
	},
	{
		garage_id: 69,
		part_id: 43,
		quantity: 128,
	},
	{
		garage_id: 69,
		part_id: 44,
		quantity: 169,
	},
	{
		garage_id: 69,
		part_id: 45,
		quantity: 491,
	},
	{
		garage_id: 69,
		part_id: 46,
		quantity: 381,
	},
	{
		garage_id: 69,
		part_id: 47,
		quantity: 317,
	},
	{
		garage_id: 69,
		part_id: 48,
		quantity: 207,
	},
	{
		garage_id: 69,
		part_id: 49,
		quantity: 353,
	},
	{
		garage_id: 69,
		part_id: 50,
		quantity: 286,
	},
	{
		garage_id: 69,
		part_id: 51,
		quantity: 319,
	},
	{
		garage_id: 69,
		part_id: 52,
		quantity: 426,
	},
	{
		garage_id: 69,
		part_id: 53,
		quantity: 441,
	},
	{
		garage_id: 69,
		part_id: 54,
		quantity: 285,
	},
	{
		garage_id: 69,
		part_id: 55,
		quantity: 87,
	},
	{
		garage_id: 69,
		part_id: 56,
		quantity: 393,
	},
	{
		garage_id: 69,
		part_id: 57,
		quantity: 270,
	},
	{
		garage_id: 69,
		part_id: 58,
		quantity: 215,
	},
	{
		garage_id: 69,
		part_id: 59,
		quantity: 83,
	},
	{
		garage_id: 69,
		part_id: 60,
		quantity: 273,
	},
	{
		garage_id: 69,
		part_id: 61,
		quantity: 236,
	},
	{
		garage_id: 69,
		part_id: 62,
		quantity: 443,
	},
	{
		garage_id: 69,
		part_id: 63,
		quantity: 302,
	},
	{
		garage_id: 69,
		part_id: 64,
		quantity: 191,
	},
	{
		garage_id: 69,
		part_id: 65,
		quantity: 203,
	},
	{
		garage_id: 69,
		part_id: 66,
		quantity: 482,
	},
	{
		garage_id: 69,
		part_id: 67,
		quantity: 120,
	},
	{
		garage_id: 69,
		part_id: 68,
		quantity: 394,
	},
	{
		garage_id: 69,
		part_id: 69,
		quantity: 500,
	},
	{
		garage_id: 69,
		part_id: 70,
		quantity: 67,
	},
	{
		garage_id: 69,
		part_id: 71,
		quantity: 319,
	},
	{
		garage_id: 69,
		part_id: 72,
		quantity: 124,
	},
	{
		garage_id: 69,
		part_id: 73,
		quantity: 291,
	},
	{
		garage_id: 69,
		part_id: 74,
		quantity: 293,
	},
	{
		garage_id: 69,
		part_id: 75,
		quantity: 5,
	},
	{
		garage_id: 69,
		part_id: 76,
		quantity: 175,
	},
	{
		garage_id: 69,
		part_id: 77,
		quantity: 303,
	},
	{
		garage_id: 69,
		part_id: 78,
		quantity: 439,
	},
	{
		garage_id: 69,
		part_id: 79,
		quantity: 270,
	},
	{
		garage_id: 69,
		part_id: 80,
		quantity: 34,
	},
	{
		garage_id: 69,
		part_id: 81,
		quantity: 68,
	},
	{
		garage_id: 69,
		part_id: 82,
		quantity: 36,
	},
	{
		garage_id: 69,
		part_id: 83,
		quantity: 338,
	},
	{
		garage_id: 69,
		part_id: 84,
		quantity: 57,
	},
	{
		garage_id: 69,
		part_id: 85,
		quantity: 149,
	},
	{
		garage_id: 69,
		part_id: 86,
		quantity: 304,
	},
	{
		garage_id: 69,
		part_id: 87,
		quantity: 422,
	},
	{
		garage_id: 69,
		part_id: 88,
		quantity: 66,
	},
	{
		garage_id: 69,
		part_id: 89,
		quantity: 372,
	},
	{
		garage_id: 69,
		part_id: 90,
		quantity: 409,
	},
	{
		garage_id: 69,
		part_id: 91,
		quantity: 93,
	},
	{
		garage_id: 69,
		part_id: 92,
		quantity: 141,
	},
	{
		garage_id: 69,
		part_id: 93,
		quantity: 78,
	},
	{
		garage_id: 69,
		part_id: 94,
		quantity: 316,
	},
	{
		garage_id: 69,
		part_id: 95,
		quantity: 368,
	},
	{
		garage_id: 69,
		part_id: 96,
		quantity: 317,
	},
	{
		garage_id: 69,
		part_id: 97,
		quantity: 265,
	},
	{
		garage_id: 69,
		part_id: 98,
		quantity: 46,
	},
	{
		garage_id: 69,
		part_id: 99,
		quantity: 492,
	},
	{
		garage_id: 69,
		part_id: 100,
		quantity: 500,
	},
	{
		garage_id: 69,
		part_id: 101,
		quantity: 415,
	},
	{
		garage_id: 69,
		part_id: 102,
		quantity: 433,
	},
	{
		garage_id: 69,
		part_id: 103,
		quantity: 43,
	},
	{
		garage_id: 69,
		part_id: 104,
		quantity: 475,
	},
	{
		garage_id: 69,
		part_id: 105,
		quantity: 288,
	},
	{
		garage_id: 69,
		part_id: 106,
		quantity: 321,
	},
	{
		garage_id: 69,
		part_id: 107,
		quantity: 138,
	},
	{
		garage_id: 69,
		part_id: 108,
		quantity: 149,
	},
	{
		garage_id: 69,
		part_id: 109,
		quantity: 43,
	},
	{
		garage_id: 69,
		part_id: 110,
		quantity: 86,
	},
	{
		garage_id: 69,
		part_id: 111,
		quantity: 344,
	},
	{
		garage_id: 69,
		part_id: 112,
		quantity: 428,
	},
	{
		garage_id: 69,
		part_id: 113,
		quantity: 491,
	},
	{
		garage_id: 69,
		part_id: 114,
		quantity: 262,
	},
	{
		garage_id: 69,
		part_id: 115,
		quantity: 379,
	},
	{
		garage_id: 69,
		part_id: 116,
		quantity: 148,
	},
	{
		garage_id: 69,
		part_id: 117,
		quantity: 236,
	},
	{
		garage_id: 69,
		part_id: 118,
		quantity: 85,
	},
	{
		garage_id: 69,
		part_id: 119,
		quantity: 0,
	},
	{
		garage_id: 69,
		part_id: 120,
		quantity: 216,
	},
	{
		garage_id: 69,
		part_id: 121,
		quantity: 74,
	},
	{
		garage_id: 69,
		part_id: 122,
		quantity: 8,
	},
	{
		garage_id: 69,
		part_id: 123,
		quantity: 144,
	},
	{
		garage_id: 69,
		part_id: 124,
		quantity: 338,
	},
	{
		garage_id: 69,
		part_id: 125,
		quantity: 84,
	},
	{
		garage_id: 69,
		part_id: 126,
		quantity: 476,
	},
	{
		garage_id: 69,
		part_id: 127,
		quantity: 181,
	},
	{
		garage_id: 69,
		part_id: 128,
		quantity: 44,
	},
	{
		garage_id: 69,
		part_id: 129,
		quantity: 369,
	},
	{
		garage_id: 69,
		part_id: 130,
		quantity: 246,
	},
	{
		garage_id: 69,
		part_id: 131,
		quantity: 148,
	},
	{
		garage_id: 69,
		part_id: 132,
		quantity: 136,
	},
	{
		garage_id: 69,
		part_id: 133,
		quantity: 137,
	},
	{
		garage_id: 69,
		part_id: 134,
		quantity: 498,
	},
	{
		garage_id: 69,
		part_id: 135,
		quantity: 357,
	},
	{
		garage_id: 69,
		part_id: 136,
		quantity: 194,
	},
	{
		garage_id: 69,
		part_id: 137,
		quantity: 427,
	},
	{
		garage_id: 69,
		part_id: 138,
		quantity: 123,
	},
	{
		garage_id: 69,
		part_id: 139,
		quantity: 216,
	},
	{
		garage_id: 69,
		part_id: 140,
		quantity: 266,
	},
	{
		garage_id: 69,
		part_id: 141,
		quantity: 327,
	},
	{
		garage_id: 69,
		part_id: 142,
		quantity: 300,
	},
	{
		garage_id: 69,
		part_id: 143,
		quantity: 444,
	},
	{
		garage_id: 69,
		part_id: 144,
		quantity: 90,
	},
	{
		garage_id: 69,
		part_id: 145,
		quantity: 135,
	},
	{
		garage_id: 69,
		part_id: 146,
		quantity: 378,
	},
	{
		garage_id: 69,
		part_id: 147,
		quantity: 158,
	},
	{
		garage_id: 69,
		part_id: 148,
		quantity: 168,
	},
	{
		garage_id: 69,
		part_id: 149,
		quantity: 80,
	},
	{
		garage_id: 69,
		part_id: 150,
		quantity: 208,
	},
	{
		garage_id: 69,
		part_id: 151,
		quantity: 255,
	},
	{
		garage_id: 69,
		part_id: 152,
		quantity: 259,
	},
	{
		garage_id: 69,
		part_id: 153,
		quantity: 416,
	},
	{
		garage_id: 69,
		part_id: 154,
		quantity: 286,
	},
	{
		garage_id: 69,
		part_id: 155,
		quantity: 494,
	},
	{
		garage_id: 69,
		part_id: 156,
		quantity: 292,
	},
	{
		garage_id: 69,
		part_id: 157,
		quantity: 177,
	},
	{
		garage_id: 69,
		part_id: 158,
		quantity: 173,
	},
	{
		garage_id: 69,
		part_id: 159,
		quantity: 115,
	},
	{
		garage_id: 69,
		part_id: 160,
		quantity: 354,
	},
	{
		garage_id: 69,
		part_id: 161,
		quantity: 420,
	},
	{
		garage_id: 69,
		part_id: 162,
		quantity: 267,
	},
	{
		garage_id: 69,
		part_id: 163,
		quantity: 112,
	},
	{
		garage_id: 69,
		part_id: 164,
		quantity: 118,
	},
	{
		garage_id: 69,
		part_id: 165,
		quantity: 156,
	},
	{
		garage_id: 69,
		part_id: 166,
		quantity: 92,
	},
	{
		garage_id: 69,
		part_id: 167,
		quantity: 83,
	},
	{
		garage_id: 69,
		part_id: 168,
		quantity: 173,
	},
	{
		garage_id: 69,
		part_id: 169,
		quantity: 120,
	},
	{
		garage_id: 69,
		part_id: 170,
		quantity: 90,
	},
	{
		garage_id: 69,
		part_id: 171,
		quantity: 31,
	},
	{
		garage_id: 69,
		part_id: 172,
		quantity: 443,
	},
	{
		garage_id: 69,
		part_id: 173,
		quantity: 427,
	},
	{
		garage_id: 69,
		part_id: 174,
		quantity: 442,
	},
	{
		garage_id: 69,
		part_id: 175,
		quantity: 136,
	},
	{
		garage_id: 69,
		part_id: 176,
		quantity: 34,
	},
	{
		garage_id: 69,
		part_id: 177,
		quantity: 249,
	},
	{
		garage_id: 69,
		part_id: 178,
		quantity: 272,
	},
	{
		garage_id: 69,
		part_id: 179,
		quantity: 260,
	},
	{
		garage_id: 69,
		part_id: 180,
		quantity: 45,
	},
	{
		garage_id: 69,
		part_id: 181,
		quantity: 5,
	},
	{
		garage_id: 69,
		part_id: 182,
		quantity: 185,
	},
	{
		garage_id: 69,
		part_id: 183,
		quantity: 371,
	},
	{
		garage_id: 69,
		part_id: 184,
		quantity: 93,
	},
	{
		garage_id: 69,
		part_id: 185,
		quantity: 154,
	},
	{
		garage_id: 69,
		part_id: 186,
		quantity: 295,
	},
	{
		garage_id: 69,
		part_id: 187,
		quantity: 1,
	},
	{
		garage_id: 69,
		part_id: 188,
		quantity: 137,
	},
	{
		garage_id: 69,
		part_id: 189,
		quantity: 108,
	},
	{
		garage_id: 69,
		part_id: 190,
		quantity: 482,
	},
	{
		garage_id: 69,
		part_id: 191,
		quantity: 468,
	},
	{
		garage_id: 69,
		part_id: 192,
		quantity: 338,
	},
	{
		garage_id: 69,
		part_id: 193,
		quantity: 296,
	},
	{
		garage_id: 69,
		part_id: 194,
		quantity: 148,
	},
	{
		garage_id: 69,
		part_id: 195,
		quantity: 246,
	},
	{
		garage_id: 69,
		part_id: 196,
		quantity: 307,
	},
	{
		garage_id: 69,
		part_id: 197,
		quantity: 289,
	},
	{
		garage_id: 69,
		part_id: 198,
		quantity: 481,
	},
	{
		garage_id: 69,
		part_id: 199,
		quantity: 476,
	},
	{
		garage_id: 69,
		part_id: 200,
		quantity: 275,
	},
	{
		garage_id: 69,
		part_id: 201,
		quantity: 87,
	},
	{
		garage_id: 69,
		part_id: 202,
		quantity: 481,
	},
	{
		garage_id: 69,
		part_id: 203,
		quantity: 282,
	},
	{
		garage_id: 69,
		part_id: 204,
		quantity: 285,
	},
	{
		garage_id: 69,
		part_id: 205,
		quantity: 195,
	},
	{
		garage_id: 69,
		part_id: 206,
		quantity: 482,
	},
	{
		garage_id: 69,
		part_id: 207,
		quantity: 273,
	},
	{
		garage_id: 69,
		part_id: 208,
		quantity: 329,
	},
	{
		garage_id: 69,
		part_id: 209,
		quantity: 406,
	},
	{
		garage_id: 69,
		part_id: 210,
		quantity: 157,
	},
	{
		garage_id: 69,
		part_id: 211,
		quantity: 179,
	},
	{
		garage_id: 69,
		part_id: 212,
		quantity: 251,
	},
	{
		garage_id: 69,
		part_id: 213,
		quantity: 256,
	},
	{
		garage_id: 69,
		part_id: 214,
		quantity: 367,
	},
	{
		garage_id: 69,
		part_id: 215,
		quantity: 240,
	},
	{
		garage_id: 69,
		part_id: 216,
		quantity: 399,
	},
	{
		garage_id: 69,
		part_id: 217,
		quantity: 136,
	},
	{
		garage_id: 69,
		part_id: 218,
		quantity: 88,
	},
	{
		garage_id: 69,
		part_id: 219,
		quantity: 392,
	},
	{
		garage_id: 69,
		part_id: 220,
		quantity: 321,
	},
	{
		garage_id: 69,
		part_id: 221,
		quantity: 431,
	},
	{
		garage_id: 69,
		part_id: 222,
		quantity: 493,
	},
	{
		garage_id: 69,
		part_id: 223,
		quantity: 385,
	},
	{
		garage_id: 69,
		part_id: 224,
		quantity: 300,
	},
	{
		garage_id: 69,
		part_id: 225,
		quantity: 374,
	},
	{
		garage_id: 69,
		part_id: 226,
		quantity: 279,
	},
	{
		garage_id: 69,
		part_id: 227,
		quantity: 11,
	},
	{
		garage_id: 69,
		part_id: 228,
		quantity: 56,
	},
	{
		garage_id: 69,
		part_id: 229,
		quantity: 351,
	},
	{
		garage_id: 69,
		part_id: 230,
		quantity: 131,
	},
	{
		garage_id: 69,
		part_id: 231,
		quantity: 442,
	},
	{
		garage_id: 69,
		part_id: 232,
		quantity: 499,
	},
	{
		garage_id: 69,
		part_id: 233,
		quantity: 280,
	},
	{
		garage_id: 69,
		part_id: 234,
		quantity: 392,
	},
	{
		garage_id: 69,
		part_id: 235,
		quantity: 49,
	},
	{
		garage_id: 69,
		part_id: 236,
		quantity: 102,
	},
	{
		garage_id: 69,
		part_id: 237,
		quantity: 243,
	},
	{
		garage_id: 69,
		part_id: 238,
		quantity: 77,
	},
	{
		garage_id: 69,
		part_id: 239,
		quantity: 316,
	},
	{
		garage_id: 69,
		part_id: 240,
		quantity: 104,
	},
	{
		garage_id: 69,
		part_id: 241,
		quantity: 355,
	},
	{
		garage_id: 69,
		part_id: 242,
		quantity: 78,
	},
	{
		garage_id: 69,
		part_id: 243,
		quantity: 289,
	},
	{
		garage_id: 69,
		part_id: 244,
		quantity: 23,
	},
	{
		garage_id: 69,
		part_id: 245,
		quantity: 20,
	},
	{
		garage_id: 69,
		part_id: 246,
		quantity: 452,
	},
	{
		garage_id: 69,
		part_id: 247,
		quantity: 332,
	},
	{
		garage_id: 69,
		part_id: 248,
		quantity: 245,
	},
	{
		garage_id: 69,
		part_id: 249,
		quantity: 71,
	},
	{
		garage_id: 69,
		part_id: 250,
		quantity: 53,
	},
	{
		garage_id: 69,
		part_id: 251,
		quantity: 104,
	},
	{
		garage_id: 69,
		part_id: 252,
		quantity: 224,
	},
	{
		garage_id: 69,
		part_id: 253,
		quantity: 260,
	},
	{
		garage_id: 69,
		part_id: 254,
		quantity: 490,
	},
	{
		garage_id: 69,
		part_id: 255,
		quantity: 393,
	},
	{
		garage_id: 69,
		part_id: 256,
		quantity: 253,
	},
	{
		garage_id: 69,
		part_id: 257,
		quantity: 16,
	},
	{
		garage_id: 69,
		part_id: 258,
		quantity: 342,
	},
	{
		garage_id: 69,
		part_id: 259,
		quantity: 425,
	},
	{
		garage_id: 69,
		part_id: 260,
		quantity: 98,
	},
	{
		garage_id: 69,
		part_id: 261,
		quantity: 62,
	},
	{
		garage_id: 69,
		part_id: 262,
		quantity: 101,
	},
	{
		garage_id: 69,
		part_id: 263,
		quantity: 311,
	},
	{
		garage_id: 69,
		part_id: 264,
		quantity: 377,
	},
	{
		garage_id: 69,
		part_id: 265,
		quantity: 337,
	},
	{
		garage_id: 69,
		part_id: 266,
		quantity: 256,
	},
	{
		garage_id: 69,
		part_id: 267,
		quantity: 401,
	},
	{
		garage_id: 69,
		part_id: 268,
		quantity: 61,
	},
	{
		garage_id: 69,
		part_id: 269,
		quantity: 204,
	},
	{
		garage_id: 69,
		part_id: 270,
		quantity: 424,
	},
	{
		garage_id: 69,
		part_id: 271,
		quantity: 255,
	},
	{
		garage_id: 69,
		part_id: 272,
		quantity: 115,
	},
	{
		garage_id: 69,
		part_id: 273,
		quantity: 459,
	},
	{
		garage_id: 69,
		part_id: 274,
		quantity: 132,
	},
	{
		garage_id: 69,
		part_id: 275,
		quantity: 125,
	},
	{
		garage_id: 69,
		part_id: 276,
		quantity: 449,
	},
	{
		garage_id: 69,
		part_id: 277,
		quantity: 391,
	},
	{
		garage_id: 69,
		part_id: 278,
		quantity: 178,
	},
	{
		garage_id: 69,
		part_id: 279,
		quantity: 360,
	},
	{
		garage_id: 69,
		part_id: 280,
		quantity: 326,
	},
	{
		garage_id: 69,
		part_id: 281,
		quantity: 397,
	},
	{
		garage_id: 69,
		part_id: 282,
		quantity: 378,
	},
	{
		garage_id: 69,
		part_id: 283,
		quantity: 413,
	},
	{
		garage_id: 69,
		part_id: 284,
		quantity: 43,
	},
	{
		garage_id: 69,
		part_id: 285,
		quantity: 285,
	},
	{
		garage_id: 69,
		part_id: 286,
		quantity: 445,
	},
	{
		garage_id: 69,
		part_id: 287,
		quantity: 23,
	},
	{
		garage_id: 69,
		part_id: 288,
		quantity: 377,
	},
	{
		garage_id: 69,
		part_id: 289,
		quantity: 460,
	},
	{
		garage_id: 69,
		part_id: 290,
		quantity: 151,
	},
	{
		garage_id: 69,
		part_id: 291,
		quantity: 392,
	},
	{
		garage_id: 69,
		part_id: 292,
		quantity: 471,
	},
	{
		garage_id: 69,
		part_id: 293,
		quantity: 359,
	},
	{
		garage_id: 69,
		part_id: 294,
		quantity: 139,
	},
	{
		garage_id: 69,
		part_id: 295,
		quantity: 264,
	},
	{
		garage_id: 69,
		part_id: 296,
		quantity: 255,
	},
	{
		garage_id: 69,
		part_id: 297,
		quantity: 427,
	},
	{
		garage_id: 69,
		part_id: 298,
		quantity: 135,
	},
	{
		garage_id: 69,
		part_id: 299,
		quantity: 63,
	},
	{
		garage_id: 69,
		part_id: 300,
		quantity: 395,
	},
	{
		garage_id: 69,
		part_id: 301,
		quantity: 269,
	},
	{
		garage_id: 69,
		part_id: 302,
		quantity: 399,
	},
	{
		garage_id: 69,
		part_id: 303,
		quantity: 500,
	},
	{
		garage_id: 69,
		part_id: 304,
		quantity: 223,
	},
	{
		garage_id: 69,
		part_id: 305,
		quantity: 348,
	},
	{
		garage_id: 69,
		part_id: 306,
		quantity: 161,
	},
	{
		garage_id: 69,
		part_id: 307,
		quantity: 434,
	},
	{
		garage_id: 69,
		part_id: 308,
		quantity: 83,
	},
	{
		garage_id: 69,
		part_id: 309,
		quantity: 17,
	},
	{
		garage_id: 69,
		part_id: 310,
		quantity: 259,
	},
	{
		garage_id: 69,
		part_id: 311,
		quantity: 341,
	},
	{
		garage_id: 69,
		part_id: 312,
		quantity: 105,
	},
	{
		garage_id: 69,
		part_id: 313,
		quantity: 172,
	},
	{
		garage_id: 69,
		part_id: 314,
		quantity: 282,
	},
	{
		garage_id: 69,
		part_id: 315,
		quantity: 381,
	},
	{
		garage_id: 69,
		part_id: 316,
		quantity: 42,
	},
	{
		garage_id: 69,
		part_id: 317,
		quantity: 339,
	},
	{
		garage_id: 69,
		part_id: 318,
		quantity: 314,
	},
	{
		garage_id: 69,
		part_id: 319,
		quantity: 439,
	},
	{
		garage_id: 69,
		part_id: 320,
		quantity: 95,
	},
	{
		garage_id: 69,
		part_id: 321,
		quantity: 67,
	},
	{
		garage_id: 69,
		part_id: 322,
		quantity: 95,
	},
	{
		garage_id: 69,
		part_id: 323,
		quantity: 203,
	},
	{
		garage_id: 69,
		part_id: 324,
		quantity: 472,
	},
	{
		garage_id: 69,
		part_id: 325,
		quantity: 345,
	},
	{
		garage_id: 69,
		part_id: 326,
		quantity: 284,
	},
	{
		garage_id: 69,
		part_id: 327,
		quantity: 264,
	},
	{
		garage_id: 69,
		part_id: 328,
		quantity: 13,
	},
	{
		garage_id: 69,
		part_id: 329,
		quantity: 266,
	},
	{
		garage_id: 69,
		part_id: 330,
		quantity: 465,
	},
	{
		garage_id: 69,
		part_id: 331,
		quantity: 466,
	},
	{
		garage_id: 69,
		part_id: 332,
		quantity: 466,
	},
	{
		garage_id: 69,
		part_id: 333,
		quantity: 134,
	},
	{
		garage_id: 69,
		part_id: 334,
		quantity: 275,
	},
	{
		garage_id: 69,
		part_id: 335,
		quantity: 141,
	},
	{
		garage_id: 69,
		part_id: 336,
		quantity: 0,
	},
	{
		garage_id: 69,
		part_id: 337,
		quantity: 423,
	},
	{
		garage_id: 69,
		part_id: 338,
		quantity: 173,
	},
	{
		garage_id: 69,
		part_id: 339,
		quantity: 461,
	},
	{
		garage_id: 69,
		part_id: 340,
		quantity: 194,
	},
	{
		garage_id: 69,
		part_id: 341,
		quantity: 11,
	},
	{
		garage_id: 69,
		part_id: 342,
		quantity: 202,
	},
	{
		garage_id: 69,
		part_id: 343,
		quantity: 266,
	},
	{
		garage_id: 69,
		part_id: 344,
		quantity: 197,
	},
	{
		garage_id: 69,
		part_id: 345,
		quantity: 133,
	},
	{
		garage_id: 69,
		part_id: 346,
		quantity: 396,
	},
	{
		garage_id: 69,
		part_id: 347,
		quantity: 91,
	},
	{
		garage_id: 69,
		part_id: 348,
		quantity: 119,
	},
	{
		garage_id: 69,
		part_id: 349,
		quantity: 158,
	},
	{
		garage_id: 69,
		part_id: 350,
		quantity: 323,
	},
	{
		garage_id: 69,
		part_id: 351,
		quantity: 261,
	},
	{
		garage_id: 69,
		part_id: 352,
		quantity: 369,
	},
	{
		garage_id: 69,
		part_id: 353,
		quantity: 307,
	},
	{
		garage_id: 69,
		part_id: 354,
		quantity: 347,
	},
	{
		garage_id: 69,
		part_id: 355,
		quantity: 381,
	},
	{
		garage_id: 69,
		part_id: 356,
		quantity: 371,
	},
	{
		garage_id: 69,
		part_id: 357,
		quantity: 75,
	},
	{
		garage_id: 69,
		part_id: 358,
		quantity: 397,
	},
	{
		garage_id: 69,
		part_id: 359,
		quantity: 123,
	},
	{
		garage_id: 69,
		part_id: 360,
		quantity: 100,
	},
	{
		garage_id: 69,
		part_id: 361,
		quantity: 402,
	},
	{
		garage_id: 69,
		part_id: 362,
		quantity: 382,
	},
	{
		garage_id: 69,
		part_id: 363,
		quantity: 465,
	},
	{
		garage_id: 69,
		part_id: 364,
		quantity: 161,
	},
	{
		garage_id: 69,
		part_id: 365,
		quantity: 265,
	},
	{
		garage_id: 69,
		part_id: 366,
		quantity: 448,
	},
	{
		garage_id: 69,
		part_id: 367,
		quantity: 476,
	},
	{
		garage_id: 69,
		part_id: 368,
		quantity: 201,
	},
	{
		garage_id: 69,
		part_id: 369,
		quantity: 201,
	},
	{
		garage_id: 69,
		part_id: 370,
		quantity: 338,
	},
	{
		garage_id: 69,
		part_id: 371,
		quantity: 213,
	},
	{
		garage_id: 69,
		part_id: 372,
		quantity: 146,
	},
	{
		garage_id: 69,
		part_id: 373,
		quantity: 133,
	},
	{
		garage_id: 69,
		part_id: 374,
		quantity: 100,
	},
	{
		garage_id: 69,
		part_id: 375,
		quantity: 62,
	},
	{
		garage_id: 69,
		part_id: 376,
		quantity: 145,
	},
	{
		garage_id: 69,
		part_id: 377,
		quantity: 38,
	},
	{
		garage_id: 69,
		part_id: 378,
		quantity: 499,
	},
	{
		garage_id: 69,
		part_id: 379,
		quantity: 261,
	},
	{
		garage_id: 69,
		part_id: 380,
		quantity: 48,
	},
	{
		garage_id: 69,
		part_id: 381,
		quantity: 295,
	},
	{
		garage_id: 69,
		part_id: 382,
		quantity: 166,
	},
	{
		garage_id: 69,
		part_id: 383,
		quantity: 493,
	},
	{
		garage_id: 69,
		part_id: 384,
		quantity: 443,
	},
	{
		garage_id: 69,
		part_id: 385,
		quantity: 374,
	},
	{
		garage_id: 69,
		part_id: 386,
		quantity: 280,
	},
	{
		garage_id: 69,
		part_id: 387,
		quantity: 307,
	},
	{
		garage_id: 69,
		part_id: 388,
		quantity: 221,
	},
	{
		garage_id: 69,
		part_id: 389,
		quantity: 198,
	},
	{
		garage_id: 69,
		part_id: 390,
		quantity: 444,
	},
	{
		garage_id: 69,
		part_id: 391,
		quantity: 250,
	},
	{
		garage_id: 69,
		part_id: 392,
		quantity: 192,
	},
	{
		garage_id: 69,
		part_id: 393,
		quantity: 205,
	},
	{
		garage_id: 69,
		part_id: 394,
		quantity: 347,
	},
	{
		garage_id: 69,
		part_id: 395,
		quantity: 214,
	},
	{
		garage_id: 69,
		part_id: 396,
		quantity: 143,
	},
	{
		garage_id: 69,
		part_id: 397,
		quantity: 58,
	},
	{
		garage_id: 69,
		part_id: 398,
		quantity: 117,
	},
	{
		garage_id: 69,
		part_id: 399,
		quantity: 209,
	},
	{
		garage_id: 70,
		part_id: 0,
		quantity: 241,
	},
	{
		garage_id: 70,
		part_id: 1,
		quantity: 250,
	},
	{
		garage_id: 70,
		part_id: 2,
		quantity: 385,
	},
	{
		garage_id: 70,
		part_id: 3,
		quantity: 71,
	},
	{
		garage_id: 70,
		part_id: 4,
		quantity: 213,
	},
	{
		garage_id: 70,
		part_id: 5,
		quantity: 358,
	},
	{
		garage_id: 70,
		part_id: 6,
		quantity: 273,
	},
	{
		garage_id: 70,
		part_id: 7,
		quantity: 484,
	},
	{
		garage_id: 70,
		part_id: 8,
		quantity: 80,
	},
	{
		garage_id: 70,
		part_id: 9,
		quantity: 485,
	},
	{
		garage_id: 70,
		part_id: 10,
		quantity: 405,
	},
	{
		garage_id: 70,
		part_id: 11,
		quantity: 291,
	},
	{
		garage_id: 70,
		part_id: 12,
		quantity: 112,
	},
	{
		garage_id: 70,
		part_id: 13,
		quantity: 328,
	},
	{
		garage_id: 70,
		part_id: 14,
		quantity: 398,
	},
	{
		garage_id: 70,
		part_id: 15,
		quantity: 115,
	},
	{
		garage_id: 70,
		part_id: 16,
		quantity: 250,
	},
	{
		garage_id: 70,
		part_id: 17,
		quantity: 401,
	},
	{
		garage_id: 70,
		part_id: 18,
		quantity: 66,
	},
	{
		garage_id: 70,
		part_id: 19,
		quantity: 397,
	},
	{
		garage_id: 70,
		part_id: 20,
		quantity: 389,
	},
	{
		garage_id: 70,
		part_id: 21,
		quantity: 485,
	},
	{
		garage_id: 70,
		part_id: 22,
		quantity: 139,
	},
	{
		garage_id: 70,
		part_id: 23,
		quantity: 20,
	},
	{
		garage_id: 70,
		part_id: 24,
		quantity: 331,
	},
	{
		garage_id: 70,
		part_id: 25,
		quantity: 423,
	},
	{
		garage_id: 70,
		part_id: 26,
		quantity: 351,
	},
	{
		garage_id: 70,
		part_id: 27,
		quantity: 403,
	},
	{
		garage_id: 70,
		part_id: 28,
		quantity: 491,
	},
	{
		garage_id: 70,
		part_id: 29,
		quantity: 195,
	},
	{
		garage_id: 70,
		part_id: 30,
		quantity: 167,
	},
	{
		garage_id: 70,
		part_id: 31,
		quantity: 481,
	},
	{
		garage_id: 70,
		part_id: 32,
		quantity: 352,
	},
	{
		garage_id: 70,
		part_id: 33,
		quantity: 383,
	},
	{
		garage_id: 70,
		part_id: 34,
		quantity: 454,
	},
	{
		garage_id: 70,
		part_id: 35,
		quantity: 179,
	},
	{
		garage_id: 70,
		part_id: 36,
		quantity: 314,
	},
	{
		garage_id: 70,
		part_id: 37,
		quantity: 207,
	},
	{
		garage_id: 70,
		part_id: 38,
		quantity: 414,
	},
	{
		garage_id: 70,
		part_id: 39,
		quantity: 377,
	},
	{
		garage_id: 70,
		part_id: 40,
		quantity: 251,
	},
	{
		garage_id: 70,
		part_id: 41,
		quantity: 67,
	},
	{
		garage_id: 70,
		part_id: 42,
		quantity: 229,
	},
	{
		garage_id: 70,
		part_id: 43,
		quantity: 207,
	},
	{
		garage_id: 70,
		part_id: 44,
		quantity: 465,
	},
	{
		garage_id: 70,
		part_id: 45,
		quantity: 294,
	},
	{
		garage_id: 70,
		part_id: 46,
		quantity: 43,
	},
	{
		garage_id: 70,
		part_id: 47,
		quantity: 303,
	},
	{
		garage_id: 70,
		part_id: 48,
		quantity: 90,
	},
	{
		garage_id: 70,
		part_id: 49,
		quantity: 477,
	},
	{
		garage_id: 70,
		part_id: 50,
		quantity: 425,
	},
	{
		garage_id: 70,
		part_id: 51,
		quantity: 65,
	},
	{
		garage_id: 70,
		part_id: 52,
		quantity: 302,
	},
	{
		garage_id: 70,
		part_id: 53,
		quantity: 309,
	},
	{
		garage_id: 70,
		part_id: 54,
		quantity: 182,
	},
	{
		garage_id: 70,
		part_id: 55,
		quantity: 134,
	},
	{
		garage_id: 70,
		part_id: 56,
		quantity: 90,
	},
	{
		garage_id: 70,
		part_id: 57,
		quantity: 285,
	},
	{
		garage_id: 70,
		part_id: 58,
		quantity: 444,
	},
	{
		garage_id: 70,
		part_id: 59,
		quantity: 16,
	},
	{
		garage_id: 70,
		part_id: 60,
		quantity: 303,
	},
	{
		garage_id: 70,
		part_id: 61,
		quantity: 261,
	},
	{
		garage_id: 70,
		part_id: 62,
		quantity: 264,
	},
	{
		garage_id: 70,
		part_id: 63,
		quantity: 481,
	},
	{
		garage_id: 70,
		part_id: 64,
		quantity: 442,
	},
	{
		garage_id: 70,
		part_id: 65,
		quantity: 120,
	},
	{
		garage_id: 70,
		part_id: 66,
		quantity: 237,
	},
	{
		garage_id: 70,
		part_id: 67,
		quantity: 303,
	},
	{
		garage_id: 70,
		part_id: 68,
		quantity: 255,
	},
	{
		garage_id: 70,
		part_id: 69,
		quantity: 266,
	},
	{
		garage_id: 70,
		part_id: 70,
		quantity: 379,
	},
	{
		garage_id: 70,
		part_id: 71,
		quantity: 173,
	},
	{
		garage_id: 70,
		part_id: 72,
		quantity: 136,
	},
	{
		garage_id: 70,
		part_id: 73,
		quantity: 176,
	},
	{
		garage_id: 70,
		part_id: 74,
		quantity: 350,
	},
	{
		garage_id: 70,
		part_id: 75,
		quantity: 187,
	},
	{
		garage_id: 70,
		part_id: 76,
		quantity: 10,
	},
	{
		garage_id: 70,
		part_id: 77,
		quantity: 308,
	},
	{
		garage_id: 70,
		part_id: 78,
		quantity: 80,
	},
	{
		garage_id: 70,
		part_id: 79,
		quantity: 83,
	},
	{
		garage_id: 70,
		part_id: 80,
		quantity: 405,
	},
	{
		garage_id: 70,
		part_id: 81,
		quantity: 102,
	},
	{
		garage_id: 70,
		part_id: 82,
		quantity: 17,
	},
	{
		garage_id: 70,
		part_id: 83,
		quantity: 415,
	},
	{
		garage_id: 70,
		part_id: 84,
		quantity: 326,
	},
	{
		garage_id: 70,
		part_id: 85,
		quantity: 193,
	},
	{
		garage_id: 70,
		part_id: 86,
		quantity: 124,
	},
	{
		garage_id: 70,
		part_id: 87,
		quantity: 214,
	},
	{
		garage_id: 70,
		part_id: 88,
		quantity: 473,
	},
	{
		garage_id: 70,
		part_id: 89,
		quantity: 17,
	},
	{
		garage_id: 70,
		part_id: 90,
		quantity: 154,
	},
	{
		garage_id: 70,
		part_id: 91,
		quantity: 50,
	},
	{
		garage_id: 70,
		part_id: 92,
		quantity: 425,
	},
	{
		garage_id: 70,
		part_id: 93,
		quantity: 394,
	},
	{
		garage_id: 70,
		part_id: 94,
		quantity: 353,
	},
	{
		garage_id: 70,
		part_id: 95,
		quantity: 148,
	},
	{
		garage_id: 70,
		part_id: 96,
		quantity: 444,
	},
	{
		garage_id: 70,
		part_id: 97,
		quantity: 422,
	},
	{
		garage_id: 70,
		part_id: 98,
		quantity: 412,
	},
	{
		garage_id: 70,
		part_id: 99,
		quantity: 155,
	},
	{
		garage_id: 70,
		part_id: 100,
		quantity: 296,
	},
	{
		garage_id: 70,
		part_id: 101,
		quantity: 325,
	},
	{
		garage_id: 70,
		part_id: 102,
		quantity: 452,
	},
	{
		garage_id: 70,
		part_id: 103,
		quantity: 85,
	},
	{
		garage_id: 70,
		part_id: 104,
		quantity: 447,
	},
	{
		garage_id: 70,
		part_id: 105,
		quantity: 400,
	},
	{
		garage_id: 70,
		part_id: 106,
		quantity: 378,
	},
	{
		garage_id: 70,
		part_id: 107,
		quantity: 22,
	},
	{
		garage_id: 70,
		part_id: 108,
		quantity: 17,
	},
	{
		garage_id: 70,
		part_id: 109,
		quantity: 406,
	},
	{
		garage_id: 70,
		part_id: 110,
		quantity: 427,
	},
	{
		garage_id: 70,
		part_id: 111,
		quantity: 154,
	},
	{
		garage_id: 70,
		part_id: 112,
		quantity: 117,
	},
	{
		garage_id: 70,
		part_id: 113,
		quantity: 378,
	},
	{
		garage_id: 70,
		part_id: 114,
		quantity: 181,
	},
	{
		garage_id: 70,
		part_id: 115,
		quantity: 246,
	},
	{
		garage_id: 70,
		part_id: 116,
		quantity: 117,
	},
	{
		garage_id: 70,
		part_id: 117,
		quantity: 289,
	},
	{
		garage_id: 70,
		part_id: 118,
		quantity: 82,
	},
	{
		garage_id: 70,
		part_id: 119,
		quantity: 327,
	},
	{
		garage_id: 70,
		part_id: 120,
		quantity: 171,
	},
	{
		garage_id: 70,
		part_id: 121,
		quantity: 114,
	},
	{
		garage_id: 70,
		part_id: 122,
		quantity: 358,
	},
	{
		garage_id: 70,
		part_id: 123,
		quantity: 219,
	},
	{
		garage_id: 70,
		part_id: 124,
		quantity: 329,
	},
	{
		garage_id: 70,
		part_id: 125,
		quantity: 331,
	},
	{
		garage_id: 70,
		part_id: 126,
		quantity: 108,
	},
	{
		garage_id: 70,
		part_id: 127,
		quantity: 302,
	},
	{
		garage_id: 70,
		part_id: 128,
		quantity: 88,
	},
	{
		garage_id: 70,
		part_id: 129,
		quantity: 80,
	},
	{
		garage_id: 70,
		part_id: 130,
		quantity: 264,
	},
	{
		garage_id: 70,
		part_id: 131,
		quantity: 219,
	},
	{
		garage_id: 70,
		part_id: 132,
		quantity: 95,
	},
	{
		garage_id: 70,
		part_id: 133,
		quantity: 280,
	},
	{
		garage_id: 70,
		part_id: 134,
		quantity: 276,
	},
	{
		garage_id: 70,
		part_id: 135,
		quantity: 165,
	},
	{
		garage_id: 70,
		part_id: 136,
		quantity: 273,
	},
	{
		garage_id: 70,
		part_id: 137,
		quantity: 170,
	},
	{
		garage_id: 70,
		part_id: 138,
		quantity: 114,
	},
	{
		garage_id: 70,
		part_id: 139,
		quantity: 136,
	},
	{
		garage_id: 70,
		part_id: 140,
		quantity: 309,
	},
	{
		garage_id: 70,
		part_id: 141,
		quantity: 360,
	},
	{
		garage_id: 70,
		part_id: 142,
		quantity: 152,
	},
	{
		garage_id: 70,
		part_id: 143,
		quantity: 437,
	},
	{
		garage_id: 70,
		part_id: 144,
		quantity: 261,
	},
	{
		garage_id: 70,
		part_id: 145,
		quantity: 394,
	},
	{
		garage_id: 70,
		part_id: 146,
		quantity: 141,
	},
	{
		garage_id: 70,
		part_id: 147,
		quantity: 45,
	},
	{
		garage_id: 70,
		part_id: 148,
		quantity: 455,
	},
	{
		garage_id: 70,
		part_id: 149,
		quantity: 99,
	},
	{
		garage_id: 70,
		part_id: 150,
		quantity: 161,
	},
	{
		garage_id: 70,
		part_id: 151,
		quantity: 75,
	},
	{
		garage_id: 70,
		part_id: 152,
		quantity: 300,
	},
	{
		garage_id: 70,
		part_id: 153,
		quantity: 222,
	},
	{
		garage_id: 70,
		part_id: 154,
		quantity: 214,
	},
	{
		garage_id: 70,
		part_id: 155,
		quantity: 140,
	},
	{
		garage_id: 70,
		part_id: 156,
		quantity: 332,
	},
	{
		garage_id: 70,
		part_id: 157,
		quantity: 42,
	},
	{
		garage_id: 70,
		part_id: 158,
		quantity: 145,
	},
	{
		garage_id: 70,
		part_id: 159,
		quantity: 194,
	},
	{
		garage_id: 70,
		part_id: 160,
		quantity: 237,
	},
	{
		garage_id: 70,
		part_id: 161,
		quantity: 156,
	},
	{
		garage_id: 70,
		part_id: 162,
		quantity: 449,
	},
	{
		garage_id: 70,
		part_id: 163,
		quantity: 55,
	},
	{
		garage_id: 70,
		part_id: 164,
		quantity: 49,
	},
	{
		garage_id: 70,
		part_id: 165,
		quantity: 278,
	},
	{
		garage_id: 70,
		part_id: 166,
		quantity: 38,
	},
	{
		garage_id: 70,
		part_id: 167,
		quantity: 392,
	},
	{
		garage_id: 70,
		part_id: 168,
		quantity: 347,
	},
	{
		garage_id: 70,
		part_id: 169,
		quantity: 427,
	},
	{
		garage_id: 70,
		part_id: 170,
		quantity: 181,
	},
	{
		garage_id: 70,
		part_id: 171,
		quantity: 197,
	},
	{
		garage_id: 70,
		part_id: 172,
		quantity: 283,
	},
	{
		garage_id: 70,
		part_id: 173,
		quantity: 205,
	},
	{
		garage_id: 70,
		part_id: 174,
		quantity: 428,
	},
	{
		garage_id: 70,
		part_id: 175,
		quantity: 57,
	},
	{
		garage_id: 70,
		part_id: 176,
		quantity: 167,
	},
	{
		garage_id: 70,
		part_id: 177,
		quantity: 338,
	},
	{
		garage_id: 70,
		part_id: 178,
		quantity: 105,
	},
	{
		garage_id: 70,
		part_id: 179,
		quantity: 157,
	},
	{
		garage_id: 70,
		part_id: 180,
		quantity: 334,
	},
	{
		garage_id: 70,
		part_id: 181,
		quantity: 128,
	},
	{
		garage_id: 70,
		part_id: 182,
		quantity: 335,
	},
	{
		garage_id: 70,
		part_id: 183,
		quantity: 355,
	},
	{
		garage_id: 70,
		part_id: 184,
		quantity: 63,
	},
	{
		garage_id: 70,
		part_id: 185,
		quantity: 373,
	},
	{
		garage_id: 70,
		part_id: 186,
		quantity: 374,
	},
	{
		garage_id: 70,
		part_id: 187,
		quantity: 462,
	},
	{
		garage_id: 70,
		part_id: 188,
		quantity: 155,
	},
	{
		garage_id: 70,
		part_id: 189,
		quantity: 123,
	},
	{
		garage_id: 70,
		part_id: 190,
		quantity: 308,
	},
	{
		garage_id: 70,
		part_id: 191,
		quantity: 314,
	},
	{
		garage_id: 70,
		part_id: 192,
		quantity: 496,
	},
	{
		garage_id: 70,
		part_id: 193,
		quantity: 227,
	},
	{
		garage_id: 70,
		part_id: 194,
		quantity: 466,
	},
	{
		garage_id: 70,
		part_id: 195,
		quantity: 64,
	},
	{
		garage_id: 70,
		part_id: 196,
		quantity: 491,
	},
	{
		garage_id: 70,
		part_id: 197,
		quantity: 457,
	},
	{
		garage_id: 70,
		part_id: 198,
		quantity: 314,
	},
	{
		garage_id: 70,
		part_id: 199,
		quantity: 110,
	},
	{
		garage_id: 70,
		part_id: 200,
		quantity: 456,
	},
	{
		garage_id: 70,
		part_id: 201,
		quantity: 162,
	},
	{
		garage_id: 70,
		part_id: 202,
		quantity: 194,
	},
	{
		garage_id: 70,
		part_id: 203,
		quantity: 225,
	},
	{
		garage_id: 70,
		part_id: 204,
		quantity: 221,
	},
	{
		garage_id: 70,
		part_id: 205,
		quantity: 277,
	},
	{
		garage_id: 70,
		part_id: 206,
		quantity: 491,
	},
	{
		garage_id: 70,
		part_id: 207,
		quantity: 140,
	},
	{
		garage_id: 70,
		part_id: 208,
		quantity: 21,
	},
	{
		garage_id: 70,
		part_id: 209,
		quantity: 104,
	},
	{
		garage_id: 70,
		part_id: 210,
		quantity: 380,
	},
	{
		garage_id: 70,
		part_id: 211,
		quantity: 342,
	},
	{
		garage_id: 70,
		part_id: 212,
		quantity: 385,
	},
	{
		garage_id: 70,
		part_id: 213,
		quantity: 424,
	},
	{
		garage_id: 70,
		part_id: 214,
		quantity: 244,
	},
	{
		garage_id: 70,
		part_id: 215,
		quantity: 41,
	},
	{
		garage_id: 70,
		part_id: 216,
		quantity: 138,
	},
	{
		garage_id: 70,
		part_id: 217,
		quantity: 42,
	},
	{
		garage_id: 70,
		part_id: 218,
		quantity: 358,
	},
	{
		garage_id: 70,
		part_id: 219,
		quantity: 362,
	},
	{
		garage_id: 70,
		part_id: 220,
		quantity: 329,
	},
	{
		garage_id: 70,
		part_id: 221,
		quantity: 498,
	},
	{
		garage_id: 70,
		part_id: 222,
		quantity: 444,
	},
	{
		garage_id: 70,
		part_id: 223,
		quantity: 374,
	},
	{
		garage_id: 70,
		part_id: 224,
		quantity: 254,
	},
	{
		garage_id: 70,
		part_id: 225,
		quantity: 289,
	},
	{
		garage_id: 70,
		part_id: 226,
		quantity: 354,
	},
	{
		garage_id: 70,
		part_id: 227,
		quantity: 10,
	},
	{
		garage_id: 70,
		part_id: 228,
		quantity: 255,
	},
	{
		garage_id: 70,
		part_id: 229,
		quantity: 96,
	},
	{
		garage_id: 70,
		part_id: 230,
		quantity: 409,
	},
	{
		garage_id: 70,
		part_id: 231,
		quantity: 450,
	},
	{
		garage_id: 70,
		part_id: 232,
		quantity: 133,
	},
	{
		garage_id: 70,
		part_id: 233,
		quantity: 258,
	},
	{
		garage_id: 70,
		part_id: 234,
		quantity: 203,
	},
	{
		garage_id: 70,
		part_id: 235,
		quantity: 73,
	},
	{
		garage_id: 70,
		part_id: 236,
		quantity: 114,
	},
	{
		garage_id: 70,
		part_id: 237,
		quantity: 101,
	},
	{
		garage_id: 70,
		part_id: 238,
		quantity: 348,
	},
	{
		garage_id: 70,
		part_id: 239,
		quantity: 279,
	},
	{
		garage_id: 70,
		part_id: 240,
		quantity: 267,
	},
	{
		garage_id: 70,
		part_id: 241,
		quantity: 273,
	},
	{
		garage_id: 70,
		part_id: 242,
		quantity: 406,
	},
	{
		garage_id: 70,
		part_id: 243,
		quantity: 378,
	},
	{
		garage_id: 70,
		part_id: 244,
		quantity: 491,
	},
	{
		garage_id: 70,
		part_id: 245,
		quantity: 252,
	},
	{
		garage_id: 70,
		part_id: 246,
		quantity: 439,
	},
	{
		garage_id: 70,
		part_id: 247,
		quantity: 22,
	},
	{
		garage_id: 70,
		part_id: 248,
		quantity: 444,
	},
	{
		garage_id: 70,
		part_id: 249,
		quantity: 152,
	},
	{
		garage_id: 70,
		part_id: 250,
		quantity: 30,
	},
	{
		garage_id: 70,
		part_id: 251,
		quantity: 220,
	},
	{
		garage_id: 70,
		part_id: 252,
		quantity: 33,
	},
	{
		garage_id: 70,
		part_id: 253,
		quantity: 8,
	},
	{
		garage_id: 70,
		part_id: 254,
		quantity: 355,
	},
	{
		garage_id: 70,
		part_id: 255,
		quantity: 80,
	},
	{
		garage_id: 70,
		part_id: 256,
		quantity: 441,
	},
	{
		garage_id: 70,
		part_id: 257,
		quantity: 309,
	},
	{
		garage_id: 70,
		part_id: 258,
		quantity: 369,
	},
	{
		garage_id: 70,
		part_id: 259,
		quantity: 465,
	},
	{
		garage_id: 70,
		part_id: 260,
		quantity: 462,
	},
	{
		garage_id: 70,
		part_id: 261,
		quantity: 279,
	},
	{
		garage_id: 70,
		part_id: 262,
		quantity: 91,
	},
	{
		garage_id: 70,
		part_id: 263,
		quantity: 433,
	},
	{
		garage_id: 70,
		part_id: 264,
		quantity: 34,
	},
	{
		garage_id: 70,
		part_id: 265,
		quantity: 251,
	},
	{
		garage_id: 70,
		part_id: 266,
		quantity: 224,
	},
	{
		garage_id: 70,
		part_id: 267,
		quantity: 247,
	},
	{
		garage_id: 70,
		part_id: 268,
		quantity: 428,
	},
	{
		garage_id: 70,
		part_id: 269,
		quantity: 347,
	},
	{
		garage_id: 70,
		part_id: 270,
		quantity: 221,
	},
	{
		garage_id: 70,
		part_id: 271,
		quantity: 312,
	},
	{
		garage_id: 70,
		part_id: 272,
		quantity: 166,
	},
	{
		garage_id: 70,
		part_id: 273,
		quantity: 223,
	},
	{
		garage_id: 70,
		part_id: 274,
		quantity: 467,
	},
	{
		garage_id: 70,
		part_id: 275,
		quantity: 141,
	},
	{
		garage_id: 70,
		part_id: 276,
		quantity: 375,
	},
	{
		garage_id: 70,
		part_id: 277,
		quantity: 472,
	},
	{
		garage_id: 70,
		part_id: 278,
		quantity: 129,
	},
	{
		garage_id: 70,
		part_id: 279,
		quantity: 354,
	},
	{
		garage_id: 70,
		part_id: 280,
		quantity: 247,
	},
	{
		garage_id: 70,
		part_id: 281,
		quantity: 340,
	},
	{
		garage_id: 70,
		part_id: 282,
		quantity: 310,
	},
	{
		garage_id: 70,
		part_id: 283,
		quantity: 96,
	},
	{
		garage_id: 70,
		part_id: 284,
		quantity: 422,
	},
	{
		garage_id: 70,
		part_id: 285,
		quantity: 302,
	},
	{
		garage_id: 70,
		part_id: 286,
		quantity: 227,
	},
	{
		garage_id: 70,
		part_id: 287,
		quantity: 12,
	},
	{
		garage_id: 70,
		part_id: 288,
		quantity: 386,
	},
	{
		garage_id: 70,
		part_id: 289,
		quantity: 199,
	},
	{
		garage_id: 70,
		part_id: 290,
		quantity: 119,
	},
	{
		garage_id: 70,
		part_id: 291,
		quantity: 190,
	},
	{
		garage_id: 70,
		part_id: 292,
		quantity: 196,
	},
	{
		garage_id: 70,
		part_id: 293,
		quantity: 373,
	},
	{
		garage_id: 70,
		part_id: 294,
		quantity: 374,
	},
	{
		garage_id: 70,
		part_id: 295,
		quantity: 211,
	},
	{
		garage_id: 70,
		part_id: 296,
		quantity: 23,
	},
	{
		garage_id: 70,
		part_id: 297,
		quantity: 210,
	},
	{
		garage_id: 70,
		part_id: 298,
		quantity: 333,
	},
	{
		garage_id: 70,
		part_id: 299,
		quantity: 131,
	},
	{
		garage_id: 70,
		part_id: 300,
		quantity: 488,
	},
	{
		garage_id: 70,
		part_id: 301,
		quantity: 24,
	},
	{
		garage_id: 70,
		part_id: 302,
		quantity: 133,
	},
	{
		garage_id: 70,
		part_id: 303,
		quantity: 56,
	},
	{
		garage_id: 70,
		part_id: 304,
		quantity: 407,
	},
	{
		garage_id: 70,
		part_id: 305,
		quantity: 179,
	},
	{
		garage_id: 70,
		part_id: 306,
		quantity: 110,
	},
	{
		garage_id: 70,
		part_id: 307,
		quantity: 219,
	},
	{
		garage_id: 70,
		part_id: 308,
		quantity: 180,
	},
	{
		garage_id: 70,
		part_id: 309,
		quantity: 473,
	},
	{
		garage_id: 70,
		part_id: 310,
		quantity: 325,
	},
	{
		garage_id: 70,
		part_id: 311,
		quantity: 499,
	},
	{
		garage_id: 70,
		part_id: 312,
		quantity: 305,
	},
	{
		garage_id: 70,
		part_id: 313,
		quantity: 12,
	},
	{
		garage_id: 70,
		part_id: 314,
		quantity: 151,
	},
	{
		garage_id: 70,
		part_id: 315,
		quantity: 316,
	},
	{
		garage_id: 70,
		part_id: 316,
		quantity: 460,
	},
	{
		garage_id: 70,
		part_id: 317,
		quantity: 11,
	},
	{
		garage_id: 70,
		part_id: 318,
		quantity: 243,
	},
	{
		garage_id: 70,
		part_id: 319,
		quantity: 316,
	},
	{
		garage_id: 70,
		part_id: 320,
		quantity: 338,
	},
	{
		garage_id: 70,
		part_id: 321,
		quantity: 55,
	},
	{
		garage_id: 70,
		part_id: 322,
		quantity: 187,
	},
	{
		garage_id: 70,
		part_id: 323,
		quantity: 155,
	},
	{
		garage_id: 70,
		part_id: 324,
		quantity: 80,
	},
	{
		garage_id: 70,
		part_id: 325,
		quantity: 167,
	},
	{
		garage_id: 70,
		part_id: 326,
		quantity: 375,
	},
	{
		garage_id: 70,
		part_id: 327,
		quantity: 468,
	},
	{
		garage_id: 70,
		part_id: 328,
		quantity: 32,
	},
	{
		garage_id: 70,
		part_id: 329,
		quantity: 408,
	},
	{
		garage_id: 70,
		part_id: 330,
		quantity: 259,
	},
	{
		garage_id: 70,
		part_id: 331,
		quantity: 383,
	},
	{
		garage_id: 70,
		part_id: 332,
		quantity: 452,
	},
	{
		garage_id: 70,
		part_id: 333,
		quantity: 307,
	},
	{
		garage_id: 70,
		part_id: 334,
		quantity: 224,
	},
	{
		garage_id: 70,
		part_id: 335,
		quantity: 58,
	},
	{
		garage_id: 70,
		part_id: 336,
		quantity: 458,
	},
	{
		garage_id: 70,
		part_id: 337,
		quantity: 149,
	},
	{
		garage_id: 70,
		part_id: 338,
		quantity: 307,
	},
	{
		garage_id: 70,
		part_id: 339,
		quantity: 226,
	},
	{
		garage_id: 70,
		part_id: 340,
		quantity: 239,
	},
	{
		garage_id: 70,
		part_id: 341,
		quantity: 355,
	},
	{
		garage_id: 70,
		part_id: 342,
		quantity: 368,
	},
	{
		garage_id: 70,
		part_id: 343,
		quantity: 245,
	},
	{
		garage_id: 70,
		part_id: 344,
		quantity: 302,
	},
	{
		garage_id: 70,
		part_id: 345,
		quantity: 71,
	},
	{
		garage_id: 70,
		part_id: 346,
		quantity: 303,
	},
	{
		garage_id: 70,
		part_id: 347,
		quantity: 312,
	},
	{
		garage_id: 70,
		part_id: 348,
		quantity: 194,
	},
	{
		garage_id: 70,
		part_id: 349,
		quantity: 39,
	},
	{
		garage_id: 70,
		part_id: 350,
		quantity: 122,
	},
	{
		garage_id: 70,
		part_id: 351,
		quantity: 479,
	},
	{
		garage_id: 70,
		part_id: 352,
		quantity: 45,
	},
	{
		garage_id: 70,
		part_id: 353,
		quantity: 122,
	},
	{
		garage_id: 70,
		part_id: 354,
		quantity: 254,
	},
	{
		garage_id: 70,
		part_id: 355,
		quantity: 42,
	},
	{
		garage_id: 70,
		part_id: 356,
		quantity: 26,
	},
	{
		garage_id: 70,
		part_id: 357,
		quantity: 394,
	},
	{
		garage_id: 70,
		part_id: 358,
		quantity: 290,
	},
	{
		garage_id: 70,
		part_id: 359,
		quantity: 418,
	},
	{
		garage_id: 70,
		part_id: 360,
		quantity: 61,
	},
	{
		garage_id: 70,
		part_id: 361,
		quantity: 478,
	},
	{
		garage_id: 70,
		part_id: 362,
		quantity: 30,
	},
	{
		garage_id: 70,
		part_id: 363,
		quantity: 471,
	},
	{
		garage_id: 70,
		part_id: 364,
		quantity: 242,
	},
	{
		garage_id: 70,
		part_id: 365,
		quantity: 165,
	},
	{
		garage_id: 70,
		part_id: 366,
		quantity: 193,
	},
	{
		garage_id: 70,
		part_id: 367,
		quantity: 466,
	},
	{
		garage_id: 70,
		part_id: 368,
		quantity: 325,
	},
	{
		garage_id: 70,
		part_id: 369,
		quantity: 79,
	},
	{
		garage_id: 70,
		part_id: 370,
		quantity: 136,
	},
	{
		garage_id: 70,
		part_id: 371,
		quantity: 88,
	},
	{
		garage_id: 70,
		part_id: 372,
		quantity: 239,
	},
	{
		garage_id: 70,
		part_id: 373,
		quantity: 177,
	},
	{
		garage_id: 70,
		part_id: 374,
		quantity: 18,
	},
	{
		garage_id: 70,
		part_id: 375,
		quantity: 83,
	},
	{
		garage_id: 70,
		part_id: 376,
		quantity: 149,
	},
	{
		garage_id: 70,
		part_id: 377,
		quantity: 303,
	},
	{
		garage_id: 70,
		part_id: 378,
		quantity: 467,
	},
	{
		garage_id: 70,
		part_id: 379,
		quantity: 404,
	},
	{
		garage_id: 70,
		part_id: 380,
		quantity: 274,
	},
	{
		garage_id: 70,
		part_id: 381,
		quantity: 246,
	},
	{
		garage_id: 70,
		part_id: 382,
		quantity: 17,
	},
	{
		garage_id: 70,
		part_id: 383,
		quantity: 77,
	},
	{
		garage_id: 70,
		part_id: 384,
		quantity: 1,
	},
	{
		garage_id: 70,
		part_id: 385,
		quantity: 261,
	},
	{
		garage_id: 70,
		part_id: 386,
		quantity: 161,
	},
	{
		garage_id: 70,
		part_id: 387,
		quantity: 270,
	},
	{
		garage_id: 70,
		part_id: 388,
		quantity: 320,
	},
	{
		garage_id: 70,
		part_id: 389,
		quantity: 346,
	},
	{
		garage_id: 70,
		part_id: 390,
		quantity: 428,
	},
	{
		garage_id: 70,
		part_id: 391,
		quantity: 450,
	},
	{
		garage_id: 70,
		part_id: 392,
		quantity: 67,
	},
	{
		garage_id: 70,
		part_id: 393,
		quantity: 226,
	},
	{
		garage_id: 70,
		part_id: 394,
		quantity: 307,
	},
	{
		garage_id: 70,
		part_id: 395,
		quantity: 111,
	},
	{
		garage_id: 70,
		part_id: 396,
		quantity: 136,
	},
	{
		garage_id: 70,
		part_id: 397,
		quantity: 212,
	},
	{
		garage_id: 70,
		part_id: 398,
		quantity: 160,
	},
	{
		garage_id: 70,
		part_id: 399,
		quantity: 344,
	},
	{
		garage_id: 71,
		part_id: 0,
		quantity: 427,
	},
	{
		garage_id: 71,
		part_id: 1,
		quantity: 338,
	},
	{
		garage_id: 71,
		part_id: 2,
		quantity: 223,
	},
	{
		garage_id: 71,
		part_id: 3,
		quantity: 159,
	},
	{
		garage_id: 71,
		part_id: 4,
		quantity: 41,
	},
	{
		garage_id: 71,
		part_id: 5,
		quantity: 79,
	},
	{
		garage_id: 71,
		part_id: 6,
		quantity: 472,
	},
	{
		garage_id: 71,
		part_id: 7,
		quantity: 90,
	},
	{
		garage_id: 71,
		part_id: 8,
		quantity: 46,
	},
	{
		garage_id: 71,
		part_id: 9,
		quantity: 207,
	},
	{
		garage_id: 71,
		part_id: 10,
		quantity: 179,
	},
	{
		garage_id: 71,
		part_id: 11,
		quantity: 100,
	},
	{
		garage_id: 71,
		part_id: 12,
		quantity: 119,
	},
	{
		garage_id: 71,
		part_id: 13,
		quantity: 210,
	},
	{
		garage_id: 71,
		part_id: 14,
		quantity: 103,
	},
	{
		garage_id: 71,
		part_id: 15,
		quantity: 24,
	},
	{
		garage_id: 71,
		part_id: 16,
		quantity: 318,
	},
	{
		garage_id: 71,
		part_id: 17,
		quantity: 171,
	},
	{
		garage_id: 71,
		part_id: 18,
		quantity: 251,
	},
	{
		garage_id: 71,
		part_id: 19,
		quantity: 256,
	},
	{
		garage_id: 71,
		part_id: 20,
		quantity: 168,
	},
	{
		garage_id: 71,
		part_id: 21,
		quantity: 131,
	},
	{
		garage_id: 71,
		part_id: 22,
		quantity: 396,
	},
	{
		garage_id: 71,
		part_id: 23,
		quantity: 52,
	},
	{
		garage_id: 71,
		part_id: 24,
		quantity: 125,
	},
	{
		garage_id: 71,
		part_id: 25,
		quantity: 265,
	},
	{
		garage_id: 71,
		part_id: 26,
		quantity: 267,
	},
	{
		garage_id: 71,
		part_id: 27,
		quantity: 241,
	},
	{
		garage_id: 71,
		part_id: 28,
		quantity: 18,
	},
	{
		garage_id: 71,
		part_id: 29,
		quantity: 107,
	},
	{
		garage_id: 71,
		part_id: 30,
		quantity: 22,
	},
	{
		garage_id: 71,
		part_id: 31,
		quantity: 420,
	},
	{
		garage_id: 71,
		part_id: 32,
		quantity: 162,
	},
	{
		garage_id: 71,
		part_id: 33,
		quantity: 350,
	},
	{
		garage_id: 71,
		part_id: 34,
		quantity: 112,
	},
	{
		garage_id: 71,
		part_id: 35,
		quantity: 327,
	},
	{
		garage_id: 71,
		part_id: 36,
		quantity: 498,
	},
	{
		garage_id: 71,
		part_id: 37,
		quantity: 101,
	},
	{
		garage_id: 71,
		part_id: 38,
		quantity: 118,
	},
	{
		garage_id: 71,
		part_id: 39,
		quantity: 285,
	},
	{
		garage_id: 71,
		part_id: 40,
		quantity: 374,
	},
	{
		garage_id: 71,
		part_id: 41,
		quantity: 497,
	},
	{
		garage_id: 71,
		part_id: 42,
		quantity: 459,
	},
	{
		garage_id: 71,
		part_id: 43,
		quantity: 364,
	},
	{
		garage_id: 71,
		part_id: 44,
		quantity: 295,
	},
	{
		garage_id: 71,
		part_id: 45,
		quantity: 63,
	},
	{
		garage_id: 71,
		part_id: 46,
		quantity: 382,
	},
	{
		garage_id: 71,
		part_id: 47,
		quantity: 64,
	},
	{
		garage_id: 71,
		part_id: 48,
		quantity: 498,
	},
	{
		garage_id: 71,
		part_id: 49,
		quantity: 497,
	},
	{
		garage_id: 71,
		part_id: 50,
		quantity: 377,
	},
	{
		garage_id: 71,
		part_id: 51,
		quantity: 47,
	},
	{
		garage_id: 71,
		part_id: 52,
		quantity: 426,
	},
	{
		garage_id: 71,
		part_id: 53,
		quantity: 432,
	},
	{
		garage_id: 71,
		part_id: 54,
		quantity: 6,
	},
	{
		garage_id: 71,
		part_id: 55,
		quantity: 149,
	},
	{
		garage_id: 71,
		part_id: 56,
		quantity: 302,
	},
	{
		garage_id: 71,
		part_id: 57,
		quantity: 158,
	},
	{
		garage_id: 71,
		part_id: 58,
		quantity: 5,
	},
	{
		garage_id: 71,
		part_id: 59,
		quantity: 120,
	},
	{
		garage_id: 71,
		part_id: 60,
		quantity: 64,
	},
	{
		garage_id: 71,
		part_id: 61,
		quantity: 330,
	},
	{
		garage_id: 71,
		part_id: 62,
		quantity: 412,
	},
	{
		garage_id: 71,
		part_id: 63,
		quantity: 142,
	},
	{
		garage_id: 71,
		part_id: 64,
		quantity: 279,
	},
	{
		garage_id: 71,
		part_id: 65,
		quantity: 343,
	},
	{
		garage_id: 71,
		part_id: 66,
		quantity: 404,
	},
	{
		garage_id: 71,
		part_id: 67,
		quantity: 318,
	},
	{
		garage_id: 71,
		part_id: 68,
		quantity: 345,
	},
	{
		garage_id: 71,
		part_id: 69,
		quantity: 1,
	},
	{
		garage_id: 71,
		part_id: 70,
		quantity: 27,
	},
	{
		garage_id: 71,
		part_id: 71,
		quantity: 168,
	},
	{
		garage_id: 71,
		part_id: 72,
		quantity: 203,
	},
	{
		garage_id: 71,
		part_id: 73,
		quantity: 383,
	},
	{
		garage_id: 71,
		part_id: 74,
		quantity: 95,
	},
	{
		garage_id: 71,
		part_id: 75,
		quantity: 341,
	},
	{
		garage_id: 71,
		part_id: 76,
		quantity: 177,
	},
	{
		garage_id: 71,
		part_id: 77,
		quantity: 381,
	},
	{
		garage_id: 71,
		part_id: 78,
		quantity: 208,
	},
	{
		garage_id: 71,
		part_id: 79,
		quantity: 44,
	},
	{
		garage_id: 71,
		part_id: 80,
		quantity: 80,
	},
	{
		garage_id: 71,
		part_id: 81,
		quantity: 11,
	},
	{
		garage_id: 71,
		part_id: 82,
		quantity: 317,
	},
	{
		garage_id: 71,
		part_id: 83,
		quantity: 240,
	},
	{
		garage_id: 71,
		part_id: 84,
		quantity: 109,
	},
	{
		garage_id: 71,
		part_id: 85,
		quantity: 414,
	},
	{
		garage_id: 71,
		part_id: 86,
		quantity: 98,
	},
	{
		garage_id: 71,
		part_id: 87,
		quantity: 89,
	},
	{
		garage_id: 71,
		part_id: 88,
		quantity: 410,
	},
	{
		garage_id: 71,
		part_id: 89,
		quantity: 354,
	},
	{
		garage_id: 71,
		part_id: 90,
		quantity: 33,
	},
	{
		garage_id: 71,
		part_id: 91,
		quantity: 410,
	},
	{
		garage_id: 71,
		part_id: 92,
		quantity: 233,
	},
	{
		garage_id: 71,
		part_id: 93,
		quantity: 496,
	},
	{
		garage_id: 71,
		part_id: 94,
		quantity: 138,
	},
	{
		garage_id: 71,
		part_id: 95,
		quantity: 14,
	},
	{
		garage_id: 71,
		part_id: 96,
		quantity: 473,
	},
	{
		garage_id: 71,
		part_id: 97,
		quantity: 468,
	},
	{
		garage_id: 71,
		part_id: 98,
		quantity: 210,
	},
	{
		garage_id: 71,
		part_id: 99,
		quantity: 416,
	},
	{
		garage_id: 71,
		part_id: 100,
		quantity: 384,
	},
	{
		garage_id: 71,
		part_id: 101,
		quantity: 175,
	},
	{
		garage_id: 71,
		part_id: 102,
		quantity: 226,
	},
	{
		garage_id: 71,
		part_id: 103,
		quantity: 455,
	},
	{
		garage_id: 71,
		part_id: 104,
		quantity: 175,
	},
	{
		garage_id: 71,
		part_id: 105,
		quantity: 213,
	},
	{
		garage_id: 71,
		part_id: 106,
		quantity: 54,
	},
	{
		garage_id: 71,
		part_id: 107,
		quantity: 497,
	},
	{
		garage_id: 71,
		part_id: 108,
		quantity: 206,
	},
	{
		garage_id: 71,
		part_id: 109,
		quantity: 470,
	},
	{
		garage_id: 71,
		part_id: 110,
		quantity: 253,
	},
	{
		garage_id: 71,
		part_id: 111,
		quantity: 499,
	},
	{
		garage_id: 71,
		part_id: 112,
		quantity: 110,
	},
	{
		garage_id: 71,
		part_id: 113,
		quantity: 462,
	},
	{
		garage_id: 71,
		part_id: 114,
		quantity: 495,
	},
	{
		garage_id: 71,
		part_id: 115,
		quantity: 69,
	},
	{
		garage_id: 71,
		part_id: 116,
		quantity: 89,
	},
	{
		garage_id: 71,
		part_id: 117,
		quantity: 472,
	},
	{
		garage_id: 71,
		part_id: 118,
		quantity: 234,
	},
	{
		garage_id: 71,
		part_id: 119,
		quantity: 447,
	},
	{
		garage_id: 71,
		part_id: 120,
		quantity: 373,
	},
	{
		garage_id: 71,
		part_id: 121,
		quantity: 460,
	},
	{
		garage_id: 71,
		part_id: 122,
		quantity: 49,
	},
	{
		garage_id: 71,
		part_id: 123,
		quantity: 356,
	},
	{
		garage_id: 71,
		part_id: 124,
		quantity: 426,
	},
	{
		garage_id: 71,
		part_id: 125,
		quantity: 166,
	},
	{
		garage_id: 71,
		part_id: 126,
		quantity: 424,
	},
	{
		garage_id: 71,
		part_id: 127,
		quantity: 158,
	},
	{
		garage_id: 71,
		part_id: 128,
		quantity: 256,
	},
	{
		garage_id: 71,
		part_id: 129,
		quantity: 157,
	},
	{
		garage_id: 71,
		part_id: 130,
		quantity: 58,
	},
	{
		garage_id: 71,
		part_id: 131,
		quantity: 490,
	},
	{
		garage_id: 71,
		part_id: 132,
		quantity: 32,
	},
	{
		garage_id: 71,
		part_id: 133,
		quantity: 458,
	},
	{
		garage_id: 71,
		part_id: 134,
		quantity: 98,
	},
	{
		garage_id: 71,
		part_id: 135,
		quantity: 355,
	},
	{
		garage_id: 71,
		part_id: 136,
		quantity: 324,
	},
	{
		garage_id: 71,
		part_id: 137,
		quantity: 6,
	},
	{
		garage_id: 71,
		part_id: 138,
		quantity: 71,
	},
	{
		garage_id: 71,
		part_id: 139,
		quantity: 419,
	},
	{
		garage_id: 71,
		part_id: 140,
		quantity: 118,
	},
	{
		garage_id: 71,
		part_id: 141,
		quantity: 125,
	},
	{
		garage_id: 71,
		part_id: 142,
		quantity: 75,
	},
	{
		garage_id: 71,
		part_id: 143,
		quantity: 310,
	},
	{
		garage_id: 71,
		part_id: 144,
		quantity: 188,
	},
	{
		garage_id: 71,
		part_id: 145,
		quantity: 400,
	},
	{
		garage_id: 71,
		part_id: 146,
		quantity: 291,
	},
	{
		garage_id: 71,
		part_id: 147,
		quantity: 337,
	},
	{
		garage_id: 71,
		part_id: 148,
		quantity: 479,
	},
	{
		garage_id: 71,
		part_id: 149,
		quantity: 78,
	},
	{
		garage_id: 71,
		part_id: 150,
		quantity: 350,
	},
	{
		garage_id: 71,
		part_id: 151,
		quantity: 255,
	},
	{
		garage_id: 71,
		part_id: 152,
		quantity: 285,
	},
	{
		garage_id: 71,
		part_id: 153,
		quantity: 349,
	},
	{
		garage_id: 71,
		part_id: 154,
		quantity: 263,
	},
	{
		garage_id: 71,
		part_id: 155,
		quantity: 361,
	},
	{
		garage_id: 71,
		part_id: 156,
		quantity: 58,
	},
	{
		garage_id: 71,
		part_id: 157,
		quantity: 317,
	},
	{
		garage_id: 71,
		part_id: 158,
		quantity: 50,
	},
	{
		garage_id: 71,
		part_id: 159,
		quantity: 63,
	},
	{
		garage_id: 71,
		part_id: 160,
		quantity: 175,
	},
	{
		garage_id: 71,
		part_id: 161,
		quantity: 160,
	},
	{
		garage_id: 71,
		part_id: 162,
		quantity: 53,
	},
	{
		garage_id: 71,
		part_id: 163,
		quantity: 493,
	},
	{
		garage_id: 71,
		part_id: 164,
		quantity: 310,
	},
	{
		garage_id: 71,
		part_id: 165,
		quantity: 75,
	},
	{
		garage_id: 71,
		part_id: 166,
		quantity: 473,
	},
	{
		garage_id: 71,
		part_id: 167,
		quantity: 176,
	},
	{
		garage_id: 71,
		part_id: 168,
		quantity: 26,
	},
	{
		garage_id: 71,
		part_id: 169,
		quantity: 433,
	},
	{
		garage_id: 71,
		part_id: 170,
		quantity: 301,
	},
	{
		garage_id: 71,
		part_id: 171,
		quantity: 448,
	},
	{
		garage_id: 71,
		part_id: 172,
		quantity: 441,
	},
	{
		garage_id: 71,
		part_id: 173,
		quantity: 309,
	},
	{
		garage_id: 71,
		part_id: 174,
		quantity: 444,
	},
	{
		garage_id: 71,
		part_id: 175,
		quantity: 49,
	},
	{
		garage_id: 71,
		part_id: 176,
		quantity: 250,
	},
	{
		garage_id: 71,
		part_id: 177,
		quantity: 62,
	},
	{
		garage_id: 71,
		part_id: 178,
		quantity: 185,
	},
	{
		garage_id: 71,
		part_id: 179,
		quantity: 210,
	},
	{
		garage_id: 71,
		part_id: 180,
		quantity: 226,
	},
	{
		garage_id: 71,
		part_id: 181,
		quantity: 290,
	},
	{
		garage_id: 71,
		part_id: 182,
		quantity: 167,
	},
	{
		garage_id: 71,
		part_id: 183,
		quantity: 48,
	},
	{
		garage_id: 71,
		part_id: 184,
		quantity: 228,
	},
	{
		garage_id: 71,
		part_id: 185,
		quantity: 125,
	},
	{
		garage_id: 71,
		part_id: 186,
		quantity: 178,
	},
	{
		garage_id: 71,
		part_id: 187,
		quantity: 312,
	},
	{
		garage_id: 71,
		part_id: 188,
		quantity: 88,
	},
	{
		garage_id: 71,
		part_id: 189,
		quantity: 237,
	},
	{
		garage_id: 71,
		part_id: 190,
		quantity: 69,
	},
	{
		garage_id: 71,
		part_id: 191,
		quantity: 188,
	},
	{
		garage_id: 71,
		part_id: 192,
		quantity: 240,
	},
	{
		garage_id: 71,
		part_id: 193,
		quantity: 355,
	},
	{
		garage_id: 71,
		part_id: 194,
		quantity: 380,
	},
	{
		garage_id: 71,
		part_id: 195,
		quantity: 111,
	},
	{
		garage_id: 71,
		part_id: 196,
		quantity: 444,
	},
	{
		garage_id: 71,
		part_id: 197,
		quantity: 53,
	},
	{
		garage_id: 71,
		part_id: 198,
		quantity: 15,
	},
	{
		garage_id: 71,
		part_id: 199,
		quantity: 25,
	},
	{
		garage_id: 71,
		part_id: 200,
		quantity: 312,
	},
	{
		garage_id: 71,
		part_id: 201,
		quantity: 163,
	},
	{
		garage_id: 71,
		part_id: 202,
		quantity: 166,
	},
	{
		garage_id: 71,
		part_id: 203,
		quantity: 379,
	},
	{
		garage_id: 71,
		part_id: 204,
		quantity: 19,
	},
	{
		garage_id: 71,
		part_id: 205,
		quantity: 107,
	},
	{
		garage_id: 71,
		part_id: 206,
		quantity: 472,
	},
	{
		garage_id: 71,
		part_id: 207,
		quantity: 418,
	},
	{
		garage_id: 71,
		part_id: 208,
		quantity: 380,
	},
	{
		garage_id: 71,
		part_id: 209,
		quantity: 354,
	},
	{
		garage_id: 71,
		part_id: 210,
		quantity: 147,
	},
	{
		garage_id: 71,
		part_id: 211,
		quantity: 312,
	},
	{
		garage_id: 71,
		part_id: 212,
		quantity: 11,
	},
	{
		garage_id: 71,
		part_id: 213,
		quantity: 81,
	},
	{
		garage_id: 71,
		part_id: 214,
		quantity: 256,
	},
	{
		garage_id: 71,
		part_id: 215,
		quantity: 0,
	},
	{
		garage_id: 71,
		part_id: 216,
		quantity: 305,
	},
	{
		garage_id: 71,
		part_id: 217,
		quantity: 310,
	},
	{
		garage_id: 71,
		part_id: 218,
		quantity: 160,
	},
	{
		garage_id: 71,
		part_id: 219,
		quantity: 211,
	},
	{
		garage_id: 71,
		part_id: 220,
		quantity: 225,
	},
	{
		garage_id: 71,
		part_id: 221,
		quantity: 448,
	},
	{
		garage_id: 71,
		part_id: 222,
		quantity: 493,
	},
	{
		garage_id: 71,
		part_id: 223,
		quantity: 103,
	},
	{
		garage_id: 71,
		part_id: 224,
		quantity: 217,
	},
	{
		garage_id: 71,
		part_id: 225,
		quantity: 78,
	},
	{
		garage_id: 71,
		part_id: 226,
		quantity: 337,
	},
	{
		garage_id: 71,
		part_id: 227,
		quantity: 219,
	},
	{
		garage_id: 71,
		part_id: 228,
		quantity: 50,
	},
	{
		garage_id: 71,
		part_id: 229,
		quantity: 244,
	},
	{
		garage_id: 71,
		part_id: 230,
		quantity: 46,
	},
	{
		garage_id: 71,
		part_id: 231,
		quantity: 327,
	},
	{
		garage_id: 71,
		part_id: 232,
		quantity: 294,
	},
	{
		garage_id: 71,
		part_id: 233,
		quantity: 98,
	},
	{
		garage_id: 71,
		part_id: 234,
		quantity: 38,
	},
	{
		garage_id: 71,
		part_id: 235,
		quantity: 360,
	},
	{
		garage_id: 71,
		part_id: 236,
		quantity: 171,
	},
	{
		garage_id: 71,
		part_id: 237,
		quantity: 447,
	},
	{
		garage_id: 71,
		part_id: 238,
		quantity: 384,
	},
	{
		garage_id: 71,
		part_id: 239,
		quantity: 483,
	},
	{
		garage_id: 71,
		part_id: 240,
		quantity: 58,
	},
	{
		garage_id: 71,
		part_id: 241,
		quantity: 216,
	},
	{
		garage_id: 71,
		part_id: 242,
		quantity: 320,
	},
	{
		garage_id: 71,
		part_id: 243,
		quantity: 201,
	},
	{
		garage_id: 71,
		part_id: 244,
		quantity: 157,
	},
	{
		garage_id: 71,
		part_id: 245,
		quantity: 11,
	},
	{
		garage_id: 71,
		part_id: 246,
		quantity: 199,
	},
	{
		garage_id: 71,
		part_id: 247,
		quantity: 130,
	},
	{
		garage_id: 71,
		part_id: 248,
		quantity: 44,
	},
	{
		garage_id: 71,
		part_id: 249,
		quantity: 346,
	},
	{
		garage_id: 71,
		part_id: 250,
		quantity: 471,
	},
	{
		garage_id: 71,
		part_id: 251,
		quantity: 451,
	},
	{
		garage_id: 71,
		part_id: 252,
		quantity: 101,
	},
	{
		garage_id: 71,
		part_id: 253,
		quantity: 105,
	},
	{
		garage_id: 71,
		part_id: 254,
		quantity: 213,
	},
	{
		garage_id: 71,
		part_id: 255,
		quantity: 499,
	},
	{
		garage_id: 71,
		part_id: 256,
		quantity: 241,
	},
	{
		garage_id: 71,
		part_id: 257,
		quantity: 316,
	},
	{
		garage_id: 71,
		part_id: 258,
		quantity: 343,
	},
	{
		garage_id: 71,
		part_id: 259,
		quantity: 347,
	},
	{
		garage_id: 71,
		part_id: 260,
		quantity: 235,
	},
	{
		garage_id: 71,
		part_id: 261,
		quantity: 93,
	},
	{
		garage_id: 71,
		part_id: 262,
		quantity: 343,
	},
	{
		garage_id: 71,
		part_id: 263,
		quantity: 248,
	},
	{
		garage_id: 71,
		part_id: 264,
		quantity: 353,
	},
	{
		garage_id: 71,
		part_id: 265,
		quantity: 145,
	},
	{
		garage_id: 71,
		part_id: 266,
		quantity: 110,
	},
	{
		garage_id: 71,
		part_id: 267,
		quantity: 182,
	},
	{
		garage_id: 71,
		part_id: 268,
		quantity: 485,
	},
	{
		garage_id: 71,
		part_id: 269,
		quantity: 39,
	},
	{
		garage_id: 71,
		part_id: 270,
		quantity: 433,
	},
	{
		garage_id: 71,
		part_id: 271,
		quantity: 434,
	},
	{
		garage_id: 71,
		part_id: 272,
		quantity: 43,
	},
	{
		garage_id: 71,
		part_id: 273,
		quantity: 119,
	},
	{
		garage_id: 71,
		part_id: 274,
		quantity: 332,
	},
	{
		garage_id: 71,
		part_id: 275,
		quantity: 336,
	},
	{
		garage_id: 71,
		part_id: 276,
		quantity: 63,
	},
	{
		garage_id: 71,
		part_id: 277,
		quantity: 461,
	},
	{
		garage_id: 71,
		part_id: 278,
		quantity: 290,
	},
	{
		garage_id: 71,
		part_id: 279,
		quantity: 12,
	},
	{
		garage_id: 71,
		part_id: 280,
		quantity: 358,
	},
	{
		garage_id: 71,
		part_id: 281,
		quantity: 217,
	},
	{
		garage_id: 71,
		part_id: 282,
		quantity: 79,
	},
	{
		garage_id: 71,
		part_id: 283,
		quantity: 140,
	},
	{
		garage_id: 71,
		part_id: 284,
		quantity: 158,
	},
	{
		garage_id: 71,
		part_id: 285,
		quantity: 0,
	},
	{
		garage_id: 71,
		part_id: 286,
		quantity: 432,
	},
	{
		garage_id: 71,
		part_id: 287,
		quantity: 451,
	},
	{
		garage_id: 71,
		part_id: 288,
		quantity: 141,
	},
	{
		garage_id: 71,
		part_id: 289,
		quantity: 121,
	},
	{
		garage_id: 71,
		part_id: 290,
		quantity: 329,
	},
	{
		garage_id: 71,
		part_id: 291,
		quantity: 233,
	},
	{
		garage_id: 71,
		part_id: 292,
		quantity: 115,
	},
	{
		garage_id: 71,
		part_id: 293,
		quantity: 275,
	},
	{
		garage_id: 71,
		part_id: 294,
		quantity: 48,
	},
	{
		garage_id: 71,
		part_id: 295,
		quantity: 252,
	},
	{
		garage_id: 71,
		part_id: 296,
		quantity: 330,
	},
	{
		garage_id: 71,
		part_id: 297,
		quantity: 262,
	},
	{
		garage_id: 71,
		part_id: 298,
		quantity: 124,
	},
	{
		garage_id: 71,
		part_id: 299,
		quantity: 238,
	},
	{
		garage_id: 71,
		part_id: 300,
		quantity: 93,
	},
	{
		garage_id: 71,
		part_id: 301,
		quantity: 473,
	},
	{
		garage_id: 71,
		part_id: 302,
		quantity: 227,
	},
	{
		garage_id: 71,
		part_id: 303,
		quantity: 323,
	},
	{
		garage_id: 71,
		part_id: 304,
		quantity: 27,
	},
	{
		garage_id: 71,
		part_id: 305,
		quantity: 146,
	},
	{
		garage_id: 71,
		part_id: 306,
		quantity: 143,
	},
	{
		garage_id: 71,
		part_id: 307,
		quantity: 252,
	},
	{
		garage_id: 71,
		part_id: 308,
		quantity: 72,
	},
	{
		garage_id: 71,
		part_id: 309,
		quantity: 482,
	},
	{
		garage_id: 71,
		part_id: 310,
		quantity: 332,
	},
	{
		garage_id: 71,
		part_id: 311,
		quantity: 492,
	},
	{
		garage_id: 71,
		part_id: 312,
		quantity: 48,
	},
	{
		garage_id: 71,
		part_id: 313,
		quantity: 14,
	},
	{
		garage_id: 71,
		part_id: 314,
		quantity: 378,
	},
	{
		garage_id: 71,
		part_id: 315,
		quantity: 40,
	},
	{
		garage_id: 71,
		part_id: 316,
		quantity: 138,
	},
	{
		garage_id: 71,
		part_id: 317,
		quantity: 318,
	},
	{
		garage_id: 71,
		part_id: 318,
		quantity: 106,
	},
	{
		garage_id: 71,
		part_id: 319,
		quantity: 286,
	},
	{
		garage_id: 71,
		part_id: 320,
		quantity: 6,
	},
	{
		garage_id: 71,
		part_id: 321,
		quantity: 12,
	},
	{
		garage_id: 71,
		part_id: 322,
		quantity: 284,
	},
	{
		garage_id: 71,
		part_id: 323,
		quantity: 298,
	},
	{
		garage_id: 71,
		part_id: 324,
		quantity: 411,
	},
	{
		garage_id: 71,
		part_id: 325,
		quantity: 8,
	},
	{
		garage_id: 71,
		part_id: 326,
		quantity: 361,
	},
	{
		garage_id: 71,
		part_id: 327,
		quantity: 354,
	},
	{
		garage_id: 71,
		part_id: 328,
		quantity: 472,
	},
	{
		garage_id: 71,
		part_id: 329,
		quantity: 196,
	},
	{
		garage_id: 71,
		part_id: 330,
		quantity: 418,
	},
	{
		garage_id: 71,
		part_id: 331,
		quantity: 211,
	},
	{
		garage_id: 71,
		part_id: 332,
		quantity: 0,
	},
	{
		garage_id: 71,
		part_id: 333,
		quantity: 471,
	},
	{
		garage_id: 71,
		part_id: 334,
		quantity: 221,
	},
	{
		garage_id: 71,
		part_id: 335,
		quantity: 419,
	},
	{
		garage_id: 71,
		part_id: 336,
		quantity: 180,
	},
	{
		garage_id: 71,
		part_id: 337,
		quantity: 429,
	},
	{
		garage_id: 71,
		part_id: 338,
		quantity: 252,
	},
	{
		garage_id: 71,
		part_id: 339,
		quantity: 349,
	},
	{
		garage_id: 71,
		part_id: 340,
		quantity: 7,
	},
	{
		garage_id: 71,
		part_id: 341,
		quantity: 269,
	},
	{
		garage_id: 71,
		part_id: 342,
		quantity: 269,
	},
	{
		garage_id: 71,
		part_id: 343,
		quantity: 411,
	},
	{
		garage_id: 71,
		part_id: 344,
		quantity: 130,
	},
	{
		garage_id: 71,
		part_id: 345,
		quantity: 55,
	},
	{
		garage_id: 71,
		part_id: 346,
		quantity: 355,
	},
	{
		garage_id: 71,
		part_id: 347,
		quantity: 98,
	},
	{
		garage_id: 71,
		part_id: 348,
		quantity: 330,
	},
	{
		garage_id: 71,
		part_id: 349,
		quantity: 415,
	},
	{
		garage_id: 71,
		part_id: 350,
		quantity: 193,
	},
	{
		garage_id: 71,
		part_id: 351,
		quantity: 50,
	},
	{
		garage_id: 71,
		part_id: 352,
		quantity: 26,
	},
	{
		garage_id: 71,
		part_id: 353,
		quantity: 182,
	},
	{
		garage_id: 71,
		part_id: 354,
		quantity: 318,
	},
	{
		garage_id: 71,
		part_id: 355,
		quantity: 454,
	},
	{
		garage_id: 71,
		part_id: 356,
		quantity: 334,
	},
	{
		garage_id: 71,
		part_id: 357,
		quantity: 401,
	},
	{
		garage_id: 71,
		part_id: 358,
		quantity: 324,
	},
	{
		garage_id: 71,
		part_id: 359,
		quantity: 449,
	},
	{
		garage_id: 71,
		part_id: 360,
		quantity: 14,
	},
	{
		garage_id: 71,
		part_id: 361,
		quantity: 73,
	},
	{
		garage_id: 71,
		part_id: 362,
		quantity: 270,
	},
	{
		garage_id: 71,
		part_id: 363,
		quantity: 11,
	},
	{
		garage_id: 71,
		part_id: 364,
		quantity: 76,
	},
	{
		garage_id: 71,
		part_id: 365,
		quantity: 495,
	},
	{
		garage_id: 71,
		part_id: 366,
		quantity: 136,
	},
	{
		garage_id: 71,
		part_id: 367,
		quantity: 20,
	},
	{
		garage_id: 71,
		part_id: 368,
		quantity: 49,
	},
	{
		garage_id: 71,
		part_id: 369,
		quantity: 298,
	},
	{
		garage_id: 71,
		part_id: 370,
		quantity: 174,
	},
	{
		garage_id: 71,
		part_id: 371,
		quantity: 62,
	},
	{
		garage_id: 71,
		part_id: 372,
		quantity: 165,
	},
	{
		garage_id: 71,
		part_id: 373,
		quantity: 263,
	},
	{
		garage_id: 71,
		part_id: 374,
		quantity: 316,
	},
	{
		garage_id: 71,
		part_id: 375,
		quantity: 60,
	},
	{
		garage_id: 71,
		part_id: 376,
		quantity: 304,
	},
	{
		garage_id: 71,
		part_id: 377,
		quantity: 142,
	},
	{
		garage_id: 71,
		part_id: 378,
		quantity: 483,
	},
	{
		garage_id: 71,
		part_id: 379,
		quantity: 211,
	},
	{
		garage_id: 71,
		part_id: 380,
		quantity: 240,
	},
	{
		garage_id: 71,
		part_id: 381,
		quantity: 292,
	},
	{
		garage_id: 71,
		part_id: 382,
		quantity: 192,
	},
	{
		garage_id: 71,
		part_id: 383,
		quantity: 405,
	},
	{
		garage_id: 71,
		part_id: 384,
		quantity: 163,
	},
	{
		garage_id: 71,
		part_id: 385,
		quantity: 31,
	},
	{
		garage_id: 71,
		part_id: 386,
		quantity: 209,
	},
	{
		garage_id: 71,
		part_id: 387,
		quantity: 106,
	},
	{
		garage_id: 71,
		part_id: 388,
		quantity: 252,
	},
	{
		garage_id: 71,
		part_id: 389,
		quantity: 377,
	},
	{
		garage_id: 71,
		part_id: 390,
		quantity: 277,
	},
	{
		garage_id: 71,
		part_id: 391,
		quantity: 302,
	},
	{
		garage_id: 71,
		part_id: 392,
		quantity: 177,
	},
	{
		garage_id: 71,
		part_id: 393,
		quantity: 413,
	},
	{
		garage_id: 71,
		part_id: 394,
		quantity: 384,
	},
	{
		garage_id: 71,
		part_id: 395,
		quantity: 369,
	},
	{
		garage_id: 71,
		part_id: 396,
		quantity: 81,
	},
	{
		garage_id: 71,
		part_id: 397,
		quantity: 171,
	},
	{
		garage_id: 71,
		part_id: 398,
		quantity: 68,
	},
	{
		garage_id: 71,
		part_id: 399,
		quantity: 395,
	},
	{
		garage_id: 72,
		part_id: 0,
		quantity: 247,
	},
	{
		garage_id: 72,
		part_id: 1,
		quantity: 198,
	},
	{
		garage_id: 72,
		part_id: 2,
		quantity: 306,
	},
	{
		garage_id: 72,
		part_id: 3,
		quantity: 450,
	},
	{
		garage_id: 72,
		part_id: 4,
		quantity: 253,
	},
	{
		garage_id: 72,
		part_id: 5,
		quantity: 80,
	},
	{
		garage_id: 72,
		part_id: 6,
		quantity: 412,
	},
	{
		garage_id: 72,
		part_id: 7,
		quantity: 39,
	},
	{
		garage_id: 72,
		part_id: 8,
		quantity: 409,
	},
	{
		garage_id: 72,
		part_id: 9,
		quantity: 9,
	},
	{
		garage_id: 72,
		part_id: 10,
		quantity: 201,
	},
	{
		garage_id: 72,
		part_id: 11,
		quantity: 337,
	},
	{
		garage_id: 72,
		part_id: 12,
		quantity: 428,
	},
	{
		garage_id: 72,
		part_id: 13,
		quantity: 191,
	},
	{
		garage_id: 72,
		part_id: 14,
		quantity: 142,
	},
	{
		garage_id: 72,
		part_id: 15,
		quantity: 119,
	},
	{
		garage_id: 72,
		part_id: 16,
		quantity: 223,
	},
	{
		garage_id: 72,
		part_id: 17,
		quantity: 89,
	},
	{
		garage_id: 72,
		part_id: 18,
		quantity: 490,
	},
	{
		garage_id: 72,
		part_id: 19,
		quantity: 390,
	},
	{
		garage_id: 72,
		part_id: 20,
		quantity: 144,
	},
	{
		garage_id: 72,
		part_id: 21,
		quantity: 242,
	},
	{
		garage_id: 72,
		part_id: 22,
		quantity: 103,
	},
	{
		garage_id: 72,
		part_id: 23,
		quantity: 140,
	},
	{
		garage_id: 72,
		part_id: 24,
		quantity: 447,
	},
	{
		garage_id: 72,
		part_id: 25,
		quantity: 170,
	},
	{
		garage_id: 72,
		part_id: 26,
		quantity: 14,
	},
	{
		garage_id: 72,
		part_id: 27,
		quantity: 134,
	},
	{
		garage_id: 72,
		part_id: 28,
		quantity: 456,
	},
	{
		garage_id: 72,
		part_id: 29,
		quantity: 61,
	},
	{
		garage_id: 72,
		part_id: 30,
		quantity: 457,
	},
	{
		garage_id: 72,
		part_id: 31,
		quantity: 30,
	},
	{
		garage_id: 72,
		part_id: 32,
		quantity: 120,
	},
	{
		garage_id: 72,
		part_id: 33,
		quantity: 90,
	},
	{
		garage_id: 72,
		part_id: 34,
		quantity: 300,
	},
	{
		garage_id: 72,
		part_id: 35,
		quantity: 100,
	},
	{
		garage_id: 72,
		part_id: 36,
		quantity: 135,
	},
	{
		garage_id: 72,
		part_id: 37,
		quantity: 399,
	},
	{
		garage_id: 72,
		part_id: 38,
		quantity: 71,
	},
	{
		garage_id: 72,
		part_id: 39,
		quantity: 421,
	},
	{
		garage_id: 72,
		part_id: 40,
		quantity: 364,
	},
	{
		garage_id: 72,
		part_id: 41,
		quantity: 115,
	},
	{
		garage_id: 72,
		part_id: 42,
		quantity: 418,
	},
	{
		garage_id: 72,
		part_id: 43,
		quantity: 110,
	},
	{
		garage_id: 72,
		part_id: 44,
		quantity: 188,
	},
	{
		garage_id: 72,
		part_id: 45,
		quantity: 185,
	},
	{
		garage_id: 72,
		part_id: 46,
		quantity: 342,
	},
	{
		garage_id: 72,
		part_id: 47,
		quantity: 345,
	},
	{
		garage_id: 72,
		part_id: 48,
		quantity: 47,
	},
	{
		garage_id: 72,
		part_id: 49,
		quantity: 289,
	},
	{
		garage_id: 72,
		part_id: 50,
		quantity: 229,
	},
	{
		garage_id: 72,
		part_id: 51,
		quantity: 220,
	},
	{
		garage_id: 72,
		part_id: 52,
		quantity: 283,
	},
	{
		garage_id: 72,
		part_id: 53,
		quantity: 246,
	},
	{
		garage_id: 72,
		part_id: 54,
		quantity: 119,
	},
	{
		garage_id: 72,
		part_id: 55,
		quantity: 456,
	},
	{
		garage_id: 72,
		part_id: 56,
		quantity: 340,
	},
	{
		garage_id: 72,
		part_id: 57,
		quantity: 188,
	},
	{
		garage_id: 72,
		part_id: 58,
		quantity: 353,
	},
	{
		garage_id: 72,
		part_id: 59,
		quantity: 22,
	},
	{
		garage_id: 72,
		part_id: 60,
		quantity: 121,
	},
	{
		garage_id: 72,
		part_id: 61,
		quantity: 161,
	},
	{
		garage_id: 72,
		part_id: 62,
		quantity: 388,
	},
	{
		garage_id: 72,
		part_id: 63,
		quantity: 261,
	},
	{
		garage_id: 72,
		part_id: 64,
		quantity: 484,
	},
	{
		garage_id: 72,
		part_id: 65,
		quantity: 42,
	},
	{
		garage_id: 72,
		part_id: 66,
		quantity: 164,
	},
	{
		garage_id: 72,
		part_id: 67,
		quantity: 184,
	},
	{
		garage_id: 72,
		part_id: 68,
		quantity: 100,
	},
	{
		garage_id: 72,
		part_id: 69,
		quantity: 470,
	},
	{
		garage_id: 72,
		part_id: 70,
		quantity: 409,
	},
	{
		garage_id: 72,
		part_id: 71,
		quantity: 170,
	},
	{
		garage_id: 72,
		part_id: 72,
		quantity: 155,
	},
	{
		garage_id: 72,
		part_id: 73,
		quantity: 332,
	},
	{
		garage_id: 72,
		part_id: 74,
		quantity: 178,
	},
	{
		garage_id: 72,
		part_id: 75,
		quantity: 455,
	},
	{
		garage_id: 72,
		part_id: 76,
		quantity: 261,
	},
	{
		garage_id: 72,
		part_id: 77,
		quantity: 228,
	},
	{
		garage_id: 72,
		part_id: 78,
		quantity: 224,
	},
	{
		garage_id: 72,
		part_id: 79,
		quantity: 42,
	},
	{
		garage_id: 72,
		part_id: 80,
		quantity: 442,
	},
	{
		garage_id: 72,
		part_id: 81,
		quantity: 29,
	},
	{
		garage_id: 72,
		part_id: 82,
		quantity: 143,
	},
	{
		garage_id: 72,
		part_id: 83,
		quantity: 150,
	},
	{
		garage_id: 72,
		part_id: 84,
		quantity: 316,
	},
	{
		garage_id: 72,
		part_id: 85,
		quantity: 417,
	},
	{
		garage_id: 72,
		part_id: 86,
		quantity: 500,
	},
	{
		garage_id: 72,
		part_id: 87,
		quantity: 232,
	},
	{
		garage_id: 72,
		part_id: 88,
		quantity: 291,
	},
	{
		garage_id: 72,
		part_id: 89,
		quantity: 274,
	},
	{
		garage_id: 72,
		part_id: 90,
		quantity: 426,
	},
	{
		garage_id: 72,
		part_id: 91,
		quantity: 171,
	},
	{
		garage_id: 72,
		part_id: 92,
		quantity: 471,
	},
	{
		garage_id: 72,
		part_id: 93,
		quantity: 289,
	},
	{
		garage_id: 72,
		part_id: 94,
		quantity: 330,
	},
	{
		garage_id: 72,
		part_id: 95,
		quantity: 70,
	},
	{
		garage_id: 72,
		part_id: 96,
		quantity: 93,
	},
	{
		garage_id: 72,
		part_id: 97,
		quantity: 179,
	},
	{
		garage_id: 72,
		part_id: 98,
		quantity: 384,
	},
	{
		garage_id: 72,
		part_id: 99,
		quantity: 349,
	},
	{
		garage_id: 72,
		part_id: 100,
		quantity: 495,
	},
	{
		garage_id: 72,
		part_id: 101,
		quantity: 191,
	},
	{
		garage_id: 72,
		part_id: 102,
		quantity: 185,
	},
	{
		garage_id: 72,
		part_id: 103,
		quantity: 480,
	},
	{
		garage_id: 72,
		part_id: 104,
		quantity: 100,
	},
	{
		garage_id: 72,
		part_id: 105,
		quantity: 64,
	},
	{
		garage_id: 72,
		part_id: 106,
		quantity: 288,
	},
	{
		garage_id: 72,
		part_id: 107,
		quantity: 25,
	},
	{
		garage_id: 72,
		part_id: 108,
		quantity: 278,
	},
	{
		garage_id: 72,
		part_id: 109,
		quantity: 351,
	},
	{
		garage_id: 72,
		part_id: 110,
		quantity: 291,
	},
	{
		garage_id: 72,
		part_id: 111,
		quantity: 352,
	},
	{
		garage_id: 72,
		part_id: 112,
		quantity: 404,
	},
	{
		garage_id: 72,
		part_id: 113,
		quantity: 487,
	},
	{
		garage_id: 72,
		part_id: 114,
		quantity: 333,
	},
	{
		garage_id: 72,
		part_id: 115,
		quantity: 468,
	},
	{
		garage_id: 72,
		part_id: 116,
		quantity: 195,
	},
	{
		garage_id: 72,
		part_id: 117,
		quantity: 196,
	},
	{
		garage_id: 72,
		part_id: 118,
		quantity: 308,
	},
	{
		garage_id: 72,
		part_id: 119,
		quantity: 362,
	},
	{
		garage_id: 72,
		part_id: 120,
		quantity: 421,
	},
	{
		garage_id: 72,
		part_id: 121,
		quantity: 15,
	},
	{
		garage_id: 72,
		part_id: 122,
		quantity: 394,
	},
	{
		garage_id: 72,
		part_id: 123,
		quantity: 393,
	},
	{
		garage_id: 72,
		part_id: 124,
		quantity: 63,
	},
	{
		garage_id: 72,
		part_id: 125,
		quantity: 82,
	},
	{
		garage_id: 72,
		part_id: 126,
		quantity: 168,
	},
	{
		garage_id: 72,
		part_id: 127,
		quantity: 286,
	},
	{
		garage_id: 72,
		part_id: 128,
		quantity: 36,
	},
	{
		garage_id: 72,
		part_id: 129,
		quantity: 490,
	},
	{
		garage_id: 72,
		part_id: 130,
		quantity: 223,
	},
	{
		garage_id: 72,
		part_id: 131,
		quantity: 5,
	},
	{
		garage_id: 72,
		part_id: 132,
		quantity: 166,
	},
	{
		garage_id: 72,
		part_id: 133,
		quantity: 447,
	},
	{
		garage_id: 72,
		part_id: 134,
		quantity: 183,
	},
	{
		garage_id: 72,
		part_id: 135,
		quantity: 326,
	},
	{
		garage_id: 72,
		part_id: 136,
		quantity: 424,
	},
	{
		garage_id: 72,
		part_id: 137,
		quantity: 442,
	},
	{
		garage_id: 72,
		part_id: 138,
		quantity: 136,
	},
	{
		garage_id: 72,
		part_id: 139,
		quantity: 189,
	},
	{
		garage_id: 72,
		part_id: 140,
		quantity: 237,
	},
	{
		garage_id: 72,
		part_id: 141,
		quantity: 138,
	},
	{
		garage_id: 72,
		part_id: 142,
		quantity: 203,
	},
	{
		garage_id: 72,
		part_id: 143,
		quantity: 163,
	},
	{
		garage_id: 72,
		part_id: 144,
		quantity: 443,
	},
	{
		garage_id: 72,
		part_id: 145,
		quantity: 173,
	},
	{
		garage_id: 72,
		part_id: 146,
		quantity: 436,
	},
	{
		garage_id: 72,
		part_id: 147,
		quantity: 374,
	},
	{
		garage_id: 72,
		part_id: 148,
		quantity: 470,
	},
	{
		garage_id: 72,
		part_id: 149,
		quantity: 273,
	},
	{
		garage_id: 72,
		part_id: 150,
		quantity: 275,
	},
	{
		garage_id: 72,
		part_id: 151,
		quantity: 409,
	},
	{
		garage_id: 72,
		part_id: 152,
		quantity: 196,
	},
	{
		garage_id: 72,
		part_id: 153,
		quantity: 232,
	},
	{
		garage_id: 72,
		part_id: 154,
		quantity: 441,
	},
	{
		garage_id: 72,
		part_id: 155,
		quantity: 297,
	},
	{
		garage_id: 72,
		part_id: 156,
		quantity: 237,
	},
	{
		garage_id: 72,
		part_id: 157,
		quantity: 260,
	},
	{
		garage_id: 72,
		part_id: 158,
		quantity: 237,
	},
	{
		garage_id: 72,
		part_id: 159,
		quantity: 302,
	},
	{
		garage_id: 72,
		part_id: 160,
		quantity: 431,
	},
	{
		garage_id: 72,
		part_id: 161,
		quantity: 182,
	},
	{
		garage_id: 72,
		part_id: 162,
		quantity: 292,
	},
	{
		garage_id: 72,
		part_id: 163,
		quantity: 450,
	},
	{
		garage_id: 72,
		part_id: 164,
		quantity: 98,
	},
	{
		garage_id: 72,
		part_id: 165,
		quantity: 137,
	},
	{
		garage_id: 72,
		part_id: 166,
		quantity: 300,
	},
	{
		garage_id: 72,
		part_id: 167,
		quantity: 437,
	},
	{
		garage_id: 72,
		part_id: 168,
		quantity: 341,
	},
	{
		garage_id: 72,
		part_id: 169,
		quantity: 91,
	},
	{
		garage_id: 72,
		part_id: 170,
		quantity: 22,
	},
	{
		garage_id: 72,
		part_id: 171,
		quantity: 314,
	},
	{
		garage_id: 72,
		part_id: 172,
		quantity: 234,
	},
	{
		garage_id: 72,
		part_id: 173,
		quantity: 422,
	},
	{
		garage_id: 72,
		part_id: 174,
		quantity: 263,
	},
	{
		garage_id: 72,
		part_id: 175,
		quantity: 73,
	},
	{
		garage_id: 72,
		part_id: 176,
		quantity: 134,
	},
	{
		garage_id: 72,
		part_id: 177,
		quantity: 319,
	},
	{
		garage_id: 72,
		part_id: 178,
		quantity: 364,
	},
	{
		garage_id: 72,
		part_id: 179,
		quantity: 29,
	},
	{
		garage_id: 72,
		part_id: 180,
		quantity: 155,
	},
	{
		garage_id: 72,
		part_id: 181,
		quantity: 356,
	},
	{
		garage_id: 72,
		part_id: 182,
		quantity: 96,
	},
	{
		garage_id: 72,
		part_id: 183,
		quantity: 147,
	},
	{
		garage_id: 72,
		part_id: 184,
		quantity: 293,
	},
	{
		garage_id: 72,
		part_id: 185,
		quantity: 106,
	},
	{
		garage_id: 72,
		part_id: 186,
		quantity: 163,
	},
	{
		garage_id: 72,
		part_id: 187,
		quantity: 395,
	},
	{
		garage_id: 72,
		part_id: 188,
		quantity: 20,
	},
	{
		garage_id: 72,
		part_id: 189,
		quantity: 88,
	},
	{
		garage_id: 72,
		part_id: 190,
		quantity: 86,
	},
	{
		garage_id: 72,
		part_id: 191,
		quantity: 463,
	},
	{
		garage_id: 72,
		part_id: 192,
		quantity: 275,
	},
	{
		garage_id: 72,
		part_id: 193,
		quantity: 5,
	},
	{
		garage_id: 72,
		part_id: 194,
		quantity: 295,
	},
	{
		garage_id: 72,
		part_id: 195,
		quantity: 218,
	},
	{
		garage_id: 72,
		part_id: 196,
		quantity: 133,
	},
	{
		garage_id: 72,
		part_id: 197,
		quantity: 47,
	},
	{
		garage_id: 72,
		part_id: 198,
		quantity: 269,
	},
	{
		garage_id: 72,
		part_id: 199,
		quantity: 214,
	},
	{
		garage_id: 72,
		part_id: 200,
		quantity: 493,
	},
	{
		garage_id: 72,
		part_id: 201,
		quantity: 120,
	},
	{
		garage_id: 72,
		part_id: 202,
		quantity: 204,
	},
	{
		garage_id: 72,
		part_id: 203,
		quantity: 230,
	},
	{
		garage_id: 72,
		part_id: 204,
		quantity: 413,
	},
	{
		garage_id: 72,
		part_id: 205,
		quantity: 104,
	},
	{
		garage_id: 72,
		part_id: 206,
		quantity: 224,
	},
	{
		garage_id: 72,
		part_id: 207,
		quantity: 494,
	},
	{
		garage_id: 72,
		part_id: 208,
		quantity: 453,
	},
	{
		garage_id: 72,
		part_id: 209,
		quantity: 225,
	},
	{
		garage_id: 72,
		part_id: 210,
		quantity: 293,
	},
	{
		garage_id: 72,
		part_id: 211,
		quantity: 101,
	},
	{
		garage_id: 72,
		part_id: 212,
		quantity: 179,
	},
	{
		garage_id: 72,
		part_id: 213,
		quantity: 408,
	},
	{
		garage_id: 72,
		part_id: 214,
		quantity: 148,
	},
	{
		garage_id: 72,
		part_id: 215,
		quantity: 278,
	},
	{
		garage_id: 72,
		part_id: 216,
		quantity: 297,
	},
	{
		garage_id: 72,
		part_id: 217,
		quantity: 127,
	},
	{
		garage_id: 72,
		part_id: 218,
		quantity: 8,
	},
	{
		garage_id: 72,
		part_id: 219,
		quantity: 250,
	},
	{
		garage_id: 72,
		part_id: 220,
		quantity: 9,
	},
	{
		garage_id: 72,
		part_id: 221,
		quantity: 470,
	},
	{
		garage_id: 72,
		part_id: 222,
		quantity: 315,
	},
	{
		garage_id: 72,
		part_id: 223,
		quantity: 432,
	},
	{
		garage_id: 72,
		part_id: 224,
		quantity: 4,
	},
	{
		garage_id: 72,
		part_id: 225,
		quantity: 422,
	},
	{
		garage_id: 72,
		part_id: 226,
		quantity: 323,
	},
	{
		garage_id: 72,
		part_id: 227,
		quantity: 0,
	},
	{
		garage_id: 72,
		part_id: 228,
		quantity: 455,
	},
	{
		garage_id: 72,
		part_id: 229,
		quantity: 478,
	},
	{
		garage_id: 72,
		part_id: 230,
		quantity: 399,
	},
	{
		garage_id: 72,
		part_id: 231,
		quantity: 155,
	},
	{
		garage_id: 72,
		part_id: 232,
		quantity: 305,
	},
	{
		garage_id: 72,
		part_id: 233,
		quantity: 59,
	},
	{
		garage_id: 72,
		part_id: 234,
		quantity: 88,
	},
	{
		garage_id: 72,
		part_id: 235,
		quantity: 136,
	},
	{
		garage_id: 72,
		part_id: 236,
		quantity: 57,
	},
	{
		garage_id: 72,
		part_id: 237,
		quantity: 79,
	},
	{
		garage_id: 72,
		part_id: 238,
		quantity: 326,
	},
	{
		garage_id: 72,
		part_id: 239,
		quantity: 268,
	},
	{
		garage_id: 72,
		part_id: 240,
		quantity: 24,
	},
	{
		garage_id: 72,
		part_id: 241,
		quantity: 138,
	},
	{
		garage_id: 72,
		part_id: 242,
		quantity: 441,
	},
	{
		garage_id: 72,
		part_id: 243,
		quantity: 285,
	},
	{
		garage_id: 72,
		part_id: 244,
		quantity: 335,
	},
	{
		garage_id: 72,
		part_id: 245,
		quantity: 379,
	},
	{
		garage_id: 72,
		part_id: 246,
		quantity: 302,
	},
	{
		garage_id: 72,
		part_id: 247,
		quantity: 168,
	},
	{
		garage_id: 72,
		part_id: 248,
		quantity: 453,
	},
	{
		garage_id: 72,
		part_id: 249,
		quantity: 239,
	},
	{
		garage_id: 72,
		part_id: 250,
		quantity: 267,
	},
	{
		garage_id: 72,
		part_id: 251,
		quantity: 397,
	},
	{
		garage_id: 72,
		part_id: 252,
		quantity: 126,
	},
	{
		garage_id: 72,
		part_id: 253,
		quantity: 62,
	},
	{
		garage_id: 72,
		part_id: 254,
		quantity: 201,
	},
	{
		garage_id: 72,
		part_id: 255,
		quantity: 303,
	},
	{
		garage_id: 72,
		part_id: 256,
		quantity: 236,
	},
	{
		garage_id: 72,
		part_id: 257,
		quantity: 293,
	},
	{
		garage_id: 72,
		part_id: 258,
		quantity: 426,
	},
	{
		garage_id: 72,
		part_id: 259,
		quantity: 409,
	},
	{
		garage_id: 72,
		part_id: 260,
		quantity: 341,
	},
	{
		garage_id: 72,
		part_id: 261,
		quantity: 69,
	},
	{
		garage_id: 72,
		part_id: 262,
		quantity: 86,
	},
	{
		garage_id: 72,
		part_id: 263,
		quantity: 126,
	},
	{
		garage_id: 72,
		part_id: 264,
		quantity: 2,
	},
	{
		garage_id: 72,
		part_id: 265,
		quantity: 394,
	},
	{
		garage_id: 72,
		part_id: 266,
		quantity: 255,
	},
	{
		garage_id: 72,
		part_id: 267,
		quantity: 172,
	},
	{
		garage_id: 72,
		part_id: 268,
		quantity: 78,
	},
	{
		garage_id: 72,
		part_id: 269,
		quantity: 407,
	},
	{
		garage_id: 72,
		part_id: 270,
		quantity: 223,
	},
	{
		garage_id: 72,
		part_id: 271,
		quantity: 174,
	},
	{
		garage_id: 72,
		part_id: 272,
		quantity: 481,
	},
	{
		garage_id: 72,
		part_id: 273,
		quantity: 259,
	},
	{
		garage_id: 72,
		part_id: 274,
		quantity: 69,
	},
	{
		garage_id: 72,
		part_id: 275,
		quantity: 372,
	},
	{
		garage_id: 72,
		part_id: 276,
		quantity: 68,
	},
	{
		garage_id: 72,
		part_id: 277,
		quantity: 335,
	},
	{
		garage_id: 72,
		part_id: 278,
		quantity: 266,
	},
	{
		garage_id: 72,
		part_id: 279,
		quantity: 22,
	},
	{
		garage_id: 72,
		part_id: 280,
		quantity: 218,
	},
	{
		garage_id: 72,
		part_id: 281,
		quantity: 361,
	},
	{
		garage_id: 72,
		part_id: 282,
		quantity: 145,
	},
	{
		garage_id: 72,
		part_id: 283,
		quantity: 446,
	},
	{
		garage_id: 72,
		part_id: 284,
		quantity: 351,
	},
	{
		garage_id: 72,
		part_id: 285,
		quantity: 82,
	},
	{
		garage_id: 72,
		part_id: 286,
		quantity: 11,
	},
	{
		garage_id: 72,
		part_id: 287,
		quantity: 159,
	},
	{
		garage_id: 72,
		part_id: 288,
		quantity: 407,
	},
	{
		garage_id: 72,
		part_id: 289,
		quantity: 98,
	},
	{
		garage_id: 72,
		part_id: 290,
		quantity: 127,
	},
	{
		garage_id: 72,
		part_id: 291,
		quantity: 425,
	},
	{
		garage_id: 72,
		part_id: 292,
		quantity: 222,
	},
	{
		garage_id: 72,
		part_id: 293,
		quantity: 470,
	},
	{
		garage_id: 72,
		part_id: 294,
		quantity: 77,
	},
	{
		garage_id: 72,
		part_id: 295,
		quantity: 71,
	},
	{
		garage_id: 72,
		part_id: 296,
		quantity: 52,
	},
	{
		garage_id: 72,
		part_id: 297,
		quantity: 141,
	},
	{
		garage_id: 72,
		part_id: 298,
		quantity: 312,
	},
	{
		garage_id: 72,
		part_id: 299,
		quantity: 471,
	},
	{
		garage_id: 72,
		part_id: 300,
		quantity: 479,
	},
	{
		garage_id: 72,
		part_id: 301,
		quantity: 408,
	},
	{
		garage_id: 72,
		part_id: 302,
		quantity: 382,
	},
	{
		garage_id: 72,
		part_id: 303,
		quantity: 147,
	},
	{
		garage_id: 72,
		part_id: 304,
		quantity: 19,
	},
	{
		garage_id: 72,
		part_id: 305,
		quantity: 221,
	},
	{
		garage_id: 72,
		part_id: 306,
		quantity: 302,
	},
	{
		garage_id: 72,
		part_id: 307,
		quantity: 281,
	},
	{
		garage_id: 72,
		part_id: 308,
		quantity: 422,
	},
	{
		garage_id: 72,
		part_id: 309,
		quantity: 457,
	},
	{
		garage_id: 72,
		part_id: 310,
		quantity: 264,
	},
	{
		garage_id: 72,
		part_id: 311,
		quantity: 452,
	},
	{
		garage_id: 72,
		part_id: 312,
		quantity: 115,
	},
	{
		garage_id: 72,
		part_id: 313,
		quantity: 177,
	},
	{
		garage_id: 72,
		part_id: 314,
		quantity: 64,
	},
	{
		garage_id: 72,
		part_id: 315,
		quantity: 4,
	},
	{
		garage_id: 72,
		part_id: 316,
		quantity: 12,
	},
	{
		garage_id: 72,
		part_id: 317,
		quantity: 350,
	},
	{
		garage_id: 72,
		part_id: 318,
		quantity: 450,
	},
	{
		garage_id: 72,
		part_id: 319,
		quantity: 236,
	},
	{
		garage_id: 72,
		part_id: 320,
		quantity: 279,
	},
	{
		garage_id: 72,
		part_id: 321,
		quantity: 430,
	},
	{
		garage_id: 72,
		part_id: 322,
		quantity: 194,
	},
	{
		garage_id: 72,
		part_id: 323,
		quantity: 264,
	},
	{
		garage_id: 72,
		part_id: 324,
		quantity: 247,
	},
	{
		garage_id: 72,
		part_id: 325,
		quantity: 419,
	},
	{
		garage_id: 72,
		part_id: 326,
		quantity: 389,
	},
	{
		garage_id: 72,
		part_id: 327,
		quantity: 92,
	},
	{
		garage_id: 72,
		part_id: 328,
		quantity: 438,
	},
	{
		garage_id: 72,
		part_id: 329,
		quantity: 427,
	},
	{
		garage_id: 72,
		part_id: 330,
		quantity: 18,
	},
	{
		garage_id: 72,
		part_id: 331,
		quantity: 115,
	},
	{
		garage_id: 72,
		part_id: 332,
		quantity: 85,
	},
	{
		garage_id: 72,
		part_id: 333,
		quantity: 478,
	},
	{
		garage_id: 72,
		part_id: 334,
		quantity: 421,
	},
	{
		garage_id: 72,
		part_id: 335,
		quantity: 48,
	},
	{
		garage_id: 72,
		part_id: 336,
		quantity: 143,
	},
	{
		garage_id: 72,
		part_id: 337,
		quantity: 424,
	},
	{
		garage_id: 72,
		part_id: 338,
		quantity: 127,
	},
	{
		garage_id: 72,
		part_id: 339,
		quantity: 344,
	},
	{
		garage_id: 72,
		part_id: 340,
		quantity: 184,
	},
	{
		garage_id: 72,
		part_id: 341,
		quantity: 322,
	},
	{
		garage_id: 72,
		part_id: 342,
		quantity: 170,
	},
	{
		garage_id: 72,
		part_id: 343,
		quantity: 447,
	},
	{
		garage_id: 72,
		part_id: 344,
		quantity: 175,
	},
	{
		garage_id: 72,
		part_id: 345,
		quantity: 39,
	},
	{
		garage_id: 72,
		part_id: 346,
		quantity: 314,
	},
	{
		garage_id: 72,
		part_id: 347,
		quantity: 287,
	},
	{
		garage_id: 72,
		part_id: 348,
		quantity: 69,
	},
	{
		garage_id: 72,
		part_id: 349,
		quantity: 65,
	},
	{
		garage_id: 72,
		part_id: 350,
		quantity: 32,
	},
	{
		garage_id: 72,
		part_id: 351,
		quantity: 350,
	},
	{
		garage_id: 72,
		part_id: 352,
		quantity: 323,
	},
	{
		garage_id: 72,
		part_id: 353,
		quantity: 217,
	},
	{
		garage_id: 72,
		part_id: 354,
		quantity: 456,
	},
	{
		garage_id: 72,
		part_id: 355,
		quantity: 454,
	},
	{
		garage_id: 72,
		part_id: 356,
		quantity: 255,
	},
	{
		garage_id: 72,
		part_id: 357,
		quantity: 405,
	},
	{
		garage_id: 72,
		part_id: 358,
		quantity: 91,
	},
	{
		garage_id: 72,
		part_id: 359,
		quantity: 207,
	},
	{
		garage_id: 72,
		part_id: 360,
		quantity: 456,
	},
	{
		garage_id: 72,
		part_id: 361,
		quantity: 99,
	},
	{
		garage_id: 72,
		part_id: 362,
		quantity: 485,
	},
	{
		garage_id: 72,
		part_id: 363,
		quantity: 340,
	},
	{
		garage_id: 72,
		part_id: 364,
		quantity: 77,
	},
	{
		garage_id: 72,
		part_id: 365,
		quantity: 401,
	},
	{
		garage_id: 72,
		part_id: 366,
		quantity: 164,
	},
	{
		garage_id: 72,
		part_id: 367,
		quantity: 29,
	},
	{
		garage_id: 72,
		part_id: 368,
		quantity: 478,
	},
	{
		garage_id: 72,
		part_id: 369,
		quantity: 96,
	},
	{
		garage_id: 72,
		part_id: 370,
		quantity: 87,
	},
	{
		garage_id: 72,
		part_id: 371,
		quantity: 160,
	},
	{
		garage_id: 72,
		part_id: 372,
		quantity: 12,
	},
	{
		garage_id: 72,
		part_id: 373,
		quantity: 248,
	},
	{
		garage_id: 72,
		part_id: 374,
		quantity: 91,
	},
	{
		garage_id: 72,
		part_id: 375,
		quantity: 467,
	},
	{
		garage_id: 72,
		part_id: 376,
		quantity: 249,
	},
	{
		garage_id: 72,
		part_id: 377,
		quantity: 37,
	},
	{
		garage_id: 72,
		part_id: 378,
		quantity: 269,
	},
	{
		garage_id: 72,
		part_id: 379,
		quantity: 17,
	},
	{
		garage_id: 72,
		part_id: 380,
		quantity: 391,
	},
	{
		garage_id: 72,
		part_id: 381,
		quantity: 301,
	},
	{
		garage_id: 72,
		part_id: 382,
		quantity: 270,
	},
	{
		garage_id: 72,
		part_id: 383,
		quantity: 131,
	},
	{
		garage_id: 72,
		part_id: 384,
		quantity: 428,
	},
	{
		garage_id: 72,
		part_id: 385,
		quantity: 442,
	},
	{
		garage_id: 72,
		part_id: 386,
		quantity: 214,
	},
	{
		garage_id: 72,
		part_id: 387,
		quantity: 332,
	},
	{
		garage_id: 72,
		part_id: 388,
		quantity: 414,
	},
	{
		garage_id: 72,
		part_id: 389,
		quantity: 306,
	},
	{
		garage_id: 72,
		part_id: 390,
		quantity: 457,
	},
	{
		garage_id: 72,
		part_id: 391,
		quantity: 464,
	},
	{
		garage_id: 72,
		part_id: 392,
		quantity: 350,
	},
	{
		garage_id: 72,
		part_id: 393,
		quantity: 366,
	},
	{
		garage_id: 72,
		part_id: 394,
		quantity: 300,
	},
	{
		garage_id: 72,
		part_id: 395,
		quantity: 251,
	},
	{
		garage_id: 72,
		part_id: 396,
		quantity: 473,
	},
	{
		garage_id: 72,
		part_id: 397,
		quantity: 306,
	},
	{
		garage_id: 72,
		part_id: 398,
		quantity: 103,
	},
	{
		garage_id: 72,
		part_id: 399,
		quantity: 315,
	},
	{
		garage_id: 73,
		part_id: 0,
		quantity: 275,
	},
	{
		garage_id: 73,
		part_id: 1,
		quantity: 392,
	},
	{
		garage_id: 73,
		part_id: 2,
		quantity: 74,
	},
	{
		garage_id: 73,
		part_id: 3,
		quantity: 426,
	},
	{
		garage_id: 73,
		part_id: 4,
		quantity: 271,
	},
	{
		garage_id: 73,
		part_id: 5,
		quantity: 365,
	},
	{
		garage_id: 73,
		part_id: 6,
		quantity: 440,
	},
	{
		garage_id: 73,
		part_id: 7,
		quantity: 406,
	},
	{
		garage_id: 73,
		part_id: 8,
		quantity: 22,
	},
	{
		garage_id: 73,
		part_id: 9,
		quantity: 85,
	},
	{
		garage_id: 73,
		part_id: 10,
		quantity: 168,
	},
	{
		garage_id: 73,
		part_id: 11,
		quantity: 212,
	},
	{
		garage_id: 73,
		part_id: 12,
		quantity: 68,
	},
	{
		garage_id: 73,
		part_id: 13,
		quantity: 430,
	},
	{
		garage_id: 73,
		part_id: 14,
		quantity: 204,
	},
	{
		garage_id: 73,
		part_id: 15,
		quantity: 64,
	},
	{
		garage_id: 73,
		part_id: 16,
		quantity: 116,
	},
	{
		garage_id: 73,
		part_id: 17,
		quantity: 367,
	},
	{
		garage_id: 73,
		part_id: 18,
		quantity: 409,
	},
	{
		garage_id: 73,
		part_id: 19,
		quantity: 226,
	},
	{
		garage_id: 73,
		part_id: 20,
		quantity: 203,
	},
	{
		garage_id: 73,
		part_id: 21,
		quantity: 80,
	},
	{
		garage_id: 73,
		part_id: 22,
		quantity: 484,
	},
	{
		garage_id: 73,
		part_id: 23,
		quantity: 295,
	},
	{
		garage_id: 73,
		part_id: 24,
		quantity: 345,
	},
	{
		garage_id: 73,
		part_id: 25,
		quantity: 192,
	},
	{
		garage_id: 73,
		part_id: 26,
		quantity: 426,
	},
	{
		garage_id: 73,
		part_id: 27,
		quantity: 165,
	},
	{
		garage_id: 73,
		part_id: 28,
		quantity: 414,
	},
	{
		garage_id: 73,
		part_id: 29,
		quantity: 162,
	},
	{
		garage_id: 73,
		part_id: 30,
		quantity: 268,
	},
	{
		garage_id: 73,
		part_id: 31,
		quantity: 42,
	},
	{
		garage_id: 73,
		part_id: 32,
		quantity: 480,
	},
	{
		garage_id: 73,
		part_id: 33,
		quantity: 254,
	},
	{
		garage_id: 73,
		part_id: 34,
		quantity: 147,
	},
	{
		garage_id: 73,
		part_id: 35,
		quantity: 359,
	},
	{
		garage_id: 73,
		part_id: 36,
		quantity: 426,
	},
	{
		garage_id: 73,
		part_id: 37,
		quantity: 452,
	},
	{
		garage_id: 73,
		part_id: 38,
		quantity: 428,
	},
	{
		garage_id: 73,
		part_id: 39,
		quantity: 78,
	},
	{
		garage_id: 73,
		part_id: 40,
		quantity: 476,
	},
	{
		garage_id: 73,
		part_id: 41,
		quantity: 139,
	},
	{
		garage_id: 73,
		part_id: 42,
		quantity: 491,
	},
	{
		garage_id: 73,
		part_id: 43,
		quantity: 377,
	},
	{
		garage_id: 73,
		part_id: 44,
		quantity: 133,
	},
	{
		garage_id: 73,
		part_id: 45,
		quantity: 59,
	},
	{
		garage_id: 73,
		part_id: 46,
		quantity: 205,
	},
	{
		garage_id: 73,
		part_id: 47,
		quantity: 187,
	},
	{
		garage_id: 73,
		part_id: 48,
		quantity: 258,
	},
	{
		garage_id: 73,
		part_id: 49,
		quantity: 195,
	},
	{
		garage_id: 73,
		part_id: 50,
		quantity: 126,
	},
	{
		garage_id: 73,
		part_id: 51,
		quantity: 375,
	},
	{
		garage_id: 73,
		part_id: 52,
		quantity: 156,
	},
	{
		garage_id: 73,
		part_id: 53,
		quantity: 139,
	},
	{
		garage_id: 73,
		part_id: 54,
		quantity: 381,
	},
	{
		garage_id: 73,
		part_id: 55,
		quantity: 369,
	},
	{
		garage_id: 73,
		part_id: 56,
		quantity: 250,
	},
	{
		garage_id: 73,
		part_id: 57,
		quantity: 378,
	},
	{
		garage_id: 73,
		part_id: 58,
		quantity: 276,
	},
	{
		garage_id: 73,
		part_id: 59,
		quantity: 126,
	},
	{
		garage_id: 73,
		part_id: 60,
		quantity: 92,
	},
	{
		garage_id: 73,
		part_id: 61,
		quantity: 207,
	},
	{
		garage_id: 73,
		part_id: 62,
		quantity: 343,
	},
	{
		garage_id: 73,
		part_id: 63,
		quantity: 87,
	},
	{
		garage_id: 73,
		part_id: 64,
		quantity: 132,
	},
	{
		garage_id: 73,
		part_id: 65,
		quantity: 308,
	},
	{
		garage_id: 73,
		part_id: 66,
		quantity: 306,
	},
	{
		garage_id: 73,
		part_id: 67,
		quantity: 32,
	},
	{
		garage_id: 73,
		part_id: 68,
		quantity: 156,
	},
	{
		garage_id: 73,
		part_id: 69,
		quantity: 494,
	},
	{
		garage_id: 73,
		part_id: 70,
		quantity: 129,
	},
	{
		garage_id: 73,
		part_id: 71,
		quantity: 98,
	},
	{
		garage_id: 73,
		part_id: 72,
		quantity: 30,
	},
	{
		garage_id: 73,
		part_id: 73,
		quantity: 39,
	},
	{
		garage_id: 73,
		part_id: 74,
		quantity: 157,
	},
	{
		garage_id: 73,
		part_id: 75,
		quantity: 419,
	},
	{
		garage_id: 73,
		part_id: 76,
		quantity: 98,
	},
	{
		garage_id: 73,
		part_id: 77,
		quantity: 283,
	},
	{
		garage_id: 73,
		part_id: 78,
		quantity: 253,
	},
	{
		garage_id: 73,
		part_id: 79,
		quantity: 434,
	},
	{
		garage_id: 73,
		part_id: 80,
		quantity: 213,
	},
	{
		garage_id: 73,
		part_id: 81,
		quantity: 113,
	},
	{
		garage_id: 73,
		part_id: 82,
		quantity: 397,
	},
	{
		garage_id: 73,
		part_id: 83,
		quantity: 393,
	},
	{
		garage_id: 73,
		part_id: 84,
		quantity: 204,
	},
	{
		garage_id: 73,
		part_id: 85,
		quantity: 424,
	},
	{
		garage_id: 73,
		part_id: 86,
		quantity: 155,
	},
	{
		garage_id: 73,
		part_id: 87,
		quantity: 235,
	},
	{
		garage_id: 73,
		part_id: 88,
		quantity: 215,
	},
	{
		garage_id: 73,
		part_id: 89,
		quantity: 157,
	},
	{
		garage_id: 73,
		part_id: 90,
		quantity: 498,
	},
	{
		garage_id: 73,
		part_id: 91,
		quantity: 123,
	},
	{
		garage_id: 73,
		part_id: 92,
		quantity: 398,
	},
	{
		garage_id: 73,
		part_id: 93,
		quantity: 294,
	},
	{
		garage_id: 73,
		part_id: 94,
		quantity: 6,
	},
	{
		garage_id: 73,
		part_id: 95,
		quantity: 169,
	},
	{
		garage_id: 73,
		part_id: 96,
		quantity: 251,
	},
	{
		garage_id: 73,
		part_id: 97,
		quantity: 159,
	},
	{
		garage_id: 73,
		part_id: 98,
		quantity: 321,
	},
	{
		garage_id: 73,
		part_id: 99,
		quantity: 320,
	},
	{
		garage_id: 73,
		part_id: 100,
		quantity: 143,
	},
	{
		garage_id: 73,
		part_id: 101,
		quantity: 176,
	},
	{
		garage_id: 73,
		part_id: 102,
		quantity: 288,
	},
	{
		garage_id: 73,
		part_id: 103,
		quantity: 429,
	},
	{
		garage_id: 73,
		part_id: 104,
		quantity: 325,
	},
	{
		garage_id: 73,
		part_id: 105,
		quantity: 368,
	},
	{
		garage_id: 73,
		part_id: 106,
		quantity: 91,
	},
	{
		garage_id: 73,
		part_id: 107,
		quantity: 97,
	},
	{
		garage_id: 73,
		part_id: 108,
		quantity: 349,
	},
	{
		garage_id: 73,
		part_id: 109,
		quantity: 109,
	},
	{
		garage_id: 73,
		part_id: 110,
		quantity: 116,
	},
	{
		garage_id: 73,
		part_id: 111,
		quantity: 223,
	},
	{
		garage_id: 73,
		part_id: 112,
		quantity: 355,
	},
	{
		garage_id: 73,
		part_id: 113,
		quantity: 128,
	},
	{
		garage_id: 73,
		part_id: 114,
		quantity: 377,
	},
	{
		garage_id: 73,
		part_id: 115,
		quantity: 334,
	},
	{
		garage_id: 73,
		part_id: 116,
		quantity: 481,
	},
	{
		garage_id: 73,
		part_id: 117,
		quantity: 41,
	},
	{
		garage_id: 73,
		part_id: 118,
		quantity: 302,
	},
	{
		garage_id: 73,
		part_id: 119,
		quantity: 19,
	},
	{
		garage_id: 73,
		part_id: 120,
		quantity: 310,
	},
	{
		garage_id: 73,
		part_id: 121,
		quantity: 106,
	},
	{
		garage_id: 73,
		part_id: 122,
		quantity: 233,
	},
	{
		garage_id: 73,
		part_id: 123,
		quantity: 1,
	},
	{
		garage_id: 73,
		part_id: 124,
		quantity: 126,
	},
	{
		garage_id: 73,
		part_id: 125,
		quantity: 2,
	},
	{
		garage_id: 73,
		part_id: 126,
		quantity: 176,
	},
	{
		garage_id: 73,
		part_id: 127,
		quantity: 486,
	},
	{
		garage_id: 73,
		part_id: 128,
		quantity: 4,
	},
	{
		garage_id: 73,
		part_id: 129,
		quantity: 42,
	},
	{
		garage_id: 73,
		part_id: 130,
		quantity: 343,
	},
	{
		garage_id: 73,
		part_id: 131,
		quantity: 20,
	},
	{
		garage_id: 73,
		part_id: 132,
		quantity: 200,
	},
	{
		garage_id: 73,
		part_id: 133,
		quantity: 470,
	},
	{
		garage_id: 73,
		part_id: 134,
		quantity: 37,
	},
	{
		garage_id: 73,
		part_id: 135,
		quantity: 50,
	},
	{
		garage_id: 73,
		part_id: 136,
		quantity: 90,
	},
	{
		garage_id: 73,
		part_id: 137,
		quantity: 274,
	},
	{
		garage_id: 73,
		part_id: 138,
		quantity: 459,
	},
	{
		garage_id: 73,
		part_id: 139,
		quantity: 464,
	},
	{
		garage_id: 73,
		part_id: 140,
		quantity: 312,
	},
	{
		garage_id: 73,
		part_id: 141,
		quantity: 170,
	},
	{
		garage_id: 73,
		part_id: 142,
		quantity: 48,
	},
	{
		garage_id: 73,
		part_id: 143,
		quantity: 192,
	},
	{
		garage_id: 73,
		part_id: 144,
		quantity: 292,
	},
	{
		garage_id: 73,
		part_id: 145,
		quantity: 425,
	},
	{
		garage_id: 73,
		part_id: 146,
		quantity: 234,
	},
	{
		garage_id: 73,
		part_id: 147,
		quantity: 266,
	},
	{
		garage_id: 73,
		part_id: 148,
		quantity: 369,
	},
	{
		garage_id: 73,
		part_id: 149,
		quantity: 49,
	},
	{
		garage_id: 73,
		part_id: 150,
		quantity: 221,
	},
	{
		garage_id: 73,
		part_id: 151,
		quantity: 260,
	},
	{
		garage_id: 73,
		part_id: 152,
		quantity: 211,
	},
	{
		garage_id: 73,
		part_id: 153,
		quantity: 144,
	},
	{
		garage_id: 73,
		part_id: 154,
		quantity: 238,
	},
	{
		garage_id: 73,
		part_id: 155,
		quantity: 68,
	},
	{
		garage_id: 73,
		part_id: 156,
		quantity: 23,
	},
	{
		garage_id: 73,
		part_id: 157,
		quantity: 458,
	},
	{
		garage_id: 73,
		part_id: 158,
		quantity: 279,
	},
	{
		garage_id: 73,
		part_id: 159,
		quantity: 39,
	},
	{
		garage_id: 73,
		part_id: 160,
		quantity: 234,
	},
	{
		garage_id: 73,
		part_id: 161,
		quantity: 483,
	},
	{
		garage_id: 73,
		part_id: 162,
		quantity: 64,
	},
	{
		garage_id: 73,
		part_id: 163,
		quantity: 69,
	},
	{
		garage_id: 73,
		part_id: 164,
		quantity: 383,
	},
	{
		garage_id: 73,
		part_id: 165,
		quantity: 85,
	},
	{
		garage_id: 73,
		part_id: 166,
		quantity: 488,
	},
	{
		garage_id: 73,
		part_id: 167,
		quantity: 105,
	},
	{
		garage_id: 73,
		part_id: 168,
		quantity: 47,
	},
	{
		garage_id: 73,
		part_id: 169,
		quantity: 187,
	},
	{
		garage_id: 73,
		part_id: 170,
		quantity: 382,
	},
	{
		garage_id: 73,
		part_id: 171,
		quantity: 193,
	},
	{
		garage_id: 73,
		part_id: 172,
		quantity: 264,
	},
	{
		garage_id: 73,
		part_id: 173,
		quantity: 434,
	},
	{
		garage_id: 73,
		part_id: 174,
		quantity: 241,
	},
	{
		garage_id: 73,
		part_id: 175,
		quantity: 155,
	},
	{
		garage_id: 73,
		part_id: 176,
		quantity: 64,
	},
	{
		garage_id: 73,
		part_id: 177,
		quantity: 103,
	},
	{
		garage_id: 73,
		part_id: 178,
		quantity: 400,
	},
	{
		garage_id: 73,
		part_id: 179,
		quantity: 427,
	},
	{
		garage_id: 73,
		part_id: 180,
		quantity: 70,
	},
	{
		garage_id: 73,
		part_id: 181,
		quantity: 278,
	},
	{
		garage_id: 73,
		part_id: 182,
		quantity: 58,
	},
	{
		garage_id: 73,
		part_id: 183,
		quantity: 67,
	},
	{
		garage_id: 73,
		part_id: 184,
		quantity: 267,
	},
	{
		garage_id: 73,
		part_id: 185,
		quantity: 96,
	},
	{
		garage_id: 73,
		part_id: 186,
		quantity: 404,
	},
	{
		garage_id: 73,
		part_id: 187,
		quantity: 53,
	},
	{
		garage_id: 73,
		part_id: 188,
		quantity: 419,
	},
	{
		garage_id: 73,
		part_id: 189,
		quantity: 257,
	},
	{
		garage_id: 73,
		part_id: 190,
		quantity: 135,
	},
	{
		garage_id: 73,
		part_id: 191,
		quantity: 151,
	},
	{
		garage_id: 73,
		part_id: 192,
		quantity: 161,
	},
	{
		garage_id: 73,
		part_id: 193,
		quantity: 489,
	},
	{
		garage_id: 73,
		part_id: 194,
		quantity: 195,
	},
	{
		garage_id: 73,
		part_id: 195,
		quantity: 411,
	},
	{
		garage_id: 73,
		part_id: 196,
		quantity: 466,
	},
	{
		garage_id: 73,
		part_id: 197,
		quantity: 290,
	},
	{
		garage_id: 73,
		part_id: 198,
		quantity: 414,
	},
	{
		garage_id: 73,
		part_id: 199,
		quantity: 491,
	},
	{
		garage_id: 73,
		part_id: 200,
		quantity: 422,
	},
	{
		garage_id: 73,
		part_id: 201,
		quantity: 144,
	},
	{
		garage_id: 73,
		part_id: 202,
		quantity: 73,
	},
	{
		garage_id: 73,
		part_id: 203,
		quantity: 399,
	},
	{
		garage_id: 73,
		part_id: 204,
		quantity: 187,
	},
	{
		garage_id: 73,
		part_id: 205,
		quantity: 399,
	},
	{
		garage_id: 73,
		part_id: 206,
		quantity: 258,
	},
	{
		garage_id: 73,
		part_id: 207,
		quantity: 500,
	},
	{
		garage_id: 73,
		part_id: 208,
		quantity: 93,
	},
	{
		garage_id: 73,
		part_id: 209,
		quantity: 64,
	},
	{
		garage_id: 73,
		part_id: 210,
		quantity: 340,
	},
	{
		garage_id: 73,
		part_id: 211,
		quantity: 193,
	},
	{
		garage_id: 73,
		part_id: 212,
		quantity: 158,
	},
	{
		garage_id: 73,
		part_id: 213,
		quantity: 311,
	},
	{
		garage_id: 73,
		part_id: 214,
		quantity: 239,
	},
	{
		garage_id: 73,
		part_id: 215,
		quantity: 437,
	},
	{
		garage_id: 73,
		part_id: 216,
		quantity: 355,
	},
	{
		garage_id: 73,
		part_id: 217,
		quantity: 350,
	},
	{
		garage_id: 73,
		part_id: 218,
		quantity: 359,
	},
	{
		garage_id: 73,
		part_id: 219,
		quantity: 38,
	},
	{
		garage_id: 73,
		part_id: 220,
		quantity: 437,
	},
	{
		garage_id: 73,
		part_id: 221,
		quantity: 484,
	},
	{
		garage_id: 73,
		part_id: 222,
		quantity: 1,
	},
	{
		garage_id: 73,
		part_id: 223,
		quantity: 344,
	},
	{
		garage_id: 73,
		part_id: 224,
		quantity: 404,
	},
	{
		garage_id: 73,
		part_id: 225,
		quantity: 411,
	},
	{
		garage_id: 73,
		part_id: 226,
		quantity: 469,
	},
	{
		garage_id: 73,
		part_id: 227,
		quantity: 228,
	},
	{
		garage_id: 73,
		part_id: 228,
		quantity: 41,
	},
	{
		garage_id: 73,
		part_id: 229,
		quantity: 152,
	},
	{
		garage_id: 73,
		part_id: 230,
		quantity: 90,
	},
	{
		garage_id: 73,
		part_id: 231,
		quantity: 59,
	},
	{
		garage_id: 73,
		part_id: 232,
		quantity: 239,
	},
	{
		garage_id: 73,
		part_id: 233,
		quantity: 325,
	},
	{
		garage_id: 73,
		part_id: 234,
		quantity: 60,
	},
	{
		garage_id: 73,
		part_id: 235,
		quantity: 385,
	},
	{
		garage_id: 73,
		part_id: 236,
		quantity: 262,
	},
	{
		garage_id: 73,
		part_id: 237,
		quantity: 333,
	},
	{
		garage_id: 73,
		part_id: 238,
		quantity: 53,
	},
	{
		garage_id: 73,
		part_id: 239,
		quantity: 418,
	},
	{
		garage_id: 73,
		part_id: 240,
		quantity: 185,
	},
	{
		garage_id: 73,
		part_id: 241,
		quantity: 76,
	},
	{
		garage_id: 73,
		part_id: 242,
		quantity: 31,
	},
	{
		garage_id: 73,
		part_id: 243,
		quantity: 358,
	},
	{
		garage_id: 73,
		part_id: 244,
		quantity: 392,
	},
	{
		garage_id: 73,
		part_id: 245,
		quantity: 309,
	},
	{
		garage_id: 73,
		part_id: 246,
		quantity: 144,
	},
	{
		garage_id: 73,
		part_id: 247,
		quantity: 37,
	},
	{
		garage_id: 73,
		part_id: 248,
		quantity: 415,
	},
	{
		garage_id: 73,
		part_id: 249,
		quantity: 352,
	},
	{
		garage_id: 73,
		part_id: 250,
		quantity: 283,
	},
	{
		garage_id: 73,
		part_id: 251,
		quantity: 34,
	},
	{
		garage_id: 73,
		part_id: 252,
		quantity: 262,
	},
	{
		garage_id: 73,
		part_id: 253,
		quantity: 129,
	},
	{
		garage_id: 73,
		part_id: 254,
		quantity: 233,
	},
	{
		garage_id: 73,
		part_id: 255,
		quantity: 284,
	},
	{
		garage_id: 73,
		part_id: 256,
		quantity: 217,
	},
	{
		garage_id: 73,
		part_id: 257,
		quantity: 72,
	},
	{
		garage_id: 73,
		part_id: 258,
		quantity: 55,
	},
	{
		garage_id: 73,
		part_id: 259,
		quantity: 260,
	},
	{
		garage_id: 73,
		part_id: 260,
		quantity: 466,
	},
	{
		garage_id: 73,
		part_id: 261,
		quantity: 15,
	},
	{
		garage_id: 73,
		part_id: 262,
		quantity: 106,
	},
	{
		garage_id: 73,
		part_id: 263,
		quantity: 183,
	},
	{
		garage_id: 73,
		part_id: 264,
		quantity: 493,
	},
	{
		garage_id: 73,
		part_id: 265,
		quantity: 102,
	},
	{
		garage_id: 73,
		part_id: 266,
		quantity: 25,
	},
	{
		garage_id: 73,
		part_id: 267,
		quantity: 210,
	},
	{
		garage_id: 73,
		part_id: 268,
		quantity: 278,
	},
	{
		garage_id: 73,
		part_id: 269,
		quantity: 89,
	},
	{
		garage_id: 73,
		part_id: 270,
		quantity: 188,
	},
	{
		garage_id: 73,
		part_id: 271,
		quantity: 245,
	},
	{
		garage_id: 73,
		part_id: 272,
		quantity: 174,
	},
	{
		garage_id: 73,
		part_id: 273,
		quantity: 115,
	},
	{
		garage_id: 73,
		part_id: 274,
		quantity: 107,
	},
	{
		garage_id: 73,
		part_id: 275,
		quantity: 496,
	},
	{
		garage_id: 73,
		part_id: 276,
		quantity: 223,
	},
	{
		garage_id: 73,
		part_id: 277,
		quantity: 175,
	},
	{
		garage_id: 73,
		part_id: 278,
		quantity: 374,
	},
	{
		garage_id: 73,
		part_id: 279,
		quantity: 279,
	},
	{
		garage_id: 73,
		part_id: 280,
		quantity: 272,
	},
	{
		garage_id: 73,
		part_id: 281,
		quantity: 420,
	},
	{
		garage_id: 73,
		part_id: 282,
		quantity: 290,
	},
	{
		garage_id: 73,
		part_id: 283,
		quantity: 337,
	},
	{
		garage_id: 73,
		part_id: 284,
		quantity: 482,
	},
	{
		garage_id: 73,
		part_id: 285,
		quantity: 194,
	},
	{
		garage_id: 73,
		part_id: 286,
		quantity: 337,
	},
	{
		garage_id: 73,
		part_id: 287,
		quantity: 211,
	},
	{
		garage_id: 73,
		part_id: 288,
		quantity: 479,
	},
	{
		garage_id: 73,
		part_id: 289,
		quantity: 356,
	},
	{
		garage_id: 73,
		part_id: 290,
		quantity: 77,
	},
	{
		garage_id: 73,
		part_id: 291,
		quantity: 57,
	},
	{
		garage_id: 73,
		part_id: 292,
		quantity: 304,
	},
	{
		garage_id: 73,
		part_id: 293,
		quantity: 15,
	},
	{
		garage_id: 73,
		part_id: 294,
		quantity: 453,
	},
	{
		garage_id: 73,
		part_id: 295,
		quantity: 471,
	},
	{
		garage_id: 73,
		part_id: 296,
		quantity: 44,
	},
	{
		garage_id: 73,
		part_id: 297,
		quantity: 63,
	},
	{
		garage_id: 73,
		part_id: 298,
		quantity: 130,
	},
	{
		garage_id: 73,
		part_id: 299,
		quantity: 82,
	},
	{
		garage_id: 73,
		part_id: 300,
		quantity: 248,
	},
	{
		garage_id: 73,
		part_id: 301,
		quantity: 150,
	},
	{
		garage_id: 73,
		part_id: 302,
		quantity: 240,
	},
	{
		garage_id: 73,
		part_id: 303,
		quantity: 478,
	},
	{
		garage_id: 73,
		part_id: 304,
		quantity: 314,
	},
	{
		garage_id: 73,
		part_id: 305,
		quantity: 314,
	},
	{
		garage_id: 73,
		part_id: 306,
		quantity: 314,
	},
	{
		garage_id: 73,
		part_id: 307,
		quantity: 223,
	},
	{
		garage_id: 73,
		part_id: 308,
		quantity: 118,
	},
	{
		garage_id: 73,
		part_id: 309,
		quantity: 370,
	},
	{
		garage_id: 73,
		part_id: 310,
		quantity: 249,
	},
	{
		garage_id: 73,
		part_id: 311,
		quantity: 202,
	},
	{
		garage_id: 73,
		part_id: 312,
		quantity: 365,
	},
	{
		garage_id: 73,
		part_id: 313,
		quantity: 1,
	},
	{
		garage_id: 73,
		part_id: 314,
		quantity: 336,
	},
	{
		garage_id: 73,
		part_id: 315,
		quantity: 364,
	},
	{
		garage_id: 73,
		part_id: 316,
		quantity: 90,
	},
	{
		garage_id: 73,
		part_id: 317,
		quantity: 146,
	},
	{
		garage_id: 73,
		part_id: 318,
		quantity: 291,
	},
	{
		garage_id: 73,
		part_id: 319,
		quantity: 108,
	},
	{
		garage_id: 73,
		part_id: 320,
		quantity: 354,
	},
	{
		garage_id: 73,
		part_id: 321,
		quantity: 389,
	},
	{
		garage_id: 73,
		part_id: 322,
		quantity: 402,
	},
	{
		garage_id: 73,
		part_id: 323,
		quantity: 337,
	},
	{
		garage_id: 73,
		part_id: 324,
		quantity: 67,
	},
	{
		garage_id: 73,
		part_id: 325,
		quantity: 276,
	},
	{
		garage_id: 73,
		part_id: 326,
		quantity: 75,
	},
	{
		garage_id: 73,
		part_id: 327,
		quantity: 492,
	},
	{
		garage_id: 73,
		part_id: 328,
		quantity: 265,
	},
	{
		garage_id: 73,
		part_id: 329,
		quantity: 319,
	},
	{
		garage_id: 73,
		part_id: 330,
		quantity: 318,
	},
	{
		garage_id: 73,
		part_id: 331,
		quantity: 30,
	},
	{
		garage_id: 73,
		part_id: 332,
		quantity: 444,
	},
	{
		garage_id: 73,
		part_id: 333,
		quantity: 278,
	},
	{
		garage_id: 73,
		part_id: 334,
		quantity: 173,
	},
	{
		garage_id: 73,
		part_id: 335,
		quantity: 250,
	},
	{
		garage_id: 73,
		part_id: 336,
		quantity: 474,
	},
	{
		garage_id: 73,
		part_id: 337,
		quantity: 129,
	},
	{
		garage_id: 73,
		part_id: 338,
		quantity: 142,
	},
	{
		garage_id: 73,
		part_id: 339,
		quantity: 105,
	},
	{
		garage_id: 73,
		part_id: 340,
		quantity: 17,
	},
	{
		garage_id: 73,
		part_id: 341,
		quantity: 146,
	},
	{
		garage_id: 73,
		part_id: 342,
		quantity: 294,
	},
	{
		garage_id: 73,
		part_id: 343,
		quantity: 45,
	},
	{
		garage_id: 73,
		part_id: 344,
		quantity: 184,
	},
	{
		garage_id: 73,
		part_id: 345,
		quantity: 490,
	},
	{
		garage_id: 73,
		part_id: 346,
		quantity: 374,
	},
	{
		garage_id: 73,
		part_id: 347,
		quantity: 194,
	},
	{
		garage_id: 73,
		part_id: 348,
		quantity: 403,
	},
	{
		garage_id: 73,
		part_id: 349,
		quantity: 30,
	},
	{
		garage_id: 73,
		part_id: 350,
		quantity: 262,
	},
	{
		garage_id: 73,
		part_id: 351,
		quantity: 225,
	},
	{
		garage_id: 73,
		part_id: 352,
		quantity: 90,
	},
	{
		garage_id: 73,
		part_id: 353,
		quantity: 170,
	},
	{
		garage_id: 73,
		part_id: 354,
		quantity: 248,
	},
	{
		garage_id: 73,
		part_id: 355,
		quantity: 189,
	},
	{
		garage_id: 73,
		part_id: 356,
		quantity: 183,
	},
	{
		garage_id: 73,
		part_id: 357,
		quantity: 470,
	},
	{
		garage_id: 73,
		part_id: 358,
		quantity: 14,
	},
	{
		garage_id: 73,
		part_id: 359,
		quantity: 135,
	},
	{
		garage_id: 73,
		part_id: 360,
		quantity: 129,
	},
	{
		garage_id: 73,
		part_id: 361,
		quantity: 406,
	},
	{
		garage_id: 73,
		part_id: 362,
		quantity: 398,
	},
	{
		garage_id: 73,
		part_id: 363,
		quantity: 42,
	},
	{
		garage_id: 73,
		part_id: 364,
		quantity: 330,
	},
	{
		garage_id: 73,
		part_id: 365,
		quantity: 20,
	},
	{
		garage_id: 73,
		part_id: 366,
		quantity: 410,
	},
	{
		garage_id: 73,
		part_id: 367,
		quantity: 136,
	},
	{
		garage_id: 73,
		part_id: 368,
		quantity: 443,
	},
	{
		garage_id: 73,
		part_id: 369,
		quantity: 450,
	},
	{
		garage_id: 73,
		part_id: 370,
		quantity: 175,
	},
	{
		garage_id: 73,
		part_id: 371,
		quantity: 89,
	},
	{
		garage_id: 73,
		part_id: 372,
		quantity: 443,
	},
	{
		garage_id: 73,
		part_id: 373,
		quantity: 155,
	},
	{
		garage_id: 73,
		part_id: 374,
		quantity: 469,
	},
	{
		garage_id: 73,
		part_id: 375,
		quantity: 499,
	},
	{
		garage_id: 73,
		part_id: 376,
		quantity: 74,
	},
	{
		garage_id: 73,
		part_id: 377,
		quantity: 234,
	},
	{
		garage_id: 73,
		part_id: 378,
		quantity: 483,
	},
	{
		garage_id: 73,
		part_id: 379,
		quantity: 269,
	},
	{
		garage_id: 73,
		part_id: 380,
		quantity: 151,
	},
	{
		garage_id: 73,
		part_id: 381,
		quantity: 285,
	},
	{
		garage_id: 73,
		part_id: 382,
		quantity: 60,
	},
	{
		garage_id: 73,
		part_id: 383,
		quantity: 289,
	},
	{
		garage_id: 73,
		part_id: 384,
		quantity: 85,
	},
	{
		garage_id: 73,
		part_id: 385,
		quantity: 262,
	},
	{
		garage_id: 73,
		part_id: 386,
		quantity: 381,
	},
	{
		garage_id: 73,
		part_id: 387,
		quantity: 189,
	},
	{
		garage_id: 73,
		part_id: 388,
		quantity: 333,
	},
	{
		garage_id: 73,
		part_id: 389,
		quantity: 180,
	},
	{
		garage_id: 73,
		part_id: 390,
		quantity: 423,
	},
	{
		garage_id: 73,
		part_id: 391,
		quantity: 431,
	},
	{
		garage_id: 73,
		part_id: 392,
		quantity: 144,
	},
	{
		garage_id: 73,
		part_id: 393,
		quantity: 423,
	},
	{
		garage_id: 73,
		part_id: 394,
		quantity: 171,
	},
	{
		garage_id: 73,
		part_id: 395,
		quantity: 116,
	},
	{
		garage_id: 73,
		part_id: 396,
		quantity: 360,
	},
	{
		garage_id: 73,
		part_id: 397,
		quantity: 132,
	},
	{
		garage_id: 73,
		part_id: 398,
		quantity: 452,
	},
	{
		garage_id: 73,
		part_id: 399,
		quantity: 375,
	},
	{
		garage_id: 74,
		part_id: 0,
		quantity: 353,
	},
	{
		garage_id: 74,
		part_id: 1,
		quantity: 315,
	},
	{
		garage_id: 74,
		part_id: 2,
		quantity: 294,
	},
	{
		garage_id: 74,
		part_id: 3,
		quantity: 229,
	},
	{
		garage_id: 74,
		part_id: 4,
		quantity: 91,
	},
	{
		garage_id: 74,
		part_id: 5,
		quantity: 414,
	},
	{
		garage_id: 74,
		part_id: 6,
		quantity: 288,
	},
	{
		garage_id: 74,
		part_id: 7,
		quantity: 71,
	},
	{
		garage_id: 74,
		part_id: 8,
		quantity: 247,
	},
	{
		garage_id: 74,
		part_id: 9,
		quantity: 227,
	},
	{
		garage_id: 74,
		part_id: 10,
		quantity: 489,
	},
	{
		garage_id: 74,
		part_id: 11,
		quantity: 301,
	},
	{
		garage_id: 74,
		part_id: 12,
		quantity: 312,
	},
	{
		garage_id: 74,
		part_id: 13,
		quantity: 449,
	},
	{
		garage_id: 74,
		part_id: 14,
		quantity: 184,
	},
	{
		garage_id: 74,
		part_id: 15,
		quantity: 431,
	},
	{
		garage_id: 74,
		part_id: 16,
		quantity: 147,
	},
	{
		garage_id: 74,
		part_id: 17,
		quantity: 70,
	},
	{
		garage_id: 74,
		part_id: 18,
		quantity: 17,
	},
	{
		garage_id: 74,
		part_id: 19,
		quantity: 73,
	},
	{
		garage_id: 74,
		part_id: 20,
		quantity: 188,
	},
	{
		garage_id: 74,
		part_id: 21,
		quantity: 392,
	},
	{
		garage_id: 74,
		part_id: 22,
		quantity: 291,
	},
	{
		garage_id: 74,
		part_id: 23,
		quantity: 134,
	},
	{
		garage_id: 74,
		part_id: 24,
		quantity: 313,
	},
	{
		garage_id: 74,
		part_id: 25,
		quantity: 179,
	},
	{
		garage_id: 74,
		part_id: 26,
		quantity: 182,
	},
	{
		garage_id: 74,
		part_id: 27,
		quantity: 238,
	},
	{
		garage_id: 74,
		part_id: 28,
		quantity: 386,
	},
	{
		garage_id: 74,
		part_id: 29,
		quantity: 82,
	},
	{
		garage_id: 74,
		part_id: 30,
		quantity: 126,
	},
	{
		garage_id: 74,
		part_id: 31,
		quantity: 80,
	},
	{
		garage_id: 74,
		part_id: 32,
		quantity: 460,
	},
	{
		garage_id: 74,
		part_id: 33,
		quantity: 167,
	},
	{
		garage_id: 74,
		part_id: 34,
		quantity: 183,
	},
	{
		garage_id: 74,
		part_id: 35,
		quantity: 0,
	},
	{
		garage_id: 74,
		part_id: 36,
		quantity: 334,
	},
	{
		garage_id: 74,
		part_id: 37,
		quantity: 295,
	},
	{
		garage_id: 74,
		part_id: 38,
		quantity: 404,
	},
	{
		garage_id: 74,
		part_id: 39,
		quantity: 238,
	},
	{
		garage_id: 74,
		part_id: 40,
		quantity: 173,
	},
	{
		garage_id: 74,
		part_id: 41,
		quantity: 106,
	},
	{
		garage_id: 74,
		part_id: 42,
		quantity: 78,
	},
	{
		garage_id: 74,
		part_id: 43,
		quantity: 156,
	},
	{
		garage_id: 74,
		part_id: 44,
		quantity: 376,
	},
	{
		garage_id: 74,
		part_id: 45,
		quantity: 84,
	},
	{
		garage_id: 74,
		part_id: 46,
		quantity: 151,
	},
	{
		garage_id: 74,
		part_id: 47,
		quantity: 406,
	},
	{
		garage_id: 74,
		part_id: 48,
		quantity: 413,
	},
	{
		garage_id: 74,
		part_id: 49,
		quantity: 373,
	},
	{
		garage_id: 74,
		part_id: 50,
		quantity: 320,
	},
	{
		garage_id: 74,
		part_id: 51,
		quantity: 420,
	},
	{
		garage_id: 74,
		part_id: 52,
		quantity: 290,
	},
	{
		garage_id: 74,
		part_id: 53,
		quantity: 487,
	},
	{
		garage_id: 74,
		part_id: 54,
		quantity: 150,
	},
	{
		garage_id: 74,
		part_id: 55,
		quantity: 374,
	},
	{
		garage_id: 74,
		part_id: 56,
		quantity: 72,
	},
	{
		garage_id: 74,
		part_id: 57,
		quantity: 391,
	},
	{
		garage_id: 74,
		part_id: 58,
		quantity: 1,
	},
	{
		garage_id: 74,
		part_id: 59,
		quantity: 326,
	},
	{
		garage_id: 74,
		part_id: 60,
		quantity: 308,
	},
	{
		garage_id: 74,
		part_id: 61,
		quantity: 375,
	},
	{
		garage_id: 74,
		part_id: 62,
		quantity: 215,
	},
	{
		garage_id: 74,
		part_id: 63,
		quantity: 83,
	},
	{
		garage_id: 74,
		part_id: 64,
		quantity: 166,
	},
	{
		garage_id: 74,
		part_id: 65,
		quantity: 238,
	},
	{
		garage_id: 74,
		part_id: 66,
		quantity: 429,
	},
	{
		garage_id: 74,
		part_id: 67,
		quantity: 455,
	},
	{
		garage_id: 74,
		part_id: 68,
		quantity: 255,
	},
	{
		garage_id: 74,
		part_id: 69,
		quantity: 323,
	},
	{
		garage_id: 74,
		part_id: 70,
		quantity: 378,
	},
	{
		garage_id: 74,
		part_id: 71,
		quantity: 126,
	},
	{
		garage_id: 74,
		part_id: 72,
		quantity: 170,
	},
	{
		garage_id: 74,
		part_id: 73,
		quantity: 465,
	},
	{
		garage_id: 74,
		part_id: 74,
		quantity: 441,
	},
	{
		garage_id: 74,
		part_id: 75,
		quantity: 83,
	},
	{
		garage_id: 74,
		part_id: 76,
		quantity: 129,
	},
	{
		garage_id: 74,
		part_id: 77,
		quantity: 445,
	},
	{
		garage_id: 74,
		part_id: 78,
		quantity: 40,
	},
	{
		garage_id: 74,
		part_id: 79,
		quantity: 106,
	},
	{
		garage_id: 74,
		part_id: 80,
		quantity: 335,
	},
	{
		garage_id: 74,
		part_id: 81,
		quantity: 325,
	},
	{
		garage_id: 74,
		part_id: 82,
		quantity: 390,
	},
	{
		garage_id: 74,
		part_id: 83,
		quantity: 44,
	},
	{
		garage_id: 74,
		part_id: 84,
		quantity: 375,
	},
	{
		garage_id: 74,
		part_id: 85,
		quantity: 482,
	},
	{
		garage_id: 74,
		part_id: 86,
		quantity: 187,
	},
	{
		garage_id: 74,
		part_id: 87,
		quantity: 299,
	},
	{
		garage_id: 74,
		part_id: 88,
		quantity: 434,
	},
	{
		garage_id: 74,
		part_id: 89,
		quantity: 284,
	},
	{
		garage_id: 74,
		part_id: 90,
		quantity: 412,
	},
	{
		garage_id: 74,
		part_id: 91,
		quantity: 205,
	},
	{
		garage_id: 74,
		part_id: 92,
		quantity: 192,
	},
	{
		garage_id: 74,
		part_id: 93,
		quantity: 213,
	},
	{
		garage_id: 74,
		part_id: 94,
		quantity: 224,
	},
	{
		garage_id: 74,
		part_id: 95,
		quantity: 128,
	},
	{
		garage_id: 74,
		part_id: 96,
		quantity: 223,
	},
	{
		garage_id: 74,
		part_id: 97,
		quantity: 316,
	},
	{
		garage_id: 74,
		part_id: 98,
		quantity: 34,
	},
	{
		garage_id: 74,
		part_id: 99,
		quantity: 290,
	},
	{
		garage_id: 74,
		part_id: 100,
		quantity: 385,
	},
	{
		garage_id: 74,
		part_id: 101,
		quantity: 480,
	},
	{
		garage_id: 74,
		part_id: 102,
		quantity: 214,
	},
	{
		garage_id: 74,
		part_id: 103,
		quantity: 473,
	},
	{
		garage_id: 74,
		part_id: 104,
		quantity: 203,
	},
	{
		garage_id: 74,
		part_id: 105,
		quantity: 100,
	},
	{
		garage_id: 74,
		part_id: 106,
		quantity: 337,
	},
	{
		garage_id: 74,
		part_id: 107,
		quantity: 255,
	},
	{
		garage_id: 74,
		part_id: 108,
		quantity: 422,
	},
	{
		garage_id: 74,
		part_id: 109,
		quantity: 114,
	},
	{
		garage_id: 74,
		part_id: 110,
		quantity: 68,
	},
	{
		garage_id: 74,
		part_id: 111,
		quantity: 261,
	},
	{
		garage_id: 74,
		part_id: 112,
		quantity: 260,
	},
	{
		garage_id: 74,
		part_id: 113,
		quantity: 258,
	},
	{
		garage_id: 74,
		part_id: 114,
		quantity: 359,
	},
	{
		garage_id: 74,
		part_id: 115,
		quantity: 203,
	},
	{
		garage_id: 74,
		part_id: 116,
		quantity: 78,
	},
	{
		garage_id: 74,
		part_id: 117,
		quantity: 180,
	},
	{
		garage_id: 74,
		part_id: 118,
		quantity: 256,
	},
	{
		garage_id: 74,
		part_id: 119,
		quantity: 156,
	},
	{
		garage_id: 74,
		part_id: 120,
		quantity: 67,
	},
	{
		garage_id: 74,
		part_id: 121,
		quantity: 338,
	},
	{
		garage_id: 74,
		part_id: 122,
		quantity: 2,
	},
	{
		garage_id: 74,
		part_id: 123,
		quantity: 401,
	},
	{
		garage_id: 74,
		part_id: 124,
		quantity: 90,
	},
	{
		garage_id: 74,
		part_id: 125,
		quantity: 97,
	},
	{
		garage_id: 74,
		part_id: 126,
		quantity: 130,
	},
	{
		garage_id: 74,
		part_id: 127,
		quantity: 65,
	},
	{
		garage_id: 74,
		part_id: 128,
		quantity: 495,
	},
	{
		garage_id: 74,
		part_id: 129,
		quantity: 158,
	},
	{
		garage_id: 74,
		part_id: 130,
		quantity: 428,
	},
	{
		garage_id: 74,
		part_id: 131,
		quantity: 158,
	},
	{
		garage_id: 74,
		part_id: 132,
		quantity: 70,
	},
	{
		garage_id: 74,
		part_id: 133,
		quantity: 82,
	},
	{
		garage_id: 74,
		part_id: 134,
		quantity: 360,
	},
	{
		garage_id: 74,
		part_id: 135,
		quantity: 295,
	},
	{
		garage_id: 74,
		part_id: 136,
		quantity: 394,
	},
	{
		garage_id: 74,
		part_id: 137,
		quantity: 308,
	},
	{
		garage_id: 74,
		part_id: 138,
		quantity: 50,
	},
	{
		garage_id: 74,
		part_id: 139,
		quantity: 470,
	},
	{
		garage_id: 74,
		part_id: 140,
		quantity: 443,
	},
	{
		garage_id: 74,
		part_id: 141,
		quantity: 233,
	},
	{
		garage_id: 74,
		part_id: 142,
		quantity: 64,
	},
	{
		garage_id: 74,
		part_id: 143,
		quantity: 148,
	},
	{
		garage_id: 74,
		part_id: 144,
		quantity: 443,
	},
	{
		garage_id: 74,
		part_id: 145,
		quantity: 147,
	},
	{
		garage_id: 74,
		part_id: 146,
		quantity: 13,
	},
	{
		garage_id: 74,
		part_id: 147,
		quantity: 34,
	},
	{
		garage_id: 74,
		part_id: 148,
		quantity: 463,
	},
	{
		garage_id: 74,
		part_id: 149,
		quantity: 137,
	},
	{
		garage_id: 74,
		part_id: 150,
		quantity: 455,
	},
	{
		garage_id: 74,
		part_id: 151,
		quantity: 371,
	},
	{
		garage_id: 74,
		part_id: 152,
		quantity: 255,
	},
	{
		garage_id: 74,
		part_id: 153,
		quantity: 76,
	},
	{
		garage_id: 74,
		part_id: 154,
		quantity: 330,
	},
	{
		garage_id: 74,
		part_id: 155,
		quantity: 72,
	},
	{
		garage_id: 74,
		part_id: 156,
		quantity: 443,
	},
	{
		garage_id: 74,
		part_id: 157,
		quantity: 69,
	},
	{
		garage_id: 74,
		part_id: 158,
		quantity: 498,
	},
	{
		garage_id: 74,
		part_id: 159,
		quantity: 90,
	},
	{
		garage_id: 74,
		part_id: 160,
		quantity: 371,
	},
	{
		garage_id: 74,
		part_id: 161,
		quantity: 152,
	},
	{
		garage_id: 74,
		part_id: 162,
		quantity: 444,
	},
	{
		garage_id: 74,
		part_id: 163,
		quantity: 294,
	},
	{
		garage_id: 74,
		part_id: 164,
		quantity: 113,
	},
	{
		garage_id: 74,
		part_id: 165,
		quantity: 322,
	},
	{
		garage_id: 74,
		part_id: 166,
		quantity: 7,
	},
	{
		garage_id: 74,
		part_id: 167,
		quantity: 159,
	},
	{
		garage_id: 74,
		part_id: 168,
		quantity: 471,
	},
	{
		garage_id: 74,
		part_id: 169,
		quantity: 163,
	},
	{
		garage_id: 74,
		part_id: 170,
		quantity: 304,
	},
	{
		garage_id: 74,
		part_id: 171,
		quantity: 364,
	},
	{
		garage_id: 74,
		part_id: 172,
		quantity: 281,
	},
	{
		garage_id: 74,
		part_id: 173,
		quantity: 389,
	},
	{
		garage_id: 74,
		part_id: 174,
		quantity: 50,
	},
	{
		garage_id: 74,
		part_id: 175,
		quantity: 274,
	},
	{
		garage_id: 74,
		part_id: 176,
		quantity: 478,
	},
	{
		garage_id: 74,
		part_id: 177,
		quantity: 48,
	},
	{
		garage_id: 74,
		part_id: 178,
		quantity: 188,
	},
	{
		garage_id: 74,
		part_id: 179,
		quantity: 30,
	},
	{
		garage_id: 74,
		part_id: 180,
		quantity: 391,
	},
	{
		garage_id: 74,
		part_id: 181,
		quantity: 380,
	},
	{
		garage_id: 74,
		part_id: 182,
		quantity: 150,
	},
	{
		garage_id: 74,
		part_id: 183,
		quantity: 335,
	},
	{
		garage_id: 74,
		part_id: 184,
		quantity: 482,
	},
	{
		garage_id: 74,
		part_id: 185,
		quantity: 303,
	},
	{
		garage_id: 74,
		part_id: 186,
		quantity: 243,
	},
	{
		garage_id: 74,
		part_id: 187,
		quantity: 336,
	},
	{
		garage_id: 74,
		part_id: 188,
		quantity: 272,
	},
	{
		garage_id: 74,
		part_id: 189,
		quantity: 48,
	},
	{
		garage_id: 74,
		part_id: 190,
		quantity: 125,
	},
	{
		garage_id: 74,
		part_id: 191,
		quantity: 137,
	},
	{
		garage_id: 74,
		part_id: 192,
		quantity: 197,
	},
	{
		garage_id: 74,
		part_id: 193,
		quantity: 479,
	},
	{
		garage_id: 74,
		part_id: 194,
		quantity: 334,
	},
	{
		garage_id: 74,
		part_id: 195,
		quantity: 36,
	},
	{
		garage_id: 74,
		part_id: 196,
		quantity: 278,
	},
	{
		garage_id: 74,
		part_id: 197,
		quantity: 171,
	},
	{
		garage_id: 74,
		part_id: 198,
		quantity: 247,
	},
	{
		garage_id: 74,
		part_id: 199,
		quantity: 490,
	},
	{
		garage_id: 74,
		part_id: 200,
		quantity: 73,
	},
	{
		garage_id: 74,
		part_id: 201,
		quantity: 443,
	},
	{
		garage_id: 74,
		part_id: 202,
		quantity: 145,
	},
	{
		garage_id: 74,
		part_id: 203,
		quantity: 267,
	},
	{
		garage_id: 74,
		part_id: 204,
		quantity: 471,
	},
	{
		garage_id: 74,
		part_id: 205,
		quantity: 224,
	},
	{
		garage_id: 74,
		part_id: 206,
		quantity: 88,
	},
	{
		garage_id: 74,
		part_id: 207,
		quantity: 112,
	},
	{
		garage_id: 74,
		part_id: 208,
		quantity: 319,
	},
	{
		garage_id: 74,
		part_id: 209,
		quantity: 286,
	},
	{
		garage_id: 74,
		part_id: 210,
		quantity: 160,
	},
	{
		garage_id: 74,
		part_id: 211,
		quantity: 236,
	},
	{
		garage_id: 74,
		part_id: 212,
		quantity: 324,
	},
	{
		garage_id: 74,
		part_id: 213,
		quantity: 343,
	},
	{
		garage_id: 74,
		part_id: 214,
		quantity: 306,
	},
	{
		garage_id: 74,
		part_id: 215,
		quantity: 143,
	},
	{
		garage_id: 74,
		part_id: 216,
		quantity: 86,
	},
	{
		garage_id: 74,
		part_id: 217,
		quantity: 406,
	},
	{
		garage_id: 74,
		part_id: 218,
		quantity: 476,
	},
	{
		garage_id: 74,
		part_id: 219,
		quantity: 451,
	},
	{
		garage_id: 74,
		part_id: 220,
		quantity: 424,
	},
	{
		garage_id: 74,
		part_id: 221,
		quantity: 471,
	},
	{
		garage_id: 74,
		part_id: 222,
		quantity: 457,
	},
	{
		garage_id: 74,
		part_id: 223,
		quantity: 14,
	},
	{
		garage_id: 74,
		part_id: 224,
		quantity: 46,
	},
	{
		garage_id: 74,
		part_id: 225,
		quantity: 208,
	},
	{
		garage_id: 74,
		part_id: 226,
		quantity: 123,
	},
	{
		garage_id: 74,
		part_id: 227,
		quantity: 59,
	},
	{
		garage_id: 74,
		part_id: 228,
		quantity: 184,
	},
	{
		garage_id: 74,
		part_id: 229,
		quantity: 300,
	},
	{
		garage_id: 74,
		part_id: 230,
		quantity: 294,
	},
	{
		garage_id: 74,
		part_id: 231,
		quantity: 392,
	},
	{
		garage_id: 74,
		part_id: 232,
		quantity: 50,
	},
	{
		garage_id: 74,
		part_id: 233,
		quantity: 362,
	},
	{
		garage_id: 74,
		part_id: 234,
		quantity: 437,
	},
	{
		garage_id: 74,
		part_id: 235,
		quantity: 19,
	},
	{
		garage_id: 74,
		part_id: 236,
		quantity: 492,
	},
	{
		garage_id: 74,
		part_id: 237,
		quantity: 18,
	},
	{
		garage_id: 74,
		part_id: 238,
		quantity: 210,
	},
	{
		garage_id: 74,
		part_id: 239,
		quantity: 183,
	},
	{
		garage_id: 74,
		part_id: 240,
		quantity: 112,
	},
	{
		garage_id: 74,
		part_id: 241,
		quantity: 350,
	},
	{
		garage_id: 74,
		part_id: 242,
		quantity: 275,
	},
	{
		garage_id: 74,
		part_id: 243,
		quantity: 45,
	},
	{
		garage_id: 74,
		part_id: 244,
		quantity: 202,
	},
	{
		garage_id: 74,
		part_id: 245,
		quantity: 236,
	},
	{
		garage_id: 74,
		part_id: 246,
		quantity: 338,
	},
	{
		garage_id: 74,
		part_id: 247,
		quantity: 448,
	},
	{
		garage_id: 74,
		part_id: 248,
		quantity: 189,
	},
	{
		garage_id: 74,
		part_id: 249,
		quantity: 402,
	},
	{
		garage_id: 74,
		part_id: 250,
		quantity: 82,
	},
	{
		garage_id: 74,
		part_id: 251,
		quantity: 279,
	},
	{
		garage_id: 74,
		part_id: 252,
		quantity: 317,
	},
	{
		garage_id: 74,
		part_id: 253,
		quantity: 253,
	},
	{
		garage_id: 74,
		part_id: 254,
		quantity: 244,
	},
	{
		garage_id: 74,
		part_id: 255,
		quantity: 241,
	},
	{
		garage_id: 74,
		part_id: 256,
		quantity: 261,
	},
	{
		garage_id: 74,
		part_id: 257,
		quantity: 316,
	},
	{
		garage_id: 74,
		part_id: 258,
		quantity: 352,
	},
	{
		garage_id: 74,
		part_id: 259,
		quantity: 146,
	},
	{
		garage_id: 74,
		part_id: 260,
		quantity: 181,
	},
	{
		garage_id: 74,
		part_id: 261,
		quantity: 48,
	},
	{
		garage_id: 74,
		part_id: 262,
		quantity: 7,
	},
	{
		garage_id: 74,
		part_id: 263,
		quantity: 31,
	},
	{
		garage_id: 74,
		part_id: 264,
		quantity: 233,
	},
	{
		garage_id: 74,
		part_id: 265,
		quantity: 388,
	},
	{
		garage_id: 74,
		part_id: 266,
		quantity: 432,
	},
	{
		garage_id: 74,
		part_id: 267,
		quantity: 149,
	},
	{
		garage_id: 74,
		part_id: 268,
		quantity: 474,
	},
	{
		garage_id: 74,
		part_id: 269,
		quantity: 229,
	},
	{
		garage_id: 74,
		part_id: 270,
		quantity: 49,
	},
	{
		garage_id: 74,
		part_id: 271,
		quantity: 198,
	},
	{
		garage_id: 74,
		part_id: 272,
		quantity: 395,
	},
	{
		garage_id: 74,
		part_id: 273,
		quantity: 137,
	},
	{
		garage_id: 74,
		part_id: 274,
		quantity: 226,
	},
	{
		garage_id: 74,
		part_id: 275,
		quantity: 486,
	},
	{
		garage_id: 74,
		part_id: 276,
		quantity: 331,
	},
	{
		garage_id: 74,
		part_id: 277,
		quantity: 6,
	},
	{
		garage_id: 74,
		part_id: 278,
		quantity: 98,
	},
	{
		garage_id: 74,
		part_id: 279,
		quantity: 145,
	},
	{
		garage_id: 74,
		part_id: 280,
		quantity: 416,
	},
	{
		garage_id: 74,
		part_id: 281,
		quantity: 32,
	},
	{
		garage_id: 74,
		part_id: 282,
		quantity: 146,
	},
	{
		garage_id: 74,
		part_id: 283,
		quantity: 64,
	},
	{
		garage_id: 74,
		part_id: 284,
		quantity: 367,
	},
	{
		garage_id: 74,
		part_id: 285,
		quantity: 157,
	},
	{
		garage_id: 74,
		part_id: 286,
		quantity: 234,
	},
	{
		garage_id: 74,
		part_id: 287,
		quantity: 425,
	},
	{
		garage_id: 74,
		part_id: 288,
		quantity: 293,
	},
	{
		garage_id: 74,
		part_id: 289,
		quantity: 440,
	},
	{
		garage_id: 74,
		part_id: 290,
		quantity: 288,
	},
	{
		garage_id: 74,
		part_id: 291,
		quantity: 347,
	},
	{
		garage_id: 74,
		part_id: 292,
		quantity: 353,
	},
	{
		garage_id: 74,
		part_id: 293,
		quantity: 447,
	},
	{
		garage_id: 74,
		part_id: 294,
		quantity: 111,
	},
	{
		garage_id: 74,
		part_id: 295,
		quantity: 344,
	},
	{
		garage_id: 74,
		part_id: 296,
		quantity: 309,
	},
	{
		garage_id: 74,
		part_id: 297,
		quantity: 390,
	},
	{
		garage_id: 74,
		part_id: 298,
		quantity: 182,
	},
	{
		garage_id: 74,
		part_id: 299,
		quantity: 470,
	},
	{
		garage_id: 74,
		part_id: 300,
		quantity: 212,
	},
	{
		garage_id: 74,
		part_id: 301,
		quantity: 62,
	},
	{
		garage_id: 74,
		part_id: 302,
		quantity: 295,
	},
	{
		garage_id: 74,
		part_id: 303,
		quantity: 203,
	},
	{
		garage_id: 74,
		part_id: 304,
		quantity: 243,
	},
	{
		garage_id: 74,
		part_id: 305,
		quantity: 451,
	},
	{
		garage_id: 74,
		part_id: 306,
		quantity: 249,
	},
	{
		garage_id: 74,
		part_id: 307,
		quantity: 168,
	},
	{
		garage_id: 74,
		part_id: 308,
		quantity: 439,
	},
	{
		garage_id: 74,
		part_id: 309,
		quantity: 180,
	},
	{
		garage_id: 74,
		part_id: 310,
		quantity: 313,
	},
	{
		garage_id: 74,
		part_id: 311,
		quantity: 355,
	},
	{
		garage_id: 74,
		part_id: 312,
		quantity: 128,
	},
	{
		garage_id: 74,
		part_id: 313,
		quantity: 300,
	},
	{
		garage_id: 74,
		part_id: 314,
		quantity: 169,
	},
	{
		garage_id: 74,
		part_id: 315,
		quantity: 278,
	},
	{
		garage_id: 74,
		part_id: 316,
		quantity: 104,
	},
	{
		garage_id: 74,
		part_id: 317,
		quantity: 113,
	},
	{
		garage_id: 74,
		part_id: 318,
		quantity: 352,
	},
	{
		garage_id: 74,
		part_id: 319,
		quantity: 293,
	},
	{
		garage_id: 74,
		part_id: 320,
		quantity: 9,
	},
	{
		garage_id: 74,
		part_id: 321,
		quantity: 313,
	},
	{
		garage_id: 74,
		part_id: 322,
		quantity: 419,
	},
	{
		garage_id: 74,
		part_id: 323,
		quantity: 49,
	},
	{
		garage_id: 74,
		part_id: 324,
		quantity: 136,
	},
	{
		garage_id: 74,
		part_id: 325,
		quantity: 113,
	},
	{
		garage_id: 74,
		part_id: 326,
		quantity: 118,
	},
	{
		garage_id: 74,
		part_id: 327,
		quantity: 48,
	},
	{
		garage_id: 74,
		part_id: 328,
		quantity: 272,
	},
	{
		garage_id: 74,
		part_id: 329,
		quantity: 493,
	},
	{
		garage_id: 74,
		part_id: 330,
		quantity: 237,
	},
	{
		garage_id: 74,
		part_id: 331,
		quantity: 172,
	},
	{
		garage_id: 74,
		part_id: 332,
		quantity: 102,
	},
	{
		garage_id: 74,
		part_id: 333,
		quantity: 169,
	},
	{
		garage_id: 74,
		part_id: 334,
		quantity: 241,
	},
	{
		garage_id: 74,
		part_id: 335,
		quantity: 4,
	},
	{
		garage_id: 74,
		part_id: 336,
		quantity: 84,
	},
	{
		garage_id: 74,
		part_id: 337,
		quantity: 81,
	},
	{
		garage_id: 74,
		part_id: 338,
		quantity: 210,
	},
	{
		garage_id: 74,
		part_id: 339,
		quantity: 224,
	},
	{
		garage_id: 74,
		part_id: 340,
		quantity: 257,
	},
	{
		garage_id: 74,
		part_id: 341,
		quantity: 453,
	},
	{
		garage_id: 74,
		part_id: 342,
		quantity: 475,
	},
	{
		garage_id: 74,
		part_id: 343,
		quantity: 94,
	},
	{
		garage_id: 74,
		part_id: 344,
		quantity: 144,
	},
	{
		garage_id: 74,
		part_id: 345,
		quantity: 379,
	},
	{
		garage_id: 74,
		part_id: 346,
		quantity: 133,
	},
	{
		garage_id: 74,
		part_id: 347,
		quantity: 97,
	},
	{
		garage_id: 74,
		part_id: 348,
		quantity: 153,
	},
	{
		garage_id: 74,
		part_id: 349,
		quantity: 229,
	},
	{
		garage_id: 74,
		part_id: 350,
		quantity: 8,
	},
	{
		garage_id: 74,
		part_id: 351,
		quantity: 343,
	},
	{
		garage_id: 74,
		part_id: 352,
		quantity: 493,
	},
	{
		garage_id: 74,
		part_id: 353,
		quantity: 217,
	},
	{
		garage_id: 74,
		part_id: 354,
		quantity: 224,
	},
	{
		garage_id: 74,
		part_id: 355,
		quantity: 128,
	},
	{
		garage_id: 74,
		part_id: 356,
		quantity: 426,
	},
	{
		garage_id: 74,
		part_id: 357,
		quantity: 243,
	},
	{
		garage_id: 74,
		part_id: 358,
		quantity: 472,
	},
	{
		garage_id: 74,
		part_id: 359,
		quantity: 57,
	},
	{
		garage_id: 74,
		part_id: 360,
		quantity: 467,
	},
	{
		garage_id: 74,
		part_id: 361,
		quantity: 222,
	},
	{
		garage_id: 74,
		part_id: 362,
		quantity: 312,
	},
	{
		garage_id: 74,
		part_id: 363,
		quantity: 175,
	},
	{
		garage_id: 74,
		part_id: 364,
		quantity: 447,
	},
	{
		garage_id: 74,
		part_id: 365,
		quantity: 221,
	},
	{
		garage_id: 74,
		part_id: 366,
		quantity: 499,
	},
	{
		garage_id: 74,
		part_id: 367,
		quantity: 332,
	},
	{
		garage_id: 74,
		part_id: 368,
		quantity: 282,
	},
	{
		garage_id: 74,
		part_id: 369,
		quantity: 410,
	},
	{
		garage_id: 74,
		part_id: 370,
		quantity: 449,
	},
	{
		garage_id: 74,
		part_id: 371,
		quantity: 214,
	},
	{
		garage_id: 74,
		part_id: 372,
		quantity: 27,
	},
	{
		garage_id: 74,
		part_id: 373,
		quantity: 49,
	},
	{
		garage_id: 74,
		part_id: 374,
		quantity: 253,
	},
	{
		garage_id: 74,
		part_id: 375,
		quantity: 236,
	},
	{
		garage_id: 74,
		part_id: 376,
		quantity: 279,
	},
	{
		garage_id: 74,
		part_id: 377,
		quantity: 175,
	},
	{
		garage_id: 74,
		part_id: 378,
		quantity: 0,
	},
	{
		garage_id: 74,
		part_id: 379,
		quantity: 481,
	},
	{
		garage_id: 74,
		part_id: 380,
		quantity: 456,
	},
	{
		garage_id: 74,
		part_id: 381,
		quantity: 249,
	},
	{
		garage_id: 74,
		part_id: 382,
		quantity: 483,
	},
	{
		garage_id: 74,
		part_id: 383,
		quantity: 254,
	},
	{
		garage_id: 74,
		part_id: 384,
		quantity: 132,
	},
	{
		garage_id: 74,
		part_id: 385,
		quantity: 66,
	},
	{
		garage_id: 74,
		part_id: 386,
		quantity: 445,
	},
	{
		garage_id: 74,
		part_id: 387,
		quantity: 63,
	},
	{
		garage_id: 74,
		part_id: 388,
		quantity: 169,
	},
	{
		garage_id: 74,
		part_id: 389,
		quantity: 458,
	},
	{
		garage_id: 74,
		part_id: 390,
		quantity: 420,
	},
	{
		garage_id: 74,
		part_id: 391,
		quantity: 221,
	},
	{
		garage_id: 74,
		part_id: 392,
		quantity: 227,
	},
	{
		garage_id: 74,
		part_id: 393,
		quantity: 125,
	},
	{
		garage_id: 74,
		part_id: 394,
		quantity: 435,
	},
	{
		garage_id: 74,
		part_id: 395,
		quantity: 317,
	},
	{
		garage_id: 74,
		part_id: 396,
		quantity: 281,
	},
	{
		garage_id: 74,
		part_id: 397,
		quantity: 190,
	},
	{
		garage_id: 74,
		part_id: 398,
		quantity: 76,
	},
	{
		garage_id: 74,
		part_id: 399,
		quantity: 387,
	},
	{
		garage_id: 75,
		part_id: 0,
		quantity: 460,
	},
	{
		garage_id: 75,
		part_id: 1,
		quantity: 497,
	},
	{
		garage_id: 75,
		part_id: 2,
		quantity: 220,
	},
	{
		garage_id: 75,
		part_id: 3,
		quantity: 492,
	},
	{
		garage_id: 75,
		part_id: 4,
		quantity: 7,
	},
	{
		garage_id: 75,
		part_id: 5,
		quantity: 275,
	},
	{
		garage_id: 75,
		part_id: 6,
		quantity: 85,
	},
	{
		garage_id: 75,
		part_id: 7,
		quantity: 104,
	},
	{
		garage_id: 75,
		part_id: 8,
		quantity: 13,
	},
	{
		garage_id: 75,
		part_id: 9,
		quantity: 353,
	},
	{
		garage_id: 75,
		part_id: 10,
		quantity: 26,
	},
	{
		garage_id: 75,
		part_id: 11,
		quantity: 240,
	},
	{
		garage_id: 75,
		part_id: 12,
		quantity: 154,
	},
	{
		garage_id: 75,
		part_id: 13,
		quantity: 372,
	},
	{
		garage_id: 75,
		part_id: 14,
		quantity: 197,
	},
	{
		garage_id: 75,
		part_id: 15,
		quantity: 381,
	},
	{
		garage_id: 75,
		part_id: 16,
		quantity: 64,
	},
	{
		garage_id: 75,
		part_id: 17,
		quantity: 309,
	},
	{
		garage_id: 75,
		part_id: 18,
		quantity: 87,
	},
	{
		garage_id: 75,
		part_id: 19,
		quantity: 194,
	},
	{
		garage_id: 75,
		part_id: 20,
		quantity: 134,
	},
	{
		garage_id: 75,
		part_id: 21,
		quantity: 4,
	},
	{
		garage_id: 75,
		part_id: 22,
		quantity: 353,
	},
	{
		garage_id: 75,
		part_id: 23,
		quantity: 203,
	},
	{
		garage_id: 75,
		part_id: 24,
		quantity: 55,
	},
	{
		garage_id: 75,
		part_id: 25,
		quantity: 156,
	},
	{
		garage_id: 75,
		part_id: 26,
		quantity: 452,
	},
	{
		garage_id: 75,
		part_id: 27,
		quantity: 66,
	},
	{
		garage_id: 75,
		part_id: 28,
		quantity: 434,
	},
	{
		garage_id: 75,
		part_id: 29,
		quantity: 132,
	},
	{
		garage_id: 75,
		part_id: 30,
		quantity: 76,
	},
	{
		garage_id: 75,
		part_id: 31,
		quantity: 59,
	},
	{
		garage_id: 75,
		part_id: 32,
		quantity: 384,
	},
	{
		garage_id: 75,
		part_id: 33,
		quantity: 133,
	},
	{
		garage_id: 75,
		part_id: 34,
		quantity: 207,
	},
	{
		garage_id: 75,
		part_id: 35,
		quantity: 211,
	},
	{
		garage_id: 75,
		part_id: 36,
		quantity: 180,
	},
	{
		garage_id: 75,
		part_id: 37,
		quantity: 404,
	},
	{
		garage_id: 75,
		part_id: 38,
		quantity: 50,
	},
	{
		garage_id: 75,
		part_id: 39,
		quantity: 412,
	},
	{
		garage_id: 75,
		part_id: 40,
		quantity: 279,
	},
	{
		garage_id: 75,
		part_id: 41,
		quantity: 495,
	},
	{
		garage_id: 75,
		part_id: 42,
		quantity: 479,
	},
	{
		garage_id: 75,
		part_id: 43,
		quantity: 414,
	},
	{
		garage_id: 75,
		part_id: 44,
		quantity: 280,
	},
	{
		garage_id: 75,
		part_id: 45,
		quantity: 500,
	},
	{
		garage_id: 75,
		part_id: 46,
		quantity: 74,
	},
	{
		garage_id: 75,
		part_id: 47,
		quantity: 368,
	},
	{
		garage_id: 75,
		part_id: 48,
		quantity: 71,
	},
	{
		garage_id: 75,
		part_id: 49,
		quantity: 373,
	},
	{
		garage_id: 75,
		part_id: 50,
		quantity: 85,
	},
	{
		garage_id: 75,
		part_id: 51,
		quantity: 391,
	},
	{
		garage_id: 75,
		part_id: 52,
		quantity: 89,
	},
	{
		garage_id: 75,
		part_id: 53,
		quantity: 217,
	},
	{
		garage_id: 75,
		part_id: 54,
		quantity: 36,
	},
	{
		garage_id: 75,
		part_id: 55,
		quantity: 182,
	},
	{
		garage_id: 75,
		part_id: 56,
		quantity: 327,
	},
	{
		garage_id: 75,
		part_id: 57,
		quantity: 365,
	},
	{
		garage_id: 75,
		part_id: 58,
		quantity: 232,
	},
	{
		garage_id: 75,
		part_id: 59,
		quantity: 216,
	},
	{
		garage_id: 75,
		part_id: 60,
		quantity: 301,
	},
	{
		garage_id: 75,
		part_id: 61,
		quantity: 207,
	},
	{
		garage_id: 75,
		part_id: 62,
		quantity: 13,
	},
	{
		garage_id: 75,
		part_id: 63,
		quantity: 146,
	},
	{
		garage_id: 75,
		part_id: 64,
		quantity: 365,
	},
	{
		garage_id: 75,
		part_id: 65,
		quantity: 467,
	},
	{
		garage_id: 75,
		part_id: 66,
		quantity: 388,
	},
	{
		garage_id: 75,
		part_id: 67,
		quantity: 303,
	},
	{
		garage_id: 75,
		part_id: 68,
		quantity: 485,
	},
	{
		garage_id: 75,
		part_id: 69,
		quantity: 320,
	},
	{
		garage_id: 75,
		part_id: 70,
		quantity: 182,
	},
	{
		garage_id: 75,
		part_id: 71,
		quantity: 64,
	},
	{
		garage_id: 75,
		part_id: 72,
		quantity: 482,
	},
	{
		garage_id: 75,
		part_id: 73,
		quantity: 78,
	},
	{
		garage_id: 75,
		part_id: 74,
		quantity: 175,
	},
	{
		garage_id: 75,
		part_id: 75,
		quantity: 133,
	},
	{
		garage_id: 75,
		part_id: 76,
		quantity: 53,
	},
	{
		garage_id: 75,
		part_id: 77,
		quantity: 237,
	},
	{
		garage_id: 75,
		part_id: 78,
		quantity: 322,
	},
	{
		garage_id: 75,
		part_id: 79,
		quantity: 14,
	},
	{
		garage_id: 75,
		part_id: 80,
		quantity: 488,
	},
	{
		garage_id: 75,
		part_id: 81,
		quantity: 301,
	},
	{
		garage_id: 75,
		part_id: 82,
		quantity: 398,
	},
	{
		garage_id: 75,
		part_id: 83,
		quantity: 491,
	},
	{
		garage_id: 75,
		part_id: 84,
		quantity: 342,
	},
	{
		garage_id: 75,
		part_id: 85,
		quantity: 151,
	},
	{
		garage_id: 75,
		part_id: 86,
		quantity: 396,
	},
	{
		garage_id: 75,
		part_id: 87,
		quantity: 7,
	},
	{
		garage_id: 75,
		part_id: 88,
		quantity: 286,
	},
	{
		garage_id: 75,
		part_id: 89,
		quantity: 132,
	},
	{
		garage_id: 75,
		part_id: 90,
		quantity: 101,
	},
	{
		garage_id: 75,
		part_id: 91,
		quantity: 383,
	},
	{
		garage_id: 75,
		part_id: 92,
		quantity: 73,
	},
	{
		garage_id: 75,
		part_id: 93,
		quantity: 125,
	},
	{
		garage_id: 75,
		part_id: 94,
		quantity: 358,
	},
	{
		garage_id: 75,
		part_id: 95,
		quantity: 245,
	},
	{
		garage_id: 75,
		part_id: 96,
		quantity: 491,
	},
	{
		garage_id: 75,
		part_id: 97,
		quantity: 162,
	},
	{
		garage_id: 75,
		part_id: 98,
		quantity: 95,
	},
	{
		garage_id: 75,
		part_id: 99,
		quantity: 435,
	},
	{
		garage_id: 75,
		part_id: 100,
		quantity: 438,
	},
	{
		garage_id: 75,
		part_id: 101,
		quantity: 347,
	},
	{
		garage_id: 75,
		part_id: 102,
		quantity: 320,
	},
	{
		garage_id: 75,
		part_id: 103,
		quantity: 359,
	},
	{
		garage_id: 75,
		part_id: 104,
		quantity: 486,
	},
	{
		garage_id: 75,
		part_id: 105,
		quantity: 391,
	},
	{
		garage_id: 75,
		part_id: 106,
		quantity: 449,
	},
	{
		garage_id: 75,
		part_id: 107,
		quantity: 126,
	},
	{
		garage_id: 75,
		part_id: 108,
		quantity: 36,
	},
	{
		garage_id: 75,
		part_id: 109,
		quantity: 310,
	},
	{
		garage_id: 75,
		part_id: 110,
		quantity: 174,
	},
	{
		garage_id: 75,
		part_id: 111,
		quantity: 100,
	},
	{
		garage_id: 75,
		part_id: 112,
		quantity: 477,
	},
	{
		garage_id: 75,
		part_id: 113,
		quantity: 450,
	},
	{
		garage_id: 75,
		part_id: 114,
		quantity: 54,
	},
	{
		garage_id: 75,
		part_id: 115,
		quantity: 317,
	},
	{
		garage_id: 75,
		part_id: 116,
		quantity: 467,
	},
	{
		garage_id: 75,
		part_id: 117,
		quantity: 342,
	},
	{
		garage_id: 75,
		part_id: 118,
		quantity: 89,
	},
	{
		garage_id: 75,
		part_id: 119,
		quantity: 396,
	},
	{
		garage_id: 75,
		part_id: 120,
		quantity: 51,
	},
	{
		garage_id: 75,
		part_id: 121,
		quantity: 365,
	},
	{
		garage_id: 75,
		part_id: 122,
		quantity: 19,
	},
	{
		garage_id: 75,
		part_id: 123,
		quantity: 214,
	},
	{
		garage_id: 75,
		part_id: 124,
		quantity: 287,
	},
	{
		garage_id: 75,
		part_id: 125,
		quantity: 158,
	},
	{
		garage_id: 75,
		part_id: 126,
		quantity: 95,
	},
	{
		garage_id: 75,
		part_id: 127,
		quantity: 474,
	},
	{
		garage_id: 75,
		part_id: 128,
		quantity: 349,
	},
	{
		garage_id: 75,
		part_id: 129,
		quantity: 219,
	},
	{
		garage_id: 75,
		part_id: 130,
		quantity: 173,
	},
	{
		garage_id: 75,
		part_id: 131,
		quantity: 256,
	},
	{
		garage_id: 75,
		part_id: 132,
		quantity: 38,
	},
	{
		garage_id: 75,
		part_id: 133,
		quantity: 347,
	},
	{
		garage_id: 75,
		part_id: 134,
		quantity: 441,
	},
	{
		garage_id: 75,
		part_id: 135,
		quantity: 231,
	},
	{
		garage_id: 75,
		part_id: 136,
		quantity: 60,
	},
	{
		garage_id: 75,
		part_id: 137,
		quantity: 408,
	},
	{
		garage_id: 75,
		part_id: 138,
		quantity: 329,
	},
	{
		garage_id: 75,
		part_id: 139,
		quantity: 303,
	},
	{
		garage_id: 75,
		part_id: 140,
		quantity: 245,
	},
	{
		garage_id: 75,
		part_id: 141,
		quantity: 231,
	},
	{
		garage_id: 75,
		part_id: 142,
		quantity: 306,
	},
	{
		garage_id: 75,
		part_id: 143,
		quantity: 276,
	},
	{
		garage_id: 75,
		part_id: 144,
		quantity: 21,
	},
	{
		garage_id: 75,
		part_id: 145,
		quantity: 187,
	},
	{
		garage_id: 75,
		part_id: 146,
		quantity: 357,
	},
	{
		garage_id: 75,
		part_id: 147,
		quantity: 109,
	},
	{
		garage_id: 75,
		part_id: 148,
		quantity: 23,
	},
	{
		garage_id: 75,
		part_id: 149,
		quantity: 133,
	},
	{
		garage_id: 75,
		part_id: 150,
		quantity: 472,
	},
	{
		garage_id: 75,
		part_id: 151,
		quantity: 432,
	},
	{
		garage_id: 75,
		part_id: 152,
		quantity: 118,
	},
	{
		garage_id: 75,
		part_id: 153,
		quantity: 473,
	},
	{
		garage_id: 75,
		part_id: 154,
		quantity: 12,
	},
	{
		garage_id: 75,
		part_id: 155,
		quantity: 13,
	},
	{
		garage_id: 75,
		part_id: 156,
		quantity: 136,
	},
	{
		garage_id: 75,
		part_id: 157,
		quantity: 53,
	},
	{
		garage_id: 75,
		part_id: 158,
		quantity: 385,
	},
	{
		garage_id: 75,
		part_id: 159,
		quantity: 54,
	},
	{
		garage_id: 75,
		part_id: 160,
		quantity: 291,
	},
	{
		garage_id: 75,
		part_id: 161,
		quantity: 324,
	},
	{
		garage_id: 75,
		part_id: 162,
		quantity: 167,
	},
	{
		garage_id: 75,
		part_id: 163,
		quantity: 223,
	},
	{
		garage_id: 75,
		part_id: 164,
		quantity: 310,
	},
	{
		garage_id: 75,
		part_id: 165,
		quantity: 174,
	},
	{
		garage_id: 75,
		part_id: 166,
		quantity: 486,
	},
	{
		garage_id: 75,
		part_id: 167,
		quantity: 270,
	},
	{
		garage_id: 75,
		part_id: 168,
		quantity: 12,
	},
	{
		garage_id: 75,
		part_id: 169,
		quantity: 204,
	},
	{
		garage_id: 75,
		part_id: 170,
		quantity: 165,
	},
	{
		garage_id: 75,
		part_id: 171,
		quantity: 76,
	},
	{
		garage_id: 75,
		part_id: 172,
		quantity: 212,
	},
	{
		garage_id: 75,
		part_id: 173,
		quantity: 229,
	},
	{
		garage_id: 75,
		part_id: 174,
		quantity: 139,
	},
	{
		garage_id: 75,
		part_id: 175,
		quantity: 290,
	},
	{
		garage_id: 75,
		part_id: 176,
		quantity: 251,
	},
	{
		garage_id: 75,
		part_id: 177,
		quantity: 456,
	},
	{
		garage_id: 75,
		part_id: 178,
		quantity: 195,
	},
	{
		garage_id: 75,
		part_id: 179,
		quantity: 25,
	},
	{
		garage_id: 75,
		part_id: 180,
		quantity: 105,
	},
	{
		garage_id: 75,
		part_id: 181,
		quantity: 473,
	},
	{
		garage_id: 75,
		part_id: 182,
		quantity: 339,
	},
	{
		garage_id: 75,
		part_id: 183,
		quantity: 421,
	},
	{
		garage_id: 75,
		part_id: 184,
		quantity: 156,
	},
	{
		garage_id: 75,
		part_id: 185,
		quantity: 60,
	},
	{
		garage_id: 75,
		part_id: 186,
		quantity: 369,
	},
	{
		garage_id: 75,
		part_id: 187,
		quantity: 387,
	},
	{
		garage_id: 75,
		part_id: 188,
		quantity: 192,
	},
	{
		garage_id: 75,
		part_id: 189,
		quantity: 221,
	},
	{
		garage_id: 75,
		part_id: 190,
		quantity: 361,
	},
	{
		garage_id: 75,
		part_id: 191,
		quantity: 231,
	},
	{
		garage_id: 75,
		part_id: 192,
		quantity: 66,
	},
	{
		garage_id: 75,
		part_id: 193,
		quantity: 415,
	},
	{
		garage_id: 75,
		part_id: 194,
		quantity: 17,
	},
	{
		garage_id: 75,
		part_id: 195,
		quantity: 39,
	},
	{
		garage_id: 75,
		part_id: 196,
		quantity: 241,
	},
	{
		garage_id: 75,
		part_id: 197,
		quantity: 433,
	},
	{
		garage_id: 75,
		part_id: 198,
		quantity: 11,
	},
	{
		garage_id: 75,
		part_id: 199,
		quantity: 245,
	},
	{
		garage_id: 75,
		part_id: 200,
		quantity: 437,
	},
	{
		garage_id: 75,
		part_id: 201,
		quantity: 396,
	},
	{
		garage_id: 75,
		part_id: 202,
		quantity: 400,
	},
	{
		garage_id: 75,
		part_id: 203,
		quantity: 208,
	},
	{
		garage_id: 75,
		part_id: 204,
		quantity: 355,
	},
	{
		garage_id: 75,
		part_id: 205,
		quantity: 16,
	},
	{
		garage_id: 75,
		part_id: 206,
		quantity: 360,
	},
	{
		garage_id: 75,
		part_id: 207,
		quantity: 60,
	},
	{
		garage_id: 75,
		part_id: 208,
		quantity: 401,
	},
	{
		garage_id: 75,
		part_id: 209,
		quantity: 371,
	},
	{
		garage_id: 75,
		part_id: 210,
		quantity: 226,
	},
	{
		garage_id: 75,
		part_id: 211,
		quantity: 64,
	},
	{
		garage_id: 75,
		part_id: 212,
		quantity: 142,
	},
	{
		garage_id: 75,
		part_id: 213,
		quantity: 500,
	},
	{
		garage_id: 75,
		part_id: 214,
		quantity: 43,
	},
	{
		garage_id: 75,
		part_id: 215,
		quantity: 20,
	},
	{
		garage_id: 75,
		part_id: 216,
		quantity: 449,
	},
	{
		garage_id: 75,
		part_id: 217,
		quantity: 85,
	},
	{
		garage_id: 75,
		part_id: 218,
		quantity: 425,
	},
	{
		garage_id: 75,
		part_id: 219,
		quantity: 361,
	},
	{
		garage_id: 75,
		part_id: 220,
		quantity: 261,
	},
	{
		garage_id: 75,
		part_id: 221,
		quantity: 177,
	},
	{
		garage_id: 75,
		part_id: 222,
		quantity: 211,
	},
	{
		garage_id: 75,
		part_id: 223,
		quantity: 105,
	},
	{
		garage_id: 75,
		part_id: 224,
		quantity: 24,
	},
	{
		garage_id: 75,
		part_id: 225,
		quantity: 224,
	},
	{
		garage_id: 75,
		part_id: 226,
		quantity: 51,
	},
	{
		garage_id: 75,
		part_id: 227,
		quantity: 118,
	},
	{
		garage_id: 75,
		part_id: 228,
		quantity: 131,
	},
	{
		garage_id: 75,
		part_id: 229,
		quantity: 208,
	},
	{
		garage_id: 75,
		part_id: 230,
		quantity: 275,
	},
	{
		garage_id: 75,
		part_id: 231,
		quantity: 90,
	},
	{
		garage_id: 75,
		part_id: 232,
		quantity: 410,
	},
	{
		garage_id: 75,
		part_id: 233,
		quantity: 170,
	},
	{
		garage_id: 75,
		part_id: 234,
		quantity: 265,
	},
	{
		garage_id: 75,
		part_id: 235,
		quantity: 453,
	},
	{
		garage_id: 75,
		part_id: 236,
		quantity: 29,
	},
	{
		garage_id: 75,
		part_id: 237,
		quantity: 329,
	},
	{
		garage_id: 75,
		part_id: 238,
		quantity: 59,
	},
	{
		garage_id: 75,
		part_id: 239,
		quantity: 305,
	},
	{
		garage_id: 75,
		part_id: 240,
		quantity: 326,
	},
	{
		garage_id: 75,
		part_id: 241,
		quantity: 498,
	},
	{
		garage_id: 75,
		part_id: 242,
		quantity: 158,
	},
	{
		garage_id: 75,
		part_id: 243,
		quantity: 39,
	},
	{
		garage_id: 75,
		part_id: 244,
		quantity: 461,
	},
	{
		garage_id: 75,
		part_id: 245,
		quantity: 213,
	},
	{
		garage_id: 75,
		part_id: 246,
		quantity: 358,
	},
	{
		garage_id: 75,
		part_id: 247,
		quantity: 353,
	},
	{
		garage_id: 75,
		part_id: 248,
		quantity: 341,
	},
	{
		garage_id: 75,
		part_id: 249,
		quantity: 479,
	},
	{
		garage_id: 75,
		part_id: 250,
		quantity: 197,
	},
	{
		garage_id: 75,
		part_id: 251,
		quantity: 231,
	},
	{
		garage_id: 75,
		part_id: 252,
		quantity: 246,
	},
	{
		garage_id: 75,
		part_id: 253,
		quantity: 445,
	},
	{
		garage_id: 75,
		part_id: 254,
		quantity: 500,
	},
	{
		garage_id: 75,
		part_id: 255,
		quantity: 485,
	},
	{
		garage_id: 75,
		part_id: 256,
		quantity: 408,
	},
	{
		garage_id: 75,
		part_id: 257,
		quantity: 468,
	},
	{
		garage_id: 75,
		part_id: 258,
		quantity: 499,
	},
	{
		garage_id: 75,
		part_id: 259,
		quantity: 88,
	},
	{
		garage_id: 75,
		part_id: 260,
		quantity: 21,
	},
	{
		garage_id: 75,
		part_id: 261,
		quantity: 191,
	},
	{
		garage_id: 75,
		part_id: 262,
		quantity: 322,
	},
	{
		garage_id: 75,
		part_id: 263,
		quantity: 394,
	},
	{
		garage_id: 75,
		part_id: 264,
		quantity: 302,
	},
	{
		garage_id: 75,
		part_id: 265,
		quantity: 99,
	},
	{
		garage_id: 75,
		part_id: 266,
		quantity: 441,
	},
	{
		garage_id: 75,
		part_id: 267,
		quantity: 265,
	},
	{
		garage_id: 75,
		part_id: 268,
		quantity: 12,
	},
	{
		garage_id: 75,
		part_id: 269,
		quantity: 67,
	},
	{
		garage_id: 75,
		part_id: 270,
		quantity: 16,
	},
	{
		garage_id: 75,
		part_id: 271,
		quantity: 244,
	},
	{
		garage_id: 75,
		part_id: 272,
		quantity: 197,
	},
	{
		garage_id: 75,
		part_id: 273,
		quantity: 47,
	},
	{
		garage_id: 75,
		part_id: 274,
		quantity: 160,
	},
	{
		garage_id: 75,
		part_id: 275,
		quantity: 492,
	},
	{
		garage_id: 75,
		part_id: 276,
		quantity: 451,
	},
	{
		garage_id: 75,
		part_id: 277,
		quantity: 469,
	},
	{
		garage_id: 75,
		part_id: 278,
		quantity: 192,
	},
	{
		garage_id: 75,
		part_id: 279,
		quantity: 466,
	},
	{
		garage_id: 75,
		part_id: 280,
		quantity: 51,
	},
	{
		garage_id: 75,
		part_id: 281,
		quantity: 43,
	},
	{
		garage_id: 75,
		part_id: 282,
		quantity: 444,
	},
	{
		garage_id: 75,
		part_id: 283,
		quantity: 479,
	},
	{
		garage_id: 75,
		part_id: 284,
		quantity: 243,
	},
	{
		garage_id: 75,
		part_id: 285,
		quantity: 426,
	},
	{
		garage_id: 75,
		part_id: 286,
		quantity: 194,
	},
	{
		garage_id: 75,
		part_id: 287,
		quantity: 177,
	},
	{
		garage_id: 75,
		part_id: 288,
		quantity: 131,
	},
	{
		garage_id: 75,
		part_id: 289,
		quantity: 220,
	},
	{
		garage_id: 75,
		part_id: 290,
		quantity: 106,
	},
	{
		garage_id: 75,
		part_id: 291,
		quantity: 410,
	},
	{
		garage_id: 75,
		part_id: 292,
		quantity: 6,
	},
	{
		garage_id: 75,
		part_id: 293,
		quantity: 137,
	},
	{
		garage_id: 75,
		part_id: 294,
		quantity: 152,
	},
	{
		garage_id: 75,
		part_id: 295,
		quantity: 256,
	},
	{
		garage_id: 75,
		part_id: 296,
		quantity: 479,
	},
	{
		garage_id: 75,
		part_id: 297,
		quantity: 450,
	},
	{
		garage_id: 75,
		part_id: 298,
		quantity: 328,
	},
	{
		garage_id: 75,
		part_id: 299,
		quantity: 97,
	},
	{
		garage_id: 75,
		part_id: 300,
		quantity: 285,
	},
	{
		garage_id: 75,
		part_id: 301,
		quantity: 393,
	},
	{
		garage_id: 75,
		part_id: 302,
		quantity: 141,
	},
	{
		garage_id: 75,
		part_id: 303,
		quantity: 254,
	},
	{
		garage_id: 75,
		part_id: 304,
		quantity: 458,
	},
	{
		garage_id: 75,
		part_id: 305,
		quantity: 418,
	},
	{
		garage_id: 75,
		part_id: 306,
		quantity: 12,
	},
	{
		garage_id: 75,
		part_id: 307,
		quantity: 35,
	},
	{
		garage_id: 75,
		part_id: 308,
		quantity: 54,
	},
	{
		garage_id: 75,
		part_id: 309,
		quantity: 351,
	},
	{
		garage_id: 75,
		part_id: 310,
		quantity: 384,
	},
	{
		garage_id: 75,
		part_id: 311,
		quantity: 430,
	},
	{
		garage_id: 75,
		part_id: 312,
		quantity: 354,
	},
	{
		garage_id: 75,
		part_id: 313,
		quantity: 418,
	},
	{
		garage_id: 75,
		part_id: 314,
		quantity: 175,
	},
	{
		garage_id: 75,
		part_id: 315,
		quantity: 132,
	},
	{
		garage_id: 75,
		part_id: 316,
		quantity: 266,
	},
	{
		garage_id: 75,
		part_id: 317,
		quantity: 300,
	},
	{
		garage_id: 75,
		part_id: 318,
		quantity: 11,
	},
	{
		garage_id: 75,
		part_id: 319,
		quantity: 326,
	},
	{
		garage_id: 75,
		part_id: 320,
		quantity: 321,
	},
	{
		garage_id: 75,
		part_id: 321,
		quantity: 273,
	},
	{
		garage_id: 75,
		part_id: 322,
		quantity: 387,
	},
	{
		garage_id: 75,
		part_id: 323,
		quantity: 326,
	},
	{
		garage_id: 75,
		part_id: 324,
		quantity: 283,
	},
	{
		garage_id: 75,
		part_id: 325,
		quantity: 488,
	},
	{
		garage_id: 75,
		part_id: 326,
		quantity: 473,
	},
	{
		garage_id: 75,
		part_id: 327,
		quantity: 51,
	},
	{
		garage_id: 75,
		part_id: 328,
		quantity: 400,
	},
	{
		garage_id: 75,
		part_id: 329,
		quantity: 170,
	},
	{
		garage_id: 75,
		part_id: 330,
		quantity: 171,
	},
	{
		garage_id: 75,
		part_id: 331,
		quantity: 229,
	},
	{
		garage_id: 75,
		part_id: 332,
		quantity: 281,
	},
	{
		garage_id: 75,
		part_id: 333,
		quantity: 30,
	},
	{
		garage_id: 75,
		part_id: 334,
		quantity: 30,
	},
	{
		garage_id: 75,
		part_id: 335,
		quantity: 96,
	},
	{
		garage_id: 75,
		part_id: 336,
		quantity: 99,
	},
	{
		garage_id: 75,
		part_id: 337,
		quantity: 156,
	},
	{
		garage_id: 75,
		part_id: 338,
		quantity: 76,
	},
	{
		garage_id: 75,
		part_id: 339,
		quantity: 269,
	},
	{
		garage_id: 75,
		part_id: 340,
		quantity: 15,
	},
	{
		garage_id: 75,
		part_id: 341,
		quantity: 493,
	},
	{
		garage_id: 75,
		part_id: 342,
		quantity: 500,
	},
	{
		garage_id: 75,
		part_id: 343,
		quantity: 469,
	},
	{
		garage_id: 75,
		part_id: 344,
		quantity: 455,
	},
	{
		garage_id: 75,
		part_id: 345,
		quantity: 422,
	},
	{
		garage_id: 75,
		part_id: 346,
		quantity: 153,
	},
	{
		garage_id: 75,
		part_id: 347,
		quantity: 221,
	},
	{
		garage_id: 75,
		part_id: 348,
		quantity: 190,
	},
	{
		garage_id: 75,
		part_id: 349,
		quantity: 359,
	},
	{
		garage_id: 75,
		part_id: 350,
		quantity: 284,
	},
	{
		garage_id: 75,
		part_id: 351,
		quantity: 392,
	},
	{
		garage_id: 75,
		part_id: 352,
		quantity: 195,
	},
	{
		garage_id: 75,
		part_id: 353,
		quantity: 484,
	},
	{
		garage_id: 75,
		part_id: 354,
		quantity: 136,
	},
	{
		garage_id: 75,
		part_id: 355,
		quantity: 391,
	},
	{
		garage_id: 75,
		part_id: 356,
		quantity: 366,
	},
	{
		garage_id: 75,
		part_id: 357,
		quantity: 11,
	},
	{
		garage_id: 75,
		part_id: 358,
		quantity: 486,
	},
	{
		garage_id: 75,
		part_id: 359,
		quantity: 309,
	},
	{
		garage_id: 75,
		part_id: 360,
		quantity: 132,
	},
	{
		garage_id: 75,
		part_id: 361,
		quantity: 31,
	},
	{
		garage_id: 75,
		part_id: 362,
		quantity: 161,
	},
	{
		garage_id: 75,
		part_id: 363,
		quantity: 87,
	},
	{
		garage_id: 75,
		part_id: 364,
		quantity: 95,
	},
	{
		garage_id: 75,
		part_id: 365,
		quantity: 406,
	},
	{
		garage_id: 75,
		part_id: 366,
		quantity: 433,
	},
	{
		garage_id: 75,
		part_id: 367,
		quantity: 389,
	},
	{
		garage_id: 75,
		part_id: 368,
		quantity: 351,
	},
	{
		garage_id: 75,
		part_id: 369,
		quantity: 75,
	},
	{
		garage_id: 75,
		part_id: 370,
		quantity: 149,
	},
	{
		garage_id: 75,
		part_id: 371,
		quantity: 202,
	},
	{
		garage_id: 75,
		part_id: 372,
		quantity: 360,
	},
	{
		garage_id: 75,
		part_id: 373,
		quantity: 93,
	},
	{
		garage_id: 75,
		part_id: 374,
		quantity: 273,
	},
	{
		garage_id: 75,
		part_id: 375,
		quantity: 265,
	},
	{
		garage_id: 75,
		part_id: 376,
		quantity: 215,
	},
	{
		garage_id: 75,
		part_id: 377,
		quantity: 365,
	},
	{
		garage_id: 75,
		part_id: 378,
		quantity: 70,
	},
	{
		garage_id: 75,
		part_id: 379,
		quantity: 167,
	},
	{
		garage_id: 75,
		part_id: 380,
		quantity: 90,
	},
	{
		garage_id: 75,
		part_id: 381,
		quantity: 203,
	},
	{
		garage_id: 75,
		part_id: 382,
		quantity: 242,
	},
	{
		garage_id: 75,
		part_id: 383,
		quantity: 490,
	},
	{
		garage_id: 75,
		part_id: 384,
		quantity: 176,
	},
	{
		garage_id: 75,
		part_id: 385,
		quantity: 105,
	},
	{
		garage_id: 75,
		part_id: 386,
		quantity: 408,
	},
	{
		garage_id: 75,
		part_id: 387,
		quantity: 465,
	},
	{
		garage_id: 75,
		part_id: 388,
		quantity: 385,
	},
	{
		garage_id: 75,
		part_id: 389,
		quantity: 159,
	},
	{
		garage_id: 75,
		part_id: 390,
		quantity: 482,
	},
	{
		garage_id: 75,
		part_id: 391,
		quantity: 387,
	},
	{
		garage_id: 75,
		part_id: 392,
		quantity: 324,
	},
	{
		garage_id: 75,
		part_id: 393,
		quantity: 336,
	},
	{
		garage_id: 75,
		part_id: 394,
		quantity: 469,
	},
	{
		garage_id: 75,
		part_id: 395,
		quantity: 315,
	},
	{
		garage_id: 75,
		part_id: 396,
		quantity: 29,
	},
	{
		garage_id: 75,
		part_id: 397,
		quantity: 426,
	},
	{
		garage_id: 75,
		part_id: 398,
		quantity: 499,
	},
	{
		garage_id: 75,
		part_id: 399,
		quantity: 368,
	},
	{
		garage_id: 76,
		part_id: 0,
		quantity: 455,
	},
	{
		garage_id: 76,
		part_id: 1,
		quantity: 256,
	},
	{
		garage_id: 76,
		part_id: 2,
		quantity: 384,
	},
	{
		garage_id: 76,
		part_id: 3,
		quantity: 201,
	},
	{
		garage_id: 76,
		part_id: 4,
		quantity: 17,
	},
	{
		garage_id: 76,
		part_id: 5,
		quantity: 37,
	},
	{
		garage_id: 76,
		part_id: 6,
		quantity: 127,
	},
	{
		garage_id: 76,
		part_id: 7,
		quantity: 415,
	},
	{
		garage_id: 76,
		part_id: 8,
		quantity: 249,
	},
	{
		garage_id: 76,
		part_id: 9,
		quantity: 152,
	},
	{
		garage_id: 76,
		part_id: 10,
		quantity: 484,
	},
	{
		garage_id: 76,
		part_id: 11,
		quantity: 84,
	},
	{
		garage_id: 76,
		part_id: 12,
		quantity: 443,
	},
	{
		garage_id: 76,
		part_id: 13,
		quantity: 287,
	},
	{
		garage_id: 76,
		part_id: 14,
		quantity: 227,
	},
	{
		garage_id: 76,
		part_id: 15,
		quantity: 26,
	},
	{
		garage_id: 76,
		part_id: 16,
		quantity: 46,
	},
	{
		garage_id: 76,
		part_id: 17,
		quantity: 46,
	},
	{
		garage_id: 76,
		part_id: 18,
		quantity: 269,
	},
	{
		garage_id: 76,
		part_id: 19,
		quantity: 319,
	},
	{
		garage_id: 76,
		part_id: 20,
		quantity: 296,
	},
	{
		garage_id: 76,
		part_id: 21,
		quantity: 402,
	},
	{
		garage_id: 76,
		part_id: 22,
		quantity: 427,
	},
	{
		garage_id: 76,
		part_id: 23,
		quantity: 231,
	},
	{
		garage_id: 76,
		part_id: 24,
		quantity: 55,
	},
	{
		garage_id: 76,
		part_id: 25,
		quantity: 288,
	},
	{
		garage_id: 76,
		part_id: 26,
		quantity: 13,
	},
	{
		garage_id: 76,
		part_id: 27,
		quantity: 225,
	},
	{
		garage_id: 76,
		part_id: 28,
		quantity: 49,
	},
	{
		garage_id: 76,
		part_id: 29,
		quantity: 297,
	},
	{
		garage_id: 76,
		part_id: 30,
		quantity: 222,
	},
	{
		garage_id: 76,
		part_id: 31,
		quantity: 295,
	},
	{
		garage_id: 76,
		part_id: 32,
		quantity: 350,
	},
	{
		garage_id: 76,
		part_id: 33,
		quantity: 49,
	},
	{
		garage_id: 76,
		part_id: 34,
		quantity: 482,
	},
	{
		garage_id: 76,
		part_id: 35,
		quantity: 141,
	},
	{
		garage_id: 76,
		part_id: 36,
		quantity: 23,
	},
	{
		garage_id: 76,
		part_id: 37,
		quantity: 286,
	},
	{
		garage_id: 76,
		part_id: 38,
		quantity: 133,
	},
	{
		garage_id: 76,
		part_id: 39,
		quantity: 462,
	},
	{
		garage_id: 76,
		part_id: 40,
		quantity: 442,
	},
	{
		garage_id: 76,
		part_id: 41,
		quantity: 422,
	},
	{
		garage_id: 76,
		part_id: 42,
		quantity: 126,
	},
	{
		garage_id: 76,
		part_id: 43,
		quantity: 199,
	},
	{
		garage_id: 76,
		part_id: 44,
		quantity: 148,
	},
	{
		garage_id: 76,
		part_id: 45,
		quantity: 173,
	},
	{
		garage_id: 76,
		part_id: 46,
		quantity: 164,
	},
	{
		garage_id: 76,
		part_id: 47,
		quantity: 5,
	},
	{
		garage_id: 76,
		part_id: 48,
		quantity: 230,
	},
	{
		garage_id: 76,
		part_id: 49,
		quantity: 363,
	},
	{
		garage_id: 76,
		part_id: 50,
		quantity: 111,
	},
	{
		garage_id: 76,
		part_id: 51,
		quantity: 378,
	},
	{
		garage_id: 76,
		part_id: 52,
		quantity: 81,
	},
	{
		garage_id: 76,
		part_id: 53,
		quantity: 317,
	},
	{
		garage_id: 76,
		part_id: 54,
		quantity: 410,
	},
	{
		garage_id: 76,
		part_id: 55,
		quantity: 346,
	},
	{
		garage_id: 76,
		part_id: 56,
		quantity: 267,
	},
	{
		garage_id: 76,
		part_id: 57,
		quantity: 454,
	},
	{
		garage_id: 76,
		part_id: 58,
		quantity: 442,
	},
	{
		garage_id: 76,
		part_id: 59,
		quantity: 63,
	},
	{
		garage_id: 76,
		part_id: 60,
		quantity: 258,
	},
	{
		garage_id: 76,
		part_id: 61,
		quantity: 229,
	},
	{
		garage_id: 76,
		part_id: 62,
		quantity: 462,
	},
	{
		garage_id: 76,
		part_id: 63,
		quantity: 151,
	},
	{
		garage_id: 76,
		part_id: 64,
		quantity: 438,
	},
	{
		garage_id: 76,
		part_id: 65,
		quantity: 40,
	},
	{
		garage_id: 76,
		part_id: 66,
		quantity: 234,
	},
	{
		garage_id: 76,
		part_id: 67,
		quantity: 109,
	},
	{
		garage_id: 76,
		part_id: 68,
		quantity: 63,
	},
	{
		garage_id: 76,
		part_id: 69,
		quantity: 66,
	},
	{
		garage_id: 76,
		part_id: 70,
		quantity: 236,
	},
	{
		garage_id: 76,
		part_id: 71,
		quantity: 152,
	},
	{
		garage_id: 76,
		part_id: 72,
		quantity: 118,
	},
	{
		garage_id: 76,
		part_id: 73,
		quantity: 293,
	},
	{
		garage_id: 76,
		part_id: 74,
		quantity: 399,
	},
	{
		garage_id: 76,
		part_id: 75,
		quantity: 412,
	},
	{
		garage_id: 76,
		part_id: 76,
		quantity: 472,
	},
	{
		garage_id: 76,
		part_id: 77,
		quantity: 444,
	},
	{
		garage_id: 76,
		part_id: 78,
		quantity: 337,
	},
	{
		garage_id: 76,
		part_id: 79,
		quantity: 259,
	},
	{
		garage_id: 76,
		part_id: 80,
		quantity: 58,
	},
	{
		garage_id: 76,
		part_id: 81,
		quantity: 412,
	},
	{
		garage_id: 76,
		part_id: 82,
		quantity: 433,
	},
	{
		garage_id: 76,
		part_id: 83,
		quantity: 21,
	},
	{
		garage_id: 76,
		part_id: 84,
		quantity: 466,
	},
	{
		garage_id: 76,
		part_id: 85,
		quantity: 369,
	},
	{
		garage_id: 76,
		part_id: 86,
		quantity: 24,
	},
	{
		garage_id: 76,
		part_id: 87,
		quantity: 374,
	},
	{
		garage_id: 76,
		part_id: 88,
		quantity: 159,
	},
	{
		garage_id: 76,
		part_id: 89,
		quantity: 31,
	},
	{
		garage_id: 76,
		part_id: 90,
		quantity: 136,
	},
	{
		garage_id: 76,
		part_id: 91,
		quantity: 16,
	},
	{
		garage_id: 76,
		part_id: 92,
		quantity: 319,
	},
	{
		garage_id: 76,
		part_id: 93,
		quantity: 245,
	},
	{
		garage_id: 76,
		part_id: 94,
		quantity: 110,
	},
	{
		garage_id: 76,
		part_id: 95,
		quantity: 49,
	},
	{
		garage_id: 76,
		part_id: 96,
		quantity: 26,
	},
	{
		garage_id: 76,
		part_id: 97,
		quantity: 34,
	},
	{
		garage_id: 76,
		part_id: 98,
		quantity: 406,
	},
	{
		garage_id: 76,
		part_id: 99,
		quantity: 48,
	},
	{
		garage_id: 76,
		part_id: 100,
		quantity: 136,
	},
	{
		garage_id: 76,
		part_id: 101,
		quantity: 281,
	},
	{
		garage_id: 76,
		part_id: 102,
		quantity: 266,
	},
	{
		garage_id: 76,
		part_id: 103,
		quantity: 159,
	},
	{
		garage_id: 76,
		part_id: 104,
		quantity: 384,
	},
	{
		garage_id: 76,
		part_id: 105,
		quantity: 84,
	},
	{
		garage_id: 76,
		part_id: 106,
		quantity: 132,
	},
	{
		garage_id: 76,
		part_id: 107,
		quantity: 198,
	},
	{
		garage_id: 76,
		part_id: 108,
		quantity: 396,
	},
	{
		garage_id: 76,
		part_id: 109,
		quantity: 394,
	},
	{
		garage_id: 76,
		part_id: 110,
		quantity: 298,
	},
	{
		garage_id: 76,
		part_id: 111,
		quantity: 23,
	},
	{
		garage_id: 76,
		part_id: 112,
		quantity: 38,
	},
	{
		garage_id: 76,
		part_id: 113,
		quantity: 102,
	},
	{
		garage_id: 76,
		part_id: 114,
		quantity: 31,
	},
	{
		garage_id: 76,
		part_id: 115,
		quantity: 105,
	},
	{
		garage_id: 76,
		part_id: 116,
		quantity: 319,
	},
	{
		garage_id: 76,
		part_id: 117,
		quantity: 96,
	},
	{
		garage_id: 76,
		part_id: 118,
		quantity: 108,
	},
	{
		garage_id: 76,
		part_id: 119,
		quantity: 463,
	},
	{
		garage_id: 76,
		part_id: 120,
		quantity: 163,
	},
	{
		garage_id: 76,
		part_id: 121,
		quantity: 380,
	},
	{
		garage_id: 76,
		part_id: 122,
		quantity: 341,
	},
	{
		garage_id: 76,
		part_id: 123,
		quantity: 101,
	},
	{
		garage_id: 76,
		part_id: 124,
		quantity: 154,
	},
	{
		garage_id: 76,
		part_id: 125,
		quantity: 45,
	},
	{
		garage_id: 76,
		part_id: 126,
		quantity: 88,
	},
	{
		garage_id: 76,
		part_id: 127,
		quantity: 23,
	},
	{
		garage_id: 76,
		part_id: 128,
		quantity: 492,
	},
	{
		garage_id: 76,
		part_id: 129,
		quantity: 340,
	},
	{
		garage_id: 76,
		part_id: 130,
		quantity: 365,
	},
	{
		garage_id: 76,
		part_id: 131,
		quantity: 245,
	},
	{
		garage_id: 76,
		part_id: 132,
		quantity: 129,
	},
	{
		garage_id: 76,
		part_id: 133,
		quantity: 58,
	},
	{
		garage_id: 76,
		part_id: 134,
		quantity: 409,
	},
	{
		garage_id: 76,
		part_id: 135,
		quantity: 49,
	},
	{
		garage_id: 76,
		part_id: 136,
		quantity: 99,
	},
	{
		garage_id: 76,
		part_id: 137,
		quantity: 413,
	},
	{
		garage_id: 76,
		part_id: 138,
		quantity: 479,
	},
	{
		garage_id: 76,
		part_id: 139,
		quantity: 400,
	},
	{
		garage_id: 76,
		part_id: 140,
		quantity: 452,
	},
	{
		garage_id: 76,
		part_id: 141,
		quantity: 330,
	},
	{
		garage_id: 76,
		part_id: 142,
		quantity: 492,
	},
	{
		garage_id: 76,
		part_id: 143,
		quantity: 66,
	},
	{
		garage_id: 76,
		part_id: 144,
		quantity: 266,
	},
	{
		garage_id: 76,
		part_id: 145,
		quantity: 136,
	},
	{
		garage_id: 76,
		part_id: 146,
		quantity: 475,
	},
	{
		garage_id: 76,
		part_id: 147,
		quantity: 82,
	},
	{
		garage_id: 76,
		part_id: 148,
		quantity: 349,
	},
	{
		garage_id: 76,
		part_id: 149,
		quantity: 367,
	},
	{
		garage_id: 76,
		part_id: 150,
		quantity: 278,
	},
	{
		garage_id: 76,
		part_id: 151,
		quantity: 346,
	},
	{
		garage_id: 76,
		part_id: 152,
		quantity: 463,
	},
	{
		garage_id: 76,
		part_id: 153,
		quantity: 213,
	},
	{
		garage_id: 76,
		part_id: 154,
		quantity: 128,
	},
	{
		garage_id: 76,
		part_id: 155,
		quantity: 68,
	},
	{
		garage_id: 76,
		part_id: 156,
		quantity: 27,
	},
	{
		garage_id: 76,
		part_id: 157,
		quantity: 428,
	},
	{
		garage_id: 76,
		part_id: 158,
		quantity: 1,
	},
	{
		garage_id: 76,
		part_id: 159,
		quantity: 299,
	},
	{
		garage_id: 76,
		part_id: 160,
		quantity: 484,
	},
	{
		garage_id: 76,
		part_id: 161,
		quantity: 182,
	},
	{
		garage_id: 76,
		part_id: 162,
		quantity: 64,
	},
	{
		garage_id: 76,
		part_id: 163,
		quantity: 319,
	},
	{
		garage_id: 76,
		part_id: 164,
		quantity: 195,
	},
	{
		garage_id: 76,
		part_id: 165,
		quantity: 309,
	},
	{
		garage_id: 76,
		part_id: 166,
		quantity: 439,
	},
	{
		garage_id: 76,
		part_id: 167,
		quantity: 69,
	},
	{
		garage_id: 76,
		part_id: 168,
		quantity: 13,
	},
	{
		garage_id: 76,
		part_id: 169,
		quantity: 206,
	},
	{
		garage_id: 76,
		part_id: 170,
		quantity: 297,
	},
	{
		garage_id: 76,
		part_id: 171,
		quantity: 497,
	},
	{
		garage_id: 76,
		part_id: 172,
		quantity: 419,
	},
	{
		garage_id: 76,
		part_id: 173,
		quantity: 70,
	},
	{
		garage_id: 76,
		part_id: 174,
		quantity: 444,
	},
	{
		garage_id: 76,
		part_id: 175,
		quantity: 422,
	},
	{
		garage_id: 76,
		part_id: 176,
		quantity: 240,
	},
	{
		garage_id: 76,
		part_id: 177,
		quantity: 164,
	},
	{
		garage_id: 76,
		part_id: 178,
		quantity: 122,
	},
	{
		garage_id: 76,
		part_id: 179,
		quantity: 132,
	},
	{
		garage_id: 76,
		part_id: 180,
		quantity: 284,
	},
	{
		garage_id: 76,
		part_id: 181,
		quantity: 58,
	},
	{
		garage_id: 76,
		part_id: 182,
		quantity: 107,
	},
	{
		garage_id: 76,
		part_id: 183,
		quantity: 376,
	},
	{
		garage_id: 76,
		part_id: 184,
		quantity: 154,
	},
	{
		garage_id: 76,
		part_id: 185,
		quantity: 378,
	},
	{
		garage_id: 76,
		part_id: 186,
		quantity: 5,
	},
	{
		garage_id: 76,
		part_id: 187,
		quantity: 150,
	},
	{
		garage_id: 76,
		part_id: 188,
		quantity: 261,
	},
	{
		garage_id: 76,
		part_id: 189,
		quantity: 408,
	},
	{
		garage_id: 76,
		part_id: 190,
		quantity: 167,
	},
	{
		garage_id: 76,
		part_id: 191,
		quantity: 481,
	},
	{
		garage_id: 76,
		part_id: 192,
		quantity: 406,
	},
	{
		garage_id: 76,
		part_id: 193,
		quantity: 135,
	},
	{
		garage_id: 76,
		part_id: 194,
		quantity: 377,
	},
	{
		garage_id: 76,
		part_id: 195,
		quantity: 187,
	},
	{
		garage_id: 76,
		part_id: 196,
		quantity: 178,
	},
	{
		garage_id: 76,
		part_id: 197,
		quantity: 429,
	},
	{
		garage_id: 76,
		part_id: 198,
		quantity: 370,
	},
	{
		garage_id: 76,
		part_id: 199,
		quantity: 13,
	},
	{
		garage_id: 76,
		part_id: 200,
		quantity: 344,
	},
	{
		garage_id: 76,
		part_id: 201,
		quantity: 82,
	},
	{
		garage_id: 76,
		part_id: 202,
		quantity: 189,
	},
	{
		garage_id: 76,
		part_id: 203,
		quantity: 375,
	},
	{
		garage_id: 76,
		part_id: 204,
		quantity: 381,
	},
	{
		garage_id: 76,
		part_id: 205,
		quantity: 239,
	},
	{
		garage_id: 76,
		part_id: 206,
		quantity: 35,
	},
	{
		garage_id: 76,
		part_id: 207,
		quantity: 241,
	},
	{
		garage_id: 76,
		part_id: 208,
		quantity: 376,
	},
	{
		garage_id: 76,
		part_id: 209,
		quantity: 202,
	},
	{
		garage_id: 76,
		part_id: 210,
		quantity: 375,
	},
	{
		garage_id: 76,
		part_id: 211,
		quantity: 500,
	},
	{
		garage_id: 76,
		part_id: 212,
		quantity: 382,
	},
	{
		garage_id: 76,
		part_id: 213,
		quantity: 13,
	},
	{
		garage_id: 76,
		part_id: 214,
		quantity: 48,
	},
	{
		garage_id: 76,
		part_id: 215,
		quantity: 497,
	},
	{
		garage_id: 76,
		part_id: 216,
		quantity: 142,
	},
	{
		garage_id: 76,
		part_id: 217,
		quantity: 192,
	},
	{
		garage_id: 76,
		part_id: 218,
		quantity: 74,
	},
	{
		garage_id: 76,
		part_id: 219,
		quantity: 33,
	},
	{
		garage_id: 76,
		part_id: 220,
		quantity: 153,
	},
	{
		garage_id: 76,
		part_id: 221,
		quantity: 149,
	},
	{
		garage_id: 76,
		part_id: 222,
		quantity: 184,
	},
	{
		garage_id: 76,
		part_id: 223,
		quantity: 192,
	},
	{
		garage_id: 76,
		part_id: 224,
		quantity: 356,
	},
	{
		garage_id: 76,
		part_id: 225,
		quantity: 300,
	},
	{
		garage_id: 76,
		part_id: 226,
		quantity: 209,
	},
	{
		garage_id: 76,
		part_id: 227,
		quantity: 300,
	},
	{
		garage_id: 76,
		part_id: 228,
		quantity: 33,
	},
	{
		garage_id: 76,
		part_id: 229,
		quantity: 171,
	},
	{
		garage_id: 76,
		part_id: 230,
		quantity: 383,
	},
	{
		garage_id: 76,
		part_id: 231,
		quantity: 322,
	},
	{
		garage_id: 76,
		part_id: 232,
		quantity: 293,
	},
	{
		garage_id: 76,
		part_id: 233,
		quantity: 378,
	},
	{
		garage_id: 76,
		part_id: 234,
		quantity: 40,
	},
	{
		garage_id: 76,
		part_id: 235,
		quantity: 146,
	},
	{
		garage_id: 76,
		part_id: 236,
		quantity: 343,
	},
	{
		garage_id: 76,
		part_id: 237,
		quantity: 1,
	},
	{
		garage_id: 76,
		part_id: 238,
		quantity: 154,
	},
	{
		garage_id: 76,
		part_id: 239,
		quantity: 302,
	},
	{
		garage_id: 76,
		part_id: 240,
		quantity: 414,
	},
	{
		garage_id: 76,
		part_id: 241,
		quantity: 379,
	},
	{
		garage_id: 76,
		part_id: 242,
		quantity: 95,
	},
	{
		garage_id: 76,
		part_id: 243,
		quantity: 483,
	},
	{
		garage_id: 76,
		part_id: 244,
		quantity: 500,
	},
	{
		garage_id: 76,
		part_id: 245,
		quantity: 287,
	},
	{
		garage_id: 76,
		part_id: 246,
		quantity: 204,
	},
	{
		garage_id: 76,
		part_id: 247,
		quantity: 327,
	},
	{
		garage_id: 76,
		part_id: 248,
		quantity: 238,
	},
	{
		garage_id: 76,
		part_id: 249,
		quantity: 349,
	},
	{
		garage_id: 76,
		part_id: 250,
		quantity: 291,
	},
	{
		garage_id: 76,
		part_id: 251,
		quantity: 171,
	},
	{
		garage_id: 76,
		part_id: 252,
		quantity: 485,
	},
	{
		garage_id: 76,
		part_id: 253,
		quantity: 20,
	},
	{
		garage_id: 76,
		part_id: 254,
		quantity: 31,
	},
	{
		garage_id: 76,
		part_id: 255,
		quantity: 194,
	},
	{
		garage_id: 76,
		part_id: 256,
		quantity: 82,
	},
	{
		garage_id: 76,
		part_id: 257,
		quantity: 181,
	},
	{
		garage_id: 76,
		part_id: 258,
		quantity: 140,
	},
	{
		garage_id: 76,
		part_id: 259,
		quantity: 176,
	},
	{
		garage_id: 76,
		part_id: 260,
		quantity: 69,
	},
	{
		garage_id: 76,
		part_id: 261,
		quantity: 180,
	},
	{
		garage_id: 76,
		part_id: 262,
		quantity: 494,
	},
	{
		garage_id: 76,
		part_id: 263,
		quantity: 118,
	},
	{
		garage_id: 76,
		part_id: 264,
		quantity: 33,
	},
	{
		garage_id: 76,
		part_id: 265,
		quantity: 86,
	},
	{
		garage_id: 76,
		part_id: 266,
		quantity: 33,
	},
	{
		garage_id: 76,
		part_id: 267,
		quantity: 277,
	},
	{
		garage_id: 76,
		part_id: 268,
		quantity: 117,
	},
	{
		garage_id: 76,
		part_id: 269,
		quantity: 387,
	},
	{
		garage_id: 76,
		part_id: 270,
		quantity: 39,
	},
	{
		garage_id: 76,
		part_id: 271,
		quantity: 296,
	},
	{
		garage_id: 76,
		part_id: 272,
		quantity: 317,
	},
	{
		garage_id: 76,
		part_id: 273,
		quantity: 471,
	},
	{
		garage_id: 76,
		part_id: 274,
		quantity: 322,
	},
	{
		garage_id: 76,
		part_id: 275,
		quantity: 201,
	},
	{
		garage_id: 76,
		part_id: 276,
		quantity: 131,
	},
	{
		garage_id: 76,
		part_id: 277,
		quantity: 101,
	},
	{
		garage_id: 76,
		part_id: 278,
		quantity: 85,
	},
	{
		garage_id: 76,
		part_id: 279,
		quantity: 240,
	},
	{
		garage_id: 76,
		part_id: 280,
		quantity: 280,
	},
	{
		garage_id: 76,
		part_id: 281,
		quantity: 281,
	},
	{
		garage_id: 76,
		part_id: 282,
		quantity: 482,
	},
	{
		garage_id: 76,
		part_id: 283,
		quantity: 379,
	},
	{
		garage_id: 76,
		part_id: 284,
		quantity: 415,
	},
	{
		garage_id: 76,
		part_id: 285,
		quantity: 424,
	},
	{
		garage_id: 76,
		part_id: 286,
		quantity: 365,
	},
	{
		garage_id: 76,
		part_id: 287,
		quantity: 43,
	},
	{
		garage_id: 76,
		part_id: 288,
		quantity: 89,
	},
	{
		garage_id: 76,
		part_id: 289,
		quantity: 257,
	},
	{
		garage_id: 76,
		part_id: 290,
		quantity: 48,
	},
	{
		garage_id: 76,
		part_id: 291,
		quantity: 193,
	},
	{
		garage_id: 76,
		part_id: 292,
		quantity: 300,
	},
	{
		garage_id: 76,
		part_id: 293,
		quantity: 379,
	},
	{
		garage_id: 76,
		part_id: 294,
		quantity: 428,
	},
	{
		garage_id: 76,
		part_id: 295,
		quantity: 435,
	},
	{
		garage_id: 76,
		part_id: 296,
		quantity: 430,
	},
	{
		garage_id: 76,
		part_id: 297,
		quantity: 31,
	},
	{
		garage_id: 76,
		part_id: 298,
		quantity: 156,
	},
	{
		garage_id: 76,
		part_id: 299,
		quantity: 184,
	},
	{
		garage_id: 76,
		part_id: 300,
		quantity: 151,
	},
	{
		garage_id: 76,
		part_id: 301,
		quantity: 461,
	},
	{
		garage_id: 76,
		part_id: 302,
		quantity: 191,
	},
	{
		garage_id: 76,
		part_id: 303,
		quantity: 414,
	},
	{
		garage_id: 76,
		part_id: 304,
		quantity: 310,
	},
	{
		garage_id: 76,
		part_id: 305,
		quantity: 356,
	},
	{
		garage_id: 76,
		part_id: 306,
		quantity: 86,
	},
	{
		garage_id: 76,
		part_id: 307,
		quantity: 437,
	},
	{
		garage_id: 76,
		part_id: 308,
		quantity: 6,
	},
	{
		garage_id: 76,
		part_id: 309,
		quantity: 267,
	},
	{
		garage_id: 76,
		part_id: 310,
		quantity: 261,
	},
	{
		garage_id: 76,
		part_id: 311,
		quantity: 61,
	},
	{
		garage_id: 76,
		part_id: 312,
		quantity: 282,
	},
	{
		garage_id: 76,
		part_id: 313,
		quantity: 429,
	},
	{
		garage_id: 76,
		part_id: 314,
		quantity: 137,
	},
	{
		garage_id: 76,
		part_id: 315,
		quantity: 194,
	},
	{
		garage_id: 76,
		part_id: 316,
		quantity: 354,
	},
	{
		garage_id: 76,
		part_id: 317,
		quantity: 337,
	},
	{
		garage_id: 76,
		part_id: 318,
		quantity: 228,
	},
	{
		garage_id: 76,
		part_id: 319,
		quantity: 368,
	},
	{
		garage_id: 76,
		part_id: 320,
		quantity: 151,
	},
	{
		garage_id: 76,
		part_id: 321,
		quantity: 157,
	},
	{
		garage_id: 76,
		part_id: 322,
		quantity: 289,
	},
	{
		garage_id: 76,
		part_id: 323,
		quantity: 424,
	},
	{
		garage_id: 76,
		part_id: 324,
		quantity: 285,
	},
	{
		garage_id: 76,
		part_id: 325,
		quantity: 462,
	},
	{
		garage_id: 76,
		part_id: 326,
		quantity: 408,
	},
	{
		garage_id: 76,
		part_id: 327,
		quantity: 434,
	},
	{
		garage_id: 76,
		part_id: 328,
		quantity: 161,
	},
	{
		garage_id: 76,
		part_id: 329,
		quantity: 309,
	},
	{
		garage_id: 76,
		part_id: 330,
		quantity: 272,
	},
	{
		garage_id: 76,
		part_id: 331,
		quantity: 130,
	},
	{
		garage_id: 76,
		part_id: 332,
		quantity: 63,
	},
	{
		garage_id: 76,
		part_id: 333,
		quantity: 2,
	},
	{
		garage_id: 76,
		part_id: 334,
		quantity: 245,
	},
	{
		garage_id: 76,
		part_id: 335,
		quantity: 201,
	},
	{
		garage_id: 76,
		part_id: 336,
		quantity: 453,
	},
	{
		garage_id: 76,
		part_id: 337,
		quantity: 282,
	},
	{
		garage_id: 76,
		part_id: 338,
		quantity: 208,
	},
	{
		garage_id: 76,
		part_id: 339,
		quantity: 431,
	},
	{
		garage_id: 76,
		part_id: 340,
		quantity: 23,
	},
	{
		garage_id: 76,
		part_id: 341,
		quantity: 267,
	},
	{
		garage_id: 76,
		part_id: 342,
		quantity: 307,
	},
	{
		garage_id: 76,
		part_id: 343,
		quantity: 493,
	},
	{
		garage_id: 76,
		part_id: 344,
		quantity: 450,
	},
	{
		garage_id: 76,
		part_id: 345,
		quantity: 212,
	},
	{
		garage_id: 76,
		part_id: 346,
		quantity: 271,
	},
	{
		garage_id: 76,
		part_id: 347,
		quantity: 29,
	},
	{
		garage_id: 76,
		part_id: 348,
		quantity: 419,
	},
	{
		garage_id: 76,
		part_id: 349,
		quantity: 482,
	},
	{
		garage_id: 76,
		part_id: 350,
		quantity: 197,
	},
	{
		garage_id: 76,
		part_id: 351,
		quantity: 376,
	},
	{
		garage_id: 76,
		part_id: 352,
		quantity: 192,
	},
	{
		garage_id: 76,
		part_id: 353,
		quantity: 341,
	},
	{
		garage_id: 76,
		part_id: 354,
		quantity: 223,
	},
	{
		garage_id: 76,
		part_id: 355,
		quantity: 217,
	},
	{
		garage_id: 76,
		part_id: 356,
		quantity: 334,
	},
	{
		garage_id: 76,
		part_id: 357,
		quantity: 116,
	},
	{
		garage_id: 76,
		part_id: 358,
		quantity: 453,
	},
	{
		garage_id: 76,
		part_id: 359,
		quantity: 84,
	},
	{
		garage_id: 76,
		part_id: 360,
		quantity: 20,
	},
	{
		garage_id: 76,
		part_id: 361,
		quantity: 202,
	},
	{
		garage_id: 76,
		part_id: 362,
		quantity: 198,
	},
	{
		garage_id: 76,
		part_id: 363,
		quantity: 161,
	},
	{
		garage_id: 76,
		part_id: 364,
		quantity: 302,
	},
	{
		garage_id: 76,
		part_id: 365,
		quantity: 181,
	},
	{
		garage_id: 76,
		part_id: 366,
		quantity: 397,
	},
	{
		garage_id: 76,
		part_id: 367,
		quantity: 205,
	},
	{
		garage_id: 76,
		part_id: 368,
		quantity: 305,
	},
	{
		garage_id: 76,
		part_id: 369,
		quantity: 391,
	},
	{
		garage_id: 76,
		part_id: 370,
		quantity: 475,
	},
	{
		garage_id: 76,
		part_id: 371,
		quantity: 5,
	},
	{
		garage_id: 76,
		part_id: 372,
		quantity: 192,
	},
	{
		garage_id: 76,
		part_id: 373,
		quantity: 187,
	},
	{
		garage_id: 76,
		part_id: 374,
		quantity: 339,
	},
	{
		garage_id: 76,
		part_id: 375,
		quantity: 82,
	},
	{
		garage_id: 76,
		part_id: 376,
		quantity: 246,
	},
	{
		garage_id: 76,
		part_id: 377,
		quantity: 428,
	},
	{
		garage_id: 76,
		part_id: 378,
		quantity: 171,
	},
	{
		garage_id: 76,
		part_id: 379,
		quantity: 115,
	},
	{
		garage_id: 76,
		part_id: 380,
		quantity: 257,
	},
	{
		garage_id: 76,
		part_id: 381,
		quantity: 110,
	},
	{
		garage_id: 76,
		part_id: 382,
		quantity: 193,
	},
	{
		garage_id: 76,
		part_id: 383,
		quantity: 395,
	},
	{
		garage_id: 76,
		part_id: 384,
		quantity: 123,
	},
	{
		garage_id: 76,
		part_id: 385,
		quantity: 161,
	},
	{
		garage_id: 76,
		part_id: 386,
		quantity: 185,
	},
	{
		garage_id: 76,
		part_id: 387,
		quantity: 295,
	},
	{
		garage_id: 76,
		part_id: 388,
		quantity: 315,
	},
	{
		garage_id: 76,
		part_id: 389,
		quantity: 490,
	},
	{
		garage_id: 76,
		part_id: 390,
		quantity: 279,
	},
	{
		garage_id: 76,
		part_id: 391,
		quantity: 0,
	},
	{
		garage_id: 76,
		part_id: 392,
		quantity: 196,
	},
	{
		garage_id: 76,
		part_id: 393,
		quantity: 357,
	},
	{
		garage_id: 76,
		part_id: 394,
		quantity: 199,
	},
	{
		garage_id: 76,
		part_id: 395,
		quantity: 386,
	},
	{
		garage_id: 76,
		part_id: 396,
		quantity: 362,
	},
	{
		garage_id: 76,
		part_id: 397,
		quantity: 340,
	},
	{
		garage_id: 76,
		part_id: 398,
		quantity: 321,
	},
	{
		garage_id: 76,
		part_id: 399,
		quantity: 294,
	},
	{
		garage_id: 77,
		part_id: 0,
		quantity: 420,
	},
	{
		garage_id: 77,
		part_id: 1,
		quantity: 219,
	},
	{
		garage_id: 77,
		part_id: 2,
		quantity: 322,
	},
	{
		garage_id: 77,
		part_id: 3,
		quantity: 307,
	},
	{
		garage_id: 77,
		part_id: 4,
		quantity: 102,
	},
	{
		garage_id: 77,
		part_id: 5,
		quantity: 192,
	},
	{
		garage_id: 77,
		part_id: 6,
		quantity: 363,
	},
	{
		garage_id: 77,
		part_id: 7,
		quantity: 46,
	},
	{
		garage_id: 77,
		part_id: 8,
		quantity: 368,
	},
	{
		garage_id: 77,
		part_id: 9,
		quantity: 451,
	},
	{
		garage_id: 77,
		part_id: 10,
		quantity: 4,
	},
	{
		garage_id: 77,
		part_id: 11,
		quantity: 12,
	},
	{
		garage_id: 77,
		part_id: 12,
		quantity: 463,
	},
	{
		garage_id: 77,
		part_id: 13,
		quantity: 47,
	},
	{
		garage_id: 77,
		part_id: 14,
		quantity: 239,
	},
	{
		garage_id: 77,
		part_id: 15,
		quantity: 35,
	},
	{
		garage_id: 77,
		part_id: 16,
		quantity: 64,
	},
	{
		garage_id: 77,
		part_id: 17,
		quantity: 171,
	},
	{
		garage_id: 77,
		part_id: 18,
		quantity: 4,
	},
	{
		garage_id: 77,
		part_id: 19,
		quantity: 193,
	},
	{
		garage_id: 77,
		part_id: 20,
		quantity: 193,
	},
	{
		garage_id: 77,
		part_id: 21,
		quantity: 364,
	},
	{
		garage_id: 77,
		part_id: 22,
		quantity: 435,
	},
	{
		garage_id: 77,
		part_id: 23,
		quantity: 2,
	},
	{
		garage_id: 77,
		part_id: 24,
		quantity: 248,
	},
	{
		garage_id: 77,
		part_id: 25,
		quantity: 66,
	},
	{
		garage_id: 77,
		part_id: 26,
		quantity: 257,
	},
	{
		garage_id: 77,
		part_id: 27,
		quantity: 408,
	},
	{
		garage_id: 77,
		part_id: 28,
		quantity: 244,
	},
	{
		garage_id: 77,
		part_id: 29,
		quantity: 170,
	},
	{
		garage_id: 77,
		part_id: 30,
		quantity: 365,
	},
	{
		garage_id: 77,
		part_id: 31,
		quantity: 396,
	},
	{
		garage_id: 77,
		part_id: 32,
		quantity: 255,
	},
	{
		garage_id: 77,
		part_id: 33,
		quantity: 179,
	},
	{
		garage_id: 77,
		part_id: 34,
		quantity: 490,
	},
	{
		garage_id: 77,
		part_id: 35,
		quantity: 147,
	},
	{
		garage_id: 77,
		part_id: 36,
		quantity: 342,
	},
	{
		garage_id: 77,
		part_id: 37,
		quantity: 282,
	},
	{
		garage_id: 77,
		part_id: 38,
		quantity: 113,
	},
	{
		garage_id: 77,
		part_id: 39,
		quantity: 349,
	},
	{
		garage_id: 77,
		part_id: 40,
		quantity: 325,
	},
	{
		garage_id: 77,
		part_id: 41,
		quantity: 400,
	},
	{
		garage_id: 77,
		part_id: 42,
		quantity: 11,
	},
	{
		garage_id: 77,
		part_id: 43,
		quantity: 159,
	},
	{
		garage_id: 77,
		part_id: 44,
		quantity: 130,
	},
	{
		garage_id: 77,
		part_id: 45,
		quantity: 464,
	},
	{
		garage_id: 77,
		part_id: 46,
		quantity: 99,
	},
	{
		garage_id: 77,
		part_id: 47,
		quantity: 304,
	},
	{
		garage_id: 77,
		part_id: 48,
		quantity: 213,
	},
	{
		garage_id: 77,
		part_id: 49,
		quantity: 491,
	},
	{
		garage_id: 77,
		part_id: 50,
		quantity: 448,
	},
	{
		garage_id: 77,
		part_id: 51,
		quantity: 144,
	},
	{
		garage_id: 77,
		part_id: 52,
		quantity: 384,
	},
	{
		garage_id: 77,
		part_id: 53,
		quantity: 362,
	},
	{
		garage_id: 77,
		part_id: 54,
		quantity: 40,
	},
	{
		garage_id: 77,
		part_id: 55,
		quantity: 342,
	},
	{
		garage_id: 77,
		part_id: 56,
		quantity: 161,
	},
	{
		garage_id: 77,
		part_id: 57,
		quantity: 490,
	},
	{
		garage_id: 77,
		part_id: 58,
		quantity: 38,
	},
	{
		garage_id: 77,
		part_id: 59,
		quantity: 381,
	},
	{
		garage_id: 77,
		part_id: 60,
		quantity: 378,
	},
	{
		garage_id: 77,
		part_id: 61,
		quantity: 137,
	},
	{
		garage_id: 77,
		part_id: 62,
		quantity: 64,
	},
	{
		garage_id: 77,
		part_id: 63,
		quantity: 168,
	},
	{
		garage_id: 77,
		part_id: 64,
		quantity: 443,
	},
	{
		garage_id: 77,
		part_id: 65,
		quantity: 200,
	},
	{
		garage_id: 77,
		part_id: 66,
		quantity: 493,
	},
	{
		garage_id: 77,
		part_id: 67,
		quantity: 68,
	},
	{
		garage_id: 77,
		part_id: 68,
		quantity: 53,
	},
	{
		garage_id: 77,
		part_id: 69,
		quantity: 301,
	},
	{
		garage_id: 77,
		part_id: 70,
		quantity: 24,
	},
	{
		garage_id: 77,
		part_id: 71,
		quantity: 489,
	},
	{
		garage_id: 77,
		part_id: 72,
		quantity: 305,
	},
	{
		garage_id: 77,
		part_id: 73,
		quantity: 125,
	},
	{
		garage_id: 77,
		part_id: 74,
		quantity: 147,
	},
	{
		garage_id: 77,
		part_id: 75,
		quantity: 156,
	},
	{
		garage_id: 77,
		part_id: 76,
		quantity: 75,
	},
	{
		garage_id: 77,
		part_id: 77,
		quantity: 396,
	},
	{
		garage_id: 77,
		part_id: 78,
		quantity: 369,
	},
	{
		garage_id: 77,
		part_id: 79,
		quantity: 198,
	},
	{
		garage_id: 77,
		part_id: 80,
		quantity: 348,
	},
	{
		garage_id: 77,
		part_id: 81,
		quantity: 42,
	},
	{
		garage_id: 77,
		part_id: 82,
		quantity: 248,
	},
	{
		garage_id: 77,
		part_id: 83,
		quantity: 384,
	},
	{
		garage_id: 77,
		part_id: 84,
		quantity: 129,
	},
	{
		garage_id: 77,
		part_id: 85,
		quantity: 344,
	},
	{
		garage_id: 77,
		part_id: 86,
		quantity: 420,
	},
	{
		garage_id: 77,
		part_id: 87,
		quantity: 442,
	},
	{
		garage_id: 77,
		part_id: 88,
		quantity: 426,
	},
	{
		garage_id: 77,
		part_id: 89,
		quantity: 133,
	},
	{
		garage_id: 77,
		part_id: 90,
		quantity: 66,
	},
	{
		garage_id: 77,
		part_id: 91,
		quantity: 286,
	},
	{
		garage_id: 77,
		part_id: 92,
		quantity: 257,
	},
	{
		garage_id: 77,
		part_id: 93,
		quantity: 88,
	},
	{
		garage_id: 77,
		part_id: 94,
		quantity: 251,
	},
	{
		garage_id: 77,
		part_id: 95,
		quantity: 207,
	},
	{
		garage_id: 77,
		part_id: 96,
		quantity: 370,
	},
	{
		garage_id: 77,
		part_id: 97,
		quantity: 335,
	},
	{
		garage_id: 77,
		part_id: 98,
		quantity: 36,
	},
	{
		garage_id: 77,
		part_id: 99,
		quantity: 490,
	},
	{
		garage_id: 77,
		part_id: 100,
		quantity: 124,
	},
	{
		garage_id: 77,
		part_id: 101,
		quantity: 43,
	},
	{
		garage_id: 77,
		part_id: 102,
		quantity: 369,
	},
	{
		garage_id: 77,
		part_id: 103,
		quantity: 232,
	},
	{
		garage_id: 77,
		part_id: 104,
		quantity: 356,
	},
	{
		garage_id: 77,
		part_id: 105,
		quantity: 173,
	},
	{
		garage_id: 77,
		part_id: 106,
		quantity: 17,
	},
	{
		garage_id: 77,
		part_id: 107,
		quantity: 326,
	},
	{
		garage_id: 77,
		part_id: 108,
		quantity: 302,
	},
	{
		garage_id: 77,
		part_id: 109,
		quantity: 349,
	},
	{
		garage_id: 77,
		part_id: 110,
		quantity: 457,
	},
	{
		garage_id: 77,
		part_id: 111,
		quantity: 471,
	},
	{
		garage_id: 77,
		part_id: 112,
		quantity: 334,
	},
	{
		garage_id: 77,
		part_id: 113,
		quantity: 475,
	},
	{
		garage_id: 77,
		part_id: 114,
		quantity: 3,
	},
	{
		garage_id: 77,
		part_id: 115,
		quantity: 471,
	},
	{
		garage_id: 77,
		part_id: 116,
		quantity: 465,
	},
	{
		garage_id: 77,
		part_id: 117,
		quantity: 274,
	},
	{
		garage_id: 77,
		part_id: 118,
		quantity: 375,
	},
	{
		garage_id: 77,
		part_id: 119,
		quantity: 418,
	},
	{
		garage_id: 77,
		part_id: 120,
		quantity: 6,
	},
	{
		garage_id: 77,
		part_id: 121,
		quantity: 6,
	},
	{
		garage_id: 77,
		part_id: 122,
		quantity: 323,
	},
	{
		garage_id: 77,
		part_id: 123,
		quantity: 204,
	},
	{
		garage_id: 77,
		part_id: 124,
		quantity: 17,
	},
	{
		garage_id: 77,
		part_id: 125,
		quantity: 7,
	},
	{
		garage_id: 77,
		part_id: 126,
		quantity: 170,
	},
	{
		garage_id: 77,
		part_id: 127,
		quantity: 18,
	},
	{
		garage_id: 77,
		part_id: 128,
		quantity: 24,
	},
	{
		garage_id: 77,
		part_id: 129,
		quantity: 320,
	},
	{
		garage_id: 77,
		part_id: 130,
		quantity: 94,
	},
	{
		garage_id: 77,
		part_id: 131,
		quantity: 356,
	},
	{
		garage_id: 77,
		part_id: 132,
		quantity: 221,
	},
	{
		garage_id: 77,
		part_id: 133,
		quantity: 316,
	},
	{
		garage_id: 77,
		part_id: 134,
		quantity: 128,
	},
	{
		garage_id: 77,
		part_id: 135,
		quantity: 104,
	},
	{
		garage_id: 77,
		part_id: 136,
		quantity: 125,
	},
	{
		garage_id: 77,
		part_id: 137,
		quantity: 45,
	},
	{
		garage_id: 77,
		part_id: 138,
		quantity: 97,
	},
	{
		garage_id: 77,
		part_id: 139,
		quantity: 94,
	},
	{
		garage_id: 77,
		part_id: 140,
		quantity: 95,
	},
	{
		garage_id: 77,
		part_id: 141,
		quantity: 427,
	},
	{
		garage_id: 77,
		part_id: 142,
		quantity: 425,
	},
	{
		garage_id: 77,
		part_id: 143,
		quantity: 173,
	},
	{
		garage_id: 77,
		part_id: 144,
		quantity: 455,
	},
	{
		garage_id: 77,
		part_id: 145,
		quantity: 172,
	},
	{
		garage_id: 77,
		part_id: 146,
		quantity: 232,
	},
	{
		garage_id: 77,
		part_id: 147,
		quantity: 9,
	},
	{
		garage_id: 77,
		part_id: 148,
		quantity: 467,
	},
	{
		garage_id: 77,
		part_id: 149,
		quantity: 373,
	},
	{
		garage_id: 77,
		part_id: 150,
		quantity: 196,
	},
	{
		garage_id: 77,
		part_id: 151,
		quantity: 429,
	},
	{
		garage_id: 77,
		part_id: 152,
		quantity: 182,
	},
	{
		garage_id: 77,
		part_id: 153,
		quantity: 422,
	},
	{
		garage_id: 77,
		part_id: 154,
		quantity: 23,
	},
	{
		garage_id: 77,
		part_id: 155,
		quantity: 457,
	},
	{
		garage_id: 77,
		part_id: 156,
		quantity: 288,
	},
	{
		garage_id: 77,
		part_id: 157,
		quantity: 449,
	},
	{
		garage_id: 77,
		part_id: 158,
		quantity: 377,
	},
	{
		garage_id: 77,
		part_id: 159,
		quantity: 150,
	},
	{
		garage_id: 77,
		part_id: 160,
		quantity: 438,
	},
	{
		garage_id: 77,
		part_id: 161,
		quantity: 342,
	},
	{
		garage_id: 77,
		part_id: 162,
		quantity: 3,
	},
	{
		garage_id: 77,
		part_id: 163,
		quantity: 317,
	},
	{
		garage_id: 77,
		part_id: 164,
		quantity: 400,
	},
	{
		garage_id: 77,
		part_id: 165,
		quantity: 157,
	},
	{
		garage_id: 77,
		part_id: 166,
		quantity: 332,
	},
	{
		garage_id: 77,
		part_id: 167,
		quantity: 270,
	},
	{
		garage_id: 77,
		part_id: 168,
		quantity: 311,
	},
	{
		garage_id: 77,
		part_id: 169,
		quantity: 174,
	},
	{
		garage_id: 77,
		part_id: 170,
		quantity: 159,
	},
	{
		garage_id: 77,
		part_id: 171,
		quantity: 251,
	},
	{
		garage_id: 77,
		part_id: 172,
		quantity: 309,
	},
	{
		garage_id: 77,
		part_id: 173,
		quantity: 88,
	},
	{
		garage_id: 77,
		part_id: 174,
		quantity: 335,
	},
	{
		garage_id: 77,
		part_id: 175,
		quantity: 462,
	},
	{
		garage_id: 77,
		part_id: 176,
		quantity: 338,
	},
	{
		garage_id: 77,
		part_id: 177,
		quantity: 202,
	},
	{
		garage_id: 77,
		part_id: 178,
		quantity: 159,
	},
	{
		garage_id: 77,
		part_id: 179,
		quantity: 145,
	},
	{
		garage_id: 77,
		part_id: 180,
		quantity: 328,
	},
	{
		garage_id: 77,
		part_id: 181,
		quantity: 264,
	},
	{
		garage_id: 77,
		part_id: 182,
		quantity: 344,
	},
	{
		garage_id: 77,
		part_id: 183,
		quantity: 116,
	},
	{
		garage_id: 77,
		part_id: 184,
		quantity: 285,
	},
	{
		garage_id: 77,
		part_id: 185,
		quantity: 458,
	},
	{
		garage_id: 77,
		part_id: 186,
		quantity: 464,
	},
	{
		garage_id: 77,
		part_id: 187,
		quantity: 116,
	},
	{
		garage_id: 77,
		part_id: 188,
		quantity: 25,
	},
	{
		garage_id: 77,
		part_id: 189,
		quantity: 99,
	},
	{
		garage_id: 77,
		part_id: 190,
		quantity: 113,
	},
	{
		garage_id: 77,
		part_id: 191,
		quantity: 200,
	},
	{
		garage_id: 77,
		part_id: 192,
		quantity: 47,
	},
	{
		garage_id: 77,
		part_id: 193,
		quantity: 455,
	},
	{
		garage_id: 77,
		part_id: 194,
		quantity: 332,
	},
	{
		garage_id: 77,
		part_id: 195,
		quantity: 365,
	},
	{
		garage_id: 77,
		part_id: 196,
		quantity: 423,
	},
	{
		garage_id: 77,
		part_id: 197,
		quantity: 306,
	},
	{
		garage_id: 77,
		part_id: 198,
		quantity: 88,
	},
	{
		garage_id: 77,
		part_id: 199,
		quantity: 179,
	},
	{
		garage_id: 77,
		part_id: 200,
		quantity: 40,
	},
	{
		garage_id: 77,
		part_id: 201,
		quantity: 482,
	},
	{
		garage_id: 77,
		part_id: 202,
		quantity: 302,
	},
	{
		garage_id: 77,
		part_id: 203,
		quantity: 50,
	},
	{
		garage_id: 77,
		part_id: 204,
		quantity: 248,
	},
	{
		garage_id: 77,
		part_id: 205,
		quantity: 163,
	},
	{
		garage_id: 77,
		part_id: 206,
		quantity: 444,
	},
	{
		garage_id: 77,
		part_id: 207,
		quantity: 204,
	},
	{
		garage_id: 77,
		part_id: 208,
		quantity: 134,
	},
	{
		garage_id: 77,
		part_id: 209,
		quantity: 346,
	},
	{
		garage_id: 77,
		part_id: 210,
		quantity: 119,
	},
	{
		garage_id: 77,
		part_id: 211,
		quantity: 491,
	},
	{
		garage_id: 77,
		part_id: 212,
		quantity: 327,
	},
	{
		garage_id: 77,
		part_id: 213,
		quantity: 52,
	},
	{
		garage_id: 77,
		part_id: 214,
		quantity: 391,
	},
	{
		garage_id: 77,
		part_id: 215,
		quantity: 208,
	},
	{
		garage_id: 77,
		part_id: 216,
		quantity: 66,
	},
	{
		garage_id: 77,
		part_id: 217,
		quantity: 313,
	},
	{
		garage_id: 77,
		part_id: 218,
		quantity: 141,
	},
	{
		garage_id: 77,
		part_id: 219,
		quantity: 454,
	},
	{
		garage_id: 77,
		part_id: 220,
		quantity: 266,
	},
	{
		garage_id: 77,
		part_id: 221,
		quantity: 90,
	},
	{
		garage_id: 77,
		part_id: 222,
		quantity: 106,
	},
	{
		garage_id: 77,
		part_id: 223,
		quantity: 462,
	},
	{
		garage_id: 77,
		part_id: 224,
		quantity: 270,
	},
	{
		garage_id: 77,
		part_id: 225,
		quantity: 338,
	},
	{
		garage_id: 77,
		part_id: 226,
		quantity: 79,
	},
	{
		garage_id: 77,
		part_id: 227,
		quantity: 144,
	},
	{
		garage_id: 77,
		part_id: 228,
		quantity: 399,
	},
	{
		garage_id: 77,
		part_id: 229,
		quantity: 456,
	},
	{
		garage_id: 77,
		part_id: 230,
		quantity: 113,
	},
	{
		garage_id: 77,
		part_id: 231,
		quantity: 14,
	},
	{
		garage_id: 77,
		part_id: 232,
		quantity: 416,
	},
	{
		garage_id: 77,
		part_id: 233,
		quantity: 499,
	},
	{
		garage_id: 77,
		part_id: 234,
		quantity: 369,
	},
	{
		garage_id: 77,
		part_id: 235,
		quantity: 495,
	},
	{
		garage_id: 77,
		part_id: 236,
		quantity: 278,
	},
	{
		garage_id: 77,
		part_id: 237,
		quantity: 114,
	},
	{
		garage_id: 77,
		part_id: 238,
		quantity: 212,
	},
	{
		garage_id: 77,
		part_id: 239,
		quantity: 394,
	},
	{
		garage_id: 77,
		part_id: 240,
		quantity: 408,
	},
	{
		garage_id: 77,
		part_id: 241,
		quantity: 442,
	},
	{
		garage_id: 77,
		part_id: 242,
		quantity: 114,
	},
	{
		garage_id: 77,
		part_id: 243,
		quantity: 314,
	},
	{
		garage_id: 77,
		part_id: 244,
		quantity: 67,
	},
	{
		garage_id: 77,
		part_id: 245,
		quantity: 145,
	},
	{
		garage_id: 77,
		part_id: 246,
		quantity: 198,
	},
	{
		garage_id: 77,
		part_id: 247,
		quantity: 446,
	},
	{
		garage_id: 77,
		part_id: 248,
		quantity: 153,
	},
	{
		garage_id: 77,
		part_id: 249,
		quantity: 292,
	},
	{
		garage_id: 77,
		part_id: 250,
		quantity: 109,
	},
	{
		garage_id: 77,
		part_id: 251,
		quantity: 383,
	},
	{
		garage_id: 77,
		part_id: 252,
		quantity: 469,
	},
	{
		garage_id: 77,
		part_id: 253,
		quantity: 125,
	},
	{
		garage_id: 77,
		part_id: 254,
		quantity: 312,
	},
	{
		garage_id: 77,
		part_id: 255,
		quantity: 314,
	},
	{
		garage_id: 77,
		part_id: 256,
		quantity: 469,
	},
	{
		garage_id: 77,
		part_id: 257,
		quantity: 112,
	},
	{
		garage_id: 77,
		part_id: 258,
		quantity: 159,
	},
	{
		garage_id: 77,
		part_id: 259,
		quantity: 307,
	},
	{
		garage_id: 77,
		part_id: 260,
		quantity: 412,
	},
	{
		garage_id: 77,
		part_id: 261,
		quantity: 496,
	},
	{
		garage_id: 77,
		part_id: 262,
		quantity: 81,
	},
	{
		garage_id: 77,
		part_id: 263,
		quantity: 415,
	},
	{
		garage_id: 77,
		part_id: 264,
		quantity: 170,
	},
	{
		garage_id: 77,
		part_id: 265,
		quantity: 411,
	},
	{
		garage_id: 77,
		part_id: 266,
		quantity: 368,
	},
	{
		garage_id: 77,
		part_id: 267,
		quantity: 275,
	},
	{
		garage_id: 77,
		part_id: 268,
		quantity: 259,
	},
	{
		garage_id: 77,
		part_id: 269,
		quantity: 234,
	},
	{
		garage_id: 77,
		part_id: 270,
		quantity: 362,
	},
	{
		garage_id: 77,
		part_id: 271,
		quantity: 350,
	},
	{
		garage_id: 77,
		part_id: 272,
		quantity: 424,
	},
	{
		garage_id: 77,
		part_id: 273,
		quantity: 346,
	},
	{
		garage_id: 77,
		part_id: 274,
		quantity: 9,
	},
	{
		garage_id: 77,
		part_id: 275,
		quantity: 60,
	},
	{
		garage_id: 77,
		part_id: 276,
		quantity: 131,
	},
	{
		garage_id: 77,
		part_id: 277,
		quantity: 0,
	},
	{
		garage_id: 77,
		part_id: 278,
		quantity: 453,
	},
	{
		garage_id: 77,
		part_id: 279,
		quantity: 237,
	},
	{
		garage_id: 77,
		part_id: 280,
		quantity: 121,
	},
	{
		garage_id: 77,
		part_id: 281,
		quantity: 286,
	},
	{
		garage_id: 77,
		part_id: 282,
		quantity: 359,
	},
	{
		garage_id: 77,
		part_id: 283,
		quantity: 231,
	},
	{
		garage_id: 77,
		part_id: 284,
		quantity: 392,
	},
	{
		garage_id: 77,
		part_id: 285,
		quantity: 262,
	},
	{
		garage_id: 77,
		part_id: 286,
		quantity: 334,
	},
	{
		garage_id: 77,
		part_id: 287,
		quantity: 477,
	},
	{
		garage_id: 77,
		part_id: 288,
		quantity: 1,
	},
	{
		garage_id: 77,
		part_id: 289,
		quantity: 296,
	},
	{
		garage_id: 77,
		part_id: 290,
		quantity: 484,
	},
	{
		garage_id: 77,
		part_id: 291,
		quantity: 373,
	},
	{
		garage_id: 77,
		part_id: 292,
		quantity: 438,
	},
	{
		garage_id: 77,
		part_id: 293,
		quantity: 225,
	},
	{
		garage_id: 77,
		part_id: 294,
		quantity: 401,
	},
	{
		garage_id: 77,
		part_id: 295,
		quantity: 37,
	},
	{
		garage_id: 77,
		part_id: 296,
		quantity: 123,
	},
	{
		garage_id: 77,
		part_id: 297,
		quantity: 355,
	},
	{
		garage_id: 77,
		part_id: 298,
		quantity: 416,
	},
	{
		garage_id: 77,
		part_id: 299,
		quantity: 493,
	},
	{
		garage_id: 77,
		part_id: 300,
		quantity: 61,
	},
	{
		garage_id: 77,
		part_id: 301,
		quantity: 231,
	},
	{
		garage_id: 77,
		part_id: 302,
		quantity: 420,
	},
	{
		garage_id: 77,
		part_id: 303,
		quantity: 137,
	},
	{
		garage_id: 77,
		part_id: 304,
		quantity: 248,
	},
	{
		garage_id: 77,
		part_id: 305,
		quantity: 355,
	},
	{
		garage_id: 77,
		part_id: 306,
		quantity: 289,
	},
	{
		garage_id: 77,
		part_id: 307,
		quantity: 38,
	},
	{
		garage_id: 77,
		part_id: 308,
		quantity: 157,
	},
	{
		garage_id: 77,
		part_id: 309,
		quantity: 265,
	},
	{
		garage_id: 77,
		part_id: 310,
		quantity: 198,
	},
	{
		garage_id: 77,
		part_id: 311,
		quantity: 292,
	},
	{
		garage_id: 77,
		part_id: 312,
		quantity: 312,
	},
	{
		garage_id: 77,
		part_id: 313,
		quantity: 220,
	},
	{
		garage_id: 77,
		part_id: 314,
		quantity: 17,
	},
	{
		garage_id: 77,
		part_id: 315,
		quantity: 11,
	},
	{
		garage_id: 77,
		part_id: 316,
		quantity: 243,
	},
	{
		garage_id: 77,
		part_id: 317,
		quantity: 38,
	},
	{
		garage_id: 77,
		part_id: 318,
		quantity: 299,
	},
	{
		garage_id: 77,
		part_id: 319,
		quantity: 490,
	},
	{
		garage_id: 77,
		part_id: 320,
		quantity: 154,
	},
	{
		garage_id: 77,
		part_id: 321,
		quantity: 211,
	},
	{
		garage_id: 77,
		part_id: 322,
		quantity: 50,
	},
	{
		garage_id: 77,
		part_id: 323,
		quantity: 227,
	},
	{
		garage_id: 77,
		part_id: 324,
		quantity: 398,
	},
	{
		garage_id: 77,
		part_id: 325,
		quantity: 102,
	},
	{
		garage_id: 77,
		part_id: 326,
		quantity: 79,
	},
	{
		garage_id: 77,
		part_id: 327,
		quantity: 423,
	},
	{
		garage_id: 77,
		part_id: 328,
		quantity: 419,
	},
	{
		garage_id: 77,
		part_id: 329,
		quantity: 193,
	},
	{
		garage_id: 77,
		part_id: 330,
		quantity: 316,
	},
	{
		garage_id: 77,
		part_id: 331,
		quantity: 461,
	},
	{
		garage_id: 77,
		part_id: 332,
		quantity: 283,
	},
	{
		garage_id: 77,
		part_id: 333,
		quantity: 226,
	},
	{
		garage_id: 77,
		part_id: 334,
		quantity: 298,
	},
	{
		garage_id: 77,
		part_id: 335,
		quantity: 466,
	},
	{
		garage_id: 77,
		part_id: 336,
		quantity: 474,
	},
	{
		garage_id: 77,
		part_id: 337,
		quantity: 488,
	},
	{
		garage_id: 77,
		part_id: 338,
		quantity: 457,
	},
	{
		garage_id: 77,
		part_id: 339,
		quantity: 205,
	},
	{
		garage_id: 77,
		part_id: 340,
		quantity: 211,
	},
	{
		garage_id: 77,
		part_id: 341,
		quantity: 497,
	},
	{
		garage_id: 77,
		part_id: 342,
		quantity: 339,
	},
	{
		garage_id: 77,
		part_id: 343,
		quantity: 351,
	},
	{
		garage_id: 77,
		part_id: 344,
		quantity: 352,
	},
	{
		garage_id: 77,
		part_id: 345,
		quantity: 439,
	},
	{
		garage_id: 77,
		part_id: 346,
		quantity: 132,
	},
	{
		garage_id: 77,
		part_id: 347,
		quantity: 297,
	},
	{
		garage_id: 77,
		part_id: 348,
		quantity: 57,
	},
	{
		garage_id: 77,
		part_id: 349,
		quantity: 406,
	},
	{
		garage_id: 77,
		part_id: 350,
		quantity: 431,
	},
	{
		garage_id: 77,
		part_id: 351,
		quantity: 351,
	},
	{
		garage_id: 77,
		part_id: 352,
		quantity: 88,
	},
	{
		garage_id: 77,
		part_id: 353,
		quantity: 195,
	},
	{
		garage_id: 77,
		part_id: 354,
		quantity: 322,
	},
	{
		garage_id: 77,
		part_id: 355,
		quantity: 26,
	},
	{
		garage_id: 77,
		part_id: 356,
		quantity: 340,
	},
	{
		garage_id: 77,
		part_id: 357,
		quantity: 152,
	},
	{
		garage_id: 77,
		part_id: 358,
		quantity: 129,
	},
	{
		garage_id: 77,
		part_id: 359,
		quantity: 125,
	},
	{
		garage_id: 77,
		part_id: 360,
		quantity: 498,
	},
	{
		garage_id: 77,
		part_id: 361,
		quantity: 92,
	},
	{
		garage_id: 77,
		part_id: 362,
		quantity: 330,
	},
	{
		garage_id: 77,
		part_id: 363,
		quantity: 176,
	},
	{
		garage_id: 77,
		part_id: 364,
		quantity: 215,
	},
	{
		garage_id: 77,
		part_id: 365,
		quantity: 292,
	},
	{
		garage_id: 77,
		part_id: 366,
		quantity: 453,
	},
	{
		garage_id: 77,
		part_id: 367,
		quantity: 344,
	},
	{
		garage_id: 77,
		part_id: 368,
		quantity: 135,
	},
	{
		garage_id: 77,
		part_id: 369,
		quantity: 150,
	},
	{
		garage_id: 77,
		part_id: 370,
		quantity: 465,
	},
	{
		garage_id: 77,
		part_id: 371,
		quantity: 241,
	},
	{
		garage_id: 77,
		part_id: 372,
		quantity: 8,
	},
	{
		garage_id: 77,
		part_id: 373,
		quantity: 433,
	},
	{
		garage_id: 77,
		part_id: 374,
		quantity: 402,
	},
	{
		garage_id: 77,
		part_id: 375,
		quantity: 74,
	},
	{
		garage_id: 77,
		part_id: 376,
		quantity: 263,
	},
	{
		garage_id: 77,
		part_id: 377,
		quantity: 278,
	},
	{
		garage_id: 77,
		part_id: 378,
		quantity: 236,
	},
	{
		garage_id: 77,
		part_id: 379,
		quantity: 454,
	},
	{
		garage_id: 77,
		part_id: 380,
		quantity: 181,
	},
	{
		garage_id: 77,
		part_id: 381,
		quantity: 458,
	},
	{
		garage_id: 77,
		part_id: 382,
		quantity: 332,
	},
	{
		garage_id: 77,
		part_id: 383,
		quantity: 262,
	},
	{
		garage_id: 77,
		part_id: 384,
		quantity: 447,
	},
	{
		garage_id: 77,
		part_id: 385,
		quantity: 1,
	},
	{
		garage_id: 77,
		part_id: 386,
		quantity: 26,
	},
	{
		garage_id: 77,
		part_id: 387,
		quantity: 50,
	},
	{
		garage_id: 77,
		part_id: 388,
		quantity: 136,
	},
	{
		garage_id: 77,
		part_id: 389,
		quantity: 158,
	},
	{
		garage_id: 77,
		part_id: 390,
		quantity: 494,
	},
	{
		garage_id: 77,
		part_id: 391,
		quantity: 355,
	},
	{
		garage_id: 77,
		part_id: 392,
		quantity: 333,
	},
	{
		garage_id: 77,
		part_id: 393,
		quantity: 238,
	},
	{
		garage_id: 77,
		part_id: 394,
		quantity: 484,
	},
	{
		garage_id: 77,
		part_id: 395,
		quantity: 219,
	},
	{
		garage_id: 77,
		part_id: 396,
		quantity: 84,
	},
	{
		garage_id: 77,
		part_id: 397,
		quantity: 321,
	},
	{
		garage_id: 77,
		part_id: 398,
		quantity: 89,
	},
	{
		garage_id: 77,
		part_id: 399,
		quantity: 126,
	},
	{
		garage_id: 78,
		part_id: 0,
		quantity: 273,
	},
	{
		garage_id: 78,
		part_id: 1,
		quantity: 57,
	},
	{
		garage_id: 78,
		part_id: 2,
		quantity: 295,
	},
	{
		garage_id: 78,
		part_id: 3,
		quantity: 114,
	},
	{
		garage_id: 78,
		part_id: 4,
		quantity: 238,
	},
	{
		garage_id: 78,
		part_id: 5,
		quantity: 307,
	},
	{
		garage_id: 78,
		part_id: 6,
		quantity: 196,
	},
	{
		garage_id: 78,
		part_id: 7,
		quantity: 183,
	},
	{
		garage_id: 78,
		part_id: 8,
		quantity: 8,
	},
	{
		garage_id: 78,
		part_id: 9,
		quantity: 419,
	},
	{
		garage_id: 78,
		part_id: 10,
		quantity: 265,
	},
	{
		garage_id: 78,
		part_id: 11,
		quantity: 421,
	},
	{
		garage_id: 78,
		part_id: 12,
		quantity: 153,
	},
	{
		garage_id: 78,
		part_id: 13,
		quantity: 353,
	},
	{
		garage_id: 78,
		part_id: 14,
		quantity: 267,
	},
	{
		garage_id: 78,
		part_id: 15,
		quantity: 29,
	},
	{
		garage_id: 78,
		part_id: 16,
		quantity: 395,
	},
	{
		garage_id: 78,
		part_id: 17,
		quantity: 44,
	},
	{
		garage_id: 78,
		part_id: 18,
		quantity: 317,
	},
	{
		garage_id: 78,
		part_id: 19,
		quantity: 264,
	},
	{
		garage_id: 78,
		part_id: 20,
		quantity: 181,
	},
	{
		garage_id: 78,
		part_id: 21,
		quantity: 276,
	},
	{
		garage_id: 78,
		part_id: 22,
		quantity: 388,
	},
	{
		garage_id: 78,
		part_id: 23,
		quantity: 318,
	},
	{
		garage_id: 78,
		part_id: 24,
		quantity: 153,
	},
	{
		garage_id: 78,
		part_id: 25,
		quantity: 155,
	},
	{
		garage_id: 78,
		part_id: 26,
		quantity: 476,
	},
	{
		garage_id: 78,
		part_id: 27,
		quantity: 312,
	},
	{
		garage_id: 78,
		part_id: 28,
		quantity: 498,
	},
	{
		garage_id: 78,
		part_id: 29,
		quantity: 16,
	},
	{
		garage_id: 78,
		part_id: 30,
		quantity: 311,
	},
	{
		garage_id: 78,
		part_id: 31,
		quantity: 334,
	},
	{
		garage_id: 78,
		part_id: 32,
		quantity: 119,
	},
	{
		garage_id: 78,
		part_id: 33,
		quantity: 333,
	},
	{
		garage_id: 78,
		part_id: 34,
		quantity: 177,
	},
	{
		garage_id: 78,
		part_id: 35,
		quantity: 299,
	},
	{
		garage_id: 78,
		part_id: 36,
		quantity: 199,
	},
	{
		garage_id: 78,
		part_id: 37,
		quantity: 75,
	},
	{
		garage_id: 78,
		part_id: 38,
		quantity: 80,
	},
	{
		garage_id: 78,
		part_id: 39,
		quantity: 285,
	},
	{
		garage_id: 78,
		part_id: 40,
		quantity: 174,
	},
	{
		garage_id: 78,
		part_id: 41,
		quantity: 148,
	},
	{
		garage_id: 78,
		part_id: 42,
		quantity: 12,
	},
	{
		garage_id: 78,
		part_id: 43,
		quantity: 223,
	},
	{
		garage_id: 78,
		part_id: 44,
		quantity: 241,
	},
	{
		garage_id: 78,
		part_id: 45,
		quantity: 278,
	},
	{
		garage_id: 78,
		part_id: 46,
		quantity: 477,
	},
	{
		garage_id: 78,
		part_id: 47,
		quantity: 76,
	},
	{
		garage_id: 78,
		part_id: 48,
		quantity: 424,
	},
	{
		garage_id: 78,
		part_id: 49,
		quantity: 199,
	},
	{
		garage_id: 78,
		part_id: 50,
		quantity: 329,
	},
	{
		garage_id: 78,
		part_id: 51,
		quantity: 461,
	},
	{
		garage_id: 78,
		part_id: 52,
		quantity: 108,
	},
	{
		garage_id: 78,
		part_id: 53,
		quantity: 489,
	},
	{
		garage_id: 78,
		part_id: 54,
		quantity: 383,
	},
	{
		garage_id: 78,
		part_id: 55,
		quantity: 464,
	},
	{
		garage_id: 78,
		part_id: 56,
		quantity: 228,
	},
	{
		garage_id: 78,
		part_id: 57,
		quantity: 487,
	},
	{
		garage_id: 78,
		part_id: 58,
		quantity: 104,
	},
	{
		garage_id: 78,
		part_id: 59,
		quantity: 333,
	},
	{
		garage_id: 78,
		part_id: 60,
		quantity: 444,
	},
	{
		garage_id: 78,
		part_id: 61,
		quantity: 435,
	},
	{
		garage_id: 78,
		part_id: 62,
		quantity: 164,
	},
	{
		garage_id: 78,
		part_id: 63,
		quantity: 498,
	},
	{
		garage_id: 78,
		part_id: 64,
		quantity: 402,
	},
	{
		garage_id: 78,
		part_id: 65,
		quantity: 95,
	},
	{
		garage_id: 78,
		part_id: 66,
		quantity: 226,
	},
	{
		garage_id: 78,
		part_id: 67,
		quantity: 117,
	},
	{
		garage_id: 78,
		part_id: 68,
		quantity: 323,
	},
	{
		garage_id: 78,
		part_id: 69,
		quantity: 145,
	},
	{
		garage_id: 78,
		part_id: 70,
		quantity: 102,
	},
	{
		garage_id: 78,
		part_id: 71,
		quantity: 113,
	},
	{
		garage_id: 78,
		part_id: 72,
		quantity: 459,
	},
	{
		garage_id: 78,
		part_id: 73,
		quantity: 141,
	},
	{
		garage_id: 78,
		part_id: 74,
		quantity: 248,
	},
	{
		garage_id: 78,
		part_id: 75,
		quantity: 320,
	},
	{
		garage_id: 78,
		part_id: 76,
		quantity: 362,
	},
	{
		garage_id: 78,
		part_id: 77,
		quantity: 73,
	},
	{
		garage_id: 78,
		part_id: 78,
		quantity: 189,
	},
	{
		garage_id: 78,
		part_id: 79,
		quantity: 190,
	},
	{
		garage_id: 78,
		part_id: 80,
		quantity: 200,
	},
	{
		garage_id: 78,
		part_id: 81,
		quantity: 434,
	},
	{
		garage_id: 78,
		part_id: 82,
		quantity: 325,
	},
	{
		garage_id: 78,
		part_id: 83,
		quantity: 18,
	},
	{
		garage_id: 78,
		part_id: 84,
		quantity: 390,
	},
	{
		garage_id: 78,
		part_id: 85,
		quantity: 374,
	},
	{
		garage_id: 78,
		part_id: 86,
		quantity: 125,
	},
	{
		garage_id: 78,
		part_id: 87,
		quantity: 397,
	},
	{
		garage_id: 78,
		part_id: 88,
		quantity: 4,
	},
	{
		garage_id: 78,
		part_id: 89,
		quantity: 64,
	},
	{
		garage_id: 78,
		part_id: 90,
		quantity: 1,
	},
	{
		garage_id: 78,
		part_id: 91,
		quantity: 272,
	},
	{
		garage_id: 78,
		part_id: 92,
		quantity: 6,
	},
	{
		garage_id: 78,
		part_id: 93,
		quantity: 178,
	},
	{
		garage_id: 78,
		part_id: 94,
		quantity: 204,
	},
	{
		garage_id: 78,
		part_id: 95,
		quantity: 269,
	},
	{
		garage_id: 78,
		part_id: 96,
		quantity: 18,
	},
	{
		garage_id: 78,
		part_id: 97,
		quantity: 486,
	},
	{
		garage_id: 78,
		part_id: 98,
		quantity: 69,
	},
	{
		garage_id: 78,
		part_id: 99,
		quantity: 269,
	},
	{
		garage_id: 78,
		part_id: 100,
		quantity: 188,
	},
	{
		garage_id: 78,
		part_id: 101,
		quantity: 105,
	},
	{
		garage_id: 78,
		part_id: 102,
		quantity: 138,
	},
	{
		garage_id: 78,
		part_id: 103,
		quantity: 251,
	},
	{
		garage_id: 78,
		part_id: 104,
		quantity: 489,
	},
	{
		garage_id: 78,
		part_id: 105,
		quantity: 56,
	},
	{
		garage_id: 78,
		part_id: 106,
		quantity: 230,
	},
	{
		garage_id: 78,
		part_id: 107,
		quantity: 136,
	},
	{
		garage_id: 78,
		part_id: 108,
		quantity: 156,
	},
	{
		garage_id: 78,
		part_id: 109,
		quantity: 150,
	},
	{
		garage_id: 78,
		part_id: 110,
		quantity: 145,
	},
	{
		garage_id: 78,
		part_id: 111,
		quantity: 311,
	},
	{
		garage_id: 78,
		part_id: 112,
		quantity: 39,
	},
	{
		garage_id: 78,
		part_id: 113,
		quantity: 447,
	},
	{
		garage_id: 78,
		part_id: 114,
		quantity: 485,
	},
	{
		garage_id: 78,
		part_id: 115,
		quantity: 86,
	},
	{
		garage_id: 78,
		part_id: 116,
		quantity: 350,
	},
	{
		garage_id: 78,
		part_id: 117,
		quantity: 432,
	},
	{
		garage_id: 78,
		part_id: 118,
		quantity: 258,
	},
	{
		garage_id: 78,
		part_id: 119,
		quantity: 184,
	},
	{
		garage_id: 78,
		part_id: 120,
		quantity: 16,
	},
	{
		garage_id: 78,
		part_id: 121,
		quantity: 146,
	},
	{
		garage_id: 78,
		part_id: 122,
		quantity: 404,
	},
	{
		garage_id: 78,
		part_id: 123,
		quantity: 24,
	},
	{
		garage_id: 78,
		part_id: 124,
		quantity: 443,
	},
	{
		garage_id: 78,
		part_id: 125,
		quantity: 426,
	},
	{
		garage_id: 78,
		part_id: 126,
		quantity: 210,
	},
	{
		garage_id: 78,
		part_id: 127,
		quantity: 369,
	},
	{
		garage_id: 78,
		part_id: 128,
		quantity: 45,
	},
	{
		garage_id: 78,
		part_id: 129,
		quantity: 269,
	},
	{
		garage_id: 78,
		part_id: 130,
		quantity: 271,
	},
	{
		garage_id: 78,
		part_id: 131,
		quantity: 30,
	},
	{
		garage_id: 78,
		part_id: 132,
		quantity: 144,
	},
	{
		garage_id: 78,
		part_id: 133,
		quantity: 238,
	},
	{
		garage_id: 78,
		part_id: 134,
		quantity: 62,
	},
	{
		garage_id: 78,
		part_id: 135,
		quantity: 238,
	},
	{
		garage_id: 78,
		part_id: 136,
		quantity: 325,
	},
	{
		garage_id: 78,
		part_id: 137,
		quantity: 269,
	},
	{
		garage_id: 78,
		part_id: 138,
		quantity: 438,
	},
	{
		garage_id: 78,
		part_id: 139,
		quantity: 200,
	},
	{
		garage_id: 78,
		part_id: 140,
		quantity: 63,
	},
	{
		garage_id: 78,
		part_id: 141,
		quantity: 183,
	},
	{
		garage_id: 78,
		part_id: 142,
		quantity: 196,
	},
	{
		garage_id: 78,
		part_id: 143,
		quantity: 479,
	},
	{
		garage_id: 78,
		part_id: 144,
		quantity: 115,
	},
	{
		garage_id: 78,
		part_id: 145,
		quantity: 364,
	},
	{
		garage_id: 78,
		part_id: 146,
		quantity: 327,
	},
	{
		garage_id: 78,
		part_id: 147,
		quantity: 404,
	},
	{
		garage_id: 78,
		part_id: 148,
		quantity: 132,
	},
	{
		garage_id: 78,
		part_id: 149,
		quantity: 130,
	},
	{
		garage_id: 78,
		part_id: 150,
		quantity: 78,
	},
	{
		garage_id: 78,
		part_id: 151,
		quantity: 383,
	},
	{
		garage_id: 78,
		part_id: 152,
		quantity: 163,
	},
	{
		garage_id: 78,
		part_id: 153,
		quantity: 365,
	},
	{
		garage_id: 78,
		part_id: 154,
		quantity: 139,
	},
	{
		garage_id: 78,
		part_id: 155,
		quantity: 12,
	},
	{
		garage_id: 78,
		part_id: 156,
		quantity: 392,
	},
	{
		garage_id: 78,
		part_id: 157,
		quantity: 431,
	},
	{
		garage_id: 78,
		part_id: 158,
		quantity: 429,
	},
	{
		garage_id: 78,
		part_id: 159,
		quantity: 221,
	},
	{
		garage_id: 78,
		part_id: 160,
		quantity: 25,
	},
	{
		garage_id: 78,
		part_id: 161,
		quantity: 130,
	},
	{
		garage_id: 78,
		part_id: 162,
		quantity: 122,
	},
	{
		garage_id: 78,
		part_id: 163,
		quantity: 372,
	},
	{
		garage_id: 78,
		part_id: 164,
		quantity: 393,
	},
	{
		garage_id: 78,
		part_id: 165,
		quantity: 243,
	},
	{
		garage_id: 78,
		part_id: 166,
		quantity: 273,
	},
	{
		garage_id: 78,
		part_id: 167,
		quantity: 74,
	},
	{
		garage_id: 78,
		part_id: 168,
		quantity: 358,
	},
	{
		garage_id: 78,
		part_id: 169,
		quantity: 394,
	},
	{
		garage_id: 78,
		part_id: 170,
		quantity: 81,
	},
	{
		garage_id: 78,
		part_id: 171,
		quantity: 455,
	},
	{
		garage_id: 78,
		part_id: 172,
		quantity: 480,
	},
	{
		garage_id: 78,
		part_id: 173,
		quantity: 431,
	},
	{
		garage_id: 78,
		part_id: 174,
		quantity: 54,
	},
	{
		garage_id: 78,
		part_id: 175,
		quantity: 414,
	},
	{
		garage_id: 78,
		part_id: 176,
		quantity: 229,
	},
	{
		garage_id: 78,
		part_id: 177,
		quantity: 417,
	},
	{
		garage_id: 78,
		part_id: 178,
		quantity: 320,
	},
	{
		garage_id: 78,
		part_id: 179,
		quantity: 45,
	},
	{
		garage_id: 78,
		part_id: 180,
		quantity: 242,
	},
	{
		garage_id: 78,
		part_id: 181,
		quantity: 378,
	},
	{
		garage_id: 78,
		part_id: 182,
		quantity: 82,
	},
	{
		garage_id: 78,
		part_id: 183,
		quantity: 469,
	},
	{
		garage_id: 78,
		part_id: 184,
		quantity: 59,
	},
	{
		garage_id: 78,
		part_id: 185,
		quantity: 303,
	},
	{
		garage_id: 78,
		part_id: 186,
		quantity: 97,
	},
	{
		garage_id: 78,
		part_id: 187,
		quantity: 26,
	},
	{
		garage_id: 78,
		part_id: 188,
		quantity: 481,
	},
	{
		garage_id: 78,
		part_id: 189,
		quantity: 184,
	},
	{
		garage_id: 78,
		part_id: 190,
		quantity: 294,
	},
	{
		garage_id: 78,
		part_id: 191,
		quantity: 456,
	},
	{
		garage_id: 78,
		part_id: 192,
		quantity: 4,
	},
	{
		garage_id: 78,
		part_id: 193,
		quantity: 207,
	},
	{
		garage_id: 78,
		part_id: 194,
		quantity: 399,
	},
	{
		garage_id: 78,
		part_id: 195,
		quantity: 227,
	},
	{
		garage_id: 78,
		part_id: 196,
		quantity: 179,
	},
	{
		garage_id: 78,
		part_id: 197,
		quantity: 494,
	},
	{
		garage_id: 78,
		part_id: 198,
		quantity: 494,
	},
	{
		garage_id: 78,
		part_id: 199,
		quantity: 291,
	},
	{
		garage_id: 78,
		part_id: 200,
		quantity: 343,
	},
	{
		garage_id: 78,
		part_id: 201,
		quantity: 325,
	},
	{
		garage_id: 78,
		part_id: 202,
		quantity: 360,
	},
	{
		garage_id: 78,
		part_id: 203,
		quantity: 72,
	},
	{
		garage_id: 78,
		part_id: 204,
		quantity: 175,
	},
	{
		garage_id: 78,
		part_id: 205,
		quantity: 327,
	},
	{
		garage_id: 78,
		part_id: 206,
		quantity: 371,
	},
	{
		garage_id: 78,
		part_id: 207,
		quantity: 448,
	},
	{
		garage_id: 78,
		part_id: 208,
		quantity: 413,
	},
	{
		garage_id: 78,
		part_id: 209,
		quantity: 200,
	},
	{
		garage_id: 78,
		part_id: 210,
		quantity: 461,
	},
	{
		garage_id: 78,
		part_id: 211,
		quantity: 221,
	},
	{
		garage_id: 78,
		part_id: 212,
		quantity: 224,
	},
	{
		garage_id: 78,
		part_id: 213,
		quantity: 105,
	},
	{
		garage_id: 78,
		part_id: 214,
		quantity: 147,
	},
	{
		garage_id: 78,
		part_id: 215,
		quantity: 440,
	},
	{
		garage_id: 78,
		part_id: 216,
		quantity: 296,
	},
	{
		garage_id: 78,
		part_id: 217,
		quantity: 356,
	},
	{
		garage_id: 78,
		part_id: 218,
		quantity: 7,
	},
	{
		garage_id: 78,
		part_id: 219,
		quantity: 308,
	},
	{
		garage_id: 78,
		part_id: 220,
		quantity: 363,
	},
	{
		garage_id: 78,
		part_id: 221,
		quantity: 387,
	},
	{
		garage_id: 78,
		part_id: 222,
		quantity: 439,
	},
	{
		garage_id: 78,
		part_id: 223,
		quantity: 25,
	},
	{
		garage_id: 78,
		part_id: 224,
		quantity: 489,
	},
	{
		garage_id: 78,
		part_id: 225,
		quantity: 9,
	},
	{
		garage_id: 78,
		part_id: 226,
		quantity: 142,
	},
	{
		garage_id: 78,
		part_id: 227,
		quantity: 387,
	},
	{
		garage_id: 78,
		part_id: 228,
		quantity: 226,
	},
	{
		garage_id: 78,
		part_id: 229,
		quantity: 253,
	},
	{
		garage_id: 78,
		part_id: 230,
		quantity: 301,
	},
	{
		garage_id: 78,
		part_id: 231,
		quantity: 148,
	},
	{
		garage_id: 78,
		part_id: 232,
		quantity: 354,
	},
	{
		garage_id: 78,
		part_id: 233,
		quantity: 453,
	},
	{
		garage_id: 78,
		part_id: 234,
		quantity: 336,
	},
	{
		garage_id: 78,
		part_id: 235,
		quantity: 32,
	},
	{
		garage_id: 78,
		part_id: 236,
		quantity: 202,
	},
	{
		garage_id: 78,
		part_id: 237,
		quantity: 339,
	},
	{
		garage_id: 78,
		part_id: 238,
		quantity: 497,
	},
	{
		garage_id: 78,
		part_id: 239,
		quantity: 303,
	},
	{
		garage_id: 78,
		part_id: 240,
		quantity: 357,
	},
	{
		garage_id: 78,
		part_id: 241,
		quantity: 196,
	},
	{
		garage_id: 78,
		part_id: 242,
		quantity: 418,
	},
	{
		garage_id: 78,
		part_id: 243,
		quantity: 447,
	},
	{
		garage_id: 78,
		part_id: 244,
		quantity: 487,
	},
	{
		garage_id: 78,
		part_id: 245,
		quantity: 246,
	},
	{
		garage_id: 78,
		part_id: 246,
		quantity: 211,
	},
	{
		garage_id: 78,
		part_id: 247,
		quantity: 232,
	},
	{
		garage_id: 78,
		part_id: 248,
		quantity: 275,
	},
	{
		garage_id: 78,
		part_id: 249,
		quantity: 61,
	},
	{
		garage_id: 78,
		part_id: 250,
		quantity: 473,
	},
	{
		garage_id: 78,
		part_id: 251,
		quantity: 242,
	},
	{
		garage_id: 78,
		part_id: 252,
		quantity: 220,
	},
	{
		garage_id: 78,
		part_id: 253,
		quantity: 233,
	},
	{
		garage_id: 78,
		part_id: 254,
		quantity: 129,
	},
	{
		garage_id: 78,
		part_id: 255,
		quantity: 310,
	},
	{
		garage_id: 78,
		part_id: 256,
		quantity: 358,
	},
	{
		garage_id: 78,
		part_id: 257,
		quantity: 343,
	},
	{
		garage_id: 78,
		part_id: 258,
		quantity: 310,
	},
	{
		garage_id: 78,
		part_id: 259,
		quantity: 423,
	},
	{
		garage_id: 78,
		part_id: 260,
		quantity: 231,
	},
	{
		garage_id: 78,
		part_id: 261,
		quantity: 470,
	},
	{
		garage_id: 78,
		part_id: 262,
		quantity: 217,
	},
	{
		garage_id: 78,
		part_id: 263,
		quantity: 393,
	},
	{
		garage_id: 78,
		part_id: 264,
		quantity: 424,
	},
	{
		garage_id: 78,
		part_id: 265,
		quantity: 487,
	},
	{
		garage_id: 78,
		part_id: 266,
		quantity: 224,
	},
	{
		garage_id: 78,
		part_id: 267,
		quantity: 345,
	},
	{
		garage_id: 78,
		part_id: 268,
		quantity: 318,
	},
	{
		garage_id: 78,
		part_id: 269,
		quantity: 242,
	},
	{
		garage_id: 78,
		part_id: 270,
		quantity: 221,
	},
	{
		garage_id: 78,
		part_id: 271,
		quantity: 403,
	},
	{
		garage_id: 78,
		part_id: 272,
		quantity: 258,
	},
	{
		garage_id: 78,
		part_id: 273,
		quantity: 53,
	},
	{
		garage_id: 78,
		part_id: 274,
		quantity: 321,
	},
	{
		garage_id: 78,
		part_id: 275,
		quantity: 157,
	},
	{
		garage_id: 78,
		part_id: 276,
		quantity: 359,
	},
	{
		garage_id: 78,
		part_id: 277,
		quantity: 97,
	},
	{
		garage_id: 78,
		part_id: 278,
		quantity: 201,
	},
	{
		garage_id: 78,
		part_id: 279,
		quantity: 486,
	},
	{
		garage_id: 78,
		part_id: 280,
		quantity: 381,
	},
	{
		garage_id: 78,
		part_id: 281,
		quantity: 231,
	},
	{
		garage_id: 78,
		part_id: 282,
		quantity: 123,
	},
	{
		garage_id: 78,
		part_id: 283,
		quantity: 489,
	},
	{
		garage_id: 78,
		part_id: 284,
		quantity: 220,
	},
	{
		garage_id: 78,
		part_id: 285,
		quantity: 125,
	},
	{
		garage_id: 78,
		part_id: 286,
		quantity: 99,
	},
	{
		garage_id: 78,
		part_id: 287,
		quantity: 117,
	},
	{
		garage_id: 78,
		part_id: 288,
		quantity: 121,
	},
	{
		garage_id: 78,
		part_id: 289,
		quantity: 474,
	},
	{
		garage_id: 78,
		part_id: 290,
		quantity: 146,
	},
	{
		garage_id: 78,
		part_id: 291,
		quantity: 39,
	},
	{
		garage_id: 78,
		part_id: 292,
		quantity: 276,
	},
	{
		garage_id: 78,
		part_id: 293,
		quantity: 288,
	},
	{
		garage_id: 78,
		part_id: 294,
		quantity: 94,
	},
	{
		garage_id: 78,
		part_id: 295,
		quantity: 12,
	},
	{
		garage_id: 78,
		part_id: 296,
		quantity: 310,
	},
	{
		garage_id: 78,
		part_id: 297,
		quantity: 37,
	},
	{
		garage_id: 78,
		part_id: 298,
		quantity: 151,
	},
	{
		garage_id: 78,
		part_id: 299,
		quantity: 372,
	},
	{
		garage_id: 78,
		part_id: 300,
		quantity: 150,
	},
	{
		garage_id: 78,
		part_id: 301,
		quantity: 457,
	},
	{
		garage_id: 78,
		part_id: 302,
		quantity: 279,
	},
	{
		garage_id: 78,
		part_id: 303,
		quantity: 330,
	},
	{
		garage_id: 78,
		part_id: 304,
		quantity: 308,
	},
	{
		garage_id: 78,
		part_id: 305,
		quantity: 197,
	},
	{
		garage_id: 78,
		part_id: 306,
		quantity: 191,
	},
	{
		garage_id: 78,
		part_id: 307,
		quantity: 298,
	},
	{
		garage_id: 78,
		part_id: 308,
		quantity: 269,
	},
	{
		garage_id: 78,
		part_id: 309,
		quantity: 357,
	},
	{
		garage_id: 78,
		part_id: 310,
		quantity: 248,
	},
	{
		garage_id: 78,
		part_id: 311,
		quantity: 58,
	},
	{
		garage_id: 78,
		part_id: 312,
		quantity: 47,
	},
	{
		garage_id: 78,
		part_id: 313,
		quantity: 260,
	},
	{
		garage_id: 78,
		part_id: 314,
		quantity: 390,
	},
	{
		garage_id: 78,
		part_id: 315,
		quantity: 152,
	},
	{
		garage_id: 78,
		part_id: 316,
		quantity: 27,
	},
	{
		garage_id: 78,
		part_id: 317,
		quantity: 0,
	},
	{
		garage_id: 78,
		part_id: 318,
		quantity: 11,
	},
	{
		garage_id: 78,
		part_id: 319,
		quantity: 64,
	},
	{
		garage_id: 78,
		part_id: 320,
		quantity: 226,
	},
	{
		garage_id: 78,
		part_id: 321,
		quantity: 424,
	},
	{
		garage_id: 78,
		part_id: 322,
		quantity: 161,
	},
	{
		garage_id: 78,
		part_id: 323,
		quantity: 157,
	},
	{
		garage_id: 78,
		part_id: 324,
		quantity: 50,
	},
	{
		garage_id: 78,
		part_id: 325,
		quantity: 393,
	},
	{
		garage_id: 78,
		part_id: 326,
		quantity: 388,
	},
	{
		garage_id: 78,
		part_id: 327,
		quantity: 339,
	},
	{
		garage_id: 78,
		part_id: 328,
		quantity: 449,
	},
	{
		garage_id: 78,
		part_id: 329,
		quantity: 277,
	},
	{
		garage_id: 78,
		part_id: 330,
		quantity: 253,
	},
	{
		garage_id: 78,
		part_id: 331,
		quantity: 111,
	},
	{
		garage_id: 78,
		part_id: 332,
		quantity: 17,
	},
	{
		garage_id: 78,
		part_id: 333,
		quantity: 273,
	},
	{
		garage_id: 78,
		part_id: 334,
		quantity: 170,
	},
	{
		garage_id: 78,
		part_id: 335,
		quantity: 189,
	},
	{
		garage_id: 78,
		part_id: 336,
		quantity: 325,
	},
	{
		garage_id: 78,
		part_id: 337,
		quantity: 323,
	},
	{
		garage_id: 78,
		part_id: 338,
		quantity: 286,
	},
	{
		garage_id: 78,
		part_id: 339,
		quantity: 131,
	},
	{
		garage_id: 78,
		part_id: 340,
		quantity: 478,
	},
	{
		garage_id: 78,
		part_id: 341,
		quantity: 425,
	},
	{
		garage_id: 78,
		part_id: 342,
		quantity: 429,
	},
	{
		garage_id: 78,
		part_id: 343,
		quantity: 350,
	},
	{
		garage_id: 78,
		part_id: 344,
		quantity: 217,
	},
	{
		garage_id: 78,
		part_id: 345,
		quantity: 331,
	},
	{
		garage_id: 78,
		part_id: 346,
		quantity: 486,
	},
	{
		garage_id: 78,
		part_id: 347,
		quantity: 184,
	},
	{
		garage_id: 78,
		part_id: 348,
		quantity: 85,
	},
	{
		garage_id: 78,
		part_id: 349,
		quantity: 264,
	},
	{
		garage_id: 78,
		part_id: 350,
		quantity: 197,
	},
	{
		garage_id: 78,
		part_id: 351,
		quantity: 185,
	},
	{
		garage_id: 78,
		part_id: 352,
		quantity: 53,
	},
	{
		garage_id: 78,
		part_id: 353,
		quantity: 168,
	},
	{
		garage_id: 78,
		part_id: 354,
		quantity: 287,
	},
	{
		garage_id: 78,
		part_id: 355,
		quantity: 146,
	},
	{
		garage_id: 78,
		part_id: 356,
		quantity: 441,
	},
	{
		garage_id: 78,
		part_id: 357,
		quantity: 62,
	},
	{
		garage_id: 78,
		part_id: 358,
		quantity: 379,
	},
	{
		garage_id: 78,
		part_id: 359,
		quantity: 434,
	},
	{
		garage_id: 78,
		part_id: 360,
		quantity: 291,
	},
	{
		garage_id: 78,
		part_id: 361,
		quantity: 160,
	},
	{
		garage_id: 78,
		part_id: 362,
		quantity: 171,
	},
	{
		garage_id: 78,
		part_id: 363,
		quantity: 33,
	},
	{
		garage_id: 78,
		part_id: 364,
		quantity: 488,
	},
	{
		garage_id: 78,
		part_id: 365,
		quantity: 456,
	},
	{
		garage_id: 78,
		part_id: 366,
		quantity: 390,
	},
	{
		garage_id: 78,
		part_id: 367,
		quantity: 333,
	},
	{
		garage_id: 78,
		part_id: 368,
		quantity: 186,
	},
	{
		garage_id: 78,
		part_id: 369,
		quantity: 363,
	},
	{
		garage_id: 78,
		part_id: 370,
		quantity: 50,
	},
	{
		garage_id: 78,
		part_id: 371,
		quantity: 237,
	},
	{
		garage_id: 78,
		part_id: 372,
		quantity: 487,
	},
	{
		garage_id: 78,
		part_id: 373,
		quantity: 68,
	},
	{
		garage_id: 78,
		part_id: 374,
		quantity: 0,
	},
	{
		garage_id: 78,
		part_id: 375,
		quantity: 41,
	},
	{
		garage_id: 78,
		part_id: 376,
		quantity: 404,
	},
	{
		garage_id: 78,
		part_id: 377,
		quantity: 103,
	},
	{
		garage_id: 78,
		part_id: 378,
		quantity: 165,
	},
	{
		garage_id: 78,
		part_id: 379,
		quantity: 474,
	},
	{
		garage_id: 78,
		part_id: 380,
		quantity: 120,
	},
	{
		garage_id: 78,
		part_id: 381,
		quantity: 24,
	},
	{
		garage_id: 78,
		part_id: 382,
		quantity: 281,
	},
	{
		garage_id: 78,
		part_id: 383,
		quantity: 30,
	},
	{
		garage_id: 78,
		part_id: 384,
		quantity: 367,
	},
	{
		garage_id: 78,
		part_id: 385,
		quantity: 228,
	},
	{
		garage_id: 78,
		part_id: 386,
		quantity: 48,
	},
	{
		garage_id: 78,
		part_id: 387,
		quantity: 126,
	},
	{
		garage_id: 78,
		part_id: 388,
		quantity: 52,
	},
	{
		garage_id: 78,
		part_id: 389,
		quantity: 124,
	},
	{
		garage_id: 78,
		part_id: 390,
		quantity: 5,
	},
	{
		garage_id: 78,
		part_id: 391,
		quantity: 425,
	},
	{
		garage_id: 78,
		part_id: 392,
		quantity: 291,
	},
	{
		garage_id: 78,
		part_id: 393,
		quantity: 143,
	},
	{
		garage_id: 78,
		part_id: 394,
		quantity: 218,
	},
	{
		garage_id: 78,
		part_id: 395,
		quantity: 311,
	},
	{
		garage_id: 78,
		part_id: 396,
		quantity: 84,
	},
	{
		garage_id: 78,
		part_id: 397,
		quantity: 207,
	},
	{
		garage_id: 78,
		part_id: 398,
		quantity: 309,
	},
	{
		garage_id: 78,
		part_id: 399,
		quantity: 334,
	},
	{
		garage_id: 79,
		part_id: 0,
		quantity: 218,
	},
	{
		garage_id: 79,
		part_id: 1,
		quantity: 470,
	},
	{
		garage_id: 79,
		part_id: 2,
		quantity: 81,
	},
	{
		garage_id: 79,
		part_id: 3,
		quantity: 10,
	},
	{
		garage_id: 79,
		part_id: 4,
		quantity: 60,
	},
	{
		garage_id: 79,
		part_id: 5,
		quantity: 409,
	},
	{
		garage_id: 79,
		part_id: 6,
		quantity: 401,
	},
	{
		garage_id: 79,
		part_id: 7,
		quantity: 67,
	},
	{
		garage_id: 79,
		part_id: 8,
		quantity: 5,
	},
	{
		garage_id: 79,
		part_id: 9,
		quantity: 69,
	},
	{
		garage_id: 79,
		part_id: 10,
		quantity: 272,
	},
	{
		garage_id: 79,
		part_id: 11,
		quantity: 417,
	},
	{
		garage_id: 79,
		part_id: 12,
		quantity: 78,
	},
	{
		garage_id: 79,
		part_id: 13,
		quantity: 182,
	},
	{
		garage_id: 79,
		part_id: 14,
		quantity: 13,
	},
	{
		garage_id: 79,
		part_id: 15,
		quantity: 58,
	},
	{
		garage_id: 79,
		part_id: 16,
		quantity: 173,
	},
	{
		garage_id: 79,
		part_id: 17,
		quantity: 348,
	},
	{
		garage_id: 79,
		part_id: 18,
		quantity: 324,
	},
	{
		garage_id: 79,
		part_id: 19,
		quantity: 1,
	},
	{
		garage_id: 79,
		part_id: 20,
		quantity: 160,
	},
	{
		garage_id: 79,
		part_id: 21,
		quantity: 172,
	},
	{
		garage_id: 79,
		part_id: 22,
		quantity: 309,
	},
	{
		garage_id: 79,
		part_id: 23,
		quantity: 314,
	},
	{
		garage_id: 79,
		part_id: 24,
		quantity: 374,
	},
	{
		garage_id: 79,
		part_id: 25,
		quantity: 195,
	},
	{
		garage_id: 79,
		part_id: 26,
		quantity: 298,
	},
	{
		garage_id: 79,
		part_id: 27,
		quantity: 230,
	},
	{
		garage_id: 79,
		part_id: 28,
		quantity: 105,
	},
	{
		garage_id: 79,
		part_id: 29,
		quantity: 432,
	},
	{
		garage_id: 79,
		part_id: 30,
		quantity: 242,
	},
	{
		garage_id: 79,
		part_id: 31,
		quantity: 398,
	},
	{
		garage_id: 79,
		part_id: 32,
		quantity: 150,
	},
	{
		garage_id: 79,
		part_id: 33,
		quantity: 385,
	},
	{
		garage_id: 79,
		part_id: 34,
		quantity: 72,
	},
	{
		garage_id: 79,
		part_id: 35,
		quantity: 2,
	},
	{
		garage_id: 79,
		part_id: 36,
		quantity: 203,
	},
	{
		garage_id: 79,
		part_id: 37,
		quantity: 232,
	},
	{
		garage_id: 79,
		part_id: 38,
		quantity: 365,
	},
	{
		garage_id: 79,
		part_id: 39,
		quantity: 388,
	},
	{
		garage_id: 79,
		part_id: 40,
		quantity: 116,
	},
	{
		garage_id: 79,
		part_id: 41,
		quantity: 396,
	},
	{
		garage_id: 79,
		part_id: 42,
		quantity: 182,
	},
	{
		garage_id: 79,
		part_id: 43,
		quantity: 42,
	},
	{
		garage_id: 79,
		part_id: 44,
		quantity: 72,
	},
	{
		garage_id: 79,
		part_id: 45,
		quantity: 345,
	},
	{
		garage_id: 79,
		part_id: 46,
		quantity: 466,
	},
	{
		garage_id: 79,
		part_id: 47,
		quantity: 234,
	},
	{
		garage_id: 79,
		part_id: 48,
		quantity: 212,
	},
	{
		garage_id: 79,
		part_id: 49,
		quantity: 356,
	},
	{
		garage_id: 79,
		part_id: 50,
		quantity: 44,
	},
	{
		garage_id: 79,
		part_id: 51,
		quantity: 90,
	},
	{
		garage_id: 79,
		part_id: 52,
		quantity: 258,
	},
	{
		garage_id: 79,
		part_id: 53,
		quantity: 212,
	},
	{
		garage_id: 79,
		part_id: 54,
		quantity: 118,
	},
	{
		garage_id: 79,
		part_id: 55,
		quantity: 165,
	},
	{
		garage_id: 79,
		part_id: 56,
		quantity: 33,
	},
	{
		garage_id: 79,
		part_id: 57,
		quantity: 212,
	},
	{
		garage_id: 79,
		part_id: 58,
		quantity: 335,
	},
	{
		garage_id: 79,
		part_id: 59,
		quantity: 56,
	},
	{
		garage_id: 79,
		part_id: 60,
		quantity: 322,
	},
	{
		garage_id: 79,
		part_id: 61,
		quantity: 393,
	},
	{
		garage_id: 79,
		part_id: 62,
		quantity: 149,
	},
	{
		garage_id: 79,
		part_id: 63,
		quantity: 355,
	},
	{
		garage_id: 79,
		part_id: 64,
		quantity: 26,
	},
	{
		garage_id: 79,
		part_id: 65,
		quantity: 182,
	},
	{
		garage_id: 79,
		part_id: 66,
		quantity: 180,
	},
	{
		garage_id: 79,
		part_id: 67,
		quantity: 209,
	},
	{
		garage_id: 79,
		part_id: 68,
		quantity: 263,
	},
	{
		garage_id: 79,
		part_id: 69,
		quantity: 146,
	},
	{
		garage_id: 79,
		part_id: 70,
		quantity: 368,
	},
	{
		garage_id: 79,
		part_id: 71,
		quantity: 365,
	},
	{
		garage_id: 79,
		part_id: 72,
		quantity: 367,
	},
	{
		garage_id: 79,
		part_id: 73,
		quantity: 463,
	},
	{
		garage_id: 79,
		part_id: 74,
		quantity: 87,
	},
	{
		garage_id: 79,
		part_id: 75,
		quantity: 289,
	},
	{
		garage_id: 79,
		part_id: 76,
		quantity: 194,
	},
	{
		garage_id: 79,
		part_id: 77,
		quantity: 464,
	},
	{
		garage_id: 79,
		part_id: 78,
		quantity: 94,
	},
	{
		garage_id: 79,
		part_id: 79,
		quantity: 265,
	},
	{
		garage_id: 79,
		part_id: 80,
		quantity: 461,
	},
	{
		garage_id: 79,
		part_id: 81,
		quantity: 426,
	},
	{
		garage_id: 79,
		part_id: 82,
		quantity: 123,
	},
	{
		garage_id: 79,
		part_id: 83,
		quantity: 453,
	},
	{
		garage_id: 79,
		part_id: 84,
		quantity: 163,
	},
	{
		garage_id: 79,
		part_id: 85,
		quantity: 449,
	},
	{
		garage_id: 79,
		part_id: 86,
		quantity: 412,
	},
	{
		garage_id: 79,
		part_id: 87,
		quantity: 179,
	},
	{
		garage_id: 79,
		part_id: 88,
		quantity: 185,
	},
	{
		garage_id: 79,
		part_id: 89,
		quantity: 189,
	},
	{
		garage_id: 79,
		part_id: 90,
		quantity: 170,
	},
	{
		garage_id: 79,
		part_id: 91,
		quantity: 127,
	},
	{
		garage_id: 79,
		part_id: 92,
		quantity: 453,
	},
	{
		garage_id: 79,
		part_id: 93,
		quantity: 191,
	},
	{
		garage_id: 79,
		part_id: 94,
		quantity: 180,
	},
	{
		garage_id: 79,
		part_id: 95,
		quantity: 101,
	},
	{
		garage_id: 79,
		part_id: 96,
		quantity: 297,
	},
	{
		garage_id: 79,
		part_id: 97,
		quantity: 206,
	},
	{
		garage_id: 79,
		part_id: 98,
		quantity: 414,
	},
	{
		garage_id: 79,
		part_id: 99,
		quantity: 437,
	},
	{
		garage_id: 79,
		part_id: 100,
		quantity: 302,
	},
	{
		garage_id: 79,
		part_id: 101,
		quantity: 461,
	},
	{
		garage_id: 79,
		part_id: 102,
		quantity: 347,
	},
	{
		garage_id: 79,
		part_id: 103,
		quantity: 232,
	},
	{
		garage_id: 79,
		part_id: 104,
		quantity: 254,
	},
	{
		garage_id: 79,
		part_id: 105,
		quantity: 82,
	},
	{
		garage_id: 79,
		part_id: 106,
		quantity: 426,
	},
	{
		garage_id: 79,
		part_id: 107,
		quantity: 127,
	},
	{
		garage_id: 79,
		part_id: 108,
		quantity: 84,
	},
	{
		garage_id: 79,
		part_id: 109,
		quantity: 405,
	},
	{
		garage_id: 79,
		part_id: 110,
		quantity: 356,
	},
	{
		garage_id: 79,
		part_id: 111,
		quantity: 198,
	},
	{
		garage_id: 79,
		part_id: 112,
		quantity: 151,
	},
	{
		garage_id: 79,
		part_id: 113,
		quantity: 41,
	},
	{
		garage_id: 79,
		part_id: 114,
		quantity: 274,
	},
	{
		garage_id: 79,
		part_id: 115,
		quantity: 343,
	},
	{
		garage_id: 79,
		part_id: 116,
		quantity: 39,
	},
	{
		garage_id: 79,
		part_id: 117,
		quantity: 262,
	},
	{
		garage_id: 79,
		part_id: 118,
		quantity: 397,
	},
	{
		garage_id: 79,
		part_id: 119,
		quantity: 96,
	},
	{
		garage_id: 79,
		part_id: 120,
		quantity: 405,
	},
	{
		garage_id: 79,
		part_id: 121,
		quantity: 475,
	},
	{
		garage_id: 79,
		part_id: 122,
		quantity: 137,
	},
	{
		garage_id: 79,
		part_id: 123,
		quantity: 429,
	},
	{
		garage_id: 79,
		part_id: 124,
		quantity: 332,
	},
	{
		garage_id: 79,
		part_id: 125,
		quantity: 224,
	},
	{
		garage_id: 79,
		part_id: 126,
		quantity: 410,
	},
	{
		garage_id: 79,
		part_id: 127,
		quantity: 352,
	},
	{
		garage_id: 79,
		part_id: 128,
		quantity: 19,
	},
	{
		garage_id: 79,
		part_id: 129,
		quantity: 484,
	},
	{
		garage_id: 79,
		part_id: 130,
		quantity: 495,
	},
	{
		garage_id: 79,
		part_id: 131,
		quantity: 13,
	},
	{
		garage_id: 79,
		part_id: 132,
		quantity: 64,
	},
	{
		garage_id: 79,
		part_id: 133,
		quantity: 166,
	},
	{
		garage_id: 79,
		part_id: 134,
		quantity: 99,
	},
	{
		garage_id: 79,
		part_id: 135,
		quantity: 300,
	},
	{
		garage_id: 79,
		part_id: 136,
		quantity: 130,
	},
	{
		garage_id: 79,
		part_id: 137,
		quantity: 421,
	},
	{
		garage_id: 79,
		part_id: 138,
		quantity: 331,
	},
	{
		garage_id: 79,
		part_id: 139,
		quantity: 220,
	},
	{
		garage_id: 79,
		part_id: 140,
		quantity: 283,
	},
	{
		garage_id: 79,
		part_id: 141,
		quantity: 350,
	},
	{
		garage_id: 79,
		part_id: 142,
		quantity: 372,
	},
	{
		garage_id: 79,
		part_id: 143,
		quantity: 425,
	},
	{
		garage_id: 79,
		part_id: 144,
		quantity: 477,
	},
	{
		garage_id: 79,
		part_id: 145,
		quantity: 314,
	},
	{
		garage_id: 79,
		part_id: 146,
		quantity: 407,
	},
	{
		garage_id: 79,
		part_id: 147,
		quantity: 189,
	},
	{
		garage_id: 79,
		part_id: 148,
		quantity: 334,
	},
	{
		garage_id: 79,
		part_id: 149,
		quantity: 292,
	},
	{
		garage_id: 79,
		part_id: 150,
		quantity: 399,
	},
	{
		garage_id: 79,
		part_id: 151,
		quantity: 215,
	},
	{
		garage_id: 79,
		part_id: 152,
		quantity: 435,
	},
	{
		garage_id: 79,
		part_id: 153,
		quantity: 384,
	},
	{
		garage_id: 79,
		part_id: 154,
		quantity: 25,
	},
	{
		garage_id: 79,
		part_id: 155,
		quantity: 369,
	},
	{
		garage_id: 79,
		part_id: 156,
		quantity: 323,
	},
	{
		garage_id: 79,
		part_id: 157,
		quantity: 465,
	},
	{
		garage_id: 79,
		part_id: 158,
		quantity: 441,
	},
	{
		garage_id: 79,
		part_id: 159,
		quantity: 275,
	},
	{
		garage_id: 79,
		part_id: 160,
		quantity: 500,
	},
	{
		garage_id: 79,
		part_id: 161,
		quantity: 317,
	},
	{
		garage_id: 79,
		part_id: 162,
		quantity: 449,
	},
	{
		garage_id: 79,
		part_id: 163,
		quantity: 488,
	},
	{
		garage_id: 79,
		part_id: 164,
		quantity: 165,
	},
	{
		garage_id: 79,
		part_id: 165,
		quantity: 465,
	},
	{
		garage_id: 79,
		part_id: 166,
		quantity: 97,
	},
	{
		garage_id: 79,
		part_id: 167,
		quantity: 272,
	},
	{
		garage_id: 79,
		part_id: 168,
		quantity: 225,
	},
	{
		garage_id: 79,
		part_id: 169,
		quantity: 401,
	},
	{
		garage_id: 79,
		part_id: 170,
		quantity: 225,
	},
	{
		garage_id: 79,
		part_id: 171,
		quantity: 86,
	},
	{
		garage_id: 79,
		part_id: 172,
		quantity: 69,
	},
	{
		garage_id: 79,
		part_id: 173,
		quantity: 159,
	},
	{
		garage_id: 79,
		part_id: 174,
		quantity: 89,
	},
	{
		garage_id: 79,
		part_id: 175,
		quantity: 278,
	},
	{
		garage_id: 79,
		part_id: 176,
		quantity: 267,
	},
	{
		garage_id: 79,
		part_id: 177,
		quantity: 465,
	},
	{
		garage_id: 79,
		part_id: 178,
		quantity: 382,
	},
	{
		garage_id: 79,
		part_id: 179,
		quantity: 326,
	},
	{
		garage_id: 79,
		part_id: 180,
		quantity: 356,
	},
	{
		garage_id: 79,
		part_id: 181,
		quantity: 154,
	},
	{
		garage_id: 79,
		part_id: 182,
		quantity: 14,
	},
	{
		garage_id: 79,
		part_id: 183,
		quantity: 243,
	},
	{
		garage_id: 79,
		part_id: 184,
		quantity: 31,
	},
	{
		garage_id: 79,
		part_id: 185,
		quantity: 448,
	},
	{
		garage_id: 79,
		part_id: 186,
		quantity: 257,
	},
	{
		garage_id: 79,
		part_id: 187,
		quantity: 495,
	},
	{
		garage_id: 79,
		part_id: 188,
		quantity: 13,
	},
	{
		garage_id: 79,
		part_id: 189,
		quantity: 63,
	},
	{
		garage_id: 79,
		part_id: 190,
		quantity: 193,
	},
	{
		garage_id: 79,
		part_id: 191,
		quantity: 130,
	},
	{
		garage_id: 79,
		part_id: 192,
		quantity: 263,
	},
	{
		garage_id: 79,
		part_id: 193,
		quantity: 499,
	},
	{
		garage_id: 79,
		part_id: 194,
		quantity: 245,
	},
	{
		garage_id: 79,
		part_id: 195,
		quantity: 328,
	},
	{
		garage_id: 79,
		part_id: 196,
		quantity: 198,
	},
	{
		garage_id: 79,
		part_id: 197,
		quantity: 49,
	},
	{
		garage_id: 79,
		part_id: 198,
		quantity: 177,
	},
	{
		garage_id: 79,
		part_id: 199,
		quantity: 385,
	},
	{
		garage_id: 79,
		part_id: 200,
		quantity: 22,
	},
	{
		garage_id: 79,
		part_id: 201,
		quantity: 226,
	},
	{
		garage_id: 79,
		part_id: 202,
		quantity: 78,
	},
	{
		garage_id: 79,
		part_id: 203,
		quantity: 190,
	},
	{
		garage_id: 79,
		part_id: 204,
		quantity: 257,
	},
	{
		garage_id: 79,
		part_id: 205,
		quantity: 441,
	},
	{
		garage_id: 79,
		part_id: 206,
		quantity: 313,
	},
	{
		garage_id: 79,
		part_id: 207,
		quantity: 483,
	},
	{
		garage_id: 79,
		part_id: 208,
		quantity: 406,
	},
	{
		garage_id: 79,
		part_id: 209,
		quantity: 252,
	},
	{
		garage_id: 79,
		part_id: 210,
		quantity: 245,
	},
	{
		garage_id: 79,
		part_id: 211,
		quantity: 151,
	},
	{
		garage_id: 79,
		part_id: 212,
		quantity: 393,
	},
	{
		garage_id: 79,
		part_id: 213,
		quantity: 347,
	},
	{
		garage_id: 79,
		part_id: 214,
		quantity: 335,
	},
	{
		garage_id: 79,
		part_id: 215,
		quantity: 340,
	},
	{
		garage_id: 79,
		part_id: 216,
		quantity: 1,
	},
	{
		garage_id: 79,
		part_id: 217,
		quantity: 145,
	},
	{
		garage_id: 79,
		part_id: 218,
		quantity: 363,
	},
	{
		garage_id: 79,
		part_id: 219,
		quantity: 110,
	},
	{
		garage_id: 79,
		part_id: 220,
		quantity: 301,
	},
	{
		garage_id: 79,
		part_id: 221,
		quantity: 375,
	},
	{
		garage_id: 79,
		part_id: 222,
		quantity: 216,
	},
	{
		garage_id: 79,
		part_id: 223,
		quantity: 393,
	},
	{
		garage_id: 79,
		part_id: 224,
		quantity: 286,
	},
	{
		garage_id: 79,
		part_id: 225,
		quantity: 141,
	},
	{
		garage_id: 79,
		part_id: 226,
		quantity: 333,
	},
	{
		garage_id: 79,
		part_id: 227,
		quantity: 236,
	},
	{
		garage_id: 79,
		part_id: 228,
		quantity: 28,
	},
	{
		garage_id: 79,
		part_id: 229,
		quantity: 111,
	},
	{
		garage_id: 79,
		part_id: 230,
		quantity: 46,
	},
	{
		garage_id: 79,
		part_id: 231,
		quantity: 45,
	},
	{
		garage_id: 79,
		part_id: 232,
		quantity: 269,
	},
	{
		garage_id: 79,
		part_id: 233,
		quantity: 51,
	},
	{
		garage_id: 79,
		part_id: 234,
		quantity: 117,
	},
	{
		garage_id: 79,
		part_id: 235,
		quantity: 496,
	},
	{
		garage_id: 79,
		part_id: 236,
		quantity: 4,
	},
	{
		garage_id: 79,
		part_id: 237,
		quantity: 314,
	},
	{
		garage_id: 79,
		part_id: 238,
		quantity: 339,
	},
	{
		garage_id: 79,
		part_id: 239,
		quantity: 453,
	},
	{
		garage_id: 79,
		part_id: 240,
		quantity: 53,
	},
	{
		garage_id: 79,
		part_id: 241,
		quantity: 281,
	},
	{
		garage_id: 79,
		part_id: 242,
		quantity: 140,
	},
	{
		garage_id: 79,
		part_id: 243,
		quantity: 498,
	},
	{
		garage_id: 79,
		part_id: 244,
		quantity: 378,
	},
	{
		garage_id: 79,
		part_id: 245,
		quantity: 378,
	},
	{
		garage_id: 79,
		part_id: 246,
		quantity: 180,
	},
	{
		garage_id: 79,
		part_id: 247,
		quantity: 36,
	},
	{
		garage_id: 79,
		part_id: 248,
		quantity: 140,
	},
	{
		garage_id: 79,
		part_id: 249,
		quantity: 349,
	},
	{
		garage_id: 79,
		part_id: 250,
		quantity: 415,
	},
	{
		garage_id: 79,
		part_id: 251,
		quantity: 109,
	},
	{
		garage_id: 79,
		part_id: 252,
		quantity: 3,
	},
	{
		garage_id: 79,
		part_id: 253,
		quantity: 339,
	},
	{
		garage_id: 79,
		part_id: 254,
		quantity: 457,
	},
	{
		garage_id: 79,
		part_id: 255,
		quantity: 299,
	},
	{
		garage_id: 79,
		part_id: 256,
		quantity: 447,
	},
	{
		garage_id: 79,
		part_id: 257,
		quantity: 34,
	},
	{
		garage_id: 79,
		part_id: 258,
		quantity: 84,
	},
	{
		garage_id: 79,
		part_id: 259,
		quantity: 350,
	},
	{
		garage_id: 79,
		part_id: 260,
		quantity: 428,
	},
	{
		garage_id: 79,
		part_id: 261,
		quantity: 271,
	},
	{
		garage_id: 79,
		part_id: 262,
		quantity: 439,
	},
	{
		garage_id: 79,
		part_id: 263,
		quantity: 205,
	},
	{
		garage_id: 79,
		part_id: 264,
		quantity: 312,
	},
	{
		garage_id: 79,
		part_id: 265,
		quantity: 84,
	},
	{
		garage_id: 79,
		part_id: 266,
		quantity: 391,
	},
	{
		garage_id: 79,
		part_id: 267,
		quantity: 264,
	},
	{
		garage_id: 79,
		part_id: 268,
		quantity: 245,
	},
	{
		garage_id: 79,
		part_id: 269,
		quantity: 420,
	},
	{
		garage_id: 79,
		part_id: 270,
		quantity: 199,
	},
	{
		garage_id: 79,
		part_id: 271,
		quantity: 438,
	},
	{
		garage_id: 79,
		part_id: 272,
		quantity: 481,
	},
	{
		garage_id: 79,
		part_id: 273,
		quantity: 338,
	},
	{
		garage_id: 79,
		part_id: 274,
		quantity: 385,
	},
	{
		garage_id: 79,
		part_id: 275,
		quantity: 484,
	},
	{
		garage_id: 79,
		part_id: 276,
		quantity: 61,
	},
	{
		garage_id: 79,
		part_id: 277,
		quantity: 130,
	},
	{
		garage_id: 79,
		part_id: 278,
		quantity: 188,
	},
	{
		garage_id: 79,
		part_id: 279,
		quantity: 117,
	},
	{
		garage_id: 79,
		part_id: 280,
		quantity: 171,
	},
	{
		garage_id: 79,
		part_id: 281,
		quantity: 307,
	},
	{
		garage_id: 79,
		part_id: 282,
		quantity: 24,
	},
	{
		garage_id: 79,
		part_id: 283,
		quantity: 484,
	},
	{
		garage_id: 79,
		part_id: 284,
		quantity: 184,
	},
	{
		garage_id: 79,
		part_id: 285,
		quantity: 440,
	},
	{
		garage_id: 79,
		part_id: 286,
		quantity: 459,
	},
	{
		garage_id: 79,
		part_id: 287,
		quantity: 329,
	},
	{
		garage_id: 79,
		part_id: 288,
		quantity: 342,
	},
	{
		garage_id: 79,
		part_id: 289,
		quantity: 171,
	},
	{
		garage_id: 79,
		part_id: 290,
		quantity: 394,
	},
	{
		garage_id: 79,
		part_id: 291,
		quantity: 498,
	},
	{
		garage_id: 79,
		part_id: 292,
		quantity: 348,
	},
	{
		garage_id: 79,
		part_id: 293,
		quantity: 28,
	},
	{
		garage_id: 79,
		part_id: 294,
		quantity: 141,
	},
	{
		garage_id: 79,
		part_id: 295,
		quantity: 75,
	},
	{
		garage_id: 79,
		part_id: 296,
		quantity: 142,
	},
	{
		garage_id: 79,
		part_id: 297,
		quantity: 449,
	},
	{
		garage_id: 79,
		part_id: 298,
		quantity: 356,
	},
	{
		garage_id: 79,
		part_id: 299,
		quantity: 431,
	},
	{
		garage_id: 79,
		part_id: 300,
		quantity: 200,
	},
	{
		garage_id: 79,
		part_id: 301,
		quantity: 40,
	},
	{
		garage_id: 79,
		part_id: 302,
		quantity: 249,
	},
	{
		garage_id: 79,
		part_id: 303,
		quantity: 284,
	},
	{
		garage_id: 79,
		part_id: 304,
		quantity: 349,
	},
	{
		garage_id: 79,
		part_id: 305,
		quantity: 264,
	},
	{
		garage_id: 79,
		part_id: 306,
		quantity: 82,
	},
	{
		garage_id: 79,
		part_id: 307,
		quantity: 207,
	},
	{
		garage_id: 79,
		part_id: 308,
		quantity: 338,
	},
	{
		garage_id: 79,
		part_id: 309,
		quantity: 347,
	},
	{
		garage_id: 79,
		part_id: 310,
		quantity: 312,
	},
	{
		garage_id: 79,
		part_id: 311,
		quantity: 207,
	},
	{
		garage_id: 79,
		part_id: 312,
		quantity: 19,
	},
	{
		garage_id: 79,
		part_id: 313,
		quantity: 89,
	},
	{
		garage_id: 79,
		part_id: 314,
		quantity: 199,
	},
	{
		garage_id: 79,
		part_id: 315,
		quantity: 316,
	},
	{
		garage_id: 79,
		part_id: 316,
		quantity: 423,
	},
	{
		garage_id: 79,
		part_id: 317,
		quantity: 237,
	},
	{
		garage_id: 79,
		part_id: 318,
		quantity: 202,
	},
	{
		garage_id: 79,
		part_id: 319,
		quantity: 114,
	},
	{
		garage_id: 79,
		part_id: 320,
		quantity: 218,
	},
	{
		garage_id: 79,
		part_id: 321,
		quantity: 374,
	},
	{
		garage_id: 79,
		part_id: 322,
		quantity: 374,
	},
	{
		garage_id: 79,
		part_id: 323,
		quantity: 207,
	},
	{
		garage_id: 79,
		part_id: 324,
		quantity: 236,
	},
	{
		garage_id: 79,
		part_id: 325,
		quantity: 79,
	},
	{
		garage_id: 79,
		part_id: 326,
		quantity: 432,
	},
	{
		garage_id: 79,
		part_id: 327,
		quantity: 432,
	},
	{
		garage_id: 79,
		part_id: 328,
		quantity: 497,
	},
	{
		garage_id: 79,
		part_id: 329,
		quantity: 102,
	},
	{
		garage_id: 79,
		part_id: 330,
		quantity: 245,
	},
	{
		garage_id: 79,
		part_id: 331,
		quantity: 134,
	},
	{
		garage_id: 79,
		part_id: 332,
		quantity: 234,
	},
	{
		garage_id: 79,
		part_id: 333,
		quantity: 383,
	},
	{
		garage_id: 79,
		part_id: 334,
		quantity: 499,
	},
	{
		garage_id: 79,
		part_id: 335,
		quantity: 265,
	},
	{
		garage_id: 79,
		part_id: 336,
		quantity: 140,
	},
	{
		garage_id: 79,
		part_id: 337,
		quantity: 449,
	},
	{
		garage_id: 79,
		part_id: 338,
		quantity: 345,
	},
	{
		garage_id: 79,
		part_id: 339,
		quantity: 450,
	},
	{
		garage_id: 79,
		part_id: 340,
		quantity: 98,
	},
	{
		garage_id: 79,
		part_id: 341,
		quantity: 71,
	},
	{
		garage_id: 79,
		part_id: 342,
		quantity: 241,
	},
	{
		garage_id: 79,
		part_id: 343,
		quantity: 72,
	},
	{
		garage_id: 79,
		part_id: 344,
		quantity: 203,
	},
	{
		garage_id: 79,
		part_id: 345,
		quantity: 255,
	},
	{
		garage_id: 79,
		part_id: 346,
		quantity: 7,
	},
	{
		garage_id: 79,
		part_id: 347,
		quantity: 414,
	},
	{
		garage_id: 79,
		part_id: 348,
		quantity: 57,
	},
	{
		garage_id: 79,
		part_id: 349,
		quantity: 489,
	},
	{
		garage_id: 79,
		part_id: 350,
		quantity: 186,
	},
	{
		garage_id: 79,
		part_id: 351,
		quantity: 318,
	},
	{
		garage_id: 79,
		part_id: 352,
		quantity: 366,
	},
	{
		garage_id: 79,
		part_id: 353,
		quantity: 261,
	},
	{
		garage_id: 79,
		part_id: 354,
		quantity: 391,
	},
	{
		garage_id: 79,
		part_id: 355,
		quantity: 288,
	},
	{
		garage_id: 79,
		part_id: 356,
		quantity: 476,
	},
	{
		garage_id: 79,
		part_id: 357,
		quantity: 1,
	},
	{
		garage_id: 79,
		part_id: 358,
		quantity: 85,
	},
	{
		garage_id: 79,
		part_id: 359,
		quantity: 3,
	},
	{
		garage_id: 79,
		part_id: 360,
		quantity: 370,
	},
	{
		garage_id: 79,
		part_id: 361,
		quantity: 59,
	},
	{
		garage_id: 79,
		part_id: 362,
		quantity: 100,
	},
	{
		garage_id: 79,
		part_id: 363,
		quantity: 330,
	},
	{
		garage_id: 79,
		part_id: 364,
		quantity: 211,
	},
	{
		garage_id: 79,
		part_id: 365,
		quantity: 426,
	},
	{
		garage_id: 79,
		part_id: 366,
		quantity: 171,
	},
	{
		garage_id: 79,
		part_id: 367,
		quantity: 151,
	},
	{
		garage_id: 79,
		part_id: 368,
		quantity: 387,
	},
	{
		garage_id: 79,
		part_id: 369,
		quantity: 276,
	},
	{
		garage_id: 79,
		part_id: 370,
		quantity: 269,
	},
	{
		garage_id: 79,
		part_id: 371,
		quantity: 97,
	},
	{
		garage_id: 79,
		part_id: 372,
		quantity: 331,
	},
	{
		garage_id: 79,
		part_id: 373,
		quantity: 345,
	},
	{
		garage_id: 79,
		part_id: 374,
		quantity: 184,
	},
	{
		garage_id: 79,
		part_id: 375,
		quantity: 303,
	},
	{
		garage_id: 79,
		part_id: 376,
		quantity: 34,
	},
	{
		garage_id: 79,
		part_id: 377,
		quantity: 348,
	},
	{
		garage_id: 79,
		part_id: 378,
		quantity: 174,
	},
	{
		garage_id: 79,
		part_id: 379,
		quantity: 295,
	},
	{
		garage_id: 79,
		part_id: 380,
		quantity: 335,
	},
	{
		garage_id: 79,
		part_id: 381,
		quantity: 330,
	},
	{
		garage_id: 79,
		part_id: 382,
		quantity: 209,
	},
	{
		garage_id: 79,
		part_id: 383,
		quantity: 125,
	},
	{
		garage_id: 79,
		part_id: 384,
		quantity: 22,
	},
	{
		garage_id: 79,
		part_id: 385,
		quantity: 269,
	},
	{
		garage_id: 79,
		part_id: 386,
		quantity: 299,
	},
	{
		garage_id: 79,
		part_id: 387,
		quantity: 167,
	},
	{
		garage_id: 79,
		part_id: 388,
		quantity: 224,
	},
	{
		garage_id: 79,
		part_id: 389,
		quantity: 365,
	},
	{
		garage_id: 79,
		part_id: 390,
		quantity: 283,
	},
	{
		garage_id: 79,
		part_id: 391,
		quantity: 237,
	},
	{
		garage_id: 79,
		part_id: 392,
		quantity: 33,
	},
	{
		garage_id: 79,
		part_id: 393,
		quantity: 454,
	},
	{
		garage_id: 79,
		part_id: 394,
		quantity: 108,
	},
	{
		garage_id: 79,
		part_id: 395,
		quantity: 412,
	},
	{
		garage_id: 79,
		part_id: 396,
		quantity: 359,
	},
	{
		garage_id: 79,
		part_id: 397,
		quantity: 186,
	},
	{
		garage_id: 79,
		part_id: 398,
		quantity: 340,
	},
	{
		garage_id: 79,
		part_id: 399,
		quantity: 331,
	},
	{
		garage_id: 80,
		part_id: 0,
		quantity: 482,
	},
	{
		garage_id: 80,
		part_id: 1,
		quantity: 17,
	},
	{
		garage_id: 80,
		part_id: 2,
		quantity: 168,
	},
	{
		garage_id: 80,
		part_id: 3,
		quantity: 167,
	},
	{
		garage_id: 80,
		part_id: 4,
		quantity: 430,
	},
	{
		garage_id: 80,
		part_id: 5,
		quantity: 87,
	},
	{
		garage_id: 80,
		part_id: 6,
		quantity: 7,
	},
	{
		garage_id: 80,
		part_id: 7,
		quantity: 246,
	},
	{
		garage_id: 80,
		part_id: 8,
		quantity: 16,
	},
	{
		garage_id: 80,
		part_id: 9,
		quantity: 103,
	},
	{
		garage_id: 80,
		part_id: 10,
		quantity: 480,
	},
	{
		garage_id: 80,
		part_id: 11,
		quantity: 10,
	},
	{
		garage_id: 80,
		part_id: 12,
		quantity: 11,
	},
	{
		garage_id: 80,
		part_id: 13,
		quantity: 338,
	},
	{
		garage_id: 80,
		part_id: 14,
		quantity: 489,
	},
	{
		garage_id: 80,
		part_id: 15,
		quantity: 429,
	},
	{
		garage_id: 80,
		part_id: 16,
		quantity: 416,
	},
	{
		garage_id: 80,
		part_id: 17,
		quantity: 322,
	},
	{
		garage_id: 80,
		part_id: 18,
		quantity: 178,
	},
	{
		garage_id: 80,
		part_id: 19,
		quantity: 143,
	},
	{
		garage_id: 80,
		part_id: 20,
		quantity: 467,
	},
	{
		garage_id: 80,
		part_id: 21,
		quantity: 280,
	},
	{
		garage_id: 80,
		part_id: 22,
		quantity: 397,
	},
	{
		garage_id: 80,
		part_id: 23,
		quantity: 108,
	},
	{
		garage_id: 80,
		part_id: 24,
		quantity: 358,
	},
	{
		garage_id: 80,
		part_id: 25,
		quantity: 22,
	},
	{
		garage_id: 80,
		part_id: 26,
		quantity: 369,
	},
	{
		garage_id: 80,
		part_id: 27,
		quantity: 123,
	},
	{
		garage_id: 80,
		part_id: 28,
		quantity: 400,
	},
	{
		garage_id: 80,
		part_id: 29,
		quantity: 356,
	},
	{
		garage_id: 80,
		part_id: 30,
		quantity: 26,
	},
	{
		garage_id: 80,
		part_id: 31,
		quantity: 446,
	},
	{
		garage_id: 80,
		part_id: 32,
		quantity: 353,
	},
	{
		garage_id: 80,
		part_id: 33,
		quantity: 169,
	},
	{
		garage_id: 80,
		part_id: 34,
		quantity: 72,
	},
	{
		garage_id: 80,
		part_id: 35,
		quantity: 333,
	},
	{
		garage_id: 80,
		part_id: 36,
		quantity: 96,
	},
	{
		garage_id: 80,
		part_id: 37,
		quantity: 79,
	},
	{
		garage_id: 80,
		part_id: 38,
		quantity: 196,
	},
	{
		garage_id: 80,
		part_id: 39,
		quantity: 102,
	},
	{
		garage_id: 80,
		part_id: 40,
		quantity: 183,
	},
	{
		garage_id: 80,
		part_id: 41,
		quantity: 203,
	},
	{
		garage_id: 80,
		part_id: 42,
		quantity: 149,
	},
	{
		garage_id: 80,
		part_id: 43,
		quantity: 423,
	},
	{
		garage_id: 80,
		part_id: 44,
		quantity: 490,
	},
	{
		garage_id: 80,
		part_id: 45,
		quantity: 444,
	},
	{
		garage_id: 80,
		part_id: 46,
		quantity: 155,
	},
	{
		garage_id: 80,
		part_id: 47,
		quantity: 122,
	},
	{
		garage_id: 80,
		part_id: 48,
		quantity: 98,
	},
	{
		garage_id: 80,
		part_id: 49,
		quantity: 411,
	},
	{
		garage_id: 80,
		part_id: 50,
		quantity: 206,
	},
	{
		garage_id: 80,
		part_id: 51,
		quantity: 250,
	},
	{
		garage_id: 80,
		part_id: 52,
		quantity: 57,
	},
	{
		garage_id: 80,
		part_id: 53,
		quantity: 31,
	},
	{
		garage_id: 80,
		part_id: 54,
		quantity: 448,
	},
	{
		garage_id: 80,
		part_id: 55,
		quantity: 53,
	},
	{
		garage_id: 80,
		part_id: 56,
		quantity: 309,
	},
	{
		garage_id: 80,
		part_id: 57,
		quantity: 389,
	},
	{
		garage_id: 80,
		part_id: 58,
		quantity: 472,
	},
	{
		garage_id: 80,
		part_id: 59,
		quantity: 177,
	},
	{
		garage_id: 80,
		part_id: 60,
		quantity: 351,
	},
	{
		garage_id: 80,
		part_id: 61,
		quantity: 40,
	},
	{
		garage_id: 80,
		part_id: 62,
		quantity: 151,
	},
	{
		garage_id: 80,
		part_id: 63,
		quantity: 38,
	},
	{
		garage_id: 80,
		part_id: 64,
		quantity: 279,
	},
	{
		garage_id: 80,
		part_id: 65,
		quantity: 466,
	},
	{
		garage_id: 80,
		part_id: 66,
		quantity: 407,
	},
	{
		garage_id: 80,
		part_id: 67,
		quantity: 143,
	},
	{
		garage_id: 80,
		part_id: 68,
		quantity: 267,
	},
	{
		garage_id: 80,
		part_id: 69,
		quantity: 498,
	},
	{
		garage_id: 80,
		part_id: 70,
		quantity: 46,
	},
	{
		garage_id: 80,
		part_id: 71,
		quantity: 436,
	},
	{
		garage_id: 80,
		part_id: 72,
		quantity: 79,
	},
	{
		garage_id: 80,
		part_id: 73,
		quantity: 140,
	},
	{
		garage_id: 80,
		part_id: 74,
		quantity: 120,
	},
	{
		garage_id: 80,
		part_id: 75,
		quantity: 401,
	},
	{
		garage_id: 80,
		part_id: 76,
		quantity: 388,
	},
	{
		garage_id: 80,
		part_id: 77,
		quantity: 40,
	},
	{
		garage_id: 80,
		part_id: 78,
		quantity: 67,
	},
	{
		garage_id: 80,
		part_id: 79,
		quantity: 230,
	},
	{
		garage_id: 80,
		part_id: 80,
		quantity: 167,
	},
	{
		garage_id: 80,
		part_id: 81,
		quantity: 370,
	},
	{
		garage_id: 80,
		part_id: 82,
		quantity: 186,
	},
	{
		garage_id: 80,
		part_id: 83,
		quantity: 335,
	},
	{
		garage_id: 80,
		part_id: 84,
		quantity: 232,
	},
	{
		garage_id: 80,
		part_id: 85,
		quantity: 188,
	},
	{
		garage_id: 80,
		part_id: 86,
		quantity: 204,
	},
	{
		garage_id: 80,
		part_id: 87,
		quantity: 373,
	},
	{
		garage_id: 80,
		part_id: 88,
		quantity: 224,
	},
	{
		garage_id: 80,
		part_id: 89,
		quantity: 355,
	},
	{
		garage_id: 80,
		part_id: 90,
		quantity: 361,
	},
	{
		garage_id: 80,
		part_id: 91,
		quantity: 415,
	},
	{
		garage_id: 80,
		part_id: 92,
		quantity: 298,
	},
	{
		garage_id: 80,
		part_id: 93,
		quantity: 123,
	},
	{
		garage_id: 80,
		part_id: 94,
		quantity: 431,
	},
	{
		garage_id: 80,
		part_id: 95,
		quantity: 225,
	},
	{
		garage_id: 80,
		part_id: 96,
		quantity: 469,
	},
	{
		garage_id: 80,
		part_id: 97,
		quantity: 488,
	},
	{
		garage_id: 80,
		part_id: 98,
		quantity: 353,
	},
	{
		garage_id: 80,
		part_id: 99,
		quantity: 247,
	},
	{
		garage_id: 80,
		part_id: 100,
		quantity: 466,
	},
	{
		garage_id: 80,
		part_id: 101,
		quantity: 460,
	},
	{
		garage_id: 80,
		part_id: 102,
		quantity: 425,
	},
	{
		garage_id: 80,
		part_id: 103,
		quantity: 26,
	},
	{
		garage_id: 80,
		part_id: 104,
		quantity: 442,
	},
	{
		garage_id: 80,
		part_id: 105,
		quantity: 204,
	},
	{
		garage_id: 80,
		part_id: 106,
		quantity: 336,
	},
	{
		garage_id: 80,
		part_id: 107,
		quantity: 202,
	},
	{
		garage_id: 80,
		part_id: 108,
		quantity: 220,
	},
	{
		garage_id: 80,
		part_id: 109,
		quantity: 51,
	},
	{
		garage_id: 80,
		part_id: 110,
		quantity: 88,
	},
	{
		garage_id: 80,
		part_id: 111,
		quantity: 318,
	},
	{
		garage_id: 80,
		part_id: 112,
		quantity: 281,
	},
	{
		garage_id: 80,
		part_id: 113,
		quantity: 368,
	},
	{
		garage_id: 80,
		part_id: 114,
		quantity: 227,
	},
	{
		garage_id: 80,
		part_id: 115,
		quantity: 292,
	},
	{
		garage_id: 80,
		part_id: 116,
		quantity: 339,
	},
	{
		garage_id: 80,
		part_id: 117,
		quantity: 284,
	},
	{
		garage_id: 80,
		part_id: 118,
		quantity: 224,
	},
	{
		garage_id: 80,
		part_id: 119,
		quantity: 369,
	},
	{
		garage_id: 80,
		part_id: 120,
		quantity: 370,
	},
	{
		garage_id: 80,
		part_id: 121,
		quantity: 290,
	},
	{
		garage_id: 80,
		part_id: 122,
		quantity: 306,
	},
	{
		garage_id: 80,
		part_id: 123,
		quantity: 133,
	},
	{
		garage_id: 80,
		part_id: 124,
		quantity: 100,
	},
	{
		garage_id: 80,
		part_id: 125,
		quantity: 111,
	},
	{
		garage_id: 80,
		part_id: 126,
		quantity: 275,
	},
	{
		garage_id: 80,
		part_id: 127,
		quantity: 184,
	},
	{
		garage_id: 80,
		part_id: 128,
		quantity: 131,
	},
	{
		garage_id: 80,
		part_id: 129,
		quantity: 490,
	},
	{
		garage_id: 80,
		part_id: 130,
		quantity: 182,
	},
	{
		garage_id: 80,
		part_id: 131,
		quantity: 294,
	},
	{
		garage_id: 80,
		part_id: 132,
		quantity: 354,
	},
	{
		garage_id: 80,
		part_id: 133,
		quantity: 323,
	},
	{
		garage_id: 80,
		part_id: 134,
		quantity: 427,
	},
	{
		garage_id: 80,
		part_id: 135,
		quantity: 454,
	},
	{
		garage_id: 80,
		part_id: 136,
		quantity: 348,
	},
	{
		garage_id: 80,
		part_id: 137,
		quantity: 318,
	},
	{
		garage_id: 80,
		part_id: 138,
		quantity: 313,
	},
	{
		garage_id: 80,
		part_id: 139,
		quantity: 313,
	},
	{
		garage_id: 80,
		part_id: 140,
		quantity: 166,
	},
	{
		garage_id: 80,
		part_id: 141,
		quantity: 167,
	},
	{
		garage_id: 80,
		part_id: 142,
		quantity: 366,
	},
	{
		garage_id: 80,
		part_id: 143,
		quantity: 238,
	},
	{
		garage_id: 80,
		part_id: 144,
		quantity: 305,
	},
	{
		garage_id: 80,
		part_id: 145,
		quantity: 217,
	},
	{
		garage_id: 80,
		part_id: 146,
		quantity: 175,
	},
	{
		garage_id: 80,
		part_id: 147,
		quantity: 327,
	},
	{
		garage_id: 80,
		part_id: 148,
		quantity: 425,
	},
	{
		garage_id: 80,
		part_id: 149,
		quantity: 387,
	},
	{
		garage_id: 80,
		part_id: 150,
		quantity: 36,
	},
	{
		garage_id: 80,
		part_id: 151,
		quantity: 151,
	},
	{
		garage_id: 80,
		part_id: 152,
		quantity: 289,
	},
	{
		garage_id: 80,
		part_id: 153,
		quantity: 148,
	},
	{
		garage_id: 80,
		part_id: 154,
		quantity: 360,
	},
	{
		garage_id: 80,
		part_id: 155,
		quantity: 478,
	},
	{
		garage_id: 80,
		part_id: 156,
		quantity: 366,
	},
	{
		garage_id: 80,
		part_id: 157,
		quantity: 395,
	},
	{
		garage_id: 80,
		part_id: 158,
		quantity: 482,
	},
	{
		garage_id: 80,
		part_id: 159,
		quantity: 223,
	},
	{
		garage_id: 80,
		part_id: 160,
		quantity: 388,
	},
	{
		garage_id: 80,
		part_id: 161,
		quantity: 272,
	},
	{
		garage_id: 80,
		part_id: 162,
		quantity: 19,
	},
	{
		garage_id: 80,
		part_id: 163,
		quantity: 425,
	},
	{
		garage_id: 80,
		part_id: 164,
		quantity: 45,
	},
	{
		garage_id: 80,
		part_id: 165,
		quantity: 5,
	},
	{
		garage_id: 80,
		part_id: 166,
		quantity: 135,
	},
	{
		garage_id: 80,
		part_id: 167,
		quantity: 220,
	},
	{
		garage_id: 80,
		part_id: 168,
		quantity: 445,
	},
	{
		garage_id: 80,
		part_id: 169,
		quantity: 229,
	},
	{
		garage_id: 80,
		part_id: 170,
		quantity: 156,
	},
	{
		garage_id: 80,
		part_id: 171,
		quantity: 188,
	},
	{
		garage_id: 80,
		part_id: 172,
		quantity: 239,
	},
	{
		garage_id: 80,
		part_id: 173,
		quantity: 254,
	},
	{
		garage_id: 80,
		part_id: 174,
		quantity: 39,
	},
	{
		garage_id: 80,
		part_id: 175,
		quantity: 308,
	},
	{
		garage_id: 80,
		part_id: 176,
		quantity: 400,
	},
	{
		garage_id: 80,
		part_id: 177,
		quantity: 63,
	},
	{
		garage_id: 80,
		part_id: 178,
		quantity: 333,
	},
	{
		garage_id: 80,
		part_id: 179,
		quantity: 298,
	},
	{
		garage_id: 80,
		part_id: 180,
		quantity: 112,
	},
	{
		garage_id: 80,
		part_id: 181,
		quantity: 107,
	},
	{
		garage_id: 80,
		part_id: 182,
		quantity: 256,
	},
	{
		garage_id: 80,
		part_id: 183,
		quantity: 103,
	},
	{
		garage_id: 80,
		part_id: 184,
		quantity: 490,
	},
	{
		garage_id: 80,
		part_id: 185,
		quantity: 33,
	},
	{
		garage_id: 80,
		part_id: 186,
		quantity: 341,
	},
	{
		garage_id: 80,
		part_id: 187,
		quantity: 272,
	},
	{
		garage_id: 80,
		part_id: 188,
		quantity: 121,
	},
	{
		garage_id: 80,
		part_id: 189,
		quantity: 146,
	},
	{
		garage_id: 80,
		part_id: 190,
		quantity: 425,
	},
	{
		garage_id: 80,
		part_id: 191,
		quantity: 267,
	},
	{
		garage_id: 80,
		part_id: 192,
		quantity: 272,
	},
	{
		garage_id: 80,
		part_id: 193,
		quantity: 149,
	},
	{
		garage_id: 80,
		part_id: 194,
		quantity: 473,
	},
	{
		garage_id: 80,
		part_id: 195,
		quantity: 282,
	},
	{
		garage_id: 80,
		part_id: 196,
		quantity: 194,
	},
	{
		garage_id: 80,
		part_id: 197,
		quantity: 198,
	},
	{
		garage_id: 80,
		part_id: 198,
		quantity: 118,
	},
	{
		garage_id: 80,
		part_id: 199,
		quantity: 409,
	},
	{
		garage_id: 80,
		part_id: 200,
		quantity: 326,
	},
	{
		garage_id: 80,
		part_id: 201,
		quantity: 413,
	},
	{
		garage_id: 80,
		part_id: 202,
		quantity: 355,
	},
	{
		garage_id: 80,
		part_id: 203,
		quantity: 329,
	},
	{
		garage_id: 80,
		part_id: 204,
		quantity: 398,
	},
	{
		garage_id: 80,
		part_id: 205,
		quantity: 407,
	},
	{
		garage_id: 80,
		part_id: 206,
		quantity: 268,
	},
	{
		garage_id: 80,
		part_id: 207,
		quantity: 469,
	},
	{
		garage_id: 80,
		part_id: 208,
		quantity: 287,
	},
	{
		garage_id: 80,
		part_id: 209,
		quantity: 265,
	},
	{
		garage_id: 80,
		part_id: 210,
		quantity: 36,
	},
	{
		garage_id: 80,
		part_id: 211,
		quantity: 163,
	},
	{
		garage_id: 80,
		part_id: 212,
		quantity: 2,
	},
	{
		garage_id: 80,
		part_id: 213,
		quantity: 104,
	},
	{
		garage_id: 80,
		part_id: 214,
		quantity: 232,
	},
	{
		garage_id: 80,
		part_id: 215,
		quantity: 391,
	},
	{
		garage_id: 80,
		part_id: 216,
		quantity: 276,
	},
	{
		garage_id: 80,
		part_id: 217,
		quantity: 170,
	},
	{
		garage_id: 80,
		part_id: 218,
		quantity: 375,
	},
	{
		garage_id: 80,
		part_id: 219,
		quantity: 398,
	},
	{
		garage_id: 80,
		part_id: 220,
		quantity: 280,
	},
	{
		garage_id: 80,
		part_id: 221,
		quantity: 164,
	},
	{
		garage_id: 80,
		part_id: 222,
		quantity: 248,
	},
	{
		garage_id: 80,
		part_id: 223,
		quantity: 119,
	},
	{
		garage_id: 80,
		part_id: 224,
		quantity: 384,
	},
	{
		garage_id: 80,
		part_id: 225,
		quantity: 270,
	},
	{
		garage_id: 80,
		part_id: 226,
		quantity: 465,
	},
	{
		garage_id: 80,
		part_id: 227,
		quantity: 418,
	},
	{
		garage_id: 80,
		part_id: 228,
		quantity: 136,
	},
	{
		garage_id: 80,
		part_id: 229,
		quantity: 484,
	},
	{
		garage_id: 80,
		part_id: 230,
		quantity: 189,
	},
	{
		garage_id: 80,
		part_id: 231,
		quantity: 149,
	},
	{
		garage_id: 80,
		part_id: 232,
		quantity: 108,
	},
	{
		garage_id: 80,
		part_id: 233,
		quantity: 51,
	},
	{
		garage_id: 80,
		part_id: 234,
		quantity: 27,
	},
	{
		garage_id: 80,
		part_id: 235,
		quantity: 186,
	},
	{
		garage_id: 80,
		part_id: 236,
		quantity: 433,
	},
	{
		garage_id: 80,
		part_id: 237,
		quantity: 216,
	},
	{
		garage_id: 80,
		part_id: 238,
		quantity: 494,
	},
	{
		garage_id: 80,
		part_id: 239,
		quantity: 223,
	},
	{
		garage_id: 80,
		part_id: 240,
		quantity: 210,
	},
	{
		garage_id: 80,
		part_id: 241,
		quantity: 81,
	},
	{
		garage_id: 80,
		part_id: 242,
		quantity: 155,
	},
	{
		garage_id: 80,
		part_id: 243,
		quantity: 499,
	},
	{
		garage_id: 80,
		part_id: 244,
		quantity: 392,
	},
	{
		garage_id: 80,
		part_id: 245,
		quantity: 75,
	},
	{
		garage_id: 80,
		part_id: 246,
		quantity: 72,
	},
	{
		garage_id: 80,
		part_id: 247,
		quantity: 492,
	},
	{
		garage_id: 80,
		part_id: 248,
		quantity: 236,
	},
	{
		garage_id: 80,
		part_id: 249,
		quantity: 135,
	},
	{
		garage_id: 80,
		part_id: 250,
		quantity: 34,
	},
	{
		garage_id: 80,
		part_id: 251,
		quantity: 427,
	},
	{
		garage_id: 80,
		part_id: 252,
		quantity: 323,
	},
	{
		garage_id: 80,
		part_id: 253,
		quantity: 250,
	},
	{
		garage_id: 80,
		part_id: 254,
		quantity: 204,
	},
	{
		garage_id: 80,
		part_id: 255,
		quantity: 353,
	},
	{
		garage_id: 80,
		part_id: 256,
		quantity: 92,
	},
	{
		garage_id: 80,
		part_id: 257,
		quantity: 14,
	},
	{
		garage_id: 80,
		part_id: 258,
		quantity: 390,
	},
	{
		garage_id: 80,
		part_id: 259,
		quantity: 277,
	},
	{
		garage_id: 80,
		part_id: 260,
		quantity: 34,
	},
	{
		garage_id: 80,
		part_id: 261,
		quantity: 288,
	},
	{
		garage_id: 80,
		part_id: 262,
		quantity: 361,
	},
	{
		garage_id: 80,
		part_id: 263,
		quantity: 473,
	},
	{
		garage_id: 80,
		part_id: 264,
		quantity: 83,
	},
	{
		garage_id: 80,
		part_id: 265,
		quantity: 11,
	},
	{
		garage_id: 80,
		part_id: 266,
		quantity: 344,
	},
	{
		garage_id: 80,
		part_id: 267,
		quantity: 162,
	},
	{
		garage_id: 80,
		part_id: 268,
		quantity: 254,
	},
	{
		garage_id: 80,
		part_id: 269,
		quantity: 261,
	},
	{
		garage_id: 80,
		part_id: 270,
		quantity: 390,
	},
	{
		garage_id: 80,
		part_id: 271,
		quantity: 185,
	},
	{
		garage_id: 80,
		part_id: 272,
		quantity: 156,
	},
	{
		garage_id: 80,
		part_id: 273,
		quantity: 73,
	},
	{
		garage_id: 80,
		part_id: 274,
		quantity: 87,
	},
	{
		garage_id: 80,
		part_id: 275,
		quantity: 66,
	},
	{
		garage_id: 80,
		part_id: 276,
		quantity: 146,
	},
	{
		garage_id: 80,
		part_id: 277,
		quantity: 442,
	},
	{
		garage_id: 80,
		part_id: 278,
		quantity: 89,
	},
	{
		garage_id: 80,
		part_id: 279,
		quantity: 182,
	},
	{
		garage_id: 80,
		part_id: 280,
		quantity: 11,
	},
	{
		garage_id: 80,
		part_id: 281,
		quantity: 40,
	},
	{
		garage_id: 80,
		part_id: 282,
		quantity: 412,
	},
	{
		garage_id: 80,
		part_id: 283,
		quantity: 333,
	},
	{
		garage_id: 80,
		part_id: 284,
		quantity: 113,
	},
	{
		garage_id: 80,
		part_id: 285,
		quantity: 156,
	},
	{
		garage_id: 80,
		part_id: 286,
		quantity: 317,
	},
	{
		garage_id: 80,
		part_id: 287,
		quantity: 273,
	},
	{
		garage_id: 80,
		part_id: 288,
		quantity: 244,
	},
	{
		garage_id: 80,
		part_id: 289,
		quantity: 208,
	},
	{
		garage_id: 80,
		part_id: 290,
		quantity: 478,
	},
	{
		garage_id: 80,
		part_id: 291,
		quantity: 353,
	},
	{
		garage_id: 80,
		part_id: 292,
		quantity: 357,
	},
	{
		garage_id: 80,
		part_id: 293,
		quantity: 108,
	},
	{
		garage_id: 80,
		part_id: 294,
		quantity: 81,
	},
	{
		garage_id: 80,
		part_id: 295,
		quantity: 70,
	},
	{
		garage_id: 80,
		part_id: 296,
		quantity: 277,
	},
	{
		garage_id: 80,
		part_id: 297,
		quantity: 194,
	},
	{
		garage_id: 80,
		part_id: 298,
		quantity: 399,
	},
	{
		garage_id: 80,
		part_id: 299,
		quantity: 174,
	},
	{
		garage_id: 80,
		part_id: 300,
		quantity: 64,
	},
	{
		garage_id: 80,
		part_id: 301,
		quantity: 20,
	},
	{
		garage_id: 80,
		part_id: 302,
		quantity: 410,
	},
	{
		garage_id: 80,
		part_id: 303,
		quantity: 157,
	},
	{
		garage_id: 80,
		part_id: 304,
		quantity: 457,
	},
	{
		garage_id: 80,
		part_id: 305,
		quantity: 59,
	},
	{
		garage_id: 80,
		part_id: 306,
		quantity: 124,
	},
	{
		garage_id: 80,
		part_id: 307,
		quantity: 442,
	},
	{
		garage_id: 80,
		part_id: 308,
		quantity: 389,
	},
	{
		garage_id: 80,
		part_id: 309,
		quantity: 398,
	},
	{
		garage_id: 80,
		part_id: 310,
		quantity: 329,
	},
	{
		garage_id: 80,
		part_id: 311,
		quantity: 454,
	},
	{
		garage_id: 80,
		part_id: 312,
		quantity: 276,
	},
	{
		garage_id: 80,
		part_id: 313,
		quantity: 255,
	},
	{
		garage_id: 80,
		part_id: 314,
		quantity: 458,
	},
	{
		garage_id: 80,
		part_id: 315,
		quantity: 424,
	},
	{
		garage_id: 80,
		part_id: 316,
		quantity: 227,
	},
	{
		garage_id: 80,
		part_id: 317,
		quantity: 296,
	},
	{
		garage_id: 80,
		part_id: 318,
		quantity: 400,
	},
	{
		garage_id: 80,
		part_id: 319,
		quantity: 170,
	},
	{
		garage_id: 80,
		part_id: 320,
		quantity: 25,
	},
	{
		garage_id: 80,
		part_id: 321,
		quantity: 127,
	},
	{
		garage_id: 80,
		part_id: 322,
		quantity: 332,
	},
	{
		garage_id: 80,
		part_id: 323,
		quantity: 4,
	},
	{
		garage_id: 80,
		part_id: 324,
		quantity: 453,
	},
	{
		garage_id: 80,
		part_id: 325,
		quantity: 443,
	},
	{
		garage_id: 80,
		part_id: 326,
		quantity: 165,
	},
	{
		garage_id: 80,
		part_id: 327,
		quantity: 332,
	},
	{
		garage_id: 80,
		part_id: 328,
		quantity: 367,
	},
	{
		garage_id: 80,
		part_id: 329,
		quantity: 60,
	},
	{
		garage_id: 80,
		part_id: 330,
		quantity: 499,
	},
	{
		garage_id: 80,
		part_id: 331,
		quantity: 480,
	},
	{
		garage_id: 80,
		part_id: 332,
		quantity: 12,
	},
	{
		garage_id: 80,
		part_id: 333,
		quantity: 162,
	},
	{
		garage_id: 80,
		part_id: 334,
		quantity: 19,
	},
	{
		garage_id: 80,
		part_id: 335,
		quantity: 302,
	},
	{
		garage_id: 80,
		part_id: 336,
		quantity: 158,
	},
	{
		garage_id: 80,
		part_id: 337,
		quantity: 406,
	},
	{
		garage_id: 80,
		part_id: 338,
		quantity: 351,
	},
	{
		garage_id: 80,
		part_id: 339,
		quantity: 289,
	},
	{
		garage_id: 80,
		part_id: 340,
		quantity: 247,
	},
	{
		garage_id: 80,
		part_id: 341,
		quantity: 225,
	},
	{
		garage_id: 80,
		part_id: 342,
		quantity: 79,
	},
	{
		garage_id: 80,
		part_id: 343,
		quantity: 438,
	},
	{
		garage_id: 80,
		part_id: 344,
		quantity: 191,
	},
	{
		garage_id: 80,
		part_id: 345,
		quantity: 155,
	},
	{
		garage_id: 80,
		part_id: 346,
		quantity: 366,
	},
	{
		garage_id: 80,
		part_id: 347,
		quantity: 28,
	},
	{
		garage_id: 80,
		part_id: 348,
		quantity: 73,
	},
	{
		garage_id: 80,
		part_id: 349,
		quantity: 369,
	},
	{
		garage_id: 80,
		part_id: 350,
		quantity: 436,
	},
	{
		garage_id: 80,
		part_id: 351,
		quantity: 464,
	},
	{
		garage_id: 80,
		part_id: 352,
		quantity: 472,
	},
	{
		garage_id: 80,
		part_id: 353,
		quantity: 301,
	},
	{
		garage_id: 80,
		part_id: 354,
		quantity: 453,
	},
	{
		garage_id: 80,
		part_id: 355,
		quantity: 312,
	},
	{
		garage_id: 80,
		part_id: 356,
		quantity: 275,
	},
	{
		garage_id: 80,
		part_id: 357,
		quantity: 494,
	},
	{
		garage_id: 80,
		part_id: 358,
		quantity: 86,
	},
	{
		garage_id: 80,
		part_id: 359,
		quantity: 315,
	},
	{
		garage_id: 80,
		part_id: 360,
		quantity: 123,
	},
	{
		garage_id: 80,
		part_id: 361,
		quantity: 265,
	},
	{
		garage_id: 80,
		part_id: 362,
		quantity: 350,
	},
	{
		garage_id: 80,
		part_id: 363,
		quantity: 494,
	},
	{
		garage_id: 80,
		part_id: 364,
		quantity: 87,
	},
	{
		garage_id: 80,
		part_id: 365,
		quantity: 412,
	},
	{
		garage_id: 80,
		part_id: 366,
		quantity: 125,
	},
	{
		garage_id: 80,
		part_id: 367,
		quantity: 86,
	},
	{
		garage_id: 80,
		part_id: 368,
		quantity: 19,
	},
	{
		garage_id: 80,
		part_id: 369,
		quantity: 146,
	},
	{
		garage_id: 80,
		part_id: 370,
		quantity: 487,
	},
	{
		garage_id: 80,
		part_id: 371,
		quantity: 57,
	},
	{
		garage_id: 80,
		part_id: 372,
		quantity: 491,
	},
	{
		garage_id: 80,
		part_id: 373,
		quantity: 144,
	},
	{
		garage_id: 80,
		part_id: 374,
		quantity: 494,
	},
	{
		garage_id: 80,
		part_id: 375,
		quantity: 174,
	},
	{
		garage_id: 80,
		part_id: 376,
		quantity: 311,
	},
	{
		garage_id: 80,
		part_id: 377,
		quantity: 50,
	},
	{
		garage_id: 80,
		part_id: 378,
		quantity: 11,
	},
	{
		garage_id: 80,
		part_id: 379,
		quantity: 70,
	},
	{
		garage_id: 80,
		part_id: 380,
		quantity: 400,
	},
	{
		garage_id: 80,
		part_id: 381,
		quantity: 229,
	},
	{
		garage_id: 80,
		part_id: 382,
		quantity: 134,
	},
	{
		garage_id: 80,
		part_id: 383,
		quantity: 275,
	},
	{
		garage_id: 80,
		part_id: 384,
		quantity: 322,
	},
	{
		garage_id: 80,
		part_id: 385,
		quantity: 346,
	},
	{
		garage_id: 80,
		part_id: 386,
		quantity: 316,
	},
	{
		garage_id: 80,
		part_id: 387,
		quantity: 247,
	},
	{
		garage_id: 80,
		part_id: 388,
		quantity: 188,
	},
	{
		garage_id: 80,
		part_id: 389,
		quantity: 64,
	},
	{
		garage_id: 80,
		part_id: 390,
		quantity: 417,
	},
	{
		garage_id: 80,
		part_id: 391,
		quantity: 158,
	},
	{
		garage_id: 80,
		part_id: 392,
		quantity: 459,
	},
	{
		garage_id: 80,
		part_id: 393,
		quantity: 107,
	},
	{
		garage_id: 80,
		part_id: 394,
		quantity: 214,
	},
	{
		garage_id: 80,
		part_id: 395,
		quantity: 439,
	},
	{
		garage_id: 80,
		part_id: 396,
		quantity: 48,
	},
	{
		garage_id: 80,
		part_id: 397,
		quantity: 484,
	},
	{
		garage_id: 80,
		part_id: 398,
		quantity: 193,
	},
	{
		garage_id: 80,
		part_id: 399,
		quantity: 313,
	},
	{
		garage_id: 81,
		part_id: 0,
		quantity: 195,
	},
	{
		garage_id: 81,
		part_id: 1,
		quantity: 222,
	},
	{
		garage_id: 81,
		part_id: 2,
		quantity: 64,
	},
	{
		garage_id: 81,
		part_id: 3,
		quantity: 272,
	},
	{
		garage_id: 81,
		part_id: 4,
		quantity: 316,
	},
	{
		garage_id: 81,
		part_id: 5,
		quantity: 449,
	},
	{
		garage_id: 81,
		part_id: 6,
		quantity: 436,
	},
	{
		garage_id: 81,
		part_id: 7,
		quantity: 13,
	},
	{
		garage_id: 81,
		part_id: 8,
		quantity: 208,
	},
	{
		garage_id: 81,
		part_id: 9,
		quantity: 304,
	},
	{
		garage_id: 81,
		part_id: 10,
		quantity: 220,
	},
	{
		garage_id: 81,
		part_id: 11,
		quantity: 257,
	},
	{
		garage_id: 81,
		part_id: 12,
		quantity: 435,
	},
	{
		garage_id: 81,
		part_id: 13,
		quantity: 118,
	},
	{
		garage_id: 81,
		part_id: 14,
		quantity: 43,
	},
	{
		garage_id: 81,
		part_id: 15,
		quantity: 125,
	},
	{
		garage_id: 81,
		part_id: 16,
		quantity: 459,
	},
	{
		garage_id: 81,
		part_id: 17,
		quantity: 13,
	},
	{
		garage_id: 81,
		part_id: 18,
		quantity: 337,
	},
	{
		garage_id: 81,
		part_id: 19,
		quantity: 299,
	},
	{
		garage_id: 81,
		part_id: 20,
		quantity: 294,
	},
	{
		garage_id: 81,
		part_id: 21,
		quantity: 268,
	},
	{
		garage_id: 81,
		part_id: 22,
		quantity: 488,
	},
	{
		garage_id: 81,
		part_id: 23,
		quantity: 226,
	},
	{
		garage_id: 81,
		part_id: 24,
		quantity: 273,
	},
	{
		garage_id: 81,
		part_id: 25,
		quantity: 491,
	},
	{
		garage_id: 81,
		part_id: 26,
		quantity: 460,
	},
	{
		garage_id: 81,
		part_id: 27,
		quantity: 212,
	},
	{
		garage_id: 81,
		part_id: 28,
		quantity: 425,
	},
	{
		garage_id: 81,
		part_id: 29,
		quantity: 69,
	},
	{
		garage_id: 81,
		part_id: 30,
		quantity: 359,
	},
	{
		garage_id: 81,
		part_id: 31,
		quantity: 228,
	},
	{
		garage_id: 81,
		part_id: 32,
		quantity: 429,
	},
	{
		garage_id: 81,
		part_id: 33,
		quantity: 352,
	},
	{
		garage_id: 81,
		part_id: 34,
		quantity: 305,
	},
	{
		garage_id: 81,
		part_id: 35,
		quantity: 66,
	},
	{
		garage_id: 81,
		part_id: 36,
		quantity: 476,
	},
	{
		garage_id: 81,
		part_id: 37,
		quantity: 470,
	},
	{
		garage_id: 81,
		part_id: 38,
		quantity: 80,
	},
	{
		garage_id: 81,
		part_id: 39,
		quantity: 411,
	},
	{
		garage_id: 81,
		part_id: 40,
		quantity: 32,
	},
	{
		garage_id: 81,
		part_id: 41,
		quantity: 97,
	},
	{
		garage_id: 81,
		part_id: 42,
		quantity: 15,
	},
	{
		garage_id: 81,
		part_id: 43,
		quantity: 454,
	},
	{
		garage_id: 81,
		part_id: 44,
		quantity: 37,
	},
	{
		garage_id: 81,
		part_id: 45,
		quantity: 411,
	},
	{
		garage_id: 81,
		part_id: 46,
		quantity: 404,
	},
	{
		garage_id: 81,
		part_id: 47,
		quantity: 491,
	},
	{
		garage_id: 81,
		part_id: 48,
		quantity: 241,
	},
	{
		garage_id: 81,
		part_id: 49,
		quantity: 90,
	},
	{
		garage_id: 81,
		part_id: 50,
		quantity: 109,
	},
	{
		garage_id: 81,
		part_id: 51,
		quantity: 241,
	},
	{
		garage_id: 81,
		part_id: 52,
		quantity: 330,
	},
	{
		garage_id: 81,
		part_id: 53,
		quantity: 120,
	},
	{
		garage_id: 81,
		part_id: 54,
		quantity: 454,
	},
	{
		garage_id: 81,
		part_id: 55,
		quantity: 312,
	},
	{
		garage_id: 81,
		part_id: 56,
		quantity: 140,
	},
	{
		garage_id: 81,
		part_id: 57,
		quantity: 116,
	},
	{
		garage_id: 81,
		part_id: 58,
		quantity: 478,
	},
	{
		garage_id: 81,
		part_id: 59,
		quantity: 148,
	},
	{
		garage_id: 81,
		part_id: 60,
		quantity: 322,
	},
	{
		garage_id: 81,
		part_id: 61,
		quantity: 85,
	},
	{
		garage_id: 81,
		part_id: 62,
		quantity: 52,
	},
	{
		garage_id: 81,
		part_id: 63,
		quantity: 373,
	},
	{
		garage_id: 81,
		part_id: 64,
		quantity: 84,
	},
	{
		garage_id: 81,
		part_id: 65,
		quantity: 31,
	},
	{
		garage_id: 81,
		part_id: 66,
		quantity: 218,
	},
	{
		garage_id: 81,
		part_id: 67,
		quantity: 459,
	},
	{
		garage_id: 81,
		part_id: 68,
		quantity: 486,
	},
	{
		garage_id: 81,
		part_id: 69,
		quantity: 80,
	},
	{
		garage_id: 81,
		part_id: 70,
		quantity: 455,
	},
	{
		garage_id: 81,
		part_id: 71,
		quantity: 327,
	},
	{
		garage_id: 81,
		part_id: 72,
		quantity: 163,
	},
	{
		garage_id: 81,
		part_id: 73,
		quantity: 435,
	},
	{
		garage_id: 81,
		part_id: 74,
		quantity: 393,
	},
	{
		garage_id: 81,
		part_id: 75,
		quantity: 179,
	},
	{
		garage_id: 81,
		part_id: 76,
		quantity: 89,
	},
	{
		garage_id: 81,
		part_id: 77,
		quantity: 259,
	},
	{
		garage_id: 81,
		part_id: 78,
		quantity: 384,
	},
	{
		garage_id: 81,
		part_id: 79,
		quantity: 10,
	},
	{
		garage_id: 81,
		part_id: 80,
		quantity: 53,
	},
	{
		garage_id: 81,
		part_id: 81,
		quantity: 399,
	},
	{
		garage_id: 81,
		part_id: 82,
		quantity: 410,
	},
	{
		garage_id: 81,
		part_id: 83,
		quantity: 281,
	},
	{
		garage_id: 81,
		part_id: 84,
		quantity: 94,
	},
	{
		garage_id: 81,
		part_id: 85,
		quantity: 65,
	},
	{
		garage_id: 81,
		part_id: 86,
		quantity: 234,
	},
	{
		garage_id: 81,
		part_id: 87,
		quantity: 41,
	},
	{
		garage_id: 81,
		part_id: 88,
		quantity: 358,
	},
	{
		garage_id: 81,
		part_id: 89,
		quantity: 31,
	},
	{
		garage_id: 81,
		part_id: 90,
		quantity: 299,
	},
	{
		garage_id: 81,
		part_id: 91,
		quantity: 356,
	},
	{
		garage_id: 81,
		part_id: 92,
		quantity: 11,
	},
	{
		garage_id: 81,
		part_id: 93,
		quantity: 394,
	},
	{
		garage_id: 81,
		part_id: 94,
		quantity: 161,
	},
	{
		garage_id: 81,
		part_id: 95,
		quantity: 379,
	},
	{
		garage_id: 81,
		part_id: 96,
		quantity: 172,
	},
	{
		garage_id: 81,
		part_id: 97,
		quantity: 359,
	},
	{
		garage_id: 81,
		part_id: 98,
		quantity: 136,
	},
	{
		garage_id: 81,
		part_id: 99,
		quantity: 145,
	},
	{
		garage_id: 81,
		part_id: 100,
		quantity: 341,
	},
	{
		garage_id: 81,
		part_id: 101,
		quantity: 401,
	},
	{
		garage_id: 81,
		part_id: 102,
		quantity: 301,
	},
	{
		garage_id: 81,
		part_id: 103,
		quantity: 62,
	},
	{
		garage_id: 81,
		part_id: 104,
		quantity: 136,
	},
	{
		garage_id: 81,
		part_id: 105,
		quantity: 56,
	},
	{
		garage_id: 81,
		part_id: 106,
		quantity: 96,
	},
	{
		garage_id: 81,
		part_id: 107,
		quantity: 359,
	},
	{
		garage_id: 81,
		part_id: 108,
		quantity: 242,
	},
	{
		garage_id: 81,
		part_id: 109,
		quantity: 288,
	},
	{
		garage_id: 81,
		part_id: 110,
		quantity: 265,
	},
	{
		garage_id: 81,
		part_id: 111,
		quantity: 115,
	},
	{
		garage_id: 81,
		part_id: 112,
		quantity: 234,
	},
	{
		garage_id: 81,
		part_id: 113,
		quantity: 157,
	},
	{
		garage_id: 81,
		part_id: 114,
		quantity: 68,
	},
	{
		garage_id: 81,
		part_id: 115,
		quantity: 233,
	},
	{
		garage_id: 81,
		part_id: 116,
		quantity: 319,
	},
	{
		garage_id: 81,
		part_id: 117,
		quantity: 486,
	},
	{
		garage_id: 81,
		part_id: 118,
		quantity: 56,
	},
	{
		garage_id: 81,
		part_id: 119,
		quantity: 168,
	},
	{
		garage_id: 81,
		part_id: 120,
		quantity: 113,
	},
	{
		garage_id: 81,
		part_id: 121,
		quantity: 373,
	},
	{
		garage_id: 81,
		part_id: 122,
		quantity: 112,
	},
	{
		garage_id: 81,
		part_id: 123,
		quantity: 404,
	},
	{
		garage_id: 81,
		part_id: 124,
		quantity: 163,
	},
	{
		garage_id: 81,
		part_id: 125,
		quantity: 125,
	},
	{
		garage_id: 81,
		part_id: 126,
		quantity: 477,
	},
	{
		garage_id: 81,
		part_id: 127,
		quantity: 482,
	},
	{
		garage_id: 81,
		part_id: 128,
		quantity: 31,
	},
	{
		garage_id: 81,
		part_id: 129,
		quantity: 110,
	},
	{
		garage_id: 81,
		part_id: 130,
		quantity: 110,
	},
	{
		garage_id: 81,
		part_id: 131,
		quantity: 133,
	},
	{
		garage_id: 81,
		part_id: 132,
		quantity: 312,
	},
	{
		garage_id: 81,
		part_id: 133,
		quantity: 296,
	},
	{
		garage_id: 81,
		part_id: 134,
		quantity: 217,
	},
	{
		garage_id: 81,
		part_id: 135,
		quantity: 400,
	},
	{
		garage_id: 81,
		part_id: 136,
		quantity: 223,
	},
	{
		garage_id: 81,
		part_id: 137,
		quantity: 471,
	},
	{
		garage_id: 81,
		part_id: 138,
		quantity: 8,
	},
	{
		garage_id: 81,
		part_id: 139,
		quantity: 363,
	},
	{
		garage_id: 81,
		part_id: 140,
		quantity: 278,
	},
	{
		garage_id: 81,
		part_id: 141,
		quantity: 249,
	},
	{
		garage_id: 81,
		part_id: 142,
		quantity: 189,
	},
	{
		garage_id: 81,
		part_id: 143,
		quantity: 204,
	},
	{
		garage_id: 81,
		part_id: 144,
		quantity: 199,
	},
	{
		garage_id: 81,
		part_id: 145,
		quantity: 349,
	},
	{
		garage_id: 81,
		part_id: 146,
		quantity: 91,
	},
	{
		garage_id: 81,
		part_id: 147,
		quantity: 171,
	},
	{
		garage_id: 81,
		part_id: 148,
		quantity: 499,
	},
	{
		garage_id: 81,
		part_id: 149,
		quantity: 179,
	},
	{
		garage_id: 81,
		part_id: 150,
		quantity: 221,
	},
	{
		garage_id: 81,
		part_id: 151,
		quantity: 478,
	},
	{
		garage_id: 81,
		part_id: 152,
		quantity: 33,
	},
	{
		garage_id: 81,
		part_id: 153,
		quantity: 263,
	},
	{
		garage_id: 81,
		part_id: 154,
		quantity: 7,
	},
	{
		garage_id: 81,
		part_id: 155,
		quantity: 69,
	},
	{
		garage_id: 81,
		part_id: 156,
		quantity: 52,
	},
	{
		garage_id: 81,
		part_id: 157,
		quantity: 165,
	},
	{
		garage_id: 81,
		part_id: 158,
		quantity: 282,
	},
	{
		garage_id: 81,
		part_id: 159,
		quantity: 407,
	},
	{
		garage_id: 81,
		part_id: 160,
		quantity: 344,
	},
	{
		garage_id: 81,
		part_id: 161,
		quantity: 431,
	},
	{
		garage_id: 81,
		part_id: 162,
		quantity: 118,
	},
	{
		garage_id: 81,
		part_id: 163,
		quantity: 491,
	},
	{
		garage_id: 81,
		part_id: 164,
		quantity: 311,
	},
	{
		garage_id: 81,
		part_id: 165,
		quantity: 31,
	},
	{
		garage_id: 81,
		part_id: 166,
		quantity: 136,
	},
	{
		garage_id: 81,
		part_id: 167,
		quantity: 472,
	},
	{
		garage_id: 81,
		part_id: 168,
		quantity: 161,
	},
	{
		garage_id: 81,
		part_id: 169,
		quantity: 451,
	},
	{
		garage_id: 81,
		part_id: 170,
		quantity: 388,
	},
	{
		garage_id: 81,
		part_id: 171,
		quantity: 304,
	},
	{
		garage_id: 81,
		part_id: 172,
		quantity: 365,
	},
	{
		garage_id: 81,
		part_id: 173,
		quantity: 309,
	},
	{
		garage_id: 81,
		part_id: 174,
		quantity: 79,
	},
	{
		garage_id: 81,
		part_id: 175,
		quantity: 373,
	},
	{
		garage_id: 81,
		part_id: 176,
		quantity: 244,
	},
	{
		garage_id: 81,
		part_id: 177,
		quantity: 258,
	},
	{
		garage_id: 81,
		part_id: 178,
		quantity: 314,
	},
	{
		garage_id: 81,
		part_id: 179,
		quantity: 126,
	},
	{
		garage_id: 81,
		part_id: 180,
		quantity: 482,
	},
	{
		garage_id: 81,
		part_id: 181,
		quantity: 346,
	},
	{
		garage_id: 81,
		part_id: 182,
		quantity: 339,
	},
	{
		garage_id: 81,
		part_id: 183,
		quantity: 446,
	},
	{
		garage_id: 81,
		part_id: 184,
		quantity: 453,
	},
	{
		garage_id: 81,
		part_id: 185,
		quantity: 342,
	},
	{
		garage_id: 81,
		part_id: 186,
		quantity: 276,
	},
	{
		garage_id: 81,
		part_id: 187,
		quantity: 104,
	},
	{
		garage_id: 81,
		part_id: 188,
		quantity: 62,
	},
	{
		garage_id: 81,
		part_id: 189,
		quantity: 388,
	},
	{
		garage_id: 81,
		part_id: 190,
		quantity: 205,
	},
	{
		garage_id: 81,
		part_id: 191,
		quantity: 432,
	},
	{
		garage_id: 81,
		part_id: 192,
		quantity: 4,
	},
	{
		garage_id: 81,
		part_id: 193,
		quantity: 87,
	},
	{
		garage_id: 81,
		part_id: 194,
		quantity: 440,
	},
	{
		garage_id: 81,
		part_id: 195,
		quantity: 335,
	},
	{
		garage_id: 81,
		part_id: 196,
		quantity: 499,
	},
	{
		garage_id: 81,
		part_id: 197,
		quantity: 147,
	},
	{
		garage_id: 81,
		part_id: 198,
		quantity: 391,
	},
	{
		garage_id: 81,
		part_id: 199,
		quantity: 471,
	},
	{
		garage_id: 81,
		part_id: 200,
		quantity: 443,
	},
	{
		garage_id: 81,
		part_id: 201,
		quantity: 429,
	},
	{
		garage_id: 81,
		part_id: 202,
		quantity: 437,
	},
	{
		garage_id: 81,
		part_id: 203,
		quantity: 378,
	},
	{
		garage_id: 81,
		part_id: 204,
		quantity: 135,
	},
	{
		garage_id: 81,
		part_id: 205,
		quantity: 271,
	},
	{
		garage_id: 81,
		part_id: 206,
		quantity: 248,
	},
	{
		garage_id: 81,
		part_id: 207,
		quantity: 140,
	},
	{
		garage_id: 81,
		part_id: 208,
		quantity: 44,
	},
	{
		garage_id: 81,
		part_id: 209,
		quantity: 418,
	},
	{
		garage_id: 81,
		part_id: 210,
		quantity: 39,
	},
	{
		garage_id: 81,
		part_id: 211,
		quantity: 33,
	},
	{
		garage_id: 81,
		part_id: 212,
		quantity: 208,
	},
	{
		garage_id: 81,
		part_id: 213,
		quantity: 362,
	},
	{
		garage_id: 81,
		part_id: 214,
		quantity: 250,
	},
	{
		garage_id: 81,
		part_id: 215,
		quantity: 299,
	},
	{
		garage_id: 81,
		part_id: 216,
		quantity: 358,
	},
	{
		garage_id: 81,
		part_id: 217,
		quantity: 412,
	},
	{
		garage_id: 81,
		part_id: 218,
		quantity: 202,
	},
	{
		garage_id: 81,
		part_id: 219,
		quantity: 181,
	},
	{
		garage_id: 81,
		part_id: 220,
		quantity: 86,
	},
	{
		garage_id: 81,
		part_id: 221,
		quantity: 381,
	},
	{
		garage_id: 81,
		part_id: 222,
		quantity: 157,
	},
	{
		garage_id: 81,
		part_id: 223,
		quantity: 491,
	},
	{
		garage_id: 81,
		part_id: 224,
		quantity: 488,
	},
	{
		garage_id: 81,
		part_id: 225,
		quantity: 103,
	},
	{
		garage_id: 81,
		part_id: 226,
		quantity: 358,
	},
	{
		garage_id: 81,
		part_id: 227,
		quantity: 94,
	},
	{
		garage_id: 81,
		part_id: 228,
		quantity: 192,
	},
	{
		garage_id: 81,
		part_id: 229,
		quantity: 164,
	},
	{
		garage_id: 81,
		part_id: 230,
		quantity: 433,
	},
	{
		garage_id: 81,
		part_id: 231,
		quantity: 136,
	},
	{
		garage_id: 81,
		part_id: 232,
		quantity: 358,
	},
	{
		garage_id: 81,
		part_id: 233,
		quantity: 378,
	},
	{
		garage_id: 81,
		part_id: 234,
		quantity: 350,
	},
	{
		garage_id: 81,
		part_id: 235,
		quantity: 107,
	},
	{
		garage_id: 81,
		part_id: 236,
		quantity: 148,
	},
	{
		garage_id: 81,
		part_id: 237,
		quantity: 486,
	},
	{
		garage_id: 81,
		part_id: 238,
		quantity: 409,
	},
	{
		garage_id: 81,
		part_id: 239,
		quantity: 213,
	},
	{
		garage_id: 81,
		part_id: 240,
		quantity: 224,
	},
	{
		garage_id: 81,
		part_id: 241,
		quantity: 157,
	},
	{
		garage_id: 81,
		part_id: 242,
		quantity: 14,
	},
	{
		garage_id: 81,
		part_id: 243,
		quantity: 440,
	},
	{
		garage_id: 81,
		part_id: 244,
		quantity: 41,
	},
	{
		garage_id: 81,
		part_id: 245,
		quantity: 109,
	},
	{
		garage_id: 81,
		part_id: 246,
		quantity: 140,
	},
	{
		garage_id: 81,
		part_id: 247,
		quantity: 88,
	},
	{
		garage_id: 81,
		part_id: 248,
		quantity: 287,
	},
	{
		garage_id: 81,
		part_id: 249,
		quantity: 407,
	},
	{
		garage_id: 81,
		part_id: 250,
		quantity: 254,
	},
	{
		garage_id: 81,
		part_id: 251,
		quantity: 436,
	},
	{
		garage_id: 81,
		part_id: 252,
		quantity: 88,
	},
	{
		garage_id: 81,
		part_id: 253,
		quantity: 368,
	},
	{
		garage_id: 81,
		part_id: 254,
		quantity: 91,
	},
	{
		garage_id: 81,
		part_id: 255,
		quantity: 54,
	},
	{
		garage_id: 81,
		part_id: 256,
		quantity: 94,
	},
	{
		garage_id: 81,
		part_id: 257,
		quantity: 406,
	},
	{
		garage_id: 81,
		part_id: 258,
		quantity: 87,
	},
	{
		garage_id: 81,
		part_id: 259,
		quantity: 214,
	},
	{
		garage_id: 81,
		part_id: 260,
		quantity: 33,
	},
	{
		garage_id: 81,
		part_id: 261,
		quantity: 50,
	},
	{
		garage_id: 81,
		part_id: 262,
		quantity: 67,
	},
	{
		garage_id: 81,
		part_id: 263,
		quantity: 126,
	},
	{
		garage_id: 81,
		part_id: 264,
		quantity: 259,
	},
	{
		garage_id: 81,
		part_id: 265,
		quantity: 138,
	},
	{
		garage_id: 81,
		part_id: 266,
		quantity: 346,
	},
	{
		garage_id: 81,
		part_id: 267,
		quantity: 233,
	},
	{
		garage_id: 81,
		part_id: 268,
		quantity: 483,
	},
	{
		garage_id: 81,
		part_id: 269,
		quantity: 214,
	},
	{
		garage_id: 81,
		part_id: 270,
		quantity: 470,
	},
	{
		garage_id: 81,
		part_id: 271,
		quantity: 313,
	},
	{
		garage_id: 81,
		part_id: 272,
		quantity: 304,
	},
	{
		garage_id: 81,
		part_id: 273,
		quantity: 351,
	},
	{
		garage_id: 81,
		part_id: 274,
		quantity: 302,
	},
	{
		garage_id: 81,
		part_id: 275,
		quantity: 14,
	},
	{
		garage_id: 81,
		part_id: 276,
		quantity: 40,
	},
	{
		garage_id: 81,
		part_id: 277,
		quantity: 113,
	},
	{
		garage_id: 81,
		part_id: 278,
		quantity: 341,
	},
	{
		garage_id: 81,
		part_id: 279,
		quantity: 249,
	},
	{
		garage_id: 81,
		part_id: 280,
		quantity: 129,
	},
	{
		garage_id: 81,
		part_id: 281,
		quantity: 230,
	},
	{
		garage_id: 81,
		part_id: 282,
		quantity: 297,
	},
	{
		garage_id: 81,
		part_id: 283,
		quantity: 126,
	},
	{
		garage_id: 81,
		part_id: 284,
		quantity: 88,
	},
	{
		garage_id: 81,
		part_id: 285,
		quantity: 135,
	},
	{
		garage_id: 81,
		part_id: 286,
		quantity: 106,
	},
	{
		garage_id: 81,
		part_id: 287,
		quantity: 432,
	},
	{
		garage_id: 81,
		part_id: 288,
		quantity: 167,
	},
	{
		garage_id: 81,
		part_id: 289,
		quantity: 89,
	},
	{
		garage_id: 81,
		part_id: 290,
		quantity: 359,
	},
	{
		garage_id: 81,
		part_id: 291,
		quantity: 354,
	},
	{
		garage_id: 81,
		part_id: 292,
		quantity: 363,
	},
	{
		garage_id: 81,
		part_id: 293,
		quantity: 117,
	},
	{
		garage_id: 81,
		part_id: 294,
		quantity: 338,
	},
	{
		garage_id: 81,
		part_id: 295,
		quantity: 141,
	},
	{
		garage_id: 81,
		part_id: 296,
		quantity: 378,
	},
	{
		garage_id: 81,
		part_id: 297,
		quantity: 491,
	},
	{
		garage_id: 81,
		part_id: 298,
		quantity: 278,
	},
	{
		garage_id: 81,
		part_id: 299,
		quantity: 203,
	},
	{
		garage_id: 81,
		part_id: 300,
		quantity: 94,
	},
	{
		garage_id: 81,
		part_id: 301,
		quantity: 481,
	},
	{
		garage_id: 81,
		part_id: 302,
		quantity: 325,
	},
	{
		garage_id: 81,
		part_id: 303,
		quantity: 315,
	},
	{
		garage_id: 81,
		part_id: 304,
		quantity: 394,
	},
	{
		garage_id: 81,
		part_id: 305,
		quantity: 380,
	},
	{
		garage_id: 81,
		part_id: 306,
		quantity: 239,
	},
	{
		garage_id: 81,
		part_id: 307,
		quantity: 249,
	},
	{
		garage_id: 81,
		part_id: 308,
		quantity: 97,
	},
	{
		garage_id: 81,
		part_id: 309,
		quantity: 464,
	},
	{
		garage_id: 81,
		part_id: 310,
		quantity: 369,
	},
	{
		garage_id: 81,
		part_id: 311,
		quantity: 141,
	},
	{
		garage_id: 81,
		part_id: 312,
		quantity: 229,
	},
	{
		garage_id: 81,
		part_id: 313,
		quantity: 404,
	},
	{
		garage_id: 81,
		part_id: 314,
		quantity: 149,
	},
	{
		garage_id: 81,
		part_id: 315,
		quantity: 302,
	},
	{
		garage_id: 81,
		part_id: 316,
		quantity: 384,
	},
	{
		garage_id: 81,
		part_id: 317,
		quantity: 84,
	},
	{
		garage_id: 81,
		part_id: 318,
		quantity: 265,
	},
	{
		garage_id: 81,
		part_id: 319,
		quantity: 185,
	},
	{
		garage_id: 81,
		part_id: 320,
		quantity: 1,
	},
	{
		garage_id: 81,
		part_id: 321,
		quantity: 212,
	},
	{
		garage_id: 81,
		part_id: 322,
		quantity: 105,
	},
	{
		garage_id: 81,
		part_id: 323,
		quantity: 219,
	},
	{
		garage_id: 81,
		part_id: 324,
		quantity: 4,
	},
	{
		garage_id: 81,
		part_id: 325,
		quantity: 421,
	},
	{
		garage_id: 81,
		part_id: 326,
		quantity: 195,
	},
	{
		garage_id: 81,
		part_id: 327,
		quantity: 412,
	},
	{
		garage_id: 81,
		part_id: 328,
		quantity: 268,
	},
	{
		garage_id: 81,
		part_id: 329,
		quantity: 446,
	},
	{
		garage_id: 81,
		part_id: 330,
		quantity: 122,
	},
	{
		garage_id: 81,
		part_id: 331,
		quantity: 286,
	},
	{
		garage_id: 81,
		part_id: 332,
		quantity: 85,
	},
	{
		garage_id: 81,
		part_id: 333,
		quantity: 420,
	},
	{
		garage_id: 81,
		part_id: 334,
		quantity: 27,
	},
	{
		garage_id: 81,
		part_id: 335,
		quantity: 169,
	},
	{
		garage_id: 81,
		part_id: 336,
		quantity: 438,
	},
	{
		garage_id: 81,
		part_id: 337,
		quantity: 109,
	},
	{
		garage_id: 81,
		part_id: 338,
		quantity: 93,
	},
	{
		garage_id: 81,
		part_id: 339,
		quantity: 75,
	},
	{
		garage_id: 81,
		part_id: 340,
		quantity: 223,
	},
	{
		garage_id: 81,
		part_id: 341,
		quantity: 182,
	},
	{
		garage_id: 81,
		part_id: 342,
		quantity: 457,
	},
	{
		garage_id: 81,
		part_id: 343,
		quantity: 400,
	},
	{
		garage_id: 81,
		part_id: 344,
		quantity: 453,
	},
	{
		garage_id: 81,
		part_id: 345,
		quantity: 350,
	},
	{
		garage_id: 81,
		part_id: 346,
		quantity: 230,
	},
	{
		garage_id: 81,
		part_id: 347,
		quantity: 249,
	},
	{
		garage_id: 81,
		part_id: 348,
		quantity: 481,
	},
	{
		garage_id: 81,
		part_id: 349,
		quantity: 461,
	},
	{
		garage_id: 81,
		part_id: 350,
		quantity: 206,
	},
	{
		garage_id: 81,
		part_id: 351,
		quantity: 491,
	},
	{
		garage_id: 81,
		part_id: 352,
		quantity: 449,
	},
	{
		garage_id: 81,
		part_id: 353,
		quantity: 4,
	},
	{
		garage_id: 81,
		part_id: 354,
		quantity: 241,
	},
	{
		garage_id: 81,
		part_id: 355,
		quantity: 496,
	},
	{
		garage_id: 81,
		part_id: 356,
		quantity: 290,
	},
	{
		garage_id: 81,
		part_id: 357,
		quantity: 369,
	},
	{
		garage_id: 81,
		part_id: 358,
		quantity: 478,
	},
	{
		garage_id: 81,
		part_id: 359,
		quantity: 221,
	},
	{
		garage_id: 81,
		part_id: 360,
		quantity: 455,
	},
	{
		garage_id: 81,
		part_id: 361,
		quantity: 132,
	},
	{
		garage_id: 81,
		part_id: 362,
		quantity: 396,
	},
	{
		garage_id: 81,
		part_id: 363,
		quantity: 272,
	},
	{
		garage_id: 81,
		part_id: 364,
		quantity: 494,
	},
	{
		garage_id: 81,
		part_id: 365,
		quantity: 226,
	},
	{
		garage_id: 81,
		part_id: 366,
		quantity: 492,
	},
	{
		garage_id: 81,
		part_id: 367,
		quantity: 101,
	},
	{
		garage_id: 81,
		part_id: 368,
		quantity: 439,
	},
	{
		garage_id: 81,
		part_id: 369,
		quantity: 416,
	},
	{
		garage_id: 81,
		part_id: 370,
		quantity: 101,
	},
	{
		garage_id: 81,
		part_id: 371,
		quantity: 154,
	},
	{
		garage_id: 81,
		part_id: 372,
		quantity: 307,
	},
	{
		garage_id: 81,
		part_id: 373,
		quantity: 84,
	},
	{
		garage_id: 81,
		part_id: 374,
		quantity: 306,
	},
	{
		garage_id: 81,
		part_id: 375,
		quantity: 49,
	},
	{
		garage_id: 81,
		part_id: 376,
		quantity: 367,
	},
	{
		garage_id: 81,
		part_id: 377,
		quantity: 114,
	},
	{
		garage_id: 81,
		part_id: 378,
		quantity: 473,
	},
	{
		garage_id: 81,
		part_id: 379,
		quantity: 260,
	},
	{
		garage_id: 81,
		part_id: 380,
		quantity: 314,
	},
	{
		garage_id: 81,
		part_id: 381,
		quantity: 414,
	},
	{
		garage_id: 81,
		part_id: 382,
		quantity: 152,
	},
	{
		garage_id: 81,
		part_id: 383,
		quantity: 39,
	},
	{
		garage_id: 81,
		part_id: 384,
		quantity: 288,
	},
	{
		garage_id: 81,
		part_id: 385,
		quantity: 296,
	},
	{
		garage_id: 81,
		part_id: 386,
		quantity: 136,
	},
	{
		garage_id: 81,
		part_id: 387,
		quantity: 78,
	},
	{
		garage_id: 81,
		part_id: 388,
		quantity: 285,
	},
	{
		garage_id: 81,
		part_id: 389,
		quantity: 269,
	},
	{
		garage_id: 81,
		part_id: 390,
		quantity: 253,
	},
	{
		garage_id: 81,
		part_id: 391,
		quantity: 15,
	},
	{
		garage_id: 81,
		part_id: 392,
		quantity: 80,
	},
	{
		garage_id: 81,
		part_id: 393,
		quantity: 353,
	},
	{
		garage_id: 81,
		part_id: 394,
		quantity: 218,
	},
	{
		garage_id: 81,
		part_id: 395,
		quantity: 256,
	},
	{
		garage_id: 81,
		part_id: 396,
		quantity: 205,
	},
	{
		garage_id: 81,
		part_id: 397,
		quantity: 406,
	},
	{
		garage_id: 81,
		part_id: 398,
		quantity: 334,
	},
	{
		garage_id: 81,
		part_id: 399,
		quantity: 374,
	},
	{
		garage_id: 82,
		part_id: 0,
		quantity: 175,
	},
	{
		garage_id: 82,
		part_id: 1,
		quantity: 184,
	},
	{
		garage_id: 82,
		part_id: 2,
		quantity: 251,
	},
	{
		garage_id: 82,
		part_id: 3,
		quantity: 20,
	},
	{
		garage_id: 82,
		part_id: 4,
		quantity: 314,
	},
	{
		garage_id: 82,
		part_id: 5,
		quantity: 447,
	},
	{
		garage_id: 82,
		part_id: 6,
		quantity: 49,
	},
	{
		garage_id: 82,
		part_id: 7,
		quantity: 247,
	},
	{
		garage_id: 82,
		part_id: 8,
		quantity: 148,
	},
	{
		garage_id: 82,
		part_id: 9,
		quantity: 359,
	},
	{
		garage_id: 82,
		part_id: 10,
		quantity: 32,
	},
	{
		garage_id: 82,
		part_id: 11,
		quantity: 327,
	},
	{
		garage_id: 82,
		part_id: 12,
		quantity: 311,
	},
	{
		garage_id: 82,
		part_id: 13,
		quantity: 194,
	},
	{
		garage_id: 82,
		part_id: 14,
		quantity: 33,
	},
	{
		garage_id: 82,
		part_id: 15,
		quantity: 148,
	},
	{
		garage_id: 82,
		part_id: 16,
		quantity: 327,
	},
	{
		garage_id: 82,
		part_id: 17,
		quantity: 28,
	},
	{
		garage_id: 82,
		part_id: 18,
		quantity: 130,
	},
	{
		garage_id: 82,
		part_id: 19,
		quantity: 185,
	},
	{
		garage_id: 82,
		part_id: 20,
		quantity: 20,
	},
	{
		garage_id: 82,
		part_id: 21,
		quantity: 462,
	},
	{
		garage_id: 82,
		part_id: 22,
		quantity: 127,
	},
	{
		garage_id: 82,
		part_id: 23,
		quantity: 265,
	},
	{
		garage_id: 82,
		part_id: 24,
		quantity: 232,
	},
	{
		garage_id: 82,
		part_id: 25,
		quantity: 81,
	},
	{
		garage_id: 82,
		part_id: 26,
		quantity: 151,
	},
	{
		garage_id: 82,
		part_id: 27,
		quantity: 233,
	},
	{
		garage_id: 82,
		part_id: 28,
		quantity: 179,
	},
	{
		garage_id: 82,
		part_id: 29,
		quantity: 150,
	},
	{
		garage_id: 82,
		part_id: 30,
		quantity: 270,
	},
	{
		garage_id: 82,
		part_id: 31,
		quantity: 264,
	},
	{
		garage_id: 82,
		part_id: 32,
		quantity: 315,
	},
	{
		garage_id: 82,
		part_id: 33,
		quantity: 404,
	},
	{
		garage_id: 82,
		part_id: 34,
		quantity: 88,
	},
	{
		garage_id: 82,
		part_id: 35,
		quantity: 356,
	},
	{
		garage_id: 82,
		part_id: 36,
		quantity: 14,
	},
	{
		garage_id: 82,
		part_id: 37,
		quantity: 197,
	},
	{
		garage_id: 82,
		part_id: 38,
		quantity: 57,
	},
	{
		garage_id: 82,
		part_id: 39,
		quantity: 458,
	},
	{
		garage_id: 82,
		part_id: 40,
		quantity: 405,
	},
	{
		garage_id: 82,
		part_id: 41,
		quantity: 77,
	},
	{
		garage_id: 82,
		part_id: 42,
		quantity: 423,
	},
	{
		garage_id: 82,
		part_id: 43,
		quantity: 354,
	},
	{
		garage_id: 82,
		part_id: 44,
		quantity: 190,
	},
	{
		garage_id: 82,
		part_id: 45,
		quantity: 402,
	},
	{
		garage_id: 82,
		part_id: 46,
		quantity: 113,
	},
	{
		garage_id: 82,
		part_id: 47,
		quantity: 76,
	},
	{
		garage_id: 82,
		part_id: 48,
		quantity: 150,
	},
	{
		garage_id: 82,
		part_id: 49,
		quantity: 27,
	},
	{
		garage_id: 82,
		part_id: 50,
		quantity: 231,
	},
	{
		garage_id: 82,
		part_id: 51,
		quantity: 159,
	},
	{
		garage_id: 82,
		part_id: 52,
		quantity: 105,
	},
	{
		garage_id: 82,
		part_id: 53,
		quantity: 201,
	},
	{
		garage_id: 82,
		part_id: 54,
		quantity: 43,
	},
	{
		garage_id: 82,
		part_id: 55,
		quantity: 469,
	},
	{
		garage_id: 82,
		part_id: 56,
		quantity: 166,
	},
	{
		garage_id: 82,
		part_id: 57,
		quantity: 477,
	},
	{
		garage_id: 82,
		part_id: 58,
		quantity: 259,
	},
	{
		garage_id: 82,
		part_id: 59,
		quantity: 148,
	},
	{
		garage_id: 82,
		part_id: 60,
		quantity: 187,
	},
	{
		garage_id: 82,
		part_id: 61,
		quantity: 217,
	},
	{
		garage_id: 82,
		part_id: 62,
		quantity: 157,
	},
	{
		garage_id: 82,
		part_id: 63,
		quantity: 217,
	},
	{
		garage_id: 82,
		part_id: 64,
		quantity: 109,
	},
	{
		garage_id: 82,
		part_id: 65,
		quantity: 408,
	},
	{
		garage_id: 82,
		part_id: 66,
		quantity: 240,
	},
	{
		garage_id: 82,
		part_id: 67,
		quantity: 433,
	},
	{
		garage_id: 82,
		part_id: 68,
		quantity: 493,
	},
	{
		garage_id: 82,
		part_id: 69,
		quantity: 488,
	},
	{
		garage_id: 82,
		part_id: 70,
		quantity: 41,
	},
	{
		garage_id: 82,
		part_id: 71,
		quantity: 371,
	},
	{
		garage_id: 82,
		part_id: 72,
		quantity: 248,
	},
	{
		garage_id: 82,
		part_id: 73,
		quantity: 75,
	},
	{
		garage_id: 82,
		part_id: 74,
		quantity: 196,
	},
	{
		garage_id: 82,
		part_id: 75,
		quantity: 367,
	},
	{
		garage_id: 82,
		part_id: 76,
		quantity: 225,
	},
	{
		garage_id: 82,
		part_id: 77,
		quantity: 391,
	},
	{
		garage_id: 82,
		part_id: 78,
		quantity: 272,
	},
	{
		garage_id: 82,
		part_id: 79,
		quantity: 268,
	},
	{
		garage_id: 82,
		part_id: 80,
		quantity: 253,
	},
	{
		garage_id: 82,
		part_id: 81,
		quantity: 433,
	},
	{
		garage_id: 82,
		part_id: 82,
		quantity: 16,
	},
	{
		garage_id: 82,
		part_id: 83,
		quantity: 209,
	},
	{
		garage_id: 82,
		part_id: 84,
		quantity: 393,
	},
	{
		garage_id: 82,
		part_id: 85,
		quantity: 413,
	},
	{
		garage_id: 82,
		part_id: 86,
		quantity: 435,
	},
	{
		garage_id: 82,
		part_id: 87,
		quantity: 398,
	},
	{
		garage_id: 82,
		part_id: 88,
		quantity: 232,
	},
	{
		garage_id: 82,
		part_id: 89,
		quantity: 405,
	},
	{
		garage_id: 82,
		part_id: 90,
		quantity: 29,
	},
	{
		garage_id: 82,
		part_id: 91,
		quantity: 394,
	},
	{
		garage_id: 82,
		part_id: 92,
		quantity: 251,
	},
	{
		garage_id: 82,
		part_id: 93,
		quantity: 1,
	},
	{
		garage_id: 82,
		part_id: 94,
		quantity: 117,
	},
	{
		garage_id: 82,
		part_id: 95,
		quantity: 402,
	},
	{
		garage_id: 82,
		part_id: 96,
		quantity: 381,
	},
	{
		garage_id: 82,
		part_id: 97,
		quantity: 315,
	},
	{
		garage_id: 82,
		part_id: 98,
		quantity: 118,
	},
	{
		garage_id: 82,
		part_id: 99,
		quantity: 26,
	},
	{
		garage_id: 82,
		part_id: 100,
		quantity: 219,
	},
	{
		garage_id: 82,
		part_id: 101,
		quantity: 364,
	},
	{
		garage_id: 82,
		part_id: 102,
		quantity: 219,
	},
	{
		garage_id: 82,
		part_id: 103,
		quantity: 79,
	},
	{
		garage_id: 82,
		part_id: 104,
		quantity: 381,
	},
	{
		garage_id: 82,
		part_id: 105,
		quantity: 360,
	},
	{
		garage_id: 82,
		part_id: 106,
		quantity: 233,
	},
	{
		garage_id: 82,
		part_id: 107,
		quantity: 133,
	},
	{
		garage_id: 82,
		part_id: 108,
		quantity: 457,
	},
	{
		garage_id: 82,
		part_id: 109,
		quantity: 321,
	},
	{
		garage_id: 82,
		part_id: 110,
		quantity: 253,
	},
	{
		garage_id: 82,
		part_id: 111,
		quantity: 212,
	},
	{
		garage_id: 82,
		part_id: 112,
		quantity: 266,
	},
	{
		garage_id: 82,
		part_id: 113,
		quantity: 179,
	},
	{
		garage_id: 82,
		part_id: 114,
		quantity: 405,
	},
	{
		garage_id: 82,
		part_id: 115,
		quantity: 244,
	},
	{
		garage_id: 82,
		part_id: 116,
		quantity: 150,
	},
	{
		garage_id: 82,
		part_id: 117,
		quantity: 405,
	},
	{
		garage_id: 82,
		part_id: 118,
		quantity: 42,
	},
	{
		garage_id: 82,
		part_id: 119,
		quantity: 330,
	},
	{
		garage_id: 82,
		part_id: 120,
		quantity: 59,
	},
	{
		garage_id: 82,
		part_id: 121,
		quantity: 446,
	},
	{
		garage_id: 82,
		part_id: 122,
		quantity: 361,
	},
	{
		garage_id: 82,
		part_id: 123,
		quantity: 11,
	},
	{
		garage_id: 82,
		part_id: 124,
		quantity: 488,
	},
	{
		garage_id: 82,
		part_id: 125,
		quantity: 370,
	},
	{
		garage_id: 82,
		part_id: 126,
		quantity: 390,
	},
	{
		garage_id: 82,
		part_id: 127,
		quantity: 432,
	},
	{
		garage_id: 82,
		part_id: 128,
		quantity: 81,
	},
	{
		garage_id: 82,
		part_id: 129,
		quantity: 396,
	},
	{
		garage_id: 82,
		part_id: 130,
		quantity: 15,
	},
	{
		garage_id: 82,
		part_id: 131,
		quantity: 284,
	},
	{
		garage_id: 82,
		part_id: 132,
		quantity: 196,
	},
	{
		garage_id: 82,
		part_id: 133,
		quantity: 43,
	},
	{
		garage_id: 82,
		part_id: 134,
		quantity: 104,
	},
	{
		garage_id: 82,
		part_id: 135,
		quantity: 411,
	},
	{
		garage_id: 82,
		part_id: 136,
		quantity: 419,
	},
	{
		garage_id: 82,
		part_id: 137,
		quantity: 269,
	},
	{
		garage_id: 82,
		part_id: 138,
		quantity: 121,
	},
	{
		garage_id: 82,
		part_id: 139,
		quantity: 107,
	},
	{
		garage_id: 82,
		part_id: 140,
		quantity: 125,
	},
	{
		garage_id: 82,
		part_id: 141,
		quantity: 292,
	},
	{
		garage_id: 82,
		part_id: 142,
		quantity: 166,
	},
	{
		garage_id: 82,
		part_id: 143,
		quantity: 225,
	},
	{
		garage_id: 82,
		part_id: 144,
		quantity: 339,
	},
	{
		garage_id: 82,
		part_id: 145,
		quantity: 268,
	},
	{
		garage_id: 82,
		part_id: 146,
		quantity: 408,
	},
	{
		garage_id: 82,
		part_id: 147,
		quantity: 89,
	},
	{
		garage_id: 82,
		part_id: 148,
		quantity: 191,
	},
	{
		garage_id: 82,
		part_id: 149,
		quantity: 17,
	},
	{
		garage_id: 82,
		part_id: 150,
		quantity: 104,
	},
	{
		garage_id: 82,
		part_id: 151,
		quantity: 30,
	},
	{
		garage_id: 82,
		part_id: 152,
		quantity: 97,
	},
	{
		garage_id: 82,
		part_id: 153,
		quantity: 321,
	},
	{
		garage_id: 82,
		part_id: 154,
		quantity: 374,
	},
	{
		garage_id: 82,
		part_id: 155,
		quantity: 118,
	},
	{
		garage_id: 82,
		part_id: 156,
		quantity: 231,
	},
	{
		garage_id: 82,
		part_id: 157,
		quantity: 436,
	},
	{
		garage_id: 82,
		part_id: 158,
		quantity: 82,
	},
	{
		garage_id: 82,
		part_id: 159,
		quantity: 451,
	},
	{
		garage_id: 82,
		part_id: 160,
		quantity: 188,
	},
	{
		garage_id: 82,
		part_id: 161,
		quantity: 249,
	},
	{
		garage_id: 82,
		part_id: 162,
		quantity: 287,
	},
	{
		garage_id: 82,
		part_id: 163,
		quantity: 372,
	},
	{
		garage_id: 82,
		part_id: 164,
		quantity: 444,
	},
	{
		garage_id: 82,
		part_id: 165,
		quantity: 471,
	},
	{
		garage_id: 82,
		part_id: 166,
		quantity: 151,
	},
	{
		garage_id: 82,
		part_id: 167,
		quantity: 15,
	},
	{
		garage_id: 82,
		part_id: 168,
		quantity: 294,
	},
	{
		garage_id: 82,
		part_id: 169,
		quantity: 8,
	},
	{
		garage_id: 82,
		part_id: 170,
		quantity: 73,
	},
	{
		garage_id: 82,
		part_id: 171,
		quantity: 200,
	},
	{
		garage_id: 82,
		part_id: 172,
		quantity: 495,
	},
	{
		garage_id: 82,
		part_id: 173,
		quantity: 114,
	},
	{
		garage_id: 82,
		part_id: 174,
		quantity: 280,
	},
	{
		garage_id: 82,
		part_id: 175,
		quantity: 324,
	},
	{
		garage_id: 82,
		part_id: 176,
		quantity: 7,
	},
	{
		garage_id: 82,
		part_id: 177,
		quantity: 3,
	},
	{
		garage_id: 82,
		part_id: 178,
		quantity: 62,
	},
	{
		garage_id: 82,
		part_id: 179,
		quantity: 344,
	},
	{
		garage_id: 82,
		part_id: 180,
		quantity: 334,
	},
	{
		garage_id: 82,
		part_id: 181,
		quantity: 43,
	},
	{
		garage_id: 82,
		part_id: 182,
		quantity: 121,
	},
	{
		garage_id: 82,
		part_id: 183,
		quantity: 246,
	},
	{
		garage_id: 82,
		part_id: 184,
		quantity: 28,
	},
	{
		garage_id: 82,
		part_id: 185,
		quantity: 286,
	},
	{
		garage_id: 82,
		part_id: 186,
		quantity: 20,
	},
	{
		garage_id: 82,
		part_id: 187,
		quantity: 251,
	},
	{
		garage_id: 82,
		part_id: 188,
		quantity: 462,
	},
	{
		garage_id: 82,
		part_id: 189,
		quantity: 401,
	},
	{
		garage_id: 82,
		part_id: 190,
		quantity: 295,
	},
	{
		garage_id: 82,
		part_id: 191,
		quantity: 323,
	},
	{
		garage_id: 82,
		part_id: 192,
		quantity: 401,
	},
	{
		garage_id: 82,
		part_id: 193,
		quantity: 265,
	},
	{
		garage_id: 82,
		part_id: 194,
		quantity: 36,
	},
	{
		garage_id: 82,
		part_id: 195,
		quantity: 293,
	},
	{
		garage_id: 82,
		part_id: 196,
		quantity: 331,
	},
	{
		garage_id: 82,
		part_id: 197,
		quantity: 468,
	},
	{
		garage_id: 82,
		part_id: 198,
		quantity: 428,
	},
	{
		garage_id: 82,
		part_id: 199,
		quantity: 473,
	},
	{
		garage_id: 82,
		part_id: 200,
		quantity: 11,
	},
	{
		garage_id: 82,
		part_id: 201,
		quantity: 386,
	},
	{
		garage_id: 82,
		part_id: 202,
		quantity: 322,
	},
	{
		garage_id: 82,
		part_id: 203,
		quantity: 467,
	},
	{
		garage_id: 82,
		part_id: 204,
		quantity: 28,
	},
	{
		garage_id: 82,
		part_id: 205,
		quantity: 58,
	},
	{
		garage_id: 82,
		part_id: 206,
		quantity: 150,
	},
	{
		garage_id: 82,
		part_id: 207,
		quantity: 77,
	},
	{
		garage_id: 82,
		part_id: 208,
		quantity: 140,
	},
	{
		garage_id: 82,
		part_id: 209,
		quantity: 443,
	},
	{
		garage_id: 82,
		part_id: 210,
		quantity: 472,
	},
	{
		garage_id: 82,
		part_id: 211,
		quantity: 436,
	},
	{
		garage_id: 82,
		part_id: 212,
		quantity: 127,
	},
	{
		garage_id: 82,
		part_id: 213,
		quantity: 47,
	},
	{
		garage_id: 82,
		part_id: 214,
		quantity: 215,
	},
	{
		garage_id: 82,
		part_id: 215,
		quantity: 113,
	},
	{
		garage_id: 82,
		part_id: 216,
		quantity: 135,
	},
	{
		garage_id: 82,
		part_id: 217,
		quantity: 25,
	},
	{
		garage_id: 82,
		part_id: 218,
		quantity: 286,
	},
	{
		garage_id: 82,
		part_id: 219,
		quantity: 290,
	},
	{
		garage_id: 82,
		part_id: 220,
		quantity: 460,
	},
	{
		garage_id: 82,
		part_id: 221,
		quantity: 454,
	},
	{
		garage_id: 82,
		part_id: 222,
		quantity: 383,
	},
	{
		garage_id: 82,
		part_id: 223,
		quantity: 59,
	},
	{
		garage_id: 82,
		part_id: 224,
		quantity: 317,
	},
	{
		garage_id: 82,
		part_id: 225,
		quantity: 415,
	},
	{
		garage_id: 82,
		part_id: 226,
		quantity: 245,
	},
	{
		garage_id: 82,
		part_id: 227,
		quantity: 134,
	},
	{
		garage_id: 82,
		part_id: 228,
		quantity: 165,
	},
	{
		garage_id: 82,
		part_id: 229,
		quantity: 317,
	},
	{
		garage_id: 82,
		part_id: 230,
		quantity: 445,
	},
	{
		garage_id: 82,
		part_id: 231,
		quantity: 371,
	},
	{
		garage_id: 82,
		part_id: 232,
		quantity: 458,
	},
	{
		garage_id: 82,
		part_id: 233,
		quantity: 61,
	},
	{
		garage_id: 82,
		part_id: 234,
		quantity: 258,
	},
	{
		garage_id: 82,
		part_id: 235,
		quantity: 185,
	},
	{
		garage_id: 82,
		part_id: 236,
		quantity: 239,
	},
	{
		garage_id: 82,
		part_id: 237,
		quantity: 321,
	},
	{
		garage_id: 82,
		part_id: 238,
		quantity: 143,
	},
	{
		garage_id: 82,
		part_id: 239,
		quantity: 326,
	},
	{
		garage_id: 82,
		part_id: 240,
		quantity: 156,
	},
	{
		garage_id: 82,
		part_id: 241,
		quantity: 439,
	},
	{
		garage_id: 82,
		part_id: 242,
		quantity: 268,
	},
	{
		garage_id: 82,
		part_id: 243,
		quantity: 64,
	},
	{
		garage_id: 82,
		part_id: 244,
		quantity: 465,
	},
	{
		garage_id: 82,
		part_id: 245,
		quantity: 414,
	},
	{
		garage_id: 82,
		part_id: 246,
		quantity: 45,
	},
	{
		garage_id: 82,
		part_id: 247,
		quantity: 128,
	},
	{
		garage_id: 82,
		part_id: 248,
		quantity: 246,
	},
	{
		garage_id: 82,
		part_id: 249,
		quantity: 488,
	},
	{
		garage_id: 82,
		part_id: 250,
		quantity: 137,
	},
	{
		garage_id: 82,
		part_id: 251,
		quantity: 369,
	},
	{
		garage_id: 82,
		part_id: 252,
		quantity: 401,
	},
	{
		garage_id: 82,
		part_id: 253,
		quantity: 432,
	},
	{
		garage_id: 82,
		part_id: 254,
		quantity: 407,
	},
	{
		garage_id: 82,
		part_id: 255,
		quantity: 368,
	},
	{
		garage_id: 82,
		part_id: 256,
		quantity: 257,
	},
	{
		garage_id: 82,
		part_id: 257,
		quantity: 133,
	},
	{
		garage_id: 82,
		part_id: 258,
		quantity: 41,
	},
	{
		garage_id: 82,
		part_id: 259,
		quantity: 66,
	},
	{
		garage_id: 82,
		part_id: 260,
		quantity: 103,
	},
	{
		garage_id: 82,
		part_id: 261,
		quantity: 263,
	},
	{
		garage_id: 82,
		part_id: 262,
		quantity: 8,
	},
	{
		garage_id: 82,
		part_id: 263,
		quantity: 140,
	},
	{
		garage_id: 82,
		part_id: 264,
		quantity: 452,
	},
	{
		garage_id: 82,
		part_id: 265,
		quantity: 93,
	},
	{
		garage_id: 82,
		part_id: 266,
		quantity: 376,
	},
	{
		garage_id: 82,
		part_id: 267,
		quantity: 229,
	},
	{
		garage_id: 82,
		part_id: 268,
		quantity: 85,
	},
	{
		garage_id: 82,
		part_id: 269,
		quantity: 476,
	},
	{
		garage_id: 82,
		part_id: 270,
		quantity: 25,
	},
	{
		garage_id: 82,
		part_id: 271,
		quantity: 484,
	},
	{
		garage_id: 82,
		part_id: 272,
		quantity: 160,
	},
	{
		garage_id: 82,
		part_id: 273,
		quantity: 5,
	},
	{
		garage_id: 82,
		part_id: 274,
		quantity: 344,
	},
	{
		garage_id: 82,
		part_id: 275,
		quantity: 371,
	},
	{
		garage_id: 82,
		part_id: 276,
		quantity: 319,
	},
	{
		garage_id: 82,
		part_id: 277,
		quantity: 412,
	},
	{
		garage_id: 82,
		part_id: 278,
		quantity: 39,
	},
	{
		garage_id: 82,
		part_id: 279,
		quantity: 325,
	},
	{
		garage_id: 82,
		part_id: 280,
		quantity: 141,
	},
	{
		garage_id: 82,
		part_id: 281,
		quantity: 453,
	},
	{
		garage_id: 82,
		part_id: 282,
		quantity: 71,
	},
	{
		garage_id: 82,
		part_id: 283,
		quantity: 69,
	},
	{
		garage_id: 82,
		part_id: 284,
		quantity: 115,
	},
	{
		garage_id: 82,
		part_id: 285,
		quantity: 217,
	},
	{
		garage_id: 82,
		part_id: 286,
		quantity: 468,
	},
	{
		garage_id: 82,
		part_id: 287,
		quantity: 480,
	},
	{
		garage_id: 82,
		part_id: 288,
		quantity: 52,
	},
	{
		garage_id: 82,
		part_id: 289,
		quantity: 192,
	},
	{
		garage_id: 82,
		part_id: 290,
		quantity: 461,
	},
	{
		garage_id: 82,
		part_id: 291,
		quantity: 401,
	},
	{
		garage_id: 82,
		part_id: 292,
		quantity: 441,
	},
	{
		garage_id: 82,
		part_id: 293,
		quantity: 111,
	},
	{
		garage_id: 82,
		part_id: 294,
		quantity: 217,
	},
	{
		garage_id: 82,
		part_id: 295,
		quantity: 143,
	},
	{
		garage_id: 82,
		part_id: 296,
		quantity: 401,
	},
	{
		garage_id: 82,
		part_id: 297,
		quantity: 126,
	},
	{
		garage_id: 82,
		part_id: 298,
		quantity: 166,
	},
	{
		garage_id: 82,
		part_id: 299,
		quantity: 58,
	},
	{
		garage_id: 82,
		part_id: 300,
		quantity: 190,
	},
	{
		garage_id: 82,
		part_id: 301,
		quantity: 125,
	},
	{
		garage_id: 82,
		part_id: 302,
		quantity: 215,
	},
	{
		garage_id: 82,
		part_id: 303,
		quantity: 339,
	},
	{
		garage_id: 82,
		part_id: 304,
		quantity: 370,
	},
	{
		garage_id: 82,
		part_id: 305,
		quantity: 464,
	},
	{
		garage_id: 82,
		part_id: 306,
		quantity: 294,
	},
	{
		garage_id: 82,
		part_id: 307,
		quantity: 88,
	},
	{
		garage_id: 82,
		part_id: 308,
		quantity: 93,
	},
	{
		garage_id: 82,
		part_id: 309,
		quantity: 38,
	},
	{
		garage_id: 82,
		part_id: 310,
		quantity: 51,
	},
	{
		garage_id: 82,
		part_id: 311,
		quantity: 396,
	},
	{
		garage_id: 82,
		part_id: 312,
		quantity: 162,
	},
	{
		garage_id: 82,
		part_id: 313,
		quantity: 456,
	},
	{
		garage_id: 82,
		part_id: 314,
		quantity: 402,
	},
	{
		garage_id: 82,
		part_id: 315,
		quantity: 83,
	},
	{
		garage_id: 82,
		part_id: 316,
		quantity: 435,
	},
	{
		garage_id: 82,
		part_id: 317,
		quantity: 329,
	},
	{
		garage_id: 82,
		part_id: 318,
		quantity: 20,
	},
	{
		garage_id: 82,
		part_id: 319,
		quantity: 358,
	},
	{
		garage_id: 82,
		part_id: 320,
		quantity: 495,
	},
	{
		garage_id: 82,
		part_id: 321,
		quantity: 258,
	},
	{
		garage_id: 82,
		part_id: 322,
		quantity: 329,
	},
	{
		garage_id: 82,
		part_id: 323,
		quantity: 90,
	},
	{
		garage_id: 82,
		part_id: 324,
		quantity: 235,
	},
	{
		garage_id: 82,
		part_id: 325,
		quantity: 479,
	},
	{
		garage_id: 82,
		part_id: 326,
		quantity: 151,
	},
	{
		garage_id: 82,
		part_id: 327,
		quantity: 405,
	},
	{
		garage_id: 82,
		part_id: 328,
		quantity: 445,
	},
	{
		garage_id: 82,
		part_id: 329,
		quantity: 263,
	},
	{
		garage_id: 82,
		part_id: 330,
		quantity: 42,
	},
	{
		garage_id: 82,
		part_id: 331,
		quantity: 484,
	},
	{
		garage_id: 82,
		part_id: 332,
		quantity: 30,
	},
	{
		garage_id: 82,
		part_id: 333,
		quantity: 142,
	},
	{
		garage_id: 82,
		part_id: 334,
		quantity: 150,
	},
	{
		garage_id: 82,
		part_id: 335,
		quantity: 112,
	},
	{
		garage_id: 82,
		part_id: 336,
		quantity: 372,
	},
	{
		garage_id: 82,
		part_id: 337,
		quantity: 377,
	},
	{
		garage_id: 82,
		part_id: 338,
		quantity: 4,
	},
	{
		garage_id: 82,
		part_id: 339,
		quantity: 310,
	},
	{
		garage_id: 82,
		part_id: 340,
		quantity: 151,
	},
	{
		garage_id: 82,
		part_id: 341,
		quantity: 243,
	},
	{
		garage_id: 82,
		part_id: 342,
		quantity: 263,
	},
	{
		garage_id: 82,
		part_id: 343,
		quantity: 150,
	},
	{
		garage_id: 82,
		part_id: 344,
		quantity: 342,
	},
	{
		garage_id: 82,
		part_id: 345,
		quantity: 189,
	},
	{
		garage_id: 82,
		part_id: 346,
		quantity: 38,
	},
	{
		garage_id: 82,
		part_id: 347,
		quantity: 401,
	},
	{
		garage_id: 82,
		part_id: 348,
		quantity: 303,
	},
	{
		garage_id: 82,
		part_id: 349,
		quantity: 259,
	},
	{
		garage_id: 82,
		part_id: 350,
		quantity: 134,
	},
	{
		garage_id: 82,
		part_id: 351,
		quantity: 143,
	},
	{
		garage_id: 82,
		part_id: 352,
		quantity: 69,
	},
	{
		garage_id: 82,
		part_id: 353,
		quantity: 169,
	},
	{
		garage_id: 82,
		part_id: 354,
		quantity: 457,
	},
	{
		garage_id: 82,
		part_id: 355,
		quantity: 263,
	},
	{
		garage_id: 82,
		part_id: 356,
		quantity: 137,
	},
	{
		garage_id: 82,
		part_id: 357,
		quantity: 378,
	},
	{
		garage_id: 82,
		part_id: 358,
		quantity: 72,
	},
	{
		garage_id: 82,
		part_id: 359,
		quantity: 439,
	},
	{
		garage_id: 82,
		part_id: 360,
		quantity: 455,
	},
	{
		garage_id: 82,
		part_id: 361,
		quantity: 240,
	},
	{
		garage_id: 82,
		part_id: 362,
		quantity: 495,
	},
	{
		garage_id: 82,
		part_id: 363,
		quantity: 160,
	},
	{
		garage_id: 82,
		part_id: 364,
		quantity: 261,
	},
	{
		garage_id: 82,
		part_id: 365,
		quantity: 58,
	},
	{
		garage_id: 82,
		part_id: 366,
		quantity: 355,
	},
	{
		garage_id: 82,
		part_id: 367,
		quantity: 134,
	},
	{
		garage_id: 82,
		part_id: 368,
		quantity: 307,
	},
	{
		garage_id: 82,
		part_id: 369,
		quantity: 56,
	},
	{
		garage_id: 82,
		part_id: 370,
		quantity: 55,
	},
	{
		garage_id: 82,
		part_id: 371,
		quantity: 245,
	},
	{
		garage_id: 82,
		part_id: 372,
		quantity: 382,
	},
	{
		garage_id: 82,
		part_id: 373,
		quantity: 455,
	},
	{
		garage_id: 82,
		part_id: 374,
		quantity: 39,
	},
	{
		garage_id: 82,
		part_id: 375,
		quantity: 162,
	},
	{
		garage_id: 82,
		part_id: 376,
		quantity: 297,
	},
	{
		garage_id: 82,
		part_id: 377,
		quantity: 321,
	},
	{
		garage_id: 82,
		part_id: 378,
		quantity: 299,
	},
	{
		garage_id: 82,
		part_id: 379,
		quantity: 116,
	},
	{
		garage_id: 82,
		part_id: 380,
		quantity: 268,
	},
	{
		garage_id: 82,
		part_id: 381,
		quantity: 265,
	},
	{
		garage_id: 82,
		part_id: 382,
		quantity: 257,
	},
	{
		garage_id: 82,
		part_id: 383,
		quantity: 203,
	},
	{
		garage_id: 82,
		part_id: 384,
		quantity: 140,
	},
	{
		garage_id: 82,
		part_id: 385,
		quantity: 430,
	},
	{
		garage_id: 82,
		part_id: 386,
		quantity: 442,
	},
	{
		garage_id: 82,
		part_id: 387,
		quantity: 402,
	},
	{
		garage_id: 82,
		part_id: 388,
		quantity: 414,
	},
	{
		garage_id: 82,
		part_id: 389,
		quantity: 208,
	},
	{
		garage_id: 82,
		part_id: 390,
		quantity: 441,
	},
	{
		garage_id: 82,
		part_id: 391,
		quantity: 203,
	},
	{
		garage_id: 82,
		part_id: 392,
		quantity: 121,
	},
	{
		garage_id: 82,
		part_id: 393,
		quantity: 218,
	},
	{
		garage_id: 82,
		part_id: 394,
		quantity: 448,
	},
	{
		garage_id: 82,
		part_id: 395,
		quantity: 452,
	},
	{
		garage_id: 82,
		part_id: 396,
		quantity: 339,
	},
	{
		garage_id: 82,
		part_id: 397,
		quantity: 195,
	},
	{
		garage_id: 82,
		part_id: 398,
		quantity: 88,
	},
	{
		garage_id: 82,
		part_id: 399,
		quantity: 404,
	},
	{
		garage_id: 83,
		part_id: 0,
		quantity: 105,
	},
	{
		garage_id: 83,
		part_id: 1,
		quantity: 280,
	},
	{
		garage_id: 83,
		part_id: 2,
		quantity: 177,
	},
	{
		garage_id: 83,
		part_id: 3,
		quantity: 120,
	},
	{
		garage_id: 83,
		part_id: 4,
		quantity: 466,
	},
	{
		garage_id: 83,
		part_id: 5,
		quantity: 324,
	},
	{
		garage_id: 83,
		part_id: 6,
		quantity: 153,
	},
	{
		garage_id: 83,
		part_id: 7,
		quantity: 21,
	},
	{
		garage_id: 83,
		part_id: 8,
		quantity: 493,
	},
	{
		garage_id: 83,
		part_id: 9,
		quantity: 77,
	},
	{
		garage_id: 83,
		part_id: 10,
		quantity: 40,
	},
	{
		garage_id: 83,
		part_id: 11,
		quantity: 283,
	},
	{
		garage_id: 83,
		part_id: 12,
		quantity: 248,
	},
	{
		garage_id: 83,
		part_id: 13,
		quantity: 447,
	},
	{
		garage_id: 83,
		part_id: 14,
		quantity: 332,
	},
	{
		garage_id: 83,
		part_id: 15,
		quantity: 123,
	},
	{
		garage_id: 83,
		part_id: 16,
		quantity: 246,
	},
	{
		garage_id: 83,
		part_id: 17,
		quantity: 217,
	},
	{
		garage_id: 83,
		part_id: 18,
		quantity: 108,
	},
	{
		garage_id: 83,
		part_id: 19,
		quantity: 476,
	},
	{
		garage_id: 83,
		part_id: 20,
		quantity: 168,
	},
	{
		garage_id: 83,
		part_id: 21,
		quantity: 4,
	},
	{
		garage_id: 83,
		part_id: 22,
		quantity: 382,
	},
	{
		garage_id: 83,
		part_id: 23,
		quantity: 66,
	},
	{
		garage_id: 83,
		part_id: 24,
		quantity: 127,
	},
	{
		garage_id: 83,
		part_id: 25,
		quantity: 228,
	},
	{
		garage_id: 83,
		part_id: 26,
		quantity: 37,
	},
	{
		garage_id: 83,
		part_id: 27,
		quantity: 253,
	},
	{
		garage_id: 83,
		part_id: 28,
		quantity: 401,
	},
	{
		garage_id: 83,
		part_id: 29,
		quantity: 323,
	},
	{
		garage_id: 83,
		part_id: 30,
		quantity: 400,
	},
	{
		garage_id: 83,
		part_id: 31,
		quantity: 433,
	},
	{
		garage_id: 83,
		part_id: 32,
		quantity: 283,
	},
	{
		garage_id: 83,
		part_id: 33,
		quantity: 84,
	},
	{
		garage_id: 83,
		part_id: 34,
		quantity: 44,
	},
	{
		garage_id: 83,
		part_id: 35,
		quantity: 227,
	},
	{
		garage_id: 83,
		part_id: 36,
		quantity: 146,
	},
	{
		garage_id: 83,
		part_id: 37,
		quantity: 178,
	},
	{
		garage_id: 83,
		part_id: 38,
		quantity: 106,
	},
	{
		garage_id: 83,
		part_id: 39,
		quantity: 106,
	},
	{
		garage_id: 83,
		part_id: 40,
		quantity: 411,
	},
	{
		garage_id: 83,
		part_id: 41,
		quantity: 50,
	},
	{
		garage_id: 83,
		part_id: 42,
		quantity: 140,
	},
	{
		garage_id: 83,
		part_id: 43,
		quantity: 182,
	},
	{
		garage_id: 83,
		part_id: 44,
		quantity: 124,
	},
	{
		garage_id: 83,
		part_id: 45,
		quantity: 93,
	},
	{
		garage_id: 83,
		part_id: 46,
		quantity: 482,
	},
	{
		garage_id: 83,
		part_id: 47,
		quantity: 375,
	},
	{
		garage_id: 83,
		part_id: 48,
		quantity: 401,
	},
	{
		garage_id: 83,
		part_id: 49,
		quantity: 45,
	},
	{
		garage_id: 83,
		part_id: 50,
		quantity: 183,
	},
	{
		garage_id: 83,
		part_id: 51,
		quantity: 275,
	},
	{
		garage_id: 83,
		part_id: 52,
		quantity: 20,
	},
	{
		garage_id: 83,
		part_id: 53,
		quantity: 275,
	},
	{
		garage_id: 83,
		part_id: 54,
		quantity: 46,
	},
	{
		garage_id: 83,
		part_id: 55,
		quantity: 150,
	},
	{
		garage_id: 83,
		part_id: 56,
		quantity: 278,
	},
	{
		garage_id: 83,
		part_id: 57,
		quantity: 481,
	},
	{
		garage_id: 83,
		part_id: 58,
		quantity: 438,
	},
	{
		garage_id: 83,
		part_id: 59,
		quantity: 106,
	},
	{
		garage_id: 83,
		part_id: 60,
		quantity: 89,
	},
	{
		garage_id: 83,
		part_id: 61,
		quantity: 26,
	},
	{
		garage_id: 83,
		part_id: 62,
		quantity: 75,
	},
	{
		garage_id: 83,
		part_id: 63,
		quantity: 487,
	},
	{
		garage_id: 83,
		part_id: 64,
		quantity: 294,
	},
	{
		garage_id: 83,
		part_id: 65,
		quantity: 478,
	},
	{
		garage_id: 83,
		part_id: 66,
		quantity: 194,
	},
	{
		garage_id: 83,
		part_id: 67,
		quantity: 314,
	},
	{
		garage_id: 83,
		part_id: 68,
		quantity: 240,
	},
	{
		garage_id: 83,
		part_id: 69,
		quantity: 257,
	},
	{
		garage_id: 83,
		part_id: 70,
		quantity: 158,
	},
	{
		garage_id: 83,
		part_id: 71,
		quantity: 90,
	},
	{
		garage_id: 83,
		part_id: 72,
		quantity: 211,
	},
	{
		garage_id: 83,
		part_id: 73,
		quantity: 10,
	},
	{
		garage_id: 83,
		part_id: 74,
		quantity: 307,
	},
	{
		garage_id: 83,
		part_id: 75,
		quantity: 18,
	},
	{
		garage_id: 83,
		part_id: 76,
		quantity: 201,
	},
	{
		garage_id: 83,
		part_id: 77,
		quantity: 80,
	},
	{
		garage_id: 83,
		part_id: 78,
		quantity: 2,
	},
	{
		garage_id: 83,
		part_id: 79,
		quantity: 47,
	},
	{
		garage_id: 83,
		part_id: 80,
		quantity: 435,
	},
	{
		garage_id: 83,
		part_id: 81,
		quantity: 410,
	},
	{
		garage_id: 83,
		part_id: 82,
		quantity: 116,
	},
	{
		garage_id: 83,
		part_id: 83,
		quantity: 100,
	},
	{
		garage_id: 83,
		part_id: 84,
		quantity: 353,
	},
	{
		garage_id: 83,
		part_id: 85,
		quantity: 485,
	},
	{
		garage_id: 83,
		part_id: 86,
		quantity: 183,
	},
	{
		garage_id: 83,
		part_id: 87,
		quantity: 486,
	},
	{
		garage_id: 83,
		part_id: 88,
		quantity: 227,
	},
	{
		garage_id: 83,
		part_id: 89,
		quantity: 228,
	},
	{
		garage_id: 83,
		part_id: 90,
		quantity: 167,
	},
	{
		garage_id: 83,
		part_id: 91,
		quantity: 30,
	},
	{
		garage_id: 83,
		part_id: 92,
		quantity: 351,
	},
	{
		garage_id: 83,
		part_id: 93,
		quantity: 348,
	},
	{
		garage_id: 83,
		part_id: 94,
		quantity: 46,
	},
	{
		garage_id: 83,
		part_id: 95,
		quantity: 203,
	},
	{
		garage_id: 83,
		part_id: 96,
		quantity: 147,
	},
	{
		garage_id: 83,
		part_id: 97,
		quantity: 459,
	},
	{
		garage_id: 83,
		part_id: 98,
		quantity: 432,
	},
	{
		garage_id: 83,
		part_id: 99,
		quantity: 294,
	},
	{
		garage_id: 83,
		part_id: 100,
		quantity: 126,
	},
	{
		garage_id: 83,
		part_id: 101,
		quantity: 409,
	},
	{
		garage_id: 83,
		part_id: 102,
		quantity: 210,
	},
	{
		garage_id: 83,
		part_id: 103,
		quantity: 298,
	},
	{
		garage_id: 83,
		part_id: 104,
		quantity: 122,
	},
	{
		garage_id: 83,
		part_id: 105,
		quantity: 294,
	},
	{
		garage_id: 83,
		part_id: 106,
		quantity: 371,
	},
	{
		garage_id: 83,
		part_id: 107,
		quantity: 372,
	},
	{
		garage_id: 83,
		part_id: 108,
		quantity: 90,
	},
	{
		garage_id: 83,
		part_id: 109,
		quantity: 403,
	},
	{
		garage_id: 83,
		part_id: 110,
		quantity: 445,
	},
	{
		garage_id: 83,
		part_id: 111,
		quantity: 202,
	},
	{
		garage_id: 83,
		part_id: 112,
		quantity: 279,
	},
	{
		garage_id: 83,
		part_id: 113,
		quantity: 394,
	},
	{
		garage_id: 83,
		part_id: 114,
		quantity: 170,
	},
	{
		garage_id: 83,
		part_id: 115,
		quantity: 337,
	},
	{
		garage_id: 83,
		part_id: 116,
		quantity: 334,
	},
	{
		garage_id: 83,
		part_id: 117,
		quantity: 86,
	},
	{
		garage_id: 83,
		part_id: 118,
		quantity: 103,
	},
	{
		garage_id: 83,
		part_id: 119,
		quantity: 155,
	},
	{
		garage_id: 83,
		part_id: 120,
		quantity: 127,
	},
	{
		garage_id: 83,
		part_id: 121,
		quantity: 345,
	},
	{
		garage_id: 83,
		part_id: 122,
		quantity: 28,
	},
	{
		garage_id: 83,
		part_id: 123,
		quantity: 7,
	},
	{
		garage_id: 83,
		part_id: 124,
		quantity: 278,
	},
	{
		garage_id: 83,
		part_id: 125,
		quantity: 397,
	},
	{
		garage_id: 83,
		part_id: 126,
		quantity: 235,
	},
	{
		garage_id: 83,
		part_id: 127,
		quantity: 456,
	},
	{
		garage_id: 83,
		part_id: 128,
		quantity: 465,
	},
	{
		garage_id: 83,
		part_id: 129,
		quantity: 432,
	},
	{
		garage_id: 83,
		part_id: 130,
		quantity: 390,
	},
	{
		garage_id: 83,
		part_id: 131,
		quantity: 125,
	},
	{
		garage_id: 83,
		part_id: 132,
		quantity: 39,
	},
	{
		garage_id: 83,
		part_id: 133,
		quantity: 334,
	},
	{
		garage_id: 83,
		part_id: 134,
		quantity: 67,
	},
	{
		garage_id: 83,
		part_id: 135,
		quantity: 260,
	},
	{
		garage_id: 83,
		part_id: 136,
		quantity: 23,
	},
	{
		garage_id: 83,
		part_id: 137,
		quantity: 239,
	},
	{
		garage_id: 83,
		part_id: 138,
		quantity: 75,
	},
	{
		garage_id: 83,
		part_id: 139,
		quantity: 423,
	},
	{
		garage_id: 83,
		part_id: 140,
		quantity: 245,
	},
	{
		garage_id: 83,
		part_id: 141,
		quantity: 483,
	},
	{
		garage_id: 83,
		part_id: 142,
		quantity: 44,
	},
	{
		garage_id: 83,
		part_id: 143,
		quantity: 396,
	},
	{
		garage_id: 83,
		part_id: 144,
		quantity: 390,
	},
	{
		garage_id: 83,
		part_id: 145,
		quantity: 471,
	},
	{
		garage_id: 83,
		part_id: 146,
		quantity: 221,
	},
	{
		garage_id: 83,
		part_id: 147,
		quantity: 481,
	},
	{
		garage_id: 83,
		part_id: 148,
		quantity: 276,
	},
	{
		garage_id: 83,
		part_id: 149,
		quantity: 409,
	},
	{
		garage_id: 83,
		part_id: 150,
		quantity: 497,
	},
	{
		garage_id: 83,
		part_id: 151,
		quantity: 126,
	},
	{
		garage_id: 83,
		part_id: 152,
		quantity: 181,
	},
	{
		garage_id: 83,
		part_id: 153,
		quantity: 25,
	},
	{
		garage_id: 83,
		part_id: 154,
		quantity: 478,
	},
	{
		garage_id: 83,
		part_id: 155,
		quantity: 77,
	},
	{
		garage_id: 83,
		part_id: 156,
		quantity: 318,
	},
	{
		garage_id: 83,
		part_id: 157,
		quantity: 434,
	},
	{
		garage_id: 83,
		part_id: 158,
		quantity: 298,
	},
	{
		garage_id: 83,
		part_id: 159,
		quantity: 239,
	},
	{
		garage_id: 83,
		part_id: 160,
		quantity: 33,
	},
	{
		garage_id: 83,
		part_id: 161,
		quantity: 219,
	},
	{
		garage_id: 83,
		part_id: 162,
		quantity: 254,
	},
	{
		garage_id: 83,
		part_id: 163,
		quantity: 44,
	},
	{
		garage_id: 83,
		part_id: 164,
		quantity: 390,
	},
	{
		garage_id: 83,
		part_id: 165,
		quantity: 144,
	},
	{
		garage_id: 83,
		part_id: 166,
		quantity: 361,
	},
	{
		garage_id: 83,
		part_id: 167,
		quantity: 131,
	},
	{
		garage_id: 83,
		part_id: 168,
		quantity: 480,
	},
	{
		garage_id: 83,
		part_id: 169,
		quantity: 159,
	},
	{
		garage_id: 83,
		part_id: 170,
		quantity: 333,
	},
	{
		garage_id: 83,
		part_id: 171,
		quantity: 322,
	},
	{
		garage_id: 83,
		part_id: 172,
		quantity: 167,
	},
	{
		garage_id: 83,
		part_id: 173,
		quantity: 407,
	},
	{
		garage_id: 83,
		part_id: 174,
		quantity: 332,
	},
	{
		garage_id: 83,
		part_id: 175,
		quantity: 356,
	},
	{
		garage_id: 83,
		part_id: 176,
		quantity: 374,
	},
	{
		garage_id: 83,
		part_id: 177,
		quantity: 184,
	},
	{
		garage_id: 83,
		part_id: 178,
		quantity: 11,
	},
	{
		garage_id: 83,
		part_id: 179,
		quantity: 351,
	},
	{
		garage_id: 83,
		part_id: 180,
		quantity: 188,
	},
	{
		garage_id: 83,
		part_id: 181,
		quantity: 204,
	},
	{
		garage_id: 83,
		part_id: 182,
		quantity: 62,
	},
	{
		garage_id: 83,
		part_id: 183,
		quantity: 461,
	},
	{
		garage_id: 83,
		part_id: 184,
		quantity: 242,
	},
	{
		garage_id: 83,
		part_id: 185,
		quantity: 7,
	},
	{
		garage_id: 83,
		part_id: 186,
		quantity: 185,
	},
	{
		garage_id: 83,
		part_id: 187,
		quantity: 349,
	},
	{
		garage_id: 83,
		part_id: 188,
		quantity: 412,
	},
	{
		garage_id: 83,
		part_id: 189,
		quantity: 426,
	},
	{
		garage_id: 83,
		part_id: 190,
		quantity: 320,
	},
	{
		garage_id: 83,
		part_id: 191,
		quantity: 344,
	},
	{
		garage_id: 83,
		part_id: 192,
		quantity: 374,
	},
	{
		garage_id: 83,
		part_id: 193,
		quantity: 214,
	},
	{
		garage_id: 83,
		part_id: 194,
		quantity: 439,
	},
	{
		garage_id: 83,
		part_id: 195,
		quantity: 130,
	},
	{
		garage_id: 83,
		part_id: 196,
		quantity: 500,
	},
	{
		garage_id: 83,
		part_id: 197,
		quantity: 0,
	},
	{
		garage_id: 83,
		part_id: 198,
		quantity: 423,
	},
	{
		garage_id: 83,
		part_id: 199,
		quantity: 393,
	},
	{
		garage_id: 83,
		part_id: 200,
		quantity: 206,
	},
	{
		garage_id: 83,
		part_id: 201,
		quantity: 446,
	},
	{
		garage_id: 83,
		part_id: 202,
		quantity: 257,
	},
	{
		garage_id: 83,
		part_id: 203,
		quantity: 333,
	},
	{
		garage_id: 83,
		part_id: 204,
		quantity: 84,
	},
	{
		garage_id: 83,
		part_id: 205,
		quantity: 258,
	},
	{
		garage_id: 83,
		part_id: 206,
		quantity: 362,
	},
	{
		garage_id: 83,
		part_id: 207,
		quantity: 192,
	},
	{
		garage_id: 83,
		part_id: 208,
		quantity: 499,
	},
	{
		garage_id: 83,
		part_id: 209,
		quantity: 234,
	},
	{
		garage_id: 83,
		part_id: 210,
		quantity: 17,
	},
	{
		garage_id: 83,
		part_id: 211,
		quantity: 28,
	},
	{
		garage_id: 83,
		part_id: 212,
		quantity: 425,
	},
	{
		garage_id: 83,
		part_id: 213,
		quantity: 195,
	},
	{
		garage_id: 83,
		part_id: 214,
		quantity: 449,
	},
	{
		garage_id: 83,
		part_id: 215,
		quantity: 74,
	},
	{
		garage_id: 83,
		part_id: 216,
		quantity: 214,
	},
	{
		garage_id: 83,
		part_id: 217,
		quantity: 354,
	},
	{
		garage_id: 83,
		part_id: 218,
		quantity: 100,
	},
	{
		garage_id: 83,
		part_id: 219,
		quantity: 460,
	},
	{
		garage_id: 83,
		part_id: 220,
		quantity: 186,
	},
	{
		garage_id: 83,
		part_id: 221,
		quantity: 197,
	},
	{
		garage_id: 83,
		part_id: 222,
		quantity: 27,
	},
	{
		garage_id: 83,
		part_id: 223,
		quantity: 49,
	},
	{
		garage_id: 83,
		part_id: 224,
		quantity: 428,
	},
	{
		garage_id: 83,
		part_id: 225,
		quantity: 126,
	},
	{
		garage_id: 83,
		part_id: 226,
		quantity: 7,
	},
	{
		garage_id: 83,
		part_id: 227,
		quantity: 290,
	},
	{
		garage_id: 83,
		part_id: 228,
		quantity: 369,
	},
	{
		garage_id: 83,
		part_id: 229,
		quantity: 362,
	},
	{
		garage_id: 83,
		part_id: 230,
		quantity: 222,
	},
	{
		garage_id: 83,
		part_id: 231,
		quantity: 153,
	},
	{
		garage_id: 83,
		part_id: 232,
		quantity: 494,
	},
	{
		garage_id: 83,
		part_id: 233,
		quantity: 206,
	},
	{
		garage_id: 83,
		part_id: 234,
		quantity: 230,
	},
	{
		garage_id: 83,
		part_id: 235,
		quantity: 287,
	},
	{
		garage_id: 83,
		part_id: 236,
		quantity: 347,
	},
	{
		garage_id: 83,
		part_id: 237,
		quantity: 262,
	},
	{
		garage_id: 83,
		part_id: 238,
		quantity: 242,
	},
	{
		garage_id: 83,
		part_id: 239,
		quantity: 149,
	},
	{
		garage_id: 83,
		part_id: 240,
		quantity: 76,
	},
	{
		garage_id: 83,
		part_id: 241,
		quantity: 72,
	},
	{
		garage_id: 83,
		part_id: 242,
		quantity: 146,
	},
	{
		garage_id: 83,
		part_id: 243,
		quantity: 86,
	},
	{
		garage_id: 83,
		part_id: 244,
		quantity: 137,
	},
	{
		garage_id: 83,
		part_id: 245,
		quantity: 406,
	},
	{
		garage_id: 83,
		part_id: 246,
		quantity: 91,
	},
	{
		garage_id: 83,
		part_id: 247,
		quantity: 179,
	},
	{
		garage_id: 83,
		part_id: 248,
		quantity: 485,
	},
	{
		garage_id: 83,
		part_id: 249,
		quantity: 113,
	},
	{
		garage_id: 83,
		part_id: 250,
		quantity: 130,
	},
	{
		garage_id: 83,
		part_id: 251,
		quantity: 260,
	},
	{
		garage_id: 83,
		part_id: 252,
		quantity: 200,
	},
	{
		garage_id: 83,
		part_id: 253,
		quantity: 216,
	},
	{
		garage_id: 83,
		part_id: 254,
		quantity: 399,
	},
	{
		garage_id: 83,
		part_id: 255,
		quantity: 372,
	},
	{
		garage_id: 83,
		part_id: 256,
		quantity: 299,
	},
	{
		garage_id: 83,
		part_id: 257,
		quantity: 326,
	},
	{
		garage_id: 83,
		part_id: 258,
		quantity: 229,
	},
	{
		garage_id: 83,
		part_id: 259,
		quantity: 281,
	},
	{
		garage_id: 83,
		part_id: 260,
		quantity: 42,
	},
	{
		garage_id: 83,
		part_id: 261,
		quantity: 467,
	},
	{
		garage_id: 83,
		part_id: 262,
		quantity: 54,
	},
	{
		garage_id: 83,
		part_id: 263,
		quantity: 284,
	},
	{
		garage_id: 83,
		part_id: 264,
		quantity: 247,
	},
	{
		garage_id: 83,
		part_id: 265,
		quantity: 180,
	},
	{
		garage_id: 83,
		part_id: 266,
		quantity: 27,
	},
	{
		garage_id: 83,
		part_id: 267,
		quantity: 279,
	},
	{
		garage_id: 83,
		part_id: 268,
		quantity: 59,
	},
	{
		garage_id: 83,
		part_id: 269,
		quantity: 322,
	},
	{
		garage_id: 83,
		part_id: 270,
		quantity: 204,
	},
	{
		garage_id: 83,
		part_id: 271,
		quantity: 241,
	},
	{
		garage_id: 83,
		part_id: 272,
		quantity: 235,
	},
	{
		garage_id: 83,
		part_id: 273,
		quantity: 408,
	},
	{
		garage_id: 83,
		part_id: 274,
		quantity: 408,
	},
	{
		garage_id: 83,
		part_id: 275,
		quantity: 304,
	},
	{
		garage_id: 83,
		part_id: 276,
		quantity: 336,
	},
	{
		garage_id: 83,
		part_id: 277,
		quantity: 367,
	},
	{
		garage_id: 83,
		part_id: 278,
		quantity: 181,
	},
	{
		garage_id: 83,
		part_id: 279,
		quantity: 48,
	},
	{
		garage_id: 83,
		part_id: 280,
		quantity: 456,
	},
	{
		garage_id: 83,
		part_id: 281,
		quantity: 97,
	},
	{
		garage_id: 83,
		part_id: 282,
		quantity: 99,
	},
	{
		garage_id: 83,
		part_id: 283,
		quantity: 90,
	},
	{
		garage_id: 83,
		part_id: 284,
		quantity: 133,
	},
	{
		garage_id: 83,
		part_id: 285,
		quantity: 494,
	},
	{
		garage_id: 83,
		part_id: 286,
		quantity: 401,
	},
	{
		garage_id: 83,
		part_id: 287,
		quantity: 482,
	},
	{
		garage_id: 83,
		part_id: 288,
		quantity: 166,
	},
	{
		garage_id: 83,
		part_id: 289,
		quantity: 478,
	},
	{
		garage_id: 83,
		part_id: 290,
		quantity: 108,
	},
	{
		garage_id: 83,
		part_id: 291,
		quantity: 316,
	},
	{
		garage_id: 83,
		part_id: 292,
		quantity: 456,
	},
	{
		garage_id: 83,
		part_id: 293,
		quantity: 383,
	},
	{
		garage_id: 83,
		part_id: 294,
		quantity: 489,
	},
	{
		garage_id: 83,
		part_id: 295,
		quantity: 167,
	},
	{
		garage_id: 83,
		part_id: 296,
		quantity: 103,
	},
	{
		garage_id: 83,
		part_id: 297,
		quantity: 432,
	},
	{
		garage_id: 83,
		part_id: 298,
		quantity: 110,
	},
	{
		garage_id: 83,
		part_id: 299,
		quantity: 359,
	},
	{
		garage_id: 83,
		part_id: 300,
		quantity: 494,
	},
	{
		garage_id: 83,
		part_id: 301,
		quantity: 83,
	},
	{
		garage_id: 83,
		part_id: 302,
		quantity: 46,
	},
	{
		garage_id: 83,
		part_id: 303,
		quantity: 84,
	},
	{
		garage_id: 83,
		part_id: 304,
		quantity: 315,
	},
	{
		garage_id: 83,
		part_id: 305,
		quantity: 374,
	},
	{
		garage_id: 83,
		part_id: 306,
		quantity: 59,
	},
	{
		garage_id: 83,
		part_id: 307,
		quantity: 327,
	},
	{
		garage_id: 83,
		part_id: 308,
		quantity: 305,
	},
	{
		garage_id: 83,
		part_id: 309,
		quantity: 445,
	},
	{
		garage_id: 83,
		part_id: 310,
		quantity: 455,
	},
	{
		garage_id: 83,
		part_id: 311,
		quantity: 388,
	},
	{
		garage_id: 83,
		part_id: 312,
		quantity: 306,
	},
	{
		garage_id: 83,
		part_id: 313,
		quantity: 276,
	},
	{
		garage_id: 83,
		part_id: 314,
		quantity: 321,
	},
	{
		garage_id: 83,
		part_id: 315,
		quantity: 46,
	},
	{
		garage_id: 83,
		part_id: 316,
		quantity: 403,
	},
	{
		garage_id: 83,
		part_id: 317,
		quantity: 126,
	},
	{
		garage_id: 83,
		part_id: 318,
		quantity: 276,
	},
	{
		garage_id: 83,
		part_id: 319,
		quantity: 173,
	},
	{
		garage_id: 83,
		part_id: 320,
		quantity: 69,
	},
	{
		garage_id: 83,
		part_id: 321,
		quantity: 181,
	},
	{
		garage_id: 83,
		part_id: 322,
		quantity: 494,
	},
	{
		garage_id: 83,
		part_id: 323,
		quantity: 454,
	},
	{
		garage_id: 83,
		part_id: 324,
		quantity: 189,
	},
	{
		garage_id: 83,
		part_id: 325,
		quantity: 36,
	},
	{
		garage_id: 83,
		part_id: 326,
		quantity: 412,
	},
	{
		garage_id: 83,
		part_id: 327,
		quantity: 34,
	},
	{
		garage_id: 83,
		part_id: 328,
		quantity: 412,
	},
	{
		garage_id: 83,
		part_id: 329,
		quantity: 217,
	},
	{
		garage_id: 83,
		part_id: 330,
		quantity: 345,
	},
	{
		garage_id: 83,
		part_id: 331,
		quantity: 352,
	},
	{
		garage_id: 83,
		part_id: 332,
		quantity: 20,
	},
	{
		garage_id: 83,
		part_id: 333,
		quantity: 228,
	},
	{
		garage_id: 83,
		part_id: 334,
		quantity: 395,
	},
	{
		garage_id: 83,
		part_id: 335,
		quantity: 365,
	},
	{
		garage_id: 83,
		part_id: 336,
		quantity: 210,
	},
	{
		garage_id: 83,
		part_id: 337,
		quantity: 130,
	},
	{
		garage_id: 83,
		part_id: 338,
		quantity: 244,
	},
	{
		garage_id: 83,
		part_id: 339,
		quantity: 41,
	},
	{
		garage_id: 83,
		part_id: 340,
		quantity: 229,
	},
	{
		garage_id: 83,
		part_id: 341,
		quantity: 96,
	},
	{
		garage_id: 83,
		part_id: 342,
		quantity: 120,
	},
	{
		garage_id: 83,
		part_id: 343,
		quantity: 64,
	},
	{
		garage_id: 83,
		part_id: 344,
		quantity: 467,
	},
	{
		garage_id: 83,
		part_id: 345,
		quantity: 126,
	},
	{
		garage_id: 83,
		part_id: 346,
		quantity: 202,
	},
	{
		garage_id: 83,
		part_id: 347,
		quantity: 499,
	},
	{
		garage_id: 83,
		part_id: 348,
		quantity: 256,
	},
	{
		garage_id: 83,
		part_id: 349,
		quantity: 184,
	},
	{
		garage_id: 83,
		part_id: 350,
		quantity: 56,
	},
	{
		garage_id: 83,
		part_id: 351,
		quantity: 369,
	},
	{
		garage_id: 83,
		part_id: 352,
		quantity: 170,
	},
	{
		garage_id: 83,
		part_id: 353,
		quantity: 43,
	},
	{
		garage_id: 83,
		part_id: 354,
		quantity: 211,
	},
	{
		garage_id: 83,
		part_id: 355,
		quantity: 150,
	},
	{
		garage_id: 83,
		part_id: 356,
		quantity: 445,
	},
	{
		garage_id: 83,
		part_id: 357,
		quantity: 305,
	},
	{
		garage_id: 83,
		part_id: 358,
		quantity: 343,
	},
	{
		garage_id: 83,
		part_id: 359,
		quantity: 72,
	},
	{
		garage_id: 83,
		part_id: 360,
		quantity: 1,
	},
	{
		garage_id: 83,
		part_id: 361,
		quantity: 61,
	},
	{
		garage_id: 83,
		part_id: 362,
		quantity: 26,
	},
	{
		garage_id: 83,
		part_id: 363,
		quantity: 141,
	},
	{
		garage_id: 83,
		part_id: 364,
		quantity: 317,
	},
	{
		garage_id: 83,
		part_id: 365,
		quantity: 346,
	},
	{
		garage_id: 83,
		part_id: 366,
		quantity: 420,
	},
	{
		garage_id: 83,
		part_id: 367,
		quantity: 407,
	},
	{
		garage_id: 83,
		part_id: 368,
		quantity: 58,
	},
	{
		garage_id: 83,
		part_id: 369,
		quantity: 345,
	},
	{
		garage_id: 83,
		part_id: 370,
		quantity: 73,
	},
	{
		garage_id: 83,
		part_id: 371,
		quantity: 32,
	},
	{
		garage_id: 83,
		part_id: 372,
		quantity: 29,
	},
	{
		garage_id: 83,
		part_id: 373,
		quantity: 138,
	},
	{
		garage_id: 83,
		part_id: 374,
		quantity: 456,
	},
	{
		garage_id: 83,
		part_id: 375,
		quantity: 139,
	},
	{
		garage_id: 83,
		part_id: 376,
		quantity: 255,
	},
	{
		garage_id: 83,
		part_id: 377,
		quantity: 195,
	},
	{
		garage_id: 83,
		part_id: 378,
		quantity: 352,
	},
	{
		garage_id: 83,
		part_id: 379,
		quantity: 278,
	},
	{
		garage_id: 83,
		part_id: 380,
		quantity: 464,
	},
	{
		garage_id: 83,
		part_id: 381,
		quantity: 101,
	},
	{
		garage_id: 83,
		part_id: 382,
		quantity: 269,
	},
	{
		garage_id: 83,
		part_id: 383,
		quantity: 172,
	},
	{
		garage_id: 83,
		part_id: 384,
		quantity: 365,
	},
	{
		garage_id: 83,
		part_id: 385,
		quantity: 280,
	},
	{
		garage_id: 83,
		part_id: 386,
		quantity: 27,
	},
	{
		garage_id: 83,
		part_id: 387,
		quantity: 459,
	},
	{
		garage_id: 83,
		part_id: 388,
		quantity: 167,
	},
	{
		garage_id: 83,
		part_id: 389,
		quantity: 292,
	},
	{
		garage_id: 83,
		part_id: 390,
		quantity: 27,
	},
	{
		garage_id: 83,
		part_id: 391,
		quantity: 296,
	},
	{
		garage_id: 83,
		part_id: 392,
		quantity: 430,
	},
	{
		garage_id: 83,
		part_id: 393,
		quantity: 361,
	},
	{
		garage_id: 83,
		part_id: 394,
		quantity: 118,
	},
	{
		garage_id: 83,
		part_id: 395,
		quantity: 116,
	},
	{
		garage_id: 83,
		part_id: 396,
		quantity: 409,
	},
	{
		garage_id: 83,
		part_id: 397,
		quantity: 387,
	},
	{
		garage_id: 83,
		part_id: 398,
		quantity: 423,
	},
	{
		garage_id: 83,
		part_id: 399,
		quantity: 155,
	},
	{
		garage_id: 84,
		part_id: 0,
		quantity: 68,
	},
	{
		garage_id: 84,
		part_id: 1,
		quantity: 244,
	},
	{
		garage_id: 84,
		part_id: 2,
		quantity: 37,
	},
	{
		garage_id: 84,
		part_id: 3,
		quantity: 10,
	},
	{
		garage_id: 84,
		part_id: 4,
		quantity: 186,
	},
	{
		garage_id: 84,
		part_id: 5,
		quantity: 24,
	},
	{
		garage_id: 84,
		part_id: 6,
		quantity: 302,
	},
	{
		garage_id: 84,
		part_id: 7,
		quantity: 22,
	},
	{
		garage_id: 84,
		part_id: 8,
		quantity: 446,
	},
	{
		garage_id: 84,
		part_id: 9,
		quantity: 273,
	},
	{
		garage_id: 84,
		part_id: 10,
		quantity: 310,
	},
	{
		garage_id: 84,
		part_id: 11,
		quantity: 224,
	},
	{
		garage_id: 84,
		part_id: 12,
		quantity: 63,
	},
	{
		garage_id: 84,
		part_id: 13,
		quantity: 234,
	},
	{
		garage_id: 84,
		part_id: 14,
		quantity: 34,
	},
	{
		garage_id: 84,
		part_id: 15,
		quantity: 103,
	},
	{
		garage_id: 84,
		part_id: 16,
		quantity: 337,
	},
	{
		garage_id: 84,
		part_id: 17,
		quantity: 168,
	},
	{
		garage_id: 84,
		part_id: 18,
		quantity: 211,
	},
	{
		garage_id: 84,
		part_id: 19,
		quantity: 363,
	},
	{
		garage_id: 84,
		part_id: 20,
		quantity: 340,
	},
	{
		garage_id: 84,
		part_id: 21,
		quantity: 76,
	},
	{
		garage_id: 84,
		part_id: 22,
		quantity: 250,
	},
	{
		garage_id: 84,
		part_id: 23,
		quantity: 170,
	},
	{
		garage_id: 84,
		part_id: 24,
		quantity: 151,
	},
	{
		garage_id: 84,
		part_id: 25,
		quantity: 82,
	},
	{
		garage_id: 84,
		part_id: 26,
		quantity: 128,
	},
	{
		garage_id: 84,
		part_id: 27,
		quantity: 337,
	},
	{
		garage_id: 84,
		part_id: 28,
		quantity: 104,
	},
	{
		garage_id: 84,
		part_id: 29,
		quantity: 37,
	},
	{
		garage_id: 84,
		part_id: 30,
		quantity: 452,
	},
	{
		garage_id: 84,
		part_id: 31,
		quantity: 385,
	},
	{
		garage_id: 84,
		part_id: 32,
		quantity: 394,
	},
	{
		garage_id: 84,
		part_id: 33,
		quantity: 206,
	},
	{
		garage_id: 84,
		part_id: 34,
		quantity: 127,
	},
	{
		garage_id: 84,
		part_id: 35,
		quantity: 73,
	},
	{
		garage_id: 84,
		part_id: 36,
		quantity: 167,
	},
	{
		garage_id: 84,
		part_id: 37,
		quantity: 133,
	},
	{
		garage_id: 84,
		part_id: 38,
		quantity: 376,
	},
	{
		garage_id: 84,
		part_id: 39,
		quantity: 407,
	},
	{
		garage_id: 84,
		part_id: 40,
		quantity: 29,
	},
	{
		garage_id: 84,
		part_id: 41,
		quantity: 145,
	},
	{
		garage_id: 84,
		part_id: 42,
		quantity: 100,
	},
	{
		garage_id: 84,
		part_id: 43,
		quantity: 322,
	},
	{
		garage_id: 84,
		part_id: 44,
		quantity: 429,
	},
	{
		garage_id: 84,
		part_id: 45,
		quantity: 196,
	},
	{
		garage_id: 84,
		part_id: 46,
		quantity: 188,
	},
	{
		garage_id: 84,
		part_id: 47,
		quantity: 50,
	},
	{
		garage_id: 84,
		part_id: 48,
		quantity: 49,
	},
	{
		garage_id: 84,
		part_id: 49,
		quantity: 328,
	},
	{
		garage_id: 84,
		part_id: 50,
		quantity: 84,
	},
	{
		garage_id: 84,
		part_id: 51,
		quantity: 127,
	},
	{
		garage_id: 84,
		part_id: 52,
		quantity: 257,
	},
	{
		garage_id: 84,
		part_id: 53,
		quantity: 478,
	},
	{
		garage_id: 84,
		part_id: 54,
		quantity: 234,
	},
	{
		garage_id: 84,
		part_id: 55,
		quantity: 19,
	},
	{
		garage_id: 84,
		part_id: 56,
		quantity: 35,
	},
	{
		garage_id: 84,
		part_id: 57,
		quantity: 182,
	},
	{
		garage_id: 84,
		part_id: 58,
		quantity: 366,
	},
	{
		garage_id: 84,
		part_id: 59,
		quantity: 14,
	},
	{
		garage_id: 84,
		part_id: 60,
		quantity: 89,
	},
	{
		garage_id: 84,
		part_id: 61,
		quantity: 129,
	},
	{
		garage_id: 84,
		part_id: 62,
		quantity: 112,
	},
	{
		garage_id: 84,
		part_id: 63,
		quantity: 151,
	},
	{
		garage_id: 84,
		part_id: 64,
		quantity: 16,
	},
	{
		garage_id: 84,
		part_id: 65,
		quantity: 210,
	},
	{
		garage_id: 84,
		part_id: 66,
		quantity: 231,
	},
	{
		garage_id: 84,
		part_id: 67,
		quantity: 229,
	},
	{
		garage_id: 84,
		part_id: 68,
		quantity: 396,
	},
	{
		garage_id: 84,
		part_id: 69,
		quantity: 390,
	},
	{
		garage_id: 84,
		part_id: 70,
		quantity: 316,
	},
	{
		garage_id: 84,
		part_id: 71,
		quantity: 438,
	},
	{
		garage_id: 84,
		part_id: 72,
		quantity: 399,
	},
	{
		garage_id: 84,
		part_id: 73,
		quantity: 350,
	},
	{
		garage_id: 84,
		part_id: 74,
		quantity: 440,
	},
	{
		garage_id: 84,
		part_id: 75,
		quantity: 32,
	},
	{
		garage_id: 84,
		part_id: 76,
		quantity: 285,
	},
	{
		garage_id: 84,
		part_id: 77,
		quantity: 426,
	},
	{
		garage_id: 84,
		part_id: 78,
		quantity: 161,
	},
	{
		garage_id: 84,
		part_id: 79,
		quantity: 134,
	},
	{
		garage_id: 84,
		part_id: 80,
		quantity: 124,
	},
	{
		garage_id: 84,
		part_id: 81,
		quantity: 278,
	},
	{
		garage_id: 84,
		part_id: 82,
		quantity: 348,
	},
	{
		garage_id: 84,
		part_id: 83,
		quantity: 360,
	},
	{
		garage_id: 84,
		part_id: 84,
		quantity: 47,
	},
	{
		garage_id: 84,
		part_id: 85,
		quantity: 323,
	},
	{
		garage_id: 84,
		part_id: 86,
		quantity: 37,
	},
	{
		garage_id: 84,
		part_id: 87,
		quantity: 496,
	},
	{
		garage_id: 84,
		part_id: 88,
		quantity: 339,
	},
	{
		garage_id: 84,
		part_id: 89,
		quantity: 179,
	},
	{
		garage_id: 84,
		part_id: 90,
		quantity: 387,
	},
	{
		garage_id: 84,
		part_id: 91,
		quantity: 14,
	},
	{
		garage_id: 84,
		part_id: 92,
		quantity: 243,
	},
	{
		garage_id: 84,
		part_id: 93,
		quantity: 148,
	},
	{
		garage_id: 84,
		part_id: 94,
		quantity: 381,
	},
	{
		garage_id: 84,
		part_id: 95,
		quantity: 94,
	},
	{
		garage_id: 84,
		part_id: 96,
		quantity: 20,
	},
	{
		garage_id: 84,
		part_id: 97,
		quantity: 381,
	},
	{
		garage_id: 84,
		part_id: 98,
		quantity: 300,
	},
	{
		garage_id: 84,
		part_id: 99,
		quantity: 36,
	},
	{
		garage_id: 84,
		part_id: 100,
		quantity: 129,
	},
	{
		garage_id: 84,
		part_id: 101,
		quantity: 47,
	},
	{
		garage_id: 84,
		part_id: 102,
		quantity: 378,
	},
	{
		garage_id: 84,
		part_id: 103,
		quantity: 285,
	},
	{
		garage_id: 84,
		part_id: 104,
		quantity: 299,
	},
	{
		garage_id: 84,
		part_id: 105,
		quantity: 215,
	},
	{
		garage_id: 84,
		part_id: 106,
		quantity: 190,
	},
	{
		garage_id: 84,
		part_id: 107,
		quantity: 66,
	},
	{
		garage_id: 84,
		part_id: 108,
		quantity: 98,
	},
	{
		garage_id: 84,
		part_id: 109,
		quantity: 469,
	},
	{
		garage_id: 84,
		part_id: 110,
		quantity: 216,
	},
	{
		garage_id: 84,
		part_id: 111,
		quantity: 364,
	},
	{
		garage_id: 84,
		part_id: 112,
		quantity: 310,
	},
	{
		garage_id: 84,
		part_id: 113,
		quantity: 192,
	},
	{
		garage_id: 84,
		part_id: 114,
		quantity: 121,
	},
	{
		garage_id: 84,
		part_id: 115,
		quantity: 8,
	},
	{
		garage_id: 84,
		part_id: 116,
		quantity: 335,
	},
	{
		garage_id: 84,
		part_id: 117,
		quantity: 150,
	},
	{
		garage_id: 84,
		part_id: 118,
		quantity: 230,
	},
	{
		garage_id: 84,
		part_id: 119,
		quantity: 200,
	},
	{
		garage_id: 84,
		part_id: 120,
		quantity: 112,
	},
	{
		garage_id: 84,
		part_id: 121,
		quantity: 323,
	},
	{
		garage_id: 84,
		part_id: 122,
		quantity: 126,
	},
	{
		garage_id: 84,
		part_id: 123,
		quantity: 319,
	},
	{
		garage_id: 84,
		part_id: 124,
		quantity: 275,
	},
	{
		garage_id: 84,
		part_id: 125,
		quantity: 160,
	},
	{
		garage_id: 84,
		part_id: 126,
		quantity: 279,
	},
	{
		garage_id: 84,
		part_id: 127,
		quantity: 120,
	},
	{
		garage_id: 84,
		part_id: 128,
		quantity: 359,
	},
	{
		garage_id: 84,
		part_id: 129,
		quantity: 131,
	},
	{
		garage_id: 84,
		part_id: 130,
		quantity: 10,
	},
	{
		garage_id: 84,
		part_id: 131,
		quantity: 240,
	},
	{
		garage_id: 84,
		part_id: 132,
		quantity: 168,
	},
	{
		garage_id: 84,
		part_id: 133,
		quantity: 195,
	},
	{
		garage_id: 84,
		part_id: 134,
		quantity: 385,
	},
	{
		garage_id: 84,
		part_id: 135,
		quantity: 198,
	},
	{
		garage_id: 84,
		part_id: 136,
		quantity: 58,
	},
	{
		garage_id: 84,
		part_id: 137,
		quantity: 434,
	},
	{
		garage_id: 84,
		part_id: 138,
		quantity: 119,
	},
	{
		garage_id: 84,
		part_id: 139,
		quantity: 481,
	},
	{
		garage_id: 84,
		part_id: 140,
		quantity: 391,
	},
	{
		garage_id: 84,
		part_id: 141,
		quantity: 348,
	},
	{
		garage_id: 84,
		part_id: 142,
		quantity: 50,
	},
	{
		garage_id: 84,
		part_id: 143,
		quantity: 422,
	},
	{
		garage_id: 84,
		part_id: 144,
		quantity: 96,
	},
	{
		garage_id: 84,
		part_id: 145,
		quantity: 241,
	},
	{
		garage_id: 84,
		part_id: 146,
		quantity: 265,
	},
	{
		garage_id: 84,
		part_id: 147,
		quantity: 437,
	},
	{
		garage_id: 84,
		part_id: 148,
		quantity: 155,
	},
	{
		garage_id: 84,
		part_id: 149,
		quantity: 121,
	},
	{
		garage_id: 84,
		part_id: 150,
		quantity: 94,
	},
	{
		garage_id: 84,
		part_id: 151,
		quantity: 442,
	},
	{
		garage_id: 84,
		part_id: 152,
		quantity: 207,
	},
	{
		garage_id: 84,
		part_id: 153,
		quantity: 26,
	},
	{
		garage_id: 84,
		part_id: 154,
		quantity: 27,
	},
	{
		garage_id: 84,
		part_id: 155,
		quantity: 108,
	},
	{
		garage_id: 84,
		part_id: 156,
		quantity: 142,
	},
	{
		garage_id: 84,
		part_id: 157,
		quantity: 71,
	},
	{
		garage_id: 84,
		part_id: 158,
		quantity: 291,
	},
	{
		garage_id: 84,
		part_id: 159,
		quantity: 93,
	},
	{
		garage_id: 84,
		part_id: 160,
		quantity: 276,
	},
	{
		garage_id: 84,
		part_id: 161,
		quantity: 200,
	},
	{
		garage_id: 84,
		part_id: 162,
		quantity: 43,
	},
	{
		garage_id: 84,
		part_id: 163,
		quantity: 133,
	},
	{
		garage_id: 84,
		part_id: 164,
		quantity: 303,
	},
	{
		garage_id: 84,
		part_id: 165,
		quantity: 85,
	},
	{
		garage_id: 84,
		part_id: 166,
		quantity: 296,
	},
	{
		garage_id: 84,
		part_id: 167,
		quantity: 36,
	},
	{
		garage_id: 84,
		part_id: 168,
		quantity: 330,
	},
	{
		garage_id: 84,
		part_id: 169,
		quantity: 473,
	},
	{
		garage_id: 84,
		part_id: 170,
		quantity: 441,
	},
	{
		garage_id: 84,
		part_id: 171,
		quantity: 397,
	},
	{
		garage_id: 84,
		part_id: 172,
		quantity: 225,
	},
	{
		garage_id: 84,
		part_id: 173,
		quantity: 489,
	},
	{
		garage_id: 84,
		part_id: 174,
		quantity: 180,
	},
	{
		garage_id: 84,
		part_id: 175,
		quantity: 83,
	},
	{
		garage_id: 84,
		part_id: 176,
		quantity: 319,
	},
	{
		garage_id: 84,
		part_id: 177,
		quantity: 484,
	},
	{
		garage_id: 84,
		part_id: 178,
		quantity: 278,
	},
	{
		garage_id: 84,
		part_id: 179,
		quantity: 296,
	},
	{
		garage_id: 84,
		part_id: 180,
		quantity: 221,
	},
	{
		garage_id: 84,
		part_id: 181,
		quantity: 300,
	},
	{
		garage_id: 84,
		part_id: 182,
		quantity: 203,
	},
	{
		garage_id: 84,
		part_id: 183,
		quantity: 42,
	},
	{
		garage_id: 84,
		part_id: 184,
		quantity: 369,
	},
	{
		garage_id: 84,
		part_id: 185,
		quantity: 327,
	},
	{
		garage_id: 84,
		part_id: 186,
		quantity: 256,
	},
	{
		garage_id: 84,
		part_id: 187,
		quantity: 497,
	},
	{
		garage_id: 84,
		part_id: 188,
		quantity: 368,
	},
	{
		garage_id: 84,
		part_id: 189,
		quantity: 469,
	},
	{
		garage_id: 84,
		part_id: 190,
		quantity: 187,
	},
	{
		garage_id: 84,
		part_id: 191,
		quantity: 360,
	},
	{
		garage_id: 84,
		part_id: 192,
		quantity: 451,
	},
	{
		garage_id: 84,
		part_id: 193,
		quantity: 359,
	},
	{
		garage_id: 84,
		part_id: 194,
		quantity: 30,
	},
	{
		garage_id: 84,
		part_id: 195,
		quantity: 92,
	},
	{
		garage_id: 84,
		part_id: 196,
		quantity: 271,
	},
	{
		garage_id: 84,
		part_id: 197,
		quantity: 401,
	},
	{
		garage_id: 84,
		part_id: 198,
		quantity: 280,
	},
	{
		garage_id: 84,
		part_id: 199,
		quantity: 226,
	},
	{
		garage_id: 84,
		part_id: 200,
		quantity: 6,
	},
	{
		garage_id: 84,
		part_id: 201,
		quantity: 99,
	},
	{
		garage_id: 84,
		part_id: 202,
		quantity: 448,
	},
	{
		garage_id: 84,
		part_id: 203,
		quantity: 103,
	},
	{
		garage_id: 84,
		part_id: 204,
		quantity: 435,
	},
	{
		garage_id: 84,
		part_id: 205,
		quantity: 391,
	},
	{
		garage_id: 84,
		part_id: 206,
		quantity: 255,
	},
	{
		garage_id: 84,
		part_id: 207,
		quantity: 111,
	},
	{
		garage_id: 84,
		part_id: 208,
		quantity: 215,
	},
	{
		garage_id: 84,
		part_id: 209,
		quantity: 103,
	},
	{
		garage_id: 84,
		part_id: 210,
		quantity: 210,
	},
	{
		garage_id: 84,
		part_id: 211,
		quantity: 163,
	},
	{
		garage_id: 84,
		part_id: 212,
		quantity: 74,
	},
	{
		garage_id: 84,
		part_id: 213,
		quantity: 366,
	},
	{
		garage_id: 84,
		part_id: 214,
		quantity: 371,
	},
	{
		garage_id: 84,
		part_id: 215,
		quantity: 484,
	},
	{
		garage_id: 84,
		part_id: 216,
		quantity: 181,
	},
	{
		garage_id: 84,
		part_id: 217,
		quantity: 461,
	},
	{
		garage_id: 84,
		part_id: 218,
		quantity: 477,
	},
	{
		garage_id: 84,
		part_id: 219,
		quantity: 308,
	},
	{
		garage_id: 84,
		part_id: 220,
		quantity: 144,
	},
	{
		garage_id: 84,
		part_id: 221,
		quantity: 468,
	},
	{
		garage_id: 84,
		part_id: 222,
		quantity: 217,
	},
	{
		garage_id: 84,
		part_id: 223,
		quantity: 317,
	},
	{
		garage_id: 84,
		part_id: 224,
		quantity: 491,
	},
	{
		garage_id: 84,
		part_id: 225,
		quantity: 222,
	},
	{
		garage_id: 84,
		part_id: 226,
		quantity: 23,
	},
	{
		garage_id: 84,
		part_id: 227,
		quantity: 124,
	},
	{
		garage_id: 84,
		part_id: 228,
		quantity: 102,
	},
	{
		garage_id: 84,
		part_id: 229,
		quantity: 401,
	},
	{
		garage_id: 84,
		part_id: 230,
		quantity: 50,
	},
	{
		garage_id: 84,
		part_id: 231,
		quantity: 412,
	},
	{
		garage_id: 84,
		part_id: 232,
		quantity: 133,
	},
	{
		garage_id: 84,
		part_id: 233,
		quantity: 24,
	},
	{
		garage_id: 84,
		part_id: 234,
		quantity: 308,
	},
	{
		garage_id: 84,
		part_id: 235,
		quantity: 491,
	},
	{
		garage_id: 84,
		part_id: 236,
		quantity: 363,
	},
	{
		garage_id: 84,
		part_id: 237,
		quantity: 481,
	},
	{
		garage_id: 84,
		part_id: 238,
		quantity: 381,
	},
	{
		garage_id: 84,
		part_id: 239,
		quantity: 494,
	},
	{
		garage_id: 84,
		part_id: 240,
		quantity: 320,
	},
	{
		garage_id: 84,
		part_id: 241,
		quantity: 351,
	},
	{
		garage_id: 84,
		part_id: 242,
		quantity: 138,
	},
	{
		garage_id: 84,
		part_id: 243,
		quantity: 323,
	},
	{
		garage_id: 84,
		part_id: 244,
		quantity: 146,
	},
	{
		garage_id: 84,
		part_id: 245,
		quantity: 19,
	},
	{
		garage_id: 84,
		part_id: 246,
		quantity: 123,
	},
	{
		garage_id: 84,
		part_id: 247,
		quantity: 195,
	},
	{
		garage_id: 84,
		part_id: 248,
		quantity: 329,
	},
	{
		garage_id: 84,
		part_id: 249,
		quantity: 437,
	},
	{
		garage_id: 84,
		part_id: 250,
		quantity: 341,
	},
	{
		garage_id: 84,
		part_id: 251,
		quantity: 264,
	},
	{
		garage_id: 84,
		part_id: 252,
		quantity: 142,
	},
	{
		garage_id: 84,
		part_id: 253,
		quantity: 52,
	},
	{
		garage_id: 84,
		part_id: 254,
		quantity: 289,
	},
	{
		garage_id: 84,
		part_id: 255,
		quantity: 88,
	},
	{
		garage_id: 84,
		part_id: 256,
		quantity: 118,
	},
	{
		garage_id: 84,
		part_id: 257,
		quantity: 151,
	},
	{
		garage_id: 84,
		part_id: 258,
		quantity: 100,
	},
	{
		garage_id: 84,
		part_id: 259,
		quantity: 276,
	},
	{
		garage_id: 84,
		part_id: 260,
		quantity: 178,
	},
	{
		garage_id: 84,
		part_id: 261,
		quantity: 216,
	},
	{
		garage_id: 84,
		part_id: 262,
		quantity: 337,
	},
	{
		garage_id: 84,
		part_id: 263,
		quantity: 326,
	},
	{
		garage_id: 84,
		part_id: 264,
		quantity: 123,
	},
	{
		garage_id: 84,
		part_id: 265,
		quantity: 435,
	},
	{
		garage_id: 84,
		part_id: 266,
		quantity: 344,
	},
	{
		garage_id: 84,
		part_id: 267,
		quantity: 293,
	},
	{
		garage_id: 84,
		part_id: 268,
		quantity: 425,
	},
	{
		garage_id: 84,
		part_id: 269,
		quantity: 17,
	},
	{
		garage_id: 84,
		part_id: 270,
		quantity: 371,
	},
	{
		garage_id: 84,
		part_id: 271,
		quantity: 412,
	},
	{
		garage_id: 84,
		part_id: 272,
		quantity: 497,
	},
	{
		garage_id: 84,
		part_id: 273,
		quantity: 239,
	},
	{
		garage_id: 84,
		part_id: 274,
		quantity: 169,
	},
	{
		garage_id: 84,
		part_id: 275,
		quantity: 400,
	},
	{
		garage_id: 84,
		part_id: 276,
		quantity: 323,
	},
	{
		garage_id: 84,
		part_id: 277,
		quantity: 76,
	},
	{
		garage_id: 84,
		part_id: 278,
		quantity: 163,
	},
	{
		garage_id: 84,
		part_id: 279,
		quantity: 3,
	},
	{
		garage_id: 84,
		part_id: 280,
		quantity: 351,
	},
	{
		garage_id: 84,
		part_id: 281,
		quantity: 114,
	},
	{
		garage_id: 84,
		part_id: 282,
		quantity: 247,
	},
	{
		garage_id: 84,
		part_id: 283,
		quantity: 254,
	},
	{
		garage_id: 84,
		part_id: 284,
		quantity: 252,
	},
	{
		garage_id: 84,
		part_id: 285,
		quantity: 209,
	},
	{
		garage_id: 84,
		part_id: 286,
		quantity: 446,
	},
	{
		garage_id: 84,
		part_id: 287,
		quantity: 397,
	},
	{
		garage_id: 84,
		part_id: 288,
		quantity: 170,
	},
	{
		garage_id: 84,
		part_id: 289,
		quantity: 276,
	},
	{
		garage_id: 84,
		part_id: 290,
		quantity: 166,
	},
	{
		garage_id: 84,
		part_id: 291,
		quantity: 3,
	},
	{
		garage_id: 84,
		part_id: 292,
		quantity: 334,
	},
	{
		garage_id: 84,
		part_id: 293,
		quantity: 116,
	},
	{
		garage_id: 84,
		part_id: 294,
		quantity: 125,
	},
	{
		garage_id: 84,
		part_id: 295,
		quantity: 192,
	},
	{
		garage_id: 84,
		part_id: 296,
		quantity: 216,
	},
	{
		garage_id: 84,
		part_id: 297,
		quantity: 204,
	},
	{
		garage_id: 84,
		part_id: 298,
		quantity: 161,
	},
	{
		garage_id: 84,
		part_id: 299,
		quantity: 105,
	},
	{
		garage_id: 84,
		part_id: 300,
		quantity: 400,
	},
	{
		garage_id: 84,
		part_id: 301,
		quantity: 272,
	},
	{
		garage_id: 84,
		part_id: 302,
		quantity: 496,
	},
	{
		garage_id: 84,
		part_id: 303,
		quantity: 140,
	},
	{
		garage_id: 84,
		part_id: 304,
		quantity: 140,
	},
	{
		garage_id: 84,
		part_id: 305,
		quantity: 95,
	},
	{
		garage_id: 84,
		part_id: 306,
		quantity: 330,
	},
	{
		garage_id: 84,
		part_id: 307,
		quantity: 453,
	},
	{
		garage_id: 84,
		part_id: 308,
		quantity: 217,
	},
	{
		garage_id: 84,
		part_id: 309,
		quantity: 325,
	},
	{
		garage_id: 84,
		part_id: 310,
		quantity: 322,
	},
	{
		garage_id: 84,
		part_id: 311,
		quantity: 356,
	},
	{
		garage_id: 84,
		part_id: 312,
		quantity: 71,
	},
	{
		garage_id: 84,
		part_id: 313,
		quantity: 343,
	},
	{
		garage_id: 84,
		part_id: 314,
		quantity: 40,
	},
	{
		garage_id: 84,
		part_id: 315,
		quantity: 173,
	},
	{
		garage_id: 84,
		part_id: 316,
		quantity: 492,
	},
	{
		garage_id: 84,
		part_id: 317,
		quantity: 141,
	},
	{
		garage_id: 84,
		part_id: 318,
		quantity: 126,
	},
	{
		garage_id: 84,
		part_id: 319,
		quantity: 467,
	},
	{
		garage_id: 84,
		part_id: 320,
		quantity: 285,
	},
	{
		garage_id: 84,
		part_id: 321,
		quantity: 171,
	},
	{
		garage_id: 84,
		part_id: 322,
		quantity: 115,
	},
	{
		garage_id: 84,
		part_id: 323,
		quantity: 27,
	},
	{
		garage_id: 84,
		part_id: 324,
		quantity: 157,
	},
	{
		garage_id: 84,
		part_id: 325,
		quantity: 62,
	},
	{
		garage_id: 84,
		part_id: 326,
		quantity: 258,
	},
	{
		garage_id: 84,
		part_id: 327,
		quantity: 346,
	},
	{
		garage_id: 84,
		part_id: 328,
		quantity: 256,
	},
	{
		garage_id: 84,
		part_id: 329,
		quantity: 444,
	},
	{
		garage_id: 84,
		part_id: 330,
		quantity: 250,
	},
	{
		garage_id: 84,
		part_id: 331,
		quantity: 39,
	},
	{
		garage_id: 84,
		part_id: 332,
		quantity: 61,
	},
	{
		garage_id: 84,
		part_id: 333,
		quantity: 386,
	},
	{
		garage_id: 84,
		part_id: 334,
		quantity: 339,
	},
	{
		garage_id: 84,
		part_id: 335,
		quantity: 228,
	},
	{
		garage_id: 84,
		part_id: 336,
		quantity: 364,
	},
	{
		garage_id: 84,
		part_id: 337,
		quantity: 25,
	},
	{
		garage_id: 84,
		part_id: 338,
		quantity: 88,
	},
	{
		garage_id: 84,
		part_id: 339,
		quantity: 102,
	},
	{
		garage_id: 84,
		part_id: 340,
		quantity: 374,
	},
	{
		garage_id: 84,
		part_id: 341,
		quantity: 446,
	},
	{
		garage_id: 84,
		part_id: 342,
		quantity: 320,
	},
	{
		garage_id: 84,
		part_id: 343,
		quantity: 97,
	},
	{
		garage_id: 84,
		part_id: 344,
		quantity: 440,
	},
	{
		garage_id: 84,
		part_id: 345,
		quantity: 301,
	},
	{
		garage_id: 84,
		part_id: 346,
		quantity: 355,
	},
	{
		garage_id: 84,
		part_id: 347,
		quantity: 88,
	},
	{
		garage_id: 84,
		part_id: 348,
		quantity: 39,
	},
	{
		garage_id: 84,
		part_id: 349,
		quantity: 281,
	},
	{
		garage_id: 84,
		part_id: 350,
		quantity: 177,
	},
	{
		garage_id: 84,
		part_id: 351,
		quantity: 110,
	},
	{
		garage_id: 84,
		part_id: 352,
		quantity: 241,
	},
	{
		garage_id: 84,
		part_id: 353,
		quantity: 356,
	},
	{
		garage_id: 84,
		part_id: 354,
		quantity: 456,
	},
	{
		garage_id: 84,
		part_id: 355,
		quantity: 19,
	},
	{
		garage_id: 84,
		part_id: 356,
		quantity: 126,
	},
	{
		garage_id: 84,
		part_id: 357,
		quantity: 4,
	},
	{
		garage_id: 84,
		part_id: 358,
		quantity: 249,
	},
	{
		garage_id: 84,
		part_id: 359,
		quantity: 152,
	},
	{
		garage_id: 84,
		part_id: 360,
		quantity: 439,
	},
	{
		garage_id: 84,
		part_id: 361,
		quantity: 80,
	},
	{
		garage_id: 84,
		part_id: 362,
		quantity: 238,
	},
	{
		garage_id: 84,
		part_id: 363,
		quantity: 405,
	},
	{
		garage_id: 84,
		part_id: 364,
		quantity: 88,
	},
	{
		garage_id: 84,
		part_id: 365,
		quantity: 350,
	},
	{
		garage_id: 84,
		part_id: 366,
		quantity: 428,
	},
	{
		garage_id: 84,
		part_id: 367,
		quantity: 341,
	},
	{
		garage_id: 84,
		part_id: 368,
		quantity: 391,
	},
	{
		garage_id: 84,
		part_id: 369,
		quantity: 88,
	},
	{
		garage_id: 84,
		part_id: 370,
		quantity: 285,
	},
	{
		garage_id: 84,
		part_id: 371,
		quantity: 499,
	},
	{
		garage_id: 84,
		part_id: 372,
		quantity: 260,
	},
	{
		garage_id: 84,
		part_id: 373,
		quantity: 9,
	},
	{
		garage_id: 84,
		part_id: 374,
		quantity: 309,
	},
	{
		garage_id: 84,
		part_id: 375,
		quantity: 321,
	},
	{
		garage_id: 84,
		part_id: 376,
		quantity: 207,
	},
	{
		garage_id: 84,
		part_id: 377,
		quantity: 410,
	},
	{
		garage_id: 84,
		part_id: 378,
		quantity: 323,
	},
	{
		garage_id: 84,
		part_id: 379,
		quantity: 271,
	},
	{
		garage_id: 84,
		part_id: 380,
		quantity: 370,
	},
	{
		garage_id: 84,
		part_id: 381,
		quantity: 422,
	},
	{
		garage_id: 84,
		part_id: 382,
		quantity: 22,
	},
	{
		garage_id: 84,
		part_id: 383,
		quantity: 254,
	},
	{
		garage_id: 84,
		part_id: 384,
		quantity: 483,
	},
	{
		garage_id: 84,
		part_id: 385,
		quantity: 181,
	},
	{
		garage_id: 84,
		part_id: 386,
		quantity: 293,
	},
	{
		garage_id: 84,
		part_id: 387,
		quantity: 96,
	},
	{
		garage_id: 84,
		part_id: 388,
		quantity: 266,
	},
	{
		garage_id: 84,
		part_id: 389,
		quantity: 413,
	},
	{
		garage_id: 84,
		part_id: 390,
		quantity: 119,
	},
	{
		garage_id: 84,
		part_id: 391,
		quantity: 315,
	},
	{
		garage_id: 84,
		part_id: 392,
		quantity: 64,
	},
	{
		garage_id: 84,
		part_id: 393,
		quantity: 204,
	},
	{
		garage_id: 84,
		part_id: 394,
		quantity: 106,
	},
	{
		garage_id: 84,
		part_id: 395,
		quantity: 93,
	},
	{
		garage_id: 84,
		part_id: 396,
		quantity: 496,
	},
	{
		garage_id: 84,
		part_id: 397,
		quantity: 170,
	},
	{
		garage_id: 84,
		part_id: 398,
		quantity: 6,
	},
	{
		garage_id: 84,
		part_id: 399,
		quantity: 345,
	},
	{
		garage_id: 85,
		part_id: 0,
		quantity: 479,
	},
	{
		garage_id: 85,
		part_id: 1,
		quantity: 126,
	},
	{
		garage_id: 85,
		part_id: 2,
		quantity: 283,
	},
	{
		garage_id: 85,
		part_id: 3,
		quantity: 445,
	},
	{
		garage_id: 85,
		part_id: 4,
		quantity: 202,
	},
	{
		garage_id: 85,
		part_id: 5,
		quantity: 458,
	},
	{
		garage_id: 85,
		part_id: 6,
		quantity: 273,
	},
	{
		garage_id: 85,
		part_id: 7,
		quantity: 331,
	},
	{
		garage_id: 85,
		part_id: 8,
		quantity: 70,
	},
	{
		garage_id: 85,
		part_id: 9,
		quantity: 480,
	},
	{
		garage_id: 85,
		part_id: 10,
		quantity: 228,
	},
	{
		garage_id: 85,
		part_id: 11,
		quantity: 195,
	},
	{
		garage_id: 85,
		part_id: 12,
		quantity: 10,
	},
	{
		garage_id: 85,
		part_id: 13,
		quantity: 309,
	},
	{
		garage_id: 85,
		part_id: 14,
		quantity: 124,
	},
	{
		garage_id: 85,
		part_id: 15,
		quantity: 391,
	},
	{
		garage_id: 85,
		part_id: 16,
		quantity: 314,
	},
	{
		garage_id: 85,
		part_id: 17,
		quantity: 256,
	},
	{
		garage_id: 85,
		part_id: 18,
		quantity: 454,
	},
	{
		garage_id: 85,
		part_id: 19,
		quantity: 434,
	},
	{
		garage_id: 85,
		part_id: 20,
		quantity: 436,
	},
	{
		garage_id: 85,
		part_id: 21,
		quantity: 404,
	},
	{
		garage_id: 85,
		part_id: 22,
		quantity: 5,
	},
	{
		garage_id: 85,
		part_id: 23,
		quantity: 115,
	},
	{
		garage_id: 85,
		part_id: 24,
		quantity: 146,
	},
	{
		garage_id: 85,
		part_id: 25,
		quantity: 297,
	},
	{
		garage_id: 85,
		part_id: 26,
		quantity: 256,
	},
	{
		garage_id: 85,
		part_id: 27,
		quantity: 161,
	},
	{
		garage_id: 85,
		part_id: 28,
		quantity: 407,
	},
	{
		garage_id: 85,
		part_id: 29,
		quantity: 64,
	},
	{
		garage_id: 85,
		part_id: 30,
		quantity: 20,
	},
	{
		garage_id: 85,
		part_id: 31,
		quantity: 191,
	},
	{
		garage_id: 85,
		part_id: 32,
		quantity: 236,
	},
	{
		garage_id: 85,
		part_id: 33,
		quantity: 98,
	},
	{
		garage_id: 85,
		part_id: 34,
		quantity: 464,
	},
	{
		garage_id: 85,
		part_id: 35,
		quantity: 223,
	},
	{
		garage_id: 85,
		part_id: 36,
		quantity: 138,
	},
	{
		garage_id: 85,
		part_id: 37,
		quantity: 246,
	},
	{
		garage_id: 85,
		part_id: 38,
		quantity: 241,
	},
	{
		garage_id: 85,
		part_id: 39,
		quantity: 410,
	},
	{
		garage_id: 85,
		part_id: 40,
		quantity: 43,
	},
	{
		garage_id: 85,
		part_id: 41,
		quantity: 180,
	},
	{
		garage_id: 85,
		part_id: 42,
		quantity: 13,
	},
	{
		garage_id: 85,
		part_id: 43,
		quantity: 70,
	},
	{
		garage_id: 85,
		part_id: 44,
		quantity: 409,
	},
	{
		garage_id: 85,
		part_id: 45,
		quantity: 230,
	},
	{
		garage_id: 85,
		part_id: 46,
		quantity: 113,
	},
	{
		garage_id: 85,
		part_id: 47,
		quantity: 87,
	},
	{
		garage_id: 85,
		part_id: 48,
		quantity: 422,
	},
	{
		garage_id: 85,
		part_id: 49,
		quantity: 77,
	},
	{
		garage_id: 85,
		part_id: 50,
		quantity: 82,
	},
	{
		garage_id: 85,
		part_id: 51,
		quantity: 132,
	},
	{
		garage_id: 85,
		part_id: 52,
		quantity: 119,
	},
	{
		garage_id: 85,
		part_id: 53,
		quantity: 24,
	},
	{
		garage_id: 85,
		part_id: 54,
		quantity: 364,
	},
	{
		garage_id: 85,
		part_id: 55,
		quantity: 318,
	},
	{
		garage_id: 85,
		part_id: 56,
		quantity: 447,
	},
	{
		garage_id: 85,
		part_id: 57,
		quantity: 147,
	},
	{
		garage_id: 85,
		part_id: 58,
		quantity: 182,
	},
	{
		garage_id: 85,
		part_id: 59,
		quantity: 286,
	},
	{
		garage_id: 85,
		part_id: 60,
		quantity: 61,
	},
	{
		garage_id: 85,
		part_id: 61,
		quantity: 8,
	},
	{
		garage_id: 85,
		part_id: 62,
		quantity: 389,
	},
	{
		garage_id: 85,
		part_id: 63,
		quantity: 472,
	},
	{
		garage_id: 85,
		part_id: 64,
		quantity: 279,
	},
	{
		garage_id: 85,
		part_id: 65,
		quantity: 425,
	},
	{
		garage_id: 85,
		part_id: 66,
		quantity: 182,
	},
	{
		garage_id: 85,
		part_id: 67,
		quantity: 386,
	},
	{
		garage_id: 85,
		part_id: 68,
		quantity: 463,
	},
	{
		garage_id: 85,
		part_id: 69,
		quantity: 445,
	},
	{
		garage_id: 85,
		part_id: 70,
		quantity: 267,
	},
	{
		garage_id: 85,
		part_id: 71,
		quantity: 181,
	},
	{
		garage_id: 85,
		part_id: 72,
		quantity: 262,
	},
	{
		garage_id: 85,
		part_id: 73,
		quantity: 94,
	},
	{
		garage_id: 85,
		part_id: 74,
		quantity: 443,
	},
	{
		garage_id: 85,
		part_id: 75,
		quantity: 451,
	},
	{
		garage_id: 85,
		part_id: 76,
		quantity: 453,
	},
	{
		garage_id: 85,
		part_id: 77,
		quantity: 172,
	},
	{
		garage_id: 85,
		part_id: 78,
		quantity: 120,
	},
	{
		garage_id: 85,
		part_id: 79,
		quantity: 288,
	},
	{
		garage_id: 85,
		part_id: 80,
		quantity: 136,
	},
	{
		garage_id: 85,
		part_id: 81,
		quantity: 241,
	},
	{
		garage_id: 85,
		part_id: 82,
		quantity: 477,
	},
	{
		garage_id: 85,
		part_id: 83,
		quantity: 259,
	},
	{
		garage_id: 85,
		part_id: 84,
		quantity: 464,
	},
	{
		garage_id: 85,
		part_id: 85,
		quantity: 285,
	},
	{
		garage_id: 85,
		part_id: 86,
		quantity: 482,
	},
	{
		garage_id: 85,
		part_id: 87,
		quantity: 55,
	},
	{
		garage_id: 85,
		part_id: 88,
		quantity: 471,
	},
	{
		garage_id: 85,
		part_id: 89,
		quantity: 285,
	},
	{
		garage_id: 85,
		part_id: 90,
		quantity: 131,
	},
	{
		garage_id: 85,
		part_id: 91,
		quantity: 232,
	},
	{
		garage_id: 85,
		part_id: 92,
		quantity: 283,
	},
	{
		garage_id: 85,
		part_id: 93,
		quantity: 455,
	},
	{
		garage_id: 85,
		part_id: 94,
		quantity: 156,
	},
	{
		garage_id: 85,
		part_id: 95,
		quantity: 381,
	},
	{
		garage_id: 85,
		part_id: 96,
		quantity: 302,
	},
	{
		garage_id: 85,
		part_id: 97,
		quantity: 296,
	},
	{
		garage_id: 85,
		part_id: 98,
		quantity: 124,
	},
	{
		garage_id: 85,
		part_id: 99,
		quantity: 448,
	},
	{
		garage_id: 85,
		part_id: 100,
		quantity: 369,
	},
	{
		garage_id: 85,
		part_id: 101,
		quantity: 194,
	},
	{
		garage_id: 85,
		part_id: 102,
		quantity: 285,
	},
	{
		garage_id: 85,
		part_id: 103,
		quantity: 371,
	},
	{
		garage_id: 85,
		part_id: 104,
		quantity: 435,
	},
	{
		garage_id: 85,
		part_id: 105,
		quantity: 260,
	},
	{
		garage_id: 85,
		part_id: 106,
		quantity: 107,
	},
	{
		garage_id: 85,
		part_id: 107,
		quantity: 410,
	},
	{
		garage_id: 85,
		part_id: 108,
		quantity: 468,
	},
	{
		garage_id: 85,
		part_id: 109,
		quantity: 232,
	},
	{
		garage_id: 85,
		part_id: 110,
		quantity: 219,
	},
	{
		garage_id: 85,
		part_id: 111,
		quantity: 83,
	},
	{
		garage_id: 85,
		part_id: 112,
		quantity: 423,
	},
	{
		garage_id: 85,
		part_id: 113,
		quantity: 494,
	},
	{
		garage_id: 85,
		part_id: 114,
		quantity: 218,
	},
	{
		garage_id: 85,
		part_id: 115,
		quantity: 447,
	},
	{
		garage_id: 85,
		part_id: 116,
		quantity: 190,
	},
	{
		garage_id: 85,
		part_id: 117,
		quantity: 494,
	},
	{
		garage_id: 85,
		part_id: 118,
		quantity: 332,
	},
	{
		garage_id: 85,
		part_id: 119,
		quantity: 31,
	},
	{
		garage_id: 85,
		part_id: 120,
		quantity: 392,
	},
	{
		garage_id: 85,
		part_id: 121,
		quantity: 255,
	},
	{
		garage_id: 85,
		part_id: 122,
		quantity: 208,
	},
	{
		garage_id: 85,
		part_id: 123,
		quantity: 134,
	},
	{
		garage_id: 85,
		part_id: 124,
		quantity: 313,
	},
	{
		garage_id: 85,
		part_id: 125,
		quantity: 110,
	},
	{
		garage_id: 85,
		part_id: 126,
		quantity: 299,
	},
	{
		garage_id: 85,
		part_id: 127,
		quantity: 427,
	},
	{
		garage_id: 85,
		part_id: 128,
		quantity: 252,
	},
	{
		garage_id: 85,
		part_id: 129,
		quantity: 409,
	},
	{
		garage_id: 85,
		part_id: 130,
		quantity: 356,
	},
	{
		garage_id: 85,
		part_id: 131,
		quantity: 79,
	},
	{
		garage_id: 85,
		part_id: 132,
		quantity: 391,
	},
	{
		garage_id: 85,
		part_id: 133,
		quantity: 420,
	},
	{
		garage_id: 85,
		part_id: 134,
		quantity: 111,
	},
	{
		garage_id: 85,
		part_id: 135,
		quantity: 436,
	},
	{
		garage_id: 85,
		part_id: 136,
		quantity: 302,
	},
	{
		garage_id: 85,
		part_id: 137,
		quantity: 493,
	},
	{
		garage_id: 85,
		part_id: 138,
		quantity: 31,
	},
	{
		garage_id: 85,
		part_id: 139,
		quantity: 81,
	},
	{
		garage_id: 85,
		part_id: 140,
		quantity: 426,
	},
	{
		garage_id: 85,
		part_id: 141,
		quantity: 458,
	},
	{
		garage_id: 85,
		part_id: 142,
		quantity: 245,
	},
	{
		garage_id: 85,
		part_id: 143,
		quantity: 3,
	},
	{
		garage_id: 85,
		part_id: 144,
		quantity: 397,
	},
	{
		garage_id: 85,
		part_id: 145,
		quantity: 299,
	},
	{
		garage_id: 85,
		part_id: 146,
		quantity: 469,
	},
	{
		garage_id: 85,
		part_id: 147,
		quantity: 354,
	},
	{
		garage_id: 85,
		part_id: 148,
		quantity: 128,
	},
	{
		garage_id: 85,
		part_id: 149,
		quantity: 267,
	},
	{
		garage_id: 85,
		part_id: 150,
		quantity: 465,
	},
	{
		garage_id: 85,
		part_id: 151,
		quantity: 446,
	},
	{
		garage_id: 85,
		part_id: 152,
		quantity: 364,
	},
	{
		garage_id: 85,
		part_id: 153,
		quantity: 362,
	},
	{
		garage_id: 85,
		part_id: 154,
		quantity: 191,
	},
	{
		garage_id: 85,
		part_id: 155,
		quantity: 426,
	},
	{
		garage_id: 85,
		part_id: 156,
		quantity: 424,
	},
	{
		garage_id: 85,
		part_id: 157,
		quantity: 153,
	},
	{
		garage_id: 85,
		part_id: 158,
		quantity: 353,
	},
	{
		garage_id: 85,
		part_id: 159,
		quantity: 364,
	},
	{
		garage_id: 85,
		part_id: 160,
		quantity: 183,
	},
	{
		garage_id: 85,
		part_id: 161,
		quantity: 373,
	},
	{
		garage_id: 85,
		part_id: 162,
		quantity: 134,
	},
	{
		garage_id: 85,
		part_id: 163,
		quantity: 444,
	},
	{
		garage_id: 85,
		part_id: 164,
		quantity: 55,
	},
	{
		garage_id: 85,
		part_id: 165,
		quantity: 134,
	},
	{
		garage_id: 85,
		part_id: 166,
		quantity: 314,
	},
	{
		garage_id: 85,
		part_id: 167,
		quantity: 55,
	},
	{
		garage_id: 85,
		part_id: 168,
		quantity: 52,
	},
	{
		garage_id: 85,
		part_id: 169,
		quantity: 205,
	},
	{
		garage_id: 85,
		part_id: 170,
		quantity: 259,
	},
	{
		garage_id: 85,
		part_id: 171,
		quantity: 303,
	},
	{
		garage_id: 85,
		part_id: 172,
		quantity: 394,
	},
	{
		garage_id: 85,
		part_id: 173,
		quantity: 249,
	},
	{
		garage_id: 85,
		part_id: 174,
		quantity: 220,
	},
	{
		garage_id: 85,
		part_id: 175,
		quantity: 466,
	},
	{
		garage_id: 85,
		part_id: 176,
		quantity: 269,
	},
	{
		garage_id: 85,
		part_id: 177,
		quantity: 364,
	},
	{
		garage_id: 85,
		part_id: 178,
		quantity: 107,
	},
	{
		garage_id: 85,
		part_id: 179,
		quantity: 22,
	},
	{
		garage_id: 85,
		part_id: 180,
		quantity: 128,
	},
	{
		garage_id: 85,
		part_id: 181,
		quantity: 1,
	},
	{
		garage_id: 85,
		part_id: 182,
		quantity: 134,
	},
	{
		garage_id: 85,
		part_id: 183,
		quantity: 327,
	},
	{
		garage_id: 85,
		part_id: 184,
		quantity: 315,
	},
	{
		garage_id: 85,
		part_id: 185,
		quantity: 180,
	},
	{
		garage_id: 85,
		part_id: 186,
		quantity: 388,
	},
	{
		garage_id: 85,
		part_id: 187,
		quantity: 368,
	},
	{
		garage_id: 85,
		part_id: 188,
		quantity: 176,
	},
	{
		garage_id: 85,
		part_id: 189,
		quantity: 435,
	},
	{
		garage_id: 85,
		part_id: 190,
		quantity: 283,
	},
	{
		garage_id: 85,
		part_id: 191,
		quantity: 104,
	},
	{
		garage_id: 85,
		part_id: 192,
		quantity: 443,
	},
	{
		garage_id: 85,
		part_id: 193,
		quantity: 60,
	},
	{
		garage_id: 85,
		part_id: 194,
		quantity: 289,
	},
	{
		garage_id: 85,
		part_id: 195,
		quantity: 461,
	},
	{
		garage_id: 85,
		part_id: 196,
		quantity: 66,
	},
	{
		garage_id: 85,
		part_id: 197,
		quantity: 291,
	},
	{
		garage_id: 85,
		part_id: 198,
		quantity: 71,
	},
	{
		garage_id: 85,
		part_id: 199,
		quantity: 232,
	},
	{
		garage_id: 85,
		part_id: 200,
		quantity: 409,
	},
	{
		garage_id: 85,
		part_id: 201,
		quantity: 483,
	},
	{
		garage_id: 85,
		part_id: 202,
		quantity: 108,
	},
	{
		garage_id: 85,
		part_id: 203,
		quantity: 15,
	},
	{
		garage_id: 85,
		part_id: 204,
		quantity: 488,
	},
	{
		garage_id: 85,
		part_id: 205,
		quantity: 74,
	},
	{
		garage_id: 85,
		part_id: 206,
		quantity: 306,
	},
	{
		garage_id: 85,
		part_id: 207,
		quantity: 142,
	},
	{
		garage_id: 85,
		part_id: 208,
		quantity: 175,
	},
	{
		garage_id: 85,
		part_id: 209,
		quantity: 252,
	},
	{
		garage_id: 85,
		part_id: 210,
		quantity: 279,
	},
	{
		garage_id: 85,
		part_id: 211,
		quantity: 495,
	},
	{
		garage_id: 85,
		part_id: 212,
		quantity: 149,
	},
	{
		garage_id: 85,
		part_id: 213,
		quantity: 314,
	},
	{
		garage_id: 85,
		part_id: 214,
		quantity: 123,
	},
	{
		garage_id: 85,
		part_id: 215,
		quantity: 21,
	},
	{
		garage_id: 85,
		part_id: 216,
		quantity: 361,
	},
	{
		garage_id: 85,
		part_id: 217,
		quantity: 319,
	},
	{
		garage_id: 85,
		part_id: 218,
		quantity: 140,
	},
	{
		garage_id: 85,
		part_id: 219,
		quantity: 447,
	},
	{
		garage_id: 85,
		part_id: 220,
		quantity: 82,
	},
	{
		garage_id: 85,
		part_id: 221,
		quantity: 432,
	},
	{
		garage_id: 85,
		part_id: 222,
		quantity: 32,
	},
	{
		garage_id: 85,
		part_id: 223,
		quantity: 372,
	},
	{
		garage_id: 85,
		part_id: 224,
		quantity: 199,
	},
	{
		garage_id: 85,
		part_id: 225,
		quantity: 178,
	},
	{
		garage_id: 85,
		part_id: 226,
		quantity: 292,
	},
	{
		garage_id: 85,
		part_id: 227,
		quantity: 99,
	},
	{
		garage_id: 85,
		part_id: 228,
		quantity: 52,
	},
	{
		garage_id: 85,
		part_id: 229,
		quantity: 186,
	},
	{
		garage_id: 85,
		part_id: 230,
		quantity: 460,
	},
	{
		garage_id: 85,
		part_id: 231,
		quantity: 321,
	},
	{
		garage_id: 85,
		part_id: 232,
		quantity: 388,
	},
	{
		garage_id: 85,
		part_id: 233,
		quantity: 125,
	},
	{
		garage_id: 85,
		part_id: 234,
		quantity: 304,
	},
	{
		garage_id: 85,
		part_id: 235,
		quantity: 312,
	},
	{
		garage_id: 85,
		part_id: 236,
		quantity: 9,
	},
	{
		garage_id: 85,
		part_id: 237,
		quantity: 16,
	},
	{
		garage_id: 85,
		part_id: 238,
		quantity: 8,
	},
	{
		garage_id: 85,
		part_id: 239,
		quantity: 430,
	},
	{
		garage_id: 85,
		part_id: 240,
		quantity: 323,
	},
	{
		garage_id: 85,
		part_id: 241,
		quantity: 341,
	},
	{
		garage_id: 85,
		part_id: 242,
		quantity: 281,
	},
	{
		garage_id: 85,
		part_id: 243,
		quantity: 458,
	},
	{
		garage_id: 85,
		part_id: 244,
		quantity: 370,
	},
	{
		garage_id: 85,
		part_id: 245,
		quantity: 42,
	},
	{
		garage_id: 85,
		part_id: 246,
		quantity: 470,
	},
	{
		garage_id: 85,
		part_id: 247,
		quantity: 53,
	},
	{
		garage_id: 85,
		part_id: 248,
		quantity: 348,
	},
	{
		garage_id: 85,
		part_id: 249,
		quantity: 299,
	},
	{
		garage_id: 85,
		part_id: 250,
		quantity: 378,
	},
	{
		garage_id: 85,
		part_id: 251,
		quantity: 240,
	},
	{
		garage_id: 85,
		part_id: 252,
		quantity: 229,
	},
	{
		garage_id: 85,
		part_id: 253,
		quantity: 444,
	},
	{
		garage_id: 85,
		part_id: 254,
		quantity: 197,
	},
	{
		garage_id: 85,
		part_id: 255,
		quantity: 178,
	},
	{
		garage_id: 85,
		part_id: 256,
		quantity: 23,
	},
	{
		garage_id: 85,
		part_id: 257,
		quantity: 224,
	},
	{
		garage_id: 85,
		part_id: 258,
		quantity: 57,
	},
	{
		garage_id: 85,
		part_id: 259,
		quantity: 362,
	},
	{
		garage_id: 85,
		part_id: 260,
		quantity: 318,
	},
	{
		garage_id: 85,
		part_id: 261,
		quantity: 362,
	},
	{
		garage_id: 85,
		part_id: 262,
		quantity: 239,
	},
	{
		garage_id: 85,
		part_id: 263,
		quantity: 137,
	},
	{
		garage_id: 85,
		part_id: 264,
		quantity: 470,
	},
	{
		garage_id: 85,
		part_id: 265,
		quantity: 220,
	},
	{
		garage_id: 85,
		part_id: 266,
		quantity: 422,
	},
	{
		garage_id: 85,
		part_id: 267,
		quantity: 331,
	},
	{
		garage_id: 85,
		part_id: 268,
		quantity: 82,
	},
	{
		garage_id: 85,
		part_id: 269,
		quantity: 463,
	},
	{
		garage_id: 85,
		part_id: 270,
		quantity: 418,
	},
	{
		garage_id: 85,
		part_id: 271,
		quantity: 423,
	},
	{
		garage_id: 85,
		part_id: 272,
		quantity: 303,
	},
	{
		garage_id: 85,
		part_id: 273,
		quantity: 137,
	},
	{
		garage_id: 85,
		part_id: 274,
		quantity: 67,
	},
	{
		garage_id: 85,
		part_id: 275,
		quantity: 399,
	},
	{
		garage_id: 85,
		part_id: 276,
		quantity: 288,
	},
	{
		garage_id: 85,
		part_id: 277,
		quantity: 66,
	},
	{
		garage_id: 85,
		part_id: 278,
		quantity: 42,
	},
	{
		garage_id: 85,
		part_id: 279,
		quantity: 392,
	},
	{
		garage_id: 85,
		part_id: 280,
		quantity: 242,
	},
	{
		garage_id: 85,
		part_id: 281,
		quantity: 161,
	},
	{
		garage_id: 85,
		part_id: 282,
		quantity: 404,
	},
	{
		garage_id: 85,
		part_id: 283,
		quantity: 305,
	},
	{
		garage_id: 85,
		part_id: 284,
		quantity: 22,
	},
	{
		garage_id: 85,
		part_id: 285,
		quantity: 105,
	},
	{
		garage_id: 85,
		part_id: 286,
		quantity: 359,
	},
	{
		garage_id: 85,
		part_id: 287,
		quantity: 315,
	},
	{
		garage_id: 85,
		part_id: 288,
		quantity: 482,
	},
	{
		garage_id: 85,
		part_id: 289,
		quantity: 439,
	},
	{
		garage_id: 85,
		part_id: 290,
		quantity: 21,
	},
	{
		garage_id: 85,
		part_id: 291,
		quantity: 63,
	},
	{
		garage_id: 85,
		part_id: 292,
		quantity: 111,
	},
	{
		garage_id: 85,
		part_id: 293,
		quantity: 466,
	},
	{
		garage_id: 85,
		part_id: 294,
		quantity: 275,
	},
	{
		garage_id: 85,
		part_id: 295,
		quantity: 414,
	},
	{
		garage_id: 85,
		part_id: 296,
		quantity: 454,
	},
	{
		garage_id: 85,
		part_id: 297,
		quantity: 446,
	},
	{
		garage_id: 85,
		part_id: 298,
		quantity: 136,
	},
	{
		garage_id: 85,
		part_id: 299,
		quantity: 407,
	},
	{
		garage_id: 85,
		part_id: 300,
		quantity: 328,
	},
	{
		garage_id: 85,
		part_id: 301,
		quantity: 35,
	},
	{
		garage_id: 85,
		part_id: 302,
		quantity: 75,
	},
	{
		garage_id: 85,
		part_id: 303,
		quantity: 259,
	},
	{
		garage_id: 85,
		part_id: 304,
		quantity: 360,
	},
	{
		garage_id: 85,
		part_id: 305,
		quantity: 415,
	},
	{
		garage_id: 85,
		part_id: 306,
		quantity: 147,
	},
	{
		garage_id: 85,
		part_id: 307,
		quantity: 381,
	},
	{
		garage_id: 85,
		part_id: 308,
		quantity: 468,
	},
	{
		garage_id: 85,
		part_id: 309,
		quantity: 375,
	},
	{
		garage_id: 85,
		part_id: 310,
		quantity: 163,
	},
	{
		garage_id: 85,
		part_id: 311,
		quantity: 426,
	},
	{
		garage_id: 85,
		part_id: 312,
		quantity: 379,
	},
	{
		garage_id: 85,
		part_id: 313,
		quantity: 111,
	},
	{
		garage_id: 85,
		part_id: 314,
		quantity: 360,
	},
	{
		garage_id: 85,
		part_id: 315,
		quantity: 348,
	},
	{
		garage_id: 85,
		part_id: 316,
		quantity: 236,
	},
	{
		garage_id: 85,
		part_id: 317,
		quantity: 125,
	},
	{
		garage_id: 85,
		part_id: 318,
		quantity: 333,
	},
	{
		garage_id: 85,
		part_id: 319,
		quantity: 69,
	},
	{
		garage_id: 85,
		part_id: 320,
		quantity: 211,
	},
	{
		garage_id: 85,
		part_id: 321,
		quantity: 103,
	},
	{
		garage_id: 85,
		part_id: 322,
		quantity: 245,
	},
	{
		garage_id: 85,
		part_id: 323,
		quantity: 447,
	},
	{
		garage_id: 85,
		part_id: 324,
		quantity: 491,
	},
	{
		garage_id: 85,
		part_id: 325,
		quantity: 252,
	},
	{
		garage_id: 85,
		part_id: 326,
		quantity: 112,
	},
	{
		garage_id: 85,
		part_id: 327,
		quantity: 140,
	},
	{
		garage_id: 85,
		part_id: 328,
		quantity: 122,
	},
	{
		garage_id: 85,
		part_id: 329,
		quantity: 22,
	},
	{
		garage_id: 85,
		part_id: 330,
		quantity: 398,
	},
	{
		garage_id: 85,
		part_id: 331,
		quantity: 419,
	},
	{
		garage_id: 85,
		part_id: 332,
		quantity: 100,
	},
	{
		garage_id: 85,
		part_id: 333,
		quantity: 456,
	},
	{
		garage_id: 85,
		part_id: 334,
		quantity: 172,
	},
	{
		garage_id: 85,
		part_id: 335,
		quantity: 149,
	},
	{
		garage_id: 85,
		part_id: 336,
		quantity: 141,
	},
	{
		garage_id: 85,
		part_id: 337,
		quantity: 472,
	},
	{
		garage_id: 85,
		part_id: 338,
		quantity: 392,
	},
	{
		garage_id: 85,
		part_id: 339,
		quantity: 131,
	},
	{
		garage_id: 85,
		part_id: 340,
		quantity: 103,
	},
	{
		garage_id: 85,
		part_id: 341,
		quantity: 252,
	},
	{
		garage_id: 85,
		part_id: 342,
		quantity: 253,
	},
	{
		garage_id: 85,
		part_id: 343,
		quantity: 432,
	},
	{
		garage_id: 85,
		part_id: 344,
		quantity: 420,
	},
	{
		garage_id: 85,
		part_id: 345,
		quantity: 175,
	},
	{
		garage_id: 85,
		part_id: 346,
		quantity: 367,
	},
	{
		garage_id: 85,
		part_id: 347,
		quantity: 423,
	},
	{
		garage_id: 85,
		part_id: 348,
		quantity: 363,
	},
	{
		garage_id: 85,
		part_id: 349,
		quantity: 397,
	},
	{
		garage_id: 85,
		part_id: 350,
		quantity: 184,
	},
	{
		garage_id: 85,
		part_id: 351,
		quantity: 144,
	},
	{
		garage_id: 85,
		part_id: 352,
		quantity: 340,
	},
	{
		garage_id: 85,
		part_id: 353,
		quantity: 101,
	},
	{
		garage_id: 85,
		part_id: 354,
		quantity: 252,
	},
	{
		garage_id: 85,
		part_id: 355,
		quantity: 8,
	},
	{
		garage_id: 85,
		part_id: 356,
		quantity: 202,
	},
	{
		garage_id: 85,
		part_id: 357,
		quantity: 293,
	},
	{
		garage_id: 85,
		part_id: 358,
		quantity: 387,
	},
	{
		garage_id: 85,
		part_id: 359,
		quantity: 185,
	},
	{
		garage_id: 85,
		part_id: 360,
		quantity: 325,
	},
	{
		garage_id: 85,
		part_id: 361,
		quantity: 434,
	},
	{
		garage_id: 85,
		part_id: 362,
		quantity: 157,
	},
	{
		garage_id: 85,
		part_id: 363,
		quantity: 179,
	},
	{
		garage_id: 85,
		part_id: 364,
		quantity: 447,
	},
	{
		garage_id: 85,
		part_id: 365,
		quantity: 302,
	},
	{
		garage_id: 85,
		part_id: 366,
		quantity: 365,
	},
	{
		garage_id: 85,
		part_id: 367,
		quantity: 175,
	},
	{
		garage_id: 85,
		part_id: 368,
		quantity: 304,
	},
	{
		garage_id: 85,
		part_id: 369,
		quantity: 223,
	},
	{
		garage_id: 85,
		part_id: 370,
		quantity: 374,
	},
	{
		garage_id: 85,
		part_id: 371,
		quantity: 33,
	},
	{
		garage_id: 85,
		part_id: 372,
		quantity: 60,
	},
	{
		garage_id: 85,
		part_id: 373,
		quantity: 135,
	},
	{
		garage_id: 85,
		part_id: 374,
		quantity: 407,
	},
	{
		garage_id: 85,
		part_id: 375,
		quantity: 410,
	},
	{
		garage_id: 85,
		part_id: 376,
		quantity: 445,
	},
	{
		garage_id: 85,
		part_id: 377,
		quantity: 156,
	},
	{
		garage_id: 85,
		part_id: 378,
		quantity: 319,
	},
	{
		garage_id: 85,
		part_id: 379,
		quantity: 482,
	},
	{
		garage_id: 85,
		part_id: 380,
		quantity: 456,
	},
	{
		garage_id: 85,
		part_id: 381,
		quantity: 495,
	},
	{
		garage_id: 85,
		part_id: 382,
		quantity: 335,
	},
	{
		garage_id: 85,
		part_id: 383,
		quantity: 306,
	},
	{
		garage_id: 85,
		part_id: 384,
		quantity: 185,
	},
	{
		garage_id: 85,
		part_id: 385,
		quantity: 5,
	},
	{
		garage_id: 85,
		part_id: 386,
		quantity: 2,
	},
	{
		garage_id: 85,
		part_id: 387,
		quantity: 201,
	},
	{
		garage_id: 85,
		part_id: 388,
		quantity: 1,
	},
	{
		garage_id: 85,
		part_id: 389,
		quantity: 27,
	},
	{
		garage_id: 85,
		part_id: 390,
		quantity: 380,
	},
	{
		garage_id: 85,
		part_id: 391,
		quantity: 18,
	},
	{
		garage_id: 85,
		part_id: 392,
		quantity: 455,
	},
	{
		garage_id: 85,
		part_id: 393,
		quantity: 85,
	},
	{
		garage_id: 85,
		part_id: 394,
		quantity: 64,
	},
	{
		garage_id: 85,
		part_id: 395,
		quantity: 189,
	},
	{
		garage_id: 85,
		part_id: 396,
		quantity: 283,
	},
	{
		garage_id: 85,
		part_id: 397,
		quantity: 175,
	},
	{
		garage_id: 85,
		part_id: 398,
		quantity: 196,
	},
	{
		garage_id: 85,
		part_id: 399,
		quantity: 447,
	},
	{
		garage_id: 86,
		part_id: 0,
		quantity: 216,
	},
	{
		garage_id: 86,
		part_id: 1,
		quantity: 136,
	},
	{
		garage_id: 86,
		part_id: 2,
		quantity: 440,
	},
	{
		garage_id: 86,
		part_id: 3,
		quantity: 210,
	},
	{
		garage_id: 86,
		part_id: 4,
		quantity: 9,
	},
	{
		garage_id: 86,
		part_id: 5,
		quantity: 446,
	},
	{
		garage_id: 86,
		part_id: 6,
		quantity: 98,
	},
	{
		garage_id: 86,
		part_id: 7,
		quantity: 75,
	},
	{
		garage_id: 86,
		part_id: 8,
		quantity: 281,
	},
	{
		garage_id: 86,
		part_id: 9,
		quantity: 234,
	},
	{
		garage_id: 86,
		part_id: 10,
		quantity: 251,
	},
	{
		garage_id: 86,
		part_id: 11,
		quantity: 4,
	},
	{
		garage_id: 86,
		part_id: 12,
		quantity: 163,
	},
	{
		garage_id: 86,
		part_id: 13,
		quantity: 352,
	},
	{
		garage_id: 86,
		part_id: 14,
		quantity: 212,
	},
	{
		garage_id: 86,
		part_id: 15,
		quantity: 326,
	},
	{
		garage_id: 86,
		part_id: 16,
		quantity: 473,
	},
	{
		garage_id: 86,
		part_id: 17,
		quantity: 146,
	},
	{
		garage_id: 86,
		part_id: 18,
		quantity: 342,
	},
	{
		garage_id: 86,
		part_id: 19,
		quantity: 189,
	},
	{
		garage_id: 86,
		part_id: 20,
		quantity: 70,
	},
	{
		garage_id: 86,
		part_id: 21,
		quantity: 317,
	},
	{
		garage_id: 86,
		part_id: 22,
		quantity: 357,
	},
	{
		garage_id: 86,
		part_id: 23,
		quantity: 192,
	},
	{
		garage_id: 86,
		part_id: 24,
		quantity: 341,
	},
	{
		garage_id: 86,
		part_id: 25,
		quantity: 229,
	},
	{
		garage_id: 86,
		part_id: 26,
		quantity: 365,
	},
	{
		garage_id: 86,
		part_id: 27,
		quantity: 271,
	},
	{
		garage_id: 86,
		part_id: 28,
		quantity: 422,
	},
	{
		garage_id: 86,
		part_id: 29,
		quantity: 401,
	},
	{
		garage_id: 86,
		part_id: 30,
		quantity: 77,
	},
	{
		garage_id: 86,
		part_id: 31,
		quantity: 147,
	},
	{
		garage_id: 86,
		part_id: 32,
		quantity: 319,
	},
	{
		garage_id: 86,
		part_id: 33,
		quantity: 408,
	},
	{
		garage_id: 86,
		part_id: 34,
		quantity: 397,
	},
	{
		garage_id: 86,
		part_id: 35,
		quantity: 482,
	},
	{
		garage_id: 86,
		part_id: 36,
		quantity: 403,
	},
	{
		garage_id: 86,
		part_id: 37,
		quantity: 276,
	},
	{
		garage_id: 86,
		part_id: 38,
		quantity: 482,
	},
	{
		garage_id: 86,
		part_id: 39,
		quantity: 137,
	},
	{
		garage_id: 86,
		part_id: 40,
		quantity: 337,
	},
	{
		garage_id: 86,
		part_id: 41,
		quantity: 124,
	},
	{
		garage_id: 86,
		part_id: 42,
		quantity: 151,
	},
	{
		garage_id: 86,
		part_id: 43,
		quantity: 274,
	},
	{
		garage_id: 86,
		part_id: 44,
		quantity: 431,
	},
	{
		garage_id: 86,
		part_id: 45,
		quantity: 276,
	},
	{
		garage_id: 86,
		part_id: 46,
		quantity: 43,
	},
	{
		garage_id: 86,
		part_id: 47,
		quantity: 431,
	},
	{
		garage_id: 86,
		part_id: 48,
		quantity: 202,
	},
	{
		garage_id: 86,
		part_id: 49,
		quantity: 160,
	},
	{
		garage_id: 86,
		part_id: 50,
		quantity: 64,
	},
	{
		garage_id: 86,
		part_id: 51,
		quantity: 418,
	},
	{
		garage_id: 86,
		part_id: 52,
		quantity: 271,
	},
	{
		garage_id: 86,
		part_id: 53,
		quantity: 27,
	},
	{
		garage_id: 86,
		part_id: 54,
		quantity: 235,
	},
	{
		garage_id: 86,
		part_id: 55,
		quantity: 228,
	},
	{
		garage_id: 86,
		part_id: 56,
		quantity: 126,
	},
	{
		garage_id: 86,
		part_id: 57,
		quantity: 398,
	},
	{
		garage_id: 86,
		part_id: 58,
		quantity: 156,
	},
	{
		garage_id: 86,
		part_id: 59,
		quantity: 463,
	},
	{
		garage_id: 86,
		part_id: 60,
		quantity: 164,
	},
	{
		garage_id: 86,
		part_id: 61,
		quantity: 377,
	},
	{
		garage_id: 86,
		part_id: 62,
		quantity: 472,
	},
	{
		garage_id: 86,
		part_id: 63,
		quantity: 49,
	},
	{
		garage_id: 86,
		part_id: 64,
		quantity: 125,
	},
	{
		garage_id: 86,
		part_id: 65,
		quantity: 15,
	},
	{
		garage_id: 86,
		part_id: 66,
		quantity: 65,
	},
	{
		garage_id: 86,
		part_id: 67,
		quantity: 167,
	},
	{
		garage_id: 86,
		part_id: 68,
		quantity: 208,
	},
	{
		garage_id: 86,
		part_id: 69,
		quantity: 71,
	},
	{
		garage_id: 86,
		part_id: 70,
		quantity: 204,
	},
	{
		garage_id: 86,
		part_id: 71,
		quantity: 492,
	},
	{
		garage_id: 86,
		part_id: 72,
		quantity: 418,
	},
	{
		garage_id: 86,
		part_id: 73,
		quantity: 22,
	},
	{
		garage_id: 86,
		part_id: 74,
		quantity: 271,
	},
	{
		garage_id: 86,
		part_id: 75,
		quantity: 118,
	},
	{
		garage_id: 86,
		part_id: 76,
		quantity: 479,
	},
	{
		garage_id: 86,
		part_id: 77,
		quantity: 181,
	},
	{
		garage_id: 86,
		part_id: 78,
		quantity: 318,
	},
	{
		garage_id: 86,
		part_id: 79,
		quantity: 109,
	},
	{
		garage_id: 86,
		part_id: 80,
		quantity: 353,
	},
	{
		garage_id: 86,
		part_id: 81,
		quantity: 316,
	},
	{
		garage_id: 86,
		part_id: 82,
		quantity: 306,
	},
	{
		garage_id: 86,
		part_id: 83,
		quantity: 139,
	},
	{
		garage_id: 86,
		part_id: 84,
		quantity: 449,
	},
	{
		garage_id: 86,
		part_id: 85,
		quantity: 377,
	},
	{
		garage_id: 86,
		part_id: 86,
		quantity: 132,
	},
	{
		garage_id: 86,
		part_id: 87,
		quantity: 19,
	},
	{
		garage_id: 86,
		part_id: 88,
		quantity: 307,
	},
	{
		garage_id: 86,
		part_id: 89,
		quantity: 243,
	},
	{
		garage_id: 86,
		part_id: 90,
		quantity: 73,
	},
	{
		garage_id: 86,
		part_id: 91,
		quantity: 246,
	},
	{
		garage_id: 86,
		part_id: 92,
		quantity: 301,
	},
	{
		garage_id: 86,
		part_id: 93,
		quantity: 130,
	},
	{
		garage_id: 86,
		part_id: 94,
		quantity: 215,
	},
	{
		garage_id: 86,
		part_id: 95,
		quantity: 478,
	},
	{
		garage_id: 86,
		part_id: 96,
		quantity: 401,
	},
	{
		garage_id: 86,
		part_id: 97,
		quantity: 282,
	},
	{
		garage_id: 86,
		part_id: 98,
		quantity: 353,
	},
	{
		garage_id: 86,
		part_id: 99,
		quantity: 469,
	},
	{
		garage_id: 86,
		part_id: 100,
		quantity: 149,
	},
	{
		garage_id: 86,
		part_id: 101,
		quantity: 312,
	},
	{
		garage_id: 86,
		part_id: 102,
		quantity: 174,
	},
	{
		garage_id: 86,
		part_id: 103,
		quantity: 126,
	},
	{
		garage_id: 86,
		part_id: 104,
		quantity: 343,
	},
	{
		garage_id: 86,
		part_id: 105,
		quantity: 96,
	},
	{
		garage_id: 86,
		part_id: 106,
		quantity: 31,
	},
	{
		garage_id: 86,
		part_id: 107,
		quantity: 466,
	},
	{
		garage_id: 86,
		part_id: 108,
		quantity: 397,
	},
	{
		garage_id: 86,
		part_id: 109,
		quantity: 24,
	},
	{
		garage_id: 86,
		part_id: 110,
		quantity: 165,
	},
	{
		garage_id: 86,
		part_id: 111,
		quantity: 10,
	},
	{
		garage_id: 86,
		part_id: 112,
		quantity: 223,
	},
	{
		garage_id: 86,
		part_id: 113,
		quantity: 281,
	},
	{
		garage_id: 86,
		part_id: 114,
		quantity: 424,
	},
	{
		garage_id: 86,
		part_id: 115,
		quantity: 78,
	},
	{
		garage_id: 86,
		part_id: 116,
		quantity: 201,
	},
	{
		garage_id: 86,
		part_id: 117,
		quantity: 444,
	},
	{
		garage_id: 86,
		part_id: 118,
		quantity: 59,
	},
	{
		garage_id: 86,
		part_id: 119,
		quantity: 204,
	},
	{
		garage_id: 86,
		part_id: 120,
		quantity: 120,
	},
	{
		garage_id: 86,
		part_id: 121,
		quantity: 178,
	},
	{
		garage_id: 86,
		part_id: 122,
		quantity: 310,
	},
	{
		garage_id: 86,
		part_id: 123,
		quantity: 126,
	},
	{
		garage_id: 86,
		part_id: 124,
		quantity: 65,
	},
	{
		garage_id: 86,
		part_id: 125,
		quantity: 403,
	},
	{
		garage_id: 86,
		part_id: 126,
		quantity: 94,
	},
	{
		garage_id: 86,
		part_id: 127,
		quantity: 254,
	},
	{
		garage_id: 86,
		part_id: 128,
		quantity: 251,
	},
	{
		garage_id: 86,
		part_id: 129,
		quantity: 482,
	},
	{
		garage_id: 86,
		part_id: 130,
		quantity: 370,
	},
	{
		garage_id: 86,
		part_id: 131,
		quantity: 255,
	},
	{
		garage_id: 86,
		part_id: 132,
		quantity: 234,
	},
	{
		garage_id: 86,
		part_id: 133,
		quantity: 165,
	},
	{
		garage_id: 86,
		part_id: 134,
		quantity: 239,
	},
	{
		garage_id: 86,
		part_id: 135,
		quantity: 207,
	},
	{
		garage_id: 86,
		part_id: 136,
		quantity: 375,
	},
	{
		garage_id: 86,
		part_id: 137,
		quantity: 113,
	},
	{
		garage_id: 86,
		part_id: 138,
		quantity: 197,
	},
	{
		garage_id: 86,
		part_id: 139,
		quantity: 321,
	},
	{
		garage_id: 86,
		part_id: 140,
		quantity: 3,
	},
	{
		garage_id: 86,
		part_id: 141,
		quantity: 34,
	},
	{
		garage_id: 86,
		part_id: 142,
		quantity: 265,
	},
	{
		garage_id: 86,
		part_id: 143,
		quantity: 247,
	},
	{
		garage_id: 86,
		part_id: 144,
		quantity: 422,
	},
	{
		garage_id: 86,
		part_id: 145,
		quantity: 69,
	},
	{
		garage_id: 86,
		part_id: 146,
		quantity: 353,
	},
	{
		garage_id: 86,
		part_id: 147,
		quantity: 429,
	},
	{
		garage_id: 86,
		part_id: 148,
		quantity: 268,
	},
	{
		garage_id: 86,
		part_id: 149,
		quantity: 47,
	},
	{
		garage_id: 86,
		part_id: 150,
		quantity: 77,
	},
	{
		garage_id: 86,
		part_id: 151,
		quantity: 300,
	},
	{
		garage_id: 86,
		part_id: 152,
		quantity: 500,
	},
	{
		garage_id: 86,
		part_id: 153,
		quantity: 476,
	},
	{
		garage_id: 86,
		part_id: 154,
		quantity: 409,
	},
	{
		garage_id: 86,
		part_id: 155,
		quantity: 467,
	},
	{
		garage_id: 86,
		part_id: 156,
		quantity: 412,
	},
	{
		garage_id: 86,
		part_id: 157,
		quantity: 210,
	},
	{
		garage_id: 86,
		part_id: 158,
		quantity: 52,
	},
	{
		garage_id: 86,
		part_id: 159,
		quantity: 120,
	},
	{
		garage_id: 86,
		part_id: 160,
		quantity: 459,
	},
	{
		garage_id: 86,
		part_id: 161,
		quantity: 355,
	},
	{
		garage_id: 86,
		part_id: 162,
		quantity: 171,
	},
	{
		garage_id: 86,
		part_id: 163,
		quantity: 238,
	},
	{
		garage_id: 86,
		part_id: 164,
		quantity: 393,
	},
	{
		garage_id: 86,
		part_id: 165,
		quantity: 348,
	},
	{
		garage_id: 86,
		part_id: 166,
		quantity: 307,
	},
	{
		garage_id: 86,
		part_id: 167,
		quantity: 362,
	},
	{
		garage_id: 86,
		part_id: 168,
		quantity: 316,
	},
	{
		garage_id: 86,
		part_id: 169,
		quantity: 197,
	},
	{
		garage_id: 86,
		part_id: 170,
		quantity: 143,
	},
	{
		garage_id: 86,
		part_id: 171,
		quantity: 4,
	},
	{
		garage_id: 86,
		part_id: 172,
		quantity: 438,
	},
	{
		garage_id: 86,
		part_id: 173,
		quantity: 333,
	},
	{
		garage_id: 86,
		part_id: 174,
		quantity: 369,
	},
	{
		garage_id: 86,
		part_id: 175,
		quantity: 14,
	},
	{
		garage_id: 86,
		part_id: 176,
		quantity: 204,
	},
	{
		garage_id: 86,
		part_id: 177,
		quantity: 120,
	},
	{
		garage_id: 86,
		part_id: 178,
		quantity: 418,
	},
	{
		garage_id: 86,
		part_id: 179,
		quantity: 115,
	},
	{
		garage_id: 86,
		part_id: 180,
		quantity: 186,
	},
	{
		garage_id: 86,
		part_id: 181,
		quantity: 190,
	},
	{
		garage_id: 86,
		part_id: 182,
		quantity: 231,
	},
	{
		garage_id: 86,
		part_id: 183,
		quantity: 280,
	},
	{
		garage_id: 86,
		part_id: 184,
		quantity: 186,
	},
	{
		garage_id: 86,
		part_id: 185,
		quantity: 4,
	},
	{
		garage_id: 86,
		part_id: 186,
		quantity: 440,
	},
	{
		garage_id: 86,
		part_id: 187,
		quantity: 117,
	},
	{
		garage_id: 86,
		part_id: 188,
		quantity: 394,
	},
	{
		garage_id: 86,
		part_id: 189,
		quantity: 286,
	},
	{
		garage_id: 86,
		part_id: 190,
		quantity: 295,
	},
	{
		garage_id: 86,
		part_id: 191,
		quantity: 154,
	},
	{
		garage_id: 86,
		part_id: 192,
		quantity: 144,
	},
	{
		garage_id: 86,
		part_id: 193,
		quantity: 380,
	},
	{
		garage_id: 86,
		part_id: 194,
		quantity: 476,
	},
	{
		garage_id: 86,
		part_id: 195,
		quantity: 292,
	},
	{
		garage_id: 86,
		part_id: 196,
		quantity: 358,
	},
	{
		garage_id: 86,
		part_id: 197,
		quantity: 292,
	},
	{
		garage_id: 86,
		part_id: 198,
		quantity: 500,
	},
	{
		garage_id: 86,
		part_id: 199,
		quantity: 60,
	},
	{
		garage_id: 86,
		part_id: 200,
		quantity: 93,
	},
	{
		garage_id: 86,
		part_id: 201,
		quantity: 220,
	},
	{
		garage_id: 86,
		part_id: 202,
		quantity: 487,
	},
	{
		garage_id: 86,
		part_id: 203,
		quantity: 44,
	},
	{
		garage_id: 86,
		part_id: 204,
		quantity: 465,
	},
	{
		garage_id: 86,
		part_id: 205,
		quantity: 479,
	},
	{
		garage_id: 86,
		part_id: 206,
		quantity: 25,
	},
	{
		garage_id: 86,
		part_id: 207,
		quantity: 75,
	},
	{
		garage_id: 86,
		part_id: 208,
		quantity: 189,
	},
	{
		garage_id: 86,
		part_id: 209,
		quantity: 429,
	},
	{
		garage_id: 86,
		part_id: 210,
		quantity: 140,
	},
	{
		garage_id: 86,
		part_id: 211,
		quantity: 275,
	},
	{
		garage_id: 86,
		part_id: 212,
		quantity: 341,
	},
	{
		garage_id: 86,
		part_id: 213,
		quantity: 268,
	},
	{
		garage_id: 86,
		part_id: 214,
		quantity: 187,
	},
	{
		garage_id: 86,
		part_id: 215,
		quantity: 238,
	},
	{
		garage_id: 86,
		part_id: 216,
		quantity: 403,
	},
	{
		garage_id: 86,
		part_id: 217,
		quantity: 165,
	},
	{
		garage_id: 86,
		part_id: 218,
		quantity: 77,
	},
	{
		garage_id: 86,
		part_id: 219,
		quantity: 388,
	},
	{
		garage_id: 86,
		part_id: 220,
		quantity: 71,
	},
	{
		garage_id: 86,
		part_id: 221,
		quantity: 231,
	},
	{
		garage_id: 86,
		part_id: 222,
		quantity: 43,
	},
	{
		garage_id: 86,
		part_id: 223,
		quantity: 273,
	},
	{
		garage_id: 86,
		part_id: 224,
		quantity: 178,
	},
	{
		garage_id: 86,
		part_id: 225,
		quantity: 415,
	},
	{
		garage_id: 86,
		part_id: 226,
		quantity: 58,
	},
	{
		garage_id: 86,
		part_id: 227,
		quantity: 353,
	},
	{
		garage_id: 86,
		part_id: 228,
		quantity: 328,
	},
	{
		garage_id: 86,
		part_id: 229,
		quantity: 268,
	},
	{
		garage_id: 86,
		part_id: 230,
		quantity: 198,
	},
	{
		garage_id: 86,
		part_id: 231,
		quantity: 359,
	},
	{
		garage_id: 86,
		part_id: 232,
		quantity: 49,
	},
	{
		garage_id: 86,
		part_id: 233,
		quantity: 302,
	},
	{
		garage_id: 86,
		part_id: 234,
		quantity: 482,
	},
	{
		garage_id: 86,
		part_id: 235,
		quantity: 191,
	},
	{
		garage_id: 86,
		part_id: 236,
		quantity: 435,
	},
	{
		garage_id: 86,
		part_id: 237,
		quantity: 324,
	},
	{
		garage_id: 86,
		part_id: 238,
		quantity: 375,
	},
	{
		garage_id: 86,
		part_id: 239,
		quantity: 251,
	},
	{
		garage_id: 86,
		part_id: 240,
		quantity: 499,
	},
	{
		garage_id: 86,
		part_id: 241,
		quantity: 489,
	},
	{
		garage_id: 86,
		part_id: 242,
		quantity: 56,
	},
	{
		garage_id: 86,
		part_id: 243,
		quantity: 486,
	},
	{
		garage_id: 86,
		part_id: 244,
		quantity: 411,
	},
	{
		garage_id: 86,
		part_id: 245,
		quantity: 352,
	},
	{
		garage_id: 86,
		part_id: 246,
		quantity: 133,
	},
	{
		garage_id: 86,
		part_id: 247,
		quantity: 58,
	},
	{
		garage_id: 86,
		part_id: 248,
		quantity: 461,
	},
	{
		garage_id: 86,
		part_id: 249,
		quantity: 156,
	},
	{
		garage_id: 86,
		part_id: 250,
		quantity: 238,
	},
	{
		garage_id: 86,
		part_id: 251,
		quantity: 297,
	},
	{
		garage_id: 86,
		part_id: 252,
		quantity: 405,
	},
	{
		garage_id: 86,
		part_id: 253,
		quantity: 132,
	},
	{
		garage_id: 86,
		part_id: 254,
		quantity: 161,
	},
	{
		garage_id: 86,
		part_id: 255,
		quantity: 126,
	},
	{
		garage_id: 86,
		part_id: 256,
		quantity: 154,
	},
	{
		garage_id: 86,
		part_id: 257,
		quantity: 116,
	},
	{
		garage_id: 86,
		part_id: 258,
		quantity: 486,
	},
	{
		garage_id: 86,
		part_id: 259,
		quantity: 48,
	},
	{
		garage_id: 86,
		part_id: 260,
		quantity: 500,
	},
	{
		garage_id: 86,
		part_id: 261,
		quantity: 66,
	},
	{
		garage_id: 86,
		part_id: 262,
		quantity: 100,
	},
	{
		garage_id: 86,
		part_id: 263,
		quantity: 8,
	},
	{
		garage_id: 86,
		part_id: 264,
		quantity: 395,
	},
	{
		garage_id: 86,
		part_id: 265,
		quantity: 70,
	},
	{
		garage_id: 86,
		part_id: 266,
		quantity: 497,
	},
	{
		garage_id: 86,
		part_id: 267,
		quantity: 73,
	},
	{
		garage_id: 86,
		part_id: 268,
		quantity: 55,
	},
	{
		garage_id: 86,
		part_id: 269,
		quantity: 188,
	},
	{
		garage_id: 86,
		part_id: 270,
		quantity: 492,
	},
	{
		garage_id: 86,
		part_id: 271,
		quantity: 430,
	},
	{
		garage_id: 86,
		part_id: 272,
		quantity: 58,
	},
	{
		garage_id: 86,
		part_id: 273,
		quantity: 452,
	},
	{
		garage_id: 86,
		part_id: 274,
		quantity: 234,
	},
	{
		garage_id: 86,
		part_id: 275,
		quantity: 491,
	},
	{
		garage_id: 86,
		part_id: 276,
		quantity: 178,
	},
	{
		garage_id: 86,
		part_id: 277,
		quantity: 467,
	},
	{
		garage_id: 86,
		part_id: 278,
		quantity: 462,
	},
	{
		garage_id: 86,
		part_id: 279,
		quantity: 59,
	},
	{
		garage_id: 86,
		part_id: 280,
		quantity: 123,
	},
	{
		garage_id: 86,
		part_id: 281,
		quantity: 189,
	},
	{
		garage_id: 86,
		part_id: 282,
		quantity: 298,
	},
	{
		garage_id: 86,
		part_id: 283,
		quantity: 69,
	},
	{
		garage_id: 86,
		part_id: 284,
		quantity: 335,
	},
	{
		garage_id: 86,
		part_id: 285,
		quantity: 454,
	},
	{
		garage_id: 86,
		part_id: 286,
		quantity: 155,
	},
	{
		garage_id: 86,
		part_id: 287,
		quantity: 207,
	},
	{
		garage_id: 86,
		part_id: 288,
		quantity: 409,
	},
	{
		garage_id: 86,
		part_id: 289,
		quantity: 428,
	},
	{
		garage_id: 86,
		part_id: 290,
		quantity: 277,
	},
	{
		garage_id: 86,
		part_id: 291,
		quantity: 53,
	},
	{
		garage_id: 86,
		part_id: 292,
		quantity: 465,
	},
	{
		garage_id: 86,
		part_id: 293,
		quantity: 77,
	},
	{
		garage_id: 86,
		part_id: 294,
		quantity: 497,
	},
	{
		garage_id: 86,
		part_id: 295,
		quantity: 29,
	},
	{
		garage_id: 86,
		part_id: 296,
		quantity: 296,
	},
	{
		garage_id: 86,
		part_id: 297,
		quantity: 165,
	},
	{
		garage_id: 86,
		part_id: 298,
		quantity: 499,
	},
	{
		garage_id: 86,
		part_id: 299,
		quantity: 64,
	},
	{
		garage_id: 86,
		part_id: 300,
		quantity: 12,
	},
	{
		garage_id: 86,
		part_id: 301,
		quantity: 168,
	},
	{
		garage_id: 86,
		part_id: 302,
		quantity: 301,
	},
	{
		garage_id: 86,
		part_id: 303,
		quantity: 350,
	},
	{
		garage_id: 86,
		part_id: 304,
		quantity: 415,
	},
	{
		garage_id: 86,
		part_id: 305,
		quantity: 376,
	},
	{
		garage_id: 86,
		part_id: 306,
		quantity: 46,
	},
	{
		garage_id: 86,
		part_id: 307,
		quantity: 328,
	},
	{
		garage_id: 86,
		part_id: 308,
		quantity: 393,
	},
	{
		garage_id: 86,
		part_id: 309,
		quantity: 230,
	},
	{
		garage_id: 86,
		part_id: 310,
		quantity: 256,
	},
	{
		garage_id: 86,
		part_id: 311,
		quantity: 482,
	},
	{
		garage_id: 86,
		part_id: 312,
		quantity: 146,
	},
	{
		garage_id: 86,
		part_id: 313,
		quantity: 90,
	},
	{
		garage_id: 86,
		part_id: 314,
		quantity: 284,
	},
	{
		garage_id: 86,
		part_id: 315,
		quantity: 456,
	},
	{
		garage_id: 86,
		part_id: 316,
		quantity: 314,
	},
	{
		garage_id: 86,
		part_id: 317,
		quantity: 427,
	},
	{
		garage_id: 86,
		part_id: 318,
		quantity: 379,
	},
	{
		garage_id: 86,
		part_id: 319,
		quantity: 335,
	},
	{
		garage_id: 86,
		part_id: 320,
		quantity: 128,
	},
	{
		garage_id: 86,
		part_id: 321,
		quantity: 257,
	},
	{
		garage_id: 86,
		part_id: 322,
		quantity: 250,
	},
	{
		garage_id: 86,
		part_id: 323,
		quantity: 296,
	},
	{
		garage_id: 86,
		part_id: 324,
		quantity: 221,
	},
	{
		garage_id: 86,
		part_id: 325,
		quantity: 463,
	},
	{
		garage_id: 86,
		part_id: 326,
		quantity: 429,
	},
	{
		garage_id: 86,
		part_id: 327,
		quantity: 161,
	},
	{
		garage_id: 86,
		part_id: 328,
		quantity: 165,
	},
	{
		garage_id: 86,
		part_id: 329,
		quantity: 191,
	},
	{
		garage_id: 86,
		part_id: 330,
		quantity: 287,
	},
	{
		garage_id: 86,
		part_id: 331,
		quantity: 151,
	},
	{
		garage_id: 86,
		part_id: 332,
		quantity: 480,
	},
	{
		garage_id: 86,
		part_id: 333,
		quantity: 251,
	},
	{
		garage_id: 86,
		part_id: 334,
		quantity: 60,
	},
	{
		garage_id: 86,
		part_id: 335,
		quantity: 120,
	},
	{
		garage_id: 86,
		part_id: 336,
		quantity: 127,
	},
	{
		garage_id: 86,
		part_id: 337,
		quantity: 199,
	},
	{
		garage_id: 86,
		part_id: 338,
		quantity: 56,
	},
	{
		garage_id: 86,
		part_id: 339,
		quantity: 113,
	},
	{
		garage_id: 86,
		part_id: 340,
		quantity: 319,
	},
	{
		garage_id: 86,
		part_id: 341,
		quantity: 480,
	},
	{
		garage_id: 86,
		part_id: 342,
		quantity: 351,
	},
	{
		garage_id: 86,
		part_id: 343,
		quantity: 407,
	},
	{
		garage_id: 86,
		part_id: 344,
		quantity: 428,
	},
	{
		garage_id: 86,
		part_id: 345,
		quantity: 355,
	},
	{
		garage_id: 86,
		part_id: 346,
		quantity: 46,
	},
	{
		garage_id: 86,
		part_id: 347,
		quantity: 33,
	},
	{
		garage_id: 86,
		part_id: 348,
		quantity: 477,
	},
	{
		garage_id: 86,
		part_id: 349,
		quantity: 184,
	},
	{
		garage_id: 86,
		part_id: 350,
		quantity: 189,
	},
	{
		garage_id: 86,
		part_id: 351,
		quantity: 243,
	},
	{
		garage_id: 86,
		part_id: 352,
		quantity: 474,
	},
	{
		garage_id: 86,
		part_id: 353,
		quantity: 194,
	},
	{
		garage_id: 86,
		part_id: 354,
		quantity: 109,
	},
	{
		garage_id: 86,
		part_id: 355,
		quantity: 4,
	},
	{
		garage_id: 86,
		part_id: 356,
		quantity: 196,
	},
	{
		garage_id: 86,
		part_id: 357,
		quantity: 172,
	},
	{
		garage_id: 86,
		part_id: 358,
		quantity: 409,
	},
	{
		garage_id: 86,
		part_id: 359,
		quantity: 237,
	},
	{
		garage_id: 86,
		part_id: 360,
		quantity: 5,
	},
	{
		garage_id: 86,
		part_id: 361,
		quantity: 107,
	},
	{
		garage_id: 86,
		part_id: 362,
		quantity: 95,
	},
	{
		garage_id: 86,
		part_id: 363,
		quantity: 337,
	},
	{
		garage_id: 86,
		part_id: 364,
		quantity: 344,
	},
	{
		garage_id: 86,
		part_id: 365,
		quantity: 219,
	},
	{
		garage_id: 86,
		part_id: 366,
		quantity: 46,
	},
	{
		garage_id: 86,
		part_id: 367,
		quantity: 467,
	},
	{
		garage_id: 86,
		part_id: 368,
		quantity: 289,
	},
	{
		garage_id: 86,
		part_id: 369,
		quantity: 257,
	},
	{
		garage_id: 86,
		part_id: 370,
		quantity: 304,
	},
	{
		garage_id: 86,
		part_id: 371,
		quantity: 21,
	},
	{
		garage_id: 86,
		part_id: 372,
		quantity: 203,
	},
	{
		garage_id: 86,
		part_id: 373,
		quantity: 227,
	},
	{
		garage_id: 86,
		part_id: 374,
		quantity: 119,
	},
	{
		garage_id: 86,
		part_id: 375,
		quantity: 258,
	},
	{
		garage_id: 86,
		part_id: 376,
		quantity: 321,
	},
	{
		garage_id: 86,
		part_id: 377,
		quantity: 470,
	},
	{
		garage_id: 86,
		part_id: 378,
		quantity: 428,
	},
	{
		garage_id: 86,
		part_id: 379,
		quantity: 353,
	},
	{
		garage_id: 86,
		part_id: 380,
		quantity: 353,
	},
	{
		garage_id: 86,
		part_id: 381,
		quantity: 194,
	},
	{
		garage_id: 86,
		part_id: 382,
		quantity: 459,
	},
	{
		garage_id: 86,
		part_id: 383,
		quantity: 197,
	},
	{
		garage_id: 86,
		part_id: 384,
		quantity: 297,
	},
	{
		garage_id: 86,
		part_id: 385,
		quantity: 113,
	},
	{
		garage_id: 86,
		part_id: 386,
		quantity: 92,
	},
	{
		garage_id: 86,
		part_id: 387,
		quantity: 210,
	},
	{
		garage_id: 86,
		part_id: 388,
		quantity: 306,
	},
	{
		garage_id: 86,
		part_id: 389,
		quantity: 420,
	},
	{
		garage_id: 86,
		part_id: 390,
		quantity: 355,
	},
	{
		garage_id: 86,
		part_id: 391,
		quantity: 66,
	},
	{
		garage_id: 86,
		part_id: 392,
		quantity: 384,
	},
	{
		garage_id: 86,
		part_id: 393,
		quantity: 269,
	},
	{
		garage_id: 86,
		part_id: 394,
		quantity: 15,
	},
	{
		garage_id: 86,
		part_id: 395,
		quantity: 254,
	},
	{
		garage_id: 86,
		part_id: 396,
		quantity: 73,
	},
	{
		garage_id: 86,
		part_id: 397,
		quantity: 87,
	},
	{
		garage_id: 86,
		part_id: 398,
		quantity: 72,
	},
	{
		garage_id: 86,
		part_id: 399,
		quantity: 316,
	},
	{
		garage_id: 87,
		part_id: 0,
		quantity: 287,
	},
	{
		garage_id: 87,
		part_id: 1,
		quantity: 325,
	},
	{
		garage_id: 87,
		part_id: 2,
		quantity: 58,
	},
	{
		garage_id: 87,
		part_id: 3,
		quantity: 447,
	},
	{
		garage_id: 87,
		part_id: 4,
		quantity: 81,
	},
	{
		garage_id: 87,
		part_id: 5,
		quantity: 489,
	},
	{
		garage_id: 87,
		part_id: 6,
		quantity: 356,
	},
	{
		garage_id: 87,
		part_id: 7,
		quantity: 405,
	},
	{
		garage_id: 87,
		part_id: 8,
		quantity: 144,
	},
	{
		garage_id: 87,
		part_id: 9,
		quantity: 362,
	},
	{
		garage_id: 87,
		part_id: 10,
		quantity: 489,
	},
	{
		garage_id: 87,
		part_id: 11,
		quantity: 355,
	},
	{
		garage_id: 87,
		part_id: 12,
		quantity: 451,
	},
	{
		garage_id: 87,
		part_id: 13,
		quantity: 327,
	},
	{
		garage_id: 87,
		part_id: 14,
		quantity: 265,
	},
	{
		garage_id: 87,
		part_id: 15,
		quantity: 307,
	},
	{
		garage_id: 87,
		part_id: 16,
		quantity: 485,
	},
	{
		garage_id: 87,
		part_id: 17,
		quantity: 150,
	},
	{
		garage_id: 87,
		part_id: 18,
		quantity: 154,
	},
	{
		garage_id: 87,
		part_id: 19,
		quantity: 21,
	},
	{
		garage_id: 87,
		part_id: 20,
		quantity: 191,
	},
	{
		garage_id: 87,
		part_id: 21,
		quantity: 116,
	},
	{
		garage_id: 87,
		part_id: 22,
		quantity: 460,
	},
	{
		garage_id: 87,
		part_id: 23,
		quantity: 172,
	},
	{
		garage_id: 87,
		part_id: 24,
		quantity: 475,
	},
	{
		garage_id: 87,
		part_id: 25,
		quantity: 124,
	},
	{
		garage_id: 87,
		part_id: 26,
		quantity: 346,
	},
	{
		garage_id: 87,
		part_id: 27,
		quantity: 216,
	},
	{
		garage_id: 87,
		part_id: 28,
		quantity: 26,
	},
	{
		garage_id: 87,
		part_id: 29,
		quantity: 128,
	},
	{
		garage_id: 87,
		part_id: 30,
		quantity: 335,
	},
	{
		garage_id: 87,
		part_id: 31,
		quantity: 468,
	},
	{
		garage_id: 87,
		part_id: 32,
		quantity: 262,
	},
	{
		garage_id: 87,
		part_id: 33,
		quantity: 487,
	},
	{
		garage_id: 87,
		part_id: 34,
		quantity: 170,
	},
	{
		garage_id: 87,
		part_id: 35,
		quantity: 407,
	},
	{
		garage_id: 87,
		part_id: 36,
		quantity: 176,
	},
	{
		garage_id: 87,
		part_id: 37,
		quantity: 433,
	},
	{
		garage_id: 87,
		part_id: 38,
		quantity: 209,
	},
	{
		garage_id: 87,
		part_id: 39,
		quantity: 248,
	},
	{
		garage_id: 87,
		part_id: 40,
		quantity: 457,
	},
	{
		garage_id: 87,
		part_id: 41,
		quantity: 126,
	},
	{
		garage_id: 87,
		part_id: 42,
		quantity: 306,
	},
	{
		garage_id: 87,
		part_id: 43,
		quantity: 185,
	},
	{
		garage_id: 87,
		part_id: 44,
		quantity: 115,
	},
	{
		garage_id: 87,
		part_id: 45,
		quantity: 286,
	},
	{
		garage_id: 87,
		part_id: 46,
		quantity: 231,
	},
	{
		garage_id: 87,
		part_id: 47,
		quantity: 136,
	},
	{
		garage_id: 87,
		part_id: 48,
		quantity: 387,
	},
	{
		garage_id: 87,
		part_id: 49,
		quantity: 389,
	},
	{
		garage_id: 87,
		part_id: 50,
		quantity: 453,
	},
	{
		garage_id: 87,
		part_id: 51,
		quantity: 344,
	},
	{
		garage_id: 87,
		part_id: 52,
		quantity: 471,
	},
	{
		garage_id: 87,
		part_id: 53,
		quantity: 194,
	},
	{
		garage_id: 87,
		part_id: 54,
		quantity: 129,
	},
	{
		garage_id: 87,
		part_id: 55,
		quantity: 135,
	},
	{
		garage_id: 87,
		part_id: 56,
		quantity: 32,
	},
	{
		garage_id: 87,
		part_id: 57,
		quantity: 394,
	},
	{
		garage_id: 87,
		part_id: 58,
		quantity: 34,
	},
	{
		garage_id: 87,
		part_id: 59,
		quantity: 99,
	},
	{
		garage_id: 87,
		part_id: 60,
		quantity: 386,
	},
	{
		garage_id: 87,
		part_id: 61,
		quantity: 403,
	},
	{
		garage_id: 87,
		part_id: 62,
		quantity: 482,
	},
	{
		garage_id: 87,
		part_id: 63,
		quantity: 97,
	},
	{
		garage_id: 87,
		part_id: 64,
		quantity: 312,
	},
	{
		garage_id: 87,
		part_id: 65,
		quantity: 378,
	},
	{
		garage_id: 87,
		part_id: 66,
		quantity: 227,
	},
	{
		garage_id: 87,
		part_id: 67,
		quantity: 100,
	},
	{
		garage_id: 87,
		part_id: 68,
		quantity: 223,
	},
	{
		garage_id: 87,
		part_id: 69,
		quantity: 297,
	},
	{
		garage_id: 87,
		part_id: 70,
		quantity: 36,
	},
	{
		garage_id: 87,
		part_id: 71,
		quantity: 53,
	},
	{
		garage_id: 87,
		part_id: 72,
		quantity: 345,
	},
	{
		garage_id: 87,
		part_id: 73,
		quantity: 236,
	},
	{
		garage_id: 87,
		part_id: 74,
		quantity: 321,
	},
	{
		garage_id: 87,
		part_id: 75,
		quantity: 190,
	},
	{
		garage_id: 87,
		part_id: 76,
		quantity: 188,
	},
	{
		garage_id: 87,
		part_id: 77,
		quantity: 238,
	},
	{
		garage_id: 87,
		part_id: 78,
		quantity: 349,
	},
	{
		garage_id: 87,
		part_id: 79,
		quantity: 378,
	},
	{
		garage_id: 87,
		part_id: 80,
		quantity: 134,
	},
	{
		garage_id: 87,
		part_id: 81,
		quantity: 65,
	},
	{
		garage_id: 87,
		part_id: 82,
		quantity: 500,
	},
	{
		garage_id: 87,
		part_id: 83,
		quantity: 34,
	},
	{
		garage_id: 87,
		part_id: 84,
		quantity: 232,
	},
	{
		garage_id: 87,
		part_id: 85,
		quantity: 113,
	},
	{
		garage_id: 87,
		part_id: 86,
		quantity: 324,
	},
	{
		garage_id: 87,
		part_id: 87,
		quantity: 17,
	},
	{
		garage_id: 87,
		part_id: 88,
		quantity: 398,
	},
	{
		garage_id: 87,
		part_id: 89,
		quantity: 280,
	},
	{
		garage_id: 87,
		part_id: 90,
		quantity: 0,
	},
	{
		garage_id: 87,
		part_id: 91,
		quantity: 214,
	},
	{
		garage_id: 87,
		part_id: 92,
		quantity: 268,
	},
	{
		garage_id: 87,
		part_id: 93,
		quantity: 211,
	},
	{
		garage_id: 87,
		part_id: 94,
		quantity: 144,
	},
	{
		garage_id: 87,
		part_id: 95,
		quantity: 17,
	},
	{
		garage_id: 87,
		part_id: 96,
		quantity: 407,
	},
	{
		garage_id: 87,
		part_id: 97,
		quantity: 125,
	},
	{
		garage_id: 87,
		part_id: 98,
		quantity: 166,
	},
	{
		garage_id: 87,
		part_id: 99,
		quantity: 213,
	},
	{
		garage_id: 87,
		part_id: 100,
		quantity: 357,
	},
	{
		garage_id: 87,
		part_id: 101,
		quantity: 343,
	},
	{
		garage_id: 87,
		part_id: 102,
		quantity: 105,
	},
	{
		garage_id: 87,
		part_id: 103,
		quantity: 22,
	},
	{
		garage_id: 87,
		part_id: 104,
		quantity: 321,
	},
	{
		garage_id: 87,
		part_id: 105,
		quantity: 245,
	},
	{
		garage_id: 87,
		part_id: 106,
		quantity: 253,
	},
	{
		garage_id: 87,
		part_id: 107,
		quantity: 490,
	},
	{
		garage_id: 87,
		part_id: 108,
		quantity: 96,
	},
	{
		garage_id: 87,
		part_id: 109,
		quantity: 416,
	},
	{
		garage_id: 87,
		part_id: 110,
		quantity: 294,
	},
	{
		garage_id: 87,
		part_id: 111,
		quantity: 76,
	},
	{
		garage_id: 87,
		part_id: 112,
		quantity: 465,
	},
	{
		garage_id: 87,
		part_id: 113,
		quantity: 451,
	},
	{
		garage_id: 87,
		part_id: 114,
		quantity: 404,
	},
	{
		garage_id: 87,
		part_id: 115,
		quantity: 157,
	},
	{
		garage_id: 87,
		part_id: 116,
		quantity: 389,
	},
	{
		garage_id: 87,
		part_id: 117,
		quantity: 441,
	},
	{
		garage_id: 87,
		part_id: 118,
		quantity: 437,
	},
	{
		garage_id: 87,
		part_id: 119,
		quantity: 225,
	},
	{
		garage_id: 87,
		part_id: 120,
		quantity: 466,
	},
	{
		garage_id: 87,
		part_id: 121,
		quantity: 207,
	},
	{
		garage_id: 87,
		part_id: 122,
		quantity: 191,
	},
	{
		garage_id: 87,
		part_id: 123,
		quantity: 81,
	},
	{
		garage_id: 87,
		part_id: 124,
		quantity: 366,
	},
	{
		garage_id: 87,
		part_id: 125,
		quantity: 388,
	},
	{
		garage_id: 87,
		part_id: 126,
		quantity: 163,
	},
	{
		garage_id: 87,
		part_id: 127,
		quantity: 96,
	},
	{
		garage_id: 87,
		part_id: 128,
		quantity: 439,
	},
	{
		garage_id: 87,
		part_id: 129,
		quantity: 259,
	},
	{
		garage_id: 87,
		part_id: 130,
		quantity: 435,
	},
	{
		garage_id: 87,
		part_id: 131,
		quantity: 217,
	},
	{
		garage_id: 87,
		part_id: 132,
		quantity: 459,
	},
	{
		garage_id: 87,
		part_id: 133,
		quantity: 375,
	},
	{
		garage_id: 87,
		part_id: 134,
		quantity: 462,
	},
	{
		garage_id: 87,
		part_id: 135,
		quantity: 367,
	},
	{
		garage_id: 87,
		part_id: 136,
		quantity: 99,
	},
	{
		garage_id: 87,
		part_id: 137,
		quantity: 429,
	},
	{
		garage_id: 87,
		part_id: 138,
		quantity: 8,
	},
	{
		garage_id: 87,
		part_id: 139,
		quantity: 211,
	},
	{
		garage_id: 87,
		part_id: 140,
		quantity: 109,
	},
	{
		garage_id: 87,
		part_id: 141,
		quantity: 403,
	},
	{
		garage_id: 87,
		part_id: 142,
		quantity: 42,
	},
	{
		garage_id: 87,
		part_id: 143,
		quantity: 188,
	},
	{
		garage_id: 87,
		part_id: 144,
		quantity: 117,
	},
	{
		garage_id: 87,
		part_id: 145,
		quantity: 323,
	},
	{
		garage_id: 87,
		part_id: 146,
		quantity: 485,
	},
	{
		garage_id: 87,
		part_id: 147,
		quantity: 416,
	},
	{
		garage_id: 87,
		part_id: 148,
		quantity: 295,
	},
	{
		garage_id: 87,
		part_id: 149,
		quantity: 388,
	},
	{
		garage_id: 87,
		part_id: 150,
		quantity: 66,
	},
	{
		garage_id: 87,
		part_id: 151,
		quantity: 322,
	},
	{
		garage_id: 87,
		part_id: 152,
		quantity: 60,
	},
	{
		garage_id: 87,
		part_id: 153,
		quantity: 12,
	},
	{
		garage_id: 87,
		part_id: 154,
		quantity: 403,
	},
	{
		garage_id: 87,
		part_id: 155,
		quantity: 53,
	},
	{
		garage_id: 87,
		part_id: 156,
		quantity: 313,
	},
	{
		garage_id: 87,
		part_id: 157,
		quantity: 293,
	},
	{
		garage_id: 87,
		part_id: 158,
		quantity: 235,
	},
	{
		garage_id: 87,
		part_id: 159,
		quantity: 127,
	},
	{
		garage_id: 87,
		part_id: 160,
		quantity: 405,
	},
	{
		garage_id: 87,
		part_id: 161,
		quantity: 402,
	},
	{
		garage_id: 87,
		part_id: 162,
		quantity: 436,
	},
	{
		garage_id: 87,
		part_id: 163,
		quantity: 375,
	},
	{
		garage_id: 87,
		part_id: 164,
		quantity: 238,
	},
	{
		garage_id: 87,
		part_id: 165,
		quantity: 295,
	},
	{
		garage_id: 87,
		part_id: 166,
		quantity: 58,
	},
	{
		garage_id: 87,
		part_id: 167,
		quantity: 191,
	},
	{
		garage_id: 87,
		part_id: 168,
		quantity: 342,
	},
	{
		garage_id: 87,
		part_id: 169,
		quantity: 235,
	},
	{
		garage_id: 87,
		part_id: 170,
		quantity: 400,
	},
	{
		garage_id: 87,
		part_id: 171,
		quantity: 147,
	},
	{
		garage_id: 87,
		part_id: 172,
		quantity: 412,
	},
	{
		garage_id: 87,
		part_id: 173,
		quantity: 271,
	},
	{
		garage_id: 87,
		part_id: 174,
		quantity: 466,
	},
	{
		garage_id: 87,
		part_id: 175,
		quantity: 329,
	},
	{
		garage_id: 87,
		part_id: 176,
		quantity: 438,
	},
	{
		garage_id: 87,
		part_id: 177,
		quantity: 267,
	},
	{
		garage_id: 87,
		part_id: 178,
		quantity: 355,
	},
	{
		garage_id: 87,
		part_id: 179,
		quantity: 415,
	},
	{
		garage_id: 87,
		part_id: 180,
		quantity: 421,
	},
	{
		garage_id: 87,
		part_id: 181,
		quantity: 459,
	},
	{
		garage_id: 87,
		part_id: 182,
		quantity: 73,
	},
	{
		garage_id: 87,
		part_id: 183,
		quantity: 164,
	},
	{
		garage_id: 87,
		part_id: 184,
		quantity: 350,
	},
	{
		garage_id: 87,
		part_id: 185,
		quantity: 316,
	},
	{
		garage_id: 87,
		part_id: 186,
		quantity: 469,
	},
	{
		garage_id: 87,
		part_id: 187,
		quantity: 121,
	},
	{
		garage_id: 87,
		part_id: 188,
		quantity: 421,
	},
	{
		garage_id: 87,
		part_id: 189,
		quantity: 477,
	},
	{
		garage_id: 87,
		part_id: 190,
		quantity: 204,
	},
	{
		garage_id: 87,
		part_id: 191,
		quantity: 470,
	},
	{
		garage_id: 87,
		part_id: 192,
		quantity: 363,
	},
	{
		garage_id: 87,
		part_id: 193,
		quantity: 104,
	},
	{
		garage_id: 87,
		part_id: 194,
		quantity: 88,
	},
	{
		garage_id: 87,
		part_id: 195,
		quantity: 427,
	},
	{
		garage_id: 87,
		part_id: 196,
		quantity: 483,
	},
	{
		garage_id: 87,
		part_id: 197,
		quantity: 287,
	},
	{
		garage_id: 87,
		part_id: 198,
		quantity: 6,
	},
	{
		garage_id: 87,
		part_id: 199,
		quantity: 190,
	},
	{
		garage_id: 87,
		part_id: 200,
		quantity: 343,
	},
	{
		garage_id: 87,
		part_id: 201,
		quantity: 77,
	},
	{
		garage_id: 87,
		part_id: 202,
		quantity: 177,
	},
	{
		garage_id: 87,
		part_id: 203,
		quantity: 490,
	},
	{
		garage_id: 87,
		part_id: 204,
		quantity: 327,
	},
	{
		garage_id: 87,
		part_id: 205,
		quantity: 226,
	},
	{
		garage_id: 87,
		part_id: 206,
		quantity: 76,
	},
	{
		garage_id: 87,
		part_id: 207,
		quantity: 472,
	},
	{
		garage_id: 87,
		part_id: 208,
		quantity: 87,
	},
	{
		garage_id: 87,
		part_id: 209,
		quantity: 101,
	},
	{
		garage_id: 87,
		part_id: 210,
		quantity: 259,
	},
	{
		garage_id: 87,
		part_id: 211,
		quantity: 160,
	},
	{
		garage_id: 87,
		part_id: 212,
		quantity: 59,
	},
	{
		garage_id: 87,
		part_id: 213,
		quantity: 395,
	},
	{
		garage_id: 87,
		part_id: 214,
		quantity: 127,
	},
	{
		garage_id: 87,
		part_id: 215,
		quantity: 495,
	},
	{
		garage_id: 87,
		part_id: 216,
		quantity: 417,
	},
	{
		garage_id: 87,
		part_id: 217,
		quantity: 75,
	},
	{
		garage_id: 87,
		part_id: 218,
		quantity: 275,
	},
	{
		garage_id: 87,
		part_id: 219,
		quantity: 396,
	},
	{
		garage_id: 87,
		part_id: 220,
		quantity: 311,
	},
	{
		garage_id: 87,
		part_id: 221,
		quantity: 301,
	},
	{
		garage_id: 87,
		part_id: 222,
		quantity: 159,
	},
	{
		garage_id: 87,
		part_id: 223,
		quantity: 423,
	},
	{
		garage_id: 87,
		part_id: 224,
		quantity: 385,
	},
	{
		garage_id: 87,
		part_id: 225,
		quantity: 365,
	},
	{
		garage_id: 87,
		part_id: 226,
		quantity: 459,
	},
	{
		garage_id: 87,
		part_id: 227,
		quantity: 123,
	},
	{
		garage_id: 87,
		part_id: 228,
		quantity: 411,
	},
	{
		garage_id: 87,
		part_id: 229,
		quantity: 113,
	},
	{
		garage_id: 87,
		part_id: 230,
		quantity: 227,
	},
	{
		garage_id: 87,
		part_id: 231,
		quantity: 290,
	},
	{
		garage_id: 87,
		part_id: 232,
		quantity: 405,
	},
	{
		garage_id: 87,
		part_id: 233,
		quantity: 193,
	},
	{
		garage_id: 87,
		part_id: 234,
		quantity: 64,
	},
	{
		garage_id: 87,
		part_id: 235,
		quantity: 257,
	},
	{
		garage_id: 87,
		part_id: 236,
		quantity: 250,
	},
	{
		garage_id: 87,
		part_id: 237,
		quantity: 236,
	},
	{
		garage_id: 87,
		part_id: 238,
		quantity: 359,
	},
	{
		garage_id: 87,
		part_id: 239,
		quantity: 57,
	},
	{
		garage_id: 87,
		part_id: 240,
		quantity: 86,
	},
	{
		garage_id: 87,
		part_id: 241,
		quantity: 335,
	},
	{
		garage_id: 87,
		part_id: 242,
		quantity: 365,
	},
	{
		garage_id: 87,
		part_id: 243,
		quantity: 245,
	},
	{
		garage_id: 87,
		part_id: 244,
		quantity: 334,
	},
	{
		garage_id: 87,
		part_id: 245,
		quantity: 255,
	},
	{
		garage_id: 87,
		part_id: 246,
		quantity: 397,
	},
	{
		garage_id: 87,
		part_id: 247,
		quantity: 496,
	},
	{
		garage_id: 87,
		part_id: 248,
		quantity: 351,
	},
	{
		garage_id: 87,
		part_id: 249,
		quantity: 443,
	},
	{
		garage_id: 87,
		part_id: 250,
		quantity: 74,
	},
	{
		garage_id: 87,
		part_id: 251,
		quantity: 485,
	},
	{
		garage_id: 87,
		part_id: 252,
		quantity: 101,
	},
	{
		garage_id: 87,
		part_id: 253,
		quantity: 78,
	},
	{
		garage_id: 87,
		part_id: 254,
		quantity: 389,
	},
	{
		garage_id: 87,
		part_id: 255,
		quantity: 372,
	},
	{
		garage_id: 87,
		part_id: 256,
		quantity: 100,
	},
	{
		garage_id: 87,
		part_id: 257,
		quantity: 89,
	},
	{
		garage_id: 87,
		part_id: 258,
		quantity: 53,
	},
	{
		garage_id: 87,
		part_id: 259,
		quantity: 487,
	},
	{
		garage_id: 87,
		part_id: 260,
		quantity: 195,
	},
	{
		garage_id: 87,
		part_id: 261,
		quantity: 381,
	},
	{
		garage_id: 87,
		part_id: 262,
		quantity: 247,
	},
	{
		garage_id: 87,
		part_id: 263,
		quantity: 276,
	},
	{
		garage_id: 87,
		part_id: 264,
		quantity: 250,
	},
	{
		garage_id: 87,
		part_id: 265,
		quantity: 385,
	},
	{
		garage_id: 87,
		part_id: 266,
		quantity: 435,
	},
	{
		garage_id: 87,
		part_id: 267,
		quantity: 386,
	},
	{
		garage_id: 87,
		part_id: 268,
		quantity: 239,
	},
	{
		garage_id: 87,
		part_id: 269,
		quantity: 206,
	},
	{
		garage_id: 87,
		part_id: 270,
		quantity: 312,
	},
	{
		garage_id: 87,
		part_id: 271,
		quantity: 297,
	},
	{
		garage_id: 87,
		part_id: 272,
		quantity: 348,
	},
	{
		garage_id: 87,
		part_id: 273,
		quantity: 391,
	},
	{
		garage_id: 87,
		part_id: 274,
		quantity: 26,
	},
	{
		garage_id: 87,
		part_id: 275,
		quantity: 85,
	},
	{
		garage_id: 87,
		part_id: 276,
		quantity: 415,
	},
	{
		garage_id: 87,
		part_id: 277,
		quantity: 33,
	},
	{
		garage_id: 87,
		part_id: 278,
		quantity: 337,
	},
	{
		garage_id: 87,
		part_id: 279,
		quantity: 5,
	},
	{
		garage_id: 87,
		part_id: 280,
		quantity: 249,
	},
	{
		garage_id: 87,
		part_id: 281,
		quantity: 137,
	},
	{
		garage_id: 87,
		part_id: 282,
		quantity: 470,
	},
	{
		garage_id: 87,
		part_id: 283,
		quantity: 397,
	},
	{
		garage_id: 87,
		part_id: 284,
		quantity: 162,
	},
	{
		garage_id: 87,
		part_id: 285,
		quantity: 147,
	},
	{
		garage_id: 87,
		part_id: 286,
		quantity: 499,
	},
	{
		garage_id: 87,
		part_id: 287,
		quantity: 48,
	},
	{
		garage_id: 87,
		part_id: 288,
		quantity: 148,
	},
	{
		garage_id: 87,
		part_id: 289,
		quantity: 126,
	},
	{
		garage_id: 87,
		part_id: 290,
		quantity: 456,
	},
	{
		garage_id: 87,
		part_id: 291,
		quantity: 288,
	},
	{
		garage_id: 87,
		part_id: 292,
		quantity: 190,
	},
	{
		garage_id: 87,
		part_id: 293,
		quantity: 435,
	},
	{
		garage_id: 87,
		part_id: 294,
		quantity: 369,
	},
	{
		garage_id: 87,
		part_id: 295,
		quantity: 416,
	},
	{
		garage_id: 87,
		part_id: 296,
		quantity: 63,
	},
	{
		garage_id: 87,
		part_id: 297,
		quantity: 356,
	},
	{
		garage_id: 87,
		part_id: 298,
		quantity: 226,
	},
	{
		garage_id: 87,
		part_id: 299,
		quantity: 354,
	},
	{
		garage_id: 87,
		part_id: 300,
		quantity: 472,
	},
	{
		garage_id: 87,
		part_id: 301,
		quantity: 150,
	},
	{
		garage_id: 87,
		part_id: 302,
		quantity: 220,
	},
	{
		garage_id: 87,
		part_id: 303,
		quantity: 110,
	},
	{
		garage_id: 87,
		part_id: 304,
		quantity: 353,
	},
	{
		garage_id: 87,
		part_id: 305,
		quantity: 246,
	},
	{
		garage_id: 87,
		part_id: 306,
		quantity: 67,
	},
	{
		garage_id: 87,
		part_id: 307,
		quantity: 312,
	},
	{
		garage_id: 87,
		part_id: 308,
		quantity: 12,
	},
	{
		garage_id: 87,
		part_id: 309,
		quantity: 469,
	},
	{
		garage_id: 87,
		part_id: 310,
		quantity: 303,
	},
	{
		garage_id: 87,
		part_id: 311,
		quantity: 339,
	},
	{
		garage_id: 87,
		part_id: 312,
		quantity: 297,
	},
	{
		garage_id: 87,
		part_id: 313,
		quantity: 428,
	},
	{
		garage_id: 87,
		part_id: 314,
		quantity: 451,
	},
	{
		garage_id: 87,
		part_id: 315,
		quantity: 85,
	},
	{
		garage_id: 87,
		part_id: 316,
		quantity: 349,
	},
	{
		garage_id: 87,
		part_id: 317,
		quantity: 367,
	},
	{
		garage_id: 87,
		part_id: 318,
		quantity: 31,
	},
	{
		garage_id: 87,
		part_id: 319,
		quantity: 333,
	},
	{
		garage_id: 87,
		part_id: 320,
		quantity: 41,
	},
	{
		garage_id: 87,
		part_id: 321,
		quantity: 207,
	},
	{
		garage_id: 87,
		part_id: 322,
		quantity: 186,
	},
	{
		garage_id: 87,
		part_id: 323,
		quantity: 258,
	},
	{
		garage_id: 87,
		part_id: 324,
		quantity: 395,
	},
	{
		garage_id: 87,
		part_id: 325,
		quantity: 457,
	},
	{
		garage_id: 87,
		part_id: 326,
		quantity: 91,
	},
	{
		garage_id: 87,
		part_id: 327,
		quantity: 428,
	},
	{
		garage_id: 87,
		part_id: 328,
		quantity: 195,
	},
	{
		garage_id: 87,
		part_id: 329,
		quantity: 39,
	},
	{
		garage_id: 87,
		part_id: 330,
		quantity: 373,
	},
	{
		garage_id: 87,
		part_id: 331,
		quantity: 379,
	},
	{
		garage_id: 87,
		part_id: 332,
		quantity: 401,
	},
	{
		garage_id: 87,
		part_id: 333,
		quantity: 7,
	},
	{
		garage_id: 87,
		part_id: 334,
		quantity: 166,
	},
	{
		garage_id: 87,
		part_id: 335,
		quantity: 352,
	},
	{
		garage_id: 87,
		part_id: 336,
		quantity: 345,
	},
	{
		garage_id: 87,
		part_id: 337,
		quantity: 472,
	},
	{
		garage_id: 87,
		part_id: 338,
		quantity: 205,
	},
	{
		garage_id: 87,
		part_id: 339,
		quantity: 200,
	},
	{
		garage_id: 87,
		part_id: 340,
		quantity: 78,
	},
	{
		garage_id: 87,
		part_id: 341,
		quantity: 401,
	},
	{
		garage_id: 87,
		part_id: 342,
		quantity: 490,
	},
	{
		garage_id: 87,
		part_id: 343,
		quantity: 282,
	},
	{
		garage_id: 87,
		part_id: 344,
		quantity: 125,
	},
	{
		garage_id: 87,
		part_id: 345,
		quantity: 13,
	},
	{
		garage_id: 87,
		part_id: 346,
		quantity: 229,
	},
	{
		garage_id: 87,
		part_id: 347,
		quantity: 109,
	},
	{
		garage_id: 87,
		part_id: 348,
		quantity: 117,
	},
	{
		garage_id: 87,
		part_id: 349,
		quantity: 264,
	},
	{
		garage_id: 87,
		part_id: 350,
		quantity: 392,
	},
	{
		garage_id: 87,
		part_id: 351,
		quantity: 133,
	},
	{
		garage_id: 87,
		part_id: 352,
		quantity: 237,
	},
	{
		garage_id: 87,
		part_id: 353,
		quantity: 381,
	},
	{
		garage_id: 87,
		part_id: 354,
		quantity: 189,
	},
	{
		garage_id: 87,
		part_id: 355,
		quantity: 268,
	},
	{
		garage_id: 87,
		part_id: 356,
		quantity: 134,
	},
	{
		garage_id: 87,
		part_id: 357,
		quantity: 321,
	},
	{
		garage_id: 87,
		part_id: 358,
		quantity: 347,
	},
	{
		garage_id: 87,
		part_id: 359,
		quantity: 23,
	},
	{
		garage_id: 87,
		part_id: 360,
		quantity: 215,
	},
	{
		garage_id: 87,
		part_id: 361,
		quantity: 40,
	},
	{
		garage_id: 87,
		part_id: 362,
		quantity: 435,
	},
	{
		garage_id: 87,
		part_id: 363,
		quantity: 272,
	},
	{
		garage_id: 87,
		part_id: 364,
		quantity: 65,
	},
	{
		garage_id: 87,
		part_id: 365,
		quantity: 180,
	},
	{
		garage_id: 87,
		part_id: 366,
		quantity: 71,
	},
	{
		garage_id: 87,
		part_id: 367,
		quantity: 283,
	},
	{
		garage_id: 87,
		part_id: 368,
		quantity: 467,
	},
	{
		garage_id: 87,
		part_id: 369,
		quantity: 80,
	},
	{
		garage_id: 87,
		part_id: 370,
		quantity: 347,
	},
	{
		garage_id: 87,
		part_id: 371,
		quantity: 344,
	},
	{
		garage_id: 87,
		part_id: 372,
		quantity: 64,
	},
	{
		garage_id: 87,
		part_id: 373,
		quantity: 481,
	},
	{
		garage_id: 87,
		part_id: 374,
		quantity: 166,
	},
	{
		garage_id: 87,
		part_id: 375,
		quantity: 397,
	},
	{
		garage_id: 87,
		part_id: 376,
		quantity: 492,
	},
	{
		garage_id: 87,
		part_id: 377,
		quantity: 500,
	},
	{
		garage_id: 87,
		part_id: 378,
		quantity: 91,
	},
	{
		garage_id: 87,
		part_id: 379,
		quantity: 261,
	},
	{
		garage_id: 87,
		part_id: 380,
		quantity: 78,
	},
	{
		garage_id: 87,
		part_id: 381,
		quantity: 490,
	},
	{
		garage_id: 87,
		part_id: 382,
		quantity: 321,
	},
	{
		garage_id: 87,
		part_id: 383,
		quantity: 61,
	},
	{
		garage_id: 87,
		part_id: 384,
		quantity: 167,
	},
	{
		garage_id: 87,
		part_id: 385,
		quantity: 57,
	},
	{
		garage_id: 87,
		part_id: 386,
		quantity: 403,
	},
	{
		garage_id: 87,
		part_id: 387,
		quantity: 286,
	},
	{
		garage_id: 87,
		part_id: 388,
		quantity: 424,
	},
	{
		garage_id: 87,
		part_id: 389,
		quantity: 431,
	},
	{
		garage_id: 87,
		part_id: 390,
		quantity: 97,
	},
	{
		garage_id: 87,
		part_id: 391,
		quantity: 471,
	},
	{
		garage_id: 87,
		part_id: 392,
		quantity: 358,
	},
	{
		garage_id: 87,
		part_id: 393,
		quantity: 52,
	},
	{
		garage_id: 87,
		part_id: 394,
		quantity: 401,
	},
	{
		garage_id: 87,
		part_id: 395,
		quantity: 18,
	},
	{
		garage_id: 87,
		part_id: 396,
		quantity: 419,
	},
	{
		garage_id: 87,
		part_id: 397,
		quantity: 133,
	},
	{
		garage_id: 87,
		part_id: 398,
		quantity: 462,
	},
	{
		garage_id: 87,
		part_id: 399,
		quantity: 158,
	},
	{
		garage_id: 88,
		part_id: 0,
		quantity: 405,
	},
	{
		garage_id: 88,
		part_id: 1,
		quantity: 78,
	},
	{
		garage_id: 88,
		part_id: 2,
		quantity: 122,
	},
	{
		garage_id: 88,
		part_id: 3,
		quantity: 485,
	},
	{
		garage_id: 88,
		part_id: 4,
		quantity: 495,
	},
	{
		garage_id: 88,
		part_id: 5,
		quantity: 285,
	},
	{
		garage_id: 88,
		part_id: 6,
		quantity: 180,
	},
	{
		garage_id: 88,
		part_id: 7,
		quantity: 306,
	},
	{
		garage_id: 88,
		part_id: 8,
		quantity: 112,
	},
	{
		garage_id: 88,
		part_id: 9,
		quantity: 477,
	},
	{
		garage_id: 88,
		part_id: 10,
		quantity: 460,
	},
	{
		garage_id: 88,
		part_id: 11,
		quantity: 53,
	},
	{
		garage_id: 88,
		part_id: 12,
		quantity: 138,
	},
	{
		garage_id: 88,
		part_id: 13,
		quantity: 263,
	},
	{
		garage_id: 88,
		part_id: 14,
		quantity: 132,
	},
	{
		garage_id: 88,
		part_id: 15,
		quantity: 18,
	},
	{
		garage_id: 88,
		part_id: 16,
		quantity: 247,
	},
	{
		garage_id: 88,
		part_id: 17,
		quantity: 170,
	},
	{
		garage_id: 88,
		part_id: 18,
		quantity: 411,
	},
	{
		garage_id: 88,
		part_id: 19,
		quantity: 409,
	},
	{
		garage_id: 88,
		part_id: 20,
		quantity: 327,
	},
	{
		garage_id: 88,
		part_id: 21,
		quantity: 304,
	},
	{
		garage_id: 88,
		part_id: 22,
		quantity: 16,
	},
	{
		garage_id: 88,
		part_id: 23,
		quantity: 385,
	},
	{
		garage_id: 88,
		part_id: 24,
		quantity: 103,
	},
	{
		garage_id: 88,
		part_id: 25,
		quantity: 490,
	},
	{
		garage_id: 88,
		part_id: 26,
		quantity: 382,
	},
	{
		garage_id: 88,
		part_id: 27,
		quantity: 437,
	},
	{
		garage_id: 88,
		part_id: 28,
		quantity: 134,
	},
	{
		garage_id: 88,
		part_id: 29,
		quantity: 144,
	},
	{
		garage_id: 88,
		part_id: 30,
		quantity: 398,
	},
	{
		garage_id: 88,
		part_id: 31,
		quantity: 422,
	},
	{
		garage_id: 88,
		part_id: 32,
		quantity: 221,
	},
	{
		garage_id: 88,
		part_id: 33,
		quantity: 496,
	},
	{
		garage_id: 88,
		part_id: 34,
		quantity: 231,
	},
	{
		garage_id: 88,
		part_id: 35,
		quantity: 465,
	},
	{
		garage_id: 88,
		part_id: 36,
		quantity: 85,
	},
	{
		garage_id: 88,
		part_id: 37,
		quantity: 34,
	},
	{
		garage_id: 88,
		part_id: 38,
		quantity: 342,
	},
	{
		garage_id: 88,
		part_id: 39,
		quantity: 90,
	},
	{
		garage_id: 88,
		part_id: 40,
		quantity: 455,
	},
	{
		garage_id: 88,
		part_id: 41,
		quantity: 154,
	},
	{
		garage_id: 88,
		part_id: 42,
		quantity: 273,
	},
	{
		garage_id: 88,
		part_id: 43,
		quantity: 440,
	},
	{
		garage_id: 88,
		part_id: 44,
		quantity: 335,
	},
	{
		garage_id: 88,
		part_id: 45,
		quantity: 295,
	},
	{
		garage_id: 88,
		part_id: 46,
		quantity: 270,
	},
	{
		garage_id: 88,
		part_id: 47,
		quantity: 237,
	},
	{
		garage_id: 88,
		part_id: 48,
		quantity: 292,
	},
	{
		garage_id: 88,
		part_id: 49,
		quantity: 0,
	},
	{
		garage_id: 88,
		part_id: 50,
		quantity: 51,
	},
	{
		garage_id: 88,
		part_id: 51,
		quantity: 254,
	},
	{
		garage_id: 88,
		part_id: 52,
		quantity: 478,
	},
	{
		garage_id: 88,
		part_id: 53,
		quantity: 401,
	},
	{
		garage_id: 88,
		part_id: 54,
		quantity: 52,
	},
	{
		garage_id: 88,
		part_id: 55,
		quantity: 148,
	},
	{
		garage_id: 88,
		part_id: 56,
		quantity: 453,
	},
	{
		garage_id: 88,
		part_id: 57,
		quantity: 98,
	},
	{
		garage_id: 88,
		part_id: 58,
		quantity: 275,
	},
	{
		garage_id: 88,
		part_id: 59,
		quantity: 176,
	},
	{
		garage_id: 88,
		part_id: 60,
		quantity: 307,
	},
	{
		garage_id: 88,
		part_id: 61,
		quantity: 254,
	},
	{
		garage_id: 88,
		part_id: 62,
		quantity: 36,
	},
	{
		garage_id: 88,
		part_id: 63,
		quantity: 180,
	},
	{
		garage_id: 88,
		part_id: 64,
		quantity: 262,
	},
	{
		garage_id: 88,
		part_id: 65,
		quantity: 338,
	},
	{
		garage_id: 88,
		part_id: 66,
		quantity: 195,
	},
	{
		garage_id: 88,
		part_id: 67,
		quantity: 292,
	},
	{
		garage_id: 88,
		part_id: 68,
		quantity: 273,
	},
	{
		garage_id: 88,
		part_id: 69,
		quantity: 156,
	},
	{
		garage_id: 88,
		part_id: 70,
		quantity: 381,
	},
	{
		garage_id: 88,
		part_id: 71,
		quantity: 327,
	},
	{
		garage_id: 88,
		part_id: 72,
		quantity: 148,
	},
	{
		garage_id: 88,
		part_id: 73,
		quantity: 344,
	},
	{
		garage_id: 88,
		part_id: 74,
		quantity: 274,
	},
	{
		garage_id: 88,
		part_id: 75,
		quantity: 476,
	},
	{
		garage_id: 88,
		part_id: 76,
		quantity: 59,
	},
	{
		garage_id: 88,
		part_id: 77,
		quantity: 72,
	},
	{
		garage_id: 88,
		part_id: 78,
		quantity: 435,
	},
	{
		garage_id: 88,
		part_id: 79,
		quantity: 10,
	},
	{
		garage_id: 88,
		part_id: 80,
		quantity: 157,
	},
	{
		garage_id: 88,
		part_id: 81,
		quantity: 103,
	},
	{
		garage_id: 88,
		part_id: 82,
		quantity: 44,
	},
	{
		garage_id: 88,
		part_id: 83,
		quantity: 311,
	},
	{
		garage_id: 88,
		part_id: 84,
		quantity: 411,
	},
	{
		garage_id: 88,
		part_id: 85,
		quantity: 454,
	},
	{
		garage_id: 88,
		part_id: 86,
		quantity: 368,
	},
	{
		garage_id: 88,
		part_id: 87,
		quantity: 283,
	},
	{
		garage_id: 88,
		part_id: 88,
		quantity: 9,
	},
	{
		garage_id: 88,
		part_id: 89,
		quantity: 420,
	},
	{
		garage_id: 88,
		part_id: 90,
		quantity: 298,
	},
	{
		garage_id: 88,
		part_id: 91,
		quantity: 299,
	},
	{
		garage_id: 88,
		part_id: 92,
		quantity: 124,
	},
	{
		garage_id: 88,
		part_id: 93,
		quantity: 242,
	},
	{
		garage_id: 88,
		part_id: 94,
		quantity: 190,
	},
	{
		garage_id: 88,
		part_id: 95,
		quantity: 443,
	},
	{
		garage_id: 88,
		part_id: 96,
		quantity: 339,
	},
	{
		garage_id: 88,
		part_id: 97,
		quantity: 277,
	},
	{
		garage_id: 88,
		part_id: 98,
		quantity: 266,
	},
	{
		garage_id: 88,
		part_id: 99,
		quantity: 169,
	},
	{
		garage_id: 88,
		part_id: 100,
		quantity: 184,
	},
	{
		garage_id: 88,
		part_id: 101,
		quantity: 70,
	},
	{
		garage_id: 88,
		part_id: 102,
		quantity: 120,
	},
	{
		garage_id: 88,
		part_id: 103,
		quantity: 470,
	},
	{
		garage_id: 88,
		part_id: 104,
		quantity: 455,
	},
	{
		garage_id: 88,
		part_id: 105,
		quantity: 337,
	},
	{
		garage_id: 88,
		part_id: 106,
		quantity: 394,
	},
	{
		garage_id: 88,
		part_id: 107,
		quantity: 449,
	},
	{
		garage_id: 88,
		part_id: 108,
		quantity: 432,
	},
	{
		garage_id: 88,
		part_id: 109,
		quantity: 410,
	},
	{
		garage_id: 88,
		part_id: 110,
		quantity: 238,
	},
	{
		garage_id: 88,
		part_id: 111,
		quantity: 334,
	},
	{
		garage_id: 88,
		part_id: 112,
		quantity: 500,
	},
	{
		garage_id: 88,
		part_id: 113,
		quantity: 103,
	},
	{
		garage_id: 88,
		part_id: 114,
		quantity: 404,
	},
	{
		garage_id: 88,
		part_id: 115,
		quantity: 200,
	},
	{
		garage_id: 88,
		part_id: 116,
		quantity: 429,
	},
	{
		garage_id: 88,
		part_id: 117,
		quantity: 59,
	},
	{
		garage_id: 88,
		part_id: 118,
		quantity: 326,
	},
	{
		garage_id: 88,
		part_id: 119,
		quantity: 494,
	},
	{
		garage_id: 88,
		part_id: 120,
		quantity: 281,
	},
	{
		garage_id: 88,
		part_id: 121,
		quantity: 27,
	},
	{
		garage_id: 88,
		part_id: 122,
		quantity: 73,
	},
	{
		garage_id: 88,
		part_id: 123,
		quantity: 335,
	},
	{
		garage_id: 88,
		part_id: 124,
		quantity: 143,
	},
	{
		garage_id: 88,
		part_id: 125,
		quantity: 375,
	},
	{
		garage_id: 88,
		part_id: 126,
		quantity: 71,
	},
	{
		garage_id: 88,
		part_id: 127,
		quantity: 304,
	},
	{
		garage_id: 88,
		part_id: 128,
		quantity: 309,
	},
	{
		garage_id: 88,
		part_id: 129,
		quantity: 416,
	},
	{
		garage_id: 88,
		part_id: 130,
		quantity: 392,
	},
	{
		garage_id: 88,
		part_id: 131,
		quantity: 0,
	},
	{
		garage_id: 88,
		part_id: 132,
		quantity: 109,
	},
	{
		garage_id: 88,
		part_id: 133,
		quantity: 249,
	},
	{
		garage_id: 88,
		part_id: 134,
		quantity: 93,
	},
	{
		garage_id: 88,
		part_id: 135,
		quantity: 115,
	},
	{
		garage_id: 88,
		part_id: 136,
		quantity: 426,
	},
	{
		garage_id: 88,
		part_id: 137,
		quantity: 468,
	},
	{
		garage_id: 88,
		part_id: 138,
		quantity: 92,
	},
	{
		garage_id: 88,
		part_id: 139,
		quantity: 53,
	},
	{
		garage_id: 88,
		part_id: 140,
		quantity: 21,
	},
	{
		garage_id: 88,
		part_id: 141,
		quantity: 108,
	},
	{
		garage_id: 88,
		part_id: 142,
		quantity: 342,
	},
	{
		garage_id: 88,
		part_id: 143,
		quantity: 406,
	},
	{
		garage_id: 88,
		part_id: 144,
		quantity: 47,
	},
	{
		garage_id: 88,
		part_id: 145,
		quantity: 386,
	},
	{
		garage_id: 88,
		part_id: 146,
		quantity: 62,
	},
	{
		garage_id: 88,
		part_id: 147,
		quantity: 377,
	},
	{
		garage_id: 88,
		part_id: 148,
		quantity: 493,
	},
	{
		garage_id: 88,
		part_id: 149,
		quantity: 99,
	},
	{
		garage_id: 88,
		part_id: 150,
		quantity: 470,
	},
	{
		garage_id: 88,
		part_id: 151,
		quantity: 300,
	},
	{
		garage_id: 88,
		part_id: 152,
		quantity: 442,
	},
	{
		garage_id: 88,
		part_id: 153,
		quantity: 302,
	},
	{
		garage_id: 88,
		part_id: 154,
		quantity: 184,
	},
	{
		garage_id: 88,
		part_id: 155,
		quantity: 186,
	},
	{
		garage_id: 88,
		part_id: 156,
		quantity: 71,
	},
	{
		garage_id: 88,
		part_id: 157,
		quantity: 335,
	},
	{
		garage_id: 88,
		part_id: 158,
		quantity: 395,
	},
	{
		garage_id: 88,
		part_id: 159,
		quantity: 431,
	},
	{
		garage_id: 88,
		part_id: 160,
		quantity: 211,
	},
	{
		garage_id: 88,
		part_id: 161,
		quantity: 184,
	},
	{
		garage_id: 88,
		part_id: 162,
		quantity: 398,
	},
	{
		garage_id: 88,
		part_id: 163,
		quantity: 87,
	},
	{
		garage_id: 88,
		part_id: 164,
		quantity: 180,
	},
	{
		garage_id: 88,
		part_id: 165,
		quantity: 165,
	},
	{
		garage_id: 88,
		part_id: 166,
		quantity: 31,
	},
	{
		garage_id: 88,
		part_id: 167,
		quantity: 265,
	},
	{
		garage_id: 88,
		part_id: 168,
		quantity: 227,
	},
	{
		garage_id: 88,
		part_id: 169,
		quantity: 347,
	},
	{
		garage_id: 88,
		part_id: 170,
		quantity: 74,
	},
	{
		garage_id: 88,
		part_id: 171,
		quantity: 252,
	},
	{
		garage_id: 88,
		part_id: 172,
		quantity: 126,
	},
	{
		garage_id: 88,
		part_id: 173,
		quantity: 306,
	},
	{
		garage_id: 88,
		part_id: 174,
		quantity: 380,
	},
	{
		garage_id: 88,
		part_id: 175,
		quantity: 371,
	},
	{
		garage_id: 88,
		part_id: 176,
		quantity: 149,
	},
	{
		garage_id: 88,
		part_id: 177,
		quantity: 35,
	},
	{
		garage_id: 88,
		part_id: 178,
		quantity: 196,
	},
	{
		garage_id: 88,
		part_id: 179,
		quantity: 474,
	},
	{
		garage_id: 88,
		part_id: 180,
		quantity: 259,
	},
	{
		garage_id: 88,
		part_id: 181,
		quantity: 422,
	},
	{
		garage_id: 88,
		part_id: 182,
		quantity: 378,
	},
	{
		garage_id: 88,
		part_id: 183,
		quantity: 231,
	},
	{
		garage_id: 88,
		part_id: 184,
		quantity: 104,
	},
	{
		garage_id: 88,
		part_id: 185,
		quantity: 20,
	},
	{
		garage_id: 88,
		part_id: 186,
		quantity: 414,
	},
	{
		garage_id: 88,
		part_id: 187,
		quantity: 65,
	},
	{
		garage_id: 88,
		part_id: 188,
		quantity: 40,
	},
	{
		garage_id: 88,
		part_id: 189,
		quantity: 85,
	},
	{
		garage_id: 88,
		part_id: 190,
		quantity: 496,
	},
	{
		garage_id: 88,
		part_id: 191,
		quantity: 388,
	},
	{
		garage_id: 88,
		part_id: 192,
		quantity: 467,
	},
	{
		garage_id: 88,
		part_id: 193,
		quantity: 68,
	},
	{
		garage_id: 88,
		part_id: 194,
		quantity: 297,
	},
	{
		garage_id: 88,
		part_id: 195,
		quantity: 75,
	},
	{
		garage_id: 88,
		part_id: 196,
		quantity: 89,
	},
	{
		garage_id: 88,
		part_id: 197,
		quantity: 360,
	},
	{
		garage_id: 88,
		part_id: 198,
		quantity: 327,
	},
	{
		garage_id: 88,
		part_id: 199,
		quantity: 182,
	},
	{
		garage_id: 88,
		part_id: 200,
		quantity: 377,
	},
	{
		garage_id: 88,
		part_id: 201,
		quantity: 252,
	},
	{
		garage_id: 88,
		part_id: 202,
		quantity: 180,
	},
	{
		garage_id: 88,
		part_id: 203,
		quantity: 409,
	},
	{
		garage_id: 88,
		part_id: 204,
		quantity: 162,
	},
	{
		garage_id: 88,
		part_id: 205,
		quantity: 189,
	},
	{
		garage_id: 88,
		part_id: 206,
		quantity: 469,
	},
	{
		garage_id: 88,
		part_id: 207,
		quantity: 326,
	},
	{
		garage_id: 88,
		part_id: 208,
		quantity: 79,
	},
	{
		garage_id: 88,
		part_id: 209,
		quantity: 56,
	},
	{
		garage_id: 88,
		part_id: 210,
		quantity: 386,
	},
	{
		garage_id: 88,
		part_id: 211,
		quantity: 189,
	},
	{
		garage_id: 88,
		part_id: 212,
		quantity: 454,
	},
	{
		garage_id: 88,
		part_id: 213,
		quantity: 296,
	},
	{
		garage_id: 88,
		part_id: 214,
		quantity: 231,
	},
	{
		garage_id: 88,
		part_id: 215,
		quantity: 265,
	},
	{
		garage_id: 88,
		part_id: 216,
		quantity: 425,
	},
	{
		garage_id: 88,
		part_id: 217,
		quantity: 121,
	},
	{
		garage_id: 88,
		part_id: 218,
		quantity: 285,
	},
	{
		garage_id: 88,
		part_id: 219,
		quantity: 178,
	},
	{
		garage_id: 88,
		part_id: 220,
		quantity: 161,
	},
	{
		garage_id: 88,
		part_id: 221,
		quantity: 176,
	},
	{
		garage_id: 88,
		part_id: 222,
		quantity: 1,
	},
	{
		garage_id: 88,
		part_id: 223,
		quantity: 284,
	},
	{
		garage_id: 88,
		part_id: 224,
		quantity: 200,
	},
	{
		garage_id: 88,
		part_id: 225,
		quantity: 111,
	},
	{
		garage_id: 88,
		part_id: 226,
		quantity: 224,
	},
	{
		garage_id: 88,
		part_id: 227,
		quantity: 408,
	},
	{
		garage_id: 88,
		part_id: 228,
		quantity: 205,
	},
	{
		garage_id: 88,
		part_id: 229,
		quantity: 227,
	},
	{
		garage_id: 88,
		part_id: 230,
		quantity: 317,
	},
	{
		garage_id: 88,
		part_id: 231,
		quantity: 198,
	},
	{
		garage_id: 88,
		part_id: 232,
		quantity: 316,
	},
	{
		garage_id: 88,
		part_id: 233,
		quantity: 379,
	},
	{
		garage_id: 88,
		part_id: 234,
		quantity: 404,
	},
	{
		garage_id: 88,
		part_id: 235,
		quantity: 147,
	},
	{
		garage_id: 88,
		part_id: 236,
		quantity: 33,
	},
	{
		garage_id: 88,
		part_id: 237,
		quantity: 41,
	},
	{
		garage_id: 88,
		part_id: 238,
		quantity: 439,
	},
	{
		garage_id: 88,
		part_id: 239,
		quantity: 105,
	},
	{
		garage_id: 88,
		part_id: 240,
		quantity: 305,
	},
	{
		garage_id: 88,
		part_id: 241,
		quantity: 401,
	},
	{
		garage_id: 88,
		part_id: 242,
		quantity: 114,
	},
	{
		garage_id: 88,
		part_id: 243,
		quantity: 415,
	},
	{
		garage_id: 88,
		part_id: 244,
		quantity: 441,
	},
	{
		garage_id: 88,
		part_id: 245,
		quantity: 295,
	},
	{
		garage_id: 88,
		part_id: 246,
		quantity: 99,
	},
	{
		garage_id: 88,
		part_id: 247,
		quantity: 264,
	},
	{
		garage_id: 88,
		part_id: 248,
		quantity: 48,
	},
	{
		garage_id: 88,
		part_id: 249,
		quantity: 349,
	},
	{
		garage_id: 88,
		part_id: 250,
		quantity: 104,
	},
	{
		garage_id: 88,
		part_id: 251,
		quantity: 270,
	},
	{
		garage_id: 88,
		part_id: 252,
		quantity: 10,
	},
	{
		garage_id: 88,
		part_id: 253,
		quantity: 95,
	},
	{
		garage_id: 88,
		part_id: 254,
		quantity: 439,
	},
	{
		garage_id: 88,
		part_id: 255,
		quantity: 82,
	},
	{
		garage_id: 88,
		part_id: 256,
		quantity: 4,
	},
	{
		garage_id: 88,
		part_id: 257,
		quantity: 235,
	},
	{
		garage_id: 88,
		part_id: 258,
		quantity: 412,
	},
	{
		garage_id: 88,
		part_id: 259,
		quantity: 360,
	},
	{
		garage_id: 88,
		part_id: 260,
		quantity: 139,
	},
	{
		garage_id: 88,
		part_id: 261,
		quantity: 401,
	},
	{
		garage_id: 88,
		part_id: 262,
		quantity: 294,
	},
	{
		garage_id: 88,
		part_id: 263,
		quantity: 476,
	},
	{
		garage_id: 88,
		part_id: 264,
		quantity: 448,
	},
	{
		garage_id: 88,
		part_id: 265,
		quantity: 467,
	},
	{
		garage_id: 88,
		part_id: 266,
		quantity: 401,
	},
	{
		garage_id: 88,
		part_id: 267,
		quantity: 102,
	},
	{
		garage_id: 88,
		part_id: 268,
		quantity: 76,
	},
	{
		garage_id: 88,
		part_id: 269,
		quantity: 391,
	},
	{
		garage_id: 88,
		part_id: 270,
		quantity: 388,
	},
	{
		garage_id: 88,
		part_id: 271,
		quantity: 22,
	},
	{
		garage_id: 88,
		part_id: 272,
		quantity: 9,
	},
	{
		garage_id: 88,
		part_id: 273,
		quantity: 180,
	},
	{
		garage_id: 88,
		part_id: 274,
		quantity: 123,
	},
	{
		garage_id: 88,
		part_id: 275,
		quantity: 347,
	},
	{
		garage_id: 88,
		part_id: 276,
		quantity: 165,
	},
	{
		garage_id: 88,
		part_id: 277,
		quantity: 43,
	},
	{
		garage_id: 88,
		part_id: 278,
		quantity: 154,
	},
	{
		garage_id: 88,
		part_id: 279,
		quantity: 307,
	},
	{
		garage_id: 88,
		part_id: 280,
		quantity: 276,
	},
	{
		garage_id: 88,
		part_id: 281,
		quantity: 128,
	},
	{
		garage_id: 88,
		part_id: 282,
		quantity: 85,
	},
	{
		garage_id: 88,
		part_id: 283,
		quantity: 463,
	},
	{
		garage_id: 88,
		part_id: 284,
		quantity: 79,
	},
	{
		garage_id: 88,
		part_id: 285,
		quantity: 463,
	},
	{
		garage_id: 88,
		part_id: 286,
		quantity: 274,
	},
	{
		garage_id: 88,
		part_id: 287,
		quantity: 298,
	},
	{
		garage_id: 88,
		part_id: 288,
		quantity: 40,
	},
	{
		garage_id: 88,
		part_id: 289,
		quantity: 59,
	},
	{
		garage_id: 88,
		part_id: 290,
		quantity: 365,
	},
	{
		garage_id: 88,
		part_id: 291,
		quantity: 295,
	},
	{
		garage_id: 88,
		part_id: 292,
		quantity: 62,
	},
	{
		garage_id: 88,
		part_id: 293,
		quantity: 77,
	},
	{
		garage_id: 88,
		part_id: 294,
		quantity: 362,
	},
	{
		garage_id: 88,
		part_id: 295,
		quantity: 364,
	},
	{
		garage_id: 88,
		part_id: 296,
		quantity: 404,
	},
	{
		garage_id: 88,
		part_id: 297,
		quantity: 70,
	},
	{
		garage_id: 88,
		part_id: 298,
		quantity: 15,
	},
	{
		garage_id: 88,
		part_id: 299,
		quantity: 246,
	},
	{
		garage_id: 88,
		part_id: 300,
		quantity: 393,
	},
	{
		garage_id: 88,
		part_id: 301,
		quantity: 294,
	},
	{
		garage_id: 88,
		part_id: 302,
		quantity: 28,
	},
	{
		garage_id: 88,
		part_id: 303,
		quantity: 241,
	},
	{
		garage_id: 88,
		part_id: 304,
		quantity: 481,
	},
	{
		garage_id: 88,
		part_id: 305,
		quantity: 472,
	},
	{
		garage_id: 88,
		part_id: 306,
		quantity: 259,
	},
	{
		garage_id: 88,
		part_id: 307,
		quantity: 106,
	},
	{
		garage_id: 88,
		part_id: 308,
		quantity: 137,
	},
	{
		garage_id: 88,
		part_id: 309,
		quantity: 413,
	},
	{
		garage_id: 88,
		part_id: 310,
		quantity: 245,
	},
	{
		garage_id: 88,
		part_id: 311,
		quantity: 115,
	},
	{
		garage_id: 88,
		part_id: 312,
		quantity: 210,
	},
	{
		garage_id: 88,
		part_id: 313,
		quantity: 443,
	},
	{
		garage_id: 88,
		part_id: 314,
		quantity: 429,
	},
	{
		garage_id: 88,
		part_id: 315,
		quantity: 138,
	},
	{
		garage_id: 88,
		part_id: 316,
		quantity: 210,
	},
	{
		garage_id: 88,
		part_id: 317,
		quantity: 70,
	},
	{
		garage_id: 88,
		part_id: 318,
		quantity: 179,
	},
	{
		garage_id: 88,
		part_id: 319,
		quantity: 343,
	},
	{
		garage_id: 88,
		part_id: 320,
		quantity: 252,
	},
	{
		garage_id: 88,
		part_id: 321,
		quantity: 200,
	},
	{
		garage_id: 88,
		part_id: 322,
		quantity: 302,
	},
	{
		garage_id: 88,
		part_id: 323,
		quantity: 472,
	},
	{
		garage_id: 88,
		part_id: 324,
		quantity: 122,
	},
	{
		garage_id: 88,
		part_id: 325,
		quantity: 350,
	},
	{
		garage_id: 88,
		part_id: 326,
		quantity: 95,
	},
	{
		garage_id: 88,
		part_id: 327,
		quantity: 65,
	},
	{
		garage_id: 88,
		part_id: 328,
		quantity: 76,
	},
	{
		garage_id: 88,
		part_id: 329,
		quantity: 415,
	},
	{
		garage_id: 88,
		part_id: 330,
		quantity: 276,
	},
	{
		garage_id: 88,
		part_id: 331,
		quantity: 6,
	},
	{
		garage_id: 88,
		part_id: 332,
		quantity: 225,
	},
	{
		garage_id: 88,
		part_id: 333,
		quantity: 141,
	},
	{
		garage_id: 88,
		part_id: 334,
		quantity: 28,
	},
	{
		garage_id: 88,
		part_id: 335,
		quantity: 428,
	},
	{
		garage_id: 88,
		part_id: 336,
		quantity: 93,
	},
	{
		garage_id: 88,
		part_id: 337,
		quantity: 333,
	},
	{
		garage_id: 88,
		part_id: 338,
		quantity: 95,
	},
	{
		garage_id: 88,
		part_id: 339,
		quantity: 142,
	},
	{
		garage_id: 88,
		part_id: 340,
		quantity: 436,
	},
	{
		garage_id: 88,
		part_id: 341,
		quantity: 362,
	},
	{
		garage_id: 88,
		part_id: 342,
		quantity: 409,
	},
	{
		garage_id: 88,
		part_id: 343,
		quantity: 211,
	},
	{
		garage_id: 88,
		part_id: 344,
		quantity: 178,
	},
	{
		garage_id: 88,
		part_id: 345,
		quantity: 361,
	},
	{
		garage_id: 88,
		part_id: 346,
		quantity: 75,
	},
	{
		garage_id: 88,
		part_id: 347,
		quantity: 210,
	},
	{
		garage_id: 88,
		part_id: 348,
		quantity: 278,
	},
	{
		garage_id: 88,
		part_id: 349,
		quantity: 60,
	},
	{
		garage_id: 88,
		part_id: 350,
		quantity: 107,
	},
	{
		garage_id: 88,
		part_id: 351,
		quantity: 417,
	},
	{
		garage_id: 88,
		part_id: 352,
		quantity: 95,
	},
	{
		garage_id: 88,
		part_id: 353,
		quantity: 134,
	},
	{
		garage_id: 88,
		part_id: 354,
		quantity: 193,
	},
	{
		garage_id: 88,
		part_id: 355,
		quantity: 290,
	},
	{
		garage_id: 88,
		part_id: 356,
		quantity: 251,
	},
	{
		garage_id: 88,
		part_id: 357,
		quantity: 354,
	},
	{
		garage_id: 88,
		part_id: 358,
		quantity: 154,
	},
	{
		garage_id: 88,
		part_id: 359,
		quantity: 277,
	},
	{
		garage_id: 88,
		part_id: 360,
		quantity: 193,
	},
	{
		garage_id: 88,
		part_id: 361,
		quantity: 496,
	},
	{
		garage_id: 88,
		part_id: 362,
		quantity: 256,
	},
	{
		garage_id: 88,
		part_id: 363,
		quantity: 411,
	},
	{
		garage_id: 88,
		part_id: 364,
		quantity: 372,
	},
	{
		garage_id: 88,
		part_id: 365,
		quantity: 357,
	},
	{
		garage_id: 88,
		part_id: 366,
		quantity: 142,
	},
	{
		garage_id: 88,
		part_id: 367,
		quantity: 428,
	},
	{
		garage_id: 88,
		part_id: 368,
		quantity: 176,
	},
	{
		garage_id: 88,
		part_id: 369,
		quantity: 129,
	},
	{
		garage_id: 88,
		part_id: 370,
		quantity: 299,
	},
	{
		garage_id: 88,
		part_id: 371,
		quantity: 259,
	},
	{
		garage_id: 88,
		part_id: 372,
		quantity: 474,
	},
	{
		garage_id: 88,
		part_id: 373,
		quantity: 120,
	},
	{
		garage_id: 88,
		part_id: 374,
		quantity: 280,
	},
	{
		garage_id: 88,
		part_id: 375,
		quantity: 130,
	},
	{
		garage_id: 88,
		part_id: 376,
		quantity: 288,
	},
	{
		garage_id: 88,
		part_id: 377,
		quantity: 224,
	},
	{
		garage_id: 88,
		part_id: 378,
		quantity: 407,
	},
	{
		garage_id: 88,
		part_id: 379,
		quantity: 27,
	},
	{
		garage_id: 88,
		part_id: 380,
		quantity: 6,
	},
	{
		garage_id: 88,
		part_id: 381,
		quantity: 70,
	},
	{
		garage_id: 88,
		part_id: 382,
		quantity: 181,
	},
	{
		garage_id: 88,
		part_id: 383,
		quantity: 298,
	},
	{
		garage_id: 88,
		part_id: 384,
		quantity: 70,
	},
	{
		garage_id: 88,
		part_id: 385,
		quantity: 221,
	},
	{
		garage_id: 88,
		part_id: 386,
		quantity: 224,
	},
	{
		garage_id: 88,
		part_id: 387,
		quantity: 164,
	},
	{
		garage_id: 88,
		part_id: 388,
		quantity: 362,
	},
	{
		garage_id: 88,
		part_id: 389,
		quantity: 232,
	},
	{
		garage_id: 88,
		part_id: 390,
		quantity: 120,
	},
	{
		garage_id: 88,
		part_id: 391,
		quantity: 39,
	},
	{
		garage_id: 88,
		part_id: 392,
		quantity: 99,
	},
	{
		garage_id: 88,
		part_id: 393,
		quantity: 331,
	},
	{
		garage_id: 88,
		part_id: 394,
		quantity: 117,
	},
	{
		garage_id: 88,
		part_id: 395,
		quantity: 171,
	},
	{
		garage_id: 88,
		part_id: 396,
		quantity: 150,
	},
	{
		garage_id: 88,
		part_id: 397,
		quantity: 376,
	},
	{
		garage_id: 88,
		part_id: 398,
		quantity: 319,
	},
	{
		garage_id: 88,
		part_id: 399,
		quantity: 364,
	},
	{
		garage_id: 89,
		part_id: 0,
		quantity: 70,
	},
	{
		garage_id: 89,
		part_id: 1,
		quantity: 474,
	},
	{
		garage_id: 89,
		part_id: 2,
		quantity: 224,
	},
	{
		garage_id: 89,
		part_id: 3,
		quantity: 472,
	},
	{
		garage_id: 89,
		part_id: 4,
		quantity: 43,
	},
	{
		garage_id: 89,
		part_id: 5,
		quantity: 429,
	},
	{
		garage_id: 89,
		part_id: 6,
		quantity: 176,
	},
	{
		garage_id: 89,
		part_id: 7,
		quantity: 66,
	},
	{
		garage_id: 89,
		part_id: 8,
		quantity: 123,
	},
	{
		garage_id: 89,
		part_id: 9,
		quantity: 465,
	},
	{
		garage_id: 89,
		part_id: 10,
		quantity: 381,
	},
	{
		garage_id: 89,
		part_id: 11,
		quantity: 357,
	},
	{
		garage_id: 89,
		part_id: 12,
		quantity: 99,
	},
	{
		garage_id: 89,
		part_id: 13,
		quantity: 215,
	},
	{
		garage_id: 89,
		part_id: 14,
		quantity: 438,
	},
	{
		garage_id: 89,
		part_id: 15,
		quantity: 52,
	},
	{
		garage_id: 89,
		part_id: 16,
		quantity: 304,
	},
	{
		garage_id: 89,
		part_id: 17,
		quantity: 109,
	},
	{
		garage_id: 89,
		part_id: 18,
		quantity: 212,
	},
	{
		garage_id: 89,
		part_id: 19,
		quantity: 278,
	},
	{
		garage_id: 89,
		part_id: 20,
		quantity: 433,
	},
	{
		garage_id: 89,
		part_id: 21,
		quantity: 36,
	},
	{
		garage_id: 89,
		part_id: 22,
		quantity: 149,
	},
	{
		garage_id: 89,
		part_id: 23,
		quantity: 398,
	},
	{
		garage_id: 89,
		part_id: 24,
		quantity: 163,
	},
	{
		garage_id: 89,
		part_id: 25,
		quantity: 417,
	},
	{
		garage_id: 89,
		part_id: 26,
		quantity: 137,
	},
	{
		garage_id: 89,
		part_id: 27,
		quantity: 328,
	},
	{
		garage_id: 89,
		part_id: 28,
		quantity: 448,
	},
	{
		garage_id: 89,
		part_id: 29,
		quantity: 456,
	},
	{
		garage_id: 89,
		part_id: 30,
		quantity: 294,
	},
	{
		garage_id: 89,
		part_id: 31,
		quantity: 52,
	},
	{
		garage_id: 89,
		part_id: 32,
		quantity: 67,
	},
	{
		garage_id: 89,
		part_id: 33,
		quantity: 98,
	},
	{
		garage_id: 89,
		part_id: 34,
		quantity: 141,
	},
	{
		garage_id: 89,
		part_id: 35,
		quantity: 124,
	},
	{
		garage_id: 89,
		part_id: 36,
		quantity: 125,
	},
	{
		garage_id: 89,
		part_id: 37,
		quantity: 178,
	},
	{
		garage_id: 89,
		part_id: 38,
		quantity: 336,
	},
	{
		garage_id: 89,
		part_id: 39,
		quantity: 230,
	},
	{
		garage_id: 89,
		part_id: 40,
		quantity: 480,
	},
	{
		garage_id: 89,
		part_id: 41,
		quantity: 385,
	},
	{
		garage_id: 89,
		part_id: 42,
		quantity: 288,
	},
	{
		garage_id: 89,
		part_id: 43,
		quantity: 204,
	},
	{
		garage_id: 89,
		part_id: 44,
		quantity: 39,
	},
	{
		garage_id: 89,
		part_id: 45,
		quantity: 322,
	},
	{
		garage_id: 89,
		part_id: 46,
		quantity: 441,
	},
	{
		garage_id: 89,
		part_id: 47,
		quantity: 89,
	},
	{
		garage_id: 89,
		part_id: 48,
		quantity: 88,
	},
	{
		garage_id: 89,
		part_id: 49,
		quantity: 241,
	},
	{
		garage_id: 89,
		part_id: 50,
		quantity: 378,
	},
	{
		garage_id: 89,
		part_id: 51,
		quantity: 186,
	},
	{
		garage_id: 89,
		part_id: 52,
		quantity: 34,
	},
	{
		garage_id: 89,
		part_id: 53,
		quantity: 115,
	},
	{
		garage_id: 89,
		part_id: 54,
		quantity: 397,
	},
	{
		garage_id: 89,
		part_id: 55,
		quantity: 372,
	},
	{
		garage_id: 89,
		part_id: 56,
		quantity: 142,
	},
	{
		garage_id: 89,
		part_id: 57,
		quantity: 162,
	},
	{
		garage_id: 89,
		part_id: 58,
		quantity: 106,
	},
	{
		garage_id: 89,
		part_id: 59,
		quantity: 364,
	},
	{
		garage_id: 89,
		part_id: 60,
		quantity: 490,
	},
	{
		garage_id: 89,
		part_id: 61,
		quantity: 151,
	},
	{
		garage_id: 89,
		part_id: 62,
		quantity: 415,
	},
	{
		garage_id: 89,
		part_id: 63,
		quantity: 335,
	},
	{
		garage_id: 89,
		part_id: 64,
		quantity: 13,
	},
	{
		garage_id: 89,
		part_id: 65,
		quantity: 151,
	},
	{
		garage_id: 89,
		part_id: 66,
		quantity: 459,
	},
	{
		garage_id: 89,
		part_id: 67,
		quantity: 257,
	},
	{
		garage_id: 89,
		part_id: 68,
		quantity: 77,
	},
	{
		garage_id: 89,
		part_id: 69,
		quantity: 65,
	},
	{
		garage_id: 89,
		part_id: 70,
		quantity: 399,
	},
	{
		garage_id: 89,
		part_id: 71,
		quantity: 192,
	},
	{
		garage_id: 89,
		part_id: 72,
		quantity: 39,
	},
	{
		garage_id: 89,
		part_id: 73,
		quantity: 355,
	},
	{
		garage_id: 89,
		part_id: 74,
		quantity: 190,
	},
	{
		garage_id: 89,
		part_id: 75,
		quantity: 185,
	},
	{
		garage_id: 89,
		part_id: 76,
		quantity: 4,
	},
	{
		garage_id: 89,
		part_id: 77,
		quantity: 292,
	},
	{
		garage_id: 89,
		part_id: 78,
		quantity: 407,
	},
	{
		garage_id: 89,
		part_id: 79,
		quantity: 363,
	},
	{
		garage_id: 89,
		part_id: 80,
		quantity: 254,
	},
	{
		garage_id: 89,
		part_id: 81,
		quantity: 202,
	},
	{
		garage_id: 89,
		part_id: 82,
		quantity: 330,
	},
	{
		garage_id: 89,
		part_id: 83,
		quantity: 481,
	},
	{
		garage_id: 89,
		part_id: 84,
		quantity: 55,
	},
	{
		garage_id: 89,
		part_id: 85,
		quantity: 448,
	},
	{
		garage_id: 89,
		part_id: 86,
		quantity: 88,
	},
	{
		garage_id: 89,
		part_id: 87,
		quantity: 383,
	},
	{
		garage_id: 89,
		part_id: 88,
		quantity: 361,
	},
	{
		garage_id: 89,
		part_id: 89,
		quantity: 473,
	},
	{
		garage_id: 89,
		part_id: 90,
		quantity: 277,
	},
	{
		garage_id: 89,
		part_id: 91,
		quantity: 440,
	},
	{
		garage_id: 89,
		part_id: 92,
		quantity: 406,
	},
	{
		garage_id: 89,
		part_id: 93,
		quantity: 140,
	},
	{
		garage_id: 89,
		part_id: 94,
		quantity: 468,
	},
	{
		garage_id: 89,
		part_id: 95,
		quantity: 452,
	},
	{
		garage_id: 89,
		part_id: 96,
		quantity: 127,
	},
	{
		garage_id: 89,
		part_id: 97,
		quantity: 445,
	},
	{
		garage_id: 89,
		part_id: 98,
		quantity: 249,
	},
	{
		garage_id: 89,
		part_id: 99,
		quantity: 112,
	},
	{
		garage_id: 89,
		part_id: 100,
		quantity: 71,
	},
	{
		garage_id: 89,
		part_id: 101,
		quantity: 56,
	},
	{
		garage_id: 89,
		part_id: 102,
		quantity: 390,
	},
	{
		garage_id: 89,
		part_id: 103,
		quantity: 161,
	},
	{
		garage_id: 89,
		part_id: 104,
		quantity: 244,
	},
	{
		garage_id: 89,
		part_id: 105,
		quantity: 474,
	},
	{
		garage_id: 89,
		part_id: 106,
		quantity: 32,
	},
	{
		garage_id: 89,
		part_id: 107,
		quantity: 209,
	},
	{
		garage_id: 89,
		part_id: 108,
		quantity: 66,
	},
	{
		garage_id: 89,
		part_id: 109,
		quantity: 63,
	},
	{
		garage_id: 89,
		part_id: 110,
		quantity: 219,
	},
	{
		garage_id: 89,
		part_id: 111,
		quantity: 289,
	},
	{
		garage_id: 89,
		part_id: 112,
		quantity: 314,
	},
	{
		garage_id: 89,
		part_id: 113,
		quantity: 353,
	},
	{
		garage_id: 89,
		part_id: 114,
		quantity: 269,
	},
	{
		garage_id: 89,
		part_id: 115,
		quantity: 118,
	},
	{
		garage_id: 89,
		part_id: 116,
		quantity: 120,
	},
	{
		garage_id: 89,
		part_id: 117,
		quantity: 432,
	},
	{
		garage_id: 89,
		part_id: 118,
		quantity: 471,
	},
	{
		garage_id: 89,
		part_id: 119,
		quantity: 346,
	},
	{
		garage_id: 89,
		part_id: 120,
		quantity: 217,
	},
	{
		garage_id: 89,
		part_id: 121,
		quantity: 406,
	},
	{
		garage_id: 89,
		part_id: 122,
		quantity: 436,
	},
	{
		garage_id: 89,
		part_id: 123,
		quantity: 23,
	},
	{
		garage_id: 89,
		part_id: 124,
		quantity: 263,
	},
	{
		garage_id: 89,
		part_id: 125,
		quantity: 477,
	},
	{
		garage_id: 89,
		part_id: 126,
		quantity: 477,
	},
	{
		garage_id: 89,
		part_id: 127,
		quantity: 453,
	},
	{
		garage_id: 89,
		part_id: 128,
		quantity: 132,
	},
	{
		garage_id: 89,
		part_id: 129,
		quantity: 354,
	},
	{
		garage_id: 89,
		part_id: 130,
		quantity: 367,
	},
	{
		garage_id: 89,
		part_id: 131,
		quantity: 409,
	},
	{
		garage_id: 89,
		part_id: 132,
		quantity: 438,
	},
	{
		garage_id: 89,
		part_id: 133,
		quantity: 114,
	},
	{
		garage_id: 89,
		part_id: 134,
		quantity: 53,
	},
	{
		garage_id: 89,
		part_id: 135,
		quantity: 253,
	},
	{
		garage_id: 89,
		part_id: 136,
		quantity: 363,
	},
	{
		garage_id: 89,
		part_id: 137,
		quantity: 29,
	},
	{
		garage_id: 89,
		part_id: 138,
		quantity: 119,
	},
	{
		garage_id: 89,
		part_id: 139,
		quantity: 156,
	},
	{
		garage_id: 89,
		part_id: 140,
		quantity: 196,
	},
	{
		garage_id: 89,
		part_id: 141,
		quantity: 193,
	},
	{
		garage_id: 89,
		part_id: 142,
		quantity: 163,
	},
	{
		garage_id: 89,
		part_id: 143,
		quantity: 360,
	},
	{
		garage_id: 89,
		part_id: 144,
		quantity: 68,
	},
	{
		garage_id: 89,
		part_id: 145,
		quantity: 477,
	},
	{
		garage_id: 89,
		part_id: 146,
		quantity: 472,
	},
	{
		garage_id: 89,
		part_id: 147,
		quantity: 0,
	},
	{
		garage_id: 89,
		part_id: 148,
		quantity: 94,
	},
	{
		garage_id: 89,
		part_id: 149,
		quantity: 298,
	},
	{
		garage_id: 89,
		part_id: 150,
		quantity: 203,
	},
	{
		garage_id: 89,
		part_id: 151,
		quantity: 376,
	},
	{
		garage_id: 89,
		part_id: 152,
		quantity: 47,
	},
	{
		garage_id: 89,
		part_id: 153,
		quantity: 241,
	},
	{
		garage_id: 89,
		part_id: 154,
		quantity: 301,
	},
	{
		garage_id: 89,
		part_id: 155,
		quantity: 87,
	},
	{
		garage_id: 89,
		part_id: 156,
		quantity: 352,
	},
	{
		garage_id: 89,
		part_id: 157,
		quantity: 240,
	},
	{
		garage_id: 89,
		part_id: 158,
		quantity: 100,
	},
	{
		garage_id: 89,
		part_id: 159,
		quantity: 444,
	},
	{
		garage_id: 89,
		part_id: 160,
		quantity: 239,
	},
	{
		garage_id: 89,
		part_id: 161,
		quantity: 51,
	},
	{
		garage_id: 89,
		part_id: 162,
		quantity: 364,
	},
	{
		garage_id: 89,
		part_id: 163,
		quantity: 348,
	},
	{
		garage_id: 89,
		part_id: 164,
		quantity: 78,
	},
	{
		garage_id: 89,
		part_id: 165,
		quantity: 353,
	},
	{
		garage_id: 89,
		part_id: 166,
		quantity: 403,
	},
	{
		garage_id: 89,
		part_id: 167,
		quantity: 401,
	},
	{
		garage_id: 89,
		part_id: 168,
		quantity: 120,
	},
	{
		garage_id: 89,
		part_id: 169,
		quantity: 458,
	},
	{
		garage_id: 89,
		part_id: 170,
		quantity: 310,
	},
	{
		garage_id: 89,
		part_id: 171,
		quantity: 448,
	},
	{
		garage_id: 89,
		part_id: 172,
		quantity: 61,
	},
	{
		garage_id: 89,
		part_id: 173,
		quantity: 355,
	},
	{
		garage_id: 89,
		part_id: 174,
		quantity: 103,
	},
	{
		garage_id: 89,
		part_id: 175,
		quantity: 16,
	},
	{
		garage_id: 89,
		part_id: 176,
		quantity: 144,
	},
	{
		garage_id: 89,
		part_id: 177,
		quantity: 124,
	},
	{
		garage_id: 89,
		part_id: 178,
		quantity: 38,
	},
	{
		garage_id: 89,
		part_id: 179,
		quantity: 365,
	},
	{
		garage_id: 89,
		part_id: 180,
		quantity: 37,
	},
	{
		garage_id: 89,
		part_id: 181,
		quantity: 496,
	},
	{
		garage_id: 89,
		part_id: 182,
		quantity: 421,
	},
	{
		garage_id: 89,
		part_id: 183,
		quantity: 64,
	},
	{
		garage_id: 89,
		part_id: 184,
		quantity: 80,
	},
	{
		garage_id: 89,
		part_id: 185,
		quantity: 325,
	},
	{
		garage_id: 89,
		part_id: 186,
		quantity: 392,
	},
	{
		garage_id: 89,
		part_id: 187,
		quantity: 299,
	},
	{
		garage_id: 89,
		part_id: 188,
		quantity: 63,
	},
	{
		garage_id: 89,
		part_id: 189,
		quantity: 464,
	},
	{
		garage_id: 89,
		part_id: 190,
		quantity: 368,
	},
	{
		garage_id: 89,
		part_id: 191,
		quantity: 256,
	},
	{
		garage_id: 89,
		part_id: 192,
		quantity: 160,
	},
	{
		garage_id: 89,
		part_id: 193,
		quantity: 154,
	},
	{
		garage_id: 89,
		part_id: 194,
		quantity: 218,
	},
	{
		garage_id: 89,
		part_id: 195,
		quantity: 324,
	},
	{
		garage_id: 89,
		part_id: 196,
		quantity: 275,
	},
	{
		garage_id: 89,
		part_id: 197,
		quantity: 474,
	},
	{
		garage_id: 89,
		part_id: 198,
		quantity: 127,
	},
	{
		garage_id: 89,
		part_id: 199,
		quantity: 225,
	},
	{
		garage_id: 89,
		part_id: 200,
		quantity: 430,
	},
	{
		garage_id: 89,
		part_id: 201,
		quantity: 433,
	},
	{
		garage_id: 89,
		part_id: 202,
		quantity: 183,
	},
	{
		garage_id: 89,
		part_id: 203,
		quantity: 415,
	},
	{
		garage_id: 89,
		part_id: 204,
		quantity: 61,
	},
	{
		garage_id: 89,
		part_id: 205,
		quantity: 166,
	},
	{
		garage_id: 89,
		part_id: 206,
		quantity: 377,
	},
	{
		garage_id: 89,
		part_id: 207,
		quantity: 337,
	},
	{
		garage_id: 89,
		part_id: 208,
		quantity: 117,
	},
	{
		garage_id: 89,
		part_id: 209,
		quantity: 241,
	},
	{
		garage_id: 89,
		part_id: 210,
		quantity: 225,
	},
	{
		garage_id: 89,
		part_id: 211,
		quantity: 236,
	},
	{
		garage_id: 89,
		part_id: 212,
		quantity: 33,
	},
	{
		garage_id: 89,
		part_id: 213,
		quantity: 459,
	},
	{
		garage_id: 89,
		part_id: 214,
		quantity: 272,
	},
	{
		garage_id: 89,
		part_id: 215,
		quantity: 101,
	},
	{
		garage_id: 89,
		part_id: 216,
		quantity: 468,
	},
	{
		garage_id: 89,
		part_id: 217,
		quantity: 366,
	},
	{
		garage_id: 89,
		part_id: 218,
		quantity: 41,
	},
	{
		garage_id: 89,
		part_id: 219,
		quantity: 200,
	},
	{
		garage_id: 89,
		part_id: 220,
		quantity: 378,
	},
	{
		garage_id: 89,
		part_id: 221,
		quantity: 139,
	},
	{
		garage_id: 89,
		part_id: 222,
		quantity: 18,
	},
	{
		garage_id: 89,
		part_id: 223,
		quantity: 335,
	},
	{
		garage_id: 89,
		part_id: 224,
		quantity: 249,
	},
	{
		garage_id: 89,
		part_id: 225,
		quantity: 365,
	},
	{
		garage_id: 89,
		part_id: 226,
		quantity: 418,
	},
	{
		garage_id: 89,
		part_id: 227,
		quantity: 143,
	},
	{
		garage_id: 89,
		part_id: 228,
		quantity: 153,
	},
	{
		garage_id: 89,
		part_id: 229,
		quantity: 347,
	},
	{
		garage_id: 89,
		part_id: 230,
		quantity: 274,
	},
	{
		garage_id: 89,
		part_id: 231,
		quantity: 446,
	},
	{
		garage_id: 89,
		part_id: 232,
		quantity: 209,
	},
	{
		garage_id: 89,
		part_id: 233,
		quantity: 378,
	},
	{
		garage_id: 89,
		part_id: 234,
		quantity: 76,
	},
	{
		garage_id: 89,
		part_id: 235,
		quantity: 364,
	},
	{
		garage_id: 89,
		part_id: 236,
		quantity: 362,
	},
	{
		garage_id: 89,
		part_id: 237,
		quantity: 492,
	},
	{
		garage_id: 89,
		part_id: 238,
		quantity: 246,
	},
	{
		garage_id: 89,
		part_id: 239,
		quantity: 2,
	},
	{
		garage_id: 89,
		part_id: 240,
		quantity: 487,
	},
	{
		garage_id: 89,
		part_id: 241,
		quantity: 392,
	},
	{
		garage_id: 89,
		part_id: 242,
		quantity: 471,
	},
	{
		garage_id: 89,
		part_id: 243,
		quantity: 467,
	},
	{
		garage_id: 89,
		part_id: 244,
		quantity: 452,
	},
	{
		garage_id: 89,
		part_id: 245,
		quantity: 430,
	},
	{
		garage_id: 89,
		part_id: 246,
		quantity: 41,
	},
	{
		garage_id: 89,
		part_id: 247,
		quantity: 323,
	},
	{
		garage_id: 89,
		part_id: 248,
		quantity: 73,
	},
	{
		garage_id: 89,
		part_id: 249,
		quantity: 219,
	},
	{
		garage_id: 89,
		part_id: 250,
		quantity: 380,
	},
	{
		garage_id: 89,
		part_id: 251,
		quantity: 454,
	},
	{
		garage_id: 89,
		part_id: 252,
		quantity: 475,
	},
	{
		garage_id: 89,
		part_id: 253,
		quantity: 365,
	},
	{
		garage_id: 89,
		part_id: 254,
		quantity: 283,
	},
	{
		garage_id: 89,
		part_id: 255,
		quantity: 321,
	},
	{
		garage_id: 89,
		part_id: 256,
		quantity: 19,
	},
	{
		garage_id: 89,
		part_id: 257,
		quantity: 204,
	},
	{
		garage_id: 89,
		part_id: 258,
		quantity: 54,
	},
	{
		garage_id: 89,
		part_id: 259,
		quantity: 122,
	},
	{
		garage_id: 89,
		part_id: 260,
		quantity: 115,
	},
	{
		garage_id: 89,
		part_id: 261,
		quantity: 201,
	},
	{
		garage_id: 89,
		part_id: 262,
		quantity: 436,
	},
	{
		garage_id: 89,
		part_id: 263,
		quantity: 455,
	},
	{
		garage_id: 89,
		part_id: 264,
		quantity: 321,
	},
	{
		garage_id: 89,
		part_id: 265,
		quantity: 448,
	},
	{
		garage_id: 89,
		part_id: 266,
		quantity: 187,
	},
	{
		garage_id: 89,
		part_id: 267,
		quantity: 333,
	},
	{
		garage_id: 89,
		part_id: 268,
		quantity: 182,
	},
	{
		garage_id: 89,
		part_id: 269,
		quantity: 20,
	},
	{
		garage_id: 89,
		part_id: 270,
		quantity: 247,
	},
	{
		garage_id: 89,
		part_id: 271,
		quantity: 181,
	},
	{
		garage_id: 89,
		part_id: 272,
		quantity: 444,
	},
	{
		garage_id: 89,
		part_id: 273,
		quantity: 32,
	},
	{
		garage_id: 89,
		part_id: 274,
		quantity: 337,
	},
	{
		garage_id: 89,
		part_id: 275,
		quantity: 212,
	},
	{
		garage_id: 89,
		part_id: 276,
		quantity: 383,
	},
	{
		garage_id: 89,
		part_id: 277,
		quantity: 395,
	},
	{
		garage_id: 89,
		part_id: 278,
		quantity: 467,
	},
	{
		garage_id: 89,
		part_id: 279,
		quantity: 26,
	},
	{
		garage_id: 89,
		part_id: 280,
		quantity: 430,
	},
	{
		garage_id: 89,
		part_id: 281,
		quantity: 150,
	},
	{
		garage_id: 89,
		part_id: 282,
		quantity: 493,
	},
	{
		garage_id: 89,
		part_id: 283,
		quantity: 116,
	},
	{
		garage_id: 89,
		part_id: 284,
		quantity: 197,
	},
	{
		garage_id: 89,
		part_id: 285,
		quantity: 75,
	},
	{
		garage_id: 89,
		part_id: 286,
		quantity: 161,
	},
	{
		garage_id: 89,
		part_id: 287,
		quantity: 111,
	},
	{
		garage_id: 89,
		part_id: 288,
		quantity: 407,
	},
	{
		garage_id: 89,
		part_id: 289,
		quantity: 91,
	},
	{
		garage_id: 89,
		part_id: 290,
		quantity: 259,
	},
	{
		garage_id: 89,
		part_id: 291,
		quantity: 105,
	},
	{
		garage_id: 89,
		part_id: 292,
		quantity: 74,
	},
	{
		garage_id: 89,
		part_id: 293,
		quantity: 190,
	},
	{
		garage_id: 89,
		part_id: 294,
		quantity: 233,
	},
	{
		garage_id: 89,
		part_id: 295,
		quantity: 436,
	},
	{
		garage_id: 89,
		part_id: 296,
		quantity: 28,
	},
	{
		garage_id: 89,
		part_id: 297,
		quantity: 73,
	},
	{
		garage_id: 89,
		part_id: 298,
		quantity: 101,
	},
	{
		garage_id: 89,
		part_id: 299,
		quantity: 417,
	},
	{
		garage_id: 89,
		part_id: 300,
		quantity: 337,
	},
	{
		garage_id: 89,
		part_id: 301,
		quantity: 500,
	},
	{
		garage_id: 89,
		part_id: 302,
		quantity: 217,
	},
	{
		garage_id: 89,
		part_id: 303,
		quantity: 302,
	},
	{
		garage_id: 89,
		part_id: 304,
		quantity: 470,
	},
	{
		garage_id: 89,
		part_id: 305,
		quantity: 360,
	},
	{
		garage_id: 89,
		part_id: 306,
		quantity: 86,
	},
	{
		garage_id: 89,
		part_id: 307,
		quantity: 98,
	},
	{
		garage_id: 89,
		part_id: 308,
		quantity: 490,
	},
	{
		garage_id: 89,
		part_id: 309,
		quantity: 280,
	},
	{
		garage_id: 89,
		part_id: 310,
		quantity: 165,
	},
	{
		garage_id: 89,
		part_id: 311,
		quantity: 301,
	},
	{
		garage_id: 89,
		part_id: 312,
		quantity: 303,
	},
	{
		garage_id: 89,
		part_id: 313,
		quantity: 40,
	},
	{
		garage_id: 89,
		part_id: 314,
		quantity: 210,
	},
	{
		garage_id: 89,
		part_id: 315,
		quantity: 239,
	},
	{
		garage_id: 89,
		part_id: 316,
		quantity: 77,
	},
	{
		garage_id: 89,
		part_id: 317,
		quantity: 291,
	},
	{
		garage_id: 89,
		part_id: 318,
		quantity: 415,
	},
	{
		garage_id: 89,
		part_id: 319,
		quantity: 304,
	},
	{
		garage_id: 89,
		part_id: 320,
		quantity: 476,
	},
	{
		garage_id: 89,
		part_id: 321,
		quantity: 262,
	},
	{
		garage_id: 89,
		part_id: 322,
		quantity: 299,
	},
	{
		garage_id: 89,
		part_id: 323,
		quantity: 265,
	},
	{
		garage_id: 89,
		part_id: 324,
		quantity: 172,
	},
	{
		garage_id: 89,
		part_id: 325,
		quantity: 396,
	},
	{
		garage_id: 89,
		part_id: 326,
		quantity: 470,
	},
	{
		garage_id: 89,
		part_id: 327,
		quantity: 235,
	},
	{
		garage_id: 89,
		part_id: 328,
		quantity: 378,
	},
	{
		garage_id: 89,
		part_id: 329,
		quantity: 183,
	},
	{
		garage_id: 89,
		part_id: 330,
		quantity: 430,
	},
	{
		garage_id: 89,
		part_id: 331,
		quantity: 192,
	},
	{
		garage_id: 89,
		part_id: 332,
		quantity: 64,
	},
	{
		garage_id: 89,
		part_id: 333,
		quantity: 305,
	},
	{
		garage_id: 89,
		part_id: 334,
		quantity: 74,
	},
	{
		garage_id: 89,
		part_id: 335,
		quantity: 246,
	},
	{
		garage_id: 89,
		part_id: 336,
		quantity: 71,
	},
	{
		garage_id: 89,
		part_id: 337,
		quantity: 62,
	},
	{
		garage_id: 89,
		part_id: 338,
		quantity: 35,
	},
	{
		garage_id: 89,
		part_id: 339,
		quantity: 35,
	},
	{
		garage_id: 89,
		part_id: 340,
		quantity: 118,
	},
	{
		garage_id: 89,
		part_id: 341,
		quantity: 32,
	},
	{
		garage_id: 89,
		part_id: 342,
		quantity: 133,
	},
	{
		garage_id: 89,
		part_id: 343,
		quantity: 155,
	},
	{
		garage_id: 89,
		part_id: 344,
		quantity: 435,
	},
	{
		garage_id: 89,
		part_id: 345,
		quantity: 269,
	},
	{
		garage_id: 89,
		part_id: 346,
		quantity: 360,
	},
	{
		garage_id: 89,
		part_id: 347,
		quantity: 22,
	},
	{
		garage_id: 89,
		part_id: 348,
		quantity: 137,
	},
	{
		garage_id: 89,
		part_id: 349,
		quantity: 381,
	},
	{
		garage_id: 89,
		part_id: 350,
		quantity: 85,
	},
	{
		garage_id: 89,
		part_id: 351,
		quantity: 205,
	},
	{
		garage_id: 89,
		part_id: 352,
		quantity: 118,
	},
	{
		garage_id: 89,
		part_id: 353,
		quantity: 140,
	},
	{
		garage_id: 89,
		part_id: 354,
		quantity: 412,
	},
	{
		garage_id: 89,
		part_id: 355,
		quantity: 285,
	},
	{
		garage_id: 89,
		part_id: 356,
		quantity: 458,
	},
	{
		garage_id: 89,
		part_id: 357,
		quantity: 39,
	},
	{
		garage_id: 89,
		part_id: 358,
		quantity: 154,
	},
	{
		garage_id: 89,
		part_id: 359,
		quantity: 480,
	},
	{
		garage_id: 89,
		part_id: 360,
		quantity: 67,
	},
	{
		garage_id: 89,
		part_id: 361,
		quantity: 50,
	},
	{
		garage_id: 89,
		part_id: 362,
		quantity: 54,
	},
	{
		garage_id: 89,
		part_id: 363,
		quantity: 432,
	},
	{
		garage_id: 89,
		part_id: 364,
		quantity: 127,
	},
	{
		garage_id: 89,
		part_id: 365,
		quantity: 139,
	},
	{
		garage_id: 89,
		part_id: 366,
		quantity: 256,
	},
	{
		garage_id: 89,
		part_id: 367,
		quantity: 115,
	},
	{
		garage_id: 89,
		part_id: 368,
		quantity: 120,
	},
	{
		garage_id: 89,
		part_id: 369,
		quantity: 307,
	},
	{
		garage_id: 89,
		part_id: 370,
		quantity: 141,
	},
	{
		garage_id: 89,
		part_id: 371,
		quantity: 395,
	},
	{
		garage_id: 89,
		part_id: 372,
		quantity: 196,
	},
	{
		garage_id: 89,
		part_id: 373,
		quantity: 472,
	},
	{
		garage_id: 89,
		part_id: 374,
		quantity: 49,
	},
	{
		garage_id: 89,
		part_id: 375,
		quantity: 332,
	},
	{
		garage_id: 89,
		part_id: 376,
		quantity: 314,
	},
	{
		garage_id: 89,
		part_id: 377,
		quantity: 492,
	},
	{
		garage_id: 89,
		part_id: 378,
		quantity: 36,
	},
	{
		garage_id: 89,
		part_id: 379,
		quantity: 430,
	},
	{
		garage_id: 89,
		part_id: 380,
		quantity: 66,
	},
	{
		garage_id: 89,
		part_id: 381,
		quantity: 272,
	},
	{
		garage_id: 89,
		part_id: 382,
		quantity: 370,
	},
	{
		garage_id: 89,
		part_id: 383,
		quantity: 428,
	},
	{
		garage_id: 89,
		part_id: 384,
		quantity: 481,
	},
	{
		garage_id: 89,
		part_id: 385,
		quantity: 417,
	},
	{
		garage_id: 89,
		part_id: 386,
		quantity: 314,
	},
	{
		garage_id: 89,
		part_id: 387,
		quantity: 330,
	},
	{
		garage_id: 89,
		part_id: 388,
		quantity: 282,
	},
	{
		garage_id: 89,
		part_id: 389,
		quantity: 395,
	},
	{
		garage_id: 89,
		part_id: 390,
		quantity: 318,
	},
	{
		garage_id: 89,
		part_id: 391,
		quantity: 37,
	},
	{
		garage_id: 89,
		part_id: 392,
		quantity: 337,
	},
	{
		garage_id: 89,
		part_id: 393,
		quantity: 340,
	},
	{
		garage_id: 89,
		part_id: 394,
		quantity: 422,
	},
	{
		garage_id: 89,
		part_id: 395,
		quantity: 290,
	},
	{
		garage_id: 89,
		part_id: 396,
		quantity: 410,
	},
	{
		garage_id: 89,
		part_id: 397,
		quantity: 420,
	},
	{
		garage_id: 89,
		part_id: 398,
		quantity: 201,
	},
	{
		garage_id: 89,
		part_id: 399,
		quantity: 159,
	},
	{
		garage_id: 90,
		part_id: 0,
		quantity: 352,
	},
	{
		garage_id: 90,
		part_id: 1,
		quantity: 341,
	},
	{
		garage_id: 90,
		part_id: 2,
		quantity: 342,
	},
	{
		garage_id: 90,
		part_id: 3,
		quantity: 498,
	},
	{
		garage_id: 90,
		part_id: 4,
		quantity: 265,
	},
	{
		garage_id: 90,
		part_id: 5,
		quantity: 38,
	},
	{
		garage_id: 90,
		part_id: 6,
		quantity: 95,
	},
	{
		garage_id: 90,
		part_id: 7,
		quantity: 1,
	},
	{
		garage_id: 90,
		part_id: 8,
		quantity: 29,
	},
	{
		garage_id: 90,
		part_id: 9,
		quantity: 0,
	},
	{
		garage_id: 90,
		part_id: 10,
		quantity: 408,
	},
	{
		garage_id: 90,
		part_id: 11,
		quantity: 99,
	},
	{
		garage_id: 90,
		part_id: 12,
		quantity: 110,
	},
	{
		garage_id: 90,
		part_id: 13,
		quantity: 279,
	},
	{
		garage_id: 90,
		part_id: 14,
		quantity: 291,
	},
	{
		garage_id: 90,
		part_id: 15,
		quantity: 115,
	},
	{
		garage_id: 90,
		part_id: 16,
		quantity: 348,
	},
	{
		garage_id: 90,
		part_id: 17,
		quantity: 74,
	},
	{
		garage_id: 90,
		part_id: 18,
		quantity: 20,
	},
	{
		garage_id: 90,
		part_id: 19,
		quantity: 207,
	},
	{
		garage_id: 90,
		part_id: 20,
		quantity: 23,
	},
	{
		garage_id: 90,
		part_id: 21,
		quantity: 438,
	},
	{
		garage_id: 90,
		part_id: 22,
		quantity: 271,
	},
	{
		garage_id: 90,
		part_id: 23,
		quantity: 436,
	},
	{
		garage_id: 90,
		part_id: 24,
		quantity: 243,
	},
	{
		garage_id: 90,
		part_id: 25,
		quantity: 406,
	},
	{
		garage_id: 90,
		part_id: 26,
		quantity: 170,
	},
	{
		garage_id: 90,
		part_id: 27,
		quantity: 420,
	},
	{
		garage_id: 90,
		part_id: 28,
		quantity: 89,
	},
	{
		garage_id: 90,
		part_id: 29,
		quantity: 112,
	},
	{
		garage_id: 90,
		part_id: 30,
		quantity: 116,
	},
	{
		garage_id: 90,
		part_id: 31,
		quantity: 59,
	},
	{
		garage_id: 90,
		part_id: 32,
		quantity: 425,
	},
	{
		garage_id: 90,
		part_id: 33,
		quantity: 156,
	},
	{
		garage_id: 90,
		part_id: 34,
		quantity: 22,
	},
	{
		garage_id: 90,
		part_id: 35,
		quantity: 262,
	},
	{
		garage_id: 90,
		part_id: 36,
		quantity: 68,
	},
	{
		garage_id: 90,
		part_id: 37,
		quantity: 294,
	},
	{
		garage_id: 90,
		part_id: 38,
		quantity: 427,
	},
	{
		garage_id: 90,
		part_id: 39,
		quantity: 27,
	},
	{
		garage_id: 90,
		part_id: 40,
		quantity: 39,
	},
	{
		garage_id: 90,
		part_id: 41,
		quantity: 294,
	},
	{
		garage_id: 90,
		part_id: 42,
		quantity: 477,
	},
	{
		garage_id: 90,
		part_id: 43,
		quantity: 389,
	},
	{
		garage_id: 90,
		part_id: 44,
		quantity: 259,
	},
	{
		garage_id: 90,
		part_id: 45,
		quantity: 341,
	},
	{
		garage_id: 90,
		part_id: 46,
		quantity: 431,
	},
	{
		garage_id: 90,
		part_id: 47,
		quantity: 57,
	},
	{
		garage_id: 90,
		part_id: 48,
		quantity: 401,
	},
	{
		garage_id: 90,
		part_id: 49,
		quantity: 144,
	},
	{
		garage_id: 90,
		part_id: 50,
		quantity: 435,
	},
	{
		garage_id: 90,
		part_id: 51,
		quantity: 206,
	},
	{
		garage_id: 90,
		part_id: 52,
		quantity: 337,
	},
	{
		garage_id: 90,
		part_id: 53,
		quantity: 431,
	},
	{
		garage_id: 90,
		part_id: 54,
		quantity: 198,
	},
	{
		garage_id: 90,
		part_id: 55,
		quantity: 352,
	},
	{
		garage_id: 90,
		part_id: 56,
		quantity: 166,
	},
	{
		garage_id: 90,
		part_id: 57,
		quantity: 150,
	},
	{
		garage_id: 90,
		part_id: 58,
		quantity: 267,
	},
	{
		garage_id: 90,
		part_id: 59,
		quantity: 44,
	},
	{
		garage_id: 90,
		part_id: 60,
		quantity: 375,
	},
	{
		garage_id: 90,
		part_id: 61,
		quantity: 301,
	},
	{
		garage_id: 90,
		part_id: 62,
		quantity: 249,
	},
	{
		garage_id: 90,
		part_id: 63,
		quantity: 323,
	},
	{
		garage_id: 90,
		part_id: 64,
		quantity: 189,
	},
	{
		garage_id: 90,
		part_id: 65,
		quantity: 295,
	},
	{
		garage_id: 90,
		part_id: 66,
		quantity: 477,
	},
	{
		garage_id: 90,
		part_id: 67,
		quantity: 313,
	},
	{
		garage_id: 90,
		part_id: 68,
		quantity: 111,
	},
	{
		garage_id: 90,
		part_id: 69,
		quantity: 266,
	},
	{
		garage_id: 90,
		part_id: 70,
		quantity: 165,
	},
	{
		garage_id: 90,
		part_id: 71,
		quantity: 383,
	},
	{
		garage_id: 90,
		part_id: 72,
		quantity: 489,
	},
	{
		garage_id: 90,
		part_id: 73,
		quantity: 491,
	},
	{
		garage_id: 90,
		part_id: 74,
		quantity: 369,
	},
	{
		garage_id: 90,
		part_id: 75,
		quantity: 472,
	},
	{
		garage_id: 90,
		part_id: 76,
		quantity: 461,
	},
	{
		garage_id: 90,
		part_id: 77,
		quantity: 51,
	},
	{
		garage_id: 90,
		part_id: 78,
		quantity: 217,
	},
	{
		garage_id: 90,
		part_id: 79,
		quantity: 89,
	},
	{
		garage_id: 90,
		part_id: 80,
		quantity: 263,
	},
	{
		garage_id: 90,
		part_id: 81,
		quantity: 493,
	},
	{
		garage_id: 90,
		part_id: 82,
		quantity: 470,
	},
	{
		garage_id: 90,
		part_id: 83,
		quantity: 194,
	},
	{
		garage_id: 90,
		part_id: 84,
		quantity: 58,
	},
	{
		garage_id: 90,
		part_id: 85,
		quantity: 421,
	},
	{
		garage_id: 90,
		part_id: 86,
		quantity: 168,
	},
	{
		garage_id: 90,
		part_id: 87,
		quantity: 477,
	},
	{
		garage_id: 90,
		part_id: 88,
		quantity: 463,
	},
	{
		garage_id: 90,
		part_id: 89,
		quantity: 392,
	},
	{
		garage_id: 90,
		part_id: 90,
		quantity: 429,
	},
	{
		garage_id: 90,
		part_id: 91,
		quantity: 54,
	},
	{
		garage_id: 90,
		part_id: 92,
		quantity: 45,
	},
	{
		garage_id: 90,
		part_id: 93,
		quantity: 393,
	},
	{
		garage_id: 90,
		part_id: 94,
		quantity: 72,
	},
	{
		garage_id: 90,
		part_id: 95,
		quantity: 365,
	},
	{
		garage_id: 90,
		part_id: 96,
		quantity: 370,
	},
	{
		garage_id: 90,
		part_id: 97,
		quantity: 84,
	},
	{
		garage_id: 90,
		part_id: 98,
		quantity: 179,
	},
	{
		garage_id: 90,
		part_id: 99,
		quantity: 52,
	},
	{
		garage_id: 90,
		part_id: 100,
		quantity: 18,
	},
	{
		garage_id: 90,
		part_id: 101,
		quantity: 385,
	},
	{
		garage_id: 90,
		part_id: 102,
		quantity: 168,
	},
	{
		garage_id: 90,
		part_id: 103,
		quantity: 191,
	},
	{
		garage_id: 90,
		part_id: 104,
		quantity: 280,
	},
	{
		garage_id: 90,
		part_id: 105,
		quantity: 466,
	},
	{
		garage_id: 90,
		part_id: 106,
		quantity: 362,
	},
	{
		garage_id: 90,
		part_id: 107,
		quantity: 169,
	},
	{
		garage_id: 90,
		part_id: 108,
		quantity: 261,
	},
	{
		garage_id: 90,
		part_id: 109,
		quantity: 250,
	},
	{
		garage_id: 90,
		part_id: 110,
		quantity: 279,
	},
	{
		garage_id: 90,
		part_id: 111,
		quantity: 122,
	},
	{
		garage_id: 90,
		part_id: 112,
		quantity: 114,
	},
	{
		garage_id: 90,
		part_id: 113,
		quantity: 183,
	},
	{
		garage_id: 90,
		part_id: 114,
		quantity: 421,
	},
	{
		garage_id: 90,
		part_id: 115,
		quantity: 437,
	},
	{
		garage_id: 90,
		part_id: 116,
		quantity: 82,
	},
	{
		garage_id: 90,
		part_id: 117,
		quantity: 268,
	},
	{
		garage_id: 90,
		part_id: 118,
		quantity: 413,
	},
	{
		garage_id: 90,
		part_id: 119,
		quantity: 3,
	},
	{
		garage_id: 90,
		part_id: 120,
		quantity: 137,
	},
	{
		garage_id: 90,
		part_id: 121,
		quantity: 465,
	},
	{
		garage_id: 90,
		part_id: 122,
		quantity: 192,
	},
	{
		garage_id: 90,
		part_id: 123,
		quantity: 187,
	},
	{
		garage_id: 90,
		part_id: 124,
		quantity: 360,
	},
	{
		garage_id: 90,
		part_id: 125,
		quantity: 341,
	},
	{
		garage_id: 90,
		part_id: 126,
		quantity: 235,
	},
	{
		garage_id: 90,
		part_id: 127,
		quantity: 183,
	},
	{
		garage_id: 90,
		part_id: 128,
		quantity: 78,
	},
	{
		garage_id: 90,
		part_id: 129,
		quantity: 58,
	},
	{
		garage_id: 90,
		part_id: 130,
		quantity: 378,
	},
	{
		garage_id: 90,
		part_id: 131,
		quantity: 313,
	},
	{
		garage_id: 90,
		part_id: 132,
		quantity: 350,
	},
	{
		garage_id: 90,
		part_id: 133,
		quantity: 465,
	},
	{
		garage_id: 90,
		part_id: 134,
		quantity: 422,
	},
	{
		garage_id: 90,
		part_id: 135,
		quantity: 353,
	},
	{
		garage_id: 90,
		part_id: 136,
		quantity: 318,
	},
	{
		garage_id: 90,
		part_id: 137,
		quantity: 241,
	},
	{
		garage_id: 90,
		part_id: 138,
		quantity: 107,
	},
	{
		garage_id: 90,
		part_id: 139,
		quantity: 435,
	},
	{
		garage_id: 90,
		part_id: 140,
		quantity: 273,
	},
	{
		garage_id: 90,
		part_id: 141,
		quantity: 137,
	},
	{
		garage_id: 90,
		part_id: 142,
		quantity: 62,
	},
	{
		garage_id: 90,
		part_id: 143,
		quantity: 125,
	},
	{
		garage_id: 90,
		part_id: 144,
		quantity: 62,
	},
	{
		garage_id: 90,
		part_id: 145,
		quantity: 296,
	},
	{
		garage_id: 90,
		part_id: 146,
		quantity: 224,
	},
	{
		garage_id: 90,
		part_id: 147,
		quantity: 191,
	},
	{
		garage_id: 90,
		part_id: 148,
		quantity: 83,
	},
	{
		garage_id: 90,
		part_id: 149,
		quantity: 316,
	},
	{
		garage_id: 90,
		part_id: 150,
		quantity: 381,
	},
	{
		garage_id: 90,
		part_id: 151,
		quantity: 27,
	},
	{
		garage_id: 90,
		part_id: 152,
		quantity: 221,
	},
	{
		garage_id: 90,
		part_id: 153,
		quantity: 240,
	},
	{
		garage_id: 90,
		part_id: 154,
		quantity: 280,
	},
	{
		garage_id: 90,
		part_id: 155,
		quantity: 254,
	},
	{
		garage_id: 90,
		part_id: 156,
		quantity: 382,
	},
	{
		garage_id: 90,
		part_id: 157,
		quantity: 456,
	},
	{
		garage_id: 90,
		part_id: 158,
		quantity: 12,
	},
	{
		garage_id: 90,
		part_id: 159,
		quantity: 57,
	},
	{
		garage_id: 90,
		part_id: 160,
		quantity: 267,
	},
	{
		garage_id: 90,
		part_id: 161,
		quantity: 46,
	},
	{
		garage_id: 90,
		part_id: 162,
		quantity: 467,
	},
	{
		garage_id: 90,
		part_id: 163,
		quantity: 436,
	},
	{
		garage_id: 90,
		part_id: 164,
		quantity: 498,
	},
	{
		garage_id: 90,
		part_id: 165,
		quantity: 400,
	},
	{
		garage_id: 90,
		part_id: 166,
		quantity: 443,
	},
	{
		garage_id: 90,
		part_id: 167,
		quantity: 460,
	},
	{
		garage_id: 90,
		part_id: 168,
		quantity: 434,
	},
	{
		garage_id: 90,
		part_id: 169,
		quantity: 396,
	},
	{
		garage_id: 90,
		part_id: 170,
		quantity: 250,
	},
	{
		garage_id: 90,
		part_id: 171,
		quantity: 387,
	},
	{
		garage_id: 90,
		part_id: 172,
		quantity: 271,
	},
	{
		garage_id: 90,
		part_id: 173,
		quantity: 275,
	},
	{
		garage_id: 90,
		part_id: 174,
		quantity: 300,
	},
	{
		garage_id: 90,
		part_id: 175,
		quantity: 190,
	},
	{
		garage_id: 90,
		part_id: 176,
		quantity: 432,
	},
	{
		garage_id: 90,
		part_id: 177,
		quantity: 101,
	},
	{
		garage_id: 90,
		part_id: 178,
		quantity: 20,
	},
	{
		garage_id: 90,
		part_id: 179,
		quantity: 382,
	},
	{
		garage_id: 90,
		part_id: 180,
		quantity: 490,
	},
	{
		garage_id: 90,
		part_id: 181,
		quantity: 8,
	},
	{
		garage_id: 90,
		part_id: 182,
		quantity: 386,
	},
	{
		garage_id: 90,
		part_id: 183,
		quantity: 270,
	},
	{
		garage_id: 90,
		part_id: 184,
		quantity: 38,
	},
	{
		garage_id: 90,
		part_id: 185,
		quantity: 461,
	},
	{
		garage_id: 90,
		part_id: 186,
		quantity: 155,
	},
	{
		garage_id: 90,
		part_id: 187,
		quantity: 348,
	},
	{
		garage_id: 90,
		part_id: 188,
		quantity: 406,
	},
	{
		garage_id: 90,
		part_id: 189,
		quantity: 486,
	},
	{
		garage_id: 90,
		part_id: 190,
		quantity: 464,
	},
	{
		garage_id: 90,
		part_id: 191,
		quantity: 206,
	},
	{
		garage_id: 90,
		part_id: 192,
		quantity: 482,
	},
	{
		garage_id: 90,
		part_id: 193,
		quantity: 105,
	},
	{
		garage_id: 90,
		part_id: 194,
		quantity: 204,
	},
	{
		garage_id: 90,
		part_id: 195,
		quantity: 191,
	},
	{
		garage_id: 90,
		part_id: 196,
		quantity: 172,
	},
	{
		garage_id: 90,
		part_id: 197,
		quantity: 284,
	},
	{
		garage_id: 90,
		part_id: 198,
		quantity: 309,
	},
	{
		garage_id: 90,
		part_id: 199,
		quantity: 190,
	},
	{
		garage_id: 90,
		part_id: 200,
		quantity: 17,
	},
	{
		garage_id: 90,
		part_id: 201,
		quantity: 377,
	},
	{
		garage_id: 90,
		part_id: 202,
		quantity: 237,
	},
	{
		garage_id: 90,
		part_id: 203,
		quantity: 74,
	},
	{
		garage_id: 90,
		part_id: 204,
		quantity: 154,
	},
	{
		garage_id: 90,
		part_id: 205,
		quantity: 198,
	},
	{
		garage_id: 90,
		part_id: 206,
		quantity: 212,
	},
	{
		garage_id: 90,
		part_id: 207,
		quantity: 109,
	},
	{
		garage_id: 90,
		part_id: 208,
		quantity: 127,
	},
	{
		garage_id: 90,
		part_id: 209,
		quantity: 267,
	},
	{
		garage_id: 90,
		part_id: 210,
		quantity: 373,
	},
	{
		garage_id: 90,
		part_id: 211,
		quantity: 296,
	},
	{
		garage_id: 90,
		part_id: 212,
		quantity: 466,
	},
	{
		garage_id: 90,
		part_id: 213,
		quantity: 103,
	},
	{
		garage_id: 90,
		part_id: 214,
		quantity: 220,
	},
	{
		garage_id: 90,
		part_id: 215,
		quantity: 127,
	},
	{
		garage_id: 90,
		part_id: 216,
		quantity: 118,
	},
	{
		garage_id: 90,
		part_id: 217,
		quantity: 361,
	},
	{
		garage_id: 90,
		part_id: 218,
		quantity: 120,
	},
	{
		garage_id: 90,
		part_id: 219,
		quantity: 159,
	},
	{
		garage_id: 90,
		part_id: 220,
		quantity: 500,
	},
	{
		garage_id: 90,
		part_id: 221,
		quantity: 136,
	},
	{
		garage_id: 90,
		part_id: 222,
		quantity: 499,
	},
	{
		garage_id: 90,
		part_id: 223,
		quantity: 108,
	},
	{
		garage_id: 90,
		part_id: 224,
		quantity: 476,
	},
	{
		garage_id: 90,
		part_id: 225,
		quantity: 286,
	},
	{
		garage_id: 90,
		part_id: 226,
		quantity: 178,
	},
	{
		garage_id: 90,
		part_id: 227,
		quantity: 448,
	},
	{
		garage_id: 90,
		part_id: 228,
		quantity: 156,
	},
	{
		garage_id: 90,
		part_id: 229,
		quantity: 344,
	},
	{
		garage_id: 90,
		part_id: 230,
		quantity: 443,
	},
	{
		garage_id: 90,
		part_id: 231,
		quantity: 357,
	},
	{
		garage_id: 90,
		part_id: 232,
		quantity: 100,
	},
	{
		garage_id: 90,
		part_id: 233,
		quantity: 287,
	},
	{
		garage_id: 90,
		part_id: 234,
		quantity: 319,
	},
	{
		garage_id: 90,
		part_id: 235,
		quantity: 126,
	},
	{
		garage_id: 90,
		part_id: 236,
		quantity: 394,
	},
	{
		garage_id: 90,
		part_id: 237,
		quantity: 283,
	},
	{
		garage_id: 90,
		part_id: 238,
		quantity: 489,
	},
	{
		garage_id: 90,
		part_id: 239,
		quantity: 135,
	},
	{
		garage_id: 90,
		part_id: 240,
		quantity: 432,
	},
	{
		garage_id: 90,
		part_id: 241,
		quantity: 156,
	},
	{
		garage_id: 90,
		part_id: 242,
		quantity: 424,
	},
	{
		garage_id: 90,
		part_id: 243,
		quantity: 285,
	},
	{
		garage_id: 90,
		part_id: 244,
		quantity: 77,
	},
	{
		garage_id: 90,
		part_id: 245,
		quantity: 352,
	},
	{
		garage_id: 90,
		part_id: 246,
		quantity: 107,
	},
	{
		garage_id: 90,
		part_id: 247,
		quantity: 185,
	},
	{
		garage_id: 90,
		part_id: 248,
		quantity: 223,
	},
	{
		garage_id: 90,
		part_id: 249,
		quantity: 428,
	},
	{
		garage_id: 90,
		part_id: 250,
		quantity: 185,
	},
	{
		garage_id: 90,
		part_id: 251,
		quantity: 213,
	},
	{
		garage_id: 90,
		part_id: 252,
		quantity: 82,
	},
	{
		garage_id: 90,
		part_id: 253,
		quantity: 174,
	},
	{
		garage_id: 90,
		part_id: 254,
		quantity: 157,
	},
	{
		garage_id: 90,
		part_id: 255,
		quantity: 418,
	},
	{
		garage_id: 90,
		part_id: 256,
		quantity: 366,
	},
	{
		garage_id: 90,
		part_id: 257,
		quantity: 108,
	},
	{
		garage_id: 90,
		part_id: 258,
		quantity: 140,
	},
	{
		garage_id: 90,
		part_id: 259,
		quantity: 389,
	},
	{
		garage_id: 90,
		part_id: 260,
		quantity: 16,
	},
	{
		garage_id: 90,
		part_id: 261,
		quantity: 23,
	},
	{
		garage_id: 90,
		part_id: 262,
		quantity: 357,
	},
	{
		garage_id: 90,
		part_id: 263,
		quantity: 2,
	},
	{
		garage_id: 90,
		part_id: 264,
		quantity: 199,
	},
	{
		garage_id: 90,
		part_id: 265,
		quantity: 256,
	},
	{
		garage_id: 90,
		part_id: 266,
		quantity: 64,
	},
	{
		garage_id: 90,
		part_id: 267,
		quantity: 410,
	},
	{
		garage_id: 90,
		part_id: 268,
		quantity: 156,
	},
	{
		garage_id: 90,
		part_id: 269,
		quantity: 329,
	},
	{
		garage_id: 90,
		part_id: 270,
		quantity: 484,
	},
	{
		garage_id: 90,
		part_id: 271,
		quantity: 452,
	},
	{
		garage_id: 90,
		part_id: 272,
		quantity: 344,
	},
	{
		garage_id: 90,
		part_id: 273,
		quantity: 19,
	},
	{
		garage_id: 90,
		part_id: 274,
		quantity: 166,
	},
	{
		garage_id: 90,
		part_id: 275,
		quantity: 307,
	},
	{
		garage_id: 90,
		part_id: 276,
		quantity: 46,
	},
	{
		garage_id: 90,
		part_id: 277,
		quantity: 92,
	},
	{
		garage_id: 90,
		part_id: 278,
		quantity: 175,
	},
	{
		garage_id: 90,
		part_id: 279,
		quantity: 371,
	},
	{
		garage_id: 90,
		part_id: 280,
		quantity: 422,
	},
	{
		garage_id: 90,
		part_id: 281,
		quantity: 480,
	},
	{
		garage_id: 90,
		part_id: 282,
		quantity: 325,
	},
	{
		garage_id: 90,
		part_id: 283,
		quantity: 258,
	},
	{
		garage_id: 90,
		part_id: 284,
		quantity: 500,
	},
	{
		garage_id: 90,
		part_id: 285,
		quantity: 39,
	},
	{
		garage_id: 90,
		part_id: 286,
		quantity: 25,
	},
	{
		garage_id: 90,
		part_id: 287,
		quantity: 241,
	},
	{
		garage_id: 90,
		part_id: 288,
		quantity: 377,
	},
	{
		garage_id: 90,
		part_id: 289,
		quantity: 28,
	},
	{
		garage_id: 90,
		part_id: 290,
		quantity: 207,
	},
	{
		garage_id: 90,
		part_id: 291,
		quantity: 67,
	},
	{
		garage_id: 90,
		part_id: 292,
		quantity: 230,
	},
	{
		garage_id: 90,
		part_id: 293,
		quantity: 337,
	},
	{
		garage_id: 90,
		part_id: 294,
		quantity: 370,
	},
	{
		garage_id: 90,
		part_id: 295,
		quantity: 167,
	},
	{
		garage_id: 90,
		part_id: 296,
		quantity: 372,
	},
	{
		garage_id: 90,
		part_id: 297,
		quantity: 357,
	},
	{
		garage_id: 90,
		part_id: 298,
		quantity: 440,
	},
	{
		garage_id: 90,
		part_id: 299,
		quantity: 489,
	},
	{
		garage_id: 90,
		part_id: 300,
		quantity: 95,
	},
	{
		garage_id: 90,
		part_id: 301,
		quantity: 142,
	},
	{
		garage_id: 90,
		part_id: 302,
		quantity: 426,
	},
	{
		garage_id: 90,
		part_id: 303,
		quantity: 403,
	},
	{
		garage_id: 90,
		part_id: 304,
		quantity: 129,
	},
	{
		garage_id: 90,
		part_id: 305,
		quantity: 376,
	},
	{
		garage_id: 90,
		part_id: 306,
		quantity: 462,
	},
	{
		garage_id: 90,
		part_id: 307,
		quantity: 484,
	},
	{
		garage_id: 90,
		part_id: 308,
		quantity: 15,
	},
	{
		garage_id: 90,
		part_id: 309,
		quantity: 404,
	},
	{
		garage_id: 90,
		part_id: 310,
		quantity: 0,
	},
	{
		garage_id: 90,
		part_id: 311,
		quantity: 249,
	},
	{
		garage_id: 90,
		part_id: 312,
		quantity: 350,
	},
	{
		garage_id: 90,
		part_id: 313,
		quantity: 290,
	},
	{
		garage_id: 90,
		part_id: 314,
		quantity: 250,
	},
	{
		garage_id: 90,
		part_id: 315,
		quantity: 453,
	},
	{
		garage_id: 90,
		part_id: 316,
		quantity: 364,
	},
	{
		garage_id: 90,
		part_id: 317,
		quantity: 291,
	},
	{
		garage_id: 90,
		part_id: 318,
		quantity: 319,
	},
	{
		garage_id: 90,
		part_id: 319,
		quantity: 487,
	},
	{
		garage_id: 90,
		part_id: 320,
		quantity: 399,
	},
	{
		garage_id: 90,
		part_id: 321,
		quantity: 437,
	},
	{
		garage_id: 90,
		part_id: 322,
		quantity: 52,
	},
	{
		garage_id: 90,
		part_id: 323,
		quantity: 80,
	},
	{
		garage_id: 90,
		part_id: 324,
		quantity: 39,
	},
	{
		garage_id: 90,
		part_id: 325,
		quantity: 191,
	},
	{
		garage_id: 90,
		part_id: 326,
		quantity: 102,
	},
	{
		garage_id: 90,
		part_id: 327,
		quantity: 146,
	},
	{
		garage_id: 90,
		part_id: 328,
		quantity: 81,
	},
	{
		garage_id: 90,
		part_id: 329,
		quantity: 423,
	},
	{
		garage_id: 90,
		part_id: 330,
		quantity: 45,
	},
	{
		garage_id: 90,
		part_id: 331,
		quantity: 170,
	},
	{
		garage_id: 90,
		part_id: 332,
		quantity: 39,
	},
	{
		garage_id: 90,
		part_id: 333,
		quantity: 300,
	},
	{
		garage_id: 90,
		part_id: 334,
		quantity: 318,
	},
	{
		garage_id: 90,
		part_id: 335,
		quantity: 402,
	},
	{
		garage_id: 90,
		part_id: 336,
		quantity: 216,
	},
	{
		garage_id: 90,
		part_id: 337,
		quantity: 94,
	},
	{
		garage_id: 90,
		part_id: 338,
		quantity: 333,
	},
	{
		garage_id: 90,
		part_id: 339,
		quantity: 145,
	},
	{
		garage_id: 90,
		part_id: 340,
		quantity: 151,
	},
	{
		garage_id: 90,
		part_id: 341,
		quantity: 206,
	},
	{
		garage_id: 90,
		part_id: 342,
		quantity: 72,
	},
	{
		garage_id: 90,
		part_id: 343,
		quantity: 200,
	},
	{
		garage_id: 90,
		part_id: 344,
		quantity: 436,
	},
	{
		garage_id: 90,
		part_id: 345,
		quantity: 475,
	},
	{
		garage_id: 90,
		part_id: 346,
		quantity: 72,
	},
	{
		garage_id: 90,
		part_id: 347,
		quantity: 255,
	},
	{
		garage_id: 90,
		part_id: 348,
		quantity: 487,
	},
	{
		garage_id: 90,
		part_id: 349,
		quantity: 246,
	},
	{
		garage_id: 90,
		part_id: 350,
		quantity: 426,
	},
	{
		garage_id: 90,
		part_id: 351,
		quantity: 321,
	},
	{
		garage_id: 90,
		part_id: 352,
		quantity: 345,
	},
	{
		garage_id: 90,
		part_id: 353,
		quantity: 139,
	},
	{
		garage_id: 90,
		part_id: 354,
		quantity: 284,
	},
	{
		garage_id: 90,
		part_id: 355,
		quantity: 143,
	},
	{
		garage_id: 90,
		part_id: 356,
		quantity: 126,
	},
	{
		garage_id: 90,
		part_id: 357,
		quantity: 456,
	},
	{
		garage_id: 90,
		part_id: 358,
		quantity: 178,
	},
	{
		garage_id: 90,
		part_id: 359,
		quantity: 0,
	},
	{
		garage_id: 90,
		part_id: 360,
		quantity: 385,
	},
	{
		garage_id: 90,
		part_id: 361,
		quantity: 378,
	},
	{
		garage_id: 90,
		part_id: 362,
		quantity: 309,
	},
	{
		garage_id: 90,
		part_id: 363,
		quantity: 59,
	},
	{
		garage_id: 90,
		part_id: 364,
		quantity: 89,
	},
	{
		garage_id: 90,
		part_id: 365,
		quantity: 399,
	},
	{
		garage_id: 90,
		part_id: 366,
		quantity: 217,
	},
	{
		garage_id: 90,
		part_id: 367,
		quantity: 483,
	},
	{
		garage_id: 90,
		part_id: 368,
		quantity: 472,
	},
	{
		garage_id: 90,
		part_id: 369,
		quantity: 52,
	},
	{
		garage_id: 90,
		part_id: 370,
		quantity: 321,
	},
	{
		garage_id: 90,
		part_id: 371,
		quantity: 32,
	},
	{
		garage_id: 90,
		part_id: 372,
		quantity: 343,
	},
	{
		garage_id: 90,
		part_id: 373,
		quantity: 44,
	},
	{
		garage_id: 90,
		part_id: 374,
		quantity: 7,
	},
	{
		garage_id: 90,
		part_id: 375,
		quantity: 281,
	},
	{
		garage_id: 90,
		part_id: 376,
		quantity: 496,
	},
	{
		garage_id: 90,
		part_id: 377,
		quantity: 481,
	},
	{
		garage_id: 90,
		part_id: 378,
		quantity: 452,
	},
	{
		garage_id: 90,
		part_id: 379,
		quantity: 221,
	},
	{
		garage_id: 90,
		part_id: 380,
		quantity: 266,
	},
	{
		garage_id: 90,
		part_id: 381,
		quantity: 14,
	},
	{
		garage_id: 90,
		part_id: 382,
		quantity: 394,
	},
	{
		garage_id: 90,
		part_id: 383,
		quantity: 341,
	},
	{
		garage_id: 90,
		part_id: 384,
		quantity: 477,
	},
	{
		garage_id: 90,
		part_id: 385,
		quantity: 213,
	},
	{
		garage_id: 90,
		part_id: 386,
		quantity: 234,
	},
	{
		garage_id: 90,
		part_id: 387,
		quantity: 302,
	},
	{
		garage_id: 90,
		part_id: 388,
		quantity: 110,
	},
	{
		garage_id: 90,
		part_id: 389,
		quantity: 388,
	},
	{
		garage_id: 90,
		part_id: 390,
		quantity: 91,
	},
	{
		garage_id: 90,
		part_id: 391,
		quantity: 270,
	},
	{
		garage_id: 90,
		part_id: 392,
		quantity: 301,
	},
	{
		garage_id: 90,
		part_id: 393,
		quantity: 33,
	},
	{
		garage_id: 90,
		part_id: 394,
		quantity: 346,
	},
	{
		garage_id: 90,
		part_id: 395,
		quantity: 102,
	},
	{
		garage_id: 90,
		part_id: 396,
		quantity: 199,
	},
	{
		garage_id: 90,
		part_id: 397,
		quantity: 287,
	},
	{
		garage_id: 90,
		part_id: 398,
		quantity: 359,
	},
	{
		garage_id: 90,
		part_id: 399,
		quantity: 99,
	},
	{
		garage_id: 91,
		part_id: 0,
		quantity: 420,
	},
	{
		garage_id: 91,
		part_id: 1,
		quantity: 332,
	},
	{
		garage_id: 91,
		part_id: 2,
		quantity: 16,
	},
	{
		garage_id: 91,
		part_id: 3,
		quantity: 449,
	},
	{
		garage_id: 91,
		part_id: 4,
		quantity: 194,
	},
	{
		garage_id: 91,
		part_id: 5,
		quantity: 426,
	},
	{
		garage_id: 91,
		part_id: 6,
		quantity: 350,
	},
	{
		garage_id: 91,
		part_id: 7,
		quantity: 1,
	},
	{
		garage_id: 91,
		part_id: 8,
		quantity: 112,
	},
	{
		garage_id: 91,
		part_id: 9,
		quantity: 222,
	},
	{
		garage_id: 91,
		part_id: 10,
		quantity: 147,
	},
	{
		garage_id: 91,
		part_id: 11,
		quantity: 284,
	},
	{
		garage_id: 91,
		part_id: 12,
		quantity: 114,
	},
	{
		garage_id: 91,
		part_id: 13,
		quantity: 416,
	},
	{
		garage_id: 91,
		part_id: 14,
		quantity: 422,
	},
	{
		garage_id: 91,
		part_id: 15,
		quantity: 326,
	},
	{
		garage_id: 91,
		part_id: 16,
		quantity: 211,
	},
	{
		garage_id: 91,
		part_id: 17,
		quantity: 358,
	},
	{
		garage_id: 91,
		part_id: 18,
		quantity: 210,
	},
	{
		garage_id: 91,
		part_id: 19,
		quantity: 276,
	},
	{
		garage_id: 91,
		part_id: 20,
		quantity: 180,
	},
	{
		garage_id: 91,
		part_id: 21,
		quantity: 348,
	},
	{
		garage_id: 91,
		part_id: 22,
		quantity: 369,
	},
	{
		garage_id: 91,
		part_id: 23,
		quantity: 205,
	},
	{
		garage_id: 91,
		part_id: 24,
		quantity: 78,
	},
	{
		garage_id: 91,
		part_id: 25,
		quantity: 69,
	},
	{
		garage_id: 91,
		part_id: 26,
		quantity: 342,
	},
	{
		garage_id: 91,
		part_id: 27,
		quantity: 21,
	},
	{
		garage_id: 91,
		part_id: 28,
		quantity: 443,
	},
	{
		garage_id: 91,
		part_id: 29,
		quantity: 256,
	},
	{
		garage_id: 91,
		part_id: 30,
		quantity: 347,
	},
	{
		garage_id: 91,
		part_id: 31,
		quantity: 82,
	},
	{
		garage_id: 91,
		part_id: 32,
		quantity: 17,
	},
	{
		garage_id: 91,
		part_id: 33,
		quantity: 430,
	},
	{
		garage_id: 91,
		part_id: 34,
		quantity: 400,
	},
	{
		garage_id: 91,
		part_id: 35,
		quantity: 419,
	},
	{
		garage_id: 91,
		part_id: 36,
		quantity: 303,
	},
	{
		garage_id: 91,
		part_id: 37,
		quantity: 435,
	},
	{
		garage_id: 91,
		part_id: 38,
		quantity: 395,
	},
	{
		garage_id: 91,
		part_id: 39,
		quantity: 285,
	},
	{
		garage_id: 91,
		part_id: 40,
		quantity: 177,
	},
	{
		garage_id: 91,
		part_id: 41,
		quantity: 178,
	},
	{
		garage_id: 91,
		part_id: 42,
		quantity: 460,
	},
	{
		garage_id: 91,
		part_id: 43,
		quantity: 232,
	},
	{
		garage_id: 91,
		part_id: 44,
		quantity: 293,
	},
	{
		garage_id: 91,
		part_id: 45,
		quantity: 147,
	},
	{
		garage_id: 91,
		part_id: 46,
		quantity: 392,
	},
	{
		garage_id: 91,
		part_id: 47,
		quantity: 219,
	},
	{
		garage_id: 91,
		part_id: 48,
		quantity: 258,
	},
	{
		garage_id: 91,
		part_id: 49,
		quantity: 432,
	},
	{
		garage_id: 91,
		part_id: 50,
		quantity: 292,
	},
	{
		garage_id: 91,
		part_id: 51,
		quantity: 345,
	},
	{
		garage_id: 91,
		part_id: 52,
		quantity: 288,
	},
	{
		garage_id: 91,
		part_id: 53,
		quantity: 350,
	},
	{
		garage_id: 91,
		part_id: 54,
		quantity: 216,
	},
	{
		garage_id: 91,
		part_id: 55,
		quantity: 0,
	},
	{
		garage_id: 91,
		part_id: 56,
		quantity: 130,
	},
	{
		garage_id: 91,
		part_id: 57,
		quantity: 182,
	},
	{
		garage_id: 91,
		part_id: 58,
		quantity: 213,
	},
	{
		garage_id: 91,
		part_id: 59,
		quantity: 224,
	},
	{
		garage_id: 91,
		part_id: 60,
		quantity: 95,
	},
	{
		garage_id: 91,
		part_id: 61,
		quantity: 447,
	},
	{
		garage_id: 91,
		part_id: 62,
		quantity: 369,
	},
	{
		garage_id: 91,
		part_id: 63,
		quantity: 95,
	},
	{
		garage_id: 91,
		part_id: 64,
		quantity: 498,
	},
	{
		garage_id: 91,
		part_id: 65,
		quantity: 312,
	},
	{
		garage_id: 91,
		part_id: 66,
		quantity: 468,
	},
	{
		garage_id: 91,
		part_id: 67,
		quantity: 73,
	},
	{
		garage_id: 91,
		part_id: 68,
		quantity: 396,
	},
	{
		garage_id: 91,
		part_id: 69,
		quantity: 439,
	},
	{
		garage_id: 91,
		part_id: 70,
		quantity: 154,
	},
	{
		garage_id: 91,
		part_id: 71,
		quantity: 386,
	},
	{
		garage_id: 91,
		part_id: 72,
		quantity: 371,
	},
	{
		garage_id: 91,
		part_id: 73,
		quantity: 310,
	},
	{
		garage_id: 91,
		part_id: 74,
		quantity: 254,
	},
	{
		garage_id: 91,
		part_id: 75,
		quantity: 256,
	},
	{
		garage_id: 91,
		part_id: 76,
		quantity: 313,
	},
	{
		garage_id: 91,
		part_id: 77,
		quantity: 302,
	},
	{
		garage_id: 91,
		part_id: 78,
		quantity: 499,
	},
	{
		garage_id: 91,
		part_id: 79,
		quantity: 183,
	},
	{
		garage_id: 91,
		part_id: 80,
		quantity: 483,
	},
	{
		garage_id: 91,
		part_id: 81,
		quantity: 402,
	},
	{
		garage_id: 91,
		part_id: 82,
		quantity: 93,
	},
	{
		garage_id: 91,
		part_id: 83,
		quantity: 150,
	},
	{
		garage_id: 91,
		part_id: 84,
		quantity: 262,
	},
	{
		garage_id: 91,
		part_id: 85,
		quantity: 3,
	},
	{
		garage_id: 91,
		part_id: 86,
		quantity: 27,
	},
	{
		garage_id: 91,
		part_id: 87,
		quantity: 62,
	},
	{
		garage_id: 91,
		part_id: 88,
		quantity: 320,
	},
	{
		garage_id: 91,
		part_id: 89,
		quantity: 37,
	},
	{
		garage_id: 91,
		part_id: 90,
		quantity: 464,
	},
	{
		garage_id: 91,
		part_id: 91,
		quantity: 439,
	},
	{
		garage_id: 91,
		part_id: 92,
		quantity: 174,
	},
	{
		garage_id: 91,
		part_id: 93,
		quantity: 63,
	},
	{
		garage_id: 91,
		part_id: 94,
		quantity: 412,
	},
	{
		garage_id: 91,
		part_id: 95,
		quantity: 406,
	},
	{
		garage_id: 91,
		part_id: 96,
		quantity: 286,
	},
	{
		garage_id: 91,
		part_id: 97,
		quantity: 491,
	},
	{
		garage_id: 91,
		part_id: 98,
		quantity: 391,
	},
	{
		garage_id: 91,
		part_id: 99,
		quantity: 474,
	},
	{
		garage_id: 91,
		part_id: 100,
		quantity: 400,
	},
	{
		garage_id: 91,
		part_id: 101,
		quantity: 7,
	},
	{
		garage_id: 91,
		part_id: 102,
		quantity: 88,
	},
	{
		garage_id: 91,
		part_id: 103,
		quantity: 336,
	},
	{
		garage_id: 91,
		part_id: 104,
		quantity: 106,
	},
	{
		garage_id: 91,
		part_id: 105,
		quantity: 204,
	},
	{
		garage_id: 91,
		part_id: 106,
		quantity: 199,
	},
	{
		garage_id: 91,
		part_id: 107,
		quantity: 376,
	},
	{
		garage_id: 91,
		part_id: 108,
		quantity: 54,
	},
	{
		garage_id: 91,
		part_id: 109,
		quantity: 160,
	},
	{
		garage_id: 91,
		part_id: 110,
		quantity: 135,
	},
	{
		garage_id: 91,
		part_id: 111,
		quantity: 21,
	},
	{
		garage_id: 91,
		part_id: 112,
		quantity: 27,
	},
	{
		garage_id: 91,
		part_id: 113,
		quantity: 250,
	},
	{
		garage_id: 91,
		part_id: 114,
		quantity: 206,
	},
	{
		garage_id: 91,
		part_id: 115,
		quantity: 435,
	},
	{
		garage_id: 91,
		part_id: 116,
		quantity: 476,
	},
	{
		garage_id: 91,
		part_id: 117,
		quantity: 406,
	},
	{
		garage_id: 91,
		part_id: 118,
		quantity: 27,
	},
	{
		garage_id: 91,
		part_id: 119,
		quantity: 52,
	},
	{
		garage_id: 91,
		part_id: 120,
		quantity: 402,
	},
	{
		garage_id: 91,
		part_id: 121,
		quantity: 100,
	},
	{
		garage_id: 91,
		part_id: 122,
		quantity: 345,
	},
	{
		garage_id: 91,
		part_id: 123,
		quantity: 59,
	},
	{
		garage_id: 91,
		part_id: 124,
		quantity: 76,
	},
	{
		garage_id: 91,
		part_id: 125,
		quantity: 152,
	},
	{
		garage_id: 91,
		part_id: 126,
		quantity: 254,
	},
	{
		garage_id: 91,
		part_id: 127,
		quantity: 340,
	},
	{
		garage_id: 91,
		part_id: 128,
		quantity: 449,
	},
	{
		garage_id: 91,
		part_id: 129,
		quantity: 301,
	},
	{
		garage_id: 91,
		part_id: 130,
		quantity: 424,
	},
	{
		garage_id: 91,
		part_id: 131,
		quantity: 446,
	},
	{
		garage_id: 91,
		part_id: 132,
		quantity: 233,
	},
	{
		garage_id: 91,
		part_id: 133,
		quantity: 190,
	},
	{
		garage_id: 91,
		part_id: 134,
		quantity: 108,
	},
	{
		garage_id: 91,
		part_id: 135,
		quantity: 465,
	},
	{
		garage_id: 91,
		part_id: 136,
		quantity: 279,
	},
	{
		garage_id: 91,
		part_id: 137,
		quantity: 110,
	},
	{
		garage_id: 91,
		part_id: 138,
		quantity: 252,
	},
	{
		garage_id: 91,
		part_id: 139,
		quantity: 171,
	},
	{
		garage_id: 91,
		part_id: 140,
		quantity: 275,
	},
	{
		garage_id: 91,
		part_id: 141,
		quantity: 79,
	},
	{
		garage_id: 91,
		part_id: 142,
		quantity: 465,
	},
	{
		garage_id: 91,
		part_id: 143,
		quantity: 298,
	},
	{
		garage_id: 91,
		part_id: 144,
		quantity: 248,
	},
	{
		garage_id: 91,
		part_id: 145,
		quantity: 324,
	},
	{
		garage_id: 91,
		part_id: 146,
		quantity: 355,
	},
	{
		garage_id: 91,
		part_id: 147,
		quantity: 213,
	},
	{
		garage_id: 91,
		part_id: 148,
		quantity: 5,
	},
	{
		garage_id: 91,
		part_id: 149,
		quantity: 385,
	},
	{
		garage_id: 91,
		part_id: 150,
		quantity: 487,
	},
	{
		garage_id: 91,
		part_id: 151,
		quantity: 76,
	},
	{
		garage_id: 91,
		part_id: 152,
		quantity: 90,
	},
	{
		garage_id: 91,
		part_id: 153,
		quantity: 378,
	},
	{
		garage_id: 91,
		part_id: 154,
		quantity: 309,
	},
	{
		garage_id: 91,
		part_id: 155,
		quantity: 147,
	},
	{
		garage_id: 91,
		part_id: 156,
		quantity: 13,
	},
	{
		garage_id: 91,
		part_id: 157,
		quantity: 473,
	},
	{
		garage_id: 91,
		part_id: 158,
		quantity: 430,
	},
	{
		garage_id: 91,
		part_id: 159,
		quantity: 409,
	},
	{
		garage_id: 91,
		part_id: 160,
		quantity: 189,
	},
	{
		garage_id: 91,
		part_id: 161,
		quantity: 270,
	},
	{
		garage_id: 91,
		part_id: 162,
		quantity: 432,
	},
	{
		garage_id: 91,
		part_id: 163,
		quantity: 117,
	},
	{
		garage_id: 91,
		part_id: 164,
		quantity: 85,
	},
	{
		garage_id: 91,
		part_id: 165,
		quantity: 60,
	},
	{
		garage_id: 91,
		part_id: 166,
		quantity: 166,
	},
	{
		garage_id: 91,
		part_id: 167,
		quantity: 94,
	},
	{
		garage_id: 91,
		part_id: 168,
		quantity: 424,
	},
	{
		garage_id: 91,
		part_id: 169,
		quantity: 257,
	},
	{
		garage_id: 91,
		part_id: 170,
		quantity: 153,
	},
	{
		garage_id: 91,
		part_id: 171,
		quantity: 413,
	},
	{
		garage_id: 91,
		part_id: 172,
		quantity: 337,
	},
	{
		garage_id: 91,
		part_id: 173,
		quantity: 335,
	},
	{
		garage_id: 91,
		part_id: 174,
		quantity: 486,
	},
	{
		garage_id: 91,
		part_id: 175,
		quantity: 445,
	},
	{
		garage_id: 91,
		part_id: 176,
		quantity: 370,
	},
	{
		garage_id: 91,
		part_id: 177,
		quantity: 458,
	},
	{
		garage_id: 91,
		part_id: 178,
		quantity: 165,
	},
	{
		garage_id: 91,
		part_id: 179,
		quantity: 491,
	},
	{
		garage_id: 91,
		part_id: 180,
		quantity: 155,
	},
	{
		garage_id: 91,
		part_id: 181,
		quantity: 180,
	},
	{
		garage_id: 91,
		part_id: 182,
		quantity: 207,
	},
	{
		garage_id: 91,
		part_id: 183,
		quantity: 359,
	},
	{
		garage_id: 91,
		part_id: 184,
		quantity: 313,
	},
	{
		garage_id: 91,
		part_id: 185,
		quantity: 195,
	},
	{
		garage_id: 91,
		part_id: 186,
		quantity: 368,
	},
	{
		garage_id: 91,
		part_id: 187,
		quantity: 191,
	},
	{
		garage_id: 91,
		part_id: 188,
		quantity: 413,
	},
	{
		garage_id: 91,
		part_id: 189,
		quantity: 452,
	},
	{
		garage_id: 91,
		part_id: 190,
		quantity: 47,
	},
	{
		garage_id: 91,
		part_id: 191,
		quantity: 409,
	},
	{
		garage_id: 91,
		part_id: 192,
		quantity: 355,
	},
	{
		garage_id: 91,
		part_id: 193,
		quantity: 464,
	},
	{
		garage_id: 91,
		part_id: 194,
		quantity: 376,
	},
	{
		garage_id: 91,
		part_id: 195,
		quantity: 66,
	},
	{
		garage_id: 91,
		part_id: 196,
		quantity: 4,
	},
	{
		garage_id: 91,
		part_id: 197,
		quantity: 46,
	},
	{
		garage_id: 91,
		part_id: 198,
		quantity: 475,
	},
	{
		garage_id: 91,
		part_id: 199,
		quantity: 213,
	},
	{
		garage_id: 91,
		part_id: 200,
		quantity: 120,
	},
	{
		garage_id: 91,
		part_id: 201,
		quantity: 384,
	},
	{
		garage_id: 91,
		part_id: 202,
		quantity: 375,
	},
	{
		garage_id: 91,
		part_id: 203,
		quantity: 108,
	},
	{
		garage_id: 91,
		part_id: 204,
		quantity: 346,
	},
	{
		garage_id: 91,
		part_id: 205,
		quantity: 311,
	},
	{
		garage_id: 91,
		part_id: 206,
		quantity: 120,
	},
	{
		garage_id: 91,
		part_id: 207,
		quantity: 185,
	},
	{
		garage_id: 91,
		part_id: 208,
		quantity: 484,
	},
	{
		garage_id: 91,
		part_id: 209,
		quantity: 313,
	},
	{
		garage_id: 91,
		part_id: 210,
		quantity: 265,
	},
	{
		garage_id: 91,
		part_id: 211,
		quantity: 201,
	},
	{
		garage_id: 91,
		part_id: 212,
		quantity: 96,
	},
	{
		garage_id: 91,
		part_id: 213,
		quantity: 324,
	},
	{
		garage_id: 91,
		part_id: 214,
		quantity: 129,
	},
	{
		garage_id: 91,
		part_id: 215,
		quantity: 489,
	},
	{
		garage_id: 91,
		part_id: 216,
		quantity: 169,
	},
	{
		garage_id: 91,
		part_id: 217,
		quantity: 464,
	},
	{
		garage_id: 91,
		part_id: 218,
		quantity: 481,
	},
	{
		garage_id: 91,
		part_id: 219,
		quantity: 261,
	},
	{
		garage_id: 91,
		part_id: 220,
		quantity: 420,
	},
	{
		garage_id: 91,
		part_id: 221,
		quantity: 478,
	},
	{
		garage_id: 91,
		part_id: 222,
		quantity: 353,
	},
	{
		garage_id: 91,
		part_id: 223,
		quantity: 396,
	},
	{
		garage_id: 91,
		part_id: 224,
		quantity: 326,
	},
	{
		garage_id: 91,
		part_id: 225,
		quantity: 439,
	},
	{
		garage_id: 91,
		part_id: 226,
		quantity: 362,
	},
	{
		garage_id: 91,
		part_id: 227,
		quantity: 39,
	},
	{
		garage_id: 91,
		part_id: 228,
		quantity: 417,
	},
	{
		garage_id: 91,
		part_id: 229,
		quantity: 71,
	},
	{
		garage_id: 91,
		part_id: 230,
		quantity: 211,
	},
	{
		garage_id: 91,
		part_id: 231,
		quantity: 337,
	},
	{
		garage_id: 91,
		part_id: 232,
		quantity: 299,
	},
	{
		garage_id: 91,
		part_id: 233,
		quantity: 60,
	},
	{
		garage_id: 91,
		part_id: 234,
		quantity: 234,
	},
	{
		garage_id: 91,
		part_id: 235,
		quantity: 336,
	},
	{
		garage_id: 91,
		part_id: 236,
		quantity: 156,
	},
	{
		garage_id: 91,
		part_id: 237,
		quantity: 37,
	},
	{
		garage_id: 91,
		part_id: 238,
		quantity: 5,
	},
	{
		garage_id: 91,
		part_id: 239,
		quantity: 240,
	},
	{
		garage_id: 91,
		part_id: 240,
		quantity: 146,
	},
	{
		garage_id: 91,
		part_id: 241,
		quantity: 189,
	},
	{
		garage_id: 91,
		part_id: 242,
		quantity: 12,
	},
	{
		garage_id: 91,
		part_id: 243,
		quantity: 18,
	},
	{
		garage_id: 91,
		part_id: 244,
		quantity: 479,
	},
	{
		garage_id: 91,
		part_id: 245,
		quantity: 175,
	},
	{
		garage_id: 91,
		part_id: 246,
		quantity: 38,
	},
	{
		garage_id: 91,
		part_id: 247,
		quantity: 374,
	},
	{
		garage_id: 91,
		part_id: 248,
		quantity: 353,
	},
	{
		garage_id: 91,
		part_id: 249,
		quantity: 323,
	},
	{
		garage_id: 91,
		part_id: 250,
		quantity: 40,
	},
	{
		garage_id: 91,
		part_id: 251,
		quantity: 307,
	},
	{
		garage_id: 91,
		part_id: 252,
		quantity: 16,
	},
	{
		garage_id: 91,
		part_id: 253,
		quantity: 329,
	},
	{
		garage_id: 91,
		part_id: 254,
		quantity: 154,
	},
	{
		garage_id: 91,
		part_id: 255,
		quantity: 258,
	},
	{
		garage_id: 91,
		part_id: 256,
		quantity: 495,
	},
	{
		garage_id: 91,
		part_id: 257,
		quantity: 410,
	},
	{
		garage_id: 91,
		part_id: 258,
		quantity: 71,
	},
	{
		garage_id: 91,
		part_id: 259,
		quantity: 283,
	},
	{
		garage_id: 91,
		part_id: 260,
		quantity: 167,
	},
	{
		garage_id: 91,
		part_id: 261,
		quantity: 354,
	},
	{
		garage_id: 91,
		part_id: 262,
		quantity: 246,
	},
	{
		garage_id: 91,
		part_id: 263,
		quantity: 13,
	},
	{
		garage_id: 91,
		part_id: 264,
		quantity: 138,
	},
	{
		garage_id: 91,
		part_id: 265,
		quantity: 388,
	},
	{
		garage_id: 91,
		part_id: 266,
		quantity: 350,
	},
	{
		garage_id: 91,
		part_id: 267,
		quantity: 486,
	},
	{
		garage_id: 91,
		part_id: 268,
		quantity: 143,
	},
	{
		garage_id: 91,
		part_id: 269,
		quantity: 84,
	},
	{
		garage_id: 91,
		part_id: 270,
		quantity: 32,
	},
	{
		garage_id: 91,
		part_id: 271,
		quantity: 234,
	},
	{
		garage_id: 91,
		part_id: 272,
		quantity: 14,
	},
	{
		garage_id: 91,
		part_id: 273,
		quantity: 446,
	},
	{
		garage_id: 91,
		part_id: 274,
		quantity: 279,
	},
	{
		garage_id: 91,
		part_id: 275,
		quantity: 373,
	},
	{
		garage_id: 91,
		part_id: 276,
		quantity: 172,
	},
	{
		garage_id: 91,
		part_id: 277,
		quantity: 59,
	},
	{
		garage_id: 91,
		part_id: 278,
		quantity: 261,
	},
	{
		garage_id: 91,
		part_id: 279,
		quantity: 130,
	},
	{
		garage_id: 91,
		part_id: 280,
		quantity: 186,
	},
	{
		garage_id: 91,
		part_id: 281,
		quantity: 18,
	},
	{
		garage_id: 91,
		part_id: 282,
		quantity: 206,
	},
	{
		garage_id: 91,
		part_id: 283,
		quantity: 27,
	},
	{
		garage_id: 91,
		part_id: 284,
		quantity: 446,
	},
	{
		garage_id: 91,
		part_id: 285,
		quantity: 191,
	},
	{
		garage_id: 91,
		part_id: 286,
		quantity: 411,
	},
	{
		garage_id: 91,
		part_id: 287,
		quantity: 119,
	},
	{
		garage_id: 91,
		part_id: 288,
		quantity: 7,
	},
	{
		garage_id: 91,
		part_id: 289,
		quantity: 471,
	},
	{
		garage_id: 91,
		part_id: 290,
		quantity: 408,
	},
	{
		garage_id: 91,
		part_id: 291,
		quantity: 25,
	},
	{
		garage_id: 91,
		part_id: 292,
		quantity: 31,
	},
	{
		garage_id: 91,
		part_id: 293,
		quantity: 380,
	},
	{
		garage_id: 91,
		part_id: 294,
		quantity: 355,
	},
	{
		garage_id: 91,
		part_id: 295,
		quantity: 103,
	},
	{
		garage_id: 91,
		part_id: 296,
		quantity: 391,
	},
	{
		garage_id: 91,
		part_id: 297,
		quantity: 17,
	},
	{
		garage_id: 91,
		part_id: 298,
		quantity: 51,
	},
	{
		garage_id: 91,
		part_id: 299,
		quantity: 159,
	},
	{
		garage_id: 91,
		part_id: 300,
		quantity: 320,
	},
	{
		garage_id: 91,
		part_id: 301,
		quantity: 165,
	},
	{
		garage_id: 91,
		part_id: 302,
		quantity: 475,
	},
	{
		garage_id: 91,
		part_id: 303,
		quantity: 94,
	},
	{
		garage_id: 91,
		part_id: 304,
		quantity: 472,
	},
	{
		garage_id: 91,
		part_id: 305,
		quantity: 473,
	},
	{
		garage_id: 91,
		part_id: 306,
		quantity: 340,
	},
	{
		garage_id: 91,
		part_id: 307,
		quantity: 417,
	},
	{
		garage_id: 91,
		part_id: 308,
		quantity: 462,
	},
	{
		garage_id: 91,
		part_id: 309,
		quantity: 68,
	},
	{
		garage_id: 91,
		part_id: 310,
		quantity: 389,
	},
	{
		garage_id: 91,
		part_id: 311,
		quantity: 339,
	},
	{
		garage_id: 91,
		part_id: 312,
		quantity: 399,
	},
	{
		garage_id: 91,
		part_id: 313,
		quantity: 151,
	},
	{
		garage_id: 91,
		part_id: 314,
		quantity: 150,
	},
	{
		garage_id: 91,
		part_id: 315,
		quantity: 41,
	},
	{
		garage_id: 91,
		part_id: 316,
		quantity: 366,
	},
	{
		garage_id: 91,
		part_id: 317,
		quantity: 159,
	},
	{
		garage_id: 91,
		part_id: 318,
		quantity: 88,
	},
	{
		garage_id: 91,
		part_id: 319,
		quantity: 172,
	},
	{
		garage_id: 91,
		part_id: 320,
		quantity: 367,
	},
	{
		garage_id: 91,
		part_id: 321,
		quantity: 470,
	},
	{
		garage_id: 91,
		part_id: 322,
		quantity: 488,
	},
	{
		garage_id: 91,
		part_id: 323,
		quantity: 184,
	},
	{
		garage_id: 91,
		part_id: 324,
		quantity: 475,
	},
	{
		garage_id: 91,
		part_id: 325,
		quantity: 41,
	},
	{
		garage_id: 91,
		part_id: 326,
		quantity: 183,
	},
	{
		garage_id: 91,
		part_id: 327,
		quantity: 117,
	},
	{
		garage_id: 91,
		part_id: 328,
		quantity: 259,
	},
	{
		garage_id: 91,
		part_id: 329,
		quantity: 449,
	},
	{
		garage_id: 91,
		part_id: 330,
		quantity: 194,
	},
	{
		garage_id: 91,
		part_id: 331,
		quantity: 187,
	},
	{
		garage_id: 91,
		part_id: 332,
		quantity: 251,
	},
	{
		garage_id: 91,
		part_id: 333,
		quantity: 432,
	},
	{
		garage_id: 91,
		part_id: 334,
		quantity: 46,
	},
	{
		garage_id: 91,
		part_id: 335,
		quantity: 111,
	},
	{
		garage_id: 91,
		part_id: 336,
		quantity: 213,
	},
	{
		garage_id: 91,
		part_id: 337,
		quantity: 178,
	},
	{
		garage_id: 91,
		part_id: 338,
		quantity: 218,
	},
	{
		garage_id: 91,
		part_id: 339,
		quantity: 400,
	},
	{
		garage_id: 91,
		part_id: 340,
		quantity: 366,
	},
	{
		garage_id: 91,
		part_id: 341,
		quantity: 484,
	},
	{
		garage_id: 91,
		part_id: 342,
		quantity: 224,
	},
	{
		garage_id: 91,
		part_id: 343,
		quantity: 199,
	},
	{
		garage_id: 91,
		part_id: 344,
		quantity: 205,
	},
	{
		garage_id: 91,
		part_id: 345,
		quantity: 430,
	},
	{
		garage_id: 91,
		part_id: 346,
		quantity: 215,
	},
	{
		garage_id: 91,
		part_id: 347,
		quantity: 210,
	},
	{
		garage_id: 91,
		part_id: 348,
		quantity: 12,
	},
	{
		garage_id: 91,
		part_id: 349,
		quantity: 475,
	},
	{
		garage_id: 91,
		part_id: 350,
		quantity: 499,
	},
	{
		garage_id: 91,
		part_id: 351,
		quantity: 414,
	},
	{
		garage_id: 91,
		part_id: 352,
		quantity: 492,
	},
	{
		garage_id: 91,
		part_id: 353,
		quantity: 484,
	},
	{
		garage_id: 91,
		part_id: 354,
		quantity: 18,
	},
	{
		garage_id: 91,
		part_id: 355,
		quantity: 464,
	},
	{
		garage_id: 91,
		part_id: 356,
		quantity: 184,
	},
	{
		garage_id: 91,
		part_id: 357,
		quantity: 312,
	},
	{
		garage_id: 91,
		part_id: 358,
		quantity: 156,
	},
	{
		garage_id: 91,
		part_id: 359,
		quantity: 198,
	},
	{
		garage_id: 91,
		part_id: 360,
		quantity: 267,
	},
	{
		garage_id: 91,
		part_id: 361,
		quantity: 399,
	},
	{
		garage_id: 91,
		part_id: 362,
		quantity: 310,
	},
	{
		garage_id: 91,
		part_id: 363,
		quantity: 483,
	},
	{
		garage_id: 91,
		part_id: 364,
		quantity: 337,
	},
	{
		garage_id: 91,
		part_id: 365,
		quantity: 116,
	},
	{
		garage_id: 91,
		part_id: 366,
		quantity: 52,
	},
	{
		garage_id: 91,
		part_id: 367,
		quantity: 275,
	},
	{
		garage_id: 91,
		part_id: 368,
		quantity: 154,
	},
	{
		garage_id: 91,
		part_id: 369,
		quantity: 198,
	},
	{
		garage_id: 91,
		part_id: 370,
		quantity: 269,
	},
	{
		garage_id: 91,
		part_id: 371,
		quantity: 24,
	},
	{
		garage_id: 91,
		part_id: 372,
		quantity: 139,
	},
	{
		garage_id: 91,
		part_id: 373,
		quantity: 216,
	},
	{
		garage_id: 91,
		part_id: 374,
		quantity: 143,
	},
	{
		garage_id: 91,
		part_id: 375,
		quantity: 217,
	},
	{
		garage_id: 91,
		part_id: 376,
		quantity: 379,
	},
	{
		garage_id: 91,
		part_id: 377,
		quantity: 404,
	},
	{
		garage_id: 91,
		part_id: 378,
		quantity: 145,
	},
	{
		garage_id: 91,
		part_id: 379,
		quantity: 410,
	},
	{
		garage_id: 91,
		part_id: 380,
		quantity: 40,
	},
	{
		garage_id: 91,
		part_id: 381,
		quantity: 315,
	},
	{
		garage_id: 91,
		part_id: 382,
		quantity: 198,
	},
	{
		garage_id: 91,
		part_id: 383,
		quantity: 40,
	},
	{
		garage_id: 91,
		part_id: 384,
		quantity: 253,
	},
	{
		garage_id: 91,
		part_id: 385,
		quantity: 245,
	},
	{
		garage_id: 91,
		part_id: 386,
		quantity: 82,
	},
	{
		garage_id: 91,
		part_id: 387,
		quantity: 195,
	},
	{
		garage_id: 91,
		part_id: 388,
		quantity: 72,
	},
	{
		garage_id: 91,
		part_id: 389,
		quantity: 18,
	},
	{
		garage_id: 91,
		part_id: 390,
		quantity: 241,
	},
	{
		garage_id: 91,
		part_id: 391,
		quantity: 367,
	},
	{
		garage_id: 91,
		part_id: 392,
		quantity: 497,
	},
	{
		garage_id: 91,
		part_id: 393,
		quantity: 145,
	},
	{
		garage_id: 91,
		part_id: 394,
		quantity: 441,
	},
	{
		garage_id: 91,
		part_id: 395,
		quantity: 416,
	},
	{
		garage_id: 91,
		part_id: 396,
		quantity: 39,
	},
	{
		garage_id: 91,
		part_id: 397,
		quantity: 369,
	},
	{
		garage_id: 91,
		part_id: 398,
		quantity: 94,
	},
	{
		garage_id: 91,
		part_id: 399,
		quantity: 439,
	},
	{
		garage_id: 92,
		part_id: 0,
		quantity: 475,
	},
	{
		garage_id: 92,
		part_id: 1,
		quantity: 124,
	},
	{
		garage_id: 92,
		part_id: 2,
		quantity: 2,
	},
	{
		garage_id: 92,
		part_id: 3,
		quantity: 149,
	},
	{
		garage_id: 92,
		part_id: 4,
		quantity: 483,
	},
	{
		garage_id: 92,
		part_id: 5,
		quantity: 323,
	},
	{
		garage_id: 92,
		part_id: 6,
		quantity: 432,
	},
	{
		garage_id: 92,
		part_id: 7,
		quantity: 323,
	},
	{
		garage_id: 92,
		part_id: 8,
		quantity: 78,
	},
	{
		garage_id: 92,
		part_id: 9,
		quantity: 142,
	},
	{
		garage_id: 92,
		part_id: 10,
		quantity: 306,
	},
	{
		garage_id: 92,
		part_id: 11,
		quantity: 234,
	},
	{
		garage_id: 92,
		part_id: 12,
		quantity: 376,
	},
	{
		garage_id: 92,
		part_id: 13,
		quantity: 228,
	},
	{
		garage_id: 92,
		part_id: 14,
		quantity: 289,
	},
	{
		garage_id: 92,
		part_id: 15,
		quantity: 35,
	},
	{
		garage_id: 92,
		part_id: 16,
		quantity: 144,
	},
	{
		garage_id: 92,
		part_id: 17,
		quantity: 384,
	},
	{
		garage_id: 92,
		part_id: 18,
		quantity: 338,
	},
	{
		garage_id: 92,
		part_id: 19,
		quantity: 61,
	},
	{
		garage_id: 92,
		part_id: 20,
		quantity: 447,
	},
	{
		garage_id: 92,
		part_id: 21,
		quantity: 339,
	},
	{
		garage_id: 92,
		part_id: 22,
		quantity: 46,
	},
	{
		garage_id: 92,
		part_id: 23,
		quantity: 481,
	},
	{
		garage_id: 92,
		part_id: 24,
		quantity: 316,
	},
	{
		garage_id: 92,
		part_id: 25,
		quantity: 357,
	},
	{
		garage_id: 92,
		part_id: 26,
		quantity: 272,
	},
	{
		garage_id: 92,
		part_id: 27,
		quantity: 236,
	},
	{
		garage_id: 92,
		part_id: 28,
		quantity: 24,
	},
	{
		garage_id: 92,
		part_id: 29,
		quantity: 250,
	},
	{
		garage_id: 92,
		part_id: 30,
		quantity: 327,
	},
	{
		garage_id: 92,
		part_id: 31,
		quantity: 275,
	},
	{
		garage_id: 92,
		part_id: 32,
		quantity: 93,
	},
	{
		garage_id: 92,
		part_id: 33,
		quantity: 47,
	},
	{
		garage_id: 92,
		part_id: 34,
		quantity: 2,
	},
	{
		garage_id: 92,
		part_id: 35,
		quantity: 95,
	},
	{
		garage_id: 92,
		part_id: 36,
		quantity: 53,
	},
	{
		garage_id: 92,
		part_id: 37,
		quantity: 21,
	},
	{
		garage_id: 92,
		part_id: 38,
		quantity: 287,
	},
	{
		garage_id: 92,
		part_id: 39,
		quantity: 276,
	},
	{
		garage_id: 92,
		part_id: 40,
		quantity: 350,
	},
	{
		garage_id: 92,
		part_id: 41,
		quantity: 45,
	},
	{
		garage_id: 92,
		part_id: 42,
		quantity: 157,
	},
	{
		garage_id: 92,
		part_id: 43,
		quantity: 264,
	},
	{
		garage_id: 92,
		part_id: 44,
		quantity: 352,
	},
	{
		garage_id: 92,
		part_id: 45,
		quantity: 493,
	},
	{
		garage_id: 92,
		part_id: 46,
		quantity: 189,
	},
	{
		garage_id: 92,
		part_id: 47,
		quantity: 343,
	},
	{
		garage_id: 92,
		part_id: 48,
		quantity: 269,
	},
	{
		garage_id: 92,
		part_id: 49,
		quantity: 297,
	},
	{
		garage_id: 92,
		part_id: 50,
		quantity: 436,
	},
	{
		garage_id: 92,
		part_id: 51,
		quantity: 350,
	},
	{
		garage_id: 92,
		part_id: 52,
		quantity: 273,
	},
	{
		garage_id: 92,
		part_id: 53,
		quantity: 331,
	},
	{
		garage_id: 92,
		part_id: 54,
		quantity: 87,
	},
	{
		garage_id: 92,
		part_id: 55,
		quantity: 46,
	},
	{
		garage_id: 92,
		part_id: 56,
		quantity: 229,
	},
	{
		garage_id: 92,
		part_id: 57,
		quantity: 63,
	},
	{
		garage_id: 92,
		part_id: 58,
		quantity: 380,
	},
	{
		garage_id: 92,
		part_id: 59,
		quantity: 122,
	},
	{
		garage_id: 92,
		part_id: 60,
		quantity: 245,
	},
	{
		garage_id: 92,
		part_id: 61,
		quantity: 320,
	},
	{
		garage_id: 92,
		part_id: 62,
		quantity: 251,
	},
	{
		garage_id: 92,
		part_id: 63,
		quantity: 410,
	},
	{
		garage_id: 92,
		part_id: 64,
		quantity: 358,
	},
	{
		garage_id: 92,
		part_id: 65,
		quantity: 151,
	},
	{
		garage_id: 92,
		part_id: 66,
		quantity: 456,
	},
	{
		garage_id: 92,
		part_id: 67,
		quantity: 131,
	},
	{
		garage_id: 92,
		part_id: 68,
		quantity: 105,
	},
	{
		garage_id: 92,
		part_id: 69,
		quantity: 381,
	},
	{
		garage_id: 92,
		part_id: 70,
		quantity: 31,
	},
	{
		garage_id: 92,
		part_id: 71,
		quantity: 2,
	},
	{
		garage_id: 92,
		part_id: 72,
		quantity: 181,
	},
	{
		garage_id: 92,
		part_id: 73,
		quantity: 70,
	},
	{
		garage_id: 92,
		part_id: 74,
		quantity: 19,
	},
	{
		garage_id: 92,
		part_id: 75,
		quantity: 76,
	},
	{
		garage_id: 92,
		part_id: 76,
		quantity: 306,
	},
	{
		garage_id: 92,
		part_id: 77,
		quantity: 66,
	},
	{
		garage_id: 92,
		part_id: 78,
		quantity: 74,
	},
	{
		garage_id: 92,
		part_id: 79,
		quantity: 223,
	},
	{
		garage_id: 92,
		part_id: 80,
		quantity: 471,
	},
	{
		garage_id: 92,
		part_id: 81,
		quantity: 183,
	},
	{
		garage_id: 92,
		part_id: 82,
		quantity: 385,
	},
	{
		garage_id: 92,
		part_id: 83,
		quantity: 174,
	},
	{
		garage_id: 92,
		part_id: 84,
		quantity: 377,
	},
	{
		garage_id: 92,
		part_id: 85,
		quantity: 493,
	},
	{
		garage_id: 92,
		part_id: 86,
		quantity: 276,
	},
	{
		garage_id: 92,
		part_id: 87,
		quantity: 59,
	},
	{
		garage_id: 92,
		part_id: 88,
		quantity: 54,
	},
	{
		garage_id: 92,
		part_id: 89,
		quantity: 194,
	},
	{
		garage_id: 92,
		part_id: 90,
		quantity: 193,
	},
	{
		garage_id: 92,
		part_id: 91,
		quantity: 492,
	},
	{
		garage_id: 92,
		part_id: 92,
		quantity: 292,
	},
	{
		garage_id: 92,
		part_id: 93,
		quantity: 433,
	},
	{
		garage_id: 92,
		part_id: 94,
		quantity: 76,
	},
	{
		garage_id: 92,
		part_id: 95,
		quantity: 41,
	},
	{
		garage_id: 92,
		part_id: 96,
		quantity: 271,
	},
	{
		garage_id: 92,
		part_id: 97,
		quantity: 254,
	},
	{
		garage_id: 92,
		part_id: 98,
		quantity: 127,
	},
	{
		garage_id: 92,
		part_id: 99,
		quantity: 78,
	},
	{
		garage_id: 92,
		part_id: 100,
		quantity: 467,
	},
	{
		garage_id: 92,
		part_id: 101,
		quantity: 368,
	},
	{
		garage_id: 92,
		part_id: 102,
		quantity: 42,
	},
	{
		garage_id: 92,
		part_id: 103,
		quantity: 99,
	},
	{
		garage_id: 92,
		part_id: 104,
		quantity: 277,
	},
	{
		garage_id: 92,
		part_id: 105,
		quantity: 444,
	},
	{
		garage_id: 92,
		part_id: 106,
		quantity: 64,
	},
	{
		garage_id: 92,
		part_id: 107,
		quantity: 218,
	},
	{
		garage_id: 92,
		part_id: 108,
		quantity: 242,
	},
	{
		garage_id: 92,
		part_id: 109,
		quantity: 62,
	},
	{
		garage_id: 92,
		part_id: 110,
		quantity: 184,
	},
	{
		garage_id: 92,
		part_id: 111,
		quantity: 121,
	},
	{
		garage_id: 92,
		part_id: 112,
		quantity: 344,
	},
	{
		garage_id: 92,
		part_id: 113,
		quantity: 182,
	},
	{
		garage_id: 92,
		part_id: 114,
		quantity: 368,
	},
	{
		garage_id: 92,
		part_id: 115,
		quantity: 185,
	},
	{
		garage_id: 92,
		part_id: 116,
		quantity: 71,
	},
	{
		garage_id: 92,
		part_id: 117,
		quantity: 329,
	},
	{
		garage_id: 92,
		part_id: 118,
		quantity: 443,
	},
	{
		garage_id: 92,
		part_id: 119,
		quantity: 56,
	},
	{
		garage_id: 92,
		part_id: 120,
		quantity: 97,
	},
	{
		garage_id: 92,
		part_id: 121,
		quantity: 404,
	},
	{
		garage_id: 92,
		part_id: 122,
		quantity: 333,
	},
	{
		garage_id: 92,
		part_id: 123,
		quantity: 307,
	},
	{
		garage_id: 92,
		part_id: 124,
		quantity: 218,
	},
	{
		garage_id: 92,
		part_id: 125,
		quantity: 391,
	},
	{
		garage_id: 92,
		part_id: 126,
		quantity: 418,
	},
	{
		garage_id: 92,
		part_id: 127,
		quantity: 81,
	},
	{
		garage_id: 92,
		part_id: 128,
		quantity: 499,
	},
	{
		garage_id: 92,
		part_id: 129,
		quantity: 164,
	},
	{
		garage_id: 92,
		part_id: 130,
		quantity: 163,
	},
	{
		garage_id: 92,
		part_id: 131,
		quantity: 452,
	},
	{
		garage_id: 92,
		part_id: 132,
		quantity: 340,
	},
	{
		garage_id: 92,
		part_id: 133,
		quantity: 277,
	},
	{
		garage_id: 92,
		part_id: 134,
		quantity: 379,
	},
	{
		garage_id: 92,
		part_id: 135,
		quantity: 36,
	},
	{
		garage_id: 92,
		part_id: 136,
		quantity: 377,
	},
	{
		garage_id: 92,
		part_id: 137,
		quantity: 147,
	},
	{
		garage_id: 92,
		part_id: 138,
		quantity: 345,
	},
	{
		garage_id: 92,
		part_id: 139,
		quantity: 352,
	},
	{
		garage_id: 92,
		part_id: 140,
		quantity: 129,
	},
	{
		garage_id: 92,
		part_id: 141,
		quantity: 44,
	},
	{
		garage_id: 92,
		part_id: 142,
		quantity: 421,
	},
	{
		garage_id: 92,
		part_id: 143,
		quantity: 287,
	},
	{
		garage_id: 92,
		part_id: 144,
		quantity: 160,
	},
	{
		garage_id: 92,
		part_id: 145,
		quantity: 92,
	},
	{
		garage_id: 92,
		part_id: 146,
		quantity: 128,
	},
	{
		garage_id: 92,
		part_id: 147,
		quantity: 413,
	},
	{
		garage_id: 92,
		part_id: 148,
		quantity: 68,
	},
	{
		garage_id: 92,
		part_id: 149,
		quantity: 278,
	},
	{
		garage_id: 92,
		part_id: 150,
		quantity: 426,
	},
	{
		garage_id: 92,
		part_id: 151,
		quantity: 391,
	},
	{
		garage_id: 92,
		part_id: 152,
		quantity: 270,
	},
	{
		garage_id: 92,
		part_id: 153,
		quantity: 372,
	},
	{
		garage_id: 92,
		part_id: 154,
		quantity: 88,
	},
	{
		garage_id: 92,
		part_id: 155,
		quantity: 214,
	},
	{
		garage_id: 92,
		part_id: 156,
		quantity: 318,
	},
	{
		garage_id: 92,
		part_id: 157,
		quantity: 73,
	},
	{
		garage_id: 92,
		part_id: 158,
		quantity: 258,
	},
	{
		garage_id: 92,
		part_id: 159,
		quantity: 392,
	},
	{
		garage_id: 92,
		part_id: 160,
		quantity: 103,
	},
	{
		garage_id: 92,
		part_id: 161,
		quantity: 25,
	},
	{
		garage_id: 92,
		part_id: 162,
		quantity: 179,
	},
	{
		garage_id: 92,
		part_id: 163,
		quantity: 115,
	},
	{
		garage_id: 92,
		part_id: 164,
		quantity: 249,
	},
	{
		garage_id: 92,
		part_id: 165,
		quantity: 63,
	},
	{
		garage_id: 92,
		part_id: 166,
		quantity: 94,
	},
	{
		garage_id: 92,
		part_id: 167,
		quantity: 309,
	},
	{
		garage_id: 92,
		part_id: 168,
		quantity: 420,
	},
	{
		garage_id: 92,
		part_id: 169,
		quantity: 110,
	},
	{
		garage_id: 92,
		part_id: 170,
		quantity: 367,
	},
	{
		garage_id: 92,
		part_id: 171,
		quantity: 153,
	},
	{
		garage_id: 92,
		part_id: 172,
		quantity: 45,
	},
	{
		garage_id: 92,
		part_id: 173,
		quantity: 138,
	},
	{
		garage_id: 92,
		part_id: 174,
		quantity: 178,
	},
	{
		garage_id: 92,
		part_id: 175,
		quantity: 176,
	},
	{
		garage_id: 92,
		part_id: 176,
		quantity: 435,
	},
	{
		garage_id: 92,
		part_id: 177,
		quantity: 350,
	},
	{
		garage_id: 92,
		part_id: 178,
		quantity: 155,
	},
	{
		garage_id: 92,
		part_id: 179,
		quantity: 29,
	},
	{
		garage_id: 92,
		part_id: 180,
		quantity: 429,
	},
	{
		garage_id: 92,
		part_id: 181,
		quantity: 164,
	},
	{
		garage_id: 92,
		part_id: 182,
		quantity: 367,
	},
	{
		garage_id: 92,
		part_id: 183,
		quantity: 44,
	},
	{
		garage_id: 92,
		part_id: 184,
		quantity: 88,
	},
	{
		garage_id: 92,
		part_id: 185,
		quantity: 307,
	},
	{
		garage_id: 92,
		part_id: 186,
		quantity: 88,
	},
	{
		garage_id: 92,
		part_id: 187,
		quantity: 330,
	},
	{
		garage_id: 92,
		part_id: 188,
		quantity: 477,
	},
	{
		garage_id: 92,
		part_id: 189,
		quantity: 462,
	},
	{
		garage_id: 92,
		part_id: 190,
		quantity: 123,
	},
	{
		garage_id: 92,
		part_id: 191,
		quantity: 289,
	},
	{
		garage_id: 92,
		part_id: 192,
		quantity: 56,
	},
	{
		garage_id: 92,
		part_id: 193,
		quantity: 310,
	},
	{
		garage_id: 92,
		part_id: 194,
		quantity: 4,
	},
	{
		garage_id: 92,
		part_id: 195,
		quantity: 283,
	},
	{
		garage_id: 92,
		part_id: 196,
		quantity: 45,
	},
	{
		garage_id: 92,
		part_id: 197,
		quantity: 335,
	},
	{
		garage_id: 92,
		part_id: 198,
		quantity: 225,
	},
	{
		garage_id: 92,
		part_id: 199,
		quantity: 400,
	},
	{
		garage_id: 92,
		part_id: 200,
		quantity: 319,
	},
	{
		garage_id: 92,
		part_id: 201,
		quantity: 383,
	},
	{
		garage_id: 92,
		part_id: 202,
		quantity: 181,
	},
	{
		garage_id: 92,
		part_id: 203,
		quantity: 433,
	},
	{
		garage_id: 92,
		part_id: 204,
		quantity: 447,
	},
	{
		garage_id: 92,
		part_id: 205,
		quantity: 18,
	},
	{
		garage_id: 92,
		part_id: 206,
		quantity: 363,
	},
	{
		garage_id: 92,
		part_id: 207,
		quantity: 101,
	},
	{
		garage_id: 92,
		part_id: 208,
		quantity: 45,
	},
	{
		garage_id: 92,
		part_id: 209,
		quantity: 212,
	},
	{
		garage_id: 92,
		part_id: 210,
		quantity: 17,
	},
	{
		garage_id: 92,
		part_id: 211,
		quantity: 365,
	},
	{
		garage_id: 92,
		part_id: 212,
		quantity: 141,
	},
	{
		garage_id: 92,
		part_id: 213,
		quantity: 433,
	},
	{
		garage_id: 92,
		part_id: 214,
		quantity: 258,
	},
	{
		garage_id: 92,
		part_id: 215,
		quantity: 268,
	},
	{
		garage_id: 92,
		part_id: 216,
		quantity: 480,
	},
	{
		garage_id: 92,
		part_id: 217,
		quantity: 219,
	},
	{
		garage_id: 92,
		part_id: 218,
		quantity: 359,
	},
	{
		garage_id: 92,
		part_id: 219,
		quantity: 391,
	},
	{
		garage_id: 92,
		part_id: 220,
		quantity: 425,
	},
	{
		garage_id: 92,
		part_id: 221,
		quantity: 340,
	},
	{
		garage_id: 92,
		part_id: 222,
		quantity: 465,
	},
	{
		garage_id: 92,
		part_id: 223,
		quantity: 152,
	},
	{
		garage_id: 92,
		part_id: 224,
		quantity: 496,
	},
	{
		garage_id: 92,
		part_id: 225,
		quantity: 369,
	},
	{
		garage_id: 92,
		part_id: 226,
		quantity: 234,
	},
	{
		garage_id: 92,
		part_id: 227,
		quantity: 414,
	},
	{
		garage_id: 92,
		part_id: 228,
		quantity: 205,
	},
	{
		garage_id: 92,
		part_id: 229,
		quantity: 314,
	},
	{
		garage_id: 92,
		part_id: 230,
		quantity: 162,
	},
	{
		garage_id: 92,
		part_id: 231,
		quantity: 393,
	},
	{
		garage_id: 92,
		part_id: 232,
		quantity: 238,
	},
	{
		garage_id: 92,
		part_id: 233,
		quantity: 69,
	},
	{
		garage_id: 92,
		part_id: 234,
		quantity: 295,
	},
	{
		garage_id: 92,
		part_id: 235,
		quantity: 168,
	},
	{
		garage_id: 92,
		part_id: 236,
		quantity: 378,
	},
	{
		garage_id: 92,
		part_id: 237,
		quantity: 72,
	},
	{
		garage_id: 92,
		part_id: 238,
		quantity: 240,
	},
	{
		garage_id: 92,
		part_id: 239,
		quantity: 276,
	},
	{
		garage_id: 92,
		part_id: 240,
		quantity: 88,
	},
	{
		garage_id: 92,
		part_id: 241,
		quantity: 294,
	},
	{
		garage_id: 92,
		part_id: 242,
		quantity: 33,
	},
	{
		garage_id: 92,
		part_id: 243,
		quantity: 0,
	},
	{
		garage_id: 92,
		part_id: 244,
		quantity: 39,
	},
	{
		garage_id: 92,
		part_id: 245,
		quantity: 267,
	},
	{
		garage_id: 92,
		part_id: 246,
		quantity: 274,
	},
	{
		garage_id: 92,
		part_id: 247,
		quantity: 192,
	},
	{
		garage_id: 92,
		part_id: 248,
		quantity: 327,
	},
	{
		garage_id: 92,
		part_id: 249,
		quantity: 40,
	},
	{
		garage_id: 92,
		part_id: 250,
		quantity: 470,
	},
	{
		garage_id: 92,
		part_id: 251,
		quantity: 222,
	},
	{
		garage_id: 92,
		part_id: 252,
		quantity: 94,
	},
	{
		garage_id: 92,
		part_id: 253,
		quantity: 461,
	},
	{
		garage_id: 92,
		part_id: 254,
		quantity: 473,
	},
	{
		garage_id: 92,
		part_id: 255,
		quantity: 203,
	},
	{
		garage_id: 92,
		part_id: 256,
		quantity: 278,
	},
	{
		garage_id: 92,
		part_id: 257,
		quantity: 136,
	},
	{
		garage_id: 92,
		part_id: 258,
		quantity: 449,
	},
	{
		garage_id: 92,
		part_id: 259,
		quantity: 283,
	},
	{
		garage_id: 92,
		part_id: 260,
		quantity: 333,
	},
	{
		garage_id: 92,
		part_id: 261,
		quantity: 80,
	},
	{
		garage_id: 92,
		part_id: 262,
		quantity: 185,
	},
	{
		garage_id: 92,
		part_id: 263,
		quantity: 8,
	},
	{
		garage_id: 92,
		part_id: 264,
		quantity: 246,
	},
	{
		garage_id: 92,
		part_id: 265,
		quantity: 89,
	},
	{
		garage_id: 92,
		part_id: 266,
		quantity: 321,
	},
	{
		garage_id: 92,
		part_id: 267,
		quantity: 490,
	},
	{
		garage_id: 92,
		part_id: 268,
		quantity: 427,
	},
	{
		garage_id: 92,
		part_id: 269,
		quantity: 342,
	},
	{
		garage_id: 92,
		part_id: 270,
		quantity: 191,
	},
	{
		garage_id: 92,
		part_id: 271,
		quantity: 25,
	},
	{
		garage_id: 92,
		part_id: 272,
		quantity: 285,
	},
	{
		garage_id: 92,
		part_id: 273,
		quantity: 362,
	},
	{
		garage_id: 92,
		part_id: 274,
		quantity: 134,
	},
	{
		garage_id: 92,
		part_id: 275,
		quantity: 420,
	},
	{
		garage_id: 92,
		part_id: 276,
		quantity: 317,
	},
	{
		garage_id: 92,
		part_id: 277,
		quantity: 399,
	},
	{
		garage_id: 92,
		part_id: 278,
		quantity: 271,
	},
	{
		garage_id: 92,
		part_id: 279,
		quantity: 376,
	},
	{
		garage_id: 92,
		part_id: 280,
		quantity: 398,
	},
	{
		garage_id: 92,
		part_id: 281,
		quantity: 161,
	},
	{
		garage_id: 92,
		part_id: 282,
		quantity: 240,
	},
	{
		garage_id: 92,
		part_id: 283,
		quantity: 48,
	},
	{
		garage_id: 92,
		part_id: 284,
		quantity: 352,
	},
	{
		garage_id: 92,
		part_id: 285,
		quantity: 340,
	},
	{
		garage_id: 92,
		part_id: 286,
		quantity: 495,
	},
	{
		garage_id: 92,
		part_id: 287,
		quantity: 414,
	},
	{
		garage_id: 92,
		part_id: 288,
		quantity: 442,
	},
	{
		garage_id: 92,
		part_id: 289,
		quantity: 240,
	},
	{
		garage_id: 92,
		part_id: 290,
		quantity: 419,
	},
	{
		garage_id: 92,
		part_id: 291,
		quantity: 340,
	},
	{
		garage_id: 92,
		part_id: 292,
		quantity: 380,
	},
	{
		garage_id: 92,
		part_id: 293,
		quantity: 35,
	},
	{
		garage_id: 92,
		part_id: 294,
		quantity: 388,
	},
	{
		garage_id: 92,
		part_id: 295,
		quantity: 224,
	},
	{
		garage_id: 92,
		part_id: 296,
		quantity: 235,
	},
	{
		garage_id: 92,
		part_id: 297,
		quantity: 455,
	},
	{
		garage_id: 92,
		part_id: 298,
		quantity: 0,
	},
	{
		garage_id: 92,
		part_id: 299,
		quantity: 246,
	},
	{
		garage_id: 92,
		part_id: 300,
		quantity: 150,
	},
	{
		garage_id: 92,
		part_id: 301,
		quantity: 365,
	},
	{
		garage_id: 92,
		part_id: 302,
		quantity: 444,
	},
	{
		garage_id: 92,
		part_id: 303,
		quantity: 269,
	},
	{
		garage_id: 92,
		part_id: 304,
		quantity: 45,
	},
	{
		garage_id: 92,
		part_id: 305,
		quantity: 234,
	},
	{
		garage_id: 92,
		part_id: 306,
		quantity: 467,
	},
	{
		garage_id: 92,
		part_id: 307,
		quantity: 485,
	},
	{
		garage_id: 92,
		part_id: 308,
		quantity: 475,
	},
	{
		garage_id: 92,
		part_id: 309,
		quantity: 67,
	},
	{
		garage_id: 92,
		part_id: 310,
		quantity: 4,
	},
	{
		garage_id: 92,
		part_id: 311,
		quantity: 232,
	},
	{
		garage_id: 92,
		part_id: 312,
		quantity: 17,
	},
	{
		garage_id: 92,
		part_id: 313,
		quantity: 443,
	},
	{
		garage_id: 92,
		part_id: 314,
		quantity: 296,
	},
	{
		garage_id: 92,
		part_id: 315,
		quantity: 61,
	},
	{
		garage_id: 92,
		part_id: 316,
		quantity: 74,
	},
	{
		garage_id: 92,
		part_id: 317,
		quantity: 396,
	},
	{
		garage_id: 92,
		part_id: 318,
		quantity: 328,
	},
	{
		garage_id: 92,
		part_id: 319,
		quantity: 30,
	},
	{
		garage_id: 92,
		part_id: 320,
		quantity: 411,
	},
	{
		garage_id: 92,
		part_id: 321,
		quantity: 30,
	},
	{
		garage_id: 92,
		part_id: 322,
		quantity: 32,
	},
	{
		garage_id: 92,
		part_id: 323,
		quantity: 86,
	},
	{
		garage_id: 92,
		part_id: 324,
		quantity: 429,
	},
	{
		garage_id: 92,
		part_id: 325,
		quantity: 1,
	},
	{
		garage_id: 92,
		part_id: 326,
		quantity: 410,
	},
	{
		garage_id: 92,
		part_id: 327,
		quantity: 425,
	},
	{
		garage_id: 92,
		part_id: 328,
		quantity: 440,
	},
	{
		garage_id: 92,
		part_id: 329,
		quantity: 466,
	},
	{
		garage_id: 92,
		part_id: 330,
		quantity: 380,
	},
	{
		garage_id: 92,
		part_id: 331,
		quantity: 76,
	},
	{
		garage_id: 92,
		part_id: 332,
		quantity: 273,
	},
	{
		garage_id: 92,
		part_id: 333,
		quantity: 170,
	},
	{
		garage_id: 92,
		part_id: 334,
		quantity: 412,
	},
	{
		garage_id: 92,
		part_id: 335,
		quantity: 270,
	},
	{
		garage_id: 92,
		part_id: 336,
		quantity: 247,
	},
	{
		garage_id: 92,
		part_id: 337,
		quantity: 142,
	},
	{
		garage_id: 92,
		part_id: 338,
		quantity: 233,
	},
	{
		garage_id: 92,
		part_id: 339,
		quantity: 31,
	},
	{
		garage_id: 92,
		part_id: 340,
		quantity: 125,
	},
	{
		garage_id: 92,
		part_id: 341,
		quantity: 106,
	},
	{
		garage_id: 92,
		part_id: 342,
		quantity: 189,
	},
	{
		garage_id: 92,
		part_id: 343,
		quantity: 10,
	},
	{
		garage_id: 92,
		part_id: 344,
		quantity: 7,
	},
	{
		garage_id: 92,
		part_id: 345,
		quantity: 84,
	},
	{
		garage_id: 92,
		part_id: 346,
		quantity: 455,
	},
	{
		garage_id: 92,
		part_id: 347,
		quantity: 319,
	},
	{
		garage_id: 92,
		part_id: 348,
		quantity: 254,
	},
	{
		garage_id: 92,
		part_id: 349,
		quantity: 413,
	},
	{
		garage_id: 92,
		part_id: 350,
		quantity: 261,
	},
	{
		garage_id: 92,
		part_id: 351,
		quantity: 308,
	},
	{
		garage_id: 92,
		part_id: 352,
		quantity: 500,
	},
	{
		garage_id: 92,
		part_id: 353,
		quantity: 349,
	},
	{
		garage_id: 92,
		part_id: 354,
		quantity: 200,
	},
	{
		garage_id: 92,
		part_id: 355,
		quantity: 306,
	},
	{
		garage_id: 92,
		part_id: 356,
		quantity: 67,
	},
	{
		garage_id: 92,
		part_id: 357,
		quantity: 327,
	},
	{
		garage_id: 92,
		part_id: 358,
		quantity: 483,
	},
	{
		garage_id: 92,
		part_id: 359,
		quantity: 276,
	},
	{
		garage_id: 92,
		part_id: 360,
		quantity: 452,
	},
	{
		garage_id: 92,
		part_id: 361,
		quantity: 133,
	},
	{
		garage_id: 92,
		part_id: 362,
		quantity: 319,
	},
	{
		garage_id: 92,
		part_id: 363,
		quantity: 309,
	},
	{
		garage_id: 92,
		part_id: 364,
		quantity: 448,
	},
	{
		garage_id: 92,
		part_id: 365,
		quantity: 229,
	},
	{
		garage_id: 92,
		part_id: 366,
		quantity: 96,
	},
	{
		garage_id: 92,
		part_id: 367,
		quantity: 137,
	},
	{
		garage_id: 92,
		part_id: 368,
		quantity: 201,
	},
	{
		garage_id: 92,
		part_id: 369,
		quantity: 114,
	},
	{
		garage_id: 92,
		part_id: 370,
		quantity: 261,
	},
	{
		garage_id: 92,
		part_id: 371,
		quantity: 57,
	},
	{
		garage_id: 92,
		part_id: 372,
		quantity: 275,
	},
	{
		garage_id: 92,
		part_id: 373,
		quantity: 98,
	},
	{
		garage_id: 92,
		part_id: 374,
		quantity: 309,
	},
	{
		garage_id: 92,
		part_id: 375,
		quantity: 228,
	},
	{
		garage_id: 92,
		part_id: 376,
		quantity: 107,
	},
	{
		garage_id: 92,
		part_id: 377,
		quantity: 111,
	},
	{
		garage_id: 92,
		part_id: 378,
		quantity: 401,
	},
	{
		garage_id: 92,
		part_id: 379,
		quantity: 206,
	},
	{
		garage_id: 92,
		part_id: 380,
		quantity: 100,
	},
	{
		garage_id: 92,
		part_id: 381,
		quantity: 210,
	},
	{
		garage_id: 92,
		part_id: 382,
		quantity: 464,
	},
	{
		garage_id: 92,
		part_id: 383,
		quantity: 380,
	},
	{
		garage_id: 92,
		part_id: 384,
		quantity: 421,
	},
	{
		garage_id: 92,
		part_id: 385,
		quantity: 338,
	},
	{
		garage_id: 92,
		part_id: 386,
		quantity: 105,
	},
	{
		garage_id: 92,
		part_id: 387,
		quantity: 48,
	},
	{
		garage_id: 92,
		part_id: 388,
		quantity: 185,
	},
	{
		garage_id: 92,
		part_id: 389,
		quantity: 386,
	},
	{
		garage_id: 92,
		part_id: 390,
		quantity: 147,
	},
	{
		garage_id: 92,
		part_id: 391,
		quantity: 128,
	},
	{
		garage_id: 92,
		part_id: 392,
		quantity: 37,
	},
	{
		garage_id: 92,
		part_id: 393,
		quantity: 117,
	},
	{
		garage_id: 92,
		part_id: 394,
		quantity: 13,
	},
	{
		garage_id: 92,
		part_id: 395,
		quantity: 193,
	},
	{
		garage_id: 92,
		part_id: 396,
		quantity: 310,
	},
	{
		garage_id: 92,
		part_id: 397,
		quantity: 372,
	},
	{
		garage_id: 92,
		part_id: 398,
		quantity: 206,
	},
	{
		garage_id: 92,
		part_id: 399,
		quantity: 192,
	},
	{
		garage_id: 93,
		part_id: 0,
		quantity: 203,
	},
	{
		garage_id: 93,
		part_id: 1,
		quantity: 184,
	},
	{
		garage_id: 93,
		part_id: 2,
		quantity: 371,
	},
	{
		garage_id: 93,
		part_id: 3,
		quantity: 80,
	},
	{
		garage_id: 93,
		part_id: 4,
		quantity: 141,
	},
	{
		garage_id: 93,
		part_id: 5,
		quantity: 213,
	},
	{
		garage_id: 93,
		part_id: 6,
		quantity: 182,
	},
	{
		garage_id: 93,
		part_id: 7,
		quantity: 50,
	},
	{
		garage_id: 93,
		part_id: 8,
		quantity: 396,
	},
	{
		garage_id: 93,
		part_id: 9,
		quantity: 21,
	},
	{
		garage_id: 93,
		part_id: 10,
		quantity: 330,
	},
	{
		garage_id: 93,
		part_id: 11,
		quantity: 429,
	},
	{
		garage_id: 93,
		part_id: 12,
		quantity: 299,
	},
	{
		garage_id: 93,
		part_id: 13,
		quantity: 316,
	},
	{
		garage_id: 93,
		part_id: 14,
		quantity: 327,
	},
	{
		garage_id: 93,
		part_id: 15,
		quantity: 432,
	},
	{
		garage_id: 93,
		part_id: 16,
		quantity: 79,
	},
	{
		garage_id: 93,
		part_id: 17,
		quantity: 398,
	},
	{
		garage_id: 93,
		part_id: 18,
		quantity: 321,
	},
	{
		garage_id: 93,
		part_id: 19,
		quantity: 3,
	},
	{
		garage_id: 93,
		part_id: 20,
		quantity: 414,
	},
	{
		garage_id: 93,
		part_id: 21,
		quantity: 84,
	},
	{
		garage_id: 93,
		part_id: 22,
		quantity: 395,
	},
	{
		garage_id: 93,
		part_id: 23,
		quantity: 72,
	},
	{
		garage_id: 93,
		part_id: 24,
		quantity: 12,
	},
	{
		garage_id: 93,
		part_id: 25,
		quantity: 323,
	},
	{
		garage_id: 93,
		part_id: 26,
		quantity: 441,
	},
	{
		garage_id: 93,
		part_id: 27,
		quantity: 94,
	},
	{
		garage_id: 93,
		part_id: 28,
		quantity: 467,
	},
	{
		garage_id: 93,
		part_id: 29,
		quantity: 345,
	},
	{
		garage_id: 93,
		part_id: 30,
		quantity: 481,
	},
	{
		garage_id: 93,
		part_id: 31,
		quantity: 170,
	},
	{
		garage_id: 93,
		part_id: 32,
		quantity: 400,
	},
	{
		garage_id: 93,
		part_id: 33,
		quantity: 164,
	},
	{
		garage_id: 93,
		part_id: 34,
		quantity: 270,
	},
	{
		garage_id: 93,
		part_id: 35,
		quantity: 243,
	},
	{
		garage_id: 93,
		part_id: 36,
		quantity: 258,
	},
	{
		garage_id: 93,
		part_id: 37,
		quantity: 460,
	},
	{
		garage_id: 93,
		part_id: 38,
		quantity: 205,
	},
	{
		garage_id: 93,
		part_id: 39,
		quantity: 358,
	},
	{
		garage_id: 93,
		part_id: 40,
		quantity: 173,
	},
	{
		garage_id: 93,
		part_id: 41,
		quantity: 12,
	},
	{
		garage_id: 93,
		part_id: 42,
		quantity: 116,
	},
	{
		garage_id: 93,
		part_id: 43,
		quantity: 343,
	},
	{
		garage_id: 93,
		part_id: 44,
		quantity: 374,
	},
	{
		garage_id: 93,
		part_id: 45,
		quantity: 89,
	},
	{
		garage_id: 93,
		part_id: 46,
		quantity: 350,
	},
	{
		garage_id: 93,
		part_id: 47,
		quantity: 454,
	},
	{
		garage_id: 93,
		part_id: 48,
		quantity: 146,
	},
	{
		garage_id: 93,
		part_id: 49,
		quantity: 150,
	},
	{
		garage_id: 93,
		part_id: 50,
		quantity: 362,
	},
	{
		garage_id: 93,
		part_id: 51,
		quantity: 486,
	},
	{
		garage_id: 93,
		part_id: 52,
		quantity: 288,
	},
	{
		garage_id: 93,
		part_id: 53,
		quantity: 328,
	},
	{
		garage_id: 93,
		part_id: 54,
		quantity: 212,
	},
	{
		garage_id: 93,
		part_id: 55,
		quantity: 417,
	},
	{
		garage_id: 93,
		part_id: 56,
		quantity: 233,
	},
	{
		garage_id: 93,
		part_id: 57,
		quantity: 81,
	},
	{
		garage_id: 93,
		part_id: 58,
		quantity: 215,
	},
	{
		garage_id: 93,
		part_id: 59,
		quantity: 256,
	},
	{
		garage_id: 93,
		part_id: 60,
		quantity: 237,
	},
	{
		garage_id: 93,
		part_id: 61,
		quantity: 226,
	},
	{
		garage_id: 93,
		part_id: 62,
		quantity: 79,
	},
	{
		garage_id: 93,
		part_id: 63,
		quantity: 26,
	},
	{
		garage_id: 93,
		part_id: 64,
		quantity: 195,
	},
	{
		garage_id: 93,
		part_id: 65,
		quantity: 367,
	},
	{
		garage_id: 93,
		part_id: 66,
		quantity: 241,
	},
	{
		garage_id: 93,
		part_id: 67,
		quantity: 194,
	},
	{
		garage_id: 93,
		part_id: 68,
		quantity: 77,
	},
	{
		garage_id: 93,
		part_id: 69,
		quantity: 254,
	},
	{
		garage_id: 93,
		part_id: 70,
		quantity: 167,
	},
	{
		garage_id: 93,
		part_id: 71,
		quantity: 179,
	},
	{
		garage_id: 93,
		part_id: 72,
		quantity: 134,
	},
	{
		garage_id: 93,
		part_id: 73,
		quantity: 186,
	},
	{
		garage_id: 93,
		part_id: 74,
		quantity: 278,
	},
	{
		garage_id: 93,
		part_id: 75,
		quantity: 189,
	},
	{
		garage_id: 93,
		part_id: 76,
		quantity: 306,
	},
	{
		garage_id: 93,
		part_id: 77,
		quantity: 80,
	},
	{
		garage_id: 93,
		part_id: 78,
		quantity: 433,
	},
	{
		garage_id: 93,
		part_id: 79,
		quantity: 128,
	},
	{
		garage_id: 93,
		part_id: 80,
		quantity: 245,
	},
	{
		garage_id: 93,
		part_id: 81,
		quantity: 393,
	},
	{
		garage_id: 93,
		part_id: 82,
		quantity: 452,
	},
	{
		garage_id: 93,
		part_id: 83,
		quantity: 210,
	},
	{
		garage_id: 93,
		part_id: 84,
		quantity: 264,
	},
	{
		garage_id: 93,
		part_id: 85,
		quantity: 155,
	},
	{
		garage_id: 93,
		part_id: 86,
		quantity: 75,
	},
	{
		garage_id: 93,
		part_id: 87,
		quantity: 278,
	},
	{
		garage_id: 93,
		part_id: 88,
		quantity: 455,
	},
	{
		garage_id: 93,
		part_id: 89,
		quantity: 257,
	},
	{
		garage_id: 93,
		part_id: 90,
		quantity: 10,
	},
	{
		garage_id: 93,
		part_id: 91,
		quantity: 83,
	},
	{
		garage_id: 93,
		part_id: 92,
		quantity: 340,
	},
	{
		garage_id: 93,
		part_id: 93,
		quantity: 493,
	},
	{
		garage_id: 93,
		part_id: 94,
		quantity: 323,
	},
	{
		garage_id: 93,
		part_id: 95,
		quantity: 277,
	},
	{
		garage_id: 93,
		part_id: 96,
		quantity: 295,
	},
	{
		garage_id: 93,
		part_id: 97,
		quantity: 495,
	},
	{
		garage_id: 93,
		part_id: 98,
		quantity: 191,
	},
	{
		garage_id: 93,
		part_id: 99,
		quantity: 246,
	},
	{
		garage_id: 93,
		part_id: 100,
		quantity: 80,
	},
	{
		garage_id: 93,
		part_id: 101,
		quantity: 372,
	},
	{
		garage_id: 93,
		part_id: 102,
		quantity: 46,
	},
	{
		garage_id: 93,
		part_id: 103,
		quantity: 118,
	},
	{
		garage_id: 93,
		part_id: 104,
		quantity: 207,
	},
	{
		garage_id: 93,
		part_id: 105,
		quantity: 77,
	},
	{
		garage_id: 93,
		part_id: 106,
		quantity: 459,
	},
	{
		garage_id: 93,
		part_id: 107,
		quantity: 70,
	},
	{
		garage_id: 93,
		part_id: 108,
		quantity: 289,
	},
	{
		garage_id: 93,
		part_id: 109,
		quantity: 139,
	},
	{
		garage_id: 93,
		part_id: 110,
		quantity: 315,
	},
	{
		garage_id: 93,
		part_id: 111,
		quantity: 452,
	},
	{
		garage_id: 93,
		part_id: 112,
		quantity: 177,
	},
	{
		garage_id: 93,
		part_id: 113,
		quantity: 307,
	},
	{
		garage_id: 93,
		part_id: 114,
		quantity: 46,
	},
	{
		garage_id: 93,
		part_id: 115,
		quantity: 143,
	},
	{
		garage_id: 93,
		part_id: 116,
		quantity: 33,
	},
	{
		garage_id: 93,
		part_id: 117,
		quantity: 154,
	},
	{
		garage_id: 93,
		part_id: 118,
		quantity: 118,
	},
	{
		garage_id: 93,
		part_id: 119,
		quantity: 211,
	},
	{
		garage_id: 93,
		part_id: 120,
		quantity: 350,
	},
	{
		garage_id: 93,
		part_id: 121,
		quantity: 480,
	},
	{
		garage_id: 93,
		part_id: 122,
		quantity: 397,
	},
	{
		garage_id: 93,
		part_id: 123,
		quantity: 115,
	},
	{
		garage_id: 93,
		part_id: 124,
		quantity: 112,
	},
	{
		garage_id: 93,
		part_id: 125,
		quantity: 400,
	},
	{
		garage_id: 93,
		part_id: 126,
		quantity: 250,
	},
	{
		garage_id: 93,
		part_id: 127,
		quantity: 238,
	},
	{
		garage_id: 93,
		part_id: 128,
		quantity: 276,
	},
	{
		garage_id: 93,
		part_id: 129,
		quantity: 179,
	},
	{
		garage_id: 93,
		part_id: 130,
		quantity: 98,
	},
	{
		garage_id: 93,
		part_id: 131,
		quantity: 442,
	},
	{
		garage_id: 93,
		part_id: 132,
		quantity: 423,
	},
	{
		garage_id: 93,
		part_id: 133,
		quantity: 106,
	},
	{
		garage_id: 93,
		part_id: 134,
		quantity: 424,
	},
	{
		garage_id: 93,
		part_id: 135,
		quantity: 101,
	},
	{
		garage_id: 93,
		part_id: 136,
		quantity: 138,
	},
	{
		garage_id: 93,
		part_id: 137,
		quantity: 96,
	},
	{
		garage_id: 93,
		part_id: 138,
		quantity: 227,
	},
	{
		garage_id: 93,
		part_id: 139,
		quantity: 0,
	},
	{
		garage_id: 93,
		part_id: 140,
		quantity: 155,
	},
	{
		garage_id: 93,
		part_id: 141,
		quantity: 20,
	},
	{
		garage_id: 93,
		part_id: 142,
		quantity: 108,
	},
	{
		garage_id: 93,
		part_id: 143,
		quantity: 425,
	},
	{
		garage_id: 93,
		part_id: 144,
		quantity: 280,
	},
	{
		garage_id: 93,
		part_id: 145,
		quantity: 18,
	},
	{
		garage_id: 93,
		part_id: 146,
		quantity: 33,
	},
	{
		garage_id: 93,
		part_id: 147,
		quantity: 8,
	},
	{
		garage_id: 93,
		part_id: 148,
		quantity: 137,
	},
	{
		garage_id: 93,
		part_id: 149,
		quantity: 458,
	},
	{
		garage_id: 93,
		part_id: 150,
		quantity: 369,
	},
	{
		garage_id: 93,
		part_id: 151,
		quantity: 110,
	},
	{
		garage_id: 93,
		part_id: 152,
		quantity: 205,
	},
	{
		garage_id: 93,
		part_id: 153,
		quantity: 299,
	},
	{
		garage_id: 93,
		part_id: 154,
		quantity: 186,
	},
	{
		garage_id: 93,
		part_id: 155,
		quantity: 69,
	},
	{
		garage_id: 93,
		part_id: 156,
		quantity: 37,
	},
	{
		garage_id: 93,
		part_id: 157,
		quantity: 63,
	},
	{
		garage_id: 93,
		part_id: 158,
		quantity: 181,
	},
	{
		garage_id: 93,
		part_id: 159,
		quantity: 265,
	},
	{
		garage_id: 93,
		part_id: 160,
		quantity: 367,
	},
	{
		garage_id: 93,
		part_id: 161,
		quantity: 274,
	},
	{
		garage_id: 93,
		part_id: 162,
		quantity: 307,
	},
	{
		garage_id: 93,
		part_id: 163,
		quantity: 244,
	},
	{
		garage_id: 93,
		part_id: 164,
		quantity: 413,
	},
	{
		garage_id: 93,
		part_id: 165,
		quantity: 27,
	},
	{
		garage_id: 93,
		part_id: 166,
		quantity: 282,
	},
	{
		garage_id: 93,
		part_id: 167,
		quantity: 297,
	},
	{
		garage_id: 93,
		part_id: 168,
		quantity: 498,
	},
	{
		garage_id: 93,
		part_id: 169,
		quantity: 329,
	},
	{
		garage_id: 93,
		part_id: 170,
		quantity: 11,
	},
	{
		garage_id: 93,
		part_id: 171,
		quantity: 311,
	},
	{
		garage_id: 93,
		part_id: 172,
		quantity: 221,
	},
	{
		garage_id: 93,
		part_id: 173,
		quantity: 230,
	},
	{
		garage_id: 93,
		part_id: 174,
		quantity: 356,
	},
	{
		garage_id: 93,
		part_id: 175,
		quantity: 321,
	},
	{
		garage_id: 93,
		part_id: 176,
		quantity: 244,
	},
	{
		garage_id: 93,
		part_id: 177,
		quantity: 199,
	},
	{
		garage_id: 93,
		part_id: 178,
		quantity: 125,
	},
	{
		garage_id: 93,
		part_id: 179,
		quantity: 460,
	},
	{
		garage_id: 93,
		part_id: 180,
		quantity: 484,
	},
	{
		garage_id: 93,
		part_id: 181,
		quantity: 222,
	},
	{
		garage_id: 93,
		part_id: 182,
		quantity: 98,
	},
	{
		garage_id: 93,
		part_id: 183,
		quantity: 426,
	},
	{
		garage_id: 93,
		part_id: 184,
		quantity: 386,
	},
	{
		garage_id: 93,
		part_id: 185,
		quantity: 204,
	},
	{
		garage_id: 93,
		part_id: 186,
		quantity: 349,
	},
	{
		garage_id: 93,
		part_id: 187,
		quantity: 374,
	},
	{
		garage_id: 93,
		part_id: 188,
		quantity: 75,
	},
	{
		garage_id: 93,
		part_id: 189,
		quantity: 172,
	},
	{
		garage_id: 93,
		part_id: 190,
		quantity: 349,
	},
	{
		garage_id: 93,
		part_id: 191,
		quantity: 462,
	},
	{
		garage_id: 93,
		part_id: 192,
		quantity: 40,
	},
	{
		garage_id: 93,
		part_id: 193,
		quantity: 356,
	},
	{
		garage_id: 93,
		part_id: 194,
		quantity: 494,
	},
	{
		garage_id: 93,
		part_id: 195,
		quantity: 337,
	},
	{
		garage_id: 93,
		part_id: 196,
		quantity: 198,
	},
	{
		garage_id: 93,
		part_id: 197,
		quantity: 208,
	},
	{
		garage_id: 93,
		part_id: 198,
		quantity: 11,
	},
	{
		garage_id: 93,
		part_id: 199,
		quantity: 354,
	},
	{
		garage_id: 93,
		part_id: 200,
		quantity: 369,
	},
	{
		garage_id: 93,
		part_id: 201,
		quantity: 1,
	},
	{
		garage_id: 93,
		part_id: 202,
		quantity: 219,
	},
	{
		garage_id: 93,
		part_id: 203,
		quantity: 129,
	},
	{
		garage_id: 93,
		part_id: 204,
		quantity: 88,
	},
	{
		garage_id: 93,
		part_id: 205,
		quantity: 345,
	},
	{
		garage_id: 93,
		part_id: 206,
		quantity: 297,
	},
	{
		garage_id: 93,
		part_id: 207,
		quantity: 451,
	},
	{
		garage_id: 93,
		part_id: 208,
		quantity: 87,
	},
	{
		garage_id: 93,
		part_id: 209,
		quantity: 230,
	},
	{
		garage_id: 93,
		part_id: 210,
		quantity: 111,
	},
	{
		garage_id: 93,
		part_id: 211,
		quantity: 81,
	},
	{
		garage_id: 93,
		part_id: 212,
		quantity: 67,
	},
	{
		garage_id: 93,
		part_id: 213,
		quantity: 8,
	},
	{
		garage_id: 93,
		part_id: 214,
		quantity: 198,
	},
	{
		garage_id: 93,
		part_id: 215,
		quantity: 90,
	},
	{
		garage_id: 93,
		part_id: 216,
		quantity: 343,
	},
	{
		garage_id: 93,
		part_id: 217,
		quantity: 239,
	},
	{
		garage_id: 93,
		part_id: 218,
		quantity: 279,
	},
	{
		garage_id: 93,
		part_id: 219,
		quantity: 464,
	},
	{
		garage_id: 93,
		part_id: 220,
		quantity: 381,
	},
	{
		garage_id: 93,
		part_id: 221,
		quantity: 130,
	},
	{
		garage_id: 93,
		part_id: 222,
		quantity: 90,
	},
	{
		garage_id: 93,
		part_id: 223,
		quantity: 11,
	},
	{
		garage_id: 93,
		part_id: 224,
		quantity: 346,
	},
	{
		garage_id: 93,
		part_id: 225,
		quantity: 224,
	},
	{
		garage_id: 93,
		part_id: 226,
		quantity: 196,
	},
	{
		garage_id: 93,
		part_id: 227,
		quantity: 319,
	},
	{
		garage_id: 93,
		part_id: 228,
		quantity: 181,
	},
	{
		garage_id: 93,
		part_id: 229,
		quantity: 417,
	},
	{
		garage_id: 93,
		part_id: 230,
		quantity: 86,
	},
	{
		garage_id: 93,
		part_id: 231,
		quantity: 486,
	},
	{
		garage_id: 93,
		part_id: 232,
		quantity: 295,
	},
	{
		garage_id: 93,
		part_id: 233,
		quantity: 10,
	},
	{
		garage_id: 93,
		part_id: 234,
		quantity: 8,
	},
	{
		garage_id: 93,
		part_id: 235,
		quantity: 295,
	},
	{
		garage_id: 93,
		part_id: 236,
		quantity: 29,
	},
	{
		garage_id: 93,
		part_id: 237,
		quantity: 476,
	},
	{
		garage_id: 93,
		part_id: 238,
		quantity: 54,
	},
	{
		garage_id: 93,
		part_id: 239,
		quantity: 290,
	},
	{
		garage_id: 93,
		part_id: 240,
		quantity: 125,
	},
	{
		garage_id: 93,
		part_id: 241,
		quantity: 148,
	},
	{
		garage_id: 93,
		part_id: 242,
		quantity: 478,
	},
	{
		garage_id: 93,
		part_id: 243,
		quantity: 107,
	},
	{
		garage_id: 93,
		part_id: 244,
		quantity: 472,
	},
	{
		garage_id: 93,
		part_id: 245,
		quantity: 421,
	},
	{
		garage_id: 93,
		part_id: 246,
		quantity: 405,
	},
	{
		garage_id: 93,
		part_id: 247,
		quantity: 129,
	},
	{
		garage_id: 93,
		part_id: 248,
		quantity: 326,
	},
	{
		garage_id: 93,
		part_id: 249,
		quantity: 228,
	},
	{
		garage_id: 93,
		part_id: 250,
		quantity: 195,
	},
	{
		garage_id: 93,
		part_id: 251,
		quantity: 247,
	},
	{
		garage_id: 93,
		part_id: 252,
		quantity: 192,
	},
	{
		garage_id: 93,
		part_id: 253,
		quantity: 254,
	},
	{
		garage_id: 93,
		part_id: 254,
		quantity: 92,
	},
	{
		garage_id: 93,
		part_id: 255,
		quantity: 482,
	},
	{
		garage_id: 93,
		part_id: 256,
		quantity: 346,
	},
	{
		garage_id: 93,
		part_id: 257,
		quantity: 412,
	},
	{
		garage_id: 93,
		part_id: 258,
		quantity: 259,
	},
	{
		garage_id: 93,
		part_id: 259,
		quantity: 239,
	},
	{
		garage_id: 93,
		part_id: 260,
		quantity: 103,
	},
	{
		garage_id: 93,
		part_id: 261,
		quantity: 333,
	},
	{
		garage_id: 93,
		part_id: 262,
		quantity: 291,
	},
	{
		garage_id: 93,
		part_id: 263,
		quantity: 133,
	},
	{
		garage_id: 93,
		part_id: 264,
		quantity: 354,
	},
	{
		garage_id: 93,
		part_id: 265,
		quantity: 131,
	},
	{
		garage_id: 93,
		part_id: 266,
		quantity: 438,
	},
	{
		garage_id: 93,
		part_id: 267,
		quantity: 244,
	},
	{
		garage_id: 93,
		part_id: 268,
		quantity: 22,
	},
	{
		garage_id: 93,
		part_id: 269,
		quantity: 428,
	},
	{
		garage_id: 93,
		part_id: 270,
		quantity: 459,
	},
	{
		garage_id: 93,
		part_id: 271,
		quantity: 148,
	},
	{
		garage_id: 93,
		part_id: 272,
		quantity: 131,
	},
	{
		garage_id: 93,
		part_id: 273,
		quantity: 281,
	},
	{
		garage_id: 93,
		part_id: 274,
		quantity: 108,
	},
	{
		garage_id: 93,
		part_id: 275,
		quantity: 283,
	},
	{
		garage_id: 93,
		part_id: 276,
		quantity: 8,
	},
	{
		garage_id: 93,
		part_id: 277,
		quantity: 427,
	},
	{
		garage_id: 93,
		part_id: 278,
		quantity: 329,
	},
	{
		garage_id: 93,
		part_id: 279,
		quantity: 255,
	},
	{
		garage_id: 93,
		part_id: 280,
		quantity: 127,
	},
	{
		garage_id: 93,
		part_id: 281,
		quantity: 219,
	},
	{
		garage_id: 93,
		part_id: 282,
		quantity: 424,
	},
	{
		garage_id: 93,
		part_id: 283,
		quantity: 85,
	},
	{
		garage_id: 93,
		part_id: 284,
		quantity: 405,
	},
	{
		garage_id: 93,
		part_id: 285,
		quantity: 68,
	},
	{
		garage_id: 93,
		part_id: 286,
		quantity: 494,
	},
	{
		garage_id: 93,
		part_id: 287,
		quantity: 450,
	},
	{
		garage_id: 93,
		part_id: 288,
		quantity: 275,
	},
	{
		garage_id: 93,
		part_id: 289,
		quantity: 289,
	},
	{
		garage_id: 93,
		part_id: 290,
		quantity: 23,
	},
	{
		garage_id: 93,
		part_id: 291,
		quantity: 442,
	},
	{
		garage_id: 93,
		part_id: 292,
		quantity: 52,
	},
	{
		garage_id: 93,
		part_id: 293,
		quantity: 145,
	},
	{
		garage_id: 93,
		part_id: 294,
		quantity: 165,
	},
	{
		garage_id: 93,
		part_id: 295,
		quantity: 154,
	},
	{
		garage_id: 93,
		part_id: 296,
		quantity: 244,
	},
	{
		garage_id: 93,
		part_id: 297,
		quantity: 129,
	},
	{
		garage_id: 93,
		part_id: 298,
		quantity: 358,
	},
	{
		garage_id: 93,
		part_id: 299,
		quantity: 104,
	},
	{
		garage_id: 93,
		part_id: 300,
		quantity: 80,
	},
	{
		garage_id: 93,
		part_id: 301,
		quantity: 292,
	},
	{
		garage_id: 93,
		part_id: 302,
		quantity: 274,
	},
	{
		garage_id: 93,
		part_id: 303,
		quantity: 326,
	},
	{
		garage_id: 93,
		part_id: 304,
		quantity: 214,
	},
	{
		garage_id: 93,
		part_id: 305,
		quantity: 84,
	},
	{
		garage_id: 93,
		part_id: 306,
		quantity: 456,
	},
	{
		garage_id: 93,
		part_id: 307,
		quantity: 35,
	},
	{
		garage_id: 93,
		part_id: 308,
		quantity: 83,
	},
	{
		garage_id: 93,
		part_id: 309,
		quantity: 324,
	},
	{
		garage_id: 93,
		part_id: 310,
		quantity: 10,
	},
	{
		garage_id: 93,
		part_id: 311,
		quantity: 266,
	},
	{
		garage_id: 93,
		part_id: 312,
		quantity: 376,
	},
	{
		garage_id: 93,
		part_id: 313,
		quantity: 124,
	},
	{
		garage_id: 93,
		part_id: 314,
		quantity: 2,
	},
	{
		garage_id: 93,
		part_id: 315,
		quantity: 111,
	},
	{
		garage_id: 93,
		part_id: 316,
		quantity: 459,
	},
	{
		garage_id: 93,
		part_id: 317,
		quantity: 236,
	},
	{
		garage_id: 93,
		part_id: 318,
		quantity: 11,
	},
	{
		garage_id: 93,
		part_id: 319,
		quantity: 455,
	},
	{
		garage_id: 93,
		part_id: 320,
		quantity: 480,
	},
	{
		garage_id: 93,
		part_id: 321,
		quantity: 22,
	},
	{
		garage_id: 93,
		part_id: 322,
		quantity: 423,
	},
	{
		garage_id: 93,
		part_id: 323,
		quantity: 320,
	},
	{
		garage_id: 93,
		part_id: 324,
		quantity: 329,
	},
	{
		garage_id: 93,
		part_id: 325,
		quantity: 163,
	},
	{
		garage_id: 93,
		part_id: 326,
		quantity: 203,
	},
	{
		garage_id: 93,
		part_id: 327,
		quantity: 358,
	},
	{
		garage_id: 93,
		part_id: 328,
		quantity: 374,
	},
	{
		garage_id: 93,
		part_id: 329,
		quantity: 359,
	},
	{
		garage_id: 93,
		part_id: 330,
		quantity: 438,
	},
	{
		garage_id: 93,
		part_id: 331,
		quantity: 135,
	},
	{
		garage_id: 93,
		part_id: 332,
		quantity: 99,
	},
	{
		garage_id: 93,
		part_id: 333,
		quantity: 427,
	},
	{
		garage_id: 93,
		part_id: 334,
		quantity: 391,
	},
	{
		garage_id: 93,
		part_id: 335,
		quantity: 118,
	},
	{
		garage_id: 93,
		part_id: 336,
		quantity: 72,
	},
	{
		garage_id: 93,
		part_id: 337,
		quantity: 269,
	},
	{
		garage_id: 93,
		part_id: 338,
		quantity: 129,
	},
	{
		garage_id: 93,
		part_id: 339,
		quantity: 476,
	},
	{
		garage_id: 93,
		part_id: 340,
		quantity: 370,
	},
	{
		garage_id: 93,
		part_id: 341,
		quantity: 68,
	},
	{
		garage_id: 93,
		part_id: 342,
		quantity: 433,
	},
	{
		garage_id: 93,
		part_id: 343,
		quantity: 81,
	},
	{
		garage_id: 93,
		part_id: 344,
		quantity: 332,
	},
	{
		garage_id: 93,
		part_id: 345,
		quantity: 82,
	},
	{
		garage_id: 93,
		part_id: 346,
		quantity: 35,
	},
	{
		garage_id: 93,
		part_id: 347,
		quantity: 215,
	},
	{
		garage_id: 93,
		part_id: 348,
		quantity: 430,
	},
	{
		garage_id: 93,
		part_id: 349,
		quantity: 426,
	},
	{
		garage_id: 93,
		part_id: 350,
		quantity: 483,
	},
	{
		garage_id: 93,
		part_id: 351,
		quantity: 377,
	},
	{
		garage_id: 93,
		part_id: 352,
		quantity: 85,
	},
	{
		garage_id: 93,
		part_id: 353,
		quantity: 312,
	},
	{
		garage_id: 93,
		part_id: 354,
		quantity: 53,
	},
	{
		garage_id: 93,
		part_id: 355,
		quantity: 452,
	},
	{
		garage_id: 93,
		part_id: 356,
		quantity: 333,
	},
	{
		garage_id: 93,
		part_id: 357,
		quantity: 7,
	},
	{
		garage_id: 93,
		part_id: 358,
		quantity: 157,
	},
	{
		garage_id: 93,
		part_id: 359,
		quantity: 32,
	},
	{
		garage_id: 93,
		part_id: 360,
		quantity: 0,
	},
	{
		garage_id: 93,
		part_id: 361,
		quantity: 434,
	},
	{
		garage_id: 93,
		part_id: 362,
		quantity: 75,
	},
	{
		garage_id: 93,
		part_id: 363,
		quantity: 111,
	},
	{
		garage_id: 93,
		part_id: 364,
		quantity: 424,
	},
	{
		garage_id: 93,
		part_id: 365,
		quantity: 342,
	},
	{
		garage_id: 93,
		part_id: 366,
		quantity: 372,
	},
	{
		garage_id: 93,
		part_id: 367,
		quantity: 41,
	},
	{
		garage_id: 93,
		part_id: 368,
		quantity: 113,
	},
	{
		garage_id: 93,
		part_id: 369,
		quantity: 334,
	},
	{
		garage_id: 93,
		part_id: 370,
		quantity: 180,
	},
	{
		garage_id: 93,
		part_id: 371,
		quantity: 213,
	},
	{
		garage_id: 93,
		part_id: 372,
		quantity: 360,
	},
	{
		garage_id: 93,
		part_id: 373,
		quantity: 278,
	},
	{
		garage_id: 93,
		part_id: 374,
		quantity: 364,
	},
	{
		garage_id: 93,
		part_id: 375,
		quantity: 31,
	},
	{
		garage_id: 93,
		part_id: 376,
		quantity: 275,
	},
	{
		garage_id: 93,
		part_id: 377,
		quantity: 421,
	},
	{
		garage_id: 93,
		part_id: 378,
		quantity: 239,
	},
	{
		garage_id: 93,
		part_id: 379,
		quantity: 470,
	},
	{
		garage_id: 93,
		part_id: 380,
		quantity: 369,
	},
	{
		garage_id: 93,
		part_id: 381,
		quantity: 487,
	},
	{
		garage_id: 93,
		part_id: 382,
		quantity: 118,
	},
	{
		garage_id: 93,
		part_id: 383,
		quantity: 326,
	},
	{
		garage_id: 93,
		part_id: 384,
		quantity: 380,
	},
	{
		garage_id: 93,
		part_id: 385,
		quantity: 321,
	},
	{
		garage_id: 93,
		part_id: 386,
		quantity: 329,
	},
	{
		garage_id: 93,
		part_id: 387,
		quantity: 437,
	},
	{
		garage_id: 93,
		part_id: 388,
		quantity: 397,
	},
	{
		garage_id: 93,
		part_id: 389,
		quantity: 370,
	},
	{
		garage_id: 93,
		part_id: 390,
		quantity: 19,
	},
	{
		garage_id: 93,
		part_id: 391,
		quantity: 175,
	},
	{
		garage_id: 93,
		part_id: 392,
		quantity: 106,
	},
	{
		garage_id: 93,
		part_id: 393,
		quantity: 281,
	},
	{
		garage_id: 93,
		part_id: 394,
		quantity: 471,
	},
	{
		garage_id: 93,
		part_id: 395,
		quantity: 374,
	},
	{
		garage_id: 93,
		part_id: 396,
		quantity: 71,
	},
	{
		garage_id: 93,
		part_id: 397,
		quantity: 445,
	},
	{
		garage_id: 93,
		part_id: 398,
		quantity: 327,
	},
	{
		garage_id: 93,
		part_id: 399,
		quantity: 304,
	},
	{
		garage_id: 94,
		part_id: 0,
		quantity: 498,
	},
	{
		garage_id: 94,
		part_id: 1,
		quantity: 28,
	},
	{
		garage_id: 94,
		part_id: 2,
		quantity: 125,
	},
	{
		garage_id: 94,
		part_id: 3,
		quantity: 89,
	},
	{
		garage_id: 94,
		part_id: 4,
		quantity: 228,
	},
	{
		garage_id: 94,
		part_id: 5,
		quantity: 317,
	},
	{
		garage_id: 94,
		part_id: 6,
		quantity: 300,
	},
	{
		garage_id: 94,
		part_id: 7,
		quantity: 132,
	},
	{
		garage_id: 94,
		part_id: 8,
		quantity: 373,
	},
	{
		garage_id: 94,
		part_id: 9,
		quantity: 448,
	},
	{
		garage_id: 94,
		part_id: 10,
		quantity: 351,
	},
	{
		garage_id: 94,
		part_id: 11,
		quantity: 220,
	},
	{
		garage_id: 94,
		part_id: 12,
		quantity: 484,
	},
	{
		garage_id: 94,
		part_id: 13,
		quantity: 480,
	},
	{
		garage_id: 94,
		part_id: 14,
		quantity: 86,
	},
	{
		garage_id: 94,
		part_id: 15,
		quantity: 321,
	},
	{
		garage_id: 94,
		part_id: 16,
		quantity: 208,
	},
	{
		garage_id: 94,
		part_id: 17,
		quantity: 480,
	},
	{
		garage_id: 94,
		part_id: 18,
		quantity: 396,
	},
	{
		garage_id: 94,
		part_id: 19,
		quantity: 224,
	},
	{
		garage_id: 94,
		part_id: 20,
		quantity: 107,
	},
	{
		garage_id: 94,
		part_id: 21,
		quantity: 475,
	},
	{
		garage_id: 94,
		part_id: 22,
		quantity: 98,
	},
	{
		garage_id: 94,
		part_id: 23,
		quantity: 356,
	},
	{
		garage_id: 94,
		part_id: 24,
		quantity: 421,
	},
	{
		garage_id: 94,
		part_id: 25,
		quantity: 491,
	},
	{
		garage_id: 94,
		part_id: 26,
		quantity: 399,
	},
	{
		garage_id: 94,
		part_id: 27,
		quantity: 123,
	},
	{
		garage_id: 94,
		part_id: 28,
		quantity: 223,
	},
	{
		garage_id: 94,
		part_id: 29,
		quantity: 165,
	},
	{
		garage_id: 94,
		part_id: 30,
		quantity: 292,
	},
	{
		garage_id: 94,
		part_id: 31,
		quantity: 79,
	},
	{
		garage_id: 94,
		part_id: 32,
		quantity: 120,
	},
	{
		garage_id: 94,
		part_id: 33,
		quantity: 365,
	},
	{
		garage_id: 94,
		part_id: 34,
		quantity: 289,
	},
	{
		garage_id: 94,
		part_id: 35,
		quantity: 278,
	},
	{
		garage_id: 94,
		part_id: 36,
		quantity: 56,
	},
	{
		garage_id: 94,
		part_id: 37,
		quantity: 448,
	},
	{
		garage_id: 94,
		part_id: 38,
		quantity: 267,
	},
	{
		garage_id: 94,
		part_id: 39,
		quantity: 85,
	},
	{
		garage_id: 94,
		part_id: 40,
		quantity: 365,
	},
	{
		garage_id: 94,
		part_id: 41,
		quantity: 59,
	},
	{
		garage_id: 94,
		part_id: 42,
		quantity: 349,
	},
	{
		garage_id: 94,
		part_id: 43,
		quantity: 280,
	},
	{
		garage_id: 94,
		part_id: 44,
		quantity: 3,
	},
	{
		garage_id: 94,
		part_id: 45,
		quantity: 376,
	},
	{
		garage_id: 94,
		part_id: 46,
		quantity: 235,
	},
	{
		garage_id: 94,
		part_id: 47,
		quantity: 152,
	},
	{
		garage_id: 94,
		part_id: 48,
		quantity: 50,
	},
	{
		garage_id: 94,
		part_id: 49,
		quantity: 26,
	},
	{
		garage_id: 94,
		part_id: 50,
		quantity: 454,
	},
	{
		garage_id: 94,
		part_id: 51,
		quantity: 11,
	},
	{
		garage_id: 94,
		part_id: 52,
		quantity: 358,
	},
	{
		garage_id: 94,
		part_id: 53,
		quantity: 87,
	},
	{
		garage_id: 94,
		part_id: 54,
		quantity: 27,
	},
	{
		garage_id: 94,
		part_id: 55,
		quantity: 292,
	},
	{
		garage_id: 94,
		part_id: 56,
		quantity: 136,
	},
	{
		garage_id: 94,
		part_id: 57,
		quantity: 94,
	},
	{
		garage_id: 94,
		part_id: 58,
		quantity: 186,
	},
	{
		garage_id: 94,
		part_id: 59,
		quantity: 487,
	},
	{
		garage_id: 94,
		part_id: 60,
		quantity: 401,
	},
	{
		garage_id: 94,
		part_id: 61,
		quantity: 277,
	},
	{
		garage_id: 94,
		part_id: 62,
		quantity: 139,
	},
	{
		garage_id: 94,
		part_id: 63,
		quantity: 411,
	},
	{
		garage_id: 94,
		part_id: 64,
		quantity: 261,
	},
	{
		garage_id: 94,
		part_id: 65,
		quantity: 232,
	},
	{
		garage_id: 94,
		part_id: 66,
		quantity: 72,
	},
	{
		garage_id: 94,
		part_id: 67,
		quantity: 10,
	},
	{
		garage_id: 94,
		part_id: 68,
		quantity: 173,
	},
	{
		garage_id: 94,
		part_id: 69,
		quantity: 254,
	},
	{
		garage_id: 94,
		part_id: 70,
		quantity: 455,
	},
	{
		garage_id: 94,
		part_id: 71,
		quantity: 88,
	},
	{
		garage_id: 94,
		part_id: 72,
		quantity: 149,
	},
	{
		garage_id: 94,
		part_id: 73,
		quantity: 246,
	},
	{
		garage_id: 94,
		part_id: 74,
		quantity: 463,
	},
	{
		garage_id: 94,
		part_id: 75,
		quantity: 10,
	},
	{
		garage_id: 94,
		part_id: 76,
		quantity: 282,
	},
	{
		garage_id: 94,
		part_id: 77,
		quantity: 66,
	},
	{
		garage_id: 94,
		part_id: 78,
		quantity: 491,
	},
	{
		garage_id: 94,
		part_id: 79,
		quantity: 249,
	},
	{
		garage_id: 94,
		part_id: 80,
		quantity: 197,
	},
	{
		garage_id: 94,
		part_id: 81,
		quantity: 353,
	},
	{
		garage_id: 94,
		part_id: 82,
		quantity: 389,
	},
	{
		garage_id: 94,
		part_id: 83,
		quantity: 274,
	},
	{
		garage_id: 94,
		part_id: 84,
		quantity: 217,
	},
	{
		garage_id: 94,
		part_id: 85,
		quantity: 64,
	},
	{
		garage_id: 94,
		part_id: 86,
		quantity: 378,
	},
	{
		garage_id: 94,
		part_id: 87,
		quantity: 45,
	},
	{
		garage_id: 94,
		part_id: 88,
		quantity: 386,
	},
	{
		garage_id: 94,
		part_id: 89,
		quantity: 455,
	},
	{
		garage_id: 94,
		part_id: 90,
		quantity: 157,
	},
	{
		garage_id: 94,
		part_id: 91,
		quantity: 28,
	},
	{
		garage_id: 94,
		part_id: 92,
		quantity: 125,
	},
	{
		garage_id: 94,
		part_id: 93,
		quantity: 376,
	},
	{
		garage_id: 94,
		part_id: 94,
		quantity: 362,
	},
	{
		garage_id: 94,
		part_id: 95,
		quantity: 427,
	},
	{
		garage_id: 94,
		part_id: 96,
		quantity: 426,
	},
	{
		garage_id: 94,
		part_id: 97,
		quantity: 380,
	},
	{
		garage_id: 94,
		part_id: 98,
		quantity: 281,
	},
	{
		garage_id: 94,
		part_id: 99,
		quantity: 327,
	},
	{
		garage_id: 94,
		part_id: 100,
		quantity: 29,
	},
	{
		garage_id: 94,
		part_id: 101,
		quantity: 405,
	},
	{
		garage_id: 94,
		part_id: 102,
		quantity: 319,
	},
	{
		garage_id: 94,
		part_id: 103,
		quantity: 37,
	},
	{
		garage_id: 94,
		part_id: 104,
		quantity: 341,
	},
	{
		garage_id: 94,
		part_id: 105,
		quantity: 243,
	},
	{
		garage_id: 94,
		part_id: 106,
		quantity: 222,
	},
	{
		garage_id: 94,
		part_id: 107,
		quantity: 96,
	},
	{
		garage_id: 94,
		part_id: 108,
		quantity: 378,
	},
	{
		garage_id: 94,
		part_id: 109,
		quantity: 171,
	},
	{
		garage_id: 94,
		part_id: 110,
		quantity: 425,
	},
	{
		garage_id: 94,
		part_id: 111,
		quantity: 188,
	},
	{
		garage_id: 94,
		part_id: 112,
		quantity: 173,
	},
	{
		garage_id: 94,
		part_id: 113,
		quantity: 257,
	},
	{
		garage_id: 94,
		part_id: 114,
		quantity: 434,
	},
	{
		garage_id: 94,
		part_id: 115,
		quantity: 481,
	},
	{
		garage_id: 94,
		part_id: 116,
		quantity: 301,
	},
	{
		garage_id: 94,
		part_id: 117,
		quantity: 133,
	},
	{
		garage_id: 94,
		part_id: 118,
		quantity: 250,
	},
	{
		garage_id: 94,
		part_id: 119,
		quantity: 299,
	},
	{
		garage_id: 94,
		part_id: 120,
		quantity: 215,
	},
	{
		garage_id: 94,
		part_id: 121,
		quantity: 459,
	},
	{
		garage_id: 94,
		part_id: 122,
		quantity: 241,
	},
	{
		garage_id: 94,
		part_id: 123,
		quantity: 146,
	},
	{
		garage_id: 94,
		part_id: 124,
		quantity: 391,
	},
	{
		garage_id: 94,
		part_id: 125,
		quantity: 56,
	},
	{
		garage_id: 94,
		part_id: 126,
		quantity: 150,
	},
	{
		garage_id: 94,
		part_id: 127,
		quantity: 165,
	},
	{
		garage_id: 94,
		part_id: 128,
		quantity: 496,
	},
	{
		garage_id: 94,
		part_id: 129,
		quantity: 255,
	},
	{
		garage_id: 94,
		part_id: 130,
		quantity: 53,
	},
	{
		garage_id: 94,
		part_id: 131,
		quantity: 37,
	},
	{
		garage_id: 94,
		part_id: 132,
		quantity: 397,
	},
	{
		garage_id: 94,
		part_id: 133,
		quantity: 74,
	},
	{
		garage_id: 94,
		part_id: 134,
		quantity: 405,
	},
	{
		garage_id: 94,
		part_id: 135,
		quantity: 369,
	},
	{
		garage_id: 94,
		part_id: 136,
		quantity: 384,
	},
	{
		garage_id: 94,
		part_id: 137,
		quantity: 223,
	},
	{
		garage_id: 94,
		part_id: 138,
		quantity: 26,
	},
	{
		garage_id: 94,
		part_id: 139,
		quantity: 496,
	},
	{
		garage_id: 94,
		part_id: 140,
		quantity: 350,
	},
	{
		garage_id: 94,
		part_id: 141,
		quantity: 264,
	},
	{
		garage_id: 94,
		part_id: 142,
		quantity: 251,
	},
	{
		garage_id: 94,
		part_id: 143,
		quantity: 231,
	},
	{
		garage_id: 94,
		part_id: 144,
		quantity: 400,
	},
	{
		garage_id: 94,
		part_id: 145,
		quantity: 20,
	},
	{
		garage_id: 94,
		part_id: 146,
		quantity: 80,
	},
	{
		garage_id: 94,
		part_id: 147,
		quantity: 301,
	},
	{
		garage_id: 94,
		part_id: 148,
		quantity: 271,
	},
	{
		garage_id: 94,
		part_id: 149,
		quantity: 403,
	},
	{
		garage_id: 94,
		part_id: 150,
		quantity: 331,
	},
	{
		garage_id: 94,
		part_id: 151,
		quantity: 322,
	},
	{
		garage_id: 94,
		part_id: 152,
		quantity: 293,
	},
	{
		garage_id: 94,
		part_id: 153,
		quantity: 370,
	},
	{
		garage_id: 94,
		part_id: 154,
		quantity: 50,
	},
	{
		garage_id: 94,
		part_id: 155,
		quantity: 260,
	},
	{
		garage_id: 94,
		part_id: 156,
		quantity: 181,
	},
	{
		garage_id: 94,
		part_id: 157,
		quantity: 3,
	},
	{
		garage_id: 94,
		part_id: 158,
		quantity: 119,
	},
	{
		garage_id: 94,
		part_id: 159,
		quantity: 105,
	},
	{
		garage_id: 94,
		part_id: 160,
		quantity: 204,
	},
	{
		garage_id: 94,
		part_id: 161,
		quantity: 129,
	},
	{
		garage_id: 94,
		part_id: 162,
		quantity: 472,
	},
	{
		garage_id: 94,
		part_id: 163,
		quantity: 227,
	},
	{
		garage_id: 94,
		part_id: 164,
		quantity: 472,
	},
	{
		garage_id: 94,
		part_id: 165,
		quantity: 292,
	},
	{
		garage_id: 94,
		part_id: 166,
		quantity: 1,
	},
	{
		garage_id: 94,
		part_id: 167,
		quantity: 255,
	},
	{
		garage_id: 94,
		part_id: 168,
		quantity: 34,
	},
	{
		garage_id: 94,
		part_id: 169,
		quantity: 437,
	},
	{
		garage_id: 94,
		part_id: 170,
		quantity: 245,
	},
	{
		garage_id: 94,
		part_id: 171,
		quantity: 58,
	},
	{
		garage_id: 94,
		part_id: 172,
		quantity: 187,
	},
	{
		garage_id: 94,
		part_id: 173,
		quantity: 19,
	},
	{
		garage_id: 94,
		part_id: 174,
		quantity: 2,
	},
	{
		garage_id: 94,
		part_id: 175,
		quantity: 368,
	},
	{
		garage_id: 94,
		part_id: 176,
		quantity: 79,
	},
	{
		garage_id: 94,
		part_id: 177,
		quantity: 417,
	},
	{
		garage_id: 94,
		part_id: 178,
		quantity: 269,
	},
	{
		garage_id: 94,
		part_id: 179,
		quantity: 454,
	},
	{
		garage_id: 94,
		part_id: 180,
		quantity: 28,
	},
	{
		garage_id: 94,
		part_id: 181,
		quantity: 165,
	},
	{
		garage_id: 94,
		part_id: 182,
		quantity: 237,
	},
	{
		garage_id: 94,
		part_id: 183,
		quantity: 127,
	},
	{
		garage_id: 94,
		part_id: 184,
		quantity: 35,
	},
	{
		garage_id: 94,
		part_id: 185,
		quantity: 342,
	},
	{
		garage_id: 94,
		part_id: 186,
		quantity: 72,
	},
	{
		garage_id: 94,
		part_id: 187,
		quantity: 287,
	},
	{
		garage_id: 94,
		part_id: 188,
		quantity: 323,
	},
	{
		garage_id: 94,
		part_id: 189,
		quantity: 172,
	},
	{
		garage_id: 94,
		part_id: 190,
		quantity: 26,
	},
	{
		garage_id: 94,
		part_id: 191,
		quantity: 436,
	},
	{
		garage_id: 94,
		part_id: 192,
		quantity: 35,
	},
	{
		garage_id: 94,
		part_id: 193,
		quantity: 429,
	},
	{
		garage_id: 94,
		part_id: 194,
		quantity: 390,
	},
	{
		garage_id: 94,
		part_id: 195,
		quantity: 422,
	},
	{
		garage_id: 94,
		part_id: 196,
		quantity: 498,
	},
	{
		garage_id: 94,
		part_id: 197,
		quantity: 277,
	},
	{
		garage_id: 94,
		part_id: 198,
		quantity: 327,
	},
	{
		garage_id: 94,
		part_id: 199,
		quantity: 92,
	},
	{
		garage_id: 94,
		part_id: 200,
		quantity: 279,
	},
	{
		garage_id: 94,
		part_id: 201,
		quantity: 140,
	},
	{
		garage_id: 94,
		part_id: 202,
		quantity: 426,
	},
	{
		garage_id: 94,
		part_id: 203,
		quantity: 246,
	},
	{
		garage_id: 94,
		part_id: 204,
		quantity: 43,
	},
	{
		garage_id: 94,
		part_id: 205,
		quantity: 392,
	},
	{
		garage_id: 94,
		part_id: 206,
		quantity: 468,
	},
	{
		garage_id: 94,
		part_id: 207,
		quantity: 69,
	},
	{
		garage_id: 94,
		part_id: 208,
		quantity: 52,
	},
	{
		garage_id: 94,
		part_id: 209,
		quantity: 305,
	},
	{
		garage_id: 94,
		part_id: 210,
		quantity: 247,
	},
	{
		garage_id: 94,
		part_id: 211,
		quantity: 407,
	},
	{
		garage_id: 94,
		part_id: 212,
		quantity: 402,
	},
	{
		garage_id: 94,
		part_id: 213,
		quantity: 150,
	},
	{
		garage_id: 94,
		part_id: 214,
		quantity: 79,
	},
	{
		garage_id: 94,
		part_id: 215,
		quantity: 159,
	},
	{
		garage_id: 94,
		part_id: 216,
		quantity: 138,
	},
	{
		garage_id: 94,
		part_id: 217,
		quantity: 352,
	},
	{
		garage_id: 94,
		part_id: 218,
		quantity: 76,
	},
	{
		garage_id: 94,
		part_id: 219,
		quantity: 373,
	},
	{
		garage_id: 94,
		part_id: 220,
		quantity: 15,
	},
	{
		garage_id: 94,
		part_id: 221,
		quantity: 366,
	},
	{
		garage_id: 94,
		part_id: 222,
		quantity: 189,
	},
	{
		garage_id: 94,
		part_id: 223,
		quantity: 238,
	},
	{
		garage_id: 94,
		part_id: 224,
		quantity: 8,
	},
	{
		garage_id: 94,
		part_id: 225,
		quantity: 71,
	},
	{
		garage_id: 94,
		part_id: 226,
		quantity: 112,
	},
	{
		garage_id: 94,
		part_id: 227,
		quantity: 250,
	},
	{
		garage_id: 94,
		part_id: 228,
		quantity: 437,
	},
	{
		garage_id: 94,
		part_id: 229,
		quantity: 165,
	},
	{
		garage_id: 94,
		part_id: 230,
		quantity: 211,
	},
	{
		garage_id: 94,
		part_id: 231,
		quantity: 66,
	},
	{
		garage_id: 94,
		part_id: 232,
		quantity: 29,
	},
	{
		garage_id: 94,
		part_id: 233,
		quantity: 498,
	},
	{
		garage_id: 94,
		part_id: 234,
		quantity: 41,
	},
	{
		garage_id: 94,
		part_id: 235,
		quantity: 442,
	},
	{
		garage_id: 94,
		part_id: 236,
		quantity: 15,
	},
	{
		garage_id: 94,
		part_id: 237,
		quantity: 127,
	},
	{
		garage_id: 94,
		part_id: 238,
		quantity: 428,
	},
	{
		garage_id: 94,
		part_id: 239,
		quantity: 262,
	},
	{
		garage_id: 94,
		part_id: 240,
		quantity: 456,
	},
	{
		garage_id: 94,
		part_id: 241,
		quantity: 386,
	},
	{
		garage_id: 94,
		part_id: 242,
		quantity: 184,
	},
	{
		garage_id: 94,
		part_id: 243,
		quantity: 46,
	},
	{
		garage_id: 94,
		part_id: 244,
		quantity: 259,
	},
	{
		garage_id: 94,
		part_id: 245,
		quantity: 114,
	},
	{
		garage_id: 94,
		part_id: 246,
		quantity: 393,
	},
	{
		garage_id: 94,
		part_id: 247,
		quantity: 254,
	},
	{
		garage_id: 94,
		part_id: 248,
		quantity: 392,
	},
	{
		garage_id: 94,
		part_id: 249,
		quantity: 259,
	},
	{
		garage_id: 94,
		part_id: 250,
		quantity: 304,
	},
	{
		garage_id: 94,
		part_id: 251,
		quantity: 33,
	},
	{
		garage_id: 94,
		part_id: 252,
		quantity: 393,
	},
	{
		garage_id: 94,
		part_id: 253,
		quantity: 464,
	},
	{
		garage_id: 94,
		part_id: 254,
		quantity: 219,
	},
	{
		garage_id: 94,
		part_id: 255,
		quantity: 439,
	},
	{
		garage_id: 94,
		part_id: 256,
		quantity: 174,
	},
	{
		garage_id: 94,
		part_id: 257,
		quantity: 350,
	},
	{
		garage_id: 94,
		part_id: 258,
		quantity: 190,
	},
	{
		garage_id: 94,
		part_id: 259,
		quantity: 323,
	},
	{
		garage_id: 94,
		part_id: 260,
		quantity: 42,
	},
	{
		garage_id: 94,
		part_id: 261,
		quantity: 220,
	},
	{
		garage_id: 94,
		part_id: 262,
		quantity: 137,
	},
	{
		garage_id: 94,
		part_id: 263,
		quantity: 402,
	},
	{
		garage_id: 94,
		part_id: 264,
		quantity: 397,
	},
	{
		garage_id: 94,
		part_id: 265,
		quantity: 132,
	},
	{
		garage_id: 94,
		part_id: 266,
		quantity: 410,
	},
	{
		garage_id: 94,
		part_id: 267,
		quantity: 114,
	},
	{
		garage_id: 94,
		part_id: 268,
		quantity: 364,
	},
	{
		garage_id: 94,
		part_id: 269,
		quantity: 446,
	},
	{
		garage_id: 94,
		part_id: 270,
		quantity: 137,
	},
	{
		garage_id: 94,
		part_id: 271,
		quantity: 369,
	},
	{
		garage_id: 94,
		part_id: 272,
		quantity: 230,
	},
	{
		garage_id: 94,
		part_id: 273,
		quantity: 139,
	},
	{
		garage_id: 94,
		part_id: 274,
		quantity: 290,
	},
	{
		garage_id: 94,
		part_id: 275,
		quantity: 128,
	},
	{
		garage_id: 94,
		part_id: 276,
		quantity: 353,
	},
	{
		garage_id: 94,
		part_id: 277,
		quantity: 424,
	},
	{
		garage_id: 94,
		part_id: 278,
		quantity: 412,
	},
	{
		garage_id: 94,
		part_id: 279,
		quantity: 46,
	},
	{
		garage_id: 94,
		part_id: 280,
		quantity: 374,
	},
	{
		garage_id: 94,
		part_id: 281,
		quantity: 182,
	},
	{
		garage_id: 94,
		part_id: 282,
		quantity: 214,
	},
	{
		garage_id: 94,
		part_id: 283,
		quantity: 241,
	},
	{
		garage_id: 94,
		part_id: 284,
		quantity: 127,
	},
	{
		garage_id: 94,
		part_id: 285,
		quantity: 465,
	},
	{
		garage_id: 94,
		part_id: 286,
		quantity: 278,
	},
	{
		garage_id: 94,
		part_id: 287,
		quantity: 149,
	},
	{
		garage_id: 94,
		part_id: 288,
		quantity: 149,
	},
	{
		garage_id: 94,
		part_id: 289,
		quantity: 91,
	},
	{
		garage_id: 94,
		part_id: 290,
		quantity: 404,
	},
	{
		garage_id: 94,
		part_id: 291,
		quantity: 232,
	},
	{
		garage_id: 94,
		part_id: 292,
		quantity: 242,
	},
	{
		garage_id: 94,
		part_id: 293,
		quantity: 421,
	},
	{
		garage_id: 94,
		part_id: 294,
		quantity: 120,
	},
	{
		garage_id: 94,
		part_id: 295,
		quantity: 124,
	},
	{
		garage_id: 94,
		part_id: 296,
		quantity: 89,
	},
	{
		garage_id: 94,
		part_id: 297,
		quantity: 183,
	},
	{
		garage_id: 94,
		part_id: 298,
		quantity: 117,
	},
	{
		garage_id: 94,
		part_id: 299,
		quantity: 469,
	},
	{
		garage_id: 94,
		part_id: 300,
		quantity: 72,
	},
	{
		garage_id: 94,
		part_id: 301,
		quantity: 11,
	},
	{
		garage_id: 94,
		part_id: 302,
		quantity: 96,
	},
	{
		garage_id: 94,
		part_id: 303,
		quantity: 232,
	},
	{
		garage_id: 94,
		part_id: 304,
		quantity: 94,
	},
	{
		garage_id: 94,
		part_id: 305,
		quantity: 346,
	},
	{
		garage_id: 94,
		part_id: 306,
		quantity: 419,
	},
	{
		garage_id: 94,
		part_id: 307,
		quantity: 402,
	},
	{
		garage_id: 94,
		part_id: 308,
		quantity: 377,
	},
	{
		garage_id: 94,
		part_id: 309,
		quantity: 494,
	},
	{
		garage_id: 94,
		part_id: 310,
		quantity: 138,
	},
	{
		garage_id: 94,
		part_id: 311,
		quantity: 281,
	},
	{
		garage_id: 94,
		part_id: 312,
		quantity: 264,
	},
	{
		garage_id: 94,
		part_id: 313,
		quantity: 154,
	},
	{
		garage_id: 94,
		part_id: 314,
		quantity: 63,
	},
	{
		garage_id: 94,
		part_id: 315,
		quantity: 139,
	},
	{
		garage_id: 94,
		part_id: 316,
		quantity: 217,
	},
	{
		garage_id: 94,
		part_id: 317,
		quantity: 315,
	},
	{
		garage_id: 94,
		part_id: 318,
		quantity: 484,
	},
	{
		garage_id: 94,
		part_id: 319,
		quantity: 133,
	},
	{
		garage_id: 94,
		part_id: 320,
		quantity: 200,
	},
	{
		garage_id: 94,
		part_id: 321,
		quantity: 313,
	},
	{
		garage_id: 94,
		part_id: 322,
		quantity: 71,
	},
	{
		garage_id: 94,
		part_id: 323,
		quantity: 193,
	},
	{
		garage_id: 94,
		part_id: 324,
		quantity: 353,
	},
	{
		garage_id: 94,
		part_id: 325,
		quantity: 128,
	},
	{
		garage_id: 94,
		part_id: 326,
		quantity: 352,
	},
	{
		garage_id: 94,
		part_id: 327,
		quantity: 65,
	},
	{
		garage_id: 94,
		part_id: 328,
		quantity: 204,
	},
	{
		garage_id: 94,
		part_id: 329,
		quantity: 321,
	},
	{
		garage_id: 94,
		part_id: 330,
		quantity: 484,
	},
	{
		garage_id: 94,
		part_id: 331,
		quantity: 411,
	},
	{
		garage_id: 94,
		part_id: 332,
		quantity: 422,
	},
	{
		garage_id: 94,
		part_id: 333,
		quantity: 481,
	},
	{
		garage_id: 94,
		part_id: 334,
		quantity: 64,
	},
	{
		garage_id: 94,
		part_id: 335,
		quantity: 425,
	},
	{
		garage_id: 94,
		part_id: 336,
		quantity: 442,
	},
	{
		garage_id: 94,
		part_id: 337,
		quantity: 282,
	},
	{
		garage_id: 94,
		part_id: 338,
		quantity: 492,
	},
	{
		garage_id: 94,
		part_id: 339,
		quantity: 451,
	},
	{
		garage_id: 94,
		part_id: 340,
		quantity: 302,
	},
	{
		garage_id: 94,
		part_id: 341,
		quantity: 331,
	},
	{
		garage_id: 94,
		part_id: 342,
		quantity: 399,
	},
	{
		garage_id: 94,
		part_id: 343,
		quantity: 130,
	},
	{
		garage_id: 94,
		part_id: 344,
		quantity: 114,
	},
	{
		garage_id: 94,
		part_id: 345,
		quantity: 257,
	},
	{
		garage_id: 94,
		part_id: 346,
		quantity: 361,
	},
	{
		garage_id: 94,
		part_id: 347,
		quantity: 68,
	},
	{
		garage_id: 94,
		part_id: 348,
		quantity: 29,
	},
	{
		garage_id: 94,
		part_id: 349,
		quantity: 114,
	},
	{
		garage_id: 94,
		part_id: 350,
		quantity: 492,
	},
	{
		garage_id: 94,
		part_id: 351,
		quantity: 460,
	},
	{
		garage_id: 94,
		part_id: 352,
		quantity: 456,
	},
	{
		garage_id: 94,
		part_id: 353,
		quantity: 77,
	},
	{
		garage_id: 94,
		part_id: 354,
		quantity: 361,
	},
	{
		garage_id: 94,
		part_id: 355,
		quantity: 275,
	},
	{
		garage_id: 94,
		part_id: 356,
		quantity: 68,
	},
	{
		garage_id: 94,
		part_id: 357,
		quantity: 477,
	},
	{
		garage_id: 94,
		part_id: 358,
		quantity: 464,
	},
	{
		garage_id: 94,
		part_id: 359,
		quantity: 129,
	},
	{
		garage_id: 94,
		part_id: 360,
		quantity: 98,
	},
	{
		garage_id: 94,
		part_id: 361,
		quantity: 149,
	},
	{
		garage_id: 94,
		part_id: 362,
		quantity: 121,
	},
	{
		garage_id: 94,
		part_id: 363,
		quantity: 173,
	},
	{
		garage_id: 94,
		part_id: 364,
		quantity: 261,
	},
	{
		garage_id: 94,
		part_id: 365,
		quantity: 50,
	},
	{
		garage_id: 94,
		part_id: 366,
		quantity: 218,
	},
	{
		garage_id: 94,
		part_id: 367,
		quantity: 167,
	},
	{
		garage_id: 94,
		part_id: 368,
		quantity: 93,
	},
	{
		garage_id: 94,
		part_id: 369,
		quantity: 64,
	},
	{
		garage_id: 94,
		part_id: 370,
		quantity: 267,
	},
	{
		garage_id: 94,
		part_id: 371,
		quantity: 308,
	},
	{
		garage_id: 94,
		part_id: 372,
		quantity: 149,
	},
	{
		garage_id: 94,
		part_id: 373,
		quantity: 293,
	},
	{
		garage_id: 94,
		part_id: 374,
		quantity: 390,
	},
	{
		garage_id: 94,
		part_id: 375,
		quantity: 77,
	},
	{
		garage_id: 94,
		part_id: 376,
		quantity: 228,
	},
	{
		garage_id: 94,
		part_id: 377,
		quantity: 90,
	},
	{
		garage_id: 94,
		part_id: 378,
		quantity: 127,
	},
	{
		garage_id: 94,
		part_id: 379,
		quantity: 315,
	},
	{
		garage_id: 94,
		part_id: 380,
		quantity: 291,
	},
	{
		garage_id: 94,
		part_id: 381,
		quantity: 200,
	},
	{
		garage_id: 94,
		part_id: 382,
		quantity: 242,
	},
	{
		garage_id: 94,
		part_id: 383,
		quantity: 398,
	},
	{
		garage_id: 94,
		part_id: 384,
		quantity: 68,
	},
	{
		garage_id: 94,
		part_id: 385,
		quantity: 131,
	},
	{
		garage_id: 94,
		part_id: 386,
		quantity: 430,
	},
	{
		garage_id: 94,
		part_id: 387,
		quantity: 133,
	},
	{
		garage_id: 94,
		part_id: 388,
		quantity: 457,
	},
	{
		garage_id: 94,
		part_id: 389,
		quantity: 204,
	},
	{
		garage_id: 94,
		part_id: 390,
		quantity: 5,
	},
	{
		garage_id: 94,
		part_id: 391,
		quantity: 19,
	},
	{
		garage_id: 94,
		part_id: 392,
		quantity: 147,
	},
	{
		garage_id: 94,
		part_id: 393,
		quantity: 23,
	},
	{
		garage_id: 94,
		part_id: 394,
		quantity: 157,
	},
	{
		garage_id: 94,
		part_id: 395,
		quantity: 178,
	},
	{
		garage_id: 94,
		part_id: 396,
		quantity: 465,
	},
	{
		garage_id: 94,
		part_id: 397,
		quantity: 259,
	},
	{
		garage_id: 94,
		part_id: 398,
		quantity: 318,
	},
	{
		garage_id: 94,
		part_id: 399,
		quantity: 400,
	},
	{
		garage_id: 95,
		part_id: 0,
		quantity: 305,
	},
	{
		garage_id: 95,
		part_id: 1,
		quantity: 299,
	},
	{
		garage_id: 95,
		part_id: 2,
		quantity: 212,
	},
	{
		garage_id: 95,
		part_id: 3,
		quantity: 159,
	},
	{
		garage_id: 95,
		part_id: 4,
		quantity: 232,
	},
	{
		garage_id: 95,
		part_id: 5,
		quantity: 5,
	},
	{
		garage_id: 95,
		part_id: 6,
		quantity: 309,
	},
	{
		garage_id: 95,
		part_id: 7,
		quantity: 280,
	},
	{
		garage_id: 95,
		part_id: 8,
		quantity: 44,
	},
	{
		garage_id: 95,
		part_id: 9,
		quantity: 431,
	},
	{
		garage_id: 95,
		part_id: 10,
		quantity: 433,
	},
	{
		garage_id: 95,
		part_id: 11,
		quantity: 243,
	},
	{
		garage_id: 95,
		part_id: 12,
		quantity: 16,
	},
	{
		garage_id: 95,
		part_id: 13,
		quantity: 120,
	},
	{
		garage_id: 95,
		part_id: 14,
		quantity: 224,
	},
	{
		garage_id: 95,
		part_id: 15,
		quantity: 141,
	},
	{
		garage_id: 95,
		part_id: 16,
		quantity: 314,
	},
	{
		garage_id: 95,
		part_id: 17,
		quantity: 4,
	},
	{
		garage_id: 95,
		part_id: 18,
		quantity: 59,
	},
	{
		garage_id: 95,
		part_id: 19,
		quantity: 360,
	},
	{
		garage_id: 95,
		part_id: 20,
		quantity: 167,
	},
	{
		garage_id: 95,
		part_id: 21,
		quantity: 416,
	},
	{
		garage_id: 95,
		part_id: 22,
		quantity: 464,
	},
	{
		garage_id: 95,
		part_id: 23,
		quantity: 476,
	},
	{
		garage_id: 95,
		part_id: 24,
		quantity: 17,
	},
	{
		garage_id: 95,
		part_id: 25,
		quantity: 402,
	},
	{
		garage_id: 95,
		part_id: 26,
		quantity: 340,
	},
	{
		garage_id: 95,
		part_id: 27,
		quantity: 452,
	},
	{
		garage_id: 95,
		part_id: 28,
		quantity: 354,
	},
	{
		garage_id: 95,
		part_id: 29,
		quantity: 217,
	},
	{
		garage_id: 95,
		part_id: 30,
		quantity: 412,
	},
	{
		garage_id: 95,
		part_id: 31,
		quantity: 354,
	},
	{
		garage_id: 95,
		part_id: 32,
		quantity: 461,
	},
	{
		garage_id: 95,
		part_id: 33,
		quantity: 71,
	},
	{
		garage_id: 95,
		part_id: 34,
		quantity: 46,
	},
	{
		garage_id: 95,
		part_id: 35,
		quantity: 446,
	},
	{
		garage_id: 95,
		part_id: 36,
		quantity: 412,
	},
	{
		garage_id: 95,
		part_id: 37,
		quantity: 196,
	},
	{
		garage_id: 95,
		part_id: 38,
		quantity: 390,
	},
	{
		garage_id: 95,
		part_id: 39,
		quantity: 61,
	},
	{
		garage_id: 95,
		part_id: 40,
		quantity: 37,
	},
	{
		garage_id: 95,
		part_id: 41,
		quantity: 228,
	},
	{
		garage_id: 95,
		part_id: 42,
		quantity: 186,
	},
	{
		garage_id: 95,
		part_id: 43,
		quantity: 412,
	},
	{
		garage_id: 95,
		part_id: 44,
		quantity: 459,
	},
	{
		garage_id: 95,
		part_id: 45,
		quantity: 37,
	},
	{
		garage_id: 95,
		part_id: 46,
		quantity: 402,
	},
	{
		garage_id: 95,
		part_id: 47,
		quantity: 193,
	},
	{
		garage_id: 95,
		part_id: 48,
		quantity: 355,
	},
	{
		garage_id: 95,
		part_id: 49,
		quantity: 114,
	},
	{
		garage_id: 95,
		part_id: 50,
		quantity: 114,
	},
	{
		garage_id: 95,
		part_id: 51,
		quantity: 396,
	},
	{
		garage_id: 95,
		part_id: 52,
		quantity: 4,
	},
	{
		garage_id: 95,
		part_id: 53,
		quantity: 243,
	},
	{
		garage_id: 95,
		part_id: 54,
		quantity: 111,
	},
	{
		garage_id: 95,
		part_id: 55,
		quantity: 148,
	},
	{
		garage_id: 95,
		part_id: 56,
		quantity: 454,
	},
	{
		garage_id: 95,
		part_id: 57,
		quantity: 498,
	},
	{
		garage_id: 95,
		part_id: 58,
		quantity: 73,
	},
	{
		garage_id: 95,
		part_id: 59,
		quantity: 66,
	},
	{
		garage_id: 95,
		part_id: 60,
		quantity: 177,
	},
	{
		garage_id: 95,
		part_id: 61,
		quantity: 399,
	},
	{
		garage_id: 95,
		part_id: 62,
		quantity: 478,
	},
	{
		garage_id: 95,
		part_id: 63,
		quantity: 120,
	},
	{
		garage_id: 95,
		part_id: 64,
		quantity: 222,
	},
	{
		garage_id: 95,
		part_id: 65,
		quantity: 130,
	},
	{
		garage_id: 95,
		part_id: 66,
		quantity: 71,
	},
	{
		garage_id: 95,
		part_id: 67,
		quantity: 370,
	},
	{
		garage_id: 95,
		part_id: 68,
		quantity: 15,
	},
	{
		garage_id: 95,
		part_id: 69,
		quantity: 403,
	},
	{
		garage_id: 95,
		part_id: 70,
		quantity: 234,
	},
	{
		garage_id: 95,
		part_id: 71,
		quantity: 100,
	},
	{
		garage_id: 95,
		part_id: 72,
		quantity: 220,
	},
	{
		garage_id: 95,
		part_id: 73,
		quantity: 429,
	},
	{
		garage_id: 95,
		part_id: 74,
		quantity: 123,
	},
	{
		garage_id: 95,
		part_id: 75,
		quantity: 262,
	},
	{
		garage_id: 95,
		part_id: 76,
		quantity: 499,
	},
	{
		garage_id: 95,
		part_id: 77,
		quantity: 165,
	},
	{
		garage_id: 95,
		part_id: 78,
		quantity: 216,
	},
	{
		garage_id: 95,
		part_id: 79,
		quantity: 215,
	},
	{
		garage_id: 95,
		part_id: 80,
		quantity: 300,
	},
	{
		garage_id: 95,
		part_id: 81,
		quantity: 244,
	},
	{
		garage_id: 95,
		part_id: 82,
		quantity: 346,
	},
	{
		garage_id: 95,
		part_id: 83,
		quantity: 256,
	},
	{
		garage_id: 95,
		part_id: 84,
		quantity: 138,
	},
	{
		garage_id: 95,
		part_id: 85,
		quantity: 373,
	},
	{
		garage_id: 95,
		part_id: 86,
		quantity: 256,
	},
	{
		garage_id: 95,
		part_id: 87,
		quantity: 105,
	},
	{
		garage_id: 95,
		part_id: 88,
		quantity: 211,
	},
	{
		garage_id: 95,
		part_id: 89,
		quantity: 125,
	},
	{
		garage_id: 95,
		part_id: 90,
		quantity: 3,
	},
	{
		garage_id: 95,
		part_id: 91,
		quantity: 470,
	},
	{
		garage_id: 95,
		part_id: 92,
		quantity: 22,
	},
	{
		garage_id: 95,
		part_id: 93,
		quantity: 11,
	},
	{
		garage_id: 95,
		part_id: 94,
		quantity: 174,
	},
	{
		garage_id: 95,
		part_id: 95,
		quantity: 302,
	},
	{
		garage_id: 95,
		part_id: 96,
		quantity: 42,
	},
	{
		garage_id: 95,
		part_id: 97,
		quantity: 440,
	},
	{
		garage_id: 95,
		part_id: 98,
		quantity: 261,
	},
	{
		garage_id: 95,
		part_id: 99,
		quantity: 202,
	},
	{
		garage_id: 95,
		part_id: 100,
		quantity: 261,
	},
	{
		garage_id: 95,
		part_id: 101,
		quantity: 350,
	},
	{
		garage_id: 95,
		part_id: 102,
		quantity: 14,
	},
	{
		garage_id: 95,
		part_id: 103,
		quantity: 161,
	},
	{
		garage_id: 95,
		part_id: 104,
		quantity: 315,
	},
	{
		garage_id: 95,
		part_id: 105,
		quantity: 335,
	},
	{
		garage_id: 95,
		part_id: 106,
		quantity: 211,
	},
	{
		garage_id: 95,
		part_id: 107,
		quantity: 309,
	},
	{
		garage_id: 95,
		part_id: 108,
		quantity: 200,
	},
	{
		garage_id: 95,
		part_id: 109,
		quantity: 289,
	},
	{
		garage_id: 95,
		part_id: 110,
		quantity: 213,
	},
	{
		garage_id: 95,
		part_id: 111,
		quantity: 72,
	},
	{
		garage_id: 95,
		part_id: 112,
		quantity: 486,
	},
	{
		garage_id: 95,
		part_id: 113,
		quantity: 367,
	},
	{
		garage_id: 95,
		part_id: 114,
		quantity: 41,
	},
	{
		garage_id: 95,
		part_id: 115,
		quantity: 46,
	},
	{
		garage_id: 95,
		part_id: 116,
		quantity: 225,
	},
	{
		garage_id: 95,
		part_id: 117,
		quantity: 398,
	},
	{
		garage_id: 95,
		part_id: 118,
		quantity: 75,
	},
	{
		garage_id: 95,
		part_id: 119,
		quantity: 349,
	},
	{
		garage_id: 95,
		part_id: 120,
		quantity: 352,
	},
	{
		garage_id: 95,
		part_id: 121,
		quantity: 165,
	},
	{
		garage_id: 95,
		part_id: 122,
		quantity: 427,
	},
	{
		garage_id: 95,
		part_id: 123,
		quantity: 49,
	},
	{
		garage_id: 95,
		part_id: 124,
		quantity: 162,
	},
	{
		garage_id: 95,
		part_id: 125,
		quantity: 241,
	},
	{
		garage_id: 95,
		part_id: 126,
		quantity: 217,
	},
	{
		garage_id: 95,
		part_id: 127,
		quantity: 155,
	},
	{
		garage_id: 95,
		part_id: 128,
		quantity: 119,
	},
	{
		garage_id: 95,
		part_id: 129,
		quantity: 164,
	},
	{
		garage_id: 95,
		part_id: 130,
		quantity: 176,
	},
	{
		garage_id: 95,
		part_id: 131,
		quantity: 147,
	},
	{
		garage_id: 95,
		part_id: 132,
		quantity: 447,
	},
	{
		garage_id: 95,
		part_id: 133,
		quantity: 229,
	},
	{
		garage_id: 95,
		part_id: 134,
		quantity: 201,
	},
	{
		garage_id: 95,
		part_id: 135,
		quantity: 478,
	},
	{
		garage_id: 95,
		part_id: 136,
		quantity: 373,
	},
	{
		garage_id: 95,
		part_id: 137,
		quantity: 131,
	},
	{
		garage_id: 95,
		part_id: 138,
		quantity: 412,
	},
	{
		garage_id: 95,
		part_id: 139,
		quantity: 489,
	},
	{
		garage_id: 95,
		part_id: 140,
		quantity: 14,
	},
	{
		garage_id: 95,
		part_id: 141,
		quantity: 331,
	},
	{
		garage_id: 95,
		part_id: 142,
		quantity: 38,
	},
	{
		garage_id: 95,
		part_id: 143,
		quantity: 326,
	},
	{
		garage_id: 95,
		part_id: 144,
		quantity: 35,
	},
	{
		garage_id: 95,
		part_id: 145,
		quantity: 223,
	},
	{
		garage_id: 95,
		part_id: 146,
		quantity: 108,
	},
	{
		garage_id: 95,
		part_id: 147,
		quantity: 404,
	},
	{
		garage_id: 95,
		part_id: 148,
		quantity: 23,
	},
	{
		garage_id: 95,
		part_id: 149,
		quantity: 274,
	},
	{
		garage_id: 95,
		part_id: 150,
		quantity: 78,
	},
	{
		garage_id: 95,
		part_id: 151,
		quantity: 339,
	},
	{
		garage_id: 95,
		part_id: 152,
		quantity: 101,
	},
	{
		garage_id: 95,
		part_id: 153,
		quantity: 130,
	},
	{
		garage_id: 95,
		part_id: 154,
		quantity: 190,
	},
	{
		garage_id: 95,
		part_id: 155,
		quantity: 367,
	},
	{
		garage_id: 95,
		part_id: 156,
		quantity: 473,
	},
	{
		garage_id: 95,
		part_id: 157,
		quantity: 33,
	},
	{
		garage_id: 95,
		part_id: 158,
		quantity: 350,
	},
	{
		garage_id: 95,
		part_id: 159,
		quantity: 120,
	},
	{
		garage_id: 95,
		part_id: 160,
		quantity: 445,
	},
	{
		garage_id: 95,
		part_id: 161,
		quantity: 159,
	},
	{
		garage_id: 95,
		part_id: 162,
		quantity: 424,
	},
	{
		garage_id: 95,
		part_id: 163,
		quantity: 120,
	},
	{
		garage_id: 95,
		part_id: 164,
		quantity: 58,
	},
	{
		garage_id: 95,
		part_id: 165,
		quantity: 42,
	},
	{
		garage_id: 95,
		part_id: 166,
		quantity: 179,
	},
	{
		garage_id: 95,
		part_id: 167,
		quantity: 0,
	},
	{
		garage_id: 95,
		part_id: 168,
		quantity: 369,
	},
	{
		garage_id: 95,
		part_id: 169,
		quantity: 13,
	},
	{
		garage_id: 95,
		part_id: 170,
		quantity: 53,
	},
	{
		garage_id: 95,
		part_id: 171,
		quantity: 258,
	},
	{
		garage_id: 95,
		part_id: 172,
		quantity: 453,
	},
	{
		garage_id: 95,
		part_id: 173,
		quantity: 53,
	},
	{
		garage_id: 95,
		part_id: 174,
		quantity: 279,
	},
	{
		garage_id: 95,
		part_id: 175,
		quantity: 446,
	},
	{
		garage_id: 95,
		part_id: 176,
		quantity: 4,
	},
	{
		garage_id: 95,
		part_id: 177,
		quantity: 401,
	},
	{
		garage_id: 95,
		part_id: 178,
		quantity: 398,
	},
	{
		garage_id: 95,
		part_id: 179,
		quantity: 334,
	},
	{
		garage_id: 95,
		part_id: 180,
		quantity: 325,
	},
	{
		garage_id: 95,
		part_id: 181,
		quantity: 434,
	},
	{
		garage_id: 95,
		part_id: 182,
		quantity: 191,
	},
	{
		garage_id: 95,
		part_id: 183,
		quantity: 319,
	},
	{
		garage_id: 95,
		part_id: 184,
		quantity: 56,
	},
	{
		garage_id: 95,
		part_id: 185,
		quantity: 27,
	},
	{
		garage_id: 95,
		part_id: 186,
		quantity: 397,
	},
	{
		garage_id: 95,
		part_id: 187,
		quantity: 407,
	},
	{
		garage_id: 95,
		part_id: 188,
		quantity: 248,
	},
	{
		garage_id: 95,
		part_id: 189,
		quantity: 2,
	},
	{
		garage_id: 95,
		part_id: 190,
		quantity: 79,
	},
	{
		garage_id: 95,
		part_id: 191,
		quantity: 126,
	},
	{
		garage_id: 95,
		part_id: 192,
		quantity: 443,
	},
	{
		garage_id: 95,
		part_id: 193,
		quantity: 242,
	},
	{
		garage_id: 95,
		part_id: 194,
		quantity: 270,
	},
	{
		garage_id: 95,
		part_id: 195,
		quantity: 160,
	},
	{
		garage_id: 95,
		part_id: 196,
		quantity: 397,
	},
	{
		garage_id: 95,
		part_id: 197,
		quantity: 444,
	},
	{
		garage_id: 95,
		part_id: 198,
		quantity: 384,
	},
	{
		garage_id: 95,
		part_id: 199,
		quantity: 134,
	},
	{
		garage_id: 95,
		part_id: 200,
		quantity: 475,
	},
	{
		garage_id: 95,
		part_id: 201,
		quantity: 154,
	},
	{
		garage_id: 95,
		part_id: 202,
		quantity: 98,
	},
	{
		garage_id: 95,
		part_id: 203,
		quantity: 272,
	},
	{
		garage_id: 95,
		part_id: 204,
		quantity: 224,
	},
	{
		garage_id: 95,
		part_id: 205,
		quantity: 333,
	},
	{
		garage_id: 95,
		part_id: 206,
		quantity: 175,
	},
	{
		garage_id: 95,
		part_id: 207,
		quantity: 419,
	},
	{
		garage_id: 95,
		part_id: 208,
		quantity: 83,
	},
	{
		garage_id: 95,
		part_id: 209,
		quantity: 217,
	},
	{
		garage_id: 95,
		part_id: 210,
		quantity: 48,
	},
	{
		garage_id: 95,
		part_id: 211,
		quantity: 55,
	},
	{
		garage_id: 95,
		part_id: 212,
		quantity: 444,
	},
	{
		garage_id: 95,
		part_id: 213,
		quantity: 414,
	},
	{
		garage_id: 95,
		part_id: 214,
		quantity: 340,
	},
	{
		garage_id: 95,
		part_id: 215,
		quantity: 337,
	},
	{
		garage_id: 95,
		part_id: 216,
		quantity: 301,
	},
	{
		garage_id: 95,
		part_id: 217,
		quantity: 416,
	},
	{
		garage_id: 95,
		part_id: 218,
		quantity: 441,
	},
	{
		garage_id: 95,
		part_id: 219,
		quantity: 347,
	},
	{
		garage_id: 95,
		part_id: 220,
		quantity: 39,
	},
	{
		garage_id: 95,
		part_id: 221,
		quantity: 0,
	},
	{
		garage_id: 95,
		part_id: 222,
		quantity: 118,
	},
	{
		garage_id: 95,
		part_id: 223,
		quantity: 456,
	},
	{
		garage_id: 95,
		part_id: 224,
		quantity: 284,
	},
	{
		garage_id: 95,
		part_id: 225,
		quantity: 268,
	},
	{
		garage_id: 95,
		part_id: 226,
		quantity: 310,
	},
	{
		garage_id: 95,
		part_id: 227,
		quantity: 10,
	},
	{
		garage_id: 95,
		part_id: 228,
		quantity: 24,
	},
	{
		garage_id: 95,
		part_id: 229,
		quantity: 327,
	},
	{
		garage_id: 95,
		part_id: 230,
		quantity: 78,
	},
	{
		garage_id: 95,
		part_id: 231,
		quantity: 396,
	},
	{
		garage_id: 95,
		part_id: 232,
		quantity: 49,
	},
	{
		garage_id: 95,
		part_id: 233,
		quantity: 308,
	},
	{
		garage_id: 95,
		part_id: 234,
		quantity: 248,
	},
	{
		garage_id: 95,
		part_id: 235,
		quantity: 498,
	},
	{
		garage_id: 95,
		part_id: 236,
		quantity: 432,
	},
	{
		garage_id: 95,
		part_id: 237,
		quantity: 250,
	},
	{
		garage_id: 95,
		part_id: 238,
		quantity: 161,
	},
	{
		garage_id: 95,
		part_id: 239,
		quantity: 399,
	},
	{
		garage_id: 95,
		part_id: 240,
		quantity: 309,
	},
	{
		garage_id: 95,
		part_id: 241,
		quantity: 288,
	},
	{
		garage_id: 95,
		part_id: 242,
		quantity: 267,
	},
	{
		garage_id: 95,
		part_id: 243,
		quantity: 430,
	},
	{
		garage_id: 95,
		part_id: 244,
		quantity: 95,
	},
	{
		garage_id: 95,
		part_id: 245,
		quantity: 358,
	},
	{
		garage_id: 95,
		part_id: 246,
		quantity: 126,
	},
	{
		garage_id: 95,
		part_id: 247,
		quantity: 335,
	},
	{
		garage_id: 95,
		part_id: 248,
		quantity: 25,
	},
	{
		garage_id: 95,
		part_id: 249,
		quantity: 127,
	},
	{
		garage_id: 95,
		part_id: 250,
		quantity: 487,
	},
	{
		garage_id: 95,
		part_id: 251,
		quantity: 20,
	},
	{
		garage_id: 95,
		part_id: 252,
		quantity: 382,
	},
	{
		garage_id: 95,
		part_id: 253,
		quantity: 58,
	},
	{
		garage_id: 95,
		part_id: 254,
		quantity: 267,
	},
	{
		garage_id: 95,
		part_id: 255,
		quantity: 469,
	},
	{
		garage_id: 95,
		part_id: 256,
		quantity: 417,
	},
	{
		garage_id: 95,
		part_id: 257,
		quantity: 75,
	},
	{
		garage_id: 95,
		part_id: 258,
		quantity: 175,
	},
	{
		garage_id: 95,
		part_id: 259,
		quantity: 91,
	},
	{
		garage_id: 95,
		part_id: 260,
		quantity: 480,
	},
	{
		garage_id: 95,
		part_id: 261,
		quantity: 315,
	},
	{
		garage_id: 95,
		part_id: 262,
		quantity: 193,
	},
	{
		garage_id: 95,
		part_id: 263,
		quantity: 132,
	},
	{
		garage_id: 95,
		part_id: 264,
		quantity: 146,
	},
	{
		garage_id: 95,
		part_id: 265,
		quantity: 213,
	},
	{
		garage_id: 95,
		part_id: 266,
		quantity: 136,
	},
	{
		garage_id: 95,
		part_id: 267,
		quantity: 57,
	},
	{
		garage_id: 95,
		part_id: 268,
		quantity: 257,
	},
	{
		garage_id: 95,
		part_id: 269,
		quantity: 183,
	},
	{
		garage_id: 95,
		part_id: 270,
		quantity: 230,
	},
	{
		garage_id: 95,
		part_id: 271,
		quantity: 484,
	},
	{
		garage_id: 95,
		part_id: 272,
		quantity: 251,
	},
	{
		garage_id: 95,
		part_id: 273,
		quantity: 490,
	},
	{
		garage_id: 95,
		part_id: 274,
		quantity: 287,
	},
	{
		garage_id: 95,
		part_id: 275,
		quantity: 114,
	},
	{
		garage_id: 95,
		part_id: 276,
		quantity: 309,
	},
	{
		garage_id: 95,
		part_id: 277,
		quantity: 492,
	},
	{
		garage_id: 95,
		part_id: 278,
		quantity: 92,
	},
	{
		garage_id: 95,
		part_id: 279,
		quantity: 411,
	},
	{
		garage_id: 95,
		part_id: 280,
		quantity: 13,
	},
	{
		garage_id: 95,
		part_id: 281,
		quantity: 499,
	},
	{
		garage_id: 95,
		part_id: 282,
		quantity: 150,
	},
	{
		garage_id: 95,
		part_id: 283,
		quantity: 366,
	},
	{
		garage_id: 95,
		part_id: 284,
		quantity: 425,
	},
	{
		garage_id: 95,
		part_id: 285,
		quantity: 306,
	},
	{
		garage_id: 95,
		part_id: 286,
		quantity: 117,
	},
	{
		garage_id: 95,
		part_id: 287,
		quantity: 61,
	},
	{
		garage_id: 95,
		part_id: 288,
		quantity: 424,
	},
	{
		garage_id: 95,
		part_id: 289,
		quantity: 422,
	},
	{
		garage_id: 95,
		part_id: 290,
		quantity: 128,
	},
	{
		garage_id: 95,
		part_id: 291,
		quantity: 356,
	},
	{
		garage_id: 95,
		part_id: 292,
		quantity: 249,
	},
	{
		garage_id: 95,
		part_id: 293,
		quantity: 29,
	},
	{
		garage_id: 95,
		part_id: 294,
		quantity: 457,
	},
	{
		garage_id: 95,
		part_id: 295,
		quantity: 448,
	},
	{
		garage_id: 95,
		part_id: 296,
		quantity: 481,
	},
	{
		garage_id: 95,
		part_id: 297,
		quantity: 155,
	},
	{
		garage_id: 95,
		part_id: 298,
		quantity: 73,
	},
	{
		garage_id: 95,
		part_id: 299,
		quantity: 74,
	},
	{
		garage_id: 95,
		part_id: 300,
		quantity: 173,
	},
	{
		garage_id: 95,
		part_id: 301,
		quantity: 225,
	},
	{
		garage_id: 95,
		part_id: 302,
		quantity: 273,
	},
	{
		garage_id: 95,
		part_id: 303,
		quantity: 453,
	},
	{
		garage_id: 95,
		part_id: 304,
		quantity: 278,
	},
	{
		garage_id: 95,
		part_id: 305,
		quantity: 35,
	},
	{
		garage_id: 95,
		part_id: 306,
		quantity: 393,
	},
	{
		garage_id: 95,
		part_id: 307,
		quantity: 191,
	},
	{
		garage_id: 95,
		part_id: 308,
		quantity: 23,
	},
	{
		garage_id: 95,
		part_id: 309,
		quantity: 85,
	},
	{
		garage_id: 95,
		part_id: 310,
		quantity: 61,
	},
	{
		garage_id: 95,
		part_id: 311,
		quantity: 83,
	},
	{
		garage_id: 95,
		part_id: 312,
		quantity: 333,
	},
	{
		garage_id: 95,
		part_id: 313,
		quantity: 403,
	},
	{
		garage_id: 95,
		part_id: 314,
		quantity: 52,
	},
	{
		garage_id: 95,
		part_id: 315,
		quantity: 362,
	},
	{
		garage_id: 95,
		part_id: 316,
		quantity: 408,
	},
	{
		garage_id: 95,
		part_id: 317,
		quantity: 212,
	},
	{
		garage_id: 95,
		part_id: 318,
		quantity: 372,
	},
	{
		garage_id: 95,
		part_id: 319,
		quantity: 66,
	},
	{
		garage_id: 95,
		part_id: 320,
		quantity: 3,
	},
	{
		garage_id: 95,
		part_id: 321,
		quantity: 496,
	},
	{
		garage_id: 95,
		part_id: 322,
		quantity: 424,
	},
	{
		garage_id: 95,
		part_id: 323,
		quantity: 344,
	},
	{
		garage_id: 95,
		part_id: 324,
		quantity: 43,
	},
	{
		garage_id: 95,
		part_id: 325,
		quantity: 215,
	},
	{
		garage_id: 95,
		part_id: 326,
		quantity: 455,
	},
	{
		garage_id: 95,
		part_id: 327,
		quantity: 475,
	},
	{
		garage_id: 95,
		part_id: 328,
		quantity: 475,
	},
	{
		garage_id: 95,
		part_id: 329,
		quantity: 296,
	},
	{
		garage_id: 95,
		part_id: 330,
		quantity: 196,
	},
	{
		garage_id: 95,
		part_id: 331,
		quantity: 443,
	},
	{
		garage_id: 95,
		part_id: 332,
		quantity: 333,
	},
	{
		garage_id: 95,
		part_id: 333,
		quantity: 231,
	},
	{
		garage_id: 95,
		part_id: 334,
		quantity: 419,
	},
	{
		garage_id: 95,
		part_id: 335,
		quantity: 435,
	},
	{
		garage_id: 95,
		part_id: 336,
		quantity: 485,
	},
	{
		garage_id: 95,
		part_id: 337,
		quantity: 169,
	},
	{
		garage_id: 95,
		part_id: 338,
		quantity: 113,
	},
	{
		garage_id: 95,
		part_id: 339,
		quantity: 355,
	},
	{
		garage_id: 95,
		part_id: 340,
		quantity: 492,
	},
	{
		garage_id: 95,
		part_id: 341,
		quantity: 134,
	},
	{
		garage_id: 95,
		part_id: 342,
		quantity: 128,
	},
	{
		garage_id: 95,
		part_id: 343,
		quantity: 225,
	},
	{
		garage_id: 95,
		part_id: 344,
		quantity: 184,
	},
	{
		garage_id: 95,
		part_id: 345,
		quantity: 207,
	},
	{
		garage_id: 95,
		part_id: 346,
		quantity: 55,
	},
	{
		garage_id: 95,
		part_id: 347,
		quantity: 319,
	},
	{
		garage_id: 95,
		part_id: 348,
		quantity: 169,
	},
	{
		garage_id: 95,
		part_id: 349,
		quantity: 107,
	},
	{
		garage_id: 95,
		part_id: 350,
		quantity: 361,
	},
	{
		garage_id: 95,
		part_id: 351,
		quantity: 42,
	},
	{
		garage_id: 95,
		part_id: 352,
		quantity: 254,
	},
	{
		garage_id: 95,
		part_id: 353,
		quantity: 95,
	},
	{
		garage_id: 95,
		part_id: 354,
		quantity: 115,
	},
	{
		garage_id: 95,
		part_id: 355,
		quantity: 195,
	},
	{
		garage_id: 95,
		part_id: 356,
		quantity: 447,
	},
	{
		garage_id: 95,
		part_id: 357,
		quantity: 365,
	},
	{
		garage_id: 95,
		part_id: 358,
		quantity: 405,
	},
	{
		garage_id: 95,
		part_id: 359,
		quantity: 394,
	},
	{
		garage_id: 95,
		part_id: 360,
		quantity: 370,
	},
	{
		garage_id: 95,
		part_id: 361,
		quantity: 42,
	},
	{
		garage_id: 95,
		part_id: 362,
		quantity: 140,
	},
	{
		garage_id: 95,
		part_id: 363,
		quantity: 211,
	},
	{
		garage_id: 95,
		part_id: 364,
		quantity: 175,
	},
	{
		garage_id: 95,
		part_id: 365,
		quantity: 360,
	},
	{
		garage_id: 95,
		part_id: 366,
		quantity: 116,
	},
	{
		garage_id: 95,
		part_id: 367,
		quantity: 271,
	},
	{
		garage_id: 95,
		part_id: 368,
		quantity: 293,
	},
	{
		garage_id: 95,
		part_id: 369,
		quantity: 72,
	},
	{
		garage_id: 95,
		part_id: 370,
		quantity: 347,
	},
	{
		garage_id: 95,
		part_id: 371,
		quantity: 8,
	},
	{
		garage_id: 95,
		part_id: 372,
		quantity: 350,
	},
	{
		garage_id: 95,
		part_id: 373,
		quantity: 500,
	},
	{
		garage_id: 95,
		part_id: 374,
		quantity: 119,
	},
	{
		garage_id: 95,
		part_id: 375,
		quantity: 311,
	},
	{
		garage_id: 95,
		part_id: 376,
		quantity: 440,
	},
	{
		garage_id: 95,
		part_id: 377,
		quantity: 87,
	},
	{
		garage_id: 95,
		part_id: 378,
		quantity: 281,
	},
	{
		garage_id: 95,
		part_id: 379,
		quantity: 347,
	},
	{
		garage_id: 95,
		part_id: 380,
		quantity: 96,
	},
	{
		garage_id: 95,
		part_id: 381,
		quantity: 204,
	},
	{
		garage_id: 95,
		part_id: 382,
		quantity: 488,
	},
	{
		garage_id: 95,
		part_id: 383,
		quantity: 268,
	},
	{
		garage_id: 95,
		part_id: 384,
		quantity: 473,
	},
	{
		garage_id: 95,
		part_id: 385,
		quantity: 63,
	},
	{
		garage_id: 95,
		part_id: 386,
		quantity: 137,
	},
	{
		garage_id: 95,
		part_id: 387,
		quantity: 19,
	},
	{
		garage_id: 95,
		part_id: 388,
		quantity: 141,
	},
	{
		garage_id: 95,
		part_id: 389,
		quantity: 368,
	},
	{
		garage_id: 95,
		part_id: 390,
		quantity: 469,
	},
	{
		garage_id: 95,
		part_id: 391,
		quantity: 256,
	},
	{
		garage_id: 95,
		part_id: 392,
		quantity: 36,
	},
	{
		garage_id: 95,
		part_id: 393,
		quantity: 315,
	},
	{
		garage_id: 95,
		part_id: 394,
		quantity: 217,
	},
	{
		garage_id: 95,
		part_id: 395,
		quantity: 445,
	},
	{
		garage_id: 95,
		part_id: 396,
		quantity: 106,
	},
	{
		garage_id: 95,
		part_id: 397,
		quantity: 477,
	},
	{
		garage_id: 95,
		part_id: 398,
		quantity: 419,
	},
	{
		garage_id: 95,
		part_id: 399,
		quantity: 262,
	},
	{
		garage_id: 96,
		part_id: 0,
		quantity: 262,
	},
	{
		garage_id: 96,
		part_id: 1,
		quantity: 119,
	},
	{
		garage_id: 96,
		part_id: 2,
		quantity: 10,
	},
	{
		garage_id: 96,
		part_id: 3,
		quantity: 19,
	},
	{
		garage_id: 96,
		part_id: 4,
		quantity: 96,
	},
	{
		garage_id: 96,
		part_id: 5,
		quantity: 415,
	},
	{
		garage_id: 96,
		part_id: 6,
		quantity: 436,
	},
	{
		garage_id: 96,
		part_id: 7,
		quantity: 278,
	},
	{
		garage_id: 96,
		part_id: 8,
		quantity: 459,
	},
	{
		garage_id: 96,
		part_id: 9,
		quantity: 164,
	},
	{
		garage_id: 96,
		part_id: 10,
		quantity: 335,
	},
	{
		garage_id: 96,
		part_id: 11,
		quantity: 232,
	},
	{
		garage_id: 96,
		part_id: 12,
		quantity: 188,
	},
	{
		garage_id: 96,
		part_id: 13,
		quantity: 297,
	},
	{
		garage_id: 96,
		part_id: 14,
		quantity: 250,
	},
	{
		garage_id: 96,
		part_id: 15,
		quantity: 184,
	},
	{
		garage_id: 96,
		part_id: 16,
		quantity: 71,
	},
	{
		garage_id: 96,
		part_id: 17,
		quantity: 226,
	},
	{
		garage_id: 96,
		part_id: 18,
		quantity: 208,
	},
	{
		garage_id: 96,
		part_id: 19,
		quantity: 113,
	},
	{
		garage_id: 96,
		part_id: 20,
		quantity: 450,
	},
	{
		garage_id: 96,
		part_id: 21,
		quantity: 382,
	},
	{
		garage_id: 96,
		part_id: 22,
		quantity: 145,
	},
	{
		garage_id: 96,
		part_id: 23,
		quantity: 284,
	},
	{
		garage_id: 96,
		part_id: 24,
		quantity: 208,
	},
	{
		garage_id: 96,
		part_id: 25,
		quantity: 230,
	},
	{
		garage_id: 96,
		part_id: 26,
		quantity: 341,
	},
	{
		garage_id: 96,
		part_id: 27,
		quantity: 281,
	},
	{
		garage_id: 96,
		part_id: 28,
		quantity: 389,
	},
	{
		garage_id: 96,
		part_id: 29,
		quantity: 11,
	},
	{
		garage_id: 96,
		part_id: 30,
		quantity: 114,
	},
	{
		garage_id: 96,
		part_id: 31,
		quantity: 499,
	},
	{
		garage_id: 96,
		part_id: 32,
		quantity: 66,
	},
	{
		garage_id: 96,
		part_id: 33,
		quantity: 244,
	},
	{
		garage_id: 96,
		part_id: 34,
		quantity: 196,
	},
	{
		garage_id: 96,
		part_id: 35,
		quantity: 249,
	},
	{
		garage_id: 96,
		part_id: 36,
		quantity: 128,
	},
	{
		garage_id: 96,
		part_id: 37,
		quantity: 488,
	},
	{
		garage_id: 96,
		part_id: 38,
		quantity: 146,
	},
	{
		garage_id: 96,
		part_id: 39,
		quantity: 157,
	},
	{
		garage_id: 96,
		part_id: 40,
		quantity: 245,
	},
	{
		garage_id: 96,
		part_id: 41,
		quantity: 183,
	},
	{
		garage_id: 96,
		part_id: 42,
		quantity: 370,
	},
	{
		garage_id: 96,
		part_id: 43,
		quantity: 74,
	},
	{
		garage_id: 96,
		part_id: 44,
		quantity: 252,
	},
	{
		garage_id: 96,
		part_id: 45,
		quantity: 53,
	},
	{
		garage_id: 96,
		part_id: 46,
		quantity: 434,
	},
	{
		garage_id: 96,
		part_id: 47,
		quantity: 35,
	},
	{
		garage_id: 96,
		part_id: 48,
		quantity: 340,
	},
	{
		garage_id: 96,
		part_id: 49,
		quantity: 339,
	},
	{
		garage_id: 96,
		part_id: 50,
		quantity: 107,
	},
	{
		garage_id: 96,
		part_id: 51,
		quantity: 4,
	},
	{
		garage_id: 96,
		part_id: 52,
		quantity: 103,
	},
	{
		garage_id: 96,
		part_id: 53,
		quantity: 495,
	},
	{
		garage_id: 96,
		part_id: 54,
		quantity: 248,
	},
	{
		garage_id: 96,
		part_id: 55,
		quantity: 308,
	},
	{
		garage_id: 96,
		part_id: 56,
		quantity: 46,
	},
	{
		garage_id: 96,
		part_id: 57,
		quantity: 324,
	},
	{
		garage_id: 96,
		part_id: 58,
		quantity: 166,
	},
	{
		garage_id: 96,
		part_id: 59,
		quantity: 413,
	},
	{
		garage_id: 96,
		part_id: 60,
		quantity: 368,
	},
	{
		garage_id: 96,
		part_id: 61,
		quantity: 154,
	},
	{
		garage_id: 96,
		part_id: 62,
		quantity: 112,
	},
	{
		garage_id: 96,
		part_id: 63,
		quantity: 309,
	},
	{
		garage_id: 96,
		part_id: 64,
		quantity: 55,
	},
	{
		garage_id: 96,
		part_id: 65,
		quantity: 59,
	},
	{
		garage_id: 96,
		part_id: 66,
		quantity: 170,
	},
	{
		garage_id: 96,
		part_id: 67,
		quantity: 262,
	},
	{
		garage_id: 96,
		part_id: 68,
		quantity: 291,
	},
	{
		garage_id: 96,
		part_id: 69,
		quantity: 386,
	},
	{
		garage_id: 96,
		part_id: 70,
		quantity: 19,
	},
	{
		garage_id: 96,
		part_id: 71,
		quantity: 280,
	},
	{
		garage_id: 96,
		part_id: 72,
		quantity: 250,
	},
	{
		garage_id: 96,
		part_id: 73,
		quantity: 150,
	},
	{
		garage_id: 96,
		part_id: 74,
		quantity: 57,
	},
	{
		garage_id: 96,
		part_id: 75,
		quantity: 498,
	},
	{
		garage_id: 96,
		part_id: 76,
		quantity: 34,
	},
	{
		garage_id: 96,
		part_id: 77,
		quantity: 219,
	},
	{
		garage_id: 96,
		part_id: 78,
		quantity: 36,
	},
	{
		garage_id: 96,
		part_id: 79,
		quantity: 429,
	},
	{
		garage_id: 96,
		part_id: 80,
		quantity: 343,
	},
	{
		garage_id: 96,
		part_id: 81,
		quantity: 101,
	},
	{
		garage_id: 96,
		part_id: 82,
		quantity: 154,
	},
	{
		garage_id: 96,
		part_id: 83,
		quantity: 183,
	},
	{
		garage_id: 96,
		part_id: 84,
		quantity: 196,
	},
	{
		garage_id: 96,
		part_id: 85,
		quantity: 446,
	},
	{
		garage_id: 96,
		part_id: 86,
		quantity: 339,
	},
	{
		garage_id: 96,
		part_id: 87,
		quantity: 16,
	},
	{
		garage_id: 96,
		part_id: 88,
		quantity: 110,
	},
	{
		garage_id: 96,
		part_id: 89,
		quantity: 37,
	},
	{
		garage_id: 96,
		part_id: 90,
		quantity: 461,
	},
	{
		garage_id: 96,
		part_id: 91,
		quantity: 234,
	},
	{
		garage_id: 96,
		part_id: 92,
		quantity: 48,
	},
	{
		garage_id: 96,
		part_id: 93,
		quantity: 433,
	},
	{
		garage_id: 96,
		part_id: 94,
		quantity: 115,
	},
	{
		garage_id: 96,
		part_id: 95,
		quantity: 224,
	},
	{
		garage_id: 96,
		part_id: 96,
		quantity: 206,
	},
	{
		garage_id: 96,
		part_id: 97,
		quantity: 2,
	},
	{
		garage_id: 96,
		part_id: 98,
		quantity: 87,
	},
	{
		garage_id: 96,
		part_id: 99,
		quantity: 492,
	},
	{
		garage_id: 96,
		part_id: 100,
		quantity: 91,
	},
	{
		garage_id: 96,
		part_id: 101,
		quantity: 51,
	},
	{
		garage_id: 96,
		part_id: 102,
		quantity: 91,
	},
	{
		garage_id: 96,
		part_id: 103,
		quantity: 451,
	},
	{
		garage_id: 96,
		part_id: 104,
		quantity: 372,
	},
	{
		garage_id: 96,
		part_id: 105,
		quantity: 360,
	},
	{
		garage_id: 96,
		part_id: 106,
		quantity: 140,
	},
	{
		garage_id: 96,
		part_id: 107,
		quantity: 10,
	},
	{
		garage_id: 96,
		part_id: 108,
		quantity: 176,
	},
	{
		garage_id: 96,
		part_id: 109,
		quantity: 197,
	},
	{
		garage_id: 96,
		part_id: 110,
		quantity: 154,
	},
	{
		garage_id: 96,
		part_id: 111,
		quantity: 329,
	},
	{
		garage_id: 96,
		part_id: 112,
		quantity: 220,
	},
	{
		garage_id: 96,
		part_id: 113,
		quantity: 422,
	},
	{
		garage_id: 96,
		part_id: 114,
		quantity: 151,
	},
	{
		garage_id: 96,
		part_id: 115,
		quantity: 339,
	},
	{
		garage_id: 96,
		part_id: 116,
		quantity: 103,
	},
	{
		garage_id: 96,
		part_id: 117,
		quantity: 105,
	},
	{
		garage_id: 96,
		part_id: 118,
		quantity: 127,
	},
	{
		garage_id: 96,
		part_id: 119,
		quantity: 322,
	},
	{
		garage_id: 96,
		part_id: 120,
		quantity: 258,
	},
	{
		garage_id: 96,
		part_id: 121,
		quantity: 499,
	},
	{
		garage_id: 96,
		part_id: 122,
		quantity: 151,
	},
	{
		garage_id: 96,
		part_id: 123,
		quantity: 471,
	},
	{
		garage_id: 96,
		part_id: 124,
		quantity: 302,
	},
	{
		garage_id: 96,
		part_id: 125,
		quantity: 318,
	},
	{
		garage_id: 96,
		part_id: 126,
		quantity: 188,
	},
	{
		garage_id: 96,
		part_id: 127,
		quantity: 339,
	},
	{
		garage_id: 96,
		part_id: 128,
		quantity: 172,
	},
	{
		garage_id: 96,
		part_id: 129,
		quantity: 103,
	},
	{
		garage_id: 96,
		part_id: 130,
		quantity: 236,
	},
	{
		garage_id: 96,
		part_id: 131,
		quantity: 418,
	},
	{
		garage_id: 96,
		part_id: 132,
		quantity: 273,
	},
	{
		garage_id: 96,
		part_id: 133,
		quantity: 413,
	},
	{
		garage_id: 96,
		part_id: 134,
		quantity: 103,
	},
	{
		garage_id: 96,
		part_id: 135,
		quantity: 39,
	},
	{
		garage_id: 96,
		part_id: 136,
		quantity: 15,
	},
	{
		garage_id: 96,
		part_id: 137,
		quantity: 374,
	},
	{
		garage_id: 96,
		part_id: 138,
		quantity: 438,
	},
	{
		garage_id: 96,
		part_id: 139,
		quantity: 332,
	},
	{
		garage_id: 96,
		part_id: 140,
		quantity: 65,
	},
	{
		garage_id: 96,
		part_id: 141,
		quantity: 377,
	},
	{
		garage_id: 96,
		part_id: 142,
		quantity: 467,
	},
	{
		garage_id: 96,
		part_id: 143,
		quantity: 32,
	},
	{
		garage_id: 96,
		part_id: 144,
		quantity: 412,
	},
	{
		garage_id: 96,
		part_id: 145,
		quantity: 366,
	},
	{
		garage_id: 96,
		part_id: 146,
		quantity: 380,
	},
	{
		garage_id: 96,
		part_id: 147,
		quantity: 154,
	},
	{
		garage_id: 96,
		part_id: 148,
		quantity: 321,
	},
	{
		garage_id: 96,
		part_id: 149,
		quantity: 181,
	},
	{
		garage_id: 96,
		part_id: 150,
		quantity: 52,
	},
	{
		garage_id: 96,
		part_id: 151,
		quantity: 383,
	},
	{
		garage_id: 96,
		part_id: 152,
		quantity: 276,
	},
	{
		garage_id: 96,
		part_id: 153,
		quantity: 197,
	},
	{
		garage_id: 96,
		part_id: 154,
		quantity: 185,
	},
	{
		garage_id: 96,
		part_id: 155,
		quantity: 200,
	},
	{
		garage_id: 96,
		part_id: 156,
		quantity: 462,
	},
	{
		garage_id: 96,
		part_id: 157,
		quantity: 405,
	},
	{
		garage_id: 96,
		part_id: 158,
		quantity: 324,
	},
	{
		garage_id: 96,
		part_id: 159,
		quantity: 469,
	},
	{
		garage_id: 96,
		part_id: 160,
		quantity: 108,
	},
	{
		garage_id: 96,
		part_id: 161,
		quantity: 392,
	},
	{
		garage_id: 96,
		part_id: 162,
		quantity: 186,
	},
	{
		garage_id: 96,
		part_id: 163,
		quantity: 364,
	},
	{
		garage_id: 96,
		part_id: 164,
		quantity: 409,
	},
	{
		garage_id: 96,
		part_id: 165,
		quantity: 71,
	},
	{
		garage_id: 96,
		part_id: 166,
		quantity: 29,
	},
	{
		garage_id: 96,
		part_id: 167,
		quantity: 417,
	},
	{
		garage_id: 96,
		part_id: 168,
		quantity: 395,
	},
	{
		garage_id: 96,
		part_id: 169,
		quantity: 445,
	},
	{
		garage_id: 96,
		part_id: 170,
		quantity: 149,
	},
	{
		garage_id: 96,
		part_id: 171,
		quantity: 349,
	},
	{
		garage_id: 96,
		part_id: 172,
		quantity: 467,
	},
	{
		garage_id: 96,
		part_id: 173,
		quantity: 214,
	},
	{
		garage_id: 96,
		part_id: 174,
		quantity: 389,
	},
	{
		garage_id: 96,
		part_id: 175,
		quantity: 70,
	},
	{
		garage_id: 96,
		part_id: 176,
		quantity: 113,
	},
	{
		garage_id: 96,
		part_id: 177,
		quantity: 97,
	},
	{
		garage_id: 96,
		part_id: 178,
		quantity: 16,
	},
	{
		garage_id: 96,
		part_id: 179,
		quantity: 220,
	},
	{
		garage_id: 96,
		part_id: 180,
		quantity: 158,
	},
	{
		garage_id: 96,
		part_id: 181,
		quantity: 397,
	},
	{
		garage_id: 96,
		part_id: 182,
		quantity: 84,
	},
	{
		garage_id: 96,
		part_id: 183,
		quantity: 72,
	},
	{
		garage_id: 96,
		part_id: 184,
		quantity: 0,
	},
	{
		garage_id: 96,
		part_id: 185,
		quantity: 254,
	},
	{
		garage_id: 96,
		part_id: 186,
		quantity: 442,
	},
	{
		garage_id: 96,
		part_id: 187,
		quantity: 190,
	},
	{
		garage_id: 96,
		part_id: 188,
		quantity: 392,
	},
	{
		garage_id: 96,
		part_id: 189,
		quantity: 447,
	},
	{
		garage_id: 96,
		part_id: 190,
		quantity: 153,
	},
	{
		garage_id: 96,
		part_id: 191,
		quantity: 259,
	},
	{
		garage_id: 96,
		part_id: 192,
		quantity: 153,
	},
	{
		garage_id: 96,
		part_id: 193,
		quantity: 102,
	},
	{
		garage_id: 96,
		part_id: 194,
		quantity: 240,
	},
	{
		garage_id: 96,
		part_id: 195,
		quantity: 165,
	},
	{
		garage_id: 96,
		part_id: 196,
		quantity: 324,
	},
	{
		garage_id: 96,
		part_id: 197,
		quantity: 324,
	},
	{
		garage_id: 96,
		part_id: 198,
		quantity: 229,
	},
	{
		garage_id: 96,
		part_id: 199,
		quantity: 319,
	},
	{
		garage_id: 96,
		part_id: 200,
		quantity: 72,
	},
	{
		garage_id: 96,
		part_id: 201,
		quantity: 350,
	},
	{
		garage_id: 96,
		part_id: 202,
		quantity: 263,
	},
	{
		garage_id: 96,
		part_id: 203,
		quantity: 268,
	},
	{
		garage_id: 96,
		part_id: 204,
		quantity: 266,
	},
	{
		garage_id: 96,
		part_id: 205,
		quantity: 219,
	},
	{
		garage_id: 96,
		part_id: 206,
		quantity: 65,
	},
	{
		garage_id: 96,
		part_id: 207,
		quantity: 362,
	},
	{
		garage_id: 96,
		part_id: 208,
		quantity: 110,
	},
	{
		garage_id: 96,
		part_id: 209,
		quantity: 152,
	},
	{
		garage_id: 96,
		part_id: 210,
		quantity: 169,
	},
	{
		garage_id: 96,
		part_id: 211,
		quantity: 392,
	},
	{
		garage_id: 96,
		part_id: 212,
		quantity: 368,
	},
	{
		garage_id: 96,
		part_id: 213,
		quantity: 185,
	},
	{
		garage_id: 96,
		part_id: 214,
		quantity: 455,
	},
	{
		garage_id: 96,
		part_id: 215,
		quantity: 359,
	},
	{
		garage_id: 96,
		part_id: 216,
		quantity: 237,
	},
	{
		garage_id: 96,
		part_id: 217,
		quantity: 189,
	},
	{
		garage_id: 96,
		part_id: 218,
		quantity: 314,
	},
	{
		garage_id: 96,
		part_id: 219,
		quantity: 473,
	},
	{
		garage_id: 96,
		part_id: 220,
		quantity: 155,
	},
	{
		garage_id: 96,
		part_id: 221,
		quantity: 49,
	},
	{
		garage_id: 96,
		part_id: 222,
		quantity: 270,
	},
	{
		garage_id: 96,
		part_id: 223,
		quantity: 243,
	},
	{
		garage_id: 96,
		part_id: 224,
		quantity: 339,
	},
	{
		garage_id: 96,
		part_id: 225,
		quantity: 310,
	},
	{
		garage_id: 96,
		part_id: 226,
		quantity: 183,
	},
	{
		garage_id: 96,
		part_id: 227,
		quantity: 219,
	},
	{
		garage_id: 96,
		part_id: 228,
		quantity: 347,
	},
	{
		garage_id: 96,
		part_id: 229,
		quantity: 371,
	},
	{
		garage_id: 96,
		part_id: 230,
		quantity: 486,
	},
	{
		garage_id: 96,
		part_id: 231,
		quantity: 460,
	},
	{
		garage_id: 96,
		part_id: 232,
		quantity: 62,
	},
	{
		garage_id: 96,
		part_id: 233,
		quantity: 98,
	},
	{
		garage_id: 96,
		part_id: 234,
		quantity: 299,
	},
	{
		garage_id: 96,
		part_id: 235,
		quantity: 22,
	},
	{
		garage_id: 96,
		part_id: 236,
		quantity: 362,
	},
	{
		garage_id: 96,
		part_id: 237,
		quantity: 16,
	},
	{
		garage_id: 96,
		part_id: 238,
		quantity: 13,
	},
	{
		garage_id: 96,
		part_id: 239,
		quantity: 224,
	},
	{
		garage_id: 96,
		part_id: 240,
		quantity: 481,
	},
	{
		garage_id: 96,
		part_id: 241,
		quantity: 357,
	},
	{
		garage_id: 96,
		part_id: 242,
		quantity: 235,
	},
	{
		garage_id: 96,
		part_id: 243,
		quantity: 431,
	},
	{
		garage_id: 96,
		part_id: 244,
		quantity: 145,
	},
	{
		garage_id: 96,
		part_id: 245,
		quantity: 256,
	},
	{
		garage_id: 96,
		part_id: 246,
		quantity: 3,
	},
	{
		garage_id: 96,
		part_id: 247,
		quantity: 87,
	},
	{
		garage_id: 96,
		part_id: 248,
		quantity: 284,
	},
	{
		garage_id: 96,
		part_id: 249,
		quantity: 60,
	},
	{
		garage_id: 96,
		part_id: 250,
		quantity: 325,
	},
	{
		garage_id: 96,
		part_id: 251,
		quantity: 326,
	},
	{
		garage_id: 96,
		part_id: 252,
		quantity: 411,
	},
	{
		garage_id: 96,
		part_id: 253,
		quantity: 403,
	},
	{
		garage_id: 96,
		part_id: 254,
		quantity: 286,
	},
	{
		garage_id: 96,
		part_id: 255,
		quantity: 438,
	},
	{
		garage_id: 96,
		part_id: 256,
		quantity: 463,
	},
	{
		garage_id: 96,
		part_id: 257,
		quantity: 113,
	},
	{
		garage_id: 96,
		part_id: 258,
		quantity: 408,
	},
	{
		garage_id: 96,
		part_id: 259,
		quantity: 187,
	},
	{
		garage_id: 96,
		part_id: 260,
		quantity: 178,
	},
	{
		garage_id: 96,
		part_id: 261,
		quantity: 57,
	},
	{
		garage_id: 96,
		part_id: 262,
		quantity: 7,
	},
	{
		garage_id: 96,
		part_id: 263,
		quantity: 277,
	},
	{
		garage_id: 96,
		part_id: 264,
		quantity: 368,
	},
	{
		garage_id: 96,
		part_id: 265,
		quantity: 104,
	},
	{
		garage_id: 96,
		part_id: 266,
		quantity: 72,
	},
	{
		garage_id: 96,
		part_id: 267,
		quantity: 73,
	},
	{
		garage_id: 96,
		part_id: 268,
		quantity: 248,
	},
	{
		garage_id: 96,
		part_id: 269,
		quantity: 487,
	},
	{
		garage_id: 96,
		part_id: 270,
		quantity: 178,
	},
	{
		garage_id: 96,
		part_id: 271,
		quantity: 420,
	},
	{
		garage_id: 96,
		part_id: 272,
		quantity: 446,
	},
	{
		garage_id: 96,
		part_id: 273,
		quantity: 64,
	},
	{
		garage_id: 96,
		part_id: 274,
		quantity: 88,
	},
	{
		garage_id: 96,
		part_id: 275,
		quantity: 395,
	},
	{
		garage_id: 96,
		part_id: 276,
		quantity: 450,
	},
	{
		garage_id: 96,
		part_id: 277,
		quantity: 80,
	},
	{
		garage_id: 96,
		part_id: 278,
		quantity: 438,
	},
	{
		garage_id: 96,
		part_id: 279,
		quantity: 463,
	},
	{
		garage_id: 96,
		part_id: 280,
		quantity: 375,
	},
	{
		garage_id: 96,
		part_id: 281,
		quantity: 149,
	},
	{
		garage_id: 96,
		part_id: 282,
		quantity: 16,
	},
	{
		garage_id: 96,
		part_id: 283,
		quantity: 327,
	},
	{
		garage_id: 96,
		part_id: 284,
		quantity: 331,
	},
	{
		garage_id: 96,
		part_id: 285,
		quantity: 491,
	},
	{
		garage_id: 96,
		part_id: 286,
		quantity: 136,
	},
	{
		garage_id: 96,
		part_id: 287,
		quantity: 215,
	},
	{
		garage_id: 96,
		part_id: 288,
		quantity: 111,
	},
	{
		garage_id: 96,
		part_id: 289,
		quantity: 430,
	},
	{
		garage_id: 96,
		part_id: 290,
		quantity: 35,
	},
	{
		garage_id: 96,
		part_id: 291,
		quantity: 141,
	},
	{
		garage_id: 96,
		part_id: 292,
		quantity: 246,
	},
	{
		garage_id: 96,
		part_id: 293,
		quantity: 161,
	},
	{
		garage_id: 96,
		part_id: 294,
		quantity: 152,
	},
	{
		garage_id: 96,
		part_id: 295,
		quantity: 466,
	},
	{
		garage_id: 96,
		part_id: 296,
		quantity: 427,
	},
	{
		garage_id: 96,
		part_id: 297,
		quantity: 438,
	},
	{
		garage_id: 96,
		part_id: 298,
		quantity: 113,
	},
	{
		garage_id: 96,
		part_id: 299,
		quantity: 480,
	},
	{
		garage_id: 96,
		part_id: 300,
		quantity: 205,
	},
	{
		garage_id: 96,
		part_id: 301,
		quantity: 254,
	},
	{
		garage_id: 96,
		part_id: 302,
		quantity: 35,
	},
	{
		garage_id: 96,
		part_id: 303,
		quantity: 336,
	},
	{
		garage_id: 96,
		part_id: 304,
		quantity: 39,
	},
	{
		garage_id: 96,
		part_id: 305,
		quantity: 50,
	},
	{
		garage_id: 96,
		part_id: 306,
		quantity: 251,
	},
	{
		garage_id: 96,
		part_id: 307,
		quantity: 432,
	},
	{
		garage_id: 96,
		part_id: 308,
		quantity: 223,
	},
	{
		garage_id: 96,
		part_id: 309,
		quantity: 29,
	},
	{
		garage_id: 96,
		part_id: 310,
		quantity: 94,
	},
	{
		garage_id: 96,
		part_id: 311,
		quantity: 53,
	},
	{
		garage_id: 96,
		part_id: 312,
		quantity: 387,
	},
	{
		garage_id: 96,
		part_id: 313,
		quantity: 434,
	},
	{
		garage_id: 96,
		part_id: 314,
		quantity: 238,
	},
	{
		garage_id: 96,
		part_id: 315,
		quantity: 29,
	},
	{
		garage_id: 96,
		part_id: 316,
		quantity: 205,
	},
	{
		garage_id: 96,
		part_id: 317,
		quantity: 26,
	},
	{
		garage_id: 96,
		part_id: 318,
		quantity: 486,
	},
	{
		garage_id: 96,
		part_id: 319,
		quantity: 461,
	},
	{
		garage_id: 96,
		part_id: 320,
		quantity: 312,
	},
	{
		garage_id: 96,
		part_id: 321,
		quantity: 6,
	},
	{
		garage_id: 96,
		part_id: 322,
		quantity: 268,
	},
	{
		garage_id: 96,
		part_id: 323,
		quantity: 285,
	},
	{
		garage_id: 96,
		part_id: 324,
		quantity: 316,
	},
	{
		garage_id: 96,
		part_id: 325,
		quantity: 260,
	},
	{
		garage_id: 96,
		part_id: 326,
		quantity: 243,
	},
	{
		garage_id: 96,
		part_id: 327,
		quantity: 146,
	},
	{
		garage_id: 96,
		part_id: 328,
		quantity: 82,
	},
	{
		garage_id: 96,
		part_id: 329,
		quantity: 304,
	},
	{
		garage_id: 96,
		part_id: 330,
		quantity: 173,
	},
	{
		garage_id: 96,
		part_id: 331,
		quantity: 365,
	},
	{
		garage_id: 96,
		part_id: 332,
		quantity: 273,
	},
	{
		garage_id: 96,
		part_id: 333,
		quantity: 153,
	},
	{
		garage_id: 96,
		part_id: 334,
		quantity: 48,
	},
	{
		garage_id: 96,
		part_id: 335,
		quantity: 470,
	},
	{
		garage_id: 96,
		part_id: 336,
		quantity: 256,
	},
	{
		garage_id: 96,
		part_id: 337,
		quantity: 441,
	},
	{
		garage_id: 96,
		part_id: 338,
		quantity: 183,
	},
	{
		garage_id: 96,
		part_id: 339,
		quantity: 135,
	},
	{
		garage_id: 96,
		part_id: 340,
		quantity: 282,
	},
	{
		garage_id: 96,
		part_id: 341,
		quantity: 499,
	},
	{
		garage_id: 96,
		part_id: 342,
		quantity: 383,
	},
	{
		garage_id: 96,
		part_id: 343,
		quantity: 461,
	},
	{
		garage_id: 96,
		part_id: 344,
		quantity: 242,
	},
	{
		garage_id: 96,
		part_id: 345,
		quantity: 259,
	},
	{
		garage_id: 96,
		part_id: 346,
		quantity: 495,
	},
	{
		garage_id: 96,
		part_id: 347,
		quantity: 40,
	},
	{
		garage_id: 96,
		part_id: 348,
		quantity: 201,
	},
	{
		garage_id: 96,
		part_id: 349,
		quantity: 158,
	},
	{
		garage_id: 96,
		part_id: 350,
		quantity: 249,
	},
	{
		garage_id: 96,
		part_id: 351,
		quantity: 48,
	},
	{
		garage_id: 96,
		part_id: 352,
		quantity: 455,
	},
	{
		garage_id: 96,
		part_id: 353,
		quantity: 29,
	},
	{
		garage_id: 96,
		part_id: 354,
		quantity: 197,
	},
	{
		garage_id: 96,
		part_id: 355,
		quantity: 53,
	},
	{
		garage_id: 96,
		part_id: 356,
		quantity: 64,
	},
	{
		garage_id: 96,
		part_id: 357,
		quantity: 183,
	},
	{
		garage_id: 96,
		part_id: 358,
		quantity: 94,
	},
	{
		garage_id: 96,
		part_id: 359,
		quantity: 146,
	},
	{
		garage_id: 96,
		part_id: 360,
		quantity: 320,
	},
	{
		garage_id: 96,
		part_id: 361,
		quantity: 39,
	},
	{
		garage_id: 96,
		part_id: 362,
		quantity: 233,
	},
	{
		garage_id: 96,
		part_id: 363,
		quantity: 284,
	},
	{
		garage_id: 96,
		part_id: 364,
		quantity: 147,
	},
	{
		garage_id: 96,
		part_id: 365,
		quantity: 160,
	},
	{
		garage_id: 96,
		part_id: 366,
		quantity: 25,
	},
	{
		garage_id: 96,
		part_id: 367,
		quantity: 147,
	},
	{
		garage_id: 96,
		part_id: 368,
		quantity: 119,
	},
	{
		garage_id: 96,
		part_id: 369,
		quantity: 309,
	},
	{
		garage_id: 96,
		part_id: 370,
		quantity: 133,
	},
	{
		garage_id: 96,
		part_id: 371,
		quantity: 382,
	},
	{
		garage_id: 96,
		part_id: 372,
		quantity: 305,
	},
	{
		garage_id: 96,
		part_id: 373,
		quantity: 37,
	},
	{
		garage_id: 96,
		part_id: 374,
		quantity: 133,
	},
	{
		garage_id: 96,
		part_id: 375,
		quantity: 468,
	},
	{
		garage_id: 96,
		part_id: 376,
		quantity: 92,
	},
	{
		garage_id: 96,
		part_id: 377,
		quantity: 478,
	},
	{
		garage_id: 96,
		part_id: 378,
		quantity: 291,
	},
	{
		garage_id: 96,
		part_id: 379,
		quantity: 62,
	},
	{
		garage_id: 96,
		part_id: 380,
		quantity: 431,
	},
	{
		garage_id: 96,
		part_id: 381,
		quantity: 218,
	},
	{
		garage_id: 96,
		part_id: 382,
		quantity: 241,
	},
	{
		garage_id: 96,
		part_id: 383,
		quantity: 414,
	},
	{
		garage_id: 96,
		part_id: 384,
		quantity: 337,
	},
	{
		garage_id: 96,
		part_id: 385,
		quantity: 122,
	},
	{
		garage_id: 96,
		part_id: 386,
		quantity: 69,
	},
	{
		garage_id: 96,
		part_id: 387,
		quantity: 140,
	},
	{
		garage_id: 96,
		part_id: 388,
		quantity: 157,
	},
	{
		garage_id: 96,
		part_id: 389,
		quantity: 412,
	},
	{
		garage_id: 96,
		part_id: 390,
		quantity: 151,
	},
	{
		garage_id: 96,
		part_id: 391,
		quantity: 81,
	},
	{
		garage_id: 96,
		part_id: 392,
		quantity: 271,
	},
	{
		garage_id: 96,
		part_id: 393,
		quantity: 379,
	},
	{
		garage_id: 96,
		part_id: 394,
		quantity: 173,
	},
	{
		garage_id: 96,
		part_id: 395,
		quantity: 447,
	},
	{
		garage_id: 96,
		part_id: 396,
		quantity: 4,
	},
	{
		garage_id: 96,
		part_id: 397,
		quantity: 311,
	},
	{
		garage_id: 96,
		part_id: 398,
		quantity: 165,
	},
	{
		garage_id: 96,
		part_id: 399,
		quantity: 317,
	},
	{
		garage_id: 97,
		part_id: 0,
		quantity: 196,
	},
	{
		garage_id: 97,
		part_id: 1,
		quantity: 208,
	},
	{
		garage_id: 97,
		part_id: 2,
		quantity: 41,
	},
	{
		garage_id: 97,
		part_id: 3,
		quantity: 216,
	},
	{
		garage_id: 97,
		part_id: 4,
		quantity: 456,
	},
	{
		garage_id: 97,
		part_id: 5,
		quantity: 430,
	},
	{
		garage_id: 97,
		part_id: 6,
		quantity: 313,
	},
	{
		garage_id: 97,
		part_id: 7,
		quantity: 336,
	},
	{
		garage_id: 97,
		part_id: 8,
		quantity: 13,
	},
	{
		garage_id: 97,
		part_id: 9,
		quantity: 287,
	},
	{
		garage_id: 97,
		part_id: 10,
		quantity: 144,
	},
	{
		garage_id: 97,
		part_id: 11,
		quantity: 218,
	},
	{
		garage_id: 97,
		part_id: 12,
		quantity: 233,
	},
	{
		garage_id: 97,
		part_id: 13,
		quantity: 33,
	},
	{
		garage_id: 97,
		part_id: 14,
		quantity: 84,
	},
	{
		garage_id: 97,
		part_id: 15,
		quantity: 257,
	},
	{
		garage_id: 97,
		part_id: 16,
		quantity: 132,
	},
	{
		garage_id: 97,
		part_id: 17,
		quantity: 136,
	},
	{
		garage_id: 97,
		part_id: 18,
		quantity: 355,
	},
	{
		garage_id: 97,
		part_id: 19,
		quantity: 464,
	},
	{
		garage_id: 97,
		part_id: 20,
		quantity: 202,
	},
	{
		garage_id: 97,
		part_id: 21,
		quantity: 372,
	},
	{
		garage_id: 97,
		part_id: 22,
		quantity: 44,
	},
	{
		garage_id: 97,
		part_id: 23,
		quantity: 7,
	},
	{
		garage_id: 97,
		part_id: 24,
		quantity: 435,
	},
	{
		garage_id: 97,
		part_id: 25,
		quantity: 476,
	},
	{
		garage_id: 97,
		part_id: 26,
		quantity: 220,
	},
	{
		garage_id: 97,
		part_id: 27,
		quantity: 17,
	},
	{
		garage_id: 97,
		part_id: 28,
		quantity: 164,
	},
	{
		garage_id: 97,
		part_id: 29,
		quantity: 69,
	},
	{
		garage_id: 97,
		part_id: 30,
		quantity: 207,
	},
	{
		garage_id: 97,
		part_id: 31,
		quantity: 65,
	},
	{
		garage_id: 97,
		part_id: 32,
		quantity: 464,
	},
	{
		garage_id: 97,
		part_id: 33,
		quantity: 30,
	},
	{
		garage_id: 97,
		part_id: 34,
		quantity: 463,
	},
	{
		garage_id: 97,
		part_id: 35,
		quantity: 204,
	},
	{
		garage_id: 97,
		part_id: 36,
		quantity: 306,
	},
	{
		garage_id: 97,
		part_id: 37,
		quantity: 271,
	},
	{
		garage_id: 97,
		part_id: 38,
		quantity: 191,
	},
	{
		garage_id: 97,
		part_id: 39,
		quantity: 55,
	},
	{
		garage_id: 97,
		part_id: 40,
		quantity: 497,
	},
	{
		garage_id: 97,
		part_id: 41,
		quantity: 93,
	},
	{
		garage_id: 97,
		part_id: 42,
		quantity: 494,
	},
	{
		garage_id: 97,
		part_id: 43,
		quantity: 344,
	},
	{
		garage_id: 97,
		part_id: 44,
		quantity: 436,
	},
	{
		garage_id: 97,
		part_id: 45,
		quantity: 152,
	},
	{
		garage_id: 97,
		part_id: 46,
		quantity: 222,
	},
	{
		garage_id: 97,
		part_id: 47,
		quantity: 281,
	},
	{
		garage_id: 97,
		part_id: 48,
		quantity: 146,
	},
	{
		garage_id: 97,
		part_id: 49,
		quantity: 225,
	},
	{
		garage_id: 97,
		part_id: 50,
		quantity: 438,
	},
	{
		garage_id: 97,
		part_id: 51,
		quantity: 489,
	},
	{
		garage_id: 97,
		part_id: 52,
		quantity: 106,
	},
	{
		garage_id: 97,
		part_id: 53,
		quantity: 393,
	},
	{
		garage_id: 97,
		part_id: 54,
		quantity: 231,
	},
	{
		garage_id: 97,
		part_id: 55,
		quantity: 421,
	},
	{
		garage_id: 97,
		part_id: 56,
		quantity: 15,
	},
	{
		garage_id: 97,
		part_id: 57,
		quantity: 342,
	},
	{
		garage_id: 97,
		part_id: 58,
		quantity: 181,
	},
	{
		garage_id: 97,
		part_id: 59,
		quantity: 421,
	},
	{
		garage_id: 97,
		part_id: 60,
		quantity: 322,
	},
	{
		garage_id: 97,
		part_id: 61,
		quantity: 274,
	},
	{
		garage_id: 97,
		part_id: 62,
		quantity: 479,
	},
	{
		garage_id: 97,
		part_id: 63,
		quantity: 52,
	},
	{
		garage_id: 97,
		part_id: 64,
		quantity: 248,
	},
	{
		garage_id: 97,
		part_id: 65,
		quantity: 99,
	},
	{
		garage_id: 97,
		part_id: 66,
		quantity: 55,
	},
	{
		garage_id: 97,
		part_id: 67,
		quantity: 112,
	},
	{
		garage_id: 97,
		part_id: 68,
		quantity: 88,
	},
	{
		garage_id: 97,
		part_id: 69,
		quantity: 125,
	},
	{
		garage_id: 97,
		part_id: 70,
		quantity: 487,
	},
	{
		garage_id: 97,
		part_id: 71,
		quantity: 435,
	},
	{
		garage_id: 97,
		part_id: 72,
		quantity: 212,
	},
	{
		garage_id: 97,
		part_id: 73,
		quantity: 346,
	},
	{
		garage_id: 97,
		part_id: 74,
		quantity: 2,
	},
	{
		garage_id: 97,
		part_id: 75,
		quantity: 382,
	},
	{
		garage_id: 97,
		part_id: 76,
		quantity: 257,
	},
	{
		garage_id: 97,
		part_id: 77,
		quantity: 90,
	},
	{
		garage_id: 97,
		part_id: 78,
		quantity: 48,
	},
	{
		garage_id: 97,
		part_id: 79,
		quantity: 468,
	},
	{
		garage_id: 97,
		part_id: 80,
		quantity: 410,
	},
	{
		garage_id: 97,
		part_id: 81,
		quantity: 57,
	},
	{
		garage_id: 97,
		part_id: 82,
		quantity: 416,
	},
	{
		garage_id: 97,
		part_id: 83,
		quantity: 314,
	},
	{
		garage_id: 97,
		part_id: 84,
		quantity: 15,
	},
	{
		garage_id: 97,
		part_id: 85,
		quantity: 423,
	},
	{
		garage_id: 97,
		part_id: 86,
		quantity: 36,
	},
	{
		garage_id: 97,
		part_id: 87,
		quantity: 67,
	},
	{
		garage_id: 97,
		part_id: 88,
		quantity: 409,
	},
	{
		garage_id: 97,
		part_id: 89,
		quantity: 6,
	},
	{
		garage_id: 97,
		part_id: 90,
		quantity: 285,
	},
	{
		garage_id: 97,
		part_id: 91,
		quantity: 254,
	},
	{
		garage_id: 97,
		part_id: 92,
		quantity: 157,
	},
	{
		garage_id: 97,
		part_id: 93,
		quantity: 47,
	},
	{
		garage_id: 97,
		part_id: 94,
		quantity: 205,
	},
	{
		garage_id: 97,
		part_id: 95,
		quantity: 231,
	},
	{
		garage_id: 97,
		part_id: 96,
		quantity: 266,
	},
	{
		garage_id: 97,
		part_id: 97,
		quantity: 274,
	},
	{
		garage_id: 97,
		part_id: 98,
		quantity: 196,
	},
	{
		garage_id: 97,
		part_id: 99,
		quantity: 101,
	},
	{
		garage_id: 97,
		part_id: 100,
		quantity: 366,
	},
	{
		garage_id: 97,
		part_id: 101,
		quantity: 486,
	},
	{
		garage_id: 97,
		part_id: 102,
		quantity: 273,
	},
	{
		garage_id: 97,
		part_id: 103,
		quantity: 59,
	},
	{
		garage_id: 97,
		part_id: 104,
		quantity: 90,
	},
	{
		garage_id: 97,
		part_id: 105,
		quantity: 32,
	},
	{
		garage_id: 97,
		part_id: 106,
		quantity: 312,
	},
	{
		garage_id: 97,
		part_id: 107,
		quantity: 453,
	},
	{
		garage_id: 97,
		part_id: 108,
		quantity: 262,
	},
	{
		garage_id: 97,
		part_id: 109,
		quantity: 72,
	},
	{
		garage_id: 97,
		part_id: 110,
		quantity: 21,
	},
	{
		garage_id: 97,
		part_id: 111,
		quantity: 32,
	},
	{
		garage_id: 97,
		part_id: 112,
		quantity: 328,
	},
	{
		garage_id: 97,
		part_id: 113,
		quantity: 209,
	},
	{
		garage_id: 97,
		part_id: 114,
		quantity: 295,
	},
	{
		garage_id: 97,
		part_id: 115,
		quantity: 122,
	},
	{
		garage_id: 97,
		part_id: 116,
		quantity: 400,
	},
	{
		garage_id: 97,
		part_id: 117,
		quantity: 465,
	},
	{
		garage_id: 97,
		part_id: 118,
		quantity: 292,
	},
	{
		garage_id: 97,
		part_id: 119,
		quantity: 340,
	},
	{
		garage_id: 97,
		part_id: 120,
		quantity: 326,
	},
	{
		garage_id: 97,
		part_id: 121,
		quantity: 217,
	},
	{
		garage_id: 97,
		part_id: 122,
		quantity: 228,
	},
	{
		garage_id: 97,
		part_id: 123,
		quantity: 318,
	},
	{
		garage_id: 97,
		part_id: 124,
		quantity: 4,
	},
	{
		garage_id: 97,
		part_id: 125,
		quantity: 83,
	},
	{
		garage_id: 97,
		part_id: 126,
		quantity: 16,
	},
	{
		garage_id: 97,
		part_id: 127,
		quantity: 484,
	},
	{
		garage_id: 97,
		part_id: 128,
		quantity: 440,
	},
	{
		garage_id: 97,
		part_id: 129,
		quantity: 395,
	},
	{
		garage_id: 97,
		part_id: 130,
		quantity: 112,
	},
	{
		garage_id: 97,
		part_id: 131,
		quantity: 171,
	},
	{
		garage_id: 97,
		part_id: 132,
		quantity: 491,
	},
	{
		garage_id: 97,
		part_id: 133,
		quantity: 416,
	},
	{
		garage_id: 97,
		part_id: 134,
		quantity: 342,
	},
	{
		garage_id: 97,
		part_id: 135,
		quantity: 487,
	},
	{
		garage_id: 97,
		part_id: 136,
		quantity: 410,
	},
	{
		garage_id: 97,
		part_id: 137,
		quantity: 386,
	},
	{
		garage_id: 97,
		part_id: 138,
		quantity: 345,
	},
	{
		garage_id: 97,
		part_id: 139,
		quantity: 423,
	},
	{
		garage_id: 97,
		part_id: 140,
		quantity: 458,
	},
	{
		garage_id: 97,
		part_id: 141,
		quantity: 193,
	},
	{
		garage_id: 97,
		part_id: 142,
		quantity: 119,
	},
	{
		garage_id: 97,
		part_id: 143,
		quantity: 489,
	},
	{
		garage_id: 97,
		part_id: 144,
		quantity: 356,
	},
	{
		garage_id: 97,
		part_id: 145,
		quantity: 323,
	},
	{
		garage_id: 97,
		part_id: 146,
		quantity: 400,
	},
	{
		garage_id: 97,
		part_id: 147,
		quantity: 246,
	},
	{
		garage_id: 97,
		part_id: 148,
		quantity: 291,
	},
	{
		garage_id: 97,
		part_id: 149,
		quantity: 367,
	},
	{
		garage_id: 97,
		part_id: 150,
		quantity: 17,
	},
	{
		garage_id: 97,
		part_id: 151,
		quantity: 180,
	},
	{
		garage_id: 97,
		part_id: 152,
		quantity: 72,
	},
	{
		garage_id: 97,
		part_id: 153,
		quantity: 302,
	},
	{
		garage_id: 97,
		part_id: 154,
		quantity: 198,
	},
	{
		garage_id: 97,
		part_id: 155,
		quantity: 284,
	},
	{
		garage_id: 97,
		part_id: 156,
		quantity: 361,
	},
	{
		garage_id: 97,
		part_id: 157,
		quantity: 230,
	},
	{
		garage_id: 97,
		part_id: 158,
		quantity: 405,
	},
	{
		garage_id: 97,
		part_id: 159,
		quantity: 461,
	},
	{
		garage_id: 97,
		part_id: 160,
		quantity: 385,
	},
	{
		garage_id: 97,
		part_id: 161,
		quantity: 255,
	},
	{
		garage_id: 97,
		part_id: 162,
		quantity: 22,
	},
	{
		garage_id: 97,
		part_id: 163,
		quantity: 60,
	},
	{
		garage_id: 97,
		part_id: 164,
		quantity: 228,
	},
	{
		garage_id: 97,
		part_id: 165,
		quantity: 328,
	},
	{
		garage_id: 97,
		part_id: 166,
		quantity: 294,
	},
	{
		garage_id: 97,
		part_id: 167,
		quantity: 333,
	},
	{
		garage_id: 97,
		part_id: 168,
		quantity: 99,
	},
	{
		garage_id: 97,
		part_id: 169,
		quantity: 59,
	},
	{
		garage_id: 97,
		part_id: 170,
		quantity: 365,
	},
	{
		garage_id: 97,
		part_id: 171,
		quantity: 359,
	},
	{
		garage_id: 97,
		part_id: 172,
		quantity: 382,
	},
	{
		garage_id: 97,
		part_id: 173,
		quantity: 418,
	},
	{
		garage_id: 97,
		part_id: 174,
		quantity: 471,
	},
	{
		garage_id: 97,
		part_id: 175,
		quantity: 128,
	},
	{
		garage_id: 97,
		part_id: 176,
		quantity: 500,
	},
	{
		garage_id: 97,
		part_id: 177,
		quantity: 466,
	},
	{
		garage_id: 97,
		part_id: 178,
		quantity: 223,
	},
	{
		garage_id: 97,
		part_id: 179,
		quantity: 365,
	},
	{
		garage_id: 97,
		part_id: 180,
		quantity: 368,
	},
	{
		garage_id: 97,
		part_id: 181,
		quantity: 79,
	},
	{
		garage_id: 97,
		part_id: 182,
		quantity: 28,
	},
	{
		garage_id: 97,
		part_id: 183,
		quantity: 234,
	},
	{
		garage_id: 97,
		part_id: 184,
		quantity: 57,
	},
	{
		garage_id: 97,
		part_id: 185,
		quantity: 245,
	},
	{
		garage_id: 97,
		part_id: 186,
		quantity: 144,
	},
	{
		garage_id: 97,
		part_id: 187,
		quantity: 381,
	},
	{
		garage_id: 97,
		part_id: 188,
		quantity: 164,
	},
	{
		garage_id: 97,
		part_id: 189,
		quantity: 205,
	},
	{
		garage_id: 97,
		part_id: 190,
		quantity: 124,
	},
	{
		garage_id: 97,
		part_id: 191,
		quantity: 365,
	},
	{
		garage_id: 97,
		part_id: 192,
		quantity: 226,
	},
	{
		garage_id: 97,
		part_id: 193,
		quantity: 268,
	},
	{
		garage_id: 97,
		part_id: 194,
		quantity: 362,
	},
	{
		garage_id: 97,
		part_id: 195,
		quantity: 464,
	},
	{
		garage_id: 97,
		part_id: 196,
		quantity: 267,
	},
	{
		garage_id: 97,
		part_id: 197,
		quantity: 97,
	},
	{
		garage_id: 97,
		part_id: 198,
		quantity: 314,
	},
	{
		garage_id: 97,
		part_id: 199,
		quantity: 202,
	},
	{
		garage_id: 97,
		part_id: 200,
		quantity: 141,
	},
	{
		garage_id: 97,
		part_id: 201,
		quantity: 196,
	},
	{
		garage_id: 97,
		part_id: 202,
		quantity: 118,
	},
	{
		garage_id: 97,
		part_id: 203,
		quantity: 187,
	},
	{
		garage_id: 97,
		part_id: 204,
		quantity: 488,
	},
	{
		garage_id: 97,
		part_id: 205,
		quantity: 471,
	},
	{
		garage_id: 97,
		part_id: 206,
		quantity: 362,
	},
	{
		garage_id: 97,
		part_id: 207,
		quantity: 150,
	},
	{
		garage_id: 97,
		part_id: 208,
		quantity: 353,
	},
	{
		garage_id: 97,
		part_id: 209,
		quantity: 374,
	},
	{
		garage_id: 97,
		part_id: 210,
		quantity: 329,
	},
	{
		garage_id: 97,
		part_id: 211,
		quantity: 347,
	},
	{
		garage_id: 97,
		part_id: 212,
		quantity: 254,
	},
	{
		garage_id: 97,
		part_id: 213,
		quantity: 310,
	},
	{
		garage_id: 97,
		part_id: 214,
		quantity: 348,
	},
	{
		garage_id: 97,
		part_id: 215,
		quantity: 494,
	},
	{
		garage_id: 97,
		part_id: 216,
		quantity: 12,
	},
	{
		garage_id: 97,
		part_id: 217,
		quantity: 311,
	},
	{
		garage_id: 97,
		part_id: 218,
		quantity: 113,
	},
	{
		garage_id: 97,
		part_id: 219,
		quantity: 116,
	},
	{
		garage_id: 97,
		part_id: 220,
		quantity: 309,
	},
	{
		garage_id: 97,
		part_id: 221,
		quantity: 109,
	},
	{
		garage_id: 97,
		part_id: 222,
		quantity: 333,
	},
	{
		garage_id: 97,
		part_id: 223,
		quantity: 442,
	},
	{
		garage_id: 97,
		part_id: 224,
		quantity: 38,
	},
	{
		garage_id: 97,
		part_id: 225,
		quantity: 366,
	},
	{
		garage_id: 97,
		part_id: 226,
		quantity: 292,
	},
	{
		garage_id: 97,
		part_id: 227,
		quantity: 1,
	},
	{
		garage_id: 97,
		part_id: 228,
		quantity: 17,
	},
	{
		garage_id: 97,
		part_id: 229,
		quantity: 144,
	},
	{
		garage_id: 97,
		part_id: 230,
		quantity: 342,
	},
	{
		garage_id: 97,
		part_id: 231,
		quantity: 115,
	},
	{
		garage_id: 97,
		part_id: 232,
		quantity: 218,
	},
	{
		garage_id: 97,
		part_id: 233,
		quantity: 293,
	},
	{
		garage_id: 97,
		part_id: 234,
		quantity: 396,
	},
	{
		garage_id: 97,
		part_id: 235,
		quantity: 205,
	},
	{
		garage_id: 97,
		part_id: 236,
		quantity: 497,
	},
	{
		garage_id: 97,
		part_id: 237,
		quantity: 170,
	},
	{
		garage_id: 97,
		part_id: 238,
		quantity: 175,
	},
	{
		garage_id: 97,
		part_id: 239,
		quantity: 139,
	},
	{
		garage_id: 97,
		part_id: 240,
		quantity: 8,
	},
	{
		garage_id: 97,
		part_id: 241,
		quantity: 6,
	},
	{
		garage_id: 97,
		part_id: 242,
		quantity: 321,
	},
	{
		garage_id: 97,
		part_id: 243,
		quantity: 486,
	},
	{
		garage_id: 97,
		part_id: 244,
		quantity: 462,
	},
	{
		garage_id: 97,
		part_id: 245,
		quantity: 219,
	},
	{
		garage_id: 97,
		part_id: 246,
		quantity: 239,
	},
	{
		garage_id: 97,
		part_id: 247,
		quantity: 466,
	},
	{
		garage_id: 97,
		part_id: 248,
		quantity: 12,
	},
	{
		garage_id: 97,
		part_id: 249,
		quantity: 35,
	},
	{
		garage_id: 97,
		part_id: 250,
		quantity: 398,
	},
	{
		garage_id: 97,
		part_id: 251,
		quantity: 368,
	},
	{
		garage_id: 97,
		part_id: 252,
		quantity: 361,
	},
	{
		garage_id: 97,
		part_id: 253,
		quantity: 465,
	},
	{
		garage_id: 97,
		part_id: 254,
		quantity: 406,
	},
	{
		garage_id: 97,
		part_id: 255,
		quantity: 293,
	},
	{
		garage_id: 97,
		part_id: 256,
		quantity: 213,
	},
	{
		garage_id: 97,
		part_id: 257,
		quantity: 303,
	},
	{
		garage_id: 97,
		part_id: 258,
		quantity: 201,
	},
	{
		garage_id: 97,
		part_id: 259,
		quantity: 193,
	},
	{
		garage_id: 97,
		part_id: 260,
		quantity: 376,
	},
	{
		garage_id: 97,
		part_id: 261,
		quantity: 382,
	},
	{
		garage_id: 97,
		part_id: 262,
		quantity: 137,
	},
	{
		garage_id: 97,
		part_id: 263,
		quantity: 284,
	},
	{
		garage_id: 97,
		part_id: 264,
		quantity: 383,
	},
	{
		garage_id: 97,
		part_id: 265,
		quantity: 176,
	},
	{
		garage_id: 97,
		part_id: 266,
		quantity: 94,
	},
	{
		garage_id: 97,
		part_id: 267,
		quantity: 433,
	},
	{
		garage_id: 97,
		part_id: 268,
		quantity: 181,
	},
	{
		garage_id: 97,
		part_id: 269,
		quantity: 218,
	},
	{
		garage_id: 97,
		part_id: 270,
		quantity: 250,
	},
	{
		garage_id: 97,
		part_id: 271,
		quantity: 96,
	},
	{
		garage_id: 97,
		part_id: 272,
		quantity: 118,
	},
	{
		garage_id: 97,
		part_id: 273,
		quantity: 319,
	},
	{
		garage_id: 97,
		part_id: 274,
		quantity: 33,
	},
	{
		garage_id: 97,
		part_id: 275,
		quantity: 55,
	},
	{
		garage_id: 97,
		part_id: 276,
		quantity: 310,
	},
	{
		garage_id: 97,
		part_id: 277,
		quantity: 482,
	},
	{
		garage_id: 97,
		part_id: 278,
		quantity: 233,
	},
	{
		garage_id: 97,
		part_id: 279,
		quantity: 215,
	},
	{
		garage_id: 97,
		part_id: 280,
		quantity: 412,
	},
	{
		garage_id: 97,
		part_id: 281,
		quantity: 320,
	},
	{
		garage_id: 97,
		part_id: 282,
		quantity: 482,
	},
	{
		garage_id: 97,
		part_id: 283,
		quantity: 290,
	},
	{
		garage_id: 97,
		part_id: 284,
		quantity: 300,
	},
	{
		garage_id: 97,
		part_id: 285,
		quantity: 392,
	},
	{
		garage_id: 97,
		part_id: 286,
		quantity: 149,
	},
	{
		garage_id: 97,
		part_id: 287,
		quantity: 356,
	},
	{
		garage_id: 97,
		part_id: 288,
		quantity: 174,
	},
	{
		garage_id: 97,
		part_id: 289,
		quantity: 337,
	},
	{
		garage_id: 97,
		part_id: 290,
		quantity: 291,
	},
	{
		garage_id: 97,
		part_id: 291,
		quantity: 412,
	},
	{
		garage_id: 97,
		part_id: 292,
		quantity: 295,
	},
	{
		garage_id: 97,
		part_id: 293,
		quantity: 377,
	},
	{
		garage_id: 97,
		part_id: 294,
		quantity: 458,
	},
	{
		garage_id: 97,
		part_id: 295,
		quantity: 242,
	},
	{
		garage_id: 97,
		part_id: 296,
		quantity: 351,
	},
	{
		garage_id: 97,
		part_id: 297,
		quantity: 198,
	},
	{
		garage_id: 97,
		part_id: 298,
		quantity: 94,
	},
	{
		garage_id: 97,
		part_id: 299,
		quantity: 359,
	},
	{
		garage_id: 97,
		part_id: 300,
		quantity: 345,
	},
	{
		garage_id: 97,
		part_id: 301,
		quantity: 417,
	},
	{
		garage_id: 97,
		part_id: 302,
		quantity: 188,
	},
	{
		garage_id: 97,
		part_id: 303,
		quantity: 70,
	},
	{
		garage_id: 97,
		part_id: 304,
		quantity: 303,
	},
	{
		garage_id: 97,
		part_id: 305,
		quantity: 76,
	},
	{
		garage_id: 97,
		part_id: 306,
		quantity: 20,
	},
	{
		garage_id: 97,
		part_id: 307,
		quantity: 440,
	},
	{
		garage_id: 97,
		part_id: 308,
		quantity: 45,
	},
	{
		garage_id: 97,
		part_id: 309,
		quantity: 442,
	},
	{
		garage_id: 97,
		part_id: 310,
		quantity: 284,
	},
	{
		garage_id: 97,
		part_id: 311,
		quantity: 118,
	},
	{
		garage_id: 97,
		part_id: 312,
		quantity: 209,
	},
	{
		garage_id: 97,
		part_id: 313,
		quantity: 395,
	},
	{
		garage_id: 97,
		part_id: 314,
		quantity: 89,
	},
	{
		garage_id: 97,
		part_id: 315,
		quantity: 244,
	},
	{
		garage_id: 97,
		part_id: 316,
		quantity: 439,
	},
	{
		garage_id: 97,
		part_id: 317,
		quantity: 80,
	},
	{
		garage_id: 97,
		part_id: 318,
		quantity: 3,
	},
	{
		garage_id: 97,
		part_id: 319,
		quantity: 224,
	},
	{
		garage_id: 97,
		part_id: 320,
		quantity: 95,
	},
	{
		garage_id: 97,
		part_id: 321,
		quantity: 42,
	},
	{
		garage_id: 97,
		part_id: 322,
		quantity: 252,
	},
	{
		garage_id: 97,
		part_id: 323,
		quantity: 303,
	},
	{
		garage_id: 97,
		part_id: 324,
		quantity: 335,
	},
	{
		garage_id: 97,
		part_id: 325,
		quantity: 186,
	},
	{
		garage_id: 97,
		part_id: 326,
		quantity: 232,
	},
	{
		garage_id: 97,
		part_id: 327,
		quantity: 442,
	},
	{
		garage_id: 97,
		part_id: 328,
		quantity: 154,
	},
	{
		garage_id: 97,
		part_id: 329,
		quantity: 76,
	},
	{
		garage_id: 97,
		part_id: 330,
		quantity: 167,
	},
	{
		garage_id: 97,
		part_id: 331,
		quantity: 172,
	},
	{
		garage_id: 97,
		part_id: 332,
		quantity: 293,
	},
	{
		garage_id: 97,
		part_id: 333,
		quantity: 324,
	},
	{
		garage_id: 97,
		part_id: 334,
		quantity: 7,
	},
	{
		garage_id: 97,
		part_id: 335,
		quantity: 475,
	},
	{
		garage_id: 97,
		part_id: 336,
		quantity: 73,
	},
	{
		garage_id: 97,
		part_id: 337,
		quantity: 397,
	},
	{
		garage_id: 97,
		part_id: 338,
		quantity: 139,
	},
	{
		garage_id: 97,
		part_id: 339,
		quantity: 269,
	},
	{
		garage_id: 97,
		part_id: 340,
		quantity: 105,
	},
	{
		garage_id: 97,
		part_id: 341,
		quantity: 1,
	},
	{
		garage_id: 97,
		part_id: 342,
		quantity: 88,
	},
	{
		garage_id: 97,
		part_id: 343,
		quantity: 109,
	},
	{
		garage_id: 97,
		part_id: 344,
		quantity: 453,
	},
	{
		garage_id: 97,
		part_id: 345,
		quantity: 116,
	},
	{
		garage_id: 97,
		part_id: 346,
		quantity: 111,
	},
	{
		garage_id: 97,
		part_id: 347,
		quantity: 162,
	},
	{
		garage_id: 97,
		part_id: 348,
		quantity: 121,
	},
	{
		garage_id: 97,
		part_id: 349,
		quantity: 197,
	},
	{
		garage_id: 97,
		part_id: 350,
		quantity: 390,
	},
	{
		garage_id: 97,
		part_id: 351,
		quantity: 270,
	},
	{
		garage_id: 97,
		part_id: 352,
		quantity: 396,
	},
	{
		garage_id: 97,
		part_id: 353,
		quantity: 458,
	},
	{
		garage_id: 97,
		part_id: 354,
		quantity: 70,
	},
	{
		garage_id: 97,
		part_id: 355,
		quantity: 486,
	},
	{
		garage_id: 97,
		part_id: 356,
		quantity: 403,
	},
	{
		garage_id: 97,
		part_id: 357,
		quantity: 267,
	},
	{
		garage_id: 97,
		part_id: 358,
		quantity: 351,
	},
	{
		garage_id: 97,
		part_id: 359,
		quantity: 160,
	},
	{
		garage_id: 97,
		part_id: 360,
		quantity: 324,
	},
	{
		garage_id: 97,
		part_id: 361,
		quantity: 121,
	},
	{
		garage_id: 97,
		part_id: 362,
		quantity: 138,
	},
	{
		garage_id: 97,
		part_id: 363,
		quantity: 411,
	},
	{
		garage_id: 97,
		part_id: 364,
		quantity: 496,
	},
	{
		garage_id: 97,
		part_id: 365,
		quantity: 368,
	},
	{
		garage_id: 97,
		part_id: 366,
		quantity: 64,
	},
	{
		garage_id: 97,
		part_id: 367,
		quantity: 143,
	},
	{
		garage_id: 97,
		part_id: 368,
		quantity: 459,
	},
	{
		garage_id: 97,
		part_id: 369,
		quantity: 381,
	},
	{
		garage_id: 97,
		part_id: 370,
		quantity: 334,
	},
	{
		garage_id: 97,
		part_id: 371,
		quantity: 144,
	},
	{
		garage_id: 97,
		part_id: 372,
		quantity: 165,
	},
	{
		garage_id: 97,
		part_id: 373,
		quantity: 188,
	},
	{
		garage_id: 97,
		part_id: 374,
		quantity: 500,
	},
	{
		garage_id: 97,
		part_id: 375,
		quantity: 58,
	},
	{
		garage_id: 97,
		part_id: 376,
		quantity: 94,
	},
	{
		garage_id: 97,
		part_id: 377,
		quantity: 443,
	},
	{
		garage_id: 97,
		part_id: 378,
		quantity: 237,
	},
	{
		garage_id: 97,
		part_id: 379,
		quantity: 344,
	},
	{
		garage_id: 97,
		part_id: 380,
		quantity: 139,
	},
	{
		garage_id: 97,
		part_id: 381,
		quantity: 266,
	},
	{
		garage_id: 97,
		part_id: 382,
		quantity: 324,
	},
	{
		garage_id: 97,
		part_id: 383,
		quantity: 201,
	},
	{
		garage_id: 97,
		part_id: 384,
		quantity: 49,
	},
	{
		garage_id: 97,
		part_id: 385,
		quantity: 377,
	},
	{
		garage_id: 97,
		part_id: 386,
		quantity: 409,
	},
	{
		garage_id: 97,
		part_id: 387,
		quantity: 315,
	},
	{
		garage_id: 97,
		part_id: 388,
		quantity: 55,
	},
	{
		garage_id: 97,
		part_id: 389,
		quantity: 218,
	},
	{
		garage_id: 97,
		part_id: 390,
		quantity: 449,
	},
	{
		garage_id: 97,
		part_id: 391,
		quantity: 497,
	},
	{
		garage_id: 97,
		part_id: 392,
		quantity: 417,
	},
	{
		garage_id: 97,
		part_id: 393,
		quantity: 338,
	},
	{
		garage_id: 97,
		part_id: 394,
		quantity: 36,
	},
	{
		garage_id: 97,
		part_id: 395,
		quantity: 422,
	},
	{
		garage_id: 97,
		part_id: 396,
		quantity: 118,
	},
	{
		garage_id: 97,
		part_id: 397,
		quantity: 392,
	},
	{
		garage_id: 97,
		part_id: 398,
		quantity: 356,
	},
	{
		garage_id: 97,
		part_id: 399,
		quantity: 220,
	},
	{
		garage_id: 98,
		part_id: 0,
		quantity: 204,
	},
	{
		garage_id: 98,
		part_id: 1,
		quantity: 133,
	},
	{
		garage_id: 98,
		part_id: 2,
		quantity: 40,
	},
	{
		garage_id: 98,
		part_id: 3,
		quantity: 31,
	},
	{
		garage_id: 98,
		part_id: 4,
		quantity: 108,
	},
	{
		garage_id: 98,
		part_id: 5,
		quantity: 206,
	},
	{
		garage_id: 98,
		part_id: 6,
		quantity: 338,
	},
	{
		garage_id: 98,
		part_id: 7,
		quantity: 342,
	},
	{
		garage_id: 98,
		part_id: 8,
		quantity: 1,
	},
	{
		garage_id: 98,
		part_id: 9,
		quantity: 498,
	},
	{
		garage_id: 98,
		part_id: 10,
		quantity: 21,
	},
	{
		garage_id: 98,
		part_id: 11,
		quantity: 125,
	},
	{
		garage_id: 98,
		part_id: 12,
		quantity: 395,
	},
	{
		garage_id: 98,
		part_id: 13,
		quantity: 62,
	},
	{
		garage_id: 98,
		part_id: 14,
		quantity: 417,
	},
	{
		garage_id: 98,
		part_id: 15,
		quantity: 187,
	},
	{
		garage_id: 98,
		part_id: 16,
		quantity: 131,
	},
	{
		garage_id: 98,
		part_id: 17,
		quantity: 264,
	},
	{
		garage_id: 98,
		part_id: 18,
		quantity: 375,
	},
	{
		garage_id: 98,
		part_id: 19,
		quantity: 323,
	},
	{
		garage_id: 98,
		part_id: 20,
		quantity: 150,
	},
	{
		garage_id: 98,
		part_id: 21,
		quantity: 60,
	},
	{
		garage_id: 98,
		part_id: 22,
		quantity: 146,
	},
	{
		garage_id: 98,
		part_id: 23,
		quantity: 162,
	},
	{
		garage_id: 98,
		part_id: 24,
		quantity: 159,
	},
	{
		garage_id: 98,
		part_id: 25,
		quantity: 285,
	},
	{
		garage_id: 98,
		part_id: 26,
		quantity: 35,
	},
	{
		garage_id: 98,
		part_id: 27,
		quantity: 277,
	},
	{
		garage_id: 98,
		part_id: 28,
		quantity: 278,
	},
	{
		garage_id: 98,
		part_id: 29,
		quantity: 489,
	},
	{
		garage_id: 98,
		part_id: 30,
		quantity: 357,
	},
	{
		garage_id: 98,
		part_id: 31,
		quantity: 496,
	},
	{
		garage_id: 98,
		part_id: 32,
		quantity: 155,
	},
	{
		garage_id: 98,
		part_id: 33,
		quantity: 385,
	},
	{
		garage_id: 98,
		part_id: 34,
		quantity: 226,
	},
	{
		garage_id: 98,
		part_id: 35,
		quantity: 432,
	},
	{
		garage_id: 98,
		part_id: 36,
		quantity: 138,
	},
	{
		garage_id: 98,
		part_id: 37,
		quantity: 480,
	},
	{
		garage_id: 98,
		part_id: 38,
		quantity: 499,
	},
	{
		garage_id: 98,
		part_id: 39,
		quantity: 178,
	},
	{
		garage_id: 98,
		part_id: 40,
		quantity: 54,
	},
	{
		garage_id: 98,
		part_id: 41,
		quantity: 227,
	},
	{
		garage_id: 98,
		part_id: 42,
		quantity: 202,
	},
	{
		garage_id: 98,
		part_id: 43,
		quantity: 389,
	},
	{
		garage_id: 98,
		part_id: 44,
		quantity: 313,
	},
	{
		garage_id: 98,
		part_id: 45,
		quantity: 460,
	},
	{
		garage_id: 98,
		part_id: 46,
		quantity: 387,
	},
	{
		garage_id: 98,
		part_id: 47,
		quantity: 276,
	},
	{
		garage_id: 98,
		part_id: 48,
		quantity: 439,
	},
	{
		garage_id: 98,
		part_id: 49,
		quantity: 135,
	},
	{
		garage_id: 98,
		part_id: 50,
		quantity: 256,
	},
	{
		garage_id: 98,
		part_id: 51,
		quantity: 177,
	},
	{
		garage_id: 98,
		part_id: 52,
		quantity: 253,
	},
	{
		garage_id: 98,
		part_id: 53,
		quantity: 26,
	},
	{
		garage_id: 98,
		part_id: 54,
		quantity: 279,
	},
	{
		garage_id: 98,
		part_id: 55,
		quantity: 474,
	},
	{
		garage_id: 98,
		part_id: 56,
		quantity: 243,
	},
	{
		garage_id: 98,
		part_id: 57,
		quantity: 438,
	},
	{
		garage_id: 98,
		part_id: 58,
		quantity: 16,
	},
	{
		garage_id: 98,
		part_id: 59,
		quantity: 252,
	},
	{
		garage_id: 98,
		part_id: 60,
		quantity: 170,
	},
	{
		garage_id: 98,
		part_id: 61,
		quantity: 246,
	},
	{
		garage_id: 98,
		part_id: 62,
		quantity: 439,
	},
	{
		garage_id: 98,
		part_id: 63,
		quantity: 305,
	},
	{
		garage_id: 98,
		part_id: 64,
		quantity: 474,
	},
	{
		garage_id: 98,
		part_id: 65,
		quantity: 402,
	},
	{
		garage_id: 98,
		part_id: 66,
		quantity: 72,
	},
	{
		garage_id: 98,
		part_id: 67,
		quantity: 254,
	},
	{
		garage_id: 98,
		part_id: 68,
		quantity: 412,
	},
	{
		garage_id: 98,
		part_id: 69,
		quantity: 28,
	},
	{
		garage_id: 98,
		part_id: 70,
		quantity: 102,
	},
	{
		garage_id: 98,
		part_id: 71,
		quantity: 3,
	},
	{
		garage_id: 98,
		part_id: 72,
		quantity: 279,
	},
	{
		garage_id: 98,
		part_id: 73,
		quantity: 137,
	},
	{
		garage_id: 98,
		part_id: 74,
		quantity: 152,
	},
	{
		garage_id: 98,
		part_id: 75,
		quantity: 303,
	},
	{
		garage_id: 98,
		part_id: 76,
		quantity: 8,
	},
	{
		garage_id: 98,
		part_id: 77,
		quantity: 494,
	},
	{
		garage_id: 98,
		part_id: 78,
		quantity: 292,
	},
	{
		garage_id: 98,
		part_id: 79,
		quantity: 478,
	},
	{
		garage_id: 98,
		part_id: 80,
		quantity: 241,
	},
	{
		garage_id: 98,
		part_id: 81,
		quantity: 192,
	},
	{
		garage_id: 98,
		part_id: 82,
		quantity: 485,
	},
	{
		garage_id: 98,
		part_id: 83,
		quantity: 480,
	},
	{
		garage_id: 98,
		part_id: 84,
		quantity: 4,
	},
	{
		garage_id: 98,
		part_id: 85,
		quantity: 279,
	},
	{
		garage_id: 98,
		part_id: 86,
		quantity: 324,
	},
	{
		garage_id: 98,
		part_id: 87,
		quantity: 172,
	},
	{
		garage_id: 98,
		part_id: 88,
		quantity: 486,
	},
	{
		garage_id: 98,
		part_id: 89,
		quantity: 27,
	},
	{
		garage_id: 98,
		part_id: 90,
		quantity: 291,
	},
	{
		garage_id: 98,
		part_id: 91,
		quantity: 92,
	},
	{
		garage_id: 98,
		part_id: 92,
		quantity: 489,
	},
	{
		garage_id: 98,
		part_id: 93,
		quantity: 354,
	},
	{
		garage_id: 98,
		part_id: 94,
		quantity: 460,
	},
	{
		garage_id: 98,
		part_id: 95,
		quantity: 199,
	},
	{
		garage_id: 98,
		part_id: 96,
		quantity: 370,
	},
	{
		garage_id: 98,
		part_id: 97,
		quantity: 118,
	},
	{
		garage_id: 98,
		part_id: 98,
		quantity: 378,
	},
	{
		garage_id: 98,
		part_id: 99,
		quantity: 223,
	},
	{
		garage_id: 98,
		part_id: 100,
		quantity: 204,
	},
	{
		garage_id: 98,
		part_id: 101,
		quantity: 445,
	},
	{
		garage_id: 98,
		part_id: 102,
		quantity: 348,
	},
	{
		garage_id: 98,
		part_id: 103,
		quantity: 354,
	},
	{
		garage_id: 98,
		part_id: 104,
		quantity: 108,
	},
	{
		garage_id: 98,
		part_id: 105,
		quantity: 495,
	},
	{
		garage_id: 98,
		part_id: 106,
		quantity: 462,
	},
	{
		garage_id: 98,
		part_id: 107,
		quantity: 421,
	},
	{
		garage_id: 98,
		part_id: 108,
		quantity: 497,
	},
	{
		garage_id: 98,
		part_id: 109,
		quantity: 380,
	},
	{
		garage_id: 98,
		part_id: 110,
		quantity: 100,
	},
	{
		garage_id: 98,
		part_id: 111,
		quantity: 150,
	},
	{
		garage_id: 98,
		part_id: 112,
		quantity: 211,
	},
	{
		garage_id: 98,
		part_id: 113,
		quantity: 258,
	},
	{
		garage_id: 98,
		part_id: 114,
		quantity: 256,
	},
	{
		garage_id: 98,
		part_id: 115,
		quantity: 377,
	},
	{
		garage_id: 98,
		part_id: 116,
		quantity: 338,
	},
	{
		garage_id: 98,
		part_id: 117,
		quantity: 466,
	},
	{
		garage_id: 98,
		part_id: 118,
		quantity: 496,
	},
	{
		garage_id: 98,
		part_id: 119,
		quantity: 107,
	},
	{
		garage_id: 98,
		part_id: 120,
		quantity: 368,
	},
	{
		garage_id: 98,
		part_id: 121,
		quantity: 291,
	},
	{
		garage_id: 98,
		part_id: 122,
		quantity: 113,
	},
	{
		garage_id: 98,
		part_id: 123,
		quantity: 453,
	},
	{
		garage_id: 98,
		part_id: 124,
		quantity: 188,
	},
	{
		garage_id: 98,
		part_id: 125,
		quantity: 157,
	},
	{
		garage_id: 98,
		part_id: 126,
		quantity: 460,
	},
	{
		garage_id: 98,
		part_id: 127,
		quantity: 380,
	},
	{
		garage_id: 98,
		part_id: 128,
		quantity: 184,
	},
	{
		garage_id: 98,
		part_id: 129,
		quantity: 74,
	},
	{
		garage_id: 98,
		part_id: 130,
		quantity: 99,
	},
	{
		garage_id: 98,
		part_id: 131,
		quantity: 181,
	},
	{
		garage_id: 98,
		part_id: 132,
		quantity: 425,
	},
	{
		garage_id: 98,
		part_id: 133,
		quantity: 200,
	},
	{
		garage_id: 98,
		part_id: 134,
		quantity: 162,
	},
	{
		garage_id: 98,
		part_id: 135,
		quantity: 139,
	},
	{
		garage_id: 98,
		part_id: 136,
		quantity: 255,
	},
	{
		garage_id: 98,
		part_id: 137,
		quantity: 180,
	},
	{
		garage_id: 98,
		part_id: 138,
		quantity: 394,
	},
	{
		garage_id: 98,
		part_id: 139,
		quantity: 339,
	},
	{
		garage_id: 98,
		part_id: 140,
		quantity: 297,
	},
	{
		garage_id: 98,
		part_id: 141,
		quantity: 368,
	},
	{
		garage_id: 98,
		part_id: 142,
		quantity: 462,
	},
	{
		garage_id: 98,
		part_id: 143,
		quantity: 363,
	},
	{
		garage_id: 98,
		part_id: 144,
		quantity: 360,
	},
	{
		garage_id: 98,
		part_id: 145,
		quantity: 232,
	},
	{
		garage_id: 98,
		part_id: 146,
		quantity: 356,
	},
	{
		garage_id: 98,
		part_id: 147,
		quantity: 270,
	},
	{
		garage_id: 98,
		part_id: 148,
		quantity: 142,
	},
	{
		garage_id: 98,
		part_id: 149,
		quantity: 271,
	},
	{
		garage_id: 98,
		part_id: 150,
		quantity: 427,
	},
	{
		garage_id: 98,
		part_id: 151,
		quantity: 262,
	},
	{
		garage_id: 98,
		part_id: 152,
		quantity: 144,
	},
	{
		garage_id: 98,
		part_id: 153,
		quantity: 121,
	},
	{
		garage_id: 98,
		part_id: 154,
		quantity: 338,
	},
	{
		garage_id: 98,
		part_id: 155,
		quantity: 192,
	},
	{
		garage_id: 98,
		part_id: 156,
		quantity: 310,
	},
	{
		garage_id: 98,
		part_id: 157,
		quantity: 242,
	},
	{
		garage_id: 98,
		part_id: 158,
		quantity: 99,
	},
	{
		garage_id: 98,
		part_id: 159,
		quantity: 475,
	},
	{
		garage_id: 98,
		part_id: 160,
		quantity: 264,
	},
	{
		garage_id: 98,
		part_id: 161,
		quantity: 481,
	},
	{
		garage_id: 98,
		part_id: 162,
		quantity: 74,
	},
	{
		garage_id: 98,
		part_id: 163,
		quantity: 347,
	},
	{
		garage_id: 98,
		part_id: 164,
		quantity: 388,
	},
	{
		garage_id: 98,
		part_id: 165,
		quantity: 210,
	},
	{
		garage_id: 98,
		part_id: 166,
		quantity: 489,
	},
	{
		garage_id: 98,
		part_id: 167,
		quantity: 274,
	},
	{
		garage_id: 98,
		part_id: 168,
		quantity: 253,
	},
	{
		garage_id: 98,
		part_id: 169,
		quantity: 245,
	},
	{
		garage_id: 98,
		part_id: 170,
		quantity: 198,
	},
	{
		garage_id: 98,
		part_id: 171,
		quantity: 275,
	},
	{
		garage_id: 98,
		part_id: 172,
		quantity: 300,
	},
	{
		garage_id: 98,
		part_id: 173,
		quantity: 74,
	},
	{
		garage_id: 98,
		part_id: 174,
		quantity: 166,
	},
	{
		garage_id: 98,
		part_id: 175,
		quantity: 135,
	},
	{
		garage_id: 98,
		part_id: 176,
		quantity: 77,
	},
	{
		garage_id: 98,
		part_id: 177,
		quantity: 291,
	},
	{
		garage_id: 98,
		part_id: 178,
		quantity: 65,
	},
	{
		garage_id: 98,
		part_id: 179,
		quantity: 182,
	},
	{
		garage_id: 98,
		part_id: 180,
		quantity: 235,
	},
	{
		garage_id: 98,
		part_id: 181,
		quantity: 392,
	},
	{
		garage_id: 98,
		part_id: 182,
		quantity: 459,
	},
	{
		garage_id: 98,
		part_id: 183,
		quantity: 234,
	},
	{
		garage_id: 98,
		part_id: 184,
		quantity: 112,
	},
	{
		garage_id: 98,
		part_id: 185,
		quantity: 146,
	},
	{
		garage_id: 98,
		part_id: 186,
		quantity: 47,
	},
	{
		garage_id: 98,
		part_id: 187,
		quantity: 4,
	},
	{
		garage_id: 98,
		part_id: 188,
		quantity: 306,
	},
	{
		garage_id: 98,
		part_id: 189,
		quantity: 139,
	},
	{
		garage_id: 98,
		part_id: 190,
		quantity: 204,
	},
	{
		garage_id: 98,
		part_id: 191,
		quantity: 189,
	},
	{
		garage_id: 98,
		part_id: 192,
		quantity: 140,
	},
	{
		garage_id: 98,
		part_id: 193,
		quantity: 448,
	},
	{
		garage_id: 98,
		part_id: 194,
		quantity: 70,
	},
	{
		garage_id: 98,
		part_id: 195,
		quantity: 155,
	},
	{
		garage_id: 98,
		part_id: 196,
		quantity: 230,
	},
	{
		garage_id: 98,
		part_id: 197,
		quantity: 115,
	},
	{
		garage_id: 98,
		part_id: 198,
		quantity: 321,
	},
	{
		garage_id: 98,
		part_id: 199,
		quantity: 27,
	},
	{
		garage_id: 98,
		part_id: 200,
		quantity: 154,
	},
	{
		garage_id: 98,
		part_id: 201,
		quantity: 56,
	},
	{
		garage_id: 98,
		part_id: 202,
		quantity: 334,
	},
	{
		garage_id: 98,
		part_id: 203,
		quantity: 465,
	},
	{
		garage_id: 98,
		part_id: 204,
		quantity: 242,
	},
	{
		garage_id: 98,
		part_id: 205,
		quantity: 142,
	},
	{
		garage_id: 98,
		part_id: 206,
		quantity: 291,
	},
	{
		garage_id: 98,
		part_id: 207,
		quantity: 243,
	},
	{
		garage_id: 98,
		part_id: 208,
		quantity: 489,
	},
	{
		garage_id: 98,
		part_id: 209,
		quantity: 252,
	},
	{
		garage_id: 98,
		part_id: 210,
		quantity: 451,
	},
	{
		garage_id: 98,
		part_id: 211,
		quantity: 113,
	},
	{
		garage_id: 98,
		part_id: 212,
		quantity: 380,
	},
	{
		garage_id: 98,
		part_id: 213,
		quantity: 176,
	},
	{
		garage_id: 98,
		part_id: 214,
		quantity: 209,
	},
	{
		garage_id: 98,
		part_id: 215,
		quantity: 192,
	},
	{
		garage_id: 98,
		part_id: 216,
		quantity: 494,
	},
	{
		garage_id: 98,
		part_id: 217,
		quantity: 284,
	},
	{
		garage_id: 98,
		part_id: 218,
		quantity: 46,
	},
	{
		garage_id: 98,
		part_id: 219,
		quantity: 241,
	},
	{
		garage_id: 98,
		part_id: 220,
		quantity: 279,
	},
	{
		garage_id: 98,
		part_id: 221,
		quantity: 1,
	},
	{
		garage_id: 98,
		part_id: 222,
		quantity: 108,
	},
	{
		garage_id: 98,
		part_id: 223,
		quantity: 299,
	},
	{
		garage_id: 98,
		part_id: 224,
		quantity: 407,
	},
	{
		garage_id: 98,
		part_id: 225,
		quantity: 308,
	},
	{
		garage_id: 98,
		part_id: 226,
		quantity: 346,
	},
	{
		garage_id: 98,
		part_id: 227,
		quantity: 426,
	},
	{
		garage_id: 98,
		part_id: 228,
		quantity: 387,
	},
	{
		garage_id: 98,
		part_id: 229,
		quantity: 290,
	},
	{
		garage_id: 98,
		part_id: 230,
		quantity: 251,
	},
	{
		garage_id: 98,
		part_id: 231,
		quantity: 268,
	},
	{
		garage_id: 98,
		part_id: 232,
		quantity: 246,
	},
	{
		garage_id: 98,
		part_id: 233,
		quantity: 415,
	},
	{
		garage_id: 98,
		part_id: 234,
		quantity: 189,
	},
	{
		garage_id: 98,
		part_id: 235,
		quantity: 170,
	},
	{
		garage_id: 98,
		part_id: 236,
		quantity: 193,
	},
	{
		garage_id: 98,
		part_id: 237,
		quantity: 432,
	},
	{
		garage_id: 98,
		part_id: 238,
		quantity: 96,
	},
	{
		garage_id: 98,
		part_id: 239,
		quantity: 368,
	},
	{
		garage_id: 98,
		part_id: 240,
		quantity: 447,
	},
	{
		garage_id: 98,
		part_id: 241,
		quantity: 285,
	},
	{
		garage_id: 98,
		part_id: 242,
		quantity: 365,
	},
	{
		garage_id: 98,
		part_id: 243,
		quantity: 1,
	},
	{
		garage_id: 98,
		part_id: 244,
		quantity: 155,
	},
	{
		garage_id: 98,
		part_id: 245,
		quantity: 48,
	},
	{
		garage_id: 98,
		part_id: 246,
		quantity: 204,
	},
	{
		garage_id: 98,
		part_id: 247,
		quantity: 140,
	},
	{
		garage_id: 98,
		part_id: 248,
		quantity: 378,
	},
	{
		garage_id: 98,
		part_id: 249,
		quantity: 34,
	},
	{
		garage_id: 98,
		part_id: 250,
		quantity: 11,
	},
	{
		garage_id: 98,
		part_id: 251,
		quantity: 469,
	},
	{
		garage_id: 98,
		part_id: 252,
		quantity: 324,
	},
	{
		garage_id: 98,
		part_id: 253,
		quantity: 53,
	},
	{
		garage_id: 98,
		part_id: 254,
		quantity: 328,
	},
	{
		garage_id: 98,
		part_id: 255,
		quantity: 144,
	},
	{
		garage_id: 98,
		part_id: 256,
		quantity: 273,
	},
	{
		garage_id: 98,
		part_id: 257,
		quantity: 152,
	},
	{
		garage_id: 98,
		part_id: 258,
		quantity: 389,
	},
	{
		garage_id: 98,
		part_id: 259,
		quantity: 145,
	},
	{
		garage_id: 98,
		part_id: 260,
		quantity: 131,
	},
	{
		garage_id: 98,
		part_id: 261,
		quantity: 359,
	},
	{
		garage_id: 98,
		part_id: 262,
		quantity: 470,
	},
	{
		garage_id: 98,
		part_id: 263,
		quantity: 290,
	},
	{
		garage_id: 98,
		part_id: 264,
		quantity: 297,
	},
	{
		garage_id: 98,
		part_id: 265,
		quantity: 125,
	},
	{
		garage_id: 98,
		part_id: 266,
		quantity: 55,
	},
	{
		garage_id: 98,
		part_id: 267,
		quantity: 272,
	},
	{
		garage_id: 98,
		part_id: 268,
		quantity: 297,
	},
	{
		garage_id: 98,
		part_id: 269,
		quantity: 476,
	},
	{
		garage_id: 98,
		part_id: 270,
		quantity: 350,
	},
	{
		garage_id: 98,
		part_id: 271,
		quantity: 209,
	},
	{
		garage_id: 98,
		part_id: 272,
		quantity: 449,
	},
	{
		garage_id: 98,
		part_id: 273,
		quantity: 277,
	},
	{
		garage_id: 98,
		part_id: 274,
		quantity: 127,
	},
	{
		garage_id: 98,
		part_id: 275,
		quantity: 30,
	},
	{
		garage_id: 98,
		part_id: 276,
		quantity: 372,
	},
	{
		garage_id: 98,
		part_id: 277,
		quantity: 75,
	},
	{
		garage_id: 98,
		part_id: 278,
		quantity: 50,
	},
	{
		garage_id: 98,
		part_id: 279,
		quantity: 316,
	},
	{
		garage_id: 98,
		part_id: 280,
		quantity: 471,
	},
	{
		garage_id: 98,
		part_id: 281,
		quantity: 57,
	},
	{
		garage_id: 98,
		part_id: 282,
		quantity: 471,
	},
	{
		garage_id: 98,
		part_id: 283,
		quantity: 358,
	},
	{
		garage_id: 98,
		part_id: 284,
		quantity: 139,
	},
	{
		garage_id: 98,
		part_id: 285,
		quantity: 215,
	},
	{
		garage_id: 98,
		part_id: 286,
		quantity: 354,
	},
	{
		garage_id: 98,
		part_id: 287,
		quantity: 172,
	},
	{
		garage_id: 98,
		part_id: 288,
		quantity: 215,
	},
	{
		garage_id: 98,
		part_id: 289,
		quantity: 465,
	},
	{
		garage_id: 98,
		part_id: 290,
		quantity: 385,
	},
	{
		garage_id: 98,
		part_id: 291,
		quantity: 410,
	},
	{
		garage_id: 98,
		part_id: 292,
		quantity: 193,
	},
	{
		garage_id: 98,
		part_id: 293,
		quantity: 358,
	},
	{
		garage_id: 98,
		part_id: 294,
		quantity: 231,
	},
	{
		garage_id: 98,
		part_id: 295,
		quantity: 156,
	},
	{
		garage_id: 98,
		part_id: 296,
		quantity: 233,
	},
	{
		garage_id: 98,
		part_id: 297,
		quantity: 59,
	},
	{
		garage_id: 98,
		part_id: 298,
		quantity: 386,
	},
	{
		garage_id: 98,
		part_id: 299,
		quantity: 229,
	},
	{
		garage_id: 98,
		part_id: 300,
		quantity: 301,
	},
	{
		garage_id: 98,
		part_id: 301,
		quantity: 206,
	},
	{
		garage_id: 98,
		part_id: 302,
		quantity: 393,
	},
	{
		garage_id: 98,
		part_id: 303,
		quantity: 16,
	},
	{
		garage_id: 98,
		part_id: 304,
		quantity: 234,
	},
	{
		garage_id: 98,
		part_id: 305,
		quantity: 14,
	},
	{
		garage_id: 98,
		part_id: 306,
		quantity: 175,
	},
	{
		garage_id: 98,
		part_id: 307,
		quantity: 424,
	},
	{
		garage_id: 98,
		part_id: 308,
		quantity: 401,
	},
	{
		garage_id: 98,
		part_id: 309,
		quantity: 20,
	},
	{
		garage_id: 98,
		part_id: 310,
		quantity: 32,
	},
	{
		garage_id: 98,
		part_id: 311,
		quantity: 459,
	},
	{
		garage_id: 98,
		part_id: 312,
		quantity: 286,
	},
	{
		garage_id: 98,
		part_id: 313,
		quantity: 272,
	},
	{
		garage_id: 98,
		part_id: 314,
		quantity: 29,
	},
	{
		garage_id: 98,
		part_id: 315,
		quantity: 77,
	},
	{
		garage_id: 98,
		part_id: 316,
		quantity: 106,
	},
	{
		garage_id: 98,
		part_id: 317,
		quantity: 498,
	},
	{
		garage_id: 98,
		part_id: 318,
		quantity: 66,
	},
	{
		garage_id: 98,
		part_id: 319,
		quantity: 173,
	},
	{
		garage_id: 98,
		part_id: 320,
		quantity: 297,
	},
	{
		garage_id: 98,
		part_id: 321,
		quantity: 228,
	},
	{
		garage_id: 98,
		part_id: 322,
		quantity: 107,
	},
	{
		garage_id: 98,
		part_id: 323,
		quantity: 215,
	},
	{
		garage_id: 98,
		part_id: 324,
		quantity: 221,
	},
	{
		garage_id: 98,
		part_id: 325,
		quantity: 268,
	},
	{
		garage_id: 98,
		part_id: 326,
		quantity: 243,
	},
	{
		garage_id: 98,
		part_id: 327,
		quantity: 345,
	},
	{
		garage_id: 98,
		part_id: 328,
		quantity: 403,
	},
	{
		garage_id: 98,
		part_id: 329,
		quantity: 176,
	},
	{
		garage_id: 98,
		part_id: 330,
		quantity: 174,
	},
	{
		garage_id: 98,
		part_id: 331,
		quantity: 380,
	},
	{
		garage_id: 98,
		part_id: 332,
		quantity: 182,
	},
	{
		garage_id: 98,
		part_id: 333,
		quantity: 196,
	},
	{
		garage_id: 98,
		part_id: 334,
		quantity: 242,
	},
	{
		garage_id: 98,
		part_id: 335,
		quantity: 481,
	},
	{
		garage_id: 98,
		part_id: 336,
		quantity: 60,
	},
	{
		garage_id: 98,
		part_id: 337,
		quantity: 18,
	},
	{
		garage_id: 98,
		part_id: 338,
		quantity: 377,
	},
	{
		garage_id: 98,
		part_id: 339,
		quantity: 351,
	},
	{
		garage_id: 98,
		part_id: 340,
		quantity: 308,
	},
	{
		garage_id: 98,
		part_id: 341,
		quantity: 479,
	},
	{
		garage_id: 98,
		part_id: 342,
		quantity: 80,
	},
	{
		garage_id: 98,
		part_id: 343,
		quantity: 229,
	},
	{
		garage_id: 98,
		part_id: 344,
		quantity: 115,
	},
	{
		garage_id: 98,
		part_id: 345,
		quantity: 71,
	},
	{
		garage_id: 98,
		part_id: 346,
		quantity: 133,
	},
	{
		garage_id: 98,
		part_id: 347,
		quantity: 258,
	},
	{
		garage_id: 98,
		part_id: 348,
		quantity: 322,
	},
	{
		garage_id: 98,
		part_id: 349,
		quantity: 426,
	},
	{
		garage_id: 98,
		part_id: 350,
		quantity: 31,
	},
	{
		garage_id: 98,
		part_id: 351,
		quantity: 485,
	},
	{
		garage_id: 98,
		part_id: 352,
		quantity: 149,
	},
	{
		garage_id: 98,
		part_id: 353,
		quantity: 86,
	},
	{
		garage_id: 98,
		part_id: 354,
		quantity: 440,
	},
	{
		garage_id: 98,
		part_id: 355,
		quantity: 369,
	},
	{
		garage_id: 98,
		part_id: 356,
		quantity: 132,
	},
	{
		garage_id: 98,
		part_id: 357,
		quantity: 60,
	},
	{
		garage_id: 98,
		part_id: 358,
		quantity: 427,
	},
	{
		garage_id: 98,
		part_id: 359,
		quantity: 197,
	},
	{
		garage_id: 98,
		part_id: 360,
		quantity: 99,
	},
	{
		garage_id: 98,
		part_id: 361,
		quantity: 329,
	},
	{
		garage_id: 98,
		part_id: 362,
		quantity: 332,
	},
	{
		garage_id: 98,
		part_id: 363,
		quantity: 352,
	},
	{
		garage_id: 98,
		part_id: 364,
		quantity: 335,
	},
	{
		garage_id: 98,
		part_id: 365,
		quantity: 144,
	},
	{
		garage_id: 98,
		part_id: 366,
		quantity: 326,
	},
	{
		garage_id: 98,
		part_id: 367,
		quantity: 113,
	},
	{
		garage_id: 98,
		part_id: 368,
		quantity: 142,
	},
	{
		garage_id: 98,
		part_id: 369,
		quantity: 188,
	},
	{
		garage_id: 98,
		part_id: 370,
		quantity: 230,
	},
	{
		garage_id: 98,
		part_id: 371,
		quantity: 407,
	},
	{
		garage_id: 98,
		part_id: 372,
		quantity: 341,
	},
	{
		garage_id: 98,
		part_id: 373,
		quantity: 384,
	},
	{
		garage_id: 98,
		part_id: 374,
		quantity: 416,
	},
	{
		garage_id: 98,
		part_id: 375,
		quantity: 225,
	},
	{
		garage_id: 98,
		part_id: 376,
		quantity: 393,
	},
	{
		garage_id: 98,
		part_id: 377,
		quantity: 140,
	},
	{
		garage_id: 98,
		part_id: 378,
		quantity: 457,
	},
	{
		garage_id: 98,
		part_id: 379,
		quantity: 205,
	},
	{
		garage_id: 98,
		part_id: 380,
		quantity: 115,
	},
	{
		garage_id: 98,
		part_id: 381,
		quantity: 258,
	},
	{
		garage_id: 98,
		part_id: 382,
		quantity: 339,
	},
	{
		garage_id: 98,
		part_id: 383,
		quantity: 467,
	},
	{
		garage_id: 98,
		part_id: 384,
		quantity: 450,
	},
	{
		garage_id: 98,
		part_id: 385,
		quantity: 50,
	},
	{
		garage_id: 98,
		part_id: 386,
		quantity: 282,
	},
	{
		garage_id: 98,
		part_id: 387,
		quantity: 329,
	},
	{
		garage_id: 98,
		part_id: 388,
		quantity: 373,
	},
	{
		garage_id: 98,
		part_id: 389,
		quantity: 54,
	},
	{
		garage_id: 98,
		part_id: 390,
		quantity: 109,
	},
	{
		garage_id: 98,
		part_id: 391,
		quantity: 328,
	},
	{
		garage_id: 98,
		part_id: 392,
		quantity: 14,
	},
	{
		garage_id: 98,
		part_id: 393,
		quantity: 77,
	},
	{
		garage_id: 98,
		part_id: 394,
		quantity: 165,
	},
	{
		garage_id: 98,
		part_id: 395,
		quantity: 417,
	},
	{
		garage_id: 98,
		part_id: 396,
		quantity: 388,
	},
	{
		garage_id: 98,
		part_id: 397,
		quantity: 164,
	},
	{
		garage_id: 98,
		part_id: 398,
		quantity: 129,
	},
	{
		garage_id: 98,
		part_id: 399,
		quantity: 476,
	},
	{
		garage_id: 99,
		part_id: 0,
		quantity: 229,
	},
	{
		garage_id: 99,
		part_id: 1,
		quantity: 136,
	},
	{
		garage_id: 99,
		part_id: 2,
		quantity: 320,
	},
	{
		garage_id: 99,
		part_id: 3,
		quantity: 194,
	},
	{
		garage_id: 99,
		part_id: 4,
		quantity: 169,
	},
	{
		garage_id: 99,
		part_id: 5,
		quantity: 480,
	},
	{
		garage_id: 99,
		part_id: 6,
		quantity: 19,
	},
	{
		garage_id: 99,
		part_id: 7,
		quantity: 108,
	},
	{
		garage_id: 99,
		part_id: 8,
		quantity: 340,
	},
	{
		garage_id: 99,
		part_id: 9,
		quantity: 486,
	},
	{
		garage_id: 99,
		part_id: 10,
		quantity: 479,
	},
	{
		garage_id: 99,
		part_id: 11,
		quantity: 81,
	},
	{
		garage_id: 99,
		part_id: 12,
		quantity: 338,
	},
	{
		garage_id: 99,
		part_id: 13,
		quantity: 154,
	},
	{
		garage_id: 99,
		part_id: 14,
		quantity: 498,
	},
	{
		garage_id: 99,
		part_id: 15,
		quantity: 273,
	},
	{
		garage_id: 99,
		part_id: 16,
		quantity: 498,
	},
	{
		garage_id: 99,
		part_id: 17,
		quantity: 56,
	},
	{
		garage_id: 99,
		part_id: 18,
		quantity: 273,
	},
	{
		garage_id: 99,
		part_id: 19,
		quantity: 452,
	},
	{
		garage_id: 99,
		part_id: 20,
		quantity: 445,
	},
	{
		garage_id: 99,
		part_id: 21,
		quantity: 88,
	},
	{
		garage_id: 99,
		part_id: 22,
		quantity: 9,
	},
	{
		garage_id: 99,
		part_id: 23,
		quantity: 350,
	},
	{
		garage_id: 99,
		part_id: 24,
		quantity: 454,
	},
	{
		garage_id: 99,
		part_id: 25,
		quantity: 105,
	},
	{
		garage_id: 99,
		part_id: 26,
		quantity: 289,
	},
	{
		garage_id: 99,
		part_id: 27,
		quantity: 442,
	},
	{
		garage_id: 99,
		part_id: 28,
		quantity: 234,
	},
	{
		garage_id: 99,
		part_id: 29,
		quantity: 127,
	},
	{
		garage_id: 99,
		part_id: 30,
		quantity: 439,
	},
	{
		garage_id: 99,
		part_id: 31,
		quantity: 160,
	},
	{
		garage_id: 99,
		part_id: 32,
		quantity: 442,
	},
	{
		garage_id: 99,
		part_id: 33,
		quantity: 135,
	},
	{
		garage_id: 99,
		part_id: 34,
		quantity: 10,
	},
	{
		garage_id: 99,
		part_id: 35,
		quantity: 17,
	},
	{
		garage_id: 99,
		part_id: 36,
		quantity: 352,
	},
	{
		garage_id: 99,
		part_id: 37,
		quantity: 480,
	},
	{
		garage_id: 99,
		part_id: 38,
		quantity: 453,
	},
	{
		garage_id: 99,
		part_id: 39,
		quantity: 464,
	},
	{
		garage_id: 99,
		part_id: 40,
		quantity: 160,
	},
	{
		garage_id: 99,
		part_id: 41,
		quantity: 487,
	},
	{
		garage_id: 99,
		part_id: 42,
		quantity: 131,
	},
	{
		garage_id: 99,
		part_id: 43,
		quantity: 455,
	},
	{
		garage_id: 99,
		part_id: 44,
		quantity: 96,
	},
	{
		garage_id: 99,
		part_id: 45,
		quantity: 179,
	},
	{
		garage_id: 99,
		part_id: 46,
		quantity: 458,
	},
	{
		garage_id: 99,
		part_id: 47,
		quantity: 439,
	},
	{
		garage_id: 99,
		part_id: 48,
		quantity: 82,
	},
	{
		garage_id: 99,
		part_id: 49,
		quantity: 249,
	},
	{
		garage_id: 99,
		part_id: 50,
		quantity: 354,
	},
	{
		garage_id: 99,
		part_id: 51,
		quantity: 476,
	},
	{
		garage_id: 99,
		part_id: 52,
		quantity: 314,
	},
	{
		garage_id: 99,
		part_id: 53,
		quantity: 453,
	},
	{
		garage_id: 99,
		part_id: 54,
		quantity: 74,
	},
	{
		garage_id: 99,
		part_id: 55,
		quantity: 304,
	},
	{
		garage_id: 99,
		part_id: 56,
		quantity: 420,
	},
	{
		garage_id: 99,
		part_id: 57,
		quantity: 379,
	},
	{
		garage_id: 99,
		part_id: 58,
		quantity: 290,
	},
	{
		garage_id: 99,
		part_id: 59,
		quantity: 204,
	},
	{
		garage_id: 99,
		part_id: 60,
		quantity: 185,
	},
	{
		garage_id: 99,
		part_id: 61,
		quantity: 19,
	},
	{
		garage_id: 99,
		part_id: 62,
		quantity: 117,
	},
	{
		garage_id: 99,
		part_id: 63,
		quantity: 90,
	},
	{
		garage_id: 99,
		part_id: 64,
		quantity: 141,
	},
	{
		garage_id: 99,
		part_id: 65,
		quantity: 146,
	},
	{
		garage_id: 99,
		part_id: 66,
		quantity: 375,
	},
	{
		garage_id: 99,
		part_id: 67,
		quantity: 251,
	},
	{
		garage_id: 99,
		part_id: 68,
		quantity: 181,
	},
	{
		garage_id: 99,
		part_id: 69,
		quantity: 470,
	},
	{
		garage_id: 99,
		part_id: 70,
		quantity: 497,
	},
	{
		garage_id: 99,
		part_id: 71,
		quantity: 477,
	},
	{
		garage_id: 99,
		part_id: 72,
		quantity: 354,
	},
	{
		garage_id: 99,
		part_id: 73,
		quantity: 489,
	},
	{
		garage_id: 99,
		part_id: 74,
		quantity: 60,
	},
	{
		garage_id: 99,
		part_id: 75,
		quantity: 221,
	},
	{
		garage_id: 99,
		part_id: 76,
		quantity: 124,
	},
	{
		garage_id: 99,
		part_id: 77,
		quantity: 437,
	},
	{
		garage_id: 99,
		part_id: 78,
		quantity: 10,
	},
	{
		garage_id: 99,
		part_id: 79,
		quantity: 299,
	},
	{
		garage_id: 99,
		part_id: 80,
		quantity: 353,
	},
	{
		garage_id: 99,
		part_id: 81,
		quantity: 500,
	},
	{
		garage_id: 99,
		part_id: 82,
		quantity: 9,
	},
	{
		garage_id: 99,
		part_id: 83,
		quantity: 393,
	},
	{
		garage_id: 99,
		part_id: 84,
		quantity: 490,
	},
	{
		garage_id: 99,
		part_id: 85,
		quantity: 277,
	},
	{
		garage_id: 99,
		part_id: 86,
		quantity: 105,
	},
	{
		garage_id: 99,
		part_id: 87,
		quantity: 277,
	},
	{
		garage_id: 99,
		part_id: 88,
		quantity: 243,
	},
	{
		garage_id: 99,
		part_id: 89,
		quantity: 259,
	},
	{
		garage_id: 99,
		part_id: 90,
		quantity: 159,
	},
	{
		garage_id: 99,
		part_id: 91,
		quantity: 100,
	},
	{
		garage_id: 99,
		part_id: 92,
		quantity: 78,
	},
	{
		garage_id: 99,
		part_id: 93,
		quantity: 69,
	},
	{
		garage_id: 99,
		part_id: 94,
		quantity: 97,
	},
	{
		garage_id: 99,
		part_id: 95,
		quantity: 170,
	},
	{
		garage_id: 99,
		part_id: 96,
		quantity: 311,
	},
	{
		garage_id: 99,
		part_id: 97,
		quantity: 138,
	},
	{
		garage_id: 99,
		part_id: 98,
		quantity: 451,
	},
	{
		garage_id: 99,
		part_id: 99,
		quantity: 68,
	},
	{
		garage_id: 99,
		part_id: 100,
		quantity: 75,
	},
	{
		garage_id: 99,
		part_id: 101,
		quantity: 55,
	},
	{
		garage_id: 99,
		part_id: 102,
		quantity: 4,
	},
	{
		garage_id: 99,
		part_id: 103,
		quantity: 479,
	},
	{
		garage_id: 99,
		part_id: 104,
		quantity: 260,
	},
	{
		garage_id: 99,
		part_id: 105,
		quantity: 411,
	},
	{
		garage_id: 99,
		part_id: 106,
		quantity: 16,
	},
	{
		garage_id: 99,
		part_id: 107,
		quantity: 383,
	},
	{
		garage_id: 99,
		part_id: 108,
		quantity: 274,
	},
	{
		garage_id: 99,
		part_id: 109,
		quantity: 73,
	},
	{
		garage_id: 99,
		part_id: 110,
		quantity: 330,
	},
	{
		garage_id: 99,
		part_id: 111,
		quantity: 480,
	},
	{
		garage_id: 99,
		part_id: 112,
		quantity: 443,
	},
	{
		garage_id: 99,
		part_id: 113,
		quantity: 456,
	},
	{
		garage_id: 99,
		part_id: 114,
		quantity: 288,
	},
	{
		garage_id: 99,
		part_id: 115,
		quantity: 115,
	},
	{
		garage_id: 99,
		part_id: 116,
		quantity: 403,
	},
	{
		garage_id: 99,
		part_id: 117,
		quantity: 270,
	},
	{
		garage_id: 99,
		part_id: 118,
		quantity: 224,
	},
	{
		garage_id: 99,
		part_id: 119,
		quantity: 377,
	},
	{
		garage_id: 99,
		part_id: 120,
		quantity: 30,
	},
	{
		garage_id: 99,
		part_id: 121,
		quantity: 91,
	},
	{
		garage_id: 99,
		part_id: 122,
		quantity: 146,
	},
	{
		garage_id: 99,
		part_id: 123,
		quantity: 49,
	},
	{
		garage_id: 99,
		part_id: 124,
		quantity: 427,
	},
	{
		garage_id: 99,
		part_id: 125,
		quantity: 289,
	},
	{
		garage_id: 99,
		part_id: 126,
		quantity: 221,
	},
	{
		garage_id: 99,
		part_id: 127,
		quantity: 77,
	},
	{
		garage_id: 99,
		part_id: 128,
		quantity: 330,
	},
	{
		garage_id: 99,
		part_id: 129,
		quantity: 44,
	},
	{
		garage_id: 99,
		part_id: 130,
		quantity: 101,
	},
	{
		garage_id: 99,
		part_id: 131,
		quantity: 413,
	},
	{
		garage_id: 99,
		part_id: 132,
		quantity: 500,
	},
	{
		garage_id: 99,
		part_id: 133,
		quantity: 8,
	},
	{
		garage_id: 99,
		part_id: 134,
		quantity: 428,
	},
	{
		garage_id: 99,
		part_id: 135,
		quantity: 434,
	},
	{
		garage_id: 99,
		part_id: 136,
		quantity: 195,
	},
	{
		garage_id: 99,
		part_id: 137,
		quantity: 327,
	},
	{
		garage_id: 99,
		part_id: 138,
		quantity: 110,
	},
	{
		garage_id: 99,
		part_id: 139,
		quantity: 40,
	},
	{
		garage_id: 99,
		part_id: 140,
		quantity: 422,
	},
	{
		garage_id: 99,
		part_id: 141,
		quantity: 153,
	},
	{
		garage_id: 99,
		part_id: 142,
		quantity: 92,
	},
	{
		garage_id: 99,
		part_id: 143,
		quantity: 419,
	},
	{
		garage_id: 99,
		part_id: 144,
		quantity: 22,
	},
	{
		garage_id: 99,
		part_id: 145,
		quantity: 186,
	},
	{
		garage_id: 99,
		part_id: 146,
		quantity: 147,
	},
	{
		garage_id: 99,
		part_id: 147,
		quantity: 58,
	},
	{
		garage_id: 99,
		part_id: 148,
		quantity: 19,
	},
	{
		garage_id: 99,
		part_id: 149,
		quantity: 189,
	},
	{
		garage_id: 99,
		part_id: 150,
		quantity: 445,
	},
	{
		garage_id: 99,
		part_id: 151,
		quantity: 412,
	},
	{
		garage_id: 99,
		part_id: 152,
		quantity: 459,
	},
	{
		garage_id: 99,
		part_id: 153,
		quantity: 312,
	},
	{
		garage_id: 99,
		part_id: 154,
		quantity: 73,
	},
	{
		garage_id: 99,
		part_id: 155,
		quantity: 266,
	},
	{
		garage_id: 99,
		part_id: 156,
		quantity: 142,
	},
	{
		garage_id: 99,
		part_id: 157,
		quantity: 272,
	},
	{
		garage_id: 99,
		part_id: 158,
		quantity: 483,
	},
	{
		garage_id: 99,
		part_id: 159,
		quantity: 232,
	},
	{
		garage_id: 99,
		part_id: 160,
		quantity: 314,
	},
	{
		garage_id: 99,
		part_id: 161,
		quantity: 0,
	},
	{
		garage_id: 99,
		part_id: 162,
		quantity: 447,
	},
	{
		garage_id: 99,
		part_id: 163,
		quantity: 17,
	},
	{
		garage_id: 99,
		part_id: 164,
		quantity: 488,
	},
	{
		garage_id: 99,
		part_id: 165,
		quantity: 498,
	},
	{
		garage_id: 99,
		part_id: 166,
		quantity: 427,
	},
	{
		garage_id: 99,
		part_id: 167,
		quantity: 211,
	},
	{
		garage_id: 99,
		part_id: 168,
		quantity: 279,
	},
	{
		garage_id: 99,
		part_id: 169,
		quantity: 74,
	},
	{
		garage_id: 99,
		part_id: 170,
		quantity: 438,
	},
	{
		garage_id: 99,
		part_id: 171,
		quantity: 67,
	},
	{
		garage_id: 99,
		part_id: 172,
		quantity: 319,
	},
	{
		garage_id: 99,
		part_id: 173,
		quantity: 403,
	},
	{
		garage_id: 99,
		part_id: 174,
		quantity: 30,
	},
	{
		garage_id: 99,
		part_id: 175,
		quantity: 150,
	},
	{
		garage_id: 99,
		part_id: 176,
		quantity: 193,
	},
	{
		garage_id: 99,
		part_id: 177,
		quantity: 468,
	},
	{
		garage_id: 99,
		part_id: 178,
		quantity: 318,
	},
	{
		garage_id: 99,
		part_id: 179,
		quantity: 293,
	},
	{
		garage_id: 99,
		part_id: 180,
		quantity: 410,
	},
	{
		garage_id: 99,
		part_id: 181,
		quantity: 171,
	},
	{
		garage_id: 99,
		part_id: 182,
		quantity: 102,
	},
	{
		garage_id: 99,
		part_id: 183,
		quantity: 313,
	},
	{
		garage_id: 99,
		part_id: 184,
		quantity: 289,
	},
	{
		garage_id: 99,
		part_id: 185,
		quantity: 496,
	},
	{
		garage_id: 99,
		part_id: 186,
		quantity: 219,
	},
	{
		garage_id: 99,
		part_id: 187,
		quantity: 309,
	},
	{
		garage_id: 99,
		part_id: 188,
		quantity: 494,
	},
	{
		garage_id: 99,
		part_id: 189,
		quantity: 277,
	},
	{
		garage_id: 99,
		part_id: 190,
		quantity: 4,
	},
	{
		garage_id: 99,
		part_id: 191,
		quantity: 312,
	},
	{
		garage_id: 99,
		part_id: 192,
		quantity: 7,
	},
	{
		garage_id: 99,
		part_id: 193,
		quantity: 256,
	},
	{
		garage_id: 99,
		part_id: 194,
		quantity: 384,
	},
	{
		garage_id: 99,
		part_id: 195,
		quantity: 486,
	},
	{
		garage_id: 99,
		part_id: 196,
		quantity: 133,
	},
	{
		garage_id: 99,
		part_id: 197,
		quantity: 474,
	},
	{
		garage_id: 99,
		part_id: 198,
		quantity: 343,
	},
	{
		garage_id: 99,
		part_id: 199,
		quantity: 187,
	},
	{
		garage_id: 99,
		part_id: 200,
		quantity: 202,
	},
	{
		garage_id: 99,
		part_id: 201,
		quantity: 73,
	},
	{
		garage_id: 99,
		part_id: 202,
		quantity: 382,
	},
	{
		garage_id: 99,
		part_id: 203,
		quantity: 35,
	},
	{
		garage_id: 99,
		part_id: 204,
		quantity: 495,
	},
	{
		garage_id: 99,
		part_id: 205,
		quantity: 43,
	},
	{
		garage_id: 99,
		part_id: 206,
		quantity: 406,
	},
	{
		garage_id: 99,
		part_id: 207,
		quantity: 119,
	},
	{
		garage_id: 99,
		part_id: 208,
		quantity: 157,
	},
	{
		garage_id: 99,
		part_id: 209,
		quantity: 419,
	},
	{
		garage_id: 99,
		part_id: 210,
		quantity: 215,
	},
	{
		garage_id: 99,
		part_id: 211,
		quantity: 58,
	},
	{
		garage_id: 99,
		part_id: 212,
		quantity: 84,
	},
	{
		garage_id: 99,
		part_id: 213,
		quantity: 45,
	},
	{
		garage_id: 99,
		part_id: 214,
		quantity: 367,
	},
	{
		garage_id: 99,
		part_id: 215,
		quantity: 184,
	},
	{
		garage_id: 99,
		part_id: 216,
		quantity: 135,
	},
	{
		garage_id: 99,
		part_id: 217,
		quantity: 95,
	},
	{
		garage_id: 99,
		part_id: 218,
		quantity: 7,
	},
	{
		garage_id: 99,
		part_id: 219,
		quantity: 346,
	},
	{
		garage_id: 99,
		part_id: 220,
		quantity: 318,
	},
	{
		garage_id: 99,
		part_id: 221,
		quantity: 268,
	},
	{
		garage_id: 99,
		part_id: 222,
		quantity: 376,
	},
	{
		garage_id: 99,
		part_id: 223,
		quantity: 148,
	},
	{
		garage_id: 99,
		part_id: 224,
		quantity: 149,
	},
	{
		garage_id: 99,
		part_id: 225,
		quantity: 154,
	},
	{
		garage_id: 99,
		part_id: 226,
		quantity: 7,
	},
	{
		garage_id: 99,
		part_id: 227,
		quantity: 137,
	},
	{
		garage_id: 99,
		part_id: 228,
		quantity: 391,
	},
	{
		garage_id: 99,
		part_id: 229,
		quantity: 360,
	},
	{
		garage_id: 99,
		part_id: 230,
		quantity: 38,
	},
	{
		garage_id: 99,
		part_id: 231,
		quantity: 101,
	},
	{
		garage_id: 99,
		part_id: 232,
		quantity: 50,
	},
	{
		garage_id: 99,
		part_id: 233,
		quantity: 186,
	},
	{
		garage_id: 99,
		part_id: 234,
		quantity: 192,
	},
	{
		garage_id: 99,
		part_id: 235,
		quantity: 22,
	},
	{
		garage_id: 99,
		part_id: 236,
		quantity: 318,
	},
	{
		garage_id: 99,
		part_id: 237,
		quantity: 53,
	},
	{
		garage_id: 99,
		part_id: 238,
		quantity: 435,
	},
	{
		garage_id: 99,
		part_id: 239,
		quantity: 365,
	},
	{
		garage_id: 99,
		part_id: 240,
		quantity: 347,
	},
	{
		garage_id: 99,
		part_id: 241,
		quantity: 109,
	},
	{
		garage_id: 99,
		part_id: 242,
		quantity: 399,
	},
	{
		garage_id: 99,
		part_id: 243,
		quantity: 40,
	},
	{
		garage_id: 99,
		part_id: 244,
		quantity: 270,
	},
	{
		garage_id: 99,
		part_id: 245,
		quantity: 88,
	},
	{
		garage_id: 99,
		part_id: 246,
		quantity: 491,
	},
	{
		garage_id: 99,
		part_id: 247,
		quantity: 163,
	},
	{
		garage_id: 99,
		part_id: 248,
		quantity: 374,
	},
	{
		garage_id: 99,
		part_id: 249,
		quantity: 468,
	},
	{
		garage_id: 99,
		part_id: 250,
		quantity: 168,
	},
	{
		garage_id: 99,
		part_id: 251,
		quantity: 45,
	},
	{
		garage_id: 99,
		part_id: 252,
		quantity: 42,
	},
	{
		garage_id: 99,
		part_id: 253,
		quantity: 443,
	},
	{
		garage_id: 99,
		part_id: 254,
		quantity: 19,
	},
	{
		garage_id: 99,
		part_id: 255,
		quantity: 98,
	},
	{
		garage_id: 99,
		part_id: 256,
		quantity: 147,
	},
	{
		garage_id: 99,
		part_id: 257,
		quantity: 312,
	},
	{
		garage_id: 99,
		part_id: 258,
		quantity: 315,
	},
	{
		garage_id: 99,
		part_id: 259,
		quantity: 263,
	},
	{
		garage_id: 99,
		part_id: 260,
		quantity: 418,
	},
	{
		garage_id: 99,
		part_id: 261,
		quantity: 189,
	},
	{
		garage_id: 99,
		part_id: 262,
		quantity: 413,
	},
	{
		garage_id: 99,
		part_id: 263,
		quantity: 440,
	},
	{
		garage_id: 99,
		part_id: 264,
		quantity: 59,
	},
	{
		garage_id: 99,
		part_id: 265,
		quantity: 299,
	},
	{
		garage_id: 99,
		part_id: 266,
		quantity: 49,
	},
	{
		garage_id: 99,
		part_id: 267,
		quantity: 363,
	},
	{
		garage_id: 99,
		part_id: 268,
		quantity: 72,
	},
	{
		garage_id: 99,
		part_id: 269,
		quantity: 38,
	},
	{
		garage_id: 99,
		part_id: 270,
		quantity: 344,
	},
	{
		garage_id: 99,
		part_id: 271,
		quantity: 260,
	},
	{
		garage_id: 99,
		part_id: 272,
		quantity: 90,
	},
	{
		garage_id: 99,
		part_id: 273,
		quantity: 34,
	},
	{
		garage_id: 99,
		part_id: 274,
		quantity: 192,
	},
	{
		garage_id: 99,
		part_id: 275,
		quantity: 8,
	},
	{
		garage_id: 99,
		part_id: 276,
		quantity: 259,
	},
	{
		garage_id: 99,
		part_id: 277,
		quantity: 250,
	},
	{
		garage_id: 99,
		part_id: 278,
		quantity: 308,
	},
	{
		garage_id: 99,
		part_id: 279,
		quantity: 462,
	},
	{
		garage_id: 99,
		part_id: 280,
		quantity: 297,
	},
	{
		garage_id: 99,
		part_id: 281,
		quantity: 303,
	},
	{
		garage_id: 99,
		part_id: 282,
		quantity: 386,
	},
	{
		garage_id: 99,
		part_id: 283,
		quantity: 499,
	},
	{
		garage_id: 99,
		part_id: 284,
		quantity: 412,
	},
	{
		garage_id: 99,
		part_id: 285,
		quantity: 361,
	},
	{
		garage_id: 99,
		part_id: 286,
		quantity: 306,
	},
	{
		garage_id: 99,
		part_id: 287,
		quantity: 486,
	},
	{
		garage_id: 99,
		part_id: 288,
		quantity: 200,
	},
	{
		garage_id: 99,
		part_id: 289,
		quantity: 397,
	},
	{
		garage_id: 99,
		part_id: 290,
		quantity: 259,
	},
	{
		garage_id: 99,
		part_id: 291,
		quantity: 1,
	},
	{
		garage_id: 99,
		part_id: 292,
		quantity: 113,
	},
	{
		garage_id: 99,
		part_id: 293,
		quantity: 468,
	},
	{
		garage_id: 99,
		part_id: 294,
		quantity: 368,
	},
	{
		garage_id: 99,
		part_id: 295,
		quantity: 81,
	},
	{
		garage_id: 99,
		part_id: 296,
		quantity: 156,
	},
	{
		garage_id: 99,
		part_id: 297,
		quantity: 179,
	},
	{
		garage_id: 99,
		part_id: 298,
		quantity: 84,
	},
	{
		garage_id: 99,
		part_id: 299,
		quantity: 199,
	},
	{
		garage_id: 99,
		part_id: 300,
		quantity: 314,
	},
	{
		garage_id: 99,
		part_id: 301,
		quantity: 267,
	},
	{
		garage_id: 99,
		part_id: 302,
		quantity: 268,
	},
	{
		garage_id: 99,
		part_id: 303,
		quantity: 308,
	},
	{
		garage_id: 99,
		part_id: 304,
		quantity: 75,
	},
	{
		garage_id: 99,
		part_id: 305,
		quantity: 374,
	},
	{
		garage_id: 99,
		part_id: 306,
		quantity: 35,
	},
	{
		garage_id: 99,
		part_id: 307,
		quantity: 416,
	},
	{
		garage_id: 99,
		part_id: 308,
		quantity: 471,
	},
	{
		garage_id: 99,
		part_id: 309,
		quantity: 472,
	},
	{
		garage_id: 99,
		part_id: 310,
		quantity: 489,
	},
	{
		garage_id: 99,
		part_id: 311,
		quantity: 187,
	},
	{
		garage_id: 99,
		part_id: 312,
		quantity: 310,
	},
	{
		garage_id: 99,
		part_id: 313,
		quantity: 152,
	},
	{
		garage_id: 99,
		part_id: 314,
		quantity: 322,
	},
	{
		garage_id: 99,
		part_id: 315,
		quantity: 94,
	},
	{
		garage_id: 99,
		part_id: 316,
		quantity: 119,
	},
	{
		garage_id: 99,
		part_id: 317,
		quantity: 226,
	},
	{
		garage_id: 99,
		part_id: 318,
		quantity: 236,
	},
	{
		garage_id: 99,
		part_id: 319,
		quantity: 103,
	},
	{
		garage_id: 99,
		part_id: 320,
		quantity: 84,
	},
	{
		garage_id: 99,
		part_id: 321,
		quantity: 436,
	},
	{
		garage_id: 99,
		part_id: 322,
		quantity: 305,
	},
	{
		garage_id: 99,
		part_id: 323,
		quantity: 223,
	},
	{
		garage_id: 99,
		part_id: 324,
		quantity: 137,
	},
	{
		garage_id: 99,
		part_id: 325,
		quantity: 463,
	},
	{
		garage_id: 99,
		part_id: 326,
		quantity: 46,
	},
	{
		garage_id: 99,
		part_id: 327,
		quantity: 431,
	},
	{
		garage_id: 99,
		part_id: 328,
		quantity: 117,
	},
	{
		garage_id: 99,
		part_id: 329,
		quantity: 186,
	},
	{
		garage_id: 99,
		part_id: 330,
		quantity: 193,
	},
	{
		garage_id: 99,
		part_id: 331,
		quantity: 191,
	},
	{
		garage_id: 99,
		part_id: 332,
		quantity: 98,
	},
	{
		garage_id: 99,
		part_id: 333,
		quantity: 26,
	},
	{
		garage_id: 99,
		part_id: 334,
		quantity: 143,
	},
	{
		garage_id: 99,
		part_id: 335,
		quantity: 111,
	},
	{
		garage_id: 99,
		part_id: 336,
		quantity: 160,
	},
	{
		garage_id: 99,
		part_id: 337,
		quantity: 339,
	},
	{
		garage_id: 99,
		part_id: 338,
		quantity: 385,
	},
	{
		garage_id: 99,
		part_id: 339,
		quantity: 335,
	},
	{
		garage_id: 99,
		part_id: 340,
		quantity: 467,
	},
	{
		garage_id: 99,
		part_id: 341,
		quantity: 379,
	},
	{
		garage_id: 99,
		part_id: 342,
		quantity: 70,
	},
	{
		garage_id: 99,
		part_id: 343,
		quantity: 235,
	},
	{
		garage_id: 99,
		part_id: 344,
		quantity: 115,
	},
	{
		garage_id: 99,
		part_id: 345,
		quantity: 55,
	},
	{
		garage_id: 99,
		part_id: 346,
		quantity: 186,
	},
	{
		garage_id: 99,
		part_id: 347,
		quantity: 390,
	},
	{
		garage_id: 99,
		part_id: 348,
		quantity: 277,
	},
	{
		garage_id: 99,
		part_id: 349,
		quantity: 281,
	},
	{
		garage_id: 99,
		part_id: 350,
		quantity: 367,
	},
	{
		garage_id: 99,
		part_id: 351,
		quantity: 210,
	},
	{
		garage_id: 99,
		part_id: 352,
		quantity: 484,
	},
	{
		garage_id: 99,
		part_id: 353,
		quantity: 359,
	},
	{
		garage_id: 99,
		part_id: 354,
		quantity: 324,
	},
	{
		garage_id: 99,
		part_id: 355,
		quantity: 406,
	},
	{
		garage_id: 99,
		part_id: 356,
		quantity: 329,
	},
	{
		garage_id: 99,
		part_id: 357,
		quantity: 44,
	},
	{
		garage_id: 99,
		part_id: 358,
		quantity: 294,
	},
	{
		garage_id: 99,
		part_id: 359,
		quantity: 29,
	},
	{
		garage_id: 99,
		part_id: 360,
		quantity: 145,
	},
	{
		garage_id: 99,
		part_id: 361,
		quantity: 165,
	},
	{
		garage_id: 99,
		part_id: 362,
		quantity: 64,
	},
	{
		garage_id: 99,
		part_id: 363,
		quantity: 124,
	},
	{
		garage_id: 99,
		part_id: 364,
		quantity: 335,
	},
	{
		garage_id: 99,
		part_id: 365,
		quantity: 207,
	},
	{
		garage_id: 99,
		part_id: 366,
		quantity: 484,
	},
	{
		garage_id: 99,
		part_id: 367,
		quantity: 427,
	},
	{
		garage_id: 99,
		part_id: 368,
		quantity: 189,
	},
	{
		garage_id: 99,
		part_id: 369,
		quantity: 200,
	},
	{
		garage_id: 99,
		part_id: 370,
		quantity: 246,
	},
	{
		garage_id: 99,
		part_id: 371,
		quantity: 233,
	},
	{
		garage_id: 99,
		part_id: 372,
		quantity: 264,
	},
	{
		garage_id: 99,
		part_id: 373,
		quantity: 47,
	},
	{
		garage_id: 99,
		part_id: 374,
		quantity: 311,
	},
	{
		garage_id: 99,
		part_id: 375,
		quantity: 428,
	},
	{
		garage_id: 99,
		part_id: 376,
		quantity: 133,
	},
	{
		garage_id: 99,
		part_id: 377,
		quantity: 245,
	},
	{
		garage_id: 99,
		part_id: 378,
		quantity: 484,
	},
	{
		garage_id: 99,
		part_id: 379,
		quantity: 147,
	},
	{
		garage_id: 99,
		part_id: 380,
		quantity: 139,
	},
	{
		garage_id: 99,
		part_id: 381,
		quantity: 245,
	},
	{
		garage_id: 99,
		part_id: 382,
		quantity: 97,
	},
	{
		garage_id: 99,
		part_id: 383,
		quantity: 113,
	},
	{
		garage_id: 99,
		part_id: 384,
		quantity: 212,
	},
	{
		garage_id: 99,
		part_id: 385,
		quantity: 200,
	},
	{
		garage_id: 99,
		part_id: 386,
		quantity: 114,
	},
	{
		garage_id: 99,
		part_id: 387,
		quantity: 175,
	},
	{
		garage_id: 99,
		part_id: 388,
		quantity: 320,
	},
	{
		garage_id: 99,
		part_id: 389,
		quantity: 238,
	},
	{
		garage_id: 99,
		part_id: 390,
		quantity: 400,
	},
	{
		garage_id: 99,
		part_id: 391,
		quantity: 64,
	},
	{
		garage_id: 99,
		part_id: 392,
		quantity: 4,
	},
	{
		garage_id: 99,
		part_id: 393,
		quantity: 301,
	},
	{
		garage_id: 99,
		part_id: 394,
		quantity: 333,
	},
	{
		garage_id: 99,
		part_id: 395,
		quantity: 317,
	},
	{
		garage_id: 99,
		part_id: 396,
		quantity: 153,
	},
	{
		garage_id: 99,
		part_id: 397,
		quantity: 139,
	},
	{
		garage_id: 99,
		part_id: 398,
		quantity: 396,
	},
	{
		garage_id: 99,
		part_id: 399,
		quantity: 290,
	},
	{
		garage_id: 100,
		part_id: 0,
		quantity: 133,
	},
	{
		garage_id: 100,
		part_id: 1,
		quantity: 2,
	},
	{
		garage_id: 100,
		part_id: 2,
		quantity: 295,
	},
	{
		garage_id: 100,
		part_id: 3,
		quantity: 329,
	},
	{
		garage_id: 100,
		part_id: 4,
		quantity: 144,
	},
	{
		garage_id: 100,
		part_id: 5,
		quantity: 315,
	},
	{
		garage_id: 100,
		part_id: 6,
		quantity: 328,
	},
	{
		garage_id: 100,
		part_id: 7,
		quantity: 69,
	},
	{
		garage_id: 100,
		part_id: 8,
		quantity: 344,
	},
	{
		garage_id: 100,
		part_id: 9,
		quantity: 471,
	},
	{
		garage_id: 100,
		part_id: 10,
		quantity: 455,
	},
	{
		garage_id: 100,
		part_id: 11,
		quantity: 68,
	},
	{
		garage_id: 100,
		part_id: 12,
		quantity: 197,
	},
	{
		garage_id: 100,
		part_id: 13,
		quantity: 328,
	},
	{
		garage_id: 100,
		part_id: 14,
		quantity: 369,
	},
	{
		garage_id: 100,
		part_id: 15,
		quantity: 40,
	},
	{
		garage_id: 100,
		part_id: 16,
		quantity: 495,
	},
	{
		garage_id: 100,
		part_id: 17,
		quantity: 201,
	},
	{
		garage_id: 100,
		part_id: 18,
		quantity: 333,
	},
	{
		garage_id: 100,
		part_id: 19,
		quantity: 53,
	},
	{
		garage_id: 100,
		part_id: 20,
		quantity: 402,
	},
	{
		garage_id: 100,
		part_id: 21,
		quantity: 429,
	},
	{
		garage_id: 100,
		part_id: 22,
		quantity: 427,
	},
	{
		garage_id: 100,
		part_id: 23,
		quantity: 403,
	},
	{
		garage_id: 100,
		part_id: 24,
		quantity: 44,
	},
	{
		garage_id: 100,
		part_id: 25,
		quantity: 32,
	},
	{
		garage_id: 100,
		part_id: 26,
		quantity: 115,
	},
	{
		garage_id: 100,
		part_id: 27,
		quantity: 231,
	},
	{
		garage_id: 100,
		part_id: 28,
		quantity: 201,
	},
	{
		garage_id: 100,
		part_id: 29,
		quantity: 309,
	},
	{
		garage_id: 100,
		part_id: 30,
		quantity: 371,
	},
	{
		garage_id: 100,
		part_id: 31,
		quantity: 90,
	},
	{
		garage_id: 100,
		part_id: 32,
		quantity: 126,
	},
	{
		garage_id: 100,
		part_id: 33,
		quantity: 304,
	},
	{
		garage_id: 100,
		part_id: 34,
		quantity: 480,
	},
	{
		garage_id: 100,
		part_id: 35,
		quantity: 422,
	},
	{
		garage_id: 100,
		part_id: 36,
		quantity: 12,
	},
	{
		garage_id: 100,
		part_id: 37,
		quantity: 96,
	},
	{
		garage_id: 100,
		part_id: 38,
		quantity: 367,
	},
	{
		garage_id: 100,
		part_id: 39,
		quantity: 473,
	},
	{
		garage_id: 100,
		part_id: 40,
		quantity: 134,
	},
	{
		garage_id: 100,
		part_id: 41,
		quantity: 299,
	},
	{
		garage_id: 100,
		part_id: 42,
		quantity: 489,
	},
	{
		garage_id: 100,
		part_id: 43,
		quantity: 348,
	},
	{
		garage_id: 100,
		part_id: 44,
		quantity: 17,
	},
	{
		garage_id: 100,
		part_id: 45,
		quantity: 418,
	},
	{
		garage_id: 100,
		part_id: 46,
		quantity: 165,
	},
	{
		garage_id: 100,
		part_id: 47,
		quantity: 142,
	},
	{
		garage_id: 100,
		part_id: 48,
		quantity: 53,
	},
	{
		garage_id: 100,
		part_id: 49,
		quantity: 11,
	},
	{
		garage_id: 100,
		part_id: 50,
		quantity: 276,
	},
	{
		garage_id: 100,
		part_id: 51,
		quantity: 336,
	},
	{
		garage_id: 100,
		part_id: 52,
		quantity: 291,
	},
	{
		garage_id: 100,
		part_id: 53,
		quantity: 93,
	},
	{
		garage_id: 100,
		part_id: 54,
		quantity: 264,
	},
	{
		garage_id: 100,
		part_id: 55,
		quantity: 96,
	},
	{
		garage_id: 100,
		part_id: 56,
		quantity: 471,
	},
	{
		garage_id: 100,
		part_id: 57,
		quantity: 70,
	},
	{
		garage_id: 100,
		part_id: 58,
		quantity: 199,
	},
	{
		garage_id: 100,
		part_id: 59,
		quantity: 121,
	},
	{
		garage_id: 100,
		part_id: 60,
		quantity: 68,
	},
	{
		garage_id: 100,
		part_id: 61,
		quantity: 278,
	},
	{
		garage_id: 100,
		part_id: 62,
		quantity: 11,
	},
	{
		garage_id: 100,
		part_id: 63,
		quantity: 383,
	},
	{
		garage_id: 100,
		part_id: 64,
		quantity: 267,
	},
	{
		garage_id: 100,
		part_id: 65,
		quantity: 423,
	},
	{
		garage_id: 100,
		part_id: 66,
		quantity: 186,
	},
	{
		garage_id: 100,
		part_id: 67,
		quantity: 276,
	},
	{
		garage_id: 100,
		part_id: 68,
		quantity: 160,
	},
	{
		garage_id: 100,
		part_id: 69,
		quantity: 495,
	},
	{
		garage_id: 100,
		part_id: 70,
		quantity: 163,
	},
	{
		garage_id: 100,
		part_id: 71,
		quantity: 179,
	},
	{
		garage_id: 100,
		part_id: 72,
		quantity: 413,
	},
	{
		garage_id: 100,
		part_id: 73,
		quantity: 353,
	},
	{
		garage_id: 100,
		part_id: 74,
		quantity: 48,
	},
	{
		garage_id: 100,
		part_id: 75,
		quantity: 310,
	},
	{
		garage_id: 100,
		part_id: 76,
		quantity: 250,
	},
	{
		garage_id: 100,
		part_id: 77,
		quantity: 408,
	},
	{
		garage_id: 100,
		part_id: 78,
		quantity: 50,
	},
	{
		garage_id: 100,
		part_id: 79,
		quantity: 251,
	},
	{
		garage_id: 100,
		part_id: 80,
		quantity: 422,
	},
	{
		garage_id: 100,
		part_id: 81,
		quantity: 244,
	},
	{
		garage_id: 100,
		part_id: 82,
		quantity: 454,
	},
	{
		garage_id: 100,
		part_id: 83,
		quantity: 482,
	},
	{
		garage_id: 100,
		part_id: 84,
		quantity: 320,
	},
	{
		garage_id: 100,
		part_id: 85,
		quantity: 96,
	},
	{
		garage_id: 100,
		part_id: 86,
		quantity: 306,
	},
	{
		garage_id: 100,
		part_id: 87,
		quantity: 175,
	},
	{
		garage_id: 100,
		part_id: 88,
		quantity: 496,
	},
	{
		garage_id: 100,
		part_id: 89,
		quantity: 173,
	},
	{
		garage_id: 100,
		part_id: 90,
		quantity: 44,
	},
	{
		garage_id: 100,
		part_id: 91,
		quantity: 228,
	},
	{
		garage_id: 100,
		part_id: 92,
		quantity: 220,
	},
	{
		garage_id: 100,
		part_id: 93,
		quantity: 194,
	},
	{
		garage_id: 100,
		part_id: 94,
		quantity: 125,
	},
	{
		garage_id: 100,
		part_id: 95,
		quantity: 323,
	},
	{
		garage_id: 100,
		part_id: 96,
		quantity: 420,
	},
	{
		garage_id: 100,
		part_id: 97,
		quantity: 70,
	},
	{
		garage_id: 100,
		part_id: 98,
		quantity: 296,
	},
	{
		garage_id: 100,
		part_id: 99,
		quantity: 135,
	},
	{
		garage_id: 100,
		part_id: 100,
		quantity: 358,
	},
	{
		garage_id: 100,
		part_id: 101,
		quantity: 228,
	},
	{
		garage_id: 100,
		part_id: 102,
		quantity: 382,
	},
	{
		garage_id: 100,
		part_id: 103,
		quantity: 249,
	},
	{
		garage_id: 100,
		part_id: 104,
		quantity: 86,
	},
	{
		garage_id: 100,
		part_id: 105,
		quantity: 193,
	},
	{
		garage_id: 100,
		part_id: 106,
		quantity: 298,
	},
	{
		garage_id: 100,
		part_id: 107,
		quantity: 169,
	},
	{
		garage_id: 100,
		part_id: 108,
		quantity: 177,
	},
	{
		garage_id: 100,
		part_id: 109,
		quantity: 343,
	},
	{
		garage_id: 100,
		part_id: 110,
		quantity: 397,
	},
	{
		garage_id: 100,
		part_id: 111,
		quantity: 96,
	},
	{
		garage_id: 100,
		part_id: 112,
		quantity: 77,
	},
	{
		garage_id: 100,
		part_id: 113,
		quantity: 286,
	},
	{
		garage_id: 100,
		part_id: 114,
		quantity: 153,
	},
	{
		garage_id: 100,
		part_id: 115,
		quantity: 469,
	},
	{
		garage_id: 100,
		part_id: 116,
		quantity: 189,
	},
	{
		garage_id: 100,
		part_id: 117,
		quantity: 481,
	},
	{
		garage_id: 100,
		part_id: 118,
		quantity: 245,
	},
	{
		garage_id: 100,
		part_id: 119,
		quantity: 78,
	},
	{
		garage_id: 100,
		part_id: 120,
		quantity: 499,
	},
	{
		garage_id: 100,
		part_id: 121,
		quantity: 385,
	},
	{
		garage_id: 100,
		part_id: 122,
		quantity: 307,
	},
	{
		garage_id: 100,
		part_id: 123,
		quantity: 117,
	},
	{
		garage_id: 100,
		part_id: 124,
		quantity: 410,
	},
	{
		garage_id: 100,
		part_id: 125,
		quantity: 294,
	},
	{
		garage_id: 100,
		part_id: 126,
		quantity: 193,
	},
	{
		garage_id: 100,
		part_id: 127,
		quantity: 282,
	},
	{
		garage_id: 100,
		part_id: 128,
		quantity: 343,
	},
	{
		garage_id: 100,
		part_id: 129,
		quantity: 12,
	},
	{
		garage_id: 100,
		part_id: 130,
		quantity: 273,
	},
	{
		garage_id: 100,
		part_id: 131,
		quantity: 121,
	},
	{
		garage_id: 100,
		part_id: 132,
		quantity: 187,
	},
	{
		garage_id: 100,
		part_id: 133,
		quantity: 142,
	},
	{
		garage_id: 100,
		part_id: 134,
		quantity: 295,
	},
	{
		garage_id: 100,
		part_id: 135,
		quantity: 16,
	},
	{
		garage_id: 100,
		part_id: 136,
		quantity: 476,
	},
	{
		garage_id: 100,
		part_id: 137,
		quantity: 278,
	},
	{
		garage_id: 100,
		part_id: 138,
		quantity: 199,
	},
	{
		garage_id: 100,
		part_id: 139,
		quantity: 443,
	},
	{
		garage_id: 100,
		part_id: 140,
		quantity: 412,
	},
	{
		garage_id: 100,
		part_id: 141,
		quantity: 316,
	},
	{
		garage_id: 100,
		part_id: 142,
		quantity: 310,
	},
	{
		garage_id: 100,
		part_id: 143,
		quantity: 344,
	},
	{
		garage_id: 100,
		part_id: 144,
		quantity: 464,
	},
	{
		garage_id: 100,
		part_id: 145,
		quantity: 123,
	},
	{
		garage_id: 100,
		part_id: 146,
		quantity: 438,
	},
	{
		garage_id: 100,
		part_id: 147,
		quantity: 354,
	},
	{
		garage_id: 100,
		part_id: 148,
		quantity: 345,
	},
	{
		garage_id: 100,
		part_id: 149,
		quantity: 97,
	},
	{
		garage_id: 100,
		part_id: 150,
		quantity: 482,
	},
	{
		garage_id: 100,
		part_id: 151,
		quantity: 218,
	},
	{
		garage_id: 100,
		part_id: 152,
		quantity: 281,
	},
	{
		garage_id: 100,
		part_id: 153,
		quantity: 166,
	},
	{
		garage_id: 100,
		part_id: 154,
		quantity: 164,
	},
	{
		garage_id: 100,
		part_id: 155,
		quantity: 196,
	},
	{
		garage_id: 100,
		part_id: 156,
		quantity: 411,
	},
	{
		garage_id: 100,
		part_id: 157,
		quantity: 492,
	},
	{
		garage_id: 100,
		part_id: 158,
		quantity: 487,
	},
	{
		garage_id: 100,
		part_id: 159,
		quantity: 54,
	},
	{
		garage_id: 100,
		part_id: 160,
		quantity: 148,
	},
	{
		garage_id: 100,
		part_id: 161,
		quantity: 410,
	},
	{
		garage_id: 100,
		part_id: 162,
		quantity: 86,
	},
	{
		garage_id: 100,
		part_id: 163,
		quantity: 22,
	},
	{
		garage_id: 100,
		part_id: 164,
		quantity: 411,
	},
	{
		garage_id: 100,
		part_id: 165,
		quantity: 165,
	},
	{
		garage_id: 100,
		part_id: 166,
		quantity: 119,
	},
	{
		garage_id: 100,
		part_id: 167,
		quantity: 122,
	},
	{
		garage_id: 100,
		part_id: 168,
		quantity: 145,
	},
	{
		garage_id: 100,
		part_id: 169,
		quantity: 28,
	},
	{
		garage_id: 100,
		part_id: 170,
		quantity: 283,
	},
	{
		garage_id: 100,
		part_id: 171,
		quantity: 402,
	},
	{
		garage_id: 100,
		part_id: 172,
		quantity: 385,
	},
	{
		garage_id: 100,
		part_id: 173,
		quantity: 414,
	},
	{
		garage_id: 100,
		part_id: 174,
		quantity: 486,
	},
	{
		garage_id: 100,
		part_id: 175,
		quantity: 203,
	},
	{
		garage_id: 100,
		part_id: 176,
		quantity: 436,
	},
	{
		garage_id: 100,
		part_id: 177,
		quantity: 258,
	},
	{
		garage_id: 100,
		part_id: 178,
		quantity: 111,
	},
	{
		garage_id: 100,
		part_id: 179,
		quantity: 297,
	},
	{
		garage_id: 100,
		part_id: 180,
		quantity: 126,
	},
	{
		garage_id: 100,
		part_id: 181,
		quantity: 346,
	},
	{
		garage_id: 100,
		part_id: 182,
		quantity: 282,
	},
	{
		garage_id: 100,
		part_id: 183,
		quantity: 109,
	},
	{
		garage_id: 100,
		part_id: 184,
		quantity: 409,
	},
	{
		garage_id: 100,
		part_id: 185,
		quantity: 304,
	},
	{
		garage_id: 100,
		part_id: 186,
		quantity: 369,
	},
	{
		garage_id: 100,
		part_id: 187,
		quantity: 286,
	},
	{
		garage_id: 100,
		part_id: 188,
		quantity: 362,
	},
	{
		garage_id: 100,
		part_id: 189,
		quantity: 430,
	},
	{
		garage_id: 100,
		part_id: 190,
		quantity: 105,
	},
	{
		garage_id: 100,
		part_id: 191,
		quantity: 166,
	},
	{
		garage_id: 100,
		part_id: 192,
		quantity: 4,
	},
	{
		garage_id: 100,
		part_id: 193,
		quantity: 344,
	},
	{
		garage_id: 100,
		part_id: 194,
		quantity: 179,
	},
	{
		garage_id: 100,
		part_id: 195,
		quantity: 342,
	},
	{
		garage_id: 100,
		part_id: 196,
		quantity: 481,
	},
	{
		garage_id: 100,
		part_id: 197,
		quantity: 420,
	},
	{
		garage_id: 100,
		part_id: 198,
		quantity: 412,
	},
	{
		garage_id: 100,
		part_id: 199,
		quantity: 172,
	},
	{
		garage_id: 100,
		part_id: 200,
		quantity: 183,
	},
	{
		garage_id: 100,
		part_id: 201,
		quantity: 4,
	},
	{
		garage_id: 100,
		part_id: 202,
		quantity: 321,
	},
	{
		garage_id: 100,
		part_id: 203,
		quantity: 9,
	},
	{
		garage_id: 100,
		part_id: 204,
		quantity: 105,
	},
	{
		garage_id: 100,
		part_id: 205,
		quantity: 418,
	},
	{
		garage_id: 100,
		part_id: 206,
		quantity: 345,
	},
	{
		garage_id: 100,
		part_id: 207,
		quantity: 98,
	},
	{
		garage_id: 100,
		part_id: 208,
		quantity: 99,
	},
	{
		garage_id: 100,
		part_id: 209,
		quantity: 15,
	},
	{
		garage_id: 100,
		part_id: 210,
		quantity: 327,
	},
	{
		garage_id: 100,
		part_id: 211,
		quantity: 486,
	},
	{
		garage_id: 100,
		part_id: 212,
		quantity: 75,
	},
	{
		garage_id: 100,
		part_id: 213,
		quantity: 399,
	},
	{
		garage_id: 100,
		part_id: 214,
		quantity: 490,
	},
	{
		garage_id: 100,
		part_id: 215,
		quantity: 248,
	},
	{
		garage_id: 100,
		part_id: 216,
		quantity: 112,
	},
	{
		garage_id: 100,
		part_id: 217,
		quantity: 364,
	},
	{
		garage_id: 100,
		part_id: 218,
		quantity: 309,
	},
	{
		garage_id: 100,
		part_id: 219,
		quantity: 484,
	},
	{
		garage_id: 100,
		part_id: 220,
		quantity: 454,
	},
	{
		garage_id: 100,
		part_id: 221,
		quantity: 134,
	},
	{
		garage_id: 100,
		part_id: 222,
		quantity: 302,
	},
	{
		garage_id: 100,
		part_id: 223,
		quantity: 219,
	},
	{
		garage_id: 100,
		part_id: 224,
		quantity: 278,
	},
	{
		garage_id: 100,
		part_id: 225,
		quantity: 177,
	},
	{
		garage_id: 100,
		part_id: 226,
		quantity: 165,
	},
	{
		garage_id: 100,
		part_id: 227,
		quantity: 500,
	},
	{
		garage_id: 100,
		part_id: 228,
		quantity: 362,
	},
	{
		garage_id: 100,
		part_id: 229,
		quantity: 227,
	},
	{
		garage_id: 100,
		part_id: 230,
		quantity: 38,
	},
	{
		garage_id: 100,
		part_id: 231,
		quantity: 66,
	},
	{
		garage_id: 100,
		part_id: 232,
		quantity: 352,
	},
	{
		garage_id: 100,
		part_id: 233,
		quantity: 202,
	},
	{
		garage_id: 100,
		part_id: 234,
		quantity: 382,
	},
	{
		garage_id: 100,
		part_id: 235,
		quantity: 133,
	},
	{
		garage_id: 100,
		part_id: 236,
		quantity: 112,
	},
	{
		garage_id: 100,
		part_id: 237,
		quantity: 21,
	},
	{
		garage_id: 100,
		part_id: 238,
		quantity: 413,
	},
	{
		garage_id: 100,
		part_id: 239,
		quantity: 478,
	},
	{
		garage_id: 100,
		part_id: 240,
		quantity: 367,
	},
	{
		garage_id: 100,
		part_id: 241,
		quantity: 60,
	},
	{
		garage_id: 100,
		part_id: 242,
		quantity: 358,
	},
	{
		garage_id: 100,
		part_id: 243,
		quantity: 446,
	},
	{
		garage_id: 100,
		part_id: 244,
		quantity: 190,
	},
	{
		garage_id: 100,
		part_id: 245,
		quantity: 13,
	},
	{
		garage_id: 100,
		part_id: 246,
		quantity: 126,
	},
	{
		garage_id: 100,
		part_id: 247,
		quantity: 328,
	},
	{
		garage_id: 100,
		part_id: 248,
		quantity: 436,
	},
	{
		garage_id: 100,
		part_id: 249,
		quantity: 346,
	},
	{
		garage_id: 100,
		part_id: 250,
		quantity: 429,
	},
	{
		garage_id: 100,
		part_id: 251,
		quantity: 112,
	},
	{
		garage_id: 100,
		part_id: 252,
		quantity: 137,
	},
	{
		garage_id: 100,
		part_id: 253,
		quantity: 114,
	},
	{
		garage_id: 100,
		part_id: 254,
		quantity: 55,
	},
	{
		garage_id: 100,
		part_id: 255,
		quantity: 157,
	},
	{
		garage_id: 100,
		part_id: 256,
		quantity: 110,
	},
	{
		garage_id: 100,
		part_id: 257,
		quantity: 464,
	},
	{
		garage_id: 100,
		part_id: 258,
		quantity: 466,
	},
	{
		garage_id: 100,
		part_id: 259,
		quantity: 310,
	},
	{
		garage_id: 100,
		part_id: 260,
		quantity: 372,
	},
	{
		garage_id: 100,
		part_id: 261,
		quantity: 183,
	},
	{
		garage_id: 100,
		part_id: 262,
		quantity: 221,
	},
	{
		garage_id: 100,
		part_id: 263,
		quantity: 60,
	},
	{
		garage_id: 100,
		part_id: 264,
		quantity: 315,
	},
	{
		garage_id: 100,
		part_id: 265,
		quantity: 186,
	},
	{
		garage_id: 100,
		part_id: 266,
		quantity: 310,
	},
	{
		garage_id: 100,
		part_id: 267,
		quantity: 413,
	},
	{
		garage_id: 100,
		part_id: 268,
		quantity: 406,
	},
	{
		garage_id: 100,
		part_id: 269,
		quantity: 260,
	},
	{
		garage_id: 100,
		part_id: 270,
		quantity: 254,
	},
	{
		garage_id: 100,
		part_id: 271,
		quantity: 103,
	},
	{
		garage_id: 100,
		part_id: 272,
		quantity: 444,
	},
	{
		garage_id: 100,
		part_id: 273,
		quantity: 132,
	},
	{
		garage_id: 100,
		part_id: 274,
		quantity: 161,
	},
	{
		garage_id: 100,
		part_id: 275,
		quantity: 75,
	},
	{
		garage_id: 100,
		part_id: 276,
		quantity: 491,
	},
	{
		garage_id: 100,
		part_id: 277,
		quantity: 217,
	},
	{
		garage_id: 100,
		part_id: 278,
		quantity: 72,
	},
	{
		garage_id: 100,
		part_id: 279,
		quantity: 93,
	},
	{
		garage_id: 100,
		part_id: 280,
		quantity: 417,
	},
	{
		garage_id: 100,
		part_id: 281,
		quantity: 325,
	},
	{
		garage_id: 100,
		part_id: 282,
		quantity: 433,
	},
	{
		garage_id: 100,
		part_id: 283,
		quantity: 340,
	},
	{
		garage_id: 100,
		part_id: 284,
		quantity: 54,
	},
	{
		garage_id: 100,
		part_id: 285,
		quantity: 21,
	},
	{
		garage_id: 100,
		part_id: 286,
		quantity: 209,
	},
	{
		garage_id: 100,
		part_id: 287,
		quantity: 382,
	},
	{
		garage_id: 100,
		part_id: 288,
		quantity: 102,
	},
	{
		garage_id: 100,
		part_id: 289,
		quantity: 45,
	},
	{
		garage_id: 100,
		part_id: 290,
		quantity: 246,
	},
	{
		garage_id: 100,
		part_id: 291,
		quantity: 434,
	},
	{
		garage_id: 100,
		part_id: 292,
		quantity: 43,
	},
	{
		garage_id: 100,
		part_id: 293,
		quantity: 141,
	},
	{
		garage_id: 100,
		part_id: 294,
		quantity: 342,
	},
	{
		garage_id: 100,
		part_id: 295,
		quantity: 458,
	},
	{
		garage_id: 100,
		part_id: 296,
		quantity: 427,
	},
	{
		garage_id: 100,
		part_id: 297,
		quantity: 189,
	},
	{
		garage_id: 100,
		part_id: 298,
		quantity: 153,
	},
	{
		garage_id: 100,
		part_id: 299,
		quantity: 262,
	},
	{
		garage_id: 100,
		part_id: 300,
		quantity: 490,
	},
	{
		garage_id: 100,
		part_id: 301,
		quantity: 244,
	},
	{
		garage_id: 100,
		part_id: 302,
		quantity: 222,
	},
	{
		garage_id: 100,
		part_id: 303,
		quantity: 445,
	},
	{
		garage_id: 100,
		part_id: 304,
		quantity: 76,
	},
	{
		garage_id: 100,
		part_id: 305,
		quantity: 203,
	},
	{
		garage_id: 100,
		part_id: 306,
		quantity: 430,
	},
	{
		garage_id: 100,
		part_id: 307,
		quantity: 137,
	},
	{
		garage_id: 100,
		part_id: 308,
		quantity: 323,
	},
	{
		garage_id: 100,
		part_id: 309,
		quantity: 158,
	},
	{
		garage_id: 100,
		part_id: 310,
		quantity: 389,
	},
	{
		garage_id: 100,
		part_id: 311,
		quantity: 348,
	},
	{
		garage_id: 100,
		part_id: 312,
		quantity: 392,
	},
	{
		garage_id: 100,
		part_id: 313,
		quantity: 235,
	},
	{
		garage_id: 100,
		part_id: 314,
		quantity: 422,
	},
	{
		garage_id: 100,
		part_id: 315,
		quantity: 433,
	},
	{
		garage_id: 100,
		part_id: 316,
		quantity: 220,
	},
	{
		garage_id: 100,
		part_id: 317,
		quantity: 410,
	},
	{
		garage_id: 100,
		part_id: 318,
		quantity: 264,
	},
	{
		garage_id: 100,
		part_id: 319,
		quantity: 348,
	},
	{
		garage_id: 100,
		part_id: 320,
		quantity: 224,
	},
	{
		garage_id: 100,
		part_id: 321,
		quantity: 399,
	},
	{
		garage_id: 100,
		part_id: 322,
		quantity: 147,
	},
	{
		garage_id: 100,
		part_id: 323,
		quantity: 226,
	},
	{
		garage_id: 100,
		part_id: 324,
		quantity: 418,
	},
	{
		garage_id: 100,
		part_id: 325,
		quantity: 180,
	},
	{
		garage_id: 100,
		part_id: 326,
		quantity: 195,
	},
	{
		garage_id: 100,
		part_id: 327,
		quantity: 252,
	},
	{
		garage_id: 100,
		part_id: 328,
		quantity: 70,
	},
	{
		garage_id: 100,
		part_id: 329,
		quantity: 377,
	},
	{
		garage_id: 100,
		part_id: 330,
		quantity: 209,
	},
	{
		garage_id: 100,
		part_id: 331,
		quantity: 303,
	},
	{
		garage_id: 100,
		part_id: 332,
		quantity: 486,
	},
	{
		garage_id: 100,
		part_id: 333,
		quantity: 221,
	},
	{
		garage_id: 100,
		part_id: 334,
		quantity: 276,
	},
	{
		garage_id: 100,
		part_id: 335,
		quantity: 422,
	},
	{
		garage_id: 100,
		part_id: 336,
		quantity: 300,
	},
	{
		garage_id: 100,
		part_id: 337,
		quantity: 22,
	},
	{
		garage_id: 100,
		part_id: 338,
		quantity: 30,
	},
	{
		garage_id: 100,
		part_id: 339,
		quantity: 420,
	},
	{
		garage_id: 100,
		part_id: 340,
		quantity: 136,
	},
	{
		garage_id: 100,
		part_id: 341,
		quantity: 106,
	},
	{
		garage_id: 100,
		part_id: 342,
		quantity: 127,
	},
	{
		garage_id: 100,
		part_id: 343,
		quantity: 407,
	},
	{
		garage_id: 100,
		part_id: 344,
		quantity: 412,
	},
	{
		garage_id: 100,
		part_id: 345,
		quantity: 342,
	},
	{
		garage_id: 100,
		part_id: 346,
		quantity: 248,
	},
	{
		garage_id: 100,
		part_id: 347,
		quantity: 476,
	},
	{
		garage_id: 100,
		part_id: 348,
		quantity: 421,
	},
	{
		garage_id: 100,
		part_id: 349,
		quantity: 128,
	},
	{
		garage_id: 100,
		part_id: 350,
		quantity: 488,
	},
	{
		garage_id: 100,
		part_id: 351,
		quantity: 290,
	},
	{
		garage_id: 100,
		part_id: 352,
		quantity: 383,
	},
	{
		garage_id: 100,
		part_id: 353,
		quantity: 346,
	},
	{
		garage_id: 100,
		part_id: 354,
		quantity: 230,
	},
	{
		garage_id: 100,
		part_id: 355,
		quantity: 104,
	},
	{
		garage_id: 100,
		part_id: 356,
		quantity: 240,
	},
	{
		garage_id: 100,
		part_id: 357,
		quantity: 283,
	},
	{
		garage_id: 100,
		part_id: 358,
		quantity: 50,
	},
	{
		garage_id: 100,
		part_id: 359,
		quantity: 158,
	},
	{
		garage_id: 100,
		part_id: 360,
		quantity: 29,
	},
	{
		garage_id: 100,
		part_id: 361,
		quantity: 101,
	},
	{
		garage_id: 100,
		part_id: 362,
		quantity: 359,
	},
	{
		garage_id: 100,
		part_id: 363,
		quantity: 496,
	},
	{
		garage_id: 100,
		part_id: 364,
		quantity: 493,
	},
	{
		garage_id: 100,
		part_id: 365,
		quantity: 382,
	},
	{
		garage_id: 100,
		part_id: 366,
		quantity: 479,
	},
	{
		garage_id: 100,
		part_id: 367,
		quantity: 287,
	},
	{
		garage_id: 100,
		part_id: 368,
		quantity: 341,
	},
	{
		garage_id: 100,
		part_id: 369,
		quantity: 31,
	},
	{
		garage_id: 100,
		part_id: 370,
		quantity: 101,
	},
	{
		garage_id: 100,
		part_id: 371,
		quantity: 35,
	},
	{
		garage_id: 100,
		part_id: 372,
		quantity: 362,
	},
	{
		garage_id: 100,
		part_id: 373,
		quantity: 291,
	},
	{
		garage_id: 100,
		part_id: 374,
		quantity: 322,
	},
	{
		garage_id: 100,
		part_id: 375,
		quantity: 362,
	},
	{
		garage_id: 100,
		part_id: 376,
		quantity: 489,
	},
	{
		garage_id: 100,
		part_id: 377,
		quantity: 249,
	},
	{
		garage_id: 100,
		part_id: 378,
		quantity: 235,
	},
	{
		garage_id: 100,
		part_id: 379,
		quantity: 277,
	},
	{
		garage_id: 100,
		part_id: 380,
		quantity: 129,
	},
	{
		garage_id: 100,
		part_id: 381,
		quantity: 145,
	},
	{
		garage_id: 100,
		part_id: 382,
		quantity: 145,
	},
	{
		garage_id: 100,
		part_id: 383,
		quantity: 218,
	},
	{
		garage_id: 100,
		part_id: 384,
		quantity: 128,
	},
	{
		garage_id: 100,
		part_id: 385,
		quantity: 399,
	},
	{
		garage_id: 100,
		part_id: 386,
		quantity: 355,
	},
	{
		garage_id: 100,
		part_id: 387,
		quantity: 426,
	},
	{
		garage_id: 100,
		part_id: 388,
		quantity: 204,
	},
	{
		garage_id: 100,
		part_id: 389,
		quantity: 447,
	},
	{
		garage_id: 100,
		part_id: 390,
		quantity: 405,
	},
	{
		garage_id: 100,
		part_id: 391,
		quantity: 303,
	},
	{
		garage_id: 100,
		part_id: 392,
		quantity: 461,
	},
	{
		garage_id: 100,
		part_id: 393,
		quantity: 40,
	},
	{
		garage_id: 100,
		part_id: 394,
		quantity: 115,
	},
	{
		garage_id: 100,
		part_id: 395,
		quantity: 225,
	},
	{
		garage_id: 100,
		part_id: 396,
		quantity: 74,
	},
	{
		garage_id: 100,
		part_id: 397,
		quantity: 255,
	},
	{
		garage_id: 100,
		part_id: 398,
		quantity: 188,
	},
	{
		garage_id: 100,
		part_id: 399,
		quantity: 373,
	},
	{
		garage_id: 101,
		part_id: 0,
		quantity: 500,
	},
	{
		garage_id: 101,
		part_id: 1,
		quantity: 202,
	},
	{
		garage_id: 101,
		part_id: 2,
		quantity: 155,
	},
	{
		garage_id: 101,
		part_id: 3,
		quantity: 437,
	},
	{
		garage_id: 101,
		part_id: 4,
		quantity: 378,
	},
	{
		garage_id: 101,
		part_id: 5,
		quantity: 218,
	},
	{
		garage_id: 101,
		part_id: 6,
		quantity: 494,
	},
	{
		garage_id: 101,
		part_id: 7,
		quantity: 59,
	},
	{
		garage_id: 101,
		part_id: 8,
		quantity: 490,
	},
	{
		garage_id: 101,
		part_id: 9,
		quantity: 405,
	},
	{
		garage_id: 101,
		part_id: 10,
		quantity: 147,
	},
	{
		garage_id: 101,
		part_id: 11,
		quantity: 491,
	},
	{
		garage_id: 101,
		part_id: 12,
		quantity: 456,
	},
	{
		garage_id: 101,
		part_id: 13,
		quantity: 324,
	},
	{
		garage_id: 101,
		part_id: 14,
		quantity: 337,
	},
	{
		garage_id: 101,
		part_id: 15,
		quantity: 20,
	},
	{
		garage_id: 101,
		part_id: 16,
		quantity: 141,
	},
	{
		garage_id: 101,
		part_id: 17,
		quantity: 426,
	},
	{
		garage_id: 101,
		part_id: 18,
		quantity: 203,
	},
	{
		garage_id: 101,
		part_id: 19,
		quantity: 326,
	},
	{
		garage_id: 101,
		part_id: 20,
		quantity: 214,
	},
	{
		garage_id: 101,
		part_id: 21,
		quantity: 5,
	},
	{
		garage_id: 101,
		part_id: 22,
		quantity: 447,
	},
	{
		garage_id: 101,
		part_id: 23,
		quantity: 382,
	},
	{
		garage_id: 101,
		part_id: 24,
		quantity: 452,
	},
	{
		garage_id: 101,
		part_id: 25,
		quantity: 145,
	},
	{
		garage_id: 101,
		part_id: 26,
		quantity: 173,
	},
	{
		garage_id: 101,
		part_id: 27,
		quantity: 185,
	},
	{
		garage_id: 101,
		part_id: 28,
		quantity: 56,
	},
	{
		garage_id: 101,
		part_id: 29,
		quantity: 135,
	},
	{
		garage_id: 101,
		part_id: 30,
		quantity: 500,
	},
	{
		garage_id: 101,
		part_id: 31,
		quantity: 258,
	},
	{
		garage_id: 101,
		part_id: 32,
		quantity: 60,
	},
	{
		garage_id: 101,
		part_id: 33,
		quantity: 500,
	},
	{
		garage_id: 101,
		part_id: 34,
		quantity: 186,
	},
	{
		garage_id: 101,
		part_id: 35,
		quantity: 146,
	},
	{
		garage_id: 101,
		part_id: 36,
		quantity: 311,
	},
	{
		garage_id: 101,
		part_id: 37,
		quantity: 453,
	},
	{
		garage_id: 101,
		part_id: 38,
		quantity: 402,
	},
	{
		garage_id: 101,
		part_id: 39,
		quantity: 222,
	},
	{
		garage_id: 101,
		part_id: 40,
		quantity: 61,
	},
	{
		garage_id: 101,
		part_id: 41,
		quantity: 31,
	},
	{
		garage_id: 101,
		part_id: 42,
		quantity: 110,
	},
	{
		garage_id: 101,
		part_id: 43,
		quantity: 377,
	},
	{
		garage_id: 101,
		part_id: 44,
		quantity: 402,
	},
	{
		garage_id: 101,
		part_id: 45,
		quantity: 406,
	},
	{
		garage_id: 101,
		part_id: 46,
		quantity: 349,
	},
	{
		garage_id: 101,
		part_id: 47,
		quantity: 430,
	},
	{
		garage_id: 101,
		part_id: 48,
		quantity: 208,
	},
	{
		garage_id: 101,
		part_id: 49,
		quantity: 465,
	},
	{
		garage_id: 101,
		part_id: 50,
		quantity: 312,
	},
	{
		garage_id: 101,
		part_id: 51,
		quantity: 433,
	},
	{
		garage_id: 101,
		part_id: 52,
		quantity: 224,
	},
	{
		garage_id: 101,
		part_id: 53,
		quantity: 241,
	},
	{
		garage_id: 101,
		part_id: 54,
		quantity: 355,
	},
	{
		garage_id: 101,
		part_id: 55,
		quantity: 202,
	},
	{
		garage_id: 101,
		part_id: 56,
		quantity: 287,
	},
	{
		garage_id: 101,
		part_id: 57,
		quantity: 68,
	},
	{
		garage_id: 101,
		part_id: 58,
		quantity: 201,
	},
	{
		garage_id: 101,
		part_id: 59,
		quantity: 500,
	},
	{
		garage_id: 101,
		part_id: 60,
		quantity: 275,
	},
	{
		garage_id: 101,
		part_id: 61,
		quantity: 294,
	},
	{
		garage_id: 101,
		part_id: 62,
		quantity: 370,
	},
	{
		garage_id: 101,
		part_id: 63,
		quantity: 359,
	},
	{
		garage_id: 101,
		part_id: 64,
		quantity: 180,
	},
	{
		garage_id: 101,
		part_id: 65,
		quantity: 332,
	},
	{
		garage_id: 101,
		part_id: 66,
		quantity: 470,
	},
	{
		garage_id: 101,
		part_id: 67,
		quantity: 418,
	},
	{
		garage_id: 101,
		part_id: 68,
		quantity: 365,
	},
	{
		garage_id: 101,
		part_id: 69,
		quantity: 349,
	},
	{
		garage_id: 101,
		part_id: 70,
		quantity: 176,
	},
	{
		garage_id: 101,
		part_id: 71,
		quantity: 457,
	},
	{
		garage_id: 101,
		part_id: 72,
		quantity: 98,
	},
	{
		garage_id: 101,
		part_id: 73,
		quantity: 88,
	},
	{
		garage_id: 101,
		part_id: 74,
		quantity: 402,
	},
	{
		garage_id: 101,
		part_id: 75,
		quantity: 383,
	},
	{
		garage_id: 101,
		part_id: 76,
		quantity: 324,
	},
	{
		garage_id: 101,
		part_id: 77,
		quantity: 312,
	},
	{
		garage_id: 101,
		part_id: 78,
		quantity: 449,
	},
	{
		garage_id: 101,
		part_id: 79,
		quantity: 78,
	},
	{
		garage_id: 101,
		part_id: 80,
		quantity: 270,
	},
	{
		garage_id: 101,
		part_id: 81,
		quantity: 200,
	},
	{
		garage_id: 101,
		part_id: 82,
		quantity: 41,
	},
	{
		garage_id: 101,
		part_id: 83,
		quantity: 119,
	},
	{
		garage_id: 101,
		part_id: 84,
		quantity: 253,
	},
	{
		garage_id: 101,
		part_id: 85,
		quantity: 460,
	},
	{
		garage_id: 101,
		part_id: 86,
		quantity: 33,
	},
	{
		garage_id: 101,
		part_id: 87,
		quantity: 123,
	},
	{
		garage_id: 101,
		part_id: 88,
		quantity: 492,
	},
	{
		garage_id: 101,
		part_id: 89,
		quantity: 153,
	},
	{
		garage_id: 101,
		part_id: 90,
		quantity: 125,
	},
	{
		garage_id: 101,
		part_id: 91,
		quantity: 497,
	},
	{
		garage_id: 101,
		part_id: 92,
		quantity: 398,
	},
	{
		garage_id: 101,
		part_id: 93,
		quantity: 213,
	},
	{
		garage_id: 101,
		part_id: 94,
		quantity: 101,
	},
	{
		garage_id: 101,
		part_id: 95,
		quantity: 435,
	},
	{
		garage_id: 101,
		part_id: 96,
		quantity: 269,
	},
	{
		garage_id: 101,
		part_id: 97,
		quantity: 176,
	},
	{
		garage_id: 101,
		part_id: 98,
		quantity: 430,
	},
	{
		garage_id: 101,
		part_id: 99,
		quantity: 416,
	},
	{
		garage_id: 101,
		part_id: 100,
		quantity: 200,
	},
	{
		garage_id: 101,
		part_id: 101,
		quantity: 136,
	},
	{
		garage_id: 101,
		part_id: 102,
		quantity: 306,
	},
	{
		garage_id: 101,
		part_id: 103,
		quantity: 98,
	},
	{
		garage_id: 101,
		part_id: 104,
		quantity: 486,
	},
	{
		garage_id: 101,
		part_id: 105,
		quantity: 434,
	},
	{
		garage_id: 101,
		part_id: 106,
		quantity: 390,
	},
	{
		garage_id: 101,
		part_id: 107,
		quantity: 412,
	},
	{
		garage_id: 101,
		part_id: 108,
		quantity: 375,
	},
	{
		garage_id: 101,
		part_id: 109,
		quantity: 48,
	},
	{
		garage_id: 101,
		part_id: 110,
		quantity: 196,
	},
	{
		garage_id: 101,
		part_id: 111,
		quantity: 438,
	},
	{
		garage_id: 101,
		part_id: 112,
		quantity: 104,
	},
	{
		garage_id: 101,
		part_id: 113,
		quantity: 449,
	},
	{
		garage_id: 101,
		part_id: 114,
		quantity: 448,
	},
	{
		garage_id: 101,
		part_id: 115,
		quantity: 253,
	},
	{
		garage_id: 101,
		part_id: 116,
		quantity: 21,
	},
	{
		garage_id: 101,
		part_id: 117,
		quantity: 415,
	},
	{
		garage_id: 101,
		part_id: 118,
		quantity: 246,
	},
	{
		garage_id: 101,
		part_id: 119,
		quantity: 383,
	},
	{
		garage_id: 101,
		part_id: 120,
		quantity: 424,
	},
	{
		garage_id: 101,
		part_id: 121,
		quantity: 157,
	},
	{
		garage_id: 101,
		part_id: 122,
		quantity: 307,
	},
	{
		garage_id: 101,
		part_id: 123,
		quantity: 162,
	},
	{
		garage_id: 101,
		part_id: 124,
		quantity: 428,
	},
	{
		garage_id: 101,
		part_id: 125,
		quantity: 275,
	},
	{
		garage_id: 101,
		part_id: 126,
		quantity: 480,
	},
	{
		garage_id: 101,
		part_id: 127,
		quantity: 103,
	},
	{
		garage_id: 101,
		part_id: 128,
		quantity: 366,
	},
	{
		garage_id: 101,
		part_id: 129,
		quantity: 173,
	},
	{
		garage_id: 101,
		part_id: 130,
		quantity: 330,
	},
	{
		garage_id: 101,
		part_id: 131,
		quantity: 274,
	},
	{
		garage_id: 101,
		part_id: 132,
		quantity: 474,
	},
	{
		garage_id: 101,
		part_id: 133,
		quantity: 344,
	},
	{
		garage_id: 101,
		part_id: 134,
		quantity: 46,
	},
	{
		garage_id: 101,
		part_id: 135,
		quantity: 51,
	},
	{
		garage_id: 101,
		part_id: 136,
		quantity: 166,
	},
	{
		garage_id: 101,
		part_id: 137,
		quantity: 178,
	},
	{
		garage_id: 101,
		part_id: 138,
		quantity: 91,
	},
	{
		garage_id: 101,
		part_id: 139,
		quantity: 153,
	},
	{
		garage_id: 101,
		part_id: 140,
		quantity: 17,
	},
	{
		garage_id: 101,
		part_id: 141,
		quantity: 219,
	},
	{
		garage_id: 101,
		part_id: 142,
		quantity: 393,
	},
	{
		garage_id: 101,
		part_id: 143,
		quantity: 303,
	},
	{
		garage_id: 101,
		part_id: 144,
		quantity: 291,
	},
	{
		garage_id: 101,
		part_id: 145,
		quantity: 417,
	},
	{
		garage_id: 101,
		part_id: 146,
		quantity: 101,
	},
	{
		garage_id: 101,
		part_id: 147,
		quantity: 441,
	},
	{
		garage_id: 101,
		part_id: 148,
		quantity: 325,
	},
	{
		garage_id: 101,
		part_id: 149,
		quantity: 485,
	},
	{
		garage_id: 101,
		part_id: 150,
		quantity: 45,
	},
	{
		garage_id: 101,
		part_id: 151,
		quantity: 397,
	},
	{
		garage_id: 101,
		part_id: 152,
		quantity: 33,
	},
	{
		garage_id: 101,
		part_id: 153,
		quantity: 251,
	},
	{
		garage_id: 101,
		part_id: 154,
		quantity: 212,
	},
	{
		garage_id: 101,
		part_id: 155,
		quantity: 331,
	},
	{
		garage_id: 101,
		part_id: 156,
		quantity: 217,
	},
	{
		garage_id: 101,
		part_id: 157,
		quantity: 163,
	},
	{
		garage_id: 101,
		part_id: 158,
		quantity: 404,
	},
	{
		garage_id: 101,
		part_id: 159,
		quantity: 140,
	},
	{
		garage_id: 101,
		part_id: 160,
		quantity: 400,
	},
	{
		garage_id: 101,
		part_id: 161,
		quantity: 161,
	},
	{
		garage_id: 101,
		part_id: 162,
		quantity: 14,
	},
	{
		garage_id: 101,
		part_id: 163,
		quantity: 162,
	},
	{
		garage_id: 101,
		part_id: 164,
		quantity: 94,
	},
	{
		garage_id: 101,
		part_id: 165,
		quantity: 72,
	},
	{
		garage_id: 101,
		part_id: 166,
		quantity: 283,
	},
	{
		garage_id: 101,
		part_id: 167,
		quantity: 266,
	},
	{
		garage_id: 101,
		part_id: 168,
		quantity: 465,
	},
	{
		garage_id: 101,
		part_id: 169,
		quantity: 258,
	},
	{
		garage_id: 101,
		part_id: 170,
		quantity: 332,
	},
	{
		garage_id: 101,
		part_id: 171,
		quantity: 50,
	},
	{
		garage_id: 101,
		part_id: 172,
		quantity: 421,
	},
	{
		garage_id: 101,
		part_id: 173,
		quantity: 180,
	},
	{
		garage_id: 101,
		part_id: 174,
		quantity: 224,
	},
	{
		garage_id: 101,
		part_id: 175,
		quantity: 128,
	},
	{
		garage_id: 101,
		part_id: 176,
		quantity: 143,
	},
	{
		garage_id: 101,
		part_id: 177,
		quantity: 140,
	},
	{
		garage_id: 101,
		part_id: 178,
		quantity: 117,
	},
	{
		garage_id: 101,
		part_id: 179,
		quantity: 36,
	},
	{
		garage_id: 101,
		part_id: 180,
		quantity: 464,
	},
	{
		garage_id: 101,
		part_id: 181,
		quantity: 103,
	},
	{
		garage_id: 101,
		part_id: 182,
		quantity: 449,
	},
	{
		garage_id: 101,
		part_id: 183,
		quantity: 270,
	},
	{
		garage_id: 101,
		part_id: 184,
		quantity: 24,
	},
	{
		garage_id: 101,
		part_id: 185,
		quantity: 484,
	},
	{
		garage_id: 101,
		part_id: 186,
		quantity: 31,
	},
	{
		garage_id: 101,
		part_id: 187,
		quantity: 169,
	},
	{
		garage_id: 101,
		part_id: 188,
		quantity: 59,
	},
	{
		garage_id: 101,
		part_id: 189,
		quantity: 112,
	},
	{
		garage_id: 101,
		part_id: 190,
		quantity: 157,
	},
	{
		garage_id: 101,
		part_id: 191,
		quantity: 137,
	},
	{
		garage_id: 101,
		part_id: 192,
		quantity: 439,
	},
	{
		garage_id: 101,
		part_id: 193,
		quantity: 267,
	},
	{
		garage_id: 101,
		part_id: 194,
		quantity: 384,
	},
	{
		garage_id: 101,
		part_id: 195,
		quantity: 435,
	},
	{
		garage_id: 101,
		part_id: 196,
		quantity: 111,
	},
	{
		garage_id: 101,
		part_id: 197,
		quantity: 271,
	},
	{
		garage_id: 101,
		part_id: 198,
		quantity: 158,
	},
	{
		garage_id: 101,
		part_id: 199,
		quantity: 71,
	},
	{
		garage_id: 101,
		part_id: 200,
		quantity: 350,
	},
	{
		garage_id: 101,
		part_id: 201,
		quantity: 422,
	},
	{
		garage_id: 101,
		part_id: 202,
		quantity: 432,
	},
	{
		garage_id: 101,
		part_id: 203,
		quantity: 130,
	},
	{
		garage_id: 101,
		part_id: 204,
		quantity: 465,
	},
	{
		garage_id: 101,
		part_id: 205,
		quantity: 241,
	},
	{
		garage_id: 101,
		part_id: 206,
		quantity: 436,
	},
	{
		garage_id: 101,
		part_id: 207,
		quantity: 65,
	},
	{
		garage_id: 101,
		part_id: 208,
		quantity: 153,
	},
	{
		garage_id: 101,
		part_id: 209,
		quantity: 76,
	},
	{
		garage_id: 101,
		part_id: 210,
		quantity: 401,
	},
	{
		garage_id: 101,
		part_id: 211,
		quantity: 495,
	},
	{
		garage_id: 101,
		part_id: 212,
		quantity: 227,
	},
	{
		garage_id: 101,
		part_id: 213,
		quantity: 7,
	},
	{
		garage_id: 101,
		part_id: 214,
		quantity: 439,
	},
	{
		garage_id: 101,
		part_id: 215,
		quantity: 43,
	},
	{
		garage_id: 101,
		part_id: 216,
		quantity: 36,
	},
	{
		garage_id: 101,
		part_id: 217,
		quantity: 237,
	},
	{
		garage_id: 101,
		part_id: 218,
		quantity: 41,
	},
	{
		garage_id: 101,
		part_id: 219,
		quantity: 105,
	},
	{
		garage_id: 101,
		part_id: 220,
		quantity: 176,
	},
	{
		garage_id: 101,
		part_id: 221,
		quantity: 433,
	},
	{
		garage_id: 101,
		part_id: 222,
		quantity: 304,
	},
	{
		garage_id: 101,
		part_id: 223,
		quantity: 143,
	},
	{
		garage_id: 101,
		part_id: 224,
		quantity: 277,
	},
	{
		garage_id: 101,
		part_id: 225,
		quantity: 338,
	},
	{
		garage_id: 101,
		part_id: 226,
		quantity: 213,
	},
	{
		garage_id: 101,
		part_id: 227,
		quantity: 475,
	},
	{
		garage_id: 101,
		part_id: 228,
		quantity: 434,
	},
	{
		garage_id: 101,
		part_id: 229,
		quantity: 377,
	},
	{
		garage_id: 101,
		part_id: 230,
		quantity: 259,
	},
	{
		garage_id: 101,
		part_id: 231,
		quantity: 219,
	},
	{
		garage_id: 101,
		part_id: 232,
		quantity: 44,
	},
	{
		garage_id: 101,
		part_id: 233,
		quantity: 131,
	},
	{
		garage_id: 101,
		part_id: 234,
		quantity: 1,
	},
	{
		garage_id: 101,
		part_id: 235,
		quantity: 314,
	},
	{
		garage_id: 101,
		part_id: 236,
		quantity: 197,
	},
	{
		garage_id: 101,
		part_id: 237,
		quantity: 154,
	},
	{
		garage_id: 101,
		part_id: 238,
		quantity: 290,
	},
	{
		garage_id: 101,
		part_id: 239,
		quantity: 317,
	},
	{
		garage_id: 101,
		part_id: 240,
		quantity: 185,
	},
	{
		garage_id: 101,
		part_id: 241,
		quantity: 439,
	},
	{
		garage_id: 101,
		part_id: 242,
		quantity: 322,
	},
	{
		garage_id: 101,
		part_id: 243,
		quantity: 498,
	},
	{
		garage_id: 101,
		part_id: 244,
		quantity: 383,
	},
	{
		garage_id: 101,
		part_id: 245,
		quantity: 23,
	},
	{
		garage_id: 101,
		part_id: 246,
		quantity: 186,
	},
	{
		garage_id: 101,
		part_id: 247,
		quantity: 129,
	},
	{
		garage_id: 101,
		part_id: 248,
		quantity: 399,
	},
	{
		garage_id: 101,
		part_id: 249,
		quantity: 103,
	},
	{
		garage_id: 101,
		part_id: 250,
		quantity: 359,
	},
	{
		garage_id: 101,
		part_id: 251,
		quantity: 389,
	},
	{
		garage_id: 101,
		part_id: 252,
		quantity: 431,
	},
	{
		garage_id: 101,
		part_id: 253,
		quantity: 143,
	},
	{
		garage_id: 101,
		part_id: 254,
		quantity: 307,
	},
	{
		garage_id: 101,
		part_id: 255,
		quantity: 157,
	},
	{
		garage_id: 101,
		part_id: 256,
		quantity: 399,
	},
	{
		garage_id: 101,
		part_id: 257,
		quantity: 32,
	},
	{
		garage_id: 101,
		part_id: 258,
		quantity: 488,
	},
	{
		garage_id: 101,
		part_id: 259,
		quantity: 30,
	},
	{
		garage_id: 101,
		part_id: 260,
		quantity: 335,
	},
	{
		garage_id: 101,
		part_id: 261,
		quantity: 342,
	},
	{
		garage_id: 101,
		part_id: 262,
		quantity: 102,
	},
	{
		garage_id: 101,
		part_id: 263,
		quantity: 171,
	},
	{
		garage_id: 101,
		part_id: 264,
		quantity: 187,
	},
	{
		garage_id: 101,
		part_id: 265,
		quantity: 275,
	},
	{
		garage_id: 101,
		part_id: 266,
		quantity: 409,
	},
	{
		garage_id: 101,
		part_id: 267,
		quantity: 432,
	},
	{
		garage_id: 101,
		part_id: 268,
		quantity: 216,
	},
	{
		garage_id: 101,
		part_id: 269,
		quantity: 64,
	},
	{
		garage_id: 101,
		part_id: 270,
		quantity: 169,
	},
	{
		garage_id: 101,
		part_id: 271,
		quantity: 412,
	},
	{
		garage_id: 101,
		part_id: 272,
		quantity: 191,
	},
	{
		garage_id: 101,
		part_id: 273,
		quantity: 90,
	},
	{
		garage_id: 101,
		part_id: 274,
		quantity: 300,
	},
	{
		garage_id: 101,
		part_id: 275,
		quantity: 82,
	},
	{
		garage_id: 101,
		part_id: 276,
		quantity: 215,
	},
	{
		garage_id: 101,
		part_id: 277,
		quantity: 95,
	},
	{
		garage_id: 101,
		part_id: 278,
		quantity: 205,
	},
	{
		garage_id: 101,
		part_id: 279,
		quantity: 368,
	},
	{
		garage_id: 101,
		part_id: 280,
		quantity: 466,
	},
	{
		garage_id: 101,
		part_id: 281,
		quantity: 42,
	},
	{
		garage_id: 101,
		part_id: 282,
		quantity: 384,
	},
	{
		garage_id: 101,
		part_id: 283,
		quantity: 192,
	},
	{
		garage_id: 101,
		part_id: 284,
		quantity: 386,
	},
	{
		garage_id: 101,
		part_id: 285,
		quantity: 134,
	},
	{
		garage_id: 101,
		part_id: 286,
		quantity: 316,
	},
	{
		garage_id: 101,
		part_id: 287,
		quantity: 465,
	},
	{
		garage_id: 101,
		part_id: 288,
		quantity: 414,
	},
	{
		garage_id: 101,
		part_id: 289,
		quantity: 384,
	},
	{
		garage_id: 101,
		part_id: 290,
		quantity: 247,
	},
	{
		garage_id: 101,
		part_id: 291,
		quantity: 262,
	},
	{
		garage_id: 101,
		part_id: 292,
		quantity: 344,
	},
	{
		garage_id: 101,
		part_id: 293,
		quantity: 172,
	},
	{
		garage_id: 101,
		part_id: 294,
		quantity: 314,
	},
	{
		garage_id: 101,
		part_id: 295,
		quantity: 221,
	},
	{
		garage_id: 101,
		part_id: 296,
		quantity: 383,
	},
	{
		garage_id: 101,
		part_id: 297,
		quantity: 330,
	},
	{
		garage_id: 101,
		part_id: 298,
		quantity: 103,
	},
	{
		garage_id: 101,
		part_id: 299,
		quantity: 359,
	},
	{
		garage_id: 101,
		part_id: 300,
		quantity: 288,
	},
	{
		garage_id: 101,
		part_id: 301,
		quantity: 25,
	},
	{
		garage_id: 101,
		part_id: 302,
		quantity: 192,
	},
	{
		garage_id: 101,
		part_id: 303,
		quantity: 232,
	},
	{
		garage_id: 101,
		part_id: 304,
		quantity: 214,
	},
	{
		garage_id: 101,
		part_id: 305,
		quantity: 218,
	},
	{
		garage_id: 101,
		part_id: 306,
		quantity: 67,
	},
	{
		garage_id: 101,
		part_id: 307,
		quantity: 244,
	},
	{
		garage_id: 101,
		part_id: 308,
		quantity: 18,
	},
	{
		garage_id: 101,
		part_id: 309,
		quantity: 80,
	},
	{
		garage_id: 101,
		part_id: 310,
		quantity: 442,
	},
	{
		garage_id: 101,
		part_id: 311,
		quantity: 89,
	},
	{
		garage_id: 101,
		part_id: 312,
		quantity: 203,
	},
	{
		garage_id: 101,
		part_id: 313,
		quantity: 500,
	},
	{
		garage_id: 101,
		part_id: 314,
		quantity: 357,
	},
	{
		garage_id: 101,
		part_id: 315,
		quantity: 17,
	},
	{
		garage_id: 101,
		part_id: 316,
		quantity: 192,
	},
	{
		garage_id: 101,
		part_id: 317,
		quantity: 243,
	},
	{
		garage_id: 101,
		part_id: 318,
		quantity: 79,
	},
	{
		garage_id: 101,
		part_id: 319,
		quantity: 496,
	},
	{
		garage_id: 101,
		part_id: 320,
		quantity: 282,
	},
	{
		garage_id: 101,
		part_id: 321,
		quantity: 122,
	},
	{
		garage_id: 101,
		part_id: 322,
		quantity: 111,
	},
	{
		garage_id: 101,
		part_id: 323,
		quantity: 275,
	},
	{
		garage_id: 101,
		part_id: 324,
		quantity: 39,
	},
	{
		garage_id: 101,
		part_id: 325,
		quantity: 419,
	},
	{
		garage_id: 101,
		part_id: 326,
		quantity: 165,
	},
	{
		garage_id: 101,
		part_id: 327,
		quantity: 170,
	},
	{
		garage_id: 101,
		part_id: 328,
		quantity: 446,
	},
	{
		garage_id: 101,
		part_id: 329,
		quantity: 48,
	},
	{
		garage_id: 101,
		part_id: 330,
		quantity: 406,
	},
	{
		garage_id: 101,
		part_id: 331,
		quantity: 76,
	},
	{
		garage_id: 101,
		part_id: 332,
		quantity: 456,
	},
	{
		garage_id: 101,
		part_id: 333,
		quantity: 448,
	},
	{
		garage_id: 101,
		part_id: 334,
		quantity: 122,
	},
	{
		garage_id: 101,
		part_id: 335,
		quantity: 156,
	},
	{
		garage_id: 101,
		part_id: 336,
		quantity: 140,
	},
	{
		garage_id: 101,
		part_id: 337,
		quantity: 495,
	},
	{
		garage_id: 101,
		part_id: 338,
		quantity: 473,
	},
	{
		garage_id: 101,
		part_id: 339,
		quantity: 67,
	},
	{
		garage_id: 101,
		part_id: 340,
		quantity: 451,
	},
	{
		garage_id: 101,
		part_id: 341,
		quantity: 416,
	},
	{
		garage_id: 101,
		part_id: 342,
		quantity: 220,
	},
	{
		garage_id: 101,
		part_id: 343,
		quantity: 126,
	},
	{
		garage_id: 101,
		part_id: 344,
		quantity: 21,
	},
	{
		garage_id: 101,
		part_id: 345,
		quantity: 17,
	},
	{
		garage_id: 101,
		part_id: 346,
		quantity: 284,
	},
	{
		garage_id: 101,
		part_id: 347,
		quantity: 89,
	},
	{
		garage_id: 101,
		part_id: 348,
		quantity: 262,
	},
	{
		garage_id: 101,
		part_id: 349,
		quantity: 458,
	},
	{
		garage_id: 101,
		part_id: 350,
		quantity: 86,
	},
	{
		garage_id: 101,
		part_id: 351,
		quantity: 307,
	},
	{
		garage_id: 101,
		part_id: 352,
		quantity: 441,
	},
	{
		garage_id: 101,
		part_id: 353,
		quantity: 169,
	},
	{
		garage_id: 101,
		part_id: 354,
		quantity: 286,
	},
	{
		garage_id: 101,
		part_id: 355,
		quantity: 51,
	},
	{
		garage_id: 101,
		part_id: 356,
		quantity: 437,
	},
	{
		garage_id: 101,
		part_id: 357,
		quantity: 351,
	},
	{
		garage_id: 101,
		part_id: 358,
		quantity: 453,
	},
	{
		garage_id: 101,
		part_id: 359,
		quantity: 97,
	},
	{
		garage_id: 101,
		part_id: 360,
		quantity: 5,
	},
	{
		garage_id: 101,
		part_id: 361,
		quantity: 379,
	},
	{
		garage_id: 101,
		part_id: 362,
		quantity: 240,
	},
	{
		garage_id: 101,
		part_id: 363,
		quantity: 162,
	},
	{
		garage_id: 101,
		part_id: 364,
		quantity: 2,
	},
	{
		garage_id: 101,
		part_id: 365,
		quantity: 325,
	},
	{
		garage_id: 101,
		part_id: 366,
		quantity: 151,
	},
	{
		garage_id: 101,
		part_id: 367,
		quantity: 2,
	},
	{
		garage_id: 101,
		part_id: 368,
		quantity: 355,
	},
	{
		garage_id: 101,
		part_id: 369,
		quantity: 235,
	},
	{
		garage_id: 101,
		part_id: 370,
		quantity: 102,
	},
	{
		garage_id: 101,
		part_id: 371,
		quantity: 337,
	},
	{
		garage_id: 101,
		part_id: 372,
		quantity: 45,
	},
	{
		garage_id: 101,
		part_id: 373,
		quantity: 459,
	},
	{
		garage_id: 101,
		part_id: 374,
		quantity: 50,
	},
	{
		garage_id: 101,
		part_id: 375,
		quantity: 425,
	},
	{
		garage_id: 101,
		part_id: 376,
		quantity: 429,
	},
	{
		garage_id: 101,
		part_id: 377,
		quantity: 273,
	},
	{
		garage_id: 101,
		part_id: 378,
		quantity: 499,
	},
	{
		garage_id: 101,
		part_id: 379,
		quantity: 197,
	},
	{
		garage_id: 101,
		part_id: 380,
		quantity: 109,
	},
	{
		garage_id: 101,
		part_id: 381,
		quantity: 63,
	},
	{
		garage_id: 101,
		part_id: 382,
		quantity: 375,
	},
	{
		garage_id: 101,
		part_id: 383,
		quantity: 133,
	},
	{
		garage_id: 101,
		part_id: 384,
		quantity: 460,
	},
	{
		garage_id: 101,
		part_id: 385,
		quantity: 237,
	},
	{
		garage_id: 101,
		part_id: 386,
		quantity: 54,
	},
	{
		garage_id: 101,
		part_id: 387,
		quantity: 185,
	},
	{
		garage_id: 101,
		part_id: 388,
		quantity: 275,
	},
	{
		garage_id: 101,
		part_id: 389,
		quantity: 443,
	},
	{
		garage_id: 101,
		part_id: 390,
		quantity: 438,
	},
	{
		garage_id: 101,
		part_id: 391,
		quantity: 489,
	},
	{
		garage_id: 101,
		part_id: 392,
		quantity: 439,
	},
	{
		garage_id: 101,
		part_id: 393,
		quantity: 315,
	},
	{
		garage_id: 101,
		part_id: 394,
		quantity: 68,
	},
	{
		garage_id: 101,
		part_id: 395,
		quantity: 361,
	},
	{
		garage_id: 101,
		part_id: 396,
		quantity: 401,
	},
	{
		garage_id: 101,
		part_id: 397,
		quantity: 220,
	},
	{
		garage_id: 101,
		part_id: 398,
		quantity: 222,
	},
	{
		garage_id: 101,
		part_id: 399,
		quantity: 476,
	},
	{
		garage_id: 102,
		part_id: 0,
		quantity: 16,
	},
	{
		garage_id: 102,
		part_id: 1,
		quantity: 464,
	},
	{
		garage_id: 102,
		part_id: 2,
		quantity: 349,
	},
	{
		garage_id: 102,
		part_id: 3,
		quantity: 293,
	},
	{
		garage_id: 102,
		part_id: 4,
		quantity: 12,
	},
	{
		garage_id: 102,
		part_id: 5,
		quantity: 490,
	},
	{
		garage_id: 102,
		part_id: 6,
		quantity: 372,
	},
	{
		garage_id: 102,
		part_id: 7,
		quantity: 28,
	},
	{
		garage_id: 102,
		part_id: 8,
		quantity: 15,
	},
	{
		garage_id: 102,
		part_id: 9,
		quantity: 93,
	},
	{
		garage_id: 102,
		part_id: 10,
		quantity: 120,
	},
	{
		garage_id: 102,
		part_id: 11,
		quantity: 255,
	},
	{
		garage_id: 102,
		part_id: 12,
		quantity: 103,
	},
	{
		garage_id: 102,
		part_id: 13,
		quantity: 325,
	},
	{
		garage_id: 102,
		part_id: 14,
		quantity: 279,
	},
	{
		garage_id: 102,
		part_id: 15,
		quantity: 175,
	},
	{
		garage_id: 102,
		part_id: 16,
		quantity: 238,
	},
	{
		garage_id: 102,
		part_id: 17,
		quantity: 274,
	},
	{
		garage_id: 102,
		part_id: 18,
		quantity: 340,
	},
	{
		garage_id: 102,
		part_id: 19,
		quantity: 6,
	},
	{
		garage_id: 102,
		part_id: 20,
		quantity: 455,
	},
	{
		garage_id: 102,
		part_id: 21,
		quantity: 87,
	},
	{
		garage_id: 102,
		part_id: 22,
		quantity: 350,
	},
	{
		garage_id: 102,
		part_id: 23,
		quantity: 268,
	},
	{
		garage_id: 102,
		part_id: 24,
		quantity: 449,
	},
	{
		garage_id: 102,
		part_id: 25,
		quantity: 25,
	},
	{
		garage_id: 102,
		part_id: 26,
		quantity: 397,
	},
	{
		garage_id: 102,
		part_id: 27,
		quantity: 47,
	},
	{
		garage_id: 102,
		part_id: 28,
		quantity: 91,
	},
	{
		garage_id: 102,
		part_id: 29,
		quantity: 458,
	},
	{
		garage_id: 102,
		part_id: 30,
		quantity: 427,
	},
	{
		garage_id: 102,
		part_id: 31,
		quantity: 280,
	},
	{
		garage_id: 102,
		part_id: 32,
		quantity: 406,
	},
	{
		garage_id: 102,
		part_id: 33,
		quantity: 311,
	},
	{
		garage_id: 102,
		part_id: 34,
		quantity: 361,
	},
	{
		garage_id: 102,
		part_id: 35,
		quantity: 54,
	},
	{
		garage_id: 102,
		part_id: 36,
		quantity: 93,
	},
	{
		garage_id: 102,
		part_id: 37,
		quantity: 158,
	},
	{
		garage_id: 102,
		part_id: 38,
		quantity: 196,
	},
	{
		garage_id: 102,
		part_id: 39,
		quantity: 422,
	},
	{
		garage_id: 102,
		part_id: 40,
		quantity: 114,
	},
	{
		garage_id: 102,
		part_id: 41,
		quantity: 310,
	},
	{
		garage_id: 102,
		part_id: 42,
		quantity: 279,
	},
	{
		garage_id: 102,
		part_id: 43,
		quantity: 445,
	},
	{
		garage_id: 102,
		part_id: 44,
		quantity: 356,
	},
	{
		garage_id: 102,
		part_id: 45,
		quantity: 261,
	},
	{
		garage_id: 102,
		part_id: 46,
		quantity: 274,
	},
	{
		garage_id: 102,
		part_id: 47,
		quantity: 165,
	},
	{
		garage_id: 102,
		part_id: 48,
		quantity: 273,
	},
	{
		garage_id: 102,
		part_id: 49,
		quantity: 253,
	},
	{
		garage_id: 102,
		part_id: 50,
		quantity: 344,
	},
	{
		garage_id: 102,
		part_id: 51,
		quantity: 438,
	},
	{
		garage_id: 102,
		part_id: 52,
		quantity: 420,
	},
	{
		garage_id: 102,
		part_id: 53,
		quantity: 106,
	},
	{
		garage_id: 102,
		part_id: 54,
		quantity: 224,
	},
	{
		garage_id: 102,
		part_id: 55,
		quantity: 424,
	},
	{
		garage_id: 102,
		part_id: 56,
		quantity: 267,
	},
	{
		garage_id: 102,
		part_id: 57,
		quantity: 427,
	},
	{
		garage_id: 102,
		part_id: 58,
		quantity: 81,
	},
	{
		garage_id: 102,
		part_id: 59,
		quantity: 117,
	},
	{
		garage_id: 102,
		part_id: 60,
		quantity: 452,
	},
	{
		garage_id: 102,
		part_id: 61,
		quantity: 471,
	},
	{
		garage_id: 102,
		part_id: 62,
		quantity: 253,
	},
	{
		garage_id: 102,
		part_id: 63,
		quantity: 267,
	},
	{
		garage_id: 102,
		part_id: 64,
		quantity: 27,
	},
	{
		garage_id: 102,
		part_id: 65,
		quantity: 484,
	},
	{
		garage_id: 102,
		part_id: 66,
		quantity: 343,
	},
	{
		garage_id: 102,
		part_id: 67,
		quantity: 354,
	},
	{
		garage_id: 102,
		part_id: 68,
		quantity: 62,
	},
	{
		garage_id: 102,
		part_id: 69,
		quantity: 383,
	},
	{
		garage_id: 102,
		part_id: 70,
		quantity: 197,
	},
	{
		garage_id: 102,
		part_id: 71,
		quantity: 56,
	},
	{
		garage_id: 102,
		part_id: 72,
		quantity: 283,
	},
	{
		garage_id: 102,
		part_id: 73,
		quantity: 475,
	},
	{
		garage_id: 102,
		part_id: 74,
		quantity: 63,
	},
	{
		garage_id: 102,
		part_id: 75,
		quantity: 209,
	},
	{
		garage_id: 102,
		part_id: 76,
		quantity: 69,
	},
	{
		garage_id: 102,
		part_id: 77,
		quantity: 323,
	},
	{
		garage_id: 102,
		part_id: 78,
		quantity: 355,
	},
	{
		garage_id: 102,
		part_id: 79,
		quantity: 389,
	},
	{
		garage_id: 102,
		part_id: 80,
		quantity: 43,
	},
	{
		garage_id: 102,
		part_id: 81,
		quantity: 46,
	},
	{
		garage_id: 102,
		part_id: 82,
		quantity: 57,
	},
	{
		garage_id: 102,
		part_id: 83,
		quantity: 171,
	},
	{
		garage_id: 102,
		part_id: 84,
		quantity: 322,
	},
	{
		garage_id: 102,
		part_id: 85,
		quantity: 216,
	},
	{
		garage_id: 102,
		part_id: 86,
		quantity: 248,
	},
	{
		garage_id: 102,
		part_id: 87,
		quantity: 385,
	},
	{
		garage_id: 102,
		part_id: 88,
		quantity: 152,
	},
	{
		garage_id: 102,
		part_id: 89,
		quantity: 383,
	},
	{
		garage_id: 102,
		part_id: 90,
		quantity: 105,
	},
	{
		garage_id: 102,
		part_id: 91,
		quantity: 20,
	},
	{
		garage_id: 102,
		part_id: 92,
		quantity: 500,
	},
	{
		garage_id: 102,
		part_id: 93,
		quantity: 168,
	},
	{
		garage_id: 102,
		part_id: 94,
		quantity: 445,
	},
	{
		garage_id: 102,
		part_id: 95,
		quantity: 168,
	},
	{
		garage_id: 102,
		part_id: 96,
		quantity: 261,
	},
	{
		garage_id: 102,
		part_id: 97,
		quantity: 361,
	},
	{
		garage_id: 102,
		part_id: 98,
		quantity: 388,
	},
	{
		garage_id: 102,
		part_id: 99,
		quantity: 256,
	},
	{
		garage_id: 102,
		part_id: 100,
		quantity: 33,
	},
	{
		garage_id: 102,
		part_id: 101,
		quantity: 449,
	},
	{
		garage_id: 102,
		part_id: 102,
		quantity: 371,
	},
	{
		garage_id: 102,
		part_id: 103,
		quantity: 465,
	},
	{
		garage_id: 102,
		part_id: 104,
		quantity: 43,
	},
	{
		garage_id: 102,
		part_id: 105,
		quantity: 481,
	},
	{
		garage_id: 102,
		part_id: 106,
		quantity: 427,
	},
	{
		garage_id: 102,
		part_id: 107,
		quantity: 140,
	},
	{
		garage_id: 102,
		part_id: 108,
		quantity: 214,
	},
	{
		garage_id: 102,
		part_id: 109,
		quantity: 111,
	},
	{
		garage_id: 102,
		part_id: 110,
		quantity: 205,
	},
	{
		garage_id: 102,
		part_id: 111,
		quantity: 167,
	},
	{
		garage_id: 102,
		part_id: 112,
		quantity: 56,
	},
	{
		garage_id: 102,
		part_id: 113,
		quantity: 398,
	},
	{
		garage_id: 102,
		part_id: 114,
		quantity: 41,
	},
	{
		garage_id: 102,
		part_id: 115,
		quantity: 457,
	},
	{
		garage_id: 102,
		part_id: 116,
		quantity: 293,
	},
	{
		garage_id: 102,
		part_id: 117,
		quantity: 34,
	},
	{
		garage_id: 102,
		part_id: 118,
		quantity: 451,
	},
	{
		garage_id: 102,
		part_id: 119,
		quantity: 203,
	},
	{
		garage_id: 102,
		part_id: 120,
		quantity: 199,
	},
	{
		garage_id: 102,
		part_id: 121,
		quantity: 447,
	},
	{
		garage_id: 102,
		part_id: 122,
		quantity: 375,
	},
	{
		garage_id: 102,
		part_id: 123,
		quantity: 425,
	},
	{
		garage_id: 102,
		part_id: 124,
		quantity: 240,
	},
	{
		garage_id: 102,
		part_id: 125,
		quantity: 52,
	},
	{
		garage_id: 102,
		part_id: 126,
		quantity: 412,
	},
	{
		garage_id: 102,
		part_id: 127,
		quantity: 481,
	},
	{
		garage_id: 102,
		part_id: 128,
		quantity: 145,
	},
	{
		garage_id: 102,
		part_id: 129,
		quantity: 474,
	},
	{
		garage_id: 102,
		part_id: 130,
		quantity: 396,
	},
	{
		garage_id: 102,
		part_id: 131,
		quantity: 419,
	},
	{
		garage_id: 102,
		part_id: 132,
		quantity: 429,
	},
	{
		garage_id: 102,
		part_id: 133,
		quantity: 422,
	},
	{
		garage_id: 102,
		part_id: 134,
		quantity: 0,
	},
	{
		garage_id: 102,
		part_id: 135,
		quantity: 127,
	},
	{
		garage_id: 102,
		part_id: 136,
		quantity: 424,
	},
	{
		garage_id: 102,
		part_id: 137,
		quantity: 219,
	},
	{
		garage_id: 102,
		part_id: 138,
		quantity: 153,
	},
	{
		garage_id: 102,
		part_id: 139,
		quantity: 29,
	},
	{
		garage_id: 102,
		part_id: 140,
		quantity: 428,
	},
	{
		garage_id: 102,
		part_id: 141,
		quantity: 192,
	},
	{
		garage_id: 102,
		part_id: 142,
		quantity: 448,
	},
	{
		garage_id: 102,
		part_id: 143,
		quantity: 89,
	},
	{
		garage_id: 102,
		part_id: 144,
		quantity: 144,
	},
	{
		garage_id: 102,
		part_id: 145,
		quantity: 225,
	},
	{
		garage_id: 102,
		part_id: 146,
		quantity: 459,
	},
	{
		garage_id: 102,
		part_id: 147,
		quantity: 473,
	},
	{
		garage_id: 102,
		part_id: 148,
		quantity: 6,
	},
	{
		garage_id: 102,
		part_id: 149,
		quantity: 87,
	},
	{
		garage_id: 102,
		part_id: 150,
		quantity: 205,
	},
	{
		garage_id: 102,
		part_id: 151,
		quantity: 402,
	},
	{
		garage_id: 102,
		part_id: 152,
		quantity: 379,
	},
	{
		garage_id: 102,
		part_id: 153,
		quantity: 365,
	},
	{
		garage_id: 102,
		part_id: 154,
		quantity: 214,
	},
	{
		garage_id: 102,
		part_id: 155,
		quantity: 435,
	},
	{
		garage_id: 102,
		part_id: 156,
		quantity: 449,
	},
	{
		garage_id: 102,
		part_id: 157,
		quantity: 456,
	},
	{
		garage_id: 102,
		part_id: 158,
		quantity: 264,
	},
	{
		garage_id: 102,
		part_id: 159,
		quantity: 60,
	},
	{
		garage_id: 102,
		part_id: 160,
		quantity: 0,
	},
	{
		garage_id: 102,
		part_id: 161,
		quantity: 104,
	},
	{
		garage_id: 102,
		part_id: 162,
		quantity: 34,
	},
	{
		garage_id: 102,
		part_id: 163,
		quantity: 396,
	},
	{
		garage_id: 102,
		part_id: 164,
		quantity: 377,
	},
	{
		garage_id: 102,
		part_id: 165,
		quantity: 132,
	},
	{
		garage_id: 102,
		part_id: 166,
		quantity: 474,
	},
	{
		garage_id: 102,
		part_id: 167,
		quantity: 295,
	},
	{
		garage_id: 102,
		part_id: 168,
		quantity: 129,
	},
	{
		garage_id: 102,
		part_id: 169,
		quantity: 289,
	},
	{
		garage_id: 102,
		part_id: 170,
		quantity: 379,
	},
	{
		garage_id: 102,
		part_id: 171,
		quantity: 437,
	},
	{
		garage_id: 102,
		part_id: 172,
		quantity: 193,
	},
	{
		garage_id: 102,
		part_id: 173,
		quantity: 267,
	},
	{
		garage_id: 102,
		part_id: 174,
		quantity: 222,
	},
	{
		garage_id: 102,
		part_id: 175,
		quantity: 177,
	},
	{
		garage_id: 102,
		part_id: 176,
		quantity: 475,
	},
	{
		garage_id: 102,
		part_id: 177,
		quantity: 210,
	},
	{
		garage_id: 102,
		part_id: 178,
		quantity: 239,
	},
	{
		garage_id: 102,
		part_id: 179,
		quantity: 391,
	},
	{
		garage_id: 102,
		part_id: 180,
		quantity: 432,
	},
	{
		garage_id: 102,
		part_id: 181,
		quantity: 31,
	},
	{
		garage_id: 102,
		part_id: 182,
		quantity: 23,
	},
	{
		garage_id: 102,
		part_id: 183,
		quantity: 142,
	},
	{
		garage_id: 102,
		part_id: 184,
		quantity: 443,
	},
	{
		garage_id: 102,
		part_id: 185,
		quantity: 440,
	},
	{
		garage_id: 102,
		part_id: 186,
		quantity: 67,
	},
	{
		garage_id: 102,
		part_id: 187,
		quantity: 83,
	},
	{
		garage_id: 102,
		part_id: 188,
		quantity: 191,
	},
	{
		garage_id: 102,
		part_id: 189,
		quantity: 362,
	},
	{
		garage_id: 102,
		part_id: 190,
		quantity: 274,
	},
	{
		garage_id: 102,
		part_id: 191,
		quantity: 106,
	},
	{
		garage_id: 102,
		part_id: 192,
		quantity: 195,
	},
	{
		garage_id: 102,
		part_id: 193,
		quantity: 330,
	},
	{
		garage_id: 102,
		part_id: 194,
		quantity: 415,
	},
	{
		garage_id: 102,
		part_id: 195,
		quantity: 269,
	},
	{
		garage_id: 102,
		part_id: 196,
		quantity: 449,
	},
	{
		garage_id: 102,
		part_id: 197,
		quantity: 117,
	},
	{
		garage_id: 102,
		part_id: 198,
		quantity: 495,
	},
	{
		garage_id: 102,
		part_id: 199,
		quantity: 393,
	},
	{
		garage_id: 102,
		part_id: 200,
		quantity: 49,
	},
	{
		garage_id: 102,
		part_id: 201,
		quantity: 417,
	},
	{
		garage_id: 102,
		part_id: 202,
		quantity: 148,
	},
	{
		garage_id: 102,
		part_id: 203,
		quantity: 104,
	},
	{
		garage_id: 102,
		part_id: 204,
		quantity: 299,
	},
	{
		garage_id: 102,
		part_id: 205,
		quantity: 81,
	},
	{
		garage_id: 102,
		part_id: 206,
		quantity: 36,
	},
	{
		garage_id: 102,
		part_id: 207,
		quantity: 250,
	},
	{
		garage_id: 102,
		part_id: 208,
		quantity: 364,
	},
	{
		garage_id: 102,
		part_id: 209,
		quantity: 280,
	},
	{
		garage_id: 102,
		part_id: 210,
		quantity: 101,
	},
	{
		garage_id: 102,
		part_id: 211,
		quantity: 500,
	},
	{
		garage_id: 102,
		part_id: 212,
		quantity: 6,
	},
	{
		garage_id: 102,
		part_id: 213,
		quantity: 168,
	},
	{
		garage_id: 102,
		part_id: 214,
		quantity: 449,
	},
	{
		garage_id: 102,
		part_id: 215,
		quantity: 182,
	},
	{
		garage_id: 102,
		part_id: 216,
		quantity: 262,
	},
	{
		garage_id: 102,
		part_id: 217,
		quantity: 245,
	},
	{
		garage_id: 102,
		part_id: 218,
		quantity: 492,
	},
	{
		garage_id: 102,
		part_id: 219,
		quantity: 207,
	},
	{
		garage_id: 102,
		part_id: 220,
		quantity: 286,
	},
	{
		garage_id: 102,
		part_id: 221,
		quantity: 67,
	},
	{
		garage_id: 102,
		part_id: 222,
		quantity: 47,
	},
	{
		garage_id: 102,
		part_id: 223,
		quantity: 124,
	},
	{
		garage_id: 102,
		part_id: 224,
		quantity: 97,
	},
	{
		garage_id: 102,
		part_id: 225,
		quantity: 267,
	},
	{
		garage_id: 102,
		part_id: 226,
		quantity: 108,
	},
	{
		garage_id: 102,
		part_id: 227,
		quantity: 75,
	},
	{
		garage_id: 102,
		part_id: 228,
		quantity: 192,
	},
	{
		garage_id: 102,
		part_id: 229,
		quantity: 102,
	},
	{
		garage_id: 102,
		part_id: 230,
		quantity: 55,
	},
	{
		garage_id: 102,
		part_id: 231,
		quantity: 304,
	},
	{
		garage_id: 102,
		part_id: 232,
		quantity: 215,
	},
	{
		garage_id: 102,
		part_id: 233,
		quantity: 168,
	},
	{
		garage_id: 102,
		part_id: 234,
		quantity: 457,
	},
	{
		garage_id: 102,
		part_id: 235,
		quantity: 486,
	},
	{
		garage_id: 102,
		part_id: 236,
		quantity: 355,
	},
	{
		garage_id: 102,
		part_id: 237,
		quantity: 446,
	},
	{
		garage_id: 102,
		part_id: 238,
		quantity: 249,
	},
	{
		garage_id: 102,
		part_id: 239,
		quantity: 110,
	},
	{
		garage_id: 102,
		part_id: 240,
		quantity: 99,
	},
	{
		garage_id: 102,
		part_id: 241,
		quantity: 77,
	},
	{
		garage_id: 102,
		part_id: 242,
		quantity: 96,
	},
	{
		garage_id: 102,
		part_id: 243,
		quantity: 188,
	},
	{
		garage_id: 102,
		part_id: 244,
		quantity: 38,
	},
	{
		garage_id: 102,
		part_id: 245,
		quantity: 62,
	},
	{
		garage_id: 102,
		part_id: 246,
		quantity: 182,
	},
	{
		garage_id: 102,
		part_id: 247,
		quantity: 215,
	},
	{
		garage_id: 102,
		part_id: 248,
		quantity: 100,
	},
	{
		garage_id: 102,
		part_id: 249,
		quantity: 132,
	},
	{
		garage_id: 102,
		part_id: 250,
		quantity: 131,
	},
	{
		garage_id: 102,
		part_id: 251,
		quantity: 253,
	},
	{
		garage_id: 102,
		part_id: 252,
		quantity: 140,
	},
	{
		garage_id: 102,
		part_id: 253,
		quantity: 286,
	},
	{
		garage_id: 102,
		part_id: 254,
		quantity: 360,
	},
	{
		garage_id: 102,
		part_id: 255,
		quantity: 355,
	},
	{
		garage_id: 102,
		part_id: 256,
		quantity: 268,
	},
	{
		garage_id: 102,
		part_id: 257,
		quantity: 226,
	},
	{
		garage_id: 102,
		part_id: 258,
		quantity: 389,
	},
	{
		garage_id: 102,
		part_id: 259,
		quantity: 487,
	},
	{
		garage_id: 102,
		part_id: 260,
		quantity: 315,
	},
	{
		garage_id: 102,
		part_id: 261,
		quantity: 197,
	},
	{
		garage_id: 102,
		part_id: 262,
		quantity: 45,
	},
	{
		garage_id: 102,
		part_id: 263,
		quantity: 329,
	},
	{
		garage_id: 102,
		part_id: 264,
		quantity: 128,
	},
	{
		garage_id: 102,
		part_id: 265,
		quantity: 16,
	},
	{
		garage_id: 102,
		part_id: 266,
		quantity: 450,
	},
	{
		garage_id: 102,
		part_id: 267,
		quantity: 405,
	},
	{
		garage_id: 102,
		part_id: 268,
		quantity: 483,
	},
	{
		garage_id: 102,
		part_id: 269,
		quantity: 377,
	},
	{
		garage_id: 102,
		part_id: 270,
		quantity: 484,
	},
	{
		garage_id: 102,
		part_id: 271,
		quantity: 76,
	},
	{
		garage_id: 102,
		part_id: 272,
		quantity: 359,
	},
	{
		garage_id: 102,
		part_id: 273,
		quantity: 17,
	},
	{
		garage_id: 102,
		part_id: 274,
		quantity: 274,
	},
	{
		garage_id: 102,
		part_id: 275,
		quantity: 440,
	},
	{
		garage_id: 102,
		part_id: 276,
		quantity: 164,
	},
	{
		garage_id: 102,
		part_id: 277,
		quantity: 225,
	},
	{
		garage_id: 102,
		part_id: 278,
		quantity: 268,
	},
	{
		garage_id: 102,
		part_id: 279,
		quantity: 498,
	},
	{
		garage_id: 102,
		part_id: 280,
		quantity: 265,
	},
	{
		garage_id: 102,
		part_id: 281,
		quantity: 77,
	},
	{
		garage_id: 102,
		part_id: 282,
		quantity: 318,
	},
	{
		garage_id: 102,
		part_id: 283,
		quantity: 263,
	},
	{
		garage_id: 102,
		part_id: 284,
		quantity: 364,
	},
	{
		garage_id: 102,
		part_id: 285,
		quantity: 127,
	},
	{
		garage_id: 102,
		part_id: 286,
		quantity: 373,
	},
	{
		garage_id: 102,
		part_id: 287,
		quantity: 333,
	},
	{
		garage_id: 102,
		part_id: 288,
		quantity: 150,
	},
	{
		garage_id: 102,
		part_id: 289,
		quantity: 371,
	},
	{
		garage_id: 102,
		part_id: 290,
		quantity: 148,
	},
	{
		garage_id: 102,
		part_id: 291,
		quantity: 270,
	},
	{
		garage_id: 102,
		part_id: 292,
		quantity: 281,
	},
	{
		garage_id: 102,
		part_id: 293,
		quantity: 355,
	},
	{
		garage_id: 102,
		part_id: 294,
		quantity: 127,
	},
	{
		garage_id: 102,
		part_id: 295,
		quantity: 248,
	},
	{
		garage_id: 102,
		part_id: 296,
		quantity: 479,
	},
	{
		garage_id: 102,
		part_id: 297,
		quantity: 178,
	},
	{
		garage_id: 102,
		part_id: 298,
		quantity: 365,
	},
	{
		garage_id: 102,
		part_id: 299,
		quantity: 421,
	},
	{
		garage_id: 102,
		part_id: 300,
		quantity: 42,
	},
	{
		garage_id: 102,
		part_id: 301,
		quantity: 397,
	},
	{
		garage_id: 102,
		part_id: 302,
		quantity: 428,
	},
	{
		garage_id: 102,
		part_id: 303,
		quantity: 281,
	},
	{
		garage_id: 102,
		part_id: 304,
		quantity: 161,
	},
	{
		garage_id: 102,
		part_id: 305,
		quantity: 226,
	},
	{
		garage_id: 102,
		part_id: 306,
		quantity: 452,
	},
	{
		garage_id: 102,
		part_id: 307,
		quantity: 216,
	},
	{
		garage_id: 102,
		part_id: 308,
		quantity: 241,
	},
	{
		garage_id: 102,
		part_id: 309,
		quantity: 232,
	},
	{
		garage_id: 102,
		part_id: 310,
		quantity: 322,
	},
	{
		garage_id: 102,
		part_id: 311,
		quantity: 239,
	},
	{
		garage_id: 102,
		part_id: 312,
		quantity: 137,
	},
	{
		garage_id: 102,
		part_id: 313,
		quantity: 151,
	},
	{
		garage_id: 102,
		part_id: 314,
		quantity: 58,
	},
	{
		garage_id: 102,
		part_id: 315,
		quantity: 403,
	},
	{
		garage_id: 102,
		part_id: 316,
		quantity: 254,
	},
	{
		garage_id: 102,
		part_id: 317,
		quantity: 77,
	},
	{
		garage_id: 102,
		part_id: 318,
		quantity: 274,
	},
	{
		garage_id: 102,
		part_id: 319,
		quantity: 272,
	},
	{
		garage_id: 102,
		part_id: 320,
		quantity: 319,
	},
	{
		garage_id: 102,
		part_id: 321,
		quantity: 430,
	},
	{
		garage_id: 102,
		part_id: 322,
		quantity: 172,
	},
	{
		garage_id: 102,
		part_id: 323,
		quantity: 280,
	},
	{
		garage_id: 102,
		part_id: 324,
		quantity: 241,
	},
	{
		garage_id: 102,
		part_id: 325,
		quantity: 405,
	},
	{
		garage_id: 102,
		part_id: 326,
		quantity: 446,
	},
	{
		garage_id: 102,
		part_id: 327,
		quantity: 339,
	},
	{
		garage_id: 102,
		part_id: 328,
		quantity: 343,
	},
	{
		garage_id: 102,
		part_id: 329,
		quantity: 498,
	},
	{
		garage_id: 102,
		part_id: 330,
		quantity: 22,
	},
	{
		garage_id: 102,
		part_id: 331,
		quantity: 133,
	},
	{
		garage_id: 102,
		part_id: 332,
		quantity: 249,
	},
	{
		garage_id: 102,
		part_id: 333,
		quantity: 151,
	},
	{
		garage_id: 102,
		part_id: 334,
		quantity: 233,
	},
	{
		garage_id: 102,
		part_id: 335,
		quantity: 59,
	},
	{
		garage_id: 102,
		part_id: 336,
		quantity: 404,
	},
	{
		garage_id: 102,
		part_id: 337,
		quantity: 343,
	},
	{
		garage_id: 102,
		part_id: 338,
		quantity: 66,
	},
	{
		garage_id: 102,
		part_id: 339,
		quantity: 158,
	},
	{
		garage_id: 102,
		part_id: 340,
		quantity: 351,
	},
	{
		garage_id: 102,
		part_id: 341,
		quantity: 279,
	},
	{
		garage_id: 102,
		part_id: 342,
		quantity: 419,
	},
	{
		garage_id: 102,
		part_id: 343,
		quantity: 97,
	},
	{
		garage_id: 102,
		part_id: 344,
		quantity: 136,
	},
	{
		garage_id: 102,
		part_id: 345,
		quantity: 460,
	},
	{
		garage_id: 102,
		part_id: 346,
		quantity: 435,
	},
	{
		garage_id: 102,
		part_id: 347,
		quantity: 146,
	},
	{
		garage_id: 102,
		part_id: 348,
		quantity: 453,
	},
	{
		garage_id: 102,
		part_id: 349,
		quantity: 176,
	},
	{
		garage_id: 102,
		part_id: 350,
		quantity: 82,
	},
	{
		garage_id: 102,
		part_id: 351,
		quantity: 43,
	},
	{
		garage_id: 102,
		part_id: 352,
		quantity: 47,
	},
	{
		garage_id: 102,
		part_id: 353,
		quantity: 300,
	},
	{
		garage_id: 102,
		part_id: 354,
		quantity: 177,
	},
	{
		garage_id: 102,
		part_id: 355,
		quantity: 107,
	},
	{
		garage_id: 102,
		part_id: 356,
		quantity: 167,
	},
	{
		garage_id: 102,
		part_id: 357,
		quantity: 486,
	},
	{
		garage_id: 102,
		part_id: 358,
		quantity: 247,
	},
	{
		garage_id: 102,
		part_id: 359,
		quantity: 339,
	},
	{
		garage_id: 102,
		part_id: 360,
		quantity: 262,
	},
	{
		garage_id: 102,
		part_id: 361,
		quantity: 1,
	},
	{
		garage_id: 102,
		part_id: 362,
		quantity: 122,
	},
	{
		garage_id: 102,
		part_id: 363,
		quantity: 39,
	},
	{
		garage_id: 102,
		part_id: 364,
		quantity: 318,
	},
	{
		garage_id: 102,
		part_id: 365,
		quantity: 21,
	},
	{
		garage_id: 102,
		part_id: 366,
		quantity: 209,
	},
	{
		garage_id: 102,
		part_id: 367,
		quantity: 271,
	},
	{
		garage_id: 102,
		part_id: 368,
		quantity: 75,
	},
	{
		garage_id: 102,
		part_id: 369,
		quantity: 80,
	},
	{
		garage_id: 102,
		part_id: 370,
		quantity: 6,
	},
	{
		garage_id: 102,
		part_id: 371,
		quantity: 229,
	},
	{
		garage_id: 102,
		part_id: 372,
		quantity: 176,
	},
	{
		garage_id: 102,
		part_id: 373,
		quantity: 484,
	},
	{
		garage_id: 102,
		part_id: 374,
		quantity: 392,
	},
	{
		garage_id: 102,
		part_id: 375,
		quantity: 261,
	},
	{
		garage_id: 102,
		part_id: 376,
		quantity: 151,
	},
	{
		garage_id: 102,
		part_id: 377,
		quantity: 146,
	},
	{
		garage_id: 102,
		part_id: 378,
		quantity: 388,
	},
	{
		garage_id: 102,
		part_id: 379,
		quantity: 133,
	},
	{
		garage_id: 102,
		part_id: 380,
		quantity: 66,
	},
	{
		garage_id: 102,
		part_id: 381,
		quantity: 32,
	},
	{
		garage_id: 102,
		part_id: 382,
		quantity: 188,
	},
	{
		garage_id: 102,
		part_id: 383,
		quantity: 355,
	},
	{
		garage_id: 102,
		part_id: 384,
		quantity: 390,
	},
	{
		garage_id: 102,
		part_id: 385,
		quantity: 437,
	},
	{
		garage_id: 102,
		part_id: 386,
		quantity: 486,
	},
	{
		garage_id: 102,
		part_id: 387,
		quantity: 100,
	},
	{
		garage_id: 102,
		part_id: 388,
		quantity: 338,
	},
	{
		garage_id: 102,
		part_id: 389,
		quantity: 159,
	},
	{
		garage_id: 102,
		part_id: 390,
		quantity: 419,
	},
	{
		garage_id: 102,
		part_id: 391,
		quantity: 460,
	},
	{
		garage_id: 102,
		part_id: 392,
		quantity: 170,
	},
	{
		garage_id: 102,
		part_id: 393,
		quantity: 113,
	},
	{
		garage_id: 102,
		part_id: 394,
		quantity: 130,
	},
	{
		garage_id: 102,
		part_id: 395,
		quantity: 177,
	},
	{
		garage_id: 102,
		part_id: 396,
		quantity: 478,
	},
	{
		garage_id: 102,
		part_id: 397,
		quantity: 80,
	},
	{
		garage_id: 102,
		part_id: 398,
		quantity: 157,
	},
	{
		garage_id: 102,
		part_id: 399,
		quantity: 449,
	},
	{
		garage_id: 103,
		part_id: 0,
		quantity: 455,
	},
	{
		garage_id: 103,
		part_id: 1,
		quantity: 292,
	},
	{
		garage_id: 103,
		part_id: 2,
		quantity: 310,
	},
	{
		garage_id: 103,
		part_id: 3,
		quantity: 484,
	},
	{
		garage_id: 103,
		part_id: 4,
		quantity: 331,
	},
	{
		garage_id: 103,
		part_id: 5,
		quantity: 361,
	},
	{
		garage_id: 103,
		part_id: 6,
		quantity: 439,
	},
	{
		garage_id: 103,
		part_id: 7,
		quantity: 204,
	},
	{
		garage_id: 103,
		part_id: 8,
		quantity: 104,
	},
	{
		garage_id: 103,
		part_id: 9,
		quantity: 7,
	},
	{
		garage_id: 103,
		part_id: 10,
		quantity: 175,
	},
	{
		garage_id: 103,
		part_id: 11,
		quantity: 166,
	},
	{
		garage_id: 103,
		part_id: 12,
		quantity: 197,
	},
	{
		garage_id: 103,
		part_id: 13,
		quantity: 329,
	},
	{
		garage_id: 103,
		part_id: 14,
		quantity: 39,
	},
	{
		garage_id: 103,
		part_id: 15,
		quantity: 466,
	},
	{
		garage_id: 103,
		part_id: 16,
		quantity: 12,
	},
	{
		garage_id: 103,
		part_id: 17,
		quantity: 434,
	},
	{
		garage_id: 103,
		part_id: 18,
		quantity: 473,
	},
	{
		garage_id: 103,
		part_id: 19,
		quantity: 98,
	},
	{
		garage_id: 103,
		part_id: 20,
		quantity: 241,
	},
	{
		garage_id: 103,
		part_id: 21,
		quantity: 304,
	},
	{
		garage_id: 103,
		part_id: 22,
		quantity: 234,
	},
	{
		garage_id: 103,
		part_id: 23,
		quantity: 210,
	},
	{
		garage_id: 103,
		part_id: 24,
		quantity: 89,
	},
	{
		garage_id: 103,
		part_id: 25,
		quantity: 307,
	},
	{
		garage_id: 103,
		part_id: 26,
		quantity: 398,
	},
	{
		garage_id: 103,
		part_id: 27,
		quantity: 329,
	},
	{
		garage_id: 103,
		part_id: 28,
		quantity: 146,
	},
	{
		garage_id: 103,
		part_id: 29,
		quantity: 211,
	},
	{
		garage_id: 103,
		part_id: 30,
		quantity: 204,
	},
	{
		garage_id: 103,
		part_id: 31,
		quantity: 481,
	},
	{
		garage_id: 103,
		part_id: 32,
		quantity: 50,
	},
	{
		garage_id: 103,
		part_id: 33,
		quantity: 320,
	},
	{
		garage_id: 103,
		part_id: 34,
		quantity: 397,
	},
	{
		garage_id: 103,
		part_id: 35,
		quantity: 174,
	},
	{
		garage_id: 103,
		part_id: 36,
		quantity: 415,
	},
	{
		garage_id: 103,
		part_id: 37,
		quantity: 300,
	},
	{
		garage_id: 103,
		part_id: 38,
		quantity: 23,
	},
	{
		garage_id: 103,
		part_id: 39,
		quantity: 186,
	},
	{
		garage_id: 103,
		part_id: 40,
		quantity: 459,
	},
	{
		garage_id: 103,
		part_id: 41,
		quantity: 78,
	},
	{
		garage_id: 103,
		part_id: 42,
		quantity: 397,
	},
	{
		garage_id: 103,
		part_id: 43,
		quantity: 131,
	},
	{
		garage_id: 103,
		part_id: 44,
		quantity: 150,
	},
	{
		garage_id: 103,
		part_id: 45,
		quantity: 179,
	},
	{
		garage_id: 103,
		part_id: 46,
		quantity: 223,
	},
	{
		garage_id: 103,
		part_id: 47,
		quantity: 73,
	},
	{
		garage_id: 103,
		part_id: 48,
		quantity: 260,
	},
	{
		garage_id: 103,
		part_id: 49,
		quantity: 115,
	},
	{
		garage_id: 103,
		part_id: 50,
		quantity: 244,
	},
	{
		garage_id: 103,
		part_id: 51,
		quantity: 126,
	},
	{
		garage_id: 103,
		part_id: 52,
		quantity: 266,
	},
	{
		garage_id: 103,
		part_id: 53,
		quantity: 1,
	},
	{
		garage_id: 103,
		part_id: 54,
		quantity: 194,
	},
	{
		garage_id: 103,
		part_id: 55,
		quantity: 128,
	},
	{
		garage_id: 103,
		part_id: 56,
		quantity: 295,
	},
	{
		garage_id: 103,
		part_id: 57,
		quantity: 162,
	},
	{
		garage_id: 103,
		part_id: 58,
		quantity: 50,
	},
	{
		garage_id: 103,
		part_id: 59,
		quantity: 387,
	},
	{
		garage_id: 103,
		part_id: 60,
		quantity: 70,
	},
	{
		garage_id: 103,
		part_id: 61,
		quantity: 76,
	},
	{
		garage_id: 103,
		part_id: 62,
		quantity: 407,
	},
	{
		garage_id: 103,
		part_id: 63,
		quantity: 182,
	},
	{
		garage_id: 103,
		part_id: 64,
		quantity: 158,
	},
	{
		garage_id: 103,
		part_id: 65,
		quantity: 376,
	},
	{
		garage_id: 103,
		part_id: 66,
		quantity: 468,
	},
	{
		garage_id: 103,
		part_id: 67,
		quantity: 359,
	},
	{
		garage_id: 103,
		part_id: 68,
		quantity: 136,
	},
	{
		garage_id: 103,
		part_id: 69,
		quantity: 296,
	},
	{
		garage_id: 103,
		part_id: 70,
		quantity: 246,
	},
	{
		garage_id: 103,
		part_id: 71,
		quantity: 404,
	},
	{
		garage_id: 103,
		part_id: 72,
		quantity: 348,
	},
	{
		garage_id: 103,
		part_id: 73,
		quantity: 368,
	},
	{
		garage_id: 103,
		part_id: 74,
		quantity: 28,
	},
	{
		garage_id: 103,
		part_id: 75,
		quantity: 199,
	},
	{
		garage_id: 103,
		part_id: 76,
		quantity: 122,
	},
	{
		garage_id: 103,
		part_id: 77,
		quantity: 371,
	},
	{
		garage_id: 103,
		part_id: 78,
		quantity: 484,
	},
	{
		garage_id: 103,
		part_id: 79,
		quantity: 156,
	},
	{
		garage_id: 103,
		part_id: 80,
		quantity: 206,
	},
	{
		garage_id: 103,
		part_id: 81,
		quantity: 495,
	},
	{
		garage_id: 103,
		part_id: 82,
		quantity: 325,
	},
	{
		garage_id: 103,
		part_id: 83,
		quantity: 382,
	},
	{
		garage_id: 103,
		part_id: 84,
		quantity: 96,
	},
	{
		garage_id: 103,
		part_id: 85,
		quantity: 309,
	},
	{
		garage_id: 103,
		part_id: 86,
		quantity: 294,
	},
	{
		garage_id: 103,
		part_id: 87,
		quantity: 265,
	},
	{
		garage_id: 103,
		part_id: 88,
		quantity: 373,
	},
	{
		garage_id: 103,
		part_id: 89,
		quantity: 323,
	},
	{
		garage_id: 103,
		part_id: 90,
		quantity: 57,
	},
	{
		garage_id: 103,
		part_id: 91,
		quantity: 276,
	},
	{
		garage_id: 103,
		part_id: 92,
		quantity: 465,
	},
	{
		garage_id: 103,
		part_id: 93,
		quantity: 396,
	},
	{
		garage_id: 103,
		part_id: 94,
		quantity: 65,
	},
	{
		garage_id: 103,
		part_id: 95,
		quantity: 400,
	},
	{
		garage_id: 103,
		part_id: 96,
		quantity: 452,
	},
	{
		garage_id: 103,
		part_id: 97,
		quantity: 343,
	},
	{
		garage_id: 103,
		part_id: 98,
		quantity: 492,
	},
	{
		garage_id: 103,
		part_id: 99,
		quantity: 311,
	},
	{
		garage_id: 103,
		part_id: 100,
		quantity: 3,
	},
	{
		garage_id: 103,
		part_id: 101,
		quantity: 479,
	},
	{
		garage_id: 103,
		part_id: 102,
		quantity: 300,
	},
	{
		garage_id: 103,
		part_id: 103,
		quantity: 14,
	},
	{
		garage_id: 103,
		part_id: 104,
		quantity: 188,
	},
	{
		garage_id: 103,
		part_id: 105,
		quantity: 265,
	},
	{
		garage_id: 103,
		part_id: 106,
		quantity: 90,
	},
	{
		garage_id: 103,
		part_id: 107,
		quantity: 302,
	},
	{
		garage_id: 103,
		part_id: 108,
		quantity: 46,
	},
	{
		garage_id: 103,
		part_id: 109,
		quantity: 245,
	},
	{
		garage_id: 103,
		part_id: 110,
		quantity: 417,
	},
	{
		garage_id: 103,
		part_id: 111,
		quantity: 449,
	},
	{
		garage_id: 103,
		part_id: 112,
		quantity: 107,
	},
	{
		garage_id: 103,
		part_id: 113,
		quantity: 37,
	},
	{
		garage_id: 103,
		part_id: 114,
		quantity: 444,
	},
	{
		garage_id: 103,
		part_id: 115,
		quantity: 446,
	},
	{
		garage_id: 103,
		part_id: 116,
		quantity: 141,
	},
	{
		garage_id: 103,
		part_id: 117,
		quantity: 393,
	},
	{
		garage_id: 103,
		part_id: 118,
		quantity: 105,
	},
	{
		garage_id: 103,
		part_id: 119,
		quantity: 205,
	},
	{
		garage_id: 103,
		part_id: 120,
		quantity: 167,
	},
	{
		garage_id: 103,
		part_id: 121,
		quantity: 382,
	},
	{
		garage_id: 103,
		part_id: 122,
		quantity: 479,
	},
	{
		garage_id: 103,
		part_id: 123,
		quantity: 27,
	},
	{
		garage_id: 103,
		part_id: 124,
		quantity: 120,
	},
	{
		garage_id: 103,
		part_id: 125,
		quantity: 185,
	},
	{
		garage_id: 103,
		part_id: 126,
		quantity: 4,
	},
	{
		garage_id: 103,
		part_id: 127,
		quantity: 436,
	},
	{
		garage_id: 103,
		part_id: 128,
		quantity: 147,
	},
	{
		garage_id: 103,
		part_id: 129,
		quantity: 357,
	},
	{
		garage_id: 103,
		part_id: 130,
		quantity: 290,
	},
	{
		garage_id: 103,
		part_id: 131,
		quantity: 370,
	},
	{
		garage_id: 103,
		part_id: 132,
		quantity: 466,
	},
	{
		garage_id: 103,
		part_id: 133,
		quantity: 439,
	},
	{
		garage_id: 103,
		part_id: 134,
		quantity: 196,
	},
	{
		garage_id: 103,
		part_id: 135,
		quantity: 331,
	},
	{
		garage_id: 103,
		part_id: 136,
		quantity: 309,
	},
	{
		garage_id: 103,
		part_id: 137,
		quantity: 90,
	},
	{
		garage_id: 103,
		part_id: 138,
		quantity: 288,
	},
	{
		garage_id: 103,
		part_id: 139,
		quantity: 177,
	},
	{
		garage_id: 103,
		part_id: 140,
		quantity: 64,
	},
	{
		garage_id: 103,
		part_id: 141,
		quantity: 256,
	},
	{
		garage_id: 103,
		part_id: 142,
		quantity: 364,
	},
	{
		garage_id: 103,
		part_id: 143,
		quantity: 480,
	},
	{
		garage_id: 103,
		part_id: 144,
		quantity: 221,
	},
	{
		garage_id: 103,
		part_id: 145,
		quantity: 212,
	},
	{
		garage_id: 103,
		part_id: 146,
		quantity: 175,
	},
	{
		garage_id: 103,
		part_id: 147,
		quantity: 179,
	},
	{
		garage_id: 103,
		part_id: 148,
		quantity: 266,
	},
	{
		garage_id: 103,
		part_id: 149,
		quantity: 376,
	},
	{
		garage_id: 103,
		part_id: 150,
		quantity: 15,
	},
	{
		garage_id: 103,
		part_id: 151,
		quantity: 468,
	},
	{
		garage_id: 103,
		part_id: 152,
		quantity: 233,
	},
	{
		garage_id: 103,
		part_id: 153,
		quantity: 109,
	},
	{
		garage_id: 103,
		part_id: 154,
		quantity: 246,
	},
	{
		garage_id: 103,
		part_id: 155,
		quantity: 175,
	},
	{
		garage_id: 103,
		part_id: 156,
		quantity: 131,
	},
	{
		garage_id: 103,
		part_id: 157,
		quantity: 232,
	},
	{
		garage_id: 103,
		part_id: 158,
		quantity: 217,
	},
	{
		garage_id: 103,
		part_id: 159,
		quantity: 412,
	},
	{
		garage_id: 103,
		part_id: 160,
		quantity: 334,
	},
	{
		garage_id: 103,
		part_id: 161,
		quantity: 396,
	},
	{
		garage_id: 103,
		part_id: 162,
		quantity: 12,
	},
	{
		garage_id: 103,
		part_id: 163,
		quantity: 347,
	},
	{
		garage_id: 103,
		part_id: 164,
		quantity: 113,
	},
	{
		garage_id: 103,
		part_id: 165,
		quantity: 272,
	},
	{
		garage_id: 103,
		part_id: 166,
		quantity: 113,
	},
	{
		garage_id: 103,
		part_id: 167,
		quantity: 440,
	},
	{
		garage_id: 103,
		part_id: 168,
		quantity: 376,
	},
	{
		garage_id: 103,
		part_id: 169,
		quantity: 15,
	},
	{
		garage_id: 103,
		part_id: 170,
		quantity: 131,
	},
	{
		garage_id: 103,
		part_id: 171,
		quantity: 171,
	},
	{
		garage_id: 103,
		part_id: 172,
		quantity: 202,
	},
	{
		garage_id: 103,
		part_id: 173,
		quantity: 375,
	},
	{
		garage_id: 103,
		part_id: 174,
		quantity: 445,
	},
	{
		garage_id: 103,
		part_id: 175,
		quantity: 222,
	},
	{
		garage_id: 103,
		part_id: 176,
		quantity: 325,
	},
	{
		garage_id: 103,
		part_id: 177,
		quantity: 178,
	},
	{
		garage_id: 103,
		part_id: 178,
		quantity: 99,
	},
	{
		garage_id: 103,
		part_id: 179,
		quantity: 125,
	},
	{
		garage_id: 103,
		part_id: 180,
		quantity: 45,
	},
	{
		garage_id: 103,
		part_id: 181,
		quantity: 47,
	},
	{
		garage_id: 103,
		part_id: 182,
		quantity: 367,
	},
	{
		garage_id: 103,
		part_id: 183,
		quantity: 299,
	},
	{
		garage_id: 103,
		part_id: 184,
		quantity: 41,
	},
	{
		garage_id: 103,
		part_id: 185,
		quantity: 76,
	},
	{
		garage_id: 103,
		part_id: 186,
		quantity: 302,
	},
	{
		garage_id: 103,
		part_id: 187,
		quantity: 83,
	},
	{
		garage_id: 103,
		part_id: 188,
		quantity: 275,
	},
	{
		garage_id: 103,
		part_id: 189,
		quantity: 189,
	},
	{
		garage_id: 103,
		part_id: 190,
		quantity: 400,
	},
	{
		garage_id: 103,
		part_id: 191,
		quantity: 117,
	},
	{
		garage_id: 103,
		part_id: 192,
		quantity: 333,
	},
	{
		garage_id: 103,
		part_id: 193,
		quantity: 71,
	},
	{
		garage_id: 103,
		part_id: 194,
		quantity: 21,
	},
	{
		garage_id: 103,
		part_id: 195,
		quantity: 286,
	},
	{
		garage_id: 103,
		part_id: 196,
		quantity: 52,
	},
	{
		garage_id: 103,
		part_id: 197,
		quantity: 67,
	},
	{
		garage_id: 103,
		part_id: 198,
		quantity: 45,
	},
	{
		garage_id: 103,
		part_id: 199,
		quantity: 126,
	},
	{
		garage_id: 103,
		part_id: 200,
		quantity: 45,
	},
	{
		garage_id: 103,
		part_id: 201,
		quantity: 434,
	},
	{
		garage_id: 103,
		part_id: 202,
		quantity: 181,
	},
	{
		garage_id: 103,
		part_id: 203,
		quantity: 483,
	},
	{
		garage_id: 103,
		part_id: 204,
		quantity: 108,
	},
	{
		garage_id: 103,
		part_id: 205,
		quantity: 310,
	},
	{
		garage_id: 103,
		part_id: 206,
		quantity: 193,
	},
	{
		garage_id: 103,
		part_id: 207,
		quantity: 167,
	},
	{
		garage_id: 103,
		part_id: 208,
		quantity: 52,
	},
	{
		garage_id: 103,
		part_id: 209,
		quantity: 281,
	},
	{
		garage_id: 103,
		part_id: 210,
		quantity: 273,
	},
	{
		garage_id: 103,
		part_id: 211,
		quantity: 302,
	},
	{
		garage_id: 103,
		part_id: 212,
		quantity: 315,
	},
	{
		garage_id: 103,
		part_id: 213,
		quantity: 497,
	},
	{
		garage_id: 103,
		part_id: 214,
		quantity: 292,
	},
	{
		garage_id: 103,
		part_id: 215,
		quantity: 1,
	},
	{
		garage_id: 103,
		part_id: 216,
		quantity: 419,
	},
	{
		garage_id: 103,
		part_id: 217,
		quantity: 143,
	},
	{
		garage_id: 103,
		part_id: 218,
		quantity: 189,
	},
	{
		garage_id: 103,
		part_id: 219,
		quantity: 35,
	},
	{
		garage_id: 103,
		part_id: 220,
		quantity: 70,
	},
	{
		garage_id: 103,
		part_id: 221,
		quantity: 47,
	},
	{
		garage_id: 103,
		part_id: 222,
		quantity: 445,
	},
	{
		garage_id: 103,
		part_id: 223,
		quantity: 4,
	},
	{
		garage_id: 103,
		part_id: 224,
		quantity: 62,
	},
	{
		garage_id: 103,
		part_id: 225,
		quantity: 500,
	},
	{
		garage_id: 103,
		part_id: 226,
		quantity: 358,
	},
	{
		garage_id: 103,
		part_id: 227,
		quantity: 137,
	},
	{
		garage_id: 103,
		part_id: 228,
		quantity: 45,
	},
	{
		garage_id: 103,
		part_id: 229,
		quantity: 219,
	},
	{
		garage_id: 103,
		part_id: 230,
		quantity: 234,
	},
	{
		garage_id: 103,
		part_id: 231,
		quantity: 500,
	},
	{
		garage_id: 103,
		part_id: 232,
		quantity: 304,
	},
	{
		garage_id: 103,
		part_id: 233,
		quantity: 13,
	},
	{
		garage_id: 103,
		part_id: 234,
		quantity: 256,
	},
	{
		garage_id: 103,
		part_id: 235,
		quantity: 359,
	},
	{
		garage_id: 103,
		part_id: 236,
		quantity: 338,
	},
	{
		garage_id: 103,
		part_id: 237,
		quantity: 161,
	},
	{
		garage_id: 103,
		part_id: 238,
		quantity: 188,
	},
	{
		garage_id: 103,
		part_id: 239,
		quantity: 469,
	},
	{
		garage_id: 103,
		part_id: 240,
		quantity: 177,
	},
	{
		garage_id: 103,
		part_id: 241,
		quantity: 379,
	},
	{
		garage_id: 103,
		part_id: 242,
		quantity: 7,
	},
	{
		garage_id: 103,
		part_id: 243,
		quantity: 239,
	},
	{
		garage_id: 103,
		part_id: 244,
		quantity: 487,
	},
	{
		garage_id: 103,
		part_id: 245,
		quantity: 312,
	},
	{
		garage_id: 103,
		part_id: 246,
		quantity: 371,
	},
	{
		garage_id: 103,
		part_id: 247,
		quantity: 13,
	},
	{
		garage_id: 103,
		part_id: 248,
		quantity: 396,
	},
	{
		garage_id: 103,
		part_id: 249,
		quantity: 191,
	},
	{
		garage_id: 103,
		part_id: 250,
		quantity: 309,
	},
	{
		garage_id: 103,
		part_id: 251,
		quantity: 248,
	},
	{
		garage_id: 103,
		part_id: 252,
		quantity: 497,
	},
	{
		garage_id: 103,
		part_id: 253,
		quantity: 111,
	},
	{
		garage_id: 103,
		part_id: 254,
		quantity: 355,
	},
	{
		garage_id: 103,
		part_id: 255,
		quantity: 251,
	},
	{
		garage_id: 103,
		part_id: 256,
		quantity: 319,
	},
	{
		garage_id: 103,
		part_id: 257,
		quantity: 461,
	},
	{
		garage_id: 103,
		part_id: 258,
		quantity: 34,
	},
	{
		garage_id: 103,
		part_id: 259,
		quantity: 80,
	},
	{
		garage_id: 103,
		part_id: 260,
		quantity: 126,
	},
	{
		garage_id: 103,
		part_id: 261,
		quantity: 38,
	},
	{
		garage_id: 103,
		part_id: 262,
		quantity: 24,
	},
	{
		garage_id: 103,
		part_id: 263,
		quantity: 436,
	},
	{
		garage_id: 103,
		part_id: 264,
		quantity: 424,
	},
	{
		garage_id: 103,
		part_id: 265,
		quantity: 324,
	},
	{
		garage_id: 103,
		part_id: 266,
		quantity: 4,
	},
	{
		garage_id: 103,
		part_id: 267,
		quantity: 429,
	},
	{
		garage_id: 103,
		part_id: 268,
		quantity: 118,
	},
	{
		garage_id: 103,
		part_id: 269,
		quantity: 374,
	},
	{
		garage_id: 103,
		part_id: 270,
		quantity: 455,
	},
	{
		garage_id: 103,
		part_id: 271,
		quantity: 489,
	},
	{
		garage_id: 103,
		part_id: 272,
		quantity: 311,
	},
	{
		garage_id: 103,
		part_id: 273,
		quantity: 345,
	},
	{
		garage_id: 103,
		part_id: 274,
		quantity: 386,
	},
	{
		garage_id: 103,
		part_id: 275,
		quantity: 199,
	},
	{
		garage_id: 103,
		part_id: 276,
		quantity: 125,
	},
	{
		garage_id: 103,
		part_id: 277,
		quantity: 384,
	},
	{
		garage_id: 103,
		part_id: 278,
		quantity: 210,
	},
	{
		garage_id: 103,
		part_id: 279,
		quantity: 326,
	},
	{
		garage_id: 103,
		part_id: 280,
		quantity: 476,
	},
	{
		garage_id: 103,
		part_id: 281,
		quantity: 59,
	},
	{
		garage_id: 103,
		part_id: 282,
		quantity: 15,
	},
	{
		garage_id: 103,
		part_id: 283,
		quantity: 142,
	},
	{
		garage_id: 103,
		part_id: 284,
		quantity: 415,
	},
	{
		garage_id: 103,
		part_id: 285,
		quantity: 326,
	},
	{
		garage_id: 103,
		part_id: 286,
		quantity: 116,
	},
	{
		garage_id: 103,
		part_id: 287,
		quantity: 388,
	},
	{
		garage_id: 103,
		part_id: 288,
		quantity: 377,
	},
	{
		garage_id: 103,
		part_id: 289,
		quantity: 143,
	},
	{
		garage_id: 103,
		part_id: 290,
		quantity: 38,
	},
	{
		garage_id: 103,
		part_id: 291,
		quantity: 196,
	},
	{
		garage_id: 103,
		part_id: 292,
		quantity: 492,
	},
	{
		garage_id: 103,
		part_id: 293,
		quantity: 462,
	},
	{
		garage_id: 103,
		part_id: 294,
		quantity: 223,
	},
	{
		garage_id: 103,
		part_id: 295,
		quantity: 249,
	},
	{
		garage_id: 103,
		part_id: 296,
		quantity: 169,
	},
	{
		garage_id: 103,
		part_id: 297,
		quantity: 343,
	},
	{
		garage_id: 103,
		part_id: 298,
		quantity: 183,
	},
	{
		garage_id: 103,
		part_id: 299,
		quantity: 421,
	},
	{
		garage_id: 103,
		part_id: 300,
		quantity: 108,
	},
	{
		garage_id: 103,
		part_id: 301,
		quantity: 483,
	},
	{
		garage_id: 103,
		part_id: 302,
		quantity: 218,
	},
	{
		garage_id: 103,
		part_id: 303,
		quantity: 116,
	},
	{
		garage_id: 103,
		part_id: 304,
		quantity: 326,
	},
	{
		garage_id: 103,
		part_id: 305,
		quantity: 108,
	},
	{
		garage_id: 103,
		part_id: 306,
		quantity: 382,
	},
	{
		garage_id: 103,
		part_id: 307,
		quantity: 442,
	},
	{
		garage_id: 103,
		part_id: 308,
		quantity: 74,
	},
	{
		garage_id: 103,
		part_id: 309,
		quantity: 113,
	},
	{
		garage_id: 103,
		part_id: 310,
		quantity: 392,
	},
	{
		garage_id: 103,
		part_id: 311,
		quantity: 367,
	},
	{
		garage_id: 103,
		part_id: 312,
		quantity: 402,
	},
	{
		garage_id: 103,
		part_id: 313,
		quantity: 251,
	},
	{
		garage_id: 103,
		part_id: 314,
		quantity: 470,
	},
	{
		garage_id: 103,
		part_id: 315,
		quantity: 51,
	},
	{
		garage_id: 103,
		part_id: 316,
		quantity: 480,
	},
	{
		garage_id: 103,
		part_id: 317,
		quantity: 281,
	},
	{
		garage_id: 103,
		part_id: 318,
		quantity: 162,
	},
	{
		garage_id: 103,
		part_id: 319,
		quantity: 220,
	},
	{
		garage_id: 103,
		part_id: 320,
		quantity: 219,
	},
	{
		garage_id: 103,
		part_id: 321,
		quantity: 82,
	},
	{
		garage_id: 103,
		part_id: 322,
		quantity: 197,
	},
	{
		garage_id: 103,
		part_id: 323,
		quantity: 371,
	},
	{
		garage_id: 103,
		part_id: 324,
		quantity: 339,
	},
	{
		garage_id: 103,
		part_id: 325,
		quantity: 252,
	},
	{
		garage_id: 103,
		part_id: 326,
		quantity: 16,
	},
	{
		garage_id: 103,
		part_id: 327,
		quantity: 185,
	},
	{
		garage_id: 103,
		part_id: 328,
		quantity: 34,
	},
	{
		garage_id: 103,
		part_id: 329,
		quantity: 309,
	},
	{
		garage_id: 103,
		part_id: 330,
		quantity: 70,
	},
	{
		garage_id: 103,
		part_id: 331,
		quantity: 103,
	},
	{
		garage_id: 103,
		part_id: 332,
		quantity: 143,
	},
	{
		garage_id: 103,
		part_id: 333,
		quantity: 410,
	},
	{
		garage_id: 103,
		part_id: 334,
		quantity: 242,
	},
	{
		garage_id: 103,
		part_id: 335,
		quantity: 199,
	},
	{
		garage_id: 103,
		part_id: 336,
		quantity: 332,
	},
	{
		garage_id: 103,
		part_id: 337,
		quantity: 104,
	},
	{
		garage_id: 103,
		part_id: 338,
		quantity: 90,
	},
	{
		garage_id: 103,
		part_id: 339,
		quantity: 106,
	},
	{
		garage_id: 103,
		part_id: 340,
		quantity: 273,
	},
	{
		garage_id: 103,
		part_id: 341,
		quantity: 177,
	},
	{
		garage_id: 103,
		part_id: 342,
		quantity: 10,
	},
	{
		garage_id: 103,
		part_id: 343,
		quantity: 303,
	},
	{
		garage_id: 103,
		part_id: 344,
		quantity: 429,
	},
	{
		garage_id: 103,
		part_id: 345,
		quantity: 459,
	},
	{
		garage_id: 103,
		part_id: 346,
		quantity: 400,
	},
	{
		garage_id: 103,
		part_id: 347,
		quantity: 348,
	},
	{
		garage_id: 103,
		part_id: 348,
		quantity: 393,
	},
	{
		garage_id: 103,
		part_id: 349,
		quantity: 304,
	},
	{
		garage_id: 103,
		part_id: 350,
		quantity: 304,
	},
	{
		garage_id: 103,
		part_id: 351,
		quantity: 211,
	},
	{
		garage_id: 103,
		part_id: 352,
		quantity: 420,
	},
	{
		garage_id: 103,
		part_id: 353,
		quantity: 145,
	},
	{
		garage_id: 103,
		part_id: 354,
		quantity: 76,
	},
	{
		garage_id: 103,
		part_id: 355,
		quantity: 481,
	},
	{
		garage_id: 103,
		part_id: 356,
		quantity: 265,
	},
	{
		garage_id: 103,
		part_id: 357,
		quantity: 7,
	},
	{
		garage_id: 103,
		part_id: 358,
		quantity: 275,
	},
	{
		garage_id: 103,
		part_id: 359,
		quantity: 334,
	},
	{
		garage_id: 103,
		part_id: 360,
		quantity: 190,
	},
	{
		garage_id: 103,
		part_id: 361,
		quantity: 402,
	},
	{
		garage_id: 103,
		part_id: 362,
		quantity: 103,
	},
	{
		garage_id: 103,
		part_id: 363,
		quantity: 59,
	},
	{
		garage_id: 103,
		part_id: 364,
		quantity: 196,
	},
	{
		garage_id: 103,
		part_id: 365,
		quantity: 306,
	},
	{
		garage_id: 103,
		part_id: 366,
		quantity: 399,
	},
	{
		garage_id: 103,
		part_id: 367,
		quantity: 476,
	},
	{
		garage_id: 103,
		part_id: 368,
		quantity: 402,
	},
	{
		garage_id: 103,
		part_id: 369,
		quantity: 227,
	},
	{
		garage_id: 103,
		part_id: 370,
		quantity: 484,
	},
	{
		garage_id: 103,
		part_id: 371,
		quantity: 93,
	},
	{
		garage_id: 103,
		part_id: 372,
		quantity: 161,
	},
	{
		garage_id: 103,
		part_id: 373,
		quantity: 482,
	},
	{
		garage_id: 103,
		part_id: 374,
		quantity: 302,
	},
	{
		garage_id: 103,
		part_id: 375,
		quantity: 363,
	},
	{
		garage_id: 103,
		part_id: 376,
		quantity: 114,
	},
	{
		garage_id: 103,
		part_id: 377,
		quantity: 124,
	},
	{
		garage_id: 103,
		part_id: 378,
		quantity: 490,
	},
	{
		garage_id: 103,
		part_id: 379,
		quantity: 120,
	},
	{
		garage_id: 103,
		part_id: 380,
		quantity: 284,
	},
	{
		garage_id: 103,
		part_id: 381,
		quantity: 41,
	},
	{
		garage_id: 103,
		part_id: 382,
		quantity: 421,
	},
	{
		garage_id: 103,
		part_id: 383,
		quantity: 414,
	},
	{
		garage_id: 103,
		part_id: 384,
		quantity: 202,
	},
	{
		garage_id: 103,
		part_id: 385,
		quantity: 262,
	},
	{
		garage_id: 103,
		part_id: 386,
		quantity: 25,
	},
	{
		garage_id: 103,
		part_id: 387,
		quantity: 270,
	},
	{
		garage_id: 103,
		part_id: 388,
		quantity: 492,
	},
	{
		garage_id: 103,
		part_id: 389,
		quantity: 121,
	},
	{
		garage_id: 103,
		part_id: 390,
		quantity: 170,
	},
	{
		garage_id: 103,
		part_id: 391,
		quantity: 465,
	},
	{
		garage_id: 103,
		part_id: 392,
		quantity: 160,
	},
	{
		garage_id: 103,
		part_id: 393,
		quantity: 364,
	},
	{
		garage_id: 103,
		part_id: 394,
		quantity: 337,
	},
	{
		garage_id: 103,
		part_id: 395,
		quantity: 246,
	},
	{
		garage_id: 103,
		part_id: 396,
		quantity: 373,
	},
	{
		garage_id: 103,
		part_id: 397,
		quantity: 307,
	},
	{
		garage_id: 103,
		part_id: 398,
		quantity: 27,
	},
	{
		garage_id: 103,
		part_id: 399,
		quantity: 109,
	},
	{
		garage_id: 104,
		part_id: 0,
		quantity: 42,
	},
	{
		garage_id: 104,
		part_id: 1,
		quantity: 22,
	},
	{
		garage_id: 104,
		part_id: 2,
		quantity: 244,
	},
	{
		garage_id: 104,
		part_id: 3,
		quantity: 116,
	},
	{
		garage_id: 104,
		part_id: 4,
		quantity: 286,
	},
	{
		garage_id: 104,
		part_id: 5,
		quantity: 464,
	},
	{
		garage_id: 104,
		part_id: 6,
		quantity: 479,
	},
	{
		garage_id: 104,
		part_id: 7,
		quantity: 180,
	},
	{
		garage_id: 104,
		part_id: 8,
		quantity: 68,
	},
	{
		garage_id: 104,
		part_id: 9,
		quantity: 4,
	},
	{
		garage_id: 104,
		part_id: 10,
		quantity: 111,
	},
	{
		garage_id: 104,
		part_id: 11,
		quantity: 257,
	},
	{
		garage_id: 104,
		part_id: 12,
		quantity: 111,
	},
	{
		garage_id: 104,
		part_id: 13,
		quantity: 142,
	},
	{
		garage_id: 104,
		part_id: 14,
		quantity: 48,
	},
	{
		garage_id: 104,
		part_id: 15,
		quantity: 243,
	},
	{
		garage_id: 104,
		part_id: 16,
		quantity: 107,
	},
	{
		garage_id: 104,
		part_id: 17,
		quantity: 169,
	},
	{
		garage_id: 104,
		part_id: 18,
		quantity: 458,
	},
	{
		garage_id: 104,
		part_id: 19,
		quantity: 255,
	},
	{
		garage_id: 104,
		part_id: 20,
		quantity: 446,
	},
	{
		garage_id: 104,
		part_id: 21,
		quantity: 447,
	},
	{
		garage_id: 104,
		part_id: 22,
		quantity: 284,
	},
	{
		garage_id: 104,
		part_id: 23,
		quantity: 69,
	},
	{
		garage_id: 104,
		part_id: 24,
		quantity: 3,
	},
	{
		garage_id: 104,
		part_id: 25,
		quantity: 441,
	},
	{
		garage_id: 104,
		part_id: 26,
		quantity: 78,
	},
	{
		garage_id: 104,
		part_id: 27,
		quantity: 251,
	},
	{
		garage_id: 104,
		part_id: 28,
		quantity: 278,
	},
	{
		garage_id: 104,
		part_id: 29,
		quantity: 377,
	},
	{
		garage_id: 104,
		part_id: 30,
		quantity: 6,
	},
	{
		garage_id: 104,
		part_id: 31,
		quantity: 320,
	},
	{
		garage_id: 104,
		part_id: 32,
		quantity: 288,
	},
	{
		garage_id: 104,
		part_id: 33,
		quantity: 223,
	},
	{
		garage_id: 104,
		part_id: 34,
		quantity: 435,
	},
	{
		garage_id: 104,
		part_id: 35,
		quantity: 277,
	},
	{
		garage_id: 104,
		part_id: 36,
		quantity: 343,
	},
	{
		garage_id: 104,
		part_id: 37,
		quantity: 119,
	},
	{
		garage_id: 104,
		part_id: 38,
		quantity: 117,
	},
	{
		garage_id: 104,
		part_id: 39,
		quantity: 343,
	},
	{
		garage_id: 104,
		part_id: 40,
		quantity: 289,
	},
	{
		garage_id: 104,
		part_id: 41,
		quantity: 390,
	},
	{
		garage_id: 104,
		part_id: 42,
		quantity: 234,
	},
	{
		garage_id: 104,
		part_id: 43,
		quantity: 371,
	},
	{
		garage_id: 104,
		part_id: 44,
		quantity: 256,
	},
	{
		garage_id: 104,
		part_id: 45,
		quantity: 228,
	},
	{
		garage_id: 104,
		part_id: 46,
		quantity: 188,
	},
	{
		garage_id: 104,
		part_id: 47,
		quantity: 371,
	},
	{
		garage_id: 104,
		part_id: 48,
		quantity: 239,
	},
	{
		garage_id: 104,
		part_id: 49,
		quantity: 428,
	},
	{
		garage_id: 104,
		part_id: 50,
		quantity: 250,
	},
	{
		garage_id: 104,
		part_id: 51,
		quantity: 443,
	},
	{
		garage_id: 104,
		part_id: 52,
		quantity: 73,
	},
	{
		garage_id: 104,
		part_id: 53,
		quantity: 123,
	},
	{
		garage_id: 104,
		part_id: 54,
		quantity: 494,
	},
	{
		garage_id: 104,
		part_id: 55,
		quantity: 183,
	},
	{
		garage_id: 104,
		part_id: 56,
		quantity: 52,
	},
	{
		garage_id: 104,
		part_id: 57,
		quantity: 356,
	},
	{
		garage_id: 104,
		part_id: 58,
		quantity: 329,
	},
	{
		garage_id: 104,
		part_id: 59,
		quantity: 171,
	},
	{
		garage_id: 104,
		part_id: 60,
		quantity: 197,
	},
	{
		garage_id: 104,
		part_id: 61,
		quantity: 431,
	},
	{
		garage_id: 104,
		part_id: 62,
		quantity: 233,
	},
	{
		garage_id: 104,
		part_id: 63,
		quantity: 402,
	},
	{
		garage_id: 104,
		part_id: 64,
		quantity: 456,
	},
	{
		garage_id: 104,
		part_id: 65,
		quantity: 430,
	},
	{
		garage_id: 104,
		part_id: 66,
		quantity: 148,
	},
	{
		garage_id: 104,
		part_id: 67,
		quantity: 206,
	},
	{
		garage_id: 104,
		part_id: 68,
		quantity: 221,
	},
	{
		garage_id: 104,
		part_id: 69,
		quantity: 132,
	},
	{
		garage_id: 104,
		part_id: 70,
		quantity: 268,
	},
	{
		garage_id: 104,
		part_id: 71,
		quantity: 309,
	},
	{
		garage_id: 104,
		part_id: 72,
		quantity: 211,
	},
	{
		garage_id: 104,
		part_id: 73,
		quantity: 10,
	},
	{
		garage_id: 104,
		part_id: 74,
		quantity: 46,
	},
	{
		garage_id: 104,
		part_id: 75,
		quantity: 424,
	},
	{
		garage_id: 104,
		part_id: 76,
		quantity: 387,
	},
	{
		garage_id: 104,
		part_id: 77,
		quantity: 244,
	},
	{
		garage_id: 104,
		part_id: 78,
		quantity: 446,
	},
	{
		garage_id: 104,
		part_id: 79,
		quantity: 401,
	},
	{
		garage_id: 104,
		part_id: 80,
		quantity: 380,
	},
	{
		garage_id: 104,
		part_id: 81,
		quantity: 454,
	},
	{
		garage_id: 104,
		part_id: 82,
		quantity: 10,
	},
	{
		garage_id: 104,
		part_id: 83,
		quantity: 197,
	},
	{
		garage_id: 104,
		part_id: 84,
		quantity: 485,
	},
	{
		garage_id: 104,
		part_id: 85,
		quantity: 103,
	},
	{
		garage_id: 104,
		part_id: 86,
		quantity: 495,
	},
	{
		garage_id: 104,
		part_id: 87,
		quantity: 26,
	},
	{
		garage_id: 104,
		part_id: 88,
		quantity: 378,
	},
	{
		garage_id: 104,
		part_id: 89,
		quantity: 311,
	},
	{
		garage_id: 104,
		part_id: 90,
		quantity: 121,
	},
	{
		garage_id: 104,
		part_id: 91,
		quantity: 199,
	},
	{
		garage_id: 104,
		part_id: 92,
		quantity: 14,
	},
	{
		garage_id: 104,
		part_id: 93,
		quantity: 149,
	},
	{
		garage_id: 104,
		part_id: 94,
		quantity: 413,
	},
	{
		garage_id: 104,
		part_id: 95,
		quantity: 219,
	},
	{
		garage_id: 104,
		part_id: 96,
		quantity: 44,
	},
	{
		garage_id: 104,
		part_id: 97,
		quantity: 364,
	},
	{
		garage_id: 104,
		part_id: 98,
		quantity: 60,
	},
	{
		garage_id: 104,
		part_id: 99,
		quantity: 446,
	},
	{
		garage_id: 104,
		part_id: 100,
		quantity: 12,
	},
	{
		garage_id: 104,
		part_id: 101,
		quantity: 350,
	},
	{
		garage_id: 104,
		part_id: 102,
		quantity: 71,
	},
	{
		garage_id: 104,
		part_id: 103,
		quantity: 44,
	},
	{
		garage_id: 104,
		part_id: 104,
		quantity: 470,
	},
	{
		garage_id: 104,
		part_id: 105,
		quantity: 333,
	},
	{
		garage_id: 104,
		part_id: 106,
		quantity: 228,
	},
	{
		garage_id: 104,
		part_id: 107,
		quantity: 480,
	},
	{
		garage_id: 104,
		part_id: 108,
		quantity: 374,
	},
	{
		garage_id: 104,
		part_id: 109,
		quantity: 158,
	},
	{
		garage_id: 104,
		part_id: 110,
		quantity: 266,
	},
	{
		garage_id: 104,
		part_id: 111,
		quantity: 149,
	},
	{
		garage_id: 104,
		part_id: 112,
		quantity: 314,
	},
	{
		garage_id: 104,
		part_id: 113,
		quantity: 390,
	},
	{
		garage_id: 104,
		part_id: 114,
		quantity: 445,
	},
	{
		garage_id: 104,
		part_id: 115,
		quantity: 328,
	},
	{
		garage_id: 104,
		part_id: 116,
		quantity: 462,
	},
	{
		garage_id: 104,
		part_id: 117,
		quantity: 253,
	},
	{
		garage_id: 104,
		part_id: 118,
		quantity: 419,
	},
	{
		garage_id: 104,
		part_id: 119,
		quantity: 467,
	},
	{
		garage_id: 104,
		part_id: 120,
		quantity: 488,
	},
	{
		garage_id: 104,
		part_id: 121,
		quantity: 304,
	},
	{
		garage_id: 104,
		part_id: 122,
		quantity: 452,
	},
	{
		garage_id: 104,
		part_id: 123,
		quantity: 186,
	},
	{
		garage_id: 104,
		part_id: 124,
		quantity: 316,
	},
	{
		garage_id: 104,
		part_id: 125,
		quantity: 151,
	},
	{
		garage_id: 104,
		part_id: 126,
		quantity: 327,
	},
	{
		garage_id: 104,
		part_id: 127,
		quantity: 390,
	},
	{
		garage_id: 104,
		part_id: 128,
		quantity: 323,
	},
	{
		garage_id: 104,
		part_id: 129,
		quantity: 381,
	},
	{
		garage_id: 104,
		part_id: 130,
		quantity: 340,
	},
	{
		garage_id: 104,
		part_id: 131,
		quantity: 12,
	},
	{
		garage_id: 104,
		part_id: 132,
		quantity: 162,
	},
	{
		garage_id: 104,
		part_id: 133,
		quantity: 49,
	},
	{
		garage_id: 104,
		part_id: 134,
		quantity: 93,
	},
	{
		garage_id: 104,
		part_id: 135,
		quantity: 320,
	},
	{
		garage_id: 104,
		part_id: 136,
		quantity: 109,
	},
	{
		garage_id: 104,
		part_id: 137,
		quantity: 0,
	},
	{
		garage_id: 104,
		part_id: 138,
		quantity: 114,
	},
	{
		garage_id: 104,
		part_id: 139,
		quantity: 125,
	},
	{
		garage_id: 104,
		part_id: 140,
		quantity: 163,
	},
	{
		garage_id: 104,
		part_id: 141,
		quantity: 376,
	},
	{
		garage_id: 104,
		part_id: 142,
		quantity: 83,
	},
	{
		garage_id: 104,
		part_id: 143,
		quantity: 160,
	},
	{
		garage_id: 104,
		part_id: 144,
		quantity: 32,
	},
	{
		garage_id: 104,
		part_id: 145,
		quantity: 41,
	},
	{
		garage_id: 104,
		part_id: 146,
		quantity: 397,
	},
	{
		garage_id: 104,
		part_id: 147,
		quantity: 435,
	},
	{
		garage_id: 104,
		part_id: 148,
		quantity: 394,
	},
	{
		garage_id: 104,
		part_id: 149,
		quantity: 339,
	},
	{
		garage_id: 104,
		part_id: 150,
		quantity: 152,
	},
	{
		garage_id: 104,
		part_id: 151,
		quantity: 431,
	},
	{
		garage_id: 104,
		part_id: 152,
		quantity: 338,
	},
	{
		garage_id: 104,
		part_id: 153,
		quantity: 160,
	},
	{
		garage_id: 104,
		part_id: 154,
		quantity: 400,
	},
	{
		garage_id: 104,
		part_id: 155,
		quantity: 481,
	},
	{
		garage_id: 104,
		part_id: 156,
		quantity: 167,
	},
	{
		garage_id: 104,
		part_id: 157,
		quantity: 3,
	},
	{
		garage_id: 104,
		part_id: 158,
		quantity: 122,
	},
	{
		garage_id: 104,
		part_id: 159,
		quantity: 100,
	},
	{
		garage_id: 104,
		part_id: 160,
		quantity: 301,
	},
	{
		garage_id: 104,
		part_id: 161,
		quantity: 258,
	},
	{
		garage_id: 104,
		part_id: 162,
		quantity: 187,
	},
	{
		garage_id: 104,
		part_id: 163,
		quantity: 336,
	},
	{
		garage_id: 104,
		part_id: 164,
		quantity: 265,
	},
	{
		garage_id: 104,
		part_id: 165,
		quantity: 80,
	},
	{
		garage_id: 104,
		part_id: 166,
		quantity: 153,
	},
	{
		garage_id: 104,
		part_id: 167,
		quantity: 144,
	},
	{
		garage_id: 104,
		part_id: 168,
		quantity: 267,
	},
	{
		garage_id: 104,
		part_id: 169,
		quantity: 171,
	},
	{
		garage_id: 104,
		part_id: 170,
		quantity: 201,
	},
	{
		garage_id: 104,
		part_id: 171,
		quantity: 155,
	},
	{
		garage_id: 104,
		part_id: 172,
		quantity: 7,
	},
	{
		garage_id: 104,
		part_id: 173,
		quantity: 6,
	},
	{
		garage_id: 104,
		part_id: 174,
		quantity: 35,
	},
	{
		garage_id: 104,
		part_id: 175,
		quantity: 149,
	},
	{
		garage_id: 104,
		part_id: 176,
		quantity: 468,
	},
	{
		garage_id: 104,
		part_id: 177,
		quantity: 60,
	},
	{
		garage_id: 104,
		part_id: 178,
		quantity: 277,
	},
	{
		garage_id: 104,
		part_id: 179,
		quantity: 395,
	},
	{
		garage_id: 104,
		part_id: 180,
		quantity: 378,
	},
	{
		garage_id: 104,
		part_id: 181,
		quantity: 363,
	},
	{
		garage_id: 104,
		part_id: 182,
		quantity: 168,
	},
	{
		garage_id: 104,
		part_id: 183,
		quantity: 373,
	},
	{
		garage_id: 104,
		part_id: 184,
		quantity: 281,
	},
	{
		garage_id: 104,
		part_id: 185,
		quantity: 252,
	},
	{
		garage_id: 104,
		part_id: 186,
		quantity: 32,
	},
	{
		garage_id: 104,
		part_id: 187,
		quantity: 160,
	},
	{
		garage_id: 104,
		part_id: 188,
		quantity: 225,
	},
	{
		garage_id: 104,
		part_id: 189,
		quantity: 6,
	},
	{
		garage_id: 104,
		part_id: 190,
		quantity: 325,
	},
	{
		garage_id: 104,
		part_id: 191,
		quantity: 378,
	},
	{
		garage_id: 104,
		part_id: 192,
		quantity: 410,
	},
	{
		garage_id: 104,
		part_id: 193,
		quantity: 301,
	},
	{
		garage_id: 104,
		part_id: 194,
		quantity: 428,
	},
	{
		garage_id: 104,
		part_id: 195,
		quantity: 411,
	},
	{
		garage_id: 104,
		part_id: 196,
		quantity: 460,
	},
	{
		garage_id: 104,
		part_id: 197,
		quantity: 41,
	},
	{
		garage_id: 104,
		part_id: 198,
		quantity: 473,
	},
	{
		garage_id: 104,
		part_id: 199,
		quantity: 440,
	},
	{
		garage_id: 104,
		part_id: 200,
		quantity: 61,
	},
	{
		garage_id: 104,
		part_id: 201,
		quantity: 111,
	},
	{
		garage_id: 104,
		part_id: 202,
		quantity: 63,
	},
	{
		garage_id: 104,
		part_id: 203,
		quantity: 318,
	},
	{
		garage_id: 104,
		part_id: 204,
		quantity: 296,
	},
	{
		garage_id: 104,
		part_id: 205,
		quantity: 269,
	},
	{
		garage_id: 104,
		part_id: 206,
		quantity: 127,
	},
	{
		garage_id: 104,
		part_id: 207,
		quantity: 27,
	},
	{
		garage_id: 104,
		part_id: 208,
		quantity: 275,
	},
	{
		garage_id: 104,
		part_id: 209,
		quantity: 368,
	},
	{
		garage_id: 104,
		part_id: 210,
		quantity: 381,
	},
	{
		garage_id: 104,
		part_id: 211,
		quantity: 66,
	},
	{
		garage_id: 104,
		part_id: 212,
		quantity: 239,
	},
	{
		garage_id: 104,
		part_id: 213,
		quantity: 386,
	},
	{
		garage_id: 104,
		part_id: 214,
		quantity: 230,
	},
	{
		garage_id: 104,
		part_id: 215,
		quantity: 428,
	},
	{
		garage_id: 104,
		part_id: 216,
		quantity: 67,
	},
	{
		garage_id: 104,
		part_id: 217,
		quantity: 74,
	},
	{
		garage_id: 104,
		part_id: 218,
		quantity: 107,
	},
	{
		garage_id: 104,
		part_id: 219,
		quantity: 373,
	},
	{
		garage_id: 104,
		part_id: 220,
		quantity: 377,
	},
	{
		garage_id: 104,
		part_id: 221,
		quantity: 123,
	},
	{
		garage_id: 104,
		part_id: 222,
		quantity: 356,
	},
	{
		garage_id: 104,
		part_id: 223,
		quantity: 60,
	},
	{
		garage_id: 104,
		part_id: 224,
		quantity: 488,
	},
	{
		garage_id: 104,
		part_id: 225,
		quantity: 439,
	},
	{
		garage_id: 104,
		part_id: 226,
		quantity: 459,
	},
	{
		garage_id: 104,
		part_id: 227,
		quantity: 377,
	},
	{
		garage_id: 104,
		part_id: 228,
		quantity: 125,
	},
	{
		garage_id: 104,
		part_id: 229,
		quantity: 367,
	},
	{
		garage_id: 104,
		part_id: 230,
		quantity: 165,
	},
	{
		garage_id: 104,
		part_id: 231,
		quantity: 226,
	},
	{
		garage_id: 104,
		part_id: 232,
		quantity: 100,
	},
	{
		garage_id: 104,
		part_id: 233,
		quantity: 400,
	},
	{
		garage_id: 104,
		part_id: 234,
		quantity: 94,
	},
	{
		garage_id: 104,
		part_id: 235,
		quantity: 495,
	},
	{
		garage_id: 104,
		part_id: 236,
		quantity: 409,
	},
	{
		garage_id: 104,
		part_id: 237,
		quantity: 144,
	},
	{
		garage_id: 104,
		part_id: 238,
		quantity: 488,
	},
	{
		garage_id: 104,
		part_id: 239,
		quantity: 214,
	},
	{
		garage_id: 104,
		part_id: 240,
		quantity: 399,
	},
	{
		garage_id: 104,
		part_id: 241,
		quantity: 300,
	},
	{
		garage_id: 104,
		part_id: 242,
		quantity: 369,
	},
	{
		garage_id: 104,
		part_id: 243,
		quantity: 201,
	},
	{
		garage_id: 104,
		part_id: 244,
		quantity: 426,
	},
	{
		garage_id: 104,
		part_id: 245,
		quantity: 312,
	},
	{
		garage_id: 104,
		part_id: 246,
		quantity: 163,
	},
	{
		garage_id: 104,
		part_id: 247,
		quantity: 250,
	},
	{
		garage_id: 104,
		part_id: 248,
		quantity: 184,
	},
	{
		garage_id: 104,
		part_id: 249,
		quantity: 378,
	},
	{
		garage_id: 104,
		part_id: 250,
		quantity: 396,
	},
	{
		garage_id: 104,
		part_id: 251,
		quantity: 107,
	},
	{
		garage_id: 104,
		part_id: 252,
		quantity: 15,
	},
	{
		garage_id: 104,
		part_id: 253,
		quantity: 156,
	},
	{
		garage_id: 104,
		part_id: 254,
		quantity: 256,
	},
	{
		garage_id: 104,
		part_id: 255,
		quantity: 23,
	},
	{
		garage_id: 104,
		part_id: 256,
		quantity: 479,
	},
	{
		garage_id: 104,
		part_id: 257,
		quantity: 339,
	},
	{
		garage_id: 104,
		part_id: 258,
		quantity: 27,
	},
	{
		garage_id: 104,
		part_id: 259,
		quantity: 143,
	},
	{
		garage_id: 104,
		part_id: 260,
		quantity: 62,
	},
	{
		garage_id: 104,
		part_id: 261,
		quantity: 332,
	},
	{
		garage_id: 104,
		part_id: 262,
		quantity: 491,
	},
	{
		garage_id: 104,
		part_id: 263,
		quantity: 332,
	},
	{
		garage_id: 104,
		part_id: 264,
		quantity: 442,
	},
	{
		garage_id: 104,
		part_id: 265,
		quantity: 144,
	},
	{
		garage_id: 104,
		part_id: 266,
		quantity: 206,
	},
	{
		garage_id: 104,
		part_id: 267,
		quantity: 355,
	},
	{
		garage_id: 104,
		part_id: 268,
		quantity: 436,
	},
	{
		garage_id: 104,
		part_id: 269,
		quantity: 500,
	},
	{
		garage_id: 104,
		part_id: 270,
		quantity: 246,
	},
	{
		garage_id: 104,
		part_id: 271,
		quantity: 31,
	},
	{
		garage_id: 104,
		part_id: 272,
		quantity: 52,
	},
	{
		garage_id: 104,
		part_id: 273,
		quantity: 2,
	},
	{
		garage_id: 104,
		part_id: 274,
		quantity: 291,
	},
	{
		garage_id: 104,
		part_id: 275,
		quantity: 469,
	},
	{
		garage_id: 104,
		part_id: 276,
		quantity: 24,
	},
	{
		garage_id: 104,
		part_id: 277,
		quantity: 380,
	},
	{
		garage_id: 104,
		part_id: 278,
		quantity: 62,
	},
	{
		garage_id: 104,
		part_id: 279,
		quantity: 192,
	},
	{
		garage_id: 104,
		part_id: 280,
		quantity: 400,
	},
	{
		garage_id: 104,
		part_id: 281,
		quantity: 19,
	},
	{
		garage_id: 104,
		part_id: 282,
		quantity: 443,
	},
	{
		garage_id: 104,
		part_id: 283,
		quantity: 306,
	},
	{
		garage_id: 104,
		part_id: 284,
		quantity: 123,
	},
	{
		garage_id: 104,
		part_id: 285,
		quantity: 469,
	},
	{
		garage_id: 104,
		part_id: 286,
		quantity: 183,
	},
	{
		garage_id: 104,
		part_id: 287,
		quantity: 53,
	},
	{
		garage_id: 104,
		part_id: 288,
		quantity: 279,
	},
	{
		garage_id: 104,
		part_id: 289,
		quantity: 172,
	},
	{
		garage_id: 104,
		part_id: 290,
		quantity: 90,
	},
	{
		garage_id: 104,
		part_id: 291,
		quantity: 250,
	},
	{
		garage_id: 104,
		part_id: 292,
		quantity: 275,
	},
	{
		garage_id: 104,
		part_id: 293,
		quantity: 240,
	},
	{
		garage_id: 104,
		part_id: 294,
		quantity: 185,
	},
	{
		garage_id: 104,
		part_id: 295,
		quantity: 477,
	},
	{
		garage_id: 104,
		part_id: 296,
		quantity: 31,
	},
	{
		garage_id: 104,
		part_id: 297,
		quantity: 142,
	},
	{
		garage_id: 104,
		part_id: 298,
		quantity: 97,
	},
	{
		garage_id: 104,
		part_id: 299,
		quantity: 306,
	},
	{
		garage_id: 104,
		part_id: 300,
		quantity: 58,
	},
	{
		garage_id: 104,
		part_id: 301,
		quantity: 157,
	},
	{
		garage_id: 104,
		part_id: 302,
		quantity: 286,
	},
	{
		garage_id: 104,
		part_id: 303,
		quantity: 263,
	},
	{
		garage_id: 104,
		part_id: 304,
		quantity: 325,
	},
	{
		garage_id: 104,
		part_id: 305,
		quantity: 133,
	},
	{
		garage_id: 104,
		part_id: 306,
		quantity: 219,
	},
	{
		garage_id: 104,
		part_id: 307,
		quantity: 254,
	},
	{
		garage_id: 104,
		part_id: 308,
		quantity: 453,
	},
	{
		garage_id: 104,
		part_id: 309,
		quantity: 81,
	},
	{
		garage_id: 104,
		part_id: 310,
		quantity: 251,
	},
	{
		garage_id: 104,
		part_id: 311,
		quantity: 320,
	},
	{
		garage_id: 104,
		part_id: 312,
		quantity: 324,
	},
	{
		garage_id: 104,
		part_id: 313,
		quantity: 213,
	},
	{
		garage_id: 104,
		part_id: 314,
		quantity: 31,
	},
	{
		garage_id: 104,
		part_id: 315,
		quantity: 469,
	},
	{
		garage_id: 104,
		part_id: 316,
		quantity: 402,
	},
	{
		garage_id: 104,
		part_id: 317,
		quantity: 20,
	},
	{
		garage_id: 104,
		part_id: 318,
		quantity: 440,
	},
	{
		garage_id: 104,
		part_id: 319,
		quantity: 87,
	},
	{
		garage_id: 104,
		part_id: 320,
		quantity: 72,
	},
	{
		garage_id: 104,
		part_id: 321,
		quantity: 18,
	},
	{
		garage_id: 104,
		part_id: 322,
		quantity: 318,
	},
	{
		garage_id: 104,
		part_id: 323,
		quantity: 373,
	},
	{
		garage_id: 104,
		part_id: 324,
		quantity: 198,
	},
	{
		garage_id: 104,
		part_id: 325,
		quantity: 178,
	},
	{
		garage_id: 104,
		part_id: 326,
		quantity: 100,
	},
	{
		garage_id: 104,
		part_id: 327,
		quantity: 291,
	},
	{
		garage_id: 104,
		part_id: 328,
		quantity: 117,
	},
	{
		garage_id: 104,
		part_id: 329,
		quantity: 295,
	},
	{
		garage_id: 104,
		part_id: 330,
		quantity: 226,
	},
	{
		garage_id: 104,
		part_id: 331,
		quantity: 89,
	},
	{
		garage_id: 104,
		part_id: 332,
		quantity: 159,
	},
	{
		garage_id: 104,
		part_id: 333,
		quantity: 66,
	},
	{
		garage_id: 104,
		part_id: 334,
		quantity: 185,
	},
	{
		garage_id: 104,
		part_id: 335,
		quantity: 474,
	},
	{
		garage_id: 104,
		part_id: 336,
		quantity: 401,
	},
	{
		garage_id: 104,
		part_id: 337,
		quantity: 332,
	},
	{
		garage_id: 104,
		part_id: 338,
		quantity: 371,
	},
	{
		garage_id: 104,
		part_id: 339,
		quantity: 212,
	},
	{
		garage_id: 104,
		part_id: 340,
		quantity: 95,
	},
	{
		garage_id: 104,
		part_id: 341,
		quantity: 271,
	},
	{
		garage_id: 104,
		part_id: 342,
		quantity: 152,
	},
	{
		garage_id: 104,
		part_id: 343,
		quantity: 80,
	},
	{
		garage_id: 104,
		part_id: 344,
		quantity: 232,
	},
	{
		garage_id: 104,
		part_id: 345,
		quantity: 287,
	},
	{
		garage_id: 104,
		part_id: 346,
		quantity: 234,
	},
	{
		garage_id: 104,
		part_id: 347,
		quantity: 320,
	},
	{
		garage_id: 104,
		part_id: 348,
		quantity: 158,
	},
	{
		garage_id: 104,
		part_id: 349,
		quantity: 372,
	},
	{
		garage_id: 104,
		part_id: 350,
		quantity: 241,
	},
	{
		garage_id: 104,
		part_id: 351,
		quantity: 229,
	},
	{
		garage_id: 104,
		part_id: 352,
		quantity: 453,
	},
	{
		garage_id: 104,
		part_id: 353,
		quantity: 178,
	},
	{
		garage_id: 104,
		part_id: 354,
		quantity: 182,
	},
	{
		garage_id: 104,
		part_id: 355,
		quantity: 198,
	},
	{
		garage_id: 104,
		part_id: 356,
		quantity: 222,
	},
	{
		garage_id: 104,
		part_id: 357,
		quantity: 330,
	},
	{
		garage_id: 104,
		part_id: 358,
		quantity: 272,
	},
	{
		garage_id: 104,
		part_id: 359,
		quantity: 466,
	},
	{
		garage_id: 104,
		part_id: 360,
		quantity: 52,
	},
	{
		garage_id: 104,
		part_id: 361,
		quantity: 153,
	},
	{
		garage_id: 104,
		part_id: 362,
		quantity: 209,
	},
	{
		garage_id: 104,
		part_id: 363,
		quantity: 443,
	},
	{
		garage_id: 104,
		part_id: 364,
		quantity: 42,
	},
	{
		garage_id: 104,
		part_id: 365,
		quantity: 395,
	},
	{
		garage_id: 104,
		part_id: 366,
		quantity: 229,
	},
	{
		garage_id: 104,
		part_id: 367,
		quantity: 3,
	},
	{
		garage_id: 104,
		part_id: 368,
		quantity: 159,
	},
	{
		garage_id: 104,
		part_id: 369,
		quantity: 282,
	},
	{
		garage_id: 104,
		part_id: 370,
		quantity: 411,
	},
	{
		garage_id: 104,
		part_id: 371,
		quantity: 165,
	},
	{
		garage_id: 104,
		part_id: 372,
		quantity: 153,
	},
	{
		garage_id: 104,
		part_id: 373,
		quantity: 109,
	},
	{
		garage_id: 104,
		part_id: 374,
		quantity: 429,
	},
	{
		garage_id: 104,
		part_id: 375,
		quantity: 409,
	},
	{
		garage_id: 104,
		part_id: 376,
		quantity: 343,
	},
	{
		garage_id: 104,
		part_id: 377,
		quantity: 187,
	},
	{
		garage_id: 104,
		part_id: 378,
		quantity: 425,
	},
	{
		garage_id: 104,
		part_id: 379,
		quantity: 383,
	},
	{
		garage_id: 104,
		part_id: 380,
		quantity: 49,
	},
	{
		garage_id: 104,
		part_id: 381,
		quantity: 45,
	},
	{
		garage_id: 104,
		part_id: 382,
		quantity: 362,
	},
	{
		garage_id: 104,
		part_id: 383,
		quantity: 428,
	},
	{
		garage_id: 104,
		part_id: 384,
		quantity: 365,
	},
	{
		garage_id: 104,
		part_id: 385,
		quantity: 185,
	},
	{
		garage_id: 104,
		part_id: 386,
		quantity: 268,
	},
	{
		garage_id: 104,
		part_id: 387,
		quantity: 245,
	},
	{
		garage_id: 104,
		part_id: 388,
		quantity: 308,
	},
	{
		garage_id: 104,
		part_id: 389,
		quantity: 57,
	},
	{
		garage_id: 104,
		part_id: 390,
		quantity: 380,
	},
	{
		garage_id: 104,
		part_id: 391,
		quantity: 462,
	},
	{
		garage_id: 104,
		part_id: 392,
		quantity: 432,
	},
	{
		garage_id: 104,
		part_id: 393,
		quantity: 212,
	},
	{
		garage_id: 104,
		part_id: 394,
		quantity: 66,
	},
	{
		garage_id: 104,
		part_id: 395,
		quantity: 245,
	},
	{
		garage_id: 104,
		part_id: 396,
		quantity: 161,
	},
	{
		garage_id: 104,
		part_id: 397,
		quantity: 459,
	},
	{
		garage_id: 104,
		part_id: 398,
		quantity: 81,
	},
	{
		garage_id: 104,
		part_id: 399,
		quantity: 471,
	},
	{
		garage_id: 105,
		part_id: 0,
		quantity: 331,
	},
	{
		garage_id: 105,
		part_id: 1,
		quantity: 3,
	},
	{
		garage_id: 105,
		part_id: 2,
		quantity: 139,
	},
	{
		garage_id: 105,
		part_id: 3,
		quantity: 80,
	},
	{
		garage_id: 105,
		part_id: 4,
		quantity: 350,
	},
	{
		garage_id: 105,
		part_id: 5,
		quantity: 491,
	},
	{
		garage_id: 105,
		part_id: 6,
		quantity: 480,
	},
	{
		garage_id: 105,
		part_id: 7,
		quantity: 183,
	},
	{
		garage_id: 105,
		part_id: 8,
		quantity: 278,
	},
	{
		garage_id: 105,
		part_id: 9,
		quantity: 141,
	},
	{
		garage_id: 105,
		part_id: 10,
		quantity: 449,
	},
	{
		garage_id: 105,
		part_id: 11,
		quantity: 186,
	},
	{
		garage_id: 105,
		part_id: 12,
		quantity: 75,
	},
	{
		garage_id: 105,
		part_id: 13,
		quantity: 49,
	},
	{
		garage_id: 105,
		part_id: 14,
		quantity: 484,
	},
	{
		garage_id: 105,
		part_id: 15,
		quantity: 344,
	},
	{
		garage_id: 105,
		part_id: 16,
		quantity: 151,
	},
	{
		garage_id: 105,
		part_id: 17,
		quantity: 300,
	},
	{
		garage_id: 105,
		part_id: 18,
		quantity: 245,
	},
	{
		garage_id: 105,
		part_id: 19,
		quantity: 204,
	},
	{
		garage_id: 105,
		part_id: 20,
		quantity: 252,
	},
	{
		garage_id: 105,
		part_id: 21,
		quantity: 330,
	},
	{
		garage_id: 105,
		part_id: 22,
		quantity: 68,
	},
	{
		garage_id: 105,
		part_id: 23,
		quantity: 458,
	},
	{
		garage_id: 105,
		part_id: 24,
		quantity: 423,
	},
	{
		garage_id: 105,
		part_id: 25,
		quantity: 214,
	},
	{
		garage_id: 105,
		part_id: 26,
		quantity: 103,
	},
	{
		garage_id: 105,
		part_id: 27,
		quantity: 344,
	},
	{
		garage_id: 105,
		part_id: 28,
		quantity: 77,
	},
	{
		garage_id: 105,
		part_id: 29,
		quantity: 415,
	},
	{
		garage_id: 105,
		part_id: 30,
		quantity: 204,
	},
	{
		garage_id: 105,
		part_id: 31,
		quantity: 443,
	},
	{
		garage_id: 105,
		part_id: 32,
		quantity: 42,
	},
	{
		garage_id: 105,
		part_id: 33,
		quantity: 318,
	},
	{
		garage_id: 105,
		part_id: 34,
		quantity: 292,
	},
	{
		garage_id: 105,
		part_id: 35,
		quantity: 245,
	},
	{
		garage_id: 105,
		part_id: 36,
		quantity: 5,
	},
	{
		garage_id: 105,
		part_id: 37,
		quantity: 44,
	},
	{
		garage_id: 105,
		part_id: 38,
		quantity: 359,
	},
	{
		garage_id: 105,
		part_id: 39,
		quantity: 479,
	},
	{
		garage_id: 105,
		part_id: 40,
		quantity: 119,
	},
	{
		garage_id: 105,
		part_id: 41,
		quantity: 166,
	},
	{
		garage_id: 105,
		part_id: 42,
		quantity: 418,
	},
	{
		garage_id: 105,
		part_id: 43,
		quantity: 137,
	},
	{
		garage_id: 105,
		part_id: 44,
		quantity: 440,
	},
	{
		garage_id: 105,
		part_id: 45,
		quantity: 42,
	},
	{
		garage_id: 105,
		part_id: 46,
		quantity: 408,
	},
	{
		garage_id: 105,
		part_id: 47,
		quantity: 473,
	},
	{
		garage_id: 105,
		part_id: 48,
		quantity: 364,
	},
	{
		garage_id: 105,
		part_id: 49,
		quantity: 222,
	},
	{
		garage_id: 105,
		part_id: 50,
		quantity: 449,
	},
	{
		garage_id: 105,
		part_id: 51,
		quantity: 499,
	},
	{
		garage_id: 105,
		part_id: 52,
		quantity: 381,
	},
	{
		garage_id: 105,
		part_id: 53,
		quantity: 70,
	},
	{
		garage_id: 105,
		part_id: 54,
		quantity: 239,
	},
	{
		garage_id: 105,
		part_id: 55,
		quantity: 414,
	},
	{
		garage_id: 105,
		part_id: 56,
		quantity: 96,
	},
	{
		garage_id: 105,
		part_id: 57,
		quantity: 57,
	},
	{
		garage_id: 105,
		part_id: 58,
		quantity: 53,
	},
	{
		garage_id: 105,
		part_id: 59,
		quantity: 133,
	},
	{
		garage_id: 105,
		part_id: 60,
		quantity: 387,
	},
	{
		garage_id: 105,
		part_id: 61,
		quantity: 283,
	},
	{
		garage_id: 105,
		part_id: 62,
		quantity: 279,
	},
	{
		garage_id: 105,
		part_id: 63,
		quantity: 11,
	},
	{
		garage_id: 105,
		part_id: 64,
		quantity: 400,
	},
	{
		garage_id: 105,
		part_id: 65,
		quantity: 115,
	},
	{
		garage_id: 105,
		part_id: 66,
		quantity: 132,
	},
	{
		garage_id: 105,
		part_id: 67,
		quantity: 469,
	},
	{
		garage_id: 105,
		part_id: 68,
		quantity: 19,
	},
	{
		garage_id: 105,
		part_id: 69,
		quantity: 34,
	},
	{
		garage_id: 105,
		part_id: 70,
		quantity: 282,
	},
	{
		garage_id: 105,
		part_id: 71,
		quantity: 172,
	},
	{
		garage_id: 105,
		part_id: 72,
		quantity: 426,
	},
	{
		garage_id: 105,
		part_id: 73,
		quantity: 191,
	},
	{
		garage_id: 105,
		part_id: 74,
		quantity: 387,
	},
	{
		garage_id: 105,
		part_id: 75,
		quantity: 85,
	},
	{
		garage_id: 105,
		part_id: 76,
		quantity: 293,
	},
	{
		garage_id: 105,
		part_id: 77,
		quantity: 246,
	},
	{
		garage_id: 105,
		part_id: 78,
		quantity: 299,
	},
	{
		garage_id: 105,
		part_id: 79,
		quantity: 184,
	},
	{
		garage_id: 105,
		part_id: 80,
		quantity: 154,
	},
	{
		garage_id: 105,
		part_id: 81,
		quantity: 173,
	},
	{
		garage_id: 105,
		part_id: 82,
		quantity: 188,
	},
	{
		garage_id: 105,
		part_id: 83,
		quantity: 344,
	},
	{
		garage_id: 105,
		part_id: 84,
		quantity: 344,
	},
	{
		garage_id: 105,
		part_id: 85,
		quantity: 26,
	},
	{
		garage_id: 105,
		part_id: 86,
		quantity: 416,
	},
	{
		garage_id: 105,
		part_id: 87,
		quantity: 467,
	},
	{
		garage_id: 105,
		part_id: 88,
		quantity: 322,
	},
	{
		garage_id: 105,
		part_id: 89,
		quantity: 239,
	},
	{
		garage_id: 105,
		part_id: 90,
		quantity: 355,
	},
	{
		garage_id: 105,
		part_id: 91,
		quantity: 178,
	},
	{
		garage_id: 105,
		part_id: 92,
		quantity: 277,
	},
	{
		garage_id: 105,
		part_id: 93,
		quantity: 471,
	},
	{
		garage_id: 105,
		part_id: 94,
		quantity: 270,
	},
	{
		garage_id: 105,
		part_id: 95,
		quantity: 125,
	},
	{
		garage_id: 105,
		part_id: 96,
		quantity: 441,
	},
	{
		garage_id: 105,
		part_id: 97,
		quantity: 225,
	},
	{
		garage_id: 105,
		part_id: 98,
		quantity: 405,
	},
	{
		garage_id: 105,
		part_id: 99,
		quantity: 198,
	},
	{
		garage_id: 105,
		part_id: 100,
		quantity: 108,
	},
	{
		garage_id: 105,
		part_id: 101,
		quantity: 4,
	},
	{
		garage_id: 105,
		part_id: 102,
		quantity: 286,
	},
	{
		garage_id: 105,
		part_id: 103,
		quantity: 458,
	},
	{
		garage_id: 105,
		part_id: 104,
		quantity: 162,
	},
	{
		garage_id: 105,
		part_id: 105,
		quantity: 328,
	},
	{
		garage_id: 105,
		part_id: 106,
		quantity: 436,
	},
	{
		garage_id: 105,
		part_id: 107,
		quantity: 344,
	},
	{
		garage_id: 105,
		part_id: 108,
		quantity: 48,
	},
	{
		garage_id: 105,
		part_id: 109,
		quantity: 20,
	},
	{
		garage_id: 105,
		part_id: 110,
		quantity: 328,
	},
	{
		garage_id: 105,
		part_id: 111,
		quantity: 240,
	},
	{
		garage_id: 105,
		part_id: 112,
		quantity: 466,
	},
	{
		garage_id: 105,
		part_id: 113,
		quantity: 305,
	},
	{
		garage_id: 105,
		part_id: 114,
		quantity: 264,
	},
	{
		garage_id: 105,
		part_id: 115,
		quantity: 0,
	},
	{
		garage_id: 105,
		part_id: 116,
		quantity: 499,
	},
	{
		garage_id: 105,
		part_id: 117,
		quantity: 130,
	},
	{
		garage_id: 105,
		part_id: 118,
		quantity: 448,
	},
	{
		garage_id: 105,
		part_id: 119,
		quantity: 96,
	},
	{
		garage_id: 105,
		part_id: 120,
		quantity: 62,
	},
	{
		garage_id: 105,
		part_id: 121,
		quantity: 140,
	},
	{
		garage_id: 105,
		part_id: 122,
		quantity: 172,
	},
	{
		garage_id: 105,
		part_id: 123,
		quantity: 439,
	},
	{
		garage_id: 105,
		part_id: 124,
		quantity: 155,
	},
	{
		garage_id: 105,
		part_id: 125,
		quantity: 300,
	},
	{
		garage_id: 105,
		part_id: 126,
		quantity: 155,
	},
	{
		garage_id: 105,
		part_id: 127,
		quantity: 187,
	},
	{
		garage_id: 105,
		part_id: 128,
		quantity: 358,
	},
	{
		garage_id: 105,
		part_id: 129,
		quantity: 447,
	},
	{
		garage_id: 105,
		part_id: 130,
		quantity: 461,
	},
	{
		garage_id: 105,
		part_id: 131,
		quantity: 191,
	},
	{
		garage_id: 105,
		part_id: 132,
		quantity: 18,
	},
	{
		garage_id: 105,
		part_id: 133,
		quantity: 44,
	},
	{
		garage_id: 105,
		part_id: 134,
		quantity: 236,
	},
	{
		garage_id: 105,
		part_id: 135,
		quantity: 37,
	},
	{
		garage_id: 105,
		part_id: 136,
		quantity: 73,
	},
	{
		garage_id: 105,
		part_id: 137,
		quantity: 191,
	},
	{
		garage_id: 105,
		part_id: 138,
		quantity: 236,
	},
	{
		garage_id: 105,
		part_id: 139,
		quantity: 388,
	},
	{
		garage_id: 105,
		part_id: 140,
		quantity: 494,
	},
	{
		garage_id: 105,
		part_id: 141,
		quantity: 313,
	},
	{
		garage_id: 105,
		part_id: 142,
		quantity: 127,
	},
	{
		garage_id: 105,
		part_id: 143,
		quantity: 272,
	},
	{
		garage_id: 105,
		part_id: 144,
		quantity: 405,
	},
	{
		garage_id: 105,
		part_id: 145,
		quantity: 287,
	},
	{
		garage_id: 105,
		part_id: 146,
		quantity: 323,
	},
	{
		garage_id: 105,
		part_id: 147,
		quantity: 169,
	},
	{
		garage_id: 105,
		part_id: 148,
		quantity: 311,
	},
	{
		garage_id: 105,
		part_id: 149,
		quantity: 239,
	},
	{
		garage_id: 105,
		part_id: 150,
		quantity: 52,
	},
	{
		garage_id: 105,
		part_id: 151,
		quantity: 358,
	},
	{
		garage_id: 105,
		part_id: 152,
		quantity: 83,
	},
	{
		garage_id: 105,
		part_id: 153,
		quantity: 22,
	},
	{
		garage_id: 105,
		part_id: 154,
		quantity: 111,
	},
	{
		garage_id: 105,
		part_id: 155,
		quantity: 373,
	},
	{
		garage_id: 105,
		part_id: 156,
		quantity: 230,
	},
	{
		garage_id: 105,
		part_id: 157,
		quantity: 217,
	},
	{
		garage_id: 105,
		part_id: 158,
		quantity: 97,
	},
	{
		garage_id: 105,
		part_id: 159,
		quantity: 205,
	},
	{
		garage_id: 105,
		part_id: 160,
		quantity: 235,
	},
	{
		garage_id: 105,
		part_id: 161,
		quantity: 488,
	},
	{
		garage_id: 105,
		part_id: 162,
		quantity: 345,
	},
	{
		garage_id: 105,
		part_id: 163,
		quantity: 17,
	},
	{
		garage_id: 105,
		part_id: 164,
		quantity: 125,
	},
	{
		garage_id: 105,
		part_id: 165,
		quantity: 346,
	},
	{
		garage_id: 105,
		part_id: 166,
		quantity: 228,
	},
	{
		garage_id: 105,
		part_id: 167,
		quantity: 310,
	},
	{
		garage_id: 105,
		part_id: 168,
		quantity: 442,
	},
	{
		garage_id: 105,
		part_id: 169,
		quantity: 217,
	},
	{
		garage_id: 105,
		part_id: 170,
		quantity: 106,
	},
	{
		garage_id: 105,
		part_id: 171,
		quantity: 132,
	},
	{
		garage_id: 105,
		part_id: 172,
		quantity: 224,
	},
	{
		garage_id: 105,
		part_id: 173,
		quantity: 97,
	},
	{
		garage_id: 105,
		part_id: 174,
		quantity: 248,
	},
	{
		garage_id: 105,
		part_id: 175,
		quantity: 335,
	},
	{
		garage_id: 105,
		part_id: 176,
		quantity: 239,
	},
	{
		garage_id: 105,
		part_id: 177,
		quantity: 143,
	},
	{
		garage_id: 105,
		part_id: 178,
		quantity: 92,
	},
	{
		garage_id: 105,
		part_id: 179,
		quantity: 300,
	},
	{
		garage_id: 105,
		part_id: 180,
		quantity: 181,
	},
	{
		garage_id: 105,
		part_id: 181,
		quantity: 6,
	},
	{
		garage_id: 105,
		part_id: 182,
		quantity: 211,
	},
	{
		garage_id: 105,
		part_id: 183,
		quantity: 223,
	},
	{
		garage_id: 105,
		part_id: 184,
		quantity: 384,
	},
	{
		garage_id: 105,
		part_id: 185,
		quantity: 431,
	},
	{
		garage_id: 105,
		part_id: 186,
		quantity: 275,
	},
	{
		garage_id: 105,
		part_id: 187,
		quantity: 347,
	},
	{
		garage_id: 105,
		part_id: 188,
		quantity: 10,
	},
	{
		garage_id: 105,
		part_id: 189,
		quantity: 498,
	},
	{
		garage_id: 105,
		part_id: 190,
		quantity: 217,
	},
	{
		garage_id: 105,
		part_id: 191,
		quantity: 487,
	},
	{
		garage_id: 105,
		part_id: 192,
		quantity: 207,
	},
	{
		garage_id: 105,
		part_id: 193,
		quantity: 192,
	},
	{
		garage_id: 105,
		part_id: 194,
		quantity: 314,
	},
	{
		garage_id: 105,
		part_id: 195,
		quantity: 266,
	},
	{
		garage_id: 105,
		part_id: 196,
		quantity: 282,
	},
	{
		garage_id: 105,
		part_id: 197,
		quantity: 54,
	},
	{
		garage_id: 105,
		part_id: 198,
		quantity: 425,
	},
	{
		garage_id: 105,
		part_id: 199,
		quantity: 298,
	},
	{
		garage_id: 105,
		part_id: 200,
		quantity: 280,
	},
	{
		garage_id: 105,
		part_id: 201,
		quantity: 12,
	},
	{
		garage_id: 105,
		part_id: 202,
		quantity: 277,
	},
	{
		garage_id: 105,
		part_id: 203,
		quantity: 278,
	},
	{
		garage_id: 105,
		part_id: 204,
		quantity: 156,
	},
	{
		garage_id: 105,
		part_id: 205,
		quantity: 165,
	},
	{
		garage_id: 105,
		part_id: 206,
		quantity: 55,
	},
	{
		garage_id: 105,
		part_id: 207,
		quantity: 183,
	},
	{
		garage_id: 105,
		part_id: 208,
		quantity: 179,
	},
	{
		garage_id: 105,
		part_id: 209,
		quantity: 109,
	},
	{
		garage_id: 105,
		part_id: 210,
		quantity: 228,
	},
	{
		garage_id: 105,
		part_id: 211,
		quantity: 485,
	},
	{
		garage_id: 105,
		part_id: 212,
		quantity: 351,
	},
	{
		garage_id: 105,
		part_id: 213,
		quantity: 136,
	},
	{
		garage_id: 105,
		part_id: 214,
		quantity: 4,
	},
	{
		garage_id: 105,
		part_id: 215,
		quantity: 18,
	},
	{
		garage_id: 105,
		part_id: 216,
		quantity: 441,
	},
	{
		garage_id: 105,
		part_id: 217,
		quantity: 323,
	},
	{
		garage_id: 105,
		part_id: 218,
		quantity: 228,
	},
	{
		garage_id: 105,
		part_id: 219,
		quantity: 203,
	},
	{
		garage_id: 105,
		part_id: 220,
		quantity: 337,
	},
	{
		garage_id: 105,
		part_id: 221,
		quantity: 330,
	},
	{
		garage_id: 105,
		part_id: 222,
		quantity: 184,
	},
	{
		garage_id: 105,
		part_id: 223,
		quantity: 287,
	},
	{
		garage_id: 105,
		part_id: 224,
		quantity: 432,
	},
	{
		garage_id: 105,
		part_id: 225,
		quantity: 108,
	},
	{
		garage_id: 105,
		part_id: 226,
		quantity: 145,
	},
	{
		garage_id: 105,
		part_id: 227,
		quantity: 59,
	},
	{
		garage_id: 105,
		part_id: 228,
		quantity: 104,
	},
	{
		garage_id: 105,
		part_id: 229,
		quantity: 331,
	},
	{
		garage_id: 105,
		part_id: 230,
		quantity: 209,
	},
	{
		garage_id: 105,
		part_id: 231,
		quantity: 348,
	},
	{
		garage_id: 105,
		part_id: 232,
		quantity: 337,
	},
	{
		garage_id: 105,
		part_id: 233,
		quantity: 110,
	},
	{
		garage_id: 105,
		part_id: 234,
		quantity: 427,
	},
	{
		garage_id: 105,
		part_id: 235,
		quantity: 132,
	},
	{
		garage_id: 105,
		part_id: 236,
		quantity: 343,
	},
	{
		garage_id: 105,
		part_id: 237,
		quantity: 342,
	},
	{
		garage_id: 105,
		part_id: 238,
		quantity: 297,
	},
	{
		garage_id: 105,
		part_id: 239,
		quantity: 399,
	},
	{
		garage_id: 105,
		part_id: 240,
		quantity: 375,
	},
	{
		garage_id: 105,
		part_id: 241,
		quantity: 59,
	},
	{
		garage_id: 105,
		part_id: 242,
		quantity: 74,
	},
	{
		garage_id: 105,
		part_id: 243,
		quantity: 23,
	},
	{
		garage_id: 105,
		part_id: 244,
		quantity: 288,
	},
	{
		garage_id: 105,
		part_id: 245,
		quantity: 284,
	},
	{
		garage_id: 105,
		part_id: 246,
		quantity: 478,
	},
	{
		garage_id: 105,
		part_id: 247,
		quantity: 241,
	},
	{
		garage_id: 105,
		part_id: 248,
		quantity: 211,
	},
	{
		garage_id: 105,
		part_id: 249,
		quantity: 308,
	},
	{
		garage_id: 105,
		part_id: 250,
		quantity: 340,
	},
	{
		garage_id: 105,
		part_id: 251,
		quantity: 140,
	},
	{
		garage_id: 105,
		part_id: 252,
		quantity: 426,
	},
	{
		garage_id: 105,
		part_id: 253,
		quantity: 43,
	},
	{
		garage_id: 105,
		part_id: 254,
		quantity: 266,
	},
	{
		garage_id: 105,
		part_id: 255,
		quantity: 476,
	},
	{
		garage_id: 105,
		part_id: 256,
		quantity: 425,
	},
	{
		garage_id: 105,
		part_id: 257,
		quantity: 322,
	},
	{
		garage_id: 105,
		part_id: 258,
		quantity: 90,
	},
	{
		garage_id: 105,
		part_id: 259,
		quantity: 163,
	},
	{
		garage_id: 105,
		part_id: 260,
		quantity: 337,
	},
	{
		garage_id: 105,
		part_id: 261,
		quantity: 147,
	},
	{
		garage_id: 105,
		part_id: 262,
		quantity: 56,
	},
	{
		garage_id: 105,
		part_id: 263,
		quantity: 177,
	},
	{
		garage_id: 105,
		part_id: 264,
		quantity: 46,
	},
	{
		garage_id: 105,
		part_id: 265,
		quantity: 179,
	},
	{
		garage_id: 105,
		part_id: 266,
		quantity: 472,
	},
	{
		garage_id: 105,
		part_id: 267,
		quantity: 203,
	},
	{
		garage_id: 105,
		part_id: 268,
		quantity: 264,
	},
	{
		garage_id: 105,
		part_id: 269,
		quantity: 34,
	},
	{
		garage_id: 105,
		part_id: 270,
		quantity: 313,
	},
	{
		garage_id: 105,
		part_id: 271,
		quantity: 363,
	},
	{
		garage_id: 105,
		part_id: 272,
		quantity: 54,
	},
	{
		garage_id: 105,
		part_id: 273,
		quantity: 147,
	},
	{
		garage_id: 105,
		part_id: 274,
		quantity: 92,
	},
	{
		garage_id: 105,
		part_id: 275,
		quantity: 428,
	},
	{
		garage_id: 105,
		part_id: 276,
		quantity: 154,
	},
	{
		garage_id: 105,
		part_id: 277,
		quantity: 116,
	},
	{
		garage_id: 105,
		part_id: 278,
		quantity: 478,
	},
	{
		garage_id: 105,
		part_id: 279,
		quantity: 274,
	},
	{
		garage_id: 105,
		part_id: 280,
		quantity: 397,
	},
	{
		garage_id: 105,
		part_id: 281,
		quantity: 347,
	},
	{
		garage_id: 105,
		part_id: 282,
		quantity: 424,
	},
	{
		garage_id: 105,
		part_id: 283,
		quantity: 422,
	},
	{
		garage_id: 105,
		part_id: 284,
		quantity: 147,
	},
	{
		garage_id: 105,
		part_id: 285,
		quantity: 469,
	},
	{
		garage_id: 105,
		part_id: 286,
		quantity: 159,
	},
	{
		garage_id: 105,
		part_id: 287,
		quantity: 264,
	},
	{
		garage_id: 105,
		part_id: 288,
		quantity: 191,
	},
	{
		garage_id: 105,
		part_id: 289,
		quantity: 272,
	},
	{
		garage_id: 105,
		part_id: 290,
		quantity: 386,
	},
	{
		garage_id: 105,
		part_id: 291,
		quantity: 390,
	},
	{
		garage_id: 105,
		part_id: 292,
		quantity: 401,
	},
	{
		garage_id: 105,
		part_id: 293,
		quantity: 270,
	},
	{
		garage_id: 105,
		part_id: 294,
		quantity: 217,
	},
	{
		garage_id: 105,
		part_id: 295,
		quantity: 281,
	},
	{
		garage_id: 105,
		part_id: 296,
		quantity: 204,
	},
	{
		garage_id: 105,
		part_id: 297,
		quantity: 104,
	},
	{
		garage_id: 105,
		part_id: 298,
		quantity: 110,
	},
	{
		garage_id: 105,
		part_id: 299,
		quantity: 30,
	},
	{
		garage_id: 105,
		part_id: 300,
		quantity: 115,
	},
	{
		garage_id: 105,
		part_id: 301,
		quantity: 230,
	},
	{
		garage_id: 105,
		part_id: 302,
		quantity: 321,
	},
	{
		garage_id: 105,
		part_id: 303,
		quantity: 88,
	},
	{
		garage_id: 105,
		part_id: 304,
		quantity: 80,
	},
	{
		garage_id: 105,
		part_id: 305,
		quantity: 37,
	},
	{
		garage_id: 105,
		part_id: 306,
		quantity: 275,
	},
	{
		garage_id: 105,
		part_id: 307,
		quantity: 64,
	},
	{
		garage_id: 105,
		part_id: 308,
		quantity: 4,
	},
	{
		garage_id: 105,
		part_id: 309,
		quantity: 125,
	},
	{
		garage_id: 105,
		part_id: 310,
		quantity: 80,
	},
	{
		garage_id: 105,
		part_id: 311,
		quantity: 91,
	},
	{
		garage_id: 105,
		part_id: 312,
		quantity: 388,
	},
	{
		garage_id: 105,
		part_id: 313,
		quantity: 102,
	},
	{
		garage_id: 105,
		part_id: 314,
		quantity: 495,
	},
	{
		garage_id: 105,
		part_id: 315,
		quantity: 344,
	},
	{
		garage_id: 105,
		part_id: 316,
		quantity: 322,
	},
	{
		garage_id: 105,
		part_id: 317,
		quantity: 428,
	},
	{
		garage_id: 105,
		part_id: 318,
		quantity: 336,
	},
	{
		garage_id: 105,
		part_id: 319,
		quantity: 251,
	},
	{
		garage_id: 105,
		part_id: 320,
		quantity: 34,
	},
	{
		garage_id: 105,
		part_id: 321,
		quantity: 453,
	},
	{
		garage_id: 105,
		part_id: 322,
		quantity: 154,
	},
	{
		garage_id: 105,
		part_id: 323,
		quantity: 75,
	},
	{
		garage_id: 105,
		part_id: 324,
		quantity: 435,
	},
	{
		garage_id: 105,
		part_id: 325,
		quantity: 277,
	},
	{
		garage_id: 105,
		part_id: 326,
		quantity: 225,
	},
	{
		garage_id: 105,
		part_id: 327,
		quantity: 399,
	},
	{
		garage_id: 105,
		part_id: 328,
		quantity: 228,
	},
	{
		garage_id: 105,
		part_id: 329,
		quantity: 468,
	},
	{
		garage_id: 105,
		part_id: 330,
		quantity: 148,
	},
	{
		garage_id: 105,
		part_id: 331,
		quantity: 395,
	},
	{
		garage_id: 105,
		part_id: 332,
		quantity: 264,
	},
	{
		garage_id: 105,
		part_id: 333,
		quantity: 427,
	},
	{
		garage_id: 105,
		part_id: 334,
		quantity: 266,
	},
	{
		garage_id: 105,
		part_id: 335,
		quantity: 430,
	},
	{
		garage_id: 105,
		part_id: 336,
		quantity: 35,
	},
	{
		garage_id: 105,
		part_id: 337,
		quantity: 194,
	},
	{
		garage_id: 105,
		part_id: 338,
		quantity: 107,
	},
	{
		garage_id: 105,
		part_id: 339,
		quantity: 27,
	},
	{
		garage_id: 105,
		part_id: 340,
		quantity: 299,
	},
	{
		garage_id: 105,
		part_id: 341,
		quantity: 3,
	},
	{
		garage_id: 105,
		part_id: 342,
		quantity: 74,
	},
	{
		garage_id: 105,
		part_id: 343,
		quantity: 341,
	},
	{
		garage_id: 105,
		part_id: 344,
		quantity: 236,
	},
	{
		garage_id: 105,
		part_id: 345,
		quantity: 340,
	},
	{
		garage_id: 105,
		part_id: 346,
		quantity: 112,
	},
	{
		garage_id: 105,
		part_id: 347,
		quantity: 293,
	},
	{
		garage_id: 105,
		part_id: 348,
		quantity: 295,
	},
	{
		garage_id: 105,
		part_id: 349,
		quantity: 451,
	},
	{
		garage_id: 105,
		part_id: 350,
		quantity: 171,
	},
	{
		garage_id: 105,
		part_id: 351,
		quantity: 190,
	},
	{
		garage_id: 105,
		part_id: 352,
		quantity: 383,
	},
	{
		garage_id: 105,
		part_id: 353,
		quantity: 258,
	},
	{
		garage_id: 105,
		part_id: 354,
		quantity: 375,
	},
	{
		garage_id: 105,
		part_id: 355,
		quantity: 182,
	},
	{
		garage_id: 105,
		part_id: 356,
		quantity: 137,
	},
	{
		garage_id: 105,
		part_id: 357,
		quantity: 472,
	},
	{
		garage_id: 105,
		part_id: 358,
		quantity: 42,
	},
	{
		garage_id: 105,
		part_id: 359,
		quantity: 68,
	},
	{
		garage_id: 105,
		part_id: 360,
		quantity: 337,
	},
	{
		garage_id: 105,
		part_id: 361,
		quantity: 36,
	},
	{
		garage_id: 105,
		part_id: 362,
		quantity: 23,
	},
	{
		garage_id: 105,
		part_id: 363,
		quantity: 48,
	},
	{
		garage_id: 105,
		part_id: 364,
		quantity: 220,
	},
	{
		garage_id: 105,
		part_id: 365,
		quantity: 336,
	},
	{
		garage_id: 105,
		part_id: 366,
		quantity: 53,
	},
	{
		garage_id: 105,
		part_id: 367,
		quantity: 211,
	},
	{
		garage_id: 105,
		part_id: 368,
		quantity: 263,
	},
	{
		garage_id: 105,
		part_id: 369,
		quantity: 331,
	},
	{
		garage_id: 105,
		part_id: 370,
		quantity: 255,
	},
	{
		garage_id: 105,
		part_id: 371,
		quantity: 373,
	},
	{
		garage_id: 105,
		part_id: 372,
		quantity: 199,
	},
	{
		garage_id: 105,
		part_id: 373,
		quantity: 435,
	},
	{
		garage_id: 105,
		part_id: 374,
		quantity: 359,
	},
	{
		garage_id: 105,
		part_id: 375,
		quantity: 130,
	},
	{
		garage_id: 105,
		part_id: 376,
		quantity: 467,
	},
	{
		garage_id: 105,
		part_id: 377,
		quantity: 126,
	},
	{
		garage_id: 105,
		part_id: 378,
		quantity: 242,
	},
	{
		garage_id: 105,
		part_id: 379,
		quantity: 439,
	},
	{
		garage_id: 105,
		part_id: 380,
		quantity: 121,
	},
	{
		garage_id: 105,
		part_id: 381,
		quantity: 288,
	},
	{
		garage_id: 105,
		part_id: 382,
		quantity: 37,
	},
	{
		garage_id: 105,
		part_id: 383,
		quantity: 262,
	},
	{
		garage_id: 105,
		part_id: 384,
		quantity: 147,
	},
	{
		garage_id: 105,
		part_id: 385,
		quantity: 319,
	},
	{
		garage_id: 105,
		part_id: 386,
		quantity: 146,
	},
	{
		garage_id: 105,
		part_id: 387,
		quantity: 419,
	},
	{
		garage_id: 105,
		part_id: 388,
		quantity: 163,
	},
	{
		garage_id: 105,
		part_id: 389,
		quantity: 222,
	},
	{
		garage_id: 105,
		part_id: 390,
		quantity: 286,
	},
	{
		garage_id: 105,
		part_id: 391,
		quantity: 293,
	},
	{
		garage_id: 105,
		part_id: 392,
		quantity: 440,
	},
	{
		garage_id: 105,
		part_id: 393,
		quantity: 352,
	},
	{
		garage_id: 105,
		part_id: 394,
		quantity: 460,
	},
	{
		garage_id: 105,
		part_id: 395,
		quantity: 346,
	},
	{
		garage_id: 105,
		part_id: 396,
		quantity: 295,
	},
	{
		garage_id: 105,
		part_id: 397,
		quantity: 497,
	},
	{
		garage_id: 105,
		part_id: 398,
		quantity: 270,
	},
	{
		garage_id: 105,
		part_id: 399,
		quantity: 270,
	},
	{
		garage_id: 106,
		part_id: 0,
		quantity: 411,
	},
	{
		garage_id: 106,
		part_id: 1,
		quantity: 450,
	},
	{
		garage_id: 106,
		part_id: 2,
		quantity: 276,
	},
	{
		garage_id: 106,
		part_id: 3,
		quantity: 474,
	},
	{
		garage_id: 106,
		part_id: 4,
		quantity: 147,
	},
	{
		garage_id: 106,
		part_id: 5,
		quantity: 231,
	},
	{
		garage_id: 106,
		part_id: 6,
		quantity: 332,
	},
	{
		garage_id: 106,
		part_id: 7,
		quantity: 315,
	},
	{
		garage_id: 106,
		part_id: 8,
		quantity: 374,
	},
	{
		garage_id: 106,
		part_id: 9,
		quantity: 328,
	},
	{
		garage_id: 106,
		part_id: 10,
		quantity: 316,
	},
	{
		garage_id: 106,
		part_id: 11,
		quantity: 423,
	},
	{
		garage_id: 106,
		part_id: 12,
		quantity: 170,
	},
	{
		garage_id: 106,
		part_id: 13,
		quantity: 380,
	},
	{
		garage_id: 106,
		part_id: 14,
		quantity: 136,
	},
	{
		garage_id: 106,
		part_id: 15,
		quantity: 488,
	},
	{
		garage_id: 106,
		part_id: 16,
		quantity: 496,
	},
	{
		garage_id: 106,
		part_id: 17,
		quantity: 127,
	},
	{
		garage_id: 106,
		part_id: 18,
		quantity: 306,
	},
	{
		garage_id: 106,
		part_id: 19,
		quantity: 78,
	},
	{
		garage_id: 106,
		part_id: 20,
		quantity: 63,
	},
	{
		garage_id: 106,
		part_id: 21,
		quantity: 257,
	},
	{
		garage_id: 106,
		part_id: 22,
		quantity: 484,
	},
	{
		garage_id: 106,
		part_id: 23,
		quantity: 328,
	},
	{
		garage_id: 106,
		part_id: 24,
		quantity: 217,
	},
	{
		garage_id: 106,
		part_id: 25,
		quantity: 12,
	},
	{
		garage_id: 106,
		part_id: 26,
		quantity: 129,
	},
	{
		garage_id: 106,
		part_id: 27,
		quantity: 448,
	},
	{
		garage_id: 106,
		part_id: 28,
		quantity: 400,
	},
	{
		garage_id: 106,
		part_id: 29,
		quantity: 476,
	},
	{
		garage_id: 106,
		part_id: 30,
		quantity: 479,
	},
	{
		garage_id: 106,
		part_id: 31,
		quantity: 99,
	},
	{
		garage_id: 106,
		part_id: 32,
		quantity: 78,
	},
	{
		garage_id: 106,
		part_id: 33,
		quantity: 158,
	},
	{
		garage_id: 106,
		part_id: 34,
		quantity: 355,
	},
	{
		garage_id: 106,
		part_id: 35,
		quantity: 169,
	},
	{
		garage_id: 106,
		part_id: 36,
		quantity: 339,
	},
	{
		garage_id: 106,
		part_id: 37,
		quantity: 273,
	},
	{
		garage_id: 106,
		part_id: 38,
		quantity: 86,
	},
	{
		garage_id: 106,
		part_id: 39,
		quantity: 449,
	},
	{
		garage_id: 106,
		part_id: 40,
		quantity: 347,
	},
	{
		garage_id: 106,
		part_id: 41,
		quantity: 202,
	},
	{
		garage_id: 106,
		part_id: 42,
		quantity: 258,
	},
	{
		garage_id: 106,
		part_id: 43,
		quantity: 131,
	},
	{
		garage_id: 106,
		part_id: 44,
		quantity: 408,
	},
	{
		garage_id: 106,
		part_id: 45,
		quantity: 464,
	},
	{
		garage_id: 106,
		part_id: 46,
		quantity: 271,
	},
	{
		garage_id: 106,
		part_id: 47,
		quantity: 0,
	},
	{
		garage_id: 106,
		part_id: 48,
		quantity: 167,
	},
	{
		garage_id: 106,
		part_id: 49,
		quantity: 143,
	},
	{
		garage_id: 106,
		part_id: 50,
		quantity: 338,
	},
	{
		garage_id: 106,
		part_id: 51,
		quantity: 124,
	},
	{
		garage_id: 106,
		part_id: 52,
		quantity: 181,
	},
	{
		garage_id: 106,
		part_id: 53,
		quantity: 373,
	},
	{
		garage_id: 106,
		part_id: 54,
		quantity: 362,
	},
	{
		garage_id: 106,
		part_id: 55,
		quantity: 44,
	},
	{
		garage_id: 106,
		part_id: 56,
		quantity: 168,
	},
	{
		garage_id: 106,
		part_id: 57,
		quantity: 28,
	},
	{
		garage_id: 106,
		part_id: 58,
		quantity: 483,
	},
	{
		garage_id: 106,
		part_id: 59,
		quantity: 61,
	},
	{
		garage_id: 106,
		part_id: 60,
		quantity: 459,
	},
	{
		garage_id: 106,
		part_id: 61,
		quantity: 425,
	},
	{
		garage_id: 106,
		part_id: 62,
		quantity: 379,
	},
	{
		garage_id: 106,
		part_id: 63,
		quantity: 58,
	},
	{
		garage_id: 106,
		part_id: 64,
		quantity: 407,
	},
	{
		garage_id: 106,
		part_id: 65,
		quantity: 361,
	},
	{
		garage_id: 106,
		part_id: 66,
		quantity: 102,
	},
	{
		garage_id: 106,
		part_id: 67,
		quantity: 84,
	},
	{
		garage_id: 106,
		part_id: 68,
		quantity: 456,
	},
	{
		garage_id: 106,
		part_id: 69,
		quantity: 485,
	},
	{
		garage_id: 106,
		part_id: 70,
		quantity: 241,
	},
	{
		garage_id: 106,
		part_id: 71,
		quantity: 323,
	},
	{
		garage_id: 106,
		part_id: 72,
		quantity: 304,
	},
	{
		garage_id: 106,
		part_id: 73,
		quantity: 75,
	},
	{
		garage_id: 106,
		part_id: 74,
		quantity: 274,
	},
	{
		garage_id: 106,
		part_id: 75,
		quantity: 92,
	},
	{
		garage_id: 106,
		part_id: 76,
		quantity: 183,
	},
	{
		garage_id: 106,
		part_id: 77,
		quantity: 41,
	},
	{
		garage_id: 106,
		part_id: 78,
		quantity: 90,
	},
	{
		garage_id: 106,
		part_id: 79,
		quantity: 180,
	},
	{
		garage_id: 106,
		part_id: 80,
		quantity: 44,
	},
	{
		garage_id: 106,
		part_id: 81,
		quantity: 250,
	},
	{
		garage_id: 106,
		part_id: 82,
		quantity: 89,
	},
	{
		garage_id: 106,
		part_id: 83,
		quantity: 271,
	},
	{
		garage_id: 106,
		part_id: 84,
		quantity: 102,
	},
	{
		garage_id: 106,
		part_id: 85,
		quantity: 374,
	},
	{
		garage_id: 106,
		part_id: 86,
		quantity: 483,
	},
	{
		garage_id: 106,
		part_id: 87,
		quantity: 122,
	},
	{
		garage_id: 106,
		part_id: 88,
		quantity: 180,
	},
	{
		garage_id: 106,
		part_id: 89,
		quantity: 254,
	},
	{
		garage_id: 106,
		part_id: 90,
		quantity: 274,
	},
	{
		garage_id: 106,
		part_id: 91,
		quantity: 413,
	},
	{
		garage_id: 106,
		part_id: 92,
		quantity: 393,
	},
	{
		garage_id: 106,
		part_id: 93,
		quantity: 137,
	},
	{
		garage_id: 106,
		part_id: 94,
		quantity: 93,
	},
	{
		garage_id: 106,
		part_id: 95,
		quantity: 472,
	},
	{
		garage_id: 106,
		part_id: 96,
		quantity: 472,
	},
	{
		garage_id: 106,
		part_id: 97,
		quantity: 151,
	},
	{
		garage_id: 106,
		part_id: 98,
		quantity: 273,
	},
	{
		garage_id: 106,
		part_id: 99,
		quantity: 238,
	},
	{
		garage_id: 106,
		part_id: 100,
		quantity: 407,
	},
	{
		garage_id: 106,
		part_id: 101,
		quantity: 52,
	},
	{
		garage_id: 106,
		part_id: 102,
		quantity: 179,
	},
	{
		garage_id: 106,
		part_id: 103,
		quantity: 411,
	},
	{
		garage_id: 106,
		part_id: 104,
		quantity: 335,
	},
	{
		garage_id: 106,
		part_id: 105,
		quantity: 50,
	},
	{
		garage_id: 106,
		part_id: 106,
		quantity: 448,
	},
	{
		garage_id: 106,
		part_id: 107,
		quantity: 498,
	},
	{
		garage_id: 106,
		part_id: 108,
		quantity: 219,
	},
	{
		garage_id: 106,
		part_id: 109,
		quantity: 370,
	},
	{
		garage_id: 106,
		part_id: 110,
		quantity: 388,
	},
	{
		garage_id: 106,
		part_id: 111,
		quantity: 412,
	},
	{
		garage_id: 106,
		part_id: 112,
		quantity: 60,
	},
	{
		garage_id: 106,
		part_id: 113,
		quantity: 252,
	},
	{
		garage_id: 106,
		part_id: 114,
		quantity: 38,
	},
	{
		garage_id: 106,
		part_id: 115,
		quantity: 153,
	},
	{
		garage_id: 106,
		part_id: 116,
		quantity: 405,
	},
	{
		garage_id: 106,
		part_id: 117,
		quantity: 282,
	},
	{
		garage_id: 106,
		part_id: 118,
		quantity: 112,
	},
	{
		garage_id: 106,
		part_id: 119,
		quantity: 289,
	},
	{
		garage_id: 106,
		part_id: 120,
		quantity: 241,
	},
	{
		garage_id: 106,
		part_id: 121,
		quantity: 21,
	},
	{
		garage_id: 106,
		part_id: 122,
		quantity: 160,
	},
	{
		garage_id: 106,
		part_id: 123,
		quantity: 90,
	},
	{
		garage_id: 106,
		part_id: 124,
		quantity: 109,
	},
	{
		garage_id: 106,
		part_id: 125,
		quantity: 276,
	},
	{
		garage_id: 106,
		part_id: 126,
		quantity: 395,
	},
	{
		garage_id: 106,
		part_id: 127,
		quantity: 6,
	},
	{
		garage_id: 106,
		part_id: 128,
		quantity: 163,
	},
	{
		garage_id: 106,
		part_id: 129,
		quantity: 207,
	},
	{
		garage_id: 106,
		part_id: 130,
		quantity: 102,
	},
	{
		garage_id: 106,
		part_id: 131,
		quantity: 448,
	},
	{
		garage_id: 106,
		part_id: 132,
		quantity: 198,
	},
	{
		garage_id: 106,
		part_id: 133,
		quantity: 78,
	},
	{
		garage_id: 106,
		part_id: 134,
		quantity: 215,
	},
	{
		garage_id: 106,
		part_id: 135,
		quantity: 166,
	},
	{
		garage_id: 106,
		part_id: 136,
		quantity: 166,
	},
	{
		garage_id: 106,
		part_id: 137,
		quantity: 424,
	},
	{
		garage_id: 106,
		part_id: 138,
		quantity: 161,
	},
	{
		garage_id: 106,
		part_id: 139,
		quantity: 399,
	},
	{
		garage_id: 106,
		part_id: 140,
		quantity: 289,
	},
	{
		garage_id: 106,
		part_id: 141,
		quantity: 64,
	},
	{
		garage_id: 106,
		part_id: 142,
		quantity: 118,
	},
	{
		garage_id: 106,
		part_id: 143,
		quantity: 301,
	},
	{
		garage_id: 106,
		part_id: 144,
		quantity: 258,
	},
	{
		garage_id: 106,
		part_id: 145,
		quantity: 458,
	},
	{
		garage_id: 106,
		part_id: 146,
		quantity: 408,
	},
	{
		garage_id: 106,
		part_id: 147,
		quantity: 51,
	},
	{
		garage_id: 106,
		part_id: 148,
		quantity: 123,
	},
	{
		garage_id: 106,
		part_id: 149,
		quantity: 223,
	},
	{
		garage_id: 106,
		part_id: 150,
		quantity: 171,
	},
	{
		garage_id: 106,
		part_id: 151,
		quantity: 319,
	},
	{
		garage_id: 106,
		part_id: 152,
		quantity: 444,
	},
	{
		garage_id: 106,
		part_id: 153,
		quantity: 415,
	},
	{
		garage_id: 106,
		part_id: 154,
		quantity: 24,
	},
	{
		garage_id: 106,
		part_id: 155,
		quantity: 149,
	},
	{
		garage_id: 106,
		part_id: 156,
		quantity: 323,
	},
	{
		garage_id: 106,
		part_id: 157,
		quantity: 269,
	},
	{
		garage_id: 106,
		part_id: 158,
		quantity: 150,
	},
	{
		garage_id: 106,
		part_id: 159,
		quantity: 160,
	},
	{
		garage_id: 106,
		part_id: 160,
		quantity: 448,
	},
	{
		garage_id: 106,
		part_id: 161,
		quantity: 268,
	},
	{
		garage_id: 106,
		part_id: 162,
		quantity: 361,
	},
	{
		garage_id: 106,
		part_id: 163,
		quantity: 245,
	},
	{
		garage_id: 106,
		part_id: 164,
		quantity: 279,
	},
	{
		garage_id: 106,
		part_id: 165,
		quantity: 291,
	},
	{
		garage_id: 106,
		part_id: 166,
		quantity: 306,
	},
	{
		garage_id: 106,
		part_id: 167,
		quantity: 183,
	},
	{
		garage_id: 106,
		part_id: 168,
		quantity: 166,
	},
	{
		garage_id: 106,
		part_id: 169,
		quantity: 77,
	},
	{
		garage_id: 106,
		part_id: 170,
		quantity: 456,
	},
	{
		garage_id: 106,
		part_id: 171,
		quantity: 73,
	},
	{
		garage_id: 106,
		part_id: 172,
		quantity: 361,
	},
	{
		garage_id: 106,
		part_id: 173,
		quantity: 179,
	},
	{
		garage_id: 106,
		part_id: 174,
		quantity: 195,
	},
	{
		garage_id: 106,
		part_id: 175,
		quantity: 45,
	},
	{
		garage_id: 106,
		part_id: 176,
		quantity: 393,
	},
	{
		garage_id: 106,
		part_id: 177,
		quantity: 442,
	},
	{
		garage_id: 106,
		part_id: 178,
		quantity: 272,
	},
	{
		garage_id: 106,
		part_id: 179,
		quantity: 21,
	},
	{
		garage_id: 106,
		part_id: 180,
		quantity: 326,
	},
	{
		garage_id: 106,
		part_id: 181,
		quantity: 434,
	},
	{
		garage_id: 106,
		part_id: 182,
		quantity: 242,
	},
	{
		garage_id: 106,
		part_id: 183,
		quantity: 298,
	},
	{
		garage_id: 106,
		part_id: 184,
		quantity: 227,
	},
	{
		garage_id: 106,
		part_id: 185,
		quantity: 69,
	},
	{
		garage_id: 106,
		part_id: 186,
		quantity: 158,
	},
	{
		garage_id: 106,
		part_id: 187,
		quantity: 222,
	},
	{
		garage_id: 106,
		part_id: 188,
		quantity: 343,
	},
	{
		garage_id: 106,
		part_id: 189,
		quantity: 260,
	},
	{
		garage_id: 106,
		part_id: 190,
		quantity: 290,
	},
	{
		garage_id: 106,
		part_id: 191,
		quantity: 268,
	},
	{
		garage_id: 106,
		part_id: 192,
		quantity: 158,
	},
	{
		garage_id: 106,
		part_id: 193,
		quantity: 388,
	},
	{
		garage_id: 106,
		part_id: 194,
		quantity: 33,
	},
	{
		garage_id: 106,
		part_id: 195,
		quantity: 156,
	},
	{
		garage_id: 106,
		part_id: 196,
		quantity: 413,
	},
	{
		garage_id: 106,
		part_id: 197,
		quantity: 251,
	},
	{
		garage_id: 106,
		part_id: 198,
		quantity: 147,
	},
	{
		garage_id: 106,
		part_id: 199,
		quantity: 225,
	},
	{
		garage_id: 106,
		part_id: 200,
		quantity: 95,
	},
	{
		garage_id: 106,
		part_id: 201,
		quantity: 205,
	},
	{
		garage_id: 106,
		part_id: 202,
		quantity: 297,
	},
	{
		garage_id: 106,
		part_id: 203,
		quantity: 18,
	},
	{
		garage_id: 106,
		part_id: 204,
		quantity: 486,
	},
	{
		garage_id: 106,
		part_id: 205,
		quantity: 427,
	},
	{
		garage_id: 106,
		part_id: 206,
		quantity: 273,
	},
	{
		garage_id: 106,
		part_id: 207,
		quantity: 431,
	},
	{
		garage_id: 106,
		part_id: 208,
		quantity: 445,
	},
	{
		garage_id: 106,
		part_id: 209,
		quantity: 343,
	},
	{
		garage_id: 106,
		part_id: 210,
		quantity: 65,
	},
	{
		garage_id: 106,
		part_id: 211,
		quantity: 456,
	},
	{
		garage_id: 106,
		part_id: 212,
		quantity: 453,
	},
	{
		garage_id: 106,
		part_id: 213,
		quantity: 327,
	},
	{
		garage_id: 106,
		part_id: 214,
		quantity: 480,
	},
	{
		garage_id: 106,
		part_id: 215,
		quantity: 92,
	},
	{
		garage_id: 106,
		part_id: 216,
		quantity: 22,
	},
	{
		garage_id: 106,
		part_id: 217,
		quantity: 54,
	},
	{
		garage_id: 106,
		part_id: 218,
		quantity: 96,
	},
	{
		garage_id: 106,
		part_id: 219,
		quantity: 226,
	},
	{
		garage_id: 106,
		part_id: 220,
		quantity: 29,
	},
	{
		garage_id: 106,
		part_id: 221,
		quantity: 82,
	},
	{
		garage_id: 106,
		part_id: 222,
		quantity: 327,
	},
	{
		garage_id: 106,
		part_id: 223,
		quantity: 496,
	},
	{
		garage_id: 106,
		part_id: 224,
		quantity: 305,
	},
	{
		garage_id: 106,
		part_id: 225,
		quantity: 245,
	},
	{
		garage_id: 106,
		part_id: 226,
		quantity: 230,
	},
	{
		garage_id: 106,
		part_id: 227,
		quantity: 276,
	},
	{
		garage_id: 106,
		part_id: 228,
		quantity: 493,
	},
	{
		garage_id: 106,
		part_id: 229,
		quantity: 434,
	},
	{
		garage_id: 106,
		part_id: 230,
		quantity: 320,
	},
	{
		garage_id: 106,
		part_id: 231,
		quantity: 165,
	},
	{
		garage_id: 106,
		part_id: 232,
		quantity: 222,
	},
	{
		garage_id: 106,
		part_id: 233,
		quantity: 58,
	},
	{
		garage_id: 106,
		part_id: 234,
		quantity: 31,
	},
	{
		garage_id: 106,
		part_id: 235,
		quantity: 415,
	},
	{
		garage_id: 106,
		part_id: 236,
		quantity: 89,
	},
	{
		garage_id: 106,
		part_id: 237,
		quantity: 218,
	},
	{
		garage_id: 106,
		part_id: 238,
		quantity: 170,
	},
	{
		garage_id: 106,
		part_id: 239,
		quantity: 228,
	},
	{
		garage_id: 106,
		part_id: 240,
		quantity: 77,
	},
	{
		garage_id: 106,
		part_id: 241,
		quantity: 16,
	},
	{
		garage_id: 106,
		part_id: 242,
		quantity: 146,
	},
	{
		garage_id: 106,
		part_id: 243,
		quantity: 267,
	},
	{
		garage_id: 106,
		part_id: 244,
		quantity: 84,
	},
	{
		garage_id: 106,
		part_id: 245,
		quantity: 65,
	},
	{
		garage_id: 106,
		part_id: 246,
		quantity: 277,
	},
	{
		garage_id: 106,
		part_id: 247,
		quantity: 324,
	},
	{
		garage_id: 106,
		part_id: 248,
		quantity: 477,
	},
	{
		garage_id: 106,
		part_id: 249,
		quantity: 496,
	},
	{
		garage_id: 106,
		part_id: 250,
		quantity: 255,
	},
	{
		garage_id: 106,
		part_id: 251,
		quantity: 318,
	},
	{
		garage_id: 106,
		part_id: 252,
		quantity: 297,
	},
	{
		garage_id: 106,
		part_id: 253,
		quantity: 10,
	},
	{
		garage_id: 106,
		part_id: 254,
		quantity: 365,
	},
	{
		garage_id: 106,
		part_id: 255,
		quantity: 364,
	},
	{
		garage_id: 106,
		part_id: 256,
		quantity: 101,
	},
	{
		garage_id: 106,
		part_id: 257,
		quantity: 356,
	},
	{
		garage_id: 106,
		part_id: 258,
		quantity: 58,
	},
	{
		garage_id: 106,
		part_id: 259,
		quantity: 454,
	},
	{
		garage_id: 106,
		part_id: 260,
		quantity: 246,
	},
	{
		garage_id: 106,
		part_id: 261,
		quantity: 236,
	},
	{
		garage_id: 106,
		part_id: 262,
		quantity: 491,
	},
	{
		garage_id: 106,
		part_id: 263,
		quantity: 297,
	},
	{
		garage_id: 106,
		part_id: 264,
		quantity: 55,
	},
	{
		garage_id: 106,
		part_id: 265,
		quantity: 292,
	},
	{
		garage_id: 106,
		part_id: 266,
		quantity: 450,
	},
	{
		garage_id: 106,
		part_id: 267,
		quantity: 306,
	},
	{
		garage_id: 106,
		part_id: 268,
		quantity: 149,
	},
	{
		garage_id: 106,
		part_id: 269,
		quantity: 43,
	},
	{
		garage_id: 106,
		part_id: 270,
		quantity: 124,
	},
	{
		garage_id: 106,
		part_id: 271,
		quantity: 291,
	},
	{
		garage_id: 106,
		part_id: 272,
		quantity: 175,
	},
	{
		garage_id: 106,
		part_id: 273,
		quantity: 207,
	},
	{
		garage_id: 106,
		part_id: 274,
		quantity: 30,
	},
	{
		garage_id: 106,
		part_id: 275,
		quantity: 350,
	},
	{
		garage_id: 106,
		part_id: 276,
		quantity: 262,
	},
	{
		garage_id: 106,
		part_id: 277,
		quantity: 206,
	},
	{
		garage_id: 106,
		part_id: 278,
		quantity: 235,
	},
	{
		garage_id: 106,
		part_id: 279,
		quantity: 178,
	},
	{
		garage_id: 106,
		part_id: 280,
		quantity: 104,
	},
	{
		garage_id: 106,
		part_id: 281,
		quantity: 223,
	},
	{
		garage_id: 106,
		part_id: 282,
		quantity: 94,
	},
	{
		garage_id: 106,
		part_id: 283,
		quantity: 63,
	},
	{
		garage_id: 106,
		part_id: 284,
		quantity: 11,
	},
	{
		garage_id: 106,
		part_id: 285,
		quantity: 266,
	},
	{
		garage_id: 106,
		part_id: 286,
		quantity: 366,
	},
	{
		garage_id: 106,
		part_id: 287,
		quantity: 256,
	},
	{
		garage_id: 106,
		part_id: 288,
		quantity: 236,
	},
	{
		garage_id: 106,
		part_id: 289,
		quantity: 14,
	},
	{
		garage_id: 106,
		part_id: 290,
		quantity: 492,
	},
	{
		garage_id: 106,
		part_id: 291,
		quantity: 182,
	},
	{
		garage_id: 106,
		part_id: 292,
		quantity: 388,
	},
	{
		garage_id: 106,
		part_id: 293,
		quantity: 212,
	},
	{
		garage_id: 106,
		part_id: 294,
		quantity: 47,
	},
	{
		garage_id: 106,
		part_id: 295,
		quantity: 249,
	},
	{
		garage_id: 106,
		part_id: 296,
		quantity: 24,
	},
	{
		garage_id: 106,
		part_id: 297,
		quantity: 334,
	},
	{
		garage_id: 106,
		part_id: 298,
		quantity: 238,
	},
	{
		garage_id: 106,
		part_id: 299,
		quantity: 299,
	},
	{
		garage_id: 106,
		part_id: 300,
		quantity: 55,
	},
	{
		garage_id: 106,
		part_id: 301,
		quantity: 453,
	},
	{
		garage_id: 106,
		part_id: 302,
		quantity: 445,
	},
	{
		garage_id: 106,
		part_id: 303,
		quantity: 250,
	},
	{
		garage_id: 106,
		part_id: 304,
		quantity: 96,
	},
	{
		garage_id: 106,
		part_id: 305,
		quantity: 465,
	},
	{
		garage_id: 106,
		part_id: 306,
		quantity: 157,
	},
	{
		garage_id: 106,
		part_id: 307,
		quantity: 338,
	},
	{
		garage_id: 106,
		part_id: 308,
		quantity: 248,
	},
	{
		garage_id: 106,
		part_id: 309,
		quantity: 400,
	},
	{
		garage_id: 106,
		part_id: 310,
		quantity: 388,
	},
	{
		garage_id: 106,
		part_id: 311,
		quantity: 198,
	},
	{
		garage_id: 106,
		part_id: 312,
		quantity: 415,
	},
	{
		garage_id: 106,
		part_id: 313,
		quantity: 66,
	},
	{
		garage_id: 106,
		part_id: 314,
		quantity: 126,
	},
	{
		garage_id: 106,
		part_id: 315,
		quantity: 473,
	},
	{
		garage_id: 106,
		part_id: 316,
		quantity: 493,
	},
	{
		garage_id: 106,
		part_id: 317,
		quantity: 303,
	},
	{
		garage_id: 106,
		part_id: 318,
		quantity: 368,
	},
	{
		garage_id: 106,
		part_id: 319,
		quantity: 161,
	},
	{
		garage_id: 106,
		part_id: 320,
		quantity: 352,
	},
	{
		garage_id: 106,
		part_id: 321,
		quantity: 298,
	},
	{
		garage_id: 106,
		part_id: 322,
		quantity: 336,
	},
	{
		garage_id: 106,
		part_id: 323,
		quantity: 402,
	},
	{
		garage_id: 106,
		part_id: 324,
		quantity: 152,
	},
	{
		garage_id: 106,
		part_id: 325,
		quantity: 489,
	},
	{
		garage_id: 106,
		part_id: 326,
		quantity: 308,
	},
	{
		garage_id: 106,
		part_id: 327,
		quantity: 320,
	},
	{
		garage_id: 106,
		part_id: 328,
		quantity: 96,
	},
	{
		garage_id: 106,
		part_id: 329,
		quantity: 434,
	},
	{
		garage_id: 106,
		part_id: 330,
		quantity: 350,
	},
	{
		garage_id: 106,
		part_id: 331,
		quantity: 371,
	},
	{
		garage_id: 106,
		part_id: 332,
		quantity: 281,
	},
	{
		garage_id: 106,
		part_id: 333,
		quantity: 312,
	},
	{
		garage_id: 106,
		part_id: 334,
		quantity: 140,
	},
	{
		garage_id: 106,
		part_id: 335,
		quantity: 28,
	},
	{
		garage_id: 106,
		part_id: 336,
		quantity: 169,
	},
	{
		garage_id: 106,
		part_id: 337,
		quantity: 55,
	},
	{
		garage_id: 106,
		part_id: 338,
		quantity: 329,
	},
	{
		garage_id: 106,
		part_id: 339,
		quantity: 196,
	},
	{
		garage_id: 106,
		part_id: 340,
		quantity: 470,
	},
	{
		garage_id: 106,
		part_id: 341,
		quantity: 276,
	},
	{
		garage_id: 106,
		part_id: 342,
		quantity: 158,
	},
	{
		garage_id: 106,
		part_id: 343,
		quantity: 467,
	},
	{
		garage_id: 106,
		part_id: 344,
		quantity: 108,
	},
	{
		garage_id: 106,
		part_id: 345,
		quantity: 153,
	},
	{
		garage_id: 106,
		part_id: 346,
		quantity: 346,
	},
	{
		garage_id: 106,
		part_id: 347,
		quantity: 490,
	},
	{
		garage_id: 106,
		part_id: 348,
		quantity: 254,
	},
	{
		garage_id: 106,
		part_id: 349,
		quantity: 326,
	},
	{
		garage_id: 106,
		part_id: 350,
		quantity: 408,
	},
	{
		garage_id: 106,
		part_id: 351,
		quantity: 200,
	},
	{
		garage_id: 106,
		part_id: 352,
		quantity: 210,
	},
	{
		garage_id: 106,
		part_id: 353,
		quantity: 82,
	},
	{
		garage_id: 106,
		part_id: 354,
		quantity: 275,
	},
	{
		garage_id: 106,
		part_id: 355,
		quantity: 387,
	},
	{
		garage_id: 106,
		part_id: 356,
		quantity: 484,
	},
	{
		garage_id: 106,
		part_id: 357,
		quantity: 333,
	},
	{
		garage_id: 106,
		part_id: 358,
		quantity: 284,
	},
	{
		garage_id: 106,
		part_id: 359,
		quantity: 240,
	},
	{
		garage_id: 106,
		part_id: 360,
		quantity: 47,
	},
	{
		garage_id: 106,
		part_id: 361,
		quantity: 159,
	},
	{
		garage_id: 106,
		part_id: 362,
		quantity: 60,
	},
	{
		garage_id: 106,
		part_id: 363,
		quantity: 285,
	},
	{
		garage_id: 106,
		part_id: 364,
		quantity: 338,
	},
	{
		garage_id: 106,
		part_id: 365,
		quantity: 439,
	},
	{
		garage_id: 106,
		part_id: 366,
		quantity: 202,
	},
	{
		garage_id: 106,
		part_id: 367,
		quantity: 79,
	},
	{
		garage_id: 106,
		part_id: 368,
		quantity: 487,
	},
	{
		garage_id: 106,
		part_id: 369,
		quantity: 338,
	},
	{
		garage_id: 106,
		part_id: 370,
		quantity: 379,
	},
	{
		garage_id: 106,
		part_id: 371,
		quantity: 215,
	},
	{
		garage_id: 106,
		part_id: 372,
		quantity: 347,
	},
	{
		garage_id: 106,
		part_id: 373,
		quantity: 464,
	},
	{
		garage_id: 106,
		part_id: 374,
		quantity: 195,
	},
	{
		garage_id: 106,
		part_id: 375,
		quantity: 44,
	},
	{
		garage_id: 106,
		part_id: 376,
		quantity: 410,
	},
	{
		garage_id: 106,
		part_id: 377,
		quantity: 223,
	},
	{
		garage_id: 106,
		part_id: 378,
		quantity: 274,
	},
	{
		garage_id: 106,
		part_id: 379,
		quantity: 353,
	},
	{
		garage_id: 106,
		part_id: 380,
		quantity: 372,
	},
	{
		garage_id: 106,
		part_id: 381,
		quantity: 208,
	},
	{
		garage_id: 106,
		part_id: 382,
		quantity: 145,
	},
	{
		garage_id: 106,
		part_id: 383,
		quantity: 438,
	},
	{
		garage_id: 106,
		part_id: 384,
		quantity: 437,
	},
	{
		garage_id: 106,
		part_id: 385,
		quantity: 101,
	},
	{
		garage_id: 106,
		part_id: 386,
		quantity: 407,
	},
	{
		garage_id: 106,
		part_id: 387,
		quantity: 475,
	},
	{
		garage_id: 106,
		part_id: 388,
		quantity: 16,
	},
	{
		garage_id: 106,
		part_id: 389,
		quantity: 11,
	},
	{
		garage_id: 106,
		part_id: 390,
		quantity: 473,
	},
	{
		garage_id: 106,
		part_id: 391,
		quantity: 430,
	},
	{
		garage_id: 106,
		part_id: 392,
		quantity: 176,
	},
	{
		garage_id: 106,
		part_id: 393,
		quantity: 135,
	},
	{
		garage_id: 106,
		part_id: 394,
		quantity: 429,
	},
	{
		garage_id: 106,
		part_id: 395,
		quantity: 68,
	},
	{
		garage_id: 106,
		part_id: 396,
		quantity: 115,
	},
	{
		garage_id: 106,
		part_id: 397,
		quantity: 111,
	},
	{
		garage_id: 106,
		part_id: 398,
		quantity: 491,
	},
	{
		garage_id: 106,
		part_id: 399,
		quantity: 103,
	},
	{
		garage_id: 107,
		part_id: 0,
		quantity: 192,
	},
	{
		garage_id: 107,
		part_id: 1,
		quantity: 225,
	},
	{
		garage_id: 107,
		part_id: 2,
		quantity: 180,
	},
	{
		garage_id: 107,
		part_id: 3,
		quantity: 127,
	},
	{
		garage_id: 107,
		part_id: 4,
		quantity: 235,
	},
	{
		garage_id: 107,
		part_id: 5,
		quantity: 305,
	},
	{
		garage_id: 107,
		part_id: 6,
		quantity: 247,
	},
	{
		garage_id: 107,
		part_id: 7,
		quantity: 55,
	},
	{
		garage_id: 107,
		part_id: 8,
		quantity: 175,
	},
	{
		garage_id: 107,
		part_id: 9,
		quantity: 450,
	},
	{
		garage_id: 107,
		part_id: 10,
		quantity: 117,
	},
	{
		garage_id: 107,
		part_id: 11,
		quantity: 208,
	},
	{
		garage_id: 107,
		part_id: 12,
		quantity: 375,
	},
	{
		garage_id: 107,
		part_id: 13,
		quantity: 326,
	},
	{
		garage_id: 107,
		part_id: 14,
		quantity: 88,
	},
	{
		garage_id: 107,
		part_id: 15,
		quantity: 434,
	},
	{
		garage_id: 107,
		part_id: 16,
		quantity: 325,
	},
	{
		garage_id: 107,
		part_id: 17,
		quantity: 300,
	},
	{
		garage_id: 107,
		part_id: 18,
		quantity: 22,
	},
	{
		garage_id: 107,
		part_id: 19,
		quantity: 456,
	},
	{
		garage_id: 107,
		part_id: 20,
		quantity: 443,
	},
	{
		garage_id: 107,
		part_id: 21,
		quantity: 248,
	},
	{
		garage_id: 107,
		part_id: 22,
		quantity: 232,
	},
	{
		garage_id: 107,
		part_id: 23,
		quantity: 103,
	},
	{
		garage_id: 107,
		part_id: 24,
		quantity: 3,
	},
	{
		garage_id: 107,
		part_id: 25,
		quantity: 418,
	},
	{
		garage_id: 107,
		part_id: 26,
		quantity: 459,
	},
	{
		garage_id: 107,
		part_id: 27,
		quantity: 179,
	},
	{
		garage_id: 107,
		part_id: 28,
		quantity: 94,
	},
	{
		garage_id: 107,
		part_id: 29,
		quantity: 83,
	},
	{
		garage_id: 107,
		part_id: 30,
		quantity: 37,
	},
	{
		garage_id: 107,
		part_id: 31,
		quantity: 219,
	},
	{
		garage_id: 107,
		part_id: 32,
		quantity: 397,
	},
	{
		garage_id: 107,
		part_id: 33,
		quantity: 470,
	},
	{
		garage_id: 107,
		part_id: 34,
		quantity: 280,
	},
	{
		garage_id: 107,
		part_id: 35,
		quantity: 340,
	},
	{
		garage_id: 107,
		part_id: 36,
		quantity: 415,
	},
	{
		garage_id: 107,
		part_id: 37,
		quantity: 296,
	},
	{
		garage_id: 107,
		part_id: 38,
		quantity: 349,
	},
	{
		garage_id: 107,
		part_id: 39,
		quantity: 128,
	},
	{
		garage_id: 107,
		part_id: 40,
		quantity: 466,
	},
	{
		garage_id: 107,
		part_id: 41,
		quantity: 475,
	},
	{
		garage_id: 107,
		part_id: 42,
		quantity: 369,
	},
	{
		garage_id: 107,
		part_id: 43,
		quantity: 69,
	},
	{
		garage_id: 107,
		part_id: 44,
		quantity: 407,
	},
	{
		garage_id: 107,
		part_id: 45,
		quantity: 230,
	},
	{
		garage_id: 107,
		part_id: 46,
		quantity: 236,
	},
	{
		garage_id: 107,
		part_id: 47,
		quantity: 290,
	},
	{
		garage_id: 107,
		part_id: 48,
		quantity: 406,
	},
	{
		garage_id: 107,
		part_id: 49,
		quantity: 173,
	},
	{
		garage_id: 107,
		part_id: 50,
		quantity: 197,
	},
	{
		garage_id: 107,
		part_id: 51,
		quantity: 116,
	},
	{
		garage_id: 107,
		part_id: 52,
		quantity: 411,
	},
	{
		garage_id: 107,
		part_id: 53,
		quantity: 162,
	},
	{
		garage_id: 107,
		part_id: 54,
		quantity: 140,
	},
	{
		garage_id: 107,
		part_id: 55,
		quantity: 410,
	},
	{
		garage_id: 107,
		part_id: 56,
		quantity: 215,
	},
	{
		garage_id: 107,
		part_id: 57,
		quantity: 204,
	},
	{
		garage_id: 107,
		part_id: 58,
		quantity: 134,
	},
	{
		garage_id: 107,
		part_id: 59,
		quantity: 4,
	},
	{
		garage_id: 107,
		part_id: 60,
		quantity: 130,
	},
	{
		garage_id: 107,
		part_id: 61,
		quantity: 159,
	},
	{
		garage_id: 107,
		part_id: 62,
		quantity: 99,
	},
	{
		garage_id: 107,
		part_id: 63,
		quantity: 67,
	},
	{
		garage_id: 107,
		part_id: 64,
		quantity: 108,
	},
	{
		garage_id: 107,
		part_id: 65,
		quantity: 359,
	},
	{
		garage_id: 107,
		part_id: 66,
		quantity: 250,
	},
	{
		garage_id: 107,
		part_id: 67,
		quantity: 477,
	},
	{
		garage_id: 107,
		part_id: 68,
		quantity: 317,
	},
	{
		garage_id: 107,
		part_id: 69,
		quantity: 395,
	},
	{
		garage_id: 107,
		part_id: 70,
		quantity: 85,
	},
	{
		garage_id: 107,
		part_id: 71,
		quantity: 130,
	},
	{
		garage_id: 107,
		part_id: 72,
		quantity: 350,
	},
	{
		garage_id: 107,
		part_id: 73,
		quantity: 412,
	},
	{
		garage_id: 107,
		part_id: 74,
		quantity: 187,
	},
	{
		garage_id: 107,
		part_id: 75,
		quantity: 232,
	},
	{
		garage_id: 107,
		part_id: 76,
		quantity: 140,
	},
	{
		garage_id: 107,
		part_id: 77,
		quantity: 298,
	},
	{
		garage_id: 107,
		part_id: 78,
		quantity: 134,
	},
	{
		garage_id: 107,
		part_id: 79,
		quantity: 303,
	},
	{
		garage_id: 107,
		part_id: 80,
		quantity: 68,
	},
	{
		garage_id: 107,
		part_id: 81,
		quantity: 3,
	},
	{
		garage_id: 107,
		part_id: 82,
		quantity: 68,
	},
	{
		garage_id: 107,
		part_id: 83,
		quantity: 342,
	},
	{
		garage_id: 107,
		part_id: 84,
		quantity: 424,
	},
	{
		garage_id: 107,
		part_id: 85,
		quantity: 471,
	},
	{
		garage_id: 107,
		part_id: 86,
		quantity: 359,
	},
	{
		garage_id: 107,
		part_id: 87,
		quantity: 204,
	},
	{
		garage_id: 107,
		part_id: 88,
		quantity: 55,
	},
	{
		garage_id: 107,
		part_id: 89,
		quantity: 151,
	},
	{
		garage_id: 107,
		part_id: 90,
		quantity: 133,
	},
	{
		garage_id: 107,
		part_id: 91,
		quantity: 347,
	},
	{
		garage_id: 107,
		part_id: 92,
		quantity: 343,
	},
	{
		garage_id: 107,
		part_id: 93,
		quantity: 270,
	},
	{
		garage_id: 107,
		part_id: 94,
		quantity: 50,
	},
	{
		garage_id: 107,
		part_id: 95,
		quantity: 186,
	},
	{
		garage_id: 107,
		part_id: 96,
		quantity: 37,
	},
	{
		garage_id: 107,
		part_id: 97,
		quantity: 358,
	},
	{
		garage_id: 107,
		part_id: 98,
		quantity: 354,
	},
	{
		garage_id: 107,
		part_id: 99,
		quantity: 313,
	},
	{
		garage_id: 107,
		part_id: 100,
		quantity: 384,
	},
	{
		garage_id: 107,
		part_id: 101,
		quantity: 433,
	},
	{
		garage_id: 107,
		part_id: 102,
		quantity: 18,
	},
	{
		garage_id: 107,
		part_id: 103,
		quantity: 118,
	},
	{
		garage_id: 107,
		part_id: 104,
		quantity: 485,
	},
	{
		garage_id: 107,
		part_id: 105,
		quantity: 369,
	},
	{
		garage_id: 107,
		part_id: 106,
		quantity: 223,
	},
	{
		garage_id: 107,
		part_id: 107,
		quantity: 495,
	},
	{
		garage_id: 107,
		part_id: 108,
		quantity: 367,
	},
	{
		garage_id: 107,
		part_id: 109,
		quantity: 181,
	},
	{
		garage_id: 107,
		part_id: 110,
		quantity: 131,
	},
	{
		garage_id: 107,
		part_id: 111,
		quantity: 330,
	},
	{
		garage_id: 107,
		part_id: 112,
		quantity: 64,
	},
	{
		garage_id: 107,
		part_id: 113,
		quantity: 460,
	},
	{
		garage_id: 107,
		part_id: 114,
		quantity: 43,
	},
	{
		garage_id: 107,
		part_id: 115,
		quantity: 249,
	},
	{
		garage_id: 107,
		part_id: 116,
		quantity: 141,
	},
	{
		garage_id: 107,
		part_id: 117,
		quantity: 252,
	},
	{
		garage_id: 107,
		part_id: 118,
		quantity: 360,
	},
	{
		garage_id: 107,
		part_id: 119,
		quantity: 16,
	},
	{
		garage_id: 107,
		part_id: 120,
		quantity: 22,
	},
	{
		garage_id: 107,
		part_id: 121,
		quantity: 341,
	},
	{
		garage_id: 107,
		part_id: 122,
		quantity: 143,
	},
	{
		garage_id: 107,
		part_id: 123,
		quantity: 414,
	},
	{
		garage_id: 107,
		part_id: 124,
		quantity: 429,
	},
	{
		garage_id: 107,
		part_id: 125,
		quantity: 456,
	},
	{
		garage_id: 107,
		part_id: 126,
		quantity: 320,
	},
	{
		garage_id: 107,
		part_id: 127,
		quantity: 213,
	},
	{
		garage_id: 107,
		part_id: 128,
		quantity: 337,
	},
	{
		garage_id: 107,
		part_id: 129,
		quantity: 93,
	},
	{
		garage_id: 107,
		part_id: 130,
		quantity: 474,
	},
	{
		garage_id: 107,
		part_id: 131,
		quantity: 463,
	},
	{
		garage_id: 107,
		part_id: 132,
		quantity: 139,
	},
	{
		garage_id: 107,
		part_id: 133,
		quantity: 326,
	},
	{
		garage_id: 107,
		part_id: 134,
		quantity: 443,
	},
	{
		garage_id: 107,
		part_id: 135,
		quantity: 120,
	},
	{
		garage_id: 107,
		part_id: 136,
		quantity: 313,
	},
	{
		garage_id: 107,
		part_id: 137,
		quantity: 95,
	},
	{
		garage_id: 107,
		part_id: 138,
		quantity: 350,
	},
	{
		garage_id: 107,
		part_id: 139,
		quantity: 298,
	},
	{
		garage_id: 107,
		part_id: 140,
		quantity: 253,
	},
	{
		garage_id: 107,
		part_id: 141,
		quantity: 258,
	},
	{
		garage_id: 107,
		part_id: 142,
		quantity: 251,
	},
	{
		garage_id: 107,
		part_id: 143,
		quantity: 483,
	},
	{
		garage_id: 107,
		part_id: 144,
		quantity: 326,
	},
	{
		garage_id: 107,
		part_id: 145,
		quantity: 337,
	},
	{
		garage_id: 107,
		part_id: 146,
		quantity: 241,
	},
	{
		garage_id: 107,
		part_id: 147,
		quantity: 370,
	},
	{
		garage_id: 107,
		part_id: 148,
		quantity: 292,
	},
	{
		garage_id: 107,
		part_id: 149,
		quantity: 96,
	},
	{
		garage_id: 107,
		part_id: 150,
		quantity: 299,
	},
	{
		garage_id: 107,
		part_id: 151,
		quantity: 63,
	},
	{
		garage_id: 107,
		part_id: 152,
		quantity: 88,
	},
	{
		garage_id: 107,
		part_id: 153,
		quantity: 237,
	},
	{
		garage_id: 107,
		part_id: 154,
		quantity: 68,
	},
	{
		garage_id: 107,
		part_id: 155,
		quantity: 335,
	},
	{
		garage_id: 107,
		part_id: 156,
		quantity: 290,
	},
	{
		garage_id: 107,
		part_id: 157,
		quantity: 298,
	},
	{
		garage_id: 107,
		part_id: 158,
		quantity: 266,
	},
	{
		garage_id: 107,
		part_id: 159,
		quantity: 126,
	},
	{
		garage_id: 107,
		part_id: 160,
		quantity: 468,
	},
	{
		garage_id: 107,
		part_id: 161,
		quantity: 383,
	},
	{
		garage_id: 107,
		part_id: 162,
		quantity: 115,
	},
	{
		garage_id: 107,
		part_id: 163,
		quantity: 201,
	},
	{
		garage_id: 107,
		part_id: 164,
		quantity: 53,
	},
	{
		garage_id: 107,
		part_id: 165,
		quantity: 163,
	},
	{
		garage_id: 107,
		part_id: 166,
		quantity: 217,
	},
	{
		garage_id: 107,
		part_id: 167,
		quantity: 235,
	},
	{
		garage_id: 107,
		part_id: 168,
		quantity: 426,
	},
	{
		garage_id: 107,
		part_id: 169,
		quantity: 113,
	},
	{
		garage_id: 107,
		part_id: 170,
		quantity: 337,
	},
	{
		garage_id: 107,
		part_id: 171,
		quantity: 389,
	},
	{
		garage_id: 107,
		part_id: 172,
		quantity: 308,
	},
	{
		garage_id: 107,
		part_id: 173,
		quantity: 234,
	},
	{
		garage_id: 107,
		part_id: 174,
		quantity: 287,
	},
	{
		garage_id: 107,
		part_id: 175,
		quantity: 254,
	},
	{
		garage_id: 107,
		part_id: 176,
		quantity: 474,
	},
	{
		garage_id: 107,
		part_id: 177,
		quantity: 285,
	},
	{
		garage_id: 107,
		part_id: 178,
		quantity: 223,
	},
	{
		garage_id: 107,
		part_id: 179,
		quantity: 468,
	},
	{
		garage_id: 107,
		part_id: 180,
		quantity: 52,
	},
	{
		garage_id: 107,
		part_id: 181,
		quantity: 408,
	},
	{
		garage_id: 107,
		part_id: 182,
		quantity: 174,
	},
	{
		garage_id: 107,
		part_id: 183,
		quantity: 394,
	},
	{
		garage_id: 107,
		part_id: 184,
		quantity: 20,
	},
	{
		garage_id: 107,
		part_id: 185,
		quantity: 452,
	},
	{
		garage_id: 107,
		part_id: 186,
		quantity: 10,
	},
	{
		garage_id: 107,
		part_id: 187,
		quantity: 399,
	},
	{
		garage_id: 107,
		part_id: 188,
		quantity: 316,
	},
	{
		garage_id: 107,
		part_id: 189,
		quantity: 53,
	},
	{
		garage_id: 107,
		part_id: 190,
		quantity: 272,
	},
	{
		garage_id: 107,
		part_id: 191,
		quantity: 67,
	},
	{
		garage_id: 107,
		part_id: 192,
		quantity: 160,
	},
	{
		garage_id: 107,
		part_id: 193,
		quantity: 202,
	},
	{
		garage_id: 107,
		part_id: 194,
		quantity: 44,
	},
	{
		garage_id: 107,
		part_id: 195,
		quantity: 83,
	},
	{
		garage_id: 107,
		part_id: 196,
		quantity: 270,
	},
	{
		garage_id: 107,
		part_id: 197,
		quantity: 64,
	},
	{
		garage_id: 107,
		part_id: 198,
		quantity: 311,
	},
	{
		garage_id: 107,
		part_id: 199,
		quantity: 182,
	},
	{
		garage_id: 107,
		part_id: 200,
		quantity: 167,
	},
	{
		garage_id: 107,
		part_id: 201,
		quantity: 182,
	},
	{
		garage_id: 107,
		part_id: 202,
		quantity: 32,
	},
	{
		garage_id: 107,
		part_id: 203,
		quantity: 78,
	},
	{
		garage_id: 107,
		part_id: 204,
		quantity: 54,
	},
	{
		garage_id: 107,
		part_id: 205,
		quantity: 365,
	},
	{
		garage_id: 107,
		part_id: 206,
		quantity: 445,
	},
	{
		garage_id: 107,
		part_id: 207,
		quantity: 203,
	},
	{
		garage_id: 107,
		part_id: 208,
		quantity: 301,
	},
	{
		garage_id: 107,
		part_id: 209,
		quantity: 439,
	},
	{
		garage_id: 107,
		part_id: 210,
		quantity: 466,
	},
	{
		garage_id: 107,
		part_id: 211,
		quantity: 301,
	},
	{
		garage_id: 107,
		part_id: 212,
		quantity: 392,
	},
	{
		garage_id: 107,
		part_id: 213,
		quantity: 30,
	},
	{
		garage_id: 107,
		part_id: 214,
		quantity: 499,
	},
	{
		garage_id: 107,
		part_id: 215,
		quantity: 333,
	},
	{
		garage_id: 107,
		part_id: 216,
		quantity: 389,
	},
	{
		garage_id: 107,
		part_id: 217,
		quantity: 208,
	},
	{
		garage_id: 107,
		part_id: 218,
		quantity: 237,
	},
	{
		garage_id: 107,
		part_id: 219,
		quantity: 123,
	},
	{
		garage_id: 107,
		part_id: 220,
		quantity: 230,
	},
	{
		garage_id: 107,
		part_id: 221,
		quantity: 51,
	},
	{
		garage_id: 107,
		part_id: 222,
		quantity: 311,
	},
	{
		garage_id: 107,
		part_id: 223,
		quantity: 226,
	},
	{
		garage_id: 107,
		part_id: 224,
		quantity: 468,
	},
	{
		garage_id: 107,
		part_id: 225,
		quantity: 467,
	},
	{
		garage_id: 107,
		part_id: 226,
		quantity: 188,
	},
	{
		garage_id: 107,
		part_id: 227,
		quantity: 144,
	},
	{
		garage_id: 107,
		part_id: 228,
		quantity: 190,
	},
	{
		garage_id: 107,
		part_id: 229,
		quantity: 349,
	},
	{
		garage_id: 107,
		part_id: 230,
		quantity: 201,
	},
	{
		garage_id: 107,
		part_id: 231,
		quantity: 315,
	},
	{
		garage_id: 107,
		part_id: 232,
		quantity: 252,
	},
	{
		garage_id: 107,
		part_id: 233,
		quantity: 498,
	},
	{
		garage_id: 107,
		part_id: 234,
		quantity: 226,
	},
	{
		garage_id: 107,
		part_id: 235,
		quantity: 338,
	},
	{
		garage_id: 107,
		part_id: 236,
		quantity: 252,
	},
	{
		garage_id: 107,
		part_id: 237,
		quantity: 14,
	},
	{
		garage_id: 107,
		part_id: 238,
		quantity: 306,
	},
	{
		garage_id: 107,
		part_id: 239,
		quantity: 123,
	},
	{
		garage_id: 107,
		part_id: 240,
		quantity: 281,
	},
	{
		garage_id: 107,
		part_id: 241,
		quantity: 414,
	},
	{
		garage_id: 107,
		part_id: 242,
		quantity: 83,
	},
	{
		garage_id: 107,
		part_id: 243,
		quantity: 28,
	},
	{
		garage_id: 107,
		part_id: 244,
		quantity: 368,
	},
	{
		garage_id: 107,
		part_id: 245,
		quantity: 67,
	},
	{
		garage_id: 107,
		part_id: 246,
		quantity: 22,
	},
	{
		garage_id: 107,
		part_id: 247,
		quantity: 378,
	},
	{
		garage_id: 107,
		part_id: 248,
		quantity: 233,
	},
	{
		garage_id: 107,
		part_id: 249,
		quantity: 48,
	},
	{
		garage_id: 107,
		part_id: 250,
		quantity: 420,
	},
	{
		garage_id: 107,
		part_id: 251,
		quantity: 283,
	},
	{
		garage_id: 107,
		part_id: 252,
		quantity: 387,
	},
	{
		garage_id: 107,
		part_id: 253,
		quantity: 72,
	},
	{
		garage_id: 107,
		part_id: 254,
		quantity: 176,
	},
	{
		garage_id: 107,
		part_id: 255,
		quantity: 121,
	},
	{
		garage_id: 107,
		part_id: 256,
		quantity: 452,
	},
	{
		garage_id: 107,
		part_id: 257,
		quantity: 461,
	},
	{
		garage_id: 107,
		part_id: 258,
		quantity: 456,
	},
	{
		garage_id: 107,
		part_id: 259,
		quantity: 477,
	},
	{
		garage_id: 107,
		part_id: 260,
		quantity: 143,
	},
	{
		garage_id: 107,
		part_id: 261,
		quantity: 177,
	},
	{
		garage_id: 107,
		part_id: 262,
		quantity: 285,
	},
	{
		garage_id: 107,
		part_id: 263,
		quantity: 304,
	},
	{
		garage_id: 107,
		part_id: 264,
		quantity: 291,
	},
	{
		garage_id: 107,
		part_id: 265,
		quantity: 410,
	},
	{
		garage_id: 107,
		part_id: 266,
		quantity: 131,
	},
	{
		garage_id: 107,
		part_id: 267,
		quantity: 452,
	},
	{
		garage_id: 107,
		part_id: 268,
		quantity: 367,
	},
	{
		garage_id: 107,
		part_id: 269,
		quantity: 248,
	},
	{
		garage_id: 107,
		part_id: 270,
		quantity: 342,
	},
	{
		garage_id: 107,
		part_id: 271,
		quantity: 316,
	},
	{
		garage_id: 107,
		part_id: 272,
		quantity: 457,
	},
	{
		garage_id: 107,
		part_id: 273,
		quantity: 245,
	},
	{
		garage_id: 107,
		part_id: 274,
		quantity: 275,
	},
	{
		garage_id: 107,
		part_id: 275,
		quantity: 359,
	},
	{
		garage_id: 107,
		part_id: 276,
		quantity: 102,
	},
	{
		garage_id: 107,
		part_id: 277,
		quantity: 218,
	},
	{
		garage_id: 107,
		part_id: 278,
		quantity: 3,
	},
	{
		garage_id: 107,
		part_id: 279,
		quantity: 261,
	},
	{
		garage_id: 107,
		part_id: 280,
		quantity: 92,
	},
	{
		garage_id: 107,
		part_id: 281,
		quantity: 398,
	},
	{
		garage_id: 107,
		part_id: 282,
		quantity: 487,
	},
	{
		garage_id: 107,
		part_id: 283,
		quantity: 431,
	},
	{
		garage_id: 107,
		part_id: 284,
		quantity: 175,
	},
	{
		garage_id: 107,
		part_id: 285,
		quantity: 192,
	},
	{
		garage_id: 107,
		part_id: 286,
		quantity: 5,
	},
	{
		garage_id: 107,
		part_id: 287,
		quantity: 10,
	},
	{
		garage_id: 107,
		part_id: 288,
		quantity: 328,
	},
	{
		garage_id: 107,
		part_id: 289,
		quantity: 456,
	},
	{
		garage_id: 107,
		part_id: 290,
		quantity: 394,
	},
	{
		garage_id: 107,
		part_id: 291,
		quantity: 207,
	},
	{
		garage_id: 107,
		part_id: 292,
		quantity: 344,
	},
	{
		garage_id: 107,
		part_id: 293,
		quantity: 423,
	},
	{
		garage_id: 107,
		part_id: 294,
		quantity: 81,
	},
	{
		garage_id: 107,
		part_id: 295,
		quantity: 0,
	},
	{
		garage_id: 107,
		part_id: 296,
		quantity: 401,
	},
	{
		garage_id: 107,
		part_id: 297,
		quantity: 34,
	},
	{
		garage_id: 107,
		part_id: 298,
		quantity: 17,
	},
	{
		garage_id: 107,
		part_id: 299,
		quantity: 136,
	},
	{
		garage_id: 107,
		part_id: 300,
		quantity: 445,
	},
	{
		garage_id: 107,
		part_id: 301,
		quantity: 395,
	},
	{
		garage_id: 107,
		part_id: 302,
		quantity: 201,
	},
	{
		garage_id: 107,
		part_id: 303,
		quantity: 196,
	},
	{
		garage_id: 107,
		part_id: 304,
		quantity: 107,
	},
	{
		garage_id: 107,
		part_id: 305,
		quantity: 235,
	},
	{
		garage_id: 107,
		part_id: 306,
		quantity: 423,
	},
	{
		garage_id: 107,
		part_id: 307,
		quantity: 304,
	},
	{
		garage_id: 107,
		part_id: 308,
		quantity: 430,
	},
	{
		garage_id: 107,
		part_id: 309,
		quantity: 297,
	},
	{
		garage_id: 107,
		part_id: 310,
		quantity: 50,
	},
	{
		garage_id: 107,
		part_id: 311,
		quantity: 384,
	},
	{
		garage_id: 107,
		part_id: 312,
		quantity: 344,
	},
	{
		garage_id: 107,
		part_id: 313,
		quantity: 192,
	},
	{
		garage_id: 107,
		part_id: 314,
		quantity: 411,
	},
	{
		garage_id: 107,
		part_id: 315,
		quantity: 357,
	},
	{
		garage_id: 107,
		part_id: 316,
		quantity: 474,
	},
	{
		garage_id: 107,
		part_id: 317,
		quantity: 191,
	},
	{
		garage_id: 107,
		part_id: 318,
		quantity: 7,
	},
	{
		garage_id: 107,
		part_id: 319,
		quantity: 172,
	},
	{
		garage_id: 107,
		part_id: 320,
		quantity: 405,
	},
	{
		garage_id: 107,
		part_id: 321,
		quantity: 476,
	},
	{
		garage_id: 107,
		part_id: 322,
		quantity: 435,
	},
	{
		garage_id: 107,
		part_id: 323,
		quantity: 435,
	},
	{
		garage_id: 107,
		part_id: 324,
		quantity: 156,
	},
	{
		garage_id: 107,
		part_id: 325,
		quantity: 50,
	},
	{
		garage_id: 107,
		part_id: 326,
		quantity: 207,
	},
	{
		garage_id: 107,
		part_id: 327,
		quantity: 475,
	},
	{
		garage_id: 107,
		part_id: 328,
		quantity: 62,
	},
	{
		garage_id: 107,
		part_id: 329,
		quantity: 203,
	},
	{
		garage_id: 107,
		part_id: 330,
		quantity: 451,
	},
	{
		garage_id: 107,
		part_id: 331,
		quantity: 257,
	},
	{
		garage_id: 107,
		part_id: 332,
		quantity: 152,
	},
	{
		garage_id: 107,
		part_id: 333,
		quantity: 44,
	},
	{
		garage_id: 107,
		part_id: 334,
		quantity: 8,
	},
	{
		garage_id: 107,
		part_id: 335,
		quantity: 112,
	},
	{
		garage_id: 107,
		part_id: 336,
		quantity: 99,
	},
	{
		garage_id: 107,
		part_id: 337,
		quantity: 243,
	},
	{
		garage_id: 107,
		part_id: 338,
		quantity: 494,
	},
	{
		garage_id: 107,
		part_id: 339,
		quantity: 287,
	},
	{
		garage_id: 107,
		part_id: 340,
		quantity: 435,
	},
	{
		garage_id: 107,
		part_id: 341,
		quantity: 274,
	},
	{
		garage_id: 107,
		part_id: 342,
		quantity: 18,
	},
	{
		garage_id: 107,
		part_id: 343,
		quantity: 45,
	},
	{
		garage_id: 107,
		part_id: 344,
		quantity: 138,
	},
	{
		garage_id: 107,
		part_id: 345,
		quantity: 31,
	},
	{
		garage_id: 107,
		part_id: 346,
		quantity: 458,
	},
	{
		garage_id: 107,
		part_id: 347,
		quantity: 484,
	},
	{
		garage_id: 107,
		part_id: 348,
		quantity: 31,
	},
	{
		garage_id: 107,
		part_id: 349,
		quantity: 145,
	},
	{
		garage_id: 107,
		part_id: 350,
		quantity: 255,
	},
	{
		garage_id: 107,
		part_id: 351,
		quantity: 480,
	},
	{
		garage_id: 107,
		part_id: 352,
		quantity: 207,
	},
	{
		garage_id: 107,
		part_id: 353,
		quantity: 337,
	},
	{
		garage_id: 107,
		part_id: 354,
		quantity: 451,
	},
	{
		garage_id: 107,
		part_id: 355,
		quantity: 136,
	},
	{
		garage_id: 107,
		part_id: 356,
		quantity: 287,
	},
	{
		garage_id: 107,
		part_id: 357,
		quantity: 90,
	},
	{
		garage_id: 107,
		part_id: 358,
		quantity: 86,
	},
	{
		garage_id: 107,
		part_id: 359,
		quantity: 15,
	},
	{
		garage_id: 107,
		part_id: 360,
		quantity: 82,
	},
	{
		garage_id: 107,
		part_id: 361,
		quantity: 229,
	},
	{
		garage_id: 107,
		part_id: 362,
		quantity: 174,
	},
	{
		garage_id: 107,
		part_id: 363,
		quantity: 74,
	},
	{
		garage_id: 107,
		part_id: 364,
		quantity: 105,
	},
	{
		garage_id: 107,
		part_id: 365,
		quantity: 231,
	},
	{
		garage_id: 107,
		part_id: 366,
		quantity: 252,
	},
	{
		garage_id: 107,
		part_id: 367,
		quantity: 269,
	},
	{
		garage_id: 107,
		part_id: 368,
		quantity: 19,
	},
	{
		garage_id: 107,
		part_id: 369,
		quantity: 61,
	},
	{
		garage_id: 107,
		part_id: 370,
		quantity: 60,
	},
	{
		garage_id: 107,
		part_id: 371,
		quantity: 290,
	},
	{
		garage_id: 107,
		part_id: 372,
		quantity: 237,
	},
	{
		garage_id: 107,
		part_id: 373,
		quantity: 496,
	},
	{
		garage_id: 107,
		part_id: 374,
		quantity: 256,
	},
	{
		garage_id: 107,
		part_id: 375,
		quantity: 299,
	},
	{
		garage_id: 107,
		part_id: 376,
		quantity: 202,
	},
	{
		garage_id: 107,
		part_id: 377,
		quantity: 458,
	},
	{
		garage_id: 107,
		part_id: 378,
		quantity: 165,
	},
	{
		garage_id: 107,
		part_id: 379,
		quantity: 257,
	},
	{
		garage_id: 107,
		part_id: 380,
		quantity: 360,
	},
	{
		garage_id: 107,
		part_id: 381,
		quantity: 266,
	},
	{
		garage_id: 107,
		part_id: 382,
		quantity: 243,
	},
	{
		garage_id: 107,
		part_id: 383,
		quantity: 372,
	},
	{
		garage_id: 107,
		part_id: 384,
		quantity: 474,
	},
	{
		garage_id: 107,
		part_id: 385,
		quantity: 77,
	},
	{
		garage_id: 107,
		part_id: 386,
		quantity: 38,
	},
	{
		garage_id: 107,
		part_id: 387,
		quantity: 65,
	},
	{
		garage_id: 107,
		part_id: 388,
		quantity: 15,
	},
	{
		garage_id: 107,
		part_id: 389,
		quantity: 189,
	},
	{
		garage_id: 107,
		part_id: 390,
		quantity: 326,
	},
	{
		garage_id: 107,
		part_id: 391,
		quantity: 332,
	},
	{
		garage_id: 107,
		part_id: 392,
		quantity: 247,
	},
	{
		garage_id: 107,
		part_id: 393,
		quantity: 470,
	},
	{
		garage_id: 107,
		part_id: 394,
		quantity: 99,
	},
	{
		garage_id: 107,
		part_id: 395,
		quantity: 226,
	},
	{
		garage_id: 107,
		part_id: 396,
		quantity: 417,
	},
	{
		garage_id: 107,
		part_id: 397,
		quantity: 492,
	},
	{
		garage_id: 107,
		part_id: 398,
		quantity: 298,
	},
	{
		garage_id: 107,
		part_id: 399,
		quantity: 490,
	},
	{
		garage_id: 108,
		part_id: 0,
		quantity: 357,
	},
	{
		garage_id: 108,
		part_id: 1,
		quantity: 441,
	},
	{
		garage_id: 108,
		part_id: 2,
		quantity: 407,
	},
	{
		garage_id: 108,
		part_id: 3,
		quantity: 400,
	},
	{
		garage_id: 108,
		part_id: 4,
		quantity: 20,
	},
	{
		garage_id: 108,
		part_id: 5,
		quantity: 494,
	},
	{
		garage_id: 108,
		part_id: 6,
		quantity: 468,
	},
	{
		garage_id: 108,
		part_id: 7,
		quantity: 309,
	},
	{
		garage_id: 108,
		part_id: 8,
		quantity: 19,
	},
	{
		garage_id: 108,
		part_id: 9,
		quantity: 162,
	},
	{
		garage_id: 108,
		part_id: 10,
		quantity: 296,
	},
	{
		garage_id: 108,
		part_id: 11,
		quantity: 215,
	},
	{
		garage_id: 108,
		part_id: 12,
		quantity: 63,
	},
	{
		garage_id: 108,
		part_id: 13,
		quantity: 404,
	},
	{
		garage_id: 108,
		part_id: 14,
		quantity: 451,
	},
	{
		garage_id: 108,
		part_id: 15,
		quantity: 274,
	},
	{
		garage_id: 108,
		part_id: 16,
		quantity: 19,
	},
	{
		garage_id: 108,
		part_id: 17,
		quantity: 266,
	},
	{
		garage_id: 108,
		part_id: 18,
		quantity: 327,
	},
	{
		garage_id: 108,
		part_id: 19,
		quantity: 456,
	},
	{
		garage_id: 108,
		part_id: 20,
		quantity: 400,
	},
	{
		garage_id: 108,
		part_id: 21,
		quantity: 411,
	},
	{
		garage_id: 108,
		part_id: 22,
		quantity: 299,
	},
	{
		garage_id: 108,
		part_id: 23,
		quantity: 113,
	},
	{
		garage_id: 108,
		part_id: 24,
		quantity: 227,
	},
	{
		garage_id: 108,
		part_id: 25,
		quantity: 6,
	},
	{
		garage_id: 108,
		part_id: 26,
		quantity: 359,
	},
	{
		garage_id: 108,
		part_id: 27,
		quantity: 129,
	},
	{
		garage_id: 108,
		part_id: 28,
		quantity: 215,
	},
	{
		garage_id: 108,
		part_id: 29,
		quantity: 172,
	},
	{
		garage_id: 108,
		part_id: 30,
		quantity: 335,
	},
	{
		garage_id: 108,
		part_id: 31,
		quantity: 114,
	},
	{
		garage_id: 108,
		part_id: 32,
		quantity: 263,
	},
	{
		garage_id: 108,
		part_id: 33,
		quantity: 237,
	},
	{
		garage_id: 108,
		part_id: 34,
		quantity: 475,
	},
	{
		garage_id: 108,
		part_id: 35,
		quantity: 118,
	},
	{
		garage_id: 108,
		part_id: 36,
		quantity: 211,
	},
	{
		garage_id: 108,
		part_id: 37,
		quantity: 133,
	},
	{
		garage_id: 108,
		part_id: 38,
		quantity: 242,
	},
	{
		garage_id: 108,
		part_id: 39,
		quantity: 153,
	},
	{
		garage_id: 108,
		part_id: 40,
		quantity: 276,
	},
	{
		garage_id: 108,
		part_id: 41,
		quantity: 316,
	},
	{
		garage_id: 108,
		part_id: 42,
		quantity: 40,
	},
	{
		garage_id: 108,
		part_id: 43,
		quantity: 229,
	},
	{
		garage_id: 108,
		part_id: 44,
		quantity: 492,
	},
	{
		garage_id: 108,
		part_id: 45,
		quantity: 28,
	},
	{
		garage_id: 108,
		part_id: 46,
		quantity: 208,
	},
	{
		garage_id: 108,
		part_id: 47,
		quantity: 250,
	},
	{
		garage_id: 108,
		part_id: 48,
		quantity: 456,
	},
	{
		garage_id: 108,
		part_id: 49,
		quantity: 166,
	},
	{
		garage_id: 108,
		part_id: 50,
		quantity: 170,
	},
	{
		garage_id: 108,
		part_id: 51,
		quantity: 110,
	},
	{
		garage_id: 108,
		part_id: 52,
		quantity: 454,
	},
	{
		garage_id: 108,
		part_id: 53,
		quantity: 269,
	},
	{
		garage_id: 108,
		part_id: 54,
		quantity: 448,
	},
	{
		garage_id: 108,
		part_id: 55,
		quantity: 110,
	},
	{
		garage_id: 108,
		part_id: 56,
		quantity: 467,
	},
	{
		garage_id: 108,
		part_id: 57,
		quantity: 155,
	},
	{
		garage_id: 108,
		part_id: 58,
		quantity: 352,
	},
	{
		garage_id: 108,
		part_id: 59,
		quantity: 235,
	},
	{
		garage_id: 108,
		part_id: 60,
		quantity: 316,
	},
	{
		garage_id: 108,
		part_id: 61,
		quantity: 280,
	},
	{
		garage_id: 108,
		part_id: 62,
		quantity: 111,
	},
	{
		garage_id: 108,
		part_id: 63,
		quantity: 284,
	},
	{
		garage_id: 108,
		part_id: 64,
		quantity: 31,
	},
	{
		garage_id: 108,
		part_id: 65,
		quantity: 323,
	},
	{
		garage_id: 108,
		part_id: 66,
		quantity: 15,
	},
	{
		garage_id: 108,
		part_id: 67,
		quantity: 161,
	},
	{
		garage_id: 108,
		part_id: 68,
		quantity: 344,
	},
	{
		garage_id: 108,
		part_id: 69,
		quantity: 485,
	},
	{
		garage_id: 108,
		part_id: 70,
		quantity: 210,
	},
	{
		garage_id: 108,
		part_id: 71,
		quantity: 152,
	},
	{
		garage_id: 108,
		part_id: 72,
		quantity: 0,
	},
	{
		garage_id: 108,
		part_id: 73,
		quantity: 29,
	},
	{
		garage_id: 108,
		part_id: 74,
		quantity: 417,
	},
	{
		garage_id: 108,
		part_id: 75,
		quantity: 414,
	},
	{
		garage_id: 108,
		part_id: 76,
		quantity: 94,
	},
	{
		garage_id: 108,
		part_id: 77,
		quantity: 311,
	},
	{
		garage_id: 108,
		part_id: 78,
		quantity: 251,
	},
	{
		garage_id: 108,
		part_id: 79,
		quantity: 382,
	},
	{
		garage_id: 108,
		part_id: 80,
		quantity: 64,
	},
	{
		garage_id: 108,
		part_id: 81,
		quantity: 227,
	},
	{
		garage_id: 108,
		part_id: 82,
		quantity: 233,
	},
	{
		garage_id: 108,
		part_id: 83,
		quantity: 365,
	},
	{
		garage_id: 108,
		part_id: 84,
		quantity: 432,
	},
	{
		garage_id: 108,
		part_id: 85,
		quantity: 360,
	},
	{
		garage_id: 108,
		part_id: 86,
		quantity: 438,
	},
	{
		garage_id: 108,
		part_id: 87,
		quantity: 296,
	},
	{
		garage_id: 108,
		part_id: 88,
		quantity: 389,
	},
	{
		garage_id: 108,
		part_id: 89,
		quantity: 319,
	},
	{
		garage_id: 108,
		part_id: 90,
		quantity: 25,
	},
	{
		garage_id: 108,
		part_id: 91,
		quantity: 45,
	},
	{
		garage_id: 108,
		part_id: 92,
		quantity: 372,
	},
	{
		garage_id: 108,
		part_id: 93,
		quantity: 318,
	},
	{
		garage_id: 108,
		part_id: 94,
		quantity: 85,
	},
	{
		garage_id: 108,
		part_id: 95,
		quantity: 297,
	},
	{
		garage_id: 108,
		part_id: 96,
		quantity: 463,
	},
	{
		garage_id: 108,
		part_id: 97,
		quantity: 409,
	},
	{
		garage_id: 108,
		part_id: 98,
		quantity: 366,
	},
	{
		garage_id: 108,
		part_id: 99,
		quantity: 239,
	},
	{
		garage_id: 108,
		part_id: 100,
		quantity: 389,
	},
	{
		garage_id: 108,
		part_id: 101,
		quantity: 194,
	},
	{
		garage_id: 108,
		part_id: 102,
		quantity: 45,
	},
	{
		garage_id: 108,
		part_id: 103,
		quantity: 199,
	},
	{
		garage_id: 108,
		part_id: 104,
		quantity: 380,
	},
	{
		garage_id: 108,
		part_id: 105,
		quantity: 163,
	},
	{
		garage_id: 108,
		part_id: 106,
		quantity: 154,
	},
	{
		garage_id: 108,
		part_id: 107,
		quantity: 435,
	},
	{
		garage_id: 108,
		part_id: 108,
		quantity: 219,
	},
	{
		garage_id: 108,
		part_id: 109,
		quantity: 121,
	},
	{
		garage_id: 108,
		part_id: 110,
		quantity: 143,
	},
	{
		garage_id: 108,
		part_id: 111,
		quantity: 183,
	},
	{
		garage_id: 108,
		part_id: 112,
		quantity: 28,
	},
	{
		garage_id: 108,
		part_id: 113,
		quantity: 103,
	},
	{
		garage_id: 108,
		part_id: 114,
		quantity: 267,
	},
	{
		garage_id: 108,
		part_id: 115,
		quantity: 130,
	},
	{
		garage_id: 108,
		part_id: 116,
		quantity: 95,
	},
	{
		garage_id: 108,
		part_id: 117,
		quantity: 373,
	},
	{
		garage_id: 108,
		part_id: 118,
		quantity: 174,
	},
	{
		garage_id: 108,
		part_id: 119,
		quantity: 239,
	},
	{
		garage_id: 108,
		part_id: 120,
		quantity: 473,
	},
	{
		garage_id: 108,
		part_id: 121,
		quantity: 358,
	},
	{
		garage_id: 108,
		part_id: 122,
		quantity: 300,
	},
	{
		garage_id: 108,
		part_id: 123,
		quantity: 216,
	},
	{
		garage_id: 108,
		part_id: 124,
		quantity: 182,
	},
	{
		garage_id: 108,
		part_id: 125,
		quantity: 82,
	},
	{
		garage_id: 108,
		part_id: 126,
		quantity: 376,
	},
	{
		garage_id: 108,
		part_id: 127,
		quantity: 198,
	},
	{
		garage_id: 108,
		part_id: 128,
		quantity: 472,
	},
	{
		garage_id: 108,
		part_id: 129,
		quantity: 8,
	},
	{
		garage_id: 108,
		part_id: 130,
		quantity: 238,
	},
	{
		garage_id: 108,
		part_id: 131,
		quantity: 449,
	},
	{
		garage_id: 108,
		part_id: 132,
		quantity: 272,
	},
	{
		garage_id: 108,
		part_id: 133,
		quantity: 369,
	},
	{
		garage_id: 108,
		part_id: 134,
		quantity: 57,
	},
	{
		garage_id: 108,
		part_id: 135,
		quantity: 116,
	},
	{
		garage_id: 108,
		part_id: 136,
		quantity: 463,
	},
	{
		garage_id: 108,
		part_id: 137,
		quantity: 85,
	},
	{
		garage_id: 108,
		part_id: 138,
		quantity: 278,
	},
	{
		garage_id: 108,
		part_id: 139,
		quantity: 244,
	},
	{
		garage_id: 108,
		part_id: 140,
		quantity: 449,
	},
	{
		garage_id: 108,
		part_id: 141,
		quantity: 254,
	},
	{
		garage_id: 108,
		part_id: 142,
		quantity: 362,
	},
	{
		garage_id: 108,
		part_id: 143,
		quantity: 187,
	},
	{
		garage_id: 108,
		part_id: 144,
		quantity: 230,
	},
	{
		garage_id: 108,
		part_id: 145,
		quantity: 212,
	},
	{
		garage_id: 108,
		part_id: 146,
		quantity: 305,
	},
	{
		garage_id: 108,
		part_id: 147,
		quantity: 95,
	},
	{
		garage_id: 108,
		part_id: 148,
		quantity: 408,
	},
	{
		garage_id: 108,
		part_id: 149,
		quantity: 55,
	},
	{
		garage_id: 108,
		part_id: 150,
		quantity: 419,
	},
	{
		garage_id: 108,
		part_id: 151,
		quantity: 131,
	},
	{
		garage_id: 108,
		part_id: 152,
		quantity: 218,
	},
	{
		garage_id: 108,
		part_id: 153,
		quantity: 467,
	},
	{
		garage_id: 108,
		part_id: 154,
		quantity: 222,
	},
	{
		garage_id: 108,
		part_id: 155,
		quantity: 492,
	},
	{
		garage_id: 108,
		part_id: 156,
		quantity: 20,
	},
	{
		garage_id: 108,
		part_id: 157,
		quantity: 142,
	},
	{
		garage_id: 108,
		part_id: 158,
		quantity: 171,
	},
	{
		garage_id: 108,
		part_id: 159,
		quantity: 442,
	},
	{
		garage_id: 108,
		part_id: 160,
		quantity: 20,
	},
	{
		garage_id: 108,
		part_id: 161,
		quantity: 315,
	},
	{
		garage_id: 108,
		part_id: 162,
		quantity: 381,
	},
	{
		garage_id: 108,
		part_id: 163,
		quantity: 214,
	},
	{
		garage_id: 108,
		part_id: 164,
		quantity: 343,
	},
	{
		garage_id: 108,
		part_id: 165,
		quantity: 137,
	},
	{
		garage_id: 108,
		part_id: 166,
		quantity: 371,
	},
	{
		garage_id: 108,
		part_id: 167,
		quantity: 200,
	},
	{
		garage_id: 108,
		part_id: 168,
		quantity: 216,
	},
	{
		garage_id: 108,
		part_id: 169,
		quantity: 109,
	},
	{
		garage_id: 108,
		part_id: 170,
		quantity: 376,
	},
	{
		garage_id: 108,
		part_id: 171,
		quantity: 377,
	},
	{
		garage_id: 108,
		part_id: 172,
		quantity: 351,
	},
	{
		garage_id: 108,
		part_id: 173,
		quantity: 498,
	},
	{
		garage_id: 108,
		part_id: 174,
		quantity: 463,
	},
	{
		garage_id: 108,
		part_id: 175,
		quantity: 216,
	},
	{
		garage_id: 108,
		part_id: 176,
		quantity: 364,
	},
	{
		garage_id: 108,
		part_id: 177,
		quantity: 324,
	},
	{
		garage_id: 108,
		part_id: 178,
		quantity: 266,
	},
	{
		garage_id: 108,
		part_id: 179,
		quantity: 201,
	},
	{
		garage_id: 108,
		part_id: 180,
		quantity: 176,
	},
	{
		garage_id: 108,
		part_id: 181,
		quantity: 442,
	},
	{
		garage_id: 108,
		part_id: 182,
		quantity: 50,
	},
	{
		garage_id: 108,
		part_id: 183,
		quantity: 284,
	},
	{
		garage_id: 108,
		part_id: 184,
		quantity: 223,
	},
	{
		garage_id: 108,
		part_id: 185,
		quantity: 453,
	},
	{
		garage_id: 108,
		part_id: 186,
		quantity: 356,
	},
	{
		garage_id: 108,
		part_id: 187,
		quantity: 110,
	},
	{
		garage_id: 108,
		part_id: 188,
		quantity: 258,
	},
	{
		garage_id: 108,
		part_id: 189,
		quantity: 56,
	},
	{
		garage_id: 108,
		part_id: 190,
		quantity: 256,
	},
	{
		garage_id: 108,
		part_id: 191,
		quantity: 244,
	},
	{
		garage_id: 108,
		part_id: 192,
		quantity: 491,
	},
	{
		garage_id: 108,
		part_id: 193,
		quantity: 116,
	},
	{
		garage_id: 108,
		part_id: 194,
		quantity: 330,
	},
	{
		garage_id: 108,
		part_id: 195,
		quantity: 7,
	},
	{
		garage_id: 108,
		part_id: 196,
		quantity: 62,
	},
	{
		garage_id: 108,
		part_id: 197,
		quantity: 108,
	},
	{
		garage_id: 108,
		part_id: 198,
		quantity: 263,
	},
	{
		garage_id: 108,
		part_id: 199,
		quantity: 204,
	},
	{
		garage_id: 108,
		part_id: 200,
		quantity: 18,
	},
	{
		garage_id: 108,
		part_id: 201,
		quantity: 121,
	},
	{
		garage_id: 108,
		part_id: 202,
		quantity: 249,
	},
	{
		garage_id: 108,
		part_id: 203,
		quantity: 311,
	},
	{
		garage_id: 108,
		part_id: 204,
		quantity: 413,
	},
	{
		garage_id: 108,
		part_id: 205,
		quantity: 331,
	},
	{
		garage_id: 108,
		part_id: 206,
		quantity: 389,
	},
	{
		garage_id: 108,
		part_id: 207,
		quantity: 135,
	},
	{
		garage_id: 108,
		part_id: 208,
		quantity: 137,
	},
	{
		garage_id: 108,
		part_id: 209,
		quantity: 104,
	},
	{
		garage_id: 108,
		part_id: 210,
		quantity: 440,
	},
	{
		garage_id: 108,
		part_id: 211,
		quantity: 243,
	},
	{
		garage_id: 108,
		part_id: 212,
		quantity: 214,
	},
	{
		garage_id: 108,
		part_id: 213,
		quantity: 220,
	},
	{
		garage_id: 108,
		part_id: 214,
		quantity: 78,
	},
	{
		garage_id: 108,
		part_id: 215,
		quantity: 198,
	},
	{
		garage_id: 108,
		part_id: 216,
		quantity: 310,
	},
	{
		garage_id: 108,
		part_id: 217,
		quantity: 438,
	},
	{
		garage_id: 108,
		part_id: 218,
		quantity: 266,
	},
	{
		garage_id: 108,
		part_id: 219,
		quantity: 306,
	},
	{
		garage_id: 108,
		part_id: 220,
		quantity: 220,
	},
	{
		garage_id: 108,
		part_id: 221,
		quantity: 230,
	},
	{
		garage_id: 108,
		part_id: 222,
		quantity: 364,
	},
	{
		garage_id: 108,
		part_id: 223,
		quantity: 88,
	},
	{
		garage_id: 108,
		part_id: 224,
		quantity: 85,
	},
	{
		garage_id: 108,
		part_id: 225,
		quantity: 318,
	},
	{
		garage_id: 108,
		part_id: 226,
		quantity: 207,
	},
	{
		garage_id: 108,
		part_id: 227,
		quantity: 161,
	},
	{
		garage_id: 108,
		part_id: 228,
		quantity: 10,
	},
	{
		garage_id: 108,
		part_id: 229,
		quantity: 347,
	},
	{
		garage_id: 108,
		part_id: 230,
		quantity: 140,
	},
	{
		garage_id: 108,
		part_id: 231,
		quantity: 471,
	},
	{
		garage_id: 108,
		part_id: 232,
		quantity: 266,
	},
	{
		garage_id: 108,
		part_id: 233,
		quantity: 273,
	},
	{
		garage_id: 108,
		part_id: 234,
		quantity: 162,
	},
	{
		garage_id: 108,
		part_id: 235,
		quantity: 285,
	},
	{
		garage_id: 108,
		part_id: 236,
		quantity: 210,
	},
	{
		garage_id: 108,
		part_id: 237,
		quantity: 282,
	},
	{
		garage_id: 108,
		part_id: 238,
		quantity: 367,
	},
	{
		garage_id: 108,
		part_id: 239,
		quantity: 195,
	},
	{
		garage_id: 108,
		part_id: 240,
		quantity: 196,
	},
	{
		garage_id: 108,
		part_id: 241,
		quantity: 74,
	},
	{
		garage_id: 108,
		part_id: 242,
		quantity: 152,
	},
	{
		garage_id: 108,
		part_id: 243,
		quantity: 377,
	},
	{
		garage_id: 108,
		part_id: 244,
		quantity: 45,
	},
	{
		garage_id: 108,
		part_id: 245,
		quantity: 91,
	},
	{
		garage_id: 108,
		part_id: 246,
		quantity: 32,
	},
	{
		garage_id: 108,
		part_id: 247,
		quantity: 99,
	},
	{
		garage_id: 108,
		part_id: 248,
		quantity: 459,
	},
	{
		garage_id: 108,
		part_id: 249,
		quantity: 156,
	},
	{
		garage_id: 108,
		part_id: 250,
		quantity: 25,
	},
	{
		garage_id: 108,
		part_id: 251,
		quantity: 132,
	},
	{
		garage_id: 108,
		part_id: 252,
		quantity: 182,
	},
	{
		garage_id: 108,
		part_id: 253,
		quantity: 135,
	},
	{
		garage_id: 108,
		part_id: 254,
		quantity: 152,
	},
	{
		garage_id: 108,
		part_id: 255,
		quantity: 178,
	},
	{
		garage_id: 108,
		part_id: 256,
		quantity: 133,
	},
	{
		garage_id: 108,
		part_id: 257,
		quantity: 180,
	},
	{
		garage_id: 108,
		part_id: 258,
		quantity: 252,
	},
	{
		garage_id: 108,
		part_id: 259,
		quantity: 43,
	},
	{
		garage_id: 108,
		part_id: 260,
		quantity: 159,
	},
	{
		garage_id: 108,
		part_id: 261,
		quantity: 364,
	},
	{
		garage_id: 108,
		part_id: 262,
		quantity: 372,
	},
	{
		garage_id: 108,
		part_id: 263,
		quantity: 15,
	},
	{
		garage_id: 108,
		part_id: 264,
		quantity: 385,
	},
	{
		garage_id: 108,
		part_id: 265,
		quantity: 452,
	},
	{
		garage_id: 108,
		part_id: 266,
		quantity: 268,
	},
	{
		garage_id: 108,
		part_id: 267,
		quantity: 487,
	},
	{
		garage_id: 108,
		part_id: 268,
		quantity: 452,
	},
	{
		garage_id: 108,
		part_id: 269,
		quantity: 338,
	},
	{
		garage_id: 108,
		part_id: 270,
		quantity: 122,
	},
	{
		garage_id: 108,
		part_id: 271,
		quantity: 404,
	},
	{
		garage_id: 108,
		part_id: 272,
		quantity: 223,
	},
	{
		garage_id: 108,
		part_id: 273,
		quantity: 418,
	},
	{
		garage_id: 108,
		part_id: 274,
		quantity: 275,
	},
	{
		garage_id: 108,
		part_id: 275,
		quantity: 144,
	},
	{
		garage_id: 108,
		part_id: 276,
		quantity: 333,
	},
	{
		garage_id: 108,
		part_id: 277,
		quantity: 98,
	},
	{
		garage_id: 108,
		part_id: 278,
		quantity: 485,
	},
	{
		garage_id: 108,
		part_id: 279,
		quantity: 418,
	},
	{
		garage_id: 108,
		part_id: 280,
		quantity: 230,
	},
	{
		garage_id: 108,
		part_id: 281,
		quantity: 64,
	},
	{
		garage_id: 108,
		part_id: 282,
		quantity: 82,
	},
	{
		garage_id: 108,
		part_id: 283,
		quantity: 284,
	},
	{
		garage_id: 108,
		part_id: 284,
		quantity: 285,
	},
	{
		garage_id: 108,
		part_id: 285,
		quantity: 63,
	},
	{
		garage_id: 108,
		part_id: 286,
		quantity: 39,
	},
	{
		garage_id: 108,
		part_id: 287,
		quantity: 206,
	},
	{
		garage_id: 108,
		part_id: 288,
		quantity: 457,
	},
	{
		garage_id: 108,
		part_id: 289,
		quantity: 129,
	},
	{
		garage_id: 108,
		part_id: 290,
		quantity: 298,
	},
	{
		garage_id: 108,
		part_id: 291,
		quantity: 127,
	},
	{
		garage_id: 108,
		part_id: 292,
		quantity: 165,
	},
	{
		garage_id: 108,
		part_id: 293,
		quantity: 456,
	},
	{
		garage_id: 108,
		part_id: 294,
		quantity: 430,
	},
	{
		garage_id: 108,
		part_id: 295,
		quantity: 421,
	},
	{
		garage_id: 108,
		part_id: 296,
		quantity: 14,
	},
	{
		garage_id: 108,
		part_id: 297,
		quantity: 88,
	},
	{
		garage_id: 108,
		part_id: 298,
		quantity: 132,
	},
	{
		garage_id: 108,
		part_id: 299,
		quantity: 391,
	},
	{
		garage_id: 108,
		part_id: 300,
		quantity: 45,
	},
	{
		garage_id: 108,
		part_id: 301,
		quantity: 493,
	},
	{
		garage_id: 108,
		part_id: 302,
		quantity: 132,
	},
	{
		garage_id: 108,
		part_id: 303,
		quantity: 237,
	},
	{
		garage_id: 108,
		part_id: 304,
		quantity: 219,
	},
	{
		garage_id: 108,
		part_id: 305,
		quantity: 111,
	},
	{
		garage_id: 108,
		part_id: 306,
		quantity: 366,
	},
	{
		garage_id: 108,
		part_id: 307,
		quantity: 153,
	},
	{
		garage_id: 108,
		part_id: 308,
		quantity: 41,
	},
	{
		garage_id: 108,
		part_id: 309,
		quantity: 296,
	},
	{
		garage_id: 108,
		part_id: 310,
		quantity: 20,
	},
	{
		garage_id: 108,
		part_id: 311,
		quantity: 453,
	},
	{
		garage_id: 108,
		part_id: 312,
		quantity: 336,
	},
	{
		garage_id: 108,
		part_id: 313,
		quantity: 276,
	},
	{
		garage_id: 108,
		part_id: 314,
		quantity: 73,
	},
	{
		garage_id: 108,
		part_id: 315,
		quantity: 214,
	},
	{
		garage_id: 108,
		part_id: 316,
		quantity: 342,
	},
	{
		garage_id: 108,
		part_id: 317,
		quantity: 270,
	},
	{
		garage_id: 108,
		part_id: 318,
		quantity: 80,
	},
	{
		garage_id: 108,
		part_id: 319,
		quantity: 396,
	},
	{
		garage_id: 108,
		part_id: 320,
		quantity: 395,
	},
	{
		garage_id: 108,
		part_id: 321,
		quantity: 274,
	},
	{
		garage_id: 108,
		part_id: 322,
		quantity: 18,
	},
	{
		garage_id: 108,
		part_id: 323,
		quantity: 179,
	},
	{
		garage_id: 108,
		part_id: 324,
		quantity: 134,
	},
	{
		garage_id: 108,
		part_id: 325,
		quantity: 498,
	},
	{
		garage_id: 108,
		part_id: 326,
		quantity: 309,
	},
	{
		garage_id: 108,
		part_id: 327,
		quantity: 375,
	},
	{
		garage_id: 108,
		part_id: 328,
		quantity: 64,
	},
	{
		garage_id: 108,
		part_id: 329,
		quantity: 406,
	},
	{
		garage_id: 108,
		part_id: 330,
		quantity: 487,
	},
	{
		garage_id: 108,
		part_id: 331,
		quantity: 3,
	},
	{
		garage_id: 108,
		part_id: 332,
		quantity: 350,
	},
	{
		garage_id: 108,
		part_id: 333,
		quantity: 220,
	},
	{
		garage_id: 108,
		part_id: 334,
		quantity: 77,
	},
	{
		garage_id: 108,
		part_id: 335,
		quantity: 165,
	},
	{
		garage_id: 108,
		part_id: 336,
		quantity: 348,
	},
	{
		garage_id: 108,
		part_id: 337,
		quantity: 311,
	},
	{
		garage_id: 108,
		part_id: 338,
		quantity: 64,
	},
	{
		garage_id: 108,
		part_id: 339,
		quantity: 57,
	},
	{
		garage_id: 108,
		part_id: 340,
		quantity: 137,
	},
	{
		garage_id: 108,
		part_id: 341,
		quantity: 405,
	},
	{
		garage_id: 108,
		part_id: 342,
		quantity: 274,
	},
	{
		garage_id: 108,
		part_id: 343,
		quantity: 443,
	},
	{
		garage_id: 108,
		part_id: 344,
		quantity: 452,
	},
	{
		garage_id: 108,
		part_id: 345,
		quantity: 340,
	},
	{
		garage_id: 108,
		part_id: 346,
		quantity: 423,
	},
	{
		garage_id: 108,
		part_id: 347,
		quantity: 453,
	},
	{
		garage_id: 108,
		part_id: 348,
		quantity: 337,
	},
	{
		garage_id: 108,
		part_id: 349,
		quantity: 358,
	},
	{
		garage_id: 108,
		part_id: 350,
		quantity: 59,
	},
	{
		garage_id: 108,
		part_id: 351,
		quantity: 485,
	},
	{
		garage_id: 108,
		part_id: 352,
		quantity: 24,
	},
	{
		garage_id: 108,
		part_id: 353,
		quantity: 497,
	},
	{
		garage_id: 108,
		part_id: 354,
		quantity: 26,
	},
	{
		garage_id: 108,
		part_id: 355,
		quantity: 321,
	},
	{
		garage_id: 108,
		part_id: 356,
		quantity: 132,
	},
	{
		garage_id: 108,
		part_id: 357,
		quantity: 121,
	},
	{
		garage_id: 108,
		part_id: 358,
		quantity: 340,
	},
	{
		garage_id: 108,
		part_id: 359,
		quantity: 489,
	},
	{
		garage_id: 108,
		part_id: 360,
		quantity: 109,
	},
	{
		garage_id: 108,
		part_id: 361,
		quantity: 82,
	},
	{
		garage_id: 108,
		part_id: 362,
		quantity: 280,
	},
	{
		garage_id: 108,
		part_id: 363,
		quantity: 351,
	},
	{
		garage_id: 108,
		part_id: 364,
		quantity: 252,
	},
	{
		garage_id: 108,
		part_id: 365,
		quantity: 485,
	},
	{
		garage_id: 108,
		part_id: 366,
		quantity: 175,
	},
	{
		garage_id: 108,
		part_id: 367,
		quantity: 109,
	},
	{
		garage_id: 108,
		part_id: 368,
		quantity: 53,
	},
	{
		garage_id: 108,
		part_id: 369,
		quantity: 257,
	},
	{
		garage_id: 108,
		part_id: 370,
		quantity: 366,
	},
	{
		garage_id: 108,
		part_id: 371,
		quantity: 370,
	},
	{
		garage_id: 108,
		part_id: 372,
		quantity: 485,
	},
	{
		garage_id: 108,
		part_id: 373,
		quantity: 319,
	},
	{
		garage_id: 108,
		part_id: 374,
		quantity: 434,
	},
	{
		garage_id: 108,
		part_id: 375,
		quantity: 192,
	},
	{
		garage_id: 108,
		part_id: 376,
		quantity: 268,
	},
	{
		garage_id: 108,
		part_id: 377,
		quantity: 298,
	},
	{
		garage_id: 108,
		part_id: 378,
		quantity: 170,
	},
	{
		garage_id: 108,
		part_id: 379,
		quantity: 417,
	},
	{
		garage_id: 108,
		part_id: 380,
		quantity: 428,
	},
	{
		garage_id: 108,
		part_id: 381,
		quantity: 236,
	},
	{
		garage_id: 108,
		part_id: 382,
		quantity: 39,
	},
	{
		garage_id: 108,
		part_id: 383,
		quantity: 327,
	},
	{
		garage_id: 108,
		part_id: 384,
		quantity: 353,
	},
	{
		garage_id: 108,
		part_id: 385,
		quantity: 181,
	},
	{
		garage_id: 108,
		part_id: 386,
		quantity: 152,
	},
	{
		garage_id: 108,
		part_id: 387,
		quantity: 284,
	},
	{
		garage_id: 108,
		part_id: 388,
		quantity: 431,
	},
	{
		garage_id: 108,
		part_id: 389,
		quantity: 38,
	},
	{
		garage_id: 108,
		part_id: 390,
		quantity: 289,
	},
	{
		garage_id: 108,
		part_id: 391,
		quantity: 366,
	},
	{
		garage_id: 108,
		part_id: 392,
		quantity: 366,
	},
	{
		garage_id: 108,
		part_id: 393,
		quantity: 109,
	},
	{
		garage_id: 108,
		part_id: 394,
		quantity: 161,
	},
	{
		garage_id: 108,
		part_id: 395,
		quantity: 211,
	},
	{
		garage_id: 108,
		part_id: 396,
		quantity: 416,
	},
	{
		garage_id: 108,
		part_id: 397,
		quantity: 81,
	},
	{
		garage_id: 108,
		part_id: 398,
		quantity: 282,
	},
	{
		garage_id: 108,
		part_id: 399,
		quantity: 0,
	},
	{
		garage_id: 109,
		part_id: 0,
		quantity: 355,
	},
	{
		garage_id: 109,
		part_id: 1,
		quantity: 414,
	},
	{
		garage_id: 109,
		part_id: 2,
		quantity: 347,
	},
	{
		garage_id: 109,
		part_id: 3,
		quantity: 262,
	},
	{
		garage_id: 109,
		part_id: 4,
		quantity: 39,
	},
	{
		garage_id: 109,
		part_id: 5,
		quantity: 340,
	},
	{
		garage_id: 109,
		part_id: 6,
		quantity: 34,
	},
	{
		garage_id: 109,
		part_id: 7,
		quantity: 389,
	},
	{
		garage_id: 109,
		part_id: 8,
		quantity: 133,
	},
	{
		garage_id: 109,
		part_id: 9,
		quantity: 77,
	},
	{
		garage_id: 109,
		part_id: 10,
		quantity: 170,
	},
	{
		garage_id: 109,
		part_id: 11,
		quantity: 59,
	},
	{
		garage_id: 109,
		part_id: 12,
		quantity: 187,
	},
	{
		garage_id: 109,
		part_id: 13,
		quantity: 105,
	},
	{
		garage_id: 109,
		part_id: 14,
		quantity: 467,
	},
	{
		garage_id: 109,
		part_id: 15,
		quantity: 387,
	},
	{
		garage_id: 109,
		part_id: 16,
		quantity: 79,
	},
	{
		garage_id: 109,
		part_id: 17,
		quantity: 137,
	},
	{
		garage_id: 109,
		part_id: 18,
		quantity: 142,
	},
	{
		garage_id: 109,
		part_id: 19,
		quantity: 84,
	},
	{
		garage_id: 109,
		part_id: 20,
		quantity: 436,
	},
	{
		garage_id: 109,
		part_id: 21,
		quantity: 399,
	},
	{
		garage_id: 109,
		part_id: 22,
		quantity: 322,
	},
	{
		garage_id: 109,
		part_id: 23,
		quantity: 148,
	},
	{
		garage_id: 109,
		part_id: 24,
		quantity: 224,
	},
	{
		garage_id: 109,
		part_id: 25,
		quantity: 460,
	},
	{
		garage_id: 109,
		part_id: 26,
		quantity: 384,
	},
	{
		garage_id: 109,
		part_id: 27,
		quantity: 339,
	},
	{
		garage_id: 109,
		part_id: 28,
		quantity: 378,
	},
	{
		garage_id: 109,
		part_id: 29,
		quantity: 348,
	},
	{
		garage_id: 109,
		part_id: 30,
		quantity: 434,
	},
	{
		garage_id: 109,
		part_id: 31,
		quantity: 67,
	},
	{
		garage_id: 109,
		part_id: 32,
		quantity: 187,
	},
	{
		garage_id: 109,
		part_id: 33,
		quantity: 205,
	},
	{
		garage_id: 109,
		part_id: 34,
		quantity: 306,
	},
	{
		garage_id: 109,
		part_id: 35,
		quantity: 399,
	},
	{
		garage_id: 109,
		part_id: 36,
		quantity: 424,
	},
	{
		garage_id: 109,
		part_id: 37,
		quantity: 387,
	},
	{
		garage_id: 109,
		part_id: 38,
		quantity: 68,
	},
	{
		garage_id: 109,
		part_id: 39,
		quantity: 216,
	},
	{
		garage_id: 109,
		part_id: 40,
		quantity: 319,
	},
	{
		garage_id: 109,
		part_id: 41,
		quantity: 410,
	},
	{
		garage_id: 109,
		part_id: 42,
		quantity: 339,
	},
	{
		garage_id: 109,
		part_id: 43,
		quantity: 280,
	},
	{
		garage_id: 109,
		part_id: 44,
		quantity: 251,
	},
	{
		garage_id: 109,
		part_id: 45,
		quantity: 1,
	},
	{
		garage_id: 109,
		part_id: 46,
		quantity: 45,
	},
	{
		garage_id: 109,
		part_id: 47,
		quantity: 2,
	},
	{
		garage_id: 109,
		part_id: 48,
		quantity: 265,
	},
	{
		garage_id: 109,
		part_id: 49,
		quantity: 157,
	},
	{
		garage_id: 109,
		part_id: 50,
		quantity: 11,
	},
	{
		garage_id: 109,
		part_id: 51,
		quantity: 147,
	},
	{
		garage_id: 109,
		part_id: 52,
		quantity: 260,
	},
	{
		garage_id: 109,
		part_id: 53,
		quantity: 83,
	},
	{
		garage_id: 109,
		part_id: 54,
		quantity: 448,
	},
	{
		garage_id: 109,
		part_id: 55,
		quantity: 21,
	},
	{
		garage_id: 109,
		part_id: 56,
		quantity: 415,
	},
	{
		garage_id: 109,
		part_id: 57,
		quantity: 393,
	},
	{
		garage_id: 109,
		part_id: 58,
		quantity: 303,
	},
	{
		garage_id: 109,
		part_id: 59,
		quantity: 326,
	},
	{
		garage_id: 109,
		part_id: 60,
		quantity: 431,
	},
	{
		garage_id: 109,
		part_id: 61,
		quantity: 339,
	},
	{
		garage_id: 109,
		part_id: 62,
		quantity: 126,
	},
	{
		garage_id: 109,
		part_id: 63,
		quantity: 443,
	},
	{
		garage_id: 109,
		part_id: 64,
		quantity: 324,
	},
	{
		garage_id: 109,
		part_id: 65,
		quantity: 356,
	},
	{
		garage_id: 109,
		part_id: 66,
		quantity: 406,
	},
	{
		garage_id: 109,
		part_id: 67,
		quantity: 4,
	},
	{
		garage_id: 109,
		part_id: 68,
		quantity: 145,
	},
	{
		garage_id: 109,
		part_id: 69,
		quantity: 470,
	},
	{
		garage_id: 109,
		part_id: 70,
		quantity: 111,
	},
	{
		garage_id: 109,
		part_id: 71,
		quantity: 175,
	},
	{
		garage_id: 109,
		part_id: 72,
		quantity: 339,
	},
	{
		garage_id: 109,
		part_id: 73,
		quantity: 75,
	},
	{
		garage_id: 109,
		part_id: 74,
		quantity: 358,
	},
	{
		garage_id: 109,
		part_id: 75,
		quantity: 42,
	},
	{
		garage_id: 109,
		part_id: 76,
		quantity: 100,
	},
	{
		garage_id: 109,
		part_id: 77,
		quantity: 184,
	},
	{
		garage_id: 109,
		part_id: 78,
		quantity: 479,
	},
	{
		garage_id: 109,
		part_id: 79,
		quantity: 201,
	},
	{
		garage_id: 109,
		part_id: 80,
		quantity: 490,
	},
	{
		garage_id: 109,
		part_id: 81,
		quantity: 184,
	},
	{
		garage_id: 109,
		part_id: 82,
		quantity: 98,
	},
	{
		garage_id: 109,
		part_id: 83,
		quantity: 465,
	},
	{
		garage_id: 109,
		part_id: 84,
		quantity: 454,
	},
	{
		garage_id: 109,
		part_id: 85,
		quantity: 203,
	},
	{
		garage_id: 109,
		part_id: 86,
		quantity: 238,
	},
	{
		garage_id: 109,
		part_id: 87,
		quantity: 173,
	},
	{
		garage_id: 109,
		part_id: 88,
		quantity: 273,
	},
	{
		garage_id: 109,
		part_id: 89,
		quantity: 435,
	},
	{
		garage_id: 109,
		part_id: 90,
		quantity: 67,
	},
	{
		garage_id: 109,
		part_id: 91,
		quantity: 394,
	},
	{
		garage_id: 109,
		part_id: 92,
		quantity: 347,
	},
	{
		garage_id: 109,
		part_id: 93,
		quantity: 411,
	},
	{
		garage_id: 109,
		part_id: 94,
		quantity: 452,
	},
	{
		garage_id: 109,
		part_id: 95,
		quantity: 109,
	},
	{
		garage_id: 109,
		part_id: 96,
		quantity: 315,
	},
	{
		garage_id: 109,
		part_id: 97,
		quantity: 239,
	},
	{
		garage_id: 109,
		part_id: 98,
		quantity: 423,
	},
	{
		garage_id: 109,
		part_id: 99,
		quantity: 433,
	},
	{
		garage_id: 109,
		part_id: 100,
		quantity: 500,
	},
	{
		garage_id: 109,
		part_id: 101,
		quantity: 263,
	},
	{
		garage_id: 109,
		part_id: 102,
		quantity: 466,
	},
	{
		garage_id: 109,
		part_id: 103,
		quantity: 265,
	},
	{
		garage_id: 109,
		part_id: 104,
		quantity: 127,
	},
	{
		garage_id: 109,
		part_id: 105,
		quantity: 424,
	},
	{
		garage_id: 109,
		part_id: 106,
		quantity: 173,
	},
	{
		garage_id: 109,
		part_id: 107,
		quantity: 206,
	},
	{
		garage_id: 109,
		part_id: 108,
		quantity: 447,
	},
	{
		garage_id: 109,
		part_id: 109,
		quantity: 378,
	},
	{
		garage_id: 109,
		part_id: 110,
		quantity: 193,
	},
	{
		garage_id: 109,
		part_id: 111,
		quantity: 59,
	},
	{
		garage_id: 109,
		part_id: 112,
		quantity: 375,
	},
	{
		garage_id: 109,
		part_id: 113,
		quantity: 144,
	},
	{
		garage_id: 109,
		part_id: 114,
		quantity: 25,
	},
	{
		garage_id: 109,
		part_id: 115,
		quantity: 404,
	},
	{
		garage_id: 109,
		part_id: 116,
		quantity: 377,
	},
	{
		garage_id: 109,
		part_id: 117,
		quantity: 391,
	},
	{
		garage_id: 109,
		part_id: 118,
		quantity: 449,
	},
	{
		garage_id: 109,
		part_id: 119,
		quantity: 40,
	},
	{
		garage_id: 109,
		part_id: 120,
		quantity: 235,
	},
	{
		garage_id: 109,
		part_id: 121,
		quantity: 273,
	},
	{
		garage_id: 109,
		part_id: 122,
		quantity: 87,
	},
	{
		garage_id: 109,
		part_id: 123,
		quantity: 174,
	},
	{
		garage_id: 109,
		part_id: 124,
		quantity: 444,
	},
	{
		garage_id: 109,
		part_id: 125,
		quantity: 45,
	},
	{
		garage_id: 109,
		part_id: 126,
		quantity: 90,
	},
	{
		garage_id: 109,
		part_id: 127,
		quantity: 216,
	},
	{
		garage_id: 109,
		part_id: 128,
		quantity: 453,
	},
	{
		garage_id: 109,
		part_id: 129,
		quantity: 304,
	},
	{
		garage_id: 109,
		part_id: 130,
		quantity: 348,
	},
	{
		garage_id: 109,
		part_id: 131,
		quantity: 166,
	},
	{
		garage_id: 109,
		part_id: 132,
		quantity: 449,
	},
	{
		garage_id: 109,
		part_id: 133,
		quantity: 350,
	},
	{
		garage_id: 109,
		part_id: 134,
		quantity: 47,
	},
	{
		garage_id: 109,
		part_id: 135,
		quantity: 119,
	},
	{
		garage_id: 109,
		part_id: 136,
		quantity: 181,
	},
	{
		garage_id: 109,
		part_id: 137,
		quantity: 359,
	},
	{
		garage_id: 109,
		part_id: 138,
		quantity: 451,
	},
	{
		garage_id: 109,
		part_id: 139,
		quantity: 322,
	},
	{
		garage_id: 109,
		part_id: 140,
		quantity: 25,
	},
	{
		garage_id: 109,
		part_id: 141,
		quantity: 143,
	},
	{
		garage_id: 109,
		part_id: 142,
		quantity: 346,
	},
	{
		garage_id: 109,
		part_id: 143,
		quantity: 419,
	},
	{
		garage_id: 109,
		part_id: 144,
		quantity: 388,
	},
	{
		garage_id: 109,
		part_id: 145,
		quantity: 364,
	},
	{
		garage_id: 109,
		part_id: 146,
		quantity: 246,
	},
	{
		garage_id: 109,
		part_id: 147,
		quantity: 367,
	},
	{
		garage_id: 109,
		part_id: 148,
		quantity: 487,
	},
	{
		garage_id: 109,
		part_id: 149,
		quantity: 448,
	},
	{
		garage_id: 109,
		part_id: 150,
		quantity: 147,
	},
	{
		garage_id: 109,
		part_id: 151,
		quantity: 18,
	},
	{
		garage_id: 109,
		part_id: 152,
		quantity: 266,
	},
	{
		garage_id: 109,
		part_id: 153,
		quantity: 109,
	},
	{
		garage_id: 109,
		part_id: 154,
		quantity: 22,
	},
	{
		garage_id: 109,
		part_id: 155,
		quantity: 257,
	},
	{
		garage_id: 109,
		part_id: 156,
		quantity: 341,
	},
	{
		garage_id: 109,
		part_id: 157,
		quantity: 45,
	},
	{
		garage_id: 109,
		part_id: 158,
		quantity: 459,
	},
	{
		garage_id: 109,
		part_id: 159,
		quantity: 43,
	},
	{
		garage_id: 109,
		part_id: 160,
		quantity: 348,
	},
	{
		garage_id: 109,
		part_id: 161,
		quantity: 40,
	},
	{
		garage_id: 109,
		part_id: 162,
		quantity: 102,
	},
	{
		garage_id: 109,
		part_id: 163,
		quantity: 379,
	},
	{
		garage_id: 109,
		part_id: 164,
		quantity: 81,
	},
	{
		garage_id: 109,
		part_id: 165,
		quantity: 448,
	},
	{
		garage_id: 109,
		part_id: 166,
		quantity: 459,
	},
	{
		garage_id: 109,
		part_id: 167,
		quantity: 147,
	},
	{
		garage_id: 109,
		part_id: 168,
		quantity: 370,
	},
	{
		garage_id: 109,
		part_id: 169,
		quantity: 393,
	},
	{
		garage_id: 109,
		part_id: 170,
		quantity: 454,
	},
	{
		garage_id: 109,
		part_id: 171,
		quantity: 63,
	},
	{
		garage_id: 109,
		part_id: 172,
		quantity: 328,
	},
	{
		garage_id: 109,
		part_id: 173,
		quantity: 53,
	},
	{
		garage_id: 109,
		part_id: 174,
		quantity: 312,
	},
	{
		garage_id: 109,
		part_id: 175,
		quantity: 475,
	},
	{
		garage_id: 109,
		part_id: 176,
		quantity: 148,
	},
	{
		garage_id: 109,
		part_id: 177,
		quantity: 291,
	},
	{
		garage_id: 109,
		part_id: 178,
		quantity: 137,
	},
	{
		garage_id: 109,
		part_id: 179,
		quantity: 405,
	},
	{
		garage_id: 109,
		part_id: 180,
		quantity: 435,
	},
	{
		garage_id: 109,
		part_id: 181,
		quantity: 310,
	},
	{
		garage_id: 109,
		part_id: 182,
		quantity: 91,
	},
	{
		garage_id: 109,
		part_id: 183,
		quantity: 347,
	},
	{
		garage_id: 109,
		part_id: 184,
		quantity: 60,
	},
	{
		garage_id: 109,
		part_id: 185,
		quantity: 226,
	},
	{
		garage_id: 109,
		part_id: 186,
		quantity: 397,
	},
	{
		garage_id: 109,
		part_id: 187,
		quantity: 170,
	},
	{
		garage_id: 109,
		part_id: 188,
		quantity: 174,
	},
	{
		garage_id: 109,
		part_id: 189,
		quantity: 293,
	},
	{
		garage_id: 109,
		part_id: 190,
		quantity: 346,
	},
	{
		garage_id: 109,
		part_id: 191,
		quantity: 325,
	},
	{
		garage_id: 109,
		part_id: 192,
		quantity: 154,
	},
	{
		garage_id: 109,
		part_id: 193,
		quantity: 67,
	},
	{
		garage_id: 109,
		part_id: 194,
		quantity: 12,
	},
	{
		garage_id: 109,
		part_id: 195,
		quantity: 330,
	},
	{
		garage_id: 109,
		part_id: 196,
		quantity: 174,
	},
	{
		garage_id: 109,
		part_id: 197,
		quantity: 384,
	},
	{
		garage_id: 109,
		part_id: 198,
		quantity: 37,
	},
	{
		garage_id: 109,
		part_id: 199,
		quantity: 323,
	},
	{
		garage_id: 109,
		part_id: 200,
		quantity: 356,
	},
	{
		garage_id: 109,
		part_id: 201,
		quantity: 52,
	},
	{
		garage_id: 109,
		part_id: 202,
		quantity: 497,
	},
	{
		garage_id: 109,
		part_id: 203,
		quantity: 217,
	},
	{
		garage_id: 109,
		part_id: 204,
		quantity: 391,
	},
	{
		garage_id: 109,
		part_id: 205,
		quantity: 497,
	},
	{
		garage_id: 109,
		part_id: 206,
		quantity: 277,
	},
	{
		garage_id: 109,
		part_id: 207,
		quantity: 150,
	},
	{
		garage_id: 109,
		part_id: 208,
		quantity: 33,
	},
	{
		garage_id: 109,
		part_id: 209,
		quantity: 209,
	},
	{
		garage_id: 109,
		part_id: 210,
		quantity: 84,
	},
	{
		garage_id: 109,
		part_id: 211,
		quantity: 20,
	},
	{
		garage_id: 109,
		part_id: 212,
		quantity: 458,
	},
	{
		garage_id: 109,
		part_id: 213,
		quantity: 220,
	},
	{
		garage_id: 109,
		part_id: 214,
		quantity: 110,
	},
	{
		garage_id: 109,
		part_id: 215,
		quantity: 448,
	},
	{
		garage_id: 109,
		part_id: 216,
		quantity: 496,
	},
	{
		garage_id: 109,
		part_id: 217,
		quantity: 364,
	},
	{
		garage_id: 109,
		part_id: 218,
		quantity: 476,
	},
	{
		garage_id: 109,
		part_id: 219,
		quantity: 180,
	},
	{
		garage_id: 109,
		part_id: 220,
		quantity: 192,
	},
	{
		garage_id: 109,
		part_id: 221,
		quantity: 393,
	},
	{
		garage_id: 109,
		part_id: 222,
		quantity: 459,
	},
	{
		garage_id: 109,
		part_id: 223,
		quantity: 48,
	},
	{
		garage_id: 109,
		part_id: 224,
		quantity: 454,
	},
	{
		garage_id: 109,
		part_id: 225,
		quantity: 358,
	},
	{
		garage_id: 109,
		part_id: 226,
		quantity: 260,
	},
	{
		garage_id: 109,
		part_id: 227,
		quantity: 451,
	},
	{
		garage_id: 109,
		part_id: 228,
		quantity: 220,
	},
	{
		garage_id: 109,
		part_id: 229,
		quantity: 46,
	},
	{
		garage_id: 109,
		part_id: 230,
		quantity: 61,
	},
	{
		garage_id: 109,
		part_id: 231,
		quantity: 330,
	},
	{
		garage_id: 109,
		part_id: 232,
		quantity: 271,
	},
	{
		garage_id: 109,
		part_id: 233,
		quantity: 450,
	},
	{
		garage_id: 109,
		part_id: 234,
		quantity: 431,
	},
	{
		garage_id: 109,
		part_id: 235,
		quantity: 291,
	},
	{
		garage_id: 109,
		part_id: 236,
		quantity: 43,
	},
	{
		garage_id: 109,
		part_id: 237,
		quantity: 228,
	},
	{
		garage_id: 109,
		part_id: 238,
		quantity: 311,
	},
	{
		garage_id: 109,
		part_id: 239,
		quantity: 145,
	},
	{
		garage_id: 109,
		part_id: 240,
		quantity: 78,
	},
	{
		garage_id: 109,
		part_id: 241,
		quantity: 410,
	},
	{
		garage_id: 109,
		part_id: 242,
		quantity: 55,
	},
	{
		garage_id: 109,
		part_id: 243,
		quantity: 1,
	},
	{
		garage_id: 109,
		part_id: 244,
		quantity: 478,
	},
	{
		garage_id: 109,
		part_id: 245,
		quantity: 298,
	},
	{
		garage_id: 109,
		part_id: 246,
		quantity: 420,
	},
	{
		garage_id: 109,
		part_id: 247,
		quantity: 447,
	},
	{
		garage_id: 109,
		part_id: 248,
		quantity: 55,
	},
	{
		garage_id: 109,
		part_id: 249,
		quantity: 184,
	},
	{
		garage_id: 109,
		part_id: 250,
		quantity: 424,
	},
	{
		garage_id: 109,
		part_id: 251,
		quantity: 363,
	},
	{
		garage_id: 109,
		part_id: 252,
		quantity: 384,
	},
	{
		garage_id: 109,
		part_id: 253,
		quantity: 347,
	},
	{
		garage_id: 109,
		part_id: 254,
		quantity: 221,
	},
	{
		garage_id: 109,
		part_id: 255,
		quantity: 153,
	},
	{
		garage_id: 109,
		part_id: 256,
		quantity: 460,
	},
	{
		garage_id: 109,
		part_id: 257,
		quantity: 430,
	},
	{
		garage_id: 109,
		part_id: 258,
		quantity: 142,
	},
	{
		garage_id: 109,
		part_id: 259,
		quantity: 21,
	},
	{
		garage_id: 109,
		part_id: 260,
		quantity: 394,
	},
	{
		garage_id: 109,
		part_id: 261,
		quantity: 407,
	},
	{
		garage_id: 109,
		part_id: 262,
		quantity: 497,
	},
	{
		garage_id: 109,
		part_id: 263,
		quantity: 92,
	},
	{
		garage_id: 109,
		part_id: 264,
		quantity: 45,
	},
	{
		garage_id: 109,
		part_id: 265,
		quantity: 465,
	},
	{
		garage_id: 109,
		part_id: 266,
		quantity: 356,
	},
	{
		garage_id: 109,
		part_id: 267,
		quantity: 312,
	},
	{
		garage_id: 109,
		part_id: 268,
		quantity: 413,
	},
	{
		garage_id: 109,
		part_id: 269,
		quantity: 141,
	},
	{
		garage_id: 109,
		part_id: 270,
		quantity: 192,
	},
	{
		garage_id: 109,
		part_id: 271,
		quantity: 246,
	},
	{
		garage_id: 109,
		part_id: 272,
		quantity: 358,
	},
	{
		garage_id: 109,
		part_id: 273,
		quantity: 339,
	},
	{
		garage_id: 109,
		part_id: 274,
		quantity: 196,
	},
	{
		garage_id: 109,
		part_id: 275,
		quantity: 319,
	},
	{
		garage_id: 109,
		part_id: 276,
		quantity: 267,
	},
	{
		garage_id: 109,
		part_id: 277,
		quantity: 201,
	},
	{
		garage_id: 109,
		part_id: 278,
		quantity: 57,
	},
	{
		garage_id: 109,
		part_id: 279,
		quantity: 63,
	},
	{
		garage_id: 109,
		part_id: 280,
		quantity: 361,
	},
	{
		garage_id: 109,
		part_id: 281,
		quantity: 175,
	},
	{
		garage_id: 109,
		part_id: 282,
		quantity: 30,
	},
	{
		garage_id: 109,
		part_id: 283,
		quantity: 137,
	},
	{
		garage_id: 109,
		part_id: 284,
		quantity: 403,
	},
	{
		garage_id: 109,
		part_id: 285,
		quantity: 1,
	},
	{
		garage_id: 109,
		part_id: 286,
		quantity: 256,
	},
	{
		garage_id: 109,
		part_id: 287,
		quantity: 2,
	},
	{
		garage_id: 109,
		part_id: 288,
		quantity: 134,
	},
	{
		garage_id: 109,
		part_id: 289,
		quantity: 39,
	},
	{
		garage_id: 109,
		part_id: 290,
		quantity: 103,
	},
	{
		garage_id: 109,
		part_id: 291,
		quantity: 225,
	},
	{
		garage_id: 109,
		part_id: 292,
		quantity: 184,
	},
	{
		garage_id: 109,
		part_id: 293,
		quantity: 449,
	},
	{
		garage_id: 109,
		part_id: 294,
		quantity: 370,
	},
	{
		garage_id: 109,
		part_id: 295,
		quantity: 143,
	},
	{
		garage_id: 109,
		part_id: 296,
		quantity: 365,
	},
	{
		garage_id: 109,
		part_id: 297,
		quantity: 390,
	},
	{
		garage_id: 109,
		part_id: 298,
		quantity: 133,
	},
	{
		garage_id: 109,
		part_id: 299,
		quantity: 358,
	},
	{
		garage_id: 109,
		part_id: 300,
		quantity: 95,
	},
	{
		garage_id: 109,
		part_id: 301,
		quantity: 472,
	},
	{
		garage_id: 109,
		part_id: 302,
		quantity: 117,
	},
	{
		garage_id: 109,
		part_id: 303,
		quantity: 95,
	},
	{
		garage_id: 109,
		part_id: 304,
		quantity: 36,
	},
	{
		garage_id: 109,
		part_id: 305,
		quantity: 327,
	},
	{
		garage_id: 109,
		part_id: 306,
		quantity: 430,
	},
	{
		garage_id: 109,
		part_id: 307,
		quantity: 428,
	},
	{
		garage_id: 109,
		part_id: 308,
		quantity: 49,
	},
	{
		garage_id: 109,
		part_id: 309,
		quantity: 328,
	},
	{
		garage_id: 109,
		part_id: 310,
		quantity: 480,
	},
	{
		garage_id: 109,
		part_id: 311,
		quantity: 131,
	},
	{
		garage_id: 109,
		part_id: 312,
		quantity: 353,
	},
	{
		garage_id: 109,
		part_id: 313,
		quantity: 97,
	},
	{
		garage_id: 109,
		part_id: 314,
		quantity: 87,
	},
	{
		garage_id: 109,
		part_id: 315,
		quantity: 145,
	},
	{
		garage_id: 109,
		part_id: 316,
		quantity: 109,
	},
	{
		garage_id: 109,
		part_id: 317,
		quantity: 416,
	},
	{
		garage_id: 109,
		part_id: 318,
		quantity: 8,
	},
	{
		garage_id: 109,
		part_id: 319,
		quantity: 497,
	},
	{
		garage_id: 109,
		part_id: 320,
		quantity: 244,
	},
	{
		garage_id: 109,
		part_id: 321,
		quantity: 103,
	},
	{
		garage_id: 109,
		part_id: 322,
		quantity: 465,
	},
	{
		garage_id: 109,
		part_id: 323,
		quantity: 165,
	},
	{
		garage_id: 109,
		part_id: 324,
		quantity: 67,
	},
	{
		garage_id: 109,
		part_id: 325,
		quantity: 450,
	},
	{
		garage_id: 109,
		part_id: 326,
		quantity: 283,
	},
	{
		garage_id: 109,
		part_id: 327,
		quantity: 142,
	},
	{
		garage_id: 109,
		part_id: 328,
		quantity: 33,
	},
	{
		garage_id: 109,
		part_id: 329,
		quantity: 128,
	},
	{
		garage_id: 109,
		part_id: 330,
		quantity: 387,
	},
	{
		garage_id: 109,
		part_id: 331,
		quantity: 33,
	},
	{
		garage_id: 109,
		part_id: 332,
		quantity: 429,
	},
	{
		garage_id: 109,
		part_id: 333,
		quantity: 177,
	},
	{
		garage_id: 109,
		part_id: 334,
		quantity: 101,
	},
	{
		garage_id: 109,
		part_id: 335,
		quantity: 11,
	},
	{
		garage_id: 109,
		part_id: 336,
		quantity: 340,
	},
	{
		garage_id: 109,
		part_id: 337,
		quantity: 245,
	},
	{
		garage_id: 109,
		part_id: 338,
		quantity: 348,
	},
	{
		garage_id: 109,
		part_id: 339,
		quantity: 462,
	},
	{
		garage_id: 109,
		part_id: 340,
		quantity: 388,
	},
	{
		garage_id: 109,
		part_id: 341,
		quantity: 427,
	},
	{
		garage_id: 109,
		part_id: 342,
		quantity: 383,
	},
	{
		garage_id: 109,
		part_id: 343,
		quantity: 279,
	},
	{
		garage_id: 109,
		part_id: 344,
		quantity: 455,
	},
	{
		garage_id: 109,
		part_id: 345,
		quantity: 383,
	},
	{
		garage_id: 109,
		part_id: 346,
		quantity: 101,
	},
	{
		garage_id: 109,
		part_id: 347,
		quantity: 28,
	},
	{
		garage_id: 109,
		part_id: 348,
		quantity: 425,
	},
	{
		garage_id: 109,
		part_id: 349,
		quantity: 11,
	},
	{
		garage_id: 109,
		part_id: 350,
		quantity: 113,
	},
	{
		garage_id: 109,
		part_id: 351,
		quantity: 51,
	},
	{
		garage_id: 109,
		part_id: 352,
		quantity: 424,
	},
	{
		garage_id: 109,
		part_id: 353,
		quantity: 419,
	},
	{
		garage_id: 109,
		part_id: 354,
		quantity: 191,
	},
	{
		garage_id: 109,
		part_id: 355,
		quantity: 243,
	},
	{
		garage_id: 109,
		part_id: 356,
		quantity: 303,
	},
	{
		garage_id: 109,
		part_id: 357,
		quantity: 387,
	},
	{
		garage_id: 109,
		part_id: 358,
		quantity: 480,
	},
	{
		garage_id: 109,
		part_id: 359,
		quantity: 44,
	},
	{
		garage_id: 109,
		part_id: 360,
		quantity: 417,
	},
	{
		garage_id: 109,
		part_id: 361,
		quantity: 400,
	},
	{
		garage_id: 109,
		part_id: 362,
		quantity: 3,
	},
	{
		garage_id: 109,
		part_id: 363,
		quantity: 408,
	},
	{
		garage_id: 109,
		part_id: 364,
		quantity: 106,
	},
	{
		garage_id: 109,
		part_id: 365,
		quantity: 265,
	},
	{
		garage_id: 109,
		part_id: 366,
		quantity: 213,
	},
	{
		garage_id: 109,
		part_id: 367,
		quantity: 36,
	},
	{
		garage_id: 109,
		part_id: 368,
		quantity: 359,
	},
	{
		garage_id: 109,
		part_id: 369,
		quantity: 287,
	},
	{
		garage_id: 109,
		part_id: 370,
		quantity: 89,
	},
	{
		garage_id: 109,
		part_id: 371,
		quantity: 138,
	},
	{
		garage_id: 109,
		part_id: 372,
		quantity: 384,
	},
	{
		garage_id: 109,
		part_id: 373,
		quantity: 411,
	},
	{
		garage_id: 109,
		part_id: 374,
		quantity: 112,
	},
	{
		garage_id: 109,
		part_id: 375,
		quantity: 204,
	},
	{
		garage_id: 109,
		part_id: 376,
		quantity: 222,
	},
	{
		garage_id: 109,
		part_id: 377,
		quantity: 60,
	},
	{
		garage_id: 109,
		part_id: 378,
		quantity: 437,
	},
	{
		garage_id: 109,
		part_id: 379,
		quantity: 154,
	},
	{
		garage_id: 109,
		part_id: 380,
		quantity: 329,
	},
	{
		garage_id: 109,
		part_id: 381,
		quantity: 61,
	},
	{
		garage_id: 109,
		part_id: 382,
		quantity: 428,
	},
	{
		garage_id: 109,
		part_id: 383,
		quantity: 97,
	},
	{
		garage_id: 109,
		part_id: 384,
		quantity: 11,
	},
	{
		garage_id: 109,
		part_id: 385,
		quantity: 476,
	},
	{
		garage_id: 109,
		part_id: 386,
		quantity: 432,
	},
	{
		garage_id: 109,
		part_id: 387,
		quantity: 374,
	},
	{
		garage_id: 109,
		part_id: 388,
		quantity: 177,
	},
	{
		garage_id: 109,
		part_id: 389,
		quantity: 216,
	},
	{
		garage_id: 109,
		part_id: 390,
		quantity: 32,
	},
	{
		garage_id: 109,
		part_id: 391,
		quantity: 446,
	},
	{
		garage_id: 109,
		part_id: 392,
		quantity: 49,
	},
	{
		garage_id: 109,
		part_id: 393,
		quantity: 231,
	},
	{
		garage_id: 109,
		part_id: 394,
		quantity: 353,
	},
	{
		garage_id: 109,
		part_id: 395,
		quantity: 70,
	},
	{
		garage_id: 109,
		part_id: 396,
		quantity: 393,
	},
	{
		garage_id: 109,
		part_id: 397,
		quantity: 120,
	},
	{
		garage_id: 109,
		part_id: 398,
		quantity: 317,
	},
	{
		garage_id: 109,
		part_id: 399,
		quantity: 46,
	},
	{
		garage_id: 110,
		part_id: 0,
		quantity: 140,
	},
	{
		garage_id: 110,
		part_id: 1,
		quantity: 197,
	},
	{
		garage_id: 110,
		part_id: 2,
		quantity: 448,
	},
	{
		garage_id: 110,
		part_id: 3,
		quantity: 283,
	},
	{
		garage_id: 110,
		part_id: 4,
		quantity: 333,
	},
	{
		garage_id: 110,
		part_id: 5,
		quantity: 357,
	},
	{
		garage_id: 110,
		part_id: 6,
		quantity: 25,
	},
	{
		garage_id: 110,
		part_id: 7,
		quantity: 421,
	},
	{
		garage_id: 110,
		part_id: 8,
		quantity: 498,
	},
	{
		garage_id: 110,
		part_id: 9,
		quantity: 439,
	},
	{
		garage_id: 110,
		part_id: 10,
		quantity: 51,
	},
	{
		garage_id: 110,
		part_id: 11,
		quantity: 405,
	},
	{
		garage_id: 110,
		part_id: 12,
		quantity: 191,
	},
	{
		garage_id: 110,
		part_id: 13,
		quantity: 450,
	},
	{
		garage_id: 110,
		part_id: 14,
		quantity: 483,
	},
	{
		garage_id: 110,
		part_id: 15,
		quantity: 337,
	},
	{
		garage_id: 110,
		part_id: 16,
		quantity: 254,
	},
	{
		garage_id: 110,
		part_id: 17,
		quantity: 133,
	},
	{
		garage_id: 110,
		part_id: 18,
		quantity: 291,
	},
	{
		garage_id: 110,
		part_id: 19,
		quantity: 351,
	},
	{
		garage_id: 110,
		part_id: 20,
		quantity: 415,
	},
	{
		garage_id: 110,
		part_id: 21,
		quantity: 130,
	},
	{
		garage_id: 110,
		part_id: 22,
		quantity: 58,
	},
	{
		garage_id: 110,
		part_id: 23,
		quantity: 238,
	},
	{
		garage_id: 110,
		part_id: 24,
		quantity: 474,
	},
	{
		garage_id: 110,
		part_id: 25,
		quantity: 216,
	},
	{
		garage_id: 110,
		part_id: 26,
		quantity: 295,
	},
	{
		garage_id: 110,
		part_id: 27,
		quantity: 35,
	},
	{
		garage_id: 110,
		part_id: 28,
		quantity: 391,
	},
	{
		garage_id: 110,
		part_id: 29,
		quantity: 418,
	},
	{
		garage_id: 110,
		part_id: 30,
		quantity: 46,
	},
	{
		garage_id: 110,
		part_id: 31,
		quantity: 211,
	},
	{
		garage_id: 110,
		part_id: 32,
		quantity: 187,
	},
	{
		garage_id: 110,
		part_id: 33,
		quantity: 428,
	},
	{
		garage_id: 110,
		part_id: 34,
		quantity: 144,
	},
	{
		garage_id: 110,
		part_id: 35,
		quantity: 2,
	},
	{
		garage_id: 110,
		part_id: 36,
		quantity: 230,
	},
	{
		garage_id: 110,
		part_id: 37,
		quantity: 225,
	},
	{
		garage_id: 110,
		part_id: 38,
		quantity: 194,
	},
	{
		garage_id: 110,
		part_id: 39,
		quantity: 34,
	},
	{
		garage_id: 110,
		part_id: 40,
		quantity: 175,
	},
	{
		garage_id: 110,
		part_id: 41,
		quantity: 159,
	},
	{
		garage_id: 110,
		part_id: 42,
		quantity: 130,
	},
	{
		garage_id: 110,
		part_id: 43,
		quantity: 307,
	},
	{
		garage_id: 110,
		part_id: 44,
		quantity: 483,
	},
	{
		garage_id: 110,
		part_id: 45,
		quantity: 107,
	},
	{
		garage_id: 110,
		part_id: 46,
		quantity: 117,
	},
	{
		garage_id: 110,
		part_id: 47,
		quantity: 433,
	},
	{
		garage_id: 110,
		part_id: 48,
		quantity: 6,
	},
	{
		garage_id: 110,
		part_id: 49,
		quantity: 98,
	},
	{
		garage_id: 110,
		part_id: 50,
		quantity: 296,
	},
	{
		garage_id: 110,
		part_id: 51,
		quantity: 86,
	},
	{
		garage_id: 110,
		part_id: 52,
		quantity: 100,
	},
	{
		garage_id: 110,
		part_id: 53,
		quantity: 68,
	},
	{
		garage_id: 110,
		part_id: 54,
		quantity: 348,
	},
	{
		garage_id: 110,
		part_id: 55,
		quantity: 481,
	},
	{
		garage_id: 110,
		part_id: 56,
		quantity: 389,
	},
	{
		garage_id: 110,
		part_id: 57,
		quantity: 46,
	},
	{
		garage_id: 110,
		part_id: 58,
		quantity: 224,
	},
	{
		garage_id: 110,
		part_id: 59,
		quantity: 189,
	},
	{
		garage_id: 110,
		part_id: 60,
		quantity: 390,
	},
	{
		garage_id: 110,
		part_id: 61,
		quantity: 59,
	},
	{
		garage_id: 110,
		part_id: 62,
		quantity: 397,
	},
	{
		garage_id: 110,
		part_id: 63,
		quantity: 391,
	},
	{
		garage_id: 110,
		part_id: 64,
		quantity: 244,
	},
	{
		garage_id: 110,
		part_id: 65,
		quantity: 178,
	},
	{
		garage_id: 110,
		part_id: 66,
		quantity: 91,
	},
	{
		garage_id: 110,
		part_id: 67,
		quantity: 144,
	},
	{
		garage_id: 110,
		part_id: 68,
		quantity: 417,
	},
	{
		garage_id: 110,
		part_id: 69,
		quantity: 428,
	},
	{
		garage_id: 110,
		part_id: 70,
		quantity: 18,
	},
	{
		garage_id: 110,
		part_id: 71,
		quantity: 235,
	},
	{
		garage_id: 110,
		part_id: 72,
		quantity: 24,
	},
	{
		garage_id: 110,
		part_id: 73,
		quantity: 34,
	},
	{
		garage_id: 110,
		part_id: 74,
		quantity: 327,
	},
	{
		garage_id: 110,
		part_id: 75,
		quantity: 284,
	},
	{
		garage_id: 110,
		part_id: 76,
		quantity: 75,
	},
	{
		garage_id: 110,
		part_id: 77,
		quantity: 233,
	},
	{
		garage_id: 110,
		part_id: 78,
		quantity: 221,
	},
	{
		garage_id: 110,
		part_id: 79,
		quantity: 4,
	},
	{
		garage_id: 110,
		part_id: 80,
		quantity: 397,
	},
	{
		garage_id: 110,
		part_id: 81,
		quantity: 296,
	},
	{
		garage_id: 110,
		part_id: 82,
		quantity: 328,
	},
	{
		garage_id: 110,
		part_id: 83,
		quantity: 180,
	},
	{
		garage_id: 110,
		part_id: 84,
		quantity: 389,
	},
	{
		garage_id: 110,
		part_id: 85,
		quantity: 135,
	},
	{
		garage_id: 110,
		part_id: 86,
		quantity: 305,
	},
	{
		garage_id: 110,
		part_id: 87,
		quantity: 5,
	},
	{
		garage_id: 110,
		part_id: 88,
		quantity: 25,
	},
	{
		garage_id: 110,
		part_id: 89,
		quantity: 218,
	},
	{
		garage_id: 110,
		part_id: 90,
		quantity: 178,
	},
	{
		garage_id: 110,
		part_id: 91,
		quantity: 353,
	},
	{
		garage_id: 110,
		part_id: 92,
		quantity: 231,
	},
	{
		garage_id: 110,
		part_id: 93,
		quantity: 302,
	},
	{
		garage_id: 110,
		part_id: 94,
		quantity: 56,
	},
	{
		garage_id: 110,
		part_id: 95,
		quantity: 406,
	},
	{
		garage_id: 110,
		part_id: 96,
		quantity: 83,
	},
	{
		garage_id: 110,
		part_id: 97,
		quantity: 453,
	},
	{
		garage_id: 110,
		part_id: 98,
		quantity: 382,
	},
	{
		garage_id: 110,
		part_id: 99,
		quantity: 77,
	},
	{
		garage_id: 110,
		part_id: 100,
		quantity: 452,
	},
	{
		garage_id: 110,
		part_id: 101,
		quantity: 187,
	},
	{
		garage_id: 110,
		part_id: 102,
		quantity: 10,
	},
	{
		garage_id: 110,
		part_id: 103,
		quantity: 455,
	},
	{
		garage_id: 110,
		part_id: 104,
		quantity: 349,
	},
	{
		garage_id: 110,
		part_id: 105,
		quantity: 156,
	},
	{
		garage_id: 110,
		part_id: 106,
		quantity: 391,
	},
	{
		garage_id: 110,
		part_id: 107,
		quantity: 469,
	},
	{
		garage_id: 110,
		part_id: 108,
		quantity: 392,
	},
	{
		garage_id: 110,
		part_id: 109,
		quantity: 476,
	},
	{
		garage_id: 110,
		part_id: 110,
		quantity: 387,
	},
	{
		garage_id: 110,
		part_id: 111,
		quantity: 139,
	},
	{
		garage_id: 110,
		part_id: 112,
		quantity: 463,
	},
	{
		garage_id: 110,
		part_id: 113,
		quantity: 206,
	},
	{
		garage_id: 110,
		part_id: 114,
		quantity: 11,
	},
	{
		garage_id: 110,
		part_id: 115,
		quantity: 170,
	},
	{
		garage_id: 110,
		part_id: 116,
		quantity: 143,
	},
	{
		garage_id: 110,
		part_id: 117,
		quantity: 152,
	},
	{
		garage_id: 110,
		part_id: 118,
		quantity: 70,
	},
	{
		garage_id: 110,
		part_id: 119,
		quantity: 296,
	},
	{
		garage_id: 110,
		part_id: 120,
		quantity: 222,
	},
	{
		garage_id: 110,
		part_id: 121,
		quantity: 441,
	},
	{
		garage_id: 110,
		part_id: 122,
		quantity: 82,
	},
	{
		garage_id: 110,
		part_id: 123,
		quantity: 187,
	},
	{
		garage_id: 110,
		part_id: 124,
		quantity: 270,
	},
	{
		garage_id: 110,
		part_id: 125,
		quantity: 30,
	},
	{
		garage_id: 110,
		part_id: 126,
		quantity: 340,
	},
	{
		garage_id: 110,
		part_id: 127,
		quantity: 278,
	},
	{
		garage_id: 110,
		part_id: 128,
		quantity: 459,
	},
	{
		garage_id: 110,
		part_id: 129,
		quantity: 439,
	},
	{
		garage_id: 110,
		part_id: 130,
		quantity: 168,
	},
	{
		garage_id: 110,
		part_id: 131,
		quantity: 253,
	},
	{
		garage_id: 110,
		part_id: 132,
		quantity: 224,
	},
	{
		garage_id: 110,
		part_id: 133,
		quantity: 258,
	},
	{
		garage_id: 110,
		part_id: 134,
		quantity: 91,
	},
	{
		garage_id: 110,
		part_id: 135,
		quantity: 213,
	},
	{
		garage_id: 110,
		part_id: 136,
		quantity: 259,
	},
	{
		garage_id: 110,
		part_id: 137,
		quantity: 291,
	},
	{
		garage_id: 110,
		part_id: 138,
		quantity: 401,
	},
	{
		garage_id: 110,
		part_id: 139,
		quantity: 248,
	},
	{
		garage_id: 110,
		part_id: 140,
		quantity: 66,
	},
	{
		garage_id: 110,
		part_id: 141,
		quantity: 49,
	},
	{
		garage_id: 110,
		part_id: 142,
		quantity: 413,
	},
	{
		garage_id: 110,
		part_id: 143,
		quantity: 83,
	},
	{
		garage_id: 110,
		part_id: 144,
		quantity: 291,
	},
	{
		garage_id: 110,
		part_id: 145,
		quantity: 181,
	},
	{
		garage_id: 110,
		part_id: 146,
		quantity: 276,
	},
	{
		garage_id: 110,
		part_id: 147,
		quantity: 420,
	},
	{
		garage_id: 110,
		part_id: 148,
		quantity: 465,
	},
	{
		garage_id: 110,
		part_id: 149,
		quantity: 328,
	},
	{
		garage_id: 110,
		part_id: 150,
		quantity: 479,
	},
	{
		garage_id: 110,
		part_id: 151,
		quantity: 8,
	},
	{
		garage_id: 110,
		part_id: 152,
		quantity: 74,
	},
	{
		garage_id: 110,
		part_id: 153,
		quantity: 56,
	},
	{
		garage_id: 110,
		part_id: 154,
		quantity: 112,
	},
	{
		garage_id: 110,
		part_id: 155,
		quantity: 313,
	},
	{
		garage_id: 110,
		part_id: 156,
		quantity: 173,
	},
	{
		garage_id: 110,
		part_id: 157,
		quantity: 289,
	},
	{
		garage_id: 110,
		part_id: 158,
		quantity: 468,
	},
	{
		garage_id: 110,
		part_id: 159,
		quantity: 442,
	},
	{
		garage_id: 110,
		part_id: 160,
		quantity: 426,
	},
	{
		garage_id: 110,
		part_id: 161,
		quantity: 438,
	},
	{
		garage_id: 110,
		part_id: 162,
		quantity: 304,
	},
	{
		garage_id: 110,
		part_id: 163,
		quantity: 25,
	},
	{
		garage_id: 110,
		part_id: 164,
		quantity: 29,
	},
	{
		garage_id: 110,
		part_id: 165,
		quantity: 152,
	},
	{
		garage_id: 110,
		part_id: 166,
		quantity: 417,
	},
	{
		garage_id: 110,
		part_id: 167,
		quantity: 270,
	},
	{
		garage_id: 110,
		part_id: 168,
		quantity: 412,
	},
	{
		garage_id: 110,
		part_id: 169,
		quantity: 413,
	},
	{
		garage_id: 110,
		part_id: 170,
		quantity: 230,
	},
	{
		garage_id: 110,
		part_id: 171,
		quantity: 350,
	},
	{
		garage_id: 110,
		part_id: 172,
		quantity: 195,
	},
	{
		garage_id: 110,
		part_id: 173,
		quantity: 174,
	},
	{
		garage_id: 110,
		part_id: 174,
		quantity: 73,
	},
	{
		garage_id: 110,
		part_id: 175,
		quantity: 3,
	},
	{
		garage_id: 110,
		part_id: 176,
		quantity: 466,
	},
	{
		garage_id: 110,
		part_id: 177,
		quantity: 100,
	},
	{
		garage_id: 110,
		part_id: 178,
		quantity: 151,
	},
	{
		garage_id: 110,
		part_id: 179,
		quantity: 372,
	},
	{
		garage_id: 110,
		part_id: 180,
		quantity: 468,
	},
	{
		garage_id: 110,
		part_id: 181,
		quantity: 373,
	},
	{
		garage_id: 110,
		part_id: 182,
		quantity: 229,
	},
	{
		garage_id: 110,
		part_id: 183,
		quantity: 372,
	},
	{
		garage_id: 110,
		part_id: 184,
		quantity: 184,
	},
	{
		garage_id: 110,
		part_id: 185,
		quantity: 161,
	},
	{
		garage_id: 110,
		part_id: 186,
		quantity: 366,
	},
	{
		garage_id: 110,
		part_id: 187,
		quantity: 35,
	},
	{
		garage_id: 110,
		part_id: 188,
		quantity: 341,
	},
	{
		garage_id: 110,
		part_id: 189,
		quantity: 353,
	},
	{
		garage_id: 110,
		part_id: 190,
		quantity: 497,
	},
	{
		garage_id: 110,
		part_id: 191,
		quantity: 248,
	},
	{
		garage_id: 110,
		part_id: 192,
		quantity: 329,
	},
	{
		garage_id: 110,
		part_id: 193,
		quantity: 90,
	},
	{
		garage_id: 110,
		part_id: 194,
		quantity: 422,
	},
	{
		garage_id: 110,
		part_id: 195,
		quantity: 118,
	},
	{
		garage_id: 110,
		part_id: 196,
		quantity: 21,
	},
	{
		garage_id: 110,
		part_id: 197,
		quantity: 385,
	},
	{
		garage_id: 110,
		part_id: 198,
		quantity: 120,
	},
	{
		garage_id: 110,
		part_id: 199,
		quantity: 13,
	},
	{
		garage_id: 110,
		part_id: 200,
		quantity: 60,
	},
	{
		garage_id: 110,
		part_id: 201,
		quantity: 359,
	},
	{
		garage_id: 110,
		part_id: 202,
		quantity: 274,
	},
	{
		garage_id: 110,
		part_id: 203,
		quantity: 363,
	},
	{
		garage_id: 110,
		part_id: 204,
		quantity: 466,
	},
	{
		garage_id: 110,
		part_id: 205,
		quantity: 16,
	},
	{
		garage_id: 110,
		part_id: 206,
		quantity: 99,
	},
	{
		garage_id: 110,
		part_id: 207,
		quantity: 233,
	},
	{
		garage_id: 110,
		part_id: 208,
		quantity: 220,
	},
	{
		garage_id: 110,
		part_id: 209,
		quantity: 51,
	},
	{
		garage_id: 110,
		part_id: 210,
		quantity: 449,
	},
	{
		garage_id: 110,
		part_id: 211,
		quantity: 261,
	},
	{
		garage_id: 110,
		part_id: 212,
		quantity: 227,
	},
	{
		garage_id: 110,
		part_id: 213,
		quantity: 273,
	},
	{
		garage_id: 110,
		part_id: 214,
		quantity: 60,
	},
	{
		garage_id: 110,
		part_id: 215,
		quantity: 317,
	},
	{
		garage_id: 110,
		part_id: 216,
		quantity: 439,
	},
	{
		garage_id: 110,
		part_id: 217,
		quantity: 317,
	},
	{
		garage_id: 110,
		part_id: 218,
		quantity: 417,
	},
	{
		garage_id: 110,
		part_id: 219,
		quantity: 411,
	},
	{
		garage_id: 110,
		part_id: 220,
		quantity: 148,
	},
	{
		garage_id: 110,
		part_id: 221,
		quantity: 200,
	},
	{
		garage_id: 110,
		part_id: 222,
		quantity: 211,
	},
	{
		garage_id: 110,
		part_id: 223,
		quantity: 138,
	},
	{
		garage_id: 110,
		part_id: 224,
		quantity: 198,
	},
	{
		garage_id: 110,
		part_id: 225,
		quantity: 341,
	},
	{
		garage_id: 110,
		part_id: 226,
		quantity: 216,
	},
	{
		garage_id: 110,
		part_id: 227,
		quantity: 241,
	},
	{
		garage_id: 110,
		part_id: 228,
		quantity: 419,
	},
	{
		garage_id: 110,
		part_id: 229,
		quantity: 312,
	},
	{
		garage_id: 110,
		part_id: 230,
		quantity: 410,
	},
	{
		garage_id: 110,
		part_id: 231,
		quantity: 347,
	},
	{
		garage_id: 110,
		part_id: 232,
		quantity: 60,
	},
	{
		garage_id: 110,
		part_id: 233,
		quantity: 384,
	},
	{
		garage_id: 110,
		part_id: 234,
		quantity: 103,
	},
	{
		garage_id: 110,
		part_id: 235,
		quantity: 274,
	},
	{
		garage_id: 110,
		part_id: 236,
		quantity: 386,
	},
	{
		garage_id: 110,
		part_id: 237,
		quantity: 9,
	},
	{
		garage_id: 110,
		part_id: 238,
		quantity: 422,
	},
	{
		garage_id: 110,
		part_id: 239,
		quantity: 482,
	},
	{
		garage_id: 110,
		part_id: 240,
		quantity: 439,
	},
	{
		garage_id: 110,
		part_id: 241,
		quantity: 401,
	},
	{
		garage_id: 110,
		part_id: 242,
		quantity: 33,
	},
	{
		garage_id: 110,
		part_id: 243,
		quantity: 43,
	},
	{
		garage_id: 110,
		part_id: 244,
		quantity: 151,
	},
	{
		garage_id: 110,
		part_id: 245,
		quantity: 265,
	},
	{
		garage_id: 110,
		part_id: 246,
		quantity: 406,
	},
	{
		garage_id: 110,
		part_id: 247,
		quantity: 334,
	},
	{
		garage_id: 110,
		part_id: 248,
		quantity: 58,
	},
	{
		garage_id: 110,
		part_id: 249,
		quantity: 445,
	},
	{
		garage_id: 110,
		part_id: 250,
		quantity: 128,
	},
	{
		garage_id: 110,
		part_id: 251,
		quantity: 472,
	},
	{
		garage_id: 110,
		part_id: 252,
		quantity: 63,
	},
	{
		garage_id: 110,
		part_id: 253,
		quantity: 313,
	},
	{
		garage_id: 110,
		part_id: 254,
		quantity: 89,
	},
	{
		garage_id: 110,
		part_id: 255,
		quantity: 192,
	},
	{
		garage_id: 110,
		part_id: 256,
		quantity: 329,
	},
	{
		garage_id: 110,
		part_id: 257,
		quantity: 6,
	},
	{
		garage_id: 110,
		part_id: 258,
		quantity: 495,
	},
	{
		garage_id: 110,
		part_id: 259,
		quantity: 116,
	},
	{
		garage_id: 110,
		part_id: 260,
		quantity: 245,
	},
	{
		garage_id: 110,
		part_id: 261,
		quantity: 267,
	},
	{
		garage_id: 110,
		part_id: 262,
		quantity: 69,
	},
	{
		garage_id: 110,
		part_id: 263,
		quantity: 383,
	},
	{
		garage_id: 110,
		part_id: 264,
		quantity: 250,
	},
	{
		garage_id: 110,
		part_id: 265,
		quantity: 470,
	},
	{
		garage_id: 110,
		part_id: 266,
		quantity: 189,
	},
	{
		garage_id: 110,
		part_id: 267,
		quantity: 349,
	},
	{
		garage_id: 110,
		part_id: 268,
		quantity: 210,
	},
	{
		garage_id: 110,
		part_id: 269,
		quantity: 352,
	},
	{
		garage_id: 110,
		part_id: 270,
		quantity: 9,
	},
	{
		garage_id: 110,
		part_id: 271,
		quantity: 392,
	},
	{
		garage_id: 110,
		part_id: 272,
		quantity: 257,
	},
	{
		garage_id: 110,
		part_id: 273,
		quantity: 113,
	},
	{
		garage_id: 110,
		part_id: 274,
		quantity: 439,
	},
	{
		garage_id: 110,
		part_id: 275,
		quantity: 297,
	},
	{
		garage_id: 110,
		part_id: 276,
		quantity: 47,
	},
	{
		garage_id: 110,
		part_id: 277,
		quantity: 255,
	},
	{
		garage_id: 110,
		part_id: 278,
		quantity: 433,
	},
	{
		garage_id: 110,
		part_id: 279,
		quantity: 315,
	},
	{
		garage_id: 110,
		part_id: 280,
		quantity: 121,
	},
	{
		garage_id: 110,
		part_id: 281,
		quantity: 82,
	},
	{
		garage_id: 110,
		part_id: 282,
		quantity: 246,
	},
	{
		garage_id: 110,
		part_id: 283,
		quantity: 250,
	},
	{
		garage_id: 110,
		part_id: 284,
		quantity: 437,
	},
	{
		garage_id: 110,
		part_id: 285,
		quantity: 204,
	},
	{
		garage_id: 110,
		part_id: 286,
		quantity: 66,
	},
	{
		garage_id: 110,
		part_id: 287,
		quantity: 266,
	},
	{
		garage_id: 110,
		part_id: 288,
		quantity: 89,
	},
	{
		garage_id: 110,
		part_id: 289,
		quantity: 183,
	},
	{
		garage_id: 110,
		part_id: 290,
		quantity: 143,
	},
	{
		garage_id: 110,
		part_id: 291,
		quantity: 304,
	},
	{
		garage_id: 110,
		part_id: 292,
		quantity: 236,
	},
	{
		garage_id: 110,
		part_id: 293,
		quantity: 313,
	},
	{
		garage_id: 110,
		part_id: 294,
		quantity: 58,
	},
	{
		garage_id: 110,
		part_id: 295,
		quantity: 405,
	},
	{
		garage_id: 110,
		part_id: 296,
		quantity: 399,
	},
	{
		garage_id: 110,
		part_id: 297,
		quantity: 226,
	},
	{
		garage_id: 110,
		part_id: 298,
		quantity: 57,
	},
	{
		garage_id: 110,
		part_id: 299,
		quantity: 357,
	},
	{
		garage_id: 110,
		part_id: 300,
		quantity: 366,
	},
	{
		garage_id: 110,
		part_id: 301,
		quantity: 22,
	},
	{
		garage_id: 110,
		part_id: 302,
		quantity: 355,
	},
	{
		garage_id: 110,
		part_id: 303,
		quantity: 329,
	},
	{
		garage_id: 110,
		part_id: 304,
		quantity: 213,
	},
	{
		garage_id: 110,
		part_id: 305,
		quantity: 128,
	},
	{
		garage_id: 110,
		part_id: 306,
		quantity: 175,
	},
	{
		garage_id: 110,
		part_id: 307,
		quantity: 40,
	},
	{
		garage_id: 110,
		part_id: 308,
		quantity: 370,
	},
	{
		garage_id: 110,
		part_id: 309,
		quantity: 334,
	},
	{
		garage_id: 110,
		part_id: 310,
		quantity: 408,
	},
	{
		garage_id: 110,
		part_id: 311,
		quantity: 98,
	},
	{
		garage_id: 110,
		part_id: 312,
		quantity: 401,
	},
	{
		garage_id: 110,
		part_id: 313,
		quantity: 460,
	},
	{
		garage_id: 110,
		part_id: 314,
		quantity: 7,
	},
	{
		garage_id: 110,
		part_id: 315,
		quantity: 426,
	},
	{
		garage_id: 110,
		part_id: 316,
		quantity: 152,
	},
	{
		garage_id: 110,
		part_id: 317,
		quantity: 247,
	},
	{
		garage_id: 110,
		part_id: 318,
		quantity: 337,
	},
	{
		garage_id: 110,
		part_id: 319,
		quantity: 500,
	},
	{
		garage_id: 110,
		part_id: 320,
		quantity: 451,
	},
	{
		garage_id: 110,
		part_id: 321,
		quantity: 247,
	},
	{
		garage_id: 110,
		part_id: 322,
		quantity: 5,
	},
	{
		garage_id: 110,
		part_id: 323,
		quantity: 453,
	},
	{
		garage_id: 110,
		part_id: 324,
		quantity: 223,
	},
	{
		garage_id: 110,
		part_id: 325,
		quantity: 223,
	},
	{
		garage_id: 110,
		part_id: 326,
		quantity: 213,
	},
	{
		garage_id: 110,
		part_id: 327,
		quantity: 498,
	},
	{
		garage_id: 110,
		part_id: 328,
		quantity: 84,
	},
	{
		garage_id: 110,
		part_id: 329,
		quantity: 172,
	},
	{
		garage_id: 110,
		part_id: 330,
		quantity: 265,
	},
	{
		garage_id: 110,
		part_id: 331,
		quantity: 123,
	},
	{
		garage_id: 110,
		part_id: 332,
		quantity: 120,
	},
	{
		garage_id: 110,
		part_id: 333,
		quantity: 337,
	},
	{
		garage_id: 110,
		part_id: 334,
		quantity: 302,
	},
	{
		garage_id: 110,
		part_id: 335,
		quantity: 498,
	},
	{
		garage_id: 110,
		part_id: 336,
		quantity: 338,
	},
	{
		garage_id: 110,
		part_id: 337,
		quantity: 105,
	},
	{
		garage_id: 110,
		part_id: 338,
		quantity: 20,
	},
	{
		garage_id: 110,
		part_id: 339,
		quantity: 127,
	},
	{
		garage_id: 110,
		part_id: 340,
		quantity: 439,
	},
	{
		garage_id: 110,
		part_id: 341,
		quantity: 225,
	},
	{
		garage_id: 110,
		part_id: 342,
		quantity: 354,
	},
	{
		garage_id: 110,
		part_id: 343,
		quantity: 493,
	},
	{
		garage_id: 110,
		part_id: 344,
		quantity: 185,
	},
	{
		garage_id: 110,
		part_id: 345,
		quantity: 111,
	},
	{
		garage_id: 110,
		part_id: 346,
		quantity: 184,
	},
	{
		garage_id: 110,
		part_id: 347,
		quantity: 176,
	},
	{
		garage_id: 110,
		part_id: 348,
		quantity: 488,
	},
	{
		garage_id: 110,
		part_id: 349,
		quantity: 466,
	},
	{
		garage_id: 110,
		part_id: 350,
		quantity: 68,
	},
	{
		garage_id: 110,
		part_id: 351,
		quantity: 167,
	},
	{
		garage_id: 110,
		part_id: 352,
		quantity: 171,
	},
	{
		garage_id: 110,
		part_id: 353,
		quantity: 226,
	},
	{
		garage_id: 110,
		part_id: 354,
		quantity: 176,
	},
	{
		garage_id: 110,
		part_id: 355,
		quantity: 430,
	},
	{
		garage_id: 110,
		part_id: 356,
		quantity: 423,
	},
	{
		garage_id: 110,
		part_id: 357,
		quantity: 8,
	},
	{
		garage_id: 110,
		part_id: 358,
		quantity: 305,
	},
	{
		garage_id: 110,
		part_id: 359,
		quantity: 126,
	},
	{
		garage_id: 110,
		part_id: 360,
		quantity: 204,
	},
	{
		garage_id: 110,
		part_id: 361,
		quantity: 316,
	},
	{
		garage_id: 110,
		part_id: 362,
		quantity: 373,
	},
	{
		garage_id: 110,
		part_id: 363,
		quantity: 72,
	},
	{
		garage_id: 110,
		part_id: 364,
		quantity: 195,
	},
	{
		garage_id: 110,
		part_id: 365,
		quantity: 357,
	},
	{
		garage_id: 110,
		part_id: 366,
		quantity: 318,
	},
	{
		garage_id: 110,
		part_id: 367,
		quantity: 347,
	},
	{
		garage_id: 110,
		part_id: 368,
		quantity: 458,
	},
	{
		garage_id: 110,
		part_id: 369,
		quantity: 139,
	},
	{
		garage_id: 110,
		part_id: 370,
		quantity: 4,
	},
	{
		garage_id: 110,
		part_id: 371,
		quantity: 280,
	},
	{
		garage_id: 110,
		part_id: 372,
		quantity: 417,
	},
	{
		garage_id: 110,
		part_id: 373,
		quantity: 392,
	},
	{
		garage_id: 110,
		part_id: 374,
		quantity: 431,
	},
	{
		garage_id: 110,
		part_id: 375,
		quantity: 399,
	},
	{
		garage_id: 110,
		part_id: 376,
		quantity: 382,
	},
	{
		garage_id: 110,
		part_id: 377,
		quantity: 439,
	},
	{
		garage_id: 110,
		part_id: 378,
		quantity: 281,
	},
	{
		garage_id: 110,
		part_id: 379,
		quantity: 53,
	},
	{
		garage_id: 110,
		part_id: 380,
		quantity: 79,
	},
	{
		garage_id: 110,
		part_id: 381,
		quantity: 182,
	},
	{
		garage_id: 110,
		part_id: 382,
		quantity: 328,
	},
	{
		garage_id: 110,
		part_id: 383,
		quantity: 485,
	},
	{
		garage_id: 110,
		part_id: 384,
		quantity: 34,
	},
	{
		garage_id: 110,
		part_id: 385,
		quantity: 481,
	},
	{
		garage_id: 110,
		part_id: 386,
		quantity: 154,
	},
	{
		garage_id: 110,
		part_id: 387,
		quantity: 447,
	},
	{
		garage_id: 110,
		part_id: 388,
		quantity: 362,
	},
	{
		garage_id: 110,
		part_id: 389,
		quantity: 119,
	},
	{
		garage_id: 110,
		part_id: 390,
		quantity: 119,
	},
	{
		garage_id: 110,
		part_id: 391,
		quantity: 463,
	},
	{
		garage_id: 110,
		part_id: 392,
		quantity: 258,
	},
	{
		garage_id: 110,
		part_id: 393,
		quantity: 125,
	},
	{
		garage_id: 110,
		part_id: 394,
		quantity: 36,
	},
	{
		garage_id: 110,
		part_id: 395,
		quantity: 32,
	},
	{
		garage_id: 110,
		part_id: 396,
		quantity: 45,
	},
	{
		garage_id: 110,
		part_id: 397,
		quantity: 22,
	},
	{
		garage_id: 110,
		part_id: 398,
		quantity: 298,
	},
	{
		garage_id: 110,
		part_id: 399,
		quantity: 60,
	},
	{
		garage_id: 111,
		part_id: 0,
		quantity: 167,
	},
	{
		garage_id: 111,
		part_id: 1,
		quantity: 382,
	},
	{
		garage_id: 111,
		part_id: 2,
		quantity: 251,
	},
	{
		garage_id: 111,
		part_id: 3,
		quantity: 62,
	},
	{
		garage_id: 111,
		part_id: 4,
		quantity: 16,
	},
	{
		garage_id: 111,
		part_id: 5,
		quantity: 463,
	},
	{
		garage_id: 111,
		part_id: 6,
		quantity: 105,
	},
	{
		garage_id: 111,
		part_id: 7,
		quantity: 445,
	},
	{
		garage_id: 111,
		part_id: 8,
		quantity: 20,
	},
	{
		garage_id: 111,
		part_id: 9,
		quantity: 17,
	},
	{
		garage_id: 111,
		part_id: 10,
		quantity: 2,
	},
	{
		garage_id: 111,
		part_id: 11,
		quantity: 426,
	},
	{
		garage_id: 111,
		part_id: 12,
		quantity: 442,
	},
	{
		garage_id: 111,
		part_id: 13,
		quantity: 29,
	},
	{
		garage_id: 111,
		part_id: 14,
		quantity: 63,
	},
	{
		garage_id: 111,
		part_id: 15,
		quantity: 258,
	},
	{
		garage_id: 111,
		part_id: 16,
		quantity: 27,
	},
	{
		garage_id: 111,
		part_id: 17,
		quantity: 474,
	},
	{
		garage_id: 111,
		part_id: 18,
		quantity: 245,
	},
	{
		garage_id: 111,
		part_id: 19,
		quantity: 195,
	},
	{
		garage_id: 111,
		part_id: 20,
		quantity: 140,
	},
	{
		garage_id: 111,
		part_id: 21,
		quantity: 326,
	},
	{
		garage_id: 111,
		part_id: 22,
		quantity: 72,
	},
	{
		garage_id: 111,
		part_id: 23,
		quantity: 168,
	},
	{
		garage_id: 111,
		part_id: 24,
		quantity: 18,
	},
	{
		garage_id: 111,
		part_id: 25,
		quantity: 382,
	},
	{
		garage_id: 111,
		part_id: 26,
		quantity: 271,
	},
	{
		garage_id: 111,
		part_id: 27,
		quantity: 325,
	},
	{
		garage_id: 111,
		part_id: 28,
		quantity: 12,
	},
	{
		garage_id: 111,
		part_id: 29,
		quantity: 165,
	},
	{
		garage_id: 111,
		part_id: 30,
		quantity: 435,
	},
	{
		garage_id: 111,
		part_id: 31,
		quantity: 334,
	},
	{
		garage_id: 111,
		part_id: 32,
		quantity: 364,
	},
	{
		garage_id: 111,
		part_id: 33,
		quantity: 491,
	},
	{
		garage_id: 111,
		part_id: 34,
		quantity: 336,
	},
	{
		garage_id: 111,
		part_id: 35,
		quantity: 51,
	},
	{
		garage_id: 111,
		part_id: 36,
		quantity: 67,
	},
	{
		garage_id: 111,
		part_id: 37,
		quantity: 16,
	},
	{
		garage_id: 111,
		part_id: 38,
		quantity: 438,
	},
	{
		garage_id: 111,
		part_id: 39,
		quantity: 272,
	},
	{
		garage_id: 111,
		part_id: 40,
		quantity: 452,
	},
	{
		garage_id: 111,
		part_id: 41,
		quantity: 280,
	},
	{
		garage_id: 111,
		part_id: 42,
		quantity: 56,
	},
	{
		garage_id: 111,
		part_id: 43,
		quantity: 296,
	},
	{
		garage_id: 111,
		part_id: 44,
		quantity: 14,
	},
	{
		garage_id: 111,
		part_id: 45,
		quantity: 497,
	},
	{
		garage_id: 111,
		part_id: 46,
		quantity: 370,
	},
	{
		garage_id: 111,
		part_id: 47,
		quantity: 240,
	},
	{
		garage_id: 111,
		part_id: 48,
		quantity: 224,
	},
	{
		garage_id: 111,
		part_id: 49,
		quantity: 202,
	},
	{
		garage_id: 111,
		part_id: 50,
		quantity: 26,
	},
	{
		garage_id: 111,
		part_id: 51,
		quantity: 90,
	},
	{
		garage_id: 111,
		part_id: 52,
		quantity: 260,
	},
	{
		garage_id: 111,
		part_id: 53,
		quantity: 183,
	},
	{
		garage_id: 111,
		part_id: 54,
		quantity: 12,
	},
	{
		garage_id: 111,
		part_id: 55,
		quantity: 189,
	},
	{
		garage_id: 111,
		part_id: 56,
		quantity: 465,
	},
	{
		garage_id: 111,
		part_id: 57,
		quantity: 189,
	},
	{
		garage_id: 111,
		part_id: 58,
		quantity: 429,
	},
	{
		garage_id: 111,
		part_id: 59,
		quantity: 379,
	},
	{
		garage_id: 111,
		part_id: 60,
		quantity: 182,
	},
	{
		garage_id: 111,
		part_id: 61,
		quantity: 337,
	},
	{
		garage_id: 111,
		part_id: 62,
		quantity: 396,
	},
	{
		garage_id: 111,
		part_id: 63,
		quantity: 259,
	},
	{
		garage_id: 111,
		part_id: 64,
		quantity: 23,
	},
	{
		garage_id: 111,
		part_id: 65,
		quantity: 298,
	},
	{
		garage_id: 111,
		part_id: 66,
		quantity: 47,
	},
	{
		garage_id: 111,
		part_id: 67,
		quantity: 338,
	},
	{
		garage_id: 111,
		part_id: 68,
		quantity: 483,
	},
	{
		garage_id: 111,
		part_id: 69,
		quantity: 256,
	},
	{
		garage_id: 111,
		part_id: 70,
		quantity: 198,
	},
	{
		garage_id: 111,
		part_id: 71,
		quantity: 70,
	},
	{
		garage_id: 111,
		part_id: 72,
		quantity: 88,
	},
	{
		garage_id: 111,
		part_id: 73,
		quantity: 112,
	},
	{
		garage_id: 111,
		part_id: 74,
		quantity: 68,
	},
	{
		garage_id: 111,
		part_id: 75,
		quantity: 302,
	},
	{
		garage_id: 111,
		part_id: 76,
		quantity: 364,
	},
	{
		garage_id: 111,
		part_id: 77,
		quantity: 477,
	},
	{
		garage_id: 111,
		part_id: 78,
		quantity: 150,
	},
	{
		garage_id: 111,
		part_id: 79,
		quantity: 218,
	},
	{
		garage_id: 111,
		part_id: 80,
		quantity: 407,
	},
	{
		garage_id: 111,
		part_id: 81,
		quantity: 252,
	},
	{
		garage_id: 111,
		part_id: 82,
		quantity: 207,
	},
	{
		garage_id: 111,
		part_id: 83,
		quantity: 207,
	},
	{
		garage_id: 111,
		part_id: 84,
		quantity: 340,
	},
	{
		garage_id: 111,
		part_id: 85,
		quantity: 271,
	},
	{
		garage_id: 111,
		part_id: 86,
		quantity: 238,
	},
	{
		garage_id: 111,
		part_id: 87,
		quantity: 499,
	},
	{
		garage_id: 111,
		part_id: 88,
		quantity: 154,
	},
	{
		garage_id: 111,
		part_id: 89,
		quantity: 299,
	},
	{
		garage_id: 111,
		part_id: 90,
		quantity: 201,
	},
	{
		garage_id: 111,
		part_id: 91,
		quantity: 497,
	},
	{
		garage_id: 111,
		part_id: 92,
		quantity: 396,
	},
	{
		garage_id: 111,
		part_id: 93,
		quantity: 349,
	},
	{
		garage_id: 111,
		part_id: 94,
		quantity: 390,
	},
	{
		garage_id: 111,
		part_id: 95,
		quantity: 350,
	},
	{
		garage_id: 111,
		part_id: 96,
		quantity: 35,
	},
	{
		garage_id: 111,
		part_id: 97,
		quantity: 181,
	},
	{
		garage_id: 111,
		part_id: 98,
		quantity: 453,
	},
	{
		garage_id: 111,
		part_id: 99,
		quantity: 129,
	},
	{
		garage_id: 111,
		part_id: 100,
		quantity: 14,
	},
	{
		garage_id: 111,
		part_id: 101,
		quantity: 396,
	},
	{
		garage_id: 111,
		part_id: 102,
		quantity: 439,
	},
	{
		garage_id: 111,
		part_id: 103,
		quantity: 180,
	},
	{
		garage_id: 111,
		part_id: 104,
		quantity: 252,
	},
	{
		garage_id: 111,
		part_id: 105,
		quantity: 64,
	},
	{
		garage_id: 111,
		part_id: 106,
		quantity: 449,
	},
	{
		garage_id: 111,
		part_id: 107,
		quantity: 103,
	},
	{
		garage_id: 111,
		part_id: 108,
		quantity: 244,
	},
	{
		garage_id: 111,
		part_id: 109,
		quantity: 154,
	},
	{
		garage_id: 111,
		part_id: 110,
		quantity: 281,
	},
	{
		garage_id: 111,
		part_id: 111,
		quantity: 177,
	},
	{
		garage_id: 111,
		part_id: 112,
		quantity: 106,
	},
	{
		garage_id: 111,
		part_id: 113,
		quantity: 394,
	},
	{
		garage_id: 111,
		part_id: 114,
		quantity: 451,
	},
	{
		garage_id: 111,
		part_id: 115,
		quantity: 439,
	},
	{
		garage_id: 111,
		part_id: 116,
		quantity: 33,
	},
	{
		garage_id: 111,
		part_id: 117,
		quantity: 91,
	},
	{
		garage_id: 111,
		part_id: 118,
		quantity: 7,
	},
	{
		garage_id: 111,
		part_id: 119,
		quantity: 338,
	},
	{
		garage_id: 111,
		part_id: 120,
		quantity: 263,
	},
	{
		garage_id: 111,
		part_id: 121,
		quantity: 428,
	},
	{
		garage_id: 111,
		part_id: 122,
		quantity: 11,
	},
	{
		garage_id: 111,
		part_id: 123,
		quantity: 330,
	},
	{
		garage_id: 111,
		part_id: 124,
		quantity: 102,
	},
	{
		garage_id: 111,
		part_id: 125,
		quantity: 102,
	},
	{
		garage_id: 111,
		part_id: 126,
		quantity: 128,
	},
	{
		garage_id: 111,
		part_id: 127,
		quantity: 263,
	},
	{
		garage_id: 111,
		part_id: 128,
		quantity: 325,
	},
	{
		garage_id: 111,
		part_id: 129,
		quantity: 117,
	},
	{
		garage_id: 111,
		part_id: 130,
		quantity: 125,
	},
	{
		garage_id: 111,
		part_id: 131,
		quantity: 429,
	},
	{
		garage_id: 111,
		part_id: 132,
		quantity: 377,
	},
	{
		garage_id: 111,
		part_id: 133,
		quantity: 460,
	},
	{
		garage_id: 111,
		part_id: 134,
		quantity: 96,
	},
	{
		garage_id: 111,
		part_id: 135,
		quantity: 267,
	},
	{
		garage_id: 111,
		part_id: 136,
		quantity: 350,
	},
	{
		garage_id: 111,
		part_id: 137,
		quantity: 382,
	},
	{
		garage_id: 111,
		part_id: 138,
		quantity: 311,
	},
	{
		garage_id: 111,
		part_id: 139,
		quantity: 398,
	},
	{
		garage_id: 111,
		part_id: 140,
		quantity: 96,
	},
	{
		garage_id: 111,
		part_id: 141,
		quantity: 40,
	},
	{
		garage_id: 111,
		part_id: 142,
		quantity: 252,
	},
	{
		garage_id: 111,
		part_id: 143,
		quantity: 404,
	},
	{
		garage_id: 111,
		part_id: 144,
		quantity: 240,
	},
	{
		garage_id: 111,
		part_id: 145,
		quantity: 340,
	},
	{
		garage_id: 111,
		part_id: 146,
		quantity: 457,
	},
	{
		garage_id: 111,
		part_id: 147,
		quantity: 36,
	},
	{
		garage_id: 111,
		part_id: 148,
		quantity: 121,
	},
	{
		garage_id: 111,
		part_id: 149,
		quantity: 226,
	},
	{
		garage_id: 111,
		part_id: 150,
		quantity: 394,
	},
	{
		garage_id: 111,
		part_id: 151,
		quantity: 312,
	},
	{
		garage_id: 111,
		part_id: 152,
		quantity: 393,
	},
	{
		garage_id: 111,
		part_id: 153,
		quantity: 147,
	},
	{
		garage_id: 111,
		part_id: 154,
		quantity: 306,
	},
	{
		garage_id: 111,
		part_id: 155,
		quantity: 404,
	},
	{
		garage_id: 111,
		part_id: 156,
		quantity: 431,
	},
	{
		garage_id: 111,
		part_id: 157,
		quantity: 267,
	},
	{
		garage_id: 111,
		part_id: 158,
		quantity: 443,
	},
	{
		garage_id: 111,
		part_id: 159,
		quantity: 60,
	},
	{
		garage_id: 111,
		part_id: 160,
		quantity: 25,
	},
	{
		garage_id: 111,
		part_id: 161,
		quantity: 358,
	},
	{
		garage_id: 111,
		part_id: 162,
		quantity: 205,
	},
	{
		garage_id: 111,
		part_id: 163,
		quantity: 122,
	},
	{
		garage_id: 111,
		part_id: 164,
		quantity: 157,
	},
	{
		garage_id: 111,
		part_id: 165,
		quantity: 496,
	},
	{
		garage_id: 111,
		part_id: 166,
		quantity: 83,
	},
	{
		garage_id: 111,
		part_id: 167,
		quantity: 84,
	},
	{
		garage_id: 111,
		part_id: 168,
		quantity: 351,
	},
	{
		garage_id: 111,
		part_id: 169,
		quantity: 484,
	},
	{
		garage_id: 111,
		part_id: 170,
		quantity: 106,
	},
	{
		garage_id: 111,
		part_id: 171,
		quantity: 258,
	},
	{
		garage_id: 111,
		part_id: 172,
		quantity: 377,
	},
	{
		garage_id: 111,
		part_id: 173,
		quantity: 136,
	},
	{
		garage_id: 111,
		part_id: 174,
		quantity: 265,
	},
	{
		garage_id: 111,
		part_id: 175,
		quantity: 212,
	},
	{
		garage_id: 111,
		part_id: 176,
		quantity: 2,
	},
	{
		garage_id: 111,
		part_id: 177,
		quantity: 287,
	},
	{
		garage_id: 111,
		part_id: 178,
		quantity: 299,
	},
	{
		garage_id: 111,
		part_id: 179,
		quantity: 59,
	},
	{
		garage_id: 111,
		part_id: 180,
		quantity: 398,
	},
	{
		garage_id: 111,
		part_id: 181,
		quantity: 65,
	},
	{
		garage_id: 111,
		part_id: 182,
		quantity: 224,
	},
	{
		garage_id: 111,
		part_id: 183,
		quantity: 36,
	},
	{
		garage_id: 111,
		part_id: 184,
		quantity: 472,
	},
	{
		garage_id: 111,
		part_id: 185,
		quantity: 209,
	},
	{
		garage_id: 111,
		part_id: 186,
		quantity: 24,
	},
	{
		garage_id: 111,
		part_id: 187,
		quantity: 408,
	},
	{
		garage_id: 111,
		part_id: 188,
		quantity: 17,
	},
	{
		garage_id: 111,
		part_id: 189,
		quantity: 454,
	},
	{
		garage_id: 111,
		part_id: 190,
		quantity: 111,
	},
	{
		garage_id: 111,
		part_id: 191,
		quantity: 6,
	},
	{
		garage_id: 111,
		part_id: 192,
		quantity: 128,
	},
	{
		garage_id: 111,
		part_id: 193,
		quantity: 373,
	},
	{
		garage_id: 111,
		part_id: 194,
		quantity: 425,
	},
	{
		garage_id: 111,
		part_id: 195,
		quantity: 262,
	},
	{
		garage_id: 111,
		part_id: 196,
		quantity: 393,
	},
	{
		garage_id: 111,
		part_id: 197,
		quantity: 408,
	},
	{
		garage_id: 111,
		part_id: 198,
		quantity: 240,
	},
	{
		garage_id: 111,
		part_id: 199,
		quantity: 143,
	},
	{
		garage_id: 111,
		part_id: 200,
		quantity: 103,
	},
	{
		garage_id: 111,
		part_id: 201,
		quantity: 455,
	},
	{
		garage_id: 111,
		part_id: 202,
		quantity: 378,
	},
	{
		garage_id: 111,
		part_id: 203,
		quantity: 407,
	},
	{
		garage_id: 111,
		part_id: 204,
		quantity: 302,
	},
	{
		garage_id: 111,
		part_id: 205,
		quantity: 58,
	},
	{
		garage_id: 111,
		part_id: 206,
		quantity: 263,
	},
	{
		garage_id: 111,
		part_id: 207,
		quantity: 115,
	},
	{
		garage_id: 111,
		part_id: 208,
		quantity: 240,
	},
	{
		garage_id: 111,
		part_id: 209,
		quantity: 435,
	},
	{
		garage_id: 111,
		part_id: 210,
		quantity: 9,
	},
	{
		garage_id: 111,
		part_id: 211,
		quantity: 205,
	},
	{
		garage_id: 111,
		part_id: 212,
		quantity: 237,
	},
	{
		garage_id: 111,
		part_id: 213,
		quantity: 417,
	},
	{
		garage_id: 111,
		part_id: 214,
		quantity: 399,
	},
	{
		garage_id: 111,
		part_id: 215,
		quantity: 65,
	},
	{
		garage_id: 111,
		part_id: 216,
		quantity: 186,
	},
	{
		garage_id: 111,
		part_id: 217,
		quantity: 171,
	},
	{
		garage_id: 111,
		part_id: 218,
		quantity: 455,
	},
	{
		garage_id: 111,
		part_id: 219,
		quantity: 129,
	},
	{
		garage_id: 111,
		part_id: 220,
		quantity: 345,
	},
	{
		garage_id: 111,
		part_id: 221,
		quantity: 287,
	},
	{
		garage_id: 111,
		part_id: 222,
		quantity: 41,
	},
	{
		garage_id: 111,
		part_id: 223,
		quantity: 155,
	},
	{
		garage_id: 111,
		part_id: 224,
		quantity: 286,
	},
	{
		garage_id: 111,
		part_id: 225,
		quantity: 35,
	},
	{
		garage_id: 111,
		part_id: 226,
		quantity: 0,
	},
	{
		garage_id: 111,
		part_id: 227,
		quantity: 20,
	},
	{
		garage_id: 111,
		part_id: 228,
		quantity: 51,
	},
	{
		garage_id: 111,
		part_id: 229,
		quantity: 455,
	},
	{
		garage_id: 111,
		part_id: 230,
		quantity: 88,
	},
	{
		garage_id: 111,
		part_id: 231,
		quantity: 300,
	},
	{
		garage_id: 111,
		part_id: 232,
		quantity: 500,
	},
	{
		garage_id: 111,
		part_id: 233,
		quantity: 464,
	},
	{
		garage_id: 111,
		part_id: 234,
		quantity: 277,
	},
	{
		garage_id: 111,
		part_id: 235,
		quantity: 52,
	},
	{
		garage_id: 111,
		part_id: 236,
		quantity: 183,
	},
	{
		garage_id: 111,
		part_id: 237,
		quantity: 395,
	},
	{
		garage_id: 111,
		part_id: 238,
		quantity: 322,
	},
	{
		garage_id: 111,
		part_id: 239,
		quantity: 263,
	},
	{
		garage_id: 111,
		part_id: 240,
		quantity: 18,
	},
	{
		garage_id: 111,
		part_id: 241,
		quantity: 90,
	},
	{
		garage_id: 111,
		part_id: 242,
		quantity: 300,
	},
	{
		garage_id: 111,
		part_id: 243,
		quantity: 296,
	},
	{
		garage_id: 111,
		part_id: 244,
		quantity: 256,
	},
	{
		garage_id: 111,
		part_id: 245,
		quantity: 15,
	},
	{
		garage_id: 111,
		part_id: 246,
		quantity: 481,
	},
	{
		garage_id: 111,
		part_id: 247,
		quantity: 137,
	},
	{
		garage_id: 111,
		part_id: 248,
		quantity: 64,
	},
	{
		garage_id: 111,
		part_id: 249,
		quantity: 306,
	},
	{
		garage_id: 111,
		part_id: 250,
		quantity: 365,
	},
	{
		garage_id: 111,
		part_id: 251,
		quantity: 154,
	},
	{
		garage_id: 111,
		part_id: 252,
		quantity: 185,
	},
	{
		garage_id: 111,
		part_id: 253,
		quantity: 192,
	},
	{
		garage_id: 111,
		part_id: 254,
		quantity: 80,
	},
	{
		garage_id: 111,
		part_id: 255,
		quantity: 361,
	},
	{
		garage_id: 111,
		part_id: 256,
		quantity: 334,
	},
	{
		garage_id: 111,
		part_id: 257,
		quantity: 417,
	},
	{
		garage_id: 111,
		part_id: 258,
		quantity: 369,
	},
	{
		garage_id: 111,
		part_id: 259,
		quantity: 487,
	},
	{
		garage_id: 111,
		part_id: 260,
		quantity: 269,
	},
	{
		garage_id: 111,
		part_id: 261,
		quantity: 374,
	},
	{
		garage_id: 111,
		part_id: 262,
		quantity: 58,
	},
	{
		garage_id: 111,
		part_id: 263,
		quantity: 287,
	},
	{
		garage_id: 111,
		part_id: 264,
		quantity: 351,
	},
	{
		garage_id: 111,
		part_id: 265,
		quantity: 92,
	},
	{
		garage_id: 111,
		part_id: 266,
		quantity: 228,
	},
	{
		garage_id: 111,
		part_id: 267,
		quantity: 199,
	},
	{
		garage_id: 111,
		part_id: 268,
		quantity: 72,
	},
	{
		garage_id: 111,
		part_id: 269,
		quantity: 470,
	},
	{
		garage_id: 111,
		part_id: 270,
		quantity: 146,
	},
	{
		garage_id: 111,
		part_id: 271,
		quantity: 487,
	},
	{
		garage_id: 111,
		part_id: 272,
		quantity: 116,
	},
	{
		garage_id: 111,
		part_id: 273,
		quantity: 349,
	},
	{
		garage_id: 111,
		part_id: 274,
		quantity: 153,
	},
	{
		garage_id: 111,
		part_id: 275,
		quantity: 432,
	},
	{
		garage_id: 111,
		part_id: 276,
		quantity: 10,
	},
	{
		garage_id: 111,
		part_id: 277,
		quantity: 142,
	},
	{
		garage_id: 111,
		part_id: 278,
		quantity: 176,
	},
	{
		garage_id: 111,
		part_id: 279,
		quantity: 285,
	},
	{
		garage_id: 111,
		part_id: 280,
		quantity: 384,
	},
	{
		garage_id: 111,
		part_id: 281,
		quantity: 121,
	},
	{
		garage_id: 111,
		part_id: 282,
		quantity: 153,
	},
	{
		garage_id: 111,
		part_id: 283,
		quantity: 499,
	},
	{
		garage_id: 111,
		part_id: 284,
		quantity: 158,
	},
	{
		garage_id: 111,
		part_id: 285,
		quantity: 454,
	},
	{
		garage_id: 111,
		part_id: 286,
		quantity: 212,
	},
	{
		garage_id: 111,
		part_id: 287,
		quantity: 368,
	},
	{
		garage_id: 111,
		part_id: 288,
		quantity: 54,
	},
	{
		garage_id: 111,
		part_id: 289,
		quantity: 34,
	},
	{
		garage_id: 111,
		part_id: 290,
		quantity: 489,
	},
	{
		garage_id: 111,
		part_id: 291,
		quantity: 474,
	},
	{
		garage_id: 111,
		part_id: 292,
		quantity: 441,
	},
	{
		garage_id: 111,
		part_id: 293,
		quantity: 80,
	},
	{
		garage_id: 111,
		part_id: 294,
		quantity: 343,
	},
	{
		garage_id: 111,
		part_id: 295,
		quantity: 434,
	},
	{
		garage_id: 111,
		part_id: 296,
		quantity: 247,
	},
	{
		garage_id: 111,
		part_id: 297,
		quantity: 464,
	},
	{
		garage_id: 111,
		part_id: 298,
		quantity: 258,
	},
	{
		garage_id: 111,
		part_id: 299,
		quantity: 291,
	},
	{
		garage_id: 111,
		part_id: 300,
		quantity: 169,
	},
	{
		garage_id: 111,
		part_id: 301,
		quantity: 201,
	},
	{
		garage_id: 111,
		part_id: 302,
		quantity: 244,
	},
	{
		garage_id: 111,
		part_id: 303,
		quantity: 177,
	},
	{
		garage_id: 111,
		part_id: 304,
		quantity: 116,
	},
	{
		garage_id: 111,
		part_id: 305,
		quantity: 282,
	},
	{
		garage_id: 111,
		part_id: 306,
		quantity: 421,
	},
	{
		garage_id: 111,
		part_id: 307,
		quantity: 167,
	},
	{
		garage_id: 111,
		part_id: 308,
		quantity: 291,
	},
	{
		garage_id: 111,
		part_id: 309,
		quantity: 58,
	},
	{
		garage_id: 111,
		part_id: 310,
		quantity: 450,
	},
	{
		garage_id: 111,
		part_id: 311,
		quantity: 303,
	},
	{
		garage_id: 111,
		part_id: 312,
		quantity: 373,
	},
	{
		garage_id: 111,
		part_id: 313,
		quantity: 54,
	},
	{
		garage_id: 111,
		part_id: 314,
		quantity: 65,
	},
	{
		garage_id: 111,
		part_id: 315,
		quantity: 264,
	},
	{
		garage_id: 111,
		part_id: 316,
		quantity: 27,
	},
	{
		garage_id: 111,
		part_id: 317,
		quantity: 285,
	},
	{
		garage_id: 111,
		part_id: 318,
		quantity: 494,
	},
	{
		garage_id: 111,
		part_id: 319,
		quantity: 395,
	},
	{
		garage_id: 111,
		part_id: 320,
		quantity: 50,
	},
	{
		garage_id: 111,
		part_id: 321,
		quantity: 406,
	},
	{
		garage_id: 111,
		part_id: 322,
		quantity: 221,
	},
	{
		garage_id: 111,
		part_id: 323,
		quantity: 6,
	},
	{
		garage_id: 111,
		part_id: 324,
		quantity: 12,
	},
	{
		garage_id: 111,
		part_id: 325,
		quantity: 104,
	},
	{
		garage_id: 111,
		part_id: 326,
		quantity: 484,
	},
	{
		garage_id: 111,
		part_id: 327,
		quantity: 156,
	},
	{
		garage_id: 111,
		part_id: 328,
		quantity: 225,
	},
	{
		garage_id: 111,
		part_id: 329,
		quantity: 470,
	},
	{
		garage_id: 111,
		part_id: 330,
		quantity: 132,
	},
	{
		garage_id: 111,
		part_id: 331,
		quantity: 449,
	},
	{
		garage_id: 111,
		part_id: 332,
		quantity: 479,
	},
	{
		garage_id: 111,
		part_id: 333,
		quantity: 209,
	},
	{
		garage_id: 111,
		part_id: 334,
		quantity: 145,
	},
	{
		garage_id: 111,
		part_id: 335,
		quantity: 481,
	},
	{
		garage_id: 111,
		part_id: 336,
		quantity: 315,
	},
	{
		garage_id: 111,
		part_id: 337,
		quantity: 326,
	},
	{
		garage_id: 111,
		part_id: 338,
		quantity: 458,
	},
	{
		garage_id: 111,
		part_id: 339,
		quantity: 95,
	},
	{
		garage_id: 111,
		part_id: 340,
		quantity: 328,
	},
	{
		garage_id: 111,
		part_id: 341,
		quantity: 295,
	},
	{
		garage_id: 111,
		part_id: 342,
		quantity: 330,
	},
	{
		garage_id: 111,
		part_id: 343,
		quantity: 3,
	},
	{
		garage_id: 111,
		part_id: 344,
		quantity: 127,
	},
	{
		garage_id: 111,
		part_id: 345,
		quantity: 170,
	},
	{
		garage_id: 111,
		part_id: 346,
		quantity: 28,
	},
	{
		garage_id: 111,
		part_id: 347,
		quantity: 182,
	},
	{
		garage_id: 111,
		part_id: 348,
		quantity: 495,
	},
	{
		garage_id: 111,
		part_id: 349,
		quantity: 296,
	},
	{
		garage_id: 111,
		part_id: 350,
		quantity: 225,
	},
	{
		garage_id: 111,
		part_id: 351,
		quantity: 123,
	},
	{
		garage_id: 111,
		part_id: 352,
		quantity: 443,
	},
	{
		garage_id: 111,
		part_id: 353,
		quantity: 389,
	},
	{
		garage_id: 111,
		part_id: 354,
		quantity: 258,
	},
	{
		garage_id: 111,
		part_id: 355,
		quantity: 134,
	},
	{
		garage_id: 111,
		part_id: 356,
		quantity: 276,
	},
	{
		garage_id: 111,
		part_id: 357,
		quantity: 367,
	},
	{
		garage_id: 111,
		part_id: 358,
		quantity: 15,
	},
	{
		garage_id: 111,
		part_id: 359,
		quantity: 98,
	},
	{
		garage_id: 111,
		part_id: 360,
		quantity: 352,
	},
	{
		garage_id: 111,
		part_id: 361,
		quantity: 342,
	},
	{
		garage_id: 111,
		part_id: 362,
		quantity: 90,
	},
	{
		garage_id: 111,
		part_id: 363,
		quantity: 41,
	},
	{
		garage_id: 111,
		part_id: 364,
		quantity: 213,
	},
	{
		garage_id: 111,
		part_id: 365,
		quantity: 70,
	},
	{
		garage_id: 111,
		part_id: 366,
		quantity: 57,
	},
	{
		garage_id: 111,
		part_id: 367,
		quantity: 186,
	},
	{
		garage_id: 111,
		part_id: 368,
		quantity: 376,
	},
	{
		garage_id: 111,
		part_id: 369,
		quantity: 416,
	},
	{
		garage_id: 111,
		part_id: 370,
		quantity: 451,
	},
	{
		garage_id: 111,
		part_id: 371,
		quantity: 357,
	},
	{
		garage_id: 111,
		part_id: 372,
		quantity: 356,
	},
	{
		garage_id: 111,
		part_id: 373,
		quantity: 294,
	},
	{
		garage_id: 111,
		part_id: 374,
		quantity: 251,
	},
	{
		garage_id: 111,
		part_id: 375,
		quantity: 422,
	},
	{
		garage_id: 111,
		part_id: 376,
		quantity: 163,
	},
	{
		garage_id: 111,
		part_id: 377,
		quantity: 295,
	},
	{
		garage_id: 111,
		part_id: 378,
		quantity: 15,
	},
	{
		garage_id: 111,
		part_id: 379,
		quantity: 101,
	},
	{
		garage_id: 111,
		part_id: 380,
		quantity: 364,
	},
	{
		garage_id: 111,
		part_id: 381,
		quantity: 102,
	},
	{
		garage_id: 111,
		part_id: 382,
		quantity: 14,
	},
	{
		garage_id: 111,
		part_id: 383,
		quantity: 74,
	},
	{
		garage_id: 111,
		part_id: 384,
		quantity: 129,
	},
	{
		garage_id: 111,
		part_id: 385,
		quantity: 92,
	},
	{
		garage_id: 111,
		part_id: 386,
		quantity: 110,
	},
	{
		garage_id: 111,
		part_id: 387,
		quantity: 288,
	},
	{
		garage_id: 111,
		part_id: 388,
		quantity: 365,
	},
	{
		garage_id: 111,
		part_id: 389,
		quantity: 35,
	},
	{
		garage_id: 111,
		part_id: 390,
		quantity: 8,
	},
	{
		garage_id: 111,
		part_id: 391,
		quantity: 317,
	},
	{
		garage_id: 111,
		part_id: 392,
		quantity: 333,
	},
	{
		garage_id: 111,
		part_id: 393,
		quantity: 293,
	},
	{
		garage_id: 111,
		part_id: 394,
		quantity: 309,
	},
	{
		garage_id: 111,
		part_id: 395,
		quantity: 380,
	},
	{
		garage_id: 111,
		part_id: 396,
		quantity: 465,
	},
	{
		garage_id: 111,
		part_id: 397,
		quantity: 80,
	},
	{
		garage_id: 111,
		part_id: 398,
		quantity: 241,
	},
	{
		garage_id: 111,
		part_id: 399,
		quantity: 139,
	},
	{
		garage_id: 112,
		part_id: 0,
		quantity: 345,
	},
	{
		garage_id: 112,
		part_id: 1,
		quantity: 267,
	},
	{
		garage_id: 112,
		part_id: 2,
		quantity: 475,
	},
	{
		garage_id: 112,
		part_id: 3,
		quantity: 244,
	},
	{
		garage_id: 112,
		part_id: 4,
		quantity: 367,
	},
	{
		garage_id: 112,
		part_id: 5,
		quantity: 53,
	},
	{
		garage_id: 112,
		part_id: 6,
		quantity: 6,
	},
	{
		garage_id: 112,
		part_id: 7,
		quantity: 479,
	},
	{
		garage_id: 112,
		part_id: 8,
		quantity: 404,
	},
	{
		garage_id: 112,
		part_id: 9,
		quantity: 340,
	},
	{
		garage_id: 112,
		part_id: 10,
		quantity: 133,
	},
	{
		garage_id: 112,
		part_id: 11,
		quantity: 257,
	},
	{
		garage_id: 112,
		part_id: 12,
		quantity: 370,
	},
	{
		garage_id: 112,
		part_id: 13,
		quantity: 184,
	},
	{
		garage_id: 112,
		part_id: 14,
		quantity: 209,
	},
	{
		garage_id: 112,
		part_id: 15,
		quantity: 499,
	},
	{
		garage_id: 112,
		part_id: 16,
		quantity: 499,
	},
	{
		garage_id: 112,
		part_id: 17,
		quantity: 29,
	},
	{
		garage_id: 112,
		part_id: 18,
		quantity: 372,
	},
	{
		garage_id: 112,
		part_id: 19,
		quantity: 206,
	},
	{
		garage_id: 112,
		part_id: 20,
		quantity: 448,
	},
	{
		garage_id: 112,
		part_id: 21,
		quantity: 402,
	},
	{
		garage_id: 112,
		part_id: 22,
		quantity: 448,
	},
	{
		garage_id: 112,
		part_id: 23,
		quantity: 477,
	},
	{
		garage_id: 112,
		part_id: 24,
		quantity: 340,
	},
	{
		garage_id: 112,
		part_id: 25,
		quantity: 153,
	},
	{
		garage_id: 112,
		part_id: 26,
		quantity: 487,
	},
	{
		garage_id: 112,
		part_id: 27,
		quantity: 467,
	},
	{
		garage_id: 112,
		part_id: 28,
		quantity: 198,
	},
	{
		garage_id: 112,
		part_id: 29,
		quantity: 468,
	},
	{
		garage_id: 112,
		part_id: 30,
		quantity: 205,
	},
	{
		garage_id: 112,
		part_id: 31,
		quantity: 241,
	},
	{
		garage_id: 112,
		part_id: 32,
		quantity: 105,
	},
	{
		garage_id: 112,
		part_id: 33,
		quantity: 47,
	},
	{
		garage_id: 112,
		part_id: 34,
		quantity: 436,
	},
	{
		garage_id: 112,
		part_id: 35,
		quantity: 270,
	},
	{
		garage_id: 112,
		part_id: 36,
		quantity: 275,
	},
	{
		garage_id: 112,
		part_id: 37,
		quantity: 62,
	},
	{
		garage_id: 112,
		part_id: 38,
		quantity: 311,
	},
	{
		garage_id: 112,
		part_id: 39,
		quantity: 41,
	},
	{
		garage_id: 112,
		part_id: 40,
		quantity: 433,
	},
	{
		garage_id: 112,
		part_id: 41,
		quantity: 77,
	},
	{
		garage_id: 112,
		part_id: 42,
		quantity: 216,
	},
	{
		garage_id: 112,
		part_id: 43,
		quantity: 382,
	},
	{
		garage_id: 112,
		part_id: 44,
		quantity: 455,
	},
	{
		garage_id: 112,
		part_id: 45,
		quantity: 91,
	},
	{
		garage_id: 112,
		part_id: 46,
		quantity: 414,
	},
	{
		garage_id: 112,
		part_id: 47,
		quantity: 64,
	},
	{
		garage_id: 112,
		part_id: 48,
		quantity: 3,
	},
	{
		garage_id: 112,
		part_id: 49,
		quantity: 414,
	},
	{
		garage_id: 112,
		part_id: 50,
		quantity: 134,
	},
	{
		garage_id: 112,
		part_id: 51,
		quantity: 176,
	},
	{
		garage_id: 112,
		part_id: 52,
		quantity: 76,
	},
	{
		garage_id: 112,
		part_id: 53,
		quantity: 129,
	},
	{
		garage_id: 112,
		part_id: 54,
		quantity: 178,
	},
	{
		garage_id: 112,
		part_id: 55,
		quantity: 104,
	},
	{
		garage_id: 112,
		part_id: 56,
		quantity: 72,
	},
	{
		garage_id: 112,
		part_id: 57,
		quantity: 453,
	},
	{
		garage_id: 112,
		part_id: 58,
		quantity: 153,
	},
	{
		garage_id: 112,
		part_id: 59,
		quantity: 138,
	},
	{
		garage_id: 112,
		part_id: 60,
		quantity: 152,
	},
	{
		garage_id: 112,
		part_id: 61,
		quantity: 58,
	},
	{
		garage_id: 112,
		part_id: 62,
		quantity: 480,
	},
	{
		garage_id: 112,
		part_id: 63,
		quantity: 316,
	},
	{
		garage_id: 112,
		part_id: 64,
		quantity: 21,
	},
	{
		garage_id: 112,
		part_id: 65,
		quantity: 448,
	},
	{
		garage_id: 112,
		part_id: 66,
		quantity: 258,
	},
	{
		garage_id: 112,
		part_id: 67,
		quantity: 411,
	},
	{
		garage_id: 112,
		part_id: 68,
		quantity: 406,
	},
	{
		garage_id: 112,
		part_id: 69,
		quantity: 334,
	},
	{
		garage_id: 112,
		part_id: 70,
		quantity: 203,
	},
	{
		garage_id: 112,
		part_id: 71,
		quantity: 165,
	},
	{
		garage_id: 112,
		part_id: 72,
		quantity: 214,
	},
	{
		garage_id: 112,
		part_id: 73,
		quantity: 178,
	},
	{
		garage_id: 112,
		part_id: 74,
		quantity: 228,
	},
	{
		garage_id: 112,
		part_id: 75,
		quantity: 138,
	},
	{
		garage_id: 112,
		part_id: 76,
		quantity: 125,
	},
	{
		garage_id: 112,
		part_id: 77,
		quantity: 125,
	},
	{
		garage_id: 112,
		part_id: 78,
		quantity: 63,
	},
	{
		garage_id: 112,
		part_id: 79,
		quantity: 288,
	},
	{
		garage_id: 112,
		part_id: 80,
		quantity: 377,
	},
	{
		garage_id: 112,
		part_id: 81,
		quantity: 16,
	},
	{
		garage_id: 112,
		part_id: 82,
		quantity: 218,
	},
	{
		garage_id: 112,
		part_id: 83,
		quantity: 304,
	},
	{
		garage_id: 112,
		part_id: 84,
		quantity: 10,
	},
	{
		garage_id: 112,
		part_id: 85,
		quantity: 53,
	},
	{
		garage_id: 112,
		part_id: 86,
		quantity: 200,
	},
	{
		garage_id: 112,
		part_id: 87,
		quantity: 61,
	},
	{
		garage_id: 112,
		part_id: 88,
		quantity: 154,
	},
	{
		garage_id: 112,
		part_id: 89,
		quantity: 126,
	},
	{
		garage_id: 112,
		part_id: 90,
		quantity: 429,
	},
	{
		garage_id: 112,
		part_id: 91,
		quantity: 265,
	},
	{
		garage_id: 112,
		part_id: 92,
		quantity: 12,
	},
	{
		garage_id: 112,
		part_id: 93,
		quantity: 437,
	},
	{
		garage_id: 112,
		part_id: 94,
		quantity: 200,
	},
	{
		garage_id: 112,
		part_id: 95,
		quantity: 48,
	},
	{
		garage_id: 112,
		part_id: 96,
		quantity: 32,
	},
	{
		garage_id: 112,
		part_id: 97,
		quantity: 412,
	},
	{
		garage_id: 112,
		part_id: 98,
		quantity: 331,
	},
	{
		garage_id: 112,
		part_id: 99,
		quantity: 263,
	},
	{
		garage_id: 112,
		part_id: 100,
		quantity: 139,
	},
	{
		garage_id: 112,
		part_id: 101,
		quantity: 227,
	},
	{
		garage_id: 112,
		part_id: 102,
		quantity: 459,
	},
	{
		garage_id: 112,
		part_id: 103,
		quantity: 61,
	},
	{
		garage_id: 112,
		part_id: 104,
		quantity: 11,
	},
	{
		garage_id: 112,
		part_id: 105,
		quantity: 243,
	},
	{
		garage_id: 112,
		part_id: 106,
		quantity: 24,
	},
	{
		garage_id: 112,
		part_id: 107,
		quantity: 433,
	},
	{
		garage_id: 112,
		part_id: 108,
		quantity: 185,
	},
	{
		garage_id: 112,
		part_id: 109,
		quantity: 156,
	},
	{
		garage_id: 112,
		part_id: 110,
		quantity: 344,
	},
	{
		garage_id: 112,
		part_id: 111,
		quantity: 380,
	},
	{
		garage_id: 112,
		part_id: 112,
		quantity: 90,
	},
	{
		garage_id: 112,
		part_id: 113,
		quantity: 475,
	},
	{
		garage_id: 112,
		part_id: 114,
		quantity: 115,
	},
	{
		garage_id: 112,
		part_id: 115,
		quantity: 371,
	},
	{
		garage_id: 112,
		part_id: 116,
		quantity: 5,
	},
	{
		garage_id: 112,
		part_id: 117,
		quantity: 110,
	},
	{
		garage_id: 112,
		part_id: 118,
		quantity: 394,
	},
	{
		garage_id: 112,
		part_id: 119,
		quantity: 372,
	},
	{
		garage_id: 112,
		part_id: 120,
		quantity: 485,
	},
	{
		garage_id: 112,
		part_id: 121,
		quantity: 226,
	},
	{
		garage_id: 112,
		part_id: 122,
		quantity: 350,
	},
	{
		garage_id: 112,
		part_id: 123,
		quantity: 415,
	},
	{
		garage_id: 112,
		part_id: 124,
		quantity: 136,
	},
	{
		garage_id: 112,
		part_id: 125,
		quantity: 399,
	},
	{
		garage_id: 112,
		part_id: 126,
		quantity: 359,
	},
	{
		garage_id: 112,
		part_id: 127,
		quantity: 113,
	},
	{
		garage_id: 112,
		part_id: 128,
		quantity: 409,
	},
	{
		garage_id: 112,
		part_id: 129,
		quantity: 90,
	},
	{
		garage_id: 112,
		part_id: 130,
		quantity: 498,
	},
	{
		garage_id: 112,
		part_id: 131,
		quantity: 241,
	},
	{
		garage_id: 112,
		part_id: 132,
		quantity: 174,
	},
	{
		garage_id: 112,
		part_id: 133,
		quantity: 475,
	},
	{
		garage_id: 112,
		part_id: 134,
		quantity: 396,
	},
	{
		garage_id: 112,
		part_id: 135,
		quantity: 113,
	},
	{
		garage_id: 112,
		part_id: 136,
		quantity: 446,
	},
	{
		garage_id: 112,
		part_id: 137,
		quantity: 48,
	},
	{
		garage_id: 112,
		part_id: 138,
		quantity: 428,
	},
	{
		garage_id: 112,
		part_id: 139,
		quantity: 237,
	},
	{
		garage_id: 112,
		part_id: 140,
		quantity: 54,
	},
	{
		garage_id: 112,
		part_id: 141,
		quantity: 237,
	},
	{
		garage_id: 112,
		part_id: 142,
		quantity: 369,
	},
	{
		garage_id: 112,
		part_id: 143,
		quantity: 32,
	},
	{
		garage_id: 112,
		part_id: 144,
		quantity: 178,
	},
	{
		garage_id: 112,
		part_id: 145,
		quantity: 108,
	},
	{
		garage_id: 112,
		part_id: 146,
		quantity: 486,
	},
	{
		garage_id: 112,
		part_id: 147,
		quantity: 231,
	},
	{
		garage_id: 112,
		part_id: 148,
		quantity: 52,
	},
	{
		garage_id: 112,
		part_id: 149,
		quantity: 156,
	},
	{
		garage_id: 112,
		part_id: 150,
		quantity: 418,
	},
	{
		garage_id: 112,
		part_id: 151,
		quantity: 265,
	},
	{
		garage_id: 112,
		part_id: 152,
		quantity: 449,
	},
	{
		garage_id: 112,
		part_id: 153,
		quantity: 419,
	},
	{
		garage_id: 112,
		part_id: 154,
		quantity: 255,
	},
	{
		garage_id: 112,
		part_id: 155,
		quantity: 488,
	},
	{
		garage_id: 112,
		part_id: 156,
		quantity: 145,
	},
	{
		garage_id: 112,
		part_id: 157,
		quantity: 75,
	},
	{
		garage_id: 112,
		part_id: 158,
		quantity: 243,
	},
	{
		garage_id: 112,
		part_id: 159,
		quantity: 13,
	},
	{
		garage_id: 112,
		part_id: 160,
		quantity: 326,
	},
	{
		garage_id: 112,
		part_id: 161,
		quantity: 193,
	},
	{
		garage_id: 112,
		part_id: 162,
		quantity: 172,
	},
	{
		garage_id: 112,
		part_id: 163,
		quantity: 165,
	},
	{
		garage_id: 112,
		part_id: 164,
		quantity: 51,
	},
	{
		garage_id: 112,
		part_id: 165,
		quantity: 376,
	},
	{
		garage_id: 112,
		part_id: 166,
		quantity: 25,
	},
	{
		garage_id: 112,
		part_id: 167,
		quantity: 343,
	},
	{
		garage_id: 112,
		part_id: 168,
		quantity: 281,
	},
	{
		garage_id: 112,
		part_id: 169,
		quantity: 201,
	},
	{
		garage_id: 112,
		part_id: 170,
		quantity: 352,
	},
	{
		garage_id: 112,
		part_id: 171,
		quantity: 161,
	},
	{
		garage_id: 112,
		part_id: 172,
		quantity: 148,
	},
	{
		garage_id: 112,
		part_id: 173,
		quantity: 248,
	},
	{
		garage_id: 112,
		part_id: 174,
		quantity: 383,
	},
	{
		garage_id: 112,
		part_id: 175,
		quantity: 43,
	},
	{
		garage_id: 112,
		part_id: 176,
		quantity: 178,
	},
	{
		garage_id: 112,
		part_id: 177,
		quantity: 416,
	},
	{
		garage_id: 112,
		part_id: 178,
		quantity: 120,
	},
	{
		garage_id: 112,
		part_id: 179,
		quantity: 332,
	},
	{
		garage_id: 112,
		part_id: 180,
		quantity: 319,
	},
	{
		garage_id: 112,
		part_id: 181,
		quantity: 299,
	},
	{
		garage_id: 112,
		part_id: 182,
		quantity: 169,
	},
	{
		garage_id: 112,
		part_id: 183,
		quantity: 387,
	},
	{
		garage_id: 112,
		part_id: 184,
		quantity: 76,
	},
	{
		garage_id: 112,
		part_id: 185,
		quantity: 210,
	},
	{
		garage_id: 112,
		part_id: 186,
		quantity: 134,
	},
	{
		garage_id: 112,
		part_id: 187,
		quantity: 288,
	},
	{
		garage_id: 112,
		part_id: 188,
		quantity: 437,
	},
	{
		garage_id: 112,
		part_id: 189,
		quantity: 119,
	},
	{
		garage_id: 112,
		part_id: 190,
		quantity: 369,
	},
	{
		garage_id: 112,
		part_id: 191,
		quantity: 114,
	},
	{
		garage_id: 112,
		part_id: 192,
		quantity: 47,
	},
	{
		garage_id: 112,
		part_id: 193,
		quantity: 202,
	},
	{
		garage_id: 112,
		part_id: 194,
		quantity: 196,
	},
	{
		garage_id: 112,
		part_id: 195,
		quantity: 34,
	},
	{
		garage_id: 112,
		part_id: 196,
		quantity: 248,
	},
	{
		garage_id: 112,
		part_id: 197,
		quantity: 341,
	},
	{
		garage_id: 112,
		part_id: 198,
		quantity: 153,
	},
	{
		garage_id: 112,
		part_id: 199,
		quantity: 33,
	},
	{
		garage_id: 112,
		part_id: 200,
		quantity: 434,
	},
	{
		garage_id: 112,
		part_id: 201,
		quantity: 140,
	},
	{
		garage_id: 112,
		part_id: 202,
		quantity: 314,
	},
	{
		garage_id: 112,
		part_id: 203,
		quantity: 339,
	},
	{
		garage_id: 112,
		part_id: 204,
		quantity: 317,
	},
	{
		garage_id: 112,
		part_id: 205,
		quantity: 73,
	},
	{
		garage_id: 112,
		part_id: 206,
		quantity: 152,
	},
	{
		garage_id: 112,
		part_id: 207,
		quantity: 210,
	},
	{
		garage_id: 112,
		part_id: 208,
		quantity: 149,
	},
	{
		garage_id: 112,
		part_id: 209,
		quantity: 394,
	},
	{
		garage_id: 112,
		part_id: 210,
		quantity: 227,
	},
	{
		garage_id: 112,
		part_id: 211,
		quantity: 33,
	},
	{
		garage_id: 112,
		part_id: 212,
		quantity: 71,
	},
	{
		garage_id: 112,
		part_id: 213,
		quantity: 51,
	},
	{
		garage_id: 112,
		part_id: 214,
		quantity: 73,
	},
	{
		garage_id: 112,
		part_id: 215,
		quantity: 107,
	},
	{
		garage_id: 112,
		part_id: 216,
		quantity: 323,
	},
	{
		garage_id: 112,
		part_id: 217,
		quantity: 126,
	},
	{
		garage_id: 112,
		part_id: 218,
		quantity: 216,
	},
	{
		garage_id: 112,
		part_id: 219,
		quantity: 459,
	},
	{
		garage_id: 112,
		part_id: 220,
		quantity: 470,
	},
	{
		garage_id: 112,
		part_id: 221,
		quantity: 221,
	},
	{
		garage_id: 112,
		part_id: 222,
		quantity: 90,
	},
	{
		garage_id: 112,
		part_id: 223,
		quantity: 18,
	},
	{
		garage_id: 112,
		part_id: 224,
		quantity: 476,
	},
	{
		garage_id: 112,
		part_id: 225,
		quantity: 45,
	},
	{
		garage_id: 112,
		part_id: 226,
		quantity: 469,
	},
	{
		garage_id: 112,
		part_id: 227,
		quantity: 235,
	},
	{
		garage_id: 112,
		part_id: 228,
		quantity: 291,
	},
	{
		garage_id: 112,
		part_id: 229,
		quantity: 63,
	},
	{
		garage_id: 112,
		part_id: 230,
		quantity: 443,
	},
	{
		garage_id: 112,
		part_id: 231,
		quantity: 271,
	},
	{
		garage_id: 112,
		part_id: 232,
		quantity: 25,
	},
	{
		garage_id: 112,
		part_id: 233,
		quantity: 311,
	},
	{
		garage_id: 112,
		part_id: 234,
		quantity: 52,
	},
	{
		garage_id: 112,
		part_id: 235,
		quantity: 311,
	},
	{
		garage_id: 112,
		part_id: 236,
		quantity: 364,
	},
	{
		garage_id: 112,
		part_id: 237,
		quantity: 360,
	},
	{
		garage_id: 112,
		part_id: 238,
		quantity: 110,
	},
	{
		garage_id: 112,
		part_id: 239,
		quantity: 324,
	},
	{
		garage_id: 112,
		part_id: 240,
		quantity: 126,
	},
	{
		garage_id: 112,
		part_id: 241,
		quantity: 171,
	},
	{
		garage_id: 112,
		part_id: 242,
		quantity: 416,
	},
	{
		garage_id: 112,
		part_id: 243,
		quantity: 37,
	},
	{
		garage_id: 112,
		part_id: 244,
		quantity: 448,
	},
	{
		garage_id: 112,
		part_id: 245,
		quantity: 201,
	},
	{
		garage_id: 112,
		part_id: 246,
		quantity: 256,
	},
	{
		garage_id: 112,
		part_id: 247,
		quantity: 302,
	},
	{
		garage_id: 112,
		part_id: 248,
		quantity: 252,
	},
	{
		garage_id: 112,
		part_id: 249,
		quantity: 211,
	},
	{
		garage_id: 112,
		part_id: 250,
		quantity: 78,
	},
	{
		garage_id: 112,
		part_id: 251,
		quantity: 234,
	},
	{
		garage_id: 112,
		part_id: 252,
		quantity: 460,
	},
	{
		garage_id: 112,
		part_id: 253,
		quantity: 128,
	},
	{
		garage_id: 112,
		part_id: 254,
		quantity: 267,
	},
	{
		garage_id: 112,
		part_id: 255,
		quantity: 67,
	},
	{
		garage_id: 112,
		part_id: 256,
		quantity: 41,
	},
	{
		garage_id: 112,
		part_id: 257,
		quantity: 269,
	},
	{
		garage_id: 112,
		part_id: 258,
		quantity: 409,
	},
	{
		garage_id: 112,
		part_id: 259,
		quantity: 416,
	},
	{
		garage_id: 112,
		part_id: 260,
		quantity: 263,
	},
	{
		garage_id: 112,
		part_id: 261,
		quantity: 118,
	},
	{
		garage_id: 112,
		part_id: 262,
		quantity: 24,
	},
	{
		garage_id: 112,
		part_id: 263,
		quantity: 151,
	},
	{
		garage_id: 112,
		part_id: 264,
		quantity: 112,
	},
	{
		garage_id: 112,
		part_id: 265,
		quantity: 472,
	},
	{
		garage_id: 112,
		part_id: 266,
		quantity: 459,
	},
	{
		garage_id: 112,
		part_id: 267,
		quantity: 389,
	},
	{
		garage_id: 112,
		part_id: 268,
		quantity: 494,
	},
	{
		garage_id: 112,
		part_id: 269,
		quantity: 61,
	},
	{
		garage_id: 112,
		part_id: 270,
		quantity: 238,
	},
	{
		garage_id: 112,
		part_id: 271,
		quantity: 267,
	},
	{
		garage_id: 112,
		part_id: 272,
		quantity: 221,
	},
	{
		garage_id: 112,
		part_id: 273,
		quantity: 79,
	},
	{
		garage_id: 112,
		part_id: 274,
		quantity: 6,
	},
	{
		garage_id: 112,
		part_id: 275,
		quantity: 218,
	},
	{
		garage_id: 112,
		part_id: 276,
		quantity: 409,
	},
	{
		garage_id: 112,
		part_id: 277,
		quantity: 193,
	},
	{
		garage_id: 112,
		part_id: 278,
		quantity: 55,
	},
	{
		garage_id: 112,
		part_id: 279,
		quantity: 350,
	},
	{
		garage_id: 112,
		part_id: 280,
		quantity: 394,
	},
	{
		garage_id: 112,
		part_id: 281,
		quantity: 358,
	},
	{
		garage_id: 112,
		part_id: 282,
		quantity: 85,
	},
	{
		garage_id: 112,
		part_id: 283,
		quantity: 197,
	},
	{
		garage_id: 112,
		part_id: 284,
		quantity: 284,
	},
	{
		garage_id: 112,
		part_id: 285,
		quantity: 466,
	},
	{
		garage_id: 112,
		part_id: 286,
		quantity: 399,
	},
	{
		garage_id: 112,
		part_id: 287,
		quantity: 429,
	},
	{
		garage_id: 112,
		part_id: 288,
		quantity: 54,
	},
	{
		garage_id: 112,
		part_id: 289,
		quantity: 405,
	},
	{
		garage_id: 112,
		part_id: 290,
		quantity: 402,
	},
	{
		garage_id: 112,
		part_id: 291,
		quantity: 481,
	},
	{
		garage_id: 112,
		part_id: 292,
		quantity: 42,
	},
	{
		garage_id: 112,
		part_id: 293,
		quantity: 99,
	},
	{
		garage_id: 112,
		part_id: 294,
		quantity: 248,
	},
	{
		garage_id: 112,
		part_id: 295,
		quantity: 481,
	},
	{
		garage_id: 112,
		part_id: 296,
		quantity: 216,
	},
	{
		garage_id: 112,
		part_id: 297,
		quantity: 365,
	},
	{
		garage_id: 112,
		part_id: 298,
		quantity: 236,
	},
	{
		garage_id: 112,
		part_id: 299,
		quantity: 120,
	},
	{
		garage_id: 112,
		part_id: 300,
		quantity: 60,
	},
	{
		garage_id: 112,
		part_id: 301,
		quantity: 342,
	},
	{
		garage_id: 112,
		part_id: 302,
		quantity: 227,
	},
	{
		garage_id: 112,
		part_id: 303,
		quantity: 325,
	},
	{
		garage_id: 112,
		part_id: 304,
		quantity: 303,
	},
	{
		garage_id: 112,
		part_id: 305,
		quantity: 99,
	},
	{
		garage_id: 112,
		part_id: 306,
		quantity: 359,
	},
	{
		garage_id: 112,
		part_id: 307,
		quantity: 100,
	},
	{
		garage_id: 112,
		part_id: 308,
		quantity: 159,
	},
	{
		garage_id: 112,
		part_id: 309,
		quantity: 397,
	},
	{
		garage_id: 112,
		part_id: 310,
		quantity: 478,
	},
	{
		garage_id: 112,
		part_id: 311,
		quantity: 372,
	},
	{
		garage_id: 112,
		part_id: 312,
		quantity: 400,
	},
	{
		garage_id: 112,
		part_id: 313,
		quantity: 329,
	},
	{
		garage_id: 112,
		part_id: 314,
		quantity: 425,
	},
	{
		garage_id: 112,
		part_id: 315,
		quantity: 319,
	},
	{
		garage_id: 112,
		part_id: 316,
		quantity: 174,
	},
	{
		garage_id: 112,
		part_id: 317,
		quantity: 18,
	},
	{
		garage_id: 112,
		part_id: 318,
		quantity: 99,
	},
	{
		garage_id: 112,
		part_id: 319,
		quantity: 324,
	},
	{
		garage_id: 112,
		part_id: 320,
		quantity: 252,
	},
	{
		garage_id: 112,
		part_id: 321,
		quantity: 185,
	},
	{
		garage_id: 112,
		part_id: 322,
		quantity: 129,
	},
	{
		garage_id: 112,
		part_id: 323,
		quantity: 230,
	},
	{
		garage_id: 112,
		part_id: 324,
		quantity: 223,
	},
	{
		garage_id: 112,
		part_id: 325,
		quantity: 287,
	},
	{
		garage_id: 112,
		part_id: 326,
		quantity: 168,
	},
	{
		garage_id: 112,
		part_id: 327,
		quantity: 320,
	},
	{
		garage_id: 112,
		part_id: 328,
		quantity: 294,
	},
	{
		garage_id: 112,
		part_id: 329,
		quantity: 8,
	},
	{
		garage_id: 112,
		part_id: 330,
		quantity: 52,
	},
	{
		garage_id: 112,
		part_id: 331,
		quantity: 143,
	},
	{
		garage_id: 112,
		part_id: 332,
		quantity: 417,
	},
	{
		garage_id: 112,
		part_id: 333,
		quantity: 283,
	},
	{
		garage_id: 112,
		part_id: 334,
		quantity: 150,
	},
	{
		garage_id: 112,
		part_id: 335,
		quantity: 180,
	},
	{
		garage_id: 112,
		part_id: 336,
		quantity: 478,
	},
	{
		garage_id: 112,
		part_id: 337,
		quantity: 91,
	},
	{
		garage_id: 112,
		part_id: 338,
		quantity: 58,
	},
	{
		garage_id: 112,
		part_id: 339,
		quantity: 222,
	},
	{
		garage_id: 112,
		part_id: 340,
		quantity: 221,
	},
	{
		garage_id: 112,
		part_id: 341,
		quantity: 54,
	},
	{
		garage_id: 112,
		part_id: 342,
		quantity: 65,
	},
	{
		garage_id: 112,
		part_id: 343,
		quantity: 369,
	},
	{
		garage_id: 112,
		part_id: 344,
		quantity: 341,
	},
	{
		garage_id: 112,
		part_id: 345,
		quantity: 93,
	},
	{
		garage_id: 112,
		part_id: 346,
		quantity: 108,
	},
	{
		garage_id: 112,
		part_id: 347,
		quantity: 64,
	},
	{
		garage_id: 112,
		part_id: 348,
		quantity: 482,
	},
	{
		garage_id: 112,
		part_id: 349,
		quantity: 209,
	},
	{
		garage_id: 112,
		part_id: 350,
		quantity: 275,
	},
	{
		garage_id: 112,
		part_id: 351,
		quantity: 380,
	},
	{
		garage_id: 112,
		part_id: 352,
		quantity: 432,
	},
	{
		garage_id: 112,
		part_id: 353,
		quantity: 254,
	},
	{
		garage_id: 112,
		part_id: 354,
		quantity: 284,
	},
	{
		garage_id: 112,
		part_id: 355,
		quantity: 437,
	},
	{
		garage_id: 112,
		part_id: 356,
		quantity: 399,
	},
	{
		garage_id: 112,
		part_id: 357,
		quantity: 207,
	},
	{
		garage_id: 112,
		part_id: 358,
		quantity: 351,
	},
	{
		garage_id: 112,
		part_id: 359,
		quantity: 66,
	},
	{
		garage_id: 112,
		part_id: 360,
		quantity: 77,
	},
	{
		garage_id: 112,
		part_id: 361,
		quantity: 30,
	},
	{
		garage_id: 112,
		part_id: 362,
		quantity: 220,
	},
	{
		garage_id: 112,
		part_id: 363,
		quantity: 61,
	},
	{
		garage_id: 112,
		part_id: 364,
		quantity: 159,
	},
	{
		garage_id: 112,
		part_id: 365,
		quantity: 32,
	},
	{
		garage_id: 112,
		part_id: 366,
		quantity: 104,
	},
	{
		garage_id: 112,
		part_id: 367,
		quantity: 487,
	},
	{
		garage_id: 112,
		part_id: 368,
		quantity: 424,
	},
	{
		garage_id: 112,
		part_id: 369,
		quantity: 45,
	},
	{
		garage_id: 112,
		part_id: 370,
		quantity: 216,
	},
	{
		garage_id: 112,
		part_id: 371,
		quantity: 154,
	},
	{
		garage_id: 112,
		part_id: 372,
		quantity: 473,
	},
	{
		garage_id: 112,
		part_id: 373,
		quantity: 94,
	},
	{
		garage_id: 112,
		part_id: 374,
		quantity: 84,
	},
	{
		garage_id: 112,
		part_id: 375,
		quantity: 16,
	},
	{
		garage_id: 112,
		part_id: 376,
		quantity: 422,
	},
	{
		garage_id: 112,
		part_id: 377,
		quantity: 494,
	},
	{
		garage_id: 112,
		part_id: 378,
		quantity: 420,
	},
	{
		garage_id: 112,
		part_id: 379,
		quantity: 214,
	},
	{
		garage_id: 112,
		part_id: 380,
		quantity: 431,
	},
	{
		garage_id: 112,
		part_id: 381,
		quantity: 316,
	},
	{
		garage_id: 112,
		part_id: 382,
		quantity: 38,
	},
	{
		garage_id: 112,
		part_id: 383,
		quantity: 251,
	},
	{
		garage_id: 112,
		part_id: 384,
		quantity: 52,
	},
	{
		garage_id: 112,
		part_id: 385,
		quantity: 59,
	},
	{
		garage_id: 112,
		part_id: 386,
		quantity: 11,
	},
	{
		garage_id: 112,
		part_id: 387,
		quantity: 222,
	},
	{
		garage_id: 112,
		part_id: 388,
		quantity: 104,
	},
	{
		garage_id: 112,
		part_id: 389,
		quantity: 23,
	},
	{
		garage_id: 112,
		part_id: 390,
		quantity: 129,
	},
	{
		garage_id: 112,
		part_id: 391,
		quantity: 392,
	},
	{
		garage_id: 112,
		part_id: 392,
		quantity: 384,
	},
	{
		garage_id: 112,
		part_id: 393,
		quantity: 446,
	},
	{
		garage_id: 112,
		part_id: 394,
		quantity: 388,
	},
	{
		garage_id: 112,
		part_id: 395,
		quantity: 1,
	},
	{
		garage_id: 112,
		part_id: 396,
		quantity: 251,
	},
	{
		garage_id: 112,
		part_id: 397,
		quantity: 309,
	},
	{
		garage_id: 112,
		part_id: 398,
		quantity: 210,
	},
	{
		garage_id: 112,
		part_id: 399,
		quantity: 115,
	},
	{
		garage_id: 113,
		part_id: 0,
		quantity: 260,
	},
	{
		garage_id: 113,
		part_id: 1,
		quantity: 167,
	},
	{
		garage_id: 113,
		part_id: 2,
		quantity: 286,
	},
	{
		garage_id: 113,
		part_id: 3,
		quantity: 125,
	},
	{
		garage_id: 113,
		part_id: 4,
		quantity: 274,
	},
	{
		garage_id: 113,
		part_id: 5,
		quantity: 311,
	},
	{
		garage_id: 113,
		part_id: 6,
		quantity: 286,
	},
	{
		garage_id: 113,
		part_id: 7,
		quantity: 125,
	},
	{
		garage_id: 113,
		part_id: 8,
		quantity: 96,
	},
	{
		garage_id: 113,
		part_id: 9,
		quantity: 473,
	},
	{
		garage_id: 113,
		part_id: 10,
		quantity: 105,
	},
	{
		garage_id: 113,
		part_id: 11,
		quantity: 151,
	},
	{
		garage_id: 113,
		part_id: 12,
		quantity: 52,
	},
	{
		garage_id: 113,
		part_id: 13,
		quantity: 340,
	},
	{
		garage_id: 113,
		part_id: 14,
		quantity: 285,
	},
	{
		garage_id: 113,
		part_id: 15,
		quantity: 415,
	},
	{
		garage_id: 113,
		part_id: 16,
		quantity: 226,
	},
	{
		garage_id: 113,
		part_id: 17,
		quantity: 189,
	},
	{
		garage_id: 113,
		part_id: 18,
		quantity: 8,
	},
	{
		garage_id: 113,
		part_id: 19,
		quantity: 447,
	},
	{
		garage_id: 113,
		part_id: 20,
		quantity: 146,
	},
	{
		garage_id: 113,
		part_id: 21,
		quantity: 110,
	},
	{
		garage_id: 113,
		part_id: 22,
		quantity: 178,
	},
	{
		garage_id: 113,
		part_id: 23,
		quantity: 426,
	},
	{
		garage_id: 113,
		part_id: 24,
		quantity: 452,
	},
	{
		garage_id: 113,
		part_id: 25,
		quantity: 243,
	},
	{
		garage_id: 113,
		part_id: 26,
		quantity: 59,
	},
	{
		garage_id: 113,
		part_id: 27,
		quantity: 156,
	},
	{
		garage_id: 113,
		part_id: 28,
		quantity: 1,
	},
	{
		garage_id: 113,
		part_id: 29,
		quantity: 61,
	},
	{
		garage_id: 113,
		part_id: 30,
		quantity: 240,
	},
	{
		garage_id: 113,
		part_id: 31,
		quantity: 119,
	},
	{
		garage_id: 113,
		part_id: 32,
		quantity: 58,
	},
	{
		garage_id: 113,
		part_id: 33,
		quantity: 449,
	},
	{
		garage_id: 113,
		part_id: 34,
		quantity: 459,
	},
	{
		garage_id: 113,
		part_id: 35,
		quantity: 465,
	},
	{
		garage_id: 113,
		part_id: 36,
		quantity: 15,
	},
	{
		garage_id: 113,
		part_id: 37,
		quantity: 413,
	},
	{
		garage_id: 113,
		part_id: 38,
		quantity: 225,
	},
	{
		garage_id: 113,
		part_id: 39,
		quantity: 109,
	},
	{
		garage_id: 113,
		part_id: 40,
		quantity: 18,
	},
	{
		garage_id: 113,
		part_id: 41,
		quantity: 139,
	},
	{
		garage_id: 113,
		part_id: 42,
		quantity: 403,
	},
	{
		garage_id: 113,
		part_id: 43,
		quantity: 389,
	},
	{
		garage_id: 113,
		part_id: 44,
		quantity: 50,
	},
	{
		garage_id: 113,
		part_id: 45,
		quantity: 364,
	},
	{
		garage_id: 113,
		part_id: 46,
		quantity: 154,
	},
	{
		garage_id: 113,
		part_id: 47,
		quantity: 223,
	},
	{
		garage_id: 113,
		part_id: 48,
		quantity: 309,
	},
	{
		garage_id: 113,
		part_id: 49,
		quantity: 407,
	},
	{
		garage_id: 113,
		part_id: 50,
		quantity: 67,
	},
	{
		garage_id: 113,
		part_id: 51,
		quantity: 354,
	},
	{
		garage_id: 113,
		part_id: 52,
		quantity: 472,
	},
	{
		garage_id: 113,
		part_id: 53,
		quantity: 310,
	},
	{
		garage_id: 113,
		part_id: 54,
		quantity: 310,
	},
	{
		garage_id: 113,
		part_id: 55,
		quantity: 67,
	},
	{
		garage_id: 113,
		part_id: 56,
		quantity: 130,
	},
	{
		garage_id: 113,
		part_id: 57,
		quantity: 150,
	},
	{
		garage_id: 113,
		part_id: 58,
		quantity: 363,
	},
	{
		garage_id: 113,
		part_id: 59,
		quantity: 349,
	},
	{
		garage_id: 113,
		part_id: 60,
		quantity: 7,
	},
	{
		garage_id: 113,
		part_id: 61,
		quantity: 48,
	},
	{
		garage_id: 113,
		part_id: 62,
		quantity: 200,
	},
	{
		garage_id: 113,
		part_id: 63,
		quantity: 324,
	},
	{
		garage_id: 113,
		part_id: 64,
		quantity: 242,
	},
	{
		garage_id: 113,
		part_id: 65,
		quantity: 129,
	},
	{
		garage_id: 113,
		part_id: 66,
		quantity: 475,
	},
	{
		garage_id: 113,
		part_id: 67,
		quantity: 388,
	},
	{
		garage_id: 113,
		part_id: 68,
		quantity: 56,
	},
	{
		garage_id: 113,
		part_id: 69,
		quantity: 89,
	},
	{
		garage_id: 113,
		part_id: 70,
		quantity: 69,
	},
	{
		garage_id: 113,
		part_id: 71,
		quantity: 422,
	},
	{
		garage_id: 113,
		part_id: 72,
		quantity: 462,
	},
	{
		garage_id: 113,
		part_id: 73,
		quantity: 138,
	},
	{
		garage_id: 113,
		part_id: 74,
		quantity: 460,
	},
	{
		garage_id: 113,
		part_id: 75,
		quantity: 367,
	},
	{
		garage_id: 113,
		part_id: 76,
		quantity: 282,
	},
	{
		garage_id: 113,
		part_id: 77,
		quantity: 223,
	},
	{
		garage_id: 113,
		part_id: 78,
		quantity: 143,
	},
	{
		garage_id: 113,
		part_id: 79,
		quantity: 13,
	},
	{
		garage_id: 113,
		part_id: 80,
		quantity: 311,
	},
	{
		garage_id: 113,
		part_id: 81,
		quantity: 126,
	},
	{
		garage_id: 113,
		part_id: 82,
		quantity: 302,
	},
	{
		garage_id: 113,
		part_id: 83,
		quantity: 133,
	},
	{
		garage_id: 113,
		part_id: 84,
		quantity: 68,
	},
	{
		garage_id: 113,
		part_id: 85,
		quantity: 171,
	},
	{
		garage_id: 113,
		part_id: 86,
		quantity: 41,
	},
	{
		garage_id: 113,
		part_id: 87,
		quantity: 242,
	},
	{
		garage_id: 113,
		part_id: 88,
		quantity: 145,
	},
	{
		garage_id: 113,
		part_id: 89,
		quantity: 113,
	},
	{
		garage_id: 113,
		part_id: 90,
		quantity: 80,
	},
	{
		garage_id: 113,
		part_id: 91,
		quantity: 27,
	},
	{
		garage_id: 113,
		part_id: 92,
		quantity: 149,
	},
	{
		garage_id: 113,
		part_id: 93,
		quantity: 240,
	},
	{
		garage_id: 113,
		part_id: 94,
		quantity: 213,
	},
	{
		garage_id: 113,
		part_id: 95,
		quantity: 99,
	},
	{
		garage_id: 113,
		part_id: 96,
		quantity: 140,
	},
	{
		garage_id: 113,
		part_id: 97,
		quantity: 178,
	},
	{
		garage_id: 113,
		part_id: 98,
		quantity: 459,
	},
	{
		garage_id: 113,
		part_id: 99,
		quantity: 165,
	},
	{
		garage_id: 113,
		part_id: 100,
		quantity: 238,
	},
	{
		garage_id: 113,
		part_id: 101,
		quantity: 103,
	},
	{
		garage_id: 113,
		part_id: 102,
		quantity: 477,
	},
	{
		garage_id: 113,
		part_id: 103,
		quantity: 278,
	},
	{
		garage_id: 113,
		part_id: 104,
		quantity: 229,
	},
	{
		garage_id: 113,
		part_id: 105,
		quantity: 145,
	},
	{
		garage_id: 113,
		part_id: 106,
		quantity: 66,
	},
	{
		garage_id: 113,
		part_id: 107,
		quantity: 456,
	},
	{
		garage_id: 113,
		part_id: 108,
		quantity: 348,
	},
	{
		garage_id: 113,
		part_id: 109,
		quantity: 59,
	},
	{
		garage_id: 113,
		part_id: 110,
		quantity: 245,
	},
	{
		garage_id: 113,
		part_id: 111,
		quantity: 393,
	},
	{
		garage_id: 113,
		part_id: 112,
		quantity: 184,
	},
	{
		garage_id: 113,
		part_id: 113,
		quantity: 30,
	},
	{
		garage_id: 113,
		part_id: 114,
		quantity: 20,
	},
	{
		garage_id: 113,
		part_id: 115,
		quantity: 433,
	},
	{
		garage_id: 113,
		part_id: 116,
		quantity: 178,
	},
	{
		garage_id: 113,
		part_id: 117,
		quantity: 338,
	},
	{
		garage_id: 113,
		part_id: 118,
		quantity: 215,
	},
	{
		garage_id: 113,
		part_id: 119,
		quantity: 459,
	},
	{
		garage_id: 113,
		part_id: 120,
		quantity: 276,
	},
	{
		garage_id: 113,
		part_id: 121,
		quantity: 301,
	},
	{
		garage_id: 113,
		part_id: 122,
		quantity: 214,
	},
	{
		garage_id: 113,
		part_id: 123,
		quantity: 413,
	},
	{
		garage_id: 113,
		part_id: 124,
		quantity: 204,
	},
	{
		garage_id: 113,
		part_id: 125,
		quantity: 327,
	},
	{
		garage_id: 113,
		part_id: 126,
		quantity: 442,
	},
	{
		garage_id: 113,
		part_id: 127,
		quantity: 251,
	},
	{
		garage_id: 113,
		part_id: 128,
		quantity: 67,
	},
	{
		garage_id: 113,
		part_id: 129,
		quantity: 324,
	},
	{
		garage_id: 113,
		part_id: 130,
		quantity: 326,
	},
	{
		garage_id: 113,
		part_id: 131,
		quantity: 353,
	},
	{
		garage_id: 113,
		part_id: 132,
		quantity: 434,
	},
	{
		garage_id: 113,
		part_id: 133,
		quantity: 159,
	},
	{
		garage_id: 113,
		part_id: 134,
		quantity: 305,
	},
	{
		garage_id: 113,
		part_id: 135,
		quantity: 285,
	},
	{
		garage_id: 113,
		part_id: 136,
		quantity: 486,
	},
	{
		garage_id: 113,
		part_id: 137,
		quantity: 0,
	},
	{
		garage_id: 113,
		part_id: 138,
		quantity: 203,
	},
	{
		garage_id: 113,
		part_id: 139,
		quantity: 385,
	},
	{
		garage_id: 113,
		part_id: 140,
		quantity: 492,
	},
	{
		garage_id: 113,
		part_id: 141,
		quantity: 99,
	},
	{
		garage_id: 113,
		part_id: 142,
		quantity: 313,
	},
	{
		garage_id: 113,
		part_id: 143,
		quantity: 126,
	},
	{
		garage_id: 113,
		part_id: 144,
		quantity: 252,
	},
	{
		garage_id: 113,
		part_id: 145,
		quantity: 295,
	},
	{
		garage_id: 113,
		part_id: 146,
		quantity: 194,
	},
	{
		garage_id: 113,
		part_id: 147,
		quantity: 360,
	},
	{
		garage_id: 113,
		part_id: 148,
		quantity: 142,
	},
	{
		garage_id: 113,
		part_id: 149,
		quantity: 454,
	},
	{
		garage_id: 113,
		part_id: 150,
		quantity: 185,
	},
	{
		garage_id: 113,
		part_id: 151,
		quantity: 206,
	},
	{
		garage_id: 113,
		part_id: 152,
		quantity: 332,
	},
	{
		garage_id: 113,
		part_id: 153,
		quantity: 157,
	},
	{
		garage_id: 113,
		part_id: 154,
		quantity: 395,
	},
	{
		garage_id: 113,
		part_id: 155,
		quantity: 36,
	},
	{
		garage_id: 113,
		part_id: 156,
		quantity: 412,
	},
	{
		garage_id: 113,
		part_id: 157,
		quantity: 65,
	},
	{
		garage_id: 113,
		part_id: 158,
		quantity: 42,
	},
	{
		garage_id: 113,
		part_id: 159,
		quantity: 82,
	},
	{
		garage_id: 113,
		part_id: 160,
		quantity: 448,
	},
	{
		garage_id: 113,
		part_id: 161,
		quantity: 125,
	},
	{
		garage_id: 113,
		part_id: 162,
		quantity: 480,
	},
	{
		garage_id: 113,
		part_id: 163,
		quantity: 261,
	},
	{
		garage_id: 113,
		part_id: 164,
		quantity: 301,
	},
	{
		garage_id: 113,
		part_id: 165,
		quantity: 303,
	},
	{
		garage_id: 113,
		part_id: 166,
		quantity: 449,
	},
	{
		garage_id: 113,
		part_id: 167,
		quantity: 228,
	},
	{
		garage_id: 113,
		part_id: 168,
		quantity: 7,
	},
	{
		garage_id: 113,
		part_id: 169,
		quantity: 402,
	},
	{
		garage_id: 113,
		part_id: 170,
		quantity: 313,
	},
	{
		garage_id: 113,
		part_id: 171,
		quantity: 278,
	},
	{
		garage_id: 113,
		part_id: 172,
		quantity: 367,
	},
	{
		garage_id: 113,
		part_id: 173,
		quantity: 455,
	},
	{
		garage_id: 113,
		part_id: 174,
		quantity: 263,
	},
	{
		garage_id: 113,
		part_id: 175,
		quantity: 234,
	},
	{
		garage_id: 113,
		part_id: 176,
		quantity: 4,
	},
	{
		garage_id: 113,
		part_id: 177,
		quantity: 469,
	},
	{
		garage_id: 113,
		part_id: 178,
		quantity: 99,
	},
	{
		garage_id: 113,
		part_id: 179,
		quantity: 150,
	},
	{
		garage_id: 113,
		part_id: 180,
		quantity: 385,
	},
	{
		garage_id: 113,
		part_id: 181,
		quantity: 150,
	},
	{
		garage_id: 113,
		part_id: 182,
		quantity: 268,
	},
	{
		garage_id: 113,
		part_id: 183,
		quantity: 349,
	},
	{
		garage_id: 113,
		part_id: 184,
		quantity: 462,
	},
	{
		garage_id: 113,
		part_id: 185,
		quantity: 106,
	},
	{
		garage_id: 113,
		part_id: 186,
		quantity: 242,
	},
	{
		garage_id: 113,
		part_id: 187,
		quantity: 33,
	},
	{
		garage_id: 113,
		part_id: 188,
		quantity: 61,
	},
	{
		garage_id: 113,
		part_id: 189,
		quantity: 393,
	},
	{
		garage_id: 113,
		part_id: 190,
		quantity: 315,
	},
	{
		garage_id: 113,
		part_id: 191,
		quantity: 448,
	},
	{
		garage_id: 113,
		part_id: 192,
		quantity: 486,
	},
	{
		garage_id: 113,
		part_id: 193,
		quantity: 198,
	},
	{
		garage_id: 113,
		part_id: 194,
		quantity: 416,
	},
	{
		garage_id: 113,
		part_id: 195,
		quantity: 314,
	},
	{
		garage_id: 113,
		part_id: 196,
		quantity: 169,
	},
	{
		garage_id: 113,
		part_id: 197,
		quantity: 316,
	},
	{
		garage_id: 113,
		part_id: 198,
		quantity: 147,
	},
	{
		garage_id: 113,
		part_id: 199,
		quantity: 43,
	},
	{
		garage_id: 113,
		part_id: 200,
		quantity: 96,
	},
	{
		garage_id: 113,
		part_id: 201,
		quantity: 374,
	},
	{
		garage_id: 113,
		part_id: 202,
		quantity: 65,
	},
	{
		garage_id: 113,
		part_id: 203,
		quantity: 442,
	},
	{
		garage_id: 113,
		part_id: 204,
		quantity: 226,
	},
	{
		garage_id: 113,
		part_id: 205,
		quantity: 371,
	},
	{
		garage_id: 113,
		part_id: 206,
		quantity: 122,
	},
	{
		garage_id: 113,
		part_id: 207,
		quantity: 25,
	},
	{
		garage_id: 113,
		part_id: 208,
		quantity: 309,
	},
	{
		garage_id: 113,
		part_id: 209,
		quantity: 109,
	},
	{
		garage_id: 113,
		part_id: 210,
		quantity: 121,
	},
	{
		garage_id: 113,
		part_id: 211,
		quantity: 206,
	},
	{
		garage_id: 113,
		part_id: 212,
		quantity: 38,
	},
	{
		garage_id: 113,
		part_id: 213,
		quantity: 415,
	},
	{
		garage_id: 113,
		part_id: 214,
		quantity: 48,
	},
	{
		garage_id: 113,
		part_id: 215,
		quantity: 334,
	},
	{
		garage_id: 113,
		part_id: 216,
		quantity: 378,
	},
	{
		garage_id: 113,
		part_id: 217,
		quantity: 364,
	},
	{
		garage_id: 113,
		part_id: 218,
		quantity: 154,
	},
	{
		garage_id: 113,
		part_id: 219,
		quantity: 242,
	},
	{
		garage_id: 113,
		part_id: 220,
		quantity: 312,
	},
	{
		garage_id: 113,
		part_id: 221,
		quantity: 361,
	},
	{
		garage_id: 113,
		part_id: 222,
		quantity: 300,
	},
	{
		garage_id: 113,
		part_id: 223,
		quantity: 150,
	},
	{
		garage_id: 113,
		part_id: 224,
		quantity: 478,
	},
	{
		garage_id: 113,
		part_id: 225,
		quantity: 351,
	},
	{
		garage_id: 113,
		part_id: 226,
		quantity: 172,
	},
	{
		garage_id: 113,
		part_id: 227,
		quantity: 304,
	},
	{
		garage_id: 113,
		part_id: 228,
		quantity: 175,
	},
	{
		garage_id: 113,
		part_id: 229,
		quantity: 171,
	},
	{
		garage_id: 113,
		part_id: 230,
		quantity: 345,
	},
	{
		garage_id: 113,
		part_id: 231,
		quantity: 306,
	},
	{
		garage_id: 113,
		part_id: 232,
		quantity: 131,
	},
	{
		garage_id: 113,
		part_id: 233,
		quantity: 16,
	},
	{
		garage_id: 113,
		part_id: 234,
		quantity: 261,
	},
	{
		garage_id: 113,
		part_id: 235,
		quantity: 143,
	},
	{
		garage_id: 113,
		part_id: 236,
		quantity: 498,
	},
	{
		garage_id: 113,
		part_id: 237,
		quantity: 381,
	},
	{
		garage_id: 113,
		part_id: 238,
		quantity: 59,
	},
	{
		garage_id: 113,
		part_id: 239,
		quantity: 269,
	},
	{
		garage_id: 113,
		part_id: 240,
		quantity: 43,
	},
	{
		garage_id: 113,
		part_id: 241,
		quantity: 8,
	},
	{
		garage_id: 113,
		part_id: 242,
		quantity: 430,
	},
	{
		garage_id: 113,
		part_id: 243,
		quantity: 255,
	},
	{
		garage_id: 113,
		part_id: 244,
		quantity: 0,
	},
	{
		garage_id: 113,
		part_id: 245,
		quantity: 465,
	},
	{
		garage_id: 113,
		part_id: 246,
		quantity: 347,
	},
	{
		garage_id: 113,
		part_id: 247,
		quantity: 9,
	},
	{
		garage_id: 113,
		part_id: 248,
		quantity: 55,
	},
	{
		garage_id: 113,
		part_id: 249,
		quantity: 99,
	},
	{
		garage_id: 113,
		part_id: 250,
		quantity: 57,
	},
	{
		garage_id: 113,
		part_id: 251,
		quantity: 93,
	},
	{
		garage_id: 113,
		part_id: 252,
		quantity: 484,
	},
	{
		garage_id: 113,
		part_id: 253,
		quantity: 205,
	},
	{
		garage_id: 113,
		part_id: 254,
		quantity: 28,
	},
	{
		garage_id: 113,
		part_id: 255,
		quantity: 243,
	},
	{
		garage_id: 113,
		part_id: 256,
		quantity: 103,
	},
	{
		garage_id: 113,
		part_id: 257,
		quantity: 485,
	},
	{
		garage_id: 113,
		part_id: 258,
		quantity: 146,
	},
	{
		garage_id: 113,
		part_id: 259,
		quantity: 132,
	},
	{
		garage_id: 113,
		part_id: 260,
		quantity: 375,
	},
	{
		garage_id: 113,
		part_id: 261,
		quantity: 378,
	},
	{
		garage_id: 113,
		part_id: 262,
		quantity: 123,
	},
	{
		garage_id: 113,
		part_id: 263,
		quantity: 63,
	},
	{
		garage_id: 113,
		part_id: 264,
		quantity: 356,
	},
	{
		garage_id: 113,
		part_id: 265,
		quantity: 327,
	},
	{
		garage_id: 113,
		part_id: 266,
		quantity: 409,
	},
	{
		garage_id: 113,
		part_id: 267,
		quantity: 392,
	},
	{
		garage_id: 113,
		part_id: 268,
		quantity: 406,
	},
	{
		garage_id: 113,
		part_id: 269,
		quantity: 327,
	},
	{
		garage_id: 113,
		part_id: 270,
		quantity: 79,
	},
	{
		garage_id: 113,
		part_id: 271,
		quantity: 42,
	},
	{
		garage_id: 113,
		part_id: 272,
		quantity: 45,
	},
	{
		garage_id: 113,
		part_id: 273,
		quantity: 168,
	},
	{
		garage_id: 113,
		part_id: 274,
		quantity: 409,
	},
	{
		garage_id: 113,
		part_id: 275,
		quantity: 449,
	},
	{
		garage_id: 113,
		part_id: 276,
		quantity: 436,
	},
	{
		garage_id: 113,
		part_id: 277,
		quantity: 195,
	},
	{
		garage_id: 113,
		part_id: 278,
		quantity: 435,
	},
	{
		garage_id: 113,
		part_id: 279,
		quantity: 265,
	},
	{
		garage_id: 113,
		part_id: 280,
		quantity: 217,
	},
	{
		garage_id: 113,
		part_id: 281,
		quantity: 257,
	},
	{
		garage_id: 113,
		part_id: 282,
		quantity: 116,
	},
	{
		garage_id: 113,
		part_id: 283,
		quantity: 285,
	},
	{
		garage_id: 113,
		part_id: 284,
		quantity: 472,
	},
	{
		garage_id: 113,
		part_id: 285,
		quantity: 438,
	},
	{
		garage_id: 113,
		part_id: 286,
		quantity: 446,
	},
	{
		garage_id: 113,
		part_id: 287,
		quantity: 160,
	},
	{
		garage_id: 113,
		part_id: 288,
		quantity: 309,
	},
	{
		garage_id: 113,
		part_id: 289,
		quantity: 15,
	},
	{
		garage_id: 113,
		part_id: 290,
		quantity: 305,
	},
	{
		garage_id: 113,
		part_id: 291,
		quantity: 388,
	},
	{
		garage_id: 113,
		part_id: 292,
		quantity: 40,
	},
	{
		garage_id: 113,
		part_id: 293,
		quantity: 182,
	},
	{
		garage_id: 113,
		part_id: 294,
		quantity: 287,
	},
	{
		garage_id: 113,
		part_id: 295,
		quantity: 375,
	},
	{
		garage_id: 113,
		part_id: 296,
		quantity: 44,
	},
	{
		garage_id: 113,
		part_id: 297,
		quantity: 222,
	},
	{
		garage_id: 113,
		part_id: 298,
		quantity: 6,
	},
	{
		garage_id: 113,
		part_id: 299,
		quantity: 219,
	},
	{
		garage_id: 113,
		part_id: 300,
		quantity: 100,
	},
	{
		garage_id: 113,
		part_id: 301,
		quantity: 213,
	},
	{
		garage_id: 113,
		part_id: 302,
		quantity: 4,
	},
	{
		garage_id: 113,
		part_id: 303,
		quantity: 457,
	},
	{
		garage_id: 113,
		part_id: 304,
		quantity: 227,
	},
	{
		garage_id: 113,
		part_id: 305,
		quantity: 234,
	},
	{
		garage_id: 113,
		part_id: 306,
		quantity: 449,
	},
	{
		garage_id: 113,
		part_id: 307,
		quantity: 240,
	},
	{
		garage_id: 113,
		part_id: 308,
		quantity: 115,
	},
	{
		garage_id: 113,
		part_id: 309,
		quantity: 466,
	},
	{
		garage_id: 113,
		part_id: 310,
		quantity: 355,
	},
	{
		garage_id: 113,
		part_id: 311,
		quantity: 349,
	},
	{
		garage_id: 113,
		part_id: 312,
		quantity: 144,
	},
	{
		garage_id: 113,
		part_id: 313,
		quantity: 281,
	},
	{
		garage_id: 113,
		part_id: 314,
		quantity: 87,
	},
	{
		garage_id: 113,
		part_id: 315,
		quantity: 336,
	},
	{
		garage_id: 113,
		part_id: 316,
		quantity: 87,
	},
	{
		garage_id: 113,
		part_id: 317,
		quantity: 54,
	},
	{
		garage_id: 113,
		part_id: 318,
		quantity: 321,
	},
	{
		garage_id: 113,
		part_id: 319,
		quantity: 157,
	},
	{
		garage_id: 113,
		part_id: 320,
		quantity: 206,
	},
	{
		garage_id: 113,
		part_id: 321,
		quantity: 354,
	},
	{
		garage_id: 113,
		part_id: 322,
		quantity: 343,
	},
	{
		garage_id: 113,
		part_id: 323,
		quantity: 405,
	},
	{
		garage_id: 113,
		part_id: 324,
		quantity: 425,
	},
	{
		garage_id: 113,
		part_id: 325,
		quantity: 249,
	},
	{
		garage_id: 113,
		part_id: 326,
		quantity: 13,
	},
	{
		garage_id: 113,
		part_id: 327,
		quantity: 148,
	},
	{
		garage_id: 113,
		part_id: 328,
		quantity: 177,
	},
	{
		garage_id: 113,
		part_id: 329,
		quantity: 130,
	},
	{
		garage_id: 113,
		part_id: 330,
		quantity: 325,
	},
	{
		garage_id: 113,
		part_id: 331,
		quantity: 399,
	},
	{
		garage_id: 113,
		part_id: 332,
		quantity: 343,
	},
	{
		garage_id: 113,
		part_id: 333,
		quantity: 156,
	},
	{
		garage_id: 113,
		part_id: 334,
		quantity: 133,
	},
	{
		garage_id: 113,
		part_id: 335,
		quantity: 23,
	},
	{
		garage_id: 113,
		part_id: 336,
		quantity: 390,
	},
	{
		garage_id: 113,
		part_id: 337,
		quantity: 272,
	},
	{
		garage_id: 113,
		part_id: 338,
		quantity: 133,
	},
	{
		garage_id: 113,
		part_id: 339,
		quantity: 272,
	},
	{
		garage_id: 113,
		part_id: 340,
		quantity: 181,
	},
	{
		garage_id: 113,
		part_id: 341,
		quantity: 418,
	},
	{
		garage_id: 113,
		part_id: 342,
		quantity: 232,
	},
	{
		garage_id: 113,
		part_id: 343,
		quantity: 246,
	},
	{
		garage_id: 113,
		part_id: 344,
		quantity: 330,
	},
	{
		garage_id: 113,
		part_id: 345,
		quantity: 326,
	},
	{
		garage_id: 113,
		part_id: 346,
		quantity: 286,
	},
	{
		garage_id: 113,
		part_id: 347,
		quantity: 85,
	},
	{
		garage_id: 113,
		part_id: 348,
		quantity: 383,
	},
	{
		garage_id: 113,
		part_id: 349,
		quantity: 207,
	},
	{
		garage_id: 113,
		part_id: 350,
		quantity: 96,
	},
	{
		garage_id: 113,
		part_id: 351,
		quantity: 165,
	},
	{
		garage_id: 113,
		part_id: 352,
		quantity: 454,
	},
	{
		garage_id: 113,
		part_id: 353,
		quantity: 118,
	},
	{
		garage_id: 113,
		part_id: 354,
		quantity: 45,
	},
	{
		garage_id: 113,
		part_id: 355,
		quantity: 463,
	},
	{
		garage_id: 113,
		part_id: 356,
		quantity: 196,
	},
	{
		garage_id: 113,
		part_id: 357,
		quantity: 66,
	},
	{
		garage_id: 113,
		part_id: 358,
		quantity: 346,
	},
	{
		garage_id: 113,
		part_id: 359,
		quantity: 142,
	},
	{
		garage_id: 113,
		part_id: 360,
		quantity: 14,
	},
	{
		garage_id: 113,
		part_id: 361,
		quantity: 442,
	},
	{
		garage_id: 113,
		part_id: 362,
		quantity: 470,
	},
	{
		garage_id: 113,
		part_id: 363,
		quantity: 225,
	},
	{
		garage_id: 113,
		part_id: 364,
		quantity: 121,
	},
	{
		garage_id: 113,
		part_id: 365,
		quantity: 336,
	},
	{
		garage_id: 113,
		part_id: 366,
		quantity: 185,
	},
	{
		garage_id: 113,
		part_id: 367,
		quantity: 206,
	},
	{
		garage_id: 113,
		part_id: 368,
		quantity: 414,
	},
	{
		garage_id: 113,
		part_id: 369,
		quantity: 19,
	},
	{
		garage_id: 113,
		part_id: 370,
		quantity: 207,
	},
	{
		garage_id: 113,
		part_id: 371,
		quantity: 355,
	},
	{
		garage_id: 113,
		part_id: 372,
		quantity: 413,
	},
	{
		garage_id: 113,
		part_id: 373,
		quantity: 181,
	},
	{
		garage_id: 113,
		part_id: 374,
		quantity: 292,
	},
	{
		garage_id: 113,
		part_id: 375,
		quantity: 253,
	},
	{
		garage_id: 113,
		part_id: 376,
		quantity: 86,
	},
	{
		garage_id: 113,
		part_id: 377,
		quantity: 280,
	},
	{
		garage_id: 113,
		part_id: 378,
		quantity: 456,
	},
	{
		garage_id: 113,
		part_id: 379,
		quantity: 262,
	},
	{
		garage_id: 113,
		part_id: 380,
		quantity: 259,
	},
	{
		garage_id: 113,
		part_id: 381,
		quantity: 334,
	},
	{
		garage_id: 113,
		part_id: 382,
		quantity: 443,
	},
	{
		garage_id: 113,
		part_id: 383,
		quantity: 174,
	},
	{
		garage_id: 113,
		part_id: 384,
		quantity: 423,
	},
	{
		garage_id: 113,
		part_id: 385,
		quantity: 305,
	},
	{
		garage_id: 113,
		part_id: 386,
		quantity: 233,
	},
	{
		garage_id: 113,
		part_id: 387,
		quantity: 480,
	},
	{
		garage_id: 113,
		part_id: 388,
		quantity: 449,
	},
	{
		garage_id: 113,
		part_id: 389,
		quantity: 481,
	},
	{
		garage_id: 113,
		part_id: 390,
		quantity: 113,
	},
	{
		garage_id: 113,
		part_id: 391,
		quantity: 282,
	},
	{
		garage_id: 113,
		part_id: 392,
		quantity: 212,
	},
	{
		garage_id: 113,
		part_id: 393,
		quantity: 258,
	},
	{
		garage_id: 113,
		part_id: 394,
		quantity: 105,
	},
	{
		garage_id: 113,
		part_id: 395,
		quantity: 132,
	},
	{
		garage_id: 113,
		part_id: 396,
		quantity: 484,
	},
	{
		garage_id: 113,
		part_id: 397,
		quantity: 183,
	},
	{
		garage_id: 113,
		part_id: 398,
		quantity: 355,
	},
	{
		garage_id: 113,
		part_id: 399,
		quantity: 429,
	},
	{
		garage_id: 114,
		part_id: 0,
		quantity: 141,
	},
	{
		garage_id: 114,
		part_id: 1,
		quantity: 270,
	},
	{
		garage_id: 114,
		part_id: 2,
		quantity: 86,
	},
	{
		garage_id: 114,
		part_id: 3,
		quantity: 227,
	},
	{
		garage_id: 114,
		part_id: 4,
		quantity: 489,
	},
	{
		garage_id: 114,
		part_id: 5,
		quantity: 88,
	},
	{
		garage_id: 114,
		part_id: 6,
		quantity: 33,
	},
	{
		garage_id: 114,
		part_id: 7,
		quantity: 82,
	},
	{
		garage_id: 114,
		part_id: 8,
		quantity: 189,
	},
	{
		garage_id: 114,
		part_id: 9,
		quantity: 77,
	},
	{
		garage_id: 114,
		part_id: 10,
		quantity: 481,
	},
	{
		garage_id: 114,
		part_id: 11,
		quantity: 248,
	},
	{
		garage_id: 114,
		part_id: 12,
		quantity: 239,
	},
	{
		garage_id: 114,
		part_id: 13,
		quantity: 12,
	},
	{
		garage_id: 114,
		part_id: 14,
		quantity: 3,
	},
	{
		garage_id: 114,
		part_id: 15,
		quantity: 113,
	},
	{
		garage_id: 114,
		part_id: 16,
		quantity: 399,
	},
	{
		garage_id: 114,
		part_id: 17,
		quantity: 318,
	},
	{
		garage_id: 114,
		part_id: 18,
		quantity: 281,
	},
	{
		garage_id: 114,
		part_id: 19,
		quantity: 23,
	},
	{
		garage_id: 114,
		part_id: 20,
		quantity: 21,
	},
	{
		garage_id: 114,
		part_id: 21,
		quantity: 41,
	},
	{
		garage_id: 114,
		part_id: 22,
		quantity: 353,
	},
	{
		garage_id: 114,
		part_id: 23,
		quantity: 206,
	},
	{
		garage_id: 114,
		part_id: 24,
		quantity: 343,
	},
	{
		garage_id: 114,
		part_id: 25,
		quantity: 78,
	},
	{
		garage_id: 114,
		part_id: 26,
		quantity: 408,
	},
	{
		garage_id: 114,
		part_id: 27,
		quantity: 67,
	},
	{
		garage_id: 114,
		part_id: 28,
		quantity: 477,
	},
	{
		garage_id: 114,
		part_id: 29,
		quantity: 173,
	},
	{
		garage_id: 114,
		part_id: 30,
		quantity: 382,
	},
	{
		garage_id: 114,
		part_id: 31,
		quantity: 100,
	},
	{
		garage_id: 114,
		part_id: 32,
		quantity: 401,
	},
	{
		garage_id: 114,
		part_id: 33,
		quantity: 170,
	},
	{
		garage_id: 114,
		part_id: 34,
		quantity: 107,
	},
	{
		garage_id: 114,
		part_id: 35,
		quantity: 19,
	},
	{
		garage_id: 114,
		part_id: 36,
		quantity: 111,
	},
	{
		garage_id: 114,
		part_id: 37,
		quantity: 474,
	},
	{
		garage_id: 114,
		part_id: 38,
		quantity: 185,
	},
	{
		garage_id: 114,
		part_id: 39,
		quantity: 135,
	},
	{
		garage_id: 114,
		part_id: 40,
		quantity: 185,
	},
	{
		garage_id: 114,
		part_id: 41,
		quantity: 325,
	},
	{
		garage_id: 114,
		part_id: 42,
		quantity: 237,
	},
	{
		garage_id: 114,
		part_id: 43,
		quantity: 294,
	},
	{
		garage_id: 114,
		part_id: 44,
		quantity: 494,
	},
	{
		garage_id: 114,
		part_id: 45,
		quantity: 325,
	},
	{
		garage_id: 114,
		part_id: 46,
		quantity: 391,
	},
	{
		garage_id: 114,
		part_id: 47,
		quantity: 153,
	},
	{
		garage_id: 114,
		part_id: 48,
		quantity: 296,
	},
	{
		garage_id: 114,
		part_id: 49,
		quantity: 146,
	},
	{
		garage_id: 114,
		part_id: 50,
		quantity: 350,
	},
	{
		garage_id: 114,
		part_id: 51,
		quantity: 76,
	},
	{
		garage_id: 114,
		part_id: 52,
		quantity: 429,
	},
	{
		garage_id: 114,
		part_id: 53,
		quantity: 148,
	},
	{
		garage_id: 114,
		part_id: 54,
		quantity: 66,
	},
	{
		garage_id: 114,
		part_id: 55,
		quantity: 80,
	},
	{
		garage_id: 114,
		part_id: 56,
		quantity: 164,
	},
	{
		garage_id: 114,
		part_id: 57,
		quantity: 277,
	},
	{
		garage_id: 114,
		part_id: 58,
		quantity: 259,
	},
	{
		garage_id: 114,
		part_id: 59,
		quantity: 228,
	},
	{
		garage_id: 114,
		part_id: 60,
		quantity: 392,
	},
	{
		garage_id: 114,
		part_id: 61,
		quantity: 66,
	},
	{
		garage_id: 114,
		part_id: 62,
		quantity: 393,
	},
	{
		garage_id: 114,
		part_id: 63,
		quantity: 68,
	},
	{
		garage_id: 114,
		part_id: 64,
		quantity: 223,
	},
	{
		garage_id: 114,
		part_id: 65,
		quantity: 428,
	},
	{
		garage_id: 114,
		part_id: 66,
		quantity: 162,
	},
	{
		garage_id: 114,
		part_id: 67,
		quantity: 133,
	},
	{
		garage_id: 114,
		part_id: 68,
		quantity: 14,
	},
	{
		garage_id: 114,
		part_id: 69,
		quantity: 468,
	},
	{
		garage_id: 114,
		part_id: 70,
		quantity: 420,
	},
	{
		garage_id: 114,
		part_id: 71,
		quantity: 406,
	},
	{
		garage_id: 114,
		part_id: 72,
		quantity: 276,
	},
	{
		garage_id: 114,
		part_id: 73,
		quantity: 223,
	},
	{
		garage_id: 114,
		part_id: 74,
		quantity: 25,
	},
	{
		garage_id: 114,
		part_id: 75,
		quantity: 277,
	},
	{
		garage_id: 114,
		part_id: 76,
		quantity: 151,
	},
	{
		garage_id: 114,
		part_id: 77,
		quantity: 292,
	},
	{
		garage_id: 114,
		part_id: 78,
		quantity: 83,
	},
	{
		garage_id: 114,
		part_id: 79,
		quantity: 174,
	},
	{
		garage_id: 114,
		part_id: 80,
		quantity: 59,
	},
	{
		garage_id: 114,
		part_id: 81,
		quantity: 160,
	},
	{
		garage_id: 114,
		part_id: 82,
		quantity: 274,
	},
	{
		garage_id: 114,
		part_id: 83,
		quantity: 455,
	},
	{
		garage_id: 114,
		part_id: 84,
		quantity: 312,
	},
	{
		garage_id: 114,
		part_id: 85,
		quantity: 283,
	},
	{
		garage_id: 114,
		part_id: 86,
		quantity: 8,
	},
	{
		garage_id: 114,
		part_id: 87,
		quantity: 431,
	},
	{
		garage_id: 114,
		part_id: 88,
		quantity: 193,
	},
	{
		garage_id: 114,
		part_id: 89,
		quantity: 434,
	},
	{
		garage_id: 114,
		part_id: 90,
		quantity: 456,
	},
	{
		garage_id: 114,
		part_id: 91,
		quantity: 76,
	},
	{
		garage_id: 114,
		part_id: 92,
		quantity: 97,
	},
	{
		garage_id: 114,
		part_id: 93,
		quantity: 437,
	},
	{
		garage_id: 114,
		part_id: 94,
		quantity: 277,
	},
	{
		garage_id: 114,
		part_id: 95,
		quantity: 421,
	},
	{
		garage_id: 114,
		part_id: 96,
		quantity: 428,
	},
	{
		garage_id: 114,
		part_id: 97,
		quantity: 150,
	},
	{
		garage_id: 114,
		part_id: 98,
		quantity: 278,
	},
	{
		garage_id: 114,
		part_id: 99,
		quantity: 114,
	},
	{
		garage_id: 114,
		part_id: 100,
		quantity: 55,
	},
	{
		garage_id: 114,
		part_id: 101,
		quantity: 473,
	},
	{
		garage_id: 114,
		part_id: 102,
		quantity: 103,
	},
	{
		garage_id: 114,
		part_id: 103,
		quantity: 93,
	},
	{
		garage_id: 114,
		part_id: 104,
		quantity: 462,
	},
	{
		garage_id: 114,
		part_id: 105,
		quantity: 40,
	},
	{
		garage_id: 114,
		part_id: 106,
		quantity: 212,
	},
	{
		garage_id: 114,
		part_id: 107,
		quantity: 479,
	},
	{
		garage_id: 114,
		part_id: 108,
		quantity: 424,
	},
	{
		garage_id: 114,
		part_id: 109,
		quantity: 20,
	},
	{
		garage_id: 114,
		part_id: 110,
		quantity: 260,
	},
	{
		garage_id: 114,
		part_id: 111,
		quantity: 82,
	},
	{
		garage_id: 114,
		part_id: 112,
		quantity: 230,
	},
	{
		garage_id: 114,
		part_id: 113,
		quantity: 184,
	},
	{
		garage_id: 114,
		part_id: 114,
		quantity: 363,
	},
	{
		garage_id: 114,
		part_id: 115,
		quantity: 213,
	},
	{
		garage_id: 114,
		part_id: 116,
		quantity: 481,
	},
	{
		garage_id: 114,
		part_id: 117,
		quantity: 393,
	},
	{
		garage_id: 114,
		part_id: 118,
		quantity: 364,
	},
	{
		garage_id: 114,
		part_id: 119,
		quantity: 458,
	},
	{
		garage_id: 114,
		part_id: 120,
		quantity: 287,
	},
	{
		garage_id: 114,
		part_id: 121,
		quantity: 42,
	},
	{
		garage_id: 114,
		part_id: 122,
		quantity: 433,
	},
	{
		garage_id: 114,
		part_id: 123,
		quantity: 106,
	},
	{
		garage_id: 114,
		part_id: 124,
		quantity: 186,
	},
	{
		garage_id: 114,
		part_id: 125,
		quantity: 51,
	},
	{
		garage_id: 114,
		part_id: 126,
		quantity: 308,
	},
	{
		garage_id: 114,
		part_id: 127,
		quantity: 399,
	},
	{
		garage_id: 114,
		part_id: 128,
		quantity: 12,
	},
	{
		garage_id: 114,
		part_id: 129,
		quantity: 205,
	},
	{
		garage_id: 114,
		part_id: 130,
		quantity: 313,
	},
	{
		garage_id: 114,
		part_id: 131,
		quantity: 120,
	},
	{
		garage_id: 114,
		part_id: 132,
		quantity: 17,
	},
	{
		garage_id: 114,
		part_id: 133,
		quantity: 238,
	},
	{
		garage_id: 114,
		part_id: 134,
		quantity: 248,
	},
	{
		garage_id: 114,
		part_id: 135,
		quantity: 258,
	},
	{
		garage_id: 114,
		part_id: 136,
		quantity: 316,
	},
	{
		garage_id: 114,
		part_id: 137,
		quantity: 457,
	},
	{
		garage_id: 114,
		part_id: 138,
		quantity: 5,
	},
	{
		garage_id: 114,
		part_id: 139,
		quantity: 374,
	},
	{
		garage_id: 114,
		part_id: 140,
		quantity: 179,
	},
	{
		garage_id: 114,
		part_id: 141,
		quantity: 229,
	},
	{
		garage_id: 114,
		part_id: 142,
		quantity: 15,
	},
	{
		garage_id: 114,
		part_id: 143,
		quantity: 61,
	},
	{
		garage_id: 114,
		part_id: 144,
		quantity: 65,
	},
	{
		garage_id: 114,
		part_id: 145,
		quantity: 342,
	},
	{
		garage_id: 114,
		part_id: 146,
		quantity: 248,
	},
	{
		garage_id: 114,
		part_id: 147,
		quantity: 0,
	},
	{
		garage_id: 114,
		part_id: 148,
		quantity: 259,
	},
	{
		garage_id: 114,
		part_id: 149,
		quantity: 259,
	},
	{
		garage_id: 114,
		part_id: 150,
		quantity: 34,
	},
	{
		garage_id: 114,
		part_id: 151,
		quantity: 252,
	},
	{
		garage_id: 114,
		part_id: 152,
		quantity: 491,
	},
	{
		garage_id: 114,
		part_id: 153,
		quantity: 412,
	},
	{
		garage_id: 114,
		part_id: 154,
		quantity: 293,
	},
	{
		garage_id: 114,
		part_id: 155,
		quantity: 207,
	},
	{
		garage_id: 114,
		part_id: 156,
		quantity: 132,
	},
	{
		garage_id: 114,
		part_id: 157,
		quantity: 36,
	},
	{
		garage_id: 114,
		part_id: 158,
		quantity: 387,
	},
	{
		garage_id: 114,
		part_id: 159,
		quantity: 305,
	},
	{
		garage_id: 114,
		part_id: 160,
		quantity: 385,
	},
	{
		garage_id: 114,
		part_id: 161,
		quantity: 64,
	},
	{
		garage_id: 114,
		part_id: 162,
		quantity: 25,
	},
	{
		garage_id: 114,
		part_id: 163,
		quantity: 279,
	},
	{
		garage_id: 114,
		part_id: 164,
		quantity: 162,
	},
	{
		garage_id: 114,
		part_id: 165,
		quantity: 58,
	},
	{
		garage_id: 114,
		part_id: 166,
		quantity: 150,
	},
	{
		garage_id: 114,
		part_id: 167,
		quantity: 379,
	},
	{
		garage_id: 114,
		part_id: 168,
		quantity: 21,
	},
	{
		garage_id: 114,
		part_id: 169,
		quantity: 463,
	},
	{
		garage_id: 114,
		part_id: 170,
		quantity: 225,
	},
	{
		garage_id: 114,
		part_id: 171,
		quantity: 410,
	},
	{
		garage_id: 114,
		part_id: 172,
		quantity: 404,
	},
	{
		garage_id: 114,
		part_id: 173,
		quantity: 489,
	},
	{
		garage_id: 114,
		part_id: 174,
		quantity: 10,
	},
	{
		garage_id: 114,
		part_id: 175,
		quantity: 47,
	},
	{
		garage_id: 114,
		part_id: 176,
		quantity: 189,
	},
	{
		garage_id: 114,
		part_id: 177,
		quantity: 421,
	},
	{
		garage_id: 114,
		part_id: 178,
		quantity: 327,
	},
	{
		garage_id: 114,
		part_id: 179,
		quantity: 16,
	},
	{
		garage_id: 114,
		part_id: 180,
		quantity: 408,
	},
	{
		garage_id: 114,
		part_id: 181,
		quantity: 91,
	},
	{
		garage_id: 114,
		part_id: 182,
		quantity: 405,
	},
	{
		garage_id: 114,
		part_id: 183,
		quantity: 262,
	},
	{
		garage_id: 114,
		part_id: 184,
		quantity: 375,
	},
	{
		garage_id: 114,
		part_id: 185,
		quantity: 123,
	},
	{
		garage_id: 114,
		part_id: 186,
		quantity: 177,
	},
	{
		garage_id: 114,
		part_id: 187,
		quantity: 365,
	},
	{
		garage_id: 114,
		part_id: 188,
		quantity: 232,
	},
	{
		garage_id: 114,
		part_id: 189,
		quantity: 46,
	},
	{
		garage_id: 114,
		part_id: 190,
		quantity: 4,
	},
	{
		garage_id: 114,
		part_id: 191,
		quantity: 5,
	},
	{
		garage_id: 114,
		part_id: 192,
		quantity: 43,
	},
	{
		garage_id: 114,
		part_id: 193,
		quantity: 228,
	},
	{
		garage_id: 114,
		part_id: 194,
		quantity: 256,
	},
	{
		garage_id: 114,
		part_id: 195,
		quantity: 244,
	},
	{
		garage_id: 114,
		part_id: 196,
		quantity: 494,
	},
	{
		garage_id: 114,
		part_id: 197,
		quantity: 76,
	},
	{
		garage_id: 114,
		part_id: 198,
		quantity: 80,
	},
	{
		garage_id: 114,
		part_id: 199,
		quantity: 457,
	},
	{
		garage_id: 114,
		part_id: 200,
		quantity: 333,
	},
	{
		garage_id: 114,
		part_id: 201,
		quantity: 232,
	},
	{
		garage_id: 114,
		part_id: 202,
		quantity: 95,
	},
	{
		garage_id: 114,
		part_id: 203,
		quantity: 283,
	},
	{
		garage_id: 114,
		part_id: 204,
		quantity: 309,
	},
	{
		garage_id: 114,
		part_id: 205,
		quantity: 472,
	},
	{
		garage_id: 114,
		part_id: 206,
		quantity: 304,
	},
	{
		garage_id: 114,
		part_id: 207,
		quantity: 277,
	},
	{
		garage_id: 114,
		part_id: 208,
		quantity: 366,
	},
	{
		garage_id: 114,
		part_id: 209,
		quantity: 462,
	},
	{
		garage_id: 114,
		part_id: 210,
		quantity: 240,
	},
	{
		garage_id: 114,
		part_id: 211,
		quantity: 441,
	},
	{
		garage_id: 114,
		part_id: 212,
		quantity: 465,
	},
	{
		garage_id: 114,
		part_id: 213,
		quantity: 392,
	},
	{
		garage_id: 114,
		part_id: 214,
		quantity: 120,
	},
	{
		garage_id: 114,
		part_id: 215,
		quantity: 452,
	},
	{
		garage_id: 114,
		part_id: 216,
		quantity: 179,
	},
	{
		garage_id: 114,
		part_id: 217,
		quantity: 350,
	},
	{
		garage_id: 114,
		part_id: 218,
		quantity: 59,
	},
	{
		garage_id: 114,
		part_id: 219,
		quantity: 438,
	},
	{
		garage_id: 114,
		part_id: 220,
		quantity: 298,
	},
	{
		garage_id: 114,
		part_id: 221,
		quantity: 317,
	},
	{
		garage_id: 114,
		part_id: 222,
		quantity: 241,
	},
	{
		garage_id: 114,
		part_id: 223,
		quantity: 240,
	},
	{
		garage_id: 114,
		part_id: 224,
		quantity: 138,
	},
	{
		garage_id: 114,
		part_id: 225,
		quantity: 233,
	},
	{
		garage_id: 114,
		part_id: 226,
		quantity: 105,
	},
	{
		garage_id: 114,
		part_id: 227,
		quantity: 250,
	},
	{
		garage_id: 114,
		part_id: 228,
		quantity: 292,
	},
	{
		garage_id: 114,
		part_id: 229,
		quantity: 55,
	},
	{
		garage_id: 114,
		part_id: 230,
		quantity: 156,
	},
	{
		garage_id: 114,
		part_id: 231,
		quantity: 288,
	},
	{
		garage_id: 114,
		part_id: 232,
		quantity: 68,
	},
	{
		garage_id: 114,
		part_id: 233,
		quantity: 7,
	},
	{
		garage_id: 114,
		part_id: 234,
		quantity: 345,
	},
	{
		garage_id: 114,
		part_id: 235,
		quantity: 410,
	},
	{
		garage_id: 114,
		part_id: 236,
		quantity: 85,
	},
	{
		garage_id: 114,
		part_id: 237,
		quantity: 404,
	},
	{
		garage_id: 114,
		part_id: 238,
		quantity: 242,
	},
	{
		garage_id: 114,
		part_id: 239,
		quantity: 119,
	},
	{
		garage_id: 114,
		part_id: 240,
		quantity: 389,
	},
	{
		garage_id: 114,
		part_id: 241,
		quantity: 95,
	},
	{
		garage_id: 114,
		part_id: 242,
		quantity: 96,
	},
	{
		garage_id: 114,
		part_id: 243,
		quantity: 380,
	},
	{
		garage_id: 114,
		part_id: 244,
		quantity: 359,
	},
	{
		garage_id: 114,
		part_id: 245,
		quantity: 306,
	},
	{
		garage_id: 114,
		part_id: 246,
		quantity: 467,
	},
	{
		garage_id: 114,
		part_id: 247,
		quantity: 498,
	},
	{
		garage_id: 114,
		part_id: 248,
		quantity: 79,
	},
	{
		garage_id: 114,
		part_id: 249,
		quantity: 125,
	},
	{
		garage_id: 114,
		part_id: 250,
		quantity: 302,
	},
	{
		garage_id: 114,
		part_id: 251,
		quantity: 391,
	},
	{
		garage_id: 114,
		part_id: 252,
		quantity: 475,
	},
	{
		garage_id: 114,
		part_id: 253,
		quantity: 154,
	},
	{
		garage_id: 114,
		part_id: 254,
		quantity: 201,
	},
	{
		garage_id: 114,
		part_id: 255,
		quantity: 329,
	},
	{
		garage_id: 114,
		part_id: 256,
		quantity: 124,
	},
	{
		garage_id: 114,
		part_id: 257,
		quantity: 372,
	},
	{
		garage_id: 114,
		part_id: 258,
		quantity: 349,
	},
	{
		garage_id: 114,
		part_id: 259,
		quantity: 1,
	},
	{
		garage_id: 114,
		part_id: 260,
		quantity: 408,
	},
	{
		garage_id: 114,
		part_id: 261,
		quantity: 381,
	},
	{
		garage_id: 114,
		part_id: 262,
		quantity: 274,
	},
	{
		garage_id: 114,
		part_id: 263,
		quantity: 475,
	},
	{
		garage_id: 114,
		part_id: 264,
		quantity: 100,
	},
	{
		garage_id: 114,
		part_id: 265,
		quantity: 261,
	},
	{
		garage_id: 114,
		part_id: 266,
		quantity: 332,
	},
	{
		garage_id: 114,
		part_id: 267,
		quantity: 197,
	},
	{
		garage_id: 114,
		part_id: 268,
		quantity: 36,
	},
	{
		garage_id: 114,
		part_id: 269,
		quantity: 129,
	},
	{
		garage_id: 114,
		part_id: 270,
		quantity: 380,
	},
	{
		garage_id: 114,
		part_id: 271,
		quantity: 83,
	},
	{
		garage_id: 114,
		part_id: 272,
		quantity: 304,
	},
	{
		garage_id: 114,
		part_id: 273,
		quantity: 6,
	},
	{
		garage_id: 114,
		part_id: 274,
		quantity: 490,
	},
	{
		garage_id: 114,
		part_id: 275,
		quantity: 30,
	},
	{
		garage_id: 114,
		part_id: 276,
		quantity: 279,
	},
	{
		garage_id: 114,
		part_id: 277,
		quantity: 485,
	},
	{
		garage_id: 114,
		part_id: 278,
		quantity: 483,
	},
	{
		garage_id: 114,
		part_id: 279,
		quantity: 353,
	},
	{
		garage_id: 114,
		part_id: 280,
		quantity: 493,
	},
	{
		garage_id: 114,
		part_id: 281,
		quantity: 51,
	},
	{
		garage_id: 114,
		part_id: 282,
		quantity: 374,
	},
	{
		garage_id: 114,
		part_id: 283,
		quantity: 205,
	},
	{
		garage_id: 114,
		part_id: 284,
		quantity: 159,
	},
	{
		garage_id: 114,
		part_id: 285,
		quantity: 225,
	},
	{
		garage_id: 114,
		part_id: 286,
		quantity: 263,
	},
	{
		garage_id: 114,
		part_id: 287,
		quantity: 432,
	},
	{
		garage_id: 114,
		part_id: 288,
		quantity: 193,
	},
	{
		garage_id: 114,
		part_id: 289,
		quantity: 8,
	},
	{
		garage_id: 114,
		part_id: 290,
		quantity: 465,
	},
	{
		garage_id: 114,
		part_id: 291,
		quantity: 139,
	},
	{
		garage_id: 114,
		part_id: 292,
		quantity: 184,
	},
	{
		garage_id: 114,
		part_id: 293,
		quantity: 182,
	},
	{
		garage_id: 114,
		part_id: 294,
		quantity: 110,
	},
	{
		garage_id: 114,
		part_id: 295,
		quantity: 167,
	},
	{
		garage_id: 114,
		part_id: 296,
		quantity: 123,
	},
	{
		garage_id: 114,
		part_id: 297,
		quantity: 6,
	},
	{
		garage_id: 114,
		part_id: 298,
		quantity: 82,
	},
	{
		garage_id: 114,
		part_id: 299,
		quantity: 133,
	},
	{
		garage_id: 114,
		part_id: 300,
		quantity: 105,
	},
	{
		garage_id: 114,
		part_id: 301,
		quantity: 85,
	},
	{
		garage_id: 114,
		part_id: 302,
		quantity: 137,
	},
	{
		garage_id: 114,
		part_id: 303,
		quantity: 92,
	},
	{
		garage_id: 114,
		part_id: 304,
		quantity: 403,
	},
	{
		garage_id: 114,
		part_id: 305,
		quantity: 466,
	},
	{
		garage_id: 114,
		part_id: 306,
		quantity: 299,
	},
	{
		garage_id: 114,
		part_id: 307,
		quantity: 163,
	},
	{
		garage_id: 114,
		part_id: 308,
		quantity: 274,
	},
	{
		garage_id: 114,
		part_id: 309,
		quantity: 43,
	},
	{
		garage_id: 114,
		part_id: 310,
		quantity: 237,
	},
	{
		garage_id: 114,
		part_id: 311,
		quantity: 163,
	},
	{
		garage_id: 114,
		part_id: 312,
		quantity: 305,
	},
	{
		garage_id: 114,
		part_id: 313,
		quantity: 245,
	},
	{
		garage_id: 114,
		part_id: 314,
		quantity: 417,
	},
	{
		garage_id: 114,
		part_id: 315,
		quantity: 154,
	},
	{
		garage_id: 114,
		part_id: 316,
		quantity: 186,
	},
	{
		garage_id: 114,
		part_id: 317,
		quantity: 184,
	},
	{
		garage_id: 114,
		part_id: 318,
		quantity: 124,
	},
	{
		garage_id: 114,
		part_id: 319,
		quantity: 267,
	},
	{
		garage_id: 114,
		part_id: 320,
		quantity: 411,
	},
	{
		garage_id: 114,
		part_id: 321,
		quantity: 115,
	},
	{
		garage_id: 114,
		part_id: 322,
		quantity: 435,
	},
	{
		garage_id: 114,
		part_id: 323,
		quantity: 124,
	},
	{
		garage_id: 114,
		part_id: 324,
		quantity: 306,
	},
	{
		garage_id: 114,
		part_id: 325,
		quantity: 0,
	},
	{
		garage_id: 114,
		part_id: 326,
		quantity: 286,
	},
	{
		garage_id: 114,
		part_id: 327,
		quantity: 463,
	},
	{
		garage_id: 114,
		part_id: 328,
		quantity: 457,
	},
	{
		garage_id: 114,
		part_id: 329,
		quantity: 270,
	},
	{
		garage_id: 114,
		part_id: 330,
		quantity: 448,
	},
	{
		garage_id: 114,
		part_id: 331,
		quantity: 254,
	},
	{
		garage_id: 114,
		part_id: 332,
		quantity: 383,
	},
	{
		garage_id: 114,
		part_id: 333,
		quantity: 398,
	},
	{
		garage_id: 114,
		part_id: 334,
		quantity: 322,
	},
	{
		garage_id: 114,
		part_id: 335,
		quantity: 241,
	},
	{
		garage_id: 114,
		part_id: 336,
		quantity: 331,
	},
	{
		garage_id: 114,
		part_id: 337,
		quantity: 445,
	},
	{
		garage_id: 114,
		part_id: 338,
		quantity: 101,
	},
	{
		garage_id: 114,
		part_id: 339,
		quantity: 58,
	},
	{
		garage_id: 114,
		part_id: 340,
		quantity: 148,
	},
	{
		garage_id: 114,
		part_id: 341,
		quantity: 61,
	},
	{
		garage_id: 114,
		part_id: 342,
		quantity: 95,
	},
	{
		garage_id: 114,
		part_id: 343,
		quantity: 404,
	},
	{
		garage_id: 114,
		part_id: 344,
		quantity: 412,
	},
	{
		garage_id: 114,
		part_id: 345,
		quantity: 141,
	},
	{
		garage_id: 114,
		part_id: 346,
		quantity: 304,
	},
	{
		garage_id: 114,
		part_id: 347,
		quantity: 26,
	},
	{
		garage_id: 114,
		part_id: 348,
		quantity: 145,
	},
	{
		garage_id: 114,
		part_id: 349,
		quantity: 469,
	},
	{
		garage_id: 114,
		part_id: 350,
		quantity: 67,
	},
	{
		garage_id: 114,
		part_id: 351,
		quantity: 153,
	},
	{
		garage_id: 114,
		part_id: 352,
		quantity: 311,
	},
	{
		garage_id: 114,
		part_id: 353,
		quantity: 452,
	},
	{
		garage_id: 114,
		part_id: 354,
		quantity: 251,
	},
	{
		garage_id: 114,
		part_id: 355,
		quantity: 146,
	},
	{
		garage_id: 114,
		part_id: 356,
		quantity: 123,
	},
	{
		garage_id: 114,
		part_id: 357,
		quantity: 354,
	},
	{
		garage_id: 114,
		part_id: 358,
		quantity: 377,
	},
	{
		garage_id: 114,
		part_id: 359,
		quantity: 483,
	},
	{
		garage_id: 114,
		part_id: 360,
		quantity: 8,
	},
	{
		garage_id: 114,
		part_id: 361,
		quantity: 229,
	},
	{
		garage_id: 114,
		part_id: 362,
		quantity: 243,
	},
	{
		garage_id: 114,
		part_id: 363,
		quantity: 334,
	},
	{
		garage_id: 114,
		part_id: 364,
		quantity: 27,
	},
	{
		garage_id: 114,
		part_id: 365,
		quantity: 273,
	},
	{
		garage_id: 114,
		part_id: 366,
		quantity: 498,
	},
	{
		garage_id: 114,
		part_id: 367,
		quantity: 357,
	},
	{
		garage_id: 114,
		part_id: 368,
		quantity: 227,
	},
	{
		garage_id: 114,
		part_id: 369,
		quantity: 63,
	},
	{
		garage_id: 114,
		part_id: 370,
		quantity: 190,
	},
	{
		garage_id: 114,
		part_id: 371,
		quantity: 456,
	},
	{
		garage_id: 114,
		part_id: 372,
		quantity: 187,
	},
	{
		garage_id: 114,
		part_id: 373,
		quantity: 308,
	},
	{
		garage_id: 114,
		part_id: 374,
		quantity: 401,
	},
	{
		garage_id: 114,
		part_id: 375,
		quantity: 315,
	},
	{
		garage_id: 114,
		part_id: 376,
		quantity: 152,
	},
	{
		garage_id: 114,
		part_id: 377,
		quantity: 430,
	},
	{
		garage_id: 114,
		part_id: 378,
		quantity: 117,
	},
	{
		garage_id: 114,
		part_id: 379,
		quantity: 490,
	},
	{
		garage_id: 114,
		part_id: 380,
		quantity: 274,
	},
	{
		garage_id: 114,
		part_id: 381,
		quantity: 111,
	},
	{
		garage_id: 114,
		part_id: 382,
		quantity: 270,
	},
	{
		garage_id: 114,
		part_id: 383,
		quantity: 160,
	},
	{
		garage_id: 114,
		part_id: 384,
		quantity: 474,
	},
	{
		garage_id: 114,
		part_id: 385,
		quantity: 330,
	},
	{
		garage_id: 114,
		part_id: 386,
		quantity: 301,
	},
	{
		garage_id: 114,
		part_id: 387,
		quantity: 340,
	},
	{
		garage_id: 114,
		part_id: 388,
		quantity: 497,
	},
	{
		garage_id: 114,
		part_id: 389,
		quantity: 13,
	},
	{
		garage_id: 114,
		part_id: 390,
		quantity: 457,
	},
	{
		garage_id: 114,
		part_id: 391,
		quantity: 27,
	},
	{
		garage_id: 114,
		part_id: 392,
		quantity: 283,
	},
	{
		garage_id: 114,
		part_id: 393,
		quantity: 210,
	},
	{
		garage_id: 114,
		part_id: 394,
		quantity: 37,
	},
	{
		garage_id: 114,
		part_id: 395,
		quantity: 47,
	},
	{
		garage_id: 114,
		part_id: 396,
		quantity: 450,
	},
	{
		garage_id: 114,
		part_id: 397,
		quantity: 364,
	},
	{
		garage_id: 114,
		part_id: 398,
		quantity: 165,
	},
	{
		garage_id: 114,
		part_id: 399,
		quantity: 35,
	},
	{
		garage_id: 115,
		part_id: 0,
		quantity: 210,
	},
	{
		garage_id: 115,
		part_id: 1,
		quantity: 148,
	},
	{
		garage_id: 115,
		part_id: 2,
		quantity: 166,
	},
	{
		garage_id: 115,
		part_id: 3,
		quantity: 66,
	},
	{
		garage_id: 115,
		part_id: 4,
		quantity: 14,
	},
	{
		garage_id: 115,
		part_id: 5,
		quantity: 260,
	},
	{
		garage_id: 115,
		part_id: 6,
		quantity: 98,
	},
	{
		garage_id: 115,
		part_id: 7,
		quantity: 173,
	},
	{
		garage_id: 115,
		part_id: 8,
		quantity: 290,
	},
	{
		garage_id: 115,
		part_id: 9,
		quantity: 499,
	},
	{
		garage_id: 115,
		part_id: 10,
		quantity: 323,
	},
	{
		garage_id: 115,
		part_id: 11,
		quantity: 6,
	},
	{
		garage_id: 115,
		part_id: 12,
		quantity: 486,
	},
	{
		garage_id: 115,
		part_id: 13,
		quantity: 268,
	},
	{
		garage_id: 115,
		part_id: 14,
		quantity: 434,
	},
	{
		garage_id: 115,
		part_id: 15,
		quantity: 451,
	},
	{
		garage_id: 115,
		part_id: 16,
		quantity: 248,
	},
	{
		garage_id: 115,
		part_id: 17,
		quantity: 427,
	},
	{
		garage_id: 115,
		part_id: 18,
		quantity: 316,
	},
	{
		garage_id: 115,
		part_id: 19,
		quantity: 391,
	},
	{
		garage_id: 115,
		part_id: 20,
		quantity: 335,
	},
	{
		garage_id: 115,
		part_id: 21,
		quantity: 369,
	},
	{
		garage_id: 115,
		part_id: 22,
		quantity: 499,
	},
	{
		garage_id: 115,
		part_id: 23,
		quantity: 264,
	},
	{
		garage_id: 115,
		part_id: 24,
		quantity: 188,
	},
	{
		garage_id: 115,
		part_id: 25,
		quantity: 491,
	},
	{
		garage_id: 115,
		part_id: 26,
		quantity: 342,
	},
	{
		garage_id: 115,
		part_id: 27,
		quantity: 467,
	},
	{
		garage_id: 115,
		part_id: 28,
		quantity: 346,
	},
	{
		garage_id: 115,
		part_id: 29,
		quantity: 25,
	},
	{
		garage_id: 115,
		part_id: 30,
		quantity: 80,
	},
	{
		garage_id: 115,
		part_id: 31,
		quantity: 36,
	},
	{
		garage_id: 115,
		part_id: 32,
		quantity: 60,
	},
	{
		garage_id: 115,
		part_id: 33,
		quantity: 24,
	},
	{
		garage_id: 115,
		part_id: 34,
		quantity: 308,
	},
	{
		garage_id: 115,
		part_id: 35,
		quantity: 423,
	},
	{
		garage_id: 115,
		part_id: 36,
		quantity: 21,
	},
	{
		garage_id: 115,
		part_id: 37,
		quantity: 293,
	},
	{
		garage_id: 115,
		part_id: 38,
		quantity: 444,
	},
	{
		garage_id: 115,
		part_id: 39,
		quantity: 289,
	},
	{
		garage_id: 115,
		part_id: 40,
		quantity: 385,
	},
	{
		garage_id: 115,
		part_id: 41,
		quantity: 123,
	},
	{
		garage_id: 115,
		part_id: 42,
		quantity: 313,
	},
	{
		garage_id: 115,
		part_id: 43,
		quantity: 68,
	},
	{
		garage_id: 115,
		part_id: 44,
		quantity: 67,
	},
	{
		garage_id: 115,
		part_id: 45,
		quantity: 470,
	},
	{
		garage_id: 115,
		part_id: 46,
		quantity: 401,
	},
	{
		garage_id: 115,
		part_id: 47,
		quantity: 157,
	},
	{
		garage_id: 115,
		part_id: 48,
		quantity: 203,
	},
	{
		garage_id: 115,
		part_id: 49,
		quantity: 102,
	},
	{
		garage_id: 115,
		part_id: 50,
		quantity: 135,
	},
	{
		garage_id: 115,
		part_id: 51,
		quantity: 5,
	},
	{
		garage_id: 115,
		part_id: 52,
		quantity: 465,
	},
	{
		garage_id: 115,
		part_id: 53,
		quantity: 23,
	},
	{
		garage_id: 115,
		part_id: 54,
		quantity: 428,
	},
	{
		garage_id: 115,
		part_id: 55,
		quantity: 358,
	},
	{
		garage_id: 115,
		part_id: 56,
		quantity: 28,
	},
	{
		garage_id: 115,
		part_id: 57,
		quantity: 343,
	},
	{
		garage_id: 115,
		part_id: 58,
		quantity: 196,
	},
	{
		garage_id: 115,
		part_id: 59,
		quantity: 18,
	},
	{
		garage_id: 115,
		part_id: 60,
		quantity: 301,
	},
	{
		garage_id: 115,
		part_id: 61,
		quantity: 155,
	},
	{
		garage_id: 115,
		part_id: 62,
		quantity: 413,
	},
	{
		garage_id: 115,
		part_id: 63,
		quantity: 105,
	},
	{
		garage_id: 115,
		part_id: 64,
		quantity: 463,
	},
	{
		garage_id: 115,
		part_id: 65,
		quantity: 215,
	},
	{
		garage_id: 115,
		part_id: 66,
		quantity: 441,
	},
	{
		garage_id: 115,
		part_id: 67,
		quantity: 104,
	},
	{
		garage_id: 115,
		part_id: 68,
		quantity: 431,
	},
	{
		garage_id: 115,
		part_id: 69,
		quantity: 357,
	},
	{
		garage_id: 115,
		part_id: 70,
		quantity: 419,
	},
	{
		garage_id: 115,
		part_id: 71,
		quantity: 422,
	},
	{
		garage_id: 115,
		part_id: 72,
		quantity: 444,
	},
	{
		garage_id: 115,
		part_id: 73,
		quantity: 446,
	},
	{
		garage_id: 115,
		part_id: 74,
		quantity: 365,
	},
	{
		garage_id: 115,
		part_id: 75,
		quantity: 71,
	},
	{
		garage_id: 115,
		part_id: 76,
		quantity: 162,
	},
	{
		garage_id: 115,
		part_id: 77,
		quantity: 64,
	},
	{
		garage_id: 115,
		part_id: 78,
		quantity: 455,
	},
	{
		garage_id: 115,
		part_id: 79,
		quantity: 479,
	},
	{
		garage_id: 115,
		part_id: 80,
		quantity: 283,
	},
	{
		garage_id: 115,
		part_id: 81,
		quantity: 1,
	},
	{
		garage_id: 115,
		part_id: 82,
		quantity: 314,
	},
	{
		garage_id: 115,
		part_id: 83,
		quantity: 139,
	},
	{
		garage_id: 115,
		part_id: 84,
		quantity: 339,
	},
	{
		garage_id: 115,
		part_id: 85,
		quantity: 430,
	},
	{
		garage_id: 115,
		part_id: 86,
		quantity: 354,
	},
	{
		garage_id: 115,
		part_id: 87,
		quantity: 306,
	},
	{
		garage_id: 115,
		part_id: 88,
		quantity: 444,
	},
	{
		garage_id: 115,
		part_id: 89,
		quantity: 277,
	},
	{
		garage_id: 115,
		part_id: 90,
		quantity: 77,
	},
	{
		garage_id: 115,
		part_id: 91,
		quantity: 99,
	},
	{
		garage_id: 115,
		part_id: 92,
		quantity: 252,
	},
	{
		garage_id: 115,
		part_id: 93,
		quantity: 102,
	},
	{
		garage_id: 115,
		part_id: 94,
		quantity: 146,
	},
	{
		garage_id: 115,
		part_id: 95,
		quantity: 429,
	},
	{
		garage_id: 115,
		part_id: 96,
		quantity: 337,
	},
	{
		garage_id: 115,
		part_id: 97,
		quantity: 87,
	},
	{
		garage_id: 115,
		part_id: 98,
		quantity: 246,
	},
	{
		garage_id: 115,
		part_id: 99,
		quantity: 473,
	},
	{
		garage_id: 115,
		part_id: 100,
		quantity: 493,
	},
	{
		garage_id: 115,
		part_id: 101,
		quantity: 238,
	},
	{
		garage_id: 115,
		part_id: 102,
		quantity: 208,
	},
	{
		garage_id: 115,
		part_id: 103,
		quantity: 86,
	},
	{
		garage_id: 115,
		part_id: 104,
		quantity: 246,
	},
	{
		garage_id: 115,
		part_id: 105,
		quantity: 26,
	},
	{
		garage_id: 115,
		part_id: 106,
		quantity: 333,
	},
	{
		garage_id: 115,
		part_id: 107,
		quantity: 254,
	},
	{
		garage_id: 115,
		part_id: 108,
		quantity: 197,
	},
	{
		garage_id: 115,
		part_id: 109,
		quantity: 240,
	},
	{
		garage_id: 115,
		part_id: 110,
		quantity: 241,
	},
	{
		garage_id: 115,
		part_id: 111,
		quantity: 320,
	},
	{
		garage_id: 115,
		part_id: 112,
		quantity: 152,
	},
	{
		garage_id: 115,
		part_id: 113,
		quantity: 85,
	},
	{
		garage_id: 115,
		part_id: 114,
		quantity: 223,
	},
	{
		garage_id: 115,
		part_id: 115,
		quantity: 263,
	},
	{
		garage_id: 115,
		part_id: 116,
		quantity: 134,
	},
	{
		garage_id: 115,
		part_id: 117,
		quantity: 460,
	},
	{
		garage_id: 115,
		part_id: 118,
		quantity: 266,
	},
	{
		garage_id: 115,
		part_id: 119,
		quantity: 254,
	},
	{
		garage_id: 115,
		part_id: 120,
		quantity: 60,
	},
	{
		garage_id: 115,
		part_id: 121,
		quantity: 131,
	},
	{
		garage_id: 115,
		part_id: 122,
		quantity: 283,
	},
	{
		garage_id: 115,
		part_id: 123,
		quantity: 236,
	},
	{
		garage_id: 115,
		part_id: 124,
		quantity: 11,
	},
	{
		garage_id: 115,
		part_id: 125,
		quantity: 113,
	},
	{
		garage_id: 115,
		part_id: 126,
		quantity: 146,
	},
	{
		garage_id: 115,
		part_id: 127,
		quantity: 386,
	},
	{
		garage_id: 115,
		part_id: 128,
		quantity: 258,
	},
	{
		garage_id: 115,
		part_id: 129,
		quantity: 278,
	},
	{
		garage_id: 115,
		part_id: 130,
		quantity: 442,
	},
	{
		garage_id: 115,
		part_id: 131,
		quantity: 395,
	},
	{
		garage_id: 115,
		part_id: 132,
		quantity: 134,
	},
	{
		garage_id: 115,
		part_id: 133,
		quantity: 76,
	},
	{
		garage_id: 115,
		part_id: 134,
		quantity: 74,
	},
	{
		garage_id: 115,
		part_id: 135,
		quantity: 141,
	},
	{
		garage_id: 115,
		part_id: 136,
		quantity: 484,
	},
	{
		garage_id: 115,
		part_id: 137,
		quantity: 51,
	},
	{
		garage_id: 115,
		part_id: 138,
		quantity: 157,
	},
	{
		garage_id: 115,
		part_id: 139,
		quantity: 17,
	},
	{
		garage_id: 115,
		part_id: 140,
		quantity: 337,
	},
	{
		garage_id: 115,
		part_id: 141,
		quantity: 106,
	},
	{
		garage_id: 115,
		part_id: 142,
		quantity: 471,
	},
	{
		garage_id: 115,
		part_id: 143,
		quantity: 354,
	},
	{
		garage_id: 115,
		part_id: 144,
		quantity: 36,
	},
	{
		garage_id: 115,
		part_id: 145,
		quantity: 117,
	},
	{
		garage_id: 115,
		part_id: 146,
		quantity: 154,
	},
	{
		garage_id: 115,
		part_id: 147,
		quantity: 184,
	},
	{
		garage_id: 115,
		part_id: 148,
		quantity: 113,
	},
	{
		garage_id: 115,
		part_id: 149,
		quantity: 379,
	},
	{
		garage_id: 115,
		part_id: 150,
		quantity: 265,
	},
	{
		garage_id: 115,
		part_id: 151,
		quantity: 283,
	},
	{
		garage_id: 115,
		part_id: 152,
		quantity: 256,
	},
	{
		garage_id: 115,
		part_id: 153,
		quantity: 375,
	},
	{
		garage_id: 115,
		part_id: 154,
		quantity: 306,
	},
	{
		garage_id: 115,
		part_id: 155,
		quantity: 289,
	},
	{
		garage_id: 115,
		part_id: 156,
		quantity: 299,
	},
	{
		garage_id: 115,
		part_id: 157,
		quantity: 5,
	},
	{
		garage_id: 115,
		part_id: 158,
		quantity: 136,
	},
	{
		garage_id: 115,
		part_id: 159,
		quantity: 462,
	},
	{
		garage_id: 115,
		part_id: 160,
		quantity: 325,
	},
	{
		garage_id: 115,
		part_id: 161,
		quantity: 226,
	},
	{
		garage_id: 115,
		part_id: 162,
		quantity: 422,
	},
	{
		garage_id: 115,
		part_id: 163,
		quantity: 372,
	},
	{
		garage_id: 115,
		part_id: 164,
		quantity: 321,
	},
	{
		garage_id: 115,
		part_id: 165,
		quantity: 119,
	},
	{
		garage_id: 115,
		part_id: 166,
		quantity: 335,
	},
	{
		garage_id: 115,
		part_id: 167,
		quantity: 120,
	},
	{
		garage_id: 115,
		part_id: 168,
		quantity: 232,
	},
	{
		garage_id: 115,
		part_id: 169,
		quantity: 233,
	},
	{
		garage_id: 115,
		part_id: 170,
		quantity: 220,
	},
	{
		garage_id: 115,
		part_id: 171,
		quantity: 456,
	},
	{
		garage_id: 115,
		part_id: 172,
		quantity: 246,
	},
	{
		garage_id: 115,
		part_id: 173,
		quantity: 136,
	},
	{
		garage_id: 115,
		part_id: 174,
		quantity: 358,
	},
	{
		garage_id: 115,
		part_id: 175,
		quantity: 496,
	},
	{
		garage_id: 115,
		part_id: 176,
		quantity: 232,
	},
	{
		garage_id: 115,
		part_id: 177,
		quantity: 217,
	},
	{
		garage_id: 115,
		part_id: 178,
		quantity: 234,
	},
	{
		garage_id: 115,
		part_id: 179,
		quantity: 457,
	},
	{
		garage_id: 115,
		part_id: 180,
		quantity: 89,
	},
	{
		garage_id: 115,
		part_id: 181,
		quantity: 238,
	},
	{
		garage_id: 115,
		part_id: 182,
		quantity: 115,
	},
	{
		garage_id: 115,
		part_id: 183,
		quantity: 454,
	},
	{
		garage_id: 115,
		part_id: 184,
		quantity: 177,
	},
	{
		garage_id: 115,
		part_id: 185,
		quantity: 61,
	},
	{
		garage_id: 115,
		part_id: 186,
		quantity: 28,
	},
	{
		garage_id: 115,
		part_id: 187,
		quantity: 455,
	},
	{
		garage_id: 115,
		part_id: 188,
		quantity: 458,
	},
	{
		garage_id: 115,
		part_id: 189,
		quantity: 229,
	},
	{
		garage_id: 115,
		part_id: 190,
		quantity: 419,
	},
	{
		garage_id: 115,
		part_id: 191,
		quantity: 424,
	},
	{
		garage_id: 115,
		part_id: 192,
		quantity: 47,
	},
	{
		garage_id: 115,
		part_id: 193,
		quantity: 344,
	},
	{
		garage_id: 115,
		part_id: 194,
		quantity: 281,
	},
	{
		garage_id: 115,
		part_id: 195,
		quantity: 417,
	},
	{
		garage_id: 115,
		part_id: 196,
		quantity: 135,
	},
	{
		garage_id: 115,
		part_id: 197,
		quantity: 452,
	},
	{
		garage_id: 115,
		part_id: 198,
		quantity: 430,
	},
	{
		garage_id: 115,
		part_id: 199,
		quantity: 22,
	},
	{
		garage_id: 115,
		part_id: 200,
		quantity: 328,
	},
	{
		garage_id: 115,
		part_id: 201,
		quantity: 347,
	},
	{
		garage_id: 115,
		part_id: 202,
		quantity: 304,
	},
	{
		garage_id: 115,
		part_id: 203,
		quantity: 161,
	},
	{
		garage_id: 115,
		part_id: 204,
		quantity: 84,
	},
	{
		garage_id: 115,
		part_id: 205,
		quantity: 163,
	},
	{
		garage_id: 115,
		part_id: 206,
		quantity: 221,
	},
	{
		garage_id: 115,
		part_id: 207,
		quantity: 455,
	},
	{
		garage_id: 115,
		part_id: 208,
		quantity: 317,
	},
	{
		garage_id: 115,
		part_id: 209,
		quantity: 15,
	},
	{
		garage_id: 115,
		part_id: 210,
		quantity: 325,
	},
	{
		garage_id: 115,
		part_id: 211,
		quantity: 437,
	},
	{
		garage_id: 115,
		part_id: 212,
		quantity: 403,
	},
	{
		garage_id: 115,
		part_id: 213,
		quantity: 89,
	},
	{
		garage_id: 115,
		part_id: 214,
		quantity: 412,
	},
	{
		garage_id: 115,
		part_id: 215,
		quantity: 445,
	},
	{
		garage_id: 115,
		part_id: 216,
		quantity: 471,
	},
	{
		garage_id: 115,
		part_id: 217,
		quantity: 132,
	},
	{
		garage_id: 115,
		part_id: 218,
		quantity: 142,
	},
	{
		garage_id: 115,
		part_id: 219,
		quantity: 259,
	},
	{
		garage_id: 115,
		part_id: 220,
		quantity: 203,
	},
	{
		garage_id: 115,
		part_id: 221,
		quantity: 483,
	},
	{
		garage_id: 115,
		part_id: 222,
		quantity: 367,
	},
	{
		garage_id: 115,
		part_id: 223,
		quantity: 386,
	},
	{
		garage_id: 115,
		part_id: 224,
		quantity: 37,
	},
	{
		garage_id: 115,
		part_id: 225,
		quantity: 452,
	},
	{
		garage_id: 115,
		part_id: 226,
		quantity: 360,
	},
	{
		garage_id: 115,
		part_id: 227,
		quantity: 158,
	},
	{
		garage_id: 115,
		part_id: 228,
		quantity: 376,
	},
	{
		garage_id: 115,
		part_id: 229,
		quantity: 220,
	},
	{
		garage_id: 115,
		part_id: 230,
		quantity: 178,
	},
	{
		garage_id: 115,
		part_id: 231,
		quantity: 381,
	},
	{
		garage_id: 115,
		part_id: 232,
		quantity: 422,
	},
	{
		garage_id: 115,
		part_id: 233,
		quantity: 90,
	},
	{
		garage_id: 115,
		part_id: 234,
		quantity: 431,
	},
	{
		garage_id: 115,
		part_id: 235,
		quantity: 486,
	},
	{
		garage_id: 115,
		part_id: 236,
		quantity: 60,
	},
	{
		garage_id: 115,
		part_id: 237,
		quantity: 89,
	},
	{
		garage_id: 115,
		part_id: 238,
		quantity: 475,
	},
	{
		garage_id: 115,
		part_id: 239,
		quantity: 39,
	},
	{
		garage_id: 115,
		part_id: 240,
		quantity: 447,
	},
	{
		garage_id: 115,
		part_id: 241,
		quantity: 402,
	},
	{
		garage_id: 115,
		part_id: 242,
		quantity: 257,
	},
	{
		garage_id: 115,
		part_id: 243,
		quantity: 61,
	},
	{
		garage_id: 115,
		part_id: 244,
		quantity: 490,
	},
	{
		garage_id: 115,
		part_id: 245,
		quantity: 339,
	},
	{
		garage_id: 115,
		part_id: 246,
		quantity: 390,
	},
	{
		garage_id: 115,
		part_id: 247,
		quantity: 222,
	},
	{
		garage_id: 115,
		part_id: 248,
		quantity: 478,
	},
	{
		garage_id: 115,
		part_id: 249,
		quantity: 274,
	},
	{
		garage_id: 115,
		part_id: 250,
		quantity: 171,
	},
	{
		garage_id: 115,
		part_id: 251,
		quantity: 334,
	},
	{
		garage_id: 115,
		part_id: 252,
		quantity: 224,
	},
	{
		garage_id: 115,
		part_id: 253,
		quantity: 110,
	},
	{
		garage_id: 115,
		part_id: 254,
		quantity: 75,
	},
	{
		garage_id: 115,
		part_id: 255,
		quantity: 129,
	},
	{
		garage_id: 115,
		part_id: 256,
		quantity: 371,
	},
	{
		garage_id: 115,
		part_id: 257,
		quantity: 75,
	},
	{
		garage_id: 115,
		part_id: 258,
		quantity: 415,
	},
	{
		garage_id: 115,
		part_id: 259,
		quantity: 249,
	},
	{
		garage_id: 115,
		part_id: 260,
		quantity: 285,
	},
	{
		garage_id: 115,
		part_id: 261,
		quantity: 222,
	},
	{
		garage_id: 115,
		part_id: 262,
		quantity: 297,
	},
	{
		garage_id: 115,
		part_id: 263,
		quantity: 381,
	},
	{
		garage_id: 115,
		part_id: 264,
		quantity: 60,
	},
	{
		garage_id: 115,
		part_id: 265,
		quantity: 341,
	},
	{
		garage_id: 115,
		part_id: 266,
		quantity: 493,
	},
	{
		garage_id: 115,
		part_id: 267,
		quantity: 196,
	},
	{
		garage_id: 115,
		part_id: 268,
		quantity: 119,
	},
	{
		garage_id: 115,
		part_id: 269,
		quantity: 96,
	},
	{
		garage_id: 115,
		part_id: 270,
		quantity: 495,
	},
	{
		garage_id: 115,
		part_id: 271,
		quantity: 197,
	},
	{
		garage_id: 115,
		part_id: 272,
		quantity: 436,
	},
	{
		garage_id: 115,
		part_id: 273,
		quantity: 162,
	},
	{
		garage_id: 115,
		part_id: 274,
		quantity: 191,
	},
	{
		garage_id: 115,
		part_id: 275,
		quantity: 375,
	},
	{
		garage_id: 115,
		part_id: 276,
		quantity: 321,
	},
	{
		garage_id: 115,
		part_id: 277,
		quantity: 84,
	},
	{
		garage_id: 115,
		part_id: 278,
		quantity: 198,
	},
	{
		garage_id: 115,
		part_id: 279,
		quantity: 430,
	},
	{
		garage_id: 115,
		part_id: 280,
		quantity: 285,
	},
	{
		garage_id: 115,
		part_id: 281,
		quantity: 414,
	},
	{
		garage_id: 115,
		part_id: 282,
		quantity: 355,
	},
	{
		garage_id: 115,
		part_id: 283,
		quantity: 115,
	},
	{
		garage_id: 115,
		part_id: 284,
		quantity: 124,
	},
	{
		garage_id: 115,
		part_id: 285,
		quantity: 228,
	},
	{
		garage_id: 115,
		part_id: 286,
		quantity: 128,
	},
	{
		garage_id: 115,
		part_id: 287,
		quantity: 234,
	},
	{
		garage_id: 115,
		part_id: 288,
		quantity: 71,
	},
	{
		garage_id: 115,
		part_id: 289,
		quantity: 41,
	},
	{
		garage_id: 115,
		part_id: 290,
		quantity: 317,
	},
	{
		garage_id: 115,
		part_id: 291,
		quantity: 387,
	},
	{
		garage_id: 115,
		part_id: 292,
		quantity: 123,
	},
	{
		garage_id: 115,
		part_id: 293,
		quantity: 488,
	},
	{
		garage_id: 115,
		part_id: 294,
		quantity: 54,
	},
	{
		garage_id: 115,
		part_id: 295,
		quantity: 211,
	},
	{
		garage_id: 115,
		part_id: 296,
		quantity: 180,
	},
	{
		garage_id: 115,
		part_id: 297,
		quantity: 115,
	},
	{
		garage_id: 115,
		part_id: 298,
		quantity: 253,
	},
	{
		garage_id: 115,
		part_id: 299,
		quantity: 31,
	},
	{
		garage_id: 115,
		part_id: 300,
		quantity: 320,
	},
	{
		garage_id: 115,
		part_id: 301,
		quantity: 288,
	},
	{
		garage_id: 115,
		part_id: 302,
		quantity: 122,
	},
	{
		garage_id: 115,
		part_id: 303,
		quantity: 334,
	},
	{
		garage_id: 115,
		part_id: 304,
		quantity: 31,
	},
	{
		garage_id: 115,
		part_id: 305,
		quantity: 274,
	},
	{
		garage_id: 115,
		part_id: 306,
		quantity: 244,
	},
	{
		garage_id: 115,
		part_id: 307,
		quantity: 460,
	},
	{
		garage_id: 115,
		part_id: 308,
		quantity: 218,
	},
	{
		garage_id: 115,
		part_id: 309,
		quantity: 189,
	},
	{
		garage_id: 115,
		part_id: 310,
		quantity: 415,
	},
	{
		garage_id: 115,
		part_id: 311,
		quantity: 327,
	},
	{
		garage_id: 115,
		part_id: 312,
		quantity: 363,
	},
	{
		garage_id: 115,
		part_id: 313,
		quantity: 320,
	},
	{
		garage_id: 115,
		part_id: 314,
		quantity: 79,
	},
	{
		garage_id: 115,
		part_id: 315,
		quantity: 323,
	},
	{
		garage_id: 115,
		part_id: 316,
		quantity: 214,
	},
	{
		garage_id: 115,
		part_id: 317,
		quantity: 157,
	},
	{
		garage_id: 115,
		part_id: 318,
		quantity: 189,
	},
	{
		garage_id: 115,
		part_id: 319,
		quantity: 384,
	},
	{
		garage_id: 115,
		part_id: 320,
		quantity: 208,
	},
	{
		garage_id: 115,
		part_id: 321,
		quantity: 21,
	},
	{
		garage_id: 115,
		part_id: 322,
		quantity: 480,
	},
	{
		garage_id: 115,
		part_id: 323,
		quantity: 26,
	},
	{
		garage_id: 115,
		part_id: 324,
		quantity: 32,
	},
	{
		garage_id: 115,
		part_id: 325,
		quantity: 175,
	},
	{
		garage_id: 115,
		part_id: 326,
		quantity: 358,
	},
	{
		garage_id: 115,
		part_id: 327,
		quantity: 80,
	},
	{
		garage_id: 115,
		part_id: 328,
		quantity: 197,
	},
	{
		garage_id: 115,
		part_id: 329,
		quantity: 130,
	},
	{
		garage_id: 115,
		part_id: 330,
		quantity: 465,
	},
	{
		garage_id: 115,
		part_id: 331,
		quantity: 218,
	},
	{
		garage_id: 115,
		part_id: 332,
		quantity: 173,
	},
	{
		garage_id: 115,
		part_id: 333,
		quantity: 58,
	},
	{
		garage_id: 115,
		part_id: 334,
		quantity: 147,
	},
	{
		garage_id: 115,
		part_id: 335,
		quantity: 318,
	},
	{
		garage_id: 115,
		part_id: 336,
		quantity: 256,
	},
	{
		garage_id: 115,
		part_id: 337,
		quantity: 432,
	},
	{
		garage_id: 115,
		part_id: 338,
		quantity: 294,
	},
	{
		garage_id: 115,
		part_id: 339,
		quantity: 258,
	},
	{
		garage_id: 115,
		part_id: 340,
		quantity: 161,
	},
	{
		garage_id: 115,
		part_id: 341,
		quantity: 9,
	},
	{
		garage_id: 115,
		part_id: 342,
		quantity: 427,
	},
	{
		garage_id: 115,
		part_id: 343,
		quantity: 484,
	},
	{
		garage_id: 115,
		part_id: 344,
		quantity: 82,
	},
	{
		garage_id: 115,
		part_id: 345,
		quantity: 383,
	},
	{
		garage_id: 115,
		part_id: 346,
		quantity: 106,
	},
	{
		garage_id: 115,
		part_id: 347,
		quantity: 146,
	},
	{
		garage_id: 115,
		part_id: 348,
		quantity: 496,
	},
	{
		garage_id: 115,
		part_id: 349,
		quantity: 451,
	},
	{
		garage_id: 115,
		part_id: 350,
		quantity: 284,
	},
	{
		garage_id: 115,
		part_id: 351,
		quantity: 310,
	},
	{
		garage_id: 115,
		part_id: 352,
		quantity: 441,
	},
	{
		garage_id: 115,
		part_id: 353,
		quantity: 163,
	},
	{
		garage_id: 115,
		part_id: 354,
		quantity: 54,
	},
	{
		garage_id: 115,
		part_id: 355,
		quantity: 412,
	},
	{
		garage_id: 115,
		part_id: 356,
		quantity: 163,
	},
	{
		garage_id: 115,
		part_id: 357,
		quantity: 17,
	},
	{
		garage_id: 115,
		part_id: 358,
		quantity: 129,
	},
	{
		garage_id: 115,
		part_id: 359,
		quantity: 143,
	},
	{
		garage_id: 115,
		part_id: 360,
		quantity: 88,
	},
	{
		garage_id: 115,
		part_id: 361,
		quantity: 285,
	},
	{
		garage_id: 115,
		part_id: 362,
		quantity: 265,
	},
	{
		garage_id: 115,
		part_id: 363,
		quantity: 218,
	},
	{
		garage_id: 115,
		part_id: 364,
		quantity: 472,
	},
	{
		garage_id: 115,
		part_id: 365,
		quantity: 29,
	},
	{
		garage_id: 115,
		part_id: 366,
		quantity: 76,
	},
	{
		garage_id: 115,
		part_id: 367,
		quantity: 5,
	},
	{
		garage_id: 115,
		part_id: 368,
		quantity: 253,
	},
	{
		garage_id: 115,
		part_id: 369,
		quantity: 341,
	},
	{
		garage_id: 115,
		part_id: 370,
		quantity: 419,
	},
	{
		garage_id: 115,
		part_id: 371,
		quantity: 220,
	},
	{
		garage_id: 115,
		part_id: 372,
		quantity: 494,
	},
	{
		garage_id: 115,
		part_id: 373,
		quantity: 370,
	},
	{
		garage_id: 115,
		part_id: 374,
		quantity: 6,
	},
	{
		garage_id: 115,
		part_id: 375,
		quantity: 77,
	},
	{
		garage_id: 115,
		part_id: 376,
		quantity: 32,
	},
	{
		garage_id: 115,
		part_id: 377,
		quantity: 294,
	},
	{
		garage_id: 115,
		part_id: 378,
		quantity: 103,
	},
	{
		garage_id: 115,
		part_id: 379,
		quantity: 465,
	},
	{
		garage_id: 115,
		part_id: 380,
		quantity: 55,
	},
	{
		garage_id: 115,
		part_id: 381,
		quantity: 476,
	},
	{
		garage_id: 115,
		part_id: 382,
		quantity: 112,
	},
	{
		garage_id: 115,
		part_id: 383,
		quantity: 500,
	},
	{
		garage_id: 115,
		part_id: 384,
		quantity: 356,
	},
	{
		garage_id: 115,
		part_id: 385,
		quantity: 131,
	},
	{
		garage_id: 115,
		part_id: 386,
		quantity: 185,
	},
	{
		garage_id: 115,
		part_id: 387,
		quantity: 468,
	},
	{
		garage_id: 115,
		part_id: 388,
		quantity: 245,
	},
	{
		garage_id: 115,
		part_id: 389,
		quantity: 112,
	},
	{
		garage_id: 115,
		part_id: 390,
		quantity: 452,
	},
	{
		garage_id: 115,
		part_id: 391,
		quantity: 50,
	},
	{
		garage_id: 115,
		part_id: 392,
		quantity: 193,
	},
	{
		garage_id: 115,
		part_id: 393,
		quantity: 19,
	},
	{
		garage_id: 115,
		part_id: 394,
		quantity: 242,
	},
	{
		garage_id: 115,
		part_id: 395,
		quantity: 146,
	},
	{
		garage_id: 115,
		part_id: 396,
		quantity: 1,
	},
	{
		garage_id: 115,
		part_id: 397,
		quantity: 278,
	},
	{
		garage_id: 115,
		part_id: 398,
		quantity: 14,
	},
	{
		garage_id: 115,
		part_id: 399,
		quantity: 303,
	},
	{
		garage_id: 116,
		part_id: 0,
		quantity: 118,
	},
	{
		garage_id: 116,
		part_id: 1,
		quantity: 216,
	},
	{
		garage_id: 116,
		part_id: 2,
		quantity: 426,
	},
	{
		garage_id: 116,
		part_id: 3,
		quantity: 325,
	},
	{
		garage_id: 116,
		part_id: 4,
		quantity: 324,
	},
	{
		garage_id: 116,
		part_id: 5,
		quantity: 289,
	},
	{
		garage_id: 116,
		part_id: 6,
		quantity: 366,
	},
	{
		garage_id: 116,
		part_id: 7,
		quantity: 177,
	},
	{
		garage_id: 116,
		part_id: 8,
		quantity: 258,
	},
	{
		garage_id: 116,
		part_id: 9,
		quantity: 255,
	},
	{
		garage_id: 116,
		part_id: 10,
		quantity: 424,
	},
	{
		garage_id: 116,
		part_id: 11,
		quantity: 0,
	},
	{
		garage_id: 116,
		part_id: 12,
		quantity: 164,
	},
	{
		garage_id: 116,
		part_id: 13,
		quantity: 195,
	},
	{
		garage_id: 116,
		part_id: 14,
		quantity: 130,
	},
	{
		garage_id: 116,
		part_id: 15,
		quantity: 161,
	},
	{
		garage_id: 116,
		part_id: 16,
		quantity: 215,
	},
	{
		garage_id: 116,
		part_id: 17,
		quantity: 202,
	},
	{
		garage_id: 116,
		part_id: 18,
		quantity: 66,
	},
	{
		garage_id: 116,
		part_id: 19,
		quantity: 262,
	},
	{
		garage_id: 116,
		part_id: 20,
		quantity: 212,
	},
	{
		garage_id: 116,
		part_id: 21,
		quantity: 283,
	},
	{
		garage_id: 116,
		part_id: 22,
		quantity: 13,
	},
	{
		garage_id: 116,
		part_id: 23,
		quantity: 402,
	},
	{
		garage_id: 116,
		part_id: 24,
		quantity: 6,
	},
	{
		garage_id: 116,
		part_id: 25,
		quantity: 392,
	},
	{
		garage_id: 116,
		part_id: 26,
		quantity: 155,
	},
	{
		garage_id: 116,
		part_id: 27,
		quantity: 485,
	},
	{
		garage_id: 116,
		part_id: 28,
		quantity: 418,
	},
	{
		garage_id: 116,
		part_id: 29,
		quantity: 3,
	},
	{
		garage_id: 116,
		part_id: 30,
		quantity: 279,
	},
	{
		garage_id: 116,
		part_id: 31,
		quantity: 357,
	},
	{
		garage_id: 116,
		part_id: 32,
		quantity: 498,
	},
	{
		garage_id: 116,
		part_id: 33,
		quantity: 372,
	},
	{
		garage_id: 116,
		part_id: 34,
		quantity: 209,
	},
	{
		garage_id: 116,
		part_id: 35,
		quantity: 464,
	},
	{
		garage_id: 116,
		part_id: 36,
		quantity: 144,
	},
	{
		garage_id: 116,
		part_id: 37,
		quantity: 178,
	},
	{
		garage_id: 116,
		part_id: 38,
		quantity: 127,
	},
	{
		garage_id: 116,
		part_id: 39,
		quantity: 276,
	},
	{
		garage_id: 116,
		part_id: 40,
		quantity: 395,
	},
	{
		garage_id: 116,
		part_id: 41,
		quantity: 471,
	},
	{
		garage_id: 116,
		part_id: 42,
		quantity: 271,
	},
	{
		garage_id: 116,
		part_id: 43,
		quantity: 463,
	},
	{
		garage_id: 116,
		part_id: 44,
		quantity: 32,
	},
	{
		garage_id: 116,
		part_id: 45,
		quantity: 322,
	},
	{
		garage_id: 116,
		part_id: 46,
		quantity: 438,
	},
	{
		garage_id: 116,
		part_id: 47,
		quantity: 43,
	},
	{
		garage_id: 116,
		part_id: 48,
		quantity: 410,
	},
	{
		garage_id: 116,
		part_id: 49,
		quantity: 78,
	},
	{
		garage_id: 116,
		part_id: 50,
		quantity: 200,
	},
	{
		garage_id: 116,
		part_id: 51,
		quantity: 71,
	},
	{
		garage_id: 116,
		part_id: 52,
		quantity: 284,
	},
	{
		garage_id: 116,
		part_id: 53,
		quantity: 425,
	},
	{
		garage_id: 116,
		part_id: 54,
		quantity: 278,
	},
	{
		garage_id: 116,
		part_id: 55,
		quantity: 230,
	},
	{
		garage_id: 116,
		part_id: 56,
		quantity: 178,
	},
	{
		garage_id: 116,
		part_id: 57,
		quantity: 122,
	},
	{
		garage_id: 116,
		part_id: 58,
		quantity: 474,
	},
	{
		garage_id: 116,
		part_id: 59,
		quantity: 200,
	},
	{
		garage_id: 116,
		part_id: 60,
		quantity: 68,
	},
	{
		garage_id: 116,
		part_id: 61,
		quantity: 88,
	},
	{
		garage_id: 116,
		part_id: 62,
		quantity: 300,
	},
	{
		garage_id: 116,
		part_id: 63,
		quantity: 314,
	},
	{
		garage_id: 116,
		part_id: 64,
		quantity: 236,
	},
	{
		garage_id: 116,
		part_id: 65,
		quantity: 225,
	},
	{
		garage_id: 116,
		part_id: 66,
		quantity: 416,
	},
	{
		garage_id: 116,
		part_id: 67,
		quantity: 201,
	},
	{
		garage_id: 116,
		part_id: 68,
		quantity: 478,
	},
	{
		garage_id: 116,
		part_id: 69,
		quantity: 225,
	},
	{
		garage_id: 116,
		part_id: 70,
		quantity: 359,
	},
	{
		garage_id: 116,
		part_id: 71,
		quantity: 262,
	},
	{
		garage_id: 116,
		part_id: 72,
		quantity: 416,
	},
	{
		garage_id: 116,
		part_id: 73,
		quantity: 131,
	},
	{
		garage_id: 116,
		part_id: 74,
		quantity: 431,
	},
	{
		garage_id: 116,
		part_id: 75,
		quantity: 304,
	},
	{
		garage_id: 116,
		part_id: 76,
		quantity: 61,
	},
	{
		garage_id: 116,
		part_id: 77,
		quantity: 347,
	},
	{
		garage_id: 116,
		part_id: 78,
		quantity: 311,
	},
	{
		garage_id: 116,
		part_id: 79,
		quantity: 77,
	},
	{
		garage_id: 116,
		part_id: 80,
		quantity: 481,
	},
	{
		garage_id: 116,
		part_id: 81,
		quantity: 375,
	},
	{
		garage_id: 116,
		part_id: 82,
		quantity: 214,
	},
	{
		garage_id: 116,
		part_id: 83,
		quantity: 386,
	},
	{
		garage_id: 116,
		part_id: 84,
		quantity: 26,
	},
	{
		garage_id: 116,
		part_id: 85,
		quantity: 91,
	},
	{
		garage_id: 116,
		part_id: 86,
		quantity: 478,
	},
	{
		garage_id: 116,
		part_id: 87,
		quantity: 95,
	},
	{
		garage_id: 116,
		part_id: 88,
		quantity: 315,
	},
	{
		garage_id: 116,
		part_id: 89,
		quantity: 295,
	},
	{
		garage_id: 116,
		part_id: 90,
		quantity: 206,
	},
	{
		garage_id: 116,
		part_id: 91,
		quantity: 40,
	},
	{
		garage_id: 116,
		part_id: 92,
		quantity: 106,
	},
	{
		garage_id: 116,
		part_id: 93,
		quantity: 308,
	},
	{
		garage_id: 116,
		part_id: 94,
		quantity: 240,
	},
	{
		garage_id: 116,
		part_id: 95,
		quantity: 384,
	},
	{
		garage_id: 116,
		part_id: 96,
		quantity: 49,
	},
	{
		garage_id: 116,
		part_id: 97,
		quantity: 78,
	},
	{
		garage_id: 116,
		part_id: 98,
		quantity: 415,
	},
	{
		garage_id: 116,
		part_id: 99,
		quantity: 54,
	},
	{
		garage_id: 116,
		part_id: 100,
		quantity: 346,
	},
	{
		garage_id: 116,
		part_id: 101,
		quantity: 44,
	},
	{
		garage_id: 116,
		part_id: 102,
		quantity: 196,
	},
	{
		garage_id: 116,
		part_id: 103,
		quantity: 350,
	},
	{
		garage_id: 116,
		part_id: 104,
		quantity: 158,
	},
	{
		garage_id: 116,
		part_id: 105,
		quantity: 91,
	},
	{
		garage_id: 116,
		part_id: 106,
		quantity: 279,
	},
	{
		garage_id: 116,
		part_id: 107,
		quantity: 324,
	},
	{
		garage_id: 116,
		part_id: 108,
		quantity: 58,
	},
	{
		garage_id: 116,
		part_id: 109,
		quantity: 270,
	},
	{
		garage_id: 116,
		part_id: 110,
		quantity: 46,
	},
	{
		garage_id: 116,
		part_id: 111,
		quantity: 56,
	},
	{
		garage_id: 116,
		part_id: 112,
		quantity: 134,
	},
	{
		garage_id: 116,
		part_id: 113,
		quantity: 142,
	},
	{
		garage_id: 116,
		part_id: 114,
		quantity: 250,
	},
	{
		garage_id: 116,
		part_id: 115,
		quantity: 122,
	},
	{
		garage_id: 116,
		part_id: 116,
		quantity: 492,
	},
	{
		garage_id: 116,
		part_id: 117,
		quantity: 408,
	},
	{
		garage_id: 116,
		part_id: 118,
		quantity: 259,
	},
	{
		garage_id: 116,
		part_id: 119,
		quantity: 151,
	},
	{
		garage_id: 116,
		part_id: 120,
		quantity: 273,
	},
	{
		garage_id: 116,
		part_id: 121,
		quantity: 111,
	},
	{
		garage_id: 116,
		part_id: 122,
		quantity: 444,
	},
	{
		garage_id: 116,
		part_id: 123,
		quantity: 115,
	},
	{
		garage_id: 116,
		part_id: 124,
		quantity: 325,
	},
	{
		garage_id: 116,
		part_id: 125,
		quantity: 85,
	},
	{
		garage_id: 116,
		part_id: 126,
		quantity: 222,
	},
	{
		garage_id: 116,
		part_id: 127,
		quantity: 312,
	},
	{
		garage_id: 116,
		part_id: 128,
		quantity: 110,
	},
	{
		garage_id: 116,
		part_id: 129,
		quantity: 326,
	},
	{
		garage_id: 116,
		part_id: 130,
		quantity: 112,
	},
	{
		garage_id: 116,
		part_id: 131,
		quantity: 37,
	},
	{
		garage_id: 116,
		part_id: 132,
		quantity: 187,
	},
	{
		garage_id: 116,
		part_id: 133,
		quantity: 35,
	},
	{
		garage_id: 116,
		part_id: 134,
		quantity: 149,
	},
	{
		garage_id: 116,
		part_id: 135,
		quantity: 320,
	},
	{
		garage_id: 116,
		part_id: 136,
		quantity: 453,
	},
	{
		garage_id: 116,
		part_id: 137,
		quantity: 19,
	},
	{
		garage_id: 116,
		part_id: 138,
		quantity: 209,
	},
	{
		garage_id: 116,
		part_id: 139,
		quantity: 145,
	},
	{
		garage_id: 116,
		part_id: 140,
		quantity: 292,
	},
	{
		garage_id: 116,
		part_id: 141,
		quantity: 264,
	},
	{
		garage_id: 116,
		part_id: 142,
		quantity: 136,
	},
	{
		garage_id: 116,
		part_id: 143,
		quantity: 261,
	},
	{
		garage_id: 116,
		part_id: 144,
		quantity: 362,
	},
	{
		garage_id: 116,
		part_id: 145,
		quantity: 36,
	},
	{
		garage_id: 116,
		part_id: 146,
		quantity: 222,
	},
	{
		garage_id: 116,
		part_id: 147,
		quantity: 43,
	},
	{
		garage_id: 116,
		part_id: 148,
		quantity: 146,
	},
	{
		garage_id: 116,
		part_id: 149,
		quantity: 147,
	},
	{
		garage_id: 116,
		part_id: 150,
		quantity: 138,
	},
	{
		garage_id: 116,
		part_id: 151,
		quantity: 135,
	},
	{
		garage_id: 116,
		part_id: 152,
		quantity: 435,
	},
	{
		garage_id: 116,
		part_id: 153,
		quantity: 10,
	},
	{
		garage_id: 116,
		part_id: 154,
		quantity: 494,
	},
	{
		garage_id: 116,
		part_id: 155,
		quantity: 163,
	},
	{
		garage_id: 116,
		part_id: 156,
		quantity: 427,
	},
	{
		garage_id: 116,
		part_id: 157,
		quantity: 348,
	},
	{
		garage_id: 116,
		part_id: 158,
		quantity: 398,
	},
	{
		garage_id: 116,
		part_id: 159,
		quantity: 296,
	},
	{
		garage_id: 116,
		part_id: 160,
		quantity: 408,
	},
	{
		garage_id: 116,
		part_id: 161,
		quantity: 247,
	},
	{
		garage_id: 116,
		part_id: 162,
		quantity: 332,
	},
	{
		garage_id: 116,
		part_id: 163,
		quantity: 138,
	},
	{
		garage_id: 116,
		part_id: 164,
		quantity: 226,
	},
	{
		garage_id: 116,
		part_id: 165,
		quantity: 468,
	},
	{
		garage_id: 116,
		part_id: 166,
		quantity: 224,
	},
	{
		garage_id: 116,
		part_id: 167,
		quantity: 259,
	},
	{
		garage_id: 116,
		part_id: 168,
		quantity: 90,
	},
	{
		garage_id: 116,
		part_id: 169,
		quantity: 68,
	},
	{
		garage_id: 116,
		part_id: 170,
		quantity: 72,
	},
	{
		garage_id: 116,
		part_id: 171,
		quantity: 8,
	},
	{
		garage_id: 116,
		part_id: 172,
		quantity: 57,
	},
	{
		garage_id: 116,
		part_id: 173,
		quantity: 36,
	},
	{
		garage_id: 116,
		part_id: 174,
		quantity: 64,
	},
	{
		garage_id: 116,
		part_id: 175,
		quantity: 235,
	},
	{
		garage_id: 116,
		part_id: 176,
		quantity: 493,
	},
	{
		garage_id: 116,
		part_id: 177,
		quantity: 0,
	},
	{
		garage_id: 116,
		part_id: 178,
		quantity: 344,
	},
	{
		garage_id: 116,
		part_id: 179,
		quantity: 85,
	},
	{
		garage_id: 116,
		part_id: 180,
		quantity: 313,
	},
	{
		garage_id: 116,
		part_id: 181,
		quantity: 148,
	},
	{
		garage_id: 116,
		part_id: 182,
		quantity: 155,
	},
	{
		garage_id: 116,
		part_id: 183,
		quantity: 95,
	},
	{
		garage_id: 116,
		part_id: 184,
		quantity: 234,
	},
	{
		garage_id: 116,
		part_id: 185,
		quantity: 220,
	},
	{
		garage_id: 116,
		part_id: 186,
		quantity: 41,
	},
	{
		garage_id: 116,
		part_id: 187,
		quantity: 20,
	},
	{
		garage_id: 116,
		part_id: 188,
		quantity: 217,
	},
	{
		garage_id: 116,
		part_id: 189,
		quantity: 218,
	},
	{
		garage_id: 116,
		part_id: 190,
		quantity: 317,
	},
	{
		garage_id: 116,
		part_id: 191,
		quantity: 452,
	},
	{
		garage_id: 116,
		part_id: 192,
		quantity: 227,
	},
	{
		garage_id: 116,
		part_id: 193,
		quantity: 128,
	},
	{
		garage_id: 116,
		part_id: 194,
		quantity: 417,
	},
	{
		garage_id: 116,
		part_id: 195,
		quantity: 355,
	},
	{
		garage_id: 116,
		part_id: 196,
		quantity: 423,
	},
	{
		garage_id: 116,
		part_id: 197,
		quantity: 279,
	},
	{
		garage_id: 116,
		part_id: 198,
		quantity: 39,
	},
	{
		garage_id: 116,
		part_id: 199,
		quantity: 172,
	},
	{
		garage_id: 116,
		part_id: 200,
		quantity: 198,
	},
	{
		garage_id: 116,
		part_id: 201,
		quantity: 187,
	},
	{
		garage_id: 116,
		part_id: 202,
		quantity: 140,
	},
	{
		garage_id: 116,
		part_id: 203,
		quantity: 374,
	},
	{
		garage_id: 116,
		part_id: 204,
		quantity: 364,
	},
	{
		garage_id: 116,
		part_id: 205,
		quantity: 367,
	},
	{
		garage_id: 116,
		part_id: 206,
		quantity: 18,
	},
	{
		garage_id: 116,
		part_id: 207,
		quantity: 106,
	},
	{
		garage_id: 116,
		part_id: 208,
		quantity: 224,
	},
	{
		garage_id: 116,
		part_id: 209,
		quantity: 389,
	},
	{
		garage_id: 116,
		part_id: 210,
		quantity: 466,
	},
	{
		garage_id: 116,
		part_id: 211,
		quantity: 135,
	},
	{
		garage_id: 116,
		part_id: 212,
		quantity: 331,
	},
	{
		garage_id: 116,
		part_id: 213,
		quantity: 109,
	},
	{
		garage_id: 116,
		part_id: 214,
		quantity: 256,
	},
	{
		garage_id: 116,
		part_id: 215,
		quantity: 82,
	},
	{
		garage_id: 116,
		part_id: 216,
		quantity: 183,
	},
	{
		garage_id: 116,
		part_id: 217,
		quantity: 311,
	},
	{
		garage_id: 116,
		part_id: 218,
		quantity: 313,
	},
	{
		garage_id: 116,
		part_id: 219,
		quantity: 165,
	},
	{
		garage_id: 116,
		part_id: 220,
		quantity: 51,
	},
	{
		garage_id: 116,
		part_id: 221,
		quantity: 291,
	},
	{
		garage_id: 116,
		part_id: 222,
		quantity: 373,
	},
	{
		garage_id: 116,
		part_id: 223,
		quantity: 333,
	},
	{
		garage_id: 116,
		part_id: 224,
		quantity: 333,
	},
	{
		garage_id: 116,
		part_id: 225,
		quantity: 228,
	},
	{
		garage_id: 116,
		part_id: 226,
		quantity: 72,
	},
	{
		garage_id: 116,
		part_id: 227,
		quantity: 431,
	},
	{
		garage_id: 116,
		part_id: 228,
		quantity: 94,
	},
	{
		garage_id: 116,
		part_id: 229,
		quantity: 241,
	},
	{
		garage_id: 116,
		part_id: 230,
		quantity: 347,
	},
	{
		garage_id: 116,
		part_id: 231,
		quantity: 138,
	},
	{
		garage_id: 116,
		part_id: 232,
		quantity: 111,
	},
	{
		garage_id: 116,
		part_id: 233,
		quantity: 68,
	},
	{
		garage_id: 116,
		part_id: 234,
		quantity: 430,
	},
	{
		garage_id: 116,
		part_id: 235,
		quantity: 205,
	},
	{
		garage_id: 116,
		part_id: 236,
		quantity: 428,
	},
	{
		garage_id: 116,
		part_id: 237,
		quantity: 49,
	},
	{
		garage_id: 116,
		part_id: 238,
		quantity: 273,
	},
	{
		garage_id: 116,
		part_id: 239,
		quantity: 191,
	},
	{
		garage_id: 116,
		part_id: 240,
		quantity: 367,
	},
	{
		garage_id: 116,
		part_id: 241,
		quantity: 295,
	},
	{
		garage_id: 116,
		part_id: 242,
		quantity: 406,
	},
	{
		garage_id: 116,
		part_id: 243,
		quantity: 103,
	},
	{
		garage_id: 116,
		part_id: 244,
		quantity: 382,
	},
	{
		garage_id: 116,
		part_id: 245,
		quantity: 468,
	},
	{
		garage_id: 116,
		part_id: 246,
		quantity: 206,
	},
	{
		garage_id: 116,
		part_id: 247,
		quantity: 470,
	},
	{
		garage_id: 116,
		part_id: 248,
		quantity: 103,
	},
	{
		garage_id: 116,
		part_id: 249,
		quantity: 147,
	},
	{
		garage_id: 116,
		part_id: 250,
		quantity: 431,
	},
	{
		garage_id: 116,
		part_id: 251,
		quantity: 136,
	},
	{
		garage_id: 116,
		part_id: 252,
		quantity: 170,
	},
	{
		garage_id: 116,
		part_id: 253,
		quantity: 204,
	},
	{
		garage_id: 116,
		part_id: 254,
		quantity: 483,
	},
	{
		garage_id: 116,
		part_id: 255,
		quantity: 196,
	},
	{
		garage_id: 116,
		part_id: 256,
		quantity: 55,
	},
	{
		garage_id: 116,
		part_id: 257,
		quantity: 265,
	},
	{
		garage_id: 116,
		part_id: 258,
		quantity: 409,
	},
	{
		garage_id: 116,
		part_id: 259,
		quantity: 81,
	},
	{
		garage_id: 116,
		part_id: 260,
		quantity: 12,
	},
	{
		garage_id: 116,
		part_id: 261,
		quantity: 223,
	},
	{
		garage_id: 116,
		part_id: 262,
		quantity: 5,
	},
	{
		garage_id: 116,
		part_id: 263,
		quantity: 291,
	},
	{
		garage_id: 116,
		part_id: 264,
		quantity: 350,
	},
	{
		garage_id: 116,
		part_id: 265,
		quantity: 392,
	},
	{
		garage_id: 116,
		part_id: 266,
		quantity: 440,
	},
	{
		garage_id: 116,
		part_id: 267,
		quantity: 11,
	},
	{
		garage_id: 116,
		part_id: 268,
		quantity: 255,
	},
	{
		garage_id: 116,
		part_id: 269,
		quantity: 157,
	},
	{
		garage_id: 116,
		part_id: 270,
		quantity: 441,
	},
	{
		garage_id: 116,
		part_id: 271,
		quantity: 260,
	},
	{
		garage_id: 116,
		part_id: 272,
		quantity: 192,
	},
	{
		garage_id: 116,
		part_id: 273,
		quantity: 375,
	},
	{
		garage_id: 116,
		part_id: 274,
		quantity: 494,
	},
	{
		garage_id: 116,
		part_id: 275,
		quantity: 315,
	},
	{
		garage_id: 116,
		part_id: 276,
		quantity: 246,
	},
	{
		garage_id: 116,
		part_id: 277,
		quantity: 421,
	},
	{
		garage_id: 116,
		part_id: 278,
		quantity: 28,
	},
	{
		garage_id: 116,
		part_id: 279,
		quantity: 496,
	},
	{
		garage_id: 116,
		part_id: 280,
		quantity: 71,
	},
	{
		garage_id: 116,
		part_id: 281,
		quantity: 334,
	},
	{
		garage_id: 116,
		part_id: 282,
		quantity: 490,
	},
	{
		garage_id: 116,
		part_id: 283,
		quantity: 206,
	},
	{
		garage_id: 116,
		part_id: 284,
		quantity: 238,
	},
	{
		garage_id: 116,
		part_id: 285,
		quantity: 325,
	},
	{
		garage_id: 116,
		part_id: 286,
		quantity: 89,
	},
	{
		garage_id: 116,
		part_id: 287,
		quantity: 39,
	},
	{
		garage_id: 116,
		part_id: 288,
		quantity: 439,
	},
	{
		garage_id: 116,
		part_id: 289,
		quantity: 160,
	},
	{
		garage_id: 116,
		part_id: 290,
		quantity: 193,
	},
	{
		garage_id: 116,
		part_id: 291,
		quantity: 18,
	},
	{
		garage_id: 116,
		part_id: 292,
		quantity: 408,
	},
	{
		garage_id: 116,
		part_id: 293,
		quantity: 317,
	},
	{
		garage_id: 116,
		part_id: 294,
		quantity: 14,
	},
	{
		garage_id: 116,
		part_id: 295,
		quantity: 224,
	},
	{
		garage_id: 116,
		part_id: 296,
		quantity: 353,
	},
	{
		garage_id: 116,
		part_id: 297,
		quantity: 492,
	},
	{
		garage_id: 116,
		part_id: 298,
		quantity: 420,
	},
	{
		garage_id: 116,
		part_id: 299,
		quantity: 40,
	},
	{
		garage_id: 116,
		part_id: 300,
		quantity: 25,
	},
	{
		garage_id: 116,
		part_id: 301,
		quantity: 270,
	},
	{
		garage_id: 116,
		part_id: 302,
		quantity: 420,
	},
	{
		garage_id: 116,
		part_id: 303,
		quantity: 155,
	},
	{
		garage_id: 116,
		part_id: 304,
		quantity: 413,
	},
	{
		garage_id: 116,
		part_id: 305,
		quantity: 179,
	},
	{
		garage_id: 116,
		part_id: 306,
		quantity: 484,
	},
	{
		garage_id: 116,
		part_id: 307,
		quantity: 246,
	},
	{
		garage_id: 116,
		part_id: 308,
		quantity: 287,
	},
	{
		garage_id: 116,
		part_id: 309,
		quantity: 397,
	},
	{
		garage_id: 116,
		part_id: 310,
		quantity: 314,
	},
	{
		garage_id: 116,
		part_id: 311,
		quantity: 3,
	},
	{
		garage_id: 116,
		part_id: 312,
		quantity: 157,
	},
	{
		garage_id: 116,
		part_id: 313,
		quantity: 23,
	},
	{
		garage_id: 116,
		part_id: 314,
		quantity: 230,
	},
	{
		garage_id: 116,
		part_id: 315,
		quantity: 151,
	},
	{
		garage_id: 116,
		part_id: 316,
		quantity: 366,
	},
	{
		garage_id: 116,
		part_id: 317,
		quantity: 454,
	},
	{
		garage_id: 116,
		part_id: 318,
		quantity: 12,
	},
	{
		garage_id: 116,
		part_id: 319,
		quantity: 383,
	},
	{
		garage_id: 116,
		part_id: 320,
		quantity: 210,
	},
	{
		garage_id: 116,
		part_id: 321,
		quantity: 251,
	},
	{
		garage_id: 116,
		part_id: 322,
		quantity: 339,
	},
	{
		garage_id: 116,
		part_id: 323,
		quantity: 185,
	},
	{
		garage_id: 116,
		part_id: 324,
		quantity: 381,
	},
	{
		garage_id: 116,
		part_id: 325,
		quantity: 194,
	},
	{
		garage_id: 116,
		part_id: 326,
		quantity: 268,
	},
	{
		garage_id: 116,
		part_id: 327,
		quantity: 420,
	},
	{
		garage_id: 116,
		part_id: 328,
		quantity: 243,
	},
	{
		garage_id: 116,
		part_id: 329,
		quantity: 340,
	},
	{
		garage_id: 116,
		part_id: 330,
		quantity: 411,
	},
	{
		garage_id: 116,
		part_id: 331,
		quantity: 366,
	},
	{
		garage_id: 116,
		part_id: 332,
		quantity: 380,
	},
	{
		garage_id: 116,
		part_id: 333,
		quantity: 104,
	},
	{
		garage_id: 116,
		part_id: 334,
		quantity: 207,
	},
	{
		garage_id: 116,
		part_id: 335,
		quantity: 467,
	},
	{
		garage_id: 116,
		part_id: 336,
		quantity: 149,
	},
	{
		garage_id: 116,
		part_id: 337,
		quantity: 354,
	},
	{
		garage_id: 116,
		part_id: 338,
		quantity: 387,
	},
	{
		garage_id: 116,
		part_id: 339,
		quantity: 494,
	},
	{
		garage_id: 116,
		part_id: 340,
		quantity: 19,
	},
	{
		garage_id: 116,
		part_id: 341,
		quantity: 401,
	},
	{
		garage_id: 116,
		part_id: 342,
		quantity: 294,
	},
	{
		garage_id: 116,
		part_id: 343,
		quantity: 170,
	},
	{
		garage_id: 116,
		part_id: 344,
		quantity: 392,
	},
	{
		garage_id: 116,
		part_id: 345,
		quantity: 294,
	},
	{
		garage_id: 116,
		part_id: 346,
		quantity: 62,
	},
	{
		garage_id: 116,
		part_id: 347,
		quantity: 128,
	},
	{
		garage_id: 116,
		part_id: 348,
		quantity: 265,
	},
	{
		garage_id: 116,
		part_id: 349,
		quantity: 316,
	},
	{
		garage_id: 116,
		part_id: 350,
		quantity: 114,
	},
	{
		garage_id: 116,
		part_id: 351,
		quantity: 370,
	},
	{
		garage_id: 116,
		part_id: 352,
		quantity: 258,
	},
	{
		garage_id: 116,
		part_id: 353,
		quantity: 112,
	},
	{
		garage_id: 116,
		part_id: 354,
		quantity: 365,
	},
	{
		garage_id: 116,
		part_id: 355,
		quantity: 443,
	},
	{
		garage_id: 116,
		part_id: 356,
		quantity: 280,
	},
	{
		garage_id: 116,
		part_id: 357,
		quantity: 102,
	},
	{
		garage_id: 116,
		part_id: 358,
		quantity: 71,
	},
	{
		garage_id: 116,
		part_id: 359,
		quantity: 128,
	},
	{
		garage_id: 116,
		part_id: 360,
		quantity: 313,
	},
	{
		garage_id: 116,
		part_id: 361,
		quantity: 474,
	},
	{
		garage_id: 116,
		part_id: 362,
		quantity: 395,
	},
	{
		garage_id: 116,
		part_id: 363,
		quantity: 408,
	},
	{
		garage_id: 116,
		part_id: 364,
		quantity: 240,
	},
	{
		garage_id: 116,
		part_id: 365,
		quantity: 108,
	},
	{
		garage_id: 116,
		part_id: 366,
		quantity: 233,
	},
	{
		garage_id: 116,
		part_id: 367,
		quantity: 209,
	},
	{
		garage_id: 116,
		part_id: 368,
		quantity: 468,
	},
	{
		garage_id: 116,
		part_id: 369,
		quantity: 496,
	},
	{
		garage_id: 116,
		part_id: 370,
		quantity: 286,
	},
	{
		garage_id: 116,
		part_id: 371,
		quantity: 498,
	},
	{
		garage_id: 116,
		part_id: 372,
		quantity: 95,
	},
	{
		garage_id: 116,
		part_id: 373,
		quantity: 341,
	},
	{
		garage_id: 116,
		part_id: 374,
		quantity: 67,
	},
	{
		garage_id: 116,
		part_id: 375,
		quantity: 344,
	},
	{
		garage_id: 116,
		part_id: 376,
		quantity: 253,
	},
	{
		garage_id: 116,
		part_id: 377,
		quantity: 195,
	},
	{
		garage_id: 116,
		part_id: 378,
		quantity: 215,
	},
	{
		garage_id: 116,
		part_id: 379,
		quantity: 173,
	},
	{
		garage_id: 116,
		part_id: 380,
		quantity: 349,
	},
	{
		garage_id: 116,
		part_id: 381,
		quantity: 358,
	},
	{
		garage_id: 116,
		part_id: 382,
		quantity: 438,
	},
	{
		garage_id: 116,
		part_id: 383,
		quantity: 198,
	},
	{
		garage_id: 116,
		part_id: 384,
		quantity: 481,
	},
	{
		garage_id: 116,
		part_id: 385,
		quantity: 246,
	},
	{
		garage_id: 116,
		part_id: 386,
		quantity: 435,
	},
	{
		garage_id: 116,
		part_id: 387,
		quantity: 402,
	},
	{
		garage_id: 116,
		part_id: 388,
		quantity: 499,
	},
	{
		garage_id: 116,
		part_id: 389,
		quantity: 241,
	},
	{
		garage_id: 116,
		part_id: 390,
		quantity: 341,
	},
	{
		garage_id: 116,
		part_id: 391,
		quantity: 432,
	},
	{
		garage_id: 116,
		part_id: 392,
		quantity: 235,
	},
	{
		garage_id: 116,
		part_id: 393,
		quantity: 309,
	},
	{
		garage_id: 116,
		part_id: 394,
		quantity: 83,
	},
	{
		garage_id: 116,
		part_id: 395,
		quantity: 459,
	},
	{
		garage_id: 116,
		part_id: 396,
		quantity: 406,
	},
	{
		garage_id: 116,
		part_id: 397,
		quantity: 445,
	},
	{
		garage_id: 116,
		part_id: 398,
		quantity: 13,
	},
	{
		garage_id: 116,
		part_id: 399,
		quantity: 72,
	},
	{
		garage_id: 117,
		part_id: 0,
		quantity: 272,
	},
	{
		garage_id: 117,
		part_id: 1,
		quantity: 176,
	},
	{
		garage_id: 117,
		part_id: 2,
		quantity: 110,
	},
	{
		garage_id: 117,
		part_id: 3,
		quantity: 460,
	},
	{
		garage_id: 117,
		part_id: 4,
		quantity: 76,
	},
	{
		garage_id: 117,
		part_id: 5,
		quantity: 169,
	},
	{
		garage_id: 117,
		part_id: 6,
		quantity: 284,
	},
	{
		garage_id: 117,
		part_id: 7,
		quantity: 62,
	},
	{
		garage_id: 117,
		part_id: 8,
		quantity: 96,
	},
	{
		garage_id: 117,
		part_id: 9,
		quantity: 329,
	},
	{
		garage_id: 117,
		part_id: 10,
		quantity: 289,
	},
	{
		garage_id: 117,
		part_id: 11,
		quantity: 147,
	},
	{
		garage_id: 117,
		part_id: 12,
		quantity: 154,
	},
	{
		garage_id: 117,
		part_id: 13,
		quantity: 322,
	},
	{
		garage_id: 117,
		part_id: 14,
		quantity: 10,
	},
	{
		garage_id: 117,
		part_id: 15,
		quantity: 52,
	},
	{
		garage_id: 117,
		part_id: 16,
		quantity: 26,
	},
	{
		garage_id: 117,
		part_id: 17,
		quantity: 227,
	},
	{
		garage_id: 117,
		part_id: 18,
		quantity: 9,
	},
	{
		garage_id: 117,
		part_id: 19,
		quantity: 136,
	},
	{
		garage_id: 117,
		part_id: 20,
		quantity: 5,
	},
	{
		garage_id: 117,
		part_id: 21,
		quantity: 422,
	},
	{
		garage_id: 117,
		part_id: 22,
		quantity: 398,
	},
	{
		garage_id: 117,
		part_id: 23,
		quantity: 161,
	},
	{
		garage_id: 117,
		part_id: 24,
		quantity: 36,
	},
	{
		garage_id: 117,
		part_id: 25,
		quantity: 227,
	},
	{
		garage_id: 117,
		part_id: 26,
		quantity: 241,
	},
	{
		garage_id: 117,
		part_id: 27,
		quantity: 20,
	},
	{
		garage_id: 117,
		part_id: 28,
		quantity: 309,
	},
	{
		garage_id: 117,
		part_id: 29,
		quantity: 321,
	},
	{
		garage_id: 117,
		part_id: 30,
		quantity: 3,
	},
	{
		garage_id: 117,
		part_id: 31,
		quantity: 472,
	},
	{
		garage_id: 117,
		part_id: 32,
		quantity: 38,
	},
	{
		garage_id: 117,
		part_id: 33,
		quantity: 430,
	},
	{
		garage_id: 117,
		part_id: 34,
		quantity: 359,
	},
	{
		garage_id: 117,
		part_id: 35,
		quantity: 437,
	},
	{
		garage_id: 117,
		part_id: 36,
		quantity: 265,
	},
	{
		garage_id: 117,
		part_id: 37,
		quantity: 391,
	},
	{
		garage_id: 117,
		part_id: 38,
		quantity: 70,
	},
	{
		garage_id: 117,
		part_id: 39,
		quantity: 236,
	},
	{
		garage_id: 117,
		part_id: 40,
		quantity: 275,
	},
	{
		garage_id: 117,
		part_id: 41,
		quantity: 470,
	},
	{
		garage_id: 117,
		part_id: 42,
		quantity: 321,
	},
	{
		garage_id: 117,
		part_id: 43,
		quantity: 173,
	},
	{
		garage_id: 117,
		part_id: 44,
		quantity: 475,
	},
	{
		garage_id: 117,
		part_id: 45,
		quantity: 25,
	},
	{
		garage_id: 117,
		part_id: 46,
		quantity: 273,
	},
	{
		garage_id: 117,
		part_id: 47,
		quantity: 481,
	},
	{
		garage_id: 117,
		part_id: 48,
		quantity: 387,
	},
	{
		garage_id: 117,
		part_id: 49,
		quantity: 253,
	},
	{
		garage_id: 117,
		part_id: 50,
		quantity: 399,
	},
	{
		garage_id: 117,
		part_id: 51,
		quantity: 312,
	},
	{
		garage_id: 117,
		part_id: 52,
		quantity: 450,
	},
	{
		garage_id: 117,
		part_id: 53,
		quantity: 233,
	},
	{
		garage_id: 117,
		part_id: 54,
		quantity: 274,
	},
	{
		garage_id: 117,
		part_id: 55,
		quantity: 88,
	},
	{
		garage_id: 117,
		part_id: 56,
		quantity: 408,
	},
	{
		garage_id: 117,
		part_id: 57,
		quantity: 71,
	},
	{
		garage_id: 117,
		part_id: 58,
		quantity: 427,
	},
	{
		garage_id: 117,
		part_id: 59,
		quantity: 309,
	},
	{
		garage_id: 117,
		part_id: 60,
		quantity: 245,
	},
	{
		garage_id: 117,
		part_id: 61,
		quantity: 464,
	},
	{
		garage_id: 117,
		part_id: 62,
		quantity: 44,
	},
	{
		garage_id: 117,
		part_id: 63,
		quantity: 84,
	},
	{
		garage_id: 117,
		part_id: 64,
		quantity: 7,
	},
	{
		garage_id: 117,
		part_id: 65,
		quantity: 119,
	},
	{
		garage_id: 117,
		part_id: 66,
		quantity: 25,
	},
	{
		garage_id: 117,
		part_id: 67,
		quantity: 88,
	},
	{
		garage_id: 117,
		part_id: 68,
		quantity: 364,
	},
	{
		garage_id: 117,
		part_id: 69,
		quantity: 372,
	},
	{
		garage_id: 117,
		part_id: 70,
		quantity: 320,
	},
	{
		garage_id: 117,
		part_id: 71,
		quantity: 365,
	},
	{
		garage_id: 117,
		part_id: 72,
		quantity: 342,
	},
	{
		garage_id: 117,
		part_id: 73,
		quantity: 120,
	},
	{
		garage_id: 117,
		part_id: 74,
		quantity: 106,
	},
	{
		garage_id: 117,
		part_id: 75,
		quantity: 138,
	},
	{
		garage_id: 117,
		part_id: 76,
		quantity: 376,
	},
	{
		garage_id: 117,
		part_id: 77,
		quantity: 279,
	},
	{
		garage_id: 117,
		part_id: 78,
		quantity: 3,
	},
	{
		garage_id: 117,
		part_id: 79,
		quantity: 370,
	},
	{
		garage_id: 117,
		part_id: 80,
		quantity: 224,
	},
	{
		garage_id: 117,
		part_id: 81,
		quantity: 226,
	},
	{
		garage_id: 117,
		part_id: 82,
		quantity: 255,
	},
	{
		garage_id: 117,
		part_id: 83,
		quantity: 185,
	},
	{
		garage_id: 117,
		part_id: 84,
		quantity: 259,
	},
	{
		garage_id: 117,
		part_id: 85,
		quantity: 247,
	},
	{
		garage_id: 117,
		part_id: 86,
		quantity: 150,
	},
	{
		garage_id: 117,
		part_id: 87,
		quantity: 32,
	},
	{
		garage_id: 117,
		part_id: 88,
		quantity: 325,
	},
	{
		garage_id: 117,
		part_id: 89,
		quantity: 493,
	},
	{
		garage_id: 117,
		part_id: 90,
		quantity: 32,
	},
	{
		garage_id: 117,
		part_id: 91,
		quantity: 71,
	},
	{
		garage_id: 117,
		part_id: 92,
		quantity: 106,
	},
	{
		garage_id: 117,
		part_id: 93,
		quantity: 1,
	},
	{
		garage_id: 117,
		part_id: 94,
		quantity: 141,
	},
	{
		garage_id: 117,
		part_id: 95,
		quantity: 236,
	},
	{
		garage_id: 117,
		part_id: 96,
		quantity: 485,
	},
	{
		garage_id: 117,
		part_id: 97,
		quantity: 420,
	},
	{
		garage_id: 117,
		part_id: 98,
		quantity: 338,
	},
	{
		garage_id: 117,
		part_id: 99,
		quantity: 224,
	},
	{
		garage_id: 117,
		part_id: 100,
		quantity: 472,
	},
	{
		garage_id: 117,
		part_id: 101,
		quantity: 280,
	},
	{
		garage_id: 117,
		part_id: 102,
		quantity: 194,
	},
	{
		garage_id: 117,
		part_id: 103,
		quantity: 481,
	},
	{
		garage_id: 117,
		part_id: 104,
		quantity: 223,
	},
	{
		garage_id: 117,
		part_id: 105,
		quantity: 235,
	},
	{
		garage_id: 117,
		part_id: 106,
		quantity: 201,
	},
	{
		garage_id: 117,
		part_id: 107,
		quantity: 342,
	},
	{
		garage_id: 117,
		part_id: 108,
		quantity: 399,
	},
	{
		garage_id: 117,
		part_id: 109,
		quantity: 313,
	},
	{
		garage_id: 117,
		part_id: 110,
		quantity: 391,
	},
	{
		garage_id: 117,
		part_id: 111,
		quantity: 111,
	},
	{
		garage_id: 117,
		part_id: 112,
		quantity: 419,
	},
	{
		garage_id: 117,
		part_id: 113,
		quantity: 93,
	},
	{
		garage_id: 117,
		part_id: 114,
		quantity: 295,
	},
	{
		garage_id: 117,
		part_id: 115,
		quantity: 407,
	},
	{
		garage_id: 117,
		part_id: 116,
		quantity: 178,
	},
	{
		garage_id: 117,
		part_id: 117,
		quantity: 432,
	},
	{
		garage_id: 117,
		part_id: 118,
		quantity: 129,
	},
	{
		garage_id: 117,
		part_id: 119,
		quantity: 19,
	},
	{
		garage_id: 117,
		part_id: 120,
		quantity: 434,
	},
	{
		garage_id: 117,
		part_id: 121,
		quantity: 209,
	},
	{
		garage_id: 117,
		part_id: 122,
		quantity: 477,
	},
	{
		garage_id: 117,
		part_id: 123,
		quantity: 279,
	},
	{
		garage_id: 117,
		part_id: 124,
		quantity: 67,
	},
	{
		garage_id: 117,
		part_id: 125,
		quantity: 449,
	},
	{
		garage_id: 117,
		part_id: 126,
		quantity: 416,
	},
	{
		garage_id: 117,
		part_id: 127,
		quantity: 262,
	},
	{
		garage_id: 117,
		part_id: 128,
		quantity: 104,
	},
	{
		garage_id: 117,
		part_id: 129,
		quantity: 24,
	},
	{
		garage_id: 117,
		part_id: 130,
		quantity: 402,
	},
	{
		garage_id: 117,
		part_id: 131,
		quantity: 131,
	},
	{
		garage_id: 117,
		part_id: 132,
		quantity: 40,
	},
	{
		garage_id: 117,
		part_id: 133,
		quantity: 181,
	},
	{
		garage_id: 117,
		part_id: 134,
		quantity: 436,
	},
	{
		garage_id: 117,
		part_id: 135,
		quantity: 40,
	},
	{
		garage_id: 117,
		part_id: 136,
		quantity: 247,
	},
	{
		garage_id: 117,
		part_id: 137,
		quantity: 444,
	},
	{
		garage_id: 117,
		part_id: 138,
		quantity: 72,
	},
	{
		garage_id: 117,
		part_id: 139,
		quantity: 135,
	},
	{
		garage_id: 117,
		part_id: 140,
		quantity: 162,
	},
	{
		garage_id: 117,
		part_id: 141,
		quantity: 273,
	},
	{
		garage_id: 117,
		part_id: 142,
		quantity: 127,
	},
	{
		garage_id: 117,
		part_id: 143,
		quantity: 258,
	},
	{
		garage_id: 117,
		part_id: 144,
		quantity: 15,
	},
	{
		garage_id: 117,
		part_id: 145,
		quantity: 80,
	},
	{
		garage_id: 117,
		part_id: 146,
		quantity: 470,
	},
	{
		garage_id: 117,
		part_id: 147,
		quantity: 248,
	},
	{
		garage_id: 117,
		part_id: 148,
		quantity: 283,
	},
	{
		garage_id: 117,
		part_id: 149,
		quantity: 129,
	},
	{
		garage_id: 117,
		part_id: 150,
		quantity: 296,
	},
	{
		garage_id: 117,
		part_id: 151,
		quantity: 76,
	},
	{
		garage_id: 117,
		part_id: 152,
		quantity: 44,
	},
	{
		garage_id: 117,
		part_id: 153,
		quantity: 399,
	},
	{
		garage_id: 117,
		part_id: 154,
		quantity: 131,
	},
	{
		garage_id: 117,
		part_id: 155,
		quantity: 422,
	},
	{
		garage_id: 117,
		part_id: 156,
		quantity: 126,
	},
	{
		garage_id: 117,
		part_id: 157,
		quantity: 15,
	},
	{
		garage_id: 117,
		part_id: 158,
		quantity: 201,
	},
	{
		garage_id: 117,
		part_id: 159,
		quantity: 197,
	},
	{
		garage_id: 117,
		part_id: 160,
		quantity: 292,
	},
	{
		garage_id: 117,
		part_id: 161,
		quantity: 144,
	},
	{
		garage_id: 117,
		part_id: 162,
		quantity: 407,
	},
	{
		garage_id: 117,
		part_id: 163,
		quantity: 290,
	},
	{
		garage_id: 117,
		part_id: 164,
		quantity: 102,
	},
	{
		garage_id: 117,
		part_id: 165,
		quantity: 291,
	},
	{
		garage_id: 117,
		part_id: 166,
		quantity: 418,
	},
	{
		garage_id: 117,
		part_id: 167,
		quantity: 160,
	},
	{
		garage_id: 117,
		part_id: 168,
		quantity: 87,
	},
	{
		garage_id: 117,
		part_id: 169,
		quantity: 468,
	},
	{
		garage_id: 117,
		part_id: 170,
		quantity: 39,
	},
	{
		garage_id: 117,
		part_id: 171,
		quantity: 162,
	},
	{
		garage_id: 117,
		part_id: 172,
		quantity: 464,
	},
	{
		garage_id: 117,
		part_id: 173,
		quantity: 179,
	},
	{
		garage_id: 117,
		part_id: 174,
		quantity: 38,
	},
	{
		garage_id: 117,
		part_id: 175,
		quantity: 262,
	},
	{
		garage_id: 117,
		part_id: 176,
		quantity: 465,
	},
	{
		garage_id: 117,
		part_id: 177,
		quantity: 27,
	},
	{
		garage_id: 117,
		part_id: 178,
		quantity: 498,
	},
	{
		garage_id: 117,
		part_id: 179,
		quantity: 453,
	},
	{
		garage_id: 117,
		part_id: 180,
		quantity: 443,
	},
	{
		garage_id: 117,
		part_id: 181,
		quantity: 328,
	},
	{
		garage_id: 117,
		part_id: 182,
		quantity: 334,
	},
	{
		garage_id: 117,
		part_id: 183,
		quantity: 65,
	},
	{
		garage_id: 117,
		part_id: 184,
		quantity: 290,
	},
	{
		garage_id: 117,
		part_id: 185,
		quantity: 321,
	},
	{
		garage_id: 117,
		part_id: 186,
		quantity: 457,
	},
	{
		garage_id: 117,
		part_id: 187,
		quantity: 191,
	},
	{
		garage_id: 117,
		part_id: 188,
		quantity: 219,
	},
	{
		garage_id: 117,
		part_id: 189,
		quantity: 498,
	},
	{
		garage_id: 117,
		part_id: 190,
		quantity: 216,
	},
	{
		garage_id: 117,
		part_id: 191,
		quantity: 487,
	},
	{
		garage_id: 117,
		part_id: 192,
		quantity: 476,
	},
	{
		garage_id: 117,
		part_id: 193,
		quantity: 72,
	},
	{
		garage_id: 117,
		part_id: 194,
		quantity: 270,
	},
	{
		garage_id: 117,
		part_id: 195,
		quantity: 96,
	},
	{
		garage_id: 117,
		part_id: 196,
		quantity: 249,
	},
	{
		garage_id: 117,
		part_id: 197,
		quantity: 134,
	},
	{
		garage_id: 117,
		part_id: 198,
		quantity: 17,
	},
	{
		garage_id: 117,
		part_id: 199,
		quantity: 27,
	},
	{
		garage_id: 117,
		part_id: 200,
		quantity: 499,
	},
	{
		garage_id: 117,
		part_id: 201,
		quantity: 214,
	},
	{
		garage_id: 117,
		part_id: 202,
		quantity: 155,
	},
	{
		garage_id: 117,
		part_id: 203,
		quantity: 42,
	},
	{
		garage_id: 117,
		part_id: 204,
		quantity: 233,
	},
	{
		garage_id: 117,
		part_id: 205,
		quantity: 497,
	},
	{
		garage_id: 117,
		part_id: 206,
		quantity: 356,
	},
	{
		garage_id: 117,
		part_id: 207,
		quantity: 256,
	},
	{
		garage_id: 117,
		part_id: 208,
		quantity: 310,
	},
	{
		garage_id: 117,
		part_id: 209,
		quantity: 421,
	},
	{
		garage_id: 117,
		part_id: 210,
		quantity: 447,
	},
	{
		garage_id: 117,
		part_id: 211,
		quantity: 495,
	},
	{
		garage_id: 117,
		part_id: 212,
		quantity: 10,
	},
	{
		garage_id: 117,
		part_id: 213,
		quantity: 407,
	},
	{
		garage_id: 117,
		part_id: 214,
		quantity: 200,
	},
	{
		garage_id: 117,
		part_id: 215,
		quantity: 208,
	},
	{
		garage_id: 117,
		part_id: 216,
		quantity: 262,
	},
	{
		garage_id: 117,
		part_id: 217,
		quantity: 390,
	},
	{
		garage_id: 117,
		part_id: 218,
		quantity: 22,
	},
	{
		garage_id: 117,
		part_id: 219,
		quantity: 459,
	},
	{
		garage_id: 117,
		part_id: 220,
		quantity: 80,
	},
	{
		garage_id: 117,
		part_id: 221,
		quantity: 144,
	},
	{
		garage_id: 117,
		part_id: 222,
		quantity: 89,
	},
	{
		garage_id: 117,
		part_id: 223,
		quantity: 136,
	},
	{
		garage_id: 117,
		part_id: 224,
		quantity: 85,
	},
	{
		garage_id: 117,
		part_id: 225,
		quantity: 279,
	},
	{
		garage_id: 117,
		part_id: 226,
		quantity: 23,
	},
	{
		garage_id: 117,
		part_id: 227,
		quantity: 123,
	},
	{
		garage_id: 117,
		part_id: 228,
		quantity: 271,
	},
	{
		garage_id: 117,
		part_id: 229,
		quantity: 358,
	},
	{
		garage_id: 117,
		part_id: 230,
		quantity: 204,
	},
	{
		garage_id: 117,
		part_id: 231,
		quantity: 48,
	},
	{
		garage_id: 117,
		part_id: 232,
		quantity: 222,
	},
	{
		garage_id: 117,
		part_id: 233,
		quantity: 370,
	},
	{
		garage_id: 117,
		part_id: 234,
		quantity: 235,
	},
	{
		garage_id: 117,
		part_id: 235,
		quantity: 296,
	},
	{
		garage_id: 117,
		part_id: 236,
		quantity: 242,
	},
	{
		garage_id: 117,
		part_id: 237,
		quantity: 459,
	},
	{
		garage_id: 117,
		part_id: 238,
		quantity: 27,
	},
	{
		garage_id: 117,
		part_id: 239,
		quantity: 11,
	},
	{
		garage_id: 117,
		part_id: 240,
		quantity: 57,
	},
	{
		garage_id: 117,
		part_id: 241,
		quantity: 215,
	},
	{
		garage_id: 117,
		part_id: 242,
		quantity: 315,
	},
	{
		garage_id: 117,
		part_id: 243,
		quantity: 7,
	},
	{
		garage_id: 117,
		part_id: 244,
		quantity: 407,
	},
	{
		garage_id: 117,
		part_id: 245,
		quantity: 217,
	},
	{
		garage_id: 117,
		part_id: 246,
		quantity: 272,
	},
	{
		garage_id: 117,
		part_id: 247,
		quantity: 164,
	},
	{
		garage_id: 117,
		part_id: 248,
		quantity: 159,
	},
	{
		garage_id: 117,
		part_id: 249,
		quantity: 416,
	},
	{
		garage_id: 117,
		part_id: 250,
		quantity: 348,
	},
	{
		garage_id: 117,
		part_id: 251,
		quantity: 275,
	},
	{
		garage_id: 117,
		part_id: 252,
		quantity: 384,
	},
	{
		garage_id: 117,
		part_id: 253,
		quantity: 208,
	},
	{
		garage_id: 117,
		part_id: 254,
		quantity: 234,
	},
	{
		garage_id: 117,
		part_id: 255,
		quantity: 50,
	},
	{
		garage_id: 117,
		part_id: 256,
		quantity: 95,
	},
	{
		garage_id: 117,
		part_id: 257,
		quantity: 337,
	},
	{
		garage_id: 117,
		part_id: 258,
		quantity: 202,
	},
	{
		garage_id: 117,
		part_id: 259,
		quantity: 251,
	},
	{
		garage_id: 117,
		part_id: 260,
		quantity: 289,
	},
	{
		garage_id: 117,
		part_id: 261,
		quantity: 85,
	},
	{
		garage_id: 117,
		part_id: 262,
		quantity: 255,
	},
	{
		garage_id: 117,
		part_id: 263,
		quantity: 325,
	},
	{
		garage_id: 117,
		part_id: 264,
		quantity: 5,
	},
	{
		garage_id: 117,
		part_id: 265,
		quantity: 145,
	},
	{
		garage_id: 117,
		part_id: 266,
		quantity: 116,
	},
	{
		garage_id: 117,
		part_id: 267,
		quantity: 315,
	},
	{
		garage_id: 117,
		part_id: 268,
		quantity: 253,
	},
	{
		garage_id: 117,
		part_id: 269,
		quantity: 446,
	},
	{
		garage_id: 117,
		part_id: 270,
		quantity: 51,
	},
	{
		garage_id: 117,
		part_id: 271,
		quantity: 203,
	},
	{
		garage_id: 117,
		part_id: 272,
		quantity: 85,
	},
	{
		garage_id: 117,
		part_id: 273,
		quantity: 122,
	},
	{
		garage_id: 117,
		part_id: 274,
		quantity: 417,
	},
	{
		garage_id: 117,
		part_id: 275,
		quantity: 473,
	},
	{
		garage_id: 117,
		part_id: 276,
		quantity: 144,
	},
	{
		garage_id: 117,
		part_id: 277,
		quantity: 229,
	},
	{
		garage_id: 117,
		part_id: 278,
		quantity: 438,
	},
	{
		garage_id: 117,
		part_id: 279,
		quantity: 121,
	},
	{
		garage_id: 117,
		part_id: 280,
		quantity: 35,
	},
	{
		garage_id: 117,
		part_id: 281,
		quantity: 20,
	},
	{
		garage_id: 117,
		part_id: 282,
		quantity: 48,
	},
	{
		garage_id: 117,
		part_id: 283,
		quantity: 122,
	},
	{
		garage_id: 117,
		part_id: 284,
		quantity: 382,
	},
	{
		garage_id: 117,
		part_id: 285,
		quantity: 337,
	},
	{
		garage_id: 117,
		part_id: 286,
		quantity: 271,
	},
	{
		garage_id: 117,
		part_id: 287,
		quantity: 126,
	},
	{
		garage_id: 117,
		part_id: 288,
		quantity: 327,
	},
	{
		garage_id: 117,
		part_id: 289,
		quantity: 186,
	},
	{
		garage_id: 117,
		part_id: 290,
		quantity: 76,
	},
	{
		garage_id: 117,
		part_id: 291,
		quantity: 148,
	},
	{
		garage_id: 117,
		part_id: 292,
		quantity: 85,
	},
	{
		garage_id: 117,
		part_id: 293,
		quantity: 13,
	},
	{
		garage_id: 117,
		part_id: 294,
		quantity: 310,
	},
	{
		garage_id: 117,
		part_id: 295,
		quantity: 359,
	},
	{
		garage_id: 117,
		part_id: 296,
		quantity: 499,
	},
	{
		garage_id: 117,
		part_id: 297,
		quantity: 260,
	},
	{
		garage_id: 117,
		part_id: 298,
		quantity: 215,
	},
	{
		garage_id: 117,
		part_id: 299,
		quantity: 352,
	},
	{
		garage_id: 117,
		part_id: 300,
		quantity: 73,
	},
	{
		garage_id: 117,
		part_id: 301,
		quantity: 72,
	},
	{
		garage_id: 117,
		part_id: 302,
		quantity: 418,
	},
	{
		garage_id: 117,
		part_id: 303,
		quantity: 314,
	},
	{
		garage_id: 117,
		part_id: 304,
		quantity: 192,
	},
	{
		garage_id: 117,
		part_id: 305,
		quantity: 436,
	},
	{
		garage_id: 117,
		part_id: 306,
		quantity: 245,
	},
	{
		garage_id: 117,
		part_id: 307,
		quantity: 32,
	},
	{
		garage_id: 117,
		part_id: 308,
		quantity: 193,
	},
	{
		garage_id: 117,
		part_id: 309,
		quantity: 231,
	},
	{
		garage_id: 117,
		part_id: 310,
		quantity: 161,
	},
	{
		garage_id: 117,
		part_id: 311,
		quantity: 303,
	},
	{
		garage_id: 117,
		part_id: 312,
		quantity: 19,
	},
	{
		garage_id: 117,
		part_id: 313,
		quantity: 112,
	},
	{
		garage_id: 117,
		part_id: 314,
		quantity: 455,
	},
	{
		garage_id: 117,
		part_id: 315,
		quantity: 193,
	},
	{
		garage_id: 117,
		part_id: 316,
		quantity: 266,
	},
	{
		garage_id: 117,
		part_id: 317,
		quantity: 499,
	},
	{
		garage_id: 117,
		part_id: 318,
		quantity: 473,
	},
	{
		garage_id: 117,
		part_id: 319,
		quantity: 250,
	},
	{
		garage_id: 117,
		part_id: 320,
		quantity: 264,
	},
	{
		garage_id: 117,
		part_id: 321,
		quantity: 160,
	},
	{
		garage_id: 117,
		part_id: 322,
		quantity: 388,
	},
	{
		garage_id: 117,
		part_id: 323,
		quantity: 325,
	},
	{
		garage_id: 117,
		part_id: 324,
		quantity: 198,
	},
	{
		garage_id: 117,
		part_id: 325,
		quantity: 15,
	},
	{
		garage_id: 117,
		part_id: 326,
		quantity: 294,
	},
	{
		garage_id: 117,
		part_id: 327,
		quantity: 181,
	},
	{
		garage_id: 117,
		part_id: 328,
		quantity: 474,
	},
	{
		garage_id: 117,
		part_id: 329,
		quantity: 46,
	},
	{
		garage_id: 117,
		part_id: 330,
		quantity: 289,
	},
	{
		garage_id: 117,
		part_id: 331,
		quantity: 254,
	},
	{
		garage_id: 117,
		part_id: 332,
		quantity: 107,
	},
	{
		garage_id: 117,
		part_id: 333,
		quantity: 448,
	},
	{
		garage_id: 117,
		part_id: 334,
		quantity: 276,
	},
	{
		garage_id: 117,
		part_id: 335,
		quantity: 93,
	},
	{
		garage_id: 117,
		part_id: 336,
		quantity: 72,
	},
	{
		garage_id: 117,
		part_id: 337,
		quantity: 182,
	},
	{
		garage_id: 117,
		part_id: 338,
		quantity: 299,
	},
	{
		garage_id: 117,
		part_id: 339,
		quantity: 261,
	},
	{
		garage_id: 117,
		part_id: 340,
		quantity: 154,
	},
	{
		garage_id: 117,
		part_id: 341,
		quantity: 107,
	},
	{
		garage_id: 117,
		part_id: 342,
		quantity: 350,
	},
	{
		garage_id: 117,
		part_id: 343,
		quantity: 207,
	},
	{
		garage_id: 117,
		part_id: 344,
		quantity: 99,
	},
	{
		garage_id: 117,
		part_id: 345,
		quantity: 79,
	},
	{
		garage_id: 117,
		part_id: 346,
		quantity: 277,
	},
	{
		garage_id: 117,
		part_id: 347,
		quantity: 199,
	},
	{
		garage_id: 117,
		part_id: 348,
		quantity: 301,
	},
	{
		garage_id: 117,
		part_id: 349,
		quantity: 458,
	},
	{
		garage_id: 117,
		part_id: 350,
		quantity: 315,
	},
	{
		garage_id: 117,
		part_id: 351,
		quantity: 494,
	},
	{
		garage_id: 117,
		part_id: 352,
		quantity: 313,
	},
	{
		garage_id: 117,
		part_id: 353,
		quantity: 429,
	},
	{
		garage_id: 117,
		part_id: 354,
		quantity: 292,
	},
	{
		garage_id: 117,
		part_id: 355,
		quantity: 103,
	},
	{
		garage_id: 117,
		part_id: 356,
		quantity: 300,
	},
	{
		garage_id: 117,
		part_id: 357,
		quantity: 150,
	},
	{
		garage_id: 117,
		part_id: 358,
		quantity: 149,
	},
	{
		garage_id: 117,
		part_id: 359,
		quantity: 364,
	},
	{
		garage_id: 117,
		part_id: 360,
		quantity: 11,
	},
	{
		garage_id: 117,
		part_id: 361,
		quantity: 387,
	},
	{
		garage_id: 117,
		part_id: 362,
		quantity: 61,
	},
	{
		garage_id: 117,
		part_id: 363,
		quantity: 388,
	},
	{
		garage_id: 117,
		part_id: 364,
		quantity: 379,
	},
	{
		garage_id: 117,
		part_id: 365,
		quantity: 83,
	},
	{
		garage_id: 117,
		part_id: 366,
		quantity: 235,
	},
	{
		garage_id: 117,
		part_id: 367,
		quantity: 297,
	},
	{
		garage_id: 117,
		part_id: 368,
		quantity: 82,
	},
	{
		garage_id: 117,
		part_id: 369,
		quantity: 7,
	},
	{
		garage_id: 117,
		part_id: 370,
		quantity: 385,
	},
	{
		garage_id: 117,
		part_id: 371,
		quantity: 179,
	},
	{
		garage_id: 117,
		part_id: 372,
		quantity: 153,
	},
	{
		garage_id: 117,
		part_id: 373,
		quantity: 494,
	},
	{
		garage_id: 117,
		part_id: 374,
		quantity: 166,
	},
	{
		garage_id: 117,
		part_id: 375,
		quantity: 26,
	},
	{
		garage_id: 117,
		part_id: 376,
		quantity: 88,
	},
	{
		garage_id: 117,
		part_id: 377,
		quantity: 96,
	},
	{
		garage_id: 117,
		part_id: 378,
		quantity: 223,
	},
	{
		garage_id: 117,
		part_id: 379,
		quantity: 55,
	},
	{
		garage_id: 117,
		part_id: 380,
		quantity: 431,
	},
	{
		garage_id: 117,
		part_id: 381,
		quantity: 138,
	},
	{
		garage_id: 117,
		part_id: 382,
		quantity: 244,
	},
	{
		garage_id: 117,
		part_id: 383,
		quantity: 240,
	},
	{
		garage_id: 117,
		part_id: 384,
		quantity: 103,
	},
	{
		garage_id: 117,
		part_id: 385,
		quantity: 167,
	},
	{
		garage_id: 117,
		part_id: 386,
		quantity: 148,
	},
	{
		garage_id: 117,
		part_id: 387,
		quantity: 167,
	},
	{
		garage_id: 117,
		part_id: 388,
		quantity: 235,
	},
	{
		garage_id: 117,
		part_id: 389,
		quantity: 46,
	},
	{
		garage_id: 117,
		part_id: 390,
		quantity: 163,
	},
	{
		garage_id: 117,
		part_id: 391,
		quantity: 125,
	},
	{
		garage_id: 117,
		part_id: 392,
		quantity: 307,
	},
	{
		garage_id: 117,
		part_id: 393,
		quantity: 301,
	},
	{
		garage_id: 117,
		part_id: 394,
		quantity: 170,
	},
	{
		garage_id: 117,
		part_id: 395,
		quantity: 428,
	},
	{
		garage_id: 117,
		part_id: 396,
		quantity: 161,
	},
	{
		garage_id: 117,
		part_id: 397,
		quantity: 258,
	},
	{
		garage_id: 117,
		part_id: 398,
		quantity: 72,
	},
	{
		garage_id: 117,
		part_id: 399,
		quantity: 346,
	},
	{
		garage_id: 118,
		part_id: 0,
		quantity: 55,
	},
	{
		garage_id: 118,
		part_id: 1,
		quantity: 75,
	},
	{
		garage_id: 118,
		part_id: 2,
		quantity: 295,
	},
	{
		garage_id: 118,
		part_id: 3,
		quantity: 28,
	},
	{
		garage_id: 118,
		part_id: 4,
		quantity: 451,
	},
	{
		garage_id: 118,
		part_id: 5,
		quantity: 210,
	},
	{
		garage_id: 118,
		part_id: 6,
		quantity: 120,
	},
	{
		garage_id: 118,
		part_id: 7,
		quantity: 6,
	},
	{
		garage_id: 118,
		part_id: 8,
		quantity: 242,
	},
	{
		garage_id: 118,
		part_id: 9,
		quantity: 79,
	},
	{
		garage_id: 118,
		part_id: 10,
		quantity: 408,
	},
	{
		garage_id: 118,
		part_id: 11,
		quantity: 65,
	},
	{
		garage_id: 118,
		part_id: 12,
		quantity: 282,
	},
	{
		garage_id: 118,
		part_id: 13,
		quantity: 3,
	},
	{
		garage_id: 118,
		part_id: 14,
		quantity: 414,
	},
	{
		garage_id: 118,
		part_id: 15,
		quantity: 344,
	},
	{
		garage_id: 118,
		part_id: 16,
		quantity: 160,
	},
	{
		garage_id: 118,
		part_id: 17,
		quantity: 194,
	},
	{
		garage_id: 118,
		part_id: 18,
		quantity: 480,
	},
	{
		garage_id: 118,
		part_id: 19,
		quantity: 290,
	},
	{
		garage_id: 118,
		part_id: 20,
		quantity: 74,
	},
	{
		garage_id: 118,
		part_id: 21,
		quantity: 85,
	},
	{
		garage_id: 118,
		part_id: 22,
		quantity: 328,
	},
	{
		garage_id: 118,
		part_id: 23,
		quantity: 418,
	},
	{
		garage_id: 118,
		part_id: 24,
		quantity: 426,
	},
	{
		garage_id: 118,
		part_id: 25,
		quantity: 122,
	},
	{
		garage_id: 118,
		part_id: 26,
		quantity: 59,
	},
	{
		garage_id: 118,
		part_id: 27,
		quantity: 365,
	},
	{
		garage_id: 118,
		part_id: 28,
		quantity: 451,
	},
	{
		garage_id: 118,
		part_id: 29,
		quantity: 453,
	},
	{
		garage_id: 118,
		part_id: 30,
		quantity: 19,
	},
	{
		garage_id: 118,
		part_id: 31,
		quantity: 462,
	},
	{
		garage_id: 118,
		part_id: 32,
		quantity: 378,
	},
	{
		garage_id: 118,
		part_id: 33,
		quantity: 98,
	},
	{
		garage_id: 118,
		part_id: 34,
		quantity: 188,
	},
	{
		garage_id: 118,
		part_id: 35,
		quantity: 466,
	},
	{
		garage_id: 118,
		part_id: 36,
		quantity: 147,
	},
	{
		garage_id: 118,
		part_id: 37,
		quantity: 178,
	},
	{
		garage_id: 118,
		part_id: 38,
		quantity: 175,
	},
	{
		garage_id: 118,
		part_id: 39,
		quantity: 58,
	},
	{
		garage_id: 118,
		part_id: 40,
		quantity: 65,
	},
	{
		garage_id: 118,
		part_id: 41,
		quantity: 96,
	},
	{
		garage_id: 118,
		part_id: 42,
		quantity: 380,
	},
	{
		garage_id: 118,
		part_id: 43,
		quantity: 18,
	},
	{
		garage_id: 118,
		part_id: 44,
		quantity: 8,
	},
	{
		garage_id: 118,
		part_id: 45,
		quantity: 207,
	},
	{
		garage_id: 118,
		part_id: 46,
		quantity: 190,
	},
	{
		garage_id: 118,
		part_id: 47,
		quantity: 61,
	},
	{
		garage_id: 118,
		part_id: 48,
		quantity: 445,
	},
	{
		garage_id: 118,
		part_id: 49,
		quantity: 55,
	},
	{
		garage_id: 118,
		part_id: 50,
		quantity: 263,
	},
	{
		garage_id: 118,
		part_id: 51,
		quantity: 45,
	},
	{
		garage_id: 118,
		part_id: 52,
		quantity: 43,
	},
	{
		garage_id: 118,
		part_id: 53,
		quantity: 10,
	},
	{
		garage_id: 118,
		part_id: 54,
		quantity: 103,
	},
	{
		garage_id: 118,
		part_id: 55,
		quantity: 499,
	},
	{
		garage_id: 118,
		part_id: 56,
		quantity: 404,
	},
	{
		garage_id: 118,
		part_id: 57,
		quantity: 110,
	},
	{
		garage_id: 118,
		part_id: 58,
		quantity: 406,
	},
	{
		garage_id: 118,
		part_id: 59,
		quantity: 266,
	},
	{
		garage_id: 118,
		part_id: 60,
		quantity: 305,
	},
	{
		garage_id: 118,
		part_id: 61,
		quantity: 272,
	},
	{
		garage_id: 118,
		part_id: 62,
		quantity: 88,
	},
	{
		garage_id: 118,
		part_id: 63,
		quantity: 423,
	},
	{
		garage_id: 118,
		part_id: 64,
		quantity: 275,
	},
	{
		garage_id: 118,
		part_id: 65,
		quantity: 471,
	},
	{
		garage_id: 118,
		part_id: 66,
		quantity: 85,
	},
	{
		garage_id: 118,
		part_id: 67,
		quantity: 90,
	},
	{
		garage_id: 118,
		part_id: 68,
		quantity: 419,
	},
	{
		garage_id: 118,
		part_id: 69,
		quantity: 102,
	},
	{
		garage_id: 118,
		part_id: 70,
		quantity: 364,
	},
	{
		garage_id: 118,
		part_id: 71,
		quantity: 225,
	},
	{
		garage_id: 118,
		part_id: 72,
		quantity: 166,
	},
	{
		garage_id: 118,
		part_id: 73,
		quantity: 385,
	},
	{
		garage_id: 118,
		part_id: 74,
		quantity: 322,
	},
	{
		garage_id: 118,
		part_id: 75,
		quantity: 103,
	},
	{
		garage_id: 118,
		part_id: 76,
		quantity: 111,
	},
	{
		garage_id: 118,
		part_id: 77,
		quantity: 152,
	},
	{
		garage_id: 118,
		part_id: 78,
		quantity: 123,
	},
	{
		garage_id: 118,
		part_id: 79,
		quantity: 45,
	},
	{
		garage_id: 118,
		part_id: 80,
		quantity: 98,
	},
	{
		garage_id: 118,
		part_id: 81,
		quantity: 345,
	},
	{
		garage_id: 118,
		part_id: 82,
		quantity: 86,
	},
	{
		garage_id: 118,
		part_id: 83,
		quantity: 36,
	},
	{
		garage_id: 118,
		part_id: 84,
		quantity: 211,
	},
	{
		garage_id: 118,
		part_id: 85,
		quantity: 67,
	},
	{
		garage_id: 118,
		part_id: 86,
		quantity: 303,
	},
	{
		garage_id: 118,
		part_id: 87,
		quantity: 489,
	},
	{
		garage_id: 118,
		part_id: 88,
		quantity: 123,
	},
	{
		garage_id: 118,
		part_id: 89,
		quantity: 201,
	},
	{
		garage_id: 118,
		part_id: 90,
		quantity: 329,
	},
	{
		garage_id: 118,
		part_id: 91,
		quantity: 95,
	},
	{
		garage_id: 118,
		part_id: 92,
		quantity: 137,
	},
	{
		garage_id: 118,
		part_id: 93,
		quantity: 161,
	},
	{
		garage_id: 118,
		part_id: 94,
		quantity: 317,
	},
	{
		garage_id: 118,
		part_id: 95,
		quantity: 436,
	},
	{
		garage_id: 118,
		part_id: 96,
		quantity: 259,
	},
	{
		garage_id: 118,
		part_id: 97,
		quantity: 26,
	},
	{
		garage_id: 118,
		part_id: 98,
		quantity: 327,
	},
	{
		garage_id: 118,
		part_id: 99,
		quantity: 55,
	},
	{
		garage_id: 118,
		part_id: 100,
		quantity: 442,
	},
	{
		garage_id: 118,
		part_id: 101,
		quantity: 9,
	},
	{
		garage_id: 118,
		part_id: 102,
		quantity: 71,
	},
	{
		garage_id: 118,
		part_id: 103,
		quantity: 236,
	},
	{
		garage_id: 118,
		part_id: 104,
		quantity: 375,
	},
	{
		garage_id: 118,
		part_id: 105,
		quantity: 36,
	},
	{
		garage_id: 118,
		part_id: 106,
		quantity: 415,
	},
	{
		garage_id: 118,
		part_id: 107,
		quantity: 142,
	},
	{
		garage_id: 118,
		part_id: 108,
		quantity: 343,
	},
	{
		garage_id: 118,
		part_id: 109,
		quantity: 38,
	},
	{
		garage_id: 118,
		part_id: 110,
		quantity: 219,
	},
	{
		garage_id: 118,
		part_id: 111,
		quantity: 471,
	},
	{
		garage_id: 118,
		part_id: 112,
		quantity: 128,
	},
	{
		garage_id: 118,
		part_id: 113,
		quantity: 232,
	},
	{
		garage_id: 118,
		part_id: 114,
		quantity: 465,
	},
	{
		garage_id: 118,
		part_id: 115,
		quantity: 355,
	},
	{
		garage_id: 118,
		part_id: 116,
		quantity: 182,
	},
	{
		garage_id: 118,
		part_id: 117,
		quantity: 482,
	},
	{
		garage_id: 118,
		part_id: 118,
		quantity: 44,
	},
	{
		garage_id: 118,
		part_id: 119,
		quantity: 197,
	},
	{
		garage_id: 118,
		part_id: 120,
		quantity: 54,
	},
	{
		garage_id: 118,
		part_id: 121,
		quantity: 259,
	},
	{
		garage_id: 118,
		part_id: 122,
		quantity: 91,
	},
	{
		garage_id: 118,
		part_id: 123,
		quantity: 228,
	},
	{
		garage_id: 118,
		part_id: 124,
		quantity: 349,
	},
	{
		garage_id: 118,
		part_id: 125,
		quantity: 402,
	},
	{
		garage_id: 118,
		part_id: 126,
		quantity: 283,
	},
	{
		garage_id: 118,
		part_id: 127,
		quantity: 439,
	},
	{
		garage_id: 118,
		part_id: 128,
		quantity: 320,
	},
	{
		garage_id: 118,
		part_id: 129,
		quantity: 163,
	},
	{
		garage_id: 118,
		part_id: 130,
		quantity: 252,
	},
	{
		garage_id: 118,
		part_id: 131,
		quantity: 478,
	},
	{
		garage_id: 118,
		part_id: 132,
		quantity: 478,
	},
	{
		garage_id: 118,
		part_id: 133,
		quantity: 108,
	},
	{
		garage_id: 118,
		part_id: 134,
		quantity: 64,
	},
	{
		garage_id: 118,
		part_id: 135,
		quantity: 318,
	},
	{
		garage_id: 118,
		part_id: 136,
		quantity: 156,
	},
	{
		garage_id: 118,
		part_id: 137,
		quantity: 269,
	},
	{
		garage_id: 118,
		part_id: 138,
		quantity: 279,
	},
	{
		garage_id: 118,
		part_id: 139,
		quantity: 397,
	},
	{
		garage_id: 118,
		part_id: 140,
		quantity: 186,
	},
	{
		garage_id: 118,
		part_id: 141,
		quantity: 425,
	},
	{
		garage_id: 118,
		part_id: 142,
		quantity: 294,
	},
	{
		garage_id: 118,
		part_id: 143,
		quantity: 286,
	},
	{
		garage_id: 118,
		part_id: 144,
		quantity: 143,
	},
	{
		garage_id: 118,
		part_id: 145,
		quantity: 492,
	},
	{
		garage_id: 118,
		part_id: 146,
		quantity: 456,
	},
	{
		garage_id: 118,
		part_id: 147,
		quantity: 476,
	},
	{
		garage_id: 118,
		part_id: 148,
		quantity: 446,
	},
	{
		garage_id: 118,
		part_id: 149,
		quantity: 175,
	},
	{
		garage_id: 118,
		part_id: 150,
		quantity: 374,
	},
	{
		garage_id: 118,
		part_id: 151,
		quantity: 488,
	},
	{
		garage_id: 118,
		part_id: 152,
		quantity: 338,
	},
	{
		garage_id: 118,
		part_id: 153,
		quantity: 106,
	},
	{
		garage_id: 118,
		part_id: 154,
		quantity: 357,
	},
	{
		garage_id: 118,
		part_id: 155,
		quantity: 274,
	},
	{
		garage_id: 118,
		part_id: 156,
		quantity: 60,
	},
	{
		garage_id: 118,
		part_id: 157,
		quantity: 74,
	},
	{
		garage_id: 118,
		part_id: 158,
		quantity: 415,
	},
	{
		garage_id: 118,
		part_id: 159,
		quantity: 355,
	},
	{
		garage_id: 118,
		part_id: 160,
		quantity: 335,
	},
	{
		garage_id: 118,
		part_id: 161,
		quantity: 15,
	},
	{
		garage_id: 118,
		part_id: 162,
		quantity: 165,
	},
	{
		garage_id: 118,
		part_id: 163,
		quantity: 433,
	},
	{
		garage_id: 118,
		part_id: 164,
		quantity: 323,
	},
	{
		garage_id: 118,
		part_id: 165,
		quantity: 263,
	},
	{
		garage_id: 118,
		part_id: 166,
		quantity: 7,
	},
	{
		garage_id: 118,
		part_id: 167,
		quantity: 336,
	},
	{
		garage_id: 118,
		part_id: 168,
		quantity: 357,
	},
	{
		garage_id: 118,
		part_id: 169,
		quantity: 261,
	},
	{
		garage_id: 118,
		part_id: 170,
		quantity: 419,
	},
	{
		garage_id: 118,
		part_id: 171,
		quantity: 13,
	},
	{
		garage_id: 118,
		part_id: 172,
		quantity: 200,
	},
	{
		garage_id: 118,
		part_id: 173,
		quantity: 152,
	},
	{
		garage_id: 118,
		part_id: 174,
		quantity: 29,
	},
	{
		garage_id: 118,
		part_id: 175,
		quantity: 26,
	},
	{
		garage_id: 118,
		part_id: 176,
		quantity: 402,
	},
	{
		garage_id: 118,
		part_id: 177,
		quantity: 71,
	},
	{
		garage_id: 118,
		part_id: 178,
		quantity: 352,
	},
	{
		garage_id: 118,
		part_id: 179,
		quantity: 23,
	},
	{
		garage_id: 118,
		part_id: 180,
		quantity: 288,
	},
	{
		garage_id: 118,
		part_id: 181,
		quantity: 197,
	},
	{
		garage_id: 118,
		part_id: 182,
		quantity: 6,
	},
	{
		garage_id: 118,
		part_id: 183,
		quantity: 108,
	},
	{
		garage_id: 118,
		part_id: 184,
		quantity: 45,
	},
	{
		garage_id: 118,
		part_id: 185,
		quantity: 18,
	},
	{
		garage_id: 118,
		part_id: 186,
		quantity: 286,
	},
	{
		garage_id: 118,
		part_id: 187,
		quantity: 294,
	},
	{
		garage_id: 118,
		part_id: 188,
		quantity: 269,
	},
	{
		garage_id: 118,
		part_id: 189,
		quantity: 34,
	},
	{
		garage_id: 118,
		part_id: 190,
		quantity: 5,
	},
	{
		garage_id: 118,
		part_id: 191,
		quantity: 4,
	},
	{
		garage_id: 118,
		part_id: 192,
		quantity: 219,
	},
	{
		garage_id: 118,
		part_id: 193,
		quantity: 113,
	},
	{
		garage_id: 118,
		part_id: 194,
		quantity: 441,
	},
	{
		garage_id: 118,
		part_id: 195,
		quantity: 344,
	},
	{
		garage_id: 118,
		part_id: 196,
		quantity: 388,
	},
	{
		garage_id: 118,
		part_id: 197,
		quantity: 145,
	},
	{
		garage_id: 118,
		part_id: 198,
		quantity: 172,
	},
	{
		garage_id: 118,
		part_id: 199,
		quantity: 268,
	},
	{
		garage_id: 118,
		part_id: 200,
		quantity: 360,
	},
	{
		garage_id: 118,
		part_id: 201,
		quantity: 25,
	},
	{
		garage_id: 118,
		part_id: 202,
		quantity: 130,
	},
	{
		garage_id: 118,
		part_id: 203,
		quantity: 447,
	},
	{
		garage_id: 118,
		part_id: 204,
		quantity: 155,
	},
	{
		garage_id: 118,
		part_id: 205,
		quantity: 362,
	},
	{
		garage_id: 118,
		part_id: 206,
		quantity: 86,
	},
	{
		garage_id: 118,
		part_id: 207,
		quantity: 175,
	},
	{
		garage_id: 118,
		part_id: 208,
		quantity: 275,
	},
	{
		garage_id: 118,
		part_id: 209,
		quantity: 86,
	},
	{
		garage_id: 118,
		part_id: 210,
		quantity: 98,
	},
	{
		garage_id: 118,
		part_id: 211,
		quantity: 74,
	},
	{
		garage_id: 118,
		part_id: 212,
		quantity: 168,
	},
	{
		garage_id: 118,
		part_id: 213,
		quantity: 385,
	},
	{
		garage_id: 118,
		part_id: 214,
		quantity: 346,
	},
	{
		garage_id: 118,
		part_id: 215,
		quantity: 189,
	},
	{
		garage_id: 118,
		part_id: 216,
		quantity: 182,
	},
	{
		garage_id: 118,
		part_id: 217,
		quantity: 194,
	},
	{
		garage_id: 118,
		part_id: 218,
		quantity: 337,
	},
	{
		garage_id: 118,
		part_id: 219,
		quantity: 352,
	},
	{
		garage_id: 118,
		part_id: 220,
		quantity: 339,
	},
	{
		garage_id: 118,
		part_id: 221,
		quantity: 475,
	},
	{
		garage_id: 118,
		part_id: 222,
		quantity: 81,
	},
	{
		garage_id: 118,
		part_id: 223,
		quantity: 500,
	},
	{
		garage_id: 118,
		part_id: 224,
		quantity: 144,
	},
	{
		garage_id: 118,
		part_id: 225,
		quantity: 158,
	},
	{
		garage_id: 118,
		part_id: 226,
		quantity: 12,
	},
	{
		garage_id: 118,
		part_id: 227,
		quantity: 224,
	},
	{
		garage_id: 118,
		part_id: 228,
		quantity: 170,
	},
	{
		garage_id: 118,
		part_id: 229,
		quantity: 448,
	},
	{
		garage_id: 118,
		part_id: 230,
		quantity: 260,
	},
	{
		garage_id: 118,
		part_id: 231,
		quantity: 377,
	},
	{
		garage_id: 118,
		part_id: 232,
		quantity: 149,
	},
	{
		garage_id: 118,
		part_id: 233,
		quantity: 155,
	},
	{
		garage_id: 118,
		part_id: 234,
		quantity: 255,
	},
	{
		garage_id: 118,
		part_id: 235,
		quantity: 271,
	},
	{
		garage_id: 118,
		part_id: 236,
		quantity: 489,
	},
	{
		garage_id: 118,
		part_id: 237,
		quantity: 91,
	},
	{
		garage_id: 118,
		part_id: 238,
		quantity: 50,
	},
	{
		garage_id: 118,
		part_id: 239,
		quantity: 341,
	},
	{
		garage_id: 118,
		part_id: 240,
		quantity: 114,
	},
	{
		garage_id: 118,
		part_id: 241,
		quantity: 254,
	},
	{
		garage_id: 118,
		part_id: 242,
		quantity: 25,
	},
	{
		garage_id: 118,
		part_id: 243,
		quantity: 117,
	},
	{
		garage_id: 118,
		part_id: 244,
		quantity: 308,
	},
	{
		garage_id: 118,
		part_id: 245,
		quantity: 72,
	},
	{
		garage_id: 118,
		part_id: 246,
		quantity: 13,
	},
	{
		garage_id: 118,
		part_id: 247,
		quantity: 88,
	},
	{
		garage_id: 118,
		part_id: 248,
		quantity: 357,
	},
	{
		garage_id: 118,
		part_id: 249,
		quantity: 141,
	},
	{
		garage_id: 118,
		part_id: 250,
		quantity: 332,
	},
	{
		garage_id: 118,
		part_id: 251,
		quantity: 287,
	},
	{
		garage_id: 118,
		part_id: 252,
		quantity: 95,
	},
	{
		garage_id: 118,
		part_id: 253,
		quantity: 43,
	},
	{
		garage_id: 118,
		part_id: 254,
		quantity: 63,
	},
	{
		garage_id: 118,
		part_id: 255,
		quantity: 314,
	},
	{
		garage_id: 118,
		part_id: 256,
		quantity: 53,
	},
	{
		garage_id: 118,
		part_id: 257,
		quantity: 407,
	},
	{
		garage_id: 118,
		part_id: 258,
		quantity: 212,
	},
	{
		garage_id: 118,
		part_id: 259,
		quantity: 31,
	},
	{
		garage_id: 118,
		part_id: 260,
		quantity: 371,
	},
	{
		garage_id: 118,
		part_id: 261,
		quantity: 429,
	},
	{
		garage_id: 118,
		part_id: 262,
		quantity: 357,
	},
	{
		garage_id: 118,
		part_id: 263,
		quantity: 14,
	},
	{
		garage_id: 118,
		part_id: 264,
		quantity: 156,
	},
	{
		garage_id: 118,
		part_id: 265,
		quantity: 240,
	},
	{
		garage_id: 118,
		part_id: 266,
		quantity: 341,
	},
	{
		garage_id: 118,
		part_id: 267,
		quantity: 14,
	},
	{
		garage_id: 118,
		part_id: 268,
		quantity: 442,
	},
	{
		garage_id: 118,
		part_id: 269,
		quantity: 137,
	},
	{
		garage_id: 118,
		part_id: 270,
		quantity: 289,
	},
	{
		garage_id: 118,
		part_id: 271,
		quantity: 420,
	},
	{
		garage_id: 118,
		part_id: 272,
		quantity: 195,
	},
	{
		garage_id: 118,
		part_id: 273,
		quantity: 118,
	},
	{
		garage_id: 118,
		part_id: 274,
		quantity: 482,
	},
	{
		garage_id: 118,
		part_id: 275,
		quantity: 214,
	},
	{
		garage_id: 118,
		part_id: 276,
		quantity: 286,
	},
	{
		garage_id: 118,
		part_id: 277,
		quantity: 158,
	},
	{
		garage_id: 118,
		part_id: 278,
		quantity: 47,
	},
	{
		garage_id: 118,
		part_id: 279,
		quantity: 177,
	},
	{
		garage_id: 118,
		part_id: 280,
		quantity: 33,
	},
	{
		garage_id: 118,
		part_id: 281,
		quantity: 405,
	},
	{
		garage_id: 118,
		part_id: 282,
		quantity: 303,
	},
	{
		garage_id: 118,
		part_id: 283,
		quantity: 358,
	},
	{
		garage_id: 118,
		part_id: 284,
		quantity: 44,
	},
	{
		garage_id: 118,
		part_id: 285,
		quantity: 339,
	},
	{
		garage_id: 118,
		part_id: 286,
		quantity: 490,
	},
	{
		garage_id: 118,
		part_id: 287,
		quantity: 161,
	},
	{
		garage_id: 118,
		part_id: 288,
		quantity: 4,
	},
	{
		garage_id: 118,
		part_id: 289,
		quantity: 479,
	},
	{
		garage_id: 118,
		part_id: 290,
		quantity: 403,
	},
	{
		garage_id: 118,
		part_id: 291,
		quantity: 154,
	},
	{
		garage_id: 118,
		part_id: 292,
		quantity: 465,
	},
	{
		garage_id: 118,
		part_id: 293,
		quantity: 199,
	},
	{
		garage_id: 118,
		part_id: 294,
		quantity: 284,
	},
	{
		garage_id: 118,
		part_id: 295,
		quantity: 465,
	},
	{
		garage_id: 118,
		part_id: 296,
		quantity: 363,
	},
	{
		garage_id: 118,
		part_id: 297,
		quantity: 284,
	},
	{
		garage_id: 118,
		part_id: 298,
		quantity: 111,
	},
	{
		garage_id: 118,
		part_id: 299,
		quantity: 458,
	},
	{
		garage_id: 118,
		part_id: 300,
		quantity: 401,
	},
	{
		garage_id: 118,
		part_id: 301,
		quantity: 358,
	},
	{
		garage_id: 118,
		part_id: 302,
		quantity: 450,
	},
	{
		garage_id: 118,
		part_id: 303,
		quantity: 392,
	},
	{
		garage_id: 118,
		part_id: 304,
		quantity: 253,
	},
	{
		garage_id: 118,
		part_id: 305,
		quantity: 198,
	},
	{
		garage_id: 118,
		part_id: 306,
		quantity: 163,
	},
	{
		garage_id: 118,
		part_id: 307,
		quantity: 323,
	},
	{
		garage_id: 118,
		part_id: 308,
		quantity: 489,
	},
	{
		garage_id: 118,
		part_id: 309,
		quantity: 448,
	},
	{
		garage_id: 118,
		part_id: 310,
		quantity: 467,
	},
	{
		garage_id: 118,
		part_id: 311,
		quantity: 119,
	},
	{
		garage_id: 118,
		part_id: 312,
		quantity: 154,
	},
	{
		garage_id: 118,
		part_id: 313,
		quantity: 158,
	},
	{
		garage_id: 118,
		part_id: 314,
		quantity: 226,
	},
	{
		garage_id: 118,
		part_id: 315,
		quantity: 321,
	},
	{
		garage_id: 118,
		part_id: 316,
		quantity: 486,
	},
	{
		garage_id: 118,
		part_id: 317,
		quantity: 103,
	},
	{
		garage_id: 118,
		part_id: 318,
		quantity: 415,
	},
	{
		garage_id: 118,
		part_id: 319,
		quantity: 195,
	},
	{
		garage_id: 118,
		part_id: 320,
		quantity: 490,
	},
	{
		garage_id: 118,
		part_id: 321,
		quantity: 122,
	},
	{
		garage_id: 118,
		part_id: 322,
		quantity: 412,
	},
	{
		garage_id: 118,
		part_id: 323,
		quantity: 308,
	},
	{
		garage_id: 118,
		part_id: 324,
		quantity: 408,
	},
	{
		garage_id: 118,
		part_id: 325,
		quantity: 41,
	},
	{
		garage_id: 118,
		part_id: 326,
		quantity: 258,
	},
	{
		garage_id: 118,
		part_id: 327,
		quantity: 396,
	},
	{
		garage_id: 118,
		part_id: 328,
		quantity: 26,
	},
	{
		garage_id: 118,
		part_id: 329,
		quantity: 166,
	},
	{
		garage_id: 118,
		part_id: 330,
		quantity: 106,
	},
	{
		garage_id: 118,
		part_id: 331,
		quantity: 236,
	},
	{
		garage_id: 118,
		part_id: 332,
		quantity: 139,
	},
	{
		garage_id: 118,
		part_id: 333,
		quantity: 395,
	},
	{
		garage_id: 118,
		part_id: 334,
		quantity: 485,
	},
	{
		garage_id: 118,
		part_id: 335,
		quantity: 318,
	},
	{
		garage_id: 118,
		part_id: 336,
		quantity: 392,
	},
	{
		garage_id: 118,
		part_id: 337,
		quantity: 238,
	},
	{
		garage_id: 118,
		part_id: 338,
		quantity: 113,
	},
	{
		garage_id: 118,
		part_id: 339,
		quantity: 270,
	},
	{
		garage_id: 118,
		part_id: 340,
		quantity: 304,
	},
	{
		garage_id: 118,
		part_id: 341,
		quantity: 253,
	},
	{
		garage_id: 118,
		part_id: 342,
		quantity: 256,
	},
	{
		garage_id: 118,
		part_id: 343,
		quantity: 365,
	},
	{
		garage_id: 118,
		part_id: 344,
		quantity: 391,
	},
	{
		garage_id: 118,
		part_id: 345,
		quantity: 455,
	},
	{
		garage_id: 118,
		part_id: 346,
		quantity: 488,
	},
	{
		garage_id: 118,
		part_id: 347,
		quantity: 38,
	},
	{
		garage_id: 118,
		part_id: 348,
		quantity: 207,
	},
	{
		garage_id: 118,
		part_id: 349,
		quantity: 187,
	},
	{
		garage_id: 118,
		part_id: 350,
		quantity: 224,
	},
	{
		garage_id: 118,
		part_id: 351,
		quantity: 372,
	},
	{
		garage_id: 118,
		part_id: 352,
		quantity: 77,
	},
	{
		garage_id: 118,
		part_id: 353,
		quantity: 225,
	},
	{
		garage_id: 118,
		part_id: 354,
		quantity: 324,
	},
	{
		garage_id: 118,
		part_id: 355,
		quantity: 117,
	},
	{
		garage_id: 118,
		part_id: 356,
		quantity: 334,
	},
	{
		garage_id: 118,
		part_id: 357,
		quantity: 46,
	},
	{
		garage_id: 118,
		part_id: 358,
		quantity: 63,
	},
	{
		garage_id: 118,
		part_id: 359,
		quantity: 483,
	},
	{
		garage_id: 118,
		part_id: 360,
		quantity: 155,
	},
	{
		garage_id: 118,
		part_id: 361,
		quantity: 302,
	},
	{
		garage_id: 118,
		part_id: 362,
		quantity: 113,
	},
	{
		garage_id: 118,
		part_id: 363,
		quantity: 219,
	},
	{
		garage_id: 118,
		part_id: 364,
		quantity: 153,
	},
	{
		garage_id: 118,
		part_id: 365,
		quantity: 263,
	},
	{
		garage_id: 118,
		part_id: 366,
		quantity: 236,
	},
	{
		garage_id: 118,
		part_id: 367,
		quantity: 227,
	},
	{
		garage_id: 118,
		part_id: 368,
		quantity: 291,
	},
	{
		garage_id: 118,
		part_id: 369,
		quantity: 141,
	},
	{
		garage_id: 118,
		part_id: 370,
		quantity: 370,
	},
	{
		garage_id: 118,
		part_id: 371,
		quantity: 213,
	},
	{
		garage_id: 118,
		part_id: 372,
		quantity: 227,
	},
	{
		garage_id: 118,
		part_id: 373,
		quantity: 487,
	},
	{
		garage_id: 118,
		part_id: 374,
		quantity: 122,
	},
	{
		garage_id: 118,
		part_id: 375,
		quantity: 325,
	},
	{
		garage_id: 118,
		part_id: 376,
		quantity: 475,
	},
	{
		garage_id: 118,
		part_id: 377,
		quantity: 368,
	},
	{
		garage_id: 118,
		part_id: 378,
		quantity: 188,
	},
	{
		garage_id: 118,
		part_id: 379,
		quantity: 386,
	},
	{
		garage_id: 118,
		part_id: 380,
		quantity: 406,
	},
	{
		garage_id: 118,
		part_id: 381,
		quantity: 200,
	},
	{
		garage_id: 118,
		part_id: 382,
		quantity: 49,
	},
	{
		garage_id: 118,
		part_id: 383,
		quantity: 349,
	},
	{
		garage_id: 118,
		part_id: 384,
		quantity: 77,
	},
	{
		garage_id: 118,
		part_id: 385,
		quantity: 267,
	},
	{
		garage_id: 118,
		part_id: 386,
		quantity: 164,
	},
	{
		garage_id: 118,
		part_id: 387,
		quantity: 272,
	},
	{
		garage_id: 118,
		part_id: 388,
		quantity: 392,
	},
	{
		garage_id: 118,
		part_id: 389,
		quantity: 51,
	},
	{
		garage_id: 118,
		part_id: 390,
		quantity: 63,
	},
	{
		garage_id: 118,
		part_id: 391,
		quantity: 106,
	},
	{
		garage_id: 118,
		part_id: 392,
		quantity: 255,
	},
	{
		garage_id: 118,
		part_id: 393,
		quantity: 355,
	},
	{
		garage_id: 118,
		part_id: 394,
		quantity: 415,
	},
	{
		garage_id: 118,
		part_id: 395,
		quantity: 127,
	},
	{
		garage_id: 118,
		part_id: 396,
		quantity: 128,
	},
	{
		garage_id: 118,
		part_id: 397,
		quantity: 357,
	},
	{
		garage_id: 118,
		part_id: 398,
		quantity: 449,
	},
	{
		garage_id: 118,
		part_id: 399,
		quantity: 50,
	},
	{
		garage_id: 119,
		part_id: 0,
		quantity: 286,
	},
	{
		garage_id: 119,
		part_id: 1,
		quantity: 424,
	},
	{
		garage_id: 119,
		part_id: 2,
		quantity: 37,
	},
	{
		garage_id: 119,
		part_id: 3,
		quantity: 194,
	},
	{
		garage_id: 119,
		part_id: 4,
		quantity: 79,
	},
	{
		garage_id: 119,
		part_id: 5,
		quantity: 176,
	},
	{
		garage_id: 119,
		part_id: 6,
		quantity: 17,
	},
	{
		garage_id: 119,
		part_id: 7,
		quantity: 134,
	},
	{
		garage_id: 119,
		part_id: 8,
		quantity: 344,
	},
	{
		garage_id: 119,
		part_id: 9,
		quantity: 424,
	},
	{
		garage_id: 119,
		part_id: 10,
		quantity: 418,
	},
	{
		garage_id: 119,
		part_id: 11,
		quantity: 355,
	},
	{
		garage_id: 119,
		part_id: 12,
		quantity: 331,
	},
	{
		garage_id: 119,
		part_id: 13,
		quantity: 108,
	},
	{
		garage_id: 119,
		part_id: 14,
		quantity: 17,
	},
	{
		garage_id: 119,
		part_id: 15,
		quantity: 402,
	},
	{
		garage_id: 119,
		part_id: 16,
		quantity: 300,
	},
	{
		garage_id: 119,
		part_id: 17,
		quantity: 5,
	},
	{
		garage_id: 119,
		part_id: 18,
		quantity: 413,
	},
	{
		garage_id: 119,
		part_id: 19,
		quantity: 294,
	},
	{
		garage_id: 119,
		part_id: 20,
		quantity: 477,
	},
	{
		garage_id: 119,
		part_id: 21,
		quantity: 307,
	},
	{
		garage_id: 119,
		part_id: 22,
		quantity: 233,
	},
	{
		garage_id: 119,
		part_id: 23,
		quantity: 158,
	},
	{
		garage_id: 119,
		part_id: 24,
		quantity: 432,
	},
	{
		garage_id: 119,
		part_id: 25,
		quantity: 270,
	},
	{
		garage_id: 119,
		part_id: 26,
		quantity: 115,
	},
	{
		garage_id: 119,
		part_id: 27,
		quantity: 425,
	},
	{
		garage_id: 119,
		part_id: 28,
		quantity: 400,
	},
	{
		garage_id: 119,
		part_id: 29,
		quantity: 110,
	},
	{
		garage_id: 119,
		part_id: 30,
		quantity: 389,
	},
	{
		garage_id: 119,
		part_id: 31,
		quantity: 430,
	},
	{
		garage_id: 119,
		part_id: 32,
		quantity: 468,
	},
	{
		garage_id: 119,
		part_id: 33,
		quantity: 211,
	},
	{
		garage_id: 119,
		part_id: 34,
		quantity: 204,
	},
	{
		garage_id: 119,
		part_id: 35,
		quantity: 53,
	},
	{
		garage_id: 119,
		part_id: 36,
		quantity: 357,
	},
	{
		garage_id: 119,
		part_id: 37,
		quantity: 411,
	},
	{
		garage_id: 119,
		part_id: 38,
		quantity: 90,
	},
	{
		garage_id: 119,
		part_id: 39,
		quantity: 194,
	},
	{
		garage_id: 119,
		part_id: 40,
		quantity: 190,
	},
	{
		garage_id: 119,
		part_id: 41,
		quantity: 54,
	},
	{
		garage_id: 119,
		part_id: 42,
		quantity: 193,
	},
	{
		garage_id: 119,
		part_id: 43,
		quantity: 459,
	},
	{
		garage_id: 119,
		part_id: 44,
		quantity: 332,
	},
	{
		garage_id: 119,
		part_id: 45,
		quantity: 183,
	},
	{
		garage_id: 119,
		part_id: 46,
		quantity: 363,
	},
	{
		garage_id: 119,
		part_id: 47,
		quantity: 161,
	},
	{
		garage_id: 119,
		part_id: 48,
		quantity: 456,
	},
	{
		garage_id: 119,
		part_id: 49,
		quantity: 103,
	},
	{
		garage_id: 119,
		part_id: 50,
		quantity: 65,
	},
	{
		garage_id: 119,
		part_id: 51,
		quantity: 28,
	},
	{
		garage_id: 119,
		part_id: 52,
		quantity: 91,
	},
	{
		garage_id: 119,
		part_id: 53,
		quantity: 33,
	},
	{
		garage_id: 119,
		part_id: 54,
		quantity: 199,
	},
	{
		garage_id: 119,
		part_id: 55,
		quantity: 299,
	},
	{
		garage_id: 119,
		part_id: 56,
		quantity: 344,
	},
	{
		garage_id: 119,
		part_id: 57,
		quantity: 127,
	},
	{
		garage_id: 119,
		part_id: 58,
		quantity: 132,
	},
	{
		garage_id: 119,
		part_id: 59,
		quantity: 174,
	},
	{
		garage_id: 119,
		part_id: 60,
		quantity: 181,
	},
	{
		garage_id: 119,
		part_id: 61,
		quantity: 203,
	},
	{
		garage_id: 119,
		part_id: 62,
		quantity: 133,
	},
	{
		garage_id: 119,
		part_id: 63,
		quantity: 264,
	},
	{
		garage_id: 119,
		part_id: 64,
		quantity: 212,
	},
	{
		garage_id: 119,
		part_id: 65,
		quantity: 243,
	},
	{
		garage_id: 119,
		part_id: 66,
		quantity: 488,
	},
	{
		garage_id: 119,
		part_id: 67,
		quantity: 225,
	},
	{
		garage_id: 119,
		part_id: 68,
		quantity: 66,
	},
	{
		garage_id: 119,
		part_id: 69,
		quantity: 126,
	},
	{
		garage_id: 119,
		part_id: 70,
		quantity: 263,
	},
	{
		garage_id: 119,
		part_id: 71,
		quantity: 217,
	},
	{
		garage_id: 119,
		part_id: 72,
		quantity: 376,
	},
	{
		garage_id: 119,
		part_id: 73,
		quantity: 340,
	},
	{
		garage_id: 119,
		part_id: 74,
		quantity: 367,
	},
	{
		garage_id: 119,
		part_id: 75,
		quantity: 207,
	},
	{
		garage_id: 119,
		part_id: 76,
		quantity: 72,
	},
	{
		garage_id: 119,
		part_id: 77,
		quantity: 355,
	},
	{
		garage_id: 119,
		part_id: 78,
		quantity: 187,
	},
	{
		garage_id: 119,
		part_id: 79,
		quantity: 144,
	},
	{
		garage_id: 119,
		part_id: 80,
		quantity: 181,
	},
	{
		garage_id: 119,
		part_id: 81,
		quantity: 331,
	},
	{
		garage_id: 119,
		part_id: 82,
		quantity: 78,
	},
	{
		garage_id: 119,
		part_id: 83,
		quantity: 84,
	},
	{
		garage_id: 119,
		part_id: 84,
		quantity: 468,
	},
	{
		garage_id: 119,
		part_id: 85,
		quantity: 293,
	},
	{
		garage_id: 119,
		part_id: 86,
		quantity: 314,
	},
	{
		garage_id: 119,
		part_id: 87,
		quantity: 440,
	},
	{
		garage_id: 119,
		part_id: 88,
		quantity: 332,
	},
	{
		garage_id: 119,
		part_id: 89,
		quantity: 251,
	},
	{
		garage_id: 119,
		part_id: 90,
		quantity: 22,
	},
	{
		garage_id: 119,
		part_id: 91,
		quantity: 37,
	},
	{
		garage_id: 119,
		part_id: 92,
		quantity: 412,
	},
	{
		garage_id: 119,
		part_id: 93,
		quantity: 83,
	},
	{
		garage_id: 119,
		part_id: 94,
		quantity: 286,
	},
	{
		garage_id: 119,
		part_id: 95,
		quantity: 480,
	},
	{
		garage_id: 119,
		part_id: 96,
		quantity: 367,
	},
	{
		garage_id: 119,
		part_id: 97,
		quantity: 320,
	},
	{
		garage_id: 119,
		part_id: 98,
		quantity: 332,
	},
	{
		garage_id: 119,
		part_id: 99,
		quantity: 190,
	},
	{
		garage_id: 119,
		part_id: 100,
		quantity: 494,
	},
	{
		garage_id: 119,
		part_id: 101,
		quantity: 480,
	},
	{
		garage_id: 119,
		part_id: 102,
		quantity: 410,
	},
	{
		garage_id: 119,
		part_id: 103,
		quantity: 164,
	},
	{
		garage_id: 119,
		part_id: 104,
		quantity: 365,
	},
	{
		garage_id: 119,
		part_id: 105,
		quantity: 275,
	},
	{
		garage_id: 119,
		part_id: 106,
		quantity: 282,
	},
	{
		garage_id: 119,
		part_id: 107,
		quantity: 381,
	},
	{
		garage_id: 119,
		part_id: 108,
		quantity: 98,
	},
	{
		garage_id: 119,
		part_id: 109,
		quantity: 396,
	},
	{
		garage_id: 119,
		part_id: 110,
		quantity: 273,
	},
	{
		garage_id: 119,
		part_id: 111,
		quantity: 203,
	},
	{
		garage_id: 119,
		part_id: 112,
		quantity: 51,
	},
	{
		garage_id: 119,
		part_id: 113,
		quantity: 391,
	},
	{
		garage_id: 119,
		part_id: 114,
		quantity: 339,
	},
	{
		garage_id: 119,
		part_id: 115,
		quantity: 309,
	},
	{
		garage_id: 119,
		part_id: 116,
		quantity: 68,
	},
	{
		garage_id: 119,
		part_id: 117,
		quantity: 353,
	},
	{
		garage_id: 119,
		part_id: 118,
		quantity: 232,
	},
	{
		garage_id: 119,
		part_id: 119,
		quantity: 202,
	},
	{
		garage_id: 119,
		part_id: 120,
		quantity: 95,
	},
	{
		garage_id: 119,
		part_id: 121,
		quantity: 103,
	},
	{
		garage_id: 119,
		part_id: 122,
		quantity: 118,
	},
	{
		garage_id: 119,
		part_id: 123,
		quantity: 344,
	},
	{
		garage_id: 119,
		part_id: 124,
		quantity: 246,
	},
	{
		garage_id: 119,
		part_id: 125,
		quantity: 305,
	},
	{
		garage_id: 119,
		part_id: 126,
		quantity: 293,
	},
	{
		garage_id: 119,
		part_id: 127,
		quantity: 178,
	},
	{
		garage_id: 119,
		part_id: 128,
		quantity: 26,
	},
	{
		garage_id: 119,
		part_id: 129,
		quantity: 271,
	},
	{
		garage_id: 119,
		part_id: 130,
		quantity: 318,
	},
	{
		garage_id: 119,
		part_id: 131,
		quantity: 276,
	},
	{
		garage_id: 119,
		part_id: 132,
		quantity: 311,
	},
	{
		garage_id: 119,
		part_id: 133,
		quantity: 483,
	},
	{
		garage_id: 119,
		part_id: 134,
		quantity: 273,
	},
	{
		garage_id: 119,
		part_id: 135,
		quantity: 389,
	},
	{
		garage_id: 119,
		part_id: 136,
		quantity: 397,
	},
	{
		garage_id: 119,
		part_id: 137,
		quantity: 67,
	},
	{
		garage_id: 119,
		part_id: 138,
		quantity: 114,
	},
	{
		garage_id: 119,
		part_id: 139,
		quantity: 163,
	},
	{
		garage_id: 119,
		part_id: 140,
		quantity: 71,
	},
	{
		garage_id: 119,
		part_id: 141,
		quantity: 206,
	},
	{
		garage_id: 119,
		part_id: 142,
		quantity: 403,
	},
	{
		garage_id: 119,
		part_id: 143,
		quantity: 402,
	},
	{
		garage_id: 119,
		part_id: 144,
		quantity: 282,
	},
	{
		garage_id: 119,
		part_id: 145,
		quantity: 5,
	},
	{
		garage_id: 119,
		part_id: 146,
		quantity: 128,
	},
	{
		garage_id: 119,
		part_id: 147,
		quantity: 348,
	},
	{
		garage_id: 119,
		part_id: 148,
		quantity: 22,
	},
	{
		garage_id: 119,
		part_id: 149,
		quantity: 44,
	},
	{
		garage_id: 119,
		part_id: 150,
		quantity: 497,
	},
	{
		garage_id: 119,
		part_id: 151,
		quantity: 304,
	},
	{
		garage_id: 119,
		part_id: 152,
		quantity: 105,
	},
	{
		garage_id: 119,
		part_id: 153,
		quantity: 263,
	},
	{
		garage_id: 119,
		part_id: 154,
		quantity: 237,
	},
	{
		garage_id: 119,
		part_id: 155,
		quantity: 320,
	},
	{
		garage_id: 119,
		part_id: 156,
		quantity: 479,
	},
	{
		garage_id: 119,
		part_id: 157,
		quantity: 293,
	},
	{
		garage_id: 119,
		part_id: 158,
		quantity: 151,
	},
	{
		garage_id: 119,
		part_id: 159,
		quantity: 378,
	},
	{
		garage_id: 119,
		part_id: 160,
		quantity: 373,
	},
	{
		garage_id: 119,
		part_id: 161,
		quantity: 198,
	},
	{
		garage_id: 119,
		part_id: 162,
		quantity: 220,
	},
	{
		garage_id: 119,
		part_id: 163,
		quantity: 88,
	},
	{
		garage_id: 119,
		part_id: 164,
		quantity: 483,
	},
	{
		garage_id: 119,
		part_id: 165,
		quantity: 284,
	},
	{
		garage_id: 119,
		part_id: 166,
		quantity: 475,
	},
	{
		garage_id: 119,
		part_id: 167,
		quantity: 453,
	},
	{
		garage_id: 119,
		part_id: 168,
		quantity: 330,
	},
	{
		garage_id: 119,
		part_id: 169,
		quantity: 354,
	},
	{
		garage_id: 119,
		part_id: 170,
		quantity: 163,
	},
	{
		garage_id: 119,
		part_id: 171,
		quantity: 288,
	},
	{
		garage_id: 119,
		part_id: 172,
		quantity: 279,
	},
	{
		garage_id: 119,
		part_id: 173,
		quantity: 411,
	},
	{
		garage_id: 119,
		part_id: 174,
		quantity: 113,
	},
	{
		garage_id: 119,
		part_id: 175,
		quantity: 148,
	},
	{
		garage_id: 119,
		part_id: 176,
		quantity: 429,
	},
	{
		garage_id: 119,
		part_id: 177,
		quantity: 23,
	},
	{
		garage_id: 119,
		part_id: 178,
		quantity: 66,
	},
	{
		garage_id: 119,
		part_id: 179,
		quantity: 123,
	},
	{
		garage_id: 119,
		part_id: 180,
		quantity: 106,
	},
	{
		garage_id: 119,
		part_id: 181,
		quantity: 479,
	},
	{
		garage_id: 119,
		part_id: 182,
		quantity: 403,
	},
	{
		garage_id: 119,
		part_id: 183,
		quantity: 399,
	},
	{
		garage_id: 119,
		part_id: 184,
		quantity: 219,
	},
	{
		garage_id: 119,
		part_id: 185,
		quantity: 364,
	},
	{
		garage_id: 119,
		part_id: 186,
		quantity: 18,
	},
	{
		garage_id: 119,
		part_id: 187,
		quantity: 240,
	},
	{
		garage_id: 119,
		part_id: 188,
		quantity: 227,
	},
	{
		garage_id: 119,
		part_id: 189,
		quantity: 386,
	},
	{
		garage_id: 119,
		part_id: 190,
		quantity: 74,
	},
	{
		garage_id: 119,
		part_id: 191,
		quantity: 196,
	},
	{
		garage_id: 119,
		part_id: 192,
		quantity: 127,
	},
	{
		garage_id: 119,
		part_id: 193,
		quantity: 307,
	},
	{
		garage_id: 119,
		part_id: 194,
		quantity: 245,
	},
	{
		garage_id: 119,
		part_id: 195,
		quantity: 121,
	},
	{
		garage_id: 119,
		part_id: 196,
		quantity: 201,
	},
	{
		garage_id: 119,
		part_id: 197,
		quantity: 421,
	},
	{
		garage_id: 119,
		part_id: 198,
		quantity: 384,
	},
	{
		garage_id: 119,
		part_id: 199,
		quantity: 177,
	},
	{
		garage_id: 119,
		part_id: 200,
		quantity: 355,
	},
	{
		garage_id: 119,
		part_id: 201,
		quantity: 274,
	},
	{
		garage_id: 119,
		part_id: 202,
		quantity: 91,
	},
	{
		garage_id: 119,
		part_id: 203,
		quantity: 349,
	},
	{
		garage_id: 119,
		part_id: 204,
		quantity: 495,
	},
	{
		garage_id: 119,
		part_id: 205,
		quantity: 410,
	},
	{
		garage_id: 119,
		part_id: 206,
		quantity: 99,
	},
	{
		garage_id: 119,
		part_id: 207,
		quantity: 253,
	},
	{
		garage_id: 119,
		part_id: 208,
		quantity: 324,
	},
	{
		garage_id: 119,
		part_id: 209,
		quantity: 347,
	},
	{
		garage_id: 119,
		part_id: 210,
		quantity: 465,
	},
	{
		garage_id: 119,
		part_id: 211,
		quantity: 407,
	},
	{
		garage_id: 119,
		part_id: 212,
		quantity: 160,
	},
	{
		garage_id: 119,
		part_id: 213,
		quantity: 435,
	},
	{
		garage_id: 119,
		part_id: 214,
		quantity: 148,
	},
	{
		garage_id: 119,
		part_id: 215,
		quantity: 250,
	},
	{
		garage_id: 119,
		part_id: 216,
		quantity: 118,
	},
	{
		garage_id: 119,
		part_id: 217,
		quantity: 25,
	},
	{
		garage_id: 119,
		part_id: 218,
		quantity: 235,
	},
	{
		garage_id: 119,
		part_id: 219,
		quantity: 492,
	},
	{
		garage_id: 119,
		part_id: 220,
		quantity: 130,
	},
	{
		garage_id: 119,
		part_id: 221,
		quantity: 78,
	},
	{
		garage_id: 119,
		part_id: 222,
		quantity: 381,
	},
	{
		garage_id: 119,
		part_id: 223,
		quantity: 372,
	},
	{
		garage_id: 119,
		part_id: 224,
		quantity: 405,
	},
	{
		garage_id: 119,
		part_id: 225,
		quantity: 149,
	},
	{
		garage_id: 119,
		part_id: 226,
		quantity: 86,
	},
	{
		garage_id: 119,
		part_id: 227,
		quantity: 86,
	},
	{
		garage_id: 119,
		part_id: 228,
		quantity: 288,
	},
	{
		garage_id: 119,
		part_id: 229,
		quantity: 164,
	},
	{
		garage_id: 119,
		part_id: 230,
		quantity: 229,
	},
	{
		garage_id: 119,
		part_id: 231,
		quantity: 237,
	},
	{
		garage_id: 119,
		part_id: 232,
		quantity: 273,
	},
	{
		garage_id: 119,
		part_id: 233,
		quantity: 172,
	},
	{
		garage_id: 119,
		part_id: 234,
		quantity: 457,
	},
	{
		garage_id: 119,
		part_id: 235,
		quantity: 431,
	},
	{
		garage_id: 119,
		part_id: 236,
		quantity: 312,
	},
	{
		garage_id: 119,
		part_id: 237,
		quantity: 52,
	},
	{
		garage_id: 119,
		part_id: 238,
		quantity: 69,
	},
	{
		garage_id: 119,
		part_id: 239,
		quantity: 72,
	},
	{
		garage_id: 119,
		part_id: 240,
		quantity: 132,
	},
	{
		garage_id: 119,
		part_id: 241,
		quantity: 32,
	},
	{
		garage_id: 119,
		part_id: 242,
		quantity: 99,
	},
	{
		garage_id: 119,
		part_id: 243,
		quantity: 366,
	},
	{
		garage_id: 119,
		part_id: 244,
		quantity: 305,
	},
	{
		garage_id: 119,
		part_id: 245,
		quantity: 292,
	},
	{
		garage_id: 119,
		part_id: 246,
		quantity: 353,
	},
	{
		garage_id: 119,
		part_id: 247,
		quantity: 491,
	},
	{
		garage_id: 119,
		part_id: 248,
		quantity: 142,
	},
	{
		garage_id: 119,
		part_id: 249,
		quantity: 393,
	},
	{
		garage_id: 119,
		part_id: 250,
		quantity: 69,
	},
	{
		garage_id: 119,
		part_id: 251,
		quantity: 190,
	},
	{
		garage_id: 119,
		part_id: 252,
		quantity: 56,
	},
	{
		garage_id: 119,
		part_id: 253,
		quantity: 452,
	},
	{
		garage_id: 119,
		part_id: 254,
		quantity: 420,
	},
	{
		garage_id: 119,
		part_id: 255,
		quantity: 174,
	},
	{
		garage_id: 119,
		part_id: 256,
		quantity: 413,
	},
	{
		garage_id: 119,
		part_id: 257,
		quantity: 45,
	},
	{
		garage_id: 119,
		part_id: 258,
		quantity: 42,
	},
	{
		garage_id: 119,
		part_id: 259,
		quantity: 129,
	},
	{
		garage_id: 119,
		part_id: 260,
		quantity: 12,
	},
	{
		garage_id: 119,
		part_id: 261,
		quantity: 145,
	},
	{
		garage_id: 119,
		part_id: 262,
		quantity: 119,
	},
	{
		garage_id: 119,
		part_id: 263,
		quantity: 20,
	},
	{
		garage_id: 119,
		part_id: 264,
		quantity: 1,
	},
	{
		garage_id: 119,
		part_id: 265,
		quantity: 22,
	},
	{
		garage_id: 119,
		part_id: 266,
		quantity: 179,
	},
	{
		garage_id: 119,
		part_id: 267,
		quantity: 54,
	},
	{
		garage_id: 119,
		part_id: 268,
		quantity: 392,
	},
	{
		garage_id: 119,
		part_id: 269,
		quantity: 248,
	},
	{
		garage_id: 119,
		part_id: 270,
		quantity: 434,
	},
	{
		garage_id: 119,
		part_id: 271,
		quantity: 299,
	},
	{
		garage_id: 119,
		part_id: 272,
		quantity: 117,
	},
	{
		garage_id: 119,
		part_id: 273,
		quantity: 321,
	},
	{
		garage_id: 119,
		part_id: 274,
		quantity: 161,
	},
	{
		garage_id: 119,
		part_id: 275,
		quantity: 452,
	},
	{
		garage_id: 119,
		part_id: 276,
		quantity: 75,
	},
	{
		garage_id: 119,
		part_id: 277,
		quantity: 4,
	},
	{
		garage_id: 119,
		part_id: 278,
		quantity: 56,
	},
	{
		garage_id: 119,
		part_id: 279,
		quantity: 395,
	},
	{
		garage_id: 119,
		part_id: 280,
		quantity: 463,
	},
	{
		garage_id: 119,
		part_id: 281,
		quantity: 69,
	},
	{
		garage_id: 119,
		part_id: 282,
		quantity: 400,
	},
	{
		garage_id: 119,
		part_id: 283,
		quantity: 355,
	},
	{
		garage_id: 119,
		part_id: 284,
		quantity: 106,
	},
	{
		garage_id: 119,
		part_id: 285,
		quantity: 322,
	},
	{
		garage_id: 119,
		part_id: 286,
		quantity: 353,
	},
	{
		garage_id: 119,
		part_id: 287,
		quantity: 239,
	},
	{
		garage_id: 119,
		part_id: 288,
		quantity: 435,
	},
	{
		garage_id: 119,
		part_id: 289,
		quantity: 238,
	},
	{
		garage_id: 119,
		part_id: 290,
		quantity: 256,
	},
	{
		garage_id: 119,
		part_id: 291,
		quantity: 467,
	},
	{
		garage_id: 119,
		part_id: 292,
		quantity: 173,
	},
	{
		garage_id: 119,
		part_id: 293,
		quantity: 405,
	},
	{
		garage_id: 119,
		part_id: 294,
		quantity: 151,
	},
	{
		garage_id: 119,
		part_id: 295,
		quantity: 261,
	},
	{
		garage_id: 119,
		part_id: 296,
		quantity: 314,
	},
	{
		garage_id: 119,
		part_id: 297,
		quantity: 472,
	},
	{
		garage_id: 119,
		part_id: 298,
		quantity: 484,
	},
	{
		garage_id: 119,
		part_id: 299,
		quantity: 381,
	},
	{
		garage_id: 119,
		part_id: 300,
		quantity: 184,
	},
	{
		garage_id: 119,
		part_id: 301,
		quantity: 72,
	},
	{
		garage_id: 119,
		part_id: 302,
		quantity: 277,
	},
	{
		garage_id: 119,
		part_id: 303,
		quantity: 214,
	},
	{
		garage_id: 119,
		part_id: 304,
		quantity: 410,
	},
	{
		garage_id: 119,
		part_id: 305,
		quantity: 350,
	},
	{
		garage_id: 119,
		part_id: 306,
		quantity: 417,
	},
	{
		garage_id: 119,
		part_id: 307,
		quantity: 95,
	},
	{
		garage_id: 119,
		part_id: 308,
		quantity: 343,
	},
	{
		garage_id: 119,
		part_id: 309,
		quantity: 462,
	},
	{
		garage_id: 119,
		part_id: 310,
		quantity: 94,
	},
	{
		garage_id: 119,
		part_id: 311,
		quantity: 413,
	},
	{
		garage_id: 119,
		part_id: 312,
		quantity: 22,
	},
	{
		garage_id: 119,
		part_id: 313,
		quantity: 486,
	},
	{
		garage_id: 119,
		part_id: 314,
		quantity: 375,
	},
	{
		garage_id: 119,
		part_id: 315,
		quantity: 354,
	},
	{
		garage_id: 119,
		part_id: 316,
		quantity: 404,
	},
	{
		garage_id: 119,
		part_id: 317,
		quantity: 125,
	},
	{
		garage_id: 119,
		part_id: 318,
		quantity: 394,
	},
	{
		garage_id: 119,
		part_id: 319,
		quantity: 178,
	},
	{
		garage_id: 119,
		part_id: 320,
		quantity: 379,
	},
	{
		garage_id: 119,
		part_id: 321,
		quantity: 9,
	},
	{
		garage_id: 119,
		part_id: 322,
		quantity: 422,
	},
	{
		garage_id: 119,
		part_id: 323,
		quantity: 155,
	},
	{
		garage_id: 119,
		part_id: 324,
		quantity: 8,
	},
	{
		garage_id: 119,
		part_id: 325,
		quantity: 17,
	},
	{
		garage_id: 119,
		part_id: 326,
		quantity: 154,
	},
	{
		garage_id: 119,
		part_id: 327,
		quantity: 297,
	},
	{
		garage_id: 119,
		part_id: 328,
		quantity: 79,
	},
	{
		garage_id: 119,
		part_id: 329,
		quantity: 86,
	},
	{
		garage_id: 119,
		part_id: 330,
		quantity: 94,
	},
	{
		garage_id: 119,
		part_id: 331,
		quantity: 202,
	},
	{
		garage_id: 119,
		part_id: 332,
		quantity: 334,
	},
	{
		garage_id: 119,
		part_id: 333,
		quantity: 230,
	},
	{
		garage_id: 119,
		part_id: 334,
		quantity: 407,
	},
	{
		garage_id: 119,
		part_id: 335,
		quantity: 444,
	},
	{
		garage_id: 119,
		part_id: 336,
		quantity: 46,
	},
	{
		garage_id: 119,
		part_id: 337,
		quantity: 249,
	},
	{
		garage_id: 119,
		part_id: 338,
		quantity: 229,
	},
	{
		garage_id: 119,
		part_id: 339,
		quantity: 440,
	},
	{
		garage_id: 119,
		part_id: 340,
		quantity: 365,
	},
	{
		garage_id: 119,
		part_id: 341,
		quantity: 71,
	},
	{
		garage_id: 119,
		part_id: 342,
		quantity: 15,
	},
	{
		garage_id: 119,
		part_id: 343,
		quantity: 305,
	},
	{
		garage_id: 119,
		part_id: 344,
		quantity: 45,
	},
	{
		garage_id: 119,
		part_id: 345,
		quantity: 393,
	},
	{
		garage_id: 119,
		part_id: 346,
		quantity: 84,
	},
	{
		garage_id: 119,
		part_id: 347,
		quantity: 188,
	},
	{
		garage_id: 119,
		part_id: 348,
		quantity: 315,
	},
	{
		garage_id: 119,
		part_id: 349,
		quantity: 406,
	},
	{
		garage_id: 119,
		part_id: 350,
		quantity: 375,
	},
	{
		garage_id: 119,
		part_id: 351,
		quantity: 452,
	},
	{
		garage_id: 119,
		part_id: 352,
		quantity: 194,
	},
	{
		garage_id: 119,
		part_id: 353,
		quantity: 180,
	},
	{
		garage_id: 119,
		part_id: 354,
		quantity: 393,
	},
	{
		garage_id: 119,
		part_id: 355,
		quantity: 60,
	},
	{
		garage_id: 119,
		part_id: 356,
		quantity: 271,
	},
	{
		garage_id: 119,
		part_id: 357,
		quantity: 271,
	},
	{
		garage_id: 119,
		part_id: 358,
		quantity: 87,
	},
	{
		garage_id: 119,
		part_id: 359,
		quantity: 375,
	},
	{
		garage_id: 119,
		part_id: 360,
		quantity: 423,
	},
	{
		garage_id: 119,
		part_id: 361,
		quantity: 127,
	},
	{
		garage_id: 119,
		part_id: 362,
		quantity: 323,
	},
	{
		garage_id: 119,
		part_id: 363,
		quantity: 240,
	},
	{
		garage_id: 119,
		part_id: 364,
		quantity: 302,
	},
	{
		garage_id: 119,
		part_id: 365,
		quantity: 100,
	},
	{
		garage_id: 119,
		part_id: 366,
		quantity: 230,
	},
	{
		garage_id: 119,
		part_id: 367,
		quantity: 434,
	},
	{
		garage_id: 119,
		part_id: 368,
		quantity: 319,
	},
	{
		garage_id: 119,
		part_id: 369,
		quantity: 324,
	},
	{
		garage_id: 119,
		part_id: 370,
		quantity: 109,
	},
	{
		garage_id: 119,
		part_id: 371,
		quantity: 297,
	},
	{
		garage_id: 119,
		part_id: 372,
		quantity: 246,
	},
	{
		garage_id: 119,
		part_id: 373,
		quantity: 470,
	},
	{
		garage_id: 119,
		part_id: 374,
		quantity: 190,
	},
	{
		garage_id: 119,
		part_id: 375,
		quantity: 55,
	},
	{
		garage_id: 119,
		part_id: 376,
		quantity: 460,
	},
	{
		garage_id: 119,
		part_id: 377,
		quantity: 25,
	},
	{
		garage_id: 119,
		part_id: 378,
		quantity: 225,
	},
	{
		garage_id: 119,
		part_id: 379,
		quantity: 285,
	},
	{
		garage_id: 119,
		part_id: 380,
		quantity: 100,
	},
	{
		garage_id: 119,
		part_id: 381,
		quantity: 440,
	},
	{
		garage_id: 119,
		part_id: 382,
		quantity: 395,
	},
	{
		garage_id: 119,
		part_id: 383,
		quantity: 272,
	},
	{
		garage_id: 119,
		part_id: 384,
		quantity: 14,
	},
	{
		garage_id: 119,
		part_id: 385,
		quantity: 212,
	},
	{
		garage_id: 119,
		part_id: 386,
		quantity: 75,
	},
	{
		garage_id: 119,
		part_id: 387,
		quantity: 459,
	},
	{
		garage_id: 119,
		part_id: 388,
		quantity: 105,
	},
	{
		garage_id: 119,
		part_id: 389,
		quantity: 307,
	},
	{
		garage_id: 119,
		part_id: 390,
		quantity: 140,
	},
	{
		garage_id: 119,
		part_id: 391,
		quantity: 223,
	},
	{
		garage_id: 119,
		part_id: 392,
		quantity: 216,
	},
	{
		garage_id: 119,
		part_id: 393,
		quantity: 410,
	},
	{
		garage_id: 119,
		part_id: 394,
		quantity: 61,
	},
	{
		garage_id: 119,
		part_id: 395,
		quantity: 318,
	},
	{
		garage_id: 119,
		part_id: 396,
		quantity: 486,
	},
	{
		garage_id: 119,
		part_id: 397,
		quantity: 178,
	},
	{
		garage_id: 119,
		part_id: 398,
		quantity: 65,
	},
	{
		garage_id: 119,
		part_id: 399,
		quantity: 491,
	},
	{
		garage_id: 120,
		part_id: 0,
		quantity: 200,
	},
	{
		garage_id: 120,
		part_id: 1,
		quantity: 36,
	},
	{
		garage_id: 120,
		part_id: 2,
		quantity: 114,
	},
	{
		garage_id: 120,
		part_id: 3,
		quantity: 81,
	},
	{
		garage_id: 120,
		part_id: 4,
		quantity: 180,
	},
	{
		garage_id: 120,
		part_id: 5,
		quantity: 110,
	},
	{
		garage_id: 120,
		part_id: 6,
		quantity: 62,
	},
	{
		garage_id: 120,
		part_id: 7,
		quantity: 270,
	},
	{
		garage_id: 120,
		part_id: 8,
		quantity: 396,
	},
	{
		garage_id: 120,
		part_id: 9,
		quantity: 430,
	},
	{
		garage_id: 120,
		part_id: 10,
		quantity: 121,
	},
	{
		garage_id: 120,
		part_id: 11,
		quantity: 226,
	},
	{
		garage_id: 120,
		part_id: 12,
		quantity: 415,
	},
	{
		garage_id: 120,
		part_id: 13,
		quantity: 472,
	},
	{
		garage_id: 120,
		part_id: 14,
		quantity: 267,
	},
	{
		garage_id: 120,
		part_id: 15,
		quantity: 316,
	},
	{
		garage_id: 120,
		part_id: 16,
		quantity: 13,
	},
	{
		garage_id: 120,
		part_id: 17,
		quantity: 131,
	},
	{
		garage_id: 120,
		part_id: 18,
		quantity: 484,
	},
	{
		garage_id: 120,
		part_id: 19,
		quantity: 332,
	},
	{
		garage_id: 120,
		part_id: 20,
		quantity: 199,
	},
	{
		garage_id: 120,
		part_id: 21,
		quantity: 76,
	},
	{
		garage_id: 120,
		part_id: 22,
		quantity: 253,
	},
	{
		garage_id: 120,
		part_id: 23,
		quantity: 23,
	},
	{
		garage_id: 120,
		part_id: 24,
		quantity: 218,
	},
	{
		garage_id: 120,
		part_id: 25,
		quantity: 389,
	},
	{
		garage_id: 120,
		part_id: 26,
		quantity: 452,
	},
	{
		garage_id: 120,
		part_id: 27,
		quantity: 445,
	},
	{
		garage_id: 120,
		part_id: 28,
		quantity: 59,
	},
	{
		garage_id: 120,
		part_id: 29,
		quantity: 357,
	},
	{
		garage_id: 120,
		part_id: 30,
		quantity: 439,
	},
	{
		garage_id: 120,
		part_id: 31,
		quantity: 377,
	},
	{
		garage_id: 120,
		part_id: 32,
		quantity: 107,
	},
	{
		garage_id: 120,
		part_id: 33,
		quantity: 370,
	},
	{
		garage_id: 120,
		part_id: 34,
		quantity: 378,
	},
	{
		garage_id: 120,
		part_id: 35,
		quantity: 382,
	},
	{
		garage_id: 120,
		part_id: 36,
		quantity: 456,
	},
	{
		garage_id: 120,
		part_id: 37,
		quantity: 69,
	},
	{
		garage_id: 120,
		part_id: 38,
		quantity: 436,
	},
	{
		garage_id: 120,
		part_id: 39,
		quantity: 82,
	},
	{
		garage_id: 120,
		part_id: 40,
		quantity: 329,
	},
	{
		garage_id: 120,
		part_id: 41,
		quantity: 20,
	},
	{
		garage_id: 120,
		part_id: 42,
		quantity: 379,
	},
	{
		garage_id: 120,
		part_id: 43,
		quantity: 129,
	},
	{
		garage_id: 120,
		part_id: 44,
		quantity: 5,
	},
	{
		garage_id: 120,
		part_id: 45,
		quantity: 4,
	},
	{
		garage_id: 120,
		part_id: 46,
		quantity: 228,
	},
	{
		garage_id: 120,
		part_id: 47,
		quantity: 36,
	},
	{
		garage_id: 120,
		part_id: 48,
		quantity: 278,
	},
	{
		garage_id: 120,
		part_id: 49,
		quantity: 494,
	},
	{
		garage_id: 120,
		part_id: 50,
		quantity: 157,
	},
	{
		garage_id: 120,
		part_id: 51,
		quantity: 261,
	},
	{
		garage_id: 120,
		part_id: 52,
		quantity: 207,
	},
	{
		garage_id: 120,
		part_id: 53,
		quantity: 430,
	},
	{
		garage_id: 120,
		part_id: 54,
		quantity: 486,
	},
	{
		garage_id: 120,
		part_id: 55,
		quantity: 37,
	},
	{
		garage_id: 120,
		part_id: 56,
		quantity: 174,
	},
	{
		garage_id: 120,
		part_id: 57,
		quantity: 289,
	},
	{
		garage_id: 120,
		part_id: 58,
		quantity: 312,
	},
	{
		garage_id: 120,
		part_id: 59,
		quantity: 241,
	},
	{
		garage_id: 120,
		part_id: 60,
		quantity: 99,
	},
	{
		garage_id: 120,
		part_id: 61,
		quantity: 234,
	},
	{
		garage_id: 120,
		part_id: 62,
		quantity: 11,
	},
	{
		garage_id: 120,
		part_id: 63,
		quantity: 217,
	},
	{
		garage_id: 120,
		part_id: 64,
		quantity: 217,
	},
	{
		garage_id: 120,
		part_id: 65,
		quantity: 98,
	},
	{
		garage_id: 120,
		part_id: 66,
		quantity: 93,
	},
	{
		garage_id: 120,
		part_id: 67,
		quantity: 304,
	},
	{
		garage_id: 120,
		part_id: 68,
		quantity: 87,
	},
	{
		garage_id: 120,
		part_id: 69,
		quantity: 271,
	},
	{
		garage_id: 120,
		part_id: 70,
		quantity: 474,
	},
	{
		garage_id: 120,
		part_id: 71,
		quantity: 394,
	},
	{
		garage_id: 120,
		part_id: 72,
		quantity: 247,
	},
	{
		garage_id: 120,
		part_id: 73,
		quantity: 59,
	},
	{
		garage_id: 120,
		part_id: 74,
		quantity: 420,
	},
	{
		garage_id: 120,
		part_id: 75,
		quantity: 2,
	},
	{
		garage_id: 120,
		part_id: 76,
		quantity: 439,
	},
	{
		garage_id: 120,
		part_id: 77,
		quantity: 189,
	},
	{
		garage_id: 120,
		part_id: 78,
		quantity: 95,
	},
	{
		garage_id: 120,
		part_id: 79,
		quantity: 192,
	},
	{
		garage_id: 120,
		part_id: 80,
		quantity: 26,
	},
	{
		garage_id: 120,
		part_id: 81,
		quantity: 436,
	},
	{
		garage_id: 120,
		part_id: 82,
		quantity: 207,
	},
	{
		garage_id: 120,
		part_id: 83,
		quantity: 489,
	},
	{
		garage_id: 120,
		part_id: 84,
		quantity: 473,
	},
	{
		garage_id: 120,
		part_id: 85,
		quantity: 33,
	},
	{
		garage_id: 120,
		part_id: 86,
		quantity: 20,
	},
	{
		garage_id: 120,
		part_id: 87,
		quantity: 153,
	},
	{
		garage_id: 120,
		part_id: 88,
		quantity: 240,
	},
	{
		garage_id: 120,
		part_id: 89,
		quantity: 373,
	},
	{
		garage_id: 120,
		part_id: 90,
		quantity: 68,
	},
	{
		garage_id: 120,
		part_id: 91,
		quantity: 214,
	},
	{
		garage_id: 120,
		part_id: 92,
		quantity: 283,
	},
	{
		garage_id: 120,
		part_id: 93,
		quantity: 169,
	},
	{
		garage_id: 120,
		part_id: 94,
		quantity: 114,
	},
	{
		garage_id: 120,
		part_id: 95,
		quantity: 134,
	},
	{
		garage_id: 120,
		part_id: 96,
		quantity: 62,
	},
	{
		garage_id: 120,
		part_id: 97,
		quantity: 108,
	},
	{
		garage_id: 120,
		part_id: 98,
		quantity: 410,
	},
	{
		garage_id: 120,
		part_id: 99,
		quantity: 385,
	},
	{
		garage_id: 120,
		part_id: 100,
		quantity: 102,
	},
	{
		garage_id: 120,
		part_id: 101,
		quantity: 79,
	},
	{
		garage_id: 120,
		part_id: 102,
		quantity: 249,
	},
	{
		garage_id: 120,
		part_id: 103,
		quantity: 242,
	},
	{
		garage_id: 120,
		part_id: 104,
		quantity: 27,
	},
	{
		garage_id: 120,
		part_id: 105,
		quantity: 338,
	},
	{
		garage_id: 120,
		part_id: 106,
		quantity: 180,
	},
	{
		garage_id: 120,
		part_id: 107,
		quantity: 352,
	},
	{
		garage_id: 120,
		part_id: 108,
		quantity: 359,
	},
	{
		garage_id: 120,
		part_id: 109,
		quantity: 474,
	},
	{
		garage_id: 120,
		part_id: 110,
		quantity: 334,
	},
	{
		garage_id: 120,
		part_id: 111,
		quantity: 206,
	},
	{
		garage_id: 120,
		part_id: 112,
		quantity: 207,
	},
	{
		garage_id: 120,
		part_id: 113,
		quantity: 337,
	},
	{
		garage_id: 120,
		part_id: 114,
		quantity: 75,
	},
	{
		garage_id: 120,
		part_id: 115,
		quantity: 232,
	},
	{
		garage_id: 120,
		part_id: 116,
		quantity: 245,
	},
	{
		garage_id: 120,
		part_id: 117,
		quantity: 399,
	},
	{
		garage_id: 120,
		part_id: 118,
		quantity: 377,
	},
	{
		garage_id: 120,
		part_id: 119,
		quantity: 426,
	},
	{
		garage_id: 120,
		part_id: 120,
		quantity: 374,
	},
	{
		garage_id: 120,
		part_id: 121,
		quantity: 60,
	},
	{
		garage_id: 120,
		part_id: 122,
		quantity: 134,
	},
	{
		garage_id: 120,
		part_id: 123,
		quantity: 268,
	},
	{
		garage_id: 120,
		part_id: 124,
		quantity: 245,
	},
	{
		garage_id: 120,
		part_id: 125,
		quantity: 350,
	},
	{
		garage_id: 120,
		part_id: 126,
		quantity: 170,
	},
	{
		garage_id: 120,
		part_id: 127,
		quantity: 147,
	},
	{
		garage_id: 120,
		part_id: 128,
		quantity: 381,
	},
	{
		garage_id: 120,
		part_id: 129,
		quantity: 105,
	},
	{
		garage_id: 120,
		part_id: 130,
		quantity: 339,
	},
	{
		garage_id: 120,
		part_id: 131,
		quantity: 494,
	},
	{
		garage_id: 120,
		part_id: 132,
		quantity: 317,
	},
	{
		garage_id: 120,
		part_id: 133,
		quantity: 445,
	},
	{
		garage_id: 120,
		part_id: 134,
		quantity: 294,
	},
	{
		garage_id: 120,
		part_id: 135,
		quantity: 256,
	},
	{
		garage_id: 120,
		part_id: 136,
		quantity: 366,
	},
	{
		garage_id: 120,
		part_id: 137,
		quantity: 42,
	},
	{
		garage_id: 120,
		part_id: 138,
		quantity: 143,
	},
	{
		garage_id: 120,
		part_id: 139,
		quantity: 332,
	},
	{
		garage_id: 120,
		part_id: 140,
		quantity: 391,
	},
	{
		garage_id: 120,
		part_id: 141,
		quantity: 490,
	},
	{
		garage_id: 120,
		part_id: 142,
		quantity: 203,
	},
	{
		garage_id: 120,
		part_id: 143,
		quantity: 210,
	},
	{
		garage_id: 120,
		part_id: 144,
		quantity: 479,
	},
	{
		garage_id: 120,
		part_id: 145,
		quantity: 357,
	},
	{
		garage_id: 120,
		part_id: 146,
		quantity: 119,
	},
	{
		garage_id: 120,
		part_id: 147,
		quantity: 495,
	},
	{
		garage_id: 120,
		part_id: 148,
		quantity: 374,
	},
	{
		garage_id: 120,
		part_id: 149,
		quantity: 339,
	},
	{
		garage_id: 120,
		part_id: 150,
		quantity: 119,
	},
	{
		garage_id: 120,
		part_id: 151,
		quantity: 216,
	},
	{
		garage_id: 120,
		part_id: 152,
		quantity: 335,
	},
	{
		garage_id: 120,
		part_id: 153,
		quantity: 218,
	},
	{
		garage_id: 120,
		part_id: 154,
		quantity: 497,
	},
	{
		garage_id: 120,
		part_id: 155,
		quantity: 107,
	},
	{
		garage_id: 120,
		part_id: 156,
		quantity: 23,
	},
	{
		garage_id: 120,
		part_id: 157,
		quantity: 440,
	},
	{
		garage_id: 120,
		part_id: 158,
		quantity: 460,
	},
	{
		garage_id: 120,
		part_id: 159,
		quantity: 290,
	},
	{
		garage_id: 120,
		part_id: 160,
		quantity: 445,
	},
	{
		garage_id: 120,
		part_id: 161,
		quantity: 180,
	},
	{
		garage_id: 120,
		part_id: 162,
		quantity: 113,
	},
	{
		garage_id: 120,
		part_id: 163,
		quantity: 17,
	},
	{
		garage_id: 120,
		part_id: 164,
		quantity: 230,
	},
	{
		garage_id: 120,
		part_id: 165,
		quantity: 318,
	},
	{
		garage_id: 120,
		part_id: 166,
		quantity: 219,
	},
	{
		garage_id: 120,
		part_id: 167,
		quantity: 306,
	},
	{
		garage_id: 120,
		part_id: 168,
		quantity: 119,
	},
	{
		garage_id: 120,
		part_id: 169,
		quantity: 379,
	},
	{
		garage_id: 120,
		part_id: 170,
		quantity: 90,
	},
	{
		garage_id: 120,
		part_id: 171,
		quantity: 364,
	},
	{
		garage_id: 120,
		part_id: 172,
		quantity: 433,
	},
	{
		garage_id: 120,
		part_id: 173,
		quantity: 498,
	},
	{
		garage_id: 120,
		part_id: 174,
		quantity: 58,
	},
	{
		garage_id: 120,
		part_id: 175,
		quantity: 489,
	},
	{
		garage_id: 120,
		part_id: 176,
		quantity: 165,
	},
	{
		garage_id: 120,
		part_id: 177,
		quantity: 443,
	},
	{
		garage_id: 120,
		part_id: 178,
		quantity: 467,
	},
	{
		garage_id: 120,
		part_id: 179,
		quantity: 145,
	},
	{
		garage_id: 120,
		part_id: 180,
		quantity: 45,
	},
	{
		garage_id: 120,
		part_id: 181,
		quantity: 481,
	},
	{
		garage_id: 120,
		part_id: 182,
		quantity: 464,
	},
	{
		garage_id: 120,
		part_id: 183,
		quantity: 193,
	},
	{
		garage_id: 120,
		part_id: 184,
		quantity: 384,
	},
	{
		garage_id: 120,
		part_id: 185,
		quantity: 363,
	},
	{
		garage_id: 120,
		part_id: 186,
		quantity: 139,
	},
	{
		garage_id: 120,
		part_id: 187,
		quantity: 309,
	},
	{
		garage_id: 120,
		part_id: 188,
		quantity: 40,
	},
	{
		garage_id: 120,
		part_id: 189,
		quantity: 266,
	},
	{
		garage_id: 120,
		part_id: 190,
		quantity: 496,
	},
	{
		garage_id: 120,
		part_id: 191,
		quantity: 425,
	},
	{
		garage_id: 120,
		part_id: 192,
		quantity: 382,
	},
	{
		garage_id: 120,
		part_id: 193,
		quantity: 167,
	},
	{
		garage_id: 120,
		part_id: 194,
		quantity: 463,
	},
	{
		garage_id: 120,
		part_id: 195,
		quantity: 290,
	},
	{
		garage_id: 120,
		part_id: 196,
		quantity: 260,
	},
	{
		garage_id: 120,
		part_id: 197,
		quantity: 285,
	},
	{
		garage_id: 120,
		part_id: 198,
		quantity: 18,
	},
	{
		garage_id: 120,
		part_id: 199,
		quantity: 323,
	},
	{
		garage_id: 120,
		part_id: 200,
		quantity: 202,
	},
	{
		garage_id: 120,
		part_id: 201,
		quantity: 291,
	},
	{
		garage_id: 120,
		part_id: 202,
		quantity: 301,
	},
	{
		garage_id: 120,
		part_id: 203,
		quantity: 493,
	},
	{
		garage_id: 120,
		part_id: 204,
		quantity: 378,
	},
	{
		garage_id: 120,
		part_id: 205,
		quantity: 288,
	},
	{
		garage_id: 120,
		part_id: 206,
		quantity: 134,
	},
	{
		garage_id: 120,
		part_id: 207,
		quantity: 271,
	},
	{
		garage_id: 120,
		part_id: 208,
		quantity: 250,
	},
	{
		garage_id: 120,
		part_id: 209,
		quantity: 31,
	},
	{
		garage_id: 120,
		part_id: 210,
		quantity: 401,
	},
	{
		garage_id: 120,
		part_id: 211,
		quantity: 205,
	},
	{
		garage_id: 120,
		part_id: 212,
		quantity: 6,
	},
	{
		garage_id: 120,
		part_id: 213,
		quantity: 151,
	},
	{
		garage_id: 120,
		part_id: 214,
		quantity: 304,
	},
	{
		garage_id: 120,
		part_id: 215,
		quantity: 6,
	},
	{
		garage_id: 120,
		part_id: 216,
		quantity: 328,
	},
	{
		garage_id: 120,
		part_id: 217,
		quantity: 280,
	},
	{
		garage_id: 120,
		part_id: 218,
		quantity: 417,
	},
	{
		garage_id: 120,
		part_id: 219,
		quantity: 335,
	},
	{
		garage_id: 120,
		part_id: 220,
		quantity: 101,
	},
	{
		garage_id: 120,
		part_id: 221,
		quantity: 455,
	},
	{
		garage_id: 120,
		part_id: 222,
		quantity: 34,
	},
	{
		garage_id: 120,
		part_id: 223,
		quantity: 190,
	},
	{
		garage_id: 120,
		part_id: 224,
		quantity: 99,
	},
	{
		garage_id: 120,
		part_id: 225,
		quantity: 426,
	},
	{
		garage_id: 120,
		part_id: 226,
		quantity: 405,
	},
	{
		garage_id: 120,
		part_id: 227,
		quantity: 112,
	},
	{
		garage_id: 120,
		part_id: 228,
		quantity: 25,
	},
	{
		garage_id: 120,
		part_id: 229,
		quantity: 379,
	},
	{
		garage_id: 120,
		part_id: 230,
		quantity: 474,
	},
	{
		garage_id: 120,
		part_id: 231,
		quantity: 374,
	},
	{
		garage_id: 120,
		part_id: 232,
		quantity: 232,
	},
	{
		garage_id: 120,
		part_id: 233,
		quantity: 492,
	},
	{
		garage_id: 120,
		part_id: 234,
		quantity: 306,
	},
	{
		garage_id: 120,
		part_id: 235,
		quantity: 117,
	},
	{
		garage_id: 120,
		part_id: 236,
		quantity: 434,
	},
	{
		garage_id: 120,
		part_id: 237,
		quantity: 453,
	},
	{
		garage_id: 120,
		part_id: 238,
		quantity: 254,
	},
	{
		garage_id: 120,
		part_id: 239,
		quantity: 205,
	},
	{
		garage_id: 120,
		part_id: 240,
		quantity: 327,
	},
	{
		garage_id: 120,
		part_id: 241,
		quantity: 295,
	},
	{
		garage_id: 120,
		part_id: 242,
		quantity: 241,
	},
	{
		garage_id: 120,
		part_id: 243,
		quantity: 212,
	},
	{
		garage_id: 120,
		part_id: 244,
		quantity: 457,
	},
	{
		garage_id: 120,
		part_id: 245,
		quantity: 275,
	},
	{
		garage_id: 120,
		part_id: 246,
		quantity: 389,
	},
	{
		garage_id: 120,
		part_id: 247,
		quantity: 190,
	},
	{
		garage_id: 120,
		part_id: 248,
		quantity: 174,
	},
	{
		garage_id: 120,
		part_id: 249,
		quantity: 437,
	},
	{
		garage_id: 120,
		part_id: 250,
		quantity: 371,
	},
	{
		garage_id: 120,
		part_id: 251,
		quantity: 456,
	},
	{
		garage_id: 120,
		part_id: 252,
		quantity: 19,
	},
	{
		garage_id: 120,
		part_id: 253,
		quantity: 63,
	},
	{
		garage_id: 120,
		part_id: 254,
		quantity: 219,
	},
	{
		garage_id: 120,
		part_id: 255,
		quantity: 415,
	},
	{
		garage_id: 120,
		part_id: 256,
		quantity: 43,
	},
	{
		garage_id: 120,
		part_id: 257,
		quantity: 308,
	},
	{
		garage_id: 120,
		part_id: 258,
		quantity: 204,
	},
	{
		garage_id: 120,
		part_id: 259,
		quantity: 337,
	},
	{
		garage_id: 120,
		part_id: 260,
		quantity: 199,
	},
	{
		garage_id: 120,
		part_id: 261,
		quantity: 308,
	},
	{
		garage_id: 120,
		part_id: 262,
		quantity: 305,
	},
	{
		garage_id: 120,
		part_id: 263,
		quantity: 373,
	},
	{
		garage_id: 120,
		part_id: 264,
		quantity: 314,
	},
	{
		garage_id: 120,
		part_id: 265,
		quantity: 280,
	},
	{
		garage_id: 120,
		part_id: 266,
		quantity: 398,
	},
	{
		garage_id: 120,
		part_id: 267,
		quantity: 251,
	},
	{
		garage_id: 120,
		part_id: 268,
		quantity: 31,
	},
	{
		garage_id: 120,
		part_id: 269,
		quantity: 217,
	},
	{
		garage_id: 120,
		part_id: 270,
		quantity: 82,
	},
	{
		garage_id: 120,
		part_id: 271,
		quantity: 302,
	},
	{
		garage_id: 120,
		part_id: 272,
		quantity: 389,
	},
	{
		garage_id: 120,
		part_id: 273,
		quantity: 451,
	},
	{
		garage_id: 120,
		part_id: 274,
		quantity: 404,
	},
	{
		garage_id: 120,
		part_id: 275,
		quantity: 5,
	},
	{
		garage_id: 120,
		part_id: 276,
		quantity: 222,
	},
	{
		garage_id: 120,
		part_id: 277,
		quantity: 405,
	},
	{
		garage_id: 120,
		part_id: 278,
		quantity: 362,
	},
	{
		garage_id: 120,
		part_id: 279,
		quantity: 127,
	},
	{
		garage_id: 120,
		part_id: 280,
		quantity: 273,
	},
	{
		garage_id: 120,
		part_id: 281,
		quantity: 232,
	},
	{
		garage_id: 120,
		part_id: 282,
		quantity: 480,
	},
	{
		garage_id: 120,
		part_id: 283,
		quantity: 429,
	},
	{
		garage_id: 120,
		part_id: 284,
		quantity: 365,
	},
	{
		garage_id: 120,
		part_id: 285,
		quantity: 152,
	},
	{
		garage_id: 120,
		part_id: 286,
		quantity: 440,
	},
	{
		garage_id: 120,
		part_id: 287,
		quantity: 424,
	},
	{
		garage_id: 120,
		part_id: 288,
		quantity: 246,
	},
	{
		garage_id: 120,
		part_id: 289,
		quantity: 98,
	},
	{
		garage_id: 120,
		part_id: 290,
		quantity: 216,
	},
	{
		garage_id: 120,
		part_id: 291,
		quantity: 427,
	},
	{
		garage_id: 120,
		part_id: 292,
		quantity: 148,
	},
	{
		garage_id: 120,
		part_id: 293,
		quantity: 12,
	},
	{
		garage_id: 120,
		part_id: 294,
		quantity: 128,
	},
	{
		garage_id: 120,
		part_id: 295,
		quantity: 242,
	},
	{
		garage_id: 120,
		part_id: 296,
		quantity: 467,
	},
	{
		garage_id: 120,
		part_id: 297,
		quantity: 189,
	},
	{
		garage_id: 120,
		part_id: 298,
		quantity: 261,
	},
	{
		garage_id: 120,
		part_id: 299,
		quantity: 28,
	},
	{
		garage_id: 120,
		part_id: 300,
		quantity: 219,
	},
	{
		garage_id: 120,
		part_id: 301,
		quantity: 152,
	},
	{
		garage_id: 120,
		part_id: 302,
		quantity: 125,
	},
	{
		garage_id: 120,
		part_id: 303,
		quantity: 412,
	},
	{
		garage_id: 120,
		part_id: 304,
		quantity: 61,
	},
	{
		garage_id: 120,
		part_id: 305,
		quantity: 261,
	},
	{
		garage_id: 120,
		part_id: 306,
		quantity: 373,
	},
	{
		garage_id: 120,
		part_id: 307,
		quantity: 20,
	},
	{
		garage_id: 120,
		part_id: 308,
		quantity: 355,
	},
	{
		garage_id: 120,
		part_id: 309,
		quantity: 293,
	},
	{
		garage_id: 120,
		part_id: 310,
		quantity: 486,
	},
	{
		garage_id: 120,
		part_id: 311,
		quantity: 17,
	},
	{
		garage_id: 120,
		part_id: 312,
		quantity: 120,
	},
	{
		garage_id: 120,
		part_id: 313,
		quantity: 398,
	},
	{
		garage_id: 120,
		part_id: 314,
		quantity: 88,
	},
	{
		garage_id: 120,
		part_id: 315,
		quantity: 124,
	},
	{
		garage_id: 120,
		part_id: 316,
		quantity: 240,
	},
	{
		garage_id: 120,
		part_id: 317,
		quantity: 343,
	},
	{
		garage_id: 120,
		part_id: 318,
		quantity: 150,
	},
	{
		garage_id: 120,
		part_id: 319,
		quantity: 341,
	},
	{
		garage_id: 120,
		part_id: 320,
		quantity: 122,
	},
	{
		garage_id: 120,
		part_id: 321,
		quantity: 114,
	},
	{
		garage_id: 120,
		part_id: 322,
		quantity: 413,
	},
	{
		garage_id: 120,
		part_id: 323,
		quantity: 326,
	},
	{
		garage_id: 120,
		part_id: 324,
		quantity: 231,
	},
	{
		garage_id: 120,
		part_id: 325,
		quantity: 326,
	},
	{
		garage_id: 120,
		part_id: 326,
		quantity: 21,
	},
	{
		garage_id: 120,
		part_id: 327,
		quantity: 38,
	},
	{
		garage_id: 120,
		part_id: 328,
		quantity: 166,
	},
	{
		garage_id: 120,
		part_id: 329,
		quantity: 332,
	},
	{
		garage_id: 120,
		part_id: 330,
		quantity: 254,
	},
	{
		garage_id: 120,
		part_id: 331,
		quantity: 489,
	},
	{
		garage_id: 120,
		part_id: 332,
		quantity: 101,
	},
	{
		garage_id: 120,
		part_id: 333,
		quantity: 94,
	},
	{
		garage_id: 120,
		part_id: 334,
		quantity: 210,
	},
	{
		garage_id: 120,
		part_id: 335,
		quantity: 393,
	},
	{
		garage_id: 120,
		part_id: 336,
		quantity: 375,
	},
	{
		garage_id: 120,
		part_id: 337,
		quantity: 454,
	},
	{
		garage_id: 120,
		part_id: 338,
		quantity: 79,
	},
	{
		garage_id: 120,
		part_id: 339,
		quantity: 194,
	},
	{
		garage_id: 120,
		part_id: 340,
		quantity: 500,
	},
	{
		garage_id: 120,
		part_id: 341,
		quantity: 158,
	},
	{
		garage_id: 120,
		part_id: 342,
		quantity: 361,
	},
	{
		garage_id: 120,
		part_id: 343,
		quantity: 1,
	},
	{
		garage_id: 120,
		part_id: 344,
		quantity: 210,
	},
	{
		garage_id: 120,
		part_id: 345,
		quantity: 385,
	},
	{
		garage_id: 120,
		part_id: 346,
		quantity: 333,
	},
	{
		garage_id: 120,
		part_id: 347,
		quantity: 80,
	},
	{
		garage_id: 120,
		part_id: 348,
		quantity: 154,
	},
	{
		garage_id: 120,
		part_id: 349,
		quantity: 368,
	},
	{
		garage_id: 120,
		part_id: 350,
		quantity: 304,
	},
	{
		garage_id: 120,
		part_id: 351,
		quantity: 147,
	},
	{
		garage_id: 120,
		part_id: 352,
		quantity: 162,
	},
	{
		garage_id: 120,
		part_id: 353,
		quantity: 65,
	},
	{
		garage_id: 120,
		part_id: 354,
		quantity: 396,
	},
	{
		garage_id: 120,
		part_id: 355,
		quantity: 411,
	},
	{
		garage_id: 120,
		part_id: 356,
		quantity: 38,
	},
	{
		garage_id: 120,
		part_id: 357,
		quantity: 205,
	},
	{
		garage_id: 120,
		part_id: 358,
		quantity: 450,
	},
	{
		garage_id: 120,
		part_id: 359,
		quantity: 133,
	},
	{
		garage_id: 120,
		part_id: 360,
		quantity: 187,
	},
	{
		garage_id: 120,
		part_id: 361,
		quantity: 22,
	},
	{
		garage_id: 120,
		part_id: 362,
		quantity: 48,
	},
	{
		garage_id: 120,
		part_id: 363,
		quantity: 443,
	},
	{
		garage_id: 120,
		part_id: 364,
		quantity: 420,
	},
	{
		garage_id: 120,
		part_id: 365,
		quantity: 290,
	},
	{
		garage_id: 120,
		part_id: 366,
		quantity: 80,
	},
	{
		garage_id: 120,
		part_id: 367,
		quantity: 70,
	},
	{
		garage_id: 120,
		part_id: 368,
		quantity: 251,
	},
	{
		garage_id: 120,
		part_id: 369,
		quantity: 358,
	},
	{
		garage_id: 120,
		part_id: 370,
		quantity: 21,
	},
	{
		garage_id: 120,
		part_id: 371,
		quantity: 23,
	},
	{
		garage_id: 120,
		part_id: 372,
		quantity: 450,
	},
	{
		garage_id: 120,
		part_id: 373,
		quantity: 137,
	},
	{
		garage_id: 120,
		part_id: 374,
		quantity: 385,
	},
	{
		garage_id: 120,
		part_id: 375,
		quantity: 110,
	},
	{
		garage_id: 120,
		part_id: 376,
		quantity: 488,
	},
	{
		garage_id: 120,
		part_id: 377,
		quantity: 247,
	},
	{
		garage_id: 120,
		part_id: 378,
		quantity: 379,
	},
	{
		garage_id: 120,
		part_id: 379,
		quantity: 243,
	},
	{
		garage_id: 120,
		part_id: 380,
		quantity: 322,
	},
	{
		garage_id: 120,
		part_id: 381,
		quantity: 420,
	},
	{
		garage_id: 120,
		part_id: 382,
		quantity: 396,
	},
	{
		garage_id: 120,
		part_id: 383,
		quantity: 336,
	},
	{
		garage_id: 120,
		part_id: 384,
		quantity: 353,
	},
	{
		garage_id: 120,
		part_id: 385,
		quantity: 3,
	},
	{
		garage_id: 120,
		part_id: 386,
		quantity: 320,
	},
	{
		garage_id: 120,
		part_id: 387,
		quantity: 150,
	},
	{
		garage_id: 120,
		part_id: 388,
		quantity: 463,
	},
	{
		garage_id: 120,
		part_id: 389,
		quantity: 336,
	},
	{
		garage_id: 120,
		part_id: 390,
		quantity: 238,
	},
	{
		garage_id: 120,
		part_id: 391,
		quantity: 450,
	},
	{
		garage_id: 120,
		part_id: 392,
		quantity: 155,
	},
	{
		garage_id: 120,
		part_id: 393,
		quantity: 477,
	},
	{
		garage_id: 120,
		part_id: 394,
		quantity: 126,
	},
	{
		garage_id: 120,
		part_id: 395,
		quantity: 336,
	},
	{
		garage_id: 120,
		part_id: 396,
		quantity: 337,
	},
	{
		garage_id: 120,
		part_id: 397,
		quantity: 485,
	},
	{
		garage_id: 120,
		part_id: 398,
		quantity: 241,
	},
	{
		garage_id: 120,
		part_id: 399,
		quantity: 344,
	},
	{
		garage_id: 121,
		part_id: 0,
		quantity: 378,
	},
	{
		garage_id: 121,
		part_id: 1,
		quantity: 215,
	},
	{
		garage_id: 121,
		part_id: 2,
		quantity: 351,
	},
	{
		garage_id: 121,
		part_id: 3,
		quantity: 387,
	},
	{
		garage_id: 121,
		part_id: 4,
		quantity: 74,
	},
	{
		garage_id: 121,
		part_id: 5,
		quantity: 202,
	},
	{
		garage_id: 121,
		part_id: 6,
		quantity: 273,
	},
	{
		garage_id: 121,
		part_id: 7,
		quantity: 289,
	},
	{
		garage_id: 121,
		part_id: 8,
		quantity: 415,
	},
	{
		garage_id: 121,
		part_id: 9,
		quantity: 191,
	},
	{
		garage_id: 121,
		part_id: 10,
		quantity: 465,
	},
	{
		garage_id: 121,
		part_id: 11,
		quantity: 248,
	},
	{
		garage_id: 121,
		part_id: 12,
		quantity: 348,
	},
	{
		garage_id: 121,
		part_id: 13,
		quantity: 488,
	},
	{
		garage_id: 121,
		part_id: 14,
		quantity: 435,
	},
	{
		garage_id: 121,
		part_id: 15,
		quantity: 36,
	},
	{
		garage_id: 121,
		part_id: 16,
		quantity: 162,
	},
	{
		garage_id: 121,
		part_id: 17,
		quantity: 301,
	},
	{
		garage_id: 121,
		part_id: 18,
		quantity: 83,
	},
	{
		garage_id: 121,
		part_id: 19,
		quantity: 312,
	},
	{
		garage_id: 121,
		part_id: 20,
		quantity: 456,
	},
	{
		garage_id: 121,
		part_id: 21,
		quantity: 343,
	},
	{
		garage_id: 121,
		part_id: 22,
		quantity: 171,
	},
	{
		garage_id: 121,
		part_id: 23,
		quantity: 70,
	},
	{
		garage_id: 121,
		part_id: 24,
		quantity: 223,
	},
	{
		garage_id: 121,
		part_id: 25,
		quantity: 289,
	},
	{
		garage_id: 121,
		part_id: 26,
		quantity: 455,
	},
	{
		garage_id: 121,
		part_id: 27,
		quantity: 10,
	},
	{
		garage_id: 121,
		part_id: 28,
		quantity: 394,
	},
	{
		garage_id: 121,
		part_id: 29,
		quantity: 370,
	},
	{
		garage_id: 121,
		part_id: 30,
		quantity: 107,
	},
	{
		garage_id: 121,
		part_id: 31,
		quantity: 26,
	},
	{
		garage_id: 121,
		part_id: 32,
		quantity: 380,
	},
	{
		garage_id: 121,
		part_id: 33,
		quantity: 19,
	},
	{
		garage_id: 121,
		part_id: 34,
		quantity: 2,
	},
	{
		garage_id: 121,
		part_id: 35,
		quantity: 420,
	},
	{
		garage_id: 121,
		part_id: 36,
		quantity: 457,
	},
	{
		garage_id: 121,
		part_id: 37,
		quantity: 258,
	},
	{
		garage_id: 121,
		part_id: 38,
		quantity: 491,
	},
	{
		garage_id: 121,
		part_id: 39,
		quantity: 136,
	},
	{
		garage_id: 121,
		part_id: 40,
		quantity: 312,
	},
	{
		garage_id: 121,
		part_id: 41,
		quantity: 348,
	},
	{
		garage_id: 121,
		part_id: 42,
		quantity: 138,
	},
	{
		garage_id: 121,
		part_id: 43,
		quantity: 142,
	},
	{
		garage_id: 121,
		part_id: 44,
		quantity: 174,
	},
	{
		garage_id: 121,
		part_id: 45,
		quantity: 349,
	},
	{
		garage_id: 121,
		part_id: 46,
		quantity: 369,
	},
	{
		garage_id: 121,
		part_id: 47,
		quantity: 74,
	},
	{
		garage_id: 121,
		part_id: 48,
		quantity: 75,
	},
	{
		garage_id: 121,
		part_id: 49,
		quantity: 392,
	},
	{
		garage_id: 121,
		part_id: 50,
		quantity: 142,
	},
	{
		garage_id: 121,
		part_id: 51,
		quantity: 61,
	},
	{
		garage_id: 121,
		part_id: 52,
		quantity: 156,
	},
	{
		garage_id: 121,
		part_id: 53,
		quantity: 417,
	},
	{
		garage_id: 121,
		part_id: 54,
		quantity: 458,
	},
	{
		garage_id: 121,
		part_id: 55,
		quantity: 115,
	},
	{
		garage_id: 121,
		part_id: 56,
		quantity: 302,
	},
	{
		garage_id: 121,
		part_id: 57,
		quantity: 166,
	},
	{
		garage_id: 121,
		part_id: 58,
		quantity: 133,
	},
	{
		garage_id: 121,
		part_id: 59,
		quantity: 481,
	},
	{
		garage_id: 121,
		part_id: 60,
		quantity: 324,
	},
	{
		garage_id: 121,
		part_id: 61,
		quantity: 17,
	},
	{
		garage_id: 121,
		part_id: 62,
		quantity: 172,
	},
	{
		garage_id: 121,
		part_id: 63,
		quantity: 167,
	},
	{
		garage_id: 121,
		part_id: 64,
		quantity: 215,
	},
	{
		garage_id: 121,
		part_id: 65,
		quantity: 35,
	},
	{
		garage_id: 121,
		part_id: 66,
		quantity: 246,
	},
	{
		garage_id: 121,
		part_id: 67,
		quantity: 401,
	},
	{
		garage_id: 121,
		part_id: 68,
		quantity: 417,
	},
	{
		garage_id: 121,
		part_id: 69,
		quantity: 321,
	},
	{
		garage_id: 121,
		part_id: 70,
		quantity: 259,
	},
	{
		garage_id: 121,
		part_id: 71,
		quantity: 268,
	},
	{
		garage_id: 121,
		part_id: 72,
		quantity: 152,
	},
	{
		garage_id: 121,
		part_id: 73,
		quantity: 398,
	},
	{
		garage_id: 121,
		part_id: 74,
		quantity: 185,
	},
	{
		garage_id: 121,
		part_id: 75,
		quantity: 311,
	},
	{
		garage_id: 121,
		part_id: 76,
		quantity: 87,
	},
	{
		garage_id: 121,
		part_id: 77,
		quantity: 50,
	},
	{
		garage_id: 121,
		part_id: 78,
		quantity: 344,
	},
	{
		garage_id: 121,
		part_id: 79,
		quantity: 67,
	},
	{
		garage_id: 121,
		part_id: 80,
		quantity: 105,
	},
	{
		garage_id: 121,
		part_id: 81,
		quantity: 491,
	},
	{
		garage_id: 121,
		part_id: 82,
		quantity: 438,
	},
	{
		garage_id: 121,
		part_id: 83,
		quantity: 325,
	},
	{
		garage_id: 121,
		part_id: 84,
		quantity: 127,
	},
	{
		garage_id: 121,
		part_id: 85,
		quantity: 475,
	},
	{
		garage_id: 121,
		part_id: 86,
		quantity: 292,
	},
	{
		garage_id: 121,
		part_id: 87,
		quantity: 129,
	},
	{
		garage_id: 121,
		part_id: 88,
		quantity: 63,
	},
	{
		garage_id: 121,
		part_id: 89,
		quantity: 278,
	},
	{
		garage_id: 121,
		part_id: 90,
		quantity: 399,
	},
	{
		garage_id: 121,
		part_id: 91,
		quantity: 263,
	},
	{
		garage_id: 121,
		part_id: 92,
		quantity: 156,
	},
	{
		garage_id: 121,
		part_id: 93,
		quantity: 220,
	},
	{
		garage_id: 121,
		part_id: 94,
		quantity: 142,
	},
	{
		garage_id: 121,
		part_id: 95,
		quantity: 116,
	},
	{
		garage_id: 121,
		part_id: 96,
		quantity: 348,
	},
	{
		garage_id: 121,
		part_id: 97,
		quantity: 339,
	},
	{
		garage_id: 121,
		part_id: 98,
		quantity: 57,
	},
	{
		garage_id: 121,
		part_id: 99,
		quantity: 37,
	},
	{
		garage_id: 121,
		part_id: 100,
		quantity: 15,
	},
	{
		garage_id: 121,
		part_id: 101,
		quantity: 50,
	},
	{
		garage_id: 121,
		part_id: 102,
		quantity: 387,
	},
	{
		garage_id: 121,
		part_id: 103,
		quantity: 447,
	},
	{
		garage_id: 121,
		part_id: 104,
		quantity: 291,
	},
	{
		garage_id: 121,
		part_id: 105,
		quantity: 449,
	},
	{
		garage_id: 121,
		part_id: 106,
		quantity: 196,
	},
	{
		garage_id: 121,
		part_id: 107,
		quantity: 352,
	},
	{
		garage_id: 121,
		part_id: 108,
		quantity: 263,
	},
	{
		garage_id: 121,
		part_id: 109,
		quantity: 122,
	},
	{
		garage_id: 121,
		part_id: 110,
		quantity: 264,
	},
	{
		garage_id: 121,
		part_id: 111,
		quantity: 15,
	},
	{
		garage_id: 121,
		part_id: 112,
		quantity: 326,
	},
	{
		garage_id: 121,
		part_id: 113,
		quantity: 365,
	},
	{
		garage_id: 121,
		part_id: 114,
		quantity: 313,
	},
	{
		garage_id: 121,
		part_id: 115,
		quantity: 36,
	},
	{
		garage_id: 121,
		part_id: 116,
		quantity: 153,
	},
	{
		garage_id: 121,
		part_id: 117,
		quantity: 487,
	},
	{
		garage_id: 121,
		part_id: 118,
		quantity: 453,
	},
	{
		garage_id: 121,
		part_id: 119,
		quantity: 373,
	},
	{
		garage_id: 121,
		part_id: 120,
		quantity: 77,
	},
	{
		garage_id: 121,
		part_id: 121,
		quantity: 431,
	},
	{
		garage_id: 121,
		part_id: 122,
		quantity: 96,
	},
	{
		garage_id: 121,
		part_id: 123,
		quantity: 240,
	},
	{
		garage_id: 121,
		part_id: 124,
		quantity: 354,
	},
	{
		garage_id: 121,
		part_id: 125,
		quantity: 135,
	},
	{
		garage_id: 121,
		part_id: 126,
		quantity: 101,
	},
	{
		garage_id: 121,
		part_id: 127,
		quantity: 68,
	},
	{
		garage_id: 121,
		part_id: 128,
		quantity: 81,
	},
	{
		garage_id: 121,
		part_id: 129,
		quantity: 97,
	},
	{
		garage_id: 121,
		part_id: 130,
		quantity: 124,
	},
	{
		garage_id: 121,
		part_id: 131,
		quantity: 494,
	},
	{
		garage_id: 121,
		part_id: 132,
		quantity: 16,
	},
	{
		garage_id: 121,
		part_id: 133,
		quantity: 280,
	},
	{
		garage_id: 121,
		part_id: 134,
		quantity: 332,
	},
	{
		garage_id: 121,
		part_id: 135,
		quantity: 254,
	},
	{
		garage_id: 121,
		part_id: 136,
		quantity: 393,
	},
	{
		garage_id: 121,
		part_id: 137,
		quantity: 69,
	},
	{
		garage_id: 121,
		part_id: 138,
		quantity: 140,
	},
	{
		garage_id: 121,
		part_id: 139,
		quantity: 231,
	},
	{
		garage_id: 121,
		part_id: 140,
		quantity: 83,
	},
	{
		garage_id: 121,
		part_id: 141,
		quantity: 449,
	},
	{
		garage_id: 121,
		part_id: 142,
		quantity: 59,
	},
	{
		garage_id: 121,
		part_id: 143,
		quantity: 140,
	},
	{
		garage_id: 121,
		part_id: 144,
		quantity: 187,
	},
	{
		garage_id: 121,
		part_id: 145,
		quantity: 404,
	},
	{
		garage_id: 121,
		part_id: 146,
		quantity: 27,
	},
	{
		garage_id: 121,
		part_id: 147,
		quantity: 211,
	},
	{
		garage_id: 121,
		part_id: 148,
		quantity: 431,
	},
	{
		garage_id: 121,
		part_id: 149,
		quantity: 377,
	},
	{
		garage_id: 121,
		part_id: 150,
		quantity: 369,
	},
	{
		garage_id: 121,
		part_id: 151,
		quantity: 50,
	},
	{
		garage_id: 121,
		part_id: 152,
		quantity: 242,
	},
	{
		garage_id: 121,
		part_id: 153,
		quantity: 345,
	},
	{
		garage_id: 121,
		part_id: 154,
		quantity: 135,
	},
	{
		garage_id: 121,
		part_id: 155,
		quantity: 241,
	},
	{
		garage_id: 121,
		part_id: 156,
		quantity: 288,
	},
	{
		garage_id: 121,
		part_id: 157,
		quantity: 193,
	},
	{
		garage_id: 121,
		part_id: 158,
		quantity: 456,
	},
	{
		garage_id: 121,
		part_id: 159,
		quantity: 167,
	},
	{
		garage_id: 121,
		part_id: 160,
		quantity: 104,
	},
	{
		garage_id: 121,
		part_id: 161,
		quantity: 470,
	},
	{
		garage_id: 121,
		part_id: 162,
		quantity: 101,
	},
	{
		garage_id: 121,
		part_id: 163,
		quantity: 281,
	},
	{
		garage_id: 121,
		part_id: 164,
		quantity: 394,
	},
	{
		garage_id: 121,
		part_id: 165,
		quantity: 287,
	},
	{
		garage_id: 121,
		part_id: 166,
		quantity: 19,
	},
	{
		garage_id: 121,
		part_id: 167,
		quantity: 272,
	},
	{
		garage_id: 121,
		part_id: 168,
		quantity: 267,
	},
	{
		garage_id: 121,
		part_id: 169,
		quantity: 330,
	},
	{
		garage_id: 121,
		part_id: 170,
		quantity: 356,
	},
	{
		garage_id: 121,
		part_id: 171,
		quantity: 89,
	},
	{
		garage_id: 121,
		part_id: 172,
		quantity: 261,
	},
	{
		garage_id: 121,
		part_id: 173,
		quantity: 106,
	},
	{
		garage_id: 121,
		part_id: 174,
		quantity: 35,
	},
	{
		garage_id: 121,
		part_id: 175,
		quantity: 5,
	},
	{
		garage_id: 121,
		part_id: 176,
		quantity: 123,
	},
	{
		garage_id: 121,
		part_id: 177,
		quantity: 210,
	},
	{
		garage_id: 121,
		part_id: 178,
		quantity: 458,
	},
	{
		garage_id: 121,
		part_id: 179,
		quantity: 209,
	},
	{
		garage_id: 121,
		part_id: 180,
		quantity: 55,
	},
	{
		garage_id: 121,
		part_id: 181,
		quantity: 432,
	},
	{
		garage_id: 121,
		part_id: 182,
		quantity: 124,
	},
	{
		garage_id: 121,
		part_id: 183,
		quantity: 330,
	},
	{
		garage_id: 121,
		part_id: 184,
		quantity: 201,
	},
	{
		garage_id: 121,
		part_id: 185,
		quantity: 432,
	},
	{
		garage_id: 121,
		part_id: 186,
		quantity: 298,
	},
	{
		garage_id: 121,
		part_id: 187,
		quantity: 379,
	},
	{
		garage_id: 121,
		part_id: 188,
		quantity: 461,
	},
	{
		garage_id: 121,
		part_id: 189,
		quantity: 367,
	},
	{
		garage_id: 121,
		part_id: 190,
		quantity: 286,
	},
	{
		garage_id: 121,
		part_id: 191,
		quantity: 387,
	},
	{
		garage_id: 121,
		part_id: 192,
		quantity: 93,
	},
	{
		garage_id: 121,
		part_id: 193,
		quantity: 462,
	},
	{
		garage_id: 121,
		part_id: 194,
		quantity: 264,
	},
	{
		garage_id: 121,
		part_id: 195,
		quantity: 400,
	},
	{
		garage_id: 121,
		part_id: 196,
		quantity: 246,
	},
	{
		garage_id: 121,
		part_id: 197,
		quantity: 263,
	},
	{
		garage_id: 121,
		part_id: 198,
		quantity: 126,
	},
	{
		garage_id: 121,
		part_id: 199,
		quantity: 242,
	},
	{
		garage_id: 121,
		part_id: 200,
		quantity: 290,
	},
	{
		garage_id: 121,
		part_id: 201,
		quantity: 406,
	},
	{
		garage_id: 121,
		part_id: 202,
		quantity: 298,
	},
	{
		garage_id: 121,
		part_id: 203,
		quantity: 188,
	},
	{
		garage_id: 121,
		part_id: 204,
		quantity: 257,
	},
	{
		garage_id: 121,
		part_id: 205,
		quantity: 329,
	},
	{
		garage_id: 121,
		part_id: 206,
		quantity: 495,
	},
	{
		garage_id: 121,
		part_id: 207,
		quantity: 342,
	},
	{
		garage_id: 121,
		part_id: 208,
		quantity: 100,
	},
	{
		garage_id: 121,
		part_id: 209,
		quantity: 360,
	},
	{
		garage_id: 121,
		part_id: 210,
		quantity: 246,
	},
	{
		garage_id: 121,
		part_id: 211,
		quantity: 282,
	},
	{
		garage_id: 121,
		part_id: 212,
		quantity: 386,
	},
	{
		garage_id: 121,
		part_id: 213,
		quantity: 28,
	},
	{
		garage_id: 121,
		part_id: 214,
		quantity: 311,
	},
	{
		garage_id: 121,
		part_id: 215,
		quantity: 114,
	},
	{
		garage_id: 121,
		part_id: 216,
		quantity: 53,
	},
	{
		garage_id: 121,
		part_id: 217,
		quantity: 306,
	},
	{
		garage_id: 121,
		part_id: 218,
		quantity: 361,
	},
	{
		garage_id: 121,
		part_id: 219,
		quantity: 105,
	},
	{
		garage_id: 121,
		part_id: 220,
		quantity: 155,
	},
	{
		garage_id: 121,
		part_id: 221,
		quantity: 80,
	},
	{
		garage_id: 121,
		part_id: 222,
		quantity: 494,
	},
	{
		garage_id: 121,
		part_id: 223,
		quantity: 85,
	},
	{
		garage_id: 121,
		part_id: 224,
		quantity: 419,
	},
	{
		garage_id: 121,
		part_id: 225,
		quantity: 28,
	},
	{
		garage_id: 121,
		part_id: 226,
		quantity: 187,
	},
	{
		garage_id: 121,
		part_id: 227,
		quantity: 12,
	},
	{
		garage_id: 121,
		part_id: 228,
		quantity: 298,
	},
	{
		garage_id: 121,
		part_id: 229,
		quantity: 419,
	},
	{
		garage_id: 121,
		part_id: 230,
		quantity: 457,
	},
	{
		garage_id: 121,
		part_id: 231,
		quantity: 410,
	},
	{
		garage_id: 121,
		part_id: 232,
		quantity: 143,
	},
	{
		garage_id: 121,
		part_id: 233,
		quantity: 401,
	},
	{
		garage_id: 121,
		part_id: 234,
		quantity: 444,
	},
	{
		garage_id: 121,
		part_id: 235,
		quantity: 219,
	},
	{
		garage_id: 121,
		part_id: 236,
		quantity: 28,
	},
	{
		garage_id: 121,
		part_id: 237,
		quantity: 37,
	},
	{
		garage_id: 121,
		part_id: 238,
		quantity: 337,
	},
	{
		garage_id: 121,
		part_id: 239,
		quantity: 237,
	},
	{
		garage_id: 121,
		part_id: 240,
		quantity: 430,
	},
	{
		garage_id: 121,
		part_id: 241,
		quantity: 209,
	},
	{
		garage_id: 121,
		part_id: 242,
		quantity: 66,
	},
	{
		garage_id: 121,
		part_id: 243,
		quantity: 233,
	},
	{
		garage_id: 121,
		part_id: 244,
		quantity: 388,
	},
	{
		garage_id: 121,
		part_id: 245,
		quantity: 252,
	},
	{
		garage_id: 121,
		part_id: 246,
		quantity: 195,
	},
	{
		garage_id: 121,
		part_id: 247,
		quantity: 191,
	},
	{
		garage_id: 121,
		part_id: 248,
		quantity: 358,
	},
	{
		garage_id: 121,
		part_id: 249,
		quantity: 231,
	},
	{
		garage_id: 121,
		part_id: 250,
		quantity: 11,
	},
	{
		garage_id: 121,
		part_id: 251,
		quantity: 343,
	},
	{
		garage_id: 121,
		part_id: 252,
		quantity: 93,
	},
	{
		garage_id: 121,
		part_id: 253,
		quantity: 143,
	},
	{
		garage_id: 121,
		part_id: 254,
		quantity: 116,
	},
	{
		garage_id: 121,
		part_id: 255,
		quantity: 7,
	},
	{
		garage_id: 121,
		part_id: 256,
		quantity: 488,
	},
	{
		garage_id: 121,
		part_id: 257,
		quantity: 15,
	},
	{
		garage_id: 121,
		part_id: 258,
		quantity: 79,
	},
	{
		garage_id: 121,
		part_id: 259,
		quantity: 319,
	},
	{
		garage_id: 121,
		part_id: 260,
		quantity: 494,
	},
	{
		garage_id: 121,
		part_id: 261,
		quantity: 75,
	},
	{
		garage_id: 121,
		part_id: 262,
		quantity: 96,
	},
	{
		garage_id: 121,
		part_id: 263,
		quantity: 78,
	},
	{
		garage_id: 121,
		part_id: 264,
		quantity: 413,
	},
	{
		garage_id: 121,
		part_id: 265,
		quantity: 55,
	},
	{
		garage_id: 121,
		part_id: 266,
		quantity: 158,
	},
	{
		garage_id: 121,
		part_id: 267,
		quantity: 165,
	},
	{
		garage_id: 121,
		part_id: 268,
		quantity: 98,
	},
	{
		garage_id: 121,
		part_id: 269,
		quantity: 102,
	},
	{
		garage_id: 121,
		part_id: 270,
		quantity: 257,
	},
	{
		garage_id: 121,
		part_id: 271,
		quantity: 378,
	},
	{
		garage_id: 121,
		part_id: 272,
		quantity: 184,
	},
	{
		garage_id: 121,
		part_id: 273,
		quantity: 182,
	},
	{
		garage_id: 121,
		part_id: 274,
		quantity: 6,
	},
	{
		garage_id: 121,
		part_id: 275,
		quantity: 319,
	},
	{
		garage_id: 121,
		part_id: 276,
		quantity: 157,
	},
	{
		garage_id: 121,
		part_id: 277,
		quantity: 314,
	},
	{
		garage_id: 121,
		part_id: 278,
		quantity: 312,
	},
	{
		garage_id: 121,
		part_id: 279,
		quantity: 127,
	},
	{
		garage_id: 121,
		part_id: 280,
		quantity: 166,
	},
	{
		garage_id: 121,
		part_id: 281,
		quantity: 224,
	},
	{
		garage_id: 121,
		part_id: 282,
		quantity: 392,
	},
	{
		garage_id: 121,
		part_id: 283,
		quantity: 64,
	},
	{
		garage_id: 121,
		part_id: 284,
		quantity: 336,
	},
	{
		garage_id: 121,
		part_id: 285,
		quantity: 244,
	},
	{
		garage_id: 121,
		part_id: 286,
		quantity: 233,
	},
	{
		garage_id: 121,
		part_id: 287,
		quantity: 205,
	},
	{
		garage_id: 121,
		part_id: 288,
		quantity: 110,
	},
	{
		garage_id: 121,
		part_id: 289,
		quantity: 129,
	},
	{
		garage_id: 121,
		part_id: 290,
		quantity: 475,
	},
	{
		garage_id: 121,
		part_id: 291,
		quantity: 244,
	},
	{
		garage_id: 121,
		part_id: 292,
		quantity: 49,
	},
	{
		garage_id: 121,
		part_id: 293,
		quantity: 391,
	},
	{
		garage_id: 121,
		part_id: 294,
		quantity: 204,
	},
	{
		garage_id: 121,
		part_id: 295,
		quantity: 291,
	},
	{
		garage_id: 121,
		part_id: 296,
		quantity: 219,
	},
	{
		garage_id: 121,
		part_id: 297,
		quantity: 370,
	},
	{
		garage_id: 121,
		part_id: 298,
		quantity: 361,
	},
	{
		garage_id: 121,
		part_id: 299,
		quantity: 419,
	},
	{
		garage_id: 121,
		part_id: 300,
		quantity: 468,
	},
	{
		garage_id: 121,
		part_id: 301,
		quantity: 376,
	},
	{
		garage_id: 121,
		part_id: 302,
		quantity: 19,
	},
	{
		garage_id: 121,
		part_id: 303,
		quantity: 356,
	},
	{
		garage_id: 121,
		part_id: 304,
		quantity: 439,
	},
	{
		garage_id: 121,
		part_id: 305,
		quantity: 110,
	},
	{
		garage_id: 121,
		part_id: 306,
		quantity: 29,
	},
	{
		garage_id: 121,
		part_id: 307,
		quantity: 159,
	},
	{
		garage_id: 121,
		part_id: 308,
		quantity: 89,
	},
	{
		garage_id: 121,
		part_id: 309,
		quantity: 217,
	},
	{
		garage_id: 121,
		part_id: 310,
		quantity: 408,
	},
	{
		garage_id: 121,
		part_id: 311,
		quantity: 2,
	},
	{
		garage_id: 121,
		part_id: 312,
		quantity: 242,
	},
	{
		garage_id: 121,
		part_id: 313,
		quantity: 385,
	},
	{
		garage_id: 121,
		part_id: 314,
		quantity: 375,
	},
	{
		garage_id: 121,
		part_id: 315,
		quantity: 99,
	},
	{
		garage_id: 121,
		part_id: 316,
		quantity: 186,
	},
	{
		garage_id: 121,
		part_id: 317,
		quantity: 312,
	},
	{
		garage_id: 121,
		part_id: 318,
		quantity: 127,
	},
	{
		garage_id: 121,
		part_id: 319,
		quantity: 280,
	},
	{
		garage_id: 121,
		part_id: 320,
		quantity: 373,
	},
	{
		garage_id: 121,
		part_id: 321,
		quantity: 395,
	},
	{
		garage_id: 121,
		part_id: 322,
		quantity: 128,
	},
	{
		garage_id: 121,
		part_id: 323,
		quantity: 61,
	},
	{
		garage_id: 121,
		part_id: 324,
		quantity: 222,
	},
	{
		garage_id: 121,
		part_id: 325,
		quantity: 112,
	},
	{
		garage_id: 121,
		part_id: 326,
		quantity: 296,
	},
	{
		garage_id: 121,
		part_id: 327,
		quantity: 409,
	},
	{
		garage_id: 121,
		part_id: 328,
		quantity: 322,
	},
	{
		garage_id: 121,
		part_id: 329,
		quantity: 422,
	},
	{
		garage_id: 121,
		part_id: 330,
		quantity: 384,
	},
	{
		garage_id: 121,
		part_id: 331,
		quantity: 477,
	},
	{
		garage_id: 121,
		part_id: 332,
		quantity: 343,
	},
	{
		garage_id: 121,
		part_id: 333,
		quantity: 174,
	},
	{
		garage_id: 121,
		part_id: 334,
		quantity: 138,
	},
	{
		garage_id: 121,
		part_id: 335,
		quantity: 426,
	},
	{
		garage_id: 121,
		part_id: 336,
		quantity: 85,
	},
	{
		garage_id: 121,
		part_id: 337,
		quantity: 181,
	},
	{
		garage_id: 121,
		part_id: 338,
		quantity: 366,
	},
	{
		garage_id: 121,
		part_id: 339,
		quantity: 18,
	},
	{
		garage_id: 121,
		part_id: 340,
		quantity: 457,
	},
	{
		garage_id: 121,
		part_id: 341,
		quantity: 303,
	},
	{
		garage_id: 121,
		part_id: 342,
		quantity: 462,
	},
	{
		garage_id: 121,
		part_id: 343,
		quantity: 85,
	},
	{
		garage_id: 121,
		part_id: 344,
		quantity: 240,
	},
	{
		garage_id: 121,
		part_id: 345,
		quantity: 132,
	},
	{
		garage_id: 121,
		part_id: 346,
		quantity: 487,
	},
	{
		garage_id: 121,
		part_id: 347,
		quantity: 325,
	},
	{
		garage_id: 121,
		part_id: 348,
		quantity: 127,
	},
	{
		garage_id: 121,
		part_id: 349,
		quantity: 58,
	},
	{
		garage_id: 121,
		part_id: 350,
		quantity: 398,
	},
	{
		garage_id: 121,
		part_id: 351,
		quantity: 478,
	},
	{
		garage_id: 121,
		part_id: 352,
		quantity: 8,
	},
	{
		garage_id: 121,
		part_id: 353,
		quantity: 201,
	},
	{
		garage_id: 121,
		part_id: 354,
		quantity: 351,
	},
	{
		garage_id: 121,
		part_id: 355,
		quantity: 119,
	},
	{
		garage_id: 121,
		part_id: 356,
		quantity: 295,
	},
	{
		garage_id: 121,
		part_id: 357,
		quantity: 244,
	},
	{
		garage_id: 121,
		part_id: 358,
		quantity: 20,
	},
	{
		garage_id: 121,
		part_id: 359,
		quantity: 273,
	},
	{
		garage_id: 121,
		part_id: 360,
		quantity: 50,
	},
	{
		garage_id: 121,
		part_id: 361,
		quantity: 263,
	},
	{
		garage_id: 121,
		part_id: 362,
		quantity: 163,
	},
	{
		garage_id: 121,
		part_id: 363,
		quantity: 7,
	},
	{
		garage_id: 121,
		part_id: 364,
		quantity: 221,
	},
	{
		garage_id: 121,
		part_id: 365,
		quantity: 432,
	},
	{
		garage_id: 121,
		part_id: 366,
		quantity: 343,
	},
	{
		garage_id: 121,
		part_id: 367,
		quantity: 271,
	},
	{
		garage_id: 121,
		part_id: 368,
		quantity: 8,
	},
	{
		garage_id: 121,
		part_id: 369,
		quantity: 71,
	},
	{
		garage_id: 121,
		part_id: 370,
		quantity: 416,
	},
	{
		garage_id: 121,
		part_id: 371,
		quantity: 26,
	},
	{
		garage_id: 121,
		part_id: 372,
		quantity: 266,
	},
	{
		garage_id: 121,
		part_id: 373,
		quantity: 49,
	},
	{
		garage_id: 121,
		part_id: 374,
		quantity: 191,
	},
	{
		garage_id: 121,
		part_id: 375,
		quantity: 368,
	},
	{
		garage_id: 121,
		part_id: 376,
		quantity: 55,
	},
	{
		garage_id: 121,
		part_id: 377,
		quantity: 185,
	},
	{
		garage_id: 121,
		part_id: 378,
		quantity: 252,
	},
	{
		garage_id: 121,
		part_id: 379,
		quantity: 98,
	},
	{
		garage_id: 121,
		part_id: 380,
		quantity: 323,
	},
	{
		garage_id: 121,
		part_id: 381,
		quantity: 363,
	},
	{
		garage_id: 121,
		part_id: 382,
		quantity: 66,
	},
	{
		garage_id: 121,
		part_id: 383,
		quantity: 175,
	},
	{
		garage_id: 121,
		part_id: 384,
		quantity: 415,
	},
	{
		garage_id: 121,
		part_id: 385,
		quantity: 182,
	},
	{
		garage_id: 121,
		part_id: 386,
		quantity: 132,
	},
	{
		garage_id: 121,
		part_id: 387,
		quantity: 396,
	},
	{
		garage_id: 121,
		part_id: 388,
		quantity: 500,
	},
	{
		garage_id: 121,
		part_id: 389,
		quantity: 364,
	},
	{
		garage_id: 121,
		part_id: 390,
		quantity: 269,
	},
	{
		garage_id: 121,
		part_id: 391,
		quantity: 447,
	},
	{
		garage_id: 121,
		part_id: 392,
		quantity: 483,
	},
	{
		garage_id: 121,
		part_id: 393,
		quantity: 480,
	},
	{
		garage_id: 121,
		part_id: 394,
		quantity: 51,
	},
	{
		garage_id: 121,
		part_id: 395,
		quantity: 444,
	},
	{
		garage_id: 121,
		part_id: 396,
		quantity: 272,
	},
	{
		garage_id: 121,
		part_id: 397,
		quantity: 7,
	},
	{
		garage_id: 121,
		part_id: 398,
		quantity: 301,
	},
	{
		garage_id: 121,
		part_id: 399,
		quantity: 473,
	},
	{
		garage_id: 122,
		part_id: 0,
		quantity: 107,
	},
	{
		garage_id: 122,
		part_id: 1,
		quantity: 427,
	},
	{
		garage_id: 122,
		part_id: 2,
		quantity: 238,
	},
	{
		garage_id: 122,
		part_id: 3,
		quantity: 471,
	},
	{
		garage_id: 122,
		part_id: 4,
		quantity: 344,
	},
	{
		garage_id: 122,
		part_id: 5,
		quantity: 198,
	},
	{
		garage_id: 122,
		part_id: 6,
		quantity: 308,
	},
	{
		garage_id: 122,
		part_id: 7,
		quantity: 328,
	},
	{
		garage_id: 122,
		part_id: 8,
		quantity: 240,
	},
	{
		garage_id: 122,
		part_id: 9,
		quantity: 397,
	},
	{
		garage_id: 122,
		part_id: 10,
		quantity: 205,
	},
	{
		garage_id: 122,
		part_id: 11,
		quantity: 227,
	},
	{
		garage_id: 122,
		part_id: 12,
		quantity: 493,
	},
	{
		garage_id: 122,
		part_id: 13,
		quantity: 262,
	},
	{
		garage_id: 122,
		part_id: 14,
		quantity: 192,
	},
	{
		garage_id: 122,
		part_id: 15,
		quantity: 185,
	},
	{
		garage_id: 122,
		part_id: 16,
		quantity: 32,
	},
	{
		garage_id: 122,
		part_id: 17,
		quantity: 269,
	},
	{
		garage_id: 122,
		part_id: 18,
		quantity: 179,
	},
	{
		garage_id: 122,
		part_id: 19,
		quantity: 382,
	},
	{
		garage_id: 122,
		part_id: 20,
		quantity: 42,
	},
	{
		garage_id: 122,
		part_id: 21,
		quantity: 329,
	},
	{
		garage_id: 122,
		part_id: 22,
		quantity: 397,
	},
	{
		garage_id: 122,
		part_id: 23,
		quantity: 362,
	},
	{
		garage_id: 122,
		part_id: 24,
		quantity: 313,
	},
	{
		garage_id: 122,
		part_id: 25,
		quantity: 33,
	},
	{
		garage_id: 122,
		part_id: 26,
		quantity: 204,
	},
	{
		garage_id: 122,
		part_id: 27,
		quantity: 470,
	},
	{
		garage_id: 122,
		part_id: 28,
		quantity: 340,
	},
	{
		garage_id: 122,
		part_id: 29,
		quantity: 54,
	},
	{
		garage_id: 122,
		part_id: 30,
		quantity: 346,
	},
	{
		garage_id: 122,
		part_id: 31,
		quantity: 395,
	},
	{
		garage_id: 122,
		part_id: 32,
		quantity: 210,
	},
	{
		garage_id: 122,
		part_id: 33,
		quantity: 86,
	},
	{
		garage_id: 122,
		part_id: 34,
		quantity: 431,
	},
	{
		garage_id: 122,
		part_id: 35,
		quantity: 410,
	},
	{
		garage_id: 122,
		part_id: 36,
		quantity: 381,
	},
	{
		garage_id: 122,
		part_id: 37,
		quantity: 306,
	},
	{
		garage_id: 122,
		part_id: 38,
		quantity: 136,
	},
	{
		garage_id: 122,
		part_id: 39,
		quantity: 22,
	},
	{
		garage_id: 122,
		part_id: 40,
		quantity: 478,
	},
	{
		garage_id: 122,
		part_id: 41,
		quantity: 162,
	},
	{
		garage_id: 122,
		part_id: 42,
		quantity: 102,
	},
	{
		garage_id: 122,
		part_id: 43,
		quantity: 97,
	},
	{
		garage_id: 122,
		part_id: 44,
		quantity: 428,
	},
	{
		garage_id: 122,
		part_id: 45,
		quantity: 427,
	},
	{
		garage_id: 122,
		part_id: 46,
		quantity: 320,
	},
	{
		garage_id: 122,
		part_id: 47,
		quantity: 302,
	},
	{
		garage_id: 122,
		part_id: 48,
		quantity: 268,
	},
	{
		garage_id: 122,
		part_id: 49,
		quantity: 202,
	},
	{
		garage_id: 122,
		part_id: 50,
		quantity: 385,
	},
	{
		garage_id: 122,
		part_id: 51,
		quantity: 443,
	},
	{
		garage_id: 122,
		part_id: 52,
		quantity: 491,
	},
	{
		garage_id: 122,
		part_id: 53,
		quantity: 244,
	},
	{
		garage_id: 122,
		part_id: 54,
		quantity: 486,
	},
	{
		garage_id: 122,
		part_id: 55,
		quantity: 227,
	},
	{
		garage_id: 122,
		part_id: 56,
		quantity: 155,
	},
	{
		garage_id: 122,
		part_id: 57,
		quantity: 275,
	},
	{
		garage_id: 122,
		part_id: 58,
		quantity: 287,
	},
	{
		garage_id: 122,
		part_id: 59,
		quantity: 226,
	},
	{
		garage_id: 122,
		part_id: 60,
		quantity: 191,
	},
	{
		garage_id: 122,
		part_id: 61,
		quantity: 487,
	},
	{
		garage_id: 122,
		part_id: 62,
		quantity: 207,
	},
	{
		garage_id: 122,
		part_id: 63,
		quantity: 1,
	},
	{
		garage_id: 122,
		part_id: 64,
		quantity: 403,
	},
	{
		garage_id: 122,
		part_id: 65,
		quantity: 57,
	},
	{
		garage_id: 122,
		part_id: 66,
		quantity: 109,
	},
	{
		garage_id: 122,
		part_id: 67,
		quantity: 216,
	},
	{
		garage_id: 122,
		part_id: 68,
		quantity: 156,
	},
	{
		garage_id: 122,
		part_id: 69,
		quantity: 452,
	},
	{
		garage_id: 122,
		part_id: 70,
		quantity: 421,
	},
	{
		garage_id: 122,
		part_id: 71,
		quantity: 17,
	},
	{
		garage_id: 122,
		part_id: 72,
		quantity: 54,
	},
	{
		garage_id: 122,
		part_id: 73,
		quantity: 192,
	},
	{
		garage_id: 122,
		part_id: 74,
		quantity: 465,
	},
	{
		garage_id: 122,
		part_id: 75,
		quantity: 59,
	},
	{
		garage_id: 122,
		part_id: 76,
		quantity: 209,
	},
	{
		garage_id: 122,
		part_id: 77,
		quantity: 68,
	},
	{
		garage_id: 122,
		part_id: 78,
		quantity: 190,
	},
	{
		garage_id: 122,
		part_id: 79,
		quantity: 447,
	},
	{
		garage_id: 122,
		part_id: 80,
		quantity: 4,
	},
	{
		garage_id: 122,
		part_id: 81,
		quantity: 86,
	},
	{
		garage_id: 122,
		part_id: 82,
		quantity: 53,
	},
	{
		garage_id: 122,
		part_id: 83,
		quantity: 389,
	},
	{
		garage_id: 122,
		part_id: 84,
		quantity: 236,
	},
	{
		garage_id: 122,
		part_id: 85,
		quantity: 158,
	},
	{
		garage_id: 122,
		part_id: 86,
		quantity: 456,
	},
	{
		garage_id: 122,
		part_id: 87,
		quantity: 283,
	},
	{
		garage_id: 122,
		part_id: 88,
		quantity: 442,
	},
	{
		garage_id: 122,
		part_id: 89,
		quantity: 335,
	},
	{
		garage_id: 122,
		part_id: 90,
		quantity: 441,
	},
	{
		garage_id: 122,
		part_id: 91,
		quantity: 209,
	},
	{
		garage_id: 122,
		part_id: 92,
		quantity: 34,
	},
	{
		garage_id: 122,
		part_id: 93,
		quantity: 20,
	},
	{
		garage_id: 122,
		part_id: 94,
		quantity: 301,
	},
	{
		garage_id: 122,
		part_id: 95,
		quantity: 110,
	},
	{
		garage_id: 122,
		part_id: 96,
		quantity: 24,
	},
	{
		garage_id: 122,
		part_id: 97,
		quantity: 22,
	},
	{
		garage_id: 122,
		part_id: 98,
		quantity: 500,
	},
	{
		garage_id: 122,
		part_id: 99,
		quantity: 128,
	},
	{
		garage_id: 122,
		part_id: 100,
		quantity: 236,
	},
	{
		garage_id: 122,
		part_id: 101,
		quantity: 54,
	},
	{
		garage_id: 122,
		part_id: 102,
		quantity: 236,
	},
	{
		garage_id: 122,
		part_id: 103,
		quantity: 173,
	},
	{
		garage_id: 122,
		part_id: 104,
		quantity: 392,
	},
	{
		garage_id: 122,
		part_id: 105,
		quantity: 172,
	},
	{
		garage_id: 122,
		part_id: 106,
		quantity: 267,
	},
	{
		garage_id: 122,
		part_id: 107,
		quantity: 31,
	},
	{
		garage_id: 122,
		part_id: 108,
		quantity: 452,
	},
	{
		garage_id: 122,
		part_id: 109,
		quantity: 207,
	},
	{
		garage_id: 122,
		part_id: 110,
		quantity: 32,
	},
	{
		garage_id: 122,
		part_id: 111,
		quantity: 452,
	},
	{
		garage_id: 122,
		part_id: 112,
		quantity: 60,
	},
	{
		garage_id: 122,
		part_id: 113,
		quantity: 190,
	},
	{
		garage_id: 122,
		part_id: 114,
		quantity: 2,
	},
	{
		garage_id: 122,
		part_id: 115,
		quantity: 121,
	},
	{
		garage_id: 122,
		part_id: 116,
		quantity: 347,
	},
	{
		garage_id: 122,
		part_id: 117,
		quantity: 256,
	},
	{
		garage_id: 122,
		part_id: 118,
		quantity: 174,
	},
	{
		garage_id: 122,
		part_id: 119,
		quantity: 460,
	},
	{
		garage_id: 122,
		part_id: 120,
		quantity: 260,
	},
	{
		garage_id: 122,
		part_id: 121,
		quantity: 444,
	},
	{
		garage_id: 122,
		part_id: 122,
		quantity: 156,
	},
	{
		garage_id: 122,
		part_id: 123,
		quantity: 197,
	},
	{
		garage_id: 122,
		part_id: 124,
		quantity: 449,
	},
	{
		garage_id: 122,
		part_id: 125,
		quantity: 264,
	},
	{
		garage_id: 122,
		part_id: 126,
		quantity: 134,
	},
	{
		garage_id: 122,
		part_id: 127,
		quantity: 370,
	},
	{
		garage_id: 122,
		part_id: 128,
		quantity: 119,
	},
	{
		garage_id: 122,
		part_id: 129,
		quantity: 284,
	},
	{
		garage_id: 122,
		part_id: 130,
		quantity: 396,
	},
	{
		garage_id: 122,
		part_id: 131,
		quantity: 56,
	},
	{
		garage_id: 122,
		part_id: 132,
		quantity: 34,
	},
	{
		garage_id: 122,
		part_id: 133,
		quantity: 401,
	},
	{
		garage_id: 122,
		part_id: 134,
		quantity: 392,
	},
	{
		garage_id: 122,
		part_id: 135,
		quantity: 0,
	},
	{
		garage_id: 122,
		part_id: 136,
		quantity: 469,
	},
	{
		garage_id: 122,
		part_id: 137,
		quantity: 2,
	},
	{
		garage_id: 122,
		part_id: 138,
		quantity: 189,
	},
	{
		garage_id: 122,
		part_id: 139,
		quantity: 109,
	},
	{
		garage_id: 122,
		part_id: 140,
		quantity: 474,
	},
	{
		garage_id: 122,
		part_id: 141,
		quantity: 182,
	},
	{
		garage_id: 122,
		part_id: 142,
		quantity: 386,
	},
	{
		garage_id: 122,
		part_id: 143,
		quantity: 55,
	},
	{
		garage_id: 122,
		part_id: 144,
		quantity: 420,
	},
	{
		garage_id: 122,
		part_id: 145,
		quantity: 55,
	},
	{
		garage_id: 122,
		part_id: 146,
		quantity: 29,
	},
	{
		garage_id: 122,
		part_id: 147,
		quantity: 199,
	},
	{
		garage_id: 122,
		part_id: 148,
		quantity: 428,
	},
	{
		garage_id: 122,
		part_id: 149,
		quantity: 179,
	},
	{
		garage_id: 122,
		part_id: 150,
		quantity: 458,
	},
	{
		garage_id: 122,
		part_id: 151,
		quantity: 196,
	},
	{
		garage_id: 122,
		part_id: 152,
		quantity: 366,
	},
	{
		garage_id: 122,
		part_id: 153,
		quantity: 358,
	},
	{
		garage_id: 122,
		part_id: 154,
		quantity: 219,
	},
	{
		garage_id: 122,
		part_id: 155,
		quantity: 226,
	},
	{
		garage_id: 122,
		part_id: 156,
		quantity: 490,
	},
	{
		garage_id: 122,
		part_id: 157,
		quantity: 176,
	},
	{
		garage_id: 122,
		part_id: 158,
		quantity: 397,
	},
	{
		garage_id: 122,
		part_id: 159,
		quantity: 312,
	},
	{
		garage_id: 122,
		part_id: 160,
		quantity: 268,
	},
	{
		garage_id: 122,
		part_id: 161,
		quantity: 238,
	},
	{
		garage_id: 122,
		part_id: 162,
		quantity: 435,
	},
	{
		garage_id: 122,
		part_id: 163,
		quantity: 252,
	},
	{
		garage_id: 122,
		part_id: 164,
		quantity: 341,
	},
	{
		garage_id: 122,
		part_id: 165,
		quantity: 413,
	},
	{
		garage_id: 122,
		part_id: 166,
		quantity: 447,
	},
	{
		garage_id: 122,
		part_id: 167,
		quantity: 299,
	},
	{
		garage_id: 122,
		part_id: 168,
		quantity: 180,
	},
	{
		garage_id: 122,
		part_id: 169,
		quantity: 267,
	},
	{
		garage_id: 122,
		part_id: 170,
		quantity: 271,
	},
	{
		garage_id: 122,
		part_id: 171,
		quantity: 469,
	},
	{
		garage_id: 122,
		part_id: 172,
		quantity: 181,
	},
	{
		garage_id: 122,
		part_id: 173,
		quantity: 454,
	},
	{
		garage_id: 122,
		part_id: 174,
		quantity: 462,
	},
	{
		garage_id: 122,
		part_id: 175,
		quantity: 123,
	},
	{
		garage_id: 122,
		part_id: 176,
		quantity: 7,
	},
	{
		garage_id: 122,
		part_id: 177,
		quantity: 490,
	},
	{
		garage_id: 122,
		part_id: 178,
		quantity: 291,
	},
	{
		garage_id: 122,
		part_id: 179,
		quantity: 209,
	},
	{
		garage_id: 122,
		part_id: 180,
		quantity: 491,
	},
	{
		garage_id: 122,
		part_id: 181,
		quantity: 75,
	},
	{
		garage_id: 122,
		part_id: 182,
		quantity: 382,
	},
	{
		garage_id: 122,
		part_id: 183,
		quantity: 25,
	},
	{
		garage_id: 122,
		part_id: 184,
		quantity: 387,
	},
	{
		garage_id: 122,
		part_id: 185,
		quantity: 296,
	},
	{
		garage_id: 122,
		part_id: 186,
		quantity: 424,
	},
	{
		garage_id: 122,
		part_id: 187,
		quantity: 252,
	},
	{
		garage_id: 122,
		part_id: 188,
		quantity: 79,
	},
	{
		garage_id: 122,
		part_id: 189,
		quantity: 460,
	},
	{
		garage_id: 122,
		part_id: 190,
		quantity: 440,
	},
	{
		garage_id: 122,
		part_id: 191,
		quantity: 86,
	},
	{
		garage_id: 122,
		part_id: 192,
		quantity: 227,
	},
	{
		garage_id: 122,
		part_id: 193,
		quantity: 26,
	},
	{
		garage_id: 122,
		part_id: 194,
		quantity: 260,
	},
	{
		garage_id: 122,
		part_id: 195,
		quantity: 145,
	},
	{
		garage_id: 122,
		part_id: 196,
		quantity: 74,
	},
	{
		garage_id: 122,
		part_id: 197,
		quantity: 400,
	},
	{
		garage_id: 122,
		part_id: 198,
		quantity: 22,
	},
	{
		garage_id: 122,
		part_id: 199,
		quantity: 162,
	},
	{
		garage_id: 122,
		part_id: 200,
		quantity: 425,
	},
	{
		garage_id: 122,
		part_id: 201,
		quantity: 80,
	},
	{
		garage_id: 122,
		part_id: 202,
		quantity: 201,
	},
	{
		garage_id: 122,
		part_id: 203,
		quantity: 1,
	},
	{
		garage_id: 122,
		part_id: 204,
		quantity: 141,
	},
	{
		garage_id: 122,
		part_id: 205,
		quantity: 356,
	},
	{
		garage_id: 122,
		part_id: 206,
		quantity: 273,
	},
	{
		garage_id: 122,
		part_id: 207,
		quantity: 100,
	},
	{
		garage_id: 122,
		part_id: 208,
		quantity: 267,
	},
	{
		garage_id: 122,
		part_id: 209,
		quantity: 2,
	},
	{
		garage_id: 122,
		part_id: 210,
		quantity: 220,
	},
	{
		garage_id: 122,
		part_id: 211,
		quantity: 485,
	},
	{
		garage_id: 122,
		part_id: 212,
		quantity: 462,
	},
	{
		garage_id: 122,
		part_id: 213,
		quantity: 350,
	},
	{
		garage_id: 122,
		part_id: 214,
		quantity: 50,
	},
	{
		garage_id: 122,
		part_id: 215,
		quantity: 286,
	},
	{
		garage_id: 122,
		part_id: 216,
		quantity: 53,
	},
	{
		garage_id: 122,
		part_id: 217,
		quantity: 409,
	},
	{
		garage_id: 122,
		part_id: 218,
		quantity: 129,
	},
	{
		garage_id: 122,
		part_id: 219,
		quantity: 364,
	},
	{
		garage_id: 122,
		part_id: 220,
		quantity: 307,
	},
	{
		garage_id: 122,
		part_id: 221,
		quantity: 109,
	},
	{
		garage_id: 122,
		part_id: 222,
		quantity: 461,
	},
	{
		garage_id: 122,
		part_id: 223,
		quantity: 378,
	},
	{
		garage_id: 122,
		part_id: 224,
		quantity: 468,
	},
	{
		garage_id: 122,
		part_id: 225,
		quantity: 397,
	},
	{
		garage_id: 122,
		part_id: 226,
		quantity: 223,
	},
	{
		garage_id: 122,
		part_id: 227,
		quantity: 436,
	},
	{
		garage_id: 122,
		part_id: 228,
		quantity: 495,
	},
	{
		garage_id: 122,
		part_id: 229,
		quantity: 102,
	},
	{
		garage_id: 122,
		part_id: 230,
		quantity: 39,
	},
	{
		garage_id: 122,
		part_id: 231,
		quantity: 73,
	},
	{
		garage_id: 122,
		part_id: 232,
		quantity: 181,
	},
	{
		garage_id: 122,
		part_id: 233,
		quantity: 437,
	},
	{
		garage_id: 122,
		part_id: 234,
		quantity: 277,
	},
	{
		garage_id: 122,
		part_id: 235,
		quantity: 278,
	},
	{
		garage_id: 122,
		part_id: 236,
		quantity: 202,
	},
	{
		garage_id: 122,
		part_id: 237,
		quantity: 265,
	},
	{
		garage_id: 122,
		part_id: 238,
		quantity: 112,
	},
	{
		garage_id: 122,
		part_id: 239,
		quantity: 399,
	},
	{
		garage_id: 122,
		part_id: 240,
		quantity: 114,
	},
	{
		garage_id: 122,
		part_id: 241,
		quantity: 45,
	},
	{
		garage_id: 122,
		part_id: 242,
		quantity: 362,
	},
	{
		garage_id: 122,
		part_id: 243,
		quantity: 419,
	},
	{
		garage_id: 122,
		part_id: 244,
		quantity: 223,
	},
	{
		garage_id: 122,
		part_id: 245,
		quantity: 222,
	},
	{
		garage_id: 122,
		part_id: 246,
		quantity: 448,
	},
	{
		garage_id: 122,
		part_id: 247,
		quantity: 468,
	},
	{
		garage_id: 122,
		part_id: 248,
		quantity: 121,
	},
	{
		garage_id: 122,
		part_id: 249,
		quantity: 177,
	},
	{
		garage_id: 122,
		part_id: 250,
		quantity: 361,
	},
	{
		garage_id: 122,
		part_id: 251,
		quantity: 366,
	},
	{
		garage_id: 122,
		part_id: 252,
		quantity: 318,
	},
	{
		garage_id: 122,
		part_id: 253,
		quantity: 11,
	},
	{
		garage_id: 122,
		part_id: 254,
		quantity: 472,
	},
	{
		garage_id: 122,
		part_id: 255,
		quantity: 317,
	},
	{
		garage_id: 122,
		part_id: 256,
		quantity: 362,
	},
	{
		garage_id: 122,
		part_id: 257,
		quantity: 351,
	},
	{
		garage_id: 122,
		part_id: 258,
		quantity: 241,
	},
	{
		garage_id: 122,
		part_id: 259,
		quantity: 84,
	},
	{
		garage_id: 122,
		part_id: 260,
		quantity: 407,
	},
	{
		garage_id: 122,
		part_id: 261,
		quantity: 462,
	},
	{
		garage_id: 122,
		part_id: 262,
		quantity: 346,
	},
	{
		garage_id: 122,
		part_id: 263,
		quantity: 187,
	},
	{
		garage_id: 122,
		part_id: 264,
		quantity: 291,
	},
	{
		garage_id: 122,
		part_id: 265,
		quantity: 92,
	},
	{
		garage_id: 122,
		part_id: 266,
		quantity: 135,
	},
	{
		garage_id: 122,
		part_id: 267,
		quantity: 354,
	},
	{
		garage_id: 122,
		part_id: 268,
		quantity: 146,
	},
	{
		garage_id: 122,
		part_id: 269,
		quantity: 134,
	},
	{
		garage_id: 122,
		part_id: 270,
		quantity: 78,
	},
	{
		garage_id: 122,
		part_id: 271,
		quantity: 254,
	},
	{
		garage_id: 122,
		part_id: 272,
		quantity: 3,
	},
	{
		garage_id: 122,
		part_id: 273,
		quantity: 291,
	},
	{
		garage_id: 122,
		part_id: 274,
		quantity: 209,
	},
	{
		garage_id: 122,
		part_id: 275,
		quantity: 378,
	},
	{
		garage_id: 122,
		part_id: 276,
		quantity: 261,
	},
	{
		garage_id: 122,
		part_id: 277,
		quantity: 313,
	},
	{
		garage_id: 122,
		part_id: 278,
		quantity: 500,
	},
	{
		garage_id: 122,
		part_id: 279,
		quantity: 211,
	},
	{
		garage_id: 122,
		part_id: 280,
		quantity: 340,
	},
	{
		garage_id: 122,
		part_id: 281,
		quantity: 351,
	},
	{
		garage_id: 122,
		part_id: 282,
		quantity: 409,
	},
	{
		garage_id: 122,
		part_id: 283,
		quantity: 453,
	},
	{
		garage_id: 122,
		part_id: 284,
		quantity: 260,
	},
	{
		garage_id: 122,
		part_id: 285,
		quantity: 325,
	},
	{
		garage_id: 122,
		part_id: 286,
		quantity: 300,
	},
	{
		garage_id: 122,
		part_id: 287,
		quantity: 91,
	},
	{
		garage_id: 122,
		part_id: 288,
		quantity: 80,
	},
	{
		garage_id: 122,
		part_id: 289,
		quantity: 94,
	},
	{
		garage_id: 122,
		part_id: 290,
		quantity: 424,
	},
	{
		garage_id: 122,
		part_id: 291,
		quantity: 254,
	},
	{
		garage_id: 122,
		part_id: 292,
		quantity: 417,
	},
	{
		garage_id: 122,
		part_id: 293,
		quantity: 108,
	},
	{
		garage_id: 122,
		part_id: 294,
		quantity: 408,
	},
	{
		garage_id: 122,
		part_id: 295,
		quantity: 458,
	},
	{
		garage_id: 122,
		part_id: 296,
		quantity: 445,
	},
	{
		garage_id: 122,
		part_id: 297,
		quantity: 45,
	},
	{
		garage_id: 122,
		part_id: 298,
		quantity: 67,
	},
	{
		garage_id: 122,
		part_id: 299,
		quantity: 254,
	},
	{
		garage_id: 122,
		part_id: 300,
		quantity: 379,
	},
	{
		garage_id: 122,
		part_id: 301,
		quantity: 288,
	},
	{
		garage_id: 122,
		part_id: 302,
		quantity: 48,
	},
	{
		garage_id: 122,
		part_id: 303,
		quantity: 411,
	},
	{
		garage_id: 122,
		part_id: 304,
		quantity: 186,
	},
	{
		garage_id: 122,
		part_id: 305,
		quantity: 433,
	},
	{
		garage_id: 122,
		part_id: 306,
		quantity: 195,
	},
	{
		garage_id: 122,
		part_id: 307,
		quantity: 213,
	},
	{
		garage_id: 122,
		part_id: 308,
		quantity: 74,
	},
	{
		garage_id: 122,
		part_id: 309,
		quantity: 422,
	},
	{
		garage_id: 122,
		part_id: 310,
		quantity: 452,
	},
	{
		garage_id: 122,
		part_id: 311,
		quantity: 306,
	},
	{
		garage_id: 122,
		part_id: 312,
		quantity: 500,
	},
	{
		garage_id: 122,
		part_id: 313,
		quantity: 102,
	},
	{
		garage_id: 122,
		part_id: 314,
		quantity: 139,
	},
	{
		garage_id: 122,
		part_id: 315,
		quantity: 425,
	},
	{
		garage_id: 122,
		part_id: 316,
		quantity: 313,
	},
	{
		garage_id: 122,
		part_id: 317,
		quantity: 125,
	},
	{
		garage_id: 122,
		part_id: 318,
		quantity: 41,
	},
	{
		garage_id: 122,
		part_id: 319,
		quantity: 360,
	},
	{
		garage_id: 122,
		part_id: 320,
		quantity: 300,
	},
	{
		garage_id: 122,
		part_id: 321,
		quantity: 8,
	},
	{
		garage_id: 122,
		part_id: 322,
		quantity: 211,
	},
	{
		garage_id: 122,
		part_id: 323,
		quantity: 46,
	},
	{
		garage_id: 122,
		part_id: 324,
		quantity: 480,
	},
	{
		garage_id: 122,
		part_id: 325,
		quantity: 104,
	},
	{
		garage_id: 122,
		part_id: 326,
		quantity: 471,
	},
	{
		garage_id: 122,
		part_id: 327,
		quantity: 457,
	},
	{
		garage_id: 122,
		part_id: 328,
		quantity: 371,
	},
	{
		garage_id: 122,
		part_id: 329,
		quantity: 266,
	},
	{
		garage_id: 122,
		part_id: 330,
		quantity: 122,
	},
	{
		garage_id: 122,
		part_id: 331,
		quantity: 211,
	},
	{
		garage_id: 122,
		part_id: 332,
		quantity: 97,
	},
	{
		garage_id: 122,
		part_id: 333,
		quantity: 219,
	},
	{
		garage_id: 122,
		part_id: 334,
		quantity: 182,
	},
	{
		garage_id: 122,
		part_id: 335,
		quantity: 16,
	},
	{
		garage_id: 122,
		part_id: 336,
		quantity: 158,
	},
	{
		garage_id: 122,
		part_id: 337,
		quantity: 166,
	},
	{
		garage_id: 122,
		part_id: 338,
		quantity: 94,
	},
	{
		garage_id: 122,
		part_id: 339,
		quantity: 209,
	},
	{
		garage_id: 122,
		part_id: 340,
		quantity: 499,
	},
	{
		garage_id: 122,
		part_id: 341,
		quantity: 46,
	},
	{
		garage_id: 122,
		part_id: 342,
		quantity: 326,
	},
	{
		garage_id: 122,
		part_id: 343,
		quantity: 267,
	},
	{
		garage_id: 122,
		part_id: 344,
		quantity: 419,
	},
	{
		garage_id: 122,
		part_id: 345,
		quantity: 84,
	},
	{
		garage_id: 122,
		part_id: 346,
		quantity: 493,
	},
	{
		garage_id: 122,
		part_id: 347,
		quantity: 64,
	},
	{
		garage_id: 122,
		part_id: 348,
		quantity: 479,
	},
	{
		garage_id: 122,
		part_id: 349,
		quantity: 272,
	},
	{
		garage_id: 122,
		part_id: 350,
		quantity: 478,
	},
	{
		garage_id: 122,
		part_id: 351,
		quantity: 217,
	},
	{
		garage_id: 122,
		part_id: 352,
		quantity: 111,
	},
	{
		garage_id: 122,
		part_id: 353,
		quantity: 228,
	},
	{
		garage_id: 122,
		part_id: 354,
		quantity: 262,
	},
	{
		garage_id: 122,
		part_id: 355,
		quantity: 145,
	},
	{
		garage_id: 122,
		part_id: 356,
		quantity: 119,
	},
	{
		garage_id: 122,
		part_id: 357,
		quantity: 165,
	},
	{
		garage_id: 122,
		part_id: 358,
		quantity: 153,
	},
	{
		garage_id: 122,
		part_id: 359,
		quantity: 475,
	},
	{
		garage_id: 122,
		part_id: 360,
		quantity: 260,
	},
	{
		garage_id: 122,
		part_id: 361,
		quantity: 229,
	},
	{
		garage_id: 122,
		part_id: 362,
		quantity: 201,
	},
	{
		garage_id: 122,
		part_id: 363,
		quantity: 364,
	},
	{
		garage_id: 122,
		part_id: 364,
		quantity: 73,
	},
	{
		garage_id: 122,
		part_id: 365,
		quantity: 436,
	},
	{
		garage_id: 122,
		part_id: 366,
		quantity: 205,
	},
	{
		garage_id: 122,
		part_id: 367,
		quantity: 101,
	},
	{
		garage_id: 122,
		part_id: 368,
		quantity: 313,
	},
	{
		garage_id: 122,
		part_id: 369,
		quantity: 154,
	},
	{
		garage_id: 122,
		part_id: 370,
		quantity: 219,
	},
	{
		garage_id: 122,
		part_id: 371,
		quantity: 307,
	},
	{
		garage_id: 122,
		part_id: 372,
		quantity: 336,
	},
	{
		garage_id: 122,
		part_id: 373,
		quantity: 426,
	},
	{
		garage_id: 122,
		part_id: 374,
		quantity: 46,
	},
	{
		garage_id: 122,
		part_id: 375,
		quantity: 360,
	},
	{
		garage_id: 122,
		part_id: 376,
		quantity: 255,
	},
	{
		garage_id: 122,
		part_id: 377,
		quantity: 317,
	},
	{
		garage_id: 122,
		part_id: 378,
		quantity: 460,
	},
	{
		garage_id: 122,
		part_id: 379,
		quantity: 456,
	},
	{
		garage_id: 122,
		part_id: 380,
		quantity: 26,
	},
	{
		garage_id: 122,
		part_id: 381,
		quantity: 374,
	},
	{
		garage_id: 122,
		part_id: 382,
		quantity: 317,
	},
	{
		garage_id: 122,
		part_id: 383,
		quantity: 307,
	},
	{
		garage_id: 122,
		part_id: 384,
		quantity: 155,
	},
	{
		garage_id: 122,
		part_id: 385,
		quantity: 30,
	},
	{
		garage_id: 122,
		part_id: 386,
		quantity: 153,
	},
	{
		garage_id: 122,
		part_id: 387,
		quantity: 248,
	},
	{
		garage_id: 122,
		part_id: 388,
		quantity: 72,
	},
	{
		garage_id: 122,
		part_id: 389,
		quantity: 451,
	},
	{
		garage_id: 122,
		part_id: 390,
		quantity: 248,
	},
	{
		garage_id: 122,
		part_id: 391,
		quantity: 461,
	},
	{
		garage_id: 122,
		part_id: 392,
		quantity: 349,
	},
	{
		garage_id: 122,
		part_id: 393,
		quantity: 469,
	},
	{
		garage_id: 122,
		part_id: 394,
		quantity: 15,
	},
	{
		garage_id: 122,
		part_id: 395,
		quantity: 251,
	},
	{
		garage_id: 122,
		part_id: 396,
		quantity: 78,
	},
	{
		garage_id: 122,
		part_id: 397,
		quantity: 407,
	},
	{
		garage_id: 122,
		part_id: 398,
		quantity: 49,
	},
	{
		garage_id: 122,
		part_id: 399,
		quantity: 34,
	},
	{
		garage_id: 123,
		part_id: 0,
		quantity: 208,
	},
	{
		garage_id: 123,
		part_id: 1,
		quantity: 71,
	},
	{
		garage_id: 123,
		part_id: 2,
		quantity: 83,
	},
	{
		garage_id: 123,
		part_id: 3,
		quantity: 399,
	},
	{
		garage_id: 123,
		part_id: 4,
		quantity: 286,
	},
	{
		garage_id: 123,
		part_id: 5,
		quantity: 244,
	},
	{
		garage_id: 123,
		part_id: 6,
		quantity: 274,
	},
	{
		garage_id: 123,
		part_id: 7,
		quantity: 490,
	},
	{
		garage_id: 123,
		part_id: 8,
		quantity: 265,
	},
	{
		garage_id: 123,
		part_id: 9,
		quantity: 332,
	},
	{
		garage_id: 123,
		part_id: 10,
		quantity: 111,
	},
	{
		garage_id: 123,
		part_id: 11,
		quantity: 119,
	},
	{
		garage_id: 123,
		part_id: 12,
		quantity: 382,
	},
	{
		garage_id: 123,
		part_id: 13,
		quantity: 127,
	},
	{
		garage_id: 123,
		part_id: 14,
		quantity: 234,
	},
	{
		garage_id: 123,
		part_id: 15,
		quantity: 147,
	},
	{
		garage_id: 123,
		part_id: 16,
		quantity: 487,
	},
	{
		garage_id: 123,
		part_id: 17,
		quantity: 345,
	},
	{
		garage_id: 123,
		part_id: 18,
		quantity: 26,
	},
	{
		garage_id: 123,
		part_id: 19,
		quantity: 58,
	},
	{
		garage_id: 123,
		part_id: 20,
		quantity: 310,
	},
	{
		garage_id: 123,
		part_id: 21,
		quantity: 125,
	},
	{
		garage_id: 123,
		part_id: 22,
		quantity: 224,
	},
	{
		garage_id: 123,
		part_id: 23,
		quantity: 281,
	},
	{
		garage_id: 123,
		part_id: 24,
		quantity: 192,
	},
	{
		garage_id: 123,
		part_id: 25,
		quantity: 252,
	},
	{
		garage_id: 123,
		part_id: 26,
		quantity: 28,
	},
	{
		garage_id: 123,
		part_id: 27,
		quantity: 119,
	},
	{
		garage_id: 123,
		part_id: 28,
		quantity: 73,
	},
	{
		garage_id: 123,
		part_id: 29,
		quantity: 347,
	},
	{
		garage_id: 123,
		part_id: 30,
		quantity: 88,
	},
	{
		garage_id: 123,
		part_id: 31,
		quantity: 208,
	},
	{
		garage_id: 123,
		part_id: 32,
		quantity: 481,
	},
	{
		garage_id: 123,
		part_id: 33,
		quantity: 168,
	},
	{
		garage_id: 123,
		part_id: 34,
		quantity: 220,
	},
	{
		garage_id: 123,
		part_id: 35,
		quantity: 337,
	},
	{
		garage_id: 123,
		part_id: 36,
		quantity: 435,
	},
	{
		garage_id: 123,
		part_id: 37,
		quantity: 409,
	},
	{
		garage_id: 123,
		part_id: 38,
		quantity: 40,
	},
	{
		garage_id: 123,
		part_id: 39,
		quantity: 56,
	},
	{
		garage_id: 123,
		part_id: 40,
		quantity: 422,
	},
	{
		garage_id: 123,
		part_id: 41,
		quantity: 81,
	},
	{
		garage_id: 123,
		part_id: 42,
		quantity: 224,
	},
	{
		garage_id: 123,
		part_id: 43,
		quantity: 153,
	},
	{
		garage_id: 123,
		part_id: 44,
		quantity: 8,
	},
	{
		garage_id: 123,
		part_id: 45,
		quantity: 192,
	},
	{
		garage_id: 123,
		part_id: 46,
		quantity: 36,
	},
	{
		garage_id: 123,
		part_id: 47,
		quantity: 161,
	},
	{
		garage_id: 123,
		part_id: 48,
		quantity: 316,
	},
	{
		garage_id: 123,
		part_id: 49,
		quantity: 129,
	},
	{
		garage_id: 123,
		part_id: 50,
		quantity: 228,
	},
	{
		garage_id: 123,
		part_id: 51,
		quantity: 334,
	},
	{
		garage_id: 123,
		part_id: 52,
		quantity: 46,
	},
	{
		garage_id: 123,
		part_id: 53,
		quantity: 271,
	},
	{
		garage_id: 123,
		part_id: 54,
		quantity: 180,
	},
	{
		garage_id: 123,
		part_id: 55,
		quantity: 273,
	},
	{
		garage_id: 123,
		part_id: 56,
		quantity: 343,
	},
	{
		garage_id: 123,
		part_id: 57,
		quantity: 234,
	},
	{
		garage_id: 123,
		part_id: 58,
		quantity: 355,
	},
	{
		garage_id: 123,
		part_id: 59,
		quantity: 398,
	},
	{
		garage_id: 123,
		part_id: 60,
		quantity: 241,
	},
	{
		garage_id: 123,
		part_id: 61,
		quantity: 188,
	},
	{
		garage_id: 123,
		part_id: 62,
		quantity: 98,
	},
	{
		garage_id: 123,
		part_id: 63,
		quantity: 136,
	},
	{
		garage_id: 123,
		part_id: 64,
		quantity: 393,
	},
	{
		garage_id: 123,
		part_id: 65,
		quantity: 197,
	},
	{
		garage_id: 123,
		part_id: 66,
		quantity: 389,
	},
	{
		garage_id: 123,
		part_id: 67,
		quantity: 311,
	},
	{
		garage_id: 123,
		part_id: 68,
		quantity: 468,
	},
	{
		garage_id: 123,
		part_id: 69,
		quantity: 461,
	},
	{
		garage_id: 123,
		part_id: 70,
		quantity: 237,
	},
	{
		garage_id: 123,
		part_id: 71,
		quantity: 424,
	},
	{
		garage_id: 123,
		part_id: 72,
		quantity: 237,
	},
	{
		garage_id: 123,
		part_id: 73,
		quantity: 352,
	},
	{
		garage_id: 123,
		part_id: 74,
		quantity: 399,
	},
	{
		garage_id: 123,
		part_id: 75,
		quantity: 437,
	},
	{
		garage_id: 123,
		part_id: 76,
		quantity: 145,
	},
	{
		garage_id: 123,
		part_id: 77,
		quantity: 35,
	},
	{
		garage_id: 123,
		part_id: 78,
		quantity: 48,
	},
	{
		garage_id: 123,
		part_id: 79,
		quantity: 153,
	},
	{
		garage_id: 123,
		part_id: 80,
		quantity: 100,
	},
	{
		garage_id: 123,
		part_id: 81,
		quantity: 230,
	},
	{
		garage_id: 123,
		part_id: 82,
		quantity: 395,
	},
	{
		garage_id: 123,
		part_id: 83,
		quantity: 134,
	},
	{
		garage_id: 123,
		part_id: 84,
		quantity: 274,
	},
	{
		garage_id: 123,
		part_id: 85,
		quantity: 41,
	},
	{
		garage_id: 123,
		part_id: 86,
		quantity: 191,
	},
	{
		garage_id: 123,
		part_id: 87,
		quantity: 386,
	},
	{
		garage_id: 123,
		part_id: 88,
		quantity: 101,
	},
	{
		garage_id: 123,
		part_id: 89,
		quantity: 117,
	},
	{
		garage_id: 123,
		part_id: 90,
		quantity: 251,
	},
	{
		garage_id: 123,
		part_id: 91,
		quantity: 499,
	},
	{
		garage_id: 123,
		part_id: 92,
		quantity: 438,
	},
	{
		garage_id: 123,
		part_id: 93,
		quantity: 171,
	},
	{
		garage_id: 123,
		part_id: 94,
		quantity: 190,
	},
	{
		garage_id: 123,
		part_id: 95,
		quantity: 29,
	},
	{
		garage_id: 123,
		part_id: 96,
		quantity: 159,
	},
	{
		garage_id: 123,
		part_id: 97,
		quantity: 335,
	},
	{
		garage_id: 123,
		part_id: 98,
		quantity: 69,
	},
	{
		garage_id: 123,
		part_id: 99,
		quantity: 124,
	},
	{
		garage_id: 123,
		part_id: 100,
		quantity: 362,
	},
	{
		garage_id: 123,
		part_id: 101,
		quantity: 234,
	},
	{
		garage_id: 123,
		part_id: 102,
		quantity: 289,
	},
	{
		garage_id: 123,
		part_id: 103,
		quantity: 439,
	},
	{
		garage_id: 123,
		part_id: 104,
		quantity: 465,
	},
	{
		garage_id: 123,
		part_id: 105,
		quantity: 209,
	},
	{
		garage_id: 123,
		part_id: 106,
		quantity: 319,
	},
	{
		garage_id: 123,
		part_id: 107,
		quantity: 380,
	},
	{
		garage_id: 123,
		part_id: 108,
		quantity: 300,
	},
	{
		garage_id: 123,
		part_id: 109,
		quantity: 163,
	},
	{
		garage_id: 123,
		part_id: 110,
		quantity: 297,
	},
	{
		garage_id: 123,
		part_id: 111,
		quantity: 451,
	},
	{
		garage_id: 123,
		part_id: 112,
		quantity: 259,
	},
	{
		garage_id: 123,
		part_id: 113,
		quantity: 300,
	},
	{
		garage_id: 123,
		part_id: 114,
		quantity: 415,
	},
	{
		garage_id: 123,
		part_id: 115,
		quantity: 147,
	},
	{
		garage_id: 123,
		part_id: 116,
		quantity: 308,
	},
	{
		garage_id: 123,
		part_id: 117,
		quantity: 1,
	},
	{
		garage_id: 123,
		part_id: 118,
		quantity: 467,
	},
	{
		garage_id: 123,
		part_id: 119,
		quantity: 15,
	},
	{
		garage_id: 123,
		part_id: 120,
		quantity: 306,
	},
	{
		garage_id: 123,
		part_id: 121,
		quantity: 121,
	},
	{
		garage_id: 123,
		part_id: 122,
		quantity: 0,
	},
	{
		garage_id: 123,
		part_id: 123,
		quantity: 46,
	},
	{
		garage_id: 123,
		part_id: 124,
		quantity: 70,
	},
	{
		garage_id: 123,
		part_id: 125,
		quantity: 365,
	},
	{
		garage_id: 123,
		part_id: 126,
		quantity: 48,
	},
	{
		garage_id: 123,
		part_id: 127,
		quantity: 447,
	},
	{
		garage_id: 123,
		part_id: 128,
		quantity: 230,
	},
	{
		garage_id: 123,
		part_id: 129,
		quantity: 181,
	},
	{
		garage_id: 123,
		part_id: 130,
		quantity: 6,
	},
	{
		garage_id: 123,
		part_id: 131,
		quantity: 69,
	},
	{
		garage_id: 123,
		part_id: 132,
		quantity: 179,
	},
	{
		garage_id: 123,
		part_id: 133,
		quantity: 86,
	},
	{
		garage_id: 123,
		part_id: 134,
		quantity: 58,
	},
	{
		garage_id: 123,
		part_id: 135,
		quantity: 12,
	},
	{
		garage_id: 123,
		part_id: 136,
		quantity: 414,
	},
	{
		garage_id: 123,
		part_id: 137,
		quantity: 468,
	},
	{
		garage_id: 123,
		part_id: 138,
		quantity: 92,
	},
	{
		garage_id: 123,
		part_id: 139,
		quantity: 391,
	},
	{
		garage_id: 123,
		part_id: 140,
		quantity: 368,
	},
	{
		garage_id: 123,
		part_id: 141,
		quantity: 68,
	},
	{
		garage_id: 123,
		part_id: 142,
		quantity: 488,
	},
	{
		garage_id: 123,
		part_id: 143,
		quantity: 455,
	},
	{
		garage_id: 123,
		part_id: 144,
		quantity: 143,
	},
	{
		garage_id: 123,
		part_id: 145,
		quantity: 337,
	},
	{
		garage_id: 123,
		part_id: 146,
		quantity: 69,
	},
	{
		garage_id: 123,
		part_id: 147,
		quantity: 30,
	},
	{
		garage_id: 123,
		part_id: 148,
		quantity: 60,
	},
	{
		garage_id: 123,
		part_id: 149,
		quantity: 141,
	},
	{
		garage_id: 123,
		part_id: 150,
		quantity: 492,
	},
	{
		garage_id: 123,
		part_id: 151,
		quantity: 291,
	},
	{
		garage_id: 123,
		part_id: 152,
		quantity: 230,
	},
	{
		garage_id: 123,
		part_id: 153,
		quantity: 162,
	},
	{
		garage_id: 123,
		part_id: 154,
		quantity: 189,
	},
	{
		garage_id: 123,
		part_id: 155,
		quantity: 435,
	},
	{
		garage_id: 123,
		part_id: 156,
		quantity: 232,
	},
	{
		garage_id: 123,
		part_id: 157,
		quantity: 464,
	},
	{
		garage_id: 123,
		part_id: 158,
		quantity: 115,
	},
	{
		garage_id: 123,
		part_id: 159,
		quantity: 209,
	},
	{
		garage_id: 123,
		part_id: 160,
		quantity: 176,
	},
	{
		garage_id: 123,
		part_id: 161,
		quantity: 27,
	},
	{
		garage_id: 123,
		part_id: 162,
		quantity: 426,
	},
	{
		garage_id: 123,
		part_id: 163,
		quantity: 126,
	},
	{
		garage_id: 123,
		part_id: 164,
		quantity: 356,
	},
	{
		garage_id: 123,
		part_id: 165,
		quantity: 365,
	},
	{
		garage_id: 123,
		part_id: 166,
		quantity: 282,
	},
	{
		garage_id: 123,
		part_id: 167,
		quantity: 430,
	},
	{
		garage_id: 123,
		part_id: 168,
		quantity: 281,
	},
	{
		garage_id: 123,
		part_id: 169,
		quantity: 454,
	},
	{
		garage_id: 123,
		part_id: 170,
		quantity: 379,
	},
	{
		garage_id: 123,
		part_id: 171,
		quantity: 293,
	},
	{
		garage_id: 123,
		part_id: 172,
		quantity: 380,
	},
	{
		garage_id: 123,
		part_id: 173,
		quantity: 281,
	},
	{
		garage_id: 123,
		part_id: 174,
		quantity: 340,
	},
	{
		garage_id: 123,
		part_id: 175,
		quantity: 152,
	},
	{
		garage_id: 123,
		part_id: 176,
		quantity: 264,
	},
	{
		garage_id: 123,
		part_id: 177,
		quantity: 399,
	},
	{
		garage_id: 123,
		part_id: 178,
		quantity: 117,
	},
	{
		garage_id: 123,
		part_id: 179,
		quantity: 461,
	},
	{
		garage_id: 123,
		part_id: 180,
		quantity: 399,
	},
	{
		garage_id: 123,
		part_id: 181,
		quantity: 349,
	},
	{
		garage_id: 123,
		part_id: 182,
		quantity: 349,
	},
	{
		garage_id: 123,
		part_id: 183,
		quantity: 258,
	},
	{
		garage_id: 123,
		part_id: 184,
		quantity: 468,
	},
	{
		garage_id: 123,
		part_id: 185,
		quantity: 376,
	},
	{
		garage_id: 123,
		part_id: 186,
		quantity: 242,
	},
	{
		garage_id: 123,
		part_id: 187,
		quantity: 454,
	},
	{
		garage_id: 123,
		part_id: 188,
		quantity: 28,
	},
	{
		garage_id: 123,
		part_id: 189,
		quantity: 96,
	},
	{
		garage_id: 123,
		part_id: 190,
		quantity: 264,
	},
	{
		garage_id: 123,
		part_id: 191,
		quantity: 158,
	},
	{
		garage_id: 123,
		part_id: 192,
		quantity: 229,
	},
	{
		garage_id: 123,
		part_id: 193,
		quantity: 149,
	},
	{
		garage_id: 123,
		part_id: 194,
		quantity: 46,
	},
	{
		garage_id: 123,
		part_id: 195,
		quantity: 123,
	},
	{
		garage_id: 123,
		part_id: 196,
		quantity: 139,
	},
	{
		garage_id: 123,
		part_id: 197,
		quantity: 462,
	},
	{
		garage_id: 123,
		part_id: 198,
		quantity: 236,
	},
	{
		garage_id: 123,
		part_id: 199,
		quantity: 96,
	},
	{
		garage_id: 123,
		part_id: 200,
		quantity: 355,
	},
	{
		garage_id: 123,
		part_id: 201,
		quantity: 453,
	},
	{
		garage_id: 123,
		part_id: 202,
		quantity: 164,
	},
	{
		garage_id: 123,
		part_id: 203,
		quantity: 24,
	},
	{
		garage_id: 123,
		part_id: 204,
		quantity: 301,
	},
	{
		garage_id: 123,
		part_id: 205,
		quantity: 323,
	},
	{
		garage_id: 123,
		part_id: 206,
		quantity: 265,
	},
	{
		garage_id: 123,
		part_id: 207,
		quantity: 217,
	},
	{
		garage_id: 123,
		part_id: 208,
		quantity: 59,
	},
	{
		garage_id: 123,
		part_id: 209,
		quantity: 115,
	},
	{
		garage_id: 123,
		part_id: 210,
		quantity: 26,
	},
	{
		garage_id: 123,
		part_id: 211,
		quantity: 404,
	},
	{
		garage_id: 123,
		part_id: 212,
		quantity: 431,
	},
	{
		garage_id: 123,
		part_id: 213,
		quantity: 451,
	},
	{
		garage_id: 123,
		part_id: 214,
		quantity: 254,
	},
	{
		garage_id: 123,
		part_id: 215,
		quantity: 485,
	},
	{
		garage_id: 123,
		part_id: 216,
		quantity: 159,
	},
	{
		garage_id: 123,
		part_id: 217,
		quantity: 238,
	},
	{
		garage_id: 123,
		part_id: 218,
		quantity: 337,
	},
	{
		garage_id: 123,
		part_id: 219,
		quantity: 97,
	},
	{
		garage_id: 123,
		part_id: 220,
		quantity: 230,
	},
	{
		garage_id: 123,
		part_id: 221,
		quantity: 20,
	},
	{
		garage_id: 123,
		part_id: 222,
		quantity: 490,
	},
	{
		garage_id: 123,
		part_id: 223,
		quantity: 489,
	},
	{
		garage_id: 123,
		part_id: 224,
		quantity: 95,
	},
	{
		garage_id: 123,
		part_id: 225,
		quantity: 460,
	},
	{
		garage_id: 123,
		part_id: 226,
		quantity: 147,
	},
	{
		garage_id: 123,
		part_id: 227,
		quantity: 2,
	},
	{
		garage_id: 123,
		part_id: 228,
		quantity: 182,
	},
	{
		garage_id: 123,
		part_id: 229,
		quantity: 127,
	},
	{
		garage_id: 123,
		part_id: 230,
		quantity: 462,
	},
	{
		garage_id: 123,
		part_id: 231,
		quantity: 119,
	},
	{
		garage_id: 123,
		part_id: 232,
		quantity: 55,
	},
	{
		garage_id: 123,
		part_id: 233,
		quantity: 183,
	},
	{
		garage_id: 123,
		part_id: 234,
		quantity: 146,
	},
	{
		garage_id: 123,
		part_id: 235,
		quantity: 343,
	},
	{
		garage_id: 123,
		part_id: 236,
		quantity: 203,
	},
	{
		garage_id: 123,
		part_id: 237,
		quantity: 318,
	},
	{
		garage_id: 123,
		part_id: 238,
		quantity: 175,
	},
	{
		garage_id: 123,
		part_id: 239,
		quantity: 320,
	},
	{
		garage_id: 123,
		part_id: 240,
		quantity: 113,
	},
	{
		garage_id: 123,
		part_id: 241,
		quantity: 389,
	},
	{
		garage_id: 123,
		part_id: 242,
		quantity: 444,
	},
	{
		garage_id: 123,
		part_id: 243,
		quantity: 210,
	},
	{
		garage_id: 123,
		part_id: 244,
		quantity: 151,
	},
	{
		garage_id: 123,
		part_id: 245,
		quantity: 471,
	},
	{
		garage_id: 123,
		part_id: 246,
		quantity: 50,
	},
	{
		garage_id: 123,
		part_id: 247,
		quantity: 95,
	},
	{
		garage_id: 123,
		part_id: 248,
		quantity: 97,
	},
	{
		garage_id: 123,
		part_id: 249,
		quantity: 224,
	},
	{
		garage_id: 123,
		part_id: 250,
		quantity: 216,
	},
	{
		garage_id: 123,
		part_id: 251,
		quantity: 103,
	},
	{
		garage_id: 123,
		part_id: 252,
		quantity: 466,
	},
	{
		garage_id: 123,
		part_id: 253,
		quantity: 177,
	},
	{
		garage_id: 123,
		part_id: 254,
		quantity: 477,
	},
	{
		garage_id: 123,
		part_id: 255,
		quantity: 326,
	},
	{
		garage_id: 123,
		part_id: 256,
		quantity: 460,
	},
	{
		garage_id: 123,
		part_id: 257,
		quantity: 65,
	},
	{
		garage_id: 123,
		part_id: 258,
		quantity: 293,
	},
	{
		garage_id: 123,
		part_id: 259,
		quantity: 381,
	},
	{
		garage_id: 123,
		part_id: 260,
		quantity: 113,
	},
	{
		garage_id: 123,
		part_id: 261,
		quantity: 483,
	},
	{
		garage_id: 123,
		part_id: 262,
		quantity: 195,
	},
	{
		garage_id: 123,
		part_id: 263,
		quantity: 172,
	},
	{
		garage_id: 123,
		part_id: 264,
		quantity: 174,
	},
	{
		garage_id: 123,
		part_id: 265,
		quantity: 418,
	},
	{
		garage_id: 123,
		part_id: 266,
		quantity: 216,
	},
	{
		garage_id: 123,
		part_id: 267,
		quantity: 300,
	},
	{
		garage_id: 123,
		part_id: 268,
		quantity: 371,
	},
	{
		garage_id: 123,
		part_id: 269,
		quantity: 177,
	},
	{
		garage_id: 123,
		part_id: 270,
		quantity: 270,
	},
	{
		garage_id: 123,
		part_id: 271,
		quantity: 456,
	},
	{
		garage_id: 123,
		part_id: 272,
		quantity: 292,
	},
	{
		garage_id: 123,
		part_id: 273,
		quantity: 141,
	},
	{
		garage_id: 123,
		part_id: 274,
		quantity: 441,
	},
	{
		garage_id: 123,
		part_id: 275,
		quantity: 453,
	},
	{
		garage_id: 123,
		part_id: 276,
		quantity: 95,
	},
	{
		garage_id: 123,
		part_id: 277,
		quantity: 499,
	},
	{
		garage_id: 123,
		part_id: 278,
		quantity: 459,
	},
	{
		garage_id: 123,
		part_id: 279,
		quantity: 156,
	},
	{
		garage_id: 123,
		part_id: 280,
		quantity: 414,
	},
	{
		garage_id: 123,
		part_id: 281,
		quantity: 217,
	},
	{
		garage_id: 123,
		part_id: 282,
		quantity: 75,
	},
	{
		garage_id: 123,
		part_id: 283,
		quantity: 18,
	},
	{
		garage_id: 123,
		part_id: 284,
		quantity: 317,
	},
	{
		garage_id: 123,
		part_id: 285,
		quantity: 254,
	},
	{
		garage_id: 123,
		part_id: 286,
		quantity: 117,
	},
	{
		garage_id: 123,
		part_id: 287,
		quantity: 65,
	},
	{
		garage_id: 123,
		part_id: 288,
		quantity: 461,
	},
	{
		garage_id: 123,
		part_id: 289,
		quantity: 348,
	},
	{
		garage_id: 123,
		part_id: 290,
		quantity: 251,
	},
	{
		garage_id: 123,
		part_id: 291,
		quantity: 466,
	},
	{
		garage_id: 123,
		part_id: 292,
		quantity: 400,
	},
	{
		garage_id: 123,
		part_id: 293,
		quantity: 170,
	},
	{
		garage_id: 123,
		part_id: 294,
		quantity: 456,
	},
	{
		garage_id: 123,
		part_id: 295,
		quantity: 392,
	},
	{
		garage_id: 123,
		part_id: 296,
		quantity: 270,
	},
	{
		garage_id: 123,
		part_id: 297,
		quantity: 77,
	},
	{
		garage_id: 123,
		part_id: 298,
		quantity: 400,
	},
	{
		garage_id: 123,
		part_id: 299,
		quantity: 171,
	},
	{
		garage_id: 123,
		part_id: 300,
		quantity: 62,
	},
	{
		garage_id: 123,
		part_id: 301,
		quantity: 143,
	},
	{
		garage_id: 123,
		part_id: 302,
		quantity: 459,
	},
	{
		garage_id: 123,
		part_id: 303,
		quantity: 322,
	},
	{
		garage_id: 123,
		part_id: 304,
		quantity: 120,
	},
	{
		garage_id: 123,
		part_id: 305,
		quantity: 129,
	},
	{
		garage_id: 123,
		part_id: 306,
		quantity: 397,
	},
	{
		garage_id: 123,
		part_id: 307,
		quantity: 340,
	},
	{
		garage_id: 123,
		part_id: 308,
		quantity: 51,
	},
	{
		garage_id: 123,
		part_id: 309,
		quantity: 454,
	},
	{
		garage_id: 123,
		part_id: 310,
		quantity: 194,
	},
	{
		garage_id: 123,
		part_id: 311,
		quantity: 103,
	},
	{
		garage_id: 123,
		part_id: 312,
		quantity: 113,
	},
	{
		garage_id: 123,
		part_id: 313,
		quantity: 467,
	},
	{
		garage_id: 123,
		part_id: 314,
		quantity: 162,
	},
	{
		garage_id: 123,
		part_id: 315,
		quantity: 405,
	},
	{
		garage_id: 123,
		part_id: 316,
		quantity: 10,
	},
	{
		garage_id: 123,
		part_id: 317,
		quantity: 41,
	},
	{
		garage_id: 123,
		part_id: 318,
		quantity: 280,
	},
	{
		garage_id: 123,
		part_id: 319,
		quantity: 269,
	},
	{
		garage_id: 123,
		part_id: 320,
		quantity: 99,
	},
	{
		garage_id: 123,
		part_id: 321,
		quantity: 483,
	},
	{
		garage_id: 123,
		part_id: 322,
		quantity: 468,
	},
	{
		garage_id: 123,
		part_id: 323,
		quantity: 217,
	},
	{
		garage_id: 123,
		part_id: 324,
		quantity: 7,
	},
	{
		garage_id: 123,
		part_id: 325,
		quantity: 370,
	},
	{
		garage_id: 123,
		part_id: 326,
		quantity: 191,
	},
	{
		garage_id: 123,
		part_id: 327,
		quantity: 446,
	},
	{
		garage_id: 123,
		part_id: 328,
		quantity: 412,
	},
	{
		garage_id: 123,
		part_id: 329,
		quantity: 354,
	},
	{
		garage_id: 123,
		part_id: 330,
		quantity: 29,
	},
	{
		garage_id: 123,
		part_id: 331,
		quantity: 191,
	},
	{
		garage_id: 123,
		part_id: 332,
		quantity: 333,
	},
	{
		garage_id: 123,
		part_id: 333,
		quantity: 387,
	},
	{
		garage_id: 123,
		part_id: 334,
		quantity: 120,
	},
	{
		garage_id: 123,
		part_id: 335,
		quantity: 170,
	},
	{
		garage_id: 123,
		part_id: 336,
		quantity: 234,
	},
	{
		garage_id: 123,
		part_id: 337,
		quantity: 117,
	},
	{
		garage_id: 123,
		part_id: 338,
		quantity: 357,
	},
	{
		garage_id: 123,
		part_id: 339,
		quantity: 218,
	},
	{
		garage_id: 123,
		part_id: 340,
		quantity: 420,
	},
	{
		garage_id: 123,
		part_id: 341,
		quantity: 289,
	},
	{
		garage_id: 123,
		part_id: 342,
		quantity: 390,
	},
	{
		garage_id: 123,
		part_id: 343,
		quantity: 340,
	},
	{
		garage_id: 123,
		part_id: 344,
		quantity: 210,
	},
	{
		garage_id: 123,
		part_id: 345,
		quantity: 257,
	},
	{
		garage_id: 123,
		part_id: 346,
		quantity: 401,
	},
	{
		garage_id: 123,
		part_id: 347,
		quantity: 226,
	},
	{
		garage_id: 123,
		part_id: 348,
		quantity: 361,
	},
	{
		garage_id: 123,
		part_id: 349,
		quantity: 87,
	},
	{
		garage_id: 123,
		part_id: 350,
		quantity: 247,
	},
	{
		garage_id: 123,
		part_id: 351,
		quantity: 151,
	},
	{
		garage_id: 123,
		part_id: 352,
		quantity: 226,
	},
	{
		garage_id: 123,
		part_id: 353,
		quantity: 180,
	},
	{
		garage_id: 123,
		part_id: 354,
		quantity: 471,
	},
	{
		garage_id: 123,
		part_id: 355,
		quantity: 499,
	},
	{
		garage_id: 123,
		part_id: 356,
		quantity: 477,
	},
	{
		garage_id: 123,
		part_id: 357,
		quantity: 281,
	},
	{
		garage_id: 123,
		part_id: 358,
		quantity: 495,
	},
	{
		garage_id: 123,
		part_id: 359,
		quantity: 155,
	},
	{
		garage_id: 123,
		part_id: 360,
		quantity: 257,
	},
	{
		garage_id: 123,
		part_id: 361,
		quantity: 457,
	},
	{
		garage_id: 123,
		part_id: 362,
		quantity: 255,
	},
	{
		garage_id: 123,
		part_id: 363,
		quantity: 356,
	},
	{
		garage_id: 123,
		part_id: 364,
		quantity: 213,
	},
	{
		garage_id: 123,
		part_id: 365,
		quantity: 423,
	},
	{
		garage_id: 123,
		part_id: 366,
		quantity: 439,
	},
	{
		garage_id: 123,
		part_id: 367,
		quantity: 44,
	},
	{
		garage_id: 123,
		part_id: 368,
		quantity: 37,
	},
	{
		garage_id: 123,
		part_id: 369,
		quantity: 79,
	},
	{
		garage_id: 123,
		part_id: 370,
		quantity: 321,
	},
	{
		garage_id: 123,
		part_id: 371,
		quantity: 295,
	},
	{
		garage_id: 123,
		part_id: 372,
		quantity: 244,
	},
	{
		garage_id: 123,
		part_id: 373,
		quantity: 317,
	},
	{
		garage_id: 123,
		part_id: 374,
		quantity: 396,
	},
	{
		garage_id: 123,
		part_id: 375,
		quantity: 151,
	},
	{
		garage_id: 123,
		part_id: 376,
		quantity: 106,
	},
	{
		garage_id: 123,
		part_id: 377,
		quantity: 87,
	},
	{
		garage_id: 123,
		part_id: 378,
		quantity: 485,
	},
	{
		garage_id: 123,
		part_id: 379,
		quantity: 187,
	},
	{
		garage_id: 123,
		part_id: 380,
		quantity: 160,
	},
	{
		garage_id: 123,
		part_id: 381,
		quantity: 277,
	},
	{
		garage_id: 123,
		part_id: 382,
		quantity: 280,
	},
	{
		garage_id: 123,
		part_id: 383,
		quantity: 155,
	},
	{
		garage_id: 123,
		part_id: 384,
		quantity: 159,
	},
	{
		garage_id: 123,
		part_id: 385,
		quantity: 5,
	},
	{
		garage_id: 123,
		part_id: 386,
		quantity: 159,
	},
	{
		garage_id: 123,
		part_id: 387,
		quantity: 310,
	},
	{
		garage_id: 123,
		part_id: 388,
		quantity: 86,
	},
	{
		garage_id: 123,
		part_id: 389,
		quantity: 203,
	},
	{
		garage_id: 123,
		part_id: 390,
		quantity: 29,
	},
	{
		garage_id: 123,
		part_id: 391,
		quantity: 379,
	},
	{
		garage_id: 123,
		part_id: 392,
		quantity: 369,
	},
	{
		garage_id: 123,
		part_id: 393,
		quantity: 416,
	},
	{
		garage_id: 123,
		part_id: 394,
		quantity: 432,
	},
	{
		garage_id: 123,
		part_id: 395,
		quantity: 109,
	},
	{
		garage_id: 123,
		part_id: 396,
		quantity: 149,
	},
	{
		garage_id: 123,
		part_id: 397,
		quantity: 139,
	},
	{
		garage_id: 123,
		part_id: 398,
		quantity: 264,
	},
	{
		garage_id: 123,
		part_id: 399,
		quantity: 104,
	},
	{
		garage_id: 124,
		part_id: 0,
		quantity: 259,
	},
	{
		garage_id: 124,
		part_id: 1,
		quantity: 255,
	},
	{
		garage_id: 124,
		part_id: 2,
		quantity: 408,
	},
	{
		garage_id: 124,
		part_id: 3,
		quantity: 467,
	},
	{
		garage_id: 124,
		part_id: 4,
		quantity: 42,
	},
	{
		garage_id: 124,
		part_id: 5,
		quantity: 408,
	},
	{
		garage_id: 124,
		part_id: 6,
		quantity: 413,
	},
	{
		garage_id: 124,
		part_id: 7,
		quantity: 246,
	},
	{
		garage_id: 124,
		part_id: 8,
		quantity: 217,
	},
	{
		garage_id: 124,
		part_id: 9,
		quantity: 10,
	},
	{
		garage_id: 124,
		part_id: 10,
		quantity: 75,
	},
	{
		garage_id: 124,
		part_id: 11,
		quantity: 249,
	},
	{
		garage_id: 124,
		part_id: 12,
		quantity: 313,
	},
	{
		garage_id: 124,
		part_id: 13,
		quantity: 341,
	},
	{
		garage_id: 124,
		part_id: 14,
		quantity: 291,
	},
	{
		garage_id: 124,
		part_id: 15,
		quantity: 355,
	},
	{
		garage_id: 124,
		part_id: 16,
		quantity: 97,
	},
	{
		garage_id: 124,
		part_id: 17,
		quantity: 372,
	},
	{
		garage_id: 124,
		part_id: 18,
		quantity: 184,
	},
	{
		garage_id: 124,
		part_id: 19,
		quantity: 53,
	},
	{
		garage_id: 124,
		part_id: 20,
		quantity: 217,
	},
	{
		garage_id: 124,
		part_id: 21,
		quantity: 138,
	},
	{
		garage_id: 124,
		part_id: 22,
		quantity: 46,
	},
	{
		garage_id: 124,
		part_id: 23,
		quantity: 439,
	},
	{
		garage_id: 124,
		part_id: 24,
		quantity: 101,
	},
	{
		garage_id: 124,
		part_id: 25,
		quantity: 46,
	},
	{
		garage_id: 124,
		part_id: 26,
		quantity: 418,
	},
	{
		garage_id: 124,
		part_id: 27,
		quantity: 422,
	},
	{
		garage_id: 124,
		part_id: 28,
		quantity: 237,
	},
	{
		garage_id: 124,
		part_id: 29,
		quantity: 345,
	},
	{
		garage_id: 124,
		part_id: 30,
		quantity: 39,
	},
	{
		garage_id: 124,
		part_id: 31,
		quantity: 440,
	},
	{
		garage_id: 124,
		part_id: 32,
		quantity: 37,
	},
	{
		garage_id: 124,
		part_id: 33,
		quantity: 45,
	},
	{
		garage_id: 124,
		part_id: 34,
		quantity: 260,
	},
	{
		garage_id: 124,
		part_id: 35,
		quantity: 169,
	},
	{
		garage_id: 124,
		part_id: 36,
		quantity: 361,
	},
	{
		garage_id: 124,
		part_id: 37,
		quantity: 342,
	},
	{
		garage_id: 124,
		part_id: 38,
		quantity: 358,
	},
	{
		garage_id: 124,
		part_id: 39,
		quantity: 163,
	},
	{
		garage_id: 124,
		part_id: 40,
		quantity: 354,
	},
	{
		garage_id: 124,
		part_id: 41,
		quantity: 56,
	},
	{
		garage_id: 124,
		part_id: 42,
		quantity: 150,
	},
	{
		garage_id: 124,
		part_id: 43,
		quantity: 337,
	},
	{
		garage_id: 124,
		part_id: 44,
		quantity: 203,
	},
	{
		garage_id: 124,
		part_id: 45,
		quantity: 118,
	},
	{
		garage_id: 124,
		part_id: 46,
		quantity: 80,
	},
	{
		garage_id: 124,
		part_id: 47,
		quantity: 299,
	},
	{
		garage_id: 124,
		part_id: 48,
		quantity: 50,
	},
	{
		garage_id: 124,
		part_id: 49,
		quantity: 142,
	},
	{
		garage_id: 124,
		part_id: 50,
		quantity: 447,
	},
	{
		garage_id: 124,
		part_id: 51,
		quantity: 195,
	},
	{
		garage_id: 124,
		part_id: 52,
		quantity: 109,
	},
	{
		garage_id: 124,
		part_id: 53,
		quantity: 331,
	},
	{
		garage_id: 124,
		part_id: 54,
		quantity: 207,
	},
	{
		garage_id: 124,
		part_id: 55,
		quantity: 70,
	},
	{
		garage_id: 124,
		part_id: 56,
		quantity: 47,
	},
	{
		garage_id: 124,
		part_id: 57,
		quantity: 122,
	},
	{
		garage_id: 124,
		part_id: 58,
		quantity: 5,
	},
	{
		garage_id: 124,
		part_id: 59,
		quantity: 175,
	},
	{
		garage_id: 124,
		part_id: 60,
		quantity: 185,
	},
	{
		garage_id: 124,
		part_id: 61,
		quantity: 379,
	},
	{
		garage_id: 124,
		part_id: 62,
		quantity: 414,
	},
	{
		garage_id: 124,
		part_id: 63,
		quantity: 337,
	},
	{
		garage_id: 124,
		part_id: 64,
		quantity: 246,
	},
	{
		garage_id: 124,
		part_id: 65,
		quantity: 260,
	},
	{
		garage_id: 124,
		part_id: 66,
		quantity: 90,
	},
	{
		garage_id: 124,
		part_id: 67,
		quantity: 434,
	},
	{
		garage_id: 124,
		part_id: 68,
		quantity: 207,
	},
	{
		garage_id: 124,
		part_id: 69,
		quantity: 94,
	},
	{
		garage_id: 124,
		part_id: 70,
		quantity: 288,
	},
	{
		garage_id: 124,
		part_id: 71,
		quantity: 248,
	},
	{
		garage_id: 124,
		part_id: 72,
		quantity: 483,
	},
	{
		garage_id: 124,
		part_id: 73,
		quantity: 163,
	},
	{
		garage_id: 124,
		part_id: 74,
		quantity: 310,
	},
	{
		garage_id: 124,
		part_id: 75,
		quantity: 274,
	},
	{
		garage_id: 124,
		part_id: 76,
		quantity: 235,
	},
	{
		garage_id: 124,
		part_id: 77,
		quantity: 290,
	},
	{
		garage_id: 124,
		part_id: 78,
		quantity: 215,
	},
	{
		garage_id: 124,
		part_id: 79,
		quantity: 44,
	},
	{
		garage_id: 124,
		part_id: 80,
		quantity: 315,
	},
	{
		garage_id: 124,
		part_id: 81,
		quantity: 457,
	},
	{
		garage_id: 124,
		part_id: 82,
		quantity: 117,
	},
	{
		garage_id: 124,
		part_id: 83,
		quantity: 48,
	},
	{
		garage_id: 124,
		part_id: 84,
		quantity: 431,
	},
	{
		garage_id: 124,
		part_id: 85,
		quantity: 169,
	},
	{
		garage_id: 124,
		part_id: 86,
		quantity: 209,
	},
	{
		garage_id: 124,
		part_id: 87,
		quantity: 93,
	},
	{
		garage_id: 124,
		part_id: 88,
		quantity: 49,
	},
	{
		garage_id: 124,
		part_id: 89,
		quantity: 134,
	},
	{
		garage_id: 124,
		part_id: 90,
		quantity: 468,
	},
	{
		garage_id: 124,
		part_id: 91,
		quantity: 300,
	},
	{
		garage_id: 124,
		part_id: 92,
		quantity: 246,
	},
	{
		garage_id: 124,
		part_id: 93,
		quantity: 171,
	},
	{
		garage_id: 124,
		part_id: 94,
		quantity: 413,
	},
	{
		garage_id: 124,
		part_id: 95,
		quantity: 252,
	},
	{
		garage_id: 124,
		part_id: 96,
		quantity: 150,
	},
	{
		garage_id: 124,
		part_id: 97,
		quantity: 297,
	},
	{
		garage_id: 124,
		part_id: 98,
		quantity: 314,
	},
	{
		garage_id: 124,
		part_id: 99,
		quantity: 64,
	},
	{
		garage_id: 124,
		part_id: 100,
		quantity: 181,
	},
	{
		garage_id: 124,
		part_id: 101,
		quantity: 406,
	},
	{
		garage_id: 124,
		part_id: 102,
		quantity: 197,
	},
	{
		garage_id: 124,
		part_id: 103,
		quantity: 19,
	},
	{
		garage_id: 124,
		part_id: 104,
		quantity: 322,
	},
	{
		garage_id: 124,
		part_id: 105,
		quantity: 127,
	},
	{
		garage_id: 124,
		part_id: 106,
		quantity: 251,
	},
	{
		garage_id: 124,
		part_id: 107,
		quantity: 165,
	},
	{
		garage_id: 124,
		part_id: 108,
		quantity: 405,
	},
	{
		garage_id: 124,
		part_id: 109,
		quantity: 61,
	},
	{
		garage_id: 124,
		part_id: 110,
		quantity: 141,
	},
	{
		garage_id: 124,
		part_id: 111,
		quantity: 383,
	},
	{
		garage_id: 124,
		part_id: 112,
		quantity: 83,
	},
	{
		garage_id: 124,
		part_id: 113,
		quantity: 29,
	},
	{
		garage_id: 124,
		part_id: 114,
		quantity: 272,
	},
	{
		garage_id: 124,
		part_id: 115,
		quantity: 344,
	},
	{
		garage_id: 124,
		part_id: 116,
		quantity: 473,
	},
	{
		garage_id: 124,
		part_id: 117,
		quantity: 494,
	},
	{
		garage_id: 124,
		part_id: 118,
		quantity: 188,
	},
	{
		garage_id: 124,
		part_id: 119,
		quantity: 323,
	},
	{
		garage_id: 124,
		part_id: 120,
		quantity: 289,
	},
	{
		garage_id: 124,
		part_id: 121,
		quantity: 218,
	},
	{
		garage_id: 124,
		part_id: 122,
		quantity: 437,
	},
	{
		garage_id: 124,
		part_id: 123,
		quantity: 466,
	},
	{
		garage_id: 124,
		part_id: 124,
		quantity: 252,
	},
	{
		garage_id: 124,
		part_id: 125,
		quantity: 110,
	},
	{
		garage_id: 124,
		part_id: 126,
		quantity: 486,
	},
	{
		garage_id: 124,
		part_id: 127,
		quantity: 317,
	},
	{
		garage_id: 124,
		part_id: 128,
		quantity: 405,
	},
	{
		garage_id: 124,
		part_id: 129,
		quantity: 285,
	},
	{
		garage_id: 124,
		part_id: 130,
		quantity: 424,
	},
	{
		garage_id: 124,
		part_id: 131,
		quantity: 190,
	},
	{
		garage_id: 124,
		part_id: 132,
		quantity: 94,
	},
	{
		garage_id: 124,
		part_id: 133,
		quantity: 199,
	},
	{
		garage_id: 124,
		part_id: 134,
		quantity: 212,
	},
	{
		garage_id: 124,
		part_id: 135,
		quantity: 147,
	},
	{
		garage_id: 124,
		part_id: 136,
		quantity: 105,
	},
	{
		garage_id: 124,
		part_id: 137,
		quantity: 408,
	},
	{
		garage_id: 124,
		part_id: 138,
		quantity: 45,
	},
	{
		garage_id: 124,
		part_id: 139,
		quantity: 370,
	},
	{
		garage_id: 124,
		part_id: 140,
		quantity: 132,
	},
	{
		garage_id: 124,
		part_id: 141,
		quantity: 223,
	},
	{
		garage_id: 124,
		part_id: 142,
		quantity: 298,
	},
	{
		garage_id: 124,
		part_id: 143,
		quantity: 271,
	},
	{
		garage_id: 124,
		part_id: 144,
		quantity: 384,
	},
	{
		garage_id: 124,
		part_id: 145,
		quantity: 441,
	},
	{
		garage_id: 124,
		part_id: 146,
		quantity: 22,
	},
	{
		garage_id: 124,
		part_id: 147,
		quantity: 118,
	},
	{
		garage_id: 124,
		part_id: 148,
		quantity: 217,
	},
	{
		garage_id: 124,
		part_id: 149,
		quantity: 190,
	},
	{
		garage_id: 124,
		part_id: 150,
		quantity: 285,
	},
	{
		garage_id: 124,
		part_id: 151,
		quantity: 130,
	},
	{
		garage_id: 124,
		part_id: 152,
		quantity: 146,
	},
	{
		garage_id: 124,
		part_id: 153,
		quantity: 265,
	},
	{
		garage_id: 124,
		part_id: 154,
		quantity: 129,
	},
	{
		garage_id: 124,
		part_id: 155,
		quantity: 358,
	},
	{
		garage_id: 124,
		part_id: 156,
		quantity: 125,
	},
	{
		garage_id: 124,
		part_id: 157,
		quantity: 180,
	},
	{
		garage_id: 124,
		part_id: 158,
		quantity: 287,
	},
	{
		garage_id: 124,
		part_id: 159,
		quantity: 278,
	},
	{
		garage_id: 124,
		part_id: 160,
		quantity: 493,
	},
	{
		garage_id: 124,
		part_id: 161,
		quantity: 430,
	},
	{
		garage_id: 124,
		part_id: 162,
		quantity: 446,
	},
	{
		garage_id: 124,
		part_id: 163,
		quantity: 419,
	},
	{
		garage_id: 124,
		part_id: 164,
		quantity: 173,
	},
	{
		garage_id: 124,
		part_id: 165,
		quantity: 297,
	},
	{
		garage_id: 124,
		part_id: 166,
		quantity: 263,
	},
	{
		garage_id: 124,
		part_id: 167,
		quantity: 31,
	},
	{
		garage_id: 124,
		part_id: 168,
		quantity: 328,
	},
	{
		garage_id: 124,
		part_id: 169,
		quantity: 241,
	},
	{
		garage_id: 124,
		part_id: 170,
		quantity: 61,
	},
	{
		garage_id: 124,
		part_id: 171,
		quantity: 66,
	},
	{
		garage_id: 124,
		part_id: 172,
		quantity: 111,
	},
	{
		garage_id: 124,
		part_id: 173,
		quantity: 212,
	},
	{
		garage_id: 124,
		part_id: 174,
		quantity: 14,
	},
	{
		garage_id: 124,
		part_id: 175,
		quantity: 435,
	},
	{
		garage_id: 124,
		part_id: 176,
		quantity: 294,
	},
	{
		garage_id: 124,
		part_id: 177,
		quantity: 268,
	},
	{
		garage_id: 124,
		part_id: 178,
		quantity: 434,
	},
	{
		garage_id: 124,
		part_id: 179,
		quantity: 186,
	},
	{
		garage_id: 124,
		part_id: 180,
		quantity: 437,
	},
	{
		garage_id: 124,
		part_id: 181,
		quantity: 260,
	},
	{
		garage_id: 124,
		part_id: 182,
		quantity: 46,
	},
	{
		garage_id: 124,
		part_id: 183,
		quantity: 125,
	},
	{
		garage_id: 124,
		part_id: 184,
		quantity: 383,
	},
	{
		garage_id: 124,
		part_id: 185,
		quantity: 484,
	},
	{
		garage_id: 124,
		part_id: 186,
		quantity: 124,
	},
	{
		garage_id: 124,
		part_id: 187,
		quantity: 391,
	},
	{
		garage_id: 124,
		part_id: 188,
		quantity: 123,
	},
	{
		garage_id: 124,
		part_id: 189,
		quantity: 364,
	},
	{
		garage_id: 124,
		part_id: 190,
		quantity: 367,
	},
	{
		garage_id: 124,
		part_id: 191,
		quantity: 424,
	},
	{
		garage_id: 124,
		part_id: 192,
		quantity: 135,
	},
	{
		garage_id: 124,
		part_id: 193,
		quantity: 289,
	},
	{
		garage_id: 124,
		part_id: 194,
		quantity: 490,
	},
	{
		garage_id: 124,
		part_id: 195,
		quantity: 353,
	},
	{
		garage_id: 124,
		part_id: 196,
		quantity: 453,
	},
	{
		garage_id: 124,
		part_id: 197,
		quantity: 96,
	},
	{
		garage_id: 124,
		part_id: 198,
		quantity: 90,
	},
	{
		garage_id: 124,
		part_id: 199,
		quantity: 64,
	},
	{
		garage_id: 124,
		part_id: 200,
		quantity: 397,
	},
	{
		garage_id: 124,
		part_id: 201,
		quantity: 315,
	},
	{
		garage_id: 124,
		part_id: 202,
		quantity: 21,
	},
	{
		garage_id: 124,
		part_id: 203,
		quantity: 219,
	},
	{
		garage_id: 124,
		part_id: 204,
		quantity: 9,
	},
	{
		garage_id: 124,
		part_id: 205,
		quantity: 268,
	},
	{
		garage_id: 124,
		part_id: 206,
		quantity: 2,
	},
	{
		garage_id: 124,
		part_id: 207,
		quantity: 310,
	},
	{
		garage_id: 124,
		part_id: 208,
		quantity: 40,
	},
	{
		garage_id: 124,
		part_id: 209,
		quantity: 305,
	},
	{
		garage_id: 124,
		part_id: 210,
		quantity: 209,
	},
	{
		garage_id: 124,
		part_id: 211,
		quantity: 158,
	},
	{
		garage_id: 124,
		part_id: 212,
		quantity: 295,
	},
	{
		garage_id: 124,
		part_id: 213,
		quantity: 184,
	},
	{
		garage_id: 124,
		part_id: 214,
		quantity: 371,
	},
	{
		garage_id: 124,
		part_id: 215,
		quantity: 138,
	},
	{
		garage_id: 124,
		part_id: 216,
		quantity: 212,
	},
	{
		garage_id: 124,
		part_id: 217,
		quantity: 376,
	},
	{
		garage_id: 124,
		part_id: 218,
		quantity: 356,
	},
	{
		garage_id: 124,
		part_id: 219,
		quantity: 127,
	},
	{
		garage_id: 124,
		part_id: 220,
		quantity: 401,
	},
	{
		garage_id: 124,
		part_id: 221,
		quantity: 260,
	},
	{
		garage_id: 124,
		part_id: 222,
		quantity: 87,
	},
	{
		garage_id: 124,
		part_id: 223,
		quantity: 84,
	},
	{
		garage_id: 124,
		part_id: 224,
		quantity: 55,
	},
	{
		garage_id: 124,
		part_id: 225,
		quantity: 329,
	},
	{
		garage_id: 124,
		part_id: 226,
		quantity: 402,
	},
	{
		garage_id: 124,
		part_id: 227,
		quantity: 367,
	},
	{
		garage_id: 124,
		part_id: 228,
		quantity: 408,
	},
	{
		garage_id: 124,
		part_id: 229,
		quantity: 115,
	},
	{
		garage_id: 124,
		part_id: 230,
		quantity: 211,
	},
	{
		garage_id: 124,
		part_id: 231,
		quantity: 42,
	},
	{
		garage_id: 124,
		part_id: 232,
		quantity: 36,
	},
	{
		garage_id: 124,
		part_id: 233,
		quantity: 290,
	},
	{
		garage_id: 124,
		part_id: 234,
		quantity: 386,
	},
	{
		garage_id: 124,
		part_id: 235,
		quantity: 305,
	},
	{
		garage_id: 124,
		part_id: 236,
		quantity: 405,
	},
	{
		garage_id: 124,
		part_id: 237,
		quantity: 455,
	},
	{
		garage_id: 124,
		part_id: 238,
		quantity: 278,
	},
	{
		garage_id: 124,
		part_id: 239,
		quantity: 439,
	},
	{
		garage_id: 124,
		part_id: 240,
		quantity: 432,
	},
	{
		garage_id: 124,
		part_id: 241,
		quantity: 338,
	},
	{
		garage_id: 124,
		part_id: 242,
		quantity: 17,
	},
	{
		garage_id: 124,
		part_id: 243,
		quantity: 27,
	},
	{
		garage_id: 124,
		part_id: 244,
		quantity: 321,
	},
	{
		garage_id: 124,
		part_id: 245,
		quantity: 149,
	},
	{
		garage_id: 124,
		part_id: 246,
		quantity: 464,
	},
	{
		garage_id: 124,
		part_id: 247,
		quantity: 475,
	},
	{
		garage_id: 124,
		part_id: 248,
		quantity: 62,
	},
	{
		garage_id: 124,
		part_id: 249,
		quantity: 384,
	},
	{
		garage_id: 124,
		part_id: 250,
		quantity: 153,
	},
	{
		garage_id: 124,
		part_id: 251,
		quantity: 411,
	},
	{
		garage_id: 124,
		part_id: 252,
		quantity: 319,
	},
	{
		garage_id: 124,
		part_id: 253,
		quantity: 293,
	},
	{
		garage_id: 124,
		part_id: 254,
		quantity: 318,
	},
	{
		garage_id: 124,
		part_id: 255,
		quantity: 406,
	},
	{
		garage_id: 124,
		part_id: 256,
		quantity: 31,
	},
	{
		garage_id: 124,
		part_id: 257,
		quantity: 403,
	},
	{
		garage_id: 124,
		part_id: 258,
		quantity: 246,
	},
	{
		garage_id: 124,
		part_id: 259,
		quantity: 403,
	},
	{
		garage_id: 124,
		part_id: 260,
		quantity: 384,
	},
	{
		garage_id: 124,
		part_id: 261,
		quantity: 243,
	},
	{
		garage_id: 124,
		part_id: 262,
		quantity: 375,
	},
	{
		garage_id: 124,
		part_id: 263,
		quantity: 276,
	},
	{
		garage_id: 124,
		part_id: 264,
		quantity: 194,
	},
	{
		garage_id: 124,
		part_id: 265,
		quantity: 20,
	},
	{
		garage_id: 124,
		part_id: 266,
		quantity: 392,
	},
	{
		garage_id: 124,
		part_id: 267,
		quantity: 97,
	},
	{
		garage_id: 124,
		part_id: 268,
		quantity: 480,
	},
	{
		garage_id: 124,
		part_id: 269,
		quantity: 467,
	},
	{
		garage_id: 124,
		part_id: 270,
		quantity: 295,
	},
	{
		garage_id: 124,
		part_id: 271,
		quantity: 316,
	},
	{
		garage_id: 124,
		part_id: 272,
		quantity: 286,
	},
	{
		garage_id: 124,
		part_id: 273,
		quantity: 98,
	},
	{
		garage_id: 124,
		part_id: 274,
		quantity: 96,
	},
	{
		garage_id: 124,
		part_id: 275,
		quantity: 242,
	},
	{
		garage_id: 124,
		part_id: 276,
		quantity: 500,
	},
	{
		garage_id: 124,
		part_id: 277,
		quantity: 218,
	},
	{
		garage_id: 124,
		part_id: 278,
		quantity: 36,
	},
	{
		garage_id: 124,
		part_id: 279,
		quantity: 123,
	},
	{
		garage_id: 124,
		part_id: 280,
		quantity: 213,
	},
	{
		garage_id: 124,
		part_id: 281,
		quantity: 241,
	},
	{
		garage_id: 124,
		part_id: 282,
		quantity: 311,
	},
	{
		garage_id: 124,
		part_id: 283,
		quantity: 59,
	},
	{
		garage_id: 124,
		part_id: 284,
		quantity: 167,
	},
	{
		garage_id: 124,
		part_id: 285,
		quantity: 10,
	},
	{
		garage_id: 124,
		part_id: 286,
		quantity: 265,
	},
	{
		garage_id: 124,
		part_id: 287,
		quantity: 118,
	},
	{
		garage_id: 124,
		part_id: 288,
		quantity: 388,
	},
	{
		garage_id: 124,
		part_id: 289,
		quantity: 90,
	},
	{
		garage_id: 124,
		part_id: 290,
		quantity: 410,
	},
	{
		garage_id: 124,
		part_id: 291,
		quantity: 483,
	},
	{
		garage_id: 124,
		part_id: 292,
		quantity: 61,
	},
	{
		garage_id: 124,
		part_id: 293,
		quantity: 144,
	},
	{
		garage_id: 124,
		part_id: 294,
		quantity: 105,
	},
	{
		garage_id: 124,
		part_id: 295,
		quantity: 362,
	},
	{
		garage_id: 124,
		part_id: 296,
		quantity: 41,
	},
	{
		garage_id: 124,
		part_id: 297,
		quantity: 359,
	},
	{
		garage_id: 124,
		part_id: 298,
		quantity: 0,
	},
	{
		garage_id: 124,
		part_id: 299,
		quantity: 224,
	},
	{
		garage_id: 124,
		part_id: 300,
		quantity: 169,
	},
	{
		garage_id: 124,
		part_id: 301,
		quantity: 429,
	},
	{
		garage_id: 124,
		part_id: 302,
		quantity: 112,
	},
	{
		garage_id: 124,
		part_id: 303,
		quantity: 381,
	},
	{
		garage_id: 124,
		part_id: 304,
		quantity: 498,
	},
	{
		garage_id: 124,
		part_id: 305,
		quantity: 288,
	},
	{
		garage_id: 124,
		part_id: 306,
		quantity: 367,
	},
	{
		garage_id: 124,
		part_id: 307,
		quantity: 170,
	},
	{
		garage_id: 124,
		part_id: 308,
		quantity: 241,
	},
	{
		garage_id: 124,
		part_id: 309,
		quantity: 380,
	},
	{
		garage_id: 124,
		part_id: 310,
		quantity: 322,
	},
	{
		garage_id: 124,
		part_id: 311,
		quantity: 230,
	},
	{
		garage_id: 124,
		part_id: 312,
		quantity: 140,
	},
	{
		garage_id: 124,
		part_id: 313,
		quantity: 484,
	},
	{
		garage_id: 124,
		part_id: 314,
		quantity: 456,
	},
	{
		garage_id: 124,
		part_id: 315,
		quantity: 466,
	},
	{
		garage_id: 124,
		part_id: 316,
		quantity: 466,
	},
	{
		garage_id: 124,
		part_id: 317,
		quantity: 286,
	},
	{
		garage_id: 124,
		part_id: 318,
		quantity: 226,
	},
	{
		garage_id: 124,
		part_id: 319,
		quantity: 189,
	},
	{
		garage_id: 124,
		part_id: 320,
		quantity: 169,
	},
	{
		garage_id: 124,
		part_id: 321,
		quantity: 340,
	},
	{
		garage_id: 124,
		part_id: 322,
		quantity: 266,
	},
	{
		garage_id: 124,
		part_id: 323,
		quantity: 168,
	},
	{
		garage_id: 124,
		part_id: 324,
		quantity: 263,
	},
	{
		garage_id: 124,
		part_id: 325,
		quantity: 87,
	},
	{
		garage_id: 124,
		part_id: 326,
		quantity: 180,
	},
	{
		garage_id: 124,
		part_id: 327,
		quantity: 370,
	},
	{
		garage_id: 124,
		part_id: 328,
		quantity: 311,
	},
	{
		garage_id: 124,
		part_id: 329,
		quantity: 422,
	},
	{
		garage_id: 124,
		part_id: 330,
		quantity: 263,
	},
	{
		garage_id: 124,
		part_id: 331,
		quantity: 334,
	},
	{
		garage_id: 124,
		part_id: 332,
		quantity: 93,
	},
	{
		garage_id: 124,
		part_id: 333,
		quantity: 109,
	},
	{
		garage_id: 124,
		part_id: 334,
		quantity: 331,
	},
	{
		garage_id: 124,
		part_id: 335,
		quantity: 231,
	},
	{
		garage_id: 124,
		part_id: 336,
		quantity: 230,
	},
	{
		garage_id: 124,
		part_id: 337,
		quantity: 65,
	},
	{
		garage_id: 124,
		part_id: 338,
		quantity: 52,
	},
	{
		garage_id: 124,
		part_id: 339,
		quantity: 142,
	},
	{
		garage_id: 124,
		part_id: 340,
		quantity: 292,
	},
	{
		garage_id: 124,
		part_id: 341,
		quantity: 11,
	},
	{
		garage_id: 124,
		part_id: 342,
		quantity: 77,
	},
	{
		garage_id: 124,
		part_id: 343,
		quantity: 31,
	},
	{
		garage_id: 124,
		part_id: 344,
		quantity: 393,
	},
	{
		garage_id: 124,
		part_id: 345,
		quantity: 470,
	},
	{
		garage_id: 124,
		part_id: 346,
		quantity: 109,
	},
	{
		garage_id: 124,
		part_id: 347,
		quantity: 280,
	},
	{
		garage_id: 124,
		part_id: 348,
		quantity: 74,
	},
	{
		garage_id: 124,
		part_id: 349,
		quantity: 277,
	},
	{
		garage_id: 124,
		part_id: 350,
		quantity: 265,
	},
	{
		garage_id: 124,
		part_id: 351,
		quantity: 307,
	},
	{
		garage_id: 124,
		part_id: 352,
		quantity: 213,
	},
	{
		garage_id: 124,
		part_id: 353,
		quantity: 346,
	},
	{
		garage_id: 124,
		part_id: 354,
		quantity: 43,
	},
	{
		garage_id: 124,
		part_id: 355,
		quantity: 83,
	},
	{
		garage_id: 124,
		part_id: 356,
		quantity: 157,
	},
	{
		garage_id: 124,
		part_id: 357,
		quantity: 67,
	},
	{
		garage_id: 124,
		part_id: 358,
		quantity: 51,
	},
	{
		garage_id: 124,
		part_id: 359,
		quantity: 481,
	},
	{
		garage_id: 124,
		part_id: 360,
		quantity: 477,
	},
	{
		garage_id: 124,
		part_id: 361,
		quantity: 213,
	},
	{
		garage_id: 124,
		part_id: 362,
		quantity: 395,
	},
	{
		garage_id: 124,
		part_id: 363,
		quantity: 373,
	},
	{
		garage_id: 124,
		part_id: 364,
		quantity: 171,
	},
	{
		garage_id: 124,
		part_id: 365,
		quantity: 438,
	},
	{
		garage_id: 124,
		part_id: 366,
		quantity: 184,
	},
	{
		garage_id: 124,
		part_id: 367,
		quantity: 34,
	},
	{
		garage_id: 124,
		part_id: 368,
		quantity: 58,
	},
	{
		garage_id: 124,
		part_id: 369,
		quantity: 214,
	},
	{
		garage_id: 124,
		part_id: 370,
		quantity: 442,
	},
	{
		garage_id: 124,
		part_id: 371,
		quantity: 446,
	},
	{
		garage_id: 124,
		part_id: 372,
		quantity: 379,
	},
	{
		garage_id: 124,
		part_id: 373,
		quantity: 87,
	},
	{
		garage_id: 124,
		part_id: 374,
		quantity: 394,
	},
	{
		garage_id: 124,
		part_id: 375,
		quantity: 275,
	},
	{
		garage_id: 124,
		part_id: 376,
		quantity: 21,
	},
	{
		garage_id: 124,
		part_id: 377,
		quantity: 375,
	},
	{
		garage_id: 124,
		part_id: 378,
		quantity: 481,
	},
	{
		garage_id: 124,
		part_id: 379,
		quantity: 23,
	},
	{
		garage_id: 124,
		part_id: 380,
		quantity: 294,
	},
	{
		garage_id: 124,
		part_id: 381,
		quantity: 330,
	},
	{
		garage_id: 124,
		part_id: 382,
		quantity: 165,
	},
	{
		garage_id: 124,
		part_id: 383,
		quantity: 475,
	},
	{
		garage_id: 124,
		part_id: 384,
		quantity: 198,
	},
	{
		garage_id: 124,
		part_id: 385,
		quantity: 245,
	},
	{
		garage_id: 124,
		part_id: 386,
		quantity: 159,
	},
	{
		garage_id: 124,
		part_id: 387,
		quantity: 24,
	},
	{
		garage_id: 124,
		part_id: 388,
		quantity: 337,
	},
	{
		garage_id: 124,
		part_id: 389,
		quantity: 156,
	},
	{
		garage_id: 124,
		part_id: 390,
		quantity: 76,
	},
	{
		garage_id: 124,
		part_id: 391,
		quantity: 147,
	},
	{
		garage_id: 124,
		part_id: 392,
		quantity: 453,
	},
	{
		garage_id: 124,
		part_id: 393,
		quantity: 144,
	},
	{
		garage_id: 124,
		part_id: 394,
		quantity: 334,
	},
	{
		garage_id: 124,
		part_id: 395,
		quantity: 96,
	},
	{
		garage_id: 124,
		part_id: 396,
		quantity: 50,
	},
	{
		garage_id: 124,
		part_id: 397,
		quantity: 417,
	},
	{
		garage_id: 124,
		part_id: 398,
		quantity: 471,
	},
	{
		garage_id: 124,
		part_id: 399,
		quantity: 401,
	},
	{
		garage_id: 125,
		part_id: 0,
		quantity: 125,
	},
	{
		garage_id: 125,
		part_id: 1,
		quantity: 474,
	},
	{
		garage_id: 125,
		part_id: 2,
		quantity: 144,
	},
	{
		garage_id: 125,
		part_id: 3,
		quantity: 232,
	},
	{
		garage_id: 125,
		part_id: 4,
		quantity: 300,
	},
	{
		garage_id: 125,
		part_id: 5,
		quantity: 406,
	},
	{
		garage_id: 125,
		part_id: 6,
		quantity: 187,
	},
	{
		garage_id: 125,
		part_id: 7,
		quantity: 328,
	},
	{
		garage_id: 125,
		part_id: 8,
		quantity: 174,
	},
	{
		garage_id: 125,
		part_id: 9,
		quantity: 52,
	},
	{
		garage_id: 125,
		part_id: 10,
		quantity: 110,
	},
	{
		garage_id: 125,
		part_id: 11,
		quantity: 329,
	},
	{
		garage_id: 125,
		part_id: 12,
		quantity: 47,
	},
	{
		garage_id: 125,
		part_id: 13,
		quantity: 455,
	},
	{
		garage_id: 125,
		part_id: 14,
		quantity: 87,
	},
	{
		garage_id: 125,
		part_id: 15,
		quantity: 63,
	},
	{
		garage_id: 125,
		part_id: 16,
		quantity: 14,
	},
	{
		garage_id: 125,
		part_id: 17,
		quantity: 462,
	},
	{
		garage_id: 125,
		part_id: 18,
		quantity: 30,
	},
	{
		garage_id: 125,
		part_id: 19,
		quantity: 12,
	},
	{
		garage_id: 125,
		part_id: 20,
		quantity: 438,
	},
	{
		garage_id: 125,
		part_id: 21,
		quantity: 260,
	},
	{
		garage_id: 125,
		part_id: 22,
		quantity: 82,
	},
	{
		garage_id: 125,
		part_id: 23,
		quantity: 5,
	},
	{
		garage_id: 125,
		part_id: 24,
		quantity: 252,
	},
	{
		garage_id: 125,
		part_id: 25,
		quantity: 141,
	},
	{
		garage_id: 125,
		part_id: 26,
		quantity: 325,
	},
	{
		garage_id: 125,
		part_id: 27,
		quantity: 399,
	},
	{
		garage_id: 125,
		part_id: 28,
		quantity: 381,
	},
	{
		garage_id: 125,
		part_id: 29,
		quantity: 332,
	},
	{
		garage_id: 125,
		part_id: 30,
		quantity: 152,
	},
	{
		garage_id: 125,
		part_id: 31,
		quantity: 17,
	},
	{
		garage_id: 125,
		part_id: 32,
		quantity: 230,
	},
	{
		garage_id: 125,
		part_id: 33,
		quantity: 484,
	},
	{
		garage_id: 125,
		part_id: 34,
		quantity: 241,
	},
	{
		garage_id: 125,
		part_id: 35,
		quantity: 374,
	},
	{
		garage_id: 125,
		part_id: 36,
		quantity: 200,
	},
	{
		garage_id: 125,
		part_id: 37,
		quantity: 463,
	},
	{
		garage_id: 125,
		part_id: 38,
		quantity: 67,
	},
	{
		garage_id: 125,
		part_id: 39,
		quantity: 63,
	},
	{
		garage_id: 125,
		part_id: 40,
		quantity: 430,
	},
	{
		garage_id: 125,
		part_id: 41,
		quantity: 226,
	},
	{
		garage_id: 125,
		part_id: 42,
		quantity: 444,
	},
	{
		garage_id: 125,
		part_id: 43,
		quantity: 207,
	},
	{
		garage_id: 125,
		part_id: 44,
		quantity: 190,
	},
	{
		garage_id: 125,
		part_id: 45,
		quantity: 56,
	},
	{
		garage_id: 125,
		part_id: 46,
		quantity: 251,
	},
	{
		garage_id: 125,
		part_id: 47,
		quantity: 149,
	},
	{
		garage_id: 125,
		part_id: 48,
		quantity: 238,
	},
	{
		garage_id: 125,
		part_id: 49,
		quantity: 258,
	},
	{
		garage_id: 125,
		part_id: 50,
		quantity: 328,
	},
	{
		garage_id: 125,
		part_id: 51,
		quantity: 308,
	},
	{
		garage_id: 125,
		part_id: 52,
		quantity: 147,
	},
	{
		garage_id: 125,
		part_id: 53,
		quantity: 241,
	},
	{
		garage_id: 125,
		part_id: 54,
		quantity: 189,
	},
	{
		garage_id: 125,
		part_id: 55,
		quantity: 46,
	},
	{
		garage_id: 125,
		part_id: 56,
		quantity: 353,
	},
	{
		garage_id: 125,
		part_id: 57,
		quantity: 297,
	},
	{
		garage_id: 125,
		part_id: 58,
		quantity: 226,
	},
	{
		garage_id: 125,
		part_id: 59,
		quantity: 474,
	},
	{
		garage_id: 125,
		part_id: 60,
		quantity: 438,
	},
	{
		garage_id: 125,
		part_id: 61,
		quantity: 244,
	},
	{
		garage_id: 125,
		part_id: 62,
		quantity: 122,
	},
	{
		garage_id: 125,
		part_id: 63,
		quantity: 177,
	},
	{
		garage_id: 125,
		part_id: 64,
		quantity: 83,
	},
	{
		garage_id: 125,
		part_id: 65,
		quantity: 333,
	},
	{
		garage_id: 125,
		part_id: 66,
		quantity: 478,
	},
	{
		garage_id: 125,
		part_id: 67,
		quantity: 78,
	},
	{
		garage_id: 125,
		part_id: 68,
		quantity: 171,
	},
	{
		garage_id: 125,
		part_id: 69,
		quantity: 73,
	},
	{
		garage_id: 125,
		part_id: 70,
		quantity: 370,
	},
	{
		garage_id: 125,
		part_id: 71,
		quantity: 274,
	},
	{
		garage_id: 125,
		part_id: 72,
		quantity: 300,
	},
	{
		garage_id: 125,
		part_id: 73,
		quantity: 58,
	},
	{
		garage_id: 125,
		part_id: 74,
		quantity: 124,
	},
	{
		garage_id: 125,
		part_id: 75,
		quantity: 174,
	},
	{
		garage_id: 125,
		part_id: 76,
		quantity: 37,
	},
	{
		garage_id: 125,
		part_id: 77,
		quantity: 456,
	},
	{
		garage_id: 125,
		part_id: 78,
		quantity: 401,
	},
	{
		garage_id: 125,
		part_id: 79,
		quantity: 96,
	},
	{
		garage_id: 125,
		part_id: 80,
		quantity: 108,
	},
	{
		garage_id: 125,
		part_id: 81,
		quantity: 321,
	},
	{
		garage_id: 125,
		part_id: 82,
		quantity: 31,
	},
	{
		garage_id: 125,
		part_id: 83,
		quantity: 10,
	},
	{
		garage_id: 125,
		part_id: 84,
		quantity: 183,
	},
	{
		garage_id: 125,
		part_id: 85,
		quantity: 169,
	},
	{
		garage_id: 125,
		part_id: 86,
		quantity: 413,
	},
	{
		garage_id: 125,
		part_id: 87,
		quantity: 37,
	},
	{
		garage_id: 125,
		part_id: 88,
		quantity: 369,
	},
	{
		garage_id: 125,
		part_id: 89,
		quantity: 200,
	},
	{
		garage_id: 125,
		part_id: 90,
		quantity: 462,
	},
	{
		garage_id: 125,
		part_id: 91,
		quantity: 136,
	},
	{
		garage_id: 125,
		part_id: 92,
		quantity: 62,
	},
	{
		garage_id: 125,
		part_id: 93,
		quantity: 471,
	},
	{
		garage_id: 125,
		part_id: 94,
		quantity: 71,
	},
	{
		garage_id: 125,
		part_id: 95,
		quantity: 226,
	},
	{
		garage_id: 125,
		part_id: 96,
		quantity: 125,
	},
	{
		garage_id: 125,
		part_id: 97,
		quantity: 99,
	},
	{
		garage_id: 125,
		part_id: 98,
		quantity: 389,
	},
	{
		garage_id: 125,
		part_id: 99,
		quantity: 107,
	},
	{
		garage_id: 125,
		part_id: 100,
		quantity: 142,
	},
	{
		garage_id: 125,
		part_id: 101,
		quantity: 194,
	},
	{
		garage_id: 125,
		part_id: 102,
		quantity: 276,
	},
	{
		garage_id: 125,
		part_id: 103,
		quantity: 279,
	},
	{
		garage_id: 125,
		part_id: 104,
		quantity: 434,
	},
	{
		garage_id: 125,
		part_id: 105,
		quantity: 9,
	},
	{
		garage_id: 125,
		part_id: 106,
		quantity: 455,
	},
	{
		garage_id: 125,
		part_id: 107,
		quantity: 457,
	},
	{
		garage_id: 125,
		part_id: 108,
		quantity: 106,
	},
	{
		garage_id: 125,
		part_id: 109,
		quantity: 337,
	},
	{
		garage_id: 125,
		part_id: 110,
		quantity: 196,
	},
	{
		garage_id: 125,
		part_id: 111,
		quantity: 315,
	},
	{
		garage_id: 125,
		part_id: 112,
		quantity: 308,
	},
	{
		garage_id: 125,
		part_id: 113,
		quantity: 83,
	},
	{
		garage_id: 125,
		part_id: 114,
		quantity: 292,
	},
	{
		garage_id: 125,
		part_id: 115,
		quantity: 422,
	},
	{
		garage_id: 125,
		part_id: 116,
		quantity: 87,
	},
	{
		garage_id: 125,
		part_id: 117,
		quantity: 299,
	},
	{
		garage_id: 125,
		part_id: 118,
		quantity: 14,
	},
	{
		garage_id: 125,
		part_id: 119,
		quantity: 202,
	},
	{
		garage_id: 125,
		part_id: 120,
		quantity: 189,
	},
	{
		garage_id: 125,
		part_id: 121,
		quantity: 160,
	},
	{
		garage_id: 125,
		part_id: 122,
		quantity: 231,
	},
	{
		garage_id: 125,
		part_id: 123,
		quantity: 493,
	},
	{
		garage_id: 125,
		part_id: 124,
		quantity: 322,
	},
	{
		garage_id: 125,
		part_id: 125,
		quantity: 327,
	},
	{
		garage_id: 125,
		part_id: 126,
		quantity: 324,
	},
	{
		garage_id: 125,
		part_id: 127,
		quantity: 39,
	},
	{
		garage_id: 125,
		part_id: 128,
		quantity: 1,
	},
	{
		garage_id: 125,
		part_id: 129,
		quantity: 394,
	},
	{
		garage_id: 125,
		part_id: 130,
		quantity: 243,
	},
	{
		garage_id: 125,
		part_id: 131,
		quantity: 380,
	},
	{
		garage_id: 125,
		part_id: 132,
		quantity: 471,
	},
	{
		garage_id: 125,
		part_id: 133,
		quantity: 221,
	},
	{
		garage_id: 125,
		part_id: 134,
		quantity: 439,
	},
	{
		garage_id: 125,
		part_id: 135,
		quantity: 304,
	},
	{
		garage_id: 125,
		part_id: 136,
		quantity: 418,
	},
	{
		garage_id: 125,
		part_id: 137,
		quantity: 323,
	},
	{
		garage_id: 125,
		part_id: 138,
		quantity: 38,
	},
	{
		garage_id: 125,
		part_id: 139,
		quantity: 464,
	},
	{
		garage_id: 125,
		part_id: 140,
		quantity: 177,
	},
	{
		garage_id: 125,
		part_id: 141,
		quantity: 149,
	},
	{
		garage_id: 125,
		part_id: 142,
		quantity: 291,
	},
	{
		garage_id: 125,
		part_id: 143,
		quantity: 110,
	},
	{
		garage_id: 125,
		part_id: 144,
		quantity: 480,
	},
	{
		garage_id: 125,
		part_id: 145,
		quantity: 482,
	},
	{
		garage_id: 125,
		part_id: 146,
		quantity: 453,
	},
	{
		garage_id: 125,
		part_id: 147,
		quantity: 42,
	},
	{
		garage_id: 125,
		part_id: 148,
		quantity: 458,
	},
	{
		garage_id: 125,
		part_id: 149,
		quantity: 247,
	},
	{
		garage_id: 125,
		part_id: 150,
		quantity: 144,
	},
	{
		garage_id: 125,
		part_id: 151,
		quantity: 334,
	},
	{
		garage_id: 125,
		part_id: 152,
		quantity: 284,
	},
	{
		garage_id: 125,
		part_id: 153,
		quantity: 380,
	},
	{
		garage_id: 125,
		part_id: 154,
		quantity: 312,
	},
	{
		garage_id: 125,
		part_id: 155,
		quantity: 201,
	},
	{
		garage_id: 125,
		part_id: 156,
		quantity: 268,
	},
	{
		garage_id: 125,
		part_id: 157,
		quantity: 324,
	},
	{
		garage_id: 125,
		part_id: 158,
		quantity: 147,
	},
	{
		garage_id: 125,
		part_id: 159,
		quantity: 30,
	},
	{
		garage_id: 125,
		part_id: 160,
		quantity: 320,
	},
	{
		garage_id: 125,
		part_id: 161,
		quantity: 165,
	},
	{
		garage_id: 125,
		part_id: 162,
		quantity: 82,
	},
	{
		garage_id: 125,
		part_id: 163,
		quantity: 485,
	},
	{
		garage_id: 125,
		part_id: 164,
		quantity: 221,
	},
	{
		garage_id: 125,
		part_id: 165,
		quantity: 133,
	},
	{
		garage_id: 125,
		part_id: 166,
		quantity: 29,
	},
	{
		garage_id: 125,
		part_id: 167,
		quantity: 17,
	},
	{
		garage_id: 125,
		part_id: 168,
		quantity: 164,
	},
	{
		garage_id: 125,
		part_id: 169,
		quantity: 402,
	},
	{
		garage_id: 125,
		part_id: 170,
		quantity: 378,
	},
	{
		garage_id: 125,
		part_id: 171,
		quantity: 180,
	},
	{
		garage_id: 125,
		part_id: 172,
		quantity: 460,
	},
	{
		garage_id: 125,
		part_id: 173,
		quantity: 85,
	},
	{
		garage_id: 125,
		part_id: 174,
		quantity: 308,
	},
	{
		garage_id: 125,
		part_id: 175,
		quantity: 177,
	},
	{
		garage_id: 125,
		part_id: 176,
		quantity: 431,
	},
	{
		garage_id: 125,
		part_id: 177,
		quantity: 31,
	},
	{
		garage_id: 125,
		part_id: 178,
		quantity: 349,
	},
	{
		garage_id: 125,
		part_id: 179,
		quantity: 106,
	},
	{
		garage_id: 125,
		part_id: 180,
		quantity: 475,
	},
	{
		garage_id: 125,
		part_id: 181,
		quantity: 34,
	},
	{
		garage_id: 125,
		part_id: 182,
		quantity: 25,
	},
	{
		garage_id: 125,
		part_id: 183,
		quantity: 300,
	},
	{
		garage_id: 125,
		part_id: 184,
		quantity: 220,
	},
	{
		garage_id: 125,
		part_id: 185,
		quantity: 160,
	},
	{
		garage_id: 125,
		part_id: 186,
		quantity: 462,
	},
	{
		garage_id: 125,
		part_id: 187,
		quantity: 37,
	},
	{
		garage_id: 125,
		part_id: 188,
		quantity: 356,
	},
	{
		garage_id: 125,
		part_id: 189,
		quantity: 246,
	},
	{
		garage_id: 125,
		part_id: 190,
		quantity: 66,
	},
	{
		garage_id: 125,
		part_id: 191,
		quantity: 166,
	},
	{
		garage_id: 125,
		part_id: 192,
		quantity: 348,
	},
	{
		garage_id: 125,
		part_id: 193,
		quantity: 232,
	},
	{
		garage_id: 125,
		part_id: 194,
		quantity: 72,
	},
	{
		garage_id: 125,
		part_id: 195,
		quantity: 284,
	},
	{
		garage_id: 125,
		part_id: 196,
		quantity: 109,
	},
	{
		garage_id: 125,
		part_id: 197,
		quantity: 364,
	},
	{
		garage_id: 125,
		part_id: 198,
		quantity: 217,
	},
	{
		garage_id: 125,
		part_id: 199,
		quantity: 351,
	},
	{
		garage_id: 125,
		part_id: 200,
		quantity: 297,
	},
	{
		garage_id: 125,
		part_id: 201,
		quantity: 137,
	},
	{
		garage_id: 125,
		part_id: 202,
		quantity: 303,
	},
	{
		garage_id: 125,
		part_id: 203,
		quantity: 251,
	},
	{
		garage_id: 125,
		part_id: 204,
		quantity: 336,
	},
	{
		garage_id: 125,
		part_id: 205,
		quantity: 476,
	},
	{
		garage_id: 125,
		part_id: 206,
		quantity: 208,
	},
	{
		garage_id: 125,
		part_id: 207,
		quantity: 464,
	},
	{
		garage_id: 125,
		part_id: 208,
		quantity: 417,
	},
	{
		garage_id: 125,
		part_id: 209,
		quantity: 174,
	},
	{
		garage_id: 125,
		part_id: 210,
		quantity: 500,
	},
	{
		garage_id: 125,
		part_id: 211,
		quantity: 425,
	},
	{
		garage_id: 125,
		part_id: 212,
		quantity: 88,
	},
	{
		garage_id: 125,
		part_id: 213,
		quantity: 470,
	},
	{
		garage_id: 125,
		part_id: 214,
		quantity: 152,
	},
	{
		garage_id: 125,
		part_id: 215,
		quantity: 343,
	},
	{
		garage_id: 125,
		part_id: 216,
		quantity: 362,
	},
	{
		garage_id: 125,
		part_id: 217,
		quantity: 372,
	},
	{
		garage_id: 125,
		part_id: 218,
		quantity: 5,
	},
	{
		garage_id: 125,
		part_id: 219,
		quantity: 495,
	},
	{
		garage_id: 125,
		part_id: 220,
		quantity: 118,
	},
	{
		garage_id: 125,
		part_id: 221,
		quantity: 140,
	},
	{
		garage_id: 125,
		part_id: 222,
		quantity: 410,
	},
	{
		garage_id: 125,
		part_id: 223,
		quantity: 348,
	},
	{
		garage_id: 125,
		part_id: 224,
		quantity: 248,
	},
	{
		garage_id: 125,
		part_id: 225,
		quantity: 111,
	},
	{
		garage_id: 125,
		part_id: 226,
		quantity: 243,
	},
	{
		garage_id: 125,
		part_id: 227,
		quantity: 161,
	},
	{
		garage_id: 125,
		part_id: 228,
		quantity: 274,
	},
	{
		garage_id: 125,
		part_id: 229,
		quantity: 118,
	},
	{
		garage_id: 125,
		part_id: 230,
		quantity: 483,
	},
	{
		garage_id: 125,
		part_id: 231,
		quantity: 204,
	},
	{
		garage_id: 125,
		part_id: 232,
		quantity: 256,
	},
	{
		garage_id: 125,
		part_id: 233,
		quantity: 120,
	},
	{
		garage_id: 125,
		part_id: 234,
		quantity: 324,
	},
	{
		garage_id: 125,
		part_id: 235,
		quantity: 240,
	},
	{
		garage_id: 125,
		part_id: 236,
		quantity: 79,
	},
	{
		garage_id: 125,
		part_id: 237,
		quantity: 491,
	},
	{
		garage_id: 125,
		part_id: 238,
		quantity: 1,
	},
	{
		garage_id: 125,
		part_id: 239,
		quantity: 420,
	},
	{
		garage_id: 125,
		part_id: 240,
		quantity: 184,
	},
	{
		garage_id: 125,
		part_id: 241,
		quantity: 64,
	},
	{
		garage_id: 125,
		part_id: 242,
		quantity: 186,
	},
	{
		garage_id: 125,
		part_id: 243,
		quantity: 61,
	},
	{
		garage_id: 125,
		part_id: 244,
		quantity: 33,
	},
	{
		garage_id: 125,
		part_id: 245,
		quantity: 257,
	},
	{
		garage_id: 125,
		part_id: 246,
		quantity: 75,
	},
	{
		garage_id: 125,
		part_id: 247,
		quantity: 388,
	},
	{
		garage_id: 125,
		part_id: 248,
		quantity: 270,
	},
	{
		garage_id: 125,
		part_id: 249,
		quantity: 349,
	},
	{
		garage_id: 125,
		part_id: 250,
		quantity: 358,
	},
	{
		garage_id: 125,
		part_id: 251,
		quantity: 394,
	},
	{
		garage_id: 125,
		part_id: 252,
		quantity: 495,
	},
	{
		garage_id: 125,
		part_id: 253,
		quantity: 18,
	},
	{
		garage_id: 125,
		part_id: 254,
		quantity: 479,
	},
	{
		garage_id: 125,
		part_id: 255,
		quantity: 321,
	},
	{
		garage_id: 125,
		part_id: 256,
		quantity: 468,
	},
	{
		garage_id: 125,
		part_id: 257,
		quantity: 290,
	},
	{
		garage_id: 125,
		part_id: 258,
		quantity: 42,
	},
	{
		garage_id: 125,
		part_id: 259,
		quantity: 5,
	},
	{
		garage_id: 125,
		part_id: 260,
		quantity: 163,
	},
	{
		garage_id: 125,
		part_id: 261,
		quantity: 234,
	},
	{
		garage_id: 125,
		part_id: 262,
		quantity: 79,
	},
	{
		garage_id: 125,
		part_id: 263,
		quantity: 330,
	},
	{
		garage_id: 125,
		part_id: 264,
		quantity: 152,
	},
	{
		garage_id: 125,
		part_id: 265,
		quantity: 478,
	},
	{
		garage_id: 125,
		part_id: 266,
		quantity: 431,
	},
	{
		garage_id: 125,
		part_id: 267,
		quantity: 37,
	},
	{
		garage_id: 125,
		part_id: 268,
		quantity: 273,
	},
	{
		garage_id: 125,
		part_id: 269,
		quantity: 227,
	},
	{
		garage_id: 125,
		part_id: 270,
		quantity: 239,
	},
	{
		garage_id: 125,
		part_id: 271,
		quantity: 346,
	},
	{
		garage_id: 125,
		part_id: 272,
		quantity: 313,
	},
	{
		garage_id: 125,
		part_id: 273,
		quantity: 157,
	},
	{
		garage_id: 125,
		part_id: 274,
		quantity: 213,
	},
	{
		garage_id: 125,
		part_id: 275,
		quantity: 130,
	},
	{
		garage_id: 125,
		part_id: 276,
		quantity: 397,
	},
	{
		garage_id: 125,
		part_id: 277,
		quantity: 359,
	},
	{
		garage_id: 125,
		part_id: 278,
		quantity: 379,
	},
	{
		garage_id: 125,
		part_id: 279,
		quantity: 55,
	},
	{
		garage_id: 125,
		part_id: 280,
		quantity: 58,
	},
	{
		garage_id: 125,
		part_id: 281,
		quantity: 309,
	},
	{
		garage_id: 125,
		part_id: 282,
		quantity: 245,
	},
	{
		garage_id: 125,
		part_id: 283,
		quantity: 254,
	},
	{
		garage_id: 125,
		part_id: 284,
		quantity: 45,
	},
	{
		garage_id: 125,
		part_id: 285,
		quantity: 117,
	},
	{
		garage_id: 125,
		part_id: 286,
		quantity: 153,
	},
	{
		garage_id: 125,
		part_id: 287,
		quantity: 153,
	},
	{
		garage_id: 125,
		part_id: 288,
		quantity: 413,
	},
	{
		garage_id: 125,
		part_id: 289,
		quantity: 390,
	},
	{
		garage_id: 125,
		part_id: 290,
		quantity: 434,
	},
	{
		garage_id: 125,
		part_id: 291,
		quantity: 283,
	},
	{
		garage_id: 125,
		part_id: 292,
		quantity: 372,
	},
	{
		garage_id: 125,
		part_id: 293,
		quantity: 108,
	},
	{
		garage_id: 125,
		part_id: 294,
		quantity: 182,
	},
	{
		garage_id: 125,
		part_id: 295,
		quantity: 56,
	},
	{
		garage_id: 125,
		part_id: 296,
		quantity: 493,
	},
	{
		garage_id: 125,
		part_id: 297,
		quantity: 372,
	},
	{
		garage_id: 125,
		part_id: 298,
		quantity: 279,
	},
	{
		garage_id: 125,
		part_id: 299,
		quantity: 367,
	},
	{
		garage_id: 125,
		part_id: 300,
		quantity: 116,
	},
	{
		garage_id: 125,
		part_id: 301,
		quantity: 142,
	},
	{
		garage_id: 125,
		part_id: 302,
		quantity: 400,
	},
	{
		garage_id: 125,
		part_id: 303,
		quantity: 475,
	},
	{
		garage_id: 125,
		part_id: 304,
		quantity: 246,
	},
	{
		garage_id: 125,
		part_id: 305,
		quantity: 64,
	},
	{
		garage_id: 125,
		part_id: 306,
		quantity: 14,
	},
	{
		garage_id: 125,
		part_id: 307,
		quantity: 444,
	},
	{
		garage_id: 125,
		part_id: 308,
		quantity: 319,
	},
	{
		garage_id: 125,
		part_id: 309,
		quantity: 0,
	},
	{
		garage_id: 125,
		part_id: 310,
		quantity: 19,
	},
	{
		garage_id: 125,
		part_id: 311,
		quantity: 347,
	},
	{
		garage_id: 125,
		part_id: 312,
		quantity: 80,
	},
	{
		garage_id: 125,
		part_id: 313,
		quantity: 282,
	},
	{
		garage_id: 125,
		part_id: 314,
		quantity: 94,
	},
	{
		garage_id: 125,
		part_id: 315,
		quantity: 80,
	},
	{
		garage_id: 125,
		part_id: 316,
		quantity: 149,
	},
	{
		garage_id: 125,
		part_id: 317,
		quantity: 11,
	},
	{
		garage_id: 125,
		part_id: 318,
		quantity: 185,
	},
	{
		garage_id: 125,
		part_id: 319,
		quantity: 49,
	},
	{
		garage_id: 125,
		part_id: 320,
		quantity: 215,
	},
	{
		garage_id: 125,
		part_id: 321,
		quantity: 136,
	},
	{
		garage_id: 125,
		part_id: 322,
		quantity: 428,
	},
	{
		garage_id: 125,
		part_id: 323,
		quantity: 488,
	},
	{
		garage_id: 125,
		part_id: 324,
		quantity: 462,
	},
	{
		garage_id: 125,
		part_id: 325,
		quantity: 301,
	},
	{
		garage_id: 125,
		part_id: 326,
		quantity: 233,
	},
	{
		garage_id: 125,
		part_id: 327,
		quantity: 344,
	},
	{
		garage_id: 125,
		part_id: 328,
		quantity: 385,
	},
	{
		garage_id: 125,
		part_id: 329,
		quantity: 396,
	},
	{
		garage_id: 125,
		part_id: 330,
		quantity: 192,
	},
	{
		garage_id: 125,
		part_id: 331,
		quantity: 443,
	},
	{
		garage_id: 125,
		part_id: 332,
		quantity: 145,
	},
	{
		garage_id: 125,
		part_id: 333,
		quantity: 88,
	},
	{
		garage_id: 125,
		part_id: 334,
		quantity: 350,
	},
	{
		garage_id: 125,
		part_id: 335,
		quantity: 483,
	},
	{
		garage_id: 125,
		part_id: 336,
		quantity: 27,
	},
	{
		garage_id: 125,
		part_id: 337,
		quantity: 125,
	},
	{
		garage_id: 125,
		part_id: 338,
		quantity: 240,
	},
	{
		garage_id: 125,
		part_id: 339,
		quantity: 82,
	},
	{
		garage_id: 125,
		part_id: 340,
		quantity: 461,
	},
	{
		garage_id: 125,
		part_id: 341,
		quantity: 87,
	},
	{
		garage_id: 125,
		part_id: 342,
		quantity: 182,
	},
	{
		garage_id: 125,
		part_id: 343,
		quantity: 155,
	},
	{
		garage_id: 125,
		part_id: 344,
		quantity: 6,
	},
	{
		garage_id: 125,
		part_id: 345,
		quantity: 252,
	},
	{
		garage_id: 125,
		part_id: 346,
		quantity: 290,
	},
	{
		garage_id: 125,
		part_id: 347,
		quantity: 167,
	},
	{
		garage_id: 125,
		part_id: 348,
		quantity: 161,
	},
	{
		garage_id: 125,
		part_id: 349,
		quantity: 324,
	},
	{
		garage_id: 125,
		part_id: 350,
		quantity: 254,
	},
	{
		garage_id: 125,
		part_id: 351,
		quantity: 367,
	},
	{
		garage_id: 125,
		part_id: 352,
		quantity: 57,
	},
	{
		garage_id: 125,
		part_id: 353,
		quantity: 226,
	},
	{
		garage_id: 125,
		part_id: 354,
		quantity: 183,
	},
	{
		garage_id: 125,
		part_id: 355,
		quantity: 448,
	},
	{
		garage_id: 125,
		part_id: 356,
		quantity: 208,
	},
	{
		garage_id: 125,
		part_id: 357,
		quantity: 273,
	},
	{
		garage_id: 125,
		part_id: 358,
		quantity: 229,
	},
	{
		garage_id: 125,
		part_id: 359,
		quantity: 267,
	},
	{
		garage_id: 125,
		part_id: 360,
		quantity: 327,
	},
	{
		garage_id: 125,
		part_id: 361,
		quantity: 229,
	},
	{
		garage_id: 125,
		part_id: 362,
		quantity: 402,
	},
	{
		garage_id: 125,
		part_id: 363,
		quantity: 448,
	},
	{
		garage_id: 125,
		part_id: 364,
		quantity: 433,
	},
	{
		garage_id: 125,
		part_id: 365,
		quantity: 250,
	},
	{
		garage_id: 125,
		part_id: 366,
		quantity: 293,
	},
	{
		garage_id: 125,
		part_id: 367,
		quantity: 310,
	},
	{
		garage_id: 125,
		part_id: 368,
		quantity: 35,
	},
	{
		garage_id: 125,
		part_id: 369,
		quantity: 115,
	},
	{
		garage_id: 125,
		part_id: 370,
		quantity: 312,
	},
	{
		garage_id: 125,
		part_id: 371,
		quantity: 143,
	},
	{
		garage_id: 125,
		part_id: 372,
		quantity: 49,
	},
	{
		garage_id: 125,
		part_id: 373,
		quantity: 60,
	},
	{
		garage_id: 125,
		part_id: 374,
		quantity: 415,
	},
	{
		garage_id: 125,
		part_id: 375,
		quantity: 166,
	},
	{
		garage_id: 125,
		part_id: 376,
		quantity: 409,
	},
	{
		garage_id: 125,
		part_id: 377,
		quantity: 279,
	},
	{
		garage_id: 125,
		part_id: 378,
		quantity: 467,
	},
	{
		garage_id: 125,
		part_id: 379,
		quantity: 334,
	},
	{
		garage_id: 125,
		part_id: 380,
		quantity: 71,
	},
	{
		garage_id: 125,
		part_id: 381,
		quantity: 151,
	},
	{
		garage_id: 125,
		part_id: 382,
		quantity: 69,
	},
	{
		garage_id: 125,
		part_id: 383,
		quantity: 477,
	},
	{
		garage_id: 125,
		part_id: 384,
		quantity: 361,
	},
	{
		garage_id: 125,
		part_id: 385,
		quantity: 53,
	},
	{
		garage_id: 125,
		part_id: 386,
		quantity: 80,
	},
	{
		garage_id: 125,
		part_id: 387,
		quantity: 79,
	},
	{
		garage_id: 125,
		part_id: 388,
		quantity: 313,
	},
	{
		garage_id: 125,
		part_id: 389,
		quantity: 443,
	},
	{
		garage_id: 125,
		part_id: 390,
		quantity: 242,
	},
	{
		garage_id: 125,
		part_id: 391,
		quantity: 164,
	},
	{
		garage_id: 125,
		part_id: 392,
		quantity: 15,
	},
	{
		garage_id: 125,
		part_id: 393,
		quantity: 494,
	},
	{
		garage_id: 125,
		part_id: 394,
		quantity: 170,
	},
	{
		garage_id: 125,
		part_id: 395,
		quantity: 311,
	},
	{
		garage_id: 125,
		part_id: 396,
		quantity: 303,
	},
	{
		garage_id: 125,
		part_id: 397,
		quantity: 62,
	},
	{
		garage_id: 125,
		part_id: 398,
		quantity: 107,
	},
	{
		garage_id: 125,
		part_id: 399,
		quantity: 425,
	},
	{
		garage_id: 126,
		part_id: 0,
		quantity: 34,
	},
	{
		garage_id: 126,
		part_id: 1,
		quantity: 438,
	},
	{
		garage_id: 126,
		part_id: 2,
		quantity: 186,
	},
	{
		garage_id: 126,
		part_id: 3,
		quantity: 114,
	},
	{
		garage_id: 126,
		part_id: 4,
		quantity: 310,
	},
	{
		garage_id: 126,
		part_id: 5,
		quantity: 224,
	},
	{
		garage_id: 126,
		part_id: 6,
		quantity: 314,
	},
	{
		garage_id: 126,
		part_id: 7,
		quantity: 350,
	},
	{
		garage_id: 126,
		part_id: 8,
		quantity: 86,
	},
	{
		garage_id: 126,
		part_id: 9,
		quantity: 306,
	},
	{
		garage_id: 126,
		part_id: 10,
		quantity: 364,
	},
	{
		garage_id: 126,
		part_id: 11,
		quantity: 159,
	},
	{
		garage_id: 126,
		part_id: 12,
		quantity: 151,
	},
	{
		garage_id: 126,
		part_id: 13,
		quantity: 413,
	},
	{
		garage_id: 126,
		part_id: 14,
		quantity: 267,
	},
	{
		garage_id: 126,
		part_id: 15,
		quantity: 101,
	},
	{
		garage_id: 126,
		part_id: 16,
		quantity: 104,
	},
	{
		garage_id: 126,
		part_id: 17,
		quantity: 118,
	},
	{
		garage_id: 126,
		part_id: 18,
		quantity: 227,
	},
	{
		garage_id: 126,
		part_id: 19,
		quantity: 332,
	},
	{
		garage_id: 126,
		part_id: 20,
		quantity: 239,
	},
	{
		garage_id: 126,
		part_id: 21,
		quantity: 133,
	},
	{
		garage_id: 126,
		part_id: 22,
		quantity: 250,
	},
	{
		garage_id: 126,
		part_id: 23,
		quantity: 464,
	},
	{
		garage_id: 126,
		part_id: 24,
		quantity: 76,
	},
	{
		garage_id: 126,
		part_id: 25,
		quantity: 341,
	},
	{
		garage_id: 126,
		part_id: 26,
		quantity: 184,
	},
	{
		garage_id: 126,
		part_id: 27,
		quantity: 211,
	},
	{
		garage_id: 126,
		part_id: 28,
		quantity: 60,
	},
	{
		garage_id: 126,
		part_id: 29,
		quantity: 133,
	},
	{
		garage_id: 126,
		part_id: 30,
		quantity: 449,
	},
	{
		garage_id: 126,
		part_id: 31,
		quantity: 272,
	},
	{
		garage_id: 126,
		part_id: 32,
		quantity: 38,
	},
	{
		garage_id: 126,
		part_id: 33,
		quantity: 431,
	},
	{
		garage_id: 126,
		part_id: 34,
		quantity: 196,
	},
	{
		garage_id: 126,
		part_id: 35,
		quantity: 443,
	},
	{
		garage_id: 126,
		part_id: 36,
		quantity: 114,
	},
	{
		garage_id: 126,
		part_id: 37,
		quantity: 493,
	},
	{
		garage_id: 126,
		part_id: 38,
		quantity: 473,
	},
	{
		garage_id: 126,
		part_id: 39,
		quantity: 250,
	},
	{
		garage_id: 126,
		part_id: 40,
		quantity: 57,
	},
	{
		garage_id: 126,
		part_id: 41,
		quantity: 55,
	},
	{
		garage_id: 126,
		part_id: 42,
		quantity: 391,
	},
	{
		garage_id: 126,
		part_id: 43,
		quantity: 100,
	},
	{
		garage_id: 126,
		part_id: 44,
		quantity: 138,
	},
	{
		garage_id: 126,
		part_id: 45,
		quantity: 386,
	},
	{
		garage_id: 126,
		part_id: 46,
		quantity: 178,
	},
	{
		garage_id: 126,
		part_id: 47,
		quantity: 426,
	},
	{
		garage_id: 126,
		part_id: 48,
		quantity: 451,
	},
	{
		garage_id: 126,
		part_id: 49,
		quantity: 131,
	},
	{
		garage_id: 126,
		part_id: 50,
		quantity: 138,
	},
	{
		garage_id: 126,
		part_id: 51,
		quantity: 270,
	},
	{
		garage_id: 126,
		part_id: 52,
		quantity: 15,
	},
	{
		garage_id: 126,
		part_id: 53,
		quantity: 254,
	},
	{
		garage_id: 126,
		part_id: 54,
		quantity: 473,
	},
	{
		garage_id: 126,
		part_id: 55,
		quantity: 99,
	},
	{
		garage_id: 126,
		part_id: 56,
		quantity: 17,
	},
	{
		garage_id: 126,
		part_id: 57,
		quantity: 52,
	},
	{
		garage_id: 126,
		part_id: 58,
		quantity: 463,
	},
	{
		garage_id: 126,
		part_id: 59,
		quantity: 17,
	},
	{
		garage_id: 126,
		part_id: 60,
		quantity: 146,
	},
	{
		garage_id: 126,
		part_id: 61,
		quantity: 269,
	},
	{
		garage_id: 126,
		part_id: 62,
		quantity: 228,
	},
	{
		garage_id: 126,
		part_id: 63,
		quantity: 205,
	},
	{
		garage_id: 126,
		part_id: 64,
		quantity: 418,
	},
	{
		garage_id: 126,
		part_id: 65,
		quantity: 180,
	},
	{
		garage_id: 126,
		part_id: 66,
		quantity: 441,
	},
	{
		garage_id: 126,
		part_id: 67,
		quantity: 466,
	},
	{
		garage_id: 126,
		part_id: 68,
		quantity: 247,
	},
	{
		garage_id: 126,
		part_id: 69,
		quantity: 20,
	},
	{
		garage_id: 126,
		part_id: 70,
		quantity: 165,
	},
	{
		garage_id: 126,
		part_id: 71,
		quantity: 458,
	},
	{
		garage_id: 126,
		part_id: 72,
		quantity: 420,
	},
	{
		garage_id: 126,
		part_id: 73,
		quantity: 127,
	},
	{
		garage_id: 126,
		part_id: 74,
		quantity: 404,
	},
	{
		garage_id: 126,
		part_id: 75,
		quantity: 180,
	},
	{
		garage_id: 126,
		part_id: 76,
		quantity: 387,
	},
	{
		garage_id: 126,
		part_id: 77,
		quantity: 202,
	},
	{
		garage_id: 126,
		part_id: 78,
		quantity: 123,
	},
	{
		garage_id: 126,
		part_id: 79,
		quantity: 359,
	},
	{
		garage_id: 126,
		part_id: 80,
		quantity: 67,
	},
	{
		garage_id: 126,
		part_id: 81,
		quantity: 486,
	},
	{
		garage_id: 126,
		part_id: 82,
		quantity: 165,
	},
	{
		garage_id: 126,
		part_id: 83,
		quantity: 69,
	},
	{
		garage_id: 126,
		part_id: 84,
		quantity: 190,
	},
	{
		garage_id: 126,
		part_id: 85,
		quantity: 448,
	},
	{
		garage_id: 126,
		part_id: 86,
		quantity: 49,
	},
	{
		garage_id: 126,
		part_id: 87,
		quantity: 39,
	},
	{
		garage_id: 126,
		part_id: 88,
		quantity: 260,
	},
	{
		garage_id: 126,
		part_id: 89,
		quantity: 232,
	},
	{
		garage_id: 126,
		part_id: 90,
		quantity: 41,
	},
	{
		garage_id: 126,
		part_id: 91,
		quantity: 486,
	},
	{
		garage_id: 126,
		part_id: 92,
		quantity: 203,
	},
	{
		garage_id: 126,
		part_id: 93,
		quantity: 190,
	},
	{
		garage_id: 126,
		part_id: 94,
		quantity: 5,
	},
	{
		garage_id: 126,
		part_id: 95,
		quantity: 452,
	},
	{
		garage_id: 126,
		part_id: 96,
		quantity: 61,
	},
	{
		garage_id: 126,
		part_id: 97,
		quantity: 285,
	},
	{
		garage_id: 126,
		part_id: 98,
		quantity: 366,
	},
	{
		garage_id: 126,
		part_id: 99,
		quantity: 119,
	},
	{
		garage_id: 126,
		part_id: 100,
		quantity: 380,
	},
	{
		garage_id: 126,
		part_id: 101,
		quantity: 226,
	},
	{
		garage_id: 126,
		part_id: 102,
		quantity: 165,
	},
	{
		garage_id: 126,
		part_id: 103,
		quantity: 132,
	},
	{
		garage_id: 126,
		part_id: 104,
		quantity: 19,
	},
	{
		garage_id: 126,
		part_id: 105,
		quantity: 2,
	},
	{
		garage_id: 126,
		part_id: 106,
		quantity: 248,
	},
	{
		garage_id: 126,
		part_id: 107,
		quantity: 142,
	},
	{
		garage_id: 126,
		part_id: 108,
		quantity: 127,
	},
	{
		garage_id: 126,
		part_id: 109,
		quantity: 113,
	},
	{
		garage_id: 126,
		part_id: 110,
		quantity: 58,
	},
	{
		garage_id: 126,
		part_id: 111,
		quantity: 395,
	},
	{
		garage_id: 126,
		part_id: 112,
		quantity: 388,
	},
	{
		garage_id: 126,
		part_id: 113,
		quantity: 107,
	},
	{
		garage_id: 126,
		part_id: 114,
		quantity: 17,
	},
	{
		garage_id: 126,
		part_id: 115,
		quantity: 435,
	},
	{
		garage_id: 126,
		part_id: 116,
		quantity: 101,
	},
	{
		garage_id: 126,
		part_id: 117,
		quantity: 215,
	},
	{
		garage_id: 126,
		part_id: 118,
		quantity: 433,
	},
	{
		garage_id: 126,
		part_id: 119,
		quantity: 288,
	},
	{
		garage_id: 126,
		part_id: 120,
		quantity: 56,
	},
	{
		garage_id: 126,
		part_id: 121,
		quantity: 258,
	},
	{
		garage_id: 126,
		part_id: 122,
		quantity: 316,
	},
	{
		garage_id: 126,
		part_id: 123,
		quantity: 411,
	},
	{
		garage_id: 126,
		part_id: 124,
		quantity: 422,
	},
	{
		garage_id: 126,
		part_id: 125,
		quantity: 422,
	},
	{
		garage_id: 126,
		part_id: 126,
		quantity: 62,
	},
	{
		garage_id: 126,
		part_id: 127,
		quantity: 178,
	},
	{
		garage_id: 126,
		part_id: 128,
		quantity: 479,
	},
	{
		garage_id: 126,
		part_id: 129,
		quantity: 318,
	},
	{
		garage_id: 126,
		part_id: 130,
		quantity: 171,
	},
	{
		garage_id: 126,
		part_id: 131,
		quantity: 388,
	},
	{
		garage_id: 126,
		part_id: 132,
		quantity: 272,
	},
	{
		garage_id: 126,
		part_id: 133,
		quantity: 396,
	},
	{
		garage_id: 126,
		part_id: 134,
		quantity: 48,
	},
	{
		garage_id: 126,
		part_id: 135,
		quantity: 91,
	},
	{
		garage_id: 126,
		part_id: 136,
		quantity: 288,
	},
	{
		garage_id: 126,
		part_id: 137,
		quantity: 251,
	},
	{
		garage_id: 126,
		part_id: 138,
		quantity: 261,
	},
	{
		garage_id: 126,
		part_id: 139,
		quantity: 311,
	},
	{
		garage_id: 126,
		part_id: 140,
		quantity: 170,
	},
	{
		garage_id: 126,
		part_id: 141,
		quantity: 266,
	},
	{
		garage_id: 126,
		part_id: 142,
		quantity: 437,
	},
	{
		garage_id: 126,
		part_id: 143,
		quantity: 228,
	},
	{
		garage_id: 126,
		part_id: 144,
		quantity: 292,
	},
	{
		garage_id: 126,
		part_id: 145,
		quantity: 387,
	},
	{
		garage_id: 126,
		part_id: 146,
		quantity: 454,
	},
	{
		garage_id: 126,
		part_id: 147,
		quantity: 387,
	},
	{
		garage_id: 126,
		part_id: 148,
		quantity: 461,
	},
	{
		garage_id: 126,
		part_id: 149,
		quantity: 491,
	},
	{
		garage_id: 126,
		part_id: 150,
		quantity: 177,
	},
	{
		garage_id: 126,
		part_id: 151,
		quantity: 294,
	},
	{
		garage_id: 126,
		part_id: 152,
		quantity: 486,
	},
	{
		garage_id: 126,
		part_id: 153,
		quantity: 254,
	},
	{
		garage_id: 126,
		part_id: 154,
		quantity: 9,
	},
	{
		garage_id: 126,
		part_id: 155,
		quantity: 154,
	},
	{
		garage_id: 126,
		part_id: 156,
		quantity: 172,
	},
	{
		garage_id: 126,
		part_id: 157,
		quantity: 84,
	},
	{
		garage_id: 126,
		part_id: 158,
		quantity: 198,
	},
	{
		garage_id: 126,
		part_id: 159,
		quantity: 26,
	},
	{
		garage_id: 126,
		part_id: 160,
		quantity: 434,
	},
	{
		garage_id: 126,
		part_id: 161,
		quantity: 431,
	},
	{
		garage_id: 126,
		part_id: 162,
		quantity: 242,
	},
	{
		garage_id: 126,
		part_id: 163,
		quantity: 171,
	},
	{
		garage_id: 126,
		part_id: 164,
		quantity: 13,
	},
	{
		garage_id: 126,
		part_id: 165,
		quantity: 368,
	},
	{
		garage_id: 126,
		part_id: 166,
		quantity: 160,
	},
	{
		garage_id: 126,
		part_id: 167,
		quantity: 280,
	},
	{
		garage_id: 126,
		part_id: 168,
		quantity: 26,
	},
	{
		garage_id: 126,
		part_id: 169,
		quantity: 58,
	},
	{
		garage_id: 126,
		part_id: 170,
		quantity: 435,
	},
	{
		garage_id: 126,
		part_id: 171,
		quantity: 410,
	},
	{
		garage_id: 126,
		part_id: 172,
		quantity: 378,
	},
	{
		garage_id: 126,
		part_id: 173,
		quantity: 187,
	},
	{
		garage_id: 126,
		part_id: 174,
		quantity: 68,
	},
	{
		garage_id: 126,
		part_id: 175,
		quantity: 269,
	},
	{
		garage_id: 126,
		part_id: 176,
		quantity: 97,
	},
	{
		garage_id: 126,
		part_id: 177,
		quantity: 124,
	},
	{
		garage_id: 126,
		part_id: 178,
		quantity: 45,
	},
	{
		garage_id: 126,
		part_id: 179,
		quantity: 49,
	},
	{
		garage_id: 126,
		part_id: 180,
		quantity: 370,
	},
	{
		garage_id: 126,
		part_id: 181,
		quantity: 487,
	},
	{
		garage_id: 126,
		part_id: 182,
		quantity: 332,
	},
	{
		garage_id: 126,
		part_id: 183,
		quantity: 496,
	},
	{
		garage_id: 126,
		part_id: 184,
		quantity: 218,
	},
	{
		garage_id: 126,
		part_id: 185,
		quantity: 434,
	},
	{
		garage_id: 126,
		part_id: 186,
		quantity: 2,
	},
	{
		garage_id: 126,
		part_id: 187,
		quantity: 312,
	},
	{
		garage_id: 126,
		part_id: 188,
		quantity: 29,
	},
	{
		garage_id: 126,
		part_id: 189,
		quantity: 296,
	},
	{
		garage_id: 126,
		part_id: 190,
		quantity: 34,
	},
	{
		garage_id: 126,
		part_id: 191,
		quantity: 201,
	},
	{
		garage_id: 126,
		part_id: 192,
		quantity: 480,
	},
	{
		garage_id: 126,
		part_id: 193,
		quantity: 64,
	},
	{
		garage_id: 126,
		part_id: 194,
		quantity: 471,
	},
	{
		garage_id: 126,
		part_id: 195,
		quantity: 337,
	},
	{
		garage_id: 126,
		part_id: 196,
		quantity: 384,
	},
	{
		garage_id: 126,
		part_id: 197,
		quantity: 493,
	},
	{
		garage_id: 126,
		part_id: 198,
		quantity: 271,
	},
	{
		garage_id: 126,
		part_id: 199,
		quantity: 372,
	},
	{
		garage_id: 126,
		part_id: 200,
		quantity: 161,
	},
	{
		garage_id: 126,
		part_id: 201,
		quantity: 362,
	},
	{
		garage_id: 126,
		part_id: 202,
		quantity: 428,
	},
	{
		garage_id: 126,
		part_id: 203,
		quantity: 406,
	},
	{
		garage_id: 126,
		part_id: 204,
		quantity: 31,
	},
	{
		garage_id: 126,
		part_id: 205,
		quantity: 328,
	},
	{
		garage_id: 126,
		part_id: 206,
		quantity: 22,
	},
	{
		garage_id: 126,
		part_id: 207,
		quantity: 349,
	},
	{
		garage_id: 126,
		part_id: 208,
		quantity: 14,
	},
	{
		garage_id: 126,
		part_id: 209,
		quantity: 7,
	},
	{
		garage_id: 126,
		part_id: 210,
		quantity: 231,
	},
	{
		garage_id: 126,
		part_id: 211,
		quantity: 145,
	},
	{
		garage_id: 126,
		part_id: 212,
		quantity: 337,
	},
	{
		garage_id: 126,
		part_id: 213,
		quantity: 219,
	},
	{
		garage_id: 126,
		part_id: 214,
		quantity: 172,
	},
	{
		garage_id: 126,
		part_id: 215,
		quantity: 154,
	},
	{
		garage_id: 126,
		part_id: 216,
		quantity: 211,
	},
	{
		garage_id: 126,
		part_id: 217,
		quantity: 414,
	},
	{
		garage_id: 126,
		part_id: 218,
		quantity: 260,
	},
	{
		garage_id: 126,
		part_id: 219,
		quantity: 112,
	},
	{
		garage_id: 126,
		part_id: 220,
		quantity: 475,
	},
	{
		garage_id: 126,
		part_id: 221,
		quantity: 70,
	},
	{
		garage_id: 126,
		part_id: 222,
		quantity: 252,
	},
	{
		garage_id: 126,
		part_id: 223,
		quantity: 428,
	},
	{
		garage_id: 126,
		part_id: 224,
		quantity: 175,
	},
	{
		garage_id: 126,
		part_id: 225,
		quantity: 454,
	},
	{
		garage_id: 126,
		part_id: 226,
		quantity: 494,
	},
	{
		garage_id: 126,
		part_id: 227,
		quantity: 6,
	},
	{
		garage_id: 126,
		part_id: 228,
		quantity: 375,
	},
	{
		garage_id: 126,
		part_id: 229,
		quantity: 3,
	},
	{
		garage_id: 126,
		part_id: 230,
		quantity: 318,
	},
	{
		garage_id: 126,
		part_id: 231,
		quantity: 222,
	},
	{
		garage_id: 126,
		part_id: 232,
		quantity: 468,
	},
	{
		garage_id: 126,
		part_id: 233,
		quantity: 395,
	},
	{
		garage_id: 126,
		part_id: 234,
		quantity: 178,
	},
	{
		garage_id: 126,
		part_id: 235,
		quantity: 244,
	},
	{
		garage_id: 126,
		part_id: 236,
		quantity: 182,
	},
	{
		garage_id: 126,
		part_id: 237,
		quantity: 3,
	},
	{
		garage_id: 126,
		part_id: 238,
		quantity: 289,
	},
	{
		garage_id: 126,
		part_id: 239,
		quantity: 201,
	},
	{
		garage_id: 126,
		part_id: 240,
		quantity: 303,
	},
	{
		garage_id: 126,
		part_id: 241,
		quantity: 40,
	},
	{
		garage_id: 126,
		part_id: 242,
		quantity: 365,
	},
	{
		garage_id: 126,
		part_id: 243,
		quantity: 208,
	},
	{
		garage_id: 126,
		part_id: 244,
		quantity: 267,
	},
	{
		garage_id: 126,
		part_id: 245,
		quantity: 177,
	},
	{
		garage_id: 126,
		part_id: 246,
		quantity: 199,
	},
	{
		garage_id: 126,
		part_id: 247,
		quantity: 140,
	},
	{
		garage_id: 126,
		part_id: 248,
		quantity: 68,
	},
	{
		garage_id: 126,
		part_id: 249,
		quantity: 136,
	},
	{
		garage_id: 126,
		part_id: 250,
		quantity: 109,
	},
	{
		garage_id: 126,
		part_id: 251,
		quantity: 493,
	},
	{
		garage_id: 126,
		part_id: 252,
		quantity: 201,
	},
	{
		garage_id: 126,
		part_id: 253,
		quantity: 231,
	},
	{
		garage_id: 126,
		part_id: 254,
		quantity: 378,
	},
	{
		garage_id: 126,
		part_id: 255,
		quantity: 172,
	},
	{
		garage_id: 126,
		part_id: 256,
		quantity: 420,
	},
	{
		garage_id: 126,
		part_id: 257,
		quantity: 198,
	},
	{
		garage_id: 126,
		part_id: 258,
		quantity: 400,
	},
	{
		garage_id: 126,
		part_id: 259,
		quantity: 319,
	},
	{
		garage_id: 126,
		part_id: 260,
		quantity: 169,
	},
	{
		garage_id: 126,
		part_id: 261,
		quantity: 369,
	},
	{
		garage_id: 126,
		part_id: 262,
		quantity: 24,
	},
	{
		garage_id: 126,
		part_id: 263,
		quantity: 307,
	},
	{
		garage_id: 126,
		part_id: 264,
		quantity: 202,
	},
	{
		garage_id: 126,
		part_id: 265,
		quantity: 415,
	},
	{
		garage_id: 126,
		part_id: 266,
		quantity: 376,
	},
	{
		garage_id: 126,
		part_id: 267,
		quantity: 2,
	},
	{
		garage_id: 126,
		part_id: 268,
		quantity: 37,
	},
	{
		garage_id: 126,
		part_id: 269,
		quantity: 220,
	},
	{
		garage_id: 126,
		part_id: 270,
		quantity: 85,
	},
	{
		garage_id: 126,
		part_id: 271,
		quantity: 382,
	},
	{
		garage_id: 126,
		part_id: 272,
		quantity: 39,
	},
	{
		garage_id: 126,
		part_id: 273,
		quantity: 234,
	},
	{
		garage_id: 126,
		part_id: 274,
		quantity: 412,
	},
	{
		garage_id: 126,
		part_id: 275,
		quantity: 111,
	},
	{
		garage_id: 126,
		part_id: 276,
		quantity: 212,
	},
	{
		garage_id: 126,
		part_id: 277,
		quantity: 94,
	},
	{
		garage_id: 126,
		part_id: 278,
		quantity: 364,
	},
	{
		garage_id: 126,
		part_id: 279,
		quantity: 105,
	},
	{
		garage_id: 126,
		part_id: 280,
		quantity: 225,
	},
	{
		garage_id: 126,
		part_id: 281,
		quantity: 450,
	},
	{
		garage_id: 126,
		part_id: 282,
		quantity: 482,
	},
	{
		garage_id: 126,
		part_id: 283,
		quantity: 106,
	},
	{
		garage_id: 126,
		part_id: 284,
		quantity: 185,
	},
	{
		garage_id: 126,
		part_id: 285,
		quantity: 312,
	},
	{
		garage_id: 126,
		part_id: 286,
		quantity: 34,
	},
	{
		garage_id: 126,
		part_id: 287,
		quantity: 289,
	},
	{
		garage_id: 126,
		part_id: 288,
		quantity: 3,
	},
	{
		garage_id: 126,
		part_id: 289,
		quantity: 367,
	},
	{
		garage_id: 126,
		part_id: 290,
		quantity: 496,
	},
	{
		garage_id: 126,
		part_id: 291,
		quantity: 70,
	},
	{
		garage_id: 126,
		part_id: 292,
		quantity: 195,
	},
	{
		garage_id: 126,
		part_id: 293,
		quantity: 218,
	},
	{
		garage_id: 126,
		part_id: 294,
		quantity: 18,
	},
	{
		garage_id: 126,
		part_id: 295,
		quantity: 273,
	},
	{
		garage_id: 126,
		part_id: 296,
		quantity: 98,
	},
	{
		garage_id: 126,
		part_id: 297,
		quantity: 276,
	},
	{
		garage_id: 126,
		part_id: 298,
		quantity: 386,
	},
	{
		garage_id: 126,
		part_id: 299,
		quantity: 335,
	},
	{
		garage_id: 126,
		part_id: 300,
		quantity: 335,
	},
	{
		garage_id: 126,
		part_id: 301,
		quantity: 207,
	},
	{
		garage_id: 126,
		part_id: 302,
		quantity: 402,
	},
	{
		garage_id: 126,
		part_id: 303,
		quantity: 466,
	},
	{
		garage_id: 126,
		part_id: 304,
		quantity: 320,
	},
	{
		garage_id: 126,
		part_id: 305,
		quantity: 491,
	},
	{
		garage_id: 126,
		part_id: 306,
		quantity: 208,
	},
	{
		garage_id: 126,
		part_id: 307,
		quantity: 466,
	},
	{
		garage_id: 126,
		part_id: 308,
		quantity: 497,
	},
	{
		garage_id: 126,
		part_id: 309,
		quantity: 66,
	},
	{
		garage_id: 126,
		part_id: 310,
		quantity: 256,
	},
	{
		garage_id: 126,
		part_id: 311,
		quantity: 368,
	},
	{
		garage_id: 126,
		part_id: 312,
		quantity: 393,
	},
	{
		garage_id: 126,
		part_id: 313,
		quantity: 143,
	},
	{
		garage_id: 126,
		part_id: 314,
		quantity: 331,
	},
	{
		garage_id: 126,
		part_id: 315,
		quantity: 497,
	},
	{
		garage_id: 126,
		part_id: 316,
		quantity: 211,
	},
	{
		garage_id: 126,
		part_id: 317,
		quantity: 435,
	},
	{
		garage_id: 126,
		part_id: 318,
		quantity: 224,
	},
	{
		garage_id: 126,
		part_id: 319,
		quantity: 19,
	},
	{
		garage_id: 126,
		part_id: 320,
		quantity: 342,
	},
	{
		garage_id: 126,
		part_id: 321,
		quantity: 258,
	},
	{
		garage_id: 126,
		part_id: 322,
		quantity: 331,
	},
	{
		garage_id: 126,
		part_id: 323,
		quantity: 30,
	},
	{
		garage_id: 126,
		part_id: 324,
		quantity: 340,
	},
	{
		garage_id: 126,
		part_id: 325,
		quantity: 85,
	},
	{
		garage_id: 126,
		part_id: 326,
		quantity: 294,
	},
	{
		garage_id: 126,
		part_id: 327,
		quantity: 294,
	},
	{
		garage_id: 126,
		part_id: 328,
		quantity: 367,
	},
	{
		garage_id: 126,
		part_id: 329,
		quantity: 301,
	},
	{
		garage_id: 126,
		part_id: 330,
		quantity: 223,
	},
	{
		garage_id: 126,
		part_id: 331,
		quantity: 254,
	},
	{
		garage_id: 126,
		part_id: 332,
		quantity: 322,
	},
	{
		garage_id: 126,
		part_id: 333,
		quantity: 214,
	},
	{
		garage_id: 126,
		part_id: 334,
		quantity: 77,
	},
	{
		garage_id: 126,
		part_id: 335,
		quantity: 499,
	},
	{
		garage_id: 126,
		part_id: 336,
		quantity: 392,
	},
	{
		garage_id: 126,
		part_id: 337,
		quantity: 69,
	},
	{
		garage_id: 126,
		part_id: 338,
		quantity: 324,
	},
	{
		garage_id: 126,
		part_id: 339,
		quantity: 332,
	},
	{
		garage_id: 126,
		part_id: 340,
		quantity: 194,
	},
	{
		garage_id: 126,
		part_id: 341,
		quantity: 87,
	},
	{
		garage_id: 126,
		part_id: 342,
		quantity: 175,
	},
	{
		garage_id: 126,
		part_id: 343,
		quantity: 250,
	},
	{
		garage_id: 126,
		part_id: 344,
		quantity: 230,
	},
	{
		garage_id: 126,
		part_id: 345,
		quantity: 373,
	},
	{
		garage_id: 126,
		part_id: 346,
		quantity: 423,
	},
	{
		garage_id: 126,
		part_id: 347,
		quantity: 83,
	},
	{
		garage_id: 126,
		part_id: 348,
		quantity: 214,
	},
	{
		garage_id: 126,
		part_id: 349,
		quantity: 489,
	},
	{
		garage_id: 126,
		part_id: 350,
		quantity: 187,
	},
	{
		garage_id: 126,
		part_id: 351,
		quantity: 330,
	},
	{
		garage_id: 126,
		part_id: 352,
		quantity: 59,
	},
	{
		garage_id: 126,
		part_id: 353,
		quantity: 54,
	},
	{
		garage_id: 126,
		part_id: 354,
		quantity: 424,
	},
	{
		garage_id: 126,
		part_id: 355,
		quantity: 347,
	},
	{
		garage_id: 126,
		part_id: 356,
		quantity: 213,
	},
	{
		garage_id: 126,
		part_id: 357,
		quantity: 180,
	},
	{
		garage_id: 126,
		part_id: 358,
		quantity: 362,
	},
	{
		garage_id: 126,
		part_id: 359,
		quantity: 313,
	},
	{
		garage_id: 126,
		part_id: 360,
		quantity: 234,
	},
	{
		garage_id: 126,
		part_id: 361,
		quantity: 173,
	},
	{
		garage_id: 126,
		part_id: 362,
		quantity: 27,
	},
	{
		garage_id: 126,
		part_id: 363,
		quantity: 16,
	},
	{
		garage_id: 126,
		part_id: 364,
		quantity: 113,
	},
	{
		garage_id: 126,
		part_id: 365,
		quantity: 406,
	},
	{
		garage_id: 126,
		part_id: 366,
		quantity: 154,
	},
	{
		garage_id: 126,
		part_id: 367,
		quantity: 155,
	},
	{
		garage_id: 126,
		part_id: 368,
		quantity: 177,
	},
	{
		garage_id: 126,
		part_id: 369,
		quantity: 280,
	},
	{
		garage_id: 126,
		part_id: 370,
		quantity: 435,
	},
	{
		garage_id: 126,
		part_id: 371,
		quantity: 425,
	},
	{
		garage_id: 126,
		part_id: 372,
		quantity: 48,
	},
	{
		garage_id: 126,
		part_id: 373,
		quantity: 317,
	},
	{
		garage_id: 126,
		part_id: 374,
		quantity: 464,
	},
	{
		garage_id: 126,
		part_id: 375,
		quantity: 496,
	},
	{
		garage_id: 126,
		part_id: 376,
		quantity: 46,
	},
	{
		garage_id: 126,
		part_id: 377,
		quantity: 486,
	},
	{
		garage_id: 126,
		part_id: 378,
		quantity: 179,
	},
	{
		garage_id: 126,
		part_id: 379,
		quantity: 417,
	},
	{
		garage_id: 126,
		part_id: 380,
		quantity: 450,
	},
	{
		garage_id: 126,
		part_id: 381,
		quantity: 120,
	},
	{
		garage_id: 126,
		part_id: 382,
		quantity: 47,
	},
	{
		garage_id: 126,
		part_id: 383,
		quantity: 401,
	},
	{
		garage_id: 126,
		part_id: 384,
		quantity: 275,
	},
	{
		garage_id: 126,
		part_id: 385,
		quantity: 234,
	},
	{
		garage_id: 126,
		part_id: 386,
		quantity: 162,
	},
	{
		garage_id: 126,
		part_id: 387,
		quantity: 295,
	},
	{
		garage_id: 126,
		part_id: 388,
		quantity: 351,
	},
	{
		garage_id: 126,
		part_id: 389,
		quantity: 196,
	},
	{
		garage_id: 126,
		part_id: 390,
		quantity: 10,
	},
	{
		garage_id: 126,
		part_id: 391,
		quantity: 87,
	},
	{
		garage_id: 126,
		part_id: 392,
		quantity: 297,
	},
	{
		garage_id: 126,
		part_id: 393,
		quantity: 183,
	},
	{
		garage_id: 126,
		part_id: 394,
		quantity: 477,
	},
	{
		garage_id: 126,
		part_id: 395,
		quantity: 312,
	},
	{
		garage_id: 126,
		part_id: 396,
		quantity: 367,
	},
	{
		garage_id: 126,
		part_id: 397,
		quantity: 426,
	},
	{
		garage_id: 126,
		part_id: 398,
		quantity: 155,
	},
	{
		garage_id: 126,
		part_id: 399,
		quantity: 220,
	},
	{
		garage_id: 127,
		part_id: 0,
		quantity: 164,
	},
	{
		garage_id: 127,
		part_id: 1,
		quantity: 484,
	},
	{
		garage_id: 127,
		part_id: 2,
		quantity: 203,
	},
	{
		garage_id: 127,
		part_id: 3,
		quantity: 197,
	},
	{
		garage_id: 127,
		part_id: 4,
		quantity: 7,
	},
	{
		garage_id: 127,
		part_id: 5,
		quantity: 93,
	},
	{
		garage_id: 127,
		part_id: 6,
		quantity: 428,
	},
	{
		garage_id: 127,
		part_id: 7,
		quantity: 479,
	},
	{
		garage_id: 127,
		part_id: 8,
		quantity: 408,
	},
	{
		garage_id: 127,
		part_id: 9,
		quantity: 113,
	},
	{
		garage_id: 127,
		part_id: 10,
		quantity: 116,
	},
	{
		garage_id: 127,
		part_id: 11,
		quantity: 349,
	},
	{
		garage_id: 127,
		part_id: 12,
		quantity: 330,
	},
	{
		garage_id: 127,
		part_id: 13,
		quantity: 331,
	},
	{
		garage_id: 127,
		part_id: 14,
		quantity: 344,
	},
	{
		garage_id: 127,
		part_id: 15,
		quantity: 346,
	},
	{
		garage_id: 127,
		part_id: 16,
		quantity: 171,
	},
	{
		garage_id: 127,
		part_id: 17,
		quantity: 10,
	},
	{
		garage_id: 127,
		part_id: 18,
		quantity: 254,
	},
	{
		garage_id: 127,
		part_id: 19,
		quantity: 166,
	},
	{
		garage_id: 127,
		part_id: 20,
		quantity: 171,
	},
	{
		garage_id: 127,
		part_id: 21,
		quantity: 382,
	},
	{
		garage_id: 127,
		part_id: 22,
		quantity: 236,
	},
	{
		garage_id: 127,
		part_id: 23,
		quantity: 388,
	},
	{
		garage_id: 127,
		part_id: 24,
		quantity: 320,
	},
	{
		garage_id: 127,
		part_id: 25,
		quantity: 208,
	},
	{
		garage_id: 127,
		part_id: 26,
		quantity: 42,
	},
	{
		garage_id: 127,
		part_id: 27,
		quantity: 463,
	},
	{
		garage_id: 127,
		part_id: 28,
		quantity: 186,
	},
	{
		garage_id: 127,
		part_id: 29,
		quantity: 4,
	},
	{
		garage_id: 127,
		part_id: 30,
		quantity: 495,
	},
	{
		garage_id: 127,
		part_id: 31,
		quantity: 209,
	},
	{
		garage_id: 127,
		part_id: 32,
		quantity: 72,
	},
	{
		garage_id: 127,
		part_id: 33,
		quantity: 184,
	},
	{
		garage_id: 127,
		part_id: 34,
		quantity: 167,
	},
	{
		garage_id: 127,
		part_id: 35,
		quantity: 204,
	},
	{
		garage_id: 127,
		part_id: 36,
		quantity: 175,
	},
	{
		garage_id: 127,
		part_id: 37,
		quantity: 271,
	},
	{
		garage_id: 127,
		part_id: 38,
		quantity: 265,
	},
	{
		garage_id: 127,
		part_id: 39,
		quantity: 86,
	},
	{
		garage_id: 127,
		part_id: 40,
		quantity: 257,
	},
	{
		garage_id: 127,
		part_id: 41,
		quantity: 310,
	},
	{
		garage_id: 127,
		part_id: 42,
		quantity: 434,
	},
	{
		garage_id: 127,
		part_id: 43,
		quantity: 14,
	},
	{
		garage_id: 127,
		part_id: 44,
		quantity: 7,
	},
	{
		garage_id: 127,
		part_id: 45,
		quantity: 143,
	},
	{
		garage_id: 127,
		part_id: 46,
		quantity: 167,
	},
	{
		garage_id: 127,
		part_id: 47,
		quantity: 258,
	},
	{
		garage_id: 127,
		part_id: 48,
		quantity: 282,
	},
	{
		garage_id: 127,
		part_id: 49,
		quantity: 258,
	},
	{
		garage_id: 127,
		part_id: 50,
		quantity: 388,
	},
	{
		garage_id: 127,
		part_id: 51,
		quantity: 365,
	},
	{
		garage_id: 127,
		part_id: 52,
		quantity: 89,
	},
	{
		garage_id: 127,
		part_id: 53,
		quantity: 302,
	},
	{
		garage_id: 127,
		part_id: 54,
		quantity: 429,
	},
	{
		garage_id: 127,
		part_id: 55,
		quantity: 299,
	},
	{
		garage_id: 127,
		part_id: 56,
		quantity: 38,
	},
	{
		garage_id: 127,
		part_id: 57,
		quantity: 253,
	},
	{
		garage_id: 127,
		part_id: 58,
		quantity: 201,
	},
	{
		garage_id: 127,
		part_id: 59,
		quantity: 72,
	},
	{
		garage_id: 127,
		part_id: 60,
		quantity: 216,
	},
	{
		garage_id: 127,
		part_id: 61,
		quantity: 85,
	},
	{
		garage_id: 127,
		part_id: 62,
		quantity: 130,
	},
	{
		garage_id: 127,
		part_id: 63,
		quantity: 4,
	},
	{
		garage_id: 127,
		part_id: 64,
		quantity: 34,
	},
	{
		garage_id: 127,
		part_id: 65,
		quantity: 311,
	},
	{
		garage_id: 127,
		part_id: 66,
		quantity: 330,
	},
	{
		garage_id: 127,
		part_id: 67,
		quantity: 187,
	},
	{
		garage_id: 127,
		part_id: 68,
		quantity: 242,
	},
	{
		garage_id: 127,
		part_id: 69,
		quantity: 246,
	},
	{
		garage_id: 127,
		part_id: 70,
		quantity: 7,
	},
	{
		garage_id: 127,
		part_id: 71,
		quantity: 353,
	},
	{
		garage_id: 127,
		part_id: 72,
		quantity: 81,
	},
	{
		garage_id: 127,
		part_id: 73,
		quantity: 137,
	},
	{
		garage_id: 127,
		part_id: 74,
		quantity: 148,
	},
	{
		garage_id: 127,
		part_id: 75,
		quantity: 3,
	},
	{
		garage_id: 127,
		part_id: 76,
		quantity: 386,
	},
	{
		garage_id: 127,
		part_id: 77,
		quantity: 140,
	},
	{
		garage_id: 127,
		part_id: 78,
		quantity: 252,
	},
	{
		garage_id: 127,
		part_id: 79,
		quantity: 361,
	},
	{
		garage_id: 127,
		part_id: 80,
		quantity: 69,
	},
	{
		garage_id: 127,
		part_id: 81,
		quantity: 464,
	},
	{
		garage_id: 127,
		part_id: 82,
		quantity: 297,
	},
	{
		garage_id: 127,
		part_id: 83,
		quantity: 318,
	},
	{
		garage_id: 127,
		part_id: 84,
		quantity: 215,
	},
	{
		garage_id: 127,
		part_id: 85,
		quantity: 238,
	},
	{
		garage_id: 127,
		part_id: 86,
		quantity: 66,
	},
	{
		garage_id: 127,
		part_id: 87,
		quantity: 442,
	},
	{
		garage_id: 127,
		part_id: 88,
		quantity: 383,
	},
	{
		garage_id: 127,
		part_id: 89,
		quantity: 127,
	},
	{
		garage_id: 127,
		part_id: 90,
		quantity: 232,
	},
	{
		garage_id: 127,
		part_id: 91,
		quantity: 405,
	},
	{
		garage_id: 127,
		part_id: 92,
		quantity: 333,
	},
	{
		garage_id: 127,
		part_id: 93,
		quantity: 131,
	},
	{
		garage_id: 127,
		part_id: 94,
		quantity: 51,
	},
	{
		garage_id: 127,
		part_id: 95,
		quantity: 30,
	},
	{
		garage_id: 127,
		part_id: 96,
		quantity: 198,
	},
	{
		garage_id: 127,
		part_id: 97,
		quantity: 88,
	},
	{
		garage_id: 127,
		part_id: 98,
		quantity: 228,
	},
	{
		garage_id: 127,
		part_id: 99,
		quantity: 212,
	},
	{
		garage_id: 127,
		part_id: 100,
		quantity: 311,
	},
	{
		garage_id: 127,
		part_id: 101,
		quantity: 374,
	},
	{
		garage_id: 127,
		part_id: 102,
		quantity: 4,
	},
	{
		garage_id: 127,
		part_id: 103,
		quantity: 139,
	},
	{
		garage_id: 127,
		part_id: 104,
		quantity: 432,
	},
	{
		garage_id: 127,
		part_id: 105,
		quantity: 447,
	},
	{
		garage_id: 127,
		part_id: 106,
		quantity: 215,
	},
	{
		garage_id: 127,
		part_id: 107,
		quantity: 0,
	},
	{
		garage_id: 127,
		part_id: 108,
		quantity: 25,
	},
	{
		garage_id: 127,
		part_id: 109,
		quantity: 446,
	},
	{
		garage_id: 127,
		part_id: 110,
		quantity: 317,
	},
	{
		garage_id: 127,
		part_id: 111,
		quantity: 99,
	},
	{
		garage_id: 127,
		part_id: 112,
		quantity: 174,
	},
	{
		garage_id: 127,
		part_id: 113,
		quantity: 129,
	},
	{
		garage_id: 127,
		part_id: 114,
		quantity: 216,
	},
	{
		garage_id: 127,
		part_id: 115,
		quantity: 359,
	},
	{
		garage_id: 127,
		part_id: 116,
		quantity: 236,
	},
	{
		garage_id: 127,
		part_id: 117,
		quantity: 249,
	},
	{
		garage_id: 127,
		part_id: 118,
		quantity: 184,
	},
	{
		garage_id: 127,
		part_id: 119,
		quantity: 40,
	},
	{
		garage_id: 127,
		part_id: 120,
		quantity: 105,
	},
	{
		garage_id: 127,
		part_id: 121,
		quantity: 422,
	},
	{
		garage_id: 127,
		part_id: 122,
		quantity: 447,
	},
	{
		garage_id: 127,
		part_id: 123,
		quantity: 446,
	},
	{
		garage_id: 127,
		part_id: 124,
		quantity: 297,
	},
	{
		garage_id: 127,
		part_id: 125,
		quantity: 318,
	},
	{
		garage_id: 127,
		part_id: 126,
		quantity: 493,
	},
	{
		garage_id: 127,
		part_id: 127,
		quantity: 58,
	},
	{
		garage_id: 127,
		part_id: 128,
		quantity: 30,
	},
	{
		garage_id: 127,
		part_id: 129,
		quantity: 175,
	},
	{
		garage_id: 127,
		part_id: 130,
		quantity: 403,
	},
	{
		garage_id: 127,
		part_id: 131,
		quantity: 31,
	},
	{
		garage_id: 127,
		part_id: 132,
		quantity: 122,
	},
	{
		garage_id: 127,
		part_id: 133,
		quantity: 24,
	},
	{
		garage_id: 127,
		part_id: 134,
		quantity: 276,
	},
	{
		garage_id: 127,
		part_id: 135,
		quantity: 167,
	},
	{
		garage_id: 127,
		part_id: 136,
		quantity: 20,
	},
	{
		garage_id: 127,
		part_id: 137,
		quantity: 26,
	},
	{
		garage_id: 127,
		part_id: 138,
		quantity: 410,
	},
	{
		garage_id: 127,
		part_id: 139,
		quantity: 288,
	},
	{
		garage_id: 127,
		part_id: 140,
		quantity: 206,
	},
	{
		garage_id: 127,
		part_id: 141,
		quantity: 319,
	},
	{
		garage_id: 127,
		part_id: 142,
		quantity: 56,
	},
	{
		garage_id: 127,
		part_id: 143,
		quantity: 231,
	},
	{
		garage_id: 127,
		part_id: 144,
		quantity: 285,
	},
	{
		garage_id: 127,
		part_id: 145,
		quantity: 16,
	},
	{
		garage_id: 127,
		part_id: 146,
		quantity: 91,
	},
	{
		garage_id: 127,
		part_id: 147,
		quantity: 3,
	},
	{
		garage_id: 127,
		part_id: 148,
		quantity: 455,
	},
	{
		garage_id: 127,
		part_id: 149,
		quantity: 329,
	},
	{
		garage_id: 127,
		part_id: 150,
		quantity: 63,
	},
	{
		garage_id: 127,
		part_id: 151,
		quantity: 245,
	},
	{
		garage_id: 127,
		part_id: 152,
		quantity: 266,
	},
	{
		garage_id: 127,
		part_id: 153,
		quantity: 278,
	},
	{
		garage_id: 127,
		part_id: 154,
		quantity: 472,
	},
	{
		garage_id: 127,
		part_id: 155,
		quantity: 151,
	},
	{
		garage_id: 127,
		part_id: 156,
		quantity: 240,
	},
	{
		garage_id: 127,
		part_id: 157,
		quantity: 177,
	},
	{
		garage_id: 127,
		part_id: 158,
		quantity: 11,
	},
	{
		garage_id: 127,
		part_id: 159,
		quantity: 253,
	},
	{
		garage_id: 127,
		part_id: 160,
		quantity: 346,
	},
	{
		garage_id: 127,
		part_id: 161,
		quantity: 304,
	},
	{
		garage_id: 127,
		part_id: 162,
		quantity: 447,
	},
	{
		garage_id: 127,
		part_id: 163,
		quantity: 471,
	},
	{
		garage_id: 127,
		part_id: 164,
		quantity: 119,
	},
	{
		garage_id: 127,
		part_id: 165,
		quantity: 106,
	},
	{
		garage_id: 127,
		part_id: 166,
		quantity: 333,
	},
	{
		garage_id: 127,
		part_id: 167,
		quantity: 59,
	},
	{
		garage_id: 127,
		part_id: 168,
		quantity: 204,
	},
	{
		garage_id: 127,
		part_id: 169,
		quantity: 331,
	},
	{
		garage_id: 127,
		part_id: 170,
		quantity: 244,
	},
	{
		garage_id: 127,
		part_id: 171,
		quantity: 211,
	},
	{
		garage_id: 127,
		part_id: 172,
		quantity: 132,
	},
	{
		garage_id: 127,
		part_id: 173,
		quantity: 54,
	},
	{
		garage_id: 127,
		part_id: 174,
		quantity: 179,
	},
	{
		garage_id: 127,
		part_id: 175,
		quantity: 374,
	},
	{
		garage_id: 127,
		part_id: 176,
		quantity: 239,
	},
	{
		garage_id: 127,
		part_id: 177,
		quantity: 21,
	},
	{
		garage_id: 127,
		part_id: 178,
		quantity: 356,
	},
	{
		garage_id: 127,
		part_id: 179,
		quantity: 304,
	},
	{
		garage_id: 127,
		part_id: 180,
		quantity: 110,
	},
	{
		garage_id: 127,
		part_id: 181,
		quantity: 14,
	},
	{
		garage_id: 127,
		part_id: 182,
		quantity: 213,
	},
	{
		garage_id: 127,
		part_id: 183,
		quantity: 477,
	},
	{
		garage_id: 127,
		part_id: 184,
		quantity: 211,
	},
	{
		garage_id: 127,
		part_id: 185,
		quantity: 370,
	},
	{
		garage_id: 127,
		part_id: 186,
		quantity: 391,
	},
	{
		garage_id: 127,
		part_id: 187,
		quantity: 473,
	},
	{
		garage_id: 127,
		part_id: 188,
		quantity: 221,
	},
	{
		garage_id: 127,
		part_id: 189,
		quantity: 269,
	},
	{
		garage_id: 127,
		part_id: 190,
		quantity: 130,
	},
	{
		garage_id: 127,
		part_id: 191,
		quantity: 166,
	},
	{
		garage_id: 127,
		part_id: 192,
		quantity: 363,
	},
	{
		garage_id: 127,
		part_id: 193,
		quantity: 248,
	},
	{
		garage_id: 127,
		part_id: 194,
		quantity: 253,
	},
	{
		garage_id: 127,
		part_id: 195,
		quantity: 391,
	},
	{
		garage_id: 127,
		part_id: 196,
		quantity: 30,
	},
	{
		garage_id: 127,
		part_id: 197,
		quantity: 292,
	},
	{
		garage_id: 127,
		part_id: 198,
		quantity: 82,
	},
	{
		garage_id: 127,
		part_id: 199,
		quantity: 294,
	},
	{
		garage_id: 127,
		part_id: 200,
		quantity: 59,
	},
	{
		garage_id: 127,
		part_id: 201,
		quantity: 218,
	},
	{
		garage_id: 127,
		part_id: 202,
		quantity: 90,
	},
	{
		garage_id: 127,
		part_id: 203,
		quantity: 324,
	},
	{
		garage_id: 127,
		part_id: 204,
		quantity: 317,
	},
	{
		garage_id: 127,
		part_id: 205,
		quantity: 163,
	},
	{
		garage_id: 127,
		part_id: 206,
		quantity: 328,
	},
	{
		garage_id: 127,
		part_id: 207,
		quantity: 202,
	},
	{
		garage_id: 127,
		part_id: 208,
		quantity: 336,
	},
	{
		garage_id: 127,
		part_id: 209,
		quantity: 384,
	},
	{
		garage_id: 127,
		part_id: 210,
		quantity: 494,
	},
	{
		garage_id: 127,
		part_id: 211,
		quantity: 355,
	},
	{
		garage_id: 127,
		part_id: 212,
		quantity: 348,
	},
	{
		garage_id: 127,
		part_id: 213,
		quantity: 295,
	},
	{
		garage_id: 127,
		part_id: 214,
		quantity: 428,
	},
	{
		garage_id: 127,
		part_id: 215,
		quantity: 92,
	},
	{
		garage_id: 127,
		part_id: 216,
		quantity: 482,
	},
	{
		garage_id: 127,
		part_id: 217,
		quantity: 349,
	},
	{
		garage_id: 127,
		part_id: 218,
		quantity: 237,
	},
	{
		garage_id: 127,
		part_id: 219,
		quantity: 126,
	},
	{
		garage_id: 127,
		part_id: 220,
		quantity: 143,
	},
	{
		garage_id: 127,
		part_id: 221,
		quantity: 353,
	},
	{
		garage_id: 127,
		part_id: 222,
		quantity: 132,
	},
	{
		garage_id: 127,
		part_id: 223,
		quantity: 232,
	},
	{
		garage_id: 127,
		part_id: 224,
		quantity: 85,
	},
	{
		garage_id: 127,
		part_id: 225,
		quantity: 343,
	},
	{
		garage_id: 127,
		part_id: 226,
		quantity: 295,
	},
	{
		garage_id: 127,
		part_id: 227,
		quantity: 103,
	},
	{
		garage_id: 127,
		part_id: 228,
		quantity: 82,
	},
	{
		garage_id: 127,
		part_id: 229,
		quantity: 207,
	},
	{
		garage_id: 127,
		part_id: 230,
		quantity: 220,
	},
	{
		garage_id: 127,
		part_id: 231,
		quantity: 451,
	},
	{
		garage_id: 127,
		part_id: 232,
		quantity: 452,
	},
	{
		garage_id: 127,
		part_id: 233,
		quantity: 396,
	},
	{
		garage_id: 127,
		part_id: 234,
		quantity: 409,
	},
	{
		garage_id: 127,
		part_id: 235,
		quantity: 423,
	},
	{
		garage_id: 127,
		part_id: 236,
		quantity: 19,
	},
	{
		garage_id: 127,
		part_id: 237,
		quantity: 407,
	},
	{
		garage_id: 127,
		part_id: 238,
		quantity: 268,
	},
	{
		garage_id: 127,
		part_id: 239,
		quantity: 148,
	},
	{
		garage_id: 127,
		part_id: 240,
		quantity: 187,
	},
	{
		garage_id: 127,
		part_id: 241,
		quantity: 165,
	},
	{
		garage_id: 127,
		part_id: 242,
		quantity: 180,
	},
	{
		garage_id: 127,
		part_id: 243,
		quantity: 375,
	},
	{
		garage_id: 127,
		part_id: 244,
		quantity: 406,
	},
	{
		garage_id: 127,
		part_id: 245,
		quantity: 484,
	},
	{
		garage_id: 127,
		part_id: 246,
		quantity: 371,
	},
	{
		garage_id: 127,
		part_id: 247,
		quantity: 456,
	},
	{
		garage_id: 127,
		part_id: 248,
		quantity: 138,
	},
	{
		garage_id: 127,
		part_id: 249,
		quantity: 97,
	},
	{
		garage_id: 127,
		part_id: 250,
		quantity: 377,
	},
	{
		garage_id: 127,
		part_id: 251,
		quantity: 75,
	},
	{
		garage_id: 127,
		part_id: 252,
		quantity: 382,
	},
	{
		garage_id: 127,
		part_id: 253,
		quantity: 12,
	},
	{
		garage_id: 127,
		part_id: 254,
		quantity: 381,
	},
	{
		garage_id: 127,
		part_id: 255,
		quantity: 488,
	},
	{
		garage_id: 127,
		part_id: 256,
		quantity: 172,
	},
	{
		garage_id: 127,
		part_id: 257,
		quantity: 492,
	},
	{
		garage_id: 127,
		part_id: 258,
		quantity: 151,
	},
	{
		garage_id: 127,
		part_id: 259,
		quantity: 413,
	},
	{
		garage_id: 127,
		part_id: 260,
		quantity: 337,
	},
	{
		garage_id: 127,
		part_id: 261,
		quantity: 435,
	},
	{
		garage_id: 127,
		part_id: 262,
		quantity: 451,
	},
	{
		garage_id: 127,
		part_id: 263,
		quantity: 196,
	},
	{
		garage_id: 127,
		part_id: 264,
		quantity: 246,
	},
	{
		garage_id: 127,
		part_id: 265,
		quantity: 19,
	},
	{
		garage_id: 127,
		part_id: 266,
		quantity: 107,
	},
	{
		garage_id: 127,
		part_id: 267,
		quantity: 499,
	},
	{
		garage_id: 127,
		part_id: 268,
		quantity: 274,
	},
	{
		garage_id: 127,
		part_id: 269,
		quantity: 230,
	},
	{
		garage_id: 127,
		part_id: 270,
		quantity: 1,
	},
	{
		garage_id: 127,
		part_id: 271,
		quantity: 468,
	},
	{
		garage_id: 127,
		part_id: 272,
		quantity: 223,
	},
	{
		garage_id: 127,
		part_id: 273,
		quantity: 422,
	},
	{
		garage_id: 127,
		part_id: 274,
		quantity: 276,
	},
	{
		garage_id: 127,
		part_id: 275,
		quantity: 263,
	},
	{
		garage_id: 127,
		part_id: 276,
		quantity: 421,
	},
	{
		garage_id: 127,
		part_id: 277,
		quantity: 464,
	},
	{
		garage_id: 127,
		part_id: 278,
		quantity: 80,
	},
	{
		garage_id: 127,
		part_id: 279,
		quantity: 316,
	},
	{
		garage_id: 127,
		part_id: 280,
		quantity: 263,
	},
	{
		garage_id: 127,
		part_id: 281,
		quantity: 462,
	},
	{
		garage_id: 127,
		part_id: 282,
		quantity: 390,
	},
	{
		garage_id: 127,
		part_id: 283,
		quantity: 375,
	},
	{
		garage_id: 127,
		part_id: 284,
		quantity: 181,
	},
	{
		garage_id: 127,
		part_id: 285,
		quantity: 321,
	},
	{
		garage_id: 127,
		part_id: 286,
		quantity: 246,
	},
	{
		garage_id: 127,
		part_id: 287,
		quantity: 453,
	},
	{
		garage_id: 127,
		part_id: 288,
		quantity: 444,
	},
	{
		garage_id: 127,
		part_id: 289,
		quantity: 198,
	},
	{
		garage_id: 127,
		part_id: 290,
		quantity: 495,
	},
	{
		garage_id: 127,
		part_id: 291,
		quantity: 488,
	},
	{
		garage_id: 127,
		part_id: 292,
		quantity: 405,
	},
	{
		garage_id: 127,
		part_id: 293,
		quantity: 139,
	},
	{
		garage_id: 127,
		part_id: 294,
		quantity: 352,
	},
	{
		garage_id: 127,
		part_id: 295,
		quantity: 150,
	},
	{
		garage_id: 127,
		part_id: 296,
		quantity: 12,
	},
	{
		garage_id: 127,
		part_id: 297,
		quantity: 99,
	},
	{
		garage_id: 127,
		part_id: 298,
		quantity: 48,
	},
	{
		garage_id: 127,
		part_id: 299,
		quantity: 375,
	},
	{
		garage_id: 127,
		part_id: 300,
		quantity: 164,
	},
	{
		garage_id: 127,
		part_id: 301,
		quantity: 212,
	},
	{
		garage_id: 127,
		part_id: 302,
		quantity: 106,
	},
	{
		garage_id: 127,
		part_id: 303,
		quantity: 338,
	},
	{
		garage_id: 127,
		part_id: 304,
		quantity: 403,
	},
	{
		garage_id: 127,
		part_id: 305,
		quantity: 473,
	},
	{
		garage_id: 127,
		part_id: 306,
		quantity: 297,
	},
	{
		garage_id: 127,
		part_id: 307,
		quantity: 201,
	},
	{
		garage_id: 127,
		part_id: 308,
		quantity: 439,
	},
	{
		garage_id: 127,
		part_id: 309,
		quantity: 220,
	},
	{
		garage_id: 127,
		part_id: 310,
		quantity: 28,
	},
	{
		garage_id: 127,
		part_id: 311,
		quantity: 413,
	},
	{
		garage_id: 127,
		part_id: 312,
		quantity: 305,
	},
	{
		garage_id: 127,
		part_id: 313,
		quantity: 440,
	},
	{
		garage_id: 127,
		part_id: 314,
		quantity: 471,
	},
	{
		garage_id: 127,
		part_id: 315,
		quantity: 457,
	},
	{
		garage_id: 127,
		part_id: 316,
		quantity: 104,
	},
	{
		garage_id: 127,
		part_id: 317,
		quantity: 325,
	},
	{
		garage_id: 127,
		part_id: 318,
		quantity: 222,
	},
	{
		garage_id: 127,
		part_id: 319,
		quantity: 104,
	},
	{
		garage_id: 127,
		part_id: 320,
		quantity: 60,
	},
	{
		garage_id: 127,
		part_id: 321,
		quantity: 363,
	},
	{
		garage_id: 127,
		part_id: 322,
		quantity: 469,
	},
	{
		garage_id: 127,
		part_id: 323,
		quantity: 246,
	},
	{
		garage_id: 127,
		part_id: 324,
		quantity: 486,
	},
	{
		garage_id: 127,
		part_id: 325,
		quantity: 422,
	},
	{
		garage_id: 127,
		part_id: 326,
		quantity: 320,
	},
	{
		garage_id: 127,
		part_id: 327,
		quantity: 158,
	},
	{
		garage_id: 127,
		part_id: 328,
		quantity: 489,
	},
	{
		garage_id: 127,
		part_id: 329,
		quantity: 84,
	},
	{
		garage_id: 127,
		part_id: 330,
		quantity: 165,
	},
	{
		garage_id: 127,
		part_id: 331,
		quantity: 20,
	},
	{
		garage_id: 127,
		part_id: 332,
		quantity: 385,
	},
	{
		garage_id: 127,
		part_id: 333,
		quantity: 456,
	},
	{
		garage_id: 127,
		part_id: 334,
		quantity: 231,
	},
	{
		garage_id: 127,
		part_id: 335,
		quantity: 393,
	},
	{
		garage_id: 127,
		part_id: 336,
		quantity: 457,
	},
	{
		garage_id: 127,
		part_id: 337,
		quantity: 48,
	},
	{
		garage_id: 127,
		part_id: 338,
		quantity: 67,
	},
	{
		garage_id: 127,
		part_id: 339,
		quantity: 407,
	},
	{
		garage_id: 127,
		part_id: 340,
		quantity: 309,
	},
	{
		garage_id: 127,
		part_id: 341,
		quantity: 324,
	},
	{
		garage_id: 127,
		part_id: 342,
		quantity: 105,
	},
	{
		garage_id: 127,
		part_id: 343,
		quantity: 346,
	},
	{
		garage_id: 127,
		part_id: 344,
		quantity: 459,
	},
	{
		garage_id: 127,
		part_id: 345,
		quantity: 195,
	},
	{
		garage_id: 127,
		part_id: 346,
		quantity: 337,
	},
	{
		garage_id: 127,
		part_id: 347,
		quantity: 426,
	},
	{
		garage_id: 127,
		part_id: 348,
		quantity: 223,
	},
	{
		garage_id: 127,
		part_id: 349,
		quantity: 327,
	},
	{
		garage_id: 127,
		part_id: 350,
		quantity: 247,
	},
	{
		garage_id: 127,
		part_id: 351,
		quantity: 96,
	},
	{
		garage_id: 127,
		part_id: 352,
		quantity: 66,
	},
	{
		garage_id: 127,
		part_id: 353,
		quantity: 490,
	},
	{
		garage_id: 127,
		part_id: 354,
		quantity: 248,
	},
	{
		garage_id: 127,
		part_id: 355,
		quantity: 44,
	},
	{
		garage_id: 127,
		part_id: 356,
		quantity: 478,
	},
	{
		garage_id: 127,
		part_id: 357,
		quantity: 150,
	},
	{
		garage_id: 127,
		part_id: 358,
		quantity: 190,
	},
	{
		garage_id: 127,
		part_id: 359,
		quantity: 346,
	},
	{
		garage_id: 127,
		part_id: 360,
		quantity: 294,
	},
	{
		garage_id: 127,
		part_id: 361,
		quantity: 427,
	},
	{
		garage_id: 127,
		part_id: 362,
		quantity: 16,
	},
	{
		garage_id: 127,
		part_id: 363,
		quantity: 394,
	},
	{
		garage_id: 127,
		part_id: 364,
		quantity: 61,
	},
	{
		garage_id: 127,
		part_id: 365,
		quantity: 422,
	},
	{
		garage_id: 127,
		part_id: 366,
		quantity: 40,
	},
	{
		garage_id: 127,
		part_id: 367,
		quantity: 393,
	},
	{
		garage_id: 127,
		part_id: 368,
		quantity: 138,
	},
	{
		garage_id: 127,
		part_id: 369,
		quantity: 309,
	},
	{
		garage_id: 127,
		part_id: 370,
		quantity: 445,
	},
	{
		garage_id: 127,
		part_id: 371,
		quantity: 91,
	},
	{
		garage_id: 127,
		part_id: 372,
		quantity: 137,
	},
	{
		garage_id: 127,
		part_id: 373,
		quantity: 250,
	},
	{
		garage_id: 127,
		part_id: 374,
		quantity: 472,
	},
	{
		garage_id: 127,
		part_id: 375,
		quantity: 214,
	},
	{
		garage_id: 127,
		part_id: 376,
		quantity: 290,
	},
	{
		garage_id: 127,
		part_id: 377,
		quantity: 116,
	},
	{
		garage_id: 127,
		part_id: 378,
		quantity: 498,
	},
	{
		garage_id: 127,
		part_id: 379,
		quantity: 432,
	},
	{
		garage_id: 127,
		part_id: 380,
		quantity: 487,
	},
	{
		garage_id: 127,
		part_id: 381,
		quantity: 7,
	},
	{
		garage_id: 127,
		part_id: 382,
		quantity: 300,
	},
	{
		garage_id: 127,
		part_id: 383,
		quantity: 236,
	},
	{
		garage_id: 127,
		part_id: 384,
		quantity: 316,
	},
	{
		garage_id: 127,
		part_id: 385,
		quantity: 14,
	},
	{
		garage_id: 127,
		part_id: 386,
		quantity: 17,
	},
	{
		garage_id: 127,
		part_id: 387,
		quantity: 242,
	},
	{
		garage_id: 127,
		part_id: 388,
		quantity: 114,
	},
	{
		garage_id: 127,
		part_id: 389,
		quantity: 366,
	},
	{
		garage_id: 127,
		part_id: 390,
		quantity: 290,
	},
	{
		garage_id: 127,
		part_id: 391,
		quantity: 358,
	},
	{
		garage_id: 127,
		part_id: 392,
		quantity: 91,
	},
	{
		garage_id: 127,
		part_id: 393,
		quantity: 381,
	},
	{
		garage_id: 127,
		part_id: 394,
		quantity: 453,
	},
	{
		garage_id: 127,
		part_id: 395,
		quantity: 266,
	},
	{
		garage_id: 127,
		part_id: 396,
		quantity: 306,
	},
	{
		garage_id: 127,
		part_id: 397,
		quantity: 419,
	},
	{
		garage_id: 127,
		part_id: 398,
		quantity: 418,
	},
	{
		garage_id: 127,
		part_id: 399,
		quantity: 243,
	},
	{
		garage_id: 128,
		part_id: 0,
		quantity: 306,
	},
	{
		garage_id: 128,
		part_id: 1,
		quantity: 342,
	},
	{
		garage_id: 128,
		part_id: 2,
		quantity: 82,
	},
	{
		garage_id: 128,
		part_id: 3,
		quantity: 91,
	},
	{
		garage_id: 128,
		part_id: 4,
		quantity: 388,
	},
	{
		garage_id: 128,
		part_id: 5,
		quantity: 226,
	},
	{
		garage_id: 128,
		part_id: 6,
		quantity: 350,
	},
	{
		garage_id: 128,
		part_id: 7,
		quantity: 493,
	},
	{
		garage_id: 128,
		part_id: 8,
		quantity: 6,
	},
	{
		garage_id: 128,
		part_id: 9,
		quantity: 7,
	},
	{
		garage_id: 128,
		part_id: 10,
		quantity: 285,
	},
	{
		garage_id: 128,
		part_id: 11,
		quantity: 241,
	},
	{
		garage_id: 128,
		part_id: 12,
		quantity: 155,
	},
	{
		garage_id: 128,
		part_id: 13,
		quantity: 32,
	},
	{
		garage_id: 128,
		part_id: 14,
		quantity: 228,
	},
	{
		garage_id: 128,
		part_id: 15,
		quantity: 85,
	},
	{
		garage_id: 128,
		part_id: 16,
		quantity: 203,
	},
	{
		garage_id: 128,
		part_id: 17,
		quantity: 33,
	},
	{
		garage_id: 128,
		part_id: 18,
		quantity: 316,
	},
	{
		garage_id: 128,
		part_id: 19,
		quantity: 138,
	},
	{
		garage_id: 128,
		part_id: 20,
		quantity: 6,
	},
	{
		garage_id: 128,
		part_id: 21,
		quantity: 216,
	},
	{
		garage_id: 128,
		part_id: 22,
		quantity: 289,
	},
	{
		garage_id: 128,
		part_id: 23,
		quantity: 386,
	},
	{
		garage_id: 128,
		part_id: 24,
		quantity: 299,
	},
	{
		garage_id: 128,
		part_id: 25,
		quantity: 20,
	},
	{
		garage_id: 128,
		part_id: 26,
		quantity: 324,
	},
	{
		garage_id: 128,
		part_id: 27,
		quantity: 362,
	},
	{
		garage_id: 128,
		part_id: 28,
		quantity: 477,
	},
	{
		garage_id: 128,
		part_id: 29,
		quantity: 457,
	},
	{
		garage_id: 128,
		part_id: 30,
		quantity: 180,
	},
	{
		garage_id: 128,
		part_id: 31,
		quantity: 358,
	},
	{
		garage_id: 128,
		part_id: 32,
		quantity: 454,
	},
	{
		garage_id: 128,
		part_id: 33,
		quantity: 72,
	},
	{
		garage_id: 128,
		part_id: 34,
		quantity: 186,
	},
	{
		garage_id: 128,
		part_id: 35,
		quantity: 394,
	},
	{
		garage_id: 128,
		part_id: 36,
		quantity: 289,
	},
	{
		garage_id: 128,
		part_id: 37,
		quantity: 233,
	},
	{
		garage_id: 128,
		part_id: 38,
		quantity: 292,
	},
	{
		garage_id: 128,
		part_id: 39,
		quantity: 128,
	},
	{
		garage_id: 128,
		part_id: 40,
		quantity: 163,
	},
	{
		garage_id: 128,
		part_id: 41,
		quantity: 408,
	},
	{
		garage_id: 128,
		part_id: 42,
		quantity: 482,
	},
	{
		garage_id: 128,
		part_id: 43,
		quantity: 364,
	},
	{
		garage_id: 128,
		part_id: 44,
		quantity: 150,
	},
	{
		garage_id: 128,
		part_id: 45,
		quantity: 115,
	},
	{
		garage_id: 128,
		part_id: 46,
		quantity: 243,
	},
	{
		garage_id: 128,
		part_id: 47,
		quantity: 147,
	},
	{
		garage_id: 128,
		part_id: 48,
		quantity: 209,
	},
	{
		garage_id: 128,
		part_id: 49,
		quantity: 200,
	},
	{
		garage_id: 128,
		part_id: 50,
		quantity: 236,
	},
	{
		garage_id: 128,
		part_id: 51,
		quantity: 361,
	},
	{
		garage_id: 128,
		part_id: 52,
		quantity: 371,
	},
	{
		garage_id: 128,
		part_id: 53,
		quantity: 282,
	},
	{
		garage_id: 128,
		part_id: 54,
		quantity: 457,
	},
	{
		garage_id: 128,
		part_id: 55,
		quantity: 216,
	},
	{
		garage_id: 128,
		part_id: 56,
		quantity: 122,
	},
	{
		garage_id: 128,
		part_id: 57,
		quantity: 367,
	},
	{
		garage_id: 128,
		part_id: 58,
		quantity: 51,
	},
	{
		garage_id: 128,
		part_id: 59,
		quantity: 83,
	},
	{
		garage_id: 128,
		part_id: 60,
		quantity: 435,
	},
	{
		garage_id: 128,
		part_id: 61,
		quantity: 12,
	},
	{
		garage_id: 128,
		part_id: 62,
		quantity: 323,
	},
	{
		garage_id: 128,
		part_id: 63,
		quantity: 348,
	},
	{
		garage_id: 128,
		part_id: 64,
		quantity: 349,
	},
	{
		garage_id: 128,
		part_id: 65,
		quantity: 135,
	},
	{
		garage_id: 128,
		part_id: 66,
		quantity: 271,
	},
	{
		garage_id: 128,
		part_id: 67,
		quantity: 289,
	},
	{
		garage_id: 128,
		part_id: 68,
		quantity: 241,
	},
	{
		garage_id: 128,
		part_id: 69,
		quantity: 483,
	},
	{
		garage_id: 128,
		part_id: 70,
		quantity: 414,
	},
	{
		garage_id: 128,
		part_id: 71,
		quantity: 223,
	},
	{
		garage_id: 128,
		part_id: 72,
		quantity: 434,
	},
	{
		garage_id: 128,
		part_id: 73,
		quantity: 259,
	},
	{
		garage_id: 128,
		part_id: 74,
		quantity: 253,
	},
	{
		garage_id: 128,
		part_id: 75,
		quantity: 164,
	},
	{
		garage_id: 128,
		part_id: 76,
		quantity: 142,
	},
	{
		garage_id: 128,
		part_id: 77,
		quantity: 236,
	},
	{
		garage_id: 128,
		part_id: 78,
		quantity: 127,
	},
	{
		garage_id: 128,
		part_id: 79,
		quantity: 120,
	},
	{
		garage_id: 128,
		part_id: 80,
		quantity: 433,
	},
	{
		garage_id: 128,
		part_id: 81,
		quantity: 312,
	},
	{
		garage_id: 128,
		part_id: 82,
		quantity: 354,
	},
	{
		garage_id: 128,
		part_id: 83,
		quantity: 1,
	},
	{
		garage_id: 128,
		part_id: 84,
		quantity: 101,
	},
	{
		garage_id: 128,
		part_id: 85,
		quantity: 233,
	},
	{
		garage_id: 128,
		part_id: 86,
		quantity: 380,
	},
	{
		garage_id: 128,
		part_id: 87,
		quantity: 280,
	},
	{
		garage_id: 128,
		part_id: 88,
		quantity: 143,
	},
	{
		garage_id: 128,
		part_id: 89,
		quantity: 393,
	},
	{
		garage_id: 128,
		part_id: 90,
		quantity: 484,
	},
	{
		garage_id: 128,
		part_id: 91,
		quantity: 143,
	},
	{
		garage_id: 128,
		part_id: 92,
		quantity: 405,
	},
	{
		garage_id: 128,
		part_id: 93,
		quantity: 41,
	},
	{
		garage_id: 128,
		part_id: 94,
		quantity: 416,
	},
	{
		garage_id: 128,
		part_id: 95,
		quantity: 52,
	},
	{
		garage_id: 128,
		part_id: 96,
		quantity: 215,
	},
	{
		garage_id: 128,
		part_id: 97,
		quantity: 61,
	},
	{
		garage_id: 128,
		part_id: 98,
		quantity: 56,
	},
	{
		garage_id: 128,
		part_id: 99,
		quantity: 123,
	},
	{
		garage_id: 128,
		part_id: 100,
		quantity: 27,
	},
	{
		garage_id: 128,
		part_id: 101,
		quantity: 214,
	},
	{
		garage_id: 128,
		part_id: 102,
		quantity: 245,
	},
	{
		garage_id: 128,
		part_id: 103,
		quantity: 161,
	},
	{
		garage_id: 128,
		part_id: 104,
		quantity: 59,
	},
	{
		garage_id: 128,
		part_id: 105,
		quantity: 72,
	},
	{
		garage_id: 128,
		part_id: 106,
		quantity: 44,
	},
	{
		garage_id: 128,
		part_id: 107,
		quantity: 273,
	},
	{
		garage_id: 128,
		part_id: 108,
		quantity: 433,
	},
	{
		garage_id: 128,
		part_id: 109,
		quantity: 313,
	},
	{
		garage_id: 128,
		part_id: 110,
		quantity: 392,
	},
	{
		garage_id: 128,
		part_id: 111,
		quantity: 141,
	},
	{
		garage_id: 128,
		part_id: 112,
		quantity: 98,
	},
	{
		garage_id: 128,
		part_id: 113,
		quantity: 283,
	},
	{
		garage_id: 128,
		part_id: 114,
		quantity: 329,
	},
	{
		garage_id: 128,
		part_id: 115,
		quantity: 133,
	},
	{
		garage_id: 128,
		part_id: 116,
		quantity: 302,
	},
	{
		garage_id: 128,
		part_id: 117,
		quantity: 236,
	},
	{
		garage_id: 128,
		part_id: 118,
		quantity: 8,
	},
	{
		garage_id: 128,
		part_id: 119,
		quantity: 68,
	},
	{
		garage_id: 128,
		part_id: 120,
		quantity: 493,
	},
	{
		garage_id: 128,
		part_id: 121,
		quantity: 131,
	},
	{
		garage_id: 128,
		part_id: 122,
		quantity: 400,
	},
	{
		garage_id: 128,
		part_id: 123,
		quantity: 474,
	},
	{
		garage_id: 128,
		part_id: 124,
		quantity: 424,
	},
	{
		garage_id: 128,
		part_id: 125,
		quantity: 198,
	},
	{
		garage_id: 128,
		part_id: 126,
		quantity: 436,
	},
	{
		garage_id: 128,
		part_id: 127,
		quantity: 17,
	},
	{
		garage_id: 128,
		part_id: 128,
		quantity: 53,
	},
	{
		garage_id: 128,
		part_id: 129,
		quantity: 451,
	},
	{
		garage_id: 128,
		part_id: 130,
		quantity: 110,
	},
	{
		garage_id: 128,
		part_id: 131,
		quantity: 189,
	},
	{
		garage_id: 128,
		part_id: 132,
		quantity: 112,
	},
	{
		garage_id: 128,
		part_id: 133,
		quantity: 154,
	},
	{
		garage_id: 128,
		part_id: 134,
		quantity: 362,
	},
	{
		garage_id: 128,
		part_id: 135,
		quantity: 420,
	},
	{
		garage_id: 128,
		part_id: 136,
		quantity: 191,
	},
	{
		garage_id: 128,
		part_id: 137,
		quantity: 344,
	},
	{
		garage_id: 128,
		part_id: 138,
		quantity: 90,
	},
	{
		garage_id: 128,
		part_id: 139,
		quantity: 309,
	},
	{
		garage_id: 128,
		part_id: 140,
		quantity: 137,
	},
	{
		garage_id: 128,
		part_id: 141,
		quantity: 315,
	},
	{
		garage_id: 128,
		part_id: 142,
		quantity: 254,
	},
	{
		garage_id: 128,
		part_id: 143,
		quantity: 165,
	},
	{
		garage_id: 128,
		part_id: 144,
		quantity: 289,
	},
	{
		garage_id: 128,
		part_id: 145,
		quantity: 112,
	},
	{
		garage_id: 128,
		part_id: 146,
		quantity: 28,
	},
	{
		garage_id: 128,
		part_id: 147,
		quantity: 14,
	},
	{
		garage_id: 128,
		part_id: 148,
		quantity: 127,
	},
	{
		garage_id: 128,
		part_id: 149,
		quantity: 487,
	},
	{
		garage_id: 128,
		part_id: 150,
		quantity: 363,
	},
	{
		garage_id: 128,
		part_id: 151,
		quantity: 204,
	},
	{
		garage_id: 128,
		part_id: 152,
		quantity: 126,
	},
	{
		garage_id: 128,
		part_id: 153,
		quantity: 262,
	},
	{
		garage_id: 128,
		part_id: 154,
		quantity: 16,
	},
	{
		garage_id: 128,
		part_id: 155,
		quantity: 87,
	},
	{
		garage_id: 128,
		part_id: 156,
		quantity: 295,
	},
	{
		garage_id: 128,
		part_id: 157,
		quantity: 69,
	},
	{
		garage_id: 128,
		part_id: 158,
		quantity: 336,
	},
	{
		garage_id: 128,
		part_id: 159,
		quantity: 252,
	},
	{
		garage_id: 128,
		part_id: 160,
		quantity: 480,
	},
	{
		garage_id: 128,
		part_id: 161,
		quantity: 248,
	},
	{
		garage_id: 128,
		part_id: 162,
		quantity: 212,
	},
	{
		garage_id: 128,
		part_id: 163,
		quantity: 34,
	},
	{
		garage_id: 128,
		part_id: 164,
		quantity: 5,
	},
	{
		garage_id: 128,
		part_id: 165,
		quantity: 29,
	},
	{
		garage_id: 128,
		part_id: 166,
		quantity: 66,
	},
	{
		garage_id: 128,
		part_id: 167,
		quantity: 475,
	},
	{
		garage_id: 128,
		part_id: 168,
		quantity: 221,
	},
	{
		garage_id: 128,
		part_id: 169,
		quantity: 14,
	},
	{
		garage_id: 128,
		part_id: 170,
		quantity: 332,
	},
	{
		garage_id: 128,
		part_id: 171,
		quantity: 421,
	},
	{
		garage_id: 128,
		part_id: 172,
		quantity: 139,
	},
	{
		garage_id: 128,
		part_id: 173,
		quantity: 310,
	},
	{
		garage_id: 128,
		part_id: 174,
		quantity: 246,
	},
	{
		garage_id: 128,
		part_id: 175,
		quantity: 445,
	},
	{
		garage_id: 128,
		part_id: 176,
		quantity: 195,
	},
	{
		garage_id: 128,
		part_id: 177,
		quantity: 431,
	},
	{
		garage_id: 128,
		part_id: 178,
		quantity: 301,
	},
	{
		garage_id: 128,
		part_id: 179,
		quantity: 392,
	},
	{
		garage_id: 128,
		part_id: 180,
		quantity: 133,
	},
	{
		garage_id: 128,
		part_id: 181,
		quantity: 376,
	},
	{
		garage_id: 128,
		part_id: 182,
		quantity: 139,
	},
	{
		garage_id: 128,
		part_id: 183,
		quantity: 450,
	},
	{
		garage_id: 128,
		part_id: 184,
		quantity: 194,
	},
	{
		garage_id: 128,
		part_id: 185,
		quantity: 476,
	},
	{
		garage_id: 128,
		part_id: 186,
		quantity: 185,
	},
	{
		garage_id: 128,
		part_id: 187,
		quantity: 195,
	},
	{
		garage_id: 128,
		part_id: 188,
		quantity: 355,
	},
	{
		garage_id: 128,
		part_id: 189,
		quantity: 427,
	},
	{
		garage_id: 128,
		part_id: 190,
		quantity: 172,
	},
	{
		garage_id: 128,
		part_id: 191,
		quantity: 125,
	},
	{
		garage_id: 128,
		part_id: 192,
		quantity: 434,
	},
	{
		garage_id: 128,
		part_id: 193,
		quantity: 230,
	},
	{
		garage_id: 128,
		part_id: 194,
		quantity: 52,
	},
	{
		garage_id: 128,
		part_id: 195,
		quantity: 11,
	},
	{
		garage_id: 128,
		part_id: 196,
		quantity: 285,
	},
	{
		garage_id: 128,
		part_id: 197,
		quantity: 237,
	},
	{
		garage_id: 128,
		part_id: 198,
		quantity: 142,
	},
	{
		garage_id: 128,
		part_id: 199,
		quantity: 392,
	},
	{
		garage_id: 128,
		part_id: 200,
		quantity: 476,
	},
	{
		garage_id: 128,
		part_id: 201,
		quantity: 479,
	},
	{
		garage_id: 128,
		part_id: 202,
		quantity: 339,
	},
	{
		garage_id: 128,
		part_id: 203,
		quantity: 346,
	},
	{
		garage_id: 128,
		part_id: 204,
		quantity: 90,
	},
	{
		garage_id: 128,
		part_id: 205,
		quantity: 155,
	},
	{
		garage_id: 128,
		part_id: 206,
		quantity: 145,
	},
	{
		garage_id: 128,
		part_id: 207,
		quantity: 122,
	},
	{
		garage_id: 128,
		part_id: 208,
		quantity: 162,
	},
	{
		garage_id: 128,
		part_id: 209,
		quantity: 495,
	},
	{
		garage_id: 128,
		part_id: 210,
		quantity: 489,
	},
	{
		garage_id: 128,
		part_id: 211,
		quantity: 362,
	},
	{
		garage_id: 128,
		part_id: 212,
		quantity: 168,
	},
	{
		garage_id: 128,
		part_id: 213,
		quantity: 357,
	},
	{
		garage_id: 128,
		part_id: 214,
		quantity: 256,
	},
	{
		garage_id: 128,
		part_id: 215,
		quantity: 351,
	},
	{
		garage_id: 128,
		part_id: 216,
		quantity: 404,
	},
	{
		garage_id: 128,
		part_id: 217,
		quantity: 188,
	},
	{
		garage_id: 128,
		part_id: 218,
		quantity: 228,
	},
	{
		garage_id: 128,
		part_id: 219,
		quantity: 35,
	},
	{
		garage_id: 128,
		part_id: 220,
		quantity: 204,
	},
	{
		garage_id: 128,
		part_id: 221,
		quantity: 319,
	},
	{
		garage_id: 128,
		part_id: 222,
		quantity: 140,
	},
	{
		garage_id: 128,
		part_id: 223,
		quantity: 164,
	},
	{
		garage_id: 128,
		part_id: 224,
		quantity: 73,
	},
	{
		garage_id: 128,
		part_id: 225,
		quantity: 442,
	},
	{
		garage_id: 128,
		part_id: 226,
		quantity: 259,
	},
	{
		garage_id: 128,
		part_id: 227,
		quantity: 258,
	},
	{
		garage_id: 128,
		part_id: 228,
		quantity: 75,
	},
	{
		garage_id: 128,
		part_id: 229,
		quantity: 412,
	},
	{
		garage_id: 128,
		part_id: 230,
		quantity: 450,
	},
	{
		garage_id: 128,
		part_id: 231,
		quantity: 439,
	},
	{
		garage_id: 128,
		part_id: 232,
		quantity: 362,
	},
	{
		garage_id: 128,
		part_id: 233,
		quantity: 38,
	},
	{
		garage_id: 128,
		part_id: 234,
		quantity: 26,
	},
	{
		garage_id: 128,
		part_id: 235,
		quantity: 283,
	},
	{
		garage_id: 128,
		part_id: 236,
		quantity: 478,
	},
	{
		garage_id: 128,
		part_id: 237,
		quantity: 449,
	},
	{
		garage_id: 128,
		part_id: 238,
		quantity: 167,
	},
	{
		garage_id: 128,
		part_id: 239,
		quantity: 464,
	},
	{
		garage_id: 128,
		part_id: 240,
		quantity: 96,
	},
	{
		garage_id: 128,
		part_id: 241,
		quantity: 296,
	},
	{
		garage_id: 128,
		part_id: 242,
		quantity: 126,
	},
	{
		garage_id: 128,
		part_id: 243,
		quantity: 372,
	},
	{
		garage_id: 128,
		part_id: 244,
		quantity: 57,
	},
	{
		garage_id: 128,
		part_id: 245,
		quantity: 370,
	},
	{
		garage_id: 128,
		part_id: 246,
		quantity: 212,
	},
	{
		garage_id: 128,
		part_id: 247,
		quantity: 196,
	},
	{
		garage_id: 128,
		part_id: 248,
		quantity: 456,
	},
	{
		garage_id: 128,
		part_id: 249,
		quantity: 63,
	},
	{
		garage_id: 128,
		part_id: 250,
		quantity: 431,
	},
	{
		garage_id: 128,
		part_id: 251,
		quantity: 37,
	},
	{
		garage_id: 128,
		part_id: 252,
		quantity: 32,
	},
	{
		garage_id: 128,
		part_id: 253,
		quantity: 55,
	},
	{
		garage_id: 128,
		part_id: 254,
		quantity: 15,
	},
	{
		garage_id: 128,
		part_id: 255,
		quantity: 104,
	},
	{
		garage_id: 128,
		part_id: 256,
		quantity: 270,
	},
	{
		garage_id: 128,
		part_id: 257,
		quantity: 68,
	},
	{
		garage_id: 128,
		part_id: 258,
		quantity: 92,
	},
	{
		garage_id: 128,
		part_id: 259,
		quantity: 187,
	},
	{
		garage_id: 128,
		part_id: 260,
		quantity: 354,
	},
	{
		garage_id: 128,
		part_id: 261,
		quantity: 256,
	},
	{
		garage_id: 128,
		part_id: 262,
		quantity: 218,
	},
	{
		garage_id: 128,
		part_id: 263,
		quantity: 178,
	},
	{
		garage_id: 128,
		part_id: 264,
		quantity: 411,
	},
	{
		garage_id: 128,
		part_id: 265,
		quantity: 18,
	},
	{
		garage_id: 128,
		part_id: 266,
		quantity: 207,
	},
	{
		garage_id: 128,
		part_id: 267,
		quantity: 480,
	},
	{
		garage_id: 128,
		part_id: 268,
		quantity: 429,
	},
	{
		garage_id: 128,
		part_id: 269,
		quantity: 456,
	},
	{
		garage_id: 128,
		part_id: 270,
		quantity: 262,
	},
	{
		garage_id: 128,
		part_id: 271,
		quantity: 25,
	},
	{
		garage_id: 128,
		part_id: 272,
		quantity: 37,
	},
	{
		garage_id: 128,
		part_id: 273,
		quantity: 392,
	},
	{
		garage_id: 128,
		part_id: 274,
		quantity: 120,
	},
	{
		garage_id: 128,
		part_id: 275,
		quantity: 301,
	},
	{
		garage_id: 128,
		part_id: 276,
		quantity: 45,
	},
	{
		garage_id: 128,
		part_id: 277,
		quantity: 171,
	},
	{
		garage_id: 128,
		part_id: 278,
		quantity: 355,
	},
	{
		garage_id: 128,
		part_id: 279,
		quantity: 424,
	},
	{
		garage_id: 128,
		part_id: 280,
		quantity: 353,
	},
	{
		garage_id: 128,
		part_id: 281,
		quantity: 53,
	},
	{
		garage_id: 128,
		part_id: 282,
		quantity: 32,
	},
	{
		garage_id: 128,
		part_id: 283,
		quantity: 61,
	},
	{
		garage_id: 128,
		part_id: 284,
		quantity: 27,
	},
	{
		garage_id: 128,
		part_id: 285,
		quantity: 493,
	},
	{
		garage_id: 128,
		part_id: 286,
		quantity: 361,
	},
	{
		garage_id: 128,
		part_id: 287,
		quantity: 334,
	},
	{
		garage_id: 128,
		part_id: 288,
		quantity: 412,
	},
	{
		garage_id: 128,
		part_id: 289,
		quantity: 61,
	},
	{
		garage_id: 128,
		part_id: 290,
		quantity: 434,
	},
	{
		garage_id: 128,
		part_id: 291,
		quantity: 469,
	},
	{
		garage_id: 128,
		part_id: 292,
		quantity: 229,
	},
	{
		garage_id: 128,
		part_id: 293,
		quantity: 168,
	},
	{
		garage_id: 128,
		part_id: 294,
		quantity: 267,
	},
	{
		garage_id: 128,
		part_id: 295,
		quantity: 128,
	},
	{
		garage_id: 128,
		part_id: 296,
		quantity: 197,
	},
	{
		garage_id: 128,
		part_id: 297,
		quantity: 64,
	},
	{
		garage_id: 128,
		part_id: 298,
		quantity: 211,
	},
	{
		garage_id: 128,
		part_id: 299,
		quantity: 106,
	},
	{
		garage_id: 128,
		part_id: 300,
		quantity: 294,
	},
	{
		garage_id: 128,
		part_id: 301,
		quantity: 232,
	},
	{
		garage_id: 128,
		part_id: 302,
		quantity: 6,
	},
	{
		garage_id: 128,
		part_id: 303,
		quantity: 92,
	},
	{
		garage_id: 128,
		part_id: 304,
		quantity: 133,
	},
	{
		garage_id: 128,
		part_id: 305,
		quantity: 184,
	},
	{
		garage_id: 128,
		part_id: 306,
		quantity: 486,
	},
	{
		garage_id: 128,
		part_id: 307,
		quantity: 274,
	},
	{
		garage_id: 128,
		part_id: 308,
		quantity: 228,
	},
	{
		garage_id: 128,
		part_id: 309,
		quantity: 173,
	},
	{
		garage_id: 128,
		part_id: 310,
		quantity: 277,
	},
	{
		garage_id: 128,
		part_id: 311,
		quantity: 260,
	},
	{
		garage_id: 128,
		part_id: 312,
		quantity: 190,
	},
	{
		garage_id: 128,
		part_id: 313,
		quantity: 240,
	},
	{
		garage_id: 128,
		part_id: 314,
		quantity: 195,
	},
	{
		garage_id: 128,
		part_id: 315,
		quantity: 322,
	},
	{
		garage_id: 128,
		part_id: 316,
		quantity: 329,
	},
	{
		garage_id: 128,
		part_id: 317,
		quantity: 472,
	},
	{
		garage_id: 128,
		part_id: 318,
		quantity: 376,
	},
	{
		garage_id: 128,
		part_id: 319,
		quantity: 297,
	},
	{
		garage_id: 128,
		part_id: 320,
		quantity: 491,
	},
	{
		garage_id: 128,
		part_id: 321,
		quantity: 464,
	},
	{
		garage_id: 128,
		part_id: 322,
		quantity: 328,
	},
	{
		garage_id: 128,
		part_id: 323,
		quantity: 77,
	},
	{
		garage_id: 128,
		part_id: 324,
		quantity: 225,
	},
	{
		garage_id: 128,
		part_id: 325,
		quantity: 59,
	},
	{
		garage_id: 128,
		part_id: 326,
		quantity: 283,
	},
	{
		garage_id: 128,
		part_id: 327,
		quantity: 473,
	},
	{
		garage_id: 128,
		part_id: 328,
		quantity: 172,
	},
	{
		garage_id: 128,
		part_id: 329,
		quantity: 130,
	},
	{
		garage_id: 128,
		part_id: 330,
		quantity: 157,
	},
	{
		garage_id: 128,
		part_id: 331,
		quantity: 29,
	},
	{
		garage_id: 128,
		part_id: 332,
		quantity: 25,
	},
	{
		garage_id: 128,
		part_id: 333,
		quantity: 57,
	},
	{
		garage_id: 128,
		part_id: 334,
		quantity: 290,
	},
	{
		garage_id: 128,
		part_id: 335,
		quantity: 80,
	},
	{
		garage_id: 128,
		part_id: 336,
		quantity: 235,
	},
	{
		garage_id: 128,
		part_id: 337,
		quantity: 487,
	},
	{
		garage_id: 128,
		part_id: 338,
		quantity: 44,
	},
	{
		garage_id: 128,
		part_id: 339,
		quantity: 407,
	},
	{
		garage_id: 128,
		part_id: 340,
		quantity: 376,
	},
	{
		garage_id: 128,
		part_id: 341,
		quantity: 46,
	},
	{
		garage_id: 128,
		part_id: 342,
		quantity: 211,
	},
	{
		garage_id: 128,
		part_id: 343,
		quantity: 158,
	},
	{
		garage_id: 128,
		part_id: 344,
		quantity: 420,
	},
	{
		garage_id: 128,
		part_id: 345,
		quantity: 493,
	},
	{
		garage_id: 128,
		part_id: 346,
		quantity: 283,
	},
	{
		garage_id: 128,
		part_id: 347,
		quantity: 132,
	},
	{
		garage_id: 128,
		part_id: 348,
		quantity: 46,
	},
	{
		garage_id: 128,
		part_id: 349,
		quantity: 423,
	},
	{
		garage_id: 128,
		part_id: 350,
		quantity: 139,
	},
	{
		garage_id: 128,
		part_id: 351,
		quantity: 43,
	},
	{
		garage_id: 128,
		part_id: 352,
		quantity: 231,
	},
	{
		garage_id: 128,
		part_id: 353,
		quantity: 246,
	},
	{
		garage_id: 128,
		part_id: 354,
		quantity: 48,
	},
	{
		garage_id: 128,
		part_id: 355,
		quantity: 374,
	},
	{
		garage_id: 128,
		part_id: 356,
		quantity: 343,
	},
	{
		garage_id: 128,
		part_id: 357,
		quantity: 350,
	},
	{
		garage_id: 128,
		part_id: 358,
		quantity: 251,
	},
	{
		garage_id: 128,
		part_id: 359,
		quantity: 97,
	},
	{
		garage_id: 128,
		part_id: 360,
		quantity: 284,
	},
	{
		garage_id: 128,
		part_id: 361,
		quantity: 183,
	},
	{
		garage_id: 128,
		part_id: 362,
		quantity: 91,
	},
	{
		garage_id: 128,
		part_id: 363,
		quantity: 316,
	},
	{
		garage_id: 128,
		part_id: 364,
		quantity: 90,
	},
	{
		garage_id: 128,
		part_id: 365,
		quantity: 498,
	},
	{
		garage_id: 128,
		part_id: 366,
		quantity: 498,
	},
	{
		garage_id: 128,
		part_id: 367,
		quantity: 194,
	},
	{
		garage_id: 128,
		part_id: 368,
		quantity: 346,
	},
	{
		garage_id: 128,
		part_id: 369,
		quantity: 15,
	},
	{
		garage_id: 128,
		part_id: 370,
		quantity: 339,
	},
	{
		garage_id: 128,
		part_id: 371,
		quantity: 175,
	},
	{
		garage_id: 128,
		part_id: 372,
		quantity: 208,
	},
	{
		garage_id: 128,
		part_id: 373,
		quantity: 479,
	},
	{
		garage_id: 128,
		part_id: 374,
		quantity: 398,
	},
	{
		garage_id: 128,
		part_id: 375,
		quantity: 59,
	},
	{
		garage_id: 128,
		part_id: 376,
		quantity: 9,
	},
	{
		garage_id: 128,
		part_id: 377,
		quantity: 298,
	},
	{
		garage_id: 128,
		part_id: 378,
		quantity: 239,
	},
	{
		garage_id: 128,
		part_id: 379,
		quantity: 159,
	},
	{
		garage_id: 128,
		part_id: 380,
		quantity: 420,
	},
	{
		garage_id: 128,
		part_id: 381,
		quantity: 473,
	},
	{
		garage_id: 128,
		part_id: 382,
		quantity: 476,
	},
	{
		garage_id: 128,
		part_id: 383,
		quantity: 345,
	},
	{
		garage_id: 128,
		part_id: 384,
		quantity: 75,
	},
	{
		garage_id: 128,
		part_id: 385,
		quantity: 308,
	},
	{
		garage_id: 128,
		part_id: 386,
		quantity: 55,
	},
	{
		garage_id: 128,
		part_id: 387,
		quantity: 303,
	},
	{
		garage_id: 128,
		part_id: 388,
		quantity: 490,
	},
	{
		garage_id: 128,
		part_id: 389,
		quantity: 435,
	},
	{
		garage_id: 128,
		part_id: 390,
		quantity: 82,
	},
	{
		garage_id: 128,
		part_id: 391,
		quantity: 116,
	},
	{
		garage_id: 128,
		part_id: 392,
		quantity: 217,
	},
	{
		garage_id: 128,
		part_id: 393,
		quantity: 490,
	},
	{
		garage_id: 128,
		part_id: 394,
		quantity: 386,
	},
	{
		garage_id: 128,
		part_id: 395,
		quantity: 140,
	},
	{
		garage_id: 128,
		part_id: 396,
		quantity: 166,
	},
	{
		garage_id: 128,
		part_id: 397,
		quantity: 465,
	},
	{
		garage_id: 128,
		part_id: 398,
		quantity: 0,
	},
	{
		garage_id: 128,
		part_id: 399,
		quantity: 196,
	},
	{
		garage_id: 129,
		part_id: 0,
		quantity: 47,
	},
	{
		garage_id: 129,
		part_id: 1,
		quantity: 86,
	},
	{
		garage_id: 129,
		part_id: 2,
		quantity: 168,
	},
	{
		garage_id: 129,
		part_id: 3,
		quantity: 452,
	},
	{
		garage_id: 129,
		part_id: 4,
		quantity: 323,
	},
	{
		garage_id: 129,
		part_id: 5,
		quantity: 276,
	},
	{
		garage_id: 129,
		part_id: 6,
		quantity: 281,
	},
	{
		garage_id: 129,
		part_id: 7,
		quantity: 48,
	},
	{
		garage_id: 129,
		part_id: 8,
		quantity: 432,
	},
	{
		garage_id: 129,
		part_id: 9,
		quantity: 23,
	},
	{
		garage_id: 129,
		part_id: 10,
		quantity: 446,
	},
	{
		garage_id: 129,
		part_id: 11,
		quantity: 199,
	},
	{
		garage_id: 129,
		part_id: 12,
		quantity: 222,
	},
	{
		garage_id: 129,
		part_id: 13,
		quantity: 418,
	},
	{
		garage_id: 129,
		part_id: 14,
		quantity: 351,
	},
	{
		garage_id: 129,
		part_id: 15,
		quantity: 299,
	},
	{
		garage_id: 129,
		part_id: 16,
		quantity: 304,
	},
	{
		garage_id: 129,
		part_id: 17,
		quantity: 420,
	},
	{
		garage_id: 129,
		part_id: 18,
		quantity: 45,
	},
	{
		garage_id: 129,
		part_id: 19,
		quantity: 233,
	},
	{
		garage_id: 129,
		part_id: 20,
		quantity: 415,
	},
	{
		garage_id: 129,
		part_id: 21,
		quantity: 371,
	},
	{
		garage_id: 129,
		part_id: 22,
		quantity: 474,
	},
	{
		garage_id: 129,
		part_id: 23,
		quantity: 30,
	},
	{
		garage_id: 129,
		part_id: 24,
		quantity: 8,
	},
	{
		garage_id: 129,
		part_id: 25,
		quantity: 221,
	},
	{
		garage_id: 129,
		part_id: 26,
		quantity: 83,
	},
	{
		garage_id: 129,
		part_id: 27,
		quantity: 447,
	},
	{
		garage_id: 129,
		part_id: 28,
		quantity: 277,
	},
	{
		garage_id: 129,
		part_id: 29,
		quantity: 84,
	},
	{
		garage_id: 129,
		part_id: 30,
		quantity: 386,
	},
	{
		garage_id: 129,
		part_id: 31,
		quantity: 444,
	},
	{
		garage_id: 129,
		part_id: 32,
		quantity: 410,
	},
	{
		garage_id: 129,
		part_id: 33,
		quantity: 388,
	},
	{
		garage_id: 129,
		part_id: 34,
		quantity: 495,
	},
	{
		garage_id: 129,
		part_id: 35,
		quantity: 443,
	},
	{
		garage_id: 129,
		part_id: 36,
		quantity: 60,
	},
	{
		garage_id: 129,
		part_id: 37,
		quantity: 400,
	},
	{
		garage_id: 129,
		part_id: 38,
		quantity: 469,
	},
	{
		garage_id: 129,
		part_id: 39,
		quantity: 392,
	},
	{
		garage_id: 129,
		part_id: 40,
		quantity: 414,
	},
	{
		garage_id: 129,
		part_id: 41,
		quantity: 410,
	},
	{
		garage_id: 129,
		part_id: 42,
		quantity: 332,
	},
	{
		garage_id: 129,
		part_id: 43,
		quantity: 314,
	},
	{
		garage_id: 129,
		part_id: 44,
		quantity: 420,
	},
	{
		garage_id: 129,
		part_id: 45,
		quantity: 402,
	},
	{
		garage_id: 129,
		part_id: 46,
		quantity: 235,
	},
	{
		garage_id: 129,
		part_id: 47,
		quantity: 234,
	},
	{
		garage_id: 129,
		part_id: 48,
		quantity: 388,
	},
	{
		garage_id: 129,
		part_id: 49,
		quantity: 84,
	},
	{
		garage_id: 129,
		part_id: 50,
		quantity: 395,
	},
	{
		garage_id: 129,
		part_id: 51,
		quantity: 294,
	},
	{
		garage_id: 129,
		part_id: 52,
		quantity: 158,
	},
	{
		garage_id: 129,
		part_id: 53,
		quantity: 485,
	},
	{
		garage_id: 129,
		part_id: 54,
		quantity: 382,
	},
	{
		garage_id: 129,
		part_id: 55,
		quantity: 163,
	},
	{
		garage_id: 129,
		part_id: 56,
		quantity: 13,
	},
	{
		garage_id: 129,
		part_id: 57,
		quantity: 265,
	},
	{
		garage_id: 129,
		part_id: 58,
		quantity: 349,
	},
	{
		garage_id: 129,
		part_id: 59,
		quantity: 359,
	},
	{
		garage_id: 129,
		part_id: 60,
		quantity: 313,
	},
	{
		garage_id: 129,
		part_id: 61,
		quantity: 497,
	},
	{
		garage_id: 129,
		part_id: 62,
		quantity: 106,
	},
	{
		garage_id: 129,
		part_id: 63,
		quantity: 215,
	},
	{
		garage_id: 129,
		part_id: 64,
		quantity: 351,
	},
	{
		garage_id: 129,
		part_id: 65,
		quantity: 294,
	},
	{
		garage_id: 129,
		part_id: 66,
		quantity: 414,
	},
	{
		garage_id: 129,
		part_id: 67,
		quantity: 276,
	},
	{
		garage_id: 129,
		part_id: 68,
		quantity: 446,
	},
	{
		garage_id: 129,
		part_id: 69,
		quantity: 310,
	},
	{
		garage_id: 129,
		part_id: 70,
		quantity: 263,
	},
	{
		garage_id: 129,
		part_id: 71,
		quantity: 419,
	},
	{
		garage_id: 129,
		part_id: 72,
		quantity: 281,
	},
	{
		garage_id: 129,
		part_id: 73,
		quantity: 108,
	},
	{
		garage_id: 129,
		part_id: 74,
		quantity: 165,
	},
	{
		garage_id: 129,
		part_id: 75,
		quantity: 446,
	},
	{
		garage_id: 129,
		part_id: 76,
		quantity: 412,
	},
	{
		garage_id: 129,
		part_id: 77,
		quantity: 84,
	},
	{
		garage_id: 129,
		part_id: 78,
		quantity: 291,
	},
	{
		garage_id: 129,
		part_id: 79,
		quantity: 72,
	},
	{
		garage_id: 129,
		part_id: 80,
		quantity: 255,
	},
	{
		garage_id: 129,
		part_id: 81,
		quantity: 464,
	},
	{
		garage_id: 129,
		part_id: 82,
		quantity: 125,
	},
	{
		garage_id: 129,
		part_id: 83,
		quantity: 61,
	},
	{
		garage_id: 129,
		part_id: 84,
		quantity: 495,
	},
	{
		garage_id: 129,
		part_id: 85,
		quantity: 111,
	},
	{
		garage_id: 129,
		part_id: 86,
		quantity: 389,
	},
	{
		garage_id: 129,
		part_id: 87,
		quantity: 112,
	},
	{
		garage_id: 129,
		part_id: 88,
		quantity: 164,
	},
	{
		garage_id: 129,
		part_id: 89,
		quantity: 316,
	},
	{
		garage_id: 129,
		part_id: 90,
		quantity: 276,
	},
	{
		garage_id: 129,
		part_id: 91,
		quantity: 484,
	},
	{
		garage_id: 129,
		part_id: 92,
		quantity: 283,
	},
	{
		garage_id: 129,
		part_id: 93,
		quantity: 434,
	},
	{
		garage_id: 129,
		part_id: 94,
		quantity: 291,
	},
	{
		garage_id: 129,
		part_id: 95,
		quantity: 254,
	},
	{
		garage_id: 129,
		part_id: 96,
		quantity: 96,
	},
	{
		garage_id: 129,
		part_id: 97,
		quantity: 389,
	},
	{
		garage_id: 129,
		part_id: 98,
		quantity: 457,
	},
	{
		garage_id: 129,
		part_id: 99,
		quantity: 433,
	},
	{
		garage_id: 129,
		part_id: 100,
		quantity: 159,
	},
	{
		garage_id: 129,
		part_id: 101,
		quantity: 68,
	},
	{
		garage_id: 129,
		part_id: 102,
		quantity: 463,
	},
	{
		garage_id: 129,
		part_id: 103,
		quantity: 355,
	},
	{
		garage_id: 129,
		part_id: 104,
		quantity: 359,
	},
	{
		garage_id: 129,
		part_id: 105,
		quantity: 473,
	},
	{
		garage_id: 129,
		part_id: 106,
		quantity: 286,
	},
	{
		garage_id: 129,
		part_id: 107,
		quantity: 178,
	},
	{
		garage_id: 129,
		part_id: 108,
		quantity: 279,
	},
	{
		garage_id: 129,
		part_id: 109,
		quantity: 45,
	},
	{
		garage_id: 129,
		part_id: 110,
		quantity: 1,
	},
	{
		garage_id: 129,
		part_id: 111,
		quantity: 54,
	},
	{
		garage_id: 129,
		part_id: 112,
		quantity: 301,
	},
	{
		garage_id: 129,
		part_id: 113,
		quantity: 55,
	},
	{
		garage_id: 129,
		part_id: 114,
		quantity: 139,
	},
	{
		garage_id: 129,
		part_id: 115,
		quantity: 377,
	},
	{
		garage_id: 129,
		part_id: 116,
		quantity: 246,
	},
	{
		garage_id: 129,
		part_id: 117,
		quantity: 238,
	},
	{
		garage_id: 129,
		part_id: 118,
		quantity: 290,
	},
	{
		garage_id: 129,
		part_id: 119,
		quantity: 210,
	},
	{
		garage_id: 129,
		part_id: 120,
		quantity: 89,
	},
	{
		garage_id: 129,
		part_id: 121,
		quantity: 410,
	},
	{
		garage_id: 129,
		part_id: 122,
		quantity: 466,
	},
	{
		garage_id: 129,
		part_id: 123,
		quantity: 370,
	},
	{
		garage_id: 129,
		part_id: 124,
		quantity: 498,
	},
	{
		garage_id: 129,
		part_id: 125,
		quantity: 275,
	},
	{
		garage_id: 129,
		part_id: 126,
		quantity: 493,
	},
	{
		garage_id: 129,
		part_id: 127,
		quantity: 317,
	},
	{
		garage_id: 129,
		part_id: 128,
		quantity: 485,
	},
	{
		garage_id: 129,
		part_id: 129,
		quantity: 110,
	},
	{
		garage_id: 129,
		part_id: 130,
		quantity: 280,
	},
	{
		garage_id: 129,
		part_id: 131,
		quantity: 29,
	},
	{
		garage_id: 129,
		part_id: 132,
		quantity: 322,
	},
	{
		garage_id: 129,
		part_id: 133,
		quantity: 156,
	},
	{
		garage_id: 129,
		part_id: 134,
		quantity: 110,
	},
	{
		garage_id: 129,
		part_id: 135,
		quantity: 20,
	},
	{
		garage_id: 129,
		part_id: 136,
		quantity: 258,
	},
	{
		garage_id: 129,
		part_id: 137,
		quantity: 484,
	},
	{
		garage_id: 129,
		part_id: 138,
		quantity: 496,
	},
	{
		garage_id: 129,
		part_id: 139,
		quantity: 454,
	},
	{
		garage_id: 129,
		part_id: 140,
		quantity: 438,
	},
	{
		garage_id: 129,
		part_id: 141,
		quantity: 124,
	},
	{
		garage_id: 129,
		part_id: 142,
		quantity: 65,
	},
	{
		garage_id: 129,
		part_id: 143,
		quantity: 258,
	},
	{
		garage_id: 129,
		part_id: 144,
		quantity: 199,
	},
	{
		garage_id: 129,
		part_id: 145,
		quantity: 154,
	},
	{
		garage_id: 129,
		part_id: 146,
		quantity: 69,
	},
	{
		garage_id: 129,
		part_id: 147,
		quantity: 35,
	},
	{
		garage_id: 129,
		part_id: 148,
		quantity: 130,
	},
	{
		garage_id: 129,
		part_id: 149,
		quantity: 358,
	},
	{
		garage_id: 129,
		part_id: 150,
		quantity: 275,
	},
	{
		garage_id: 129,
		part_id: 151,
		quantity: 99,
	},
	{
		garage_id: 129,
		part_id: 152,
		quantity: 374,
	},
	{
		garage_id: 129,
		part_id: 153,
		quantity: 213,
	},
	{
		garage_id: 129,
		part_id: 154,
		quantity: 424,
	},
	{
		garage_id: 129,
		part_id: 155,
		quantity: 327,
	},
	{
		garage_id: 129,
		part_id: 156,
		quantity: 115,
	},
	{
		garage_id: 129,
		part_id: 157,
		quantity: 299,
	},
	{
		garage_id: 129,
		part_id: 158,
		quantity: 171,
	},
	{
		garage_id: 129,
		part_id: 159,
		quantity: 61,
	},
	{
		garage_id: 129,
		part_id: 160,
		quantity: 454,
	},
	{
		garage_id: 129,
		part_id: 161,
		quantity: 467,
	},
	{
		garage_id: 129,
		part_id: 162,
		quantity: 228,
	},
	{
		garage_id: 129,
		part_id: 163,
		quantity: 172,
	},
	{
		garage_id: 129,
		part_id: 164,
		quantity: 353,
	},
	{
		garage_id: 129,
		part_id: 165,
		quantity: 197,
	},
	{
		garage_id: 129,
		part_id: 166,
		quantity: 97,
	},
	{
		garage_id: 129,
		part_id: 167,
		quantity: 394,
	},
	{
		garage_id: 129,
		part_id: 168,
		quantity: 393,
	},
	{
		garage_id: 129,
		part_id: 169,
		quantity: 396,
	},
	{
		garage_id: 129,
		part_id: 170,
		quantity: 465,
	},
	{
		garage_id: 129,
		part_id: 171,
		quantity: 327,
	},
	{
		garage_id: 129,
		part_id: 172,
		quantity: 11,
	},
	{
		garage_id: 129,
		part_id: 173,
		quantity: 73,
	},
	{
		garage_id: 129,
		part_id: 174,
		quantity: 70,
	},
	{
		garage_id: 129,
		part_id: 175,
		quantity: 64,
	},
	{
		garage_id: 129,
		part_id: 176,
		quantity: 151,
	},
	{
		garage_id: 129,
		part_id: 177,
		quantity: 123,
	},
	{
		garage_id: 129,
		part_id: 178,
		quantity: 303,
	},
	{
		garage_id: 129,
		part_id: 179,
		quantity: 132,
	},
	{
		garage_id: 129,
		part_id: 180,
		quantity: 230,
	},
	{
		garage_id: 129,
		part_id: 181,
		quantity: 46,
	},
	{
		garage_id: 129,
		part_id: 182,
		quantity: 432,
	},
	{
		garage_id: 129,
		part_id: 183,
		quantity: 335,
	},
	{
		garage_id: 129,
		part_id: 184,
		quantity: 255,
	},
	{
		garage_id: 129,
		part_id: 185,
		quantity: 80,
	},
	{
		garage_id: 129,
		part_id: 186,
		quantity: 274,
	},
	{
		garage_id: 129,
		part_id: 187,
		quantity: 220,
	},
	{
		garage_id: 129,
		part_id: 188,
		quantity: 71,
	},
	{
		garage_id: 129,
		part_id: 189,
		quantity: 316,
	},
	{
		garage_id: 129,
		part_id: 190,
		quantity: 321,
	},
	{
		garage_id: 129,
		part_id: 191,
		quantity: 183,
	},
	{
		garage_id: 129,
		part_id: 192,
		quantity: 52,
	},
	{
		garage_id: 129,
		part_id: 193,
		quantity: 145,
	},
	{
		garage_id: 129,
		part_id: 194,
		quantity: 39,
	},
	{
		garage_id: 129,
		part_id: 195,
		quantity: 461,
	},
	{
		garage_id: 129,
		part_id: 196,
		quantity: 340,
	},
	{
		garage_id: 129,
		part_id: 197,
		quantity: 401,
	},
	{
		garage_id: 129,
		part_id: 198,
		quantity: 137,
	},
	{
		garage_id: 129,
		part_id: 199,
		quantity: 495,
	},
	{
		garage_id: 129,
		part_id: 200,
		quantity: 490,
	},
	{
		garage_id: 129,
		part_id: 201,
		quantity: 43,
	},
	{
		garage_id: 129,
		part_id: 202,
		quantity: 18,
	},
	{
		garage_id: 129,
		part_id: 203,
		quantity: 359,
	},
	{
		garage_id: 129,
		part_id: 204,
		quantity: 279,
	},
	{
		garage_id: 129,
		part_id: 205,
		quantity: 432,
	},
	{
		garage_id: 129,
		part_id: 206,
		quantity: 122,
	},
	{
		garage_id: 129,
		part_id: 207,
		quantity: 211,
	},
	{
		garage_id: 129,
		part_id: 208,
		quantity: 82,
	},
	{
		garage_id: 129,
		part_id: 209,
		quantity: 271,
	},
	{
		garage_id: 129,
		part_id: 210,
		quantity: 448,
	},
	{
		garage_id: 129,
		part_id: 211,
		quantity: 308,
	},
	{
		garage_id: 129,
		part_id: 212,
		quantity: 278,
	},
	{
		garage_id: 129,
		part_id: 213,
		quantity: 370,
	},
	{
		garage_id: 129,
		part_id: 214,
		quantity: 284,
	},
	{
		garage_id: 129,
		part_id: 215,
		quantity: 44,
	},
	{
		garage_id: 129,
		part_id: 216,
		quantity: 56,
	},
	{
		garage_id: 129,
		part_id: 217,
		quantity: 194,
	},
	{
		garage_id: 129,
		part_id: 218,
		quantity: 252,
	},
	{
		garage_id: 129,
		part_id: 219,
		quantity: 19,
	},
	{
		garage_id: 129,
		part_id: 220,
		quantity: 24,
	},
	{
		garage_id: 129,
		part_id: 221,
		quantity: 499,
	},
	{
		garage_id: 129,
		part_id: 222,
		quantity: 370,
	},
	{
		garage_id: 129,
		part_id: 223,
		quantity: 385,
	},
	{
		garage_id: 129,
		part_id: 224,
		quantity: 380,
	},
	{
		garage_id: 129,
		part_id: 225,
		quantity: 246,
	},
	{
		garage_id: 129,
		part_id: 226,
		quantity: 309,
	},
	{
		garage_id: 129,
		part_id: 227,
		quantity: 202,
	},
	{
		garage_id: 129,
		part_id: 228,
		quantity: 276,
	},
	{
		garage_id: 129,
		part_id: 229,
		quantity: 456,
	},
	{
		garage_id: 129,
		part_id: 230,
		quantity: 433,
	},
	{
		garage_id: 129,
		part_id: 231,
		quantity: 144,
	},
	{
		garage_id: 129,
		part_id: 232,
		quantity: 36,
	},
	{
		garage_id: 129,
		part_id: 233,
		quantity: 177,
	},
	{
		garage_id: 129,
		part_id: 234,
		quantity: 107,
	},
	{
		garage_id: 129,
		part_id: 235,
		quantity: 220,
	},
	{
		garage_id: 129,
		part_id: 236,
		quantity: 289,
	},
	{
		garage_id: 129,
		part_id: 237,
		quantity: 482,
	},
	{
		garage_id: 129,
		part_id: 238,
		quantity: 411,
	},
	{
		garage_id: 129,
		part_id: 239,
		quantity: 494,
	},
	{
		garage_id: 129,
		part_id: 240,
		quantity: 149,
	},
	{
		garage_id: 129,
		part_id: 241,
		quantity: 148,
	},
	{
		garage_id: 129,
		part_id: 242,
		quantity: 188,
	},
	{
		garage_id: 129,
		part_id: 243,
		quantity: 10,
	},
	{
		garage_id: 129,
		part_id: 244,
		quantity: 234,
	},
	{
		garage_id: 129,
		part_id: 245,
		quantity: 30,
	},
	{
		garage_id: 129,
		part_id: 246,
		quantity: 34,
	},
	{
		garage_id: 129,
		part_id: 247,
		quantity: 140,
	},
	{
		garage_id: 129,
		part_id: 248,
		quantity: 168,
	},
	{
		garage_id: 129,
		part_id: 249,
		quantity: 221,
	},
	{
		garage_id: 129,
		part_id: 250,
		quantity: 54,
	},
	{
		garage_id: 129,
		part_id: 251,
		quantity: 432,
	},
	{
		garage_id: 129,
		part_id: 252,
		quantity: 0,
	},
	{
		garage_id: 129,
		part_id: 253,
		quantity: 314,
	},
	{
		garage_id: 129,
		part_id: 254,
		quantity: 72,
	},
	{
		garage_id: 129,
		part_id: 255,
		quantity: 427,
	},
	{
		garage_id: 129,
		part_id: 256,
		quantity: 346,
	},
	{
		garage_id: 129,
		part_id: 257,
		quantity: 161,
	},
	{
		garage_id: 129,
		part_id: 258,
		quantity: 183,
	},
	{
		garage_id: 129,
		part_id: 259,
		quantity: 356,
	},
	{
		garage_id: 129,
		part_id: 260,
		quantity: 43,
	},
	{
		garage_id: 129,
		part_id: 261,
		quantity: 146,
	},
	{
		garage_id: 129,
		part_id: 262,
		quantity: 216,
	},
	{
		garage_id: 129,
		part_id: 263,
		quantity: 60,
	},
	{
		garage_id: 129,
		part_id: 264,
		quantity: 310,
	},
	{
		garage_id: 129,
		part_id: 265,
		quantity: 272,
	},
	{
		garage_id: 129,
		part_id: 266,
		quantity: 391,
	},
	{
		garage_id: 129,
		part_id: 267,
		quantity: 466,
	},
	{
		garage_id: 129,
		part_id: 268,
		quantity: 116,
	},
	{
		garage_id: 129,
		part_id: 269,
		quantity: 257,
	},
	{
		garage_id: 129,
		part_id: 270,
		quantity: 317,
	},
	{
		garage_id: 129,
		part_id: 271,
		quantity: 460,
	},
	{
		garage_id: 129,
		part_id: 272,
		quantity: 2,
	},
	{
		garage_id: 129,
		part_id: 273,
		quantity: 21,
	},
	{
		garage_id: 129,
		part_id: 274,
		quantity: 377,
	},
	{
		garage_id: 129,
		part_id: 275,
		quantity: 196,
	},
	{
		garage_id: 129,
		part_id: 276,
		quantity: 326,
	},
	{
		garage_id: 129,
		part_id: 277,
		quantity: 10,
	},
	{
		garage_id: 129,
		part_id: 278,
		quantity: 283,
	},
	{
		garage_id: 129,
		part_id: 279,
		quantity: 161,
	},
	{
		garage_id: 129,
		part_id: 280,
		quantity: 181,
	},
	{
		garage_id: 129,
		part_id: 281,
		quantity: 114,
	},
	{
		garage_id: 129,
		part_id: 282,
		quantity: 132,
	},
	{
		garage_id: 129,
		part_id: 283,
		quantity: 6,
	},
	{
		garage_id: 129,
		part_id: 284,
		quantity: 259,
	},
	{
		garage_id: 129,
		part_id: 285,
		quantity: 488,
	},
	{
		garage_id: 129,
		part_id: 286,
		quantity: 108,
	},
	{
		garage_id: 129,
		part_id: 287,
		quantity: 176,
	},
	{
		garage_id: 129,
		part_id: 288,
		quantity: 448,
	},
	{
		garage_id: 129,
		part_id: 289,
		quantity: 318,
	},
	{
		garage_id: 129,
		part_id: 290,
		quantity: 238,
	},
	{
		garage_id: 129,
		part_id: 291,
		quantity: 254,
	},
	{
		garage_id: 129,
		part_id: 292,
		quantity: 162,
	},
	{
		garage_id: 129,
		part_id: 293,
		quantity: 9,
	},
	{
		garage_id: 129,
		part_id: 294,
		quantity: 398,
	},
	{
		garage_id: 129,
		part_id: 295,
		quantity: 226,
	},
	{
		garage_id: 129,
		part_id: 296,
		quantity: 298,
	},
	{
		garage_id: 129,
		part_id: 297,
		quantity: 116,
	},
	{
		garage_id: 129,
		part_id: 298,
		quantity: 187,
	},
	{
		garage_id: 129,
		part_id: 299,
		quantity: 455,
	},
	{
		garage_id: 129,
		part_id: 300,
		quantity: 203,
	},
	{
		garage_id: 129,
		part_id: 301,
		quantity: 285,
	},
	{
		garage_id: 129,
		part_id: 302,
		quantity: 157,
	},
	{
		garage_id: 129,
		part_id: 303,
		quantity: 263,
	},
	{
		garage_id: 129,
		part_id: 304,
		quantity: 42,
	},
	{
		garage_id: 129,
		part_id: 305,
		quantity: 457,
	},
	{
		garage_id: 129,
		part_id: 306,
		quantity: 115,
	},
	{
		garage_id: 129,
		part_id: 307,
		quantity: 354,
	},
	{
		garage_id: 129,
		part_id: 308,
		quantity: 149,
	},
	{
		garage_id: 129,
		part_id: 309,
		quantity: 376,
	},
	{
		garage_id: 129,
		part_id: 310,
		quantity: 175,
	},
	{
		garage_id: 129,
		part_id: 311,
		quantity: 311,
	},
	{
		garage_id: 129,
		part_id: 312,
		quantity: 252,
	},
	{
		garage_id: 129,
		part_id: 313,
		quantity: 75,
	},
	{
		garage_id: 129,
		part_id: 314,
		quantity: 215,
	},
	{
		garage_id: 129,
		part_id: 315,
		quantity: 419,
	},
	{
		garage_id: 129,
		part_id: 316,
		quantity: 170,
	},
	{
		garage_id: 129,
		part_id: 317,
		quantity: 371,
	},
	{
		garage_id: 129,
		part_id: 318,
		quantity: 456,
	},
	{
		garage_id: 129,
		part_id: 319,
		quantity: 69,
	},
	{
		garage_id: 129,
		part_id: 320,
		quantity: 127,
	},
	{
		garage_id: 129,
		part_id: 321,
		quantity: 420,
	},
	{
		garage_id: 129,
		part_id: 322,
		quantity: 146,
	},
	{
		garage_id: 129,
		part_id: 323,
		quantity: 111,
	},
	{
		garage_id: 129,
		part_id: 324,
		quantity: 316,
	},
	{
		garage_id: 129,
		part_id: 325,
		quantity: 305,
	},
	{
		garage_id: 129,
		part_id: 326,
		quantity: 93,
	},
	{
		garage_id: 129,
		part_id: 327,
		quantity: 323,
	},
	{
		garage_id: 129,
		part_id: 328,
		quantity: 188,
	},
	{
		garage_id: 129,
		part_id: 329,
		quantity: 402,
	},
	{
		garage_id: 129,
		part_id: 330,
		quantity: 157,
	},
	{
		garage_id: 129,
		part_id: 331,
		quantity: 129,
	},
	{
		garage_id: 129,
		part_id: 332,
		quantity: 314,
	},
	{
		garage_id: 129,
		part_id: 333,
		quantity: 342,
	},
	{
		garage_id: 129,
		part_id: 334,
		quantity: 108,
	},
	{
		garage_id: 129,
		part_id: 335,
		quantity: 62,
	},
	{
		garage_id: 129,
		part_id: 336,
		quantity: 243,
	},
	{
		garage_id: 129,
		part_id: 337,
		quantity: 163,
	},
	{
		garage_id: 129,
		part_id: 338,
		quantity: 19,
	},
	{
		garage_id: 129,
		part_id: 339,
		quantity: 260,
	},
	{
		garage_id: 129,
		part_id: 340,
		quantity: 96,
	},
	{
		garage_id: 129,
		part_id: 341,
		quantity: 492,
	},
	{
		garage_id: 129,
		part_id: 342,
		quantity: 53,
	},
	{
		garage_id: 129,
		part_id: 343,
		quantity: 72,
	},
	{
		garage_id: 129,
		part_id: 344,
		quantity: 360,
	},
	{
		garage_id: 129,
		part_id: 345,
		quantity: 222,
	},
	{
		garage_id: 129,
		part_id: 346,
		quantity: 85,
	},
	{
		garage_id: 129,
		part_id: 347,
		quantity: 111,
	},
	{
		garage_id: 129,
		part_id: 348,
		quantity: 15,
	},
	{
		garage_id: 129,
		part_id: 349,
		quantity: 319,
	},
	{
		garage_id: 129,
		part_id: 350,
		quantity: 228,
	},
	{
		garage_id: 129,
		part_id: 351,
		quantity: 197,
	},
	{
		garage_id: 129,
		part_id: 352,
		quantity: 25,
	},
	{
		garage_id: 129,
		part_id: 353,
		quantity: 201,
	},
	{
		garage_id: 129,
		part_id: 354,
		quantity: 289,
	},
	{
		garage_id: 129,
		part_id: 355,
		quantity: 74,
	},
	{
		garage_id: 129,
		part_id: 356,
		quantity: 422,
	},
	{
		garage_id: 129,
		part_id: 357,
		quantity: 131,
	},
	{
		garage_id: 129,
		part_id: 358,
		quantity: 89,
	},
	{
		garage_id: 129,
		part_id: 359,
		quantity: 418,
	},
	{
		garage_id: 129,
		part_id: 360,
		quantity: 227,
	},
	{
		garage_id: 129,
		part_id: 361,
		quantity: 415,
	},
	{
		garage_id: 129,
		part_id: 362,
		quantity: 288,
	},
	{
		garage_id: 129,
		part_id: 363,
		quantity: 35,
	},
	{
		garage_id: 129,
		part_id: 364,
		quantity: 296,
	},
	{
		garage_id: 129,
		part_id: 365,
		quantity: 64,
	},
	{
		garage_id: 129,
		part_id: 366,
		quantity: 340,
	},
	{
		garage_id: 129,
		part_id: 367,
		quantity: 430,
	},
	{
		garage_id: 129,
		part_id: 368,
		quantity: 165,
	},
	{
		garage_id: 129,
		part_id: 369,
		quantity: 432,
	},
	{
		garage_id: 129,
		part_id: 370,
		quantity: 54,
	},
	{
		garage_id: 129,
		part_id: 371,
		quantity: 187,
	},
	{
		garage_id: 129,
		part_id: 372,
		quantity: 405,
	},
	{
		garage_id: 129,
		part_id: 373,
		quantity: 372,
	},
	{
		garage_id: 129,
		part_id: 374,
		quantity: 172,
	},
	{
		garage_id: 129,
		part_id: 375,
		quantity: 334,
	},
	{
		garage_id: 129,
		part_id: 376,
		quantity: 450,
	},
	{
		garage_id: 129,
		part_id: 377,
		quantity: 458,
	},
	{
		garage_id: 129,
		part_id: 378,
		quantity: 274,
	},
	{
		garage_id: 129,
		part_id: 379,
		quantity: 324,
	},
	{
		garage_id: 129,
		part_id: 380,
		quantity: 90,
	},
	{
		garage_id: 129,
		part_id: 381,
		quantity: 269,
	},
	{
		garage_id: 129,
		part_id: 382,
		quantity: 394,
	},
	{
		garage_id: 129,
		part_id: 383,
		quantity: 489,
	},
	{
		garage_id: 129,
		part_id: 384,
		quantity: 52,
	},
	{
		garage_id: 129,
		part_id: 385,
		quantity: 441,
	},
	{
		garage_id: 129,
		part_id: 386,
		quantity: 163,
	},
	{
		garage_id: 129,
		part_id: 387,
		quantity: 185,
	},
	{
		garage_id: 129,
		part_id: 388,
		quantity: 178,
	},
	{
		garage_id: 129,
		part_id: 389,
		quantity: 244,
	},
	{
		garage_id: 129,
		part_id: 390,
		quantity: 450,
	},
	{
		garage_id: 129,
		part_id: 391,
		quantity: 409,
	},
	{
		garage_id: 129,
		part_id: 392,
		quantity: 67,
	},
	{
		garage_id: 129,
		part_id: 393,
		quantity: 2,
	},
	{
		garage_id: 129,
		part_id: 394,
		quantity: 442,
	},
	{
		garage_id: 129,
		part_id: 395,
		quantity: 196,
	},
	{
		garage_id: 129,
		part_id: 396,
		quantity: 323,
	},
	{
		garage_id: 129,
		part_id: 397,
		quantity: 93,
	},
	{
		garage_id: 129,
		part_id: 398,
		quantity: 277,
	},
	{
		garage_id: 129,
		part_id: 399,
		quantity: 383,
	},
	{
		garage_id: 130,
		part_id: 0,
		quantity: 390,
	},
	{
		garage_id: 130,
		part_id: 1,
		quantity: 466,
	},
	{
		garage_id: 130,
		part_id: 2,
		quantity: 31,
	},
	{
		garage_id: 130,
		part_id: 3,
		quantity: 243,
	},
	{
		garage_id: 130,
		part_id: 4,
		quantity: 446,
	},
	{
		garage_id: 130,
		part_id: 5,
		quantity: 478,
	},
	{
		garage_id: 130,
		part_id: 6,
		quantity: 487,
	},
	{
		garage_id: 130,
		part_id: 7,
		quantity: 114,
	},
	{
		garage_id: 130,
		part_id: 8,
		quantity: 260,
	},
	{
		garage_id: 130,
		part_id: 9,
		quantity: 199,
	},
	{
		garage_id: 130,
		part_id: 10,
		quantity: 72,
	},
	{
		garage_id: 130,
		part_id: 11,
		quantity: 35,
	},
	{
		garage_id: 130,
		part_id: 12,
		quantity: 160,
	},
	{
		garage_id: 130,
		part_id: 13,
		quantity: 87,
	},
	{
		garage_id: 130,
		part_id: 14,
		quantity: 131,
	},
	{
		garage_id: 130,
		part_id: 15,
		quantity: 254,
	},
	{
		garage_id: 130,
		part_id: 16,
		quantity: 348,
	},
	{
		garage_id: 130,
		part_id: 17,
		quantity: 83,
	},
	{
		garage_id: 130,
		part_id: 18,
		quantity: 264,
	},
	{
		garage_id: 130,
		part_id: 19,
		quantity: 184,
	},
	{
		garage_id: 130,
		part_id: 20,
		quantity: 36,
	},
	{
		garage_id: 130,
		part_id: 21,
		quantity: 464,
	},
	{
		garage_id: 130,
		part_id: 22,
		quantity: 78,
	},
	{
		garage_id: 130,
		part_id: 23,
		quantity: 294,
	},
	{
		garage_id: 130,
		part_id: 24,
		quantity: 396,
	},
	{
		garage_id: 130,
		part_id: 25,
		quantity: 339,
	},
	{
		garage_id: 130,
		part_id: 26,
		quantity: 365,
	},
	{
		garage_id: 130,
		part_id: 27,
		quantity: 351,
	},
	{
		garage_id: 130,
		part_id: 28,
		quantity: 356,
	},
	{
		garage_id: 130,
		part_id: 29,
		quantity: 309,
	},
	{
		garage_id: 130,
		part_id: 30,
		quantity: 487,
	},
	{
		garage_id: 130,
		part_id: 31,
		quantity: 343,
	},
	{
		garage_id: 130,
		part_id: 32,
		quantity: 386,
	},
	{
		garage_id: 130,
		part_id: 33,
		quantity: 344,
	},
	{
		garage_id: 130,
		part_id: 34,
		quantity: 135,
	},
	{
		garage_id: 130,
		part_id: 35,
		quantity: 22,
	},
	{
		garage_id: 130,
		part_id: 36,
		quantity: 32,
	},
	{
		garage_id: 130,
		part_id: 37,
		quantity: 390,
	},
	{
		garage_id: 130,
		part_id: 38,
		quantity: 47,
	},
	{
		garage_id: 130,
		part_id: 39,
		quantity: 472,
	},
	{
		garage_id: 130,
		part_id: 40,
		quantity: 349,
	},
	{
		garage_id: 130,
		part_id: 41,
		quantity: 148,
	},
	{
		garage_id: 130,
		part_id: 42,
		quantity: 210,
	},
	{
		garage_id: 130,
		part_id: 43,
		quantity: 227,
	},
	{
		garage_id: 130,
		part_id: 44,
		quantity: 40,
	},
	{
		garage_id: 130,
		part_id: 45,
		quantity: 148,
	},
	{
		garage_id: 130,
		part_id: 46,
		quantity: 431,
	},
	{
		garage_id: 130,
		part_id: 47,
		quantity: 424,
	},
	{
		garage_id: 130,
		part_id: 48,
		quantity: 438,
	},
	{
		garage_id: 130,
		part_id: 49,
		quantity: 438,
	},
	{
		garage_id: 130,
		part_id: 50,
		quantity: 155,
	},
	{
		garage_id: 130,
		part_id: 51,
		quantity: 351,
	},
	{
		garage_id: 130,
		part_id: 52,
		quantity: 117,
	},
	{
		garage_id: 130,
		part_id: 53,
		quantity: 136,
	},
	{
		garage_id: 130,
		part_id: 54,
		quantity: 436,
	},
	{
		garage_id: 130,
		part_id: 55,
		quantity: 91,
	},
	{
		garage_id: 130,
		part_id: 56,
		quantity: 167,
	},
	{
		garage_id: 130,
		part_id: 57,
		quantity: 412,
	},
	{
		garage_id: 130,
		part_id: 58,
		quantity: 211,
	},
	{
		garage_id: 130,
		part_id: 59,
		quantity: 19,
	},
	{
		garage_id: 130,
		part_id: 60,
		quantity: 32,
	},
	{
		garage_id: 130,
		part_id: 61,
		quantity: 454,
	},
	{
		garage_id: 130,
		part_id: 62,
		quantity: 328,
	},
	{
		garage_id: 130,
		part_id: 63,
		quantity: 447,
	},
	{
		garage_id: 130,
		part_id: 64,
		quantity: 177,
	},
	{
		garage_id: 130,
		part_id: 65,
		quantity: 391,
	},
	{
		garage_id: 130,
		part_id: 66,
		quantity: 64,
	},
	{
		garage_id: 130,
		part_id: 67,
		quantity: 295,
	},
	{
		garage_id: 130,
		part_id: 68,
		quantity: 193,
	},
	{
		garage_id: 130,
		part_id: 69,
		quantity: 298,
	},
	{
		garage_id: 130,
		part_id: 70,
		quantity: 313,
	},
	{
		garage_id: 130,
		part_id: 71,
		quantity: 234,
	},
	{
		garage_id: 130,
		part_id: 72,
		quantity: 273,
	},
	{
		garage_id: 130,
		part_id: 73,
		quantity: 33,
	},
	{
		garage_id: 130,
		part_id: 74,
		quantity: 288,
	},
	{
		garage_id: 130,
		part_id: 75,
		quantity: 433,
	},
	{
		garage_id: 130,
		part_id: 76,
		quantity: 336,
	},
	{
		garage_id: 130,
		part_id: 77,
		quantity: 126,
	},
	{
		garage_id: 130,
		part_id: 78,
		quantity: 395,
	},
	{
		garage_id: 130,
		part_id: 79,
		quantity: 123,
	},
	{
		garage_id: 130,
		part_id: 80,
		quantity: 91,
	},
	{
		garage_id: 130,
		part_id: 81,
		quantity: 326,
	},
	{
		garage_id: 130,
		part_id: 82,
		quantity: 395,
	},
	{
		garage_id: 130,
		part_id: 83,
		quantity: 327,
	},
	{
		garage_id: 130,
		part_id: 84,
		quantity: 405,
	},
	{
		garage_id: 130,
		part_id: 85,
		quantity: 247,
	},
	{
		garage_id: 130,
		part_id: 86,
		quantity: 123,
	},
	{
		garage_id: 130,
		part_id: 87,
		quantity: 324,
	},
	{
		garage_id: 130,
		part_id: 88,
		quantity: 46,
	},
	{
		garage_id: 130,
		part_id: 89,
		quantity: 342,
	},
	{
		garage_id: 130,
		part_id: 90,
		quantity: 28,
	},
	{
		garage_id: 130,
		part_id: 91,
		quantity: 13,
	},
	{
		garage_id: 130,
		part_id: 92,
		quantity: 61,
	},
	{
		garage_id: 130,
		part_id: 93,
		quantity: 257,
	},
	{
		garage_id: 130,
		part_id: 94,
		quantity: 254,
	},
	{
		garage_id: 130,
		part_id: 95,
		quantity: 315,
	},
	{
		garage_id: 130,
		part_id: 96,
		quantity: 129,
	},
	{
		garage_id: 130,
		part_id: 97,
		quantity: 439,
	},
	{
		garage_id: 130,
		part_id: 98,
		quantity: 30,
	},
	{
		garage_id: 130,
		part_id: 99,
		quantity: 54,
	},
	{
		garage_id: 130,
		part_id: 100,
		quantity: 278,
	},
	{
		garage_id: 130,
		part_id: 101,
		quantity: 133,
	},
	{
		garage_id: 130,
		part_id: 102,
		quantity: 202,
	},
	{
		garage_id: 130,
		part_id: 103,
		quantity: 268,
	},
	{
		garage_id: 130,
		part_id: 104,
		quantity: 262,
	},
	{
		garage_id: 130,
		part_id: 105,
		quantity: 390,
	},
	{
		garage_id: 130,
		part_id: 106,
		quantity: 111,
	},
	{
		garage_id: 130,
		part_id: 107,
		quantity: 395,
	},
	{
		garage_id: 130,
		part_id: 108,
		quantity: 179,
	},
	{
		garage_id: 130,
		part_id: 109,
		quantity: 408,
	},
	{
		garage_id: 130,
		part_id: 110,
		quantity: 112,
	},
	{
		garage_id: 130,
		part_id: 111,
		quantity: 208,
	},
	{
		garage_id: 130,
		part_id: 112,
		quantity: 128,
	},
	{
		garage_id: 130,
		part_id: 113,
		quantity: 479,
	},
	{
		garage_id: 130,
		part_id: 114,
		quantity: 482,
	},
	{
		garage_id: 130,
		part_id: 115,
		quantity: 263,
	},
	{
		garage_id: 130,
		part_id: 116,
		quantity: 284,
	},
	{
		garage_id: 130,
		part_id: 117,
		quantity: 461,
	},
	{
		garage_id: 130,
		part_id: 118,
		quantity: 279,
	},
	{
		garage_id: 130,
		part_id: 119,
		quantity: 252,
	},
	{
		garage_id: 130,
		part_id: 120,
		quantity: 197,
	},
	{
		garage_id: 130,
		part_id: 121,
		quantity: 415,
	},
	{
		garage_id: 130,
		part_id: 122,
		quantity: 123,
	},
	{
		garage_id: 130,
		part_id: 123,
		quantity: 80,
	},
	{
		garage_id: 130,
		part_id: 124,
		quantity: 14,
	},
	{
		garage_id: 130,
		part_id: 125,
		quantity: 163,
	},
	{
		garage_id: 130,
		part_id: 126,
		quantity: 361,
	},
	{
		garage_id: 130,
		part_id: 127,
		quantity: 326,
	},
	{
		garage_id: 130,
		part_id: 128,
		quantity: 70,
	},
	{
		garage_id: 130,
		part_id: 129,
		quantity: 211,
	},
	{
		garage_id: 130,
		part_id: 130,
		quantity: 258,
	},
	{
		garage_id: 130,
		part_id: 131,
		quantity: 486,
	},
	{
		garage_id: 130,
		part_id: 132,
		quantity: 171,
	},
	{
		garage_id: 130,
		part_id: 133,
		quantity: 365,
	},
	{
		garage_id: 130,
		part_id: 134,
		quantity: 290,
	},
	{
		garage_id: 130,
		part_id: 135,
		quantity: 383,
	},
	{
		garage_id: 130,
		part_id: 136,
		quantity: 93,
	},
	{
		garage_id: 130,
		part_id: 137,
		quantity: 218,
	},
	{
		garage_id: 130,
		part_id: 138,
		quantity: 239,
	},
	{
		garage_id: 130,
		part_id: 139,
		quantity: 292,
	},
	{
		garage_id: 130,
		part_id: 140,
		quantity: 57,
	},
	{
		garage_id: 130,
		part_id: 141,
		quantity: 121,
	},
	{
		garage_id: 130,
		part_id: 142,
		quantity: 23,
	},
	{
		garage_id: 130,
		part_id: 143,
		quantity: 478,
	},
	{
		garage_id: 130,
		part_id: 144,
		quantity: 390,
	},
	{
		garage_id: 130,
		part_id: 145,
		quantity: 317,
	},
	{
		garage_id: 130,
		part_id: 146,
		quantity: 313,
	},
	{
		garage_id: 130,
		part_id: 147,
		quantity: 447,
	},
	{
		garage_id: 130,
		part_id: 148,
		quantity: 186,
	},
	{
		garage_id: 130,
		part_id: 149,
		quantity: 214,
	},
	{
		garage_id: 130,
		part_id: 150,
		quantity: 154,
	},
	{
		garage_id: 130,
		part_id: 151,
		quantity: 295,
	},
	{
		garage_id: 130,
		part_id: 152,
		quantity: 250,
	},
	{
		garage_id: 130,
		part_id: 153,
		quantity: 171,
	},
	{
		garage_id: 130,
		part_id: 154,
		quantity: 219,
	},
	{
		garage_id: 130,
		part_id: 155,
		quantity: 226,
	},
	{
		garage_id: 130,
		part_id: 156,
		quantity: 50,
	},
	{
		garage_id: 130,
		part_id: 157,
		quantity: 61,
	},
	{
		garage_id: 130,
		part_id: 158,
		quantity: 227,
	},
	{
		garage_id: 130,
		part_id: 159,
		quantity: 182,
	},
	{
		garage_id: 130,
		part_id: 160,
		quantity: 63,
	},
	{
		garage_id: 130,
		part_id: 161,
		quantity: 185,
	},
	{
		garage_id: 130,
		part_id: 162,
		quantity: 451,
	},
	{
		garage_id: 130,
		part_id: 163,
		quantity: 85,
	},
	{
		garage_id: 130,
		part_id: 164,
		quantity: 499,
	},
	{
		garage_id: 130,
		part_id: 165,
		quantity: 274,
	},
	{
		garage_id: 130,
		part_id: 166,
		quantity: 435,
	},
	{
		garage_id: 130,
		part_id: 167,
		quantity: 489,
	},
	{
		garage_id: 130,
		part_id: 168,
		quantity: 68,
	},
	{
		garage_id: 130,
		part_id: 169,
		quantity: 434,
	},
	{
		garage_id: 130,
		part_id: 170,
		quantity: 404,
	},
	{
		garage_id: 130,
		part_id: 171,
		quantity: 486,
	},
	{
		garage_id: 130,
		part_id: 172,
		quantity: 81,
	},
	{
		garage_id: 130,
		part_id: 173,
		quantity: 1,
	},
	{
		garage_id: 130,
		part_id: 174,
		quantity: 155,
	},
	{
		garage_id: 130,
		part_id: 175,
		quantity: 45,
	},
	{
		garage_id: 130,
		part_id: 176,
		quantity: 162,
	},
	{
		garage_id: 130,
		part_id: 177,
		quantity: 265,
	},
	{
		garage_id: 130,
		part_id: 178,
		quantity: 336,
	},
	{
		garage_id: 130,
		part_id: 179,
		quantity: 46,
	},
	{
		garage_id: 130,
		part_id: 180,
		quantity: 457,
	},
	{
		garage_id: 130,
		part_id: 181,
		quantity: 235,
	},
	{
		garage_id: 130,
		part_id: 182,
		quantity: 492,
	},
	{
		garage_id: 130,
		part_id: 183,
		quantity: 332,
	},
	{
		garage_id: 130,
		part_id: 184,
		quantity: 339,
	},
	{
		garage_id: 130,
		part_id: 185,
		quantity: 174,
	},
	{
		garage_id: 130,
		part_id: 186,
		quantity: 211,
	},
	{
		garage_id: 130,
		part_id: 187,
		quantity: 432,
	},
	{
		garage_id: 130,
		part_id: 188,
		quantity: 409,
	},
	{
		garage_id: 130,
		part_id: 189,
		quantity: 29,
	},
	{
		garage_id: 130,
		part_id: 190,
		quantity: 317,
	},
	{
		garage_id: 130,
		part_id: 191,
		quantity: 48,
	},
	{
		garage_id: 130,
		part_id: 192,
		quantity: 271,
	},
	{
		garage_id: 130,
		part_id: 193,
		quantity: 309,
	},
	{
		garage_id: 130,
		part_id: 194,
		quantity: 145,
	},
	{
		garage_id: 130,
		part_id: 195,
		quantity: 224,
	},
	{
		garage_id: 130,
		part_id: 196,
		quantity: 217,
	},
	{
		garage_id: 130,
		part_id: 197,
		quantity: 223,
	},
	{
		garage_id: 130,
		part_id: 198,
		quantity: 125,
	},
	{
		garage_id: 130,
		part_id: 199,
		quantity: 52,
	},
	{
		garage_id: 130,
		part_id: 200,
		quantity: 84,
	},
	{
		garage_id: 130,
		part_id: 201,
		quantity: 286,
	},
	{
		garage_id: 130,
		part_id: 202,
		quantity: 189,
	},
	{
		garage_id: 130,
		part_id: 203,
		quantity: 12,
	},
	{
		garage_id: 130,
		part_id: 204,
		quantity: 434,
	},
	{
		garage_id: 130,
		part_id: 205,
		quantity: 200,
	},
	{
		garage_id: 130,
		part_id: 206,
		quantity: 381,
	},
	{
		garage_id: 130,
		part_id: 207,
		quantity: 453,
	},
	{
		garage_id: 130,
		part_id: 208,
		quantity: 448,
	},
	{
		garage_id: 130,
		part_id: 209,
		quantity: 79,
	},
	{
		garage_id: 130,
		part_id: 210,
		quantity: 135,
	},
	{
		garage_id: 130,
		part_id: 211,
		quantity: 104,
	},
	{
		garage_id: 130,
		part_id: 212,
		quantity: 273,
	},
	{
		garage_id: 130,
		part_id: 213,
		quantity: 453,
	},
	{
		garage_id: 130,
		part_id: 214,
		quantity: 409,
	},
	{
		garage_id: 130,
		part_id: 215,
		quantity: 75,
	},
	{
		garage_id: 130,
		part_id: 216,
		quantity: 91,
	},
	{
		garage_id: 130,
		part_id: 217,
		quantity: 417,
	},
	{
		garage_id: 130,
		part_id: 218,
		quantity: 291,
	},
	{
		garage_id: 130,
		part_id: 219,
		quantity: 448,
	},
	{
		garage_id: 130,
		part_id: 220,
		quantity: 201,
	},
	{
		garage_id: 130,
		part_id: 221,
		quantity: 472,
	},
	{
		garage_id: 130,
		part_id: 222,
		quantity: 324,
	},
	{
		garage_id: 130,
		part_id: 223,
		quantity: 278,
	},
	{
		garage_id: 130,
		part_id: 224,
		quantity: 435,
	},
	{
		garage_id: 130,
		part_id: 225,
		quantity: 367,
	},
	{
		garage_id: 130,
		part_id: 226,
		quantity: 473,
	},
	{
		garage_id: 130,
		part_id: 227,
		quantity: 437,
	},
	{
		garage_id: 130,
		part_id: 228,
		quantity: 436,
	},
	{
		garage_id: 130,
		part_id: 229,
		quantity: 411,
	},
	{
		garage_id: 130,
		part_id: 230,
		quantity: 92,
	},
	{
		garage_id: 130,
		part_id: 231,
		quantity: 175,
	},
	{
		garage_id: 130,
		part_id: 232,
		quantity: 421,
	},
	{
		garage_id: 130,
		part_id: 233,
		quantity: 107,
	},
	{
		garage_id: 130,
		part_id: 234,
		quantity: 453,
	},
	{
		garage_id: 130,
		part_id: 235,
		quantity: 87,
	},
	{
		garage_id: 130,
		part_id: 236,
		quantity: 494,
	},
	{
		garage_id: 130,
		part_id: 237,
		quantity: 499,
	},
	{
		garage_id: 130,
		part_id: 238,
		quantity: 331,
	},
	{
		garage_id: 130,
		part_id: 239,
		quantity: 362,
	},
	{
		garage_id: 130,
		part_id: 240,
		quantity: 219,
	},
	{
		garage_id: 130,
		part_id: 241,
		quantity: 209,
	},
	{
		garage_id: 130,
		part_id: 242,
		quantity: 187,
	},
	{
		garage_id: 130,
		part_id: 243,
		quantity: 140,
	},
	{
		garage_id: 130,
		part_id: 244,
		quantity: 298,
	},
	{
		garage_id: 130,
		part_id: 245,
		quantity: 429,
	},
	{
		garage_id: 130,
		part_id: 246,
		quantity: 28,
	},
	{
		garage_id: 130,
		part_id: 247,
		quantity: 402,
	},
	{
		garage_id: 130,
		part_id: 248,
		quantity: 137,
	},
	{
		garage_id: 130,
		part_id: 249,
		quantity: 359,
	},
	{
		garage_id: 130,
		part_id: 250,
		quantity: 262,
	},
	{
		garage_id: 130,
		part_id: 251,
		quantity: 137,
	},
	{
		garage_id: 130,
		part_id: 252,
		quantity: 394,
	},
	{
		garage_id: 130,
		part_id: 253,
		quantity: 129,
	},
	{
		garage_id: 130,
		part_id: 254,
		quantity: 146,
	},
	{
		garage_id: 130,
		part_id: 255,
		quantity: 336,
	},
	{
		garage_id: 130,
		part_id: 256,
		quantity: 277,
	},
	{
		garage_id: 130,
		part_id: 257,
		quantity: 96,
	},
	{
		garage_id: 130,
		part_id: 258,
		quantity: 356,
	},
	{
		garage_id: 130,
		part_id: 259,
		quantity: 161,
	},
	{
		garage_id: 130,
		part_id: 260,
		quantity: 111,
	},
	{
		garage_id: 130,
		part_id: 261,
		quantity: 155,
	},
	{
		garage_id: 130,
		part_id: 262,
		quantity: 382,
	},
	{
		garage_id: 130,
		part_id: 263,
		quantity: 463,
	},
	{
		garage_id: 130,
		part_id: 264,
		quantity: 172,
	},
	{
		garage_id: 130,
		part_id: 265,
		quantity: 153,
	},
	{
		garage_id: 130,
		part_id: 266,
		quantity: 488,
	},
	{
		garage_id: 130,
		part_id: 267,
		quantity: 478,
	},
	{
		garage_id: 130,
		part_id: 268,
		quantity: 475,
	},
	{
		garage_id: 130,
		part_id: 269,
		quantity: 418,
	},
	{
		garage_id: 130,
		part_id: 270,
		quantity: 8,
	},
	{
		garage_id: 130,
		part_id: 271,
		quantity: 226,
	},
	{
		garage_id: 130,
		part_id: 272,
		quantity: 52,
	},
	{
		garage_id: 130,
		part_id: 273,
		quantity: 355,
	},
	{
		garage_id: 130,
		part_id: 274,
		quantity: 111,
	},
	{
		garage_id: 130,
		part_id: 275,
		quantity: 65,
	},
	{
		garage_id: 130,
		part_id: 276,
		quantity: 28,
	},
	{
		garage_id: 130,
		part_id: 277,
		quantity: 290,
	},
	{
		garage_id: 130,
		part_id: 278,
		quantity: 136,
	},
	{
		garage_id: 130,
		part_id: 279,
		quantity: 35,
	},
	{
		garage_id: 130,
		part_id: 280,
		quantity: 34,
	},
	{
		garage_id: 130,
		part_id: 281,
		quantity: 255,
	},
	{
		garage_id: 130,
		part_id: 282,
		quantity: 198,
	},
	{
		garage_id: 130,
		part_id: 283,
		quantity: 444,
	},
	{
		garage_id: 130,
		part_id: 284,
		quantity: 86,
	},
	{
		garage_id: 130,
		part_id: 285,
		quantity: 99,
	},
	{
		garage_id: 130,
		part_id: 286,
		quantity: 94,
	},
	{
		garage_id: 130,
		part_id: 287,
		quantity: 30,
	},
	{
		garage_id: 130,
		part_id: 288,
		quantity: 305,
	},
	{
		garage_id: 130,
		part_id: 289,
		quantity: 405,
	},
	{
		garage_id: 130,
		part_id: 290,
		quantity: 28,
	},
	{
		garage_id: 130,
		part_id: 291,
		quantity: 260,
	},
	{
		garage_id: 130,
		part_id: 292,
		quantity: 350,
	},
	{
		garage_id: 130,
		part_id: 293,
		quantity: 254,
	},
	{
		garage_id: 130,
		part_id: 294,
		quantity: 274,
	},
	{
		garage_id: 130,
		part_id: 295,
		quantity: 19,
	},
	{
		garage_id: 130,
		part_id: 296,
		quantity: 253,
	},
	{
		garage_id: 130,
		part_id: 297,
		quantity: 398,
	},
	{
		garage_id: 130,
		part_id: 298,
		quantity: 472,
	},
	{
		garage_id: 130,
		part_id: 299,
		quantity: 160,
	},
	{
		garage_id: 130,
		part_id: 300,
		quantity: 261,
	},
	{
		garage_id: 130,
		part_id: 301,
		quantity: 273,
	},
	{
		garage_id: 130,
		part_id: 302,
		quantity: 497,
	},
	{
		garage_id: 130,
		part_id: 303,
		quantity: 34,
	},
	{
		garage_id: 130,
		part_id: 304,
		quantity: 494,
	},
	{
		garage_id: 130,
		part_id: 305,
		quantity: 184,
	},
	{
		garage_id: 130,
		part_id: 306,
		quantity: 483,
	},
	{
		garage_id: 130,
		part_id: 307,
		quantity: 476,
	},
	{
		garage_id: 130,
		part_id: 308,
		quantity: 500,
	},
	{
		garage_id: 130,
		part_id: 309,
		quantity: 476,
	},
	{
		garage_id: 130,
		part_id: 310,
		quantity: 251,
	},
	{
		garage_id: 130,
		part_id: 311,
		quantity: 336,
	},
	{
		garage_id: 130,
		part_id: 312,
		quantity: 448,
	},
	{
		garage_id: 130,
		part_id: 313,
		quantity: 500,
	},
	{
		garage_id: 130,
		part_id: 314,
		quantity: 312,
	},
	{
		garage_id: 130,
		part_id: 315,
		quantity: 421,
	},
	{
		garage_id: 130,
		part_id: 316,
		quantity: 443,
	},
	{
		garage_id: 130,
		part_id: 317,
		quantity: 369,
	},
	{
		garage_id: 130,
		part_id: 318,
		quantity: 315,
	},
	{
		garage_id: 130,
		part_id: 319,
		quantity: 242,
	},
	{
		garage_id: 130,
		part_id: 320,
		quantity: 223,
	},
	{
		garage_id: 130,
		part_id: 321,
		quantity: 374,
	},
	{
		garage_id: 130,
		part_id: 322,
		quantity: 482,
	},
	{
		garage_id: 130,
		part_id: 323,
		quantity: 28,
	},
	{
		garage_id: 130,
		part_id: 324,
		quantity: 376,
	},
	{
		garage_id: 130,
		part_id: 325,
		quantity: 171,
	},
	{
		garage_id: 130,
		part_id: 326,
		quantity: 71,
	},
	{
		garage_id: 130,
		part_id: 327,
		quantity: 18,
	},
	{
		garage_id: 130,
		part_id: 328,
		quantity: 434,
	},
	{
		garage_id: 130,
		part_id: 329,
		quantity: 2,
	},
	{
		garage_id: 130,
		part_id: 330,
		quantity: 145,
	},
	{
		garage_id: 130,
		part_id: 331,
		quantity: 203,
	},
	{
		garage_id: 130,
		part_id: 332,
		quantity: 41,
	},
	{
		garage_id: 130,
		part_id: 333,
		quantity: 237,
	},
	{
		garage_id: 130,
		part_id: 334,
		quantity: 342,
	},
	{
		garage_id: 130,
		part_id: 335,
		quantity: 250,
	},
	{
		garage_id: 130,
		part_id: 336,
		quantity: 221,
	},
	{
		garage_id: 130,
		part_id: 337,
		quantity: 487,
	},
	{
		garage_id: 130,
		part_id: 338,
		quantity: 193,
	},
	{
		garage_id: 130,
		part_id: 339,
		quantity: 261,
	},
	{
		garage_id: 130,
		part_id: 340,
		quantity: 165,
	},
	{
		garage_id: 130,
		part_id: 341,
		quantity: 273,
	},
	{
		garage_id: 130,
		part_id: 342,
		quantity: 401,
	},
	{
		garage_id: 130,
		part_id: 343,
		quantity: 410,
	},
	{
		garage_id: 130,
		part_id: 344,
		quantity: 141,
	},
	{
		garage_id: 130,
		part_id: 345,
		quantity: 409,
	},
	{
		garage_id: 130,
		part_id: 346,
		quantity: 86,
	},
	{
		garage_id: 130,
		part_id: 347,
		quantity: 341,
	},
	{
		garage_id: 130,
		part_id: 348,
		quantity: 176,
	},
	{
		garage_id: 130,
		part_id: 349,
		quantity: 189,
	},
	{
		garage_id: 130,
		part_id: 350,
		quantity: 382,
	},
	{
		garage_id: 130,
		part_id: 351,
		quantity: 43,
	},
	{
		garage_id: 130,
		part_id: 352,
		quantity: 274,
	},
	{
		garage_id: 130,
		part_id: 353,
		quantity: 296,
	},
	{
		garage_id: 130,
		part_id: 354,
		quantity: 97,
	},
	{
		garage_id: 130,
		part_id: 355,
		quantity: 58,
	},
	{
		garage_id: 130,
		part_id: 356,
		quantity: 125,
	},
	{
		garage_id: 130,
		part_id: 357,
		quantity: 58,
	},
	{
		garage_id: 130,
		part_id: 358,
		quantity: 258,
	},
	{
		garage_id: 130,
		part_id: 359,
		quantity: 443,
	},
	{
		garage_id: 130,
		part_id: 360,
		quantity: 469,
	},
	{
		garage_id: 130,
		part_id: 361,
		quantity: 455,
	},
	{
		garage_id: 130,
		part_id: 362,
		quantity: 338,
	},
	{
		garage_id: 130,
		part_id: 363,
		quantity: 430,
	},
	{
		garage_id: 130,
		part_id: 364,
		quantity: 137,
	},
	{
		garage_id: 130,
		part_id: 365,
		quantity: 466,
	},
	{
		garage_id: 130,
		part_id: 366,
		quantity: 55,
	},
	{
		garage_id: 130,
		part_id: 367,
		quantity: 364,
	},
	{
		garage_id: 130,
		part_id: 368,
		quantity: 418,
	},
	{
		garage_id: 130,
		part_id: 369,
		quantity: 233,
	},
	{
		garage_id: 130,
		part_id: 370,
		quantity: 278,
	},
	{
		garage_id: 130,
		part_id: 371,
		quantity: 355,
	},
	{
		garage_id: 130,
		part_id: 372,
		quantity: 101,
	},
	{
		garage_id: 130,
		part_id: 373,
		quantity: 109,
	},
	{
		garage_id: 130,
		part_id: 374,
		quantity: 457,
	},
	{
		garage_id: 130,
		part_id: 375,
		quantity: 444,
	},
	{
		garage_id: 130,
		part_id: 376,
		quantity: 353,
	},
	{
		garage_id: 130,
		part_id: 377,
		quantity: 422,
	},
	{
		garage_id: 130,
		part_id: 378,
		quantity: 224,
	},
	{
		garage_id: 130,
		part_id: 379,
		quantity: 8,
	},
	{
		garage_id: 130,
		part_id: 380,
		quantity: 401,
	},
	{
		garage_id: 130,
		part_id: 381,
		quantity: 484,
	},
	{
		garage_id: 130,
		part_id: 382,
		quantity: 205,
	},
	{
		garage_id: 130,
		part_id: 383,
		quantity: 215,
	},
	{
		garage_id: 130,
		part_id: 384,
		quantity: 374,
	},
	{
		garage_id: 130,
		part_id: 385,
		quantity: 62,
	},
	{
		garage_id: 130,
		part_id: 386,
		quantity: 42,
	},
	{
		garage_id: 130,
		part_id: 387,
		quantity: 174,
	},
	{
		garage_id: 130,
		part_id: 388,
		quantity: 157,
	},
	{
		garage_id: 130,
		part_id: 389,
		quantity: 2,
	},
	{
		garage_id: 130,
		part_id: 390,
		quantity: 67,
	},
	{
		garage_id: 130,
		part_id: 391,
		quantity: 116,
	},
	{
		garage_id: 130,
		part_id: 392,
		quantity: 62,
	},
	{
		garage_id: 130,
		part_id: 393,
		quantity: 283,
	},
	{
		garage_id: 130,
		part_id: 394,
		quantity: 110,
	},
	{
		garage_id: 130,
		part_id: 395,
		quantity: 354,
	},
	{
		garage_id: 130,
		part_id: 396,
		quantity: 275,
	},
	{
		garage_id: 130,
		part_id: 397,
		quantity: 41,
	},
	{
		garage_id: 130,
		part_id: 398,
		quantity: 155,
	},
	{
		garage_id: 130,
		part_id: 399,
		quantity: 268,
	},
	{
		garage_id: 131,
		part_id: 0,
		quantity: 184,
	},
	{
		garage_id: 131,
		part_id: 1,
		quantity: 154,
	},
	{
		garage_id: 131,
		part_id: 2,
		quantity: 394,
	},
	{
		garage_id: 131,
		part_id: 3,
		quantity: 240,
	},
	{
		garage_id: 131,
		part_id: 4,
		quantity: 6,
	},
	{
		garage_id: 131,
		part_id: 5,
		quantity: 316,
	},
	{
		garage_id: 131,
		part_id: 6,
		quantity: 352,
	},
	{
		garage_id: 131,
		part_id: 7,
		quantity: 44,
	},
	{
		garage_id: 131,
		part_id: 8,
		quantity: 83,
	},
	{
		garage_id: 131,
		part_id: 9,
		quantity: 276,
	},
	{
		garage_id: 131,
		part_id: 10,
		quantity: 388,
	},
	{
		garage_id: 131,
		part_id: 11,
		quantity: 128,
	},
	{
		garage_id: 131,
		part_id: 12,
		quantity: 411,
	},
	{
		garage_id: 131,
		part_id: 13,
		quantity: 286,
	},
	{
		garage_id: 131,
		part_id: 14,
		quantity: 225,
	},
	{
		garage_id: 131,
		part_id: 15,
		quantity: 262,
	},
	{
		garage_id: 131,
		part_id: 16,
		quantity: 245,
	},
	{
		garage_id: 131,
		part_id: 17,
		quantity: 233,
	},
	{
		garage_id: 131,
		part_id: 18,
		quantity: 171,
	},
	{
		garage_id: 131,
		part_id: 19,
		quantity: 268,
	},
	{
		garage_id: 131,
		part_id: 20,
		quantity: 373,
	},
	{
		garage_id: 131,
		part_id: 21,
		quantity: 45,
	},
	{
		garage_id: 131,
		part_id: 22,
		quantity: 85,
	},
	{
		garage_id: 131,
		part_id: 23,
		quantity: 283,
	},
	{
		garage_id: 131,
		part_id: 24,
		quantity: 60,
	},
	{
		garage_id: 131,
		part_id: 25,
		quantity: 35,
	},
	{
		garage_id: 131,
		part_id: 26,
		quantity: 112,
	},
	{
		garage_id: 131,
		part_id: 27,
		quantity: 255,
	},
	{
		garage_id: 131,
		part_id: 28,
		quantity: 159,
	},
	{
		garage_id: 131,
		part_id: 29,
		quantity: 107,
	},
	{
		garage_id: 131,
		part_id: 30,
		quantity: 349,
	},
	{
		garage_id: 131,
		part_id: 31,
		quantity: 386,
	},
	{
		garage_id: 131,
		part_id: 32,
		quantity: 424,
	},
	{
		garage_id: 131,
		part_id: 33,
		quantity: 481,
	},
	{
		garage_id: 131,
		part_id: 34,
		quantity: 239,
	},
	{
		garage_id: 131,
		part_id: 35,
		quantity: 488,
	},
	{
		garage_id: 131,
		part_id: 36,
		quantity: 228,
	},
	{
		garage_id: 131,
		part_id: 37,
		quantity: 47,
	},
	{
		garage_id: 131,
		part_id: 38,
		quantity: 444,
	},
	{
		garage_id: 131,
		part_id: 39,
		quantity: 167,
	},
	{
		garage_id: 131,
		part_id: 40,
		quantity: 132,
	},
	{
		garage_id: 131,
		part_id: 41,
		quantity: 52,
	},
	{
		garage_id: 131,
		part_id: 42,
		quantity: 395,
	},
	{
		garage_id: 131,
		part_id: 43,
		quantity: 380,
	},
	{
		garage_id: 131,
		part_id: 44,
		quantity: 33,
	},
	{
		garage_id: 131,
		part_id: 45,
		quantity: 332,
	},
	{
		garage_id: 131,
		part_id: 46,
		quantity: 15,
	},
	{
		garage_id: 131,
		part_id: 47,
		quantity: 178,
	},
	{
		garage_id: 131,
		part_id: 48,
		quantity: 313,
	},
	{
		garage_id: 131,
		part_id: 49,
		quantity: 78,
	},
	{
		garage_id: 131,
		part_id: 50,
		quantity: 196,
	},
	{
		garage_id: 131,
		part_id: 51,
		quantity: 232,
	},
	{
		garage_id: 131,
		part_id: 52,
		quantity: 334,
	},
	{
		garage_id: 131,
		part_id: 53,
		quantity: 243,
	},
	{
		garage_id: 131,
		part_id: 54,
		quantity: 379,
	},
	{
		garage_id: 131,
		part_id: 55,
		quantity: 80,
	},
	{
		garage_id: 131,
		part_id: 56,
		quantity: 306,
	},
	{
		garage_id: 131,
		part_id: 57,
		quantity: 470,
	},
	{
		garage_id: 131,
		part_id: 58,
		quantity: 498,
	},
	{
		garage_id: 131,
		part_id: 59,
		quantity: 40,
	},
	{
		garage_id: 131,
		part_id: 60,
		quantity: 484,
	},
	{
		garage_id: 131,
		part_id: 61,
		quantity: 348,
	},
	{
		garage_id: 131,
		part_id: 62,
		quantity: 94,
	},
	{
		garage_id: 131,
		part_id: 63,
		quantity: 216,
	},
	{
		garage_id: 131,
		part_id: 64,
		quantity: 407,
	},
	{
		garage_id: 131,
		part_id: 65,
		quantity: 110,
	},
	{
		garage_id: 131,
		part_id: 66,
		quantity: 350,
	},
	{
		garage_id: 131,
		part_id: 67,
		quantity: 320,
	},
	{
		garage_id: 131,
		part_id: 68,
		quantity: 375,
	},
	{
		garage_id: 131,
		part_id: 69,
		quantity: 373,
	},
	{
		garage_id: 131,
		part_id: 70,
		quantity: 213,
	},
	{
		garage_id: 131,
		part_id: 71,
		quantity: 365,
	},
	{
		garage_id: 131,
		part_id: 72,
		quantity: 393,
	},
	{
		garage_id: 131,
		part_id: 73,
		quantity: 89,
	},
	{
		garage_id: 131,
		part_id: 74,
		quantity: 111,
	},
	{
		garage_id: 131,
		part_id: 75,
		quantity: 171,
	},
	{
		garage_id: 131,
		part_id: 76,
		quantity: 90,
	},
	{
		garage_id: 131,
		part_id: 77,
		quantity: 236,
	},
	{
		garage_id: 131,
		part_id: 78,
		quantity: 211,
	},
	{
		garage_id: 131,
		part_id: 79,
		quantity: 240,
	},
	{
		garage_id: 131,
		part_id: 80,
		quantity: 458,
	},
	{
		garage_id: 131,
		part_id: 81,
		quantity: 357,
	},
	{
		garage_id: 131,
		part_id: 82,
		quantity: 143,
	},
	{
		garage_id: 131,
		part_id: 83,
		quantity: 426,
	},
	{
		garage_id: 131,
		part_id: 84,
		quantity: 434,
	},
	{
		garage_id: 131,
		part_id: 85,
		quantity: 61,
	},
	{
		garage_id: 131,
		part_id: 86,
		quantity: 479,
	},
	{
		garage_id: 131,
		part_id: 87,
		quantity: 79,
	},
	{
		garage_id: 131,
		part_id: 88,
		quantity: 357,
	},
	{
		garage_id: 131,
		part_id: 89,
		quantity: 108,
	},
	{
		garage_id: 131,
		part_id: 90,
		quantity: 485,
	},
	{
		garage_id: 131,
		part_id: 91,
		quantity: 294,
	},
	{
		garage_id: 131,
		part_id: 92,
		quantity: 361,
	},
	{
		garage_id: 131,
		part_id: 93,
		quantity: 239,
	},
	{
		garage_id: 131,
		part_id: 94,
		quantity: 287,
	},
	{
		garage_id: 131,
		part_id: 95,
		quantity: 120,
	},
	{
		garage_id: 131,
		part_id: 96,
		quantity: 493,
	},
	{
		garage_id: 131,
		part_id: 97,
		quantity: 83,
	},
	{
		garage_id: 131,
		part_id: 98,
		quantity: 353,
	},
	{
		garage_id: 131,
		part_id: 99,
		quantity: 304,
	},
	{
		garage_id: 131,
		part_id: 100,
		quantity: 262,
	},
	{
		garage_id: 131,
		part_id: 101,
		quantity: 236,
	},
	{
		garage_id: 131,
		part_id: 102,
		quantity: 213,
	},
	{
		garage_id: 131,
		part_id: 103,
		quantity: 12,
	},
	{
		garage_id: 131,
		part_id: 104,
		quantity: 470,
	},
	{
		garage_id: 131,
		part_id: 105,
		quantity: 331,
	},
	{
		garage_id: 131,
		part_id: 106,
		quantity: 28,
	},
	{
		garage_id: 131,
		part_id: 107,
		quantity: 400,
	},
	{
		garage_id: 131,
		part_id: 108,
		quantity: 311,
	},
	{
		garage_id: 131,
		part_id: 109,
		quantity: 91,
	},
	{
		garage_id: 131,
		part_id: 110,
		quantity: 426,
	},
	{
		garage_id: 131,
		part_id: 111,
		quantity: 482,
	},
	{
		garage_id: 131,
		part_id: 112,
		quantity: 227,
	},
	{
		garage_id: 131,
		part_id: 113,
		quantity: 166,
	},
	{
		garage_id: 131,
		part_id: 114,
		quantity: 183,
	},
	{
		garage_id: 131,
		part_id: 115,
		quantity: 296,
	},
	{
		garage_id: 131,
		part_id: 116,
		quantity: 355,
	},
	{
		garage_id: 131,
		part_id: 117,
		quantity: 140,
	},
	{
		garage_id: 131,
		part_id: 118,
		quantity: 207,
	},
	{
		garage_id: 131,
		part_id: 119,
		quantity: 480,
	},
	{
		garage_id: 131,
		part_id: 120,
		quantity: 248,
	},
	{
		garage_id: 131,
		part_id: 121,
		quantity: 93,
	},
	{
		garage_id: 131,
		part_id: 122,
		quantity: 433,
	},
	{
		garage_id: 131,
		part_id: 123,
		quantity: 494,
	},
	{
		garage_id: 131,
		part_id: 124,
		quantity: 474,
	},
	{
		garage_id: 131,
		part_id: 125,
		quantity: 456,
	},
	{
		garage_id: 131,
		part_id: 126,
		quantity: 418,
	},
	{
		garage_id: 131,
		part_id: 127,
		quantity: 208,
	},
	{
		garage_id: 131,
		part_id: 128,
		quantity: 323,
	},
	{
		garage_id: 131,
		part_id: 129,
		quantity: 225,
	},
	{
		garage_id: 131,
		part_id: 130,
		quantity: 408,
	},
	{
		garage_id: 131,
		part_id: 131,
		quantity: 469,
	},
	{
		garage_id: 131,
		part_id: 132,
		quantity: 453,
	},
	{
		garage_id: 131,
		part_id: 133,
		quantity: 236,
	},
	{
		garage_id: 131,
		part_id: 134,
		quantity: 453,
	},
	{
		garage_id: 131,
		part_id: 135,
		quantity: 20,
	},
	{
		garage_id: 131,
		part_id: 136,
		quantity: 369,
	},
	{
		garage_id: 131,
		part_id: 137,
		quantity: 287,
	},
	{
		garage_id: 131,
		part_id: 138,
		quantity: 432,
	},
	{
		garage_id: 131,
		part_id: 139,
		quantity: 168,
	},
	{
		garage_id: 131,
		part_id: 140,
		quantity: 249,
	},
	{
		garage_id: 131,
		part_id: 141,
		quantity: 16,
	},
	{
		garage_id: 131,
		part_id: 142,
		quantity: 402,
	},
	{
		garage_id: 131,
		part_id: 143,
		quantity: 253,
	},
	{
		garage_id: 131,
		part_id: 144,
		quantity: 130,
	},
	{
		garage_id: 131,
		part_id: 145,
		quantity: 231,
	},
	{
		garage_id: 131,
		part_id: 146,
		quantity: 283,
	},
	{
		garage_id: 131,
		part_id: 147,
		quantity: 341,
	},
	{
		garage_id: 131,
		part_id: 148,
		quantity: 2,
	},
	{
		garage_id: 131,
		part_id: 149,
		quantity: 243,
	},
	{
		garage_id: 131,
		part_id: 150,
		quantity: 241,
	},
	{
		garage_id: 131,
		part_id: 151,
		quantity: 81,
	},
	{
		garage_id: 131,
		part_id: 152,
		quantity: 297,
	},
	{
		garage_id: 131,
		part_id: 153,
		quantity: 179,
	},
	{
		garage_id: 131,
		part_id: 154,
		quantity: 142,
	},
	{
		garage_id: 131,
		part_id: 155,
		quantity: 185,
	},
	{
		garage_id: 131,
		part_id: 156,
		quantity: 92,
	},
	{
		garage_id: 131,
		part_id: 157,
		quantity: 142,
	},
	{
		garage_id: 131,
		part_id: 158,
		quantity: 45,
	},
	{
		garage_id: 131,
		part_id: 159,
		quantity: 262,
	},
	{
		garage_id: 131,
		part_id: 160,
		quantity: 318,
	},
	{
		garage_id: 131,
		part_id: 161,
		quantity: 8,
	},
	{
		garage_id: 131,
		part_id: 162,
		quantity: 196,
	},
	{
		garage_id: 131,
		part_id: 163,
		quantity: 190,
	},
	{
		garage_id: 131,
		part_id: 164,
		quantity: 256,
	},
	{
		garage_id: 131,
		part_id: 165,
		quantity: 357,
	},
	{
		garage_id: 131,
		part_id: 166,
		quantity: 147,
	},
	{
		garage_id: 131,
		part_id: 167,
		quantity: 49,
	},
	{
		garage_id: 131,
		part_id: 168,
		quantity: 476,
	},
	{
		garage_id: 131,
		part_id: 169,
		quantity: 276,
	},
	{
		garage_id: 131,
		part_id: 170,
		quantity: 52,
	},
	{
		garage_id: 131,
		part_id: 171,
		quantity: 224,
	},
	{
		garage_id: 131,
		part_id: 172,
		quantity: 147,
	},
	{
		garage_id: 131,
		part_id: 173,
		quantity: 109,
	},
	{
		garage_id: 131,
		part_id: 174,
		quantity: 335,
	},
	{
		garage_id: 131,
		part_id: 175,
		quantity: 399,
	},
	{
		garage_id: 131,
		part_id: 176,
		quantity: 399,
	},
	{
		garage_id: 131,
		part_id: 177,
		quantity: 499,
	},
	{
		garage_id: 131,
		part_id: 178,
		quantity: 422,
	},
	{
		garage_id: 131,
		part_id: 179,
		quantity: 128,
	},
	{
		garage_id: 131,
		part_id: 180,
		quantity: 442,
	},
	{
		garage_id: 131,
		part_id: 181,
		quantity: 64,
	},
	{
		garage_id: 131,
		part_id: 182,
		quantity: 125,
	},
	{
		garage_id: 131,
		part_id: 183,
		quantity: 320,
	},
	{
		garage_id: 131,
		part_id: 184,
		quantity: 294,
	},
	{
		garage_id: 131,
		part_id: 185,
		quantity: 157,
	},
	{
		garage_id: 131,
		part_id: 186,
		quantity: 447,
	},
	{
		garage_id: 131,
		part_id: 187,
		quantity: 149,
	},
	{
		garage_id: 131,
		part_id: 188,
		quantity: 457,
	},
	{
		garage_id: 131,
		part_id: 189,
		quantity: 187,
	},
	{
		garage_id: 131,
		part_id: 190,
		quantity: 105,
	},
	{
		garage_id: 131,
		part_id: 191,
		quantity: 75,
	},
	{
		garage_id: 131,
		part_id: 192,
		quantity: 466,
	},
	{
		garage_id: 131,
		part_id: 193,
		quantity: 133,
	},
	{
		garage_id: 131,
		part_id: 194,
		quantity: 473,
	},
	{
		garage_id: 131,
		part_id: 195,
		quantity: 90,
	},
	{
		garage_id: 131,
		part_id: 196,
		quantity: 472,
	},
	{
		garage_id: 131,
		part_id: 197,
		quantity: 274,
	},
	{
		garage_id: 131,
		part_id: 198,
		quantity: 244,
	},
	{
		garage_id: 131,
		part_id: 199,
		quantity: 37,
	},
	{
		garage_id: 131,
		part_id: 200,
		quantity: 408,
	},
	{
		garage_id: 131,
		part_id: 201,
		quantity: 490,
	},
	{
		garage_id: 131,
		part_id: 202,
		quantity: 313,
	},
	{
		garage_id: 131,
		part_id: 203,
		quantity: 451,
	},
	{
		garage_id: 131,
		part_id: 204,
		quantity: 195,
	},
	{
		garage_id: 131,
		part_id: 205,
		quantity: 11,
	},
	{
		garage_id: 131,
		part_id: 206,
		quantity: 183,
	},
	{
		garage_id: 131,
		part_id: 207,
		quantity: 85,
	},
	{
		garage_id: 131,
		part_id: 208,
		quantity: 279,
	},
	{
		garage_id: 131,
		part_id: 209,
		quantity: 268,
	},
	{
		garage_id: 131,
		part_id: 210,
		quantity: 66,
	},
	{
		garage_id: 131,
		part_id: 211,
		quantity: 182,
	},
	{
		garage_id: 131,
		part_id: 212,
		quantity: 437,
	},
	{
		garage_id: 131,
		part_id: 213,
		quantity: 35,
	},
	{
		garage_id: 131,
		part_id: 214,
		quantity: 428,
	},
	{
		garage_id: 131,
		part_id: 215,
		quantity: 296,
	},
	{
		garage_id: 131,
		part_id: 216,
		quantity: 454,
	},
	{
		garage_id: 131,
		part_id: 217,
		quantity: 341,
	},
	{
		garage_id: 131,
		part_id: 218,
		quantity: 61,
	},
	{
		garage_id: 131,
		part_id: 219,
		quantity: 51,
	},
	{
		garage_id: 131,
		part_id: 220,
		quantity: 359,
	},
	{
		garage_id: 131,
		part_id: 221,
		quantity: 23,
	},
	{
		garage_id: 131,
		part_id: 222,
		quantity: 456,
	},
	{
		garage_id: 131,
		part_id: 223,
		quantity: 416,
	},
	{
		garage_id: 131,
		part_id: 224,
		quantity: 403,
	},
	{
		garage_id: 131,
		part_id: 225,
		quantity: 223,
	},
	{
		garage_id: 131,
		part_id: 226,
		quantity: 446,
	},
	{
		garage_id: 131,
		part_id: 227,
		quantity: 420,
	},
	{
		garage_id: 131,
		part_id: 228,
		quantity: 379,
	},
	{
		garage_id: 131,
		part_id: 229,
		quantity: 37,
	},
	{
		garage_id: 131,
		part_id: 230,
		quantity: 371,
	},
	{
		garage_id: 131,
		part_id: 231,
		quantity: 447,
	},
	{
		garage_id: 131,
		part_id: 232,
		quantity: 349,
	},
	{
		garage_id: 131,
		part_id: 233,
		quantity: 331,
	},
	{
		garage_id: 131,
		part_id: 234,
		quantity: 351,
	},
	{
		garage_id: 131,
		part_id: 235,
		quantity: 486,
	},
	{
		garage_id: 131,
		part_id: 236,
		quantity: 236,
	},
	{
		garage_id: 131,
		part_id: 237,
		quantity: 26,
	},
	{
		garage_id: 131,
		part_id: 238,
		quantity: 325,
	},
	{
		garage_id: 131,
		part_id: 239,
		quantity: 464,
	},
	{
		garage_id: 131,
		part_id: 240,
		quantity: 114,
	},
	{
		garage_id: 131,
		part_id: 241,
		quantity: 204,
	},
	{
		garage_id: 131,
		part_id: 242,
		quantity: 177,
	},
	{
		garage_id: 131,
		part_id: 243,
		quantity: 445,
	},
	{
		garage_id: 131,
		part_id: 244,
		quantity: 105,
	},
	{
		garage_id: 131,
		part_id: 245,
		quantity: 435,
	},
	{
		garage_id: 131,
		part_id: 246,
		quantity: 343,
	},
	{
		garage_id: 131,
		part_id: 247,
		quantity: 151,
	},
	{
		garage_id: 131,
		part_id: 248,
		quantity: 2,
	},
	{
		garage_id: 131,
		part_id: 249,
		quantity: 358,
	},
	{
		garage_id: 131,
		part_id: 250,
		quantity: 73,
	},
	{
		garage_id: 131,
		part_id: 251,
		quantity: 232,
	},
	{
		garage_id: 131,
		part_id: 252,
		quantity: 453,
	},
	{
		garage_id: 131,
		part_id: 253,
		quantity: 336,
	},
	{
		garage_id: 131,
		part_id: 254,
		quantity: 471,
	},
	{
		garage_id: 131,
		part_id: 255,
		quantity: 154,
	},
	{
		garage_id: 131,
		part_id: 256,
		quantity: 433,
	},
	{
		garage_id: 131,
		part_id: 257,
		quantity: 67,
	},
	{
		garage_id: 131,
		part_id: 258,
		quantity: 35,
	},
	{
		garage_id: 131,
		part_id: 259,
		quantity: 249,
	},
	{
		garage_id: 131,
		part_id: 260,
		quantity: 311,
	},
	{
		garage_id: 131,
		part_id: 261,
		quantity: 436,
	},
	{
		garage_id: 131,
		part_id: 262,
		quantity: 186,
	},
	{
		garage_id: 131,
		part_id: 263,
		quantity: 354,
	},
	{
		garage_id: 131,
		part_id: 264,
		quantity: 176,
	},
	{
		garage_id: 131,
		part_id: 265,
		quantity: 76,
	},
	{
		garage_id: 131,
		part_id: 266,
		quantity: 174,
	},
	{
		garage_id: 131,
		part_id: 267,
		quantity: 94,
	},
	{
		garage_id: 131,
		part_id: 268,
		quantity: 422,
	},
	{
		garage_id: 131,
		part_id: 269,
		quantity: 87,
	},
	{
		garage_id: 131,
		part_id: 270,
		quantity: 34,
	},
	{
		garage_id: 131,
		part_id: 271,
		quantity: 443,
	},
	{
		garage_id: 131,
		part_id: 272,
		quantity: 411,
	},
	{
		garage_id: 131,
		part_id: 273,
		quantity: 435,
	},
	{
		garage_id: 131,
		part_id: 274,
		quantity: 424,
	},
	{
		garage_id: 131,
		part_id: 275,
		quantity: 259,
	},
	{
		garage_id: 131,
		part_id: 276,
		quantity: 68,
	},
	{
		garage_id: 131,
		part_id: 277,
		quantity: 261,
	},
	{
		garage_id: 131,
		part_id: 278,
		quantity: 87,
	},
	{
		garage_id: 131,
		part_id: 279,
		quantity: 9,
	},
	{
		garage_id: 131,
		part_id: 280,
		quantity: 3,
	},
	{
		garage_id: 131,
		part_id: 281,
		quantity: 98,
	},
	{
		garage_id: 131,
		part_id: 282,
		quantity: 378,
	},
	{
		garage_id: 131,
		part_id: 283,
		quantity: 195,
	},
	{
		garage_id: 131,
		part_id: 284,
		quantity: 287,
	},
	{
		garage_id: 131,
		part_id: 285,
		quantity: 357,
	},
	{
		garage_id: 131,
		part_id: 286,
		quantity: 340,
	},
	{
		garage_id: 131,
		part_id: 287,
		quantity: 407,
	},
	{
		garage_id: 131,
		part_id: 288,
		quantity: 460,
	},
	{
		garage_id: 131,
		part_id: 289,
		quantity: 76,
	},
	{
		garage_id: 131,
		part_id: 290,
		quantity: 261,
	},
	{
		garage_id: 131,
		part_id: 291,
		quantity: 273,
	},
	{
		garage_id: 131,
		part_id: 292,
		quantity: 251,
	},
	{
		garage_id: 131,
		part_id: 293,
		quantity: 397,
	},
	{
		garage_id: 131,
		part_id: 294,
		quantity: 304,
	},
	{
		garage_id: 131,
		part_id: 295,
		quantity: 179,
	},
	{
		garage_id: 131,
		part_id: 296,
		quantity: 45,
	},
	{
		garage_id: 131,
		part_id: 297,
		quantity: 360,
	},
	{
		garage_id: 131,
		part_id: 298,
		quantity: 123,
	},
	{
		garage_id: 131,
		part_id: 299,
		quantity: 330,
	},
	{
		garage_id: 131,
		part_id: 300,
		quantity: 158,
	},
	{
		garage_id: 131,
		part_id: 301,
		quantity: 116,
	},
	{
		garage_id: 131,
		part_id: 302,
		quantity: 482,
	},
	{
		garage_id: 131,
		part_id: 303,
		quantity: 204,
	},
	{
		garage_id: 131,
		part_id: 304,
		quantity: 187,
	},
	{
		garage_id: 131,
		part_id: 305,
		quantity: 415,
	},
	{
		garage_id: 131,
		part_id: 306,
		quantity: 30,
	},
	{
		garage_id: 131,
		part_id: 307,
		quantity: 108,
	},
	{
		garage_id: 131,
		part_id: 308,
		quantity: 244,
	},
	{
		garage_id: 131,
		part_id: 309,
		quantity: 208,
	},
	{
		garage_id: 131,
		part_id: 310,
		quantity: 302,
	},
	{
		garage_id: 131,
		part_id: 311,
		quantity: 51,
	},
	{
		garage_id: 131,
		part_id: 312,
		quantity: 228,
	},
	{
		garage_id: 131,
		part_id: 313,
		quantity: 363,
	},
	{
		garage_id: 131,
		part_id: 314,
		quantity: 350,
	},
	{
		garage_id: 131,
		part_id: 315,
		quantity: 416,
	},
	{
		garage_id: 131,
		part_id: 316,
		quantity: 223,
	},
	{
		garage_id: 131,
		part_id: 317,
		quantity: 261,
	},
	{
		garage_id: 131,
		part_id: 318,
		quantity: 333,
	},
	{
		garage_id: 131,
		part_id: 319,
		quantity: 450,
	},
	{
		garage_id: 131,
		part_id: 320,
		quantity: 246,
	},
	{
		garage_id: 131,
		part_id: 321,
		quantity: 89,
	},
	{
		garage_id: 131,
		part_id: 322,
		quantity: 99,
	},
	{
		garage_id: 131,
		part_id: 323,
		quantity: 274,
	},
	{
		garage_id: 131,
		part_id: 324,
		quantity: 182,
	},
	{
		garage_id: 131,
		part_id: 325,
		quantity: 215,
	},
	{
		garage_id: 131,
		part_id: 326,
		quantity: 451,
	},
	{
		garage_id: 131,
		part_id: 327,
		quantity: 38,
	},
	{
		garage_id: 131,
		part_id: 328,
		quantity: 124,
	},
	{
		garage_id: 131,
		part_id: 329,
		quantity: 83,
	},
	{
		garage_id: 131,
		part_id: 330,
		quantity: 329,
	},
	{
		garage_id: 131,
		part_id: 331,
		quantity: 431,
	},
	{
		garage_id: 131,
		part_id: 332,
		quantity: 177,
	},
	{
		garage_id: 131,
		part_id: 333,
		quantity: 15,
	},
	{
		garage_id: 131,
		part_id: 334,
		quantity: 63,
	},
	{
		garage_id: 131,
		part_id: 335,
		quantity: 143,
	},
	{
		garage_id: 131,
		part_id: 336,
		quantity: 33,
	},
	{
		garage_id: 131,
		part_id: 337,
		quantity: 17,
	},
	{
		garage_id: 131,
		part_id: 338,
		quantity: 270,
	},
	{
		garage_id: 131,
		part_id: 339,
		quantity: 311,
	},
	{
		garage_id: 131,
		part_id: 340,
		quantity: 270,
	},
	{
		garage_id: 131,
		part_id: 341,
		quantity: 48,
	},
	{
		garage_id: 131,
		part_id: 342,
		quantity: 192,
	},
	{
		garage_id: 131,
		part_id: 343,
		quantity: 367,
	},
	{
		garage_id: 131,
		part_id: 344,
		quantity: 322,
	},
	{
		garage_id: 131,
		part_id: 345,
		quantity: 82,
	},
	{
		garage_id: 131,
		part_id: 346,
		quantity: 346,
	},
	{
		garage_id: 131,
		part_id: 347,
		quantity: 39,
	},
	{
		garage_id: 131,
		part_id: 348,
		quantity: 307,
	},
	{
		garage_id: 131,
		part_id: 349,
		quantity: 241,
	},
	{
		garage_id: 131,
		part_id: 350,
		quantity: 38,
	},
	{
		garage_id: 131,
		part_id: 351,
		quantity: 271,
	},
	{
		garage_id: 131,
		part_id: 352,
		quantity: 313,
	},
	{
		garage_id: 131,
		part_id: 353,
		quantity: 127,
	},
	{
		garage_id: 131,
		part_id: 354,
		quantity: 323,
	},
	{
		garage_id: 131,
		part_id: 355,
		quantity: 483,
	},
	{
		garage_id: 131,
		part_id: 356,
		quantity: 263,
	},
	{
		garage_id: 131,
		part_id: 357,
		quantity: 127,
	},
	{
		garage_id: 131,
		part_id: 358,
		quantity: 268,
	},
	{
		garage_id: 131,
		part_id: 359,
		quantity: 353,
	},
	{
		garage_id: 131,
		part_id: 360,
		quantity: 75,
	},
	{
		garage_id: 131,
		part_id: 361,
		quantity: 288,
	},
	{
		garage_id: 131,
		part_id: 362,
		quantity: 278,
	},
	{
		garage_id: 131,
		part_id: 363,
		quantity: 73,
	},
	{
		garage_id: 131,
		part_id: 364,
		quantity: 231,
	},
	{
		garage_id: 131,
		part_id: 365,
		quantity: 138,
	},
	{
		garage_id: 131,
		part_id: 366,
		quantity: 117,
	},
	{
		garage_id: 131,
		part_id: 367,
		quantity: 116,
	},
	{
		garage_id: 131,
		part_id: 368,
		quantity: 321,
	},
	{
		garage_id: 131,
		part_id: 369,
		quantity: 97,
	},
	{
		garage_id: 131,
		part_id: 370,
		quantity: 371,
	},
	{
		garage_id: 131,
		part_id: 371,
		quantity: 375,
	},
	{
		garage_id: 131,
		part_id: 372,
		quantity: 99,
	},
	{
		garage_id: 131,
		part_id: 373,
		quantity: 398,
	},
	{
		garage_id: 131,
		part_id: 374,
		quantity: 345,
	},
	{
		garage_id: 131,
		part_id: 375,
		quantity: 462,
	},
	{
		garage_id: 131,
		part_id: 376,
		quantity: 1,
	},
	{
		garage_id: 131,
		part_id: 377,
		quantity: 238,
	},
	{
		garage_id: 131,
		part_id: 378,
		quantity: 6,
	},
	{
		garage_id: 131,
		part_id: 379,
		quantity: 0,
	},
	{
		garage_id: 131,
		part_id: 380,
		quantity: 423,
	},
	{
		garage_id: 131,
		part_id: 381,
		quantity: 448,
	},
	{
		garage_id: 131,
		part_id: 382,
		quantity: 175,
	},
	{
		garage_id: 131,
		part_id: 383,
		quantity: 94,
	},
	{
		garage_id: 131,
		part_id: 384,
		quantity: 417,
	},
	{
		garage_id: 131,
		part_id: 385,
		quantity: 22,
	},
	{
		garage_id: 131,
		part_id: 386,
		quantity: 130,
	},
	{
		garage_id: 131,
		part_id: 387,
		quantity: 353,
	},
	{
		garage_id: 131,
		part_id: 388,
		quantity: 240,
	},
	{
		garage_id: 131,
		part_id: 389,
		quantity: 230,
	},
	{
		garage_id: 131,
		part_id: 390,
		quantity: 239,
	},
	{
		garage_id: 131,
		part_id: 391,
		quantity: 81,
	},
	{
		garage_id: 131,
		part_id: 392,
		quantity: 62,
	},
	{
		garage_id: 131,
		part_id: 393,
		quantity: 223,
	},
	{
		garage_id: 131,
		part_id: 394,
		quantity: 391,
	},
	{
		garage_id: 131,
		part_id: 395,
		quantity: 312,
	},
	{
		garage_id: 131,
		part_id: 396,
		quantity: 404,
	},
	{
		garage_id: 131,
		part_id: 397,
		quantity: 125,
	},
	{
		garage_id: 131,
		part_id: 398,
		quantity: 355,
	},
	{
		garage_id: 131,
		part_id: 399,
		quantity: 43,
	},
	{
		garage_id: 132,
		part_id: 0,
		quantity: 5,
	},
	{
		garage_id: 132,
		part_id: 1,
		quantity: 74,
	},
	{
		garage_id: 132,
		part_id: 2,
		quantity: 261,
	},
	{
		garage_id: 132,
		part_id: 3,
		quantity: 183,
	},
	{
		garage_id: 132,
		part_id: 4,
		quantity: 247,
	},
	{
		garage_id: 132,
		part_id: 5,
		quantity: 495,
	},
	{
		garage_id: 132,
		part_id: 6,
		quantity: 100,
	},
	{
		garage_id: 132,
		part_id: 7,
		quantity: 430,
	},
	{
		garage_id: 132,
		part_id: 8,
		quantity: 444,
	},
	{
		garage_id: 132,
		part_id: 9,
		quantity: 351,
	},
	{
		garage_id: 132,
		part_id: 10,
		quantity: 268,
	},
	{
		garage_id: 132,
		part_id: 11,
		quantity: 200,
	},
	{
		garage_id: 132,
		part_id: 12,
		quantity: 414,
	},
	{
		garage_id: 132,
		part_id: 13,
		quantity: 381,
	},
	{
		garage_id: 132,
		part_id: 14,
		quantity: 425,
	},
	{
		garage_id: 132,
		part_id: 15,
		quantity: 144,
	},
	{
		garage_id: 132,
		part_id: 16,
		quantity: 116,
	},
	{
		garage_id: 132,
		part_id: 17,
		quantity: 49,
	},
	{
		garage_id: 132,
		part_id: 18,
		quantity: 495,
	},
	{
		garage_id: 132,
		part_id: 19,
		quantity: 367,
	},
	{
		garage_id: 132,
		part_id: 20,
		quantity: 362,
	},
	{
		garage_id: 132,
		part_id: 21,
		quantity: 129,
	},
	{
		garage_id: 132,
		part_id: 22,
		quantity: 485,
	},
	{
		garage_id: 132,
		part_id: 23,
		quantity: 288,
	},
	{
		garage_id: 132,
		part_id: 24,
		quantity: 1,
	},
	{
		garage_id: 132,
		part_id: 25,
		quantity: 369,
	},
	{
		garage_id: 132,
		part_id: 26,
		quantity: 283,
	},
	{
		garage_id: 132,
		part_id: 27,
		quantity: 104,
	},
	{
		garage_id: 132,
		part_id: 28,
		quantity: 214,
	},
	{
		garage_id: 132,
		part_id: 29,
		quantity: 124,
	},
	{
		garage_id: 132,
		part_id: 30,
		quantity: 444,
	},
	{
		garage_id: 132,
		part_id: 31,
		quantity: 53,
	},
	{
		garage_id: 132,
		part_id: 32,
		quantity: 255,
	},
	{
		garage_id: 132,
		part_id: 33,
		quantity: 261,
	},
	{
		garage_id: 132,
		part_id: 34,
		quantity: 452,
	},
	{
		garage_id: 132,
		part_id: 35,
		quantity: 76,
	},
	{
		garage_id: 132,
		part_id: 36,
		quantity: 114,
	},
	{
		garage_id: 132,
		part_id: 37,
		quantity: 55,
	},
	{
		garage_id: 132,
		part_id: 38,
		quantity: 106,
	},
	{
		garage_id: 132,
		part_id: 39,
		quantity: 94,
	},
	{
		garage_id: 132,
		part_id: 40,
		quantity: 359,
	},
	{
		garage_id: 132,
		part_id: 41,
		quantity: 285,
	},
	{
		garage_id: 132,
		part_id: 42,
		quantity: 257,
	},
	{
		garage_id: 132,
		part_id: 43,
		quantity: 369,
	},
	{
		garage_id: 132,
		part_id: 44,
		quantity: 466,
	},
	{
		garage_id: 132,
		part_id: 45,
		quantity: 197,
	},
	{
		garage_id: 132,
		part_id: 46,
		quantity: 321,
	},
	{
		garage_id: 132,
		part_id: 47,
		quantity: 338,
	},
	{
		garage_id: 132,
		part_id: 48,
		quantity: 114,
	},
	{
		garage_id: 132,
		part_id: 49,
		quantity: 317,
	},
	{
		garage_id: 132,
		part_id: 50,
		quantity: 70,
	},
	{
		garage_id: 132,
		part_id: 51,
		quantity: 226,
	},
	{
		garage_id: 132,
		part_id: 52,
		quantity: 276,
	},
	{
		garage_id: 132,
		part_id: 53,
		quantity: 422,
	},
	{
		garage_id: 132,
		part_id: 54,
		quantity: 37,
	},
	{
		garage_id: 132,
		part_id: 55,
		quantity: 166,
	},
	{
		garage_id: 132,
		part_id: 56,
		quantity: 80,
	},
	{
		garage_id: 132,
		part_id: 57,
		quantity: 160,
	},
	{
		garage_id: 132,
		part_id: 58,
		quantity: 220,
	},
	{
		garage_id: 132,
		part_id: 59,
		quantity: 227,
	},
	{
		garage_id: 132,
		part_id: 60,
		quantity: 294,
	},
	{
		garage_id: 132,
		part_id: 61,
		quantity: 387,
	},
	{
		garage_id: 132,
		part_id: 62,
		quantity: 84,
	},
	{
		garage_id: 132,
		part_id: 63,
		quantity: 373,
	},
	{
		garage_id: 132,
		part_id: 64,
		quantity: 185,
	},
	{
		garage_id: 132,
		part_id: 65,
		quantity: 408,
	},
	{
		garage_id: 132,
		part_id: 66,
		quantity: 243,
	},
	{
		garage_id: 132,
		part_id: 67,
		quantity: 279,
	},
	{
		garage_id: 132,
		part_id: 68,
		quantity: 358,
	},
	{
		garage_id: 132,
		part_id: 69,
		quantity: 409,
	},
	{
		garage_id: 132,
		part_id: 70,
		quantity: 240,
	},
	{
		garage_id: 132,
		part_id: 71,
		quantity: 390,
	},
	{
		garage_id: 132,
		part_id: 72,
		quantity: 156,
	},
	{
		garage_id: 132,
		part_id: 73,
		quantity: 402,
	},
	{
		garage_id: 132,
		part_id: 74,
		quantity: 300,
	},
	{
		garage_id: 132,
		part_id: 75,
		quantity: 38,
	},
	{
		garage_id: 132,
		part_id: 76,
		quantity: 173,
	},
	{
		garage_id: 132,
		part_id: 77,
		quantity: 300,
	},
	{
		garage_id: 132,
		part_id: 78,
		quantity: 330,
	},
	{
		garage_id: 132,
		part_id: 79,
		quantity: 307,
	},
	{
		garage_id: 132,
		part_id: 80,
		quantity: 430,
	},
	{
		garage_id: 132,
		part_id: 81,
		quantity: 126,
	},
	{
		garage_id: 132,
		part_id: 82,
		quantity: 418,
	},
	{
		garage_id: 132,
		part_id: 83,
		quantity: 444,
	},
	{
		garage_id: 132,
		part_id: 84,
		quantity: 299,
	},
	{
		garage_id: 132,
		part_id: 85,
		quantity: 4,
	},
	{
		garage_id: 132,
		part_id: 86,
		quantity: 473,
	},
	{
		garage_id: 132,
		part_id: 87,
		quantity: 190,
	},
	{
		garage_id: 132,
		part_id: 88,
		quantity: 379,
	},
	{
		garage_id: 132,
		part_id: 89,
		quantity: 440,
	},
	{
		garage_id: 132,
		part_id: 90,
		quantity: 79,
	},
	{
		garage_id: 132,
		part_id: 91,
		quantity: 10,
	},
	{
		garage_id: 132,
		part_id: 92,
		quantity: 193,
	},
	{
		garage_id: 132,
		part_id: 93,
		quantity: 24,
	},
	{
		garage_id: 132,
		part_id: 94,
		quantity: 51,
	},
	{
		garage_id: 132,
		part_id: 95,
		quantity: 5,
	},
	{
		garage_id: 132,
		part_id: 96,
		quantity: 369,
	},
	{
		garage_id: 132,
		part_id: 97,
		quantity: 208,
	},
	{
		garage_id: 132,
		part_id: 98,
		quantity: 322,
	},
	{
		garage_id: 132,
		part_id: 99,
		quantity: 142,
	},
	{
		garage_id: 132,
		part_id: 100,
		quantity: 462,
	},
	{
		garage_id: 132,
		part_id: 101,
		quantity: 458,
	},
	{
		garage_id: 132,
		part_id: 102,
		quantity: 331,
	},
	{
		garage_id: 132,
		part_id: 103,
		quantity: 288,
	},
	{
		garage_id: 132,
		part_id: 104,
		quantity: 478,
	},
	{
		garage_id: 132,
		part_id: 105,
		quantity: 107,
	},
	{
		garage_id: 132,
		part_id: 106,
		quantity: 166,
	},
	{
		garage_id: 132,
		part_id: 107,
		quantity: 158,
	},
	{
		garage_id: 132,
		part_id: 108,
		quantity: 197,
	},
	{
		garage_id: 132,
		part_id: 109,
		quantity: 300,
	},
	{
		garage_id: 132,
		part_id: 110,
		quantity: 321,
	},
	{
		garage_id: 132,
		part_id: 111,
		quantity: 435,
	},
	{
		garage_id: 132,
		part_id: 112,
		quantity: 98,
	},
	{
		garage_id: 132,
		part_id: 113,
		quantity: 217,
	},
	{
		garage_id: 132,
		part_id: 114,
		quantity: 114,
	},
	{
		garage_id: 132,
		part_id: 115,
		quantity: 325,
	},
	{
		garage_id: 132,
		part_id: 116,
		quantity: 205,
	},
	{
		garage_id: 132,
		part_id: 117,
		quantity: 332,
	},
	{
		garage_id: 132,
		part_id: 118,
		quantity: 39,
	},
	{
		garage_id: 132,
		part_id: 119,
		quantity: 312,
	},
	{
		garage_id: 132,
		part_id: 120,
		quantity: 229,
	},
	{
		garage_id: 132,
		part_id: 121,
		quantity: 305,
	},
	{
		garage_id: 132,
		part_id: 122,
		quantity: 326,
	},
	{
		garage_id: 132,
		part_id: 123,
		quantity: 340,
	},
	{
		garage_id: 132,
		part_id: 124,
		quantity: 28,
	},
	{
		garage_id: 132,
		part_id: 125,
		quantity: 256,
	},
	{
		garage_id: 132,
		part_id: 126,
		quantity: 110,
	},
	{
		garage_id: 132,
		part_id: 127,
		quantity: 428,
	},
	{
		garage_id: 132,
		part_id: 128,
		quantity: 338,
	},
	{
		garage_id: 132,
		part_id: 129,
		quantity: 224,
	},
	{
		garage_id: 132,
		part_id: 130,
		quantity: 120,
	},
	{
		garage_id: 132,
		part_id: 131,
		quantity: 33,
	},
	{
		garage_id: 132,
		part_id: 132,
		quantity: 339,
	},
	{
		garage_id: 132,
		part_id: 133,
		quantity: 428,
	},
	{
		garage_id: 132,
		part_id: 134,
		quantity: 214,
	},
	{
		garage_id: 132,
		part_id: 135,
		quantity: 3,
	},
	{
		garage_id: 132,
		part_id: 136,
		quantity: 264,
	},
	{
		garage_id: 132,
		part_id: 137,
		quantity: 154,
	},
	{
		garage_id: 132,
		part_id: 138,
		quantity: 418,
	},
	{
		garage_id: 132,
		part_id: 139,
		quantity: 20,
	},
	{
		garage_id: 132,
		part_id: 140,
		quantity: 126,
	},
	{
		garage_id: 132,
		part_id: 141,
		quantity: 130,
	},
	{
		garage_id: 132,
		part_id: 142,
		quantity: 180,
	},
	{
		garage_id: 132,
		part_id: 143,
		quantity: 313,
	},
	{
		garage_id: 132,
		part_id: 144,
		quantity: 307,
	},
	{
		garage_id: 132,
		part_id: 145,
		quantity: 271,
	},
	{
		garage_id: 132,
		part_id: 146,
		quantity: 271,
	},
	{
		garage_id: 132,
		part_id: 147,
		quantity: 211,
	},
	{
		garage_id: 132,
		part_id: 148,
		quantity: 178,
	},
	{
		garage_id: 132,
		part_id: 149,
		quantity: 365,
	},
	{
		garage_id: 132,
		part_id: 150,
		quantity: 461,
	},
	{
		garage_id: 132,
		part_id: 151,
		quantity: 208,
	},
	{
		garage_id: 132,
		part_id: 152,
		quantity: 385,
	},
	{
		garage_id: 132,
		part_id: 153,
		quantity: 140,
	},
	{
		garage_id: 132,
		part_id: 154,
		quantity: 153,
	},
	{
		garage_id: 132,
		part_id: 155,
		quantity: 149,
	},
	{
		garage_id: 132,
		part_id: 156,
		quantity: 388,
	},
	{
		garage_id: 132,
		part_id: 157,
		quantity: 290,
	},
	{
		garage_id: 132,
		part_id: 158,
		quantity: 292,
	},
	{
		garage_id: 132,
		part_id: 159,
		quantity: 174,
	},
	{
		garage_id: 132,
		part_id: 160,
		quantity: 398,
	},
	{
		garage_id: 132,
		part_id: 161,
		quantity: 388,
	},
	{
		garage_id: 132,
		part_id: 162,
		quantity: 270,
	},
	{
		garage_id: 132,
		part_id: 163,
		quantity: 98,
	},
	{
		garage_id: 132,
		part_id: 164,
		quantity: 143,
	},
	{
		garage_id: 132,
		part_id: 165,
		quantity: 468,
	},
	{
		garage_id: 132,
		part_id: 166,
		quantity: 11,
	},
	{
		garage_id: 132,
		part_id: 167,
		quantity: 186,
	},
	{
		garage_id: 132,
		part_id: 168,
		quantity: 491,
	},
	{
		garage_id: 132,
		part_id: 169,
		quantity: 305,
	},
	{
		garage_id: 132,
		part_id: 170,
		quantity: 235,
	},
	{
		garage_id: 132,
		part_id: 171,
		quantity: 463,
	},
	{
		garage_id: 132,
		part_id: 172,
		quantity: 476,
	},
	{
		garage_id: 132,
		part_id: 173,
		quantity: 238,
	},
	{
		garage_id: 132,
		part_id: 174,
		quantity: 295,
	},
	{
		garage_id: 132,
		part_id: 175,
		quantity: 467,
	},
	{
		garage_id: 132,
		part_id: 176,
		quantity: 11,
	},
	{
		garage_id: 132,
		part_id: 177,
		quantity: 75,
	},
	{
		garage_id: 132,
		part_id: 178,
		quantity: 476,
	},
	{
		garage_id: 132,
		part_id: 179,
		quantity: 437,
	},
	{
		garage_id: 132,
		part_id: 180,
		quantity: 31,
	},
	{
		garage_id: 132,
		part_id: 181,
		quantity: 306,
	},
	{
		garage_id: 132,
		part_id: 182,
		quantity: 487,
	},
	{
		garage_id: 132,
		part_id: 183,
		quantity: 74,
	},
	{
		garage_id: 132,
		part_id: 184,
		quantity: 119,
	},
	{
		garage_id: 132,
		part_id: 185,
		quantity: 102,
	},
	{
		garage_id: 132,
		part_id: 186,
		quantity: 156,
	},
	{
		garage_id: 132,
		part_id: 187,
		quantity: 370,
	},
	{
		garage_id: 132,
		part_id: 188,
		quantity: 211,
	},
	{
		garage_id: 132,
		part_id: 189,
		quantity: 395,
	},
	{
		garage_id: 132,
		part_id: 190,
		quantity: 395,
	},
	{
		garage_id: 132,
		part_id: 191,
		quantity: 284,
	},
	{
		garage_id: 132,
		part_id: 192,
		quantity: 138,
	},
	{
		garage_id: 132,
		part_id: 193,
		quantity: 64,
	},
	{
		garage_id: 132,
		part_id: 194,
		quantity: 430,
	},
	{
		garage_id: 132,
		part_id: 195,
		quantity: 416,
	},
	{
		garage_id: 132,
		part_id: 196,
		quantity: 230,
	},
	{
		garage_id: 132,
		part_id: 197,
		quantity: 86,
	},
	{
		garage_id: 132,
		part_id: 198,
		quantity: 417,
	},
	{
		garage_id: 132,
		part_id: 199,
		quantity: 15,
	},
	{
		garage_id: 132,
		part_id: 200,
		quantity: 200,
	},
	{
		garage_id: 132,
		part_id: 201,
		quantity: 337,
	},
	{
		garage_id: 132,
		part_id: 202,
		quantity: 309,
	},
	{
		garage_id: 132,
		part_id: 203,
		quantity: 479,
	},
	{
		garage_id: 132,
		part_id: 204,
		quantity: 416,
	},
	{
		garage_id: 132,
		part_id: 205,
		quantity: 385,
	},
	{
		garage_id: 132,
		part_id: 206,
		quantity: 164,
	},
	{
		garage_id: 132,
		part_id: 207,
		quantity: 438,
	},
	{
		garage_id: 132,
		part_id: 208,
		quantity: 483,
	},
	{
		garage_id: 132,
		part_id: 209,
		quantity: 226,
	},
	{
		garage_id: 132,
		part_id: 210,
		quantity: 0,
	},
	{
		garage_id: 132,
		part_id: 211,
		quantity: 418,
	},
	{
		garage_id: 132,
		part_id: 212,
		quantity: 52,
	},
	{
		garage_id: 132,
		part_id: 213,
		quantity: 41,
	},
	{
		garage_id: 132,
		part_id: 214,
		quantity: 37,
	},
	{
		garage_id: 132,
		part_id: 215,
		quantity: 330,
	},
	{
		garage_id: 132,
		part_id: 216,
		quantity: 380,
	},
	{
		garage_id: 132,
		part_id: 217,
		quantity: 382,
	},
	{
		garage_id: 132,
		part_id: 218,
		quantity: 486,
	},
	{
		garage_id: 132,
		part_id: 219,
		quantity: 56,
	},
	{
		garage_id: 132,
		part_id: 220,
		quantity: 443,
	},
	{
		garage_id: 132,
		part_id: 221,
		quantity: 209,
	},
	{
		garage_id: 132,
		part_id: 222,
		quantity: 408,
	},
	{
		garage_id: 132,
		part_id: 223,
		quantity: 164,
	},
	{
		garage_id: 132,
		part_id: 224,
		quantity: 359,
	},
	{
		garage_id: 132,
		part_id: 225,
		quantity: 429,
	},
	{
		garage_id: 132,
		part_id: 226,
		quantity: 367,
	},
	{
		garage_id: 132,
		part_id: 227,
		quantity: 134,
	},
	{
		garage_id: 132,
		part_id: 228,
		quantity: 410,
	},
	{
		garage_id: 132,
		part_id: 229,
		quantity: 375,
	},
	{
		garage_id: 132,
		part_id: 230,
		quantity: 20,
	},
	{
		garage_id: 132,
		part_id: 231,
		quantity: 323,
	},
	{
		garage_id: 132,
		part_id: 232,
		quantity: 235,
	},
	{
		garage_id: 132,
		part_id: 233,
		quantity: 143,
	},
	{
		garage_id: 132,
		part_id: 234,
		quantity: 179,
	},
	{
		garage_id: 132,
		part_id: 235,
		quantity: 198,
	},
	{
		garage_id: 132,
		part_id: 236,
		quantity: 122,
	},
	{
		garage_id: 132,
		part_id: 237,
		quantity: 424,
	},
	{
		garage_id: 132,
		part_id: 238,
		quantity: 474,
	},
	{
		garage_id: 132,
		part_id: 239,
		quantity: 451,
	},
	{
		garage_id: 132,
		part_id: 240,
		quantity: 355,
	},
	{
		garage_id: 132,
		part_id: 241,
		quantity: 259,
	},
	{
		garage_id: 132,
		part_id: 242,
		quantity: 448,
	},
	{
		garage_id: 132,
		part_id: 243,
		quantity: 43,
	},
	{
		garage_id: 132,
		part_id: 244,
		quantity: 221,
	},
	{
		garage_id: 132,
		part_id: 245,
		quantity: 100,
	},
	{
		garage_id: 132,
		part_id: 246,
		quantity: 247,
	},
	{
		garage_id: 132,
		part_id: 247,
		quantity: 346,
	},
	{
		garage_id: 132,
		part_id: 248,
		quantity: 210,
	},
	{
		garage_id: 132,
		part_id: 249,
		quantity: 327,
	},
	{
		garage_id: 132,
		part_id: 250,
		quantity: 111,
	},
	{
		garage_id: 132,
		part_id: 251,
		quantity: 81,
	},
	{
		garage_id: 132,
		part_id: 252,
		quantity: 176,
	},
	{
		garage_id: 132,
		part_id: 253,
		quantity: 196,
	},
	{
		garage_id: 132,
		part_id: 254,
		quantity: 204,
	},
	{
		garage_id: 132,
		part_id: 255,
		quantity: 39,
	},
	{
		garage_id: 132,
		part_id: 256,
		quantity: 303,
	},
	{
		garage_id: 132,
		part_id: 257,
		quantity: 257,
	},
	{
		garage_id: 132,
		part_id: 258,
		quantity: 271,
	},
	{
		garage_id: 132,
		part_id: 259,
		quantity: 129,
	},
	{
		garage_id: 132,
		part_id: 260,
		quantity: 460,
	},
	{
		garage_id: 132,
		part_id: 261,
		quantity: 252,
	},
	{
		garage_id: 132,
		part_id: 262,
		quantity: 484,
	},
	{
		garage_id: 132,
		part_id: 263,
		quantity: 311,
	},
	{
		garage_id: 132,
		part_id: 264,
		quantity: 276,
	},
	{
		garage_id: 132,
		part_id: 265,
		quantity: 39,
	},
	{
		garage_id: 132,
		part_id: 266,
		quantity: 76,
	},
	{
		garage_id: 132,
		part_id: 267,
		quantity: 477,
	},
	{
		garage_id: 132,
		part_id: 268,
		quantity: 359,
	},
	{
		garage_id: 132,
		part_id: 269,
		quantity: 7,
	},
	{
		garage_id: 132,
		part_id: 270,
		quantity: 252,
	},
	{
		garage_id: 132,
		part_id: 271,
		quantity: 223,
	},
	{
		garage_id: 132,
		part_id: 272,
		quantity: 206,
	},
	{
		garage_id: 132,
		part_id: 273,
		quantity: 174,
	},
	{
		garage_id: 132,
		part_id: 274,
		quantity: 287,
	},
	{
		garage_id: 132,
		part_id: 275,
		quantity: 319,
	},
	{
		garage_id: 132,
		part_id: 276,
		quantity: 156,
	},
	{
		garage_id: 132,
		part_id: 277,
		quantity: 180,
	},
	{
		garage_id: 132,
		part_id: 278,
		quantity: 203,
	},
	{
		garage_id: 132,
		part_id: 279,
		quantity: 82,
	},
	{
		garage_id: 132,
		part_id: 280,
		quantity: 424,
	},
	{
		garage_id: 132,
		part_id: 281,
		quantity: 63,
	},
	{
		garage_id: 132,
		part_id: 282,
		quantity: 225,
	},
	{
		garage_id: 132,
		part_id: 283,
		quantity: 149,
	},
	{
		garage_id: 132,
		part_id: 284,
		quantity: 337,
	},
	{
		garage_id: 132,
		part_id: 285,
		quantity: 267,
	},
	{
		garage_id: 132,
		part_id: 286,
		quantity: 57,
	},
	{
		garage_id: 132,
		part_id: 287,
		quantity: 318,
	},
	{
		garage_id: 132,
		part_id: 288,
		quantity: 192,
	},
	{
		garage_id: 132,
		part_id: 289,
		quantity: 308,
	},
	{
		garage_id: 132,
		part_id: 290,
		quantity: 66,
	},
	{
		garage_id: 132,
		part_id: 291,
		quantity: 248,
	},
	{
		garage_id: 132,
		part_id: 292,
		quantity: 14,
	},
	{
		garage_id: 132,
		part_id: 293,
		quantity: 362,
	},
	{
		garage_id: 132,
		part_id: 294,
		quantity: 177,
	},
	{
		garage_id: 132,
		part_id: 295,
		quantity: 326,
	},
	{
		garage_id: 132,
		part_id: 296,
		quantity: 197,
	},
	{
		garage_id: 132,
		part_id: 297,
		quantity: 203,
	},
	{
		garage_id: 132,
		part_id: 298,
		quantity: 220,
	},
	{
		garage_id: 132,
		part_id: 299,
		quantity: 478,
	},
	{
		garage_id: 132,
		part_id: 300,
		quantity: 331,
	},
	{
		garage_id: 132,
		part_id: 301,
		quantity: 301,
	},
	{
		garage_id: 132,
		part_id: 302,
		quantity: 251,
	},
	{
		garage_id: 132,
		part_id: 303,
		quantity: 431,
	},
	{
		garage_id: 132,
		part_id: 304,
		quantity: 492,
	},
	{
		garage_id: 132,
		part_id: 305,
		quantity: 366,
	},
	{
		garage_id: 132,
		part_id: 306,
		quantity: 375,
	},
	{
		garage_id: 132,
		part_id: 307,
		quantity: 432,
	},
	{
		garage_id: 132,
		part_id: 308,
		quantity: 412,
	},
	{
		garage_id: 132,
		part_id: 309,
		quantity: 33,
	},
	{
		garage_id: 132,
		part_id: 310,
		quantity: 86,
	},
	{
		garage_id: 132,
		part_id: 311,
		quantity: 251,
	},
	{
		garage_id: 132,
		part_id: 312,
		quantity: 212,
	},
	{
		garage_id: 132,
		part_id: 313,
		quantity: 134,
	},
	{
		garage_id: 132,
		part_id: 314,
		quantity: 192,
	},
	{
		garage_id: 132,
		part_id: 315,
		quantity: 278,
	},
	{
		garage_id: 132,
		part_id: 316,
		quantity: 188,
	},
	{
		garage_id: 132,
		part_id: 317,
		quantity: 404,
	},
	{
		garage_id: 132,
		part_id: 318,
		quantity: 343,
	},
	{
		garage_id: 132,
		part_id: 319,
		quantity: 366,
	},
	{
		garage_id: 132,
		part_id: 320,
		quantity: 62,
	},
	{
		garage_id: 132,
		part_id: 321,
		quantity: 315,
	},
	{
		garage_id: 132,
		part_id: 322,
		quantity: 58,
	},
	{
		garage_id: 132,
		part_id: 323,
		quantity: 363,
	},
	{
		garage_id: 132,
		part_id: 324,
		quantity: 225,
	},
	{
		garage_id: 132,
		part_id: 325,
		quantity: 371,
	},
	{
		garage_id: 132,
		part_id: 326,
		quantity: 243,
	},
	{
		garage_id: 132,
		part_id: 327,
		quantity: 461,
	},
	{
		garage_id: 132,
		part_id: 328,
		quantity: 240,
	},
	{
		garage_id: 132,
		part_id: 329,
		quantity: 227,
	},
	{
		garage_id: 132,
		part_id: 330,
		quantity: 483,
	},
	{
		garage_id: 132,
		part_id: 331,
		quantity: 319,
	},
	{
		garage_id: 132,
		part_id: 332,
		quantity: 465,
	},
	{
		garage_id: 132,
		part_id: 333,
		quantity: 212,
	},
	{
		garage_id: 132,
		part_id: 334,
		quantity: 440,
	},
	{
		garage_id: 132,
		part_id: 335,
		quantity: 460,
	},
	{
		garage_id: 132,
		part_id: 336,
		quantity: 4,
	},
	{
		garage_id: 132,
		part_id: 337,
		quantity: 419,
	},
	{
		garage_id: 132,
		part_id: 338,
		quantity: 226,
	},
	{
		garage_id: 132,
		part_id: 339,
		quantity: 274,
	},
	{
		garage_id: 132,
		part_id: 340,
		quantity: 43,
	},
	{
		garage_id: 132,
		part_id: 341,
		quantity: 154,
	},
	{
		garage_id: 132,
		part_id: 342,
		quantity: 44,
	},
	{
		garage_id: 132,
		part_id: 343,
		quantity: 101,
	},
	{
		garage_id: 132,
		part_id: 344,
		quantity: 496,
	},
	{
		garage_id: 132,
		part_id: 345,
		quantity: 452,
	},
	{
		garage_id: 132,
		part_id: 346,
		quantity: 182,
	},
	{
		garage_id: 132,
		part_id: 347,
		quantity: 248,
	},
	{
		garage_id: 132,
		part_id: 348,
		quantity: 88,
	},
	{
		garage_id: 132,
		part_id: 349,
		quantity: 329,
	},
	{
		garage_id: 132,
		part_id: 350,
		quantity: 103,
	},
	{
		garage_id: 132,
		part_id: 351,
		quantity: 360,
	},
	{
		garage_id: 132,
		part_id: 352,
		quantity: 300,
	},
	{
		garage_id: 132,
		part_id: 353,
		quantity: 249,
	},
	{
		garage_id: 132,
		part_id: 354,
		quantity: 490,
	},
	{
		garage_id: 132,
		part_id: 355,
		quantity: 492,
	},
	{
		garage_id: 132,
		part_id: 356,
		quantity: 77,
	},
	{
		garage_id: 132,
		part_id: 357,
		quantity: 170,
	},
	{
		garage_id: 132,
		part_id: 358,
		quantity: 424,
	},
	{
		garage_id: 132,
		part_id: 359,
		quantity: 269,
	},
	{
		garage_id: 132,
		part_id: 360,
		quantity: 233,
	},
	{
		garage_id: 132,
		part_id: 361,
		quantity: 310,
	},
	{
		garage_id: 132,
		part_id: 362,
		quantity: 385,
	},
	{
		garage_id: 132,
		part_id: 363,
		quantity: 326,
	},
	{
		garage_id: 132,
		part_id: 364,
		quantity: 248,
	},
	{
		garage_id: 132,
		part_id: 365,
		quantity: 472,
	},
	{
		garage_id: 132,
		part_id: 366,
		quantity: 28,
	},
	{
		garage_id: 132,
		part_id: 367,
		quantity: 494,
	},
	{
		garage_id: 132,
		part_id: 368,
		quantity: 474,
	},
	{
		garage_id: 132,
		part_id: 369,
		quantity: 320,
	},
	{
		garage_id: 132,
		part_id: 370,
		quantity: 491,
	},
	{
		garage_id: 132,
		part_id: 371,
		quantity: 248,
	},
	{
		garage_id: 132,
		part_id: 372,
		quantity: 75,
	},
	{
		garage_id: 132,
		part_id: 373,
		quantity: 453,
	},
	{
		garage_id: 132,
		part_id: 374,
		quantity: 132,
	},
	{
		garage_id: 132,
		part_id: 375,
		quantity: 250,
	},
	{
		garage_id: 132,
		part_id: 376,
		quantity: 211,
	},
	{
		garage_id: 132,
		part_id: 377,
		quantity: 283,
	},
	{
		garage_id: 132,
		part_id: 378,
		quantity: 447,
	},
	{
		garage_id: 132,
		part_id: 379,
		quantity: 231,
	},
	{
		garage_id: 132,
		part_id: 380,
		quantity: 300,
	},
	{
		garage_id: 132,
		part_id: 381,
		quantity: 102,
	},
	{
		garage_id: 132,
		part_id: 382,
		quantity: 264,
	},
	{
		garage_id: 132,
		part_id: 383,
		quantity: 110,
	},
	{
		garage_id: 132,
		part_id: 384,
		quantity: 263,
	},
	{
		garage_id: 132,
		part_id: 385,
		quantity: 58,
	},
	{
		garage_id: 132,
		part_id: 386,
		quantity: 21,
	},
	{
		garage_id: 132,
		part_id: 387,
		quantity: 383,
	},
	{
		garage_id: 132,
		part_id: 388,
		quantity: 236,
	},
	{
		garage_id: 132,
		part_id: 389,
		quantity: 227,
	},
	{
		garage_id: 132,
		part_id: 390,
		quantity: 394,
	},
	{
		garage_id: 132,
		part_id: 391,
		quantity: 108,
	},
	{
		garage_id: 132,
		part_id: 392,
		quantity: 399,
	},
	{
		garage_id: 132,
		part_id: 393,
		quantity: 396,
	},
	{
		garage_id: 132,
		part_id: 394,
		quantity: 56,
	},
	{
		garage_id: 132,
		part_id: 395,
		quantity: 329,
	},
	{
		garage_id: 132,
		part_id: 396,
		quantity: 35,
	},
	{
		garage_id: 132,
		part_id: 397,
		quantity: 142,
	},
	{
		garage_id: 132,
		part_id: 398,
		quantity: 292,
	},
	{
		garage_id: 132,
		part_id: 399,
		quantity: 195,
	},
	{
		garage_id: 133,
		part_id: 0,
		quantity: 397,
	},
	{
		garage_id: 133,
		part_id: 1,
		quantity: 1,
	},
	{
		garage_id: 133,
		part_id: 2,
		quantity: 491,
	},
	{
		garage_id: 133,
		part_id: 3,
		quantity: 199,
	},
	{
		garage_id: 133,
		part_id: 4,
		quantity: 435,
	},
	{
		garage_id: 133,
		part_id: 5,
		quantity: 447,
	},
	{
		garage_id: 133,
		part_id: 6,
		quantity: 218,
	},
	{
		garage_id: 133,
		part_id: 7,
		quantity: 379,
	},
	{
		garage_id: 133,
		part_id: 8,
		quantity: 331,
	},
	{
		garage_id: 133,
		part_id: 9,
		quantity: 437,
	},
	{
		garage_id: 133,
		part_id: 10,
		quantity: 499,
	},
	{
		garage_id: 133,
		part_id: 11,
		quantity: 358,
	},
	{
		garage_id: 133,
		part_id: 12,
		quantity: 23,
	},
	{
		garage_id: 133,
		part_id: 13,
		quantity: 311,
	},
	{
		garage_id: 133,
		part_id: 14,
		quantity: 109,
	},
	{
		garage_id: 133,
		part_id: 15,
		quantity: 138,
	},
	{
		garage_id: 133,
		part_id: 16,
		quantity: 292,
	},
	{
		garage_id: 133,
		part_id: 17,
		quantity: 131,
	},
	{
		garage_id: 133,
		part_id: 18,
		quantity: 29,
	},
	{
		garage_id: 133,
		part_id: 19,
		quantity: 418,
	},
	{
		garage_id: 133,
		part_id: 20,
		quantity: 24,
	},
	{
		garage_id: 133,
		part_id: 21,
		quantity: 346,
	},
	{
		garage_id: 133,
		part_id: 22,
		quantity: 62,
	},
	{
		garage_id: 133,
		part_id: 23,
		quantity: 148,
	},
	{
		garage_id: 133,
		part_id: 24,
		quantity: 495,
	},
	{
		garage_id: 133,
		part_id: 25,
		quantity: 408,
	},
	{
		garage_id: 133,
		part_id: 26,
		quantity: 385,
	},
	{
		garage_id: 133,
		part_id: 27,
		quantity: 228,
	},
	{
		garage_id: 133,
		part_id: 28,
		quantity: 296,
	},
	{
		garage_id: 133,
		part_id: 29,
		quantity: 72,
	},
	{
		garage_id: 133,
		part_id: 30,
		quantity: 230,
	},
	{
		garage_id: 133,
		part_id: 31,
		quantity: 108,
	},
	{
		garage_id: 133,
		part_id: 32,
		quantity: 110,
	},
	{
		garage_id: 133,
		part_id: 33,
		quantity: 191,
	},
	{
		garage_id: 133,
		part_id: 34,
		quantity: 250,
	},
	{
		garage_id: 133,
		part_id: 35,
		quantity: 334,
	},
	{
		garage_id: 133,
		part_id: 36,
		quantity: 435,
	},
	{
		garage_id: 133,
		part_id: 37,
		quantity: 252,
	},
	{
		garage_id: 133,
		part_id: 38,
		quantity: 213,
	},
	{
		garage_id: 133,
		part_id: 39,
		quantity: 114,
	},
	{
		garage_id: 133,
		part_id: 40,
		quantity: 399,
	},
	{
		garage_id: 133,
		part_id: 41,
		quantity: 99,
	},
	{
		garage_id: 133,
		part_id: 42,
		quantity: 112,
	},
	{
		garage_id: 133,
		part_id: 43,
		quantity: 412,
	},
	{
		garage_id: 133,
		part_id: 44,
		quantity: 331,
	},
	{
		garage_id: 133,
		part_id: 45,
		quantity: 43,
	},
	{
		garage_id: 133,
		part_id: 46,
		quantity: 479,
	},
	{
		garage_id: 133,
		part_id: 47,
		quantity: 202,
	},
	{
		garage_id: 133,
		part_id: 48,
		quantity: 414,
	},
	{
		garage_id: 133,
		part_id: 49,
		quantity: 32,
	},
	{
		garage_id: 133,
		part_id: 50,
		quantity: 143,
	},
	{
		garage_id: 133,
		part_id: 51,
		quantity: 54,
	},
	{
		garage_id: 133,
		part_id: 52,
		quantity: 306,
	},
	{
		garage_id: 133,
		part_id: 53,
		quantity: 8,
	},
	{
		garage_id: 133,
		part_id: 54,
		quantity: 126,
	},
	{
		garage_id: 133,
		part_id: 55,
		quantity: 213,
	},
	{
		garage_id: 133,
		part_id: 56,
		quantity: 240,
	},
	{
		garage_id: 133,
		part_id: 57,
		quantity: 62,
	},
	{
		garage_id: 133,
		part_id: 58,
		quantity: 196,
	},
	{
		garage_id: 133,
		part_id: 59,
		quantity: 471,
	},
	{
		garage_id: 133,
		part_id: 60,
		quantity: 443,
	},
	{
		garage_id: 133,
		part_id: 61,
		quantity: 23,
	},
	{
		garage_id: 133,
		part_id: 62,
		quantity: 399,
	},
	{
		garage_id: 133,
		part_id: 63,
		quantity: 66,
	},
	{
		garage_id: 133,
		part_id: 64,
		quantity: 298,
	},
	{
		garage_id: 133,
		part_id: 65,
		quantity: 488,
	},
	{
		garage_id: 133,
		part_id: 66,
		quantity: 492,
	},
	{
		garage_id: 133,
		part_id: 67,
		quantity: 110,
	},
	{
		garage_id: 133,
		part_id: 68,
		quantity: 381,
	},
	{
		garage_id: 133,
		part_id: 69,
		quantity: 316,
	},
	{
		garage_id: 133,
		part_id: 70,
		quantity: 190,
	},
	{
		garage_id: 133,
		part_id: 71,
		quantity: 176,
	},
	{
		garage_id: 133,
		part_id: 72,
		quantity: 104,
	},
	{
		garage_id: 133,
		part_id: 73,
		quantity: 106,
	},
	{
		garage_id: 133,
		part_id: 74,
		quantity: 387,
	},
	{
		garage_id: 133,
		part_id: 75,
		quantity: 388,
	},
	{
		garage_id: 133,
		part_id: 76,
		quantity: 94,
	},
	{
		garage_id: 133,
		part_id: 77,
		quantity: 169,
	},
	{
		garage_id: 133,
		part_id: 78,
		quantity: 63,
	},
	{
		garage_id: 133,
		part_id: 79,
		quantity: 225,
	},
	{
		garage_id: 133,
		part_id: 80,
		quantity: 498,
	},
	{
		garage_id: 133,
		part_id: 81,
		quantity: 98,
	},
	{
		garage_id: 133,
		part_id: 82,
		quantity: 37,
	},
	{
		garage_id: 133,
		part_id: 83,
		quantity: 349,
	},
	{
		garage_id: 133,
		part_id: 84,
		quantity: 405,
	},
	{
		garage_id: 133,
		part_id: 85,
		quantity: 126,
	},
	{
		garage_id: 133,
		part_id: 86,
		quantity: 215,
	},
	{
		garage_id: 133,
		part_id: 87,
		quantity: 185,
	},
	{
		garage_id: 133,
		part_id: 88,
		quantity: 380,
	},
	{
		garage_id: 133,
		part_id: 89,
		quantity: 19,
	},
	{
		garage_id: 133,
		part_id: 90,
		quantity: 136,
	},
	{
		garage_id: 133,
		part_id: 91,
		quantity: 430,
	},
	{
		garage_id: 133,
		part_id: 92,
		quantity: 168,
	},
	{
		garage_id: 133,
		part_id: 93,
		quantity: 25,
	},
	{
		garage_id: 133,
		part_id: 94,
		quantity: 308,
	},
	{
		garage_id: 133,
		part_id: 95,
		quantity: 15,
	},
	{
		garage_id: 133,
		part_id: 96,
		quantity: 174,
	},
	{
		garage_id: 133,
		part_id: 97,
		quantity: 475,
	},
	{
		garage_id: 133,
		part_id: 98,
		quantity: 20,
	},
	{
		garage_id: 133,
		part_id: 99,
		quantity: 220,
	},
	{
		garage_id: 133,
		part_id: 100,
		quantity: 263,
	},
	{
		garage_id: 133,
		part_id: 101,
		quantity: 316,
	},
	{
		garage_id: 133,
		part_id: 102,
		quantity: 447,
	},
	{
		garage_id: 133,
		part_id: 103,
		quantity: 90,
	},
	{
		garage_id: 133,
		part_id: 104,
		quantity: 289,
	},
	{
		garage_id: 133,
		part_id: 105,
		quantity: 195,
	},
	{
		garage_id: 133,
		part_id: 106,
		quantity: 495,
	},
	{
		garage_id: 133,
		part_id: 107,
		quantity: 144,
	},
	{
		garage_id: 133,
		part_id: 108,
		quantity: 218,
	},
	{
		garage_id: 133,
		part_id: 109,
		quantity: 373,
	},
	{
		garage_id: 133,
		part_id: 110,
		quantity: 490,
	},
	{
		garage_id: 133,
		part_id: 111,
		quantity: 55,
	},
	{
		garage_id: 133,
		part_id: 112,
		quantity: 306,
	},
	{
		garage_id: 133,
		part_id: 113,
		quantity: 397,
	},
	{
		garage_id: 133,
		part_id: 114,
		quantity: 48,
	},
	{
		garage_id: 133,
		part_id: 115,
		quantity: 168,
	},
	{
		garage_id: 133,
		part_id: 116,
		quantity: 46,
	},
	{
		garage_id: 133,
		part_id: 117,
		quantity: 364,
	},
	{
		garage_id: 133,
		part_id: 118,
		quantity: 71,
	},
	{
		garage_id: 133,
		part_id: 119,
		quantity: 458,
	},
	{
		garage_id: 133,
		part_id: 120,
		quantity: 51,
	},
	{
		garage_id: 133,
		part_id: 121,
		quantity: 13,
	},
	{
		garage_id: 133,
		part_id: 122,
		quantity: 423,
	},
	{
		garage_id: 133,
		part_id: 123,
		quantity: 5,
	},
	{
		garage_id: 133,
		part_id: 124,
		quantity: 343,
	},
	{
		garage_id: 133,
		part_id: 125,
		quantity: 482,
	},
	{
		garage_id: 133,
		part_id: 126,
		quantity: 490,
	},
	{
		garage_id: 133,
		part_id: 127,
		quantity: 13,
	},
	{
		garage_id: 133,
		part_id: 128,
		quantity: 263,
	},
	{
		garage_id: 133,
		part_id: 129,
		quantity: 339,
	},
	{
		garage_id: 133,
		part_id: 130,
		quantity: 428,
	},
	{
		garage_id: 133,
		part_id: 131,
		quantity: 463,
	},
	{
		garage_id: 133,
		part_id: 132,
		quantity: 446,
	},
	{
		garage_id: 133,
		part_id: 133,
		quantity: 356,
	},
	{
		garage_id: 133,
		part_id: 134,
		quantity: 255,
	},
	{
		garage_id: 133,
		part_id: 135,
		quantity: 75,
	},
	{
		garage_id: 133,
		part_id: 136,
		quantity: 365,
	},
	{
		garage_id: 133,
		part_id: 137,
		quantity: 30,
	},
	{
		garage_id: 133,
		part_id: 138,
		quantity: 495,
	},
	{
		garage_id: 133,
		part_id: 139,
		quantity: 30,
	},
	{
		garage_id: 133,
		part_id: 140,
		quantity: 413,
	},
	{
		garage_id: 133,
		part_id: 141,
		quantity: 71,
	},
	{
		garage_id: 133,
		part_id: 142,
		quantity: 308,
	},
	{
		garage_id: 133,
		part_id: 143,
		quantity: 317,
	},
	{
		garage_id: 133,
		part_id: 144,
		quantity: 255,
	},
	{
		garage_id: 133,
		part_id: 145,
		quantity: 359,
	},
	{
		garage_id: 133,
		part_id: 146,
		quantity: 116,
	},
	{
		garage_id: 133,
		part_id: 147,
		quantity: 113,
	},
	{
		garage_id: 133,
		part_id: 148,
		quantity: 61,
	},
	{
		garage_id: 133,
		part_id: 149,
		quantity: 447,
	},
	{
		garage_id: 133,
		part_id: 150,
		quantity: 204,
	},
	{
		garage_id: 133,
		part_id: 151,
		quantity: 194,
	},
	{
		garage_id: 133,
		part_id: 152,
		quantity: 310,
	},
	{
		garage_id: 133,
		part_id: 153,
		quantity: 295,
	},
	{
		garage_id: 133,
		part_id: 154,
		quantity: 287,
	},
	{
		garage_id: 133,
		part_id: 155,
		quantity: 443,
	},
	{
		garage_id: 133,
		part_id: 156,
		quantity: 136,
	},
	{
		garage_id: 133,
		part_id: 157,
		quantity: 194,
	},
	{
		garage_id: 133,
		part_id: 158,
		quantity: 12,
	},
	{
		garage_id: 133,
		part_id: 159,
		quantity: 322,
	},
	{
		garage_id: 133,
		part_id: 160,
		quantity: 350,
	},
	{
		garage_id: 133,
		part_id: 161,
		quantity: 180,
	},
	{
		garage_id: 133,
		part_id: 162,
		quantity: 138,
	},
	{
		garage_id: 133,
		part_id: 163,
		quantity: 51,
	},
	{
		garage_id: 133,
		part_id: 164,
		quantity: 370,
	},
	{
		garage_id: 133,
		part_id: 165,
		quantity: 260,
	},
	{
		garage_id: 133,
		part_id: 166,
		quantity: 133,
	},
	{
		garage_id: 133,
		part_id: 167,
		quantity: 298,
	},
	{
		garage_id: 133,
		part_id: 168,
		quantity: 184,
	},
	{
		garage_id: 133,
		part_id: 169,
		quantity: 481,
	},
	{
		garage_id: 133,
		part_id: 170,
		quantity: 394,
	},
	{
		garage_id: 133,
		part_id: 171,
		quantity: 284,
	},
	{
		garage_id: 133,
		part_id: 172,
		quantity: 309,
	},
	{
		garage_id: 133,
		part_id: 173,
		quantity: 145,
	},
	{
		garage_id: 133,
		part_id: 174,
		quantity: 488,
	},
	{
		garage_id: 133,
		part_id: 175,
		quantity: 276,
	},
	{
		garage_id: 133,
		part_id: 176,
		quantity: 480,
	},
	{
		garage_id: 133,
		part_id: 177,
		quantity: 118,
	},
	{
		garage_id: 133,
		part_id: 178,
		quantity: 374,
	},
	{
		garage_id: 133,
		part_id: 179,
		quantity: 361,
	},
	{
		garage_id: 133,
		part_id: 180,
		quantity: 180,
	},
	{
		garage_id: 133,
		part_id: 181,
		quantity: 81,
	},
	{
		garage_id: 133,
		part_id: 182,
		quantity: 219,
	},
	{
		garage_id: 133,
		part_id: 183,
		quantity: 301,
	},
	{
		garage_id: 133,
		part_id: 184,
		quantity: 8,
	},
	{
		garage_id: 133,
		part_id: 185,
		quantity: 88,
	},
	{
		garage_id: 133,
		part_id: 186,
		quantity: 117,
	},
	{
		garage_id: 133,
		part_id: 187,
		quantity: 173,
	},
	{
		garage_id: 133,
		part_id: 188,
		quantity: 342,
	},
	{
		garage_id: 133,
		part_id: 189,
		quantity: 410,
	},
	{
		garage_id: 133,
		part_id: 190,
		quantity: 162,
	},
	{
		garage_id: 133,
		part_id: 191,
		quantity: 191,
	},
	{
		garage_id: 133,
		part_id: 192,
		quantity: 134,
	},
	{
		garage_id: 133,
		part_id: 193,
		quantity: 369,
	},
	{
		garage_id: 133,
		part_id: 194,
		quantity: 174,
	},
	{
		garage_id: 133,
		part_id: 195,
		quantity: 49,
	},
	{
		garage_id: 133,
		part_id: 196,
		quantity: 73,
	},
	{
		garage_id: 133,
		part_id: 197,
		quantity: 194,
	},
	{
		garage_id: 133,
		part_id: 198,
		quantity: 19,
	},
	{
		garage_id: 133,
		part_id: 199,
		quantity: 123,
	},
	{
		garage_id: 133,
		part_id: 200,
		quantity: 431,
	},
	{
		garage_id: 133,
		part_id: 201,
		quantity: 24,
	},
	{
		garage_id: 133,
		part_id: 202,
		quantity: 235,
	},
	{
		garage_id: 133,
		part_id: 203,
		quantity: 469,
	},
	{
		garage_id: 133,
		part_id: 204,
		quantity: 12,
	},
	{
		garage_id: 133,
		part_id: 205,
		quantity: 212,
	},
	{
		garage_id: 133,
		part_id: 206,
		quantity: 470,
	},
	{
		garage_id: 133,
		part_id: 207,
		quantity: 351,
	},
	{
		garage_id: 133,
		part_id: 208,
		quantity: 170,
	},
	{
		garage_id: 133,
		part_id: 209,
		quantity: 337,
	},
	{
		garage_id: 133,
		part_id: 210,
		quantity: 303,
	},
	{
		garage_id: 133,
		part_id: 211,
		quantity: 122,
	},
	{
		garage_id: 133,
		part_id: 212,
		quantity: 0,
	},
	{
		garage_id: 133,
		part_id: 213,
		quantity: 311,
	},
	{
		garage_id: 133,
		part_id: 214,
		quantity: 437,
	},
	{
		garage_id: 133,
		part_id: 215,
		quantity: 40,
	},
	{
		garage_id: 133,
		part_id: 216,
		quantity: 257,
	},
	{
		garage_id: 133,
		part_id: 217,
		quantity: 144,
	},
	{
		garage_id: 133,
		part_id: 218,
		quantity: 97,
	},
	{
		garage_id: 133,
		part_id: 219,
		quantity: 332,
	},
	{
		garage_id: 133,
		part_id: 220,
		quantity: 149,
	},
	{
		garage_id: 133,
		part_id: 221,
		quantity: 134,
	},
	{
		garage_id: 133,
		part_id: 222,
		quantity: 422,
	},
	{
		garage_id: 133,
		part_id: 223,
		quantity: 80,
	},
	{
		garage_id: 133,
		part_id: 224,
		quantity: 309,
	},
	{
		garage_id: 133,
		part_id: 225,
		quantity: 143,
	},
	{
		garage_id: 133,
		part_id: 226,
		quantity: 398,
	},
	{
		garage_id: 133,
		part_id: 227,
		quantity: 382,
	},
	{
		garage_id: 133,
		part_id: 228,
		quantity: 320,
	},
	{
		garage_id: 133,
		part_id: 229,
		quantity: 353,
	},
	{
		garage_id: 133,
		part_id: 230,
		quantity: 471,
	},
	{
		garage_id: 133,
		part_id: 231,
		quantity: 78,
	},
	{
		garage_id: 133,
		part_id: 232,
		quantity: 44,
	},
	{
		garage_id: 133,
		part_id: 233,
		quantity: 191,
	},
	{
		garage_id: 133,
		part_id: 234,
		quantity: 118,
	},
	{
		garage_id: 133,
		part_id: 235,
		quantity: 464,
	},
	{
		garage_id: 133,
		part_id: 236,
		quantity: 467,
	},
	{
		garage_id: 133,
		part_id: 237,
		quantity: 348,
	},
	{
		garage_id: 133,
		part_id: 238,
		quantity: 126,
	},
	{
		garage_id: 133,
		part_id: 239,
		quantity: 97,
	},
	{
		garage_id: 133,
		part_id: 240,
		quantity: 273,
	},
	{
		garage_id: 133,
		part_id: 241,
		quantity: 162,
	},
	{
		garage_id: 133,
		part_id: 242,
		quantity: 437,
	},
	{
		garage_id: 133,
		part_id: 243,
		quantity: 126,
	},
	{
		garage_id: 133,
		part_id: 244,
		quantity: 76,
	},
	{
		garage_id: 133,
		part_id: 245,
		quantity: 390,
	},
	{
		garage_id: 133,
		part_id: 246,
		quantity: 225,
	},
	{
		garage_id: 133,
		part_id: 247,
		quantity: 351,
	},
	{
		garage_id: 133,
		part_id: 248,
		quantity: 371,
	},
	{
		garage_id: 133,
		part_id: 249,
		quantity: 188,
	},
	{
		garage_id: 133,
		part_id: 250,
		quantity: 450,
	},
	{
		garage_id: 133,
		part_id: 251,
		quantity: 270,
	},
	{
		garage_id: 133,
		part_id: 252,
		quantity: 219,
	},
	{
		garage_id: 133,
		part_id: 253,
		quantity: 191,
	},
	{
		garage_id: 133,
		part_id: 254,
		quantity: 165,
	},
	{
		garage_id: 133,
		part_id: 255,
		quantity: 288,
	},
	{
		garage_id: 133,
		part_id: 256,
		quantity: 39,
	},
	{
		garage_id: 133,
		part_id: 257,
		quantity: 279,
	},
	{
		garage_id: 133,
		part_id: 258,
		quantity: 331,
	},
	{
		garage_id: 133,
		part_id: 259,
		quantity: 396,
	},
	{
		garage_id: 133,
		part_id: 260,
		quantity: 189,
	},
	{
		garage_id: 133,
		part_id: 261,
		quantity: 271,
	},
	{
		garage_id: 133,
		part_id: 262,
		quantity: 453,
	},
	{
		garage_id: 133,
		part_id: 263,
		quantity: 248,
	},
	{
		garage_id: 133,
		part_id: 264,
		quantity: 226,
	},
	{
		garage_id: 133,
		part_id: 265,
		quantity: 139,
	},
	{
		garage_id: 133,
		part_id: 266,
		quantity: 112,
	},
	{
		garage_id: 133,
		part_id: 267,
		quantity: 427,
	},
	{
		garage_id: 133,
		part_id: 268,
		quantity: 209,
	},
	{
		garage_id: 133,
		part_id: 269,
		quantity: 25,
	},
	{
		garage_id: 133,
		part_id: 270,
		quantity: 30,
	},
	{
		garage_id: 133,
		part_id: 271,
		quantity: 321,
	},
	{
		garage_id: 133,
		part_id: 272,
		quantity: 364,
	},
	{
		garage_id: 133,
		part_id: 273,
		quantity: 41,
	},
	{
		garage_id: 133,
		part_id: 274,
		quantity: 54,
	},
	{
		garage_id: 133,
		part_id: 275,
		quantity: 106,
	},
	{
		garage_id: 133,
		part_id: 276,
		quantity: 416,
	},
	{
		garage_id: 133,
		part_id: 277,
		quantity: 279,
	},
	{
		garage_id: 133,
		part_id: 278,
		quantity: 304,
	},
	{
		garage_id: 133,
		part_id: 279,
		quantity: 484,
	},
	{
		garage_id: 133,
		part_id: 280,
		quantity: 176,
	},
	{
		garage_id: 133,
		part_id: 281,
		quantity: 153,
	},
	{
		garage_id: 133,
		part_id: 282,
		quantity: 434,
	},
	{
		garage_id: 133,
		part_id: 283,
		quantity: 324,
	},
	{
		garage_id: 133,
		part_id: 284,
		quantity: 333,
	},
	{
		garage_id: 133,
		part_id: 285,
		quantity: 234,
	},
	{
		garage_id: 133,
		part_id: 286,
		quantity: 262,
	},
	{
		garage_id: 133,
		part_id: 287,
		quantity: 448,
	},
	{
		garage_id: 133,
		part_id: 288,
		quantity: 416,
	},
	{
		garage_id: 133,
		part_id: 289,
		quantity: 484,
	},
	{
		garage_id: 133,
		part_id: 290,
		quantity: 379,
	},
	{
		garage_id: 133,
		part_id: 291,
		quantity: 278,
	},
	{
		garage_id: 133,
		part_id: 292,
		quantity: 115,
	},
	{
		garage_id: 133,
		part_id: 293,
		quantity: 419,
	},
	{
		garage_id: 133,
		part_id: 294,
		quantity: 200,
	},
	{
		garage_id: 133,
		part_id: 295,
		quantity: 473,
	},
	{
		garage_id: 133,
		part_id: 296,
		quantity: 186,
	},
	{
		garage_id: 133,
		part_id: 297,
		quantity: 141,
	},
	{
		garage_id: 133,
		part_id: 298,
		quantity: 413,
	},
	{
		garage_id: 133,
		part_id: 299,
		quantity: 175,
	},
	{
		garage_id: 133,
		part_id: 300,
		quantity: 335,
	},
	{
		garage_id: 133,
		part_id: 301,
		quantity: 215,
	},
	{
		garage_id: 133,
		part_id: 302,
		quantity: 138,
	},
	{
		garage_id: 133,
		part_id: 303,
		quantity: 293,
	},
	{
		garage_id: 133,
		part_id: 304,
		quantity: 247,
	},
	{
		garage_id: 133,
		part_id: 305,
		quantity: 169,
	},
	{
		garage_id: 133,
		part_id: 306,
		quantity: 457,
	},
	{
		garage_id: 133,
		part_id: 307,
		quantity: 252,
	},
	{
		garage_id: 133,
		part_id: 308,
		quantity: 246,
	},
	{
		garage_id: 133,
		part_id: 309,
		quantity: 211,
	},
	{
		garage_id: 133,
		part_id: 310,
		quantity: 161,
	},
	{
		garage_id: 133,
		part_id: 311,
		quantity: 389,
	},
	{
		garage_id: 133,
		part_id: 312,
		quantity: 386,
	},
	{
		garage_id: 133,
		part_id: 313,
		quantity: 228,
	},
	{
		garage_id: 133,
		part_id: 314,
		quantity: 67,
	},
	{
		garage_id: 133,
		part_id: 315,
		quantity: 404,
	},
	{
		garage_id: 133,
		part_id: 316,
		quantity: 368,
	},
	{
		garage_id: 133,
		part_id: 317,
		quantity: 385,
	},
	{
		garage_id: 133,
		part_id: 318,
		quantity: 484,
	},
	{
		garage_id: 133,
		part_id: 319,
		quantity: 131,
	},
	{
		garage_id: 133,
		part_id: 320,
		quantity: 381,
	},
	{
		garage_id: 133,
		part_id: 321,
		quantity: 431,
	},
	{
		garage_id: 133,
		part_id: 322,
		quantity: 303,
	},
	{
		garage_id: 133,
		part_id: 323,
		quantity: 448,
	},
	{
		garage_id: 133,
		part_id: 324,
		quantity: 114,
	},
	{
		garage_id: 133,
		part_id: 325,
		quantity: 318,
	},
	{
		garage_id: 133,
		part_id: 326,
		quantity: 177,
	},
	{
		garage_id: 133,
		part_id: 327,
		quantity: 373,
	},
	{
		garage_id: 133,
		part_id: 328,
		quantity: 381,
	},
	{
		garage_id: 133,
		part_id: 329,
		quantity: 215,
	},
	{
		garage_id: 133,
		part_id: 330,
		quantity: 251,
	},
	{
		garage_id: 133,
		part_id: 331,
		quantity: 318,
	},
	{
		garage_id: 133,
		part_id: 332,
		quantity: 292,
	},
	{
		garage_id: 133,
		part_id: 333,
		quantity: 224,
	},
	{
		garage_id: 133,
		part_id: 334,
		quantity: 60,
	},
	{
		garage_id: 133,
		part_id: 335,
		quantity: 154,
	},
	{
		garage_id: 133,
		part_id: 336,
		quantity: 39,
	},
	{
		garage_id: 133,
		part_id: 337,
		quantity: 16,
	},
	{
		garage_id: 133,
		part_id: 338,
		quantity: 389,
	},
	{
		garage_id: 133,
		part_id: 339,
		quantity: 118,
	},
	{
		garage_id: 133,
		part_id: 340,
		quantity: 409,
	},
	{
		garage_id: 133,
		part_id: 341,
		quantity: 377,
	},
	{
		garage_id: 133,
		part_id: 342,
		quantity: 269,
	},
	{
		garage_id: 133,
		part_id: 343,
		quantity: 196,
	},
	{
		garage_id: 133,
		part_id: 344,
		quantity: 238,
	},
	{
		garage_id: 133,
		part_id: 345,
		quantity: 491,
	},
	{
		garage_id: 133,
		part_id: 346,
		quantity: 346,
	},
	{
		garage_id: 133,
		part_id: 347,
		quantity: 411,
	},
	{
		garage_id: 133,
		part_id: 348,
		quantity: 472,
	},
	{
		garage_id: 133,
		part_id: 349,
		quantity: 59,
	},
	{
		garage_id: 133,
		part_id: 350,
		quantity: 395,
	},
	{
		garage_id: 133,
		part_id: 351,
		quantity: 361,
	},
	{
		garage_id: 133,
		part_id: 352,
		quantity: 88,
	},
	{
		garage_id: 133,
		part_id: 353,
		quantity: 358,
	},
	{
		garage_id: 133,
		part_id: 354,
		quantity: 434,
	},
	{
		garage_id: 133,
		part_id: 355,
		quantity: 161,
	},
	{
		garage_id: 133,
		part_id: 356,
		quantity: 224,
	},
	{
		garage_id: 133,
		part_id: 357,
		quantity: 401,
	},
	{
		garage_id: 133,
		part_id: 358,
		quantity: 82,
	},
	{
		garage_id: 133,
		part_id: 359,
		quantity: 104,
	},
	{
		garage_id: 133,
		part_id: 360,
		quantity: 149,
	},
	{
		garage_id: 133,
		part_id: 361,
		quantity: 264,
	},
	{
		garage_id: 133,
		part_id: 362,
		quantity: 195,
	},
	{
		garage_id: 133,
		part_id: 363,
		quantity: 219,
	},
	{
		garage_id: 133,
		part_id: 364,
		quantity: 38,
	},
	{
		garage_id: 133,
		part_id: 365,
		quantity: 156,
	},
	{
		garage_id: 133,
		part_id: 366,
		quantity: 152,
	},
	{
		garage_id: 133,
		part_id: 367,
		quantity: 255,
	},
	{
		garage_id: 133,
		part_id: 368,
		quantity: 113,
	},
	{
		garage_id: 133,
		part_id: 369,
		quantity: 438,
	},
	{
		garage_id: 133,
		part_id: 370,
		quantity: 369,
	},
	{
		garage_id: 133,
		part_id: 371,
		quantity: 411,
	},
	{
		garage_id: 133,
		part_id: 372,
		quantity: 259,
	},
	{
		garage_id: 133,
		part_id: 373,
		quantity: 34,
	},
	{
		garage_id: 133,
		part_id: 374,
		quantity: 136,
	},
	{
		garage_id: 133,
		part_id: 375,
		quantity: 242,
	},
	{
		garage_id: 133,
		part_id: 376,
		quantity: 53,
	},
	{
		garage_id: 133,
		part_id: 377,
		quantity: 228,
	},
	{
		garage_id: 133,
		part_id: 378,
		quantity: 85,
	},
	{
		garage_id: 133,
		part_id: 379,
		quantity: 168,
	},
	{
		garage_id: 133,
		part_id: 380,
		quantity: 305,
	},
	{
		garage_id: 133,
		part_id: 381,
		quantity: 390,
	},
	{
		garage_id: 133,
		part_id: 382,
		quantity: 159,
	},
	{
		garage_id: 133,
		part_id: 383,
		quantity: 433,
	},
	{
		garage_id: 133,
		part_id: 384,
		quantity: 315,
	},
	{
		garage_id: 133,
		part_id: 385,
		quantity: 147,
	},
	{
		garage_id: 133,
		part_id: 386,
		quantity: 125,
	},
	{
		garage_id: 133,
		part_id: 387,
		quantity: 72,
	},
	{
		garage_id: 133,
		part_id: 388,
		quantity: 441,
	},
	{
		garage_id: 133,
		part_id: 389,
		quantity: 97,
	},
	{
		garage_id: 133,
		part_id: 390,
		quantity: 298,
	},
	{
		garage_id: 133,
		part_id: 391,
		quantity: 273,
	},
	{
		garage_id: 133,
		part_id: 392,
		quantity: 290,
	},
	{
		garage_id: 133,
		part_id: 393,
		quantity: 21,
	},
	{
		garage_id: 133,
		part_id: 394,
		quantity: 480,
	},
	{
		garage_id: 133,
		part_id: 395,
		quantity: 268,
	},
	{
		garage_id: 133,
		part_id: 396,
		quantity: 180,
	},
	{
		garage_id: 133,
		part_id: 397,
		quantity: 227,
	},
	{
		garage_id: 133,
		part_id: 398,
		quantity: 471,
	},
	{
		garage_id: 133,
		part_id: 399,
		quantity: 485,
	},
	{
		garage_id: 134,
		part_id: 0,
		quantity: 72,
	},
	{
		garage_id: 134,
		part_id: 1,
		quantity: 421,
	},
	{
		garage_id: 134,
		part_id: 2,
		quantity: 168,
	},
	{
		garage_id: 134,
		part_id: 3,
		quantity: 416,
	},
	{
		garage_id: 134,
		part_id: 4,
		quantity: 253,
	},
	{
		garage_id: 134,
		part_id: 5,
		quantity: 414,
	},
	{
		garage_id: 134,
		part_id: 6,
		quantity: 428,
	},
	{
		garage_id: 134,
		part_id: 7,
		quantity: 367,
	},
	{
		garage_id: 134,
		part_id: 8,
		quantity: 138,
	},
	{
		garage_id: 134,
		part_id: 9,
		quantity: 256,
	},
	{
		garage_id: 134,
		part_id: 10,
		quantity: 461,
	},
	{
		garage_id: 134,
		part_id: 11,
		quantity: 419,
	},
	{
		garage_id: 134,
		part_id: 12,
		quantity: 79,
	},
	{
		garage_id: 134,
		part_id: 13,
		quantity: 72,
	},
	{
		garage_id: 134,
		part_id: 14,
		quantity: 256,
	},
	{
		garage_id: 134,
		part_id: 15,
		quantity: 91,
	},
	{
		garage_id: 134,
		part_id: 16,
		quantity: 37,
	},
	{
		garage_id: 134,
		part_id: 17,
		quantity: 270,
	},
	{
		garage_id: 134,
		part_id: 18,
		quantity: 330,
	},
	{
		garage_id: 134,
		part_id: 19,
		quantity: 193,
	},
	{
		garage_id: 134,
		part_id: 20,
		quantity: 346,
	},
	{
		garage_id: 134,
		part_id: 21,
		quantity: 325,
	},
	{
		garage_id: 134,
		part_id: 22,
		quantity: 271,
	},
	{
		garage_id: 134,
		part_id: 23,
		quantity: 361,
	},
	{
		garage_id: 134,
		part_id: 24,
		quantity: 76,
	},
	{
		garage_id: 134,
		part_id: 25,
		quantity: 71,
	},
	{
		garage_id: 134,
		part_id: 26,
		quantity: 282,
	},
	{
		garage_id: 134,
		part_id: 27,
		quantity: 111,
	},
	{
		garage_id: 134,
		part_id: 28,
		quantity: 58,
	},
	{
		garage_id: 134,
		part_id: 29,
		quantity: 74,
	},
	{
		garage_id: 134,
		part_id: 30,
		quantity: 365,
	},
	{
		garage_id: 134,
		part_id: 31,
		quantity: 474,
	},
	{
		garage_id: 134,
		part_id: 32,
		quantity: 406,
	},
	{
		garage_id: 134,
		part_id: 33,
		quantity: 140,
	},
	{
		garage_id: 134,
		part_id: 34,
		quantity: 74,
	},
	{
		garage_id: 134,
		part_id: 35,
		quantity: 358,
	},
	{
		garage_id: 134,
		part_id: 36,
		quantity: 307,
	},
	{
		garage_id: 134,
		part_id: 37,
		quantity: 30,
	},
	{
		garage_id: 134,
		part_id: 38,
		quantity: 59,
	},
	{
		garage_id: 134,
		part_id: 39,
		quantity: 495,
	},
	{
		garage_id: 134,
		part_id: 40,
		quantity: 327,
	},
	{
		garage_id: 134,
		part_id: 41,
		quantity: 174,
	},
	{
		garage_id: 134,
		part_id: 42,
		quantity: 354,
	},
	{
		garage_id: 134,
		part_id: 43,
		quantity: 277,
	},
	{
		garage_id: 134,
		part_id: 44,
		quantity: 96,
	},
	{
		garage_id: 134,
		part_id: 45,
		quantity: 425,
	},
	{
		garage_id: 134,
		part_id: 46,
		quantity: 272,
	},
	{
		garage_id: 134,
		part_id: 47,
		quantity: 52,
	},
	{
		garage_id: 134,
		part_id: 48,
		quantity: 471,
	},
	{
		garage_id: 134,
		part_id: 49,
		quantity: 171,
	},
	{
		garage_id: 134,
		part_id: 50,
		quantity: 117,
	},
	{
		garage_id: 134,
		part_id: 51,
		quantity: 298,
	},
	{
		garage_id: 134,
		part_id: 52,
		quantity: 97,
	},
	{
		garage_id: 134,
		part_id: 53,
		quantity: 415,
	},
	{
		garage_id: 134,
		part_id: 54,
		quantity: 402,
	},
	{
		garage_id: 134,
		part_id: 55,
		quantity: 159,
	},
	{
		garage_id: 134,
		part_id: 56,
		quantity: 215,
	},
	{
		garage_id: 134,
		part_id: 57,
		quantity: 206,
	},
	{
		garage_id: 134,
		part_id: 58,
		quantity: 35,
	},
	{
		garage_id: 134,
		part_id: 59,
		quantity: 339,
	},
	{
		garage_id: 134,
		part_id: 60,
		quantity: 275,
	},
	{
		garage_id: 134,
		part_id: 61,
		quantity: 126,
	},
	{
		garage_id: 134,
		part_id: 62,
		quantity: 47,
	},
	{
		garage_id: 134,
		part_id: 63,
		quantity: 313,
	},
	{
		garage_id: 134,
		part_id: 64,
		quantity: 467,
	},
	{
		garage_id: 134,
		part_id: 65,
		quantity: 141,
	},
	{
		garage_id: 134,
		part_id: 66,
		quantity: 241,
	},
	{
		garage_id: 134,
		part_id: 67,
		quantity: 167,
	},
	{
		garage_id: 134,
		part_id: 68,
		quantity: 246,
	},
	{
		garage_id: 134,
		part_id: 69,
		quantity: 364,
	},
	{
		garage_id: 134,
		part_id: 70,
		quantity: 402,
	},
	{
		garage_id: 134,
		part_id: 71,
		quantity: 451,
	},
	{
		garage_id: 134,
		part_id: 72,
		quantity: 246,
	},
	{
		garage_id: 134,
		part_id: 73,
		quantity: 443,
	},
	{
		garage_id: 134,
		part_id: 74,
		quantity: 259,
	},
	{
		garage_id: 134,
		part_id: 75,
		quantity: 342,
	},
	{
		garage_id: 134,
		part_id: 76,
		quantity: 456,
	},
	{
		garage_id: 134,
		part_id: 77,
		quantity: 385,
	},
	{
		garage_id: 134,
		part_id: 78,
		quantity: 172,
	},
	{
		garage_id: 134,
		part_id: 79,
		quantity: 38,
	},
	{
		garage_id: 134,
		part_id: 80,
		quantity: 105,
	},
	{
		garage_id: 134,
		part_id: 81,
		quantity: 474,
	},
	{
		garage_id: 134,
		part_id: 82,
		quantity: 34,
	},
	{
		garage_id: 134,
		part_id: 83,
		quantity: 34,
	},
	{
		garage_id: 134,
		part_id: 84,
		quantity: 113,
	},
	{
		garage_id: 134,
		part_id: 85,
		quantity: 485,
	},
	{
		garage_id: 134,
		part_id: 86,
		quantity: 392,
	},
	{
		garage_id: 134,
		part_id: 87,
		quantity: 296,
	},
	{
		garage_id: 134,
		part_id: 88,
		quantity: 460,
	},
	{
		garage_id: 134,
		part_id: 89,
		quantity: 352,
	},
	{
		garage_id: 134,
		part_id: 90,
		quantity: 262,
	},
	{
		garage_id: 134,
		part_id: 91,
		quantity: 474,
	},
	{
		garage_id: 134,
		part_id: 92,
		quantity: 500,
	},
	{
		garage_id: 134,
		part_id: 93,
		quantity: 470,
	},
	{
		garage_id: 134,
		part_id: 94,
		quantity: 477,
	},
	{
		garage_id: 134,
		part_id: 95,
		quantity: 499,
	},
	{
		garage_id: 134,
		part_id: 96,
		quantity: 156,
	},
	{
		garage_id: 134,
		part_id: 97,
		quantity: 9,
	},
	{
		garage_id: 134,
		part_id: 98,
		quantity: 148,
	},
	{
		garage_id: 134,
		part_id: 99,
		quantity: 359,
	},
	{
		garage_id: 134,
		part_id: 100,
		quantity: 401,
	},
	{
		garage_id: 134,
		part_id: 101,
		quantity: 61,
	},
	{
		garage_id: 134,
		part_id: 102,
		quantity: 36,
	},
	{
		garage_id: 134,
		part_id: 103,
		quantity: 458,
	},
	{
		garage_id: 134,
		part_id: 104,
		quantity: 479,
	},
	{
		garage_id: 134,
		part_id: 105,
		quantity: 290,
	},
	{
		garage_id: 134,
		part_id: 106,
		quantity: 416,
	},
	{
		garage_id: 134,
		part_id: 107,
		quantity: 374,
	},
	{
		garage_id: 134,
		part_id: 108,
		quantity: 241,
	},
	{
		garage_id: 134,
		part_id: 109,
		quantity: 494,
	},
	{
		garage_id: 134,
		part_id: 110,
		quantity: 322,
	},
	{
		garage_id: 134,
		part_id: 111,
		quantity: 240,
	},
	{
		garage_id: 134,
		part_id: 112,
		quantity: 52,
	},
	{
		garage_id: 134,
		part_id: 113,
		quantity: 195,
	},
	{
		garage_id: 134,
		part_id: 114,
		quantity: 282,
	},
	{
		garage_id: 134,
		part_id: 115,
		quantity: 404,
	},
	{
		garage_id: 134,
		part_id: 116,
		quantity: 28,
	},
	{
		garage_id: 134,
		part_id: 117,
		quantity: 13,
	},
	{
		garage_id: 134,
		part_id: 118,
		quantity: 214,
	},
	{
		garage_id: 134,
		part_id: 119,
		quantity: 344,
	},
	{
		garage_id: 134,
		part_id: 120,
		quantity: 159,
	},
	{
		garage_id: 134,
		part_id: 121,
		quantity: 76,
	},
	{
		garage_id: 134,
		part_id: 122,
		quantity: 59,
	},
	{
		garage_id: 134,
		part_id: 123,
		quantity: 71,
	},
	{
		garage_id: 134,
		part_id: 124,
		quantity: 11,
	},
	{
		garage_id: 134,
		part_id: 125,
		quantity: 40,
	},
	{
		garage_id: 134,
		part_id: 126,
		quantity: 64,
	},
	{
		garage_id: 134,
		part_id: 127,
		quantity: 128,
	},
	{
		garage_id: 134,
		part_id: 128,
		quantity: 358,
	},
	{
		garage_id: 134,
		part_id: 129,
		quantity: 471,
	},
	{
		garage_id: 134,
		part_id: 130,
		quantity: 19,
	},
	{
		garage_id: 134,
		part_id: 131,
		quantity: 187,
	},
	{
		garage_id: 134,
		part_id: 132,
		quantity: 178,
	},
	{
		garage_id: 134,
		part_id: 133,
		quantity: 14,
	},
	{
		garage_id: 134,
		part_id: 134,
		quantity: 196,
	},
	{
		garage_id: 134,
		part_id: 135,
		quantity: 22,
	},
	{
		garage_id: 134,
		part_id: 136,
		quantity: 34,
	},
	{
		garage_id: 134,
		part_id: 137,
		quantity: 329,
	},
	{
		garage_id: 134,
		part_id: 138,
		quantity: 393,
	},
	{
		garage_id: 134,
		part_id: 139,
		quantity: 193,
	},
	{
		garage_id: 134,
		part_id: 140,
		quantity: 164,
	},
	{
		garage_id: 134,
		part_id: 141,
		quantity: 215,
	},
	{
		garage_id: 134,
		part_id: 142,
		quantity: 332,
	},
	{
		garage_id: 134,
		part_id: 143,
		quantity: 33,
	},
	{
		garage_id: 134,
		part_id: 144,
		quantity: 85,
	},
	{
		garage_id: 134,
		part_id: 145,
		quantity: 241,
	},
	{
		garage_id: 134,
		part_id: 146,
		quantity: 26,
	},
	{
		garage_id: 134,
		part_id: 147,
		quantity: 472,
	},
	{
		garage_id: 134,
		part_id: 148,
		quantity: 187,
	},
	{
		garage_id: 134,
		part_id: 149,
		quantity: 386,
	},
	{
		garage_id: 134,
		part_id: 150,
		quantity: 362,
	},
	{
		garage_id: 134,
		part_id: 151,
		quantity: 263,
	},
	{
		garage_id: 134,
		part_id: 152,
		quantity: 399,
	},
	{
		garage_id: 134,
		part_id: 153,
		quantity: 146,
	},
	{
		garage_id: 134,
		part_id: 154,
		quantity: 52,
	},
	{
		garage_id: 134,
		part_id: 155,
		quantity: 167,
	},
	{
		garage_id: 134,
		part_id: 156,
		quantity: 368,
	},
	{
		garage_id: 134,
		part_id: 157,
		quantity: 451,
	},
	{
		garage_id: 134,
		part_id: 158,
		quantity: 406,
	},
	{
		garage_id: 134,
		part_id: 159,
		quantity: 172,
	},
	{
		garage_id: 134,
		part_id: 160,
		quantity: 157,
	},
	{
		garage_id: 134,
		part_id: 161,
		quantity: 499,
	},
	{
		garage_id: 134,
		part_id: 162,
		quantity: 324,
	},
	{
		garage_id: 134,
		part_id: 163,
		quantity: 285,
	},
	{
		garage_id: 134,
		part_id: 164,
		quantity: 359,
	},
	{
		garage_id: 134,
		part_id: 165,
		quantity: 269,
	},
	{
		garage_id: 134,
		part_id: 166,
		quantity: 297,
	},
	{
		garage_id: 134,
		part_id: 167,
		quantity: 262,
	},
	{
		garage_id: 134,
		part_id: 168,
		quantity: 385,
	},
	{
		garage_id: 134,
		part_id: 169,
		quantity: 452,
	},
	{
		garage_id: 134,
		part_id: 170,
		quantity: 113,
	},
	{
		garage_id: 134,
		part_id: 171,
		quantity: 390,
	},
	{
		garage_id: 134,
		part_id: 172,
		quantity: 292,
	},
	{
		garage_id: 134,
		part_id: 173,
		quantity: 303,
	},
	{
		garage_id: 134,
		part_id: 174,
		quantity: 347,
	},
	{
		garage_id: 134,
		part_id: 175,
		quantity: 100,
	},
	{
		garage_id: 134,
		part_id: 176,
		quantity: 91,
	},
	{
		garage_id: 134,
		part_id: 177,
		quantity: 137,
	},
	{
		garage_id: 134,
		part_id: 178,
		quantity: 481,
	},
	{
		garage_id: 134,
		part_id: 179,
		quantity: 180,
	},
	{
		garage_id: 134,
		part_id: 180,
		quantity: 204,
	},
	{
		garage_id: 134,
		part_id: 181,
		quantity: 302,
	},
	{
		garage_id: 134,
		part_id: 182,
		quantity: 387,
	},
	{
		garage_id: 134,
		part_id: 183,
		quantity: 281,
	},
	{
		garage_id: 134,
		part_id: 184,
		quantity: 139,
	},
	{
		garage_id: 134,
		part_id: 185,
		quantity: 5,
	},
	{
		garage_id: 134,
		part_id: 186,
		quantity: 161,
	},
	{
		garage_id: 134,
		part_id: 187,
		quantity: 13,
	},
	{
		garage_id: 134,
		part_id: 188,
		quantity: 142,
	},
	{
		garage_id: 134,
		part_id: 189,
		quantity: 209,
	},
	{
		garage_id: 134,
		part_id: 190,
		quantity: 303,
	},
	{
		garage_id: 134,
		part_id: 191,
		quantity: 473,
	},
	{
		garage_id: 134,
		part_id: 192,
		quantity: 476,
	},
	{
		garage_id: 134,
		part_id: 193,
		quantity: 148,
	},
	{
		garage_id: 134,
		part_id: 194,
		quantity: 154,
	},
	{
		garage_id: 134,
		part_id: 195,
		quantity: 356,
	},
	{
		garage_id: 134,
		part_id: 196,
		quantity: 119,
	},
	{
		garage_id: 134,
		part_id: 197,
		quantity: 162,
	},
	{
		garage_id: 134,
		part_id: 198,
		quantity: 339,
	},
	{
		garage_id: 134,
		part_id: 199,
		quantity: 347,
	},
	{
		garage_id: 134,
		part_id: 200,
		quantity: 237,
	},
	{
		garage_id: 134,
		part_id: 201,
		quantity: 105,
	},
	{
		garage_id: 134,
		part_id: 202,
		quantity: 344,
	},
	{
		garage_id: 134,
		part_id: 203,
		quantity: 321,
	},
	{
		garage_id: 134,
		part_id: 204,
		quantity: 162,
	},
	{
		garage_id: 134,
		part_id: 205,
		quantity: 419,
	},
	{
		garage_id: 134,
		part_id: 206,
		quantity: 199,
	},
	{
		garage_id: 134,
		part_id: 207,
		quantity: 433,
	},
	{
		garage_id: 134,
		part_id: 208,
		quantity: 213,
	},
	{
		garage_id: 134,
		part_id: 209,
		quantity: 321,
	},
	{
		garage_id: 134,
		part_id: 210,
		quantity: 364,
	},
	{
		garage_id: 134,
		part_id: 211,
		quantity: 54,
	},
	{
		garage_id: 134,
		part_id: 212,
		quantity: 197,
	},
	{
		garage_id: 134,
		part_id: 213,
		quantity: 286,
	},
	{
		garage_id: 134,
		part_id: 214,
		quantity: 293,
	},
	{
		garage_id: 134,
		part_id: 215,
		quantity: 162,
	},
	{
		garage_id: 134,
		part_id: 216,
		quantity: 196,
	},
	{
		garage_id: 134,
		part_id: 217,
		quantity: 215,
	},
	{
		garage_id: 134,
		part_id: 218,
		quantity: 249,
	},
	{
		garage_id: 134,
		part_id: 219,
		quantity: 9,
	},
	{
		garage_id: 134,
		part_id: 220,
		quantity: 265,
	},
	{
		garage_id: 134,
		part_id: 221,
		quantity: 69,
	},
	{
		garage_id: 134,
		part_id: 222,
		quantity: 272,
	},
	{
		garage_id: 134,
		part_id: 223,
		quantity: 267,
	},
	{
		garage_id: 134,
		part_id: 224,
		quantity: 498,
	},
	{
		garage_id: 134,
		part_id: 225,
		quantity: 71,
	},
	{
		garage_id: 134,
		part_id: 226,
		quantity: 109,
	},
	{
		garage_id: 134,
		part_id: 227,
		quantity: 6,
	},
	{
		garage_id: 134,
		part_id: 228,
		quantity: 486,
	},
	{
		garage_id: 134,
		part_id: 229,
		quantity: 475,
	},
	{
		garage_id: 134,
		part_id: 230,
		quantity: 163,
	},
	{
		garage_id: 134,
		part_id: 231,
		quantity: 14,
	},
	{
		garage_id: 134,
		part_id: 232,
		quantity: 152,
	},
	{
		garage_id: 134,
		part_id: 233,
		quantity: 223,
	},
	{
		garage_id: 134,
		part_id: 234,
		quantity: 106,
	},
	{
		garage_id: 134,
		part_id: 235,
		quantity: 201,
	},
	{
		garage_id: 134,
		part_id: 236,
		quantity: 373,
	},
	{
		garage_id: 134,
		part_id: 237,
		quantity: 376,
	},
	{
		garage_id: 134,
		part_id: 238,
		quantity: 425,
	},
	{
		garage_id: 134,
		part_id: 239,
		quantity: 23,
	},
	{
		garage_id: 134,
		part_id: 240,
		quantity: 404,
	},
	{
		garage_id: 134,
		part_id: 241,
		quantity: 181,
	},
	{
		garage_id: 134,
		part_id: 242,
		quantity: 362,
	},
	{
		garage_id: 134,
		part_id: 243,
		quantity: 285,
	},
	{
		garage_id: 134,
		part_id: 244,
		quantity: 257,
	},
	{
		garage_id: 134,
		part_id: 245,
		quantity: 410,
	},
	{
		garage_id: 134,
		part_id: 246,
		quantity: 213,
	},
	{
		garage_id: 134,
		part_id: 247,
		quantity: 162,
	},
	{
		garage_id: 134,
		part_id: 248,
		quantity: 219,
	},
	{
		garage_id: 134,
		part_id: 249,
		quantity: 398,
	},
	{
		garage_id: 134,
		part_id: 250,
		quantity: 461,
	},
	{
		garage_id: 134,
		part_id: 251,
		quantity: 73,
	},
	{
		garage_id: 134,
		part_id: 252,
		quantity: 252,
	},
	{
		garage_id: 134,
		part_id: 253,
		quantity: 195,
	},
	{
		garage_id: 134,
		part_id: 254,
		quantity: 245,
	},
	{
		garage_id: 134,
		part_id: 255,
		quantity: 452,
	},
	{
		garage_id: 134,
		part_id: 256,
		quantity: 438,
	},
	{
		garage_id: 134,
		part_id: 257,
		quantity: 375,
	},
	{
		garage_id: 134,
		part_id: 258,
		quantity: 416,
	},
	{
		garage_id: 134,
		part_id: 259,
		quantity: 5,
	},
	{
		garage_id: 134,
		part_id: 260,
		quantity: 258,
	},
	{
		garage_id: 134,
		part_id: 261,
		quantity: 63,
	},
	{
		garage_id: 134,
		part_id: 262,
		quantity: 164,
	},
	{
		garage_id: 134,
		part_id: 263,
		quantity: 82,
	},
	{
		garage_id: 134,
		part_id: 264,
		quantity: 361,
	},
	{
		garage_id: 134,
		part_id: 265,
		quantity: 494,
	},
	{
		garage_id: 134,
		part_id: 266,
		quantity: 341,
	},
	{
		garage_id: 134,
		part_id: 267,
		quantity: 36,
	},
	{
		garage_id: 134,
		part_id: 268,
		quantity: 35,
	},
	{
		garage_id: 134,
		part_id: 269,
		quantity: 161,
	},
	{
		garage_id: 134,
		part_id: 270,
		quantity: 496,
	},
	{
		garage_id: 134,
		part_id: 271,
		quantity: 6,
	},
	{
		garage_id: 134,
		part_id: 272,
		quantity: 255,
	},
	{
		garage_id: 134,
		part_id: 273,
		quantity: 473,
	},
	{
		garage_id: 134,
		part_id: 274,
		quantity: 471,
	},
	{
		garage_id: 134,
		part_id: 275,
		quantity: 121,
	},
	{
		garage_id: 134,
		part_id: 276,
		quantity: 65,
	},
	{
		garage_id: 134,
		part_id: 277,
		quantity: 462,
	},
	{
		garage_id: 134,
		part_id: 278,
		quantity: 150,
	},
	{
		garage_id: 134,
		part_id: 279,
		quantity: 368,
	},
	{
		garage_id: 134,
		part_id: 280,
		quantity: 331,
	},
	{
		garage_id: 134,
		part_id: 281,
		quantity: 296,
	},
	{
		garage_id: 134,
		part_id: 282,
		quantity: 225,
	},
	{
		garage_id: 134,
		part_id: 283,
		quantity: 433,
	},
	{
		garage_id: 134,
		part_id: 284,
		quantity: 413,
	},
	{
		garage_id: 134,
		part_id: 285,
		quantity: 157,
	},
	{
		garage_id: 134,
		part_id: 286,
		quantity: 173,
	},
	{
		garage_id: 134,
		part_id: 287,
		quantity: 27,
	},
	{
		garage_id: 134,
		part_id: 288,
		quantity: 462,
	},
	{
		garage_id: 134,
		part_id: 289,
		quantity: 146,
	},
	{
		garage_id: 134,
		part_id: 290,
		quantity: 184,
	},
	{
		garage_id: 134,
		part_id: 291,
		quantity: 393,
	},
	{
		garage_id: 134,
		part_id: 292,
		quantity: 470,
	},
	{
		garage_id: 134,
		part_id: 293,
		quantity: 66,
	},
	{
		garage_id: 134,
		part_id: 294,
		quantity: 322,
	},
	{
		garage_id: 134,
		part_id: 295,
		quantity: 418,
	},
	{
		garage_id: 134,
		part_id: 296,
		quantity: 458,
	},
	{
		garage_id: 134,
		part_id: 297,
		quantity: 194,
	},
	{
		garage_id: 134,
		part_id: 298,
		quantity: 164,
	},
	{
		garage_id: 134,
		part_id: 299,
		quantity: 262,
	},
	{
		garage_id: 134,
		part_id: 300,
		quantity: 396,
	},
	{
		garage_id: 134,
		part_id: 301,
		quantity: 24,
	},
	{
		garage_id: 134,
		part_id: 302,
		quantity: 92,
	},
	{
		garage_id: 134,
		part_id: 303,
		quantity: 302,
	},
	{
		garage_id: 134,
		part_id: 304,
		quantity: 13,
	},
	{
		garage_id: 134,
		part_id: 305,
		quantity: 106,
	},
	{
		garage_id: 134,
		part_id: 306,
		quantity: 110,
	},
	{
		garage_id: 134,
		part_id: 307,
		quantity: 414,
	},
	{
		garage_id: 134,
		part_id: 308,
		quantity: 350,
	},
	{
		garage_id: 134,
		part_id: 309,
		quantity: 67,
	},
	{
		garage_id: 134,
		part_id: 310,
		quantity: 388,
	},
	{
		garage_id: 134,
		part_id: 311,
		quantity: 206,
	},
	{
		garage_id: 134,
		part_id: 312,
		quantity: 492,
	},
	{
		garage_id: 134,
		part_id: 313,
		quantity: 272,
	},
	{
		garage_id: 134,
		part_id: 314,
		quantity: 344,
	},
	{
		garage_id: 134,
		part_id: 315,
		quantity: 181,
	},
	{
		garage_id: 134,
		part_id: 316,
		quantity: 490,
	},
	{
		garage_id: 134,
		part_id: 317,
		quantity: 482,
	},
	{
		garage_id: 134,
		part_id: 318,
		quantity: 234,
	},
	{
		garage_id: 134,
		part_id: 319,
		quantity: 428,
	},
	{
		garage_id: 134,
		part_id: 320,
		quantity: 191,
	},
	{
		garage_id: 134,
		part_id: 321,
		quantity: 286,
	},
	{
		garage_id: 134,
		part_id: 322,
		quantity: 139,
	},
	{
		garage_id: 134,
		part_id: 323,
		quantity: 318,
	},
	{
		garage_id: 134,
		part_id: 324,
		quantity: 227,
	},
	{
		garage_id: 134,
		part_id: 325,
		quantity: 454,
	},
	{
		garage_id: 134,
		part_id: 326,
		quantity: 20,
	},
	{
		garage_id: 134,
		part_id: 327,
		quantity: 139,
	},
	{
		garage_id: 134,
		part_id: 328,
		quantity: 158,
	},
	{
		garage_id: 134,
		part_id: 329,
		quantity: 137,
	},
	{
		garage_id: 134,
		part_id: 330,
		quantity: 2,
	},
	{
		garage_id: 134,
		part_id: 331,
		quantity: 363,
	},
	{
		garage_id: 134,
		part_id: 332,
		quantity: 467,
	},
	{
		garage_id: 134,
		part_id: 333,
		quantity: 125,
	},
	{
		garage_id: 134,
		part_id: 334,
		quantity: 433,
	},
	{
		garage_id: 134,
		part_id: 335,
		quantity: 168,
	},
	{
		garage_id: 134,
		part_id: 336,
		quantity: 394,
	},
	{
		garage_id: 134,
		part_id: 337,
		quantity: 409,
	},
	{
		garage_id: 134,
		part_id: 338,
		quantity: 93,
	},
	{
		garage_id: 134,
		part_id: 339,
		quantity: 317,
	},
	{
		garage_id: 134,
		part_id: 340,
		quantity: 162,
	},
	{
		garage_id: 134,
		part_id: 341,
		quantity: 279,
	},
	{
		garage_id: 134,
		part_id: 342,
		quantity: 450,
	},
	{
		garage_id: 134,
		part_id: 343,
		quantity: 305,
	},
	{
		garage_id: 134,
		part_id: 344,
		quantity: 361,
	},
	{
		garage_id: 134,
		part_id: 345,
		quantity: 230,
	},
	{
		garage_id: 134,
		part_id: 346,
		quantity: 403,
	},
	{
		garage_id: 134,
		part_id: 347,
		quantity: 245,
	},
	{
		garage_id: 134,
		part_id: 348,
		quantity: 136,
	},
	{
		garage_id: 134,
		part_id: 349,
		quantity: 485,
	},
	{
		garage_id: 134,
		part_id: 350,
		quantity: 377,
	},
	{
		garage_id: 134,
		part_id: 351,
		quantity: 171,
	},
	{
		garage_id: 134,
		part_id: 352,
		quantity: 250,
	},
	{
		garage_id: 134,
		part_id: 353,
		quantity: 12,
	},
	{
		garage_id: 134,
		part_id: 354,
		quantity: 433,
	},
	{
		garage_id: 134,
		part_id: 355,
		quantity: 406,
	},
	{
		garage_id: 134,
		part_id: 356,
		quantity: 421,
	},
	{
		garage_id: 134,
		part_id: 357,
		quantity: 282,
	},
	{
		garage_id: 134,
		part_id: 358,
		quantity: 188,
	},
	{
		garage_id: 134,
		part_id: 359,
		quantity: 105,
	},
	{
		garage_id: 134,
		part_id: 360,
		quantity: 96,
	},
	{
		garage_id: 134,
		part_id: 361,
		quantity: 471,
	},
	{
		garage_id: 134,
		part_id: 362,
		quantity: 19,
	},
	{
		garage_id: 134,
		part_id: 363,
		quantity: 60,
	},
	{
		garage_id: 134,
		part_id: 364,
		quantity: 273,
	},
	{
		garage_id: 134,
		part_id: 365,
		quantity: 82,
	},
	{
		garage_id: 134,
		part_id: 366,
		quantity: 283,
	},
	{
		garage_id: 134,
		part_id: 367,
		quantity: 455,
	},
	{
		garage_id: 134,
		part_id: 368,
		quantity: 185,
	},
	{
		garage_id: 134,
		part_id: 369,
		quantity: 330,
	},
	{
		garage_id: 134,
		part_id: 370,
		quantity: 325,
	},
	{
		garage_id: 134,
		part_id: 371,
		quantity: 352,
	},
	{
		garage_id: 134,
		part_id: 372,
		quantity: 293,
	},
	{
		garage_id: 134,
		part_id: 373,
		quantity: 162,
	},
	{
		garage_id: 134,
		part_id: 374,
		quantity: 277,
	},
	{
		garage_id: 134,
		part_id: 375,
		quantity: 209,
	},
	{
		garage_id: 134,
		part_id: 376,
		quantity: 256,
	},
	{
		garage_id: 134,
		part_id: 377,
		quantity: 294,
	},
	{
		garage_id: 134,
		part_id: 378,
		quantity: 318,
	},
	{
		garage_id: 134,
		part_id: 379,
		quantity: 191,
	},
	{
		garage_id: 134,
		part_id: 380,
		quantity: 298,
	},
	{
		garage_id: 134,
		part_id: 381,
		quantity: 261,
	},
	{
		garage_id: 134,
		part_id: 382,
		quantity: 129,
	},
	{
		garage_id: 134,
		part_id: 383,
		quantity: 495,
	},
	{
		garage_id: 134,
		part_id: 384,
		quantity: 249,
	},
	{
		garage_id: 134,
		part_id: 385,
		quantity: 415,
	},
	{
		garage_id: 134,
		part_id: 386,
		quantity: 457,
	},
	{
		garage_id: 134,
		part_id: 387,
		quantity: 87,
	},
	{
		garage_id: 134,
		part_id: 388,
		quantity: 473,
	},
	{
		garage_id: 134,
		part_id: 389,
		quantity: 107,
	},
	{
		garage_id: 134,
		part_id: 390,
		quantity: 19,
	},
	{
		garage_id: 134,
		part_id: 391,
		quantity: 2,
	},
	{
		garage_id: 134,
		part_id: 392,
		quantity: 294,
	},
	{
		garage_id: 134,
		part_id: 393,
		quantity: 385,
	},
	{
		garage_id: 134,
		part_id: 394,
		quantity: 447,
	},
	{
		garage_id: 134,
		part_id: 395,
		quantity: 350,
	},
	{
		garage_id: 134,
		part_id: 396,
		quantity: 282,
	},
	{
		garage_id: 134,
		part_id: 397,
		quantity: 197,
	},
	{
		garage_id: 134,
		part_id: 398,
		quantity: 499,
	},
	{
		garage_id: 134,
		part_id: 399,
		quantity: 357,
	},
	{
		garage_id: 135,
		part_id: 0,
		quantity: 372,
	},
	{
		garage_id: 135,
		part_id: 1,
		quantity: 247,
	},
	{
		garage_id: 135,
		part_id: 2,
		quantity: 381,
	},
	{
		garage_id: 135,
		part_id: 3,
		quantity: 210,
	},
	{
		garage_id: 135,
		part_id: 4,
		quantity: 429,
	},
	{
		garage_id: 135,
		part_id: 5,
		quantity: 262,
	},
	{
		garage_id: 135,
		part_id: 6,
		quantity: 476,
	},
	{
		garage_id: 135,
		part_id: 7,
		quantity: 418,
	},
	{
		garage_id: 135,
		part_id: 8,
		quantity: 350,
	},
	{
		garage_id: 135,
		part_id: 9,
		quantity: 405,
	},
	{
		garage_id: 135,
		part_id: 10,
		quantity: 211,
	},
	{
		garage_id: 135,
		part_id: 11,
		quantity: 317,
	},
	{
		garage_id: 135,
		part_id: 12,
		quantity: 62,
	},
	{
		garage_id: 135,
		part_id: 13,
		quantity: 30,
	},
	{
		garage_id: 135,
		part_id: 14,
		quantity: 78,
	},
	{
		garage_id: 135,
		part_id: 15,
		quantity: 243,
	},
	{
		garage_id: 135,
		part_id: 16,
		quantity: 432,
	},
	{
		garage_id: 135,
		part_id: 17,
		quantity: 141,
	},
	{
		garage_id: 135,
		part_id: 18,
		quantity: 155,
	},
	{
		garage_id: 135,
		part_id: 19,
		quantity: 8,
	},
	{
		garage_id: 135,
		part_id: 20,
		quantity: 410,
	},
	{
		garage_id: 135,
		part_id: 21,
		quantity: 197,
	},
	{
		garage_id: 135,
		part_id: 22,
		quantity: 183,
	},
	{
		garage_id: 135,
		part_id: 23,
		quantity: 396,
	},
	{
		garage_id: 135,
		part_id: 24,
		quantity: 15,
	},
	{
		garage_id: 135,
		part_id: 25,
		quantity: 485,
	},
	{
		garage_id: 135,
		part_id: 26,
		quantity: 111,
	},
	{
		garage_id: 135,
		part_id: 27,
		quantity: 127,
	},
	{
		garage_id: 135,
		part_id: 28,
		quantity: 394,
	},
	{
		garage_id: 135,
		part_id: 29,
		quantity: 102,
	},
	{
		garage_id: 135,
		part_id: 30,
		quantity: 248,
	},
	{
		garage_id: 135,
		part_id: 31,
		quantity: 176,
	},
	{
		garage_id: 135,
		part_id: 32,
		quantity: 452,
	},
	{
		garage_id: 135,
		part_id: 33,
		quantity: 462,
	},
	{
		garage_id: 135,
		part_id: 34,
		quantity: 35,
	},
	{
		garage_id: 135,
		part_id: 35,
		quantity: 145,
	},
	{
		garage_id: 135,
		part_id: 36,
		quantity: 169,
	},
	{
		garage_id: 135,
		part_id: 37,
		quantity: 475,
	},
	{
		garage_id: 135,
		part_id: 38,
		quantity: 102,
	},
	{
		garage_id: 135,
		part_id: 39,
		quantity: 371,
	},
	{
		garage_id: 135,
		part_id: 40,
		quantity: 333,
	},
	{
		garage_id: 135,
		part_id: 41,
		quantity: 419,
	},
	{
		garage_id: 135,
		part_id: 42,
		quantity: 46,
	},
	{
		garage_id: 135,
		part_id: 43,
		quantity: 455,
	},
	{
		garage_id: 135,
		part_id: 44,
		quantity: 431,
	},
	{
		garage_id: 135,
		part_id: 45,
		quantity: 404,
	},
	{
		garage_id: 135,
		part_id: 46,
		quantity: 125,
	},
	{
		garage_id: 135,
		part_id: 47,
		quantity: 245,
	},
	{
		garage_id: 135,
		part_id: 48,
		quantity: 390,
	},
	{
		garage_id: 135,
		part_id: 49,
		quantity: 40,
	},
	{
		garage_id: 135,
		part_id: 50,
		quantity: 472,
	},
	{
		garage_id: 135,
		part_id: 51,
		quantity: 156,
	},
	{
		garage_id: 135,
		part_id: 52,
		quantity: 147,
	},
	{
		garage_id: 135,
		part_id: 53,
		quantity: 95,
	},
	{
		garage_id: 135,
		part_id: 54,
		quantity: 478,
	},
	{
		garage_id: 135,
		part_id: 55,
		quantity: 200,
	},
	{
		garage_id: 135,
		part_id: 56,
		quantity: 268,
	},
	{
		garage_id: 135,
		part_id: 57,
		quantity: 129,
	},
	{
		garage_id: 135,
		part_id: 58,
		quantity: 2,
	},
	{
		garage_id: 135,
		part_id: 59,
		quantity: 76,
	},
	{
		garage_id: 135,
		part_id: 60,
		quantity: 237,
	},
	{
		garage_id: 135,
		part_id: 61,
		quantity: 258,
	},
	{
		garage_id: 135,
		part_id: 62,
		quantity: 209,
	},
	{
		garage_id: 135,
		part_id: 63,
		quantity: 280,
	},
	{
		garage_id: 135,
		part_id: 64,
		quantity: 41,
	},
	{
		garage_id: 135,
		part_id: 65,
		quantity: 74,
	},
	{
		garage_id: 135,
		part_id: 66,
		quantity: 396,
	},
	{
		garage_id: 135,
		part_id: 67,
		quantity: 199,
	},
	{
		garage_id: 135,
		part_id: 68,
		quantity: 396,
	},
	{
		garage_id: 135,
		part_id: 69,
		quantity: 439,
	},
	{
		garage_id: 135,
		part_id: 70,
		quantity: 414,
	},
	{
		garage_id: 135,
		part_id: 71,
		quantity: 130,
	},
	{
		garage_id: 135,
		part_id: 72,
		quantity: 288,
	},
	{
		garage_id: 135,
		part_id: 73,
		quantity: 8,
	},
	{
		garage_id: 135,
		part_id: 74,
		quantity: 414,
	},
	{
		garage_id: 135,
		part_id: 75,
		quantity: 399,
	},
	{
		garage_id: 135,
		part_id: 76,
		quantity: 201,
	},
	{
		garage_id: 135,
		part_id: 77,
		quantity: 210,
	},
	{
		garage_id: 135,
		part_id: 78,
		quantity: 8,
	},
	{
		garage_id: 135,
		part_id: 79,
		quantity: 125,
	},
	{
		garage_id: 135,
		part_id: 80,
		quantity: 484,
	},
	{
		garage_id: 135,
		part_id: 81,
		quantity: 32,
	},
	{
		garage_id: 135,
		part_id: 82,
		quantity: 357,
	},
	{
		garage_id: 135,
		part_id: 83,
		quantity: 252,
	},
	{
		garage_id: 135,
		part_id: 84,
		quantity: 172,
	},
	{
		garage_id: 135,
		part_id: 85,
		quantity: 70,
	},
	{
		garage_id: 135,
		part_id: 86,
		quantity: 443,
	},
	{
		garage_id: 135,
		part_id: 87,
		quantity: 119,
	},
	{
		garage_id: 135,
		part_id: 88,
		quantity: 343,
	},
	{
		garage_id: 135,
		part_id: 89,
		quantity: 359,
	},
	{
		garage_id: 135,
		part_id: 90,
		quantity: 145,
	},
	{
		garage_id: 135,
		part_id: 91,
		quantity: 81,
	},
	{
		garage_id: 135,
		part_id: 92,
		quantity: 240,
	},
	{
		garage_id: 135,
		part_id: 93,
		quantity: 247,
	},
	{
		garage_id: 135,
		part_id: 94,
		quantity: 403,
	},
	{
		garage_id: 135,
		part_id: 95,
		quantity: 234,
	},
	{
		garage_id: 135,
		part_id: 96,
		quantity: 352,
	},
	{
		garage_id: 135,
		part_id: 97,
		quantity: 122,
	},
	{
		garage_id: 135,
		part_id: 98,
		quantity: 109,
	},
	{
		garage_id: 135,
		part_id: 99,
		quantity: 244,
	},
	{
		garage_id: 135,
		part_id: 100,
		quantity: 289,
	},
	{
		garage_id: 135,
		part_id: 101,
		quantity: 73,
	},
	{
		garage_id: 135,
		part_id: 102,
		quantity: 75,
	},
	{
		garage_id: 135,
		part_id: 103,
		quantity: 126,
	},
	{
		garage_id: 135,
		part_id: 104,
		quantity: 410,
	},
	{
		garage_id: 135,
		part_id: 105,
		quantity: 64,
	},
	{
		garage_id: 135,
		part_id: 106,
		quantity: 483,
	},
	{
		garage_id: 135,
		part_id: 107,
		quantity: 167,
	},
	{
		garage_id: 135,
		part_id: 108,
		quantity: 41,
	},
	{
		garage_id: 135,
		part_id: 109,
		quantity: 396,
	},
	{
		garage_id: 135,
		part_id: 110,
		quantity: 84,
	},
	{
		garage_id: 135,
		part_id: 111,
		quantity: 273,
	},
	{
		garage_id: 135,
		part_id: 112,
		quantity: 187,
	},
	{
		garage_id: 135,
		part_id: 113,
		quantity: 394,
	},
	{
		garage_id: 135,
		part_id: 114,
		quantity: 130,
	},
	{
		garage_id: 135,
		part_id: 115,
		quantity: 443,
	},
	{
		garage_id: 135,
		part_id: 116,
		quantity: 189,
	},
	{
		garage_id: 135,
		part_id: 117,
		quantity: 456,
	},
	{
		garage_id: 135,
		part_id: 118,
		quantity: 311,
	},
	{
		garage_id: 135,
		part_id: 119,
		quantity: 404,
	},
	{
		garage_id: 135,
		part_id: 120,
		quantity: 341,
	},
	{
		garage_id: 135,
		part_id: 121,
		quantity: 358,
	},
	{
		garage_id: 135,
		part_id: 122,
		quantity: 178,
	},
	{
		garage_id: 135,
		part_id: 123,
		quantity: 84,
	},
	{
		garage_id: 135,
		part_id: 124,
		quantity: 37,
	},
	{
		garage_id: 135,
		part_id: 125,
		quantity: 267,
	},
	{
		garage_id: 135,
		part_id: 126,
		quantity: 394,
	},
	{
		garage_id: 135,
		part_id: 127,
		quantity: 454,
	},
	{
		garage_id: 135,
		part_id: 128,
		quantity: 303,
	},
	{
		garage_id: 135,
		part_id: 129,
		quantity: 423,
	},
	{
		garage_id: 135,
		part_id: 130,
		quantity: 72,
	},
	{
		garage_id: 135,
		part_id: 131,
		quantity: 404,
	},
	{
		garage_id: 135,
		part_id: 132,
		quantity: 360,
	},
	{
		garage_id: 135,
		part_id: 133,
		quantity: 436,
	},
	{
		garage_id: 135,
		part_id: 134,
		quantity: 237,
	},
	{
		garage_id: 135,
		part_id: 135,
		quantity: 90,
	},
	{
		garage_id: 135,
		part_id: 136,
		quantity: 48,
	},
	{
		garage_id: 135,
		part_id: 137,
		quantity: 327,
	},
	{
		garage_id: 135,
		part_id: 138,
		quantity: 30,
	},
	{
		garage_id: 135,
		part_id: 139,
		quantity: 253,
	},
	{
		garage_id: 135,
		part_id: 140,
		quantity: 64,
	},
	{
		garage_id: 135,
		part_id: 141,
		quantity: 106,
	},
	{
		garage_id: 135,
		part_id: 142,
		quantity: 48,
	},
	{
		garage_id: 135,
		part_id: 143,
		quantity: 431,
	},
	{
		garage_id: 135,
		part_id: 144,
		quantity: 315,
	},
	{
		garage_id: 135,
		part_id: 145,
		quantity: 313,
	},
	{
		garage_id: 135,
		part_id: 146,
		quantity: 449,
	},
	{
		garage_id: 135,
		part_id: 147,
		quantity: 480,
	},
	{
		garage_id: 135,
		part_id: 148,
		quantity: 166,
	},
	{
		garage_id: 135,
		part_id: 149,
		quantity: 490,
	},
	{
		garage_id: 135,
		part_id: 150,
		quantity: 416,
	},
	{
		garage_id: 135,
		part_id: 151,
		quantity: 317,
	},
	{
		garage_id: 135,
		part_id: 152,
		quantity: 149,
	},
	{
		garage_id: 135,
		part_id: 153,
		quantity: 500,
	},
	{
		garage_id: 135,
		part_id: 154,
		quantity: 465,
	},
	{
		garage_id: 135,
		part_id: 155,
		quantity: 299,
	},
	{
		garage_id: 135,
		part_id: 156,
		quantity: 407,
	},
	{
		garage_id: 135,
		part_id: 157,
		quantity: 220,
	},
	{
		garage_id: 135,
		part_id: 158,
		quantity: 297,
	},
	{
		garage_id: 135,
		part_id: 159,
		quantity: 323,
	},
	{
		garage_id: 135,
		part_id: 160,
		quantity: 0,
	},
	{
		garage_id: 135,
		part_id: 161,
		quantity: 310,
	},
	{
		garage_id: 135,
		part_id: 162,
		quantity: 321,
	},
	{
		garage_id: 135,
		part_id: 163,
		quantity: 121,
	},
	{
		garage_id: 135,
		part_id: 164,
		quantity: 29,
	},
	{
		garage_id: 135,
		part_id: 165,
		quantity: 6,
	},
	{
		garage_id: 135,
		part_id: 166,
		quantity: 338,
	},
	{
		garage_id: 135,
		part_id: 167,
		quantity: 95,
	},
	{
		garage_id: 135,
		part_id: 168,
		quantity: 91,
	},
	{
		garage_id: 135,
		part_id: 169,
		quantity: 435,
	},
	{
		garage_id: 135,
		part_id: 170,
		quantity: 354,
	},
	{
		garage_id: 135,
		part_id: 171,
		quantity: 366,
	},
	{
		garage_id: 135,
		part_id: 172,
		quantity: 364,
	},
	{
		garage_id: 135,
		part_id: 173,
		quantity: 312,
	},
	{
		garage_id: 135,
		part_id: 174,
		quantity: 24,
	},
	{
		garage_id: 135,
		part_id: 175,
		quantity: 379,
	},
	{
		garage_id: 135,
		part_id: 176,
		quantity: 321,
	},
	{
		garage_id: 135,
		part_id: 177,
		quantity: 298,
	},
	{
		garage_id: 135,
		part_id: 178,
		quantity: 388,
	},
	{
		garage_id: 135,
		part_id: 179,
		quantity: 361,
	},
	{
		garage_id: 135,
		part_id: 180,
		quantity: 288,
	},
	{
		garage_id: 135,
		part_id: 181,
		quantity: 483,
	},
	{
		garage_id: 135,
		part_id: 182,
		quantity: 249,
	},
	{
		garage_id: 135,
		part_id: 183,
		quantity: 417,
	},
	{
		garage_id: 135,
		part_id: 184,
		quantity: 304,
	},
	{
		garage_id: 135,
		part_id: 185,
		quantity: 135,
	},
	{
		garage_id: 135,
		part_id: 186,
		quantity: 295,
	},
	{
		garage_id: 135,
		part_id: 187,
		quantity: 314,
	},
	{
		garage_id: 135,
		part_id: 188,
		quantity: 20,
	},
	{
		garage_id: 135,
		part_id: 189,
		quantity: 19,
	},
	{
		garage_id: 135,
		part_id: 190,
		quantity: 486,
	},
	{
		garage_id: 135,
		part_id: 191,
		quantity: 491,
	},
	{
		garage_id: 135,
		part_id: 192,
		quantity: 10,
	},
	{
		garage_id: 135,
		part_id: 193,
		quantity: 253,
	},
	{
		garage_id: 135,
		part_id: 194,
		quantity: 141,
	},
	{
		garage_id: 135,
		part_id: 195,
		quantity: 309,
	},
	{
		garage_id: 135,
		part_id: 196,
		quantity: 103,
	},
	{
		garage_id: 135,
		part_id: 197,
		quantity: 382,
	},
	{
		garage_id: 135,
		part_id: 198,
		quantity: 453,
	},
	{
		garage_id: 135,
		part_id: 199,
		quantity: 4,
	},
	{
		garage_id: 135,
		part_id: 200,
		quantity: 291,
	},
	{
		garage_id: 135,
		part_id: 201,
		quantity: 370,
	},
	{
		garage_id: 135,
		part_id: 202,
		quantity: 121,
	},
	{
		garage_id: 135,
		part_id: 203,
		quantity: 158,
	},
	{
		garage_id: 135,
		part_id: 204,
		quantity: 150,
	},
	{
		garage_id: 135,
		part_id: 205,
		quantity: 125,
	},
	{
		garage_id: 135,
		part_id: 206,
		quantity: 429,
	},
	{
		garage_id: 135,
		part_id: 207,
		quantity: 124,
	},
	{
		garage_id: 135,
		part_id: 208,
		quantity: 222,
	},
	{
		garage_id: 135,
		part_id: 209,
		quantity: 99,
	},
	{
		garage_id: 135,
		part_id: 210,
		quantity: 83,
	},
	{
		garage_id: 135,
		part_id: 211,
		quantity: 122,
	},
	{
		garage_id: 135,
		part_id: 212,
		quantity: 149,
	},
	{
		garage_id: 135,
		part_id: 213,
		quantity: 342,
	},
	{
		garage_id: 135,
		part_id: 214,
		quantity: 167,
	},
	{
		garage_id: 135,
		part_id: 215,
		quantity: 369,
	},
	{
		garage_id: 135,
		part_id: 216,
		quantity: 262,
	},
	{
		garage_id: 135,
		part_id: 217,
		quantity: 53,
	},
	{
		garage_id: 135,
		part_id: 218,
		quantity: 61,
	},
	{
		garage_id: 135,
		part_id: 219,
		quantity: 243,
	},
	{
		garage_id: 135,
		part_id: 220,
		quantity: 16,
	},
	{
		garage_id: 135,
		part_id: 221,
		quantity: 282,
	},
	{
		garage_id: 135,
		part_id: 222,
		quantity: 473,
	},
	{
		garage_id: 135,
		part_id: 223,
		quantity: 45,
	},
	{
		garage_id: 135,
		part_id: 224,
		quantity: 422,
	},
	{
		garage_id: 135,
		part_id: 225,
		quantity: 108,
	},
	{
		garage_id: 135,
		part_id: 226,
		quantity: 386,
	},
	{
		garage_id: 135,
		part_id: 227,
		quantity: 378,
	},
	{
		garage_id: 135,
		part_id: 228,
		quantity: 79,
	},
	{
		garage_id: 135,
		part_id: 229,
		quantity: 264,
	},
	{
		garage_id: 135,
		part_id: 230,
		quantity: 54,
	},
	{
		garage_id: 135,
		part_id: 231,
		quantity: 171,
	},
	{
		garage_id: 135,
		part_id: 232,
		quantity: 229,
	},
	{
		garage_id: 135,
		part_id: 233,
		quantity: 400,
	},
	{
		garage_id: 135,
		part_id: 234,
		quantity: 56,
	},
	{
		garage_id: 135,
		part_id: 235,
		quantity: 276,
	},
	{
		garage_id: 135,
		part_id: 236,
		quantity: 193,
	},
	{
		garage_id: 135,
		part_id: 237,
		quantity: 57,
	},
	{
		garage_id: 135,
		part_id: 238,
		quantity: 28,
	},
	{
		garage_id: 135,
		part_id: 239,
		quantity: 378,
	},
	{
		garage_id: 135,
		part_id: 240,
		quantity: 299,
	},
	{
		garage_id: 135,
		part_id: 241,
		quantity: 426,
	},
	{
		garage_id: 135,
		part_id: 242,
		quantity: 206,
	},
	{
		garage_id: 135,
		part_id: 243,
		quantity: 379,
	},
	{
		garage_id: 135,
		part_id: 244,
		quantity: 392,
	},
	{
		garage_id: 135,
		part_id: 245,
		quantity: 99,
	},
	{
		garage_id: 135,
		part_id: 246,
		quantity: 146,
	},
	{
		garage_id: 135,
		part_id: 247,
		quantity: 88,
	},
	{
		garage_id: 135,
		part_id: 248,
		quantity: 341,
	},
	{
		garage_id: 135,
		part_id: 249,
		quantity: 439,
	},
	{
		garage_id: 135,
		part_id: 250,
		quantity: 447,
	},
	{
		garage_id: 135,
		part_id: 251,
		quantity: 270,
	},
	{
		garage_id: 135,
		part_id: 252,
		quantity: 187,
	},
	{
		garage_id: 135,
		part_id: 253,
		quantity: 46,
	},
	{
		garage_id: 135,
		part_id: 254,
		quantity: 462,
	},
	{
		garage_id: 135,
		part_id: 255,
		quantity: 397,
	},
	{
		garage_id: 135,
		part_id: 256,
		quantity: 215,
	},
	{
		garage_id: 135,
		part_id: 257,
		quantity: 320,
	},
	{
		garage_id: 135,
		part_id: 258,
		quantity: 457,
	},
	{
		garage_id: 135,
		part_id: 259,
		quantity: 44,
	},
	{
		garage_id: 135,
		part_id: 260,
		quantity: 95,
	},
	{
		garage_id: 135,
		part_id: 261,
		quantity: 406,
	},
	{
		garage_id: 135,
		part_id: 262,
		quantity: 476,
	},
	{
		garage_id: 135,
		part_id: 263,
		quantity: 197,
	},
	{
		garage_id: 135,
		part_id: 264,
		quantity: 307,
	},
	{
		garage_id: 135,
		part_id: 265,
		quantity: 297,
	},
	{
		garage_id: 135,
		part_id: 266,
		quantity: 434,
	},
	{
		garage_id: 135,
		part_id: 267,
		quantity: 286,
	},
	{
		garage_id: 135,
		part_id: 268,
		quantity: 333,
	},
	{
		garage_id: 135,
		part_id: 269,
		quantity: 298,
	},
	{
		garage_id: 135,
		part_id: 270,
		quantity: 391,
	},
	{
		garage_id: 135,
		part_id: 271,
		quantity: 243,
	},
	{
		garage_id: 135,
		part_id: 272,
		quantity: 122,
	},
	{
		garage_id: 135,
		part_id: 273,
		quantity: 93,
	},
	{
		garage_id: 135,
		part_id: 274,
		quantity: 438,
	},
	{
		garage_id: 135,
		part_id: 275,
		quantity: 239,
	},
	{
		garage_id: 135,
		part_id: 276,
		quantity: 233,
	},
	{
		garage_id: 135,
		part_id: 277,
		quantity: 219,
	},
	{
		garage_id: 135,
		part_id: 278,
		quantity: 293,
	},
	{
		garage_id: 135,
		part_id: 279,
		quantity: 206,
	},
	{
		garage_id: 135,
		part_id: 280,
		quantity: 356,
	},
	{
		garage_id: 135,
		part_id: 281,
		quantity: 112,
	},
	{
		garage_id: 135,
		part_id: 282,
		quantity: 100,
	},
	{
		garage_id: 135,
		part_id: 283,
		quantity: 416,
	},
	{
		garage_id: 135,
		part_id: 284,
		quantity: 447,
	},
	{
		garage_id: 135,
		part_id: 285,
		quantity: 227,
	},
	{
		garage_id: 135,
		part_id: 286,
		quantity: 434,
	},
	{
		garage_id: 135,
		part_id: 287,
		quantity: 317,
	},
	{
		garage_id: 135,
		part_id: 288,
		quantity: 174,
	},
	{
		garage_id: 135,
		part_id: 289,
		quantity: 369,
	},
	{
		garage_id: 135,
		part_id: 290,
		quantity: 98,
	},
	{
		garage_id: 135,
		part_id: 291,
		quantity: 147,
	},
	{
		garage_id: 135,
		part_id: 292,
		quantity: 20,
	},
	{
		garage_id: 135,
		part_id: 293,
		quantity: 194,
	},
	{
		garage_id: 135,
		part_id: 294,
		quantity: 198,
	},
	{
		garage_id: 135,
		part_id: 295,
		quantity: 467,
	},
	{
		garage_id: 135,
		part_id: 296,
		quantity: 400,
	},
	{
		garage_id: 135,
		part_id: 297,
		quantity: 281,
	},
	{
		garage_id: 135,
		part_id: 298,
		quantity: 62,
	},
	{
		garage_id: 135,
		part_id: 299,
		quantity: 196,
	},
	{
		garage_id: 135,
		part_id: 300,
		quantity: 130,
	},
	{
		garage_id: 135,
		part_id: 301,
		quantity: 287,
	},
	{
		garage_id: 135,
		part_id: 302,
		quantity: 377,
	},
	{
		garage_id: 135,
		part_id: 303,
		quantity: 144,
	},
	{
		garage_id: 135,
		part_id: 304,
		quantity: 269,
	},
	{
		garage_id: 135,
		part_id: 305,
		quantity: 60,
	},
	{
		garage_id: 135,
		part_id: 306,
		quantity: 254,
	},
	{
		garage_id: 135,
		part_id: 307,
		quantity: 121,
	},
	{
		garage_id: 135,
		part_id: 308,
		quantity: 32,
	},
	{
		garage_id: 135,
		part_id: 309,
		quantity: 188,
	},
	{
		garage_id: 135,
		part_id: 310,
		quantity: 277,
	},
	{
		garage_id: 135,
		part_id: 311,
		quantity: 162,
	},
	{
		garage_id: 135,
		part_id: 312,
		quantity: 392,
	},
	{
		garage_id: 135,
		part_id: 313,
		quantity: 65,
	},
	{
		garage_id: 135,
		part_id: 314,
		quantity: 171,
	},
	{
		garage_id: 135,
		part_id: 315,
		quantity: 84,
	},
	{
		garage_id: 135,
		part_id: 316,
		quantity: 17,
	},
	{
		garage_id: 135,
		part_id: 317,
		quantity: 284,
	},
	{
		garage_id: 135,
		part_id: 318,
		quantity: 245,
	},
	{
		garage_id: 135,
		part_id: 319,
		quantity: 349,
	},
	{
		garage_id: 135,
		part_id: 320,
		quantity: 186,
	},
	{
		garage_id: 135,
		part_id: 321,
		quantity: 353,
	},
	{
		garage_id: 135,
		part_id: 322,
		quantity: 487,
	},
	{
		garage_id: 135,
		part_id: 323,
		quantity: 310,
	},
	{
		garage_id: 135,
		part_id: 324,
		quantity: 96,
	},
	{
		garage_id: 135,
		part_id: 325,
		quantity: 342,
	},
	{
		garage_id: 135,
		part_id: 326,
		quantity: 298,
	},
	{
		garage_id: 135,
		part_id: 327,
		quantity: 375,
	},
	{
		garage_id: 135,
		part_id: 328,
		quantity: 48,
	},
	{
		garage_id: 135,
		part_id: 329,
		quantity: 25,
	},
	{
		garage_id: 135,
		part_id: 330,
		quantity: 455,
	},
	{
		garage_id: 135,
		part_id: 331,
		quantity: 74,
	},
	{
		garage_id: 135,
		part_id: 332,
		quantity: 354,
	},
	{
		garage_id: 135,
		part_id: 333,
		quantity: 322,
	},
	{
		garage_id: 135,
		part_id: 334,
		quantity: 392,
	},
	{
		garage_id: 135,
		part_id: 335,
		quantity: 419,
	},
	{
		garage_id: 135,
		part_id: 336,
		quantity: 312,
	},
	{
		garage_id: 135,
		part_id: 337,
		quantity: 32,
	},
	{
		garage_id: 135,
		part_id: 338,
		quantity: 69,
	},
	{
		garage_id: 135,
		part_id: 339,
		quantity: 50,
	},
	{
		garage_id: 135,
		part_id: 340,
		quantity: 404,
	},
	{
		garage_id: 135,
		part_id: 341,
		quantity: 481,
	},
	{
		garage_id: 135,
		part_id: 342,
		quantity: 477,
	},
	{
		garage_id: 135,
		part_id: 343,
		quantity: 99,
	},
	{
		garage_id: 135,
		part_id: 344,
		quantity: 329,
	},
	{
		garage_id: 135,
		part_id: 345,
		quantity: 430,
	},
	{
		garage_id: 135,
		part_id: 346,
		quantity: 242,
	},
	{
		garage_id: 135,
		part_id: 347,
		quantity: 319,
	},
	{
		garage_id: 135,
		part_id: 348,
		quantity: 187,
	},
	{
		garage_id: 135,
		part_id: 349,
		quantity: 483,
	},
	{
		garage_id: 135,
		part_id: 350,
		quantity: 9,
	},
	{
		garage_id: 135,
		part_id: 351,
		quantity: 322,
	},
	{
		garage_id: 135,
		part_id: 352,
		quantity: 150,
	},
	{
		garage_id: 135,
		part_id: 353,
		quantity: 255,
	},
	{
		garage_id: 135,
		part_id: 354,
		quantity: 253,
	},
	{
		garage_id: 135,
		part_id: 355,
		quantity: 82,
	},
	{
		garage_id: 135,
		part_id: 356,
		quantity: 474,
	},
	{
		garage_id: 135,
		part_id: 357,
		quantity: 394,
	},
	{
		garage_id: 135,
		part_id: 358,
		quantity: 318,
	},
	{
		garage_id: 135,
		part_id: 359,
		quantity: 354,
	},
	{
		garage_id: 135,
		part_id: 360,
		quantity: 35,
	},
	{
		garage_id: 135,
		part_id: 361,
		quantity: 458,
	},
	{
		garage_id: 135,
		part_id: 362,
		quantity: 8,
	},
	{
		garage_id: 135,
		part_id: 363,
		quantity: 297,
	},
	{
		garage_id: 135,
		part_id: 364,
		quantity: 190,
	},
	{
		garage_id: 135,
		part_id: 365,
		quantity: 309,
	},
	{
		garage_id: 135,
		part_id: 366,
		quantity: 496,
	},
	{
		garage_id: 135,
		part_id: 367,
		quantity: 216,
	},
	{
		garage_id: 135,
		part_id: 368,
		quantity: 182,
	},
	{
		garage_id: 135,
		part_id: 369,
		quantity: 443,
	},
	{
		garage_id: 135,
		part_id: 370,
		quantity: 216,
	},
	{
		garage_id: 135,
		part_id: 371,
		quantity: 245,
	},
	{
		garage_id: 135,
		part_id: 372,
		quantity: 18,
	},
	{
		garage_id: 135,
		part_id: 373,
		quantity: 82,
	},
	{
		garage_id: 135,
		part_id: 374,
		quantity: 103,
	},
	{
		garage_id: 135,
		part_id: 375,
		quantity: 350,
	},
	{
		garage_id: 135,
		part_id: 376,
		quantity: 311,
	},
	{
		garage_id: 135,
		part_id: 377,
		quantity: 182,
	},
	{
		garage_id: 135,
		part_id: 378,
		quantity: 411,
	},
	{
		garage_id: 135,
		part_id: 379,
		quantity: 490,
	},
	{
		garage_id: 135,
		part_id: 380,
		quantity: 225,
	},
	{
		garage_id: 135,
		part_id: 381,
		quantity: 177,
	},
	{
		garage_id: 135,
		part_id: 382,
		quantity: 156,
	},
	{
		garage_id: 135,
		part_id: 383,
		quantity: 440,
	},
	{
		garage_id: 135,
		part_id: 384,
		quantity: 482,
	},
	{
		garage_id: 135,
		part_id: 385,
		quantity: 203,
	},
	{
		garage_id: 135,
		part_id: 386,
		quantity: 69,
	},
	{
		garage_id: 135,
		part_id: 387,
		quantity: 55,
	},
	{
		garage_id: 135,
		part_id: 388,
		quantity: 383,
	},
	{
		garage_id: 135,
		part_id: 389,
		quantity: 205,
	},
	{
		garage_id: 135,
		part_id: 390,
		quantity: 93,
	},
	{
		garage_id: 135,
		part_id: 391,
		quantity: 147,
	},
	{
		garage_id: 135,
		part_id: 392,
		quantity: 137,
	},
	{
		garage_id: 135,
		part_id: 393,
		quantity: 498,
	},
	{
		garage_id: 135,
		part_id: 394,
		quantity: 273,
	},
	{
		garage_id: 135,
		part_id: 395,
		quantity: 120,
	},
	{
		garage_id: 135,
		part_id: 396,
		quantity: 166,
	},
	{
		garage_id: 135,
		part_id: 397,
		quantity: 300,
	},
	{
		garage_id: 135,
		part_id: 398,
		quantity: 12,
	},
	{
		garage_id: 135,
		part_id: 399,
		quantity: 374,
	},
	{
		garage_id: 136,
		part_id: 0,
		quantity: 152,
	},
	{
		garage_id: 136,
		part_id: 1,
		quantity: 351,
	},
	{
		garage_id: 136,
		part_id: 2,
		quantity: 488,
	},
	{
		garage_id: 136,
		part_id: 3,
		quantity: 23,
	},
	{
		garage_id: 136,
		part_id: 4,
		quantity: 370,
	},
	{
		garage_id: 136,
		part_id: 5,
		quantity: 141,
	},
	{
		garage_id: 136,
		part_id: 6,
		quantity: 159,
	},
	{
		garage_id: 136,
		part_id: 7,
		quantity: 157,
	},
	{
		garage_id: 136,
		part_id: 8,
		quantity: 274,
	},
	{
		garage_id: 136,
		part_id: 9,
		quantity: 320,
	},
	{
		garage_id: 136,
		part_id: 10,
		quantity: 26,
	},
	{
		garage_id: 136,
		part_id: 11,
		quantity: 72,
	},
	{
		garage_id: 136,
		part_id: 12,
		quantity: 107,
	},
	{
		garage_id: 136,
		part_id: 13,
		quantity: 435,
	},
	{
		garage_id: 136,
		part_id: 14,
		quantity: 424,
	},
	{
		garage_id: 136,
		part_id: 15,
		quantity: 476,
	},
	{
		garage_id: 136,
		part_id: 16,
		quantity: 112,
	},
	{
		garage_id: 136,
		part_id: 17,
		quantity: 495,
	},
	{
		garage_id: 136,
		part_id: 18,
		quantity: 307,
	},
	{
		garage_id: 136,
		part_id: 19,
		quantity: 432,
	},
	{
		garage_id: 136,
		part_id: 20,
		quantity: 422,
	},
	{
		garage_id: 136,
		part_id: 21,
		quantity: 194,
	},
	{
		garage_id: 136,
		part_id: 22,
		quantity: 308,
	},
	{
		garage_id: 136,
		part_id: 23,
		quantity: 358,
	},
	{
		garage_id: 136,
		part_id: 24,
		quantity: 140,
	},
	{
		garage_id: 136,
		part_id: 25,
		quantity: 47,
	},
	{
		garage_id: 136,
		part_id: 26,
		quantity: 101,
	},
	{
		garage_id: 136,
		part_id: 27,
		quantity: 400,
	},
	{
		garage_id: 136,
		part_id: 28,
		quantity: 26,
	},
	{
		garage_id: 136,
		part_id: 29,
		quantity: 4,
	},
	{
		garage_id: 136,
		part_id: 30,
		quantity: 172,
	},
	{
		garage_id: 136,
		part_id: 31,
		quantity: 387,
	},
	{
		garage_id: 136,
		part_id: 32,
		quantity: 330,
	},
	{
		garage_id: 136,
		part_id: 33,
		quantity: 334,
	},
	{
		garage_id: 136,
		part_id: 34,
		quantity: 224,
	},
	{
		garage_id: 136,
		part_id: 35,
		quantity: 260,
	},
	{
		garage_id: 136,
		part_id: 36,
		quantity: 410,
	},
	{
		garage_id: 136,
		part_id: 37,
		quantity: 254,
	},
	{
		garage_id: 136,
		part_id: 38,
		quantity: 240,
	},
	{
		garage_id: 136,
		part_id: 39,
		quantity: 230,
	},
	{
		garage_id: 136,
		part_id: 40,
		quantity: 475,
	},
	{
		garage_id: 136,
		part_id: 41,
		quantity: 206,
	},
	{
		garage_id: 136,
		part_id: 42,
		quantity: 41,
	},
	{
		garage_id: 136,
		part_id: 43,
		quantity: 396,
	},
	{
		garage_id: 136,
		part_id: 44,
		quantity: 473,
	},
	{
		garage_id: 136,
		part_id: 45,
		quantity: 369,
	},
	{
		garage_id: 136,
		part_id: 46,
		quantity: 153,
	},
	{
		garage_id: 136,
		part_id: 47,
		quantity: 299,
	},
	{
		garage_id: 136,
		part_id: 48,
		quantity: 345,
	},
	{
		garage_id: 136,
		part_id: 49,
		quantity: 302,
	},
	{
		garage_id: 136,
		part_id: 50,
		quantity: 231,
	},
	{
		garage_id: 136,
		part_id: 51,
		quantity: 33,
	},
	{
		garage_id: 136,
		part_id: 52,
		quantity: 193,
	},
	{
		garage_id: 136,
		part_id: 53,
		quantity: 389,
	},
	{
		garage_id: 136,
		part_id: 54,
		quantity: 163,
	},
	{
		garage_id: 136,
		part_id: 55,
		quantity: 37,
	},
	{
		garage_id: 136,
		part_id: 56,
		quantity: 212,
	},
	{
		garage_id: 136,
		part_id: 57,
		quantity: 99,
	},
	{
		garage_id: 136,
		part_id: 58,
		quantity: 32,
	},
	{
		garage_id: 136,
		part_id: 59,
		quantity: 354,
	},
	{
		garage_id: 136,
		part_id: 60,
		quantity: 404,
	},
	{
		garage_id: 136,
		part_id: 61,
		quantity: 357,
	},
	{
		garage_id: 136,
		part_id: 62,
		quantity: 377,
	},
	{
		garage_id: 136,
		part_id: 63,
		quantity: 36,
	},
	{
		garage_id: 136,
		part_id: 64,
		quantity: 160,
	},
	{
		garage_id: 136,
		part_id: 65,
		quantity: 342,
	},
	{
		garage_id: 136,
		part_id: 66,
		quantity: 487,
	},
	{
		garage_id: 136,
		part_id: 67,
		quantity: 163,
	},
	{
		garage_id: 136,
		part_id: 68,
		quantity: 459,
	},
	{
		garage_id: 136,
		part_id: 69,
		quantity: 459,
	},
	{
		garage_id: 136,
		part_id: 70,
		quantity: 183,
	},
	{
		garage_id: 136,
		part_id: 71,
		quantity: 488,
	},
	{
		garage_id: 136,
		part_id: 72,
		quantity: 439,
	},
	{
		garage_id: 136,
		part_id: 73,
		quantity: 120,
	},
	{
		garage_id: 136,
		part_id: 74,
		quantity: 26,
	},
	{
		garage_id: 136,
		part_id: 75,
		quantity: 292,
	},
	{
		garage_id: 136,
		part_id: 76,
		quantity: 431,
	},
	{
		garage_id: 136,
		part_id: 77,
		quantity: 155,
	},
	{
		garage_id: 136,
		part_id: 78,
		quantity: 196,
	},
	{
		garage_id: 136,
		part_id: 79,
		quantity: 387,
	},
	{
		garage_id: 136,
		part_id: 80,
		quantity: 287,
	},
	{
		garage_id: 136,
		part_id: 81,
		quantity: 31,
	},
	{
		garage_id: 136,
		part_id: 82,
		quantity: 498,
	},
	{
		garage_id: 136,
		part_id: 83,
		quantity: 29,
	},
	{
		garage_id: 136,
		part_id: 84,
		quantity: 147,
	},
	{
		garage_id: 136,
		part_id: 85,
		quantity: 16,
	},
	{
		garage_id: 136,
		part_id: 86,
		quantity: 342,
	},
	{
		garage_id: 136,
		part_id: 87,
		quantity: 25,
	},
	{
		garage_id: 136,
		part_id: 88,
		quantity: 137,
	},
	{
		garage_id: 136,
		part_id: 89,
		quantity: 69,
	},
	{
		garage_id: 136,
		part_id: 90,
		quantity: 129,
	},
	{
		garage_id: 136,
		part_id: 91,
		quantity: 281,
	},
	{
		garage_id: 136,
		part_id: 92,
		quantity: 357,
	},
	{
		garage_id: 136,
		part_id: 93,
		quantity: 54,
	},
	{
		garage_id: 136,
		part_id: 94,
		quantity: 421,
	},
	{
		garage_id: 136,
		part_id: 95,
		quantity: 336,
	},
	{
		garage_id: 136,
		part_id: 96,
		quantity: 78,
	},
	{
		garage_id: 136,
		part_id: 97,
		quantity: 27,
	},
	{
		garage_id: 136,
		part_id: 98,
		quantity: 385,
	},
	{
		garage_id: 136,
		part_id: 99,
		quantity: 10,
	},
	{
		garage_id: 136,
		part_id: 100,
		quantity: 430,
	},
	{
		garage_id: 136,
		part_id: 101,
		quantity: 421,
	},
	{
		garage_id: 136,
		part_id: 102,
		quantity: 438,
	},
	{
		garage_id: 136,
		part_id: 103,
		quantity: 243,
	},
	{
		garage_id: 136,
		part_id: 104,
		quantity: 323,
	},
	{
		garage_id: 136,
		part_id: 105,
		quantity: 497,
	},
	{
		garage_id: 136,
		part_id: 106,
		quantity: 269,
	},
	{
		garage_id: 136,
		part_id: 107,
		quantity: 207,
	},
	{
		garage_id: 136,
		part_id: 108,
		quantity: 180,
	},
	{
		garage_id: 136,
		part_id: 109,
		quantity: 163,
	},
	{
		garage_id: 136,
		part_id: 110,
		quantity: 17,
	},
	{
		garage_id: 136,
		part_id: 111,
		quantity: 486,
	},
	{
		garage_id: 136,
		part_id: 112,
		quantity: 104,
	},
	{
		garage_id: 136,
		part_id: 113,
		quantity: 152,
	},
	{
		garage_id: 136,
		part_id: 114,
		quantity: 481,
	},
	{
		garage_id: 136,
		part_id: 115,
		quantity: 152,
	},
	{
		garage_id: 136,
		part_id: 116,
		quantity: 166,
	},
	{
		garage_id: 136,
		part_id: 117,
		quantity: 100,
	},
	{
		garage_id: 136,
		part_id: 118,
		quantity: 205,
	},
	{
		garage_id: 136,
		part_id: 119,
		quantity: 443,
	},
	{
		garage_id: 136,
		part_id: 120,
		quantity: 187,
	},
	{
		garage_id: 136,
		part_id: 121,
		quantity: 180,
	},
	{
		garage_id: 136,
		part_id: 122,
		quantity: 380,
	},
	{
		garage_id: 136,
		part_id: 123,
		quantity: 41,
	},
	{
		garage_id: 136,
		part_id: 124,
		quantity: 394,
	},
	{
		garage_id: 136,
		part_id: 125,
		quantity: 211,
	},
	{
		garage_id: 136,
		part_id: 126,
		quantity: 208,
	},
	{
		garage_id: 136,
		part_id: 127,
		quantity: 162,
	},
	{
		garage_id: 136,
		part_id: 128,
		quantity: 171,
	},
	{
		garage_id: 136,
		part_id: 129,
		quantity: 204,
	},
	{
		garage_id: 136,
		part_id: 130,
		quantity: 75,
	},
	{
		garage_id: 136,
		part_id: 131,
		quantity: 171,
	},
	{
		garage_id: 136,
		part_id: 132,
		quantity: 49,
	},
	{
		garage_id: 136,
		part_id: 133,
		quantity: 495,
	},
	{
		garage_id: 136,
		part_id: 134,
		quantity: 179,
	},
	{
		garage_id: 136,
		part_id: 135,
		quantity: 179,
	},
	{
		garage_id: 136,
		part_id: 136,
		quantity: 340,
	},
	{
		garage_id: 136,
		part_id: 137,
		quantity: 418,
	},
	{
		garage_id: 136,
		part_id: 138,
		quantity: 228,
	},
	{
		garage_id: 136,
		part_id: 139,
		quantity: 229,
	},
	{
		garage_id: 136,
		part_id: 140,
		quantity: 229,
	},
	{
		garage_id: 136,
		part_id: 141,
		quantity: 213,
	},
	{
		garage_id: 136,
		part_id: 142,
		quantity: 80,
	},
	{
		garage_id: 136,
		part_id: 143,
		quantity: 201,
	},
	{
		garage_id: 136,
		part_id: 144,
		quantity: 439,
	},
	{
		garage_id: 136,
		part_id: 145,
		quantity: 478,
	},
	{
		garage_id: 136,
		part_id: 146,
		quantity: 260,
	},
	{
		garage_id: 136,
		part_id: 147,
		quantity: 177,
	},
	{
		garage_id: 136,
		part_id: 148,
		quantity: 349,
	},
	{
		garage_id: 136,
		part_id: 149,
		quantity: 134,
	},
	{
		garage_id: 136,
		part_id: 150,
		quantity: 9,
	},
	{
		garage_id: 136,
		part_id: 151,
		quantity: 170,
	},
	{
		garage_id: 136,
		part_id: 152,
		quantity: 257,
	},
	{
		garage_id: 136,
		part_id: 153,
		quantity: 375,
	},
	{
		garage_id: 136,
		part_id: 154,
		quantity: 467,
	},
	{
		garage_id: 136,
		part_id: 155,
		quantity: 132,
	},
	{
		garage_id: 136,
		part_id: 156,
		quantity: 400,
	},
	{
		garage_id: 136,
		part_id: 157,
		quantity: 283,
	},
	{
		garage_id: 136,
		part_id: 158,
		quantity: 358,
	},
	{
		garage_id: 136,
		part_id: 159,
		quantity: 302,
	},
	{
		garage_id: 136,
		part_id: 160,
		quantity: 465,
	},
	{
		garage_id: 136,
		part_id: 161,
		quantity: 365,
	},
	{
		garage_id: 136,
		part_id: 162,
		quantity: 285,
	},
	{
		garage_id: 136,
		part_id: 163,
		quantity: 32,
	},
	{
		garage_id: 136,
		part_id: 164,
		quantity: 136,
	},
	{
		garage_id: 136,
		part_id: 165,
		quantity: 85,
	},
	{
		garage_id: 136,
		part_id: 166,
		quantity: 320,
	},
	{
		garage_id: 136,
		part_id: 167,
		quantity: 23,
	},
	{
		garage_id: 136,
		part_id: 168,
		quantity: 458,
	},
	{
		garage_id: 136,
		part_id: 169,
		quantity: 150,
	},
	{
		garage_id: 136,
		part_id: 170,
		quantity: 217,
	},
	{
		garage_id: 136,
		part_id: 171,
		quantity: 496,
	},
	{
		garage_id: 136,
		part_id: 172,
		quantity: 352,
	},
	{
		garage_id: 136,
		part_id: 173,
		quantity: 162,
	},
	{
		garage_id: 136,
		part_id: 174,
		quantity: 63,
	},
	{
		garage_id: 136,
		part_id: 175,
		quantity: 57,
	},
	{
		garage_id: 136,
		part_id: 176,
		quantity: 402,
	},
	{
		garage_id: 136,
		part_id: 177,
		quantity: 492,
	},
	{
		garage_id: 136,
		part_id: 178,
		quantity: 377,
	},
	{
		garage_id: 136,
		part_id: 179,
		quantity: 284,
	},
	{
		garage_id: 136,
		part_id: 180,
		quantity: 19,
	},
	{
		garage_id: 136,
		part_id: 181,
		quantity: 348,
	},
	{
		garage_id: 136,
		part_id: 182,
		quantity: 248,
	},
	{
		garage_id: 136,
		part_id: 183,
		quantity: 237,
	},
	{
		garage_id: 136,
		part_id: 184,
		quantity: 416,
	},
	{
		garage_id: 136,
		part_id: 185,
		quantity: 67,
	},
	{
		garage_id: 136,
		part_id: 186,
		quantity: 178,
	},
	{
		garage_id: 136,
		part_id: 187,
		quantity: 28,
	},
	{
		garage_id: 136,
		part_id: 188,
		quantity: 387,
	},
	{
		garage_id: 136,
		part_id: 189,
		quantity: 431,
	},
	{
		garage_id: 136,
		part_id: 190,
		quantity: 414,
	},
	{
		garage_id: 136,
		part_id: 191,
		quantity: 327,
	},
	{
		garage_id: 136,
		part_id: 192,
		quantity: 444,
	},
	{
		garage_id: 136,
		part_id: 193,
		quantity: 347,
	},
	{
		garage_id: 136,
		part_id: 194,
		quantity: 355,
	},
	{
		garage_id: 136,
		part_id: 195,
		quantity: 419,
	},
	{
		garage_id: 136,
		part_id: 196,
		quantity: 155,
	},
	{
		garage_id: 136,
		part_id: 197,
		quantity: 446,
	},
	{
		garage_id: 136,
		part_id: 198,
		quantity: 174,
	},
	{
		garage_id: 136,
		part_id: 199,
		quantity: 430,
	},
	{
		garage_id: 136,
		part_id: 200,
		quantity: 296,
	},
	{
		garage_id: 136,
		part_id: 201,
		quantity: 412,
	},
	{
		garage_id: 136,
		part_id: 202,
		quantity: 277,
	},
	{
		garage_id: 136,
		part_id: 203,
		quantity: 246,
	},
	{
		garage_id: 136,
		part_id: 204,
		quantity: 327,
	},
	{
		garage_id: 136,
		part_id: 205,
		quantity: 474,
	},
	{
		garage_id: 136,
		part_id: 206,
		quantity: 356,
	},
	{
		garage_id: 136,
		part_id: 207,
		quantity: 126,
	},
	{
		garage_id: 136,
		part_id: 208,
		quantity: 182,
	},
	{
		garage_id: 136,
		part_id: 209,
		quantity: 422,
	},
	{
		garage_id: 136,
		part_id: 210,
		quantity: 319,
	},
	{
		garage_id: 136,
		part_id: 211,
		quantity: 34,
	},
	{
		garage_id: 136,
		part_id: 212,
		quantity: 477,
	},
	{
		garage_id: 136,
		part_id: 213,
		quantity: 46,
	},
	{
		garage_id: 136,
		part_id: 214,
		quantity: 135,
	},
	{
		garage_id: 136,
		part_id: 215,
		quantity: 332,
	},
	{
		garage_id: 136,
		part_id: 216,
		quantity: 345,
	},
	{
		garage_id: 136,
		part_id: 217,
		quantity: 202,
	},
	{
		garage_id: 136,
		part_id: 218,
		quantity: 6,
	},
	{
		garage_id: 136,
		part_id: 219,
		quantity: 8,
	},
	{
		garage_id: 136,
		part_id: 220,
		quantity: 495,
	},
	{
		garage_id: 136,
		part_id: 221,
		quantity: 29,
	},
	{
		garage_id: 136,
		part_id: 222,
		quantity: 98,
	},
	{
		garage_id: 136,
		part_id: 223,
		quantity: 198,
	},
	{
		garage_id: 136,
		part_id: 224,
		quantity: 92,
	},
	{
		garage_id: 136,
		part_id: 225,
		quantity: 253,
	},
	{
		garage_id: 136,
		part_id: 226,
		quantity: 61,
	},
	{
		garage_id: 136,
		part_id: 227,
		quantity: 100,
	},
	{
		garage_id: 136,
		part_id: 228,
		quantity: 10,
	},
	{
		garage_id: 136,
		part_id: 229,
		quantity: 266,
	},
	{
		garage_id: 136,
		part_id: 230,
		quantity: 477,
	},
	{
		garage_id: 136,
		part_id: 231,
		quantity: 358,
	},
	{
		garage_id: 136,
		part_id: 232,
		quantity: 113,
	},
	{
		garage_id: 136,
		part_id: 233,
		quantity: 466,
	},
	{
		garage_id: 136,
		part_id: 234,
		quantity: 369,
	},
	{
		garage_id: 136,
		part_id: 235,
		quantity: 365,
	},
	{
		garage_id: 136,
		part_id: 236,
		quantity: 241,
	},
	{
		garage_id: 136,
		part_id: 237,
		quantity: 142,
	},
	{
		garage_id: 136,
		part_id: 238,
		quantity: 424,
	},
	{
		garage_id: 136,
		part_id: 239,
		quantity: 373,
	},
	{
		garage_id: 136,
		part_id: 240,
		quantity: 353,
	},
	{
		garage_id: 136,
		part_id: 241,
		quantity: 37,
	},
	{
		garage_id: 136,
		part_id: 242,
		quantity: 410,
	},
	{
		garage_id: 136,
		part_id: 243,
		quantity: 132,
	},
	{
		garage_id: 136,
		part_id: 244,
		quantity: 29,
	},
	{
		garage_id: 136,
		part_id: 245,
		quantity: 345,
	},
	{
		garage_id: 136,
		part_id: 246,
		quantity: 205,
	},
	{
		garage_id: 136,
		part_id: 247,
		quantity: 347,
	},
	{
		garage_id: 136,
		part_id: 248,
		quantity: 472,
	},
	{
		garage_id: 136,
		part_id: 249,
		quantity: 91,
	},
	{
		garage_id: 136,
		part_id: 250,
		quantity: 32,
	},
	{
		garage_id: 136,
		part_id: 251,
		quantity: 43,
	},
	{
		garage_id: 136,
		part_id: 252,
		quantity: 455,
	},
	{
		garage_id: 136,
		part_id: 253,
		quantity: 86,
	},
	{
		garage_id: 136,
		part_id: 254,
		quantity: 221,
	},
	{
		garage_id: 136,
		part_id: 255,
		quantity: 190,
	},
	{
		garage_id: 136,
		part_id: 256,
		quantity: 497,
	},
	{
		garage_id: 136,
		part_id: 257,
		quantity: 28,
	},
	{
		garage_id: 136,
		part_id: 258,
		quantity: 311,
	},
	{
		garage_id: 136,
		part_id: 259,
		quantity: 11,
	},
	{
		garage_id: 136,
		part_id: 260,
		quantity: 172,
	},
	{
		garage_id: 136,
		part_id: 261,
		quantity: 238,
	},
	{
		garage_id: 136,
		part_id: 262,
		quantity: 150,
	},
	{
		garage_id: 136,
		part_id: 263,
		quantity: 324,
	},
	{
		garage_id: 136,
		part_id: 264,
		quantity: 192,
	},
	{
		garage_id: 136,
		part_id: 265,
		quantity: 5,
	},
	{
		garage_id: 136,
		part_id: 266,
		quantity: 231,
	},
	{
		garage_id: 136,
		part_id: 267,
		quantity: 497,
	},
	{
		garage_id: 136,
		part_id: 268,
		quantity: 10,
	},
	{
		garage_id: 136,
		part_id: 269,
		quantity: 304,
	},
	{
		garage_id: 136,
		part_id: 270,
		quantity: 44,
	},
	{
		garage_id: 136,
		part_id: 271,
		quantity: 251,
	},
	{
		garage_id: 136,
		part_id: 272,
		quantity: 82,
	},
	{
		garage_id: 136,
		part_id: 273,
		quantity: 466,
	},
	{
		garage_id: 136,
		part_id: 274,
		quantity: 454,
	},
	{
		garage_id: 136,
		part_id: 275,
		quantity: 229,
	},
	{
		garage_id: 136,
		part_id: 276,
		quantity: 250,
	},
	{
		garage_id: 136,
		part_id: 277,
		quantity: 55,
	},
	{
		garage_id: 136,
		part_id: 278,
		quantity: 347,
	},
	{
		garage_id: 136,
		part_id: 279,
		quantity: 444,
	},
	{
		garage_id: 136,
		part_id: 280,
		quantity: 255,
	},
	{
		garage_id: 136,
		part_id: 281,
		quantity: 79,
	},
	{
		garage_id: 136,
		part_id: 282,
		quantity: 156,
	},
	{
		garage_id: 136,
		part_id: 283,
		quantity: 95,
	},
	{
		garage_id: 136,
		part_id: 284,
		quantity: 392,
	},
	{
		garage_id: 136,
		part_id: 285,
		quantity: 382,
	},
	{
		garage_id: 136,
		part_id: 286,
		quantity: 283,
	},
	{
		garage_id: 136,
		part_id: 287,
		quantity: 424,
	},
	{
		garage_id: 136,
		part_id: 288,
		quantity: 43,
	},
	{
		garage_id: 136,
		part_id: 289,
		quantity: 296,
	},
	{
		garage_id: 136,
		part_id: 290,
		quantity: 79,
	},
	{
		garage_id: 136,
		part_id: 291,
		quantity: 353,
	},
	{
		garage_id: 136,
		part_id: 292,
		quantity: 426,
	},
	{
		garage_id: 136,
		part_id: 293,
		quantity: 175,
	},
	{
		garage_id: 136,
		part_id: 294,
		quantity: 225,
	},
	{
		garage_id: 136,
		part_id: 295,
		quantity: 305,
	},
	{
		garage_id: 136,
		part_id: 296,
		quantity: 130,
	},
	{
		garage_id: 136,
		part_id: 297,
		quantity: 352,
	},
	{
		garage_id: 136,
		part_id: 298,
		quantity: 7,
	},
	{
		garage_id: 136,
		part_id: 299,
		quantity: 193,
	},
	{
		garage_id: 136,
		part_id: 300,
		quantity: 494,
	},
	{
		garage_id: 136,
		part_id: 301,
		quantity: 7,
	},
	{
		garage_id: 136,
		part_id: 302,
		quantity: 282,
	},
	{
		garage_id: 136,
		part_id: 303,
		quantity: 294,
	},
	{
		garage_id: 136,
		part_id: 304,
		quantity: 426,
	},
	{
		garage_id: 136,
		part_id: 305,
		quantity: 499,
	},
	{
		garage_id: 136,
		part_id: 306,
		quantity: 48,
	},
	{
		garage_id: 136,
		part_id: 307,
		quantity: 104,
	},
	{
		garage_id: 136,
		part_id: 308,
		quantity: 431,
	},
	{
		garage_id: 136,
		part_id: 309,
		quantity: 62,
	},
	{
		garage_id: 136,
		part_id: 310,
		quantity: 278,
	},
	{
		garage_id: 136,
		part_id: 311,
		quantity: 126,
	},
	{
		garage_id: 136,
		part_id: 312,
		quantity: 238,
	},
	{
		garage_id: 136,
		part_id: 313,
		quantity: 103,
	},
	{
		garage_id: 136,
		part_id: 314,
		quantity: 145,
	},
	{
		garage_id: 136,
		part_id: 315,
		quantity: 57,
	},
	{
		garage_id: 136,
		part_id: 316,
		quantity: 381,
	},
	{
		garage_id: 136,
		part_id: 317,
		quantity: 98,
	},
	{
		garage_id: 136,
		part_id: 318,
		quantity: 364,
	},
	{
		garage_id: 136,
		part_id: 319,
		quantity: 391,
	},
	{
		garage_id: 136,
		part_id: 320,
		quantity: 93,
	},
	{
		garage_id: 136,
		part_id: 321,
		quantity: 228,
	},
	{
		garage_id: 136,
		part_id: 322,
		quantity: 459,
	},
	{
		garage_id: 136,
		part_id: 323,
		quantity: 269,
	},
	{
		garage_id: 136,
		part_id: 324,
		quantity: 417,
	},
	{
		garage_id: 136,
		part_id: 325,
		quantity: 436,
	},
	{
		garage_id: 136,
		part_id: 326,
		quantity: 187,
	},
	{
		garage_id: 136,
		part_id: 327,
		quantity: 65,
	},
	{
		garage_id: 136,
		part_id: 328,
		quantity: 54,
	},
	{
		garage_id: 136,
		part_id: 329,
		quantity: 444,
	},
	{
		garage_id: 136,
		part_id: 330,
		quantity: 479,
	},
	{
		garage_id: 136,
		part_id: 331,
		quantity: 320,
	},
	{
		garage_id: 136,
		part_id: 332,
		quantity: 236,
	},
	{
		garage_id: 136,
		part_id: 333,
		quantity: 332,
	},
	{
		garage_id: 136,
		part_id: 334,
		quantity: 13,
	},
	{
		garage_id: 136,
		part_id: 335,
		quantity: 30,
	},
	{
		garage_id: 136,
		part_id: 336,
		quantity: 474,
	},
	{
		garage_id: 136,
		part_id: 337,
		quantity: 381,
	},
	{
		garage_id: 136,
		part_id: 338,
		quantity: 309,
	},
	{
		garage_id: 136,
		part_id: 339,
		quantity: 209,
	},
	{
		garage_id: 136,
		part_id: 340,
		quantity: 320,
	},
	{
		garage_id: 136,
		part_id: 341,
		quantity: 376,
	},
	{
		garage_id: 136,
		part_id: 342,
		quantity: 320,
	},
	{
		garage_id: 136,
		part_id: 343,
		quantity: 489,
	},
	{
		garage_id: 136,
		part_id: 344,
		quantity: 115,
	},
	{
		garage_id: 136,
		part_id: 345,
		quantity: 261,
	},
	{
		garage_id: 136,
		part_id: 346,
		quantity: 239,
	},
	{
		garage_id: 136,
		part_id: 347,
		quantity: 159,
	},
	{
		garage_id: 136,
		part_id: 348,
		quantity: 181,
	},
	{
		garage_id: 136,
		part_id: 349,
		quantity: 254,
	},
	{
		garage_id: 136,
		part_id: 350,
		quantity: 36,
	},
	{
		garage_id: 136,
		part_id: 351,
		quantity: 371,
	},
	{
		garage_id: 136,
		part_id: 352,
		quantity: 27,
	},
	{
		garage_id: 136,
		part_id: 353,
		quantity: 475,
	},
	{
		garage_id: 136,
		part_id: 354,
		quantity: 437,
	},
	{
		garage_id: 136,
		part_id: 355,
		quantity: 480,
	},
	{
		garage_id: 136,
		part_id: 356,
		quantity: 239,
	},
	{
		garage_id: 136,
		part_id: 357,
		quantity: 131,
	},
	{
		garage_id: 136,
		part_id: 358,
		quantity: 235,
	},
	{
		garage_id: 136,
		part_id: 359,
		quantity: 346,
	},
	{
		garage_id: 136,
		part_id: 360,
		quantity: 154,
	},
	{
		garage_id: 136,
		part_id: 361,
		quantity: 462,
	},
	{
		garage_id: 136,
		part_id: 362,
		quantity: 65,
	},
	{
		garage_id: 136,
		part_id: 363,
		quantity: 378,
	},
	{
		garage_id: 136,
		part_id: 364,
		quantity: 115,
	},
	{
		garage_id: 136,
		part_id: 365,
		quantity: 224,
	},
	{
		garage_id: 136,
		part_id: 366,
		quantity: 439,
	},
	{
		garage_id: 136,
		part_id: 367,
		quantity: 247,
	},
	{
		garage_id: 136,
		part_id: 368,
		quantity: 292,
	},
	{
		garage_id: 136,
		part_id: 369,
		quantity: 207,
	},
	{
		garage_id: 136,
		part_id: 370,
		quantity: 229,
	},
	{
		garage_id: 136,
		part_id: 371,
		quantity: 214,
	},
	{
		garage_id: 136,
		part_id: 372,
		quantity: 219,
	},
	{
		garage_id: 136,
		part_id: 373,
		quantity: 244,
	},
	{
		garage_id: 136,
		part_id: 374,
		quantity: 78,
	},
	{
		garage_id: 136,
		part_id: 375,
		quantity: 248,
	},
	{
		garage_id: 136,
		part_id: 376,
		quantity: 114,
	},
	{
		garage_id: 136,
		part_id: 377,
		quantity: 93,
	},
	{
		garage_id: 136,
		part_id: 378,
		quantity: 199,
	},
	{
		garage_id: 136,
		part_id: 379,
		quantity: 194,
	},
	{
		garage_id: 136,
		part_id: 380,
		quantity: 393,
	},
	{
		garage_id: 136,
		part_id: 381,
		quantity: 111,
	},
	{
		garage_id: 136,
		part_id: 382,
		quantity: 69,
	},
	{
		garage_id: 136,
		part_id: 383,
		quantity: 8,
	},
	{
		garage_id: 136,
		part_id: 384,
		quantity: 393,
	},
	{
		garage_id: 136,
		part_id: 385,
		quantity: 461,
	},
	{
		garage_id: 136,
		part_id: 386,
		quantity: 86,
	},
	{
		garage_id: 136,
		part_id: 387,
		quantity: 439,
	},
	{
		garage_id: 136,
		part_id: 388,
		quantity: 489,
	},
	{
		garage_id: 136,
		part_id: 389,
		quantity: 229,
	},
	{
		garage_id: 136,
		part_id: 390,
		quantity: 250,
	},
	{
		garage_id: 136,
		part_id: 391,
		quantity: 482,
	},
	{
		garage_id: 136,
		part_id: 392,
		quantity: 122,
	},
	{
		garage_id: 136,
		part_id: 393,
		quantity: 145,
	},
	{
		garage_id: 136,
		part_id: 394,
		quantity: 227,
	},
	{
		garage_id: 136,
		part_id: 395,
		quantity: 188,
	},
	{
		garage_id: 136,
		part_id: 396,
		quantity: 275,
	},
	{
		garage_id: 136,
		part_id: 397,
		quantity: 136,
	},
	{
		garage_id: 136,
		part_id: 398,
		quantity: 463,
	},
	{
		garage_id: 136,
		part_id: 399,
		quantity: 49,
	},
	{
		garage_id: 137,
		part_id: 0,
		quantity: 85,
	},
	{
		garage_id: 137,
		part_id: 1,
		quantity: 476,
	},
	{
		garage_id: 137,
		part_id: 2,
		quantity: 215,
	},
	{
		garage_id: 137,
		part_id: 3,
		quantity: 94,
	},
	{
		garage_id: 137,
		part_id: 4,
		quantity: 77,
	},
	{
		garage_id: 137,
		part_id: 5,
		quantity: 251,
	},
	{
		garage_id: 137,
		part_id: 6,
		quantity: 218,
	},
	{
		garage_id: 137,
		part_id: 7,
		quantity: 45,
	},
	{
		garage_id: 137,
		part_id: 8,
		quantity: 245,
	},
	{
		garage_id: 137,
		part_id: 9,
		quantity: 332,
	},
	{
		garage_id: 137,
		part_id: 10,
		quantity: 42,
	},
	{
		garage_id: 137,
		part_id: 11,
		quantity: 22,
	},
	{
		garage_id: 137,
		part_id: 12,
		quantity: 200,
	},
	{
		garage_id: 137,
		part_id: 13,
		quantity: 163,
	},
	{
		garage_id: 137,
		part_id: 14,
		quantity: 337,
	},
	{
		garage_id: 137,
		part_id: 15,
		quantity: 193,
	},
	{
		garage_id: 137,
		part_id: 16,
		quantity: 241,
	},
	{
		garage_id: 137,
		part_id: 17,
		quantity: 458,
	},
	{
		garage_id: 137,
		part_id: 18,
		quantity: 385,
	},
	{
		garage_id: 137,
		part_id: 19,
		quantity: 154,
	},
	{
		garage_id: 137,
		part_id: 20,
		quantity: 281,
	},
	{
		garage_id: 137,
		part_id: 21,
		quantity: 320,
	},
	{
		garage_id: 137,
		part_id: 22,
		quantity: 136,
	},
	{
		garage_id: 137,
		part_id: 23,
		quantity: 180,
	},
	{
		garage_id: 137,
		part_id: 24,
		quantity: 12,
	},
	{
		garage_id: 137,
		part_id: 25,
		quantity: 23,
	},
	{
		garage_id: 137,
		part_id: 26,
		quantity: 226,
	},
	{
		garage_id: 137,
		part_id: 27,
		quantity: 32,
	},
	{
		garage_id: 137,
		part_id: 28,
		quantity: 116,
	},
	{
		garage_id: 137,
		part_id: 29,
		quantity: 176,
	},
	{
		garage_id: 137,
		part_id: 30,
		quantity: 251,
	},
	{
		garage_id: 137,
		part_id: 31,
		quantity: 428,
	},
	{
		garage_id: 137,
		part_id: 32,
		quantity: 216,
	},
	{
		garage_id: 137,
		part_id: 33,
		quantity: 280,
	},
	{
		garage_id: 137,
		part_id: 34,
		quantity: 16,
	},
	{
		garage_id: 137,
		part_id: 35,
		quantity: 406,
	},
	{
		garage_id: 137,
		part_id: 36,
		quantity: 330,
	},
	{
		garage_id: 137,
		part_id: 37,
		quantity: 412,
	},
	{
		garage_id: 137,
		part_id: 38,
		quantity: 234,
	},
	{
		garage_id: 137,
		part_id: 39,
		quantity: 131,
	},
	{
		garage_id: 137,
		part_id: 40,
		quantity: 379,
	},
	{
		garage_id: 137,
		part_id: 41,
		quantity: 268,
	},
	{
		garage_id: 137,
		part_id: 42,
		quantity: 472,
	},
	{
		garage_id: 137,
		part_id: 43,
		quantity: 295,
	},
	{
		garage_id: 137,
		part_id: 44,
		quantity: 450,
	},
	{
		garage_id: 137,
		part_id: 45,
		quantity: 493,
	},
	{
		garage_id: 137,
		part_id: 46,
		quantity: 37,
	},
	{
		garage_id: 137,
		part_id: 47,
		quantity: 36,
	},
	{
		garage_id: 137,
		part_id: 48,
		quantity: 17,
	},
	{
		garage_id: 137,
		part_id: 49,
		quantity: 70,
	},
	{
		garage_id: 137,
		part_id: 50,
		quantity: 127,
	},
	{
		garage_id: 137,
		part_id: 51,
		quantity: 207,
	},
	{
		garage_id: 137,
		part_id: 52,
		quantity: 285,
	},
	{
		garage_id: 137,
		part_id: 53,
		quantity: 9,
	},
	{
		garage_id: 137,
		part_id: 54,
		quantity: 354,
	},
	{
		garage_id: 137,
		part_id: 55,
		quantity: 367,
	},
	{
		garage_id: 137,
		part_id: 56,
		quantity: 278,
	},
	{
		garage_id: 137,
		part_id: 57,
		quantity: 194,
	},
	{
		garage_id: 137,
		part_id: 58,
		quantity: 285,
	},
	{
		garage_id: 137,
		part_id: 59,
		quantity: 471,
	},
	{
		garage_id: 137,
		part_id: 60,
		quantity: 215,
	},
	{
		garage_id: 137,
		part_id: 61,
		quantity: 67,
	},
	{
		garage_id: 137,
		part_id: 62,
		quantity: 486,
	},
	{
		garage_id: 137,
		part_id: 63,
		quantity: 308,
	},
	{
		garage_id: 137,
		part_id: 64,
		quantity: 300,
	},
	{
		garage_id: 137,
		part_id: 65,
		quantity: 250,
	},
	{
		garage_id: 137,
		part_id: 66,
		quantity: 415,
	},
	{
		garage_id: 137,
		part_id: 67,
		quantity: 206,
	},
	{
		garage_id: 137,
		part_id: 68,
		quantity: 46,
	},
	{
		garage_id: 137,
		part_id: 69,
		quantity: 443,
	},
	{
		garage_id: 137,
		part_id: 70,
		quantity: 170,
	},
	{
		garage_id: 137,
		part_id: 71,
		quantity: 225,
	},
	{
		garage_id: 137,
		part_id: 72,
		quantity: 148,
	},
	{
		garage_id: 137,
		part_id: 73,
		quantity: 339,
	},
	{
		garage_id: 137,
		part_id: 74,
		quantity: 352,
	},
	{
		garage_id: 137,
		part_id: 75,
		quantity: 445,
	},
	{
		garage_id: 137,
		part_id: 76,
		quantity: 112,
	},
	{
		garage_id: 137,
		part_id: 77,
		quantity: 418,
	},
	{
		garage_id: 137,
		part_id: 78,
		quantity: 165,
	},
	{
		garage_id: 137,
		part_id: 79,
		quantity: 215,
	},
	{
		garage_id: 137,
		part_id: 80,
		quantity: 68,
	},
	{
		garage_id: 137,
		part_id: 81,
		quantity: 158,
	},
	{
		garage_id: 137,
		part_id: 82,
		quantity: 47,
	},
	{
		garage_id: 137,
		part_id: 83,
		quantity: 325,
	},
	{
		garage_id: 137,
		part_id: 84,
		quantity: 312,
	},
	{
		garage_id: 137,
		part_id: 85,
		quantity: 471,
	},
	{
		garage_id: 137,
		part_id: 86,
		quantity: 340,
	},
	{
		garage_id: 137,
		part_id: 87,
		quantity: 22,
	},
	{
		garage_id: 137,
		part_id: 88,
		quantity: 6,
	},
	{
		garage_id: 137,
		part_id: 89,
		quantity: 359,
	},
	{
		garage_id: 137,
		part_id: 90,
		quantity: 178,
	},
	{
		garage_id: 137,
		part_id: 91,
		quantity: 84,
	},
	{
		garage_id: 137,
		part_id: 92,
		quantity: 413,
	},
	{
		garage_id: 137,
		part_id: 93,
		quantity: 56,
	},
	{
		garage_id: 137,
		part_id: 94,
		quantity: 21,
	},
	{
		garage_id: 137,
		part_id: 95,
		quantity: 336,
	},
	{
		garage_id: 137,
		part_id: 96,
		quantity: 409,
	},
	{
		garage_id: 137,
		part_id: 97,
		quantity: 154,
	},
	{
		garage_id: 137,
		part_id: 98,
		quantity: 411,
	},
	{
		garage_id: 137,
		part_id: 99,
		quantity: 353,
	},
	{
		garage_id: 137,
		part_id: 100,
		quantity: 175,
	},
	{
		garage_id: 137,
		part_id: 101,
		quantity: 458,
	},
	{
		garage_id: 137,
		part_id: 102,
		quantity: 31,
	},
	{
		garage_id: 137,
		part_id: 103,
		quantity: 202,
	},
	{
		garage_id: 137,
		part_id: 104,
		quantity: 441,
	},
	{
		garage_id: 137,
		part_id: 105,
		quantity: 486,
	},
	{
		garage_id: 137,
		part_id: 106,
		quantity: 244,
	},
	{
		garage_id: 137,
		part_id: 107,
		quantity: 474,
	},
	{
		garage_id: 137,
		part_id: 108,
		quantity: 368,
	},
	{
		garage_id: 137,
		part_id: 109,
		quantity: 356,
	},
	{
		garage_id: 137,
		part_id: 110,
		quantity: 494,
	},
	{
		garage_id: 137,
		part_id: 111,
		quantity: 130,
	},
	{
		garage_id: 137,
		part_id: 112,
		quantity: 171,
	},
	{
		garage_id: 137,
		part_id: 113,
		quantity: 278,
	},
	{
		garage_id: 137,
		part_id: 114,
		quantity: 420,
	},
	{
		garage_id: 137,
		part_id: 115,
		quantity: 430,
	},
	{
		garage_id: 137,
		part_id: 116,
		quantity: 446,
	},
	{
		garage_id: 137,
		part_id: 117,
		quantity: 65,
	},
	{
		garage_id: 137,
		part_id: 118,
		quantity: 235,
	},
	{
		garage_id: 137,
		part_id: 119,
		quantity: 142,
	},
	{
		garage_id: 137,
		part_id: 120,
		quantity: 87,
	},
	{
		garage_id: 137,
		part_id: 121,
		quantity: 314,
	},
	{
		garage_id: 137,
		part_id: 122,
		quantity: 142,
	},
	{
		garage_id: 137,
		part_id: 123,
		quantity: 445,
	},
	{
		garage_id: 137,
		part_id: 124,
		quantity: 468,
	},
	{
		garage_id: 137,
		part_id: 125,
		quantity: 483,
	},
	{
		garage_id: 137,
		part_id: 126,
		quantity: 185,
	},
	{
		garage_id: 137,
		part_id: 127,
		quantity: 482,
	},
	{
		garage_id: 137,
		part_id: 128,
		quantity: 244,
	},
	{
		garage_id: 137,
		part_id: 129,
		quantity: 91,
	},
	{
		garage_id: 137,
		part_id: 130,
		quantity: 394,
	},
	{
		garage_id: 137,
		part_id: 131,
		quantity: 380,
	},
	{
		garage_id: 137,
		part_id: 132,
		quantity: 368,
	},
	{
		garage_id: 137,
		part_id: 133,
		quantity: 306,
	},
	{
		garage_id: 137,
		part_id: 134,
		quantity: 93,
	},
	{
		garage_id: 137,
		part_id: 135,
		quantity: 240,
	},
	{
		garage_id: 137,
		part_id: 136,
		quantity: 179,
	},
	{
		garage_id: 137,
		part_id: 137,
		quantity: 429,
	},
	{
		garage_id: 137,
		part_id: 138,
		quantity: 267,
	},
	{
		garage_id: 137,
		part_id: 139,
		quantity: 381,
	},
	{
		garage_id: 137,
		part_id: 140,
		quantity: 343,
	},
	{
		garage_id: 137,
		part_id: 141,
		quantity: 85,
	},
	{
		garage_id: 137,
		part_id: 142,
		quantity: 375,
	},
	{
		garage_id: 137,
		part_id: 143,
		quantity: 1,
	},
	{
		garage_id: 137,
		part_id: 144,
		quantity: 75,
	},
	{
		garage_id: 137,
		part_id: 145,
		quantity: 149,
	},
	{
		garage_id: 137,
		part_id: 146,
		quantity: 57,
	},
	{
		garage_id: 137,
		part_id: 147,
		quantity: 10,
	},
	{
		garage_id: 137,
		part_id: 148,
		quantity: 26,
	},
	{
		garage_id: 137,
		part_id: 149,
		quantity: 403,
	},
	{
		garage_id: 137,
		part_id: 150,
		quantity: 341,
	},
	{
		garage_id: 137,
		part_id: 151,
		quantity: 204,
	},
	{
		garage_id: 137,
		part_id: 152,
		quantity: 124,
	},
	{
		garage_id: 137,
		part_id: 153,
		quantity: 422,
	},
	{
		garage_id: 137,
		part_id: 154,
		quantity: 394,
	},
	{
		garage_id: 137,
		part_id: 155,
		quantity: 167,
	},
	{
		garage_id: 137,
		part_id: 156,
		quantity: 428,
	},
	{
		garage_id: 137,
		part_id: 157,
		quantity: 332,
	},
	{
		garage_id: 137,
		part_id: 158,
		quantity: 424,
	},
	{
		garage_id: 137,
		part_id: 159,
		quantity: 83,
	},
	{
		garage_id: 137,
		part_id: 160,
		quantity: 151,
	},
	{
		garage_id: 137,
		part_id: 161,
		quantity: 156,
	},
	{
		garage_id: 137,
		part_id: 162,
		quantity: 261,
	},
	{
		garage_id: 137,
		part_id: 163,
		quantity: 76,
	},
	{
		garage_id: 137,
		part_id: 164,
		quantity: 371,
	},
	{
		garage_id: 137,
		part_id: 165,
		quantity: 498,
	},
	{
		garage_id: 137,
		part_id: 166,
		quantity: 412,
	},
	{
		garage_id: 137,
		part_id: 167,
		quantity: 335,
	},
	{
		garage_id: 137,
		part_id: 168,
		quantity: 324,
	},
	{
		garage_id: 137,
		part_id: 169,
		quantity: 247,
	},
	{
		garage_id: 137,
		part_id: 170,
		quantity: 406,
	},
	{
		garage_id: 137,
		part_id: 171,
		quantity: 117,
	},
	{
		garage_id: 137,
		part_id: 172,
		quantity: 402,
	},
	{
		garage_id: 137,
		part_id: 173,
		quantity: 239,
	},
	{
		garage_id: 137,
		part_id: 174,
		quantity: 63,
	},
	{
		garage_id: 137,
		part_id: 175,
		quantity: 0,
	},
	{
		garage_id: 137,
		part_id: 176,
		quantity: 442,
	},
	{
		garage_id: 137,
		part_id: 177,
		quantity: 457,
	},
	{
		garage_id: 137,
		part_id: 178,
		quantity: 183,
	},
	{
		garage_id: 137,
		part_id: 179,
		quantity: 413,
	},
	{
		garage_id: 137,
		part_id: 180,
		quantity: 364,
	},
	{
		garage_id: 137,
		part_id: 181,
		quantity: 399,
	},
	{
		garage_id: 137,
		part_id: 182,
		quantity: 420,
	},
	{
		garage_id: 137,
		part_id: 183,
		quantity: 75,
	},
	{
		garage_id: 137,
		part_id: 184,
		quantity: 286,
	},
	{
		garage_id: 137,
		part_id: 185,
		quantity: 463,
	},
	{
		garage_id: 137,
		part_id: 186,
		quantity: 125,
	},
	{
		garage_id: 137,
		part_id: 187,
		quantity: 238,
	},
	{
		garage_id: 137,
		part_id: 188,
		quantity: 152,
	},
	{
		garage_id: 137,
		part_id: 189,
		quantity: 237,
	},
	{
		garage_id: 137,
		part_id: 190,
		quantity: 191,
	},
	{
		garage_id: 137,
		part_id: 191,
		quantity: 60,
	},
	{
		garage_id: 137,
		part_id: 192,
		quantity: 461,
	},
	{
		garage_id: 137,
		part_id: 193,
		quantity: 254,
	},
	{
		garage_id: 137,
		part_id: 194,
		quantity: 499,
	},
	{
		garage_id: 137,
		part_id: 195,
		quantity: 366,
	},
	{
		garage_id: 137,
		part_id: 196,
		quantity: 216,
	},
	{
		garage_id: 137,
		part_id: 197,
		quantity: 371,
	},
	{
		garage_id: 137,
		part_id: 198,
		quantity: 307,
	},
	{
		garage_id: 137,
		part_id: 199,
		quantity: 153,
	},
	{
		garage_id: 137,
		part_id: 200,
		quantity: 49,
	},
	{
		garage_id: 137,
		part_id: 201,
		quantity: 238,
	},
	{
		garage_id: 137,
		part_id: 202,
		quantity: 7,
	},
	{
		garage_id: 137,
		part_id: 203,
		quantity: 93,
	},
	{
		garage_id: 137,
		part_id: 204,
		quantity: 481,
	},
	{
		garage_id: 137,
		part_id: 205,
		quantity: 497,
	},
	{
		garage_id: 137,
		part_id: 206,
		quantity: 399,
	},
	{
		garage_id: 137,
		part_id: 207,
		quantity: 44,
	},
	{
		garage_id: 137,
		part_id: 208,
		quantity: 333,
	},
	{
		garage_id: 137,
		part_id: 209,
		quantity: 344,
	},
	{
		garage_id: 137,
		part_id: 210,
		quantity: 193,
	},
	{
		garage_id: 137,
		part_id: 211,
		quantity: 172,
	},
	{
		garage_id: 137,
		part_id: 212,
		quantity: 169,
	},
	{
		garage_id: 137,
		part_id: 213,
		quantity: 366,
	},
	{
		garage_id: 137,
		part_id: 214,
		quantity: 135,
	},
	{
		garage_id: 137,
		part_id: 215,
		quantity: 231,
	},
	{
		garage_id: 137,
		part_id: 216,
		quantity: 101,
	},
	{
		garage_id: 137,
		part_id: 217,
		quantity: 143,
	},
	{
		garage_id: 137,
		part_id: 218,
		quantity: 180,
	},
	{
		garage_id: 137,
		part_id: 219,
		quantity: 414,
	},
	{
		garage_id: 137,
		part_id: 220,
		quantity: 408,
	},
	{
		garage_id: 137,
		part_id: 221,
		quantity: 162,
	},
	{
		garage_id: 137,
		part_id: 222,
		quantity: 364,
	},
	{
		garage_id: 137,
		part_id: 223,
		quantity: 84,
	},
	{
		garage_id: 137,
		part_id: 224,
		quantity: 282,
	},
	{
		garage_id: 137,
		part_id: 225,
		quantity: 458,
	},
	{
		garage_id: 137,
		part_id: 226,
		quantity: 171,
	},
	{
		garage_id: 137,
		part_id: 227,
		quantity: 322,
	},
	{
		garage_id: 137,
		part_id: 228,
		quantity: 488,
	},
	{
		garage_id: 137,
		part_id: 229,
		quantity: 102,
	},
	{
		garage_id: 137,
		part_id: 230,
		quantity: 418,
	},
	{
		garage_id: 137,
		part_id: 231,
		quantity: 474,
	},
	{
		garage_id: 137,
		part_id: 232,
		quantity: 111,
	},
	{
		garage_id: 137,
		part_id: 233,
		quantity: 498,
	},
	{
		garage_id: 137,
		part_id: 234,
		quantity: 416,
	},
	{
		garage_id: 137,
		part_id: 235,
		quantity: 201,
	},
	{
		garage_id: 137,
		part_id: 236,
		quantity: 302,
	},
	{
		garage_id: 137,
		part_id: 237,
		quantity: 484,
	},
	{
		garage_id: 137,
		part_id: 238,
		quantity: 433,
	},
	{
		garage_id: 137,
		part_id: 239,
		quantity: 488,
	},
	{
		garage_id: 137,
		part_id: 240,
		quantity: 441,
	},
	{
		garage_id: 137,
		part_id: 241,
		quantity: 97,
	},
	{
		garage_id: 137,
		part_id: 242,
		quantity: 410,
	},
	{
		garage_id: 137,
		part_id: 243,
		quantity: 339,
	},
	{
		garage_id: 137,
		part_id: 244,
		quantity: 131,
	},
	{
		garage_id: 137,
		part_id: 245,
		quantity: 348,
	},
	{
		garage_id: 137,
		part_id: 246,
		quantity: 48,
	},
	{
		garage_id: 137,
		part_id: 247,
		quantity: 261,
	},
	{
		garage_id: 137,
		part_id: 248,
		quantity: 148,
	},
	{
		garage_id: 137,
		part_id: 249,
		quantity: 243,
	},
	{
		garage_id: 137,
		part_id: 250,
		quantity: 450,
	},
	{
		garage_id: 137,
		part_id: 251,
		quantity: 0,
	},
	{
		garage_id: 137,
		part_id: 252,
		quantity: 210,
	},
	{
		garage_id: 137,
		part_id: 253,
		quantity: 210,
	},
	{
		garage_id: 137,
		part_id: 254,
		quantity: 104,
	},
	{
		garage_id: 137,
		part_id: 255,
		quantity: 158,
	},
	{
		garage_id: 137,
		part_id: 256,
		quantity: 297,
	},
	{
		garage_id: 137,
		part_id: 257,
		quantity: 277,
	},
	{
		garage_id: 137,
		part_id: 258,
		quantity: 139,
	},
	{
		garage_id: 137,
		part_id: 259,
		quantity: 275,
	},
	{
		garage_id: 137,
		part_id: 260,
		quantity: 177,
	},
	{
		garage_id: 137,
		part_id: 261,
		quantity: 42,
	},
	{
		garage_id: 137,
		part_id: 262,
		quantity: 432,
	},
	{
		garage_id: 137,
		part_id: 263,
		quantity: 223,
	},
	{
		garage_id: 137,
		part_id: 264,
		quantity: 108,
	},
	{
		garage_id: 137,
		part_id: 265,
		quantity: 274,
	},
	{
		garage_id: 137,
		part_id: 266,
		quantity: 488,
	},
	{
		garage_id: 137,
		part_id: 267,
		quantity: 10,
	},
	{
		garage_id: 137,
		part_id: 268,
		quantity: 139,
	},
	{
		garage_id: 137,
		part_id: 269,
		quantity: 12,
	},
	{
		garage_id: 137,
		part_id: 270,
		quantity: 229,
	},
	{
		garage_id: 137,
		part_id: 271,
		quantity: 498,
	},
	{
		garage_id: 137,
		part_id: 272,
		quantity: 338,
	},
	{
		garage_id: 137,
		part_id: 273,
		quantity: 398,
	},
	{
		garage_id: 137,
		part_id: 274,
		quantity: 323,
	},
	{
		garage_id: 137,
		part_id: 275,
		quantity: 16,
	},
	{
		garage_id: 137,
		part_id: 276,
		quantity: 228,
	},
	{
		garage_id: 137,
		part_id: 277,
		quantity: 356,
	},
	{
		garage_id: 137,
		part_id: 278,
		quantity: 386,
	},
	{
		garage_id: 137,
		part_id: 279,
		quantity: 387,
	},
	{
		garage_id: 137,
		part_id: 280,
		quantity: 396,
	},
	{
		garage_id: 137,
		part_id: 281,
		quantity: 322,
	},
	{
		garage_id: 137,
		part_id: 282,
		quantity: 295,
	},
	{
		garage_id: 137,
		part_id: 283,
		quantity: 123,
	},
	{
		garage_id: 137,
		part_id: 284,
		quantity: 212,
	},
	{
		garage_id: 137,
		part_id: 285,
		quantity: 290,
	},
	{
		garage_id: 137,
		part_id: 286,
		quantity: 184,
	},
	{
		garage_id: 137,
		part_id: 287,
		quantity: 83,
	},
	{
		garage_id: 137,
		part_id: 288,
		quantity: 412,
	},
	{
		garage_id: 137,
		part_id: 289,
		quantity: 139,
	},
	{
		garage_id: 137,
		part_id: 290,
		quantity: 73,
	},
	{
		garage_id: 137,
		part_id: 291,
		quantity: 156,
	},
	{
		garage_id: 137,
		part_id: 292,
		quantity: 435,
	},
	{
		garage_id: 137,
		part_id: 293,
		quantity: 48,
	},
	{
		garage_id: 137,
		part_id: 294,
		quantity: 367,
	},
	{
		garage_id: 137,
		part_id: 295,
		quantity: 52,
	},
	{
		garage_id: 137,
		part_id: 296,
		quantity: 126,
	},
	{
		garage_id: 137,
		part_id: 297,
		quantity: 57,
	},
	{
		garage_id: 137,
		part_id: 298,
		quantity: 205,
	},
	{
		garage_id: 137,
		part_id: 299,
		quantity: 367,
	},
	{
		garage_id: 137,
		part_id: 300,
		quantity: 463,
	},
	{
		garage_id: 137,
		part_id: 301,
		quantity: 34,
	},
	{
		garage_id: 137,
		part_id: 302,
		quantity: 324,
	},
	{
		garage_id: 137,
		part_id: 303,
		quantity: 181,
	},
	{
		garage_id: 137,
		part_id: 304,
		quantity: 441,
	},
	{
		garage_id: 137,
		part_id: 305,
		quantity: 93,
	},
	{
		garage_id: 137,
		part_id: 306,
		quantity: 307,
	},
	{
		garage_id: 137,
		part_id: 307,
		quantity: 315,
	},
	{
		garage_id: 137,
		part_id: 308,
		quantity: 280,
	},
	{
		garage_id: 137,
		part_id: 309,
		quantity: 127,
	},
	{
		garage_id: 137,
		part_id: 310,
		quantity: 198,
	},
	{
		garage_id: 137,
		part_id: 311,
		quantity: 85,
	},
	{
		garage_id: 137,
		part_id: 312,
		quantity: 476,
	},
	{
		garage_id: 137,
		part_id: 313,
		quantity: 410,
	},
	{
		garage_id: 137,
		part_id: 314,
		quantity: 174,
	},
	{
		garage_id: 137,
		part_id: 315,
		quantity: 379,
	},
	{
		garage_id: 137,
		part_id: 316,
		quantity: 9,
	},
	{
		garage_id: 137,
		part_id: 317,
		quantity: 211,
	},
	{
		garage_id: 137,
		part_id: 318,
		quantity: 241,
	},
	{
		garage_id: 137,
		part_id: 319,
		quantity: 223,
	},
	{
		garage_id: 137,
		part_id: 320,
		quantity: 166,
	},
	{
		garage_id: 137,
		part_id: 321,
		quantity: 190,
	},
	{
		garage_id: 137,
		part_id: 322,
		quantity: 26,
	},
	{
		garage_id: 137,
		part_id: 323,
		quantity: 104,
	},
	{
		garage_id: 137,
		part_id: 324,
		quantity: 456,
	},
	{
		garage_id: 137,
		part_id: 325,
		quantity: 422,
	},
	{
		garage_id: 137,
		part_id: 326,
		quantity: 121,
	},
	{
		garage_id: 137,
		part_id: 327,
		quantity: 144,
	},
	{
		garage_id: 137,
		part_id: 328,
		quantity: 208,
	},
	{
		garage_id: 137,
		part_id: 329,
		quantity: 344,
	},
	{
		garage_id: 137,
		part_id: 330,
		quantity: 464,
	},
	{
		garage_id: 137,
		part_id: 331,
		quantity: 319,
	},
	{
		garage_id: 137,
		part_id: 332,
		quantity: 449,
	},
	{
		garage_id: 137,
		part_id: 333,
		quantity: 130,
	},
	{
		garage_id: 137,
		part_id: 334,
		quantity: 476,
	},
	{
		garage_id: 137,
		part_id: 335,
		quantity: 266,
	},
	{
		garage_id: 137,
		part_id: 336,
		quantity: 60,
	},
	{
		garage_id: 137,
		part_id: 337,
		quantity: 284,
	},
	{
		garage_id: 137,
		part_id: 338,
		quantity: 105,
	},
	{
		garage_id: 137,
		part_id: 339,
		quantity: 361,
	},
	{
		garage_id: 137,
		part_id: 340,
		quantity: 473,
	},
	{
		garage_id: 137,
		part_id: 341,
		quantity: 407,
	},
	{
		garage_id: 137,
		part_id: 342,
		quantity: 348,
	},
	{
		garage_id: 137,
		part_id: 343,
		quantity: 419,
	},
	{
		garage_id: 137,
		part_id: 344,
		quantity: 161,
	},
	{
		garage_id: 137,
		part_id: 345,
		quantity: 69,
	},
	{
		garage_id: 137,
		part_id: 346,
		quantity: 469,
	},
	{
		garage_id: 137,
		part_id: 347,
		quantity: 200,
	},
	{
		garage_id: 137,
		part_id: 348,
		quantity: 327,
	},
	{
		garage_id: 137,
		part_id: 349,
		quantity: 254,
	},
	{
		garage_id: 137,
		part_id: 350,
		quantity: 370,
	},
	{
		garage_id: 137,
		part_id: 351,
		quantity: 268,
	},
	{
		garage_id: 137,
		part_id: 352,
		quantity: 393,
	},
	{
		garage_id: 137,
		part_id: 353,
		quantity: 430,
	},
	{
		garage_id: 137,
		part_id: 354,
		quantity: 489,
	},
	{
		garage_id: 137,
		part_id: 355,
		quantity: 18,
	},
	{
		garage_id: 137,
		part_id: 356,
		quantity: 482,
	},
	{
		garage_id: 137,
		part_id: 357,
		quantity: 355,
	},
	{
		garage_id: 137,
		part_id: 358,
		quantity: 4,
	},
	{
		garage_id: 137,
		part_id: 359,
		quantity: 77,
	},
	{
		garage_id: 137,
		part_id: 360,
		quantity: 11,
	},
	{
		garage_id: 137,
		part_id: 361,
		quantity: 221,
	},
	{
		garage_id: 137,
		part_id: 362,
		quantity: 2,
	},
	{
		garage_id: 137,
		part_id: 363,
		quantity: 70,
	},
	{
		garage_id: 137,
		part_id: 364,
		quantity: 299,
	},
	{
		garage_id: 137,
		part_id: 365,
		quantity: 8,
	},
	{
		garage_id: 137,
		part_id: 366,
		quantity: 412,
	},
	{
		garage_id: 137,
		part_id: 367,
		quantity: 47,
	},
	{
		garage_id: 137,
		part_id: 368,
		quantity: 165,
	},
	{
		garage_id: 137,
		part_id: 369,
		quantity: 275,
	},
	{
		garage_id: 137,
		part_id: 370,
		quantity: 341,
	},
	{
		garage_id: 137,
		part_id: 371,
		quantity: 270,
	},
	{
		garage_id: 137,
		part_id: 372,
		quantity: 78,
	},
	{
		garage_id: 137,
		part_id: 373,
		quantity: 298,
	},
	{
		garage_id: 137,
		part_id: 374,
		quantity: 405,
	},
	{
		garage_id: 137,
		part_id: 375,
		quantity: 351,
	},
	{
		garage_id: 137,
		part_id: 376,
		quantity: 454,
	},
	{
		garage_id: 137,
		part_id: 377,
		quantity: 5,
	},
	{
		garage_id: 137,
		part_id: 378,
		quantity: 117,
	},
	{
		garage_id: 137,
		part_id: 379,
		quantity: 477,
	},
	{
		garage_id: 137,
		part_id: 380,
		quantity: 493,
	},
	{
		garage_id: 137,
		part_id: 381,
		quantity: 482,
	},
	{
		garage_id: 137,
		part_id: 382,
		quantity: 496,
	},
	{
		garage_id: 137,
		part_id: 383,
		quantity: 252,
	},
	{
		garage_id: 137,
		part_id: 384,
		quantity: 476,
	},
	{
		garage_id: 137,
		part_id: 385,
		quantity: 157,
	},
	{
		garage_id: 137,
		part_id: 386,
		quantity: 306,
	},
	{
		garage_id: 137,
		part_id: 387,
		quantity: 134,
	},
	{
		garage_id: 137,
		part_id: 388,
		quantity: 26,
	},
	{
		garage_id: 137,
		part_id: 389,
		quantity: 347,
	},
	{
		garage_id: 137,
		part_id: 390,
		quantity: 441,
	},
	{
		garage_id: 137,
		part_id: 391,
		quantity: 30,
	},
	{
		garage_id: 137,
		part_id: 392,
		quantity: 285,
	},
	{
		garage_id: 137,
		part_id: 393,
		quantity: 403,
	},
	{
		garage_id: 137,
		part_id: 394,
		quantity: 91,
	},
	{
		garage_id: 137,
		part_id: 395,
		quantity: 47,
	},
	{
		garage_id: 137,
		part_id: 396,
		quantity: 439,
	},
	{
		garage_id: 137,
		part_id: 397,
		quantity: 178,
	},
	{
		garage_id: 137,
		part_id: 398,
		quantity: 456,
	},
	{
		garage_id: 137,
		part_id: 399,
		quantity: 53,
	},
	{
		garage_id: 138,
		part_id: 0,
		quantity: 86,
	},
	{
		garage_id: 138,
		part_id: 1,
		quantity: 403,
	},
	{
		garage_id: 138,
		part_id: 2,
		quantity: 441,
	},
	{
		garage_id: 138,
		part_id: 3,
		quantity: 459,
	},
	{
		garage_id: 138,
		part_id: 4,
		quantity: 432,
	},
	{
		garage_id: 138,
		part_id: 5,
		quantity: 254,
	},
	{
		garage_id: 138,
		part_id: 6,
		quantity: 74,
	},
	{
		garage_id: 138,
		part_id: 7,
		quantity: 188,
	},
	{
		garage_id: 138,
		part_id: 8,
		quantity: 72,
	},
	{
		garage_id: 138,
		part_id: 9,
		quantity: 178,
	},
	{
		garage_id: 138,
		part_id: 10,
		quantity: 121,
	},
	{
		garage_id: 138,
		part_id: 11,
		quantity: 148,
	},
	{
		garage_id: 138,
		part_id: 12,
		quantity: 330,
	},
	{
		garage_id: 138,
		part_id: 13,
		quantity: 215,
	},
	{
		garage_id: 138,
		part_id: 14,
		quantity: 252,
	},
	{
		garage_id: 138,
		part_id: 15,
		quantity: 105,
	},
	{
		garage_id: 138,
		part_id: 16,
		quantity: 367,
	},
	{
		garage_id: 138,
		part_id: 17,
		quantity: 121,
	},
	{
		garage_id: 138,
		part_id: 18,
		quantity: 284,
	},
	{
		garage_id: 138,
		part_id: 19,
		quantity: 415,
	},
	{
		garage_id: 138,
		part_id: 20,
		quantity: 144,
	},
	{
		garage_id: 138,
		part_id: 21,
		quantity: 446,
	},
	{
		garage_id: 138,
		part_id: 22,
		quantity: 499,
	},
	{
		garage_id: 138,
		part_id: 23,
		quantity: 164,
	},
	{
		garage_id: 138,
		part_id: 24,
		quantity: 174,
	},
	{
		garage_id: 138,
		part_id: 25,
		quantity: 420,
	},
	{
		garage_id: 138,
		part_id: 26,
		quantity: 297,
	},
	{
		garage_id: 138,
		part_id: 27,
		quantity: 13,
	},
	{
		garage_id: 138,
		part_id: 28,
		quantity: 163,
	},
	{
		garage_id: 138,
		part_id: 29,
		quantity: 259,
	},
	{
		garage_id: 138,
		part_id: 30,
		quantity: 85,
	},
	{
		garage_id: 138,
		part_id: 31,
		quantity: 294,
	},
	{
		garage_id: 138,
		part_id: 32,
		quantity: 85,
	},
	{
		garage_id: 138,
		part_id: 33,
		quantity: 159,
	},
	{
		garage_id: 138,
		part_id: 34,
		quantity: 44,
	},
	{
		garage_id: 138,
		part_id: 35,
		quantity: 370,
	},
	{
		garage_id: 138,
		part_id: 36,
		quantity: 253,
	},
	{
		garage_id: 138,
		part_id: 37,
		quantity: 407,
	},
	{
		garage_id: 138,
		part_id: 38,
		quantity: 483,
	},
	{
		garage_id: 138,
		part_id: 39,
		quantity: 223,
	},
	{
		garage_id: 138,
		part_id: 40,
		quantity: 326,
	},
	{
		garage_id: 138,
		part_id: 41,
		quantity: 141,
	},
	{
		garage_id: 138,
		part_id: 42,
		quantity: 136,
	},
	{
		garage_id: 138,
		part_id: 43,
		quantity: 74,
	},
	{
		garage_id: 138,
		part_id: 44,
		quantity: 353,
	},
	{
		garage_id: 138,
		part_id: 45,
		quantity: 155,
	},
	{
		garage_id: 138,
		part_id: 46,
		quantity: 387,
	},
	{
		garage_id: 138,
		part_id: 47,
		quantity: 129,
	},
	{
		garage_id: 138,
		part_id: 48,
		quantity: 168,
	},
	{
		garage_id: 138,
		part_id: 49,
		quantity: 412,
	},
	{
		garage_id: 138,
		part_id: 50,
		quantity: 177,
	},
	{
		garage_id: 138,
		part_id: 51,
		quantity: 300,
	},
	{
		garage_id: 138,
		part_id: 52,
		quantity: 59,
	},
	{
		garage_id: 138,
		part_id: 53,
		quantity: 6,
	},
	{
		garage_id: 138,
		part_id: 54,
		quantity: 98,
	},
	{
		garage_id: 138,
		part_id: 55,
		quantity: 161,
	},
	{
		garage_id: 138,
		part_id: 56,
		quantity: 273,
	},
	{
		garage_id: 138,
		part_id: 57,
		quantity: 261,
	},
	{
		garage_id: 138,
		part_id: 58,
		quantity: 223,
	},
	{
		garage_id: 138,
		part_id: 59,
		quantity: 127,
	},
	{
		garage_id: 138,
		part_id: 60,
		quantity: 32,
	},
	{
		garage_id: 138,
		part_id: 61,
		quantity: 191,
	},
	{
		garage_id: 138,
		part_id: 62,
		quantity: 27,
	},
	{
		garage_id: 138,
		part_id: 63,
		quantity: 198,
	},
	{
		garage_id: 138,
		part_id: 64,
		quantity: 73,
	},
	{
		garage_id: 138,
		part_id: 65,
		quantity: 222,
	},
	{
		garage_id: 138,
		part_id: 66,
		quantity: 209,
	},
	{
		garage_id: 138,
		part_id: 67,
		quantity: 464,
	},
	{
		garage_id: 138,
		part_id: 68,
		quantity: 194,
	},
	{
		garage_id: 138,
		part_id: 69,
		quantity: 323,
	},
	{
		garage_id: 138,
		part_id: 70,
		quantity: 104,
	},
	{
		garage_id: 138,
		part_id: 71,
		quantity: 269,
	},
	{
		garage_id: 138,
		part_id: 72,
		quantity: 460,
	},
	{
		garage_id: 138,
		part_id: 73,
		quantity: 412,
	},
	{
		garage_id: 138,
		part_id: 74,
		quantity: 77,
	},
	{
		garage_id: 138,
		part_id: 75,
		quantity: 367,
	},
	{
		garage_id: 138,
		part_id: 76,
		quantity: 198,
	},
	{
		garage_id: 138,
		part_id: 77,
		quantity: 61,
	},
	{
		garage_id: 138,
		part_id: 78,
		quantity: 58,
	},
	{
		garage_id: 138,
		part_id: 79,
		quantity: 237,
	},
	{
		garage_id: 138,
		part_id: 80,
		quantity: 415,
	},
	{
		garage_id: 138,
		part_id: 81,
		quantity: 466,
	},
	{
		garage_id: 138,
		part_id: 82,
		quantity: 337,
	},
	{
		garage_id: 138,
		part_id: 83,
		quantity: 384,
	},
	{
		garage_id: 138,
		part_id: 84,
		quantity: 66,
	},
	{
		garage_id: 138,
		part_id: 85,
		quantity: 367,
	},
	{
		garage_id: 138,
		part_id: 86,
		quantity: 472,
	},
	{
		garage_id: 138,
		part_id: 87,
		quantity: 376,
	},
	{
		garage_id: 138,
		part_id: 88,
		quantity: 456,
	},
	{
		garage_id: 138,
		part_id: 89,
		quantity: 70,
	},
	{
		garage_id: 138,
		part_id: 90,
		quantity: 33,
	},
	{
		garage_id: 138,
		part_id: 91,
		quantity: 253,
	},
	{
		garage_id: 138,
		part_id: 92,
		quantity: 283,
	},
	{
		garage_id: 138,
		part_id: 93,
		quantity: 348,
	},
	{
		garage_id: 138,
		part_id: 94,
		quantity: 194,
	},
	{
		garage_id: 138,
		part_id: 95,
		quantity: 330,
	},
	{
		garage_id: 138,
		part_id: 96,
		quantity: 480,
	},
	{
		garage_id: 138,
		part_id: 97,
		quantity: 487,
	},
	{
		garage_id: 138,
		part_id: 98,
		quantity: 449,
	},
	{
		garage_id: 138,
		part_id: 99,
		quantity: 124,
	},
	{
		garage_id: 138,
		part_id: 100,
		quantity: 59,
	},
	{
		garage_id: 138,
		part_id: 101,
		quantity: 56,
	},
	{
		garage_id: 138,
		part_id: 102,
		quantity: 119,
	},
	{
		garage_id: 138,
		part_id: 103,
		quantity: 338,
	},
	{
		garage_id: 138,
		part_id: 104,
		quantity: 377,
	},
	{
		garage_id: 138,
		part_id: 105,
		quantity: 24,
	},
	{
		garage_id: 138,
		part_id: 106,
		quantity: 33,
	},
	{
		garage_id: 138,
		part_id: 107,
		quantity: 407,
	},
	{
		garage_id: 138,
		part_id: 108,
		quantity: 59,
	},
	{
		garage_id: 138,
		part_id: 109,
		quantity: 70,
	},
	{
		garage_id: 138,
		part_id: 110,
		quantity: 222,
	},
	{
		garage_id: 138,
		part_id: 111,
		quantity: 25,
	},
	{
		garage_id: 138,
		part_id: 112,
		quantity: 96,
	},
	{
		garage_id: 138,
		part_id: 113,
		quantity: 93,
	},
	{
		garage_id: 138,
		part_id: 114,
		quantity: 492,
	},
	{
		garage_id: 138,
		part_id: 115,
		quantity: 89,
	},
	{
		garage_id: 138,
		part_id: 116,
		quantity: 18,
	},
	{
		garage_id: 138,
		part_id: 117,
		quantity: 203,
	},
	{
		garage_id: 138,
		part_id: 118,
		quantity: 103,
	},
	{
		garage_id: 138,
		part_id: 119,
		quantity: 490,
	},
	{
		garage_id: 138,
		part_id: 120,
		quantity: 275,
	},
	{
		garage_id: 138,
		part_id: 121,
		quantity: 447,
	},
	{
		garage_id: 138,
		part_id: 122,
		quantity: 233,
	},
	{
		garage_id: 138,
		part_id: 123,
		quantity: 379,
	},
	{
		garage_id: 138,
		part_id: 124,
		quantity: 379,
	},
	{
		garage_id: 138,
		part_id: 125,
		quantity: 337,
	},
	{
		garage_id: 138,
		part_id: 126,
		quantity: 378,
	},
	{
		garage_id: 138,
		part_id: 127,
		quantity: 321,
	},
	{
		garage_id: 138,
		part_id: 128,
		quantity: 381,
	},
	{
		garage_id: 138,
		part_id: 129,
		quantity: 460,
	},
	{
		garage_id: 138,
		part_id: 130,
		quantity: 111,
	},
	{
		garage_id: 138,
		part_id: 131,
		quantity: 249,
	},
	{
		garage_id: 138,
		part_id: 132,
		quantity: 71,
	},
	{
		garage_id: 138,
		part_id: 133,
		quantity: 27,
	},
	{
		garage_id: 138,
		part_id: 134,
		quantity: 180,
	},
	{
		garage_id: 138,
		part_id: 135,
		quantity: 466,
	},
	{
		garage_id: 138,
		part_id: 136,
		quantity: 212,
	},
	{
		garage_id: 138,
		part_id: 137,
		quantity: 377,
	},
	{
		garage_id: 138,
		part_id: 138,
		quantity: 152,
	},
	{
		garage_id: 138,
		part_id: 139,
		quantity: 346,
	},
	{
		garage_id: 138,
		part_id: 140,
		quantity: 255,
	},
	{
		garage_id: 138,
		part_id: 141,
		quantity: 182,
	},
	{
		garage_id: 138,
		part_id: 142,
		quantity: 302,
	},
	{
		garage_id: 138,
		part_id: 143,
		quantity: 462,
	},
	{
		garage_id: 138,
		part_id: 144,
		quantity: 91,
	},
	{
		garage_id: 138,
		part_id: 145,
		quantity: 100,
	},
	{
		garage_id: 138,
		part_id: 146,
		quantity: 368,
	},
	{
		garage_id: 138,
		part_id: 147,
		quantity: 389,
	},
	{
		garage_id: 138,
		part_id: 148,
		quantity: 50,
	},
	{
		garage_id: 138,
		part_id: 149,
		quantity: 313,
	},
	{
		garage_id: 138,
		part_id: 150,
		quantity: 217,
	},
	{
		garage_id: 138,
		part_id: 151,
		quantity: 469,
	},
	{
		garage_id: 138,
		part_id: 152,
		quantity: 36,
	},
	{
		garage_id: 138,
		part_id: 153,
		quantity: 4,
	},
	{
		garage_id: 138,
		part_id: 154,
		quantity: 327,
	},
	{
		garage_id: 138,
		part_id: 155,
		quantity: 235,
	},
	{
		garage_id: 138,
		part_id: 156,
		quantity: 214,
	},
	{
		garage_id: 138,
		part_id: 157,
		quantity: 484,
	},
	{
		garage_id: 138,
		part_id: 158,
		quantity: 418,
	},
	{
		garage_id: 138,
		part_id: 159,
		quantity: 402,
	},
	{
		garage_id: 138,
		part_id: 160,
		quantity: 463,
	},
	{
		garage_id: 138,
		part_id: 161,
		quantity: 281,
	},
	{
		garage_id: 138,
		part_id: 162,
		quantity: 7,
	},
	{
		garage_id: 138,
		part_id: 163,
		quantity: 390,
	},
	{
		garage_id: 138,
		part_id: 164,
		quantity: 362,
	},
	{
		garage_id: 138,
		part_id: 165,
		quantity: 46,
	},
	{
		garage_id: 138,
		part_id: 166,
		quantity: 71,
	},
	{
		garage_id: 138,
		part_id: 167,
		quantity: 96,
	},
	{
		garage_id: 138,
		part_id: 168,
		quantity: 172,
	},
	{
		garage_id: 138,
		part_id: 169,
		quantity: 177,
	},
	{
		garage_id: 138,
		part_id: 170,
		quantity: 309,
	},
	{
		garage_id: 138,
		part_id: 171,
		quantity: 88,
	},
	{
		garage_id: 138,
		part_id: 172,
		quantity: 214,
	},
	{
		garage_id: 138,
		part_id: 173,
		quantity: 17,
	},
	{
		garage_id: 138,
		part_id: 174,
		quantity: 161,
	},
	{
		garage_id: 138,
		part_id: 175,
		quantity: 462,
	},
	{
		garage_id: 138,
		part_id: 176,
		quantity: 49,
	},
	{
		garage_id: 138,
		part_id: 177,
		quantity: 57,
	},
	{
		garage_id: 138,
		part_id: 178,
		quantity: 114,
	},
	{
		garage_id: 138,
		part_id: 179,
		quantity: 244,
	},
	{
		garage_id: 138,
		part_id: 180,
		quantity: 119,
	},
	{
		garage_id: 138,
		part_id: 181,
		quantity: 33,
	},
	{
		garage_id: 138,
		part_id: 182,
		quantity: 403,
	},
	{
		garage_id: 138,
		part_id: 183,
		quantity: 239,
	},
	{
		garage_id: 138,
		part_id: 184,
		quantity: 224,
	},
	{
		garage_id: 138,
		part_id: 185,
		quantity: 19,
	},
	{
		garage_id: 138,
		part_id: 186,
		quantity: 34,
	},
	{
		garage_id: 138,
		part_id: 187,
		quantity: 161,
	},
	{
		garage_id: 138,
		part_id: 188,
		quantity: 237,
	},
	{
		garage_id: 138,
		part_id: 189,
		quantity: 245,
	},
	{
		garage_id: 138,
		part_id: 190,
		quantity: 416,
	},
	{
		garage_id: 138,
		part_id: 191,
		quantity: 67,
	},
	{
		garage_id: 138,
		part_id: 192,
		quantity: 326,
	},
	{
		garage_id: 138,
		part_id: 193,
		quantity: 276,
	},
	{
		garage_id: 138,
		part_id: 194,
		quantity: 375,
	},
	{
		garage_id: 138,
		part_id: 195,
		quantity: 370,
	},
	{
		garage_id: 138,
		part_id: 196,
		quantity: 422,
	},
	{
		garage_id: 138,
		part_id: 197,
		quantity: 88,
	},
	{
		garage_id: 138,
		part_id: 198,
		quantity: 242,
	},
	{
		garage_id: 138,
		part_id: 199,
		quantity: 76,
	},
	{
		garage_id: 138,
		part_id: 200,
		quantity: 50,
	},
	{
		garage_id: 138,
		part_id: 201,
		quantity: 8,
	},
	{
		garage_id: 138,
		part_id: 202,
		quantity: 374,
	},
	{
		garage_id: 138,
		part_id: 203,
		quantity: 391,
	},
	{
		garage_id: 138,
		part_id: 204,
		quantity: 157,
	},
	{
		garage_id: 138,
		part_id: 205,
		quantity: 441,
	},
	{
		garage_id: 138,
		part_id: 206,
		quantity: 413,
	},
	{
		garage_id: 138,
		part_id: 207,
		quantity: 497,
	},
	{
		garage_id: 138,
		part_id: 208,
		quantity: 294,
	},
	{
		garage_id: 138,
		part_id: 209,
		quantity: 86,
	},
	{
		garage_id: 138,
		part_id: 210,
		quantity: 29,
	},
	{
		garage_id: 138,
		part_id: 211,
		quantity: 272,
	},
	{
		garage_id: 138,
		part_id: 212,
		quantity: 312,
	},
	{
		garage_id: 138,
		part_id: 213,
		quantity: 269,
	},
	{
		garage_id: 138,
		part_id: 214,
		quantity: 219,
	},
	{
		garage_id: 138,
		part_id: 215,
		quantity: 36,
	},
	{
		garage_id: 138,
		part_id: 216,
		quantity: 174,
	},
	{
		garage_id: 138,
		part_id: 217,
		quantity: 310,
	},
	{
		garage_id: 138,
		part_id: 218,
		quantity: 165,
	},
	{
		garage_id: 138,
		part_id: 219,
		quantity: 416,
	},
	{
		garage_id: 138,
		part_id: 220,
		quantity: 393,
	},
	{
		garage_id: 138,
		part_id: 221,
		quantity: 46,
	},
	{
		garage_id: 138,
		part_id: 222,
		quantity: 230,
	},
	{
		garage_id: 138,
		part_id: 223,
		quantity: 39,
	},
	{
		garage_id: 138,
		part_id: 224,
		quantity: 351,
	},
	{
		garage_id: 138,
		part_id: 225,
		quantity: 250,
	},
	{
		garage_id: 138,
		part_id: 226,
		quantity: 367,
	},
	{
		garage_id: 138,
		part_id: 227,
		quantity: 226,
	},
	{
		garage_id: 138,
		part_id: 228,
		quantity: 478,
	},
	{
		garage_id: 138,
		part_id: 229,
		quantity: 63,
	},
	{
		garage_id: 138,
		part_id: 230,
		quantity: 346,
	},
	{
		garage_id: 138,
		part_id: 231,
		quantity: 430,
	},
	{
		garage_id: 138,
		part_id: 232,
		quantity: 256,
	},
	{
		garage_id: 138,
		part_id: 233,
		quantity: 43,
	},
	{
		garage_id: 138,
		part_id: 234,
		quantity: 320,
	},
	{
		garage_id: 138,
		part_id: 235,
		quantity: 220,
	},
	{
		garage_id: 138,
		part_id: 236,
		quantity: 155,
	},
	{
		garage_id: 138,
		part_id: 237,
		quantity: 391,
	},
	{
		garage_id: 138,
		part_id: 238,
		quantity: 123,
	},
	{
		garage_id: 138,
		part_id: 239,
		quantity: 172,
	},
	{
		garage_id: 138,
		part_id: 240,
		quantity: 286,
	},
	{
		garage_id: 138,
		part_id: 241,
		quantity: 458,
	},
	{
		garage_id: 138,
		part_id: 242,
		quantity: 285,
	},
	{
		garage_id: 138,
		part_id: 243,
		quantity: 483,
	},
	{
		garage_id: 138,
		part_id: 244,
		quantity: 303,
	},
	{
		garage_id: 138,
		part_id: 245,
		quantity: 50,
	},
	{
		garage_id: 138,
		part_id: 246,
		quantity: 423,
	},
	{
		garage_id: 138,
		part_id: 247,
		quantity: 475,
	},
	{
		garage_id: 138,
		part_id: 248,
		quantity: 57,
	},
	{
		garage_id: 138,
		part_id: 249,
		quantity: 252,
	},
	{
		garage_id: 138,
		part_id: 250,
		quantity: 163,
	},
	{
		garage_id: 138,
		part_id: 251,
		quantity: 324,
	},
	{
		garage_id: 138,
		part_id: 252,
		quantity: 477,
	},
	{
		garage_id: 138,
		part_id: 253,
		quantity: 468,
	},
	{
		garage_id: 138,
		part_id: 254,
		quantity: 405,
	},
	{
		garage_id: 138,
		part_id: 255,
		quantity: 263,
	},
	{
		garage_id: 138,
		part_id: 256,
		quantity: 23,
	},
	{
		garage_id: 138,
		part_id: 257,
		quantity: 93,
	},
	{
		garage_id: 138,
		part_id: 258,
		quantity: 400,
	},
	{
		garage_id: 138,
		part_id: 259,
		quantity: 232,
	},
	{
		garage_id: 138,
		part_id: 260,
		quantity: 463,
	},
	{
		garage_id: 138,
		part_id: 261,
		quantity: 157,
	},
	{
		garage_id: 138,
		part_id: 262,
		quantity: 437,
	},
	{
		garage_id: 138,
		part_id: 263,
		quantity: 134,
	},
	{
		garage_id: 138,
		part_id: 264,
		quantity: 208,
	},
	{
		garage_id: 138,
		part_id: 265,
		quantity: 482,
	},
	{
		garage_id: 138,
		part_id: 266,
		quantity: 280,
	},
	{
		garage_id: 138,
		part_id: 267,
		quantity: 277,
	},
	{
		garage_id: 138,
		part_id: 268,
		quantity: 497,
	},
	{
		garage_id: 138,
		part_id: 269,
		quantity: 76,
	},
	{
		garage_id: 138,
		part_id: 270,
		quantity: 19,
	},
	{
		garage_id: 138,
		part_id: 271,
		quantity: 354,
	},
	{
		garage_id: 138,
		part_id: 272,
		quantity: 478,
	},
	{
		garage_id: 138,
		part_id: 273,
		quantity: 166,
	},
	{
		garage_id: 138,
		part_id: 274,
		quantity: 297,
	},
	{
		garage_id: 138,
		part_id: 275,
		quantity: 31,
	},
	{
		garage_id: 138,
		part_id: 276,
		quantity: 254,
	},
	{
		garage_id: 138,
		part_id: 277,
		quantity: 285,
	},
	{
		garage_id: 138,
		part_id: 278,
		quantity: 423,
	},
	{
		garage_id: 138,
		part_id: 279,
		quantity: 105,
	},
	{
		garage_id: 138,
		part_id: 280,
		quantity: 361,
	},
	{
		garage_id: 138,
		part_id: 281,
		quantity: 88,
	},
	{
		garage_id: 138,
		part_id: 282,
		quantity: 323,
	},
	{
		garage_id: 138,
		part_id: 283,
		quantity: 317,
	},
	{
		garage_id: 138,
		part_id: 284,
		quantity: 291,
	},
	{
		garage_id: 138,
		part_id: 285,
		quantity: 299,
	},
	{
		garage_id: 138,
		part_id: 286,
		quantity: 150,
	},
	{
		garage_id: 138,
		part_id: 287,
		quantity: 117,
	},
	{
		garage_id: 138,
		part_id: 288,
		quantity: 439,
	},
	{
		garage_id: 138,
		part_id: 289,
		quantity: 141,
	},
	{
		garage_id: 138,
		part_id: 290,
		quantity: 317,
	},
	{
		garage_id: 138,
		part_id: 291,
		quantity: 381,
	},
	{
		garage_id: 138,
		part_id: 292,
		quantity: 216,
	},
	{
		garage_id: 138,
		part_id: 293,
		quantity: 365,
	},
	{
		garage_id: 138,
		part_id: 294,
		quantity: 173,
	},
	{
		garage_id: 138,
		part_id: 295,
		quantity: 36,
	},
	{
		garage_id: 138,
		part_id: 296,
		quantity: 170,
	},
	{
		garage_id: 138,
		part_id: 297,
		quantity: 271,
	},
	{
		garage_id: 138,
		part_id: 298,
		quantity: 95,
	},
	{
		garage_id: 138,
		part_id: 299,
		quantity: 106,
	},
	{
		garage_id: 138,
		part_id: 300,
		quantity: 231,
	},
	{
		garage_id: 138,
		part_id: 301,
		quantity: 71,
	},
	{
		garage_id: 138,
		part_id: 302,
		quantity: 225,
	},
	{
		garage_id: 138,
		part_id: 303,
		quantity: 55,
	},
	{
		garage_id: 138,
		part_id: 304,
		quantity: 109,
	},
	{
		garage_id: 138,
		part_id: 305,
		quantity: 87,
	},
	{
		garage_id: 138,
		part_id: 306,
		quantity: 326,
	},
	{
		garage_id: 138,
		part_id: 307,
		quantity: 225,
	},
	{
		garage_id: 138,
		part_id: 308,
		quantity: 406,
	},
	{
		garage_id: 138,
		part_id: 309,
		quantity: 491,
	},
	{
		garage_id: 138,
		part_id: 310,
		quantity: 443,
	},
	{
		garage_id: 138,
		part_id: 311,
		quantity: 46,
	},
	{
		garage_id: 138,
		part_id: 312,
		quantity: 130,
	},
	{
		garage_id: 138,
		part_id: 313,
		quantity: 491,
	},
	{
		garage_id: 138,
		part_id: 314,
		quantity: 225,
	},
	{
		garage_id: 138,
		part_id: 315,
		quantity: 229,
	},
	{
		garage_id: 138,
		part_id: 316,
		quantity: 269,
	},
	{
		garage_id: 138,
		part_id: 317,
		quantity: 381,
	},
	{
		garage_id: 138,
		part_id: 318,
		quantity: 239,
	},
	{
		garage_id: 138,
		part_id: 319,
		quantity: 240,
	},
	{
		garage_id: 138,
		part_id: 320,
		quantity: 60,
	},
	{
		garage_id: 138,
		part_id: 321,
		quantity: 29,
	},
	{
		garage_id: 138,
		part_id: 322,
		quantity: 416,
	},
	{
		garage_id: 138,
		part_id: 323,
		quantity: 123,
	},
	{
		garage_id: 138,
		part_id: 324,
		quantity: 238,
	},
	{
		garage_id: 138,
		part_id: 325,
		quantity: 163,
	},
	{
		garage_id: 138,
		part_id: 326,
		quantity: 347,
	},
	{
		garage_id: 138,
		part_id: 327,
		quantity: 36,
	},
	{
		garage_id: 138,
		part_id: 328,
		quantity: 455,
	},
	{
		garage_id: 138,
		part_id: 329,
		quantity: 464,
	},
	{
		garage_id: 138,
		part_id: 330,
		quantity: 333,
	},
	{
		garage_id: 138,
		part_id: 331,
		quantity: 344,
	},
	{
		garage_id: 138,
		part_id: 332,
		quantity: 443,
	},
	{
		garage_id: 138,
		part_id: 333,
		quantity: 395,
	},
	{
		garage_id: 138,
		part_id: 334,
		quantity: 214,
	},
	{
		garage_id: 138,
		part_id: 335,
		quantity: 385,
	},
	{
		garage_id: 138,
		part_id: 336,
		quantity: 309,
	},
	{
		garage_id: 138,
		part_id: 337,
		quantity: 319,
	},
	{
		garage_id: 138,
		part_id: 338,
		quantity: 221,
	},
	{
		garage_id: 138,
		part_id: 339,
		quantity: 450,
	},
	{
		garage_id: 138,
		part_id: 340,
		quantity: 430,
	},
	{
		garage_id: 138,
		part_id: 341,
		quantity: 378,
	},
	{
		garage_id: 138,
		part_id: 342,
		quantity: 338,
	},
	{
		garage_id: 138,
		part_id: 343,
		quantity: 500,
	},
	{
		garage_id: 138,
		part_id: 344,
		quantity: 360,
	},
	{
		garage_id: 138,
		part_id: 345,
		quantity: 51,
	},
	{
		garage_id: 138,
		part_id: 346,
		quantity: 291,
	},
	{
		garage_id: 138,
		part_id: 347,
		quantity: 118,
	},
	{
		garage_id: 138,
		part_id: 348,
		quantity: 401,
	},
	{
		garage_id: 138,
		part_id: 349,
		quantity: 402,
	},
	{
		garage_id: 138,
		part_id: 350,
		quantity: 349,
	},
	{
		garage_id: 138,
		part_id: 351,
		quantity: 286,
	},
	{
		garage_id: 138,
		part_id: 352,
		quantity: 421,
	},
	{
		garage_id: 138,
		part_id: 353,
		quantity: 25,
	},
	{
		garage_id: 138,
		part_id: 354,
		quantity: 232,
	},
	{
		garage_id: 138,
		part_id: 355,
		quantity: 281,
	},
	{
		garage_id: 138,
		part_id: 356,
		quantity: 121,
	},
	{
		garage_id: 138,
		part_id: 357,
		quantity: 221,
	},
	{
		garage_id: 138,
		part_id: 358,
		quantity: 132,
	},
	{
		garage_id: 138,
		part_id: 359,
		quantity: 319,
	},
	{
		garage_id: 138,
		part_id: 360,
		quantity: 215,
	},
	{
		garage_id: 138,
		part_id: 361,
		quantity: 327,
	},
	{
		garage_id: 138,
		part_id: 362,
		quantity: 50,
	},
	{
		garage_id: 138,
		part_id: 363,
		quantity: 90,
	},
	{
		garage_id: 138,
		part_id: 364,
		quantity: 287,
	},
	{
		garage_id: 138,
		part_id: 365,
		quantity: 34,
	},
	{
		garage_id: 138,
		part_id: 366,
		quantity: 247,
	},
	{
		garage_id: 138,
		part_id: 367,
		quantity: 494,
	},
	{
		garage_id: 138,
		part_id: 368,
		quantity: 260,
	},
	{
		garage_id: 138,
		part_id: 369,
		quantity: 161,
	},
	{
		garage_id: 138,
		part_id: 370,
		quantity: 289,
	},
	{
		garage_id: 138,
		part_id: 371,
		quantity: 230,
	},
	{
		garage_id: 138,
		part_id: 372,
		quantity: 180,
	},
	{
		garage_id: 138,
		part_id: 373,
		quantity: 129,
	},
	{
		garage_id: 138,
		part_id: 374,
		quantity: 372,
	},
	{
		garage_id: 138,
		part_id: 375,
		quantity: 104,
	},
	{
		garage_id: 138,
		part_id: 376,
		quantity: 134,
	},
	{
		garage_id: 138,
		part_id: 377,
		quantity: 261,
	},
	{
		garage_id: 138,
		part_id: 378,
		quantity: 352,
	},
	{
		garage_id: 138,
		part_id: 379,
		quantity: 386,
	},
	{
		garage_id: 138,
		part_id: 380,
		quantity: 469,
	},
	{
		garage_id: 138,
		part_id: 381,
		quantity: 2,
	},
	{
		garage_id: 138,
		part_id: 382,
		quantity: 317,
	},
	{
		garage_id: 138,
		part_id: 383,
		quantity: 475,
	},
	{
		garage_id: 138,
		part_id: 384,
		quantity: 413,
	},
	{
		garage_id: 138,
		part_id: 385,
		quantity: 479,
	},
	{
		garage_id: 138,
		part_id: 386,
		quantity: 453,
	},
	{
		garage_id: 138,
		part_id: 387,
		quantity: 77,
	},
	{
		garage_id: 138,
		part_id: 388,
		quantity: 413,
	},
	{
		garage_id: 138,
		part_id: 389,
		quantity: 296,
	},
	{
		garage_id: 138,
		part_id: 390,
		quantity: 40,
	},
	{
		garage_id: 138,
		part_id: 391,
		quantity: 365,
	},
	{
		garage_id: 138,
		part_id: 392,
		quantity: 287,
	},
	{
		garage_id: 138,
		part_id: 393,
		quantity: 498,
	},
	{
		garage_id: 138,
		part_id: 394,
		quantity: 208,
	},
	{
		garage_id: 138,
		part_id: 395,
		quantity: 141,
	},
	{
		garage_id: 138,
		part_id: 396,
		quantity: 185,
	},
	{
		garage_id: 138,
		part_id: 397,
		quantity: 214,
	},
	{
		garage_id: 138,
		part_id: 398,
		quantity: 183,
	},
	{
		garage_id: 138,
		part_id: 399,
		quantity: 145,
	},
	{
		garage_id: 139,
		part_id: 0,
		quantity: 72,
	},
	{
		garage_id: 139,
		part_id: 1,
		quantity: 452,
	},
	{
		garage_id: 139,
		part_id: 2,
		quantity: 168,
	},
	{
		garage_id: 139,
		part_id: 3,
		quantity: 72,
	},
	{
		garage_id: 139,
		part_id: 4,
		quantity: 329,
	},
	{
		garage_id: 139,
		part_id: 5,
		quantity: 212,
	},
	{
		garage_id: 139,
		part_id: 6,
		quantity: 256,
	},
	{
		garage_id: 139,
		part_id: 7,
		quantity: 344,
	},
	{
		garage_id: 139,
		part_id: 8,
		quantity: 91,
	},
	{
		garage_id: 139,
		part_id: 9,
		quantity: 435,
	},
	{
		garage_id: 139,
		part_id: 10,
		quantity: 415,
	},
	{
		garage_id: 139,
		part_id: 11,
		quantity: 419,
	},
	{
		garage_id: 139,
		part_id: 12,
		quantity: 1,
	},
	{
		garage_id: 139,
		part_id: 13,
		quantity: 77,
	},
	{
		garage_id: 139,
		part_id: 14,
		quantity: 48,
	},
	{
		garage_id: 139,
		part_id: 15,
		quantity: 284,
	},
	{
		garage_id: 139,
		part_id: 16,
		quantity: 220,
	},
	{
		garage_id: 139,
		part_id: 17,
		quantity: 122,
	},
	{
		garage_id: 139,
		part_id: 18,
		quantity: 356,
	},
	{
		garage_id: 139,
		part_id: 19,
		quantity: 15,
	},
	{
		garage_id: 139,
		part_id: 20,
		quantity: 294,
	},
	{
		garage_id: 139,
		part_id: 21,
		quantity: 413,
	},
	{
		garage_id: 139,
		part_id: 22,
		quantity: 299,
	},
	{
		garage_id: 139,
		part_id: 23,
		quantity: 367,
	},
	{
		garage_id: 139,
		part_id: 24,
		quantity: 14,
	},
	{
		garage_id: 139,
		part_id: 25,
		quantity: 204,
	},
	{
		garage_id: 139,
		part_id: 26,
		quantity: 184,
	},
	{
		garage_id: 139,
		part_id: 27,
		quantity: 460,
	},
	{
		garage_id: 139,
		part_id: 28,
		quantity: 354,
	},
	{
		garage_id: 139,
		part_id: 29,
		quantity: 245,
	},
	{
		garage_id: 139,
		part_id: 30,
		quantity: 195,
	},
	{
		garage_id: 139,
		part_id: 31,
		quantity: 354,
	},
	{
		garage_id: 139,
		part_id: 32,
		quantity: 77,
	},
	{
		garage_id: 139,
		part_id: 33,
		quantity: 336,
	},
	{
		garage_id: 139,
		part_id: 34,
		quantity: 82,
	},
	{
		garage_id: 139,
		part_id: 35,
		quantity: 131,
	},
	{
		garage_id: 139,
		part_id: 36,
		quantity: 106,
	},
	{
		garage_id: 139,
		part_id: 37,
		quantity: 32,
	},
	{
		garage_id: 139,
		part_id: 38,
		quantity: 154,
	},
	{
		garage_id: 139,
		part_id: 39,
		quantity: 236,
	},
	{
		garage_id: 139,
		part_id: 40,
		quantity: 418,
	},
	{
		garage_id: 139,
		part_id: 41,
		quantity: 196,
	},
	{
		garage_id: 139,
		part_id: 42,
		quantity: 223,
	},
	{
		garage_id: 139,
		part_id: 43,
		quantity: 254,
	},
	{
		garage_id: 139,
		part_id: 44,
		quantity: 5,
	},
	{
		garage_id: 139,
		part_id: 45,
		quantity: 309,
	},
	{
		garage_id: 139,
		part_id: 46,
		quantity: 462,
	},
	{
		garage_id: 139,
		part_id: 47,
		quantity: 204,
	},
	{
		garage_id: 139,
		part_id: 48,
		quantity: 173,
	},
	{
		garage_id: 139,
		part_id: 49,
		quantity: 325,
	},
	{
		garage_id: 139,
		part_id: 50,
		quantity: 299,
	},
	{
		garage_id: 139,
		part_id: 51,
		quantity: 245,
	},
	{
		garage_id: 139,
		part_id: 52,
		quantity: 178,
	},
	{
		garage_id: 139,
		part_id: 53,
		quantity: 280,
	},
	{
		garage_id: 139,
		part_id: 54,
		quantity: 444,
	},
	{
		garage_id: 139,
		part_id: 55,
		quantity: 414,
	},
	{
		garage_id: 139,
		part_id: 56,
		quantity: 423,
	},
	{
		garage_id: 139,
		part_id: 57,
		quantity: 121,
	},
	{
		garage_id: 139,
		part_id: 58,
		quantity: 176,
	},
	{
		garage_id: 139,
		part_id: 59,
		quantity: 265,
	},
	{
		garage_id: 139,
		part_id: 60,
		quantity: 222,
	},
	{
		garage_id: 139,
		part_id: 61,
		quantity: 251,
	},
	{
		garage_id: 139,
		part_id: 62,
		quantity: 246,
	},
	{
		garage_id: 139,
		part_id: 63,
		quantity: 85,
	},
	{
		garage_id: 139,
		part_id: 64,
		quantity: 441,
	},
	{
		garage_id: 139,
		part_id: 65,
		quantity: 302,
	},
	{
		garage_id: 139,
		part_id: 66,
		quantity: 254,
	},
	{
		garage_id: 139,
		part_id: 67,
		quantity: 451,
	},
	{
		garage_id: 139,
		part_id: 68,
		quantity: 126,
	},
	{
		garage_id: 139,
		part_id: 69,
		quantity: 423,
	},
	{
		garage_id: 139,
		part_id: 70,
		quantity: 374,
	},
	{
		garage_id: 139,
		part_id: 71,
		quantity: 3,
	},
	{
		garage_id: 139,
		part_id: 72,
		quantity: 301,
	},
	{
		garage_id: 139,
		part_id: 73,
		quantity: 210,
	},
	{
		garage_id: 139,
		part_id: 74,
		quantity: 148,
	},
	{
		garage_id: 139,
		part_id: 75,
		quantity: 142,
	},
	{
		garage_id: 139,
		part_id: 76,
		quantity: 318,
	},
	{
		garage_id: 139,
		part_id: 77,
		quantity: 55,
	},
	{
		garage_id: 139,
		part_id: 78,
		quantity: 236,
	},
	{
		garage_id: 139,
		part_id: 79,
		quantity: 417,
	},
	{
		garage_id: 139,
		part_id: 80,
		quantity: 463,
	},
	{
		garage_id: 139,
		part_id: 81,
		quantity: 59,
	},
	{
		garage_id: 139,
		part_id: 82,
		quantity: 129,
	},
	{
		garage_id: 139,
		part_id: 83,
		quantity: 315,
	},
	{
		garage_id: 139,
		part_id: 84,
		quantity: 197,
	},
	{
		garage_id: 139,
		part_id: 85,
		quantity: 490,
	},
	{
		garage_id: 139,
		part_id: 86,
		quantity: 385,
	},
	{
		garage_id: 139,
		part_id: 87,
		quantity: 36,
	},
	{
		garage_id: 139,
		part_id: 88,
		quantity: 264,
	},
	{
		garage_id: 139,
		part_id: 89,
		quantity: 164,
	},
	{
		garage_id: 139,
		part_id: 90,
		quantity: 141,
	},
	{
		garage_id: 139,
		part_id: 91,
		quantity: 342,
	},
	{
		garage_id: 139,
		part_id: 92,
		quantity: 30,
	},
	{
		garage_id: 139,
		part_id: 93,
		quantity: 352,
	},
	{
		garage_id: 139,
		part_id: 94,
		quantity: 409,
	},
	{
		garage_id: 139,
		part_id: 95,
		quantity: 449,
	},
	{
		garage_id: 139,
		part_id: 96,
		quantity: 352,
	},
	{
		garage_id: 139,
		part_id: 97,
		quantity: 373,
	},
	{
		garage_id: 139,
		part_id: 98,
		quantity: 170,
	},
	{
		garage_id: 139,
		part_id: 99,
		quantity: 430,
	},
	{
		garage_id: 139,
		part_id: 100,
		quantity: 151,
	},
	{
		garage_id: 139,
		part_id: 101,
		quantity: 222,
	},
	{
		garage_id: 139,
		part_id: 102,
		quantity: 300,
	},
	{
		garage_id: 139,
		part_id: 103,
		quantity: 49,
	},
	{
		garage_id: 139,
		part_id: 104,
		quantity: 43,
	},
	{
		garage_id: 139,
		part_id: 105,
		quantity: 372,
	},
	{
		garage_id: 139,
		part_id: 106,
		quantity: 362,
	},
	{
		garage_id: 139,
		part_id: 107,
		quantity: 267,
	},
	{
		garage_id: 139,
		part_id: 108,
		quantity: 277,
	},
	{
		garage_id: 139,
		part_id: 109,
		quantity: 115,
	},
	{
		garage_id: 139,
		part_id: 110,
		quantity: 346,
	},
	{
		garage_id: 139,
		part_id: 111,
		quantity: 419,
	},
	{
		garage_id: 139,
		part_id: 112,
		quantity: 410,
	},
	{
		garage_id: 139,
		part_id: 113,
		quantity: 82,
	},
	{
		garage_id: 139,
		part_id: 114,
		quantity: 331,
	},
	{
		garage_id: 139,
		part_id: 115,
		quantity: 121,
	},
	{
		garage_id: 139,
		part_id: 116,
		quantity: 347,
	},
	{
		garage_id: 139,
		part_id: 117,
		quantity: 237,
	},
	{
		garage_id: 139,
		part_id: 118,
		quantity: 169,
	},
	{
		garage_id: 139,
		part_id: 119,
		quantity: 130,
	},
	{
		garage_id: 139,
		part_id: 120,
		quantity: 392,
	},
	{
		garage_id: 139,
		part_id: 121,
		quantity: 151,
	},
	{
		garage_id: 139,
		part_id: 122,
		quantity: 269,
	},
	{
		garage_id: 139,
		part_id: 123,
		quantity: 143,
	},
	{
		garage_id: 139,
		part_id: 124,
		quantity: 25,
	},
	{
		garage_id: 139,
		part_id: 125,
		quantity: 379,
	},
	{
		garage_id: 139,
		part_id: 126,
		quantity: 162,
	},
	{
		garage_id: 139,
		part_id: 127,
		quantity: 266,
	},
	{
		garage_id: 139,
		part_id: 128,
		quantity: 209,
	},
	{
		garage_id: 139,
		part_id: 129,
		quantity: 164,
	},
	{
		garage_id: 139,
		part_id: 130,
		quantity: 484,
	},
	{
		garage_id: 139,
		part_id: 131,
		quantity: 184,
	},
	{
		garage_id: 139,
		part_id: 132,
		quantity: 330,
	},
	{
		garage_id: 139,
		part_id: 133,
		quantity: 251,
	},
	{
		garage_id: 139,
		part_id: 134,
		quantity: 479,
	},
	{
		garage_id: 139,
		part_id: 135,
		quantity: 481,
	},
	{
		garage_id: 139,
		part_id: 136,
		quantity: 401,
	},
	{
		garage_id: 139,
		part_id: 137,
		quantity: 190,
	},
	{
		garage_id: 139,
		part_id: 138,
		quantity: 110,
	},
	{
		garage_id: 139,
		part_id: 139,
		quantity: 420,
	},
	{
		garage_id: 139,
		part_id: 140,
		quantity: 89,
	},
	{
		garage_id: 139,
		part_id: 141,
		quantity: 250,
	},
	{
		garage_id: 139,
		part_id: 142,
		quantity: 488,
	},
	{
		garage_id: 139,
		part_id: 143,
		quantity: 64,
	},
	{
		garage_id: 139,
		part_id: 144,
		quantity: 163,
	},
	{
		garage_id: 139,
		part_id: 145,
		quantity: 283,
	},
	{
		garage_id: 139,
		part_id: 146,
		quantity: 252,
	},
	{
		garage_id: 139,
		part_id: 147,
		quantity: 390,
	},
	{
		garage_id: 139,
		part_id: 148,
		quantity: 291,
	},
	{
		garage_id: 139,
		part_id: 149,
		quantity: 184,
	},
	{
		garage_id: 139,
		part_id: 150,
		quantity: 243,
	},
	{
		garage_id: 139,
		part_id: 151,
		quantity: 434,
	},
	{
		garage_id: 139,
		part_id: 152,
		quantity: 314,
	},
	{
		garage_id: 139,
		part_id: 153,
		quantity: 362,
	},
	{
		garage_id: 139,
		part_id: 154,
		quantity: 78,
	},
	{
		garage_id: 139,
		part_id: 155,
		quantity: 13,
	},
	{
		garage_id: 139,
		part_id: 156,
		quantity: 141,
	},
	{
		garage_id: 139,
		part_id: 157,
		quantity: 184,
	},
	{
		garage_id: 139,
		part_id: 158,
		quantity: 82,
	},
	{
		garage_id: 139,
		part_id: 159,
		quantity: 73,
	},
	{
		garage_id: 139,
		part_id: 160,
		quantity: 410,
	},
	{
		garage_id: 139,
		part_id: 161,
		quantity: 496,
	},
	{
		garage_id: 139,
		part_id: 162,
		quantity: 82,
	},
	{
		garage_id: 139,
		part_id: 163,
		quantity: 241,
	},
	{
		garage_id: 139,
		part_id: 164,
		quantity: 325,
	},
	{
		garage_id: 139,
		part_id: 165,
		quantity: 383,
	},
	{
		garage_id: 139,
		part_id: 166,
		quantity: 236,
	},
	{
		garage_id: 139,
		part_id: 167,
		quantity: 385,
	},
	{
		garage_id: 139,
		part_id: 168,
		quantity: 317,
	},
	{
		garage_id: 139,
		part_id: 169,
		quantity: 136,
	},
	{
		garage_id: 139,
		part_id: 170,
		quantity: 258,
	},
	{
		garage_id: 139,
		part_id: 171,
		quantity: 239,
	},
	{
		garage_id: 139,
		part_id: 172,
		quantity: 162,
	},
	{
		garage_id: 139,
		part_id: 173,
		quantity: 291,
	},
	{
		garage_id: 139,
		part_id: 174,
		quantity: 256,
	},
	{
		garage_id: 139,
		part_id: 175,
		quantity: 315,
	},
	{
		garage_id: 139,
		part_id: 176,
		quantity: 493,
	},
	{
		garage_id: 139,
		part_id: 177,
		quantity: 140,
	},
	{
		garage_id: 139,
		part_id: 178,
		quantity: 155,
	},
	{
		garage_id: 139,
		part_id: 179,
		quantity: 82,
	},
	{
		garage_id: 139,
		part_id: 180,
		quantity: 421,
	},
	{
		garage_id: 139,
		part_id: 181,
		quantity: 341,
	},
	{
		garage_id: 139,
		part_id: 182,
		quantity: 349,
	},
	{
		garage_id: 139,
		part_id: 183,
		quantity: 213,
	},
	{
		garage_id: 139,
		part_id: 184,
		quantity: 207,
	},
	{
		garage_id: 139,
		part_id: 185,
		quantity: 349,
	},
	{
		garage_id: 139,
		part_id: 186,
		quantity: 387,
	},
	{
		garage_id: 139,
		part_id: 187,
		quantity: 408,
	},
	{
		garage_id: 139,
		part_id: 188,
		quantity: 426,
	},
	{
		garage_id: 139,
		part_id: 189,
		quantity: 409,
	},
	{
		garage_id: 139,
		part_id: 190,
		quantity: 141,
	},
	{
		garage_id: 139,
		part_id: 191,
		quantity: 398,
	},
	{
		garage_id: 139,
		part_id: 192,
		quantity: 81,
	},
	{
		garage_id: 139,
		part_id: 193,
		quantity: 167,
	},
	{
		garage_id: 139,
		part_id: 194,
		quantity: 68,
	},
	{
		garage_id: 139,
		part_id: 195,
		quantity: 59,
	},
	{
		garage_id: 139,
		part_id: 196,
		quantity: 426,
	},
	{
		garage_id: 139,
		part_id: 197,
		quantity: 387,
	},
	{
		garage_id: 139,
		part_id: 198,
		quantity: 139,
	},
	{
		garage_id: 139,
		part_id: 199,
		quantity: 460,
	},
	{
		garage_id: 139,
		part_id: 200,
		quantity: 183,
	},
	{
		garage_id: 139,
		part_id: 201,
		quantity: 387,
	},
	{
		garage_id: 139,
		part_id: 202,
		quantity: 333,
	},
	{
		garage_id: 139,
		part_id: 203,
		quantity: 141,
	},
	{
		garage_id: 139,
		part_id: 204,
		quantity: 33,
	},
	{
		garage_id: 139,
		part_id: 205,
		quantity: 107,
	},
	{
		garage_id: 139,
		part_id: 206,
		quantity: 426,
	},
	{
		garage_id: 139,
		part_id: 207,
		quantity: 291,
	},
	{
		garage_id: 139,
		part_id: 208,
		quantity: 390,
	},
	{
		garage_id: 139,
		part_id: 209,
		quantity: 357,
	},
	{
		garage_id: 139,
		part_id: 210,
		quantity: 137,
	},
	{
		garage_id: 139,
		part_id: 211,
		quantity: 286,
	},
	{
		garage_id: 139,
		part_id: 212,
		quantity: 293,
	},
	{
		garage_id: 139,
		part_id: 213,
		quantity: 197,
	},
	{
		garage_id: 139,
		part_id: 214,
		quantity: 497,
	},
	{
		garage_id: 139,
		part_id: 215,
		quantity: 399,
	},
	{
		garage_id: 139,
		part_id: 216,
		quantity: 268,
	},
	{
		garage_id: 139,
		part_id: 217,
		quantity: 256,
	},
	{
		garage_id: 139,
		part_id: 218,
		quantity: 365,
	},
	{
		garage_id: 139,
		part_id: 219,
		quantity: 383,
	},
	{
		garage_id: 139,
		part_id: 220,
		quantity: 76,
	},
	{
		garage_id: 139,
		part_id: 221,
		quantity: 316,
	},
	{
		garage_id: 139,
		part_id: 222,
		quantity: 321,
	},
	{
		garage_id: 139,
		part_id: 223,
		quantity: 151,
	},
	{
		garage_id: 139,
		part_id: 224,
		quantity: 121,
	},
	{
		garage_id: 139,
		part_id: 225,
		quantity: 209,
	},
	{
		garage_id: 139,
		part_id: 226,
		quantity: 49,
	},
	{
		garage_id: 139,
		part_id: 227,
		quantity: 24,
	},
	{
		garage_id: 139,
		part_id: 228,
		quantity: 466,
	},
	{
		garage_id: 139,
		part_id: 229,
		quantity: 222,
	},
	{
		garage_id: 139,
		part_id: 230,
		quantity: 127,
	},
	{
		garage_id: 139,
		part_id: 231,
		quantity: 239,
	},
	{
		garage_id: 139,
		part_id: 232,
		quantity: 339,
	},
	{
		garage_id: 139,
		part_id: 233,
		quantity: 340,
	},
	{
		garage_id: 139,
		part_id: 234,
		quantity: 162,
	},
	{
		garage_id: 139,
		part_id: 235,
		quantity: 340,
	},
	{
		garage_id: 139,
		part_id: 236,
		quantity: 263,
	},
	{
		garage_id: 139,
		part_id: 237,
		quantity: 251,
	},
	{
		garage_id: 139,
		part_id: 238,
		quantity: 113,
	},
	{
		garage_id: 139,
		part_id: 239,
		quantity: 375,
	},
	{
		garage_id: 139,
		part_id: 240,
		quantity: 279,
	},
	{
		garage_id: 139,
		part_id: 241,
		quantity: 199,
	},
	{
		garage_id: 139,
		part_id: 242,
		quantity: 112,
	},
	{
		garage_id: 139,
		part_id: 243,
		quantity: 276,
	},
	{
		garage_id: 139,
		part_id: 244,
		quantity: 74,
	},
	{
		garage_id: 139,
		part_id: 245,
		quantity: 186,
	},
	{
		garage_id: 139,
		part_id: 246,
		quantity: 285,
	},
	{
		garage_id: 139,
		part_id: 247,
		quantity: 373,
	},
	{
		garage_id: 139,
		part_id: 248,
		quantity: 95,
	},
	{
		garage_id: 139,
		part_id: 249,
		quantity: 195,
	},
	{
		garage_id: 139,
		part_id: 250,
		quantity: 53,
	},
	{
		garage_id: 139,
		part_id: 251,
		quantity: 380,
	},
	{
		garage_id: 139,
		part_id: 252,
		quantity: 313,
	},
	{
		garage_id: 139,
		part_id: 253,
		quantity: 212,
	},
	{
		garage_id: 139,
		part_id: 254,
		quantity: 277,
	},
	{
		garage_id: 139,
		part_id: 255,
		quantity: 240,
	},
	{
		garage_id: 139,
		part_id: 256,
		quantity: 394,
	},
	{
		garage_id: 139,
		part_id: 257,
		quantity: 322,
	},
	{
		garage_id: 139,
		part_id: 258,
		quantity: 498,
	},
	{
		garage_id: 139,
		part_id: 259,
		quantity: 136,
	},
	{
		garage_id: 139,
		part_id: 260,
		quantity: 468,
	},
	{
		garage_id: 139,
		part_id: 261,
		quantity: 264,
	},
	{
		garage_id: 139,
		part_id: 262,
		quantity: 296,
	},
	{
		garage_id: 139,
		part_id: 263,
		quantity: 451,
	},
	{
		garage_id: 139,
		part_id: 264,
		quantity: 249,
	},
	{
		garage_id: 139,
		part_id: 265,
		quantity: 184,
	},
	{
		garage_id: 139,
		part_id: 266,
		quantity: 336,
	},
	{
		garage_id: 139,
		part_id: 267,
		quantity: 488,
	},
	{
		garage_id: 139,
		part_id: 268,
		quantity: 225,
	},
	{
		garage_id: 139,
		part_id: 269,
		quantity: 440,
	},
	{
		garage_id: 139,
		part_id: 270,
		quantity: 370,
	},
	{
		garage_id: 139,
		part_id: 271,
		quantity: 468,
	},
	{
		garage_id: 139,
		part_id: 272,
		quantity: 471,
	},
	{
		garage_id: 139,
		part_id: 273,
		quantity: 425,
	},
	{
		garage_id: 139,
		part_id: 274,
		quantity: 120,
	},
	{
		garage_id: 139,
		part_id: 275,
		quantity: 57,
	},
	{
		garage_id: 139,
		part_id: 276,
		quantity: 66,
	},
	{
		garage_id: 139,
		part_id: 277,
		quantity: 422,
	},
	{
		garage_id: 139,
		part_id: 278,
		quantity: 99,
	},
	{
		garage_id: 139,
		part_id: 279,
		quantity: 99,
	},
	{
		garage_id: 139,
		part_id: 280,
		quantity: 283,
	},
	{
		garage_id: 139,
		part_id: 281,
		quantity: 143,
	},
	{
		garage_id: 139,
		part_id: 282,
		quantity: 400,
	},
	{
		garage_id: 139,
		part_id: 283,
		quantity: 348,
	},
	{
		garage_id: 139,
		part_id: 284,
		quantity: 369,
	},
	{
		garage_id: 139,
		part_id: 285,
		quantity: 125,
	},
	{
		garage_id: 139,
		part_id: 286,
		quantity: 254,
	},
	{
		garage_id: 139,
		part_id: 287,
		quantity: 254,
	},
	{
		garage_id: 139,
		part_id: 288,
		quantity: 144,
	},
	{
		garage_id: 139,
		part_id: 289,
		quantity: 289,
	},
	{
		garage_id: 139,
		part_id: 290,
		quantity: 145,
	},
	{
		garage_id: 139,
		part_id: 291,
		quantity: 311,
	},
	{
		garage_id: 139,
		part_id: 292,
		quantity: 16,
	},
	{
		garage_id: 139,
		part_id: 293,
		quantity: 199,
	},
	{
		garage_id: 139,
		part_id: 294,
		quantity: 270,
	},
	{
		garage_id: 139,
		part_id: 295,
		quantity: 351,
	},
	{
		garage_id: 139,
		part_id: 296,
		quantity: 157,
	},
	{
		garage_id: 139,
		part_id: 297,
		quantity: 306,
	},
	{
		garage_id: 139,
		part_id: 298,
		quantity: 409,
	},
	{
		garage_id: 139,
		part_id: 299,
		quantity: 418,
	},
	{
		garage_id: 139,
		part_id: 300,
		quantity: 87,
	},
	{
		garage_id: 139,
		part_id: 301,
		quantity: 388,
	},
	{
		garage_id: 139,
		part_id: 302,
		quantity: 422,
	},
	{
		garage_id: 139,
		part_id: 303,
		quantity: 32,
	},
	{
		garage_id: 139,
		part_id: 304,
		quantity: 432,
	},
	{
		garage_id: 139,
		part_id: 305,
		quantity: 14,
	},
	{
		garage_id: 139,
		part_id: 306,
		quantity: 43,
	},
	{
		garage_id: 139,
		part_id: 307,
		quantity: 333,
	},
	{
		garage_id: 139,
		part_id: 308,
		quantity: 191,
	},
	{
		garage_id: 139,
		part_id: 309,
		quantity: 313,
	},
	{
		garage_id: 139,
		part_id: 310,
		quantity: 243,
	},
	{
		garage_id: 139,
		part_id: 311,
		quantity: 212,
	},
	{
		garage_id: 139,
		part_id: 312,
		quantity: 121,
	},
	{
		garage_id: 139,
		part_id: 313,
		quantity: 319,
	},
	{
		garage_id: 139,
		part_id: 314,
		quantity: 119,
	},
	{
		garage_id: 139,
		part_id: 315,
		quantity: 106,
	},
	{
		garage_id: 139,
		part_id: 316,
		quantity: 241,
	},
	{
		garage_id: 139,
		part_id: 317,
		quantity: 489,
	},
	{
		garage_id: 139,
		part_id: 318,
		quantity: 89,
	},
	{
		garage_id: 139,
		part_id: 319,
		quantity: 425,
	},
	{
		garage_id: 139,
		part_id: 320,
		quantity: 274,
	},
	{
		garage_id: 139,
		part_id: 321,
		quantity: 431,
	},
	{
		garage_id: 139,
		part_id: 322,
		quantity: 187,
	},
	{
		garage_id: 139,
		part_id: 323,
		quantity: 117,
	},
	{
		garage_id: 139,
		part_id: 324,
		quantity: 188,
	},
	{
		garage_id: 139,
		part_id: 325,
		quantity: 253,
	},
	{
		garage_id: 139,
		part_id: 326,
		quantity: 214,
	},
	{
		garage_id: 139,
		part_id: 327,
		quantity: 474,
	},
	{
		garage_id: 139,
		part_id: 328,
		quantity: 417,
	},
	{
		garage_id: 139,
		part_id: 329,
		quantity: 283,
	},
	{
		garage_id: 139,
		part_id: 330,
		quantity: 265,
	},
	{
		garage_id: 139,
		part_id: 331,
		quantity: 94,
	},
	{
		garage_id: 139,
		part_id: 332,
		quantity: 166,
	},
	{
		garage_id: 139,
		part_id: 333,
		quantity: 464,
	},
	{
		garage_id: 139,
		part_id: 334,
		quantity: 177,
	},
	{
		garage_id: 139,
		part_id: 335,
		quantity: 485,
	},
	{
		garage_id: 139,
		part_id: 336,
		quantity: 284,
	},
	{
		garage_id: 139,
		part_id: 337,
		quantity: 232,
	},
	{
		garage_id: 139,
		part_id: 338,
		quantity: 292,
	},
	{
		garage_id: 139,
		part_id: 339,
		quantity: 306,
	},
	{
		garage_id: 139,
		part_id: 340,
		quantity: 25,
	},
	{
		garage_id: 139,
		part_id: 341,
		quantity: 419,
	},
	{
		garage_id: 139,
		part_id: 342,
		quantity: 190,
	},
	{
		garage_id: 139,
		part_id: 343,
		quantity: 239,
	},
	{
		garage_id: 139,
		part_id: 344,
		quantity: 247,
	},
	{
		garage_id: 139,
		part_id: 345,
		quantity: 306,
	},
	{
		garage_id: 139,
		part_id: 346,
		quantity: 119,
	},
	{
		garage_id: 139,
		part_id: 347,
		quantity: 16,
	},
	{
		garage_id: 139,
		part_id: 348,
		quantity: 343,
	},
	{
		garage_id: 139,
		part_id: 349,
		quantity: 486,
	},
	{
		garage_id: 139,
		part_id: 350,
		quantity: 246,
	},
	{
		garage_id: 139,
		part_id: 351,
		quantity: 205,
	},
	{
		garage_id: 139,
		part_id: 352,
		quantity: 416,
	},
	{
		garage_id: 139,
		part_id: 353,
		quantity: 377,
	},
	{
		garage_id: 139,
		part_id: 354,
		quantity: 20,
	},
	{
		garage_id: 139,
		part_id: 355,
		quantity: 337,
	},
	{
		garage_id: 139,
		part_id: 356,
		quantity: 69,
	},
	{
		garage_id: 139,
		part_id: 357,
		quantity: 288,
	},
	{
		garage_id: 139,
		part_id: 358,
		quantity: 489,
	},
	{
		garage_id: 139,
		part_id: 359,
		quantity: 165,
	},
	{
		garage_id: 139,
		part_id: 360,
		quantity: 315,
	},
	{
		garage_id: 139,
		part_id: 361,
		quantity: 416,
	},
	{
		garage_id: 139,
		part_id: 362,
		quantity: 74,
	},
	{
		garage_id: 139,
		part_id: 363,
		quantity: 327,
	},
	{
		garage_id: 139,
		part_id: 364,
		quantity: 236,
	},
	{
		garage_id: 139,
		part_id: 365,
		quantity: 175,
	},
	{
		garage_id: 139,
		part_id: 366,
		quantity: 13,
	},
	{
		garage_id: 139,
		part_id: 367,
		quantity: 447,
	},
	{
		garage_id: 139,
		part_id: 368,
		quantity: 73,
	},
	{
		garage_id: 139,
		part_id: 369,
		quantity: 9,
	},
	{
		garage_id: 139,
		part_id: 370,
		quantity: 376,
	},
	{
		garage_id: 139,
		part_id: 371,
		quantity: 51,
	},
	{
		garage_id: 139,
		part_id: 372,
		quantity: 371,
	},
	{
		garage_id: 139,
		part_id: 373,
		quantity: 408,
	},
	{
		garage_id: 139,
		part_id: 374,
		quantity: 222,
	},
	{
		garage_id: 139,
		part_id: 375,
		quantity: 404,
	},
	{
		garage_id: 139,
		part_id: 376,
		quantity: 38,
	},
	{
		garage_id: 139,
		part_id: 377,
		quantity: 355,
	},
	{
		garage_id: 139,
		part_id: 378,
		quantity: 20,
	},
	{
		garage_id: 139,
		part_id: 379,
		quantity: 425,
	},
	{
		garage_id: 139,
		part_id: 380,
		quantity: 198,
	},
	{
		garage_id: 139,
		part_id: 381,
		quantity: 299,
	},
	{
		garage_id: 139,
		part_id: 382,
		quantity: 415,
	},
	{
		garage_id: 139,
		part_id: 383,
		quantity: 16,
	},
	{
		garage_id: 139,
		part_id: 384,
		quantity: 345,
	},
	{
		garage_id: 139,
		part_id: 385,
		quantity: 243,
	},
	{
		garage_id: 139,
		part_id: 386,
		quantity: 43,
	},
	{
		garage_id: 139,
		part_id: 387,
		quantity: 170,
	},
	{
		garage_id: 139,
		part_id: 388,
		quantity: 299,
	},
	{
		garage_id: 139,
		part_id: 389,
		quantity: 171,
	},
	{
		garage_id: 139,
		part_id: 390,
		quantity: 158,
	},
	{
		garage_id: 139,
		part_id: 391,
		quantity: 478,
	},
	{
		garage_id: 139,
		part_id: 392,
		quantity: 453,
	},
	{
		garage_id: 139,
		part_id: 393,
		quantity: 350,
	},
	{
		garage_id: 139,
		part_id: 394,
		quantity: 38,
	},
	{
		garage_id: 139,
		part_id: 395,
		quantity: 83,
	},
	{
		garage_id: 139,
		part_id: 396,
		quantity: 281,
	},
	{
		garage_id: 139,
		part_id: 397,
		quantity: 313,
	},
	{
		garage_id: 139,
		part_id: 398,
		quantity: 184,
	},
	{
		garage_id: 139,
		part_id: 399,
		quantity: 214,
	},
	{
		garage_id: 140,
		part_id: 0,
		quantity: 209,
	},
	{
		garage_id: 140,
		part_id: 1,
		quantity: 229,
	},
	{
		garage_id: 140,
		part_id: 2,
		quantity: 209,
	},
	{
		garage_id: 140,
		part_id: 3,
		quantity: 253,
	},
	{
		garage_id: 140,
		part_id: 4,
		quantity: 75,
	},
	{
		garage_id: 140,
		part_id: 5,
		quantity: 356,
	},
	{
		garage_id: 140,
		part_id: 6,
		quantity: 357,
	},
	{
		garage_id: 140,
		part_id: 7,
		quantity: 353,
	},
	{
		garage_id: 140,
		part_id: 8,
		quantity: 498,
	},
	{
		garage_id: 140,
		part_id: 9,
		quantity: 19,
	},
	{
		garage_id: 140,
		part_id: 10,
		quantity: 484,
	},
	{
		garage_id: 140,
		part_id: 11,
		quantity: 85,
	},
	{
		garage_id: 140,
		part_id: 12,
		quantity: 94,
	},
	{
		garage_id: 140,
		part_id: 13,
		quantity: 219,
	},
	{
		garage_id: 140,
		part_id: 14,
		quantity: 191,
	},
	{
		garage_id: 140,
		part_id: 15,
		quantity: 402,
	},
	{
		garage_id: 140,
		part_id: 16,
		quantity: 312,
	},
	{
		garage_id: 140,
		part_id: 17,
		quantity: 308,
	},
	{
		garage_id: 140,
		part_id: 18,
		quantity: 209,
	},
	{
		garage_id: 140,
		part_id: 19,
		quantity: 228,
	},
	{
		garage_id: 140,
		part_id: 20,
		quantity: 322,
	},
	{
		garage_id: 140,
		part_id: 21,
		quantity: 18,
	},
	{
		garage_id: 140,
		part_id: 22,
		quantity: 58,
	},
	{
		garage_id: 140,
		part_id: 23,
		quantity: 468,
	},
	{
		garage_id: 140,
		part_id: 24,
		quantity: 196,
	},
	{
		garage_id: 140,
		part_id: 25,
		quantity: 358,
	},
	{
		garage_id: 140,
		part_id: 26,
		quantity: 370,
	},
	{
		garage_id: 140,
		part_id: 27,
		quantity: 64,
	},
	{
		garage_id: 140,
		part_id: 28,
		quantity: 354,
	},
	{
		garage_id: 140,
		part_id: 29,
		quantity: 141,
	},
	{
		garage_id: 140,
		part_id: 30,
		quantity: 103,
	},
	{
		garage_id: 140,
		part_id: 31,
		quantity: 243,
	},
	{
		garage_id: 140,
		part_id: 32,
		quantity: 352,
	},
	{
		garage_id: 140,
		part_id: 33,
		quantity: 436,
	},
	{
		garage_id: 140,
		part_id: 34,
		quantity: 208,
	},
	{
		garage_id: 140,
		part_id: 35,
		quantity: 477,
	},
	{
		garage_id: 140,
		part_id: 36,
		quantity: 21,
	},
	{
		garage_id: 140,
		part_id: 37,
		quantity: 465,
	},
	{
		garage_id: 140,
		part_id: 38,
		quantity: 427,
	},
	{
		garage_id: 140,
		part_id: 39,
		quantity: 410,
	},
	{
		garage_id: 140,
		part_id: 40,
		quantity: 170,
	},
	{
		garage_id: 140,
		part_id: 41,
		quantity: 19,
	},
	{
		garage_id: 140,
		part_id: 42,
		quantity: 68,
	},
	{
		garage_id: 140,
		part_id: 43,
		quantity: 349,
	},
	{
		garage_id: 140,
		part_id: 44,
		quantity: 144,
	},
	{
		garage_id: 140,
		part_id: 45,
		quantity: 144,
	},
	{
		garage_id: 140,
		part_id: 46,
		quantity: 220,
	},
	{
		garage_id: 140,
		part_id: 47,
		quantity: 314,
	},
	{
		garage_id: 140,
		part_id: 48,
		quantity: 458,
	},
	{
		garage_id: 140,
		part_id: 49,
		quantity: 107,
	},
	{
		garage_id: 140,
		part_id: 50,
		quantity: 120,
	},
	{
		garage_id: 140,
		part_id: 51,
		quantity: 217,
	},
	{
		garage_id: 140,
		part_id: 52,
		quantity: 202,
	},
	{
		garage_id: 140,
		part_id: 53,
		quantity: 287,
	},
	{
		garage_id: 140,
		part_id: 54,
		quantity: 481,
	},
	{
		garage_id: 140,
		part_id: 55,
		quantity: 417,
	},
	{
		garage_id: 140,
		part_id: 56,
		quantity: 247,
	},
	{
		garage_id: 140,
		part_id: 57,
		quantity: 306,
	},
	{
		garage_id: 140,
		part_id: 58,
		quantity: 136,
	},
	{
		garage_id: 140,
		part_id: 59,
		quantity: 420,
	},
	{
		garage_id: 140,
		part_id: 60,
		quantity: 191,
	},
	{
		garage_id: 140,
		part_id: 61,
		quantity: 355,
	},
	{
		garage_id: 140,
		part_id: 62,
		quantity: 240,
	},
	{
		garage_id: 140,
		part_id: 63,
		quantity: 163,
	},
	{
		garage_id: 140,
		part_id: 64,
		quantity: 158,
	},
	{
		garage_id: 140,
		part_id: 65,
		quantity: 122,
	},
	{
		garage_id: 140,
		part_id: 66,
		quantity: 217,
	},
	{
		garage_id: 140,
		part_id: 67,
		quantity: 19,
	},
	{
		garage_id: 140,
		part_id: 68,
		quantity: 486,
	},
	{
		garage_id: 140,
		part_id: 69,
		quantity: 158,
	},
	{
		garage_id: 140,
		part_id: 70,
		quantity: 25,
	},
	{
		garage_id: 140,
		part_id: 71,
		quantity: 217,
	},
	{
		garage_id: 140,
		part_id: 72,
		quantity: 101,
	},
	{
		garage_id: 140,
		part_id: 73,
		quantity: 386,
	},
	{
		garage_id: 140,
		part_id: 74,
		quantity: 245,
	},
	{
		garage_id: 140,
		part_id: 75,
		quantity: 65,
	},
	{
		garage_id: 140,
		part_id: 76,
		quantity: 175,
	},
	{
		garage_id: 140,
		part_id: 77,
		quantity: 465,
	},
	{
		garage_id: 140,
		part_id: 78,
		quantity: 477,
	},
	{
		garage_id: 140,
		part_id: 79,
		quantity: 155,
	},
	{
		garage_id: 140,
		part_id: 80,
		quantity: 396,
	},
	{
		garage_id: 140,
		part_id: 81,
		quantity: 467,
	},
	{
		garage_id: 140,
		part_id: 82,
		quantity: 49,
	},
	{
		garage_id: 140,
		part_id: 83,
		quantity: 358,
	},
	{
		garage_id: 140,
		part_id: 84,
		quantity: 45,
	},
	{
		garage_id: 140,
		part_id: 85,
		quantity: 497,
	},
	{
		garage_id: 140,
		part_id: 86,
		quantity: 493,
	},
	{
		garage_id: 140,
		part_id: 87,
		quantity: 88,
	},
	{
		garage_id: 140,
		part_id: 88,
		quantity: 460,
	},
	{
		garage_id: 140,
		part_id: 89,
		quantity: 164,
	},
	{
		garage_id: 140,
		part_id: 90,
		quantity: 338,
	},
	{
		garage_id: 140,
		part_id: 91,
		quantity: 180,
	},
	{
		garage_id: 140,
		part_id: 92,
		quantity: 98,
	},
	{
		garage_id: 140,
		part_id: 93,
		quantity: 193,
	},
	{
		garage_id: 140,
		part_id: 94,
		quantity: 46,
	},
	{
		garage_id: 140,
		part_id: 95,
		quantity: 72,
	},
	{
		garage_id: 140,
		part_id: 96,
		quantity: 115,
	},
	{
		garage_id: 140,
		part_id: 97,
		quantity: 54,
	},
	{
		garage_id: 140,
		part_id: 98,
		quantity: 257,
	},
	{
		garage_id: 140,
		part_id: 99,
		quantity: 84,
	},
	{
		garage_id: 140,
		part_id: 100,
		quantity: 198,
	},
	{
		garage_id: 140,
		part_id: 101,
		quantity: 358,
	},
	{
		garage_id: 140,
		part_id: 102,
		quantity: 122,
	},
	{
		garage_id: 140,
		part_id: 103,
		quantity: 217,
	},
	{
		garage_id: 140,
		part_id: 104,
		quantity: 209,
	},
	{
		garage_id: 140,
		part_id: 105,
		quantity: 276,
	},
	{
		garage_id: 140,
		part_id: 106,
		quantity: 99,
	},
	{
		garage_id: 140,
		part_id: 107,
		quantity: 187,
	},
	{
		garage_id: 140,
		part_id: 108,
		quantity: 82,
	},
	{
		garage_id: 140,
		part_id: 109,
		quantity: 342,
	},
	{
		garage_id: 140,
		part_id: 110,
		quantity: 276,
	},
	{
		garage_id: 140,
		part_id: 111,
		quantity: 124,
	},
	{
		garage_id: 140,
		part_id: 112,
		quantity: 325,
	},
	{
		garage_id: 140,
		part_id: 113,
		quantity: 376,
	},
	{
		garage_id: 140,
		part_id: 114,
		quantity: 209,
	},
	{
		garage_id: 140,
		part_id: 115,
		quantity: 12,
	},
	{
		garage_id: 140,
		part_id: 116,
		quantity: 250,
	},
	{
		garage_id: 140,
		part_id: 117,
		quantity: 279,
	},
	{
		garage_id: 140,
		part_id: 118,
		quantity: 151,
	},
	{
		garage_id: 140,
		part_id: 119,
		quantity: 115,
	},
	{
		garage_id: 140,
		part_id: 120,
		quantity: 421,
	},
	{
		garage_id: 140,
		part_id: 121,
		quantity: 112,
	},
	{
		garage_id: 140,
		part_id: 122,
		quantity: 462,
	},
	{
		garage_id: 140,
		part_id: 123,
		quantity: 149,
	},
	{
		garage_id: 140,
		part_id: 124,
		quantity: 433,
	},
	{
		garage_id: 140,
		part_id: 125,
		quantity: 474,
	},
	{
		garage_id: 140,
		part_id: 126,
		quantity: 398,
	},
	{
		garage_id: 140,
		part_id: 127,
		quantity: 387,
	},
	{
		garage_id: 140,
		part_id: 128,
		quantity: 495,
	},
	{
		garage_id: 140,
		part_id: 129,
		quantity: 457,
	},
	{
		garage_id: 140,
		part_id: 130,
		quantity: 159,
	},
	{
		garage_id: 140,
		part_id: 131,
		quantity: 284,
	},
	{
		garage_id: 140,
		part_id: 132,
		quantity: 280,
	},
	{
		garage_id: 140,
		part_id: 133,
		quantity: 38,
	},
	{
		garage_id: 140,
		part_id: 134,
		quantity: 191,
	},
	{
		garage_id: 140,
		part_id: 135,
		quantity: 199,
	},
	{
		garage_id: 140,
		part_id: 136,
		quantity: 277,
	},
	{
		garage_id: 140,
		part_id: 137,
		quantity: 338,
	},
	{
		garage_id: 140,
		part_id: 138,
		quantity: 255,
	},
	{
		garage_id: 140,
		part_id: 139,
		quantity: 365,
	},
	{
		garage_id: 140,
		part_id: 140,
		quantity: 406,
	},
	{
		garage_id: 140,
		part_id: 141,
		quantity: 416,
	},
	{
		garage_id: 140,
		part_id: 142,
		quantity: 474,
	},
	{
		garage_id: 140,
		part_id: 143,
		quantity: 349,
	},
	{
		garage_id: 140,
		part_id: 144,
		quantity: 413,
	},
	{
		garage_id: 140,
		part_id: 145,
		quantity: 366,
	},
	{
		garage_id: 140,
		part_id: 146,
		quantity: 0,
	},
	{
		garage_id: 140,
		part_id: 147,
		quantity: 183,
	},
	{
		garage_id: 140,
		part_id: 148,
		quantity: 267,
	},
	{
		garage_id: 140,
		part_id: 149,
		quantity: 192,
	},
	{
		garage_id: 140,
		part_id: 150,
		quantity: 164,
	},
	{
		garage_id: 140,
		part_id: 151,
		quantity: 128,
	},
	{
		garage_id: 140,
		part_id: 152,
		quantity: 229,
	},
	{
		garage_id: 140,
		part_id: 153,
		quantity: 382,
	},
	{
		garage_id: 140,
		part_id: 154,
		quantity: 348,
	},
	{
		garage_id: 140,
		part_id: 155,
		quantity: 52,
	},
	{
		garage_id: 140,
		part_id: 156,
		quantity: 61,
	},
	{
		garage_id: 140,
		part_id: 157,
		quantity: 474,
	},
	{
		garage_id: 140,
		part_id: 158,
		quantity: 339,
	},
	{
		garage_id: 140,
		part_id: 159,
		quantity: 239,
	},
	{
		garage_id: 140,
		part_id: 160,
		quantity: 369,
	},
	{
		garage_id: 140,
		part_id: 161,
		quantity: 200,
	},
	{
		garage_id: 140,
		part_id: 162,
		quantity: 312,
	},
	{
		garage_id: 140,
		part_id: 163,
		quantity: 252,
	},
	{
		garage_id: 140,
		part_id: 164,
		quantity: 114,
	},
	{
		garage_id: 140,
		part_id: 165,
		quantity: 488,
	},
	{
		garage_id: 140,
		part_id: 166,
		quantity: 243,
	},
	{
		garage_id: 140,
		part_id: 167,
		quantity: 33,
	},
	{
		garage_id: 140,
		part_id: 168,
		quantity: 107,
	},
	{
		garage_id: 140,
		part_id: 169,
		quantity: 19,
	},
	{
		garage_id: 140,
		part_id: 170,
		quantity: 151,
	},
	{
		garage_id: 140,
		part_id: 171,
		quantity: 406,
	},
	{
		garage_id: 140,
		part_id: 172,
		quantity: 390,
	},
	{
		garage_id: 140,
		part_id: 173,
		quantity: 305,
	},
	{
		garage_id: 140,
		part_id: 174,
		quantity: 456,
	},
	{
		garage_id: 140,
		part_id: 175,
		quantity: 426,
	},
	{
		garage_id: 140,
		part_id: 176,
		quantity: 346,
	},
	{
		garage_id: 140,
		part_id: 177,
		quantity: 323,
	},
	{
		garage_id: 140,
		part_id: 178,
		quantity: 180,
	},
	{
		garage_id: 140,
		part_id: 179,
		quantity: 49,
	},
	{
		garage_id: 140,
		part_id: 180,
		quantity: 24,
	},
	{
		garage_id: 140,
		part_id: 181,
		quantity: 149,
	},
	{
		garage_id: 140,
		part_id: 182,
		quantity: 72,
	},
	{
		garage_id: 140,
		part_id: 183,
		quantity: 455,
	},
	{
		garage_id: 140,
		part_id: 184,
		quantity: 13,
	},
	{
		garage_id: 140,
		part_id: 185,
		quantity: 487,
	},
	{
		garage_id: 140,
		part_id: 186,
		quantity: 268,
	},
	{
		garage_id: 140,
		part_id: 187,
		quantity: 455,
	},
	{
		garage_id: 140,
		part_id: 188,
		quantity: 310,
	},
	{
		garage_id: 140,
		part_id: 189,
		quantity: 198,
	},
	{
		garage_id: 140,
		part_id: 190,
		quantity: 64,
	},
	{
		garage_id: 140,
		part_id: 191,
		quantity: 306,
	},
	{
		garage_id: 140,
		part_id: 192,
		quantity: 84,
	},
	{
		garage_id: 140,
		part_id: 193,
		quantity: 286,
	},
	{
		garage_id: 140,
		part_id: 194,
		quantity: 234,
	},
	{
		garage_id: 140,
		part_id: 195,
		quantity: 324,
	},
	{
		garage_id: 140,
		part_id: 196,
		quantity: 384,
	},
	{
		garage_id: 140,
		part_id: 197,
		quantity: 5,
	},
	{
		garage_id: 140,
		part_id: 198,
		quantity: 278,
	},
	{
		garage_id: 140,
		part_id: 199,
		quantity: 357,
	},
	{
		garage_id: 140,
		part_id: 200,
		quantity: 56,
	},
	{
		garage_id: 140,
		part_id: 201,
		quantity: 349,
	},
	{
		garage_id: 140,
		part_id: 202,
		quantity: 256,
	},
	{
		garage_id: 140,
		part_id: 203,
		quantity: 411,
	},
	{
		garage_id: 140,
		part_id: 204,
		quantity: 188,
	},
	{
		garage_id: 140,
		part_id: 205,
		quantity: 263,
	},
	{
		garage_id: 140,
		part_id: 206,
		quantity: 362,
	},
	{
		garage_id: 140,
		part_id: 207,
		quantity: 103,
	},
	{
		garage_id: 140,
		part_id: 208,
		quantity: 176,
	},
	{
		garage_id: 140,
		part_id: 209,
		quantity: 52,
	},
	{
		garage_id: 140,
		part_id: 210,
		quantity: 367,
	},
	{
		garage_id: 140,
		part_id: 211,
		quantity: 386,
	},
	{
		garage_id: 140,
		part_id: 212,
		quantity: 122,
	},
	{
		garage_id: 140,
		part_id: 213,
		quantity: 313,
	},
	{
		garage_id: 140,
		part_id: 214,
		quantity: 247,
	},
	{
		garage_id: 140,
		part_id: 215,
		quantity: 150,
	},
	{
		garage_id: 140,
		part_id: 216,
		quantity: 62,
	},
	{
		garage_id: 140,
		part_id: 217,
		quantity: 366,
	},
	{
		garage_id: 140,
		part_id: 218,
		quantity: 178,
	},
	{
		garage_id: 140,
		part_id: 219,
		quantity: 51,
	},
	{
		garage_id: 140,
		part_id: 220,
		quantity: 246,
	},
	{
		garage_id: 140,
		part_id: 221,
		quantity: 248,
	},
	{
		garage_id: 140,
		part_id: 222,
		quantity: 329,
	},
	{
		garage_id: 140,
		part_id: 223,
		quantity: 358,
	},
	{
		garage_id: 140,
		part_id: 224,
		quantity: 277,
	},
	{
		garage_id: 140,
		part_id: 225,
		quantity: 78,
	},
	{
		garage_id: 140,
		part_id: 226,
		quantity: 224,
	},
	{
		garage_id: 140,
		part_id: 227,
		quantity: 456,
	},
	{
		garage_id: 140,
		part_id: 228,
		quantity: 390,
	},
	{
		garage_id: 140,
		part_id: 229,
		quantity: 350,
	},
	{
		garage_id: 140,
		part_id: 230,
		quantity: 315,
	},
	{
		garage_id: 140,
		part_id: 231,
		quantity: 292,
	},
	{
		garage_id: 140,
		part_id: 232,
		quantity: 366,
	},
	{
		garage_id: 140,
		part_id: 233,
		quantity: 413,
	},
	{
		garage_id: 140,
		part_id: 234,
		quantity: 326,
	},
	{
		garage_id: 140,
		part_id: 235,
		quantity: 323,
	},
	{
		garage_id: 140,
		part_id: 236,
		quantity: 411,
	},
	{
		garage_id: 140,
		part_id: 237,
		quantity: 356,
	},
	{
		garage_id: 140,
		part_id: 238,
		quantity: 479,
	},
	{
		garage_id: 140,
		part_id: 239,
		quantity: 403,
	},
	{
		garage_id: 140,
		part_id: 240,
		quantity: 250,
	},
	{
		garage_id: 140,
		part_id: 241,
		quantity: 64,
	},
	{
		garage_id: 140,
		part_id: 242,
		quantity: 329,
	},
	{
		garage_id: 140,
		part_id: 243,
		quantity: 110,
	},
	{
		garage_id: 140,
		part_id: 244,
		quantity: 264,
	},
	{
		garage_id: 140,
		part_id: 245,
		quantity: 377,
	},
	{
		garage_id: 140,
		part_id: 246,
		quantity: 71,
	},
	{
		garage_id: 140,
		part_id: 247,
		quantity: 55,
	},
	{
		garage_id: 140,
		part_id: 248,
		quantity: 313,
	},
	{
		garage_id: 140,
		part_id: 249,
		quantity: 424,
	},
	{
		garage_id: 140,
		part_id: 250,
		quantity: 264,
	},
	{
		garage_id: 140,
		part_id: 251,
		quantity: 322,
	},
	{
		garage_id: 140,
		part_id: 252,
		quantity: 373,
	},
	{
		garage_id: 140,
		part_id: 253,
		quantity: 113,
	},
	{
		garage_id: 140,
		part_id: 254,
		quantity: 409,
	},
	{
		garage_id: 140,
		part_id: 255,
		quantity: 250,
	},
	{
		garage_id: 140,
		part_id: 256,
		quantity: 194,
	},
	{
		garage_id: 140,
		part_id: 257,
		quantity: 468,
	},
	{
		garage_id: 140,
		part_id: 258,
		quantity: 288,
	},
	{
		garage_id: 140,
		part_id: 259,
		quantity: 399,
	},
	{
		garage_id: 140,
		part_id: 260,
		quantity: 352,
	},
	{
		garage_id: 140,
		part_id: 261,
		quantity: 155,
	},
	{
		garage_id: 140,
		part_id: 262,
		quantity: 442,
	},
	{
		garage_id: 140,
		part_id: 263,
		quantity: 192,
	},
	{
		garage_id: 140,
		part_id: 264,
		quantity: 370,
	},
	{
		garage_id: 140,
		part_id: 265,
		quantity: 112,
	},
	{
		garage_id: 140,
		part_id: 266,
		quantity: 96,
	},
	{
		garage_id: 140,
		part_id: 267,
		quantity: 180,
	},
	{
		garage_id: 140,
		part_id: 268,
		quantity: 437,
	},
	{
		garage_id: 140,
		part_id: 269,
		quantity: 78,
	},
	{
		garage_id: 140,
		part_id: 270,
		quantity: 168,
	},
	{
		garage_id: 140,
		part_id: 271,
		quantity: 137,
	},
	{
		garage_id: 140,
		part_id: 272,
		quantity: 139,
	},
	{
		garage_id: 140,
		part_id: 273,
		quantity: 104,
	},
	{
		garage_id: 140,
		part_id: 274,
		quantity: 30,
	},
	{
		garage_id: 140,
		part_id: 275,
		quantity: 294,
	},
	{
		garage_id: 140,
		part_id: 276,
		quantity: 91,
	},
	{
		garage_id: 140,
		part_id: 277,
		quantity: 436,
	},
	{
		garage_id: 140,
		part_id: 278,
		quantity: 223,
	},
	{
		garage_id: 140,
		part_id: 279,
		quantity: 264,
	},
	{
		garage_id: 140,
		part_id: 280,
		quantity: 139,
	},
	{
		garage_id: 140,
		part_id: 281,
		quantity: 242,
	},
	{
		garage_id: 140,
		part_id: 282,
		quantity: 121,
	},
	{
		garage_id: 140,
		part_id: 283,
		quantity: 344,
	},
	{
		garage_id: 140,
		part_id: 284,
		quantity: 29,
	},
	{
		garage_id: 140,
		part_id: 285,
		quantity: 254,
	},
	{
		garage_id: 140,
		part_id: 286,
		quantity: 155,
	},
	{
		garage_id: 140,
		part_id: 287,
		quantity: 325,
	},
	{
		garage_id: 140,
		part_id: 288,
		quantity: 409,
	},
	{
		garage_id: 140,
		part_id: 289,
		quantity: 339,
	},
	{
		garage_id: 140,
		part_id: 290,
		quantity: 493,
	},
	{
		garage_id: 140,
		part_id: 291,
		quantity: 417,
	},
	{
		garage_id: 140,
		part_id: 292,
		quantity: 261,
	},
	{
		garage_id: 140,
		part_id: 293,
		quantity: 447,
	},
	{
		garage_id: 140,
		part_id: 294,
		quantity: 99,
	},
	{
		garage_id: 140,
		part_id: 295,
		quantity: 81,
	},
	{
		garage_id: 140,
		part_id: 296,
		quantity: 438,
	},
	{
		garage_id: 140,
		part_id: 297,
		quantity: 141,
	},
	{
		garage_id: 140,
		part_id: 298,
		quantity: 435,
	},
	{
		garage_id: 140,
		part_id: 299,
		quantity: 309,
	},
	{
		garage_id: 140,
		part_id: 300,
		quantity: 242,
	},
	{
		garage_id: 140,
		part_id: 301,
		quantity: 316,
	},
	{
		garage_id: 140,
		part_id: 302,
		quantity: 126,
	},
	{
		garage_id: 140,
		part_id: 303,
		quantity: 451,
	},
	{
		garage_id: 140,
		part_id: 304,
		quantity: 420,
	},
	{
		garage_id: 140,
		part_id: 305,
		quantity: 434,
	},
	{
		garage_id: 140,
		part_id: 306,
		quantity: 89,
	},
	{
		garage_id: 140,
		part_id: 307,
		quantity: 255,
	},
	{
		garage_id: 140,
		part_id: 308,
		quantity: 459,
	},
	{
		garage_id: 140,
		part_id: 309,
		quantity: 363,
	},
	{
		garage_id: 140,
		part_id: 310,
		quantity: 104,
	},
	{
		garage_id: 140,
		part_id: 311,
		quantity: 355,
	},
	{
		garage_id: 140,
		part_id: 312,
		quantity: 474,
	},
	{
		garage_id: 140,
		part_id: 313,
		quantity: 261,
	},
	{
		garage_id: 140,
		part_id: 314,
		quantity: 346,
	},
	{
		garage_id: 140,
		part_id: 315,
		quantity: 310,
	},
	{
		garage_id: 140,
		part_id: 316,
		quantity: 246,
	},
	{
		garage_id: 140,
		part_id: 317,
		quantity: 411,
	},
	{
		garage_id: 140,
		part_id: 318,
		quantity: 30,
	},
	{
		garage_id: 140,
		part_id: 319,
		quantity: 79,
	},
	{
		garage_id: 140,
		part_id: 320,
		quantity: 103,
	},
	{
		garage_id: 140,
		part_id: 321,
		quantity: 185,
	},
	{
		garage_id: 140,
		part_id: 322,
		quantity: 192,
	},
	{
		garage_id: 140,
		part_id: 323,
		quantity: 160,
	},
	{
		garage_id: 140,
		part_id: 324,
		quantity: 292,
	},
	{
		garage_id: 140,
		part_id: 325,
		quantity: 423,
	},
	{
		garage_id: 140,
		part_id: 326,
		quantity: 484,
	},
	{
		garage_id: 140,
		part_id: 327,
		quantity: 310,
	},
	{
		garage_id: 140,
		part_id: 328,
		quantity: 112,
	},
	{
		garage_id: 140,
		part_id: 329,
		quantity: 224,
	},
	{
		garage_id: 140,
		part_id: 330,
		quantity: 499,
	},
	{
		garage_id: 140,
		part_id: 331,
		quantity: 176,
	},
	{
		garage_id: 140,
		part_id: 332,
		quantity: 388,
	},
	{
		garage_id: 140,
		part_id: 333,
		quantity: 292,
	},
	{
		garage_id: 140,
		part_id: 334,
		quantity: 185,
	},
	{
		garage_id: 140,
		part_id: 335,
		quantity: 476,
	},
	{
		garage_id: 140,
		part_id: 336,
		quantity: 344,
	},
	{
		garage_id: 140,
		part_id: 337,
		quantity: 34,
	},
	{
		garage_id: 140,
		part_id: 338,
		quantity: 290,
	},
	{
		garage_id: 140,
		part_id: 339,
		quantity: 69,
	},
	{
		garage_id: 140,
		part_id: 340,
		quantity: 128,
	},
	{
		garage_id: 140,
		part_id: 341,
		quantity: 387,
	},
	{
		garage_id: 140,
		part_id: 342,
		quantity: 128,
	},
	{
		garage_id: 140,
		part_id: 343,
		quantity: 184,
	},
	{
		garage_id: 140,
		part_id: 344,
		quantity: 454,
	},
	{
		garage_id: 140,
		part_id: 345,
		quantity: 162,
	},
	{
		garage_id: 140,
		part_id: 346,
		quantity: 213,
	},
	{
		garage_id: 140,
		part_id: 347,
		quantity: 390,
	},
	{
		garage_id: 140,
		part_id: 348,
		quantity: 53,
	},
	{
		garage_id: 140,
		part_id: 349,
		quantity: 444,
	},
	{
		garage_id: 140,
		part_id: 350,
		quantity: 400,
	},
	{
		garage_id: 140,
		part_id: 351,
		quantity: 112,
	},
	{
		garage_id: 140,
		part_id: 352,
		quantity: 66,
	},
	{
		garage_id: 140,
		part_id: 353,
		quantity: 416,
	},
	{
		garage_id: 140,
		part_id: 354,
		quantity: 283,
	},
	{
		garage_id: 140,
		part_id: 355,
		quantity: 176,
	},
	{
		garage_id: 140,
		part_id: 356,
		quantity: 375,
	},
	{
		garage_id: 140,
		part_id: 357,
		quantity: 150,
	},
	{
		garage_id: 140,
		part_id: 358,
		quantity: 51,
	},
	{
		garage_id: 140,
		part_id: 359,
		quantity: 445,
	},
	{
		garage_id: 140,
		part_id: 360,
		quantity: 101,
	},
	{
		garage_id: 140,
		part_id: 361,
		quantity: 288,
	},
	{
		garage_id: 140,
		part_id: 362,
		quantity: 337,
	},
	{
		garage_id: 140,
		part_id: 363,
		quantity: 466,
	},
	{
		garage_id: 140,
		part_id: 364,
		quantity: 428,
	},
	{
		garage_id: 140,
		part_id: 365,
		quantity: 480,
	},
	{
		garage_id: 140,
		part_id: 366,
		quantity: 357,
	},
	{
		garage_id: 140,
		part_id: 367,
		quantity: 264,
	},
	{
		garage_id: 140,
		part_id: 368,
		quantity: 488,
	},
	{
		garage_id: 140,
		part_id: 369,
		quantity: 145,
	},
	{
		garage_id: 140,
		part_id: 370,
		quantity: 329,
	},
	{
		garage_id: 140,
		part_id: 371,
		quantity: 50,
	},
	{
		garage_id: 140,
		part_id: 372,
		quantity: 436,
	},
	{
		garage_id: 140,
		part_id: 373,
		quantity: 208,
	},
	{
		garage_id: 140,
		part_id: 374,
		quantity: 31,
	},
	{
		garage_id: 140,
		part_id: 375,
		quantity: 369,
	},
	{
		garage_id: 140,
		part_id: 376,
		quantity: 409,
	},
	{
		garage_id: 140,
		part_id: 377,
		quantity: 353,
	},
	{
		garage_id: 140,
		part_id: 378,
		quantity: 214,
	},
	{
		garage_id: 140,
		part_id: 379,
		quantity: 80,
	},
	{
		garage_id: 140,
		part_id: 380,
		quantity: 30,
	},
	{
		garage_id: 140,
		part_id: 381,
		quantity: 334,
	},
	{
		garage_id: 140,
		part_id: 382,
		quantity: 49,
	},
	{
		garage_id: 140,
		part_id: 383,
		quantity: 82,
	},
	{
		garage_id: 140,
		part_id: 384,
		quantity: 232,
	},
	{
		garage_id: 140,
		part_id: 385,
		quantity: 186,
	},
	{
		garage_id: 140,
		part_id: 386,
		quantity: 471,
	},
	{
		garage_id: 140,
		part_id: 387,
		quantity: 446,
	},
	{
		garage_id: 140,
		part_id: 388,
		quantity: 22,
	},
	{
		garage_id: 140,
		part_id: 389,
		quantity: 160,
	},
	{
		garage_id: 140,
		part_id: 390,
		quantity: 488,
	},
	{
		garage_id: 140,
		part_id: 391,
		quantity: 144,
	},
	{
		garage_id: 140,
		part_id: 392,
		quantity: 234,
	},
	{
		garage_id: 140,
		part_id: 393,
		quantity: 264,
	},
	{
		garage_id: 140,
		part_id: 394,
		quantity: 12,
	},
	{
		garage_id: 140,
		part_id: 395,
		quantity: 111,
	},
	{
		garage_id: 140,
		part_id: 396,
		quantity: 259,
	},
	{
		garage_id: 140,
		part_id: 397,
		quantity: 253,
	},
	{
		garage_id: 140,
		part_id: 398,
		quantity: 383,
	},
	{
		garage_id: 140,
		part_id: 399,
		quantity: 158,
	},
	{
		garage_id: 141,
		part_id: 0,
		quantity: 108,
	},
	{
		garage_id: 141,
		part_id: 1,
		quantity: 105,
	},
	{
		garage_id: 141,
		part_id: 2,
		quantity: 311,
	},
	{
		garage_id: 141,
		part_id: 3,
		quantity: 171,
	},
	{
		garage_id: 141,
		part_id: 4,
		quantity: 233,
	},
	{
		garage_id: 141,
		part_id: 5,
		quantity: 2,
	},
	{
		garage_id: 141,
		part_id: 6,
		quantity: 205,
	},
	{
		garage_id: 141,
		part_id: 7,
		quantity: 257,
	},
	{
		garage_id: 141,
		part_id: 8,
		quantity: 23,
	},
	{
		garage_id: 141,
		part_id: 9,
		quantity: 433,
	},
	{
		garage_id: 141,
		part_id: 10,
		quantity: 134,
	},
	{
		garage_id: 141,
		part_id: 11,
		quantity: 153,
	},
	{
		garage_id: 141,
		part_id: 12,
		quantity: 5,
	},
	{
		garage_id: 141,
		part_id: 13,
		quantity: 455,
	},
	{
		garage_id: 141,
		part_id: 14,
		quantity: 321,
	},
	{
		garage_id: 141,
		part_id: 15,
		quantity: 469,
	},
	{
		garage_id: 141,
		part_id: 16,
		quantity: 132,
	},
	{
		garage_id: 141,
		part_id: 17,
		quantity: 207,
	},
	{
		garage_id: 141,
		part_id: 18,
		quantity: 307,
	},
	{
		garage_id: 141,
		part_id: 19,
		quantity: 392,
	},
	{
		garage_id: 141,
		part_id: 20,
		quantity: 36,
	},
	{
		garage_id: 141,
		part_id: 21,
		quantity: 102,
	},
	{
		garage_id: 141,
		part_id: 22,
		quantity: 46,
	},
	{
		garage_id: 141,
		part_id: 23,
		quantity: 297,
	},
	{
		garage_id: 141,
		part_id: 24,
		quantity: 45,
	},
	{
		garage_id: 141,
		part_id: 25,
		quantity: 328,
	},
	{
		garage_id: 141,
		part_id: 26,
		quantity: 47,
	},
	{
		garage_id: 141,
		part_id: 27,
		quantity: 172,
	},
	{
		garage_id: 141,
		part_id: 28,
		quantity: 29,
	},
	{
		garage_id: 141,
		part_id: 29,
		quantity: 448,
	},
	{
		garage_id: 141,
		part_id: 30,
		quantity: 95,
	},
	{
		garage_id: 141,
		part_id: 31,
		quantity: 170,
	},
	{
		garage_id: 141,
		part_id: 32,
		quantity: 73,
	},
	{
		garage_id: 141,
		part_id: 33,
		quantity: 57,
	},
	{
		garage_id: 141,
		part_id: 34,
		quantity: 312,
	},
	{
		garage_id: 141,
		part_id: 35,
		quantity: 36,
	},
	{
		garage_id: 141,
		part_id: 36,
		quantity: 148,
	},
	{
		garage_id: 141,
		part_id: 37,
		quantity: 250,
	},
	{
		garage_id: 141,
		part_id: 38,
		quantity: 408,
	},
	{
		garage_id: 141,
		part_id: 39,
		quantity: 442,
	},
	{
		garage_id: 141,
		part_id: 40,
		quantity: 148,
	},
	{
		garage_id: 141,
		part_id: 41,
		quantity: 93,
	},
	{
		garage_id: 141,
		part_id: 42,
		quantity: 396,
	},
	{
		garage_id: 141,
		part_id: 43,
		quantity: 425,
	},
	{
		garage_id: 141,
		part_id: 44,
		quantity: 380,
	},
	{
		garage_id: 141,
		part_id: 45,
		quantity: 364,
	},
	{
		garage_id: 141,
		part_id: 46,
		quantity: 330,
	},
	{
		garage_id: 141,
		part_id: 47,
		quantity: 60,
	},
	{
		garage_id: 141,
		part_id: 48,
		quantity: 128,
	},
	{
		garage_id: 141,
		part_id: 49,
		quantity: 235,
	},
	{
		garage_id: 141,
		part_id: 50,
		quantity: 402,
	},
	{
		garage_id: 141,
		part_id: 51,
		quantity: 109,
	},
	{
		garage_id: 141,
		part_id: 52,
		quantity: 500,
	},
	{
		garage_id: 141,
		part_id: 53,
		quantity: 485,
	},
	{
		garage_id: 141,
		part_id: 54,
		quantity: 41,
	},
	{
		garage_id: 141,
		part_id: 55,
		quantity: 313,
	},
	{
		garage_id: 141,
		part_id: 56,
		quantity: 325,
	},
	{
		garage_id: 141,
		part_id: 57,
		quantity: 370,
	},
	{
		garage_id: 141,
		part_id: 58,
		quantity: 211,
	},
	{
		garage_id: 141,
		part_id: 59,
		quantity: 315,
	},
	{
		garage_id: 141,
		part_id: 60,
		quantity: 136,
	},
	{
		garage_id: 141,
		part_id: 61,
		quantity: 166,
	},
	{
		garage_id: 141,
		part_id: 62,
		quantity: 232,
	},
	{
		garage_id: 141,
		part_id: 63,
		quantity: 18,
	},
	{
		garage_id: 141,
		part_id: 64,
		quantity: 348,
	},
	{
		garage_id: 141,
		part_id: 65,
		quantity: 163,
	},
	{
		garage_id: 141,
		part_id: 66,
		quantity: 347,
	},
	{
		garage_id: 141,
		part_id: 67,
		quantity: 408,
	},
	{
		garage_id: 141,
		part_id: 68,
		quantity: 448,
	},
	{
		garage_id: 141,
		part_id: 69,
		quantity: 133,
	},
	{
		garage_id: 141,
		part_id: 70,
		quantity: 407,
	},
	{
		garage_id: 141,
		part_id: 71,
		quantity: 470,
	},
	{
		garage_id: 141,
		part_id: 72,
		quantity: 339,
	},
	{
		garage_id: 141,
		part_id: 73,
		quantity: 239,
	},
	{
		garage_id: 141,
		part_id: 74,
		quantity: 104,
	},
	{
		garage_id: 141,
		part_id: 75,
		quantity: 500,
	},
	{
		garage_id: 141,
		part_id: 76,
		quantity: 217,
	},
	{
		garage_id: 141,
		part_id: 77,
		quantity: 387,
	},
	{
		garage_id: 141,
		part_id: 78,
		quantity: 186,
	},
	{
		garage_id: 141,
		part_id: 79,
		quantity: 101,
	},
	{
		garage_id: 141,
		part_id: 80,
		quantity: 23,
	},
	{
		garage_id: 141,
		part_id: 81,
		quantity: 175,
	},
	{
		garage_id: 141,
		part_id: 82,
		quantity: 194,
	},
	{
		garage_id: 141,
		part_id: 83,
		quantity: 350,
	},
	{
		garage_id: 141,
		part_id: 84,
		quantity: 363,
	},
	{
		garage_id: 141,
		part_id: 85,
		quantity: 305,
	},
	{
		garage_id: 141,
		part_id: 86,
		quantity: 83,
	},
	{
		garage_id: 141,
		part_id: 87,
		quantity: 398,
	},
	{
		garage_id: 141,
		part_id: 88,
		quantity: 118,
	},
	{
		garage_id: 141,
		part_id: 89,
		quantity: 447,
	},
	{
		garage_id: 141,
		part_id: 90,
		quantity: 154,
	},
	{
		garage_id: 141,
		part_id: 91,
		quantity: 438,
	},
	{
		garage_id: 141,
		part_id: 92,
		quantity: 89,
	},
	{
		garage_id: 141,
		part_id: 93,
		quantity: 385,
	},
	{
		garage_id: 141,
		part_id: 94,
		quantity: 346,
	},
	{
		garage_id: 141,
		part_id: 95,
		quantity: 371,
	},
	{
		garage_id: 141,
		part_id: 96,
		quantity: 195,
	},
	{
		garage_id: 141,
		part_id: 97,
		quantity: 335,
	},
	{
		garage_id: 141,
		part_id: 98,
		quantity: 206,
	},
	{
		garage_id: 141,
		part_id: 99,
		quantity: 210,
	},
	{
		garage_id: 141,
		part_id: 100,
		quantity: 192,
	},
	{
		garage_id: 141,
		part_id: 101,
		quantity: 372,
	},
	{
		garage_id: 141,
		part_id: 102,
		quantity: 45,
	},
	{
		garage_id: 141,
		part_id: 103,
		quantity: 300,
	},
	{
		garage_id: 141,
		part_id: 104,
		quantity: 409,
	},
	{
		garage_id: 141,
		part_id: 105,
		quantity: 181,
	},
	{
		garage_id: 141,
		part_id: 106,
		quantity: 64,
	},
	{
		garage_id: 141,
		part_id: 107,
		quantity: 263,
	},
	{
		garage_id: 141,
		part_id: 108,
		quantity: 2,
	},
	{
		garage_id: 141,
		part_id: 109,
		quantity: 72,
	},
	{
		garage_id: 141,
		part_id: 110,
		quantity: 430,
	},
	{
		garage_id: 141,
		part_id: 111,
		quantity: 145,
	},
	{
		garage_id: 141,
		part_id: 112,
		quantity: 337,
	},
	{
		garage_id: 141,
		part_id: 113,
		quantity: 210,
	},
	{
		garage_id: 141,
		part_id: 114,
		quantity: 51,
	},
	{
		garage_id: 141,
		part_id: 115,
		quantity: 85,
	},
	{
		garage_id: 141,
		part_id: 116,
		quantity: 173,
	},
	{
		garage_id: 141,
		part_id: 117,
		quantity: 411,
	},
	{
		garage_id: 141,
		part_id: 118,
		quantity: 355,
	},
	{
		garage_id: 141,
		part_id: 119,
		quantity: 448,
	},
	{
		garage_id: 141,
		part_id: 120,
		quantity: 330,
	},
	{
		garage_id: 141,
		part_id: 121,
		quantity: 488,
	},
	{
		garage_id: 141,
		part_id: 122,
		quantity: 258,
	},
	{
		garage_id: 141,
		part_id: 123,
		quantity: 169,
	},
	{
		garage_id: 141,
		part_id: 124,
		quantity: 194,
	},
	{
		garage_id: 141,
		part_id: 125,
		quantity: 356,
	},
	{
		garage_id: 141,
		part_id: 126,
		quantity: 192,
	},
	{
		garage_id: 141,
		part_id: 127,
		quantity: 54,
	},
	{
		garage_id: 141,
		part_id: 128,
		quantity: 340,
	},
	{
		garage_id: 141,
		part_id: 129,
		quantity: 159,
	},
	{
		garage_id: 141,
		part_id: 130,
		quantity: 330,
	},
	{
		garage_id: 141,
		part_id: 131,
		quantity: 205,
	},
	{
		garage_id: 141,
		part_id: 132,
		quantity: 305,
	},
	{
		garage_id: 141,
		part_id: 133,
		quantity: 332,
	},
	{
		garage_id: 141,
		part_id: 134,
		quantity: 491,
	},
	{
		garage_id: 141,
		part_id: 135,
		quantity: 303,
	},
	{
		garage_id: 141,
		part_id: 136,
		quantity: 158,
	},
	{
		garage_id: 141,
		part_id: 137,
		quantity: 148,
	},
	{
		garage_id: 141,
		part_id: 138,
		quantity: 146,
	},
	{
		garage_id: 141,
		part_id: 139,
		quantity: 500,
	},
	{
		garage_id: 141,
		part_id: 140,
		quantity: 84,
	},
	{
		garage_id: 141,
		part_id: 141,
		quantity: 358,
	},
	{
		garage_id: 141,
		part_id: 142,
		quantity: 100,
	},
	{
		garage_id: 141,
		part_id: 143,
		quantity: 491,
	},
	{
		garage_id: 141,
		part_id: 144,
		quantity: 180,
	},
	{
		garage_id: 141,
		part_id: 145,
		quantity: 298,
	},
	{
		garage_id: 141,
		part_id: 146,
		quantity: 335,
	},
	{
		garage_id: 141,
		part_id: 147,
		quantity: 422,
	},
	{
		garage_id: 141,
		part_id: 148,
		quantity: 386,
	},
	{
		garage_id: 141,
		part_id: 149,
		quantity: 440,
	},
	{
		garage_id: 141,
		part_id: 150,
		quantity: 188,
	},
	{
		garage_id: 141,
		part_id: 151,
		quantity: 126,
	},
	{
		garage_id: 141,
		part_id: 152,
		quantity: 195,
	},
	{
		garage_id: 141,
		part_id: 153,
		quantity: 457,
	},
	{
		garage_id: 141,
		part_id: 154,
		quantity: 356,
	},
	{
		garage_id: 141,
		part_id: 155,
		quantity: 230,
	},
	{
		garage_id: 141,
		part_id: 156,
		quantity: 337,
	},
	{
		garage_id: 141,
		part_id: 157,
		quantity: 306,
	},
	{
		garage_id: 141,
		part_id: 158,
		quantity: 206,
	},
	{
		garage_id: 141,
		part_id: 159,
		quantity: 161,
	},
	{
		garage_id: 141,
		part_id: 160,
		quantity: 132,
	},
	{
		garage_id: 141,
		part_id: 161,
		quantity: 30,
	},
	{
		garage_id: 141,
		part_id: 162,
		quantity: 371,
	},
	{
		garage_id: 141,
		part_id: 163,
		quantity: 469,
	},
	{
		garage_id: 141,
		part_id: 164,
		quantity: 269,
	},
	{
		garage_id: 141,
		part_id: 165,
		quantity: 370,
	},
	{
		garage_id: 141,
		part_id: 166,
		quantity: 169,
	},
	{
		garage_id: 141,
		part_id: 167,
		quantity: 405,
	},
	{
		garage_id: 141,
		part_id: 168,
		quantity: 44,
	},
	{
		garage_id: 141,
		part_id: 169,
		quantity: 174,
	},
	{
		garage_id: 141,
		part_id: 170,
		quantity: 161,
	},
	{
		garage_id: 141,
		part_id: 171,
		quantity: 388,
	},
	{
		garage_id: 141,
		part_id: 172,
		quantity: 445,
	},
	{
		garage_id: 141,
		part_id: 173,
		quantity: 416,
	},
	{
		garage_id: 141,
		part_id: 174,
		quantity: 181,
	},
	{
		garage_id: 141,
		part_id: 175,
		quantity: 209,
	},
	{
		garage_id: 141,
		part_id: 176,
		quantity: 435,
	},
	{
		garage_id: 141,
		part_id: 177,
		quantity: 418,
	},
	{
		garage_id: 141,
		part_id: 178,
		quantity: 467,
	},
	{
		garage_id: 141,
		part_id: 179,
		quantity: 496,
	},
	{
		garage_id: 141,
		part_id: 180,
		quantity: 189,
	},
	{
		garage_id: 141,
		part_id: 181,
		quantity: 82,
	},
	{
		garage_id: 141,
		part_id: 182,
		quantity: 342,
	},
	{
		garage_id: 141,
		part_id: 183,
		quantity: 431,
	},
	{
		garage_id: 141,
		part_id: 184,
		quantity: 387,
	},
	{
		garage_id: 141,
		part_id: 185,
		quantity: 3,
	},
	{
		garage_id: 141,
		part_id: 186,
		quantity: 341,
	},
	{
		garage_id: 141,
		part_id: 187,
		quantity: 411,
	},
	{
		garage_id: 141,
		part_id: 188,
		quantity: 422,
	},
	{
		garage_id: 141,
		part_id: 189,
		quantity: 18,
	},
	{
		garage_id: 141,
		part_id: 190,
		quantity: 275,
	},
	{
		garage_id: 141,
		part_id: 191,
		quantity: 137,
	},
	{
		garage_id: 141,
		part_id: 192,
		quantity: 480,
	},
	{
		garage_id: 141,
		part_id: 193,
		quantity: 200,
	},
	{
		garage_id: 141,
		part_id: 194,
		quantity: 256,
	},
	{
		garage_id: 141,
		part_id: 195,
		quantity: 377,
	},
	{
		garage_id: 141,
		part_id: 196,
		quantity: 447,
	},
	{
		garage_id: 141,
		part_id: 197,
		quantity: 488,
	},
	{
		garage_id: 141,
		part_id: 198,
		quantity: 267,
	},
	{
		garage_id: 141,
		part_id: 199,
		quantity: 405,
	},
	{
		garage_id: 141,
		part_id: 200,
		quantity: 381,
	},
	{
		garage_id: 141,
		part_id: 201,
		quantity: 490,
	},
	{
		garage_id: 141,
		part_id: 202,
		quantity: 406,
	},
	{
		garage_id: 141,
		part_id: 203,
		quantity: 266,
	},
	{
		garage_id: 141,
		part_id: 204,
		quantity: 278,
	},
	{
		garage_id: 141,
		part_id: 205,
		quantity: 216,
	},
	{
		garage_id: 141,
		part_id: 206,
		quantity: 482,
	},
	{
		garage_id: 141,
		part_id: 207,
		quantity: 251,
	},
	{
		garage_id: 141,
		part_id: 208,
		quantity: 50,
	},
	{
		garage_id: 141,
		part_id: 209,
		quantity: 370,
	},
	{
		garage_id: 141,
		part_id: 210,
		quantity: 337,
	},
	{
		garage_id: 141,
		part_id: 211,
		quantity: 117,
	},
	{
		garage_id: 141,
		part_id: 212,
		quantity: 491,
	},
	{
		garage_id: 141,
		part_id: 213,
		quantity: 282,
	},
	{
		garage_id: 141,
		part_id: 214,
		quantity: 441,
	},
	{
		garage_id: 141,
		part_id: 215,
		quantity: 22,
	},
	{
		garage_id: 141,
		part_id: 216,
		quantity: 258,
	},
	{
		garage_id: 141,
		part_id: 217,
		quantity: 321,
	},
	{
		garage_id: 141,
		part_id: 218,
		quantity: 2,
	},
	{
		garage_id: 141,
		part_id: 219,
		quantity: 266,
	},
	{
		garage_id: 141,
		part_id: 220,
		quantity: 86,
	},
	{
		garage_id: 141,
		part_id: 221,
		quantity: 113,
	},
	{
		garage_id: 141,
		part_id: 222,
		quantity: 279,
	},
	{
		garage_id: 141,
		part_id: 223,
		quantity: 348,
	},
	{
		garage_id: 141,
		part_id: 224,
		quantity: 125,
	},
	{
		garage_id: 141,
		part_id: 225,
		quantity: 367,
	},
	{
		garage_id: 141,
		part_id: 226,
		quantity: 498,
	},
	{
		garage_id: 141,
		part_id: 227,
		quantity: 337,
	},
	{
		garage_id: 141,
		part_id: 228,
		quantity: 145,
	},
	{
		garage_id: 141,
		part_id: 229,
		quantity: 17,
	},
	{
		garage_id: 141,
		part_id: 230,
		quantity: 485,
	},
	{
		garage_id: 141,
		part_id: 231,
		quantity: 258,
	},
	{
		garage_id: 141,
		part_id: 232,
		quantity: 366,
	},
	{
		garage_id: 141,
		part_id: 233,
		quantity: 482,
	},
	{
		garage_id: 141,
		part_id: 234,
		quantity: 367,
	},
	{
		garage_id: 141,
		part_id: 235,
		quantity: 405,
	},
	{
		garage_id: 141,
		part_id: 236,
		quantity: 335,
	},
	{
		garage_id: 141,
		part_id: 237,
		quantity: 481,
	},
	{
		garage_id: 141,
		part_id: 238,
		quantity: 466,
	},
	{
		garage_id: 141,
		part_id: 239,
		quantity: 407,
	},
	{
		garage_id: 141,
		part_id: 240,
		quantity: 271,
	},
	{
		garage_id: 141,
		part_id: 241,
		quantity: 496,
	},
	{
		garage_id: 141,
		part_id: 242,
		quantity: 369,
	},
	{
		garage_id: 141,
		part_id: 243,
		quantity: 485,
	},
	{
		garage_id: 141,
		part_id: 244,
		quantity: 245,
	},
	{
		garage_id: 141,
		part_id: 245,
		quantity: 203,
	},
	{
		garage_id: 141,
		part_id: 246,
		quantity: 324,
	},
	{
		garage_id: 141,
		part_id: 247,
		quantity: 448,
	},
	{
		garage_id: 141,
		part_id: 248,
		quantity: 41,
	},
	{
		garage_id: 141,
		part_id: 249,
		quantity: 90,
	},
	{
		garage_id: 141,
		part_id: 250,
		quantity: 153,
	},
	{
		garage_id: 141,
		part_id: 251,
		quantity: 493,
	},
	{
		garage_id: 141,
		part_id: 252,
		quantity: 150,
	},
	{
		garage_id: 141,
		part_id: 253,
		quantity: 124,
	},
	{
		garage_id: 141,
		part_id: 254,
		quantity: 382,
	},
	{
		garage_id: 141,
		part_id: 255,
		quantity: 125,
	},
	{
		garage_id: 141,
		part_id: 256,
		quantity: 26,
	},
	{
		garage_id: 141,
		part_id: 257,
		quantity: 105,
	},
	{
		garage_id: 141,
		part_id: 258,
		quantity: 365,
	},
	{
		garage_id: 141,
		part_id: 259,
		quantity: 42,
	},
	{
		garage_id: 141,
		part_id: 260,
		quantity: 479,
	},
	{
		garage_id: 141,
		part_id: 261,
		quantity: 307,
	},
	{
		garage_id: 141,
		part_id: 262,
		quantity: 286,
	},
	{
		garage_id: 141,
		part_id: 263,
		quantity: 471,
	},
	{
		garage_id: 141,
		part_id: 264,
		quantity: 481,
	},
	{
		garage_id: 141,
		part_id: 265,
		quantity: 407,
	},
	{
		garage_id: 141,
		part_id: 266,
		quantity: 97,
	},
	{
		garage_id: 141,
		part_id: 267,
		quantity: 230,
	},
	{
		garage_id: 141,
		part_id: 268,
		quantity: 379,
	},
	{
		garage_id: 141,
		part_id: 269,
		quantity: 197,
	},
	{
		garage_id: 141,
		part_id: 270,
		quantity: 33,
	},
	{
		garage_id: 141,
		part_id: 271,
		quantity: 239,
	},
	{
		garage_id: 141,
		part_id: 272,
		quantity: 133,
	},
	{
		garage_id: 141,
		part_id: 273,
		quantity: 96,
	},
	{
		garage_id: 141,
		part_id: 274,
		quantity: 182,
	},
	{
		garage_id: 141,
		part_id: 275,
		quantity: 273,
	},
	{
		garage_id: 141,
		part_id: 276,
		quantity: 55,
	},
	{
		garage_id: 141,
		part_id: 277,
		quantity: 185,
	},
	{
		garage_id: 141,
		part_id: 278,
		quantity: 231,
	},
	{
		garage_id: 141,
		part_id: 279,
		quantity: 254,
	},
	{
		garage_id: 141,
		part_id: 280,
		quantity: 414,
	},
	{
		garage_id: 141,
		part_id: 281,
		quantity: 279,
	},
	{
		garage_id: 141,
		part_id: 282,
		quantity: 141,
	},
	{
		garage_id: 141,
		part_id: 283,
		quantity: 176,
	},
	{
		garage_id: 141,
		part_id: 284,
		quantity: 321,
	},
	{
		garage_id: 141,
		part_id: 285,
		quantity: 166,
	},
	{
		garage_id: 141,
		part_id: 286,
		quantity: 99,
	},
	{
		garage_id: 141,
		part_id: 287,
		quantity: 452,
	},
	{
		garage_id: 141,
		part_id: 288,
		quantity: 278,
	},
	{
		garage_id: 141,
		part_id: 289,
		quantity: 375,
	},
	{
		garage_id: 141,
		part_id: 290,
		quantity: 351,
	},
	{
		garage_id: 141,
		part_id: 291,
		quantity: 56,
	},
	{
		garage_id: 141,
		part_id: 292,
		quantity: 303,
	},
	{
		garage_id: 141,
		part_id: 293,
		quantity: 291,
	},
	{
		garage_id: 141,
		part_id: 294,
		quantity: 152,
	},
	{
		garage_id: 141,
		part_id: 295,
		quantity: 489,
	},
	{
		garage_id: 141,
		part_id: 296,
		quantity: 160,
	},
	{
		garage_id: 141,
		part_id: 297,
		quantity: 388,
	},
	{
		garage_id: 141,
		part_id: 298,
		quantity: 350,
	},
	{
		garage_id: 141,
		part_id: 299,
		quantity: 247,
	},
	{
		garage_id: 141,
		part_id: 300,
		quantity: 373,
	},
	{
		garage_id: 141,
		part_id: 301,
		quantity: 380,
	},
	{
		garage_id: 141,
		part_id: 302,
		quantity: 203,
	},
	{
		garage_id: 141,
		part_id: 303,
		quantity: 411,
	},
	{
		garage_id: 141,
		part_id: 304,
		quantity: 293,
	},
	{
		garage_id: 141,
		part_id: 305,
		quantity: 364,
	},
	{
		garage_id: 141,
		part_id: 306,
		quantity: 56,
	},
	{
		garage_id: 141,
		part_id: 307,
		quantity: 439,
	},
	{
		garage_id: 141,
		part_id: 308,
		quantity: 480,
	},
	{
		garage_id: 141,
		part_id: 309,
		quantity: 374,
	},
	{
		garage_id: 141,
		part_id: 310,
		quantity: 51,
	},
	{
		garage_id: 141,
		part_id: 311,
		quantity: 421,
	},
	{
		garage_id: 141,
		part_id: 312,
		quantity: 41,
	},
	{
		garage_id: 141,
		part_id: 313,
		quantity: 176,
	},
	{
		garage_id: 141,
		part_id: 314,
		quantity: 240,
	},
	{
		garage_id: 141,
		part_id: 315,
		quantity: 315,
	},
	{
		garage_id: 141,
		part_id: 316,
		quantity: 345,
	},
	{
		garage_id: 141,
		part_id: 317,
		quantity: 199,
	},
	{
		garage_id: 141,
		part_id: 318,
		quantity: 416,
	},
	{
		garage_id: 141,
		part_id: 319,
		quantity: 77,
	},
	{
		garage_id: 141,
		part_id: 320,
		quantity: 468,
	},
	{
		garage_id: 141,
		part_id: 321,
		quantity: 112,
	},
	{
		garage_id: 141,
		part_id: 322,
		quantity: 470,
	},
	{
		garage_id: 141,
		part_id: 323,
		quantity: 171,
	},
	{
		garage_id: 141,
		part_id: 324,
		quantity: 27,
	},
	{
		garage_id: 141,
		part_id: 325,
		quantity: 40,
	},
	{
		garage_id: 141,
		part_id: 326,
		quantity: 122,
	},
	{
		garage_id: 141,
		part_id: 327,
		quantity: 272,
	},
	{
		garage_id: 141,
		part_id: 328,
		quantity: 30,
	},
	{
		garage_id: 141,
		part_id: 329,
		quantity: 118,
	},
	{
		garage_id: 141,
		part_id: 330,
		quantity: 319,
	},
	{
		garage_id: 141,
		part_id: 331,
		quantity: 24,
	},
	{
		garage_id: 141,
		part_id: 332,
		quantity: 352,
	},
	{
		garage_id: 141,
		part_id: 333,
		quantity: 208,
	},
	{
		garage_id: 141,
		part_id: 334,
		quantity: 428,
	},
	{
		garage_id: 141,
		part_id: 335,
		quantity: 264,
	},
	{
		garage_id: 141,
		part_id: 336,
		quantity: 209,
	},
	{
		garage_id: 141,
		part_id: 337,
		quantity: 89,
	},
	{
		garage_id: 141,
		part_id: 338,
		quantity: 157,
	},
	{
		garage_id: 141,
		part_id: 339,
		quantity: 343,
	},
	{
		garage_id: 141,
		part_id: 340,
		quantity: 188,
	},
	{
		garage_id: 141,
		part_id: 341,
		quantity: 298,
	},
	{
		garage_id: 141,
		part_id: 342,
		quantity: 454,
	},
	{
		garage_id: 141,
		part_id: 343,
		quantity: 89,
	},
	{
		garage_id: 141,
		part_id: 344,
		quantity: 277,
	},
	{
		garage_id: 141,
		part_id: 345,
		quantity: 153,
	},
	{
		garage_id: 141,
		part_id: 346,
		quantity: 227,
	},
	{
		garage_id: 141,
		part_id: 347,
		quantity: 443,
	},
	{
		garage_id: 141,
		part_id: 348,
		quantity: 120,
	},
	{
		garage_id: 141,
		part_id: 349,
		quantity: 320,
	},
	{
		garage_id: 141,
		part_id: 350,
		quantity: 383,
	},
	{
		garage_id: 141,
		part_id: 351,
		quantity: 56,
	},
	{
		garage_id: 141,
		part_id: 352,
		quantity: 118,
	},
	{
		garage_id: 141,
		part_id: 353,
		quantity: 397,
	},
	{
		garage_id: 141,
		part_id: 354,
		quantity: 143,
	},
	{
		garage_id: 141,
		part_id: 355,
		quantity: 356,
	},
	{
		garage_id: 141,
		part_id: 356,
		quantity: 415,
	},
	{
		garage_id: 141,
		part_id: 357,
		quantity: 324,
	},
	{
		garage_id: 141,
		part_id: 358,
		quantity: 440,
	},
	{
		garage_id: 141,
		part_id: 359,
		quantity: 152,
	},
	{
		garage_id: 141,
		part_id: 360,
		quantity: 194,
	},
	{
		garage_id: 141,
		part_id: 361,
		quantity: 33,
	},
	{
		garage_id: 141,
		part_id: 362,
		quantity: 141,
	},
	{
		garage_id: 141,
		part_id: 363,
		quantity: 4,
	},
	{
		garage_id: 141,
		part_id: 364,
		quantity: 251,
	},
	{
		garage_id: 141,
		part_id: 365,
		quantity: 273,
	},
	{
		garage_id: 141,
		part_id: 366,
		quantity: 426,
	},
	{
		garage_id: 141,
		part_id: 367,
		quantity: 210,
	},
	{
		garage_id: 141,
		part_id: 368,
		quantity: 244,
	},
	{
		garage_id: 141,
		part_id: 369,
		quantity: 381,
	},
	{
		garage_id: 141,
		part_id: 370,
		quantity: 381,
	},
	{
		garage_id: 141,
		part_id: 371,
		quantity: 344,
	},
	{
		garage_id: 141,
		part_id: 372,
		quantity: 456,
	},
	{
		garage_id: 141,
		part_id: 373,
		quantity: 81,
	},
	{
		garage_id: 141,
		part_id: 374,
		quantity: 194,
	},
	{
		garage_id: 141,
		part_id: 375,
		quantity: 246,
	},
	{
		garage_id: 141,
		part_id: 376,
		quantity: 15,
	},
	{
		garage_id: 141,
		part_id: 377,
		quantity: 399,
	},
	{
		garage_id: 141,
		part_id: 378,
		quantity: 327,
	},
	{
		garage_id: 141,
		part_id: 379,
		quantity: 412,
	},
	{
		garage_id: 141,
		part_id: 380,
		quantity: 425,
	},
	{
		garage_id: 141,
		part_id: 381,
		quantity: 399,
	},
	{
		garage_id: 141,
		part_id: 382,
		quantity: 330,
	},
	{
		garage_id: 141,
		part_id: 383,
		quantity: 246,
	},
	{
		garage_id: 141,
		part_id: 384,
		quantity: 190,
	},
	{
		garage_id: 141,
		part_id: 385,
		quantity: 172,
	},
	{
		garage_id: 141,
		part_id: 386,
		quantity: 28,
	},
	{
		garage_id: 141,
		part_id: 387,
		quantity: 339,
	},
	{
		garage_id: 141,
		part_id: 388,
		quantity: 330,
	},
	{
		garage_id: 141,
		part_id: 389,
		quantity: 58,
	},
	{
		garage_id: 141,
		part_id: 390,
		quantity: 11,
	},
	{
		garage_id: 141,
		part_id: 391,
		quantity: 189,
	},
	{
		garage_id: 141,
		part_id: 392,
		quantity: 152,
	},
	{
		garage_id: 141,
		part_id: 393,
		quantity: 53,
	},
	{
		garage_id: 141,
		part_id: 394,
		quantity: 334,
	},
	{
		garage_id: 141,
		part_id: 395,
		quantity: 415,
	},
	{
		garage_id: 141,
		part_id: 396,
		quantity: 43,
	},
	{
		garage_id: 141,
		part_id: 397,
		quantity: 370,
	},
	{
		garage_id: 141,
		part_id: 398,
		quantity: 478,
	},
	{
		garage_id: 141,
		part_id: 399,
		quantity: 5,
	},
	{
		garage_id: 142,
		part_id: 0,
		quantity: 147,
	},
	{
		garage_id: 142,
		part_id: 1,
		quantity: 439,
	},
	{
		garage_id: 142,
		part_id: 2,
		quantity: 291,
	},
	{
		garage_id: 142,
		part_id: 3,
		quantity: 335,
	},
	{
		garage_id: 142,
		part_id: 4,
		quantity: 375,
	},
	{
		garage_id: 142,
		part_id: 5,
		quantity: 30,
	},
	{
		garage_id: 142,
		part_id: 6,
		quantity: 105,
	},
	{
		garage_id: 142,
		part_id: 7,
		quantity: 295,
	},
	{
		garage_id: 142,
		part_id: 8,
		quantity: 355,
	},
	{
		garage_id: 142,
		part_id: 9,
		quantity: 128,
	},
	{
		garage_id: 142,
		part_id: 10,
		quantity: 199,
	},
	{
		garage_id: 142,
		part_id: 11,
		quantity: 326,
	},
	{
		garage_id: 142,
		part_id: 12,
		quantity: 23,
	},
	{
		garage_id: 142,
		part_id: 13,
		quantity: 163,
	},
	{
		garage_id: 142,
		part_id: 14,
		quantity: 246,
	},
	{
		garage_id: 142,
		part_id: 15,
		quantity: 95,
	},
	{
		garage_id: 142,
		part_id: 16,
		quantity: 346,
	},
	{
		garage_id: 142,
		part_id: 17,
		quantity: 326,
	},
	{
		garage_id: 142,
		part_id: 18,
		quantity: 301,
	},
	{
		garage_id: 142,
		part_id: 19,
		quantity: 141,
	},
	{
		garage_id: 142,
		part_id: 20,
		quantity: 462,
	},
	{
		garage_id: 142,
		part_id: 21,
		quantity: 447,
	},
	{
		garage_id: 142,
		part_id: 22,
		quantity: 6,
	},
	{
		garage_id: 142,
		part_id: 23,
		quantity: 1,
	},
	{
		garage_id: 142,
		part_id: 24,
		quantity: 54,
	},
	{
		garage_id: 142,
		part_id: 25,
		quantity: 70,
	},
	{
		garage_id: 142,
		part_id: 26,
		quantity: 465,
	},
	{
		garage_id: 142,
		part_id: 27,
		quantity: 412,
	},
	{
		garage_id: 142,
		part_id: 28,
		quantity: 171,
	},
	{
		garage_id: 142,
		part_id: 29,
		quantity: 137,
	},
	{
		garage_id: 142,
		part_id: 30,
		quantity: 440,
	},
	{
		garage_id: 142,
		part_id: 31,
		quantity: 114,
	},
	{
		garage_id: 142,
		part_id: 32,
		quantity: 258,
	},
	{
		garage_id: 142,
		part_id: 33,
		quantity: 197,
	},
	{
		garage_id: 142,
		part_id: 34,
		quantity: 186,
	},
	{
		garage_id: 142,
		part_id: 35,
		quantity: 185,
	},
	{
		garage_id: 142,
		part_id: 36,
		quantity: 2,
	},
	{
		garage_id: 142,
		part_id: 37,
		quantity: 409,
	},
	{
		garage_id: 142,
		part_id: 38,
		quantity: 250,
	},
	{
		garage_id: 142,
		part_id: 39,
		quantity: 176,
	},
	{
		garage_id: 142,
		part_id: 40,
		quantity: 490,
	},
	{
		garage_id: 142,
		part_id: 41,
		quantity: 454,
	},
	{
		garage_id: 142,
		part_id: 42,
		quantity: 18,
	},
	{
		garage_id: 142,
		part_id: 43,
		quantity: 315,
	},
	{
		garage_id: 142,
		part_id: 44,
		quantity: 240,
	},
	{
		garage_id: 142,
		part_id: 45,
		quantity: 231,
	},
	{
		garage_id: 142,
		part_id: 46,
		quantity: 500,
	},
	{
		garage_id: 142,
		part_id: 47,
		quantity: 130,
	},
	{
		garage_id: 142,
		part_id: 48,
		quantity: 103,
	},
	{
		garage_id: 142,
		part_id: 49,
		quantity: 399,
	},
	{
		garage_id: 142,
		part_id: 50,
		quantity: 89,
	},
	{
		garage_id: 142,
		part_id: 51,
		quantity: 219,
	},
	{
		garage_id: 142,
		part_id: 52,
		quantity: 147,
	},
	{
		garage_id: 142,
		part_id: 53,
		quantity: 343,
	},
	{
		garage_id: 142,
		part_id: 54,
		quantity: 234,
	},
	{
		garage_id: 142,
		part_id: 55,
		quantity: 467,
	},
	{
		garage_id: 142,
		part_id: 56,
		quantity: 304,
	},
	{
		garage_id: 142,
		part_id: 57,
		quantity: 319,
	},
	{
		garage_id: 142,
		part_id: 58,
		quantity: 137,
	},
	{
		garage_id: 142,
		part_id: 59,
		quantity: 408,
	},
	{
		garage_id: 142,
		part_id: 60,
		quantity: 117,
	},
	{
		garage_id: 142,
		part_id: 61,
		quantity: 486,
	},
	{
		garage_id: 142,
		part_id: 62,
		quantity: 234,
	},
	{
		garage_id: 142,
		part_id: 63,
		quantity: 167,
	},
	{
		garage_id: 142,
		part_id: 64,
		quantity: 448,
	},
	{
		garage_id: 142,
		part_id: 65,
		quantity: 105,
	},
	{
		garage_id: 142,
		part_id: 66,
		quantity: 356,
	},
	{
		garage_id: 142,
		part_id: 67,
		quantity: 350,
	},
	{
		garage_id: 142,
		part_id: 68,
		quantity: 6,
	},
	{
		garage_id: 142,
		part_id: 69,
		quantity: 368,
	},
	{
		garage_id: 142,
		part_id: 70,
		quantity: 432,
	},
	{
		garage_id: 142,
		part_id: 71,
		quantity: 12,
	},
	{
		garage_id: 142,
		part_id: 72,
		quantity: 190,
	},
	{
		garage_id: 142,
		part_id: 73,
		quantity: 166,
	},
	{
		garage_id: 142,
		part_id: 74,
		quantity: 422,
	},
	{
		garage_id: 142,
		part_id: 75,
		quantity: 204,
	},
	{
		garage_id: 142,
		part_id: 76,
		quantity: 395,
	},
	{
		garage_id: 142,
		part_id: 77,
		quantity: 438,
	},
	{
		garage_id: 142,
		part_id: 78,
		quantity: 236,
	},
	{
		garage_id: 142,
		part_id: 79,
		quantity: 454,
	},
	{
		garage_id: 142,
		part_id: 80,
		quantity: 390,
	},
	{
		garage_id: 142,
		part_id: 81,
		quantity: 68,
	},
	{
		garage_id: 142,
		part_id: 82,
		quantity: 353,
	},
	{
		garage_id: 142,
		part_id: 83,
		quantity: 246,
	},
	{
		garage_id: 142,
		part_id: 84,
		quantity: 128,
	},
	{
		garage_id: 142,
		part_id: 85,
		quantity: 196,
	},
	{
		garage_id: 142,
		part_id: 86,
		quantity: 71,
	},
	{
		garage_id: 142,
		part_id: 87,
		quantity: 150,
	},
	{
		garage_id: 142,
		part_id: 88,
		quantity: 443,
	},
	{
		garage_id: 142,
		part_id: 89,
		quantity: 48,
	},
	{
		garage_id: 142,
		part_id: 90,
		quantity: 94,
	},
	{
		garage_id: 142,
		part_id: 91,
		quantity: 189,
	},
	{
		garage_id: 142,
		part_id: 92,
		quantity: 106,
	},
	{
		garage_id: 142,
		part_id: 93,
		quantity: 442,
	},
	{
		garage_id: 142,
		part_id: 94,
		quantity: 12,
	},
	{
		garage_id: 142,
		part_id: 95,
		quantity: 485,
	},
	{
		garage_id: 142,
		part_id: 96,
		quantity: 141,
	},
	{
		garage_id: 142,
		part_id: 97,
		quantity: 310,
	},
	{
		garage_id: 142,
		part_id: 98,
		quantity: 459,
	},
	{
		garage_id: 142,
		part_id: 99,
		quantity: 222,
	},
	{
		garage_id: 142,
		part_id: 100,
		quantity: 222,
	},
	{
		garage_id: 142,
		part_id: 101,
		quantity: 26,
	},
	{
		garage_id: 142,
		part_id: 102,
		quantity: 74,
	},
	{
		garage_id: 142,
		part_id: 103,
		quantity: 49,
	},
	{
		garage_id: 142,
		part_id: 104,
		quantity: 95,
	},
	{
		garage_id: 142,
		part_id: 105,
		quantity: 352,
	},
	{
		garage_id: 142,
		part_id: 106,
		quantity: 237,
	},
	{
		garage_id: 142,
		part_id: 107,
		quantity: 401,
	},
	{
		garage_id: 142,
		part_id: 108,
		quantity: 424,
	},
	{
		garage_id: 142,
		part_id: 109,
		quantity: 373,
	},
	{
		garage_id: 142,
		part_id: 110,
		quantity: 328,
	},
	{
		garage_id: 142,
		part_id: 111,
		quantity: 167,
	},
	{
		garage_id: 142,
		part_id: 112,
		quantity: 238,
	},
	{
		garage_id: 142,
		part_id: 113,
		quantity: 323,
	},
	{
		garage_id: 142,
		part_id: 114,
		quantity: 389,
	},
	{
		garage_id: 142,
		part_id: 115,
		quantity: 130,
	},
	{
		garage_id: 142,
		part_id: 116,
		quantity: 377,
	},
	{
		garage_id: 142,
		part_id: 117,
		quantity: 104,
	},
	{
		garage_id: 142,
		part_id: 118,
		quantity: 396,
	},
	{
		garage_id: 142,
		part_id: 119,
		quantity: 430,
	},
	{
		garage_id: 142,
		part_id: 120,
		quantity: 52,
	},
	{
		garage_id: 142,
		part_id: 121,
		quantity: 8,
	},
	{
		garage_id: 142,
		part_id: 122,
		quantity: 24,
	},
	{
		garage_id: 142,
		part_id: 123,
		quantity: 353,
	},
	{
		garage_id: 142,
		part_id: 124,
		quantity: 6,
	},
	{
		garage_id: 142,
		part_id: 125,
		quantity: 433,
	},
	{
		garage_id: 142,
		part_id: 126,
		quantity: 84,
	},
	{
		garage_id: 142,
		part_id: 127,
		quantity: 275,
	},
	{
		garage_id: 142,
		part_id: 128,
		quantity: 268,
	},
	{
		garage_id: 142,
		part_id: 129,
		quantity: 448,
	},
	{
		garage_id: 142,
		part_id: 130,
		quantity: 115,
	},
	{
		garage_id: 142,
		part_id: 131,
		quantity: 168,
	},
	{
		garage_id: 142,
		part_id: 132,
		quantity: 377,
	},
	{
		garage_id: 142,
		part_id: 133,
		quantity: 151,
	},
	{
		garage_id: 142,
		part_id: 134,
		quantity: 14,
	},
	{
		garage_id: 142,
		part_id: 135,
		quantity: 315,
	},
	{
		garage_id: 142,
		part_id: 136,
		quantity: 400,
	},
	{
		garage_id: 142,
		part_id: 137,
		quantity: 84,
	},
	{
		garage_id: 142,
		part_id: 138,
		quantity: 294,
	},
	{
		garage_id: 142,
		part_id: 139,
		quantity: 224,
	},
	{
		garage_id: 142,
		part_id: 140,
		quantity: 171,
	},
	{
		garage_id: 142,
		part_id: 141,
		quantity: 97,
	},
	{
		garage_id: 142,
		part_id: 142,
		quantity: 321,
	},
	{
		garage_id: 142,
		part_id: 143,
		quantity: 236,
	},
	{
		garage_id: 142,
		part_id: 144,
		quantity: 332,
	},
	{
		garage_id: 142,
		part_id: 145,
		quantity: 207,
	},
	{
		garage_id: 142,
		part_id: 146,
		quantity: 43,
	},
	{
		garage_id: 142,
		part_id: 147,
		quantity: 108,
	},
	{
		garage_id: 142,
		part_id: 148,
		quantity: 433,
	},
	{
		garage_id: 142,
		part_id: 149,
		quantity: 104,
	},
	{
		garage_id: 142,
		part_id: 150,
		quantity: 459,
	},
	{
		garage_id: 142,
		part_id: 151,
		quantity: 79,
	},
	{
		garage_id: 142,
		part_id: 152,
		quantity: 128,
	},
	{
		garage_id: 142,
		part_id: 153,
		quantity: 280,
	},
	{
		garage_id: 142,
		part_id: 154,
		quantity: 246,
	},
	{
		garage_id: 142,
		part_id: 155,
		quantity: 427,
	},
	{
		garage_id: 142,
		part_id: 156,
		quantity: 482,
	},
	{
		garage_id: 142,
		part_id: 157,
		quantity: 495,
	},
	{
		garage_id: 142,
		part_id: 158,
		quantity: 201,
	},
	{
		garage_id: 142,
		part_id: 159,
		quantity: 220,
	},
	{
		garage_id: 142,
		part_id: 160,
		quantity: 500,
	},
	{
		garage_id: 142,
		part_id: 161,
		quantity: 267,
	},
	{
		garage_id: 142,
		part_id: 162,
		quantity: 34,
	},
	{
		garage_id: 142,
		part_id: 163,
		quantity: 415,
	},
	{
		garage_id: 142,
		part_id: 164,
		quantity: 245,
	},
	{
		garage_id: 142,
		part_id: 165,
		quantity: 21,
	},
	{
		garage_id: 142,
		part_id: 166,
		quantity: 12,
	},
	{
		garage_id: 142,
		part_id: 167,
		quantity: 254,
	},
	{
		garage_id: 142,
		part_id: 168,
		quantity: 488,
	},
	{
		garage_id: 142,
		part_id: 169,
		quantity: 470,
	},
	{
		garage_id: 142,
		part_id: 170,
		quantity: 463,
	},
	{
		garage_id: 142,
		part_id: 171,
		quantity: 142,
	},
	{
		garage_id: 142,
		part_id: 172,
		quantity: 439,
	},
	{
		garage_id: 142,
		part_id: 173,
		quantity: 433,
	},
	{
		garage_id: 142,
		part_id: 174,
		quantity: 339,
	},
	{
		garage_id: 142,
		part_id: 175,
		quantity: 23,
	},
	{
		garage_id: 142,
		part_id: 176,
		quantity: 241,
	},
	{
		garage_id: 142,
		part_id: 177,
		quantity: 226,
	},
	{
		garage_id: 142,
		part_id: 178,
		quantity: 135,
	},
	{
		garage_id: 142,
		part_id: 179,
		quantity: 214,
	},
	{
		garage_id: 142,
		part_id: 180,
		quantity: 123,
	},
	{
		garage_id: 142,
		part_id: 181,
		quantity: 457,
	},
	{
		garage_id: 142,
		part_id: 182,
		quantity: 128,
	},
	{
		garage_id: 142,
		part_id: 183,
		quantity: 237,
	},
	{
		garage_id: 142,
		part_id: 184,
		quantity: 36,
	},
	{
		garage_id: 142,
		part_id: 185,
		quantity: 450,
	},
	{
		garage_id: 142,
		part_id: 186,
		quantity: 491,
	},
	{
		garage_id: 142,
		part_id: 187,
		quantity: 200,
	},
	{
		garage_id: 142,
		part_id: 188,
		quantity: 466,
	},
	{
		garage_id: 142,
		part_id: 189,
		quantity: 359,
	},
	{
		garage_id: 142,
		part_id: 190,
		quantity: 488,
	},
	{
		garage_id: 142,
		part_id: 191,
		quantity: 194,
	},
	{
		garage_id: 142,
		part_id: 192,
		quantity: 217,
	},
	{
		garage_id: 142,
		part_id: 193,
		quantity: 71,
	},
	{
		garage_id: 142,
		part_id: 194,
		quantity: 363,
	},
	{
		garage_id: 142,
		part_id: 195,
		quantity: 172,
	},
	{
		garage_id: 142,
		part_id: 196,
		quantity: 7,
	},
	{
		garage_id: 142,
		part_id: 197,
		quantity: 249,
	},
	{
		garage_id: 142,
		part_id: 198,
		quantity: 262,
	},
	{
		garage_id: 142,
		part_id: 199,
		quantity: 463,
	},
	{
		garage_id: 142,
		part_id: 200,
		quantity: 205,
	},
	{
		garage_id: 142,
		part_id: 201,
		quantity: 327,
	},
	{
		garage_id: 142,
		part_id: 202,
		quantity: 82,
	},
	{
		garage_id: 142,
		part_id: 203,
		quantity: 72,
	},
	{
		garage_id: 142,
		part_id: 204,
		quantity: 261,
	},
	{
		garage_id: 142,
		part_id: 205,
		quantity: 357,
	},
	{
		garage_id: 142,
		part_id: 206,
		quantity: 107,
	},
	{
		garage_id: 142,
		part_id: 207,
		quantity: 3,
	},
	{
		garage_id: 142,
		part_id: 208,
		quantity: 383,
	},
	{
		garage_id: 142,
		part_id: 209,
		quantity: 247,
	},
	{
		garage_id: 142,
		part_id: 210,
		quantity: 483,
	},
	{
		garage_id: 142,
		part_id: 211,
		quantity: 212,
	},
	{
		garage_id: 142,
		part_id: 212,
		quantity: 335,
	},
	{
		garage_id: 142,
		part_id: 213,
		quantity: 103,
	},
	{
		garage_id: 142,
		part_id: 214,
		quantity: 458,
	},
	{
		garage_id: 142,
		part_id: 215,
		quantity: 238,
	},
	{
		garage_id: 142,
		part_id: 216,
		quantity: 184,
	},
	{
		garage_id: 142,
		part_id: 217,
		quantity: 36,
	},
	{
		garage_id: 142,
		part_id: 218,
		quantity: 93,
	},
	{
		garage_id: 142,
		part_id: 219,
		quantity: 42,
	},
	{
		garage_id: 142,
		part_id: 220,
		quantity: 247,
	},
	{
		garage_id: 142,
		part_id: 221,
		quantity: 204,
	},
	{
		garage_id: 142,
		part_id: 222,
		quantity: 38,
	},
	{
		garage_id: 142,
		part_id: 223,
		quantity: 201,
	},
	{
		garage_id: 142,
		part_id: 224,
		quantity: 180,
	},
	{
		garage_id: 142,
		part_id: 225,
		quantity: 266,
	},
	{
		garage_id: 142,
		part_id: 226,
		quantity: 275,
	},
	{
		garage_id: 142,
		part_id: 227,
		quantity: 371,
	},
	{
		garage_id: 142,
		part_id: 228,
		quantity: 308,
	},
	{
		garage_id: 142,
		part_id: 229,
		quantity: 186,
	},
	{
		garage_id: 142,
		part_id: 230,
		quantity: 128,
	},
	{
		garage_id: 142,
		part_id: 231,
		quantity: 66,
	},
	{
		garage_id: 142,
		part_id: 232,
		quantity: 18,
	},
	{
		garage_id: 142,
		part_id: 233,
		quantity: 34,
	},
	{
		garage_id: 142,
		part_id: 234,
		quantity: 59,
	},
	{
		garage_id: 142,
		part_id: 235,
		quantity: 203,
	},
	{
		garage_id: 142,
		part_id: 236,
		quantity: 452,
	},
	{
		garage_id: 142,
		part_id: 237,
		quantity: 208,
	},
	{
		garage_id: 142,
		part_id: 238,
		quantity: 22,
	},
	{
		garage_id: 142,
		part_id: 239,
		quantity: 477,
	},
	{
		garage_id: 142,
		part_id: 240,
		quantity: 365,
	},
	{
		garage_id: 142,
		part_id: 241,
		quantity: 286,
	},
	{
		garage_id: 142,
		part_id: 242,
		quantity: 30,
	},
	{
		garage_id: 142,
		part_id: 243,
		quantity: 134,
	},
	{
		garage_id: 142,
		part_id: 244,
		quantity: 108,
	},
	{
		garage_id: 142,
		part_id: 245,
		quantity: 291,
	},
	{
		garage_id: 142,
		part_id: 246,
		quantity: 162,
	},
	{
		garage_id: 142,
		part_id: 247,
		quantity: 395,
	},
	{
		garage_id: 142,
		part_id: 248,
		quantity: 438,
	},
	{
		garage_id: 142,
		part_id: 249,
		quantity: 185,
	},
	{
		garage_id: 142,
		part_id: 250,
		quantity: 247,
	},
	{
		garage_id: 142,
		part_id: 251,
		quantity: 54,
	},
	{
		garage_id: 142,
		part_id: 252,
		quantity: 367,
	},
	{
		garage_id: 142,
		part_id: 253,
		quantity: 94,
	},
	{
		garage_id: 142,
		part_id: 254,
		quantity: 13,
	},
	{
		garage_id: 142,
		part_id: 255,
		quantity: 322,
	},
	{
		garage_id: 142,
		part_id: 256,
		quantity: 61,
	},
	{
		garage_id: 142,
		part_id: 257,
		quantity: 14,
	},
	{
		garage_id: 142,
		part_id: 258,
		quantity: 22,
	},
	{
		garage_id: 142,
		part_id: 259,
		quantity: 60,
	},
	{
		garage_id: 142,
		part_id: 260,
		quantity: 380,
	},
	{
		garage_id: 142,
		part_id: 261,
		quantity: 224,
	},
	{
		garage_id: 142,
		part_id: 262,
		quantity: 132,
	},
	{
		garage_id: 142,
		part_id: 263,
		quantity: 257,
	},
	{
		garage_id: 142,
		part_id: 264,
		quantity: 113,
	},
	{
		garage_id: 142,
		part_id: 265,
		quantity: 484,
	},
	{
		garage_id: 142,
		part_id: 266,
		quantity: 126,
	},
	{
		garage_id: 142,
		part_id: 267,
		quantity: 43,
	},
	{
		garage_id: 142,
		part_id: 268,
		quantity: 295,
	},
	{
		garage_id: 142,
		part_id: 269,
		quantity: 458,
	},
	{
		garage_id: 142,
		part_id: 270,
		quantity: 17,
	},
	{
		garage_id: 142,
		part_id: 271,
		quantity: 220,
	},
	{
		garage_id: 142,
		part_id: 272,
		quantity: 339,
	},
	{
		garage_id: 142,
		part_id: 273,
		quantity: 312,
	},
	{
		garage_id: 142,
		part_id: 274,
		quantity: 473,
	},
	{
		garage_id: 142,
		part_id: 275,
		quantity: 198,
	},
	{
		garage_id: 142,
		part_id: 276,
		quantity: 414,
	},
	{
		garage_id: 142,
		part_id: 277,
		quantity: 391,
	},
	{
		garage_id: 142,
		part_id: 278,
		quantity: 61,
	},
	{
		garage_id: 142,
		part_id: 279,
		quantity: 268,
	},
	{
		garage_id: 142,
		part_id: 280,
		quantity: 291,
	},
	{
		garage_id: 142,
		part_id: 281,
		quantity: 169,
	},
	{
		garage_id: 142,
		part_id: 282,
		quantity: 399,
	},
	{
		garage_id: 142,
		part_id: 283,
		quantity: 148,
	},
	{
		garage_id: 142,
		part_id: 284,
		quantity: 310,
	},
	{
		garage_id: 142,
		part_id: 285,
		quantity: 425,
	},
	{
		garage_id: 142,
		part_id: 286,
		quantity: 101,
	},
	{
		garage_id: 142,
		part_id: 287,
		quantity: 60,
	},
	{
		garage_id: 142,
		part_id: 288,
		quantity: 275,
	},
	{
		garage_id: 142,
		part_id: 289,
		quantity: 43,
	},
	{
		garage_id: 142,
		part_id: 290,
		quantity: 488,
	},
	{
		garage_id: 142,
		part_id: 291,
		quantity: 34,
	},
	{
		garage_id: 142,
		part_id: 292,
		quantity: 398,
	},
	{
		garage_id: 142,
		part_id: 293,
		quantity: 20,
	},
	{
		garage_id: 142,
		part_id: 294,
		quantity: 487,
	},
	{
		garage_id: 142,
		part_id: 295,
		quantity: 409,
	},
	{
		garage_id: 142,
		part_id: 296,
		quantity: 211,
	},
	{
		garage_id: 142,
		part_id: 297,
		quantity: 392,
	},
	{
		garage_id: 142,
		part_id: 298,
		quantity: 468,
	},
	{
		garage_id: 142,
		part_id: 299,
		quantity: 185,
	},
	{
		garage_id: 142,
		part_id: 300,
		quantity: 201,
	},
	{
		garage_id: 142,
		part_id: 301,
		quantity: 326,
	},
	{
		garage_id: 142,
		part_id: 302,
		quantity: 260,
	},
	{
		garage_id: 142,
		part_id: 303,
		quantity: 197,
	},
	{
		garage_id: 142,
		part_id: 304,
		quantity: 78,
	},
	{
		garage_id: 142,
		part_id: 305,
		quantity: 437,
	},
	{
		garage_id: 142,
		part_id: 306,
		quantity: 37,
	},
	{
		garage_id: 142,
		part_id: 307,
		quantity: 185,
	},
	{
		garage_id: 142,
		part_id: 308,
		quantity: 409,
	},
	{
		garage_id: 142,
		part_id: 309,
		quantity: 112,
	},
	{
		garage_id: 142,
		part_id: 310,
		quantity: 191,
	},
	{
		garage_id: 142,
		part_id: 311,
		quantity: 163,
	},
	{
		garage_id: 142,
		part_id: 312,
		quantity: 433,
	},
	{
		garage_id: 142,
		part_id: 313,
		quantity: 495,
	},
	{
		garage_id: 142,
		part_id: 314,
		quantity: 69,
	},
	{
		garage_id: 142,
		part_id: 315,
		quantity: 161,
	},
	{
		garage_id: 142,
		part_id: 316,
		quantity: 185,
	},
	{
		garage_id: 142,
		part_id: 317,
		quantity: 94,
	},
	{
		garage_id: 142,
		part_id: 318,
		quantity: 306,
	},
	{
		garage_id: 142,
		part_id: 319,
		quantity: 1,
	},
	{
		garage_id: 142,
		part_id: 320,
		quantity: 221,
	},
	{
		garage_id: 142,
		part_id: 321,
		quantity: 333,
	},
	{
		garage_id: 142,
		part_id: 322,
		quantity: 316,
	},
	{
		garage_id: 142,
		part_id: 323,
		quantity: 99,
	},
	{
		garage_id: 142,
		part_id: 324,
		quantity: 218,
	},
	{
		garage_id: 142,
		part_id: 325,
		quantity: 42,
	},
	{
		garage_id: 142,
		part_id: 326,
		quantity: 118,
	},
	{
		garage_id: 142,
		part_id: 327,
		quantity: 320,
	},
	{
		garage_id: 142,
		part_id: 328,
		quantity: 360,
	},
	{
		garage_id: 142,
		part_id: 329,
		quantity: 402,
	},
	{
		garage_id: 142,
		part_id: 330,
		quantity: 127,
	},
	{
		garage_id: 142,
		part_id: 331,
		quantity: 213,
	},
	{
		garage_id: 142,
		part_id: 332,
		quantity: 459,
	},
	{
		garage_id: 142,
		part_id: 333,
		quantity: 42,
	},
	{
		garage_id: 142,
		part_id: 334,
		quantity: 479,
	},
	{
		garage_id: 142,
		part_id: 335,
		quantity: 132,
	},
	{
		garage_id: 142,
		part_id: 336,
		quantity: 116,
	},
	{
		garage_id: 142,
		part_id: 337,
		quantity: 490,
	},
	{
		garage_id: 142,
		part_id: 338,
		quantity: 307,
	},
	{
		garage_id: 142,
		part_id: 339,
		quantity: 404,
	},
	{
		garage_id: 142,
		part_id: 340,
		quantity: 43,
	},
	{
		garage_id: 142,
		part_id: 341,
		quantity: 105,
	},
	{
		garage_id: 142,
		part_id: 342,
		quantity: 235,
	},
	{
		garage_id: 142,
		part_id: 343,
		quantity: 407,
	},
	{
		garage_id: 142,
		part_id: 344,
		quantity: 410,
	},
	{
		garage_id: 142,
		part_id: 345,
		quantity: 497,
	},
	{
		garage_id: 142,
		part_id: 346,
		quantity: 78,
	},
	{
		garage_id: 142,
		part_id: 347,
		quantity: 424,
	},
	{
		garage_id: 142,
		part_id: 348,
		quantity: 446,
	},
	{
		garage_id: 142,
		part_id: 349,
		quantity: 334,
	},
	{
		garage_id: 142,
		part_id: 350,
		quantity: 440,
	},
	{
		garage_id: 142,
		part_id: 351,
		quantity: 429,
	},
	{
		garage_id: 142,
		part_id: 352,
		quantity: 314,
	},
	{
		garage_id: 142,
		part_id: 353,
		quantity: 186,
	},
	{
		garage_id: 142,
		part_id: 354,
		quantity: 150,
	},
	{
		garage_id: 142,
		part_id: 355,
		quantity: 37,
	},
	{
		garage_id: 142,
		part_id: 356,
		quantity: 195,
	},
	{
		garage_id: 142,
		part_id: 357,
		quantity: 401,
	},
	{
		garage_id: 142,
		part_id: 358,
		quantity: 5,
	},
	{
		garage_id: 142,
		part_id: 359,
		quantity: 280,
	},
	{
		garage_id: 142,
		part_id: 360,
		quantity: 382,
	},
	{
		garage_id: 142,
		part_id: 361,
		quantity: 151,
	},
	{
		garage_id: 142,
		part_id: 362,
		quantity: 77,
	},
	{
		garage_id: 142,
		part_id: 363,
		quantity: 2,
	},
	{
		garage_id: 142,
		part_id: 364,
		quantity: 469,
	},
	{
		garage_id: 142,
		part_id: 365,
		quantity: 145,
	},
	{
		garage_id: 142,
		part_id: 366,
		quantity: 442,
	},
	{
		garage_id: 142,
		part_id: 367,
		quantity: 60,
	},
	{
		garage_id: 142,
		part_id: 368,
		quantity: 394,
	},
	{
		garage_id: 142,
		part_id: 369,
		quantity: 18,
	},
	{
		garage_id: 142,
		part_id: 370,
		quantity: 396,
	},
	{
		garage_id: 142,
		part_id: 371,
		quantity: 351,
	},
	{
		garage_id: 142,
		part_id: 372,
		quantity: 427,
	},
	{
		garage_id: 142,
		part_id: 373,
		quantity: 234,
	},
	{
		garage_id: 142,
		part_id: 374,
		quantity: 442,
	},
	{
		garage_id: 142,
		part_id: 375,
		quantity: 480,
	},
	{
		garage_id: 142,
		part_id: 376,
		quantity: 291,
	},
	{
		garage_id: 142,
		part_id: 377,
		quantity: 372,
	},
	{
		garage_id: 142,
		part_id: 378,
		quantity: 152,
	},
	{
		garage_id: 142,
		part_id: 379,
		quantity: 87,
	},
	{
		garage_id: 142,
		part_id: 380,
		quantity: 481,
	},
	{
		garage_id: 142,
		part_id: 381,
		quantity: 181,
	},
	{
		garage_id: 142,
		part_id: 382,
		quantity: 483,
	},
	{
		garage_id: 142,
		part_id: 383,
		quantity: 296,
	},
	{
		garage_id: 142,
		part_id: 384,
		quantity: 285,
	},
	{
		garage_id: 142,
		part_id: 385,
		quantity: 205,
	},
	{
		garage_id: 142,
		part_id: 386,
		quantity: 166,
	},
	{
		garage_id: 142,
		part_id: 387,
		quantity: 146,
	},
	{
		garage_id: 142,
		part_id: 388,
		quantity: 58,
	},
	{
		garage_id: 142,
		part_id: 389,
		quantity: 343,
	},
	{
		garage_id: 142,
		part_id: 390,
		quantity: 250,
	},
	{
		garage_id: 142,
		part_id: 391,
		quantity: 267,
	},
	{
		garage_id: 142,
		part_id: 392,
		quantity: 411,
	},
	{
		garage_id: 142,
		part_id: 393,
		quantity: 469,
	},
	{
		garage_id: 142,
		part_id: 394,
		quantity: 166,
	},
	{
		garage_id: 142,
		part_id: 395,
		quantity: 203,
	},
	{
		garage_id: 142,
		part_id: 396,
		quantity: 324,
	},
	{
		garage_id: 142,
		part_id: 397,
		quantity: 33,
	},
	{
		garage_id: 142,
		part_id: 398,
		quantity: 142,
	},
	{
		garage_id: 142,
		part_id: 399,
		quantity: 274,
	},
	{
		garage_id: 143,
		part_id: 0,
		quantity: 173,
	},
	{
		garage_id: 143,
		part_id: 1,
		quantity: 182,
	},
	{
		garage_id: 143,
		part_id: 2,
		quantity: 267,
	},
	{
		garage_id: 143,
		part_id: 3,
		quantity: 218,
	},
	{
		garage_id: 143,
		part_id: 4,
		quantity: 198,
	},
	{
		garage_id: 143,
		part_id: 5,
		quantity: 288,
	},
	{
		garage_id: 143,
		part_id: 6,
		quantity: 446,
	},
	{
		garage_id: 143,
		part_id: 7,
		quantity: 74,
	},
	{
		garage_id: 143,
		part_id: 8,
		quantity: 310,
	},
	{
		garage_id: 143,
		part_id: 9,
		quantity: 379,
	},
	{
		garage_id: 143,
		part_id: 10,
		quantity: 36,
	},
	{
		garage_id: 143,
		part_id: 11,
		quantity: 100,
	},
	{
		garage_id: 143,
		part_id: 12,
		quantity: 432,
	},
	{
		garage_id: 143,
		part_id: 13,
		quantity: 494,
	},
	{
		garage_id: 143,
		part_id: 14,
		quantity: 262,
	},
	{
		garage_id: 143,
		part_id: 15,
		quantity: 165,
	},
	{
		garage_id: 143,
		part_id: 16,
		quantity: 314,
	},
	{
		garage_id: 143,
		part_id: 17,
		quantity: 364,
	},
	{
		garage_id: 143,
		part_id: 18,
		quantity: 90,
	},
	{
		garage_id: 143,
		part_id: 19,
		quantity: 464,
	},
	{
		garage_id: 143,
		part_id: 20,
		quantity: 7,
	},
	{
		garage_id: 143,
		part_id: 21,
		quantity: 141,
	},
	{
		garage_id: 143,
		part_id: 22,
		quantity: 343,
	},
	{
		garage_id: 143,
		part_id: 23,
		quantity: 114,
	},
	{
		garage_id: 143,
		part_id: 24,
		quantity: 401,
	},
	{
		garage_id: 143,
		part_id: 25,
		quantity: 265,
	},
	{
		garage_id: 143,
		part_id: 26,
		quantity: 442,
	},
	{
		garage_id: 143,
		part_id: 27,
		quantity: 175,
	},
	{
		garage_id: 143,
		part_id: 28,
		quantity: 122,
	},
	{
		garage_id: 143,
		part_id: 29,
		quantity: 17,
	},
	{
		garage_id: 143,
		part_id: 30,
		quantity: 313,
	},
	{
		garage_id: 143,
		part_id: 31,
		quantity: 207,
	},
	{
		garage_id: 143,
		part_id: 32,
		quantity: 30,
	},
	{
		garage_id: 143,
		part_id: 33,
		quantity: 245,
	},
	{
		garage_id: 143,
		part_id: 34,
		quantity: 100,
	},
	{
		garage_id: 143,
		part_id: 35,
		quantity: 85,
	},
	{
		garage_id: 143,
		part_id: 36,
		quantity: 492,
	},
	{
		garage_id: 143,
		part_id: 37,
		quantity: 283,
	},
	{
		garage_id: 143,
		part_id: 38,
		quantity: 183,
	},
	{
		garage_id: 143,
		part_id: 39,
		quantity: 430,
	},
	{
		garage_id: 143,
		part_id: 40,
		quantity: 314,
	},
	{
		garage_id: 143,
		part_id: 41,
		quantity: 498,
	},
	{
		garage_id: 143,
		part_id: 42,
		quantity: 366,
	},
	{
		garage_id: 143,
		part_id: 43,
		quantity: 52,
	},
	{
		garage_id: 143,
		part_id: 44,
		quantity: 240,
	},
	{
		garage_id: 143,
		part_id: 45,
		quantity: 218,
	},
	{
		garage_id: 143,
		part_id: 46,
		quantity: 398,
	},
	{
		garage_id: 143,
		part_id: 47,
		quantity: 176,
	},
	{
		garage_id: 143,
		part_id: 48,
		quantity: 493,
	},
	{
		garage_id: 143,
		part_id: 49,
		quantity: 103,
	},
	{
		garage_id: 143,
		part_id: 50,
		quantity: 424,
	},
	{
		garage_id: 143,
		part_id: 51,
		quantity: 90,
	},
	{
		garage_id: 143,
		part_id: 52,
		quantity: 308,
	},
	{
		garage_id: 143,
		part_id: 53,
		quantity: 376,
	},
	{
		garage_id: 143,
		part_id: 54,
		quantity: 99,
	},
	{
		garage_id: 143,
		part_id: 55,
		quantity: 473,
	},
	{
		garage_id: 143,
		part_id: 56,
		quantity: 316,
	},
	{
		garage_id: 143,
		part_id: 57,
		quantity: 170,
	},
	{
		garage_id: 143,
		part_id: 58,
		quantity: 124,
	},
	{
		garage_id: 143,
		part_id: 59,
		quantity: 12,
	},
	{
		garage_id: 143,
		part_id: 60,
		quantity: 112,
	},
	{
		garage_id: 143,
		part_id: 61,
		quantity: 482,
	},
	{
		garage_id: 143,
		part_id: 62,
		quantity: 87,
	},
	{
		garage_id: 143,
		part_id: 63,
		quantity: 49,
	},
	{
		garage_id: 143,
		part_id: 64,
		quantity: 484,
	},
	{
		garage_id: 143,
		part_id: 65,
		quantity: 331,
	},
	{
		garage_id: 143,
		part_id: 66,
		quantity: 5,
	},
	{
		garage_id: 143,
		part_id: 67,
		quantity: 221,
	},
	{
		garage_id: 143,
		part_id: 68,
		quantity: 325,
	},
	{
		garage_id: 143,
		part_id: 69,
		quantity: 317,
	},
	{
		garage_id: 143,
		part_id: 70,
		quantity: 449,
	},
	{
		garage_id: 143,
		part_id: 71,
		quantity: 387,
	},
	{
		garage_id: 143,
		part_id: 72,
		quantity: 476,
	},
	{
		garage_id: 143,
		part_id: 73,
		quantity: 279,
	},
	{
		garage_id: 143,
		part_id: 74,
		quantity: 273,
	},
	{
		garage_id: 143,
		part_id: 75,
		quantity: 18,
	},
	{
		garage_id: 143,
		part_id: 76,
		quantity: 18,
	},
	{
		garage_id: 143,
		part_id: 77,
		quantity: 3,
	},
	{
		garage_id: 143,
		part_id: 78,
		quantity: 462,
	},
	{
		garage_id: 143,
		part_id: 79,
		quantity: 397,
	},
	{
		garage_id: 143,
		part_id: 80,
		quantity: 476,
	},
	{
		garage_id: 143,
		part_id: 81,
		quantity: 302,
	},
	{
		garage_id: 143,
		part_id: 82,
		quantity: 0,
	},
	{
		garage_id: 143,
		part_id: 83,
		quantity: 371,
	},
	{
		garage_id: 143,
		part_id: 84,
		quantity: 49,
	},
	{
		garage_id: 143,
		part_id: 85,
		quantity: 485,
	},
	{
		garage_id: 143,
		part_id: 86,
		quantity: 248,
	},
	{
		garage_id: 143,
		part_id: 87,
		quantity: 336,
	},
	{
		garage_id: 143,
		part_id: 88,
		quantity: 59,
	},
	{
		garage_id: 143,
		part_id: 89,
		quantity: 175,
	},
	{
		garage_id: 143,
		part_id: 90,
		quantity: 24,
	},
	{
		garage_id: 143,
		part_id: 91,
		quantity: 427,
	},
	{
		garage_id: 143,
		part_id: 92,
		quantity: 101,
	},
	{
		garage_id: 143,
		part_id: 93,
		quantity: 183,
	},
	{
		garage_id: 143,
		part_id: 94,
		quantity: 311,
	},
	{
		garage_id: 143,
		part_id: 95,
		quantity: 379,
	},
	{
		garage_id: 143,
		part_id: 96,
		quantity: 408,
	},
	{
		garage_id: 143,
		part_id: 97,
		quantity: 236,
	},
	{
		garage_id: 143,
		part_id: 98,
		quantity: 226,
	},
	{
		garage_id: 143,
		part_id: 99,
		quantity: 239,
	},
	{
		garage_id: 143,
		part_id: 100,
		quantity: 277,
	},
	{
		garage_id: 143,
		part_id: 101,
		quantity: 403,
	},
	{
		garage_id: 143,
		part_id: 102,
		quantity: 443,
	},
	{
		garage_id: 143,
		part_id: 103,
		quantity: 394,
	},
	{
		garage_id: 143,
		part_id: 104,
		quantity: 456,
	},
	{
		garage_id: 143,
		part_id: 105,
		quantity: 362,
	},
	{
		garage_id: 143,
		part_id: 106,
		quantity: 404,
	},
	{
		garage_id: 143,
		part_id: 107,
		quantity: 344,
	},
	{
		garage_id: 143,
		part_id: 108,
		quantity: 409,
	},
	{
		garage_id: 143,
		part_id: 109,
		quantity: 61,
	},
	{
		garage_id: 143,
		part_id: 110,
		quantity: 20,
	},
	{
		garage_id: 143,
		part_id: 111,
		quantity: 199,
	},
	{
		garage_id: 143,
		part_id: 112,
		quantity: 9,
	},
	{
		garage_id: 143,
		part_id: 113,
		quantity: 482,
	},
	{
		garage_id: 143,
		part_id: 114,
		quantity: 330,
	},
	{
		garage_id: 143,
		part_id: 115,
		quantity: 158,
	},
	{
		garage_id: 143,
		part_id: 116,
		quantity: 89,
	},
	{
		garage_id: 143,
		part_id: 117,
		quantity: 223,
	},
	{
		garage_id: 143,
		part_id: 118,
		quantity: 476,
	},
	{
		garage_id: 143,
		part_id: 119,
		quantity: 409,
	},
	{
		garage_id: 143,
		part_id: 120,
		quantity: 196,
	},
	{
		garage_id: 143,
		part_id: 121,
		quantity: 237,
	},
	{
		garage_id: 143,
		part_id: 122,
		quantity: 206,
	},
	{
		garage_id: 143,
		part_id: 123,
		quantity: 36,
	},
	{
		garage_id: 143,
		part_id: 124,
		quantity: 387,
	},
	{
		garage_id: 143,
		part_id: 125,
		quantity: 497,
	},
	{
		garage_id: 143,
		part_id: 126,
		quantity: 233,
	},
	{
		garage_id: 143,
		part_id: 127,
		quantity: 35,
	},
	{
		garage_id: 143,
		part_id: 128,
		quantity: 296,
	},
	{
		garage_id: 143,
		part_id: 129,
		quantity: 107,
	},
	{
		garage_id: 143,
		part_id: 130,
		quantity: 475,
	},
	{
		garage_id: 143,
		part_id: 131,
		quantity: 153,
	},
	{
		garage_id: 143,
		part_id: 132,
		quantity: 421,
	},
	{
		garage_id: 143,
		part_id: 133,
		quantity: 372,
	},
	{
		garage_id: 143,
		part_id: 134,
		quantity: 1,
	},
	{
		garage_id: 143,
		part_id: 135,
		quantity: 11,
	},
	{
		garage_id: 143,
		part_id: 136,
		quantity: 88,
	},
	{
		garage_id: 143,
		part_id: 137,
		quantity: 477,
	},
	{
		garage_id: 143,
		part_id: 138,
		quantity: 331,
	},
	{
		garage_id: 143,
		part_id: 139,
		quantity: 445,
	},
	{
		garage_id: 143,
		part_id: 140,
		quantity: 323,
	},
	{
		garage_id: 143,
		part_id: 141,
		quantity: 429,
	},
	{
		garage_id: 143,
		part_id: 142,
		quantity: 485,
	},
	{
		garage_id: 143,
		part_id: 143,
		quantity: 319,
	},
	{
		garage_id: 143,
		part_id: 144,
		quantity: 354,
	},
	{
		garage_id: 143,
		part_id: 145,
		quantity: 456,
	},
	{
		garage_id: 143,
		part_id: 146,
		quantity: 95,
	},
	{
		garage_id: 143,
		part_id: 147,
		quantity: 126,
	},
	{
		garage_id: 143,
		part_id: 148,
		quantity: 397,
	},
	{
		garage_id: 143,
		part_id: 149,
		quantity: 155,
	},
	{
		garage_id: 143,
		part_id: 150,
		quantity: 466,
	},
	{
		garage_id: 143,
		part_id: 151,
		quantity: 67,
	},
	{
		garage_id: 143,
		part_id: 152,
		quantity: 160,
	},
	{
		garage_id: 143,
		part_id: 153,
		quantity: 348,
	},
	{
		garage_id: 143,
		part_id: 154,
		quantity: 383,
	},
	{
		garage_id: 143,
		part_id: 155,
		quantity: 336,
	},
	{
		garage_id: 143,
		part_id: 156,
		quantity: 114,
	},
	{
		garage_id: 143,
		part_id: 157,
		quantity: 70,
	},
	{
		garage_id: 143,
		part_id: 158,
		quantity: 83,
	},
	{
		garage_id: 143,
		part_id: 159,
		quantity: 131,
	},
	{
		garage_id: 143,
		part_id: 160,
		quantity: 365,
	},
	{
		garage_id: 143,
		part_id: 161,
		quantity: 139,
	},
	{
		garage_id: 143,
		part_id: 162,
		quantity: 279,
	},
	{
		garage_id: 143,
		part_id: 163,
		quantity: 482,
	},
	{
		garage_id: 143,
		part_id: 164,
		quantity: 118,
	},
	{
		garage_id: 143,
		part_id: 165,
		quantity: 491,
	},
	{
		garage_id: 143,
		part_id: 166,
		quantity: 436,
	},
	{
		garage_id: 143,
		part_id: 167,
		quantity: 473,
	},
	{
		garage_id: 143,
		part_id: 168,
		quantity: 498,
	},
	{
		garage_id: 143,
		part_id: 169,
		quantity: 277,
	},
	{
		garage_id: 143,
		part_id: 170,
		quantity: 233,
	},
	{
		garage_id: 143,
		part_id: 171,
		quantity: 217,
	},
	{
		garage_id: 143,
		part_id: 172,
		quantity: 479,
	},
	{
		garage_id: 143,
		part_id: 173,
		quantity: 32,
	},
	{
		garage_id: 143,
		part_id: 174,
		quantity: 213,
	},
	{
		garage_id: 143,
		part_id: 175,
		quantity: 397,
	},
	{
		garage_id: 143,
		part_id: 176,
		quantity: 380,
	},
	{
		garage_id: 143,
		part_id: 177,
		quantity: 152,
	},
	{
		garage_id: 143,
		part_id: 178,
		quantity: 186,
	},
	{
		garage_id: 143,
		part_id: 179,
		quantity: 246,
	},
	{
		garage_id: 143,
		part_id: 180,
		quantity: 14,
	},
	{
		garage_id: 143,
		part_id: 181,
		quantity: 491,
	},
	{
		garage_id: 143,
		part_id: 182,
		quantity: 469,
	},
	{
		garage_id: 143,
		part_id: 183,
		quantity: 42,
	},
	{
		garage_id: 143,
		part_id: 184,
		quantity: 160,
	},
	{
		garage_id: 143,
		part_id: 185,
		quantity: 259,
	},
	{
		garage_id: 143,
		part_id: 186,
		quantity: 264,
	},
	{
		garage_id: 143,
		part_id: 187,
		quantity: 304,
	},
	{
		garage_id: 143,
		part_id: 188,
		quantity: 334,
	},
	{
		garage_id: 143,
		part_id: 189,
		quantity: 463,
	},
	{
		garage_id: 143,
		part_id: 190,
		quantity: 294,
	},
	{
		garage_id: 143,
		part_id: 191,
		quantity: 415,
	},
	{
		garage_id: 143,
		part_id: 192,
		quantity: 398,
	},
	{
		garage_id: 143,
		part_id: 193,
		quantity: 379,
	},
	{
		garage_id: 143,
		part_id: 194,
		quantity: 90,
	},
	{
		garage_id: 143,
		part_id: 195,
		quantity: 427,
	},
	{
		garage_id: 143,
		part_id: 196,
		quantity: 161,
	},
	{
		garage_id: 143,
		part_id: 197,
		quantity: 384,
	},
	{
		garage_id: 143,
		part_id: 198,
		quantity: 357,
	},
	{
		garage_id: 143,
		part_id: 199,
		quantity: 317,
	},
	{
		garage_id: 143,
		part_id: 200,
		quantity: 483,
	},
	{
		garage_id: 143,
		part_id: 201,
		quantity: 111,
	},
	{
		garage_id: 143,
		part_id: 202,
		quantity: 9,
	},
	{
		garage_id: 143,
		part_id: 203,
		quantity: 261,
	},
	{
		garage_id: 143,
		part_id: 204,
		quantity: 340,
	},
	{
		garage_id: 143,
		part_id: 205,
		quantity: 372,
	},
	{
		garage_id: 143,
		part_id: 206,
		quantity: 370,
	},
	{
		garage_id: 143,
		part_id: 207,
		quantity: 254,
	},
	{
		garage_id: 143,
		part_id: 208,
		quantity: 65,
	},
	{
		garage_id: 143,
		part_id: 209,
		quantity: 225,
	},
	{
		garage_id: 143,
		part_id: 210,
		quantity: 157,
	},
	{
		garage_id: 143,
		part_id: 211,
		quantity: 205,
	},
	{
		garage_id: 143,
		part_id: 212,
		quantity: 58,
	},
	{
		garage_id: 143,
		part_id: 213,
		quantity: 152,
	},
	{
		garage_id: 143,
		part_id: 214,
		quantity: 95,
	},
	{
		garage_id: 143,
		part_id: 215,
		quantity: 75,
	},
	{
		garage_id: 143,
		part_id: 216,
		quantity: 139,
	},
	{
		garage_id: 143,
		part_id: 217,
		quantity: 244,
	},
	{
		garage_id: 143,
		part_id: 218,
		quantity: 251,
	},
	{
		garage_id: 143,
		part_id: 219,
		quantity: 472,
	},
	{
		garage_id: 143,
		part_id: 220,
		quantity: 156,
	},
	{
		garage_id: 143,
		part_id: 221,
		quantity: 220,
	},
	{
		garage_id: 143,
		part_id: 222,
		quantity: 372,
	},
	{
		garage_id: 143,
		part_id: 223,
		quantity: 361,
	},
	{
		garage_id: 143,
		part_id: 224,
		quantity: 41,
	},
	{
		garage_id: 143,
		part_id: 225,
		quantity: 101,
	},
	{
		garage_id: 143,
		part_id: 226,
		quantity: 56,
	},
	{
		garage_id: 143,
		part_id: 227,
		quantity: 17,
	},
	{
		garage_id: 143,
		part_id: 228,
		quantity: 27,
	},
	{
		garage_id: 143,
		part_id: 229,
		quantity: 482,
	},
	{
		garage_id: 143,
		part_id: 230,
		quantity: 297,
	},
	{
		garage_id: 143,
		part_id: 231,
		quantity: 241,
	},
	{
		garage_id: 143,
		part_id: 232,
		quantity: 235,
	},
	{
		garage_id: 143,
		part_id: 233,
		quantity: 215,
	},
	{
		garage_id: 143,
		part_id: 234,
		quantity: 184,
	},
	{
		garage_id: 143,
		part_id: 235,
		quantity: 29,
	},
	{
		garage_id: 143,
		part_id: 236,
		quantity: 231,
	},
	{
		garage_id: 143,
		part_id: 237,
		quantity: 468,
	},
	{
		garage_id: 143,
		part_id: 238,
		quantity: 240,
	},
	{
		garage_id: 143,
		part_id: 239,
		quantity: 160,
	},
	{
		garage_id: 143,
		part_id: 240,
		quantity: 336,
	},
	{
		garage_id: 143,
		part_id: 241,
		quantity: 184,
	},
	{
		garage_id: 143,
		part_id: 242,
		quantity: 30,
	},
	{
		garage_id: 143,
		part_id: 243,
		quantity: 90,
	},
	{
		garage_id: 143,
		part_id: 244,
		quantity: 398,
	},
	{
		garage_id: 143,
		part_id: 245,
		quantity: 278,
	},
	{
		garage_id: 143,
		part_id: 246,
		quantity: 320,
	},
	{
		garage_id: 143,
		part_id: 247,
		quantity: 80,
	},
	{
		garage_id: 143,
		part_id: 248,
		quantity: 394,
	},
	{
		garage_id: 143,
		part_id: 249,
		quantity: 34,
	},
	{
		garage_id: 143,
		part_id: 250,
		quantity: 397,
	},
	{
		garage_id: 143,
		part_id: 251,
		quantity: 316,
	},
	{
		garage_id: 143,
		part_id: 252,
		quantity: 52,
	},
	{
		garage_id: 143,
		part_id: 253,
		quantity: 26,
	},
	{
		garage_id: 143,
		part_id: 254,
		quantity: 179,
	},
	{
		garage_id: 143,
		part_id: 255,
		quantity: 54,
	},
	{
		garage_id: 143,
		part_id: 256,
		quantity: 99,
	},
	{
		garage_id: 143,
		part_id: 257,
		quantity: 94,
	},
	{
		garage_id: 143,
		part_id: 258,
		quantity: 336,
	},
	{
		garage_id: 143,
		part_id: 259,
		quantity: 238,
	},
	{
		garage_id: 143,
		part_id: 260,
		quantity: 307,
	},
	{
		garage_id: 143,
		part_id: 261,
		quantity: 145,
	},
	{
		garage_id: 143,
		part_id: 262,
		quantity: 333,
	},
	{
		garage_id: 143,
		part_id: 263,
		quantity: 263,
	},
	{
		garage_id: 143,
		part_id: 264,
		quantity: 148,
	},
	{
		garage_id: 143,
		part_id: 265,
		quantity: 171,
	},
	{
		garage_id: 143,
		part_id: 266,
		quantity: 424,
	},
	{
		garage_id: 143,
		part_id: 267,
		quantity: 54,
	},
	{
		garage_id: 143,
		part_id: 268,
		quantity: 253,
	},
	{
		garage_id: 143,
		part_id: 269,
		quantity: 231,
	},
	{
		garage_id: 143,
		part_id: 270,
		quantity: 72,
	},
	{
		garage_id: 143,
		part_id: 271,
		quantity: 140,
	},
	{
		garage_id: 143,
		part_id: 272,
		quantity: 2,
	},
	{
		garage_id: 143,
		part_id: 273,
		quantity: 274,
	},
	{
		garage_id: 143,
		part_id: 274,
		quantity: 460,
	},
	{
		garage_id: 143,
		part_id: 275,
		quantity: 410,
	},
	{
		garage_id: 143,
		part_id: 276,
		quantity: 231,
	},
	{
		garage_id: 143,
		part_id: 277,
		quantity: 218,
	},
	{
		garage_id: 143,
		part_id: 278,
		quantity: 449,
	},
	{
		garage_id: 143,
		part_id: 279,
		quantity: 369,
	},
	{
		garage_id: 143,
		part_id: 280,
		quantity: 255,
	},
	{
		garage_id: 143,
		part_id: 281,
		quantity: 211,
	},
	{
		garage_id: 143,
		part_id: 282,
		quantity: 212,
	},
	{
		garage_id: 143,
		part_id: 283,
		quantity: 34,
	},
	{
		garage_id: 143,
		part_id: 284,
		quantity: 285,
	},
	{
		garage_id: 143,
		part_id: 285,
		quantity: 336,
	},
	{
		garage_id: 143,
		part_id: 286,
		quantity: 2,
	},
	{
		garage_id: 143,
		part_id: 287,
		quantity: 191,
	},
	{
		garage_id: 143,
		part_id: 288,
		quantity: 477,
	},
	{
		garage_id: 143,
		part_id: 289,
		quantity: 200,
	},
	{
		garage_id: 143,
		part_id: 290,
		quantity: 120,
	},
	{
		garage_id: 143,
		part_id: 291,
		quantity: 442,
	},
	{
		garage_id: 143,
		part_id: 292,
		quantity: 165,
	},
	{
		garage_id: 143,
		part_id: 293,
		quantity: 248,
	},
	{
		garage_id: 143,
		part_id: 294,
		quantity: 212,
	},
	{
		garage_id: 143,
		part_id: 295,
		quantity: 449,
	},
	{
		garage_id: 143,
		part_id: 296,
		quantity: 293,
	},
	{
		garage_id: 143,
		part_id: 297,
		quantity: 79,
	},
	{
		garage_id: 143,
		part_id: 298,
		quantity: 15,
	},
	{
		garage_id: 143,
		part_id: 299,
		quantity: 380,
	},
	{
		garage_id: 143,
		part_id: 300,
		quantity: 201,
	},
	{
		garage_id: 143,
		part_id: 301,
		quantity: 489,
	},
	{
		garage_id: 143,
		part_id: 302,
		quantity: 73,
	},
	{
		garage_id: 143,
		part_id: 303,
		quantity: 332,
	},
	{
		garage_id: 143,
		part_id: 304,
		quantity: 153,
	},
	{
		garage_id: 143,
		part_id: 305,
		quantity: 183,
	},
	{
		garage_id: 143,
		part_id: 306,
		quantity: 271,
	},
	{
		garage_id: 143,
		part_id: 307,
		quantity: 19,
	},
	{
		garage_id: 143,
		part_id: 308,
		quantity: 344,
	},
	{
		garage_id: 143,
		part_id: 309,
		quantity: 396,
	},
	{
		garage_id: 143,
		part_id: 310,
		quantity: 483,
	},
	{
		garage_id: 143,
		part_id: 311,
		quantity: 291,
	},
	{
		garage_id: 143,
		part_id: 312,
		quantity: 441,
	},
	{
		garage_id: 143,
		part_id: 313,
		quantity: 481,
	},
	{
		garage_id: 143,
		part_id: 314,
		quantity: 254,
	},
	{
		garage_id: 143,
		part_id: 315,
		quantity: 161,
	},
	{
		garage_id: 143,
		part_id: 316,
		quantity: 335,
	},
	{
		garage_id: 143,
		part_id: 317,
		quantity: 117,
	},
	{
		garage_id: 143,
		part_id: 318,
		quantity: 328,
	},
	{
		garage_id: 143,
		part_id: 319,
		quantity: 35,
	},
	{
		garage_id: 143,
		part_id: 320,
		quantity: 141,
	},
	{
		garage_id: 143,
		part_id: 321,
		quantity: 487,
	},
	{
		garage_id: 143,
		part_id: 322,
		quantity: 398,
	},
	{
		garage_id: 143,
		part_id: 323,
		quantity: 95,
	},
	{
		garage_id: 143,
		part_id: 324,
		quantity: 206,
	},
	{
		garage_id: 143,
		part_id: 325,
		quantity: 178,
	},
	{
		garage_id: 143,
		part_id: 326,
		quantity: 65,
	},
	{
		garage_id: 143,
		part_id: 327,
		quantity: 231,
	},
	{
		garage_id: 143,
		part_id: 328,
		quantity: 203,
	},
	{
		garage_id: 143,
		part_id: 329,
		quantity: 161,
	},
	{
		garage_id: 143,
		part_id: 330,
		quantity: 76,
	},
	{
		garage_id: 143,
		part_id: 331,
		quantity: 42,
	},
	{
		garage_id: 143,
		part_id: 332,
		quantity: 191,
	},
	{
		garage_id: 143,
		part_id: 333,
		quantity: 41,
	},
	{
		garage_id: 143,
		part_id: 334,
		quantity: 355,
	},
	{
		garage_id: 143,
		part_id: 335,
		quantity: 24,
	},
	{
		garage_id: 143,
		part_id: 336,
		quantity: 451,
	},
	{
		garage_id: 143,
		part_id: 337,
		quantity: 304,
	},
	{
		garage_id: 143,
		part_id: 338,
		quantity: 202,
	},
	{
		garage_id: 143,
		part_id: 339,
		quantity: 261,
	},
	{
		garage_id: 143,
		part_id: 340,
		quantity: 119,
	},
	{
		garage_id: 143,
		part_id: 341,
		quantity: 364,
	},
	{
		garage_id: 143,
		part_id: 342,
		quantity: 422,
	},
	{
		garage_id: 143,
		part_id: 343,
		quantity: 467,
	},
	{
		garage_id: 143,
		part_id: 344,
		quantity: 197,
	},
	{
		garage_id: 143,
		part_id: 345,
		quantity: 438,
	},
	{
		garage_id: 143,
		part_id: 346,
		quantity: 410,
	},
	{
		garage_id: 143,
		part_id: 347,
		quantity: 412,
	},
	{
		garage_id: 143,
		part_id: 348,
		quantity: 240,
	},
	{
		garage_id: 143,
		part_id: 349,
		quantity: 274,
	},
	{
		garage_id: 143,
		part_id: 350,
		quantity: 284,
	},
	{
		garage_id: 143,
		part_id: 351,
		quantity: 60,
	},
	{
		garage_id: 143,
		part_id: 352,
		quantity: 95,
	},
	{
		garage_id: 143,
		part_id: 353,
		quantity: 247,
	},
	{
		garage_id: 143,
		part_id: 354,
		quantity: 195,
	},
	{
		garage_id: 143,
		part_id: 355,
		quantity: 157,
	},
	{
		garage_id: 143,
		part_id: 356,
		quantity: 331,
	},
	{
		garage_id: 143,
		part_id: 357,
		quantity: 141,
	},
	{
		garage_id: 143,
		part_id: 358,
		quantity: 98,
	},
	{
		garage_id: 143,
		part_id: 359,
		quantity: 414,
	},
	{
		garage_id: 143,
		part_id: 360,
		quantity: 44,
	},
	{
		garage_id: 143,
		part_id: 361,
		quantity: 208,
	},
	{
		garage_id: 143,
		part_id: 362,
		quantity: 176,
	},
	{
		garage_id: 143,
		part_id: 363,
		quantity: 115,
	},
	{
		garage_id: 143,
		part_id: 364,
		quantity: 78,
	},
	{
		garage_id: 143,
		part_id: 365,
		quantity: 215,
	},
	{
		garage_id: 143,
		part_id: 366,
		quantity: 60,
	},
	{
		garage_id: 143,
		part_id: 367,
		quantity: 390,
	},
	{
		garage_id: 143,
		part_id: 368,
		quantity: 110,
	},
	{
		garage_id: 143,
		part_id: 369,
		quantity: 290,
	},
	{
		garage_id: 143,
		part_id: 370,
		quantity: 15,
	},
	{
		garage_id: 143,
		part_id: 371,
		quantity: 340,
	},
	{
		garage_id: 143,
		part_id: 372,
		quantity: 182,
	},
	{
		garage_id: 143,
		part_id: 373,
		quantity: 338,
	},
	{
		garage_id: 143,
		part_id: 374,
		quantity: 200,
	},
	{
		garage_id: 143,
		part_id: 375,
		quantity: 216,
	},
	{
		garage_id: 143,
		part_id: 376,
		quantity: 208,
	},
	{
		garage_id: 143,
		part_id: 377,
		quantity: 176,
	},
	{
		garage_id: 143,
		part_id: 378,
		quantity: 205,
	},
	{
		garage_id: 143,
		part_id: 379,
		quantity: 2,
	},
	{
		garage_id: 143,
		part_id: 380,
		quantity: 6,
	},
	{
		garage_id: 143,
		part_id: 381,
		quantity: 426,
	},
	{
		garage_id: 143,
		part_id: 382,
		quantity: 257,
	},
	{
		garage_id: 143,
		part_id: 383,
		quantity: 202,
	},
	{
		garage_id: 143,
		part_id: 384,
		quantity: 49,
	},
	{
		garage_id: 143,
		part_id: 385,
		quantity: 15,
	},
	{
		garage_id: 143,
		part_id: 386,
		quantity: 166,
	},
	{
		garage_id: 143,
		part_id: 387,
		quantity: 496,
	},
	{
		garage_id: 143,
		part_id: 388,
		quantity: 71,
	},
	{
		garage_id: 143,
		part_id: 389,
		quantity: 389,
	},
	{
		garage_id: 143,
		part_id: 390,
		quantity: 456,
	},
	{
		garage_id: 143,
		part_id: 391,
		quantity: 305,
	},
	{
		garage_id: 143,
		part_id: 392,
		quantity: 83,
	},
	{
		garage_id: 143,
		part_id: 393,
		quantity: 28,
	},
	{
		garage_id: 143,
		part_id: 394,
		quantity: 484,
	},
	{
		garage_id: 143,
		part_id: 395,
		quantity: 476,
	},
	{
		garage_id: 143,
		part_id: 396,
		quantity: 90,
	},
	{
		garage_id: 143,
		part_id: 397,
		quantity: 237,
	},
	{
		garage_id: 143,
		part_id: 398,
		quantity: 132,
	},
	{
		garage_id: 143,
		part_id: 399,
		quantity: 119,
	},
	{
		garage_id: 144,
		part_id: 0,
		quantity: 257,
	},
	{
		garage_id: 144,
		part_id: 1,
		quantity: 253,
	},
	{
		garage_id: 144,
		part_id: 2,
		quantity: 349,
	},
	{
		garage_id: 144,
		part_id: 3,
		quantity: 56,
	},
	{
		garage_id: 144,
		part_id: 4,
		quantity: 239,
	},
	{
		garage_id: 144,
		part_id: 5,
		quantity: 339,
	},
	{
		garage_id: 144,
		part_id: 6,
		quantity: 459,
	},
	{
		garage_id: 144,
		part_id: 7,
		quantity: 419,
	},
	{
		garage_id: 144,
		part_id: 8,
		quantity: 292,
	},
	{
		garage_id: 144,
		part_id: 9,
		quantity: 351,
	},
	{
		garage_id: 144,
		part_id: 10,
		quantity: 443,
	},
	{
		garage_id: 144,
		part_id: 11,
		quantity: 111,
	},
	{
		garage_id: 144,
		part_id: 12,
		quantity: 230,
	},
	{
		garage_id: 144,
		part_id: 13,
		quantity: 58,
	},
	{
		garage_id: 144,
		part_id: 14,
		quantity: 326,
	},
	{
		garage_id: 144,
		part_id: 15,
		quantity: 164,
	},
	{
		garage_id: 144,
		part_id: 16,
		quantity: 40,
	},
	{
		garage_id: 144,
		part_id: 17,
		quantity: 372,
	},
	{
		garage_id: 144,
		part_id: 18,
		quantity: 66,
	},
	{
		garage_id: 144,
		part_id: 19,
		quantity: 402,
	},
	{
		garage_id: 144,
		part_id: 20,
		quantity: 475,
	},
	{
		garage_id: 144,
		part_id: 21,
		quantity: 185,
	},
	{
		garage_id: 144,
		part_id: 22,
		quantity: 404,
	},
	{
		garage_id: 144,
		part_id: 23,
		quantity: 492,
	},
	{
		garage_id: 144,
		part_id: 24,
		quantity: 41,
	},
	{
		garage_id: 144,
		part_id: 25,
		quantity: 359,
	},
	{
		garage_id: 144,
		part_id: 26,
		quantity: 28,
	},
	{
		garage_id: 144,
		part_id: 27,
		quantity: 188,
	},
	{
		garage_id: 144,
		part_id: 28,
		quantity: 4,
	},
	{
		garage_id: 144,
		part_id: 29,
		quantity: 306,
	},
	{
		garage_id: 144,
		part_id: 30,
		quantity: 229,
	},
	{
		garage_id: 144,
		part_id: 31,
		quantity: 42,
	},
	{
		garage_id: 144,
		part_id: 32,
		quantity: 235,
	},
	{
		garage_id: 144,
		part_id: 33,
		quantity: 141,
	},
	{
		garage_id: 144,
		part_id: 34,
		quantity: 477,
	},
	{
		garage_id: 144,
		part_id: 35,
		quantity: 313,
	},
	{
		garage_id: 144,
		part_id: 36,
		quantity: 37,
	},
	{
		garage_id: 144,
		part_id: 37,
		quantity: 293,
	},
	{
		garage_id: 144,
		part_id: 38,
		quantity: 495,
	},
	{
		garage_id: 144,
		part_id: 39,
		quantity: 237,
	},
	{
		garage_id: 144,
		part_id: 40,
		quantity: 15,
	},
	{
		garage_id: 144,
		part_id: 41,
		quantity: 410,
	},
	{
		garage_id: 144,
		part_id: 42,
		quantity: 232,
	},
	{
		garage_id: 144,
		part_id: 43,
		quantity: 340,
	},
	{
		garage_id: 144,
		part_id: 44,
		quantity: 397,
	},
	{
		garage_id: 144,
		part_id: 45,
		quantity: 115,
	},
	{
		garage_id: 144,
		part_id: 46,
		quantity: 72,
	},
	{
		garage_id: 144,
		part_id: 47,
		quantity: 25,
	},
	{
		garage_id: 144,
		part_id: 48,
		quantity: 135,
	},
	{
		garage_id: 144,
		part_id: 49,
		quantity: 334,
	},
	{
		garage_id: 144,
		part_id: 50,
		quantity: 298,
	},
	{
		garage_id: 144,
		part_id: 51,
		quantity: 276,
	},
	{
		garage_id: 144,
		part_id: 52,
		quantity: 89,
	},
	{
		garage_id: 144,
		part_id: 53,
		quantity: 87,
	},
	{
		garage_id: 144,
		part_id: 54,
		quantity: 278,
	},
	{
		garage_id: 144,
		part_id: 55,
		quantity: 125,
	},
	{
		garage_id: 144,
		part_id: 56,
		quantity: 181,
	},
	{
		garage_id: 144,
		part_id: 57,
		quantity: 301,
	},
	{
		garage_id: 144,
		part_id: 58,
		quantity: 434,
	},
	{
		garage_id: 144,
		part_id: 59,
		quantity: 457,
	},
	{
		garage_id: 144,
		part_id: 60,
		quantity: 152,
	},
	{
		garage_id: 144,
		part_id: 61,
		quantity: 304,
	},
	{
		garage_id: 144,
		part_id: 62,
		quantity: 300,
	},
	{
		garage_id: 144,
		part_id: 63,
		quantity: 254,
	},
	{
		garage_id: 144,
		part_id: 64,
		quantity: 433,
	},
	{
		garage_id: 144,
		part_id: 65,
		quantity: 68,
	},
	{
		garage_id: 144,
		part_id: 66,
		quantity: 304,
	},
	{
		garage_id: 144,
		part_id: 67,
		quantity: 137,
	},
	{
		garage_id: 144,
		part_id: 68,
		quantity: 304,
	},
	{
		garage_id: 144,
		part_id: 69,
		quantity: 0,
	},
	{
		garage_id: 144,
		part_id: 70,
		quantity: 374,
	},
	{
		garage_id: 144,
		part_id: 71,
		quantity: 145,
	},
	{
		garage_id: 144,
		part_id: 72,
		quantity: 13,
	},
	{
		garage_id: 144,
		part_id: 73,
		quantity: 414,
	},
	{
		garage_id: 144,
		part_id: 74,
		quantity: 263,
	},
	{
		garage_id: 144,
		part_id: 75,
		quantity: 109,
	},
	{
		garage_id: 144,
		part_id: 76,
		quantity: 175,
	},
	{
		garage_id: 144,
		part_id: 77,
		quantity: 199,
	},
	{
		garage_id: 144,
		part_id: 78,
		quantity: 314,
	},
	{
		garage_id: 144,
		part_id: 79,
		quantity: 429,
	},
	{
		garage_id: 144,
		part_id: 80,
		quantity: 426,
	},
	{
		garage_id: 144,
		part_id: 81,
		quantity: 395,
	},
	{
		garage_id: 144,
		part_id: 82,
		quantity: 401,
	},
	{
		garage_id: 144,
		part_id: 83,
		quantity: 470,
	},
	{
		garage_id: 144,
		part_id: 84,
		quantity: 272,
	},
	{
		garage_id: 144,
		part_id: 85,
		quantity: 378,
	},
	{
		garage_id: 144,
		part_id: 86,
		quantity: 38,
	},
	{
		garage_id: 144,
		part_id: 87,
		quantity: 253,
	},
	{
		garage_id: 144,
		part_id: 88,
		quantity: 363,
	},
	{
		garage_id: 144,
		part_id: 89,
		quantity: 367,
	},
	{
		garage_id: 144,
		part_id: 90,
		quantity: 381,
	},
	{
		garage_id: 144,
		part_id: 91,
		quantity: 64,
	},
	{
		garage_id: 144,
		part_id: 92,
		quantity: 27,
	},
	{
		garage_id: 144,
		part_id: 93,
		quantity: 251,
	},
	{
		garage_id: 144,
		part_id: 94,
		quantity: 400,
	},
	{
		garage_id: 144,
		part_id: 95,
		quantity: 253,
	},
	{
		garage_id: 144,
		part_id: 96,
		quantity: 44,
	},
	{
		garage_id: 144,
		part_id: 97,
		quantity: 480,
	},
	{
		garage_id: 144,
		part_id: 98,
		quantity: 278,
	},
	{
		garage_id: 144,
		part_id: 99,
		quantity: 284,
	},
	{
		garage_id: 144,
		part_id: 100,
		quantity: 465,
	},
	{
		garage_id: 144,
		part_id: 101,
		quantity: 426,
	},
	{
		garage_id: 144,
		part_id: 102,
		quantity: 93,
	},
	{
		garage_id: 144,
		part_id: 103,
		quantity: 395,
	},
	{
		garage_id: 144,
		part_id: 104,
		quantity: 26,
	},
	{
		garage_id: 144,
		part_id: 105,
		quantity: 87,
	},
	{
		garage_id: 144,
		part_id: 106,
		quantity: 401,
	},
	{
		garage_id: 144,
		part_id: 107,
		quantity: 240,
	},
	{
		garage_id: 144,
		part_id: 108,
		quantity: 186,
	},
	{
		garage_id: 144,
		part_id: 109,
		quantity: 104,
	},
	{
		garage_id: 144,
		part_id: 110,
		quantity: 129,
	},
	{
		garage_id: 144,
		part_id: 111,
		quantity: 395,
	},
	{
		garage_id: 144,
		part_id: 112,
		quantity: 351,
	},
	{
		garage_id: 144,
		part_id: 113,
		quantity: 268,
	},
	{
		garage_id: 144,
		part_id: 114,
		quantity: 252,
	},
	{
		garage_id: 144,
		part_id: 115,
		quantity: 76,
	},
	{
		garage_id: 144,
		part_id: 116,
		quantity: 208,
	},
	{
		garage_id: 144,
		part_id: 117,
		quantity: 216,
	},
	{
		garage_id: 144,
		part_id: 118,
		quantity: 17,
	},
	{
		garage_id: 144,
		part_id: 119,
		quantity: 138,
	},
	{
		garage_id: 144,
		part_id: 120,
		quantity: 381,
	},
	{
		garage_id: 144,
		part_id: 121,
		quantity: 287,
	},
	{
		garage_id: 144,
		part_id: 122,
		quantity: 221,
	},
	{
		garage_id: 144,
		part_id: 123,
		quantity: 281,
	},
	{
		garage_id: 144,
		part_id: 124,
		quantity: 217,
	},
	{
		garage_id: 144,
		part_id: 125,
		quantity: 450,
	},
	{
		garage_id: 144,
		part_id: 126,
		quantity: 369,
	},
	{
		garage_id: 144,
		part_id: 127,
		quantity: 4,
	},
	{
		garage_id: 144,
		part_id: 128,
		quantity: 48,
	},
	{
		garage_id: 144,
		part_id: 129,
		quantity: 495,
	},
	{
		garage_id: 144,
		part_id: 130,
		quantity: 219,
	},
	{
		garage_id: 144,
		part_id: 131,
		quantity: 318,
	},
	{
		garage_id: 144,
		part_id: 132,
		quantity: 463,
	},
	{
		garage_id: 144,
		part_id: 133,
		quantity: 369,
	},
	{
		garage_id: 144,
		part_id: 134,
		quantity: 41,
	},
	{
		garage_id: 144,
		part_id: 135,
		quantity: 6,
	},
	{
		garage_id: 144,
		part_id: 136,
		quantity: 109,
	},
	{
		garage_id: 144,
		part_id: 137,
		quantity: 113,
	},
	{
		garage_id: 144,
		part_id: 138,
		quantity: 31,
	},
	{
		garage_id: 144,
		part_id: 139,
		quantity: 311,
	},
	{
		garage_id: 144,
		part_id: 140,
		quantity: 310,
	},
	{
		garage_id: 144,
		part_id: 141,
		quantity: 177,
	},
	{
		garage_id: 144,
		part_id: 142,
		quantity: 489,
	},
	{
		garage_id: 144,
		part_id: 143,
		quantity: 445,
	},
	{
		garage_id: 144,
		part_id: 144,
		quantity: 323,
	},
	{
		garage_id: 144,
		part_id: 145,
		quantity: 292,
	},
	{
		garage_id: 144,
		part_id: 146,
		quantity: 308,
	},
	{
		garage_id: 144,
		part_id: 147,
		quantity: 104,
	},
	{
		garage_id: 144,
		part_id: 148,
		quantity: 364,
	},
	{
		garage_id: 144,
		part_id: 149,
		quantity: 241,
	},
	{
		garage_id: 144,
		part_id: 150,
		quantity: 273,
	},
	{
		garage_id: 144,
		part_id: 151,
		quantity: 450,
	},
	{
		garage_id: 144,
		part_id: 152,
		quantity: 46,
	},
	{
		garage_id: 144,
		part_id: 153,
		quantity: 198,
	},
	{
		garage_id: 144,
		part_id: 154,
		quantity: 292,
	},
	{
		garage_id: 144,
		part_id: 155,
		quantity: 214,
	},
	{
		garage_id: 144,
		part_id: 156,
		quantity: 288,
	},
	{
		garage_id: 144,
		part_id: 157,
		quantity: 439,
	},
	{
		garage_id: 144,
		part_id: 158,
		quantity: 243,
	},
	{
		garage_id: 144,
		part_id: 159,
		quantity: 280,
	},
	{
		garage_id: 144,
		part_id: 160,
		quantity: 287,
	},
	{
		garage_id: 144,
		part_id: 161,
		quantity: 78,
	},
	{
		garage_id: 144,
		part_id: 162,
		quantity: 1,
	},
	{
		garage_id: 144,
		part_id: 163,
		quantity: 360,
	},
	{
		garage_id: 144,
		part_id: 164,
		quantity: 397,
	},
	{
		garage_id: 144,
		part_id: 165,
		quantity: 91,
	},
	{
		garage_id: 144,
		part_id: 166,
		quantity: 41,
	},
	{
		garage_id: 144,
		part_id: 167,
		quantity: 11,
	},
	{
		garage_id: 144,
		part_id: 168,
		quantity: 405,
	},
	{
		garage_id: 144,
		part_id: 169,
		quantity: 156,
	},
	{
		garage_id: 144,
		part_id: 170,
		quantity: 156,
	},
	{
		garage_id: 144,
		part_id: 171,
		quantity: 223,
	},
	{
		garage_id: 144,
		part_id: 172,
		quantity: 368,
	},
	{
		garage_id: 144,
		part_id: 173,
		quantity: 420,
	},
	{
		garage_id: 144,
		part_id: 174,
		quantity: 447,
	},
	{
		garage_id: 144,
		part_id: 175,
		quantity: 130,
	},
	{
		garage_id: 144,
		part_id: 176,
		quantity: 56,
	},
	{
		garage_id: 144,
		part_id: 177,
		quantity: 71,
	},
	{
		garage_id: 144,
		part_id: 178,
		quantity: 206,
	},
	{
		garage_id: 144,
		part_id: 179,
		quantity: 125,
	},
	{
		garage_id: 144,
		part_id: 180,
		quantity: 403,
	},
	{
		garage_id: 144,
		part_id: 181,
		quantity: 182,
	},
	{
		garage_id: 144,
		part_id: 182,
		quantity: 137,
	},
	{
		garage_id: 144,
		part_id: 183,
		quantity: 130,
	},
	{
		garage_id: 144,
		part_id: 184,
		quantity: 124,
	},
	{
		garage_id: 144,
		part_id: 185,
		quantity: 198,
	},
	{
		garage_id: 144,
		part_id: 186,
		quantity: 41,
	},
	{
		garage_id: 144,
		part_id: 187,
		quantity: 361,
	},
	{
		garage_id: 144,
		part_id: 188,
		quantity: 450,
	},
	{
		garage_id: 144,
		part_id: 189,
		quantity: 311,
	},
	{
		garage_id: 144,
		part_id: 190,
		quantity: 156,
	},
	{
		garage_id: 144,
		part_id: 191,
		quantity: 394,
	},
	{
		garage_id: 144,
		part_id: 192,
		quantity: 9,
	},
	{
		garage_id: 144,
		part_id: 193,
		quantity: 220,
	},
	{
		garage_id: 144,
		part_id: 194,
		quantity: 330,
	},
	{
		garage_id: 144,
		part_id: 195,
		quantity: 124,
	},
	{
		garage_id: 144,
		part_id: 196,
		quantity: 24,
	},
	{
		garage_id: 144,
		part_id: 197,
		quantity: 336,
	},
	{
		garage_id: 144,
		part_id: 198,
		quantity: 383,
	},
	{
		garage_id: 144,
		part_id: 199,
		quantity: 250,
	},
	{
		garage_id: 144,
		part_id: 200,
		quantity: 173,
	},
	{
		garage_id: 144,
		part_id: 201,
		quantity: 395,
	},
	{
		garage_id: 144,
		part_id: 202,
		quantity: 450,
	},
	{
		garage_id: 144,
		part_id: 203,
		quantity: 462,
	},
	{
		garage_id: 144,
		part_id: 204,
		quantity: 309,
	},
	{
		garage_id: 144,
		part_id: 205,
		quantity: 68,
	},
	{
		garage_id: 144,
		part_id: 206,
		quantity: 34,
	},
	{
		garage_id: 144,
		part_id: 207,
		quantity: 408,
	},
	{
		garage_id: 144,
		part_id: 208,
		quantity: 470,
	},
	{
		garage_id: 144,
		part_id: 209,
		quantity: 253,
	},
	{
		garage_id: 144,
		part_id: 210,
		quantity: 13,
	},
	{
		garage_id: 144,
		part_id: 211,
		quantity: 309,
	},
	{
		garage_id: 144,
		part_id: 212,
		quantity: 284,
	},
	{
		garage_id: 144,
		part_id: 213,
		quantity: 414,
	},
	{
		garage_id: 144,
		part_id: 214,
		quantity: 286,
	},
	{
		garage_id: 144,
		part_id: 215,
		quantity: 223,
	},
	{
		garage_id: 144,
		part_id: 216,
		quantity: 175,
	},
	{
		garage_id: 144,
		part_id: 217,
		quantity: 417,
	},
	{
		garage_id: 144,
		part_id: 218,
		quantity: 280,
	},
	{
		garage_id: 144,
		part_id: 219,
		quantity: 204,
	},
	{
		garage_id: 144,
		part_id: 220,
		quantity: 104,
	},
	{
		garage_id: 144,
		part_id: 221,
		quantity: 71,
	},
	{
		garage_id: 144,
		part_id: 222,
		quantity: 209,
	},
	{
		garage_id: 144,
		part_id: 223,
		quantity: 416,
	},
	{
		garage_id: 144,
		part_id: 224,
		quantity: 136,
	},
	{
		garage_id: 144,
		part_id: 225,
		quantity: 222,
	},
	{
		garage_id: 144,
		part_id: 226,
		quantity: 405,
	},
	{
		garage_id: 144,
		part_id: 227,
		quantity: 208,
	},
	{
		garage_id: 144,
		part_id: 228,
		quantity: 221,
	},
	{
		garage_id: 144,
		part_id: 229,
		quantity: 90,
	},
	{
		garage_id: 144,
		part_id: 230,
		quantity: 473,
	},
	{
		garage_id: 144,
		part_id: 231,
		quantity: 281,
	},
	{
		garage_id: 144,
		part_id: 232,
		quantity: 336,
	},
	{
		garage_id: 144,
		part_id: 233,
		quantity: 494,
	},
	{
		garage_id: 144,
		part_id: 234,
		quantity: 392,
	},
	{
		garage_id: 144,
		part_id: 235,
		quantity: 72,
	},
	{
		garage_id: 144,
		part_id: 236,
		quantity: 442,
	},
	{
		garage_id: 144,
		part_id: 237,
		quantity: 4,
	},
	{
		garage_id: 144,
		part_id: 238,
		quantity: 135,
	},
	{
		garage_id: 144,
		part_id: 239,
		quantity: 196,
	},
	{
		garage_id: 144,
		part_id: 240,
		quantity: 26,
	},
	{
		garage_id: 144,
		part_id: 241,
		quantity: 188,
	},
	{
		garage_id: 144,
		part_id: 242,
		quantity: 86,
	},
	{
		garage_id: 144,
		part_id: 243,
		quantity: 407,
	},
	{
		garage_id: 144,
		part_id: 244,
		quantity: 308,
	},
	{
		garage_id: 144,
		part_id: 245,
		quantity: 273,
	},
	{
		garage_id: 144,
		part_id: 246,
		quantity: 318,
	},
	{
		garage_id: 144,
		part_id: 247,
		quantity: 206,
	},
	{
		garage_id: 144,
		part_id: 248,
		quantity: 339,
	},
	{
		garage_id: 144,
		part_id: 249,
		quantity: 124,
	},
	{
		garage_id: 144,
		part_id: 250,
		quantity: 361,
	},
	{
		garage_id: 144,
		part_id: 251,
		quantity: 318,
	},
	{
		garage_id: 144,
		part_id: 252,
		quantity: 469,
	},
	{
		garage_id: 144,
		part_id: 253,
		quantity: 423,
	},
	{
		garage_id: 144,
		part_id: 254,
		quantity: 88,
	},
	{
		garage_id: 144,
		part_id: 255,
		quantity: 307,
	},
	{
		garage_id: 144,
		part_id: 256,
		quantity: 65,
	},
	{
		garage_id: 144,
		part_id: 257,
		quantity: 33,
	},
	{
		garage_id: 144,
		part_id: 258,
		quantity: 74,
	},
	{
		garage_id: 144,
		part_id: 259,
		quantity: 484,
	},
	{
		garage_id: 144,
		part_id: 260,
		quantity: 176,
	},
	{
		garage_id: 144,
		part_id: 261,
		quantity: 110,
	},
	{
		garage_id: 144,
		part_id: 262,
		quantity: 123,
	},
	{
		garage_id: 144,
		part_id: 263,
		quantity: 53,
	},
	{
		garage_id: 144,
		part_id: 264,
		quantity: 58,
	},
	{
		garage_id: 144,
		part_id: 265,
		quantity: 445,
	},
	{
		garage_id: 144,
		part_id: 266,
		quantity: 330,
	},
	{
		garage_id: 144,
		part_id: 267,
		quantity: 359,
	},
	{
		garage_id: 144,
		part_id: 268,
		quantity: 456,
	},
	{
		garage_id: 144,
		part_id: 269,
		quantity: 227,
	},
	{
		garage_id: 144,
		part_id: 270,
		quantity: 297,
	},
	{
		garage_id: 144,
		part_id: 271,
		quantity: 338,
	},
	{
		garage_id: 144,
		part_id: 272,
		quantity: 397,
	},
	{
		garage_id: 144,
		part_id: 273,
		quantity: 217,
	},
	{
		garage_id: 144,
		part_id: 274,
		quantity: 160,
	},
	{
		garage_id: 144,
		part_id: 275,
		quantity: 141,
	},
	{
		garage_id: 144,
		part_id: 276,
		quantity: 182,
	},
	{
		garage_id: 144,
		part_id: 277,
		quantity: 210,
	},
	{
		garage_id: 144,
		part_id: 278,
		quantity: 365,
	},
	{
		garage_id: 144,
		part_id: 279,
		quantity: 395,
	},
	{
		garage_id: 144,
		part_id: 280,
		quantity: 27,
	},
	{
		garage_id: 144,
		part_id: 281,
		quantity: 164,
	},
	{
		garage_id: 144,
		part_id: 282,
		quantity: 375,
	},
	{
		garage_id: 144,
		part_id: 283,
		quantity: 181,
	},
	{
		garage_id: 144,
		part_id: 284,
		quantity: 361,
	},
	{
		garage_id: 144,
		part_id: 285,
		quantity: 425,
	},
	{
		garage_id: 144,
		part_id: 286,
		quantity: 403,
	},
	{
		garage_id: 144,
		part_id: 287,
		quantity: 491,
	},
	{
		garage_id: 144,
		part_id: 288,
		quantity: 449,
	},
	{
		garage_id: 144,
		part_id: 289,
		quantity: 476,
	},
	{
		garage_id: 144,
		part_id: 290,
		quantity: 491,
	},
	{
		garage_id: 144,
		part_id: 291,
		quantity: 205,
	},
	{
		garage_id: 144,
		part_id: 292,
		quantity: 315,
	},
	{
		garage_id: 144,
		part_id: 293,
		quantity: 7,
	},
	{
		garage_id: 144,
		part_id: 294,
		quantity: 117,
	},
	{
		garage_id: 144,
		part_id: 295,
		quantity: 229,
	},
	{
		garage_id: 144,
		part_id: 296,
		quantity: 126,
	},
	{
		garage_id: 144,
		part_id: 297,
		quantity: 51,
	},
	{
		garage_id: 144,
		part_id: 298,
		quantity: 476,
	},
	{
		garage_id: 144,
		part_id: 299,
		quantity: 141,
	},
	{
		garage_id: 144,
		part_id: 300,
		quantity: 271,
	},
	{
		garage_id: 144,
		part_id: 301,
		quantity: 433,
	},
	{
		garage_id: 144,
		part_id: 302,
		quantity: 15,
	},
	{
		garage_id: 144,
		part_id: 303,
		quantity: 17,
	},
	{
		garage_id: 144,
		part_id: 304,
		quantity: 252,
	},
	{
		garage_id: 144,
		part_id: 305,
		quantity: 381,
	},
	{
		garage_id: 144,
		part_id: 306,
		quantity: 106,
	},
	{
		garage_id: 144,
		part_id: 307,
		quantity: 129,
	},
	{
		garage_id: 144,
		part_id: 308,
		quantity: 218,
	},
	{
		garage_id: 144,
		part_id: 309,
		quantity: 246,
	},
	{
		garage_id: 144,
		part_id: 310,
		quantity: 493,
	},
	{
		garage_id: 144,
		part_id: 311,
		quantity: 499,
	},
	{
		garage_id: 144,
		part_id: 312,
		quantity: 428,
	},
	{
		garage_id: 144,
		part_id: 313,
		quantity: 193,
	},
	{
		garage_id: 144,
		part_id: 314,
		quantity: 493,
	},
	{
		garage_id: 144,
		part_id: 315,
		quantity: 477,
	},
	{
		garage_id: 144,
		part_id: 316,
		quantity: 268,
	},
	{
		garage_id: 144,
		part_id: 317,
		quantity: 341,
	},
	{
		garage_id: 144,
		part_id: 318,
		quantity: 91,
	},
	{
		garage_id: 144,
		part_id: 319,
		quantity: 262,
	},
	{
		garage_id: 144,
		part_id: 320,
		quantity: 385,
	},
	{
		garage_id: 144,
		part_id: 321,
		quantity: 184,
	},
	{
		garage_id: 144,
		part_id: 322,
		quantity: 410,
	},
	{
		garage_id: 144,
		part_id: 323,
		quantity: 306,
	},
	{
		garage_id: 144,
		part_id: 324,
		quantity: 483,
	},
	{
		garage_id: 144,
		part_id: 325,
		quantity: 404,
	},
	{
		garage_id: 144,
		part_id: 326,
		quantity: 47,
	},
	{
		garage_id: 144,
		part_id: 327,
		quantity: 174,
	},
	{
		garage_id: 144,
		part_id: 328,
		quantity: 194,
	},
	{
		garage_id: 144,
		part_id: 329,
		quantity: 182,
	},
	{
		garage_id: 144,
		part_id: 330,
		quantity: 310,
	},
	{
		garage_id: 144,
		part_id: 331,
		quantity: 235,
	},
	{
		garage_id: 144,
		part_id: 332,
		quantity: 101,
	},
	{
		garage_id: 144,
		part_id: 333,
		quantity: 50,
	},
	{
		garage_id: 144,
		part_id: 334,
		quantity: 273,
	},
	{
		garage_id: 144,
		part_id: 335,
		quantity: 349,
	},
	{
		garage_id: 144,
		part_id: 336,
		quantity: 204,
	},
	{
		garage_id: 144,
		part_id: 337,
		quantity: 386,
	},
	{
		garage_id: 144,
		part_id: 338,
		quantity: 232,
	},
	{
		garage_id: 144,
		part_id: 339,
		quantity: 222,
	},
	{
		garage_id: 144,
		part_id: 340,
		quantity: 121,
	},
	{
		garage_id: 144,
		part_id: 341,
		quantity: 484,
	},
	{
		garage_id: 144,
		part_id: 342,
		quantity: 103,
	},
	{
		garage_id: 144,
		part_id: 343,
		quantity: 320,
	},
	{
		garage_id: 144,
		part_id: 344,
		quantity: 180,
	},
	{
		garage_id: 144,
		part_id: 345,
		quantity: 68,
	},
	{
		garage_id: 144,
		part_id: 346,
		quantity: 490,
	},
	{
		garage_id: 144,
		part_id: 347,
		quantity: 71,
	},
	{
		garage_id: 144,
		part_id: 348,
		quantity: 183,
	},
	{
		garage_id: 144,
		part_id: 349,
		quantity: 416,
	},
	{
		garage_id: 144,
		part_id: 350,
		quantity: 22,
	},
	{
		garage_id: 144,
		part_id: 351,
		quantity: 139,
	},
	{
		garage_id: 144,
		part_id: 352,
		quantity: 211,
	},
	{
		garage_id: 144,
		part_id: 353,
		quantity: 301,
	},
	{
		garage_id: 144,
		part_id: 354,
		quantity: 468,
	},
	{
		garage_id: 144,
		part_id: 355,
		quantity: 471,
	},
	{
		garage_id: 144,
		part_id: 356,
		quantity: 421,
	},
	{
		garage_id: 144,
		part_id: 357,
		quantity: 297,
	},
	{
		garage_id: 144,
		part_id: 358,
		quantity: 212,
	},
	{
		garage_id: 144,
		part_id: 359,
		quantity: 499,
	},
	{
		garage_id: 144,
		part_id: 360,
		quantity: 281,
	},
	{
		garage_id: 144,
		part_id: 361,
		quantity: 491,
	},
	{
		garage_id: 144,
		part_id: 362,
		quantity: 493,
	},
	{
		garage_id: 144,
		part_id: 363,
		quantity: 338,
	},
	{
		garage_id: 144,
		part_id: 364,
		quantity: 443,
	},
	{
		garage_id: 144,
		part_id: 365,
		quantity: 235,
	},
	{
		garage_id: 144,
		part_id: 366,
		quantity: 92,
	},
	{
		garage_id: 144,
		part_id: 367,
		quantity: 347,
	},
	{
		garage_id: 144,
		part_id: 368,
		quantity: 246,
	},
	{
		garage_id: 144,
		part_id: 369,
		quantity: 316,
	},
	{
		garage_id: 144,
		part_id: 370,
		quantity: 255,
	},
	{
		garage_id: 144,
		part_id: 371,
		quantity: 436,
	},
	{
		garage_id: 144,
		part_id: 372,
		quantity: 409,
	},
	{
		garage_id: 144,
		part_id: 373,
		quantity: 277,
	},
	{
		garage_id: 144,
		part_id: 374,
		quantity: 378,
	},
	{
		garage_id: 144,
		part_id: 375,
		quantity: 98,
	},
	{
		garage_id: 144,
		part_id: 376,
		quantity: 404,
	},
	{
		garage_id: 144,
		part_id: 377,
		quantity: 122,
	},
	{
		garage_id: 144,
		part_id: 378,
		quantity: 147,
	},
	{
		garage_id: 144,
		part_id: 379,
		quantity: 450,
	},
	{
		garage_id: 144,
		part_id: 380,
		quantity: 142,
	},
	{
		garage_id: 144,
		part_id: 381,
		quantity: 490,
	},
	{
		garage_id: 144,
		part_id: 382,
		quantity: 248,
	},
	{
		garage_id: 144,
		part_id: 383,
		quantity: 172,
	},
	{
		garage_id: 144,
		part_id: 384,
		quantity: 228,
	},
	{
		garage_id: 144,
		part_id: 385,
		quantity: 72,
	},
	{
		garage_id: 144,
		part_id: 386,
		quantity: 312,
	},
	{
		garage_id: 144,
		part_id: 387,
		quantity: 311,
	},
	{
		garage_id: 144,
		part_id: 388,
		quantity: 383,
	},
	{
		garage_id: 144,
		part_id: 389,
		quantity: 332,
	},
	{
		garage_id: 144,
		part_id: 390,
		quantity: 103,
	},
	{
		garage_id: 144,
		part_id: 391,
		quantity: 323,
	},
	{
		garage_id: 144,
		part_id: 392,
		quantity: 1,
	},
	{
		garage_id: 144,
		part_id: 393,
		quantity: 200,
	},
	{
		garage_id: 144,
		part_id: 394,
		quantity: 182,
	},
	{
		garage_id: 144,
		part_id: 395,
		quantity: 250,
	},
	{
		garage_id: 144,
		part_id: 396,
		quantity: 364,
	},
	{
		garage_id: 144,
		part_id: 397,
		quantity: 218,
	},
	{
		garage_id: 144,
		part_id: 398,
		quantity: 304,
	},
	{
		garage_id: 144,
		part_id: 399,
		quantity: 391,
	},
	{
		garage_id: 145,
		part_id: 0,
		quantity: 81,
	},
	{
		garage_id: 145,
		part_id: 1,
		quantity: 38,
	},
	{
		garage_id: 145,
		part_id: 2,
		quantity: 249,
	},
	{
		garage_id: 145,
		part_id: 3,
		quantity: 339,
	},
	{
		garage_id: 145,
		part_id: 4,
		quantity: 79,
	},
	{
		garage_id: 145,
		part_id: 5,
		quantity: 321,
	},
	{
		garage_id: 145,
		part_id: 6,
		quantity: 177,
	},
	{
		garage_id: 145,
		part_id: 7,
		quantity: 200,
	},
	{
		garage_id: 145,
		part_id: 8,
		quantity: 230,
	},
	{
		garage_id: 145,
		part_id: 9,
		quantity: 422,
	},
	{
		garage_id: 145,
		part_id: 10,
		quantity: 413,
	},
	{
		garage_id: 145,
		part_id: 11,
		quantity: 386,
	},
	{
		garage_id: 145,
		part_id: 12,
		quantity: 178,
	},
	{
		garage_id: 145,
		part_id: 13,
		quantity: 10,
	},
	{
		garage_id: 145,
		part_id: 14,
		quantity: 103,
	},
	{
		garage_id: 145,
		part_id: 15,
		quantity: 329,
	},
	{
		garage_id: 145,
		part_id: 16,
		quantity: 132,
	},
	{
		garage_id: 145,
		part_id: 17,
		quantity: 112,
	},
	{
		garage_id: 145,
		part_id: 18,
		quantity: 493,
	},
	{
		garage_id: 145,
		part_id: 19,
		quantity: 363,
	},
	{
		garage_id: 145,
		part_id: 20,
		quantity: 206,
	},
	{
		garage_id: 145,
		part_id: 21,
		quantity: 65,
	},
	{
		garage_id: 145,
		part_id: 22,
		quantity: 324,
	},
	{
		garage_id: 145,
		part_id: 23,
		quantity: 266,
	},
	{
		garage_id: 145,
		part_id: 24,
		quantity: 340,
	},
	{
		garage_id: 145,
		part_id: 25,
		quantity: 167,
	},
	{
		garage_id: 145,
		part_id: 26,
		quantity: 440,
	},
	{
		garage_id: 145,
		part_id: 27,
		quantity: 374,
	},
	{
		garage_id: 145,
		part_id: 28,
		quantity: 250,
	},
	{
		garage_id: 145,
		part_id: 29,
		quantity: 6,
	},
	{
		garage_id: 145,
		part_id: 30,
		quantity: 230,
	},
	{
		garage_id: 145,
		part_id: 31,
		quantity: 283,
	},
	{
		garage_id: 145,
		part_id: 32,
		quantity: 179,
	},
	{
		garage_id: 145,
		part_id: 33,
		quantity: 93,
	},
	{
		garage_id: 145,
		part_id: 34,
		quantity: 351,
	},
	{
		garage_id: 145,
		part_id: 35,
		quantity: 141,
	},
	{
		garage_id: 145,
		part_id: 36,
		quantity: 119,
	},
	{
		garage_id: 145,
		part_id: 37,
		quantity: 103,
	},
	{
		garage_id: 145,
		part_id: 38,
		quantity: 272,
	},
	{
		garage_id: 145,
		part_id: 39,
		quantity: 337,
	},
	{
		garage_id: 145,
		part_id: 40,
		quantity: 6,
	},
	{
		garage_id: 145,
		part_id: 41,
		quantity: 191,
	},
	{
		garage_id: 145,
		part_id: 42,
		quantity: 110,
	},
	{
		garage_id: 145,
		part_id: 43,
		quantity: 370,
	},
	{
		garage_id: 145,
		part_id: 44,
		quantity: 305,
	},
	{
		garage_id: 145,
		part_id: 45,
		quantity: 187,
	},
	{
		garage_id: 145,
		part_id: 46,
		quantity: 214,
	},
	{
		garage_id: 145,
		part_id: 47,
		quantity: 58,
	},
	{
		garage_id: 145,
		part_id: 48,
		quantity: 430,
	},
	{
		garage_id: 145,
		part_id: 49,
		quantity: 32,
	},
	{
		garage_id: 145,
		part_id: 50,
		quantity: 97,
	},
	{
		garage_id: 145,
		part_id: 51,
		quantity: 483,
	},
	{
		garage_id: 145,
		part_id: 52,
		quantity: 18,
	},
	{
		garage_id: 145,
		part_id: 53,
		quantity: 476,
	},
	{
		garage_id: 145,
		part_id: 54,
		quantity: 67,
	},
	{
		garage_id: 145,
		part_id: 55,
		quantity: 165,
	},
	{
		garage_id: 145,
		part_id: 56,
		quantity: 307,
	},
	{
		garage_id: 145,
		part_id: 57,
		quantity: 38,
	},
	{
		garage_id: 145,
		part_id: 58,
		quantity: 384,
	},
	{
		garage_id: 145,
		part_id: 59,
		quantity: 419,
	},
	{
		garage_id: 145,
		part_id: 60,
		quantity: 184,
	},
	{
		garage_id: 145,
		part_id: 61,
		quantity: 432,
	},
	{
		garage_id: 145,
		part_id: 62,
		quantity: 174,
	},
	{
		garage_id: 145,
		part_id: 63,
		quantity: 337,
	},
	{
		garage_id: 145,
		part_id: 64,
		quantity: 330,
	},
	{
		garage_id: 145,
		part_id: 65,
		quantity: 159,
	},
	{
		garage_id: 145,
		part_id: 66,
		quantity: 432,
	},
	{
		garage_id: 145,
		part_id: 67,
		quantity: 414,
	},
	{
		garage_id: 145,
		part_id: 68,
		quantity: 82,
	},
	{
		garage_id: 145,
		part_id: 69,
		quantity: 361,
	},
	{
		garage_id: 145,
		part_id: 70,
		quantity: 375,
	},
	{
		garage_id: 145,
		part_id: 71,
		quantity: 415,
	},
	{
		garage_id: 145,
		part_id: 72,
		quantity: 367,
	},
	{
		garage_id: 145,
		part_id: 73,
		quantity: 458,
	},
	{
		garage_id: 145,
		part_id: 74,
		quantity: 30,
	},
	{
		garage_id: 145,
		part_id: 75,
		quantity: 43,
	},
	{
		garage_id: 145,
		part_id: 76,
		quantity: 86,
	},
	{
		garage_id: 145,
		part_id: 77,
		quantity: 41,
	},
	{
		garage_id: 145,
		part_id: 78,
		quantity: 223,
	},
	{
		garage_id: 145,
		part_id: 79,
		quantity: 309,
	},
	{
		garage_id: 145,
		part_id: 80,
		quantity: 480,
	},
	{
		garage_id: 145,
		part_id: 81,
		quantity: 186,
	},
	{
		garage_id: 145,
		part_id: 82,
		quantity: 494,
	},
	{
		garage_id: 145,
		part_id: 83,
		quantity: 353,
	},
	{
		garage_id: 145,
		part_id: 84,
		quantity: 215,
	},
	{
		garage_id: 145,
		part_id: 85,
		quantity: 225,
	},
	{
		garage_id: 145,
		part_id: 86,
		quantity: 463,
	},
	{
		garage_id: 145,
		part_id: 87,
		quantity: 102,
	},
	{
		garage_id: 145,
		part_id: 88,
		quantity: 182,
	},
	{
		garage_id: 145,
		part_id: 89,
		quantity: 21,
	},
	{
		garage_id: 145,
		part_id: 90,
		quantity: 248,
	},
	{
		garage_id: 145,
		part_id: 91,
		quantity: 257,
	},
	{
		garage_id: 145,
		part_id: 92,
		quantity: 354,
	},
	{
		garage_id: 145,
		part_id: 93,
		quantity: 228,
	},
	{
		garage_id: 145,
		part_id: 94,
		quantity: 421,
	},
	{
		garage_id: 145,
		part_id: 95,
		quantity: 395,
	},
	{
		garage_id: 145,
		part_id: 96,
		quantity: 410,
	},
	{
		garage_id: 145,
		part_id: 97,
		quantity: 126,
	},
	{
		garage_id: 145,
		part_id: 98,
		quantity: 436,
	},
	{
		garage_id: 145,
		part_id: 99,
		quantity: 293,
	},
	{
		garage_id: 145,
		part_id: 100,
		quantity: 28,
	},
	{
		garage_id: 145,
		part_id: 101,
		quantity: 482,
	},
	{
		garage_id: 145,
		part_id: 102,
		quantity: 117,
	},
	{
		garage_id: 145,
		part_id: 103,
		quantity: 255,
	},
	{
		garage_id: 145,
		part_id: 104,
		quantity: 262,
	},
	{
		garage_id: 145,
		part_id: 105,
		quantity: 417,
	},
	{
		garage_id: 145,
		part_id: 106,
		quantity: 314,
	},
	{
		garage_id: 145,
		part_id: 107,
		quantity: 423,
	},
	{
		garage_id: 145,
		part_id: 108,
		quantity: 224,
	},
	{
		garage_id: 145,
		part_id: 109,
		quantity: 474,
	},
	{
		garage_id: 145,
		part_id: 110,
		quantity: 80,
	},
	{
		garage_id: 145,
		part_id: 111,
		quantity: 387,
	},
	{
		garage_id: 145,
		part_id: 112,
		quantity: 280,
	},
	{
		garage_id: 145,
		part_id: 113,
		quantity: 364,
	},
	{
		garage_id: 145,
		part_id: 114,
		quantity: 262,
	},
	{
		garage_id: 145,
		part_id: 115,
		quantity: 453,
	},
	{
		garage_id: 145,
		part_id: 116,
		quantity: 239,
	},
	{
		garage_id: 145,
		part_id: 117,
		quantity: 387,
	},
	{
		garage_id: 145,
		part_id: 118,
		quantity: 257,
	},
	{
		garage_id: 145,
		part_id: 119,
		quantity: 352,
	},
	{
		garage_id: 145,
		part_id: 120,
		quantity: 70,
	},
	{
		garage_id: 145,
		part_id: 121,
		quantity: 87,
	},
	{
		garage_id: 145,
		part_id: 122,
		quantity: 280,
	},
	{
		garage_id: 145,
		part_id: 123,
		quantity: 372,
	},
	{
		garage_id: 145,
		part_id: 124,
		quantity: 124,
	},
	{
		garage_id: 145,
		part_id: 125,
		quantity: 448,
	},
	{
		garage_id: 145,
		part_id: 126,
		quantity: 366,
	},
	{
		garage_id: 145,
		part_id: 127,
		quantity: 75,
	},
	{
		garage_id: 145,
		part_id: 128,
		quantity: 35,
	},
	{
		garage_id: 145,
		part_id: 129,
		quantity: 314,
	},
	{
		garage_id: 145,
		part_id: 130,
		quantity: 245,
	},
	{
		garage_id: 145,
		part_id: 131,
		quantity: 218,
	},
	{
		garage_id: 145,
		part_id: 132,
		quantity: 209,
	},
	{
		garage_id: 145,
		part_id: 133,
		quantity: 461,
	},
	{
		garage_id: 145,
		part_id: 134,
		quantity: 222,
	},
	{
		garage_id: 145,
		part_id: 135,
		quantity: 462,
	},
	{
		garage_id: 145,
		part_id: 136,
		quantity: 465,
	},
	{
		garage_id: 145,
		part_id: 137,
		quantity: 86,
	},
	{
		garage_id: 145,
		part_id: 138,
		quantity: 301,
	},
	{
		garage_id: 145,
		part_id: 139,
		quantity: 145,
	},
	{
		garage_id: 145,
		part_id: 140,
		quantity: 46,
	},
	{
		garage_id: 145,
		part_id: 141,
		quantity: 434,
	},
	{
		garage_id: 145,
		part_id: 142,
		quantity: 433,
	},
	{
		garage_id: 145,
		part_id: 143,
		quantity: 253,
	},
	{
		garage_id: 145,
		part_id: 144,
		quantity: 134,
	},
	{
		garage_id: 145,
		part_id: 145,
		quantity: 326,
	},
	{
		garage_id: 145,
		part_id: 146,
		quantity: 65,
	},
	{
		garage_id: 145,
		part_id: 147,
		quantity: 84,
	},
	{
		garage_id: 145,
		part_id: 148,
		quantity: 274,
	},
	{
		garage_id: 145,
		part_id: 149,
		quantity: 350,
	},
	{
		garage_id: 145,
		part_id: 150,
		quantity: 96,
	},
	{
		garage_id: 145,
		part_id: 151,
		quantity: 9,
	},
	{
		garage_id: 145,
		part_id: 152,
		quantity: 78,
	},
	{
		garage_id: 145,
		part_id: 153,
		quantity: 139,
	},
	{
		garage_id: 145,
		part_id: 154,
		quantity: 477,
	},
	{
		garage_id: 145,
		part_id: 155,
		quantity: 179,
	},
	{
		garage_id: 145,
		part_id: 156,
		quantity: 222,
	},
	{
		garage_id: 145,
		part_id: 157,
		quantity: 105,
	},
	{
		garage_id: 145,
		part_id: 158,
		quantity: 80,
	},
	{
		garage_id: 145,
		part_id: 159,
		quantity: 234,
	},
	{
		garage_id: 145,
		part_id: 160,
		quantity: 97,
	},
	{
		garage_id: 145,
		part_id: 161,
		quantity: 329,
	},
	{
		garage_id: 145,
		part_id: 162,
		quantity: 467,
	},
	{
		garage_id: 145,
		part_id: 163,
		quantity: 99,
	},
	{
		garage_id: 145,
		part_id: 164,
		quantity: 209,
	},
	{
		garage_id: 145,
		part_id: 165,
		quantity: 89,
	},
	{
		garage_id: 145,
		part_id: 166,
		quantity: 396,
	},
	{
		garage_id: 145,
		part_id: 167,
		quantity: 458,
	},
	{
		garage_id: 145,
		part_id: 168,
		quantity: 201,
	},
	{
		garage_id: 145,
		part_id: 169,
		quantity: 304,
	},
	{
		garage_id: 145,
		part_id: 170,
		quantity: 425,
	},
	{
		garage_id: 145,
		part_id: 171,
		quantity: 49,
	},
	{
		garage_id: 145,
		part_id: 172,
		quantity: 182,
	},
	{
		garage_id: 145,
		part_id: 173,
		quantity: 277,
	},
	{
		garage_id: 145,
		part_id: 174,
		quantity: 188,
	},
	{
		garage_id: 145,
		part_id: 175,
		quantity: 447,
	},
	{
		garage_id: 145,
		part_id: 176,
		quantity: 441,
	},
	{
		garage_id: 145,
		part_id: 177,
		quantity: 152,
	},
	{
		garage_id: 145,
		part_id: 178,
		quantity: 415,
	},
	{
		garage_id: 145,
		part_id: 179,
		quantity: 417,
	},
	{
		garage_id: 145,
		part_id: 180,
		quantity: 135,
	},
	{
		garage_id: 145,
		part_id: 181,
		quantity: 6,
	},
	{
		garage_id: 145,
		part_id: 182,
		quantity: 452,
	},
	{
		garage_id: 145,
		part_id: 183,
		quantity: 327,
	},
	{
		garage_id: 145,
		part_id: 184,
		quantity: 292,
	},
	{
		garage_id: 145,
		part_id: 185,
		quantity: 234,
	},
	{
		garage_id: 145,
		part_id: 186,
		quantity: 247,
	},
	{
		garage_id: 145,
		part_id: 187,
		quantity: 291,
	},
	{
		garage_id: 145,
		part_id: 188,
		quantity: 494,
	},
	{
		garage_id: 145,
		part_id: 189,
		quantity: 324,
	},
	{
		garage_id: 145,
		part_id: 190,
		quantity: 137,
	},
	{
		garage_id: 145,
		part_id: 191,
		quantity: 316,
	},
	{
		garage_id: 145,
		part_id: 192,
		quantity: 453,
	},
	{
		garage_id: 145,
		part_id: 193,
		quantity: 50,
	},
	{
		garage_id: 145,
		part_id: 194,
		quantity: 422,
	},
	{
		garage_id: 145,
		part_id: 195,
		quantity: 386,
	},
	{
		garage_id: 145,
		part_id: 196,
		quantity: 333,
	},
	{
		garage_id: 145,
		part_id: 197,
		quantity: 41,
	},
	{
		garage_id: 145,
		part_id: 198,
		quantity: 215,
	},
	{
		garage_id: 145,
		part_id: 199,
		quantity: 491,
	},
	{
		garage_id: 145,
		part_id: 200,
		quantity: 345,
	},
	{
		garage_id: 145,
		part_id: 201,
		quantity: 93,
	},
	{
		garage_id: 145,
		part_id: 202,
		quantity: 18,
	},
	{
		garage_id: 145,
		part_id: 203,
		quantity: 136,
	},
	{
		garage_id: 145,
		part_id: 204,
		quantity: 327,
	},
	{
		garage_id: 145,
		part_id: 205,
		quantity: 219,
	},
	{
		garage_id: 145,
		part_id: 206,
		quantity: 461,
	},
	{
		garage_id: 145,
		part_id: 207,
		quantity: 329,
	},
	{
		garage_id: 145,
		part_id: 208,
		quantity: 97,
	},
	{
		garage_id: 145,
		part_id: 209,
		quantity: 158,
	},
	{
		garage_id: 145,
		part_id: 210,
		quantity: 346,
	},
	{
		garage_id: 145,
		part_id: 211,
		quantity: 306,
	},
	{
		garage_id: 145,
		part_id: 212,
		quantity: 244,
	},
	{
		garage_id: 145,
		part_id: 213,
		quantity: 167,
	},
	{
		garage_id: 145,
		part_id: 214,
		quantity: 24,
	},
	{
		garage_id: 145,
		part_id: 215,
		quantity: 25,
	},
	{
		garage_id: 145,
		part_id: 216,
		quantity: 236,
	},
	{
		garage_id: 145,
		part_id: 217,
		quantity: 489,
	},
	{
		garage_id: 145,
		part_id: 218,
		quantity: 44,
	},
	{
		garage_id: 145,
		part_id: 219,
		quantity: 55,
	},
	{
		garage_id: 145,
		part_id: 220,
		quantity: 89,
	},
	{
		garage_id: 145,
		part_id: 221,
		quantity: 168,
	},
	{
		garage_id: 145,
		part_id: 222,
		quantity: 181,
	},
	{
		garage_id: 145,
		part_id: 223,
		quantity: 483,
	},
	{
		garage_id: 145,
		part_id: 224,
		quantity: 51,
	},
	{
		garage_id: 145,
		part_id: 225,
		quantity: 430,
	},
	{
		garage_id: 145,
		part_id: 226,
		quantity: 434,
	},
	{
		garage_id: 145,
		part_id: 227,
		quantity: 75,
	},
	{
		garage_id: 145,
		part_id: 228,
		quantity: 29,
	},
	{
		garage_id: 145,
		part_id: 229,
		quantity: 133,
	},
	{
		garage_id: 145,
		part_id: 230,
		quantity: 300,
	},
	{
		garage_id: 145,
		part_id: 231,
		quantity: 410,
	},
	{
		garage_id: 145,
		part_id: 232,
		quantity: 326,
	},
	{
		garage_id: 145,
		part_id: 233,
		quantity: 307,
	},
	{
		garage_id: 145,
		part_id: 234,
		quantity: 473,
	},
	{
		garage_id: 145,
		part_id: 235,
		quantity: 188,
	},
	{
		garage_id: 145,
		part_id: 236,
		quantity: 218,
	},
	{
		garage_id: 145,
		part_id: 237,
		quantity: 158,
	},
	{
		garage_id: 145,
		part_id: 238,
		quantity: 328,
	},
	{
		garage_id: 145,
		part_id: 239,
		quantity: 499,
	},
	{
		garage_id: 145,
		part_id: 240,
		quantity: 26,
	},
	{
		garage_id: 145,
		part_id: 241,
		quantity: 29,
	},
	{
		garage_id: 145,
		part_id: 242,
		quantity: 186,
	},
	{
		garage_id: 145,
		part_id: 243,
		quantity: 392,
	},
	{
		garage_id: 145,
		part_id: 244,
		quantity: 22,
	},
	{
		garage_id: 145,
		part_id: 245,
		quantity: 240,
	},
	{
		garage_id: 145,
		part_id: 246,
		quantity: 392,
	},
	{
		garage_id: 145,
		part_id: 247,
		quantity: 342,
	},
	{
		garage_id: 145,
		part_id: 248,
		quantity: 29,
	},
	{
		garage_id: 145,
		part_id: 249,
		quantity: 65,
	},
	{
		garage_id: 145,
		part_id: 250,
		quantity: 499,
	},
	{
		garage_id: 145,
		part_id: 251,
		quantity: 412,
	},
	{
		garage_id: 145,
		part_id: 252,
		quantity: 481,
	},
	{
		garage_id: 145,
		part_id: 253,
		quantity: 462,
	},
	{
		garage_id: 145,
		part_id: 254,
		quantity: 16,
	},
	{
		garage_id: 145,
		part_id: 255,
		quantity: 86,
	},
	{
		garage_id: 145,
		part_id: 256,
		quantity: 73,
	},
	{
		garage_id: 145,
		part_id: 257,
		quantity: 382,
	},
	{
		garage_id: 145,
		part_id: 258,
		quantity: 160,
	},
	{
		garage_id: 145,
		part_id: 259,
		quantity: 496,
	},
	{
		garage_id: 145,
		part_id: 260,
		quantity: 74,
	},
	{
		garage_id: 145,
		part_id: 261,
		quantity: 152,
	},
	{
		garage_id: 145,
		part_id: 262,
		quantity: 108,
	},
	{
		garage_id: 145,
		part_id: 263,
		quantity: 106,
	},
	{
		garage_id: 145,
		part_id: 264,
		quantity: 496,
	},
	{
		garage_id: 145,
		part_id: 265,
		quantity: 280,
	},
	{
		garage_id: 145,
		part_id: 266,
		quantity: 84,
	},
	{
		garage_id: 145,
		part_id: 267,
		quantity: 247,
	},
	{
		garage_id: 145,
		part_id: 268,
		quantity: 85,
	},
	{
		garage_id: 145,
		part_id: 269,
		quantity: 12,
	},
	{
		garage_id: 145,
		part_id: 270,
		quantity: 242,
	},
	{
		garage_id: 145,
		part_id: 271,
		quantity: 81,
	},
	{
		garage_id: 145,
		part_id: 272,
		quantity: 137,
	},
	{
		garage_id: 145,
		part_id: 273,
		quantity: 435,
	},
	{
		garage_id: 145,
		part_id: 274,
		quantity: 51,
	},
	{
		garage_id: 145,
		part_id: 275,
		quantity: 449,
	},
	{
		garage_id: 145,
		part_id: 276,
		quantity: 285,
	},
	{
		garage_id: 145,
		part_id: 277,
		quantity: 482,
	},
	{
		garage_id: 145,
		part_id: 278,
		quantity: 200,
	},
	{
		garage_id: 145,
		part_id: 279,
		quantity: 127,
	},
	{
		garage_id: 145,
		part_id: 280,
		quantity: 418,
	},
	{
		garage_id: 145,
		part_id: 281,
		quantity: 126,
	},
	{
		garage_id: 145,
		part_id: 282,
		quantity: 308,
	},
	{
		garage_id: 145,
		part_id: 283,
		quantity: 485,
	},
	{
		garage_id: 145,
		part_id: 284,
		quantity: 464,
	},
	{
		garage_id: 145,
		part_id: 285,
		quantity: 35,
	},
	{
		garage_id: 145,
		part_id: 286,
		quantity: 83,
	},
	{
		garage_id: 145,
		part_id: 287,
		quantity: 106,
	},
	{
		garage_id: 145,
		part_id: 288,
		quantity: 280,
	},
	{
		garage_id: 145,
		part_id: 289,
		quantity: 434,
	},
	{
		garage_id: 145,
		part_id: 290,
		quantity: 311,
	},
	{
		garage_id: 145,
		part_id: 291,
		quantity: 140,
	},
	{
		garage_id: 145,
		part_id: 292,
		quantity: 277,
	},
	{
		garage_id: 145,
		part_id: 293,
		quantity: 304,
	},
	{
		garage_id: 145,
		part_id: 294,
		quantity: 339,
	},
	{
		garage_id: 145,
		part_id: 295,
		quantity: 63,
	},
	{
		garage_id: 145,
		part_id: 296,
		quantity: 191,
	},
	{
		garage_id: 145,
		part_id: 297,
		quantity: 14,
	},
	{
		garage_id: 145,
		part_id: 298,
		quantity: 65,
	},
	{
		garage_id: 145,
		part_id: 299,
		quantity: 54,
	},
	{
		garage_id: 145,
		part_id: 300,
		quantity: 289,
	},
	{
		garage_id: 145,
		part_id: 301,
		quantity: 53,
	},
	{
		garage_id: 145,
		part_id: 302,
		quantity: 185,
	},
	{
		garage_id: 145,
		part_id: 303,
		quantity: 282,
	},
	{
		garage_id: 145,
		part_id: 304,
		quantity: 498,
	},
	{
		garage_id: 145,
		part_id: 305,
		quantity: 452,
	},
	{
		garage_id: 145,
		part_id: 306,
		quantity: 12,
	},
	{
		garage_id: 145,
		part_id: 307,
		quantity: 171,
	},
	{
		garage_id: 145,
		part_id: 308,
		quantity: 313,
	},
	{
		garage_id: 145,
		part_id: 309,
		quantity: 445,
	},
	{
		garage_id: 145,
		part_id: 310,
		quantity: 479,
	},
	{
		garage_id: 145,
		part_id: 311,
		quantity: 203,
	},
	{
		garage_id: 145,
		part_id: 312,
		quantity: 388,
	},
	{
		garage_id: 145,
		part_id: 313,
		quantity: 474,
	},
	{
		garage_id: 145,
		part_id: 314,
		quantity: 14,
	},
	{
		garage_id: 145,
		part_id: 315,
		quantity: 343,
	},
	{
		garage_id: 145,
		part_id: 316,
		quantity: 500,
	},
	{
		garage_id: 145,
		part_id: 317,
		quantity: 370,
	},
	{
		garage_id: 145,
		part_id: 318,
		quantity: 487,
	},
	{
		garage_id: 145,
		part_id: 319,
		quantity: 34,
	},
	{
		garage_id: 145,
		part_id: 320,
		quantity: 458,
	},
	{
		garage_id: 145,
		part_id: 321,
		quantity: 413,
	},
	{
		garage_id: 145,
		part_id: 322,
		quantity: 332,
	},
	{
		garage_id: 145,
		part_id: 323,
		quantity: 178,
	},
	{
		garage_id: 145,
		part_id: 324,
		quantity: 301,
	},
	{
		garage_id: 145,
		part_id: 325,
		quantity: 349,
	},
	{
		garage_id: 145,
		part_id: 326,
		quantity: 116,
	},
	{
		garage_id: 145,
		part_id: 327,
		quantity: 168,
	},
	{
		garage_id: 145,
		part_id: 328,
		quantity: 246,
	},
	{
		garage_id: 145,
		part_id: 329,
		quantity: 193,
	},
	{
		garage_id: 145,
		part_id: 330,
		quantity: 308,
	},
	{
		garage_id: 145,
		part_id: 331,
		quantity: 144,
	},
	{
		garage_id: 145,
		part_id: 332,
		quantity: 125,
	},
	{
		garage_id: 145,
		part_id: 333,
		quantity: 280,
	},
	{
		garage_id: 145,
		part_id: 334,
		quantity: 391,
	},
	{
		garage_id: 145,
		part_id: 335,
		quantity: 181,
	},
	{
		garage_id: 145,
		part_id: 336,
		quantity: 221,
	},
	{
		garage_id: 145,
		part_id: 337,
		quantity: 418,
	},
	{
		garage_id: 145,
		part_id: 338,
		quantity: 353,
	},
	{
		garage_id: 145,
		part_id: 339,
		quantity: 62,
	},
	{
		garage_id: 145,
		part_id: 340,
		quantity: 382,
	},
	{
		garage_id: 145,
		part_id: 341,
		quantity: 277,
	},
	{
		garage_id: 145,
		part_id: 342,
		quantity: 15,
	},
	{
		garage_id: 145,
		part_id: 343,
		quantity: 220,
	},
	{
		garage_id: 145,
		part_id: 344,
		quantity: 491,
	},
	{
		garage_id: 145,
		part_id: 345,
		quantity: 56,
	},
	{
		garage_id: 145,
		part_id: 346,
		quantity: 460,
	},
	{
		garage_id: 145,
		part_id: 347,
		quantity: 342,
	},
	{
		garage_id: 145,
		part_id: 348,
		quantity: 431,
	},
	{
		garage_id: 145,
		part_id: 349,
		quantity: 407,
	},
	{
		garage_id: 145,
		part_id: 350,
		quantity: 331,
	},
	{
		garage_id: 145,
		part_id: 351,
		quantity: 225,
	},
	{
		garage_id: 145,
		part_id: 352,
		quantity: 449,
	},
	{
		garage_id: 145,
		part_id: 353,
		quantity: 96,
	},
	{
		garage_id: 145,
		part_id: 354,
		quantity: 453,
	},
	{
		garage_id: 145,
		part_id: 355,
		quantity: 449,
	},
	{
		garage_id: 145,
		part_id: 356,
		quantity: 124,
	},
	{
		garage_id: 145,
		part_id: 357,
		quantity: 22,
	},
	{
		garage_id: 145,
		part_id: 358,
		quantity: 237,
	},
	{
		garage_id: 145,
		part_id: 359,
		quantity: 378,
	},
	{
		garage_id: 145,
		part_id: 360,
		quantity: 78,
	},
	{
		garage_id: 145,
		part_id: 361,
		quantity: 31,
	},
	{
		garage_id: 145,
		part_id: 362,
		quantity: 209,
	},
	{
		garage_id: 145,
		part_id: 363,
		quantity: 248,
	},
	{
		garage_id: 145,
		part_id: 364,
		quantity: 6,
	},
	{
		garage_id: 145,
		part_id: 365,
		quantity: 295,
	},
	{
		garage_id: 145,
		part_id: 366,
		quantity: 490,
	},
	{
		garage_id: 145,
		part_id: 367,
		quantity: 232,
	},
	{
		garage_id: 145,
		part_id: 368,
		quantity: 407,
	},
	{
		garage_id: 145,
		part_id: 369,
		quantity: 180,
	},
	{
		garage_id: 145,
		part_id: 370,
		quantity: 148,
	},
	{
		garage_id: 145,
		part_id: 371,
		quantity: 32,
	},
	{
		garage_id: 145,
		part_id: 372,
		quantity: 78,
	},
	{
		garage_id: 145,
		part_id: 373,
		quantity: 360,
	},
	{
		garage_id: 145,
		part_id: 374,
		quantity: 90,
	},
	{
		garage_id: 145,
		part_id: 375,
		quantity: 464,
	},
	{
		garage_id: 145,
		part_id: 376,
		quantity: 100,
	},
	{
		garage_id: 145,
		part_id: 377,
		quantity: 7,
	},
	{
		garage_id: 145,
		part_id: 378,
		quantity: 96,
	},
	{
		garage_id: 145,
		part_id: 379,
		quantity: 304,
	},
	{
		garage_id: 145,
		part_id: 380,
		quantity: 148,
	},
	{
		garage_id: 145,
		part_id: 381,
		quantity: 481,
	},
	{
		garage_id: 145,
		part_id: 382,
		quantity: 220,
	},
	{
		garage_id: 145,
		part_id: 383,
		quantity: 383,
	},
	{
		garage_id: 145,
		part_id: 384,
		quantity: 26,
	},
	{
		garage_id: 145,
		part_id: 385,
		quantity: 224,
	},
	{
		garage_id: 145,
		part_id: 386,
		quantity: 193,
	},
	{
		garage_id: 145,
		part_id: 387,
		quantity: 454,
	},
	{
		garage_id: 145,
		part_id: 388,
		quantity: 100,
	},
	{
		garage_id: 145,
		part_id: 389,
		quantity: 393,
	},
	{
		garage_id: 145,
		part_id: 390,
		quantity: 134,
	},
	{
		garage_id: 145,
		part_id: 391,
		quantity: 411,
	},
	{
		garage_id: 145,
		part_id: 392,
		quantity: 321,
	},
	{
		garage_id: 145,
		part_id: 393,
		quantity: 180,
	},
	{
		garage_id: 145,
		part_id: 394,
		quantity: 309,
	},
	{
		garage_id: 145,
		part_id: 395,
		quantity: 181,
	},
	{
		garage_id: 145,
		part_id: 396,
		quantity: 460,
	},
	{
		garage_id: 145,
		part_id: 397,
		quantity: 167,
	},
	{
		garage_id: 145,
		part_id: 398,
		quantity: 281,
	},
	{
		garage_id: 145,
		part_id: 399,
		quantity: 229,
	},
	{
		garage_id: 146,
		part_id: 0,
		quantity: 77,
	},
	{
		garage_id: 146,
		part_id: 1,
		quantity: 0,
	},
	{
		garage_id: 146,
		part_id: 2,
		quantity: 444,
	},
	{
		garage_id: 146,
		part_id: 3,
		quantity: 34,
	},
	{
		garage_id: 146,
		part_id: 4,
		quantity: 51,
	},
	{
		garage_id: 146,
		part_id: 5,
		quantity: 101,
	},
	{
		garage_id: 146,
		part_id: 6,
		quantity: 16,
	},
	{
		garage_id: 146,
		part_id: 7,
		quantity: 169,
	},
	{
		garage_id: 146,
		part_id: 8,
		quantity: 64,
	},
	{
		garage_id: 146,
		part_id: 9,
		quantity: 323,
	},
	{
		garage_id: 146,
		part_id: 10,
		quantity: 473,
	},
	{
		garage_id: 146,
		part_id: 11,
		quantity: 155,
	},
	{
		garage_id: 146,
		part_id: 12,
		quantity: 146,
	},
	{
		garage_id: 146,
		part_id: 13,
		quantity: 31,
	},
	{
		garage_id: 146,
		part_id: 14,
		quantity: 233,
	},
	{
		garage_id: 146,
		part_id: 15,
		quantity: 350,
	},
	{
		garage_id: 146,
		part_id: 16,
		quantity: 459,
	},
	{
		garage_id: 146,
		part_id: 17,
		quantity: 207,
	},
	{
		garage_id: 146,
		part_id: 18,
		quantity: 145,
	},
	{
		garage_id: 146,
		part_id: 19,
		quantity: 220,
	},
	{
		garage_id: 146,
		part_id: 20,
		quantity: 284,
	},
	{
		garage_id: 146,
		part_id: 21,
		quantity: 293,
	},
	{
		garage_id: 146,
		part_id: 22,
		quantity: 64,
	},
	{
		garage_id: 146,
		part_id: 23,
		quantity: 400,
	},
	{
		garage_id: 146,
		part_id: 24,
		quantity: 194,
	},
	{
		garage_id: 146,
		part_id: 25,
		quantity: 353,
	},
	{
		garage_id: 146,
		part_id: 26,
		quantity: 79,
	},
	{
		garage_id: 146,
		part_id: 27,
		quantity: 277,
	},
	{
		garage_id: 146,
		part_id: 28,
		quantity: 476,
	},
	{
		garage_id: 146,
		part_id: 29,
		quantity: 20,
	},
	{
		garage_id: 146,
		part_id: 30,
		quantity: 340,
	},
	{
		garage_id: 146,
		part_id: 31,
		quantity: 33,
	},
	{
		garage_id: 146,
		part_id: 32,
		quantity: 321,
	},
	{
		garage_id: 146,
		part_id: 33,
		quantity: 163,
	},
	{
		garage_id: 146,
		part_id: 34,
		quantity: 171,
	},
	{
		garage_id: 146,
		part_id: 35,
		quantity: 408,
	},
	{
		garage_id: 146,
		part_id: 36,
		quantity: 204,
	},
	{
		garage_id: 146,
		part_id: 37,
		quantity: 128,
	},
	{
		garage_id: 146,
		part_id: 38,
		quantity: 206,
	},
	{
		garage_id: 146,
		part_id: 39,
		quantity: 44,
	},
	{
		garage_id: 146,
		part_id: 40,
		quantity: 129,
	},
	{
		garage_id: 146,
		part_id: 41,
		quantity: 335,
	},
	{
		garage_id: 146,
		part_id: 42,
		quantity: 455,
	},
	{
		garage_id: 146,
		part_id: 43,
		quantity: 252,
	},
	{
		garage_id: 146,
		part_id: 44,
		quantity: 164,
	},
	{
		garage_id: 146,
		part_id: 45,
		quantity: 161,
	},
	{
		garage_id: 146,
		part_id: 46,
		quantity: 481,
	},
	{
		garage_id: 146,
		part_id: 47,
		quantity: 426,
	},
	{
		garage_id: 146,
		part_id: 48,
		quantity: 265,
	},
	{
		garage_id: 146,
		part_id: 49,
		quantity: 134,
	},
	{
		garage_id: 146,
		part_id: 50,
		quantity: 169,
	},
	{
		garage_id: 146,
		part_id: 51,
		quantity: 307,
	},
	{
		garage_id: 146,
		part_id: 52,
		quantity: 359,
	},
	{
		garage_id: 146,
		part_id: 53,
		quantity: 279,
	},
	{
		garage_id: 146,
		part_id: 54,
		quantity: 117,
	},
	{
		garage_id: 146,
		part_id: 55,
		quantity: 322,
	},
	{
		garage_id: 146,
		part_id: 56,
		quantity: 441,
	},
	{
		garage_id: 146,
		part_id: 57,
		quantity: 302,
	},
	{
		garage_id: 146,
		part_id: 58,
		quantity: 245,
	},
	{
		garage_id: 146,
		part_id: 59,
		quantity: 452,
	},
	{
		garage_id: 146,
		part_id: 60,
		quantity: 261,
	},
	{
		garage_id: 146,
		part_id: 61,
		quantity: 78,
	},
	{
		garage_id: 146,
		part_id: 62,
		quantity: 432,
	},
	{
		garage_id: 146,
		part_id: 63,
		quantity: 310,
	},
	{
		garage_id: 146,
		part_id: 64,
		quantity: 322,
	},
	{
		garage_id: 146,
		part_id: 65,
		quantity: 334,
	},
	{
		garage_id: 146,
		part_id: 66,
		quantity: 25,
	},
	{
		garage_id: 146,
		part_id: 67,
		quantity: 265,
	},
	{
		garage_id: 146,
		part_id: 68,
		quantity: 329,
	},
	{
		garage_id: 146,
		part_id: 69,
		quantity: 198,
	},
	{
		garage_id: 146,
		part_id: 70,
		quantity: 148,
	},
	{
		garage_id: 146,
		part_id: 71,
		quantity: 311,
	},
	{
		garage_id: 146,
		part_id: 72,
		quantity: 276,
	},
	{
		garage_id: 146,
		part_id: 73,
		quantity: 172,
	},
	{
		garage_id: 146,
		part_id: 74,
		quantity: 461,
	},
	{
		garage_id: 146,
		part_id: 75,
		quantity: 27,
	},
	{
		garage_id: 146,
		part_id: 76,
		quantity: 10,
	},
	{
		garage_id: 146,
		part_id: 77,
		quantity: 315,
	},
	{
		garage_id: 146,
		part_id: 78,
		quantity: 124,
	},
	{
		garage_id: 146,
		part_id: 79,
		quantity: 192,
	},
	{
		garage_id: 146,
		part_id: 80,
		quantity: 356,
	},
	{
		garage_id: 146,
		part_id: 81,
		quantity: 237,
	},
	{
		garage_id: 146,
		part_id: 82,
		quantity: 61,
	},
	{
		garage_id: 146,
		part_id: 83,
		quantity: 356,
	},
	{
		garage_id: 146,
		part_id: 84,
		quantity: 404,
	},
	{
		garage_id: 146,
		part_id: 85,
		quantity: 111,
	},
	{
		garage_id: 146,
		part_id: 86,
		quantity: 27,
	},
	{
		garage_id: 146,
		part_id: 87,
		quantity: 203,
	},
	{
		garage_id: 146,
		part_id: 88,
		quantity: 59,
	},
	{
		garage_id: 146,
		part_id: 89,
		quantity: 436,
	},
	{
		garage_id: 146,
		part_id: 90,
		quantity: 193,
	},
	{
		garage_id: 146,
		part_id: 91,
		quantity: 346,
	},
	{
		garage_id: 146,
		part_id: 92,
		quantity: 55,
	},
	{
		garage_id: 146,
		part_id: 93,
		quantity: 160,
	},
	{
		garage_id: 146,
		part_id: 94,
		quantity: 69,
	},
	{
		garage_id: 146,
		part_id: 95,
		quantity: 126,
	},
	{
		garage_id: 146,
		part_id: 96,
		quantity: 347,
	},
	{
		garage_id: 146,
		part_id: 97,
		quantity: 219,
	},
	{
		garage_id: 146,
		part_id: 98,
		quantity: 208,
	},
	{
		garage_id: 146,
		part_id: 99,
		quantity: 428,
	},
	{
		garage_id: 146,
		part_id: 100,
		quantity: 130,
	},
	{
		garage_id: 146,
		part_id: 101,
		quantity: 113,
	},
	{
		garage_id: 146,
		part_id: 102,
		quantity: 221,
	},
	{
		garage_id: 146,
		part_id: 103,
		quantity: 70,
	},
	{
		garage_id: 146,
		part_id: 104,
		quantity: 265,
	},
	{
		garage_id: 146,
		part_id: 105,
		quantity: 465,
	},
	{
		garage_id: 146,
		part_id: 106,
		quantity: 290,
	},
	{
		garage_id: 146,
		part_id: 107,
		quantity: 144,
	},
	{
		garage_id: 146,
		part_id: 108,
		quantity: 286,
	},
	{
		garage_id: 146,
		part_id: 109,
		quantity: 425,
	},
	{
		garage_id: 146,
		part_id: 110,
		quantity: 155,
	},
	{
		garage_id: 146,
		part_id: 111,
		quantity: 27,
	},
	{
		garage_id: 146,
		part_id: 112,
		quantity: 334,
	},
	{
		garage_id: 146,
		part_id: 113,
		quantity: 321,
	},
	{
		garage_id: 146,
		part_id: 114,
		quantity: 73,
	},
	{
		garage_id: 146,
		part_id: 115,
		quantity: 387,
	},
	{
		garage_id: 146,
		part_id: 116,
		quantity: 395,
	},
	{
		garage_id: 146,
		part_id: 117,
		quantity: 457,
	},
	{
		garage_id: 146,
		part_id: 118,
		quantity: 97,
	},
	{
		garage_id: 146,
		part_id: 119,
		quantity: 391,
	},
	{
		garage_id: 146,
		part_id: 120,
		quantity: 131,
	},
	{
		garage_id: 146,
		part_id: 121,
		quantity: 4,
	},
	{
		garage_id: 146,
		part_id: 122,
		quantity: 125,
	},
	{
		garage_id: 146,
		part_id: 123,
		quantity: 124,
	},
	{
		garage_id: 146,
		part_id: 124,
		quantity: 344,
	},
	{
		garage_id: 146,
		part_id: 125,
		quantity: 119,
	},
	{
		garage_id: 146,
		part_id: 126,
		quantity: 381,
	},
	{
		garage_id: 146,
		part_id: 127,
		quantity: 78,
	},
	{
		garage_id: 146,
		part_id: 128,
		quantity: 129,
	},
	{
		garage_id: 146,
		part_id: 129,
		quantity: 90,
	},
	{
		garage_id: 146,
		part_id: 130,
		quantity: 254,
	},
	{
		garage_id: 146,
		part_id: 131,
		quantity: 169,
	},
	{
		garage_id: 146,
		part_id: 132,
		quantity: 143,
	},
	{
		garage_id: 146,
		part_id: 133,
		quantity: 369,
	},
	{
		garage_id: 146,
		part_id: 134,
		quantity: 452,
	},
	{
		garage_id: 146,
		part_id: 135,
		quantity: 198,
	},
	{
		garage_id: 146,
		part_id: 136,
		quantity: 46,
	},
	{
		garage_id: 146,
		part_id: 137,
		quantity: 443,
	},
	{
		garage_id: 146,
		part_id: 138,
		quantity: 323,
	},
	{
		garage_id: 146,
		part_id: 139,
		quantity: 72,
	},
	{
		garage_id: 146,
		part_id: 140,
		quantity: 393,
	},
	{
		garage_id: 146,
		part_id: 141,
		quantity: 447,
	},
	{
		garage_id: 146,
		part_id: 142,
		quantity: 185,
	},
	{
		garage_id: 146,
		part_id: 143,
		quantity: 360,
	},
	{
		garage_id: 146,
		part_id: 144,
		quantity: 406,
	},
	{
		garage_id: 146,
		part_id: 145,
		quantity: 242,
	},
	{
		garage_id: 146,
		part_id: 146,
		quantity: 316,
	},
	{
		garage_id: 146,
		part_id: 147,
		quantity: 340,
	},
	{
		garage_id: 146,
		part_id: 148,
		quantity: 387,
	},
	{
		garage_id: 146,
		part_id: 149,
		quantity: 180,
	},
	{
		garage_id: 146,
		part_id: 150,
		quantity: 383,
	},
	{
		garage_id: 146,
		part_id: 151,
		quantity: 109,
	},
	{
		garage_id: 146,
		part_id: 152,
		quantity: 372,
	},
	{
		garage_id: 146,
		part_id: 153,
		quantity: 320,
	},
	{
		garage_id: 146,
		part_id: 154,
		quantity: 122,
	},
	{
		garage_id: 146,
		part_id: 155,
		quantity: 173,
	},
	{
		garage_id: 146,
		part_id: 156,
		quantity: 53,
	},
	{
		garage_id: 146,
		part_id: 157,
		quantity: 255,
	},
	{
		garage_id: 146,
		part_id: 158,
		quantity: 450,
	},
	{
		garage_id: 146,
		part_id: 159,
		quantity: 4,
	},
	{
		garage_id: 146,
		part_id: 160,
		quantity: 284,
	},
	{
		garage_id: 146,
		part_id: 161,
		quantity: 204,
	},
	{
		garage_id: 146,
		part_id: 162,
		quantity: 403,
	},
	{
		garage_id: 146,
		part_id: 163,
		quantity: 472,
	},
	{
		garage_id: 146,
		part_id: 164,
		quantity: 189,
	},
	{
		garage_id: 146,
		part_id: 165,
		quantity: 135,
	},
	{
		garage_id: 146,
		part_id: 166,
		quantity: 150,
	},
	{
		garage_id: 146,
		part_id: 167,
		quantity: 489,
	},
	{
		garage_id: 146,
		part_id: 168,
		quantity: 172,
	},
	{
		garage_id: 146,
		part_id: 169,
		quantity: 101,
	},
	{
		garage_id: 146,
		part_id: 170,
		quantity: 16,
	},
	{
		garage_id: 146,
		part_id: 171,
		quantity: 131,
	},
	{
		garage_id: 146,
		part_id: 172,
		quantity: 87,
	},
	{
		garage_id: 146,
		part_id: 173,
		quantity: 368,
	},
	{
		garage_id: 146,
		part_id: 174,
		quantity: 72,
	},
	{
		garage_id: 146,
		part_id: 175,
		quantity: 36,
	},
	{
		garage_id: 146,
		part_id: 176,
		quantity: 434,
	},
	{
		garage_id: 146,
		part_id: 177,
		quantity: 163,
	},
	{
		garage_id: 146,
		part_id: 178,
		quantity: 201,
	},
	{
		garage_id: 146,
		part_id: 179,
		quantity: 206,
	},
	{
		garage_id: 146,
		part_id: 180,
		quantity: 316,
	},
	{
		garage_id: 146,
		part_id: 181,
		quantity: 226,
	},
	{
		garage_id: 146,
		part_id: 182,
		quantity: 316,
	},
	{
		garage_id: 146,
		part_id: 183,
		quantity: 255,
	},
	{
		garage_id: 146,
		part_id: 184,
		quantity: 183,
	},
	{
		garage_id: 146,
		part_id: 185,
		quantity: 18,
	},
	{
		garage_id: 146,
		part_id: 186,
		quantity: 52,
	},
	{
		garage_id: 146,
		part_id: 187,
		quantity: 77,
	},
	{
		garage_id: 146,
		part_id: 188,
		quantity: 52,
	},
	{
		garage_id: 146,
		part_id: 189,
		quantity: 51,
	},
	{
		garage_id: 146,
		part_id: 190,
		quantity: 103,
	},
	{
		garage_id: 146,
		part_id: 191,
		quantity: 316,
	},
	{
		garage_id: 146,
		part_id: 192,
		quantity: 457,
	},
	{
		garage_id: 146,
		part_id: 193,
		quantity: 41,
	},
	{
		garage_id: 146,
		part_id: 194,
		quantity: 191,
	},
	{
		garage_id: 146,
		part_id: 195,
		quantity: 455,
	},
	{
		garage_id: 146,
		part_id: 196,
		quantity: 302,
	},
	{
		garage_id: 146,
		part_id: 197,
		quantity: 276,
	},
	{
		garage_id: 146,
		part_id: 198,
		quantity: 488,
	},
	{
		garage_id: 146,
		part_id: 199,
		quantity: 411,
	},
	{
		garage_id: 146,
		part_id: 200,
		quantity: 224,
	},
	{
		garage_id: 146,
		part_id: 201,
		quantity: 105,
	},
	{
		garage_id: 146,
		part_id: 202,
		quantity: 496,
	},
	{
		garage_id: 146,
		part_id: 203,
		quantity: 48,
	},
	{
		garage_id: 146,
		part_id: 204,
		quantity: 499,
	},
	{
		garage_id: 146,
		part_id: 205,
		quantity: 497,
	},
	{
		garage_id: 146,
		part_id: 206,
		quantity: 181,
	},
	{
		garage_id: 146,
		part_id: 207,
		quantity: 328,
	},
	{
		garage_id: 146,
		part_id: 208,
		quantity: 21,
	},
	{
		garage_id: 146,
		part_id: 209,
		quantity: 31,
	},
	{
		garage_id: 146,
		part_id: 210,
		quantity: 449,
	},
	{
		garage_id: 146,
		part_id: 211,
		quantity: 402,
	},
	{
		garage_id: 146,
		part_id: 212,
		quantity: 361,
	},
	{
		garage_id: 146,
		part_id: 213,
		quantity: 316,
	},
	{
		garage_id: 146,
		part_id: 214,
		quantity: 16,
	},
	{
		garage_id: 146,
		part_id: 215,
		quantity: 157,
	},
	{
		garage_id: 146,
		part_id: 216,
		quantity: 135,
	},
	{
		garage_id: 146,
		part_id: 217,
		quantity: 67,
	},
	{
		garage_id: 146,
		part_id: 218,
		quantity: 187,
	},
	{
		garage_id: 146,
		part_id: 219,
		quantity: 191,
	},
	{
		garage_id: 146,
		part_id: 220,
		quantity: 230,
	},
	{
		garage_id: 146,
		part_id: 221,
		quantity: 437,
	},
	{
		garage_id: 146,
		part_id: 222,
		quantity: 77,
	},
	{
		garage_id: 146,
		part_id: 223,
		quantity: 387,
	},
	{
		garage_id: 146,
		part_id: 224,
		quantity: 163,
	},
	{
		garage_id: 146,
		part_id: 225,
		quantity: 495,
	},
	{
		garage_id: 146,
		part_id: 226,
		quantity: 328,
	},
	{
		garage_id: 146,
		part_id: 227,
		quantity: 57,
	},
	{
		garage_id: 146,
		part_id: 228,
		quantity: 186,
	},
	{
		garage_id: 146,
		part_id: 229,
		quantity: 280,
	},
	{
		garage_id: 146,
		part_id: 230,
		quantity: 472,
	},
	{
		garage_id: 146,
		part_id: 231,
		quantity: 293,
	},
	{
		garage_id: 146,
		part_id: 232,
		quantity: 424,
	},
	{
		garage_id: 146,
		part_id: 233,
		quantity: 80,
	},
	{
		garage_id: 146,
		part_id: 234,
		quantity: 428,
	},
	{
		garage_id: 146,
		part_id: 235,
		quantity: 243,
	},
	{
		garage_id: 146,
		part_id: 236,
		quantity: 468,
	},
	{
		garage_id: 146,
		part_id: 237,
		quantity: 44,
	},
	{
		garage_id: 146,
		part_id: 238,
		quantity: 95,
	},
	{
		garage_id: 146,
		part_id: 239,
		quantity: 181,
	},
	{
		garage_id: 146,
		part_id: 240,
		quantity: 400,
	},
	{
		garage_id: 146,
		part_id: 241,
		quantity: 314,
	},
	{
		garage_id: 146,
		part_id: 242,
		quantity: 463,
	},
	{
		garage_id: 146,
		part_id: 243,
		quantity: 252,
	},
	{
		garage_id: 146,
		part_id: 244,
		quantity: 71,
	},
	{
		garage_id: 146,
		part_id: 245,
		quantity: 321,
	},
	{
		garage_id: 146,
		part_id: 246,
		quantity: 335,
	},
	{
		garage_id: 146,
		part_id: 247,
		quantity: 392,
	},
	{
		garage_id: 146,
		part_id: 248,
		quantity: 221,
	},
	{
		garage_id: 146,
		part_id: 249,
		quantity: 267,
	},
	{
		garage_id: 146,
		part_id: 250,
		quantity: 10,
	},
	{
		garage_id: 146,
		part_id: 251,
		quantity: 159,
	},
	{
		garage_id: 146,
		part_id: 252,
		quantity: 355,
	},
	{
		garage_id: 146,
		part_id: 253,
		quantity: 44,
	},
	{
		garage_id: 146,
		part_id: 254,
		quantity: 10,
	},
	{
		garage_id: 146,
		part_id: 255,
		quantity: 449,
	},
	{
		garage_id: 146,
		part_id: 256,
		quantity: 439,
	},
	{
		garage_id: 146,
		part_id: 257,
		quantity: 26,
	},
	{
		garage_id: 146,
		part_id: 258,
		quantity: 44,
	},
	{
		garage_id: 146,
		part_id: 259,
		quantity: 232,
	},
	{
		garage_id: 146,
		part_id: 260,
		quantity: 184,
	},
	{
		garage_id: 146,
		part_id: 261,
		quantity: 360,
	},
	{
		garage_id: 146,
		part_id: 262,
		quantity: 407,
	},
	{
		garage_id: 146,
		part_id: 263,
		quantity: 80,
	},
	{
		garage_id: 146,
		part_id: 264,
		quantity: 396,
	},
	{
		garage_id: 146,
		part_id: 265,
		quantity: 57,
	},
	{
		garage_id: 146,
		part_id: 266,
		quantity: 202,
	},
	{
		garage_id: 146,
		part_id: 267,
		quantity: 405,
	},
	{
		garage_id: 146,
		part_id: 268,
		quantity: 421,
	},
	{
		garage_id: 146,
		part_id: 269,
		quantity: 21,
	},
	{
		garage_id: 146,
		part_id: 270,
		quantity: 190,
	},
	{
		garage_id: 146,
		part_id: 271,
		quantity: 483,
	},
	{
		garage_id: 146,
		part_id: 272,
		quantity: 4,
	},
	{
		garage_id: 146,
		part_id: 273,
		quantity: 395,
	},
	{
		garage_id: 146,
		part_id: 274,
		quantity: 360,
	},
	{
		garage_id: 146,
		part_id: 275,
		quantity: 189,
	},
	{
		garage_id: 146,
		part_id: 276,
		quantity: 452,
	},
	{
		garage_id: 146,
		part_id: 277,
		quantity: 177,
	},
	{
		garage_id: 146,
		part_id: 278,
		quantity: 59,
	},
	{
		garage_id: 146,
		part_id: 279,
		quantity: 218,
	},
	{
		garage_id: 146,
		part_id: 280,
		quantity: 384,
	},
	{
		garage_id: 146,
		part_id: 281,
		quantity: 14,
	},
	{
		garage_id: 146,
		part_id: 282,
		quantity: 3,
	},
	{
		garage_id: 146,
		part_id: 283,
		quantity: 413,
	},
	{
		garage_id: 146,
		part_id: 284,
		quantity: 120,
	},
	{
		garage_id: 146,
		part_id: 285,
		quantity: 453,
	},
	{
		garage_id: 146,
		part_id: 286,
		quantity: 181,
	},
	{
		garage_id: 146,
		part_id: 287,
		quantity: 90,
	},
	{
		garage_id: 146,
		part_id: 288,
		quantity: 322,
	},
	{
		garage_id: 146,
		part_id: 289,
		quantity: 67,
	},
	{
		garage_id: 146,
		part_id: 290,
		quantity: 392,
	},
	{
		garage_id: 146,
		part_id: 291,
		quantity: 269,
	},
	{
		garage_id: 146,
		part_id: 292,
		quantity: 64,
	},
	{
		garage_id: 146,
		part_id: 293,
		quantity: 185,
	},
	{
		garage_id: 146,
		part_id: 294,
		quantity: 281,
	},
	{
		garage_id: 146,
		part_id: 295,
		quantity: 500,
	},
	{
		garage_id: 146,
		part_id: 296,
		quantity: 427,
	},
	{
		garage_id: 146,
		part_id: 297,
		quantity: 220,
	},
	{
		garage_id: 146,
		part_id: 298,
		quantity: 91,
	},
	{
		garage_id: 146,
		part_id: 299,
		quantity: 256,
	},
	{
		garage_id: 146,
		part_id: 300,
		quantity: 305,
	},
	{
		garage_id: 146,
		part_id: 301,
		quantity: 0,
	},
	{
		garage_id: 146,
		part_id: 302,
		quantity: 367,
	},
	{
		garage_id: 146,
		part_id: 303,
		quantity: 458,
	},
	{
		garage_id: 146,
		part_id: 304,
		quantity: 378,
	},
	{
		garage_id: 146,
		part_id: 305,
		quantity: 359,
	},
	{
		garage_id: 146,
		part_id: 306,
		quantity: 24,
	},
	{
		garage_id: 146,
		part_id: 307,
		quantity: 211,
	},
	{
		garage_id: 146,
		part_id: 308,
		quantity: 422,
	},
	{
		garage_id: 146,
		part_id: 309,
		quantity: 324,
	},
	{
		garage_id: 146,
		part_id: 310,
		quantity: 240,
	},
	{
		garage_id: 146,
		part_id: 311,
		quantity: 65,
	},
	{
		garage_id: 146,
		part_id: 312,
		quantity: 423,
	},
	{
		garage_id: 146,
		part_id: 313,
		quantity: 33,
	},
	{
		garage_id: 146,
		part_id: 314,
		quantity: 188,
	},
	{
		garage_id: 146,
		part_id: 315,
		quantity: 419,
	},
	{
		garage_id: 146,
		part_id: 316,
		quantity: 217,
	},
	{
		garage_id: 146,
		part_id: 317,
		quantity: 490,
	},
	{
		garage_id: 146,
		part_id: 318,
		quantity: 192,
	},
	{
		garage_id: 146,
		part_id: 319,
		quantity: 92,
	},
	{
		garage_id: 146,
		part_id: 320,
		quantity: 396,
	},
	{
		garage_id: 146,
		part_id: 321,
		quantity: 263,
	},
	{
		garage_id: 146,
		part_id: 322,
		quantity: 231,
	},
	{
		garage_id: 146,
		part_id: 323,
		quantity: 40,
	},
	{
		garage_id: 146,
		part_id: 324,
		quantity: 253,
	},
	{
		garage_id: 146,
		part_id: 325,
		quantity: 24,
	},
	{
		garage_id: 146,
		part_id: 326,
		quantity: 334,
	},
	{
		garage_id: 146,
		part_id: 327,
		quantity: 342,
	},
	{
		garage_id: 146,
		part_id: 328,
		quantity: 95,
	},
	{
		garage_id: 146,
		part_id: 329,
		quantity: 499,
	},
	{
		garage_id: 146,
		part_id: 330,
		quantity: 437,
	},
	{
		garage_id: 146,
		part_id: 331,
		quantity: 334,
	},
	{
		garage_id: 146,
		part_id: 332,
		quantity: 291,
	},
	{
		garage_id: 146,
		part_id: 333,
		quantity: 316,
	},
	{
		garage_id: 146,
		part_id: 334,
		quantity: 373,
	},
	{
		garage_id: 146,
		part_id: 335,
		quantity: 122,
	},
	{
		garage_id: 146,
		part_id: 336,
		quantity: 325,
	},
	{
		garage_id: 146,
		part_id: 337,
		quantity: 340,
	},
	{
		garage_id: 146,
		part_id: 338,
		quantity: 294,
	},
	{
		garage_id: 146,
		part_id: 339,
		quantity: 236,
	},
	{
		garage_id: 146,
		part_id: 340,
		quantity: 297,
	},
	{
		garage_id: 146,
		part_id: 341,
		quantity: 475,
	},
	{
		garage_id: 146,
		part_id: 342,
		quantity: 32,
	},
	{
		garage_id: 146,
		part_id: 343,
		quantity: 103,
	},
	{
		garage_id: 146,
		part_id: 344,
		quantity: 317,
	},
	{
		garage_id: 146,
		part_id: 345,
		quantity: 154,
	},
	{
		garage_id: 146,
		part_id: 346,
		quantity: 129,
	},
	{
		garage_id: 146,
		part_id: 347,
		quantity: 265,
	},
	{
		garage_id: 146,
		part_id: 348,
		quantity: 452,
	},
	{
		garage_id: 146,
		part_id: 349,
		quantity: 170,
	},
	{
		garage_id: 146,
		part_id: 350,
		quantity: 243,
	},
	{
		garage_id: 146,
		part_id: 351,
		quantity: 258,
	},
	{
		garage_id: 146,
		part_id: 352,
		quantity: 59,
	},
	{
		garage_id: 146,
		part_id: 353,
		quantity: 189,
	},
	{
		garage_id: 146,
		part_id: 354,
		quantity: 478,
	},
	{
		garage_id: 146,
		part_id: 355,
		quantity: 225,
	},
	{
		garage_id: 146,
		part_id: 356,
		quantity: 228,
	},
	{
		garage_id: 146,
		part_id: 357,
		quantity: 139,
	},
	{
		garage_id: 146,
		part_id: 358,
		quantity: 250,
	},
	{
		garage_id: 146,
		part_id: 359,
		quantity: 486,
	},
	{
		garage_id: 146,
		part_id: 360,
		quantity: 86,
	},
	{
		garage_id: 146,
		part_id: 361,
		quantity: 445,
	},
	{
		garage_id: 146,
		part_id: 362,
		quantity: 335,
	},
	{
		garage_id: 146,
		part_id: 363,
		quantity: 102,
	},
	{
		garage_id: 146,
		part_id: 364,
		quantity: 414,
	},
	{
		garage_id: 146,
		part_id: 365,
		quantity: 302,
	},
	{
		garage_id: 146,
		part_id: 366,
		quantity: 415,
	},
	{
		garage_id: 146,
		part_id: 367,
		quantity: 450,
	},
	{
		garage_id: 146,
		part_id: 368,
		quantity: 303,
	},
	{
		garage_id: 146,
		part_id: 369,
		quantity: 64,
	},
	{
		garage_id: 146,
		part_id: 370,
		quantity: 343,
	},
	{
		garage_id: 146,
		part_id: 371,
		quantity: 452,
	},
	{
		garage_id: 146,
		part_id: 372,
		quantity: 198,
	},
	{
		garage_id: 146,
		part_id: 373,
		quantity: 314,
	},
	{
		garage_id: 146,
		part_id: 374,
		quantity: 221,
	},
	{
		garage_id: 146,
		part_id: 375,
		quantity: 34,
	},
	{
		garage_id: 146,
		part_id: 376,
		quantity: 50,
	},
	{
		garage_id: 146,
		part_id: 377,
		quantity: 220,
	},
	{
		garage_id: 146,
		part_id: 378,
		quantity: 398,
	},
	{
		garage_id: 146,
		part_id: 379,
		quantity: 239,
	},
	{
		garage_id: 146,
		part_id: 380,
		quantity: 300,
	},
	{
		garage_id: 146,
		part_id: 381,
		quantity: 426,
	},
	{
		garage_id: 146,
		part_id: 382,
		quantity: 17,
	},
	{
		garage_id: 146,
		part_id: 383,
		quantity: 444,
	},
	{
		garage_id: 146,
		part_id: 384,
		quantity: 49,
	},
	{
		garage_id: 146,
		part_id: 385,
		quantity: 283,
	},
	{
		garage_id: 146,
		part_id: 386,
		quantity: 290,
	},
	{
		garage_id: 146,
		part_id: 387,
		quantity: 354,
	},
	{
		garage_id: 146,
		part_id: 388,
		quantity: 82,
	},
	{
		garage_id: 146,
		part_id: 389,
		quantity: 344,
	},
	{
		garage_id: 146,
		part_id: 390,
		quantity: 476,
	},
	{
		garage_id: 146,
		part_id: 391,
		quantity: 305,
	},
	{
		garage_id: 146,
		part_id: 392,
		quantity: 402,
	},
	{
		garage_id: 146,
		part_id: 393,
		quantity: 85,
	},
	{
		garage_id: 146,
		part_id: 394,
		quantity: 208,
	},
	{
		garage_id: 146,
		part_id: 395,
		quantity: 488,
	},
	{
		garage_id: 146,
		part_id: 396,
		quantity: 208,
	},
	{
		garage_id: 146,
		part_id: 397,
		quantity: 236,
	},
	{
		garage_id: 146,
		part_id: 398,
		quantity: 106,
	},
	{
		garage_id: 146,
		part_id: 399,
		quantity: 300,
	},
	{
		garage_id: 147,
		part_id: 0,
		quantity: 117,
	},
	{
		garage_id: 147,
		part_id: 1,
		quantity: 302,
	},
	{
		garage_id: 147,
		part_id: 2,
		quantity: 82,
	},
	{
		garage_id: 147,
		part_id: 3,
		quantity: 207,
	},
	{
		garage_id: 147,
		part_id: 4,
		quantity: 449,
	},
	{
		garage_id: 147,
		part_id: 5,
		quantity: 132,
	},
	{
		garage_id: 147,
		part_id: 6,
		quantity: 309,
	},
	{
		garage_id: 147,
		part_id: 7,
		quantity: 285,
	},
	{
		garage_id: 147,
		part_id: 8,
		quantity: 191,
	},
	{
		garage_id: 147,
		part_id: 9,
		quantity: 479,
	},
	{
		garage_id: 147,
		part_id: 10,
		quantity: 301,
	},
	{
		garage_id: 147,
		part_id: 11,
		quantity: 2,
	},
	{
		garage_id: 147,
		part_id: 12,
		quantity: 92,
	},
	{
		garage_id: 147,
		part_id: 13,
		quantity: 124,
	},
	{
		garage_id: 147,
		part_id: 14,
		quantity: 201,
	},
	{
		garage_id: 147,
		part_id: 15,
		quantity: 236,
	},
	{
		garage_id: 147,
		part_id: 16,
		quantity: 400,
	},
	{
		garage_id: 147,
		part_id: 17,
		quantity: 469,
	},
	{
		garage_id: 147,
		part_id: 18,
		quantity: 336,
	},
	{
		garage_id: 147,
		part_id: 19,
		quantity: 310,
	},
	{
		garage_id: 147,
		part_id: 20,
		quantity: 336,
	},
	{
		garage_id: 147,
		part_id: 21,
		quantity: 130,
	},
	{
		garage_id: 147,
		part_id: 22,
		quantity: 477,
	},
	{
		garage_id: 147,
		part_id: 23,
		quantity: 410,
	},
	{
		garage_id: 147,
		part_id: 24,
		quantity: 368,
	},
	{
		garage_id: 147,
		part_id: 25,
		quantity: 268,
	},
	{
		garage_id: 147,
		part_id: 26,
		quantity: 164,
	},
	{
		garage_id: 147,
		part_id: 27,
		quantity: 53,
	},
	{
		garage_id: 147,
		part_id: 28,
		quantity: 430,
	},
	{
		garage_id: 147,
		part_id: 29,
		quantity: 128,
	},
	{
		garage_id: 147,
		part_id: 30,
		quantity: 468,
	},
	{
		garage_id: 147,
		part_id: 31,
		quantity: 105,
	},
	{
		garage_id: 147,
		part_id: 32,
		quantity: 112,
	},
	{
		garage_id: 147,
		part_id: 33,
		quantity: 189,
	},
	{
		garage_id: 147,
		part_id: 34,
		quantity: 321,
	},
	{
		garage_id: 147,
		part_id: 35,
		quantity: 494,
	},
	{
		garage_id: 147,
		part_id: 36,
		quantity: 185,
	},
	{
		garage_id: 147,
		part_id: 37,
		quantity: 64,
	},
	{
		garage_id: 147,
		part_id: 38,
		quantity: 461,
	},
	{
		garage_id: 147,
		part_id: 39,
		quantity: 467,
	},
	{
		garage_id: 147,
		part_id: 40,
		quantity: 475,
	},
	{
		garage_id: 147,
		part_id: 41,
		quantity: 97,
	},
	{
		garage_id: 147,
		part_id: 42,
		quantity: 97,
	},
	{
		garage_id: 147,
		part_id: 43,
		quantity: 11,
	},
	{
		garage_id: 147,
		part_id: 44,
		quantity: 18,
	},
	{
		garage_id: 147,
		part_id: 45,
		quantity: 47,
	},
	{
		garage_id: 147,
		part_id: 46,
		quantity: 121,
	},
	{
		garage_id: 147,
		part_id: 47,
		quantity: 354,
	},
	{
		garage_id: 147,
		part_id: 48,
		quantity: 192,
	},
	{
		garage_id: 147,
		part_id: 49,
		quantity: 62,
	},
	{
		garage_id: 147,
		part_id: 50,
		quantity: 311,
	},
	{
		garage_id: 147,
		part_id: 51,
		quantity: 20,
	},
	{
		garage_id: 147,
		part_id: 52,
		quantity: 211,
	},
	{
		garage_id: 147,
		part_id: 53,
		quantity: 413,
	},
	{
		garage_id: 147,
		part_id: 54,
		quantity: 168,
	},
	{
		garage_id: 147,
		part_id: 55,
		quantity: 248,
	},
	{
		garage_id: 147,
		part_id: 56,
		quantity: 121,
	},
	{
		garage_id: 147,
		part_id: 57,
		quantity: 414,
	},
	{
		garage_id: 147,
		part_id: 58,
		quantity: 118,
	},
	{
		garage_id: 147,
		part_id: 59,
		quantity: 235,
	},
	{
		garage_id: 147,
		part_id: 60,
		quantity: 418,
	},
	{
		garage_id: 147,
		part_id: 61,
		quantity: 14,
	},
	{
		garage_id: 147,
		part_id: 62,
		quantity: 384,
	},
	{
		garage_id: 147,
		part_id: 63,
		quantity: 122,
	},
	{
		garage_id: 147,
		part_id: 64,
		quantity: 252,
	},
	{
		garage_id: 147,
		part_id: 65,
		quantity: 174,
	},
	{
		garage_id: 147,
		part_id: 66,
		quantity: 169,
	},
	{
		garage_id: 147,
		part_id: 67,
		quantity: 19,
	},
	{
		garage_id: 147,
		part_id: 68,
		quantity: 87,
	},
	{
		garage_id: 147,
		part_id: 69,
		quantity: 399,
	},
	{
		garage_id: 147,
		part_id: 70,
		quantity: 275,
	},
	{
		garage_id: 147,
		part_id: 71,
		quantity: 393,
	},
	{
		garage_id: 147,
		part_id: 72,
		quantity: 412,
	},
	{
		garage_id: 147,
		part_id: 73,
		quantity: 120,
	},
	{
		garage_id: 147,
		part_id: 74,
		quantity: 281,
	},
	{
		garage_id: 147,
		part_id: 75,
		quantity: 299,
	},
	{
		garage_id: 147,
		part_id: 76,
		quantity: 112,
	},
	{
		garage_id: 147,
		part_id: 77,
		quantity: 236,
	},
	{
		garage_id: 147,
		part_id: 78,
		quantity: 282,
	},
	{
		garage_id: 147,
		part_id: 79,
		quantity: 82,
	},
	{
		garage_id: 147,
		part_id: 80,
		quantity: 393,
	},
	{
		garage_id: 147,
		part_id: 81,
		quantity: 163,
	},
	{
		garage_id: 147,
		part_id: 82,
		quantity: 463,
	},
	{
		garage_id: 147,
		part_id: 83,
		quantity: 435,
	},
	{
		garage_id: 147,
		part_id: 84,
		quantity: 44,
	},
	{
		garage_id: 147,
		part_id: 85,
		quantity: 270,
	},
	{
		garage_id: 147,
		part_id: 86,
		quantity: 135,
	},
	{
		garage_id: 147,
		part_id: 87,
		quantity: 329,
	},
	{
		garage_id: 147,
		part_id: 88,
		quantity: 57,
	},
	{
		garage_id: 147,
		part_id: 89,
		quantity: 439,
	},
	{
		garage_id: 147,
		part_id: 90,
		quantity: 155,
	},
	{
		garage_id: 147,
		part_id: 91,
		quantity: 7,
	},
	{
		garage_id: 147,
		part_id: 92,
		quantity: 396,
	},
	{
		garage_id: 147,
		part_id: 93,
		quantity: 200,
	},
	{
		garage_id: 147,
		part_id: 94,
		quantity: 135,
	},
	{
		garage_id: 147,
		part_id: 95,
		quantity: 410,
	},
	{
		garage_id: 147,
		part_id: 96,
		quantity: 390,
	},
	{
		garage_id: 147,
		part_id: 97,
		quantity: 258,
	},
	{
		garage_id: 147,
		part_id: 98,
		quantity: 448,
	},
	{
		garage_id: 147,
		part_id: 99,
		quantity: 322,
	},
	{
		garage_id: 147,
		part_id: 100,
		quantity: 421,
	},
	{
		garage_id: 147,
		part_id: 101,
		quantity: 126,
	},
	{
		garage_id: 147,
		part_id: 102,
		quantity: 399,
	},
	{
		garage_id: 147,
		part_id: 103,
		quantity: 273,
	},
	{
		garage_id: 147,
		part_id: 104,
		quantity: 92,
	},
	{
		garage_id: 147,
		part_id: 105,
		quantity: 321,
	},
	{
		garage_id: 147,
		part_id: 106,
		quantity: 221,
	},
	{
		garage_id: 147,
		part_id: 107,
		quantity: 287,
	},
	{
		garage_id: 147,
		part_id: 108,
		quantity: 51,
	},
	{
		garage_id: 147,
		part_id: 109,
		quantity: 223,
	},
	{
		garage_id: 147,
		part_id: 110,
		quantity: 15,
	},
	{
		garage_id: 147,
		part_id: 111,
		quantity: 473,
	},
	{
		garage_id: 147,
		part_id: 112,
		quantity: 326,
	},
	{
		garage_id: 147,
		part_id: 113,
		quantity: 359,
	},
	{
		garage_id: 147,
		part_id: 114,
		quantity: 214,
	},
	{
		garage_id: 147,
		part_id: 115,
		quantity: 197,
	},
	{
		garage_id: 147,
		part_id: 116,
		quantity: 210,
	},
	{
		garage_id: 147,
		part_id: 117,
		quantity: 86,
	},
	{
		garage_id: 147,
		part_id: 118,
		quantity: 174,
	},
	{
		garage_id: 147,
		part_id: 119,
		quantity: 314,
	},
	{
		garage_id: 147,
		part_id: 120,
		quantity: 263,
	},
	{
		garage_id: 147,
		part_id: 121,
		quantity: 115,
	},
	{
		garage_id: 147,
		part_id: 122,
		quantity: 291,
	},
	{
		garage_id: 147,
		part_id: 123,
		quantity: 83,
	},
	{
		garage_id: 147,
		part_id: 124,
		quantity: 165,
	},
	{
		garage_id: 147,
		part_id: 125,
		quantity: 260,
	},
	{
		garage_id: 147,
		part_id: 126,
		quantity: 391,
	},
	{
		garage_id: 147,
		part_id: 127,
		quantity: 64,
	},
	{
		garage_id: 147,
		part_id: 128,
		quantity: 332,
	},
	{
		garage_id: 147,
		part_id: 129,
		quantity: 436,
	},
	{
		garage_id: 147,
		part_id: 130,
		quantity: 134,
	},
	{
		garage_id: 147,
		part_id: 131,
		quantity: 449,
	},
	{
		garage_id: 147,
		part_id: 132,
		quantity: 358,
	},
	{
		garage_id: 147,
		part_id: 133,
		quantity: 57,
	},
	{
		garage_id: 147,
		part_id: 134,
		quantity: 130,
	},
	{
		garage_id: 147,
		part_id: 135,
		quantity: 273,
	},
	{
		garage_id: 147,
		part_id: 136,
		quantity: 122,
	},
	{
		garage_id: 147,
		part_id: 137,
		quantity: 34,
	},
	{
		garage_id: 147,
		part_id: 138,
		quantity: 28,
	},
	{
		garage_id: 147,
		part_id: 139,
		quantity: 117,
	},
	{
		garage_id: 147,
		part_id: 140,
		quantity: 46,
	},
	{
		garage_id: 147,
		part_id: 141,
		quantity: 86,
	},
	{
		garage_id: 147,
		part_id: 142,
		quantity: 466,
	},
	{
		garage_id: 147,
		part_id: 143,
		quantity: 389,
	},
	{
		garage_id: 147,
		part_id: 144,
		quantity: 359,
	},
	{
		garage_id: 147,
		part_id: 145,
		quantity: 300,
	},
	{
		garage_id: 147,
		part_id: 146,
		quantity: 345,
	},
	{
		garage_id: 147,
		part_id: 147,
		quantity: 181,
	},
	{
		garage_id: 147,
		part_id: 148,
		quantity: 413,
	},
	{
		garage_id: 147,
		part_id: 149,
		quantity: 123,
	},
	{
		garage_id: 147,
		part_id: 150,
		quantity: 46,
	},
	{
		garage_id: 147,
		part_id: 151,
		quantity: 473,
	},
	{
		garage_id: 147,
		part_id: 152,
		quantity: 439,
	},
	{
		garage_id: 147,
		part_id: 153,
		quantity: 129,
	},
	{
		garage_id: 147,
		part_id: 154,
		quantity: 290,
	},
	{
		garage_id: 147,
		part_id: 155,
		quantity: 202,
	},
	{
		garage_id: 147,
		part_id: 156,
		quantity: 142,
	},
	{
		garage_id: 147,
		part_id: 157,
		quantity: 403,
	},
	{
		garage_id: 147,
		part_id: 158,
		quantity: 188,
	},
	{
		garage_id: 147,
		part_id: 159,
		quantity: 160,
	},
	{
		garage_id: 147,
		part_id: 160,
		quantity: 116,
	},
	{
		garage_id: 147,
		part_id: 161,
		quantity: 300,
	},
	{
		garage_id: 147,
		part_id: 162,
		quantity: 252,
	},
	{
		garage_id: 147,
		part_id: 163,
		quantity: 444,
	},
	{
		garage_id: 147,
		part_id: 164,
		quantity: 475,
	},
	{
		garage_id: 147,
		part_id: 165,
		quantity: 111,
	},
	{
		garage_id: 147,
		part_id: 166,
		quantity: 286,
	},
	{
		garage_id: 147,
		part_id: 167,
		quantity: 496,
	},
	{
		garage_id: 147,
		part_id: 168,
		quantity: 159,
	},
	{
		garage_id: 147,
		part_id: 169,
		quantity: 194,
	},
	{
		garage_id: 147,
		part_id: 170,
		quantity: 5,
	},
	{
		garage_id: 147,
		part_id: 171,
		quantity: 186,
	},
	{
		garage_id: 147,
		part_id: 172,
		quantity: 412,
	},
	{
		garage_id: 147,
		part_id: 173,
		quantity: 296,
	},
	{
		garage_id: 147,
		part_id: 174,
		quantity: 242,
	},
	{
		garage_id: 147,
		part_id: 175,
		quantity: 34,
	},
	{
		garage_id: 147,
		part_id: 176,
		quantity: 476,
	},
	{
		garage_id: 147,
		part_id: 177,
		quantity: 199,
	},
	{
		garage_id: 147,
		part_id: 178,
		quantity: 247,
	},
	{
		garage_id: 147,
		part_id: 179,
		quantity: 174,
	},
	{
		garage_id: 147,
		part_id: 180,
		quantity: 114,
	},
	{
		garage_id: 147,
		part_id: 181,
		quantity: 392,
	},
	{
		garage_id: 147,
		part_id: 182,
		quantity: 329,
	},
	{
		garage_id: 147,
		part_id: 183,
		quantity: 319,
	},
	{
		garage_id: 147,
		part_id: 184,
		quantity: 137,
	},
	{
		garage_id: 147,
		part_id: 185,
		quantity: 324,
	},
	{
		garage_id: 147,
		part_id: 186,
		quantity: 247,
	},
	{
		garage_id: 147,
		part_id: 187,
		quantity: 151,
	},
	{
		garage_id: 147,
		part_id: 188,
		quantity: 250,
	},
	{
		garage_id: 147,
		part_id: 189,
		quantity: 467,
	},
	{
		garage_id: 147,
		part_id: 190,
		quantity: 370,
	},
	{
		garage_id: 147,
		part_id: 191,
		quantity: 463,
	},
	{
		garage_id: 147,
		part_id: 192,
		quantity: 307,
	},
	{
		garage_id: 147,
		part_id: 193,
		quantity: 150,
	},
	{
		garage_id: 147,
		part_id: 194,
		quantity: 475,
	},
	{
		garage_id: 147,
		part_id: 195,
		quantity: 165,
	},
	{
		garage_id: 147,
		part_id: 196,
		quantity: 302,
	},
	{
		garage_id: 147,
		part_id: 197,
		quantity: 493,
	},
	{
		garage_id: 147,
		part_id: 198,
		quantity: 382,
	},
	{
		garage_id: 147,
		part_id: 199,
		quantity: 304,
	},
	{
		garage_id: 147,
		part_id: 200,
		quantity: 112,
	},
	{
		garage_id: 147,
		part_id: 201,
		quantity: 157,
	},
	{
		garage_id: 147,
		part_id: 202,
		quantity: 393,
	},
	{
		garage_id: 147,
		part_id: 203,
		quantity: 418,
	},
	{
		garage_id: 147,
		part_id: 204,
		quantity: 440,
	},
	{
		garage_id: 147,
		part_id: 205,
		quantity: 92,
	},
	{
		garage_id: 147,
		part_id: 206,
		quantity: 122,
	},
	{
		garage_id: 147,
		part_id: 207,
		quantity: 144,
	},
	{
		garage_id: 147,
		part_id: 208,
		quantity: 500,
	},
	{
		garage_id: 147,
		part_id: 209,
		quantity: 498,
	},
	{
		garage_id: 147,
		part_id: 210,
		quantity: 361,
	},
	{
		garage_id: 147,
		part_id: 211,
		quantity: 174,
	},
	{
		garage_id: 147,
		part_id: 212,
		quantity: 295,
	},
	{
		garage_id: 147,
		part_id: 213,
		quantity: 394,
	},
	{
		garage_id: 147,
		part_id: 214,
		quantity: 341,
	},
	{
		garage_id: 147,
		part_id: 215,
		quantity: 404,
	},
	{
		garage_id: 147,
		part_id: 216,
		quantity: 126,
	},
	{
		garage_id: 147,
		part_id: 217,
		quantity: 53,
	},
	{
		garage_id: 147,
		part_id: 218,
		quantity: 139,
	},
	{
		garage_id: 147,
		part_id: 219,
		quantity: 433,
	},
	{
		garage_id: 147,
		part_id: 220,
		quantity: 40,
	},
	{
		garage_id: 147,
		part_id: 221,
		quantity: 384,
	},
	{
		garage_id: 147,
		part_id: 222,
		quantity: 112,
	},
	{
		garage_id: 147,
		part_id: 223,
		quantity: 329,
	},
	{
		garage_id: 147,
		part_id: 224,
		quantity: 493,
	},
	{
		garage_id: 147,
		part_id: 225,
		quantity: 303,
	},
	{
		garage_id: 147,
		part_id: 226,
		quantity: 431,
	},
	{
		garage_id: 147,
		part_id: 227,
		quantity: 215,
	},
	{
		garage_id: 147,
		part_id: 228,
		quantity: 71,
	},
	{
		garage_id: 147,
		part_id: 229,
		quantity: 293,
	},
	{
		garage_id: 147,
		part_id: 230,
		quantity: 62,
	},
	{
		garage_id: 147,
		part_id: 231,
		quantity: 353,
	},
	{
		garage_id: 147,
		part_id: 232,
		quantity: 194,
	},
	{
		garage_id: 147,
		part_id: 233,
		quantity: 414,
	},
	{
		garage_id: 147,
		part_id: 234,
		quantity: 214,
	},
	{
		garage_id: 147,
		part_id: 235,
		quantity: 39,
	},
	{
		garage_id: 147,
		part_id: 236,
		quantity: 287,
	},
	{
		garage_id: 147,
		part_id: 237,
		quantity: 37,
	},
	{
		garage_id: 147,
		part_id: 238,
		quantity: 108,
	},
	{
		garage_id: 147,
		part_id: 239,
		quantity: 275,
	},
	{
		garage_id: 147,
		part_id: 240,
		quantity: 307,
	},
	{
		garage_id: 147,
		part_id: 241,
		quantity: 252,
	},
	{
		garage_id: 147,
		part_id: 242,
		quantity: 68,
	},
	{
		garage_id: 147,
		part_id: 243,
		quantity: 104,
	},
	{
		garage_id: 147,
		part_id: 244,
		quantity: 63,
	},
	{
		garage_id: 147,
		part_id: 245,
		quantity: 288,
	},
	{
		garage_id: 147,
		part_id: 246,
		quantity: 201,
	},
	{
		garage_id: 147,
		part_id: 247,
		quantity: 370,
	},
	{
		garage_id: 147,
		part_id: 248,
		quantity: 7,
	},
	{
		garage_id: 147,
		part_id: 249,
		quantity: 35,
	},
	{
		garage_id: 147,
		part_id: 250,
		quantity: 424,
	},
	{
		garage_id: 147,
		part_id: 251,
		quantity: 275,
	},
	{
		garage_id: 147,
		part_id: 252,
		quantity: 261,
	},
	{
		garage_id: 147,
		part_id: 253,
		quantity: 47,
	},
	{
		garage_id: 147,
		part_id: 254,
		quantity: 315,
	},
	{
		garage_id: 147,
		part_id: 255,
		quantity: 446,
	},
	{
		garage_id: 147,
		part_id: 256,
		quantity: 437,
	},
	{
		garage_id: 147,
		part_id: 257,
		quantity: 422,
	},
	{
		garage_id: 147,
		part_id: 258,
		quantity: 323,
	},
	{
		garage_id: 147,
		part_id: 259,
		quantity: 403,
	},
	{
		garage_id: 147,
		part_id: 260,
		quantity: 427,
	},
	{
		garage_id: 147,
		part_id: 261,
		quantity: 107,
	},
	{
		garage_id: 147,
		part_id: 262,
		quantity: 448,
	},
	{
		garage_id: 147,
		part_id: 263,
		quantity: 417,
	},
	{
		garage_id: 147,
		part_id: 264,
		quantity: 316,
	},
	{
		garage_id: 147,
		part_id: 265,
		quantity: 347,
	},
	{
		garage_id: 147,
		part_id: 266,
		quantity: 226,
	},
	{
		garage_id: 147,
		part_id: 267,
		quantity: 158,
	},
	{
		garage_id: 147,
		part_id: 268,
		quantity: 22,
	},
	{
		garage_id: 147,
		part_id: 269,
		quantity: 482,
	},
	{
		garage_id: 147,
		part_id: 270,
		quantity: 61,
	},
	{
		garage_id: 147,
		part_id: 271,
		quantity: 455,
	},
	{
		garage_id: 147,
		part_id: 272,
		quantity: 32,
	},
	{
		garage_id: 147,
		part_id: 273,
		quantity: 133,
	},
	{
		garage_id: 147,
		part_id: 274,
		quantity: 7,
	},
	{
		garage_id: 147,
		part_id: 275,
		quantity: 318,
	},
	{
		garage_id: 147,
		part_id: 276,
		quantity: 495,
	},
	{
		garage_id: 147,
		part_id: 277,
		quantity: 103,
	},
	{
		garage_id: 147,
		part_id: 278,
		quantity: 20,
	},
	{
		garage_id: 147,
		part_id: 279,
		quantity: 111,
	},
	{
		garage_id: 147,
		part_id: 280,
		quantity: 471,
	},
	{
		garage_id: 147,
		part_id: 281,
		quantity: 468,
	},
	{
		garage_id: 147,
		part_id: 282,
		quantity: 30,
	},
	{
		garage_id: 147,
		part_id: 283,
		quantity: 196,
	},
	{
		garage_id: 147,
		part_id: 284,
		quantity: 455,
	},
	{
		garage_id: 147,
		part_id: 285,
		quantity: 1,
	},
	{
		garage_id: 147,
		part_id: 286,
		quantity: 205,
	},
	{
		garage_id: 147,
		part_id: 287,
		quantity: 411,
	},
	{
		garage_id: 147,
		part_id: 288,
		quantity: 202,
	},
	{
		garage_id: 147,
		part_id: 289,
		quantity: 411,
	},
	{
		garage_id: 147,
		part_id: 290,
		quantity: 335,
	},
	{
		garage_id: 147,
		part_id: 291,
		quantity: 381,
	},
	{
		garage_id: 147,
		part_id: 292,
		quantity: 14,
	},
	{
		garage_id: 147,
		part_id: 293,
		quantity: 465,
	},
	{
		garage_id: 147,
		part_id: 294,
		quantity: 461,
	},
	{
		garage_id: 147,
		part_id: 295,
		quantity: 120,
	},
	{
		garage_id: 147,
		part_id: 296,
		quantity: 81,
	},
	{
		garage_id: 147,
		part_id: 297,
		quantity: 323,
	},
	{
		garage_id: 147,
		part_id: 298,
		quantity: 247,
	},
	{
		garage_id: 147,
		part_id: 299,
		quantity: 249,
	},
	{
		garage_id: 147,
		part_id: 300,
		quantity: 449,
	},
	{
		garage_id: 147,
		part_id: 301,
		quantity: 305,
	},
	{
		garage_id: 147,
		part_id: 302,
		quantity: 6,
	},
	{
		garage_id: 147,
		part_id: 303,
		quantity: 159,
	},
	{
		garage_id: 147,
		part_id: 304,
		quantity: 278,
	},
	{
		garage_id: 147,
		part_id: 305,
		quantity: 317,
	},
	{
		garage_id: 147,
		part_id: 306,
		quantity: 318,
	},
	{
		garage_id: 147,
		part_id: 307,
		quantity: 273,
	},
	{
		garage_id: 147,
		part_id: 308,
		quantity: 311,
	},
	{
		garage_id: 147,
		part_id: 309,
		quantity: 493,
	},
	{
		garage_id: 147,
		part_id: 310,
		quantity: 160,
	},
	{
		garage_id: 147,
		part_id: 311,
		quantity: 57,
	},
	{
		garage_id: 147,
		part_id: 312,
		quantity: 364,
	},
	{
		garage_id: 147,
		part_id: 313,
		quantity: 106,
	},
	{
		garage_id: 147,
		part_id: 314,
		quantity: 452,
	},
	{
		garage_id: 147,
		part_id: 315,
		quantity: 9,
	},
	{
		garage_id: 147,
		part_id: 316,
		quantity: 119,
	},
	{
		garage_id: 147,
		part_id: 317,
		quantity: 247,
	},
	{
		garage_id: 147,
		part_id: 318,
		quantity: 495,
	},
	{
		garage_id: 147,
		part_id: 319,
		quantity: 462,
	},
	{
		garage_id: 147,
		part_id: 320,
		quantity: 223,
	},
	{
		garage_id: 147,
		part_id: 321,
		quantity: 246,
	},
	{
		garage_id: 147,
		part_id: 322,
		quantity: 244,
	},
	{
		garage_id: 147,
		part_id: 323,
		quantity: 226,
	},
	{
		garage_id: 147,
		part_id: 324,
		quantity: 317,
	},
	{
		garage_id: 147,
		part_id: 325,
		quantity: 496,
	},
	{
		garage_id: 147,
		part_id: 326,
		quantity: 81,
	},
	{
		garage_id: 147,
		part_id: 327,
		quantity: 3,
	},
	{
		garage_id: 147,
		part_id: 328,
		quantity: 53,
	},
	{
		garage_id: 147,
		part_id: 329,
		quantity: 226,
	},
	{
		garage_id: 147,
		part_id: 330,
		quantity: 174,
	},
	{
		garage_id: 147,
		part_id: 331,
		quantity: 2,
	},
	{
		garage_id: 147,
		part_id: 332,
		quantity: 411,
	},
	{
		garage_id: 147,
		part_id: 333,
		quantity: 106,
	},
	{
		garage_id: 147,
		part_id: 334,
		quantity: 281,
	},
	{
		garage_id: 147,
		part_id: 335,
		quantity: 179,
	},
	{
		garage_id: 147,
		part_id: 336,
		quantity: 92,
	},
	{
		garage_id: 147,
		part_id: 337,
		quantity: 283,
	},
	{
		garage_id: 147,
		part_id: 338,
		quantity: 450,
	},
	{
		garage_id: 147,
		part_id: 339,
		quantity: 301,
	},
	{
		garage_id: 147,
		part_id: 340,
		quantity: 124,
	},
	{
		garage_id: 147,
		part_id: 341,
		quantity: 49,
	},
	{
		garage_id: 147,
		part_id: 342,
		quantity: 366,
	},
	{
		garage_id: 147,
		part_id: 343,
		quantity: 349,
	},
	{
		garage_id: 147,
		part_id: 344,
		quantity: 205,
	},
	{
		garage_id: 147,
		part_id: 345,
		quantity: 185,
	},
	{
		garage_id: 147,
		part_id: 346,
		quantity: 6,
	},
	{
		garage_id: 147,
		part_id: 347,
		quantity: 222,
	},
	{
		garage_id: 147,
		part_id: 348,
		quantity: 179,
	},
	{
		garage_id: 147,
		part_id: 349,
		quantity: 111,
	},
	{
		garage_id: 147,
		part_id: 350,
		quantity: 224,
	},
	{
		garage_id: 147,
		part_id: 351,
		quantity: 183,
	},
	{
		garage_id: 147,
		part_id: 352,
		quantity: 287,
	},
	{
		garage_id: 147,
		part_id: 353,
		quantity: 457,
	},
	{
		garage_id: 147,
		part_id: 354,
		quantity: 236,
	},
	{
		garage_id: 147,
		part_id: 355,
		quantity: 17,
	},
	{
		garage_id: 147,
		part_id: 356,
		quantity: 376,
	},
	{
		garage_id: 147,
		part_id: 357,
		quantity: 480,
	},
	{
		garage_id: 147,
		part_id: 358,
		quantity: 266,
	},
	{
		garage_id: 147,
		part_id: 359,
		quantity: 135,
	},
	{
		garage_id: 147,
		part_id: 360,
		quantity: 162,
	},
	{
		garage_id: 147,
		part_id: 361,
		quantity: 60,
	},
	{
		garage_id: 147,
		part_id: 362,
		quantity: 467,
	},
	{
		garage_id: 147,
		part_id: 363,
		quantity: 398,
	},
	{
		garage_id: 147,
		part_id: 364,
		quantity: 405,
	},
	{
		garage_id: 147,
		part_id: 365,
		quantity: 317,
	},
	{
		garage_id: 147,
		part_id: 366,
		quantity: 223,
	},
	{
		garage_id: 147,
		part_id: 367,
		quantity: 45,
	},
	{
		garage_id: 147,
		part_id: 368,
		quantity: 115,
	},
	{
		garage_id: 147,
		part_id: 369,
		quantity: 37,
	},
	{
		garage_id: 147,
		part_id: 370,
		quantity: 394,
	},
	{
		garage_id: 147,
		part_id: 371,
		quantity: 96,
	},
	{
		garage_id: 147,
		part_id: 372,
		quantity: 434,
	},
	{
		garage_id: 147,
		part_id: 373,
		quantity: 404,
	},
	{
		garage_id: 147,
		part_id: 374,
		quantity: 427,
	},
	{
		garage_id: 147,
		part_id: 375,
		quantity: 234,
	},
	{
		garage_id: 147,
		part_id: 376,
		quantity: 93,
	},
	{
		garage_id: 147,
		part_id: 377,
		quantity: 486,
	},
	{
		garage_id: 147,
		part_id: 378,
		quantity: 452,
	},
	{
		garage_id: 147,
		part_id: 379,
		quantity: 176,
	},
	{
		garage_id: 147,
		part_id: 380,
		quantity: 308,
	},
	{
		garage_id: 147,
		part_id: 381,
		quantity: 22,
	},
	{
		garage_id: 147,
		part_id: 382,
		quantity: 88,
	},
	{
		garage_id: 147,
		part_id: 383,
		quantity: 192,
	},
	{
		garage_id: 147,
		part_id: 384,
		quantity: 170,
	},
	{
		garage_id: 147,
		part_id: 385,
		quantity: 100,
	},
	{
		garage_id: 147,
		part_id: 386,
		quantity: 178,
	},
	{
		garage_id: 147,
		part_id: 387,
		quantity: 364,
	},
	{
		garage_id: 147,
		part_id: 388,
		quantity: 278,
	},
	{
		garage_id: 147,
		part_id: 389,
		quantity: 382,
	},
	{
		garage_id: 147,
		part_id: 390,
		quantity: 287,
	},
	{
		garage_id: 147,
		part_id: 391,
		quantity: 34,
	},
	{
		garage_id: 147,
		part_id: 392,
		quantity: 356,
	},
	{
		garage_id: 147,
		part_id: 393,
		quantity: 282,
	},
	{
		garage_id: 147,
		part_id: 394,
		quantity: 181,
	},
	{
		garage_id: 147,
		part_id: 395,
		quantity: 328,
	},
	{
		garage_id: 147,
		part_id: 396,
		quantity: 447,
	},
	{
		garage_id: 147,
		part_id: 397,
		quantity: 474,
	},
	{
		garage_id: 147,
		part_id: 398,
		quantity: 192,
	},
	{
		garage_id: 147,
		part_id: 399,
		quantity: 40,
	},
	{
		garage_id: 148,
		part_id: 0,
		quantity: 19,
	},
	{
		garage_id: 148,
		part_id: 1,
		quantity: 224,
	},
	{
		garage_id: 148,
		part_id: 2,
		quantity: 352,
	},
	{
		garage_id: 148,
		part_id: 3,
		quantity: 402,
	},
	{
		garage_id: 148,
		part_id: 4,
		quantity: 320,
	},
	{
		garage_id: 148,
		part_id: 5,
		quantity: 268,
	},
	{
		garage_id: 148,
		part_id: 6,
		quantity: 303,
	},
	{
		garage_id: 148,
		part_id: 7,
		quantity: 171,
	},
	{
		garage_id: 148,
		part_id: 8,
		quantity: 400,
	},
	{
		garage_id: 148,
		part_id: 9,
		quantity: 112,
	},
	{
		garage_id: 148,
		part_id: 10,
		quantity: 32,
	},
	{
		garage_id: 148,
		part_id: 11,
		quantity: 285,
	},
	{
		garage_id: 148,
		part_id: 12,
		quantity: 184,
	},
	{
		garage_id: 148,
		part_id: 13,
		quantity: 385,
	},
	{
		garage_id: 148,
		part_id: 14,
		quantity: 478,
	},
	{
		garage_id: 148,
		part_id: 15,
		quantity: 190,
	},
	{
		garage_id: 148,
		part_id: 16,
		quantity: 447,
	},
	{
		garage_id: 148,
		part_id: 17,
		quantity: 319,
	},
	{
		garage_id: 148,
		part_id: 18,
		quantity: 398,
	},
	{
		garage_id: 148,
		part_id: 19,
		quantity: 256,
	},
	{
		garage_id: 148,
		part_id: 20,
		quantity: 350,
	},
	{
		garage_id: 148,
		part_id: 21,
		quantity: 336,
	},
	{
		garage_id: 148,
		part_id: 22,
		quantity: 410,
	},
	{
		garage_id: 148,
		part_id: 23,
		quantity: 344,
	},
	{
		garage_id: 148,
		part_id: 24,
		quantity: 486,
	},
	{
		garage_id: 148,
		part_id: 25,
		quantity: 337,
	},
	{
		garage_id: 148,
		part_id: 26,
		quantity: 483,
	},
	{
		garage_id: 148,
		part_id: 27,
		quantity: 143,
	},
	{
		garage_id: 148,
		part_id: 28,
		quantity: 257,
	},
	{
		garage_id: 148,
		part_id: 29,
		quantity: 73,
	},
	{
		garage_id: 148,
		part_id: 30,
		quantity: 312,
	},
	{
		garage_id: 148,
		part_id: 31,
		quantity: 146,
	},
	{
		garage_id: 148,
		part_id: 32,
		quantity: 216,
	},
	{
		garage_id: 148,
		part_id: 33,
		quantity: 97,
	},
	{
		garage_id: 148,
		part_id: 34,
		quantity: 233,
	},
	{
		garage_id: 148,
		part_id: 35,
		quantity: 334,
	},
	{
		garage_id: 148,
		part_id: 36,
		quantity: 458,
	},
	{
		garage_id: 148,
		part_id: 37,
		quantity: 183,
	},
	{
		garage_id: 148,
		part_id: 38,
		quantity: 471,
	},
	{
		garage_id: 148,
		part_id: 39,
		quantity: 445,
	},
	{
		garage_id: 148,
		part_id: 40,
		quantity: 340,
	},
	{
		garage_id: 148,
		part_id: 41,
		quantity: 135,
	},
	{
		garage_id: 148,
		part_id: 42,
		quantity: 325,
	},
	{
		garage_id: 148,
		part_id: 43,
		quantity: 318,
	},
	{
		garage_id: 148,
		part_id: 44,
		quantity: 160,
	},
	{
		garage_id: 148,
		part_id: 45,
		quantity: 187,
	},
	{
		garage_id: 148,
		part_id: 46,
		quantity: 242,
	},
	{
		garage_id: 148,
		part_id: 47,
		quantity: 166,
	},
	{
		garage_id: 148,
		part_id: 48,
		quantity: 447,
	},
	{
		garage_id: 148,
		part_id: 49,
		quantity: 328,
	},
	{
		garage_id: 148,
		part_id: 50,
		quantity: 35,
	},
	{
		garage_id: 148,
		part_id: 51,
		quantity: 380,
	},
	{
		garage_id: 148,
		part_id: 52,
		quantity: 410,
	},
	{
		garage_id: 148,
		part_id: 53,
		quantity: 238,
	},
	{
		garage_id: 148,
		part_id: 54,
		quantity: 171,
	},
	{
		garage_id: 148,
		part_id: 55,
		quantity: 349,
	},
	{
		garage_id: 148,
		part_id: 56,
		quantity: 236,
	},
	{
		garage_id: 148,
		part_id: 57,
		quantity: 131,
	},
	{
		garage_id: 148,
		part_id: 58,
		quantity: 347,
	},
	{
		garage_id: 148,
		part_id: 59,
		quantity: 40,
	},
	{
		garage_id: 148,
		part_id: 60,
		quantity: 495,
	},
	{
		garage_id: 148,
		part_id: 61,
		quantity: 176,
	},
	{
		garage_id: 148,
		part_id: 62,
		quantity: 297,
	},
	{
		garage_id: 148,
		part_id: 63,
		quantity: 392,
	},
	{
		garage_id: 148,
		part_id: 64,
		quantity: 29,
	},
	{
		garage_id: 148,
		part_id: 65,
		quantity: 469,
	},
	{
		garage_id: 148,
		part_id: 66,
		quantity: 372,
	},
	{
		garage_id: 148,
		part_id: 67,
		quantity: 408,
	},
	{
		garage_id: 148,
		part_id: 68,
		quantity: 463,
	},
	{
		garage_id: 148,
		part_id: 69,
		quantity: 453,
	},
	{
		garage_id: 148,
		part_id: 70,
		quantity: 309,
	},
	{
		garage_id: 148,
		part_id: 71,
		quantity: 33,
	},
	{
		garage_id: 148,
		part_id: 72,
		quantity: 37,
	},
	{
		garage_id: 148,
		part_id: 73,
		quantity: 395,
	},
	{
		garage_id: 148,
		part_id: 74,
		quantity: 309,
	},
	{
		garage_id: 148,
		part_id: 75,
		quantity: 301,
	},
	{
		garage_id: 148,
		part_id: 76,
		quantity: 315,
	},
	{
		garage_id: 148,
		part_id: 77,
		quantity: 355,
	},
	{
		garage_id: 148,
		part_id: 78,
		quantity: 327,
	},
	{
		garage_id: 148,
		part_id: 79,
		quantity: 71,
	},
	{
		garage_id: 148,
		part_id: 80,
		quantity: 442,
	},
	{
		garage_id: 148,
		part_id: 81,
		quantity: 38,
	},
	{
		garage_id: 148,
		part_id: 82,
		quantity: 203,
	},
	{
		garage_id: 148,
		part_id: 83,
		quantity: 90,
	},
	{
		garage_id: 148,
		part_id: 84,
		quantity: 294,
	},
	{
		garage_id: 148,
		part_id: 85,
		quantity: 283,
	},
	{
		garage_id: 148,
		part_id: 86,
		quantity: 5,
	},
	{
		garage_id: 148,
		part_id: 87,
		quantity: 214,
	},
	{
		garage_id: 148,
		part_id: 88,
		quantity: 58,
	},
	{
		garage_id: 148,
		part_id: 89,
		quantity: 142,
	},
	{
		garage_id: 148,
		part_id: 90,
		quantity: 418,
	},
	{
		garage_id: 148,
		part_id: 91,
		quantity: 355,
	},
	{
		garage_id: 148,
		part_id: 92,
		quantity: 284,
	},
	{
		garage_id: 148,
		part_id: 93,
		quantity: 310,
	},
	{
		garage_id: 148,
		part_id: 94,
		quantity: 264,
	},
	{
		garage_id: 148,
		part_id: 95,
		quantity: 479,
	},
	{
		garage_id: 148,
		part_id: 96,
		quantity: 349,
	},
	{
		garage_id: 148,
		part_id: 97,
		quantity: 385,
	},
	{
		garage_id: 148,
		part_id: 98,
		quantity: 386,
	},
	{
		garage_id: 148,
		part_id: 99,
		quantity: 1,
	},
	{
		garage_id: 148,
		part_id: 100,
		quantity: 372,
	},
	{
		garage_id: 148,
		part_id: 101,
		quantity: 43,
	},
	{
		garage_id: 148,
		part_id: 102,
		quantity: 94,
	},
	{
		garage_id: 148,
		part_id: 103,
		quantity: 266,
	},
	{
		garage_id: 148,
		part_id: 104,
		quantity: 338,
	},
	{
		garage_id: 148,
		part_id: 105,
		quantity: 164,
	},
	{
		garage_id: 148,
		part_id: 106,
		quantity: 101,
	},
	{
		garage_id: 148,
		part_id: 107,
		quantity: 5,
	},
	{
		garage_id: 148,
		part_id: 108,
		quantity: 105,
	},
	{
		garage_id: 148,
		part_id: 109,
		quantity: 151,
	},
	{
		garage_id: 148,
		part_id: 110,
		quantity: 327,
	},
	{
		garage_id: 148,
		part_id: 111,
		quantity: 61,
	},
	{
		garage_id: 148,
		part_id: 112,
		quantity: 500,
	},
	{
		garage_id: 148,
		part_id: 113,
		quantity: 229,
	},
	{
		garage_id: 148,
		part_id: 114,
		quantity: 352,
	},
	{
		garage_id: 148,
		part_id: 115,
		quantity: 112,
	},
	{
		garage_id: 148,
		part_id: 116,
		quantity: 234,
	},
	{
		garage_id: 148,
		part_id: 117,
		quantity: 487,
	},
	{
		garage_id: 148,
		part_id: 118,
		quantity: 11,
	},
	{
		garage_id: 148,
		part_id: 119,
		quantity: 301,
	},
	{
		garage_id: 148,
		part_id: 120,
		quantity: 342,
	},
	{
		garage_id: 148,
		part_id: 121,
		quantity: 342,
	},
	{
		garage_id: 148,
		part_id: 122,
		quantity: 61,
	},
	{
		garage_id: 148,
		part_id: 123,
		quantity: 85,
	},
	{
		garage_id: 148,
		part_id: 124,
		quantity: 372,
	},
	{
		garage_id: 148,
		part_id: 125,
		quantity: 351,
	},
	{
		garage_id: 148,
		part_id: 126,
		quantity: 121,
	},
	{
		garage_id: 148,
		part_id: 127,
		quantity: 411,
	},
	{
		garage_id: 148,
		part_id: 128,
		quantity: 201,
	},
	{
		garage_id: 148,
		part_id: 129,
		quantity: 324,
	},
	{
		garage_id: 148,
		part_id: 130,
		quantity: 110,
	},
	{
		garage_id: 148,
		part_id: 131,
		quantity: 185,
	},
	{
		garage_id: 148,
		part_id: 132,
		quantity: 262,
	},
	{
		garage_id: 148,
		part_id: 133,
		quantity: 126,
	},
	{
		garage_id: 148,
		part_id: 134,
		quantity: 458,
	},
	{
		garage_id: 148,
		part_id: 135,
		quantity: 229,
	},
	{
		garage_id: 148,
		part_id: 136,
		quantity: 324,
	},
	{
		garage_id: 148,
		part_id: 137,
		quantity: 104,
	},
	{
		garage_id: 148,
		part_id: 138,
		quantity: 158,
	},
	{
		garage_id: 148,
		part_id: 139,
		quantity: 347,
	},
	{
		garage_id: 148,
		part_id: 140,
		quantity: 342,
	},
	{
		garage_id: 148,
		part_id: 141,
		quantity: 180,
	},
	{
		garage_id: 148,
		part_id: 142,
		quantity: 489,
	},
	{
		garage_id: 148,
		part_id: 143,
		quantity: 320,
	},
	{
		garage_id: 148,
		part_id: 144,
		quantity: 182,
	},
	{
		garage_id: 148,
		part_id: 145,
		quantity: 488,
	},
	{
		garage_id: 148,
		part_id: 146,
		quantity: 198,
	},
	{
		garage_id: 148,
		part_id: 147,
		quantity: 364,
	},
	{
		garage_id: 148,
		part_id: 148,
		quantity: 411,
	},
	{
		garage_id: 148,
		part_id: 149,
		quantity: 159,
	},
	{
		garage_id: 148,
		part_id: 150,
		quantity: 199,
	},
	{
		garage_id: 148,
		part_id: 151,
		quantity: 242,
	},
	{
		garage_id: 148,
		part_id: 152,
		quantity: 426,
	},
	{
		garage_id: 148,
		part_id: 153,
		quantity: 492,
	},
	{
		garage_id: 148,
		part_id: 154,
		quantity: 342,
	},
	{
		garage_id: 148,
		part_id: 155,
		quantity: 255,
	},
	{
		garage_id: 148,
		part_id: 156,
		quantity: 334,
	},
	{
		garage_id: 148,
		part_id: 157,
		quantity: 443,
	},
	{
		garage_id: 148,
		part_id: 158,
		quantity: 372,
	},
	{
		garage_id: 148,
		part_id: 159,
		quantity: 151,
	},
	{
		garage_id: 148,
		part_id: 160,
		quantity: 309,
	},
	{
		garage_id: 148,
		part_id: 161,
		quantity: 81,
	},
	{
		garage_id: 148,
		part_id: 162,
		quantity: 90,
	},
	{
		garage_id: 148,
		part_id: 163,
		quantity: 465,
	},
	{
		garage_id: 148,
		part_id: 164,
		quantity: 454,
	},
	{
		garage_id: 148,
		part_id: 165,
		quantity: 102,
	},
	{
		garage_id: 148,
		part_id: 166,
		quantity: 52,
	},
	{
		garage_id: 148,
		part_id: 167,
		quantity: 114,
	},
	{
		garage_id: 148,
		part_id: 168,
		quantity: 315,
	},
	{
		garage_id: 148,
		part_id: 169,
		quantity: 320,
	},
	{
		garage_id: 148,
		part_id: 170,
		quantity: 34,
	},
	{
		garage_id: 148,
		part_id: 171,
		quantity: 367,
	},
	{
		garage_id: 148,
		part_id: 172,
		quantity: 183,
	},
	{
		garage_id: 148,
		part_id: 173,
		quantity: 499,
	},
	{
		garage_id: 148,
		part_id: 174,
		quantity: 59,
	},
	{
		garage_id: 148,
		part_id: 175,
		quantity: 11,
	},
	{
		garage_id: 148,
		part_id: 176,
		quantity: 107,
	},
	{
		garage_id: 148,
		part_id: 177,
		quantity: 354,
	},
	{
		garage_id: 148,
		part_id: 178,
		quantity: 241,
	},
	{
		garage_id: 148,
		part_id: 179,
		quantity: 333,
	},
	{
		garage_id: 148,
		part_id: 180,
		quantity: 350,
	},
	{
		garage_id: 148,
		part_id: 181,
		quantity: 399,
	},
	{
		garage_id: 148,
		part_id: 182,
		quantity: 420,
	},
	{
		garage_id: 148,
		part_id: 183,
		quantity: 288,
	},
	{
		garage_id: 148,
		part_id: 184,
		quantity: 107,
	},
	{
		garage_id: 148,
		part_id: 185,
		quantity: 375,
	},
	{
		garage_id: 148,
		part_id: 186,
		quantity: 422,
	},
	{
		garage_id: 148,
		part_id: 187,
		quantity: 272,
	},
	{
		garage_id: 148,
		part_id: 188,
		quantity: 326,
	},
	{
		garage_id: 148,
		part_id: 189,
		quantity: 129,
	},
	{
		garage_id: 148,
		part_id: 190,
		quantity: 302,
	},
	{
		garage_id: 148,
		part_id: 191,
		quantity: 438,
	},
	{
		garage_id: 148,
		part_id: 192,
		quantity: 71,
	},
	{
		garage_id: 148,
		part_id: 193,
		quantity: 471,
	},
	{
		garage_id: 148,
		part_id: 194,
		quantity: 63,
	},
	{
		garage_id: 148,
		part_id: 195,
		quantity: 102,
	},
	{
		garage_id: 148,
		part_id: 196,
		quantity: 238,
	},
	{
		garage_id: 148,
		part_id: 197,
		quantity: 122,
	},
	{
		garage_id: 148,
		part_id: 198,
		quantity: 369,
	},
	{
		garage_id: 148,
		part_id: 199,
		quantity: 126,
	},
	{
		garage_id: 148,
		part_id: 200,
		quantity: 80,
	},
	{
		garage_id: 148,
		part_id: 201,
		quantity: 275,
	},
	{
		garage_id: 148,
		part_id: 202,
		quantity: 427,
	},
	{
		garage_id: 148,
		part_id: 203,
		quantity: 96,
	},
	{
		garage_id: 148,
		part_id: 204,
		quantity: 40,
	},
	{
		garage_id: 148,
		part_id: 205,
		quantity: 255,
	},
	{
		garage_id: 148,
		part_id: 206,
		quantity: 452,
	},
	{
		garage_id: 148,
		part_id: 207,
		quantity: 480,
	},
	{
		garage_id: 148,
		part_id: 208,
		quantity: 271,
	},
	{
		garage_id: 148,
		part_id: 209,
		quantity: 186,
	},
	{
		garage_id: 148,
		part_id: 210,
		quantity: 409,
	},
	{
		garage_id: 148,
		part_id: 211,
		quantity: 218,
	},
	{
		garage_id: 148,
		part_id: 212,
		quantity: 313,
	},
	{
		garage_id: 148,
		part_id: 213,
		quantity: 306,
	},
	{
		garage_id: 148,
		part_id: 214,
		quantity: 266,
	},
	{
		garage_id: 148,
		part_id: 215,
		quantity: 299,
	},
	{
		garage_id: 148,
		part_id: 216,
		quantity: 74,
	},
	{
		garage_id: 148,
		part_id: 217,
		quantity: 341,
	},
	{
		garage_id: 148,
		part_id: 218,
		quantity: 187,
	},
	{
		garage_id: 148,
		part_id: 219,
		quantity: 272,
	},
	{
		garage_id: 148,
		part_id: 220,
		quantity: 244,
	},
	{
		garage_id: 148,
		part_id: 221,
		quantity: 29,
	},
	{
		garage_id: 148,
		part_id: 222,
		quantity: 324,
	},
	{
		garage_id: 148,
		part_id: 223,
		quantity: 62,
	},
	{
		garage_id: 148,
		part_id: 224,
		quantity: 183,
	},
	{
		garage_id: 148,
		part_id: 225,
		quantity: 464,
	},
	{
		garage_id: 148,
		part_id: 226,
		quantity: 158,
	},
	{
		garage_id: 148,
		part_id: 227,
		quantity: 189,
	},
	{
		garage_id: 148,
		part_id: 228,
		quantity: 335,
	},
	{
		garage_id: 148,
		part_id: 229,
		quantity: 260,
	},
	{
		garage_id: 148,
		part_id: 230,
		quantity: 143,
	},
	{
		garage_id: 148,
		part_id: 231,
		quantity: 41,
	},
	{
		garage_id: 148,
		part_id: 232,
		quantity: 70,
	},
	{
		garage_id: 148,
		part_id: 233,
		quantity: 237,
	},
	{
		garage_id: 148,
		part_id: 234,
		quantity: 126,
	},
	{
		garage_id: 148,
		part_id: 235,
		quantity: 223,
	},
	{
		garage_id: 148,
		part_id: 236,
		quantity: 367,
	},
	{
		garage_id: 148,
		part_id: 237,
		quantity: 174,
	},
	{
		garage_id: 148,
		part_id: 238,
		quantity: 150,
	},
	{
		garage_id: 148,
		part_id: 239,
		quantity: 320,
	},
	{
		garage_id: 148,
		part_id: 240,
		quantity: 437,
	},
	{
		garage_id: 148,
		part_id: 241,
		quantity: 239,
	},
	{
		garage_id: 148,
		part_id: 242,
		quantity: 144,
	},
	{
		garage_id: 148,
		part_id: 243,
		quantity: 357,
	},
	{
		garage_id: 148,
		part_id: 244,
		quantity: 363,
	},
	{
		garage_id: 148,
		part_id: 245,
		quantity: 289,
	},
	{
		garage_id: 148,
		part_id: 246,
		quantity: 340,
	},
	{
		garage_id: 148,
		part_id: 247,
		quantity: 202,
	},
	{
		garage_id: 148,
		part_id: 248,
		quantity: 175,
	},
	{
		garage_id: 148,
		part_id: 249,
		quantity: 243,
	},
	{
		garage_id: 148,
		part_id: 250,
		quantity: 457,
	},
	{
		garage_id: 148,
		part_id: 251,
		quantity: 282,
	},
	{
		garage_id: 148,
		part_id: 252,
		quantity: 59,
	},
	{
		garage_id: 148,
		part_id: 253,
		quantity: 199,
	},
	{
		garage_id: 148,
		part_id: 254,
		quantity: 395,
	},
	{
		garage_id: 148,
		part_id: 255,
		quantity: 451,
	},
	{
		garage_id: 148,
		part_id: 256,
		quantity: 277,
	},
	{
		garage_id: 148,
		part_id: 257,
		quantity: 407,
	},
	{
		garage_id: 148,
		part_id: 258,
		quantity: 82,
	},
	{
		garage_id: 148,
		part_id: 259,
		quantity: 500,
	},
	{
		garage_id: 148,
		part_id: 260,
		quantity: 28,
	},
	{
		garage_id: 148,
		part_id: 261,
		quantity: 338,
	},
	{
		garage_id: 148,
		part_id: 262,
		quantity: 418,
	},
	{
		garage_id: 148,
		part_id: 263,
		quantity: 465,
	},
	{
		garage_id: 148,
		part_id: 264,
		quantity: 393,
	},
	{
		garage_id: 148,
		part_id: 265,
		quantity: 472,
	},
	{
		garage_id: 148,
		part_id: 266,
		quantity: 411,
	},
	{
		garage_id: 148,
		part_id: 267,
		quantity: 496,
	},
	{
		garage_id: 148,
		part_id: 268,
		quantity: 171,
	},
	{
		garage_id: 148,
		part_id: 269,
		quantity: 389,
	},
	{
		garage_id: 148,
		part_id: 270,
		quantity: 140,
	},
	{
		garage_id: 148,
		part_id: 271,
		quantity: 109,
	},
	{
		garage_id: 148,
		part_id: 272,
		quantity: 217,
	},
	{
		garage_id: 148,
		part_id: 273,
		quantity: 461,
	},
	{
		garage_id: 148,
		part_id: 274,
		quantity: 78,
	},
	{
		garage_id: 148,
		part_id: 275,
		quantity: 5,
	},
	{
		garage_id: 148,
		part_id: 276,
		quantity: 187,
	},
	{
		garage_id: 148,
		part_id: 277,
		quantity: 269,
	},
	{
		garage_id: 148,
		part_id: 278,
		quantity: 280,
	},
	{
		garage_id: 148,
		part_id: 279,
		quantity: 322,
	},
	{
		garage_id: 148,
		part_id: 280,
		quantity: 16,
	},
	{
		garage_id: 148,
		part_id: 281,
		quantity: 53,
	},
	{
		garage_id: 148,
		part_id: 282,
		quantity: 312,
	},
	{
		garage_id: 148,
		part_id: 283,
		quantity: 463,
	},
	{
		garage_id: 148,
		part_id: 284,
		quantity: 453,
	},
	{
		garage_id: 148,
		part_id: 285,
		quantity: 371,
	},
	{
		garage_id: 148,
		part_id: 286,
		quantity: 389,
	},
	{
		garage_id: 148,
		part_id: 287,
		quantity: 419,
	},
	{
		garage_id: 148,
		part_id: 288,
		quantity: 1,
	},
	{
		garage_id: 148,
		part_id: 289,
		quantity: 342,
	},
	{
		garage_id: 148,
		part_id: 290,
		quantity: 59,
	},
	{
		garage_id: 148,
		part_id: 291,
		quantity: 334,
	},
	{
		garage_id: 148,
		part_id: 292,
		quantity: 96,
	},
	{
		garage_id: 148,
		part_id: 293,
		quantity: 270,
	},
	{
		garage_id: 148,
		part_id: 294,
		quantity: 211,
	},
	{
		garage_id: 148,
		part_id: 295,
		quantity: 115,
	},
	{
		garage_id: 148,
		part_id: 296,
		quantity: 7,
	},
	{
		garage_id: 148,
		part_id: 297,
		quantity: 6,
	},
	{
		garage_id: 148,
		part_id: 298,
		quantity: 208,
	},
	{
		garage_id: 148,
		part_id: 299,
		quantity: 160,
	},
	{
		garage_id: 148,
		part_id: 300,
		quantity: 455,
	},
	{
		garage_id: 148,
		part_id: 301,
		quantity: 420,
	},
	{
		garage_id: 148,
		part_id: 302,
		quantity: 443,
	},
	{
		garage_id: 148,
		part_id: 303,
		quantity: 37,
	},
	{
		garage_id: 148,
		part_id: 304,
		quantity: 378,
	},
	{
		garage_id: 148,
		part_id: 305,
		quantity: 386,
	},
	{
		garage_id: 148,
		part_id: 306,
		quantity: 87,
	},
	{
		garage_id: 148,
		part_id: 307,
		quantity: 147,
	},
	{
		garage_id: 148,
		part_id: 308,
		quantity: 252,
	},
	{
		garage_id: 148,
		part_id: 309,
		quantity: 320,
	},
	{
		garage_id: 148,
		part_id: 310,
		quantity: 282,
	},
	{
		garage_id: 148,
		part_id: 311,
		quantity: 384,
	},
	{
		garage_id: 148,
		part_id: 312,
		quantity: 178,
	},
	{
		garage_id: 148,
		part_id: 313,
		quantity: 124,
	},
	{
		garage_id: 148,
		part_id: 314,
		quantity: 341,
	},
	{
		garage_id: 148,
		part_id: 315,
		quantity: 248,
	},
	{
		garage_id: 148,
		part_id: 316,
		quantity: 479,
	},
	{
		garage_id: 148,
		part_id: 317,
		quantity: 411,
	},
	{
		garage_id: 148,
		part_id: 318,
		quantity: 85,
	},
	{
		garage_id: 148,
		part_id: 319,
		quantity: 411,
	},
	{
		garage_id: 148,
		part_id: 320,
		quantity: 464,
	},
	{
		garage_id: 148,
		part_id: 321,
		quantity: 139,
	},
	{
		garage_id: 148,
		part_id: 322,
		quantity: 297,
	},
	{
		garage_id: 148,
		part_id: 323,
		quantity: 69,
	},
	{
		garage_id: 148,
		part_id: 324,
		quantity: 271,
	},
	{
		garage_id: 148,
		part_id: 325,
		quantity: 178,
	},
	{
		garage_id: 148,
		part_id: 326,
		quantity: 32,
	},
	{
		garage_id: 148,
		part_id: 327,
		quantity: 62,
	},
	{
		garage_id: 148,
		part_id: 328,
		quantity: 52,
	},
	{
		garage_id: 148,
		part_id: 329,
		quantity: 252,
	},
	{
		garage_id: 148,
		part_id: 330,
		quantity: 455,
	},
	{
		garage_id: 148,
		part_id: 331,
		quantity: 383,
	},
	{
		garage_id: 148,
		part_id: 332,
		quantity: 37,
	},
	{
		garage_id: 148,
		part_id: 333,
		quantity: 16,
	},
	{
		garage_id: 148,
		part_id: 334,
		quantity: 328,
	},
	{
		garage_id: 148,
		part_id: 335,
		quantity: 295,
	},
	{
		garage_id: 148,
		part_id: 336,
		quantity: 55,
	},
	{
		garage_id: 148,
		part_id: 337,
		quantity: 92,
	},
	{
		garage_id: 148,
		part_id: 338,
		quantity: 117,
	},
	{
		garage_id: 148,
		part_id: 339,
		quantity: 461,
	},
	{
		garage_id: 148,
		part_id: 340,
		quantity: 201,
	},
	{
		garage_id: 148,
		part_id: 341,
		quantity: 324,
	},
	{
		garage_id: 148,
		part_id: 342,
		quantity: 63,
	},
	{
		garage_id: 148,
		part_id: 343,
		quantity: 210,
	},
	{
		garage_id: 148,
		part_id: 344,
		quantity: 112,
	},
	{
		garage_id: 148,
		part_id: 345,
		quantity: 138,
	},
	{
		garage_id: 148,
		part_id: 346,
		quantity: 2,
	},
	{
		garage_id: 148,
		part_id: 347,
		quantity: 223,
	},
	{
		garage_id: 148,
		part_id: 348,
		quantity: 201,
	},
	{
		garage_id: 148,
		part_id: 349,
		quantity: 385,
	},
	{
		garage_id: 148,
		part_id: 350,
		quantity: 51,
	},
	{
		garage_id: 148,
		part_id: 351,
		quantity: 228,
	},
	{
		garage_id: 148,
		part_id: 352,
		quantity: 216,
	},
	{
		garage_id: 148,
		part_id: 353,
		quantity: 406,
	},
	{
		garage_id: 148,
		part_id: 354,
		quantity: 58,
	},
	{
		garage_id: 148,
		part_id: 355,
		quantity: 398,
	},
	{
		garage_id: 148,
		part_id: 356,
		quantity: 475,
	},
	{
		garage_id: 148,
		part_id: 357,
		quantity: 368,
	},
	{
		garage_id: 148,
		part_id: 358,
		quantity: 285,
	},
	{
		garage_id: 148,
		part_id: 359,
		quantity: 88,
	},
	{
		garage_id: 148,
		part_id: 360,
		quantity: 435,
	},
	{
		garage_id: 148,
		part_id: 361,
		quantity: 59,
	},
	{
		garage_id: 148,
		part_id: 362,
		quantity: 135,
	},
	{
		garage_id: 148,
		part_id: 363,
		quantity: 352,
	},
	{
		garage_id: 148,
		part_id: 364,
		quantity: 175,
	},
	{
		garage_id: 148,
		part_id: 365,
		quantity: 338,
	},
	{
		garage_id: 148,
		part_id: 366,
		quantity: 311,
	},
	{
		garage_id: 148,
		part_id: 367,
		quantity: 305,
	},
	{
		garage_id: 148,
		part_id: 368,
		quantity: 26,
	},
	{
		garage_id: 148,
		part_id: 369,
		quantity: 459,
	},
	{
		garage_id: 148,
		part_id: 370,
		quantity: 137,
	},
	{
		garage_id: 148,
		part_id: 371,
		quantity: 317,
	},
	{
		garage_id: 148,
		part_id: 372,
		quantity: 459,
	},
	{
		garage_id: 148,
		part_id: 373,
		quantity: 374,
	},
	{
		garage_id: 148,
		part_id: 374,
		quantity: 259,
	},
	{
		garage_id: 148,
		part_id: 375,
		quantity: 111,
	},
	{
		garage_id: 148,
		part_id: 376,
		quantity: 316,
	},
	{
		garage_id: 148,
		part_id: 377,
		quantity: 386,
	},
	{
		garage_id: 148,
		part_id: 378,
		quantity: 393,
	},
	{
		garage_id: 148,
		part_id: 379,
		quantity: 187,
	},
	{
		garage_id: 148,
		part_id: 380,
		quantity: 141,
	},
	{
		garage_id: 148,
		part_id: 381,
		quantity: 189,
	},
	{
		garage_id: 148,
		part_id: 382,
		quantity: 144,
	},
	{
		garage_id: 148,
		part_id: 383,
		quantity: 43,
	},
	{
		garage_id: 148,
		part_id: 384,
		quantity: 201,
	},
	{
		garage_id: 148,
		part_id: 385,
		quantity: 422,
	},
	{
		garage_id: 148,
		part_id: 386,
		quantity: 123,
	},
	{
		garage_id: 148,
		part_id: 387,
		quantity: 421,
	},
	{
		garage_id: 148,
		part_id: 388,
		quantity: 67,
	},
	{
		garage_id: 148,
		part_id: 389,
		quantity: 111,
	},
	{
		garage_id: 148,
		part_id: 390,
		quantity: 393,
	},
	{
		garage_id: 148,
		part_id: 391,
		quantity: 474,
	},
	{
		garage_id: 148,
		part_id: 392,
		quantity: 94,
	},
	{
		garage_id: 148,
		part_id: 393,
		quantity: 132,
	},
	{
		garage_id: 148,
		part_id: 394,
		quantity: 248,
	},
	{
		garage_id: 148,
		part_id: 395,
		quantity: 402,
	},
	{
		garage_id: 148,
		part_id: 396,
		quantity: 192,
	},
	{
		garage_id: 148,
		part_id: 397,
		quantity: 145,
	},
	{
		garage_id: 148,
		part_id: 398,
		quantity: 277,
	},
	{
		garage_id: 148,
		part_id: 399,
		quantity: 329,
	},
	{
		garage_id: 149,
		part_id: 0,
		quantity: 311,
	},
	{
		garage_id: 149,
		part_id: 1,
		quantity: 373,
	},
	{
		garage_id: 149,
		part_id: 2,
		quantity: 391,
	},
	{
		garage_id: 149,
		part_id: 3,
		quantity: 135,
	},
	{
		garage_id: 149,
		part_id: 4,
		quantity: 170,
	},
	{
		garage_id: 149,
		part_id: 5,
		quantity: 171,
	},
	{
		garage_id: 149,
		part_id: 6,
		quantity: 263,
	},
	{
		garage_id: 149,
		part_id: 7,
		quantity: 47,
	},
	{
		garage_id: 149,
		part_id: 8,
		quantity: 233,
	},
	{
		garage_id: 149,
		part_id: 9,
		quantity: 348,
	},
	{
		garage_id: 149,
		part_id: 10,
		quantity: 281,
	},
	{
		garage_id: 149,
		part_id: 11,
		quantity: 310,
	},
	{
		garage_id: 149,
		part_id: 12,
		quantity: 219,
	},
	{
		garage_id: 149,
		part_id: 13,
		quantity: 435,
	},
	{
		garage_id: 149,
		part_id: 14,
		quantity: 104,
	},
	{
		garage_id: 149,
		part_id: 15,
		quantity: 485,
	},
	{
		garage_id: 149,
		part_id: 16,
		quantity: 314,
	},
	{
		garage_id: 149,
		part_id: 17,
		quantity: 400,
	},
	{
		garage_id: 149,
		part_id: 18,
		quantity: 443,
	},
	{
		garage_id: 149,
		part_id: 19,
		quantity: 489,
	},
	{
		garage_id: 149,
		part_id: 20,
		quantity: 412,
	},
	{
		garage_id: 149,
		part_id: 21,
		quantity: 255,
	},
	{
		garage_id: 149,
		part_id: 22,
		quantity: 197,
	},
	{
		garage_id: 149,
		part_id: 23,
		quantity: 121,
	},
	{
		garage_id: 149,
		part_id: 24,
		quantity: 76,
	},
	{
		garage_id: 149,
		part_id: 25,
		quantity: 268,
	},
	{
		garage_id: 149,
		part_id: 26,
		quantity: 413,
	},
	{
		garage_id: 149,
		part_id: 27,
		quantity: 229,
	},
	{
		garage_id: 149,
		part_id: 28,
		quantity: 177,
	},
	{
		garage_id: 149,
		part_id: 29,
		quantity: 69,
	},
	{
		garage_id: 149,
		part_id: 30,
		quantity: 110,
	},
	{
		garage_id: 149,
		part_id: 31,
		quantity: 412,
	},
	{
		garage_id: 149,
		part_id: 32,
		quantity: 283,
	},
	{
		garage_id: 149,
		part_id: 33,
		quantity: 416,
	},
	{
		garage_id: 149,
		part_id: 34,
		quantity: 15,
	},
	{
		garage_id: 149,
		part_id: 35,
		quantity: 39,
	},
	{
		garage_id: 149,
		part_id: 36,
		quantity: 310,
	},
	{
		garage_id: 149,
		part_id: 37,
		quantity: 327,
	},
	{
		garage_id: 149,
		part_id: 38,
		quantity: 218,
	},
	{
		garage_id: 149,
		part_id: 39,
		quantity: 171,
	},
	{
		garage_id: 149,
		part_id: 40,
		quantity: 215,
	},
	{
		garage_id: 149,
		part_id: 41,
		quantity: 361,
	},
	{
		garage_id: 149,
		part_id: 42,
		quantity: 51,
	},
	{
		garage_id: 149,
		part_id: 43,
		quantity: 444,
	},
	{
		garage_id: 149,
		part_id: 44,
		quantity: 412,
	},
	{
		garage_id: 149,
		part_id: 45,
		quantity: 33,
	},
	{
		garage_id: 149,
		part_id: 46,
		quantity: 181,
	},
	{
		garage_id: 149,
		part_id: 47,
		quantity: 443,
	},
	{
		garage_id: 149,
		part_id: 48,
		quantity: 189,
	},
	{
		garage_id: 149,
		part_id: 49,
		quantity: 224,
	},
	{
		garage_id: 149,
		part_id: 50,
		quantity: 424,
	},
	{
		garage_id: 149,
		part_id: 51,
		quantity: 10,
	},
	{
		garage_id: 149,
		part_id: 52,
		quantity: 65,
	},
	{
		garage_id: 149,
		part_id: 53,
		quantity: 34,
	},
	{
		garage_id: 149,
		part_id: 54,
		quantity: 496,
	},
	{
		garage_id: 149,
		part_id: 55,
		quantity: 147,
	},
	{
		garage_id: 149,
		part_id: 56,
		quantity: 40,
	},
	{
		garage_id: 149,
		part_id: 57,
		quantity: 206,
	},
	{
		garage_id: 149,
		part_id: 58,
		quantity: 362,
	},
	{
		garage_id: 149,
		part_id: 59,
		quantity: 206,
	},
	{
		garage_id: 149,
		part_id: 60,
		quantity: 65,
	},
	{
		garage_id: 149,
		part_id: 61,
		quantity: 376,
	},
	{
		garage_id: 149,
		part_id: 62,
		quantity: 179,
	},
	{
		garage_id: 149,
		part_id: 63,
		quantity: 351,
	},
	{
		garage_id: 149,
		part_id: 64,
		quantity: 287,
	},
	{
		garage_id: 149,
		part_id: 65,
		quantity: 173,
	},
	{
		garage_id: 149,
		part_id: 66,
		quantity: 109,
	},
	{
		garage_id: 149,
		part_id: 67,
		quantity: 309,
	},
	{
		garage_id: 149,
		part_id: 68,
		quantity: 277,
	},
	{
		garage_id: 149,
		part_id: 69,
		quantity: 363,
	},
	{
		garage_id: 149,
		part_id: 70,
		quantity: 226,
	},
	{
		garage_id: 149,
		part_id: 71,
		quantity: 314,
	},
	{
		garage_id: 149,
		part_id: 72,
		quantity: 146,
	},
	{
		garage_id: 149,
		part_id: 73,
		quantity: 169,
	},
	{
		garage_id: 149,
		part_id: 74,
		quantity: 192,
	},
	{
		garage_id: 149,
		part_id: 75,
		quantity: 270,
	},
	{
		garage_id: 149,
		part_id: 76,
		quantity: 227,
	},
	{
		garage_id: 149,
		part_id: 77,
		quantity: 349,
	},
	{
		garage_id: 149,
		part_id: 78,
		quantity: 360,
	},
	{
		garage_id: 149,
		part_id: 79,
		quantity: 268,
	},
	{
		garage_id: 149,
		part_id: 80,
		quantity: 356,
	},
	{
		garage_id: 149,
		part_id: 81,
		quantity: 2,
	},
	{
		garage_id: 149,
		part_id: 82,
		quantity: 379,
	},
	{
		garage_id: 149,
		part_id: 83,
		quantity: 227,
	},
	{
		garage_id: 149,
		part_id: 84,
		quantity: 237,
	},
	{
		garage_id: 149,
		part_id: 85,
		quantity: 187,
	},
	{
		garage_id: 149,
		part_id: 86,
		quantity: 118,
	},
	{
		garage_id: 149,
		part_id: 87,
		quantity: 108,
	},
	{
		garage_id: 149,
		part_id: 88,
		quantity: 72,
	},
	{
		garage_id: 149,
		part_id: 89,
		quantity: 483,
	},
	{
		garage_id: 149,
		part_id: 90,
		quantity: 462,
	},
	{
		garage_id: 149,
		part_id: 91,
		quantity: 37,
	},
	{
		garage_id: 149,
		part_id: 92,
		quantity: 51,
	},
	{
		garage_id: 149,
		part_id: 93,
		quantity: 173,
	},
	{
		garage_id: 149,
		part_id: 94,
		quantity: 235,
	},
	{
		garage_id: 149,
		part_id: 95,
		quantity: 47,
	},
	{
		garage_id: 149,
		part_id: 96,
		quantity: 429,
	},
	{
		garage_id: 149,
		part_id: 97,
		quantity: 415,
	},
	{
		garage_id: 149,
		part_id: 98,
		quantity: 159,
	},
	{
		garage_id: 149,
		part_id: 99,
		quantity: 141,
	},
	{
		garage_id: 149,
		part_id: 100,
		quantity: 364,
	},
	{
		garage_id: 149,
		part_id: 101,
		quantity: 26,
	},
	{
		garage_id: 149,
		part_id: 102,
		quantity: 417,
	},
	{
		garage_id: 149,
		part_id: 103,
		quantity: 100,
	},
	{
		garage_id: 149,
		part_id: 104,
		quantity: 420,
	},
	{
		garage_id: 149,
		part_id: 105,
		quantity: 107,
	},
	{
		garage_id: 149,
		part_id: 106,
		quantity: 20,
	},
	{
		garage_id: 149,
		part_id: 107,
		quantity: 277,
	},
	{
		garage_id: 149,
		part_id: 108,
		quantity: 107,
	},
	{
		garage_id: 149,
		part_id: 109,
		quantity: 312,
	},
	{
		garage_id: 149,
		part_id: 110,
		quantity: 71,
	},
	{
		garage_id: 149,
		part_id: 111,
		quantity: 57,
	},
	{
		garage_id: 149,
		part_id: 112,
		quantity: 454,
	},
	{
		garage_id: 149,
		part_id: 113,
		quantity: 99,
	},
	{
		garage_id: 149,
		part_id: 114,
		quantity: 167,
	},
	{
		garage_id: 149,
		part_id: 115,
		quantity: 294,
	},
	{
		garage_id: 149,
		part_id: 116,
		quantity: 406,
	},
	{
		garage_id: 149,
		part_id: 117,
		quantity: 66,
	},
	{
		garage_id: 149,
		part_id: 118,
		quantity: 454,
	},
	{
		garage_id: 149,
		part_id: 119,
		quantity: 257,
	},
	{
		garage_id: 149,
		part_id: 120,
		quantity: 254,
	},
	{
		garage_id: 149,
		part_id: 121,
		quantity: 45,
	},
	{
		garage_id: 149,
		part_id: 122,
		quantity: 388,
	},
	{
		garage_id: 149,
		part_id: 123,
		quantity: 183,
	},
	{
		garage_id: 149,
		part_id: 124,
		quantity: 199,
	},
	{
		garage_id: 149,
		part_id: 125,
		quantity: 212,
	},
	{
		garage_id: 149,
		part_id: 126,
		quantity: 123,
	},
	{
		garage_id: 149,
		part_id: 127,
		quantity: 443,
	},
	{
		garage_id: 149,
		part_id: 128,
		quantity: 35,
	},
	{
		garage_id: 149,
		part_id: 129,
		quantity: 433,
	},
	{
		garage_id: 149,
		part_id: 130,
		quantity: 330,
	},
	{
		garage_id: 149,
		part_id: 131,
		quantity: 51,
	},
	{
		garage_id: 149,
		part_id: 132,
		quantity: 499,
	},
	{
		garage_id: 149,
		part_id: 133,
		quantity: 453,
	},
	{
		garage_id: 149,
		part_id: 134,
		quantity: 196,
	},
	{
		garage_id: 149,
		part_id: 135,
		quantity: 13,
	},
	{
		garage_id: 149,
		part_id: 136,
		quantity: 283,
	},
	{
		garage_id: 149,
		part_id: 137,
		quantity: 481,
	},
	{
		garage_id: 149,
		part_id: 138,
		quantity: 494,
	},
	{
		garage_id: 149,
		part_id: 139,
		quantity: 292,
	},
	{
		garage_id: 149,
		part_id: 140,
		quantity: 254,
	},
	{
		garage_id: 149,
		part_id: 141,
		quantity: 322,
	},
	{
		garage_id: 149,
		part_id: 142,
		quantity: 19,
	},
	{
		garage_id: 149,
		part_id: 143,
		quantity: 316,
	},
	{
		garage_id: 149,
		part_id: 144,
		quantity: 167,
	},
	{
		garage_id: 149,
		part_id: 145,
		quantity: 233,
	},
	{
		garage_id: 149,
		part_id: 146,
		quantity: 52,
	},
	{
		garage_id: 149,
		part_id: 147,
		quantity: 421,
	},
	{
		garage_id: 149,
		part_id: 148,
		quantity: 317,
	},
	{
		garage_id: 149,
		part_id: 149,
		quantity: 92,
	},
	{
		garage_id: 149,
		part_id: 150,
		quantity: 310,
	},
	{
		garage_id: 149,
		part_id: 151,
		quantity: 450,
	},
	{
		garage_id: 149,
		part_id: 152,
		quantity: 160,
	},
	{
		garage_id: 149,
		part_id: 153,
		quantity: 243,
	},
	{
		garage_id: 149,
		part_id: 154,
		quantity: 490,
	},
	{
		garage_id: 149,
		part_id: 155,
		quantity: 130,
	},
	{
		garage_id: 149,
		part_id: 156,
		quantity: 415,
	},
	{
		garage_id: 149,
		part_id: 157,
		quantity: 426,
	},
	{
		garage_id: 149,
		part_id: 158,
		quantity: 414,
	},
	{
		garage_id: 149,
		part_id: 159,
		quantity: 242,
	},
	{
		garage_id: 149,
		part_id: 160,
		quantity: 373,
	},
	{
		garage_id: 149,
		part_id: 161,
		quantity: 19,
	},
	{
		garage_id: 149,
		part_id: 162,
		quantity: 265,
	},
	{
		garage_id: 149,
		part_id: 163,
		quantity: 15,
	},
	{
		garage_id: 149,
		part_id: 164,
		quantity: 7,
	},
	{
		garage_id: 149,
		part_id: 165,
		quantity: 375,
	},
	{
		garage_id: 149,
		part_id: 166,
		quantity: 169,
	},
	{
		garage_id: 149,
		part_id: 167,
		quantity: 41,
	},
	{
		garage_id: 149,
		part_id: 168,
		quantity: 156,
	},
	{
		garage_id: 149,
		part_id: 169,
		quantity: 204,
	},
	{
		garage_id: 149,
		part_id: 170,
		quantity: 132,
	},
	{
		garage_id: 149,
		part_id: 171,
		quantity: 65,
	},
	{
		garage_id: 149,
		part_id: 172,
		quantity: 489,
	},
	{
		garage_id: 149,
		part_id: 173,
		quantity: 466,
	},
	{
		garage_id: 149,
		part_id: 174,
		quantity: 128,
	},
	{
		garage_id: 149,
		part_id: 175,
		quantity: 6,
	},
	{
		garage_id: 149,
		part_id: 176,
		quantity: 207,
	},
	{
		garage_id: 149,
		part_id: 177,
		quantity: 4,
	},
	{
		garage_id: 149,
		part_id: 178,
		quantity: 27,
	},
	{
		garage_id: 149,
		part_id: 179,
		quantity: 403,
	},
	{
		garage_id: 149,
		part_id: 180,
		quantity: 179,
	},
	{
		garage_id: 149,
		part_id: 181,
		quantity: 280,
	},
	{
		garage_id: 149,
		part_id: 182,
		quantity: 392,
	},
	{
		garage_id: 149,
		part_id: 183,
		quantity: 16,
	},
	{
		garage_id: 149,
		part_id: 184,
		quantity: 36,
	},
	{
		garage_id: 149,
		part_id: 185,
		quantity: 5,
	},
	{
		garage_id: 149,
		part_id: 186,
		quantity: 478,
	},
	{
		garage_id: 149,
		part_id: 187,
		quantity: 26,
	},
	{
		garage_id: 149,
		part_id: 188,
		quantity: 324,
	},
	{
		garage_id: 149,
		part_id: 189,
		quantity: 10,
	},
	{
		garage_id: 149,
		part_id: 190,
		quantity: 391,
	},
	{
		garage_id: 149,
		part_id: 191,
		quantity: 473,
	},
	{
		garage_id: 149,
		part_id: 192,
		quantity: 32,
	},
	{
		garage_id: 149,
		part_id: 193,
		quantity: 168,
	},
	{
		garage_id: 149,
		part_id: 194,
		quantity: 126,
	},
	{
		garage_id: 149,
		part_id: 195,
		quantity: 51,
	},
	{
		garage_id: 149,
		part_id: 196,
		quantity: 409,
	},
	{
		garage_id: 149,
		part_id: 197,
		quantity: 471,
	},
	{
		garage_id: 149,
		part_id: 198,
		quantity: 152,
	},
	{
		garage_id: 149,
		part_id: 199,
		quantity: 379,
	},
	{
		garage_id: 149,
		part_id: 200,
		quantity: 285,
	},
	{
		garage_id: 149,
		part_id: 201,
		quantity: 341,
	},
	{
		garage_id: 149,
		part_id: 202,
		quantity: 488,
	},
	{
		garage_id: 149,
		part_id: 203,
		quantity: 480,
	},
	{
		garage_id: 149,
		part_id: 204,
		quantity: 366,
	},
	{
		garage_id: 149,
		part_id: 205,
		quantity: 400,
	},
	{
		garage_id: 149,
		part_id: 206,
		quantity: 119,
	},
	{
		garage_id: 149,
		part_id: 207,
		quantity: 133,
	},
	{
		garage_id: 149,
		part_id: 208,
		quantity: 47,
	},
	{
		garage_id: 149,
		part_id: 209,
		quantity: 20,
	},
	{
		garage_id: 149,
		part_id: 210,
		quantity: 110,
	},
	{
		garage_id: 149,
		part_id: 211,
		quantity: 453,
	},
	{
		garage_id: 149,
		part_id: 212,
		quantity: 467,
	},
	{
		garage_id: 149,
		part_id: 213,
		quantity: 289,
	},
	{
		garage_id: 149,
		part_id: 214,
		quantity: 72,
	},
	{
		garage_id: 149,
		part_id: 215,
		quantity: 479,
	},
	{
		garage_id: 149,
		part_id: 216,
		quantity: 262,
	},
	{
		garage_id: 149,
		part_id: 217,
		quantity: 76,
	},
	{
		garage_id: 149,
		part_id: 218,
		quantity: 488,
	},
	{
		garage_id: 149,
		part_id: 219,
		quantity: 457,
	},
	{
		garage_id: 149,
		part_id: 220,
		quantity: 294,
	},
	{
		garage_id: 149,
		part_id: 221,
		quantity: 81,
	},
	{
		garage_id: 149,
		part_id: 222,
		quantity: 310,
	},
	{
		garage_id: 149,
		part_id: 223,
		quantity: 50,
	},
	{
		garage_id: 149,
		part_id: 224,
		quantity: 222,
	},
	{
		garage_id: 149,
		part_id: 225,
		quantity: 356,
	},
	{
		garage_id: 149,
		part_id: 226,
		quantity: 291,
	},
	{
		garage_id: 149,
		part_id: 227,
		quantity: 394,
	},
	{
		garage_id: 149,
		part_id: 228,
		quantity: 116,
	},
	{
		garage_id: 149,
		part_id: 229,
		quantity: 333,
	},
	{
		garage_id: 149,
		part_id: 230,
		quantity: 185,
	},
	{
		garage_id: 149,
		part_id: 231,
		quantity: 481,
	},
	{
		garage_id: 149,
		part_id: 232,
		quantity: 351,
	},
	{
		garage_id: 149,
		part_id: 233,
		quantity: 248,
	},
	{
		garage_id: 149,
		part_id: 234,
		quantity: 373,
	},
	{
		garage_id: 149,
		part_id: 235,
		quantity: 177,
	},
	{
		garage_id: 149,
		part_id: 236,
		quantity: 349,
	},
	{
		garage_id: 149,
		part_id: 237,
		quantity: 372,
	},
	{
		garage_id: 149,
		part_id: 238,
		quantity: 243,
	},
	{
		garage_id: 149,
		part_id: 239,
		quantity: 386,
	},
	{
		garage_id: 149,
		part_id: 240,
		quantity: 207,
	},
	{
		garage_id: 149,
		part_id: 241,
		quantity: 12,
	},
	{
		garage_id: 149,
		part_id: 242,
		quantity: 372,
	},
	{
		garage_id: 149,
		part_id: 243,
		quantity: 93,
	},
	{
		garage_id: 149,
		part_id: 244,
		quantity: 475,
	},
	{
		garage_id: 149,
		part_id: 245,
		quantity: 138,
	},
	{
		garage_id: 149,
		part_id: 246,
		quantity: 94,
	},
	{
		garage_id: 149,
		part_id: 247,
		quantity: 263,
	},
	{
		garage_id: 149,
		part_id: 248,
		quantity: 446,
	},
	{
		garage_id: 149,
		part_id: 249,
		quantity: 422,
	},
	{
		garage_id: 149,
		part_id: 250,
		quantity: 91,
	},
	{
		garage_id: 149,
		part_id: 251,
		quantity: 117,
	},
	{
		garage_id: 149,
		part_id: 252,
		quantity: 16,
	},
	{
		garage_id: 149,
		part_id: 253,
		quantity: 109,
	},
	{
		garage_id: 149,
		part_id: 254,
		quantity: 70,
	},
	{
		garage_id: 149,
		part_id: 255,
		quantity: 150,
	},
	{
		garage_id: 149,
		part_id: 256,
		quantity: 349,
	},
	{
		garage_id: 149,
		part_id: 257,
		quantity: 336,
	},
	{
		garage_id: 149,
		part_id: 258,
		quantity: 247,
	},
	{
		garage_id: 149,
		part_id: 259,
		quantity: 198,
	},
	{
		garage_id: 149,
		part_id: 260,
		quantity: 152,
	},
	{
		garage_id: 149,
		part_id: 261,
		quantity: 393,
	},
	{
		garage_id: 149,
		part_id: 262,
		quantity: 389,
	},
	{
		garage_id: 149,
		part_id: 263,
		quantity: 67,
	},
	{
		garage_id: 149,
		part_id: 264,
		quantity: 83,
	},
	{
		garage_id: 149,
		part_id: 265,
		quantity: 193,
	},
	{
		garage_id: 149,
		part_id: 266,
		quantity: 461,
	},
	{
		garage_id: 149,
		part_id: 267,
		quantity: 496,
	},
	{
		garage_id: 149,
		part_id: 268,
		quantity: 106,
	},
	{
		garage_id: 149,
		part_id: 269,
		quantity: 482,
	},
	{
		garage_id: 149,
		part_id: 270,
		quantity: 125,
	},
	{
		garage_id: 149,
		part_id: 271,
		quantity: 288,
	},
	{
		garage_id: 149,
		part_id: 272,
		quantity: 119,
	},
	{
		garage_id: 149,
		part_id: 273,
		quantity: 52,
	},
	{
		garage_id: 149,
		part_id: 274,
		quantity: 88,
	},
	{
		garage_id: 149,
		part_id: 275,
		quantity: 453,
	},
	{
		garage_id: 149,
		part_id: 276,
		quantity: 26,
	},
	{
		garage_id: 149,
		part_id: 277,
		quantity: 286,
	},
	{
		garage_id: 149,
		part_id: 278,
		quantity: 304,
	},
	{
		garage_id: 149,
		part_id: 279,
		quantity: 244,
	},
	{
		garage_id: 149,
		part_id: 280,
		quantity: 109,
	},
	{
		garage_id: 149,
		part_id: 281,
		quantity: 450,
	},
	{
		garage_id: 149,
		part_id: 282,
		quantity: 110,
	},
	{
		garage_id: 149,
		part_id: 283,
		quantity: 228,
	},
	{
		garage_id: 149,
		part_id: 284,
		quantity: 411,
	},
	{
		garage_id: 149,
		part_id: 285,
		quantity: 238,
	},
	{
		garage_id: 149,
		part_id: 286,
		quantity: 378,
	},
	{
		garage_id: 149,
		part_id: 287,
		quantity: 428,
	},
	{
		garage_id: 149,
		part_id: 288,
		quantity: 278,
	},
	{
		garage_id: 149,
		part_id: 289,
		quantity: 28,
	},
	{
		garage_id: 149,
		part_id: 290,
		quantity: 327,
	},
	{
		garage_id: 149,
		part_id: 291,
		quantity: 164,
	},
	{
		garage_id: 149,
		part_id: 292,
		quantity: 43,
	},
	{
		garage_id: 149,
		part_id: 293,
		quantity: 498,
	},
	{
		garage_id: 149,
		part_id: 294,
		quantity: 415,
	},
	{
		garage_id: 149,
		part_id: 295,
		quantity: 235,
	},
	{
		garage_id: 149,
		part_id: 296,
		quantity: 66,
	},
	{
		garage_id: 149,
		part_id: 297,
		quantity: 464,
	},
	{
		garage_id: 149,
		part_id: 298,
		quantity: 174,
	},
	{
		garage_id: 149,
		part_id: 299,
		quantity: 351,
	},
	{
		garage_id: 149,
		part_id: 300,
		quantity: 366,
	},
	{
		garage_id: 149,
		part_id: 301,
		quantity: 488,
	},
	{
		garage_id: 149,
		part_id: 302,
		quantity: 423,
	},
	{
		garage_id: 149,
		part_id: 303,
		quantity: 450,
	},
	{
		garage_id: 149,
		part_id: 304,
		quantity: 204,
	},
	{
		garage_id: 149,
		part_id: 305,
		quantity: 425,
	},
	{
		garage_id: 149,
		part_id: 306,
		quantity: 366,
	},
	{
		garage_id: 149,
		part_id: 307,
		quantity: 364,
	},
	{
		garage_id: 149,
		part_id: 308,
		quantity: 483,
	},
	{
		garage_id: 149,
		part_id: 309,
		quantity: 499,
	},
	{
		garage_id: 149,
		part_id: 310,
		quantity: 2,
	},
	{
		garage_id: 149,
		part_id: 311,
		quantity: 161,
	},
	{
		garage_id: 149,
		part_id: 312,
		quantity: 49,
	},
	{
		garage_id: 149,
		part_id: 313,
		quantity: 297,
	},
	{
		garage_id: 149,
		part_id: 314,
		quantity: 187,
	},
	{
		garage_id: 149,
		part_id: 315,
		quantity: 225,
	},
	{
		garage_id: 149,
		part_id: 316,
		quantity: 192,
	},
	{
		garage_id: 149,
		part_id: 317,
		quantity: 332,
	},
	{
		garage_id: 149,
		part_id: 318,
		quantity: 40,
	},
	{
		garage_id: 149,
		part_id: 319,
		quantity: 356,
	},
	{
		garage_id: 149,
		part_id: 320,
		quantity: 473,
	},
	{
		garage_id: 149,
		part_id: 321,
		quantity: 43,
	},
	{
		garage_id: 149,
		part_id: 322,
		quantity: 74,
	},
	{
		garage_id: 149,
		part_id: 323,
		quantity: 306,
	},
	{
		garage_id: 149,
		part_id: 324,
		quantity: 99,
	},
	{
		garage_id: 149,
		part_id: 325,
		quantity: 437,
	},
	{
		garage_id: 149,
		part_id: 326,
		quantity: 74,
	},
	{
		garage_id: 149,
		part_id: 327,
		quantity: 311,
	},
	{
		garage_id: 149,
		part_id: 328,
		quantity: 187,
	},
	{
		garage_id: 149,
		part_id: 329,
		quantity: 469,
	},
	{
		garage_id: 149,
		part_id: 330,
		quantity: 172,
	},
	{
		garage_id: 149,
		part_id: 331,
		quantity: 329,
	},
	{
		garage_id: 149,
		part_id: 332,
		quantity: 294,
	},
	{
		garage_id: 149,
		part_id: 333,
		quantity: 249,
	},
	{
		garage_id: 149,
		part_id: 334,
		quantity: 490,
	},
	{
		garage_id: 149,
		part_id: 335,
		quantity: 83,
	},
	{
		garage_id: 149,
		part_id: 336,
		quantity: 288,
	},
	{
		garage_id: 149,
		part_id: 337,
		quantity: 37,
	},
	{
		garage_id: 149,
		part_id: 338,
		quantity: 244,
	},
	{
		garage_id: 149,
		part_id: 339,
		quantity: 18,
	},
	{
		garage_id: 149,
		part_id: 340,
		quantity: 483,
	},
	{
		garage_id: 149,
		part_id: 341,
		quantity: 481,
	},
	{
		garage_id: 149,
		part_id: 342,
		quantity: 112,
	},
	{
		garage_id: 149,
		part_id: 343,
		quantity: 324,
	},
	{
		garage_id: 149,
		part_id: 344,
		quantity: 184,
	},
	{
		garage_id: 149,
		part_id: 345,
		quantity: 109,
	},
	{
		garage_id: 149,
		part_id: 346,
		quantity: 317,
	},
	{
		garage_id: 149,
		part_id: 347,
		quantity: 256,
	},
	{
		garage_id: 149,
		part_id: 348,
		quantity: 57,
	},
	{
		garage_id: 149,
		part_id: 349,
		quantity: 365,
	},
	{
		garage_id: 149,
		part_id: 350,
		quantity: 162,
	},
	{
		garage_id: 149,
		part_id: 351,
		quantity: 359,
	},
	{
		garage_id: 149,
		part_id: 352,
		quantity: 40,
	},
	{
		garage_id: 149,
		part_id: 353,
		quantity: 357,
	},
	{
		garage_id: 149,
		part_id: 354,
		quantity: 410,
	},
	{
		garage_id: 149,
		part_id: 355,
		quantity: 199,
	},
	{
		garage_id: 149,
		part_id: 356,
		quantity: 282,
	},
	{
		garage_id: 149,
		part_id: 357,
		quantity: 289,
	},
	{
		garage_id: 149,
		part_id: 358,
		quantity: 361,
	},
	{
		garage_id: 149,
		part_id: 359,
		quantity: 302,
	},
	{
		garage_id: 149,
		part_id: 360,
		quantity: 409,
	},
	{
		garage_id: 149,
		part_id: 361,
		quantity: 465,
	},
	{
		garage_id: 149,
		part_id: 362,
		quantity: 247,
	},
	{
		garage_id: 149,
		part_id: 363,
		quantity: 396,
	},
	{
		garage_id: 149,
		part_id: 364,
		quantity: 216,
	},
	{
		garage_id: 149,
		part_id: 365,
		quantity: 427,
	},
	{
		garage_id: 149,
		part_id: 366,
		quantity: 203,
	},
	{
		garage_id: 149,
		part_id: 367,
		quantity: 432,
	},
	{
		garage_id: 149,
		part_id: 368,
		quantity: 353,
	},
	{
		garage_id: 149,
		part_id: 369,
		quantity: 490,
	},
	{
		garage_id: 149,
		part_id: 370,
		quantity: 435,
	},
	{
		garage_id: 149,
		part_id: 371,
		quantity: 272,
	},
	{
		garage_id: 149,
		part_id: 372,
		quantity: 372,
	},
	{
		garage_id: 149,
		part_id: 373,
		quantity: 70,
	},
	{
		garage_id: 149,
		part_id: 374,
		quantity: 156,
	},
	{
		garage_id: 149,
		part_id: 375,
		quantity: 346,
	},
	{
		garage_id: 149,
		part_id: 376,
		quantity: 303,
	},
	{
		garage_id: 149,
		part_id: 377,
		quantity: 257,
	},
	{
		garage_id: 149,
		part_id: 378,
		quantity: 468,
	},
	{
		garage_id: 149,
		part_id: 379,
		quantity: 163,
	},
	{
		garage_id: 149,
		part_id: 380,
		quantity: 1,
	},
	{
		garage_id: 149,
		part_id: 381,
		quantity: 447,
	},
	{
		garage_id: 149,
		part_id: 382,
		quantity: 125,
	},
	{
		garage_id: 149,
		part_id: 383,
		quantity: 104,
	},
	{
		garage_id: 149,
		part_id: 384,
		quantity: 240,
	},
	{
		garage_id: 149,
		part_id: 385,
		quantity: 53,
	},
	{
		garage_id: 149,
		part_id: 386,
		quantity: 156,
	},
	{
		garage_id: 149,
		part_id: 387,
		quantity: 493,
	},
	{
		garage_id: 149,
		part_id: 388,
		quantity: 137,
	},
	{
		garage_id: 149,
		part_id: 389,
		quantity: 236,
	},
	{
		garage_id: 149,
		part_id: 390,
		quantity: 376,
	},
	{
		garage_id: 149,
		part_id: 391,
		quantity: 49,
	},
	{
		garage_id: 149,
		part_id: 392,
		quantity: 109,
	},
	{
		garage_id: 149,
		part_id: 393,
		quantity: 51,
	},
	{
		garage_id: 149,
		part_id: 394,
		quantity: 206,
	},
	{
		garage_id: 149,
		part_id: 395,
		quantity: 282,
	},
	{
		garage_id: 149,
		part_id: 396,
		quantity: 447,
	},
	{
		garage_id: 149,
		part_id: 397,
		quantity: 236,
	},
	{
		garage_id: 149,
		part_id: 398,
		quantity: 182,
	},
	{
		garage_id: 149,
		part_id: 399,
		quantity: 426,
	},
	{
		garage_id: 150,
		part_id: 0,
		quantity: 81,
	},
	{
		garage_id: 150,
		part_id: 1,
		quantity: 450,
	},
	{
		garage_id: 150,
		part_id: 2,
		quantity: 262,
	},
	{
		garage_id: 150,
		part_id: 3,
		quantity: 200,
	},
	{
		garage_id: 150,
		part_id: 4,
		quantity: 387,
	},
	{
		garage_id: 150,
		part_id: 5,
		quantity: 329,
	},
	{
		garage_id: 150,
		part_id: 6,
		quantity: 483,
	},
	{
		garage_id: 150,
		part_id: 7,
		quantity: 336,
	},
	{
		garage_id: 150,
		part_id: 8,
		quantity: 219,
	},
	{
		garage_id: 150,
		part_id: 9,
		quantity: 163,
	},
	{
		garage_id: 150,
		part_id: 10,
		quantity: 430,
	},
	{
		garage_id: 150,
		part_id: 11,
		quantity: 355,
	},
	{
		garage_id: 150,
		part_id: 12,
		quantity: 64,
	},
	{
		garage_id: 150,
		part_id: 13,
		quantity: 143,
	},
	{
		garage_id: 150,
		part_id: 14,
		quantity: 118,
	},
	{
		garage_id: 150,
		part_id: 15,
		quantity: 139,
	},
	{
		garage_id: 150,
		part_id: 16,
		quantity: 323,
	},
	{
		garage_id: 150,
		part_id: 17,
		quantity: 479,
	},
	{
		garage_id: 150,
		part_id: 18,
		quantity: 362,
	},
	{
		garage_id: 150,
		part_id: 19,
		quantity: 85,
	},
	{
		garage_id: 150,
		part_id: 20,
		quantity: 119,
	},
	{
		garage_id: 150,
		part_id: 21,
		quantity: 64,
	},
	{
		garage_id: 150,
		part_id: 22,
		quantity: 263,
	},
	{
		garage_id: 150,
		part_id: 23,
		quantity: 407,
	},
	{
		garage_id: 150,
		part_id: 24,
		quantity: 480,
	},
	{
		garage_id: 150,
		part_id: 25,
		quantity: 407,
	},
	{
		garage_id: 150,
		part_id: 26,
		quantity: 274,
	},
	{
		garage_id: 150,
		part_id: 27,
		quantity: 40,
	},
	{
		garage_id: 150,
		part_id: 28,
		quantity: 185,
	},
	{
		garage_id: 150,
		part_id: 29,
		quantity: 227,
	},
	{
		garage_id: 150,
		part_id: 30,
		quantity: 347,
	},
	{
		garage_id: 150,
		part_id: 31,
		quantity: 402,
	},
	{
		garage_id: 150,
		part_id: 32,
		quantity: 391,
	},
	{
		garage_id: 150,
		part_id: 33,
		quantity: 95,
	},
	{
		garage_id: 150,
		part_id: 34,
		quantity: 69,
	},
	{
		garage_id: 150,
		part_id: 35,
		quantity: 88,
	},
	{
		garage_id: 150,
		part_id: 36,
		quantity: 206,
	},
	{
		garage_id: 150,
		part_id: 37,
		quantity: 127,
	},
	{
		garage_id: 150,
		part_id: 38,
		quantity: 389,
	},
	{
		garage_id: 150,
		part_id: 39,
		quantity: 153,
	},
	{
		garage_id: 150,
		part_id: 40,
		quantity: 39,
	},
	{
		garage_id: 150,
		part_id: 41,
		quantity: 331,
	},
	{
		garage_id: 150,
		part_id: 42,
		quantity: 228,
	},
	{
		garage_id: 150,
		part_id: 43,
		quantity: 430,
	},
	{
		garage_id: 150,
		part_id: 44,
		quantity: 371,
	},
	{
		garage_id: 150,
		part_id: 45,
		quantity: 459,
	},
	{
		garage_id: 150,
		part_id: 46,
		quantity: 259,
	},
	{
		garage_id: 150,
		part_id: 47,
		quantity: 243,
	},
	{
		garage_id: 150,
		part_id: 48,
		quantity: 281,
	},
	{
		garage_id: 150,
		part_id: 49,
		quantity: 134,
	},
	{
		garage_id: 150,
		part_id: 50,
		quantity: 356,
	},
	{
		garage_id: 150,
		part_id: 51,
		quantity: 266,
	},
	{
		garage_id: 150,
		part_id: 52,
		quantity: 495,
	},
	{
		garage_id: 150,
		part_id: 53,
		quantity: 345,
	},
	{
		garage_id: 150,
		part_id: 54,
		quantity: 204,
	},
	{
		garage_id: 150,
		part_id: 55,
		quantity: 407,
	},
	{
		garage_id: 150,
		part_id: 56,
		quantity: 377,
	},
	{
		garage_id: 150,
		part_id: 57,
		quantity: 31,
	},
	{
		garage_id: 150,
		part_id: 58,
		quantity: 319,
	},
	{
		garage_id: 150,
		part_id: 59,
		quantity: 421,
	},
	{
		garage_id: 150,
		part_id: 60,
		quantity: 164,
	},
	{
		garage_id: 150,
		part_id: 61,
		quantity: 152,
	},
	{
		garage_id: 150,
		part_id: 62,
		quantity: 142,
	},
	{
		garage_id: 150,
		part_id: 63,
		quantity: 158,
	},
	{
		garage_id: 150,
		part_id: 64,
		quantity: 302,
	},
	{
		garage_id: 150,
		part_id: 65,
		quantity: 238,
	},
	{
		garage_id: 150,
		part_id: 66,
		quantity: 188,
	},
	{
		garage_id: 150,
		part_id: 67,
		quantity: 189,
	},
	{
		garage_id: 150,
		part_id: 68,
		quantity: 174,
	},
	{
		garage_id: 150,
		part_id: 69,
		quantity: 435,
	},
	{
		garage_id: 150,
		part_id: 70,
		quantity: 106,
	},
	{
		garage_id: 150,
		part_id: 71,
		quantity: 481,
	},
	{
		garage_id: 150,
		part_id: 72,
		quantity: 14,
	},
	{
		garage_id: 150,
		part_id: 73,
		quantity: 447,
	},
	{
		garage_id: 150,
		part_id: 74,
		quantity: 487,
	},
	{
		garage_id: 150,
		part_id: 75,
		quantity: 227,
	},
	{
		garage_id: 150,
		part_id: 76,
		quantity: 160,
	},
	{
		garage_id: 150,
		part_id: 77,
		quantity: 301,
	},
	{
		garage_id: 150,
		part_id: 78,
		quantity: 411,
	},
	{
		garage_id: 150,
		part_id: 79,
		quantity: 85,
	},
	{
		garage_id: 150,
		part_id: 80,
		quantity: 398,
	},
	{
		garage_id: 150,
		part_id: 81,
		quantity: 238,
	},
	{
		garage_id: 150,
		part_id: 82,
		quantity: 402,
	},
	{
		garage_id: 150,
		part_id: 83,
		quantity: 227,
	},
	{
		garage_id: 150,
		part_id: 84,
		quantity: 339,
	},
	{
		garage_id: 150,
		part_id: 85,
		quantity: 413,
	},
	{
		garage_id: 150,
		part_id: 86,
		quantity: 168,
	},
	{
		garage_id: 150,
		part_id: 87,
		quantity: 284,
	},
	{
		garage_id: 150,
		part_id: 88,
		quantity: 445,
	},
	{
		garage_id: 150,
		part_id: 89,
		quantity: 4,
	},
	{
		garage_id: 150,
		part_id: 90,
		quantity: 313,
	},
	{
		garage_id: 150,
		part_id: 91,
		quantity: 39,
	},
	{
		garage_id: 150,
		part_id: 92,
		quantity: 269,
	},
	{
		garage_id: 150,
		part_id: 93,
		quantity: 342,
	},
	{
		garage_id: 150,
		part_id: 94,
		quantity: 459,
	},
	{
		garage_id: 150,
		part_id: 95,
		quantity: 33,
	},
	{
		garage_id: 150,
		part_id: 96,
		quantity: 292,
	},
	{
		garage_id: 150,
		part_id: 97,
		quantity: 53,
	},
	{
		garage_id: 150,
		part_id: 98,
		quantity: 190,
	},
	{
		garage_id: 150,
		part_id: 99,
		quantity: 285,
	},
	{
		garage_id: 150,
		part_id: 100,
		quantity: 485,
	},
	{
		garage_id: 150,
		part_id: 101,
		quantity: 477,
	},
	{
		garage_id: 150,
		part_id: 102,
		quantity: 64,
	},
	{
		garage_id: 150,
		part_id: 103,
		quantity: 321,
	},
	{
		garage_id: 150,
		part_id: 104,
		quantity: 442,
	},
	{
		garage_id: 150,
		part_id: 105,
		quantity: 61,
	},
	{
		garage_id: 150,
		part_id: 106,
		quantity: 130,
	},
	{
		garage_id: 150,
		part_id: 107,
		quantity: 142,
	},
	{
		garage_id: 150,
		part_id: 108,
		quantity: 357,
	},
	{
		garage_id: 150,
		part_id: 109,
		quantity: 416,
	},
	{
		garage_id: 150,
		part_id: 110,
		quantity: 348,
	},
	{
		garage_id: 150,
		part_id: 111,
		quantity: 189,
	},
	{
		garage_id: 150,
		part_id: 112,
		quantity: 62,
	},
	{
		garage_id: 150,
		part_id: 113,
		quantity: 434,
	},
	{
		garage_id: 150,
		part_id: 114,
		quantity: 45,
	},
	{
		garage_id: 150,
		part_id: 115,
		quantity: 132,
	},
	{
		garage_id: 150,
		part_id: 116,
		quantity: 367,
	},
	{
		garage_id: 150,
		part_id: 117,
		quantity: 9,
	},
	{
		garage_id: 150,
		part_id: 118,
		quantity: 364,
	},
	{
		garage_id: 150,
		part_id: 119,
		quantity: 153,
	},
	{
		garage_id: 150,
		part_id: 120,
		quantity: 264,
	},
	{
		garage_id: 150,
		part_id: 121,
		quantity: 126,
	},
	{
		garage_id: 150,
		part_id: 122,
		quantity: 473,
	},
	{
		garage_id: 150,
		part_id: 123,
		quantity: 388,
	},
	{
		garage_id: 150,
		part_id: 124,
		quantity: 176,
	},
	{
		garage_id: 150,
		part_id: 125,
		quantity: 249,
	},
	{
		garage_id: 150,
		part_id: 126,
		quantity: 115,
	},
	{
		garage_id: 150,
		part_id: 127,
		quantity: 287,
	},
	{
		garage_id: 150,
		part_id: 128,
		quantity: 360,
	},
	{
		garage_id: 150,
		part_id: 129,
		quantity: 94,
	},
	{
		garage_id: 150,
		part_id: 130,
		quantity: 243,
	},
	{
		garage_id: 150,
		part_id: 131,
		quantity: 354,
	},
	{
		garage_id: 150,
		part_id: 132,
		quantity: 166,
	},
	{
		garage_id: 150,
		part_id: 133,
		quantity: 227,
	},
	{
		garage_id: 150,
		part_id: 134,
		quantity: 489,
	},
	{
		garage_id: 150,
		part_id: 135,
		quantity: 61,
	},
	{
		garage_id: 150,
		part_id: 136,
		quantity: 198,
	},
	{
		garage_id: 150,
		part_id: 137,
		quantity: 72,
	},
	{
		garage_id: 150,
		part_id: 138,
		quantity: 285,
	},
	{
		garage_id: 150,
		part_id: 139,
		quantity: 140,
	},
	{
		garage_id: 150,
		part_id: 140,
		quantity: 420,
	},
	{
		garage_id: 150,
		part_id: 141,
		quantity: 199,
	},
	{
		garage_id: 150,
		part_id: 142,
		quantity: 349,
	},
	{
		garage_id: 150,
		part_id: 143,
		quantity: 274,
	},
	{
		garage_id: 150,
		part_id: 144,
		quantity: 304,
	},
	{
		garage_id: 150,
		part_id: 145,
		quantity: 50,
	},
	{
		garage_id: 150,
		part_id: 146,
		quantity: 174,
	},
	{
		garage_id: 150,
		part_id: 147,
		quantity: 350,
	},
	{
		garage_id: 150,
		part_id: 148,
		quantity: 407,
	},
	{
		garage_id: 150,
		part_id: 149,
		quantity: 172,
	},
	{
		garage_id: 150,
		part_id: 150,
		quantity: 190,
	},
	{
		garage_id: 150,
		part_id: 151,
		quantity: 377,
	},
	{
		garage_id: 150,
		part_id: 152,
		quantity: 269,
	},
	{
		garage_id: 150,
		part_id: 153,
		quantity: 64,
	},
	{
		garage_id: 150,
		part_id: 154,
		quantity: 466,
	},
	{
		garage_id: 150,
		part_id: 155,
		quantity: 94,
	},
	{
		garage_id: 150,
		part_id: 156,
		quantity: 295,
	},
	{
		garage_id: 150,
		part_id: 157,
		quantity: 84,
	},
	{
		garage_id: 150,
		part_id: 158,
		quantity: 187,
	},
	{
		garage_id: 150,
		part_id: 159,
		quantity: 16,
	},
	{
		garage_id: 150,
		part_id: 160,
		quantity: 486,
	},
	{
		garage_id: 150,
		part_id: 161,
		quantity: 8,
	},
	{
		garage_id: 150,
		part_id: 162,
		quantity: 170,
	},
	{
		garage_id: 150,
		part_id: 163,
		quantity: 457,
	},
	{
		garage_id: 150,
		part_id: 164,
		quantity: 187,
	},
	{
		garage_id: 150,
		part_id: 165,
		quantity: 68,
	},
	{
		garage_id: 150,
		part_id: 166,
		quantity: 240,
	},
	{
		garage_id: 150,
		part_id: 167,
		quantity: 219,
	},
	{
		garage_id: 150,
		part_id: 168,
		quantity: 236,
	},
	{
		garage_id: 150,
		part_id: 169,
		quantity: 30,
	},
	{
		garage_id: 150,
		part_id: 170,
		quantity: 52,
	},
	{
		garage_id: 150,
		part_id: 171,
		quantity: 225,
	},
	{
		garage_id: 150,
		part_id: 172,
		quantity: 419,
	},
	{
		garage_id: 150,
		part_id: 173,
		quantity: 207,
	},
	{
		garage_id: 150,
		part_id: 174,
		quantity: 289,
	},
	{
		garage_id: 150,
		part_id: 175,
		quantity: 101,
	},
	{
		garage_id: 150,
		part_id: 176,
		quantity: 298,
	},
	{
		garage_id: 150,
		part_id: 177,
		quantity: 363,
	},
	{
		garage_id: 150,
		part_id: 178,
		quantity: 356,
	},
	{
		garage_id: 150,
		part_id: 179,
		quantity: 385,
	},
	{
		garage_id: 150,
		part_id: 180,
		quantity: 45,
	},
	{
		garage_id: 150,
		part_id: 181,
		quantity: 171,
	},
	{
		garage_id: 150,
		part_id: 182,
		quantity: 370,
	},
	{
		garage_id: 150,
		part_id: 183,
		quantity: 17,
	},
	{
		garage_id: 150,
		part_id: 184,
		quantity: 155,
	},
	{
		garage_id: 150,
		part_id: 185,
		quantity: 464,
	},
	{
		garage_id: 150,
		part_id: 186,
		quantity: 361,
	},
	{
		garage_id: 150,
		part_id: 187,
		quantity: 432,
	},
	{
		garage_id: 150,
		part_id: 188,
		quantity: 286,
	},
	{
		garage_id: 150,
		part_id: 189,
		quantity: 349,
	},
	{
		garage_id: 150,
		part_id: 190,
		quantity: 316,
	},
	{
		garage_id: 150,
		part_id: 191,
		quantity: 358,
	},
	{
		garage_id: 150,
		part_id: 192,
		quantity: 284,
	},
	{
		garage_id: 150,
		part_id: 193,
		quantity: 172,
	},
	{
		garage_id: 150,
		part_id: 194,
		quantity: 308,
	},
	{
		garage_id: 150,
		part_id: 195,
		quantity: 93,
	},
	{
		garage_id: 150,
		part_id: 196,
		quantity: 115,
	},
	{
		garage_id: 150,
		part_id: 197,
		quantity: 319,
	},
	{
		garage_id: 150,
		part_id: 198,
		quantity: 330,
	},
	{
		garage_id: 150,
		part_id: 199,
		quantity: 442,
	},
	{
		garage_id: 150,
		part_id: 200,
		quantity: 130,
	},
	{
		garage_id: 150,
		part_id: 201,
		quantity: 291,
	},
	{
		garage_id: 150,
		part_id: 202,
		quantity: 293,
	},
	{
		garage_id: 150,
		part_id: 203,
		quantity: 451,
	},
	{
		garage_id: 150,
		part_id: 204,
		quantity: 425,
	},
	{
		garage_id: 150,
		part_id: 205,
		quantity: 402,
	},
	{
		garage_id: 150,
		part_id: 206,
		quantity: 462,
	},
	{
		garage_id: 150,
		part_id: 207,
		quantity: 193,
	},
	{
		garage_id: 150,
		part_id: 208,
		quantity: 347,
	},
	{
		garage_id: 150,
		part_id: 209,
		quantity: 182,
	},
	{
		garage_id: 150,
		part_id: 210,
		quantity: 252,
	},
	{
		garage_id: 150,
		part_id: 211,
		quantity: 334,
	},
	{
		garage_id: 150,
		part_id: 212,
		quantity: 172,
	},
	{
		garage_id: 150,
		part_id: 213,
		quantity: 219,
	},
	{
		garage_id: 150,
		part_id: 214,
		quantity: 363,
	},
	{
		garage_id: 150,
		part_id: 215,
		quantity: 174,
	},
	{
		garage_id: 150,
		part_id: 216,
		quantity: 237,
	},
	{
		garage_id: 150,
		part_id: 217,
		quantity: 13,
	},
	{
		garage_id: 150,
		part_id: 218,
		quantity: 218,
	},
	{
		garage_id: 150,
		part_id: 219,
		quantity: 432,
	},
	{
		garage_id: 150,
		part_id: 220,
		quantity: 134,
	},
	{
		garage_id: 150,
		part_id: 221,
		quantity: 442,
	},
	{
		garage_id: 150,
		part_id: 222,
		quantity: 404,
	},
	{
		garage_id: 150,
		part_id: 223,
		quantity: 322,
	},
	{
		garage_id: 150,
		part_id: 224,
		quantity: 71,
	},
	{
		garage_id: 150,
		part_id: 225,
		quantity: 377,
	},
	{
		garage_id: 150,
		part_id: 226,
		quantity: 362,
	},
	{
		garage_id: 150,
		part_id: 227,
		quantity: 386,
	},
	{
		garage_id: 150,
		part_id: 228,
		quantity: 317,
	},
	{
		garage_id: 150,
		part_id: 229,
		quantity: 85,
	},
	{
		garage_id: 150,
		part_id: 230,
		quantity: 411,
	},
	{
		garage_id: 150,
		part_id: 231,
		quantity: 374,
	},
	{
		garage_id: 150,
		part_id: 232,
		quantity: 483,
	},
	{
		garage_id: 150,
		part_id: 233,
		quantity: 398,
	},
	{
		garage_id: 150,
		part_id: 234,
		quantity: 93,
	},
	{
		garage_id: 150,
		part_id: 235,
		quantity: 19,
	},
	{
		garage_id: 150,
		part_id: 236,
		quantity: 33,
	},
	{
		garage_id: 150,
		part_id: 237,
		quantity: 90,
	},
	{
		garage_id: 150,
		part_id: 238,
		quantity: 89,
	},
	{
		garage_id: 150,
		part_id: 239,
		quantity: 111,
	},
	{
		garage_id: 150,
		part_id: 240,
		quantity: 125,
	},
	{
		garage_id: 150,
		part_id: 241,
		quantity: 382,
	},
	{
		garage_id: 150,
		part_id: 242,
		quantity: 255,
	},
	{
		garage_id: 150,
		part_id: 243,
		quantity: 127,
	},
	{
		garage_id: 150,
		part_id: 244,
		quantity: 222,
	},
	{
		garage_id: 150,
		part_id: 245,
		quantity: 212,
	},
	{
		garage_id: 150,
		part_id: 246,
		quantity: 193,
	},
	{
		garage_id: 150,
		part_id: 247,
		quantity: 19,
	},
	{
		garage_id: 150,
		part_id: 248,
		quantity: 388,
	},
	{
		garage_id: 150,
		part_id: 249,
		quantity: 154,
	},
	{
		garage_id: 150,
		part_id: 250,
		quantity: 266,
	},
	{
		garage_id: 150,
		part_id: 251,
		quantity: 489,
	},
	{
		garage_id: 150,
		part_id: 252,
		quantity: 314,
	},
	{
		garage_id: 150,
		part_id: 253,
		quantity: 440,
	},
	{
		garage_id: 150,
		part_id: 254,
		quantity: 143,
	},
	{
		garage_id: 150,
		part_id: 255,
		quantity: 376,
	},
	{
		garage_id: 150,
		part_id: 256,
		quantity: 296,
	},
	{
		garage_id: 150,
		part_id: 257,
		quantity: 24,
	},
	{
		garage_id: 150,
		part_id: 258,
		quantity: 403,
	},
	{
		garage_id: 150,
		part_id: 259,
		quantity: 196,
	},
	{
		garage_id: 150,
		part_id: 260,
		quantity: 256,
	},
	{
		garage_id: 150,
		part_id: 261,
		quantity: 367,
	},
	{
		garage_id: 150,
		part_id: 262,
		quantity: 467,
	},
	{
		garage_id: 150,
		part_id: 263,
		quantity: 24,
	},
	{
		garage_id: 150,
		part_id: 264,
		quantity: 386,
	},
	{
		garage_id: 150,
		part_id: 265,
		quantity: 138,
	},
	{
		garage_id: 150,
		part_id: 266,
		quantity: 385,
	},
	{
		garage_id: 150,
		part_id: 267,
		quantity: 262,
	},
	{
		garage_id: 150,
		part_id: 268,
		quantity: 336,
	},
	{
		garage_id: 150,
		part_id: 269,
		quantity: 484,
	},
	{
		garage_id: 150,
		part_id: 270,
		quantity: 27,
	},
	{
		garage_id: 150,
		part_id: 271,
		quantity: 181,
	},
	{
		garage_id: 150,
		part_id: 272,
		quantity: 477,
	},
	{
		garage_id: 150,
		part_id: 273,
		quantity: 39,
	},
	{
		garage_id: 150,
		part_id: 274,
		quantity: 6,
	},
	{
		garage_id: 150,
		part_id: 275,
		quantity: 213,
	},
	{
		garage_id: 150,
		part_id: 276,
		quantity: 216,
	},
	{
		garage_id: 150,
		part_id: 277,
		quantity: 114,
	},
	{
		garage_id: 150,
		part_id: 278,
		quantity: 125,
	},
	{
		garage_id: 150,
		part_id: 279,
		quantity: 24,
	},
	{
		garage_id: 150,
		part_id: 280,
		quantity: 290,
	},
	{
		garage_id: 150,
		part_id: 281,
		quantity: 326,
	},
	{
		garage_id: 150,
		part_id: 282,
		quantity: 342,
	},
	{
		garage_id: 150,
		part_id: 283,
		quantity: 373,
	},
	{
		garage_id: 150,
		part_id: 284,
		quantity: 356,
	},
	{
		garage_id: 150,
		part_id: 285,
		quantity: 19,
	},
	{
		garage_id: 150,
		part_id: 286,
		quantity: 37,
	},
	{
		garage_id: 150,
		part_id: 287,
		quantity: 306,
	},
	{
		garage_id: 150,
		part_id: 288,
		quantity: 354,
	},
	{
		garage_id: 150,
		part_id: 289,
		quantity: 331,
	},
	{
		garage_id: 150,
		part_id: 290,
		quantity: 122,
	},
	{
		garage_id: 150,
		part_id: 291,
		quantity: 96,
	},
	{
		garage_id: 150,
		part_id: 292,
		quantity: 345,
	},
	{
		garage_id: 150,
		part_id: 293,
		quantity: 120,
	},
	{
		garage_id: 150,
		part_id: 294,
		quantity: 279,
	},
	{
		garage_id: 150,
		part_id: 295,
		quantity: 51,
	},
	{
		garage_id: 150,
		part_id: 296,
		quantity: 398,
	},
	{
		garage_id: 150,
		part_id: 297,
		quantity: 365,
	},
	{
		garage_id: 150,
		part_id: 298,
		quantity: 467,
	},
	{
		garage_id: 150,
		part_id: 299,
		quantity: 275,
	},
	{
		garage_id: 150,
		part_id: 300,
		quantity: 477,
	},
	{
		garage_id: 150,
		part_id: 301,
		quantity: 20,
	},
	{
		garage_id: 150,
		part_id: 302,
		quantity: 167,
	},
	{
		garage_id: 150,
		part_id: 303,
		quantity: 410,
	},
	{
		garage_id: 150,
		part_id: 304,
		quantity: 457,
	},
	{
		garage_id: 150,
		part_id: 305,
		quantity: 463,
	},
	{
		garage_id: 150,
		part_id: 306,
		quantity: 367,
	},
	{
		garage_id: 150,
		part_id: 307,
		quantity: 348,
	},
	{
		garage_id: 150,
		part_id: 308,
		quantity: 464,
	},
	{
		garage_id: 150,
		part_id: 309,
		quantity: 277,
	},
	{
		garage_id: 150,
		part_id: 310,
		quantity: 66,
	},
	{
		garage_id: 150,
		part_id: 311,
		quantity: 178,
	},
	{
		garage_id: 150,
		part_id: 312,
		quantity: 196,
	},
	{
		garage_id: 150,
		part_id: 313,
		quantity: 333,
	},
	{
		garage_id: 150,
		part_id: 314,
		quantity: 273,
	},
	{
		garage_id: 150,
		part_id: 315,
		quantity: 47,
	},
	{
		garage_id: 150,
		part_id: 316,
		quantity: 121,
	},
	{
		garage_id: 150,
		part_id: 317,
		quantity: 61,
	},
	{
		garage_id: 150,
		part_id: 318,
		quantity: 190,
	},
	{
		garage_id: 150,
		part_id: 319,
		quantity: 13,
	},
	{
		garage_id: 150,
		part_id: 320,
		quantity: 265,
	},
	{
		garage_id: 150,
		part_id: 321,
		quantity: 457,
	},
	{
		garage_id: 150,
		part_id: 322,
		quantity: 118,
	},
	{
		garage_id: 150,
		part_id: 323,
		quantity: 213,
	},
	{
		garage_id: 150,
		part_id: 324,
		quantity: 326,
	},
	{
		garage_id: 150,
		part_id: 325,
		quantity: 341,
	},
	{
		garage_id: 150,
		part_id: 326,
		quantity: 433,
	},
	{
		garage_id: 150,
		part_id: 327,
		quantity: 102,
	},
	{
		garage_id: 150,
		part_id: 328,
		quantity: 103,
	},
	{
		garage_id: 150,
		part_id: 329,
		quantity: 454,
	},
	{
		garage_id: 150,
		part_id: 330,
		quantity: 142,
	},
	{
		garage_id: 150,
		part_id: 331,
		quantity: 246,
	},
	{
		garage_id: 150,
		part_id: 332,
		quantity: 319,
	},
	{
		garage_id: 150,
		part_id: 333,
		quantity: 169,
	},
	{
		garage_id: 150,
		part_id: 334,
		quantity: 476,
	},
	{
		garage_id: 150,
		part_id: 335,
		quantity: 384,
	},
	{
		garage_id: 150,
		part_id: 336,
		quantity: 288,
	},
	{
		garage_id: 150,
		part_id: 337,
		quantity: 210,
	},
	{
		garage_id: 150,
		part_id: 338,
		quantity: 237,
	},
	{
		garage_id: 150,
		part_id: 339,
		quantity: 489,
	},
	{
		garage_id: 150,
		part_id: 340,
		quantity: 81,
	},
	{
		garage_id: 150,
		part_id: 341,
		quantity: 159,
	},
	{
		garage_id: 150,
		part_id: 342,
		quantity: 28,
	},
	{
		garage_id: 150,
		part_id: 343,
		quantity: 332,
	},
	{
		garage_id: 150,
		part_id: 344,
		quantity: 41,
	},
	{
		garage_id: 150,
		part_id: 345,
		quantity: 342,
	},
	{
		garage_id: 150,
		part_id: 346,
		quantity: 210,
	},
	{
		garage_id: 150,
		part_id: 347,
		quantity: 198,
	},
	{
		garage_id: 150,
		part_id: 348,
		quantity: 160,
	},
	{
		garage_id: 150,
		part_id: 349,
		quantity: 207,
	},
	{
		garage_id: 150,
		part_id: 350,
		quantity: 134,
	},
	{
		garage_id: 150,
		part_id: 351,
		quantity: 100,
	},
	{
		garage_id: 150,
		part_id: 352,
		quantity: 109,
	},
	{
		garage_id: 150,
		part_id: 353,
		quantity: 468,
	},
	{
		garage_id: 150,
		part_id: 354,
		quantity: 249,
	},
	{
		garage_id: 150,
		part_id: 355,
		quantity: 213,
	},
	{
		garage_id: 150,
		part_id: 356,
		quantity: 24,
	},
	{
		garage_id: 150,
		part_id: 357,
		quantity: 463,
	},
	{
		garage_id: 150,
		part_id: 358,
		quantity: 397,
	},
	{
		garage_id: 150,
		part_id: 359,
		quantity: 408,
	},
	{
		garage_id: 150,
		part_id: 360,
		quantity: 330,
	},
	{
		garage_id: 150,
		part_id: 361,
		quantity: 225,
	},
	{
		garage_id: 150,
		part_id: 362,
		quantity: 138,
	},
	{
		garage_id: 150,
		part_id: 363,
		quantity: 447,
	},
	{
		garage_id: 150,
		part_id: 364,
		quantity: 241,
	},
	{
		garage_id: 150,
		part_id: 365,
		quantity: 118,
	},
	{
		garage_id: 150,
		part_id: 366,
		quantity: 422,
	},
	{
		garage_id: 150,
		part_id: 367,
		quantity: 496,
	},
	{
		garage_id: 150,
		part_id: 368,
		quantity: 132,
	},
	{
		garage_id: 150,
		part_id: 369,
		quantity: 131,
	},
	{
		garage_id: 150,
		part_id: 370,
		quantity: 277,
	},
	{
		garage_id: 150,
		part_id: 371,
		quantity: 491,
	},
	{
		garage_id: 150,
		part_id: 372,
		quantity: 23,
	},
	{
		garage_id: 150,
		part_id: 373,
		quantity: 147,
	},
	{
		garage_id: 150,
		part_id: 374,
		quantity: 316,
	},
	{
		garage_id: 150,
		part_id: 375,
		quantity: 371,
	},
	{
		garage_id: 150,
		part_id: 376,
		quantity: 380,
	},
	{
		garage_id: 150,
		part_id: 377,
		quantity: 439,
	},
	{
		garage_id: 150,
		part_id: 378,
		quantity: 403,
	},
	{
		garage_id: 150,
		part_id: 379,
		quantity: 394,
	},
	{
		garage_id: 150,
		part_id: 380,
		quantity: 280,
	},
	{
		garage_id: 150,
		part_id: 381,
		quantity: 472,
	},
	{
		garage_id: 150,
		part_id: 382,
		quantity: 239,
	},
	{
		garage_id: 150,
		part_id: 383,
		quantity: 233,
	},
	{
		garage_id: 150,
		part_id: 384,
		quantity: 89,
	},
	{
		garage_id: 150,
		part_id: 385,
		quantity: 415,
	},
	{
		garage_id: 150,
		part_id: 386,
		quantity: 334,
	},
	{
		garage_id: 150,
		part_id: 387,
		quantity: 313,
	},
	{
		garage_id: 150,
		part_id: 388,
		quantity: 183,
	},
	{
		garage_id: 150,
		part_id: 389,
		quantity: 334,
	},
	{
		garage_id: 150,
		part_id: 390,
		quantity: 185,
	},
	{
		garage_id: 150,
		part_id: 391,
		quantity: 77,
	},
	{
		garage_id: 150,
		part_id: 392,
		quantity: 190,
	},
	{
		garage_id: 150,
		part_id: 393,
		quantity: 144,
	},
	{
		garage_id: 150,
		part_id: 394,
		quantity: 282,
	},
	{
		garage_id: 150,
		part_id: 395,
		quantity: 412,
	},
	{
		garage_id: 150,
		part_id: 396,
		quantity: 234,
	},
	{
		garage_id: 150,
		part_id: 397,
		quantity: 268,
	},
	{
		garage_id: 150,
		part_id: 398,
		quantity: 10,
	},
	{
		garage_id: 150,
		part_id: 399,
		quantity: 75,
	},
	{
		garage_id: 151,
		part_id: 0,
		quantity: 48,
	},
	{
		garage_id: 151,
		part_id: 1,
		quantity: 415,
	},
	{
		garage_id: 151,
		part_id: 2,
		quantity: 419,
	},
	{
		garage_id: 151,
		part_id: 3,
		quantity: 336,
	},
	{
		garage_id: 151,
		part_id: 4,
		quantity: 324,
	},
	{
		garage_id: 151,
		part_id: 5,
		quantity: 411,
	},
	{
		garage_id: 151,
		part_id: 6,
		quantity: 217,
	},
	{
		garage_id: 151,
		part_id: 7,
		quantity: 147,
	},
	{
		garage_id: 151,
		part_id: 8,
		quantity: 143,
	},
	{
		garage_id: 151,
		part_id: 9,
		quantity: 390,
	},
	{
		garage_id: 151,
		part_id: 10,
		quantity: 17,
	},
	{
		garage_id: 151,
		part_id: 11,
		quantity: 29,
	},
	{
		garage_id: 151,
		part_id: 12,
		quantity: 434,
	},
	{
		garage_id: 151,
		part_id: 13,
		quantity: 234,
	},
	{
		garage_id: 151,
		part_id: 14,
		quantity: 340,
	},
	{
		garage_id: 151,
		part_id: 15,
		quantity: 271,
	},
	{
		garage_id: 151,
		part_id: 16,
		quantity: 25,
	},
	{
		garage_id: 151,
		part_id: 17,
		quantity: 494,
	},
	{
		garage_id: 151,
		part_id: 18,
		quantity: 472,
	},
	{
		garage_id: 151,
		part_id: 19,
		quantity: 416,
	},
	{
		garage_id: 151,
		part_id: 20,
		quantity: 494,
	},
	{
		garage_id: 151,
		part_id: 21,
		quantity: 307,
	},
	{
		garage_id: 151,
		part_id: 22,
		quantity: 158,
	},
	{
		garage_id: 151,
		part_id: 23,
		quantity: 148,
	},
	{
		garage_id: 151,
		part_id: 24,
		quantity: 462,
	},
	{
		garage_id: 151,
		part_id: 25,
		quantity: 237,
	},
	{
		garage_id: 151,
		part_id: 26,
		quantity: 403,
	},
	{
		garage_id: 151,
		part_id: 27,
		quantity: 188,
	},
	{
		garage_id: 151,
		part_id: 28,
		quantity: 17,
	},
	{
		garage_id: 151,
		part_id: 29,
		quantity: 76,
	},
	{
		garage_id: 151,
		part_id: 30,
		quantity: 98,
	},
	{
		garage_id: 151,
		part_id: 31,
		quantity: 494,
	},
	{
		garage_id: 151,
		part_id: 32,
		quantity: 109,
	},
	{
		garage_id: 151,
		part_id: 33,
		quantity: 28,
	},
	{
		garage_id: 151,
		part_id: 34,
		quantity: 330,
	},
	{
		garage_id: 151,
		part_id: 35,
		quantity: 297,
	},
	{
		garage_id: 151,
		part_id: 36,
		quantity: 14,
	},
	{
		garage_id: 151,
		part_id: 37,
		quantity: 265,
	},
	{
		garage_id: 151,
		part_id: 38,
		quantity: 415,
	},
	{
		garage_id: 151,
		part_id: 39,
		quantity: 432,
	},
	{
		garage_id: 151,
		part_id: 40,
		quantity: 169,
	},
	{
		garage_id: 151,
		part_id: 41,
		quantity: 244,
	},
	{
		garage_id: 151,
		part_id: 42,
		quantity: 198,
	},
	{
		garage_id: 151,
		part_id: 43,
		quantity: 25,
	},
	{
		garage_id: 151,
		part_id: 44,
		quantity: 125,
	},
	{
		garage_id: 151,
		part_id: 45,
		quantity: 401,
	},
	{
		garage_id: 151,
		part_id: 46,
		quantity: 482,
	},
	{
		garage_id: 151,
		part_id: 47,
		quantity: 471,
	},
	{
		garage_id: 151,
		part_id: 48,
		quantity: 288,
	},
	{
		garage_id: 151,
		part_id: 49,
		quantity: 201,
	},
	{
		garage_id: 151,
		part_id: 50,
		quantity: 483,
	},
	{
		garage_id: 151,
		part_id: 51,
		quantity: 279,
	},
	{
		garage_id: 151,
		part_id: 52,
		quantity: 258,
	},
	{
		garage_id: 151,
		part_id: 53,
		quantity: 140,
	},
	{
		garage_id: 151,
		part_id: 54,
		quantity: 52,
	},
	{
		garage_id: 151,
		part_id: 55,
		quantity: 422,
	},
	{
		garage_id: 151,
		part_id: 56,
		quantity: 489,
	},
	{
		garage_id: 151,
		part_id: 57,
		quantity: 310,
	},
	{
		garage_id: 151,
		part_id: 58,
		quantity: 227,
	},
	{
		garage_id: 151,
		part_id: 59,
		quantity: 124,
	},
	{
		garage_id: 151,
		part_id: 60,
		quantity: 196,
	},
	{
		garage_id: 151,
		part_id: 61,
		quantity: 370,
	},
	{
		garage_id: 151,
		part_id: 62,
		quantity: 135,
	},
	{
		garage_id: 151,
		part_id: 63,
		quantity: 242,
	},
	{
		garage_id: 151,
		part_id: 64,
		quantity: 213,
	},
	{
		garage_id: 151,
		part_id: 65,
		quantity: 250,
	},
	{
		garage_id: 151,
		part_id: 66,
		quantity: 67,
	},
	{
		garage_id: 151,
		part_id: 67,
		quantity: 75,
	},
	{
		garage_id: 151,
		part_id: 68,
		quantity: 313,
	},
	{
		garage_id: 151,
		part_id: 69,
		quantity: 342,
	},
	{
		garage_id: 151,
		part_id: 70,
		quantity: 459,
	},
	{
		garage_id: 151,
		part_id: 71,
		quantity: 252,
	},
	{
		garage_id: 151,
		part_id: 72,
		quantity: 230,
	},
	{
		garage_id: 151,
		part_id: 73,
		quantity: 122,
	},
	{
		garage_id: 151,
		part_id: 74,
		quantity: 89,
	},
	{
		garage_id: 151,
		part_id: 75,
		quantity: 26,
	},
	{
		garage_id: 151,
		part_id: 76,
		quantity: 183,
	},
	{
		garage_id: 151,
		part_id: 77,
		quantity: 448,
	},
	{
		garage_id: 151,
		part_id: 78,
		quantity: 477,
	},
	{
		garage_id: 151,
		part_id: 79,
		quantity: 256,
	},
	{
		garage_id: 151,
		part_id: 80,
		quantity: 60,
	},
	{
		garage_id: 151,
		part_id: 81,
		quantity: 162,
	},
	{
		garage_id: 151,
		part_id: 82,
		quantity: 232,
	},
	{
		garage_id: 151,
		part_id: 83,
		quantity: 493,
	},
	{
		garage_id: 151,
		part_id: 84,
		quantity: 20,
	},
	{
		garage_id: 151,
		part_id: 85,
		quantity: 260,
	},
	{
		garage_id: 151,
		part_id: 86,
		quantity: 183,
	},
	{
		garage_id: 151,
		part_id: 87,
		quantity: 482,
	},
	{
		garage_id: 151,
		part_id: 88,
		quantity: 22,
	},
	{
		garage_id: 151,
		part_id: 89,
		quantity: 499,
	},
	{
		garage_id: 151,
		part_id: 90,
		quantity: 102,
	},
	{
		garage_id: 151,
		part_id: 91,
		quantity: 249,
	},
	{
		garage_id: 151,
		part_id: 92,
		quantity: 37,
	},
	{
		garage_id: 151,
		part_id: 93,
		quantity: 222,
	},
	{
		garage_id: 151,
		part_id: 94,
		quantity: 113,
	},
	{
		garage_id: 151,
		part_id: 95,
		quantity: 93,
	},
	{
		garage_id: 151,
		part_id: 96,
		quantity: 134,
	},
	{
		garage_id: 151,
		part_id: 97,
		quantity: 129,
	},
	{
		garage_id: 151,
		part_id: 98,
		quantity: 435,
	},
	{
		garage_id: 151,
		part_id: 99,
		quantity: 311,
	},
	{
		garage_id: 151,
		part_id: 100,
		quantity: 71,
	},
	{
		garage_id: 151,
		part_id: 101,
		quantity: 336,
	},
	{
		garage_id: 151,
		part_id: 102,
		quantity: 406,
	},
	{
		garage_id: 151,
		part_id: 103,
		quantity: 129,
	},
	{
		garage_id: 151,
		part_id: 104,
		quantity: 158,
	},
	{
		garage_id: 151,
		part_id: 105,
		quantity: 359,
	},
	{
		garage_id: 151,
		part_id: 106,
		quantity: 96,
	},
	{
		garage_id: 151,
		part_id: 107,
		quantity: 277,
	},
	{
		garage_id: 151,
		part_id: 108,
		quantity: 73,
	},
	{
		garage_id: 151,
		part_id: 109,
		quantity: 158,
	},
	{
		garage_id: 151,
		part_id: 110,
		quantity: 355,
	},
	{
		garage_id: 151,
		part_id: 111,
		quantity: 316,
	},
	{
		garage_id: 151,
		part_id: 112,
		quantity: 288,
	},
	{
		garage_id: 151,
		part_id: 113,
		quantity: 105,
	},
	{
		garage_id: 151,
		part_id: 114,
		quantity: 249,
	},
	{
		garage_id: 151,
		part_id: 115,
		quantity: 2,
	},
	{
		garage_id: 151,
		part_id: 116,
		quantity: 139,
	},
	{
		garage_id: 151,
		part_id: 117,
		quantity: 92,
	},
	{
		garage_id: 151,
		part_id: 118,
		quantity: 194,
	},
	{
		garage_id: 151,
		part_id: 119,
		quantity: 282,
	},
	{
		garage_id: 151,
		part_id: 120,
		quantity: 357,
	},
	{
		garage_id: 151,
		part_id: 121,
		quantity: 292,
	},
	{
		garage_id: 151,
		part_id: 122,
		quantity: 122,
	},
	{
		garage_id: 151,
		part_id: 123,
		quantity: 115,
	},
	{
		garage_id: 151,
		part_id: 124,
		quantity: 469,
	},
	{
		garage_id: 151,
		part_id: 125,
		quantity: 227,
	},
	{
		garage_id: 151,
		part_id: 126,
		quantity: 440,
	},
	{
		garage_id: 151,
		part_id: 127,
		quantity: 411,
	},
	{
		garage_id: 151,
		part_id: 128,
		quantity: 415,
	},
	{
		garage_id: 151,
		part_id: 129,
		quantity: 272,
	},
	{
		garage_id: 151,
		part_id: 130,
		quantity: 469,
	},
	{
		garage_id: 151,
		part_id: 131,
		quantity: 312,
	},
	{
		garage_id: 151,
		part_id: 132,
		quantity: 469,
	},
	{
		garage_id: 151,
		part_id: 133,
		quantity: 49,
	},
	{
		garage_id: 151,
		part_id: 134,
		quantity: 353,
	},
	{
		garage_id: 151,
		part_id: 135,
		quantity: 103,
	},
	{
		garage_id: 151,
		part_id: 136,
		quantity: 460,
	},
	{
		garage_id: 151,
		part_id: 137,
		quantity: 135,
	},
	{
		garage_id: 151,
		part_id: 138,
		quantity: 173,
	},
	{
		garage_id: 151,
		part_id: 139,
		quantity: 420,
	},
	{
		garage_id: 151,
		part_id: 140,
		quantity: 16,
	},
	{
		garage_id: 151,
		part_id: 141,
		quantity: 56,
	},
	{
		garage_id: 151,
		part_id: 142,
		quantity: 460,
	},
	{
		garage_id: 151,
		part_id: 143,
		quantity: 74,
	},
	{
		garage_id: 151,
		part_id: 144,
		quantity: 254,
	},
	{
		garage_id: 151,
		part_id: 145,
		quantity: 67,
	},
	{
		garage_id: 151,
		part_id: 146,
		quantity: 1,
	},
	{
		garage_id: 151,
		part_id: 147,
		quantity: 318,
	},
	{
		garage_id: 151,
		part_id: 148,
		quantity: 127,
	},
	{
		garage_id: 151,
		part_id: 149,
		quantity: 489,
	},
	{
		garage_id: 151,
		part_id: 150,
		quantity: 334,
	},
	{
		garage_id: 151,
		part_id: 151,
		quantity: 246,
	},
	{
		garage_id: 151,
		part_id: 152,
		quantity: 21,
	},
	{
		garage_id: 151,
		part_id: 153,
		quantity: 48,
	},
	{
		garage_id: 151,
		part_id: 154,
		quantity: 218,
	},
	{
		garage_id: 151,
		part_id: 155,
		quantity: 128,
	},
	{
		garage_id: 151,
		part_id: 156,
		quantity: 263,
	},
	{
		garage_id: 151,
		part_id: 157,
		quantity: 127,
	},
	{
		garage_id: 151,
		part_id: 158,
		quantity: 323,
	},
	{
		garage_id: 151,
		part_id: 159,
		quantity: 13,
	},
	{
		garage_id: 151,
		part_id: 160,
		quantity: 411,
	},
	{
		garage_id: 151,
		part_id: 161,
		quantity: 453,
	},
	{
		garage_id: 151,
		part_id: 162,
		quantity: 40,
	},
	{
		garage_id: 151,
		part_id: 163,
		quantity: 11,
	},
	{
		garage_id: 151,
		part_id: 164,
		quantity: 420,
	},
	{
		garage_id: 151,
		part_id: 165,
		quantity: 312,
	},
	{
		garage_id: 151,
		part_id: 166,
		quantity: 76,
	},
	{
		garage_id: 151,
		part_id: 167,
		quantity: 240,
	},
	{
		garage_id: 151,
		part_id: 168,
		quantity: 444,
	},
	{
		garage_id: 151,
		part_id: 169,
		quantity: 429,
	},
	{
		garage_id: 151,
		part_id: 170,
		quantity: 403,
	},
	{
		garage_id: 151,
		part_id: 171,
		quantity: 401,
	},
	{
		garage_id: 151,
		part_id: 172,
		quantity: 150,
	},
	{
		garage_id: 151,
		part_id: 173,
		quantity: 50,
	},
	{
		garage_id: 151,
		part_id: 174,
		quantity: 232,
	},
	{
		garage_id: 151,
		part_id: 175,
		quantity: 290,
	},
	{
		garage_id: 151,
		part_id: 176,
		quantity: 79,
	},
	{
		garage_id: 151,
		part_id: 177,
		quantity: 186,
	},
	{
		garage_id: 151,
		part_id: 178,
		quantity: 41,
	},
	{
		garage_id: 151,
		part_id: 179,
		quantity: 113,
	},
	{
		garage_id: 151,
		part_id: 180,
		quantity: 18,
	},
	{
		garage_id: 151,
		part_id: 181,
		quantity: 436,
	},
	{
		garage_id: 151,
		part_id: 182,
		quantity: 204,
	},
	{
		garage_id: 151,
		part_id: 183,
		quantity: 315,
	},
	{
		garage_id: 151,
		part_id: 184,
		quantity: 171,
	},
	{
		garage_id: 151,
		part_id: 185,
		quantity: 230,
	},
	{
		garage_id: 151,
		part_id: 186,
		quantity: 180,
	},
	{
		garage_id: 151,
		part_id: 187,
		quantity: 345,
	},
	{
		garage_id: 151,
		part_id: 188,
		quantity: 29,
	},
	{
		garage_id: 151,
		part_id: 189,
		quantity: 314,
	},
	{
		garage_id: 151,
		part_id: 190,
		quantity: 138,
	},
	{
		garage_id: 151,
		part_id: 191,
		quantity: 490,
	},
	{
		garage_id: 151,
		part_id: 192,
		quantity: 278,
	},
	{
		garage_id: 151,
		part_id: 193,
		quantity: 355,
	},
	{
		garage_id: 151,
		part_id: 194,
		quantity: 214,
	},
	{
		garage_id: 151,
		part_id: 195,
		quantity: 201,
	},
	{
		garage_id: 151,
		part_id: 196,
		quantity: 355,
	},
	{
		garage_id: 151,
		part_id: 197,
		quantity: 311,
	},
	{
		garage_id: 151,
		part_id: 198,
		quantity: 368,
	},
	{
		garage_id: 151,
		part_id: 199,
		quantity: 2,
	},
	{
		garage_id: 151,
		part_id: 200,
		quantity: 14,
	},
	{
		garage_id: 151,
		part_id: 201,
		quantity: 419,
	},
	{
		garage_id: 151,
		part_id: 202,
		quantity: 84,
	},
	{
		garage_id: 151,
		part_id: 203,
		quantity: 53,
	},
	{
		garage_id: 151,
		part_id: 204,
		quantity: 109,
	},
	{
		garage_id: 151,
		part_id: 205,
		quantity: 402,
	},
	{
		garage_id: 151,
		part_id: 206,
		quantity: 357,
	},
	{
		garage_id: 151,
		part_id: 207,
		quantity: 419,
	},
	{
		garage_id: 151,
		part_id: 208,
		quantity: 43,
	},
	{
		garage_id: 151,
		part_id: 209,
		quantity: 73,
	},
	{
		garage_id: 151,
		part_id: 210,
		quantity: 248,
	},
	{
		garage_id: 151,
		part_id: 211,
		quantity: 32,
	},
	{
		garage_id: 151,
		part_id: 212,
		quantity: 51,
	},
	{
		garage_id: 151,
		part_id: 213,
		quantity: 358,
	},
	{
		garage_id: 151,
		part_id: 214,
		quantity: 266,
	},
	{
		garage_id: 151,
		part_id: 215,
		quantity: 119,
	},
	{
		garage_id: 151,
		part_id: 216,
		quantity: 407,
	},
	{
		garage_id: 151,
		part_id: 217,
		quantity: 270,
	},
	{
		garage_id: 151,
		part_id: 218,
		quantity: 271,
	},
	{
		garage_id: 151,
		part_id: 219,
		quantity: 487,
	},
	{
		garage_id: 151,
		part_id: 220,
		quantity: 186,
	},
	{
		garage_id: 151,
		part_id: 221,
		quantity: 455,
	},
	{
		garage_id: 151,
		part_id: 222,
		quantity: 76,
	},
	{
		garage_id: 151,
		part_id: 223,
		quantity: 51,
	},
	{
		garage_id: 151,
		part_id: 224,
		quantity: 327,
	},
	{
		garage_id: 151,
		part_id: 225,
		quantity: 435,
	},
	{
		garage_id: 151,
		part_id: 226,
		quantity: 185,
	},
	{
		garage_id: 151,
		part_id: 227,
		quantity: 281,
	},
	{
		garage_id: 151,
		part_id: 228,
		quantity: 452,
	},
	{
		garage_id: 151,
		part_id: 229,
		quantity: 233,
	},
	{
		garage_id: 151,
		part_id: 230,
		quantity: 388,
	},
	{
		garage_id: 151,
		part_id: 231,
		quantity: 89,
	},
	{
		garage_id: 151,
		part_id: 232,
		quantity: 490,
	},
	{
		garage_id: 151,
		part_id: 233,
		quantity: 397,
	},
	{
		garage_id: 151,
		part_id: 234,
		quantity: 139,
	},
	{
		garage_id: 151,
		part_id: 235,
		quantity: 362,
	},
	{
		garage_id: 151,
		part_id: 236,
		quantity: 189,
	},
	{
		garage_id: 151,
		part_id: 237,
		quantity: 399,
	},
	{
		garage_id: 151,
		part_id: 238,
		quantity: 242,
	},
	{
		garage_id: 151,
		part_id: 239,
		quantity: 405,
	},
	{
		garage_id: 151,
		part_id: 240,
		quantity: 126,
	},
	{
		garage_id: 151,
		part_id: 241,
		quantity: 14,
	},
	{
		garage_id: 151,
		part_id: 242,
		quantity: 99,
	},
	{
		garage_id: 151,
		part_id: 243,
		quantity: 169,
	},
	{
		garage_id: 151,
		part_id: 244,
		quantity: 47,
	},
	{
		garage_id: 151,
		part_id: 245,
		quantity: 424,
	},
	{
		garage_id: 151,
		part_id: 246,
		quantity: 242,
	},
	{
		garage_id: 151,
		part_id: 247,
		quantity: 158,
	},
	{
		garage_id: 151,
		part_id: 248,
		quantity: 375,
	},
	{
		garage_id: 151,
		part_id: 249,
		quantity: 159,
	},
	{
		garage_id: 151,
		part_id: 250,
		quantity: 131,
	},
	{
		garage_id: 151,
		part_id: 251,
		quantity: 457,
	},
	{
		garage_id: 151,
		part_id: 252,
		quantity: 21,
	},
	{
		garage_id: 151,
		part_id: 253,
		quantity: 325,
	},
	{
		garage_id: 151,
		part_id: 254,
		quantity: 50,
	},
	{
		garage_id: 151,
		part_id: 255,
		quantity: 201,
	},
	{
		garage_id: 151,
		part_id: 256,
		quantity: 405,
	},
	{
		garage_id: 151,
		part_id: 257,
		quantity: 473,
	},
	{
		garage_id: 151,
		part_id: 258,
		quantity: 369,
	},
	{
		garage_id: 151,
		part_id: 259,
		quantity: 175,
	},
	{
		garage_id: 151,
		part_id: 260,
		quantity: 356,
	},
	{
		garage_id: 151,
		part_id: 261,
		quantity: 133,
	},
	{
		garage_id: 151,
		part_id: 262,
		quantity: 292,
	},
	{
		garage_id: 151,
		part_id: 263,
		quantity: 399,
	},
	{
		garage_id: 151,
		part_id: 264,
		quantity: 9,
	},
	{
		garage_id: 151,
		part_id: 265,
		quantity: 371,
	},
	{
		garage_id: 151,
		part_id: 266,
		quantity: 289,
	},
	{
		garage_id: 151,
		part_id: 267,
		quantity: 476,
	},
	{
		garage_id: 151,
		part_id: 268,
		quantity: 382,
	},
	{
		garage_id: 151,
		part_id: 269,
		quantity: 84,
	},
	{
		garage_id: 151,
		part_id: 270,
		quantity: 121,
	},
	{
		garage_id: 151,
		part_id: 271,
		quantity: 432,
	},
	{
		garage_id: 151,
		part_id: 272,
		quantity: 315,
	},
	{
		garage_id: 151,
		part_id: 273,
		quantity: 441,
	},
	{
		garage_id: 151,
		part_id: 274,
		quantity: 35,
	},
	{
		garage_id: 151,
		part_id: 275,
		quantity: 68,
	},
	{
		garage_id: 151,
		part_id: 276,
		quantity: 28,
	},
	{
		garage_id: 151,
		part_id: 277,
		quantity: 18,
	},
	{
		garage_id: 151,
		part_id: 278,
		quantity: 339,
	},
	{
		garage_id: 151,
		part_id: 279,
		quantity: 402,
	},
	{
		garage_id: 151,
		part_id: 280,
		quantity: 23,
	},
	{
		garage_id: 151,
		part_id: 281,
		quantity: 325,
	},
	{
		garage_id: 151,
		part_id: 282,
		quantity: 188,
	},
	{
		garage_id: 151,
		part_id: 283,
		quantity: 80,
	},
	{
		garage_id: 151,
		part_id: 284,
		quantity: 418,
	},
	{
		garage_id: 151,
		part_id: 285,
		quantity: 192,
	},
	{
		garage_id: 151,
		part_id: 286,
		quantity: 203,
	},
	{
		garage_id: 151,
		part_id: 287,
		quantity: 130,
	},
	{
		garage_id: 151,
		part_id: 288,
		quantity: 340,
	},
	{
		garage_id: 151,
		part_id: 289,
		quantity: 459,
	},
	{
		garage_id: 151,
		part_id: 290,
		quantity: 417,
	},
	{
		garage_id: 151,
		part_id: 291,
		quantity: 398,
	},
	{
		garage_id: 151,
		part_id: 292,
		quantity: 237,
	},
	{
		garage_id: 151,
		part_id: 293,
		quantity: 210,
	},
	{
		garage_id: 151,
		part_id: 294,
		quantity: 89,
	},
	{
		garage_id: 151,
		part_id: 295,
		quantity: 300,
	},
	{
		garage_id: 151,
		part_id: 296,
		quantity: 248,
	},
	{
		garage_id: 151,
		part_id: 297,
		quantity: 460,
	},
	{
		garage_id: 151,
		part_id: 298,
		quantity: 386,
	},
	{
		garage_id: 151,
		part_id: 299,
		quantity: 22,
	},
	{
		garage_id: 151,
		part_id: 300,
		quantity: 492,
	},
	{
		garage_id: 151,
		part_id: 301,
		quantity: 410,
	},
	{
		garage_id: 151,
		part_id: 302,
		quantity: 170,
	},
	{
		garage_id: 151,
		part_id: 303,
		quantity: 3,
	},
	{
		garage_id: 151,
		part_id: 304,
		quantity: 154,
	},
	{
		garage_id: 151,
		part_id: 305,
		quantity: 411,
	},
	{
		garage_id: 151,
		part_id: 306,
		quantity: 176,
	},
	{
		garage_id: 151,
		part_id: 307,
		quantity: 347,
	},
	{
		garage_id: 151,
		part_id: 308,
		quantity: 284,
	},
	{
		garage_id: 151,
		part_id: 309,
		quantity: 373,
	},
	{
		garage_id: 151,
		part_id: 310,
		quantity: 170,
	},
	{
		garage_id: 151,
		part_id: 311,
		quantity: 214,
	},
	{
		garage_id: 151,
		part_id: 312,
		quantity: 289,
	},
	{
		garage_id: 151,
		part_id: 313,
		quantity: 447,
	},
	{
		garage_id: 151,
		part_id: 314,
		quantity: 340,
	},
	{
		garage_id: 151,
		part_id: 315,
		quantity: 81,
	},
	{
		garage_id: 151,
		part_id: 316,
		quantity: 377,
	},
	{
		garage_id: 151,
		part_id: 317,
		quantity: 401,
	},
	{
		garage_id: 151,
		part_id: 318,
		quantity: 31,
	},
	{
		garage_id: 151,
		part_id: 319,
		quantity: 383,
	},
	{
		garage_id: 151,
		part_id: 320,
		quantity: 121,
	},
	{
		garage_id: 151,
		part_id: 321,
		quantity: 2,
	},
	{
		garage_id: 151,
		part_id: 322,
		quantity: 265,
	},
	{
		garage_id: 151,
		part_id: 323,
		quantity: 277,
	},
	{
		garage_id: 151,
		part_id: 324,
		quantity: 327,
	},
	{
		garage_id: 151,
		part_id: 325,
		quantity: 93,
	},
	{
		garage_id: 151,
		part_id: 326,
		quantity: 465,
	},
	{
		garage_id: 151,
		part_id: 327,
		quantity: 147,
	},
	{
		garage_id: 151,
		part_id: 328,
		quantity: 297,
	},
	{
		garage_id: 151,
		part_id: 329,
		quantity: 350,
	},
	{
		garage_id: 151,
		part_id: 330,
		quantity: 12,
	},
	{
		garage_id: 151,
		part_id: 331,
		quantity: 142,
	},
	{
		garage_id: 151,
		part_id: 332,
		quantity: 281,
	},
	{
		garage_id: 151,
		part_id: 333,
		quantity: 334,
	},
	{
		garage_id: 151,
		part_id: 334,
		quantity: 254,
	},
	{
		garage_id: 151,
		part_id: 335,
		quantity: 72,
	},
	{
		garage_id: 151,
		part_id: 336,
		quantity: 167,
	},
	{
		garage_id: 151,
		part_id: 337,
		quantity: 446,
	},
	{
		garage_id: 151,
		part_id: 338,
		quantity: 268,
	},
	{
		garage_id: 151,
		part_id: 339,
		quantity: 401,
	},
	{
		garage_id: 151,
		part_id: 340,
		quantity: 252,
	},
	{
		garage_id: 151,
		part_id: 341,
		quantity: 258,
	},
	{
		garage_id: 151,
		part_id: 342,
		quantity: 91,
	},
	{
		garage_id: 151,
		part_id: 343,
		quantity: 195,
	},
	{
		garage_id: 151,
		part_id: 344,
		quantity: 214,
	},
	{
		garage_id: 151,
		part_id: 345,
		quantity: 390,
	},
	{
		garage_id: 151,
		part_id: 346,
		quantity: 255,
	},
	{
		garage_id: 151,
		part_id: 347,
		quantity: 161,
	},
	{
		garage_id: 151,
		part_id: 348,
		quantity: 89,
	},
	{
		garage_id: 151,
		part_id: 349,
		quantity: 117,
	},
	{
		garage_id: 151,
		part_id: 350,
		quantity: 65,
	},
	{
		garage_id: 151,
		part_id: 351,
		quantity: 324,
	},
	{
		garage_id: 151,
		part_id: 352,
		quantity: 429,
	},
	{
		garage_id: 151,
		part_id: 353,
		quantity: 175,
	},
	{
		garage_id: 151,
		part_id: 354,
		quantity: 387,
	},
	{
		garage_id: 151,
		part_id: 355,
		quantity: 366,
	},
	{
		garage_id: 151,
		part_id: 356,
		quantity: 182,
	},
	{
		garage_id: 151,
		part_id: 357,
		quantity: 134,
	},
	{
		garage_id: 151,
		part_id: 358,
		quantity: 239,
	},
	{
		garage_id: 151,
		part_id: 359,
		quantity: 129,
	},
	{
		garage_id: 151,
		part_id: 360,
		quantity: 395,
	},
	{
		garage_id: 151,
		part_id: 361,
		quantity: 236,
	},
	{
		garage_id: 151,
		part_id: 362,
		quantity: 130,
	},
	{
		garage_id: 151,
		part_id: 363,
		quantity: 299,
	},
	{
		garage_id: 151,
		part_id: 364,
		quantity: 7,
	},
	{
		garage_id: 151,
		part_id: 365,
		quantity: 313,
	},
	{
		garage_id: 151,
		part_id: 366,
		quantity: 232,
	},
	{
		garage_id: 151,
		part_id: 367,
		quantity: 296,
	},
	{
		garage_id: 151,
		part_id: 368,
		quantity: 137,
	},
	{
		garage_id: 151,
		part_id: 369,
		quantity: 16,
	},
	{
		garage_id: 151,
		part_id: 370,
		quantity: 180,
	},
	{
		garage_id: 151,
		part_id: 371,
		quantity: 443,
	},
	{
		garage_id: 151,
		part_id: 372,
		quantity: 288,
	},
	{
		garage_id: 151,
		part_id: 373,
		quantity: 392,
	},
	{
		garage_id: 151,
		part_id: 374,
		quantity: 429,
	},
	{
		garage_id: 151,
		part_id: 375,
		quantity: 38,
	},
	{
		garage_id: 151,
		part_id: 376,
		quantity: 134,
	},
	{
		garage_id: 151,
		part_id: 377,
		quantity: 243,
	},
	{
		garage_id: 151,
		part_id: 378,
		quantity: 488,
	},
	{
		garage_id: 151,
		part_id: 379,
		quantity: 273,
	},
	{
		garage_id: 151,
		part_id: 380,
		quantity: 71,
	},
	{
		garage_id: 151,
		part_id: 381,
		quantity: 175,
	},
	{
		garage_id: 151,
		part_id: 382,
		quantity: 63,
	},
	{
		garage_id: 151,
		part_id: 383,
		quantity: 302,
	},
	{
		garage_id: 151,
		part_id: 384,
		quantity: 302,
	},
	{
		garage_id: 151,
		part_id: 385,
		quantity: 351,
	},
	{
		garage_id: 151,
		part_id: 386,
		quantity: 301,
	},
	{
		garage_id: 151,
		part_id: 387,
		quantity: 37,
	},
	{
		garage_id: 151,
		part_id: 388,
		quantity: 372,
	},
	{
		garage_id: 151,
		part_id: 389,
		quantity: 121,
	},
	{
		garage_id: 151,
		part_id: 390,
		quantity: 474,
	},
	{
		garage_id: 151,
		part_id: 391,
		quantity: 405,
	},
	{
		garage_id: 151,
		part_id: 392,
		quantity: 377,
	},
	{
		garage_id: 151,
		part_id: 393,
		quantity: 258,
	},
	{
		garage_id: 151,
		part_id: 394,
		quantity: 390,
	},
	{
		garage_id: 151,
		part_id: 395,
		quantity: 203,
	},
	{
		garage_id: 151,
		part_id: 396,
		quantity: 419,
	},
	{
		garage_id: 151,
		part_id: 397,
		quantity: 399,
	},
	{
		garage_id: 151,
		part_id: 398,
		quantity: 250,
	},
	{
		garage_id: 151,
		part_id: 399,
		quantity: 135,
	},
	{
		garage_id: 152,
		part_id: 0,
		quantity: 233,
	},
	{
		garage_id: 152,
		part_id: 1,
		quantity: 371,
	},
	{
		garage_id: 152,
		part_id: 2,
		quantity: 329,
	},
	{
		garage_id: 152,
		part_id: 3,
		quantity: 472,
	},
	{
		garage_id: 152,
		part_id: 4,
		quantity: 55,
	},
	{
		garage_id: 152,
		part_id: 5,
		quantity: 441,
	},
	{
		garage_id: 152,
		part_id: 6,
		quantity: 216,
	},
	{
		garage_id: 152,
		part_id: 7,
		quantity: 185,
	},
	{
		garage_id: 152,
		part_id: 8,
		quantity: 409,
	},
	{
		garage_id: 152,
		part_id: 9,
		quantity: 118,
	},
	{
		garage_id: 152,
		part_id: 10,
		quantity: 386,
	},
	{
		garage_id: 152,
		part_id: 11,
		quantity: 454,
	},
	{
		garage_id: 152,
		part_id: 12,
		quantity: 272,
	},
	{
		garage_id: 152,
		part_id: 13,
		quantity: 1,
	},
	{
		garage_id: 152,
		part_id: 14,
		quantity: 259,
	},
	{
		garage_id: 152,
		part_id: 15,
		quantity: 171,
	},
	{
		garage_id: 152,
		part_id: 16,
		quantity: 308,
	},
	{
		garage_id: 152,
		part_id: 17,
		quantity: 24,
	},
	{
		garage_id: 152,
		part_id: 18,
		quantity: 472,
	},
	{
		garage_id: 152,
		part_id: 19,
		quantity: 379,
	},
	{
		garage_id: 152,
		part_id: 20,
		quantity: 143,
	},
	{
		garage_id: 152,
		part_id: 21,
		quantity: 33,
	},
	{
		garage_id: 152,
		part_id: 22,
		quantity: 303,
	},
	{
		garage_id: 152,
		part_id: 23,
		quantity: 429,
	},
	{
		garage_id: 152,
		part_id: 24,
		quantity: 169,
	},
	{
		garage_id: 152,
		part_id: 25,
		quantity: 185,
	},
	{
		garage_id: 152,
		part_id: 26,
		quantity: 385,
	},
	{
		garage_id: 152,
		part_id: 27,
		quantity: 225,
	},
	{
		garage_id: 152,
		part_id: 28,
		quantity: 396,
	},
	{
		garage_id: 152,
		part_id: 29,
		quantity: 111,
	},
	{
		garage_id: 152,
		part_id: 30,
		quantity: 209,
	},
	{
		garage_id: 152,
		part_id: 31,
		quantity: 294,
	},
	{
		garage_id: 152,
		part_id: 32,
		quantity: 456,
	},
	{
		garage_id: 152,
		part_id: 33,
		quantity: 253,
	},
	{
		garage_id: 152,
		part_id: 34,
		quantity: 440,
	},
	{
		garage_id: 152,
		part_id: 35,
		quantity: 39,
	},
	{
		garage_id: 152,
		part_id: 36,
		quantity: 267,
	},
	{
		garage_id: 152,
		part_id: 37,
		quantity: 24,
	},
	{
		garage_id: 152,
		part_id: 38,
		quantity: 391,
	},
	{
		garage_id: 152,
		part_id: 39,
		quantity: 44,
	},
	{
		garage_id: 152,
		part_id: 40,
		quantity: 351,
	},
	{
		garage_id: 152,
		part_id: 41,
		quantity: 362,
	},
	{
		garage_id: 152,
		part_id: 42,
		quantity: 272,
	},
	{
		garage_id: 152,
		part_id: 43,
		quantity: 458,
	},
	{
		garage_id: 152,
		part_id: 44,
		quantity: 199,
	},
	{
		garage_id: 152,
		part_id: 45,
		quantity: 0,
	},
	{
		garage_id: 152,
		part_id: 46,
		quantity: 348,
	},
	{
		garage_id: 152,
		part_id: 47,
		quantity: 343,
	},
	{
		garage_id: 152,
		part_id: 48,
		quantity: 251,
	},
	{
		garage_id: 152,
		part_id: 49,
		quantity: 51,
	},
	{
		garage_id: 152,
		part_id: 50,
		quantity: 109,
	},
	{
		garage_id: 152,
		part_id: 51,
		quantity: 341,
	},
	{
		garage_id: 152,
		part_id: 52,
		quantity: 270,
	},
	{
		garage_id: 152,
		part_id: 53,
		quantity: 172,
	},
	{
		garage_id: 152,
		part_id: 54,
		quantity: 430,
	},
	{
		garage_id: 152,
		part_id: 55,
		quantity: 361,
	},
	{
		garage_id: 152,
		part_id: 56,
		quantity: 444,
	},
	{
		garage_id: 152,
		part_id: 57,
		quantity: 479,
	},
	{
		garage_id: 152,
		part_id: 58,
		quantity: 65,
	},
	{
		garage_id: 152,
		part_id: 59,
		quantity: 8,
	},
	{
		garage_id: 152,
		part_id: 60,
		quantity: 147,
	},
	{
		garage_id: 152,
		part_id: 61,
		quantity: 230,
	},
	{
		garage_id: 152,
		part_id: 62,
		quantity: 414,
	},
	{
		garage_id: 152,
		part_id: 63,
		quantity: 341,
	},
	{
		garage_id: 152,
		part_id: 64,
		quantity: 237,
	},
	{
		garage_id: 152,
		part_id: 65,
		quantity: 86,
	},
	{
		garage_id: 152,
		part_id: 66,
		quantity: 422,
	},
	{
		garage_id: 152,
		part_id: 67,
		quantity: 83,
	},
	{
		garage_id: 152,
		part_id: 68,
		quantity: 295,
	},
	{
		garage_id: 152,
		part_id: 69,
		quantity: 240,
	},
	{
		garage_id: 152,
		part_id: 70,
		quantity: 177,
	},
	{
		garage_id: 152,
		part_id: 71,
		quantity: 353,
	},
	{
		garage_id: 152,
		part_id: 72,
		quantity: 379,
	},
	{
		garage_id: 152,
		part_id: 73,
		quantity: 417,
	},
	{
		garage_id: 152,
		part_id: 74,
		quantity: 236,
	},
	{
		garage_id: 152,
		part_id: 75,
		quantity: 318,
	},
	{
		garage_id: 152,
		part_id: 76,
		quantity: 474,
	},
	{
		garage_id: 152,
		part_id: 77,
		quantity: 202,
	},
	{
		garage_id: 152,
		part_id: 78,
		quantity: 80,
	},
	{
		garage_id: 152,
		part_id: 79,
		quantity: 95,
	},
	{
		garage_id: 152,
		part_id: 80,
		quantity: 454,
	},
	{
		garage_id: 152,
		part_id: 81,
		quantity: 152,
	},
	{
		garage_id: 152,
		part_id: 82,
		quantity: 160,
	},
	{
		garage_id: 152,
		part_id: 83,
		quantity: 339,
	},
	{
		garage_id: 152,
		part_id: 84,
		quantity: 341,
	},
	{
		garage_id: 152,
		part_id: 85,
		quantity: 355,
	},
	{
		garage_id: 152,
		part_id: 86,
		quantity: 188,
	},
	{
		garage_id: 152,
		part_id: 87,
		quantity: 359,
	},
	{
		garage_id: 152,
		part_id: 88,
		quantity: 281,
	},
	{
		garage_id: 152,
		part_id: 89,
		quantity: 433,
	},
	{
		garage_id: 152,
		part_id: 90,
		quantity: 121,
	},
	{
		garage_id: 152,
		part_id: 91,
		quantity: 309,
	},
	{
		garage_id: 152,
		part_id: 92,
		quantity: 454,
	},
	{
		garage_id: 152,
		part_id: 93,
		quantity: 301,
	},
	{
		garage_id: 152,
		part_id: 94,
		quantity: 279,
	},
	{
		garage_id: 152,
		part_id: 95,
		quantity: 168,
	},
	{
		garage_id: 152,
		part_id: 96,
		quantity: 220,
	},
	{
		garage_id: 152,
		part_id: 97,
		quantity: 145,
	},
	{
		garage_id: 152,
		part_id: 98,
		quantity: 46,
	},
	{
		garage_id: 152,
		part_id: 99,
		quantity: 495,
	},
	{
		garage_id: 152,
		part_id: 100,
		quantity: 297,
	},
	{
		garage_id: 152,
		part_id: 101,
		quantity: 38,
	},
	{
		garage_id: 152,
		part_id: 102,
		quantity: 105,
	},
	{
		garage_id: 152,
		part_id: 103,
		quantity: 119,
	},
	{
		garage_id: 152,
		part_id: 104,
		quantity: 296,
	},
	{
		garage_id: 152,
		part_id: 105,
		quantity: 75,
	},
	{
		garage_id: 152,
		part_id: 106,
		quantity: 194,
	},
	{
		garage_id: 152,
		part_id: 107,
		quantity: 132,
	},
	{
		garage_id: 152,
		part_id: 108,
		quantity: 87,
	},
	{
		garage_id: 152,
		part_id: 109,
		quantity: 202,
	},
	{
		garage_id: 152,
		part_id: 110,
		quantity: 491,
	},
	{
		garage_id: 152,
		part_id: 111,
		quantity: 272,
	},
	{
		garage_id: 152,
		part_id: 112,
		quantity: 223,
	},
	{
		garage_id: 152,
		part_id: 113,
		quantity: 7,
	},
	{
		garage_id: 152,
		part_id: 114,
		quantity: 135,
	},
	{
		garage_id: 152,
		part_id: 115,
		quantity: 421,
	},
	{
		garage_id: 152,
		part_id: 116,
		quantity: 68,
	},
	{
		garage_id: 152,
		part_id: 117,
		quantity: 117,
	},
	{
		garage_id: 152,
		part_id: 118,
		quantity: 455,
	},
	{
		garage_id: 152,
		part_id: 119,
		quantity: 371,
	},
	{
		garage_id: 152,
		part_id: 120,
		quantity: 316,
	},
	{
		garage_id: 152,
		part_id: 121,
		quantity: 393,
	},
	{
		garage_id: 152,
		part_id: 122,
		quantity: 265,
	},
	{
		garage_id: 152,
		part_id: 123,
		quantity: 348,
	},
	{
		garage_id: 152,
		part_id: 124,
		quantity: 331,
	},
	{
		garage_id: 152,
		part_id: 125,
		quantity: 459,
	},
	{
		garage_id: 152,
		part_id: 126,
		quantity: 313,
	},
	{
		garage_id: 152,
		part_id: 127,
		quantity: 444,
	},
	{
		garage_id: 152,
		part_id: 128,
		quantity: 300,
	},
	{
		garage_id: 152,
		part_id: 129,
		quantity: 401,
	},
	{
		garage_id: 152,
		part_id: 130,
		quantity: 59,
	},
	{
		garage_id: 152,
		part_id: 131,
		quantity: 141,
	},
	{
		garage_id: 152,
		part_id: 132,
		quantity: 126,
	},
	{
		garage_id: 152,
		part_id: 133,
		quantity: 396,
	},
	{
		garage_id: 152,
		part_id: 134,
		quantity: 147,
	},
	{
		garage_id: 152,
		part_id: 135,
		quantity: 269,
	},
	{
		garage_id: 152,
		part_id: 136,
		quantity: 2,
	},
	{
		garage_id: 152,
		part_id: 137,
		quantity: 292,
	},
	{
		garage_id: 152,
		part_id: 138,
		quantity: 473,
	},
	{
		garage_id: 152,
		part_id: 139,
		quantity: 172,
	},
	{
		garage_id: 152,
		part_id: 140,
		quantity: 132,
	},
	{
		garage_id: 152,
		part_id: 141,
		quantity: 170,
	},
	{
		garage_id: 152,
		part_id: 142,
		quantity: 359,
	},
	{
		garage_id: 152,
		part_id: 143,
		quantity: 391,
	},
	{
		garage_id: 152,
		part_id: 144,
		quantity: 61,
	},
	{
		garage_id: 152,
		part_id: 145,
		quantity: 168,
	},
	{
		garage_id: 152,
		part_id: 146,
		quantity: 211,
	},
	{
		garage_id: 152,
		part_id: 147,
		quantity: 168,
	},
	{
		garage_id: 152,
		part_id: 148,
		quantity: 355,
	},
	{
		garage_id: 152,
		part_id: 149,
		quantity: 265,
	},
	{
		garage_id: 152,
		part_id: 150,
		quantity: 156,
	},
	{
		garage_id: 152,
		part_id: 151,
		quantity: 115,
	},
	{
		garage_id: 152,
		part_id: 152,
		quantity: 262,
	},
	{
		garage_id: 152,
		part_id: 153,
		quantity: 284,
	},
	{
		garage_id: 152,
		part_id: 154,
		quantity: 365,
	},
	{
		garage_id: 152,
		part_id: 155,
		quantity: 145,
	},
	{
		garage_id: 152,
		part_id: 156,
		quantity: 36,
	},
	{
		garage_id: 152,
		part_id: 157,
		quantity: 3,
	},
	{
		garage_id: 152,
		part_id: 158,
		quantity: 184,
	},
	{
		garage_id: 152,
		part_id: 159,
		quantity: 167,
	},
	{
		garage_id: 152,
		part_id: 160,
		quantity: 158,
	},
	{
		garage_id: 152,
		part_id: 161,
		quantity: 486,
	},
	{
		garage_id: 152,
		part_id: 162,
		quantity: 335,
	},
	{
		garage_id: 152,
		part_id: 163,
		quantity: 19,
	},
	{
		garage_id: 152,
		part_id: 164,
		quantity: 281,
	},
	{
		garage_id: 152,
		part_id: 165,
		quantity: 414,
	},
	{
		garage_id: 152,
		part_id: 166,
		quantity: 237,
	},
	{
		garage_id: 152,
		part_id: 167,
		quantity: 458,
	},
	{
		garage_id: 152,
		part_id: 168,
		quantity: 372,
	},
	{
		garage_id: 152,
		part_id: 169,
		quantity: 455,
	},
	{
		garage_id: 152,
		part_id: 170,
		quantity: 217,
	},
	{
		garage_id: 152,
		part_id: 171,
		quantity: 393,
	},
	{
		garage_id: 152,
		part_id: 172,
		quantity: 250,
	},
	{
		garage_id: 152,
		part_id: 173,
		quantity: 467,
	},
	{
		garage_id: 152,
		part_id: 174,
		quantity: 475,
	},
	{
		garage_id: 152,
		part_id: 175,
		quantity: 141,
	},
	{
		garage_id: 152,
		part_id: 176,
		quantity: 499,
	},
	{
		garage_id: 152,
		part_id: 177,
		quantity: 364,
	},
	{
		garage_id: 152,
		part_id: 178,
		quantity: 183,
	},
	{
		garage_id: 152,
		part_id: 179,
		quantity: 292,
	},
	{
		garage_id: 152,
		part_id: 180,
		quantity: 315,
	},
	{
		garage_id: 152,
		part_id: 181,
		quantity: 300,
	},
	{
		garage_id: 152,
		part_id: 182,
		quantity: 14,
	},
	{
		garage_id: 152,
		part_id: 183,
		quantity: 82,
	},
	{
		garage_id: 152,
		part_id: 184,
		quantity: 491,
	},
	{
		garage_id: 152,
		part_id: 185,
		quantity: 237,
	},
	{
		garage_id: 152,
		part_id: 186,
		quantity: 25,
	},
	{
		garage_id: 152,
		part_id: 187,
		quantity: 419,
	},
	{
		garage_id: 152,
		part_id: 188,
		quantity: 69,
	},
	{
		garage_id: 152,
		part_id: 189,
		quantity: 146,
	},
	{
		garage_id: 152,
		part_id: 190,
		quantity: 327,
	},
	{
		garage_id: 152,
		part_id: 191,
		quantity: 461,
	},
	{
		garage_id: 152,
		part_id: 192,
		quantity: 177,
	},
	{
		garage_id: 152,
		part_id: 193,
		quantity: 201,
	},
	{
		garage_id: 152,
		part_id: 194,
		quantity: 72,
	},
	{
		garage_id: 152,
		part_id: 195,
		quantity: 79,
	},
	{
		garage_id: 152,
		part_id: 196,
		quantity: 357,
	},
	{
		garage_id: 152,
		part_id: 197,
		quantity: 172,
	},
	{
		garage_id: 152,
		part_id: 198,
		quantity: 300,
	},
	{
		garage_id: 152,
		part_id: 199,
		quantity: 332,
	},
	{
		garage_id: 152,
		part_id: 200,
		quantity: 79,
	},
	{
		garage_id: 152,
		part_id: 201,
		quantity: 214,
	},
	{
		garage_id: 152,
		part_id: 202,
		quantity: 372,
	},
	{
		garage_id: 152,
		part_id: 203,
		quantity: 435,
	},
	{
		garage_id: 152,
		part_id: 204,
		quantity: 412,
	},
	{
		garage_id: 152,
		part_id: 205,
		quantity: 498,
	},
	{
		garage_id: 152,
		part_id: 206,
		quantity: 439,
	},
	{
		garage_id: 152,
		part_id: 207,
		quantity: 369,
	},
	{
		garage_id: 152,
		part_id: 208,
		quantity: 430,
	},
	{
		garage_id: 152,
		part_id: 209,
		quantity: 382,
	},
	{
		garage_id: 152,
		part_id: 210,
		quantity: 400,
	},
	{
		garage_id: 152,
		part_id: 211,
		quantity: 416,
	},
	{
		garage_id: 152,
		part_id: 212,
		quantity: 124,
	},
	{
		garage_id: 152,
		part_id: 213,
		quantity: 474,
	},
	{
		garage_id: 152,
		part_id: 214,
		quantity: 154,
	},
	{
		garage_id: 152,
		part_id: 215,
		quantity: 318,
	},
	{
		garage_id: 152,
		part_id: 216,
		quantity: 251,
	},
	{
		garage_id: 152,
		part_id: 217,
		quantity: 44,
	},
	{
		garage_id: 152,
		part_id: 218,
		quantity: 165,
	},
	{
		garage_id: 152,
		part_id: 219,
		quantity: 168,
	},
	{
		garage_id: 152,
		part_id: 220,
		quantity: 355,
	},
	{
		garage_id: 152,
		part_id: 221,
		quantity: 37,
	},
	{
		garage_id: 152,
		part_id: 222,
		quantity: 101,
	},
	{
		garage_id: 152,
		part_id: 223,
		quantity: 138,
	},
	{
		garage_id: 152,
		part_id: 224,
		quantity: 388,
	},
	{
		garage_id: 152,
		part_id: 225,
		quantity: 420,
	},
	{
		garage_id: 152,
		part_id: 226,
		quantity: 442,
	},
	{
		garage_id: 152,
		part_id: 227,
		quantity: 478,
	},
	{
		garage_id: 152,
		part_id: 228,
		quantity: 165,
	},
	{
		garage_id: 152,
		part_id: 229,
		quantity: 47,
	},
	{
		garage_id: 152,
		part_id: 230,
		quantity: 260,
	},
	{
		garage_id: 152,
		part_id: 231,
		quantity: 463,
	},
	{
		garage_id: 152,
		part_id: 232,
		quantity: 1,
	},
	{
		garage_id: 152,
		part_id: 233,
		quantity: 248,
	},
	{
		garage_id: 152,
		part_id: 234,
		quantity: 212,
	},
	{
		garage_id: 152,
		part_id: 235,
		quantity: 41,
	},
	{
		garage_id: 152,
		part_id: 236,
		quantity: 415,
	},
	{
		garage_id: 152,
		part_id: 237,
		quantity: 253,
	},
	{
		garage_id: 152,
		part_id: 238,
		quantity: 251,
	},
	{
		garage_id: 152,
		part_id: 239,
		quantity: 305,
	},
	{
		garage_id: 152,
		part_id: 240,
		quantity: 213,
	},
	{
		garage_id: 152,
		part_id: 241,
		quantity: 259,
	},
	{
		garage_id: 152,
		part_id: 242,
		quantity: 335,
	},
	{
		garage_id: 152,
		part_id: 243,
		quantity: 19,
	},
	{
		garage_id: 152,
		part_id: 244,
		quantity: 30,
	},
	{
		garage_id: 152,
		part_id: 245,
		quantity: 9,
	},
	{
		garage_id: 152,
		part_id: 246,
		quantity: 71,
	},
	{
		garage_id: 152,
		part_id: 247,
		quantity: 174,
	},
	{
		garage_id: 152,
		part_id: 248,
		quantity: 471,
	},
	{
		garage_id: 152,
		part_id: 249,
		quantity: 77,
	},
	{
		garage_id: 152,
		part_id: 250,
		quantity: 398,
	},
	{
		garage_id: 152,
		part_id: 251,
		quantity: 64,
	},
	{
		garage_id: 152,
		part_id: 252,
		quantity: 450,
	},
	{
		garage_id: 152,
		part_id: 253,
		quantity: 74,
	},
	{
		garage_id: 152,
		part_id: 254,
		quantity: 221,
	},
	{
		garage_id: 152,
		part_id: 255,
		quantity: 209,
	},
	{
		garage_id: 152,
		part_id: 256,
		quantity: 134,
	},
	{
		garage_id: 152,
		part_id: 257,
		quantity: 391,
	},
	{
		garage_id: 152,
		part_id: 258,
		quantity: 303,
	},
	{
		garage_id: 152,
		part_id: 259,
		quantity: 250,
	},
	{
		garage_id: 152,
		part_id: 260,
		quantity: 319,
	},
	{
		garage_id: 152,
		part_id: 261,
		quantity: 260,
	},
	{
		garage_id: 152,
		part_id: 262,
		quantity: 212,
	},
	{
		garage_id: 152,
		part_id: 263,
		quantity: 426,
	},
	{
		garage_id: 152,
		part_id: 264,
		quantity: 369,
	},
	{
		garage_id: 152,
		part_id: 265,
		quantity: 157,
	},
	{
		garage_id: 152,
		part_id: 266,
		quantity: 290,
	},
	{
		garage_id: 152,
		part_id: 267,
		quantity: 76,
	},
	{
		garage_id: 152,
		part_id: 268,
		quantity: 155,
	},
	{
		garage_id: 152,
		part_id: 269,
		quantity: 217,
	},
	{
		garage_id: 152,
		part_id: 270,
		quantity: 164,
	},
	{
		garage_id: 152,
		part_id: 271,
		quantity: 478,
	},
	{
		garage_id: 152,
		part_id: 272,
		quantity: 319,
	},
	{
		garage_id: 152,
		part_id: 273,
		quantity: 351,
	},
	{
		garage_id: 152,
		part_id: 274,
		quantity: 214,
	},
	{
		garage_id: 152,
		part_id: 275,
		quantity: 146,
	},
	{
		garage_id: 152,
		part_id: 276,
		quantity: 173,
	},
	{
		garage_id: 152,
		part_id: 277,
		quantity: 212,
	},
	{
		garage_id: 152,
		part_id: 278,
		quantity: 398,
	},
	{
		garage_id: 152,
		part_id: 279,
		quantity: 24,
	},
	{
		garage_id: 152,
		part_id: 280,
		quantity: 2,
	},
	{
		garage_id: 152,
		part_id: 281,
		quantity: 35,
	},
	{
		garage_id: 152,
		part_id: 282,
		quantity: 163,
	},
	{
		garage_id: 152,
		part_id: 283,
		quantity: 476,
	},
	{
		garage_id: 152,
		part_id: 284,
		quantity: 183,
	},
	{
		garage_id: 152,
		part_id: 285,
		quantity: 278,
	},
	{
		garage_id: 152,
		part_id: 286,
		quantity: 54,
	},
	{
		garage_id: 152,
		part_id: 287,
		quantity: 188,
	},
	{
		garage_id: 152,
		part_id: 288,
		quantity: 162,
	},
	{
		garage_id: 152,
		part_id: 289,
		quantity: 215,
	},
	{
		garage_id: 152,
		part_id: 290,
		quantity: 411,
	},
	{
		garage_id: 152,
		part_id: 291,
		quantity: 13,
	},
	{
		garage_id: 152,
		part_id: 292,
		quantity: 20,
	},
	{
		garage_id: 152,
		part_id: 293,
		quantity: 244,
	},
	{
		garage_id: 152,
		part_id: 294,
		quantity: 302,
	},
	{
		garage_id: 152,
		part_id: 295,
		quantity: 7,
	},
	{
		garage_id: 152,
		part_id: 296,
		quantity: 223,
	},
	{
		garage_id: 152,
		part_id: 297,
		quantity: 206,
	},
	{
		garage_id: 152,
		part_id: 298,
		quantity: 318,
	},
	{
		garage_id: 152,
		part_id: 299,
		quantity: 290,
	},
	{
		garage_id: 152,
		part_id: 300,
		quantity: 362,
	},
	{
		garage_id: 152,
		part_id: 301,
		quantity: 343,
	},
	{
		garage_id: 152,
		part_id: 302,
		quantity: 194,
	},
	{
		garage_id: 152,
		part_id: 303,
		quantity: 357,
	},
	{
		garage_id: 152,
		part_id: 304,
		quantity: 306,
	},
	{
		garage_id: 152,
		part_id: 305,
		quantity: 499,
	},
	{
		garage_id: 152,
		part_id: 306,
		quantity: 183,
	},
	{
		garage_id: 152,
		part_id: 307,
		quantity: 227,
	},
	{
		garage_id: 152,
		part_id: 308,
		quantity: 482,
	},
	{
		garage_id: 152,
		part_id: 309,
		quantity: 332,
	},
	{
		garage_id: 152,
		part_id: 310,
		quantity: 257,
	},
	{
		garage_id: 152,
		part_id: 311,
		quantity: 82,
	},
	{
		garage_id: 152,
		part_id: 312,
		quantity: 424,
	},
	{
		garage_id: 152,
		part_id: 313,
		quantity: 295,
	},
	{
		garage_id: 152,
		part_id: 314,
		quantity: 183,
	},
	{
		garage_id: 152,
		part_id: 315,
		quantity: 55,
	},
	{
		garage_id: 152,
		part_id: 316,
		quantity: 97,
	},
	{
		garage_id: 152,
		part_id: 317,
		quantity: 112,
	},
	{
		garage_id: 152,
		part_id: 318,
		quantity: 132,
	},
	{
		garage_id: 152,
		part_id: 319,
		quantity: 303,
	},
	{
		garage_id: 152,
		part_id: 320,
		quantity: 469,
	},
	{
		garage_id: 152,
		part_id: 321,
		quantity: 406,
	},
	{
		garage_id: 152,
		part_id: 322,
		quantity: 43,
	},
	{
		garage_id: 152,
		part_id: 323,
		quantity: 211,
	},
	{
		garage_id: 152,
		part_id: 324,
		quantity: 355,
	},
	{
		garage_id: 152,
		part_id: 325,
		quantity: 429,
	},
	{
		garage_id: 152,
		part_id: 326,
		quantity: 275,
	},
	{
		garage_id: 152,
		part_id: 327,
		quantity: 164,
	},
	{
		garage_id: 152,
		part_id: 328,
		quantity: 101,
	},
	{
		garage_id: 152,
		part_id: 329,
		quantity: 277,
	},
	{
		garage_id: 152,
		part_id: 330,
		quantity: 276,
	},
	{
		garage_id: 152,
		part_id: 331,
		quantity: 74,
	},
	{
		garage_id: 152,
		part_id: 332,
		quantity: 256,
	},
	{
		garage_id: 152,
		part_id: 333,
		quantity: 21,
	},
	{
		garage_id: 152,
		part_id: 334,
		quantity: 263,
	},
	{
		garage_id: 152,
		part_id: 335,
		quantity: 88,
	},
	{
		garage_id: 152,
		part_id: 336,
		quantity: 49,
	},
	{
		garage_id: 152,
		part_id: 337,
		quantity: 128,
	},
	{
		garage_id: 152,
		part_id: 338,
		quantity: 61,
	},
	{
		garage_id: 152,
		part_id: 339,
		quantity: 460,
	},
	{
		garage_id: 152,
		part_id: 340,
		quantity: 222,
	},
	{
		garage_id: 152,
		part_id: 341,
		quantity: 264,
	},
	{
		garage_id: 152,
		part_id: 342,
		quantity: 256,
	},
	{
		garage_id: 152,
		part_id: 343,
		quantity: 495,
	},
	{
		garage_id: 152,
		part_id: 344,
		quantity: 329,
	},
	{
		garage_id: 152,
		part_id: 345,
		quantity: 185,
	},
	{
		garage_id: 152,
		part_id: 346,
		quantity: 275,
	},
	{
		garage_id: 152,
		part_id: 347,
		quantity: 498,
	},
	{
		garage_id: 152,
		part_id: 348,
		quantity: 273,
	},
	{
		garage_id: 152,
		part_id: 349,
		quantity: 490,
	},
	{
		garage_id: 152,
		part_id: 350,
		quantity: 115,
	},
	{
		garage_id: 152,
		part_id: 351,
		quantity: 486,
	},
	{
		garage_id: 152,
		part_id: 352,
		quantity: 396,
	},
	{
		garage_id: 152,
		part_id: 353,
		quantity: 229,
	},
	{
		garage_id: 152,
		part_id: 354,
		quantity: 222,
	},
	{
		garage_id: 152,
		part_id: 355,
		quantity: 111,
	},
	{
		garage_id: 152,
		part_id: 356,
		quantity: 384,
	},
	{
		garage_id: 152,
		part_id: 357,
		quantity: 208,
	},
	{
		garage_id: 152,
		part_id: 358,
		quantity: 392,
	},
	{
		garage_id: 152,
		part_id: 359,
		quantity: 233,
	},
	{
		garage_id: 152,
		part_id: 360,
		quantity: 83,
	},
	{
		garage_id: 152,
		part_id: 361,
		quantity: 421,
	},
	{
		garage_id: 152,
		part_id: 362,
		quantity: 353,
	},
	{
		garage_id: 152,
		part_id: 363,
		quantity: 122,
	},
	{
		garage_id: 152,
		part_id: 364,
		quantity: 456,
	},
	{
		garage_id: 152,
		part_id: 365,
		quantity: 162,
	},
	{
		garage_id: 152,
		part_id: 366,
		quantity: 108,
	},
	{
		garage_id: 152,
		part_id: 367,
		quantity: 48,
	},
	{
		garage_id: 152,
		part_id: 368,
		quantity: 248,
	},
	{
		garage_id: 152,
		part_id: 369,
		quantity: 79,
	},
	{
		garage_id: 152,
		part_id: 370,
		quantity: 38,
	},
	{
		garage_id: 152,
		part_id: 371,
		quantity: 496,
	},
	{
		garage_id: 152,
		part_id: 372,
		quantity: 369,
	},
	{
		garage_id: 152,
		part_id: 373,
		quantity: 409,
	},
	{
		garage_id: 152,
		part_id: 374,
		quantity: 409,
	},
	{
		garage_id: 152,
		part_id: 375,
		quantity: 324,
	},
	{
		garage_id: 152,
		part_id: 376,
		quantity: 120,
	},
	{
		garage_id: 152,
		part_id: 377,
		quantity: 37,
	},
	{
		garage_id: 152,
		part_id: 378,
		quantity: 34,
	},
	{
		garage_id: 152,
		part_id: 379,
		quantity: 4,
	},
	{
		garage_id: 152,
		part_id: 380,
		quantity: 230,
	},
	{
		garage_id: 152,
		part_id: 381,
		quantity: 228,
	},
	{
		garage_id: 152,
		part_id: 382,
		quantity: 26,
	},
	{
		garage_id: 152,
		part_id: 383,
		quantity: 41,
	},
	{
		garage_id: 152,
		part_id: 384,
		quantity: 483,
	},
	{
		garage_id: 152,
		part_id: 385,
		quantity: 121,
	},
	{
		garage_id: 152,
		part_id: 386,
		quantity: 311,
	},
	{
		garage_id: 152,
		part_id: 387,
		quantity: 42,
	},
	{
		garage_id: 152,
		part_id: 388,
		quantity: 31,
	},
	{
		garage_id: 152,
		part_id: 389,
		quantity: 34,
	},
	{
		garage_id: 152,
		part_id: 390,
		quantity: 125,
	},
	{
		garage_id: 152,
		part_id: 391,
		quantity: 486,
	},
	{
		garage_id: 152,
		part_id: 392,
		quantity: 129,
	},
	{
		garage_id: 152,
		part_id: 393,
		quantity: 466,
	},
	{
		garage_id: 152,
		part_id: 394,
		quantity: 489,
	},
	{
		garage_id: 152,
		part_id: 395,
		quantity: 348,
	},
	{
		garage_id: 152,
		part_id: 396,
		quantity: 73,
	},
	{
		garage_id: 152,
		part_id: 397,
		quantity: 427,
	},
	{
		garage_id: 152,
		part_id: 398,
		quantity: 369,
	},
	{
		garage_id: 152,
		part_id: 399,
		quantity: 128,
	},
	{
		garage_id: 153,
		part_id: 0,
		quantity: 472,
	},
	{
		garage_id: 153,
		part_id: 1,
		quantity: 270,
	},
	{
		garage_id: 153,
		part_id: 2,
		quantity: 355,
	},
	{
		garage_id: 153,
		part_id: 3,
		quantity: 130,
	},
	{
		garage_id: 153,
		part_id: 4,
		quantity: 223,
	},
	{
		garage_id: 153,
		part_id: 5,
		quantity: 391,
	},
	{
		garage_id: 153,
		part_id: 6,
		quantity: 30,
	},
	{
		garage_id: 153,
		part_id: 7,
		quantity: 94,
	},
	{
		garage_id: 153,
		part_id: 8,
		quantity: 358,
	},
	{
		garage_id: 153,
		part_id: 9,
		quantity: 323,
	},
	{
		garage_id: 153,
		part_id: 10,
		quantity: 102,
	},
	{
		garage_id: 153,
		part_id: 11,
		quantity: 257,
	},
	{
		garage_id: 153,
		part_id: 12,
		quantity: 410,
	},
	{
		garage_id: 153,
		part_id: 13,
		quantity: 78,
	},
	{
		garage_id: 153,
		part_id: 14,
		quantity: 130,
	},
	{
		garage_id: 153,
		part_id: 15,
		quantity: 162,
	},
	{
		garage_id: 153,
		part_id: 16,
		quantity: 83,
	},
	{
		garage_id: 153,
		part_id: 17,
		quantity: 367,
	},
	{
		garage_id: 153,
		part_id: 18,
		quantity: 68,
	},
	{
		garage_id: 153,
		part_id: 19,
		quantity: 116,
	},
	{
		garage_id: 153,
		part_id: 20,
		quantity: 413,
	},
	{
		garage_id: 153,
		part_id: 21,
		quantity: 211,
	},
	{
		garage_id: 153,
		part_id: 22,
		quantity: 483,
	},
	{
		garage_id: 153,
		part_id: 23,
		quantity: 287,
	},
	{
		garage_id: 153,
		part_id: 24,
		quantity: 390,
	},
	{
		garage_id: 153,
		part_id: 25,
		quantity: 221,
	},
	{
		garage_id: 153,
		part_id: 26,
		quantity: 317,
	},
	{
		garage_id: 153,
		part_id: 27,
		quantity: 5,
	},
	{
		garage_id: 153,
		part_id: 28,
		quantity: 464,
	},
	{
		garage_id: 153,
		part_id: 29,
		quantity: 490,
	},
	{
		garage_id: 153,
		part_id: 30,
		quantity: 341,
	},
	{
		garage_id: 153,
		part_id: 31,
		quantity: 329,
	},
	{
		garage_id: 153,
		part_id: 32,
		quantity: 340,
	},
	{
		garage_id: 153,
		part_id: 33,
		quantity: 27,
	},
	{
		garage_id: 153,
		part_id: 34,
		quantity: 468,
	},
	{
		garage_id: 153,
		part_id: 35,
		quantity: 476,
	},
	{
		garage_id: 153,
		part_id: 36,
		quantity: 474,
	},
	{
		garage_id: 153,
		part_id: 37,
		quantity: 171,
	},
	{
		garage_id: 153,
		part_id: 38,
		quantity: 463,
	},
	{
		garage_id: 153,
		part_id: 39,
		quantity: 366,
	},
	{
		garage_id: 153,
		part_id: 40,
		quantity: 181,
	},
	{
		garage_id: 153,
		part_id: 41,
		quantity: 169,
	},
	{
		garage_id: 153,
		part_id: 42,
		quantity: 191,
	},
	{
		garage_id: 153,
		part_id: 43,
		quantity: 361,
	},
	{
		garage_id: 153,
		part_id: 44,
		quantity: 102,
	},
	{
		garage_id: 153,
		part_id: 45,
		quantity: 324,
	},
	{
		garage_id: 153,
		part_id: 46,
		quantity: 245,
	},
	{
		garage_id: 153,
		part_id: 47,
		quantity: 323,
	},
	{
		garage_id: 153,
		part_id: 48,
		quantity: 319,
	},
	{
		garage_id: 153,
		part_id: 49,
		quantity: 347,
	},
	{
		garage_id: 153,
		part_id: 50,
		quantity: 158,
	},
	{
		garage_id: 153,
		part_id: 51,
		quantity: 257,
	},
	{
		garage_id: 153,
		part_id: 52,
		quantity: 340,
	},
	{
		garage_id: 153,
		part_id: 53,
		quantity: 317,
	},
	{
		garage_id: 153,
		part_id: 54,
		quantity: 478,
	},
	{
		garage_id: 153,
		part_id: 55,
		quantity: 223,
	},
	{
		garage_id: 153,
		part_id: 56,
		quantity: 306,
	},
	{
		garage_id: 153,
		part_id: 57,
		quantity: 409,
	},
	{
		garage_id: 153,
		part_id: 58,
		quantity: 295,
	},
	{
		garage_id: 153,
		part_id: 59,
		quantity: 117,
	},
	{
		garage_id: 153,
		part_id: 60,
		quantity: 2,
	},
	{
		garage_id: 153,
		part_id: 61,
		quantity: 163,
	},
	{
		garage_id: 153,
		part_id: 62,
		quantity: 400,
	},
	{
		garage_id: 153,
		part_id: 63,
		quantity: 217,
	},
	{
		garage_id: 153,
		part_id: 64,
		quantity: 270,
	},
	{
		garage_id: 153,
		part_id: 65,
		quantity: 261,
	},
	{
		garage_id: 153,
		part_id: 66,
		quantity: 78,
	},
	{
		garage_id: 153,
		part_id: 67,
		quantity: 422,
	},
	{
		garage_id: 153,
		part_id: 68,
		quantity: 47,
	},
	{
		garage_id: 153,
		part_id: 69,
		quantity: 222,
	},
	{
		garage_id: 153,
		part_id: 70,
		quantity: 405,
	},
	{
		garage_id: 153,
		part_id: 71,
		quantity: 264,
	},
	{
		garage_id: 153,
		part_id: 72,
		quantity: 7,
	},
	{
		garage_id: 153,
		part_id: 73,
		quantity: 410,
	},
	{
		garage_id: 153,
		part_id: 74,
		quantity: 273,
	},
	{
		garage_id: 153,
		part_id: 75,
		quantity: 50,
	},
	{
		garage_id: 153,
		part_id: 76,
		quantity: 452,
	},
	{
		garage_id: 153,
		part_id: 77,
		quantity: 340,
	},
	{
		garage_id: 153,
		part_id: 78,
		quantity: 147,
	},
	{
		garage_id: 153,
		part_id: 79,
		quantity: 192,
	},
	{
		garage_id: 153,
		part_id: 80,
		quantity: 364,
	},
	{
		garage_id: 153,
		part_id: 81,
		quantity: 166,
	},
	{
		garage_id: 153,
		part_id: 82,
		quantity: 424,
	},
	{
		garage_id: 153,
		part_id: 83,
		quantity: 286,
	},
	{
		garage_id: 153,
		part_id: 84,
		quantity: 36,
	},
	{
		garage_id: 153,
		part_id: 85,
		quantity: 2,
	},
	{
		garage_id: 153,
		part_id: 86,
		quantity: 33,
	},
	{
		garage_id: 153,
		part_id: 87,
		quantity: 209,
	},
	{
		garage_id: 153,
		part_id: 88,
		quantity: 382,
	},
	{
		garage_id: 153,
		part_id: 89,
		quantity: 392,
	},
	{
		garage_id: 153,
		part_id: 90,
		quantity: 238,
	},
	{
		garage_id: 153,
		part_id: 91,
		quantity: 280,
	},
	{
		garage_id: 153,
		part_id: 92,
		quantity: 86,
	},
	{
		garage_id: 153,
		part_id: 93,
		quantity: 378,
	},
	{
		garage_id: 153,
		part_id: 94,
		quantity: 80,
	},
	{
		garage_id: 153,
		part_id: 95,
		quantity: 75,
	},
	{
		garage_id: 153,
		part_id: 96,
		quantity: 398,
	},
	{
		garage_id: 153,
		part_id: 97,
		quantity: 297,
	},
	{
		garage_id: 153,
		part_id: 98,
		quantity: 244,
	},
	{
		garage_id: 153,
		part_id: 99,
		quantity: 96,
	},
	{
		garage_id: 153,
		part_id: 100,
		quantity: 239,
	},
	{
		garage_id: 153,
		part_id: 101,
		quantity: 107,
	},
	{
		garage_id: 153,
		part_id: 102,
		quantity: 222,
	},
	{
		garage_id: 153,
		part_id: 103,
		quantity: 479,
	},
	{
		garage_id: 153,
		part_id: 104,
		quantity: 484,
	},
	{
		garage_id: 153,
		part_id: 105,
		quantity: 249,
	},
	{
		garage_id: 153,
		part_id: 106,
		quantity: 148,
	},
	{
		garage_id: 153,
		part_id: 107,
		quantity: 424,
	},
	{
		garage_id: 153,
		part_id: 108,
		quantity: 119,
	},
	{
		garage_id: 153,
		part_id: 109,
		quantity: 329,
	},
	{
		garage_id: 153,
		part_id: 110,
		quantity: 397,
	},
	{
		garage_id: 153,
		part_id: 111,
		quantity: 208,
	},
	{
		garage_id: 153,
		part_id: 112,
		quantity: 197,
	},
	{
		garage_id: 153,
		part_id: 113,
		quantity: 451,
	},
	{
		garage_id: 153,
		part_id: 114,
		quantity: 165,
	},
	{
		garage_id: 153,
		part_id: 115,
		quantity: 55,
	},
	{
		garage_id: 153,
		part_id: 116,
		quantity: 437,
	},
	{
		garage_id: 153,
		part_id: 117,
		quantity: 95,
	},
	{
		garage_id: 153,
		part_id: 118,
		quantity: 396,
	},
	{
		garage_id: 153,
		part_id: 119,
		quantity: 67,
	},
	{
		garage_id: 153,
		part_id: 120,
		quantity: 189,
	},
	{
		garage_id: 153,
		part_id: 121,
		quantity: 19,
	},
	{
		garage_id: 153,
		part_id: 122,
		quantity: 206,
	},
	{
		garage_id: 153,
		part_id: 123,
		quantity: 214,
	},
	{
		garage_id: 153,
		part_id: 124,
		quantity: 274,
	},
	{
		garage_id: 153,
		part_id: 125,
		quantity: 364,
	},
	{
		garage_id: 153,
		part_id: 126,
		quantity: 146,
	},
	{
		garage_id: 153,
		part_id: 127,
		quantity: 131,
	},
	{
		garage_id: 153,
		part_id: 128,
		quantity: 188,
	},
	{
		garage_id: 153,
		part_id: 129,
		quantity: 231,
	},
	{
		garage_id: 153,
		part_id: 130,
		quantity: 127,
	},
	{
		garage_id: 153,
		part_id: 131,
		quantity: 339,
	},
	{
		garage_id: 153,
		part_id: 132,
		quantity: 492,
	},
	{
		garage_id: 153,
		part_id: 133,
		quantity: 131,
	},
	{
		garage_id: 153,
		part_id: 134,
		quantity: 154,
	},
	{
		garage_id: 153,
		part_id: 135,
		quantity: 348,
	},
	{
		garage_id: 153,
		part_id: 136,
		quantity: 345,
	},
	{
		garage_id: 153,
		part_id: 137,
		quantity: 450,
	},
	{
		garage_id: 153,
		part_id: 138,
		quantity: 146,
	},
	{
		garage_id: 153,
		part_id: 139,
		quantity: 453,
	},
	{
		garage_id: 153,
		part_id: 140,
		quantity: 84,
	},
	{
		garage_id: 153,
		part_id: 141,
		quantity: 48,
	},
	{
		garage_id: 153,
		part_id: 142,
		quantity: 446,
	},
	{
		garage_id: 153,
		part_id: 143,
		quantity: 103,
	},
	{
		garage_id: 153,
		part_id: 144,
		quantity: 367,
	},
	{
		garage_id: 153,
		part_id: 145,
		quantity: 203,
	},
	{
		garage_id: 153,
		part_id: 146,
		quantity: 246,
	},
	{
		garage_id: 153,
		part_id: 147,
		quantity: 63,
	},
	{
		garage_id: 153,
		part_id: 148,
		quantity: 469,
	},
	{
		garage_id: 153,
		part_id: 149,
		quantity: 245,
	},
	{
		garage_id: 153,
		part_id: 150,
		quantity: 13,
	},
	{
		garage_id: 153,
		part_id: 151,
		quantity: 54,
	},
	{
		garage_id: 153,
		part_id: 152,
		quantity: 256,
	},
	{
		garage_id: 153,
		part_id: 153,
		quantity: 462,
	},
	{
		garage_id: 153,
		part_id: 154,
		quantity: 322,
	},
	{
		garage_id: 153,
		part_id: 155,
		quantity: 127,
	},
	{
		garage_id: 153,
		part_id: 156,
		quantity: 437,
	},
	{
		garage_id: 153,
		part_id: 157,
		quantity: 195,
	},
	{
		garage_id: 153,
		part_id: 158,
		quantity: 203,
	},
	{
		garage_id: 153,
		part_id: 159,
		quantity: 383,
	},
	{
		garage_id: 153,
		part_id: 160,
		quantity: 193,
	},
	{
		garage_id: 153,
		part_id: 161,
		quantity: 423,
	},
	{
		garage_id: 153,
		part_id: 162,
		quantity: 197,
	},
	{
		garage_id: 153,
		part_id: 163,
		quantity: 327,
	},
	{
		garage_id: 153,
		part_id: 164,
		quantity: 19,
	},
	{
		garage_id: 153,
		part_id: 165,
		quantity: 470,
	},
	{
		garage_id: 153,
		part_id: 166,
		quantity: 350,
	},
	{
		garage_id: 153,
		part_id: 167,
		quantity: 303,
	},
	{
		garage_id: 153,
		part_id: 168,
		quantity: 486,
	},
	{
		garage_id: 153,
		part_id: 169,
		quantity: 216,
	},
	{
		garage_id: 153,
		part_id: 170,
		quantity: 453,
	},
	{
		garage_id: 153,
		part_id: 171,
		quantity: 257,
	},
	{
		garage_id: 153,
		part_id: 172,
		quantity: 122,
	},
	{
		garage_id: 153,
		part_id: 173,
		quantity: 369,
	},
	{
		garage_id: 153,
		part_id: 174,
		quantity: 302,
	},
	{
		garage_id: 153,
		part_id: 175,
		quantity: 25,
	},
	{
		garage_id: 153,
		part_id: 176,
		quantity: 400,
	},
	{
		garage_id: 153,
		part_id: 177,
		quantity: 48,
	},
	{
		garage_id: 153,
		part_id: 178,
		quantity: 131,
	},
	{
		garage_id: 153,
		part_id: 179,
		quantity: 339,
	},
	{
		garage_id: 153,
		part_id: 180,
		quantity: 252,
	},
	{
		garage_id: 153,
		part_id: 181,
		quantity: 412,
	},
	{
		garage_id: 153,
		part_id: 182,
		quantity: 440,
	},
	{
		garage_id: 153,
		part_id: 183,
		quantity: 5,
	},
	{
		garage_id: 153,
		part_id: 184,
		quantity: 211,
	},
	{
		garage_id: 153,
		part_id: 185,
		quantity: 362,
	},
	{
		garage_id: 153,
		part_id: 186,
		quantity: 273,
	},
	{
		garage_id: 153,
		part_id: 187,
		quantity: 261,
	},
	{
		garage_id: 153,
		part_id: 188,
		quantity: 123,
	},
	{
		garage_id: 153,
		part_id: 189,
		quantity: 310,
	},
	{
		garage_id: 153,
		part_id: 190,
		quantity: 23,
	},
	{
		garage_id: 153,
		part_id: 191,
		quantity: 111,
	},
	{
		garage_id: 153,
		part_id: 192,
		quantity: 68,
	},
	{
		garage_id: 153,
		part_id: 193,
		quantity: 21,
	},
	{
		garage_id: 153,
		part_id: 194,
		quantity: 415,
	},
	{
		garage_id: 153,
		part_id: 195,
		quantity: 371,
	},
	{
		garage_id: 153,
		part_id: 196,
		quantity: 97,
	},
	{
		garage_id: 153,
		part_id: 197,
		quantity: 459,
	},
	{
		garage_id: 153,
		part_id: 198,
		quantity: 230,
	},
	{
		garage_id: 153,
		part_id: 199,
		quantity: 130,
	},
	{
		garage_id: 153,
		part_id: 200,
		quantity: 349,
	},
	{
		garage_id: 153,
		part_id: 201,
		quantity: 101,
	},
	{
		garage_id: 153,
		part_id: 202,
		quantity: 172,
	},
	{
		garage_id: 153,
		part_id: 203,
		quantity: 408,
	},
	{
		garage_id: 153,
		part_id: 204,
		quantity: 104,
	},
	{
		garage_id: 153,
		part_id: 205,
		quantity: 318,
	},
	{
		garage_id: 153,
		part_id: 206,
		quantity: 143,
	},
	{
		garage_id: 153,
		part_id: 207,
		quantity: 263,
	},
	{
		garage_id: 153,
		part_id: 208,
		quantity: 70,
	},
	{
		garage_id: 153,
		part_id: 209,
		quantity: 349,
	},
	{
		garage_id: 153,
		part_id: 210,
		quantity: 130,
	},
	{
		garage_id: 153,
		part_id: 211,
		quantity: 203,
	},
	{
		garage_id: 153,
		part_id: 212,
		quantity: 388,
	},
	{
		garage_id: 153,
		part_id: 213,
		quantity: 410,
	},
	{
		garage_id: 153,
		part_id: 214,
		quantity: 205,
	},
	{
		garage_id: 153,
		part_id: 215,
		quantity: 231,
	},
	{
		garage_id: 153,
		part_id: 216,
		quantity: 287,
	},
	{
		garage_id: 153,
		part_id: 217,
		quantity: 253,
	},
	{
		garage_id: 153,
		part_id: 218,
		quantity: 432,
	},
	{
		garage_id: 153,
		part_id: 219,
		quantity: 66,
	},
	{
		garage_id: 153,
		part_id: 220,
		quantity: 328,
	},
	{
		garage_id: 153,
		part_id: 221,
		quantity: 66,
	},
	{
		garage_id: 153,
		part_id: 222,
		quantity: 297,
	},
	{
		garage_id: 153,
		part_id: 223,
		quantity: 235,
	},
	{
		garage_id: 153,
		part_id: 224,
		quantity: 254,
	},
	{
		garage_id: 153,
		part_id: 225,
		quantity: 139,
	},
	{
		garage_id: 153,
		part_id: 226,
		quantity: 61,
	},
	{
		garage_id: 153,
		part_id: 227,
		quantity: 286,
	},
	{
		garage_id: 153,
		part_id: 228,
		quantity: 103,
	},
	{
		garage_id: 153,
		part_id: 229,
		quantity: 375,
	},
	{
		garage_id: 153,
		part_id: 230,
		quantity: 177,
	},
	{
		garage_id: 153,
		part_id: 231,
		quantity: 95,
	},
	{
		garage_id: 153,
		part_id: 232,
		quantity: 184,
	},
	{
		garage_id: 153,
		part_id: 233,
		quantity: 320,
	},
	{
		garage_id: 153,
		part_id: 234,
		quantity: 412,
	},
	{
		garage_id: 153,
		part_id: 235,
		quantity: 138,
	},
	{
		garage_id: 153,
		part_id: 236,
		quantity: 107,
	},
	{
		garage_id: 153,
		part_id: 237,
		quantity: 413,
	},
	{
		garage_id: 153,
		part_id: 238,
		quantity: 309,
	},
	{
		garage_id: 153,
		part_id: 239,
		quantity: 445,
	},
	{
		garage_id: 153,
		part_id: 240,
		quantity: 162,
	},
	{
		garage_id: 153,
		part_id: 241,
		quantity: 263,
	},
	{
		garage_id: 153,
		part_id: 242,
		quantity: 237,
	},
	{
		garage_id: 153,
		part_id: 243,
		quantity: 2,
	},
	{
		garage_id: 153,
		part_id: 244,
		quantity: 296,
	},
	{
		garage_id: 153,
		part_id: 245,
		quantity: 380,
	},
	{
		garage_id: 153,
		part_id: 246,
		quantity: 442,
	},
	{
		garage_id: 153,
		part_id: 247,
		quantity: 306,
	},
	{
		garage_id: 153,
		part_id: 248,
		quantity: 280,
	},
	{
		garage_id: 153,
		part_id: 249,
		quantity: 473,
	},
	{
		garage_id: 153,
		part_id: 250,
		quantity: 48,
	},
	{
		garage_id: 153,
		part_id: 251,
		quantity: 14,
	},
	{
		garage_id: 153,
		part_id: 252,
		quantity: 458,
	},
	{
		garage_id: 153,
		part_id: 253,
		quantity: 369,
	},
	{
		garage_id: 153,
		part_id: 254,
		quantity: 486,
	},
	{
		garage_id: 153,
		part_id: 255,
		quantity: 312,
	},
	{
		garage_id: 153,
		part_id: 256,
		quantity: 485,
	},
	{
		garage_id: 153,
		part_id: 257,
		quantity: 66,
	},
	{
		garage_id: 153,
		part_id: 258,
		quantity: 312,
	},
	{
		garage_id: 153,
		part_id: 259,
		quantity: 239,
	},
	{
		garage_id: 153,
		part_id: 260,
		quantity: 433,
	},
	{
		garage_id: 153,
		part_id: 261,
		quantity: 57,
	},
	{
		garage_id: 153,
		part_id: 262,
		quantity: 64,
	},
	{
		garage_id: 153,
		part_id: 263,
		quantity: 267,
	},
	{
		garage_id: 153,
		part_id: 264,
		quantity: 355,
	},
	{
		garage_id: 153,
		part_id: 265,
		quantity: 389,
	},
	{
		garage_id: 153,
		part_id: 266,
		quantity: 335,
	},
	{
		garage_id: 153,
		part_id: 267,
		quantity: 490,
	},
	{
		garage_id: 153,
		part_id: 268,
		quantity: 0,
	},
	{
		garage_id: 153,
		part_id: 269,
		quantity: 315,
	},
	{
		garage_id: 153,
		part_id: 270,
		quantity: 374,
	},
	{
		garage_id: 153,
		part_id: 271,
		quantity: 143,
	},
	{
		garage_id: 153,
		part_id: 272,
		quantity: 177,
	},
	{
		garage_id: 153,
		part_id: 273,
		quantity: 182,
	},
	{
		garage_id: 153,
		part_id: 274,
		quantity: 42,
	},
	{
		garage_id: 153,
		part_id: 275,
		quantity: 429,
	},
	{
		garage_id: 153,
		part_id: 276,
		quantity: 424,
	},
	{
		garage_id: 153,
		part_id: 277,
		quantity: 255,
	},
	{
		garage_id: 153,
		part_id: 278,
		quantity: 92,
	},
	{
		garage_id: 153,
		part_id: 279,
		quantity: 492,
	},
	{
		garage_id: 153,
		part_id: 280,
		quantity: 126,
	},
	{
		garage_id: 153,
		part_id: 281,
		quantity: 184,
	},
	{
		garage_id: 153,
		part_id: 282,
		quantity: 115,
	},
	{
		garage_id: 153,
		part_id: 283,
		quantity: 351,
	},
	{
		garage_id: 153,
		part_id: 284,
		quantity: 248,
	},
	{
		garage_id: 153,
		part_id: 285,
		quantity: 352,
	},
	{
		garage_id: 153,
		part_id: 286,
		quantity: 434,
	},
	{
		garage_id: 153,
		part_id: 287,
		quantity: 8,
	},
	{
		garage_id: 153,
		part_id: 288,
		quantity: 315,
	},
	{
		garage_id: 153,
		part_id: 289,
		quantity: 128,
	},
	{
		garage_id: 153,
		part_id: 290,
		quantity: 429,
	},
	{
		garage_id: 153,
		part_id: 291,
		quantity: 485,
	},
	{
		garage_id: 153,
		part_id: 292,
		quantity: 158,
	},
	{
		garage_id: 153,
		part_id: 293,
		quantity: 161,
	},
	{
		garage_id: 153,
		part_id: 294,
		quantity: 419,
	},
	{
		garage_id: 153,
		part_id: 295,
		quantity: 83,
	},
	{
		garage_id: 153,
		part_id: 296,
		quantity: 178,
	},
	{
		garage_id: 153,
		part_id: 297,
		quantity: 404,
	},
	{
		garage_id: 153,
		part_id: 298,
		quantity: 101,
	},
	{
		garage_id: 153,
		part_id: 299,
		quantity: 327,
	},
	{
		garage_id: 153,
		part_id: 300,
		quantity: 362,
	},
	{
		garage_id: 153,
		part_id: 301,
		quantity: 86,
	},
	{
		garage_id: 153,
		part_id: 302,
		quantity: 268,
	},
	{
		garage_id: 153,
		part_id: 303,
		quantity: 484,
	},
	{
		garage_id: 153,
		part_id: 304,
		quantity: 149,
	},
	{
		garage_id: 153,
		part_id: 305,
		quantity: 349,
	},
	{
		garage_id: 153,
		part_id: 306,
		quantity: 469,
	},
	{
		garage_id: 153,
		part_id: 307,
		quantity: 284,
	},
	{
		garage_id: 153,
		part_id: 308,
		quantity: 474,
	},
	{
		garage_id: 153,
		part_id: 309,
		quantity: 252,
	},
	{
		garage_id: 153,
		part_id: 310,
		quantity: 454,
	},
	{
		garage_id: 153,
		part_id: 311,
		quantity: 381,
	},
	{
		garage_id: 153,
		part_id: 312,
		quantity: 351,
	},
	{
		garage_id: 153,
		part_id: 313,
		quantity: 500,
	},
	{
		garage_id: 153,
		part_id: 314,
		quantity: 358,
	},
	{
		garage_id: 153,
		part_id: 315,
		quantity: 325,
	},
	{
		garage_id: 153,
		part_id: 316,
		quantity: 332,
	},
	{
		garage_id: 153,
		part_id: 317,
		quantity: 127,
	},
	{
		garage_id: 153,
		part_id: 318,
		quantity: 375,
	},
	{
		garage_id: 153,
		part_id: 319,
		quantity: 452,
	},
	{
		garage_id: 153,
		part_id: 320,
		quantity: 301,
	},
	{
		garage_id: 153,
		part_id: 321,
		quantity: 274,
	},
	{
		garage_id: 153,
		part_id: 322,
		quantity: 351,
	},
	{
		garage_id: 153,
		part_id: 323,
		quantity: 356,
	},
	{
		garage_id: 153,
		part_id: 324,
		quantity: 436,
	},
	{
		garage_id: 153,
		part_id: 325,
		quantity: 143,
	},
	{
		garage_id: 153,
		part_id: 326,
		quantity: 368,
	},
	{
		garage_id: 153,
		part_id: 327,
		quantity: 253,
	},
	{
		garage_id: 153,
		part_id: 328,
		quantity: 141,
	},
	{
		garage_id: 153,
		part_id: 329,
		quantity: 101,
	},
	{
		garage_id: 153,
		part_id: 330,
		quantity: 484,
	},
	{
		garage_id: 153,
		part_id: 331,
		quantity: 273,
	},
	{
		garage_id: 153,
		part_id: 332,
		quantity: 463,
	},
	{
		garage_id: 153,
		part_id: 333,
		quantity: 77,
	},
	{
		garage_id: 153,
		part_id: 334,
		quantity: 390,
	},
	{
		garage_id: 153,
		part_id: 335,
		quantity: 146,
	},
	{
		garage_id: 153,
		part_id: 336,
		quantity: 182,
	},
	{
		garage_id: 153,
		part_id: 337,
		quantity: 87,
	},
	{
		garage_id: 153,
		part_id: 338,
		quantity: 176,
	},
	{
		garage_id: 153,
		part_id: 339,
		quantity: 499,
	},
	{
		garage_id: 153,
		part_id: 340,
		quantity: 31,
	},
	{
		garage_id: 153,
		part_id: 341,
		quantity: 379,
	},
	{
		garage_id: 153,
		part_id: 342,
		quantity: 248,
	},
	{
		garage_id: 153,
		part_id: 343,
		quantity: 12,
	},
	{
		garage_id: 153,
		part_id: 344,
		quantity: 412,
	},
	{
		garage_id: 153,
		part_id: 345,
		quantity: 165,
	},
	{
		garage_id: 153,
		part_id: 346,
		quantity: 300,
	},
	{
		garage_id: 153,
		part_id: 347,
		quantity: 247,
	},
	{
		garage_id: 153,
		part_id: 348,
		quantity: 311,
	},
	{
		garage_id: 153,
		part_id: 349,
		quantity: 422,
	},
	{
		garage_id: 153,
		part_id: 350,
		quantity: 90,
	},
	{
		garage_id: 153,
		part_id: 351,
		quantity: 475,
	},
	{
		garage_id: 153,
		part_id: 352,
		quantity: 286,
	},
	{
		garage_id: 153,
		part_id: 353,
		quantity: 277,
	},
	{
		garage_id: 153,
		part_id: 354,
		quantity: 414,
	},
	{
		garage_id: 153,
		part_id: 355,
		quantity: 54,
	},
	{
		garage_id: 153,
		part_id: 356,
		quantity: 368,
	},
	{
		garage_id: 153,
		part_id: 357,
		quantity: 86,
	},
	{
		garage_id: 153,
		part_id: 358,
		quantity: 369,
	},
	{
		garage_id: 153,
		part_id: 359,
		quantity: 94,
	},
	{
		garage_id: 153,
		part_id: 360,
		quantity: 52,
	},
	{
		garage_id: 153,
		part_id: 361,
		quantity: 189,
	},
	{
		garage_id: 153,
		part_id: 362,
		quantity: 160,
	},
	{
		garage_id: 153,
		part_id: 363,
		quantity: 71,
	},
	{
		garage_id: 153,
		part_id: 364,
		quantity: 338,
	},
	{
		garage_id: 153,
		part_id: 365,
		quantity: 235,
	},
	{
		garage_id: 153,
		part_id: 366,
		quantity: 467,
	},
	{
		garage_id: 153,
		part_id: 367,
		quantity: 120,
	},
	{
		garage_id: 153,
		part_id: 368,
		quantity: 213,
	},
	{
		garage_id: 153,
		part_id: 369,
		quantity: 53,
	},
	{
		garage_id: 153,
		part_id: 370,
		quantity: 38,
	},
	{
		garage_id: 153,
		part_id: 371,
		quantity: 294,
	},
	{
		garage_id: 153,
		part_id: 372,
		quantity: 458,
	},
	{
		garage_id: 153,
		part_id: 373,
		quantity: 168,
	},
	{
		garage_id: 153,
		part_id: 374,
		quantity: 476,
	},
	{
		garage_id: 153,
		part_id: 375,
		quantity: 213,
	},
	{
		garage_id: 153,
		part_id: 376,
		quantity: 306,
	},
	{
		garage_id: 153,
		part_id: 377,
		quantity: 38,
	},
	{
		garage_id: 153,
		part_id: 378,
		quantity: 173,
	},
	{
		garage_id: 153,
		part_id: 379,
		quantity: 366,
	},
	{
		garage_id: 153,
		part_id: 380,
		quantity: 465,
	},
	{
		garage_id: 153,
		part_id: 381,
		quantity: 370,
	},
	{
		garage_id: 153,
		part_id: 382,
		quantity: 293,
	},
	{
		garage_id: 153,
		part_id: 383,
		quantity: 167,
	},
	{
		garage_id: 153,
		part_id: 384,
		quantity: 288,
	},
	{
		garage_id: 153,
		part_id: 385,
		quantity: 63,
	},
	{
		garage_id: 153,
		part_id: 386,
		quantity: 412,
	},
	{
		garage_id: 153,
		part_id: 387,
		quantity: 281,
	},
	{
		garage_id: 153,
		part_id: 388,
		quantity: 138,
	},
	{
		garage_id: 153,
		part_id: 389,
		quantity: 341,
	},
	{
		garage_id: 153,
		part_id: 390,
		quantity: 6,
	},
	{
		garage_id: 153,
		part_id: 391,
		quantity: 396,
	},
	{
		garage_id: 153,
		part_id: 392,
		quantity: 259,
	},
	{
		garage_id: 153,
		part_id: 393,
		quantity: 179,
	},
	{
		garage_id: 153,
		part_id: 394,
		quantity: 82,
	},
	{
		garage_id: 153,
		part_id: 395,
		quantity: 343,
	},
	{
		garage_id: 153,
		part_id: 396,
		quantity: 231,
	},
	{
		garage_id: 153,
		part_id: 397,
		quantity: 16,
	},
	{
		garage_id: 153,
		part_id: 398,
		quantity: 148,
	},
	{
		garage_id: 153,
		part_id: 399,
		quantity: 178,
	},
	{
		garage_id: 154,
		part_id: 0,
		quantity: 374,
	},
	{
		garage_id: 154,
		part_id: 1,
		quantity: 429,
	},
	{
		garage_id: 154,
		part_id: 2,
		quantity: 402,
	},
	{
		garage_id: 154,
		part_id: 3,
		quantity: 257,
	},
	{
		garage_id: 154,
		part_id: 4,
		quantity: 131,
	},
	{
		garage_id: 154,
		part_id: 5,
		quantity: 302,
	},
	{
		garage_id: 154,
		part_id: 6,
		quantity: 461,
	},
	{
		garage_id: 154,
		part_id: 7,
		quantity: 65,
	},
	{
		garage_id: 154,
		part_id: 8,
		quantity: 277,
	},
	{
		garage_id: 154,
		part_id: 9,
		quantity: 211,
	},
	{
		garage_id: 154,
		part_id: 10,
		quantity: 393,
	},
	{
		garage_id: 154,
		part_id: 11,
		quantity: 116,
	},
	{
		garage_id: 154,
		part_id: 12,
		quantity: 391,
	},
	{
		garage_id: 154,
		part_id: 13,
		quantity: 198,
	},
	{
		garage_id: 154,
		part_id: 14,
		quantity: 14,
	},
	{
		garage_id: 154,
		part_id: 15,
		quantity: 398,
	},
	{
		garage_id: 154,
		part_id: 16,
		quantity: 157,
	},
	{
		garage_id: 154,
		part_id: 17,
		quantity: 109,
	},
	{
		garage_id: 154,
		part_id: 18,
		quantity: 22,
	},
	{
		garage_id: 154,
		part_id: 19,
		quantity: 149,
	},
	{
		garage_id: 154,
		part_id: 20,
		quantity: 232,
	},
	{
		garage_id: 154,
		part_id: 21,
		quantity: 170,
	},
	{
		garage_id: 154,
		part_id: 22,
		quantity: 262,
	},
	{
		garage_id: 154,
		part_id: 23,
		quantity: 124,
	},
	{
		garage_id: 154,
		part_id: 24,
		quantity: 259,
	},
	{
		garage_id: 154,
		part_id: 25,
		quantity: 335,
	},
	{
		garage_id: 154,
		part_id: 26,
		quantity: 304,
	},
	{
		garage_id: 154,
		part_id: 27,
		quantity: 420,
	},
	{
		garage_id: 154,
		part_id: 28,
		quantity: 295,
	},
	{
		garage_id: 154,
		part_id: 29,
		quantity: 17,
	},
	{
		garage_id: 154,
		part_id: 30,
		quantity: 152,
	},
	{
		garage_id: 154,
		part_id: 31,
		quantity: 361,
	},
	{
		garage_id: 154,
		part_id: 32,
		quantity: 326,
	},
	{
		garage_id: 154,
		part_id: 33,
		quantity: 286,
	},
	{
		garage_id: 154,
		part_id: 34,
		quantity: 203,
	},
	{
		garage_id: 154,
		part_id: 35,
		quantity: 216,
	},
	{
		garage_id: 154,
		part_id: 36,
		quantity: 370,
	},
	{
		garage_id: 154,
		part_id: 37,
		quantity: 68,
	},
	{
		garage_id: 154,
		part_id: 38,
		quantity: 248,
	},
	{
		garage_id: 154,
		part_id: 39,
		quantity: 34,
	},
	{
		garage_id: 154,
		part_id: 40,
		quantity: 239,
	},
	{
		garage_id: 154,
		part_id: 41,
		quantity: 424,
	},
	{
		garage_id: 154,
		part_id: 42,
		quantity: 403,
	},
	{
		garage_id: 154,
		part_id: 43,
		quantity: 447,
	},
	{
		garage_id: 154,
		part_id: 44,
		quantity: 245,
	},
	{
		garage_id: 154,
		part_id: 45,
		quantity: 330,
	},
	{
		garage_id: 154,
		part_id: 46,
		quantity: 286,
	},
	{
		garage_id: 154,
		part_id: 47,
		quantity: 499,
	},
	{
		garage_id: 154,
		part_id: 48,
		quantity: 202,
	},
	{
		garage_id: 154,
		part_id: 49,
		quantity: 404,
	},
	{
		garage_id: 154,
		part_id: 50,
		quantity: 439,
	},
	{
		garage_id: 154,
		part_id: 51,
		quantity: 24,
	},
	{
		garage_id: 154,
		part_id: 52,
		quantity: 123,
	},
	{
		garage_id: 154,
		part_id: 53,
		quantity: 273,
	},
	{
		garage_id: 154,
		part_id: 54,
		quantity: 38,
	},
	{
		garage_id: 154,
		part_id: 55,
		quantity: 250,
	},
	{
		garage_id: 154,
		part_id: 56,
		quantity: 208,
	},
	{
		garage_id: 154,
		part_id: 57,
		quantity: 178,
	},
	{
		garage_id: 154,
		part_id: 58,
		quantity: 362,
	},
	{
		garage_id: 154,
		part_id: 59,
		quantity: 339,
	},
	{
		garage_id: 154,
		part_id: 60,
		quantity: 146,
	},
	{
		garage_id: 154,
		part_id: 61,
		quantity: 66,
	},
	{
		garage_id: 154,
		part_id: 62,
		quantity: 122,
	},
	{
		garage_id: 154,
		part_id: 63,
		quantity: 333,
	},
	{
		garage_id: 154,
		part_id: 64,
		quantity: 249,
	},
	{
		garage_id: 154,
		part_id: 65,
		quantity: 232,
	},
	{
		garage_id: 154,
		part_id: 66,
		quantity: 415,
	},
	{
		garage_id: 154,
		part_id: 67,
		quantity: 225,
	},
	{
		garage_id: 154,
		part_id: 68,
		quantity: 288,
	},
	{
		garage_id: 154,
		part_id: 69,
		quantity: 105,
	},
	{
		garage_id: 154,
		part_id: 70,
		quantity: 229,
	},
	{
		garage_id: 154,
		part_id: 71,
		quantity: 218,
	},
	{
		garage_id: 154,
		part_id: 72,
		quantity: 57,
	},
	{
		garage_id: 154,
		part_id: 73,
		quantity: 129,
	},
	{
		garage_id: 154,
		part_id: 74,
		quantity: 331,
	},
	{
		garage_id: 154,
		part_id: 75,
		quantity: 218,
	},
	{
		garage_id: 154,
		part_id: 76,
		quantity: 260,
	},
	{
		garage_id: 154,
		part_id: 77,
		quantity: 399,
	},
	{
		garage_id: 154,
		part_id: 78,
		quantity: 377,
	},
	{
		garage_id: 154,
		part_id: 79,
		quantity: 78,
	},
	{
		garage_id: 154,
		part_id: 80,
		quantity: 384,
	},
	{
		garage_id: 154,
		part_id: 81,
		quantity: 111,
	},
	{
		garage_id: 154,
		part_id: 82,
		quantity: 106,
	},
	{
		garage_id: 154,
		part_id: 83,
		quantity: 460,
	},
	{
		garage_id: 154,
		part_id: 84,
		quantity: 165,
	},
	{
		garage_id: 154,
		part_id: 85,
		quantity: 11,
	},
	{
		garage_id: 154,
		part_id: 86,
		quantity: 143,
	},
	{
		garage_id: 154,
		part_id: 87,
		quantity: 68,
	},
	{
		garage_id: 154,
		part_id: 88,
		quantity: 142,
	},
	{
		garage_id: 154,
		part_id: 89,
		quantity: 354,
	},
	{
		garage_id: 154,
		part_id: 90,
		quantity: 464,
	},
	{
		garage_id: 154,
		part_id: 91,
		quantity: 83,
	},
	{
		garage_id: 154,
		part_id: 92,
		quantity: 325,
	},
	{
		garage_id: 154,
		part_id: 93,
		quantity: 427,
	},
	{
		garage_id: 154,
		part_id: 94,
		quantity: 137,
	},
	{
		garage_id: 154,
		part_id: 95,
		quantity: 337,
	},
	{
		garage_id: 154,
		part_id: 96,
		quantity: 277,
	},
	{
		garage_id: 154,
		part_id: 97,
		quantity: 405,
	},
	{
		garage_id: 154,
		part_id: 98,
		quantity: 116,
	},
	{
		garage_id: 154,
		part_id: 99,
		quantity: 352,
	},
	{
		garage_id: 154,
		part_id: 100,
		quantity: 178,
	},
	{
		garage_id: 154,
		part_id: 101,
		quantity: 35,
	},
	{
		garage_id: 154,
		part_id: 102,
		quantity: 274,
	},
	{
		garage_id: 154,
		part_id: 103,
		quantity: 61,
	},
	{
		garage_id: 154,
		part_id: 104,
		quantity: 164,
	},
	{
		garage_id: 154,
		part_id: 105,
		quantity: 314,
	},
	{
		garage_id: 154,
		part_id: 106,
		quantity: 303,
	},
	{
		garage_id: 154,
		part_id: 107,
		quantity: 159,
	},
	{
		garage_id: 154,
		part_id: 108,
		quantity: 132,
	},
	{
		garage_id: 154,
		part_id: 109,
		quantity: 142,
	},
	{
		garage_id: 154,
		part_id: 110,
		quantity: 296,
	},
	{
		garage_id: 154,
		part_id: 111,
		quantity: 172,
	},
	{
		garage_id: 154,
		part_id: 112,
		quantity: 95,
	},
	{
		garage_id: 154,
		part_id: 113,
		quantity: 308,
	},
	{
		garage_id: 154,
		part_id: 114,
		quantity: 401,
	},
	{
		garage_id: 154,
		part_id: 115,
		quantity: 118,
	},
	{
		garage_id: 154,
		part_id: 116,
		quantity: 156,
	},
	{
		garage_id: 154,
		part_id: 117,
		quantity: 307,
	},
	{
		garage_id: 154,
		part_id: 118,
		quantity: 251,
	},
	{
		garage_id: 154,
		part_id: 119,
		quantity: 393,
	},
	{
		garage_id: 154,
		part_id: 120,
		quantity: 463,
	},
	{
		garage_id: 154,
		part_id: 121,
		quantity: 209,
	},
	{
		garage_id: 154,
		part_id: 122,
		quantity: 184,
	},
	{
		garage_id: 154,
		part_id: 123,
		quantity: 260,
	},
	{
		garage_id: 154,
		part_id: 124,
		quantity: 161,
	},
	{
		garage_id: 154,
		part_id: 125,
		quantity: 462,
	},
	{
		garage_id: 154,
		part_id: 126,
		quantity: 427,
	},
	{
		garage_id: 154,
		part_id: 127,
		quantity: 498,
	},
	{
		garage_id: 154,
		part_id: 128,
		quantity: 21,
	},
	{
		garage_id: 154,
		part_id: 129,
		quantity: 34,
	},
	{
		garage_id: 154,
		part_id: 130,
		quantity: 429,
	},
	{
		garage_id: 154,
		part_id: 131,
		quantity: 141,
	},
	{
		garage_id: 154,
		part_id: 132,
		quantity: 389,
	},
	{
		garage_id: 154,
		part_id: 133,
		quantity: 38,
	},
	{
		garage_id: 154,
		part_id: 134,
		quantity: 103,
	},
	{
		garage_id: 154,
		part_id: 135,
		quantity: 131,
	},
	{
		garage_id: 154,
		part_id: 136,
		quantity: 7,
	},
	{
		garage_id: 154,
		part_id: 137,
		quantity: 29,
	},
	{
		garage_id: 154,
		part_id: 138,
		quantity: 329,
	},
	{
		garage_id: 154,
		part_id: 139,
		quantity: 327,
	},
	{
		garage_id: 154,
		part_id: 140,
		quantity: 223,
	},
	{
		garage_id: 154,
		part_id: 141,
		quantity: 58,
	},
	{
		garage_id: 154,
		part_id: 142,
		quantity: 280,
	},
	{
		garage_id: 154,
		part_id: 143,
		quantity: 434,
	},
	{
		garage_id: 154,
		part_id: 144,
		quantity: 87,
	},
	{
		garage_id: 154,
		part_id: 145,
		quantity: 210,
	},
	{
		garage_id: 154,
		part_id: 146,
		quantity: 481,
	},
	{
		garage_id: 154,
		part_id: 147,
		quantity: 450,
	},
	{
		garage_id: 154,
		part_id: 148,
		quantity: 217,
	},
	{
		garage_id: 154,
		part_id: 149,
		quantity: 183,
	},
	{
		garage_id: 154,
		part_id: 150,
		quantity: 477,
	},
	{
		garage_id: 154,
		part_id: 151,
		quantity: 339,
	},
	{
		garage_id: 154,
		part_id: 152,
		quantity: 209,
	},
	{
		garage_id: 154,
		part_id: 153,
		quantity: 281,
	},
	{
		garage_id: 154,
		part_id: 154,
		quantity: 368,
	},
	{
		garage_id: 154,
		part_id: 155,
		quantity: 434,
	},
	{
		garage_id: 154,
		part_id: 156,
		quantity: 20,
	},
	{
		garage_id: 154,
		part_id: 157,
		quantity: 427,
	},
	{
		garage_id: 154,
		part_id: 158,
		quantity: 231,
	},
	{
		garage_id: 154,
		part_id: 159,
		quantity: 188,
	},
	{
		garage_id: 154,
		part_id: 160,
		quantity: 355,
	},
	{
		garage_id: 154,
		part_id: 161,
		quantity: 54,
	},
	{
		garage_id: 154,
		part_id: 162,
		quantity: 29,
	},
	{
		garage_id: 154,
		part_id: 163,
		quantity: 220,
	},
	{
		garage_id: 154,
		part_id: 164,
		quantity: 87,
	},
	{
		garage_id: 154,
		part_id: 165,
		quantity: 264,
	},
	{
		garage_id: 154,
		part_id: 166,
		quantity: 423,
	},
	{
		garage_id: 154,
		part_id: 167,
		quantity: 170,
	},
	{
		garage_id: 154,
		part_id: 168,
		quantity: 235,
	},
	{
		garage_id: 154,
		part_id: 169,
		quantity: 480,
	},
	{
		garage_id: 154,
		part_id: 170,
		quantity: 147,
	},
	{
		garage_id: 154,
		part_id: 171,
		quantity: 421,
	},
	{
		garage_id: 154,
		part_id: 172,
		quantity: 233,
	},
	{
		garage_id: 154,
		part_id: 173,
		quantity: 187,
	},
	{
		garage_id: 154,
		part_id: 174,
		quantity: 314,
	},
	{
		garage_id: 154,
		part_id: 175,
		quantity: 164,
	},
	{
		garage_id: 154,
		part_id: 176,
		quantity: 323,
	},
	{
		garage_id: 154,
		part_id: 177,
		quantity: 395,
	},
	{
		garage_id: 154,
		part_id: 178,
		quantity: 25,
	},
	{
		garage_id: 154,
		part_id: 179,
		quantity: 140,
	},
	{
		garage_id: 154,
		part_id: 180,
		quantity: 107,
	},
	{
		garage_id: 154,
		part_id: 181,
		quantity: 79,
	},
	{
		garage_id: 154,
		part_id: 182,
		quantity: 177,
	},
	{
		garage_id: 154,
		part_id: 183,
		quantity: 392,
	},
	{
		garage_id: 154,
		part_id: 184,
		quantity: 425,
	},
	{
		garage_id: 154,
		part_id: 185,
		quantity: 407,
	},
	{
		garage_id: 154,
		part_id: 186,
		quantity: 65,
	},
	{
		garage_id: 154,
		part_id: 187,
		quantity: 218,
	},
	{
		garage_id: 154,
		part_id: 188,
		quantity: 30,
	},
	{
		garage_id: 154,
		part_id: 189,
		quantity: 65,
	},
	{
		garage_id: 154,
		part_id: 190,
		quantity: 99,
	},
	{
		garage_id: 154,
		part_id: 191,
		quantity: 385,
	},
	{
		garage_id: 154,
		part_id: 192,
		quantity: 100,
	},
	{
		garage_id: 154,
		part_id: 193,
		quantity: 432,
	},
	{
		garage_id: 154,
		part_id: 194,
		quantity: 161,
	},
	{
		garage_id: 154,
		part_id: 195,
		quantity: 71,
	},
	{
		garage_id: 154,
		part_id: 196,
		quantity: 113,
	},
	{
		garage_id: 154,
		part_id: 197,
		quantity: 432,
	},
	{
		garage_id: 154,
		part_id: 198,
		quantity: 215,
	},
	{
		garage_id: 154,
		part_id: 199,
		quantity: 87,
	},
	{
		garage_id: 154,
		part_id: 200,
		quantity: 101,
	},
	{
		garage_id: 154,
		part_id: 201,
		quantity: 334,
	},
	{
		garage_id: 154,
		part_id: 202,
		quantity: 25,
	},
	{
		garage_id: 154,
		part_id: 203,
		quantity: 194,
	},
	{
		garage_id: 154,
		part_id: 204,
		quantity: 247,
	},
	{
		garage_id: 154,
		part_id: 205,
		quantity: 190,
	},
	{
		garage_id: 154,
		part_id: 206,
		quantity: 466,
	},
	{
		garage_id: 154,
		part_id: 207,
		quantity: 113,
	},
	{
		garage_id: 154,
		part_id: 208,
		quantity: 364,
	},
	{
		garage_id: 154,
		part_id: 209,
		quantity: 183,
	},
	{
		garage_id: 154,
		part_id: 210,
		quantity: 322,
	},
	{
		garage_id: 154,
		part_id: 211,
		quantity: 495,
	},
	{
		garage_id: 154,
		part_id: 212,
		quantity: 462,
	},
	{
		garage_id: 154,
		part_id: 213,
		quantity: 130,
	},
	{
		garage_id: 154,
		part_id: 214,
		quantity: 192,
	},
	{
		garage_id: 154,
		part_id: 215,
		quantity: 89,
	},
	{
		garage_id: 154,
		part_id: 216,
		quantity: 6,
	},
	{
		garage_id: 154,
		part_id: 217,
		quantity: 106,
	},
	{
		garage_id: 154,
		part_id: 218,
		quantity: 437,
	},
	{
		garage_id: 154,
		part_id: 219,
		quantity: 121,
	},
	{
		garage_id: 154,
		part_id: 220,
		quantity: 63,
	},
	{
		garage_id: 154,
		part_id: 221,
		quantity: 348,
	},
	{
		garage_id: 154,
		part_id: 222,
		quantity: 432,
	},
	{
		garage_id: 154,
		part_id: 223,
		quantity: 302,
	},
	{
		garage_id: 154,
		part_id: 224,
		quantity: 464,
	},
	{
		garage_id: 154,
		part_id: 225,
		quantity: 5,
	},
	{
		garage_id: 154,
		part_id: 226,
		quantity: 395,
	},
	{
		garage_id: 154,
		part_id: 227,
		quantity: 418,
	},
	{
		garage_id: 154,
		part_id: 228,
		quantity: 48,
	},
	{
		garage_id: 154,
		part_id: 229,
		quantity: 199,
	},
	{
		garage_id: 154,
		part_id: 230,
		quantity: 204,
	},
	{
		garage_id: 154,
		part_id: 231,
		quantity: 220,
	},
	{
		garage_id: 154,
		part_id: 232,
		quantity: 303,
	},
	{
		garage_id: 154,
		part_id: 233,
		quantity: 391,
	},
	{
		garage_id: 154,
		part_id: 234,
		quantity: 192,
	},
	{
		garage_id: 154,
		part_id: 235,
		quantity: 294,
	},
	{
		garage_id: 154,
		part_id: 236,
		quantity: 190,
	},
	{
		garage_id: 154,
		part_id: 237,
		quantity: 227,
	},
	{
		garage_id: 154,
		part_id: 238,
		quantity: 341,
	},
	{
		garage_id: 154,
		part_id: 239,
		quantity: 208,
	},
	{
		garage_id: 154,
		part_id: 240,
		quantity: 133,
	},
	{
		garage_id: 154,
		part_id: 241,
		quantity: 294,
	},
	{
		garage_id: 154,
		part_id: 242,
		quantity: 251,
	},
	{
		garage_id: 154,
		part_id: 243,
		quantity: 417,
	},
	{
		garage_id: 154,
		part_id: 244,
		quantity: 403,
	},
	{
		garage_id: 154,
		part_id: 245,
		quantity: 296,
	},
	{
		garage_id: 154,
		part_id: 246,
		quantity: 45,
	},
	{
		garage_id: 154,
		part_id: 247,
		quantity: 332,
	},
	{
		garage_id: 154,
		part_id: 248,
		quantity: 8,
	},
	{
		garage_id: 154,
		part_id: 249,
		quantity: 410,
	},
	{
		garage_id: 154,
		part_id: 250,
		quantity: 496,
	},
	{
		garage_id: 154,
		part_id: 251,
		quantity: 369,
	},
	{
		garage_id: 154,
		part_id: 252,
		quantity: 445,
	},
	{
		garage_id: 154,
		part_id: 253,
		quantity: 78,
	},
	{
		garage_id: 154,
		part_id: 254,
		quantity: 180,
	},
	{
		garage_id: 154,
		part_id: 255,
		quantity: 63,
	},
	{
		garage_id: 154,
		part_id: 256,
		quantity: 339,
	},
	{
		garage_id: 154,
		part_id: 257,
		quantity: 455,
	},
	{
		garage_id: 154,
		part_id: 258,
		quantity: 0,
	},
	{
		garage_id: 154,
		part_id: 259,
		quantity: 189,
	},
	{
		garage_id: 154,
		part_id: 260,
		quantity: 193,
	},
	{
		garage_id: 154,
		part_id: 261,
		quantity: 118,
	},
	{
		garage_id: 154,
		part_id: 262,
		quantity: 68,
	},
	{
		garage_id: 154,
		part_id: 263,
		quantity: 181,
	},
	{
		garage_id: 154,
		part_id: 264,
		quantity: 479,
	},
	{
		garage_id: 154,
		part_id: 265,
		quantity: 486,
	},
	{
		garage_id: 154,
		part_id: 266,
		quantity: 146,
	},
	{
		garage_id: 154,
		part_id: 267,
		quantity: 371,
	},
	{
		garage_id: 154,
		part_id: 268,
		quantity: 339,
	},
	{
		garage_id: 154,
		part_id: 269,
		quantity: 429,
	},
	{
		garage_id: 154,
		part_id: 270,
		quantity: 78,
	},
	{
		garage_id: 154,
		part_id: 271,
		quantity: 483,
	},
	{
		garage_id: 154,
		part_id: 272,
		quantity: 299,
	},
	{
		garage_id: 154,
		part_id: 273,
		quantity: 125,
	},
	{
		garage_id: 154,
		part_id: 274,
		quantity: 95,
	},
	{
		garage_id: 154,
		part_id: 275,
		quantity: 173,
	},
	{
		garage_id: 154,
		part_id: 276,
		quantity: 298,
	},
	{
		garage_id: 154,
		part_id: 277,
		quantity: 141,
	},
	{
		garage_id: 154,
		part_id: 278,
		quantity: 187,
	},
	{
		garage_id: 154,
		part_id: 279,
		quantity: 399,
	},
	{
		garage_id: 154,
		part_id: 280,
		quantity: 181,
	},
	{
		garage_id: 154,
		part_id: 281,
		quantity: 39,
	},
	{
		garage_id: 154,
		part_id: 282,
		quantity: 386,
	},
	{
		garage_id: 154,
		part_id: 283,
		quantity: 99,
	},
	{
		garage_id: 154,
		part_id: 284,
		quantity: 41,
	},
	{
		garage_id: 154,
		part_id: 285,
		quantity: 3,
	},
	{
		garage_id: 154,
		part_id: 286,
		quantity: 369,
	},
	{
		garage_id: 154,
		part_id: 287,
		quantity: 466,
	},
	{
		garage_id: 154,
		part_id: 288,
		quantity: 348,
	},
	{
		garage_id: 154,
		part_id: 289,
		quantity: 275,
	},
	{
		garage_id: 154,
		part_id: 290,
		quantity: 146,
	},
	{
		garage_id: 154,
		part_id: 291,
		quantity: 188,
	},
	{
		garage_id: 154,
		part_id: 292,
		quantity: 249,
	},
	{
		garage_id: 154,
		part_id: 293,
		quantity: 107,
	},
	{
		garage_id: 154,
		part_id: 294,
		quantity: 70,
	},
	{
		garage_id: 154,
		part_id: 295,
		quantity: 295,
	},
	{
		garage_id: 154,
		part_id: 296,
		quantity: 211,
	},
	{
		garage_id: 154,
		part_id: 297,
		quantity: 64,
	},
	{
		garage_id: 154,
		part_id: 298,
		quantity: 424,
	},
	{
		garage_id: 154,
		part_id: 299,
		quantity: 394,
	},
	{
		garage_id: 154,
		part_id: 300,
		quantity: 445,
	},
	{
		garage_id: 154,
		part_id: 301,
		quantity: 163,
	},
	{
		garage_id: 154,
		part_id: 302,
		quantity: 39,
	},
	{
		garage_id: 154,
		part_id: 303,
		quantity: 341,
	},
	{
		garage_id: 154,
		part_id: 304,
		quantity: 20,
	},
	{
		garage_id: 154,
		part_id: 305,
		quantity: 480,
	},
	{
		garage_id: 154,
		part_id: 306,
		quantity: 402,
	},
	{
		garage_id: 154,
		part_id: 307,
		quantity: 336,
	},
	{
		garage_id: 154,
		part_id: 308,
		quantity: 485,
	},
	{
		garage_id: 154,
		part_id: 309,
		quantity: 284,
	},
	{
		garage_id: 154,
		part_id: 310,
		quantity: 326,
	},
	{
		garage_id: 154,
		part_id: 311,
		quantity: 396,
	},
	{
		garage_id: 154,
		part_id: 312,
		quantity: 376,
	},
	{
		garage_id: 154,
		part_id: 313,
		quantity: 222,
	},
	{
		garage_id: 154,
		part_id: 314,
		quantity: 193,
	},
	{
		garage_id: 154,
		part_id: 315,
		quantity: 398,
	},
	{
		garage_id: 154,
		part_id: 316,
		quantity: 59,
	},
	{
		garage_id: 154,
		part_id: 317,
		quantity: 418,
	},
	{
		garage_id: 154,
		part_id: 318,
		quantity: 496,
	},
	{
		garage_id: 154,
		part_id: 319,
		quantity: 64,
	},
	{
		garage_id: 154,
		part_id: 320,
		quantity: 38,
	},
	{
		garage_id: 154,
		part_id: 321,
		quantity: 67,
	},
	{
		garage_id: 154,
		part_id: 322,
		quantity: 327,
	},
	{
		garage_id: 154,
		part_id: 323,
		quantity: 417,
	},
	{
		garage_id: 154,
		part_id: 324,
		quantity: 330,
	},
	{
		garage_id: 154,
		part_id: 325,
		quantity: 334,
	},
	{
		garage_id: 154,
		part_id: 326,
		quantity: 498,
	},
	{
		garage_id: 154,
		part_id: 327,
		quantity: 25,
	},
	{
		garage_id: 154,
		part_id: 328,
		quantity: 290,
	},
	{
		garage_id: 154,
		part_id: 329,
		quantity: 203,
	},
	{
		garage_id: 154,
		part_id: 330,
		quantity: 237,
	},
	{
		garage_id: 154,
		part_id: 331,
		quantity: 213,
	},
	{
		garage_id: 154,
		part_id: 332,
		quantity: 417,
	},
	{
		garage_id: 154,
		part_id: 333,
		quantity: 110,
	},
	{
		garage_id: 154,
		part_id: 334,
		quantity: 78,
	},
	{
		garage_id: 154,
		part_id: 335,
		quantity: 110,
	},
	{
		garage_id: 154,
		part_id: 336,
		quantity: 374,
	},
	{
		garage_id: 154,
		part_id: 337,
		quantity: 10,
	},
	{
		garage_id: 154,
		part_id: 338,
		quantity: 368,
	},
	{
		garage_id: 154,
		part_id: 339,
		quantity: 138,
	},
	{
		garage_id: 154,
		part_id: 340,
		quantity: 379,
	},
	{
		garage_id: 154,
		part_id: 341,
		quantity: 296,
	},
	{
		garage_id: 154,
		part_id: 342,
		quantity: 292,
	},
	{
		garage_id: 154,
		part_id: 343,
		quantity: 151,
	},
	{
		garage_id: 154,
		part_id: 344,
		quantity: 23,
	},
	{
		garage_id: 154,
		part_id: 345,
		quantity: 332,
	},
	{
		garage_id: 154,
		part_id: 346,
		quantity: 278,
	},
	{
		garage_id: 154,
		part_id: 347,
		quantity: 290,
	},
	{
		garage_id: 154,
		part_id: 348,
		quantity: 233,
	},
	{
		garage_id: 154,
		part_id: 349,
		quantity: 316,
	},
	{
		garage_id: 154,
		part_id: 350,
		quantity: 455,
	},
	{
		garage_id: 154,
		part_id: 351,
		quantity: 433,
	},
	{
		garage_id: 154,
		part_id: 352,
		quantity: 253,
	},
	{
		garage_id: 154,
		part_id: 353,
		quantity: 230,
	},
	{
		garage_id: 154,
		part_id: 354,
		quantity: 136,
	},
	{
		garage_id: 154,
		part_id: 355,
		quantity: 235,
	},
	{
		garage_id: 154,
		part_id: 356,
		quantity: 351,
	},
	{
		garage_id: 154,
		part_id: 357,
		quantity: 251,
	},
	{
		garage_id: 154,
		part_id: 358,
		quantity: 471,
	},
	{
		garage_id: 154,
		part_id: 359,
		quantity: 345,
	},
	{
		garage_id: 154,
		part_id: 360,
		quantity: 466,
	},
	{
		garage_id: 154,
		part_id: 361,
		quantity: 428,
	},
	{
		garage_id: 154,
		part_id: 362,
		quantity: 5,
	},
	{
		garage_id: 154,
		part_id: 363,
		quantity: 317,
	},
	{
		garage_id: 154,
		part_id: 364,
		quantity: 212,
	},
	{
		garage_id: 154,
		part_id: 365,
		quantity: 58,
	},
	{
		garage_id: 154,
		part_id: 366,
		quantity: 115,
	},
	{
		garage_id: 154,
		part_id: 367,
		quantity: 448,
	},
	{
		garage_id: 154,
		part_id: 368,
		quantity: 381,
	},
	{
		garage_id: 154,
		part_id: 369,
		quantity: 61,
	},
	{
		garage_id: 154,
		part_id: 370,
		quantity: 208,
	},
	{
		garage_id: 154,
		part_id: 371,
		quantity: 258,
	},
	{
		garage_id: 154,
		part_id: 372,
		quantity: 426,
	},
	{
		garage_id: 154,
		part_id: 373,
		quantity: 36,
	},
	{
		garage_id: 154,
		part_id: 374,
		quantity: 280,
	},
	{
		garage_id: 154,
		part_id: 375,
		quantity: 339,
	},
	{
		garage_id: 154,
		part_id: 376,
		quantity: 126,
	},
	{
		garage_id: 154,
		part_id: 377,
		quantity: 81,
	},
	{
		garage_id: 154,
		part_id: 378,
		quantity: 389,
	},
	{
		garage_id: 154,
		part_id: 379,
		quantity: 222,
	},
	{
		garage_id: 154,
		part_id: 380,
		quantity: 435,
	},
	{
		garage_id: 154,
		part_id: 381,
		quantity: 198,
	},
	{
		garage_id: 154,
		part_id: 382,
		quantity: 289,
	},
	{
		garage_id: 154,
		part_id: 383,
		quantity: 317,
	},
	{
		garage_id: 154,
		part_id: 384,
		quantity: 180,
	},
	{
		garage_id: 154,
		part_id: 385,
		quantity: 330,
	},
	{
		garage_id: 154,
		part_id: 386,
		quantity: 178,
	},
	{
		garage_id: 154,
		part_id: 387,
		quantity: 440,
	},
	{
		garage_id: 154,
		part_id: 388,
		quantity: 197,
	},
	{
		garage_id: 154,
		part_id: 389,
		quantity: 320,
	},
	{
		garage_id: 154,
		part_id: 390,
		quantity: 156,
	},
	{
		garage_id: 154,
		part_id: 391,
		quantity: 305,
	},
	{
		garage_id: 154,
		part_id: 392,
		quantity: 321,
	},
	{
		garage_id: 154,
		part_id: 393,
		quantity: 293,
	},
	{
		garage_id: 154,
		part_id: 394,
		quantity: 229,
	},
	{
		garage_id: 154,
		part_id: 395,
		quantity: 17,
	},
	{
		garage_id: 154,
		part_id: 396,
		quantity: 68,
	},
	{
		garage_id: 154,
		part_id: 397,
		quantity: 96,
	},
	{
		garage_id: 154,
		part_id: 398,
		quantity: 346,
	},
	{
		garage_id: 154,
		part_id: 399,
		quantity: 276,
	},
	{
		garage_id: 155,
		part_id: 0,
		quantity: 424,
	},
	{
		garage_id: 155,
		part_id: 1,
		quantity: 469,
	},
	{
		garage_id: 155,
		part_id: 2,
		quantity: 220,
	},
	{
		garage_id: 155,
		part_id: 3,
		quantity: 353,
	},
	{
		garage_id: 155,
		part_id: 4,
		quantity: 178,
	},
	{
		garage_id: 155,
		part_id: 5,
		quantity: 123,
	},
	{
		garage_id: 155,
		part_id: 6,
		quantity: 237,
	},
	{
		garage_id: 155,
		part_id: 7,
		quantity: 67,
	},
	{
		garage_id: 155,
		part_id: 8,
		quantity: 341,
	},
	{
		garage_id: 155,
		part_id: 9,
		quantity: 74,
	},
	{
		garage_id: 155,
		part_id: 10,
		quantity: 445,
	},
	{
		garage_id: 155,
		part_id: 11,
		quantity: 33,
	},
	{
		garage_id: 155,
		part_id: 12,
		quantity: 460,
	},
	{
		garage_id: 155,
		part_id: 13,
		quantity: 284,
	},
	{
		garage_id: 155,
		part_id: 14,
		quantity: 148,
	},
	{
		garage_id: 155,
		part_id: 15,
		quantity: 164,
	},
	{
		garage_id: 155,
		part_id: 16,
		quantity: 389,
	},
	{
		garage_id: 155,
		part_id: 17,
		quantity: 425,
	},
	{
		garage_id: 155,
		part_id: 18,
		quantity: 277,
	},
	{
		garage_id: 155,
		part_id: 19,
		quantity: 193,
	},
	{
		garage_id: 155,
		part_id: 20,
		quantity: 100,
	},
	{
		garage_id: 155,
		part_id: 21,
		quantity: 289,
	},
	{
		garage_id: 155,
		part_id: 22,
		quantity: 144,
	},
	{
		garage_id: 155,
		part_id: 23,
		quantity: 438,
	},
	{
		garage_id: 155,
		part_id: 24,
		quantity: 160,
	},
	{
		garage_id: 155,
		part_id: 25,
		quantity: 150,
	},
	{
		garage_id: 155,
		part_id: 26,
		quantity: 451,
	},
	{
		garage_id: 155,
		part_id: 27,
		quantity: 95,
	},
	{
		garage_id: 155,
		part_id: 28,
		quantity: 291,
	},
	{
		garage_id: 155,
		part_id: 29,
		quantity: 269,
	},
	{
		garage_id: 155,
		part_id: 30,
		quantity: 348,
	},
	{
		garage_id: 155,
		part_id: 31,
		quantity: 192,
	},
	{
		garage_id: 155,
		part_id: 32,
		quantity: 179,
	},
	{
		garage_id: 155,
		part_id: 33,
		quantity: 266,
	},
	{
		garage_id: 155,
		part_id: 34,
		quantity: 204,
	},
	{
		garage_id: 155,
		part_id: 35,
		quantity: 489,
	},
	{
		garage_id: 155,
		part_id: 36,
		quantity: 218,
	},
	{
		garage_id: 155,
		part_id: 37,
		quantity: 232,
	},
	{
		garage_id: 155,
		part_id: 38,
		quantity: 194,
	},
	{
		garage_id: 155,
		part_id: 39,
		quantity: 258,
	},
	{
		garage_id: 155,
		part_id: 40,
		quantity: 228,
	},
	{
		garage_id: 155,
		part_id: 41,
		quantity: 319,
	},
	{
		garage_id: 155,
		part_id: 42,
		quantity: 249,
	},
	{
		garage_id: 155,
		part_id: 43,
		quantity: 151,
	},
	{
		garage_id: 155,
		part_id: 44,
		quantity: 290,
	},
	{
		garage_id: 155,
		part_id: 45,
		quantity: 449,
	},
	{
		garage_id: 155,
		part_id: 46,
		quantity: 207,
	},
	{
		garage_id: 155,
		part_id: 47,
		quantity: 395,
	},
	{
		garage_id: 155,
		part_id: 48,
		quantity: 171,
	},
	{
		garage_id: 155,
		part_id: 49,
		quantity: 186,
	},
	{
		garage_id: 155,
		part_id: 50,
		quantity: 364,
	},
	{
		garage_id: 155,
		part_id: 51,
		quantity: 234,
	},
	{
		garage_id: 155,
		part_id: 52,
		quantity: 481,
	},
	{
		garage_id: 155,
		part_id: 53,
		quantity: 222,
	},
	{
		garage_id: 155,
		part_id: 54,
		quantity: 340,
	},
	{
		garage_id: 155,
		part_id: 55,
		quantity: 469,
	},
	{
		garage_id: 155,
		part_id: 56,
		quantity: 183,
	},
	{
		garage_id: 155,
		part_id: 57,
		quantity: 170,
	},
	{
		garage_id: 155,
		part_id: 58,
		quantity: 466,
	},
	{
		garage_id: 155,
		part_id: 59,
		quantity: 354,
	},
	{
		garage_id: 155,
		part_id: 60,
		quantity: 269,
	},
	{
		garage_id: 155,
		part_id: 61,
		quantity: 54,
	},
	{
		garage_id: 155,
		part_id: 62,
		quantity: 168,
	},
	{
		garage_id: 155,
		part_id: 63,
		quantity: 39,
	},
	{
		garage_id: 155,
		part_id: 64,
		quantity: 419,
	},
	{
		garage_id: 155,
		part_id: 65,
		quantity: 423,
	},
	{
		garage_id: 155,
		part_id: 66,
		quantity: 295,
	},
	{
		garage_id: 155,
		part_id: 67,
		quantity: 375,
	},
	{
		garage_id: 155,
		part_id: 68,
		quantity: 221,
	},
	{
		garage_id: 155,
		part_id: 69,
		quantity: 63,
	},
	{
		garage_id: 155,
		part_id: 70,
		quantity: 108,
	},
	{
		garage_id: 155,
		part_id: 71,
		quantity: 80,
	},
	{
		garage_id: 155,
		part_id: 72,
		quantity: 410,
	},
	{
		garage_id: 155,
		part_id: 73,
		quantity: 154,
	},
	{
		garage_id: 155,
		part_id: 74,
		quantity: 326,
	},
	{
		garage_id: 155,
		part_id: 75,
		quantity: 398,
	},
	{
		garage_id: 155,
		part_id: 76,
		quantity: 375,
	},
	{
		garage_id: 155,
		part_id: 77,
		quantity: 393,
	},
	{
		garage_id: 155,
		part_id: 78,
		quantity: 122,
	},
	{
		garage_id: 155,
		part_id: 79,
		quantity: 481,
	},
	{
		garage_id: 155,
		part_id: 80,
		quantity: 423,
	},
	{
		garage_id: 155,
		part_id: 81,
		quantity: 416,
	},
	{
		garage_id: 155,
		part_id: 82,
		quantity: 364,
	},
	{
		garage_id: 155,
		part_id: 83,
		quantity: 282,
	},
	{
		garage_id: 155,
		part_id: 84,
		quantity: 190,
	},
	{
		garage_id: 155,
		part_id: 85,
		quantity: 124,
	},
	{
		garage_id: 155,
		part_id: 86,
		quantity: 182,
	},
	{
		garage_id: 155,
		part_id: 87,
		quantity: 489,
	},
	{
		garage_id: 155,
		part_id: 88,
		quantity: 19,
	},
	{
		garage_id: 155,
		part_id: 89,
		quantity: 18,
	},
	{
		garage_id: 155,
		part_id: 90,
		quantity: 345,
	},
	{
		garage_id: 155,
		part_id: 91,
		quantity: 163,
	},
	{
		garage_id: 155,
		part_id: 92,
		quantity: 146,
	},
	{
		garage_id: 155,
		part_id: 93,
		quantity: 141,
	},
	{
		garage_id: 155,
		part_id: 94,
		quantity: 304,
	},
	{
		garage_id: 155,
		part_id: 95,
		quantity: 404,
	},
	{
		garage_id: 155,
		part_id: 96,
		quantity: 202,
	},
	{
		garage_id: 155,
		part_id: 97,
		quantity: 385,
	},
	{
		garage_id: 155,
		part_id: 98,
		quantity: 438,
	},
	{
		garage_id: 155,
		part_id: 99,
		quantity: 325,
	},
	{
		garage_id: 155,
		part_id: 100,
		quantity: 375,
	},
	{
		garage_id: 155,
		part_id: 101,
		quantity: 398,
	},
	{
		garage_id: 155,
		part_id: 102,
		quantity: 114,
	},
	{
		garage_id: 155,
		part_id: 103,
		quantity: 148,
	},
	{
		garage_id: 155,
		part_id: 104,
		quantity: 61,
	},
	{
		garage_id: 155,
		part_id: 105,
		quantity: 250,
	},
	{
		garage_id: 155,
		part_id: 106,
		quantity: 144,
	},
	{
		garage_id: 155,
		part_id: 107,
		quantity: 468,
	},
	{
		garage_id: 155,
		part_id: 108,
		quantity: 486,
	},
	{
		garage_id: 155,
		part_id: 109,
		quantity: 219,
	},
	{
		garage_id: 155,
		part_id: 110,
		quantity: 22,
	},
	{
		garage_id: 155,
		part_id: 111,
		quantity: 308,
	},
	{
		garage_id: 155,
		part_id: 112,
		quantity: 352,
	},
	{
		garage_id: 155,
		part_id: 113,
		quantity: 224,
	},
	{
		garage_id: 155,
		part_id: 114,
		quantity: 203,
	},
	{
		garage_id: 155,
		part_id: 115,
		quantity: 360,
	},
	{
		garage_id: 155,
		part_id: 116,
		quantity: 200,
	},
	{
		garage_id: 155,
		part_id: 117,
		quantity: 481,
	},
	{
		garage_id: 155,
		part_id: 118,
		quantity: 98,
	},
	{
		garage_id: 155,
		part_id: 119,
		quantity: 437,
	},
	{
		garage_id: 155,
		part_id: 120,
		quantity: 23,
	},
	{
		garage_id: 155,
		part_id: 121,
		quantity: 91,
	},
	{
		garage_id: 155,
		part_id: 122,
		quantity: 176,
	},
	{
		garage_id: 155,
		part_id: 123,
		quantity: 19,
	},
	{
		garage_id: 155,
		part_id: 124,
		quantity: 98,
	},
	{
		garage_id: 155,
		part_id: 125,
		quantity: 181,
	},
	{
		garage_id: 155,
		part_id: 126,
		quantity: 465,
	},
	{
		garage_id: 155,
		part_id: 127,
		quantity: 381,
	},
	{
		garage_id: 155,
		part_id: 128,
		quantity: 274,
	},
	{
		garage_id: 155,
		part_id: 129,
		quantity: 201,
	},
	{
		garage_id: 155,
		part_id: 130,
		quantity: 325,
	},
	{
		garage_id: 155,
		part_id: 131,
		quantity: 212,
	},
	{
		garage_id: 155,
		part_id: 132,
		quantity: 63,
	},
	{
		garage_id: 155,
		part_id: 133,
		quantity: 295,
	},
	{
		garage_id: 155,
		part_id: 134,
		quantity: 129,
	},
	{
		garage_id: 155,
		part_id: 135,
		quantity: 40,
	},
	{
		garage_id: 155,
		part_id: 136,
		quantity: 27,
	},
	{
		garage_id: 155,
		part_id: 137,
		quantity: 344,
	},
	{
		garage_id: 155,
		part_id: 138,
		quantity: 443,
	},
	{
		garage_id: 155,
		part_id: 139,
		quantity: 41,
	},
	{
		garage_id: 155,
		part_id: 140,
		quantity: 153,
	},
	{
		garage_id: 155,
		part_id: 141,
		quantity: 388,
	},
	{
		garage_id: 155,
		part_id: 142,
		quantity: 241,
	},
	{
		garage_id: 155,
		part_id: 143,
		quantity: 285,
	},
	{
		garage_id: 155,
		part_id: 144,
		quantity: 110,
	},
	{
		garage_id: 155,
		part_id: 145,
		quantity: 76,
	},
	{
		garage_id: 155,
		part_id: 146,
		quantity: 363,
	},
	{
		garage_id: 155,
		part_id: 147,
		quantity: 91,
	},
	{
		garage_id: 155,
		part_id: 148,
		quantity: 274,
	},
	{
		garage_id: 155,
		part_id: 149,
		quantity: 360,
	},
	{
		garage_id: 155,
		part_id: 150,
		quantity: 355,
	},
	{
		garage_id: 155,
		part_id: 151,
		quantity: 396,
	},
	{
		garage_id: 155,
		part_id: 152,
		quantity: 356,
	},
	{
		garage_id: 155,
		part_id: 153,
		quantity: 269,
	},
	{
		garage_id: 155,
		part_id: 154,
		quantity: 60,
	},
	{
		garage_id: 155,
		part_id: 155,
		quantity: 48,
	},
	{
		garage_id: 155,
		part_id: 156,
		quantity: 362,
	},
	{
		garage_id: 155,
		part_id: 157,
		quantity: 261,
	},
	{
		garage_id: 155,
		part_id: 158,
		quantity: 65,
	},
	{
		garage_id: 155,
		part_id: 159,
		quantity: 217,
	},
	{
		garage_id: 155,
		part_id: 160,
		quantity: 369,
	},
	{
		garage_id: 155,
		part_id: 161,
		quantity: 121,
	},
	{
		garage_id: 155,
		part_id: 162,
		quantity: 139,
	},
	{
		garage_id: 155,
		part_id: 163,
		quantity: 101,
	},
	{
		garage_id: 155,
		part_id: 164,
		quantity: 428,
	},
	{
		garage_id: 155,
		part_id: 165,
		quantity: 222,
	},
	{
		garage_id: 155,
		part_id: 166,
		quantity: 99,
	},
	{
		garage_id: 155,
		part_id: 167,
		quantity: 317,
	},
	{
		garage_id: 155,
		part_id: 168,
		quantity: 123,
	},
	{
		garage_id: 155,
		part_id: 169,
		quantity: 394,
	},
	{
		garage_id: 155,
		part_id: 170,
		quantity: 211,
	},
	{
		garage_id: 155,
		part_id: 171,
		quantity: 166,
	},
	{
		garage_id: 155,
		part_id: 172,
		quantity: 446,
	},
	{
		garage_id: 155,
		part_id: 173,
		quantity: 274,
	},
	{
		garage_id: 155,
		part_id: 174,
		quantity: 301,
	},
	{
		garage_id: 155,
		part_id: 175,
		quantity: 268,
	},
	{
		garage_id: 155,
		part_id: 176,
		quantity: 423,
	},
	{
		garage_id: 155,
		part_id: 177,
		quantity: 24,
	},
	{
		garage_id: 155,
		part_id: 178,
		quantity: 131,
	},
	{
		garage_id: 155,
		part_id: 179,
		quantity: 117,
	},
	{
		garage_id: 155,
		part_id: 180,
		quantity: 225,
	},
	{
		garage_id: 155,
		part_id: 181,
		quantity: 115,
	},
	{
		garage_id: 155,
		part_id: 182,
		quantity: 193,
	},
	{
		garage_id: 155,
		part_id: 183,
		quantity: 226,
	},
	{
		garage_id: 155,
		part_id: 184,
		quantity: 453,
	},
	{
		garage_id: 155,
		part_id: 185,
		quantity: 296,
	},
	{
		garage_id: 155,
		part_id: 186,
		quantity: 45,
	},
	{
		garage_id: 155,
		part_id: 187,
		quantity: 264,
	},
	{
		garage_id: 155,
		part_id: 188,
		quantity: 458,
	},
	{
		garage_id: 155,
		part_id: 189,
		quantity: 363,
	},
	{
		garage_id: 155,
		part_id: 190,
		quantity: 358,
	},
	{
		garage_id: 155,
		part_id: 191,
		quantity: 54,
	},
	{
		garage_id: 155,
		part_id: 192,
		quantity: 447,
	},
	{
		garage_id: 155,
		part_id: 193,
		quantity: 51,
	},
	{
		garage_id: 155,
		part_id: 194,
		quantity: 17,
	},
	{
		garage_id: 155,
		part_id: 195,
		quantity: 479,
	},
	{
		garage_id: 155,
		part_id: 196,
		quantity: 14,
	},
	{
		garage_id: 155,
		part_id: 197,
		quantity: 60,
	},
	{
		garage_id: 155,
		part_id: 198,
		quantity: 481,
	},
	{
		garage_id: 155,
		part_id: 199,
		quantity: 407,
	},
	{
		garage_id: 155,
		part_id: 200,
		quantity: 375,
	},
	{
		garage_id: 155,
		part_id: 201,
		quantity: 171,
	},
	{
		garage_id: 155,
		part_id: 202,
		quantity: 367,
	},
	{
		garage_id: 155,
		part_id: 203,
		quantity: 446,
	},
	{
		garage_id: 155,
		part_id: 204,
		quantity: 330,
	},
	{
		garage_id: 155,
		part_id: 205,
		quantity: 260,
	},
	{
		garage_id: 155,
		part_id: 206,
		quantity: 137,
	},
	{
		garage_id: 155,
		part_id: 207,
		quantity: 358,
	},
	{
		garage_id: 155,
		part_id: 208,
		quantity: 264,
	},
	{
		garage_id: 155,
		part_id: 209,
		quantity: 266,
	},
	{
		garage_id: 155,
		part_id: 210,
		quantity: 155,
	},
	{
		garage_id: 155,
		part_id: 211,
		quantity: 464,
	},
	{
		garage_id: 155,
		part_id: 212,
		quantity: 249,
	},
	{
		garage_id: 155,
		part_id: 213,
		quantity: 234,
	},
	{
		garage_id: 155,
		part_id: 214,
		quantity: 406,
	},
	{
		garage_id: 155,
		part_id: 215,
		quantity: 299,
	},
	{
		garage_id: 155,
		part_id: 216,
		quantity: 463,
	},
	{
		garage_id: 155,
		part_id: 217,
		quantity: 210,
	},
	{
		garage_id: 155,
		part_id: 218,
		quantity: 95,
	},
	{
		garage_id: 155,
		part_id: 219,
		quantity: 441,
	},
	{
		garage_id: 155,
		part_id: 220,
		quantity: 388,
	},
	{
		garage_id: 155,
		part_id: 221,
		quantity: 307,
	},
	{
		garage_id: 155,
		part_id: 222,
		quantity: 203,
	},
	{
		garage_id: 155,
		part_id: 223,
		quantity: 295,
	},
	{
		garage_id: 155,
		part_id: 224,
		quantity: 9,
	},
	{
		garage_id: 155,
		part_id: 225,
		quantity: 196,
	},
	{
		garage_id: 155,
		part_id: 226,
		quantity: 281,
	},
	{
		garage_id: 155,
		part_id: 227,
		quantity: 451,
	},
	{
		garage_id: 155,
		part_id: 228,
		quantity: 173,
	},
	{
		garage_id: 155,
		part_id: 229,
		quantity: 269,
	},
	{
		garage_id: 155,
		part_id: 230,
		quantity: 234,
	},
	{
		garage_id: 155,
		part_id: 231,
		quantity: 329,
	},
	{
		garage_id: 155,
		part_id: 232,
		quantity: 283,
	},
	{
		garage_id: 155,
		part_id: 233,
		quantity: 435,
	},
	{
		garage_id: 155,
		part_id: 234,
		quantity: 203,
	},
	{
		garage_id: 155,
		part_id: 235,
		quantity: 409,
	},
	{
		garage_id: 155,
		part_id: 236,
		quantity: 39,
	},
	{
		garage_id: 155,
		part_id: 237,
		quantity: 401,
	},
	{
		garage_id: 155,
		part_id: 238,
		quantity: 272,
	},
	{
		garage_id: 155,
		part_id: 239,
		quantity: 345,
	},
	{
		garage_id: 155,
		part_id: 240,
		quantity: 348,
	},
	{
		garage_id: 155,
		part_id: 241,
		quantity: 365,
	},
	{
		garage_id: 155,
		part_id: 242,
		quantity: 43,
	},
	{
		garage_id: 155,
		part_id: 243,
		quantity: 474,
	},
	{
		garage_id: 155,
		part_id: 244,
		quantity: 454,
	},
	{
		garage_id: 155,
		part_id: 245,
		quantity: 175,
	},
	{
		garage_id: 155,
		part_id: 246,
		quantity: 389,
	},
	{
		garage_id: 155,
		part_id: 247,
		quantity: 149,
	},
	{
		garage_id: 155,
		part_id: 248,
		quantity: 476,
	},
	{
		garage_id: 155,
		part_id: 249,
		quantity: 195,
	},
	{
		garage_id: 155,
		part_id: 250,
		quantity: 117,
	},
	{
		garage_id: 155,
		part_id: 251,
		quantity: 361,
	},
	{
		garage_id: 155,
		part_id: 252,
		quantity: 72,
	},
	{
		garage_id: 155,
		part_id: 253,
		quantity: 387,
	},
	{
		garage_id: 155,
		part_id: 254,
		quantity: 308,
	},
	{
		garage_id: 155,
		part_id: 255,
		quantity: 103,
	},
	{
		garage_id: 155,
		part_id: 256,
		quantity: 88,
	},
	{
		garage_id: 155,
		part_id: 257,
		quantity: 387,
	},
	{
		garage_id: 155,
		part_id: 258,
		quantity: 387,
	},
	{
		garage_id: 155,
		part_id: 259,
		quantity: 174,
	},
	{
		garage_id: 155,
		part_id: 260,
		quantity: 265,
	},
	{
		garage_id: 155,
		part_id: 261,
		quantity: 416,
	},
	{
		garage_id: 155,
		part_id: 262,
		quantity: 342,
	},
	{
		garage_id: 155,
		part_id: 263,
		quantity: 482,
	},
	{
		garage_id: 155,
		part_id: 264,
		quantity: 416,
	},
	{
		garage_id: 155,
		part_id: 265,
		quantity: 425,
	},
	{
		garage_id: 155,
		part_id: 266,
		quantity: 268,
	},
	{
		garage_id: 155,
		part_id: 267,
		quantity: 134,
	},
	{
		garage_id: 155,
		part_id: 268,
		quantity: 242,
	},
	{
		garage_id: 155,
		part_id: 269,
		quantity: 411,
	},
	{
		garage_id: 155,
		part_id: 270,
		quantity: 430,
	},
	{
		garage_id: 155,
		part_id: 271,
		quantity: 202,
	},
	{
		garage_id: 155,
		part_id: 272,
		quantity: 16,
	},
	{
		garage_id: 155,
		part_id: 273,
		quantity: 250,
	},
	{
		garage_id: 155,
		part_id: 274,
		quantity: 291,
	},
	{
		garage_id: 155,
		part_id: 275,
		quantity: 323,
	},
	{
		garage_id: 155,
		part_id: 276,
		quantity: 472,
	},
	{
		garage_id: 155,
		part_id: 277,
		quantity: 318,
	},
	{
		garage_id: 155,
		part_id: 278,
		quantity: 275,
	},
	{
		garage_id: 155,
		part_id: 279,
		quantity: 487,
	},
	{
		garage_id: 155,
		part_id: 280,
		quantity: 366,
	},
	{
		garage_id: 155,
		part_id: 281,
		quantity: 394,
	},
	{
		garage_id: 155,
		part_id: 282,
		quantity: 367,
	},
	{
		garage_id: 155,
		part_id: 283,
		quantity: 339,
	},
	{
		garage_id: 155,
		part_id: 284,
		quantity: 327,
	},
	{
		garage_id: 155,
		part_id: 285,
		quantity: 340,
	},
	{
		garage_id: 155,
		part_id: 286,
		quantity: 104,
	},
	{
		garage_id: 155,
		part_id: 287,
		quantity: 227,
	},
	{
		garage_id: 155,
		part_id: 288,
		quantity: 480,
	},
	{
		garage_id: 155,
		part_id: 289,
		quantity: 462,
	},
	{
		garage_id: 155,
		part_id: 290,
		quantity: 397,
	},
	{
		garage_id: 155,
		part_id: 291,
		quantity: 151,
	},
	{
		garage_id: 155,
		part_id: 292,
		quantity: 393,
	},
	{
		garage_id: 155,
		part_id: 293,
		quantity: 412,
	},
	{
		garage_id: 155,
		part_id: 294,
		quantity: 189,
	},
	{
		garage_id: 155,
		part_id: 295,
		quantity: 289,
	},
	{
		garage_id: 155,
		part_id: 296,
		quantity: 240,
	},
	{
		garage_id: 155,
		part_id: 297,
		quantity: 349,
	},
	{
		garage_id: 155,
		part_id: 298,
		quantity: 151,
	},
	{
		garage_id: 155,
		part_id: 299,
		quantity: 196,
	},
	{
		garage_id: 155,
		part_id: 300,
		quantity: 259,
	},
	{
		garage_id: 155,
		part_id: 301,
		quantity: 124,
	},
	{
		garage_id: 155,
		part_id: 302,
		quantity: 125,
	},
	{
		garage_id: 155,
		part_id: 303,
		quantity: 393,
	},
	{
		garage_id: 155,
		part_id: 304,
		quantity: 122,
	},
	{
		garage_id: 155,
		part_id: 305,
		quantity: 387,
	},
	{
		garage_id: 155,
		part_id: 306,
		quantity: 196,
	},
	{
		garage_id: 155,
		part_id: 307,
		quantity: 447,
	},
	{
		garage_id: 155,
		part_id: 308,
		quantity: 275,
	},
	{
		garage_id: 155,
		part_id: 309,
		quantity: 397,
	},
	{
		garage_id: 155,
		part_id: 310,
		quantity: 345,
	},
	{
		garage_id: 155,
		part_id: 311,
		quantity: 80,
	},
	{
		garage_id: 155,
		part_id: 312,
		quantity: 257,
	},
	{
		garage_id: 155,
		part_id: 313,
		quantity: 178,
	},
	{
		garage_id: 155,
		part_id: 314,
		quantity: 4,
	},
	{
		garage_id: 155,
		part_id: 315,
		quantity: 43,
	},
	{
		garage_id: 155,
		part_id: 316,
		quantity: 10,
	},
	{
		garage_id: 155,
		part_id: 317,
		quantity: 413,
	},
	{
		garage_id: 155,
		part_id: 318,
		quantity: 29,
	},
	{
		garage_id: 155,
		part_id: 319,
		quantity: 167,
	},
	{
		garage_id: 155,
		part_id: 320,
		quantity: 355,
	},
	{
		garage_id: 155,
		part_id: 321,
		quantity: 437,
	},
	{
		garage_id: 155,
		part_id: 322,
		quantity: 92,
	},
	{
		garage_id: 155,
		part_id: 323,
		quantity: 141,
	},
	{
		garage_id: 155,
		part_id: 324,
		quantity: 241,
	},
	{
		garage_id: 155,
		part_id: 325,
		quantity: 147,
	},
	{
		garage_id: 155,
		part_id: 326,
		quantity: 37,
	},
	{
		garage_id: 155,
		part_id: 327,
		quantity: 135,
	},
	{
		garage_id: 155,
		part_id: 328,
		quantity: 57,
	},
	{
		garage_id: 155,
		part_id: 329,
		quantity: 271,
	},
	{
		garage_id: 155,
		part_id: 330,
		quantity: 288,
	},
	{
		garage_id: 155,
		part_id: 331,
		quantity: 38,
	},
	{
		garage_id: 155,
		part_id: 332,
		quantity: 43,
	},
	{
		garage_id: 155,
		part_id: 333,
		quantity: 242,
	},
	{
		garage_id: 155,
		part_id: 334,
		quantity: 278,
	},
	{
		garage_id: 155,
		part_id: 335,
		quantity: 348,
	},
	{
		garage_id: 155,
		part_id: 336,
		quantity: 89,
	},
	{
		garage_id: 155,
		part_id: 337,
		quantity: 470,
	},
	{
		garage_id: 155,
		part_id: 338,
		quantity: 396,
	},
	{
		garage_id: 155,
		part_id: 339,
		quantity: 106,
	},
	{
		garage_id: 155,
		part_id: 340,
		quantity: 284,
	},
	{
		garage_id: 155,
		part_id: 341,
		quantity: 431,
	},
	{
		garage_id: 155,
		part_id: 342,
		quantity: 149,
	},
	{
		garage_id: 155,
		part_id: 343,
		quantity: 25,
	},
	{
		garage_id: 155,
		part_id: 344,
		quantity: 468,
	},
	{
		garage_id: 155,
		part_id: 345,
		quantity: 215,
	},
	{
		garage_id: 155,
		part_id: 346,
		quantity: 81,
	},
	{
		garage_id: 155,
		part_id: 347,
		quantity: 47,
	},
	{
		garage_id: 155,
		part_id: 348,
		quantity: 350,
	},
	{
		garage_id: 155,
		part_id: 349,
		quantity: 307,
	},
	{
		garage_id: 155,
		part_id: 350,
		quantity: 111,
	},
	{
		garage_id: 155,
		part_id: 351,
		quantity: 417,
	},
	{
		garage_id: 155,
		part_id: 352,
		quantity: 112,
	},
	{
		garage_id: 155,
		part_id: 353,
		quantity: 449,
	},
	{
		garage_id: 155,
		part_id: 354,
		quantity: 169,
	},
	{
		garage_id: 155,
		part_id: 355,
		quantity: 293,
	},
	{
		garage_id: 155,
		part_id: 356,
		quantity: 223,
	},
	{
		garage_id: 155,
		part_id: 357,
		quantity: 62,
	},
	{
		garage_id: 155,
		part_id: 358,
		quantity: 477,
	},
	{
		garage_id: 155,
		part_id: 359,
		quantity: 250,
	},
	{
		garage_id: 155,
		part_id: 360,
		quantity: 36,
	},
	{
		garage_id: 155,
		part_id: 361,
		quantity: 181,
	},
	{
		garage_id: 155,
		part_id: 362,
		quantity: 97,
	},
	{
		garage_id: 155,
		part_id: 363,
		quantity: 400,
	},
	{
		garage_id: 155,
		part_id: 364,
		quantity: 209,
	},
	{
		garage_id: 155,
		part_id: 365,
		quantity: 105,
	},
	{
		garage_id: 155,
		part_id: 366,
		quantity: 371,
	},
	{
		garage_id: 155,
		part_id: 367,
		quantity: 428,
	},
	{
		garage_id: 155,
		part_id: 368,
		quantity: 331,
	},
	{
		garage_id: 155,
		part_id: 369,
		quantity: 405,
	},
	{
		garage_id: 155,
		part_id: 370,
		quantity: 305,
	},
	{
		garage_id: 155,
		part_id: 371,
		quantity: 262,
	},
	{
		garage_id: 155,
		part_id: 372,
		quantity: 139,
	},
	{
		garage_id: 155,
		part_id: 373,
		quantity: 289,
	},
	{
		garage_id: 155,
		part_id: 374,
		quantity: 79,
	},
	{
		garage_id: 155,
		part_id: 375,
		quantity: 184,
	},
	{
		garage_id: 155,
		part_id: 376,
		quantity: 72,
	},
	{
		garage_id: 155,
		part_id: 377,
		quantity: 79,
	},
	{
		garage_id: 155,
		part_id: 378,
		quantity: 55,
	},
	{
		garage_id: 155,
		part_id: 379,
		quantity: 4,
	},
	{
		garage_id: 155,
		part_id: 380,
		quantity: 153,
	},
	{
		garage_id: 155,
		part_id: 381,
		quantity: 383,
	},
	{
		garage_id: 155,
		part_id: 382,
		quantity: 10,
	},
	{
		garage_id: 155,
		part_id: 383,
		quantity: 231,
	},
	{
		garage_id: 155,
		part_id: 384,
		quantity: 272,
	},
	{
		garage_id: 155,
		part_id: 385,
		quantity: 322,
	},
	{
		garage_id: 155,
		part_id: 386,
		quantity: 134,
	},
	{
		garage_id: 155,
		part_id: 387,
		quantity: 104,
	},
	{
		garage_id: 155,
		part_id: 388,
		quantity: 86,
	},
	{
		garage_id: 155,
		part_id: 389,
		quantity: 9,
	},
	{
		garage_id: 155,
		part_id: 390,
		quantity: 342,
	},
	{
		garage_id: 155,
		part_id: 391,
		quantity: 206,
	},
	{
		garage_id: 155,
		part_id: 392,
		quantity: 232,
	},
	{
		garage_id: 155,
		part_id: 393,
		quantity: 20,
	},
	{
		garage_id: 155,
		part_id: 394,
		quantity: 445,
	},
	{
		garage_id: 155,
		part_id: 395,
		quantity: 495,
	},
	{
		garage_id: 155,
		part_id: 396,
		quantity: 356,
	},
	{
		garage_id: 155,
		part_id: 397,
		quantity: 16,
	},
	{
		garage_id: 155,
		part_id: 398,
		quantity: 148,
	},
	{
		garage_id: 155,
		part_id: 399,
		quantity: 30,
	},
	{
		garage_id: 156,
		part_id: 0,
		quantity: 9,
	},
	{
		garage_id: 156,
		part_id: 1,
		quantity: 186,
	},
	{
		garage_id: 156,
		part_id: 2,
		quantity: 65,
	},
	{
		garage_id: 156,
		part_id: 3,
		quantity: 336,
	},
	{
		garage_id: 156,
		part_id: 4,
		quantity: 426,
	},
	{
		garage_id: 156,
		part_id: 5,
		quantity: 294,
	},
	{
		garage_id: 156,
		part_id: 6,
		quantity: 225,
	},
	{
		garage_id: 156,
		part_id: 7,
		quantity: 495,
	},
	{
		garage_id: 156,
		part_id: 8,
		quantity: 17,
	},
	{
		garage_id: 156,
		part_id: 9,
		quantity: 334,
	},
	{
		garage_id: 156,
		part_id: 10,
		quantity: 242,
	},
	{
		garage_id: 156,
		part_id: 11,
		quantity: 225,
	},
	{
		garage_id: 156,
		part_id: 12,
		quantity: 269,
	},
	{
		garage_id: 156,
		part_id: 13,
		quantity: 388,
	},
	{
		garage_id: 156,
		part_id: 14,
		quantity: 188,
	},
	{
		garage_id: 156,
		part_id: 15,
		quantity: 286,
	},
	{
		garage_id: 156,
		part_id: 16,
		quantity: 394,
	},
	{
		garage_id: 156,
		part_id: 17,
		quantity: 232,
	},
	{
		garage_id: 156,
		part_id: 18,
		quantity: 162,
	},
	{
		garage_id: 156,
		part_id: 19,
		quantity: 406,
	},
	{
		garage_id: 156,
		part_id: 20,
		quantity: 99,
	},
	{
		garage_id: 156,
		part_id: 21,
		quantity: 116,
	},
	{
		garage_id: 156,
		part_id: 22,
		quantity: 137,
	},
	{
		garage_id: 156,
		part_id: 23,
		quantity: 198,
	},
	{
		garage_id: 156,
		part_id: 24,
		quantity: 350,
	},
	{
		garage_id: 156,
		part_id: 25,
		quantity: 437,
	},
	{
		garage_id: 156,
		part_id: 26,
		quantity: 451,
	},
	{
		garage_id: 156,
		part_id: 27,
		quantity: 233,
	},
	{
		garage_id: 156,
		part_id: 28,
		quantity: 344,
	},
	{
		garage_id: 156,
		part_id: 29,
		quantity: 249,
	},
	{
		garage_id: 156,
		part_id: 30,
		quantity: 353,
	},
	{
		garage_id: 156,
		part_id: 31,
		quantity: 17,
	},
	{
		garage_id: 156,
		part_id: 32,
		quantity: 157,
	},
	{
		garage_id: 156,
		part_id: 33,
		quantity: 369,
	},
	{
		garage_id: 156,
		part_id: 34,
		quantity: 229,
	},
	{
		garage_id: 156,
		part_id: 35,
		quantity: 288,
	},
	{
		garage_id: 156,
		part_id: 36,
		quantity: 401,
	},
	{
		garage_id: 156,
		part_id: 37,
		quantity: 375,
	},
	{
		garage_id: 156,
		part_id: 38,
		quantity: 258,
	},
	{
		garage_id: 156,
		part_id: 39,
		quantity: 3,
	},
	{
		garage_id: 156,
		part_id: 40,
		quantity: 315,
	},
	{
		garage_id: 156,
		part_id: 41,
		quantity: 341,
	},
	{
		garage_id: 156,
		part_id: 42,
		quantity: 269,
	},
	{
		garage_id: 156,
		part_id: 43,
		quantity: 112,
	},
	{
		garage_id: 156,
		part_id: 44,
		quantity: 339,
	},
	{
		garage_id: 156,
		part_id: 45,
		quantity: 213,
	},
	{
		garage_id: 156,
		part_id: 46,
		quantity: 380,
	},
	{
		garage_id: 156,
		part_id: 47,
		quantity: 282,
	},
	{
		garage_id: 156,
		part_id: 48,
		quantity: 342,
	},
	{
		garage_id: 156,
		part_id: 49,
		quantity: 288,
	},
	{
		garage_id: 156,
		part_id: 50,
		quantity: 68,
	},
	{
		garage_id: 156,
		part_id: 51,
		quantity: 5,
	},
	{
		garage_id: 156,
		part_id: 52,
		quantity: 337,
	},
	{
		garage_id: 156,
		part_id: 53,
		quantity: 350,
	},
	{
		garage_id: 156,
		part_id: 54,
		quantity: 472,
	},
	{
		garage_id: 156,
		part_id: 55,
		quantity: 494,
	},
	{
		garage_id: 156,
		part_id: 56,
		quantity: 308,
	},
	{
		garage_id: 156,
		part_id: 57,
		quantity: 436,
	},
	{
		garage_id: 156,
		part_id: 58,
		quantity: 399,
	},
	{
		garage_id: 156,
		part_id: 59,
		quantity: 225,
	},
	{
		garage_id: 156,
		part_id: 60,
		quantity: 126,
	},
	{
		garage_id: 156,
		part_id: 61,
		quantity: 9,
	},
	{
		garage_id: 156,
		part_id: 62,
		quantity: 152,
	},
	{
		garage_id: 156,
		part_id: 63,
		quantity: 495,
	},
	{
		garage_id: 156,
		part_id: 64,
		quantity: 241,
	},
	{
		garage_id: 156,
		part_id: 65,
		quantity: 197,
	},
	{
		garage_id: 156,
		part_id: 66,
		quantity: 131,
	},
	{
		garage_id: 156,
		part_id: 67,
		quantity: 234,
	},
	{
		garage_id: 156,
		part_id: 68,
		quantity: 150,
	},
	{
		garage_id: 156,
		part_id: 69,
		quantity: 190,
	},
	{
		garage_id: 156,
		part_id: 70,
		quantity: 399,
	},
	{
		garage_id: 156,
		part_id: 71,
		quantity: 275,
	},
	{
		garage_id: 156,
		part_id: 72,
		quantity: 4,
	},
	{
		garage_id: 156,
		part_id: 73,
		quantity: 327,
	},
	{
		garage_id: 156,
		part_id: 74,
		quantity: 438,
	},
	{
		garage_id: 156,
		part_id: 75,
		quantity: 103,
	},
	{
		garage_id: 156,
		part_id: 76,
		quantity: 124,
	},
	{
		garage_id: 156,
		part_id: 77,
		quantity: 161,
	},
	{
		garage_id: 156,
		part_id: 78,
		quantity: 182,
	},
	{
		garage_id: 156,
		part_id: 79,
		quantity: 367,
	},
	{
		garage_id: 156,
		part_id: 80,
		quantity: 226,
	},
	{
		garage_id: 156,
		part_id: 81,
		quantity: 476,
	},
	{
		garage_id: 156,
		part_id: 82,
		quantity: 116,
	},
	{
		garage_id: 156,
		part_id: 83,
		quantity: 233,
	},
	{
		garage_id: 156,
		part_id: 84,
		quantity: 110,
	},
	{
		garage_id: 156,
		part_id: 85,
		quantity: 94,
	},
	{
		garage_id: 156,
		part_id: 86,
		quantity: 231,
	},
	{
		garage_id: 156,
		part_id: 87,
		quantity: 472,
	},
	{
		garage_id: 156,
		part_id: 88,
		quantity: 385,
	},
	{
		garage_id: 156,
		part_id: 89,
		quantity: 134,
	},
	{
		garage_id: 156,
		part_id: 90,
		quantity: 479,
	},
	{
		garage_id: 156,
		part_id: 91,
		quantity: 231,
	},
	{
		garage_id: 156,
		part_id: 92,
		quantity: 140,
	},
	{
		garage_id: 156,
		part_id: 93,
		quantity: 222,
	},
	{
		garage_id: 156,
		part_id: 94,
		quantity: 421,
	},
	{
		garage_id: 156,
		part_id: 95,
		quantity: 61,
	},
	{
		garage_id: 156,
		part_id: 96,
		quantity: 92,
	},
	{
		garage_id: 156,
		part_id: 97,
		quantity: 20,
	},
	{
		garage_id: 156,
		part_id: 98,
		quantity: 471,
	},
	{
		garage_id: 156,
		part_id: 99,
		quantity: 26,
	},
	{
		garage_id: 156,
		part_id: 100,
		quantity: 107,
	},
	{
		garage_id: 156,
		part_id: 101,
		quantity: 82,
	},
	{
		garage_id: 156,
		part_id: 102,
		quantity: 29,
	},
	{
		garage_id: 156,
		part_id: 103,
		quantity: 308,
	},
	{
		garage_id: 156,
		part_id: 104,
		quantity: 128,
	},
	{
		garage_id: 156,
		part_id: 105,
		quantity: 157,
	},
	{
		garage_id: 156,
		part_id: 106,
		quantity: 210,
	},
	{
		garage_id: 156,
		part_id: 107,
		quantity: 237,
	},
	{
		garage_id: 156,
		part_id: 108,
		quantity: 271,
	},
	{
		garage_id: 156,
		part_id: 109,
		quantity: 313,
	},
	{
		garage_id: 156,
		part_id: 110,
		quantity: 178,
	},
	{
		garage_id: 156,
		part_id: 111,
		quantity: 28,
	},
	{
		garage_id: 156,
		part_id: 112,
		quantity: 24,
	},
	{
		garage_id: 156,
		part_id: 113,
		quantity: 88,
	},
	{
		garage_id: 156,
		part_id: 114,
		quantity: 271,
	},
	{
		garage_id: 156,
		part_id: 115,
		quantity: 263,
	},
	{
		garage_id: 156,
		part_id: 116,
		quantity: 18,
	},
	{
		garage_id: 156,
		part_id: 117,
		quantity: 109,
	},
	{
		garage_id: 156,
		part_id: 118,
		quantity: 98,
	},
	{
		garage_id: 156,
		part_id: 119,
		quantity: 421,
	},
	{
		garage_id: 156,
		part_id: 120,
		quantity: 130,
	},
	{
		garage_id: 156,
		part_id: 121,
		quantity: 167,
	},
	{
		garage_id: 156,
		part_id: 122,
		quantity: 151,
	},
	{
		garage_id: 156,
		part_id: 123,
		quantity: 394,
	},
	{
		garage_id: 156,
		part_id: 124,
		quantity: 317,
	},
	{
		garage_id: 156,
		part_id: 125,
		quantity: 57,
	},
	{
		garage_id: 156,
		part_id: 126,
		quantity: 222,
	},
	{
		garage_id: 156,
		part_id: 127,
		quantity: 147,
	},
	{
		garage_id: 156,
		part_id: 128,
		quantity: 80,
	},
	{
		garage_id: 156,
		part_id: 129,
		quantity: 494,
	},
	{
		garage_id: 156,
		part_id: 130,
		quantity: 388,
	},
	{
		garage_id: 156,
		part_id: 131,
		quantity: 164,
	},
	{
		garage_id: 156,
		part_id: 132,
		quantity: 219,
	},
	{
		garage_id: 156,
		part_id: 133,
		quantity: 339,
	},
	{
		garage_id: 156,
		part_id: 134,
		quantity: 239,
	},
	{
		garage_id: 156,
		part_id: 135,
		quantity: 422,
	},
	{
		garage_id: 156,
		part_id: 136,
		quantity: 96,
	},
	{
		garage_id: 156,
		part_id: 137,
		quantity: 221,
	},
	{
		garage_id: 156,
		part_id: 138,
		quantity: 3,
	},
	{
		garage_id: 156,
		part_id: 139,
		quantity: 42,
	},
	{
		garage_id: 156,
		part_id: 140,
		quantity: 97,
	},
	{
		garage_id: 156,
		part_id: 141,
		quantity: 191,
	},
	{
		garage_id: 156,
		part_id: 142,
		quantity: 400,
	},
	{
		garage_id: 156,
		part_id: 143,
		quantity: 265,
	},
	{
		garage_id: 156,
		part_id: 144,
		quantity: 423,
	},
	{
		garage_id: 156,
		part_id: 145,
		quantity: 494,
	},
	{
		garage_id: 156,
		part_id: 146,
		quantity: 119,
	},
	{
		garage_id: 156,
		part_id: 147,
		quantity: 473,
	},
	{
		garage_id: 156,
		part_id: 148,
		quantity: 198,
	},
	{
		garage_id: 156,
		part_id: 149,
		quantity: 357,
	},
	{
		garage_id: 156,
		part_id: 150,
		quantity: 109,
	},
	{
		garage_id: 156,
		part_id: 151,
		quantity: 489,
	},
	{
		garage_id: 156,
		part_id: 152,
		quantity: 28,
	},
	{
		garage_id: 156,
		part_id: 153,
		quantity: 209,
	},
	{
		garage_id: 156,
		part_id: 154,
		quantity: 449,
	},
	{
		garage_id: 156,
		part_id: 155,
		quantity: 460,
	},
	{
		garage_id: 156,
		part_id: 156,
		quantity: 336,
	},
	{
		garage_id: 156,
		part_id: 157,
		quantity: 7,
	},
	{
		garage_id: 156,
		part_id: 158,
		quantity: 133,
	},
	{
		garage_id: 156,
		part_id: 159,
		quantity: 36,
	},
	{
		garage_id: 156,
		part_id: 160,
		quantity: 185,
	},
	{
		garage_id: 156,
		part_id: 161,
		quantity: 27,
	},
	{
		garage_id: 156,
		part_id: 162,
		quantity: 409,
	},
	{
		garage_id: 156,
		part_id: 163,
		quantity: 25,
	},
	{
		garage_id: 156,
		part_id: 164,
		quantity: 470,
	},
	{
		garage_id: 156,
		part_id: 165,
		quantity: 74,
	},
	{
		garage_id: 156,
		part_id: 166,
		quantity: 432,
	},
	{
		garage_id: 156,
		part_id: 167,
		quantity: 152,
	},
	{
		garage_id: 156,
		part_id: 168,
		quantity: 493,
	},
	{
		garage_id: 156,
		part_id: 169,
		quantity: 39,
	},
	{
		garage_id: 156,
		part_id: 170,
		quantity: 121,
	},
	{
		garage_id: 156,
		part_id: 171,
		quantity: 189,
	},
	{
		garage_id: 156,
		part_id: 172,
		quantity: 470,
	},
	{
		garage_id: 156,
		part_id: 173,
		quantity: 15,
	},
	{
		garage_id: 156,
		part_id: 174,
		quantity: 173,
	},
	{
		garage_id: 156,
		part_id: 175,
		quantity: 202,
	},
	{
		garage_id: 156,
		part_id: 176,
		quantity: 348,
	},
	{
		garage_id: 156,
		part_id: 177,
		quantity: 313,
	},
	{
		garage_id: 156,
		part_id: 178,
		quantity: 62,
	},
	{
		garage_id: 156,
		part_id: 179,
		quantity: 39,
	},
	{
		garage_id: 156,
		part_id: 180,
		quantity: 300,
	},
	{
		garage_id: 156,
		part_id: 181,
		quantity: 47,
	},
	{
		garage_id: 156,
		part_id: 182,
		quantity: 495,
	},
	{
		garage_id: 156,
		part_id: 183,
		quantity: 123,
	},
	{
		garage_id: 156,
		part_id: 184,
		quantity: 197,
	},
	{
		garage_id: 156,
		part_id: 185,
		quantity: 2,
	},
	{
		garage_id: 156,
		part_id: 186,
		quantity: 259,
	},
	{
		garage_id: 156,
		part_id: 187,
		quantity: 34,
	},
	{
		garage_id: 156,
		part_id: 188,
		quantity: 443,
	},
	{
		garage_id: 156,
		part_id: 189,
		quantity: 425,
	},
	{
		garage_id: 156,
		part_id: 190,
		quantity: 480,
	},
	{
		garage_id: 156,
		part_id: 191,
		quantity: 408,
	},
	{
		garage_id: 156,
		part_id: 192,
		quantity: 336,
	},
	{
		garage_id: 156,
		part_id: 193,
		quantity: 79,
	},
	{
		garage_id: 156,
		part_id: 194,
		quantity: 196,
	},
	{
		garage_id: 156,
		part_id: 195,
		quantity: 75,
	},
	{
		garage_id: 156,
		part_id: 196,
		quantity: 243,
	},
	{
		garage_id: 156,
		part_id: 197,
		quantity: 113,
	},
	{
		garage_id: 156,
		part_id: 198,
		quantity: 323,
	},
	{
		garage_id: 156,
		part_id: 199,
		quantity: 349,
	},
	{
		garage_id: 156,
		part_id: 200,
		quantity: 10,
	},
	{
		garage_id: 156,
		part_id: 201,
		quantity: 45,
	},
	{
		garage_id: 156,
		part_id: 202,
		quantity: 204,
	},
	{
		garage_id: 156,
		part_id: 203,
		quantity: 211,
	},
	{
		garage_id: 156,
		part_id: 204,
		quantity: 341,
	},
	{
		garage_id: 156,
		part_id: 205,
		quantity: 487,
	},
	{
		garage_id: 156,
		part_id: 206,
		quantity: 388,
	},
	{
		garage_id: 156,
		part_id: 207,
		quantity: 238,
	},
	{
		garage_id: 156,
		part_id: 208,
		quantity: 127,
	},
	{
		garage_id: 156,
		part_id: 209,
		quantity: 264,
	},
	{
		garage_id: 156,
		part_id: 210,
		quantity: 337,
	},
	{
		garage_id: 156,
		part_id: 211,
		quantity: 82,
	},
	{
		garage_id: 156,
		part_id: 212,
		quantity: 65,
	},
	{
		garage_id: 156,
		part_id: 213,
		quantity: 413,
	},
	{
		garage_id: 156,
		part_id: 214,
		quantity: 187,
	},
	{
		garage_id: 156,
		part_id: 215,
		quantity: 378,
	},
	{
		garage_id: 156,
		part_id: 216,
		quantity: 320,
	},
	{
		garage_id: 156,
		part_id: 217,
		quantity: 106,
	},
	{
		garage_id: 156,
		part_id: 218,
		quantity: 15,
	},
	{
		garage_id: 156,
		part_id: 219,
		quantity: 189,
	},
	{
		garage_id: 156,
		part_id: 220,
		quantity: 184,
	},
	{
		garage_id: 156,
		part_id: 221,
		quantity: 164,
	},
	{
		garage_id: 156,
		part_id: 222,
		quantity: 74,
	},
	{
		garage_id: 156,
		part_id: 223,
		quantity: 400,
	},
	{
		garage_id: 156,
		part_id: 224,
		quantity: 284,
	},
	{
		garage_id: 156,
		part_id: 225,
		quantity: 154,
	},
	{
		garage_id: 156,
		part_id: 226,
		quantity: 25,
	},
	{
		garage_id: 156,
		part_id: 227,
		quantity: 457,
	},
	{
		garage_id: 156,
		part_id: 228,
		quantity: 424,
	},
	{
		garage_id: 156,
		part_id: 229,
		quantity: 170,
	},
	{
		garage_id: 156,
		part_id: 230,
		quantity: 489,
	},
	{
		garage_id: 156,
		part_id: 231,
		quantity: 323,
	},
	{
		garage_id: 156,
		part_id: 232,
		quantity: 280,
	},
	{
		garage_id: 156,
		part_id: 233,
		quantity: 145,
	},
	{
		garage_id: 156,
		part_id: 234,
		quantity: 26,
	},
	{
		garage_id: 156,
		part_id: 235,
		quantity: 145,
	},
	{
		garage_id: 156,
		part_id: 236,
		quantity: 253,
	},
	{
		garage_id: 156,
		part_id: 237,
		quantity: 96,
	},
	{
		garage_id: 156,
		part_id: 238,
		quantity: 243,
	},
	{
		garage_id: 156,
		part_id: 239,
		quantity: 499,
	},
	{
		garage_id: 156,
		part_id: 240,
		quantity: 125,
	},
	{
		garage_id: 156,
		part_id: 241,
		quantity: 456,
	},
	{
		garage_id: 156,
		part_id: 242,
		quantity: 38,
	},
	{
		garage_id: 156,
		part_id: 243,
		quantity: 236,
	},
	{
		garage_id: 156,
		part_id: 244,
		quantity: 331,
	},
	{
		garage_id: 156,
		part_id: 245,
		quantity: 223,
	},
	{
		garage_id: 156,
		part_id: 246,
		quantity: 11,
	},
	{
		garage_id: 156,
		part_id: 247,
		quantity: 125,
	},
	{
		garage_id: 156,
		part_id: 248,
		quantity: 408,
	},
	{
		garage_id: 156,
		part_id: 249,
		quantity: 399,
	},
	{
		garage_id: 156,
		part_id: 250,
		quantity: 324,
	},
	{
		garage_id: 156,
		part_id: 251,
		quantity: 433,
	},
	{
		garage_id: 156,
		part_id: 252,
		quantity: 221,
	},
	{
		garage_id: 156,
		part_id: 253,
		quantity: 154,
	},
	{
		garage_id: 156,
		part_id: 254,
		quantity: 267,
	},
	{
		garage_id: 156,
		part_id: 255,
		quantity: 402,
	},
	{
		garage_id: 156,
		part_id: 256,
		quantity: 56,
	},
	{
		garage_id: 156,
		part_id: 257,
		quantity: 61,
	},
	{
		garage_id: 156,
		part_id: 258,
		quantity: 55,
	},
	{
		garage_id: 156,
		part_id: 259,
		quantity: 279,
	},
	{
		garage_id: 156,
		part_id: 260,
		quantity: 367,
	},
	{
		garage_id: 156,
		part_id: 261,
		quantity: 6,
	},
	{
		garage_id: 156,
		part_id: 262,
		quantity: 122,
	},
	{
		garage_id: 156,
		part_id: 263,
		quantity: 19,
	},
	{
		garage_id: 156,
		part_id: 264,
		quantity: 174,
	},
	{
		garage_id: 156,
		part_id: 265,
		quantity: 265,
	},
	{
		garage_id: 156,
		part_id: 266,
		quantity: 9,
	},
	{
		garage_id: 156,
		part_id: 267,
		quantity: 78,
	},
	{
		garage_id: 156,
		part_id: 268,
		quantity: 111,
	},
	{
		garage_id: 156,
		part_id: 269,
		quantity: 302,
	},
	{
		garage_id: 156,
		part_id: 270,
		quantity: 322,
	},
	{
		garage_id: 156,
		part_id: 271,
		quantity: 357,
	},
	{
		garage_id: 156,
		part_id: 272,
		quantity: 322,
	},
	{
		garage_id: 156,
		part_id: 273,
		quantity: 90,
	},
	{
		garage_id: 156,
		part_id: 274,
		quantity: 107,
	},
	{
		garage_id: 156,
		part_id: 275,
		quantity: 185,
	},
	{
		garage_id: 156,
		part_id: 276,
		quantity: 367,
	},
	{
		garage_id: 156,
		part_id: 277,
		quantity: 195,
	},
	{
		garage_id: 156,
		part_id: 278,
		quantity: 8,
	},
	{
		garage_id: 156,
		part_id: 279,
		quantity: 99,
	},
	{
		garage_id: 156,
		part_id: 280,
		quantity: 163,
	},
	{
		garage_id: 156,
		part_id: 281,
		quantity: 397,
	},
	{
		garage_id: 156,
		part_id: 282,
		quantity: 210,
	},
	{
		garage_id: 156,
		part_id: 283,
		quantity: 257,
	},
	{
		garage_id: 156,
		part_id: 284,
		quantity: 479,
	},
	{
		garage_id: 156,
		part_id: 285,
		quantity: 81,
	},
	{
		garage_id: 156,
		part_id: 286,
		quantity: 188,
	},
	{
		garage_id: 156,
		part_id: 287,
		quantity: 171,
	},
	{
		garage_id: 156,
		part_id: 288,
		quantity: 293,
	},
	{
		garage_id: 156,
		part_id: 289,
		quantity: 61,
	},
	{
		garage_id: 156,
		part_id: 290,
		quantity: 364,
	},
	{
		garage_id: 156,
		part_id: 291,
		quantity: 262,
	},
	{
		garage_id: 156,
		part_id: 292,
		quantity: 289,
	},
	{
		garage_id: 156,
		part_id: 293,
		quantity: 278,
	},
	{
		garage_id: 156,
		part_id: 294,
		quantity: 174,
	},
	{
		garage_id: 156,
		part_id: 295,
		quantity: 113,
	},
	{
		garage_id: 156,
		part_id: 296,
		quantity: 305,
	},
	{
		garage_id: 156,
		part_id: 297,
		quantity: 319,
	},
	{
		garage_id: 156,
		part_id: 298,
		quantity: 93,
	},
	{
		garage_id: 156,
		part_id: 299,
		quantity: 500,
	},
	{
		garage_id: 156,
		part_id: 300,
		quantity: 441,
	},
	{
		garage_id: 156,
		part_id: 301,
		quantity: 386,
	},
	{
		garage_id: 156,
		part_id: 302,
		quantity: 117,
	},
	{
		garage_id: 156,
		part_id: 303,
		quantity: 111,
	},
	{
		garage_id: 156,
		part_id: 304,
		quantity: 383,
	},
	{
		garage_id: 156,
		part_id: 305,
		quantity: 237,
	},
	{
		garage_id: 156,
		part_id: 306,
		quantity: 245,
	},
	{
		garage_id: 156,
		part_id: 307,
		quantity: 385,
	},
	{
		garage_id: 156,
		part_id: 308,
		quantity: 482,
	},
	{
		garage_id: 156,
		part_id: 309,
		quantity: 57,
	},
	{
		garage_id: 156,
		part_id: 310,
		quantity: 443,
	},
	{
		garage_id: 156,
		part_id: 311,
		quantity: 207,
	},
	{
		garage_id: 156,
		part_id: 312,
		quantity: 132,
	},
	{
		garage_id: 156,
		part_id: 313,
		quantity: 104,
	},
	{
		garage_id: 156,
		part_id: 314,
		quantity: 342,
	},
	{
		garage_id: 156,
		part_id: 315,
		quantity: 239,
	},
	{
		garage_id: 156,
		part_id: 316,
		quantity: 169,
	},
	{
		garage_id: 156,
		part_id: 317,
		quantity: 500,
	},
	{
		garage_id: 156,
		part_id: 318,
		quantity: 38,
	},
	{
		garage_id: 156,
		part_id: 319,
		quantity: 114,
	},
	{
		garage_id: 156,
		part_id: 320,
		quantity: 424,
	},
	{
		garage_id: 156,
		part_id: 321,
		quantity: 239,
	},
	{
		garage_id: 156,
		part_id: 322,
		quantity: 64,
	},
	{
		garage_id: 156,
		part_id: 323,
		quantity: 492,
	},
	{
		garage_id: 156,
		part_id: 324,
		quantity: 40,
	},
	{
		garage_id: 156,
		part_id: 325,
		quantity: 499,
	},
	{
		garage_id: 156,
		part_id: 326,
		quantity: 19,
	},
	{
		garage_id: 156,
		part_id: 327,
		quantity: 391,
	},
	{
		garage_id: 156,
		part_id: 328,
		quantity: 461,
	},
	{
		garage_id: 156,
		part_id: 329,
		quantity: 52,
	},
	{
		garage_id: 156,
		part_id: 330,
		quantity: 125,
	},
	{
		garage_id: 156,
		part_id: 331,
		quantity: 317,
	},
	{
		garage_id: 156,
		part_id: 332,
		quantity: 164,
	},
	{
		garage_id: 156,
		part_id: 333,
		quantity: 127,
	},
	{
		garage_id: 156,
		part_id: 334,
		quantity: 371,
	},
	{
		garage_id: 156,
		part_id: 335,
		quantity: 332,
	},
	{
		garage_id: 156,
		part_id: 336,
		quantity: 270,
	},
	{
		garage_id: 156,
		part_id: 337,
		quantity: 346,
	},
	{
		garage_id: 156,
		part_id: 338,
		quantity: 346,
	},
	{
		garage_id: 156,
		part_id: 339,
		quantity: 441,
	},
	{
		garage_id: 156,
		part_id: 340,
		quantity: 140,
	},
	{
		garage_id: 156,
		part_id: 341,
		quantity: 399,
	},
	{
		garage_id: 156,
		part_id: 342,
		quantity: 405,
	},
	{
		garage_id: 156,
		part_id: 343,
		quantity: 259,
	},
	{
		garage_id: 156,
		part_id: 344,
		quantity: 463,
	},
	{
		garage_id: 156,
		part_id: 345,
		quantity: 193,
	},
	{
		garage_id: 156,
		part_id: 346,
		quantity: 153,
	},
	{
		garage_id: 156,
		part_id: 347,
		quantity: 356,
	},
	{
		garage_id: 156,
		part_id: 348,
		quantity: 87,
	},
	{
		garage_id: 156,
		part_id: 349,
		quantity: 11,
	},
	{
		garage_id: 156,
		part_id: 350,
		quantity: 86,
	},
	{
		garage_id: 156,
		part_id: 351,
		quantity: 352,
	},
	{
		garage_id: 156,
		part_id: 352,
		quantity: 319,
	},
	{
		garage_id: 156,
		part_id: 353,
		quantity: 14,
	},
	{
		garage_id: 156,
		part_id: 354,
		quantity: 276,
	},
	{
		garage_id: 156,
		part_id: 355,
		quantity: 134,
	},
	{
		garage_id: 156,
		part_id: 356,
		quantity: 91,
	},
	{
		garage_id: 156,
		part_id: 357,
		quantity: 311,
	},
	{
		garage_id: 156,
		part_id: 358,
		quantity: 32,
	},
	{
		garage_id: 156,
		part_id: 359,
		quantity: 490,
	},
	{
		garage_id: 156,
		part_id: 360,
		quantity: 399,
	},
	{
		garage_id: 156,
		part_id: 361,
		quantity: 478,
	},
	{
		garage_id: 156,
		part_id: 362,
		quantity: 107,
	},
	{
		garage_id: 156,
		part_id: 363,
		quantity: 131,
	},
	{
		garage_id: 156,
		part_id: 364,
		quantity: 349,
	},
	{
		garage_id: 156,
		part_id: 365,
		quantity: 267,
	},
	{
		garage_id: 156,
		part_id: 366,
		quantity: 76,
	},
	{
		garage_id: 156,
		part_id: 367,
		quantity: 381,
	},
	{
		garage_id: 156,
		part_id: 368,
		quantity: 185,
	},
	{
		garage_id: 156,
		part_id: 369,
		quantity: 10,
	},
	{
		garage_id: 156,
		part_id: 370,
		quantity: 150,
	},
	{
		garage_id: 156,
		part_id: 371,
		quantity: 410,
	},
	{
		garage_id: 156,
		part_id: 372,
		quantity: 16,
	},
	{
		garage_id: 156,
		part_id: 373,
		quantity: 369,
	},
	{
		garage_id: 156,
		part_id: 374,
		quantity: 223,
	},
	{
		garage_id: 156,
		part_id: 375,
		quantity: 380,
	},
	{
		garage_id: 156,
		part_id: 376,
		quantity: 184,
	},
	{
		garage_id: 156,
		part_id: 377,
		quantity: 406,
	},
	{
		garage_id: 156,
		part_id: 378,
		quantity: 487,
	},
	{
		garage_id: 156,
		part_id: 379,
		quantity: 372,
	},
	{
		garage_id: 156,
		part_id: 380,
		quantity: 263,
	},
	{
		garage_id: 156,
		part_id: 381,
		quantity: 128,
	},
	{
		garage_id: 156,
		part_id: 382,
		quantity: 156,
	},
	{
		garage_id: 156,
		part_id: 383,
		quantity: 123,
	},
	{
		garage_id: 156,
		part_id: 384,
		quantity: 486,
	},
	{
		garage_id: 156,
		part_id: 385,
		quantity: 422,
	},
	{
		garage_id: 156,
		part_id: 386,
		quantity: 206,
	},
	{
		garage_id: 156,
		part_id: 387,
		quantity: 11,
	},
	{
		garage_id: 156,
		part_id: 388,
		quantity: 21,
	},
	{
		garage_id: 156,
		part_id: 389,
		quantity: 408,
	},
	{
		garage_id: 156,
		part_id: 390,
		quantity: 281,
	},
	{
		garage_id: 156,
		part_id: 391,
		quantity: 73,
	},
	{
		garage_id: 156,
		part_id: 392,
		quantity: 455,
	},
	{
		garage_id: 156,
		part_id: 393,
		quantity: 120,
	},
	{
		garage_id: 156,
		part_id: 394,
		quantity: 423,
	},
	{
		garage_id: 156,
		part_id: 395,
		quantity: 340,
	},
	{
		garage_id: 156,
		part_id: 396,
		quantity: 400,
	},
	{
		garage_id: 156,
		part_id: 397,
		quantity: 247,
	},
	{
		garage_id: 156,
		part_id: 398,
		quantity: 127,
	},
	{
		garage_id: 156,
		part_id: 399,
		quantity: 100,
	},
	{
		garage_id: 157,
		part_id: 0,
		quantity: 355,
	},
	{
		garage_id: 157,
		part_id: 1,
		quantity: 499,
	},
	{
		garage_id: 157,
		part_id: 2,
		quantity: 407,
	},
	{
		garage_id: 157,
		part_id: 3,
		quantity: 113,
	},
	{
		garage_id: 157,
		part_id: 4,
		quantity: 60,
	},
	{
		garage_id: 157,
		part_id: 5,
		quantity: 11,
	},
	{
		garage_id: 157,
		part_id: 6,
		quantity: 31,
	},
	{
		garage_id: 157,
		part_id: 7,
		quantity: 387,
	},
	{
		garage_id: 157,
		part_id: 8,
		quantity: 89,
	},
	{
		garage_id: 157,
		part_id: 9,
		quantity: 385,
	},
	{
		garage_id: 157,
		part_id: 10,
		quantity: 185,
	},
	{
		garage_id: 157,
		part_id: 11,
		quantity: 497,
	},
	{
		garage_id: 157,
		part_id: 12,
		quantity: 414,
	},
	{
		garage_id: 157,
		part_id: 13,
		quantity: 255,
	},
	{
		garage_id: 157,
		part_id: 14,
		quantity: 377,
	},
	{
		garage_id: 157,
		part_id: 15,
		quantity: 257,
	},
	{
		garage_id: 157,
		part_id: 16,
		quantity: 452,
	},
	{
		garage_id: 157,
		part_id: 17,
		quantity: 44,
	},
	{
		garage_id: 157,
		part_id: 18,
		quantity: 248,
	},
	{
		garage_id: 157,
		part_id: 19,
		quantity: 381,
	},
	{
		garage_id: 157,
		part_id: 20,
		quantity: 372,
	},
	{
		garage_id: 157,
		part_id: 21,
		quantity: 157,
	},
	{
		garage_id: 157,
		part_id: 22,
		quantity: 74,
	},
	{
		garage_id: 157,
		part_id: 23,
		quantity: 451,
	},
	{
		garage_id: 157,
		part_id: 24,
		quantity: 464,
	},
	{
		garage_id: 157,
		part_id: 25,
		quantity: 482,
	},
	{
		garage_id: 157,
		part_id: 26,
		quantity: 148,
	},
	{
		garage_id: 157,
		part_id: 27,
		quantity: 299,
	},
	{
		garage_id: 157,
		part_id: 28,
		quantity: 453,
	},
	{
		garage_id: 157,
		part_id: 29,
		quantity: 218,
	},
	{
		garage_id: 157,
		part_id: 30,
		quantity: 108,
	},
	{
		garage_id: 157,
		part_id: 31,
		quantity: 90,
	},
	{
		garage_id: 157,
		part_id: 32,
		quantity: 99,
	},
	{
		garage_id: 157,
		part_id: 33,
		quantity: 372,
	},
	{
		garage_id: 157,
		part_id: 34,
		quantity: 102,
	},
	{
		garage_id: 157,
		part_id: 35,
		quantity: 197,
	},
	{
		garage_id: 157,
		part_id: 36,
		quantity: 97,
	},
	{
		garage_id: 157,
		part_id: 37,
		quantity: 452,
	},
	{
		garage_id: 157,
		part_id: 38,
		quantity: 152,
	},
	{
		garage_id: 157,
		part_id: 39,
		quantity: 301,
	},
	{
		garage_id: 157,
		part_id: 40,
		quantity: 174,
	},
	{
		garage_id: 157,
		part_id: 41,
		quantity: 71,
	},
	{
		garage_id: 157,
		part_id: 42,
		quantity: 82,
	},
	{
		garage_id: 157,
		part_id: 43,
		quantity: 104,
	},
	{
		garage_id: 157,
		part_id: 44,
		quantity: 431,
	},
	{
		garage_id: 157,
		part_id: 45,
		quantity: 421,
	},
	{
		garage_id: 157,
		part_id: 46,
		quantity: 137,
	},
	{
		garage_id: 157,
		part_id: 47,
		quantity: 315,
	},
	{
		garage_id: 157,
		part_id: 48,
		quantity: 309,
	},
	{
		garage_id: 157,
		part_id: 49,
		quantity: 114,
	},
	{
		garage_id: 157,
		part_id: 50,
		quantity: 207,
	},
	{
		garage_id: 157,
		part_id: 51,
		quantity: 242,
	},
	{
		garage_id: 157,
		part_id: 52,
		quantity: 322,
	},
	{
		garage_id: 157,
		part_id: 53,
		quantity: 92,
	},
	{
		garage_id: 157,
		part_id: 54,
		quantity: 281,
	},
	{
		garage_id: 157,
		part_id: 55,
		quantity: 432,
	},
	{
		garage_id: 157,
		part_id: 56,
		quantity: 324,
	},
	{
		garage_id: 157,
		part_id: 57,
		quantity: 333,
	},
	{
		garage_id: 157,
		part_id: 58,
		quantity: 371,
	},
	{
		garage_id: 157,
		part_id: 59,
		quantity: 183,
	},
	{
		garage_id: 157,
		part_id: 60,
		quantity: 270,
	},
	{
		garage_id: 157,
		part_id: 61,
		quantity: 417,
	},
	{
		garage_id: 157,
		part_id: 62,
		quantity: 195,
	},
	{
		garage_id: 157,
		part_id: 63,
		quantity: 300,
	},
	{
		garage_id: 157,
		part_id: 64,
		quantity: 366,
	},
	{
		garage_id: 157,
		part_id: 65,
		quantity: 352,
	},
	{
		garage_id: 157,
		part_id: 66,
		quantity: 336,
	},
	{
		garage_id: 157,
		part_id: 67,
		quantity: 186,
	},
	{
		garage_id: 157,
		part_id: 68,
		quantity: 240,
	},
	{
		garage_id: 157,
		part_id: 69,
		quantity: 321,
	},
	{
		garage_id: 157,
		part_id: 70,
		quantity: 480,
	},
	{
		garage_id: 157,
		part_id: 71,
		quantity: 84,
	},
	{
		garage_id: 157,
		part_id: 72,
		quantity: 177,
	},
	{
		garage_id: 157,
		part_id: 73,
		quantity: 88,
	},
	{
		garage_id: 157,
		part_id: 74,
		quantity: 327,
	},
	{
		garage_id: 157,
		part_id: 75,
		quantity: 402,
	},
	{
		garage_id: 157,
		part_id: 76,
		quantity: 317,
	},
	{
		garage_id: 157,
		part_id: 77,
		quantity: 494,
	},
	{
		garage_id: 157,
		part_id: 78,
		quantity: 324,
	},
	{
		garage_id: 157,
		part_id: 79,
		quantity: 151,
	},
	{
		garage_id: 157,
		part_id: 80,
		quantity: 382,
	},
	{
		garage_id: 157,
		part_id: 81,
		quantity: 89,
	},
	{
		garage_id: 157,
		part_id: 82,
		quantity: 369,
	},
	{
		garage_id: 157,
		part_id: 83,
		quantity: 195,
	},
	{
		garage_id: 157,
		part_id: 84,
		quantity: 198,
	},
	{
		garage_id: 157,
		part_id: 85,
		quantity: 319,
	},
	{
		garage_id: 157,
		part_id: 86,
		quantity: 77,
	},
	{
		garage_id: 157,
		part_id: 87,
		quantity: 138,
	},
	{
		garage_id: 157,
		part_id: 88,
		quantity: 266,
	},
	{
		garage_id: 157,
		part_id: 89,
		quantity: 47,
	},
	{
		garage_id: 157,
		part_id: 90,
		quantity: 267,
	},
	{
		garage_id: 157,
		part_id: 91,
		quantity: 473,
	},
	{
		garage_id: 157,
		part_id: 92,
		quantity: 77,
	},
	{
		garage_id: 157,
		part_id: 93,
		quantity: 63,
	},
	{
		garage_id: 157,
		part_id: 94,
		quantity: 40,
	},
	{
		garage_id: 157,
		part_id: 95,
		quantity: 0,
	},
	{
		garage_id: 157,
		part_id: 96,
		quantity: 335,
	},
	{
		garage_id: 157,
		part_id: 97,
		quantity: 436,
	},
	{
		garage_id: 157,
		part_id: 98,
		quantity: 110,
	},
	{
		garage_id: 157,
		part_id: 99,
		quantity: 52,
	},
	{
		garage_id: 157,
		part_id: 100,
		quantity: 321,
	},
	{
		garage_id: 157,
		part_id: 101,
		quantity: 392,
	},
	{
		garage_id: 157,
		part_id: 102,
		quantity: 471,
	},
	{
		garage_id: 157,
		part_id: 103,
		quantity: 180,
	},
	{
		garage_id: 157,
		part_id: 104,
		quantity: 311,
	},
	{
		garage_id: 157,
		part_id: 105,
		quantity: 322,
	},
	{
		garage_id: 157,
		part_id: 106,
		quantity: 225,
	},
	{
		garage_id: 157,
		part_id: 107,
		quantity: 0,
	},
	{
		garage_id: 157,
		part_id: 108,
		quantity: 259,
	},
	{
		garage_id: 157,
		part_id: 109,
		quantity: 274,
	},
	{
		garage_id: 157,
		part_id: 110,
		quantity: 479,
	},
	{
		garage_id: 157,
		part_id: 111,
		quantity: 93,
	},
	{
		garage_id: 157,
		part_id: 112,
		quantity: 20,
	},
	{
		garage_id: 157,
		part_id: 113,
		quantity: 114,
	},
	{
		garage_id: 157,
		part_id: 114,
		quantity: 241,
	},
	{
		garage_id: 157,
		part_id: 115,
		quantity: 269,
	},
	{
		garage_id: 157,
		part_id: 116,
		quantity: 494,
	},
	{
		garage_id: 157,
		part_id: 117,
		quantity: 85,
	},
	{
		garage_id: 157,
		part_id: 118,
		quantity: 13,
	},
	{
		garage_id: 157,
		part_id: 119,
		quantity: 275,
	},
	{
		garage_id: 157,
		part_id: 120,
		quantity: 44,
	},
	{
		garage_id: 157,
		part_id: 121,
		quantity: 353,
	},
	{
		garage_id: 157,
		part_id: 122,
		quantity: 373,
	},
	{
		garage_id: 157,
		part_id: 123,
		quantity: 214,
	},
	{
		garage_id: 157,
		part_id: 124,
		quantity: 124,
	},
	{
		garage_id: 157,
		part_id: 125,
		quantity: 43,
	},
	{
		garage_id: 157,
		part_id: 126,
		quantity: 73,
	},
	{
		garage_id: 157,
		part_id: 127,
		quantity: 190,
	},
	{
		garage_id: 157,
		part_id: 128,
		quantity: 296,
	},
	{
		garage_id: 157,
		part_id: 129,
		quantity: 447,
	},
	{
		garage_id: 157,
		part_id: 130,
		quantity: 497,
	},
	{
		garage_id: 157,
		part_id: 131,
		quantity: 10,
	},
	{
		garage_id: 157,
		part_id: 132,
		quantity: 418,
	},
	{
		garage_id: 157,
		part_id: 133,
		quantity: 267,
	},
	{
		garage_id: 157,
		part_id: 134,
		quantity: 448,
	},
	{
		garage_id: 157,
		part_id: 135,
		quantity: 499,
	},
	{
		garage_id: 157,
		part_id: 136,
		quantity: 78,
	},
	{
		garage_id: 157,
		part_id: 137,
		quantity: 270,
	},
	{
		garage_id: 157,
		part_id: 138,
		quantity: 54,
	},
	{
		garage_id: 157,
		part_id: 139,
		quantity: 458,
	},
	{
		garage_id: 157,
		part_id: 140,
		quantity: 17,
	},
	{
		garage_id: 157,
		part_id: 141,
		quantity: 260,
	},
	{
		garage_id: 157,
		part_id: 142,
		quantity: 191,
	},
	{
		garage_id: 157,
		part_id: 143,
		quantity: 420,
	},
	{
		garage_id: 157,
		part_id: 144,
		quantity: 211,
	},
	{
		garage_id: 157,
		part_id: 145,
		quantity: 126,
	},
	{
		garage_id: 157,
		part_id: 146,
		quantity: 382,
	},
	{
		garage_id: 157,
		part_id: 147,
		quantity: 215,
	},
	{
		garage_id: 157,
		part_id: 148,
		quantity: 211,
	},
	{
		garage_id: 157,
		part_id: 149,
		quantity: 120,
	},
	{
		garage_id: 157,
		part_id: 150,
		quantity: 2,
	},
	{
		garage_id: 157,
		part_id: 151,
		quantity: 5,
	},
	{
		garage_id: 157,
		part_id: 152,
		quantity: 356,
	},
	{
		garage_id: 157,
		part_id: 153,
		quantity: 425,
	},
	{
		garage_id: 157,
		part_id: 154,
		quantity: 380,
	},
	{
		garage_id: 157,
		part_id: 155,
		quantity: 369,
	},
	{
		garage_id: 157,
		part_id: 156,
		quantity: 239,
	},
	{
		garage_id: 157,
		part_id: 157,
		quantity: 271,
	},
	{
		garage_id: 157,
		part_id: 158,
		quantity: 475,
	},
	{
		garage_id: 157,
		part_id: 159,
		quantity: 89,
	},
	{
		garage_id: 157,
		part_id: 160,
		quantity: 239,
	},
	{
		garage_id: 157,
		part_id: 161,
		quantity: 116,
	},
	{
		garage_id: 157,
		part_id: 162,
		quantity: 148,
	},
	{
		garage_id: 157,
		part_id: 163,
		quantity: 313,
	},
	{
		garage_id: 157,
		part_id: 164,
		quantity: 79,
	},
	{
		garage_id: 157,
		part_id: 165,
		quantity: 346,
	},
	{
		garage_id: 157,
		part_id: 166,
		quantity: 40,
	},
	{
		garage_id: 157,
		part_id: 167,
		quantity: 60,
	},
	{
		garage_id: 157,
		part_id: 168,
		quantity: 46,
	},
	{
		garage_id: 157,
		part_id: 169,
		quantity: 82,
	},
	{
		garage_id: 157,
		part_id: 170,
		quantity: 441,
	},
	{
		garage_id: 157,
		part_id: 171,
		quantity: 4,
	},
	{
		garage_id: 157,
		part_id: 172,
		quantity: 76,
	},
	{
		garage_id: 157,
		part_id: 173,
		quantity: 466,
	},
	{
		garage_id: 157,
		part_id: 174,
		quantity: 249,
	},
	{
		garage_id: 157,
		part_id: 175,
		quantity: 51,
	},
	{
		garage_id: 157,
		part_id: 176,
		quantity: 169,
	},
	{
		garage_id: 157,
		part_id: 177,
		quantity: 27,
	},
	{
		garage_id: 157,
		part_id: 178,
		quantity: 230,
	},
	{
		garage_id: 157,
		part_id: 179,
		quantity: 151,
	},
	{
		garage_id: 157,
		part_id: 180,
		quantity: 106,
	},
	{
		garage_id: 157,
		part_id: 181,
		quantity: 66,
	},
	{
		garage_id: 157,
		part_id: 182,
		quantity: 266,
	},
	{
		garage_id: 157,
		part_id: 183,
		quantity: 53,
	},
	{
		garage_id: 157,
		part_id: 184,
		quantity: 100,
	},
	{
		garage_id: 157,
		part_id: 185,
		quantity: 4,
	},
	{
		garage_id: 157,
		part_id: 186,
		quantity: 428,
	},
	{
		garage_id: 157,
		part_id: 187,
		quantity: 285,
	},
	{
		garage_id: 157,
		part_id: 188,
		quantity: 262,
	},
	{
		garage_id: 157,
		part_id: 189,
		quantity: 13,
	},
	{
		garage_id: 157,
		part_id: 190,
		quantity: 364,
	},
	{
		garage_id: 157,
		part_id: 191,
		quantity: 230,
	},
	{
		garage_id: 157,
		part_id: 192,
		quantity: 93,
	},
	{
		garage_id: 157,
		part_id: 193,
		quantity: 228,
	},
	{
		garage_id: 157,
		part_id: 194,
		quantity: 66,
	},
	{
		garage_id: 157,
		part_id: 195,
		quantity: 376,
	},
	{
		garage_id: 157,
		part_id: 196,
		quantity: 131,
	},
	{
		garage_id: 157,
		part_id: 197,
		quantity: 232,
	},
	{
		garage_id: 157,
		part_id: 198,
		quantity: 101,
	},
	{
		garage_id: 157,
		part_id: 199,
		quantity: 293,
	},
	{
		garage_id: 157,
		part_id: 200,
		quantity: 166,
	},
	{
		garage_id: 157,
		part_id: 201,
		quantity: 82,
	},
	{
		garage_id: 157,
		part_id: 202,
		quantity: 125,
	},
	{
		garage_id: 157,
		part_id: 203,
		quantity: 54,
	},
	{
		garage_id: 157,
		part_id: 204,
		quantity: 220,
	},
	{
		garage_id: 157,
		part_id: 205,
		quantity: 370,
	},
	{
		garage_id: 157,
		part_id: 206,
		quantity: 224,
	},
	{
		garage_id: 157,
		part_id: 207,
		quantity: 485,
	},
	{
		garage_id: 157,
		part_id: 208,
		quantity: 220,
	},
	{
		garage_id: 157,
		part_id: 209,
		quantity: 52,
	},
	{
		garage_id: 157,
		part_id: 210,
		quantity: 87,
	},
	{
		garage_id: 157,
		part_id: 211,
		quantity: 397,
	},
	{
		garage_id: 157,
		part_id: 212,
		quantity: 455,
	},
	{
		garage_id: 157,
		part_id: 213,
		quantity: 499,
	},
	{
		garage_id: 157,
		part_id: 214,
		quantity: 482,
	},
	{
		garage_id: 157,
		part_id: 215,
		quantity: 389,
	},
	{
		garage_id: 157,
		part_id: 216,
		quantity: 214,
	},
	{
		garage_id: 157,
		part_id: 217,
		quantity: 300,
	},
	{
		garage_id: 157,
		part_id: 218,
		quantity: 439,
	},
	{
		garage_id: 157,
		part_id: 219,
		quantity: 41,
	},
	{
		garage_id: 157,
		part_id: 220,
		quantity: 422,
	},
	{
		garage_id: 157,
		part_id: 221,
		quantity: 231,
	},
	{
		garage_id: 157,
		part_id: 222,
		quantity: 336,
	},
	{
		garage_id: 157,
		part_id: 223,
		quantity: 277,
	},
	{
		garage_id: 157,
		part_id: 224,
		quantity: 206,
	},
	{
		garage_id: 157,
		part_id: 225,
		quantity: 342,
	},
	{
		garage_id: 157,
		part_id: 226,
		quantity: 328,
	},
	{
		garage_id: 157,
		part_id: 227,
		quantity: 370,
	},
	{
		garage_id: 157,
		part_id: 228,
		quantity: 490,
	},
	{
		garage_id: 157,
		part_id: 229,
		quantity: 211,
	},
	{
		garage_id: 157,
		part_id: 230,
		quantity: 97,
	},
	{
		garage_id: 157,
		part_id: 231,
		quantity: 125,
	},
	{
		garage_id: 157,
		part_id: 232,
		quantity: 238,
	},
	{
		garage_id: 157,
		part_id: 233,
		quantity: 471,
	},
	{
		garage_id: 157,
		part_id: 234,
		quantity: 169,
	},
	{
		garage_id: 157,
		part_id: 235,
		quantity: 328,
	},
	{
		garage_id: 157,
		part_id: 236,
		quantity: 90,
	},
	{
		garage_id: 157,
		part_id: 237,
		quantity: 33,
	},
	{
		garage_id: 157,
		part_id: 238,
		quantity: 443,
	},
	{
		garage_id: 157,
		part_id: 239,
		quantity: 69,
	},
	{
		garage_id: 157,
		part_id: 240,
		quantity: 136,
	},
	{
		garage_id: 157,
		part_id: 241,
		quantity: 101,
	},
	{
		garage_id: 157,
		part_id: 242,
		quantity: 71,
	},
	{
		garage_id: 157,
		part_id: 243,
		quantity: 363,
	},
	{
		garage_id: 157,
		part_id: 244,
		quantity: 129,
	},
	{
		garage_id: 157,
		part_id: 245,
		quantity: 304,
	},
	{
		garage_id: 157,
		part_id: 246,
		quantity: 362,
	},
	{
		garage_id: 157,
		part_id: 247,
		quantity: 63,
	},
	{
		garage_id: 157,
		part_id: 248,
		quantity: 316,
	},
	{
		garage_id: 157,
		part_id: 249,
		quantity: 121,
	},
	{
		garage_id: 157,
		part_id: 250,
		quantity: 371,
	},
	{
		garage_id: 157,
		part_id: 251,
		quantity: 142,
	},
	{
		garage_id: 157,
		part_id: 252,
		quantity: 467,
	},
	{
		garage_id: 157,
		part_id: 253,
		quantity: 313,
	},
	{
		garage_id: 157,
		part_id: 254,
		quantity: 409,
	},
	{
		garage_id: 157,
		part_id: 255,
		quantity: 250,
	},
	{
		garage_id: 157,
		part_id: 256,
		quantity: 468,
	},
	{
		garage_id: 157,
		part_id: 257,
		quantity: 265,
	},
	{
		garage_id: 157,
		part_id: 258,
		quantity: 153,
	},
	{
		garage_id: 157,
		part_id: 259,
		quantity: 435,
	},
	{
		garage_id: 157,
		part_id: 260,
		quantity: 290,
	},
	{
		garage_id: 157,
		part_id: 261,
		quantity: 239,
	},
	{
		garage_id: 157,
		part_id: 262,
		quantity: 283,
	},
	{
		garage_id: 157,
		part_id: 263,
		quantity: 22,
	},
	{
		garage_id: 157,
		part_id: 264,
		quantity: 449,
	},
	{
		garage_id: 157,
		part_id: 265,
		quantity: 278,
	},
	{
		garage_id: 157,
		part_id: 266,
		quantity: 321,
	},
	{
		garage_id: 157,
		part_id: 267,
		quantity: 433,
	},
	{
		garage_id: 157,
		part_id: 268,
		quantity: 420,
	},
	{
		garage_id: 157,
		part_id: 269,
		quantity: 207,
	},
	{
		garage_id: 157,
		part_id: 270,
		quantity: 86,
	},
	{
		garage_id: 157,
		part_id: 271,
		quantity: 104,
	},
	{
		garage_id: 157,
		part_id: 272,
		quantity: 284,
	},
	{
		garage_id: 157,
		part_id: 273,
		quantity: 459,
	},
	{
		garage_id: 157,
		part_id: 274,
		quantity: 342,
	},
	{
		garage_id: 157,
		part_id: 275,
		quantity: 250,
	},
	{
		garage_id: 157,
		part_id: 276,
		quantity: 499,
	},
	{
		garage_id: 157,
		part_id: 277,
		quantity: 286,
	},
	{
		garage_id: 157,
		part_id: 278,
		quantity: 160,
	},
	{
		garage_id: 157,
		part_id: 279,
		quantity: 30,
	},
	{
		garage_id: 157,
		part_id: 280,
		quantity: 392,
	},
	{
		garage_id: 157,
		part_id: 281,
		quantity: 154,
	},
	{
		garage_id: 157,
		part_id: 282,
		quantity: 230,
	},
	{
		garage_id: 157,
		part_id: 283,
		quantity: 258,
	},
	{
		garage_id: 157,
		part_id: 284,
		quantity: 24,
	},
	{
		garage_id: 157,
		part_id: 285,
		quantity: 449,
	},
	{
		garage_id: 157,
		part_id: 286,
		quantity: 252,
	},
	{
		garage_id: 157,
		part_id: 287,
		quantity: 266,
	},
	{
		garage_id: 157,
		part_id: 288,
		quantity: 127,
	},
	{
		garage_id: 157,
		part_id: 289,
		quantity: 21,
	},
	{
		garage_id: 157,
		part_id: 290,
		quantity: 290,
	},
	{
		garage_id: 157,
		part_id: 291,
		quantity: 211,
	},
	{
		garage_id: 157,
		part_id: 292,
		quantity: 89,
	},
	{
		garage_id: 157,
		part_id: 293,
		quantity: 362,
	},
	{
		garage_id: 157,
		part_id: 294,
		quantity: 197,
	},
	{
		garage_id: 157,
		part_id: 295,
		quantity: 123,
	},
	{
		garage_id: 157,
		part_id: 296,
		quantity: 290,
	},
	{
		garage_id: 157,
		part_id: 297,
		quantity: 241,
	},
	{
		garage_id: 157,
		part_id: 298,
		quantity: 275,
	},
	{
		garage_id: 157,
		part_id: 299,
		quantity: 158,
	},
	{
		garage_id: 157,
		part_id: 300,
		quantity: 39,
	},
	{
		garage_id: 157,
		part_id: 301,
		quantity: 220,
	},
	{
		garage_id: 157,
		part_id: 302,
		quantity: 325,
	},
	{
		garage_id: 157,
		part_id: 303,
		quantity: 329,
	},
	{
		garage_id: 157,
		part_id: 304,
		quantity: 207,
	},
	{
		garage_id: 157,
		part_id: 305,
		quantity: 1,
	},
	{
		garage_id: 157,
		part_id: 306,
		quantity: 439,
	},
	{
		garage_id: 157,
		part_id: 307,
		quantity: 397,
	},
	{
		garage_id: 157,
		part_id: 308,
		quantity: 323,
	},
	{
		garage_id: 157,
		part_id: 309,
		quantity: 294,
	},
	{
		garage_id: 157,
		part_id: 310,
		quantity: 346,
	},
	{
		garage_id: 157,
		part_id: 311,
		quantity: 9,
	},
	{
		garage_id: 157,
		part_id: 312,
		quantity: 469,
	},
	{
		garage_id: 157,
		part_id: 313,
		quantity: 284,
	},
	{
		garage_id: 157,
		part_id: 314,
		quantity: 445,
	},
	{
		garage_id: 157,
		part_id: 315,
		quantity: 261,
	},
	{
		garage_id: 157,
		part_id: 316,
		quantity: 398,
	},
	{
		garage_id: 157,
		part_id: 317,
		quantity: 446,
	},
	{
		garage_id: 157,
		part_id: 318,
		quantity: 121,
	},
	{
		garage_id: 157,
		part_id: 319,
		quantity: 311,
	},
	{
		garage_id: 157,
		part_id: 320,
		quantity: 437,
	},
	{
		garage_id: 157,
		part_id: 321,
		quantity: 134,
	},
	{
		garage_id: 157,
		part_id: 322,
		quantity: 381,
	},
	{
		garage_id: 157,
		part_id: 323,
		quantity: 39,
	},
	{
		garage_id: 157,
		part_id: 324,
		quantity: 37,
	},
	{
		garage_id: 157,
		part_id: 325,
		quantity: 302,
	},
	{
		garage_id: 157,
		part_id: 326,
		quantity: 272,
	},
	{
		garage_id: 157,
		part_id: 327,
		quantity: 47,
	},
	{
		garage_id: 157,
		part_id: 328,
		quantity: 391,
	},
	{
		garage_id: 157,
		part_id: 329,
		quantity: 200,
	},
	{
		garage_id: 157,
		part_id: 330,
		quantity: 409,
	},
	{
		garage_id: 157,
		part_id: 331,
		quantity: 363,
	},
	{
		garage_id: 157,
		part_id: 332,
		quantity: 78,
	},
	{
		garage_id: 157,
		part_id: 333,
		quantity: 167,
	},
	{
		garage_id: 157,
		part_id: 334,
		quantity: 370,
	},
	{
		garage_id: 157,
		part_id: 335,
		quantity: 380,
	},
	{
		garage_id: 157,
		part_id: 336,
		quantity: 22,
	},
	{
		garage_id: 157,
		part_id: 337,
		quantity: 117,
	},
	{
		garage_id: 157,
		part_id: 338,
		quantity: 138,
	},
	{
		garage_id: 157,
		part_id: 339,
		quantity: 257,
	},
	{
		garage_id: 157,
		part_id: 340,
		quantity: 122,
	},
	{
		garage_id: 157,
		part_id: 341,
		quantity: 295,
	},
	{
		garage_id: 157,
		part_id: 342,
		quantity: 58,
	},
	{
		garage_id: 157,
		part_id: 343,
		quantity: 133,
	},
	{
		garage_id: 157,
		part_id: 344,
		quantity: 318,
	},
	{
		garage_id: 157,
		part_id: 345,
		quantity: 401,
	},
	{
		garage_id: 157,
		part_id: 346,
		quantity: 432,
	},
	{
		garage_id: 157,
		part_id: 347,
		quantity: 37,
	},
	{
		garage_id: 157,
		part_id: 348,
		quantity: 154,
	},
	{
		garage_id: 157,
		part_id: 349,
		quantity: 104,
	},
	{
		garage_id: 157,
		part_id: 350,
		quantity: 421,
	},
	{
		garage_id: 157,
		part_id: 351,
		quantity: 226,
	},
	{
		garage_id: 157,
		part_id: 352,
		quantity: 406,
	},
	{
		garage_id: 157,
		part_id: 353,
		quantity: 10,
	},
	{
		garage_id: 157,
		part_id: 354,
		quantity: 143,
	},
	{
		garage_id: 157,
		part_id: 355,
		quantity: 11,
	},
	{
		garage_id: 157,
		part_id: 356,
		quantity: 342,
	},
	{
		garage_id: 157,
		part_id: 357,
		quantity: 431,
	},
	{
		garage_id: 157,
		part_id: 358,
		quantity: 396,
	},
	{
		garage_id: 157,
		part_id: 359,
		quantity: 286,
	},
	{
		garage_id: 157,
		part_id: 360,
		quantity: 155,
	},
	{
		garage_id: 157,
		part_id: 361,
		quantity: 67,
	},
	{
		garage_id: 157,
		part_id: 362,
		quantity: 192,
	},
	{
		garage_id: 157,
		part_id: 363,
		quantity: 251,
	},
	{
		garage_id: 157,
		part_id: 364,
		quantity: 353,
	},
	{
		garage_id: 157,
		part_id: 365,
		quantity: 272,
	},
	{
		garage_id: 157,
		part_id: 366,
		quantity: 22,
	},
	{
		garage_id: 157,
		part_id: 367,
		quantity: 147,
	},
	{
		garage_id: 157,
		part_id: 368,
		quantity: 344,
	},
	{
		garage_id: 157,
		part_id: 369,
		quantity: 386,
	},
	{
		garage_id: 157,
		part_id: 370,
		quantity: 29,
	},
	{
		garage_id: 157,
		part_id: 371,
		quantity: 139,
	},
	{
		garage_id: 157,
		part_id: 372,
		quantity: 333,
	},
	{
		garage_id: 157,
		part_id: 373,
		quantity: 50,
	},
	{
		garage_id: 157,
		part_id: 374,
		quantity: 22,
	},
	{
		garage_id: 157,
		part_id: 375,
		quantity: 74,
	},
	{
		garage_id: 157,
		part_id: 376,
		quantity: 371,
	},
	{
		garage_id: 157,
		part_id: 377,
		quantity: 65,
	},
	{
		garage_id: 157,
		part_id: 378,
		quantity: 497,
	},
	{
		garage_id: 157,
		part_id: 379,
		quantity: 318,
	},
	{
		garage_id: 157,
		part_id: 380,
		quantity: 332,
	},
	{
		garage_id: 157,
		part_id: 381,
		quantity: 448,
	},
	{
		garage_id: 157,
		part_id: 382,
		quantity: 205,
	},
	{
		garage_id: 157,
		part_id: 383,
		quantity: 36,
	},
	{
		garage_id: 157,
		part_id: 384,
		quantity: 324,
	},
	{
		garage_id: 157,
		part_id: 385,
		quantity: 316,
	},
	{
		garage_id: 157,
		part_id: 386,
		quantity: 427,
	},
	{
		garage_id: 157,
		part_id: 387,
		quantity: 103,
	},
	{
		garage_id: 157,
		part_id: 388,
		quantity: 296,
	},
	{
		garage_id: 157,
		part_id: 389,
		quantity: 392,
	},
	{
		garage_id: 157,
		part_id: 390,
		quantity: 239,
	},
	{
		garage_id: 157,
		part_id: 391,
		quantity: 108,
	},
	{
		garage_id: 157,
		part_id: 392,
		quantity: 34,
	},
	{
		garage_id: 157,
		part_id: 393,
		quantity: 359,
	},
	{
		garage_id: 157,
		part_id: 394,
		quantity: 438,
	},
	{
		garage_id: 157,
		part_id: 395,
		quantity: 148,
	},
	{
		garage_id: 157,
		part_id: 396,
		quantity: 396,
	},
	{
		garage_id: 157,
		part_id: 397,
		quantity: 418,
	},
	{
		garage_id: 157,
		part_id: 398,
		quantity: 135,
	},
	{
		garage_id: 157,
		part_id: 399,
		quantity: 243,
	},
	{
		garage_id: 158,
		part_id: 0,
		quantity: 406,
	},
	{
		garage_id: 158,
		part_id: 1,
		quantity: 111,
	},
	{
		garage_id: 158,
		part_id: 2,
		quantity: 219,
	},
	{
		garage_id: 158,
		part_id: 3,
		quantity: 1,
	},
	{
		garage_id: 158,
		part_id: 4,
		quantity: 300,
	},
	{
		garage_id: 158,
		part_id: 5,
		quantity: 400,
	},
	{
		garage_id: 158,
		part_id: 6,
		quantity: 202,
	},
	{
		garage_id: 158,
		part_id: 7,
		quantity: 162,
	},
	{
		garage_id: 158,
		part_id: 8,
		quantity: 95,
	},
	{
		garage_id: 158,
		part_id: 9,
		quantity: 241,
	},
	{
		garage_id: 158,
		part_id: 10,
		quantity: 275,
	},
	{
		garage_id: 158,
		part_id: 11,
		quantity: 91,
	},
	{
		garage_id: 158,
		part_id: 12,
		quantity: 388,
	},
	{
		garage_id: 158,
		part_id: 13,
		quantity: 315,
	},
	{
		garage_id: 158,
		part_id: 14,
		quantity: 258,
	},
	{
		garage_id: 158,
		part_id: 15,
		quantity: 164,
	},
	{
		garage_id: 158,
		part_id: 16,
		quantity: 399,
	},
	{
		garage_id: 158,
		part_id: 17,
		quantity: 46,
	},
	{
		garage_id: 158,
		part_id: 18,
		quantity: 331,
	},
	{
		garage_id: 158,
		part_id: 19,
		quantity: 25,
	},
	{
		garage_id: 158,
		part_id: 20,
		quantity: 285,
	},
	{
		garage_id: 158,
		part_id: 21,
		quantity: 414,
	},
	{
		garage_id: 158,
		part_id: 22,
		quantity: 16,
	},
	{
		garage_id: 158,
		part_id: 23,
		quantity: 253,
	},
	{
		garage_id: 158,
		part_id: 24,
		quantity: 206,
	},
	{
		garage_id: 158,
		part_id: 25,
		quantity: 356,
	},
	{
		garage_id: 158,
		part_id: 26,
		quantity: 344,
	},
	{
		garage_id: 158,
		part_id: 27,
		quantity: 190,
	},
	{
		garage_id: 158,
		part_id: 28,
		quantity: 120,
	},
	{
		garage_id: 158,
		part_id: 29,
		quantity: 431,
	},
	{
		garage_id: 158,
		part_id: 30,
		quantity: 204,
	},
	{
		garage_id: 158,
		part_id: 31,
		quantity: 155,
	},
	{
		garage_id: 158,
		part_id: 32,
		quantity: 131,
	},
	{
		garage_id: 158,
		part_id: 33,
		quantity: 266,
	},
	{
		garage_id: 158,
		part_id: 34,
		quantity: 39,
	},
	{
		garage_id: 158,
		part_id: 35,
		quantity: 204,
	},
	{
		garage_id: 158,
		part_id: 36,
		quantity: 109,
	},
	{
		garage_id: 158,
		part_id: 37,
		quantity: 303,
	},
	{
		garage_id: 158,
		part_id: 38,
		quantity: 110,
	},
	{
		garage_id: 158,
		part_id: 39,
		quantity: 179,
	},
	{
		garage_id: 158,
		part_id: 40,
		quantity: 299,
	},
	{
		garage_id: 158,
		part_id: 41,
		quantity: 346,
	},
	{
		garage_id: 158,
		part_id: 42,
		quantity: 490,
	},
	{
		garage_id: 158,
		part_id: 43,
		quantity: 107,
	},
	{
		garage_id: 158,
		part_id: 44,
		quantity: 46,
	},
	{
		garage_id: 158,
		part_id: 45,
		quantity: 488,
	},
	{
		garage_id: 158,
		part_id: 46,
		quantity: 347,
	},
	{
		garage_id: 158,
		part_id: 47,
		quantity: 322,
	},
	{
		garage_id: 158,
		part_id: 48,
		quantity: 367,
	},
	{
		garage_id: 158,
		part_id: 49,
		quantity: 268,
	},
	{
		garage_id: 158,
		part_id: 50,
		quantity: 175,
	},
	{
		garage_id: 158,
		part_id: 51,
		quantity: 177,
	},
	{
		garage_id: 158,
		part_id: 52,
		quantity: 52,
	},
	{
		garage_id: 158,
		part_id: 53,
		quantity: 81,
	},
	{
		garage_id: 158,
		part_id: 54,
		quantity: 357,
	},
	{
		garage_id: 158,
		part_id: 55,
		quantity: 44,
	},
	{
		garage_id: 158,
		part_id: 56,
		quantity: 382,
	},
	{
		garage_id: 158,
		part_id: 57,
		quantity: 46,
	},
	{
		garage_id: 158,
		part_id: 58,
		quantity: 144,
	},
	{
		garage_id: 158,
		part_id: 59,
		quantity: 229,
	},
	{
		garage_id: 158,
		part_id: 60,
		quantity: 290,
	},
	{
		garage_id: 158,
		part_id: 61,
		quantity: 495,
	},
	{
		garage_id: 158,
		part_id: 62,
		quantity: 313,
	},
	{
		garage_id: 158,
		part_id: 63,
		quantity: 384,
	},
	{
		garage_id: 158,
		part_id: 64,
		quantity: 241,
	},
	{
		garage_id: 158,
		part_id: 65,
		quantity: 95,
	},
	{
		garage_id: 158,
		part_id: 66,
		quantity: 406,
	},
	{
		garage_id: 158,
		part_id: 67,
		quantity: 320,
	},
	{
		garage_id: 158,
		part_id: 68,
		quantity: 99,
	},
	{
		garage_id: 158,
		part_id: 69,
		quantity: 96,
	},
	{
		garage_id: 158,
		part_id: 70,
		quantity: 225,
	},
	{
		garage_id: 158,
		part_id: 71,
		quantity: 180,
	},
	{
		garage_id: 158,
		part_id: 72,
		quantity: 230,
	},
	{
		garage_id: 158,
		part_id: 73,
		quantity: 165,
	},
	{
		garage_id: 158,
		part_id: 74,
		quantity: 373,
	},
	{
		garage_id: 158,
		part_id: 75,
		quantity: 422,
	},
	{
		garage_id: 158,
		part_id: 76,
		quantity: 131,
	},
	{
		garage_id: 158,
		part_id: 77,
		quantity: 464,
	},
	{
		garage_id: 158,
		part_id: 78,
		quantity: 73,
	},
	{
		garage_id: 158,
		part_id: 79,
		quantity: 438,
	},
	{
		garage_id: 158,
		part_id: 80,
		quantity: 81,
	},
	{
		garage_id: 158,
		part_id: 81,
		quantity: 276,
	},
	{
		garage_id: 158,
		part_id: 82,
		quantity: 410,
	},
	{
		garage_id: 158,
		part_id: 83,
		quantity: 34,
	},
	{
		garage_id: 158,
		part_id: 84,
		quantity: 177,
	},
	{
		garage_id: 158,
		part_id: 85,
		quantity: 85,
	},
	{
		garage_id: 158,
		part_id: 86,
		quantity: 260,
	},
	{
		garage_id: 158,
		part_id: 87,
		quantity: 437,
	},
	{
		garage_id: 158,
		part_id: 88,
		quantity: 332,
	},
	{
		garage_id: 158,
		part_id: 89,
		quantity: 321,
	},
	{
		garage_id: 158,
		part_id: 90,
		quantity: 355,
	},
	{
		garage_id: 158,
		part_id: 91,
		quantity: 461,
	},
	{
		garage_id: 158,
		part_id: 92,
		quantity: 264,
	},
	{
		garage_id: 158,
		part_id: 93,
		quantity: 324,
	},
	{
		garage_id: 158,
		part_id: 94,
		quantity: 276,
	},
	{
		garage_id: 158,
		part_id: 95,
		quantity: 194,
	},
	{
		garage_id: 158,
		part_id: 96,
		quantity: 267,
	},
	{
		garage_id: 158,
		part_id: 97,
		quantity: 439,
	},
	{
		garage_id: 158,
		part_id: 98,
		quantity: 107,
	},
	{
		garage_id: 158,
		part_id: 99,
		quantity: 438,
	},
	{
		garage_id: 158,
		part_id: 100,
		quantity: 53,
	},
	{
		garage_id: 158,
		part_id: 101,
		quantity: 302,
	},
	{
		garage_id: 158,
		part_id: 102,
		quantity: 276,
	},
	{
		garage_id: 158,
		part_id: 103,
		quantity: 97,
	},
	{
		garage_id: 158,
		part_id: 104,
		quantity: 126,
	},
	{
		garage_id: 158,
		part_id: 105,
		quantity: 364,
	},
	{
		garage_id: 158,
		part_id: 106,
		quantity: 472,
	},
	{
		garage_id: 158,
		part_id: 107,
		quantity: 111,
	},
	{
		garage_id: 158,
		part_id: 108,
		quantity: 170,
	},
	{
		garage_id: 158,
		part_id: 109,
		quantity: 294,
	},
	{
		garage_id: 158,
		part_id: 110,
		quantity: 66,
	},
	{
		garage_id: 158,
		part_id: 111,
		quantity: 295,
	},
	{
		garage_id: 158,
		part_id: 112,
		quantity: 311,
	},
	{
		garage_id: 158,
		part_id: 113,
		quantity: 245,
	},
	{
		garage_id: 158,
		part_id: 114,
		quantity: 313,
	},
	{
		garage_id: 158,
		part_id: 115,
		quantity: 112,
	},
	{
		garage_id: 158,
		part_id: 116,
		quantity: 113,
	},
	{
		garage_id: 158,
		part_id: 117,
		quantity: 247,
	},
	{
		garage_id: 158,
		part_id: 118,
		quantity: 388,
	},
	{
		garage_id: 158,
		part_id: 119,
		quantity: 147,
	},
	{
		garage_id: 158,
		part_id: 120,
		quantity: 119,
	},
	{
		garage_id: 158,
		part_id: 121,
		quantity: 344,
	},
	{
		garage_id: 158,
		part_id: 122,
		quantity: 100,
	},
	{
		garage_id: 158,
		part_id: 123,
		quantity: 335,
	},
	{
		garage_id: 158,
		part_id: 124,
		quantity: 236,
	},
	{
		garage_id: 158,
		part_id: 125,
		quantity: 223,
	},
	{
		garage_id: 158,
		part_id: 126,
		quantity: 299,
	},
	{
		garage_id: 158,
		part_id: 127,
		quantity: 387,
	},
	{
		garage_id: 158,
		part_id: 128,
		quantity: 343,
	},
	{
		garage_id: 158,
		part_id: 129,
		quantity: 0,
	},
	{
		garage_id: 158,
		part_id: 130,
		quantity: 113,
	},
	{
		garage_id: 158,
		part_id: 131,
		quantity: 376,
	},
	{
		garage_id: 158,
		part_id: 132,
		quantity: 446,
	},
	{
		garage_id: 158,
		part_id: 133,
		quantity: 273,
	},
	{
		garage_id: 158,
		part_id: 134,
		quantity: 349,
	},
	{
		garage_id: 158,
		part_id: 135,
		quantity: 217,
	},
	{
		garage_id: 158,
		part_id: 136,
		quantity: 194,
	},
	{
		garage_id: 158,
		part_id: 137,
		quantity: 441,
	},
	{
		garage_id: 158,
		part_id: 138,
		quantity: 20,
	},
	{
		garage_id: 158,
		part_id: 139,
		quantity: 392,
	},
	{
		garage_id: 158,
		part_id: 140,
		quantity: 486,
	},
	{
		garage_id: 158,
		part_id: 141,
		quantity: 147,
	},
	{
		garage_id: 158,
		part_id: 142,
		quantity: 302,
	},
	{
		garage_id: 158,
		part_id: 143,
		quantity: 172,
	},
	{
		garage_id: 158,
		part_id: 144,
		quantity: 141,
	},
	{
		garage_id: 158,
		part_id: 145,
		quantity: 439,
	},
	{
		garage_id: 158,
		part_id: 146,
		quantity: 77,
	},
	{
		garage_id: 158,
		part_id: 147,
		quantity: 262,
	},
	{
		garage_id: 158,
		part_id: 148,
		quantity: 355,
	},
	{
		garage_id: 158,
		part_id: 149,
		quantity: 425,
	},
	{
		garage_id: 158,
		part_id: 150,
		quantity: 499,
	},
	{
		garage_id: 158,
		part_id: 151,
		quantity: 203,
	},
	{
		garage_id: 158,
		part_id: 152,
		quantity: 143,
	},
	{
		garage_id: 158,
		part_id: 153,
		quantity: 27,
	},
	{
		garage_id: 158,
		part_id: 154,
		quantity: 130,
	},
	{
		garage_id: 158,
		part_id: 155,
		quantity: 470,
	},
	{
		garage_id: 158,
		part_id: 156,
		quantity: 111,
	},
	{
		garage_id: 158,
		part_id: 157,
		quantity: 35,
	},
	{
		garage_id: 158,
		part_id: 158,
		quantity: 239,
	},
	{
		garage_id: 158,
		part_id: 159,
		quantity: 261,
	},
	{
		garage_id: 158,
		part_id: 160,
		quantity: 24,
	},
	{
		garage_id: 158,
		part_id: 161,
		quantity: 341,
	},
	{
		garage_id: 158,
		part_id: 162,
		quantity: 70,
	},
	{
		garage_id: 158,
		part_id: 163,
		quantity: 17,
	},
	{
		garage_id: 158,
		part_id: 164,
		quantity: 288,
	},
	{
		garage_id: 158,
		part_id: 165,
		quantity: 122,
	},
	{
		garage_id: 158,
		part_id: 166,
		quantity: 281,
	},
	{
		garage_id: 158,
		part_id: 167,
		quantity: 140,
	},
	{
		garage_id: 158,
		part_id: 168,
		quantity: 284,
	},
	{
		garage_id: 158,
		part_id: 169,
		quantity: 457,
	},
	{
		garage_id: 158,
		part_id: 170,
		quantity: 314,
	},
	{
		garage_id: 158,
		part_id: 171,
		quantity: 259,
	},
	{
		garage_id: 158,
		part_id: 172,
		quantity: 244,
	},
	{
		garage_id: 158,
		part_id: 173,
		quantity: 368,
	},
	{
		garage_id: 158,
		part_id: 174,
		quantity: 390,
	},
	{
		garage_id: 158,
		part_id: 175,
		quantity: 291,
	},
	{
		garage_id: 158,
		part_id: 176,
		quantity: 146,
	},
	{
		garage_id: 158,
		part_id: 177,
		quantity: 468,
	},
	{
		garage_id: 158,
		part_id: 178,
		quantity: 388,
	},
	{
		garage_id: 158,
		part_id: 179,
		quantity: 197,
	},
	{
		garage_id: 158,
		part_id: 180,
		quantity: 498,
	},
	{
		garage_id: 158,
		part_id: 181,
		quantity: 31,
	},
	{
		garage_id: 158,
		part_id: 182,
		quantity: 404,
	},
	{
		garage_id: 158,
		part_id: 183,
		quantity: 317,
	},
	{
		garage_id: 158,
		part_id: 184,
		quantity: 176,
	},
	{
		garage_id: 158,
		part_id: 185,
		quantity: 207,
	},
	{
		garage_id: 158,
		part_id: 186,
		quantity: 365,
	},
	{
		garage_id: 158,
		part_id: 187,
		quantity: 244,
	},
	{
		garage_id: 158,
		part_id: 188,
		quantity: 111,
	},
	{
		garage_id: 158,
		part_id: 189,
		quantity: 228,
	},
	{
		garage_id: 158,
		part_id: 190,
		quantity: 88,
	},
	{
		garage_id: 158,
		part_id: 191,
		quantity: 356,
	},
	{
		garage_id: 158,
		part_id: 192,
		quantity: 225,
	},
	{
		garage_id: 158,
		part_id: 193,
		quantity: 473,
	},
	{
		garage_id: 158,
		part_id: 194,
		quantity: 322,
	},
	{
		garage_id: 158,
		part_id: 195,
		quantity: 168,
	},
	{
		garage_id: 158,
		part_id: 196,
		quantity: 103,
	},
	{
		garage_id: 158,
		part_id: 197,
		quantity: 187,
	},
	{
		garage_id: 158,
		part_id: 198,
		quantity: 382,
	},
	{
		garage_id: 158,
		part_id: 199,
		quantity: 60,
	},
	{
		garage_id: 158,
		part_id: 200,
		quantity: 348,
	},
	{
		garage_id: 158,
		part_id: 201,
		quantity: 68,
	},
	{
		garage_id: 158,
		part_id: 202,
		quantity: 191,
	},
	{
		garage_id: 158,
		part_id: 203,
		quantity: 139,
	},
	{
		garage_id: 158,
		part_id: 204,
		quantity: 367,
	},
	{
		garage_id: 158,
		part_id: 205,
		quantity: 346,
	},
	{
		garage_id: 158,
		part_id: 206,
		quantity: 382,
	},
	{
		garage_id: 158,
		part_id: 207,
		quantity: 443,
	},
	{
		garage_id: 158,
		part_id: 208,
		quantity: 463,
	},
	{
		garage_id: 158,
		part_id: 209,
		quantity: 123,
	},
	{
		garage_id: 158,
		part_id: 210,
		quantity: 2,
	},
	{
		garage_id: 158,
		part_id: 211,
		quantity: 417,
	},
	{
		garage_id: 158,
		part_id: 212,
		quantity: 328,
	},
	{
		garage_id: 158,
		part_id: 213,
		quantity: 420,
	},
	{
		garage_id: 158,
		part_id: 214,
		quantity: 287,
	},
	{
		garage_id: 158,
		part_id: 215,
		quantity: 468,
	},
	{
		garage_id: 158,
		part_id: 216,
		quantity: 444,
	},
	{
		garage_id: 158,
		part_id: 217,
		quantity: 375,
	},
	{
		garage_id: 158,
		part_id: 218,
		quantity: 111,
	},
	{
		garage_id: 158,
		part_id: 219,
		quantity: 37,
	},
	{
		garage_id: 158,
		part_id: 220,
		quantity: 412,
	},
	{
		garage_id: 158,
		part_id: 221,
		quantity: 204,
	},
	{
		garage_id: 158,
		part_id: 222,
		quantity: 140,
	},
	{
		garage_id: 158,
		part_id: 223,
		quantity: 2,
	},
	{
		garage_id: 158,
		part_id: 224,
		quantity: 315,
	},
	{
		garage_id: 158,
		part_id: 225,
		quantity: 425,
	},
	{
		garage_id: 158,
		part_id: 226,
		quantity: 158,
	},
	{
		garage_id: 158,
		part_id: 227,
		quantity: 11,
	},
	{
		garage_id: 158,
		part_id: 228,
		quantity: 257,
	},
	{
		garage_id: 158,
		part_id: 229,
		quantity: 404,
	},
	{
		garage_id: 158,
		part_id: 230,
		quantity: 236,
	},
	{
		garage_id: 158,
		part_id: 231,
		quantity: 348,
	},
	{
		garage_id: 158,
		part_id: 232,
		quantity: 349,
	},
	{
		garage_id: 158,
		part_id: 233,
		quantity: 157,
	},
	{
		garage_id: 158,
		part_id: 234,
		quantity: 426,
	},
	{
		garage_id: 158,
		part_id: 235,
		quantity: 262,
	},
	{
		garage_id: 158,
		part_id: 236,
		quantity: 493,
	},
	{
		garage_id: 158,
		part_id: 237,
		quantity: 338,
	},
	{
		garage_id: 158,
		part_id: 238,
		quantity: 348,
	},
	{
		garage_id: 158,
		part_id: 239,
		quantity: 49,
	},
	{
		garage_id: 158,
		part_id: 240,
		quantity: 142,
	},
	{
		garage_id: 158,
		part_id: 241,
		quantity: 307,
	},
	{
		garage_id: 158,
		part_id: 242,
		quantity: 37,
	},
	{
		garage_id: 158,
		part_id: 243,
		quantity: 402,
	},
	{
		garage_id: 158,
		part_id: 244,
		quantity: 442,
	},
	{
		garage_id: 158,
		part_id: 245,
		quantity: 123,
	},
	{
		garage_id: 158,
		part_id: 246,
		quantity: 163,
	},
	{
		garage_id: 158,
		part_id: 247,
		quantity: 301,
	},
	{
		garage_id: 158,
		part_id: 248,
		quantity: 107,
	},
	{
		garage_id: 158,
		part_id: 249,
		quantity: 323,
	},
	{
		garage_id: 158,
		part_id: 250,
		quantity: 482,
	},
	{
		garage_id: 158,
		part_id: 251,
		quantity: 458,
	},
	{
		garage_id: 158,
		part_id: 252,
		quantity: 150,
	},
	{
		garage_id: 158,
		part_id: 253,
		quantity: 177,
	},
	{
		garage_id: 158,
		part_id: 254,
		quantity: 197,
	},
	{
		garage_id: 158,
		part_id: 255,
		quantity: 452,
	},
	{
		garage_id: 158,
		part_id: 256,
		quantity: 155,
	},
	{
		garage_id: 158,
		part_id: 257,
		quantity: 63,
	},
	{
		garage_id: 158,
		part_id: 258,
		quantity: 326,
	},
	{
		garage_id: 158,
		part_id: 259,
		quantity: 188,
	},
	{
		garage_id: 158,
		part_id: 260,
		quantity: 410,
	},
	{
		garage_id: 158,
		part_id: 261,
		quantity: 39,
	},
	{
		garage_id: 158,
		part_id: 262,
		quantity: 386,
	},
	{
		garage_id: 158,
		part_id: 263,
		quantity: 35,
	},
	{
		garage_id: 158,
		part_id: 264,
		quantity: 468,
	},
	{
		garage_id: 158,
		part_id: 265,
		quantity: 432,
	},
	{
		garage_id: 158,
		part_id: 266,
		quantity: 147,
	},
	{
		garage_id: 158,
		part_id: 267,
		quantity: 112,
	},
	{
		garage_id: 158,
		part_id: 268,
		quantity: 475,
	},
	{
		garage_id: 158,
		part_id: 269,
		quantity: 244,
	},
	{
		garage_id: 158,
		part_id: 270,
		quantity: 314,
	},
	{
		garage_id: 158,
		part_id: 271,
		quantity: 488,
	},
	{
		garage_id: 158,
		part_id: 272,
		quantity: 480,
	},
	{
		garage_id: 158,
		part_id: 273,
		quantity: 132,
	},
	{
		garage_id: 158,
		part_id: 274,
		quantity: 182,
	},
	{
		garage_id: 158,
		part_id: 275,
		quantity: 224,
	},
	{
		garage_id: 158,
		part_id: 276,
		quantity: 108,
	},
	{
		garage_id: 158,
		part_id: 277,
		quantity: 331,
	},
	{
		garage_id: 158,
		part_id: 278,
		quantity: 399,
	},
	{
		garage_id: 158,
		part_id: 279,
		quantity: 110,
	},
	{
		garage_id: 158,
		part_id: 280,
		quantity: 88,
	},
	{
		garage_id: 158,
		part_id: 281,
		quantity: 447,
	},
	{
		garage_id: 158,
		part_id: 282,
		quantity: 28,
	},
	{
		garage_id: 158,
		part_id: 283,
		quantity: 336,
	},
	{
		garage_id: 158,
		part_id: 284,
		quantity: 395,
	},
	{
		garage_id: 158,
		part_id: 285,
		quantity: 62,
	},
	{
		garage_id: 158,
		part_id: 286,
		quantity: 497,
	},
	{
		garage_id: 158,
		part_id: 287,
		quantity: 384,
	},
	{
		garage_id: 158,
		part_id: 288,
		quantity: 62,
	},
	{
		garage_id: 158,
		part_id: 289,
		quantity: 409,
	},
	{
		garage_id: 158,
		part_id: 290,
		quantity: 121,
	},
	{
		garage_id: 158,
		part_id: 291,
		quantity: 142,
	},
	{
		garage_id: 158,
		part_id: 292,
		quantity: 97,
	},
	{
		garage_id: 158,
		part_id: 293,
		quantity: 238,
	},
	{
		garage_id: 158,
		part_id: 294,
		quantity: 246,
	},
	{
		garage_id: 158,
		part_id: 295,
		quantity: 174,
	},
	{
		garage_id: 158,
		part_id: 296,
		quantity: 243,
	},
	{
		garage_id: 158,
		part_id: 297,
		quantity: 456,
	},
	{
		garage_id: 158,
		part_id: 298,
		quantity: 34,
	},
	{
		garage_id: 158,
		part_id: 299,
		quantity: 205,
	},
	{
		garage_id: 158,
		part_id: 300,
		quantity: 187,
	},
	{
		garage_id: 158,
		part_id: 301,
		quantity: 244,
	},
	{
		garage_id: 158,
		part_id: 302,
		quantity: 338,
	},
	{
		garage_id: 158,
		part_id: 303,
		quantity: 282,
	},
	{
		garage_id: 158,
		part_id: 304,
		quantity: 227,
	},
	{
		garage_id: 158,
		part_id: 305,
		quantity: 422,
	},
	{
		garage_id: 158,
		part_id: 306,
		quantity: 69,
	},
	{
		garage_id: 158,
		part_id: 307,
		quantity: 98,
	},
	{
		garage_id: 158,
		part_id: 308,
		quantity: 112,
	},
	{
		garage_id: 158,
		part_id: 309,
		quantity: 326,
	},
	{
		garage_id: 158,
		part_id: 310,
		quantity: 189,
	},
	{
		garage_id: 158,
		part_id: 311,
		quantity: 359,
	},
	{
		garage_id: 158,
		part_id: 312,
		quantity: 298,
	},
	{
		garage_id: 158,
		part_id: 313,
		quantity: 261,
	},
	{
		garage_id: 158,
		part_id: 314,
		quantity: 401,
	},
	{
		garage_id: 158,
		part_id: 315,
		quantity: 338,
	},
	{
		garage_id: 158,
		part_id: 316,
		quantity: 368,
	},
	{
		garage_id: 158,
		part_id: 317,
		quantity: 250,
	},
	{
		garage_id: 158,
		part_id: 318,
		quantity: 217,
	},
	{
		garage_id: 158,
		part_id: 319,
		quantity: 134,
	},
	{
		garage_id: 158,
		part_id: 320,
		quantity: 111,
	},
	{
		garage_id: 158,
		part_id: 321,
		quantity: 9,
	},
	{
		garage_id: 158,
		part_id: 322,
		quantity: 241,
	},
	{
		garage_id: 158,
		part_id: 323,
		quantity: 17,
	},
	{
		garage_id: 158,
		part_id: 324,
		quantity: 212,
	},
	{
		garage_id: 158,
		part_id: 325,
		quantity: 39,
	},
	{
		garage_id: 158,
		part_id: 326,
		quantity: 36,
	},
	{
		garage_id: 158,
		part_id: 327,
		quantity: 343,
	},
	{
		garage_id: 158,
		part_id: 328,
		quantity: 238,
	},
	{
		garage_id: 158,
		part_id: 329,
		quantity: 4,
	},
	{
		garage_id: 158,
		part_id: 330,
		quantity: 92,
	},
	{
		garage_id: 158,
		part_id: 331,
		quantity: 236,
	},
	{
		garage_id: 158,
		part_id: 332,
		quantity: 108,
	},
	{
		garage_id: 158,
		part_id: 333,
		quantity: 493,
	},
	{
		garage_id: 158,
		part_id: 334,
		quantity: 464,
	},
	{
		garage_id: 158,
		part_id: 335,
		quantity: 488,
	},
	{
		garage_id: 158,
		part_id: 336,
		quantity: 37,
	},
	{
		garage_id: 158,
		part_id: 337,
		quantity: 370,
	},
	{
		garage_id: 158,
		part_id: 338,
		quantity: 138,
	},
	{
		garage_id: 158,
		part_id: 339,
		quantity: 209,
	},
	{
		garage_id: 158,
		part_id: 340,
		quantity: 186,
	},
	{
		garage_id: 158,
		part_id: 341,
		quantity: 225,
	},
	{
		garage_id: 158,
		part_id: 342,
		quantity: 186,
	},
	{
		garage_id: 158,
		part_id: 343,
		quantity: 158,
	},
	{
		garage_id: 158,
		part_id: 344,
		quantity: 446,
	},
	{
		garage_id: 158,
		part_id: 345,
		quantity: 305,
	},
	{
		garage_id: 158,
		part_id: 346,
		quantity: 362,
	},
	{
		garage_id: 158,
		part_id: 347,
		quantity: 91,
	},
	{
		garage_id: 158,
		part_id: 348,
		quantity: 423,
	},
	{
		garage_id: 158,
		part_id: 349,
		quantity: 467,
	},
	{
		garage_id: 158,
		part_id: 350,
		quantity: 480,
	},
	{
		garage_id: 158,
		part_id: 351,
		quantity: 42,
	},
	{
		garage_id: 158,
		part_id: 352,
		quantity: 269,
	},
	{
		garage_id: 158,
		part_id: 353,
		quantity: 90,
	},
	{
		garage_id: 158,
		part_id: 354,
		quantity: 223,
	},
	{
		garage_id: 158,
		part_id: 355,
		quantity: 174,
	},
	{
		garage_id: 158,
		part_id: 356,
		quantity: 185,
	},
	{
		garage_id: 158,
		part_id: 357,
		quantity: 364,
	},
	{
		garage_id: 158,
		part_id: 358,
		quantity: 383,
	},
	{
		garage_id: 158,
		part_id: 359,
		quantity: 373,
	},
	{
		garage_id: 158,
		part_id: 360,
		quantity: 446,
	},
	{
		garage_id: 158,
		part_id: 361,
		quantity: 115,
	},
	{
		garage_id: 158,
		part_id: 362,
		quantity: 308,
	},
	{
		garage_id: 158,
		part_id: 363,
		quantity: 376,
	},
	{
		garage_id: 158,
		part_id: 364,
		quantity: 233,
	},
	{
		garage_id: 158,
		part_id: 365,
		quantity: 292,
	},
	{
		garage_id: 158,
		part_id: 366,
		quantity: 373,
	},
	{
		garage_id: 158,
		part_id: 367,
		quantity: 481,
	},
	{
		garage_id: 158,
		part_id: 368,
		quantity: 148,
	},
	{
		garage_id: 158,
		part_id: 369,
		quantity: 202,
	},
	{
		garage_id: 158,
		part_id: 370,
		quantity: 480,
	},
	{
		garage_id: 158,
		part_id: 371,
		quantity: 390,
	},
	{
		garage_id: 158,
		part_id: 372,
		quantity: 47,
	},
	{
		garage_id: 158,
		part_id: 373,
		quantity: 429,
	},
	{
		garage_id: 158,
		part_id: 374,
		quantity: 478,
	},
	{
		garage_id: 158,
		part_id: 375,
		quantity: 300,
	},
	{
		garage_id: 158,
		part_id: 376,
		quantity: 405,
	},
	{
		garage_id: 158,
		part_id: 377,
		quantity: 356,
	},
	{
		garage_id: 158,
		part_id: 378,
		quantity: 93,
	},
	{
		garage_id: 158,
		part_id: 379,
		quantity: 44,
	},
	{
		garage_id: 158,
		part_id: 380,
		quantity: 498,
	},
	{
		garage_id: 158,
		part_id: 381,
		quantity: 480,
	},
	{
		garage_id: 158,
		part_id: 382,
		quantity: 107,
	},
	{
		garage_id: 158,
		part_id: 383,
		quantity: 39,
	},
	{
		garage_id: 158,
		part_id: 384,
		quantity: 236,
	},
	{
		garage_id: 158,
		part_id: 385,
		quantity: 322,
	},
	{
		garage_id: 158,
		part_id: 386,
		quantity: 424,
	},
	{
		garage_id: 158,
		part_id: 387,
		quantity: 490,
	},
	{
		garage_id: 158,
		part_id: 388,
		quantity: 175,
	},
	{
		garage_id: 158,
		part_id: 389,
		quantity: 491,
	},
	{
		garage_id: 158,
		part_id: 390,
		quantity: 103,
	},
	{
		garage_id: 158,
		part_id: 391,
		quantity: 320,
	},
	{
		garage_id: 158,
		part_id: 392,
		quantity: 439,
	},
	{
		garage_id: 158,
		part_id: 393,
		quantity: 22,
	},
	{
		garage_id: 158,
		part_id: 394,
		quantity: 490,
	},
	{
		garage_id: 158,
		part_id: 395,
		quantity: 484,
	},
	{
		garage_id: 158,
		part_id: 396,
		quantity: 57,
	},
	{
		garage_id: 158,
		part_id: 397,
		quantity: 223,
	},
	{
		garage_id: 158,
		part_id: 398,
		quantity: 55,
	},
	{
		garage_id: 158,
		part_id: 399,
		quantity: 491,
	},
	{
		garage_id: 159,
		part_id: 0,
		quantity: 72,
	},
	{
		garage_id: 159,
		part_id: 1,
		quantity: 112,
	},
	{
		garage_id: 159,
		part_id: 2,
		quantity: 357,
	},
	{
		garage_id: 159,
		part_id: 3,
		quantity: 188,
	},
	{
		garage_id: 159,
		part_id: 4,
		quantity: 58,
	},
	{
		garage_id: 159,
		part_id: 5,
		quantity: 467,
	},
	{
		garage_id: 159,
		part_id: 6,
		quantity: 9,
	},
	{
		garage_id: 159,
		part_id: 7,
		quantity: 248,
	},
	{
		garage_id: 159,
		part_id: 8,
		quantity: 163,
	},
	{
		garage_id: 159,
		part_id: 9,
		quantity: 497,
	},
	{
		garage_id: 159,
		part_id: 10,
		quantity: 4,
	},
	{
		garage_id: 159,
		part_id: 11,
		quantity: 282,
	},
	{
		garage_id: 159,
		part_id: 12,
		quantity: 500,
	},
	{
		garage_id: 159,
		part_id: 13,
		quantity: 199,
	},
	{
		garage_id: 159,
		part_id: 14,
		quantity: 28,
	},
	{
		garage_id: 159,
		part_id: 15,
		quantity: 128,
	},
	{
		garage_id: 159,
		part_id: 16,
		quantity: 180,
	},
	{
		garage_id: 159,
		part_id: 17,
		quantity: 386,
	},
	{
		garage_id: 159,
		part_id: 18,
		quantity: 284,
	},
	{
		garage_id: 159,
		part_id: 19,
		quantity: 416,
	},
	{
		garage_id: 159,
		part_id: 20,
		quantity: 328,
	},
	{
		garage_id: 159,
		part_id: 21,
		quantity: 274,
	},
	{
		garage_id: 159,
		part_id: 22,
		quantity: 261,
	},
	{
		garage_id: 159,
		part_id: 23,
		quantity: 352,
	},
	{
		garage_id: 159,
		part_id: 24,
		quantity: 198,
	},
	{
		garage_id: 159,
		part_id: 25,
		quantity: 352,
	},
	{
		garage_id: 159,
		part_id: 26,
		quantity: 403,
	},
	{
		garage_id: 159,
		part_id: 27,
		quantity: 468,
	},
	{
		garage_id: 159,
		part_id: 28,
		quantity: 470,
	},
	{
		garage_id: 159,
		part_id: 29,
		quantity: 457,
	},
	{
		garage_id: 159,
		part_id: 30,
		quantity: 38,
	},
	{
		garage_id: 159,
		part_id: 31,
		quantity: 87,
	},
	{
		garage_id: 159,
		part_id: 32,
		quantity: 270,
	},
	{
		garage_id: 159,
		part_id: 33,
		quantity: 385,
	},
	{
		garage_id: 159,
		part_id: 34,
		quantity: 101,
	},
	{
		garage_id: 159,
		part_id: 35,
		quantity: 299,
	},
	{
		garage_id: 159,
		part_id: 36,
		quantity: 281,
	},
	{
		garage_id: 159,
		part_id: 37,
		quantity: 308,
	},
	{
		garage_id: 159,
		part_id: 38,
		quantity: 471,
	},
	{
		garage_id: 159,
		part_id: 39,
		quantity: 487,
	},
	{
		garage_id: 159,
		part_id: 40,
		quantity: 55,
	},
	{
		garage_id: 159,
		part_id: 41,
		quantity: 397,
	},
	{
		garage_id: 159,
		part_id: 42,
		quantity: 274,
	},
	{
		garage_id: 159,
		part_id: 43,
		quantity: 482,
	},
	{
		garage_id: 159,
		part_id: 44,
		quantity: 165,
	},
	{
		garage_id: 159,
		part_id: 45,
		quantity: 280,
	},
	{
		garage_id: 159,
		part_id: 46,
		quantity: 356,
	},
	{
		garage_id: 159,
		part_id: 47,
		quantity: 388,
	},
	{
		garage_id: 159,
		part_id: 48,
		quantity: 39,
	},
	{
		garage_id: 159,
		part_id: 49,
		quantity: 65,
	},
	{
		garage_id: 159,
		part_id: 50,
		quantity: 131,
	},
	{
		garage_id: 159,
		part_id: 51,
		quantity: 280,
	},
	{
		garage_id: 159,
		part_id: 52,
		quantity: 119,
	},
	{
		garage_id: 159,
		part_id: 53,
		quantity: 392,
	},
	{
		garage_id: 159,
		part_id: 54,
		quantity: 135,
	},
	{
		garage_id: 159,
		part_id: 55,
		quantity: 262,
	},
	{
		garage_id: 159,
		part_id: 56,
		quantity: 273,
	},
	{
		garage_id: 159,
		part_id: 57,
		quantity: 144,
	},
	{
		garage_id: 159,
		part_id: 58,
		quantity: 290,
	},
	{
		garage_id: 159,
		part_id: 59,
		quantity: 423,
	},
	{
		garage_id: 159,
		part_id: 60,
		quantity: 4,
	},
	{
		garage_id: 159,
		part_id: 61,
		quantity: 101,
	},
	{
		garage_id: 159,
		part_id: 62,
		quantity: 474,
	},
	{
		garage_id: 159,
		part_id: 63,
		quantity: 16,
	},
	{
		garage_id: 159,
		part_id: 64,
		quantity: 190,
	},
	{
		garage_id: 159,
		part_id: 65,
		quantity: 422,
	},
	{
		garage_id: 159,
		part_id: 66,
		quantity: 450,
	},
	{
		garage_id: 159,
		part_id: 67,
		quantity: 355,
	},
	{
		garage_id: 159,
		part_id: 68,
		quantity: 380,
	},
	{
		garage_id: 159,
		part_id: 69,
		quantity: 17,
	},
	{
		garage_id: 159,
		part_id: 70,
		quantity: 396,
	},
	{
		garage_id: 159,
		part_id: 71,
		quantity: 387,
	},
	{
		garage_id: 159,
		part_id: 72,
		quantity: 123,
	},
	{
		garage_id: 159,
		part_id: 73,
		quantity: 362,
	},
	{
		garage_id: 159,
		part_id: 74,
		quantity: 164,
	},
	{
		garage_id: 159,
		part_id: 75,
		quantity: 311,
	},
	{
		garage_id: 159,
		part_id: 76,
		quantity: 309,
	},
	{
		garage_id: 159,
		part_id: 77,
		quantity: 208,
	},
	{
		garage_id: 159,
		part_id: 78,
		quantity: 358,
	},
	{
		garage_id: 159,
		part_id: 79,
		quantity: 181,
	},
	{
		garage_id: 159,
		part_id: 80,
		quantity: 1,
	},
	{
		garage_id: 159,
		part_id: 81,
		quantity: 329,
	},
	{
		garage_id: 159,
		part_id: 82,
		quantity: 276,
	},
	{
		garage_id: 159,
		part_id: 83,
		quantity: 162,
	},
	{
		garage_id: 159,
		part_id: 84,
		quantity: 31,
	},
	{
		garage_id: 159,
		part_id: 85,
		quantity: 171,
	},
	{
		garage_id: 159,
		part_id: 86,
		quantity: 444,
	},
	{
		garage_id: 159,
		part_id: 87,
		quantity: 287,
	},
	{
		garage_id: 159,
		part_id: 88,
		quantity: 131,
	},
	{
		garage_id: 159,
		part_id: 89,
		quantity: 144,
	},
	{
		garage_id: 159,
		part_id: 90,
		quantity: 337,
	},
	{
		garage_id: 159,
		part_id: 91,
		quantity: 305,
	},
	{
		garage_id: 159,
		part_id: 92,
		quantity: 438,
	},
	{
		garage_id: 159,
		part_id: 93,
		quantity: 167,
	},
	{
		garage_id: 159,
		part_id: 94,
		quantity: 286,
	},
	{
		garage_id: 159,
		part_id: 95,
		quantity: 184,
	},
	{
		garage_id: 159,
		part_id: 96,
		quantity: 159,
	},
	{
		garage_id: 159,
		part_id: 97,
		quantity: 283,
	},
	{
		garage_id: 159,
		part_id: 98,
		quantity: 306,
	},
	{
		garage_id: 159,
		part_id: 99,
		quantity: 204,
	},
	{
		garage_id: 159,
		part_id: 100,
		quantity: 448,
	},
	{
		garage_id: 159,
		part_id: 101,
		quantity: 399,
	},
	{
		garage_id: 159,
		part_id: 102,
		quantity: 264,
	},
	{
		garage_id: 159,
		part_id: 103,
		quantity: 344,
	},
	{
		garage_id: 159,
		part_id: 104,
		quantity: 41,
	},
	{
		garage_id: 159,
		part_id: 105,
		quantity: 37,
	},
	{
		garage_id: 159,
		part_id: 106,
		quantity: 234,
	},
	{
		garage_id: 159,
		part_id: 107,
		quantity: 260,
	},
	{
		garage_id: 159,
		part_id: 108,
		quantity: 320,
	},
	{
		garage_id: 159,
		part_id: 109,
		quantity: 393,
	},
	{
		garage_id: 159,
		part_id: 110,
		quantity: 7,
	},
	{
		garage_id: 159,
		part_id: 111,
		quantity: 252,
	},
	{
		garage_id: 159,
		part_id: 112,
		quantity: 136,
	},
	{
		garage_id: 159,
		part_id: 113,
		quantity: 245,
	},
	{
		garage_id: 159,
		part_id: 114,
		quantity: 43,
	},
	{
		garage_id: 159,
		part_id: 115,
		quantity: 206,
	},
	{
		garage_id: 159,
		part_id: 116,
		quantity: 138,
	},
	{
		garage_id: 159,
		part_id: 117,
		quantity: 379,
	},
	{
		garage_id: 159,
		part_id: 118,
		quantity: 392,
	},
	{
		garage_id: 159,
		part_id: 119,
		quantity: 330,
	},
	{
		garage_id: 159,
		part_id: 120,
		quantity: 368,
	},
	{
		garage_id: 159,
		part_id: 121,
		quantity: 213,
	},
	{
		garage_id: 159,
		part_id: 122,
		quantity: 330,
	},
	{
		garage_id: 159,
		part_id: 123,
		quantity: 201,
	},
	{
		garage_id: 159,
		part_id: 124,
		quantity: 212,
	},
	{
		garage_id: 159,
		part_id: 125,
		quantity: 361,
	},
	{
		garage_id: 159,
		part_id: 126,
		quantity: 223,
	},
	{
		garage_id: 159,
		part_id: 127,
		quantity: 321,
	},
	{
		garage_id: 159,
		part_id: 128,
		quantity: 113,
	},
	{
		garage_id: 159,
		part_id: 129,
		quantity: 325,
	},
	{
		garage_id: 159,
		part_id: 130,
		quantity: 453,
	},
	{
		garage_id: 159,
		part_id: 131,
		quantity: 27,
	},
	{
		garage_id: 159,
		part_id: 132,
		quantity: 341,
	},
	{
		garage_id: 159,
		part_id: 133,
		quantity: 45,
	},
	{
		garage_id: 159,
		part_id: 134,
		quantity: 3,
	},
	{
		garage_id: 159,
		part_id: 135,
		quantity: 198,
	},
	{
		garage_id: 159,
		part_id: 136,
		quantity: 266,
	},
	{
		garage_id: 159,
		part_id: 137,
		quantity: 100,
	},
	{
		garage_id: 159,
		part_id: 138,
		quantity: 285,
	},
	{
		garage_id: 159,
		part_id: 139,
		quantity: 378,
	},
	{
		garage_id: 159,
		part_id: 140,
		quantity: 83,
	},
	{
		garage_id: 159,
		part_id: 141,
		quantity: 35,
	},
	{
		garage_id: 159,
		part_id: 142,
		quantity: 80,
	},
	{
		garage_id: 159,
		part_id: 143,
		quantity: 180,
	},
	{
		garage_id: 159,
		part_id: 144,
		quantity: 459,
	},
	{
		garage_id: 159,
		part_id: 145,
		quantity: 129,
	},
	{
		garage_id: 159,
		part_id: 146,
		quantity: 178,
	},
	{
		garage_id: 159,
		part_id: 147,
		quantity: 205,
	},
	{
		garage_id: 159,
		part_id: 148,
		quantity: 403,
	},
	{
		garage_id: 159,
		part_id: 149,
		quantity: 421,
	},
	{
		garage_id: 159,
		part_id: 150,
		quantity: 290,
	},
	{
		garage_id: 159,
		part_id: 151,
		quantity: 98,
	},
	{
		garage_id: 159,
		part_id: 152,
		quantity: 282,
	},
	{
		garage_id: 159,
		part_id: 153,
		quantity: 344,
	},
	{
		garage_id: 159,
		part_id: 154,
		quantity: 270,
	},
	{
		garage_id: 159,
		part_id: 155,
		quantity: 163,
	},
	{
		garage_id: 159,
		part_id: 156,
		quantity: 153,
	},
	{
		garage_id: 159,
		part_id: 157,
		quantity: 169,
	},
	{
		garage_id: 159,
		part_id: 158,
		quantity: 440,
	},
	{
		garage_id: 159,
		part_id: 159,
		quantity: 224,
	},
	{
		garage_id: 159,
		part_id: 160,
		quantity: 478,
	},
	{
		garage_id: 159,
		part_id: 161,
		quantity: 27,
	},
	{
		garage_id: 159,
		part_id: 162,
		quantity: 320,
	},
	{
		garage_id: 159,
		part_id: 163,
		quantity: 441,
	},
	{
		garage_id: 159,
		part_id: 164,
		quantity: 404,
	},
	{
		garage_id: 159,
		part_id: 165,
		quantity: 50,
	},
	{
		garage_id: 159,
		part_id: 166,
		quantity: 145,
	},
	{
		garage_id: 159,
		part_id: 167,
		quantity: 429,
	},
	{
		garage_id: 159,
		part_id: 168,
		quantity: 474,
	},
	{
		garage_id: 159,
		part_id: 169,
		quantity: 405,
	},
	{
		garage_id: 159,
		part_id: 170,
		quantity: 79,
	},
	{
		garage_id: 159,
		part_id: 171,
		quantity: 347,
	},
	{
		garage_id: 159,
		part_id: 172,
		quantity: 178,
	},
	{
		garage_id: 159,
		part_id: 173,
		quantity: 417,
	},
	{
		garage_id: 159,
		part_id: 174,
		quantity: 328,
	},
	{
		garage_id: 159,
		part_id: 175,
		quantity: 383,
	},
	{
		garage_id: 159,
		part_id: 176,
		quantity: 491,
	},
	{
		garage_id: 159,
		part_id: 177,
		quantity: 329,
	},
	{
		garage_id: 159,
		part_id: 178,
		quantity: 162,
	},
	{
		garage_id: 159,
		part_id: 179,
		quantity: 139,
	},
	{
		garage_id: 159,
		part_id: 180,
		quantity: 318,
	},
	{
		garage_id: 159,
		part_id: 181,
		quantity: 397,
	},
	{
		garage_id: 159,
		part_id: 182,
		quantity: 479,
	},
	{
		garage_id: 159,
		part_id: 183,
		quantity: 65,
	},
	{
		garage_id: 159,
		part_id: 184,
		quantity: 406,
	},
	{
		garage_id: 159,
		part_id: 185,
		quantity: 404,
	},
	{
		garage_id: 159,
		part_id: 186,
		quantity: 341,
	},
	{
		garage_id: 159,
		part_id: 187,
		quantity: 123,
	},
	{
		garage_id: 159,
		part_id: 188,
		quantity: 331,
	},
	{
		garage_id: 159,
		part_id: 189,
		quantity: 27,
	},
	{
		garage_id: 159,
		part_id: 190,
		quantity: 44,
	},
	{
		garage_id: 159,
		part_id: 191,
		quantity: 113,
	},
	{
		garage_id: 159,
		part_id: 192,
		quantity: 170,
	},
	{
		garage_id: 159,
		part_id: 193,
		quantity: 189,
	},
	{
		garage_id: 159,
		part_id: 194,
		quantity: 173,
	},
	{
		garage_id: 159,
		part_id: 195,
		quantity: 453,
	},
	{
		garage_id: 159,
		part_id: 196,
		quantity: 189,
	},
	{
		garage_id: 159,
		part_id: 197,
		quantity: 341,
	},
	{
		garage_id: 159,
		part_id: 198,
		quantity: 319,
	},
	{
		garage_id: 159,
		part_id: 199,
		quantity: 255,
	},
	{
		garage_id: 159,
		part_id: 200,
		quantity: 330,
	},
	{
		garage_id: 159,
		part_id: 201,
		quantity: 428,
	},
	{
		garage_id: 159,
		part_id: 202,
		quantity: 377,
	},
	{
		garage_id: 159,
		part_id: 203,
		quantity: 341,
	},
	{
		garage_id: 159,
		part_id: 204,
		quantity: 160,
	},
	{
		garage_id: 159,
		part_id: 205,
		quantity: 250,
	},
	{
		garage_id: 159,
		part_id: 206,
		quantity: 421,
	},
	{
		garage_id: 159,
		part_id: 207,
		quantity: 382,
	},
	{
		garage_id: 159,
		part_id: 208,
		quantity: 422,
	},
	{
		garage_id: 159,
		part_id: 209,
		quantity: 266,
	},
	{
		garage_id: 159,
		part_id: 210,
		quantity: 266,
	},
	{
		garage_id: 159,
		part_id: 211,
		quantity: 209,
	},
	{
		garage_id: 159,
		part_id: 212,
		quantity: 276,
	},
	{
		garage_id: 159,
		part_id: 213,
		quantity: 443,
	},
	{
		garage_id: 159,
		part_id: 214,
		quantity: 469,
	},
	{
		garage_id: 159,
		part_id: 215,
		quantity: 152,
	},
	{
		garage_id: 159,
		part_id: 216,
		quantity: 131,
	},
	{
		garage_id: 159,
		part_id: 217,
		quantity: 232,
	},
	{
		garage_id: 159,
		part_id: 218,
		quantity: 76,
	},
	{
		garage_id: 159,
		part_id: 219,
		quantity: 14,
	},
	{
		garage_id: 159,
		part_id: 220,
		quantity: 245,
	},
	{
		garage_id: 159,
		part_id: 221,
		quantity: 312,
	},
	{
		garage_id: 159,
		part_id: 222,
		quantity: 157,
	},
	{
		garage_id: 159,
		part_id: 223,
		quantity: 289,
	},
	{
		garage_id: 159,
		part_id: 224,
		quantity: 254,
	},
	{
		garage_id: 159,
		part_id: 225,
		quantity: 429,
	},
	{
		garage_id: 159,
		part_id: 226,
		quantity: 8,
	},
	{
		garage_id: 159,
		part_id: 227,
		quantity: 202,
	},
	{
		garage_id: 159,
		part_id: 228,
		quantity: 200,
	},
	{
		garage_id: 159,
		part_id: 229,
		quantity: 77,
	},
	{
		garage_id: 159,
		part_id: 230,
		quantity: 351,
	},
	{
		garage_id: 159,
		part_id: 231,
		quantity: 57,
	},
	{
		garage_id: 159,
		part_id: 232,
		quantity: 413,
	},
	{
		garage_id: 159,
		part_id: 233,
		quantity: 168,
	},
	{
		garage_id: 159,
		part_id: 234,
		quantity: 212,
	},
	{
		garage_id: 159,
		part_id: 235,
		quantity: 291,
	},
	{
		garage_id: 159,
		part_id: 236,
		quantity: 66,
	},
	{
		garage_id: 159,
		part_id: 237,
		quantity: 91,
	},
	{
		garage_id: 159,
		part_id: 238,
		quantity: 58,
	},
	{
		garage_id: 159,
		part_id: 239,
		quantity: 406,
	},
	{
		garage_id: 159,
		part_id: 240,
		quantity: 183,
	},
	{
		garage_id: 159,
		part_id: 241,
		quantity: 151,
	},
	{
		garage_id: 159,
		part_id: 242,
		quantity: 420,
	},
	{
		garage_id: 159,
		part_id: 243,
		quantity: 330,
	},
	{
		garage_id: 159,
		part_id: 244,
		quantity: 191,
	},
	{
		garage_id: 159,
		part_id: 245,
		quantity: 226,
	},
	{
		garage_id: 159,
		part_id: 246,
		quantity: 403,
	},
	{
		garage_id: 159,
		part_id: 247,
		quantity: 374,
	},
	{
		garage_id: 159,
		part_id: 248,
		quantity: 256,
	},
	{
		garage_id: 159,
		part_id: 249,
		quantity: 397,
	},
	{
		garage_id: 159,
		part_id: 250,
		quantity: 430,
	},
	{
		garage_id: 159,
		part_id: 251,
		quantity: 147,
	},
	{
		garage_id: 159,
		part_id: 252,
		quantity: 264,
	},
	{
		garage_id: 159,
		part_id: 253,
		quantity: 468,
	},
	{
		garage_id: 159,
		part_id: 254,
		quantity: 408,
	},
	{
		garage_id: 159,
		part_id: 255,
		quantity: 481,
	},
	{
		garage_id: 159,
		part_id: 256,
		quantity: 198,
	},
	{
		garage_id: 159,
		part_id: 257,
		quantity: 16,
	},
	{
		garage_id: 159,
		part_id: 258,
		quantity: 66,
	},
	{
		garage_id: 159,
		part_id: 259,
		quantity: 437,
	},
	{
		garage_id: 159,
		part_id: 260,
		quantity: 179,
	},
	{
		garage_id: 159,
		part_id: 261,
		quantity: 484,
	},
	{
		garage_id: 159,
		part_id: 262,
		quantity: 148,
	},
	{
		garage_id: 159,
		part_id: 263,
		quantity: 421,
	},
	{
		garage_id: 159,
		part_id: 264,
		quantity: 394,
	},
	{
		garage_id: 159,
		part_id: 265,
		quantity: 4,
	},
	{
		garage_id: 159,
		part_id: 266,
		quantity: 322,
	},
	{
		garage_id: 159,
		part_id: 267,
		quantity: 73,
	},
	{
		garage_id: 159,
		part_id: 268,
		quantity: 293,
	},
	{
		garage_id: 159,
		part_id: 269,
		quantity: 356,
	},
	{
		garage_id: 159,
		part_id: 270,
		quantity: 1,
	},
	{
		garage_id: 159,
		part_id: 271,
		quantity: 358,
	},
	{
		garage_id: 159,
		part_id: 272,
		quantity: 393,
	},
	{
		garage_id: 159,
		part_id: 273,
		quantity: 35,
	},
	{
		garage_id: 159,
		part_id: 274,
		quantity: 412,
	},
	{
		garage_id: 159,
		part_id: 275,
		quantity: 202,
	},
	{
		garage_id: 159,
		part_id: 276,
		quantity: 271,
	},
	{
		garage_id: 159,
		part_id: 277,
		quantity: 171,
	},
	{
		garage_id: 159,
		part_id: 278,
		quantity: 346,
	},
	{
		garage_id: 159,
		part_id: 279,
		quantity: 394,
	},
	{
		garage_id: 159,
		part_id: 280,
		quantity: 64,
	},
	{
		garage_id: 159,
		part_id: 281,
		quantity: 300,
	},
	{
		garage_id: 159,
		part_id: 282,
		quantity: 307,
	},
	{
		garage_id: 159,
		part_id: 283,
		quantity: 341,
	},
	{
		garage_id: 159,
		part_id: 284,
		quantity: 41,
	},
	{
		garage_id: 159,
		part_id: 285,
		quantity: 310,
	},
	{
		garage_id: 159,
		part_id: 286,
		quantity: 149,
	},
	{
		garage_id: 159,
		part_id: 287,
		quantity: 95,
	},
	{
		garage_id: 159,
		part_id: 288,
		quantity: 95,
	},
	{
		garage_id: 159,
		part_id: 289,
		quantity: 253,
	},
	{
		garage_id: 159,
		part_id: 290,
		quantity: 463,
	},
	{
		garage_id: 159,
		part_id: 291,
		quantity: 477,
	},
	{
		garage_id: 159,
		part_id: 292,
		quantity: 333,
	},
	{
		garage_id: 159,
		part_id: 293,
		quantity: 281,
	},
	{
		garage_id: 159,
		part_id: 294,
		quantity: 253,
	},
	{
		garage_id: 159,
		part_id: 295,
		quantity: 325,
	},
	{
		garage_id: 159,
		part_id: 296,
		quantity: 279,
	},
	{
		garage_id: 159,
		part_id: 297,
		quantity: 78,
	},
	{
		garage_id: 159,
		part_id: 298,
		quantity: 109,
	},
	{
		garage_id: 159,
		part_id: 299,
		quantity: 27,
	},
	{
		garage_id: 159,
		part_id: 300,
		quantity: 413,
	},
	{
		garage_id: 159,
		part_id: 301,
		quantity: 50,
	},
	{
		garage_id: 159,
		part_id: 302,
		quantity: 242,
	},
	{
		garage_id: 159,
		part_id: 303,
		quantity: 105,
	},
	{
		garage_id: 159,
		part_id: 304,
		quantity: 351,
	},
	{
		garage_id: 159,
		part_id: 305,
		quantity: 251,
	},
	{
		garage_id: 159,
		part_id: 306,
		quantity: 172,
	},
	{
		garage_id: 159,
		part_id: 307,
		quantity: 121,
	},
	{
		garage_id: 159,
		part_id: 308,
		quantity: 42,
	},
	{
		garage_id: 159,
		part_id: 309,
		quantity: 469,
	},
	{
		garage_id: 159,
		part_id: 310,
		quantity: 128,
	},
	{
		garage_id: 159,
		part_id: 311,
		quantity: 445,
	},
	{
		garage_id: 159,
		part_id: 312,
		quantity: 415,
	},
	{
		garage_id: 159,
		part_id: 313,
		quantity: 347,
	},
	{
		garage_id: 159,
		part_id: 314,
		quantity: 473,
	},
	{
		garage_id: 159,
		part_id: 315,
		quantity: 203,
	},
	{
		garage_id: 159,
		part_id: 316,
		quantity: 356,
	},
	{
		garage_id: 159,
		part_id: 317,
		quantity: 489,
	},
	{
		garage_id: 159,
		part_id: 318,
		quantity: 339,
	},
	{
		garage_id: 159,
		part_id: 319,
		quantity: 56,
	},
	{
		garage_id: 159,
		part_id: 320,
		quantity: 366,
	},
	{
		garage_id: 159,
		part_id: 321,
		quantity: 372,
	},
	{
		garage_id: 159,
		part_id: 322,
		quantity: 466,
	},
	{
		garage_id: 159,
		part_id: 323,
		quantity: 490,
	},
	{
		garage_id: 159,
		part_id: 324,
		quantity: 271,
	},
	{
		garage_id: 159,
		part_id: 325,
		quantity: 37,
	},
	{
		garage_id: 159,
		part_id: 326,
		quantity: 29,
	},
	{
		garage_id: 159,
		part_id: 327,
		quantity: 461,
	},
	{
		garage_id: 159,
		part_id: 328,
		quantity: 447,
	},
	{
		garage_id: 159,
		part_id: 329,
		quantity: 341,
	},
	{
		garage_id: 159,
		part_id: 330,
		quantity: 249,
	},
	{
		garage_id: 159,
		part_id: 331,
		quantity: 232,
	},
	{
		garage_id: 159,
		part_id: 332,
		quantity: 227,
	},
	{
		garage_id: 159,
		part_id: 333,
		quantity: 235,
	},
	{
		garage_id: 159,
		part_id: 334,
		quantity: 260,
	},
	{
		garage_id: 159,
		part_id: 335,
		quantity: 96,
	},
	{
		garage_id: 159,
		part_id: 336,
		quantity: 322,
	},
	{
		garage_id: 159,
		part_id: 337,
		quantity: 471,
	},
	{
		garage_id: 159,
		part_id: 338,
		quantity: 146,
	},
	{
		garage_id: 159,
		part_id: 339,
		quantity: 83,
	},
	{
		garage_id: 159,
		part_id: 340,
		quantity: 218,
	},
	{
		garage_id: 159,
		part_id: 341,
		quantity: 373,
	},
	{
		garage_id: 159,
		part_id: 342,
		quantity: 6,
	},
	{
		garage_id: 159,
		part_id: 343,
		quantity: 401,
	},
	{
		garage_id: 159,
		part_id: 344,
		quantity: 321,
	},
	{
		garage_id: 159,
		part_id: 345,
		quantity: 108,
	},
	{
		garage_id: 159,
		part_id: 346,
		quantity: 204,
	},
	{
		garage_id: 159,
		part_id: 347,
		quantity: 176,
	},
	{
		garage_id: 159,
		part_id: 348,
		quantity: 152,
	},
	{
		garage_id: 159,
		part_id: 349,
		quantity: 216,
	},
	{
		garage_id: 159,
		part_id: 350,
		quantity: 33,
	},
	{
		garage_id: 159,
		part_id: 351,
		quantity: 355,
	},
	{
		garage_id: 159,
		part_id: 352,
		quantity: 473,
	},
	{
		garage_id: 159,
		part_id: 353,
		quantity: 285,
	},
	{
		garage_id: 159,
		part_id: 354,
		quantity: 120,
	},
	{
		garage_id: 159,
		part_id: 355,
		quantity: 376,
	},
	{
		garage_id: 159,
		part_id: 356,
		quantity: 220,
	},
	{
		garage_id: 159,
		part_id: 357,
		quantity: 127,
	},
	{
		garage_id: 159,
		part_id: 358,
		quantity: 237,
	},
	{
		garage_id: 159,
		part_id: 359,
		quantity: 216,
	},
	{
		garage_id: 159,
		part_id: 360,
		quantity: 211,
	},
	{
		garage_id: 159,
		part_id: 361,
		quantity: 386,
	},
	{
		garage_id: 159,
		part_id: 362,
		quantity: 245,
	},
	{
		garage_id: 159,
		part_id: 363,
		quantity: 399,
	},
	{
		garage_id: 159,
		part_id: 364,
		quantity: 97,
	},
	{
		garage_id: 159,
		part_id: 365,
		quantity: 187,
	},
	{
		garage_id: 159,
		part_id: 366,
		quantity: 195,
	},
	{
		garage_id: 159,
		part_id: 367,
		quantity: 105,
	},
	{
		garage_id: 159,
		part_id: 368,
		quantity: 312,
	},
	{
		garage_id: 159,
		part_id: 369,
		quantity: 186,
	},
	{
		garage_id: 159,
		part_id: 370,
		quantity: 57,
	},
	{
		garage_id: 159,
		part_id: 371,
		quantity: 452,
	},
	{
		garage_id: 159,
		part_id: 372,
		quantity: 210,
	},
	{
		garage_id: 159,
		part_id: 373,
		quantity: 221,
	},
	{
		garage_id: 159,
		part_id: 374,
		quantity: 71,
	},
	{
		garage_id: 159,
		part_id: 375,
		quantity: 217,
	},
	{
		garage_id: 159,
		part_id: 376,
		quantity: 363,
	},
	{
		garage_id: 159,
		part_id: 377,
		quantity: 195,
	},
	{
		garage_id: 159,
		part_id: 378,
		quantity: 67,
	},
	{
		garage_id: 159,
		part_id: 379,
		quantity: 497,
	},
	{
		garage_id: 159,
		part_id: 380,
		quantity: 123,
	},
	{
		garage_id: 159,
		part_id: 381,
		quantity: 352,
	},
	{
		garage_id: 159,
		part_id: 382,
		quantity: 471,
	},
	{
		garage_id: 159,
		part_id: 383,
		quantity: 165,
	},
	{
		garage_id: 159,
		part_id: 384,
		quantity: 359,
	},
	{
		garage_id: 159,
		part_id: 385,
		quantity: 62,
	},
	{
		garage_id: 159,
		part_id: 386,
		quantity: 176,
	},
	{
		garage_id: 159,
		part_id: 387,
		quantity: 13,
	},
	{
		garage_id: 159,
		part_id: 388,
		quantity: 359,
	},
	{
		garage_id: 159,
		part_id: 389,
		quantity: 221,
	},
	{
		garage_id: 159,
		part_id: 390,
		quantity: 430,
	},
	{
		garage_id: 159,
		part_id: 391,
		quantity: 489,
	},
	{
		garage_id: 159,
		part_id: 392,
		quantity: 356,
	},
	{
		garage_id: 159,
		part_id: 393,
		quantity: 150,
	},
	{
		garage_id: 159,
		part_id: 394,
		quantity: 439,
	},
	{
		garage_id: 159,
		part_id: 395,
		quantity: 335,
	},
	{
		garage_id: 159,
		part_id: 396,
		quantity: 412,
	},
	{
		garage_id: 159,
		part_id: 397,
		quantity: 195,
	},
	{
		garage_id: 159,
		part_id: 398,
		quantity: 373,
	},
	{
		garage_id: 159,
		part_id: 399,
		quantity: 6,
	},
	{
		garage_id: 160,
		part_id: 0,
		quantity: 98,
	},
	{
		garage_id: 160,
		part_id: 1,
		quantity: 342,
	},
	{
		garage_id: 160,
		part_id: 2,
		quantity: 372,
	},
	{
		garage_id: 160,
		part_id: 3,
		quantity: 103,
	},
	{
		garage_id: 160,
		part_id: 4,
		quantity: 101,
	},
	{
		garage_id: 160,
		part_id: 5,
		quantity: 341,
	},
	{
		garage_id: 160,
		part_id: 6,
		quantity: 281,
	},
	{
		garage_id: 160,
		part_id: 7,
		quantity: 247,
	},
	{
		garage_id: 160,
		part_id: 8,
		quantity: 424,
	},
	{
		garage_id: 160,
		part_id: 9,
		quantity: 37,
	},
	{
		garage_id: 160,
		part_id: 10,
		quantity: 333,
	},
	{
		garage_id: 160,
		part_id: 11,
		quantity: 357,
	},
	{
		garage_id: 160,
		part_id: 12,
		quantity: 173,
	},
	{
		garage_id: 160,
		part_id: 13,
		quantity: 499,
	},
	{
		garage_id: 160,
		part_id: 14,
		quantity: 137,
	},
	{
		garage_id: 160,
		part_id: 15,
		quantity: 191,
	},
	{
		garage_id: 160,
		part_id: 16,
		quantity: 401,
	},
	{
		garage_id: 160,
		part_id: 17,
		quantity: 435,
	},
	{
		garage_id: 160,
		part_id: 18,
		quantity: 333,
	},
	{
		garage_id: 160,
		part_id: 19,
		quantity: 344,
	},
	{
		garage_id: 160,
		part_id: 20,
		quantity: 435,
	},
	{
		garage_id: 160,
		part_id: 21,
		quantity: 431,
	},
	{
		garage_id: 160,
		part_id: 22,
		quantity: 60,
	},
	{
		garage_id: 160,
		part_id: 23,
		quantity: 475,
	},
	{
		garage_id: 160,
		part_id: 24,
		quantity: 413,
	},
	{
		garage_id: 160,
		part_id: 25,
		quantity: 349,
	},
	{
		garage_id: 160,
		part_id: 26,
		quantity: 205,
	},
	{
		garage_id: 160,
		part_id: 27,
		quantity: 186,
	},
	{
		garage_id: 160,
		part_id: 28,
		quantity: 449,
	},
	{
		garage_id: 160,
		part_id: 29,
		quantity: 17,
	},
	{
		garage_id: 160,
		part_id: 30,
		quantity: 230,
	},
	{
		garage_id: 160,
		part_id: 31,
		quantity: 235,
	},
	{
		garage_id: 160,
		part_id: 32,
		quantity: 241,
	},
	{
		garage_id: 160,
		part_id: 33,
		quantity: 264,
	},
	{
		garage_id: 160,
		part_id: 34,
		quantity: 345,
	},
	{
		garage_id: 160,
		part_id: 35,
		quantity: 50,
	},
	{
		garage_id: 160,
		part_id: 36,
		quantity: 460,
	},
	{
		garage_id: 160,
		part_id: 37,
		quantity: 214,
	},
	{
		garage_id: 160,
		part_id: 38,
		quantity: 348,
	},
	{
		garage_id: 160,
		part_id: 39,
		quantity: 465,
	},
	{
		garage_id: 160,
		part_id: 40,
		quantity: 74,
	},
	{
		garage_id: 160,
		part_id: 41,
		quantity: 122,
	},
	{
		garage_id: 160,
		part_id: 42,
		quantity: 451,
	},
	{
		garage_id: 160,
		part_id: 43,
		quantity: 99,
	},
	{
		garage_id: 160,
		part_id: 44,
		quantity: 251,
	},
	{
		garage_id: 160,
		part_id: 45,
		quantity: 159,
	},
	{
		garage_id: 160,
		part_id: 46,
		quantity: 252,
	},
	{
		garage_id: 160,
		part_id: 47,
		quantity: 166,
	},
	{
		garage_id: 160,
		part_id: 48,
		quantity: 87,
	},
	{
		garage_id: 160,
		part_id: 49,
		quantity: 178,
	},
	{
		garage_id: 160,
		part_id: 50,
		quantity: 457,
	},
	{
		garage_id: 160,
		part_id: 51,
		quantity: 388,
	},
	{
		garage_id: 160,
		part_id: 52,
		quantity: 343,
	},
	{
		garage_id: 160,
		part_id: 53,
		quantity: 383,
	},
	{
		garage_id: 160,
		part_id: 54,
		quantity: 488,
	},
	{
		garage_id: 160,
		part_id: 55,
		quantity: 217,
	},
	{
		garage_id: 160,
		part_id: 56,
		quantity: 66,
	},
	{
		garage_id: 160,
		part_id: 57,
		quantity: 263,
	},
	{
		garage_id: 160,
		part_id: 58,
		quantity: 420,
	},
	{
		garage_id: 160,
		part_id: 59,
		quantity: 495,
	},
	{
		garage_id: 160,
		part_id: 60,
		quantity: 190,
	},
	{
		garage_id: 160,
		part_id: 61,
		quantity: 291,
	},
	{
		garage_id: 160,
		part_id: 62,
		quantity: 340,
	},
	{
		garage_id: 160,
		part_id: 63,
		quantity: 206,
	},
	{
		garage_id: 160,
		part_id: 64,
		quantity: 118,
	},
	{
		garage_id: 160,
		part_id: 65,
		quantity: 397,
	},
	{
		garage_id: 160,
		part_id: 66,
		quantity: 128,
	},
	{
		garage_id: 160,
		part_id: 67,
		quantity: 395,
	},
	{
		garage_id: 160,
		part_id: 68,
		quantity: 494,
	},
	{
		garage_id: 160,
		part_id: 69,
		quantity: 423,
	},
	{
		garage_id: 160,
		part_id: 70,
		quantity: 97,
	},
	{
		garage_id: 160,
		part_id: 71,
		quantity: 281,
	},
	{
		garage_id: 160,
		part_id: 72,
		quantity: 89,
	},
	{
		garage_id: 160,
		part_id: 73,
		quantity: 27,
	},
	{
		garage_id: 160,
		part_id: 74,
		quantity: 443,
	},
	{
		garage_id: 160,
		part_id: 75,
		quantity: 245,
	},
	{
		garage_id: 160,
		part_id: 76,
		quantity: 79,
	},
	{
		garage_id: 160,
		part_id: 77,
		quantity: 237,
	},
	{
		garage_id: 160,
		part_id: 78,
		quantity: 169,
	},
	{
		garage_id: 160,
		part_id: 79,
		quantity: 204,
	},
	{
		garage_id: 160,
		part_id: 80,
		quantity: 178,
	},
	{
		garage_id: 160,
		part_id: 81,
		quantity: 93,
	},
	{
		garage_id: 160,
		part_id: 82,
		quantity: 321,
	},
	{
		garage_id: 160,
		part_id: 83,
		quantity: 74,
	},
	{
		garage_id: 160,
		part_id: 84,
		quantity: 450,
	},
	{
		garage_id: 160,
		part_id: 85,
		quantity: 460,
	},
	{
		garage_id: 160,
		part_id: 86,
		quantity: 231,
	},
	{
		garage_id: 160,
		part_id: 87,
		quantity: 71,
	},
	{
		garage_id: 160,
		part_id: 88,
		quantity: 66,
	},
	{
		garage_id: 160,
		part_id: 89,
		quantity: 177,
	},
	{
		garage_id: 160,
		part_id: 90,
		quantity: 206,
	},
	{
		garage_id: 160,
		part_id: 91,
		quantity: 355,
	},
	{
		garage_id: 160,
		part_id: 92,
		quantity: 318,
	},
	{
		garage_id: 160,
		part_id: 93,
		quantity: 366,
	},
	{
		garage_id: 160,
		part_id: 94,
		quantity: 402,
	},
	{
		garage_id: 160,
		part_id: 95,
		quantity: 298,
	},
	{
		garage_id: 160,
		part_id: 96,
		quantity: 336,
	},
	{
		garage_id: 160,
		part_id: 97,
		quantity: 368,
	},
	{
		garage_id: 160,
		part_id: 98,
		quantity: 69,
	},
	{
		garage_id: 160,
		part_id: 99,
		quantity: 96,
	},
	{
		garage_id: 160,
		part_id: 100,
		quantity: 413,
	},
	{
		garage_id: 160,
		part_id: 101,
		quantity: 495,
	},
	{
		garage_id: 160,
		part_id: 102,
		quantity: 391,
	},
	{
		garage_id: 160,
		part_id: 103,
		quantity: 259,
	},
	{
		garage_id: 160,
		part_id: 104,
		quantity: 461,
	},
	{
		garage_id: 160,
		part_id: 105,
		quantity: 316,
	},
	{
		garage_id: 160,
		part_id: 106,
		quantity: 470,
	},
	{
		garage_id: 160,
		part_id: 107,
		quantity: 151,
	},
	{
		garage_id: 160,
		part_id: 108,
		quantity: 67,
	},
	{
		garage_id: 160,
		part_id: 109,
		quantity: 404,
	},
	{
		garage_id: 160,
		part_id: 110,
		quantity: 442,
	},
	{
		garage_id: 160,
		part_id: 111,
		quantity: 456,
	},
	{
		garage_id: 160,
		part_id: 112,
		quantity: 266,
	},
	{
		garage_id: 160,
		part_id: 113,
		quantity: 496,
	},
	{
		garage_id: 160,
		part_id: 114,
		quantity: 236,
	},
	{
		garage_id: 160,
		part_id: 115,
		quantity: 248,
	},
	{
		garage_id: 160,
		part_id: 116,
		quantity: 259,
	},
	{
		garage_id: 160,
		part_id: 117,
		quantity: 337,
	},
	{
		garage_id: 160,
		part_id: 118,
		quantity: 361,
	},
	{
		garage_id: 160,
		part_id: 119,
		quantity: 172,
	},
	{
		garage_id: 160,
		part_id: 120,
		quantity: 292,
	},
	{
		garage_id: 160,
		part_id: 121,
		quantity: 497,
	},
	{
		garage_id: 160,
		part_id: 122,
		quantity: 31,
	},
	{
		garage_id: 160,
		part_id: 123,
		quantity: 6,
	},
	{
		garage_id: 160,
		part_id: 124,
		quantity: 35,
	},
	{
		garage_id: 160,
		part_id: 125,
		quantity: 74,
	},
	{
		garage_id: 160,
		part_id: 126,
		quantity: 169,
	},
	{
		garage_id: 160,
		part_id: 127,
		quantity: 66,
	},
	{
		garage_id: 160,
		part_id: 128,
		quantity: 354,
	},
	{
		garage_id: 160,
		part_id: 129,
		quantity: 197,
	},
	{
		garage_id: 160,
		part_id: 130,
		quantity: 30,
	},
	{
		garage_id: 160,
		part_id: 131,
		quantity: 239,
	},
	{
		garage_id: 160,
		part_id: 132,
		quantity: 307,
	},
	{
		garage_id: 160,
		part_id: 133,
		quantity: 89,
	},
	{
		garage_id: 160,
		part_id: 134,
		quantity: 192,
	},
	{
		garage_id: 160,
		part_id: 135,
		quantity: 91,
	},
	{
		garage_id: 160,
		part_id: 136,
		quantity: 0,
	},
	{
		garage_id: 160,
		part_id: 137,
		quantity: 201,
	},
	{
		garage_id: 160,
		part_id: 138,
		quantity: 476,
	},
	{
		garage_id: 160,
		part_id: 139,
		quantity: 481,
	},
	{
		garage_id: 160,
		part_id: 140,
		quantity: 374,
	},
	{
		garage_id: 160,
		part_id: 141,
		quantity: 467,
	},
	{
		garage_id: 160,
		part_id: 142,
		quantity: 251,
	},
	{
		garage_id: 160,
		part_id: 143,
		quantity: 498,
	},
	{
		garage_id: 160,
		part_id: 144,
		quantity: 454,
	},
	{
		garage_id: 160,
		part_id: 145,
		quantity: 239,
	},
	{
		garage_id: 160,
		part_id: 146,
		quantity: 56,
	},
	{
		garage_id: 160,
		part_id: 147,
		quantity: 19,
	},
	{
		garage_id: 160,
		part_id: 148,
		quantity: 65,
	},
	{
		garage_id: 160,
		part_id: 149,
		quantity: 373,
	},
	{
		garage_id: 160,
		part_id: 150,
		quantity: 28,
	},
	{
		garage_id: 160,
		part_id: 151,
		quantity: 332,
	},
	{
		garage_id: 160,
		part_id: 152,
		quantity: 60,
	},
	{
		garage_id: 160,
		part_id: 153,
		quantity: 49,
	},
	{
		garage_id: 160,
		part_id: 154,
		quantity: 370,
	},
	{
		garage_id: 160,
		part_id: 155,
		quantity: 310,
	},
	{
		garage_id: 160,
		part_id: 156,
		quantity: 102,
	},
	{
		garage_id: 160,
		part_id: 157,
		quantity: 156,
	},
	{
		garage_id: 160,
		part_id: 158,
		quantity: 415,
	},
	{
		garage_id: 160,
		part_id: 159,
		quantity: 327,
	},
	{
		garage_id: 160,
		part_id: 160,
		quantity: 297,
	},
	{
		garage_id: 160,
		part_id: 161,
		quantity: 479,
	},
	{
		garage_id: 160,
		part_id: 162,
		quantity: 32,
	},
	{
		garage_id: 160,
		part_id: 163,
		quantity: 206,
	},
	{
		garage_id: 160,
		part_id: 164,
		quantity: 360,
	},
	{
		garage_id: 160,
		part_id: 165,
		quantity: 351,
	},
	{
		garage_id: 160,
		part_id: 166,
		quantity: 10,
	},
	{
		garage_id: 160,
		part_id: 167,
		quantity: 322,
	},
	{
		garage_id: 160,
		part_id: 168,
		quantity: 212,
	},
	{
		garage_id: 160,
		part_id: 169,
		quantity: 389,
	},
	{
		garage_id: 160,
		part_id: 170,
		quantity: 359,
	},
	{
		garage_id: 160,
		part_id: 171,
		quantity: 383,
	},
	{
		garage_id: 160,
		part_id: 172,
		quantity: 299,
	},
	{
		garage_id: 160,
		part_id: 173,
		quantity: 475,
	},
	{
		garage_id: 160,
		part_id: 174,
		quantity: 65,
	},
	{
		garage_id: 160,
		part_id: 175,
		quantity: 472,
	},
	{
		garage_id: 160,
		part_id: 176,
		quantity: 296,
	},
	{
		garage_id: 160,
		part_id: 177,
		quantity: 258,
	},
	{
		garage_id: 160,
		part_id: 178,
		quantity: 170,
	},
	{
		garage_id: 160,
		part_id: 179,
		quantity: 40,
	},
	{
		garage_id: 160,
		part_id: 180,
		quantity: 253,
	},
	{
		garage_id: 160,
		part_id: 181,
		quantity: 179,
	},
	{
		garage_id: 160,
		part_id: 182,
		quantity: 71,
	},
	{
		garage_id: 160,
		part_id: 183,
		quantity: 212,
	},
	{
		garage_id: 160,
		part_id: 184,
		quantity: 479,
	},
	{
		garage_id: 160,
		part_id: 185,
		quantity: 431,
	},
	{
		garage_id: 160,
		part_id: 186,
		quantity: 497,
	},
	{
		garage_id: 160,
		part_id: 187,
		quantity: 100,
	},
	{
		garage_id: 160,
		part_id: 188,
		quantity: 284,
	},
	{
		garage_id: 160,
		part_id: 189,
		quantity: 463,
	},
	{
		garage_id: 160,
		part_id: 190,
		quantity: 250,
	},
	{
		garage_id: 160,
		part_id: 191,
		quantity: 199,
	},
	{
		garage_id: 160,
		part_id: 192,
		quantity: 488,
	},
	{
		garage_id: 160,
		part_id: 193,
		quantity: 415,
	},
	{
		garage_id: 160,
		part_id: 194,
		quantity: 17,
	},
	{
		garage_id: 160,
		part_id: 195,
		quantity: 482,
	},
	{
		garage_id: 160,
		part_id: 196,
		quantity: 361,
	},
	{
		garage_id: 160,
		part_id: 197,
		quantity: 228,
	},
	{
		garage_id: 160,
		part_id: 198,
		quantity: 105,
	},
	{
		garage_id: 160,
		part_id: 199,
		quantity: 433,
	},
	{
		garage_id: 160,
		part_id: 200,
		quantity: 230,
	},
	{
		garage_id: 160,
		part_id: 201,
		quantity: 12,
	},
	{
		garage_id: 160,
		part_id: 202,
		quantity: 381,
	},
	{
		garage_id: 160,
		part_id: 203,
		quantity: 211,
	},
	{
		garage_id: 160,
		part_id: 204,
		quantity: 271,
	},
	{
		garage_id: 160,
		part_id: 205,
		quantity: 463,
	},
	{
		garage_id: 160,
		part_id: 206,
		quantity: 168,
	},
	{
		garage_id: 160,
		part_id: 207,
		quantity: 67,
	},
	{
		garage_id: 160,
		part_id: 208,
		quantity: 264,
	},
	{
		garage_id: 160,
		part_id: 209,
		quantity: 441,
	},
	{
		garage_id: 160,
		part_id: 210,
		quantity: 218,
	},
	{
		garage_id: 160,
		part_id: 211,
		quantity: 172,
	},
	{
		garage_id: 160,
		part_id: 212,
		quantity: 228,
	},
	{
		garage_id: 160,
		part_id: 213,
		quantity: 228,
	},
	{
		garage_id: 160,
		part_id: 214,
		quantity: 258,
	},
	{
		garage_id: 160,
		part_id: 215,
		quantity: 32,
	},
	{
		garage_id: 160,
		part_id: 216,
		quantity: 113,
	},
	{
		garage_id: 160,
		part_id: 217,
		quantity: 275,
	},
	{
		garage_id: 160,
		part_id: 218,
		quantity: 220,
	},
	{
		garage_id: 160,
		part_id: 219,
		quantity: 377,
	},
	{
		garage_id: 160,
		part_id: 220,
		quantity: 479,
	},
	{
		garage_id: 160,
		part_id: 221,
		quantity: 33,
	},
	{
		garage_id: 160,
		part_id: 222,
		quantity: 67,
	},
	{
		garage_id: 160,
		part_id: 223,
		quantity: 308,
	},
	{
		garage_id: 160,
		part_id: 224,
		quantity: 300,
	},
	{
		garage_id: 160,
		part_id: 225,
		quantity: 310,
	},
	{
		garage_id: 160,
		part_id: 226,
		quantity: 34,
	},
	{
		garage_id: 160,
		part_id: 227,
		quantity: 371,
	},
	{
		garage_id: 160,
		part_id: 228,
		quantity: 497,
	},
	{
		garage_id: 160,
		part_id: 229,
		quantity: 19,
	},
	{
		garage_id: 160,
		part_id: 230,
		quantity: 284,
	},
	{
		garage_id: 160,
		part_id: 231,
		quantity: 357,
	},
	{
		garage_id: 160,
		part_id: 232,
		quantity: 260,
	},
	{
		garage_id: 160,
		part_id: 233,
		quantity: 402,
	},
	{
		garage_id: 160,
		part_id: 234,
		quantity: 9,
	},
	{
		garage_id: 160,
		part_id: 235,
		quantity: 370,
	},
	{
		garage_id: 160,
		part_id: 236,
		quantity: 205,
	},
	{
		garage_id: 160,
		part_id: 237,
		quantity: 469,
	},
	{
		garage_id: 160,
		part_id: 238,
		quantity: 382,
	},
	{
		garage_id: 160,
		part_id: 239,
		quantity: 383,
	},
	{
		garage_id: 160,
		part_id: 240,
		quantity: 474,
	},
	{
		garage_id: 160,
		part_id: 241,
		quantity: 303,
	},
	{
		garage_id: 160,
		part_id: 242,
		quantity: 267,
	},
	{
		garage_id: 160,
		part_id: 243,
		quantity: 427,
	},
	{
		garage_id: 160,
		part_id: 244,
		quantity: 195,
	},
	{
		garage_id: 160,
		part_id: 245,
		quantity: 442,
	},
	{
		garage_id: 160,
		part_id: 246,
		quantity: 466,
	},
	{
		garage_id: 160,
		part_id: 247,
		quantity: 136,
	},
	{
		garage_id: 160,
		part_id: 248,
		quantity: 9,
	},
	{
		garage_id: 160,
		part_id: 249,
		quantity: 79,
	},
	{
		garage_id: 160,
		part_id: 250,
		quantity: 477,
	},
	{
		garage_id: 160,
		part_id: 251,
		quantity: 67,
	},
	{
		garage_id: 160,
		part_id: 252,
		quantity: 351,
	},
	{
		garage_id: 160,
		part_id: 253,
		quantity: 111,
	},
	{
		garage_id: 160,
		part_id: 254,
		quantity: 160,
	},
	{
		garage_id: 160,
		part_id: 255,
		quantity: 47,
	},
	{
		garage_id: 160,
		part_id: 256,
		quantity: 113,
	},
	{
		garage_id: 160,
		part_id: 257,
		quantity: 149,
	},
	{
		garage_id: 160,
		part_id: 258,
		quantity: 19,
	},
	{
		garage_id: 160,
		part_id: 259,
		quantity: 44,
	},
	{
		garage_id: 160,
		part_id: 260,
		quantity: 17,
	},
	{
		garage_id: 160,
		part_id: 261,
		quantity: 255,
	},
	{
		garage_id: 160,
		part_id: 262,
		quantity: 421,
	},
	{
		garage_id: 160,
		part_id: 263,
		quantity: 466,
	},
	{
		garage_id: 160,
		part_id: 264,
		quantity: 106,
	},
	{
		garage_id: 160,
		part_id: 265,
		quantity: 11,
	},
	{
		garage_id: 160,
		part_id: 266,
		quantity: 43,
	},
	{
		garage_id: 160,
		part_id: 267,
		quantity: 299,
	},
	{
		garage_id: 160,
		part_id: 268,
		quantity: 300,
	},
	{
		garage_id: 160,
		part_id: 269,
		quantity: 281,
	},
	{
		garage_id: 160,
		part_id: 270,
		quantity: 302,
	},
	{
		garage_id: 160,
		part_id: 271,
		quantity: 403,
	},
	{
		garage_id: 160,
		part_id: 272,
		quantity: 217,
	},
	{
		garage_id: 160,
		part_id: 273,
		quantity: 41,
	},
	{
		garage_id: 160,
		part_id: 274,
		quantity: 375,
	},
	{
		garage_id: 160,
		part_id: 275,
		quantity: 409,
	},
	{
		garage_id: 160,
		part_id: 276,
		quantity: 343,
	},
	{
		garage_id: 160,
		part_id: 277,
		quantity: 34,
	},
	{
		garage_id: 160,
		part_id: 278,
		quantity: 78,
	},
	{
		garage_id: 160,
		part_id: 279,
		quantity: 488,
	},
	{
		garage_id: 160,
		part_id: 280,
		quantity: 320,
	},
	{
		garage_id: 160,
		part_id: 281,
		quantity: 185,
	},
	{
		garage_id: 160,
		part_id: 282,
		quantity: 202,
	},
	{
		garage_id: 160,
		part_id: 283,
		quantity: 336,
	},
	{
		garage_id: 160,
		part_id: 284,
		quantity: 163,
	},
	{
		garage_id: 160,
		part_id: 285,
		quantity: 263,
	},
	{
		garage_id: 160,
		part_id: 286,
		quantity: 213,
	},
	{
		garage_id: 160,
		part_id: 287,
		quantity: 480,
	},
	{
		garage_id: 160,
		part_id: 288,
		quantity: 180,
	},
	{
		garage_id: 160,
		part_id: 289,
		quantity: 152,
	},
	{
		garage_id: 160,
		part_id: 290,
		quantity: 255,
	},
	{
		garage_id: 160,
		part_id: 291,
		quantity: 52,
	},
	{
		garage_id: 160,
		part_id: 292,
		quantity: 387,
	},
	{
		garage_id: 160,
		part_id: 293,
		quantity: 36,
	},
	{
		garage_id: 160,
		part_id: 294,
		quantity: 246,
	},
	{
		garage_id: 160,
		part_id: 295,
		quantity: 112,
	},
	{
		garage_id: 160,
		part_id: 296,
		quantity: 315,
	},
	{
		garage_id: 160,
		part_id: 297,
		quantity: 430,
	},
	{
		garage_id: 160,
		part_id: 298,
		quantity: 471,
	},
	{
		garage_id: 160,
		part_id: 299,
		quantity: 412,
	},
	{
		garage_id: 160,
		part_id: 300,
		quantity: 333,
	},
	{
		garage_id: 160,
		part_id: 301,
		quantity: 167,
	},
	{
		garage_id: 160,
		part_id: 302,
		quantity: 91,
	},
	{
		garage_id: 160,
		part_id: 303,
		quantity: 213,
	},
	{
		garage_id: 160,
		part_id: 304,
		quantity: 203,
	},
	{
		garage_id: 160,
		part_id: 305,
		quantity: 258,
	},
	{
		garage_id: 160,
		part_id: 306,
		quantity: 1,
	},
	{
		garage_id: 160,
		part_id: 307,
		quantity: 176,
	},
	{
		garage_id: 160,
		part_id: 308,
		quantity: 19,
	},
	{
		garage_id: 160,
		part_id: 309,
		quantity: 197,
	},
	{
		garage_id: 160,
		part_id: 310,
		quantity: 371,
	},
	{
		garage_id: 160,
		part_id: 311,
		quantity: 432,
	},
	{
		garage_id: 160,
		part_id: 312,
		quantity: 175,
	},
	{
		garage_id: 160,
		part_id: 313,
		quantity: 353,
	},
	{
		garage_id: 160,
		part_id: 314,
		quantity: 375,
	},
	{
		garage_id: 160,
		part_id: 315,
		quantity: 231,
	},
	{
		garage_id: 160,
		part_id: 316,
		quantity: 27,
	},
	{
		garage_id: 160,
		part_id: 317,
		quantity: 241,
	},
	{
		garage_id: 160,
		part_id: 318,
		quantity: 41,
	},
	{
		garage_id: 160,
		part_id: 319,
		quantity: 74,
	},
	{
		garage_id: 160,
		part_id: 320,
		quantity: 220,
	},
	{
		garage_id: 160,
		part_id: 321,
		quantity: 498,
	},
	{
		garage_id: 160,
		part_id: 322,
		quantity: 59,
	},
	{
		garage_id: 160,
		part_id: 323,
		quantity: 84,
	},
	{
		garage_id: 160,
		part_id: 324,
		quantity: 87,
	},
	{
		garage_id: 160,
		part_id: 325,
		quantity: 413,
	},
	{
		garage_id: 160,
		part_id: 326,
		quantity: 274,
	},
	{
		garage_id: 160,
		part_id: 327,
		quantity: 401,
	},
	{
		garage_id: 160,
		part_id: 328,
		quantity: 36,
	},
	{
		garage_id: 160,
		part_id: 329,
		quantity: 184,
	},
	{
		garage_id: 160,
		part_id: 330,
		quantity: 362,
	},
	{
		garage_id: 160,
		part_id: 331,
		quantity: 424,
	},
	{
		garage_id: 160,
		part_id: 332,
		quantity: 498,
	},
	{
		garage_id: 160,
		part_id: 333,
		quantity: 341,
	},
	{
		garage_id: 160,
		part_id: 334,
		quantity: 14,
	},
	{
		garage_id: 160,
		part_id: 335,
		quantity: 161,
	},
	{
		garage_id: 160,
		part_id: 336,
		quantity: 183,
	},
	{
		garage_id: 160,
		part_id: 337,
		quantity: 470,
	},
	{
		garage_id: 160,
		part_id: 338,
		quantity: 401,
	},
	{
		garage_id: 160,
		part_id: 339,
		quantity: 245,
	},
	{
		garage_id: 160,
		part_id: 340,
		quantity: 450,
	},
	{
		garage_id: 160,
		part_id: 341,
		quantity: 244,
	},
	{
		garage_id: 160,
		part_id: 342,
		quantity: 315,
	},
	{
		garage_id: 160,
		part_id: 343,
		quantity: 169,
	},
	{
		garage_id: 160,
		part_id: 344,
		quantity: 4,
	},
	{
		garage_id: 160,
		part_id: 345,
		quantity: 433,
	},
	{
		garage_id: 160,
		part_id: 346,
		quantity: 363,
	},
	{
		garage_id: 160,
		part_id: 347,
		quantity: 61,
	},
	{
		garage_id: 160,
		part_id: 348,
		quantity: 374,
	},
	{
		garage_id: 160,
		part_id: 349,
		quantity: 227,
	},
	{
		garage_id: 160,
		part_id: 350,
		quantity: 95,
	},
	{
		garage_id: 160,
		part_id: 351,
		quantity: 127,
	},
	{
		garage_id: 160,
		part_id: 352,
		quantity: 301,
	},
	{
		garage_id: 160,
		part_id: 353,
		quantity: 20,
	},
	{
		garage_id: 160,
		part_id: 354,
		quantity: 248,
	},
	{
		garage_id: 160,
		part_id: 355,
		quantity: 0,
	},
	{
		garage_id: 160,
		part_id: 356,
		quantity: 66,
	},
	{
		garage_id: 160,
		part_id: 357,
		quantity: 230,
	},
	{
		garage_id: 160,
		part_id: 358,
		quantity: 362,
	},
	{
		garage_id: 160,
		part_id: 359,
		quantity: 346,
	},
	{
		garage_id: 160,
		part_id: 360,
		quantity: 151,
	},
	{
		garage_id: 160,
		part_id: 361,
		quantity: 231,
	},
	{
		garage_id: 160,
		part_id: 362,
		quantity: 128,
	},
	{
		garage_id: 160,
		part_id: 363,
		quantity: 471,
	},
	{
		garage_id: 160,
		part_id: 364,
		quantity: 141,
	},
	{
		garage_id: 160,
		part_id: 365,
		quantity: 368,
	},
	{
		garage_id: 160,
		part_id: 366,
		quantity: 61,
	},
	{
		garage_id: 160,
		part_id: 367,
		quantity: 335,
	},
	{
		garage_id: 160,
		part_id: 368,
		quantity: 285,
	},
	{
		garage_id: 160,
		part_id: 369,
		quantity: 45,
	},
	{
		garage_id: 160,
		part_id: 370,
		quantity: 210,
	},
	{
		garage_id: 160,
		part_id: 371,
		quantity: 129,
	},
	{
		garage_id: 160,
		part_id: 372,
		quantity: 73,
	},
	{
		garage_id: 160,
		part_id: 373,
		quantity: 365,
	},
	{
		garage_id: 160,
		part_id: 374,
		quantity: 149,
	},
	{
		garage_id: 160,
		part_id: 375,
		quantity: 485,
	},
	{
		garage_id: 160,
		part_id: 376,
		quantity: 60,
	},
	{
		garage_id: 160,
		part_id: 377,
		quantity: 209,
	},
	{
		garage_id: 160,
		part_id: 378,
		quantity: 473,
	},
	{
		garage_id: 160,
		part_id: 379,
		quantity: 93,
	},
	{
		garage_id: 160,
		part_id: 380,
		quantity: 438,
	},
	{
		garage_id: 160,
		part_id: 381,
		quantity: 58,
	},
	{
		garage_id: 160,
		part_id: 382,
		quantity: 482,
	},
	{
		garage_id: 160,
		part_id: 383,
		quantity: 318,
	},
	{
		garage_id: 160,
		part_id: 384,
		quantity: 445,
	},
	{
		garage_id: 160,
		part_id: 385,
		quantity: 72,
	},
	{
		garage_id: 160,
		part_id: 386,
		quantity: 265,
	},
	{
		garage_id: 160,
		part_id: 387,
		quantity: 187,
	},
	{
		garage_id: 160,
		part_id: 388,
		quantity: 446,
	},
	{
		garage_id: 160,
		part_id: 389,
		quantity: 288,
	},
	{
		garage_id: 160,
		part_id: 390,
		quantity: 366,
	},
	{
		garage_id: 160,
		part_id: 391,
		quantity: 161,
	},
	{
		garage_id: 160,
		part_id: 392,
		quantity: 349,
	},
	{
		garage_id: 160,
		part_id: 393,
		quantity: 405,
	},
	{
		garage_id: 160,
		part_id: 394,
		quantity: 61,
	},
	{
		garage_id: 160,
		part_id: 395,
		quantity: 128,
	},
	{
		garage_id: 160,
		part_id: 396,
		quantity: 202,
	},
	{
		garage_id: 160,
		part_id: 397,
		quantity: 316,
	},
	{
		garage_id: 160,
		part_id: 398,
		quantity: 285,
	},
	{
		garage_id: 160,
		part_id: 399,
		quantity: 2,
	},
	{
		garage_id: 161,
		part_id: 0,
		quantity: 123,
	},
	{
		garage_id: 161,
		part_id: 1,
		quantity: 412,
	},
	{
		garage_id: 161,
		part_id: 2,
		quantity: 17,
	},
	{
		garage_id: 161,
		part_id: 3,
		quantity: 97,
	},
	{
		garage_id: 161,
		part_id: 4,
		quantity: 75,
	},
	{
		garage_id: 161,
		part_id: 5,
		quantity: 52,
	},
	{
		garage_id: 161,
		part_id: 6,
		quantity: 57,
	},
	{
		garage_id: 161,
		part_id: 7,
		quantity: 98,
	},
	{
		garage_id: 161,
		part_id: 8,
		quantity: 274,
	},
	{
		garage_id: 161,
		part_id: 9,
		quantity: 364,
	},
	{
		garage_id: 161,
		part_id: 10,
		quantity: 116,
	},
	{
		garage_id: 161,
		part_id: 11,
		quantity: 27,
	},
	{
		garage_id: 161,
		part_id: 12,
		quantity: 108,
	},
	{
		garage_id: 161,
		part_id: 13,
		quantity: 389,
	},
	{
		garage_id: 161,
		part_id: 14,
		quantity: 86,
	},
	{
		garage_id: 161,
		part_id: 15,
		quantity: 73,
	},
	{
		garage_id: 161,
		part_id: 16,
		quantity: 388,
	},
	{
		garage_id: 161,
		part_id: 17,
		quantity: 144,
	},
	{
		garage_id: 161,
		part_id: 18,
		quantity: 220,
	},
	{
		garage_id: 161,
		part_id: 19,
		quantity: 366,
	},
	{
		garage_id: 161,
		part_id: 20,
		quantity: 250,
	},
	{
		garage_id: 161,
		part_id: 21,
		quantity: 351,
	},
	{
		garage_id: 161,
		part_id: 22,
		quantity: 239,
	},
	{
		garage_id: 161,
		part_id: 23,
		quantity: 438,
	},
	{
		garage_id: 161,
		part_id: 24,
		quantity: 377,
	},
	{
		garage_id: 161,
		part_id: 25,
		quantity: 271,
	},
	{
		garage_id: 161,
		part_id: 26,
		quantity: 259,
	},
	{
		garage_id: 161,
		part_id: 27,
		quantity: 61,
	},
	{
		garage_id: 161,
		part_id: 28,
		quantity: 185,
	},
	{
		garage_id: 161,
		part_id: 29,
		quantity: 63,
	},
	{
		garage_id: 161,
		part_id: 30,
		quantity: 302,
	},
	{
		garage_id: 161,
		part_id: 31,
		quantity: 464,
	},
	{
		garage_id: 161,
		part_id: 32,
		quantity: 417,
	},
	{
		garage_id: 161,
		part_id: 33,
		quantity: 47,
	},
	{
		garage_id: 161,
		part_id: 34,
		quantity: 491,
	},
	{
		garage_id: 161,
		part_id: 35,
		quantity: 50,
	},
	{
		garage_id: 161,
		part_id: 36,
		quantity: 227,
	},
	{
		garage_id: 161,
		part_id: 37,
		quantity: 285,
	},
	{
		garage_id: 161,
		part_id: 38,
		quantity: 10,
	},
	{
		garage_id: 161,
		part_id: 39,
		quantity: 208,
	},
	{
		garage_id: 161,
		part_id: 40,
		quantity: 477,
	},
	{
		garage_id: 161,
		part_id: 41,
		quantity: 290,
	},
	{
		garage_id: 161,
		part_id: 42,
		quantity: 137,
	},
	{
		garage_id: 161,
		part_id: 43,
		quantity: 167,
	},
	{
		garage_id: 161,
		part_id: 44,
		quantity: 177,
	},
	{
		garage_id: 161,
		part_id: 45,
		quantity: 309,
	},
	{
		garage_id: 161,
		part_id: 46,
		quantity: 210,
	},
	{
		garage_id: 161,
		part_id: 47,
		quantity: 480,
	},
	{
		garage_id: 161,
		part_id: 48,
		quantity: 259,
	},
	{
		garage_id: 161,
		part_id: 49,
		quantity: 287,
	},
	{
		garage_id: 161,
		part_id: 50,
		quantity: 38,
	},
	{
		garage_id: 161,
		part_id: 51,
		quantity: 69,
	},
	{
		garage_id: 161,
		part_id: 52,
		quantity: 320,
	},
	{
		garage_id: 161,
		part_id: 53,
		quantity: 159,
	},
	{
		garage_id: 161,
		part_id: 54,
		quantity: 458,
	},
	{
		garage_id: 161,
		part_id: 55,
		quantity: 256,
	},
	{
		garage_id: 161,
		part_id: 56,
		quantity: 118,
	},
	{
		garage_id: 161,
		part_id: 57,
		quantity: 356,
	},
	{
		garage_id: 161,
		part_id: 58,
		quantity: 137,
	},
	{
		garage_id: 161,
		part_id: 59,
		quantity: 476,
	},
	{
		garage_id: 161,
		part_id: 60,
		quantity: 223,
	},
	{
		garage_id: 161,
		part_id: 61,
		quantity: 474,
	},
	{
		garage_id: 161,
		part_id: 62,
		quantity: 52,
	},
	{
		garage_id: 161,
		part_id: 63,
		quantity: 404,
	},
	{
		garage_id: 161,
		part_id: 64,
		quantity: 14,
	},
	{
		garage_id: 161,
		part_id: 65,
		quantity: 337,
	},
	{
		garage_id: 161,
		part_id: 66,
		quantity: 142,
	},
	{
		garage_id: 161,
		part_id: 67,
		quantity: 116,
	},
	{
		garage_id: 161,
		part_id: 68,
		quantity: 134,
	},
	{
		garage_id: 161,
		part_id: 69,
		quantity: 477,
	},
	{
		garage_id: 161,
		part_id: 70,
		quantity: 131,
	},
	{
		garage_id: 161,
		part_id: 71,
		quantity: 50,
	},
	{
		garage_id: 161,
		part_id: 72,
		quantity: 356,
	},
	{
		garage_id: 161,
		part_id: 73,
		quantity: 318,
	},
	{
		garage_id: 161,
		part_id: 74,
		quantity: 427,
	},
	{
		garage_id: 161,
		part_id: 75,
		quantity: 414,
	},
	{
		garage_id: 161,
		part_id: 76,
		quantity: 326,
	},
	{
		garage_id: 161,
		part_id: 77,
		quantity: 364,
	},
	{
		garage_id: 161,
		part_id: 78,
		quantity: 196,
	},
	{
		garage_id: 161,
		part_id: 79,
		quantity: 247,
	},
	{
		garage_id: 161,
		part_id: 80,
		quantity: 353,
	},
	{
		garage_id: 161,
		part_id: 81,
		quantity: 286,
	},
	{
		garage_id: 161,
		part_id: 82,
		quantity: 125,
	},
	{
		garage_id: 161,
		part_id: 83,
		quantity: 117,
	},
	{
		garage_id: 161,
		part_id: 84,
		quantity: 64,
	},
	{
		garage_id: 161,
		part_id: 85,
		quantity: 223,
	},
	{
		garage_id: 161,
		part_id: 86,
		quantity: 330,
	},
	{
		garage_id: 161,
		part_id: 87,
		quantity: 458,
	},
	{
		garage_id: 161,
		part_id: 88,
		quantity: 43,
	},
	{
		garage_id: 161,
		part_id: 89,
		quantity: 256,
	},
	{
		garage_id: 161,
		part_id: 90,
		quantity: 333,
	},
	{
		garage_id: 161,
		part_id: 91,
		quantity: 197,
	},
	{
		garage_id: 161,
		part_id: 92,
		quantity: 492,
	},
	{
		garage_id: 161,
		part_id: 93,
		quantity: 334,
	},
	{
		garage_id: 161,
		part_id: 94,
		quantity: 410,
	},
	{
		garage_id: 161,
		part_id: 95,
		quantity: 410,
	},
	{
		garage_id: 161,
		part_id: 96,
		quantity: 455,
	},
	{
		garage_id: 161,
		part_id: 97,
		quantity: 394,
	},
	{
		garage_id: 161,
		part_id: 98,
		quantity: 392,
	},
	{
		garage_id: 161,
		part_id: 99,
		quantity: 303,
	},
	{
		garage_id: 161,
		part_id: 100,
		quantity: 368,
	},
	{
		garage_id: 161,
		part_id: 101,
		quantity: 385,
	},
	{
		garage_id: 161,
		part_id: 102,
		quantity: 282,
	},
	{
		garage_id: 161,
		part_id: 103,
		quantity: 223,
	},
	{
		garage_id: 161,
		part_id: 104,
		quantity: 172,
	},
	{
		garage_id: 161,
		part_id: 105,
		quantity: 446,
	},
	{
		garage_id: 161,
		part_id: 106,
		quantity: 79,
	},
	{
		garage_id: 161,
		part_id: 107,
		quantity: 428,
	},
	{
		garage_id: 161,
		part_id: 108,
		quantity: 182,
	},
	{
		garage_id: 161,
		part_id: 109,
		quantity: 492,
	},
	{
		garage_id: 161,
		part_id: 110,
		quantity: 59,
	},
	{
		garage_id: 161,
		part_id: 111,
		quantity: 9,
	},
	{
		garage_id: 161,
		part_id: 112,
		quantity: 438,
	},
	{
		garage_id: 161,
		part_id: 113,
		quantity: 133,
	},
	{
		garage_id: 161,
		part_id: 114,
		quantity: 20,
	},
	{
		garage_id: 161,
		part_id: 115,
		quantity: 435,
	},
	{
		garage_id: 161,
		part_id: 116,
		quantity: 119,
	},
	{
		garage_id: 161,
		part_id: 117,
		quantity: 438,
	},
	{
		garage_id: 161,
		part_id: 118,
		quantity: 329,
	},
	{
		garage_id: 161,
		part_id: 119,
		quantity: 8,
	},
	{
		garage_id: 161,
		part_id: 120,
		quantity: 216,
	},
	{
		garage_id: 161,
		part_id: 121,
		quantity: 453,
	},
	{
		garage_id: 161,
		part_id: 122,
		quantity: 469,
	},
	{
		garage_id: 161,
		part_id: 123,
		quantity: 480,
	},
	{
		garage_id: 161,
		part_id: 124,
		quantity: 268,
	},
	{
		garage_id: 161,
		part_id: 125,
		quantity: 88,
	},
	{
		garage_id: 161,
		part_id: 126,
		quantity: 259,
	},
	{
		garage_id: 161,
		part_id: 127,
		quantity: 41,
	},
	{
		garage_id: 161,
		part_id: 128,
		quantity: 384,
	},
	{
		garage_id: 161,
		part_id: 129,
		quantity: 81,
	},
	{
		garage_id: 161,
		part_id: 130,
		quantity: 217,
	},
	{
		garage_id: 161,
		part_id: 131,
		quantity: 267,
	},
	{
		garage_id: 161,
		part_id: 132,
		quantity: 70,
	},
	{
		garage_id: 161,
		part_id: 133,
		quantity: 279,
	},
	{
		garage_id: 161,
		part_id: 134,
		quantity: 291,
	},
	{
		garage_id: 161,
		part_id: 135,
		quantity: 153,
	},
	{
		garage_id: 161,
		part_id: 136,
		quantity: 144,
	},
	{
		garage_id: 161,
		part_id: 137,
		quantity: 326,
	},
	{
		garage_id: 161,
		part_id: 138,
		quantity: 57,
	},
	{
		garage_id: 161,
		part_id: 139,
		quantity: 237,
	},
	{
		garage_id: 161,
		part_id: 140,
		quantity: 40,
	},
	{
		garage_id: 161,
		part_id: 141,
		quantity: 284,
	},
	{
		garage_id: 161,
		part_id: 142,
		quantity: 486,
	},
	{
		garage_id: 161,
		part_id: 143,
		quantity: 174,
	},
	{
		garage_id: 161,
		part_id: 144,
		quantity: 426,
	},
	{
		garage_id: 161,
		part_id: 145,
		quantity: 129,
	},
	{
		garage_id: 161,
		part_id: 146,
		quantity: 203,
	},
	{
		garage_id: 161,
		part_id: 147,
		quantity: 143,
	},
	{
		garage_id: 161,
		part_id: 148,
		quantity: 389,
	},
	{
		garage_id: 161,
		part_id: 149,
		quantity: 113,
	},
	{
		garage_id: 161,
		part_id: 150,
		quantity: 308,
	},
	{
		garage_id: 161,
		part_id: 151,
		quantity: 131,
	},
	{
		garage_id: 161,
		part_id: 152,
		quantity: 127,
	},
	{
		garage_id: 161,
		part_id: 153,
		quantity: 285,
	},
	{
		garage_id: 161,
		part_id: 154,
		quantity: 177,
	},
	{
		garage_id: 161,
		part_id: 155,
		quantity: 361,
	},
	{
		garage_id: 161,
		part_id: 156,
		quantity: 87,
	},
	{
		garage_id: 161,
		part_id: 157,
		quantity: 79,
	},
	{
		garage_id: 161,
		part_id: 158,
		quantity: 379,
	},
	{
		garage_id: 161,
		part_id: 159,
		quantity: 4,
	},
	{
		garage_id: 161,
		part_id: 160,
		quantity: 453,
	},
	{
		garage_id: 161,
		part_id: 161,
		quantity: 240,
	},
	{
		garage_id: 161,
		part_id: 162,
		quantity: 371,
	},
	{
		garage_id: 161,
		part_id: 163,
		quantity: 329,
	},
	{
		garage_id: 161,
		part_id: 164,
		quantity: 404,
	},
	{
		garage_id: 161,
		part_id: 165,
		quantity: 104,
	},
	{
		garage_id: 161,
		part_id: 166,
		quantity: 448,
	},
	{
		garage_id: 161,
		part_id: 167,
		quantity: 57,
	},
	{
		garage_id: 161,
		part_id: 168,
		quantity: 41,
	},
	{
		garage_id: 161,
		part_id: 169,
		quantity: 317,
	},
	{
		garage_id: 161,
		part_id: 170,
		quantity: 193,
	},
	{
		garage_id: 161,
		part_id: 171,
		quantity: 488,
	},
	{
		garage_id: 161,
		part_id: 172,
		quantity: 159,
	},
	{
		garage_id: 161,
		part_id: 173,
		quantity: 281,
	},
	{
		garage_id: 161,
		part_id: 174,
		quantity: 470,
	},
	{
		garage_id: 161,
		part_id: 175,
		quantity: 472,
	},
	{
		garage_id: 161,
		part_id: 176,
		quantity: 450,
	},
	{
		garage_id: 161,
		part_id: 177,
		quantity: 418,
	},
	{
		garage_id: 161,
		part_id: 178,
		quantity: 282,
	},
	{
		garage_id: 161,
		part_id: 179,
		quantity: 27,
	},
	{
		garage_id: 161,
		part_id: 180,
		quantity: 51,
	},
	{
		garage_id: 161,
		part_id: 181,
		quantity: 217,
	},
	{
		garage_id: 161,
		part_id: 182,
		quantity: 177,
	},
	{
		garage_id: 161,
		part_id: 183,
		quantity: 292,
	},
	{
		garage_id: 161,
		part_id: 184,
		quantity: 443,
	},
	{
		garage_id: 161,
		part_id: 185,
		quantity: 258,
	},
	{
		garage_id: 161,
		part_id: 186,
		quantity: 132,
	},
	{
		garage_id: 161,
		part_id: 187,
		quantity: 119,
	},
	{
		garage_id: 161,
		part_id: 188,
		quantity: 244,
	},
	{
		garage_id: 161,
		part_id: 189,
		quantity: 433,
	},
	{
		garage_id: 161,
		part_id: 190,
		quantity: 239,
	},
	{
		garage_id: 161,
		part_id: 191,
		quantity: 6,
	},
	{
		garage_id: 161,
		part_id: 192,
		quantity: 416,
	},
	{
		garage_id: 161,
		part_id: 193,
		quantity: 220,
	},
	{
		garage_id: 161,
		part_id: 194,
		quantity: 91,
	},
	{
		garage_id: 161,
		part_id: 195,
		quantity: 394,
	},
	{
		garage_id: 161,
		part_id: 196,
		quantity: 71,
	},
	{
		garage_id: 161,
		part_id: 197,
		quantity: 489,
	},
	{
		garage_id: 161,
		part_id: 198,
		quantity: 477,
	},
	{
		garage_id: 161,
		part_id: 199,
		quantity: 118,
	},
	{
		garage_id: 161,
		part_id: 200,
		quantity: 18,
	},
	{
		garage_id: 161,
		part_id: 201,
		quantity: 341,
	},
	{
		garage_id: 161,
		part_id: 202,
		quantity: 106,
	},
	{
		garage_id: 161,
		part_id: 203,
		quantity: 486,
	},
	{
		garage_id: 161,
		part_id: 204,
		quantity: 55,
	},
	{
		garage_id: 161,
		part_id: 205,
		quantity: 101,
	},
	{
		garage_id: 161,
		part_id: 206,
		quantity: 361,
	},
	{
		garage_id: 161,
		part_id: 207,
		quantity: 122,
	},
	{
		garage_id: 161,
		part_id: 208,
		quantity: 106,
	},
	{
		garage_id: 161,
		part_id: 209,
		quantity: 24,
	},
	{
		garage_id: 161,
		part_id: 210,
		quantity: 274,
	},
	{
		garage_id: 161,
		part_id: 211,
		quantity: 287,
	},
	{
		garage_id: 161,
		part_id: 212,
		quantity: 48,
	},
	{
		garage_id: 161,
		part_id: 213,
		quantity: 382,
	},
	{
		garage_id: 161,
		part_id: 214,
		quantity: 335,
	},
	{
		garage_id: 161,
		part_id: 215,
		quantity: 426,
	},
	{
		garage_id: 161,
		part_id: 216,
		quantity: 40,
	},
	{
		garage_id: 161,
		part_id: 217,
		quantity: 36,
	},
	{
		garage_id: 161,
		part_id: 218,
		quantity: 336,
	},
	{
		garage_id: 161,
		part_id: 219,
		quantity: 426,
	},
	{
		garage_id: 161,
		part_id: 220,
		quantity: 474,
	},
	{
		garage_id: 161,
		part_id: 221,
		quantity: 65,
	},
	{
		garage_id: 161,
		part_id: 222,
		quantity: 258,
	},
	{
		garage_id: 161,
		part_id: 223,
		quantity: 418,
	},
	{
		garage_id: 161,
		part_id: 224,
		quantity: 287,
	},
	{
		garage_id: 161,
		part_id: 225,
		quantity: 248,
	},
	{
		garage_id: 161,
		part_id: 226,
		quantity: 126,
	},
	{
		garage_id: 161,
		part_id: 227,
		quantity: 154,
	},
	{
		garage_id: 161,
		part_id: 228,
		quantity: 497,
	},
	{
		garage_id: 161,
		part_id: 229,
		quantity: 375,
	},
	{
		garage_id: 161,
		part_id: 230,
		quantity: 237,
	},
	{
		garage_id: 161,
		part_id: 231,
		quantity: 32,
	},
	{
		garage_id: 161,
		part_id: 232,
		quantity: 17,
	},
	{
		garage_id: 161,
		part_id: 233,
		quantity: 353,
	},
	{
		garage_id: 161,
		part_id: 234,
		quantity: 450,
	},
	{
		garage_id: 161,
		part_id: 235,
		quantity: 211,
	},
	{
		garage_id: 161,
		part_id: 236,
		quantity: 151,
	},
	{
		garage_id: 161,
		part_id: 237,
		quantity: 483,
	},
	{
		garage_id: 161,
		part_id: 238,
		quantity: 496,
	},
	{
		garage_id: 161,
		part_id: 239,
		quantity: 187,
	},
	{
		garage_id: 161,
		part_id: 240,
		quantity: 296,
	},
	{
		garage_id: 161,
		part_id: 241,
		quantity: 102,
	},
	{
		garage_id: 161,
		part_id: 242,
		quantity: 133,
	},
	{
		garage_id: 161,
		part_id: 243,
		quantity: 411,
	},
	{
		garage_id: 161,
		part_id: 244,
		quantity: 383,
	},
	{
		garage_id: 161,
		part_id: 245,
		quantity: 1,
	},
	{
		garage_id: 161,
		part_id: 246,
		quantity: 26,
	},
	{
		garage_id: 161,
		part_id: 247,
		quantity: 26,
	},
	{
		garage_id: 161,
		part_id: 248,
		quantity: 495,
	},
	{
		garage_id: 161,
		part_id: 249,
		quantity: 291,
	},
	{
		garage_id: 161,
		part_id: 250,
		quantity: 112,
	},
	{
		garage_id: 161,
		part_id: 251,
		quantity: 218,
	},
	{
		garage_id: 161,
		part_id: 252,
		quantity: 421,
	},
	{
		garage_id: 161,
		part_id: 253,
		quantity: 29,
	},
	{
		garage_id: 161,
		part_id: 254,
		quantity: 451,
	},
	{
		garage_id: 161,
		part_id: 255,
		quantity: 296,
	},
	{
		garage_id: 161,
		part_id: 256,
		quantity: 409,
	},
	{
		garage_id: 161,
		part_id: 257,
		quantity: 205,
	},
	{
		garage_id: 161,
		part_id: 258,
		quantity: 172,
	},
	{
		garage_id: 161,
		part_id: 259,
		quantity: 483,
	},
	{
		garage_id: 161,
		part_id: 260,
		quantity: 451,
	},
	{
		garage_id: 161,
		part_id: 261,
		quantity: 123,
	},
	{
		garage_id: 161,
		part_id: 262,
		quantity: 202,
	},
	{
		garage_id: 161,
		part_id: 263,
		quantity: 394,
	},
	{
		garage_id: 161,
		part_id: 264,
		quantity: 127,
	},
	{
		garage_id: 161,
		part_id: 265,
		quantity: 124,
	},
	{
		garage_id: 161,
		part_id: 266,
		quantity: 456,
	},
	{
		garage_id: 161,
		part_id: 267,
		quantity: 151,
	},
	{
		garage_id: 161,
		part_id: 268,
		quantity: 77,
	},
	{
		garage_id: 161,
		part_id: 269,
		quantity: 72,
	},
	{
		garage_id: 161,
		part_id: 270,
		quantity: 271,
	},
	{
		garage_id: 161,
		part_id: 271,
		quantity: 111,
	},
	{
		garage_id: 161,
		part_id: 272,
		quantity: 28,
	},
	{
		garage_id: 161,
		part_id: 273,
		quantity: 365,
	},
	{
		garage_id: 161,
		part_id: 274,
		quantity: 43,
	},
	{
		garage_id: 161,
		part_id: 275,
		quantity: 465,
	},
	{
		garage_id: 161,
		part_id: 276,
		quantity: 411,
	},
	{
		garage_id: 161,
		part_id: 277,
		quantity: 283,
	},
	{
		garage_id: 161,
		part_id: 278,
		quantity: 325,
	},
	{
		garage_id: 161,
		part_id: 279,
		quantity: 489,
	},
	{
		garage_id: 161,
		part_id: 280,
		quantity: 129,
	},
	{
		garage_id: 161,
		part_id: 281,
		quantity: 310,
	},
	{
		garage_id: 161,
		part_id: 282,
		quantity: 352,
	},
	{
		garage_id: 161,
		part_id: 283,
		quantity: 181,
	},
	{
		garage_id: 161,
		part_id: 284,
		quantity: 13,
	},
	{
		garage_id: 161,
		part_id: 285,
		quantity: 245,
	},
	{
		garage_id: 161,
		part_id: 286,
		quantity: 249,
	},
	{
		garage_id: 161,
		part_id: 287,
		quantity: 280,
	},
	{
		garage_id: 161,
		part_id: 288,
		quantity: 210,
	},
	{
		garage_id: 161,
		part_id: 289,
		quantity: 480,
	},
	{
		garage_id: 161,
		part_id: 290,
		quantity: 30,
	},
	{
		garage_id: 161,
		part_id: 291,
		quantity: 94,
	},
	{
		garage_id: 161,
		part_id: 292,
		quantity: 366,
	},
	{
		garage_id: 161,
		part_id: 293,
		quantity: 427,
	},
	{
		garage_id: 161,
		part_id: 294,
		quantity: 158,
	},
	{
		garage_id: 161,
		part_id: 295,
		quantity: 450,
	},
	{
		garage_id: 161,
		part_id: 296,
		quantity: 257,
	},
	{
		garage_id: 161,
		part_id: 297,
		quantity: 373,
	},
	{
		garage_id: 161,
		part_id: 298,
		quantity: 312,
	},
	{
		garage_id: 161,
		part_id: 299,
		quantity: 205,
	},
	{
		garage_id: 161,
		part_id: 300,
		quantity: 478,
	},
	{
		garage_id: 161,
		part_id: 301,
		quantity: 295,
	},
	{
		garage_id: 161,
		part_id: 302,
		quantity: 492,
	},
	{
		garage_id: 161,
		part_id: 303,
		quantity: 41,
	},
	{
		garage_id: 161,
		part_id: 304,
		quantity: 145,
	},
	{
		garage_id: 161,
		part_id: 305,
		quantity: 406,
	},
	{
		garage_id: 161,
		part_id: 306,
		quantity: 469,
	},
	{
		garage_id: 161,
		part_id: 307,
		quantity: 280,
	},
	{
		garage_id: 161,
		part_id: 308,
		quantity: 138,
	},
	{
		garage_id: 161,
		part_id: 309,
		quantity: 403,
	},
	{
		garage_id: 161,
		part_id: 310,
		quantity: 134,
	},
	{
		garage_id: 161,
		part_id: 311,
		quantity: 181,
	},
	{
		garage_id: 161,
		part_id: 312,
		quantity: 144,
	},
	{
		garage_id: 161,
		part_id: 313,
		quantity: 6,
	},
	{
		garage_id: 161,
		part_id: 314,
		quantity: 91,
	},
	{
		garage_id: 161,
		part_id: 315,
		quantity: 229,
	},
	{
		garage_id: 161,
		part_id: 316,
		quantity: 488,
	},
	{
		garage_id: 161,
		part_id: 317,
		quantity: 474,
	},
	{
		garage_id: 161,
		part_id: 318,
		quantity: 458,
	},
	{
		garage_id: 161,
		part_id: 319,
		quantity: 386,
	},
	{
		garage_id: 161,
		part_id: 320,
		quantity: 466,
	},
	{
		garage_id: 161,
		part_id: 321,
		quantity: 149,
	},
	{
		garage_id: 161,
		part_id: 322,
		quantity: 13,
	},
	{
		garage_id: 161,
		part_id: 323,
		quantity: 229,
	},
	{
		garage_id: 161,
		part_id: 324,
		quantity: 319,
	},
	{
		garage_id: 161,
		part_id: 325,
		quantity: 271,
	},
	{
		garage_id: 161,
		part_id: 326,
		quantity: 37,
	},
	{
		garage_id: 161,
		part_id: 327,
		quantity: 11,
	},
	{
		garage_id: 161,
		part_id: 328,
		quantity: 48,
	},
	{
		garage_id: 161,
		part_id: 329,
		quantity: 401,
	},
	{
		garage_id: 161,
		part_id: 330,
		quantity: 317,
	},
	{
		garage_id: 161,
		part_id: 331,
		quantity: 478,
	},
	{
		garage_id: 161,
		part_id: 332,
		quantity: 377,
	},
	{
		garage_id: 161,
		part_id: 333,
		quantity: 83,
	},
	{
		garage_id: 161,
		part_id: 334,
		quantity: 327,
	},
	{
		garage_id: 161,
		part_id: 335,
		quantity: 408,
	},
	{
		garage_id: 161,
		part_id: 336,
		quantity: 142,
	},
	{
		garage_id: 161,
		part_id: 337,
		quantity: 318,
	},
	{
		garage_id: 161,
		part_id: 338,
		quantity: 67,
	},
	{
		garage_id: 161,
		part_id: 339,
		quantity: 35,
	},
	{
		garage_id: 161,
		part_id: 340,
		quantity: 28,
	},
	{
		garage_id: 161,
		part_id: 341,
		quantity: 124,
	},
	{
		garage_id: 161,
		part_id: 342,
		quantity: 453,
	},
	{
		garage_id: 161,
		part_id: 343,
		quantity: 295,
	},
	{
		garage_id: 161,
		part_id: 344,
		quantity: 240,
	},
	{
		garage_id: 161,
		part_id: 345,
		quantity: 33,
	},
	{
		garage_id: 161,
		part_id: 346,
		quantity: 7,
	},
	{
		garage_id: 161,
		part_id: 347,
		quantity: 336,
	},
	{
		garage_id: 161,
		part_id: 348,
		quantity: 487,
	},
	{
		garage_id: 161,
		part_id: 349,
		quantity: 373,
	},
	{
		garage_id: 161,
		part_id: 350,
		quantity: 409,
	},
	{
		garage_id: 161,
		part_id: 351,
		quantity: 370,
	},
	{
		garage_id: 161,
		part_id: 352,
		quantity: 438,
	},
	{
		garage_id: 161,
		part_id: 353,
		quantity: 386,
	},
	{
		garage_id: 161,
		part_id: 354,
		quantity: 239,
	},
	{
		garage_id: 161,
		part_id: 355,
		quantity: 184,
	},
	{
		garage_id: 161,
		part_id: 356,
		quantity: 408,
	},
	{
		garage_id: 161,
		part_id: 357,
		quantity: 191,
	},
	{
		garage_id: 161,
		part_id: 358,
		quantity: 324,
	},
	{
		garage_id: 161,
		part_id: 359,
		quantity: 208,
	},
	{
		garage_id: 161,
		part_id: 360,
		quantity: 486,
	},
	{
		garage_id: 161,
		part_id: 361,
		quantity: 448,
	},
	{
		garage_id: 161,
		part_id: 362,
		quantity: 98,
	},
	{
		garage_id: 161,
		part_id: 363,
		quantity: 449,
	},
	{
		garage_id: 161,
		part_id: 364,
		quantity: 103,
	},
	{
		garage_id: 161,
		part_id: 365,
		quantity: 122,
	},
	{
		garage_id: 161,
		part_id: 366,
		quantity: 344,
	},
	{
		garage_id: 161,
		part_id: 367,
		quantity: 282,
	},
	{
		garage_id: 161,
		part_id: 368,
		quantity: 477,
	},
	{
		garage_id: 161,
		part_id: 369,
		quantity: 250,
	},
	{
		garage_id: 161,
		part_id: 370,
		quantity: 47,
	},
	{
		garage_id: 161,
		part_id: 371,
		quantity: 280,
	},
	{
		garage_id: 161,
		part_id: 372,
		quantity: 24,
	},
	{
		garage_id: 161,
		part_id: 373,
		quantity: 51,
	},
	{
		garage_id: 161,
		part_id: 374,
		quantity: 190,
	},
	{
		garage_id: 161,
		part_id: 375,
		quantity: 274,
	},
	{
		garage_id: 161,
		part_id: 376,
		quantity: 417,
	},
	{
		garage_id: 161,
		part_id: 377,
		quantity: 70,
	},
	{
		garage_id: 161,
		part_id: 378,
		quantity: 32,
	},
	{
		garage_id: 161,
		part_id: 379,
		quantity: 61,
	},
	{
		garage_id: 161,
		part_id: 380,
		quantity: 411,
	},
	{
		garage_id: 161,
		part_id: 381,
		quantity: 435,
	},
	{
		garage_id: 161,
		part_id: 382,
		quantity: 295,
	},
	{
		garage_id: 161,
		part_id: 383,
		quantity: 444,
	},
	{
		garage_id: 161,
		part_id: 384,
		quantity: 249,
	},
	{
		garage_id: 161,
		part_id: 385,
		quantity: 365,
	},
	{
		garage_id: 161,
		part_id: 386,
		quantity: 130,
	},
	{
		garage_id: 161,
		part_id: 387,
		quantity: 267,
	},
	{
		garage_id: 161,
		part_id: 388,
		quantity: 378,
	},
	{
		garage_id: 161,
		part_id: 389,
		quantity: 91,
	},
	{
		garage_id: 161,
		part_id: 390,
		quantity: 455,
	},
	{
		garage_id: 161,
		part_id: 391,
		quantity: 404,
	},
	{
		garage_id: 161,
		part_id: 392,
		quantity: 373,
	},
	{
		garage_id: 161,
		part_id: 393,
		quantity: 13,
	},
	{
		garage_id: 161,
		part_id: 394,
		quantity: 75,
	},
	{
		garage_id: 161,
		part_id: 395,
		quantity: 461,
	},
	{
		garage_id: 161,
		part_id: 396,
		quantity: 447,
	},
	{
		garage_id: 161,
		part_id: 397,
		quantity: 355,
	},
	{
		garage_id: 161,
		part_id: 398,
		quantity: 10,
	},
	{
		garage_id: 161,
		part_id: 399,
		quantity: 476,
	},
	{
		garage_id: 162,
		part_id: 0,
		quantity: 264,
	},
	{
		garage_id: 162,
		part_id: 1,
		quantity: 126,
	},
	{
		garage_id: 162,
		part_id: 2,
		quantity: 461,
	},
	{
		garage_id: 162,
		part_id: 3,
		quantity: 13,
	},
	{
		garage_id: 162,
		part_id: 4,
		quantity: 243,
	},
	{
		garage_id: 162,
		part_id: 5,
		quantity: 328,
	},
	{
		garage_id: 162,
		part_id: 6,
		quantity: 410,
	},
	{
		garage_id: 162,
		part_id: 7,
		quantity: 249,
	},
	{
		garage_id: 162,
		part_id: 8,
		quantity: 364,
	},
	{
		garage_id: 162,
		part_id: 9,
		quantity: 376,
	},
	{
		garage_id: 162,
		part_id: 10,
		quantity: 145,
	},
	{
		garage_id: 162,
		part_id: 11,
		quantity: 360,
	},
	{
		garage_id: 162,
		part_id: 12,
		quantity: 111,
	},
	{
		garage_id: 162,
		part_id: 13,
		quantity: 396,
	},
	{
		garage_id: 162,
		part_id: 14,
		quantity: 377,
	},
	{
		garage_id: 162,
		part_id: 15,
		quantity: 44,
	},
	{
		garage_id: 162,
		part_id: 16,
		quantity: 499,
	},
	{
		garage_id: 162,
		part_id: 17,
		quantity: 285,
	},
	{
		garage_id: 162,
		part_id: 18,
		quantity: 36,
	},
	{
		garage_id: 162,
		part_id: 19,
		quantity: 247,
	},
	{
		garage_id: 162,
		part_id: 20,
		quantity: 186,
	},
	{
		garage_id: 162,
		part_id: 21,
		quantity: 233,
	},
	{
		garage_id: 162,
		part_id: 22,
		quantity: 398,
	},
	{
		garage_id: 162,
		part_id: 23,
		quantity: 175,
	},
	{
		garage_id: 162,
		part_id: 24,
		quantity: 109,
	},
	{
		garage_id: 162,
		part_id: 25,
		quantity: 19,
	},
	{
		garage_id: 162,
		part_id: 26,
		quantity: 421,
	},
	{
		garage_id: 162,
		part_id: 27,
		quantity: 419,
	},
	{
		garage_id: 162,
		part_id: 28,
		quantity: 279,
	},
	{
		garage_id: 162,
		part_id: 29,
		quantity: 303,
	},
	{
		garage_id: 162,
		part_id: 30,
		quantity: 277,
	},
	{
		garage_id: 162,
		part_id: 31,
		quantity: 104,
	},
	{
		garage_id: 162,
		part_id: 32,
		quantity: 461,
	},
	{
		garage_id: 162,
		part_id: 33,
		quantity: 313,
	},
	{
		garage_id: 162,
		part_id: 34,
		quantity: 402,
	},
	{
		garage_id: 162,
		part_id: 35,
		quantity: 307,
	},
	{
		garage_id: 162,
		part_id: 36,
		quantity: 47,
	},
	{
		garage_id: 162,
		part_id: 37,
		quantity: 441,
	},
	{
		garage_id: 162,
		part_id: 38,
		quantity: 497,
	},
	{
		garage_id: 162,
		part_id: 39,
		quantity: 468,
	},
	{
		garage_id: 162,
		part_id: 40,
		quantity: 239,
	},
	{
		garage_id: 162,
		part_id: 41,
		quantity: 364,
	},
	{
		garage_id: 162,
		part_id: 42,
		quantity: 320,
	},
	{
		garage_id: 162,
		part_id: 43,
		quantity: 486,
	},
	{
		garage_id: 162,
		part_id: 44,
		quantity: 183,
	},
	{
		garage_id: 162,
		part_id: 45,
		quantity: 119,
	},
	{
		garage_id: 162,
		part_id: 46,
		quantity: 239,
	},
	{
		garage_id: 162,
		part_id: 47,
		quantity: 255,
	},
	{
		garage_id: 162,
		part_id: 48,
		quantity: 199,
	},
	{
		garage_id: 162,
		part_id: 49,
		quantity: 100,
	},
	{
		garage_id: 162,
		part_id: 50,
		quantity: 35,
	},
	{
		garage_id: 162,
		part_id: 51,
		quantity: 352,
	},
	{
		garage_id: 162,
		part_id: 52,
		quantity: 140,
	},
	{
		garage_id: 162,
		part_id: 53,
		quantity: 82,
	},
	{
		garage_id: 162,
		part_id: 54,
		quantity: 272,
	},
	{
		garage_id: 162,
		part_id: 55,
		quantity: 428,
	},
	{
		garage_id: 162,
		part_id: 56,
		quantity: 359,
	},
	{
		garage_id: 162,
		part_id: 57,
		quantity: 234,
	},
	{
		garage_id: 162,
		part_id: 58,
		quantity: 179,
	},
	{
		garage_id: 162,
		part_id: 59,
		quantity: 270,
	},
	{
		garage_id: 162,
		part_id: 60,
		quantity: 194,
	},
	{
		garage_id: 162,
		part_id: 61,
		quantity: 188,
	},
	{
		garage_id: 162,
		part_id: 62,
		quantity: 276,
	},
	{
		garage_id: 162,
		part_id: 63,
		quantity: 113,
	},
	{
		garage_id: 162,
		part_id: 64,
		quantity: 8,
	},
	{
		garage_id: 162,
		part_id: 65,
		quantity: 289,
	},
	{
		garage_id: 162,
		part_id: 66,
		quantity: 500,
	},
	{
		garage_id: 162,
		part_id: 67,
		quantity: 80,
	},
	{
		garage_id: 162,
		part_id: 68,
		quantity: 92,
	},
	{
		garage_id: 162,
		part_id: 69,
		quantity: 213,
	},
	{
		garage_id: 162,
		part_id: 70,
		quantity: 35,
	},
	{
		garage_id: 162,
		part_id: 71,
		quantity: 343,
	},
	{
		garage_id: 162,
		part_id: 72,
		quantity: 79,
	},
	{
		garage_id: 162,
		part_id: 73,
		quantity: 58,
	},
	{
		garage_id: 162,
		part_id: 74,
		quantity: 119,
	},
	{
		garage_id: 162,
		part_id: 75,
		quantity: 97,
	},
	{
		garage_id: 162,
		part_id: 76,
		quantity: 372,
	},
	{
		garage_id: 162,
		part_id: 77,
		quantity: 369,
	},
	{
		garage_id: 162,
		part_id: 78,
		quantity: 406,
	},
	{
		garage_id: 162,
		part_id: 79,
		quantity: 441,
	},
	{
		garage_id: 162,
		part_id: 80,
		quantity: 427,
	},
	{
		garage_id: 162,
		part_id: 81,
		quantity: 151,
	},
	{
		garage_id: 162,
		part_id: 82,
		quantity: 47,
	},
	{
		garage_id: 162,
		part_id: 83,
		quantity: 471,
	},
	{
		garage_id: 162,
		part_id: 84,
		quantity: 15,
	},
	{
		garage_id: 162,
		part_id: 85,
		quantity: 411,
	},
	{
		garage_id: 162,
		part_id: 86,
		quantity: 77,
	},
	{
		garage_id: 162,
		part_id: 87,
		quantity: 128,
	},
	{
		garage_id: 162,
		part_id: 88,
		quantity: 428,
	},
	{
		garage_id: 162,
		part_id: 89,
		quantity: 288,
	},
	{
		garage_id: 162,
		part_id: 90,
		quantity: 494,
	},
	{
		garage_id: 162,
		part_id: 91,
		quantity: 359,
	},
	{
		garage_id: 162,
		part_id: 92,
		quantity: 336,
	},
	{
		garage_id: 162,
		part_id: 93,
		quantity: 162,
	},
	{
		garage_id: 162,
		part_id: 94,
		quantity: 191,
	},
	{
		garage_id: 162,
		part_id: 95,
		quantity: 199,
	},
	{
		garage_id: 162,
		part_id: 96,
		quantity: 375,
	},
	{
		garage_id: 162,
		part_id: 97,
		quantity: 172,
	},
	{
		garage_id: 162,
		part_id: 98,
		quantity: 36,
	},
	{
		garage_id: 162,
		part_id: 99,
		quantity: 194,
	},
	{
		garage_id: 162,
		part_id: 100,
		quantity: 20,
	},
	{
		garage_id: 162,
		part_id: 101,
		quantity: 252,
	},
	{
		garage_id: 162,
		part_id: 102,
		quantity: 500,
	},
	{
		garage_id: 162,
		part_id: 103,
		quantity: 334,
	},
	{
		garage_id: 162,
		part_id: 104,
		quantity: 491,
	},
	{
		garage_id: 162,
		part_id: 105,
		quantity: 119,
	},
	{
		garage_id: 162,
		part_id: 106,
		quantity: 91,
	},
	{
		garage_id: 162,
		part_id: 107,
		quantity: 206,
	},
	{
		garage_id: 162,
		part_id: 108,
		quantity: 46,
	},
	{
		garage_id: 162,
		part_id: 109,
		quantity: 88,
	},
	{
		garage_id: 162,
		part_id: 110,
		quantity: 49,
	},
	{
		garage_id: 162,
		part_id: 111,
		quantity: 95,
	},
	{
		garage_id: 162,
		part_id: 112,
		quantity: 323,
	},
	{
		garage_id: 162,
		part_id: 113,
		quantity: 251,
	},
	{
		garage_id: 162,
		part_id: 114,
		quantity: 202,
	},
	{
		garage_id: 162,
		part_id: 115,
		quantity: 220,
	},
	{
		garage_id: 162,
		part_id: 116,
		quantity: 225,
	},
	{
		garage_id: 162,
		part_id: 117,
		quantity: 226,
	},
	{
		garage_id: 162,
		part_id: 118,
		quantity: 427,
	},
	{
		garage_id: 162,
		part_id: 119,
		quantity: 136,
	},
	{
		garage_id: 162,
		part_id: 120,
		quantity: 424,
	},
	{
		garage_id: 162,
		part_id: 121,
		quantity: 65,
	},
	{
		garage_id: 162,
		part_id: 122,
		quantity: 99,
	},
	{
		garage_id: 162,
		part_id: 123,
		quantity: 295,
	},
	{
		garage_id: 162,
		part_id: 124,
		quantity: 18,
	},
	{
		garage_id: 162,
		part_id: 125,
		quantity: 424,
	},
	{
		garage_id: 162,
		part_id: 126,
		quantity: 149,
	},
	{
		garage_id: 162,
		part_id: 127,
		quantity: 349,
	},
	{
		garage_id: 162,
		part_id: 128,
		quantity: 148,
	},
	{
		garage_id: 162,
		part_id: 129,
		quantity: 57,
	},
	{
		garage_id: 162,
		part_id: 130,
		quantity: 66,
	},
	{
		garage_id: 162,
		part_id: 131,
		quantity: 469,
	},
	{
		garage_id: 162,
		part_id: 132,
		quantity: 490,
	},
	{
		garage_id: 162,
		part_id: 133,
		quantity: 354,
	},
	{
		garage_id: 162,
		part_id: 134,
		quantity: 97,
	},
	{
		garage_id: 162,
		part_id: 135,
		quantity: 337,
	},
	{
		garage_id: 162,
		part_id: 136,
		quantity: 400,
	},
	{
		garage_id: 162,
		part_id: 137,
		quantity: 423,
	},
	{
		garage_id: 162,
		part_id: 138,
		quantity: 382,
	},
	{
		garage_id: 162,
		part_id: 139,
		quantity: 11,
	},
	{
		garage_id: 162,
		part_id: 140,
		quantity: 342,
	},
	{
		garage_id: 162,
		part_id: 141,
		quantity: 228,
	},
	{
		garage_id: 162,
		part_id: 142,
		quantity: 274,
	},
	{
		garage_id: 162,
		part_id: 143,
		quantity: 336,
	},
	{
		garage_id: 162,
		part_id: 144,
		quantity: 390,
	},
	{
		garage_id: 162,
		part_id: 145,
		quantity: 475,
	},
	{
		garage_id: 162,
		part_id: 146,
		quantity: 175,
	},
	{
		garage_id: 162,
		part_id: 147,
		quantity: 97,
	},
	{
		garage_id: 162,
		part_id: 148,
		quantity: 450,
	},
	{
		garage_id: 162,
		part_id: 149,
		quantity: 39,
	},
	{
		garage_id: 162,
		part_id: 150,
		quantity: 375,
	},
	{
		garage_id: 162,
		part_id: 151,
		quantity: 273,
	},
	{
		garage_id: 162,
		part_id: 152,
		quantity: 166,
	},
	{
		garage_id: 162,
		part_id: 153,
		quantity: 111,
	},
	{
		garage_id: 162,
		part_id: 154,
		quantity: 271,
	},
	{
		garage_id: 162,
		part_id: 155,
		quantity: 322,
	},
	{
		garage_id: 162,
		part_id: 156,
		quantity: 179,
	},
	{
		garage_id: 162,
		part_id: 157,
		quantity: 172,
	},
	{
		garage_id: 162,
		part_id: 158,
		quantity: 492,
	},
	{
		garage_id: 162,
		part_id: 159,
		quantity: 212,
	},
	{
		garage_id: 162,
		part_id: 160,
		quantity: 350,
	},
	{
		garage_id: 162,
		part_id: 161,
		quantity: 15,
	},
	{
		garage_id: 162,
		part_id: 162,
		quantity: 415,
	},
	{
		garage_id: 162,
		part_id: 163,
		quantity: 298,
	},
	{
		garage_id: 162,
		part_id: 164,
		quantity: 394,
	},
	{
		garage_id: 162,
		part_id: 165,
		quantity: 306,
	},
	{
		garage_id: 162,
		part_id: 166,
		quantity: 406,
	},
	{
		garage_id: 162,
		part_id: 167,
		quantity: 398,
	},
	{
		garage_id: 162,
		part_id: 168,
		quantity: 361,
	},
	{
		garage_id: 162,
		part_id: 169,
		quantity: 195,
	},
	{
		garage_id: 162,
		part_id: 170,
		quantity: 360,
	},
	{
		garage_id: 162,
		part_id: 171,
		quantity: 490,
	},
	{
		garage_id: 162,
		part_id: 172,
		quantity: 140,
	},
	{
		garage_id: 162,
		part_id: 173,
		quantity: 216,
	},
	{
		garage_id: 162,
		part_id: 174,
		quantity: 366,
	},
	{
		garage_id: 162,
		part_id: 175,
		quantity: 374,
	},
	{
		garage_id: 162,
		part_id: 176,
		quantity: 175,
	},
	{
		garage_id: 162,
		part_id: 177,
		quantity: 153,
	},
	{
		garage_id: 162,
		part_id: 178,
		quantity: 311,
	},
	{
		garage_id: 162,
		part_id: 179,
		quantity: 198,
	},
	{
		garage_id: 162,
		part_id: 180,
		quantity: 443,
	},
	{
		garage_id: 162,
		part_id: 181,
		quantity: 88,
	},
	{
		garage_id: 162,
		part_id: 182,
		quantity: 246,
	},
	{
		garage_id: 162,
		part_id: 183,
		quantity: 109,
	},
	{
		garage_id: 162,
		part_id: 184,
		quantity: 45,
	},
	{
		garage_id: 162,
		part_id: 185,
		quantity: 260,
	},
	{
		garage_id: 162,
		part_id: 186,
		quantity: 239,
	},
	{
		garage_id: 162,
		part_id: 187,
		quantity: 483,
	},
	{
		garage_id: 162,
		part_id: 188,
		quantity: 89,
	},
	{
		garage_id: 162,
		part_id: 189,
		quantity: 400,
	},
	{
		garage_id: 162,
		part_id: 190,
		quantity: 492,
	},
	{
		garage_id: 162,
		part_id: 191,
		quantity: 49,
	},
	{
		garage_id: 162,
		part_id: 192,
		quantity: 398,
	},
	{
		garage_id: 162,
		part_id: 193,
		quantity: 83,
	},
	{
		garage_id: 162,
		part_id: 194,
		quantity: 103,
	},
	{
		garage_id: 162,
		part_id: 195,
		quantity: 491,
	},
	{
		garage_id: 162,
		part_id: 196,
		quantity: 370,
	},
	{
		garage_id: 162,
		part_id: 197,
		quantity: 98,
	},
	{
		garage_id: 162,
		part_id: 198,
		quantity: 254,
	},
	{
		garage_id: 162,
		part_id: 199,
		quantity: 12,
	},
	{
		garage_id: 162,
		part_id: 200,
		quantity: 494,
	},
	{
		garage_id: 162,
		part_id: 201,
		quantity: 319,
	},
	{
		garage_id: 162,
		part_id: 202,
		quantity: 233,
	},
	{
		garage_id: 162,
		part_id: 203,
		quantity: 157,
	},
	{
		garage_id: 162,
		part_id: 204,
		quantity: 130,
	},
	{
		garage_id: 162,
		part_id: 205,
		quantity: 419,
	},
	{
		garage_id: 162,
		part_id: 206,
		quantity: 222,
	},
	{
		garage_id: 162,
		part_id: 207,
		quantity: 440,
	},
	{
		garage_id: 162,
		part_id: 208,
		quantity: 213,
	},
	{
		garage_id: 162,
		part_id: 209,
		quantity: 358,
	},
	{
		garage_id: 162,
		part_id: 210,
		quantity: 91,
	},
	{
		garage_id: 162,
		part_id: 211,
		quantity: 193,
	},
	{
		garage_id: 162,
		part_id: 212,
		quantity: 369,
	},
	{
		garage_id: 162,
		part_id: 213,
		quantity: 290,
	},
	{
		garage_id: 162,
		part_id: 214,
		quantity: 181,
	},
	{
		garage_id: 162,
		part_id: 215,
		quantity: 489,
	},
	{
		garage_id: 162,
		part_id: 216,
		quantity: 61,
	},
	{
		garage_id: 162,
		part_id: 217,
		quantity: 491,
	},
	{
		garage_id: 162,
		part_id: 218,
		quantity: 381,
	},
	{
		garage_id: 162,
		part_id: 219,
		quantity: 422,
	},
	{
		garage_id: 162,
		part_id: 220,
		quantity: 401,
	},
	{
		garage_id: 162,
		part_id: 221,
		quantity: 180,
	},
	{
		garage_id: 162,
		part_id: 222,
		quantity: 489,
	},
	{
		garage_id: 162,
		part_id: 223,
		quantity: 140,
	},
	{
		garage_id: 162,
		part_id: 224,
		quantity: 342,
	},
	{
		garage_id: 162,
		part_id: 225,
		quantity: 187,
	},
	{
		garage_id: 162,
		part_id: 226,
		quantity: 470,
	},
	{
		garage_id: 162,
		part_id: 227,
		quantity: 452,
	},
	{
		garage_id: 162,
		part_id: 228,
		quantity: 273,
	},
	{
		garage_id: 162,
		part_id: 229,
		quantity: 42,
	},
	{
		garage_id: 162,
		part_id: 230,
		quantity: 123,
	},
	{
		garage_id: 162,
		part_id: 231,
		quantity: 493,
	},
	{
		garage_id: 162,
		part_id: 232,
		quantity: 231,
	},
	{
		garage_id: 162,
		part_id: 233,
		quantity: 337,
	},
	{
		garage_id: 162,
		part_id: 234,
		quantity: 107,
	},
	{
		garage_id: 162,
		part_id: 235,
		quantity: 131,
	},
	{
		garage_id: 162,
		part_id: 236,
		quantity: 130,
	},
	{
		garage_id: 162,
		part_id: 237,
		quantity: 286,
	},
	{
		garage_id: 162,
		part_id: 238,
		quantity: 176,
	},
	{
		garage_id: 162,
		part_id: 239,
		quantity: 216,
	},
	{
		garage_id: 162,
		part_id: 240,
		quantity: 111,
	},
	{
		garage_id: 162,
		part_id: 241,
		quantity: 282,
	},
	{
		garage_id: 162,
		part_id: 242,
		quantity: 336,
	},
	{
		garage_id: 162,
		part_id: 243,
		quantity: 484,
	},
	{
		garage_id: 162,
		part_id: 244,
		quantity: 185,
	},
	{
		garage_id: 162,
		part_id: 245,
		quantity: 203,
	},
	{
		garage_id: 162,
		part_id: 246,
		quantity: 83,
	},
	{
		garage_id: 162,
		part_id: 247,
		quantity: 399,
	},
	{
		garage_id: 162,
		part_id: 248,
		quantity: 63,
	},
	{
		garage_id: 162,
		part_id: 249,
		quantity: 188,
	},
	{
		garage_id: 162,
		part_id: 250,
		quantity: 439,
	},
	{
		garage_id: 162,
		part_id: 251,
		quantity: 491,
	},
	{
		garage_id: 162,
		part_id: 252,
		quantity: 14,
	},
	{
		garage_id: 162,
		part_id: 253,
		quantity: 346,
	},
	{
		garage_id: 162,
		part_id: 254,
		quantity: 358,
	},
	{
		garage_id: 162,
		part_id: 255,
		quantity: 173,
	},
	{
		garage_id: 162,
		part_id: 256,
		quantity: 242,
	},
	{
		garage_id: 162,
		part_id: 257,
		quantity: 125,
	},
	{
		garage_id: 162,
		part_id: 258,
		quantity: 107,
	},
	{
		garage_id: 162,
		part_id: 259,
		quantity: 273,
	},
	{
		garage_id: 162,
		part_id: 260,
		quantity: 13,
	},
	{
		garage_id: 162,
		part_id: 261,
		quantity: 246,
	},
	{
		garage_id: 162,
		part_id: 262,
		quantity: 58,
	},
	{
		garage_id: 162,
		part_id: 263,
		quantity: 189,
	},
	{
		garage_id: 162,
		part_id: 264,
		quantity: 331,
	},
	{
		garage_id: 162,
		part_id: 265,
		quantity: 191,
	},
	{
		garage_id: 162,
		part_id: 266,
		quantity: 281,
	},
	{
		garage_id: 162,
		part_id: 267,
		quantity: 411,
	},
	{
		garage_id: 162,
		part_id: 268,
		quantity: 384,
	},
	{
		garage_id: 162,
		part_id: 269,
		quantity: 124,
	},
	{
		garage_id: 162,
		part_id: 270,
		quantity: 223,
	},
	{
		garage_id: 162,
		part_id: 271,
		quantity: 232,
	},
	{
		garage_id: 162,
		part_id: 272,
		quantity: 249,
	},
	{
		garage_id: 162,
		part_id: 273,
		quantity: 475,
	},
	{
		garage_id: 162,
		part_id: 274,
		quantity: 283,
	},
	{
		garage_id: 162,
		part_id: 275,
		quantity: 144,
	},
	{
		garage_id: 162,
		part_id: 276,
		quantity: 129,
	},
	{
		garage_id: 162,
		part_id: 277,
		quantity: 117,
	},
	{
		garage_id: 162,
		part_id: 278,
		quantity: 331,
	},
	{
		garage_id: 162,
		part_id: 279,
		quantity: 45,
	},
	{
		garage_id: 162,
		part_id: 280,
		quantity: 490,
	},
	{
		garage_id: 162,
		part_id: 281,
		quantity: 413,
	},
	{
		garage_id: 162,
		part_id: 282,
		quantity: 190,
	},
	{
		garage_id: 162,
		part_id: 283,
		quantity: 296,
	},
	{
		garage_id: 162,
		part_id: 284,
		quantity: 290,
	},
	{
		garage_id: 162,
		part_id: 285,
		quantity: 172,
	},
	{
		garage_id: 162,
		part_id: 286,
		quantity: 326,
	},
	{
		garage_id: 162,
		part_id: 287,
		quantity: 286,
	},
	{
		garage_id: 162,
		part_id: 288,
		quantity: 310,
	},
	{
		garage_id: 162,
		part_id: 289,
		quantity: 479,
	},
	{
		garage_id: 162,
		part_id: 290,
		quantity: 400,
	},
	{
		garage_id: 162,
		part_id: 291,
		quantity: 437,
	},
	{
		garage_id: 162,
		part_id: 292,
		quantity: 381,
	},
	{
		garage_id: 162,
		part_id: 293,
		quantity: 175,
	},
	{
		garage_id: 162,
		part_id: 294,
		quantity: 471,
	},
	{
		garage_id: 162,
		part_id: 295,
		quantity: 336,
	},
	{
		garage_id: 162,
		part_id: 296,
		quantity: 490,
	},
	{
		garage_id: 162,
		part_id: 297,
		quantity: 237,
	},
	{
		garage_id: 162,
		part_id: 298,
		quantity: 138,
	},
	{
		garage_id: 162,
		part_id: 299,
		quantity: 371,
	},
	{
		garage_id: 162,
		part_id: 300,
		quantity: 266,
	},
	{
		garage_id: 162,
		part_id: 301,
		quantity: 27,
	},
	{
		garage_id: 162,
		part_id: 302,
		quantity: 447,
	},
	{
		garage_id: 162,
		part_id: 303,
		quantity: 421,
	},
	{
		garage_id: 162,
		part_id: 304,
		quantity: 455,
	},
	{
		garage_id: 162,
		part_id: 305,
		quantity: 476,
	},
	{
		garage_id: 162,
		part_id: 306,
		quantity: 362,
	},
	{
		garage_id: 162,
		part_id: 307,
		quantity: 480,
	},
	{
		garage_id: 162,
		part_id: 308,
		quantity: 63,
	},
	{
		garage_id: 162,
		part_id: 309,
		quantity: 8,
	},
	{
		garage_id: 162,
		part_id: 310,
		quantity: 98,
	},
	{
		garage_id: 162,
		part_id: 311,
		quantity: 213,
	},
	{
		garage_id: 162,
		part_id: 312,
		quantity: 396,
	},
	{
		garage_id: 162,
		part_id: 313,
		quantity: 87,
	},
	{
		garage_id: 162,
		part_id: 314,
		quantity: 50,
	},
	{
		garage_id: 162,
		part_id: 315,
		quantity: 16,
	},
	{
		garage_id: 162,
		part_id: 316,
		quantity: 96,
	},
	{
		garage_id: 162,
		part_id: 317,
		quantity: 210,
	},
	{
		garage_id: 162,
		part_id: 318,
		quantity: 169,
	},
	{
		garage_id: 162,
		part_id: 319,
		quantity: 13,
	},
	{
		garage_id: 162,
		part_id: 320,
		quantity: 385,
	},
	{
		garage_id: 162,
		part_id: 321,
		quantity: 235,
	},
	{
		garage_id: 162,
		part_id: 322,
		quantity: 19,
	},
	{
		garage_id: 162,
		part_id: 323,
		quantity: 182,
	},
	{
		garage_id: 162,
		part_id: 324,
		quantity: 363,
	},
	{
		garage_id: 162,
		part_id: 325,
		quantity: 382,
	},
	{
		garage_id: 162,
		part_id: 326,
		quantity: 259,
	},
	{
		garage_id: 162,
		part_id: 327,
		quantity: 116,
	},
	{
		garage_id: 162,
		part_id: 328,
		quantity: 392,
	},
	{
		garage_id: 162,
		part_id: 329,
		quantity: 178,
	},
	{
		garage_id: 162,
		part_id: 330,
		quantity: 388,
	},
	{
		garage_id: 162,
		part_id: 331,
		quantity: 239,
	},
	{
		garage_id: 162,
		part_id: 332,
		quantity: 490,
	},
	{
		garage_id: 162,
		part_id: 333,
		quantity: 210,
	},
	{
		garage_id: 162,
		part_id: 334,
		quantity: 331,
	},
	{
		garage_id: 162,
		part_id: 335,
		quantity: 157,
	},
	{
		garage_id: 162,
		part_id: 336,
		quantity: 294,
	},
	{
		garage_id: 162,
		part_id: 337,
		quantity: 102,
	},
	{
		garage_id: 162,
		part_id: 338,
		quantity: 36,
	},
	{
		garage_id: 162,
		part_id: 339,
		quantity: 49,
	},
	{
		garage_id: 162,
		part_id: 340,
		quantity: 462,
	},
	{
		garage_id: 162,
		part_id: 341,
		quantity: 25,
	},
	{
		garage_id: 162,
		part_id: 342,
		quantity: 362,
	},
	{
		garage_id: 162,
		part_id: 343,
		quantity: 132,
	},
	{
		garage_id: 162,
		part_id: 344,
		quantity: 70,
	},
	{
		garage_id: 162,
		part_id: 345,
		quantity: 483,
	},
	{
		garage_id: 162,
		part_id: 346,
		quantity: 357,
	},
	{
		garage_id: 162,
		part_id: 347,
		quantity: 55,
	},
	{
		garage_id: 162,
		part_id: 348,
		quantity: 157,
	},
	{
		garage_id: 162,
		part_id: 349,
		quantity: 460,
	},
	{
		garage_id: 162,
		part_id: 350,
		quantity: 167,
	},
	{
		garage_id: 162,
		part_id: 351,
		quantity: 350,
	},
	{
		garage_id: 162,
		part_id: 352,
		quantity: 229,
	},
	{
		garage_id: 162,
		part_id: 353,
		quantity: 319,
	},
	{
		garage_id: 162,
		part_id: 354,
		quantity: 92,
	},
	{
		garage_id: 162,
		part_id: 355,
		quantity: 266,
	},
	{
		garage_id: 162,
		part_id: 356,
		quantity: 157,
	},
	{
		garage_id: 162,
		part_id: 357,
		quantity: 355,
	},
	{
		garage_id: 162,
		part_id: 358,
		quantity: 484,
	},
	{
		garage_id: 162,
		part_id: 359,
		quantity: 398,
	},
	{
		garage_id: 162,
		part_id: 360,
		quantity: 353,
	},
	{
		garage_id: 162,
		part_id: 361,
		quantity: 156,
	},
	{
		garage_id: 162,
		part_id: 362,
		quantity: 295,
	},
	{
		garage_id: 162,
		part_id: 363,
		quantity: 312,
	},
	{
		garage_id: 162,
		part_id: 364,
		quantity: 437,
	},
	{
		garage_id: 162,
		part_id: 365,
		quantity: 107,
	},
	{
		garage_id: 162,
		part_id: 366,
		quantity: 356,
	},
	{
		garage_id: 162,
		part_id: 367,
		quantity: 377,
	},
	{
		garage_id: 162,
		part_id: 368,
		quantity: 437,
	},
	{
		garage_id: 162,
		part_id: 369,
		quantity: 315,
	},
	{
		garage_id: 162,
		part_id: 370,
		quantity: 388,
	},
	{
		garage_id: 162,
		part_id: 371,
		quantity: 234,
	},
	{
		garage_id: 162,
		part_id: 372,
		quantity: 57,
	},
	{
		garage_id: 162,
		part_id: 373,
		quantity: 72,
	},
	{
		garage_id: 162,
		part_id: 374,
		quantity: 340,
	},
	{
		garage_id: 162,
		part_id: 375,
		quantity: 374,
	},
	{
		garage_id: 162,
		part_id: 376,
		quantity: 362,
	},
	{
		garage_id: 162,
		part_id: 377,
		quantity: 221,
	},
	{
		garage_id: 162,
		part_id: 378,
		quantity: 422,
	},
	{
		garage_id: 162,
		part_id: 379,
		quantity: 486,
	},
	{
		garage_id: 162,
		part_id: 380,
		quantity: 400,
	},
	{
		garage_id: 162,
		part_id: 381,
		quantity: 238,
	},
	{
		garage_id: 162,
		part_id: 382,
		quantity: 396,
	},
	{
		garage_id: 162,
		part_id: 383,
		quantity: 277,
	},
	{
		garage_id: 162,
		part_id: 384,
		quantity: 264,
	},
	{
		garage_id: 162,
		part_id: 385,
		quantity: 44,
	},
	{
		garage_id: 162,
		part_id: 386,
		quantity: 485,
	},
	{
		garage_id: 162,
		part_id: 387,
		quantity: 193,
	},
	{
		garage_id: 162,
		part_id: 388,
		quantity: 474,
	},
	{
		garage_id: 162,
		part_id: 389,
		quantity: 189,
	},
	{
		garage_id: 162,
		part_id: 390,
		quantity: 403,
	},
	{
		garage_id: 162,
		part_id: 391,
		quantity: 89,
	},
	{
		garage_id: 162,
		part_id: 392,
		quantity: 157,
	},
	{
		garage_id: 162,
		part_id: 393,
		quantity: 227,
	},
	{
		garage_id: 162,
		part_id: 394,
		quantity: 99,
	},
	{
		garage_id: 162,
		part_id: 395,
		quantity: 191,
	},
	{
		garage_id: 162,
		part_id: 396,
		quantity: 266,
	},
	{
		garage_id: 162,
		part_id: 397,
		quantity: 479,
	},
	{
		garage_id: 162,
		part_id: 398,
		quantity: 99,
	},
	{
		garage_id: 162,
		part_id: 399,
		quantity: 347,
	},
	{
		garage_id: 163,
		part_id: 0,
		quantity: 122,
	},
	{
		garage_id: 163,
		part_id: 1,
		quantity: 66,
	},
	{
		garage_id: 163,
		part_id: 2,
		quantity: 237,
	},
	{
		garage_id: 163,
		part_id: 3,
		quantity: 361,
	},
	{
		garage_id: 163,
		part_id: 4,
		quantity: 427,
	},
	{
		garage_id: 163,
		part_id: 5,
		quantity: 434,
	},
	{
		garage_id: 163,
		part_id: 6,
		quantity: 356,
	},
	{
		garage_id: 163,
		part_id: 7,
		quantity: 140,
	},
	{
		garage_id: 163,
		part_id: 8,
		quantity: 153,
	},
	{
		garage_id: 163,
		part_id: 9,
		quantity: 268,
	},
	{
		garage_id: 163,
		part_id: 10,
		quantity: 237,
	},
	{
		garage_id: 163,
		part_id: 11,
		quantity: 398,
	},
	{
		garage_id: 163,
		part_id: 12,
		quantity: 243,
	},
	{
		garage_id: 163,
		part_id: 13,
		quantity: 394,
	},
	{
		garage_id: 163,
		part_id: 14,
		quantity: 58,
	},
	{
		garage_id: 163,
		part_id: 15,
		quantity: 471,
	},
	{
		garage_id: 163,
		part_id: 16,
		quantity: 26,
	},
	{
		garage_id: 163,
		part_id: 17,
		quantity: 77,
	},
	{
		garage_id: 163,
		part_id: 18,
		quantity: 79,
	},
	{
		garage_id: 163,
		part_id: 19,
		quantity: 259,
	},
	{
		garage_id: 163,
		part_id: 20,
		quantity: 42,
	},
	{
		garage_id: 163,
		part_id: 21,
		quantity: 259,
	},
	{
		garage_id: 163,
		part_id: 22,
		quantity: 418,
	},
	{
		garage_id: 163,
		part_id: 23,
		quantity: 105,
	},
	{
		garage_id: 163,
		part_id: 24,
		quantity: 153,
	},
	{
		garage_id: 163,
		part_id: 25,
		quantity: 93,
	},
	{
		garage_id: 163,
		part_id: 26,
		quantity: 347,
	},
	{
		garage_id: 163,
		part_id: 27,
		quantity: 62,
	},
	{
		garage_id: 163,
		part_id: 28,
		quantity: 119,
	},
	{
		garage_id: 163,
		part_id: 29,
		quantity: 101,
	},
	{
		garage_id: 163,
		part_id: 30,
		quantity: 358,
	},
	{
		garage_id: 163,
		part_id: 31,
		quantity: 365,
	},
	{
		garage_id: 163,
		part_id: 32,
		quantity: 479,
	},
	{
		garage_id: 163,
		part_id: 33,
		quantity: 351,
	},
	{
		garage_id: 163,
		part_id: 34,
		quantity: 437,
	},
	{
		garage_id: 163,
		part_id: 35,
		quantity: 123,
	},
	{
		garage_id: 163,
		part_id: 36,
		quantity: 251,
	},
	{
		garage_id: 163,
		part_id: 37,
		quantity: 161,
	},
	{
		garage_id: 163,
		part_id: 38,
		quantity: 54,
	},
	{
		garage_id: 163,
		part_id: 39,
		quantity: 280,
	},
	{
		garage_id: 163,
		part_id: 40,
		quantity: 224,
	},
	{
		garage_id: 163,
		part_id: 41,
		quantity: 329,
	},
	{
		garage_id: 163,
		part_id: 42,
		quantity: 298,
	},
	{
		garage_id: 163,
		part_id: 43,
		quantity: 482,
	},
	{
		garage_id: 163,
		part_id: 44,
		quantity: 343,
	},
	{
		garage_id: 163,
		part_id: 45,
		quantity: 122,
	},
	{
		garage_id: 163,
		part_id: 46,
		quantity: 388,
	},
	{
		garage_id: 163,
		part_id: 47,
		quantity: 85,
	},
	{
		garage_id: 163,
		part_id: 48,
		quantity: 112,
	},
	{
		garage_id: 163,
		part_id: 49,
		quantity: 7,
	},
	{
		garage_id: 163,
		part_id: 50,
		quantity: 246,
	},
	{
		garage_id: 163,
		part_id: 51,
		quantity: 445,
	},
	{
		garage_id: 163,
		part_id: 52,
		quantity: 420,
	},
	{
		garage_id: 163,
		part_id: 53,
		quantity: 66,
	},
	{
		garage_id: 163,
		part_id: 54,
		quantity: 17,
	},
	{
		garage_id: 163,
		part_id: 55,
		quantity: 81,
	},
	{
		garage_id: 163,
		part_id: 56,
		quantity: 50,
	},
	{
		garage_id: 163,
		part_id: 57,
		quantity: 268,
	},
	{
		garage_id: 163,
		part_id: 58,
		quantity: 36,
	},
	{
		garage_id: 163,
		part_id: 59,
		quantity: 383,
	},
	{
		garage_id: 163,
		part_id: 60,
		quantity: 374,
	},
	{
		garage_id: 163,
		part_id: 61,
		quantity: 422,
	},
	{
		garage_id: 163,
		part_id: 62,
		quantity: 1,
	},
	{
		garage_id: 163,
		part_id: 63,
		quantity: 227,
	},
	{
		garage_id: 163,
		part_id: 64,
		quantity: 62,
	},
	{
		garage_id: 163,
		part_id: 65,
		quantity: 262,
	},
	{
		garage_id: 163,
		part_id: 66,
		quantity: 377,
	},
	{
		garage_id: 163,
		part_id: 67,
		quantity: 299,
	},
	{
		garage_id: 163,
		part_id: 68,
		quantity: 445,
	},
	{
		garage_id: 163,
		part_id: 69,
		quantity: 259,
	},
	{
		garage_id: 163,
		part_id: 70,
		quantity: 129,
	},
	{
		garage_id: 163,
		part_id: 71,
		quantity: 82,
	},
	{
		garage_id: 163,
		part_id: 72,
		quantity: 146,
	},
	{
		garage_id: 163,
		part_id: 73,
		quantity: 481,
	},
	{
		garage_id: 163,
		part_id: 74,
		quantity: 336,
	},
	{
		garage_id: 163,
		part_id: 75,
		quantity: 317,
	},
	{
		garage_id: 163,
		part_id: 76,
		quantity: 413,
	},
	{
		garage_id: 163,
		part_id: 77,
		quantity: 236,
	},
	{
		garage_id: 163,
		part_id: 78,
		quantity: 452,
	},
	{
		garage_id: 163,
		part_id: 79,
		quantity: 99,
	},
	{
		garage_id: 163,
		part_id: 80,
		quantity: 46,
	},
	{
		garage_id: 163,
		part_id: 81,
		quantity: 388,
	},
	{
		garage_id: 163,
		part_id: 82,
		quantity: 283,
	},
	{
		garage_id: 163,
		part_id: 83,
		quantity: 393,
	},
	{
		garage_id: 163,
		part_id: 84,
		quantity: 77,
	},
	{
		garage_id: 163,
		part_id: 85,
		quantity: 323,
	},
	{
		garage_id: 163,
		part_id: 86,
		quantity: 409,
	},
	{
		garage_id: 163,
		part_id: 87,
		quantity: 60,
	},
	{
		garage_id: 163,
		part_id: 88,
		quantity: 326,
	},
	{
		garage_id: 163,
		part_id: 89,
		quantity: 71,
	},
	{
		garage_id: 163,
		part_id: 90,
		quantity: 59,
	},
	{
		garage_id: 163,
		part_id: 91,
		quantity: 417,
	},
	{
		garage_id: 163,
		part_id: 92,
		quantity: 158,
	},
	{
		garage_id: 163,
		part_id: 93,
		quantity: 80,
	},
	{
		garage_id: 163,
		part_id: 94,
		quantity: 360,
	},
	{
		garage_id: 163,
		part_id: 95,
		quantity: 371,
	},
	{
		garage_id: 163,
		part_id: 96,
		quantity: 411,
	},
	{
		garage_id: 163,
		part_id: 97,
		quantity: 243,
	},
	{
		garage_id: 163,
		part_id: 98,
		quantity: 447,
	},
	{
		garage_id: 163,
		part_id: 99,
		quantity: 262,
	},
	{
		garage_id: 163,
		part_id: 100,
		quantity: 285,
	},
	{
		garage_id: 163,
		part_id: 101,
		quantity: 415,
	},
	{
		garage_id: 163,
		part_id: 102,
		quantity: 40,
	},
	{
		garage_id: 163,
		part_id: 103,
		quantity: 404,
	},
	{
		garage_id: 163,
		part_id: 104,
		quantity: 246,
	},
	{
		garage_id: 163,
		part_id: 105,
		quantity: 111,
	},
	{
		garage_id: 163,
		part_id: 106,
		quantity: 71,
	},
	{
		garage_id: 163,
		part_id: 107,
		quantity: 366,
	},
	{
		garage_id: 163,
		part_id: 108,
		quantity: 333,
	},
	{
		garage_id: 163,
		part_id: 109,
		quantity: 415,
	},
	{
		garage_id: 163,
		part_id: 110,
		quantity: 225,
	},
	{
		garage_id: 163,
		part_id: 111,
		quantity: 125,
	},
	{
		garage_id: 163,
		part_id: 112,
		quantity: 161,
	},
	{
		garage_id: 163,
		part_id: 113,
		quantity: 273,
	},
	{
		garage_id: 163,
		part_id: 114,
		quantity: 214,
	},
	{
		garage_id: 163,
		part_id: 115,
		quantity: 55,
	},
	{
		garage_id: 163,
		part_id: 116,
		quantity: 283,
	},
	{
		garage_id: 163,
		part_id: 117,
		quantity: 42,
	},
	{
		garage_id: 163,
		part_id: 118,
		quantity: 448,
	},
	{
		garage_id: 163,
		part_id: 119,
		quantity: 400,
	},
	{
		garage_id: 163,
		part_id: 120,
		quantity: 469,
	},
	{
		garage_id: 163,
		part_id: 121,
		quantity: 420,
	},
	{
		garage_id: 163,
		part_id: 122,
		quantity: 308,
	},
	{
		garage_id: 163,
		part_id: 123,
		quantity: 482,
	},
	{
		garage_id: 163,
		part_id: 124,
		quantity: 496,
	},
	{
		garage_id: 163,
		part_id: 125,
		quantity: 196,
	},
	{
		garage_id: 163,
		part_id: 126,
		quantity: 430,
	},
	{
		garage_id: 163,
		part_id: 127,
		quantity: 342,
	},
	{
		garage_id: 163,
		part_id: 128,
		quantity: 453,
	},
	{
		garage_id: 163,
		part_id: 129,
		quantity: 369,
	},
	{
		garage_id: 163,
		part_id: 130,
		quantity: 197,
	},
	{
		garage_id: 163,
		part_id: 131,
		quantity: 337,
	},
	{
		garage_id: 163,
		part_id: 132,
		quantity: 226,
	},
	{
		garage_id: 163,
		part_id: 133,
		quantity: 310,
	},
	{
		garage_id: 163,
		part_id: 134,
		quantity: 313,
	},
	{
		garage_id: 163,
		part_id: 135,
		quantity: 259,
	},
	{
		garage_id: 163,
		part_id: 136,
		quantity: 9,
	},
	{
		garage_id: 163,
		part_id: 137,
		quantity: 395,
	},
	{
		garage_id: 163,
		part_id: 138,
		quantity: 348,
	},
	{
		garage_id: 163,
		part_id: 139,
		quantity: 352,
	},
	{
		garage_id: 163,
		part_id: 140,
		quantity: 88,
	},
	{
		garage_id: 163,
		part_id: 141,
		quantity: 309,
	},
	{
		garage_id: 163,
		part_id: 142,
		quantity: 153,
	},
	{
		garage_id: 163,
		part_id: 143,
		quantity: 117,
	},
	{
		garage_id: 163,
		part_id: 144,
		quantity: 360,
	},
	{
		garage_id: 163,
		part_id: 145,
		quantity: 80,
	},
	{
		garage_id: 163,
		part_id: 146,
		quantity: 263,
	},
	{
		garage_id: 163,
		part_id: 147,
		quantity: 116,
	},
	{
		garage_id: 163,
		part_id: 148,
		quantity: 316,
	},
	{
		garage_id: 163,
		part_id: 149,
		quantity: 365,
	},
	{
		garage_id: 163,
		part_id: 150,
		quantity: 377,
	},
	{
		garage_id: 163,
		part_id: 151,
		quantity: 166,
	},
	{
		garage_id: 163,
		part_id: 152,
		quantity: 395,
	},
	{
		garage_id: 163,
		part_id: 153,
		quantity: 474,
	},
	{
		garage_id: 163,
		part_id: 154,
		quantity: 27,
	},
	{
		garage_id: 163,
		part_id: 155,
		quantity: 209,
	},
	{
		garage_id: 163,
		part_id: 156,
		quantity: 404,
	},
	{
		garage_id: 163,
		part_id: 157,
		quantity: 393,
	},
	{
		garage_id: 163,
		part_id: 158,
		quantity: 118,
	},
	{
		garage_id: 163,
		part_id: 159,
		quantity: 472,
	},
	{
		garage_id: 163,
		part_id: 160,
		quantity: 187,
	},
	{
		garage_id: 163,
		part_id: 161,
		quantity: 370,
	},
	{
		garage_id: 163,
		part_id: 162,
		quantity: 28,
	},
	{
		garage_id: 163,
		part_id: 163,
		quantity: 93,
	},
	{
		garage_id: 163,
		part_id: 164,
		quantity: 198,
	},
	{
		garage_id: 163,
		part_id: 165,
		quantity: 460,
	},
	{
		garage_id: 163,
		part_id: 166,
		quantity: 469,
	},
	{
		garage_id: 163,
		part_id: 167,
		quantity: 147,
	},
	{
		garage_id: 163,
		part_id: 168,
		quantity: 172,
	},
	{
		garage_id: 163,
		part_id: 169,
		quantity: 229,
	},
	{
		garage_id: 163,
		part_id: 170,
		quantity: 488,
	},
	{
		garage_id: 163,
		part_id: 171,
		quantity: 304,
	},
	{
		garage_id: 163,
		part_id: 172,
		quantity: 147,
	},
	{
		garage_id: 163,
		part_id: 173,
		quantity: 284,
	},
	{
		garage_id: 163,
		part_id: 174,
		quantity: 451,
	},
	{
		garage_id: 163,
		part_id: 175,
		quantity: 271,
	},
	{
		garage_id: 163,
		part_id: 176,
		quantity: 99,
	},
	{
		garage_id: 163,
		part_id: 177,
		quantity: 405,
	},
	{
		garage_id: 163,
		part_id: 178,
		quantity: 368,
	},
	{
		garage_id: 163,
		part_id: 179,
		quantity: 89,
	},
	{
		garage_id: 163,
		part_id: 180,
		quantity: 187,
	},
	{
		garage_id: 163,
		part_id: 181,
		quantity: 20,
	},
	{
		garage_id: 163,
		part_id: 182,
		quantity: 163,
	},
	{
		garage_id: 163,
		part_id: 183,
		quantity: 87,
	},
	{
		garage_id: 163,
		part_id: 184,
		quantity: 338,
	},
	{
		garage_id: 163,
		part_id: 185,
		quantity: 20,
	},
	{
		garage_id: 163,
		part_id: 186,
		quantity: 265,
	},
	{
		garage_id: 163,
		part_id: 187,
		quantity: 120,
	},
	{
		garage_id: 163,
		part_id: 188,
		quantity: 266,
	},
	{
		garage_id: 163,
		part_id: 189,
		quantity: 189,
	},
	{
		garage_id: 163,
		part_id: 190,
		quantity: 82,
	},
	{
		garage_id: 163,
		part_id: 191,
		quantity: 175,
	},
	{
		garage_id: 163,
		part_id: 192,
		quantity: 187,
	},
	{
		garage_id: 163,
		part_id: 193,
		quantity: 160,
	},
	{
		garage_id: 163,
		part_id: 194,
		quantity: 396,
	},
	{
		garage_id: 163,
		part_id: 195,
		quantity: 484,
	},
	{
		garage_id: 163,
		part_id: 196,
		quantity: 126,
	},
	{
		garage_id: 163,
		part_id: 197,
		quantity: 153,
	},
	{
		garage_id: 163,
		part_id: 198,
		quantity: 430,
	},
	{
		garage_id: 163,
		part_id: 199,
		quantity: 351,
	},
	{
		garage_id: 163,
		part_id: 200,
		quantity: 151,
	},
	{
		garage_id: 163,
		part_id: 201,
		quantity: 301,
	},
	{
		garage_id: 163,
		part_id: 202,
		quantity: 14,
	},
	{
		garage_id: 163,
		part_id: 203,
		quantity: 67,
	},
	{
		garage_id: 163,
		part_id: 204,
		quantity: 270,
	},
	{
		garage_id: 163,
		part_id: 205,
		quantity: 141,
	},
	{
		garage_id: 163,
		part_id: 206,
		quantity: 29,
	},
	{
		garage_id: 163,
		part_id: 207,
		quantity: 397,
	},
	{
		garage_id: 163,
		part_id: 208,
		quantity: 311,
	},
	{
		garage_id: 163,
		part_id: 209,
		quantity: 389,
	},
	{
		garage_id: 163,
		part_id: 210,
		quantity: 391,
	},
	{
		garage_id: 163,
		part_id: 211,
		quantity: 215,
	},
	{
		garage_id: 163,
		part_id: 212,
		quantity: 449,
	},
	{
		garage_id: 163,
		part_id: 213,
		quantity: 135,
	},
	{
		garage_id: 163,
		part_id: 214,
		quantity: 418,
	},
	{
		garage_id: 163,
		part_id: 215,
		quantity: 341,
	},
	{
		garage_id: 163,
		part_id: 216,
		quantity: 90,
	},
	{
		garage_id: 163,
		part_id: 217,
		quantity: 126,
	},
	{
		garage_id: 163,
		part_id: 218,
		quantity: 299,
	},
	{
		garage_id: 163,
		part_id: 219,
		quantity: 64,
	},
	{
		garage_id: 163,
		part_id: 220,
		quantity: 360,
	},
	{
		garage_id: 163,
		part_id: 221,
		quantity: 246,
	},
	{
		garage_id: 163,
		part_id: 222,
		quantity: 226,
	},
	{
		garage_id: 163,
		part_id: 223,
		quantity: 273,
	},
	{
		garage_id: 163,
		part_id: 224,
		quantity: 429,
	},
	{
		garage_id: 163,
		part_id: 225,
		quantity: 106,
	},
	{
		garage_id: 163,
		part_id: 226,
		quantity: 347,
	},
	{
		garage_id: 163,
		part_id: 227,
		quantity: 70,
	},
	{
		garage_id: 163,
		part_id: 228,
		quantity: 330,
	},
	{
		garage_id: 163,
		part_id: 229,
		quantity: 7,
	},
	{
		garage_id: 163,
		part_id: 230,
		quantity: 111,
	},
	{
		garage_id: 163,
		part_id: 231,
		quantity: 146,
	},
	{
		garage_id: 163,
		part_id: 232,
		quantity: 85,
	},
	{
		garage_id: 163,
		part_id: 233,
		quantity: 225,
	},
	{
		garage_id: 163,
		part_id: 234,
		quantity: 47,
	},
	{
		garage_id: 163,
		part_id: 235,
		quantity: 356,
	},
	{
		garage_id: 163,
		part_id: 236,
		quantity: 165,
	},
	{
		garage_id: 163,
		part_id: 237,
		quantity: 216,
	},
	{
		garage_id: 163,
		part_id: 238,
		quantity: 265,
	},
	{
		garage_id: 163,
		part_id: 239,
		quantity: 174,
	},
	{
		garage_id: 163,
		part_id: 240,
		quantity: 76,
	},
	{
		garage_id: 163,
		part_id: 241,
		quantity: 493,
	},
	{
		garage_id: 163,
		part_id: 242,
		quantity: 99,
	},
	{
		garage_id: 163,
		part_id: 243,
		quantity: 496,
	},
	{
		garage_id: 163,
		part_id: 244,
		quantity: 339,
	},
	{
		garage_id: 163,
		part_id: 245,
		quantity: 495,
	},
	{
		garage_id: 163,
		part_id: 246,
		quantity: 13,
	},
	{
		garage_id: 163,
		part_id: 247,
		quantity: 411,
	},
	{
		garage_id: 163,
		part_id: 248,
		quantity: 48,
	},
	{
		garage_id: 163,
		part_id: 249,
		quantity: 215,
	},
	{
		garage_id: 163,
		part_id: 250,
		quantity: 413,
	},
	{
		garage_id: 163,
		part_id: 251,
		quantity: 193,
	},
	{
		garage_id: 163,
		part_id: 252,
		quantity: 273,
	},
	{
		garage_id: 163,
		part_id: 253,
		quantity: 0,
	},
	{
		garage_id: 163,
		part_id: 254,
		quantity: 3,
	},
	{
		garage_id: 163,
		part_id: 255,
		quantity: 416,
	},
	{
		garage_id: 163,
		part_id: 256,
		quantity: 389,
	},
	{
		garage_id: 163,
		part_id: 257,
		quantity: 376,
	},
	{
		garage_id: 163,
		part_id: 258,
		quantity: 118,
	},
	{
		garage_id: 163,
		part_id: 259,
		quantity: 196,
	},
	{
		garage_id: 163,
		part_id: 260,
		quantity: 497,
	},
	{
		garage_id: 163,
		part_id: 261,
		quantity: 169,
	},
	{
		garage_id: 163,
		part_id: 262,
		quantity: 52,
	},
	{
		garage_id: 163,
		part_id: 263,
		quantity: 216,
	},
	{
		garage_id: 163,
		part_id: 264,
		quantity: 272,
	},
	{
		garage_id: 163,
		part_id: 265,
		quantity: 323,
	},
	{
		garage_id: 163,
		part_id: 266,
		quantity: 69,
	},
	{
		garage_id: 163,
		part_id: 267,
		quantity: 188,
	},
	{
		garage_id: 163,
		part_id: 268,
		quantity: 223,
	},
	{
		garage_id: 163,
		part_id: 269,
		quantity: 331,
	},
	{
		garage_id: 163,
		part_id: 270,
		quantity: 119,
	},
	{
		garage_id: 163,
		part_id: 271,
		quantity: 62,
	},
	{
		garage_id: 163,
		part_id: 272,
		quantity: 151,
	},
	{
		garage_id: 163,
		part_id: 273,
		quantity: 147,
	},
	{
		garage_id: 163,
		part_id: 274,
		quantity: 342,
	},
	{
		garage_id: 163,
		part_id: 275,
		quantity: 486,
	},
	{
		garage_id: 163,
		part_id: 276,
		quantity: 484,
	},
	{
		garage_id: 163,
		part_id: 277,
		quantity: 352,
	},
	{
		garage_id: 163,
		part_id: 278,
		quantity: 402,
	},
	{
		garage_id: 163,
		part_id: 279,
		quantity: 454,
	},
	{
		garage_id: 163,
		part_id: 280,
		quantity: 402,
	},
	{
		garage_id: 163,
		part_id: 281,
		quantity: 339,
	},
	{
		garage_id: 163,
		part_id: 282,
		quantity: 288,
	},
	{
		garage_id: 163,
		part_id: 283,
		quantity: 384,
	},
	{
		garage_id: 163,
		part_id: 284,
		quantity: 415,
	},
	{
		garage_id: 163,
		part_id: 285,
		quantity: 437,
	},
	{
		garage_id: 163,
		part_id: 286,
		quantity: 427,
	},
	{
		garage_id: 163,
		part_id: 287,
		quantity: 225,
	},
	{
		garage_id: 163,
		part_id: 288,
		quantity: 113,
	},
	{
		garage_id: 163,
		part_id: 289,
		quantity: 141,
	},
	{
		garage_id: 163,
		part_id: 290,
		quantity: 37,
	},
	{
		garage_id: 163,
		part_id: 291,
		quantity: 431,
	},
	{
		garage_id: 163,
		part_id: 292,
		quantity: 336,
	},
	{
		garage_id: 163,
		part_id: 293,
		quantity: 380,
	},
	{
		garage_id: 163,
		part_id: 294,
		quantity: 217,
	},
	{
		garage_id: 163,
		part_id: 295,
		quantity: 499,
	},
	{
		garage_id: 163,
		part_id: 296,
		quantity: 38,
	},
	{
		garage_id: 163,
		part_id: 297,
		quantity: 109,
	},
	{
		garage_id: 163,
		part_id: 298,
		quantity: 261,
	},
	{
		garage_id: 163,
		part_id: 299,
		quantity: 83,
	},
	{
		garage_id: 163,
		part_id: 300,
		quantity: 362,
	},
	{
		garage_id: 163,
		part_id: 301,
		quantity: 240,
	},
	{
		garage_id: 163,
		part_id: 302,
		quantity: 471,
	},
	{
		garage_id: 163,
		part_id: 303,
		quantity: 285,
	},
	{
		garage_id: 163,
		part_id: 304,
		quantity: 228,
	},
	{
		garage_id: 163,
		part_id: 305,
		quantity: 33,
	},
	{
		garage_id: 163,
		part_id: 306,
		quantity: 402,
	},
	{
		garage_id: 163,
		part_id: 307,
		quantity: 441,
	},
	{
		garage_id: 163,
		part_id: 308,
		quantity: 377,
	},
	{
		garage_id: 163,
		part_id: 309,
		quantity: 262,
	},
	{
		garage_id: 163,
		part_id: 310,
		quantity: 385,
	},
	{
		garage_id: 163,
		part_id: 311,
		quantity: 229,
	},
	{
		garage_id: 163,
		part_id: 312,
		quantity: 486,
	},
	{
		garage_id: 163,
		part_id: 313,
		quantity: 492,
	},
	{
		garage_id: 163,
		part_id: 314,
		quantity: 436,
	},
	{
		garage_id: 163,
		part_id: 315,
		quantity: 386,
	},
	{
		garage_id: 163,
		part_id: 316,
		quantity: 126,
	},
	{
		garage_id: 163,
		part_id: 317,
		quantity: 62,
	},
	{
		garage_id: 163,
		part_id: 318,
		quantity: 322,
	},
	{
		garage_id: 163,
		part_id: 319,
		quantity: 14,
	},
	{
		garage_id: 163,
		part_id: 320,
		quantity: 256,
	},
	{
		garage_id: 163,
		part_id: 321,
		quantity: 451,
	},
	{
		garage_id: 163,
		part_id: 322,
		quantity: 434,
	},
	{
		garage_id: 163,
		part_id: 323,
		quantity: 357,
	},
	{
		garage_id: 163,
		part_id: 324,
		quantity: 272,
	},
	{
		garage_id: 163,
		part_id: 325,
		quantity: 338,
	},
	{
		garage_id: 163,
		part_id: 326,
		quantity: 117,
	},
	{
		garage_id: 163,
		part_id: 327,
		quantity: 439,
	},
	{
		garage_id: 163,
		part_id: 328,
		quantity: 301,
	},
	{
		garage_id: 163,
		part_id: 329,
		quantity: 210,
	},
	{
		garage_id: 163,
		part_id: 330,
		quantity: 257,
	},
	{
		garage_id: 163,
		part_id: 331,
		quantity: 66,
	},
	{
		garage_id: 163,
		part_id: 332,
		quantity: 285,
	},
	{
		garage_id: 163,
		part_id: 333,
		quantity: 402,
	},
	{
		garage_id: 163,
		part_id: 334,
		quantity: 223,
	},
	{
		garage_id: 163,
		part_id: 335,
		quantity: 313,
	},
	{
		garage_id: 163,
		part_id: 336,
		quantity: 463,
	},
	{
		garage_id: 163,
		part_id: 337,
		quantity: 439,
	},
	{
		garage_id: 163,
		part_id: 338,
		quantity: 287,
	},
	{
		garage_id: 163,
		part_id: 339,
		quantity: 333,
	},
	{
		garage_id: 163,
		part_id: 340,
		quantity: 367,
	},
	{
		garage_id: 163,
		part_id: 341,
		quantity: 485,
	},
	{
		garage_id: 163,
		part_id: 342,
		quantity: 208,
	},
	{
		garage_id: 163,
		part_id: 343,
		quantity: 241,
	},
	{
		garage_id: 163,
		part_id: 344,
		quantity: 334,
	},
	{
		garage_id: 163,
		part_id: 345,
		quantity: 312,
	},
	{
		garage_id: 163,
		part_id: 346,
		quantity: 418,
	},
	{
		garage_id: 163,
		part_id: 347,
		quantity: 79,
	},
	{
		garage_id: 163,
		part_id: 348,
		quantity: 378,
	},
	{
		garage_id: 163,
		part_id: 349,
		quantity: 3,
	},
	{
		garage_id: 163,
		part_id: 350,
		quantity: 354,
	},
	{
		garage_id: 163,
		part_id: 351,
		quantity: 409,
	},
	{
		garage_id: 163,
		part_id: 352,
		quantity: 397,
	},
	{
		garage_id: 163,
		part_id: 353,
		quantity: 203,
	},
	{
		garage_id: 163,
		part_id: 354,
		quantity: 337,
	},
	{
		garage_id: 163,
		part_id: 355,
		quantity: 418,
	},
	{
		garage_id: 163,
		part_id: 356,
		quantity: 247,
	},
	{
		garage_id: 163,
		part_id: 357,
		quantity: 378,
	},
	{
		garage_id: 163,
		part_id: 358,
		quantity: 474,
	},
	{
		garage_id: 163,
		part_id: 359,
		quantity: 2,
	},
	{
		garage_id: 163,
		part_id: 360,
		quantity: 298,
	},
	{
		garage_id: 163,
		part_id: 361,
		quantity: 233,
	},
	{
		garage_id: 163,
		part_id: 362,
		quantity: 143,
	},
	{
		garage_id: 163,
		part_id: 363,
		quantity: 410,
	},
	{
		garage_id: 163,
		part_id: 364,
		quantity: 288,
	},
	{
		garage_id: 163,
		part_id: 365,
		quantity: 447,
	},
	{
		garage_id: 163,
		part_id: 366,
		quantity: 175,
	},
	{
		garage_id: 163,
		part_id: 367,
		quantity: 94,
	},
	{
		garage_id: 163,
		part_id: 368,
		quantity: 53,
	},
	{
		garage_id: 163,
		part_id: 369,
		quantity: 351,
	},
	{
		garage_id: 163,
		part_id: 370,
		quantity: 18,
	},
	{
		garage_id: 163,
		part_id: 371,
		quantity: 36,
	},
	{
		garage_id: 163,
		part_id: 372,
		quantity: 471,
	},
	{
		garage_id: 163,
		part_id: 373,
		quantity: 425,
	},
	{
		garage_id: 163,
		part_id: 374,
		quantity: 8,
	},
	{
		garage_id: 163,
		part_id: 375,
		quantity: 294,
	},
	{
		garage_id: 163,
		part_id: 376,
		quantity: 200,
	},
	{
		garage_id: 163,
		part_id: 377,
		quantity: 239,
	},
	{
		garage_id: 163,
		part_id: 378,
		quantity: 455,
	},
	{
		garage_id: 163,
		part_id: 379,
		quantity: 406,
	},
	{
		garage_id: 163,
		part_id: 380,
		quantity: 81,
	},
	{
		garage_id: 163,
		part_id: 381,
		quantity: 401,
	},
	{
		garage_id: 163,
		part_id: 382,
		quantity: 299,
	},
	{
		garage_id: 163,
		part_id: 383,
		quantity: 366,
	},
	{
		garage_id: 163,
		part_id: 384,
		quantity: 445,
	},
	{
		garage_id: 163,
		part_id: 385,
		quantity: 420,
	},
	{
		garage_id: 163,
		part_id: 386,
		quantity: 49,
	},
	{
		garage_id: 163,
		part_id: 387,
		quantity: 135,
	},
	{
		garage_id: 163,
		part_id: 388,
		quantity: 88,
	},
	{
		garage_id: 163,
		part_id: 389,
		quantity: 274,
	},
	{
		garage_id: 163,
		part_id: 390,
		quantity: 214,
	},
	{
		garage_id: 163,
		part_id: 391,
		quantity: 472,
	},
	{
		garage_id: 163,
		part_id: 392,
		quantity: 442,
	},
	{
		garage_id: 163,
		part_id: 393,
		quantity: 122,
	},
	{
		garage_id: 163,
		part_id: 394,
		quantity: 210,
	},
	{
		garage_id: 163,
		part_id: 395,
		quantity: 465,
	},
	{
		garage_id: 163,
		part_id: 396,
		quantity: 394,
	},
	{
		garage_id: 163,
		part_id: 397,
		quantity: 151,
	},
	{
		garage_id: 163,
		part_id: 398,
		quantity: 92,
	},
	{
		garage_id: 163,
		part_id: 399,
		quantity: 328,
	},
	{
		garage_id: 164,
		part_id: 0,
		quantity: 394,
	},
	{
		garage_id: 164,
		part_id: 1,
		quantity: 113,
	},
	{
		garage_id: 164,
		part_id: 2,
		quantity: 268,
	},
	{
		garage_id: 164,
		part_id: 3,
		quantity: 235,
	},
	{
		garage_id: 164,
		part_id: 4,
		quantity: 445,
	},
	{
		garage_id: 164,
		part_id: 5,
		quantity: 33,
	},
	{
		garage_id: 164,
		part_id: 6,
		quantity: 228,
	},
	{
		garage_id: 164,
		part_id: 7,
		quantity: 479,
	},
	{
		garage_id: 164,
		part_id: 8,
		quantity: 482,
	},
	{
		garage_id: 164,
		part_id: 9,
		quantity: 405,
	},
	{
		garage_id: 164,
		part_id: 10,
		quantity: 202,
	},
	{
		garage_id: 164,
		part_id: 11,
		quantity: 134,
	},
	{
		garage_id: 164,
		part_id: 12,
		quantity: 234,
	},
	{
		garage_id: 164,
		part_id: 13,
		quantity: 73,
	},
	{
		garage_id: 164,
		part_id: 14,
		quantity: 175,
	},
	{
		garage_id: 164,
		part_id: 15,
		quantity: 20,
	},
	{
		garage_id: 164,
		part_id: 16,
		quantity: 409,
	},
	{
		garage_id: 164,
		part_id: 17,
		quantity: 449,
	},
	{
		garage_id: 164,
		part_id: 18,
		quantity: 470,
	},
	{
		garage_id: 164,
		part_id: 19,
		quantity: 400,
	},
	{
		garage_id: 164,
		part_id: 20,
		quantity: 90,
	},
	{
		garage_id: 164,
		part_id: 21,
		quantity: 476,
	},
	{
		garage_id: 164,
		part_id: 22,
		quantity: 92,
	},
	{
		garage_id: 164,
		part_id: 23,
		quantity: 230,
	},
	{
		garage_id: 164,
		part_id: 24,
		quantity: 69,
	},
	{
		garage_id: 164,
		part_id: 25,
		quantity: 106,
	},
	{
		garage_id: 164,
		part_id: 26,
		quantity: 1,
	},
	{
		garage_id: 164,
		part_id: 27,
		quantity: 271,
	},
	{
		garage_id: 164,
		part_id: 28,
		quantity: 419,
	},
	{
		garage_id: 164,
		part_id: 29,
		quantity: 397,
	},
	{
		garage_id: 164,
		part_id: 30,
		quantity: 143,
	},
	{
		garage_id: 164,
		part_id: 31,
		quantity: 28,
	},
	{
		garage_id: 164,
		part_id: 32,
		quantity: 163,
	},
	{
		garage_id: 164,
		part_id: 33,
		quantity: 62,
	},
	{
		garage_id: 164,
		part_id: 34,
		quantity: 4,
	},
	{
		garage_id: 164,
		part_id: 35,
		quantity: 395,
	},
	{
		garage_id: 164,
		part_id: 36,
		quantity: 277,
	},
	{
		garage_id: 164,
		part_id: 37,
		quantity: 481,
	},
	{
		garage_id: 164,
		part_id: 38,
		quantity: 8,
	},
	{
		garage_id: 164,
		part_id: 39,
		quantity: 272,
	},
	{
		garage_id: 164,
		part_id: 40,
		quantity: 409,
	},
	{
		garage_id: 164,
		part_id: 41,
		quantity: 363,
	},
	{
		garage_id: 164,
		part_id: 42,
		quantity: 242,
	},
	{
		garage_id: 164,
		part_id: 43,
		quantity: 81,
	},
	{
		garage_id: 164,
		part_id: 44,
		quantity: 487,
	},
	{
		garage_id: 164,
		part_id: 45,
		quantity: 418,
	},
	{
		garage_id: 164,
		part_id: 46,
		quantity: 413,
	},
	{
		garage_id: 164,
		part_id: 47,
		quantity: 95,
	},
	{
		garage_id: 164,
		part_id: 48,
		quantity: 349,
	},
	{
		garage_id: 164,
		part_id: 49,
		quantity: 167,
	},
	{
		garage_id: 164,
		part_id: 50,
		quantity: 371,
	},
	{
		garage_id: 164,
		part_id: 51,
		quantity: 311,
	},
	{
		garage_id: 164,
		part_id: 52,
		quantity: 376,
	},
	{
		garage_id: 164,
		part_id: 53,
		quantity: 398,
	},
	{
		garage_id: 164,
		part_id: 54,
		quantity: 106,
	},
	{
		garage_id: 164,
		part_id: 55,
		quantity: 367,
	},
	{
		garage_id: 164,
		part_id: 56,
		quantity: 162,
	},
	{
		garage_id: 164,
		part_id: 57,
		quantity: 169,
	},
	{
		garage_id: 164,
		part_id: 58,
		quantity: 464,
	},
	{
		garage_id: 164,
		part_id: 59,
		quantity: 186,
	},
	{
		garage_id: 164,
		part_id: 60,
		quantity: 152,
	},
	{
		garage_id: 164,
		part_id: 61,
		quantity: 444,
	},
	{
		garage_id: 164,
		part_id: 62,
		quantity: 405,
	},
	{
		garage_id: 164,
		part_id: 63,
		quantity: 392,
	},
	{
		garage_id: 164,
		part_id: 64,
		quantity: 131,
	},
	{
		garage_id: 164,
		part_id: 65,
		quantity: 340,
	},
	{
		garage_id: 164,
		part_id: 66,
		quantity: 197,
	},
	{
		garage_id: 164,
		part_id: 67,
		quantity: 155,
	},
	{
		garage_id: 164,
		part_id: 68,
		quantity: 204,
	},
	{
		garage_id: 164,
		part_id: 69,
		quantity: 200,
	},
	{
		garage_id: 164,
		part_id: 70,
		quantity: 475,
	},
	{
		garage_id: 164,
		part_id: 71,
		quantity: 340,
	},
	{
		garage_id: 164,
		part_id: 72,
		quantity: 137,
	},
	{
		garage_id: 164,
		part_id: 73,
		quantity: 394,
	},
	{
		garage_id: 164,
		part_id: 74,
		quantity: 449,
	},
	{
		garage_id: 164,
		part_id: 75,
		quantity: 174,
	},
	{
		garage_id: 164,
		part_id: 76,
		quantity: 249,
	},
	{
		garage_id: 164,
		part_id: 77,
		quantity: 24,
	},
	{
		garage_id: 164,
		part_id: 78,
		quantity: 14,
	},
	{
		garage_id: 164,
		part_id: 79,
		quantity: 340,
	},
	{
		garage_id: 164,
		part_id: 80,
		quantity: 482,
	},
	{
		garage_id: 164,
		part_id: 81,
		quantity: 329,
	},
	{
		garage_id: 164,
		part_id: 82,
		quantity: 432,
	},
	{
		garage_id: 164,
		part_id: 83,
		quantity: 5,
	},
	{
		garage_id: 164,
		part_id: 84,
		quantity: 188,
	},
	{
		garage_id: 164,
		part_id: 85,
		quantity: 425,
	},
	{
		garage_id: 164,
		part_id: 86,
		quantity: 364,
	},
	{
		garage_id: 164,
		part_id: 87,
		quantity: 418,
	},
	{
		garage_id: 164,
		part_id: 88,
		quantity: 417,
	},
	{
		garage_id: 164,
		part_id: 89,
		quantity: 494,
	},
	{
		garage_id: 164,
		part_id: 90,
		quantity: 370,
	},
	{
		garage_id: 164,
		part_id: 91,
		quantity: 498,
	},
	{
		garage_id: 164,
		part_id: 92,
		quantity: 75,
	},
	{
		garage_id: 164,
		part_id: 93,
		quantity: 19,
	},
	{
		garage_id: 164,
		part_id: 94,
		quantity: 185,
	},
	{
		garage_id: 164,
		part_id: 95,
		quantity: 417,
	},
	{
		garage_id: 164,
		part_id: 96,
		quantity: 254,
	},
	{
		garage_id: 164,
		part_id: 97,
		quantity: 245,
	},
	{
		garage_id: 164,
		part_id: 98,
		quantity: 83,
	},
	{
		garage_id: 164,
		part_id: 99,
		quantity: 262,
	},
	{
		garage_id: 164,
		part_id: 100,
		quantity: 153,
	},
	{
		garage_id: 164,
		part_id: 101,
		quantity: 437,
	},
	{
		garage_id: 164,
		part_id: 102,
		quantity: 437,
	},
	{
		garage_id: 164,
		part_id: 103,
		quantity: 241,
	},
	{
		garage_id: 164,
		part_id: 104,
		quantity: 352,
	},
	{
		garage_id: 164,
		part_id: 105,
		quantity: 252,
	},
	{
		garage_id: 164,
		part_id: 106,
		quantity: 247,
	},
	{
		garage_id: 164,
		part_id: 107,
		quantity: 105,
	},
	{
		garage_id: 164,
		part_id: 108,
		quantity: 369,
	},
	{
		garage_id: 164,
		part_id: 109,
		quantity: 215,
	},
	{
		garage_id: 164,
		part_id: 110,
		quantity: 352,
	},
	{
		garage_id: 164,
		part_id: 111,
		quantity: 324,
	},
	{
		garage_id: 164,
		part_id: 112,
		quantity: 226,
	},
	{
		garage_id: 164,
		part_id: 113,
		quantity: 287,
	},
	{
		garage_id: 164,
		part_id: 114,
		quantity: 86,
	},
	{
		garage_id: 164,
		part_id: 115,
		quantity: 275,
	},
	{
		garage_id: 164,
		part_id: 116,
		quantity: 470,
	},
	{
		garage_id: 164,
		part_id: 117,
		quantity: 290,
	},
	{
		garage_id: 164,
		part_id: 118,
		quantity: 356,
	},
	{
		garage_id: 164,
		part_id: 119,
		quantity: 143,
	},
	{
		garage_id: 164,
		part_id: 120,
		quantity: 19,
	},
	{
		garage_id: 164,
		part_id: 121,
		quantity: 209,
	},
	{
		garage_id: 164,
		part_id: 122,
		quantity: 11,
	},
	{
		garage_id: 164,
		part_id: 123,
		quantity: 156,
	},
	{
		garage_id: 164,
		part_id: 124,
		quantity: 63,
	},
	{
		garage_id: 164,
		part_id: 125,
		quantity: 161,
	},
	{
		garage_id: 164,
		part_id: 126,
		quantity: 157,
	},
	{
		garage_id: 164,
		part_id: 127,
		quantity: 290,
	},
	{
		garage_id: 164,
		part_id: 128,
		quantity: 43,
	},
	{
		garage_id: 164,
		part_id: 129,
		quantity: 28,
	},
	{
		garage_id: 164,
		part_id: 130,
		quantity: 124,
	},
	{
		garage_id: 164,
		part_id: 131,
		quantity: 490,
	},
	{
		garage_id: 164,
		part_id: 132,
		quantity: 343,
	},
	{
		garage_id: 164,
		part_id: 133,
		quantity: 168,
	},
	{
		garage_id: 164,
		part_id: 134,
		quantity: 376,
	},
	{
		garage_id: 164,
		part_id: 135,
		quantity: 421,
	},
	{
		garage_id: 164,
		part_id: 136,
		quantity: 312,
	},
	{
		garage_id: 164,
		part_id: 137,
		quantity: 223,
	},
	{
		garage_id: 164,
		part_id: 138,
		quantity: 423,
	},
	{
		garage_id: 164,
		part_id: 139,
		quantity: 87,
	},
	{
		garage_id: 164,
		part_id: 140,
		quantity: 32,
	},
	{
		garage_id: 164,
		part_id: 141,
		quantity: 374,
	},
	{
		garage_id: 164,
		part_id: 142,
		quantity: 176,
	},
	{
		garage_id: 164,
		part_id: 143,
		quantity: 183,
	},
	{
		garage_id: 164,
		part_id: 144,
		quantity: 7,
	},
	{
		garage_id: 164,
		part_id: 145,
		quantity: 97,
	},
	{
		garage_id: 164,
		part_id: 146,
		quantity: 328,
	},
	{
		garage_id: 164,
		part_id: 147,
		quantity: 295,
	},
	{
		garage_id: 164,
		part_id: 148,
		quantity: 290,
	},
	{
		garage_id: 164,
		part_id: 149,
		quantity: 178,
	},
	{
		garage_id: 164,
		part_id: 150,
		quantity: 71,
	},
	{
		garage_id: 164,
		part_id: 151,
		quantity: 408,
	},
	{
		garage_id: 164,
		part_id: 152,
		quantity: 154,
	},
	{
		garage_id: 164,
		part_id: 153,
		quantity: 49,
	},
	{
		garage_id: 164,
		part_id: 154,
		quantity: 265,
	},
	{
		garage_id: 164,
		part_id: 155,
		quantity: 375,
	},
	{
		garage_id: 164,
		part_id: 156,
		quantity: 394,
	},
	{
		garage_id: 164,
		part_id: 157,
		quantity: 107,
	},
	{
		garage_id: 164,
		part_id: 158,
		quantity: 298,
	},
	{
		garage_id: 164,
		part_id: 159,
		quantity: 132,
	},
	{
		garage_id: 164,
		part_id: 160,
		quantity: 135,
	},
	{
		garage_id: 164,
		part_id: 161,
		quantity: 80,
	},
	{
		garage_id: 164,
		part_id: 162,
		quantity: 248,
	},
	{
		garage_id: 164,
		part_id: 163,
		quantity: 21,
	},
	{
		garage_id: 164,
		part_id: 164,
		quantity: 266,
	},
	{
		garage_id: 164,
		part_id: 165,
		quantity: 13,
	},
	{
		garage_id: 164,
		part_id: 166,
		quantity: 430,
	},
	{
		garage_id: 164,
		part_id: 167,
		quantity: 65,
	},
	{
		garage_id: 164,
		part_id: 168,
		quantity: 52,
	},
	{
		garage_id: 164,
		part_id: 169,
		quantity: 140,
	},
	{
		garage_id: 164,
		part_id: 170,
		quantity: 236,
	},
	{
		garage_id: 164,
		part_id: 171,
		quantity: 33,
	},
	{
		garage_id: 164,
		part_id: 172,
		quantity: 271,
	},
	{
		garage_id: 164,
		part_id: 173,
		quantity: 164,
	},
	{
		garage_id: 164,
		part_id: 174,
		quantity: 364,
	},
	{
		garage_id: 164,
		part_id: 175,
		quantity: 290,
	},
	{
		garage_id: 164,
		part_id: 176,
		quantity: 308,
	},
	{
		garage_id: 164,
		part_id: 177,
		quantity: 7,
	},
	{
		garage_id: 164,
		part_id: 178,
		quantity: 478,
	},
	{
		garage_id: 164,
		part_id: 179,
		quantity: 377,
	},
	{
		garage_id: 164,
		part_id: 180,
		quantity: 318,
	},
	{
		garage_id: 164,
		part_id: 181,
		quantity: 444,
	},
	{
		garage_id: 164,
		part_id: 182,
		quantity: 168,
	},
	{
		garage_id: 164,
		part_id: 183,
		quantity: 118,
	},
	{
		garage_id: 164,
		part_id: 184,
		quantity: 286,
	},
	{
		garage_id: 164,
		part_id: 185,
		quantity: 154,
	},
	{
		garage_id: 164,
		part_id: 186,
		quantity: 44,
	},
	{
		garage_id: 164,
		part_id: 187,
		quantity: 305,
	},
	{
		garage_id: 164,
		part_id: 188,
		quantity: 478,
	},
	{
		garage_id: 164,
		part_id: 189,
		quantity: 216,
	},
	{
		garage_id: 164,
		part_id: 190,
		quantity: 226,
	},
	{
		garage_id: 164,
		part_id: 191,
		quantity: 49,
	},
	{
		garage_id: 164,
		part_id: 192,
		quantity: 447,
	},
	{
		garage_id: 164,
		part_id: 193,
		quantity: 195,
	},
	{
		garage_id: 164,
		part_id: 194,
		quantity: 460,
	},
	{
		garage_id: 164,
		part_id: 195,
		quantity: 182,
	},
	{
		garage_id: 164,
		part_id: 196,
		quantity: 62,
	},
	{
		garage_id: 164,
		part_id: 197,
		quantity: 2,
	},
	{
		garage_id: 164,
		part_id: 198,
		quantity: 263,
	},
	{
		garage_id: 164,
		part_id: 199,
		quantity: 0,
	},
	{
		garage_id: 164,
		part_id: 200,
		quantity: 112,
	},
	{
		garage_id: 164,
		part_id: 201,
		quantity: 201,
	},
	{
		garage_id: 164,
		part_id: 202,
		quantity: 451,
	},
	{
		garage_id: 164,
		part_id: 203,
		quantity: 325,
	},
	{
		garage_id: 164,
		part_id: 204,
		quantity: 265,
	},
	{
		garage_id: 164,
		part_id: 205,
		quantity: 330,
	},
	{
		garage_id: 164,
		part_id: 206,
		quantity: 329,
	},
	{
		garage_id: 164,
		part_id: 207,
		quantity: 90,
	},
	{
		garage_id: 164,
		part_id: 208,
		quantity: 43,
	},
	{
		garage_id: 164,
		part_id: 209,
		quantity: 170,
	},
	{
		garage_id: 164,
		part_id: 210,
		quantity: 392,
	},
	{
		garage_id: 164,
		part_id: 211,
		quantity: 178,
	},
	{
		garage_id: 164,
		part_id: 212,
		quantity: 108,
	},
	{
		garage_id: 164,
		part_id: 213,
		quantity: 103,
	},
	{
		garage_id: 164,
		part_id: 214,
		quantity: 238,
	},
	{
		garage_id: 164,
		part_id: 215,
		quantity: 197,
	},
	{
		garage_id: 164,
		part_id: 216,
		quantity: 233,
	},
	{
		garage_id: 164,
		part_id: 217,
		quantity: 382,
	},
	{
		garage_id: 164,
		part_id: 218,
		quantity: 88,
	},
	{
		garage_id: 164,
		part_id: 219,
		quantity: 226,
	},
	{
		garage_id: 164,
		part_id: 220,
		quantity: 367,
	},
	{
		garage_id: 164,
		part_id: 221,
		quantity: 296,
	},
	{
		garage_id: 164,
		part_id: 222,
		quantity: 395,
	},
	{
		garage_id: 164,
		part_id: 223,
		quantity: 262,
	},
	{
		garage_id: 164,
		part_id: 224,
		quantity: 297,
	},
	{
		garage_id: 164,
		part_id: 225,
		quantity: 41,
	},
	{
		garage_id: 164,
		part_id: 226,
		quantity: 192,
	},
	{
		garage_id: 164,
		part_id: 227,
		quantity: 315,
	},
	{
		garage_id: 164,
		part_id: 228,
		quantity: 173,
	},
	{
		garage_id: 164,
		part_id: 229,
		quantity: 6,
	},
	{
		garage_id: 164,
		part_id: 230,
		quantity: 5,
	},
	{
		garage_id: 164,
		part_id: 231,
		quantity: 2,
	},
	{
		garage_id: 164,
		part_id: 232,
		quantity: 125,
	},
	{
		garage_id: 164,
		part_id: 233,
		quantity: 324,
	},
	{
		garage_id: 164,
		part_id: 234,
		quantity: 303,
	},
	{
		garage_id: 164,
		part_id: 235,
		quantity: 409,
	},
	{
		garage_id: 164,
		part_id: 236,
		quantity: 349,
	},
	{
		garage_id: 164,
		part_id: 237,
		quantity: 127,
	},
	{
		garage_id: 164,
		part_id: 238,
		quantity: 24,
	},
	{
		garage_id: 164,
		part_id: 239,
		quantity: 339,
	},
	{
		garage_id: 164,
		part_id: 240,
		quantity: 105,
	},
	{
		garage_id: 164,
		part_id: 241,
		quantity: 79,
	},
	{
		garage_id: 164,
		part_id: 242,
		quantity: 122,
	},
	{
		garage_id: 164,
		part_id: 243,
		quantity: 473,
	},
	{
		garage_id: 164,
		part_id: 244,
		quantity: 70,
	},
	{
		garage_id: 164,
		part_id: 245,
		quantity: 155,
	},
	{
		garage_id: 164,
		part_id: 246,
		quantity: 109,
	},
	{
		garage_id: 164,
		part_id: 247,
		quantity: 166,
	},
	{
		garage_id: 164,
		part_id: 248,
		quantity: 298,
	},
	{
		garage_id: 164,
		part_id: 249,
		quantity: 128,
	},
	{
		garage_id: 164,
		part_id: 250,
		quantity: 463,
	},
	{
		garage_id: 164,
		part_id: 251,
		quantity: 75,
	},
	{
		garage_id: 164,
		part_id: 252,
		quantity: 297,
	},
	{
		garage_id: 164,
		part_id: 253,
		quantity: 180,
	},
	{
		garage_id: 164,
		part_id: 254,
		quantity: 224,
	},
	{
		garage_id: 164,
		part_id: 255,
		quantity: 90,
	},
	{
		garage_id: 164,
		part_id: 256,
		quantity: 85,
	},
	{
		garage_id: 164,
		part_id: 257,
		quantity: 344,
	},
	{
		garage_id: 164,
		part_id: 258,
		quantity: 317,
	},
	{
		garage_id: 164,
		part_id: 259,
		quantity: 328,
	},
	{
		garage_id: 164,
		part_id: 260,
		quantity: 489,
	},
	{
		garage_id: 164,
		part_id: 261,
		quantity: 152,
	},
	{
		garage_id: 164,
		part_id: 262,
		quantity: 26,
	},
	{
		garage_id: 164,
		part_id: 263,
		quantity: 176,
	},
	{
		garage_id: 164,
		part_id: 264,
		quantity: 243,
	},
	{
		garage_id: 164,
		part_id: 265,
		quantity: 152,
	},
	{
		garage_id: 164,
		part_id: 266,
		quantity: 257,
	},
	{
		garage_id: 164,
		part_id: 267,
		quantity: 277,
	},
	{
		garage_id: 164,
		part_id: 268,
		quantity: 301,
	},
	{
		garage_id: 164,
		part_id: 269,
		quantity: 461,
	},
	{
		garage_id: 164,
		part_id: 270,
		quantity: 397,
	},
	{
		garage_id: 164,
		part_id: 271,
		quantity: 435,
	},
	{
		garage_id: 164,
		part_id: 272,
		quantity: 433,
	},
	{
		garage_id: 164,
		part_id: 273,
		quantity: 402,
	},
	{
		garage_id: 164,
		part_id: 274,
		quantity: 209,
	},
	{
		garage_id: 164,
		part_id: 275,
		quantity: 211,
	},
	{
		garage_id: 164,
		part_id: 276,
		quantity: 125,
	},
	{
		garage_id: 164,
		part_id: 277,
		quantity: 106,
	},
	{
		garage_id: 164,
		part_id: 278,
		quantity: 117,
	},
	{
		garage_id: 164,
		part_id: 279,
		quantity: 107,
	},
	{
		garage_id: 164,
		part_id: 280,
		quantity: 218,
	},
	{
		garage_id: 164,
		part_id: 281,
		quantity: 141,
	},
	{
		garage_id: 164,
		part_id: 282,
		quantity: 285,
	},
	{
		garage_id: 164,
		part_id: 283,
		quantity: 171,
	},
	{
		garage_id: 164,
		part_id: 284,
		quantity: 269,
	},
	{
		garage_id: 164,
		part_id: 285,
		quantity: 12,
	},
	{
		garage_id: 164,
		part_id: 286,
		quantity: 385,
	},
	{
		garage_id: 164,
		part_id: 287,
		quantity: 102,
	},
	{
		garage_id: 164,
		part_id: 288,
		quantity: 469,
	},
	{
		garage_id: 164,
		part_id: 289,
		quantity: 458,
	},
	{
		garage_id: 164,
		part_id: 290,
		quantity: 262,
	},
	{
		garage_id: 164,
		part_id: 291,
		quantity: 295,
	},
	{
		garage_id: 164,
		part_id: 292,
		quantity: 372,
	},
	{
		garage_id: 164,
		part_id: 293,
		quantity: 356,
	},
	{
		garage_id: 164,
		part_id: 294,
		quantity: 156,
	},
	{
		garage_id: 164,
		part_id: 295,
		quantity: 493,
	},
	{
		garage_id: 164,
		part_id: 296,
		quantity: 9,
	},
	{
		garage_id: 164,
		part_id: 297,
		quantity: 480,
	},
	{
		garage_id: 164,
		part_id: 298,
		quantity: 401,
	},
	{
		garage_id: 164,
		part_id: 299,
		quantity: 258,
	},
	{
		garage_id: 164,
		part_id: 300,
		quantity: 401,
	},
	{
		garage_id: 164,
		part_id: 301,
		quantity: 179,
	},
	{
		garage_id: 164,
		part_id: 302,
		quantity: 293,
	},
	{
		garage_id: 164,
		part_id: 303,
		quantity: 118,
	},
	{
		garage_id: 164,
		part_id: 304,
		quantity: 7,
	},
	{
		garage_id: 164,
		part_id: 305,
		quantity: 477,
	},
	{
		garage_id: 164,
		part_id: 306,
		quantity: 343,
	},
	{
		garage_id: 164,
		part_id: 307,
		quantity: 313,
	},
	{
		garage_id: 164,
		part_id: 308,
		quantity: 74,
	},
	{
		garage_id: 164,
		part_id: 309,
		quantity: 209,
	},
	{
		garage_id: 164,
		part_id: 310,
		quantity: 154,
	},
	{
		garage_id: 164,
		part_id: 311,
		quantity: 354,
	},
	{
		garage_id: 164,
		part_id: 312,
		quantity: 81,
	},
	{
		garage_id: 164,
		part_id: 313,
		quantity: 172,
	},
	{
		garage_id: 164,
		part_id: 314,
		quantity: 369,
	},
	{
		garage_id: 164,
		part_id: 315,
		quantity: 204,
	},
	{
		garage_id: 164,
		part_id: 316,
		quantity: 31,
	},
	{
		garage_id: 164,
		part_id: 317,
		quantity: 481,
	},
	{
		garage_id: 164,
		part_id: 318,
		quantity: 305,
	},
	{
		garage_id: 164,
		part_id: 319,
		quantity: 54,
	},
	{
		garage_id: 164,
		part_id: 320,
		quantity: 421,
	},
	{
		garage_id: 164,
		part_id: 321,
		quantity: 451,
	},
	{
		garage_id: 164,
		part_id: 322,
		quantity: 153,
	},
	{
		garage_id: 164,
		part_id: 323,
		quantity: 331,
	},
	{
		garage_id: 164,
		part_id: 324,
		quantity: 164,
	},
	{
		garage_id: 164,
		part_id: 325,
		quantity: 284,
	},
	{
		garage_id: 164,
		part_id: 326,
		quantity: 242,
	},
	{
		garage_id: 164,
		part_id: 327,
		quantity: 115,
	},
	{
		garage_id: 164,
		part_id: 328,
		quantity: 129,
	},
	{
		garage_id: 164,
		part_id: 329,
		quantity: 309,
	},
	{
		garage_id: 164,
		part_id: 330,
		quantity: 456,
	},
	{
		garage_id: 164,
		part_id: 331,
		quantity: 182,
	},
	{
		garage_id: 164,
		part_id: 332,
		quantity: 414,
	},
	{
		garage_id: 164,
		part_id: 333,
		quantity: 56,
	},
	{
		garage_id: 164,
		part_id: 334,
		quantity: 390,
	},
	{
		garage_id: 164,
		part_id: 335,
		quantity: 480,
	},
	{
		garage_id: 164,
		part_id: 336,
		quantity: 476,
	},
	{
		garage_id: 164,
		part_id: 337,
		quantity: 448,
	},
	{
		garage_id: 164,
		part_id: 338,
		quantity: 144,
	},
	{
		garage_id: 164,
		part_id: 339,
		quantity: 224,
	},
	{
		garage_id: 164,
		part_id: 340,
		quantity: 203,
	},
	{
		garage_id: 164,
		part_id: 341,
		quantity: 482,
	},
	{
		garage_id: 164,
		part_id: 342,
		quantity: 473,
	},
	{
		garage_id: 164,
		part_id: 343,
		quantity: 358,
	},
	{
		garage_id: 164,
		part_id: 344,
		quantity: 37,
	},
	{
		garage_id: 164,
		part_id: 345,
		quantity: 273,
	},
	{
		garage_id: 164,
		part_id: 346,
		quantity: 366,
	},
	{
		garage_id: 164,
		part_id: 347,
		quantity: 27,
	},
	{
		garage_id: 164,
		part_id: 348,
		quantity: 465,
	},
	{
		garage_id: 164,
		part_id: 349,
		quantity: 146,
	},
	{
		garage_id: 164,
		part_id: 350,
		quantity: 58,
	},
	{
		garage_id: 164,
		part_id: 351,
		quantity: 263,
	},
	{
		garage_id: 164,
		part_id: 352,
		quantity: 246,
	},
	{
		garage_id: 164,
		part_id: 353,
		quantity: 273,
	},
	{
		garage_id: 164,
		part_id: 354,
		quantity: 150,
	},
	{
		garage_id: 164,
		part_id: 355,
		quantity: 353,
	},
	{
		garage_id: 164,
		part_id: 356,
		quantity: 12,
	},
	{
		garage_id: 164,
		part_id: 357,
		quantity: 333,
	},
	{
		garage_id: 164,
		part_id: 358,
		quantity: 298,
	},
	{
		garage_id: 164,
		part_id: 359,
		quantity: 469,
	},
	{
		garage_id: 164,
		part_id: 360,
		quantity: 325,
	},
	{
		garage_id: 164,
		part_id: 361,
		quantity: 374,
	},
	{
		garage_id: 164,
		part_id: 362,
		quantity: 18,
	},
	{
		garage_id: 164,
		part_id: 363,
		quantity: 393,
	},
	{
		garage_id: 164,
		part_id: 364,
		quantity: 367,
	},
	{
		garage_id: 164,
		part_id: 365,
		quantity: 385,
	},
	{
		garage_id: 164,
		part_id: 366,
		quantity: 410,
	},
	{
		garage_id: 164,
		part_id: 367,
		quantity: 387,
	},
	{
		garage_id: 164,
		part_id: 368,
		quantity: 359,
	},
	{
		garage_id: 164,
		part_id: 369,
		quantity: 374,
	},
	{
		garage_id: 164,
		part_id: 370,
		quantity: 449,
	},
	{
		garage_id: 164,
		part_id: 371,
		quantity: 88,
	},
	{
		garage_id: 164,
		part_id: 372,
		quantity: 337,
	},
	{
		garage_id: 164,
		part_id: 373,
		quantity: 321,
	},
	{
		garage_id: 164,
		part_id: 374,
		quantity: 461,
	},
	{
		garage_id: 164,
		part_id: 375,
		quantity: 461,
	},
	{
		garage_id: 164,
		part_id: 376,
		quantity: 442,
	},
	{
		garage_id: 164,
		part_id: 377,
		quantity: 331,
	},
	{
		garage_id: 164,
		part_id: 378,
		quantity: 78,
	},
	{
		garage_id: 164,
		part_id: 379,
		quantity: 477,
	},
	{
		garage_id: 164,
		part_id: 380,
		quantity: 270,
	},
	{
		garage_id: 164,
		part_id: 381,
		quantity: 296,
	},
	{
		garage_id: 164,
		part_id: 382,
		quantity: 163,
	},
	{
		garage_id: 164,
		part_id: 383,
		quantity: 416,
	},
	{
		garage_id: 164,
		part_id: 384,
		quantity: 55,
	},
	{
		garage_id: 164,
		part_id: 385,
		quantity: 388,
	},
	{
		garage_id: 164,
		part_id: 386,
		quantity: 480,
	},
	{
		garage_id: 164,
		part_id: 387,
		quantity: 137,
	},
	{
		garage_id: 164,
		part_id: 388,
		quantity: 496,
	},
	{
		garage_id: 164,
		part_id: 389,
		quantity: 61,
	},
	{
		garage_id: 164,
		part_id: 390,
		quantity: 23,
	},
	{
		garage_id: 164,
		part_id: 391,
		quantity: 130,
	},
	{
		garage_id: 164,
		part_id: 392,
		quantity: 489,
	},
	{
		garage_id: 164,
		part_id: 393,
		quantity: 2,
	},
	{
		garage_id: 164,
		part_id: 394,
		quantity: 349,
	},
	{
		garage_id: 164,
		part_id: 395,
		quantity: 146,
	},
	{
		garage_id: 164,
		part_id: 396,
		quantity: 270,
	},
	{
		garage_id: 164,
		part_id: 397,
		quantity: 402,
	},
	{
		garage_id: 164,
		part_id: 398,
		quantity: 195,
	},
	{
		garage_id: 164,
		part_id: 399,
		quantity: 476,
	},
	{
		garage_id: 165,
		part_id: 0,
		quantity: 67,
	},
	{
		garage_id: 165,
		part_id: 1,
		quantity: 415,
	},
	{
		garage_id: 165,
		part_id: 2,
		quantity: 441,
	},
	{
		garage_id: 165,
		part_id: 3,
		quantity: 15,
	},
	{
		garage_id: 165,
		part_id: 4,
		quantity: 72,
	},
	{
		garage_id: 165,
		part_id: 5,
		quantity: 56,
	},
	{
		garage_id: 165,
		part_id: 6,
		quantity: 398,
	},
	{
		garage_id: 165,
		part_id: 7,
		quantity: 1,
	},
	{
		garage_id: 165,
		part_id: 8,
		quantity: 213,
	},
	{
		garage_id: 165,
		part_id: 9,
		quantity: 99,
	},
	{
		garage_id: 165,
		part_id: 10,
		quantity: 252,
	},
	{
		garage_id: 165,
		part_id: 11,
		quantity: 336,
	},
	{
		garage_id: 165,
		part_id: 12,
		quantity: 52,
	},
	{
		garage_id: 165,
		part_id: 13,
		quantity: 250,
	},
	{
		garage_id: 165,
		part_id: 14,
		quantity: 207,
	},
	{
		garage_id: 165,
		part_id: 15,
		quantity: 316,
	},
	{
		garage_id: 165,
		part_id: 16,
		quantity: 74,
	},
	{
		garage_id: 165,
		part_id: 17,
		quantity: 353,
	},
	{
		garage_id: 165,
		part_id: 18,
		quantity: 375,
	},
	{
		garage_id: 165,
		part_id: 19,
		quantity: 487,
	},
	{
		garage_id: 165,
		part_id: 20,
		quantity: 479,
	},
	{
		garage_id: 165,
		part_id: 21,
		quantity: 222,
	},
	{
		garage_id: 165,
		part_id: 22,
		quantity: 253,
	},
	{
		garage_id: 165,
		part_id: 23,
		quantity: 358,
	},
	{
		garage_id: 165,
		part_id: 24,
		quantity: 371,
	},
	{
		garage_id: 165,
		part_id: 25,
		quantity: 91,
	},
	{
		garage_id: 165,
		part_id: 26,
		quantity: 37,
	},
	{
		garage_id: 165,
		part_id: 27,
		quantity: 495,
	},
	{
		garage_id: 165,
		part_id: 28,
		quantity: 489,
	},
	{
		garage_id: 165,
		part_id: 29,
		quantity: 341,
	},
	{
		garage_id: 165,
		part_id: 30,
		quantity: 107,
	},
	{
		garage_id: 165,
		part_id: 31,
		quantity: 192,
	},
	{
		garage_id: 165,
		part_id: 32,
		quantity: 123,
	},
	{
		garage_id: 165,
		part_id: 33,
		quantity: 277,
	},
	{
		garage_id: 165,
		part_id: 34,
		quantity: 218,
	},
	{
		garage_id: 165,
		part_id: 35,
		quantity: 71,
	},
	{
		garage_id: 165,
		part_id: 36,
		quantity: 450,
	},
	{
		garage_id: 165,
		part_id: 37,
		quantity: 201,
	},
	{
		garage_id: 165,
		part_id: 38,
		quantity: 292,
	},
	{
		garage_id: 165,
		part_id: 39,
		quantity: 468,
	},
	{
		garage_id: 165,
		part_id: 40,
		quantity: 317,
	},
	{
		garage_id: 165,
		part_id: 41,
		quantity: 40,
	},
	{
		garage_id: 165,
		part_id: 42,
		quantity: 350,
	},
	{
		garage_id: 165,
		part_id: 43,
		quantity: 355,
	},
	{
		garage_id: 165,
		part_id: 44,
		quantity: 291,
	},
	{
		garage_id: 165,
		part_id: 45,
		quantity: 182,
	},
	{
		garage_id: 165,
		part_id: 46,
		quantity: 247,
	},
	{
		garage_id: 165,
		part_id: 47,
		quantity: 271,
	},
	{
		garage_id: 165,
		part_id: 48,
		quantity: 14,
	},
	{
		garage_id: 165,
		part_id: 49,
		quantity: 477,
	},
	{
		garage_id: 165,
		part_id: 50,
		quantity: 61,
	},
	{
		garage_id: 165,
		part_id: 51,
		quantity: 276,
	},
	{
		garage_id: 165,
		part_id: 52,
		quantity: 98,
	},
	{
		garage_id: 165,
		part_id: 53,
		quantity: 133,
	},
	{
		garage_id: 165,
		part_id: 54,
		quantity: 436,
	},
	{
		garage_id: 165,
		part_id: 55,
		quantity: 239,
	},
	{
		garage_id: 165,
		part_id: 56,
		quantity: 207,
	},
	{
		garage_id: 165,
		part_id: 57,
		quantity: 202,
	},
	{
		garage_id: 165,
		part_id: 58,
		quantity: 225,
	},
	{
		garage_id: 165,
		part_id: 59,
		quantity: 135,
	},
	{
		garage_id: 165,
		part_id: 60,
		quantity: 26,
	},
	{
		garage_id: 165,
		part_id: 61,
		quantity: 236,
	},
	{
		garage_id: 165,
		part_id: 62,
		quantity: 230,
	},
	{
		garage_id: 165,
		part_id: 63,
		quantity: 256,
	},
	{
		garage_id: 165,
		part_id: 64,
		quantity: 36,
	},
	{
		garage_id: 165,
		part_id: 65,
		quantity: 447,
	},
	{
		garage_id: 165,
		part_id: 66,
		quantity: 46,
	},
	{
		garage_id: 165,
		part_id: 67,
		quantity: 63,
	},
	{
		garage_id: 165,
		part_id: 68,
		quantity: 15,
	},
	{
		garage_id: 165,
		part_id: 69,
		quantity: 238,
	},
	{
		garage_id: 165,
		part_id: 70,
		quantity: 84,
	},
	{
		garage_id: 165,
		part_id: 71,
		quantity: 212,
	},
	{
		garage_id: 165,
		part_id: 72,
		quantity: 484,
	},
	{
		garage_id: 165,
		part_id: 73,
		quantity: 97,
	},
	{
		garage_id: 165,
		part_id: 74,
		quantity: 81,
	},
	{
		garage_id: 165,
		part_id: 75,
		quantity: 372,
	},
	{
		garage_id: 165,
		part_id: 76,
		quantity: 446,
	},
	{
		garage_id: 165,
		part_id: 77,
		quantity: 413,
	},
	{
		garage_id: 165,
		part_id: 78,
		quantity: 93,
	},
	{
		garage_id: 165,
		part_id: 79,
		quantity: 177,
	},
	{
		garage_id: 165,
		part_id: 80,
		quantity: 131,
	},
	{
		garage_id: 165,
		part_id: 81,
		quantity: 431,
	},
	{
		garage_id: 165,
		part_id: 82,
		quantity: 308,
	},
	{
		garage_id: 165,
		part_id: 83,
		quantity: 139,
	},
	{
		garage_id: 165,
		part_id: 84,
		quantity: 481,
	},
	{
		garage_id: 165,
		part_id: 85,
		quantity: 396,
	},
	{
		garage_id: 165,
		part_id: 86,
		quantity: 359,
	},
	{
		garage_id: 165,
		part_id: 87,
		quantity: 178,
	},
	{
		garage_id: 165,
		part_id: 88,
		quantity: 21,
	},
	{
		garage_id: 165,
		part_id: 89,
		quantity: 101,
	},
	{
		garage_id: 165,
		part_id: 90,
		quantity: 190,
	},
	{
		garage_id: 165,
		part_id: 91,
		quantity: 80,
	},
	{
		garage_id: 165,
		part_id: 92,
		quantity: 292,
	},
	{
		garage_id: 165,
		part_id: 93,
		quantity: 71,
	},
	{
		garage_id: 165,
		part_id: 94,
		quantity: 415,
	},
	{
		garage_id: 165,
		part_id: 95,
		quantity: 93,
	},
	{
		garage_id: 165,
		part_id: 96,
		quantity: 188,
	},
	{
		garage_id: 165,
		part_id: 97,
		quantity: 261,
	},
	{
		garage_id: 165,
		part_id: 98,
		quantity: 254,
	},
	{
		garage_id: 165,
		part_id: 99,
		quantity: 124,
	},
	{
		garage_id: 165,
		part_id: 100,
		quantity: 172,
	},
	{
		garage_id: 165,
		part_id: 101,
		quantity: 457,
	},
	{
		garage_id: 165,
		part_id: 102,
		quantity: 90,
	},
	{
		garage_id: 165,
		part_id: 103,
		quantity: 16,
	},
	{
		garage_id: 165,
		part_id: 104,
		quantity: 275,
	},
	{
		garage_id: 165,
		part_id: 105,
		quantity: 379,
	},
	{
		garage_id: 165,
		part_id: 106,
		quantity: 311,
	},
	{
		garage_id: 165,
		part_id: 107,
		quantity: 17,
	},
	{
		garage_id: 165,
		part_id: 108,
		quantity: 116,
	},
	{
		garage_id: 165,
		part_id: 109,
		quantity: 442,
	},
	{
		garage_id: 165,
		part_id: 110,
		quantity: 236,
	},
	{
		garage_id: 165,
		part_id: 111,
		quantity: 414,
	},
	{
		garage_id: 165,
		part_id: 112,
		quantity: 398,
	},
	{
		garage_id: 165,
		part_id: 113,
		quantity: 336,
	},
	{
		garage_id: 165,
		part_id: 114,
		quantity: 466,
	},
	{
		garage_id: 165,
		part_id: 115,
		quantity: 142,
	},
	{
		garage_id: 165,
		part_id: 116,
		quantity: 268,
	},
	{
		garage_id: 165,
		part_id: 117,
		quantity: 467,
	},
	{
		garage_id: 165,
		part_id: 118,
		quantity: 48,
	},
	{
		garage_id: 165,
		part_id: 119,
		quantity: 475,
	},
	{
		garage_id: 165,
		part_id: 120,
		quantity: 203,
	},
	{
		garage_id: 165,
		part_id: 121,
		quantity: 273,
	},
	{
		garage_id: 165,
		part_id: 122,
		quantity: 171,
	},
	{
		garage_id: 165,
		part_id: 123,
		quantity: 129,
	},
	{
		garage_id: 165,
		part_id: 124,
		quantity: 112,
	},
	{
		garage_id: 165,
		part_id: 125,
		quantity: 19,
	},
	{
		garage_id: 165,
		part_id: 126,
		quantity: 271,
	},
	{
		garage_id: 165,
		part_id: 127,
		quantity: 451,
	},
	{
		garage_id: 165,
		part_id: 128,
		quantity: 372,
	},
	{
		garage_id: 165,
		part_id: 129,
		quantity: 182,
	},
	{
		garage_id: 165,
		part_id: 130,
		quantity: 239,
	},
	{
		garage_id: 165,
		part_id: 131,
		quantity: 463,
	},
	{
		garage_id: 165,
		part_id: 132,
		quantity: 444,
	},
	{
		garage_id: 165,
		part_id: 133,
		quantity: 197,
	},
	{
		garage_id: 165,
		part_id: 134,
		quantity: 197,
	},
	{
		garage_id: 165,
		part_id: 135,
		quantity: 130,
	},
	{
		garage_id: 165,
		part_id: 136,
		quantity: 329,
	},
	{
		garage_id: 165,
		part_id: 137,
		quantity: 458,
	},
	{
		garage_id: 165,
		part_id: 138,
		quantity: 298,
	},
	{
		garage_id: 165,
		part_id: 139,
		quantity: 113,
	},
	{
		garage_id: 165,
		part_id: 140,
		quantity: 308,
	},
	{
		garage_id: 165,
		part_id: 141,
		quantity: 338,
	},
	{
		garage_id: 165,
		part_id: 142,
		quantity: 57,
	},
	{
		garage_id: 165,
		part_id: 143,
		quantity: 366,
	},
	{
		garage_id: 165,
		part_id: 144,
		quantity: 248,
	},
	{
		garage_id: 165,
		part_id: 145,
		quantity: 401,
	},
	{
		garage_id: 165,
		part_id: 146,
		quantity: 289,
	},
	{
		garage_id: 165,
		part_id: 147,
		quantity: 80,
	},
	{
		garage_id: 165,
		part_id: 148,
		quantity: 315,
	},
	{
		garage_id: 165,
		part_id: 149,
		quantity: 253,
	},
	{
		garage_id: 165,
		part_id: 150,
		quantity: 327,
	},
	{
		garage_id: 165,
		part_id: 151,
		quantity: 447,
	},
	{
		garage_id: 165,
		part_id: 152,
		quantity: 194,
	},
	{
		garage_id: 165,
		part_id: 153,
		quantity: 101,
	},
	{
		garage_id: 165,
		part_id: 154,
		quantity: 367,
	},
	{
		garage_id: 165,
		part_id: 155,
		quantity: 233,
	},
	{
		garage_id: 165,
		part_id: 156,
		quantity: 148,
	},
	{
		garage_id: 165,
		part_id: 157,
		quantity: 161,
	},
	{
		garage_id: 165,
		part_id: 158,
		quantity: 112,
	},
	{
		garage_id: 165,
		part_id: 159,
		quantity: 439,
	},
	{
		garage_id: 165,
		part_id: 160,
		quantity: 228,
	},
	{
		garage_id: 165,
		part_id: 161,
		quantity: 305,
	},
	{
		garage_id: 165,
		part_id: 162,
		quantity: 107,
	},
	{
		garage_id: 165,
		part_id: 163,
		quantity: 77,
	},
	{
		garage_id: 165,
		part_id: 164,
		quantity: 498,
	},
	{
		garage_id: 165,
		part_id: 165,
		quantity: 241,
	},
	{
		garage_id: 165,
		part_id: 166,
		quantity: 320,
	},
	{
		garage_id: 165,
		part_id: 167,
		quantity: 428,
	},
	{
		garage_id: 165,
		part_id: 168,
		quantity: 62,
	},
	{
		garage_id: 165,
		part_id: 169,
		quantity: 296,
	},
	{
		garage_id: 165,
		part_id: 170,
		quantity: 197,
	},
	{
		garage_id: 165,
		part_id: 171,
		quantity: 249,
	},
	{
		garage_id: 165,
		part_id: 172,
		quantity: 65,
	},
	{
		garage_id: 165,
		part_id: 173,
		quantity: 217,
	},
	{
		garage_id: 165,
		part_id: 174,
		quantity: 447,
	},
	{
		garage_id: 165,
		part_id: 175,
		quantity: 461,
	},
	{
		garage_id: 165,
		part_id: 176,
		quantity: 464,
	},
	{
		garage_id: 165,
		part_id: 177,
		quantity: 83,
	},
	{
		garage_id: 165,
		part_id: 178,
		quantity: 312,
	},
	{
		garage_id: 165,
		part_id: 179,
		quantity: 253,
	},
	{
		garage_id: 165,
		part_id: 180,
		quantity: 229,
	},
	{
		garage_id: 165,
		part_id: 181,
		quantity: 242,
	},
	{
		garage_id: 165,
		part_id: 182,
		quantity: 182,
	},
	{
		garage_id: 165,
		part_id: 183,
		quantity: 419,
	},
	{
		garage_id: 165,
		part_id: 184,
		quantity: 6,
	},
	{
		garage_id: 165,
		part_id: 185,
		quantity: 391,
	},
	{
		garage_id: 165,
		part_id: 186,
		quantity: 439,
	},
	{
		garage_id: 165,
		part_id: 187,
		quantity: 140,
	},
	{
		garage_id: 165,
		part_id: 188,
		quantity: 482,
	},
	{
		garage_id: 165,
		part_id: 189,
		quantity: 196,
	},
	{
		garage_id: 165,
		part_id: 190,
		quantity: 220,
	},
	{
		garage_id: 165,
		part_id: 191,
		quantity: 220,
	},
	{
		garage_id: 165,
		part_id: 192,
		quantity: 317,
	},
	{
		garage_id: 165,
		part_id: 193,
		quantity: 298,
	},
	{
		garage_id: 165,
		part_id: 194,
		quantity: 317,
	},
	{
		garage_id: 165,
		part_id: 195,
		quantity: 440,
	},
	{
		garage_id: 165,
		part_id: 196,
		quantity: 288,
	},
	{
		garage_id: 165,
		part_id: 197,
		quantity: 149,
	},
	{
		garage_id: 165,
		part_id: 198,
		quantity: 171,
	},
	{
		garage_id: 165,
		part_id: 199,
		quantity: 32,
	},
	{
		garage_id: 165,
		part_id: 200,
		quantity: 393,
	},
	{
		garage_id: 165,
		part_id: 201,
		quantity: 240,
	},
	{
		garage_id: 165,
		part_id: 202,
		quantity: 350,
	},
	{
		garage_id: 165,
		part_id: 203,
		quantity: 167,
	},
	{
		garage_id: 165,
		part_id: 204,
		quantity: 189,
	},
	{
		garage_id: 165,
		part_id: 205,
		quantity: 4,
	},
	{
		garage_id: 165,
		part_id: 206,
		quantity: 303,
	},
	{
		garage_id: 165,
		part_id: 207,
		quantity: 112,
	},
	{
		garage_id: 165,
		part_id: 208,
		quantity: 332,
	},
	{
		garage_id: 165,
		part_id: 209,
		quantity: 438,
	},
	{
		garage_id: 165,
		part_id: 210,
		quantity: 170,
	},
	{
		garage_id: 165,
		part_id: 211,
		quantity: 199,
	},
	{
		garage_id: 165,
		part_id: 212,
		quantity: 28,
	},
	{
		garage_id: 165,
		part_id: 213,
		quantity: 443,
	},
	{
		garage_id: 165,
		part_id: 214,
		quantity: 12,
	},
	{
		garage_id: 165,
		part_id: 215,
		quantity: 99,
	},
	{
		garage_id: 165,
		part_id: 216,
		quantity: 106,
	},
	{
		garage_id: 165,
		part_id: 217,
		quantity: 29,
	},
	{
		garage_id: 165,
		part_id: 218,
		quantity: 262,
	},
	{
		garage_id: 165,
		part_id: 219,
		quantity: 444,
	},
	{
		garage_id: 165,
		part_id: 220,
		quantity: 315,
	},
	{
		garage_id: 165,
		part_id: 221,
		quantity: 238,
	},
	{
		garage_id: 165,
		part_id: 222,
		quantity: 81,
	},
	{
		garage_id: 165,
		part_id: 223,
		quantity: 436,
	},
	{
		garage_id: 165,
		part_id: 224,
		quantity: 299,
	},
	{
		garage_id: 165,
		part_id: 225,
		quantity: 16,
	},
	{
		garage_id: 165,
		part_id: 226,
		quantity: 329,
	},
	{
		garage_id: 165,
		part_id: 227,
		quantity: 302,
	},
	{
		garage_id: 165,
		part_id: 228,
		quantity: 102,
	},
	{
		garage_id: 165,
		part_id: 229,
		quantity: 252,
	},
	{
		garage_id: 165,
		part_id: 230,
		quantity: 77,
	},
	{
		garage_id: 165,
		part_id: 231,
		quantity: 372,
	},
	{
		garage_id: 165,
		part_id: 232,
		quantity: 257,
	},
	{
		garage_id: 165,
		part_id: 233,
		quantity: 429,
	},
	{
		garage_id: 165,
		part_id: 234,
		quantity: 240,
	},
	{
		garage_id: 165,
		part_id: 235,
		quantity: 371,
	},
	{
		garage_id: 165,
		part_id: 236,
		quantity: 406,
	},
	{
		garage_id: 165,
		part_id: 237,
		quantity: 450,
	},
	{
		garage_id: 165,
		part_id: 238,
		quantity: 461,
	},
	{
		garage_id: 165,
		part_id: 239,
		quantity: 210,
	},
	{
		garage_id: 165,
		part_id: 240,
		quantity: 161,
	},
	{
		garage_id: 165,
		part_id: 241,
		quantity: 384,
	},
	{
		garage_id: 165,
		part_id: 242,
		quantity: 389,
	},
	{
		garage_id: 165,
		part_id: 243,
		quantity: 25,
	},
	{
		garage_id: 165,
		part_id: 244,
		quantity: 325,
	},
	{
		garage_id: 165,
		part_id: 245,
		quantity: 430,
	},
	{
		garage_id: 165,
		part_id: 246,
		quantity: 397,
	},
	{
		garage_id: 165,
		part_id: 247,
		quantity: 114,
	},
	{
		garage_id: 165,
		part_id: 248,
		quantity: 444,
	},
	{
		garage_id: 165,
		part_id: 249,
		quantity: 403,
	},
	{
		garage_id: 165,
		part_id: 250,
		quantity: 382,
	},
	{
		garage_id: 165,
		part_id: 251,
		quantity: 461,
	},
	{
		garage_id: 165,
		part_id: 252,
		quantity: 259,
	},
	{
		garage_id: 165,
		part_id: 253,
		quantity: 274,
	},
	{
		garage_id: 165,
		part_id: 254,
		quantity: 109,
	},
	{
		garage_id: 165,
		part_id: 255,
		quantity: 36,
	},
	{
		garage_id: 165,
		part_id: 256,
		quantity: 185,
	},
	{
		garage_id: 165,
		part_id: 257,
		quantity: 175,
	},
	{
		garage_id: 165,
		part_id: 258,
		quantity: 425,
	},
	{
		garage_id: 165,
		part_id: 259,
		quantity: 65,
	},
	{
		garage_id: 165,
		part_id: 260,
		quantity: 339,
	},
	{
		garage_id: 165,
		part_id: 261,
		quantity: 356,
	},
	{
		garage_id: 165,
		part_id: 262,
		quantity: 103,
	},
	{
		garage_id: 165,
		part_id: 263,
		quantity: 492,
	},
	{
		garage_id: 165,
		part_id: 264,
		quantity: 82,
	},
	{
		garage_id: 165,
		part_id: 265,
		quantity: 279,
	},
	{
		garage_id: 165,
		part_id: 266,
		quantity: 111,
	},
	{
		garage_id: 165,
		part_id: 267,
		quantity: 417,
	},
	{
		garage_id: 165,
		part_id: 268,
		quantity: 270,
	},
	{
		garage_id: 165,
		part_id: 269,
		quantity: 325,
	},
	{
		garage_id: 165,
		part_id: 270,
		quantity: 134,
	},
	{
		garage_id: 165,
		part_id: 271,
		quantity: 373,
	},
	{
		garage_id: 165,
		part_id: 272,
		quantity: 377,
	},
	{
		garage_id: 165,
		part_id: 273,
		quantity: 298,
	},
	{
		garage_id: 165,
		part_id: 274,
		quantity: 96,
	},
	{
		garage_id: 165,
		part_id: 275,
		quantity: 340,
	},
	{
		garage_id: 165,
		part_id: 276,
		quantity: 404,
	},
	{
		garage_id: 165,
		part_id: 277,
		quantity: 171,
	},
	{
		garage_id: 165,
		part_id: 278,
		quantity: 71,
	},
	{
		garage_id: 165,
		part_id: 279,
		quantity: 167,
	},
	{
		garage_id: 165,
		part_id: 280,
		quantity: 133,
	},
	{
		garage_id: 165,
		part_id: 281,
		quantity: 10,
	},
	{
		garage_id: 165,
		part_id: 282,
		quantity: 139,
	},
	{
		garage_id: 165,
		part_id: 283,
		quantity: 9,
	},
	{
		garage_id: 165,
		part_id: 284,
		quantity: 78,
	},
	{
		garage_id: 165,
		part_id: 285,
		quantity: 116,
	},
	{
		garage_id: 165,
		part_id: 286,
		quantity: 202,
	},
	{
		garage_id: 165,
		part_id: 287,
		quantity: 252,
	},
	{
		garage_id: 165,
		part_id: 288,
		quantity: 74,
	},
	{
		garage_id: 165,
		part_id: 289,
		quantity: 56,
	},
	{
		garage_id: 165,
		part_id: 290,
		quantity: 420,
	},
	{
		garage_id: 165,
		part_id: 291,
		quantity: 194,
	},
	{
		garage_id: 165,
		part_id: 292,
		quantity: 197,
	},
	{
		garage_id: 165,
		part_id: 293,
		quantity: 239,
	},
	{
		garage_id: 165,
		part_id: 294,
		quantity: 124,
	},
	{
		garage_id: 165,
		part_id: 295,
		quantity: 177,
	},
	{
		garage_id: 165,
		part_id: 296,
		quantity: 62,
	},
	{
		garage_id: 165,
		part_id: 297,
		quantity: 439,
	},
	{
		garage_id: 165,
		part_id: 298,
		quantity: 5,
	},
	{
		garage_id: 165,
		part_id: 299,
		quantity: 146,
	},
	{
		garage_id: 165,
		part_id: 300,
		quantity: 406,
	},
	{
		garage_id: 165,
		part_id: 301,
		quantity: 382,
	},
	{
		garage_id: 165,
		part_id: 302,
		quantity: 48,
	},
	{
		garage_id: 165,
		part_id: 303,
		quantity: 70,
	},
	{
		garage_id: 165,
		part_id: 304,
		quantity: 355,
	},
	{
		garage_id: 165,
		part_id: 305,
		quantity: 167,
	},
	{
		garage_id: 165,
		part_id: 306,
		quantity: 488,
	},
	{
		garage_id: 165,
		part_id: 307,
		quantity: 30,
	},
	{
		garage_id: 165,
		part_id: 308,
		quantity: 176,
	},
	{
		garage_id: 165,
		part_id: 309,
		quantity: 152,
	},
	{
		garage_id: 165,
		part_id: 310,
		quantity: 336,
	},
	{
		garage_id: 165,
		part_id: 311,
		quantity: 345,
	},
	{
		garage_id: 165,
		part_id: 312,
		quantity: 107,
	},
	{
		garage_id: 165,
		part_id: 313,
		quantity: 179,
	},
	{
		garage_id: 165,
		part_id: 314,
		quantity: 202,
	},
	{
		garage_id: 165,
		part_id: 315,
		quantity: 425,
	},
	{
		garage_id: 165,
		part_id: 316,
		quantity: 387,
	},
	{
		garage_id: 165,
		part_id: 317,
		quantity: 247,
	},
	{
		garage_id: 165,
		part_id: 318,
		quantity: 472,
	},
	{
		garage_id: 165,
		part_id: 319,
		quantity: 360,
	},
	{
		garage_id: 165,
		part_id: 320,
		quantity: 255,
	},
	{
		garage_id: 165,
		part_id: 321,
		quantity: 331,
	},
	{
		garage_id: 165,
		part_id: 322,
		quantity: 80,
	},
	{
		garage_id: 165,
		part_id: 323,
		quantity: 351,
	},
	{
		garage_id: 165,
		part_id: 324,
		quantity: 315,
	},
	{
		garage_id: 165,
		part_id: 325,
		quantity: 303,
	},
	{
		garage_id: 165,
		part_id: 326,
		quantity: 257,
	},
	{
		garage_id: 165,
		part_id: 327,
		quantity: 321,
	},
	{
		garage_id: 165,
		part_id: 328,
		quantity: 160,
	},
	{
		garage_id: 165,
		part_id: 329,
		quantity: 147,
	},
	{
		garage_id: 165,
		part_id: 330,
		quantity: 332,
	},
	{
		garage_id: 165,
		part_id: 331,
		quantity: 408,
	},
	{
		garage_id: 165,
		part_id: 332,
		quantity: 230,
	},
	{
		garage_id: 165,
		part_id: 333,
		quantity: 93,
	},
	{
		garage_id: 165,
		part_id: 334,
		quantity: 108,
	},
	{
		garage_id: 165,
		part_id: 335,
		quantity: 130,
	},
	{
		garage_id: 165,
		part_id: 336,
		quantity: 120,
	},
	{
		garage_id: 165,
		part_id: 337,
		quantity: 403,
	},
	{
		garage_id: 165,
		part_id: 338,
		quantity: 9,
	},
	{
		garage_id: 165,
		part_id: 339,
		quantity: 366,
	},
	{
		garage_id: 165,
		part_id: 340,
		quantity: 93,
	},
	{
		garage_id: 165,
		part_id: 341,
		quantity: 452,
	},
	{
		garage_id: 165,
		part_id: 342,
		quantity: 419,
	},
	{
		garage_id: 165,
		part_id: 343,
		quantity: 333,
	},
	{
		garage_id: 165,
		part_id: 344,
		quantity: 400,
	},
	{
		garage_id: 165,
		part_id: 345,
		quantity: 491,
	},
	{
		garage_id: 165,
		part_id: 346,
		quantity: 58,
	},
	{
		garage_id: 165,
		part_id: 347,
		quantity: 452,
	},
	{
		garage_id: 165,
		part_id: 348,
		quantity: 365,
	},
	{
		garage_id: 165,
		part_id: 349,
		quantity: 148,
	},
	{
		garage_id: 165,
		part_id: 350,
		quantity: 29,
	},
	{
		garage_id: 165,
		part_id: 351,
		quantity: 352,
	},
	{
		garage_id: 165,
		part_id: 352,
		quantity: 120,
	},
	{
		garage_id: 165,
		part_id: 353,
		quantity: 70,
	},
	{
		garage_id: 165,
		part_id: 354,
		quantity: 433,
	},
	{
		garage_id: 165,
		part_id: 355,
		quantity: 26,
	},
	{
		garage_id: 165,
		part_id: 356,
		quantity: 299,
	},
	{
		garage_id: 165,
		part_id: 357,
		quantity: 169,
	},
	{
		garage_id: 165,
		part_id: 358,
		quantity: 231,
	},
	{
		garage_id: 165,
		part_id: 359,
		quantity: 214,
	},
	{
		garage_id: 165,
		part_id: 360,
		quantity: 382,
	},
	{
		garage_id: 165,
		part_id: 361,
		quantity: 461,
	},
	{
		garage_id: 165,
		part_id: 362,
		quantity: 214,
	},
	{
		garage_id: 165,
		part_id: 363,
		quantity: 33,
	},
	{
		garage_id: 165,
		part_id: 364,
		quantity: 30,
	},
	{
		garage_id: 165,
		part_id: 365,
		quantity: 306,
	},
	{
		garage_id: 165,
		part_id: 366,
		quantity: 10,
	},
	{
		garage_id: 165,
		part_id: 367,
		quantity: 200,
	},
	{
		garage_id: 165,
		part_id: 368,
		quantity: 317,
	},
	{
		garage_id: 165,
		part_id: 369,
		quantity: 467,
	},
	{
		garage_id: 165,
		part_id: 370,
		quantity: 369,
	},
	{
		garage_id: 165,
		part_id: 371,
		quantity: 200,
	},
	{
		garage_id: 165,
		part_id: 372,
		quantity: 250,
	},
	{
		garage_id: 165,
		part_id: 373,
		quantity: 35,
	},
	{
		garage_id: 165,
		part_id: 374,
		quantity: 33,
	},
	{
		garage_id: 165,
		part_id: 375,
		quantity: 331,
	},
	{
		garage_id: 165,
		part_id: 376,
		quantity: 127,
	},
	{
		garage_id: 165,
		part_id: 377,
		quantity: 198,
	},
	{
		garage_id: 165,
		part_id: 378,
		quantity: 133,
	},
	{
		garage_id: 165,
		part_id: 379,
		quantity: 387,
	},
	{
		garage_id: 165,
		part_id: 380,
		quantity: 34,
	},
	{
		garage_id: 165,
		part_id: 381,
		quantity: 331,
	},
	{
		garage_id: 165,
		part_id: 382,
		quantity: 302,
	},
	{
		garage_id: 165,
		part_id: 383,
		quantity: 187,
	},
	{
		garage_id: 165,
		part_id: 384,
		quantity: 166,
	},
	{
		garage_id: 165,
		part_id: 385,
		quantity: 201,
	},
	{
		garage_id: 165,
		part_id: 386,
		quantity: 451,
	},
	{
		garage_id: 165,
		part_id: 387,
		quantity: 74,
	},
	{
		garage_id: 165,
		part_id: 388,
		quantity: 276,
	},
	{
		garage_id: 165,
		part_id: 389,
		quantity: 133,
	},
	{
		garage_id: 165,
		part_id: 390,
		quantity: 42,
	},
	{
		garage_id: 165,
		part_id: 391,
		quantity: 362,
	},
	{
		garage_id: 165,
		part_id: 392,
		quantity: 197,
	},
	{
		garage_id: 165,
		part_id: 393,
		quantity: 477,
	},
	{
		garage_id: 165,
		part_id: 394,
		quantity: 226,
	},
	{
		garage_id: 165,
		part_id: 395,
		quantity: 87,
	},
	{
		garage_id: 165,
		part_id: 396,
		quantity: 323,
	},
	{
		garage_id: 165,
		part_id: 397,
		quantity: 399,
	},
	{
		garage_id: 165,
		part_id: 398,
		quantity: 37,
	},
	{
		garage_id: 165,
		part_id: 399,
		quantity: 321,
	},
	{
		garage_id: 166,
		part_id: 0,
		quantity: 237,
	},
	{
		garage_id: 166,
		part_id: 1,
		quantity: 215,
	},
	{
		garage_id: 166,
		part_id: 2,
		quantity: 65,
	},
	{
		garage_id: 166,
		part_id: 3,
		quantity: 236,
	},
	{
		garage_id: 166,
		part_id: 4,
		quantity: 143,
	},
	{
		garage_id: 166,
		part_id: 5,
		quantity: 446,
	},
	{
		garage_id: 166,
		part_id: 6,
		quantity: 240,
	},
	{
		garage_id: 166,
		part_id: 7,
		quantity: 324,
	},
	{
		garage_id: 166,
		part_id: 8,
		quantity: 399,
	},
	{
		garage_id: 166,
		part_id: 9,
		quantity: 258,
	},
	{
		garage_id: 166,
		part_id: 10,
		quantity: 91,
	},
	{
		garage_id: 166,
		part_id: 11,
		quantity: 379,
	},
	{
		garage_id: 166,
		part_id: 12,
		quantity: 55,
	},
	{
		garage_id: 166,
		part_id: 13,
		quantity: 304,
	},
	{
		garage_id: 166,
		part_id: 14,
		quantity: 156,
	},
	{
		garage_id: 166,
		part_id: 15,
		quantity: 405,
	},
	{
		garage_id: 166,
		part_id: 16,
		quantity: 241,
	},
	{
		garage_id: 166,
		part_id: 17,
		quantity: 182,
	},
	{
		garage_id: 166,
		part_id: 18,
		quantity: 455,
	},
	{
		garage_id: 166,
		part_id: 19,
		quantity: 64,
	},
	{
		garage_id: 166,
		part_id: 20,
		quantity: 492,
	},
	{
		garage_id: 166,
		part_id: 21,
		quantity: 11,
	},
	{
		garage_id: 166,
		part_id: 22,
		quantity: 481,
	},
	{
		garage_id: 166,
		part_id: 23,
		quantity: 396,
	},
	{
		garage_id: 166,
		part_id: 24,
		quantity: 84,
	},
	{
		garage_id: 166,
		part_id: 25,
		quantity: 298,
	},
	{
		garage_id: 166,
		part_id: 26,
		quantity: 42,
	},
	{
		garage_id: 166,
		part_id: 27,
		quantity: 9,
	},
	{
		garage_id: 166,
		part_id: 28,
		quantity: 421,
	},
	{
		garage_id: 166,
		part_id: 29,
		quantity: 10,
	},
	{
		garage_id: 166,
		part_id: 30,
		quantity: 479,
	},
	{
		garage_id: 166,
		part_id: 31,
		quantity: 465,
	},
	{
		garage_id: 166,
		part_id: 32,
		quantity: 61,
	},
	{
		garage_id: 166,
		part_id: 33,
		quantity: 263,
	},
	{
		garage_id: 166,
		part_id: 34,
		quantity: 32,
	},
	{
		garage_id: 166,
		part_id: 35,
		quantity: 493,
	},
	{
		garage_id: 166,
		part_id: 36,
		quantity: 52,
	},
	{
		garage_id: 166,
		part_id: 37,
		quantity: 303,
	},
	{
		garage_id: 166,
		part_id: 38,
		quantity: 499,
	},
	{
		garage_id: 166,
		part_id: 39,
		quantity: 206,
	},
	{
		garage_id: 166,
		part_id: 40,
		quantity: 240,
	},
	{
		garage_id: 166,
		part_id: 41,
		quantity: 271,
	},
	{
		garage_id: 166,
		part_id: 42,
		quantity: 73,
	},
	{
		garage_id: 166,
		part_id: 43,
		quantity: 231,
	},
	{
		garage_id: 166,
		part_id: 44,
		quantity: 67,
	},
	{
		garage_id: 166,
		part_id: 45,
		quantity: 122,
	},
	{
		garage_id: 166,
		part_id: 46,
		quantity: 429,
	},
	{
		garage_id: 166,
		part_id: 47,
		quantity: 260,
	},
	{
		garage_id: 166,
		part_id: 48,
		quantity: 278,
	},
	{
		garage_id: 166,
		part_id: 49,
		quantity: 90,
	},
	{
		garage_id: 166,
		part_id: 50,
		quantity: 307,
	},
	{
		garage_id: 166,
		part_id: 51,
		quantity: 396,
	},
	{
		garage_id: 166,
		part_id: 52,
		quantity: 346,
	},
	{
		garage_id: 166,
		part_id: 53,
		quantity: 320,
	},
	{
		garage_id: 166,
		part_id: 54,
		quantity: 44,
	},
	{
		garage_id: 166,
		part_id: 55,
		quantity: 402,
	},
	{
		garage_id: 166,
		part_id: 56,
		quantity: 58,
	},
	{
		garage_id: 166,
		part_id: 57,
		quantity: 123,
	},
	{
		garage_id: 166,
		part_id: 58,
		quantity: 495,
	},
	{
		garage_id: 166,
		part_id: 59,
		quantity: 134,
	},
	{
		garage_id: 166,
		part_id: 60,
		quantity: 438,
	},
	{
		garage_id: 166,
		part_id: 61,
		quantity: 415,
	},
	{
		garage_id: 166,
		part_id: 62,
		quantity: 324,
	},
	{
		garage_id: 166,
		part_id: 63,
		quantity: 207,
	},
	{
		garage_id: 166,
		part_id: 64,
		quantity: 461,
	},
	{
		garage_id: 166,
		part_id: 65,
		quantity: 296,
	},
	{
		garage_id: 166,
		part_id: 66,
		quantity: 453,
	},
	{
		garage_id: 166,
		part_id: 67,
		quantity: 104,
	},
	{
		garage_id: 166,
		part_id: 68,
		quantity: 191,
	},
	{
		garage_id: 166,
		part_id: 69,
		quantity: 61,
	},
	{
		garage_id: 166,
		part_id: 70,
		quantity: 339,
	},
	{
		garage_id: 166,
		part_id: 71,
		quantity: 220,
	},
	{
		garage_id: 166,
		part_id: 72,
		quantity: 268,
	},
	{
		garage_id: 166,
		part_id: 73,
		quantity: 189,
	},
	{
		garage_id: 166,
		part_id: 74,
		quantity: 85,
	},
	{
		garage_id: 166,
		part_id: 75,
		quantity: 16,
	},
	{
		garage_id: 166,
		part_id: 76,
		quantity: 458,
	},
	{
		garage_id: 166,
		part_id: 77,
		quantity: 478,
	},
	{
		garage_id: 166,
		part_id: 78,
		quantity: 7,
	},
	{
		garage_id: 166,
		part_id: 79,
		quantity: 162,
	},
	{
		garage_id: 166,
		part_id: 80,
		quantity: 211,
	},
	{
		garage_id: 166,
		part_id: 81,
		quantity: 394,
	},
	{
		garage_id: 166,
		part_id: 82,
		quantity: 434,
	},
	{
		garage_id: 166,
		part_id: 83,
		quantity: 437,
	},
	{
		garage_id: 166,
		part_id: 84,
		quantity: 147,
	},
	{
		garage_id: 166,
		part_id: 85,
		quantity: 191,
	},
	{
		garage_id: 166,
		part_id: 86,
		quantity: 114,
	},
	{
		garage_id: 166,
		part_id: 87,
		quantity: 192,
	},
	{
		garage_id: 166,
		part_id: 88,
		quantity: 86,
	},
	{
		garage_id: 166,
		part_id: 89,
		quantity: 394,
	},
	{
		garage_id: 166,
		part_id: 90,
		quantity: 278,
	},
	{
		garage_id: 166,
		part_id: 91,
		quantity: 493,
	},
	{
		garage_id: 166,
		part_id: 92,
		quantity: 63,
	},
	{
		garage_id: 166,
		part_id: 93,
		quantity: 90,
	},
	{
		garage_id: 166,
		part_id: 94,
		quantity: 339,
	},
	{
		garage_id: 166,
		part_id: 95,
		quantity: 71,
	},
	{
		garage_id: 166,
		part_id: 96,
		quantity: 214,
	},
	{
		garage_id: 166,
		part_id: 97,
		quantity: 125,
	},
	{
		garage_id: 166,
		part_id: 98,
		quantity: 37,
	},
	{
		garage_id: 166,
		part_id: 99,
		quantity: 55,
	},
	{
		garage_id: 166,
		part_id: 100,
		quantity: 186,
	},
	{
		garage_id: 166,
		part_id: 101,
		quantity: 132,
	},
	{
		garage_id: 166,
		part_id: 102,
		quantity: 347,
	},
	{
		garage_id: 166,
		part_id: 103,
		quantity: 461,
	},
	{
		garage_id: 166,
		part_id: 104,
		quantity: 305,
	},
	{
		garage_id: 166,
		part_id: 105,
		quantity: 241,
	},
	{
		garage_id: 166,
		part_id: 106,
		quantity: 473,
	},
	{
		garage_id: 166,
		part_id: 107,
		quantity: 115,
	},
	{
		garage_id: 166,
		part_id: 108,
		quantity: 29,
	},
	{
		garage_id: 166,
		part_id: 109,
		quantity: 114,
	},
	{
		garage_id: 166,
		part_id: 110,
		quantity: 315,
	},
	{
		garage_id: 166,
		part_id: 111,
		quantity: 498,
	},
	{
		garage_id: 166,
		part_id: 112,
		quantity: 189,
	},
	{
		garage_id: 166,
		part_id: 113,
		quantity: 359,
	},
	{
		garage_id: 166,
		part_id: 114,
		quantity: 180,
	},
	{
		garage_id: 166,
		part_id: 115,
		quantity: 76,
	},
	{
		garage_id: 166,
		part_id: 116,
		quantity: 291,
	},
	{
		garage_id: 166,
		part_id: 117,
		quantity: 209,
	},
	{
		garage_id: 166,
		part_id: 118,
		quantity: 136,
	},
	{
		garage_id: 166,
		part_id: 119,
		quantity: 53,
	},
	{
		garage_id: 166,
		part_id: 120,
		quantity: 345,
	},
	{
		garage_id: 166,
		part_id: 121,
		quantity: 237,
	},
	{
		garage_id: 166,
		part_id: 122,
		quantity: 56,
	},
	{
		garage_id: 166,
		part_id: 123,
		quantity: 104,
	},
	{
		garage_id: 166,
		part_id: 124,
		quantity: 159,
	},
	{
		garage_id: 166,
		part_id: 125,
		quantity: 44,
	},
	{
		garage_id: 166,
		part_id: 126,
		quantity: 252,
	},
	{
		garage_id: 166,
		part_id: 127,
		quantity: 205,
	},
	{
		garage_id: 166,
		part_id: 128,
		quantity: 354,
	},
	{
		garage_id: 166,
		part_id: 129,
		quantity: 24,
	},
	{
		garage_id: 166,
		part_id: 130,
		quantity: 264,
	},
	{
		garage_id: 166,
		part_id: 131,
		quantity: 98,
	},
	{
		garage_id: 166,
		part_id: 132,
		quantity: 465,
	},
	{
		garage_id: 166,
		part_id: 133,
		quantity: 75,
	},
	{
		garage_id: 166,
		part_id: 134,
		quantity: 433,
	},
	{
		garage_id: 166,
		part_id: 135,
		quantity: 175,
	},
	{
		garage_id: 166,
		part_id: 136,
		quantity: 322,
	},
	{
		garage_id: 166,
		part_id: 137,
		quantity: 444,
	},
	{
		garage_id: 166,
		part_id: 138,
		quantity: 118,
	},
	{
		garage_id: 166,
		part_id: 139,
		quantity: 42,
	},
	{
		garage_id: 166,
		part_id: 140,
		quantity: 497,
	},
	{
		garage_id: 166,
		part_id: 141,
		quantity: 101,
	},
	{
		garage_id: 166,
		part_id: 142,
		quantity: 116,
	},
	{
		garage_id: 166,
		part_id: 143,
		quantity: 275,
	},
	{
		garage_id: 166,
		part_id: 144,
		quantity: 354,
	},
	{
		garage_id: 166,
		part_id: 145,
		quantity: 445,
	},
	{
		garage_id: 166,
		part_id: 146,
		quantity: 48,
	},
	{
		garage_id: 166,
		part_id: 147,
		quantity: 90,
	},
	{
		garage_id: 166,
		part_id: 148,
		quantity: 121,
	},
	{
		garage_id: 166,
		part_id: 149,
		quantity: 474,
	},
	{
		garage_id: 166,
		part_id: 150,
		quantity: 141,
	},
	{
		garage_id: 166,
		part_id: 151,
		quantity: 102,
	},
	{
		garage_id: 166,
		part_id: 152,
		quantity: 46,
	},
	{
		garage_id: 166,
		part_id: 153,
		quantity: 388,
	},
	{
		garage_id: 166,
		part_id: 154,
		quantity: 378,
	},
	{
		garage_id: 166,
		part_id: 155,
		quantity: 182,
	},
	{
		garage_id: 166,
		part_id: 156,
		quantity: 495,
	},
	{
		garage_id: 166,
		part_id: 157,
		quantity: 64,
	},
	{
		garage_id: 166,
		part_id: 158,
		quantity: 44,
	},
	{
		garage_id: 166,
		part_id: 159,
		quantity: 442,
	},
	{
		garage_id: 166,
		part_id: 160,
		quantity: 119,
	},
	{
		garage_id: 166,
		part_id: 161,
		quantity: 318,
	},
	{
		garage_id: 166,
		part_id: 162,
		quantity: 155,
	},
	{
		garage_id: 166,
		part_id: 163,
		quantity: 370,
	},
	{
		garage_id: 166,
		part_id: 164,
		quantity: 427,
	},
	{
		garage_id: 166,
		part_id: 165,
		quantity: 16,
	},
	{
		garage_id: 166,
		part_id: 166,
		quantity: 277,
	},
	{
		garage_id: 166,
		part_id: 167,
		quantity: 318,
	},
	{
		garage_id: 166,
		part_id: 168,
		quantity: 63,
	},
	{
		garage_id: 166,
		part_id: 169,
		quantity: 351,
	},
	{
		garage_id: 166,
		part_id: 170,
		quantity: 83,
	},
	{
		garage_id: 166,
		part_id: 171,
		quantity: 340,
	},
	{
		garage_id: 166,
		part_id: 172,
		quantity: 331,
	},
	{
		garage_id: 166,
		part_id: 173,
		quantity: 364,
	},
	{
		garage_id: 166,
		part_id: 174,
		quantity: 204,
	},
	{
		garage_id: 166,
		part_id: 175,
		quantity: 69,
	},
	{
		garage_id: 166,
		part_id: 176,
		quantity: 191,
	},
	{
		garage_id: 166,
		part_id: 177,
		quantity: 34,
	},
	{
		garage_id: 166,
		part_id: 178,
		quantity: 341,
	},
	{
		garage_id: 166,
		part_id: 179,
		quantity: 131,
	},
	{
		garage_id: 166,
		part_id: 180,
		quantity: 453,
	},
	{
		garage_id: 166,
		part_id: 181,
		quantity: 323,
	},
	{
		garage_id: 166,
		part_id: 182,
		quantity: 21,
	},
	{
		garage_id: 166,
		part_id: 183,
		quantity: 109,
	},
	{
		garage_id: 166,
		part_id: 184,
		quantity: 118,
	},
	{
		garage_id: 166,
		part_id: 185,
		quantity: 25,
	},
	{
		garage_id: 166,
		part_id: 186,
		quantity: 60,
	},
	{
		garage_id: 166,
		part_id: 187,
		quantity: 56,
	},
	{
		garage_id: 166,
		part_id: 188,
		quantity: 288,
	},
	{
		garage_id: 166,
		part_id: 189,
		quantity: 47,
	},
	{
		garage_id: 166,
		part_id: 190,
		quantity: 386,
	},
	{
		garage_id: 166,
		part_id: 191,
		quantity: 244,
	},
	{
		garage_id: 166,
		part_id: 192,
		quantity: 475,
	},
	{
		garage_id: 166,
		part_id: 193,
		quantity: 40,
	},
	{
		garage_id: 166,
		part_id: 194,
		quantity: 279,
	},
	{
		garage_id: 166,
		part_id: 195,
		quantity: 415,
	},
	{
		garage_id: 166,
		part_id: 196,
		quantity: 85,
	},
	{
		garage_id: 166,
		part_id: 197,
		quantity: 393,
	},
	{
		garage_id: 166,
		part_id: 198,
		quantity: 234,
	},
	{
		garage_id: 166,
		part_id: 199,
		quantity: 430,
	},
	{
		garage_id: 166,
		part_id: 200,
		quantity: 7,
	},
	{
		garage_id: 166,
		part_id: 201,
		quantity: 81,
	},
	{
		garage_id: 166,
		part_id: 202,
		quantity: 49,
	},
	{
		garage_id: 166,
		part_id: 203,
		quantity: 429,
	},
	{
		garage_id: 166,
		part_id: 204,
		quantity: 485,
	},
	{
		garage_id: 166,
		part_id: 205,
		quantity: 64,
	},
	{
		garage_id: 166,
		part_id: 206,
		quantity: 169,
	},
	{
		garage_id: 166,
		part_id: 207,
		quantity: 274,
	},
	{
		garage_id: 166,
		part_id: 208,
		quantity: 134,
	},
	{
		garage_id: 166,
		part_id: 209,
		quantity: 27,
	},
	{
		garage_id: 166,
		part_id: 210,
		quantity: 0,
	},
	{
		garage_id: 166,
		part_id: 211,
		quantity: 352,
	},
	{
		garage_id: 166,
		part_id: 212,
		quantity: 39,
	},
	{
		garage_id: 166,
		part_id: 213,
		quantity: 23,
	},
	{
		garage_id: 166,
		part_id: 214,
		quantity: 498,
	},
	{
		garage_id: 166,
		part_id: 215,
		quantity: 302,
	},
	{
		garage_id: 166,
		part_id: 216,
		quantity: 85,
	},
	{
		garage_id: 166,
		part_id: 217,
		quantity: 168,
	},
	{
		garage_id: 166,
		part_id: 218,
		quantity: 395,
	},
	{
		garage_id: 166,
		part_id: 219,
		quantity: 262,
	},
	{
		garage_id: 166,
		part_id: 220,
		quantity: 301,
	},
	{
		garage_id: 166,
		part_id: 221,
		quantity: 476,
	},
	{
		garage_id: 166,
		part_id: 222,
		quantity: 116,
	},
	{
		garage_id: 166,
		part_id: 223,
		quantity: 295,
	},
	{
		garage_id: 166,
		part_id: 224,
		quantity: 294,
	},
	{
		garage_id: 166,
		part_id: 225,
		quantity: 190,
	},
	{
		garage_id: 166,
		part_id: 226,
		quantity: 414,
	},
	{
		garage_id: 166,
		part_id: 227,
		quantity: 24,
	},
	{
		garage_id: 166,
		part_id: 228,
		quantity: 338,
	},
	{
		garage_id: 166,
		part_id: 229,
		quantity: 499,
	},
	{
		garage_id: 166,
		part_id: 230,
		quantity: 477,
	},
	{
		garage_id: 166,
		part_id: 231,
		quantity: 162,
	},
	{
		garage_id: 166,
		part_id: 232,
		quantity: 30,
	},
	{
		garage_id: 166,
		part_id: 233,
		quantity: 15,
	},
	{
		garage_id: 166,
		part_id: 234,
		quantity: 115,
	},
	{
		garage_id: 166,
		part_id: 235,
		quantity: 287,
	},
	{
		garage_id: 166,
		part_id: 236,
		quantity: 446,
	},
	{
		garage_id: 166,
		part_id: 237,
		quantity: 369,
	},
	{
		garage_id: 166,
		part_id: 238,
		quantity: 93,
	},
	{
		garage_id: 166,
		part_id: 239,
		quantity: 236,
	},
	{
		garage_id: 166,
		part_id: 240,
		quantity: 415,
	},
	{
		garage_id: 166,
		part_id: 241,
		quantity: 259,
	},
	{
		garage_id: 166,
		part_id: 242,
		quantity: 332,
	},
	{
		garage_id: 166,
		part_id: 243,
		quantity: 320,
	},
	{
		garage_id: 166,
		part_id: 244,
		quantity: 428,
	},
	{
		garage_id: 166,
		part_id: 245,
		quantity: 242,
	},
	{
		garage_id: 166,
		part_id: 246,
		quantity: 420,
	},
	{
		garage_id: 166,
		part_id: 247,
		quantity: 150,
	},
	{
		garage_id: 166,
		part_id: 248,
		quantity: 70,
	},
	{
		garage_id: 166,
		part_id: 249,
		quantity: 219,
	},
	{
		garage_id: 166,
		part_id: 250,
		quantity: 449,
	},
	{
		garage_id: 166,
		part_id: 251,
		quantity: 27,
	},
	{
		garage_id: 166,
		part_id: 252,
		quantity: 254,
	},
	{
		garage_id: 166,
		part_id: 253,
		quantity: 248,
	},
	{
		garage_id: 166,
		part_id: 254,
		quantity: 498,
	},
	{
		garage_id: 166,
		part_id: 255,
		quantity: 440,
	},
	{
		garage_id: 166,
		part_id: 256,
		quantity: 389,
	},
	{
		garage_id: 166,
		part_id: 257,
		quantity: 187,
	},
	{
		garage_id: 166,
		part_id: 258,
		quantity: 451,
	},
	{
		garage_id: 166,
		part_id: 259,
		quantity: 150,
	},
	{
		garage_id: 166,
		part_id: 260,
		quantity: 272,
	},
	{
		garage_id: 166,
		part_id: 261,
		quantity: 129,
	},
	{
		garage_id: 166,
		part_id: 262,
		quantity: 276,
	},
	{
		garage_id: 166,
		part_id: 263,
		quantity: 242,
	},
	{
		garage_id: 166,
		part_id: 264,
		quantity: 450,
	},
	{
		garage_id: 166,
		part_id: 265,
		quantity: 77,
	},
	{
		garage_id: 166,
		part_id: 266,
		quantity: 263,
	},
	{
		garage_id: 166,
		part_id: 267,
		quantity: 34,
	},
	{
		garage_id: 166,
		part_id: 268,
		quantity: 480,
	},
	{
		garage_id: 166,
		part_id: 269,
		quantity: 130,
	},
	{
		garage_id: 166,
		part_id: 270,
		quantity: 461,
	},
	{
		garage_id: 166,
		part_id: 271,
		quantity: 354,
	},
	{
		garage_id: 166,
		part_id: 272,
		quantity: 190,
	},
	{
		garage_id: 166,
		part_id: 273,
		quantity: 364,
	},
	{
		garage_id: 166,
		part_id: 274,
		quantity: 102,
	},
	{
		garage_id: 166,
		part_id: 275,
		quantity: 433,
	},
	{
		garage_id: 166,
		part_id: 276,
		quantity: 416,
	},
	{
		garage_id: 166,
		part_id: 277,
		quantity: 430,
	},
	{
		garage_id: 166,
		part_id: 278,
		quantity: 257,
	},
	{
		garage_id: 166,
		part_id: 279,
		quantity: 164,
	},
	{
		garage_id: 166,
		part_id: 280,
		quantity: 283,
	},
	{
		garage_id: 166,
		part_id: 281,
		quantity: 479,
	},
	{
		garage_id: 166,
		part_id: 282,
		quantity: 474,
	},
	{
		garage_id: 166,
		part_id: 283,
		quantity: 100,
	},
	{
		garage_id: 166,
		part_id: 284,
		quantity: 27,
	},
	{
		garage_id: 166,
		part_id: 285,
		quantity: 301,
	},
	{
		garage_id: 166,
		part_id: 286,
		quantity: 37,
	},
	{
		garage_id: 166,
		part_id: 287,
		quantity: 262,
	},
	{
		garage_id: 166,
		part_id: 288,
		quantity: 82,
	},
	{
		garage_id: 166,
		part_id: 289,
		quantity: 60,
	},
	{
		garage_id: 166,
		part_id: 290,
		quantity: 262,
	},
	{
		garage_id: 166,
		part_id: 291,
		quantity: 293,
	},
	{
		garage_id: 166,
		part_id: 292,
		quantity: 323,
	},
	{
		garage_id: 166,
		part_id: 293,
		quantity: 373,
	},
	{
		garage_id: 166,
		part_id: 294,
		quantity: 229,
	},
	{
		garage_id: 166,
		part_id: 295,
		quantity: 80,
	},
	{
		garage_id: 166,
		part_id: 296,
		quantity: 398,
	},
	{
		garage_id: 166,
		part_id: 297,
		quantity: 493,
	},
	{
		garage_id: 166,
		part_id: 298,
		quantity: 451,
	},
	{
		garage_id: 166,
		part_id: 299,
		quantity: 79,
	},
	{
		garage_id: 166,
		part_id: 300,
		quantity: 451,
	},
	{
		garage_id: 166,
		part_id: 301,
		quantity: 476,
	},
	{
		garage_id: 166,
		part_id: 302,
		quantity: 282,
	},
	{
		garage_id: 166,
		part_id: 303,
		quantity: 421,
	},
	{
		garage_id: 166,
		part_id: 304,
		quantity: 233,
	},
	{
		garage_id: 166,
		part_id: 305,
		quantity: 121,
	},
	{
		garage_id: 166,
		part_id: 306,
		quantity: 202,
	},
	{
		garage_id: 166,
		part_id: 307,
		quantity: 449,
	},
	{
		garage_id: 166,
		part_id: 308,
		quantity: 313,
	},
	{
		garage_id: 166,
		part_id: 309,
		quantity: 84,
	},
	{
		garage_id: 166,
		part_id: 310,
		quantity: 47,
	},
	{
		garage_id: 166,
		part_id: 311,
		quantity: 69,
	},
	{
		garage_id: 166,
		part_id: 312,
		quantity: 93,
	},
	{
		garage_id: 166,
		part_id: 313,
		quantity: 295,
	},
	{
		garage_id: 166,
		part_id: 314,
		quantity: 85,
	},
	{
		garage_id: 166,
		part_id: 315,
		quantity: 381,
	},
	{
		garage_id: 166,
		part_id: 316,
		quantity: 286,
	},
	{
		garage_id: 166,
		part_id: 317,
		quantity: 292,
	},
	{
		garage_id: 166,
		part_id: 318,
		quantity: 373,
	},
	{
		garage_id: 166,
		part_id: 319,
		quantity: 433,
	},
	{
		garage_id: 166,
		part_id: 320,
		quantity: 387,
	},
	{
		garage_id: 166,
		part_id: 321,
		quantity: 92,
	},
	{
		garage_id: 166,
		part_id: 322,
		quantity: 381,
	},
	{
		garage_id: 166,
		part_id: 323,
		quantity: 330,
	},
	{
		garage_id: 166,
		part_id: 324,
		quantity: 146,
	},
	{
		garage_id: 166,
		part_id: 325,
		quantity: 395,
	},
	{
		garage_id: 166,
		part_id: 326,
		quantity: 26,
	},
	{
		garage_id: 166,
		part_id: 327,
		quantity: 250,
	},
	{
		garage_id: 166,
		part_id: 328,
		quantity: 32,
	},
	{
		garage_id: 166,
		part_id: 329,
		quantity: 7,
	},
	{
		garage_id: 166,
		part_id: 330,
		quantity: 359,
	},
	{
		garage_id: 166,
		part_id: 331,
		quantity: 35,
	},
	{
		garage_id: 166,
		part_id: 332,
		quantity: 198,
	},
	{
		garage_id: 166,
		part_id: 333,
		quantity: 424,
	},
	{
		garage_id: 166,
		part_id: 334,
		quantity: 219,
	},
	{
		garage_id: 166,
		part_id: 335,
		quantity: 125,
	},
	{
		garage_id: 166,
		part_id: 336,
		quantity: 301,
	},
	{
		garage_id: 166,
		part_id: 337,
		quantity: 92,
	},
	{
		garage_id: 166,
		part_id: 338,
		quantity: 498,
	},
	{
		garage_id: 166,
		part_id: 339,
		quantity: 435,
	},
	{
		garage_id: 166,
		part_id: 340,
		quantity: 296,
	},
	{
		garage_id: 166,
		part_id: 341,
		quantity: 306,
	},
	{
		garage_id: 166,
		part_id: 342,
		quantity: 182,
	},
	{
		garage_id: 166,
		part_id: 343,
		quantity: 144,
	},
	{
		garage_id: 166,
		part_id: 344,
		quantity: 284,
	},
	{
		garage_id: 166,
		part_id: 345,
		quantity: 67,
	},
	{
		garage_id: 166,
		part_id: 346,
		quantity: 115,
	},
	{
		garage_id: 166,
		part_id: 347,
		quantity: 91,
	},
	{
		garage_id: 166,
		part_id: 348,
		quantity: 0,
	},
	{
		garage_id: 166,
		part_id: 349,
		quantity: 111,
	},
	{
		garage_id: 166,
		part_id: 350,
		quantity: 81,
	},
	{
		garage_id: 166,
		part_id: 351,
		quantity: 456,
	},
	{
		garage_id: 166,
		part_id: 352,
		quantity: 144,
	},
	{
		garage_id: 166,
		part_id: 353,
		quantity: 293,
	},
	{
		garage_id: 166,
		part_id: 354,
		quantity: 263,
	},
	{
		garage_id: 166,
		part_id: 355,
		quantity: 79,
	},
	{
		garage_id: 166,
		part_id: 356,
		quantity: 202,
	},
	{
		garage_id: 166,
		part_id: 357,
		quantity: 336,
	},
	{
		garage_id: 166,
		part_id: 358,
		quantity: 97,
	},
	{
		garage_id: 166,
		part_id: 359,
		quantity: 271,
	},
	{
		garage_id: 166,
		part_id: 360,
		quantity: 470,
	},
	{
		garage_id: 166,
		part_id: 361,
		quantity: 173,
	},
	{
		garage_id: 166,
		part_id: 362,
		quantity: 183,
	},
	{
		garage_id: 166,
		part_id: 363,
		quantity: 376,
	},
	{
		garage_id: 166,
		part_id: 364,
		quantity: 459,
	},
	{
		garage_id: 166,
		part_id: 365,
		quantity: 452,
	},
	{
		garage_id: 166,
		part_id: 366,
		quantity: 101,
	},
	{
		garage_id: 166,
		part_id: 367,
		quantity: 260,
	},
	{
		garage_id: 166,
		part_id: 368,
		quantity: 482,
	},
	{
		garage_id: 166,
		part_id: 369,
		quantity: 53,
	},
	{
		garage_id: 166,
		part_id: 370,
		quantity: 303,
	},
	{
		garage_id: 166,
		part_id: 371,
		quantity: 43,
	},
	{
		garage_id: 166,
		part_id: 372,
		quantity: 33,
	},
	{
		garage_id: 166,
		part_id: 373,
		quantity: 237,
	},
	{
		garage_id: 166,
		part_id: 374,
		quantity: 209,
	},
	{
		garage_id: 166,
		part_id: 375,
		quantity: 250,
	},
	{
		garage_id: 166,
		part_id: 376,
		quantity: 181,
	},
	{
		garage_id: 166,
		part_id: 377,
		quantity: 78,
	},
	{
		garage_id: 166,
		part_id: 378,
		quantity: 183,
	},
	{
		garage_id: 166,
		part_id: 379,
		quantity: 153,
	},
	{
		garage_id: 166,
		part_id: 380,
		quantity: 195,
	},
	{
		garage_id: 166,
		part_id: 381,
		quantity: 229,
	},
	{
		garage_id: 166,
		part_id: 382,
		quantity: 358,
	},
	{
		garage_id: 166,
		part_id: 383,
		quantity: 175,
	},
	{
		garage_id: 166,
		part_id: 384,
		quantity: 421,
	},
	{
		garage_id: 166,
		part_id: 385,
		quantity: 158,
	},
	{
		garage_id: 166,
		part_id: 386,
		quantity: 364,
	},
	{
		garage_id: 166,
		part_id: 387,
		quantity: 217,
	},
	{
		garage_id: 166,
		part_id: 388,
		quantity: 478,
	},
	{
		garage_id: 166,
		part_id: 389,
		quantity: 482,
	},
	{
		garage_id: 166,
		part_id: 390,
		quantity: 143,
	},
	{
		garage_id: 166,
		part_id: 391,
		quantity: 451,
	},
	{
		garage_id: 166,
		part_id: 392,
		quantity: 91,
	},
	{
		garage_id: 166,
		part_id: 393,
		quantity: 177,
	},
	{
		garage_id: 166,
		part_id: 394,
		quantity: 146,
	},
	{
		garage_id: 166,
		part_id: 395,
		quantity: 270,
	},
	{
		garage_id: 166,
		part_id: 396,
		quantity: 195,
	},
	{
		garage_id: 166,
		part_id: 397,
		quantity: 156,
	},
	{
		garage_id: 166,
		part_id: 398,
		quantity: 380,
	},
	{
		garage_id: 166,
		part_id: 399,
		quantity: 236,
	},
	{
		garage_id: 167,
		part_id: 0,
		quantity: 249,
	},
	{
		garage_id: 167,
		part_id: 1,
		quantity: 185,
	},
	{
		garage_id: 167,
		part_id: 2,
		quantity: 308,
	},
	{
		garage_id: 167,
		part_id: 3,
		quantity: 446,
	},
	{
		garage_id: 167,
		part_id: 4,
		quantity: 364,
	},
	{
		garage_id: 167,
		part_id: 5,
		quantity: 476,
	},
	{
		garage_id: 167,
		part_id: 6,
		quantity: 161,
	},
	{
		garage_id: 167,
		part_id: 7,
		quantity: 53,
	},
	{
		garage_id: 167,
		part_id: 8,
		quantity: 14,
	},
	{
		garage_id: 167,
		part_id: 9,
		quantity: 73,
	},
	{
		garage_id: 167,
		part_id: 10,
		quantity: 326,
	},
	{
		garage_id: 167,
		part_id: 11,
		quantity: 228,
	},
	{
		garage_id: 167,
		part_id: 12,
		quantity: 224,
	},
	{
		garage_id: 167,
		part_id: 13,
		quantity: 206,
	},
	{
		garage_id: 167,
		part_id: 14,
		quantity: 307,
	},
	{
		garage_id: 167,
		part_id: 15,
		quantity: 319,
	},
	{
		garage_id: 167,
		part_id: 16,
		quantity: 72,
	},
	{
		garage_id: 167,
		part_id: 17,
		quantity: 279,
	},
	{
		garage_id: 167,
		part_id: 18,
		quantity: 12,
	},
	{
		garage_id: 167,
		part_id: 19,
		quantity: 240,
	},
	{
		garage_id: 167,
		part_id: 20,
		quantity: 301,
	},
	{
		garage_id: 167,
		part_id: 21,
		quantity: 19,
	},
	{
		garage_id: 167,
		part_id: 22,
		quantity: 225,
	},
	{
		garage_id: 167,
		part_id: 23,
		quantity: 37,
	},
	{
		garage_id: 167,
		part_id: 24,
		quantity: 92,
	},
	{
		garage_id: 167,
		part_id: 25,
		quantity: 340,
	},
	{
		garage_id: 167,
		part_id: 26,
		quantity: 27,
	},
	{
		garage_id: 167,
		part_id: 27,
		quantity: 75,
	},
	{
		garage_id: 167,
		part_id: 28,
		quantity: 44,
	},
	{
		garage_id: 167,
		part_id: 29,
		quantity: 493,
	},
	{
		garage_id: 167,
		part_id: 30,
		quantity: 160,
	},
	{
		garage_id: 167,
		part_id: 31,
		quantity: 462,
	},
	{
		garage_id: 167,
		part_id: 32,
		quantity: 12,
	},
	{
		garage_id: 167,
		part_id: 33,
		quantity: 306,
	},
	{
		garage_id: 167,
		part_id: 34,
		quantity: 106,
	},
	{
		garage_id: 167,
		part_id: 35,
		quantity: 23,
	},
	{
		garage_id: 167,
		part_id: 36,
		quantity: 147,
	},
	{
		garage_id: 167,
		part_id: 37,
		quantity: 277,
	},
	{
		garage_id: 167,
		part_id: 38,
		quantity: 3,
	},
	{
		garage_id: 167,
		part_id: 39,
		quantity: 7,
	},
	{
		garage_id: 167,
		part_id: 40,
		quantity: 33,
	},
	{
		garage_id: 167,
		part_id: 41,
		quantity: 91,
	},
	{
		garage_id: 167,
		part_id: 42,
		quantity: 219,
	},
	{
		garage_id: 167,
		part_id: 43,
		quantity: 215,
	},
	{
		garage_id: 167,
		part_id: 44,
		quantity: 360,
	},
	{
		garage_id: 167,
		part_id: 45,
		quantity: 195,
	},
	{
		garage_id: 167,
		part_id: 46,
		quantity: 33,
	},
	{
		garage_id: 167,
		part_id: 47,
		quantity: 236,
	},
	{
		garage_id: 167,
		part_id: 48,
		quantity: 294,
	},
	{
		garage_id: 167,
		part_id: 49,
		quantity: 352,
	},
	{
		garage_id: 167,
		part_id: 50,
		quantity: 89,
	},
	{
		garage_id: 167,
		part_id: 51,
		quantity: 253,
	},
	{
		garage_id: 167,
		part_id: 52,
		quantity: 317,
	},
	{
		garage_id: 167,
		part_id: 53,
		quantity: 359,
	},
	{
		garage_id: 167,
		part_id: 54,
		quantity: 226,
	},
	{
		garage_id: 167,
		part_id: 55,
		quantity: 222,
	},
	{
		garage_id: 167,
		part_id: 56,
		quantity: 16,
	},
	{
		garage_id: 167,
		part_id: 57,
		quantity: 257,
	},
	{
		garage_id: 167,
		part_id: 58,
		quantity: 39,
	},
	{
		garage_id: 167,
		part_id: 59,
		quantity: 245,
	},
	{
		garage_id: 167,
		part_id: 60,
		quantity: 468,
	},
	{
		garage_id: 167,
		part_id: 61,
		quantity: 38,
	},
	{
		garage_id: 167,
		part_id: 62,
		quantity: 91,
	},
	{
		garage_id: 167,
		part_id: 63,
		quantity: 396,
	},
	{
		garage_id: 167,
		part_id: 64,
		quantity: 36,
	},
	{
		garage_id: 167,
		part_id: 65,
		quantity: 368,
	},
	{
		garage_id: 167,
		part_id: 66,
		quantity: 58,
	},
	{
		garage_id: 167,
		part_id: 67,
		quantity: 144,
	},
	{
		garage_id: 167,
		part_id: 68,
		quantity: 296,
	},
	{
		garage_id: 167,
		part_id: 69,
		quantity: 65,
	},
	{
		garage_id: 167,
		part_id: 70,
		quantity: 241,
	},
	{
		garage_id: 167,
		part_id: 71,
		quantity: 339,
	},
	{
		garage_id: 167,
		part_id: 72,
		quantity: 382,
	},
	{
		garage_id: 167,
		part_id: 73,
		quantity: 207,
	},
	{
		garage_id: 167,
		part_id: 74,
		quantity: 221,
	},
	{
		garage_id: 167,
		part_id: 75,
		quantity: 408,
	},
	{
		garage_id: 167,
		part_id: 76,
		quantity: 152,
	},
	{
		garage_id: 167,
		part_id: 77,
		quantity: 274,
	},
	{
		garage_id: 167,
		part_id: 78,
		quantity: 237,
	},
	{
		garage_id: 167,
		part_id: 79,
		quantity: 265,
	},
	{
		garage_id: 167,
		part_id: 80,
		quantity: 451,
	},
	{
		garage_id: 167,
		part_id: 81,
		quantity: 219,
	},
	{
		garage_id: 167,
		part_id: 82,
		quantity: 147,
	},
	{
		garage_id: 167,
		part_id: 83,
		quantity: 196,
	},
	{
		garage_id: 167,
		part_id: 84,
		quantity: 311,
	},
	{
		garage_id: 167,
		part_id: 85,
		quantity: 142,
	},
	{
		garage_id: 167,
		part_id: 86,
		quantity: 96,
	},
	{
		garage_id: 167,
		part_id: 87,
		quantity: 404,
	},
	{
		garage_id: 167,
		part_id: 88,
		quantity: 478,
	},
	{
		garage_id: 167,
		part_id: 89,
		quantity: 425,
	},
	{
		garage_id: 167,
		part_id: 90,
		quantity: 89,
	},
	{
		garage_id: 167,
		part_id: 91,
		quantity: 433,
	},
	{
		garage_id: 167,
		part_id: 92,
		quantity: 202,
	},
	{
		garage_id: 167,
		part_id: 93,
		quantity: 51,
	},
	{
		garage_id: 167,
		part_id: 94,
		quantity: 275,
	},
	{
		garage_id: 167,
		part_id: 95,
		quantity: 378,
	},
	{
		garage_id: 167,
		part_id: 96,
		quantity: 475,
	},
	{
		garage_id: 167,
		part_id: 97,
		quantity: 412,
	},
	{
		garage_id: 167,
		part_id: 98,
		quantity: 47,
	},
	{
		garage_id: 167,
		part_id: 99,
		quantity: 26,
	},
	{
		garage_id: 167,
		part_id: 100,
		quantity: 88,
	},
	{
		garage_id: 167,
		part_id: 101,
		quantity: 371,
	},
	{
		garage_id: 167,
		part_id: 102,
		quantity: 75,
	},
	{
		garage_id: 167,
		part_id: 103,
		quantity: 315,
	},
	{
		garage_id: 167,
		part_id: 104,
		quantity: 445,
	},
	{
		garage_id: 167,
		part_id: 105,
		quantity: 373,
	},
	{
		garage_id: 167,
		part_id: 106,
		quantity: 354,
	},
	{
		garage_id: 167,
		part_id: 107,
		quantity: 122,
	},
	{
		garage_id: 167,
		part_id: 108,
		quantity: 334,
	},
	{
		garage_id: 167,
		part_id: 109,
		quantity: 301,
	},
	{
		garage_id: 167,
		part_id: 110,
		quantity: 278,
	},
	{
		garage_id: 167,
		part_id: 111,
		quantity: 148,
	},
	{
		garage_id: 167,
		part_id: 112,
		quantity: 239,
	},
	{
		garage_id: 167,
		part_id: 113,
		quantity: 322,
	},
	{
		garage_id: 167,
		part_id: 114,
		quantity: 104,
	},
	{
		garage_id: 167,
		part_id: 115,
		quantity: 94,
	},
	{
		garage_id: 167,
		part_id: 116,
		quantity: 403,
	},
	{
		garage_id: 167,
		part_id: 117,
		quantity: 412,
	},
	{
		garage_id: 167,
		part_id: 118,
		quantity: 375,
	},
	{
		garage_id: 167,
		part_id: 119,
		quantity: 210,
	},
	{
		garage_id: 167,
		part_id: 120,
		quantity: 52,
	},
	{
		garage_id: 167,
		part_id: 121,
		quantity: 191,
	},
	{
		garage_id: 167,
		part_id: 122,
		quantity: 426,
	},
	{
		garage_id: 167,
		part_id: 123,
		quantity: 328,
	},
	{
		garage_id: 167,
		part_id: 124,
		quantity: 204,
	},
	{
		garage_id: 167,
		part_id: 125,
		quantity: 478,
	},
	{
		garage_id: 167,
		part_id: 126,
		quantity: 276,
	},
	{
		garage_id: 167,
		part_id: 127,
		quantity: 112,
	},
	{
		garage_id: 167,
		part_id: 128,
		quantity: 402,
	},
	{
		garage_id: 167,
		part_id: 129,
		quantity: 277,
	},
	{
		garage_id: 167,
		part_id: 130,
		quantity: 37,
	},
	{
		garage_id: 167,
		part_id: 131,
		quantity: 16,
	},
	{
		garage_id: 167,
		part_id: 132,
		quantity: 363,
	},
	{
		garage_id: 167,
		part_id: 133,
		quantity: 349,
	},
	{
		garage_id: 167,
		part_id: 134,
		quantity: 196,
	},
	{
		garage_id: 167,
		part_id: 135,
		quantity: 418,
	},
	{
		garage_id: 167,
		part_id: 136,
		quantity: 214,
	},
	{
		garage_id: 167,
		part_id: 137,
		quantity: 330,
	},
	{
		garage_id: 167,
		part_id: 138,
		quantity: 227,
	},
	{
		garage_id: 167,
		part_id: 139,
		quantity: 431,
	},
	{
		garage_id: 167,
		part_id: 140,
		quantity: 283,
	},
	{
		garage_id: 167,
		part_id: 141,
		quantity: 163,
	},
	{
		garage_id: 167,
		part_id: 142,
		quantity: 484,
	},
	{
		garage_id: 167,
		part_id: 143,
		quantity: 420,
	},
	{
		garage_id: 167,
		part_id: 144,
		quantity: 492,
	},
	{
		garage_id: 167,
		part_id: 145,
		quantity: 145,
	},
	{
		garage_id: 167,
		part_id: 146,
		quantity: 362,
	},
	{
		garage_id: 167,
		part_id: 147,
		quantity: 350,
	},
	{
		garage_id: 167,
		part_id: 148,
		quantity: 472,
	},
	{
		garage_id: 167,
		part_id: 149,
		quantity: 201,
	},
	{
		garage_id: 167,
		part_id: 150,
		quantity: 222,
	},
	{
		garage_id: 167,
		part_id: 151,
		quantity: 43,
	},
	{
		garage_id: 167,
		part_id: 152,
		quantity: 493,
	},
	{
		garage_id: 167,
		part_id: 153,
		quantity: 453,
	},
	{
		garage_id: 167,
		part_id: 154,
		quantity: 361,
	},
	{
		garage_id: 167,
		part_id: 155,
		quantity: 421,
	},
	{
		garage_id: 167,
		part_id: 156,
		quantity: 161,
	},
	{
		garage_id: 167,
		part_id: 157,
		quantity: 189,
	},
	{
		garage_id: 167,
		part_id: 158,
		quantity: 333,
	},
	{
		garage_id: 167,
		part_id: 159,
		quantity: 240,
	},
	{
		garage_id: 167,
		part_id: 160,
		quantity: 62,
	},
	{
		garage_id: 167,
		part_id: 161,
		quantity: 176,
	},
	{
		garage_id: 167,
		part_id: 162,
		quantity: 383,
	},
	{
		garage_id: 167,
		part_id: 163,
		quantity: 278,
	},
	{
		garage_id: 167,
		part_id: 164,
		quantity: 146,
	},
	{
		garage_id: 167,
		part_id: 165,
		quantity: 262,
	},
	{
		garage_id: 167,
		part_id: 166,
		quantity: 459,
	},
	{
		garage_id: 167,
		part_id: 167,
		quantity: 289,
	},
	{
		garage_id: 167,
		part_id: 168,
		quantity: 97,
	},
	{
		garage_id: 167,
		part_id: 169,
		quantity: 402,
	},
	{
		garage_id: 167,
		part_id: 170,
		quantity: 108,
	},
	{
		garage_id: 167,
		part_id: 171,
		quantity: 143,
	},
	{
		garage_id: 167,
		part_id: 172,
		quantity: 300,
	},
	{
		garage_id: 167,
		part_id: 173,
		quantity: 404,
	},
	{
		garage_id: 167,
		part_id: 174,
		quantity: 291,
	},
	{
		garage_id: 167,
		part_id: 175,
		quantity: 231,
	},
	{
		garage_id: 167,
		part_id: 176,
		quantity: 196,
	},
	{
		garage_id: 167,
		part_id: 177,
		quantity: 131,
	},
	{
		garage_id: 167,
		part_id: 178,
		quantity: 311,
	},
	{
		garage_id: 167,
		part_id: 179,
		quantity: 41,
	},
	{
		garage_id: 167,
		part_id: 180,
		quantity: 131,
	},
	{
		garage_id: 167,
		part_id: 181,
		quantity: 179,
	},
	{
		garage_id: 167,
		part_id: 182,
		quantity: 137,
	},
	{
		garage_id: 167,
		part_id: 183,
		quantity: 195,
	},
	{
		garage_id: 167,
		part_id: 184,
		quantity: 144,
	},
	{
		garage_id: 167,
		part_id: 185,
		quantity: 160,
	},
	{
		garage_id: 167,
		part_id: 186,
		quantity: 481,
	},
	{
		garage_id: 167,
		part_id: 187,
		quantity: 206,
	},
	{
		garage_id: 167,
		part_id: 188,
		quantity: 116,
	},
	{
		garage_id: 167,
		part_id: 189,
		quantity: 306,
	},
	{
		garage_id: 167,
		part_id: 190,
		quantity: 406,
	},
	{
		garage_id: 167,
		part_id: 191,
		quantity: 82,
	},
	{
		garage_id: 167,
		part_id: 192,
		quantity: 258,
	},
	{
		garage_id: 167,
		part_id: 193,
		quantity: 498,
	},
	{
		garage_id: 167,
		part_id: 194,
		quantity: 175,
	},
	{
		garage_id: 167,
		part_id: 195,
		quantity: 412,
	},
	{
		garage_id: 167,
		part_id: 196,
		quantity: 444,
	},
	{
		garage_id: 167,
		part_id: 197,
		quantity: 130,
	},
	{
		garage_id: 167,
		part_id: 198,
		quantity: 68,
	},
	{
		garage_id: 167,
		part_id: 199,
		quantity: 42,
	},
	{
		garage_id: 167,
		part_id: 200,
		quantity: 495,
	},
	{
		garage_id: 167,
		part_id: 201,
		quantity: 66,
	},
	{
		garage_id: 167,
		part_id: 202,
		quantity: 251,
	},
	{
		garage_id: 167,
		part_id: 203,
		quantity: 400,
	},
	{
		garage_id: 167,
		part_id: 204,
		quantity: 2,
	},
	{
		garage_id: 167,
		part_id: 205,
		quantity: 388,
	},
	{
		garage_id: 167,
		part_id: 206,
		quantity: 285,
	},
	{
		garage_id: 167,
		part_id: 207,
		quantity: 348,
	},
	{
		garage_id: 167,
		part_id: 208,
		quantity: 249,
	},
	{
		garage_id: 167,
		part_id: 209,
		quantity: 275,
	},
	{
		garage_id: 167,
		part_id: 210,
		quantity: 256,
	},
	{
		garage_id: 167,
		part_id: 211,
		quantity: 207,
	},
	{
		garage_id: 167,
		part_id: 212,
		quantity: 340,
	},
	{
		garage_id: 167,
		part_id: 213,
		quantity: 438,
	},
	{
		garage_id: 167,
		part_id: 214,
		quantity: 392,
	},
	{
		garage_id: 167,
		part_id: 215,
		quantity: 451,
	},
	{
		garage_id: 167,
		part_id: 216,
		quantity: 294,
	},
	{
		garage_id: 167,
		part_id: 217,
		quantity: 149,
	},
	{
		garage_id: 167,
		part_id: 218,
		quantity: 456,
	},
	{
		garage_id: 167,
		part_id: 219,
		quantity: 368,
	},
	{
		garage_id: 167,
		part_id: 220,
		quantity: 198,
	},
	{
		garage_id: 167,
		part_id: 221,
		quantity: 464,
	},
	{
		garage_id: 167,
		part_id: 222,
		quantity: 172,
	},
	{
		garage_id: 167,
		part_id: 223,
		quantity: 273,
	},
	{
		garage_id: 167,
		part_id: 224,
		quantity: 221,
	},
	{
		garage_id: 167,
		part_id: 225,
		quantity: 77,
	},
	{
		garage_id: 167,
		part_id: 226,
		quantity: 84,
	},
	{
		garage_id: 167,
		part_id: 227,
		quantity: 145,
	},
	{
		garage_id: 167,
		part_id: 228,
		quantity: 35,
	},
	{
		garage_id: 167,
		part_id: 229,
		quantity: 280,
	},
	{
		garage_id: 167,
		part_id: 230,
		quantity: 331,
	},
	{
		garage_id: 167,
		part_id: 231,
		quantity: 316,
	},
	{
		garage_id: 167,
		part_id: 232,
		quantity: 315,
	},
	{
		garage_id: 167,
		part_id: 233,
		quantity: 22,
	},
	{
		garage_id: 167,
		part_id: 234,
		quantity: 258,
	},
	{
		garage_id: 167,
		part_id: 235,
		quantity: 276,
	},
	{
		garage_id: 167,
		part_id: 236,
		quantity: 234,
	},
	{
		garage_id: 167,
		part_id: 237,
		quantity: 404,
	},
	{
		garage_id: 167,
		part_id: 238,
		quantity: 363,
	},
	{
		garage_id: 167,
		part_id: 239,
		quantity: 398,
	},
	{
		garage_id: 167,
		part_id: 240,
		quantity: 167,
	},
	{
		garage_id: 167,
		part_id: 241,
		quantity: 420,
	},
	{
		garage_id: 167,
		part_id: 242,
		quantity: 388,
	},
	{
		garage_id: 167,
		part_id: 243,
		quantity: 192,
	},
	{
		garage_id: 167,
		part_id: 244,
		quantity: 240,
	},
	{
		garage_id: 167,
		part_id: 245,
		quantity: 442,
	},
	{
		garage_id: 167,
		part_id: 246,
		quantity: 156,
	},
	{
		garage_id: 167,
		part_id: 247,
		quantity: 205,
	},
	{
		garage_id: 167,
		part_id: 248,
		quantity: 120,
	},
	{
		garage_id: 167,
		part_id: 249,
		quantity: 300,
	},
	{
		garage_id: 167,
		part_id: 250,
		quantity: 357,
	},
	{
		garage_id: 167,
		part_id: 251,
		quantity: 53,
	},
	{
		garage_id: 167,
		part_id: 252,
		quantity: 380,
	},
	{
		garage_id: 167,
		part_id: 253,
		quantity: 181,
	},
	{
		garage_id: 167,
		part_id: 254,
		quantity: 488,
	},
	{
		garage_id: 167,
		part_id: 255,
		quantity: 433,
	},
	{
		garage_id: 167,
		part_id: 256,
		quantity: 358,
	},
	{
		garage_id: 167,
		part_id: 257,
		quantity: 298,
	},
	{
		garage_id: 167,
		part_id: 258,
		quantity: 308,
	},
	{
		garage_id: 167,
		part_id: 259,
		quantity: 439,
	},
	{
		garage_id: 167,
		part_id: 260,
		quantity: 21,
	},
	{
		garage_id: 167,
		part_id: 261,
		quantity: 375,
	},
	{
		garage_id: 167,
		part_id: 262,
		quantity: 59,
	},
	{
		garage_id: 167,
		part_id: 263,
		quantity: 422,
	},
	{
		garage_id: 167,
		part_id: 264,
		quantity: 451,
	},
	{
		garage_id: 167,
		part_id: 265,
		quantity: 421,
	},
	{
		garage_id: 167,
		part_id: 266,
		quantity: 205,
	},
	{
		garage_id: 167,
		part_id: 267,
		quantity: 197,
	},
	{
		garage_id: 167,
		part_id: 268,
		quantity: 359,
	},
	{
		garage_id: 167,
		part_id: 269,
		quantity: 146,
	},
	{
		garage_id: 167,
		part_id: 270,
		quantity: 412,
	},
	{
		garage_id: 167,
		part_id: 271,
		quantity: 84,
	},
	{
		garage_id: 167,
		part_id: 272,
		quantity: 108,
	},
	{
		garage_id: 167,
		part_id: 273,
		quantity: 128,
	},
	{
		garage_id: 167,
		part_id: 274,
		quantity: 102,
	},
	{
		garage_id: 167,
		part_id: 275,
		quantity: 361,
	},
	{
		garage_id: 167,
		part_id: 276,
		quantity: 67,
	},
	{
		garage_id: 167,
		part_id: 277,
		quantity: 1,
	},
	{
		garage_id: 167,
		part_id: 278,
		quantity: 487,
	},
	{
		garage_id: 167,
		part_id: 279,
		quantity: 291,
	},
	{
		garage_id: 167,
		part_id: 280,
		quantity: 320,
	},
	{
		garage_id: 167,
		part_id: 281,
		quantity: 195,
	},
	{
		garage_id: 167,
		part_id: 282,
		quantity: 253,
	},
	{
		garage_id: 167,
		part_id: 283,
		quantity: 325,
	},
	{
		garage_id: 167,
		part_id: 284,
		quantity: 340,
	},
	{
		garage_id: 167,
		part_id: 285,
		quantity: 392,
	},
	{
		garage_id: 167,
		part_id: 286,
		quantity: 328,
	},
	{
		garage_id: 167,
		part_id: 287,
		quantity: 405,
	},
	{
		garage_id: 167,
		part_id: 288,
		quantity: 268,
	},
	{
		garage_id: 167,
		part_id: 289,
		quantity: 185,
	},
	{
		garage_id: 167,
		part_id: 290,
		quantity: 318,
	},
	{
		garage_id: 167,
		part_id: 291,
		quantity: 396,
	},
	{
		garage_id: 167,
		part_id: 292,
		quantity: 270,
	},
	{
		garage_id: 167,
		part_id: 293,
		quantity: 78,
	},
	{
		garage_id: 167,
		part_id: 294,
		quantity: 155,
	},
	{
		garage_id: 167,
		part_id: 295,
		quantity: 268,
	},
	{
		garage_id: 167,
		part_id: 296,
		quantity: 256,
	},
	{
		garage_id: 167,
		part_id: 297,
		quantity: 370,
	},
	{
		garage_id: 167,
		part_id: 298,
		quantity: 305,
	},
	{
		garage_id: 167,
		part_id: 299,
		quantity: 46,
	},
	{
		garage_id: 167,
		part_id: 300,
		quantity: 345,
	},
	{
		garage_id: 167,
		part_id: 301,
		quantity: 34,
	},
	{
		garage_id: 167,
		part_id: 302,
		quantity: 137,
	},
	{
		garage_id: 167,
		part_id: 303,
		quantity: 287,
	},
	{
		garage_id: 167,
		part_id: 304,
		quantity: 398,
	},
	{
		garage_id: 167,
		part_id: 305,
		quantity: 26,
	},
	{
		garage_id: 167,
		part_id: 306,
		quantity: 439,
	},
	{
		garage_id: 167,
		part_id: 307,
		quantity: 124,
	},
	{
		garage_id: 167,
		part_id: 308,
		quantity: 30,
	},
	{
		garage_id: 167,
		part_id: 309,
		quantity: 61,
	},
	{
		garage_id: 167,
		part_id: 310,
		quantity: 204,
	},
	{
		garage_id: 167,
		part_id: 311,
		quantity: 151,
	},
	{
		garage_id: 167,
		part_id: 312,
		quantity: 452,
	},
	{
		garage_id: 167,
		part_id: 313,
		quantity: 172,
	},
	{
		garage_id: 167,
		part_id: 314,
		quantity: 153,
	},
	{
		garage_id: 167,
		part_id: 315,
		quantity: 96,
	},
	{
		garage_id: 167,
		part_id: 316,
		quantity: 200,
	},
	{
		garage_id: 167,
		part_id: 317,
		quantity: 391,
	},
	{
		garage_id: 167,
		part_id: 318,
		quantity: 246,
	},
	{
		garage_id: 167,
		part_id: 319,
		quantity: 123,
	},
	{
		garage_id: 167,
		part_id: 320,
		quantity: 43,
	},
	{
		garage_id: 167,
		part_id: 321,
		quantity: 332,
	},
	{
		garage_id: 167,
		part_id: 322,
		quantity: 210,
	},
	{
		garage_id: 167,
		part_id: 323,
		quantity: 48,
	},
	{
		garage_id: 167,
		part_id: 324,
		quantity: 325,
	},
	{
		garage_id: 167,
		part_id: 325,
		quantity: 432,
	},
	{
		garage_id: 167,
		part_id: 326,
		quantity: 352,
	},
	{
		garage_id: 167,
		part_id: 327,
		quantity: 136,
	},
	{
		garage_id: 167,
		part_id: 328,
		quantity: 79,
	},
	{
		garage_id: 167,
		part_id: 329,
		quantity: 383,
	},
	{
		garage_id: 167,
		part_id: 330,
		quantity: 157,
	},
	{
		garage_id: 167,
		part_id: 331,
		quantity: 52,
	},
	{
		garage_id: 167,
		part_id: 332,
		quantity: 475,
	},
	{
		garage_id: 167,
		part_id: 333,
		quantity: 237,
	},
	{
		garage_id: 167,
		part_id: 334,
		quantity: 466,
	},
	{
		garage_id: 167,
		part_id: 335,
		quantity: 482,
	},
	{
		garage_id: 167,
		part_id: 336,
		quantity: 31,
	},
	{
		garage_id: 167,
		part_id: 337,
		quantity: 26,
	},
	{
		garage_id: 167,
		part_id: 338,
		quantity: 353,
	},
	{
		garage_id: 167,
		part_id: 339,
		quantity: 54,
	},
	{
		garage_id: 167,
		part_id: 340,
		quantity: 122,
	},
	{
		garage_id: 167,
		part_id: 341,
		quantity: 254,
	},
	{
		garage_id: 167,
		part_id: 342,
		quantity: 112,
	},
	{
		garage_id: 167,
		part_id: 343,
		quantity: 89,
	},
	{
		garage_id: 167,
		part_id: 344,
		quantity: 130,
	},
	{
		garage_id: 167,
		part_id: 345,
		quantity: 442,
	},
	{
		garage_id: 167,
		part_id: 346,
		quantity: 90,
	},
	{
		garage_id: 167,
		part_id: 347,
		quantity: 108,
	},
	{
		garage_id: 167,
		part_id: 348,
		quantity: 121,
	},
	{
		garage_id: 167,
		part_id: 349,
		quantity: 491,
	},
	{
		garage_id: 167,
		part_id: 350,
		quantity: 274,
	},
	{
		garage_id: 167,
		part_id: 351,
		quantity: 17,
	},
	{
		garage_id: 167,
		part_id: 352,
		quantity: 170,
	},
	{
		garage_id: 167,
		part_id: 353,
		quantity: 138,
	},
	{
		garage_id: 167,
		part_id: 354,
		quantity: 215,
	},
	{
		garage_id: 167,
		part_id: 355,
		quantity: 282,
	},
	{
		garage_id: 167,
		part_id: 356,
		quantity: 116,
	},
	{
		garage_id: 167,
		part_id: 357,
		quantity: 332,
	},
	{
		garage_id: 167,
		part_id: 358,
		quantity: 223,
	},
	{
		garage_id: 167,
		part_id: 359,
		quantity: 224,
	},
	{
		garage_id: 167,
		part_id: 360,
		quantity: 390,
	},
	{
		garage_id: 167,
		part_id: 361,
		quantity: 134,
	},
	{
		garage_id: 167,
		part_id: 362,
		quantity: 321,
	},
	{
		garage_id: 167,
		part_id: 363,
		quantity: 286,
	},
	{
		garage_id: 167,
		part_id: 364,
		quantity: 141,
	},
	{
		garage_id: 167,
		part_id: 365,
		quantity: 413,
	},
	{
		garage_id: 167,
		part_id: 366,
		quantity: 440,
	},
	{
		garage_id: 167,
		part_id: 367,
		quantity: 192,
	},
	{
		garage_id: 167,
		part_id: 368,
		quantity: 179,
	},
	{
		garage_id: 167,
		part_id: 369,
		quantity: 27,
	},
	{
		garage_id: 167,
		part_id: 370,
		quantity: 274,
	},
	{
		garage_id: 167,
		part_id: 371,
		quantity: 166,
	},
	{
		garage_id: 167,
		part_id: 372,
		quantity: 484,
	},
	{
		garage_id: 167,
		part_id: 373,
		quantity: 64,
	},
	{
		garage_id: 167,
		part_id: 374,
		quantity: 329,
	},
	{
		garage_id: 167,
		part_id: 375,
		quantity: 271,
	},
	{
		garage_id: 167,
		part_id: 376,
		quantity: 183,
	},
	{
		garage_id: 167,
		part_id: 377,
		quantity: 161,
	},
	{
		garage_id: 167,
		part_id: 378,
		quantity: 267,
	},
	{
		garage_id: 167,
		part_id: 379,
		quantity: 494,
	},
	{
		garage_id: 167,
		part_id: 380,
		quantity: 394,
	},
	{
		garage_id: 167,
		part_id: 381,
		quantity: 201,
	},
	{
		garage_id: 167,
		part_id: 382,
		quantity: 145,
	},
	{
		garage_id: 167,
		part_id: 383,
		quantity: 474,
	},
	{
		garage_id: 167,
		part_id: 384,
		quantity: 96,
	},
	{
		garage_id: 167,
		part_id: 385,
		quantity: 263,
	},
	{
		garage_id: 167,
		part_id: 386,
		quantity: 433,
	},
	{
		garage_id: 167,
		part_id: 387,
		quantity: 84,
	},
	{
		garage_id: 167,
		part_id: 388,
		quantity: 218,
	},
	{
		garage_id: 167,
		part_id: 389,
		quantity: 153,
	},
	{
		garage_id: 167,
		part_id: 390,
		quantity: 153,
	},
	{
		garage_id: 167,
		part_id: 391,
		quantity: 225,
	},
	{
		garage_id: 167,
		part_id: 392,
		quantity: 360,
	},
	{
		garage_id: 167,
		part_id: 393,
		quantity: 250,
	},
	{
		garage_id: 167,
		part_id: 394,
		quantity: 252,
	},
	{
		garage_id: 167,
		part_id: 395,
		quantity: 241,
	},
	{
		garage_id: 167,
		part_id: 396,
		quantity: 221,
	},
	{
		garage_id: 167,
		part_id: 397,
		quantity: 26,
	},
	{
		garage_id: 167,
		part_id: 398,
		quantity: 106,
	},
	{
		garage_id: 167,
		part_id: 399,
		quantity: 368,
	},
	{
		garage_id: 168,
		part_id: 0,
		quantity: 177,
	},
	{
		garage_id: 168,
		part_id: 1,
		quantity: 209,
	},
	{
		garage_id: 168,
		part_id: 2,
		quantity: 466,
	},
	{
		garage_id: 168,
		part_id: 3,
		quantity: 150,
	},
	{
		garage_id: 168,
		part_id: 4,
		quantity: 61,
	},
	{
		garage_id: 168,
		part_id: 5,
		quantity: 364,
	},
	{
		garage_id: 168,
		part_id: 6,
		quantity: 207,
	},
	{
		garage_id: 168,
		part_id: 7,
		quantity: 420,
	},
	{
		garage_id: 168,
		part_id: 8,
		quantity: 218,
	},
	{
		garage_id: 168,
		part_id: 9,
		quantity: 416,
	},
	{
		garage_id: 168,
		part_id: 10,
		quantity: 187,
	},
	{
		garage_id: 168,
		part_id: 11,
		quantity: 234,
	},
	{
		garage_id: 168,
		part_id: 12,
		quantity: 32,
	},
	{
		garage_id: 168,
		part_id: 13,
		quantity: 71,
	},
	{
		garage_id: 168,
		part_id: 14,
		quantity: 215,
	},
	{
		garage_id: 168,
		part_id: 15,
		quantity: 360,
	},
	{
		garage_id: 168,
		part_id: 16,
		quantity: 125,
	},
	{
		garage_id: 168,
		part_id: 17,
		quantity: 275,
	},
	{
		garage_id: 168,
		part_id: 18,
		quantity: 418,
	},
	{
		garage_id: 168,
		part_id: 19,
		quantity: 208,
	},
	{
		garage_id: 168,
		part_id: 20,
		quantity: 450,
	},
	{
		garage_id: 168,
		part_id: 21,
		quantity: 17,
	},
	{
		garage_id: 168,
		part_id: 22,
		quantity: 149,
	},
	{
		garage_id: 168,
		part_id: 23,
		quantity: 175,
	},
	{
		garage_id: 168,
		part_id: 24,
		quantity: 468,
	},
	{
		garage_id: 168,
		part_id: 25,
		quantity: 285,
	},
	{
		garage_id: 168,
		part_id: 26,
		quantity: 164,
	},
	{
		garage_id: 168,
		part_id: 27,
		quantity: 296,
	},
	{
		garage_id: 168,
		part_id: 28,
		quantity: 232,
	},
	{
		garage_id: 168,
		part_id: 29,
		quantity: 215,
	},
	{
		garage_id: 168,
		part_id: 30,
		quantity: 24,
	},
	{
		garage_id: 168,
		part_id: 31,
		quantity: 244,
	},
	{
		garage_id: 168,
		part_id: 32,
		quantity: 122,
	},
	{
		garage_id: 168,
		part_id: 33,
		quantity: 337,
	},
	{
		garage_id: 168,
		part_id: 34,
		quantity: 447,
	},
	{
		garage_id: 168,
		part_id: 35,
		quantity: 161,
	},
	{
		garage_id: 168,
		part_id: 36,
		quantity: 407,
	},
	{
		garage_id: 168,
		part_id: 37,
		quantity: 109,
	},
	{
		garage_id: 168,
		part_id: 38,
		quantity: 370,
	},
	{
		garage_id: 168,
		part_id: 39,
		quantity: 476,
	},
	{
		garage_id: 168,
		part_id: 40,
		quantity: 282,
	},
	{
		garage_id: 168,
		part_id: 41,
		quantity: 381,
	},
	{
		garage_id: 168,
		part_id: 42,
		quantity: 291,
	},
	{
		garage_id: 168,
		part_id: 43,
		quantity: 183,
	},
	{
		garage_id: 168,
		part_id: 44,
		quantity: 216,
	},
	{
		garage_id: 168,
		part_id: 45,
		quantity: 183,
	},
	{
		garage_id: 168,
		part_id: 46,
		quantity: 348,
	},
	{
		garage_id: 168,
		part_id: 47,
		quantity: 457,
	},
	{
		garage_id: 168,
		part_id: 48,
		quantity: 9,
	},
	{
		garage_id: 168,
		part_id: 49,
		quantity: 374,
	},
	{
		garage_id: 168,
		part_id: 50,
		quantity: 484,
	},
	{
		garage_id: 168,
		part_id: 51,
		quantity: 423,
	},
	{
		garage_id: 168,
		part_id: 52,
		quantity: 345,
	},
	{
		garage_id: 168,
		part_id: 53,
		quantity: 79,
	},
	{
		garage_id: 168,
		part_id: 54,
		quantity: 169,
	},
	{
		garage_id: 168,
		part_id: 55,
		quantity: 210,
	},
	{
		garage_id: 168,
		part_id: 56,
		quantity: 284,
	},
	{
		garage_id: 168,
		part_id: 57,
		quantity: 252,
	},
	{
		garage_id: 168,
		part_id: 58,
		quantity: 345,
	},
	{
		garage_id: 168,
		part_id: 59,
		quantity: 120,
	},
	{
		garage_id: 168,
		part_id: 60,
		quantity: 134,
	},
	{
		garage_id: 168,
		part_id: 61,
		quantity: 458,
	},
	{
		garage_id: 168,
		part_id: 62,
		quantity: 410,
	},
	{
		garage_id: 168,
		part_id: 63,
		quantity: 288,
	},
	{
		garage_id: 168,
		part_id: 64,
		quantity: 292,
	},
	{
		garage_id: 168,
		part_id: 65,
		quantity: 115,
	},
	{
		garage_id: 168,
		part_id: 66,
		quantity: 250,
	},
	{
		garage_id: 168,
		part_id: 67,
		quantity: 225,
	},
	{
		garage_id: 168,
		part_id: 68,
		quantity: 294,
	},
	{
		garage_id: 168,
		part_id: 69,
		quantity: 243,
	},
	{
		garage_id: 168,
		part_id: 70,
		quantity: 351,
	},
	{
		garage_id: 168,
		part_id: 71,
		quantity: 103,
	},
	{
		garage_id: 168,
		part_id: 72,
		quantity: 84,
	},
	{
		garage_id: 168,
		part_id: 73,
		quantity: 431,
	},
	{
		garage_id: 168,
		part_id: 74,
		quantity: 7,
	},
	{
		garage_id: 168,
		part_id: 75,
		quantity: 319,
	},
	{
		garage_id: 168,
		part_id: 76,
		quantity: 257,
	},
	{
		garage_id: 168,
		part_id: 77,
		quantity: 40,
	},
	{
		garage_id: 168,
		part_id: 78,
		quantity: 107,
	},
	{
		garage_id: 168,
		part_id: 79,
		quantity: 231,
	},
	{
		garage_id: 168,
		part_id: 80,
		quantity: 243,
	},
	{
		garage_id: 168,
		part_id: 81,
		quantity: 131,
	},
	{
		garage_id: 168,
		part_id: 82,
		quantity: 196,
	},
	{
		garage_id: 168,
		part_id: 83,
		quantity: 274,
	},
	{
		garage_id: 168,
		part_id: 84,
		quantity: 310,
	},
	{
		garage_id: 168,
		part_id: 85,
		quantity: 425,
	},
	{
		garage_id: 168,
		part_id: 86,
		quantity: 326,
	},
	{
		garage_id: 168,
		part_id: 87,
		quantity: 144,
	},
	{
		garage_id: 168,
		part_id: 88,
		quantity: 180,
	},
	{
		garage_id: 168,
		part_id: 89,
		quantity: 27,
	},
	{
		garage_id: 168,
		part_id: 90,
		quantity: 270,
	},
	{
		garage_id: 168,
		part_id: 91,
		quantity: 338,
	},
	{
		garage_id: 168,
		part_id: 92,
		quantity: 157,
	},
	{
		garage_id: 168,
		part_id: 93,
		quantity: 229,
	},
	{
		garage_id: 168,
		part_id: 94,
		quantity: 290,
	},
	{
		garage_id: 168,
		part_id: 95,
		quantity: 33,
	},
	{
		garage_id: 168,
		part_id: 96,
		quantity: 212,
	},
	{
		garage_id: 168,
		part_id: 97,
		quantity: 70,
	},
	{
		garage_id: 168,
		part_id: 98,
		quantity: 336,
	},
	{
		garage_id: 168,
		part_id: 99,
		quantity: 46,
	},
	{
		garage_id: 168,
		part_id: 100,
		quantity: 458,
	},
	{
		garage_id: 168,
		part_id: 101,
		quantity: 279,
	},
	{
		garage_id: 168,
		part_id: 102,
		quantity: 305,
	},
	{
		garage_id: 168,
		part_id: 103,
		quantity: 251,
	},
	{
		garage_id: 168,
		part_id: 104,
		quantity: 440,
	},
	{
		garage_id: 168,
		part_id: 105,
		quantity: 82,
	},
	{
		garage_id: 168,
		part_id: 106,
		quantity: 438,
	},
	{
		garage_id: 168,
		part_id: 107,
		quantity: 259,
	},
	{
		garage_id: 168,
		part_id: 108,
		quantity: 443,
	},
	{
		garage_id: 168,
		part_id: 109,
		quantity: 409,
	},
	{
		garage_id: 168,
		part_id: 110,
		quantity: 44,
	},
	{
		garage_id: 168,
		part_id: 111,
		quantity: 387,
	},
	{
		garage_id: 168,
		part_id: 112,
		quantity: 318,
	},
	{
		garage_id: 168,
		part_id: 113,
		quantity: 209,
	},
	{
		garage_id: 168,
		part_id: 114,
		quantity: 85,
	},
	{
		garage_id: 168,
		part_id: 115,
		quantity: 312,
	},
	{
		garage_id: 168,
		part_id: 116,
		quantity: 190,
	},
	{
		garage_id: 168,
		part_id: 117,
		quantity: 48,
	},
	{
		garage_id: 168,
		part_id: 118,
		quantity: 400,
	},
	{
		garage_id: 168,
		part_id: 119,
		quantity: 13,
	},
	{
		garage_id: 168,
		part_id: 120,
		quantity: 206,
	},
	{
		garage_id: 168,
		part_id: 121,
		quantity: 33,
	},
	{
		garage_id: 168,
		part_id: 122,
		quantity: 303,
	},
	{
		garage_id: 168,
		part_id: 123,
		quantity: 164,
	},
	{
		garage_id: 168,
		part_id: 124,
		quantity: 207,
	},
	{
		garage_id: 168,
		part_id: 125,
		quantity: 35,
	},
	{
		garage_id: 168,
		part_id: 126,
		quantity: 140,
	},
	{
		garage_id: 168,
		part_id: 127,
		quantity: 346,
	},
	{
		garage_id: 168,
		part_id: 128,
		quantity: 50,
	},
	{
		garage_id: 168,
		part_id: 129,
		quantity: 467,
	},
	{
		garage_id: 168,
		part_id: 130,
		quantity: 269,
	},
	{
		garage_id: 168,
		part_id: 131,
		quantity: 318,
	},
	{
		garage_id: 168,
		part_id: 132,
		quantity: 134,
	},
	{
		garage_id: 168,
		part_id: 133,
		quantity: 169,
	},
	{
		garage_id: 168,
		part_id: 134,
		quantity: 8,
	},
	{
		garage_id: 168,
		part_id: 135,
		quantity: 102,
	},
	{
		garage_id: 168,
		part_id: 136,
		quantity: 335,
	},
	{
		garage_id: 168,
		part_id: 137,
		quantity: 449,
	},
	{
		garage_id: 168,
		part_id: 138,
		quantity: 477,
	},
	{
		garage_id: 168,
		part_id: 139,
		quantity: 421,
	},
	{
		garage_id: 168,
		part_id: 140,
		quantity: 2,
	},
	{
		garage_id: 168,
		part_id: 141,
		quantity: 139,
	},
	{
		garage_id: 168,
		part_id: 142,
		quantity: 78,
	},
	{
		garage_id: 168,
		part_id: 143,
		quantity: 473,
	},
	{
		garage_id: 168,
		part_id: 144,
		quantity: 141,
	},
	{
		garage_id: 168,
		part_id: 145,
		quantity: 111,
	},
	{
		garage_id: 168,
		part_id: 146,
		quantity: 124,
	},
	{
		garage_id: 168,
		part_id: 147,
		quantity: 484,
	},
	{
		garage_id: 168,
		part_id: 148,
		quantity: 477,
	},
	{
		garage_id: 168,
		part_id: 149,
		quantity: 458,
	},
	{
		garage_id: 168,
		part_id: 150,
		quantity: 282,
	},
	{
		garage_id: 168,
		part_id: 151,
		quantity: 275,
	},
	{
		garage_id: 168,
		part_id: 152,
		quantity: 55,
	},
	{
		garage_id: 168,
		part_id: 153,
		quantity: 472,
	},
	{
		garage_id: 168,
		part_id: 154,
		quantity: 476,
	},
	{
		garage_id: 168,
		part_id: 155,
		quantity: 175,
	},
	{
		garage_id: 168,
		part_id: 156,
		quantity: 15,
	},
	{
		garage_id: 168,
		part_id: 157,
		quantity: 272,
	},
	{
		garage_id: 168,
		part_id: 158,
		quantity: 65,
	},
	{
		garage_id: 168,
		part_id: 159,
		quantity: 488,
	},
	{
		garage_id: 168,
		part_id: 160,
		quantity: 453,
	},
	{
		garage_id: 168,
		part_id: 161,
		quantity: 82,
	},
	{
		garage_id: 168,
		part_id: 162,
		quantity: 250,
	},
	{
		garage_id: 168,
		part_id: 163,
		quantity: 242,
	},
	{
		garage_id: 168,
		part_id: 164,
		quantity: 263,
	},
	{
		garage_id: 168,
		part_id: 165,
		quantity: 382,
	},
	{
		garage_id: 168,
		part_id: 166,
		quantity: 348,
	},
	{
		garage_id: 168,
		part_id: 167,
		quantity: 254,
	},
	{
		garage_id: 168,
		part_id: 168,
		quantity: 82,
	},
	{
		garage_id: 168,
		part_id: 169,
		quantity: 467,
	},
	{
		garage_id: 168,
		part_id: 170,
		quantity: 14,
	},
	{
		garage_id: 168,
		part_id: 171,
		quantity: 160,
	},
	{
		garage_id: 168,
		part_id: 172,
		quantity: 23,
	},
	{
		garage_id: 168,
		part_id: 173,
		quantity: 407,
	},
	{
		garage_id: 168,
		part_id: 174,
		quantity: 411,
	},
	{
		garage_id: 168,
		part_id: 175,
		quantity: 41,
	},
	{
		garage_id: 168,
		part_id: 176,
		quantity: 330,
	},
	{
		garage_id: 168,
		part_id: 177,
		quantity: 329,
	},
	{
		garage_id: 168,
		part_id: 178,
		quantity: 487,
	},
	{
		garage_id: 168,
		part_id: 179,
		quantity: 40,
	},
	{
		garage_id: 168,
		part_id: 180,
		quantity: 336,
	},
	{
		garage_id: 168,
		part_id: 181,
		quantity: 34,
	},
	{
		garage_id: 168,
		part_id: 182,
		quantity: 331,
	},
	{
		garage_id: 168,
		part_id: 183,
		quantity: 129,
	},
	{
		garage_id: 168,
		part_id: 184,
		quantity: 14,
	},
	{
		garage_id: 168,
		part_id: 185,
		quantity: 191,
	},
	{
		garage_id: 168,
		part_id: 186,
		quantity: 119,
	},
	{
		garage_id: 168,
		part_id: 187,
		quantity: 96,
	},
	{
		garage_id: 168,
		part_id: 188,
		quantity: 213,
	},
	{
		garage_id: 168,
		part_id: 189,
		quantity: 269,
	},
	{
		garage_id: 168,
		part_id: 190,
		quantity: 301,
	},
	{
		garage_id: 168,
		part_id: 191,
		quantity: 462,
	},
	{
		garage_id: 168,
		part_id: 192,
		quantity: 35,
	},
	{
		garage_id: 168,
		part_id: 193,
		quantity: 354,
	},
	{
		garage_id: 168,
		part_id: 194,
		quantity: 322,
	},
	{
		garage_id: 168,
		part_id: 195,
		quantity: 463,
	},
	{
		garage_id: 168,
		part_id: 196,
		quantity: 181,
	},
	{
		garage_id: 168,
		part_id: 197,
		quantity: 0,
	},
	{
		garage_id: 168,
		part_id: 198,
		quantity: 386,
	},
	{
		garage_id: 168,
		part_id: 199,
		quantity: 234,
	},
	{
		garage_id: 168,
		part_id: 200,
		quantity: 436,
	},
	{
		garage_id: 168,
		part_id: 201,
		quantity: 130,
	},
	{
		garage_id: 168,
		part_id: 202,
		quantity: 139,
	},
	{
		garage_id: 168,
		part_id: 203,
		quantity: 497,
	},
	{
		garage_id: 168,
		part_id: 204,
		quantity: 90,
	},
	{
		garage_id: 168,
		part_id: 205,
		quantity: 29,
	},
	{
		garage_id: 168,
		part_id: 206,
		quantity: 46,
	},
	{
		garage_id: 168,
		part_id: 207,
		quantity: 234,
	},
	{
		garage_id: 168,
		part_id: 208,
		quantity: 462,
	},
	{
		garage_id: 168,
		part_id: 209,
		quantity: 269,
	},
	{
		garage_id: 168,
		part_id: 210,
		quantity: 464,
	},
	{
		garage_id: 168,
		part_id: 211,
		quantity: 445,
	},
	{
		garage_id: 168,
		part_id: 212,
		quantity: 272,
	},
	{
		garage_id: 168,
		part_id: 213,
		quantity: 89,
	},
	{
		garage_id: 168,
		part_id: 214,
		quantity: 467,
	},
	{
		garage_id: 168,
		part_id: 215,
		quantity: 252,
	},
	{
		garage_id: 168,
		part_id: 216,
		quantity: 8,
	},
	{
		garage_id: 168,
		part_id: 217,
		quantity: 362,
	},
	{
		garage_id: 168,
		part_id: 218,
		quantity: 126,
	},
	{
		garage_id: 168,
		part_id: 219,
		quantity: 350,
	},
	{
		garage_id: 168,
		part_id: 220,
		quantity: 274,
	},
	{
		garage_id: 168,
		part_id: 221,
		quantity: 248,
	},
	{
		garage_id: 168,
		part_id: 222,
		quantity: 196,
	},
	{
		garage_id: 168,
		part_id: 223,
		quantity: 416,
	},
	{
		garage_id: 168,
		part_id: 224,
		quantity: 355,
	},
	{
		garage_id: 168,
		part_id: 225,
		quantity: 170,
	},
	{
		garage_id: 168,
		part_id: 226,
		quantity: 432,
	},
	{
		garage_id: 168,
		part_id: 227,
		quantity: 424,
	},
	{
		garage_id: 168,
		part_id: 228,
		quantity: 82,
	},
	{
		garage_id: 168,
		part_id: 229,
		quantity: 18,
	},
	{
		garage_id: 168,
		part_id: 230,
		quantity: 88,
	},
	{
		garage_id: 168,
		part_id: 231,
		quantity: 35,
	},
	{
		garage_id: 168,
		part_id: 232,
		quantity: 487,
	},
	{
		garage_id: 168,
		part_id: 233,
		quantity: 148,
	},
	{
		garage_id: 168,
		part_id: 234,
		quantity: 254,
	},
	{
		garage_id: 168,
		part_id: 235,
		quantity: 7,
	},
	{
		garage_id: 168,
		part_id: 236,
		quantity: 251,
	},
	{
		garage_id: 168,
		part_id: 237,
		quantity: 336,
	},
	{
		garage_id: 168,
		part_id: 238,
		quantity: 212,
	},
	{
		garage_id: 168,
		part_id: 239,
		quantity: 305,
	},
	{
		garage_id: 168,
		part_id: 240,
		quantity: 389,
	},
	{
		garage_id: 168,
		part_id: 241,
		quantity: 238,
	},
	{
		garage_id: 168,
		part_id: 242,
		quantity: 292,
	},
	{
		garage_id: 168,
		part_id: 243,
		quantity: 40,
	},
	{
		garage_id: 168,
		part_id: 244,
		quantity: 60,
	},
	{
		garage_id: 168,
		part_id: 245,
		quantity: 474,
	},
	{
		garage_id: 168,
		part_id: 246,
		quantity: 166,
	},
	{
		garage_id: 168,
		part_id: 247,
		quantity: 476,
	},
	{
		garage_id: 168,
		part_id: 248,
		quantity: 449,
	},
	{
		garage_id: 168,
		part_id: 249,
		quantity: 308,
	},
	{
		garage_id: 168,
		part_id: 250,
		quantity: 429,
	},
	{
		garage_id: 168,
		part_id: 251,
		quantity: 27,
	},
	{
		garage_id: 168,
		part_id: 252,
		quantity: 122,
	},
	{
		garage_id: 168,
		part_id: 253,
		quantity: 22,
	},
	{
		garage_id: 168,
		part_id: 254,
		quantity: 111,
	},
	{
		garage_id: 168,
		part_id: 255,
		quantity: 397,
	},
	{
		garage_id: 168,
		part_id: 256,
		quantity: 189,
	},
	{
		garage_id: 168,
		part_id: 257,
		quantity: 189,
	},
	{
		garage_id: 168,
		part_id: 258,
		quantity: 469,
	},
	{
		garage_id: 168,
		part_id: 259,
		quantity: 112,
	},
	{
		garage_id: 168,
		part_id: 260,
		quantity: 87,
	},
	{
		garage_id: 168,
		part_id: 261,
		quantity: 394,
	},
	{
		garage_id: 168,
		part_id: 262,
		quantity: 31,
	},
	{
		garage_id: 168,
		part_id: 263,
		quantity: 260,
	},
	{
		garage_id: 168,
		part_id: 264,
		quantity: 281,
	},
	{
		garage_id: 168,
		part_id: 265,
		quantity: 377,
	},
	{
		garage_id: 168,
		part_id: 266,
		quantity: 321,
	},
	{
		garage_id: 168,
		part_id: 267,
		quantity: 274,
	},
	{
		garage_id: 168,
		part_id: 268,
		quantity: 383,
	},
	{
		garage_id: 168,
		part_id: 269,
		quantity: 296,
	},
	{
		garage_id: 168,
		part_id: 270,
		quantity: 55,
	},
	{
		garage_id: 168,
		part_id: 271,
		quantity: 123,
	},
	{
		garage_id: 168,
		part_id: 272,
		quantity: 407,
	},
	{
		garage_id: 168,
		part_id: 273,
		quantity: 404,
	},
	{
		garage_id: 168,
		part_id: 274,
		quantity: 329,
	},
	{
		garage_id: 168,
		part_id: 275,
		quantity: 236,
	},
	{
		garage_id: 168,
		part_id: 276,
		quantity: 291,
	},
	{
		garage_id: 168,
		part_id: 277,
		quantity: 110,
	},
	{
		garage_id: 168,
		part_id: 278,
		quantity: 91,
	},
	{
		garage_id: 168,
		part_id: 279,
		quantity: 191,
	},
	{
		garage_id: 168,
		part_id: 280,
		quantity: 380,
	},
	{
		garage_id: 168,
		part_id: 281,
		quantity: 428,
	},
	{
		garage_id: 168,
		part_id: 282,
		quantity: 346,
	},
	{
		garage_id: 168,
		part_id: 283,
		quantity: 232,
	},
	{
		garage_id: 168,
		part_id: 284,
		quantity: 94,
	},
	{
		garage_id: 168,
		part_id: 285,
		quantity: 196,
	},
	{
		garage_id: 168,
		part_id: 286,
		quantity: 275,
	},
	{
		garage_id: 168,
		part_id: 287,
		quantity: 236,
	},
	{
		garage_id: 168,
		part_id: 288,
		quantity: 448,
	},
	{
		garage_id: 168,
		part_id: 289,
		quantity: 405,
	},
	{
		garage_id: 168,
		part_id: 290,
		quantity: 10,
	},
	{
		garage_id: 168,
		part_id: 291,
		quantity: 16,
	},
	{
		garage_id: 168,
		part_id: 292,
		quantity: 437,
	},
	{
		garage_id: 168,
		part_id: 293,
		quantity: 50,
	},
	{
		garage_id: 168,
		part_id: 294,
		quantity: 342,
	},
	{
		garage_id: 168,
		part_id: 295,
		quantity: 473,
	},
	{
		garage_id: 168,
		part_id: 296,
		quantity: 166,
	},
	{
		garage_id: 168,
		part_id: 297,
		quantity: 376,
	},
	{
		garage_id: 168,
		part_id: 298,
		quantity: 340,
	},
	{
		garage_id: 168,
		part_id: 299,
		quantity: 285,
	},
	{
		garage_id: 168,
		part_id: 300,
		quantity: 98,
	},
	{
		garage_id: 168,
		part_id: 301,
		quantity: 208,
	},
	{
		garage_id: 168,
		part_id: 302,
		quantity: 468,
	},
	{
		garage_id: 168,
		part_id: 303,
		quantity: 467,
	},
	{
		garage_id: 168,
		part_id: 304,
		quantity: 92,
	},
	{
		garage_id: 168,
		part_id: 305,
		quantity: 324,
	},
	{
		garage_id: 168,
		part_id: 306,
		quantity: 27,
	},
	{
		garage_id: 168,
		part_id: 307,
		quantity: 128,
	},
	{
		garage_id: 168,
		part_id: 308,
		quantity: 214,
	},
	{
		garage_id: 168,
		part_id: 309,
		quantity: 112,
	},
	{
		garage_id: 168,
		part_id: 310,
		quantity: 382,
	},
	{
		garage_id: 168,
		part_id: 311,
		quantity: 434,
	},
	{
		garage_id: 168,
		part_id: 312,
		quantity: 452,
	},
	{
		garage_id: 168,
		part_id: 313,
		quantity: 266,
	},
	{
		garage_id: 168,
		part_id: 314,
		quantity: 0,
	},
	{
		garage_id: 168,
		part_id: 315,
		quantity: 207,
	},
	{
		garage_id: 168,
		part_id: 316,
		quantity: 496,
	},
	{
		garage_id: 168,
		part_id: 317,
		quantity: 497,
	},
	{
		garage_id: 168,
		part_id: 318,
		quantity: 488,
	},
	{
		garage_id: 168,
		part_id: 319,
		quantity: 412,
	},
	{
		garage_id: 168,
		part_id: 320,
		quantity: 71,
	},
	{
		garage_id: 168,
		part_id: 321,
		quantity: 457,
	},
	{
		garage_id: 168,
		part_id: 322,
		quantity: 433,
	},
	{
		garage_id: 168,
		part_id: 323,
		quantity: 417,
	},
	{
		garage_id: 168,
		part_id: 324,
		quantity: 471,
	},
	{
		garage_id: 168,
		part_id: 325,
		quantity: 405,
	},
	{
		garage_id: 168,
		part_id: 326,
		quantity: 14,
	},
	{
		garage_id: 168,
		part_id: 327,
		quantity: 478,
	},
	{
		garage_id: 168,
		part_id: 328,
		quantity: 432,
	},
	{
		garage_id: 168,
		part_id: 329,
		quantity: 90,
	},
	{
		garage_id: 168,
		part_id: 330,
		quantity: 252,
	},
	{
		garage_id: 168,
		part_id: 331,
		quantity: 184,
	},
	{
		garage_id: 168,
		part_id: 332,
		quantity: 467,
	},
	{
		garage_id: 168,
		part_id: 333,
		quantity: 40,
	},
	{
		garage_id: 168,
		part_id: 334,
		quantity: 484,
	},
	{
		garage_id: 168,
		part_id: 335,
		quantity: 50,
	},
	{
		garage_id: 168,
		part_id: 336,
		quantity: 3,
	},
	{
		garage_id: 168,
		part_id: 337,
		quantity: 409,
	},
	{
		garage_id: 168,
		part_id: 338,
		quantity: 173,
	},
	{
		garage_id: 168,
		part_id: 339,
		quantity: 142,
	},
	{
		garage_id: 168,
		part_id: 340,
		quantity: 298,
	},
	{
		garage_id: 168,
		part_id: 341,
		quantity: 423,
	},
	{
		garage_id: 168,
		part_id: 342,
		quantity: 229,
	},
	{
		garage_id: 168,
		part_id: 343,
		quantity: 331,
	},
	{
		garage_id: 168,
		part_id: 344,
		quantity: 495,
	},
	{
		garage_id: 168,
		part_id: 345,
		quantity: 123,
	},
	{
		garage_id: 168,
		part_id: 346,
		quantity: 441,
	},
	{
		garage_id: 168,
		part_id: 347,
		quantity: 143,
	},
	{
		garage_id: 168,
		part_id: 348,
		quantity: 55,
	},
	{
		garage_id: 168,
		part_id: 349,
		quantity: 461,
	},
	{
		garage_id: 168,
		part_id: 350,
		quantity: 210,
	},
	{
		garage_id: 168,
		part_id: 351,
		quantity: 185,
	},
	{
		garage_id: 168,
		part_id: 352,
		quantity: 206,
	},
	{
		garage_id: 168,
		part_id: 353,
		quantity: 251,
	},
	{
		garage_id: 168,
		part_id: 354,
		quantity: 23,
	},
	{
		garage_id: 168,
		part_id: 355,
		quantity: 2,
	},
	{
		garage_id: 168,
		part_id: 356,
		quantity: 95,
	},
	{
		garage_id: 168,
		part_id: 357,
		quantity: 272,
	},
	{
		garage_id: 168,
		part_id: 358,
		quantity: 490,
	},
	{
		garage_id: 168,
		part_id: 359,
		quantity: 259,
	},
	{
		garage_id: 168,
		part_id: 360,
		quantity: 303,
	},
	{
		garage_id: 168,
		part_id: 361,
		quantity: 103,
	},
	{
		garage_id: 168,
		part_id: 362,
		quantity: 214,
	},
	{
		garage_id: 168,
		part_id: 363,
		quantity: 499,
	},
	{
		garage_id: 168,
		part_id: 364,
		quantity: 11,
	},
	{
		garage_id: 168,
		part_id: 365,
		quantity: 97,
	},
	{
		garage_id: 168,
		part_id: 366,
		quantity: 320,
	},
	{
		garage_id: 168,
		part_id: 367,
		quantity: 423,
	},
	{
		garage_id: 168,
		part_id: 368,
		quantity: 248,
	},
	{
		garage_id: 168,
		part_id: 369,
		quantity: 140,
	},
	{
		garage_id: 168,
		part_id: 370,
		quantity: 455,
	},
	{
		garage_id: 168,
		part_id: 371,
		quantity: 317,
	},
	{
		garage_id: 168,
		part_id: 372,
		quantity: 69,
	},
	{
		garage_id: 168,
		part_id: 373,
		quantity: 118,
	},
	{
		garage_id: 168,
		part_id: 374,
		quantity: 96,
	},
	{
		garage_id: 168,
		part_id: 375,
		quantity: 420,
	},
	{
		garage_id: 168,
		part_id: 376,
		quantity: 405,
	},
	{
		garage_id: 168,
		part_id: 377,
		quantity: 350,
	},
	{
		garage_id: 168,
		part_id: 378,
		quantity: 273,
	},
	{
		garage_id: 168,
		part_id: 379,
		quantity: 108,
	},
	{
		garage_id: 168,
		part_id: 380,
		quantity: 63,
	},
	{
		garage_id: 168,
		part_id: 381,
		quantity: 264,
	},
	{
		garage_id: 168,
		part_id: 382,
		quantity: 155,
	},
	{
		garage_id: 168,
		part_id: 383,
		quantity: 323,
	},
	{
		garage_id: 168,
		part_id: 384,
		quantity: 125,
	},
	{
		garage_id: 168,
		part_id: 385,
		quantity: 162,
	},
	{
		garage_id: 168,
		part_id: 386,
		quantity: 436,
	},
	{
		garage_id: 168,
		part_id: 387,
		quantity: 398,
	},
	{
		garage_id: 168,
		part_id: 388,
		quantity: 489,
	},
	{
		garage_id: 168,
		part_id: 389,
		quantity: 475,
	},
	{
		garage_id: 168,
		part_id: 390,
		quantity: 438,
	},
	{
		garage_id: 168,
		part_id: 391,
		quantity: 344,
	},
	{
		garage_id: 168,
		part_id: 392,
		quantity: 86,
	},
	{
		garage_id: 168,
		part_id: 393,
		quantity: 232,
	},
	{
		garage_id: 168,
		part_id: 394,
		quantity: 207,
	},
	{
		garage_id: 168,
		part_id: 395,
		quantity: 369,
	},
	{
		garage_id: 168,
		part_id: 396,
		quantity: 327,
	},
	{
		garage_id: 168,
		part_id: 397,
		quantity: 482,
	},
	{
		garage_id: 168,
		part_id: 398,
		quantity: 42,
	},
	{
		garage_id: 168,
		part_id: 399,
		quantity: 57,
	},
	{
		garage_id: 169,
		part_id: 0,
		quantity: 425,
	},
	{
		garage_id: 169,
		part_id: 1,
		quantity: 250,
	},
	{
		garage_id: 169,
		part_id: 2,
		quantity: 202,
	},
	{
		garage_id: 169,
		part_id: 3,
		quantity: 70,
	},
	{
		garage_id: 169,
		part_id: 4,
		quantity: 96,
	},
	{
		garage_id: 169,
		part_id: 5,
		quantity: 131,
	},
	{
		garage_id: 169,
		part_id: 6,
		quantity: 13,
	},
	{
		garage_id: 169,
		part_id: 7,
		quantity: 273,
	},
	{
		garage_id: 169,
		part_id: 8,
		quantity: 77,
	},
	{
		garage_id: 169,
		part_id: 9,
		quantity: 200,
	},
	{
		garage_id: 169,
		part_id: 10,
		quantity: 114,
	},
	{
		garage_id: 169,
		part_id: 11,
		quantity: 402,
	},
	{
		garage_id: 169,
		part_id: 12,
		quantity: 286,
	},
	{
		garage_id: 169,
		part_id: 13,
		quantity: 275,
	},
	{
		garage_id: 169,
		part_id: 14,
		quantity: 214,
	},
	{
		garage_id: 169,
		part_id: 15,
		quantity: 388,
	},
	{
		garage_id: 169,
		part_id: 16,
		quantity: 400,
	},
	{
		garage_id: 169,
		part_id: 17,
		quantity: 144,
	},
	{
		garage_id: 169,
		part_id: 18,
		quantity: 164,
	},
	{
		garage_id: 169,
		part_id: 19,
		quantity: 81,
	},
	{
		garage_id: 169,
		part_id: 20,
		quantity: 63,
	},
	{
		garage_id: 169,
		part_id: 21,
		quantity: 317,
	},
	{
		garage_id: 169,
		part_id: 22,
		quantity: 469,
	},
	{
		garage_id: 169,
		part_id: 23,
		quantity: 234,
	},
	{
		garage_id: 169,
		part_id: 24,
		quantity: 75,
	},
	{
		garage_id: 169,
		part_id: 25,
		quantity: 407,
	},
	{
		garage_id: 169,
		part_id: 26,
		quantity: 54,
	},
	{
		garage_id: 169,
		part_id: 27,
		quantity: 482,
	},
	{
		garage_id: 169,
		part_id: 28,
		quantity: 133,
	},
	{
		garage_id: 169,
		part_id: 29,
		quantity: 413,
	},
	{
		garage_id: 169,
		part_id: 30,
		quantity: 367,
	},
	{
		garage_id: 169,
		part_id: 31,
		quantity: 103,
	},
	{
		garage_id: 169,
		part_id: 32,
		quantity: 169,
	},
	{
		garage_id: 169,
		part_id: 33,
		quantity: 228,
	},
	{
		garage_id: 169,
		part_id: 34,
		quantity: 89,
	},
	{
		garage_id: 169,
		part_id: 35,
		quantity: 150,
	},
	{
		garage_id: 169,
		part_id: 36,
		quantity: 104,
	},
	{
		garage_id: 169,
		part_id: 37,
		quantity: 330,
	},
	{
		garage_id: 169,
		part_id: 38,
		quantity: 97,
	},
	{
		garage_id: 169,
		part_id: 39,
		quantity: 148,
	},
	{
		garage_id: 169,
		part_id: 40,
		quantity: 291,
	},
	{
		garage_id: 169,
		part_id: 41,
		quantity: 55,
	},
	{
		garage_id: 169,
		part_id: 42,
		quantity: 57,
	},
	{
		garage_id: 169,
		part_id: 43,
		quantity: 10,
	},
	{
		garage_id: 169,
		part_id: 44,
		quantity: 28,
	},
	{
		garage_id: 169,
		part_id: 45,
		quantity: 135,
	},
	{
		garage_id: 169,
		part_id: 46,
		quantity: 184,
	},
	{
		garage_id: 169,
		part_id: 47,
		quantity: 454,
	},
	{
		garage_id: 169,
		part_id: 48,
		quantity: 184,
	},
	{
		garage_id: 169,
		part_id: 49,
		quantity: 103,
	},
	{
		garage_id: 169,
		part_id: 50,
		quantity: 341,
	},
	{
		garage_id: 169,
		part_id: 51,
		quantity: 60,
	},
	{
		garage_id: 169,
		part_id: 52,
		quantity: 374,
	},
	{
		garage_id: 169,
		part_id: 53,
		quantity: 487,
	},
	{
		garage_id: 169,
		part_id: 54,
		quantity: 486,
	},
	{
		garage_id: 169,
		part_id: 55,
		quantity: 423,
	},
	{
		garage_id: 169,
		part_id: 56,
		quantity: 30,
	},
	{
		garage_id: 169,
		part_id: 57,
		quantity: 268,
	},
	{
		garage_id: 169,
		part_id: 58,
		quantity: 217,
	},
	{
		garage_id: 169,
		part_id: 59,
		quantity: 467,
	},
	{
		garage_id: 169,
		part_id: 60,
		quantity: 332,
	},
	{
		garage_id: 169,
		part_id: 61,
		quantity: 377,
	},
	{
		garage_id: 169,
		part_id: 62,
		quantity: 299,
	},
	{
		garage_id: 169,
		part_id: 63,
		quantity: 295,
	},
	{
		garage_id: 169,
		part_id: 64,
		quantity: 70,
	},
	{
		garage_id: 169,
		part_id: 65,
		quantity: 119,
	},
	{
		garage_id: 169,
		part_id: 66,
		quantity: 154,
	},
	{
		garage_id: 169,
		part_id: 67,
		quantity: 91,
	},
	{
		garage_id: 169,
		part_id: 68,
		quantity: 250,
	},
	{
		garage_id: 169,
		part_id: 69,
		quantity: 112,
	},
	{
		garage_id: 169,
		part_id: 70,
		quantity: 154,
	},
	{
		garage_id: 169,
		part_id: 71,
		quantity: 256,
	},
	{
		garage_id: 169,
		part_id: 72,
		quantity: 495,
	},
	{
		garage_id: 169,
		part_id: 73,
		quantity: 63,
	},
	{
		garage_id: 169,
		part_id: 74,
		quantity: 15,
	},
	{
		garage_id: 169,
		part_id: 75,
		quantity: 235,
	},
	{
		garage_id: 169,
		part_id: 76,
		quantity: 240,
	},
	{
		garage_id: 169,
		part_id: 77,
		quantity: 500,
	},
	{
		garage_id: 169,
		part_id: 78,
		quantity: 67,
	},
	{
		garage_id: 169,
		part_id: 79,
		quantity: 171,
	},
	{
		garage_id: 169,
		part_id: 80,
		quantity: 294,
	},
	{
		garage_id: 169,
		part_id: 81,
		quantity: 386,
	},
	{
		garage_id: 169,
		part_id: 82,
		quantity: 356,
	},
	{
		garage_id: 169,
		part_id: 83,
		quantity: 194,
	},
	{
		garage_id: 169,
		part_id: 84,
		quantity: 181,
	},
	{
		garage_id: 169,
		part_id: 85,
		quantity: 392,
	},
	{
		garage_id: 169,
		part_id: 86,
		quantity: 443,
	},
	{
		garage_id: 169,
		part_id: 87,
		quantity: 359,
	},
	{
		garage_id: 169,
		part_id: 88,
		quantity: 90,
	},
	{
		garage_id: 169,
		part_id: 89,
		quantity: 476,
	},
	{
		garage_id: 169,
		part_id: 90,
		quantity: 67,
	},
	{
		garage_id: 169,
		part_id: 91,
		quantity: 312,
	},
	{
		garage_id: 169,
		part_id: 92,
		quantity: 66,
	},
	{
		garage_id: 169,
		part_id: 93,
		quantity: 389,
	},
	{
		garage_id: 169,
		part_id: 94,
		quantity: 140,
	},
	{
		garage_id: 169,
		part_id: 95,
		quantity: 132,
	},
	{
		garage_id: 169,
		part_id: 96,
		quantity: 395,
	},
	{
		garage_id: 169,
		part_id: 97,
		quantity: 379,
	},
	{
		garage_id: 169,
		part_id: 98,
		quantity: 177,
	},
	{
		garage_id: 169,
		part_id: 99,
		quantity: 14,
	},
	{
		garage_id: 169,
		part_id: 100,
		quantity: 190,
	},
	{
		garage_id: 169,
		part_id: 101,
		quantity: 32,
	},
	{
		garage_id: 169,
		part_id: 102,
		quantity: 317,
	},
	{
		garage_id: 169,
		part_id: 103,
		quantity: 156,
	},
	{
		garage_id: 169,
		part_id: 104,
		quantity: 416,
	},
	{
		garage_id: 169,
		part_id: 105,
		quantity: 301,
	},
	{
		garage_id: 169,
		part_id: 106,
		quantity: 3,
	},
	{
		garage_id: 169,
		part_id: 107,
		quantity: 2,
	},
	{
		garage_id: 169,
		part_id: 108,
		quantity: 21,
	},
	{
		garage_id: 169,
		part_id: 109,
		quantity: 89,
	},
	{
		garage_id: 169,
		part_id: 110,
		quantity: 164,
	},
	{
		garage_id: 169,
		part_id: 111,
		quantity: 119,
	},
	{
		garage_id: 169,
		part_id: 112,
		quantity: 212,
	},
	{
		garage_id: 169,
		part_id: 113,
		quantity: 217,
	},
	{
		garage_id: 169,
		part_id: 114,
		quantity: 440,
	},
	{
		garage_id: 169,
		part_id: 115,
		quantity: 167,
	},
	{
		garage_id: 169,
		part_id: 116,
		quantity: 247,
	},
	{
		garage_id: 169,
		part_id: 117,
		quantity: 484,
	},
	{
		garage_id: 169,
		part_id: 118,
		quantity: 149,
	},
	{
		garage_id: 169,
		part_id: 119,
		quantity: 145,
	},
	{
		garage_id: 169,
		part_id: 120,
		quantity: 361,
	},
	{
		garage_id: 169,
		part_id: 121,
		quantity: 305,
	},
	{
		garage_id: 169,
		part_id: 122,
		quantity: 426,
	},
	{
		garage_id: 169,
		part_id: 123,
		quantity: 462,
	},
	{
		garage_id: 169,
		part_id: 124,
		quantity: 379,
	},
	{
		garage_id: 169,
		part_id: 125,
		quantity: 123,
	},
	{
		garage_id: 169,
		part_id: 126,
		quantity: 83,
	},
	{
		garage_id: 169,
		part_id: 127,
		quantity: 278,
	},
	{
		garage_id: 169,
		part_id: 128,
		quantity: 349,
	},
	{
		garage_id: 169,
		part_id: 129,
		quantity: 357,
	},
	{
		garage_id: 169,
		part_id: 130,
		quantity: 477,
	},
	{
		garage_id: 169,
		part_id: 131,
		quantity: 170,
	},
	{
		garage_id: 169,
		part_id: 132,
		quantity: 189,
	},
	{
		garage_id: 169,
		part_id: 133,
		quantity: 130,
	},
	{
		garage_id: 169,
		part_id: 134,
		quantity: 167,
	},
	{
		garage_id: 169,
		part_id: 135,
		quantity: 216,
	},
	{
		garage_id: 169,
		part_id: 136,
		quantity: 394,
	},
	{
		garage_id: 169,
		part_id: 137,
		quantity: 354,
	},
	{
		garage_id: 169,
		part_id: 138,
		quantity: 302,
	},
	{
		garage_id: 169,
		part_id: 139,
		quantity: 484,
	},
	{
		garage_id: 169,
		part_id: 140,
		quantity: 243,
	},
	{
		garage_id: 169,
		part_id: 141,
		quantity: 384,
	},
	{
		garage_id: 169,
		part_id: 142,
		quantity: 298,
	},
	{
		garage_id: 169,
		part_id: 143,
		quantity: 74,
	},
	{
		garage_id: 169,
		part_id: 144,
		quantity: 419,
	},
	{
		garage_id: 169,
		part_id: 145,
		quantity: 154,
	},
	{
		garage_id: 169,
		part_id: 146,
		quantity: 355,
	},
	{
		garage_id: 169,
		part_id: 147,
		quantity: 128,
	},
	{
		garage_id: 169,
		part_id: 148,
		quantity: 212,
	},
	{
		garage_id: 169,
		part_id: 149,
		quantity: 275,
	},
	{
		garage_id: 169,
		part_id: 150,
		quantity: 53,
	},
	{
		garage_id: 169,
		part_id: 151,
		quantity: 51,
	},
	{
		garage_id: 169,
		part_id: 152,
		quantity: 389,
	},
	{
		garage_id: 169,
		part_id: 153,
		quantity: 310,
	},
	{
		garage_id: 169,
		part_id: 154,
		quantity: 72,
	},
	{
		garage_id: 169,
		part_id: 155,
		quantity: 137,
	},
	{
		garage_id: 169,
		part_id: 156,
		quantity: 124,
	},
	{
		garage_id: 169,
		part_id: 157,
		quantity: 225,
	},
	{
		garage_id: 169,
		part_id: 158,
		quantity: 31,
	},
	{
		garage_id: 169,
		part_id: 159,
		quantity: 218,
	},
	{
		garage_id: 169,
		part_id: 160,
		quantity: 447,
	},
	{
		garage_id: 169,
		part_id: 161,
		quantity: 314,
	},
	{
		garage_id: 169,
		part_id: 162,
		quantity: 115,
	},
	{
		garage_id: 169,
		part_id: 163,
		quantity: 166,
	},
	{
		garage_id: 169,
		part_id: 164,
		quantity: 247,
	},
	{
		garage_id: 169,
		part_id: 165,
		quantity: 496,
	},
	{
		garage_id: 169,
		part_id: 166,
		quantity: 2,
	},
	{
		garage_id: 169,
		part_id: 167,
		quantity: 402,
	},
	{
		garage_id: 169,
		part_id: 168,
		quantity: 68,
	},
	{
		garage_id: 169,
		part_id: 169,
		quantity: 318,
	},
	{
		garage_id: 169,
		part_id: 170,
		quantity: 22,
	},
	{
		garage_id: 169,
		part_id: 171,
		quantity: 106,
	},
	{
		garage_id: 169,
		part_id: 172,
		quantity: 222,
	},
	{
		garage_id: 169,
		part_id: 173,
		quantity: 302,
	},
	{
		garage_id: 169,
		part_id: 174,
		quantity: 451,
	},
	{
		garage_id: 169,
		part_id: 175,
		quantity: 464,
	},
	{
		garage_id: 169,
		part_id: 176,
		quantity: 168,
	},
	{
		garage_id: 169,
		part_id: 177,
		quantity: 87,
	},
	{
		garage_id: 169,
		part_id: 178,
		quantity: 391,
	},
	{
		garage_id: 169,
		part_id: 179,
		quantity: 95,
	},
	{
		garage_id: 169,
		part_id: 180,
		quantity: 25,
	},
	{
		garage_id: 169,
		part_id: 181,
		quantity: 183,
	},
	{
		garage_id: 169,
		part_id: 182,
		quantity: 346,
	},
	{
		garage_id: 169,
		part_id: 183,
		quantity: 229,
	},
	{
		garage_id: 169,
		part_id: 184,
		quantity: 427,
	},
	{
		garage_id: 169,
		part_id: 185,
		quantity: 207,
	},
	{
		garage_id: 169,
		part_id: 186,
		quantity: 244,
	},
	{
		garage_id: 169,
		part_id: 187,
		quantity: 98,
	},
	{
		garage_id: 169,
		part_id: 188,
		quantity: 371,
	},
	{
		garage_id: 169,
		part_id: 189,
		quantity: 88,
	},
	{
		garage_id: 169,
		part_id: 190,
		quantity: 371,
	},
	{
		garage_id: 169,
		part_id: 191,
		quantity: 233,
	},
	{
		garage_id: 169,
		part_id: 192,
		quantity: 403,
	},
	{
		garage_id: 169,
		part_id: 193,
		quantity: 160,
	},
	{
		garage_id: 169,
		part_id: 194,
		quantity: 417,
	},
	{
		garage_id: 169,
		part_id: 195,
		quantity: 472,
	},
	{
		garage_id: 169,
		part_id: 196,
		quantity: 103,
	},
	{
		garage_id: 169,
		part_id: 197,
		quantity: 350,
	},
	{
		garage_id: 169,
		part_id: 198,
		quantity: 9,
	},
	{
		garage_id: 169,
		part_id: 199,
		quantity: 302,
	},
	{
		garage_id: 169,
		part_id: 200,
		quantity: 231,
	},
	{
		garage_id: 169,
		part_id: 201,
		quantity: 176,
	},
	{
		garage_id: 169,
		part_id: 202,
		quantity: 246,
	},
	{
		garage_id: 169,
		part_id: 203,
		quantity: 299,
	},
	{
		garage_id: 169,
		part_id: 204,
		quantity: 0,
	},
	{
		garage_id: 169,
		part_id: 205,
		quantity: 423,
	},
	{
		garage_id: 169,
		part_id: 206,
		quantity: 52,
	},
	{
		garage_id: 169,
		part_id: 207,
		quantity: 297,
	},
	{
		garage_id: 169,
		part_id: 208,
		quantity: 60,
	},
	{
		garage_id: 169,
		part_id: 209,
		quantity: 346,
	},
	{
		garage_id: 169,
		part_id: 210,
		quantity: 276,
	},
	{
		garage_id: 169,
		part_id: 211,
		quantity: 94,
	},
	{
		garage_id: 169,
		part_id: 212,
		quantity: 36,
	},
	{
		garage_id: 169,
		part_id: 213,
		quantity: 109,
	},
	{
		garage_id: 169,
		part_id: 214,
		quantity: 135,
	},
	{
		garage_id: 169,
		part_id: 215,
		quantity: 22,
	},
	{
		garage_id: 169,
		part_id: 216,
		quantity: 43,
	},
	{
		garage_id: 169,
		part_id: 217,
		quantity: 329,
	},
	{
		garage_id: 169,
		part_id: 218,
		quantity: 82,
	},
	{
		garage_id: 169,
		part_id: 219,
		quantity: 19,
	},
	{
		garage_id: 169,
		part_id: 220,
		quantity: 466,
	},
	{
		garage_id: 169,
		part_id: 221,
		quantity: 491,
	},
	{
		garage_id: 169,
		part_id: 222,
		quantity: 465,
	},
	{
		garage_id: 169,
		part_id: 223,
		quantity: 247,
	},
	{
		garage_id: 169,
		part_id: 224,
		quantity: 251,
	},
	{
		garage_id: 169,
		part_id: 225,
		quantity: 358,
	},
	{
		garage_id: 169,
		part_id: 226,
		quantity: 200,
	},
	{
		garage_id: 169,
		part_id: 227,
		quantity: 266,
	},
	{
		garage_id: 169,
		part_id: 228,
		quantity: 107,
	},
	{
		garage_id: 169,
		part_id: 229,
		quantity: 368,
	},
	{
		garage_id: 169,
		part_id: 230,
		quantity: 147,
	},
	{
		garage_id: 169,
		part_id: 231,
		quantity: 366,
	},
	{
		garage_id: 169,
		part_id: 232,
		quantity: 288,
	},
	{
		garage_id: 169,
		part_id: 233,
		quantity: 281,
	},
	{
		garage_id: 169,
		part_id: 234,
		quantity: 472,
	},
	{
		garage_id: 169,
		part_id: 235,
		quantity: 253,
	},
	{
		garage_id: 169,
		part_id: 236,
		quantity: 66,
	},
	{
		garage_id: 169,
		part_id: 237,
		quantity: 163,
	},
	{
		garage_id: 169,
		part_id: 238,
		quantity: 499,
	},
	{
		garage_id: 169,
		part_id: 239,
		quantity: 340,
	},
	{
		garage_id: 169,
		part_id: 240,
		quantity: 101,
	},
	{
		garage_id: 169,
		part_id: 241,
		quantity: 455,
	},
	{
		garage_id: 169,
		part_id: 242,
		quantity: 374,
	},
	{
		garage_id: 169,
		part_id: 243,
		quantity: 250,
	},
	{
		garage_id: 169,
		part_id: 244,
		quantity: 387,
	},
	{
		garage_id: 169,
		part_id: 245,
		quantity: 273,
	},
	{
		garage_id: 169,
		part_id: 246,
		quantity: 387,
	},
	{
		garage_id: 169,
		part_id: 247,
		quantity: 350,
	},
	{
		garage_id: 169,
		part_id: 248,
		quantity: 72,
	},
	{
		garage_id: 169,
		part_id: 249,
		quantity: 87,
	},
	{
		garage_id: 169,
		part_id: 250,
		quantity: 489,
	},
	{
		garage_id: 169,
		part_id: 251,
		quantity: 188,
	},
	{
		garage_id: 169,
		part_id: 252,
		quantity: 294,
	},
	{
		garage_id: 169,
		part_id: 253,
		quantity: 213,
	},
	{
		garage_id: 169,
		part_id: 254,
		quantity: 110,
	},
	{
		garage_id: 169,
		part_id: 255,
		quantity: 177,
	},
	{
		garage_id: 169,
		part_id: 256,
		quantity: 264,
	},
	{
		garage_id: 169,
		part_id: 257,
		quantity: 233,
	},
	{
		garage_id: 169,
		part_id: 258,
		quantity: 370,
	},
	{
		garage_id: 169,
		part_id: 259,
		quantity: 96,
	},
	{
		garage_id: 169,
		part_id: 260,
		quantity: 17,
	},
	{
		garage_id: 169,
		part_id: 261,
		quantity: 49,
	},
	{
		garage_id: 169,
		part_id: 262,
		quantity: 416,
	},
	{
		garage_id: 169,
		part_id: 263,
		quantity: 425,
	},
	{
		garage_id: 169,
		part_id: 264,
		quantity: 36,
	},
	{
		garage_id: 169,
		part_id: 265,
		quantity: 195,
	},
	{
		garage_id: 169,
		part_id: 266,
		quantity: 0,
	},
	{
		garage_id: 169,
		part_id: 267,
		quantity: 353,
	},
	{
		garage_id: 169,
		part_id: 268,
		quantity: 214,
	},
	{
		garage_id: 169,
		part_id: 269,
		quantity: 304,
	},
	{
		garage_id: 169,
		part_id: 270,
		quantity: 411,
	},
	{
		garage_id: 169,
		part_id: 271,
		quantity: 78,
	},
	{
		garage_id: 169,
		part_id: 272,
		quantity: 70,
	},
	{
		garage_id: 169,
		part_id: 273,
		quantity: 17,
	},
	{
		garage_id: 169,
		part_id: 274,
		quantity: 383,
	},
	{
		garage_id: 169,
		part_id: 275,
		quantity: 167,
	},
	{
		garage_id: 169,
		part_id: 276,
		quantity: 244,
	},
	{
		garage_id: 169,
		part_id: 277,
		quantity: 71,
	},
	{
		garage_id: 169,
		part_id: 278,
		quantity: 433,
	},
	{
		garage_id: 169,
		part_id: 279,
		quantity: 208,
	},
	{
		garage_id: 169,
		part_id: 280,
		quantity: 104,
	},
	{
		garage_id: 169,
		part_id: 281,
		quantity: 412,
	},
	{
		garage_id: 169,
		part_id: 282,
		quantity: 12,
	},
	{
		garage_id: 169,
		part_id: 283,
		quantity: 425,
	},
	{
		garage_id: 169,
		part_id: 284,
		quantity: 420,
	},
	{
		garage_id: 169,
		part_id: 285,
		quantity: 104,
	},
	{
		garage_id: 169,
		part_id: 286,
		quantity: 79,
	},
	{
		garage_id: 169,
		part_id: 287,
		quantity: 420,
	},
	{
		garage_id: 169,
		part_id: 288,
		quantity: 287,
	},
	{
		garage_id: 169,
		part_id: 289,
		quantity: 396,
	},
	{
		garage_id: 169,
		part_id: 290,
		quantity: 68,
	},
	{
		garage_id: 169,
		part_id: 291,
		quantity: 111,
	},
	{
		garage_id: 169,
		part_id: 292,
		quantity: 476,
	},
	{
		garage_id: 169,
		part_id: 293,
		quantity: 44,
	},
	{
		garage_id: 169,
		part_id: 294,
		quantity: 368,
	},
	{
		garage_id: 169,
		part_id: 295,
		quantity: 34,
	},
	{
		garage_id: 169,
		part_id: 296,
		quantity: 59,
	},
	{
		garage_id: 169,
		part_id: 297,
		quantity: 238,
	},
	{
		garage_id: 169,
		part_id: 298,
		quantity: 174,
	},
	{
		garage_id: 169,
		part_id: 299,
		quantity: 304,
	},
	{
		garage_id: 169,
		part_id: 300,
		quantity: 338,
	},
	{
		garage_id: 169,
		part_id: 301,
		quantity: 442,
	},
	{
		garage_id: 169,
		part_id: 302,
		quantity: 185,
	},
	{
		garage_id: 169,
		part_id: 303,
		quantity: 118,
	},
	{
		garage_id: 169,
		part_id: 304,
		quantity: 85,
	},
	{
		garage_id: 169,
		part_id: 305,
		quantity: 346,
	},
	{
		garage_id: 169,
		part_id: 306,
		quantity: 112,
	},
	{
		garage_id: 169,
		part_id: 307,
		quantity: 498,
	},
	{
		garage_id: 169,
		part_id: 308,
		quantity: 119,
	},
	{
		garage_id: 169,
		part_id: 309,
		quantity: 56,
	},
	{
		garage_id: 169,
		part_id: 310,
		quantity: 247,
	},
	{
		garage_id: 169,
		part_id: 311,
		quantity: 347,
	},
	{
		garage_id: 169,
		part_id: 312,
		quantity: 70,
	},
	{
		garage_id: 169,
		part_id: 313,
		quantity: 177,
	},
	{
		garage_id: 169,
		part_id: 314,
		quantity: 187,
	},
	{
		garage_id: 169,
		part_id: 315,
		quantity: 426,
	},
	{
		garage_id: 169,
		part_id: 316,
		quantity: 373,
	},
	{
		garage_id: 169,
		part_id: 317,
		quantity: 332,
	},
	{
		garage_id: 169,
		part_id: 318,
		quantity: 203,
	},
	{
		garage_id: 169,
		part_id: 319,
		quantity: 132,
	},
	{
		garage_id: 169,
		part_id: 320,
		quantity: 133,
	},
	{
		garage_id: 169,
		part_id: 321,
		quantity: 263,
	},
	{
		garage_id: 169,
		part_id: 322,
		quantity: 471,
	},
	{
		garage_id: 169,
		part_id: 323,
		quantity: 386,
	},
	{
		garage_id: 169,
		part_id: 324,
		quantity: 53,
	},
	{
		garage_id: 169,
		part_id: 325,
		quantity: 401,
	},
	{
		garage_id: 169,
		part_id: 326,
		quantity: 460,
	},
	{
		garage_id: 169,
		part_id: 327,
		quantity: 231,
	},
	{
		garage_id: 169,
		part_id: 328,
		quantity: 257,
	},
	{
		garage_id: 169,
		part_id: 329,
		quantity: 79,
	},
	{
		garage_id: 169,
		part_id: 330,
		quantity: 21,
	},
	{
		garage_id: 169,
		part_id: 331,
		quantity: 12,
	},
	{
		garage_id: 169,
		part_id: 332,
		quantity: 225,
	},
	{
		garage_id: 169,
		part_id: 333,
		quantity: 321,
	},
	{
		garage_id: 169,
		part_id: 334,
		quantity: 44,
	},
	{
		garage_id: 169,
		part_id: 335,
		quantity: 166,
	},
	{
		garage_id: 169,
		part_id: 336,
		quantity: 443,
	},
	{
		garage_id: 169,
		part_id: 337,
		quantity: 486,
	},
	{
		garage_id: 169,
		part_id: 338,
		quantity: 379,
	},
	{
		garage_id: 169,
		part_id: 339,
		quantity: 466,
	},
	{
		garage_id: 169,
		part_id: 340,
		quantity: 86,
	},
	{
		garage_id: 169,
		part_id: 341,
		quantity: 224,
	},
	{
		garage_id: 169,
		part_id: 342,
		quantity: 43,
	},
	{
		garage_id: 169,
		part_id: 343,
		quantity: 61,
	},
	{
		garage_id: 169,
		part_id: 344,
		quantity: 474,
	},
	{
		garage_id: 169,
		part_id: 345,
		quantity: 186,
	},
	{
		garage_id: 169,
		part_id: 346,
		quantity: 315,
	},
	{
		garage_id: 169,
		part_id: 347,
		quantity: 99,
	},
	{
		garage_id: 169,
		part_id: 348,
		quantity: 286,
	},
	{
		garage_id: 169,
		part_id: 349,
		quantity: 227,
	},
	{
		garage_id: 169,
		part_id: 350,
		quantity: 189,
	},
	{
		garage_id: 169,
		part_id: 351,
		quantity: 238,
	},
	{
		garage_id: 169,
		part_id: 352,
		quantity: 460,
	},
	{
		garage_id: 169,
		part_id: 353,
		quantity: 284,
	},
	{
		garage_id: 169,
		part_id: 354,
		quantity: 41,
	},
	{
		garage_id: 169,
		part_id: 355,
		quantity: 478,
	},
	{
		garage_id: 169,
		part_id: 356,
		quantity: 1,
	},
	{
		garage_id: 169,
		part_id: 357,
		quantity: 255,
	},
	{
		garage_id: 169,
		part_id: 358,
		quantity: 53,
	},
	{
		garage_id: 169,
		part_id: 359,
		quantity: 70,
	},
	{
		garage_id: 169,
		part_id: 360,
		quantity: 180,
	},
	{
		garage_id: 169,
		part_id: 361,
		quantity: 465,
	},
	{
		garage_id: 169,
		part_id: 362,
		quantity: 300,
	},
	{
		garage_id: 169,
		part_id: 363,
		quantity: 53,
	},
	{
		garage_id: 169,
		part_id: 364,
		quantity: 3,
	},
	{
		garage_id: 169,
		part_id: 365,
		quantity: 86,
	},
	{
		garage_id: 169,
		part_id: 366,
		quantity: 472,
	},
	{
		garage_id: 169,
		part_id: 367,
		quantity: 438,
	},
	{
		garage_id: 169,
		part_id: 368,
		quantity: 49,
	},
	{
		garage_id: 169,
		part_id: 369,
		quantity: 107,
	},
	{
		garage_id: 169,
		part_id: 370,
		quantity: 314,
	},
	{
		garage_id: 169,
		part_id: 371,
		quantity: 174,
	},
	{
		garage_id: 169,
		part_id: 372,
		quantity: 318,
	},
	{
		garage_id: 169,
		part_id: 373,
		quantity: 9,
	},
	{
		garage_id: 169,
		part_id: 374,
		quantity: 438,
	},
	{
		garage_id: 169,
		part_id: 375,
		quantity: 366,
	},
	{
		garage_id: 169,
		part_id: 376,
		quantity: 414,
	},
	{
		garage_id: 169,
		part_id: 377,
		quantity: 244,
	},
	{
		garage_id: 169,
		part_id: 378,
		quantity: 142,
	},
	{
		garage_id: 169,
		part_id: 379,
		quantity: 295,
	},
	{
		garage_id: 169,
		part_id: 380,
		quantity: 92,
	},
	{
		garage_id: 169,
		part_id: 381,
		quantity: 205,
	},
	{
		garage_id: 169,
		part_id: 382,
		quantity: 476,
	},
	{
		garage_id: 169,
		part_id: 383,
		quantity: 112,
	},
	{
		garage_id: 169,
		part_id: 384,
		quantity: 187,
	},
	{
		garage_id: 169,
		part_id: 385,
		quantity: 480,
	},
	{
		garage_id: 169,
		part_id: 386,
		quantity: 44,
	},
	{
		garage_id: 169,
		part_id: 387,
		quantity: 184,
	},
	{
		garage_id: 169,
		part_id: 388,
		quantity: 387,
	},
	{
		garage_id: 169,
		part_id: 389,
		quantity: 250,
	},
	{
		garage_id: 169,
		part_id: 390,
		quantity: 435,
	},
	{
		garage_id: 169,
		part_id: 391,
		quantity: 153,
	},
	{
		garage_id: 169,
		part_id: 392,
		quantity: 258,
	},
	{
		garage_id: 169,
		part_id: 393,
		quantity: 485,
	},
	{
		garage_id: 169,
		part_id: 394,
		quantity: 173,
	},
	{
		garage_id: 169,
		part_id: 395,
		quantity: 427,
	},
	{
		garage_id: 169,
		part_id: 396,
		quantity: 202,
	},
	{
		garage_id: 169,
		part_id: 397,
		quantity: 108,
	},
	{
		garage_id: 169,
		part_id: 398,
		quantity: 206,
	},
	{
		garage_id: 169,
		part_id: 399,
		quantity: 457,
	},
	{
		garage_id: 170,
		part_id: 0,
		quantity: 211,
	},
	{
		garage_id: 170,
		part_id: 1,
		quantity: 328,
	},
	{
		garage_id: 170,
		part_id: 2,
		quantity: 474,
	},
	{
		garage_id: 170,
		part_id: 3,
		quantity: 228,
	},
	{
		garage_id: 170,
		part_id: 4,
		quantity: 371,
	},
	{
		garage_id: 170,
		part_id: 5,
		quantity: 271,
	},
	{
		garage_id: 170,
		part_id: 6,
		quantity: 280,
	},
	{
		garage_id: 170,
		part_id: 7,
		quantity: 215,
	},
	{
		garage_id: 170,
		part_id: 8,
		quantity: 59,
	},
	{
		garage_id: 170,
		part_id: 9,
		quantity: 317,
	},
	{
		garage_id: 170,
		part_id: 10,
		quantity: 20,
	},
	{
		garage_id: 170,
		part_id: 11,
		quantity: 354,
	},
	{
		garage_id: 170,
		part_id: 12,
		quantity: 169,
	},
	{
		garage_id: 170,
		part_id: 13,
		quantity: 440,
	},
	{
		garage_id: 170,
		part_id: 14,
		quantity: 483,
	},
	{
		garage_id: 170,
		part_id: 15,
		quantity: 420,
	},
	{
		garage_id: 170,
		part_id: 16,
		quantity: 493,
	},
	{
		garage_id: 170,
		part_id: 17,
		quantity: 423,
	},
	{
		garage_id: 170,
		part_id: 18,
		quantity: 391,
	},
	{
		garage_id: 170,
		part_id: 19,
		quantity: 81,
	},
	{
		garage_id: 170,
		part_id: 20,
		quantity: 59,
	},
	{
		garage_id: 170,
		part_id: 21,
		quantity: 425,
	},
	{
		garage_id: 170,
		part_id: 22,
		quantity: 387,
	},
	{
		garage_id: 170,
		part_id: 23,
		quantity: 346,
	},
	{
		garage_id: 170,
		part_id: 24,
		quantity: 253,
	},
	{
		garage_id: 170,
		part_id: 25,
		quantity: 155,
	},
	{
		garage_id: 170,
		part_id: 26,
		quantity: 436,
	},
	{
		garage_id: 170,
		part_id: 27,
		quantity: 446,
	},
	{
		garage_id: 170,
		part_id: 28,
		quantity: 434,
	},
	{
		garage_id: 170,
		part_id: 29,
		quantity: 266,
	},
	{
		garage_id: 170,
		part_id: 30,
		quantity: 383,
	},
	{
		garage_id: 170,
		part_id: 31,
		quantity: 356,
	},
	{
		garage_id: 170,
		part_id: 32,
		quantity: 245,
	},
	{
		garage_id: 170,
		part_id: 33,
		quantity: 416,
	},
	{
		garage_id: 170,
		part_id: 34,
		quantity: 213,
	},
	{
		garage_id: 170,
		part_id: 35,
		quantity: 329,
	},
	{
		garage_id: 170,
		part_id: 36,
		quantity: 56,
	},
	{
		garage_id: 170,
		part_id: 37,
		quantity: 418,
	},
	{
		garage_id: 170,
		part_id: 38,
		quantity: 168,
	},
	{
		garage_id: 170,
		part_id: 39,
		quantity: 499,
	},
	{
		garage_id: 170,
		part_id: 40,
		quantity: 67,
	},
	{
		garage_id: 170,
		part_id: 41,
		quantity: 12,
	},
	{
		garage_id: 170,
		part_id: 42,
		quantity: 306,
	},
	{
		garage_id: 170,
		part_id: 43,
		quantity: 489,
	},
	{
		garage_id: 170,
		part_id: 44,
		quantity: 127,
	},
	{
		garage_id: 170,
		part_id: 45,
		quantity: 78,
	},
	{
		garage_id: 170,
		part_id: 46,
		quantity: 380,
	},
	{
		garage_id: 170,
		part_id: 47,
		quantity: 466,
	},
	{
		garage_id: 170,
		part_id: 48,
		quantity: 58,
	},
	{
		garage_id: 170,
		part_id: 49,
		quantity: 247,
	},
	{
		garage_id: 170,
		part_id: 50,
		quantity: 500,
	},
	{
		garage_id: 170,
		part_id: 51,
		quantity: 32,
	},
	{
		garage_id: 170,
		part_id: 52,
		quantity: 94,
	},
	{
		garage_id: 170,
		part_id: 53,
		quantity: 90,
	},
	{
		garage_id: 170,
		part_id: 54,
		quantity: 220,
	},
	{
		garage_id: 170,
		part_id: 55,
		quantity: 402,
	},
	{
		garage_id: 170,
		part_id: 56,
		quantity: 146,
	},
	{
		garage_id: 170,
		part_id: 57,
		quantity: 354,
	},
	{
		garage_id: 170,
		part_id: 58,
		quantity: 164,
	},
	{
		garage_id: 170,
		part_id: 59,
		quantity: 432,
	},
	{
		garage_id: 170,
		part_id: 60,
		quantity: 365,
	},
	{
		garage_id: 170,
		part_id: 61,
		quantity: 419,
	},
	{
		garage_id: 170,
		part_id: 62,
		quantity: 325,
	},
	{
		garage_id: 170,
		part_id: 63,
		quantity: 394,
	},
	{
		garage_id: 170,
		part_id: 64,
		quantity: 107,
	},
	{
		garage_id: 170,
		part_id: 65,
		quantity: 301,
	},
	{
		garage_id: 170,
		part_id: 66,
		quantity: 97,
	},
	{
		garage_id: 170,
		part_id: 67,
		quantity: 170,
	},
	{
		garage_id: 170,
		part_id: 68,
		quantity: 445,
	},
	{
		garage_id: 170,
		part_id: 69,
		quantity: 9,
	},
	{
		garage_id: 170,
		part_id: 70,
		quantity: 472,
	},
	{
		garage_id: 170,
		part_id: 71,
		quantity: 74,
	},
	{
		garage_id: 170,
		part_id: 72,
		quantity: 492,
	},
	{
		garage_id: 170,
		part_id: 73,
		quantity: 291,
	},
	{
		garage_id: 170,
		part_id: 74,
		quantity: 388,
	},
	{
		garage_id: 170,
		part_id: 75,
		quantity: 50,
	},
	{
		garage_id: 170,
		part_id: 76,
		quantity: 418,
	},
	{
		garage_id: 170,
		part_id: 77,
		quantity: 226,
	},
	{
		garage_id: 170,
		part_id: 78,
		quantity: 329,
	},
	{
		garage_id: 170,
		part_id: 79,
		quantity: 476,
	},
	{
		garage_id: 170,
		part_id: 80,
		quantity: 206,
	},
	{
		garage_id: 170,
		part_id: 81,
		quantity: 390,
	},
	{
		garage_id: 170,
		part_id: 82,
		quantity: 223,
	},
	{
		garage_id: 170,
		part_id: 83,
		quantity: 470,
	},
	{
		garage_id: 170,
		part_id: 84,
		quantity: 64,
	},
	{
		garage_id: 170,
		part_id: 85,
		quantity: 285,
	},
	{
		garage_id: 170,
		part_id: 86,
		quantity: 490,
	},
	{
		garage_id: 170,
		part_id: 87,
		quantity: 496,
	},
	{
		garage_id: 170,
		part_id: 88,
		quantity: 8,
	},
	{
		garage_id: 170,
		part_id: 89,
		quantity: 174,
	},
	{
		garage_id: 170,
		part_id: 90,
		quantity: 376,
	},
	{
		garage_id: 170,
		part_id: 91,
		quantity: 144,
	},
	{
		garage_id: 170,
		part_id: 92,
		quantity: 460,
	},
	{
		garage_id: 170,
		part_id: 93,
		quantity: 5,
	},
	{
		garage_id: 170,
		part_id: 94,
		quantity: 149,
	},
	{
		garage_id: 170,
		part_id: 95,
		quantity: 380,
	},
	{
		garage_id: 170,
		part_id: 96,
		quantity: 0,
	},
	{
		garage_id: 170,
		part_id: 97,
		quantity: 201,
	},
	{
		garage_id: 170,
		part_id: 98,
		quantity: 248,
	},
	{
		garage_id: 170,
		part_id: 99,
		quantity: 176,
	},
	{
		garage_id: 170,
		part_id: 100,
		quantity: 60,
	},
	{
		garage_id: 170,
		part_id: 101,
		quantity: 467,
	},
	{
		garage_id: 170,
		part_id: 102,
		quantity: 175,
	},
	{
		garage_id: 170,
		part_id: 103,
		quantity: 497,
	},
	{
		garage_id: 170,
		part_id: 104,
		quantity: 322,
	},
	{
		garage_id: 170,
		part_id: 105,
		quantity: 58,
	},
	{
		garage_id: 170,
		part_id: 106,
		quantity: 186,
	},
	{
		garage_id: 170,
		part_id: 107,
		quantity: 11,
	},
	{
		garage_id: 170,
		part_id: 108,
		quantity: 400,
	},
	{
		garage_id: 170,
		part_id: 109,
		quantity: 326,
	},
	{
		garage_id: 170,
		part_id: 110,
		quantity: 473,
	},
	{
		garage_id: 170,
		part_id: 111,
		quantity: 290,
	},
	{
		garage_id: 170,
		part_id: 112,
		quantity: 432,
	},
	{
		garage_id: 170,
		part_id: 113,
		quantity: 23,
	},
	{
		garage_id: 170,
		part_id: 114,
		quantity: 316,
	},
	{
		garage_id: 170,
		part_id: 115,
		quantity: 176,
	},
	{
		garage_id: 170,
		part_id: 116,
		quantity: 393,
	},
	{
		garage_id: 170,
		part_id: 117,
		quantity: 241,
	},
	{
		garage_id: 170,
		part_id: 118,
		quantity: 210,
	},
	{
		garage_id: 170,
		part_id: 119,
		quantity: 84,
	},
	{
		garage_id: 170,
		part_id: 120,
		quantity: 494,
	},
	{
		garage_id: 170,
		part_id: 121,
		quantity: 431,
	},
	{
		garage_id: 170,
		part_id: 122,
		quantity: 118,
	},
	{
		garage_id: 170,
		part_id: 123,
		quantity: 65,
	},
	{
		garage_id: 170,
		part_id: 124,
		quantity: 40,
	},
	{
		garage_id: 170,
		part_id: 125,
		quantity: 402,
	},
	{
		garage_id: 170,
		part_id: 126,
		quantity: 326,
	},
	{
		garage_id: 170,
		part_id: 127,
		quantity: 472,
	},
	{
		garage_id: 170,
		part_id: 128,
		quantity: 6,
	},
	{
		garage_id: 170,
		part_id: 129,
		quantity: 395,
	},
	{
		garage_id: 170,
		part_id: 130,
		quantity: 400,
	},
	{
		garage_id: 170,
		part_id: 131,
		quantity: 468,
	},
	{
		garage_id: 170,
		part_id: 132,
		quantity: 391,
	},
	{
		garage_id: 170,
		part_id: 133,
		quantity: 316,
	},
	{
		garage_id: 170,
		part_id: 134,
		quantity: 383,
	},
	{
		garage_id: 170,
		part_id: 135,
		quantity: 156,
	},
	{
		garage_id: 170,
		part_id: 136,
		quantity: 341,
	},
	{
		garage_id: 170,
		part_id: 137,
		quantity: 254,
	},
	{
		garage_id: 170,
		part_id: 138,
		quantity: 54,
	},
	{
		garage_id: 170,
		part_id: 139,
		quantity: 395,
	},
	{
		garage_id: 170,
		part_id: 140,
		quantity: 416,
	},
	{
		garage_id: 170,
		part_id: 141,
		quantity: 85,
	},
	{
		garage_id: 170,
		part_id: 142,
		quantity: 258,
	},
	{
		garage_id: 170,
		part_id: 143,
		quantity: 398,
	},
	{
		garage_id: 170,
		part_id: 144,
		quantity: 90,
	},
	{
		garage_id: 170,
		part_id: 145,
		quantity: 91,
	},
	{
		garage_id: 170,
		part_id: 146,
		quantity: 180,
	},
	{
		garage_id: 170,
		part_id: 147,
		quantity: 204,
	},
	{
		garage_id: 170,
		part_id: 148,
		quantity: 234,
	},
	{
		garage_id: 170,
		part_id: 149,
		quantity: 98,
	},
	{
		garage_id: 170,
		part_id: 150,
		quantity: 0,
	},
	{
		garage_id: 170,
		part_id: 151,
		quantity: 372,
	},
	{
		garage_id: 170,
		part_id: 152,
		quantity: 266,
	},
	{
		garage_id: 170,
		part_id: 153,
		quantity: 150,
	},
	{
		garage_id: 170,
		part_id: 154,
		quantity: 13,
	},
	{
		garage_id: 170,
		part_id: 155,
		quantity: 396,
	},
	{
		garage_id: 170,
		part_id: 156,
		quantity: 316,
	},
	{
		garage_id: 170,
		part_id: 157,
		quantity: 222,
	},
	{
		garage_id: 170,
		part_id: 158,
		quantity: 244,
	},
	{
		garage_id: 170,
		part_id: 159,
		quantity: 366,
	},
	{
		garage_id: 170,
		part_id: 160,
		quantity: 353,
	},
	{
		garage_id: 170,
		part_id: 161,
		quantity: 75,
	},
	{
		garage_id: 170,
		part_id: 162,
		quantity: 33,
	},
	{
		garage_id: 170,
		part_id: 163,
		quantity: 395,
	},
	{
		garage_id: 170,
		part_id: 164,
		quantity: 263,
	},
	{
		garage_id: 170,
		part_id: 165,
		quantity: 36,
	},
	{
		garage_id: 170,
		part_id: 166,
		quantity: 342,
	},
	{
		garage_id: 170,
		part_id: 167,
		quantity: 453,
	},
	{
		garage_id: 170,
		part_id: 168,
		quantity: 376,
	},
	{
		garage_id: 170,
		part_id: 169,
		quantity: 8,
	},
	{
		garage_id: 170,
		part_id: 170,
		quantity: 205,
	},
	{
		garage_id: 170,
		part_id: 171,
		quantity: 318,
	},
	{
		garage_id: 170,
		part_id: 172,
		quantity: 230,
	},
	{
		garage_id: 170,
		part_id: 173,
		quantity: 30,
	},
	{
		garage_id: 170,
		part_id: 174,
		quantity: 272,
	},
	{
		garage_id: 170,
		part_id: 175,
		quantity: 479,
	},
	{
		garage_id: 170,
		part_id: 176,
		quantity: 346,
	},
	{
		garage_id: 170,
		part_id: 177,
		quantity: 15,
	},
	{
		garage_id: 170,
		part_id: 178,
		quantity: 302,
	},
	{
		garage_id: 170,
		part_id: 179,
		quantity: 485,
	},
	{
		garage_id: 170,
		part_id: 180,
		quantity: 341,
	},
	{
		garage_id: 170,
		part_id: 181,
		quantity: 295,
	},
	{
		garage_id: 170,
		part_id: 182,
		quantity: 345,
	},
	{
		garage_id: 170,
		part_id: 183,
		quantity: 329,
	},
	{
		garage_id: 170,
		part_id: 184,
		quantity: 19,
	},
	{
		garage_id: 170,
		part_id: 185,
		quantity: 256,
	},
	{
		garage_id: 170,
		part_id: 186,
		quantity: 62,
	},
	{
		garage_id: 170,
		part_id: 187,
		quantity: 396,
	},
	{
		garage_id: 170,
		part_id: 188,
		quantity: 97,
	},
	{
		garage_id: 170,
		part_id: 189,
		quantity: 368,
	},
	{
		garage_id: 170,
		part_id: 190,
		quantity: 145,
	},
	{
		garage_id: 170,
		part_id: 191,
		quantity: 228,
	},
	{
		garage_id: 170,
		part_id: 192,
		quantity: 444,
	},
	{
		garage_id: 170,
		part_id: 193,
		quantity: 246,
	},
	{
		garage_id: 170,
		part_id: 194,
		quantity: 127,
	},
	{
		garage_id: 170,
		part_id: 195,
		quantity: 457,
	},
	{
		garage_id: 170,
		part_id: 196,
		quantity: 180,
	},
	{
		garage_id: 170,
		part_id: 197,
		quantity: 94,
	},
	{
		garage_id: 170,
		part_id: 198,
		quantity: 294,
	},
	{
		garage_id: 170,
		part_id: 199,
		quantity: 15,
	},
	{
		garage_id: 170,
		part_id: 200,
		quantity: 62,
	},
	{
		garage_id: 170,
		part_id: 201,
		quantity: 345,
	},
	{
		garage_id: 170,
		part_id: 202,
		quantity: 184,
	},
	{
		garage_id: 170,
		part_id: 203,
		quantity: 478,
	},
	{
		garage_id: 170,
		part_id: 204,
		quantity: 412,
	},
	{
		garage_id: 170,
		part_id: 205,
		quantity: 381,
	},
	{
		garage_id: 170,
		part_id: 206,
		quantity: 203,
	},
	{
		garage_id: 170,
		part_id: 207,
		quantity: 381,
	},
	{
		garage_id: 170,
		part_id: 208,
		quantity: 69,
	},
	{
		garage_id: 170,
		part_id: 209,
		quantity: 298,
	},
	{
		garage_id: 170,
		part_id: 210,
		quantity: 314,
	},
	{
		garage_id: 170,
		part_id: 211,
		quantity: 104,
	},
	{
		garage_id: 170,
		part_id: 212,
		quantity: 286,
	},
	{
		garage_id: 170,
		part_id: 213,
		quantity: 4,
	},
	{
		garage_id: 170,
		part_id: 214,
		quantity: 5,
	},
	{
		garage_id: 170,
		part_id: 215,
		quantity: 305,
	},
	{
		garage_id: 170,
		part_id: 216,
		quantity: 35,
	},
	{
		garage_id: 170,
		part_id: 217,
		quantity: 184,
	},
	{
		garage_id: 170,
		part_id: 218,
		quantity: 419,
	},
	{
		garage_id: 170,
		part_id: 219,
		quantity: 15,
	},
	{
		garage_id: 170,
		part_id: 220,
		quantity: 431,
	},
	{
		garage_id: 170,
		part_id: 221,
		quantity: 500,
	},
	{
		garage_id: 170,
		part_id: 222,
		quantity: 188,
	},
	{
		garage_id: 170,
		part_id: 223,
		quantity: 49,
	},
	{
		garage_id: 170,
		part_id: 224,
		quantity: 469,
	},
	{
		garage_id: 170,
		part_id: 225,
		quantity: 237,
	},
	{
		garage_id: 170,
		part_id: 226,
		quantity: 427,
	},
	{
		garage_id: 170,
		part_id: 227,
		quantity: 298,
	},
	{
		garage_id: 170,
		part_id: 228,
		quantity: 480,
	},
	{
		garage_id: 170,
		part_id: 229,
		quantity: 258,
	},
	{
		garage_id: 170,
		part_id: 230,
		quantity: 290,
	},
	{
		garage_id: 170,
		part_id: 231,
		quantity: 60,
	},
	{
		garage_id: 170,
		part_id: 232,
		quantity: 90,
	},
	{
		garage_id: 170,
		part_id: 233,
		quantity: 365,
	},
	{
		garage_id: 170,
		part_id: 234,
		quantity: 126,
	},
	{
		garage_id: 170,
		part_id: 235,
		quantity: 390,
	},
	{
		garage_id: 170,
		part_id: 236,
		quantity: 12,
	},
	{
		garage_id: 170,
		part_id: 237,
		quantity: 17,
	},
	{
		garage_id: 170,
		part_id: 238,
		quantity: 117,
	},
	{
		garage_id: 170,
		part_id: 239,
		quantity: 13,
	},
	{
		garage_id: 170,
		part_id: 240,
		quantity: 182,
	},
	{
		garage_id: 170,
		part_id: 241,
		quantity: 205,
	},
	{
		garage_id: 170,
		part_id: 242,
		quantity: 364,
	},
	{
		garage_id: 170,
		part_id: 243,
		quantity: 11,
	},
	{
		garage_id: 170,
		part_id: 244,
		quantity: 278,
	},
	{
		garage_id: 170,
		part_id: 245,
		quantity: 459,
	},
	{
		garage_id: 170,
		part_id: 246,
		quantity: 483,
	},
	{
		garage_id: 170,
		part_id: 247,
		quantity: 141,
	},
	{
		garage_id: 170,
		part_id: 248,
		quantity: 258,
	},
	{
		garage_id: 170,
		part_id: 249,
		quantity: 137,
	},
	{
		garage_id: 170,
		part_id: 250,
		quantity: 354,
	},
	{
		garage_id: 170,
		part_id: 251,
		quantity: 142,
	},
	{
		garage_id: 170,
		part_id: 252,
		quantity: 314,
	},
	{
		garage_id: 170,
		part_id: 253,
		quantity: 426,
	},
	{
		garage_id: 170,
		part_id: 254,
		quantity: 383,
	},
	{
		garage_id: 170,
		part_id: 255,
		quantity: 368,
	},
	{
		garage_id: 170,
		part_id: 256,
		quantity: 446,
	},
	{
		garage_id: 170,
		part_id: 257,
		quantity: 125,
	},
	{
		garage_id: 170,
		part_id: 258,
		quantity: 9,
	},
	{
		garage_id: 170,
		part_id: 259,
		quantity: 275,
	},
	{
		garage_id: 170,
		part_id: 260,
		quantity: 241,
	},
	{
		garage_id: 170,
		part_id: 261,
		quantity: 137,
	},
	{
		garage_id: 170,
		part_id: 262,
		quantity: 363,
	},
	{
		garage_id: 170,
		part_id: 263,
		quantity: 160,
	},
	{
		garage_id: 170,
		part_id: 264,
		quantity: 6,
	},
	{
		garage_id: 170,
		part_id: 265,
		quantity: 221,
	},
	{
		garage_id: 170,
		part_id: 266,
		quantity: 95,
	},
	{
		garage_id: 170,
		part_id: 267,
		quantity: 2,
	},
	{
		garage_id: 170,
		part_id: 268,
		quantity: 327,
	},
	{
		garage_id: 170,
		part_id: 269,
		quantity: 195,
	},
	{
		garage_id: 170,
		part_id: 270,
		quantity: 237,
	},
	{
		garage_id: 170,
		part_id: 271,
		quantity: 126,
	},
	{
		garage_id: 170,
		part_id: 272,
		quantity: 185,
	},
	{
		garage_id: 170,
		part_id: 273,
		quantity: 100,
	},
	{
		garage_id: 170,
		part_id: 274,
		quantity: 5,
	},
	{
		garage_id: 170,
		part_id: 275,
		quantity: 310,
	},
	{
		garage_id: 170,
		part_id: 276,
		quantity: 272,
	},
	{
		garage_id: 170,
		part_id: 277,
		quantity: 433,
	},
	{
		garage_id: 170,
		part_id: 278,
		quantity: 10,
	},
	{
		garage_id: 170,
		part_id: 279,
		quantity: 441,
	},
	{
		garage_id: 170,
		part_id: 280,
		quantity: 309,
	},
	{
		garage_id: 170,
		part_id: 281,
		quantity: 346,
	},
	{
		garage_id: 170,
		part_id: 282,
		quantity: 159,
	},
	{
		garage_id: 170,
		part_id: 283,
		quantity: 324,
	},
	{
		garage_id: 170,
		part_id: 284,
		quantity: 134,
	},
	{
		garage_id: 170,
		part_id: 285,
		quantity: 480,
	},
	{
		garage_id: 170,
		part_id: 286,
		quantity: 70,
	},
	{
		garage_id: 170,
		part_id: 287,
		quantity: 216,
	},
	{
		garage_id: 170,
		part_id: 288,
		quantity: 479,
	},
	{
		garage_id: 170,
		part_id: 289,
		quantity: 51,
	},
	{
		garage_id: 170,
		part_id: 290,
		quantity: 381,
	},
	{
		garage_id: 170,
		part_id: 291,
		quantity: 172,
	},
	{
		garage_id: 170,
		part_id: 292,
		quantity: 121,
	},
	{
		garage_id: 170,
		part_id: 293,
		quantity: 35,
	},
	{
		garage_id: 170,
		part_id: 294,
		quantity: 127,
	},
	{
		garage_id: 170,
		part_id: 295,
		quantity: 31,
	},
	{
		garage_id: 170,
		part_id: 296,
		quantity: 479,
	},
	{
		garage_id: 170,
		part_id: 297,
		quantity: 444,
	},
	{
		garage_id: 170,
		part_id: 298,
		quantity: 97,
	},
	{
		garage_id: 170,
		part_id: 299,
		quantity: 22,
	},
	{
		garage_id: 170,
		part_id: 300,
		quantity: 292,
	},
	{
		garage_id: 170,
		part_id: 301,
		quantity: 497,
	},
	{
		garage_id: 170,
		part_id: 302,
		quantity: 256,
	},
	{
		garage_id: 170,
		part_id: 303,
		quantity: 334,
	},
	{
		garage_id: 170,
		part_id: 304,
		quantity: 158,
	},
	{
		garage_id: 170,
		part_id: 305,
		quantity: 249,
	},
	{
		garage_id: 170,
		part_id: 306,
		quantity: 245,
	},
	{
		garage_id: 170,
		part_id: 307,
		quantity: 357,
	},
	{
		garage_id: 170,
		part_id: 308,
		quantity: 421,
	},
	{
		garage_id: 170,
		part_id: 309,
		quantity: 257,
	},
	{
		garage_id: 170,
		part_id: 310,
		quantity: 109,
	},
	{
		garage_id: 170,
		part_id: 311,
		quantity: 279,
	},
	{
		garage_id: 170,
		part_id: 312,
		quantity: 387,
	},
	{
		garage_id: 170,
		part_id: 313,
		quantity: 383,
	},
	{
		garage_id: 170,
		part_id: 314,
		quantity: 14,
	},
	{
		garage_id: 170,
		part_id: 315,
		quantity: 56,
	},
	{
		garage_id: 170,
		part_id: 316,
		quantity: 276,
	},
	{
		garage_id: 170,
		part_id: 317,
		quantity: 278,
	},
	{
		garage_id: 170,
		part_id: 318,
		quantity: 458,
	},
	{
		garage_id: 170,
		part_id: 319,
		quantity: 423,
	},
	{
		garage_id: 170,
		part_id: 320,
		quantity: 350,
	},
	{
		garage_id: 170,
		part_id: 321,
		quantity: 381,
	},
	{
		garage_id: 170,
		part_id: 322,
		quantity: 403,
	},
	{
		garage_id: 170,
		part_id: 323,
		quantity: 437,
	},
	{
		garage_id: 170,
		part_id: 324,
		quantity: 496,
	},
	{
		garage_id: 170,
		part_id: 325,
		quantity: 267,
	},
	{
		garage_id: 170,
		part_id: 326,
		quantity: 458,
	},
	{
		garage_id: 170,
		part_id: 327,
		quantity: 128,
	},
	{
		garage_id: 170,
		part_id: 328,
		quantity: 279,
	},
	{
		garage_id: 170,
		part_id: 329,
		quantity: 107,
	},
	{
		garage_id: 170,
		part_id: 330,
		quantity: 436,
	},
	{
		garage_id: 170,
		part_id: 331,
		quantity: 60,
	},
	{
		garage_id: 170,
		part_id: 332,
		quantity: 497,
	},
	{
		garage_id: 170,
		part_id: 333,
		quantity: 365,
	},
	{
		garage_id: 170,
		part_id: 334,
		quantity: 329,
	},
	{
		garage_id: 170,
		part_id: 335,
		quantity: 270,
	},
	{
		garage_id: 170,
		part_id: 336,
		quantity: 453,
	},
	{
		garage_id: 170,
		part_id: 337,
		quantity: 201,
	},
	{
		garage_id: 170,
		part_id: 338,
		quantity: 389,
	},
	{
		garage_id: 170,
		part_id: 339,
		quantity: 254,
	},
	{
		garage_id: 170,
		part_id: 340,
		quantity: 25,
	},
	{
		garage_id: 170,
		part_id: 341,
		quantity: 456,
	},
	{
		garage_id: 170,
		part_id: 342,
		quantity: 101,
	},
	{
		garage_id: 170,
		part_id: 343,
		quantity: 65,
	},
	{
		garage_id: 170,
		part_id: 344,
		quantity: 166,
	},
	{
		garage_id: 170,
		part_id: 345,
		quantity: 446,
	},
	{
		garage_id: 170,
		part_id: 346,
		quantity: 209,
	},
	{
		garage_id: 170,
		part_id: 347,
		quantity: 455,
	},
	{
		garage_id: 170,
		part_id: 348,
		quantity: 279,
	},
	{
		garage_id: 170,
		part_id: 349,
		quantity: 126,
	},
	{
		garage_id: 170,
		part_id: 350,
		quantity: 349,
	},
	{
		garage_id: 170,
		part_id: 351,
		quantity: 402,
	},
	{
		garage_id: 170,
		part_id: 352,
		quantity: 235,
	},
	{
		garage_id: 170,
		part_id: 353,
		quantity: 140,
	},
	{
		garage_id: 170,
		part_id: 354,
		quantity: 290,
	},
	{
		garage_id: 170,
		part_id: 355,
		quantity: 438,
	},
	{
		garage_id: 170,
		part_id: 356,
		quantity: 463,
	},
	{
		garage_id: 170,
		part_id: 357,
		quantity: 307,
	},
	{
		garage_id: 170,
		part_id: 358,
		quantity: 144,
	},
	{
		garage_id: 170,
		part_id: 359,
		quantity: 464,
	},
	{
		garage_id: 170,
		part_id: 360,
		quantity: 315,
	},
	{
		garage_id: 170,
		part_id: 361,
		quantity: 91,
	},
	{
		garage_id: 170,
		part_id: 362,
		quantity: 351,
	},
	{
		garage_id: 170,
		part_id: 363,
		quantity: 428,
	},
	{
		garage_id: 170,
		part_id: 364,
		quantity: 395,
	},
	{
		garage_id: 170,
		part_id: 365,
		quantity: 247,
	},
	{
		garage_id: 170,
		part_id: 366,
		quantity: 179,
	},
	{
		garage_id: 170,
		part_id: 367,
		quantity: 161,
	},
	{
		garage_id: 170,
		part_id: 368,
		quantity: 107,
	},
	{
		garage_id: 170,
		part_id: 369,
		quantity: 134,
	},
	{
		garage_id: 170,
		part_id: 370,
		quantity: 65,
	},
	{
		garage_id: 170,
		part_id: 371,
		quantity: 253,
	},
	{
		garage_id: 170,
		part_id: 372,
		quantity: 339,
	},
	{
		garage_id: 170,
		part_id: 373,
		quantity: 81,
	},
	{
		garage_id: 170,
		part_id: 374,
		quantity: 431,
	},
	{
		garage_id: 170,
		part_id: 375,
		quantity: 388,
	},
	{
		garage_id: 170,
		part_id: 376,
		quantity: 90,
	},
	{
		garage_id: 170,
		part_id: 377,
		quantity: 173,
	},
	{
		garage_id: 170,
		part_id: 378,
		quantity: 286,
	},
	{
		garage_id: 170,
		part_id: 379,
		quantity: 446,
	},
	{
		garage_id: 170,
		part_id: 380,
		quantity: 500,
	},
	{
		garage_id: 170,
		part_id: 381,
		quantity: 316,
	},
	{
		garage_id: 170,
		part_id: 382,
		quantity: 22,
	},
	{
		garage_id: 170,
		part_id: 383,
		quantity: 479,
	},
	{
		garage_id: 170,
		part_id: 384,
		quantity: 24,
	},
	{
		garage_id: 170,
		part_id: 385,
		quantity: 89,
	},
	{
		garage_id: 170,
		part_id: 386,
		quantity: 76,
	},
	{
		garage_id: 170,
		part_id: 387,
		quantity: 474,
	},
	{
		garage_id: 170,
		part_id: 388,
		quantity: 44,
	},
	{
		garage_id: 170,
		part_id: 389,
		quantity: 283,
	},
	{
		garage_id: 170,
		part_id: 390,
		quantity: 64,
	},
	{
		garage_id: 170,
		part_id: 391,
		quantity: 315,
	},
	{
		garage_id: 170,
		part_id: 392,
		quantity: 31,
	},
	{
		garage_id: 170,
		part_id: 393,
		quantity: 165,
	},
	{
		garage_id: 170,
		part_id: 394,
		quantity: 472,
	},
	{
		garage_id: 170,
		part_id: 395,
		quantity: 246,
	},
	{
		garage_id: 170,
		part_id: 396,
		quantity: 380,
	},
	{
		garage_id: 170,
		part_id: 397,
		quantity: 195,
	},
	{
		garage_id: 170,
		part_id: 398,
		quantity: 277,
	},
	{
		garage_id: 170,
		part_id: 399,
		quantity: 366,
	},
	{
		garage_id: 171,
		part_id: 0,
		quantity: 49,
	},
	{
		garage_id: 171,
		part_id: 1,
		quantity: 211,
	},
	{
		garage_id: 171,
		part_id: 2,
		quantity: 45,
	},
	{
		garage_id: 171,
		part_id: 3,
		quantity: 327,
	},
	{
		garage_id: 171,
		part_id: 4,
		quantity: 350,
	},
	{
		garage_id: 171,
		part_id: 5,
		quantity: 317,
	},
	{
		garage_id: 171,
		part_id: 6,
		quantity: 17,
	},
	{
		garage_id: 171,
		part_id: 7,
		quantity: 459,
	},
	{
		garage_id: 171,
		part_id: 8,
		quantity: 62,
	},
	{
		garage_id: 171,
		part_id: 9,
		quantity: 355,
	},
	{
		garage_id: 171,
		part_id: 10,
		quantity: 233,
	},
	{
		garage_id: 171,
		part_id: 11,
		quantity: 102,
	},
	{
		garage_id: 171,
		part_id: 12,
		quantity: 237,
	},
	{
		garage_id: 171,
		part_id: 13,
		quantity: 271,
	},
	{
		garage_id: 171,
		part_id: 14,
		quantity: 311,
	},
	{
		garage_id: 171,
		part_id: 15,
		quantity: 130,
	},
	{
		garage_id: 171,
		part_id: 16,
		quantity: 294,
	},
	{
		garage_id: 171,
		part_id: 17,
		quantity: 456,
	},
	{
		garage_id: 171,
		part_id: 18,
		quantity: 257,
	},
	{
		garage_id: 171,
		part_id: 19,
		quantity: 324,
	},
	{
		garage_id: 171,
		part_id: 20,
		quantity: 173,
	},
	{
		garage_id: 171,
		part_id: 21,
		quantity: 361,
	},
	{
		garage_id: 171,
		part_id: 22,
		quantity: 495,
	},
	{
		garage_id: 171,
		part_id: 23,
		quantity: 284,
	},
	{
		garage_id: 171,
		part_id: 24,
		quantity: 90,
	},
	{
		garage_id: 171,
		part_id: 25,
		quantity: 56,
	},
	{
		garage_id: 171,
		part_id: 26,
		quantity: 254,
	},
	{
		garage_id: 171,
		part_id: 27,
		quantity: 282,
	},
	{
		garage_id: 171,
		part_id: 28,
		quantity: 34,
	},
	{
		garage_id: 171,
		part_id: 29,
		quantity: 324,
	},
	{
		garage_id: 171,
		part_id: 30,
		quantity: 25,
	},
	{
		garage_id: 171,
		part_id: 31,
		quantity: 182,
	},
	{
		garage_id: 171,
		part_id: 32,
		quantity: 44,
	},
	{
		garage_id: 171,
		part_id: 33,
		quantity: 229,
	},
	{
		garage_id: 171,
		part_id: 34,
		quantity: 292,
	},
	{
		garage_id: 171,
		part_id: 35,
		quantity: 485,
	},
	{
		garage_id: 171,
		part_id: 36,
		quantity: 151,
	},
	{
		garage_id: 171,
		part_id: 37,
		quantity: 440,
	},
	{
		garage_id: 171,
		part_id: 38,
		quantity: 71,
	},
	{
		garage_id: 171,
		part_id: 39,
		quantity: 448,
	},
	{
		garage_id: 171,
		part_id: 40,
		quantity: 420,
	},
	{
		garage_id: 171,
		part_id: 41,
		quantity: 344,
	},
	{
		garage_id: 171,
		part_id: 42,
		quantity: 86,
	},
	{
		garage_id: 171,
		part_id: 43,
		quantity: 456,
	},
	{
		garage_id: 171,
		part_id: 44,
		quantity: 366,
	},
	{
		garage_id: 171,
		part_id: 45,
		quantity: 483,
	},
	{
		garage_id: 171,
		part_id: 46,
		quantity: 29,
	},
	{
		garage_id: 171,
		part_id: 47,
		quantity: 181,
	},
	{
		garage_id: 171,
		part_id: 48,
		quantity: 417,
	},
	{
		garage_id: 171,
		part_id: 49,
		quantity: 415,
	},
	{
		garage_id: 171,
		part_id: 50,
		quantity: 203,
	},
	{
		garage_id: 171,
		part_id: 51,
		quantity: 374,
	},
	{
		garage_id: 171,
		part_id: 52,
		quantity: 230,
	},
	{
		garage_id: 171,
		part_id: 53,
		quantity: 463,
	},
	{
		garage_id: 171,
		part_id: 54,
		quantity: 25,
	},
	{
		garage_id: 171,
		part_id: 55,
		quantity: 144,
	},
	{
		garage_id: 171,
		part_id: 56,
		quantity: 93,
	},
	{
		garage_id: 171,
		part_id: 57,
		quantity: 254,
	},
	{
		garage_id: 171,
		part_id: 58,
		quantity: 475,
	},
	{
		garage_id: 171,
		part_id: 59,
		quantity: 205,
	},
	{
		garage_id: 171,
		part_id: 60,
		quantity: 272,
	},
	{
		garage_id: 171,
		part_id: 61,
		quantity: 281,
	},
	{
		garage_id: 171,
		part_id: 62,
		quantity: 498,
	},
	{
		garage_id: 171,
		part_id: 63,
		quantity: 356,
	},
	{
		garage_id: 171,
		part_id: 64,
		quantity: 115,
	},
	{
		garage_id: 171,
		part_id: 65,
		quantity: 293,
	},
	{
		garage_id: 171,
		part_id: 66,
		quantity: 67,
	},
	{
		garage_id: 171,
		part_id: 67,
		quantity: 296,
	},
	{
		garage_id: 171,
		part_id: 68,
		quantity: 378,
	},
	{
		garage_id: 171,
		part_id: 69,
		quantity: 29,
	},
	{
		garage_id: 171,
		part_id: 70,
		quantity: 389,
	},
	{
		garage_id: 171,
		part_id: 71,
		quantity: 379,
	},
	{
		garage_id: 171,
		part_id: 72,
		quantity: 346,
	},
	{
		garage_id: 171,
		part_id: 73,
		quantity: 71,
	},
	{
		garage_id: 171,
		part_id: 74,
		quantity: 256,
	},
	{
		garage_id: 171,
		part_id: 75,
		quantity: 25,
	},
	{
		garage_id: 171,
		part_id: 76,
		quantity: 438,
	},
	{
		garage_id: 171,
		part_id: 77,
		quantity: 388,
	},
	{
		garage_id: 171,
		part_id: 78,
		quantity: 103,
	},
	{
		garage_id: 171,
		part_id: 79,
		quantity: 413,
	},
	{
		garage_id: 171,
		part_id: 80,
		quantity: 15,
	},
	{
		garage_id: 171,
		part_id: 81,
		quantity: 269,
	},
	{
		garage_id: 171,
		part_id: 82,
		quantity: 409,
	},
	{
		garage_id: 171,
		part_id: 83,
		quantity: 282,
	},
	{
		garage_id: 171,
		part_id: 84,
		quantity: 415,
	},
	{
		garage_id: 171,
		part_id: 85,
		quantity: 29,
	},
	{
		garage_id: 171,
		part_id: 86,
		quantity: 19,
	},
	{
		garage_id: 171,
		part_id: 87,
		quantity: 128,
	},
	{
		garage_id: 171,
		part_id: 88,
		quantity: 140,
	},
	{
		garage_id: 171,
		part_id: 89,
		quantity: 80,
	},
	{
		garage_id: 171,
		part_id: 90,
		quantity: 66,
	},
	{
		garage_id: 171,
		part_id: 91,
		quantity: 310,
	},
	{
		garage_id: 171,
		part_id: 92,
		quantity: 107,
	},
	{
		garage_id: 171,
		part_id: 93,
		quantity: 313,
	},
	{
		garage_id: 171,
		part_id: 94,
		quantity: 475,
	},
	{
		garage_id: 171,
		part_id: 95,
		quantity: 91,
	},
	{
		garage_id: 171,
		part_id: 96,
		quantity: 272,
	},
	{
		garage_id: 171,
		part_id: 97,
		quantity: 419,
	},
	{
		garage_id: 171,
		part_id: 98,
		quantity: 264,
	},
	{
		garage_id: 171,
		part_id: 99,
		quantity: 453,
	},
	{
		garage_id: 171,
		part_id: 100,
		quantity: 162,
	},
	{
		garage_id: 171,
		part_id: 101,
		quantity: 106,
	},
	{
		garage_id: 171,
		part_id: 102,
		quantity: 138,
	},
	{
		garage_id: 171,
		part_id: 103,
		quantity: 395,
	},
	{
		garage_id: 171,
		part_id: 104,
		quantity: 40,
	},
	{
		garage_id: 171,
		part_id: 105,
		quantity: 358,
	},
	{
		garage_id: 171,
		part_id: 106,
		quantity: 35,
	},
	{
		garage_id: 171,
		part_id: 107,
		quantity: 358,
	},
	{
		garage_id: 171,
		part_id: 108,
		quantity: 285,
	},
	{
		garage_id: 171,
		part_id: 109,
		quantity: 493,
	},
	{
		garage_id: 171,
		part_id: 110,
		quantity: 308,
	},
	{
		garage_id: 171,
		part_id: 111,
		quantity: 329,
	},
	{
		garage_id: 171,
		part_id: 112,
		quantity: 285,
	},
	{
		garage_id: 171,
		part_id: 113,
		quantity: 70,
	},
	{
		garage_id: 171,
		part_id: 114,
		quantity: 440,
	},
	{
		garage_id: 171,
		part_id: 115,
		quantity: 224,
	},
	{
		garage_id: 171,
		part_id: 116,
		quantity: 111,
	},
	{
		garage_id: 171,
		part_id: 117,
		quantity: 134,
	},
	{
		garage_id: 171,
		part_id: 118,
		quantity: 49,
	},
	{
		garage_id: 171,
		part_id: 119,
		quantity: 171,
	},
	{
		garage_id: 171,
		part_id: 120,
		quantity: 226,
	},
	{
		garage_id: 171,
		part_id: 121,
		quantity: 348,
	},
	{
		garage_id: 171,
		part_id: 122,
		quantity: 18,
	},
	{
		garage_id: 171,
		part_id: 123,
		quantity: 399,
	},
	{
		garage_id: 171,
		part_id: 124,
		quantity: 362,
	},
	{
		garage_id: 171,
		part_id: 125,
		quantity: 333,
	},
	{
		garage_id: 171,
		part_id: 126,
		quantity: 4,
	},
	{
		garage_id: 171,
		part_id: 127,
		quantity: 203,
	},
	{
		garage_id: 171,
		part_id: 128,
		quantity: 441,
	},
	{
		garage_id: 171,
		part_id: 129,
		quantity: 379,
	},
	{
		garage_id: 171,
		part_id: 130,
		quantity: 327,
	},
	{
		garage_id: 171,
		part_id: 131,
		quantity: 473,
	},
	{
		garage_id: 171,
		part_id: 132,
		quantity: 54,
	},
	{
		garage_id: 171,
		part_id: 133,
		quantity: 494,
	},
	{
		garage_id: 171,
		part_id: 134,
		quantity: 302,
	},
	{
		garage_id: 171,
		part_id: 135,
		quantity: 11,
	},
	{
		garage_id: 171,
		part_id: 136,
		quantity: 271,
	},
	{
		garage_id: 171,
		part_id: 137,
		quantity: 483,
	},
	{
		garage_id: 171,
		part_id: 138,
		quantity: 178,
	},
	{
		garage_id: 171,
		part_id: 139,
		quantity: 266,
	},
	{
		garage_id: 171,
		part_id: 140,
		quantity: 10,
	},
	{
		garage_id: 171,
		part_id: 141,
		quantity: 69,
	},
	{
		garage_id: 171,
		part_id: 142,
		quantity: 404,
	},
	{
		garage_id: 171,
		part_id: 143,
		quantity: 420,
	},
	{
		garage_id: 171,
		part_id: 144,
		quantity: 201,
	},
	{
		garage_id: 171,
		part_id: 145,
		quantity: 3,
	},
	{
		garage_id: 171,
		part_id: 146,
		quantity: 116,
	},
	{
		garage_id: 171,
		part_id: 147,
		quantity: 90,
	},
	{
		garage_id: 171,
		part_id: 148,
		quantity: 142,
	},
	{
		garage_id: 171,
		part_id: 149,
		quantity: 498,
	},
	{
		garage_id: 171,
		part_id: 150,
		quantity: 407,
	},
	{
		garage_id: 171,
		part_id: 151,
		quantity: 483,
	},
	{
		garage_id: 171,
		part_id: 152,
		quantity: 359,
	},
	{
		garage_id: 171,
		part_id: 153,
		quantity: 456,
	},
	{
		garage_id: 171,
		part_id: 154,
		quantity: 134,
	},
	{
		garage_id: 171,
		part_id: 155,
		quantity: 474,
	},
	{
		garage_id: 171,
		part_id: 156,
		quantity: 244,
	},
	{
		garage_id: 171,
		part_id: 157,
		quantity: 5,
	},
	{
		garage_id: 171,
		part_id: 158,
		quantity: 186,
	},
	{
		garage_id: 171,
		part_id: 159,
		quantity: 88,
	},
	{
		garage_id: 171,
		part_id: 160,
		quantity: 372,
	},
	{
		garage_id: 171,
		part_id: 161,
		quantity: 323,
	},
	{
		garage_id: 171,
		part_id: 162,
		quantity: 52,
	},
	{
		garage_id: 171,
		part_id: 163,
		quantity: 291,
	},
	{
		garage_id: 171,
		part_id: 164,
		quantity: 142,
	},
	{
		garage_id: 171,
		part_id: 165,
		quantity: 249,
	},
	{
		garage_id: 171,
		part_id: 166,
		quantity: 213,
	},
	{
		garage_id: 171,
		part_id: 167,
		quantity: 298,
	},
	{
		garage_id: 171,
		part_id: 168,
		quantity: 278,
	},
	{
		garage_id: 171,
		part_id: 169,
		quantity: 121,
	},
	{
		garage_id: 171,
		part_id: 170,
		quantity: 121,
	},
	{
		garage_id: 171,
		part_id: 171,
		quantity: 83,
	},
	{
		garage_id: 171,
		part_id: 172,
		quantity: 500,
	},
	{
		garage_id: 171,
		part_id: 173,
		quantity: 470,
	},
	{
		garage_id: 171,
		part_id: 174,
		quantity: 257,
	},
	{
		garage_id: 171,
		part_id: 175,
		quantity: 380,
	},
	{
		garage_id: 171,
		part_id: 176,
		quantity: 65,
	},
	{
		garage_id: 171,
		part_id: 177,
		quantity: 55,
	},
	{
		garage_id: 171,
		part_id: 178,
		quantity: 46,
	},
	{
		garage_id: 171,
		part_id: 179,
		quantity: 461,
	},
	{
		garage_id: 171,
		part_id: 180,
		quantity: 255,
	},
	{
		garage_id: 171,
		part_id: 181,
		quantity: 184,
	},
	{
		garage_id: 171,
		part_id: 182,
		quantity: 249,
	},
	{
		garage_id: 171,
		part_id: 183,
		quantity: 148,
	},
	{
		garage_id: 171,
		part_id: 184,
		quantity: 407,
	},
	{
		garage_id: 171,
		part_id: 185,
		quantity: 305,
	},
	{
		garage_id: 171,
		part_id: 186,
		quantity: 313,
	},
	{
		garage_id: 171,
		part_id: 187,
		quantity: 20,
	},
	{
		garage_id: 171,
		part_id: 188,
		quantity: 104,
	},
	{
		garage_id: 171,
		part_id: 189,
		quantity: 218,
	},
	{
		garage_id: 171,
		part_id: 190,
		quantity: 26,
	},
	{
		garage_id: 171,
		part_id: 191,
		quantity: 130,
	},
	{
		garage_id: 171,
		part_id: 192,
		quantity: 359,
	},
	{
		garage_id: 171,
		part_id: 193,
		quantity: 73,
	},
	{
		garage_id: 171,
		part_id: 194,
		quantity: 198,
	},
	{
		garage_id: 171,
		part_id: 195,
		quantity: 433,
	},
	{
		garage_id: 171,
		part_id: 196,
		quantity: 109,
	},
	{
		garage_id: 171,
		part_id: 197,
		quantity: 383,
	},
	{
		garage_id: 171,
		part_id: 198,
		quantity: 77,
	},
	{
		garage_id: 171,
		part_id: 199,
		quantity: 150,
	},
	{
		garage_id: 171,
		part_id: 200,
		quantity: 55,
	},
	{
		garage_id: 171,
		part_id: 201,
		quantity: 443,
	},
	{
		garage_id: 171,
		part_id: 202,
		quantity: 98,
	},
	{
		garage_id: 171,
		part_id: 203,
		quantity: 350,
	},
	{
		garage_id: 171,
		part_id: 204,
		quantity: 252,
	},
	{
		garage_id: 171,
		part_id: 205,
		quantity: 253,
	},
	{
		garage_id: 171,
		part_id: 206,
		quantity: 482,
	},
	{
		garage_id: 171,
		part_id: 207,
		quantity: 157,
	},
	{
		garage_id: 171,
		part_id: 208,
		quantity: 303,
	},
	{
		garage_id: 171,
		part_id: 209,
		quantity: 225,
	},
	{
		garage_id: 171,
		part_id: 210,
		quantity: 120,
	},
	{
		garage_id: 171,
		part_id: 211,
		quantity: 370,
	},
	{
		garage_id: 171,
		part_id: 212,
		quantity: 310,
	},
	{
		garage_id: 171,
		part_id: 213,
		quantity: 451,
	},
	{
		garage_id: 171,
		part_id: 214,
		quantity: 194,
	},
	{
		garage_id: 171,
		part_id: 215,
		quantity: 352,
	},
	{
		garage_id: 171,
		part_id: 216,
		quantity: 413,
	},
	{
		garage_id: 171,
		part_id: 217,
		quantity: 228,
	},
	{
		garage_id: 171,
		part_id: 218,
		quantity: 286,
	},
	{
		garage_id: 171,
		part_id: 219,
		quantity: 37,
	},
	{
		garage_id: 171,
		part_id: 220,
		quantity: 35,
	},
	{
		garage_id: 171,
		part_id: 221,
		quantity: 178,
	},
	{
		garage_id: 171,
		part_id: 222,
		quantity: 472,
	},
	{
		garage_id: 171,
		part_id: 223,
		quantity: 309,
	},
	{
		garage_id: 171,
		part_id: 224,
		quantity: 268,
	},
	{
		garage_id: 171,
		part_id: 225,
		quantity: 423,
	},
	{
		garage_id: 171,
		part_id: 226,
		quantity: 416,
	},
	{
		garage_id: 171,
		part_id: 227,
		quantity: 347,
	},
	{
		garage_id: 171,
		part_id: 228,
		quantity: 50,
	},
	{
		garage_id: 171,
		part_id: 229,
		quantity: 305,
	},
	{
		garage_id: 171,
		part_id: 230,
		quantity: 356,
	},
	{
		garage_id: 171,
		part_id: 231,
		quantity: 62,
	},
	{
		garage_id: 171,
		part_id: 232,
		quantity: 413,
	},
	{
		garage_id: 171,
		part_id: 233,
		quantity: 398,
	},
	{
		garage_id: 171,
		part_id: 234,
		quantity: 300,
	},
	{
		garage_id: 171,
		part_id: 235,
		quantity: 399,
	},
	{
		garage_id: 171,
		part_id: 236,
		quantity: 343,
	},
	{
		garage_id: 171,
		part_id: 237,
		quantity: 61,
	},
	{
		garage_id: 171,
		part_id: 238,
		quantity: 183,
	},
	{
		garage_id: 171,
		part_id: 239,
		quantity: 174,
	},
	{
		garage_id: 171,
		part_id: 240,
		quantity: 475,
	},
	{
		garage_id: 171,
		part_id: 241,
		quantity: 499,
	},
	{
		garage_id: 171,
		part_id: 242,
		quantity: 113,
	},
	{
		garage_id: 171,
		part_id: 243,
		quantity: 171,
	},
	{
		garage_id: 171,
		part_id: 244,
		quantity: 121,
	},
	{
		garage_id: 171,
		part_id: 245,
		quantity: 121,
	},
	{
		garage_id: 171,
		part_id: 246,
		quantity: 307,
	},
	{
		garage_id: 171,
		part_id: 247,
		quantity: 374,
	},
	{
		garage_id: 171,
		part_id: 248,
		quantity: 418,
	},
	{
		garage_id: 171,
		part_id: 249,
		quantity: 137,
	},
	{
		garage_id: 171,
		part_id: 250,
		quantity: 159,
	},
	{
		garage_id: 171,
		part_id: 251,
		quantity: 151,
	},
	{
		garage_id: 171,
		part_id: 252,
		quantity: 338,
	},
	{
		garage_id: 171,
		part_id: 253,
		quantity: 143,
	},
	{
		garage_id: 171,
		part_id: 254,
		quantity: 152,
	},
	{
		garage_id: 171,
		part_id: 255,
		quantity: 302,
	},
	{
		garage_id: 171,
		part_id: 256,
		quantity: 214,
	},
	{
		garage_id: 171,
		part_id: 257,
		quantity: 84,
	},
	{
		garage_id: 171,
		part_id: 258,
		quantity: 424,
	},
	{
		garage_id: 171,
		part_id: 259,
		quantity: 275,
	},
	{
		garage_id: 171,
		part_id: 260,
		quantity: 10,
	},
	{
		garage_id: 171,
		part_id: 261,
		quantity: 138,
	},
	{
		garage_id: 171,
		part_id: 262,
		quantity: 241,
	},
	{
		garage_id: 171,
		part_id: 263,
		quantity: 43,
	},
	{
		garage_id: 171,
		part_id: 264,
		quantity: 247,
	},
	{
		garage_id: 171,
		part_id: 265,
		quantity: 484,
	},
	{
		garage_id: 171,
		part_id: 266,
		quantity: 29,
	},
	{
		garage_id: 171,
		part_id: 267,
		quantity: 123,
	},
	{
		garage_id: 171,
		part_id: 268,
		quantity: 492,
	},
	{
		garage_id: 171,
		part_id: 269,
		quantity: 147,
	},
	{
		garage_id: 171,
		part_id: 270,
		quantity: 127,
	},
	{
		garage_id: 171,
		part_id: 271,
		quantity: 305,
	},
	{
		garage_id: 171,
		part_id: 272,
		quantity: 24,
	},
	{
		garage_id: 171,
		part_id: 273,
		quantity: 118,
	},
	{
		garage_id: 171,
		part_id: 274,
		quantity: 227,
	},
	{
		garage_id: 171,
		part_id: 275,
		quantity: 373,
	},
	{
		garage_id: 171,
		part_id: 276,
		quantity: 454,
	},
	{
		garage_id: 171,
		part_id: 277,
		quantity: 261,
	},
	{
		garage_id: 171,
		part_id: 278,
		quantity: 180,
	},
	{
		garage_id: 171,
		part_id: 279,
		quantity: 479,
	},
	{
		garage_id: 171,
		part_id: 280,
		quantity: 427,
	},
	{
		garage_id: 171,
		part_id: 281,
		quantity: 124,
	},
	{
		garage_id: 171,
		part_id: 282,
		quantity: 277,
	},
	{
		garage_id: 171,
		part_id: 283,
		quantity: 88,
	},
	{
		garage_id: 171,
		part_id: 284,
		quantity: 293,
	},
	{
		garage_id: 171,
		part_id: 285,
		quantity: 255,
	},
	{
		garage_id: 171,
		part_id: 286,
		quantity: 376,
	},
	{
		garage_id: 171,
		part_id: 287,
		quantity: 338,
	},
	{
		garage_id: 171,
		part_id: 288,
		quantity: 256,
	},
	{
		garage_id: 171,
		part_id: 289,
		quantity: 393,
	},
	{
		garage_id: 171,
		part_id: 290,
		quantity: 85,
	},
	{
		garage_id: 171,
		part_id: 291,
		quantity: 377,
	},
	{
		garage_id: 171,
		part_id: 292,
		quantity: 66,
	},
	{
		garage_id: 171,
		part_id: 293,
		quantity: 334,
	},
	{
		garage_id: 171,
		part_id: 294,
		quantity: 297,
	},
	{
		garage_id: 171,
		part_id: 295,
		quantity: 446,
	},
	{
		garage_id: 171,
		part_id: 296,
		quantity: 61,
	},
	{
		garage_id: 171,
		part_id: 297,
		quantity: 387,
	},
	{
		garage_id: 171,
		part_id: 298,
		quantity: 492,
	},
	{
		garage_id: 171,
		part_id: 299,
		quantity: 278,
	},
	{
		garage_id: 171,
		part_id: 300,
		quantity: 312,
	},
	{
		garage_id: 171,
		part_id: 301,
		quantity: 427,
	},
	{
		garage_id: 171,
		part_id: 302,
		quantity: 47,
	},
	{
		garage_id: 171,
		part_id: 303,
		quantity: 117,
	},
	{
		garage_id: 171,
		part_id: 304,
		quantity: 363,
	},
	{
		garage_id: 171,
		part_id: 305,
		quantity: 359,
	},
	{
		garage_id: 171,
		part_id: 306,
		quantity: 167,
	},
	{
		garage_id: 171,
		part_id: 307,
		quantity: 314,
	},
	{
		garage_id: 171,
		part_id: 308,
		quantity: 154,
	},
	{
		garage_id: 171,
		part_id: 309,
		quantity: 115,
	},
	{
		garage_id: 171,
		part_id: 310,
		quantity: 390,
	},
	{
		garage_id: 171,
		part_id: 311,
		quantity: 242,
	},
	{
		garage_id: 171,
		part_id: 312,
		quantity: 352,
	},
	{
		garage_id: 171,
		part_id: 313,
		quantity: 138,
	},
	{
		garage_id: 171,
		part_id: 314,
		quantity: 207,
	},
	{
		garage_id: 171,
		part_id: 315,
		quantity: 145,
	},
	{
		garage_id: 171,
		part_id: 316,
		quantity: 383,
	},
	{
		garage_id: 171,
		part_id: 317,
		quantity: 420,
	},
	{
		garage_id: 171,
		part_id: 318,
		quantity: 442,
	},
	{
		garage_id: 171,
		part_id: 319,
		quantity: 491,
	},
	{
		garage_id: 171,
		part_id: 320,
		quantity: 112,
	},
	{
		garage_id: 171,
		part_id: 321,
		quantity: 211,
	},
	{
		garage_id: 171,
		part_id: 322,
		quantity: 430,
	},
	{
		garage_id: 171,
		part_id: 323,
		quantity: 445,
	},
	{
		garage_id: 171,
		part_id: 324,
		quantity: 349,
	},
	{
		garage_id: 171,
		part_id: 325,
		quantity: 366,
	},
	{
		garage_id: 171,
		part_id: 326,
		quantity: 500,
	},
	{
		garage_id: 171,
		part_id: 327,
		quantity: 139,
	},
	{
		garage_id: 171,
		part_id: 328,
		quantity: 436,
	},
	{
		garage_id: 171,
		part_id: 329,
		quantity: 271,
	},
	{
		garage_id: 171,
		part_id: 330,
		quantity: 347,
	},
	{
		garage_id: 171,
		part_id: 331,
		quantity: 26,
	},
	{
		garage_id: 171,
		part_id: 332,
		quantity: 440,
	},
	{
		garage_id: 171,
		part_id: 333,
		quantity: 183,
	},
	{
		garage_id: 171,
		part_id: 334,
		quantity: 239,
	},
	{
		garage_id: 171,
		part_id: 335,
		quantity: 311,
	},
	{
		garage_id: 171,
		part_id: 336,
		quantity: 99,
	},
	{
		garage_id: 171,
		part_id: 337,
		quantity: 475,
	},
	{
		garage_id: 171,
		part_id: 338,
		quantity: 177,
	},
	{
		garage_id: 171,
		part_id: 339,
		quantity: 297,
	},
	{
		garage_id: 171,
		part_id: 340,
		quantity: 296,
	},
	{
		garage_id: 171,
		part_id: 341,
		quantity: 6,
	},
	{
		garage_id: 171,
		part_id: 342,
		quantity: 449,
	},
	{
		garage_id: 171,
		part_id: 343,
		quantity: 185,
	},
	{
		garage_id: 171,
		part_id: 344,
		quantity: 483,
	},
	{
		garage_id: 171,
		part_id: 345,
		quantity: 42,
	},
	{
		garage_id: 171,
		part_id: 346,
		quantity: 384,
	},
	{
		garage_id: 171,
		part_id: 347,
		quantity: 395,
	},
	{
		garage_id: 171,
		part_id: 348,
		quantity: 410,
	},
	{
		garage_id: 171,
		part_id: 349,
		quantity: 48,
	},
	{
		garage_id: 171,
		part_id: 350,
		quantity: 238,
	},
	{
		garage_id: 171,
		part_id: 351,
		quantity: 379,
	},
	{
		garage_id: 171,
		part_id: 352,
		quantity: 48,
	},
	{
		garage_id: 171,
		part_id: 353,
		quantity: 334,
	},
	{
		garage_id: 171,
		part_id: 354,
		quantity: 101,
	},
	{
		garage_id: 171,
		part_id: 355,
		quantity: 270,
	},
	{
		garage_id: 171,
		part_id: 356,
		quantity: 40,
	},
	{
		garage_id: 171,
		part_id: 357,
		quantity: 218,
	},
	{
		garage_id: 171,
		part_id: 358,
		quantity: 159,
	},
	{
		garage_id: 171,
		part_id: 359,
		quantity: 278,
	},
	{
		garage_id: 171,
		part_id: 360,
		quantity: 131,
	},
	{
		garage_id: 171,
		part_id: 361,
		quantity: 417,
	},
	{
		garage_id: 171,
		part_id: 362,
		quantity: 433,
	},
	{
		garage_id: 171,
		part_id: 363,
		quantity: 475,
	},
	{
		garage_id: 171,
		part_id: 364,
		quantity: 84,
	},
	{
		garage_id: 171,
		part_id: 365,
		quantity: 432,
	},
	{
		garage_id: 171,
		part_id: 366,
		quantity: 440,
	},
	{
		garage_id: 171,
		part_id: 367,
		quantity: 155,
	},
	{
		garage_id: 171,
		part_id: 368,
		quantity: 260,
	},
	{
		garage_id: 171,
		part_id: 369,
		quantity: 114,
	},
	{
		garage_id: 171,
		part_id: 370,
		quantity: 478,
	},
	{
		garage_id: 171,
		part_id: 371,
		quantity: 270,
	},
	{
		garage_id: 171,
		part_id: 372,
		quantity: 266,
	},
	{
		garage_id: 171,
		part_id: 373,
		quantity: 217,
	},
	{
		garage_id: 171,
		part_id: 374,
		quantity: 355,
	},
	{
		garage_id: 171,
		part_id: 375,
		quantity: 495,
	},
	{
		garage_id: 171,
		part_id: 376,
		quantity: 183,
	},
	{
		garage_id: 171,
		part_id: 377,
		quantity: 294,
	},
	{
		garage_id: 171,
		part_id: 378,
		quantity: 232,
	},
	{
		garage_id: 171,
		part_id: 379,
		quantity: 288,
	},
	{
		garage_id: 171,
		part_id: 380,
		quantity: 191,
	},
	{
		garage_id: 171,
		part_id: 381,
		quantity: 108,
	},
	{
		garage_id: 171,
		part_id: 382,
		quantity: 19,
	},
	{
		garage_id: 171,
		part_id: 383,
		quantity: 100,
	},
	{
		garage_id: 171,
		part_id: 384,
		quantity: 106,
	},
	{
		garage_id: 171,
		part_id: 385,
		quantity: 332,
	},
	{
		garage_id: 171,
		part_id: 386,
		quantity: 105,
	},
	{
		garage_id: 171,
		part_id: 387,
		quantity: 429,
	},
	{
		garage_id: 171,
		part_id: 388,
		quantity: 402,
	},
	{
		garage_id: 171,
		part_id: 389,
		quantity: 246,
	},
	{
		garage_id: 171,
		part_id: 390,
		quantity: 431,
	},
	{
		garage_id: 171,
		part_id: 391,
		quantity: 488,
	},
	{
		garage_id: 171,
		part_id: 392,
		quantity: 484,
	},
	{
		garage_id: 171,
		part_id: 393,
		quantity: 43,
	},
	{
		garage_id: 171,
		part_id: 394,
		quantity: 148,
	},
	{
		garage_id: 171,
		part_id: 395,
		quantity: 497,
	},
	{
		garage_id: 171,
		part_id: 396,
		quantity: 227,
	},
	{
		garage_id: 171,
		part_id: 397,
		quantity: 411,
	},
	{
		garage_id: 171,
		part_id: 398,
		quantity: 331,
	},
	{
		garage_id: 171,
		part_id: 399,
		quantity: 405,
	},
	{
		garage_id: 172,
		part_id: 0,
		quantity: 32,
	},
	{
		garage_id: 172,
		part_id: 1,
		quantity: 360,
	},
	{
		garage_id: 172,
		part_id: 2,
		quantity: 163,
	},
	{
		garage_id: 172,
		part_id: 3,
		quantity: 459,
	},
	{
		garage_id: 172,
		part_id: 4,
		quantity: 158,
	},
	{
		garage_id: 172,
		part_id: 5,
		quantity: 304,
	},
	{
		garage_id: 172,
		part_id: 6,
		quantity: 341,
	},
	{
		garage_id: 172,
		part_id: 7,
		quantity: 450,
	},
	{
		garage_id: 172,
		part_id: 8,
		quantity: 216,
	},
	{
		garage_id: 172,
		part_id: 9,
		quantity: 76,
	},
	{
		garage_id: 172,
		part_id: 10,
		quantity: 32,
	},
	{
		garage_id: 172,
		part_id: 11,
		quantity: 102,
	},
	{
		garage_id: 172,
		part_id: 12,
		quantity: 268,
	},
	{
		garage_id: 172,
		part_id: 13,
		quantity: 482,
	},
	{
		garage_id: 172,
		part_id: 14,
		quantity: 319,
	},
	{
		garage_id: 172,
		part_id: 15,
		quantity: 36,
	},
	{
		garage_id: 172,
		part_id: 16,
		quantity: 204,
	},
	{
		garage_id: 172,
		part_id: 17,
		quantity: 349,
	},
	{
		garage_id: 172,
		part_id: 18,
		quantity: 316,
	},
	{
		garage_id: 172,
		part_id: 19,
		quantity: 243,
	},
	{
		garage_id: 172,
		part_id: 20,
		quantity: 470,
	},
	{
		garage_id: 172,
		part_id: 21,
		quantity: 469,
	},
	{
		garage_id: 172,
		part_id: 22,
		quantity: 133,
	},
	{
		garage_id: 172,
		part_id: 23,
		quantity: 202,
	},
	{
		garage_id: 172,
		part_id: 24,
		quantity: 36,
	},
	{
		garage_id: 172,
		part_id: 25,
		quantity: 141,
	},
	{
		garage_id: 172,
		part_id: 26,
		quantity: 156,
	},
	{
		garage_id: 172,
		part_id: 27,
		quantity: 258,
	},
	{
		garage_id: 172,
		part_id: 28,
		quantity: 167,
	},
	{
		garage_id: 172,
		part_id: 29,
		quantity: 326,
	},
	{
		garage_id: 172,
		part_id: 30,
		quantity: 119,
	},
	{
		garage_id: 172,
		part_id: 31,
		quantity: 223,
	},
	{
		garage_id: 172,
		part_id: 32,
		quantity: 428,
	},
	{
		garage_id: 172,
		part_id: 33,
		quantity: 252,
	},
	{
		garage_id: 172,
		part_id: 34,
		quantity: 294,
	},
	{
		garage_id: 172,
		part_id: 35,
		quantity: 71,
	},
	{
		garage_id: 172,
		part_id: 36,
		quantity: 257,
	},
	{
		garage_id: 172,
		part_id: 37,
		quantity: 297,
	},
	{
		garage_id: 172,
		part_id: 38,
		quantity: 425,
	},
	{
		garage_id: 172,
		part_id: 39,
		quantity: 496,
	},
	{
		garage_id: 172,
		part_id: 40,
		quantity: 24,
	},
	{
		garage_id: 172,
		part_id: 41,
		quantity: 260,
	},
	{
		garage_id: 172,
		part_id: 42,
		quantity: 385,
	},
	{
		garage_id: 172,
		part_id: 43,
		quantity: 462,
	},
	{
		garage_id: 172,
		part_id: 44,
		quantity: 497,
	},
	{
		garage_id: 172,
		part_id: 45,
		quantity: 298,
	},
	{
		garage_id: 172,
		part_id: 46,
		quantity: 58,
	},
	{
		garage_id: 172,
		part_id: 47,
		quantity: 36,
	},
	{
		garage_id: 172,
		part_id: 48,
		quantity: 377,
	},
	{
		garage_id: 172,
		part_id: 49,
		quantity: 37,
	},
	{
		garage_id: 172,
		part_id: 50,
		quantity: 148,
	},
	{
		garage_id: 172,
		part_id: 51,
		quantity: 255,
	},
	{
		garage_id: 172,
		part_id: 52,
		quantity: 31,
	},
	{
		garage_id: 172,
		part_id: 53,
		quantity: 449,
	},
	{
		garage_id: 172,
		part_id: 54,
		quantity: 319,
	},
	{
		garage_id: 172,
		part_id: 55,
		quantity: 116,
	},
	{
		garage_id: 172,
		part_id: 56,
		quantity: 161,
	},
	{
		garage_id: 172,
		part_id: 57,
		quantity: 144,
	},
	{
		garage_id: 172,
		part_id: 58,
		quantity: 161,
	},
	{
		garage_id: 172,
		part_id: 59,
		quantity: 132,
	},
	{
		garage_id: 172,
		part_id: 60,
		quantity: 35,
	},
	{
		garage_id: 172,
		part_id: 61,
		quantity: 264,
	},
	{
		garage_id: 172,
		part_id: 62,
		quantity: 271,
	},
	{
		garage_id: 172,
		part_id: 63,
		quantity: 194,
	},
	{
		garage_id: 172,
		part_id: 64,
		quantity: 418,
	},
	{
		garage_id: 172,
		part_id: 65,
		quantity: 97,
	},
	{
		garage_id: 172,
		part_id: 66,
		quantity: 100,
	},
	{
		garage_id: 172,
		part_id: 67,
		quantity: 106,
	},
	{
		garage_id: 172,
		part_id: 68,
		quantity: 399,
	},
	{
		garage_id: 172,
		part_id: 69,
		quantity: 414,
	},
	{
		garage_id: 172,
		part_id: 70,
		quantity: 308,
	},
	{
		garage_id: 172,
		part_id: 71,
		quantity: 160,
	},
	{
		garage_id: 172,
		part_id: 72,
		quantity: 327,
	},
	{
		garage_id: 172,
		part_id: 73,
		quantity: 491,
	},
	{
		garage_id: 172,
		part_id: 74,
		quantity: 253,
	},
	{
		garage_id: 172,
		part_id: 75,
		quantity: 340,
	},
	{
		garage_id: 172,
		part_id: 76,
		quantity: 493,
	},
	{
		garage_id: 172,
		part_id: 77,
		quantity: 405,
	},
	{
		garage_id: 172,
		part_id: 78,
		quantity: 430,
	},
	{
		garage_id: 172,
		part_id: 79,
		quantity: 157,
	},
	{
		garage_id: 172,
		part_id: 80,
		quantity: 231,
	},
	{
		garage_id: 172,
		part_id: 81,
		quantity: 86,
	},
	{
		garage_id: 172,
		part_id: 82,
		quantity: 456,
	},
	{
		garage_id: 172,
		part_id: 83,
		quantity: 451,
	},
	{
		garage_id: 172,
		part_id: 84,
		quantity: 444,
	},
	{
		garage_id: 172,
		part_id: 85,
		quantity: 33,
	},
	{
		garage_id: 172,
		part_id: 86,
		quantity: 158,
	},
	{
		garage_id: 172,
		part_id: 87,
		quantity: 411,
	},
	{
		garage_id: 172,
		part_id: 88,
		quantity: 242,
	},
	{
		garage_id: 172,
		part_id: 89,
		quantity: 343,
	},
	{
		garage_id: 172,
		part_id: 90,
		quantity: 472,
	},
	{
		garage_id: 172,
		part_id: 91,
		quantity: 469,
	},
	{
		garage_id: 172,
		part_id: 92,
		quantity: 447,
	},
	{
		garage_id: 172,
		part_id: 93,
		quantity: 235,
	},
	{
		garage_id: 172,
		part_id: 94,
		quantity: 327,
	},
	{
		garage_id: 172,
		part_id: 95,
		quantity: 26,
	},
	{
		garage_id: 172,
		part_id: 96,
		quantity: 225,
	},
	{
		garage_id: 172,
		part_id: 97,
		quantity: 366,
	},
	{
		garage_id: 172,
		part_id: 98,
		quantity: 381,
	},
	{
		garage_id: 172,
		part_id: 99,
		quantity: 418,
	},
	{
		garage_id: 172,
		part_id: 100,
		quantity: 203,
	},
	{
		garage_id: 172,
		part_id: 101,
		quantity: 3,
	},
	{
		garage_id: 172,
		part_id: 102,
		quantity: 397,
	},
	{
		garage_id: 172,
		part_id: 103,
		quantity: 385,
	},
	{
		garage_id: 172,
		part_id: 104,
		quantity: 272,
	},
	{
		garage_id: 172,
		part_id: 105,
		quantity: 275,
	},
	{
		garage_id: 172,
		part_id: 106,
		quantity: 42,
	},
	{
		garage_id: 172,
		part_id: 107,
		quantity: 375,
	},
	{
		garage_id: 172,
		part_id: 108,
		quantity: 203,
	},
	{
		garage_id: 172,
		part_id: 109,
		quantity: 51,
	},
	{
		garage_id: 172,
		part_id: 110,
		quantity: 387,
	},
	{
		garage_id: 172,
		part_id: 111,
		quantity: 213,
	},
	{
		garage_id: 172,
		part_id: 112,
		quantity: 261,
	},
	{
		garage_id: 172,
		part_id: 113,
		quantity: 286,
	},
	{
		garage_id: 172,
		part_id: 114,
		quantity: 41,
	},
	{
		garage_id: 172,
		part_id: 115,
		quantity: 451,
	},
	{
		garage_id: 172,
		part_id: 116,
		quantity: 135,
	},
	{
		garage_id: 172,
		part_id: 117,
		quantity: 271,
	},
	{
		garage_id: 172,
		part_id: 118,
		quantity: 466,
	},
	{
		garage_id: 172,
		part_id: 119,
		quantity: 438,
	},
	{
		garage_id: 172,
		part_id: 120,
		quantity: 105,
	},
	{
		garage_id: 172,
		part_id: 121,
		quantity: 38,
	},
	{
		garage_id: 172,
		part_id: 122,
		quantity: 194,
	},
	{
		garage_id: 172,
		part_id: 123,
		quantity: 347,
	},
	{
		garage_id: 172,
		part_id: 124,
		quantity: 192,
	},
	{
		garage_id: 172,
		part_id: 125,
		quantity: 147,
	},
	{
		garage_id: 172,
		part_id: 126,
		quantity: 44,
	},
	{
		garage_id: 172,
		part_id: 127,
		quantity: 498,
	},
	{
		garage_id: 172,
		part_id: 128,
		quantity: 118,
	},
	{
		garage_id: 172,
		part_id: 129,
		quantity: 244,
	},
	{
		garage_id: 172,
		part_id: 130,
		quantity: 395,
	},
	{
		garage_id: 172,
		part_id: 131,
		quantity: 119,
	},
	{
		garage_id: 172,
		part_id: 132,
		quantity: 9,
	},
	{
		garage_id: 172,
		part_id: 133,
		quantity: 389,
	},
	{
		garage_id: 172,
		part_id: 134,
		quantity: 405,
	},
	{
		garage_id: 172,
		part_id: 135,
		quantity: 177,
	},
	{
		garage_id: 172,
		part_id: 136,
		quantity: 65,
	},
	{
		garage_id: 172,
		part_id: 137,
		quantity: 330,
	},
	{
		garage_id: 172,
		part_id: 138,
		quantity: 459,
	},
	{
		garage_id: 172,
		part_id: 139,
		quantity: 87,
	},
	{
		garage_id: 172,
		part_id: 140,
		quantity: 103,
	},
	{
		garage_id: 172,
		part_id: 141,
		quantity: 311,
	},
	{
		garage_id: 172,
		part_id: 142,
		quantity: 119,
	},
	{
		garage_id: 172,
		part_id: 143,
		quantity: 169,
	},
	{
		garage_id: 172,
		part_id: 144,
		quantity: 78,
	},
	{
		garage_id: 172,
		part_id: 145,
		quantity: 368,
	},
	{
		garage_id: 172,
		part_id: 146,
		quantity: 2,
	},
	{
		garage_id: 172,
		part_id: 147,
		quantity: 230,
	},
	{
		garage_id: 172,
		part_id: 148,
		quantity: 414,
	},
	{
		garage_id: 172,
		part_id: 149,
		quantity: 90,
	},
	{
		garage_id: 172,
		part_id: 150,
		quantity: 466,
	},
	{
		garage_id: 172,
		part_id: 151,
		quantity: 454,
	},
	{
		garage_id: 172,
		part_id: 152,
		quantity: 197,
	},
	{
		garage_id: 172,
		part_id: 153,
		quantity: 94,
	},
	{
		garage_id: 172,
		part_id: 154,
		quantity: 113,
	},
	{
		garage_id: 172,
		part_id: 155,
		quantity: 138,
	},
	{
		garage_id: 172,
		part_id: 156,
		quantity: 466,
	},
	{
		garage_id: 172,
		part_id: 157,
		quantity: 52,
	},
	{
		garage_id: 172,
		part_id: 158,
		quantity: 60,
	},
	{
		garage_id: 172,
		part_id: 159,
		quantity: 68,
	},
	{
		garage_id: 172,
		part_id: 160,
		quantity: 106,
	},
	{
		garage_id: 172,
		part_id: 161,
		quantity: 295,
	},
	{
		garage_id: 172,
		part_id: 162,
		quantity: 330,
	},
	{
		garage_id: 172,
		part_id: 163,
		quantity: 462,
	},
	{
		garage_id: 172,
		part_id: 164,
		quantity: 394,
	},
	{
		garage_id: 172,
		part_id: 165,
		quantity: 159,
	},
	{
		garage_id: 172,
		part_id: 166,
		quantity: 294,
	},
	{
		garage_id: 172,
		part_id: 167,
		quantity: 299,
	},
	{
		garage_id: 172,
		part_id: 168,
		quantity: 187,
	},
	{
		garage_id: 172,
		part_id: 169,
		quantity: 99,
	},
	{
		garage_id: 172,
		part_id: 170,
		quantity: 296,
	},
	{
		garage_id: 172,
		part_id: 171,
		quantity: 490,
	},
	{
		garage_id: 172,
		part_id: 172,
		quantity: 90,
	},
	{
		garage_id: 172,
		part_id: 173,
		quantity: 168,
	},
	{
		garage_id: 172,
		part_id: 174,
		quantity: 54,
	},
	{
		garage_id: 172,
		part_id: 175,
		quantity: 499,
	},
	{
		garage_id: 172,
		part_id: 176,
		quantity: 379,
	},
	{
		garage_id: 172,
		part_id: 177,
		quantity: 35,
	},
	{
		garage_id: 172,
		part_id: 178,
		quantity: 206,
	},
	{
		garage_id: 172,
		part_id: 179,
		quantity: 111,
	},
	{
		garage_id: 172,
		part_id: 180,
		quantity: 271,
	},
	{
		garage_id: 172,
		part_id: 181,
		quantity: 283,
	},
	{
		garage_id: 172,
		part_id: 182,
		quantity: 265,
	},
	{
		garage_id: 172,
		part_id: 183,
		quantity: 15,
	},
	{
		garage_id: 172,
		part_id: 184,
		quantity: 178,
	},
	{
		garage_id: 172,
		part_id: 185,
		quantity: 413,
	},
	{
		garage_id: 172,
		part_id: 186,
		quantity: 286,
	},
	{
		garage_id: 172,
		part_id: 187,
		quantity: 166,
	},
	{
		garage_id: 172,
		part_id: 188,
		quantity: 404,
	},
	{
		garage_id: 172,
		part_id: 189,
		quantity: 287,
	},
	{
		garage_id: 172,
		part_id: 190,
		quantity: 427,
	},
	{
		garage_id: 172,
		part_id: 191,
		quantity: 165,
	},
	{
		garage_id: 172,
		part_id: 192,
		quantity: 32,
	},
	{
		garage_id: 172,
		part_id: 193,
		quantity: 310,
	},
	{
		garage_id: 172,
		part_id: 194,
		quantity: 470,
	},
	{
		garage_id: 172,
		part_id: 195,
		quantity: 75,
	},
	{
		garage_id: 172,
		part_id: 196,
		quantity: 7,
	},
	{
		garage_id: 172,
		part_id: 197,
		quantity: 355,
	},
	{
		garage_id: 172,
		part_id: 198,
		quantity: 111,
	},
	{
		garage_id: 172,
		part_id: 199,
		quantity: 447,
	},
	{
		garage_id: 172,
		part_id: 200,
		quantity: 374,
	},
	{
		garage_id: 172,
		part_id: 201,
		quantity: 486,
	},
	{
		garage_id: 172,
		part_id: 202,
		quantity: 73,
	},
	{
		garage_id: 172,
		part_id: 203,
		quantity: 471,
	},
	{
		garage_id: 172,
		part_id: 204,
		quantity: 225,
	},
	{
		garage_id: 172,
		part_id: 205,
		quantity: 424,
	},
	{
		garage_id: 172,
		part_id: 206,
		quantity: 257,
	},
	{
		garage_id: 172,
		part_id: 207,
		quantity: 409,
	},
	{
		garage_id: 172,
		part_id: 208,
		quantity: 366,
	},
	{
		garage_id: 172,
		part_id: 209,
		quantity: 224,
	},
	{
		garage_id: 172,
		part_id: 210,
		quantity: 74,
	},
	{
		garage_id: 172,
		part_id: 211,
		quantity: 427,
	},
	{
		garage_id: 172,
		part_id: 212,
		quantity: 377,
	},
	{
		garage_id: 172,
		part_id: 213,
		quantity: 115,
	},
	{
		garage_id: 172,
		part_id: 214,
		quantity: 119,
	},
	{
		garage_id: 172,
		part_id: 215,
		quantity: 281,
	},
	{
		garage_id: 172,
		part_id: 216,
		quantity: 145,
	},
	{
		garage_id: 172,
		part_id: 217,
		quantity: 45,
	},
	{
		garage_id: 172,
		part_id: 218,
		quantity: 410,
	},
	{
		garage_id: 172,
		part_id: 219,
		quantity: 237,
	},
	{
		garage_id: 172,
		part_id: 220,
		quantity: 105,
	},
	{
		garage_id: 172,
		part_id: 221,
		quantity: 496,
	},
	{
		garage_id: 172,
		part_id: 222,
		quantity: 166,
	},
	{
		garage_id: 172,
		part_id: 223,
		quantity: 134,
	},
	{
		garage_id: 172,
		part_id: 224,
		quantity: 140,
	},
	{
		garage_id: 172,
		part_id: 225,
		quantity: 186,
	},
	{
		garage_id: 172,
		part_id: 226,
		quantity: 33,
	},
	{
		garage_id: 172,
		part_id: 227,
		quantity: 239,
	},
	{
		garage_id: 172,
		part_id: 228,
		quantity: 92,
	},
	{
		garage_id: 172,
		part_id: 229,
		quantity: 90,
	},
	{
		garage_id: 172,
		part_id: 230,
		quantity: 21,
	},
	{
		garage_id: 172,
		part_id: 231,
		quantity: 373,
	},
	{
		garage_id: 172,
		part_id: 232,
		quantity: 62,
	},
	{
		garage_id: 172,
		part_id: 233,
		quantity: 351,
	},
	{
		garage_id: 172,
		part_id: 234,
		quantity: 472,
	},
	{
		garage_id: 172,
		part_id: 235,
		quantity: 41,
	},
	{
		garage_id: 172,
		part_id: 236,
		quantity: 189,
	},
	{
		garage_id: 172,
		part_id: 237,
		quantity: 185,
	},
	{
		garage_id: 172,
		part_id: 238,
		quantity: 195,
	},
	{
		garage_id: 172,
		part_id: 239,
		quantity: 456,
	},
	{
		garage_id: 172,
		part_id: 240,
		quantity: 56,
	},
	{
		garage_id: 172,
		part_id: 241,
		quantity: 293,
	},
	{
		garage_id: 172,
		part_id: 242,
		quantity: 465,
	},
	{
		garage_id: 172,
		part_id: 243,
		quantity: 148,
	},
	{
		garage_id: 172,
		part_id: 244,
		quantity: 173,
	},
	{
		garage_id: 172,
		part_id: 245,
		quantity: 194,
	},
	{
		garage_id: 172,
		part_id: 246,
		quantity: 245,
	},
	{
		garage_id: 172,
		part_id: 247,
		quantity: 388,
	},
	{
		garage_id: 172,
		part_id: 248,
		quantity: 434,
	},
	{
		garage_id: 172,
		part_id: 249,
		quantity: 267,
	},
	{
		garage_id: 172,
		part_id: 250,
		quantity: 85,
	},
	{
		garage_id: 172,
		part_id: 251,
		quantity: 460,
	},
	{
		garage_id: 172,
		part_id: 252,
		quantity: 460,
	},
	{
		garage_id: 172,
		part_id: 253,
		quantity: 365,
	},
	{
		garage_id: 172,
		part_id: 254,
		quantity: 249,
	},
	{
		garage_id: 172,
		part_id: 255,
		quantity: 340,
	},
	{
		garage_id: 172,
		part_id: 256,
		quantity: 170,
	},
	{
		garage_id: 172,
		part_id: 257,
		quantity: 290,
	},
	{
		garage_id: 172,
		part_id: 258,
		quantity: 365,
	},
	{
		garage_id: 172,
		part_id: 259,
		quantity: 412,
	},
	{
		garage_id: 172,
		part_id: 260,
		quantity: 386,
	},
	{
		garage_id: 172,
		part_id: 261,
		quantity: 499,
	},
	{
		garage_id: 172,
		part_id: 262,
		quantity: 260,
	},
	{
		garage_id: 172,
		part_id: 263,
		quantity: 204,
	},
	{
		garage_id: 172,
		part_id: 264,
		quantity: 11,
	},
	{
		garage_id: 172,
		part_id: 265,
		quantity: 324,
	},
	{
		garage_id: 172,
		part_id: 266,
		quantity: 203,
	},
	{
		garage_id: 172,
		part_id: 267,
		quantity: 259,
	},
	{
		garage_id: 172,
		part_id: 268,
		quantity: 378,
	},
	{
		garage_id: 172,
		part_id: 269,
		quantity: 464,
	},
	{
		garage_id: 172,
		part_id: 270,
		quantity: 290,
	},
	{
		garage_id: 172,
		part_id: 271,
		quantity: 343,
	},
	{
		garage_id: 172,
		part_id: 272,
		quantity: 104,
	},
	{
		garage_id: 172,
		part_id: 273,
		quantity: 447,
	},
	{
		garage_id: 172,
		part_id: 274,
		quantity: 14,
	},
	{
		garage_id: 172,
		part_id: 275,
		quantity: 486,
	},
	{
		garage_id: 172,
		part_id: 276,
		quantity: 28,
	},
	{
		garage_id: 172,
		part_id: 277,
		quantity: 370,
	},
	{
		garage_id: 172,
		part_id: 278,
		quantity: 252,
	},
	{
		garage_id: 172,
		part_id: 279,
		quantity: 327,
	},
	{
		garage_id: 172,
		part_id: 280,
		quantity: 99,
	},
	{
		garage_id: 172,
		part_id: 281,
		quantity: 279,
	},
	{
		garage_id: 172,
		part_id: 282,
		quantity: 243,
	},
	{
		garage_id: 172,
		part_id: 283,
		quantity: 417,
	},
	{
		garage_id: 172,
		part_id: 284,
		quantity: 462,
	},
	{
		garage_id: 172,
		part_id: 285,
		quantity: 71,
	},
	{
		garage_id: 172,
		part_id: 286,
		quantity: 77,
	},
	{
		garage_id: 172,
		part_id: 287,
		quantity: 451,
	},
	{
		garage_id: 172,
		part_id: 288,
		quantity: 452,
	},
	{
		garage_id: 172,
		part_id: 289,
		quantity: 372,
	},
	{
		garage_id: 172,
		part_id: 290,
		quantity: 120,
	},
	{
		garage_id: 172,
		part_id: 291,
		quantity: 255,
	},
	{
		garage_id: 172,
		part_id: 292,
		quantity: 132,
	},
	{
		garage_id: 172,
		part_id: 293,
		quantity: 420,
	},
	{
		garage_id: 172,
		part_id: 294,
		quantity: 378,
	},
	{
		garage_id: 172,
		part_id: 295,
		quantity: 432,
	},
	{
		garage_id: 172,
		part_id: 296,
		quantity: 29,
	},
	{
		garage_id: 172,
		part_id: 297,
		quantity: 91,
	},
	{
		garage_id: 172,
		part_id: 298,
		quantity: 274,
	},
	{
		garage_id: 172,
		part_id: 299,
		quantity: 6,
	},
	{
		garage_id: 172,
		part_id: 300,
		quantity: 146,
	},
	{
		garage_id: 172,
		part_id: 301,
		quantity: 264,
	},
	{
		garage_id: 172,
		part_id: 302,
		quantity: 66,
	},
	{
		garage_id: 172,
		part_id: 303,
		quantity: 148,
	},
	{
		garage_id: 172,
		part_id: 304,
		quantity: 77,
	},
	{
		garage_id: 172,
		part_id: 305,
		quantity: 1,
	},
	{
		garage_id: 172,
		part_id: 306,
		quantity: 451,
	},
	{
		garage_id: 172,
		part_id: 307,
		quantity: 22,
	},
	{
		garage_id: 172,
		part_id: 308,
		quantity: 58,
	},
	{
		garage_id: 172,
		part_id: 309,
		quantity: 359,
	},
	{
		garage_id: 172,
		part_id: 310,
		quantity: 180,
	},
	{
		garage_id: 172,
		part_id: 311,
		quantity: 221,
	},
	{
		garage_id: 172,
		part_id: 312,
		quantity: 246,
	},
	{
		garage_id: 172,
		part_id: 313,
		quantity: 188,
	},
	{
		garage_id: 172,
		part_id: 314,
		quantity: 353,
	},
	{
		garage_id: 172,
		part_id: 315,
		quantity: 115,
	},
	{
		garage_id: 172,
		part_id: 316,
		quantity: 318,
	},
	{
		garage_id: 172,
		part_id: 317,
		quantity: 171,
	},
	{
		garage_id: 172,
		part_id: 318,
		quantity: 225,
	},
	{
		garage_id: 172,
		part_id: 319,
		quantity: 486,
	},
	{
		garage_id: 172,
		part_id: 320,
		quantity: 161,
	},
	{
		garage_id: 172,
		part_id: 321,
		quantity: 47,
	},
	{
		garage_id: 172,
		part_id: 322,
		quantity: 173,
	},
	{
		garage_id: 172,
		part_id: 323,
		quantity: 91,
	},
	{
		garage_id: 172,
		part_id: 324,
		quantity: 351,
	},
	{
		garage_id: 172,
		part_id: 325,
		quantity: 336,
	},
	{
		garage_id: 172,
		part_id: 326,
		quantity: 446,
	},
	{
		garage_id: 172,
		part_id: 327,
		quantity: 35,
	},
	{
		garage_id: 172,
		part_id: 328,
		quantity: 303,
	},
	{
		garage_id: 172,
		part_id: 329,
		quantity: 186,
	},
	{
		garage_id: 172,
		part_id: 330,
		quantity: 292,
	},
	{
		garage_id: 172,
		part_id: 331,
		quantity: 175,
	},
	{
		garage_id: 172,
		part_id: 332,
		quantity: 481,
	},
	{
		garage_id: 172,
		part_id: 333,
		quantity: 416,
	},
	{
		garage_id: 172,
		part_id: 334,
		quantity: 140,
	},
	{
		garage_id: 172,
		part_id: 335,
		quantity: 325,
	},
	{
		garage_id: 172,
		part_id: 336,
		quantity: 384,
	},
	{
		garage_id: 172,
		part_id: 337,
		quantity: 465,
	},
	{
		garage_id: 172,
		part_id: 338,
		quantity: 168,
	},
	{
		garage_id: 172,
		part_id: 339,
		quantity: 142,
	},
	{
		garage_id: 172,
		part_id: 340,
		quantity: 234,
	},
	{
		garage_id: 172,
		part_id: 341,
		quantity: 0,
	},
	{
		garage_id: 172,
		part_id: 342,
		quantity: 283,
	},
	{
		garage_id: 172,
		part_id: 343,
		quantity: 411,
	},
	{
		garage_id: 172,
		part_id: 344,
		quantity: 35,
	},
	{
		garage_id: 172,
		part_id: 345,
		quantity: 219,
	},
	{
		garage_id: 172,
		part_id: 346,
		quantity: 427,
	},
	{
		garage_id: 172,
		part_id: 347,
		quantity: 387,
	},
	{
		garage_id: 172,
		part_id: 348,
		quantity: 394,
	},
	{
		garage_id: 172,
		part_id: 349,
		quantity: 417,
	},
	{
		garage_id: 172,
		part_id: 350,
		quantity: 493,
	},
	{
		garage_id: 172,
		part_id: 351,
		quantity: 489,
	},
	{
		garage_id: 172,
		part_id: 352,
		quantity: 17,
	},
	{
		garage_id: 172,
		part_id: 353,
		quantity: 481,
	},
	{
		garage_id: 172,
		part_id: 354,
		quantity: 196,
	},
	{
		garage_id: 172,
		part_id: 355,
		quantity: 68,
	},
	{
		garage_id: 172,
		part_id: 356,
		quantity: 413,
	},
	{
		garage_id: 172,
		part_id: 357,
		quantity: 279,
	},
	{
		garage_id: 172,
		part_id: 358,
		quantity: 242,
	},
	{
		garage_id: 172,
		part_id: 359,
		quantity: 259,
	},
	{
		garage_id: 172,
		part_id: 360,
		quantity: 267,
	},
	{
		garage_id: 172,
		part_id: 361,
		quantity: 198,
	},
	{
		garage_id: 172,
		part_id: 362,
		quantity: 191,
	},
	{
		garage_id: 172,
		part_id: 363,
		quantity: 473,
	},
	{
		garage_id: 172,
		part_id: 364,
		quantity: 173,
	},
	{
		garage_id: 172,
		part_id: 365,
		quantity: 203,
	},
	{
		garage_id: 172,
		part_id: 366,
		quantity: 374,
	},
	{
		garage_id: 172,
		part_id: 367,
		quantity: 227,
	},
	{
		garage_id: 172,
		part_id: 368,
		quantity: 423,
	},
	{
		garage_id: 172,
		part_id: 369,
		quantity: 40,
	},
	{
		garage_id: 172,
		part_id: 370,
		quantity: 229,
	},
	{
		garage_id: 172,
		part_id: 371,
		quantity: 103,
	},
	{
		garage_id: 172,
		part_id: 372,
		quantity: 361,
	},
	{
		garage_id: 172,
		part_id: 373,
		quantity: 159,
	},
	{
		garage_id: 172,
		part_id: 374,
		quantity: 344,
	},
	{
		garage_id: 172,
		part_id: 375,
		quantity: 125,
	},
	{
		garage_id: 172,
		part_id: 376,
		quantity: 37,
	},
	{
		garage_id: 172,
		part_id: 377,
		quantity: 497,
	},
	{
		garage_id: 172,
		part_id: 378,
		quantity: 88,
	},
	{
		garage_id: 172,
		part_id: 379,
		quantity: 404,
	},
	{
		garage_id: 172,
		part_id: 380,
		quantity: 83,
	},
	{
		garage_id: 172,
		part_id: 381,
		quantity: 24,
	},
	{
		garage_id: 172,
		part_id: 382,
		quantity: 23,
	},
	{
		garage_id: 172,
		part_id: 383,
		quantity: 244,
	},
	{
		garage_id: 172,
		part_id: 384,
		quantity: 104,
	},
	{
		garage_id: 172,
		part_id: 385,
		quantity: 298,
	},
	{
		garage_id: 172,
		part_id: 386,
		quantity: 126,
	},
	{
		garage_id: 172,
		part_id: 387,
		quantity: 244,
	},
	{
		garage_id: 172,
		part_id: 388,
		quantity: 363,
	},
	{
		garage_id: 172,
		part_id: 389,
		quantity: 386,
	},
	{
		garage_id: 172,
		part_id: 390,
		quantity: 369,
	},
	{
		garage_id: 172,
		part_id: 391,
		quantity: 259,
	},
	{
		garage_id: 172,
		part_id: 392,
		quantity: 381,
	},
	{
		garage_id: 172,
		part_id: 393,
		quantity: 370,
	},
	{
		garage_id: 172,
		part_id: 394,
		quantity: 332,
	},
	{
		garage_id: 172,
		part_id: 395,
		quantity: 35,
	},
	{
		garage_id: 172,
		part_id: 396,
		quantity: 173,
	},
	{
		garage_id: 172,
		part_id: 397,
		quantity: 18,
	},
	{
		garage_id: 172,
		part_id: 398,
		quantity: 385,
	},
	{
		garage_id: 172,
		part_id: 399,
		quantity: 202,
	},
	{
		garage_id: 173,
		part_id: 0,
		quantity: 434,
	},
	{
		garage_id: 173,
		part_id: 1,
		quantity: 251,
	},
	{
		garage_id: 173,
		part_id: 2,
		quantity: 493,
	},
	{
		garage_id: 173,
		part_id: 3,
		quantity: 367,
	},
	{
		garage_id: 173,
		part_id: 4,
		quantity: 216,
	},
	{
		garage_id: 173,
		part_id: 5,
		quantity: 237,
	},
	{
		garage_id: 173,
		part_id: 6,
		quantity: 474,
	},
	{
		garage_id: 173,
		part_id: 7,
		quantity: 276,
	},
	{
		garage_id: 173,
		part_id: 8,
		quantity: 390,
	},
	{
		garage_id: 173,
		part_id: 9,
		quantity: 177,
	},
	{
		garage_id: 173,
		part_id: 10,
		quantity: 228,
	},
	{
		garage_id: 173,
		part_id: 11,
		quantity: 443,
	},
	{
		garage_id: 173,
		part_id: 12,
		quantity: 47,
	},
	{
		garage_id: 173,
		part_id: 13,
		quantity: 270,
	},
	{
		garage_id: 173,
		part_id: 14,
		quantity: 151,
	},
	{
		garage_id: 173,
		part_id: 15,
		quantity: 423,
	},
	{
		garage_id: 173,
		part_id: 16,
		quantity: 132,
	},
	{
		garage_id: 173,
		part_id: 17,
		quantity: 423,
	},
	{
		garage_id: 173,
		part_id: 18,
		quantity: 19,
	},
	{
		garage_id: 173,
		part_id: 19,
		quantity: 304,
	},
	{
		garage_id: 173,
		part_id: 20,
		quantity: 385,
	},
	{
		garage_id: 173,
		part_id: 21,
		quantity: 88,
	},
	{
		garage_id: 173,
		part_id: 22,
		quantity: 205,
	},
	{
		garage_id: 173,
		part_id: 23,
		quantity: 189,
	},
	{
		garage_id: 173,
		part_id: 24,
		quantity: 197,
	},
	{
		garage_id: 173,
		part_id: 25,
		quantity: 464,
	},
	{
		garage_id: 173,
		part_id: 26,
		quantity: 142,
	},
	{
		garage_id: 173,
		part_id: 27,
		quantity: 145,
	},
	{
		garage_id: 173,
		part_id: 28,
		quantity: 105,
	},
	{
		garage_id: 173,
		part_id: 29,
		quantity: 107,
	},
	{
		garage_id: 173,
		part_id: 30,
		quantity: 23,
	},
	{
		garage_id: 173,
		part_id: 31,
		quantity: 227,
	},
	{
		garage_id: 173,
		part_id: 32,
		quantity: 374,
	},
	{
		garage_id: 173,
		part_id: 33,
		quantity: 80,
	},
	{
		garage_id: 173,
		part_id: 34,
		quantity: 194,
	},
	{
		garage_id: 173,
		part_id: 35,
		quantity: 306,
	},
	{
		garage_id: 173,
		part_id: 36,
		quantity: 193,
	},
	{
		garage_id: 173,
		part_id: 37,
		quantity: 470,
	},
	{
		garage_id: 173,
		part_id: 38,
		quantity: 365,
	},
	{
		garage_id: 173,
		part_id: 39,
		quantity: 114,
	},
	{
		garage_id: 173,
		part_id: 40,
		quantity: 373,
	},
	{
		garage_id: 173,
		part_id: 41,
		quantity: 10,
	},
	{
		garage_id: 173,
		part_id: 42,
		quantity: 232,
	},
	{
		garage_id: 173,
		part_id: 43,
		quantity: 333,
	},
	{
		garage_id: 173,
		part_id: 44,
		quantity: 425,
	},
	{
		garage_id: 173,
		part_id: 45,
		quantity: 294,
	},
	{
		garage_id: 173,
		part_id: 46,
		quantity: 132,
	},
	{
		garage_id: 173,
		part_id: 47,
		quantity: 205,
	},
	{
		garage_id: 173,
		part_id: 48,
		quantity: 17,
	},
	{
		garage_id: 173,
		part_id: 49,
		quantity: 191,
	},
	{
		garage_id: 173,
		part_id: 50,
		quantity: 86,
	},
	{
		garage_id: 173,
		part_id: 51,
		quantity: 56,
	},
	{
		garage_id: 173,
		part_id: 52,
		quantity: 482,
	},
	{
		garage_id: 173,
		part_id: 53,
		quantity: 27,
	},
	{
		garage_id: 173,
		part_id: 54,
		quantity: 68,
	},
	{
		garage_id: 173,
		part_id: 55,
		quantity: 55,
	},
	{
		garage_id: 173,
		part_id: 56,
		quantity: 235,
	},
	{
		garage_id: 173,
		part_id: 57,
		quantity: 421,
	},
	{
		garage_id: 173,
		part_id: 58,
		quantity: 354,
	},
	{
		garage_id: 173,
		part_id: 59,
		quantity: 470,
	},
	{
		garage_id: 173,
		part_id: 60,
		quantity: 260,
	},
	{
		garage_id: 173,
		part_id: 61,
		quantity: 154,
	},
	{
		garage_id: 173,
		part_id: 62,
		quantity: 432,
	},
	{
		garage_id: 173,
		part_id: 63,
		quantity: 237,
	},
	{
		garage_id: 173,
		part_id: 64,
		quantity: 312,
	},
	{
		garage_id: 173,
		part_id: 65,
		quantity: 27,
	},
	{
		garage_id: 173,
		part_id: 66,
		quantity: 388,
	},
	{
		garage_id: 173,
		part_id: 67,
		quantity: 19,
	},
	{
		garage_id: 173,
		part_id: 68,
		quantity: 337,
	},
	{
		garage_id: 173,
		part_id: 69,
		quantity: 262,
	},
	{
		garage_id: 173,
		part_id: 70,
		quantity: 283,
	},
	{
		garage_id: 173,
		part_id: 71,
		quantity: 482,
	},
	{
		garage_id: 173,
		part_id: 72,
		quantity: 314,
	},
	{
		garage_id: 173,
		part_id: 73,
		quantity: 399,
	},
	{
		garage_id: 173,
		part_id: 74,
		quantity: 485,
	},
	{
		garage_id: 173,
		part_id: 75,
		quantity: 477,
	},
	{
		garage_id: 173,
		part_id: 76,
		quantity: 332,
	},
	{
		garage_id: 173,
		part_id: 77,
		quantity: 397,
	},
	{
		garage_id: 173,
		part_id: 78,
		quantity: 351,
	},
	{
		garage_id: 173,
		part_id: 79,
		quantity: 486,
	},
	{
		garage_id: 173,
		part_id: 80,
		quantity: 265,
	},
	{
		garage_id: 173,
		part_id: 81,
		quantity: 252,
	},
	{
		garage_id: 173,
		part_id: 82,
		quantity: 210,
	},
	{
		garage_id: 173,
		part_id: 83,
		quantity: 226,
	},
	{
		garage_id: 173,
		part_id: 84,
		quantity: 120,
	},
	{
		garage_id: 173,
		part_id: 85,
		quantity: 384,
	},
	{
		garage_id: 173,
		part_id: 86,
		quantity: 310,
	},
	{
		garage_id: 173,
		part_id: 87,
		quantity: 355,
	},
	{
		garage_id: 173,
		part_id: 88,
		quantity: 256,
	},
	{
		garage_id: 173,
		part_id: 89,
		quantity: 395,
	},
	{
		garage_id: 173,
		part_id: 90,
		quantity: 63,
	},
	{
		garage_id: 173,
		part_id: 91,
		quantity: 412,
	},
	{
		garage_id: 173,
		part_id: 92,
		quantity: 242,
	},
	{
		garage_id: 173,
		part_id: 93,
		quantity: 399,
	},
	{
		garage_id: 173,
		part_id: 94,
		quantity: 247,
	},
	{
		garage_id: 173,
		part_id: 95,
		quantity: 331,
	},
	{
		garage_id: 173,
		part_id: 96,
		quantity: 279,
	},
	{
		garage_id: 173,
		part_id: 97,
		quantity: 371,
	},
	{
		garage_id: 173,
		part_id: 98,
		quantity: 490,
	},
	{
		garage_id: 173,
		part_id: 99,
		quantity: 50,
	},
	{
		garage_id: 173,
		part_id: 100,
		quantity: 149,
	},
	{
		garage_id: 173,
		part_id: 101,
		quantity: 305,
	},
	{
		garage_id: 173,
		part_id: 102,
		quantity: 403,
	},
	{
		garage_id: 173,
		part_id: 103,
		quantity: 195,
	},
	{
		garage_id: 173,
		part_id: 104,
		quantity: 499,
	},
	{
		garage_id: 173,
		part_id: 105,
		quantity: 216,
	},
	{
		garage_id: 173,
		part_id: 106,
		quantity: 195,
	},
	{
		garage_id: 173,
		part_id: 107,
		quantity: 410,
	},
	{
		garage_id: 173,
		part_id: 108,
		quantity: 52,
	},
	{
		garage_id: 173,
		part_id: 109,
		quantity: 213,
	},
	{
		garage_id: 173,
		part_id: 110,
		quantity: 296,
	},
	{
		garage_id: 173,
		part_id: 111,
		quantity: 420,
	},
	{
		garage_id: 173,
		part_id: 112,
		quantity: 419,
	},
	{
		garage_id: 173,
		part_id: 113,
		quantity: 210,
	},
	{
		garage_id: 173,
		part_id: 114,
		quantity: 59,
	},
	{
		garage_id: 173,
		part_id: 115,
		quantity: 277,
	},
	{
		garage_id: 173,
		part_id: 116,
		quantity: 242,
	},
	{
		garage_id: 173,
		part_id: 117,
		quantity: 180,
	},
	{
		garage_id: 173,
		part_id: 118,
		quantity: 311,
	},
	{
		garage_id: 173,
		part_id: 119,
		quantity: 343,
	},
	{
		garage_id: 173,
		part_id: 120,
		quantity: 435,
	},
	{
		garage_id: 173,
		part_id: 121,
		quantity: 383,
	},
	{
		garage_id: 173,
		part_id: 122,
		quantity: 154,
	},
	{
		garage_id: 173,
		part_id: 123,
		quantity: 299,
	},
	{
		garage_id: 173,
		part_id: 124,
		quantity: 412,
	},
	{
		garage_id: 173,
		part_id: 125,
		quantity: 150,
	},
	{
		garage_id: 173,
		part_id: 126,
		quantity: 496,
	},
	{
		garage_id: 173,
		part_id: 127,
		quantity: 106,
	},
	{
		garage_id: 173,
		part_id: 128,
		quantity: 188,
	},
	{
		garage_id: 173,
		part_id: 129,
		quantity: 419,
	},
	{
		garage_id: 173,
		part_id: 130,
		quantity: 439,
	},
	{
		garage_id: 173,
		part_id: 131,
		quantity: 343,
	},
	{
		garage_id: 173,
		part_id: 132,
		quantity: 334,
	},
	{
		garage_id: 173,
		part_id: 133,
		quantity: 108,
	},
	{
		garage_id: 173,
		part_id: 134,
		quantity: 398,
	},
	{
		garage_id: 173,
		part_id: 135,
		quantity: 126,
	},
	{
		garage_id: 173,
		part_id: 136,
		quantity: 331,
	},
	{
		garage_id: 173,
		part_id: 137,
		quantity: 344,
	},
	{
		garage_id: 173,
		part_id: 138,
		quantity: 32,
	},
	{
		garage_id: 173,
		part_id: 139,
		quantity: 480,
	},
	{
		garage_id: 173,
		part_id: 140,
		quantity: 99,
	},
	{
		garage_id: 173,
		part_id: 141,
		quantity: 208,
	},
	{
		garage_id: 173,
		part_id: 142,
		quantity: 379,
	},
	{
		garage_id: 173,
		part_id: 143,
		quantity: 209,
	},
	{
		garage_id: 173,
		part_id: 144,
		quantity: 112,
	},
	{
		garage_id: 173,
		part_id: 145,
		quantity: 259,
	},
	{
		garage_id: 173,
		part_id: 146,
		quantity: 49,
	},
	{
		garage_id: 173,
		part_id: 147,
		quantity: 327,
	},
	{
		garage_id: 173,
		part_id: 148,
		quantity: 8,
	},
	{
		garage_id: 173,
		part_id: 149,
		quantity: 262,
	},
	{
		garage_id: 173,
		part_id: 150,
		quantity: 248,
	},
	{
		garage_id: 173,
		part_id: 151,
		quantity: 462,
	},
	{
		garage_id: 173,
		part_id: 152,
		quantity: 425,
	},
	{
		garage_id: 173,
		part_id: 153,
		quantity: 147,
	},
	{
		garage_id: 173,
		part_id: 154,
		quantity: 494,
	},
	{
		garage_id: 173,
		part_id: 155,
		quantity: 346,
	},
	{
		garage_id: 173,
		part_id: 156,
		quantity: 422,
	},
	{
		garage_id: 173,
		part_id: 157,
		quantity: 121,
	},
	{
		garage_id: 173,
		part_id: 158,
		quantity: 139,
	},
	{
		garage_id: 173,
		part_id: 159,
		quantity: 316,
	},
	{
		garage_id: 173,
		part_id: 160,
		quantity: 320,
	},
	{
		garage_id: 173,
		part_id: 161,
		quantity: 327,
	},
	{
		garage_id: 173,
		part_id: 162,
		quantity: 306,
	},
	{
		garage_id: 173,
		part_id: 163,
		quantity: 438,
	},
	{
		garage_id: 173,
		part_id: 164,
		quantity: 260,
	},
	{
		garage_id: 173,
		part_id: 165,
		quantity: 334,
	},
	{
		garage_id: 173,
		part_id: 166,
		quantity: 368,
	},
	{
		garage_id: 173,
		part_id: 167,
		quantity: 173,
	},
	{
		garage_id: 173,
		part_id: 168,
		quantity: 441,
	},
	{
		garage_id: 173,
		part_id: 169,
		quantity: 277,
	},
	{
		garage_id: 173,
		part_id: 170,
		quantity: 40,
	},
	{
		garage_id: 173,
		part_id: 171,
		quantity: 187,
	},
	{
		garage_id: 173,
		part_id: 172,
		quantity: 339,
	},
	{
		garage_id: 173,
		part_id: 173,
		quantity: 69,
	},
	{
		garage_id: 173,
		part_id: 174,
		quantity: 37,
	},
	{
		garage_id: 173,
		part_id: 175,
		quantity: 250,
	},
	{
		garage_id: 173,
		part_id: 176,
		quantity: 200,
	},
	{
		garage_id: 173,
		part_id: 177,
		quantity: 8,
	},
	{
		garage_id: 173,
		part_id: 178,
		quantity: 340,
	},
	{
		garage_id: 173,
		part_id: 179,
		quantity: 403,
	},
	{
		garage_id: 173,
		part_id: 180,
		quantity: 1,
	},
	{
		garage_id: 173,
		part_id: 181,
		quantity: 421,
	},
	{
		garage_id: 173,
		part_id: 182,
		quantity: 330,
	},
	{
		garage_id: 173,
		part_id: 183,
		quantity: 291,
	},
	{
		garage_id: 173,
		part_id: 184,
		quantity: 323,
	},
	{
		garage_id: 173,
		part_id: 185,
		quantity: 482,
	},
	{
		garage_id: 173,
		part_id: 186,
		quantity: 94,
	},
	{
		garage_id: 173,
		part_id: 187,
		quantity: 206,
	},
	{
		garage_id: 173,
		part_id: 188,
		quantity: 288,
	},
	{
		garage_id: 173,
		part_id: 189,
		quantity: 287,
	},
	{
		garage_id: 173,
		part_id: 190,
		quantity: 114,
	},
	{
		garage_id: 173,
		part_id: 191,
		quantity: 27,
	},
	{
		garage_id: 173,
		part_id: 192,
		quantity: 390,
	},
	{
		garage_id: 173,
		part_id: 193,
		quantity: 481,
	},
	{
		garage_id: 173,
		part_id: 194,
		quantity: 26,
	},
	{
		garage_id: 173,
		part_id: 195,
		quantity: 402,
	},
	{
		garage_id: 173,
		part_id: 196,
		quantity: 163,
	},
	{
		garage_id: 173,
		part_id: 197,
		quantity: 193,
	},
	{
		garage_id: 173,
		part_id: 198,
		quantity: 143,
	},
	{
		garage_id: 173,
		part_id: 199,
		quantity: 29,
	},
	{
		garage_id: 173,
		part_id: 200,
		quantity: 64,
	},
	{
		garage_id: 173,
		part_id: 201,
		quantity: 362,
	},
	{
		garage_id: 173,
		part_id: 202,
		quantity: 124,
	},
	{
		garage_id: 173,
		part_id: 203,
		quantity: 301,
	},
	{
		garage_id: 173,
		part_id: 204,
		quantity: 352,
	},
	{
		garage_id: 173,
		part_id: 205,
		quantity: 91,
	},
	{
		garage_id: 173,
		part_id: 206,
		quantity: 472,
	},
	{
		garage_id: 173,
		part_id: 207,
		quantity: 264,
	},
	{
		garage_id: 173,
		part_id: 208,
		quantity: 441,
	},
	{
		garage_id: 173,
		part_id: 209,
		quantity: 318,
	},
	{
		garage_id: 173,
		part_id: 210,
		quantity: 35,
	},
	{
		garage_id: 173,
		part_id: 211,
		quantity: 388,
	},
	{
		garage_id: 173,
		part_id: 212,
		quantity: 297,
	},
	{
		garage_id: 173,
		part_id: 213,
		quantity: 60,
	},
	{
		garage_id: 173,
		part_id: 214,
		quantity: 413,
	},
	{
		garage_id: 173,
		part_id: 215,
		quantity: 130,
	},
	{
		garage_id: 173,
		part_id: 216,
		quantity: 306,
	},
	{
		garage_id: 173,
		part_id: 217,
		quantity: 361,
	},
	{
		garage_id: 173,
		part_id: 218,
		quantity: 205,
	},
	{
		garage_id: 173,
		part_id: 219,
		quantity: 428,
	},
	{
		garage_id: 173,
		part_id: 220,
		quantity: 431,
	},
	{
		garage_id: 173,
		part_id: 221,
		quantity: 229,
	},
	{
		garage_id: 173,
		part_id: 222,
		quantity: 214,
	},
	{
		garage_id: 173,
		part_id: 223,
		quantity: 322,
	},
	{
		garage_id: 173,
		part_id: 224,
		quantity: 131,
	},
	{
		garage_id: 173,
		part_id: 225,
		quantity: 497,
	},
	{
		garage_id: 173,
		part_id: 226,
		quantity: 439,
	},
	{
		garage_id: 173,
		part_id: 227,
		quantity: 248,
	},
	{
		garage_id: 173,
		part_id: 228,
		quantity: 408,
	},
	{
		garage_id: 173,
		part_id: 229,
		quantity: 197,
	},
	{
		garage_id: 173,
		part_id: 230,
		quantity: 171,
	},
	{
		garage_id: 173,
		part_id: 231,
		quantity: 169,
	},
	{
		garage_id: 173,
		part_id: 232,
		quantity: 123,
	},
	{
		garage_id: 173,
		part_id: 233,
		quantity: 275,
	},
	{
		garage_id: 173,
		part_id: 234,
		quantity: 450,
	},
	{
		garage_id: 173,
		part_id: 235,
		quantity: 469,
	},
	{
		garage_id: 173,
		part_id: 236,
		quantity: 112,
	},
	{
		garage_id: 173,
		part_id: 237,
		quantity: 457,
	},
	{
		garage_id: 173,
		part_id: 238,
		quantity: 38,
	},
	{
		garage_id: 173,
		part_id: 239,
		quantity: 80,
	},
	{
		garage_id: 173,
		part_id: 240,
		quantity: 486,
	},
	{
		garage_id: 173,
		part_id: 241,
		quantity: 67,
	},
	{
		garage_id: 173,
		part_id: 242,
		quantity: 439,
	},
	{
		garage_id: 173,
		part_id: 243,
		quantity: 389,
	},
	{
		garage_id: 173,
		part_id: 244,
		quantity: 184,
	},
	{
		garage_id: 173,
		part_id: 245,
		quantity: 370,
	},
	{
		garage_id: 173,
		part_id: 246,
		quantity: 135,
	},
	{
		garage_id: 173,
		part_id: 247,
		quantity: 465,
	},
	{
		garage_id: 173,
		part_id: 248,
		quantity: 415,
	},
	{
		garage_id: 173,
		part_id: 249,
		quantity: 263,
	},
	{
		garage_id: 173,
		part_id: 250,
		quantity: 131,
	},
	{
		garage_id: 173,
		part_id: 251,
		quantity: 349,
	},
	{
		garage_id: 173,
		part_id: 252,
		quantity: 435,
	},
	{
		garage_id: 173,
		part_id: 253,
		quantity: 324,
	},
	{
		garage_id: 173,
		part_id: 254,
		quantity: 464,
	},
	{
		garage_id: 173,
		part_id: 255,
		quantity: 410,
	},
	{
		garage_id: 173,
		part_id: 256,
		quantity: 202,
	},
	{
		garage_id: 173,
		part_id: 257,
		quantity: 320,
	},
	{
		garage_id: 173,
		part_id: 258,
		quantity: 496,
	},
	{
		garage_id: 173,
		part_id: 259,
		quantity: 306,
	},
	{
		garage_id: 173,
		part_id: 260,
		quantity: 445,
	},
	{
		garage_id: 173,
		part_id: 261,
		quantity: 186,
	},
	{
		garage_id: 173,
		part_id: 262,
		quantity: 444,
	},
	{
		garage_id: 173,
		part_id: 263,
		quantity: 391,
	},
	{
		garage_id: 173,
		part_id: 264,
		quantity: 84,
	},
	{
		garage_id: 173,
		part_id: 265,
		quantity: 379,
	},
	{
		garage_id: 173,
		part_id: 266,
		quantity: 432,
	},
	{
		garage_id: 173,
		part_id: 267,
		quantity: 96,
	},
	{
		garage_id: 173,
		part_id: 268,
		quantity: 37,
	},
	{
		garage_id: 173,
		part_id: 269,
		quantity: 120,
	},
	{
		garage_id: 173,
		part_id: 270,
		quantity: 215,
	},
	{
		garage_id: 173,
		part_id: 271,
		quantity: 366,
	},
	{
		garage_id: 173,
		part_id: 272,
		quantity: 363,
	},
	{
		garage_id: 173,
		part_id: 273,
		quantity: 93,
	},
	{
		garage_id: 173,
		part_id: 274,
		quantity: 479,
	},
	{
		garage_id: 173,
		part_id: 275,
		quantity: 377,
	},
	{
		garage_id: 173,
		part_id: 276,
		quantity: 381,
	},
	{
		garage_id: 173,
		part_id: 277,
		quantity: 338,
	},
	{
		garage_id: 173,
		part_id: 278,
		quantity: 345,
	},
	{
		garage_id: 173,
		part_id: 279,
		quantity: 452,
	},
	{
		garage_id: 173,
		part_id: 280,
		quantity: 266,
	},
	{
		garage_id: 173,
		part_id: 281,
		quantity: 380,
	},
	{
		garage_id: 173,
		part_id: 282,
		quantity: 50,
	},
	{
		garage_id: 173,
		part_id: 283,
		quantity: 146,
	},
	{
		garage_id: 173,
		part_id: 284,
		quantity: 427,
	},
	{
		garage_id: 173,
		part_id: 285,
		quantity: 452,
	},
	{
		garage_id: 173,
		part_id: 286,
		quantity: 327,
	},
	{
		garage_id: 173,
		part_id: 287,
		quantity: 342,
	},
	{
		garage_id: 173,
		part_id: 288,
		quantity: 334,
	},
	{
		garage_id: 173,
		part_id: 289,
		quantity: 340,
	},
	{
		garage_id: 173,
		part_id: 290,
		quantity: 340,
	},
	{
		garage_id: 173,
		part_id: 291,
		quantity: 37,
	},
	{
		garage_id: 173,
		part_id: 292,
		quantity: 181,
	},
	{
		garage_id: 173,
		part_id: 293,
		quantity: 295,
	},
	{
		garage_id: 173,
		part_id: 294,
		quantity: 495,
	},
	{
		garage_id: 173,
		part_id: 295,
		quantity: 331,
	},
	{
		garage_id: 173,
		part_id: 296,
		quantity: 121,
	},
	{
		garage_id: 173,
		part_id: 297,
		quantity: 32,
	},
	{
		garage_id: 173,
		part_id: 298,
		quantity: 207,
	},
	{
		garage_id: 173,
		part_id: 299,
		quantity: 408,
	},
	{
		garage_id: 173,
		part_id: 300,
		quantity: 460,
	},
	{
		garage_id: 173,
		part_id: 301,
		quantity: 107,
	},
	{
		garage_id: 173,
		part_id: 302,
		quantity: 489,
	},
	{
		garage_id: 173,
		part_id: 303,
		quantity: 287,
	},
	{
		garage_id: 173,
		part_id: 304,
		quantity: 285,
	},
	{
		garage_id: 173,
		part_id: 305,
		quantity: 327,
	},
	{
		garage_id: 173,
		part_id: 306,
		quantity: 440,
	},
	{
		garage_id: 173,
		part_id: 307,
		quantity: 463,
	},
	{
		garage_id: 173,
		part_id: 308,
		quantity: 9,
	},
	{
		garage_id: 173,
		part_id: 309,
		quantity: 315,
	},
	{
		garage_id: 173,
		part_id: 310,
		quantity: 267,
	},
	{
		garage_id: 173,
		part_id: 311,
		quantity: 317,
	},
	{
		garage_id: 173,
		part_id: 312,
		quantity: 314,
	},
	{
		garage_id: 173,
		part_id: 313,
		quantity: 35,
	},
	{
		garage_id: 173,
		part_id: 314,
		quantity: 440,
	},
	{
		garage_id: 173,
		part_id: 315,
		quantity: 244,
	},
	{
		garage_id: 173,
		part_id: 316,
		quantity: 211,
	},
	{
		garage_id: 173,
		part_id: 317,
		quantity: 36,
	},
	{
		garage_id: 173,
		part_id: 318,
		quantity: 48,
	},
	{
		garage_id: 173,
		part_id: 319,
		quantity: 164,
	},
	{
		garage_id: 173,
		part_id: 320,
		quantity: 435,
	},
	{
		garage_id: 173,
		part_id: 321,
		quantity: 314,
	},
	{
		garage_id: 173,
		part_id: 322,
		quantity: 265,
	},
	{
		garage_id: 173,
		part_id: 323,
		quantity: 219,
	},
	{
		garage_id: 173,
		part_id: 324,
		quantity: 449,
	},
	{
		garage_id: 173,
		part_id: 325,
		quantity: 88,
	},
	{
		garage_id: 173,
		part_id: 326,
		quantity: 176,
	},
	{
		garage_id: 173,
		part_id: 327,
		quantity: 259,
	},
	{
		garage_id: 173,
		part_id: 328,
		quantity: 103,
	},
	{
		garage_id: 173,
		part_id: 329,
		quantity: 27,
	},
	{
		garage_id: 173,
		part_id: 330,
		quantity: 450,
	},
	{
		garage_id: 173,
		part_id: 331,
		quantity: 258,
	},
	{
		garage_id: 173,
		part_id: 332,
		quantity: 336,
	},
	{
		garage_id: 173,
		part_id: 333,
		quantity: 205,
	},
	{
		garage_id: 173,
		part_id: 334,
		quantity: 308,
	},
	{
		garage_id: 173,
		part_id: 335,
		quantity: 177,
	},
	{
		garage_id: 173,
		part_id: 336,
		quantity: 290,
	},
	{
		garage_id: 173,
		part_id: 337,
		quantity: 336,
	},
	{
		garage_id: 173,
		part_id: 338,
		quantity: 222,
	},
	{
		garage_id: 173,
		part_id: 339,
		quantity: 96,
	},
	{
		garage_id: 173,
		part_id: 340,
		quantity: 111,
	},
	{
		garage_id: 173,
		part_id: 341,
		quantity: 354,
	},
	{
		garage_id: 173,
		part_id: 342,
		quantity: 405,
	},
	{
		garage_id: 173,
		part_id: 343,
		quantity: 434,
	},
	{
		garage_id: 173,
		part_id: 344,
		quantity: 442,
	},
	{
		garage_id: 173,
		part_id: 345,
		quantity: 181,
	},
	{
		garage_id: 173,
		part_id: 346,
		quantity: 8,
	},
	{
		garage_id: 173,
		part_id: 347,
		quantity: 394,
	},
	{
		garage_id: 173,
		part_id: 348,
		quantity: 154,
	},
	{
		garage_id: 173,
		part_id: 349,
		quantity: 290,
	},
	{
		garage_id: 173,
		part_id: 350,
		quantity: 38,
	},
	{
		garage_id: 173,
		part_id: 351,
		quantity: 170,
	},
	{
		garage_id: 173,
		part_id: 352,
		quantity: 468,
	},
	{
		garage_id: 173,
		part_id: 353,
		quantity: 129,
	},
	{
		garage_id: 173,
		part_id: 354,
		quantity: 203,
	},
	{
		garage_id: 173,
		part_id: 355,
		quantity: 414,
	},
	{
		garage_id: 173,
		part_id: 356,
		quantity: 322,
	},
	{
		garage_id: 173,
		part_id: 357,
		quantity: 389,
	},
	{
		garage_id: 173,
		part_id: 358,
		quantity: 114,
	},
	{
		garage_id: 173,
		part_id: 359,
		quantity: 406,
	},
	{
		garage_id: 173,
		part_id: 360,
		quantity: 220,
	},
	{
		garage_id: 173,
		part_id: 361,
		quantity: 48,
	},
	{
		garage_id: 173,
		part_id: 362,
		quantity: 406,
	},
	{
		garage_id: 173,
		part_id: 363,
		quantity: 270,
	},
	{
		garage_id: 173,
		part_id: 364,
		quantity: 347,
	},
	{
		garage_id: 173,
		part_id: 365,
		quantity: 318,
	},
	{
		garage_id: 173,
		part_id: 366,
		quantity: 8,
	},
	{
		garage_id: 173,
		part_id: 367,
		quantity: 75,
	},
	{
		garage_id: 173,
		part_id: 368,
		quantity: 164,
	},
	{
		garage_id: 173,
		part_id: 369,
		quantity: 395,
	},
	{
		garage_id: 173,
		part_id: 370,
		quantity: 279,
	},
	{
		garage_id: 173,
		part_id: 371,
		quantity: 93,
	},
	{
		garage_id: 173,
		part_id: 372,
		quantity: 166,
	},
	{
		garage_id: 173,
		part_id: 373,
		quantity: 248,
	},
	{
		garage_id: 173,
		part_id: 374,
		quantity: 81,
	},
	{
		garage_id: 173,
		part_id: 375,
		quantity: 362,
	},
	{
		garage_id: 173,
		part_id: 376,
		quantity: 348,
	},
	{
		garage_id: 173,
		part_id: 377,
		quantity: 38,
	},
	{
		garage_id: 173,
		part_id: 378,
		quantity: 374,
	},
	{
		garage_id: 173,
		part_id: 379,
		quantity: 421,
	},
	{
		garage_id: 173,
		part_id: 380,
		quantity: 86,
	},
	{
		garage_id: 173,
		part_id: 381,
		quantity: 281,
	},
	{
		garage_id: 173,
		part_id: 382,
		quantity: 212,
	},
	{
		garage_id: 173,
		part_id: 383,
		quantity: 104,
	},
	{
		garage_id: 173,
		part_id: 384,
		quantity: 94,
	},
	{
		garage_id: 173,
		part_id: 385,
		quantity: 347,
	},
	{
		garage_id: 173,
		part_id: 386,
		quantity: 353,
	},
	{
		garage_id: 173,
		part_id: 387,
		quantity: 377,
	},
	{
		garage_id: 173,
		part_id: 388,
		quantity: 299,
	},
	{
		garage_id: 173,
		part_id: 389,
		quantity: 224,
	},
	{
		garage_id: 173,
		part_id: 390,
		quantity: 43,
	},
	{
		garage_id: 173,
		part_id: 391,
		quantity: 298,
	},
	{
		garage_id: 173,
		part_id: 392,
		quantity: 322,
	},
	{
		garage_id: 173,
		part_id: 393,
		quantity: 283,
	},
	{
		garage_id: 173,
		part_id: 394,
		quantity: 17,
	},
	{
		garage_id: 173,
		part_id: 395,
		quantity: 281,
	},
	{
		garage_id: 173,
		part_id: 396,
		quantity: 169,
	},
	{
		garage_id: 173,
		part_id: 397,
		quantity: 28,
	},
	{
		garage_id: 173,
		part_id: 398,
		quantity: 154,
	},
	{
		garage_id: 173,
		part_id: 399,
		quantity: 326,
	},
	{
		garage_id: 174,
		part_id: 0,
		quantity: 77,
	},
	{
		garage_id: 174,
		part_id: 1,
		quantity: 126,
	},
	{
		garage_id: 174,
		part_id: 2,
		quantity: 182,
	},
	{
		garage_id: 174,
		part_id: 3,
		quantity: 281,
	},
	{
		garage_id: 174,
		part_id: 4,
		quantity: 433,
	},
	{
		garage_id: 174,
		part_id: 5,
		quantity: 368,
	},
	{
		garage_id: 174,
		part_id: 6,
		quantity: 214,
	},
	{
		garage_id: 174,
		part_id: 7,
		quantity: 179,
	},
	{
		garage_id: 174,
		part_id: 8,
		quantity: 323,
	},
	{
		garage_id: 174,
		part_id: 9,
		quantity: 244,
	},
	{
		garage_id: 174,
		part_id: 10,
		quantity: 212,
	},
	{
		garage_id: 174,
		part_id: 11,
		quantity: 336,
	},
	{
		garage_id: 174,
		part_id: 12,
		quantity: 493,
	},
	{
		garage_id: 174,
		part_id: 13,
		quantity: 87,
	},
	{
		garage_id: 174,
		part_id: 14,
		quantity: 445,
	},
	{
		garage_id: 174,
		part_id: 15,
		quantity: 144,
	},
	{
		garage_id: 174,
		part_id: 16,
		quantity: 349,
	},
	{
		garage_id: 174,
		part_id: 17,
		quantity: 289,
	},
	{
		garage_id: 174,
		part_id: 18,
		quantity: 21,
	},
	{
		garage_id: 174,
		part_id: 19,
		quantity: 331,
	},
	{
		garage_id: 174,
		part_id: 20,
		quantity: 217,
	},
	{
		garage_id: 174,
		part_id: 21,
		quantity: 367,
	},
	{
		garage_id: 174,
		part_id: 22,
		quantity: 472,
	},
	{
		garage_id: 174,
		part_id: 23,
		quantity: 106,
	},
	{
		garage_id: 174,
		part_id: 24,
		quantity: 449,
	},
	{
		garage_id: 174,
		part_id: 25,
		quantity: 424,
	},
	{
		garage_id: 174,
		part_id: 26,
		quantity: 214,
	},
	{
		garage_id: 174,
		part_id: 27,
		quantity: 481,
	},
	{
		garage_id: 174,
		part_id: 28,
		quantity: 422,
	},
	{
		garage_id: 174,
		part_id: 29,
		quantity: 161,
	},
	{
		garage_id: 174,
		part_id: 30,
		quantity: 297,
	},
	{
		garage_id: 174,
		part_id: 31,
		quantity: 9,
	},
	{
		garage_id: 174,
		part_id: 32,
		quantity: 448,
	},
	{
		garage_id: 174,
		part_id: 33,
		quantity: 483,
	},
	{
		garage_id: 174,
		part_id: 34,
		quantity: 458,
	},
	{
		garage_id: 174,
		part_id: 35,
		quantity: 309,
	},
	{
		garage_id: 174,
		part_id: 36,
		quantity: 99,
	},
	{
		garage_id: 174,
		part_id: 37,
		quantity: 12,
	},
	{
		garage_id: 174,
		part_id: 38,
		quantity: 360,
	},
	{
		garage_id: 174,
		part_id: 39,
		quantity: 415,
	},
	{
		garage_id: 174,
		part_id: 40,
		quantity: 356,
	},
	{
		garage_id: 174,
		part_id: 41,
		quantity: 5,
	},
	{
		garage_id: 174,
		part_id: 42,
		quantity: 471,
	},
	{
		garage_id: 174,
		part_id: 43,
		quantity: 90,
	},
	{
		garage_id: 174,
		part_id: 44,
		quantity: 270,
	},
	{
		garage_id: 174,
		part_id: 45,
		quantity: 496,
	},
	{
		garage_id: 174,
		part_id: 46,
		quantity: 464,
	},
	{
		garage_id: 174,
		part_id: 47,
		quantity: 53,
	},
	{
		garage_id: 174,
		part_id: 48,
		quantity: 422,
	},
	{
		garage_id: 174,
		part_id: 49,
		quantity: 291,
	},
	{
		garage_id: 174,
		part_id: 50,
		quantity: 12,
	},
	{
		garage_id: 174,
		part_id: 51,
		quantity: 202,
	},
	{
		garage_id: 174,
		part_id: 52,
		quantity: 461,
	},
	{
		garage_id: 174,
		part_id: 53,
		quantity: 479,
	},
	{
		garage_id: 174,
		part_id: 54,
		quantity: 70,
	},
	{
		garage_id: 174,
		part_id: 55,
		quantity: 97,
	},
	{
		garage_id: 174,
		part_id: 56,
		quantity: 114,
	},
	{
		garage_id: 174,
		part_id: 57,
		quantity: 268,
	},
	{
		garage_id: 174,
		part_id: 58,
		quantity: 339,
	},
	{
		garage_id: 174,
		part_id: 59,
		quantity: 98,
	},
	{
		garage_id: 174,
		part_id: 60,
		quantity: 363,
	},
	{
		garage_id: 174,
		part_id: 61,
		quantity: 275,
	},
	{
		garage_id: 174,
		part_id: 62,
		quantity: 347,
	},
	{
		garage_id: 174,
		part_id: 63,
		quantity: 421,
	},
	{
		garage_id: 174,
		part_id: 64,
		quantity: 114,
	},
	{
		garage_id: 174,
		part_id: 65,
		quantity: 426,
	},
	{
		garage_id: 174,
		part_id: 66,
		quantity: 499,
	},
	{
		garage_id: 174,
		part_id: 67,
		quantity: 460,
	},
	{
		garage_id: 174,
		part_id: 68,
		quantity: 277,
	},
	{
		garage_id: 174,
		part_id: 69,
		quantity: 215,
	},
	{
		garage_id: 174,
		part_id: 70,
		quantity: 443,
	},
	{
		garage_id: 174,
		part_id: 71,
		quantity: 437,
	},
	{
		garage_id: 174,
		part_id: 72,
		quantity: 468,
	},
	{
		garage_id: 174,
		part_id: 73,
		quantity: 304,
	},
	{
		garage_id: 174,
		part_id: 74,
		quantity: 210,
	},
	{
		garage_id: 174,
		part_id: 75,
		quantity: 352,
	},
	{
		garage_id: 174,
		part_id: 76,
		quantity: 405,
	},
	{
		garage_id: 174,
		part_id: 77,
		quantity: 48,
	},
	{
		garage_id: 174,
		part_id: 78,
		quantity: 245,
	},
	{
		garage_id: 174,
		part_id: 79,
		quantity: 450,
	},
	{
		garage_id: 174,
		part_id: 80,
		quantity: 376,
	},
	{
		garage_id: 174,
		part_id: 81,
		quantity: 141,
	},
	{
		garage_id: 174,
		part_id: 82,
		quantity: 312,
	},
	{
		garage_id: 174,
		part_id: 83,
		quantity: 156,
	},
	{
		garage_id: 174,
		part_id: 84,
		quantity: 0,
	},
	{
		garage_id: 174,
		part_id: 85,
		quantity: 429,
	},
	{
		garage_id: 174,
		part_id: 86,
		quantity: 215,
	},
	{
		garage_id: 174,
		part_id: 87,
		quantity: 188,
	},
	{
		garage_id: 174,
		part_id: 88,
		quantity: 427,
	},
	{
		garage_id: 174,
		part_id: 89,
		quantity: 181,
	},
	{
		garage_id: 174,
		part_id: 90,
		quantity: 277,
	},
	{
		garage_id: 174,
		part_id: 91,
		quantity: 103,
	},
	{
		garage_id: 174,
		part_id: 92,
		quantity: 67,
	},
	{
		garage_id: 174,
		part_id: 93,
		quantity: 137,
	},
	{
		garage_id: 174,
		part_id: 94,
		quantity: 241,
	},
	{
		garage_id: 174,
		part_id: 95,
		quantity: 178,
	},
	{
		garage_id: 174,
		part_id: 96,
		quantity: 460,
	},
	{
		garage_id: 174,
		part_id: 97,
		quantity: 360,
	},
	{
		garage_id: 174,
		part_id: 98,
		quantity: 99,
	},
	{
		garage_id: 174,
		part_id: 99,
		quantity: 448,
	},
	{
		garage_id: 174,
		part_id: 100,
		quantity: 480,
	},
	{
		garage_id: 174,
		part_id: 101,
		quantity: 315,
	},
	{
		garage_id: 174,
		part_id: 102,
		quantity: 235,
	},
	{
		garage_id: 174,
		part_id: 103,
		quantity: 123,
	},
	{
		garage_id: 174,
		part_id: 104,
		quantity: 382,
	},
	{
		garage_id: 174,
		part_id: 105,
		quantity: 276,
	},
	{
		garage_id: 174,
		part_id: 106,
		quantity: 231,
	},
	{
		garage_id: 174,
		part_id: 107,
		quantity: 439,
	},
	{
		garage_id: 174,
		part_id: 108,
		quantity: 458,
	},
	{
		garage_id: 174,
		part_id: 109,
		quantity: 378,
	},
	{
		garage_id: 174,
		part_id: 110,
		quantity: 79,
	},
	{
		garage_id: 174,
		part_id: 111,
		quantity: 2,
	},
	{
		garage_id: 174,
		part_id: 112,
		quantity: 306,
	},
	{
		garage_id: 174,
		part_id: 113,
		quantity: 23,
	},
	{
		garage_id: 174,
		part_id: 114,
		quantity: 482,
	},
	{
		garage_id: 174,
		part_id: 115,
		quantity: 98,
	},
	{
		garage_id: 174,
		part_id: 116,
		quantity: 247,
	},
	{
		garage_id: 174,
		part_id: 117,
		quantity: 390,
	},
	{
		garage_id: 174,
		part_id: 118,
		quantity: 137,
	},
	{
		garage_id: 174,
		part_id: 119,
		quantity: 475,
	},
	{
		garage_id: 174,
		part_id: 120,
		quantity: 31,
	},
	{
		garage_id: 174,
		part_id: 121,
		quantity: 438,
	},
	{
		garage_id: 174,
		part_id: 122,
		quantity: 58,
	},
	{
		garage_id: 174,
		part_id: 123,
		quantity: 421,
	},
	{
		garage_id: 174,
		part_id: 124,
		quantity: 498,
	},
	{
		garage_id: 174,
		part_id: 125,
		quantity: 134,
	},
	{
		garage_id: 174,
		part_id: 126,
		quantity: 147,
	},
	{
		garage_id: 174,
		part_id: 127,
		quantity: 113,
	},
	{
		garage_id: 174,
		part_id: 128,
		quantity: 489,
	},
	{
		garage_id: 174,
		part_id: 129,
		quantity: 220,
	},
	{
		garage_id: 174,
		part_id: 130,
		quantity: 78,
	},
	{
		garage_id: 174,
		part_id: 131,
		quantity: 342,
	},
	{
		garage_id: 174,
		part_id: 132,
		quantity: 113,
	},
	{
		garage_id: 174,
		part_id: 133,
		quantity: 163,
	},
	{
		garage_id: 174,
		part_id: 134,
		quantity: 415,
	},
	{
		garage_id: 174,
		part_id: 135,
		quantity: 219,
	},
	{
		garage_id: 174,
		part_id: 136,
		quantity: 417,
	},
	{
		garage_id: 174,
		part_id: 137,
		quantity: 139,
	},
	{
		garage_id: 174,
		part_id: 138,
		quantity: 49,
	},
	{
		garage_id: 174,
		part_id: 139,
		quantity: 450,
	},
	{
		garage_id: 174,
		part_id: 140,
		quantity: 134,
	},
	{
		garage_id: 174,
		part_id: 141,
		quantity: 192,
	},
	{
		garage_id: 174,
		part_id: 142,
		quantity: 190,
	},
	{
		garage_id: 174,
		part_id: 143,
		quantity: 92,
	},
	{
		garage_id: 174,
		part_id: 144,
		quantity: 430,
	},
	{
		garage_id: 174,
		part_id: 145,
		quantity: 135,
	},
	{
		garage_id: 174,
		part_id: 146,
		quantity: 283,
	},
	{
		garage_id: 174,
		part_id: 147,
		quantity: 324,
	},
	{
		garage_id: 174,
		part_id: 148,
		quantity: 349,
	},
	{
		garage_id: 174,
		part_id: 149,
		quantity: 206,
	},
	{
		garage_id: 174,
		part_id: 150,
		quantity: 278,
	},
	{
		garage_id: 174,
		part_id: 151,
		quantity: 139,
	},
	{
		garage_id: 174,
		part_id: 152,
		quantity: 498,
	},
	{
		garage_id: 174,
		part_id: 153,
		quantity: 326,
	},
	{
		garage_id: 174,
		part_id: 154,
		quantity: 477,
	},
	{
		garage_id: 174,
		part_id: 155,
		quantity: 390,
	},
	{
		garage_id: 174,
		part_id: 156,
		quantity: 192,
	},
	{
		garage_id: 174,
		part_id: 157,
		quantity: 295,
	},
	{
		garage_id: 174,
		part_id: 158,
		quantity: 42,
	},
	{
		garage_id: 174,
		part_id: 159,
		quantity: 102,
	},
	{
		garage_id: 174,
		part_id: 160,
		quantity: 257,
	},
	{
		garage_id: 174,
		part_id: 161,
		quantity: 473,
	},
	{
		garage_id: 174,
		part_id: 162,
		quantity: 322,
	},
	{
		garage_id: 174,
		part_id: 163,
		quantity: 407,
	},
	{
		garage_id: 174,
		part_id: 164,
		quantity: 29,
	},
	{
		garage_id: 174,
		part_id: 165,
		quantity: 128,
	},
	{
		garage_id: 174,
		part_id: 166,
		quantity: 235,
	},
	{
		garage_id: 174,
		part_id: 167,
		quantity: 177,
	},
	{
		garage_id: 174,
		part_id: 168,
		quantity: 330,
	},
	{
		garage_id: 174,
		part_id: 169,
		quantity: 122,
	},
	{
		garage_id: 174,
		part_id: 170,
		quantity: 265,
	},
	{
		garage_id: 174,
		part_id: 171,
		quantity: 138,
	},
	{
		garage_id: 174,
		part_id: 172,
		quantity: 220,
	},
	{
		garage_id: 174,
		part_id: 173,
		quantity: 251,
	},
	{
		garage_id: 174,
		part_id: 174,
		quantity: 258,
	},
	{
		garage_id: 174,
		part_id: 175,
		quantity: 285,
	},
	{
		garage_id: 174,
		part_id: 176,
		quantity: 204,
	},
	{
		garage_id: 174,
		part_id: 177,
		quantity: 463,
	},
	{
		garage_id: 174,
		part_id: 178,
		quantity: 444,
	},
	{
		garage_id: 174,
		part_id: 179,
		quantity: 450,
	},
	{
		garage_id: 174,
		part_id: 180,
		quantity: 354,
	},
	{
		garage_id: 174,
		part_id: 181,
		quantity: 227,
	},
	{
		garage_id: 174,
		part_id: 182,
		quantity: 144,
	},
	{
		garage_id: 174,
		part_id: 183,
		quantity: 397,
	},
	{
		garage_id: 174,
		part_id: 184,
		quantity: 379,
	},
	{
		garage_id: 174,
		part_id: 185,
		quantity: 152,
	},
	{
		garage_id: 174,
		part_id: 186,
		quantity: 409,
	},
	{
		garage_id: 174,
		part_id: 187,
		quantity: 369,
	},
	{
		garage_id: 174,
		part_id: 188,
		quantity: 427,
	},
	{
		garage_id: 174,
		part_id: 189,
		quantity: 399,
	},
	{
		garage_id: 174,
		part_id: 190,
		quantity: 204,
	},
	{
		garage_id: 174,
		part_id: 191,
		quantity: 220,
	},
	{
		garage_id: 174,
		part_id: 192,
		quantity: 143,
	},
	{
		garage_id: 174,
		part_id: 193,
		quantity: 122,
	},
	{
		garage_id: 174,
		part_id: 194,
		quantity: 188,
	},
	{
		garage_id: 174,
		part_id: 195,
		quantity: 372,
	},
	{
		garage_id: 174,
		part_id: 196,
		quantity: 328,
	},
	{
		garage_id: 174,
		part_id: 197,
		quantity: 323,
	},
	{
		garage_id: 174,
		part_id: 198,
		quantity: 436,
	},
	{
		garage_id: 174,
		part_id: 199,
		quantity: 385,
	},
	{
		garage_id: 174,
		part_id: 200,
		quantity: 164,
	},
	{
		garage_id: 174,
		part_id: 201,
		quantity: 17,
	},
	{
		garage_id: 174,
		part_id: 202,
		quantity: 5,
	},
	{
		garage_id: 174,
		part_id: 203,
		quantity: 310,
	},
	{
		garage_id: 174,
		part_id: 204,
		quantity: 249,
	},
	{
		garage_id: 174,
		part_id: 205,
		quantity: 334,
	},
	{
		garage_id: 174,
		part_id: 206,
		quantity: 377,
	},
	{
		garage_id: 174,
		part_id: 207,
		quantity: 74,
	},
	{
		garage_id: 174,
		part_id: 208,
		quantity: 446,
	},
	{
		garage_id: 174,
		part_id: 209,
		quantity: 287,
	},
	{
		garage_id: 174,
		part_id: 210,
		quantity: 487,
	},
	{
		garage_id: 174,
		part_id: 211,
		quantity: 120,
	},
	{
		garage_id: 174,
		part_id: 212,
		quantity: 388,
	},
	{
		garage_id: 174,
		part_id: 213,
		quantity: 451,
	},
	{
		garage_id: 174,
		part_id: 214,
		quantity: 182,
	},
	{
		garage_id: 174,
		part_id: 215,
		quantity: 443,
	},
	{
		garage_id: 174,
		part_id: 216,
		quantity: 267,
	},
	{
		garage_id: 174,
		part_id: 217,
		quantity: 468,
	},
	{
		garage_id: 174,
		part_id: 218,
		quantity: 436,
	},
	{
		garage_id: 174,
		part_id: 219,
		quantity: 157,
	},
	{
		garage_id: 174,
		part_id: 220,
		quantity: 439,
	},
	{
		garage_id: 174,
		part_id: 221,
		quantity: 415,
	},
	{
		garage_id: 174,
		part_id: 222,
		quantity: 210,
	},
	{
		garage_id: 174,
		part_id: 223,
		quantity: 183,
	},
	{
		garage_id: 174,
		part_id: 224,
		quantity: 172,
	},
	{
		garage_id: 174,
		part_id: 225,
		quantity: 102,
	},
	{
		garage_id: 174,
		part_id: 226,
		quantity: 426,
	},
	{
		garage_id: 174,
		part_id: 227,
		quantity: 221,
	},
	{
		garage_id: 174,
		part_id: 228,
		quantity: 55,
	},
	{
		garage_id: 174,
		part_id: 229,
		quantity: 237,
	},
	{
		garage_id: 174,
		part_id: 230,
		quantity: 97,
	},
	{
		garage_id: 174,
		part_id: 231,
		quantity: 116,
	},
	{
		garage_id: 174,
		part_id: 232,
		quantity: 267,
	},
	{
		garage_id: 174,
		part_id: 233,
		quantity: 412,
	},
	{
		garage_id: 174,
		part_id: 234,
		quantity: 82,
	},
	{
		garage_id: 174,
		part_id: 235,
		quantity: 420,
	},
	{
		garage_id: 174,
		part_id: 236,
		quantity: 381,
	},
	{
		garage_id: 174,
		part_id: 237,
		quantity: 439,
	},
	{
		garage_id: 174,
		part_id: 238,
		quantity: 64,
	},
	{
		garage_id: 174,
		part_id: 239,
		quantity: 147,
	},
	{
		garage_id: 174,
		part_id: 240,
		quantity: 290,
	},
	{
		garage_id: 174,
		part_id: 241,
		quantity: 337,
	},
	{
		garage_id: 174,
		part_id: 242,
		quantity: 404,
	},
	{
		garage_id: 174,
		part_id: 243,
		quantity: 137,
	},
	{
		garage_id: 174,
		part_id: 244,
		quantity: 149,
	},
	{
		garage_id: 174,
		part_id: 245,
		quantity: 98,
	},
	{
		garage_id: 174,
		part_id: 246,
		quantity: 177,
	},
	{
		garage_id: 174,
		part_id: 247,
		quantity: 89,
	},
	{
		garage_id: 174,
		part_id: 248,
		quantity: 178,
	},
	{
		garage_id: 174,
		part_id: 249,
		quantity: 79,
	},
	{
		garage_id: 174,
		part_id: 250,
		quantity: 404,
	},
	{
		garage_id: 174,
		part_id: 251,
		quantity: 473,
	},
	{
		garage_id: 174,
		part_id: 252,
		quantity: 458,
	},
	{
		garage_id: 174,
		part_id: 253,
		quantity: 63,
	},
	{
		garage_id: 174,
		part_id: 254,
		quantity: 408,
	},
	{
		garage_id: 174,
		part_id: 255,
		quantity: 453,
	},
	{
		garage_id: 174,
		part_id: 256,
		quantity: 176,
	},
	{
		garage_id: 174,
		part_id: 257,
		quantity: 379,
	},
	{
		garage_id: 174,
		part_id: 258,
		quantity: 272,
	},
	{
		garage_id: 174,
		part_id: 259,
		quantity: 29,
	},
	{
		garage_id: 174,
		part_id: 260,
		quantity: 172,
	},
	{
		garage_id: 174,
		part_id: 261,
		quantity: 137,
	},
	{
		garage_id: 174,
		part_id: 262,
		quantity: 7,
	},
	{
		garage_id: 174,
		part_id: 263,
		quantity: 71,
	},
	{
		garage_id: 174,
		part_id: 264,
		quantity: 345,
	},
	{
		garage_id: 174,
		part_id: 265,
		quantity: 432,
	},
	{
		garage_id: 174,
		part_id: 266,
		quantity: 42,
	},
	{
		garage_id: 174,
		part_id: 267,
		quantity: 376,
	},
	{
		garage_id: 174,
		part_id: 268,
		quantity: 392,
	},
	{
		garage_id: 174,
		part_id: 269,
		quantity: 216,
	},
	{
		garage_id: 174,
		part_id: 270,
		quantity: 235,
	},
	{
		garage_id: 174,
		part_id: 271,
		quantity: 322,
	},
	{
		garage_id: 174,
		part_id: 272,
		quantity: 177,
	},
	{
		garage_id: 174,
		part_id: 273,
		quantity: 293,
	},
	{
		garage_id: 174,
		part_id: 274,
		quantity: 41,
	},
	{
		garage_id: 174,
		part_id: 275,
		quantity: 86,
	},
	{
		garage_id: 174,
		part_id: 276,
		quantity: 308,
	},
	{
		garage_id: 174,
		part_id: 277,
		quantity: 233,
	},
	{
		garage_id: 174,
		part_id: 278,
		quantity: 404,
	},
	{
		garage_id: 174,
		part_id: 279,
		quantity: 435,
	},
	{
		garage_id: 174,
		part_id: 280,
		quantity: 325,
	},
	{
		garage_id: 174,
		part_id: 281,
		quantity: 135,
	},
	{
		garage_id: 174,
		part_id: 282,
		quantity: 225,
	},
	{
		garage_id: 174,
		part_id: 283,
		quantity: 147,
	},
	{
		garage_id: 174,
		part_id: 284,
		quantity: 111,
	},
	{
		garage_id: 174,
		part_id: 285,
		quantity: 442,
	},
	{
		garage_id: 174,
		part_id: 286,
		quantity: 135,
	},
	{
		garage_id: 174,
		part_id: 287,
		quantity: 328,
	},
	{
		garage_id: 174,
		part_id: 288,
		quantity: 391,
	},
	{
		garage_id: 174,
		part_id: 289,
		quantity: 275,
	},
	{
		garage_id: 174,
		part_id: 290,
		quantity: 430,
	},
	{
		garage_id: 174,
		part_id: 291,
		quantity: 459,
	},
	{
		garage_id: 174,
		part_id: 292,
		quantity: 323,
	},
	{
		garage_id: 174,
		part_id: 293,
		quantity: 173,
	},
	{
		garage_id: 174,
		part_id: 294,
		quantity: 361,
	},
	{
		garage_id: 174,
		part_id: 295,
		quantity: 112,
	},
	{
		garage_id: 174,
		part_id: 296,
		quantity: 366,
	},
	{
		garage_id: 174,
		part_id: 297,
		quantity: 408,
	},
	{
		garage_id: 174,
		part_id: 298,
		quantity: 62,
	},
	{
		garage_id: 174,
		part_id: 299,
		quantity: 446,
	},
	{
		garage_id: 174,
		part_id: 300,
		quantity: 188,
	},
	{
		garage_id: 174,
		part_id: 301,
		quantity: 329,
	},
	{
		garage_id: 174,
		part_id: 302,
		quantity: 273,
	},
	{
		garage_id: 174,
		part_id: 303,
		quantity: 14,
	},
	{
		garage_id: 174,
		part_id: 304,
		quantity: 275,
	},
	{
		garage_id: 174,
		part_id: 305,
		quantity: 31,
	},
	{
		garage_id: 174,
		part_id: 306,
		quantity: 409,
	},
	{
		garage_id: 174,
		part_id: 307,
		quantity: 274,
	},
	{
		garage_id: 174,
		part_id: 308,
		quantity: 396,
	},
	{
		garage_id: 174,
		part_id: 309,
		quantity: 277,
	},
	{
		garage_id: 174,
		part_id: 310,
		quantity: 277,
	},
	{
		garage_id: 174,
		part_id: 311,
		quantity: 185,
	},
	{
		garage_id: 174,
		part_id: 312,
		quantity: 49,
	},
	{
		garage_id: 174,
		part_id: 313,
		quantity: 484,
	},
	{
		garage_id: 174,
		part_id: 314,
		quantity: 346,
	},
	{
		garage_id: 174,
		part_id: 315,
		quantity: 170,
	},
	{
		garage_id: 174,
		part_id: 316,
		quantity: 449,
	},
	{
		garage_id: 174,
		part_id: 317,
		quantity: 128,
	},
	{
		garage_id: 174,
		part_id: 318,
		quantity: 305,
	},
	{
		garage_id: 174,
		part_id: 319,
		quantity: 454,
	},
	{
		garage_id: 174,
		part_id: 320,
		quantity: 124,
	},
	{
		garage_id: 174,
		part_id: 321,
		quantity: 421,
	},
	{
		garage_id: 174,
		part_id: 322,
		quantity: 255,
	},
	{
		garage_id: 174,
		part_id: 323,
		quantity: 376,
	},
	{
		garage_id: 174,
		part_id: 324,
		quantity: 202,
	},
	{
		garage_id: 174,
		part_id: 325,
		quantity: 368,
	},
	{
		garage_id: 174,
		part_id: 326,
		quantity: 300,
	},
	{
		garage_id: 174,
		part_id: 327,
		quantity: 11,
	},
	{
		garage_id: 174,
		part_id: 328,
		quantity: 94,
	},
	{
		garage_id: 174,
		part_id: 329,
		quantity: 179,
	},
	{
		garage_id: 174,
		part_id: 330,
		quantity: 53,
	},
	{
		garage_id: 174,
		part_id: 331,
		quantity: 274,
	},
	{
		garage_id: 174,
		part_id: 332,
		quantity: 387,
	},
	{
		garage_id: 174,
		part_id: 333,
		quantity: 435,
	},
	{
		garage_id: 174,
		part_id: 334,
		quantity: 298,
	},
	{
		garage_id: 174,
		part_id: 335,
		quantity: 457,
	},
	{
		garage_id: 174,
		part_id: 336,
		quantity: 301,
	},
	{
		garage_id: 174,
		part_id: 337,
		quantity: 494,
	},
	{
		garage_id: 174,
		part_id: 338,
		quantity: 484,
	},
	{
		garage_id: 174,
		part_id: 339,
		quantity: 37,
	},
	{
		garage_id: 174,
		part_id: 340,
		quantity: 20,
	},
	{
		garage_id: 174,
		part_id: 341,
		quantity: 390,
	},
	{
		garage_id: 174,
		part_id: 342,
		quantity: 192,
	},
	{
		garage_id: 174,
		part_id: 343,
		quantity: 455,
	},
	{
		garage_id: 174,
		part_id: 344,
		quantity: 448,
	},
	{
		garage_id: 174,
		part_id: 345,
		quantity: 315,
	},
	{
		garage_id: 174,
		part_id: 346,
		quantity: 238,
	},
	{
		garage_id: 174,
		part_id: 347,
		quantity: 363,
	},
	{
		garage_id: 174,
		part_id: 348,
		quantity: 110,
	},
	{
		garage_id: 174,
		part_id: 349,
		quantity: 264,
	},
	{
		garage_id: 174,
		part_id: 350,
		quantity: 339,
	},
	{
		garage_id: 174,
		part_id: 351,
		quantity: 165,
	},
	{
		garage_id: 174,
		part_id: 352,
		quantity: 215,
	},
	{
		garage_id: 174,
		part_id: 353,
		quantity: 495,
	},
	{
		garage_id: 174,
		part_id: 354,
		quantity: 298,
	},
	{
		garage_id: 174,
		part_id: 355,
		quantity: 445,
	},
	{
		garage_id: 174,
		part_id: 356,
		quantity: 471,
	},
	{
		garage_id: 174,
		part_id: 357,
		quantity: 342,
	},
	{
		garage_id: 174,
		part_id: 358,
		quantity: 463,
	},
	{
		garage_id: 174,
		part_id: 359,
		quantity: 66,
	},
	{
		garage_id: 174,
		part_id: 360,
		quantity: 95,
	},
	{
		garage_id: 174,
		part_id: 361,
		quantity: 472,
	},
	{
		garage_id: 174,
		part_id: 362,
		quantity: 184,
	},
	{
		garage_id: 174,
		part_id: 363,
		quantity: 406,
	},
	{
		garage_id: 174,
		part_id: 364,
		quantity: 262,
	},
	{
		garage_id: 174,
		part_id: 365,
		quantity: 226,
	},
	{
		garage_id: 174,
		part_id: 366,
		quantity: 493,
	},
	{
		garage_id: 174,
		part_id: 367,
		quantity: 240,
	},
	{
		garage_id: 174,
		part_id: 368,
		quantity: 211,
	},
	{
		garage_id: 174,
		part_id: 369,
		quantity: 279,
	},
	{
		garage_id: 174,
		part_id: 370,
		quantity: 169,
	},
	{
		garage_id: 174,
		part_id: 371,
		quantity: 362,
	},
	{
		garage_id: 174,
		part_id: 372,
		quantity: 273,
	},
	{
		garage_id: 174,
		part_id: 373,
		quantity: 202,
	},
	{
		garage_id: 174,
		part_id: 374,
		quantity: 496,
	},
	{
		garage_id: 174,
		part_id: 375,
		quantity: 26,
	},
	{
		garage_id: 174,
		part_id: 376,
		quantity: 52,
	},
	{
		garage_id: 174,
		part_id: 377,
		quantity: 494,
	},
	{
		garage_id: 174,
		part_id: 378,
		quantity: 139,
	},
	{
		garage_id: 174,
		part_id: 379,
		quantity: 290,
	},
	{
		garage_id: 174,
		part_id: 380,
		quantity: 394,
	},
	{
		garage_id: 174,
		part_id: 381,
		quantity: 377,
	},
	{
		garage_id: 174,
		part_id: 382,
		quantity: 50,
	},
	{
		garage_id: 174,
		part_id: 383,
		quantity: 359,
	},
	{
		garage_id: 174,
		part_id: 384,
		quantity: 446,
	},
	{
		garage_id: 174,
		part_id: 385,
		quantity: 177,
	},
	{
		garage_id: 174,
		part_id: 386,
		quantity: 341,
	},
	{
		garage_id: 174,
		part_id: 387,
		quantity: 350,
	},
	{
		garage_id: 174,
		part_id: 388,
		quantity: 6,
	},
	{
		garage_id: 174,
		part_id: 389,
		quantity: 463,
	},
	{
		garage_id: 174,
		part_id: 390,
		quantity: 81,
	},
	{
		garage_id: 174,
		part_id: 391,
		quantity: 330,
	},
	{
		garage_id: 174,
		part_id: 392,
		quantity: 270,
	},
	{
		garage_id: 174,
		part_id: 393,
		quantity: 74,
	},
	{
		garage_id: 174,
		part_id: 394,
		quantity: 448,
	},
	{
		garage_id: 174,
		part_id: 395,
		quantity: 304,
	},
	{
		garage_id: 174,
		part_id: 396,
		quantity: 382,
	},
	{
		garage_id: 174,
		part_id: 397,
		quantity: 480,
	},
	{
		garage_id: 174,
		part_id: 398,
		quantity: 16,
	},
	{
		garage_id: 174,
		part_id: 399,
		quantity: 253,
	},
	{
		garage_id: 175,
		part_id: 0,
		quantity: 370,
	},
	{
		garage_id: 175,
		part_id: 1,
		quantity: 54,
	},
	{
		garage_id: 175,
		part_id: 2,
		quantity: 448,
	},
	{
		garage_id: 175,
		part_id: 3,
		quantity: 92,
	},
	{
		garage_id: 175,
		part_id: 4,
		quantity: 45,
	},
	{
		garage_id: 175,
		part_id: 5,
		quantity: 45,
	},
	{
		garage_id: 175,
		part_id: 6,
		quantity: 208,
	},
	{
		garage_id: 175,
		part_id: 7,
		quantity: 411,
	},
	{
		garage_id: 175,
		part_id: 8,
		quantity: 378,
	},
	{
		garage_id: 175,
		part_id: 9,
		quantity: 51,
	},
	{
		garage_id: 175,
		part_id: 10,
		quantity: 473,
	},
	{
		garage_id: 175,
		part_id: 11,
		quantity: 409,
	},
	{
		garage_id: 175,
		part_id: 12,
		quantity: 23,
	},
	{
		garage_id: 175,
		part_id: 13,
		quantity: 249,
	},
	{
		garage_id: 175,
		part_id: 14,
		quantity: 107,
	},
	{
		garage_id: 175,
		part_id: 15,
		quantity: 183,
	},
	{
		garage_id: 175,
		part_id: 16,
		quantity: 408,
	},
	{
		garage_id: 175,
		part_id: 17,
		quantity: 87,
	},
	{
		garage_id: 175,
		part_id: 18,
		quantity: 104,
	},
	{
		garage_id: 175,
		part_id: 19,
		quantity: 430,
	},
	{
		garage_id: 175,
		part_id: 20,
		quantity: 446,
	},
	{
		garage_id: 175,
		part_id: 21,
		quantity: 61,
	},
	{
		garage_id: 175,
		part_id: 22,
		quantity: 312,
	},
	{
		garage_id: 175,
		part_id: 23,
		quantity: 227,
	},
	{
		garage_id: 175,
		part_id: 24,
		quantity: 223,
	},
	{
		garage_id: 175,
		part_id: 25,
		quantity: 308,
	},
	{
		garage_id: 175,
		part_id: 26,
		quantity: 128,
	},
	{
		garage_id: 175,
		part_id: 27,
		quantity: 148,
	},
	{
		garage_id: 175,
		part_id: 28,
		quantity: 449,
	},
	{
		garage_id: 175,
		part_id: 29,
		quantity: 477,
	},
	{
		garage_id: 175,
		part_id: 30,
		quantity: 410,
	},
	{
		garage_id: 175,
		part_id: 31,
		quantity: 325,
	},
	{
		garage_id: 175,
		part_id: 32,
		quantity: 269,
	},
	{
		garage_id: 175,
		part_id: 33,
		quantity: 294,
	},
	{
		garage_id: 175,
		part_id: 34,
		quantity: 458,
	},
	{
		garage_id: 175,
		part_id: 35,
		quantity: 37,
	},
	{
		garage_id: 175,
		part_id: 36,
		quantity: 300,
	},
	{
		garage_id: 175,
		part_id: 37,
		quantity: 402,
	},
	{
		garage_id: 175,
		part_id: 38,
		quantity: 226,
	},
	{
		garage_id: 175,
		part_id: 39,
		quantity: 273,
	},
	{
		garage_id: 175,
		part_id: 40,
		quantity: 451,
	},
	{
		garage_id: 175,
		part_id: 41,
		quantity: 484,
	},
	{
		garage_id: 175,
		part_id: 42,
		quantity: 242,
	},
	{
		garage_id: 175,
		part_id: 43,
		quantity: 337,
	},
	{
		garage_id: 175,
		part_id: 44,
		quantity: 416,
	},
	{
		garage_id: 175,
		part_id: 45,
		quantity: 176,
	},
	{
		garage_id: 175,
		part_id: 46,
		quantity: 350,
	},
	{
		garage_id: 175,
		part_id: 47,
		quantity: 168,
	},
	{
		garage_id: 175,
		part_id: 48,
		quantity: 176,
	},
	{
		garage_id: 175,
		part_id: 49,
		quantity: 55,
	},
	{
		garage_id: 175,
		part_id: 50,
		quantity: 187,
	},
	{
		garage_id: 175,
		part_id: 51,
		quantity: 446,
	},
	{
		garage_id: 175,
		part_id: 52,
		quantity: 181,
	},
	{
		garage_id: 175,
		part_id: 53,
		quantity: 44,
	},
	{
		garage_id: 175,
		part_id: 54,
		quantity: 23,
	},
	{
		garage_id: 175,
		part_id: 55,
		quantity: 454,
	},
	{
		garage_id: 175,
		part_id: 56,
		quantity: 193,
	},
	{
		garage_id: 175,
		part_id: 57,
		quantity: 407,
	},
	{
		garage_id: 175,
		part_id: 58,
		quantity: 439,
	},
	{
		garage_id: 175,
		part_id: 59,
		quantity: 235,
	},
	{
		garage_id: 175,
		part_id: 60,
		quantity: 215,
	},
	{
		garage_id: 175,
		part_id: 61,
		quantity: 4,
	},
	{
		garage_id: 175,
		part_id: 62,
		quantity: 218,
	},
	{
		garage_id: 175,
		part_id: 63,
		quantity: 343,
	},
	{
		garage_id: 175,
		part_id: 64,
		quantity: 311,
	},
	{
		garage_id: 175,
		part_id: 65,
		quantity: 492,
	},
	{
		garage_id: 175,
		part_id: 66,
		quantity: 63,
	},
	{
		garage_id: 175,
		part_id: 67,
		quantity: 71,
	},
	{
		garage_id: 175,
		part_id: 68,
		quantity: 122,
	},
	{
		garage_id: 175,
		part_id: 69,
		quantity: 313,
	},
	{
		garage_id: 175,
		part_id: 70,
		quantity: 63,
	},
	{
		garage_id: 175,
		part_id: 71,
		quantity: 445,
	},
	{
		garage_id: 175,
		part_id: 72,
		quantity: 250,
	},
	{
		garage_id: 175,
		part_id: 73,
		quantity: 101,
	},
	{
		garage_id: 175,
		part_id: 74,
		quantity: 239,
	},
	{
		garage_id: 175,
		part_id: 75,
		quantity: 418,
	},
	{
		garage_id: 175,
		part_id: 76,
		quantity: 445,
	},
	{
		garage_id: 175,
		part_id: 77,
		quantity: 212,
	},
	{
		garage_id: 175,
		part_id: 78,
		quantity: 1,
	},
	{
		garage_id: 175,
		part_id: 79,
		quantity: 241,
	},
	{
		garage_id: 175,
		part_id: 80,
		quantity: 240,
	},
	{
		garage_id: 175,
		part_id: 81,
		quantity: 415,
	},
	{
		garage_id: 175,
		part_id: 82,
		quantity: 347,
	},
	{
		garage_id: 175,
		part_id: 83,
		quantity: 222,
	},
	{
		garage_id: 175,
		part_id: 84,
		quantity: 133,
	},
	{
		garage_id: 175,
		part_id: 85,
		quantity: 194,
	},
	{
		garage_id: 175,
		part_id: 86,
		quantity: 247,
	},
	{
		garage_id: 175,
		part_id: 87,
		quantity: 251,
	},
	{
		garage_id: 175,
		part_id: 88,
		quantity: 231,
	},
	{
		garage_id: 175,
		part_id: 89,
		quantity: 437,
	},
	{
		garage_id: 175,
		part_id: 90,
		quantity: 155,
	},
	{
		garage_id: 175,
		part_id: 91,
		quantity: 430,
	},
	{
		garage_id: 175,
		part_id: 92,
		quantity: 393,
	},
	{
		garage_id: 175,
		part_id: 93,
		quantity: 27,
	},
	{
		garage_id: 175,
		part_id: 94,
		quantity: 325,
	},
	{
		garage_id: 175,
		part_id: 95,
		quantity: 254,
	},
	{
		garage_id: 175,
		part_id: 96,
		quantity: 91,
	},
	{
		garage_id: 175,
		part_id: 97,
		quantity: 130,
	},
	{
		garage_id: 175,
		part_id: 98,
		quantity: 157,
	},
	{
		garage_id: 175,
		part_id: 99,
		quantity: 483,
	},
	{
		garage_id: 175,
		part_id: 100,
		quantity: 286,
	},
	{
		garage_id: 175,
		part_id: 101,
		quantity: 498,
	},
	{
		garage_id: 175,
		part_id: 102,
		quantity: 438,
	},
	{
		garage_id: 175,
		part_id: 103,
		quantity: 261,
	},
	{
		garage_id: 175,
		part_id: 104,
		quantity: 417,
	},
	{
		garage_id: 175,
		part_id: 105,
		quantity: 3,
	},
	{
		garage_id: 175,
		part_id: 106,
		quantity: 494,
	},
	{
		garage_id: 175,
		part_id: 107,
		quantity: 76,
	},
	{
		garage_id: 175,
		part_id: 108,
		quantity: 239,
	},
	{
		garage_id: 175,
		part_id: 109,
		quantity: 22,
	},
	{
		garage_id: 175,
		part_id: 110,
		quantity: 280,
	},
	{
		garage_id: 175,
		part_id: 111,
		quantity: 247,
	},
	{
		garage_id: 175,
		part_id: 112,
		quantity: 202,
	},
	{
		garage_id: 175,
		part_id: 113,
		quantity: 384,
	},
	{
		garage_id: 175,
		part_id: 114,
		quantity: 351,
	},
	{
		garage_id: 175,
		part_id: 115,
		quantity: 261,
	},
	{
		garage_id: 175,
		part_id: 116,
		quantity: 400,
	},
	{
		garage_id: 175,
		part_id: 117,
		quantity: 236,
	},
	{
		garage_id: 175,
		part_id: 118,
		quantity: 55,
	},
	{
		garage_id: 175,
		part_id: 119,
		quantity: 273,
	},
	{
		garage_id: 175,
		part_id: 120,
		quantity: 119,
	},
	{
		garage_id: 175,
		part_id: 121,
		quantity: 294,
	},
	{
		garage_id: 175,
		part_id: 122,
		quantity: 292,
	},
	{
		garage_id: 175,
		part_id: 123,
		quantity: 44,
	},
	{
		garage_id: 175,
		part_id: 124,
		quantity: 17,
	},
	{
		garage_id: 175,
		part_id: 125,
		quantity: 497,
	},
	{
		garage_id: 175,
		part_id: 126,
		quantity: 335,
	},
	{
		garage_id: 175,
		part_id: 127,
		quantity: 478,
	},
	{
		garage_id: 175,
		part_id: 128,
		quantity: 137,
	},
	{
		garage_id: 175,
		part_id: 129,
		quantity: 416,
	},
	{
		garage_id: 175,
		part_id: 130,
		quantity: 418,
	},
	{
		garage_id: 175,
		part_id: 131,
		quantity: 101,
	},
	{
		garage_id: 175,
		part_id: 132,
		quantity: 122,
	},
	{
		garage_id: 175,
		part_id: 133,
		quantity: 460,
	},
	{
		garage_id: 175,
		part_id: 134,
		quantity: 489,
	},
	{
		garage_id: 175,
		part_id: 135,
		quantity: 431,
	},
	{
		garage_id: 175,
		part_id: 136,
		quantity: 479,
	},
	{
		garage_id: 175,
		part_id: 137,
		quantity: 18,
	},
	{
		garage_id: 175,
		part_id: 138,
		quantity: 110,
	},
	{
		garage_id: 175,
		part_id: 139,
		quantity: 61,
	},
	{
		garage_id: 175,
		part_id: 140,
		quantity: 362,
	},
	{
		garage_id: 175,
		part_id: 141,
		quantity: 250,
	},
	{
		garage_id: 175,
		part_id: 142,
		quantity: 217,
	},
	{
		garage_id: 175,
		part_id: 143,
		quantity: 42,
	},
	{
		garage_id: 175,
		part_id: 144,
		quantity: 348,
	},
	{
		garage_id: 175,
		part_id: 145,
		quantity: 19,
	},
	{
		garage_id: 175,
		part_id: 146,
		quantity: 86,
	},
	{
		garage_id: 175,
		part_id: 147,
		quantity: 86,
	},
	{
		garage_id: 175,
		part_id: 148,
		quantity: 276,
	},
	{
		garage_id: 175,
		part_id: 149,
		quantity: 107,
	},
	{
		garage_id: 175,
		part_id: 150,
		quantity: 281,
	},
	{
		garage_id: 175,
		part_id: 151,
		quantity: 151,
	},
	{
		garage_id: 175,
		part_id: 152,
		quantity: 321,
	},
	{
		garage_id: 175,
		part_id: 153,
		quantity: 459,
	},
	{
		garage_id: 175,
		part_id: 154,
		quantity: 293,
	},
	{
		garage_id: 175,
		part_id: 155,
		quantity: 279,
	},
	{
		garage_id: 175,
		part_id: 156,
		quantity: 109,
	},
	{
		garage_id: 175,
		part_id: 157,
		quantity: 271,
	},
	{
		garage_id: 175,
		part_id: 158,
		quantity: 162,
	},
	{
		garage_id: 175,
		part_id: 159,
		quantity: 76,
	},
	{
		garage_id: 175,
		part_id: 160,
		quantity: 118,
	},
	{
		garage_id: 175,
		part_id: 161,
		quantity: 395,
	},
	{
		garage_id: 175,
		part_id: 162,
		quantity: 151,
	},
	{
		garage_id: 175,
		part_id: 163,
		quantity: 320,
	},
	{
		garage_id: 175,
		part_id: 164,
		quantity: 113,
	},
	{
		garage_id: 175,
		part_id: 165,
		quantity: 402,
	},
	{
		garage_id: 175,
		part_id: 166,
		quantity: 275,
	},
	{
		garage_id: 175,
		part_id: 167,
		quantity: 189,
	},
	{
		garage_id: 175,
		part_id: 168,
		quantity: 192,
	},
	{
		garage_id: 175,
		part_id: 169,
		quantity: 360,
	},
	{
		garage_id: 175,
		part_id: 170,
		quantity: 64,
	},
	{
		garage_id: 175,
		part_id: 171,
		quantity: 382,
	},
	{
		garage_id: 175,
		part_id: 172,
		quantity: 304,
	},
	{
		garage_id: 175,
		part_id: 173,
		quantity: 305,
	},
	{
		garage_id: 175,
		part_id: 174,
		quantity: 192,
	},
	{
		garage_id: 175,
		part_id: 175,
		quantity: 276,
	},
	{
		garage_id: 175,
		part_id: 176,
		quantity: 416,
	},
	{
		garage_id: 175,
		part_id: 177,
		quantity: 269,
	},
	{
		garage_id: 175,
		part_id: 178,
		quantity: 202,
	},
	{
		garage_id: 175,
		part_id: 179,
		quantity: 104,
	},
	{
		garage_id: 175,
		part_id: 180,
		quantity: 136,
	},
	{
		garage_id: 175,
		part_id: 181,
		quantity: 216,
	},
	{
		garage_id: 175,
		part_id: 182,
		quantity: 45,
	},
	{
		garage_id: 175,
		part_id: 183,
		quantity: 130,
	},
	{
		garage_id: 175,
		part_id: 184,
		quantity: 174,
	},
	{
		garage_id: 175,
		part_id: 185,
		quantity: 97,
	},
	{
		garage_id: 175,
		part_id: 186,
		quantity: 351,
	},
	{
		garage_id: 175,
		part_id: 187,
		quantity: 24,
	},
	{
		garage_id: 175,
		part_id: 188,
		quantity: 466,
	},
	{
		garage_id: 175,
		part_id: 189,
		quantity: 70,
	},
	{
		garage_id: 175,
		part_id: 190,
		quantity: 488,
	},
	{
		garage_id: 175,
		part_id: 191,
		quantity: 124,
	},
	{
		garage_id: 175,
		part_id: 192,
		quantity: 397,
	},
	{
		garage_id: 175,
		part_id: 193,
		quantity: 381,
	},
	{
		garage_id: 175,
		part_id: 194,
		quantity: 158,
	},
	{
		garage_id: 175,
		part_id: 195,
		quantity: 180,
	},
	{
		garage_id: 175,
		part_id: 196,
		quantity: 249,
	},
	{
		garage_id: 175,
		part_id: 197,
		quantity: 233,
	},
	{
		garage_id: 175,
		part_id: 198,
		quantity: 136,
	},
	{
		garage_id: 175,
		part_id: 199,
		quantity: 407,
	},
	{
		garage_id: 175,
		part_id: 200,
		quantity: 254,
	},
	{
		garage_id: 175,
		part_id: 201,
		quantity: 225,
	},
	{
		garage_id: 175,
		part_id: 202,
		quantity: 147,
	},
	{
		garage_id: 175,
		part_id: 203,
		quantity: 88,
	},
	{
		garage_id: 175,
		part_id: 204,
		quantity: 132,
	},
	{
		garage_id: 175,
		part_id: 205,
		quantity: 351,
	},
	{
		garage_id: 175,
		part_id: 206,
		quantity: 490,
	},
	{
		garage_id: 175,
		part_id: 207,
		quantity: 179,
	},
	{
		garage_id: 175,
		part_id: 208,
		quantity: 16,
	},
	{
		garage_id: 175,
		part_id: 209,
		quantity: 205,
	},
	{
		garage_id: 175,
		part_id: 210,
		quantity: 294,
	},
	{
		garage_id: 175,
		part_id: 211,
		quantity: 239,
	},
	{
		garage_id: 175,
		part_id: 212,
		quantity: 38,
	},
	{
		garage_id: 175,
		part_id: 213,
		quantity: 248,
	},
	{
		garage_id: 175,
		part_id: 214,
		quantity: 475,
	},
	{
		garage_id: 175,
		part_id: 215,
		quantity: 39,
	},
	{
		garage_id: 175,
		part_id: 216,
		quantity: 309,
	},
	{
		garage_id: 175,
		part_id: 217,
		quantity: 426,
	},
	{
		garage_id: 175,
		part_id: 218,
		quantity: 53,
	},
	{
		garage_id: 175,
		part_id: 219,
		quantity: 90,
	},
	{
		garage_id: 175,
		part_id: 220,
		quantity: 125,
	},
	{
		garage_id: 175,
		part_id: 221,
		quantity: 226,
	},
	{
		garage_id: 175,
		part_id: 222,
		quantity: 450,
	},
	{
		garage_id: 175,
		part_id: 223,
		quantity: 492,
	},
	{
		garage_id: 175,
		part_id: 224,
		quantity: 114,
	},
	{
		garage_id: 175,
		part_id: 225,
		quantity: 60,
	},
	{
		garage_id: 175,
		part_id: 226,
		quantity: 271,
	},
	{
		garage_id: 175,
		part_id: 227,
		quantity: 230,
	},
	{
		garage_id: 175,
		part_id: 228,
		quantity: 63,
	},
	{
		garage_id: 175,
		part_id: 229,
		quantity: 495,
	},
	{
		garage_id: 175,
		part_id: 230,
		quantity: 434,
	},
	{
		garage_id: 175,
		part_id: 231,
		quantity: 40,
	},
	{
		garage_id: 175,
		part_id: 232,
		quantity: 471,
	},
	{
		garage_id: 175,
		part_id: 233,
		quantity: 443,
	},
	{
		garage_id: 175,
		part_id: 234,
		quantity: 9,
	},
	{
		garage_id: 175,
		part_id: 235,
		quantity: 417,
	},
	{
		garage_id: 175,
		part_id: 236,
		quantity: 358,
	},
	{
		garage_id: 175,
		part_id: 237,
		quantity: 415,
	},
	{
		garage_id: 175,
		part_id: 238,
		quantity: 183,
	},
	{
		garage_id: 175,
		part_id: 239,
		quantity: 137,
	},
	{
		garage_id: 175,
		part_id: 240,
		quantity: 270,
	},
	{
		garage_id: 175,
		part_id: 241,
		quantity: 104,
	},
	{
		garage_id: 175,
		part_id: 242,
		quantity: 42,
	},
	{
		garage_id: 175,
		part_id: 243,
		quantity: 368,
	},
	{
		garage_id: 175,
		part_id: 244,
		quantity: 483,
	},
	{
		garage_id: 175,
		part_id: 245,
		quantity: 86,
	},
	{
		garage_id: 175,
		part_id: 246,
		quantity: 77,
	},
	{
		garage_id: 175,
		part_id: 247,
		quantity: 288,
	},
	{
		garage_id: 175,
		part_id: 248,
		quantity: 418,
	},
	{
		garage_id: 175,
		part_id: 249,
		quantity: 229,
	},
	{
		garage_id: 175,
		part_id: 250,
		quantity: 451,
	},
	{
		garage_id: 175,
		part_id: 251,
		quantity: 245,
	},
	{
		garage_id: 175,
		part_id: 252,
		quantity: 285,
	},
	{
		garage_id: 175,
		part_id: 253,
		quantity: 269,
	},
	{
		garage_id: 175,
		part_id: 254,
		quantity: 36,
	},
	{
		garage_id: 175,
		part_id: 255,
		quantity: 191,
	},
	{
		garage_id: 175,
		part_id: 256,
		quantity: 352,
	},
	{
		garage_id: 175,
		part_id: 257,
		quantity: 264,
	},
	{
		garage_id: 175,
		part_id: 258,
		quantity: 290,
	},
	{
		garage_id: 175,
		part_id: 259,
		quantity: 382,
	},
	{
		garage_id: 175,
		part_id: 260,
		quantity: 278,
	},
	{
		garage_id: 175,
		part_id: 261,
		quantity: 423,
	},
	{
		garage_id: 175,
		part_id: 262,
		quantity: 7,
	},
	{
		garage_id: 175,
		part_id: 263,
		quantity: 492,
	},
	{
		garage_id: 175,
		part_id: 264,
		quantity: 290,
	},
	{
		garage_id: 175,
		part_id: 265,
		quantity: 193,
	},
	{
		garage_id: 175,
		part_id: 266,
		quantity: 110,
	},
	{
		garage_id: 175,
		part_id: 267,
		quantity: 388,
	},
	{
		garage_id: 175,
		part_id: 268,
		quantity: 468,
	},
	{
		garage_id: 175,
		part_id: 269,
		quantity: 118,
	},
	{
		garage_id: 175,
		part_id: 270,
		quantity: 348,
	},
	{
		garage_id: 175,
		part_id: 271,
		quantity: 334,
	},
	{
		garage_id: 175,
		part_id: 272,
		quantity: 266,
	},
	{
		garage_id: 175,
		part_id: 273,
		quantity: 299,
	},
	{
		garage_id: 175,
		part_id: 274,
		quantity: 496,
	},
	{
		garage_id: 175,
		part_id: 275,
		quantity: 305,
	},
	{
		garage_id: 175,
		part_id: 276,
		quantity: 430,
	},
	{
		garage_id: 175,
		part_id: 277,
		quantity: 289,
	},
	{
		garage_id: 175,
		part_id: 278,
		quantity: 316,
	},
	{
		garage_id: 175,
		part_id: 279,
		quantity: 189,
	},
	{
		garage_id: 175,
		part_id: 280,
		quantity: 290,
	},
	{
		garage_id: 175,
		part_id: 281,
		quantity: 373,
	},
	{
		garage_id: 175,
		part_id: 282,
		quantity: 177,
	},
	{
		garage_id: 175,
		part_id: 283,
		quantity: 336,
	},
	{
		garage_id: 175,
		part_id: 284,
		quantity: 123,
	},
	{
		garage_id: 175,
		part_id: 285,
		quantity: 499,
	},
	{
		garage_id: 175,
		part_id: 286,
		quantity: 427,
	},
	{
		garage_id: 175,
		part_id: 287,
		quantity: 439,
	},
	{
		garage_id: 175,
		part_id: 288,
		quantity: 259,
	},
	{
		garage_id: 175,
		part_id: 289,
		quantity: 125,
	},
	{
		garage_id: 175,
		part_id: 290,
		quantity: 178,
	},
	{
		garage_id: 175,
		part_id: 291,
		quantity: 121,
	},
	{
		garage_id: 175,
		part_id: 292,
		quantity: 492,
	},
	{
		garage_id: 175,
		part_id: 293,
		quantity: 33,
	},
	{
		garage_id: 175,
		part_id: 294,
		quantity: 450,
	},
	{
		garage_id: 175,
		part_id: 295,
		quantity: 32,
	},
	{
		garage_id: 175,
		part_id: 296,
		quantity: 389,
	},
	{
		garage_id: 175,
		part_id: 297,
		quantity: 100,
	},
	{
		garage_id: 175,
		part_id: 298,
		quantity: 135,
	},
	{
		garage_id: 175,
		part_id: 299,
		quantity: 1,
	},
	{
		garage_id: 175,
		part_id: 300,
		quantity: 224,
	},
	{
		garage_id: 175,
		part_id: 301,
		quantity: 223,
	},
	{
		garage_id: 175,
		part_id: 302,
		quantity: 370,
	},
	{
		garage_id: 175,
		part_id: 303,
		quantity: 427,
	},
	{
		garage_id: 175,
		part_id: 304,
		quantity: 460,
	},
	{
		garage_id: 175,
		part_id: 305,
		quantity: 38,
	},
	{
		garage_id: 175,
		part_id: 306,
		quantity: 438,
	},
	{
		garage_id: 175,
		part_id: 307,
		quantity: 10,
	},
	{
		garage_id: 175,
		part_id: 308,
		quantity: 462,
	},
	{
		garage_id: 175,
		part_id: 309,
		quantity: 299,
	},
	{
		garage_id: 175,
		part_id: 310,
		quantity: 408,
	},
	{
		garage_id: 175,
		part_id: 311,
		quantity: 269,
	},
	{
		garage_id: 175,
		part_id: 312,
		quantity: 137,
	},
	{
		garage_id: 175,
		part_id: 313,
		quantity: 99,
	},
	{
		garage_id: 175,
		part_id: 314,
		quantity: 316,
	},
	{
		garage_id: 175,
		part_id: 315,
		quantity: 100,
	},
	{
		garage_id: 175,
		part_id: 316,
		quantity: 400,
	},
	{
		garage_id: 175,
		part_id: 317,
		quantity: 94,
	},
	{
		garage_id: 175,
		part_id: 318,
		quantity: 265,
	},
	{
		garage_id: 175,
		part_id: 319,
		quantity: 227,
	},
	{
		garage_id: 175,
		part_id: 320,
		quantity: 458,
	},
	{
		garage_id: 175,
		part_id: 321,
		quantity: 389,
	},
	{
		garage_id: 175,
		part_id: 322,
		quantity: 456,
	},
	{
		garage_id: 175,
		part_id: 323,
		quantity: 109,
	},
	{
		garage_id: 175,
		part_id: 324,
		quantity: 461,
	},
	{
		garage_id: 175,
		part_id: 325,
		quantity: 351,
	},
	{
		garage_id: 175,
		part_id: 326,
		quantity: 10,
	},
	{
		garage_id: 175,
		part_id: 327,
		quantity: 419,
	},
	{
		garage_id: 175,
		part_id: 328,
		quantity: 192,
	},
	{
		garage_id: 175,
		part_id: 329,
		quantity: 382,
	},
	{
		garage_id: 175,
		part_id: 330,
		quantity: 425,
	},
	{
		garage_id: 175,
		part_id: 331,
		quantity: 125,
	},
	{
		garage_id: 175,
		part_id: 332,
		quantity: 130,
	},
	{
		garage_id: 175,
		part_id: 333,
		quantity: 480,
	},
	{
		garage_id: 175,
		part_id: 334,
		quantity: 414,
	},
	{
		garage_id: 175,
		part_id: 335,
		quantity: 423,
	},
	{
		garage_id: 175,
		part_id: 336,
		quantity: 355,
	},
	{
		garage_id: 175,
		part_id: 337,
		quantity: 359,
	},
	{
		garage_id: 175,
		part_id: 338,
		quantity: 107,
	},
	{
		garage_id: 175,
		part_id: 339,
		quantity: 436,
	},
	{
		garage_id: 175,
		part_id: 340,
		quantity: 178,
	},
	{
		garage_id: 175,
		part_id: 341,
		quantity: 125,
	},
	{
		garage_id: 175,
		part_id: 342,
		quantity: 497,
	},
	{
		garage_id: 175,
		part_id: 343,
		quantity: 112,
	},
	{
		garage_id: 175,
		part_id: 344,
		quantity: 346,
	},
	{
		garage_id: 175,
		part_id: 345,
		quantity: 13,
	},
	{
		garage_id: 175,
		part_id: 346,
		quantity: 95,
	},
	{
		garage_id: 175,
		part_id: 347,
		quantity: 421,
	},
	{
		garage_id: 175,
		part_id: 348,
		quantity: 21,
	},
	{
		garage_id: 175,
		part_id: 349,
		quantity: 468,
	},
	{
		garage_id: 175,
		part_id: 350,
		quantity: 164,
	},
	{
		garage_id: 175,
		part_id: 351,
		quantity: 220,
	},
	{
		garage_id: 175,
		part_id: 352,
		quantity: 465,
	},
	{
		garage_id: 175,
		part_id: 353,
		quantity: 273,
	},
	{
		garage_id: 175,
		part_id: 354,
		quantity: 204,
	},
	{
		garage_id: 175,
		part_id: 355,
		quantity: 417,
	},
	{
		garage_id: 175,
		part_id: 356,
		quantity: 59,
	},
	{
		garage_id: 175,
		part_id: 357,
		quantity: 199,
	},
	{
		garage_id: 175,
		part_id: 358,
		quantity: 390,
	},
	{
		garage_id: 175,
		part_id: 359,
		quantity: 152,
	},
	{
		garage_id: 175,
		part_id: 360,
		quantity: 381,
	},
	{
		garage_id: 175,
		part_id: 361,
		quantity: 141,
	},
	{
		garage_id: 175,
		part_id: 362,
		quantity: 442,
	},
	{
		garage_id: 175,
		part_id: 363,
		quantity: 353,
	},
	{
		garage_id: 175,
		part_id: 364,
		quantity: 351,
	},
	{
		garage_id: 175,
		part_id: 365,
		quantity: 184,
	},
	{
		garage_id: 175,
		part_id: 366,
		quantity: 18,
	},
	{
		garage_id: 175,
		part_id: 367,
		quantity: 261,
	},
	{
		garage_id: 175,
		part_id: 368,
		quantity: 32,
	},
	{
		garage_id: 175,
		part_id: 369,
		quantity: 57,
	},
	{
		garage_id: 175,
		part_id: 370,
		quantity: 406,
	},
	{
		garage_id: 175,
		part_id: 371,
		quantity: 458,
	},
	{
		garage_id: 175,
		part_id: 372,
		quantity: 214,
	},
	{
		garage_id: 175,
		part_id: 373,
		quantity: 81,
	},
	{
		garage_id: 175,
		part_id: 374,
		quantity: 232,
	},
	{
		garage_id: 175,
		part_id: 375,
		quantity: 182,
	},
	{
		garage_id: 175,
		part_id: 376,
		quantity: 442,
	},
	{
		garage_id: 175,
		part_id: 377,
		quantity: 391,
	},
	{
		garage_id: 175,
		part_id: 378,
		quantity: 293,
	},
	{
		garage_id: 175,
		part_id: 379,
		quantity: 240,
	},
	{
		garage_id: 175,
		part_id: 380,
		quantity: 65,
	},
	{
		garage_id: 175,
		part_id: 381,
		quantity: 481,
	},
	{
		garage_id: 175,
		part_id: 382,
		quantity: 264,
	},
	{
		garage_id: 175,
		part_id: 383,
		quantity: 2,
	},
	{
		garage_id: 175,
		part_id: 384,
		quantity: 270,
	},
	{
		garage_id: 175,
		part_id: 385,
		quantity: 40,
	},
	{
		garage_id: 175,
		part_id: 386,
		quantity: 368,
	},
	{
		garage_id: 175,
		part_id: 387,
		quantity: 339,
	},
	{
		garage_id: 175,
		part_id: 388,
		quantity: 145,
	},
	{
		garage_id: 175,
		part_id: 389,
		quantity: 12,
	},
	{
		garage_id: 175,
		part_id: 390,
		quantity: 403,
	},
	{
		garage_id: 175,
		part_id: 391,
		quantity: 105,
	},
	{
		garage_id: 175,
		part_id: 392,
		quantity: 401,
	},
	{
		garage_id: 175,
		part_id: 393,
		quantity: 114,
	},
	{
		garage_id: 175,
		part_id: 394,
		quantity: 149,
	},
	{
		garage_id: 175,
		part_id: 395,
		quantity: 130,
	},
	{
		garage_id: 175,
		part_id: 396,
		quantity: 41,
	},
	{
		garage_id: 175,
		part_id: 397,
		quantity: 274,
	},
	{
		garage_id: 175,
		part_id: 398,
		quantity: 202,
	},
	{
		garage_id: 175,
		part_id: 399,
		quantity: 15,
	},
	{
		garage_id: 176,
		part_id: 0,
		quantity: 413,
	},
	{
		garage_id: 176,
		part_id: 1,
		quantity: 476,
	},
	{
		garage_id: 176,
		part_id: 2,
		quantity: 236,
	},
	{
		garage_id: 176,
		part_id: 3,
		quantity: 363,
	},
	{
		garage_id: 176,
		part_id: 4,
		quantity: 148,
	},
	{
		garage_id: 176,
		part_id: 5,
		quantity: 442,
	},
	{
		garage_id: 176,
		part_id: 6,
		quantity: 274,
	},
	{
		garage_id: 176,
		part_id: 7,
		quantity: 211,
	},
	{
		garage_id: 176,
		part_id: 8,
		quantity: 116,
	},
	{
		garage_id: 176,
		part_id: 9,
		quantity: 343,
	},
	{
		garage_id: 176,
		part_id: 10,
		quantity: 331,
	},
	{
		garage_id: 176,
		part_id: 11,
		quantity: 213,
	},
	{
		garage_id: 176,
		part_id: 12,
		quantity: 347,
	},
	{
		garage_id: 176,
		part_id: 13,
		quantity: 239,
	},
	{
		garage_id: 176,
		part_id: 14,
		quantity: 450,
	},
	{
		garage_id: 176,
		part_id: 15,
		quantity: 69,
	},
	{
		garage_id: 176,
		part_id: 16,
		quantity: 138,
	},
	{
		garage_id: 176,
		part_id: 17,
		quantity: 22,
	},
	{
		garage_id: 176,
		part_id: 18,
		quantity: 116,
	},
	{
		garage_id: 176,
		part_id: 19,
		quantity: 184,
	},
	{
		garage_id: 176,
		part_id: 20,
		quantity: 115,
	},
	{
		garage_id: 176,
		part_id: 21,
		quantity: 85,
	},
	{
		garage_id: 176,
		part_id: 22,
		quantity: 67,
	},
	{
		garage_id: 176,
		part_id: 23,
		quantity: 90,
	},
	{
		garage_id: 176,
		part_id: 24,
		quantity: 176,
	},
	{
		garage_id: 176,
		part_id: 25,
		quantity: 488,
	},
	{
		garage_id: 176,
		part_id: 26,
		quantity: 345,
	},
	{
		garage_id: 176,
		part_id: 27,
		quantity: 429,
	},
	{
		garage_id: 176,
		part_id: 28,
		quantity: 71,
	},
	{
		garage_id: 176,
		part_id: 29,
		quantity: 355,
	},
	{
		garage_id: 176,
		part_id: 30,
		quantity: 110,
	},
	{
		garage_id: 176,
		part_id: 31,
		quantity: 350,
	},
	{
		garage_id: 176,
		part_id: 32,
		quantity: 366,
	},
	{
		garage_id: 176,
		part_id: 33,
		quantity: 414,
	},
	{
		garage_id: 176,
		part_id: 34,
		quantity: 5,
	},
	{
		garage_id: 176,
		part_id: 35,
		quantity: 499,
	},
	{
		garage_id: 176,
		part_id: 36,
		quantity: 114,
	},
	{
		garage_id: 176,
		part_id: 37,
		quantity: 182,
	},
	{
		garage_id: 176,
		part_id: 38,
		quantity: 38,
	},
	{
		garage_id: 176,
		part_id: 39,
		quantity: 82,
	},
	{
		garage_id: 176,
		part_id: 40,
		quantity: 401,
	},
	{
		garage_id: 176,
		part_id: 41,
		quantity: 29,
	},
	{
		garage_id: 176,
		part_id: 42,
		quantity: 480,
	},
	{
		garage_id: 176,
		part_id: 43,
		quantity: 106,
	},
	{
		garage_id: 176,
		part_id: 44,
		quantity: 239,
	},
	{
		garage_id: 176,
		part_id: 45,
		quantity: 145,
	},
	{
		garage_id: 176,
		part_id: 46,
		quantity: 467,
	},
	{
		garage_id: 176,
		part_id: 47,
		quantity: 190,
	},
	{
		garage_id: 176,
		part_id: 48,
		quantity: 90,
	},
	{
		garage_id: 176,
		part_id: 49,
		quantity: 415,
	},
	{
		garage_id: 176,
		part_id: 50,
		quantity: 129,
	},
	{
		garage_id: 176,
		part_id: 51,
		quantity: 41,
	},
	{
		garage_id: 176,
		part_id: 52,
		quantity: 87,
	},
	{
		garage_id: 176,
		part_id: 53,
		quantity: 472,
	},
	{
		garage_id: 176,
		part_id: 54,
		quantity: 404,
	},
	{
		garage_id: 176,
		part_id: 55,
		quantity: 31,
	},
	{
		garage_id: 176,
		part_id: 56,
		quantity: 478,
	},
	{
		garage_id: 176,
		part_id: 57,
		quantity: 11,
	},
	{
		garage_id: 176,
		part_id: 58,
		quantity: 441,
	},
	{
		garage_id: 176,
		part_id: 59,
		quantity: 123,
	},
	{
		garage_id: 176,
		part_id: 60,
		quantity: 409,
	},
	{
		garage_id: 176,
		part_id: 61,
		quantity: 290,
	},
	{
		garage_id: 176,
		part_id: 62,
		quantity: 148,
	},
	{
		garage_id: 176,
		part_id: 63,
		quantity: 347,
	},
	{
		garage_id: 176,
		part_id: 64,
		quantity: 312,
	},
	{
		garage_id: 176,
		part_id: 65,
		quantity: 2,
	},
	{
		garage_id: 176,
		part_id: 66,
		quantity: 398,
	},
	{
		garage_id: 176,
		part_id: 67,
		quantity: 144,
	},
	{
		garage_id: 176,
		part_id: 68,
		quantity: 374,
	},
	{
		garage_id: 176,
		part_id: 69,
		quantity: 141,
	},
	{
		garage_id: 176,
		part_id: 70,
		quantity: 174,
	},
	{
		garage_id: 176,
		part_id: 71,
		quantity: 362,
	},
	{
		garage_id: 176,
		part_id: 72,
		quantity: 488,
	},
	{
		garage_id: 176,
		part_id: 73,
		quantity: 233,
	},
	{
		garage_id: 176,
		part_id: 74,
		quantity: 190,
	},
	{
		garage_id: 176,
		part_id: 75,
		quantity: 429,
	},
	{
		garage_id: 176,
		part_id: 76,
		quantity: 455,
	},
	{
		garage_id: 176,
		part_id: 77,
		quantity: 174,
	},
	{
		garage_id: 176,
		part_id: 78,
		quantity: 454,
	},
	{
		garage_id: 176,
		part_id: 79,
		quantity: 240,
	},
	{
		garage_id: 176,
		part_id: 80,
		quantity: 79,
	},
	{
		garage_id: 176,
		part_id: 81,
		quantity: 89,
	},
	{
		garage_id: 176,
		part_id: 82,
		quantity: 81,
	},
	{
		garage_id: 176,
		part_id: 83,
		quantity: 297,
	},
	{
		garage_id: 176,
		part_id: 84,
		quantity: 319,
	},
	{
		garage_id: 176,
		part_id: 85,
		quantity: 380,
	},
	{
		garage_id: 176,
		part_id: 86,
		quantity: 251,
	},
	{
		garage_id: 176,
		part_id: 87,
		quantity: 402,
	},
	{
		garage_id: 176,
		part_id: 88,
		quantity: 237,
	},
	{
		garage_id: 176,
		part_id: 89,
		quantity: 440,
	},
	{
		garage_id: 176,
		part_id: 90,
		quantity: 49,
	},
	{
		garage_id: 176,
		part_id: 91,
		quantity: 86,
	},
	{
		garage_id: 176,
		part_id: 92,
		quantity: 447,
	},
	{
		garage_id: 176,
		part_id: 93,
		quantity: 425,
	},
	{
		garage_id: 176,
		part_id: 94,
		quantity: 356,
	},
	{
		garage_id: 176,
		part_id: 95,
		quantity: 400,
	},
	{
		garage_id: 176,
		part_id: 96,
		quantity: 276,
	},
	{
		garage_id: 176,
		part_id: 97,
		quantity: 404,
	},
	{
		garage_id: 176,
		part_id: 98,
		quantity: 394,
	},
	{
		garage_id: 176,
		part_id: 99,
		quantity: 178,
	},
	{
		garage_id: 176,
		part_id: 100,
		quantity: 176,
	},
	{
		garage_id: 176,
		part_id: 101,
		quantity: 413,
	},
	{
		garage_id: 176,
		part_id: 102,
		quantity: 251,
	},
	{
		garage_id: 176,
		part_id: 103,
		quantity: 24,
	},
	{
		garage_id: 176,
		part_id: 104,
		quantity: 422,
	},
	{
		garage_id: 176,
		part_id: 105,
		quantity: 6,
	},
	{
		garage_id: 176,
		part_id: 106,
		quantity: 345,
	},
	{
		garage_id: 176,
		part_id: 107,
		quantity: 229,
	},
	{
		garage_id: 176,
		part_id: 108,
		quantity: 82,
	},
	{
		garage_id: 176,
		part_id: 109,
		quantity: 487,
	},
	{
		garage_id: 176,
		part_id: 110,
		quantity: 374,
	},
	{
		garage_id: 176,
		part_id: 111,
		quantity: 455,
	},
	{
		garage_id: 176,
		part_id: 112,
		quantity: 368,
	},
	{
		garage_id: 176,
		part_id: 113,
		quantity: 354,
	},
	{
		garage_id: 176,
		part_id: 114,
		quantity: 99,
	},
	{
		garage_id: 176,
		part_id: 115,
		quantity: 168,
	},
	{
		garage_id: 176,
		part_id: 116,
		quantity: 121,
	},
	{
		garage_id: 176,
		part_id: 117,
		quantity: 413,
	},
	{
		garage_id: 176,
		part_id: 118,
		quantity: 46,
	},
	{
		garage_id: 176,
		part_id: 119,
		quantity: 408,
	},
	{
		garage_id: 176,
		part_id: 120,
		quantity: 267,
	},
	{
		garage_id: 176,
		part_id: 121,
		quantity: 255,
	},
	{
		garage_id: 176,
		part_id: 122,
		quantity: 185,
	},
	{
		garage_id: 176,
		part_id: 123,
		quantity: 62,
	},
	{
		garage_id: 176,
		part_id: 124,
		quantity: 422,
	},
	{
		garage_id: 176,
		part_id: 125,
		quantity: 362,
	},
	{
		garage_id: 176,
		part_id: 126,
		quantity: 467,
	},
	{
		garage_id: 176,
		part_id: 127,
		quantity: 205,
	},
	{
		garage_id: 176,
		part_id: 128,
		quantity: 123,
	},
	{
		garage_id: 176,
		part_id: 129,
		quantity: 197,
	},
	{
		garage_id: 176,
		part_id: 130,
		quantity: 326,
	},
	{
		garage_id: 176,
		part_id: 131,
		quantity: 239,
	},
	{
		garage_id: 176,
		part_id: 132,
		quantity: 440,
	},
	{
		garage_id: 176,
		part_id: 133,
		quantity: 5,
	},
	{
		garage_id: 176,
		part_id: 134,
		quantity: 279,
	},
	{
		garage_id: 176,
		part_id: 135,
		quantity: 105,
	},
	{
		garage_id: 176,
		part_id: 136,
		quantity: 399,
	},
	{
		garage_id: 176,
		part_id: 137,
		quantity: 453,
	},
	{
		garage_id: 176,
		part_id: 138,
		quantity: 303,
	},
	{
		garage_id: 176,
		part_id: 139,
		quantity: 141,
	},
	{
		garage_id: 176,
		part_id: 140,
		quantity: 477,
	},
	{
		garage_id: 176,
		part_id: 141,
		quantity: 192,
	},
	{
		garage_id: 176,
		part_id: 142,
		quantity: 305,
	},
	{
		garage_id: 176,
		part_id: 143,
		quantity: 344,
	},
	{
		garage_id: 176,
		part_id: 144,
		quantity: 374,
	},
	{
		garage_id: 176,
		part_id: 145,
		quantity: 491,
	},
	{
		garage_id: 176,
		part_id: 146,
		quantity: 500,
	},
	{
		garage_id: 176,
		part_id: 147,
		quantity: 144,
	},
	{
		garage_id: 176,
		part_id: 148,
		quantity: 393,
	},
	{
		garage_id: 176,
		part_id: 149,
		quantity: 451,
	},
	{
		garage_id: 176,
		part_id: 150,
		quantity: 430,
	},
	{
		garage_id: 176,
		part_id: 151,
		quantity: 144,
	},
	{
		garage_id: 176,
		part_id: 152,
		quantity: 290,
	},
	{
		garage_id: 176,
		part_id: 153,
		quantity: 456,
	},
	{
		garage_id: 176,
		part_id: 154,
		quantity: 370,
	},
	{
		garage_id: 176,
		part_id: 155,
		quantity: 302,
	},
	{
		garage_id: 176,
		part_id: 156,
		quantity: 140,
	},
	{
		garage_id: 176,
		part_id: 157,
		quantity: 298,
	},
	{
		garage_id: 176,
		part_id: 158,
		quantity: 466,
	},
	{
		garage_id: 176,
		part_id: 159,
		quantity: 231,
	},
	{
		garage_id: 176,
		part_id: 160,
		quantity: 42,
	},
	{
		garage_id: 176,
		part_id: 161,
		quantity: 231,
	},
	{
		garage_id: 176,
		part_id: 162,
		quantity: 396,
	},
	{
		garage_id: 176,
		part_id: 163,
		quantity: 230,
	},
	{
		garage_id: 176,
		part_id: 164,
		quantity: 393,
	},
	{
		garage_id: 176,
		part_id: 165,
		quantity: 40,
	},
	{
		garage_id: 176,
		part_id: 166,
		quantity: 5,
	},
	{
		garage_id: 176,
		part_id: 167,
		quantity: 150,
	},
	{
		garage_id: 176,
		part_id: 168,
		quantity: 88,
	},
	{
		garage_id: 176,
		part_id: 169,
		quantity: 29,
	},
	{
		garage_id: 176,
		part_id: 170,
		quantity: 419,
	},
	{
		garage_id: 176,
		part_id: 171,
		quantity: 446,
	},
	{
		garage_id: 176,
		part_id: 172,
		quantity: 166,
	},
	{
		garage_id: 176,
		part_id: 173,
		quantity: 120,
	},
	{
		garage_id: 176,
		part_id: 174,
		quantity: 333,
	},
	{
		garage_id: 176,
		part_id: 175,
		quantity: 96,
	},
	{
		garage_id: 176,
		part_id: 176,
		quantity: 105,
	},
	{
		garage_id: 176,
		part_id: 177,
		quantity: 156,
	},
	{
		garage_id: 176,
		part_id: 178,
		quantity: 99,
	},
	{
		garage_id: 176,
		part_id: 179,
		quantity: 168,
	},
	{
		garage_id: 176,
		part_id: 180,
		quantity: 200,
	},
	{
		garage_id: 176,
		part_id: 181,
		quantity: 32,
	},
	{
		garage_id: 176,
		part_id: 182,
		quantity: 118,
	},
	{
		garage_id: 176,
		part_id: 183,
		quantity: 177,
	},
	{
		garage_id: 176,
		part_id: 184,
		quantity: 440,
	},
	{
		garage_id: 176,
		part_id: 185,
		quantity: 227,
	},
	{
		garage_id: 176,
		part_id: 186,
		quantity: 460,
	},
	{
		garage_id: 176,
		part_id: 187,
		quantity: 472,
	},
	{
		garage_id: 176,
		part_id: 188,
		quantity: 343,
	},
	{
		garage_id: 176,
		part_id: 189,
		quantity: 55,
	},
	{
		garage_id: 176,
		part_id: 190,
		quantity: 218,
	},
	{
		garage_id: 176,
		part_id: 191,
		quantity: 490,
	},
	{
		garage_id: 176,
		part_id: 192,
		quantity: 377,
	},
	{
		garage_id: 176,
		part_id: 193,
		quantity: 327,
	},
	{
		garage_id: 176,
		part_id: 194,
		quantity: 429,
	},
	{
		garage_id: 176,
		part_id: 195,
		quantity: 14,
	},
	{
		garage_id: 176,
		part_id: 196,
		quantity: 210,
	},
	{
		garage_id: 176,
		part_id: 197,
		quantity: 85,
	},
	{
		garage_id: 176,
		part_id: 198,
		quantity: 319,
	},
	{
		garage_id: 176,
		part_id: 199,
		quantity: 13,
	},
	{
		garage_id: 176,
		part_id: 200,
		quantity: 355,
	},
	{
		garage_id: 176,
		part_id: 201,
		quantity: 14,
	},
	{
		garage_id: 176,
		part_id: 202,
		quantity: 444,
	},
	{
		garage_id: 176,
		part_id: 203,
		quantity: 397,
	},
	{
		garage_id: 176,
		part_id: 204,
		quantity: 428,
	},
	{
		garage_id: 176,
		part_id: 205,
		quantity: 268,
	},
	{
		garage_id: 176,
		part_id: 206,
		quantity: 256,
	},
	{
		garage_id: 176,
		part_id: 207,
		quantity: 169,
	},
	{
		garage_id: 176,
		part_id: 208,
		quantity: 62,
	},
	{
		garage_id: 176,
		part_id: 209,
		quantity: 319,
	},
	{
		garage_id: 176,
		part_id: 210,
		quantity: 337,
	},
	{
		garage_id: 176,
		part_id: 211,
		quantity: 254,
	},
	{
		garage_id: 176,
		part_id: 212,
		quantity: 338,
	},
	{
		garage_id: 176,
		part_id: 213,
		quantity: 400,
	},
	{
		garage_id: 176,
		part_id: 214,
		quantity: 153,
	},
	{
		garage_id: 176,
		part_id: 215,
		quantity: 435,
	},
	{
		garage_id: 176,
		part_id: 216,
		quantity: 343,
	},
	{
		garage_id: 176,
		part_id: 217,
		quantity: 193,
	},
	{
		garage_id: 176,
		part_id: 218,
		quantity: 98,
	},
	{
		garage_id: 176,
		part_id: 219,
		quantity: 169,
	},
	{
		garage_id: 176,
		part_id: 220,
		quantity: 18,
	},
	{
		garage_id: 176,
		part_id: 221,
		quantity: 88,
	},
	{
		garage_id: 176,
		part_id: 222,
		quantity: 192,
	},
	{
		garage_id: 176,
		part_id: 223,
		quantity: 92,
	},
	{
		garage_id: 176,
		part_id: 224,
		quantity: 45,
	},
	{
		garage_id: 176,
		part_id: 225,
		quantity: 427,
	},
	{
		garage_id: 176,
		part_id: 226,
		quantity: 397,
	},
	{
		garage_id: 176,
		part_id: 227,
		quantity: 463,
	},
	{
		garage_id: 176,
		part_id: 228,
		quantity: 332,
	},
	{
		garage_id: 176,
		part_id: 229,
		quantity: 301,
	},
	{
		garage_id: 176,
		part_id: 230,
		quantity: 342,
	},
	{
		garage_id: 176,
		part_id: 231,
		quantity: 364,
	},
	{
		garage_id: 176,
		part_id: 232,
		quantity: 203,
	},
	{
		garage_id: 176,
		part_id: 233,
		quantity: 204,
	},
	{
		garage_id: 176,
		part_id: 234,
		quantity: 413,
	},
	{
		garage_id: 176,
		part_id: 235,
		quantity: 339,
	},
	{
		garage_id: 176,
		part_id: 236,
		quantity: 327,
	},
	{
		garage_id: 176,
		part_id: 237,
		quantity: 427,
	},
	{
		garage_id: 176,
		part_id: 238,
		quantity: 20,
	},
	{
		garage_id: 176,
		part_id: 239,
		quantity: 226,
	},
	{
		garage_id: 176,
		part_id: 240,
		quantity: 425,
	},
	{
		garage_id: 176,
		part_id: 241,
		quantity: 491,
	},
	{
		garage_id: 176,
		part_id: 242,
		quantity: 225,
	},
	{
		garage_id: 176,
		part_id: 243,
		quantity: 271,
	},
	{
		garage_id: 176,
		part_id: 244,
		quantity: 188,
	},
	{
		garage_id: 176,
		part_id: 245,
		quantity: 421,
	},
	{
		garage_id: 176,
		part_id: 246,
		quantity: 30,
	},
	{
		garage_id: 176,
		part_id: 247,
		quantity: 470,
	},
	{
		garage_id: 176,
		part_id: 248,
		quantity: 29,
	},
	{
		garage_id: 176,
		part_id: 249,
		quantity: 189,
	},
	{
		garage_id: 176,
		part_id: 250,
		quantity: 11,
	},
	{
		garage_id: 176,
		part_id: 251,
		quantity: 442,
	},
	{
		garage_id: 176,
		part_id: 252,
		quantity: 451,
	},
	{
		garage_id: 176,
		part_id: 253,
		quantity: 445,
	},
	{
		garage_id: 176,
		part_id: 254,
		quantity: 84,
	},
	{
		garage_id: 176,
		part_id: 255,
		quantity: 470,
	},
	{
		garage_id: 176,
		part_id: 256,
		quantity: 325,
	},
	{
		garage_id: 176,
		part_id: 257,
		quantity: 136,
	},
	{
		garage_id: 176,
		part_id: 258,
		quantity: 135,
	},
	{
		garage_id: 176,
		part_id: 259,
		quantity: 90,
	},
	{
		garage_id: 176,
		part_id: 260,
		quantity: 301,
	},
	{
		garage_id: 176,
		part_id: 261,
		quantity: 406,
	},
	{
		garage_id: 176,
		part_id: 262,
		quantity: 293,
	},
	{
		garage_id: 176,
		part_id: 263,
		quantity: 233,
	},
	{
		garage_id: 176,
		part_id: 264,
		quantity: 299,
	},
	{
		garage_id: 176,
		part_id: 265,
		quantity: 163,
	},
	{
		garage_id: 176,
		part_id: 266,
		quantity: 277,
	},
	{
		garage_id: 176,
		part_id: 267,
		quantity: 349,
	},
	{
		garage_id: 176,
		part_id: 268,
		quantity: 484,
	},
	{
		garage_id: 176,
		part_id: 269,
		quantity: 78,
	},
	{
		garage_id: 176,
		part_id: 270,
		quantity: 172,
	},
	{
		garage_id: 176,
		part_id: 271,
		quantity: 425,
	},
	{
		garage_id: 176,
		part_id: 272,
		quantity: 104,
	},
	{
		garage_id: 176,
		part_id: 273,
		quantity: 1,
	},
	{
		garage_id: 176,
		part_id: 274,
		quantity: 72,
	},
	{
		garage_id: 176,
		part_id: 275,
		quantity: 342,
	},
	{
		garage_id: 176,
		part_id: 276,
		quantity: 36,
	},
	{
		garage_id: 176,
		part_id: 277,
		quantity: 152,
	},
	{
		garage_id: 176,
		part_id: 278,
		quantity: 199,
	},
	{
		garage_id: 176,
		part_id: 279,
		quantity: 196,
	},
	{
		garage_id: 176,
		part_id: 280,
		quantity: 456,
	},
	{
		garage_id: 176,
		part_id: 281,
		quantity: 286,
	},
	{
		garage_id: 176,
		part_id: 282,
		quantity: 347,
	},
	{
		garage_id: 176,
		part_id: 283,
		quantity: 398,
	},
	{
		garage_id: 176,
		part_id: 284,
		quantity: 123,
	},
	{
		garage_id: 176,
		part_id: 285,
		quantity: 15,
	},
	{
		garage_id: 176,
		part_id: 286,
		quantity: 487,
	},
	{
		garage_id: 176,
		part_id: 287,
		quantity: 107,
	},
	{
		garage_id: 176,
		part_id: 288,
		quantity: 130,
	},
	{
		garage_id: 176,
		part_id: 289,
		quantity: 235,
	},
	{
		garage_id: 176,
		part_id: 290,
		quantity: 330,
	},
	{
		garage_id: 176,
		part_id: 291,
		quantity: 445,
	},
	{
		garage_id: 176,
		part_id: 292,
		quantity: 424,
	},
	{
		garage_id: 176,
		part_id: 293,
		quantity: 262,
	},
	{
		garage_id: 176,
		part_id: 294,
		quantity: 463,
	},
	{
		garage_id: 176,
		part_id: 295,
		quantity: 217,
	},
	{
		garage_id: 176,
		part_id: 296,
		quantity: 181,
	},
	{
		garage_id: 176,
		part_id: 297,
		quantity: 170,
	},
	{
		garage_id: 176,
		part_id: 298,
		quantity: 212,
	},
	{
		garage_id: 176,
		part_id: 299,
		quantity: 428,
	},
	{
		garage_id: 176,
		part_id: 300,
		quantity: 151,
	},
	{
		garage_id: 176,
		part_id: 301,
		quantity: 256,
	},
	{
		garage_id: 176,
		part_id: 302,
		quantity: 392,
	},
	{
		garage_id: 176,
		part_id: 303,
		quantity: 61,
	},
	{
		garage_id: 176,
		part_id: 304,
		quantity: 488,
	},
	{
		garage_id: 176,
		part_id: 305,
		quantity: 185,
	},
	{
		garage_id: 176,
		part_id: 306,
		quantity: 298,
	},
	{
		garage_id: 176,
		part_id: 307,
		quantity: 208,
	},
	{
		garage_id: 176,
		part_id: 308,
		quantity: 401,
	},
	{
		garage_id: 176,
		part_id: 309,
		quantity: 102,
	},
	{
		garage_id: 176,
		part_id: 310,
		quantity: 369,
	},
	{
		garage_id: 176,
		part_id: 311,
		quantity: 437,
	},
	{
		garage_id: 176,
		part_id: 312,
		quantity: 183,
	},
	{
		garage_id: 176,
		part_id: 313,
		quantity: 354,
	},
	{
		garage_id: 176,
		part_id: 314,
		quantity: 28,
	},
	{
		garage_id: 176,
		part_id: 315,
		quantity: 97,
	},
	{
		garage_id: 176,
		part_id: 316,
		quantity: 35,
	},
	{
		garage_id: 176,
		part_id: 317,
		quantity: 350,
	},
	{
		garage_id: 176,
		part_id: 318,
		quantity: 486,
	},
	{
		garage_id: 176,
		part_id: 319,
		quantity: 355,
	},
	{
		garage_id: 176,
		part_id: 320,
		quantity: 130,
	},
	{
		garage_id: 176,
		part_id: 321,
		quantity: 361,
	},
	{
		garage_id: 176,
		part_id: 322,
		quantity: 50,
	},
	{
		garage_id: 176,
		part_id: 323,
		quantity: 382,
	},
	{
		garage_id: 176,
		part_id: 324,
		quantity: 12,
	},
	{
		garage_id: 176,
		part_id: 325,
		quantity: 299,
	},
	{
		garage_id: 176,
		part_id: 326,
		quantity: 471,
	},
	{
		garage_id: 176,
		part_id: 327,
		quantity: 448,
	},
	{
		garage_id: 176,
		part_id: 328,
		quantity: 382,
	},
	{
		garage_id: 176,
		part_id: 329,
		quantity: 477,
	},
	{
		garage_id: 176,
		part_id: 330,
		quantity: 267,
	},
	{
		garage_id: 176,
		part_id: 331,
		quantity: 351,
	},
	{
		garage_id: 176,
		part_id: 332,
		quantity: 119,
	},
	{
		garage_id: 176,
		part_id: 333,
		quantity: 127,
	},
	{
		garage_id: 176,
		part_id: 334,
		quantity: 430,
	},
	{
		garage_id: 176,
		part_id: 335,
		quantity: 163,
	},
	{
		garage_id: 176,
		part_id: 336,
		quantity: 465,
	},
	{
		garage_id: 176,
		part_id: 337,
		quantity: 52,
	},
	{
		garage_id: 176,
		part_id: 338,
		quantity: 153,
	},
	{
		garage_id: 176,
		part_id: 339,
		quantity: 364,
	},
	{
		garage_id: 176,
		part_id: 340,
		quantity: 7,
	},
	{
		garage_id: 176,
		part_id: 341,
		quantity: 36,
	},
	{
		garage_id: 176,
		part_id: 342,
		quantity: 378,
	},
	{
		garage_id: 176,
		part_id: 343,
		quantity: 369,
	},
	{
		garage_id: 176,
		part_id: 344,
		quantity: 113,
	},
	{
		garage_id: 176,
		part_id: 345,
		quantity: 15,
	},
	{
		garage_id: 176,
		part_id: 346,
		quantity: 174,
	},
	{
		garage_id: 176,
		part_id: 347,
		quantity: 1,
	},
	{
		garage_id: 176,
		part_id: 348,
		quantity: 321,
	},
	{
		garage_id: 176,
		part_id: 349,
		quantity: 327,
	},
	{
		garage_id: 176,
		part_id: 350,
		quantity: 357,
	},
	{
		garage_id: 176,
		part_id: 351,
		quantity: 148,
	},
	{
		garage_id: 176,
		part_id: 352,
		quantity: 472,
	},
	{
		garage_id: 176,
		part_id: 353,
		quantity: 197,
	},
	{
		garage_id: 176,
		part_id: 354,
		quantity: 374,
	},
	{
		garage_id: 176,
		part_id: 355,
		quantity: 233,
	},
	{
		garage_id: 176,
		part_id: 356,
		quantity: 88,
	},
	{
		garage_id: 176,
		part_id: 357,
		quantity: 230,
	},
	{
		garage_id: 176,
		part_id: 358,
		quantity: 450,
	},
	{
		garage_id: 176,
		part_id: 359,
		quantity: 389,
	},
	{
		garage_id: 176,
		part_id: 360,
		quantity: 324,
	},
	{
		garage_id: 176,
		part_id: 361,
		quantity: 157,
	},
	{
		garage_id: 176,
		part_id: 362,
		quantity: 450,
	},
	{
		garage_id: 176,
		part_id: 363,
		quantity: 459,
	},
	{
		garage_id: 176,
		part_id: 364,
		quantity: 84,
	},
	{
		garage_id: 176,
		part_id: 365,
		quantity: 295,
	},
	{
		garage_id: 176,
		part_id: 366,
		quantity: 119,
	},
	{
		garage_id: 176,
		part_id: 367,
		quantity: 40,
	},
	{
		garage_id: 176,
		part_id: 368,
		quantity: 63,
	},
	{
		garage_id: 176,
		part_id: 369,
		quantity: 322,
	},
	{
		garage_id: 176,
		part_id: 370,
		quantity: 63,
	},
	{
		garage_id: 176,
		part_id: 371,
		quantity: 13,
	},
	{
		garage_id: 176,
		part_id: 372,
		quantity: 54,
	},
	{
		garage_id: 176,
		part_id: 373,
		quantity: 212,
	},
	{
		garage_id: 176,
		part_id: 374,
		quantity: 279,
	},
	{
		garage_id: 176,
		part_id: 375,
		quantity: 96,
	},
	{
		garage_id: 176,
		part_id: 376,
		quantity: 235,
	},
	{
		garage_id: 176,
		part_id: 377,
		quantity: 473,
	},
	{
		garage_id: 176,
		part_id: 378,
		quantity: 263,
	},
	{
		garage_id: 176,
		part_id: 379,
		quantity: 17,
	},
	{
		garage_id: 176,
		part_id: 380,
		quantity: 429,
	},
	{
		garage_id: 176,
		part_id: 381,
		quantity: 263,
	},
	{
		garage_id: 176,
		part_id: 382,
		quantity: 4,
	},
	{
		garage_id: 176,
		part_id: 383,
		quantity: 16,
	},
	{
		garage_id: 176,
		part_id: 384,
		quantity: 430,
	},
	{
		garage_id: 176,
		part_id: 385,
		quantity: 127,
	},
	{
		garage_id: 176,
		part_id: 386,
		quantity: 33,
	},
	{
		garage_id: 176,
		part_id: 387,
		quantity: 366,
	},
	{
		garage_id: 176,
		part_id: 388,
		quantity: 146,
	},
	{
		garage_id: 176,
		part_id: 389,
		quantity: 198,
	},
	{
		garage_id: 176,
		part_id: 390,
		quantity: 284,
	},
	{
		garage_id: 176,
		part_id: 391,
		quantity: 402,
	},
	{
		garage_id: 176,
		part_id: 392,
		quantity: 146,
	},
	{
		garage_id: 176,
		part_id: 393,
		quantity: 195,
	},
	{
		garage_id: 176,
		part_id: 394,
		quantity: 69,
	},
	{
		garage_id: 176,
		part_id: 395,
		quantity: 389,
	},
	{
		garage_id: 176,
		part_id: 396,
		quantity: 182,
	},
	{
		garage_id: 176,
		part_id: 397,
		quantity: 16,
	},
	{
		garage_id: 176,
		part_id: 398,
		quantity: 473,
	},
	{
		garage_id: 176,
		part_id: 399,
		quantity: 366,
	},
	{
		garage_id: 177,
		part_id: 0,
		quantity: 310,
	},
	{
		garage_id: 177,
		part_id: 1,
		quantity: 107,
	},
	{
		garage_id: 177,
		part_id: 2,
		quantity: 311,
	},
	{
		garage_id: 177,
		part_id: 3,
		quantity: 187,
	},
	{
		garage_id: 177,
		part_id: 4,
		quantity: 206,
	},
	{
		garage_id: 177,
		part_id: 5,
		quantity: 418,
	},
	{
		garage_id: 177,
		part_id: 6,
		quantity: 325,
	},
	{
		garage_id: 177,
		part_id: 7,
		quantity: 251,
	},
	{
		garage_id: 177,
		part_id: 8,
		quantity: 171,
	},
	{
		garage_id: 177,
		part_id: 9,
		quantity: 479,
	},
	{
		garage_id: 177,
		part_id: 10,
		quantity: 313,
	},
	{
		garage_id: 177,
		part_id: 11,
		quantity: 68,
	},
	{
		garage_id: 177,
		part_id: 12,
		quantity: 397,
	},
	{
		garage_id: 177,
		part_id: 13,
		quantity: 455,
	},
	{
		garage_id: 177,
		part_id: 14,
		quantity: 490,
	},
	{
		garage_id: 177,
		part_id: 15,
		quantity: 471,
	},
	{
		garage_id: 177,
		part_id: 16,
		quantity: 325,
	},
	{
		garage_id: 177,
		part_id: 17,
		quantity: 136,
	},
	{
		garage_id: 177,
		part_id: 18,
		quantity: 366,
	},
	{
		garage_id: 177,
		part_id: 19,
		quantity: 454,
	},
	{
		garage_id: 177,
		part_id: 20,
		quantity: 420,
	},
	{
		garage_id: 177,
		part_id: 21,
		quantity: 403,
	},
	{
		garage_id: 177,
		part_id: 22,
		quantity: 472,
	},
	{
		garage_id: 177,
		part_id: 23,
		quantity: 117,
	},
	{
		garage_id: 177,
		part_id: 24,
		quantity: 39,
	},
	{
		garage_id: 177,
		part_id: 25,
		quantity: 340,
	},
	{
		garage_id: 177,
		part_id: 26,
		quantity: 33,
	},
	{
		garage_id: 177,
		part_id: 27,
		quantity: 134,
	},
	{
		garage_id: 177,
		part_id: 28,
		quantity: 75,
	},
	{
		garage_id: 177,
		part_id: 29,
		quantity: 40,
	},
	{
		garage_id: 177,
		part_id: 30,
		quantity: 335,
	},
	{
		garage_id: 177,
		part_id: 31,
		quantity: 48,
	},
	{
		garage_id: 177,
		part_id: 32,
		quantity: 293,
	},
	{
		garage_id: 177,
		part_id: 33,
		quantity: 360,
	},
	{
		garage_id: 177,
		part_id: 34,
		quantity: 171,
	},
	{
		garage_id: 177,
		part_id: 35,
		quantity: 222,
	},
	{
		garage_id: 177,
		part_id: 36,
		quantity: 98,
	},
	{
		garage_id: 177,
		part_id: 37,
		quantity: 488,
	},
	{
		garage_id: 177,
		part_id: 38,
		quantity: 411,
	},
	{
		garage_id: 177,
		part_id: 39,
		quantity: 239,
	},
	{
		garage_id: 177,
		part_id: 40,
		quantity: 199,
	},
	{
		garage_id: 177,
		part_id: 41,
		quantity: 67,
	},
	{
		garage_id: 177,
		part_id: 42,
		quantity: 213,
	},
	{
		garage_id: 177,
		part_id: 43,
		quantity: 189,
	},
	{
		garage_id: 177,
		part_id: 44,
		quantity: 355,
	},
	{
		garage_id: 177,
		part_id: 45,
		quantity: 30,
	},
	{
		garage_id: 177,
		part_id: 46,
		quantity: 257,
	},
	{
		garage_id: 177,
		part_id: 47,
		quantity: 217,
	},
	{
		garage_id: 177,
		part_id: 48,
		quantity: 55,
	},
	{
		garage_id: 177,
		part_id: 49,
		quantity: 219,
	},
	{
		garage_id: 177,
		part_id: 50,
		quantity: 32,
	},
	{
		garage_id: 177,
		part_id: 51,
		quantity: 339,
	},
	{
		garage_id: 177,
		part_id: 52,
		quantity: 8,
	},
	{
		garage_id: 177,
		part_id: 53,
		quantity: 68,
	},
	{
		garage_id: 177,
		part_id: 54,
		quantity: 297,
	},
	{
		garage_id: 177,
		part_id: 55,
		quantity: 104,
	},
	{
		garage_id: 177,
		part_id: 56,
		quantity: 251,
	},
	{
		garage_id: 177,
		part_id: 57,
		quantity: 340,
	},
	{
		garage_id: 177,
		part_id: 58,
		quantity: 259,
	},
	{
		garage_id: 177,
		part_id: 59,
		quantity: 333,
	},
	{
		garage_id: 177,
		part_id: 60,
		quantity: 41,
	},
	{
		garage_id: 177,
		part_id: 61,
		quantity: 292,
	},
	{
		garage_id: 177,
		part_id: 62,
		quantity: 242,
	},
	{
		garage_id: 177,
		part_id: 63,
		quantity: 306,
	},
	{
		garage_id: 177,
		part_id: 64,
		quantity: 258,
	},
	{
		garage_id: 177,
		part_id: 65,
		quantity: 25,
	},
	{
		garage_id: 177,
		part_id: 66,
		quantity: 335,
	},
	{
		garage_id: 177,
		part_id: 67,
		quantity: 11,
	},
	{
		garage_id: 177,
		part_id: 68,
		quantity: 181,
	},
	{
		garage_id: 177,
		part_id: 69,
		quantity: 361,
	},
	{
		garage_id: 177,
		part_id: 70,
		quantity: 431,
	},
	{
		garage_id: 177,
		part_id: 71,
		quantity: 93,
	},
	{
		garage_id: 177,
		part_id: 72,
		quantity: 425,
	},
	{
		garage_id: 177,
		part_id: 73,
		quantity: 334,
	},
	{
		garage_id: 177,
		part_id: 74,
		quantity: 70,
	},
	{
		garage_id: 177,
		part_id: 75,
		quantity: 215,
	},
	{
		garage_id: 177,
		part_id: 76,
		quantity: 180,
	},
	{
		garage_id: 177,
		part_id: 77,
		quantity: 357,
	},
	{
		garage_id: 177,
		part_id: 78,
		quantity: 126,
	},
	{
		garage_id: 177,
		part_id: 79,
		quantity: 374,
	},
	{
		garage_id: 177,
		part_id: 80,
		quantity: 304,
	},
	{
		garage_id: 177,
		part_id: 81,
		quantity: 226,
	},
	{
		garage_id: 177,
		part_id: 82,
		quantity: 140,
	},
	{
		garage_id: 177,
		part_id: 83,
		quantity: 363,
	},
	{
		garage_id: 177,
		part_id: 84,
		quantity: 208,
	},
	{
		garage_id: 177,
		part_id: 85,
		quantity: 325,
	},
	{
		garage_id: 177,
		part_id: 86,
		quantity: 66,
	},
	{
		garage_id: 177,
		part_id: 87,
		quantity: 143,
	},
	{
		garage_id: 177,
		part_id: 88,
		quantity: 315,
	},
	{
		garage_id: 177,
		part_id: 89,
		quantity: 309,
	},
	{
		garage_id: 177,
		part_id: 90,
		quantity: 338,
	},
	{
		garage_id: 177,
		part_id: 91,
		quantity: 304,
	},
	{
		garage_id: 177,
		part_id: 92,
		quantity: 167,
	},
	{
		garage_id: 177,
		part_id: 93,
		quantity: 65,
	},
	{
		garage_id: 177,
		part_id: 94,
		quantity: 107,
	},
	{
		garage_id: 177,
		part_id: 95,
		quantity: 279,
	},
	{
		garage_id: 177,
		part_id: 96,
		quantity: 331,
	},
	{
		garage_id: 177,
		part_id: 97,
		quantity: 27,
	},
	{
		garage_id: 177,
		part_id: 98,
		quantity: 125,
	},
	{
		garage_id: 177,
		part_id: 99,
		quantity: 218,
	},
	{
		garage_id: 177,
		part_id: 100,
		quantity: 474,
	},
	{
		garage_id: 177,
		part_id: 101,
		quantity: 252,
	},
	{
		garage_id: 177,
		part_id: 102,
		quantity: 54,
	},
	{
		garage_id: 177,
		part_id: 103,
		quantity: 128,
	},
	{
		garage_id: 177,
		part_id: 104,
		quantity: 331,
	},
	{
		garage_id: 177,
		part_id: 105,
		quantity: 207,
	},
	{
		garage_id: 177,
		part_id: 106,
		quantity: 239,
	},
	{
		garage_id: 177,
		part_id: 107,
		quantity: 337,
	},
	{
		garage_id: 177,
		part_id: 108,
		quantity: 371,
	},
	{
		garage_id: 177,
		part_id: 109,
		quantity: 349,
	},
	{
		garage_id: 177,
		part_id: 110,
		quantity: 167,
	},
	{
		garage_id: 177,
		part_id: 111,
		quantity: 414,
	},
	{
		garage_id: 177,
		part_id: 112,
		quantity: 238,
	},
	{
		garage_id: 177,
		part_id: 113,
		quantity: 259,
	},
	{
		garage_id: 177,
		part_id: 114,
		quantity: 50,
	},
	{
		garage_id: 177,
		part_id: 115,
		quantity: 140,
	},
	{
		garage_id: 177,
		part_id: 116,
		quantity: 284,
	},
	{
		garage_id: 177,
		part_id: 117,
		quantity: 314,
	},
	{
		garage_id: 177,
		part_id: 118,
		quantity: 111,
	},
	{
		garage_id: 177,
		part_id: 119,
		quantity: 221,
	},
	{
		garage_id: 177,
		part_id: 120,
		quantity: 117,
	},
	{
		garage_id: 177,
		part_id: 121,
		quantity: 436,
	},
	{
		garage_id: 177,
		part_id: 122,
		quantity: 213,
	},
	{
		garage_id: 177,
		part_id: 123,
		quantity: 55,
	},
	{
		garage_id: 177,
		part_id: 124,
		quantity: 239,
	},
	{
		garage_id: 177,
		part_id: 125,
		quantity: 95,
	},
	{
		garage_id: 177,
		part_id: 126,
		quantity: 370,
	},
	{
		garage_id: 177,
		part_id: 127,
		quantity: 492,
	},
	{
		garage_id: 177,
		part_id: 128,
		quantity: 401,
	},
	{
		garage_id: 177,
		part_id: 129,
		quantity: 370,
	},
	{
		garage_id: 177,
		part_id: 130,
		quantity: 346,
	},
	{
		garage_id: 177,
		part_id: 131,
		quantity: 87,
	},
	{
		garage_id: 177,
		part_id: 132,
		quantity: 407,
	},
	{
		garage_id: 177,
		part_id: 133,
		quantity: 246,
	},
	{
		garage_id: 177,
		part_id: 134,
		quantity: 237,
	},
	{
		garage_id: 177,
		part_id: 135,
		quantity: 475,
	},
	{
		garage_id: 177,
		part_id: 136,
		quantity: 122,
	},
	{
		garage_id: 177,
		part_id: 137,
		quantity: 66,
	},
	{
		garage_id: 177,
		part_id: 138,
		quantity: 93,
	},
	{
		garage_id: 177,
		part_id: 139,
		quantity: 271,
	},
	{
		garage_id: 177,
		part_id: 140,
		quantity: 30,
	},
	{
		garage_id: 177,
		part_id: 141,
		quantity: 303,
	},
	{
		garage_id: 177,
		part_id: 142,
		quantity: 205,
	},
	{
		garage_id: 177,
		part_id: 143,
		quantity: 383,
	},
	{
		garage_id: 177,
		part_id: 144,
		quantity: 224,
	},
	{
		garage_id: 177,
		part_id: 145,
		quantity: 394,
	},
	{
		garage_id: 177,
		part_id: 146,
		quantity: 71,
	},
	{
		garage_id: 177,
		part_id: 147,
		quantity: 147,
	},
	{
		garage_id: 177,
		part_id: 148,
		quantity: 374,
	},
	{
		garage_id: 177,
		part_id: 149,
		quantity: 19,
	},
	{
		garage_id: 177,
		part_id: 150,
		quantity: 381,
	},
	{
		garage_id: 177,
		part_id: 151,
		quantity: 431,
	},
	{
		garage_id: 177,
		part_id: 152,
		quantity: 276,
	},
	{
		garage_id: 177,
		part_id: 153,
		quantity: 410,
	},
	{
		garage_id: 177,
		part_id: 154,
		quantity: 454,
	},
	{
		garage_id: 177,
		part_id: 155,
		quantity: 495,
	},
	{
		garage_id: 177,
		part_id: 156,
		quantity: 306,
	},
	{
		garage_id: 177,
		part_id: 157,
		quantity: 12,
	},
	{
		garage_id: 177,
		part_id: 158,
		quantity: 189,
	},
	{
		garage_id: 177,
		part_id: 159,
		quantity: 432,
	},
	{
		garage_id: 177,
		part_id: 160,
		quantity: 320,
	},
	{
		garage_id: 177,
		part_id: 161,
		quantity: 74,
	},
	{
		garage_id: 177,
		part_id: 162,
		quantity: 21,
	},
	{
		garage_id: 177,
		part_id: 163,
		quantity: 413,
	},
	{
		garage_id: 177,
		part_id: 164,
		quantity: 463,
	},
	{
		garage_id: 177,
		part_id: 165,
		quantity: 231,
	},
	{
		garage_id: 177,
		part_id: 166,
		quantity: 272,
	},
	{
		garage_id: 177,
		part_id: 167,
		quantity: 50,
	},
	{
		garage_id: 177,
		part_id: 168,
		quantity: 318,
	},
	{
		garage_id: 177,
		part_id: 169,
		quantity: 473,
	},
	{
		garage_id: 177,
		part_id: 170,
		quantity: 486,
	},
	{
		garage_id: 177,
		part_id: 171,
		quantity: 55,
	},
	{
		garage_id: 177,
		part_id: 172,
		quantity: 63,
	},
	{
		garage_id: 177,
		part_id: 173,
		quantity: 450,
	},
	{
		garage_id: 177,
		part_id: 174,
		quantity: 177,
	},
	{
		garage_id: 177,
		part_id: 175,
		quantity: 101,
	},
	{
		garage_id: 177,
		part_id: 176,
		quantity: 111,
	},
	{
		garage_id: 177,
		part_id: 177,
		quantity: 356,
	},
	{
		garage_id: 177,
		part_id: 178,
		quantity: 11,
	},
	{
		garage_id: 177,
		part_id: 179,
		quantity: 97,
	},
	{
		garage_id: 177,
		part_id: 180,
		quantity: 182,
	},
	{
		garage_id: 177,
		part_id: 181,
		quantity: 216,
	},
	{
		garage_id: 177,
		part_id: 182,
		quantity: 358,
	},
	{
		garage_id: 177,
		part_id: 183,
		quantity: 308,
	},
	{
		garage_id: 177,
		part_id: 184,
		quantity: 456,
	},
	{
		garage_id: 177,
		part_id: 185,
		quantity: 432,
	},
	{
		garage_id: 177,
		part_id: 186,
		quantity: 102,
	},
	{
		garage_id: 177,
		part_id: 187,
		quantity: 372,
	},
	{
		garage_id: 177,
		part_id: 188,
		quantity: 340,
	},
	{
		garage_id: 177,
		part_id: 189,
		quantity: 56,
	},
	{
		garage_id: 177,
		part_id: 190,
		quantity: 495,
	},
	{
		garage_id: 177,
		part_id: 191,
		quantity: 179,
	},
	{
		garage_id: 177,
		part_id: 192,
		quantity: 154,
	},
	{
		garage_id: 177,
		part_id: 193,
		quantity: 394,
	},
	{
		garage_id: 177,
		part_id: 194,
		quantity: 344,
	},
	{
		garage_id: 177,
		part_id: 195,
		quantity: 449,
	},
	{
		garage_id: 177,
		part_id: 196,
		quantity: 481,
	},
	{
		garage_id: 177,
		part_id: 197,
		quantity: 247,
	},
	{
		garage_id: 177,
		part_id: 198,
		quantity: 93,
	},
	{
		garage_id: 177,
		part_id: 199,
		quantity: 134,
	},
	{
		garage_id: 177,
		part_id: 200,
		quantity: 423,
	},
	{
		garage_id: 177,
		part_id: 201,
		quantity: 360,
	},
	{
		garage_id: 177,
		part_id: 202,
		quantity: 290,
	},
	{
		garage_id: 177,
		part_id: 203,
		quantity: 446,
	},
	{
		garage_id: 177,
		part_id: 204,
		quantity: 65,
	},
	{
		garage_id: 177,
		part_id: 205,
		quantity: 455,
	},
	{
		garage_id: 177,
		part_id: 206,
		quantity: 449,
	},
	{
		garage_id: 177,
		part_id: 207,
		quantity: 477,
	},
	{
		garage_id: 177,
		part_id: 208,
		quantity: 219,
	},
	{
		garage_id: 177,
		part_id: 209,
		quantity: 145,
	},
	{
		garage_id: 177,
		part_id: 210,
		quantity: 26,
	},
	{
		garage_id: 177,
		part_id: 211,
		quantity: 310,
	},
	{
		garage_id: 177,
		part_id: 212,
		quantity: 77,
	},
	{
		garage_id: 177,
		part_id: 213,
		quantity: 243,
	},
	{
		garage_id: 177,
		part_id: 214,
		quantity: 214,
	},
	{
		garage_id: 177,
		part_id: 215,
		quantity: 486,
	},
	{
		garage_id: 177,
		part_id: 216,
		quantity: 324,
	},
	{
		garage_id: 177,
		part_id: 217,
		quantity: 424,
	},
	{
		garage_id: 177,
		part_id: 218,
		quantity: 92,
	},
	{
		garage_id: 177,
		part_id: 219,
		quantity: 278,
	},
	{
		garage_id: 177,
		part_id: 220,
		quantity: 343,
	},
	{
		garage_id: 177,
		part_id: 221,
		quantity: 254,
	},
	{
		garage_id: 177,
		part_id: 222,
		quantity: 241,
	},
	{
		garage_id: 177,
		part_id: 223,
		quantity: 193,
	},
	{
		garage_id: 177,
		part_id: 224,
		quantity: 106,
	},
	{
		garage_id: 177,
		part_id: 225,
		quantity: 302,
	},
	{
		garage_id: 177,
		part_id: 226,
		quantity: 360,
	},
	{
		garage_id: 177,
		part_id: 227,
		quantity: 490,
	},
	{
		garage_id: 177,
		part_id: 228,
		quantity: 160,
	},
	{
		garage_id: 177,
		part_id: 229,
		quantity: 472,
	},
	{
		garage_id: 177,
		part_id: 230,
		quantity: 388,
	},
	{
		garage_id: 177,
		part_id: 231,
		quantity: 114,
	},
	{
		garage_id: 177,
		part_id: 232,
		quantity: 409,
	},
	{
		garage_id: 177,
		part_id: 233,
		quantity: 317,
	},
	{
		garage_id: 177,
		part_id: 234,
		quantity: 260,
	},
	{
		garage_id: 177,
		part_id: 235,
		quantity: 489,
	},
	{
		garage_id: 177,
		part_id: 236,
		quantity: 494,
	},
	{
		garage_id: 177,
		part_id: 237,
		quantity: 87,
	},
	{
		garage_id: 177,
		part_id: 238,
		quantity: 36,
	},
	{
		garage_id: 177,
		part_id: 239,
		quantity: 402,
	},
	{
		garage_id: 177,
		part_id: 240,
		quantity: 317,
	},
	{
		garage_id: 177,
		part_id: 241,
		quantity: 204,
	},
	{
		garage_id: 177,
		part_id: 242,
		quantity: 422,
	},
	{
		garage_id: 177,
		part_id: 243,
		quantity: 448,
	},
	{
		garage_id: 177,
		part_id: 244,
		quantity: 105,
	},
	{
		garage_id: 177,
		part_id: 245,
		quantity: 466,
	},
	{
		garage_id: 177,
		part_id: 246,
		quantity: 358,
	},
	{
		garage_id: 177,
		part_id: 247,
		quantity: 187,
	},
	{
		garage_id: 177,
		part_id: 248,
		quantity: 95,
	},
	{
		garage_id: 177,
		part_id: 249,
		quantity: 184,
	},
	{
		garage_id: 177,
		part_id: 250,
		quantity: 240,
	},
	{
		garage_id: 177,
		part_id: 251,
		quantity: 74,
	},
	{
		garage_id: 177,
		part_id: 252,
		quantity: 468,
	},
	{
		garage_id: 177,
		part_id: 253,
		quantity: 452,
	},
	{
		garage_id: 177,
		part_id: 254,
		quantity: 389,
	},
	{
		garage_id: 177,
		part_id: 255,
		quantity: 411,
	},
	{
		garage_id: 177,
		part_id: 256,
		quantity: 8,
	},
	{
		garage_id: 177,
		part_id: 257,
		quantity: 106,
	},
	{
		garage_id: 177,
		part_id: 258,
		quantity: 420,
	},
	{
		garage_id: 177,
		part_id: 259,
		quantity: 105,
	},
	{
		garage_id: 177,
		part_id: 260,
		quantity: 251,
	},
	{
		garage_id: 177,
		part_id: 261,
		quantity: 379,
	},
	{
		garage_id: 177,
		part_id: 262,
		quantity: 182,
	},
	{
		garage_id: 177,
		part_id: 263,
		quantity: 46,
	},
	{
		garage_id: 177,
		part_id: 264,
		quantity: 156,
	},
	{
		garage_id: 177,
		part_id: 265,
		quantity: 465,
	},
	{
		garage_id: 177,
		part_id: 266,
		quantity: 55,
	},
	{
		garage_id: 177,
		part_id: 267,
		quantity: 445,
	},
	{
		garage_id: 177,
		part_id: 268,
		quantity: 26,
	},
	{
		garage_id: 177,
		part_id: 269,
		quantity: 21,
	},
	{
		garage_id: 177,
		part_id: 270,
		quantity: 245,
	},
	{
		garage_id: 177,
		part_id: 271,
		quantity: 462,
	},
	{
		garage_id: 177,
		part_id: 272,
		quantity: 58,
	},
	{
		garage_id: 177,
		part_id: 273,
		quantity: 352,
	},
	{
		garage_id: 177,
		part_id: 274,
		quantity: 464,
	},
	{
		garage_id: 177,
		part_id: 275,
		quantity: 419,
	},
	{
		garage_id: 177,
		part_id: 276,
		quantity: 368,
	},
	{
		garage_id: 177,
		part_id: 277,
		quantity: 87,
	},
	{
		garage_id: 177,
		part_id: 278,
		quantity: 137,
	},
	{
		garage_id: 177,
		part_id: 279,
		quantity: 331,
	},
	{
		garage_id: 177,
		part_id: 280,
		quantity: 73,
	},
	{
		garage_id: 177,
		part_id: 281,
		quantity: 60,
	},
	{
		garage_id: 177,
		part_id: 282,
		quantity: 336,
	},
	{
		garage_id: 177,
		part_id: 283,
		quantity: 7,
	},
	{
		garage_id: 177,
		part_id: 284,
		quantity: 36,
	},
	{
		garage_id: 177,
		part_id: 285,
		quantity: 421,
	},
	{
		garage_id: 177,
		part_id: 286,
		quantity: 166,
	},
	{
		garage_id: 177,
		part_id: 287,
		quantity: 22,
	},
	{
		garage_id: 177,
		part_id: 288,
		quantity: 391,
	},
	{
		garage_id: 177,
		part_id: 289,
		quantity: 146,
	},
	{
		garage_id: 177,
		part_id: 290,
		quantity: 357,
	},
	{
		garage_id: 177,
		part_id: 291,
		quantity: 153,
	},
	{
		garage_id: 177,
		part_id: 292,
		quantity: 355,
	},
	{
		garage_id: 177,
		part_id: 293,
		quantity: 241,
	},
	{
		garage_id: 177,
		part_id: 294,
		quantity: 295,
	},
	{
		garage_id: 177,
		part_id: 295,
		quantity: 215,
	},
	{
		garage_id: 177,
		part_id: 296,
		quantity: 333,
	},
	{
		garage_id: 177,
		part_id: 297,
		quantity: 223,
	},
	{
		garage_id: 177,
		part_id: 298,
		quantity: 183,
	},
	{
		garage_id: 177,
		part_id: 299,
		quantity: 363,
	},
	{
		garage_id: 177,
		part_id: 300,
		quantity: 234,
	},
	{
		garage_id: 177,
		part_id: 301,
		quantity: 27,
	},
	{
		garage_id: 177,
		part_id: 302,
		quantity: 126,
	},
	{
		garage_id: 177,
		part_id: 303,
		quantity: 160,
	},
	{
		garage_id: 177,
		part_id: 304,
		quantity: 287,
	},
	{
		garage_id: 177,
		part_id: 305,
		quantity: 394,
	},
	{
		garage_id: 177,
		part_id: 306,
		quantity: 375,
	},
	{
		garage_id: 177,
		part_id: 307,
		quantity: 286,
	},
	{
		garage_id: 177,
		part_id: 308,
		quantity: 416,
	},
	{
		garage_id: 177,
		part_id: 309,
		quantity: 435,
	},
	{
		garage_id: 177,
		part_id: 310,
		quantity: 311,
	},
	{
		garage_id: 177,
		part_id: 311,
		quantity: 162,
	},
	{
		garage_id: 177,
		part_id: 312,
		quantity: 458,
	},
	{
		garage_id: 177,
		part_id: 313,
		quantity: 487,
	},
	{
		garage_id: 177,
		part_id: 314,
		quantity: 67,
	},
	{
		garage_id: 177,
		part_id: 315,
		quantity: 386,
	},
	{
		garage_id: 177,
		part_id: 316,
		quantity: 90,
	},
	{
		garage_id: 177,
		part_id: 317,
		quantity: 496,
	},
	{
		garage_id: 177,
		part_id: 318,
		quantity: 388,
	},
	{
		garage_id: 177,
		part_id: 319,
		quantity: 474,
	},
	{
		garage_id: 177,
		part_id: 320,
		quantity: 177,
	},
	{
		garage_id: 177,
		part_id: 321,
		quantity: 39,
	},
	{
		garage_id: 177,
		part_id: 322,
		quantity: 79,
	},
	{
		garage_id: 177,
		part_id: 323,
		quantity: 286,
	},
	{
		garage_id: 177,
		part_id: 324,
		quantity: 163,
	},
	{
		garage_id: 177,
		part_id: 325,
		quantity: 16,
	},
	{
		garage_id: 177,
		part_id: 326,
		quantity: 152,
	},
	{
		garage_id: 177,
		part_id: 327,
		quantity: 420,
	},
	{
		garage_id: 177,
		part_id: 328,
		quantity: 476,
	},
	{
		garage_id: 177,
		part_id: 329,
		quantity: 369,
	},
	{
		garage_id: 177,
		part_id: 330,
		quantity: 218,
	},
	{
		garage_id: 177,
		part_id: 331,
		quantity: 473,
	},
	{
		garage_id: 177,
		part_id: 332,
		quantity: 185,
	},
	{
		garage_id: 177,
		part_id: 333,
		quantity: 2,
	},
	{
		garage_id: 177,
		part_id: 334,
		quantity: 474,
	},
	{
		garage_id: 177,
		part_id: 335,
		quantity: 479,
	},
	{
		garage_id: 177,
		part_id: 336,
		quantity: 65,
	},
	{
		garage_id: 177,
		part_id: 337,
		quantity: 373,
	},
	{
		garage_id: 177,
		part_id: 338,
		quantity: 329,
	},
	{
		garage_id: 177,
		part_id: 339,
		quantity: 79,
	},
	{
		garage_id: 177,
		part_id: 340,
		quantity: 453,
	},
	{
		garage_id: 177,
		part_id: 341,
		quantity: 20,
	},
	{
		garage_id: 177,
		part_id: 342,
		quantity: 287,
	},
	{
		garage_id: 177,
		part_id: 343,
		quantity: 453,
	},
	{
		garage_id: 177,
		part_id: 344,
		quantity: 227,
	},
	{
		garage_id: 177,
		part_id: 345,
		quantity: 485,
	},
	{
		garage_id: 177,
		part_id: 346,
		quantity: 435,
	},
	{
		garage_id: 177,
		part_id: 347,
		quantity: 197,
	},
	{
		garage_id: 177,
		part_id: 348,
		quantity: 152,
	},
	{
		garage_id: 177,
		part_id: 349,
		quantity: 338,
	},
	{
		garage_id: 177,
		part_id: 350,
		quantity: 416,
	},
	{
		garage_id: 177,
		part_id: 351,
		quantity: 377,
	},
	{
		garage_id: 177,
		part_id: 352,
		quantity: 496,
	},
	{
		garage_id: 177,
		part_id: 353,
		quantity: 432,
	},
	{
		garage_id: 177,
		part_id: 354,
		quantity: 99,
	},
	{
		garage_id: 177,
		part_id: 355,
		quantity: 32,
	},
	{
		garage_id: 177,
		part_id: 356,
		quantity: 399,
	},
	{
		garage_id: 177,
		part_id: 357,
		quantity: 115,
	},
	{
		garage_id: 177,
		part_id: 358,
		quantity: 1,
	},
	{
		garage_id: 177,
		part_id: 359,
		quantity: 367,
	},
	{
		garage_id: 177,
		part_id: 360,
		quantity: 221,
	},
	{
		garage_id: 177,
		part_id: 361,
		quantity: 33,
	},
	{
		garage_id: 177,
		part_id: 362,
		quantity: 13,
	},
	{
		garage_id: 177,
		part_id: 363,
		quantity: 247,
	},
	{
		garage_id: 177,
		part_id: 364,
		quantity: 175,
	},
	{
		garage_id: 177,
		part_id: 365,
		quantity: 113,
	},
	{
		garage_id: 177,
		part_id: 366,
		quantity: 432,
	},
	{
		garage_id: 177,
		part_id: 367,
		quantity: 376,
	},
	{
		garage_id: 177,
		part_id: 368,
		quantity: 423,
	},
	{
		garage_id: 177,
		part_id: 369,
		quantity: 197,
	},
	{
		garage_id: 177,
		part_id: 370,
		quantity: 371,
	},
	{
		garage_id: 177,
		part_id: 371,
		quantity: 122,
	},
	{
		garage_id: 177,
		part_id: 372,
		quantity: 420,
	},
	{
		garage_id: 177,
		part_id: 373,
		quantity: 423,
	},
	{
		garage_id: 177,
		part_id: 374,
		quantity: 474,
	},
	{
		garage_id: 177,
		part_id: 375,
		quantity: 465,
	},
	{
		garage_id: 177,
		part_id: 376,
		quantity: 400,
	},
	{
		garage_id: 177,
		part_id: 377,
		quantity: 175,
	},
	{
		garage_id: 177,
		part_id: 378,
		quantity: 113,
	},
	{
		garage_id: 177,
		part_id: 379,
		quantity: 433,
	},
	{
		garage_id: 177,
		part_id: 380,
		quantity: 491,
	},
	{
		garage_id: 177,
		part_id: 381,
		quantity: 47,
	},
	{
		garage_id: 177,
		part_id: 382,
		quantity: 305,
	},
	{
		garage_id: 177,
		part_id: 383,
		quantity: 487,
	},
	{
		garage_id: 177,
		part_id: 384,
		quantity: 26,
	},
	{
		garage_id: 177,
		part_id: 385,
		quantity: 467,
	},
	{
		garage_id: 177,
		part_id: 386,
		quantity: 311,
	},
	{
		garage_id: 177,
		part_id: 387,
		quantity: 161,
	},
	{
		garage_id: 177,
		part_id: 388,
		quantity: 197,
	},
	{
		garage_id: 177,
		part_id: 389,
		quantity: 492,
	},
	{
		garage_id: 177,
		part_id: 390,
		quantity: 165,
	},
	{
		garage_id: 177,
		part_id: 391,
		quantity: 390,
	},
	{
		garage_id: 177,
		part_id: 392,
		quantity: 353,
	},
	{
		garage_id: 177,
		part_id: 393,
		quantity: 125,
	},
	{
		garage_id: 177,
		part_id: 394,
		quantity: 418,
	},
	{
		garage_id: 177,
		part_id: 395,
		quantity: 145,
	},
	{
		garage_id: 177,
		part_id: 396,
		quantity: 72,
	},
	{
		garage_id: 177,
		part_id: 397,
		quantity: 297,
	},
	{
		garage_id: 177,
		part_id: 398,
		quantity: 268,
	},
	{
		garage_id: 177,
		part_id: 399,
		quantity: 255,
	},
	{
		garage_id: 178,
		part_id: 0,
		quantity: 406,
	},
	{
		garage_id: 178,
		part_id: 1,
		quantity: 180,
	},
	{
		garage_id: 178,
		part_id: 2,
		quantity: 463,
	},
	{
		garage_id: 178,
		part_id: 3,
		quantity: 380,
	},
	{
		garage_id: 178,
		part_id: 4,
		quantity: 478,
	},
	{
		garage_id: 178,
		part_id: 5,
		quantity: 217,
	},
	{
		garage_id: 178,
		part_id: 6,
		quantity: 129,
	},
	{
		garage_id: 178,
		part_id: 7,
		quantity: 375,
	},
	{
		garage_id: 178,
		part_id: 8,
		quantity: 336,
	},
	{
		garage_id: 178,
		part_id: 9,
		quantity: 64,
	},
	{
		garage_id: 178,
		part_id: 10,
		quantity: 159,
	},
	{
		garage_id: 178,
		part_id: 11,
		quantity: 407,
	},
	{
		garage_id: 178,
		part_id: 12,
		quantity: 247,
	},
	{
		garage_id: 178,
		part_id: 13,
		quantity: 193,
	},
	{
		garage_id: 178,
		part_id: 14,
		quantity: 107,
	},
	{
		garage_id: 178,
		part_id: 15,
		quantity: 107,
	},
	{
		garage_id: 178,
		part_id: 16,
		quantity: 435,
	},
	{
		garage_id: 178,
		part_id: 17,
		quantity: 437,
	},
	{
		garage_id: 178,
		part_id: 18,
		quantity: 260,
	},
	{
		garage_id: 178,
		part_id: 19,
		quantity: 228,
	},
	{
		garage_id: 178,
		part_id: 20,
		quantity: 310,
	},
	{
		garage_id: 178,
		part_id: 21,
		quantity: 185,
	},
	{
		garage_id: 178,
		part_id: 22,
		quantity: 371,
	},
	{
		garage_id: 178,
		part_id: 23,
		quantity: 184,
	},
	{
		garage_id: 178,
		part_id: 24,
		quantity: 472,
	},
	{
		garage_id: 178,
		part_id: 25,
		quantity: 70,
	},
	{
		garage_id: 178,
		part_id: 26,
		quantity: 75,
	},
	{
		garage_id: 178,
		part_id: 27,
		quantity: 377,
	},
	{
		garage_id: 178,
		part_id: 28,
		quantity: 291,
	},
	{
		garage_id: 178,
		part_id: 29,
		quantity: 272,
	},
	{
		garage_id: 178,
		part_id: 30,
		quantity: 341,
	},
	{
		garage_id: 178,
		part_id: 31,
		quantity: 336,
	},
	{
		garage_id: 178,
		part_id: 32,
		quantity: 349,
	},
	{
		garage_id: 178,
		part_id: 33,
		quantity: 377,
	},
	{
		garage_id: 178,
		part_id: 34,
		quantity: 233,
	},
	{
		garage_id: 178,
		part_id: 35,
		quantity: 246,
	},
	{
		garage_id: 178,
		part_id: 36,
		quantity: 274,
	},
	{
		garage_id: 178,
		part_id: 37,
		quantity: 128,
	},
	{
		garage_id: 178,
		part_id: 38,
		quantity: 81,
	},
	{
		garage_id: 178,
		part_id: 39,
		quantity: 66,
	},
	{
		garage_id: 178,
		part_id: 40,
		quantity: 181,
	},
	{
		garage_id: 178,
		part_id: 41,
		quantity: 110,
	},
	{
		garage_id: 178,
		part_id: 42,
		quantity: 118,
	},
	{
		garage_id: 178,
		part_id: 43,
		quantity: 43,
	},
	{
		garage_id: 178,
		part_id: 44,
		quantity: 265,
	},
	{
		garage_id: 178,
		part_id: 45,
		quantity: 145,
	},
	{
		garage_id: 178,
		part_id: 46,
		quantity: 175,
	},
	{
		garage_id: 178,
		part_id: 47,
		quantity: 69,
	},
	{
		garage_id: 178,
		part_id: 48,
		quantity: 147,
	},
	{
		garage_id: 178,
		part_id: 49,
		quantity: 87,
	},
	{
		garage_id: 178,
		part_id: 50,
		quantity: 65,
	},
	{
		garage_id: 178,
		part_id: 51,
		quantity: 198,
	},
	{
		garage_id: 178,
		part_id: 52,
		quantity: 488,
	},
	{
		garage_id: 178,
		part_id: 53,
		quantity: 498,
	},
	{
		garage_id: 178,
		part_id: 54,
		quantity: 463,
	},
	{
		garage_id: 178,
		part_id: 55,
		quantity: 461,
	},
	{
		garage_id: 178,
		part_id: 56,
		quantity: 26,
	},
	{
		garage_id: 178,
		part_id: 57,
		quantity: 318,
	},
	{
		garage_id: 178,
		part_id: 58,
		quantity: 1,
	},
	{
		garage_id: 178,
		part_id: 59,
		quantity: 287,
	},
	{
		garage_id: 178,
		part_id: 60,
		quantity: 159,
	},
	{
		garage_id: 178,
		part_id: 61,
		quantity: 196,
	},
	{
		garage_id: 178,
		part_id: 62,
		quantity: 432,
	},
	{
		garage_id: 178,
		part_id: 63,
		quantity: 172,
	},
	{
		garage_id: 178,
		part_id: 64,
		quantity: 8,
	},
	{
		garage_id: 178,
		part_id: 65,
		quantity: 56,
	},
	{
		garage_id: 178,
		part_id: 66,
		quantity: 72,
	},
	{
		garage_id: 178,
		part_id: 67,
		quantity: 357,
	},
	{
		garage_id: 178,
		part_id: 68,
		quantity: 224,
	},
	{
		garage_id: 178,
		part_id: 69,
		quantity: 438,
	},
	{
		garage_id: 178,
		part_id: 70,
		quantity: 71,
	},
	{
		garage_id: 178,
		part_id: 71,
		quantity: 244,
	},
	{
		garage_id: 178,
		part_id: 72,
		quantity: 92,
	},
	{
		garage_id: 178,
		part_id: 73,
		quantity: 167,
	},
	{
		garage_id: 178,
		part_id: 74,
		quantity: 373,
	},
	{
		garage_id: 178,
		part_id: 75,
		quantity: 300,
	},
	{
		garage_id: 178,
		part_id: 76,
		quantity: 274,
	},
	{
		garage_id: 178,
		part_id: 77,
		quantity: 95,
	},
	{
		garage_id: 178,
		part_id: 78,
		quantity: 299,
	},
	{
		garage_id: 178,
		part_id: 79,
		quantity: 193,
	},
	{
		garage_id: 178,
		part_id: 80,
		quantity: 182,
	},
	{
		garage_id: 178,
		part_id: 81,
		quantity: 82,
	},
	{
		garage_id: 178,
		part_id: 82,
		quantity: 478,
	},
	{
		garage_id: 178,
		part_id: 83,
		quantity: 316,
	},
	{
		garage_id: 178,
		part_id: 84,
		quantity: 130,
	},
	{
		garage_id: 178,
		part_id: 85,
		quantity: 487,
	},
	{
		garage_id: 178,
		part_id: 86,
		quantity: 388,
	},
	{
		garage_id: 178,
		part_id: 87,
		quantity: 93,
	},
	{
		garage_id: 178,
		part_id: 88,
		quantity: 431,
	},
	{
		garage_id: 178,
		part_id: 89,
		quantity: 126,
	},
	{
		garage_id: 178,
		part_id: 90,
		quantity: 456,
	},
	{
		garage_id: 178,
		part_id: 91,
		quantity: 80,
	},
	{
		garage_id: 178,
		part_id: 92,
		quantity: 449,
	},
	{
		garage_id: 178,
		part_id: 93,
		quantity: 62,
	},
	{
		garage_id: 178,
		part_id: 94,
		quantity: 252,
	},
	{
		garage_id: 178,
		part_id: 95,
		quantity: 211,
	},
	{
		garage_id: 178,
		part_id: 96,
		quantity: 415,
	},
	{
		garage_id: 178,
		part_id: 97,
		quantity: 185,
	},
	{
		garage_id: 178,
		part_id: 98,
		quantity: 242,
	},
	{
		garage_id: 178,
		part_id: 99,
		quantity: 85,
	},
	{
		garage_id: 178,
		part_id: 100,
		quantity: 426,
	},
	{
		garage_id: 178,
		part_id: 101,
		quantity: 405,
	},
	{
		garage_id: 178,
		part_id: 102,
		quantity: 93,
	},
	{
		garage_id: 178,
		part_id: 103,
		quantity: 176,
	},
	{
		garage_id: 178,
		part_id: 104,
		quantity: 143,
	},
	{
		garage_id: 178,
		part_id: 105,
		quantity: 288,
	},
	{
		garage_id: 178,
		part_id: 106,
		quantity: 248,
	},
	{
		garage_id: 178,
		part_id: 107,
		quantity: 73,
	},
	{
		garage_id: 178,
		part_id: 108,
		quantity: 195,
	},
	{
		garage_id: 178,
		part_id: 109,
		quantity: 14,
	},
	{
		garage_id: 178,
		part_id: 110,
		quantity: 251,
	},
	{
		garage_id: 178,
		part_id: 111,
		quantity: 247,
	},
	{
		garage_id: 178,
		part_id: 112,
		quantity: 287,
	},
	{
		garage_id: 178,
		part_id: 113,
		quantity: 165,
	},
	{
		garage_id: 178,
		part_id: 114,
		quantity: 220,
	},
	{
		garage_id: 178,
		part_id: 115,
		quantity: 380,
	},
	{
		garage_id: 178,
		part_id: 116,
		quantity: 404,
	},
	{
		garage_id: 178,
		part_id: 117,
		quantity: 86,
	},
	{
		garage_id: 178,
		part_id: 118,
		quantity: 165,
	},
	{
		garage_id: 178,
		part_id: 119,
		quantity: 161,
	},
	{
		garage_id: 178,
		part_id: 120,
		quantity: 201,
	},
	{
		garage_id: 178,
		part_id: 121,
		quantity: 156,
	},
	{
		garage_id: 178,
		part_id: 122,
		quantity: 189,
	},
	{
		garage_id: 178,
		part_id: 123,
		quantity: 406,
	},
	{
		garage_id: 178,
		part_id: 124,
		quantity: 247,
	},
	{
		garage_id: 178,
		part_id: 125,
		quantity: 443,
	},
	{
		garage_id: 178,
		part_id: 126,
		quantity: 229,
	},
	{
		garage_id: 178,
		part_id: 127,
		quantity: 218,
	},
	{
		garage_id: 178,
		part_id: 128,
		quantity: 6,
	},
	{
		garage_id: 178,
		part_id: 129,
		quantity: 218,
	},
	{
		garage_id: 178,
		part_id: 130,
		quantity: 172,
	},
	{
		garage_id: 178,
		part_id: 131,
		quantity: 159,
	},
	{
		garage_id: 178,
		part_id: 132,
		quantity: 466,
	},
	{
		garage_id: 178,
		part_id: 133,
		quantity: 62,
	},
	{
		garage_id: 178,
		part_id: 134,
		quantity: 286,
	},
	{
		garage_id: 178,
		part_id: 135,
		quantity: 89,
	},
	{
		garage_id: 178,
		part_id: 136,
		quantity: 317,
	},
	{
		garage_id: 178,
		part_id: 137,
		quantity: 229,
	},
	{
		garage_id: 178,
		part_id: 138,
		quantity: 330,
	},
	{
		garage_id: 178,
		part_id: 139,
		quantity: 148,
	},
	{
		garage_id: 178,
		part_id: 140,
		quantity: 92,
	},
	{
		garage_id: 178,
		part_id: 141,
		quantity: 135,
	},
	{
		garage_id: 178,
		part_id: 142,
		quantity: 462,
	},
	{
		garage_id: 178,
		part_id: 143,
		quantity: 9,
	},
	{
		garage_id: 178,
		part_id: 144,
		quantity: 122,
	},
	{
		garage_id: 178,
		part_id: 145,
		quantity: 327,
	},
	{
		garage_id: 178,
		part_id: 146,
		quantity: 160,
	},
	{
		garage_id: 178,
		part_id: 147,
		quantity: 1,
	},
	{
		garage_id: 178,
		part_id: 148,
		quantity: 23,
	},
	{
		garage_id: 178,
		part_id: 149,
		quantity: 159,
	},
	{
		garage_id: 178,
		part_id: 150,
		quantity: 141,
	},
	{
		garage_id: 178,
		part_id: 151,
		quantity: 191,
	},
	{
		garage_id: 178,
		part_id: 152,
		quantity: 76,
	},
	{
		garage_id: 178,
		part_id: 153,
		quantity: 175,
	},
	{
		garage_id: 178,
		part_id: 154,
		quantity: 462,
	},
	{
		garage_id: 178,
		part_id: 155,
		quantity: 215,
	},
	{
		garage_id: 178,
		part_id: 156,
		quantity: 201,
	},
	{
		garage_id: 178,
		part_id: 157,
		quantity: 319,
	},
	{
		garage_id: 178,
		part_id: 158,
		quantity: 135,
	},
	{
		garage_id: 178,
		part_id: 159,
		quantity: 191,
	},
	{
		garage_id: 178,
		part_id: 160,
		quantity: 282,
	},
	{
		garage_id: 178,
		part_id: 161,
		quantity: 232,
	},
	{
		garage_id: 178,
		part_id: 162,
		quantity: 328,
	},
	{
		garage_id: 178,
		part_id: 163,
		quantity: 405,
	},
	{
		garage_id: 178,
		part_id: 164,
		quantity: 16,
	},
	{
		garage_id: 178,
		part_id: 165,
		quantity: 153,
	},
	{
		garage_id: 178,
		part_id: 166,
		quantity: 50,
	},
	{
		garage_id: 178,
		part_id: 167,
		quantity: 307,
	},
	{
		garage_id: 178,
		part_id: 168,
		quantity: 156,
	},
	{
		garage_id: 178,
		part_id: 169,
		quantity: 299,
	},
	{
		garage_id: 178,
		part_id: 170,
		quantity: 367,
	},
	{
		garage_id: 178,
		part_id: 171,
		quantity: 278,
	},
	{
		garage_id: 178,
		part_id: 172,
		quantity: 175,
	},
	{
		garage_id: 178,
		part_id: 173,
		quantity: 169,
	},
	{
		garage_id: 178,
		part_id: 174,
		quantity: 109,
	},
	{
		garage_id: 178,
		part_id: 175,
		quantity: 35,
	},
	{
		garage_id: 178,
		part_id: 176,
		quantity: 489,
	},
	{
		garage_id: 178,
		part_id: 177,
		quantity: 353,
	},
	{
		garage_id: 178,
		part_id: 178,
		quantity: 81,
	},
	{
		garage_id: 178,
		part_id: 179,
		quantity: 488,
	},
	{
		garage_id: 178,
		part_id: 180,
		quantity: 84,
	},
	{
		garage_id: 178,
		part_id: 181,
		quantity: 246,
	},
	{
		garage_id: 178,
		part_id: 182,
		quantity: 221,
	},
	{
		garage_id: 178,
		part_id: 183,
		quantity: 211,
	},
	{
		garage_id: 178,
		part_id: 184,
		quantity: 326,
	},
	{
		garage_id: 178,
		part_id: 185,
		quantity: 136,
	},
	{
		garage_id: 178,
		part_id: 186,
		quantity: 469,
	},
	{
		garage_id: 178,
		part_id: 187,
		quantity: 136,
	},
	{
		garage_id: 178,
		part_id: 188,
		quantity: 246,
	},
	{
		garage_id: 178,
		part_id: 189,
		quantity: 245,
	},
	{
		garage_id: 178,
		part_id: 190,
		quantity: 87,
	},
	{
		garage_id: 178,
		part_id: 191,
		quantity: 258,
	},
	{
		garage_id: 178,
		part_id: 192,
		quantity: 303,
	},
	{
		garage_id: 178,
		part_id: 193,
		quantity: 155,
	},
	{
		garage_id: 178,
		part_id: 194,
		quantity: 57,
	},
	{
		garage_id: 178,
		part_id: 195,
		quantity: 168,
	},
	{
		garage_id: 178,
		part_id: 196,
		quantity: 161,
	},
	{
		garage_id: 178,
		part_id: 197,
		quantity: 239,
	},
	{
		garage_id: 178,
		part_id: 198,
		quantity: 168,
	},
	{
		garage_id: 178,
		part_id: 199,
		quantity: 249,
	},
	{
		garage_id: 178,
		part_id: 200,
		quantity: 460,
	},
	{
		garage_id: 178,
		part_id: 201,
		quantity: 309,
	},
	{
		garage_id: 178,
		part_id: 202,
		quantity: 59,
	},
	{
		garage_id: 178,
		part_id: 203,
		quantity: 57,
	},
	{
		garage_id: 178,
		part_id: 204,
		quantity: 265,
	},
	{
		garage_id: 178,
		part_id: 205,
		quantity: 486,
	},
	{
		garage_id: 178,
		part_id: 206,
		quantity: 52,
	},
	{
		garage_id: 178,
		part_id: 207,
		quantity: 189,
	},
	{
		garage_id: 178,
		part_id: 208,
		quantity: 394,
	},
	{
		garage_id: 178,
		part_id: 209,
		quantity: 238,
	},
	{
		garage_id: 178,
		part_id: 210,
		quantity: 262,
	},
	{
		garage_id: 178,
		part_id: 211,
		quantity: 11,
	},
	{
		garage_id: 178,
		part_id: 212,
		quantity: 308,
	},
	{
		garage_id: 178,
		part_id: 213,
		quantity: 167,
	},
	{
		garage_id: 178,
		part_id: 214,
		quantity: 12,
	},
	{
		garage_id: 178,
		part_id: 215,
		quantity: 368,
	},
	{
		garage_id: 178,
		part_id: 216,
		quantity: 82,
	},
	{
		garage_id: 178,
		part_id: 217,
		quantity: 469,
	},
	{
		garage_id: 178,
		part_id: 218,
		quantity: 213,
	},
	{
		garage_id: 178,
		part_id: 219,
		quantity: 437,
	},
	{
		garage_id: 178,
		part_id: 220,
		quantity: 267,
	},
	{
		garage_id: 178,
		part_id: 221,
		quantity: 390,
	},
	{
		garage_id: 178,
		part_id: 222,
		quantity: 433,
	},
	{
		garage_id: 178,
		part_id: 223,
		quantity: 179,
	},
	{
		garage_id: 178,
		part_id: 224,
		quantity: 301,
	},
	{
		garage_id: 178,
		part_id: 225,
		quantity: 228,
	},
	{
		garage_id: 178,
		part_id: 226,
		quantity: 419,
	},
	{
		garage_id: 178,
		part_id: 227,
		quantity: 339,
	},
	{
		garage_id: 178,
		part_id: 228,
		quantity: 404,
	},
	{
		garage_id: 178,
		part_id: 229,
		quantity: 113,
	},
	{
		garage_id: 178,
		part_id: 230,
		quantity: 217,
	},
	{
		garage_id: 178,
		part_id: 231,
		quantity: 318,
	},
	{
		garage_id: 178,
		part_id: 232,
		quantity: 246,
	},
	{
		garage_id: 178,
		part_id: 233,
		quantity: 413,
	},
	{
		garage_id: 178,
		part_id: 234,
		quantity: 372,
	},
	{
		garage_id: 178,
		part_id: 235,
		quantity: 140,
	},
	{
		garage_id: 178,
		part_id: 236,
		quantity: 461,
	},
	{
		garage_id: 178,
		part_id: 237,
		quantity: 253,
	},
	{
		garage_id: 178,
		part_id: 238,
		quantity: 187,
	},
	{
		garage_id: 178,
		part_id: 239,
		quantity: 130,
	},
	{
		garage_id: 178,
		part_id: 240,
		quantity: 367,
	},
	{
		garage_id: 178,
		part_id: 241,
		quantity: 316,
	},
	{
		garage_id: 178,
		part_id: 242,
		quantity: 346,
	},
	{
		garage_id: 178,
		part_id: 243,
		quantity: 222,
	},
	{
		garage_id: 178,
		part_id: 244,
		quantity: 178,
	},
	{
		garage_id: 178,
		part_id: 245,
		quantity: 320,
	},
	{
		garage_id: 178,
		part_id: 246,
		quantity: 323,
	},
	{
		garage_id: 178,
		part_id: 247,
		quantity: 95,
	},
	{
		garage_id: 178,
		part_id: 248,
		quantity: 311,
	},
	{
		garage_id: 178,
		part_id: 249,
		quantity: 272,
	},
	{
		garage_id: 178,
		part_id: 250,
		quantity: 457,
	},
	{
		garage_id: 178,
		part_id: 251,
		quantity: 363,
	},
	{
		garage_id: 178,
		part_id: 252,
		quantity: 257,
	},
	{
		garage_id: 178,
		part_id: 253,
		quantity: 276,
	},
	{
		garage_id: 178,
		part_id: 254,
		quantity: 280,
	},
	{
		garage_id: 178,
		part_id: 255,
		quantity: 8,
	},
	{
		garage_id: 178,
		part_id: 256,
		quantity: 256,
	},
	{
		garage_id: 178,
		part_id: 257,
		quantity: 295,
	},
	{
		garage_id: 178,
		part_id: 258,
		quantity: 266,
	},
	{
		garage_id: 178,
		part_id: 259,
		quantity: 142,
	},
	{
		garage_id: 178,
		part_id: 260,
		quantity: 76,
	},
	{
		garage_id: 178,
		part_id: 261,
		quantity: 255,
	},
	{
		garage_id: 178,
		part_id: 262,
		quantity: 108,
	},
	{
		garage_id: 178,
		part_id: 263,
		quantity: 12,
	},
	{
		garage_id: 178,
		part_id: 264,
		quantity: 11,
	},
	{
		garage_id: 178,
		part_id: 265,
		quantity: 353,
	},
	{
		garage_id: 178,
		part_id: 266,
		quantity: 392,
	},
	{
		garage_id: 178,
		part_id: 267,
		quantity: 14,
	},
	{
		garage_id: 178,
		part_id: 268,
		quantity: 467,
	},
	{
		garage_id: 178,
		part_id: 269,
		quantity: 339,
	},
	{
		garage_id: 178,
		part_id: 270,
		quantity: 402,
	},
	{
		garage_id: 178,
		part_id: 271,
		quantity: 79,
	},
	{
		garage_id: 178,
		part_id: 272,
		quantity: 166,
	},
	{
		garage_id: 178,
		part_id: 273,
		quantity: 360,
	},
	{
		garage_id: 178,
		part_id: 274,
		quantity: 293,
	},
	{
		garage_id: 178,
		part_id: 275,
		quantity: 350,
	},
	{
		garage_id: 178,
		part_id: 276,
		quantity: 423,
	},
	{
		garage_id: 178,
		part_id: 277,
		quantity: 340,
	},
	{
		garage_id: 178,
		part_id: 278,
		quantity: 315,
	},
	{
		garage_id: 178,
		part_id: 279,
		quantity: 212,
	},
	{
		garage_id: 178,
		part_id: 280,
		quantity: 254,
	},
	{
		garage_id: 178,
		part_id: 281,
		quantity: 83,
	},
	{
		garage_id: 178,
		part_id: 282,
		quantity: 254,
	},
	{
		garage_id: 178,
		part_id: 283,
		quantity: 72,
	},
	{
		garage_id: 178,
		part_id: 284,
		quantity: 314,
	},
	{
		garage_id: 178,
		part_id: 285,
		quantity: 88,
	},
	{
		garage_id: 178,
		part_id: 286,
		quantity: 315,
	},
	{
		garage_id: 178,
		part_id: 287,
		quantity: 59,
	},
	{
		garage_id: 178,
		part_id: 288,
		quantity: 41,
	},
	{
		garage_id: 178,
		part_id: 289,
		quantity: 30,
	},
	{
		garage_id: 178,
		part_id: 290,
		quantity: 379,
	},
	{
		garage_id: 178,
		part_id: 291,
		quantity: 100,
	},
	{
		garage_id: 178,
		part_id: 292,
		quantity: 243,
	},
	{
		garage_id: 178,
		part_id: 293,
		quantity: 409,
	},
	{
		garage_id: 178,
		part_id: 294,
		quantity: 13,
	},
	{
		garage_id: 178,
		part_id: 295,
		quantity: 154,
	},
	{
		garage_id: 178,
		part_id: 296,
		quantity: 476,
	},
	{
		garage_id: 178,
		part_id: 297,
		quantity: 415,
	},
	{
		garage_id: 178,
		part_id: 298,
		quantity: 404,
	},
	{
		garage_id: 178,
		part_id: 299,
		quantity: 333,
	},
	{
		garage_id: 178,
		part_id: 300,
		quantity: 343,
	},
	{
		garage_id: 178,
		part_id: 301,
		quantity: 0,
	},
	{
		garage_id: 178,
		part_id: 302,
		quantity: 128,
	},
	{
		garage_id: 178,
		part_id: 303,
		quantity: 213,
	},
	{
		garage_id: 178,
		part_id: 304,
		quantity: 154,
	},
	{
		garage_id: 178,
		part_id: 305,
		quantity: 1,
	},
	{
		garage_id: 178,
		part_id: 306,
		quantity: 136,
	},
	{
		garage_id: 178,
		part_id: 307,
		quantity: 492,
	},
	{
		garage_id: 178,
		part_id: 308,
		quantity: 483,
	},
	{
		garage_id: 178,
		part_id: 309,
		quantity: 186,
	},
	{
		garage_id: 178,
		part_id: 310,
		quantity: 352,
	},
	{
		garage_id: 178,
		part_id: 311,
		quantity: 229,
	},
	{
		garage_id: 178,
		part_id: 312,
		quantity: 307,
	},
	{
		garage_id: 178,
		part_id: 313,
		quantity: 297,
	},
	{
		garage_id: 178,
		part_id: 314,
		quantity: 101,
	},
	{
		garage_id: 178,
		part_id: 315,
		quantity: 343,
	},
	{
		garage_id: 178,
		part_id: 316,
		quantity: 365,
	},
	{
		garage_id: 178,
		part_id: 317,
		quantity: 400,
	},
	{
		garage_id: 178,
		part_id: 318,
		quantity: 265,
	},
	{
		garage_id: 178,
		part_id: 319,
		quantity: 16,
	},
	{
		garage_id: 178,
		part_id: 320,
		quantity: 244,
	},
	{
		garage_id: 178,
		part_id: 321,
		quantity: 373,
	},
	{
		garage_id: 178,
		part_id: 322,
		quantity: 3,
	},
	{
		garage_id: 178,
		part_id: 323,
		quantity: 249,
	},
	{
		garage_id: 178,
		part_id: 324,
		quantity: 53,
	},
	{
		garage_id: 178,
		part_id: 325,
		quantity: 250,
	},
	{
		garage_id: 178,
		part_id: 326,
		quantity: 273,
	},
	{
		garage_id: 178,
		part_id: 327,
		quantity: 457,
	},
	{
		garage_id: 178,
		part_id: 328,
		quantity: 162,
	},
	{
		garage_id: 178,
		part_id: 329,
		quantity: 57,
	},
	{
		garage_id: 178,
		part_id: 330,
		quantity: 132,
	},
	{
		garage_id: 178,
		part_id: 331,
		quantity: 50,
	},
	{
		garage_id: 178,
		part_id: 332,
		quantity: 130,
	},
	{
		garage_id: 178,
		part_id: 333,
		quantity: 20,
	},
	{
		garage_id: 178,
		part_id: 334,
		quantity: 375,
	},
	{
		garage_id: 178,
		part_id: 335,
		quantity: 357,
	},
	{
		garage_id: 178,
		part_id: 336,
		quantity: 356,
	},
	{
		garage_id: 178,
		part_id: 337,
		quantity: 196,
	},
	{
		garage_id: 178,
		part_id: 338,
		quantity: 475,
	},
	{
		garage_id: 178,
		part_id: 339,
		quantity: 48,
	},
	{
		garage_id: 178,
		part_id: 340,
		quantity: 377,
	},
	{
		garage_id: 178,
		part_id: 341,
		quantity: 400,
	},
	{
		garage_id: 178,
		part_id: 342,
		quantity: 231,
	},
	{
		garage_id: 178,
		part_id: 343,
		quantity: 44,
	},
	{
		garage_id: 178,
		part_id: 344,
		quantity: 128,
	},
	{
		garage_id: 178,
		part_id: 345,
		quantity: 66,
	},
	{
		garage_id: 178,
		part_id: 346,
		quantity: 388,
	},
	{
		garage_id: 178,
		part_id: 347,
		quantity: 346,
	},
	{
		garage_id: 178,
		part_id: 348,
		quantity: 419,
	},
	{
		garage_id: 178,
		part_id: 349,
		quantity: 445,
	},
	{
		garage_id: 178,
		part_id: 350,
		quantity: 182,
	},
	{
		garage_id: 178,
		part_id: 351,
		quantity: 334,
	},
	{
		garage_id: 178,
		part_id: 352,
		quantity: 154,
	},
	{
		garage_id: 178,
		part_id: 353,
		quantity: 357,
	},
	{
		garage_id: 178,
		part_id: 354,
		quantity: 377,
	},
	{
		garage_id: 178,
		part_id: 355,
		quantity: 271,
	},
	{
		garage_id: 178,
		part_id: 356,
		quantity: 481,
	},
	{
		garage_id: 178,
		part_id: 357,
		quantity: 334,
	},
	{
		garage_id: 178,
		part_id: 358,
		quantity: 233,
	},
	{
		garage_id: 178,
		part_id: 359,
		quantity: 75,
	},
	{
		garage_id: 178,
		part_id: 360,
		quantity: 245,
	},
	{
		garage_id: 178,
		part_id: 361,
		quantity: 120,
	},
	{
		garage_id: 178,
		part_id: 362,
		quantity: 241,
	},
	{
		garage_id: 178,
		part_id: 363,
		quantity: 326,
	},
	{
		garage_id: 178,
		part_id: 364,
		quantity: 83,
	},
	{
		garage_id: 178,
		part_id: 365,
		quantity: 487,
	},
	{
		garage_id: 178,
		part_id: 366,
		quantity: 421,
	},
	{
		garage_id: 178,
		part_id: 367,
		quantity: 471,
	},
	{
		garage_id: 178,
		part_id: 368,
		quantity: 179,
	},
	{
		garage_id: 178,
		part_id: 369,
		quantity: 68,
	},
	{
		garage_id: 178,
		part_id: 370,
		quantity: 134,
	},
	{
		garage_id: 178,
		part_id: 371,
		quantity: 349,
	},
	{
		garage_id: 178,
		part_id: 372,
		quantity: 236,
	},
	{
		garage_id: 178,
		part_id: 373,
		quantity: 427,
	},
	{
		garage_id: 178,
		part_id: 374,
		quantity: 104,
	},
	{
		garage_id: 178,
		part_id: 375,
		quantity: 271,
	},
	{
		garage_id: 178,
		part_id: 376,
		quantity: 1,
	},
	{
		garage_id: 178,
		part_id: 377,
		quantity: 200,
	},
	{
		garage_id: 178,
		part_id: 378,
		quantity: 313,
	},
	{
		garage_id: 178,
		part_id: 379,
		quantity: 440,
	},
	{
		garage_id: 178,
		part_id: 380,
		quantity: 231,
	},
	{
		garage_id: 178,
		part_id: 381,
		quantity: 110,
	},
	{
		garage_id: 178,
		part_id: 382,
		quantity: 343,
	},
	{
		garage_id: 178,
		part_id: 383,
		quantity: 284,
	},
	{
		garage_id: 178,
		part_id: 384,
		quantity: 460,
	},
	{
		garage_id: 178,
		part_id: 385,
		quantity: 409,
	},
	{
		garage_id: 178,
		part_id: 386,
		quantity: 129,
	},
	{
		garage_id: 178,
		part_id: 387,
		quantity: 165,
	},
	{
		garage_id: 178,
		part_id: 388,
		quantity: 386,
	},
	{
		garage_id: 178,
		part_id: 389,
		quantity: 431,
	},
	{
		garage_id: 178,
		part_id: 390,
		quantity: 39,
	},
	{
		garage_id: 178,
		part_id: 391,
		quantity: 348,
	},
	{
		garage_id: 178,
		part_id: 392,
		quantity: 180,
	},
	{
		garage_id: 178,
		part_id: 393,
		quantity: 353,
	},
	{
		garage_id: 178,
		part_id: 394,
		quantity: 40,
	},
	{
		garage_id: 178,
		part_id: 395,
		quantity: 383,
	},
	{
		garage_id: 178,
		part_id: 396,
		quantity: 488,
	},
	{
		garage_id: 178,
		part_id: 397,
		quantity: 352,
	},
	{
		garage_id: 178,
		part_id: 398,
		quantity: 292,
	},
	{
		garage_id: 178,
		part_id: 399,
		quantity: 309,
	},
	{
		garage_id: 179,
		part_id: 0,
		quantity: 176,
	},
	{
		garage_id: 179,
		part_id: 1,
		quantity: 491,
	},
	{
		garage_id: 179,
		part_id: 2,
		quantity: 271,
	},
	{
		garage_id: 179,
		part_id: 3,
		quantity: 123,
	},
	{
		garage_id: 179,
		part_id: 4,
		quantity: 408,
	},
	{
		garage_id: 179,
		part_id: 5,
		quantity: 352,
	},
	{
		garage_id: 179,
		part_id: 6,
		quantity: 492,
	},
	{
		garage_id: 179,
		part_id: 7,
		quantity: 27,
	},
	{
		garage_id: 179,
		part_id: 8,
		quantity: 322,
	},
	{
		garage_id: 179,
		part_id: 9,
		quantity: 434,
	},
	{
		garage_id: 179,
		part_id: 10,
		quantity: 196,
	},
	{
		garage_id: 179,
		part_id: 11,
		quantity: 98,
	},
	{
		garage_id: 179,
		part_id: 12,
		quantity: 449,
	},
	{
		garage_id: 179,
		part_id: 13,
		quantity: 423,
	},
	{
		garage_id: 179,
		part_id: 14,
		quantity: 361,
	},
	{
		garage_id: 179,
		part_id: 15,
		quantity: 242,
	},
	{
		garage_id: 179,
		part_id: 16,
		quantity: 460,
	},
	{
		garage_id: 179,
		part_id: 17,
		quantity: 134,
	},
	{
		garage_id: 179,
		part_id: 18,
		quantity: 464,
	},
	{
		garage_id: 179,
		part_id: 19,
		quantity: 148,
	},
	{
		garage_id: 179,
		part_id: 20,
		quantity: 64,
	},
	{
		garage_id: 179,
		part_id: 21,
		quantity: 180,
	},
	{
		garage_id: 179,
		part_id: 22,
		quantity: 448,
	},
	{
		garage_id: 179,
		part_id: 23,
		quantity: 218,
	},
	{
		garage_id: 179,
		part_id: 24,
		quantity: 33,
	},
	{
		garage_id: 179,
		part_id: 25,
		quantity: 328,
	},
	{
		garage_id: 179,
		part_id: 26,
		quantity: 117,
	},
	{
		garage_id: 179,
		part_id: 27,
		quantity: 212,
	},
	{
		garage_id: 179,
		part_id: 28,
		quantity: 280,
	},
	{
		garage_id: 179,
		part_id: 29,
		quantity: 157,
	},
	{
		garage_id: 179,
		part_id: 30,
		quantity: 438,
	},
	{
		garage_id: 179,
		part_id: 31,
		quantity: 431,
	},
	{
		garage_id: 179,
		part_id: 32,
		quantity: 245,
	},
	{
		garage_id: 179,
		part_id: 33,
		quantity: 246,
	},
	{
		garage_id: 179,
		part_id: 34,
		quantity: 50,
	},
	{
		garage_id: 179,
		part_id: 35,
		quantity: 179,
	},
	{
		garage_id: 179,
		part_id: 36,
		quantity: 165,
	},
	{
		garage_id: 179,
		part_id: 37,
		quantity: 108,
	},
	{
		garage_id: 179,
		part_id: 38,
		quantity: 295,
	},
	{
		garage_id: 179,
		part_id: 39,
		quantity: 2,
	},
	{
		garage_id: 179,
		part_id: 40,
		quantity: 433,
	},
	{
		garage_id: 179,
		part_id: 41,
		quantity: 139,
	},
	{
		garage_id: 179,
		part_id: 42,
		quantity: 311,
	},
	{
		garage_id: 179,
		part_id: 43,
		quantity: 422,
	},
	{
		garage_id: 179,
		part_id: 44,
		quantity: 23,
	},
	{
		garage_id: 179,
		part_id: 45,
		quantity: 82,
	},
	{
		garage_id: 179,
		part_id: 46,
		quantity: 389,
	},
	{
		garage_id: 179,
		part_id: 47,
		quantity: 379,
	},
	{
		garage_id: 179,
		part_id: 48,
		quantity: 498,
	},
	{
		garage_id: 179,
		part_id: 49,
		quantity: 70,
	},
	{
		garage_id: 179,
		part_id: 50,
		quantity: 115,
	},
	{
		garage_id: 179,
		part_id: 51,
		quantity: 435,
	},
	{
		garage_id: 179,
		part_id: 52,
		quantity: 107,
	},
	{
		garage_id: 179,
		part_id: 53,
		quantity: 262,
	},
	{
		garage_id: 179,
		part_id: 54,
		quantity: 53,
	},
	{
		garage_id: 179,
		part_id: 55,
		quantity: 385,
	},
	{
		garage_id: 179,
		part_id: 56,
		quantity: 210,
	},
	{
		garage_id: 179,
		part_id: 57,
		quantity: 424,
	},
	{
		garage_id: 179,
		part_id: 58,
		quantity: 82,
	},
	{
		garage_id: 179,
		part_id: 59,
		quantity: 362,
	},
	{
		garage_id: 179,
		part_id: 60,
		quantity: 59,
	},
	{
		garage_id: 179,
		part_id: 61,
		quantity: 319,
	},
	{
		garage_id: 179,
		part_id: 62,
		quantity: 294,
	},
	{
		garage_id: 179,
		part_id: 63,
		quantity: 333,
	},
	{
		garage_id: 179,
		part_id: 64,
		quantity: 145,
	},
	{
		garage_id: 179,
		part_id: 65,
		quantity: 229,
	},
	{
		garage_id: 179,
		part_id: 66,
		quantity: 428,
	},
	{
		garage_id: 179,
		part_id: 67,
		quantity: 234,
	},
	{
		garage_id: 179,
		part_id: 68,
		quantity: 117,
	},
	{
		garage_id: 179,
		part_id: 69,
		quantity: 250,
	},
	{
		garage_id: 179,
		part_id: 70,
		quantity: 388,
	},
	{
		garage_id: 179,
		part_id: 71,
		quantity: 133,
	},
	{
		garage_id: 179,
		part_id: 72,
		quantity: 90,
	},
	{
		garage_id: 179,
		part_id: 73,
		quantity: 55,
	},
	{
		garage_id: 179,
		part_id: 74,
		quantity: 159,
	},
	{
		garage_id: 179,
		part_id: 75,
		quantity: 252,
	},
	{
		garage_id: 179,
		part_id: 76,
		quantity: 411,
	},
	{
		garage_id: 179,
		part_id: 77,
		quantity: 125,
	},
	{
		garage_id: 179,
		part_id: 78,
		quantity: 1,
	},
	{
		garage_id: 179,
		part_id: 79,
		quantity: 114,
	},
	{
		garage_id: 179,
		part_id: 80,
		quantity: 390,
	},
	{
		garage_id: 179,
		part_id: 81,
		quantity: 467,
	},
	{
		garage_id: 179,
		part_id: 82,
		quantity: 149,
	},
	{
		garage_id: 179,
		part_id: 83,
		quantity: 212,
	},
	{
		garage_id: 179,
		part_id: 84,
		quantity: 480,
	},
	{
		garage_id: 179,
		part_id: 85,
		quantity: 108,
	},
	{
		garage_id: 179,
		part_id: 86,
		quantity: 426,
	},
	{
		garage_id: 179,
		part_id: 87,
		quantity: 53,
	},
	{
		garage_id: 179,
		part_id: 88,
		quantity: 315,
	},
	{
		garage_id: 179,
		part_id: 89,
		quantity: 92,
	},
	{
		garage_id: 179,
		part_id: 90,
		quantity: 378,
	},
	{
		garage_id: 179,
		part_id: 91,
		quantity: 426,
	},
	{
		garage_id: 179,
		part_id: 92,
		quantity: 229,
	},
	{
		garage_id: 179,
		part_id: 93,
		quantity: 322,
	},
	{
		garage_id: 179,
		part_id: 94,
		quantity: 62,
	},
	{
		garage_id: 179,
		part_id: 95,
		quantity: 278,
	},
	{
		garage_id: 179,
		part_id: 96,
		quantity: 126,
	},
	{
		garage_id: 179,
		part_id: 97,
		quantity: 387,
	},
	{
		garage_id: 179,
		part_id: 98,
		quantity: 409,
	},
	{
		garage_id: 179,
		part_id: 99,
		quantity: 96,
	},
	{
		garage_id: 179,
		part_id: 100,
		quantity: 308,
	},
	{
		garage_id: 179,
		part_id: 101,
		quantity: 486,
	},
	{
		garage_id: 179,
		part_id: 102,
		quantity: 170,
	},
	{
		garage_id: 179,
		part_id: 103,
		quantity: 248,
	},
	{
		garage_id: 179,
		part_id: 104,
		quantity: 12,
	},
	{
		garage_id: 179,
		part_id: 105,
		quantity: 172,
	},
	{
		garage_id: 179,
		part_id: 106,
		quantity: 309,
	},
	{
		garage_id: 179,
		part_id: 107,
		quantity: 114,
	},
	{
		garage_id: 179,
		part_id: 108,
		quantity: 292,
	},
	{
		garage_id: 179,
		part_id: 109,
		quantity: 429,
	},
	{
		garage_id: 179,
		part_id: 110,
		quantity: 410,
	},
	{
		garage_id: 179,
		part_id: 111,
		quantity: 227,
	},
	{
		garage_id: 179,
		part_id: 112,
		quantity: 457,
	},
	{
		garage_id: 179,
		part_id: 113,
		quantity: 58,
	},
	{
		garage_id: 179,
		part_id: 114,
		quantity: 356,
	},
	{
		garage_id: 179,
		part_id: 115,
		quantity: 105,
	},
	{
		garage_id: 179,
		part_id: 116,
		quantity: 371,
	},
	{
		garage_id: 179,
		part_id: 117,
		quantity: 70,
	},
	{
		garage_id: 179,
		part_id: 118,
		quantity: 103,
	},
	{
		garage_id: 179,
		part_id: 119,
		quantity: 70,
	},
	{
		garage_id: 179,
		part_id: 120,
		quantity: 248,
	},
	{
		garage_id: 179,
		part_id: 121,
		quantity: 144,
	},
	{
		garage_id: 179,
		part_id: 122,
		quantity: 219,
	},
	{
		garage_id: 179,
		part_id: 123,
		quantity: 441,
	},
	{
		garage_id: 179,
		part_id: 124,
		quantity: 415,
	},
	{
		garage_id: 179,
		part_id: 125,
		quantity: 167,
	},
	{
		garage_id: 179,
		part_id: 126,
		quantity: 321,
	},
	{
		garage_id: 179,
		part_id: 127,
		quantity: 197,
	},
	{
		garage_id: 179,
		part_id: 128,
		quantity: 397,
	},
	{
		garage_id: 179,
		part_id: 129,
		quantity: 17,
	},
	{
		garage_id: 179,
		part_id: 130,
		quantity: 24,
	},
	{
		garage_id: 179,
		part_id: 131,
		quantity: 404,
	},
	{
		garage_id: 179,
		part_id: 132,
		quantity: 334,
	},
	{
		garage_id: 179,
		part_id: 133,
		quantity: 161,
	},
	{
		garage_id: 179,
		part_id: 134,
		quantity: 45,
	},
	{
		garage_id: 179,
		part_id: 135,
		quantity: 127,
	},
	{
		garage_id: 179,
		part_id: 136,
		quantity: 358,
	},
	{
		garage_id: 179,
		part_id: 137,
		quantity: 41,
	},
	{
		garage_id: 179,
		part_id: 138,
		quantity: 157,
	},
	{
		garage_id: 179,
		part_id: 139,
		quantity: 169,
	},
	{
		garage_id: 179,
		part_id: 140,
		quantity: 221,
	},
	{
		garage_id: 179,
		part_id: 141,
		quantity: 236,
	},
	{
		garage_id: 179,
		part_id: 142,
		quantity: 236,
	},
	{
		garage_id: 179,
		part_id: 143,
		quantity: 482,
	},
	{
		garage_id: 179,
		part_id: 144,
		quantity: 188,
	},
	{
		garage_id: 179,
		part_id: 145,
		quantity: 50,
	},
	{
		garage_id: 179,
		part_id: 146,
		quantity: 346,
	},
	{
		garage_id: 179,
		part_id: 147,
		quantity: 4,
	},
	{
		garage_id: 179,
		part_id: 148,
		quantity: 127,
	},
	{
		garage_id: 179,
		part_id: 149,
		quantity: 66,
	},
	{
		garage_id: 179,
		part_id: 150,
		quantity: 459,
	},
	{
		garage_id: 179,
		part_id: 151,
		quantity: 67,
	},
	{
		garage_id: 179,
		part_id: 152,
		quantity: 384,
	},
	{
		garage_id: 179,
		part_id: 153,
		quantity: 303,
	},
	{
		garage_id: 179,
		part_id: 154,
		quantity: 416,
	},
	{
		garage_id: 179,
		part_id: 155,
		quantity: 473,
	},
	{
		garage_id: 179,
		part_id: 156,
		quantity: 476,
	},
	{
		garage_id: 179,
		part_id: 157,
		quantity: 365,
	},
	{
		garage_id: 179,
		part_id: 158,
		quantity: 227,
	},
	{
		garage_id: 179,
		part_id: 159,
		quantity: 343,
	},
	{
		garage_id: 179,
		part_id: 160,
		quantity: 163,
	},
	{
		garage_id: 179,
		part_id: 161,
		quantity: 47,
	},
	{
		garage_id: 179,
		part_id: 162,
		quantity: 360,
	},
	{
		garage_id: 179,
		part_id: 163,
		quantity: 22,
	},
	{
		garage_id: 179,
		part_id: 164,
		quantity: 325,
	},
	{
		garage_id: 179,
		part_id: 165,
		quantity: 211,
	},
	{
		garage_id: 179,
		part_id: 166,
		quantity: 70,
	},
	{
		garage_id: 179,
		part_id: 167,
		quantity: 166,
	},
	{
		garage_id: 179,
		part_id: 168,
		quantity: 451,
	},
	{
		garage_id: 179,
		part_id: 169,
		quantity: 180,
	},
	{
		garage_id: 179,
		part_id: 170,
		quantity: 284,
	},
	{
		garage_id: 179,
		part_id: 171,
		quantity: 333,
	},
	{
		garage_id: 179,
		part_id: 172,
		quantity: 26,
	},
	{
		garage_id: 179,
		part_id: 173,
		quantity: 78,
	},
	{
		garage_id: 179,
		part_id: 174,
		quantity: 412,
	},
	{
		garage_id: 179,
		part_id: 175,
		quantity: 407,
	},
	{
		garage_id: 179,
		part_id: 176,
		quantity: 349,
	},
	{
		garage_id: 179,
		part_id: 177,
		quantity: 110,
	},
	{
		garage_id: 179,
		part_id: 178,
		quantity: 220,
	},
	{
		garage_id: 179,
		part_id: 179,
		quantity: 9,
	},
	{
		garage_id: 179,
		part_id: 180,
		quantity: 252,
	},
	{
		garage_id: 179,
		part_id: 181,
		quantity: 237,
	},
	{
		garage_id: 179,
		part_id: 182,
		quantity: 80,
	},
	{
		garage_id: 179,
		part_id: 183,
		quantity: 8,
	},
	{
		garage_id: 179,
		part_id: 184,
		quantity: 445,
	},
	{
		garage_id: 179,
		part_id: 185,
		quantity: 169,
	},
	{
		garage_id: 179,
		part_id: 186,
		quantity: 296,
	},
	{
		garage_id: 179,
		part_id: 187,
		quantity: 62,
	},
	{
		garage_id: 179,
		part_id: 188,
		quantity: 419,
	},
	{
		garage_id: 179,
		part_id: 189,
		quantity: 45,
	},
	{
		garage_id: 179,
		part_id: 190,
		quantity: 55,
	},
	{
		garage_id: 179,
		part_id: 191,
		quantity: 364,
	},
	{
		garage_id: 179,
		part_id: 192,
		quantity: 350,
	},
	{
		garage_id: 179,
		part_id: 193,
		quantity: 158,
	},
	{
		garage_id: 179,
		part_id: 194,
		quantity: 377,
	},
	{
		garage_id: 179,
		part_id: 195,
		quantity: 67,
	},
	{
		garage_id: 179,
		part_id: 196,
		quantity: 171,
	},
	{
		garage_id: 179,
		part_id: 197,
		quantity: 149,
	},
	{
		garage_id: 179,
		part_id: 198,
		quantity: 345,
	},
	{
		garage_id: 179,
		part_id: 199,
		quantity: 234,
	},
	{
		garage_id: 179,
		part_id: 200,
		quantity: 246,
	},
	{
		garage_id: 179,
		part_id: 201,
		quantity: 328,
	},
	{
		garage_id: 179,
		part_id: 202,
		quantity: 304,
	},
	{
		garage_id: 179,
		part_id: 203,
		quantity: 328,
	},
	{
		garage_id: 179,
		part_id: 204,
		quantity: 460,
	},
	{
		garage_id: 179,
		part_id: 205,
		quantity: 209,
	},
	{
		garage_id: 179,
		part_id: 206,
		quantity: 453,
	},
	{
		garage_id: 179,
		part_id: 207,
		quantity: 385,
	},
	{
		garage_id: 179,
		part_id: 208,
		quantity: 2,
	},
	{
		garage_id: 179,
		part_id: 209,
		quantity: 11,
	},
	{
		garage_id: 179,
		part_id: 210,
		quantity: 92,
	},
	{
		garage_id: 179,
		part_id: 211,
		quantity: 224,
	},
	{
		garage_id: 179,
		part_id: 212,
		quantity: 56,
	},
	{
		garage_id: 179,
		part_id: 213,
		quantity: 169,
	},
	{
		garage_id: 179,
		part_id: 214,
		quantity: 333,
	},
	{
		garage_id: 179,
		part_id: 215,
		quantity: 77,
	},
	{
		garage_id: 179,
		part_id: 216,
		quantity: 469,
	},
	{
		garage_id: 179,
		part_id: 217,
		quantity: 7,
	},
	{
		garage_id: 179,
		part_id: 218,
		quantity: 427,
	},
	{
		garage_id: 179,
		part_id: 219,
		quantity: 340,
	},
	{
		garage_id: 179,
		part_id: 220,
		quantity: 304,
	},
	{
		garage_id: 179,
		part_id: 221,
		quantity: 3,
	},
	{
		garage_id: 179,
		part_id: 222,
		quantity: 346,
	},
	{
		garage_id: 179,
		part_id: 223,
		quantity: 391,
	},
	{
		garage_id: 179,
		part_id: 224,
		quantity: 198,
	},
	{
		garage_id: 179,
		part_id: 225,
		quantity: 290,
	},
	{
		garage_id: 179,
		part_id: 226,
		quantity: 107,
	},
	{
		garage_id: 179,
		part_id: 227,
		quantity: 301,
	},
	{
		garage_id: 179,
		part_id: 228,
		quantity: 495,
	},
	{
		garage_id: 179,
		part_id: 229,
		quantity: 163,
	},
	{
		garage_id: 179,
		part_id: 230,
		quantity: 161,
	},
	{
		garage_id: 179,
		part_id: 231,
		quantity: 50,
	},
	{
		garage_id: 179,
		part_id: 232,
		quantity: 485,
	},
	{
		garage_id: 179,
		part_id: 233,
		quantity: 480,
	},
	{
		garage_id: 179,
		part_id: 234,
		quantity: 239,
	},
	{
		garage_id: 179,
		part_id: 235,
		quantity: 301,
	},
	{
		garage_id: 179,
		part_id: 236,
		quantity: 5,
	},
	{
		garage_id: 179,
		part_id: 237,
		quantity: 118,
	},
	{
		garage_id: 179,
		part_id: 238,
		quantity: 323,
	},
	{
		garage_id: 179,
		part_id: 239,
		quantity: 186,
	},
	{
		garage_id: 179,
		part_id: 240,
		quantity: 219,
	},
	{
		garage_id: 179,
		part_id: 241,
		quantity: 257,
	},
	{
		garage_id: 179,
		part_id: 242,
		quantity: 142,
	},
	{
		garage_id: 179,
		part_id: 243,
		quantity: 192,
	},
	{
		garage_id: 179,
		part_id: 244,
		quantity: 327,
	},
	{
		garage_id: 179,
		part_id: 245,
		quantity: 312,
	},
	{
		garage_id: 179,
		part_id: 246,
		quantity: 113,
	},
	{
		garage_id: 179,
		part_id: 247,
		quantity: 152,
	},
	{
		garage_id: 179,
		part_id: 248,
		quantity: 17,
	},
	{
		garage_id: 179,
		part_id: 249,
		quantity: 484,
	},
	{
		garage_id: 179,
		part_id: 250,
		quantity: 485,
	},
	{
		garage_id: 179,
		part_id: 251,
		quantity: 51,
	},
	{
		garage_id: 179,
		part_id: 252,
		quantity: 66,
	},
	{
		garage_id: 179,
		part_id: 253,
		quantity: 237,
	},
	{
		garage_id: 179,
		part_id: 254,
		quantity: 335,
	},
	{
		garage_id: 179,
		part_id: 255,
		quantity: 229,
	},
	{
		garage_id: 179,
		part_id: 256,
		quantity: 298,
	},
	{
		garage_id: 179,
		part_id: 257,
		quantity: 311,
	},
	{
		garage_id: 179,
		part_id: 258,
		quantity: 287,
	},
	{
		garage_id: 179,
		part_id: 259,
		quantity: 188,
	},
	{
		garage_id: 179,
		part_id: 260,
		quantity: 118,
	},
	{
		garage_id: 179,
		part_id: 261,
		quantity: 245,
	},
	{
		garage_id: 179,
		part_id: 262,
		quantity: 321,
	},
	{
		garage_id: 179,
		part_id: 263,
		quantity: 170,
	},
	{
		garage_id: 179,
		part_id: 264,
		quantity: 445,
	},
	{
		garage_id: 179,
		part_id: 265,
		quantity: 13,
	},
	{
		garage_id: 179,
		part_id: 266,
		quantity: 97,
	},
	{
		garage_id: 179,
		part_id: 267,
		quantity: 2,
	},
	{
		garage_id: 179,
		part_id: 268,
		quantity: 32,
	},
	{
		garage_id: 179,
		part_id: 269,
		quantity: 145,
	},
	{
		garage_id: 179,
		part_id: 270,
		quantity: 381,
	},
	{
		garage_id: 179,
		part_id: 271,
		quantity: 383,
	},
	{
		garage_id: 179,
		part_id: 272,
		quantity: 122,
	},
	{
		garage_id: 179,
		part_id: 273,
		quantity: 23,
	},
	{
		garage_id: 179,
		part_id: 274,
		quantity: 48,
	},
	{
		garage_id: 179,
		part_id: 275,
		quantity: 325,
	},
	{
		garage_id: 179,
		part_id: 276,
		quantity: 100,
	},
	{
		garage_id: 179,
		part_id: 277,
		quantity: 358,
	},
	{
		garage_id: 179,
		part_id: 278,
		quantity: 301,
	},
	{
		garage_id: 179,
		part_id: 279,
		quantity: 242,
	},
	{
		garage_id: 179,
		part_id: 280,
		quantity: 454,
	},
	{
		garage_id: 179,
		part_id: 281,
		quantity: 9,
	},
	{
		garage_id: 179,
		part_id: 282,
		quantity: 26,
	},
	{
		garage_id: 179,
		part_id: 283,
		quantity: 253,
	},
	{
		garage_id: 179,
		part_id: 284,
		quantity: 413,
	},
	{
		garage_id: 179,
		part_id: 285,
		quantity: 195,
	},
	{
		garage_id: 179,
		part_id: 286,
		quantity: 114,
	},
	{
		garage_id: 179,
		part_id: 287,
		quantity: 160,
	},
	{
		garage_id: 179,
		part_id: 288,
		quantity: 251,
	},
	{
		garage_id: 179,
		part_id: 289,
		quantity: 4,
	},
	{
		garage_id: 179,
		part_id: 290,
		quantity: 407,
	},
	{
		garage_id: 179,
		part_id: 291,
		quantity: 73,
	},
	{
		garage_id: 179,
		part_id: 292,
		quantity: 430,
	},
	{
		garage_id: 179,
		part_id: 293,
		quantity: 275,
	},
	{
		garage_id: 179,
		part_id: 294,
		quantity: 206,
	},
	{
		garage_id: 179,
		part_id: 295,
		quantity: 454,
	},
	{
		garage_id: 179,
		part_id: 296,
		quantity: 487,
	},
	{
		garage_id: 179,
		part_id: 297,
		quantity: 62,
	},
	{
		garage_id: 179,
		part_id: 298,
		quantity: 240,
	},
	{
		garage_id: 179,
		part_id: 299,
		quantity: 320,
	},
	{
		garage_id: 179,
		part_id: 300,
		quantity: 316,
	},
	{
		garage_id: 179,
		part_id: 301,
		quantity: 113,
	},
	{
		garage_id: 179,
		part_id: 302,
		quantity: 56,
	},
	{
		garage_id: 179,
		part_id: 303,
		quantity: 327,
	},
	{
		garage_id: 179,
		part_id: 304,
		quantity: 199,
	},
	{
		garage_id: 179,
		part_id: 305,
		quantity: 307,
	},
	{
		garage_id: 179,
		part_id: 306,
		quantity: 450,
	},
	{
		garage_id: 179,
		part_id: 307,
		quantity: 281,
	},
	{
		garage_id: 179,
		part_id: 308,
		quantity: 264,
	},
	{
		garage_id: 179,
		part_id: 309,
		quantity: 218,
	},
	{
		garage_id: 179,
		part_id: 310,
		quantity: 392,
	},
	{
		garage_id: 179,
		part_id: 311,
		quantity: 468,
	},
	{
		garage_id: 179,
		part_id: 312,
		quantity: 325,
	},
	{
		garage_id: 179,
		part_id: 313,
		quantity: 252,
	},
	{
		garage_id: 179,
		part_id: 314,
		quantity: 498,
	},
	{
		garage_id: 179,
		part_id: 315,
		quantity: 469,
	},
	{
		garage_id: 179,
		part_id: 316,
		quantity: 283,
	},
	{
		garage_id: 179,
		part_id: 317,
		quantity: 299,
	},
	{
		garage_id: 179,
		part_id: 318,
		quantity: 235,
	},
	{
		garage_id: 179,
		part_id: 319,
		quantity: 8,
	},
	{
		garage_id: 179,
		part_id: 320,
		quantity: 388,
	},
	{
		garage_id: 179,
		part_id: 321,
		quantity: 293,
	},
	{
		garage_id: 179,
		part_id: 322,
		quantity: 7,
	},
	{
		garage_id: 179,
		part_id: 323,
		quantity: 389,
	},
	{
		garage_id: 179,
		part_id: 324,
		quantity: 379,
	},
	{
		garage_id: 179,
		part_id: 325,
		quantity: 229,
	},
	{
		garage_id: 179,
		part_id: 326,
		quantity: 331,
	},
	{
		garage_id: 179,
		part_id: 327,
		quantity: 5,
	},
	{
		garage_id: 179,
		part_id: 328,
		quantity: 48,
	},
	{
		garage_id: 179,
		part_id: 329,
		quantity: 371,
	},
	{
		garage_id: 179,
		part_id: 330,
		quantity: 37,
	},
	{
		garage_id: 179,
		part_id: 331,
		quantity: 166,
	},
	{
		garage_id: 179,
		part_id: 332,
		quantity: 226,
	},
	{
		garage_id: 179,
		part_id: 333,
		quantity: 344,
	},
	{
		garage_id: 179,
		part_id: 334,
		quantity: 227,
	},
	{
		garage_id: 179,
		part_id: 335,
		quantity: 238,
	},
	{
		garage_id: 179,
		part_id: 336,
		quantity: 213,
	},
	{
		garage_id: 179,
		part_id: 337,
		quantity: 197,
	},
	{
		garage_id: 179,
		part_id: 338,
		quantity: 28,
	},
	{
		garage_id: 179,
		part_id: 339,
		quantity: 222,
	},
	{
		garage_id: 179,
		part_id: 340,
		quantity: 18,
	},
	{
		garage_id: 179,
		part_id: 341,
		quantity: 218,
	},
	{
		garage_id: 179,
		part_id: 342,
		quantity: 49,
	},
	{
		garage_id: 179,
		part_id: 343,
		quantity: 146,
	},
	{
		garage_id: 179,
		part_id: 344,
		quantity: 177,
	},
	{
		garage_id: 179,
		part_id: 345,
		quantity: 449,
	},
	{
		garage_id: 179,
		part_id: 346,
		quantity: 242,
	},
	{
		garage_id: 179,
		part_id: 347,
		quantity: 96,
	},
	{
		garage_id: 179,
		part_id: 348,
		quantity: 418,
	},
	{
		garage_id: 179,
		part_id: 349,
		quantity: 272,
	},
	{
		garage_id: 179,
		part_id: 350,
		quantity: 10,
	},
	{
		garage_id: 179,
		part_id: 351,
		quantity: 194,
	},
	{
		garage_id: 179,
		part_id: 352,
		quantity: 298,
	},
	{
		garage_id: 179,
		part_id: 353,
		quantity: 463,
	},
	{
		garage_id: 179,
		part_id: 354,
		quantity: 175,
	},
	{
		garage_id: 179,
		part_id: 355,
		quantity: 100,
	},
	{
		garage_id: 179,
		part_id: 356,
		quantity: 434,
	},
	{
		garage_id: 179,
		part_id: 357,
		quantity: 465,
	},
	{
		garage_id: 179,
		part_id: 358,
		quantity: 262,
	},
	{
		garage_id: 179,
		part_id: 359,
		quantity: 390,
	},
	{
		garage_id: 179,
		part_id: 360,
		quantity: 61,
	},
	{
		garage_id: 179,
		part_id: 361,
		quantity: 431,
	},
	{
		garage_id: 179,
		part_id: 362,
		quantity: 441,
	},
	{
		garage_id: 179,
		part_id: 363,
		quantity: 231,
	},
	{
		garage_id: 179,
		part_id: 364,
		quantity: 166,
	},
	{
		garage_id: 179,
		part_id: 365,
		quantity: 484,
	},
	{
		garage_id: 179,
		part_id: 366,
		quantity: 24,
	},
	{
		garage_id: 179,
		part_id: 367,
		quantity: 29,
	},
	{
		garage_id: 179,
		part_id: 368,
		quantity: 311,
	},
	{
		garage_id: 179,
		part_id: 369,
		quantity: 154,
	},
	{
		garage_id: 179,
		part_id: 370,
		quantity: 454,
	},
	{
		garage_id: 179,
		part_id: 371,
		quantity: 64,
	},
	{
		garage_id: 179,
		part_id: 372,
		quantity: 217,
	},
	{
		garage_id: 179,
		part_id: 373,
		quantity: 194,
	},
	{
		garage_id: 179,
		part_id: 374,
		quantity: 97,
	},
	{
		garage_id: 179,
		part_id: 375,
		quantity: 481,
	},
	{
		garage_id: 179,
		part_id: 376,
		quantity: 257,
	},
	{
		garage_id: 179,
		part_id: 377,
		quantity: 408,
	},
	{
		garage_id: 179,
		part_id: 378,
		quantity: 158,
	},
	{
		garage_id: 179,
		part_id: 379,
		quantity: 333,
	},
	{
		garage_id: 179,
		part_id: 380,
		quantity: 171,
	},
	{
		garage_id: 179,
		part_id: 381,
		quantity: 369,
	},
	{
		garage_id: 179,
		part_id: 382,
		quantity: 127,
	},
	{
		garage_id: 179,
		part_id: 383,
		quantity: 448,
	},
	{
		garage_id: 179,
		part_id: 384,
		quantity: 42,
	},
	{
		garage_id: 179,
		part_id: 385,
		quantity: 427,
	},
	{
		garage_id: 179,
		part_id: 386,
		quantity: 434,
	},
	{
		garage_id: 179,
		part_id: 387,
		quantity: 418,
	},
	{
		garage_id: 179,
		part_id: 388,
		quantity: 214,
	},
	{
		garage_id: 179,
		part_id: 389,
		quantity: 422,
	},
	{
		garage_id: 179,
		part_id: 390,
		quantity: 313,
	},
	{
		garage_id: 179,
		part_id: 391,
		quantity: 288,
	},
	{
		garage_id: 179,
		part_id: 392,
		quantity: 180,
	},
	{
		garage_id: 179,
		part_id: 393,
		quantity: 486,
	},
	{
		garage_id: 179,
		part_id: 394,
		quantity: 8,
	},
	{
		garage_id: 179,
		part_id: 395,
		quantity: 491,
	},
	{
		garage_id: 179,
		part_id: 396,
		quantity: 260,
	},
	{
		garage_id: 179,
		part_id: 397,
		quantity: 406,
	},
	{
		garage_id: 179,
		part_id: 398,
		quantity: 95,
	},
	{
		garage_id: 179,
		part_id: 399,
		quantity: 356,
	},
	{
		garage_id: 180,
		part_id: 0,
		quantity: 466,
	},
	{
		garage_id: 180,
		part_id: 1,
		quantity: 107,
	},
	{
		garage_id: 180,
		part_id: 2,
		quantity: 26,
	},
	{
		garage_id: 180,
		part_id: 3,
		quantity: 351,
	},
	{
		garage_id: 180,
		part_id: 4,
		quantity: 39,
	},
	{
		garage_id: 180,
		part_id: 5,
		quantity: 292,
	},
	{
		garage_id: 180,
		part_id: 6,
		quantity: 356,
	},
	{
		garage_id: 180,
		part_id: 7,
		quantity: 200,
	},
	{
		garage_id: 180,
		part_id: 8,
		quantity: 372,
	},
	{
		garage_id: 180,
		part_id: 9,
		quantity: 168,
	},
	{
		garage_id: 180,
		part_id: 10,
		quantity: 61,
	},
	{
		garage_id: 180,
		part_id: 11,
		quantity: 334,
	},
	{
		garage_id: 180,
		part_id: 12,
		quantity: 499,
	},
	{
		garage_id: 180,
		part_id: 13,
		quantity: 195,
	},
	{
		garage_id: 180,
		part_id: 14,
		quantity: 388,
	},
	{
		garage_id: 180,
		part_id: 15,
		quantity: 77,
	},
	{
		garage_id: 180,
		part_id: 16,
		quantity: 360,
	},
	{
		garage_id: 180,
		part_id: 17,
		quantity: 24,
	},
	{
		garage_id: 180,
		part_id: 18,
		quantity: 251,
	},
	{
		garage_id: 180,
		part_id: 19,
		quantity: 263,
	},
	{
		garage_id: 180,
		part_id: 20,
		quantity: 341,
	},
	{
		garage_id: 180,
		part_id: 21,
		quantity: 470,
	},
	{
		garage_id: 180,
		part_id: 22,
		quantity: 73,
	},
	{
		garage_id: 180,
		part_id: 23,
		quantity: 312,
	},
	{
		garage_id: 180,
		part_id: 24,
		quantity: 119,
	},
	{
		garage_id: 180,
		part_id: 25,
		quantity: 261,
	},
	{
		garage_id: 180,
		part_id: 26,
		quantity: 201,
	},
	{
		garage_id: 180,
		part_id: 27,
		quantity: 376,
	},
	{
		garage_id: 180,
		part_id: 28,
		quantity: 246,
	},
	{
		garage_id: 180,
		part_id: 29,
		quantity: 119,
	},
	{
		garage_id: 180,
		part_id: 30,
		quantity: 290,
	},
	{
		garage_id: 180,
		part_id: 31,
		quantity: 416,
	},
	{
		garage_id: 180,
		part_id: 32,
		quantity: 190,
	},
	{
		garage_id: 180,
		part_id: 33,
		quantity: 455,
	},
	{
		garage_id: 180,
		part_id: 34,
		quantity: 68,
	},
	{
		garage_id: 180,
		part_id: 35,
		quantity: 273,
	},
	{
		garage_id: 180,
		part_id: 36,
		quantity: 150,
	},
	{
		garage_id: 180,
		part_id: 37,
		quantity: 59,
	},
	{
		garage_id: 180,
		part_id: 38,
		quantity: 182,
	},
	{
		garage_id: 180,
		part_id: 39,
		quantity: 290,
	},
	{
		garage_id: 180,
		part_id: 40,
		quantity: 290,
	},
	{
		garage_id: 180,
		part_id: 41,
		quantity: 208,
	},
	{
		garage_id: 180,
		part_id: 42,
		quantity: 257,
	},
	{
		garage_id: 180,
		part_id: 43,
		quantity: 356,
	},
	{
		garage_id: 180,
		part_id: 44,
		quantity: 198,
	},
	{
		garage_id: 180,
		part_id: 45,
		quantity: 452,
	},
	{
		garage_id: 180,
		part_id: 46,
		quantity: 481,
	},
	{
		garage_id: 180,
		part_id: 47,
		quantity: 96,
	},
	{
		garage_id: 180,
		part_id: 48,
		quantity: 381,
	},
	{
		garage_id: 180,
		part_id: 49,
		quantity: 436,
	},
	{
		garage_id: 180,
		part_id: 50,
		quantity: 455,
	},
	{
		garage_id: 180,
		part_id: 51,
		quantity: 370,
	},
	{
		garage_id: 180,
		part_id: 52,
		quantity: 312,
	},
	{
		garage_id: 180,
		part_id: 53,
		quantity: 146,
	},
	{
		garage_id: 180,
		part_id: 54,
		quantity: 0,
	},
	{
		garage_id: 180,
		part_id: 55,
		quantity: 312,
	},
	{
		garage_id: 180,
		part_id: 56,
		quantity: 55,
	},
	{
		garage_id: 180,
		part_id: 57,
		quantity: 283,
	},
	{
		garage_id: 180,
		part_id: 58,
		quantity: 214,
	},
	{
		garage_id: 180,
		part_id: 59,
		quantity: 137,
	},
	{
		garage_id: 180,
		part_id: 60,
		quantity: 71,
	},
	{
		garage_id: 180,
		part_id: 61,
		quantity: 225,
	},
	{
		garage_id: 180,
		part_id: 62,
		quantity: 462,
	},
	{
		garage_id: 180,
		part_id: 63,
		quantity: 163,
	},
	{
		garage_id: 180,
		part_id: 64,
		quantity: 117,
	},
	{
		garage_id: 180,
		part_id: 65,
		quantity: 362,
	},
	{
		garage_id: 180,
		part_id: 66,
		quantity: 385,
	},
	{
		garage_id: 180,
		part_id: 67,
		quantity: 235,
	},
	{
		garage_id: 180,
		part_id: 68,
		quantity: 210,
	},
	{
		garage_id: 180,
		part_id: 69,
		quantity: 500,
	},
	{
		garage_id: 180,
		part_id: 70,
		quantity: 44,
	},
	{
		garage_id: 180,
		part_id: 71,
		quantity: 242,
	},
	{
		garage_id: 180,
		part_id: 72,
		quantity: 167,
	},
	{
		garage_id: 180,
		part_id: 73,
		quantity: 2,
	},
	{
		garage_id: 180,
		part_id: 74,
		quantity: 273,
	},
	{
		garage_id: 180,
		part_id: 75,
		quantity: 373,
	},
	{
		garage_id: 180,
		part_id: 76,
		quantity: 383,
	},
	{
		garage_id: 180,
		part_id: 77,
		quantity: 485,
	},
	{
		garage_id: 180,
		part_id: 78,
		quantity: 399,
	},
	{
		garage_id: 180,
		part_id: 79,
		quantity: 125,
	},
	{
		garage_id: 180,
		part_id: 80,
		quantity: 479,
	},
	{
		garage_id: 180,
		part_id: 81,
		quantity: 44,
	},
	{
		garage_id: 180,
		part_id: 82,
		quantity: 140,
	},
	{
		garage_id: 180,
		part_id: 83,
		quantity: 259,
	},
	{
		garage_id: 180,
		part_id: 84,
		quantity: 246,
	},
	{
		garage_id: 180,
		part_id: 85,
		quantity: 74,
	},
	{
		garage_id: 180,
		part_id: 86,
		quantity: 370,
	},
	{
		garage_id: 180,
		part_id: 87,
		quantity: 139,
	},
	{
		garage_id: 180,
		part_id: 88,
		quantity: 96,
	},
	{
		garage_id: 180,
		part_id: 89,
		quantity: 56,
	},
	{
		garage_id: 180,
		part_id: 90,
		quantity: 186,
	},
	{
		garage_id: 180,
		part_id: 91,
		quantity: 160,
	},
	{
		garage_id: 180,
		part_id: 92,
		quantity: 366,
	},
	{
		garage_id: 180,
		part_id: 93,
		quantity: 286,
	},
	{
		garage_id: 180,
		part_id: 94,
		quantity: 206,
	},
	{
		garage_id: 180,
		part_id: 95,
		quantity: 238,
	},
	{
		garage_id: 180,
		part_id: 96,
		quantity: 241,
	},
	{
		garage_id: 180,
		part_id: 97,
		quantity: 462,
	},
	{
		garage_id: 180,
		part_id: 98,
		quantity: 183,
	},
	{
		garage_id: 180,
		part_id: 99,
		quantity: 495,
	},
	{
		garage_id: 180,
		part_id: 100,
		quantity: 67,
	},
	{
		garage_id: 180,
		part_id: 101,
		quantity: 405,
	},
	{
		garage_id: 180,
		part_id: 102,
		quantity: 334,
	},
	{
		garage_id: 180,
		part_id: 103,
		quantity: 142,
	},
	{
		garage_id: 180,
		part_id: 104,
		quantity: 397,
	},
	{
		garage_id: 180,
		part_id: 105,
		quantity: 293,
	},
	{
		garage_id: 180,
		part_id: 106,
		quantity: 500,
	},
	{
		garage_id: 180,
		part_id: 107,
		quantity: 288,
	},
	{
		garage_id: 180,
		part_id: 108,
		quantity: 454,
	},
	{
		garage_id: 180,
		part_id: 109,
		quantity: 259,
	},
	{
		garage_id: 180,
		part_id: 110,
		quantity: 46,
	},
	{
		garage_id: 180,
		part_id: 111,
		quantity: 477,
	},
	{
		garage_id: 180,
		part_id: 112,
		quantity: 429,
	},
	{
		garage_id: 180,
		part_id: 113,
		quantity: 3,
	},
	{
		garage_id: 180,
		part_id: 114,
		quantity: 121,
	},
	{
		garage_id: 180,
		part_id: 115,
		quantity: 500,
	},
	{
		garage_id: 180,
		part_id: 116,
		quantity: 494,
	},
	{
		garage_id: 180,
		part_id: 117,
		quantity: 101,
	},
	{
		garage_id: 180,
		part_id: 118,
		quantity: 328,
	},
	{
		garage_id: 180,
		part_id: 119,
		quantity: 356,
	},
	{
		garage_id: 180,
		part_id: 120,
		quantity: 302,
	},
	{
		garage_id: 180,
		part_id: 121,
		quantity: 326,
	},
	{
		garage_id: 180,
		part_id: 122,
		quantity: 195,
	},
	{
		garage_id: 180,
		part_id: 123,
		quantity: 124,
	},
	{
		garage_id: 180,
		part_id: 124,
		quantity: 495,
	},
	{
		garage_id: 180,
		part_id: 125,
		quantity: 123,
	},
	{
		garage_id: 180,
		part_id: 126,
		quantity: 74,
	},
	{
		garage_id: 180,
		part_id: 127,
		quantity: 462,
	},
	{
		garage_id: 180,
		part_id: 128,
		quantity: 190,
	},
	{
		garage_id: 180,
		part_id: 129,
		quantity: 25,
	},
	{
		garage_id: 180,
		part_id: 130,
		quantity: 473,
	},
	{
		garage_id: 180,
		part_id: 131,
		quantity: 314,
	},
	{
		garage_id: 180,
		part_id: 132,
		quantity: 372,
	},
	{
		garage_id: 180,
		part_id: 133,
		quantity: 431,
	},
	{
		garage_id: 180,
		part_id: 134,
		quantity: 488,
	},
	{
		garage_id: 180,
		part_id: 135,
		quantity: 242,
	},
	{
		garage_id: 180,
		part_id: 136,
		quantity: 74,
	},
	{
		garage_id: 180,
		part_id: 137,
		quantity: 186,
	},
	{
		garage_id: 180,
		part_id: 138,
		quantity: 199,
	},
	{
		garage_id: 180,
		part_id: 139,
		quantity: 235,
	},
	{
		garage_id: 180,
		part_id: 140,
		quantity: 83,
	},
	{
		garage_id: 180,
		part_id: 141,
		quantity: 483,
	},
	{
		garage_id: 180,
		part_id: 142,
		quantity: 203,
	},
	{
		garage_id: 180,
		part_id: 143,
		quantity: 266,
	},
	{
		garage_id: 180,
		part_id: 144,
		quantity: 365,
	},
	{
		garage_id: 180,
		part_id: 145,
		quantity: 69,
	},
	{
		garage_id: 180,
		part_id: 146,
		quantity: 427,
	},
	{
		garage_id: 180,
		part_id: 147,
		quantity: 425,
	},
	{
		garage_id: 180,
		part_id: 148,
		quantity: 211,
	},
	{
		garage_id: 180,
		part_id: 149,
		quantity: 9,
	},
	{
		garage_id: 180,
		part_id: 150,
		quantity: 229,
	},
	{
		garage_id: 180,
		part_id: 151,
		quantity: 32,
	},
	{
		garage_id: 180,
		part_id: 152,
		quantity: 383,
	},
	{
		garage_id: 180,
		part_id: 153,
		quantity: 496,
	},
	{
		garage_id: 180,
		part_id: 154,
		quantity: 202,
	},
	{
		garage_id: 180,
		part_id: 155,
		quantity: 313,
	},
	{
		garage_id: 180,
		part_id: 156,
		quantity: 67,
	},
	{
		garage_id: 180,
		part_id: 157,
		quantity: 493,
	},
	{
		garage_id: 180,
		part_id: 158,
		quantity: 25,
	},
	{
		garage_id: 180,
		part_id: 159,
		quantity: 342,
	},
	{
		garage_id: 180,
		part_id: 160,
		quantity: 239,
	},
	{
		garage_id: 180,
		part_id: 161,
		quantity: 157,
	},
	{
		garage_id: 180,
		part_id: 162,
		quantity: 169,
	},
	{
		garage_id: 180,
		part_id: 163,
		quantity: 59,
	},
	{
		garage_id: 180,
		part_id: 164,
		quantity: 414,
	},
	{
		garage_id: 180,
		part_id: 165,
		quantity: 92,
	},
	{
		garage_id: 180,
		part_id: 166,
		quantity: 386,
	},
	{
		garage_id: 180,
		part_id: 167,
		quantity: 281,
	},
	{
		garage_id: 180,
		part_id: 168,
		quantity: 338,
	},
	{
		garage_id: 180,
		part_id: 169,
		quantity: 443,
	},
	{
		garage_id: 180,
		part_id: 170,
		quantity: 231,
	},
	{
		garage_id: 180,
		part_id: 171,
		quantity: 285,
	},
	{
		garage_id: 180,
		part_id: 172,
		quantity: 216,
	},
	{
		garage_id: 180,
		part_id: 173,
		quantity: 73,
	},
	{
		garage_id: 180,
		part_id: 174,
		quantity: 211,
	},
	{
		garage_id: 180,
		part_id: 175,
		quantity: 103,
	},
	{
		garage_id: 180,
		part_id: 176,
		quantity: 397,
	},
	{
		garage_id: 180,
		part_id: 177,
		quantity: 353,
	},
	{
		garage_id: 180,
		part_id: 178,
		quantity: 106,
	},
	{
		garage_id: 180,
		part_id: 179,
		quantity: 219,
	},
	{
		garage_id: 180,
		part_id: 180,
		quantity: 162,
	},
	{
		garage_id: 180,
		part_id: 181,
		quantity: 201,
	},
	{
		garage_id: 180,
		part_id: 182,
		quantity: 337,
	},
	{
		garage_id: 180,
		part_id: 183,
		quantity: 488,
	},
	{
		garage_id: 180,
		part_id: 184,
		quantity: 371,
	},
	{
		garage_id: 180,
		part_id: 185,
		quantity: 171,
	},
	{
		garage_id: 180,
		part_id: 186,
		quantity: 195,
	},
	{
		garage_id: 180,
		part_id: 187,
		quantity: 197,
	},
	{
		garage_id: 180,
		part_id: 188,
		quantity: 205,
	},
	{
		garage_id: 180,
		part_id: 189,
		quantity: 494,
	},
	{
		garage_id: 180,
		part_id: 190,
		quantity: 125,
	},
	{
		garage_id: 180,
		part_id: 191,
		quantity: 483,
	},
	{
		garage_id: 180,
		part_id: 192,
		quantity: 330,
	},
	{
		garage_id: 180,
		part_id: 193,
		quantity: 340,
	},
	{
		garage_id: 180,
		part_id: 194,
		quantity: 300,
	},
	{
		garage_id: 180,
		part_id: 195,
		quantity: 77,
	},
	{
		garage_id: 180,
		part_id: 196,
		quantity: 93,
	},
	{
		garage_id: 180,
		part_id: 197,
		quantity: 424,
	},
	{
		garage_id: 180,
		part_id: 198,
		quantity: 338,
	},
	{
		garage_id: 180,
		part_id: 199,
		quantity: 290,
	},
	{
		garage_id: 180,
		part_id: 200,
		quantity: 321,
	},
	{
		garage_id: 180,
		part_id: 201,
		quantity: 62,
	},
	{
		garage_id: 180,
		part_id: 202,
		quantity: 202,
	},
	{
		garage_id: 180,
		part_id: 203,
		quantity: 167,
	},
	{
		garage_id: 180,
		part_id: 204,
		quantity: 270,
	},
	{
		garage_id: 180,
		part_id: 205,
		quantity: 243,
	},
	{
		garage_id: 180,
		part_id: 206,
		quantity: 370,
	},
	{
		garage_id: 180,
		part_id: 207,
		quantity: 216,
	},
	{
		garage_id: 180,
		part_id: 208,
		quantity: 406,
	},
	{
		garage_id: 180,
		part_id: 209,
		quantity: 55,
	},
	{
		garage_id: 180,
		part_id: 210,
		quantity: 58,
	},
	{
		garage_id: 180,
		part_id: 211,
		quantity: 304,
	},
	{
		garage_id: 180,
		part_id: 212,
		quantity: 107,
	},
	{
		garage_id: 180,
		part_id: 213,
		quantity: 494,
	},
	{
		garage_id: 180,
		part_id: 214,
		quantity: 190,
	},
	{
		garage_id: 180,
		part_id: 215,
		quantity: 384,
	},
	{
		garage_id: 180,
		part_id: 216,
		quantity: 298,
	},
	{
		garage_id: 180,
		part_id: 217,
		quantity: 318,
	},
	{
		garage_id: 180,
		part_id: 218,
		quantity: 252,
	},
	{
		garage_id: 180,
		part_id: 219,
		quantity: 467,
	},
	{
		garage_id: 180,
		part_id: 220,
		quantity: 86,
	},
	{
		garage_id: 180,
		part_id: 221,
		quantity: 144,
	},
	{
		garage_id: 180,
		part_id: 222,
		quantity: 260,
	},
	{
		garage_id: 180,
		part_id: 223,
		quantity: 78,
	},
	{
		garage_id: 180,
		part_id: 224,
		quantity: 444,
	},
	{
		garage_id: 180,
		part_id: 225,
		quantity: 55,
	},
	{
		garage_id: 180,
		part_id: 226,
		quantity: 313,
	},
	{
		garage_id: 180,
		part_id: 227,
		quantity: 415,
	},
	{
		garage_id: 180,
		part_id: 228,
		quantity: 134,
	},
	{
		garage_id: 180,
		part_id: 229,
		quantity: 496,
	},
	{
		garage_id: 180,
		part_id: 230,
		quantity: 358,
	},
	{
		garage_id: 180,
		part_id: 231,
		quantity: 480,
	},
	{
		garage_id: 180,
		part_id: 232,
		quantity: 105,
	},
	{
		garage_id: 180,
		part_id: 233,
		quantity: 223,
	},
	{
		garage_id: 180,
		part_id: 234,
		quantity: 498,
	},
	{
		garage_id: 180,
		part_id: 235,
		quantity: 400,
	},
	{
		garage_id: 180,
		part_id: 236,
		quantity: 344,
	},
	{
		garage_id: 180,
		part_id: 237,
		quantity: 433,
	},
	{
		garage_id: 180,
		part_id: 238,
		quantity: 262,
	},
	{
		garage_id: 180,
		part_id: 239,
		quantity: 56,
	},
	{
		garage_id: 180,
		part_id: 240,
		quantity: 170,
	},
	{
		garage_id: 180,
		part_id: 241,
		quantity: 121,
	},
	{
		garage_id: 180,
		part_id: 242,
		quantity: 119,
	},
	{
		garage_id: 180,
		part_id: 243,
		quantity: 287,
	},
	{
		garage_id: 180,
		part_id: 244,
		quantity: 60,
	},
	{
		garage_id: 180,
		part_id: 245,
		quantity: 458,
	},
	{
		garage_id: 180,
		part_id: 246,
		quantity: 98,
	},
	{
		garage_id: 180,
		part_id: 247,
		quantity: 7,
	},
	{
		garage_id: 180,
		part_id: 248,
		quantity: 4,
	},
	{
		garage_id: 180,
		part_id: 249,
		quantity: 302,
	},
	{
		garage_id: 180,
		part_id: 250,
		quantity: 172,
	},
	{
		garage_id: 180,
		part_id: 251,
		quantity: 204,
	},
	{
		garage_id: 180,
		part_id: 252,
		quantity: 354,
	},
	{
		garage_id: 180,
		part_id: 253,
		quantity: 490,
	},
	{
		garage_id: 180,
		part_id: 254,
		quantity: 476,
	},
	{
		garage_id: 180,
		part_id: 255,
		quantity: 332,
	},
	{
		garage_id: 180,
		part_id: 256,
		quantity: 281,
	},
	{
		garage_id: 180,
		part_id: 257,
		quantity: 270,
	},
	{
		garage_id: 180,
		part_id: 258,
		quantity: 213,
	},
	{
		garage_id: 180,
		part_id: 259,
		quantity: 212,
	},
	{
		garage_id: 180,
		part_id: 260,
		quantity: 184,
	},
	{
		garage_id: 180,
		part_id: 261,
		quantity: 254,
	},
	{
		garage_id: 180,
		part_id: 262,
		quantity: 61,
	},
	{
		garage_id: 180,
		part_id: 263,
		quantity: 267,
	},
	{
		garage_id: 180,
		part_id: 264,
		quantity: 456,
	},
	{
		garage_id: 180,
		part_id: 265,
		quantity: 294,
	},
	{
		garage_id: 180,
		part_id: 266,
		quantity: 118,
	},
	{
		garage_id: 180,
		part_id: 267,
		quantity: 227,
	},
	{
		garage_id: 180,
		part_id: 268,
		quantity: 87,
	},
	{
		garage_id: 180,
		part_id: 269,
		quantity: 92,
	},
	{
		garage_id: 180,
		part_id: 270,
		quantity: 472,
	},
	{
		garage_id: 180,
		part_id: 271,
		quantity: 404,
	},
	{
		garage_id: 180,
		part_id: 272,
		quantity: 4,
	},
	{
		garage_id: 180,
		part_id: 273,
		quantity: 392,
	},
	{
		garage_id: 180,
		part_id: 274,
		quantity: 328,
	},
	{
		garage_id: 180,
		part_id: 275,
		quantity: 317,
	},
	{
		garage_id: 180,
		part_id: 276,
		quantity: 434,
	},
	{
		garage_id: 180,
		part_id: 277,
		quantity: 19,
	},
	{
		garage_id: 180,
		part_id: 278,
		quantity: 18,
	},
	{
		garage_id: 180,
		part_id: 279,
		quantity: 376,
	},
	{
		garage_id: 180,
		part_id: 280,
		quantity: 1,
	},
	{
		garage_id: 180,
		part_id: 281,
		quantity: 59,
	},
	{
		garage_id: 180,
		part_id: 282,
		quantity: 210,
	},
	{
		garage_id: 180,
		part_id: 283,
		quantity: 162,
	},
	{
		garage_id: 180,
		part_id: 284,
		quantity: 46,
	},
	{
		garage_id: 180,
		part_id: 285,
		quantity: 15,
	},
	{
		garage_id: 180,
		part_id: 286,
		quantity: 348,
	},
	{
		garage_id: 180,
		part_id: 287,
		quantity: 405,
	},
	{
		garage_id: 180,
		part_id: 288,
		quantity: 265,
	},
	{
		garage_id: 180,
		part_id: 289,
		quantity: 94,
	},
	{
		garage_id: 180,
		part_id: 290,
		quantity: 271,
	},
	{
		garage_id: 180,
		part_id: 291,
		quantity: 140,
	},
	{
		garage_id: 180,
		part_id: 292,
		quantity: 19,
	},
	{
		garage_id: 180,
		part_id: 293,
		quantity: 267,
	},
	{
		garage_id: 180,
		part_id: 294,
		quantity: 43,
	},
	{
		garage_id: 180,
		part_id: 295,
		quantity: 407,
	},
	{
		garage_id: 180,
		part_id: 296,
		quantity: 198,
	},
	{
		garage_id: 180,
		part_id: 297,
		quantity: 289,
	},
	{
		garage_id: 180,
		part_id: 298,
		quantity: 279,
	},
	{
		garage_id: 180,
		part_id: 299,
		quantity: 67,
	},
	{
		garage_id: 180,
		part_id: 300,
		quantity: 261,
	},
	{
		garage_id: 180,
		part_id: 301,
		quantity: 449,
	},
	{
		garage_id: 180,
		part_id: 302,
		quantity: 344,
	},
	{
		garage_id: 180,
		part_id: 303,
		quantity: 481,
	},
	{
		garage_id: 180,
		part_id: 304,
		quantity: 195,
	},
	{
		garage_id: 180,
		part_id: 305,
		quantity: 5,
	},
	{
		garage_id: 180,
		part_id: 306,
		quantity: 409,
	},
	{
		garage_id: 180,
		part_id: 307,
		quantity: 445,
	},
	{
		garage_id: 180,
		part_id: 308,
		quantity: 233,
	},
	{
		garage_id: 180,
		part_id: 309,
		quantity: 327,
	},
	{
		garage_id: 180,
		part_id: 310,
		quantity: 470,
	},
	{
		garage_id: 180,
		part_id: 311,
		quantity: 440,
	},
	{
		garage_id: 180,
		part_id: 312,
		quantity: 477,
	},
	{
		garage_id: 180,
		part_id: 313,
		quantity: 186,
	},
	{
		garage_id: 180,
		part_id: 314,
		quantity: 453,
	},
	{
		garage_id: 180,
		part_id: 315,
		quantity: 31,
	},
	{
		garage_id: 180,
		part_id: 316,
		quantity: 216,
	},
	{
		garage_id: 180,
		part_id: 317,
		quantity: 142,
	},
	{
		garage_id: 180,
		part_id: 318,
		quantity: 438,
	},
	{
		garage_id: 180,
		part_id: 319,
		quantity: 85,
	},
	{
		garage_id: 180,
		part_id: 320,
		quantity: 104,
	},
	{
		garage_id: 180,
		part_id: 321,
		quantity: 293,
	},
	{
		garage_id: 180,
		part_id: 322,
		quantity: 143,
	},
	{
		garage_id: 180,
		part_id: 323,
		quantity: 168,
	},
	{
		garage_id: 180,
		part_id: 324,
		quantity: 289,
	},
	{
		garage_id: 180,
		part_id: 325,
		quantity: 372,
	},
	{
		garage_id: 180,
		part_id: 326,
		quantity: 130,
	},
	{
		garage_id: 180,
		part_id: 327,
		quantity: 24,
	},
	{
		garage_id: 180,
		part_id: 328,
		quantity: 415,
	},
	{
		garage_id: 180,
		part_id: 329,
		quantity: 371,
	},
	{
		garage_id: 180,
		part_id: 330,
		quantity: 414,
	},
	{
		garage_id: 180,
		part_id: 331,
		quantity: 354,
	},
	{
		garage_id: 180,
		part_id: 332,
		quantity: 390,
	},
	{
		garage_id: 180,
		part_id: 333,
		quantity: 222,
	},
	{
		garage_id: 180,
		part_id: 334,
		quantity: 30,
	},
	{
		garage_id: 180,
		part_id: 335,
		quantity: 302,
	},
	{
		garage_id: 180,
		part_id: 336,
		quantity: 34,
	},
	{
		garage_id: 180,
		part_id: 337,
		quantity: 206,
	},
	{
		garage_id: 180,
		part_id: 338,
		quantity: 63,
	},
	{
		garage_id: 180,
		part_id: 339,
		quantity: 289,
	},
	{
		garage_id: 180,
		part_id: 340,
		quantity: 205,
	},
	{
		garage_id: 180,
		part_id: 341,
		quantity: 333,
	},
	{
		garage_id: 180,
		part_id: 342,
		quantity: 163,
	},
	{
		garage_id: 180,
		part_id: 343,
		quantity: 185,
	},
	{
		garage_id: 180,
		part_id: 344,
		quantity: 175,
	},
	{
		garage_id: 180,
		part_id: 345,
		quantity: 247,
	},
	{
		garage_id: 180,
		part_id: 346,
		quantity: 284,
	},
	{
		garage_id: 180,
		part_id: 347,
		quantity: 333,
	},
	{
		garage_id: 180,
		part_id: 348,
		quantity: 266,
	},
	{
		garage_id: 180,
		part_id: 349,
		quantity: 289,
	},
	{
		garage_id: 180,
		part_id: 350,
		quantity: 210,
	},
	{
		garage_id: 180,
		part_id: 351,
		quantity: 248,
	},
	{
		garage_id: 180,
		part_id: 352,
		quantity: 301,
	},
	{
		garage_id: 180,
		part_id: 353,
		quantity: 67,
	},
	{
		garage_id: 180,
		part_id: 354,
		quantity: 442,
	},
	{
		garage_id: 180,
		part_id: 355,
		quantity: 232,
	},
	{
		garage_id: 180,
		part_id: 356,
		quantity: 443,
	},
	{
		garage_id: 180,
		part_id: 357,
		quantity: 228,
	},
	{
		garage_id: 180,
		part_id: 358,
		quantity: 475,
	},
	{
		garage_id: 180,
		part_id: 359,
		quantity: 408,
	},
	{
		garage_id: 180,
		part_id: 360,
		quantity: 68,
	},
	{
		garage_id: 180,
		part_id: 361,
		quantity: 330,
	},
	{
		garage_id: 180,
		part_id: 362,
		quantity: 175,
	},
	{
		garage_id: 180,
		part_id: 363,
		quantity: 211,
	},
	{
		garage_id: 180,
		part_id: 364,
		quantity: 340,
	},
	{
		garage_id: 180,
		part_id: 365,
		quantity: 312,
	},
	{
		garage_id: 180,
		part_id: 366,
		quantity: 385,
	},
	{
		garage_id: 180,
		part_id: 367,
		quantity: 423,
	},
	{
		garage_id: 180,
		part_id: 368,
		quantity: 430,
	},
	{
		garage_id: 180,
		part_id: 369,
		quantity: 354,
	},
	{
		garage_id: 180,
		part_id: 370,
		quantity: 86,
	},
	{
		garage_id: 180,
		part_id: 371,
		quantity: 211,
	},
	{
		garage_id: 180,
		part_id: 372,
		quantity: 224,
	},
	{
		garage_id: 180,
		part_id: 373,
		quantity: 482,
	},
	{
		garage_id: 180,
		part_id: 374,
		quantity: 424,
	},
	{
		garage_id: 180,
		part_id: 375,
		quantity: 62,
	},
	{
		garage_id: 180,
		part_id: 376,
		quantity: 268,
	},
	{
		garage_id: 180,
		part_id: 377,
		quantity: 236,
	},
	{
		garage_id: 180,
		part_id: 378,
		quantity: 86,
	},
	{
		garage_id: 180,
		part_id: 379,
		quantity: 11,
	},
	{
		garage_id: 180,
		part_id: 380,
		quantity: 416,
	},
	{
		garage_id: 180,
		part_id: 381,
		quantity: 152,
	},
	{
		garage_id: 180,
		part_id: 382,
		quantity: 442,
	},
	{
		garage_id: 180,
		part_id: 383,
		quantity: 196,
	},
	{
		garage_id: 180,
		part_id: 384,
		quantity: 161,
	},
	{
		garage_id: 180,
		part_id: 385,
		quantity: 319,
	},
	{
		garage_id: 180,
		part_id: 386,
		quantity: 406,
	},
	{
		garage_id: 180,
		part_id: 387,
		quantity: 146,
	},
	{
		garage_id: 180,
		part_id: 388,
		quantity: 441,
	},
	{
		garage_id: 180,
		part_id: 389,
		quantity: 307,
	},
	{
		garage_id: 180,
		part_id: 390,
		quantity: 218,
	},
	{
		garage_id: 180,
		part_id: 391,
		quantity: 316,
	},
	{
		garage_id: 180,
		part_id: 392,
		quantity: 13,
	},
	{
		garage_id: 180,
		part_id: 393,
		quantity: 98,
	},
	{
		garage_id: 180,
		part_id: 394,
		quantity: 317,
	},
	{
		garage_id: 180,
		part_id: 395,
		quantity: 197,
	},
	{
		garage_id: 180,
		part_id: 396,
		quantity: 153,
	},
	{
		garage_id: 180,
		part_id: 397,
		quantity: 6,
	},
	{
		garage_id: 180,
		part_id: 398,
		quantity: 200,
	},
	{
		garage_id: 180,
		part_id: 399,
		quantity: 403,
	},
	{
		garage_id: 181,
		part_id: 0,
		quantity: 424,
	},
	{
		garage_id: 181,
		part_id: 1,
		quantity: 355,
	},
	{
		garage_id: 181,
		part_id: 2,
		quantity: 112,
	},
	{
		garage_id: 181,
		part_id: 3,
		quantity: 11,
	},
	{
		garage_id: 181,
		part_id: 4,
		quantity: 51,
	},
	{
		garage_id: 181,
		part_id: 5,
		quantity: 497,
	},
	{
		garage_id: 181,
		part_id: 6,
		quantity: 369,
	},
	{
		garage_id: 181,
		part_id: 7,
		quantity: 217,
	},
	{
		garage_id: 181,
		part_id: 8,
		quantity: 440,
	},
	{
		garage_id: 181,
		part_id: 9,
		quantity: 166,
	},
	{
		garage_id: 181,
		part_id: 10,
		quantity: 213,
	},
	{
		garage_id: 181,
		part_id: 11,
		quantity: 335,
	},
	{
		garage_id: 181,
		part_id: 12,
		quantity: 116,
	},
	{
		garage_id: 181,
		part_id: 13,
		quantity: 452,
	},
	{
		garage_id: 181,
		part_id: 14,
		quantity: 268,
	},
	{
		garage_id: 181,
		part_id: 15,
		quantity: 321,
	},
	{
		garage_id: 181,
		part_id: 16,
		quantity: 454,
	},
	{
		garage_id: 181,
		part_id: 17,
		quantity: 179,
	},
	{
		garage_id: 181,
		part_id: 18,
		quantity: 148,
	},
	{
		garage_id: 181,
		part_id: 19,
		quantity: 494,
	},
	{
		garage_id: 181,
		part_id: 20,
		quantity: 220,
	},
	{
		garage_id: 181,
		part_id: 21,
		quantity: 317,
	},
	{
		garage_id: 181,
		part_id: 22,
		quantity: 77,
	},
	{
		garage_id: 181,
		part_id: 23,
		quantity: 358,
	},
	{
		garage_id: 181,
		part_id: 24,
		quantity: 458,
	},
	{
		garage_id: 181,
		part_id: 25,
		quantity: 89,
	},
	{
		garage_id: 181,
		part_id: 26,
		quantity: 24,
	},
	{
		garage_id: 181,
		part_id: 27,
		quantity: 379,
	},
	{
		garage_id: 181,
		part_id: 28,
		quantity: 484,
	},
	{
		garage_id: 181,
		part_id: 29,
		quantity: 294,
	},
	{
		garage_id: 181,
		part_id: 30,
		quantity: 496,
	},
	{
		garage_id: 181,
		part_id: 31,
		quantity: 213,
	},
	{
		garage_id: 181,
		part_id: 32,
		quantity: 422,
	},
	{
		garage_id: 181,
		part_id: 33,
		quantity: 210,
	},
	{
		garage_id: 181,
		part_id: 34,
		quantity: 383,
	},
	{
		garage_id: 181,
		part_id: 35,
		quantity: 447,
	},
	{
		garage_id: 181,
		part_id: 36,
		quantity: 497,
	},
	{
		garage_id: 181,
		part_id: 37,
		quantity: 153,
	},
	{
		garage_id: 181,
		part_id: 38,
		quantity: 15,
	},
	{
		garage_id: 181,
		part_id: 39,
		quantity: 440,
	},
	{
		garage_id: 181,
		part_id: 40,
		quantity: 275,
	},
	{
		garage_id: 181,
		part_id: 41,
		quantity: 224,
	},
	{
		garage_id: 181,
		part_id: 42,
		quantity: 231,
	},
	{
		garage_id: 181,
		part_id: 43,
		quantity: 473,
	},
	{
		garage_id: 181,
		part_id: 44,
		quantity: 61,
	},
	{
		garage_id: 181,
		part_id: 45,
		quantity: 459,
	},
	{
		garage_id: 181,
		part_id: 46,
		quantity: 471,
	},
	{
		garage_id: 181,
		part_id: 47,
		quantity: 365,
	},
	{
		garage_id: 181,
		part_id: 48,
		quantity: 102,
	},
	{
		garage_id: 181,
		part_id: 49,
		quantity: 168,
	},
	{
		garage_id: 181,
		part_id: 50,
		quantity: 423,
	},
	{
		garage_id: 181,
		part_id: 51,
		quantity: 65,
	},
	{
		garage_id: 181,
		part_id: 52,
		quantity: 191,
	},
	{
		garage_id: 181,
		part_id: 53,
		quantity: 58,
	},
	{
		garage_id: 181,
		part_id: 54,
		quantity: 423,
	},
	{
		garage_id: 181,
		part_id: 55,
		quantity: 116,
	},
	{
		garage_id: 181,
		part_id: 56,
		quantity: 111,
	},
	{
		garage_id: 181,
		part_id: 57,
		quantity: 255,
	},
	{
		garage_id: 181,
		part_id: 58,
		quantity: 106,
	},
	{
		garage_id: 181,
		part_id: 59,
		quantity: 74,
	},
	{
		garage_id: 181,
		part_id: 60,
		quantity: 13,
	},
	{
		garage_id: 181,
		part_id: 61,
		quantity: 288,
	},
	{
		garage_id: 181,
		part_id: 62,
		quantity: 137,
	},
	{
		garage_id: 181,
		part_id: 63,
		quantity: 486,
	},
	{
		garage_id: 181,
		part_id: 64,
		quantity: 400,
	},
	{
		garage_id: 181,
		part_id: 65,
		quantity: 79,
	},
	{
		garage_id: 181,
		part_id: 66,
		quantity: 88,
	},
	{
		garage_id: 181,
		part_id: 67,
		quantity: 207,
	},
	{
		garage_id: 181,
		part_id: 68,
		quantity: 87,
	},
	{
		garage_id: 181,
		part_id: 69,
		quantity: 196,
	},
	{
		garage_id: 181,
		part_id: 70,
		quantity: 66,
	},
	{
		garage_id: 181,
		part_id: 71,
		quantity: 293,
	},
	{
		garage_id: 181,
		part_id: 72,
		quantity: 239,
	},
	{
		garage_id: 181,
		part_id: 73,
		quantity: 459,
	},
	{
		garage_id: 181,
		part_id: 74,
		quantity: 200,
	},
	{
		garage_id: 181,
		part_id: 75,
		quantity: 273,
	},
	{
		garage_id: 181,
		part_id: 76,
		quantity: 69,
	},
	{
		garage_id: 181,
		part_id: 77,
		quantity: 230,
	},
	{
		garage_id: 181,
		part_id: 78,
		quantity: 146,
	},
	{
		garage_id: 181,
		part_id: 79,
		quantity: 439,
	},
	{
		garage_id: 181,
		part_id: 80,
		quantity: 461,
	},
	{
		garage_id: 181,
		part_id: 81,
		quantity: 492,
	},
	{
		garage_id: 181,
		part_id: 82,
		quantity: 371,
	},
	{
		garage_id: 181,
		part_id: 83,
		quantity: 417,
	},
	{
		garage_id: 181,
		part_id: 84,
		quantity: 155,
	},
	{
		garage_id: 181,
		part_id: 85,
		quantity: 388,
	},
	{
		garage_id: 181,
		part_id: 86,
		quantity: 79,
	},
	{
		garage_id: 181,
		part_id: 87,
		quantity: 329,
	},
	{
		garage_id: 181,
		part_id: 88,
		quantity: 129,
	},
	{
		garage_id: 181,
		part_id: 89,
		quantity: 441,
	},
	{
		garage_id: 181,
		part_id: 90,
		quantity: 226,
	},
	{
		garage_id: 181,
		part_id: 91,
		quantity: 76,
	},
	{
		garage_id: 181,
		part_id: 92,
		quantity: 65,
	},
	{
		garage_id: 181,
		part_id: 93,
		quantity: 49,
	},
	{
		garage_id: 181,
		part_id: 94,
		quantity: 431,
	},
	{
		garage_id: 181,
		part_id: 95,
		quantity: 173,
	},
	{
		garage_id: 181,
		part_id: 96,
		quantity: 166,
	},
	{
		garage_id: 181,
		part_id: 97,
		quantity: 84,
	},
	{
		garage_id: 181,
		part_id: 98,
		quantity: 92,
	},
	{
		garage_id: 181,
		part_id: 99,
		quantity: 126,
	},
	{
		garage_id: 181,
		part_id: 100,
		quantity: 178,
	},
	{
		garage_id: 181,
		part_id: 101,
		quantity: 309,
	},
	{
		garage_id: 181,
		part_id: 102,
		quantity: 57,
	},
	{
		garage_id: 181,
		part_id: 103,
		quantity: 218,
	},
	{
		garage_id: 181,
		part_id: 104,
		quantity: 492,
	},
	{
		garage_id: 181,
		part_id: 105,
		quantity: 149,
	},
	{
		garage_id: 181,
		part_id: 106,
		quantity: 339,
	},
	{
		garage_id: 181,
		part_id: 107,
		quantity: 60,
	},
	{
		garage_id: 181,
		part_id: 108,
		quantity: 336,
	},
	{
		garage_id: 181,
		part_id: 109,
		quantity: 295,
	},
	{
		garage_id: 181,
		part_id: 110,
		quantity: 184,
	},
	{
		garage_id: 181,
		part_id: 111,
		quantity: 388,
	},
	{
		garage_id: 181,
		part_id: 112,
		quantity: 158,
	},
	{
		garage_id: 181,
		part_id: 113,
		quantity: 500,
	},
	{
		garage_id: 181,
		part_id: 114,
		quantity: 101,
	},
	{
		garage_id: 181,
		part_id: 115,
		quantity: 305,
	},
	{
		garage_id: 181,
		part_id: 116,
		quantity: 450,
	},
	{
		garage_id: 181,
		part_id: 117,
		quantity: 468,
	},
	{
		garage_id: 181,
		part_id: 118,
		quantity: 475,
	},
	{
		garage_id: 181,
		part_id: 119,
		quantity: 92,
	},
	{
		garage_id: 181,
		part_id: 120,
		quantity: 41,
	},
	{
		garage_id: 181,
		part_id: 121,
		quantity: 211,
	},
	{
		garage_id: 181,
		part_id: 122,
		quantity: 235,
	},
	{
		garage_id: 181,
		part_id: 123,
		quantity: 158,
	},
	{
		garage_id: 181,
		part_id: 124,
		quantity: 80,
	},
	{
		garage_id: 181,
		part_id: 125,
		quantity: 450,
	},
	{
		garage_id: 181,
		part_id: 126,
		quantity: 149,
	},
	{
		garage_id: 181,
		part_id: 127,
		quantity: 281,
	},
	{
		garage_id: 181,
		part_id: 128,
		quantity: 463,
	},
	{
		garage_id: 181,
		part_id: 129,
		quantity: 139,
	},
	{
		garage_id: 181,
		part_id: 130,
		quantity: 485,
	},
	{
		garage_id: 181,
		part_id: 131,
		quantity: 455,
	},
	{
		garage_id: 181,
		part_id: 132,
		quantity: 270,
	},
	{
		garage_id: 181,
		part_id: 133,
		quantity: 325,
	},
	{
		garage_id: 181,
		part_id: 134,
		quantity: 333,
	},
	{
		garage_id: 181,
		part_id: 135,
		quantity: 179,
	},
	{
		garage_id: 181,
		part_id: 136,
		quantity: 243,
	},
	{
		garage_id: 181,
		part_id: 137,
		quantity: 353,
	},
	{
		garage_id: 181,
		part_id: 138,
		quantity: 132,
	},
	{
		garage_id: 181,
		part_id: 139,
		quantity: 363,
	},
	{
		garage_id: 181,
		part_id: 140,
		quantity: 321,
	},
	{
		garage_id: 181,
		part_id: 141,
		quantity: 366,
	},
	{
		garage_id: 181,
		part_id: 142,
		quantity: 279,
	},
	{
		garage_id: 181,
		part_id: 143,
		quantity: 387,
	},
	{
		garage_id: 181,
		part_id: 144,
		quantity: 298,
	},
	{
		garage_id: 181,
		part_id: 145,
		quantity: 493,
	},
	{
		garage_id: 181,
		part_id: 146,
		quantity: 108,
	},
	{
		garage_id: 181,
		part_id: 147,
		quantity: 59,
	},
	{
		garage_id: 181,
		part_id: 148,
		quantity: 413,
	},
	{
		garage_id: 181,
		part_id: 149,
		quantity: 70,
	},
	{
		garage_id: 181,
		part_id: 150,
		quantity: 268,
	},
	{
		garage_id: 181,
		part_id: 151,
		quantity: 457,
	},
	{
		garage_id: 181,
		part_id: 152,
		quantity: 95,
	},
	{
		garage_id: 181,
		part_id: 153,
		quantity: 386,
	},
	{
		garage_id: 181,
		part_id: 154,
		quantity: 257,
	},
	{
		garage_id: 181,
		part_id: 155,
		quantity: 343,
	},
	{
		garage_id: 181,
		part_id: 156,
		quantity: 136,
	},
	{
		garage_id: 181,
		part_id: 157,
		quantity: 238,
	},
	{
		garage_id: 181,
		part_id: 158,
		quantity: 260,
	},
	{
		garage_id: 181,
		part_id: 159,
		quantity: 104,
	},
	{
		garage_id: 181,
		part_id: 160,
		quantity: 0,
	},
	{
		garage_id: 181,
		part_id: 161,
		quantity: 27,
	},
	{
		garage_id: 181,
		part_id: 162,
		quantity: 245,
	},
	{
		garage_id: 181,
		part_id: 163,
		quantity: 451,
	},
	{
		garage_id: 181,
		part_id: 164,
		quantity: 108,
	},
	{
		garage_id: 181,
		part_id: 165,
		quantity: 413,
	},
	{
		garage_id: 181,
		part_id: 166,
		quantity: 404,
	},
	{
		garage_id: 181,
		part_id: 167,
		quantity: 412,
	},
	{
		garage_id: 181,
		part_id: 168,
		quantity: 98,
	},
	{
		garage_id: 181,
		part_id: 169,
		quantity: 423,
	},
	{
		garage_id: 181,
		part_id: 170,
		quantity: 80,
	},
	{
		garage_id: 181,
		part_id: 171,
		quantity: 155,
	},
	{
		garage_id: 181,
		part_id: 172,
		quantity: 284,
	},
	{
		garage_id: 181,
		part_id: 173,
		quantity: 362,
	},
	{
		garage_id: 181,
		part_id: 174,
		quantity: 122,
	},
	{
		garage_id: 181,
		part_id: 175,
		quantity: 243,
	},
	{
		garage_id: 181,
		part_id: 176,
		quantity: 388,
	},
	{
		garage_id: 181,
		part_id: 177,
		quantity: 49,
	},
	{
		garage_id: 181,
		part_id: 178,
		quantity: 401,
	},
	{
		garage_id: 181,
		part_id: 179,
		quantity: 290,
	},
	{
		garage_id: 181,
		part_id: 180,
		quantity: 148,
	},
	{
		garage_id: 181,
		part_id: 181,
		quantity: 452,
	},
	{
		garage_id: 181,
		part_id: 182,
		quantity: 500,
	},
	{
		garage_id: 181,
		part_id: 183,
		quantity: 128,
	},
	{
		garage_id: 181,
		part_id: 184,
		quantity: 326,
	},
	{
		garage_id: 181,
		part_id: 185,
		quantity: 35,
	},
	{
		garage_id: 181,
		part_id: 186,
		quantity: 388,
	},
	{
		garage_id: 181,
		part_id: 187,
		quantity: 226,
	},
	{
		garage_id: 181,
		part_id: 188,
		quantity: 11,
	},
	{
		garage_id: 181,
		part_id: 189,
		quantity: 380,
	},
	{
		garage_id: 181,
		part_id: 190,
		quantity: 268,
	},
	{
		garage_id: 181,
		part_id: 191,
		quantity: 3,
	},
	{
		garage_id: 181,
		part_id: 192,
		quantity: 92,
	},
	{
		garage_id: 181,
		part_id: 193,
		quantity: 96,
	},
	{
		garage_id: 181,
		part_id: 194,
		quantity: 168,
	},
	{
		garage_id: 181,
		part_id: 195,
		quantity: 496,
	},
	{
		garage_id: 181,
		part_id: 196,
		quantity: 205,
	},
	{
		garage_id: 181,
		part_id: 197,
		quantity: 46,
	},
	{
		garage_id: 181,
		part_id: 198,
		quantity: 328,
	},
	{
		garage_id: 181,
		part_id: 199,
		quantity: 35,
	},
	{
		garage_id: 181,
		part_id: 200,
		quantity: 337,
	},
	{
		garage_id: 181,
		part_id: 201,
		quantity: 243,
	},
	{
		garage_id: 181,
		part_id: 202,
		quantity: 118,
	},
	{
		garage_id: 181,
		part_id: 203,
		quantity: 4,
	},
	{
		garage_id: 181,
		part_id: 204,
		quantity: 346,
	},
	{
		garage_id: 181,
		part_id: 205,
		quantity: 217,
	},
	{
		garage_id: 181,
		part_id: 206,
		quantity: 176,
	},
	{
		garage_id: 181,
		part_id: 207,
		quantity: 7,
	},
	{
		garage_id: 181,
		part_id: 208,
		quantity: 353,
	},
	{
		garage_id: 181,
		part_id: 209,
		quantity: 183,
	},
	{
		garage_id: 181,
		part_id: 210,
		quantity: 497,
	},
	{
		garage_id: 181,
		part_id: 211,
		quantity: 69,
	},
	{
		garage_id: 181,
		part_id: 212,
		quantity: 120,
	},
	{
		garage_id: 181,
		part_id: 213,
		quantity: 56,
	},
	{
		garage_id: 181,
		part_id: 214,
		quantity: 27,
	},
	{
		garage_id: 181,
		part_id: 215,
		quantity: 117,
	},
	{
		garage_id: 181,
		part_id: 216,
		quantity: 468,
	},
	{
		garage_id: 181,
		part_id: 217,
		quantity: 59,
	},
	{
		garage_id: 181,
		part_id: 218,
		quantity: 475,
	},
	{
		garage_id: 181,
		part_id: 219,
		quantity: 250,
	},
	{
		garage_id: 181,
		part_id: 220,
		quantity: 335,
	},
	{
		garage_id: 181,
		part_id: 221,
		quantity: 419,
	},
	{
		garage_id: 181,
		part_id: 222,
		quantity: 418,
	},
	{
		garage_id: 181,
		part_id: 223,
		quantity: 397,
	},
	{
		garage_id: 181,
		part_id: 224,
		quantity: 117,
	},
	{
		garage_id: 181,
		part_id: 225,
		quantity: 20,
	},
	{
		garage_id: 181,
		part_id: 226,
		quantity: 98,
	},
	{
		garage_id: 181,
		part_id: 227,
		quantity: 126,
	},
	{
		garage_id: 181,
		part_id: 228,
		quantity: 70,
	},
	{
		garage_id: 181,
		part_id: 229,
		quantity: 270,
	},
	{
		garage_id: 181,
		part_id: 230,
		quantity: 97,
	},
	{
		garage_id: 181,
		part_id: 231,
		quantity: 210,
	},
	{
		garage_id: 181,
		part_id: 232,
		quantity: 431,
	},
	{
		garage_id: 181,
		part_id: 233,
		quantity: 47,
	},
	{
		garage_id: 181,
		part_id: 234,
		quantity: 437,
	},
	{
		garage_id: 181,
		part_id: 235,
		quantity: 409,
	},
	{
		garage_id: 181,
		part_id: 236,
		quantity: 155,
	},
	{
		garage_id: 181,
		part_id: 237,
		quantity: 406,
	},
	{
		garage_id: 181,
		part_id: 238,
		quantity: 331,
	},
	{
		garage_id: 181,
		part_id: 239,
		quantity: 327,
	},
	{
		garage_id: 181,
		part_id: 240,
		quantity: 287,
	},
	{
		garage_id: 181,
		part_id: 241,
		quantity: 411,
	},
	{
		garage_id: 181,
		part_id: 242,
		quantity: 330,
	},
	{
		garage_id: 181,
		part_id: 243,
		quantity: 346,
	},
	{
		garage_id: 181,
		part_id: 244,
		quantity: 38,
	},
	{
		garage_id: 181,
		part_id: 245,
		quantity: 224,
	},
	{
		garage_id: 181,
		part_id: 246,
		quantity: 298,
	},
	{
		garage_id: 181,
		part_id: 247,
		quantity: 359,
	},
	{
		garage_id: 181,
		part_id: 248,
		quantity: 339,
	},
	{
		garage_id: 181,
		part_id: 249,
		quantity: 375,
	},
	{
		garage_id: 181,
		part_id: 250,
		quantity: 85,
	},
	{
		garage_id: 181,
		part_id: 251,
		quantity: 437,
	},
	{
		garage_id: 181,
		part_id: 252,
		quantity: 211,
	},
	{
		garage_id: 181,
		part_id: 253,
		quantity: 414,
	},
	{
		garage_id: 181,
		part_id: 254,
		quantity: 379,
	},
	{
		garage_id: 181,
		part_id: 255,
		quantity: 412,
	},
	{
		garage_id: 181,
		part_id: 256,
		quantity: 377,
	},
	{
		garage_id: 181,
		part_id: 257,
		quantity: 164,
	},
	{
		garage_id: 181,
		part_id: 258,
		quantity: 458,
	},
	{
		garage_id: 181,
		part_id: 259,
		quantity: 293,
	},
	{
		garage_id: 181,
		part_id: 260,
		quantity: 26,
	},
	{
		garage_id: 181,
		part_id: 261,
		quantity: 404,
	},
	{
		garage_id: 181,
		part_id: 262,
		quantity: 140,
	},
	{
		garage_id: 181,
		part_id: 263,
		quantity: 99,
	},
	{
		garage_id: 181,
		part_id: 264,
		quantity: 262,
	},
	{
		garage_id: 181,
		part_id: 265,
		quantity: 91,
	},
	{
		garage_id: 181,
		part_id: 266,
		quantity: 145,
	},
	{
		garage_id: 181,
		part_id: 267,
		quantity: 79,
	},
	{
		garage_id: 181,
		part_id: 268,
		quantity: 127,
	},
	{
		garage_id: 181,
		part_id: 269,
		quantity: 178,
	},
	{
		garage_id: 181,
		part_id: 270,
		quantity: 84,
	},
	{
		garage_id: 181,
		part_id: 271,
		quantity: 124,
	},
	{
		garage_id: 181,
		part_id: 272,
		quantity: 261,
	},
	{
		garage_id: 181,
		part_id: 273,
		quantity: 134,
	},
	{
		garage_id: 181,
		part_id: 274,
		quantity: 316,
	},
	{
		garage_id: 181,
		part_id: 275,
		quantity: 33,
	},
	{
		garage_id: 181,
		part_id: 276,
		quantity: 29,
	},
	{
		garage_id: 181,
		part_id: 277,
		quantity: 469,
	},
	{
		garage_id: 181,
		part_id: 278,
		quantity: 307,
	},
	{
		garage_id: 181,
		part_id: 279,
		quantity: 398,
	},
	{
		garage_id: 181,
		part_id: 280,
		quantity: 406,
	},
	{
		garage_id: 181,
		part_id: 281,
		quantity: 453,
	},
	{
		garage_id: 181,
		part_id: 282,
		quantity: 480,
	},
	{
		garage_id: 181,
		part_id: 283,
		quantity: 276,
	},
	{
		garage_id: 181,
		part_id: 284,
		quantity: 63,
	},
	{
		garage_id: 181,
		part_id: 285,
		quantity: 22,
	},
	{
		garage_id: 181,
		part_id: 286,
		quantity: 371,
	},
	{
		garage_id: 181,
		part_id: 287,
		quantity: 129,
	},
	{
		garage_id: 181,
		part_id: 288,
		quantity: 417,
	},
	{
		garage_id: 181,
		part_id: 289,
		quantity: 389,
	},
	{
		garage_id: 181,
		part_id: 290,
		quantity: 382,
	},
	{
		garage_id: 181,
		part_id: 291,
		quantity: 290,
	},
	{
		garage_id: 181,
		part_id: 292,
		quantity: 187,
	},
	{
		garage_id: 181,
		part_id: 293,
		quantity: 250,
	},
	{
		garage_id: 181,
		part_id: 294,
		quantity: 433,
	},
	{
		garage_id: 181,
		part_id: 295,
		quantity: 90,
	},
	{
		garage_id: 181,
		part_id: 296,
		quantity: 107,
	},
	{
		garage_id: 181,
		part_id: 297,
		quantity: 461,
	},
	{
		garage_id: 181,
		part_id: 298,
		quantity: 222,
	},
	{
		garage_id: 181,
		part_id: 299,
		quantity: 342,
	},
	{
		garage_id: 181,
		part_id: 300,
		quantity: 125,
	},
	{
		garage_id: 181,
		part_id: 301,
		quantity: 153,
	},
	{
		garage_id: 181,
		part_id: 302,
		quantity: 421,
	},
	{
		garage_id: 181,
		part_id: 303,
		quantity: 463,
	},
	{
		garage_id: 181,
		part_id: 304,
		quantity: 416,
	},
	{
		garage_id: 181,
		part_id: 305,
		quantity: 403,
	},
	{
		garage_id: 181,
		part_id: 306,
		quantity: 437,
	},
	{
		garage_id: 181,
		part_id: 307,
		quantity: 245,
	},
	{
		garage_id: 181,
		part_id: 308,
		quantity: 10,
	},
	{
		garage_id: 181,
		part_id: 309,
		quantity: 492,
	},
	{
		garage_id: 181,
		part_id: 310,
		quantity: 414,
	},
	{
		garage_id: 181,
		part_id: 311,
		quantity: 308,
	},
	{
		garage_id: 181,
		part_id: 312,
		quantity: 499,
	},
	{
		garage_id: 181,
		part_id: 313,
		quantity: 162,
	},
	{
		garage_id: 181,
		part_id: 314,
		quantity: 254,
	},
	{
		garage_id: 181,
		part_id: 315,
		quantity: 11,
	},
	{
		garage_id: 181,
		part_id: 316,
		quantity: 88,
	},
	{
		garage_id: 181,
		part_id: 317,
		quantity: 263,
	},
	{
		garage_id: 181,
		part_id: 318,
		quantity: 262,
	},
	{
		garage_id: 181,
		part_id: 319,
		quantity: 231,
	},
	{
		garage_id: 181,
		part_id: 320,
		quantity: 95,
	},
	{
		garage_id: 181,
		part_id: 321,
		quantity: 83,
	},
	{
		garage_id: 181,
		part_id: 322,
		quantity: 358,
	},
	{
		garage_id: 181,
		part_id: 323,
		quantity: 402,
	},
	{
		garage_id: 181,
		part_id: 324,
		quantity: 284,
	},
	{
		garage_id: 181,
		part_id: 325,
		quantity: 221,
	},
	{
		garage_id: 181,
		part_id: 326,
		quantity: 64,
	},
	{
		garage_id: 181,
		part_id: 327,
		quantity: 295,
	},
	{
		garage_id: 181,
		part_id: 328,
		quantity: 275,
	},
	{
		garage_id: 181,
		part_id: 329,
		quantity: 430,
	},
	{
		garage_id: 181,
		part_id: 330,
		quantity: 162,
	},
	{
		garage_id: 181,
		part_id: 331,
		quantity: 373,
	},
	{
		garage_id: 181,
		part_id: 332,
		quantity: 131,
	},
	{
		garage_id: 181,
		part_id: 333,
		quantity: 83,
	},
	{
		garage_id: 181,
		part_id: 334,
		quantity: 193,
	},
	{
		garage_id: 181,
		part_id: 335,
		quantity: 182,
	},
	{
		garage_id: 181,
		part_id: 336,
		quantity: 220,
	},
	{
		garage_id: 181,
		part_id: 337,
		quantity: 408,
	},
	{
		garage_id: 181,
		part_id: 338,
		quantity: 97,
	},
	{
		garage_id: 181,
		part_id: 339,
		quantity: 113,
	},
	{
		garage_id: 181,
		part_id: 340,
		quantity: 160,
	},
	{
		garage_id: 181,
		part_id: 341,
		quantity: 116,
	},
	{
		garage_id: 181,
		part_id: 342,
		quantity: 63,
	},
	{
		garage_id: 181,
		part_id: 343,
		quantity: 321,
	},
	{
		garage_id: 181,
		part_id: 344,
		quantity: 58,
	},
	{
		garage_id: 181,
		part_id: 345,
		quantity: 93,
	},
	{
		garage_id: 181,
		part_id: 346,
		quantity: 260,
	},
	{
		garage_id: 181,
		part_id: 347,
		quantity: 9,
	},
	{
		garage_id: 181,
		part_id: 348,
		quantity: 274,
	},
	{
		garage_id: 181,
		part_id: 349,
		quantity: 361,
	},
	{
		garage_id: 181,
		part_id: 350,
		quantity: 175,
	},
	{
		garage_id: 181,
		part_id: 351,
		quantity: 346,
	},
	{
		garage_id: 181,
		part_id: 352,
		quantity: 158,
	},
	{
		garage_id: 181,
		part_id: 353,
		quantity: 247,
	},
	{
		garage_id: 181,
		part_id: 354,
		quantity: 30,
	},
	{
		garage_id: 181,
		part_id: 355,
		quantity: 490,
	},
	{
		garage_id: 181,
		part_id: 356,
		quantity: 228,
	},
	{
		garage_id: 181,
		part_id: 357,
		quantity: 172,
	},
	{
		garage_id: 181,
		part_id: 358,
		quantity: 92,
	},
	{
		garage_id: 181,
		part_id: 359,
		quantity: 449,
	},
	{
		garage_id: 181,
		part_id: 360,
		quantity: 1,
	},
	{
		garage_id: 181,
		part_id: 361,
		quantity: 45,
	},
	{
		garage_id: 181,
		part_id: 362,
		quantity: 219,
	},
	{
		garage_id: 181,
		part_id: 363,
		quantity: 303,
	},
	{
		garage_id: 181,
		part_id: 364,
		quantity: 6,
	},
	{
		garage_id: 181,
		part_id: 365,
		quantity: 301,
	},
	{
		garage_id: 181,
		part_id: 366,
		quantity: 261,
	},
	{
		garage_id: 181,
		part_id: 367,
		quantity: 186,
	},
	{
		garage_id: 181,
		part_id: 368,
		quantity: 383,
	},
	{
		garage_id: 181,
		part_id: 369,
		quantity: 62,
	},
	{
		garage_id: 181,
		part_id: 370,
		quantity: 127,
	},
	{
		garage_id: 181,
		part_id: 371,
		quantity: 26,
	},
	{
		garage_id: 181,
		part_id: 372,
		quantity: 164,
	},
	{
		garage_id: 181,
		part_id: 373,
		quantity: 412,
	},
	{
		garage_id: 181,
		part_id: 374,
		quantity: 275,
	},
	{
		garage_id: 181,
		part_id: 375,
		quantity: 57,
	},
	{
		garage_id: 181,
		part_id: 376,
		quantity: 328,
	},
	{
		garage_id: 181,
		part_id: 377,
		quantity: 404,
	},
	{
		garage_id: 181,
		part_id: 378,
		quantity: 164,
	},
	{
		garage_id: 181,
		part_id: 379,
		quantity: 277,
	},
	{
		garage_id: 181,
		part_id: 380,
		quantity: 138,
	},
	{
		garage_id: 181,
		part_id: 381,
		quantity: 315,
	},
	{
		garage_id: 181,
		part_id: 382,
		quantity: 234,
	},
	{
		garage_id: 181,
		part_id: 383,
		quantity: 55,
	},
	{
		garage_id: 181,
		part_id: 384,
		quantity: 324,
	},
	{
		garage_id: 181,
		part_id: 385,
		quantity: 422,
	},
	{
		garage_id: 181,
		part_id: 386,
		quantity: 357,
	},
	{
		garage_id: 181,
		part_id: 387,
		quantity: 223,
	},
	{
		garage_id: 181,
		part_id: 388,
		quantity: 32,
	},
	{
		garage_id: 181,
		part_id: 389,
		quantity: 272,
	},
	{
		garage_id: 181,
		part_id: 390,
		quantity: 459,
	},
	{
		garage_id: 181,
		part_id: 391,
		quantity: 106,
	},
	{
		garage_id: 181,
		part_id: 392,
		quantity: 215,
	},
	{
		garage_id: 181,
		part_id: 393,
		quantity: 332,
	},
	{
		garage_id: 181,
		part_id: 394,
		quantity: 454,
	},
	{
		garage_id: 181,
		part_id: 395,
		quantity: 265,
	},
	{
		garage_id: 181,
		part_id: 396,
		quantity: 80,
	},
	{
		garage_id: 181,
		part_id: 397,
		quantity: 496,
	},
	{
		garage_id: 181,
		part_id: 398,
		quantity: 347,
	},
	{
		garage_id: 181,
		part_id: 399,
		quantity: 4,
	},
	{
		garage_id: 182,
		part_id: 0,
		quantity: 111,
	},
	{
		garage_id: 182,
		part_id: 1,
		quantity: 410,
	},
	{
		garage_id: 182,
		part_id: 2,
		quantity: 408,
	},
	{
		garage_id: 182,
		part_id: 3,
		quantity: 300,
	},
	{
		garage_id: 182,
		part_id: 4,
		quantity: 489,
	},
	{
		garage_id: 182,
		part_id: 5,
		quantity: 220,
	},
	{
		garage_id: 182,
		part_id: 6,
		quantity: 199,
	},
	{
		garage_id: 182,
		part_id: 7,
		quantity: 356,
	},
	{
		garage_id: 182,
		part_id: 8,
		quantity: 101,
	},
	{
		garage_id: 182,
		part_id: 9,
		quantity: 156,
	},
	{
		garage_id: 182,
		part_id: 10,
		quantity: 329,
	},
	{
		garage_id: 182,
		part_id: 11,
		quantity: 441,
	},
	{
		garage_id: 182,
		part_id: 12,
		quantity: 173,
	},
	{
		garage_id: 182,
		part_id: 13,
		quantity: 121,
	},
	{
		garage_id: 182,
		part_id: 14,
		quantity: 362,
	},
	{
		garage_id: 182,
		part_id: 15,
		quantity: 376,
	},
	{
		garage_id: 182,
		part_id: 16,
		quantity: 61,
	},
	{
		garage_id: 182,
		part_id: 17,
		quantity: 389,
	},
	{
		garage_id: 182,
		part_id: 18,
		quantity: 215,
	},
	{
		garage_id: 182,
		part_id: 19,
		quantity: 467,
	},
	{
		garage_id: 182,
		part_id: 20,
		quantity: 101,
	},
	{
		garage_id: 182,
		part_id: 21,
		quantity: 94,
	},
	{
		garage_id: 182,
		part_id: 22,
		quantity: 428,
	},
	{
		garage_id: 182,
		part_id: 23,
		quantity: 486,
	},
	{
		garage_id: 182,
		part_id: 24,
		quantity: 35,
	},
	{
		garage_id: 182,
		part_id: 25,
		quantity: 318,
	},
	{
		garage_id: 182,
		part_id: 26,
		quantity: 57,
	},
	{
		garage_id: 182,
		part_id: 27,
		quantity: 416,
	},
	{
		garage_id: 182,
		part_id: 28,
		quantity: 85,
	},
	{
		garage_id: 182,
		part_id: 29,
		quantity: 474,
	},
	{
		garage_id: 182,
		part_id: 30,
		quantity: 164,
	},
	{
		garage_id: 182,
		part_id: 31,
		quantity: 164,
	},
	{
		garage_id: 182,
		part_id: 32,
		quantity: 458,
	},
	{
		garage_id: 182,
		part_id: 33,
		quantity: 278,
	},
	{
		garage_id: 182,
		part_id: 34,
		quantity: 272,
	},
	{
		garage_id: 182,
		part_id: 35,
		quantity: 70,
	},
	{
		garage_id: 182,
		part_id: 36,
		quantity: 432,
	},
	{
		garage_id: 182,
		part_id: 37,
		quantity: 361,
	},
	{
		garage_id: 182,
		part_id: 38,
		quantity: 80,
	},
	{
		garage_id: 182,
		part_id: 39,
		quantity: 270,
	},
	{
		garage_id: 182,
		part_id: 40,
		quantity: 305,
	},
	{
		garage_id: 182,
		part_id: 41,
		quantity: 228,
	},
	{
		garage_id: 182,
		part_id: 42,
		quantity: 142,
	},
	{
		garage_id: 182,
		part_id: 43,
		quantity: 413,
	},
	{
		garage_id: 182,
		part_id: 44,
		quantity: 169,
	},
	{
		garage_id: 182,
		part_id: 45,
		quantity: 368,
	},
	{
		garage_id: 182,
		part_id: 46,
		quantity: 168,
	},
	{
		garage_id: 182,
		part_id: 47,
		quantity: 484,
	},
	{
		garage_id: 182,
		part_id: 48,
		quantity: 134,
	},
	{
		garage_id: 182,
		part_id: 49,
		quantity: 498,
	},
	{
		garage_id: 182,
		part_id: 50,
		quantity: 120,
	},
	{
		garage_id: 182,
		part_id: 51,
		quantity: 161,
	},
	{
		garage_id: 182,
		part_id: 52,
		quantity: 28,
	},
	{
		garage_id: 182,
		part_id: 53,
		quantity: 209,
	},
	{
		garage_id: 182,
		part_id: 54,
		quantity: 268,
	},
	{
		garage_id: 182,
		part_id: 55,
		quantity: 325,
	},
	{
		garage_id: 182,
		part_id: 56,
		quantity: 75,
	},
	{
		garage_id: 182,
		part_id: 57,
		quantity: 491,
	},
	{
		garage_id: 182,
		part_id: 58,
		quantity: 202,
	},
	{
		garage_id: 182,
		part_id: 59,
		quantity: 365,
	},
	{
		garage_id: 182,
		part_id: 60,
		quantity: 388,
	},
	{
		garage_id: 182,
		part_id: 61,
		quantity: 413,
	},
	{
		garage_id: 182,
		part_id: 62,
		quantity: 349,
	},
	{
		garage_id: 182,
		part_id: 63,
		quantity: 255,
	},
	{
		garage_id: 182,
		part_id: 64,
		quantity: 204,
	},
	{
		garage_id: 182,
		part_id: 65,
		quantity: 394,
	},
	{
		garage_id: 182,
		part_id: 66,
		quantity: 474,
	},
	{
		garage_id: 182,
		part_id: 67,
		quantity: 360,
	},
	{
		garage_id: 182,
		part_id: 68,
		quantity: 288,
	},
	{
		garage_id: 182,
		part_id: 69,
		quantity: 84,
	},
	{
		garage_id: 182,
		part_id: 70,
		quantity: 348,
	},
	{
		garage_id: 182,
		part_id: 71,
		quantity: 378,
	},
	{
		garage_id: 182,
		part_id: 72,
		quantity: 190,
	},
	{
		garage_id: 182,
		part_id: 73,
		quantity: 462,
	},
	{
		garage_id: 182,
		part_id: 74,
		quantity: 46,
	},
	{
		garage_id: 182,
		part_id: 75,
		quantity: 255,
	},
	{
		garage_id: 182,
		part_id: 76,
		quantity: 260,
	},
	{
		garage_id: 182,
		part_id: 77,
		quantity: 141,
	},
	{
		garage_id: 182,
		part_id: 78,
		quantity: 451,
	},
	{
		garage_id: 182,
		part_id: 79,
		quantity: 357,
	},
	{
		garage_id: 182,
		part_id: 80,
		quantity: 272,
	},
	{
		garage_id: 182,
		part_id: 81,
		quantity: 219,
	},
	{
		garage_id: 182,
		part_id: 82,
		quantity: 84,
	},
	{
		garage_id: 182,
		part_id: 83,
		quantity: 343,
	},
	{
		garage_id: 182,
		part_id: 84,
		quantity: 178,
	},
	{
		garage_id: 182,
		part_id: 85,
		quantity: 349,
	},
	{
		garage_id: 182,
		part_id: 86,
		quantity: 128,
	},
	{
		garage_id: 182,
		part_id: 87,
		quantity: 276,
	},
	{
		garage_id: 182,
		part_id: 88,
		quantity: 483,
	},
	{
		garage_id: 182,
		part_id: 89,
		quantity: 6,
	},
	{
		garage_id: 182,
		part_id: 90,
		quantity: 349,
	},
	{
		garage_id: 182,
		part_id: 91,
		quantity: 269,
	},
	{
		garage_id: 182,
		part_id: 92,
		quantity: 125,
	},
	{
		garage_id: 182,
		part_id: 93,
		quantity: 54,
	},
	{
		garage_id: 182,
		part_id: 94,
		quantity: 152,
	},
	{
		garage_id: 182,
		part_id: 95,
		quantity: 303,
	},
	{
		garage_id: 182,
		part_id: 96,
		quantity: 240,
	},
	{
		garage_id: 182,
		part_id: 97,
		quantity: 413,
	},
	{
		garage_id: 182,
		part_id: 98,
		quantity: 13,
	},
	{
		garage_id: 182,
		part_id: 99,
		quantity: 313,
	},
	{
		garage_id: 182,
		part_id: 100,
		quantity: 60,
	},
	{
		garage_id: 182,
		part_id: 101,
		quantity: 173,
	},
	{
		garage_id: 182,
		part_id: 102,
		quantity: 126,
	},
	{
		garage_id: 182,
		part_id: 103,
		quantity: 286,
	},
	{
		garage_id: 182,
		part_id: 104,
		quantity: 283,
	},
	{
		garage_id: 182,
		part_id: 105,
		quantity: 336,
	},
	{
		garage_id: 182,
		part_id: 106,
		quantity: 87,
	},
	{
		garage_id: 182,
		part_id: 107,
		quantity: 456,
	},
	{
		garage_id: 182,
		part_id: 108,
		quantity: 488,
	},
	{
		garage_id: 182,
		part_id: 109,
		quantity: 386,
	},
	{
		garage_id: 182,
		part_id: 110,
		quantity: 95,
	},
	{
		garage_id: 182,
		part_id: 111,
		quantity: 116,
	},
	{
		garage_id: 182,
		part_id: 112,
		quantity: 279,
	},
	{
		garage_id: 182,
		part_id: 113,
		quantity: 76,
	},
	{
		garage_id: 182,
		part_id: 114,
		quantity: 359,
	},
	{
		garage_id: 182,
		part_id: 115,
		quantity: 287,
	},
	{
		garage_id: 182,
		part_id: 116,
		quantity: 261,
	},
	{
		garage_id: 182,
		part_id: 117,
		quantity: 464,
	},
	{
		garage_id: 182,
		part_id: 118,
		quantity: 288,
	},
	{
		garage_id: 182,
		part_id: 119,
		quantity: 94,
	},
	{
		garage_id: 182,
		part_id: 120,
		quantity: 241,
	},
	{
		garage_id: 182,
		part_id: 121,
		quantity: 21,
	},
	{
		garage_id: 182,
		part_id: 122,
		quantity: 358,
	},
	{
		garage_id: 182,
		part_id: 123,
		quantity: 48,
	},
	{
		garage_id: 182,
		part_id: 124,
		quantity: 22,
	},
	{
		garage_id: 182,
		part_id: 125,
		quantity: 32,
	},
	{
		garage_id: 182,
		part_id: 126,
		quantity: 75,
	},
	{
		garage_id: 182,
		part_id: 127,
		quantity: 162,
	},
	{
		garage_id: 182,
		part_id: 128,
		quantity: 168,
	},
	{
		garage_id: 182,
		part_id: 129,
		quantity: 101,
	},
	{
		garage_id: 182,
		part_id: 130,
		quantity: 227,
	},
	{
		garage_id: 182,
		part_id: 131,
		quantity: 360,
	},
	{
		garage_id: 182,
		part_id: 132,
		quantity: 461,
	},
	{
		garage_id: 182,
		part_id: 133,
		quantity: 52,
	},
	{
		garage_id: 182,
		part_id: 134,
		quantity: 309,
	},
	{
		garage_id: 182,
		part_id: 135,
		quantity: 417,
	},
	{
		garage_id: 182,
		part_id: 136,
		quantity: 170,
	},
	{
		garage_id: 182,
		part_id: 137,
		quantity: 411,
	},
	{
		garage_id: 182,
		part_id: 138,
		quantity: 239,
	},
	{
		garage_id: 182,
		part_id: 139,
		quantity: 256,
	},
	{
		garage_id: 182,
		part_id: 140,
		quantity: 283,
	},
	{
		garage_id: 182,
		part_id: 141,
		quantity: 53,
	},
	{
		garage_id: 182,
		part_id: 142,
		quantity: 195,
	},
	{
		garage_id: 182,
		part_id: 143,
		quantity: 410,
	},
	{
		garage_id: 182,
		part_id: 144,
		quantity: 23,
	},
	{
		garage_id: 182,
		part_id: 145,
		quantity: 423,
	},
	{
		garage_id: 182,
		part_id: 146,
		quantity: 6,
	},
	{
		garage_id: 182,
		part_id: 147,
		quantity: 1,
	},
	{
		garage_id: 182,
		part_id: 148,
		quantity: 92,
	},
	{
		garage_id: 182,
		part_id: 149,
		quantity: 129,
	},
	{
		garage_id: 182,
		part_id: 150,
		quantity: 6,
	},
	{
		garage_id: 182,
		part_id: 151,
		quantity: 324,
	},
	{
		garage_id: 182,
		part_id: 152,
		quantity: 19,
	},
	{
		garage_id: 182,
		part_id: 153,
		quantity: 252,
	},
	{
		garage_id: 182,
		part_id: 154,
		quantity: 63,
	},
	{
		garage_id: 182,
		part_id: 155,
		quantity: 222,
	},
	{
		garage_id: 182,
		part_id: 156,
		quantity: 442,
	},
	{
		garage_id: 182,
		part_id: 157,
		quantity: 315,
	},
	{
		garage_id: 182,
		part_id: 158,
		quantity: 18,
	},
	{
		garage_id: 182,
		part_id: 159,
		quantity: 120,
	},
	{
		garage_id: 182,
		part_id: 160,
		quantity: 491,
	},
	{
		garage_id: 182,
		part_id: 161,
		quantity: 72,
	},
	{
		garage_id: 182,
		part_id: 162,
		quantity: 410,
	},
	{
		garage_id: 182,
		part_id: 163,
		quantity: 287,
	},
	{
		garage_id: 182,
		part_id: 164,
		quantity: 451,
	},
	{
		garage_id: 182,
		part_id: 165,
		quantity: 288,
	},
	{
		garage_id: 182,
		part_id: 166,
		quantity: 472,
	},
	{
		garage_id: 182,
		part_id: 167,
		quantity: 471,
	},
	{
		garage_id: 182,
		part_id: 168,
		quantity: 1,
	},
	{
		garage_id: 182,
		part_id: 169,
		quantity: 419,
	},
	{
		garage_id: 182,
		part_id: 170,
		quantity: 90,
	},
	{
		garage_id: 182,
		part_id: 171,
		quantity: 431,
	},
	{
		garage_id: 182,
		part_id: 172,
		quantity: 352,
	},
	{
		garage_id: 182,
		part_id: 173,
		quantity: 465,
	},
	{
		garage_id: 182,
		part_id: 174,
		quantity: 110,
	},
	{
		garage_id: 182,
		part_id: 175,
		quantity: 461,
	},
	{
		garage_id: 182,
		part_id: 176,
		quantity: 40,
	},
	{
		garage_id: 182,
		part_id: 177,
		quantity: 233,
	},
	{
		garage_id: 182,
		part_id: 178,
		quantity: 323,
	},
	{
		garage_id: 182,
		part_id: 179,
		quantity: 467,
	},
	{
		garage_id: 182,
		part_id: 180,
		quantity: 364,
	},
	{
		garage_id: 182,
		part_id: 181,
		quantity: 27,
	},
	{
		garage_id: 182,
		part_id: 182,
		quantity: 371,
	},
	{
		garage_id: 182,
		part_id: 183,
		quantity: 434,
	},
	{
		garage_id: 182,
		part_id: 184,
		quantity: 25,
	},
	{
		garage_id: 182,
		part_id: 185,
		quantity: 361,
	},
	{
		garage_id: 182,
		part_id: 186,
		quantity: 401,
	},
	{
		garage_id: 182,
		part_id: 187,
		quantity: 328,
	},
	{
		garage_id: 182,
		part_id: 188,
		quantity: 95,
	},
	{
		garage_id: 182,
		part_id: 189,
		quantity: 484,
	},
	{
		garage_id: 182,
		part_id: 190,
		quantity: 425,
	},
	{
		garage_id: 182,
		part_id: 191,
		quantity: 338,
	},
	{
		garage_id: 182,
		part_id: 192,
		quantity: 230,
	},
	{
		garage_id: 182,
		part_id: 193,
		quantity: 149,
	},
	{
		garage_id: 182,
		part_id: 194,
		quantity: 34,
	},
	{
		garage_id: 182,
		part_id: 195,
		quantity: 197,
	},
	{
		garage_id: 182,
		part_id: 196,
		quantity: 340,
	},
	{
		garage_id: 182,
		part_id: 197,
		quantity: 420,
	},
	{
		garage_id: 182,
		part_id: 198,
		quantity: 360,
	},
	{
		garage_id: 182,
		part_id: 199,
		quantity: 297,
	},
	{
		garage_id: 182,
		part_id: 200,
		quantity: 191,
	},
	{
		garage_id: 182,
		part_id: 201,
		quantity: 436,
	},
	{
		garage_id: 182,
		part_id: 202,
		quantity: 421,
	},
	{
		garage_id: 182,
		part_id: 203,
		quantity: 265,
	},
	{
		garage_id: 182,
		part_id: 204,
		quantity: 173,
	},
	{
		garage_id: 182,
		part_id: 205,
		quantity: 396,
	},
	{
		garage_id: 182,
		part_id: 206,
		quantity: 67,
	},
	{
		garage_id: 182,
		part_id: 207,
		quantity: 468,
	},
	{
		garage_id: 182,
		part_id: 208,
		quantity: 321,
	},
	{
		garage_id: 182,
		part_id: 209,
		quantity: 156,
	},
	{
		garage_id: 182,
		part_id: 210,
		quantity: 199,
	},
	{
		garage_id: 182,
		part_id: 211,
		quantity: 368,
	},
	{
		garage_id: 182,
		part_id: 212,
		quantity: 10,
	},
	{
		garage_id: 182,
		part_id: 213,
		quantity: 153,
	},
	{
		garage_id: 182,
		part_id: 214,
		quantity: 156,
	},
	{
		garage_id: 182,
		part_id: 215,
		quantity: 256,
	},
	{
		garage_id: 182,
		part_id: 216,
		quantity: 411,
	},
	{
		garage_id: 182,
		part_id: 217,
		quantity: 27,
	},
	{
		garage_id: 182,
		part_id: 218,
		quantity: 275,
	},
	{
		garage_id: 182,
		part_id: 219,
		quantity: 181,
	},
	{
		garage_id: 182,
		part_id: 220,
		quantity: 47,
	},
	{
		garage_id: 182,
		part_id: 221,
		quantity: 404,
	},
	{
		garage_id: 182,
		part_id: 222,
		quantity: 322,
	},
	{
		garage_id: 182,
		part_id: 223,
		quantity: 369,
	},
	{
		garage_id: 182,
		part_id: 224,
		quantity: 38,
	},
	{
		garage_id: 182,
		part_id: 225,
		quantity: 257,
	},
	{
		garage_id: 182,
		part_id: 226,
		quantity: 15,
	},
	{
		garage_id: 182,
		part_id: 227,
		quantity: 324,
	},
	{
		garage_id: 182,
		part_id: 228,
		quantity: 342,
	},
	{
		garage_id: 182,
		part_id: 229,
		quantity: 250,
	},
	{
		garage_id: 182,
		part_id: 230,
		quantity: 286,
	},
	{
		garage_id: 182,
		part_id: 231,
		quantity: 185,
	},
	{
		garage_id: 182,
		part_id: 232,
		quantity: 485,
	},
	{
		garage_id: 182,
		part_id: 233,
		quantity: 470,
	},
	{
		garage_id: 182,
		part_id: 234,
		quantity: 498,
	},
	{
		garage_id: 182,
		part_id: 235,
		quantity: 259,
	},
	{
		garage_id: 182,
		part_id: 236,
		quantity: 498,
	},
	{
		garage_id: 182,
		part_id: 237,
		quantity: 402,
	},
	{
		garage_id: 182,
		part_id: 238,
		quantity: 389,
	},
	{
		garage_id: 182,
		part_id: 239,
		quantity: 208,
	},
	{
		garage_id: 182,
		part_id: 240,
		quantity: 168,
	},
	{
		garage_id: 182,
		part_id: 241,
		quantity: 322,
	},
	{
		garage_id: 182,
		part_id: 242,
		quantity: 94,
	},
	{
		garage_id: 182,
		part_id: 243,
		quantity: 488,
	},
	{
		garage_id: 182,
		part_id: 244,
		quantity: 49,
	},
	{
		garage_id: 182,
		part_id: 245,
		quantity: 344,
	},
	{
		garage_id: 182,
		part_id: 246,
		quantity: 185,
	},
	{
		garage_id: 182,
		part_id: 247,
		quantity: 318,
	},
	{
		garage_id: 182,
		part_id: 248,
		quantity: 326,
	},
	{
		garage_id: 182,
		part_id: 249,
		quantity: 254,
	},
	{
		garage_id: 182,
		part_id: 250,
		quantity: 50,
	},
	{
		garage_id: 182,
		part_id: 251,
		quantity: 336,
	},
	{
		garage_id: 182,
		part_id: 252,
		quantity: 392,
	},
	{
		garage_id: 182,
		part_id: 253,
		quantity: 264,
	},
	{
		garage_id: 182,
		part_id: 254,
		quantity: 377,
	},
	{
		garage_id: 182,
		part_id: 255,
		quantity: 429,
	},
	{
		garage_id: 182,
		part_id: 256,
		quantity: 313,
	},
	{
		garage_id: 182,
		part_id: 257,
		quantity: 210,
	},
	{
		garage_id: 182,
		part_id: 258,
		quantity: 330,
	},
	{
		garage_id: 182,
		part_id: 259,
		quantity: 443,
	},
	{
		garage_id: 182,
		part_id: 260,
		quantity: 335,
	},
	{
		garage_id: 182,
		part_id: 261,
		quantity: 421,
	},
	{
		garage_id: 182,
		part_id: 262,
		quantity: 34,
	},
	{
		garage_id: 182,
		part_id: 263,
		quantity: 125,
	},
	{
		garage_id: 182,
		part_id: 264,
		quantity: 250,
	},
	{
		garage_id: 182,
		part_id: 265,
		quantity: 425,
	},
	{
		garage_id: 182,
		part_id: 266,
		quantity: 87,
	},
	{
		garage_id: 182,
		part_id: 267,
		quantity: 435,
	},
	{
		garage_id: 182,
		part_id: 268,
		quantity: 320,
	},
	{
		garage_id: 182,
		part_id: 269,
		quantity: 292,
	},
	{
		garage_id: 182,
		part_id: 270,
		quantity: 496,
	},
	{
		garage_id: 182,
		part_id: 271,
		quantity: 357,
	},
	{
		garage_id: 182,
		part_id: 272,
		quantity: 338,
	},
	{
		garage_id: 182,
		part_id: 273,
		quantity: 415,
	},
	{
		garage_id: 182,
		part_id: 274,
		quantity: 304,
	},
	{
		garage_id: 182,
		part_id: 275,
		quantity: 337,
	},
	{
		garage_id: 182,
		part_id: 276,
		quantity: 424,
	},
	{
		garage_id: 182,
		part_id: 277,
		quantity: 241,
	},
	{
		garage_id: 182,
		part_id: 278,
		quantity: 242,
	},
	{
		garage_id: 182,
		part_id: 279,
		quantity: 28,
	},
	{
		garage_id: 182,
		part_id: 280,
		quantity: 413,
	},
	{
		garage_id: 182,
		part_id: 281,
		quantity: 137,
	},
	{
		garage_id: 182,
		part_id: 282,
		quantity: 9,
	},
	{
		garage_id: 182,
		part_id: 283,
		quantity: 35,
	},
	{
		garage_id: 182,
		part_id: 284,
		quantity: 0,
	},
	{
		garage_id: 182,
		part_id: 285,
		quantity: 216,
	},
	{
		garage_id: 182,
		part_id: 286,
		quantity: 264,
	},
	{
		garage_id: 182,
		part_id: 287,
		quantity: 401,
	},
	{
		garage_id: 182,
		part_id: 288,
		quantity: 490,
	},
	{
		garage_id: 182,
		part_id: 289,
		quantity: 124,
	},
	{
		garage_id: 182,
		part_id: 290,
		quantity: 213,
	},
	{
		garage_id: 182,
		part_id: 291,
		quantity: 176,
	},
	{
		garage_id: 182,
		part_id: 292,
		quantity: 378,
	},
	{
		garage_id: 182,
		part_id: 293,
		quantity: 472,
	},
	{
		garage_id: 182,
		part_id: 294,
		quantity: 29,
	},
	{
		garage_id: 182,
		part_id: 295,
		quantity: 109,
	},
	{
		garage_id: 182,
		part_id: 296,
		quantity: 397,
	},
	{
		garage_id: 182,
		part_id: 297,
		quantity: 415,
	},
	{
		garage_id: 182,
		part_id: 298,
		quantity: 173,
	},
	{
		garage_id: 182,
		part_id: 299,
		quantity: 222,
	},
	{
		garage_id: 182,
		part_id: 300,
		quantity: 426,
	},
	{
		garage_id: 182,
		part_id: 301,
		quantity: 234,
	},
	{
		garage_id: 182,
		part_id: 302,
		quantity: 340,
	},
	{
		garage_id: 182,
		part_id: 303,
		quantity: 369,
	},
	{
		garage_id: 182,
		part_id: 304,
		quantity: 79,
	},
	{
		garage_id: 182,
		part_id: 305,
		quantity: 350,
	},
	{
		garage_id: 182,
		part_id: 306,
		quantity: 159,
	},
	{
		garage_id: 182,
		part_id: 307,
		quantity: 359,
	},
	{
		garage_id: 182,
		part_id: 308,
		quantity: 145,
	},
	{
		garage_id: 182,
		part_id: 309,
		quantity: 114,
	},
	{
		garage_id: 182,
		part_id: 310,
		quantity: 41,
	},
	{
		garage_id: 182,
		part_id: 311,
		quantity: 366,
	},
	{
		garage_id: 182,
		part_id: 312,
		quantity: 90,
	},
	{
		garage_id: 182,
		part_id: 313,
		quantity: 241,
	},
	{
		garage_id: 182,
		part_id: 314,
		quantity: 467,
	},
	{
		garage_id: 182,
		part_id: 315,
		quantity: 5,
	},
	{
		garage_id: 182,
		part_id: 316,
		quantity: 49,
	},
	{
		garage_id: 182,
		part_id: 317,
		quantity: 348,
	},
	{
		garage_id: 182,
		part_id: 318,
		quantity: 12,
	},
	{
		garage_id: 182,
		part_id: 319,
		quantity: 132,
	},
	{
		garage_id: 182,
		part_id: 320,
		quantity: 275,
	},
	{
		garage_id: 182,
		part_id: 321,
		quantity: 25,
	},
	{
		garage_id: 182,
		part_id: 322,
		quantity: 140,
	},
	{
		garage_id: 182,
		part_id: 323,
		quantity: 188,
	},
	{
		garage_id: 182,
		part_id: 324,
		quantity: 283,
	},
	{
		garage_id: 182,
		part_id: 325,
		quantity: 212,
	},
	{
		garage_id: 182,
		part_id: 326,
		quantity: 417,
	},
	{
		garage_id: 182,
		part_id: 327,
		quantity: 209,
	},
	{
		garage_id: 182,
		part_id: 328,
		quantity: 480,
	},
	{
		garage_id: 182,
		part_id: 329,
		quantity: 239,
	},
	{
		garage_id: 182,
		part_id: 330,
		quantity: 419,
	},
	{
		garage_id: 182,
		part_id: 331,
		quantity: 339,
	},
	{
		garage_id: 182,
		part_id: 332,
		quantity: 70,
	},
	{
		garage_id: 182,
		part_id: 333,
		quantity: 356,
	},
	{
		garage_id: 182,
		part_id: 334,
		quantity: 309,
	},
	{
		garage_id: 182,
		part_id: 335,
		quantity: 350,
	},
	{
		garage_id: 182,
		part_id: 336,
		quantity: 302,
	},
	{
		garage_id: 182,
		part_id: 337,
		quantity: 150,
	},
	{
		garage_id: 182,
		part_id: 338,
		quantity: 80,
	},
	{
		garage_id: 182,
		part_id: 339,
		quantity: 288,
	},
	{
		garage_id: 182,
		part_id: 340,
		quantity: 235,
	},
	{
		garage_id: 182,
		part_id: 341,
		quantity: 238,
	},
	{
		garage_id: 182,
		part_id: 342,
		quantity: 170,
	},
	{
		garage_id: 182,
		part_id: 343,
		quantity: 249,
	},
	{
		garage_id: 182,
		part_id: 344,
		quantity: 285,
	},
	{
		garage_id: 182,
		part_id: 345,
		quantity: 373,
	},
	{
		garage_id: 182,
		part_id: 346,
		quantity: 68,
	},
	{
		garage_id: 182,
		part_id: 347,
		quantity: 426,
	},
	{
		garage_id: 182,
		part_id: 348,
		quantity: 184,
	},
	{
		garage_id: 182,
		part_id: 349,
		quantity: 134,
	},
	{
		garage_id: 182,
		part_id: 350,
		quantity: 421,
	},
	{
		garage_id: 182,
		part_id: 351,
		quantity: 466,
	},
	{
		garage_id: 182,
		part_id: 352,
		quantity: 33,
	},
	{
		garage_id: 182,
		part_id: 353,
		quantity: 150,
	},
	{
		garage_id: 182,
		part_id: 354,
		quantity: 113,
	},
	{
		garage_id: 182,
		part_id: 355,
		quantity: 150,
	},
	{
		garage_id: 182,
		part_id: 356,
		quantity: 314,
	},
	{
		garage_id: 182,
		part_id: 357,
		quantity: 309,
	},
	{
		garage_id: 182,
		part_id: 358,
		quantity: 488,
	},
	{
		garage_id: 182,
		part_id: 359,
		quantity: 275,
	},
	{
		garage_id: 182,
		part_id: 360,
		quantity: 440,
	},
	{
		garage_id: 182,
		part_id: 361,
		quantity: 246,
	},
	{
		garage_id: 182,
		part_id: 362,
		quantity: 183,
	},
	{
		garage_id: 182,
		part_id: 363,
		quantity: 77,
	},
	{
		garage_id: 182,
		part_id: 364,
		quantity: 59,
	},
	{
		garage_id: 182,
		part_id: 365,
		quantity: 137,
	},
	{
		garage_id: 182,
		part_id: 366,
		quantity: 234,
	},
	{
		garage_id: 182,
		part_id: 367,
		quantity: 322,
	},
	{
		garage_id: 182,
		part_id: 368,
		quantity: 85,
	},
	{
		garage_id: 182,
		part_id: 369,
		quantity: 436,
	},
	{
		garage_id: 182,
		part_id: 370,
		quantity: 390,
	},
	{
		garage_id: 182,
		part_id: 371,
		quantity: 47,
	},
	{
		garage_id: 182,
		part_id: 372,
		quantity: 258,
	},
	{
		garage_id: 182,
		part_id: 373,
		quantity: 421,
	},
	{
		garage_id: 182,
		part_id: 374,
		quantity: 27,
	},
	{
		garage_id: 182,
		part_id: 375,
		quantity: 92,
	},
	{
		garage_id: 182,
		part_id: 376,
		quantity: 60,
	},
	{
		garage_id: 182,
		part_id: 377,
		quantity: 170,
	},
	{
		garage_id: 182,
		part_id: 378,
		quantity: 404,
	},
	{
		garage_id: 182,
		part_id: 379,
		quantity: 105,
	},
	{
		garage_id: 182,
		part_id: 380,
		quantity: 139,
	},
	{
		garage_id: 182,
		part_id: 381,
		quantity: 238,
	},
	{
		garage_id: 182,
		part_id: 382,
		quantity: 77,
	},
	{
		garage_id: 182,
		part_id: 383,
		quantity: 190,
	},
	{
		garage_id: 182,
		part_id: 384,
		quantity: 399,
	},
	{
		garage_id: 182,
		part_id: 385,
		quantity: 56,
	},
	{
		garage_id: 182,
		part_id: 386,
		quantity: 297,
	},
	{
		garage_id: 182,
		part_id: 387,
		quantity: 88,
	},
	{
		garage_id: 182,
		part_id: 388,
		quantity: 52,
	},
	{
		garage_id: 182,
		part_id: 389,
		quantity: 436,
	},
	{
		garage_id: 182,
		part_id: 390,
		quantity: 415,
	},
	{
		garage_id: 182,
		part_id: 391,
		quantity: 437,
	},
	{
		garage_id: 182,
		part_id: 392,
		quantity: 98,
	},
	{
		garage_id: 182,
		part_id: 393,
		quantity: 49,
	},
	{
		garage_id: 182,
		part_id: 394,
		quantity: 483,
	},
	{
		garage_id: 182,
		part_id: 395,
		quantity: 320,
	},
	{
		garage_id: 182,
		part_id: 396,
		quantity: 126,
	},
	{
		garage_id: 182,
		part_id: 397,
		quantity: 264,
	},
	{
		garage_id: 182,
		part_id: 398,
		quantity: 213,
	},
	{
		garage_id: 182,
		part_id: 399,
		quantity: 172,
	},
	{
		garage_id: 183,
		part_id: 0,
		quantity: 23,
	},
	{
		garage_id: 183,
		part_id: 1,
		quantity: 411,
	},
	{
		garage_id: 183,
		part_id: 2,
		quantity: 121,
	},
	{
		garage_id: 183,
		part_id: 3,
		quantity: 103,
	},
	{
		garage_id: 183,
		part_id: 4,
		quantity: 361,
	},
	{
		garage_id: 183,
		part_id: 5,
		quantity: 149,
	},
	{
		garage_id: 183,
		part_id: 6,
		quantity: 360,
	},
	{
		garage_id: 183,
		part_id: 7,
		quantity: 382,
	},
	{
		garage_id: 183,
		part_id: 8,
		quantity: 218,
	},
	{
		garage_id: 183,
		part_id: 9,
		quantity: 126,
	},
	{
		garage_id: 183,
		part_id: 10,
		quantity: 499,
	},
	{
		garage_id: 183,
		part_id: 11,
		quantity: 216,
	},
	{
		garage_id: 183,
		part_id: 12,
		quantity: 118,
	},
	{
		garage_id: 183,
		part_id: 13,
		quantity: 216,
	},
	{
		garage_id: 183,
		part_id: 14,
		quantity: 373,
	},
	{
		garage_id: 183,
		part_id: 15,
		quantity: 107,
	},
	{
		garage_id: 183,
		part_id: 16,
		quantity: 291,
	},
	{
		garage_id: 183,
		part_id: 17,
		quantity: 490,
	},
	{
		garage_id: 183,
		part_id: 18,
		quantity: 373,
	},
	{
		garage_id: 183,
		part_id: 19,
		quantity: 295,
	},
	{
		garage_id: 183,
		part_id: 20,
		quantity: 192,
	},
	{
		garage_id: 183,
		part_id: 21,
		quantity: 192,
	},
	{
		garage_id: 183,
		part_id: 22,
		quantity: 15,
	},
	{
		garage_id: 183,
		part_id: 23,
		quantity: 135,
	},
	{
		garage_id: 183,
		part_id: 24,
		quantity: 315,
	},
	{
		garage_id: 183,
		part_id: 25,
		quantity: 119,
	},
	{
		garage_id: 183,
		part_id: 26,
		quantity: 124,
	},
	{
		garage_id: 183,
		part_id: 27,
		quantity: 437,
	},
	{
		garage_id: 183,
		part_id: 28,
		quantity: 155,
	},
	{
		garage_id: 183,
		part_id: 29,
		quantity: 92,
	},
	{
		garage_id: 183,
		part_id: 30,
		quantity: 494,
	},
	{
		garage_id: 183,
		part_id: 31,
		quantity: 179,
	},
	{
		garage_id: 183,
		part_id: 32,
		quantity: 266,
	},
	{
		garage_id: 183,
		part_id: 33,
		quantity: 439,
	},
	{
		garage_id: 183,
		part_id: 34,
		quantity: 365,
	},
	{
		garage_id: 183,
		part_id: 35,
		quantity: 173,
	},
	{
		garage_id: 183,
		part_id: 36,
		quantity: 302,
	},
	{
		garage_id: 183,
		part_id: 37,
		quantity: 40,
	},
	{
		garage_id: 183,
		part_id: 38,
		quantity: 34,
	},
	{
		garage_id: 183,
		part_id: 39,
		quantity: 318,
	},
	{
		garage_id: 183,
		part_id: 40,
		quantity: 125,
	},
	{
		garage_id: 183,
		part_id: 41,
		quantity: 400,
	},
	{
		garage_id: 183,
		part_id: 42,
		quantity: 187,
	},
	{
		garage_id: 183,
		part_id: 43,
		quantity: 15,
	},
	{
		garage_id: 183,
		part_id: 44,
		quantity: 426,
	},
	{
		garage_id: 183,
		part_id: 45,
		quantity: 345,
	},
	{
		garage_id: 183,
		part_id: 46,
		quantity: 452,
	},
	{
		garage_id: 183,
		part_id: 47,
		quantity: 290,
	},
	{
		garage_id: 183,
		part_id: 48,
		quantity: 281,
	},
	{
		garage_id: 183,
		part_id: 49,
		quantity: 148,
	},
	{
		garage_id: 183,
		part_id: 50,
		quantity: 92,
	},
	{
		garage_id: 183,
		part_id: 51,
		quantity: 30,
	},
	{
		garage_id: 183,
		part_id: 52,
		quantity: 471,
	},
	{
		garage_id: 183,
		part_id: 53,
		quantity: 212,
	},
	{
		garage_id: 183,
		part_id: 54,
		quantity: 42,
	},
	{
		garage_id: 183,
		part_id: 55,
		quantity: 306,
	},
	{
		garage_id: 183,
		part_id: 56,
		quantity: 437,
	},
	{
		garage_id: 183,
		part_id: 57,
		quantity: 242,
	},
	{
		garage_id: 183,
		part_id: 58,
		quantity: 368,
	},
	{
		garage_id: 183,
		part_id: 59,
		quantity: 44,
	},
	{
		garage_id: 183,
		part_id: 60,
		quantity: 374,
	},
	{
		garage_id: 183,
		part_id: 61,
		quantity: 339,
	},
	{
		garage_id: 183,
		part_id: 62,
		quantity: 88,
	},
	{
		garage_id: 183,
		part_id: 63,
		quantity: 398,
	},
	{
		garage_id: 183,
		part_id: 64,
		quantity: 315,
	},
	{
		garage_id: 183,
		part_id: 65,
		quantity: 275,
	},
	{
		garage_id: 183,
		part_id: 66,
		quantity: 94,
	},
	{
		garage_id: 183,
		part_id: 67,
		quantity: 328,
	},
	{
		garage_id: 183,
		part_id: 68,
		quantity: 383,
	},
	{
		garage_id: 183,
		part_id: 69,
		quantity: 1,
	},
	{
		garage_id: 183,
		part_id: 70,
		quantity: 351,
	},
	{
		garage_id: 183,
		part_id: 71,
		quantity: 26,
	},
	{
		garage_id: 183,
		part_id: 72,
		quantity: 130,
	},
	{
		garage_id: 183,
		part_id: 73,
		quantity: 398,
	},
	{
		garage_id: 183,
		part_id: 74,
		quantity: 290,
	},
	{
		garage_id: 183,
		part_id: 75,
		quantity: 251,
	},
	{
		garage_id: 183,
		part_id: 76,
		quantity: 9,
	},
	{
		garage_id: 183,
		part_id: 77,
		quantity: 23,
	},
	{
		garage_id: 183,
		part_id: 78,
		quantity: 415,
	},
	{
		garage_id: 183,
		part_id: 79,
		quantity: 154,
	},
	{
		garage_id: 183,
		part_id: 80,
		quantity: 4,
	},
	{
		garage_id: 183,
		part_id: 81,
		quantity: 23,
	},
	{
		garage_id: 183,
		part_id: 82,
		quantity: 110,
	},
	{
		garage_id: 183,
		part_id: 83,
		quantity: 415,
	},
	{
		garage_id: 183,
		part_id: 84,
		quantity: 312,
	},
	{
		garage_id: 183,
		part_id: 85,
		quantity: 106,
	},
	{
		garage_id: 183,
		part_id: 86,
		quantity: 214,
	},
	{
		garage_id: 183,
		part_id: 87,
		quantity: 184,
	},
	{
		garage_id: 183,
		part_id: 88,
		quantity: 411,
	},
	{
		garage_id: 183,
		part_id: 89,
		quantity: 217,
	},
	{
		garage_id: 183,
		part_id: 90,
		quantity: 185,
	},
	{
		garage_id: 183,
		part_id: 91,
		quantity: 152,
	},
	{
		garage_id: 183,
		part_id: 92,
		quantity: 441,
	},
	{
		garage_id: 183,
		part_id: 93,
		quantity: 50,
	},
	{
		garage_id: 183,
		part_id: 94,
		quantity: 193,
	},
	{
		garage_id: 183,
		part_id: 95,
		quantity: 270,
	},
	{
		garage_id: 183,
		part_id: 96,
		quantity: 62,
	},
	{
		garage_id: 183,
		part_id: 97,
		quantity: 137,
	},
	{
		garage_id: 183,
		part_id: 98,
		quantity: 138,
	},
	{
		garage_id: 183,
		part_id: 99,
		quantity: 1,
	},
	{
		garage_id: 183,
		part_id: 100,
		quantity: 349,
	},
	{
		garage_id: 183,
		part_id: 101,
		quantity: 415,
	},
	{
		garage_id: 183,
		part_id: 102,
		quantity: 209,
	},
	{
		garage_id: 183,
		part_id: 103,
		quantity: 46,
	},
	{
		garage_id: 183,
		part_id: 104,
		quantity: 369,
	},
	{
		garage_id: 183,
		part_id: 105,
		quantity: 189,
	},
	{
		garage_id: 183,
		part_id: 106,
		quantity: 189,
	},
	{
		garage_id: 183,
		part_id: 107,
		quantity: 231,
	},
	{
		garage_id: 183,
		part_id: 108,
		quantity: 239,
	},
	{
		garage_id: 183,
		part_id: 109,
		quantity: 181,
	},
	{
		garage_id: 183,
		part_id: 110,
		quantity: 121,
	},
	{
		garage_id: 183,
		part_id: 111,
		quantity: 284,
	},
	{
		garage_id: 183,
		part_id: 112,
		quantity: 13,
	},
	{
		garage_id: 183,
		part_id: 113,
		quantity: 38,
	},
	{
		garage_id: 183,
		part_id: 114,
		quantity: 179,
	},
	{
		garage_id: 183,
		part_id: 115,
		quantity: 252,
	},
	{
		garage_id: 183,
		part_id: 116,
		quantity: 42,
	},
	{
		garage_id: 183,
		part_id: 117,
		quantity: 325,
	},
	{
		garage_id: 183,
		part_id: 118,
		quantity: 269,
	},
	{
		garage_id: 183,
		part_id: 119,
		quantity: 177,
	},
	{
		garage_id: 183,
		part_id: 120,
		quantity: 90,
	},
	{
		garage_id: 183,
		part_id: 121,
		quantity: 40,
	},
	{
		garage_id: 183,
		part_id: 122,
		quantity: 303,
	},
	{
		garage_id: 183,
		part_id: 123,
		quantity: 265,
	},
	{
		garage_id: 183,
		part_id: 124,
		quantity: 439,
	},
	{
		garage_id: 183,
		part_id: 125,
		quantity: 35,
	},
	{
		garage_id: 183,
		part_id: 126,
		quantity: 20,
	},
	{
		garage_id: 183,
		part_id: 127,
		quantity: 127,
	},
	{
		garage_id: 183,
		part_id: 128,
		quantity: 293,
	},
	{
		garage_id: 183,
		part_id: 129,
		quantity: 412,
	},
	{
		garage_id: 183,
		part_id: 130,
		quantity: 23,
	},
	{
		garage_id: 183,
		part_id: 131,
		quantity: 218,
	},
	{
		garage_id: 183,
		part_id: 132,
		quantity: 420,
	},
	{
		garage_id: 183,
		part_id: 133,
		quantity: 264,
	},
	{
		garage_id: 183,
		part_id: 134,
		quantity: 407,
	},
	{
		garage_id: 183,
		part_id: 135,
		quantity: 435,
	},
	{
		garage_id: 183,
		part_id: 136,
		quantity: 239,
	},
	{
		garage_id: 183,
		part_id: 137,
		quantity: 424,
	},
	{
		garage_id: 183,
		part_id: 138,
		quantity: 165,
	},
	{
		garage_id: 183,
		part_id: 139,
		quantity: 462,
	},
	{
		garage_id: 183,
		part_id: 140,
		quantity: 428,
	},
	{
		garage_id: 183,
		part_id: 141,
		quantity: 93,
	},
	{
		garage_id: 183,
		part_id: 142,
		quantity: 148,
	},
	{
		garage_id: 183,
		part_id: 143,
		quantity: 165,
	},
	{
		garage_id: 183,
		part_id: 144,
		quantity: 377,
	},
	{
		garage_id: 183,
		part_id: 145,
		quantity: 332,
	},
	{
		garage_id: 183,
		part_id: 146,
		quantity: 86,
	},
	{
		garage_id: 183,
		part_id: 147,
		quantity: 246,
	},
	{
		garage_id: 183,
		part_id: 148,
		quantity: 209,
	},
	{
		garage_id: 183,
		part_id: 149,
		quantity: 440,
	},
	{
		garage_id: 183,
		part_id: 150,
		quantity: 176,
	},
	{
		garage_id: 183,
		part_id: 151,
		quantity: 500,
	},
	{
		garage_id: 183,
		part_id: 152,
		quantity: 198,
	},
	{
		garage_id: 183,
		part_id: 153,
		quantity: 334,
	},
	{
		garage_id: 183,
		part_id: 154,
		quantity: 156,
	},
	{
		garage_id: 183,
		part_id: 155,
		quantity: 401,
	},
	{
		garage_id: 183,
		part_id: 156,
		quantity: 461,
	},
	{
		garage_id: 183,
		part_id: 157,
		quantity: 222,
	},
	{
		garage_id: 183,
		part_id: 158,
		quantity: 163,
	},
	{
		garage_id: 183,
		part_id: 159,
		quantity: 104,
	},
	{
		garage_id: 183,
		part_id: 160,
		quantity: 356,
	},
	{
		garage_id: 183,
		part_id: 161,
		quantity: 444,
	},
	{
		garage_id: 183,
		part_id: 162,
		quantity: 468,
	},
	{
		garage_id: 183,
		part_id: 163,
		quantity: 41,
	},
	{
		garage_id: 183,
		part_id: 164,
		quantity: 320,
	},
	{
		garage_id: 183,
		part_id: 165,
		quantity: 475,
	},
	{
		garage_id: 183,
		part_id: 166,
		quantity: 131,
	},
	{
		garage_id: 183,
		part_id: 167,
		quantity: 2,
	},
	{
		garage_id: 183,
		part_id: 168,
		quantity: 8,
	},
	{
		garage_id: 183,
		part_id: 169,
		quantity: 241,
	},
	{
		garage_id: 183,
		part_id: 170,
		quantity: 129,
	},
	{
		garage_id: 183,
		part_id: 171,
		quantity: 284,
	},
	{
		garage_id: 183,
		part_id: 172,
		quantity: 210,
	},
	{
		garage_id: 183,
		part_id: 173,
		quantity: 93,
	},
	{
		garage_id: 183,
		part_id: 174,
		quantity: 321,
	},
	{
		garage_id: 183,
		part_id: 175,
		quantity: 1,
	},
	{
		garage_id: 183,
		part_id: 176,
		quantity: 170,
	},
	{
		garage_id: 183,
		part_id: 177,
		quantity: 365,
	},
	{
		garage_id: 183,
		part_id: 178,
		quantity: 48,
	},
	{
		garage_id: 183,
		part_id: 179,
		quantity: 159,
	},
	{
		garage_id: 183,
		part_id: 180,
		quantity: 343,
	},
	{
		garage_id: 183,
		part_id: 181,
		quantity: 367,
	},
	{
		garage_id: 183,
		part_id: 182,
		quantity: 221,
	},
	{
		garage_id: 183,
		part_id: 183,
		quantity: 49,
	},
	{
		garage_id: 183,
		part_id: 184,
		quantity: 500,
	},
	{
		garage_id: 183,
		part_id: 185,
		quantity: 402,
	},
	{
		garage_id: 183,
		part_id: 186,
		quantity: 14,
	},
	{
		garage_id: 183,
		part_id: 187,
		quantity: 401,
	},
	{
		garage_id: 183,
		part_id: 188,
		quantity: 175,
	},
	{
		garage_id: 183,
		part_id: 189,
		quantity: 99,
	},
	{
		garage_id: 183,
		part_id: 190,
		quantity: 337,
	},
	{
		garage_id: 183,
		part_id: 191,
		quantity: 322,
	},
	{
		garage_id: 183,
		part_id: 192,
		quantity: 360,
	},
	{
		garage_id: 183,
		part_id: 193,
		quantity: 74,
	},
	{
		garage_id: 183,
		part_id: 194,
		quantity: 243,
	},
	{
		garage_id: 183,
		part_id: 195,
		quantity: 357,
	},
	{
		garage_id: 183,
		part_id: 196,
		quantity: 223,
	},
	{
		garage_id: 183,
		part_id: 197,
		quantity: 172,
	},
	{
		garage_id: 183,
		part_id: 198,
		quantity: 156,
	},
	{
		garage_id: 183,
		part_id: 199,
		quantity: 307,
	},
	{
		garage_id: 183,
		part_id: 200,
		quantity: 198,
	},
	{
		garage_id: 183,
		part_id: 201,
		quantity: 102,
	},
	{
		garage_id: 183,
		part_id: 202,
		quantity: 391,
	},
	{
		garage_id: 183,
		part_id: 203,
		quantity: 82,
	},
	{
		garage_id: 183,
		part_id: 204,
		quantity: 237,
	},
	{
		garage_id: 183,
		part_id: 205,
		quantity: 466,
	},
	{
		garage_id: 183,
		part_id: 206,
		quantity: 298,
	},
	{
		garage_id: 183,
		part_id: 207,
		quantity: 388,
	},
	{
		garage_id: 183,
		part_id: 208,
		quantity: 100,
	},
	{
		garage_id: 183,
		part_id: 209,
		quantity: 465,
	},
	{
		garage_id: 183,
		part_id: 210,
		quantity: 190,
	},
	{
		garage_id: 183,
		part_id: 211,
		quantity: 37,
	},
	{
		garage_id: 183,
		part_id: 212,
		quantity: 13,
	},
	{
		garage_id: 183,
		part_id: 213,
		quantity: 274,
	},
	{
		garage_id: 183,
		part_id: 214,
		quantity: 357,
	},
	{
		garage_id: 183,
		part_id: 215,
		quantity: 11,
	},
	{
		garage_id: 183,
		part_id: 216,
		quantity: 216,
	},
	{
		garage_id: 183,
		part_id: 217,
		quantity: 359,
	},
	{
		garage_id: 183,
		part_id: 218,
		quantity: 115,
	},
	{
		garage_id: 183,
		part_id: 219,
		quantity: 433,
	},
	{
		garage_id: 183,
		part_id: 220,
		quantity: 95,
	},
	{
		garage_id: 183,
		part_id: 221,
		quantity: 242,
	},
	{
		garage_id: 183,
		part_id: 222,
		quantity: 448,
	},
	{
		garage_id: 183,
		part_id: 223,
		quantity: 73,
	},
	{
		garage_id: 183,
		part_id: 224,
		quantity: 33,
	},
	{
		garage_id: 183,
		part_id: 225,
		quantity: 274,
	},
	{
		garage_id: 183,
		part_id: 226,
		quantity: 181,
	},
	{
		garage_id: 183,
		part_id: 227,
		quantity: 87,
	},
	{
		garage_id: 183,
		part_id: 228,
		quantity: 106,
	},
	{
		garage_id: 183,
		part_id: 229,
		quantity: 471,
	},
	{
		garage_id: 183,
		part_id: 230,
		quantity: 281,
	},
	{
		garage_id: 183,
		part_id: 231,
		quantity: 8,
	},
	{
		garage_id: 183,
		part_id: 232,
		quantity: 15,
	},
	{
		garage_id: 183,
		part_id: 233,
		quantity: 105,
	},
	{
		garage_id: 183,
		part_id: 234,
		quantity: 422,
	},
	{
		garage_id: 183,
		part_id: 235,
		quantity: 207,
	},
	{
		garage_id: 183,
		part_id: 236,
		quantity: 423,
	},
	{
		garage_id: 183,
		part_id: 237,
		quantity: 492,
	},
	{
		garage_id: 183,
		part_id: 238,
		quantity: 335,
	},
	{
		garage_id: 183,
		part_id: 239,
		quantity: 109,
	},
	{
		garage_id: 183,
		part_id: 240,
		quantity: 194,
	},
	{
		garage_id: 183,
		part_id: 241,
		quantity: 55,
	},
	{
		garage_id: 183,
		part_id: 242,
		quantity: 404,
	},
	{
		garage_id: 183,
		part_id: 243,
		quantity: 422,
	},
	{
		garage_id: 183,
		part_id: 244,
		quantity: 256,
	},
	{
		garage_id: 183,
		part_id: 245,
		quantity: 424,
	},
	{
		garage_id: 183,
		part_id: 246,
		quantity: 287,
	},
	{
		garage_id: 183,
		part_id: 247,
		quantity: 93,
	},
	{
		garage_id: 183,
		part_id: 248,
		quantity: 412,
	},
	{
		garage_id: 183,
		part_id: 249,
		quantity: 388,
	},
	{
		garage_id: 183,
		part_id: 250,
		quantity: 428,
	},
	{
		garage_id: 183,
		part_id: 251,
		quantity: 364,
	},
	{
		garage_id: 183,
		part_id: 252,
		quantity: 421,
	},
	{
		garage_id: 183,
		part_id: 253,
		quantity: 4,
	},
	{
		garage_id: 183,
		part_id: 254,
		quantity: 247,
	},
	{
		garage_id: 183,
		part_id: 255,
		quantity: 350,
	},
	{
		garage_id: 183,
		part_id: 256,
		quantity: 111,
	},
	{
		garage_id: 183,
		part_id: 257,
		quantity: 369,
	},
	{
		garage_id: 183,
		part_id: 258,
		quantity: 339,
	},
	{
		garage_id: 183,
		part_id: 259,
		quantity: 16,
	},
	{
		garage_id: 183,
		part_id: 260,
		quantity: 169,
	},
	{
		garage_id: 183,
		part_id: 261,
		quantity: 120,
	},
	{
		garage_id: 183,
		part_id: 262,
		quantity: 380,
	},
	{
		garage_id: 183,
		part_id: 263,
		quantity: 322,
	},
	{
		garage_id: 183,
		part_id: 264,
		quantity: 296,
	},
	{
		garage_id: 183,
		part_id: 265,
		quantity: 251,
	},
	{
		garage_id: 183,
		part_id: 266,
		quantity: 497,
	},
	{
		garage_id: 183,
		part_id: 267,
		quantity: 364,
	},
	{
		garage_id: 183,
		part_id: 268,
		quantity: 389,
	},
	{
		garage_id: 183,
		part_id: 269,
		quantity: 385,
	},
	{
		garage_id: 183,
		part_id: 270,
		quantity: 233,
	},
	{
		garage_id: 183,
		part_id: 271,
		quantity: 216,
	},
	{
		garage_id: 183,
		part_id: 272,
		quantity: 372,
	},
	{
		garage_id: 183,
		part_id: 273,
		quantity: 475,
	},
	{
		garage_id: 183,
		part_id: 274,
		quantity: 87,
	},
	{
		garage_id: 183,
		part_id: 275,
		quantity: 333,
	},
	{
		garage_id: 183,
		part_id: 276,
		quantity: 239,
	},
	{
		garage_id: 183,
		part_id: 277,
		quantity: 348,
	},
	{
		garage_id: 183,
		part_id: 278,
		quantity: 383,
	},
	{
		garage_id: 183,
		part_id: 279,
		quantity: 457,
	},
	{
		garage_id: 183,
		part_id: 280,
		quantity: 392,
	},
	{
		garage_id: 183,
		part_id: 281,
		quantity: 75,
	},
	{
		garage_id: 183,
		part_id: 282,
		quantity: 204,
	},
	{
		garage_id: 183,
		part_id: 283,
		quantity: 17,
	},
	{
		garage_id: 183,
		part_id: 284,
		quantity: 97,
	},
	{
		garage_id: 183,
		part_id: 285,
		quantity: 389,
	},
	{
		garage_id: 183,
		part_id: 286,
		quantity: 442,
	},
	{
		garage_id: 183,
		part_id: 287,
		quantity: 435,
	},
	{
		garage_id: 183,
		part_id: 288,
		quantity: 370,
	},
	{
		garage_id: 183,
		part_id: 289,
		quantity: 279,
	},
	{
		garage_id: 183,
		part_id: 290,
		quantity: 11,
	},
	{
		garage_id: 183,
		part_id: 291,
		quantity: 404,
	},
	{
		garage_id: 183,
		part_id: 292,
		quantity: 416,
	},
	{
		garage_id: 183,
		part_id: 293,
		quantity: 499,
	},
	{
		garage_id: 183,
		part_id: 294,
		quantity: 228,
	},
	{
		garage_id: 183,
		part_id: 295,
		quantity: 112,
	},
	{
		garage_id: 183,
		part_id: 296,
		quantity: 267,
	},
	{
		garage_id: 183,
		part_id: 297,
		quantity: 139,
	},
	{
		garage_id: 183,
		part_id: 298,
		quantity: 87,
	},
	{
		garage_id: 183,
		part_id: 299,
		quantity: 146,
	},
	{
		garage_id: 183,
		part_id: 300,
		quantity: 390,
	},
	{
		garage_id: 183,
		part_id: 301,
		quantity: 106,
	},
	{
		garage_id: 183,
		part_id: 302,
		quantity: 479,
	},
	{
		garage_id: 183,
		part_id: 303,
		quantity: 74,
	},
	{
		garage_id: 183,
		part_id: 304,
		quantity: 62,
	},
	{
		garage_id: 183,
		part_id: 305,
		quantity: 382,
	},
	{
		garage_id: 183,
		part_id: 306,
		quantity: 236,
	},
	{
		garage_id: 183,
		part_id: 307,
		quantity: 412,
	},
	{
		garage_id: 183,
		part_id: 308,
		quantity: 382,
	},
	{
		garage_id: 183,
		part_id: 309,
		quantity: 112,
	},
	{
		garage_id: 183,
		part_id: 310,
		quantity: 170,
	},
	{
		garage_id: 183,
		part_id: 311,
		quantity: 34,
	},
	{
		garage_id: 183,
		part_id: 312,
		quantity: 370,
	},
	{
		garage_id: 183,
		part_id: 313,
		quantity: 269,
	},
	{
		garage_id: 183,
		part_id: 314,
		quantity: 205,
	},
	{
		garage_id: 183,
		part_id: 315,
		quantity: 59,
	},
	{
		garage_id: 183,
		part_id: 316,
		quantity: 238,
	},
	{
		garage_id: 183,
		part_id: 317,
		quantity: 437,
	},
	{
		garage_id: 183,
		part_id: 318,
		quantity: 115,
	},
	{
		garage_id: 183,
		part_id: 319,
		quantity: 159,
	},
	{
		garage_id: 183,
		part_id: 320,
		quantity: 206,
	},
	{
		garage_id: 183,
		part_id: 321,
		quantity: 58,
	},
	{
		garage_id: 183,
		part_id: 322,
		quantity: 116,
	},
	{
		garage_id: 183,
		part_id: 323,
		quantity: 56,
	},
	{
		garage_id: 183,
		part_id: 324,
		quantity: 195,
	},
	{
		garage_id: 183,
		part_id: 325,
		quantity: 104,
	},
	{
		garage_id: 183,
		part_id: 326,
		quantity: 292,
	},
	{
		garage_id: 183,
		part_id: 327,
		quantity: 128,
	},
	{
		garage_id: 183,
		part_id: 328,
		quantity: 467,
	},
	{
		garage_id: 183,
		part_id: 329,
		quantity: 269,
	},
	{
		garage_id: 183,
		part_id: 330,
		quantity: 91,
	},
	{
		garage_id: 183,
		part_id: 331,
		quantity: 274,
	},
	{
		garage_id: 183,
		part_id: 332,
		quantity: 490,
	},
	{
		garage_id: 183,
		part_id: 333,
		quantity: 423,
	},
	{
		garage_id: 183,
		part_id: 334,
		quantity: 439,
	},
	{
		garage_id: 183,
		part_id: 335,
		quantity: 456,
	},
	{
		garage_id: 183,
		part_id: 336,
		quantity: 28,
	},
	{
		garage_id: 183,
		part_id: 337,
		quantity: 334,
	},
	{
		garage_id: 183,
		part_id: 338,
		quantity: 13,
	},
	{
		garage_id: 183,
		part_id: 339,
		quantity: 400,
	},
	{
		garage_id: 183,
		part_id: 340,
		quantity: 255,
	},
	{
		garage_id: 183,
		part_id: 341,
		quantity: 124,
	},
	{
		garage_id: 183,
		part_id: 342,
		quantity: 279,
	},
	{
		garage_id: 183,
		part_id: 343,
		quantity: 377,
	},
	{
		garage_id: 183,
		part_id: 344,
		quantity: 434,
	},
	{
		garage_id: 183,
		part_id: 345,
		quantity: 25,
	},
	{
		garage_id: 183,
		part_id: 346,
		quantity: 264,
	},
	{
		garage_id: 183,
		part_id: 347,
		quantity: 396,
	},
	{
		garage_id: 183,
		part_id: 348,
		quantity: 456,
	},
	{
		garage_id: 183,
		part_id: 349,
		quantity: 331,
	},
	{
		garage_id: 183,
		part_id: 350,
		quantity: 267,
	},
	{
		garage_id: 183,
		part_id: 351,
		quantity: 450,
	},
	{
		garage_id: 183,
		part_id: 352,
		quantity: 336,
	},
	{
		garage_id: 183,
		part_id: 353,
		quantity: 129,
	},
	{
		garage_id: 183,
		part_id: 354,
		quantity: 340,
	},
	{
		garage_id: 183,
		part_id: 355,
		quantity: 352,
	},
	{
		garage_id: 183,
		part_id: 356,
		quantity: 263,
	},
	{
		garage_id: 183,
		part_id: 357,
		quantity: 320,
	},
	{
		garage_id: 183,
		part_id: 358,
		quantity: 231,
	},
	{
		garage_id: 183,
		part_id: 359,
		quantity: 118,
	},
	{
		garage_id: 183,
		part_id: 360,
		quantity: 417,
	},
	{
		garage_id: 183,
		part_id: 361,
		quantity: 257,
	},
	{
		garage_id: 183,
		part_id: 362,
		quantity: 456,
	},
	{
		garage_id: 183,
		part_id: 363,
		quantity: 88,
	},
	{
		garage_id: 183,
		part_id: 364,
		quantity: 266,
	},
	{
		garage_id: 183,
		part_id: 365,
		quantity: 276,
	},
	{
		garage_id: 183,
		part_id: 366,
		quantity: 404,
	},
	{
		garage_id: 183,
		part_id: 367,
		quantity: 400,
	},
	{
		garage_id: 183,
		part_id: 368,
		quantity: 233,
	},
	{
		garage_id: 183,
		part_id: 369,
		quantity: 349,
	},
	{
		garage_id: 183,
		part_id: 370,
		quantity: 183,
	},
	{
		garage_id: 183,
		part_id: 371,
		quantity: 261,
	},
	{
		garage_id: 183,
		part_id: 372,
		quantity: 490,
	},
	{
		garage_id: 183,
		part_id: 373,
		quantity: 248,
	},
	{
		garage_id: 183,
		part_id: 374,
		quantity: 312,
	},
	{
		garage_id: 183,
		part_id: 375,
		quantity: 183,
	},
	{
		garage_id: 183,
		part_id: 376,
		quantity: 356,
	},
	{
		garage_id: 183,
		part_id: 377,
		quantity: 410,
	},
	{
		garage_id: 183,
		part_id: 378,
		quantity: 187,
	},
	{
		garage_id: 183,
		part_id: 379,
		quantity: 304,
	},
	{
		garage_id: 183,
		part_id: 380,
		quantity: 401,
	},
	{
		garage_id: 183,
		part_id: 381,
		quantity: 39,
	},
	{
		garage_id: 183,
		part_id: 382,
		quantity: 45,
	},
	{
		garage_id: 183,
		part_id: 383,
		quantity: 2,
	},
	{
		garage_id: 183,
		part_id: 384,
		quantity: 396,
	},
	{
		garage_id: 183,
		part_id: 385,
		quantity: 248,
	},
	{
		garage_id: 183,
		part_id: 386,
		quantity: 343,
	},
	{
		garage_id: 183,
		part_id: 387,
		quantity: 321,
	},
	{
		garage_id: 183,
		part_id: 388,
		quantity: 90,
	},
	{
		garage_id: 183,
		part_id: 389,
		quantity: 379,
	},
	{
		garage_id: 183,
		part_id: 390,
		quantity: 11,
	},
	{
		garage_id: 183,
		part_id: 391,
		quantity: 450,
	},
	{
		garage_id: 183,
		part_id: 392,
		quantity: 379,
	},
	{
		garage_id: 183,
		part_id: 393,
		quantity: 19,
	},
	{
		garage_id: 183,
		part_id: 394,
		quantity: 119,
	},
	{
		garage_id: 183,
		part_id: 395,
		quantity: 245,
	},
	{
		garage_id: 183,
		part_id: 396,
		quantity: 44,
	},
	{
		garage_id: 183,
		part_id: 397,
		quantity: 246,
	},
	{
		garage_id: 183,
		part_id: 398,
		quantity: 182,
	},
	{
		garage_id: 183,
		part_id: 399,
		quantity: 327,
	},
	{
		garage_id: 184,
		part_id: 0,
		quantity: 97,
	},
	{
		garage_id: 184,
		part_id: 1,
		quantity: 230,
	},
	{
		garage_id: 184,
		part_id: 2,
		quantity: 295,
	},
	{
		garage_id: 184,
		part_id: 3,
		quantity: 234,
	},
	{
		garage_id: 184,
		part_id: 4,
		quantity: 439,
	},
	{
		garage_id: 184,
		part_id: 5,
		quantity: 167,
	},
	{
		garage_id: 184,
		part_id: 6,
		quantity: 268,
	},
	{
		garage_id: 184,
		part_id: 7,
		quantity: 462,
	},
	{
		garage_id: 184,
		part_id: 8,
		quantity: 317,
	},
	{
		garage_id: 184,
		part_id: 9,
		quantity: 211,
	},
	{
		garage_id: 184,
		part_id: 10,
		quantity: 49,
	},
	{
		garage_id: 184,
		part_id: 11,
		quantity: 211,
	},
	{
		garage_id: 184,
		part_id: 12,
		quantity: 444,
	},
	{
		garage_id: 184,
		part_id: 13,
		quantity: 458,
	},
	{
		garage_id: 184,
		part_id: 14,
		quantity: 313,
	},
	{
		garage_id: 184,
		part_id: 15,
		quantity: 390,
	},
	{
		garage_id: 184,
		part_id: 16,
		quantity: 34,
	},
	{
		garage_id: 184,
		part_id: 17,
		quantity: 398,
	},
	{
		garage_id: 184,
		part_id: 18,
		quantity: 484,
	},
	{
		garage_id: 184,
		part_id: 19,
		quantity: 457,
	},
	{
		garage_id: 184,
		part_id: 20,
		quantity: 11,
	},
	{
		garage_id: 184,
		part_id: 21,
		quantity: 135,
	},
	{
		garage_id: 184,
		part_id: 22,
		quantity: 365,
	},
	{
		garage_id: 184,
		part_id: 23,
		quantity: 118,
	},
	{
		garage_id: 184,
		part_id: 24,
		quantity: 444,
	},
	{
		garage_id: 184,
		part_id: 25,
		quantity: 266,
	},
	{
		garage_id: 184,
		part_id: 26,
		quantity: 495,
	},
	{
		garage_id: 184,
		part_id: 27,
		quantity: 148,
	},
	{
		garage_id: 184,
		part_id: 28,
		quantity: 210,
	},
	{
		garage_id: 184,
		part_id: 29,
		quantity: 65,
	},
	{
		garage_id: 184,
		part_id: 30,
		quantity: 137,
	},
	{
		garage_id: 184,
		part_id: 31,
		quantity: 329,
	},
	{
		garage_id: 184,
		part_id: 32,
		quantity: 311,
	},
	{
		garage_id: 184,
		part_id: 33,
		quantity: 291,
	},
	{
		garage_id: 184,
		part_id: 34,
		quantity: 346,
	},
	{
		garage_id: 184,
		part_id: 35,
		quantity: 86,
	},
	{
		garage_id: 184,
		part_id: 36,
		quantity: 49,
	},
	{
		garage_id: 184,
		part_id: 37,
		quantity: 436,
	},
	{
		garage_id: 184,
		part_id: 38,
		quantity: 253,
	},
	{
		garage_id: 184,
		part_id: 39,
		quantity: 231,
	},
	{
		garage_id: 184,
		part_id: 40,
		quantity: 437,
	},
	{
		garage_id: 184,
		part_id: 41,
		quantity: 312,
	},
	{
		garage_id: 184,
		part_id: 42,
		quantity: 52,
	},
	{
		garage_id: 184,
		part_id: 43,
		quantity: 89,
	},
	{
		garage_id: 184,
		part_id: 44,
		quantity: 480,
	},
	{
		garage_id: 184,
		part_id: 45,
		quantity: 291,
	},
	{
		garage_id: 184,
		part_id: 46,
		quantity: 279,
	},
	{
		garage_id: 184,
		part_id: 47,
		quantity: 166,
	},
	{
		garage_id: 184,
		part_id: 48,
		quantity: 75,
	},
	{
		garage_id: 184,
		part_id: 49,
		quantity: 87,
	},
	{
		garage_id: 184,
		part_id: 50,
		quantity: 161,
	},
	{
		garage_id: 184,
		part_id: 51,
		quantity: 289,
	},
	{
		garage_id: 184,
		part_id: 52,
		quantity: 445,
	},
	{
		garage_id: 184,
		part_id: 53,
		quantity: 242,
	},
	{
		garage_id: 184,
		part_id: 54,
		quantity: 338,
	},
	{
		garage_id: 184,
		part_id: 55,
		quantity: 141,
	},
	{
		garage_id: 184,
		part_id: 56,
		quantity: 190,
	},
	{
		garage_id: 184,
		part_id: 57,
		quantity: 145,
	},
	{
		garage_id: 184,
		part_id: 58,
		quantity: 296,
	},
	{
		garage_id: 184,
		part_id: 59,
		quantity: 308,
	},
	{
		garage_id: 184,
		part_id: 60,
		quantity: 490,
	},
	{
		garage_id: 184,
		part_id: 61,
		quantity: 101,
	},
	{
		garage_id: 184,
		part_id: 62,
		quantity: 218,
	},
	{
		garage_id: 184,
		part_id: 63,
		quantity: 156,
	},
	{
		garage_id: 184,
		part_id: 64,
		quantity: 163,
	},
	{
		garage_id: 184,
		part_id: 65,
		quantity: 414,
	},
	{
		garage_id: 184,
		part_id: 66,
		quantity: 232,
	},
	{
		garage_id: 184,
		part_id: 67,
		quantity: 244,
	},
	{
		garage_id: 184,
		part_id: 68,
		quantity: 472,
	},
	{
		garage_id: 184,
		part_id: 69,
		quantity: 211,
	},
	{
		garage_id: 184,
		part_id: 70,
		quantity: 255,
	},
	{
		garage_id: 184,
		part_id: 71,
		quantity: 258,
	},
	{
		garage_id: 184,
		part_id: 72,
		quantity: 245,
	},
	{
		garage_id: 184,
		part_id: 73,
		quantity: 137,
	},
	{
		garage_id: 184,
		part_id: 74,
		quantity: 389,
	},
	{
		garage_id: 184,
		part_id: 75,
		quantity: 42,
	},
	{
		garage_id: 184,
		part_id: 76,
		quantity: 139,
	},
	{
		garage_id: 184,
		part_id: 77,
		quantity: 442,
	},
	{
		garage_id: 184,
		part_id: 78,
		quantity: 117,
	},
	{
		garage_id: 184,
		part_id: 79,
		quantity: 24,
	},
	{
		garage_id: 184,
		part_id: 80,
		quantity: 245,
	},
	{
		garage_id: 184,
		part_id: 81,
		quantity: 307,
	},
	{
		garage_id: 184,
		part_id: 82,
		quantity: 250,
	},
	{
		garage_id: 184,
		part_id: 83,
		quantity: 126,
	},
	{
		garage_id: 184,
		part_id: 84,
		quantity: 389,
	},
	{
		garage_id: 184,
		part_id: 85,
		quantity: 263,
	},
	{
		garage_id: 184,
		part_id: 86,
		quantity: 302,
	},
	{
		garage_id: 184,
		part_id: 87,
		quantity: 450,
	},
	{
		garage_id: 184,
		part_id: 88,
		quantity: 338,
	},
	{
		garage_id: 184,
		part_id: 89,
		quantity: 312,
	},
	{
		garage_id: 184,
		part_id: 90,
		quantity: 61,
	},
	{
		garage_id: 184,
		part_id: 91,
		quantity: 475,
	},
	{
		garage_id: 184,
		part_id: 92,
		quantity: 267,
	},
	{
		garage_id: 184,
		part_id: 93,
		quantity: 226,
	},
	{
		garage_id: 184,
		part_id: 94,
		quantity: 196,
	},
	{
		garage_id: 184,
		part_id: 95,
		quantity: 194,
	},
	{
		garage_id: 184,
		part_id: 96,
		quantity: 251,
	},
	{
		garage_id: 184,
		part_id: 97,
		quantity: 398,
	},
	{
		garage_id: 184,
		part_id: 98,
		quantity: 494,
	},
	{
		garage_id: 184,
		part_id: 99,
		quantity: 43,
	},
	{
		garage_id: 184,
		part_id: 100,
		quantity: 38,
	},
	{
		garage_id: 184,
		part_id: 101,
		quantity: 34,
	},
	{
		garage_id: 184,
		part_id: 102,
		quantity: 169,
	},
	{
		garage_id: 184,
		part_id: 103,
		quantity: 429,
	},
	{
		garage_id: 184,
		part_id: 104,
		quantity: 409,
	},
	{
		garage_id: 184,
		part_id: 105,
		quantity: 419,
	},
	{
		garage_id: 184,
		part_id: 106,
		quantity: 469,
	},
	{
		garage_id: 184,
		part_id: 107,
		quantity: 128,
	},
	{
		garage_id: 184,
		part_id: 108,
		quantity: 301,
	},
	{
		garage_id: 184,
		part_id: 109,
		quantity: 491,
	},
	{
		garage_id: 184,
		part_id: 110,
		quantity: 137,
	},
	{
		garage_id: 184,
		part_id: 111,
		quantity: 239,
	},
	{
		garage_id: 184,
		part_id: 112,
		quantity: 14,
	},
	{
		garage_id: 184,
		part_id: 113,
		quantity: 399,
	},
	{
		garage_id: 184,
		part_id: 114,
		quantity: 79,
	},
	{
		garage_id: 184,
		part_id: 115,
		quantity: 270,
	},
	{
		garage_id: 184,
		part_id: 116,
		quantity: 61,
	},
	{
		garage_id: 184,
		part_id: 117,
		quantity: 189,
	},
	{
		garage_id: 184,
		part_id: 118,
		quantity: 184,
	},
	{
		garage_id: 184,
		part_id: 119,
		quantity: 303,
	},
	{
		garage_id: 184,
		part_id: 120,
		quantity: 482,
	},
	{
		garage_id: 184,
		part_id: 121,
		quantity: 83,
	},
	{
		garage_id: 184,
		part_id: 122,
		quantity: 376,
	},
	{
		garage_id: 184,
		part_id: 123,
		quantity: 278,
	},
	{
		garage_id: 184,
		part_id: 124,
		quantity: 416,
	},
	{
		garage_id: 184,
		part_id: 125,
		quantity: 439,
	},
	{
		garage_id: 184,
		part_id: 126,
		quantity: 102,
	},
	{
		garage_id: 184,
		part_id: 127,
		quantity: 194,
	},
	{
		garage_id: 184,
		part_id: 128,
		quantity: 300,
	},
	{
		garage_id: 184,
		part_id: 129,
		quantity: 433,
	},
	{
		garage_id: 184,
		part_id: 130,
		quantity: 136,
	},
	{
		garage_id: 184,
		part_id: 131,
		quantity: 476,
	},
	{
		garage_id: 184,
		part_id: 132,
		quantity: 376,
	},
	{
		garage_id: 184,
		part_id: 133,
		quantity: 363,
	},
	{
		garage_id: 184,
		part_id: 134,
		quantity: 139,
	},
	{
		garage_id: 184,
		part_id: 135,
		quantity: 282,
	},
	{
		garage_id: 184,
		part_id: 136,
		quantity: 500,
	},
	{
		garage_id: 184,
		part_id: 137,
		quantity: 432,
	},
	{
		garage_id: 184,
		part_id: 138,
		quantity: 172,
	},
	{
		garage_id: 184,
		part_id: 139,
		quantity: 72,
	},
	{
		garage_id: 184,
		part_id: 140,
		quantity: 304,
	},
	{
		garage_id: 184,
		part_id: 141,
		quantity: 21,
	},
	{
		garage_id: 184,
		part_id: 142,
		quantity: 346,
	},
	{
		garage_id: 184,
		part_id: 143,
		quantity: 9,
	},
	{
		garage_id: 184,
		part_id: 144,
		quantity: 272,
	},
	{
		garage_id: 184,
		part_id: 145,
		quantity: 381,
	},
	{
		garage_id: 184,
		part_id: 146,
		quantity: 10,
	},
	{
		garage_id: 184,
		part_id: 147,
		quantity: 142,
	},
	{
		garage_id: 184,
		part_id: 148,
		quantity: 154,
	},
	{
		garage_id: 184,
		part_id: 149,
		quantity: 436,
	},
	{
		garage_id: 184,
		part_id: 150,
		quantity: 114,
	},
	{
		garage_id: 184,
		part_id: 151,
		quantity: 47,
	},
	{
		garage_id: 184,
		part_id: 152,
		quantity: 213,
	},
	{
		garage_id: 184,
		part_id: 153,
		quantity: 76,
	},
	{
		garage_id: 184,
		part_id: 154,
		quantity: 458,
	},
	{
		garage_id: 184,
		part_id: 155,
		quantity: 95,
	},
	{
		garage_id: 184,
		part_id: 156,
		quantity: 119,
	},
	{
		garage_id: 184,
		part_id: 157,
		quantity: 421,
	},
	{
		garage_id: 184,
		part_id: 158,
		quantity: 182,
	},
	{
		garage_id: 184,
		part_id: 159,
		quantity: 428,
	},
	{
		garage_id: 184,
		part_id: 160,
		quantity: 429,
	},
	{
		garage_id: 184,
		part_id: 161,
		quantity: 202,
	},
	{
		garage_id: 184,
		part_id: 162,
		quantity: 248,
	},
	{
		garage_id: 184,
		part_id: 163,
		quantity: 268,
	},
	{
		garage_id: 184,
		part_id: 164,
		quantity: 16,
	},
	{
		garage_id: 184,
		part_id: 165,
		quantity: 78,
	},
	{
		garage_id: 184,
		part_id: 166,
		quantity: 261,
	},
	{
		garage_id: 184,
		part_id: 167,
		quantity: 391,
	},
	{
		garage_id: 184,
		part_id: 168,
		quantity: 330,
	},
	{
		garage_id: 184,
		part_id: 169,
		quantity: 294,
	},
	{
		garage_id: 184,
		part_id: 170,
		quantity: 12,
	},
	{
		garage_id: 184,
		part_id: 171,
		quantity: 416,
	},
	{
		garage_id: 184,
		part_id: 172,
		quantity: 356,
	},
	{
		garage_id: 184,
		part_id: 173,
		quantity: 384,
	},
	{
		garage_id: 184,
		part_id: 174,
		quantity: 410,
	},
	{
		garage_id: 184,
		part_id: 175,
		quantity: 245,
	},
	{
		garage_id: 184,
		part_id: 176,
		quantity: 66,
	},
	{
		garage_id: 184,
		part_id: 177,
		quantity: 489,
	},
	{
		garage_id: 184,
		part_id: 178,
		quantity: 105,
	},
	{
		garage_id: 184,
		part_id: 179,
		quantity: 134,
	},
	{
		garage_id: 184,
		part_id: 180,
		quantity: 168,
	},
	{
		garage_id: 184,
		part_id: 181,
		quantity: 319,
	},
	{
		garage_id: 184,
		part_id: 182,
		quantity: 249,
	},
	{
		garage_id: 184,
		part_id: 183,
		quantity: 100,
	},
	{
		garage_id: 184,
		part_id: 184,
		quantity: 336,
	},
	{
		garage_id: 184,
		part_id: 185,
		quantity: 281,
	},
	{
		garage_id: 184,
		part_id: 186,
		quantity: 194,
	},
	{
		garage_id: 184,
		part_id: 187,
		quantity: 277,
	},
	{
		garage_id: 184,
		part_id: 188,
		quantity: 97,
	},
	{
		garage_id: 184,
		part_id: 189,
		quantity: 110,
	},
	{
		garage_id: 184,
		part_id: 190,
		quantity: 4,
	},
	{
		garage_id: 184,
		part_id: 191,
		quantity: 231,
	},
	{
		garage_id: 184,
		part_id: 192,
		quantity: 435,
	},
	{
		garage_id: 184,
		part_id: 193,
		quantity: 53,
	},
	{
		garage_id: 184,
		part_id: 194,
		quantity: 156,
	},
	{
		garage_id: 184,
		part_id: 195,
		quantity: 444,
	},
	{
		garage_id: 184,
		part_id: 196,
		quantity: 497,
	},
	{
		garage_id: 184,
		part_id: 197,
		quantity: 484,
	},
	{
		garage_id: 184,
		part_id: 198,
		quantity: 384,
	},
	{
		garage_id: 184,
		part_id: 199,
		quantity: 318,
	},
	{
		garage_id: 184,
		part_id: 200,
		quantity: 425,
	},
	{
		garage_id: 184,
		part_id: 201,
		quantity: 147,
	},
	{
		garage_id: 184,
		part_id: 202,
		quantity: 180,
	},
	{
		garage_id: 184,
		part_id: 203,
		quantity: 31,
	},
	{
		garage_id: 184,
		part_id: 204,
		quantity: 78,
	},
	{
		garage_id: 184,
		part_id: 205,
		quantity: 232,
	},
	{
		garage_id: 184,
		part_id: 206,
		quantity: 12,
	},
	{
		garage_id: 184,
		part_id: 207,
		quantity: 117,
	},
	{
		garage_id: 184,
		part_id: 208,
		quantity: 32,
	},
	{
		garage_id: 184,
		part_id: 209,
		quantity: 146,
	},
	{
		garage_id: 184,
		part_id: 210,
		quantity: 74,
	},
	{
		garage_id: 184,
		part_id: 211,
		quantity: 253,
	},
	{
		garage_id: 184,
		part_id: 212,
		quantity: 145,
	},
	{
		garage_id: 184,
		part_id: 213,
		quantity: 13,
	},
	{
		garage_id: 184,
		part_id: 214,
		quantity: 142,
	},
	{
		garage_id: 184,
		part_id: 215,
		quantity: 20,
	},
	{
		garage_id: 184,
		part_id: 216,
		quantity: 443,
	},
	{
		garage_id: 184,
		part_id: 217,
		quantity: 19,
	},
	{
		garage_id: 184,
		part_id: 218,
		quantity: 13,
	},
	{
		garage_id: 184,
		part_id: 219,
		quantity: 186,
	},
	{
		garage_id: 184,
		part_id: 220,
		quantity: 35,
	},
	{
		garage_id: 184,
		part_id: 221,
		quantity: 3,
	},
	{
		garage_id: 184,
		part_id: 222,
		quantity: 153,
	},
	{
		garage_id: 184,
		part_id: 223,
		quantity: 218,
	},
	{
		garage_id: 184,
		part_id: 224,
		quantity: 347,
	},
	{
		garage_id: 184,
		part_id: 225,
		quantity: 59,
	},
	{
		garage_id: 184,
		part_id: 226,
		quantity: 91,
	},
	{
		garage_id: 184,
		part_id: 227,
		quantity: 156,
	},
	{
		garage_id: 184,
		part_id: 228,
		quantity: 143,
	},
	{
		garage_id: 184,
		part_id: 229,
		quantity: 404,
	},
	{
		garage_id: 184,
		part_id: 230,
		quantity: 438,
	},
	{
		garage_id: 184,
		part_id: 231,
		quantity: 286,
	},
	{
		garage_id: 184,
		part_id: 232,
		quantity: 423,
	},
	{
		garage_id: 184,
		part_id: 233,
		quantity: 47,
	},
	{
		garage_id: 184,
		part_id: 234,
		quantity: 432,
	},
	{
		garage_id: 184,
		part_id: 235,
		quantity: 441,
	},
	{
		garage_id: 184,
		part_id: 236,
		quantity: 440,
	},
	{
		garage_id: 184,
		part_id: 237,
		quantity: 227,
	},
	{
		garage_id: 184,
		part_id: 238,
		quantity: 498,
	},
	{
		garage_id: 184,
		part_id: 239,
		quantity: 58,
	},
	{
		garage_id: 184,
		part_id: 240,
		quantity: 217,
	},
	{
		garage_id: 184,
		part_id: 241,
		quantity: 496,
	},
	{
		garage_id: 184,
		part_id: 242,
		quantity: 246,
	},
	{
		garage_id: 184,
		part_id: 243,
		quantity: 114,
	},
	{
		garage_id: 184,
		part_id: 244,
		quantity: 320,
	},
	{
		garage_id: 184,
		part_id: 245,
		quantity: 473,
	},
	{
		garage_id: 184,
		part_id: 246,
		quantity: 392,
	},
	{
		garage_id: 184,
		part_id: 247,
		quantity: 416,
	},
	{
		garage_id: 184,
		part_id: 248,
		quantity: 443,
	},
	{
		garage_id: 184,
		part_id: 249,
		quantity: 388,
	},
	{
		garage_id: 184,
		part_id: 250,
		quantity: 86,
	},
	{
		garage_id: 184,
		part_id: 251,
		quantity: 407,
	},
	{
		garage_id: 184,
		part_id: 252,
		quantity: 371,
	},
	{
		garage_id: 184,
		part_id: 253,
		quantity: 479,
	},
	{
		garage_id: 184,
		part_id: 254,
		quantity: 39,
	},
	{
		garage_id: 184,
		part_id: 255,
		quantity: 198,
	},
	{
		garage_id: 184,
		part_id: 256,
		quantity: 240,
	},
	{
		garage_id: 184,
		part_id: 257,
		quantity: 269,
	},
	{
		garage_id: 184,
		part_id: 258,
		quantity: 120,
	},
	{
		garage_id: 184,
		part_id: 259,
		quantity: 65,
	},
	{
		garage_id: 184,
		part_id: 260,
		quantity: 113,
	},
	{
		garage_id: 184,
		part_id: 261,
		quantity: 333,
	},
	{
		garage_id: 184,
		part_id: 262,
		quantity: 323,
	},
	{
		garage_id: 184,
		part_id: 263,
		quantity: 111,
	},
	{
		garage_id: 184,
		part_id: 264,
		quantity: 429,
	},
	{
		garage_id: 184,
		part_id: 265,
		quantity: 331,
	},
	{
		garage_id: 184,
		part_id: 266,
		quantity: 478,
	},
	{
		garage_id: 184,
		part_id: 267,
		quantity: 238,
	},
	{
		garage_id: 184,
		part_id: 268,
		quantity: 329,
	},
	{
		garage_id: 184,
		part_id: 269,
		quantity: 496,
	},
	{
		garage_id: 184,
		part_id: 270,
		quantity: 105,
	},
	{
		garage_id: 184,
		part_id: 271,
		quantity: 280,
	},
	{
		garage_id: 184,
		part_id: 272,
		quantity: 61,
	},
	{
		garage_id: 184,
		part_id: 273,
		quantity: 2,
	},
	{
		garage_id: 184,
		part_id: 274,
		quantity: 16,
	},
	{
		garage_id: 184,
		part_id: 275,
		quantity: 492,
	},
	{
		garage_id: 184,
		part_id: 276,
		quantity: 167,
	},
	{
		garage_id: 184,
		part_id: 277,
		quantity: 248,
	},
	{
		garage_id: 184,
		part_id: 278,
		quantity: 424,
	},
	{
		garage_id: 184,
		part_id: 279,
		quantity: 233,
	},
	{
		garage_id: 184,
		part_id: 280,
		quantity: 237,
	},
	{
		garage_id: 184,
		part_id: 281,
		quantity: 56,
	},
	{
		garage_id: 184,
		part_id: 282,
		quantity: 275,
	},
	{
		garage_id: 184,
		part_id: 283,
		quantity: 356,
	},
	{
		garage_id: 184,
		part_id: 284,
		quantity: 294,
	},
	{
		garage_id: 184,
		part_id: 285,
		quantity: 434,
	},
	{
		garage_id: 184,
		part_id: 286,
		quantity: 469,
	},
	{
		garage_id: 184,
		part_id: 287,
		quantity: 179,
	},
	{
		garage_id: 184,
		part_id: 288,
		quantity: 344,
	},
	{
		garage_id: 184,
		part_id: 289,
		quantity: 423,
	},
	{
		garage_id: 184,
		part_id: 290,
		quantity: 192,
	},
	{
		garage_id: 184,
		part_id: 291,
		quantity: 140,
	},
	{
		garage_id: 184,
		part_id: 292,
		quantity: 30,
	},
	{
		garage_id: 184,
		part_id: 293,
		quantity: 230,
	},
	{
		garage_id: 184,
		part_id: 294,
		quantity: 119,
	},
	{
		garage_id: 184,
		part_id: 295,
		quantity: 452,
	},
	{
		garage_id: 184,
		part_id: 296,
		quantity: 200,
	},
	{
		garage_id: 184,
		part_id: 297,
		quantity: 221,
	},
	{
		garage_id: 184,
		part_id: 298,
		quantity: 185,
	},
	{
		garage_id: 184,
		part_id: 299,
		quantity: 165,
	},
	{
		garage_id: 184,
		part_id: 300,
		quantity: 309,
	},
	{
		garage_id: 184,
		part_id: 301,
		quantity: 449,
	},
	{
		garage_id: 184,
		part_id: 302,
		quantity: 409,
	},
	{
		garage_id: 184,
		part_id: 303,
		quantity: 138,
	},
	{
		garage_id: 184,
		part_id: 304,
		quantity: 86,
	},
	{
		garage_id: 184,
		part_id: 305,
		quantity: 297,
	},
	{
		garage_id: 184,
		part_id: 306,
		quantity: 317,
	},
	{
		garage_id: 184,
		part_id: 307,
		quantity: 323,
	},
	{
		garage_id: 184,
		part_id: 308,
		quantity: 396,
	},
	{
		garage_id: 184,
		part_id: 309,
		quantity: 180,
	},
	{
		garage_id: 184,
		part_id: 310,
		quantity: 243,
	},
	{
		garage_id: 184,
		part_id: 311,
		quantity: 273,
	},
	{
		garage_id: 184,
		part_id: 312,
		quantity: 44,
	},
	{
		garage_id: 184,
		part_id: 313,
		quantity: 55,
	},
	{
		garage_id: 184,
		part_id: 314,
		quantity: 36,
	},
	{
		garage_id: 184,
		part_id: 315,
		quantity: 360,
	},
	{
		garage_id: 184,
		part_id: 316,
		quantity: 285,
	},
	{
		garage_id: 184,
		part_id: 317,
		quantity: 237,
	},
	{
		garage_id: 184,
		part_id: 318,
		quantity: 139,
	},
	{
		garage_id: 184,
		part_id: 319,
		quantity: 345,
	},
	{
		garage_id: 184,
		part_id: 320,
		quantity: 404,
	},
	{
		garage_id: 184,
		part_id: 321,
		quantity: 360,
	},
	{
		garage_id: 184,
		part_id: 322,
		quantity: 209,
	},
	{
		garage_id: 184,
		part_id: 323,
		quantity: 94,
	},
	{
		garage_id: 184,
		part_id: 324,
		quantity: 209,
	},
	{
		garage_id: 184,
		part_id: 325,
		quantity: 411,
	},
	{
		garage_id: 184,
		part_id: 326,
		quantity: 234,
	},
	{
		garage_id: 184,
		part_id: 327,
		quantity: 2,
	},
	{
		garage_id: 184,
		part_id: 328,
		quantity: 465,
	},
	{
		garage_id: 184,
		part_id: 329,
		quantity: 33,
	},
	{
		garage_id: 184,
		part_id: 330,
		quantity: 150,
	},
	{
		garage_id: 184,
		part_id: 331,
		quantity: 78,
	},
	{
		garage_id: 184,
		part_id: 332,
		quantity: 332,
	},
	{
		garage_id: 184,
		part_id: 333,
		quantity: 357,
	},
	{
		garage_id: 184,
		part_id: 334,
		quantity: 3,
	},
	{
		garage_id: 184,
		part_id: 335,
		quantity: 466,
	},
	{
		garage_id: 184,
		part_id: 336,
		quantity: 342,
	},
	{
		garage_id: 184,
		part_id: 337,
		quantity: 74,
	},
	{
		garage_id: 184,
		part_id: 338,
		quantity: 435,
	},
	{
		garage_id: 184,
		part_id: 339,
		quantity: 308,
	},
	{
		garage_id: 184,
		part_id: 340,
		quantity: 50,
	},
	{
		garage_id: 184,
		part_id: 341,
		quantity: 344,
	},
	{
		garage_id: 184,
		part_id: 342,
		quantity: 349,
	},
	{
		garage_id: 184,
		part_id: 343,
		quantity: 214,
	},
	{
		garage_id: 184,
		part_id: 344,
		quantity: 347,
	},
	{
		garage_id: 184,
		part_id: 345,
		quantity: 0,
	},
	{
		garage_id: 184,
		part_id: 346,
		quantity: 451,
	},
	{
		garage_id: 184,
		part_id: 347,
		quantity: 203,
	},
	{
		garage_id: 184,
		part_id: 348,
		quantity: 88,
	},
	{
		garage_id: 184,
		part_id: 349,
		quantity: 67,
	},
	{
		garage_id: 184,
		part_id: 350,
		quantity: 159,
	},
	{
		garage_id: 184,
		part_id: 351,
		quantity: 14,
	},
	{
		garage_id: 184,
		part_id: 352,
		quantity: 7,
	},
	{
		garage_id: 184,
		part_id: 353,
		quantity: 229,
	},
	{
		garage_id: 184,
		part_id: 354,
		quantity: 114,
	},
	{
		garage_id: 184,
		part_id: 355,
		quantity: 462,
	},
	{
		garage_id: 184,
		part_id: 356,
		quantity: 49,
	},
	{
		garage_id: 184,
		part_id: 357,
		quantity: 313,
	},
	{
		garage_id: 184,
		part_id: 358,
		quantity: 97,
	},
	{
		garage_id: 184,
		part_id: 359,
		quantity: 309,
	},
	{
		garage_id: 184,
		part_id: 360,
		quantity: 249,
	},
	{
		garage_id: 184,
		part_id: 361,
		quantity: 294,
	},
	{
		garage_id: 184,
		part_id: 362,
		quantity: 496,
	},
	{
		garage_id: 184,
		part_id: 363,
		quantity: 137,
	},
	{
		garage_id: 184,
		part_id: 364,
		quantity: 270,
	},
	{
		garage_id: 184,
		part_id: 365,
		quantity: 315,
	},
	{
		garage_id: 184,
		part_id: 366,
		quantity: 382,
	},
	{
		garage_id: 184,
		part_id: 367,
		quantity: 350,
	},
	{
		garage_id: 184,
		part_id: 368,
		quantity: 250,
	},
	{
		garage_id: 184,
		part_id: 369,
		quantity: 317,
	},
	{
		garage_id: 184,
		part_id: 370,
		quantity: 450,
	},
	{
		garage_id: 184,
		part_id: 371,
		quantity: 54,
	},
	{
		garage_id: 184,
		part_id: 372,
		quantity: 275,
	},
	{
		garage_id: 184,
		part_id: 373,
		quantity: 228,
	},
	{
		garage_id: 184,
		part_id: 374,
		quantity: 202,
	},
	{
		garage_id: 184,
		part_id: 375,
		quantity: 74,
	},
	{
		garage_id: 184,
		part_id: 376,
		quantity: 421,
	},
	{
		garage_id: 184,
		part_id: 377,
		quantity: 326,
	},
	{
		garage_id: 184,
		part_id: 378,
		quantity: 208,
	},
	{
		garage_id: 184,
		part_id: 379,
		quantity: 228,
	},
	{
		garage_id: 184,
		part_id: 380,
		quantity: 418,
	},
	{
		garage_id: 184,
		part_id: 381,
		quantity: 497,
	},
	{
		garage_id: 184,
		part_id: 382,
		quantity: 137,
	},
	{
		garage_id: 184,
		part_id: 383,
		quantity: 276,
	},
	{
		garage_id: 184,
		part_id: 384,
		quantity: 192,
	},
	{
		garage_id: 184,
		part_id: 385,
		quantity: 319,
	},
	{
		garage_id: 184,
		part_id: 386,
		quantity: 99,
	},
	{
		garage_id: 184,
		part_id: 387,
		quantity: 370,
	},
	{
		garage_id: 184,
		part_id: 388,
		quantity: 237,
	},
	{
		garage_id: 184,
		part_id: 389,
		quantity: 445,
	},
	{
		garage_id: 184,
		part_id: 390,
		quantity: 321,
	},
	{
		garage_id: 184,
		part_id: 391,
		quantity: 316,
	},
	{
		garage_id: 184,
		part_id: 392,
		quantity: 473,
	},
	{
		garage_id: 184,
		part_id: 393,
		quantity: 42,
	},
	{
		garage_id: 184,
		part_id: 394,
		quantity: 161,
	},
	{
		garage_id: 184,
		part_id: 395,
		quantity: 404,
	},
	{
		garage_id: 184,
		part_id: 396,
		quantity: 352,
	},
	{
		garage_id: 184,
		part_id: 397,
		quantity: 27,
	},
	{
		garage_id: 184,
		part_id: 398,
		quantity: 405,
	},
	{
		garage_id: 184,
		part_id: 399,
		quantity: 118,
	},
	{
		garage_id: 185,
		part_id: 0,
		quantity: 87,
	},
	{
		garage_id: 185,
		part_id: 1,
		quantity: 453,
	},
	{
		garage_id: 185,
		part_id: 2,
		quantity: 258,
	},
	{
		garage_id: 185,
		part_id: 3,
		quantity: 202,
	},
	{
		garage_id: 185,
		part_id: 4,
		quantity: 70,
	},
	{
		garage_id: 185,
		part_id: 5,
		quantity: 372,
	},
	{
		garage_id: 185,
		part_id: 6,
		quantity: 68,
	},
	{
		garage_id: 185,
		part_id: 7,
		quantity: 338,
	},
	{
		garage_id: 185,
		part_id: 8,
		quantity: 500,
	},
	{
		garage_id: 185,
		part_id: 9,
		quantity: 159,
	},
	{
		garage_id: 185,
		part_id: 10,
		quantity: 311,
	},
	{
		garage_id: 185,
		part_id: 11,
		quantity: 341,
	},
	{
		garage_id: 185,
		part_id: 12,
		quantity: 216,
	},
	{
		garage_id: 185,
		part_id: 13,
		quantity: 414,
	},
	{
		garage_id: 185,
		part_id: 14,
		quantity: 458,
	},
	{
		garage_id: 185,
		part_id: 15,
		quantity: 48,
	},
	{
		garage_id: 185,
		part_id: 16,
		quantity: 229,
	},
	{
		garage_id: 185,
		part_id: 17,
		quantity: 63,
	},
	{
		garage_id: 185,
		part_id: 18,
		quantity: 106,
	},
	{
		garage_id: 185,
		part_id: 19,
		quantity: 323,
	},
	{
		garage_id: 185,
		part_id: 20,
		quantity: 315,
	},
	{
		garage_id: 185,
		part_id: 21,
		quantity: 262,
	},
	{
		garage_id: 185,
		part_id: 22,
		quantity: 356,
	},
	{
		garage_id: 185,
		part_id: 23,
		quantity: 456,
	},
	{
		garage_id: 185,
		part_id: 24,
		quantity: 317,
	},
	{
		garage_id: 185,
		part_id: 25,
		quantity: 394,
	},
	{
		garage_id: 185,
		part_id: 26,
		quantity: 189,
	},
	{
		garage_id: 185,
		part_id: 27,
		quantity: 404,
	},
	{
		garage_id: 185,
		part_id: 28,
		quantity: 452,
	},
	{
		garage_id: 185,
		part_id: 29,
		quantity: 360,
	},
	{
		garage_id: 185,
		part_id: 30,
		quantity: 229,
	},
	{
		garage_id: 185,
		part_id: 31,
		quantity: 239,
	},
	{
		garage_id: 185,
		part_id: 32,
		quantity: 335,
	},
	{
		garage_id: 185,
		part_id: 33,
		quantity: 135,
	},
	{
		garage_id: 185,
		part_id: 34,
		quantity: 22,
	},
	{
		garage_id: 185,
		part_id: 35,
		quantity: 78,
	},
	{
		garage_id: 185,
		part_id: 36,
		quantity: 324,
	},
	{
		garage_id: 185,
		part_id: 37,
		quantity: 236,
	},
	{
		garage_id: 185,
		part_id: 38,
		quantity: 46,
	},
	{
		garage_id: 185,
		part_id: 39,
		quantity: 297,
	},
	{
		garage_id: 185,
		part_id: 40,
		quantity: 92,
	},
	{
		garage_id: 185,
		part_id: 41,
		quantity: 445,
	},
	{
		garage_id: 185,
		part_id: 42,
		quantity: 252,
	},
	{
		garage_id: 185,
		part_id: 43,
		quantity: 326,
	},
	{
		garage_id: 185,
		part_id: 44,
		quantity: 389,
	},
	{
		garage_id: 185,
		part_id: 45,
		quantity: 449,
	},
	{
		garage_id: 185,
		part_id: 46,
		quantity: 87,
	},
	{
		garage_id: 185,
		part_id: 47,
		quantity: 99,
	},
	{
		garage_id: 185,
		part_id: 48,
		quantity: 307,
	},
	{
		garage_id: 185,
		part_id: 49,
		quantity: 339,
	},
	{
		garage_id: 185,
		part_id: 50,
		quantity: 32,
	},
	{
		garage_id: 185,
		part_id: 51,
		quantity: 328,
	},
	{
		garage_id: 185,
		part_id: 52,
		quantity: 5,
	},
	{
		garage_id: 185,
		part_id: 53,
		quantity: 164,
	},
	{
		garage_id: 185,
		part_id: 54,
		quantity: 360,
	},
	{
		garage_id: 185,
		part_id: 55,
		quantity: 415,
	},
	{
		garage_id: 185,
		part_id: 56,
		quantity: 446,
	},
	{
		garage_id: 185,
		part_id: 57,
		quantity: 181,
	},
	{
		garage_id: 185,
		part_id: 58,
		quantity: 457,
	},
	{
		garage_id: 185,
		part_id: 59,
		quantity: 181,
	},
	{
		garage_id: 185,
		part_id: 60,
		quantity: 273,
	},
	{
		garage_id: 185,
		part_id: 61,
		quantity: 387,
	},
	{
		garage_id: 185,
		part_id: 62,
		quantity: 472,
	},
	{
		garage_id: 185,
		part_id: 63,
		quantity: 485,
	},
	{
		garage_id: 185,
		part_id: 64,
		quantity: 314,
	},
	{
		garage_id: 185,
		part_id: 65,
		quantity: 435,
	},
	{
		garage_id: 185,
		part_id: 66,
		quantity: 143,
	},
	{
		garage_id: 185,
		part_id: 67,
		quantity: 102,
	},
	{
		garage_id: 185,
		part_id: 68,
		quantity: 356,
	},
	{
		garage_id: 185,
		part_id: 69,
		quantity: 482,
	},
	{
		garage_id: 185,
		part_id: 70,
		quantity: 279,
	},
	{
		garage_id: 185,
		part_id: 71,
		quantity: 273,
	},
	{
		garage_id: 185,
		part_id: 72,
		quantity: 12,
	},
	{
		garage_id: 185,
		part_id: 73,
		quantity: 282,
	},
	{
		garage_id: 185,
		part_id: 74,
		quantity: 179,
	},
	{
		garage_id: 185,
		part_id: 75,
		quantity: 410,
	},
	{
		garage_id: 185,
		part_id: 76,
		quantity: 314,
	},
	{
		garage_id: 185,
		part_id: 77,
		quantity: 49,
	},
	{
		garage_id: 185,
		part_id: 78,
		quantity: 364,
	},
	{
		garage_id: 185,
		part_id: 79,
		quantity: 129,
	},
	{
		garage_id: 185,
		part_id: 80,
		quantity: 316,
	},
	{
		garage_id: 185,
		part_id: 81,
		quantity: 348,
	},
	{
		garage_id: 185,
		part_id: 82,
		quantity: 497,
	},
	{
		garage_id: 185,
		part_id: 83,
		quantity: 88,
	},
	{
		garage_id: 185,
		part_id: 84,
		quantity: 107,
	},
	{
		garage_id: 185,
		part_id: 85,
		quantity: 211,
	},
	{
		garage_id: 185,
		part_id: 86,
		quantity: 436,
	},
	{
		garage_id: 185,
		part_id: 87,
		quantity: 233,
	},
	{
		garage_id: 185,
		part_id: 88,
		quantity: 323,
	},
	{
		garage_id: 185,
		part_id: 89,
		quantity: 12,
	},
	{
		garage_id: 185,
		part_id: 90,
		quantity: 168,
	},
	{
		garage_id: 185,
		part_id: 91,
		quantity: 55,
	},
	{
		garage_id: 185,
		part_id: 92,
		quantity: 54,
	},
	{
		garage_id: 185,
		part_id: 93,
		quantity: 48,
	},
	{
		garage_id: 185,
		part_id: 94,
		quantity: 74,
	},
	{
		garage_id: 185,
		part_id: 95,
		quantity: 45,
	},
	{
		garage_id: 185,
		part_id: 96,
		quantity: 373,
	},
	{
		garage_id: 185,
		part_id: 97,
		quantity: 221,
	},
	{
		garage_id: 185,
		part_id: 98,
		quantity: 295,
	},
	{
		garage_id: 185,
		part_id: 99,
		quantity: 190,
	},
	{
		garage_id: 185,
		part_id: 100,
		quantity: 393,
	},
	{
		garage_id: 185,
		part_id: 101,
		quantity: 36,
	},
	{
		garage_id: 185,
		part_id: 102,
		quantity: 427,
	},
	{
		garage_id: 185,
		part_id: 103,
		quantity: 351,
	},
	{
		garage_id: 185,
		part_id: 104,
		quantity: 12,
	},
	{
		garage_id: 185,
		part_id: 105,
		quantity: 16,
	},
	{
		garage_id: 185,
		part_id: 106,
		quantity: 183,
	},
	{
		garage_id: 185,
		part_id: 107,
		quantity: 281,
	},
	{
		garage_id: 185,
		part_id: 108,
		quantity: 462,
	},
	{
		garage_id: 185,
		part_id: 109,
		quantity: 139,
	},
	{
		garage_id: 185,
		part_id: 110,
		quantity: 77,
	},
	{
		garage_id: 185,
		part_id: 111,
		quantity: 159,
	},
	{
		garage_id: 185,
		part_id: 112,
		quantity: 28,
	},
	{
		garage_id: 185,
		part_id: 113,
		quantity: 165,
	},
	{
		garage_id: 185,
		part_id: 114,
		quantity: 492,
	},
	{
		garage_id: 185,
		part_id: 115,
		quantity: 62,
	},
	{
		garage_id: 185,
		part_id: 116,
		quantity: 406,
	},
	{
		garage_id: 185,
		part_id: 117,
		quantity: 464,
	},
	{
		garage_id: 185,
		part_id: 118,
		quantity: 183,
	},
	{
		garage_id: 185,
		part_id: 119,
		quantity: 83,
	},
	{
		garage_id: 185,
		part_id: 120,
		quantity: 397,
	},
	{
		garage_id: 185,
		part_id: 121,
		quantity: 475,
	},
	{
		garage_id: 185,
		part_id: 122,
		quantity: 171,
	},
	{
		garage_id: 185,
		part_id: 123,
		quantity: 223,
	},
	{
		garage_id: 185,
		part_id: 124,
		quantity: 355,
	},
	{
		garage_id: 185,
		part_id: 125,
		quantity: 223,
	},
	{
		garage_id: 185,
		part_id: 126,
		quantity: 164,
	},
	{
		garage_id: 185,
		part_id: 127,
		quantity: 445,
	},
	{
		garage_id: 185,
		part_id: 128,
		quantity: 419,
	},
	{
		garage_id: 185,
		part_id: 129,
		quantity: 155,
	},
	{
		garage_id: 185,
		part_id: 130,
		quantity: 369,
	},
	{
		garage_id: 185,
		part_id: 131,
		quantity: 16,
	},
	{
		garage_id: 185,
		part_id: 132,
		quantity: 261,
	},
	{
		garage_id: 185,
		part_id: 133,
		quantity: 399,
	},
	{
		garage_id: 185,
		part_id: 134,
		quantity: 430,
	},
	{
		garage_id: 185,
		part_id: 135,
		quantity: 400,
	},
	{
		garage_id: 185,
		part_id: 136,
		quantity: 18,
	},
	{
		garage_id: 185,
		part_id: 137,
		quantity: 353,
	},
	{
		garage_id: 185,
		part_id: 138,
		quantity: 436,
	},
	{
		garage_id: 185,
		part_id: 139,
		quantity: 310,
	},
	{
		garage_id: 185,
		part_id: 140,
		quantity: 384,
	},
	{
		garage_id: 185,
		part_id: 141,
		quantity: 242,
	},
	{
		garage_id: 185,
		part_id: 142,
		quantity: 306,
	},
	{
		garage_id: 185,
		part_id: 143,
		quantity: 226,
	},
	{
		garage_id: 185,
		part_id: 144,
		quantity: 411,
	},
	{
		garage_id: 185,
		part_id: 145,
		quantity: 195,
	},
	{
		garage_id: 185,
		part_id: 146,
		quantity: 206,
	},
	{
		garage_id: 185,
		part_id: 147,
		quantity: 72,
	},
	{
		garage_id: 185,
		part_id: 148,
		quantity: 431,
	},
	{
		garage_id: 185,
		part_id: 149,
		quantity: 380,
	},
	{
		garage_id: 185,
		part_id: 150,
		quantity: 219,
	},
	{
		garage_id: 185,
		part_id: 151,
		quantity: 478,
	},
	{
		garage_id: 185,
		part_id: 152,
		quantity: 450,
	},
	{
		garage_id: 185,
		part_id: 153,
		quantity: 89,
	},
	{
		garage_id: 185,
		part_id: 154,
		quantity: 251,
	},
	{
		garage_id: 185,
		part_id: 155,
		quantity: 314,
	},
	{
		garage_id: 185,
		part_id: 156,
		quantity: 404,
	},
	{
		garage_id: 185,
		part_id: 157,
		quantity: 487,
	},
	{
		garage_id: 185,
		part_id: 158,
		quantity: 102,
	},
	{
		garage_id: 185,
		part_id: 159,
		quantity: 105,
	},
	{
		garage_id: 185,
		part_id: 160,
		quantity: 312,
	},
	{
		garage_id: 185,
		part_id: 161,
		quantity: 83,
	},
	{
		garage_id: 185,
		part_id: 162,
		quantity: 451,
	},
	{
		garage_id: 185,
		part_id: 163,
		quantity: 331,
	},
	{
		garage_id: 185,
		part_id: 164,
		quantity: 180,
	},
	{
		garage_id: 185,
		part_id: 165,
		quantity: 410,
	},
	{
		garage_id: 185,
		part_id: 166,
		quantity: 307,
	},
	{
		garage_id: 185,
		part_id: 167,
		quantity: 187,
	},
	{
		garage_id: 185,
		part_id: 168,
		quantity: 126,
	},
	{
		garage_id: 185,
		part_id: 169,
		quantity: 456,
	},
	{
		garage_id: 185,
		part_id: 170,
		quantity: 467,
	},
	{
		garage_id: 185,
		part_id: 171,
		quantity: 471,
	},
	{
		garage_id: 185,
		part_id: 172,
		quantity: 194,
	},
	{
		garage_id: 185,
		part_id: 173,
		quantity: 230,
	},
	{
		garage_id: 185,
		part_id: 174,
		quantity: 239,
	},
	{
		garage_id: 185,
		part_id: 175,
		quantity: 201,
	},
	{
		garage_id: 185,
		part_id: 176,
		quantity: 250,
	},
	{
		garage_id: 185,
		part_id: 177,
		quantity: 469,
	},
	{
		garage_id: 185,
		part_id: 178,
		quantity: 13,
	},
	{
		garage_id: 185,
		part_id: 179,
		quantity: 120,
	},
	{
		garage_id: 185,
		part_id: 180,
		quantity: 47,
	},
	{
		garage_id: 185,
		part_id: 181,
		quantity: 12,
	},
	{
		garage_id: 185,
		part_id: 182,
		quantity: 287,
	},
	{
		garage_id: 185,
		part_id: 183,
		quantity: 212,
	},
	{
		garage_id: 185,
		part_id: 184,
		quantity: 55,
	},
	{
		garage_id: 185,
		part_id: 185,
		quantity: 349,
	},
	{
		garage_id: 185,
		part_id: 186,
		quantity: 16,
	},
	{
		garage_id: 185,
		part_id: 187,
		quantity: 457,
	},
	{
		garage_id: 185,
		part_id: 188,
		quantity: 317,
	},
	{
		garage_id: 185,
		part_id: 189,
		quantity: 132,
	},
	{
		garage_id: 185,
		part_id: 190,
		quantity: 448,
	},
	{
		garage_id: 185,
		part_id: 191,
		quantity: 405,
	},
	{
		garage_id: 185,
		part_id: 192,
		quantity: 258,
	},
	{
		garage_id: 185,
		part_id: 193,
		quantity: 214,
	},
	{
		garage_id: 185,
		part_id: 194,
		quantity: 260,
	},
	{
		garage_id: 185,
		part_id: 195,
		quantity: 33,
	},
	{
		garage_id: 185,
		part_id: 196,
		quantity: 247,
	},
	{
		garage_id: 185,
		part_id: 197,
		quantity: 183,
	},
	{
		garage_id: 185,
		part_id: 198,
		quantity: 406,
	},
	{
		garage_id: 185,
		part_id: 199,
		quantity: 274,
	},
	{
		garage_id: 185,
		part_id: 200,
		quantity: 219,
	},
	{
		garage_id: 185,
		part_id: 201,
		quantity: 399,
	},
	{
		garage_id: 185,
		part_id: 202,
		quantity: 442,
	},
	{
		garage_id: 185,
		part_id: 203,
		quantity: 425,
	},
	{
		garage_id: 185,
		part_id: 204,
		quantity: 64,
	},
	{
		garage_id: 185,
		part_id: 205,
		quantity: 194,
	},
	{
		garage_id: 185,
		part_id: 206,
		quantity: 453,
	},
	{
		garage_id: 185,
		part_id: 207,
		quantity: 153,
	},
	{
		garage_id: 185,
		part_id: 208,
		quantity: 261,
	},
	{
		garage_id: 185,
		part_id: 209,
		quantity: 71,
	},
	{
		garage_id: 185,
		part_id: 210,
		quantity: 450,
	},
	{
		garage_id: 185,
		part_id: 211,
		quantity: 12,
	},
	{
		garage_id: 185,
		part_id: 212,
		quantity: 18,
	},
	{
		garage_id: 185,
		part_id: 213,
		quantity: 25,
	},
	{
		garage_id: 185,
		part_id: 214,
		quantity: 75,
	},
	{
		garage_id: 185,
		part_id: 215,
		quantity: 87,
	},
	{
		garage_id: 185,
		part_id: 216,
		quantity: 477,
	},
	{
		garage_id: 185,
		part_id: 217,
		quantity: 268,
	},
	{
		garage_id: 185,
		part_id: 218,
		quantity: 369,
	},
	{
		garage_id: 185,
		part_id: 219,
		quantity: 411,
	},
	{
		garage_id: 185,
		part_id: 220,
		quantity: 17,
	},
	{
		garage_id: 185,
		part_id: 221,
		quantity: 278,
	},
	{
		garage_id: 185,
		part_id: 222,
		quantity: 64,
	},
	{
		garage_id: 185,
		part_id: 223,
		quantity: 21,
	},
	{
		garage_id: 185,
		part_id: 224,
		quantity: 137,
	},
	{
		garage_id: 185,
		part_id: 225,
		quantity: 163,
	},
	{
		garage_id: 185,
		part_id: 226,
		quantity: 226,
	},
	{
		garage_id: 185,
		part_id: 227,
		quantity: 251,
	},
	{
		garage_id: 185,
		part_id: 228,
		quantity: 471,
	},
	{
		garage_id: 185,
		part_id: 229,
		quantity: 249,
	},
	{
		garage_id: 185,
		part_id: 230,
		quantity: 349,
	},
	{
		garage_id: 185,
		part_id: 231,
		quantity: 470,
	},
	{
		garage_id: 185,
		part_id: 232,
		quantity: 437,
	},
	{
		garage_id: 185,
		part_id: 233,
		quantity: 124,
	},
	{
		garage_id: 185,
		part_id: 234,
		quantity: 396,
	},
	{
		garage_id: 185,
		part_id: 235,
		quantity: 391,
	},
	{
		garage_id: 185,
		part_id: 236,
		quantity: 82,
	},
	{
		garage_id: 185,
		part_id: 237,
		quantity: 88,
	},
	{
		garage_id: 185,
		part_id: 238,
		quantity: 392,
	},
	{
		garage_id: 185,
		part_id: 239,
		quantity: 52,
	},
	{
		garage_id: 185,
		part_id: 240,
		quantity: 40,
	},
	{
		garage_id: 185,
		part_id: 241,
		quantity: 378,
	},
	{
		garage_id: 185,
		part_id: 242,
		quantity: 469,
	},
	{
		garage_id: 185,
		part_id: 243,
		quantity: 196,
	},
	{
		garage_id: 185,
		part_id: 244,
		quantity: 230,
	},
	{
		garage_id: 185,
		part_id: 245,
		quantity: 279,
	},
	{
		garage_id: 185,
		part_id: 246,
		quantity: 345,
	},
	{
		garage_id: 185,
		part_id: 247,
		quantity: 278,
	},
	{
		garage_id: 185,
		part_id: 248,
		quantity: 320,
	},
	{
		garage_id: 185,
		part_id: 249,
		quantity: 465,
	},
	{
		garage_id: 185,
		part_id: 250,
		quantity: 159,
	},
	{
		garage_id: 185,
		part_id: 251,
		quantity: 50,
	},
	{
		garage_id: 185,
		part_id: 252,
		quantity: 184,
	},
	{
		garage_id: 185,
		part_id: 253,
		quantity: 252,
	},
	{
		garage_id: 185,
		part_id: 254,
		quantity: 482,
	},
	{
		garage_id: 185,
		part_id: 255,
		quantity: 407,
	},
	{
		garage_id: 185,
		part_id: 256,
		quantity: 420,
	},
	{
		garage_id: 185,
		part_id: 257,
		quantity: 315,
	},
	{
		garage_id: 185,
		part_id: 258,
		quantity: 69,
	},
	{
		garage_id: 185,
		part_id: 259,
		quantity: 247,
	},
	{
		garage_id: 185,
		part_id: 260,
		quantity: 311,
	},
	{
		garage_id: 185,
		part_id: 261,
		quantity: 109,
	},
	{
		garage_id: 185,
		part_id: 262,
		quantity: 497,
	},
	{
		garage_id: 185,
		part_id: 263,
		quantity: 133,
	},
	{
		garage_id: 185,
		part_id: 264,
		quantity: 490,
	},
	{
		garage_id: 185,
		part_id: 265,
		quantity: 149,
	},
	{
		garage_id: 185,
		part_id: 266,
		quantity: 375,
	},
	{
		garage_id: 185,
		part_id: 267,
		quantity: 125,
	},
	{
		garage_id: 185,
		part_id: 268,
		quantity: 69,
	},
	{
		garage_id: 185,
		part_id: 269,
		quantity: 158,
	},
	{
		garage_id: 185,
		part_id: 270,
		quantity: 120,
	},
	{
		garage_id: 185,
		part_id: 271,
		quantity: 480,
	},
	{
		garage_id: 185,
		part_id: 272,
		quantity: 143,
	},
	{
		garage_id: 185,
		part_id: 273,
		quantity: 190,
	},
	{
		garage_id: 185,
		part_id: 274,
		quantity: 479,
	},
	{
		garage_id: 185,
		part_id: 275,
		quantity: 1,
	},
	{
		garage_id: 185,
		part_id: 276,
		quantity: 12,
	},
	{
		garage_id: 185,
		part_id: 277,
		quantity: 15,
	},
	{
		garage_id: 185,
		part_id: 278,
		quantity: 339,
	},
	{
		garage_id: 185,
		part_id: 279,
		quantity: 288,
	},
	{
		garage_id: 185,
		part_id: 280,
		quantity: 188,
	},
	{
		garage_id: 185,
		part_id: 281,
		quantity: 205,
	},
	{
		garage_id: 185,
		part_id: 282,
		quantity: 454,
	},
	{
		garage_id: 185,
		part_id: 283,
		quantity: 369,
	},
	{
		garage_id: 185,
		part_id: 284,
		quantity: 470,
	},
	{
		garage_id: 185,
		part_id: 285,
		quantity: 485,
	},
	{
		garage_id: 185,
		part_id: 286,
		quantity: 377,
	},
	{
		garage_id: 185,
		part_id: 287,
		quantity: 273,
	},
	{
		garage_id: 185,
		part_id: 288,
		quantity: 290,
	},
	{
		garage_id: 185,
		part_id: 289,
		quantity: 396,
	},
	{
		garage_id: 185,
		part_id: 290,
		quantity: 35,
	},
	{
		garage_id: 185,
		part_id: 291,
		quantity: 387,
	},
	{
		garage_id: 185,
		part_id: 292,
		quantity: 103,
	},
	{
		garage_id: 185,
		part_id: 293,
		quantity: 389,
	},
	{
		garage_id: 185,
		part_id: 294,
		quantity: 264,
	},
	{
		garage_id: 185,
		part_id: 295,
		quantity: 380,
	},
	{
		garage_id: 185,
		part_id: 296,
		quantity: 10,
	},
	{
		garage_id: 185,
		part_id: 297,
		quantity: 256,
	},
	{
		garage_id: 185,
		part_id: 298,
		quantity: 411,
	},
	{
		garage_id: 185,
		part_id: 299,
		quantity: 3,
	},
	{
		garage_id: 185,
		part_id: 300,
		quantity: 395,
	},
	{
		garage_id: 185,
		part_id: 301,
		quantity: 228,
	},
	{
		garage_id: 185,
		part_id: 302,
		quantity: 492,
	},
	{
		garage_id: 185,
		part_id: 303,
		quantity: 278,
	},
	{
		garage_id: 185,
		part_id: 304,
		quantity: 323,
	},
	{
		garage_id: 185,
		part_id: 305,
		quantity: 303,
	},
	{
		garage_id: 185,
		part_id: 306,
		quantity: 79,
	},
	{
		garage_id: 185,
		part_id: 307,
		quantity: 296,
	},
	{
		garage_id: 185,
		part_id: 308,
		quantity: 191,
	},
	{
		garage_id: 185,
		part_id: 309,
		quantity: 349,
	},
	{
		garage_id: 185,
		part_id: 310,
		quantity: 349,
	},
	{
		garage_id: 185,
		part_id: 311,
		quantity: 218,
	},
	{
		garage_id: 185,
		part_id: 312,
		quantity: 348,
	},
	{
		garage_id: 185,
		part_id: 313,
		quantity: 358,
	},
	{
		garage_id: 185,
		part_id: 314,
		quantity: 247,
	},
	{
		garage_id: 185,
		part_id: 315,
		quantity: 64,
	},
	{
		garage_id: 185,
		part_id: 316,
		quantity: 21,
	},
	{
		garage_id: 185,
		part_id: 317,
		quantity: 350,
	},
	{
		garage_id: 185,
		part_id: 318,
		quantity: 106,
	},
	{
		garage_id: 185,
		part_id: 319,
		quantity: 219,
	},
	{
		garage_id: 185,
		part_id: 320,
		quantity: 236,
	},
	{
		garage_id: 185,
		part_id: 321,
		quantity: 265,
	},
	{
		garage_id: 185,
		part_id: 322,
		quantity: 267,
	},
	{
		garage_id: 185,
		part_id: 323,
		quantity: 463,
	},
	{
		garage_id: 185,
		part_id: 324,
		quantity: 421,
	},
	{
		garage_id: 185,
		part_id: 325,
		quantity: 380,
	},
	{
		garage_id: 185,
		part_id: 326,
		quantity: 239,
	},
	{
		garage_id: 185,
		part_id: 327,
		quantity: 3,
	},
	{
		garage_id: 185,
		part_id: 328,
		quantity: 60,
	},
	{
		garage_id: 185,
		part_id: 329,
		quantity: 438,
	},
	{
		garage_id: 185,
		part_id: 330,
		quantity: 96,
	},
	{
		garage_id: 185,
		part_id: 331,
		quantity: 174,
	},
	{
		garage_id: 185,
		part_id: 332,
		quantity: 167,
	},
	{
		garage_id: 185,
		part_id: 333,
		quantity: 25,
	},
	{
		garage_id: 185,
		part_id: 334,
		quantity: 236,
	},
	{
		garage_id: 185,
		part_id: 335,
		quantity: 74,
	},
	{
		garage_id: 185,
		part_id: 336,
		quantity: 276,
	},
	{
		garage_id: 185,
		part_id: 337,
		quantity: 420,
	},
	{
		garage_id: 185,
		part_id: 338,
		quantity: 145,
	},
	{
		garage_id: 185,
		part_id: 339,
		quantity: 452,
	},
	{
		garage_id: 185,
		part_id: 340,
		quantity: 435,
	},
	{
		garage_id: 185,
		part_id: 341,
		quantity: 24,
	},
	{
		garage_id: 185,
		part_id: 342,
		quantity: 368,
	},
	{
		garage_id: 185,
		part_id: 343,
		quantity: 443,
	},
	{
		garage_id: 185,
		part_id: 344,
		quantity: 139,
	},
	{
		garage_id: 185,
		part_id: 345,
		quantity: 175,
	},
	{
		garage_id: 185,
		part_id: 346,
		quantity: 394,
	},
	{
		garage_id: 185,
		part_id: 347,
		quantity: 93,
	},
	{
		garage_id: 185,
		part_id: 348,
		quantity: 473,
	},
	{
		garage_id: 185,
		part_id: 349,
		quantity: 444,
	},
	{
		garage_id: 185,
		part_id: 350,
		quantity: 91,
	},
	{
		garage_id: 185,
		part_id: 351,
		quantity: 201,
	},
	{
		garage_id: 185,
		part_id: 352,
		quantity: 463,
	},
	{
		garage_id: 185,
		part_id: 353,
		quantity: 366,
	},
	{
		garage_id: 185,
		part_id: 354,
		quantity: 311,
	},
	{
		garage_id: 185,
		part_id: 355,
		quantity: 300,
	},
	{
		garage_id: 185,
		part_id: 356,
		quantity: 431,
	},
	{
		garage_id: 185,
		part_id: 357,
		quantity: 439,
	},
	{
		garage_id: 185,
		part_id: 358,
		quantity: 99,
	},
	{
		garage_id: 185,
		part_id: 359,
		quantity: 110,
	},
	{
		garage_id: 185,
		part_id: 360,
		quantity: 356,
	},
	{
		garage_id: 185,
		part_id: 361,
		quantity: 145,
	},
	{
		garage_id: 185,
		part_id: 362,
		quantity: 33,
	},
	{
		garage_id: 185,
		part_id: 363,
		quantity: 257,
	},
	{
		garage_id: 185,
		part_id: 364,
		quantity: 328,
	},
	{
		garage_id: 185,
		part_id: 365,
		quantity: 444,
	},
	{
		garage_id: 185,
		part_id: 366,
		quantity: 392,
	},
	{
		garage_id: 185,
		part_id: 367,
		quantity: 293,
	},
	{
		garage_id: 185,
		part_id: 368,
		quantity: 452,
	},
	{
		garage_id: 185,
		part_id: 369,
		quantity: 58,
	},
	{
		garage_id: 185,
		part_id: 370,
		quantity: 405,
	},
	{
		garage_id: 185,
		part_id: 371,
		quantity: 377,
	},
	{
		garage_id: 185,
		part_id: 372,
		quantity: 311,
	},
	{
		garage_id: 185,
		part_id: 373,
		quantity: 13,
	},
	{
		garage_id: 185,
		part_id: 374,
		quantity: 313,
	},
	{
		garage_id: 185,
		part_id: 375,
		quantity: 6,
	},
	{
		garage_id: 185,
		part_id: 376,
		quantity: 90,
	},
	{
		garage_id: 185,
		part_id: 377,
		quantity: 472,
	},
	{
		garage_id: 185,
		part_id: 378,
		quantity: 321,
	},
	{
		garage_id: 185,
		part_id: 379,
		quantity: 66,
	},
	{
		garage_id: 185,
		part_id: 380,
		quantity: 182,
	},
	{
		garage_id: 185,
		part_id: 381,
		quantity: 154,
	},
	{
		garage_id: 185,
		part_id: 382,
		quantity: 466,
	},
	{
		garage_id: 185,
		part_id: 383,
		quantity: 142,
	},
	{
		garage_id: 185,
		part_id: 384,
		quantity: 206,
	},
	{
		garage_id: 185,
		part_id: 385,
		quantity: 361,
	},
	{
		garage_id: 185,
		part_id: 386,
		quantity: 448,
	},
	{
		garage_id: 185,
		part_id: 387,
		quantity: 208,
	},
	{
		garage_id: 185,
		part_id: 388,
		quantity: 271,
	},
	{
		garage_id: 185,
		part_id: 389,
		quantity: 193,
	},
	{
		garage_id: 185,
		part_id: 390,
		quantity: 213,
	},
	{
		garage_id: 185,
		part_id: 391,
		quantity: 410,
	},
	{
		garage_id: 185,
		part_id: 392,
		quantity: 447,
	},
	{
		garage_id: 185,
		part_id: 393,
		quantity: 299,
	},
	{
		garage_id: 185,
		part_id: 394,
		quantity: 377,
	},
	{
		garage_id: 185,
		part_id: 395,
		quantity: 385,
	},
	{
		garage_id: 185,
		part_id: 396,
		quantity: 97,
	},
	{
		garage_id: 185,
		part_id: 397,
		quantity: 77,
	},
	{
		garage_id: 185,
		part_id: 398,
		quantity: 157,
	},
	{
		garage_id: 185,
		part_id: 399,
		quantity: 256,
	},
	{
		garage_id: 186,
		part_id: 0,
		quantity: 335,
	},
	{
		garage_id: 186,
		part_id: 1,
		quantity: 164,
	},
	{
		garage_id: 186,
		part_id: 2,
		quantity: 183,
	},
	{
		garage_id: 186,
		part_id: 3,
		quantity: 477,
	},
	{
		garage_id: 186,
		part_id: 4,
		quantity: 390,
	},
	{
		garage_id: 186,
		part_id: 5,
		quantity: 220,
	},
	{
		garage_id: 186,
		part_id: 6,
		quantity: 130,
	},
	{
		garage_id: 186,
		part_id: 7,
		quantity: 163,
	},
	{
		garage_id: 186,
		part_id: 8,
		quantity: 371,
	},
	{
		garage_id: 186,
		part_id: 9,
		quantity: 323,
	},
	{
		garage_id: 186,
		part_id: 10,
		quantity: 221,
	},
	{
		garage_id: 186,
		part_id: 11,
		quantity: 413,
	},
	{
		garage_id: 186,
		part_id: 12,
		quantity: 271,
	},
	{
		garage_id: 186,
		part_id: 13,
		quantity: 345,
	},
	{
		garage_id: 186,
		part_id: 14,
		quantity: 296,
	},
	{
		garage_id: 186,
		part_id: 15,
		quantity: 156,
	},
	{
		garage_id: 186,
		part_id: 16,
		quantity: 77,
	},
	{
		garage_id: 186,
		part_id: 17,
		quantity: 172,
	},
	{
		garage_id: 186,
		part_id: 18,
		quantity: 291,
	},
	{
		garage_id: 186,
		part_id: 19,
		quantity: 311,
	},
	{
		garage_id: 186,
		part_id: 20,
		quantity: 107,
	},
	{
		garage_id: 186,
		part_id: 21,
		quantity: 391,
	},
	{
		garage_id: 186,
		part_id: 22,
		quantity: 7,
	},
	{
		garage_id: 186,
		part_id: 23,
		quantity: 222,
	},
	{
		garage_id: 186,
		part_id: 24,
		quantity: 467,
	},
	{
		garage_id: 186,
		part_id: 25,
		quantity: 295,
	},
	{
		garage_id: 186,
		part_id: 26,
		quantity: 323,
	},
	{
		garage_id: 186,
		part_id: 27,
		quantity: 18,
	},
	{
		garage_id: 186,
		part_id: 28,
		quantity: 272,
	},
	{
		garage_id: 186,
		part_id: 29,
		quantity: 437,
	},
	{
		garage_id: 186,
		part_id: 30,
		quantity: 90,
	},
	{
		garage_id: 186,
		part_id: 31,
		quantity: 350,
	},
	{
		garage_id: 186,
		part_id: 32,
		quantity: 489,
	},
	{
		garage_id: 186,
		part_id: 33,
		quantity: 127,
	},
	{
		garage_id: 186,
		part_id: 34,
		quantity: 151,
	},
	{
		garage_id: 186,
		part_id: 35,
		quantity: 171,
	},
	{
		garage_id: 186,
		part_id: 36,
		quantity: 97,
	},
	{
		garage_id: 186,
		part_id: 37,
		quantity: 92,
	},
	{
		garage_id: 186,
		part_id: 38,
		quantity: 347,
	},
	{
		garage_id: 186,
		part_id: 39,
		quantity: 57,
	},
	{
		garage_id: 186,
		part_id: 40,
		quantity: 296,
	},
	{
		garage_id: 186,
		part_id: 41,
		quantity: 41,
	},
	{
		garage_id: 186,
		part_id: 42,
		quantity: 255,
	},
	{
		garage_id: 186,
		part_id: 43,
		quantity: 129,
	},
	{
		garage_id: 186,
		part_id: 44,
		quantity: 277,
	},
	{
		garage_id: 186,
		part_id: 45,
		quantity: 257,
	},
	{
		garage_id: 186,
		part_id: 46,
		quantity: 268,
	},
	{
		garage_id: 186,
		part_id: 47,
		quantity: 345,
	},
	{
		garage_id: 186,
		part_id: 48,
		quantity: 80,
	},
	{
		garage_id: 186,
		part_id: 49,
		quantity: 399,
	},
	{
		garage_id: 186,
		part_id: 50,
		quantity: 110,
	},
	{
		garage_id: 186,
		part_id: 51,
		quantity: 315,
	},
	{
		garage_id: 186,
		part_id: 52,
		quantity: 497,
	},
	{
		garage_id: 186,
		part_id: 53,
		quantity: 157,
	},
	{
		garage_id: 186,
		part_id: 54,
		quantity: 250,
	},
	{
		garage_id: 186,
		part_id: 55,
		quantity: 143,
	},
	{
		garage_id: 186,
		part_id: 56,
		quantity: 92,
	},
	{
		garage_id: 186,
		part_id: 57,
		quantity: 215,
	},
	{
		garage_id: 186,
		part_id: 58,
		quantity: 253,
	},
	{
		garage_id: 186,
		part_id: 59,
		quantity: 233,
	},
	{
		garage_id: 186,
		part_id: 60,
		quantity: 393,
	},
	{
		garage_id: 186,
		part_id: 61,
		quantity: 48,
	},
	{
		garage_id: 186,
		part_id: 62,
		quantity: 476,
	},
	{
		garage_id: 186,
		part_id: 63,
		quantity: 231,
	},
	{
		garage_id: 186,
		part_id: 64,
		quantity: 217,
	},
	{
		garage_id: 186,
		part_id: 65,
		quantity: 494,
	},
	{
		garage_id: 186,
		part_id: 66,
		quantity: 401,
	},
	{
		garage_id: 186,
		part_id: 67,
		quantity: 282,
	},
	{
		garage_id: 186,
		part_id: 68,
		quantity: 206,
	},
	{
		garage_id: 186,
		part_id: 69,
		quantity: 382,
	},
	{
		garage_id: 186,
		part_id: 70,
		quantity: 436,
	},
	{
		garage_id: 186,
		part_id: 71,
		quantity: 249,
	},
	{
		garage_id: 186,
		part_id: 72,
		quantity: 125,
	},
	{
		garage_id: 186,
		part_id: 73,
		quantity: 68,
	},
	{
		garage_id: 186,
		part_id: 74,
		quantity: 0,
	},
	{
		garage_id: 186,
		part_id: 75,
		quantity: 237,
	},
	{
		garage_id: 186,
		part_id: 76,
		quantity: 249,
	},
	{
		garage_id: 186,
		part_id: 77,
		quantity: 481,
	},
	{
		garage_id: 186,
		part_id: 78,
		quantity: 413,
	},
	{
		garage_id: 186,
		part_id: 79,
		quantity: 159,
	},
	{
		garage_id: 186,
		part_id: 80,
		quantity: 421,
	},
	{
		garage_id: 186,
		part_id: 81,
		quantity: 486,
	},
	{
		garage_id: 186,
		part_id: 82,
		quantity: 76,
	},
	{
		garage_id: 186,
		part_id: 83,
		quantity: 362,
	},
	{
		garage_id: 186,
		part_id: 84,
		quantity: 359,
	},
	{
		garage_id: 186,
		part_id: 85,
		quantity: 114,
	},
	{
		garage_id: 186,
		part_id: 86,
		quantity: 344,
	},
	{
		garage_id: 186,
		part_id: 87,
		quantity: 290,
	},
	{
		garage_id: 186,
		part_id: 88,
		quantity: 200,
	},
	{
		garage_id: 186,
		part_id: 89,
		quantity: 333,
	},
	{
		garage_id: 186,
		part_id: 90,
		quantity: 140,
	},
	{
		garage_id: 186,
		part_id: 91,
		quantity: 76,
	},
	{
		garage_id: 186,
		part_id: 92,
		quantity: 459,
	},
	{
		garage_id: 186,
		part_id: 93,
		quantity: 372,
	},
	{
		garage_id: 186,
		part_id: 94,
		quantity: 112,
	},
	{
		garage_id: 186,
		part_id: 95,
		quantity: 321,
	},
	{
		garage_id: 186,
		part_id: 96,
		quantity: 332,
	},
	{
		garage_id: 186,
		part_id: 97,
		quantity: 412,
	},
	{
		garage_id: 186,
		part_id: 98,
		quantity: 448,
	},
	{
		garage_id: 186,
		part_id: 99,
		quantity: 464,
	},
	{
		garage_id: 186,
		part_id: 100,
		quantity: 235,
	},
	{
		garage_id: 186,
		part_id: 101,
		quantity: 320,
	},
	{
		garage_id: 186,
		part_id: 102,
		quantity: 349,
	},
	{
		garage_id: 186,
		part_id: 103,
		quantity: 342,
	},
	{
		garage_id: 186,
		part_id: 104,
		quantity: 397,
	},
	{
		garage_id: 186,
		part_id: 105,
		quantity: 274,
	},
	{
		garage_id: 186,
		part_id: 106,
		quantity: 145,
	},
	{
		garage_id: 186,
		part_id: 107,
		quantity: 362,
	},
	{
		garage_id: 186,
		part_id: 108,
		quantity: 53,
	},
	{
		garage_id: 186,
		part_id: 109,
		quantity: 211,
	},
	{
		garage_id: 186,
		part_id: 110,
		quantity: 110,
	},
	{
		garage_id: 186,
		part_id: 111,
		quantity: 1,
	},
	{
		garage_id: 186,
		part_id: 112,
		quantity: 18,
	},
	{
		garage_id: 186,
		part_id: 113,
		quantity: 185,
	},
	{
		garage_id: 186,
		part_id: 114,
		quantity: 418,
	},
	{
		garage_id: 186,
		part_id: 115,
		quantity: 362,
	},
	{
		garage_id: 186,
		part_id: 116,
		quantity: 222,
	},
	{
		garage_id: 186,
		part_id: 117,
		quantity: 220,
	},
	{
		garage_id: 186,
		part_id: 118,
		quantity: 304,
	},
	{
		garage_id: 186,
		part_id: 119,
		quantity: 367,
	},
	{
		garage_id: 186,
		part_id: 120,
		quantity: 449,
	},
	{
		garage_id: 186,
		part_id: 121,
		quantity: 106,
	},
	{
		garage_id: 186,
		part_id: 122,
		quantity: 252,
	},
	{
		garage_id: 186,
		part_id: 123,
		quantity: 362,
	},
	{
		garage_id: 186,
		part_id: 124,
		quantity: 289,
	},
	{
		garage_id: 186,
		part_id: 125,
		quantity: 217,
	},
	{
		garage_id: 186,
		part_id: 126,
		quantity: 491,
	},
	{
		garage_id: 186,
		part_id: 127,
		quantity: 163,
	},
	{
		garage_id: 186,
		part_id: 128,
		quantity: 393,
	},
	{
		garage_id: 186,
		part_id: 129,
		quantity: 121,
	},
	{
		garage_id: 186,
		part_id: 130,
		quantity: 287,
	},
	{
		garage_id: 186,
		part_id: 131,
		quantity: 297,
	},
	{
		garage_id: 186,
		part_id: 132,
		quantity: 80,
	},
	{
		garage_id: 186,
		part_id: 133,
		quantity: 82,
	},
	{
		garage_id: 186,
		part_id: 134,
		quantity: 359,
	},
	{
		garage_id: 186,
		part_id: 135,
		quantity: 158,
	},
	{
		garage_id: 186,
		part_id: 136,
		quantity: 91,
	},
	{
		garage_id: 186,
		part_id: 137,
		quantity: 345,
	},
	{
		garage_id: 186,
		part_id: 138,
		quantity: 448,
	},
	{
		garage_id: 186,
		part_id: 139,
		quantity: 358,
	},
	{
		garage_id: 186,
		part_id: 140,
		quantity: 38,
	},
	{
		garage_id: 186,
		part_id: 141,
		quantity: 372,
	},
	{
		garage_id: 186,
		part_id: 142,
		quantity: 128,
	},
	{
		garage_id: 186,
		part_id: 143,
		quantity: 345,
	},
	{
		garage_id: 186,
		part_id: 144,
		quantity: 392,
	},
	{
		garage_id: 186,
		part_id: 145,
		quantity: 416,
	},
	{
		garage_id: 186,
		part_id: 146,
		quantity: 277,
	},
	{
		garage_id: 186,
		part_id: 147,
		quantity: 177,
	},
	{
		garage_id: 186,
		part_id: 148,
		quantity: 284,
	},
	{
		garage_id: 186,
		part_id: 149,
		quantity: 175,
	},
	{
		garage_id: 186,
		part_id: 150,
		quantity: 332,
	},
	{
		garage_id: 186,
		part_id: 151,
		quantity: 95,
	},
	{
		garage_id: 186,
		part_id: 152,
		quantity: 37,
	},
	{
		garage_id: 186,
		part_id: 153,
		quantity: 442,
	},
	{
		garage_id: 186,
		part_id: 154,
		quantity: 256,
	},
	{
		garage_id: 186,
		part_id: 155,
		quantity: 210,
	},
	{
		garage_id: 186,
		part_id: 156,
		quantity: 157,
	},
	{
		garage_id: 186,
		part_id: 157,
		quantity: 217,
	},
	{
		garage_id: 186,
		part_id: 158,
		quantity: 481,
	},
	{
		garage_id: 186,
		part_id: 159,
		quantity: 148,
	},
	{
		garage_id: 186,
		part_id: 160,
		quantity: 131,
	},
	{
		garage_id: 186,
		part_id: 161,
		quantity: 383,
	},
	{
		garage_id: 186,
		part_id: 162,
		quantity: 20,
	},
	{
		garage_id: 186,
		part_id: 163,
		quantity: 220,
	},
	{
		garage_id: 186,
		part_id: 164,
		quantity: 0,
	},
	{
		garage_id: 186,
		part_id: 165,
		quantity: 354,
	},
	{
		garage_id: 186,
		part_id: 166,
		quantity: 386,
	},
	{
		garage_id: 186,
		part_id: 167,
		quantity: 78,
	},
	{
		garage_id: 186,
		part_id: 168,
		quantity: 256,
	},
	{
		garage_id: 186,
		part_id: 169,
		quantity: 72,
	},
	{
		garage_id: 186,
		part_id: 170,
		quantity: 68,
	},
	{
		garage_id: 186,
		part_id: 171,
		quantity: 125,
	},
	{
		garage_id: 186,
		part_id: 172,
		quantity: 380,
	},
	{
		garage_id: 186,
		part_id: 173,
		quantity: 17,
	},
	{
		garage_id: 186,
		part_id: 174,
		quantity: 123,
	},
	{
		garage_id: 186,
		part_id: 175,
		quantity: 94,
	},
	{
		garage_id: 186,
		part_id: 176,
		quantity: 480,
	},
	{
		garage_id: 186,
		part_id: 177,
		quantity: 355,
	},
	{
		garage_id: 186,
		part_id: 178,
		quantity: 150,
	},
	{
		garage_id: 186,
		part_id: 179,
		quantity: 338,
	},
	{
		garage_id: 186,
		part_id: 180,
		quantity: 404,
	},
	{
		garage_id: 186,
		part_id: 181,
		quantity: 129,
	},
	{
		garage_id: 186,
		part_id: 182,
		quantity: 459,
	},
	{
		garage_id: 186,
		part_id: 183,
		quantity: 100,
	},
	{
		garage_id: 186,
		part_id: 184,
		quantity: 68,
	},
	{
		garage_id: 186,
		part_id: 185,
		quantity: 16,
	},
	{
		garage_id: 186,
		part_id: 186,
		quantity: 263,
	},
	{
		garage_id: 186,
		part_id: 187,
		quantity: 132,
	},
	{
		garage_id: 186,
		part_id: 188,
		quantity: 193,
	},
	{
		garage_id: 186,
		part_id: 189,
		quantity: 134,
	},
	{
		garage_id: 186,
		part_id: 190,
		quantity: 93,
	},
	{
		garage_id: 186,
		part_id: 191,
		quantity: 153,
	},
	{
		garage_id: 186,
		part_id: 192,
		quantity: 53,
	},
	{
		garage_id: 186,
		part_id: 193,
		quantity: 420,
	},
	{
		garage_id: 186,
		part_id: 194,
		quantity: 169,
	},
	{
		garage_id: 186,
		part_id: 195,
		quantity: 205,
	},
	{
		garage_id: 186,
		part_id: 196,
		quantity: 161,
	},
	{
		garage_id: 186,
		part_id: 197,
		quantity: 314,
	},
	{
		garage_id: 186,
		part_id: 198,
		quantity: 396,
	},
	{
		garage_id: 186,
		part_id: 199,
		quantity: 137,
	},
	{
		garage_id: 186,
		part_id: 200,
		quantity: 185,
	},
	{
		garage_id: 186,
		part_id: 201,
		quantity: 392,
	},
	{
		garage_id: 186,
		part_id: 202,
		quantity: 95,
	},
	{
		garage_id: 186,
		part_id: 203,
		quantity: 22,
	},
	{
		garage_id: 186,
		part_id: 204,
		quantity: 229,
	},
	{
		garage_id: 186,
		part_id: 205,
		quantity: 321,
	},
	{
		garage_id: 186,
		part_id: 206,
		quantity: 52,
	},
	{
		garage_id: 186,
		part_id: 207,
		quantity: 100,
	},
	{
		garage_id: 186,
		part_id: 208,
		quantity: 259,
	},
	{
		garage_id: 186,
		part_id: 209,
		quantity: 372,
	},
	{
		garage_id: 186,
		part_id: 210,
		quantity: 30,
	},
	{
		garage_id: 186,
		part_id: 211,
		quantity: 60,
	},
	{
		garage_id: 186,
		part_id: 212,
		quantity: 436,
	},
	{
		garage_id: 186,
		part_id: 213,
		quantity: 498,
	},
	{
		garage_id: 186,
		part_id: 214,
		quantity: 468,
	},
	{
		garage_id: 186,
		part_id: 215,
		quantity: 403,
	},
	{
		garage_id: 186,
		part_id: 216,
		quantity: 67,
	},
	{
		garage_id: 186,
		part_id: 217,
		quantity: 126,
	},
	{
		garage_id: 186,
		part_id: 218,
		quantity: 30,
	},
	{
		garage_id: 186,
		part_id: 219,
		quantity: 451,
	},
	{
		garage_id: 186,
		part_id: 220,
		quantity: 235,
	},
	{
		garage_id: 186,
		part_id: 221,
		quantity: 198,
	},
	{
		garage_id: 186,
		part_id: 222,
		quantity: 136,
	},
	{
		garage_id: 186,
		part_id: 223,
		quantity: 143,
	},
	{
		garage_id: 186,
		part_id: 224,
		quantity: 233,
	},
	{
		garage_id: 186,
		part_id: 225,
		quantity: 432,
	},
	{
		garage_id: 186,
		part_id: 226,
		quantity: 23,
	},
	{
		garage_id: 186,
		part_id: 227,
		quantity: 23,
	},
	{
		garage_id: 186,
		part_id: 228,
		quantity: 453,
	},
	{
		garage_id: 186,
		part_id: 229,
		quantity: 241,
	},
	{
		garage_id: 186,
		part_id: 230,
		quantity: 354,
	},
	{
		garage_id: 186,
		part_id: 231,
		quantity: 483,
	},
	{
		garage_id: 186,
		part_id: 232,
		quantity: 87,
	},
	{
		garage_id: 186,
		part_id: 233,
		quantity: 124,
	},
	{
		garage_id: 186,
		part_id: 234,
		quantity: 342,
	},
	{
		garage_id: 186,
		part_id: 235,
		quantity: 308,
	},
	{
		garage_id: 186,
		part_id: 236,
		quantity: 445,
	},
	{
		garage_id: 186,
		part_id: 237,
		quantity: 53,
	},
	{
		garage_id: 186,
		part_id: 238,
		quantity: 390,
	},
	{
		garage_id: 186,
		part_id: 239,
		quantity: 495,
	},
	{
		garage_id: 186,
		part_id: 240,
		quantity: 48,
	},
	{
		garage_id: 186,
		part_id: 241,
		quantity: 406,
	},
	{
		garage_id: 186,
		part_id: 242,
		quantity: 396,
	},
	{
		garage_id: 186,
		part_id: 243,
		quantity: 471,
	},
	{
		garage_id: 186,
		part_id: 244,
		quantity: 308,
	},
	{
		garage_id: 186,
		part_id: 245,
		quantity: 349,
	},
	{
		garage_id: 186,
		part_id: 246,
		quantity: 156,
	},
	{
		garage_id: 186,
		part_id: 247,
		quantity: 394,
	},
	{
		garage_id: 186,
		part_id: 248,
		quantity: 322,
	},
	{
		garage_id: 186,
		part_id: 249,
		quantity: 298,
	},
	{
		garage_id: 186,
		part_id: 250,
		quantity: 360,
	},
	{
		garage_id: 186,
		part_id: 251,
		quantity: 404,
	},
	{
		garage_id: 186,
		part_id: 252,
		quantity: 457,
	},
	{
		garage_id: 186,
		part_id: 253,
		quantity: 158,
	},
	{
		garage_id: 186,
		part_id: 254,
		quantity: 315,
	},
	{
		garage_id: 186,
		part_id: 255,
		quantity: 81,
	},
	{
		garage_id: 186,
		part_id: 256,
		quantity: 198,
	},
	{
		garage_id: 186,
		part_id: 257,
		quantity: 377,
	},
	{
		garage_id: 186,
		part_id: 258,
		quantity: 255,
	},
	{
		garage_id: 186,
		part_id: 259,
		quantity: 118,
	},
	{
		garage_id: 186,
		part_id: 260,
		quantity: 347,
	},
	{
		garage_id: 186,
		part_id: 261,
		quantity: 290,
	},
	{
		garage_id: 186,
		part_id: 262,
		quantity: 252,
	},
	{
		garage_id: 186,
		part_id: 263,
		quantity: 456,
	},
	{
		garage_id: 186,
		part_id: 264,
		quantity: 332,
	},
	{
		garage_id: 186,
		part_id: 265,
		quantity: 126,
	},
	{
		garage_id: 186,
		part_id: 266,
		quantity: 28,
	},
	{
		garage_id: 186,
		part_id: 267,
		quantity: 2,
	},
	{
		garage_id: 186,
		part_id: 268,
		quantity: 367,
	},
	{
		garage_id: 186,
		part_id: 269,
		quantity: 93,
	},
	{
		garage_id: 186,
		part_id: 270,
		quantity: 300,
	},
	{
		garage_id: 186,
		part_id: 271,
		quantity: 342,
	},
	{
		garage_id: 186,
		part_id: 272,
		quantity: 449,
	},
	{
		garage_id: 186,
		part_id: 273,
		quantity: 282,
	},
	{
		garage_id: 186,
		part_id: 274,
		quantity: 193,
	},
	{
		garage_id: 186,
		part_id: 275,
		quantity: 295,
	},
	{
		garage_id: 186,
		part_id: 276,
		quantity: 55,
	},
	{
		garage_id: 186,
		part_id: 277,
		quantity: 315,
	},
	{
		garage_id: 186,
		part_id: 278,
		quantity: 37,
	},
	{
		garage_id: 186,
		part_id: 279,
		quantity: 223,
	},
	{
		garage_id: 186,
		part_id: 280,
		quantity: 452,
	},
	{
		garage_id: 186,
		part_id: 281,
		quantity: 59,
	},
	{
		garage_id: 186,
		part_id: 282,
		quantity: 356,
	},
	{
		garage_id: 186,
		part_id: 283,
		quantity: 64,
	},
	{
		garage_id: 186,
		part_id: 284,
		quantity: 424,
	},
	{
		garage_id: 186,
		part_id: 285,
		quantity: 343,
	},
	{
		garage_id: 186,
		part_id: 286,
		quantity: 173,
	},
	{
		garage_id: 186,
		part_id: 287,
		quantity: 120,
	},
	{
		garage_id: 186,
		part_id: 288,
		quantity: 447,
	},
	{
		garage_id: 186,
		part_id: 289,
		quantity: 90,
	},
	{
		garage_id: 186,
		part_id: 290,
		quantity: 278,
	},
	{
		garage_id: 186,
		part_id: 291,
		quantity: 410,
	},
	{
		garage_id: 186,
		part_id: 292,
		quantity: 324,
	},
	{
		garage_id: 186,
		part_id: 293,
		quantity: 216,
	},
	{
		garage_id: 186,
		part_id: 294,
		quantity: 162,
	},
	{
		garage_id: 186,
		part_id: 295,
		quantity: 478,
	},
	{
		garage_id: 186,
		part_id: 296,
		quantity: 415,
	},
	{
		garage_id: 186,
		part_id: 297,
		quantity: 58,
	},
	{
		garage_id: 186,
		part_id: 298,
		quantity: 81,
	},
	{
		garage_id: 186,
		part_id: 299,
		quantity: 334,
	},
	{
		garage_id: 186,
		part_id: 300,
		quantity: 400,
	},
	{
		garage_id: 186,
		part_id: 301,
		quantity: 390,
	},
	{
		garage_id: 186,
		part_id: 302,
		quantity: 101,
	},
	{
		garage_id: 186,
		part_id: 303,
		quantity: 401,
	},
	{
		garage_id: 186,
		part_id: 304,
		quantity: 268,
	},
	{
		garage_id: 186,
		part_id: 305,
		quantity: 216,
	},
	{
		garage_id: 186,
		part_id: 306,
		quantity: 421,
	},
	{
		garage_id: 186,
		part_id: 307,
		quantity: 288,
	},
	{
		garage_id: 186,
		part_id: 308,
		quantity: 24,
	},
	{
		garage_id: 186,
		part_id: 309,
		quantity: 44,
	},
	{
		garage_id: 186,
		part_id: 310,
		quantity: 124,
	},
	{
		garage_id: 186,
		part_id: 311,
		quantity: 318,
	},
	{
		garage_id: 186,
		part_id: 312,
		quantity: 22,
	},
	{
		garage_id: 186,
		part_id: 313,
		quantity: 43,
	},
	{
		garage_id: 186,
		part_id: 314,
		quantity: 295,
	},
	{
		garage_id: 186,
		part_id: 315,
		quantity: 283,
	},
	{
		garage_id: 186,
		part_id: 316,
		quantity: 434,
	},
	{
		garage_id: 186,
		part_id: 317,
		quantity: 335,
	},
	{
		garage_id: 186,
		part_id: 318,
		quantity: 247,
	},
	{
		garage_id: 186,
		part_id: 319,
		quantity: 110,
	},
	{
		garage_id: 186,
		part_id: 320,
		quantity: 480,
	},
	{
		garage_id: 186,
		part_id: 321,
		quantity: 279,
	},
	{
		garage_id: 186,
		part_id: 322,
		quantity: 246,
	},
	{
		garage_id: 186,
		part_id: 323,
		quantity: 177,
	},
	{
		garage_id: 186,
		part_id: 324,
		quantity: 66,
	},
	{
		garage_id: 186,
		part_id: 325,
		quantity: 142,
	},
	{
		garage_id: 186,
		part_id: 326,
		quantity: 115,
	},
	{
		garage_id: 186,
		part_id: 327,
		quantity: 151,
	},
	{
		garage_id: 186,
		part_id: 328,
		quantity: 308,
	},
	{
		garage_id: 186,
		part_id: 329,
		quantity: 465,
	},
	{
		garage_id: 186,
		part_id: 330,
		quantity: 345,
	},
	{
		garage_id: 186,
		part_id: 331,
		quantity: 25,
	},
	{
		garage_id: 186,
		part_id: 332,
		quantity: 324,
	},
	{
		garage_id: 186,
		part_id: 333,
		quantity: 275,
	},
	{
		garage_id: 186,
		part_id: 334,
		quantity: 249,
	},
	{
		garage_id: 186,
		part_id: 335,
		quantity: 267,
	},
	{
		garage_id: 186,
		part_id: 336,
		quantity: 137,
	},
	{
		garage_id: 186,
		part_id: 337,
		quantity: 429,
	},
	{
		garage_id: 186,
		part_id: 338,
		quantity: 181,
	},
	{
		garage_id: 186,
		part_id: 339,
		quantity: 206,
	},
	{
		garage_id: 186,
		part_id: 340,
		quantity: 63,
	},
	{
		garage_id: 186,
		part_id: 341,
		quantity: 111,
	},
	{
		garage_id: 186,
		part_id: 342,
		quantity: 386,
	},
	{
		garage_id: 186,
		part_id: 343,
		quantity: 168,
	},
	{
		garage_id: 186,
		part_id: 344,
		quantity: 167,
	},
	{
		garage_id: 186,
		part_id: 345,
		quantity: 266,
	},
	{
		garage_id: 186,
		part_id: 346,
		quantity: 22,
	},
	{
		garage_id: 186,
		part_id: 347,
		quantity: 62,
	},
	{
		garage_id: 186,
		part_id: 348,
		quantity: 363,
	},
	{
		garage_id: 186,
		part_id: 349,
		quantity: 326,
	},
	{
		garage_id: 186,
		part_id: 350,
		quantity: 93,
	},
	{
		garage_id: 186,
		part_id: 351,
		quantity: 286,
	},
	{
		garage_id: 186,
		part_id: 352,
		quantity: 15,
	},
	{
		garage_id: 186,
		part_id: 353,
		quantity: 151,
	},
	{
		garage_id: 186,
		part_id: 354,
		quantity: 456,
	},
	{
		garage_id: 186,
		part_id: 355,
		quantity: 125,
	},
	{
		garage_id: 186,
		part_id: 356,
		quantity: 64,
	},
	{
		garage_id: 186,
		part_id: 357,
		quantity: 227,
	},
	{
		garage_id: 186,
		part_id: 358,
		quantity: 11,
	},
	{
		garage_id: 186,
		part_id: 359,
		quantity: 194,
	},
	{
		garage_id: 186,
		part_id: 360,
		quantity: 480,
	},
	{
		garage_id: 186,
		part_id: 361,
		quantity: 356,
	},
	{
		garage_id: 186,
		part_id: 362,
		quantity: 142,
	},
	{
		garage_id: 186,
		part_id: 363,
		quantity: 375,
	},
	{
		garage_id: 186,
		part_id: 364,
		quantity: 500,
	},
	{
		garage_id: 186,
		part_id: 365,
		quantity: 431,
	},
	{
		garage_id: 186,
		part_id: 366,
		quantity: 219,
	},
	{
		garage_id: 186,
		part_id: 367,
		quantity: 353,
	},
	{
		garage_id: 186,
		part_id: 368,
		quantity: 337,
	},
	{
		garage_id: 186,
		part_id: 369,
		quantity: 372,
	},
	{
		garage_id: 186,
		part_id: 370,
		quantity: 301,
	},
	{
		garage_id: 186,
		part_id: 371,
		quantity: 131,
	},
	{
		garage_id: 186,
		part_id: 372,
		quantity: 125,
	},
	{
		garage_id: 186,
		part_id: 373,
		quantity: 438,
	},
	{
		garage_id: 186,
		part_id: 374,
		quantity: 0,
	},
	{
		garage_id: 186,
		part_id: 375,
		quantity: 123,
	},
	{
		garage_id: 186,
		part_id: 376,
		quantity: 306,
	},
	{
		garage_id: 186,
		part_id: 377,
		quantity: 420,
	},
	{
		garage_id: 186,
		part_id: 378,
		quantity: 386,
	},
	{
		garage_id: 186,
		part_id: 379,
		quantity: 43,
	},
	{
		garage_id: 186,
		part_id: 380,
		quantity: 296,
	},
	{
		garage_id: 186,
		part_id: 381,
		quantity: 209,
	},
	{
		garage_id: 186,
		part_id: 382,
		quantity: 9,
	},
	{
		garage_id: 186,
		part_id: 383,
		quantity: 341,
	},
	{
		garage_id: 186,
		part_id: 384,
		quantity: 98,
	},
	{
		garage_id: 186,
		part_id: 385,
		quantity: 30,
	},
	{
		garage_id: 186,
		part_id: 386,
		quantity: 81,
	},
	{
		garage_id: 186,
		part_id: 387,
		quantity: 144,
	},
	{
		garage_id: 186,
		part_id: 388,
		quantity: 344,
	},
	{
		garage_id: 186,
		part_id: 389,
		quantity: 229,
	},
	{
		garage_id: 186,
		part_id: 390,
		quantity: 338,
	},
	{
		garage_id: 186,
		part_id: 391,
		quantity: 435,
	},
	{
		garage_id: 186,
		part_id: 392,
		quantity: 88,
	},
	{
		garage_id: 186,
		part_id: 393,
		quantity: 465,
	},
	{
		garage_id: 186,
		part_id: 394,
		quantity: 475,
	},
	{
		garage_id: 186,
		part_id: 395,
		quantity: 62,
	},
	{
		garage_id: 186,
		part_id: 396,
		quantity: 154,
	},
	{
		garage_id: 186,
		part_id: 397,
		quantity: 187,
	},
	{
		garage_id: 186,
		part_id: 398,
		quantity: 7,
	},
	{
		garage_id: 186,
		part_id: 399,
		quantity: 333,
	},
	{
		garage_id: 187,
		part_id: 0,
		quantity: 356,
	},
	{
		garage_id: 187,
		part_id: 1,
		quantity: 52,
	},
	{
		garage_id: 187,
		part_id: 2,
		quantity: 137,
	},
	{
		garage_id: 187,
		part_id: 3,
		quantity: 15,
	},
	{
		garage_id: 187,
		part_id: 4,
		quantity: 462,
	},
	{
		garage_id: 187,
		part_id: 5,
		quantity: 197,
	},
	{
		garage_id: 187,
		part_id: 6,
		quantity: 179,
	},
	{
		garage_id: 187,
		part_id: 7,
		quantity: 18,
	},
	{
		garage_id: 187,
		part_id: 8,
		quantity: 10,
	},
	{
		garage_id: 187,
		part_id: 9,
		quantity: 33,
	},
	{
		garage_id: 187,
		part_id: 10,
		quantity: 231,
	},
	{
		garage_id: 187,
		part_id: 11,
		quantity: 411,
	},
	{
		garage_id: 187,
		part_id: 12,
		quantity: 242,
	},
	{
		garage_id: 187,
		part_id: 13,
		quantity: 153,
	},
	{
		garage_id: 187,
		part_id: 14,
		quantity: 255,
	},
	{
		garage_id: 187,
		part_id: 15,
		quantity: 100,
	},
	{
		garage_id: 187,
		part_id: 16,
		quantity: 229,
	},
	{
		garage_id: 187,
		part_id: 17,
		quantity: 96,
	},
	{
		garage_id: 187,
		part_id: 18,
		quantity: 70,
	},
	{
		garage_id: 187,
		part_id: 19,
		quantity: 231,
	},
	{
		garage_id: 187,
		part_id: 20,
		quantity: 266,
	},
	{
		garage_id: 187,
		part_id: 21,
		quantity: 338,
	},
	{
		garage_id: 187,
		part_id: 22,
		quantity: 448,
	},
	{
		garage_id: 187,
		part_id: 23,
		quantity: 76,
	},
	{
		garage_id: 187,
		part_id: 24,
		quantity: 122,
	},
	{
		garage_id: 187,
		part_id: 25,
		quantity: 421,
	},
	{
		garage_id: 187,
		part_id: 26,
		quantity: 72,
	},
	{
		garage_id: 187,
		part_id: 27,
		quantity: 221,
	},
	{
		garage_id: 187,
		part_id: 28,
		quantity: 316,
	},
	{
		garage_id: 187,
		part_id: 29,
		quantity: 245,
	},
	{
		garage_id: 187,
		part_id: 30,
		quantity: 316,
	},
	{
		garage_id: 187,
		part_id: 31,
		quantity: 430,
	},
	{
		garage_id: 187,
		part_id: 32,
		quantity: 200,
	},
	{
		garage_id: 187,
		part_id: 33,
		quantity: 105,
	},
	{
		garage_id: 187,
		part_id: 34,
		quantity: 124,
	},
	{
		garage_id: 187,
		part_id: 35,
		quantity: 12,
	},
	{
		garage_id: 187,
		part_id: 36,
		quantity: 109,
	},
	{
		garage_id: 187,
		part_id: 37,
		quantity: 192,
	},
	{
		garage_id: 187,
		part_id: 38,
		quantity: 274,
	},
	{
		garage_id: 187,
		part_id: 39,
		quantity: 98,
	},
	{
		garage_id: 187,
		part_id: 40,
		quantity: 444,
	},
	{
		garage_id: 187,
		part_id: 41,
		quantity: 172,
	},
	{
		garage_id: 187,
		part_id: 42,
		quantity: 55,
	},
	{
		garage_id: 187,
		part_id: 43,
		quantity: 441,
	},
	{
		garage_id: 187,
		part_id: 44,
		quantity: 3,
	},
	{
		garage_id: 187,
		part_id: 45,
		quantity: 179,
	},
	{
		garage_id: 187,
		part_id: 46,
		quantity: 30,
	},
	{
		garage_id: 187,
		part_id: 47,
		quantity: 179,
	},
	{
		garage_id: 187,
		part_id: 48,
		quantity: 134,
	},
	{
		garage_id: 187,
		part_id: 49,
		quantity: 275,
	},
	{
		garage_id: 187,
		part_id: 50,
		quantity: 59,
	},
	{
		garage_id: 187,
		part_id: 51,
		quantity: 437,
	},
	{
		garage_id: 187,
		part_id: 52,
		quantity: 490,
	},
	{
		garage_id: 187,
		part_id: 53,
		quantity: 438,
	},
	{
		garage_id: 187,
		part_id: 54,
		quantity: 12,
	},
	{
		garage_id: 187,
		part_id: 55,
		quantity: 401,
	},
	{
		garage_id: 187,
		part_id: 56,
		quantity: 399,
	},
	{
		garage_id: 187,
		part_id: 57,
		quantity: 162,
	},
	{
		garage_id: 187,
		part_id: 58,
		quantity: 155,
	},
	{
		garage_id: 187,
		part_id: 59,
		quantity: 287,
	},
	{
		garage_id: 187,
		part_id: 60,
		quantity: 70,
	},
	{
		garage_id: 187,
		part_id: 61,
		quantity: 153,
	},
	{
		garage_id: 187,
		part_id: 62,
		quantity: 219,
	},
	{
		garage_id: 187,
		part_id: 63,
		quantity: 310,
	},
	{
		garage_id: 187,
		part_id: 64,
		quantity: 499,
	},
	{
		garage_id: 187,
		part_id: 65,
		quantity: 477,
	},
	{
		garage_id: 187,
		part_id: 66,
		quantity: 322,
	},
	{
		garage_id: 187,
		part_id: 67,
		quantity: 246,
	},
	{
		garage_id: 187,
		part_id: 68,
		quantity: 131,
	},
	{
		garage_id: 187,
		part_id: 69,
		quantity: 49,
	},
	{
		garage_id: 187,
		part_id: 70,
		quantity: 270,
	},
	{
		garage_id: 187,
		part_id: 71,
		quantity: 41,
	},
	{
		garage_id: 187,
		part_id: 72,
		quantity: 495,
	},
	{
		garage_id: 187,
		part_id: 73,
		quantity: 226,
	},
	{
		garage_id: 187,
		part_id: 74,
		quantity: 264,
	},
	{
		garage_id: 187,
		part_id: 75,
		quantity: 43,
	},
	{
		garage_id: 187,
		part_id: 76,
		quantity: 26,
	},
	{
		garage_id: 187,
		part_id: 77,
		quantity: 195,
	},
	{
		garage_id: 187,
		part_id: 78,
		quantity: 315,
	},
	{
		garage_id: 187,
		part_id: 79,
		quantity: 277,
	},
	{
		garage_id: 187,
		part_id: 80,
		quantity: 274,
	},
	{
		garage_id: 187,
		part_id: 81,
		quantity: 0,
	},
	{
		garage_id: 187,
		part_id: 82,
		quantity: 492,
	},
	{
		garage_id: 187,
		part_id: 83,
		quantity: 434,
	},
	{
		garage_id: 187,
		part_id: 84,
		quantity: 224,
	},
	{
		garage_id: 187,
		part_id: 85,
		quantity: 217,
	},
	{
		garage_id: 187,
		part_id: 86,
		quantity: 41,
	},
	{
		garage_id: 187,
		part_id: 87,
		quantity: 23,
	},
	{
		garage_id: 187,
		part_id: 88,
		quantity: 96,
	},
	{
		garage_id: 187,
		part_id: 89,
		quantity: 325,
	},
	{
		garage_id: 187,
		part_id: 90,
		quantity: 64,
	},
	{
		garage_id: 187,
		part_id: 91,
		quantity: 480,
	},
	{
		garage_id: 187,
		part_id: 92,
		quantity: 463,
	},
	{
		garage_id: 187,
		part_id: 93,
		quantity: 491,
	},
	{
		garage_id: 187,
		part_id: 94,
		quantity: 299,
	},
	{
		garage_id: 187,
		part_id: 95,
		quantity: 401,
	},
	{
		garage_id: 187,
		part_id: 96,
		quantity: 127,
	},
	{
		garage_id: 187,
		part_id: 97,
		quantity: 363,
	},
	{
		garage_id: 187,
		part_id: 98,
		quantity: 186,
	},
	{
		garage_id: 187,
		part_id: 99,
		quantity: 126,
	},
	{
		garage_id: 187,
		part_id: 100,
		quantity: 351,
	},
	{
		garage_id: 187,
		part_id: 101,
		quantity: 154,
	},
	{
		garage_id: 187,
		part_id: 102,
		quantity: 410,
	},
	{
		garage_id: 187,
		part_id: 103,
		quantity: 234,
	},
	{
		garage_id: 187,
		part_id: 104,
		quantity: 70,
	},
	{
		garage_id: 187,
		part_id: 105,
		quantity: 175,
	},
	{
		garage_id: 187,
		part_id: 106,
		quantity: 141,
	},
	{
		garage_id: 187,
		part_id: 107,
		quantity: 86,
	},
	{
		garage_id: 187,
		part_id: 108,
		quantity: 255,
	},
	{
		garage_id: 187,
		part_id: 109,
		quantity: 34,
	},
	{
		garage_id: 187,
		part_id: 110,
		quantity: 397,
	},
	{
		garage_id: 187,
		part_id: 111,
		quantity: 128,
	},
	{
		garage_id: 187,
		part_id: 112,
		quantity: 166,
	},
	{
		garage_id: 187,
		part_id: 113,
		quantity: 222,
	},
	{
		garage_id: 187,
		part_id: 114,
		quantity: 153,
	},
	{
		garage_id: 187,
		part_id: 115,
		quantity: 221,
	},
	{
		garage_id: 187,
		part_id: 116,
		quantity: 494,
	},
	{
		garage_id: 187,
		part_id: 117,
		quantity: 339,
	},
	{
		garage_id: 187,
		part_id: 118,
		quantity: 281,
	},
	{
		garage_id: 187,
		part_id: 119,
		quantity: 410,
	},
	{
		garage_id: 187,
		part_id: 120,
		quantity: 465,
	},
	{
		garage_id: 187,
		part_id: 121,
		quantity: 355,
	},
	{
		garage_id: 187,
		part_id: 122,
		quantity: 61,
	},
	{
		garage_id: 187,
		part_id: 123,
		quantity: 139,
	},
	{
		garage_id: 187,
		part_id: 124,
		quantity: 230,
	},
	{
		garage_id: 187,
		part_id: 125,
		quantity: 64,
	},
	{
		garage_id: 187,
		part_id: 126,
		quantity: 136,
	},
	{
		garage_id: 187,
		part_id: 127,
		quantity: 282,
	},
	{
		garage_id: 187,
		part_id: 128,
		quantity: 352,
	},
	{
		garage_id: 187,
		part_id: 129,
		quantity: 253,
	},
	{
		garage_id: 187,
		part_id: 130,
		quantity: 234,
	},
	{
		garage_id: 187,
		part_id: 131,
		quantity: 239,
	},
	{
		garage_id: 187,
		part_id: 132,
		quantity: 227,
	},
	{
		garage_id: 187,
		part_id: 133,
		quantity: 335,
	},
	{
		garage_id: 187,
		part_id: 134,
		quantity: 419,
	},
	{
		garage_id: 187,
		part_id: 135,
		quantity: 150,
	},
	{
		garage_id: 187,
		part_id: 136,
		quantity: 388,
	},
	{
		garage_id: 187,
		part_id: 137,
		quantity: 104,
	},
	{
		garage_id: 187,
		part_id: 138,
		quantity: 232,
	},
	{
		garage_id: 187,
		part_id: 139,
		quantity: 92,
	},
	{
		garage_id: 187,
		part_id: 140,
		quantity: 399,
	},
	{
		garage_id: 187,
		part_id: 141,
		quantity: 361,
	},
	{
		garage_id: 187,
		part_id: 142,
		quantity: 452,
	},
	{
		garage_id: 187,
		part_id: 143,
		quantity: 111,
	},
	{
		garage_id: 187,
		part_id: 144,
		quantity: 294,
	},
	{
		garage_id: 187,
		part_id: 145,
		quantity: 104,
	},
	{
		garage_id: 187,
		part_id: 146,
		quantity: 253,
	},
	{
		garage_id: 187,
		part_id: 147,
		quantity: 95,
	},
	{
		garage_id: 187,
		part_id: 148,
		quantity: 257,
	},
	{
		garage_id: 187,
		part_id: 149,
		quantity: 403,
	},
	{
		garage_id: 187,
		part_id: 150,
		quantity: 250,
	},
	{
		garage_id: 187,
		part_id: 151,
		quantity: 245,
	},
	{
		garage_id: 187,
		part_id: 152,
		quantity: 373,
	},
	{
		garage_id: 187,
		part_id: 153,
		quantity: 316,
	},
	{
		garage_id: 187,
		part_id: 154,
		quantity: 263,
	},
	{
		garage_id: 187,
		part_id: 155,
		quantity: 482,
	},
	{
		garage_id: 187,
		part_id: 156,
		quantity: 281,
	},
	{
		garage_id: 187,
		part_id: 157,
		quantity: 173,
	},
	{
		garage_id: 187,
		part_id: 158,
		quantity: 224,
	},
	{
		garage_id: 187,
		part_id: 159,
		quantity: 159,
	},
	{
		garage_id: 187,
		part_id: 160,
		quantity: 487,
	},
	{
		garage_id: 187,
		part_id: 161,
		quantity: 396,
	},
	{
		garage_id: 187,
		part_id: 162,
		quantity: 28,
	},
	{
		garage_id: 187,
		part_id: 163,
		quantity: 445,
	},
	{
		garage_id: 187,
		part_id: 164,
		quantity: 387,
	},
	{
		garage_id: 187,
		part_id: 165,
		quantity: 256,
	},
	{
		garage_id: 187,
		part_id: 166,
		quantity: 200,
	},
	{
		garage_id: 187,
		part_id: 167,
		quantity: 247,
	},
	{
		garage_id: 187,
		part_id: 168,
		quantity: 331,
	},
	{
		garage_id: 187,
		part_id: 169,
		quantity: 365,
	},
	{
		garage_id: 187,
		part_id: 170,
		quantity: 53,
	},
	{
		garage_id: 187,
		part_id: 171,
		quantity: 324,
	},
	{
		garage_id: 187,
		part_id: 172,
		quantity: 442,
	},
	{
		garage_id: 187,
		part_id: 173,
		quantity: 119,
	},
	{
		garage_id: 187,
		part_id: 174,
		quantity: 233,
	},
	{
		garage_id: 187,
		part_id: 175,
		quantity: 7,
	},
	{
		garage_id: 187,
		part_id: 176,
		quantity: 481,
	},
	{
		garage_id: 187,
		part_id: 177,
		quantity: 445,
	},
	{
		garage_id: 187,
		part_id: 178,
		quantity: 297,
	},
	{
		garage_id: 187,
		part_id: 179,
		quantity: 56,
	},
	{
		garage_id: 187,
		part_id: 180,
		quantity: 411,
	},
	{
		garage_id: 187,
		part_id: 181,
		quantity: 10,
	},
	{
		garage_id: 187,
		part_id: 182,
		quantity: 7,
	},
	{
		garage_id: 187,
		part_id: 183,
		quantity: 493,
	},
	{
		garage_id: 187,
		part_id: 184,
		quantity: 147,
	},
	{
		garage_id: 187,
		part_id: 185,
		quantity: 309,
	},
	{
		garage_id: 187,
		part_id: 186,
		quantity: 190,
	},
	{
		garage_id: 187,
		part_id: 187,
		quantity: 172,
	},
	{
		garage_id: 187,
		part_id: 188,
		quantity: 438,
	},
	{
		garage_id: 187,
		part_id: 189,
		quantity: 474,
	},
	{
		garage_id: 187,
		part_id: 190,
		quantity: 314,
	},
	{
		garage_id: 187,
		part_id: 191,
		quantity: 280,
	},
	{
		garage_id: 187,
		part_id: 192,
		quantity: 169,
	},
	{
		garage_id: 187,
		part_id: 193,
		quantity: 127,
	},
	{
		garage_id: 187,
		part_id: 194,
		quantity: 455,
	},
	{
		garage_id: 187,
		part_id: 195,
		quantity: 62,
	},
	{
		garage_id: 187,
		part_id: 196,
		quantity: 100,
	},
	{
		garage_id: 187,
		part_id: 197,
		quantity: 38,
	},
	{
		garage_id: 187,
		part_id: 198,
		quantity: 462,
	},
	{
		garage_id: 187,
		part_id: 199,
		quantity: 73,
	},
	{
		garage_id: 187,
		part_id: 200,
		quantity: 203,
	},
	{
		garage_id: 187,
		part_id: 201,
		quantity: 89,
	},
	{
		garage_id: 187,
		part_id: 202,
		quantity: 298,
	},
	{
		garage_id: 187,
		part_id: 203,
		quantity: 432,
	},
	{
		garage_id: 187,
		part_id: 204,
		quantity: 499,
	},
	{
		garage_id: 187,
		part_id: 205,
		quantity: 207,
	},
	{
		garage_id: 187,
		part_id: 206,
		quantity: 269,
	},
	{
		garage_id: 187,
		part_id: 207,
		quantity: 390,
	},
	{
		garage_id: 187,
		part_id: 208,
		quantity: 14,
	},
	{
		garage_id: 187,
		part_id: 209,
		quantity: 192,
	},
	{
		garage_id: 187,
		part_id: 210,
		quantity: 87,
	},
	{
		garage_id: 187,
		part_id: 211,
		quantity: 159,
	},
	{
		garage_id: 187,
		part_id: 212,
		quantity: 25,
	},
	{
		garage_id: 187,
		part_id: 213,
		quantity: 10,
	},
	{
		garage_id: 187,
		part_id: 214,
		quantity: 424,
	},
	{
		garage_id: 187,
		part_id: 215,
		quantity: 123,
	},
	{
		garage_id: 187,
		part_id: 216,
		quantity: 442,
	},
	{
		garage_id: 187,
		part_id: 217,
		quantity: 470,
	},
	{
		garage_id: 187,
		part_id: 218,
		quantity: 315,
	},
	{
		garage_id: 187,
		part_id: 219,
		quantity: 241,
	},
	{
		garage_id: 187,
		part_id: 220,
		quantity: 28,
	},
	{
		garage_id: 187,
		part_id: 221,
		quantity: 491,
	},
	{
		garage_id: 187,
		part_id: 222,
		quantity: 489,
	},
	{
		garage_id: 187,
		part_id: 223,
		quantity: 47,
	},
	{
		garage_id: 187,
		part_id: 224,
		quantity: 80,
	},
	{
		garage_id: 187,
		part_id: 225,
		quantity: 236,
	},
	{
		garage_id: 187,
		part_id: 226,
		quantity: 303,
	},
	{
		garage_id: 187,
		part_id: 227,
		quantity: 78,
	},
	{
		garage_id: 187,
		part_id: 228,
		quantity: 322,
	},
	{
		garage_id: 187,
		part_id: 229,
		quantity: 226,
	},
	{
		garage_id: 187,
		part_id: 230,
		quantity: 421,
	},
	{
		garage_id: 187,
		part_id: 231,
		quantity: 177,
	},
	{
		garage_id: 187,
		part_id: 232,
		quantity: 141,
	},
	{
		garage_id: 187,
		part_id: 233,
		quantity: 85,
	},
	{
		garage_id: 187,
		part_id: 234,
		quantity: 366,
	},
	{
		garage_id: 187,
		part_id: 235,
		quantity: 277,
	},
	{
		garage_id: 187,
		part_id: 236,
		quantity: 336,
	},
	{
		garage_id: 187,
		part_id: 237,
		quantity: 267,
	},
	{
		garage_id: 187,
		part_id: 238,
		quantity: 367,
	},
	{
		garage_id: 187,
		part_id: 239,
		quantity: 442,
	},
	{
		garage_id: 187,
		part_id: 240,
		quantity: 129,
	},
	{
		garage_id: 187,
		part_id: 241,
		quantity: 360,
	},
	{
		garage_id: 187,
		part_id: 242,
		quantity: 408,
	},
	{
		garage_id: 187,
		part_id: 243,
		quantity: 245,
	},
	{
		garage_id: 187,
		part_id: 244,
		quantity: 72,
	},
	{
		garage_id: 187,
		part_id: 245,
		quantity: 244,
	},
	{
		garage_id: 187,
		part_id: 246,
		quantity: 208,
	},
	{
		garage_id: 187,
		part_id: 247,
		quantity: 125,
	},
	{
		garage_id: 187,
		part_id: 248,
		quantity: 60,
	},
	{
		garage_id: 187,
		part_id: 249,
		quantity: 253,
	},
	{
		garage_id: 187,
		part_id: 250,
		quantity: 122,
	},
	{
		garage_id: 187,
		part_id: 251,
		quantity: 388,
	},
	{
		garage_id: 187,
		part_id: 252,
		quantity: 391,
	},
	{
		garage_id: 187,
		part_id: 253,
		quantity: 224,
	},
	{
		garage_id: 187,
		part_id: 254,
		quantity: 18,
	},
	{
		garage_id: 187,
		part_id: 255,
		quantity: 140,
	},
	{
		garage_id: 187,
		part_id: 256,
		quantity: 341,
	},
	{
		garage_id: 187,
		part_id: 257,
		quantity: 472,
	},
	{
		garage_id: 187,
		part_id: 258,
		quantity: 176,
	},
	{
		garage_id: 187,
		part_id: 259,
		quantity: 389,
	},
	{
		garage_id: 187,
		part_id: 260,
		quantity: 226,
	},
	{
		garage_id: 187,
		part_id: 261,
		quantity: 399,
	},
	{
		garage_id: 187,
		part_id: 262,
		quantity: 278,
	},
	{
		garage_id: 187,
		part_id: 263,
		quantity: 267,
	},
	{
		garage_id: 187,
		part_id: 264,
		quantity: 115,
	},
	{
		garage_id: 187,
		part_id: 265,
		quantity: 169,
	},
	{
		garage_id: 187,
		part_id: 266,
		quantity: 147,
	},
	{
		garage_id: 187,
		part_id: 267,
		quantity: 22,
	},
	{
		garage_id: 187,
		part_id: 268,
		quantity: 491,
	},
	{
		garage_id: 187,
		part_id: 269,
		quantity: 187,
	},
	{
		garage_id: 187,
		part_id: 270,
		quantity: 257,
	},
	{
		garage_id: 187,
		part_id: 271,
		quantity: 392,
	},
	{
		garage_id: 187,
		part_id: 272,
		quantity: 224,
	},
	{
		garage_id: 187,
		part_id: 273,
		quantity: 317,
	},
	{
		garage_id: 187,
		part_id: 274,
		quantity: 397,
	},
	{
		garage_id: 187,
		part_id: 275,
		quantity: 390,
	},
	{
		garage_id: 187,
		part_id: 276,
		quantity: 336,
	},
	{
		garage_id: 187,
		part_id: 277,
		quantity: 14,
	},
	{
		garage_id: 187,
		part_id: 278,
		quantity: 114,
	},
	{
		garage_id: 187,
		part_id: 279,
		quantity: 181,
	},
	{
		garage_id: 187,
		part_id: 280,
		quantity: 1,
	},
	{
		garage_id: 187,
		part_id: 281,
		quantity: 136,
	},
	{
		garage_id: 187,
		part_id: 282,
		quantity: 175,
	},
	{
		garage_id: 187,
		part_id: 283,
		quantity: 27,
	},
	{
		garage_id: 187,
		part_id: 284,
		quantity: 494,
	},
	{
		garage_id: 187,
		part_id: 285,
		quantity: 387,
	},
	{
		garage_id: 187,
		part_id: 286,
		quantity: 198,
	},
	{
		garage_id: 187,
		part_id: 287,
		quantity: 176,
	},
	{
		garage_id: 187,
		part_id: 288,
		quantity: 183,
	},
	{
		garage_id: 187,
		part_id: 289,
		quantity: 322,
	},
	{
		garage_id: 187,
		part_id: 290,
		quantity: 425,
	},
	{
		garage_id: 187,
		part_id: 291,
		quantity: 381,
	},
	{
		garage_id: 187,
		part_id: 292,
		quantity: 46,
	},
	{
		garage_id: 187,
		part_id: 293,
		quantity: 9,
	},
	{
		garage_id: 187,
		part_id: 294,
		quantity: 425,
	},
	{
		garage_id: 187,
		part_id: 295,
		quantity: 234,
	},
	{
		garage_id: 187,
		part_id: 296,
		quantity: 6,
	},
	{
		garage_id: 187,
		part_id: 297,
		quantity: 460,
	},
	{
		garage_id: 187,
		part_id: 298,
		quantity: 294,
	},
	{
		garage_id: 187,
		part_id: 299,
		quantity: 41,
	},
	{
		garage_id: 187,
		part_id: 300,
		quantity: 20,
	},
	{
		garage_id: 187,
		part_id: 301,
		quantity: 484,
	},
	{
		garage_id: 187,
		part_id: 302,
		quantity: 290,
	},
	{
		garage_id: 187,
		part_id: 303,
		quantity: 124,
	},
	{
		garage_id: 187,
		part_id: 304,
		quantity: 268,
	},
	{
		garage_id: 187,
		part_id: 305,
		quantity: 173,
	},
	{
		garage_id: 187,
		part_id: 306,
		quantity: 399,
	},
	{
		garage_id: 187,
		part_id: 307,
		quantity: 100,
	},
	{
		garage_id: 187,
		part_id: 308,
		quantity: 91,
	},
	{
		garage_id: 187,
		part_id: 309,
		quantity: 481,
	},
	{
		garage_id: 187,
		part_id: 310,
		quantity: 43,
	},
	{
		garage_id: 187,
		part_id: 311,
		quantity: 330,
	},
	{
		garage_id: 187,
		part_id: 312,
		quantity: 450,
	},
	{
		garage_id: 187,
		part_id: 313,
		quantity: 147,
	},
	{
		garage_id: 187,
		part_id: 314,
		quantity: 1,
	},
	{
		garage_id: 187,
		part_id: 315,
		quantity: 320,
	},
	{
		garage_id: 187,
		part_id: 316,
		quantity: 239,
	},
	{
		garage_id: 187,
		part_id: 317,
		quantity: 242,
	},
	{
		garage_id: 187,
		part_id: 318,
		quantity: 211,
	},
	{
		garage_id: 187,
		part_id: 319,
		quantity: 141,
	},
	{
		garage_id: 187,
		part_id: 320,
		quantity: 341,
	},
	{
		garage_id: 187,
		part_id: 321,
		quantity: 306,
	},
	{
		garage_id: 187,
		part_id: 322,
		quantity: 468,
	},
	{
		garage_id: 187,
		part_id: 323,
		quantity: 244,
	},
	{
		garage_id: 187,
		part_id: 324,
		quantity: 232,
	},
	{
		garage_id: 187,
		part_id: 325,
		quantity: 265,
	},
	{
		garage_id: 187,
		part_id: 326,
		quantity: 295,
	},
	{
		garage_id: 187,
		part_id: 327,
		quantity: 182,
	},
	{
		garage_id: 187,
		part_id: 328,
		quantity: 99,
	},
	{
		garage_id: 187,
		part_id: 329,
		quantity: 437,
	},
	{
		garage_id: 187,
		part_id: 330,
		quantity: 223,
	},
	{
		garage_id: 187,
		part_id: 331,
		quantity: 16,
	},
	{
		garage_id: 187,
		part_id: 332,
		quantity: 80,
	},
	{
		garage_id: 187,
		part_id: 333,
		quantity: 474,
	},
	{
		garage_id: 187,
		part_id: 334,
		quantity: 370,
	},
	{
		garage_id: 187,
		part_id: 335,
		quantity: 181,
	},
	{
		garage_id: 187,
		part_id: 336,
		quantity: 196,
	},
	{
		garage_id: 187,
		part_id: 337,
		quantity: 237,
	},
	{
		garage_id: 187,
		part_id: 338,
		quantity: 151,
	},
	{
		garage_id: 187,
		part_id: 339,
		quantity: 359,
	},
	{
		garage_id: 187,
		part_id: 340,
		quantity: 280,
	},
	{
		garage_id: 187,
		part_id: 341,
		quantity: 194,
	},
	{
		garage_id: 187,
		part_id: 342,
		quantity: 425,
	},
	{
		garage_id: 187,
		part_id: 343,
		quantity: 183,
	},
	{
		garage_id: 187,
		part_id: 344,
		quantity: 424,
	},
	{
		garage_id: 187,
		part_id: 345,
		quantity: 174,
	},
	{
		garage_id: 187,
		part_id: 346,
		quantity: 316,
	},
	{
		garage_id: 187,
		part_id: 347,
		quantity: 405,
	},
	{
		garage_id: 187,
		part_id: 348,
		quantity: 451,
	},
	{
		garage_id: 187,
		part_id: 349,
		quantity: 258,
	},
	{
		garage_id: 187,
		part_id: 350,
		quantity: 352,
	},
	{
		garage_id: 187,
		part_id: 351,
		quantity: 190,
	},
	{
		garage_id: 187,
		part_id: 352,
		quantity: 132,
	},
	{
		garage_id: 187,
		part_id: 353,
		quantity: 23,
	},
	{
		garage_id: 187,
		part_id: 354,
		quantity: 155,
	},
	{
		garage_id: 187,
		part_id: 355,
		quantity: 413,
	},
	{
		garage_id: 187,
		part_id: 356,
		quantity: 316,
	},
	{
		garage_id: 187,
		part_id: 357,
		quantity: 359,
	},
	{
		garage_id: 187,
		part_id: 358,
		quantity: 207,
	},
	{
		garage_id: 187,
		part_id: 359,
		quantity: 384,
	},
	{
		garage_id: 187,
		part_id: 360,
		quantity: 27,
	},
	{
		garage_id: 187,
		part_id: 361,
		quantity: 226,
	},
	{
		garage_id: 187,
		part_id: 362,
		quantity: 323,
	},
	{
		garage_id: 187,
		part_id: 363,
		quantity: 400,
	},
	{
		garage_id: 187,
		part_id: 364,
		quantity: 466,
	},
	{
		garage_id: 187,
		part_id: 365,
		quantity: 61,
	},
	{
		garage_id: 187,
		part_id: 366,
		quantity: 150,
	},
	{
		garage_id: 187,
		part_id: 367,
		quantity: 59,
	},
	{
		garage_id: 187,
		part_id: 368,
		quantity: 28,
	},
	{
		garage_id: 187,
		part_id: 369,
		quantity: 367,
	},
	{
		garage_id: 187,
		part_id: 370,
		quantity: 406,
	},
	{
		garage_id: 187,
		part_id: 371,
		quantity: 336,
	},
	{
		garage_id: 187,
		part_id: 372,
		quantity: 431,
	},
	{
		garage_id: 187,
		part_id: 373,
		quantity: 324,
	},
	{
		garage_id: 187,
		part_id: 374,
		quantity: 88,
	},
	{
		garage_id: 187,
		part_id: 375,
		quantity: 4,
	},
	{
		garage_id: 187,
		part_id: 376,
		quantity: 98,
	},
	{
		garage_id: 187,
		part_id: 377,
		quantity: 345,
	},
	{
		garage_id: 187,
		part_id: 378,
		quantity: 281,
	},
	{
		garage_id: 187,
		part_id: 379,
		quantity: 224,
	},
	{
		garage_id: 187,
		part_id: 380,
		quantity: 353,
	},
	{
		garage_id: 187,
		part_id: 381,
		quantity: 143,
	},
	{
		garage_id: 187,
		part_id: 382,
		quantity: 482,
	},
	{
		garage_id: 187,
		part_id: 383,
		quantity: 330,
	},
	{
		garage_id: 187,
		part_id: 384,
		quantity: 190,
	},
	{
		garage_id: 187,
		part_id: 385,
		quantity: 313,
	},
	{
		garage_id: 187,
		part_id: 386,
		quantity: 118,
	},
	{
		garage_id: 187,
		part_id: 387,
		quantity: 30,
	},
	{
		garage_id: 187,
		part_id: 388,
		quantity: 274,
	},
	{
		garage_id: 187,
		part_id: 389,
		quantity: 472,
	},
	{
		garage_id: 187,
		part_id: 390,
		quantity: 172,
	},
	{
		garage_id: 187,
		part_id: 391,
		quantity: 416,
	},
	{
		garage_id: 187,
		part_id: 392,
		quantity: 8,
	},
	{
		garage_id: 187,
		part_id: 393,
		quantity: 448,
	},
	{
		garage_id: 187,
		part_id: 394,
		quantity: 109,
	},
	{
		garage_id: 187,
		part_id: 395,
		quantity: 461,
	},
	{
		garage_id: 187,
		part_id: 396,
		quantity: 143,
	},
	{
		garage_id: 187,
		part_id: 397,
		quantity: 477,
	},
	{
		garage_id: 187,
		part_id: 398,
		quantity: 362,
	},
	{
		garage_id: 187,
		part_id: 399,
		quantity: 400,
	},
	{
		garage_id: 188,
		part_id: 0,
		quantity: 218,
	},
	{
		garage_id: 188,
		part_id: 1,
		quantity: 496,
	},
	{
		garage_id: 188,
		part_id: 2,
		quantity: 457,
	},
	{
		garage_id: 188,
		part_id: 3,
		quantity: 421,
	},
	{
		garage_id: 188,
		part_id: 4,
		quantity: 34,
	},
	{
		garage_id: 188,
		part_id: 5,
		quantity: 34,
	},
	{
		garage_id: 188,
		part_id: 6,
		quantity: 341,
	},
	{
		garage_id: 188,
		part_id: 7,
		quantity: 347,
	},
	{
		garage_id: 188,
		part_id: 8,
		quantity: 351,
	},
	{
		garage_id: 188,
		part_id: 9,
		quantity: 319,
	},
	{
		garage_id: 188,
		part_id: 10,
		quantity: 330,
	},
	{
		garage_id: 188,
		part_id: 11,
		quantity: 201,
	},
	{
		garage_id: 188,
		part_id: 12,
		quantity: 298,
	},
	{
		garage_id: 188,
		part_id: 13,
		quantity: 450,
	},
	{
		garage_id: 188,
		part_id: 14,
		quantity: 176,
	},
	{
		garage_id: 188,
		part_id: 15,
		quantity: 97,
	},
	{
		garage_id: 188,
		part_id: 16,
		quantity: 344,
	},
	{
		garage_id: 188,
		part_id: 17,
		quantity: 343,
	},
	{
		garage_id: 188,
		part_id: 18,
		quantity: 303,
	},
	{
		garage_id: 188,
		part_id: 19,
		quantity: 350,
	},
	{
		garage_id: 188,
		part_id: 20,
		quantity: 259,
	},
	{
		garage_id: 188,
		part_id: 21,
		quantity: 497,
	},
	{
		garage_id: 188,
		part_id: 22,
		quantity: 398,
	},
	{
		garage_id: 188,
		part_id: 23,
		quantity: 181,
	},
	{
		garage_id: 188,
		part_id: 24,
		quantity: 393,
	},
	{
		garage_id: 188,
		part_id: 25,
		quantity: 121,
	},
	{
		garage_id: 188,
		part_id: 26,
		quantity: 379,
	},
	{
		garage_id: 188,
		part_id: 27,
		quantity: 139,
	},
	{
		garage_id: 188,
		part_id: 28,
		quantity: 465,
	},
	{
		garage_id: 188,
		part_id: 29,
		quantity: 345,
	},
	{
		garage_id: 188,
		part_id: 30,
		quantity: 247,
	},
	{
		garage_id: 188,
		part_id: 31,
		quantity: 26,
	},
	{
		garage_id: 188,
		part_id: 32,
		quantity: 280,
	},
	{
		garage_id: 188,
		part_id: 33,
		quantity: 301,
	},
	{
		garage_id: 188,
		part_id: 34,
		quantity: 424,
	},
	{
		garage_id: 188,
		part_id: 35,
		quantity: 236,
	},
	{
		garage_id: 188,
		part_id: 36,
		quantity: 332,
	},
	{
		garage_id: 188,
		part_id: 37,
		quantity: 94,
	},
	{
		garage_id: 188,
		part_id: 38,
		quantity: 10,
	},
	{
		garage_id: 188,
		part_id: 39,
		quantity: 27,
	},
	{
		garage_id: 188,
		part_id: 40,
		quantity: 484,
	},
	{
		garage_id: 188,
		part_id: 41,
		quantity: 442,
	},
	{
		garage_id: 188,
		part_id: 42,
		quantity: 224,
	},
	{
		garage_id: 188,
		part_id: 43,
		quantity: 225,
	},
	{
		garage_id: 188,
		part_id: 44,
		quantity: 98,
	},
	{
		garage_id: 188,
		part_id: 45,
		quantity: 13,
	},
	{
		garage_id: 188,
		part_id: 46,
		quantity: 405,
	},
	{
		garage_id: 188,
		part_id: 47,
		quantity: 284,
	},
	{
		garage_id: 188,
		part_id: 48,
		quantity: 98,
	},
	{
		garage_id: 188,
		part_id: 49,
		quantity: 147,
	},
	{
		garage_id: 188,
		part_id: 50,
		quantity: 353,
	},
	{
		garage_id: 188,
		part_id: 51,
		quantity: 478,
	},
	{
		garage_id: 188,
		part_id: 52,
		quantity: 361,
	},
	{
		garage_id: 188,
		part_id: 53,
		quantity: 9,
	},
	{
		garage_id: 188,
		part_id: 54,
		quantity: 206,
	},
	{
		garage_id: 188,
		part_id: 55,
		quantity: 391,
	},
	{
		garage_id: 188,
		part_id: 56,
		quantity: 345,
	},
	{
		garage_id: 188,
		part_id: 57,
		quantity: 279,
	},
	{
		garage_id: 188,
		part_id: 58,
		quantity: 135,
	},
	{
		garage_id: 188,
		part_id: 59,
		quantity: 453,
	},
	{
		garage_id: 188,
		part_id: 60,
		quantity: 174,
	},
	{
		garage_id: 188,
		part_id: 61,
		quantity: 30,
	},
	{
		garage_id: 188,
		part_id: 62,
		quantity: 467,
	},
	{
		garage_id: 188,
		part_id: 63,
		quantity: 71,
	},
	{
		garage_id: 188,
		part_id: 64,
		quantity: 232,
	},
	{
		garage_id: 188,
		part_id: 65,
		quantity: 42,
	},
	{
		garage_id: 188,
		part_id: 66,
		quantity: 490,
	},
	{
		garage_id: 188,
		part_id: 67,
		quantity: 64,
	},
	{
		garage_id: 188,
		part_id: 68,
		quantity: 281,
	},
	{
		garage_id: 188,
		part_id: 69,
		quantity: 422,
	},
	{
		garage_id: 188,
		part_id: 70,
		quantity: 185,
	},
	{
		garage_id: 188,
		part_id: 71,
		quantity: 92,
	},
	{
		garage_id: 188,
		part_id: 72,
		quantity: 314,
	},
	{
		garage_id: 188,
		part_id: 73,
		quantity: 444,
	},
	{
		garage_id: 188,
		part_id: 74,
		quantity: 382,
	},
	{
		garage_id: 188,
		part_id: 75,
		quantity: 220,
	},
	{
		garage_id: 188,
		part_id: 76,
		quantity: 37,
	},
	{
		garage_id: 188,
		part_id: 77,
		quantity: 137,
	},
	{
		garage_id: 188,
		part_id: 78,
		quantity: 130,
	},
	{
		garage_id: 188,
		part_id: 79,
		quantity: 24,
	},
	{
		garage_id: 188,
		part_id: 80,
		quantity: 168,
	},
	{
		garage_id: 188,
		part_id: 81,
		quantity: 445,
	},
	{
		garage_id: 188,
		part_id: 82,
		quantity: 281,
	},
	{
		garage_id: 188,
		part_id: 83,
		quantity: 92,
	},
	{
		garage_id: 188,
		part_id: 84,
		quantity: 30,
	},
	{
		garage_id: 188,
		part_id: 85,
		quantity: 178,
	},
	{
		garage_id: 188,
		part_id: 86,
		quantity: 234,
	},
	{
		garage_id: 188,
		part_id: 87,
		quantity: 279,
	},
	{
		garage_id: 188,
		part_id: 88,
		quantity: 244,
	},
	{
		garage_id: 188,
		part_id: 89,
		quantity: 363,
	},
	{
		garage_id: 188,
		part_id: 90,
		quantity: 320,
	},
	{
		garage_id: 188,
		part_id: 91,
		quantity: 338,
	},
	{
		garage_id: 188,
		part_id: 92,
		quantity: 488,
	},
	{
		garage_id: 188,
		part_id: 93,
		quantity: 198,
	},
	{
		garage_id: 188,
		part_id: 94,
		quantity: 307,
	},
	{
		garage_id: 188,
		part_id: 95,
		quantity: 487,
	},
	{
		garage_id: 188,
		part_id: 96,
		quantity: 81,
	},
	{
		garage_id: 188,
		part_id: 97,
		quantity: 301,
	},
	{
		garage_id: 188,
		part_id: 98,
		quantity: 128,
	},
	{
		garage_id: 188,
		part_id: 99,
		quantity: 92,
	},
	{
		garage_id: 188,
		part_id: 100,
		quantity: 271,
	},
	{
		garage_id: 188,
		part_id: 101,
		quantity: 334,
	},
	{
		garage_id: 188,
		part_id: 102,
		quantity: 260,
	},
	{
		garage_id: 188,
		part_id: 103,
		quantity: 388,
	},
	{
		garage_id: 188,
		part_id: 104,
		quantity: 364,
	},
	{
		garage_id: 188,
		part_id: 105,
		quantity: 340,
	},
	{
		garage_id: 188,
		part_id: 106,
		quantity: 88,
	},
	{
		garage_id: 188,
		part_id: 107,
		quantity: 348,
	},
	{
		garage_id: 188,
		part_id: 108,
		quantity: 24,
	},
	{
		garage_id: 188,
		part_id: 109,
		quantity: 100,
	},
	{
		garage_id: 188,
		part_id: 110,
		quantity: 146,
	},
	{
		garage_id: 188,
		part_id: 111,
		quantity: 59,
	},
	{
		garage_id: 188,
		part_id: 112,
		quantity: 447,
	},
	{
		garage_id: 188,
		part_id: 113,
		quantity: 74,
	},
	{
		garage_id: 188,
		part_id: 114,
		quantity: 195,
	},
	{
		garage_id: 188,
		part_id: 115,
		quantity: 297,
	},
	{
		garage_id: 188,
		part_id: 116,
		quantity: 171,
	},
	{
		garage_id: 188,
		part_id: 117,
		quantity: 27,
	},
	{
		garage_id: 188,
		part_id: 118,
		quantity: 164,
	},
	{
		garage_id: 188,
		part_id: 119,
		quantity: 283,
	},
	{
		garage_id: 188,
		part_id: 120,
		quantity: 498,
	},
	{
		garage_id: 188,
		part_id: 121,
		quantity: 74,
	},
	{
		garage_id: 188,
		part_id: 122,
		quantity: 164,
	},
	{
		garage_id: 188,
		part_id: 123,
		quantity: 488,
	},
	{
		garage_id: 188,
		part_id: 124,
		quantity: 127,
	},
	{
		garage_id: 188,
		part_id: 125,
		quantity: 324,
	},
	{
		garage_id: 188,
		part_id: 126,
		quantity: 212,
	},
	{
		garage_id: 188,
		part_id: 127,
		quantity: 379,
	},
	{
		garage_id: 188,
		part_id: 128,
		quantity: 115,
	},
	{
		garage_id: 188,
		part_id: 129,
		quantity: 18,
	},
	{
		garage_id: 188,
		part_id: 130,
		quantity: 198,
	},
	{
		garage_id: 188,
		part_id: 131,
		quantity: 247,
	},
	{
		garage_id: 188,
		part_id: 132,
		quantity: 251,
	},
	{
		garage_id: 188,
		part_id: 133,
		quantity: 314,
	},
	{
		garage_id: 188,
		part_id: 134,
		quantity: 275,
	},
	{
		garage_id: 188,
		part_id: 135,
		quantity: 120,
	},
	{
		garage_id: 188,
		part_id: 136,
		quantity: 91,
	},
	{
		garage_id: 188,
		part_id: 137,
		quantity: 50,
	},
	{
		garage_id: 188,
		part_id: 138,
		quantity: 338,
	},
	{
		garage_id: 188,
		part_id: 139,
		quantity: 260,
	},
	{
		garage_id: 188,
		part_id: 140,
		quantity: 478,
	},
	{
		garage_id: 188,
		part_id: 141,
		quantity: 400,
	},
	{
		garage_id: 188,
		part_id: 142,
		quantity: 354,
	},
	{
		garage_id: 188,
		part_id: 143,
		quantity: 254,
	},
	{
		garage_id: 188,
		part_id: 144,
		quantity: 137,
	},
	{
		garage_id: 188,
		part_id: 145,
		quantity: 326,
	},
	{
		garage_id: 188,
		part_id: 146,
		quantity: 417,
	},
	{
		garage_id: 188,
		part_id: 147,
		quantity: 311,
	},
	{
		garage_id: 188,
		part_id: 148,
		quantity: 466,
	},
	{
		garage_id: 188,
		part_id: 149,
		quantity: 299,
	},
	{
		garage_id: 188,
		part_id: 150,
		quantity: 353,
	},
	{
		garage_id: 188,
		part_id: 151,
		quantity: 445,
	},
	{
		garage_id: 188,
		part_id: 152,
		quantity: 359,
	},
	{
		garage_id: 188,
		part_id: 153,
		quantity: 192,
	},
	{
		garage_id: 188,
		part_id: 154,
		quantity: 19,
	},
	{
		garage_id: 188,
		part_id: 155,
		quantity: 246,
	},
	{
		garage_id: 188,
		part_id: 156,
		quantity: 492,
	},
	{
		garage_id: 188,
		part_id: 157,
		quantity: 50,
	},
	{
		garage_id: 188,
		part_id: 158,
		quantity: 207,
	},
	{
		garage_id: 188,
		part_id: 159,
		quantity: 270,
	},
	{
		garage_id: 188,
		part_id: 160,
		quantity: 114,
	},
	{
		garage_id: 188,
		part_id: 161,
		quantity: 323,
	},
	{
		garage_id: 188,
		part_id: 162,
		quantity: 0,
	},
	{
		garage_id: 188,
		part_id: 163,
		quantity: 79,
	},
	{
		garage_id: 188,
		part_id: 164,
		quantity: 248,
	},
	{
		garage_id: 188,
		part_id: 165,
		quantity: 464,
	},
	{
		garage_id: 188,
		part_id: 166,
		quantity: 227,
	},
	{
		garage_id: 188,
		part_id: 167,
		quantity: 403,
	},
	{
		garage_id: 188,
		part_id: 168,
		quantity: 411,
	},
	{
		garage_id: 188,
		part_id: 169,
		quantity: 108,
	},
	{
		garage_id: 188,
		part_id: 170,
		quantity: 275,
	},
	{
		garage_id: 188,
		part_id: 171,
		quantity: 155,
	},
	{
		garage_id: 188,
		part_id: 172,
		quantity: 138,
	},
	{
		garage_id: 188,
		part_id: 173,
		quantity: 304,
	},
	{
		garage_id: 188,
		part_id: 174,
		quantity: 66,
	},
	{
		garage_id: 188,
		part_id: 175,
		quantity: 485,
	},
	{
		garage_id: 188,
		part_id: 176,
		quantity: 139,
	},
	{
		garage_id: 188,
		part_id: 177,
		quantity: 380,
	},
	{
		garage_id: 188,
		part_id: 178,
		quantity: 395,
	},
	{
		garage_id: 188,
		part_id: 179,
		quantity: 267,
	},
	{
		garage_id: 188,
		part_id: 180,
		quantity: 206,
	},
	{
		garage_id: 188,
		part_id: 181,
		quantity: 120,
	},
	{
		garage_id: 188,
		part_id: 182,
		quantity: 158,
	},
	{
		garage_id: 188,
		part_id: 183,
		quantity: 296,
	},
	{
		garage_id: 188,
		part_id: 184,
		quantity: 178,
	},
	{
		garage_id: 188,
		part_id: 185,
		quantity: 102,
	},
	{
		garage_id: 188,
		part_id: 186,
		quantity: 66,
	},
	{
		garage_id: 188,
		part_id: 187,
		quantity: 140,
	},
	{
		garage_id: 188,
		part_id: 188,
		quantity: 126,
	},
	{
		garage_id: 188,
		part_id: 189,
		quantity: 20,
	},
	{
		garage_id: 188,
		part_id: 190,
		quantity: 191,
	},
	{
		garage_id: 188,
		part_id: 191,
		quantity: 258,
	},
	{
		garage_id: 188,
		part_id: 192,
		quantity: 266,
	},
	{
		garage_id: 188,
		part_id: 193,
		quantity: 455,
	},
	{
		garage_id: 188,
		part_id: 194,
		quantity: 232,
	},
	{
		garage_id: 188,
		part_id: 195,
		quantity: 151,
	},
	{
		garage_id: 188,
		part_id: 196,
		quantity: 306,
	},
	{
		garage_id: 188,
		part_id: 197,
		quantity: 464,
	},
	{
		garage_id: 188,
		part_id: 198,
		quantity: 242,
	},
	{
		garage_id: 188,
		part_id: 199,
		quantity: 372,
	},
	{
		garage_id: 188,
		part_id: 200,
		quantity: 321,
	},
	{
		garage_id: 188,
		part_id: 201,
		quantity: 493,
	},
	{
		garage_id: 188,
		part_id: 202,
		quantity: 141,
	},
	{
		garage_id: 188,
		part_id: 203,
		quantity: 392,
	},
	{
		garage_id: 188,
		part_id: 204,
		quantity: 277,
	},
	{
		garage_id: 188,
		part_id: 205,
		quantity: 486,
	},
	{
		garage_id: 188,
		part_id: 206,
		quantity: 353,
	},
	{
		garage_id: 188,
		part_id: 207,
		quantity: 117,
	},
	{
		garage_id: 188,
		part_id: 208,
		quantity: 308,
	},
	{
		garage_id: 188,
		part_id: 209,
		quantity: 417,
	},
	{
		garage_id: 188,
		part_id: 210,
		quantity: 197,
	},
	{
		garage_id: 188,
		part_id: 211,
		quantity: 142,
	},
	{
		garage_id: 188,
		part_id: 212,
		quantity: 383,
	},
	{
		garage_id: 188,
		part_id: 213,
		quantity: 138,
	},
	{
		garage_id: 188,
		part_id: 214,
		quantity: 387,
	},
	{
		garage_id: 188,
		part_id: 215,
		quantity: 268,
	},
	{
		garage_id: 188,
		part_id: 216,
		quantity: 353,
	},
	{
		garage_id: 188,
		part_id: 217,
		quantity: 86,
	},
	{
		garage_id: 188,
		part_id: 218,
		quantity: 158,
	},
	{
		garage_id: 188,
		part_id: 219,
		quantity: 260,
	},
	{
		garage_id: 188,
		part_id: 220,
		quantity: 200,
	},
	{
		garage_id: 188,
		part_id: 221,
		quantity: 388,
	},
	{
		garage_id: 188,
		part_id: 222,
		quantity: 239,
	},
	{
		garage_id: 188,
		part_id: 223,
		quantity: 494,
	},
	{
		garage_id: 188,
		part_id: 224,
		quantity: 17,
	},
	{
		garage_id: 188,
		part_id: 225,
		quantity: 498,
	},
	{
		garage_id: 188,
		part_id: 226,
		quantity: 224,
	},
	{
		garage_id: 188,
		part_id: 227,
		quantity: 294,
	},
	{
		garage_id: 188,
		part_id: 228,
		quantity: 211,
	},
	{
		garage_id: 188,
		part_id: 229,
		quantity: 16,
	},
	{
		garage_id: 188,
		part_id: 230,
		quantity: 359,
	},
	{
		garage_id: 188,
		part_id: 231,
		quantity: 78,
	},
	{
		garage_id: 188,
		part_id: 232,
		quantity: 422,
	},
	{
		garage_id: 188,
		part_id: 233,
		quantity: 66,
	},
	{
		garage_id: 188,
		part_id: 234,
		quantity: 75,
	},
	{
		garage_id: 188,
		part_id: 235,
		quantity: 348,
	},
	{
		garage_id: 188,
		part_id: 236,
		quantity: 242,
	},
	{
		garage_id: 188,
		part_id: 237,
		quantity: 11,
	},
	{
		garage_id: 188,
		part_id: 238,
		quantity: 38,
	},
	{
		garage_id: 188,
		part_id: 239,
		quantity: 304,
	},
	{
		garage_id: 188,
		part_id: 240,
		quantity: 265,
	},
	{
		garage_id: 188,
		part_id: 241,
		quantity: 355,
	},
	{
		garage_id: 188,
		part_id: 242,
		quantity: 419,
	},
	{
		garage_id: 188,
		part_id: 243,
		quantity: 134,
	},
	{
		garage_id: 188,
		part_id: 244,
		quantity: 122,
	},
	{
		garage_id: 188,
		part_id: 245,
		quantity: 66,
	},
	{
		garage_id: 188,
		part_id: 246,
		quantity: 278,
	},
	{
		garage_id: 188,
		part_id: 247,
		quantity: 261,
	},
	{
		garage_id: 188,
		part_id: 248,
		quantity: 201,
	},
	{
		garage_id: 188,
		part_id: 249,
		quantity: 387,
	},
	{
		garage_id: 188,
		part_id: 250,
		quantity: 249,
	},
	{
		garage_id: 188,
		part_id: 251,
		quantity: 158,
	},
	{
		garage_id: 188,
		part_id: 252,
		quantity: 279,
	},
	{
		garage_id: 188,
		part_id: 253,
		quantity: 60,
	},
	{
		garage_id: 188,
		part_id: 254,
		quantity: 493,
	},
	{
		garage_id: 188,
		part_id: 255,
		quantity: 336,
	},
	{
		garage_id: 188,
		part_id: 256,
		quantity: 24,
	},
	{
		garage_id: 188,
		part_id: 257,
		quantity: 370,
	},
	{
		garage_id: 188,
		part_id: 258,
		quantity: 322,
	},
	{
		garage_id: 188,
		part_id: 259,
		quantity: 126,
	},
	{
		garage_id: 188,
		part_id: 260,
		quantity: 204,
	},
	{
		garage_id: 188,
		part_id: 261,
		quantity: 108,
	},
	{
		garage_id: 188,
		part_id: 262,
		quantity: 157,
	},
	{
		garage_id: 188,
		part_id: 263,
		quantity: 175,
	},
	{
		garage_id: 188,
		part_id: 264,
		quantity: 390,
	},
	{
		garage_id: 188,
		part_id: 265,
		quantity: 207,
	},
	{
		garage_id: 188,
		part_id: 266,
		quantity: 429,
	},
	{
		garage_id: 188,
		part_id: 267,
		quantity: 26,
	},
	{
		garage_id: 188,
		part_id: 268,
		quantity: 90,
	},
	{
		garage_id: 188,
		part_id: 269,
		quantity: 224,
	},
	{
		garage_id: 188,
		part_id: 270,
		quantity: 126,
	},
	{
		garage_id: 188,
		part_id: 271,
		quantity: 67,
	},
	{
		garage_id: 188,
		part_id: 272,
		quantity: 457,
	},
	{
		garage_id: 188,
		part_id: 273,
		quantity: 99,
	},
	{
		garage_id: 188,
		part_id: 274,
		quantity: 280,
	},
	{
		garage_id: 188,
		part_id: 275,
		quantity: 435,
	},
	{
		garage_id: 188,
		part_id: 276,
		quantity: 378,
	},
	{
		garage_id: 188,
		part_id: 277,
		quantity: 341,
	},
	{
		garage_id: 188,
		part_id: 278,
		quantity: 274,
	},
	{
		garage_id: 188,
		part_id: 279,
		quantity: 341,
	},
	{
		garage_id: 188,
		part_id: 280,
		quantity: 191,
	},
	{
		garage_id: 188,
		part_id: 281,
		quantity: 60,
	},
	{
		garage_id: 188,
		part_id: 282,
		quantity: 349,
	},
	{
		garage_id: 188,
		part_id: 283,
		quantity: 167,
	},
	{
		garage_id: 188,
		part_id: 284,
		quantity: 199,
	},
	{
		garage_id: 188,
		part_id: 285,
		quantity: 60,
	},
	{
		garage_id: 188,
		part_id: 286,
		quantity: 454,
	},
	{
		garage_id: 188,
		part_id: 287,
		quantity: 287,
	},
	{
		garage_id: 188,
		part_id: 288,
		quantity: 270,
	},
	{
		garage_id: 188,
		part_id: 289,
		quantity: 87,
	},
	{
		garage_id: 188,
		part_id: 290,
		quantity: 178,
	},
	{
		garage_id: 188,
		part_id: 291,
		quantity: 20,
	},
	{
		garage_id: 188,
		part_id: 292,
		quantity: 209,
	},
	{
		garage_id: 188,
		part_id: 293,
		quantity: 170,
	},
	{
		garage_id: 188,
		part_id: 294,
		quantity: 379,
	},
	{
		garage_id: 188,
		part_id: 295,
		quantity: 84,
	},
	{
		garage_id: 188,
		part_id: 296,
		quantity: 183,
	},
	{
		garage_id: 188,
		part_id: 297,
		quantity: 298,
	},
	{
		garage_id: 188,
		part_id: 298,
		quantity: 500,
	},
	{
		garage_id: 188,
		part_id: 299,
		quantity: 272,
	},
	{
		garage_id: 188,
		part_id: 300,
		quantity: 84,
	},
	{
		garage_id: 188,
		part_id: 301,
		quantity: 357,
	},
	{
		garage_id: 188,
		part_id: 302,
		quantity: 278,
	},
	{
		garage_id: 188,
		part_id: 303,
		quantity: 224,
	},
	{
		garage_id: 188,
		part_id: 304,
		quantity: 289,
	},
	{
		garage_id: 188,
		part_id: 305,
		quantity: 79,
	},
	{
		garage_id: 188,
		part_id: 306,
		quantity: 136,
	},
	{
		garage_id: 188,
		part_id: 307,
		quantity: 44,
	},
	{
		garage_id: 188,
		part_id: 308,
		quantity: 77,
	},
	{
		garage_id: 188,
		part_id: 309,
		quantity: 416,
	},
	{
		garage_id: 188,
		part_id: 310,
		quantity: 65,
	},
	{
		garage_id: 188,
		part_id: 311,
		quantity: 233,
	},
	{
		garage_id: 188,
		part_id: 312,
		quantity: 391,
	},
	{
		garage_id: 188,
		part_id: 313,
		quantity: 332,
	},
	{
		garage_id: 188,
		part_id: 314,
		quantity: 144,
	},
	{
		garage_id: 188,
		part_id: 315,
		quantity: 207,
	},
	{
		garage_id: 188,
		part_id: 316,
		quantity: 272,
	},
	{
		garage_id: 188,
		part_id: 317,
		quantity: 402,
	},
	{
		garage_id: 188,
		part_id: 318,
		quantity: 361,
	},
	{
		garage_id: 188,
		part_id: 319,
		quantity: 114,
	},
	{
		garage_id: 188,
		part_id: 320,
		quantity: 37,
	},
	{
		garage_id: 188,
		part_id: 321,
		quantity: 122,
	},
	{
		garage_id: 188,
		part_id: 322,
		quantity: 91,
	},
	{
		garage_id: 188,
		part_id: 323,
		quantity: 269,
	},
	{
		garage_id: 188,
		part_id: 324,
		quantity: 347,
	},
	{
		garage_id: 188,
		part_id: 325,
		quantity: 399,
	},
	{
		garage_id: 188,
		part_id: 326,
		quantity: 360,
	},
	{
		garage_id: 188,
		part_id: 327,
		quantity: 289,
	},
	{
		garage_id: 188,
		part_id: 328,
		quantity: 334,
	},
	{
		garage_id: 188,
		part_id: 329,
		quantity: 153,
	},
	{
		garage_id: 188,
		part_id: 330,
		quantity: 1,
	},
	{
		garage_id: 188,
		part_id: 331,
		quantity: 99,
	},
	{
		garage_id: 188,
		part_id: 332,
		quantity: 263,
	},
	{
		garage_id: 188,
		part_id: 333,
		quantity: 468,
	},
	{
		garage_id: 188,
		part_id: 334,
		quantity: 40,
	},
	{
		garage_id: 188,
		part_id: 335,
		quantity: 172,
	},
	{
		garage_id: 188,
		part_id: 336,
		quantity: 187,
	},
	{
		garage_id: 188,
		part_id: 337,
		quantity: 408,
	},
	{
		garage_id: 188,
		part_id: 338,
		quantity: 415,
	},
	{
		garage_id: 188,
		part_id: 339,
		quantity: 302,
	},
	{
		garage_id: 188,
		part_id: 340,
		quantity: 472,
	},
	{
		garage_id: 188,
		part_id: 341,
		quantity: 164,
	},
	{
		garage_id: 188,
		part_id: 342,
		quantity: 407,
	},
	{
		garage_id: 188,
		part_id: 343,
		quantity: 241,
	},
	{
		garage_id: 188,
		part_id: 344,
		quantity: 86,
	},
	{
		garage_id: 188,
		part_id: 345,
		quantity: 347,
	},
	{
		garage_id: 188,
		part_id: 346,
		quantity: 220,
	},
	{
		garage_id: 188,
		part_id: 347,
		quantity: 408,
	},
	{
		garage_id: 188,
		part_id: 348,
		quantity: 162,
	},
	{
		garage_id: 188,
		part_id: 349,
		quantity: 447,
	},
	{
		garage_id: 188,
		part_id: 350,
		quantity: 499,
	},
	{
		garage_id: 188,
		part_id: 351,
		quantity: 139,
	},
	{
		garage_id: 188,
		part_id: 352,
		quantity: 437,
	},
	{
		garage_id: 188,
		part_id: 353,
		quantity: 382,
	},
	{
		garage_id: 188,
		part_id: 354,
		quantity: 15,
	},
	{
		garage_id: 188,
		part_id: 355,
		quantity: 173,
	},
	{
		garage_id: 188,
		part_id: 356,
		quantity: 193,
	},
	{
		garage_id: 188,
		part_id: 357,
		quantity: 322,
	},
	{
		garage_id: 188,
		part_id: 358,
		quantity: 6,
	},
	{
		garage_id: 188,
		part_id: 359,
		quantity: 70,
	},
	{
		garage_id: 188,
		part_id: 360,
		quantity: 51,
	},
	{
		garage_id: 188,
		part_id: 361,
		quantity: 173,
	},
	{
		garage_id: 188,
		part_id: 362,
		quantity: 171,
	},
	{
		garage_id: 188,
		part_id: 363,
		quantity: 225,
	},
	{
		garage_id: 188,
		part_id: 364,
		quantity: 493,
	},
	{
		garage_id: 188,
		part_id: 365,
		quantity: 264,
	},
	{
		garage_id: 188,
		part_id: 366,
		quantity: 426,
	},
	{
		garage_id: 188,
		part_id: 367,
		quantity: 238,
	},
	{
		garage_id: 188,
		part_id: 368,
		quantity: 151,
	},
	{
		garage_id: 188,
		part_id: 369,
		quantity: 109,
	},
	{
		garage_id: 188,
		part_id: 370,
		quantity: 218,
	},
	{
		garage_id: 188,
		part_id: 371,
		quantity: 46,
	},
	{
		garage_id: 188,
		part_id: 372,
		quantity: 196,
	},
	{
		garage_id: 188,
		part_id: 373,
		quantity: 412,
	},
	{
		garage_id: 188,
		part_id: 374,
		quantity: 29,
	},
	{
		garage_id: 188,
		part_id: 375,
		quantity: 156,
	},
	{
		garage_id: 188,
		part_id: 376,
		quantity: 61,
	},
	{
		garage_id: 188,
		part_id: 377,
		quantity: 121,
	},
	{
		garage_id: 188,
		part_id: 378,
		quantity: 71,
	},
	{
		garage_id: 188,
		part_id: 379,
		quantity: 453,
	},
	{
		garage_id: 188,
		part_id: 380,
		quantity: 393,
	},
	{
		garage_id: 188,
		part_id: 381,
		quantity: 179,
	},
	{
		garage_id: 188,
		part_id: 382,
		quantity: 187,
	},
	{
		garage_id: 188,
		part_id: 383,
		quantity: 170,
	},
	{
		garage_id: 188,
		part_id: 384,
		quantity: 18,
	},
	{
		garage_id: 188,
		part_id: 385,
		quantity: 51,
	},
	{
		garage_id: 188,
		part_id: 386,
		quantity: 369,
	},
	{
		garage_id: 188,
		part_id: 387,
		quantity: 122,
	},
	{
		garage_id: 188,
		part_id: 388,
		quantity: 408,
	},
	{
		garage_id: 188,
		part_id: 389,
		quantity: 356,
	},
	{
		garage_id: 188,
		part_id: 390,
		quantity: 460,
	},
	{
		garage_id: 188,
		part_id: 391,
		quantity: 277,
	},
	{
		garage_id: 188,
		part_id: 392,
		quantity: 484,
	},
	{
		garage_id: 188,
		part_id: 393,
		quantity: 53,
	},
	{
		garage_id: 188,
		part_id: 394,
		quantity: 327,
	},
	{
		garage_id: 188,
		part_id: 395,
		quantity: 212,
	},
	{
		garage_id: 188,
		part_id: 396,
		quantity: 158,
	},
	{
		garage_id: 188,
		part_id: 397,
		quantity: 322,
	},
	{
		garage_id: 188,
		part_id: 398,
		quantity: 491,
	},
	{
		garage_id: 188,
		part_id: 399,
		quantity: 323,
	},
	{
		garage_id: 189,
		part_id: 0,
		quantity: 454,
	},
	{
		garage_id: 189,
		part_id: 1,
		quantity: 444,
	},
	{
		garage_id: 189,
		part_id: 2,
		quantity: 81,
	},
	{
		garage_id: 189,
		part_id: 3,
		quantity: 373,
	},
	{
		garage_id: 189,
		part_id: 4,
		quantity: 44,
	},
	{
		garage_id: 189,
		part_id: 5,
		quantity: 451,
	},
	{
		garage_id: 189,
		part_id: 6,
		quantity: 28,
	},
	{
		garage_id: 189,
		part_id: 7,
		quantity: 21,
	},
	{
		garage_id: 189,
		part_id: 8,
		quantity: 431,
	},
	{
		garage_id: 189,
		part_id: 9,
		quantity: 358,
	},
	{
		garage_id: 189,
		part_id: 10,
		quantity: 27,
	},
	{
		garage_id: 189,
		part_id: 11,
		quantity: 27,
	},
	{
		garage_id: 189,
		part_id: 12,
		quantity: 480,
	},
	{
		garage_id: 189,
		part_id: 13,
		quantity: 235,
	},
	{
		garage_id: 189,
		part_id: 14,
		quantity: 52,
	},
	{
		garage_id: 189,
		part_id: 15,
		quantity: 265,
	},
	{
		garage_id: 189,
		part_id: 16,
		quantity: 199,
	},
	{
		garage_id: 189,
		part_id: 17,
		quantity: 199,
	},
	{
		garage_id: 189,
		part_id: 18,
		quantity: 203,
	},
	{
		garage_id: 189,
		part_id: 19,
		quantity: 452,
	},
	{
		garage_id: 189,
		part_id: 20,
		quantity: 290,
	},
	{
		garage_id: 189,
		part_id: 21,
		quantity: 177,
	},
	{
		garage_id: 189,
		part_id: 22,
		quantity: 406,
	},
	{
		garage_id: 189,
		part_id: 23,
		quantity: 460,
	},
	{
		garage_id: 189,
		part_id: 24,
		quantity: 137,
	},
	{
		garage_id: 189,
		part_id: 25,
		quantity: 269,
	},
	{
		garage_id: 189,
		part_id: 26,
		quantity: 152,
	},
	{
		garage_id: 189,
		part_id: 27,
		quantity: 18,
	},
	{
		garage_id: 189,
		part_id: 28,
		quantity: 474,
	},
	{
		garage_id: 189,
		part_id: 29,
		quantity: 83,
	},
	{
		garage_id: 189,
		part_id: 30,
		quantity: 361,
	},
	{
		garage_id: 189,
		part_id: 31,
		quantity: 380,
	},
	{
		garage_id: 189,
		part_id: 32,
		quantity: 190,
	},
	{
		garage_id: 189,
		part_id: 33,
		quantity: 56,
	},
	{
		garage_id: 189,
		part_id: 34,
		quantity: 256,
	},
	{
		garage_id: 189,
		part_id: 35,
		quantity: 340,
	},
	{
		garage_id: 189,
		part_id: 36,
		quantity: 96,
	},
	{
		garage_id: 189,
		part_id: 37,
		quantity: 101,
	},
	{
		garage_id: 189,
		part_id: 38,
		quantity: 275,
	},
	{
		garage_id: 189,
		part_id: 39,
		quantity: 478,
	},
	{
		garage_id: 189,
		part_id: 40,
		quantity: 94,
	},
	{
		garage_id: 189,
		part_id: 41,
		quantity: 447,
	},
	{
		garage_id: 189,
		part_id: 42,
		quantity: 107,
	},
	{
		garage_id: 189,
		part_id: 43,
		quantity: 375,
	},
	{
		garage_id: 189,
		part_id: 44,
		quantity: 394,
	},
	{
		garage_id: 189,
		part_id: 45,
		quantity: 374,
	},
	{
		garage_id: 189,
		part_id: 46,
		quantity: 322,
	},
	{
		garage_id: 189,
		part_id: 47,
		quantity: 254,
	},
	{
		garage_id: 189,
		part_id: 48,
		quantity: 417,
	},
	{
		garage_id: 189,
		part_id: 49,
		quantity: 55,
	},
	{
		garage_id: 189,
		part_id: 50,
		quantity: 261,
	},
	{
		garage_id: 189,
		part_id: 51,
		quantity: 447,
	},
	{
		garage_id: 189,
		part_id: 52,
		quantity: 493,
	},
	{
		garage_id: 189,
		part_id: 53,
		quantity: 109,
	},
	{
		garage_id: 189,
		part_id: 54,
		quantity: 139,
	},
	{
		garage_id: 189,
		part_id: 55,
		quantity: 437,
	},
	{
		garage_id: 189,
		part_id: 56,
		quantity: 415,
	},
	{
		garage_id: 189,
		part_id: 57,
		quantity: 281,
	},
	{
		garage_id: 189,
		part_id: 58,
		quantity: 492,
	},
	{
		garage_id: 189,
		part_id: 59,
		quantity: 302,
	},
	{
		garage_id: 189,
		part_id: 60,
		quantity: 369,
	},
	{
		garage_id: 189,
		part_id: 61,
		quantity: 256,
	},
	{
		garage_id: 189,
		part_id: 62,
		quantity: 423,
	},
	{
		garage_id: 189,
		part_id: 63,
		quantity: 56,
	},
	{
		garage_id: 189,
		part_id: 64,
		quantity: 222,
	},
	{
		garage_id: 189,
		part_id: 65,
		quantity: 146,
	},
	{
		garage_id: 189,
		part_id: 66,
		quantity: 265,
	},
	{
		garage_id: 189,
		part_id: 67,
		quantity: 83,
	},
	{
		garage_id: 189,
		part_id: 68,
		quantity: 117,
	},
	{
		garage_id: 189,
		part_id: 69,
		quantity: 150,
	},
	{
		garage_id: 189,
		part_id: 70,
		quantity: 340,
	},
	{
		garage_id: 189,
		part_id: 71,
		quantity: 95,
	},
	{
		garage_id: 189,
		part_id: 72,
		quantity: 217,
	},
	{
		garage_id: 189,
		part_id: 73,
		quantity: 61,
	},
	{
		garage_id: 189,
		part_id: 74,
		quantity: 224,
	},
	{
		garage_id: 189,
		part_id: 75,
		quantity: 198,
	},
	{
		garage_id: 189,
		part_id: 76,
		quantity: 100,
	},
	{
		garage_id: 189,
		part_id: 77,
		quantity: 287,
	},
	{
		garage_id: 189,
		part_id: 78,
		quantity: 200,
	},
	{
		garage_id: 189,
		part_id: 79,
		quantity: 257,
	},
	{
		garage_id: 189,
		part_id: 80,
		quantity: 366,
	},
	{
		garage_id: 189,
		part_id: 81,
		quantity: 61,
	},
	{
		garage_id: 189,
		part_id: 82,
		quantity: 25,
	},
	{
		garage_id: 189,
		part_id: 83,
		quantity: 72,
	},
	{
		garage_id: 189,
		part_id: 84,
		quantity: 387,
	},
	{
		garage_id: 189,
		part_id: 85,
		quantity: 290,
	},
	{
		garage_id: 189,
		part_id: 86,
		quantity: 0,
	},
	{
		garage_id: 189,
		part_id: 87,
		quantity: 55,
	},
	{
		garage_id: 189,
		part_id: 88,
		quantity: 180,
	},
	{
		garage_id: 189,
		part_id: 89,
		quantity: 296,
	},
	{
		garage_id: 189,
		part_id: 90,
		quantity: 334,
	},
	{
		garage_id: 189,
		part_id: 91,
		quantity: 97,
	},
	{
		garage_id: 189,
		part_id: 92,
		quantity: 285,
	},
	{
		garage_id: 189,
		part_id: 93,
		quantity: 426,
	},
	{
		garage_id: 189,
		part_id: 94,
		quantity: 408,
	},
	{
		garage_id: 189,
		part_id: 95,
		quantity: 379,
	},
	{
		garage_id: 189,
		part_id: 96,
		quantity: 210,
	},
	{
		garage_id: 189,
		part_id: 97,
		quantity: 433,
	},
	{
		garage_id: 189,
		part_id: 98,
		quantity: 23,
	},
	{
		garage_id: 189,
		part_id: 99,
		quantity: 353,
	},
	{
		garage_id: 189,
		part_id: 100,
		quantity: 209,
	},
	{
		garage_id: 189,
		part_id: 101,
		quantity: 199,
	},
	{
		garage_id: 189,
		part_id: 102,
		quantity: 192,
	},
	{
		garage_id: 189,
		part_id: 103,
		quantity: 51,
	},
	{
		garage_id: 189,
		part_id: 104,
		quantity: 68,
	},
	{
		garage_id: 189,
		part_id: 105,
		quantity: 215,
	},
	{
		garage_id: 189,
		part_id: 106,
		quantity: 377,
	},
	{
		garage_id: 189,
		part_id: 107,
		quantity: 318,
	},
	{
		garage_id: 189,
		part_id: 108,
		quantity: 151,
	},
	{
		garage_id: 189,
		part_id: 109,
		quantity: 381,
	},
	{
		garage_id: 189,
		part_id: 110,
		quantity: 391,
	},
	{
		garage_id: 189,
		part_id: 111,
		quantity: 129,
	},
	{
		garage_id: 189,
		part_id: 112,
		quantity: 55,
	},
	{
		garage_id: 189,
		part_id: 113,
		quantity: 342,
	},
	{
		garage_id: 189,
		part_id: 114,
		quantity: 422,
	},
	{
		garage_id: 189,
		part_id: 115,
		quantity: 124,
	},
	{
		garage_id: 189,
		part_id: 116,
		quantity: 316,
	},
	{
		garage_id: 189,
		part_id: 117,
		quantity: 418,
	},
	{
		garage_id: 189,
		part_id: 118,
		quantity: 61,
	},
	{
		garage_id: 189,
		part_id: 119,
		quantity: 433,
	},
	{
		garage_id: 189,
		part_id: 120,
		quantity: 70,
	},
	{
		garage_id: 189,
		part_id: 121,
		quantity: 211,
	},
	{
		garage_id: 189,
		part_id: 122,
		quantity: 481,
	},
	{
		garage_id: 189,
		part_id: 123,
		quantity: 311,
	},
	{
		garage_id: 189,
		part_id: 124,
		quantity: 6,
	},
	{
		garage_id: 189,
		part_id: 125,
		quantity: 428,
	},
	{
		garage_id: 189,
		part_id: 126,
		quantity: 261,
	},
	{
		garage_id: 189,
		part_id: 127,
		quantity: 47,
	},
	{
		garage_id: 189,
		part_id: 128,
		quantity: 205,
	},
	{
		garage_id: 189,
		part_id: 129,
		quantity: 142,
	},
	{
		garage_id: 189,
		part_id: 130,
		quantity: 402,
	},
	{
		garage_id: 189,
		part_id: 131,
		quantity: 427,
	},
	{
		garage_id: 189,
		part_id: 132,
		quantity: 280,
	},
	{
		garage_id: 189,
		part_id: 133,
		quantity: 343,
	},
	{
		garage_id: 189,
		part_id: 134,
		quantity: 60,
	},
	{
		garage_id: 189,
		part_id: 135,
		quantity: 71,
	},
	{
		garage_id: 189,
		part_id: 136,
		quantity: 427,
	},
	{
		garage_id: 189,
		part_id: 137,
		quantity: 348,
	},
	{
		garage_id: 189,
		part_id: 138,
		quantity: 147,
	},
	{
		garage_id: 189,
		part_id: 139,
		quantity: 157,
	},
	{
		garage_id: 189,
		part_id: 140,
		quantity: 292,
	},
	{
		garage_id: 189,
		part_id: 141,
		quantity: 442,
	},
	{
		garage_id: 189,
		part_id: 142,
		quantity: 77,
	},
	{
		garage_id: 189,
		part_id: 143,
		quantity: 308,
	},
	{
		garage_id: 189,
		part_id: 144,
		quantity: 160,
	},
	{
		garage_id: 189,
		part_id: 145,
		quantity: 46,
	},
	{
		garage_id: 189,
		part_id: 146,
		quantity: 361,
	},
	{
		garage_id: 189,
		part_id: 147,
		quantity: 462,
	},
	{
		garage_id: 189,
		part_id: 148,
		quantity: 459,
	},
	{
		garage_id: 189,
		part_id: 149,
		quantity: 445,
	},
	{
		garage_id: 189,
		part_id: 150,
		quantity: 353,
	},
	{
		garage_id: 189,
		part_id: 151,
		quantity: 478,
	},
	{
		garage_id: 189,
		part_id: 152,
		quantity: 32,
	},
	{
		garage_id: 189,
		part_id: 153,
		quantity: 161,
	},
	{
		garage_id: 189,
		part_id: 154,
		quantity: 149,
	},
	{
		garage_id: 189,
		part_id: 155,
		quantity: 130,
	},
	{
		garage_id: 189,
		part_id: 156,
		quantity: 113,
	},
	{
		garage_id: 189,
		part_id: 157,
		quantity: 315,
	},
	{
		garage_id: 189,
		part_id: 158,
		quantity: 376,
	},
	{
		garage_id: 189,
		part_id: 159,
		quantity: 158,
	},
	{
		garage_id: 189,
		part_id: 160,
		quantity: 135,
	},
	{
		garage_id: 189,
		part_id: 161,
		quantity: 275,
	},
	{
		garage_id: 189,
		part_id: 162,
		quantity: 229,
	},
	{
		garage_id: 189,
		part_id: 163,
		quantity: 179,
	},
	{
		garage_id: 189,
		part_id: 164,
		quantity: 323,
	},
	{
		garage_id: 189,
		part_id: 165,
		quantity: 96,
	},
	{
		garage_id: 189,
		part_id: 166,
		quantity: 160,
	},
	{
		garage_id: 189,
		part_id: 167,
		quantity: 372,
	},
	{
		garage_id: 189,
		part_id: 168,
		quantity: 388,
	},
	{
		garage_id: 189,
		part_id: 169,
		quantity: 3,
	},
	{
		garage_id: 189,
		part_id: 170,
		quantity: 210,
	},
	{
		garage_id: 189,
		part_id: 171,
		quantity: 282,
	},
	{
		garage_id: 189,
		part_id: 172,
		quantity: 304,
	},
	{
		garage_id: 189,
		part_id: 173,
		quantity: 402,
	},
	{
		garage_id: 189,
		part_id: 174,
		quantity: 365,
	},
	{
		garage_id: 189,
		part_id: 175,
		quantity: 399,
	},
	{
		garage_id: 189,
		part_id: 176,
		quantity: 212,
	},
	{
		garage_id: 189,
		part_id: 177,
		quantity: 191,
	},
	{
		garage_id: 189,
		part_id: 178,
		quantity: 296,
	},
	{
		garage_id: 189,
		part_id: 179,
		quantity: 58,
	},
	{
		garage_id: 189,
		part_id: 180,
		quantity: 194,
	},
	{
		garage_id: 189,
		part_id: 181,
		quantity: 384,
	},
	{
		garage_id: 189,
		part_id: 182,
		quantity: 167,
	},
	{
		garage_id: 189,
		part_id: 183,
		quantity: 56,
	},
	{
		garage_id: 189,
		part_id: 184,
		quantity: 244,
	},
	{
		garage_id: 189,
		part_id: 185,
		quantity: 468,
	},
	{
		garage_id: 189,
		part_id: 186,
		quantity: 487,
	},
	{
		garage_id: 189,
		part_id: 187,
		quantity: 359,
	},
	{
		garage_id: 189,
		part_id: 188,
		quantity: 435,
	},
	{
		garage_id: 189,
		part_id: 189,
		quantity: 191,
	},
	{
		garage_id: 189,
		part_id: 190,
		quantity: 208,
	},
	{
		garage_id: 189,
		part_id: 191,
		quantity: 9,
	},
	{
		garage_id: 189,
		part_id: 192,
		quantity: 134,
	},
	{
		garage_id: 189,
		part_id: 193,
		quantity: 189,
	},
	{
		garage_id: 189,
		part_id: 194,
		quantity: 374,
	},
	{
		garage_id: 189,
		part_id: 195,
		quantity: 392,
	},
	{
		garage_id: 189,
		part_id: 196,
		quantity: 160,
	},
	{
		garage_id: 189,
		part_id: 197,
		quantity: 403,
	},
	{
		garage_id: 189,
		part_id: 198,
		quantity: 126,
	},
	{
		garage_id: 189,
		part_id: 199,
		quantity: 310,
	},
	{
		garage_id: 189,
		part_id: 200,
		quantity: 269,
	},
	{
		garage_id: 189,
		part_id: 201,
		quantity: 211,
	},
	{
		garage_id: 189,
		part_id: 202,
		quantity: 95,
	},
	{
		garage_id: 189,
		part_id: 203,
		quantity: 375,
	},
	{
		garage_id: 189,
		part_id: 204,
		quantity: 479,
	},
	{
		garage_id: 189,
		part_id: 205,
		quantity: 37,
	},
	{
		garage_id: 189,
		part_id: 206,
		quantity: 385,
	},
	{
		garage_id: 189,
		part_id: 207,
		quantity: 397,
	},
	{
		garage_id: 189,
		part_id: 208,
		quantity: 323,
	},
	{
		garage_id: 189,
		part_id: 209,
		quantity: 485,
	},
	{
		garage_id: 189,
		part_id: 210,
		quantity: 155,
	},
	{
		garage_id: 189,
		part_id: 211,
		quantity: 404,
	},
	{
		garage_id: 189,
		part_id: 212,
		quantity: 360,
	},
	{
		garage_id: 189,
		part_id: 213,
		quantity: 340,
	},
	{
		garage_id: 189,
		part_id: 214,
		quantity: 466,
	},
	{
		garage_id: 189,
		part_id: 215,
		quantity: 107,
	},
	{
		garage_id: 189,
		part_id: 216,
		quantity: 113,
	},
	{
		garage_id: 189,
		part_id: 217,
		quantity: 331,
	},
	{
		garage_id: 189,
		part_id: 218,
		quantity: 157,
	},
	{
		garage_id: 189,
		part_id: 219,
		quantity: 204,
	},
	{
		garage_id: 189,
		part_id: 220,
		quantity: 59,
	},
	{
		garage_id: 189,
		part_id: 221,
		quantity: 31,
	},
	{
		garage_id: 189,
		part_id: 222,
		quantity: 235,
	},
	{
		garage_id: 189,
		part_id: 223,
		quantity: 318,
	},
	{
		garage_id: 189,
		part_id: 224,
		quantity: 412,
	},
	{
		garage_id: 189,
		part_id: 225,
		quantity: 121,
	},
	{
		garage_id: 189,
		part_id: 226,
		quantity: 42,
	},
	{
		garage_id: 189,
		part_id: 227,
		quantity: 275,
	},
	{
		garage_id: 189,
		part_id: 228,
		quantity: 141,
	},
	{
		garage_id: 189,
		part_id: 229,
		quantity: 58,
	},
	{
		garage_id: 189,
		part_id: 230,
		quantity: 164,
	},
	{
		garage_id: 189,
		part_id: 231,
		quantity: 143,
	},
	{
		garage_id: 189,
		part_id: 232,
		quantity: 85,
	},
	{
		garage_id: 189,
		part_id: 233,
		quantity: 352,
	},
	{
		garage_id: 189,
		part_id: 234,
		quantity: 311,
	},
	{
		garage_id: 189,
		part_id: 235,
		quantity: 173,
	},
	{
		garage_id: 189,
		part_id: 236,
		quantity: 55,
	},
	{
		garage_id: 189,
		part_id: 237,
		quantity: 395,
	},
	{
		garage_id: 189,
		part_id: 238,
		quantity: 191,
	},
	{
		garage_id: 189,
		part_id: 239,
		quantity: 3,
	},
	{
		garage_id: 189,
		part_id: 240,
		quantity: 496,
	},
	{
		garage_id: 189,
		part_id: 241,
		quantity: 342,
	},
	{
		garage_id: 189,
		part_id: 242,
		quantity: 54,
	},
	{
		garage_id: 189,
		part_id: 243,
		quantity: 316,
	},
	{
		garage_id: 189,
		part_id: 244,
		quantity: 363,
	},
	{
		garage_id: 189,
		part_id: 245,
		quantity: 292,
	},
	{
		garage_id: 189,
		part_id: 246,
		quantity: 314,
	},
	{
		garage_id: 189,
		part_id: 247,
		quantity: 155,
	},
	{
		garage_id: 189,
		part_id: 248,
		quantity: 262,
	},
	{
		garage_id: 189,
		part_id: 249,
		quantity: 435,
	},
	{
		garage_id: 189,
		part_id: 250,
		quantity: 137,
	},
	{
		garage_id: 189,
		part_id: 251,
		quantity: 404,
	},
	{
		garage_id: 189,
		part_id: 252,
		quantity: 429,
	},
	{
		garage_id: 189,
		part_id: 253,
		quantity: 213,
	},
	{
		garage_id: 189,
		part_id: 254,
		quantity: 407,
	},
	{
		garage_id: 189,
		part_id: 255,
		quantity: 491,
	},
	{
		garage_id: 189,
		part_id: 256,
		quantity: 394,
	},
	{
		garage_id: 189,
		part_id: 257,
		quantity: 175,
	},
	{
		garage_id: 189,
		part_id: 258,
		quantity: 397,
	},
	{
		garage_id: 189,
		part_id: 259,
		quantity: 80,
	},
	{
		garage_id: 189,
		part_id: 260,
		quantity: 141,
	},
	{
		garage_id: 189,
		part_id: 261,
		quantity: 76,
	},
	{
		garage_id: 189,
		part_id: 262,
		quantity: 286,
	},
	{
		garage_id: 189,
		part_id: 263,
		quantity: 493,
	},
	{
		garage_id: 189,
		part_id: 264,
		quantity: 39,
	},
	{
		garage_id: 189,
		part_id: 265,
		quantity: 125,
	},
	{
		garage_id: 189,
		part_id: 266,
		quantity: 140,
	},
	{
		garage_id: 189,
		part_id: 267,
		quantity: 191,
	},
	{
		garage_id: 189,
		part_id: 268,
		quantity: 483,
	},
	{
		garage_id: 189,
		part_id: 269,
		quantity: 99,
	},
	{
		garage_id: 189,
		part_id: 270,
		quantity: 458,
	},
	{
		garage_id: 189,
		part_id: 271,
		quantity: 103,
	},
	{
		garage_id: 189,
		part_id: 272,
		quantity: 404,
	},
	{
		garage_id: 189,
		part_id: 273,
		quantity: 225,
	},
	{
		garage_id: 189,
		part_id: 274,
		quantity: 95,
	},
	{
		garage_id: 189,
		part_id: 275,
		quantity: 217,
	},
	{
		garage_id: 189,
		part_id: 276,
		quantity: 473,
	},
	{
		garage_id: 189,
		part_id: 277,
		quantity: 446,
	},
	{
		garage_id: 189,
		part_id: 278,
		quantity: 92,
	},
	{
		garage_id: 189,
		part_id: 279,
		quantity: 69,
	},
	{
		garage_id: 189,
		part_id: 280,
		quantity: 313,
	},
	{
		garage_id: 189,
		part_id: 281,
		quantity: 463,
	},
	{
		garage_id: 189,
		part_id: 282,
		quantity: 348,
	},
	{
		garage_id: 189,
		part_id: 283,
		quantity: 116,
	},
	{
		garage_id: 189,
		part_id: 284,
		quantity: 363,
	},
	{
		garage_id: 189,
		part_id: 285,
		quantity: 407,
	},
	{
		garage_id: 189,
		part_id: 286,
		quantity: 254,
	},
	{
		garage_id: 189,
		part_id: 287,
		quantity: 366,
	},
	{
		garage_id: 189,
		part_id: 288,
		quantity: 382,
	},
	{
		garage_id: 189,
		part_id: 289,
		quantity: 355,
	},
	{
		garage_id: 189,
		part_id: 290,
		quantity: 26,
	},
	{
		garage_id: 189,
		part_id: 291,
		quantity: 385,
	},
	{
		garage_id: 189,
		part_id: 292,
		quantity: 139,
	},
	{
		garage_id: 189,
		part_id: 293,
		quantity: 346,
	},
	{
		garage_id: 189,
		part_id: 294,
		quantity: 79,
	},
	{
		garage_id: 189,
		part_id: 295,
		quantity: 444,
	},
	{
		garage_id: 189,
		part_id: 296,
		quantity: 32,
	},
	{
		garage_id: 189,
		part_id: 297,
		quantity: 116,
	},
	{
		garage_id: 189,
		part_id: 298,
		quantity: 307,
	},
	{
		garage_id: 189,
		part_id: 299,
		quantity: 383,
	},
	{
		garage_id: 189,
		part_id: 300,
		quantity: 458,
	},
	{
		garage_id: 189,
		part_id: 301,
		quantity: 58,
	},
	{
		garage_id: 189,
		part_id: 302,
		quantity: 230,
	},
	{
		garage_id: 189,
		part_id: 303,
		quantity: 95,
	},
	{
		garage_id: 189,
		part_id: 304,
		quantity: 413,
	},
	{
		garage_id: 189,
		part_id: 305,
		quantity: 482,
	},
	{
		garage_id: 189,
		part_id: 306,
		quantity: 420,
	},
	{
		garage_id: 189,
		part_id: 307,
		quantity: 97,
	},
	{
		garage_id: 189,
		part_id: 308,
		quantity: 377,
	},
	{
		garage_id: 189,
		part_id: 309,
		quantity: 332,
	},
	{
		garage_id: 189,
		part_id: 310,
		quantity: 40,
	},
	{
		garage_id: 189,
		part_id: 311,
		quantity: 300,
	},
	{
		garage_id: 189,
		part_id: 312,
		quantity: 99,
	},
	{
		garage_id: 189,
		part_id: 313,
		quantity: 319,
	},
	{
		garage_id: 189,
		part_id: 314,
		quantity: 131,
	},
	{
		garage_id: 189,
		part_id: 315,
		quantity: 185,
	},
	{
		garage_id: 189,
		part_id: 316,
		quantity: 211,
	},
	{
		garage_id: 189,
		part_id: 317,
		quantity: 495,
	},
	{
		garage_id: 189,
		part_id: 318,
		quantity: 87,
	},
	{
		garage_id: 189,
		part_id: 319,
		quantity: 234,
	},
	{
		garage_id: 189,
		part_id: 320,
		quantity: 86,
	},
	{
		garage_id: 189,
		part_id: 321,
		quantity: 406,
	},
	{
		garage_id: 189,
		part_id: 322,
		quantity: 48,
	},
	{
		garage_id: 189,
		part_id: 323,
		quantity: 130,
	},
	{
		garage_id: 189,
		part_id: 324,
		quantity: 431,
	},
	{
		garage_id: 189,
		part_id: 325,
		quantity: 276,
	},
	{
		garage_id: 189,
		part_id: 326,
		quantity: 50,
	},
	{
		garage_id: 189,
		part_id: 327,
		quantity: 384,
	},
	{
		garage_id: 189,
		part_id: 328,
		quantity: 448,
	},
	{
		garage_id: 189,
		part_id: 329,
		quantity: 404,
	},
	{
		garage_id: 189,
		part_id: 330,
		quantity: 122,
	},
	{
		garage_id: 189,
		part_id: 331,
		quantity: 116,
	},
	{
		garage_id: 189,
		part_id: 332,
		quantity: 141,
	},
	{
		garage_id: 189,
		part_id: 333,
		quantity: 353,
	},
	{
		garage_id: 189,
		part_id: 334,
		quantity: 125,
	},
	{
		garage_id: 189,
		part_id: 335,
		quantity: 426,
	},
	{
		garage_id: 189,
		part_id: 336,
		quantity: 247,
	},
	{
		garage_id: 189,
		part_id: 337,
		quantity: 17,
	},
	{
		garage_id: 189,
		part_id: 338,
		quantity: 373,
	},
	{
		garage_id: 189,
		part_id: 339,
		quantity: 311,
	},
	{
		garage_id: 189,
		part_id: 340,
		quantity: 497,
	},
	{
		garage_id: 189,
		part_id: 341,
		quantity: 414,
	},
	{
		garage_id: 189,
		part_id: 342,
		quantity: 262,
	},
	{
		garage_id: 189,
		part_id: 343,
		quantity: 95,
	},
	{
		garage_id: 189,
		part_id: 344,
		quantity: 204,
	},
	{
		garage_id: 189,
		part_id: 345,
		quantity: 114,
	},
	{
		garage_id: 189,
		part_id: 346,
		quantity: 440,
	},
	{
		garage_id: 189,
		part_id: 347,
		quantity: 318,
	},
	{
		garage_id: 189,
		part_id: 348,
		quantity: 456,
	},
	{
		garage_id: 189,
		part_id: 349,
		quantity: 435,
	},
	{
		garage_id: 189,
		part_id: 350,
		quantity: 93,
	},
	{
		garage_id: 189,
		part_id: 351,
		quantity: 152,
	},
	{
		garage_id: 189,
		part_id: 352,
		quantity: 327,
	},
	{
		garage_id: 189,
		part_id: 353,
		quantity: 312,
	},
	{
		garage_id: 189,
		part_id: 354,
		quantity: 403,
	},
	{
		garage_id: 189,
		part_id: 355,
		quantity: 482,
	},
	{
		garage_id: 189,
		part_id: 356,
		quantity: 185,
	},
	{
		garage_id: 189,
		part_id: 357,
		quantity: 407,
	},
	{
		garage_id: 189,
		part_id: 358,
		quantity: 92,
	},
	{
		garage_id: 189,
		part_id: 359,
		quantity: 214,
	},
	{
		garage_id: 189,
		part_id: 360,
		quantity: 26,
	},
	{
		garage_id: 189,
		part_id: 361,
		quantity: 330,
	},
	{
		garage_id: 189,
		part_id: 362,
		quantity: 464,
	},
	{
		garage_id: 189,
		part_id: 363,
		quantity: 32,
	},
	{
		garage_id: 189,
		part_id: 364,
		quantity: 39,
	},
	{
		garage_id: 189,
		part_id: 365,
		quantity: 461,
	},
	{
		garage_id: 189,
		part_id: 366,
		quantity: 483,
	},
	{
		garage_id: 189,
		part_id: 367,
		quantity: 388,
	},
	{
		garage_id: 189,
		part_id: 368,
		quantity: 129,
	},
	{
		garage_id: 189,
		part_id: 369,
		quantity: 101,
	},
	{
		garage_id: 189,
		part_id: 370,
		quantity: 36,
	},
	{
		garage_id: 189,
		part_id: 371,
		quantity: 19,
	},
	{
		garage_id: 189,
		part_id: 372,
		quantity: 374,
	},
	{
		garage_id: 189,
		part_id: 373,
		quantity: 73,
	},
	{
		garage_id: 189,
		part_id: 374,
		quantity: 479,
	},
	{
		garage_id: 189,
		part_id: 375,
		quantity: 348,
	},
	{
		garage_id: 189,
		part_id: 376,
		quantity: 126,
	},
	{
		garage_id: 189,
		part_id: 377,
		quantity: 9,
	},
	{
		garage_id: 189,
		part_id: 378,
		quantity: 389,
	},
	{
		garage_id: 189,
		part_id: 379,
		quantity: 489,
	},
	{
		garage_id: 189,
		part_id: 380,
		quantity: 108,
	},
	{
		garage_id: 189,
		part_id: 381,
		quantity: 19,
	},
	{
		garage_id: 189,
		part_id: 382,
		quantity: 81,
	},
	{
		garage_id: 189,
		part_id: 383,
		quantity: 434,
	},
	{
		garage_id: 189,
		part_id: 384,
		quantity: 317,
	},
	{
		garage_id: 189,
		part_id: 385,
		quantity: 436,
	},
	{
		garage_id: 189,
		part_id: 386,
		quantity: 354,
	},
	{
		garage_id: 189,
		part_id: 387,
		quantity: 242,
	},
	{
		garage_id: 189,
		part_id: 388,
		quantity: 98,
	},
	{
		garage_id: 189,
		part_id: 389,
		quantity: 157,
	},
	{
		garage_id: 189,
		part_id: 390,
		quantity: 303,
	},
	{
		garage_id: 189,
		part_id: 391,
		quantity: 237,
	},
	{
		garage_id: 189,
		part_id: 392,
		quantity: 349,
	},
	{
		garage_id: 189,
		part_id: 393,
		quantity: 342,
	},
	{
		garage_id: 189,
		part_id: 394,
		quantity: 297,
	},
	{
		garage_id: 189,
		part_id: 395,
		quantity: 46,
	},
	{
		garage_id: 189,
		part_id: 396,
		quantity: 450,
	},
	{
		garage_id: 189,
		part_id: 397,
		quantity: 379,
	},
	{
		garage_id: 189,
		part_id: 398,
		quantity: 482,
	},
	{
		garage_id: 189,
		part_id: 399,
		quantity: 138,
	},
	{
		garage_id: 190,
		part_id: 0,
		quantity: 162,
	},
	{
		garage_id: 190,
		part_id: 1,
		quantity: 450,
	},
	{
		garage_id: 190,
		part_id: 2,
		quantity: 321,
	},
	{
		garage_id: 190,
		part_id: 3,
		quantity: 172,
	},
	{
		garage_id: 190,
		part_id: 4,
		quantity: 226,
	},
	{
		garage_id: 190,
		part_id: 5,
		quantity: 190,
	},
	{
		garage_id: 190,
		part_id: 6,
		quantity: 39,
	},
	{
		garage_id: 190,
		part_id: 7,
		quantity: 253,
	},
	{
		garage_id: 190,
		part_id: 8,
		quantity: 266,
	},
	{
		garage_id: 190,
		part_id: 9,
		quantity: 250,
	},
	{
		garage_id: 190,
		part_id: 10,
		quantity: 308,
	},
	{
		garage_id: 190,
		part_id: 11,
		quantity: 167,
	},
	{
		garage_id: 190,
		part_id: 12,
		quantity: 24,
	},
	{
		garage_id: 190,
		part_id: 13,
		quantity: 354,
	},
	{
		garage_id: 190,
		part_id: 14,
		quantity: 44,
	},
	{
		garage_id: 190,
		part_id: 15,
		quantity: 388,
	},
	{
		garage_id: 190,
		part_id: 16,
		quantity: 205,
	},
	{
		garage_id: 190,
		part_id: 17,
		quantity: 406,
	},
	{
		garage_id: 190,
		part_id: 18,
		quantity: 28,
	},
	{
		garage_id: 190,
		part_id: 19,
		quantity: 337,
	},
	{
		garage_id: 190,
		part_id: 20,
		quantity: 357,
	},
	{
		garage_id: 190,
		part_id: 21,
		quantity: 342,
	},
	{
		garage_id: 190,
		part_id: 22,
		quantity: 132,
	},
	{
		garage_id: 190,
		part_id: 23,
		quantity: 41,
	},
	{
		garage_id: 190,
		part_id: 24,
		quantity: 139,
	},
	{
		garage_id: 190,
		part_id: 25,
		quantity: 148,
	},
	{
		garage_id: 190,
		part_id: 26,
		quantity: 21,
	},
	{
		garage_id: 190,
		part_id: 27,
		quantity: 44,
	},
	{
		garage_id: 190,
		part_id: 28,
		quantity: 245,
	},
	{
		garage_id: 190,
		part_id: 29,
		quantity: 106,
	},
	{
		garage_id: 190,
		part_id: 30,
		quantity: 354,
	},
	{
		garage_id: 190,
		part_id: 31,
		quantity: 92,
	},
	{
		garage_id: 190,
		part_id: 32,
		quantity: 335,
	},
	{
		garage_id: 190,
		part_id: 33,
		quantity: 467,
	},
	{
		garage_id: 190,
		part_id: 34,
		quantity: 492,
	},
	{
		garage_id: 190,
		part_id: 35,
		quantity: 369,
	},
	{
		garage_id: 190,
		part_id: 36,
		quantity: 202,
	},
	{
		garage_id: 190,
		part_id: 37,
		quantity: 353,
	},
	{
		garage_id: 190,
		part_id: 38,
		quantity: 71,
	},
	{
		garage_id: 190,
		part_id: 39,
		quantity: 49,
	},
	{
		garage_id: 190,
		part_id: 40,
		quantity: 74,
	},
	{
		garage_id: 190,
		part_id: 41,
		quantity: 476,
	},
	{
		garage_id: 190,
		part_id: 42,
		quantity: 31,
	},
	{
		garage_id: 190,
		part_id: 43,
		quantity: 107,
	},
	{
		garage_id: 190,
		part_id: 44,
		quantity: 442,
	},
	{
		garage_id: 190,
		part_id: 45,
		quantity: 314,
	},
	{
		garage_id: 190,
		part_id: 46,
		quantity: 112,
	},
	{
		garage_id: 190,
		part_id: 47,
		quantity: 161,
	},
	{
		garage_id: 190,
		part_id: 48,
		quantity: 180,
	},
	{
		garage_id: 190,
		part_id: 49,
		quantity: 327,
	},
	{
		garage_id: 190,
		part_id: 50,
		quantity: 102,
	},
	{
		garage_id: 190,
		part_id: 51,
		quantity: 23,
	},
	{
		garage_id: 190,
		part_id: 52,
		quantity: 264,
	},
	{
		garage_id: 190,
		part_id: 53,
		quantity: 90,
	},
	{
		garage_id: 190,
		part_id: 54,
		quantity: 6,
	},
	{
		garage_id: 190,
		part_id: 55,
		quantity: 44,
	},
	{
		garage_id: 190,
		part_id: 56,
		quantity: 271,
	},
	{
		garage_id: 190,
		part_id: 57,
		quantity: 53,
	},
	{
		garage_id: 190,
		part_id: 58,
		quantity: 330,
	},
	{
		garage_id: 190,
		part_id: 59,
		quantity: 409,
	},
	{
		garage_id: 190,
		part_id: 60,
		quantity: 326,
	},
	{
		garage_id: 190,
		part_id: 61,
		quantity: 97,
	},
	{
		garage_id: 190,
		part_id: 62,
		quantity: 470,
	},
	{
		garage_id: 190,
		part_id: 63,
		quantity: 197,
	},
	{
		garage_id: 190,
		part_id: 64,
		quantity: 320,
	},
	{
		garage_id: 190,
		part_id: 65,
		quantity: 80,
	},
	{
		garage_id: 190,
		part_id: 66,
		quantity: 492,
	},
	{
		garage_id: 190,
		part_id: 67,
		quantity: 36,
	},
	{
		garage_id: 190,
		part_id: 68,
		quantity: 377,
	},
	{
		garage_id: 190,
		part_id: 69,
		quantity: 383,
	},
	{
		garage_id: 190,
		part_id: 70,
		quantity: 25,
	},
	{
		garage_id: 190,
		part_id: 71,
		quantity: 428,
	},
	{
		garage_id: 190,
		part_id: 72,
		quantity: 422,
	},
	{
		garage_id: 190,
		part_id: 73,
		quantity: 19,
	},
	{
		garage_id: 190,
		part_id: 74,
		quantity: 343,
	},
	{
		garage_id: 190,
		part_id: 75,
		quantity: 450,
	},
	{
		garage_id: 190,
		part_id: 76,
		quantity: 238,
	},
	{
		garage_id: 190,
		part_id: 77,
		quantity: 297,
	},
	{
		garage_id: 190,
		part_id: 78,
		quantity: 342,
	},
	{
		garage_id: 190,
		part_id: 79,
		quantity: 339,
	},
	{
		garage_id: 190,
		part_id: 80,
		quantity: 402,
	},
	{
		garage_id: 190,
		part_id: 81,
		quantity: 305,
	},
	{
		garage_id: 190,
		part_id: 82,
		quantity: 461,
	},
	{
		garage_id: 190,
		part_id: 83,
		quantity: 430,
	},
	{
		garage_id: 190,
		part_id: 84,
		quantity: 383,
	},
	{
		garage_id: 190,
		part_id: 85,
		quantity: 454,
	},
	{
		garage_id: 190,
		part_id: 86,
		quantity: 139,
	},
	{
		garage_id: 190,
		part_id: 87,
		quantity: 265,
	},
	{
		garage_id: 190,
		part_id: 88,
		quantity: 445,
	},
	{
		garage_id: 190,
		part_id: 89,
		quantity: 17,
	},
	{
		garage_id: 190,
		part_id: 90,
		quantity: 432,
	},
	{
		garage_id: 190,
		part_id: 91,
		quantity: 285,
	},
	{
		garage_id: 190,
		part_id: 92,
		quantity: 228,
	},
	{
		garage_id: 190,
		part_id: 93,
		quantity: 2,
	},
	{
		garage_id: 190,
		part_id: 94,
		quantity: 433,
	},
	{
		garage_id: 190,
		part_id: 95,
		quantity: 247,
	},
	{
		garage_id: 190,
		part_id: 96,
		quantity: 300,
	},
	{
		garage_id: 190,
		part_id: 97,
		quantity: 266,
	},
	{
		garage_id: 190,
		part_id: 98,
		quantity: 309,
	},
	{
		garage_id: 190,
		part_id: 99,
		quantity: 446,
	},
	{
		garage_id: 190,
		part_id: 100,
		quantity: 91,
	},
	{
		garage_id: 190,
		part_id: 101,
		quantity: 66,
	},
	{
		garage_id: 190,
		part_id: 102,
		quantity: 442,
	},
	{
		garage_id: 190,
		part_id: 103,
		quantity: 106,
	},
	{
		garage_id: 190,
		part_id: 104,
		quantity: 250,
	},
	{
		garage_id: 190,
		part_id: 105,
		quantity: 221,
	},
	{
		garage_id: 190,
		part_id: 106,
		quantity: 274,
	},
	{
		garage_id: 190,
		part_id: 107,
		quantity: 307,
	},
	{
		garage_id: 190,
		part_id: 108,
		quantity: 417,
	},
	{
		garage_id: 190,
		part_id: 109,
		quantity: 17,
	},
	{
		garage_id: 190,
		part_id: 110,
		quantity: 414,
	},
	{
		garage_id: 190,
		part_id: 111,
		quantity: 23,
	},
	{
		garage_id: 190,
		part_id: 112,
		quantity: 131,
	},
	{
		garage_id: 190,
		part_id: 113,
		quantity: 201,
	},
	{
		garage_id: 190,
		part_id: 114,
		quantity: 433,
	},
	{
		garage_id: 190,
		part_id: 115,
		quantity: 490,
	},
	{
		garage_id: 190,
		part_id: 116,
		quantity: 393,
	},
	{
		garage_id: 190,
		part_id: 117,
		quantity: 349,
	},
	{
		garage_id: 190,
		part_id: 118,
		quantity: 350,
	},
	{
		garage_id: 190,
		part_id: 119,
		quantity: 82,
	},
	{
		garage_id: 190,
		part_id: 120,
		quantity: 402,
	},
	{
		garage_id: 190,
		part_id: 121,
		quantity: 9,
	},
	{
		garage_id: 190,
		part_id: 122,
		quantity: 328,
	},
	{
		garage_id: 190,
		part_id: 123,
		quantity: 118,
	},
	{
		garage_id: 190,
		part_id: 124,
		quantity: 456,
	},
	{
		garage_id: 190,
		part_id: 125,
		quantity: 91,
	},
	{
		garage_id: 190,
		part_id: 126,
		quantity: 500,
	},
	{
		garage_id: 190,
		part_id: 127,
		quantity: 211,
	},
	{
		garage_id: 190,
		part_id: 128,
		quantity: 390,
	},
	{
		garage_id: 190,
		part_id: 129,
		quantity: 302,
	},
	{
		garage_id: 190,
		part_id: 130,
		quantity: 395,
	},
	{
		garage_id: 190,
		part_id: 131,
		quantity: 193,
	},
	{
		garage_id: 190,
		part_id: 132,
		quantity: 99,
	},
	{
		garage_id: 190,
		part_id: 133,
		quantity: 422,
	},
	{
		garage_id: 190,
		part_id: 134,
		quantity: 107,
	},
	{
		garage_id: 190,
		part_id: 135,
		quantity: 184,
	},
	{
		garage_id: 190,
		part_id: 136,
		quantity: 53,
	},
	{
		garage_id: 190,
		part_id: 137,
		quantity: 10,
	},
	{
		garage_id: 190,
		part_id: 138,
		quantity: 342,
	},
	{
		garage_id: 190,
		part_id: 139,
		quantity: 12,
	},
	{
		garage_id: 190,
		part_id: 140,
		quantity: 206,
	},
	{
		garage_id: 190,
		part_id: 141,
		quantity: 338,
	},
	{
		garage_id: 190,
		part_id: 142,
		quantity: 494,
	},
	{
		garage_id: 190,
		part_id: 143,
		quantity: 439,
	},
	{
		garage_id: 190,
		part_id: 144,
		quantity: 358,
	},
	{
		garage_id: 190,
		part_id: 145,
		quantity: 128,
	},
	{
		garage_id: 190,
		part_id: 146,
		quantity: 234,
	},
	{
		garage_id: 190,
		part_id: 147,
		quantity: 355,
	},
	{
		garage_id: 190,
		part_id: 148,
		quantity: 286,
	},
	{
		garage_id: 190,
		part_id: 149,
		quantity: 176,
	},
	{
		garage_id: 190,
		part_id: 150,
		quantity: 433,
	},
	{
		garage_id: 190,
		part_id: 151,
		quantity: 181,
	},
	{
		garage_id: 190,
		part_id: 152,
		quantity: 69,
	},
	{
		garage_id: 190,
		part_id: 153,
		quantity: 24,
	},
	{
		garage_id: 190,
		part_id: 154,
		quantity: 235,
	},
	{
		garage_id: 190,
		part_id: 155,
		quantity: 500,
	},
	{
		garage_id: 190,
		part_id: 156,
		quantity: 244,
	},
	{
		garage_id: 190,
		part_id: 157,
		quantity: 70,
	},
	{
		garage_id: 190,
		part_id: 158,
		quantity: 295,
	},
	{
		garage_id: 190,
		part_id: 159,
		quantity: 244,
	},
	{
		garage_id: 190,
		part_id: 160,
		quantity: 61,
	},
	{
		garage_id: 190,
		part_id: 161,
		quantity: 205,
	},
	{
		garage_id: 190,
		part_id: 162,
		quantity: 260,
	},
	{
		garage_id: 190,
		part_id: 163,
		quantity: 271,
	},
	{
		garage_id: 190,
		part_id: 164,
		quantity: 365,
	},
	{
		garage_id: 190,
		part_id: 165,
		quantity: 223,
	},
	{
		garage_id: 190,
		part_id: 166,
		quantity: 89,
	},
	{
		garage_id: 190,
		part_id: 167,
		quantity: 494,
	},
	{
		garage_id: 190,
		part_id: 168,
		quantity: 26,
	},
	{
		garage_id: 190,
		part_id: 169,
		quantity: 245,
	},
	{
		garage_id: 190,
		part_id: 170,
		quantity: 323,
	},
	{
		garage_id: 190,
		part_id: 171,
		quantity: 188,
	},
	{
		garage_id: 190,
		part_id: 172,
		quantity: 8,
	},
	{
		garage_id: 190,
		part_id: 173,
		quantity: 197,
	},
	{
		garage_id: 190,
		part_id: 174,
		quantity: 152,
	},
	{
		garage_id: 190,
		part_id: 175,
		quantity: 476,
	},
	{
		garage_id: 190,
		part_id: 176,
		quantity: 479,
	},
	{
		garage_id: 190,
		part_id: 177,
		quantity: 137,
	},
	{
		garage_id: 190,
		part_id: 178,
		quantity: 483,
	},
	{
		garage_id: 190,
		part_id: 179,
		quantity: 221,
	},
	{
		garage_id: 190,
		part_id: 180,
		quantity: 311,
	},
	{
		garage_id: 190,
		part_id: 181,
		quantity: 443,
	},
	{
		garage_id: 190,
		part_id: 182,
		quantity: 485,
	},
	{
		garage_id: 190,
		part_id: 183,
		quantity: 216,
	},
	{
		garage_id: 190,
		part_id: 184,
		quantity: 207,
	},
	{
		garage_id: 190,
		part_id: 185,
		quantity: 61,
	},
	{
		garage_id: 190,
		part_id: 186,
		quantity: 153,
	},
	{
		garage_id: 190,
		part_id: 187,
		quantity: 206,
	},
	{
		garage_id: 190,
		part_id: 188,
		quantity: 228,
	},
	{
		garage_id: 190,
		part_id: 189,
		quantity: 143,
	},
	{
		garage_id: 190,
		part_id: 190,
		quantity: 418,
	},
	{
		garage_id: 190,
		part_id: 191,
		quantity: 3,
	},
	{
		garage_id: 190,
		part_id: 192,
		quantity: 497,
	},
	{
		garage_id: 190,
		part_id: 193,
		quantity: 202,
	},
	{
		garage_id: 190,
		part_id: 194,
		quantity: 357,
	},
	{
		garage_id: 190,
		part_id: 195,
		quantity: 25,
	},
	{
		garage_id: 190,
		part_id: 196,
		quantity: 15,
	},
	{
		garage_id: 190,
		part_id: 197,
		quantity: 274,
	},
	{
		garage_id: 190,
		part_id: 198,
		quantity: 208,
	},
	{
		garage_id: 190,
		part_id: 199,
		quantity: 360,
	},
	{
		garage_id: 190,
		part_id: 200,
		quantity: 405,
	},
	{
		garage_id: 190,
		part_id: 201,
		quantity: 76,
	},
	{
		garage_id: 190,
		part_id: 202,
		quantity: 125,
	},
	{
		garage_id: 190,
		part_id: 203,
		quantity: 260,
	},
	{
		garage_id: 190,
		part_id: 204,
		quantity: 276,
	},
	{
		garage_id: 190,
		part_id: 205,
		quantity: 309,
	},
	{
		garage_id: 190,
		part_id: 206,
		quantity: 283,
	},
	{
		garage_id: 190,
		part_id: 207,
		quantity: 459,
	},
	{
		garage_id: 190,
		part_id: 208,
		quantity: 464,
	},
	{
		garage_id: 190,
		part_id: 209,
		quantity: 391,
	},
	{
		garage_id: 190,
		part_id: 210,
		quantity: 409,
	},
	{
		garage_id: 190,
		part_id: 211,
		quantity: 10,
	},
	{
		garage_id: 190,
		part_id: 212,
		quantity: 251,
	},
	{
		garage_id: 190,
		part_id: 213,
		quantity: 158,
	},
	{
		garage_id: 190,
		part_id: 214,
		quantity: 223,
	},
	{
		garage_id: 190,
		part_id: 215,
		quantity: 325,
	},
	{
		garage_id: 190,
		part_id: 216,
		quantity: 267,
	},
	{
		garage_id: 190,
		part_id: 217,
		quantity: 394,
	},
	{
		garage_id: 190,
		part_id: 218,
		quantity: 48,
	},
	{
		garage_id: 190,
		part_id: 219,
		quantity: 234,
	},
	{
		garage_id: 190,
		part_id: 220,
		quantity: 307,
	},
	{
		garage_id: 190,
		part_id: 221,
		quantity: 445,
	},
	{
		garage_id: 190,
		part_id: 222,
		quantity: 144,
	},
	{
		garage_id: 190,
		part_id: 223,
		quantity: 53,
	},
	{
		garage_id: 190,
		part_id: 224,
		quantity: 387,
	},
	{
		garage_id: 190,
		part_id: 225,
		quantity: 157,
	},
	{
		garage_id: 190,
		part_id: 226,
		quantity: 293,
	},
	{
		garage_id: 190,
		part_id: 227,
		quantity: 461,
	},
	{
		garage_id: 190,
		part_id: 228,
		quantity: 48,
	},
	{
		garage_id: 190,
		part_id: 229,
		quantity: 306,
	},
	{
		garage_id: 190,
		part_id: 230,
		quantity: 426,
	},
	{
		garage_id: 190,
		part_id: 231,
		quantity: 460,
	},
	{
		garage_id: 190,
		part_id: 232,
		quantity: 448,
	},
	{
		garage_id: 190,
		part_id: 233,
		quantity: 422,
	},
	{
		garage_id: 190,
		part_id: 234,
		quantity: 335,
	},
	{
		garage_id: 190,
		part_id: 235,
		quantity: 182,
	},
	{
		garage_id: 190,
		part_id: 236,
		quantity: 64,
	},
	{
		garage_id: 190,
		part_id: 237,
		quantity: 164,
	},
	{
		garage_id: 190,
		part_id: 238,
		quantity: 230,
	},
	{
		garage_id: 190,
		part_id: 239,
		quantity: 488,
	},
	{
		garage_id: 190,
		part_id: 240,
		quantity: 33,
	},
	{
		garage_id: 190,
		part_id: 241,
		quantity: 473,
	},
	{
		garage_id: 190,
		part_id: 242,
		quantity: 349,
	},
	{
		garage_id: 190,
		part_id: 243,
		quantity: 170,
	},
	{
		garage_id: 190,
		part_id: 244,
		quantity: 474,
	},
	{
		garage_id: 190,
		part_id: 245,
		quantity: 130,
	},
	{
		garage_id: 190,
		part_id: 246,
		quantity: 365,
	},
	{
		garage_id: 190,
		part_id: 247,
		quantity: 157,
	},
	{
		garage_id: 190,
		part_id: 248,
		quantity: 425,
	},
	{
		garage_id: 190,
		part_id: 249,
		quantity: 14,
	},
	{
		garage_id: 190,
		part_id: 250,
		quantity: 31,
	},
	{
		garage_id: 190,
		part_id: 251,
		quantity: 418,
	},
	{
		garage_id: 190,
		part_id: 252,
		quantity: 266,
	},
	{
		garage_id: 190,
		part_id: 253,
		quantity: 73,
	},
	{
		garage_id: 190,
		part_id: 254,
		quantity: 306,
	},
	{
		garage_id: 190,
		part_id: 255,
		quantity: 372,
	},
	{
		garage_id: 190,
		part_id: 256,
		quantity: 44,
	},
	{
		garage_id: 190,
		part_id: 257,
		quantity: 418,
	},
	{
		garage_id: 190,
		part_id: 258,
		quantity: 188,
	},
	{
		garage_id: 190,
		part_id: 259,
		quantity: 188,
	},
	{
		garage_id: 190,
		part_id: 260,
		quantity: 233,
	},
	{
		garage_id: 190,
		part_id: 261,
		quantity: 488,
	},
	{
		garage_id: 190,
		part_id: 262,
		quantity: 96,
	},
	{
		garage_id: 190,
		part_id: 263,
		quantity: 478,
	},
	{
		garage_id: 190,
		part_id: 264,
		quantity: 265,
	},
	{
		garage_id: 190,
		part_id: 265,
		quantity: 175,
	},
	{
		garage_id: 190,
		part_id: 266,
		quantity: 375,
	},
	{
		garage_id: 190,
		part_id: 267,
		quantity: 345,
	},
	{
		garage_id: 190,
		part_id: 268,
		quantity: 177,
	},
	{
		garage_id: 190,
		part_id: 269,
		quantity: 178,
	},
	{
		garage_id: 190,
		part_id: 270,
		quantity: 36,
	},
	{
		garage_id: 190,
		part_id: 271,
		quantity: 241,
	},
	{
		garage_id: 190,
		part_id: 272,
		quantity: 414,
	},
	{
		garage_id: 190,
		part_id: 273,
		quantity: 454,
	},
	{
		garage_id: 190,
		part_id: 274,
		quantity: 127,
	},
	{
		garage_id: 190,
		part_id: 275,
		quantity: 381,
	},
	{
		garage_id: 190,
		part_id: 276,
		quantity: 378,
	},
	{
		garage_id: 190,
		part_id: 277,
		quantity: 237,
	},
	{
		garage_id: 190,
		part_id: 278,
		quantity: 337,
	},
	{
		garage_id: 190,
		part_id: 279,
		quantity: 79,
	},
	{
		garage_id: 190,
		part_id: 280,
		quantity: 284,
	},
	{
		garage_id: 190,
		part_id: 281,
		quantity: 261,
	},
	{
		garage_id: 190,
		part_id: 282,
		quantity: 101,
	},
	{
		garage_id: 190,
		part_id: 283,
		quantity: 438,
	},
	{
		garage_id: 190,
		part_id: 284,
		quantity: 73,
	},
	{
		garage_id: 190,
		part_id: 285,
		quantity: 66,
	},
	{
		garage_id: 190,
		part_id: 286,
		quantity: 254,
	},
	{
		garage_id: 190,
		part_id: 287,
		quantity: 239,
	},
	{
		garage_id: 190,
		part_id: 288,
		quantity: 65,
	},
	{
		garage_id: 190,
		part_id: 289,
		quantity: 32,
	},
	{
		garage_id: 190,
		part_id: 290,
		quantity: 400,
	},
	{
		garage_id: 190,
		part_id: 291,
		quantity: 418,
	},
	{
		garage_id: 190,
		part_id: 292,
		quantity: 324,
	},
	{
		garage_id: 190,
		part_id: 293,
		quantity: 374,
	},
	{
		garage_id: 190,
		part_id: 294,
		quantity: 232,
	},
	{
		garage_id: 190,
		part_id: 295,
		quantity: 393,
	},
	{
		garage_id: 190,
		part_id: 296,
		quantity: 285,
	},
	{
		garage_id: 190,
		part_id: 297,
		quantity: 295,
	},
	{
		garage_id: 190,
		part_id: 298,
		quantity: 243,
	},
	{
		garage_id: 190,
		part_id: 299,
		quantity: 201,
	},
	{
		garage_id: 190,
		part_id: 300,
		quantity: 367,
	},
	{
		garage_id: 190,
		part_id: 301,
		quantity: 441,
	},
	{
		garage_id: 190,
		part_id: 302,
		quantity: 355,
	},
	{
		garage_id: 190,
		part_id: 303,
		quantity: 493,
	},
	{
		garage_id: 190,
		part_id: 304,
		quantity: 226,
	},
	{
		garage_id: 190,
		part_id: 305,
		quantity: 230,
	},
	{
		garage_id: 190,
		part_id: 306,
		quantity: 308,
	},
	{
		garage_id: 190,
		part_id: 307,
		quantity: 385,
	},
	{
		garage_id: 190,
		part_id: 308,
		quantity: 455,
	},
	{
		garage_id: 190,
		part_id: 309,
		quantity: 490,
	},
	{
		garage_id: 190,
		part_id: 310,
		quantity: 208,
	},
	{
		garage_id: 190,
		part_id: 311,
		quantity: 324,
	},
	{
		garage_id: 190,
		part_id: 312,
		quantity: 135,
	},
	{
		garage_id: 190,
		part_id: 313,
		quantity: 148,
	},
	{
		garage_id: 190,
		part_id: 314,
		quantity: 436,
	},
	{
		garage_id: 190,
		part_id: 315,
		quantity: 210,
	},
	{
		garage_id: 190,
		part_id: 316,
		quantity: 170,
	},
	{
		garage_id: 190,
		part_id: 317,
		quantity: 315,
	},
	{
		garage_id: 190,
		part_id: 318,
		quantity: 133,
	},
	{
		garage_id: 190,
		part_id: 319,
		quantity: 29,
	},
	{
		garage_id: 190,
		part_id: 320,
		quantity: 259,
	},
	{
		garage_id: 190,
		part_id: 321,
		quantity: 236,
	},
	{
		garage_id: 190,
		part_id: 322,
		quantity: 379,
	},
	{
		garage_id: 190,
		part_id: 323,
		quantity: 360,
	},
	{
		garage_id: 190,
		part_id: 324,
		quantity: 27,
	},
	{
		garage_id: 190,
		part_id: 325,
		quantity: 287,
	},
	{
		garage_id: 190,
		part_id: 326,
		quantity: 369,
	},
	{
		garage_id: 190,
		part_id: 327,
		quantity: 18,
	},
	{
		garage_id: 190,
		part_id: 328,
		quantity: 275,
	},
	{
		garage_id: 190,
		part_id: 329,
		quantity: 34,
	},
	{
		garage_id: 190,
		part_id: 330,
		quantity: 104,
	},
	{
		garage_id: 190,
		part_id: 331,
		quantity: 97,
	},
	{
		garage_id: 190,
		part_id: 332,
		quantity: 61,
	},
	{
		garage_id: 190,
		part_id: 333,
		quantity: 370,
	},
	{
		garage_id: 190,
		part_id: 334,
		quantity: 234,
	},
	{
		garage_id: 190,
		part_id: 335,
		quantity: 431,
	},
	{
		garage_id: 190,
		part_id: 336,
		quantity: 433,
	},
	{
		garage_id: 190,
		part_id: 337,
		quantity: 218,
	},
	{
		garage_id: 190,
		part_id: 338,
		quantity: 492,
	},
	{
		garage_id: 190,
		part_id: 339,
		quantity: 91,
	},
	{
		garage_id: 190,
		part_id: 340,
		quantity: 23,
	},
	{
		garage_id: 190,
		part_id: 341,
		quantity: 468,
	},
	{
		garage_id: 190,
		part_id: 342,
		quantity: 29,
	},
	{
		garage_id: 190,
		part_id: 343,
		quantity: 143,
	},
	{
		garage_id: 190,
		part_id: 344,
		quantity: 241,
	},
	{
		garage_id: 190,
		part_id: 345,
		quantity: 66,
	},
	{
		garage_id: 190,
		part_id: 346,
		quantity: 422,
	},
	{
		garage_id: 190,
		part_id: 347,
		quantity: 483,
	},
	{
		garage_id: 190,
		part_id: 348,
		quantity: 29,
	},
	{
		garage_id: 190,
		part_id: 349,
		quantity: 98,
	},
	{
		garage_id: 190,
		part_id: 350,
		quantity: 288,
	},
	{
		garage_id: 190,
		part_id: 351,
		quantity: 247,
	},
	{
		garage_id: 190,
		part_id: 352,
		quantity: 315,
	},
	{
		garage_id: 190,
		part_id: 353,
		quantity: 257,
	},
	{
		garage_id: 190,
		part_id: 354,
		quantity: 276,
	},
	{
		garage_id: 190,
		part_id: 355,
		quantity: 132,
	},
	{
		garage_id: 190,
		part_id: 356,
		quantity: 289,
	},
	{
		garage_id: 190,
		part_id: 357,
		quantity: 99,
	},
	{
		garage_id: 190,
		part_id: 358,
		quantity: 369,
	},
	{
		garage_id: 190,
		part_id: 359,
		quantity: 433,
	},
	{
		garage_id: 190,
		part_id: 360,
		quantity: 238,
	},
	{
		garage_id: 190,
		part_id: 361,
		quantity: 313,
	},
	{
		garage_id: 190,
		part_id: 362,
		quantity: 197,
	},
	{
		garage_id: 190,
		part_id: 363,
		quantity: 415,
	},
	{
		garage_id: 190,
		part_id: 364,
		quantity: 472,
	},
	{
		garage_id: 190,
		part_id: 365,
		quantity: 296,
	},
	{
		garage_id: 190,
		part_id: 366,
		quantity: 208,
	},
	{
		garage_id: 190,
		part_id: 367,
		quantity: 250,
	},
	{
		garage_id: 190,
		part_id: 368,
		quantity: 181,
	},
	{
		garage_id: 190,
		part_id: 369,
		quantity: 202,
	},
	{
		garage_id: 190,
		part_id: 370,
		quantity: 5,
	},
	{
		garage_id: 190,
		part_id: 371,
		quantity: 471,
	},
	{
		garage_id: 190,
		part_id: 372,
		quantity: 32,
	},
	{
		garage_id: 190,
		part_id: 373,
		quantity: 162,
	},
	{
		garage_id: 190,
		part_id: 374,
		quantity: 126,
	},
	{
		garage_id: 190,
		part_id: 375,
		quantity: 170,
	},
	{
		garage_id: 190,
		part_id: 376,
		quantity: 435,
	},
	{
		garage_id: 190,
		part_id: 377,
		quantity: 52,
	},
	{
		garage_id: 190,
		part_id: 378,
		quantity: 119,
	},
	{
		garage_id: 190,
		part_id: 379,
		quantity: 183,
	},
	{
		garage_id: 190,
		part_id: 380,
		quantity: 166,
	},
	{
		garage_id: 190,
		part_id: 381,
		quantity: 155,
	},
	{
		garage_id: 190,
		part_id: 382,
		quantity: 143,
	},
	{
		garage_id: 190,
		part_id: 383,
		quantity: 240,
	},
	{
		garage_id: 190,
		part_id: 384,
		quantity: 212,
	},
	{
		garage_id: 190,
		part_id: 385,
		quantity: 367,
	},
	{
		garage_id: 190,
		part_id: 386,
		quantity: 361,
	},
	{
		garage_id: 190,
		part_id: 387,
		quantity: 286,
	},
	{
		garage_id: 190,
		part_id: 388,
		quantity: 467,
	},
	{
		garage_id: 190,
		part_id: 389,
		quantity: 467,
	},
	{
		garage_id: 190,
		part_id: 390,
		quantity: 288,
	},
	{
		garage_id: 190,
		part_id: 391,
		quantity: 413,
	},
	{
		garage_id: 190,
		part_id: 392,
		quantity: 497,
	},
	{
		garage_id: 190,
		part_id: 393,
		quantity: 435,
	},
	{
		garage_id: 190,
		part_id: 394,
		quantity: 7,
	},
	{
		garage_id: 190,
		part_id: 395,
		quantity: 240,
	},
	{
		garage_id: 190,
		part_id: 396,
		quantity: 94,
	},
	{
		garage_id: 190,
		part_id: 397,
		quantity: 391,
	},
	{
		garage_id: 190,
		part_id: 398,
		quantity: 31,
	},
	{
		garage_id: 190,
		part_id: 399,
		quantity: 204,
	},
	{
		garage_id: 191,
		part_id: 0,
		quantity: 142,
	},
	{
		garage_id: 191,
		part_id: 1,
		quantity: 204,
	},
	{
		garage_id: 191,
		part_id: 2,
		quantity: 435,
	},
	{
		garage_id: 191,
		part_id: 3,
		quantity: 373,
	},
	{
		garage_id: 191,
		part_id: 4,
		quantity: 62,
	},
	{
		garage_id: 191,
		part_id: 5,
		quantity: 465,
	},
	{
		garage_id: 191,
		part_id: 6,
		quantity: 97,
	},
	{
		garage_id: 191,
		part_id: 7,
		quantity: 324,
	},
	{
		garage_id: 191,
		part_id: 8,
		quantity: 337,
	},
	{
		garage_id: 191,
		part_id: 9,
		quantity: 301,
	},
	{
		garage_id: 191,
		part_id: 10,
		quantity: 38,
	},
	{
		garage_id: 191,
		part_id: 11,
		quantity: 354,
	},
	{
		garage_id: 191,
		part_id: 12,
		quantity: 476,
	},
	{
		garage_id: 191,
		part_id: 13,
		quantity: 361,
	},
	{
		garage_id: 191,
		part_id: 14,
		quantity: 330,
	},
	{
		garage_id: 191,
		part_id: 15,
		quantity: 220,
	},
	{
		garage_id: 191,
		part_id: 16,
		quantity: 181,
	},
	{
		garage_id: 191,
		part_id: 17,
		quantity: 309,
	},
	{
		garage_id: 191,
		part_id: 18,
		quantity: 417,
	},
	{
		garage_id: 191,
		part_id: 19,
		quantity: 167,
	},
	{
		garage_id: 191,
		part_id: 20,
		quantity: 118,
	},
	{
		garage_id: 191,
		part_id: 21,
		quantity: 27,
	},
	{
		garage_id: 191,
		part_id: 22,
		quantity: 454,
	},
	{
		garage_id: 191,
		part_id: 23,
		quantity: 251,
	},
	{
		garage_id: 191,
		part_id: 24,
		quantity: 314,
	},
	{
		garage_id: 191,
		part_id: 25,
		quantity: 371,
	},
	{
		garage_id: 191,
		part_id: 26,
		quantity: 163,
	},
	{
		garage_id: 191,
		part_id: 27,
		quantity: 434,
	},
	{
		garage_id: 191,
		part_id: 28,
		quantity: 121,
	},
	{
		garage_id: 191,
		part_id: 29,
		quantity: 413,
	},
	{
		garage_id: 191,
		part_id: 30,
		quantity: 176,
	},
	{
		garage_id: 191,
		part_id: 31,
		quantity: 79,
	},
	{
		garage_id: 191,
		part_id: 32,
		quantity: 193,
	},
	{
		garage_id: 191,
		part_id: 33,
		quantity: 210,
	},
	{
		garage_id: 191,
		part_id: 34,
		quantity: 484,
	},
	{
		garage_id: 191,
		part_id: 35,
		quantity: 18,
	},
	{
		garage_id: 191,
		part_id: 36,
		quantity: 303,
	},
	{
		garage_id: 191,
		part_id: 37,
		quantity: 244,
	},
	{
		garage_id: 191,
		part_id: 38,
		quantity: 362,
	},
	{
		garage_id: 191,
		part_id: 39,
		quantity: 331,
	},
	{
		garage_id: 191,
		part_id: 40,
		quantity: 118,
	},
	{
		garage_id: 191,
		part_id: 41,
		quantity: 188,
	},
	{
		garage_id: 191,
		part_id: 42,
		quantity: 28,
	},
	{
		garage_id: 191,
		part_id: 43,
		quantity: 213,
	},
	{
		garage_id: 191,
		part_id: 44,
		quantity: 346,
	},
	{
		garage_id: 191,
		part_id: 45,
		quantity: 298,
	},
	{
		garage_id: 191,
		part_id: 46,
		quantity: 317,
	},
	{
		garage_id: 191,
		part_id: 47,
		quantity: 339,
	},
	{
		garage_id: 191,
		part_id: 48,
		quantity: 113,
	},
	{
		garage_id: 191,
		part_id: 49,
		quantity: 57,
	},
	{
		garage_id: 191,
		part_id: 50,
		quantity: 116,
	},
	{
		garage_id: 191,
		part_id: 51,
		quantity: 91,
	},
	{
		garage_id: 191,
		part_id: 52,
		quantity: 67,
	},
	{
		garage_id: 191,
		part_id: 53,
		quantity: 41,
	},
	{
		garage_id: 191,
		part_id: 54,
		quantity: 119,
	},
	{
		garage_id: 191,
		part_id: 55,
		quantity: 218,
	},
	{
		garage_id: 191,
		part_id: 56,
		quantity: 134,
	},
	{
		garage_id: 191,
		part_id: 57,
		quantity: 158,
	},
	{
		garage_id: 191,
		part_id: 58,
		quantity: 391,
	},
	{
		garage_id: 191,
		part_id: 59,
		quantity: 4,
	},
	{
		garage_id: 191,
		part_id: 60,
		quantity: 367,
	},
	{
		garage_id: 191,
		part_id: 61,
		quantity: 311,
	},
	{
		garage_id: 191,
		part_id: 62,
		quantity: 314,
	},
	{
		garage_id: 191,
		part_id: 63,
		quantity: 9,
	},
	{
		garage_id: 191,
		part_id: 64,
		quantity: 139,
	},
	{
		garage_id: 191,
		part_id: 65,
		quantity: 488,
	},
	{
		garage_id: 191,
		part_id: 66,
		quantity: 121,
	},
	{
		garage_id: 191,
		part_id: 67,
		quantity: 294,
	},
	{
		garage_id: 191,
		part_id: 68,
		quantity: 353,
	},
	{
		garage_id: 191,
		part_id: 69,
		quantity: 267,
	},
	{
		garage_id: 191,
		part_id: 70,
		quantity: 67,
	},
	{
		garage_id: 191,
		part_id: 71,
		quantity: 370,
	},
	{
		garage_id: 191,
		part_id: 72,
		quantity: 159,
	},
	{
		garage_id: 191,
		part_id: 73,
		quantity: 238,
	},
	{
		garage_id: 191,
		part_id: 74,
		quantity: 51,
	},
	{
		garage_id: 191,
		part_id: 75,
		quantity: 51,
	},
	{
		garage_id: 191,
		part_id: 76,
		quantity: 313,
	},
	{
		garage_id: 191,
		part_id: 77,
		quantity: 77,
	},
	{
		garage_id: 191,
		part_id: 78,
		quantity: 500,
	},
	{
		garage_id: 191,
		part_id: 79,
		quantity: 150,
	},
	{
		garage_id: 191,
		part_id: 80,
		quantity: 462,
	},
	{
		garage_id: 191,
		part_id: 81,
		quantity: 278,
	},
	{
		garage_id: 191,
		part_id: 82,
		quantity: 186,
	},
	{
		garage_id: 191,
		part_id: 83,
		quantity: 456,
	},
	{
		garage_id: 191,
		part_id: 84,
		quantity: 314,
	},
	{
		garage_id: 191,
		part_id: 85,
		quantity: 66,
	},
	{
		garage_id: 191,
		part_id: 86,
		quantity: 102,
	},
	{
		garage_id: 191,
		part_id: 87,
		quantity: 468,
	},
	{
		garage_id: 191,
		part_id: 88,
		quantity: 447,
	},
	{
		garage_id: 191,
		part_id: 89,
		quantity: 292,
	},
	{
		garage_id: 191,
		part_id: 90,
		quantity: 149,
	},
	{
		garage_id: 191,
		part_id: 91,
		quantity: 18,
	},
	{
		garage_id: 191,
		part_id: 92,
		quantity: 90,
	},
	{
		garage_id: 191,
		part_id: 93,
		quantity: 270,
	},
	{
		garage_id: 191,
		part_id: 94,
		quantity: 397,
	},
	{
		garage_id: 191,
		part_id: 95,
		quantity: 329,
	},
	{
		garage_id: 191,
		part_id: 96,
		quantity: 72,
	},
	{
		garage_id: 191,
		part_id: 97,
		quantity: 194,
	},
	{
		garage_id: 191,
		part_id: 98,
		quantity: 319,
	},
	{
		garage_id: 191,
		part_id: 99,
		quantity: 357,
	},
	{
		garage_id: 191,
		part_id: 100,
		quantity: 259,
	},
	{
		garage_id: 191,
		part_id: 101,
		quantity: 141,
	},
	{
		garage_id: 191,
		part_id: 102,
		quantity: 246,
	},
	{
		garage_id: 191,
		part_id: 103,
		quantity: 87,
	},
	{
		garage_id: 191,
		part_id: 104,
		quantity: 344,
	},
	{
		garage_id: 191,
		part_id: 105,
		quantity: 412,
	},
	{
		garage_id: 191,
		part_id: 106,
		quantity: 402,
	},
	{
		garage_id: 191,
		part_id: 107,
		quantity: 280,
	},
	{
		garage_id: 191,
		part_id: 108,
		quantity: 407,
	},
	{
		garage_id: 191,
		part_id: 109,
		quantity: 45,
	},
	{
		garage_id: 191,
		part_id: 110,
		quantity: 53,
	},
	{
		garage_id: 191,
		part_id: 111,
		quantity: 486,
	},
	{
		garage_id: 191,
		part_id: 112,
		quantity: 222,
	},
	{
		garage_id: 191,
		part_id: 113,
		quantity: 409,
	},
	{
		garage_id: 191,
		part_id: 114,
		quantity: 400,
	},
	{
		garage_id: 191,
		part_id: 115,
		quantity: 272,
	},
	{
		garage_id: 191,
		part_id: 116,
		quantity: 52,
	},
	{
		garage_id: 191,
		part_id: 117,
		quantity: 231,
	},
	{
		garage_id: 191,
		part_id: 118,
		quantity: 287,
	},
	{
		garage_id: 191,
		part_id: 119,
		quantity: 210,
	},
	{
		garage_id: 191,
		part_id: 120,
		quantity: 491,
	},
	{
		garage_id: 191,
		part_id: 121,
		quantity: 168,
	},
	{
		garage_id: 191,
		part_id: 122,
		quantity: 435,
	},
	{
		garage_id: 191,
		part_id: 123,
		quantity: 259,
	},
	{
		garage_id: 191,
		part_id: 124,
		quantity: 233,
	},
	{
		garage_id: 191,
		part_id: 125,
		quantity: 100,
	},
	{
		garage_id: 191,
		part_id: 126,
		quantity: 363,
	},
	{
		garage_id: 191,
		part_id: 127,
		quantity: 236,
	},
	{
		garage_id: 191,
		part_id: 128,
		quantity: 286,
	},
	{
		garage_id: 191,
		part_id: 129,
		quantity: 461,
	},
	{
		garage_id: 191,
		part_id: 130,
		quantity: 51,
	},
	{
		garage_id: 191,
		part_id: 131,
		quantity: 147,
	},
	{
		garage_id: 191,
		part_id: 132,
		quantity: 329,
	},
	{
		garage_id: 191,
		part_id: 133,
		quantity: 321,
	},
	{
		garage_id: 191,
		part_id: 134,
		quantity: 422,
	},
	{
		garage_id: 191,
		part_id: 135,
		quantity: 270,
	},
	{
		garage_id: 191,
		part_id: 136,
		quantity: 115,
	},
	{
		garage_id: 191,
		part_id: 137,
		quantity: 81,
	},
	{
		garage_id: 191,
		part_id: 138,
		quantity: 174,
	},
	{
		garage_id: 191,
		part_id: 139,
		quantity: 357,
	},
	{
		garage_id: 191,
		part_id: 140,
		quantity: 194,
	},
	{
		garage_id: 191,
		part_id: 141,
		quantity: 426,
	},
	{
		garage_id: 191,
		part_id: 142,
		quantity: 172,
	},
	{
		garage_id: 191,
		part_id: 143,
		quantity: 234,
	},
	{
		garage_id: 191,
		part_id: 144,
		quantity: 292,
	},
	{
		garage_id: 191,
		part_id: 145,
		quantity: 221,
	},
	{
		garage_id: 191,
		part_id: 146,
		quantity: 480,
	},
	{
		garage_id: 191,
		part_id: 147,
		quantity: 82,
	},
	{
		garage_id: 191,
		part_id: 148,
		quantity: 257,
	},
	{
		garage_id: 191,
		part_id: 149,
		quantity: 138,
	},
	{
		garage_id: 191,
		part_id: 150,
		quantity: 177,
	},
	{
		garage_id: 191,
		part_id: 151,
		quantity: 158,
	},
	{
		garage_id: 191,
		part_id: 152,
		quantity: 129,
	},
	{
		garage_id: 191,
		part_id: 153,
		quantity: 477,
	},
	{
		garage_id: 191,
		part_id: 154,
		quantity: 206,
	},
	{
		garage_id: 191,
		part_id: 155,
		quantity: 168,
	},
	{
		garage_id: 191,
		part_id: 156,
		quantity: 356,
	},
	{
		garage_id: 191,
		part_id: 157,
		quantity: 499,
	},
	{
		garage_id: 191,
		part_id: 158,
		quantity: 457,
	},
	{
		garage_id: 191,
		part_id: 159,
		quantity: 309,
	},
	{
		garage_id: 191,
		part_id: 160,
		quantity: 186,
	},
	{
		garage_id: 191,
		part_id: 161,
		quantity: 146,
	},
	{
		garage_id: 191,
		part_id: 162,
		quantity: 406,
	},
	{
		garage_id: 191,
		part_id: 163,
		quantity: 362,
	},
	{
		garage_id: 191,
		part_id: 164,
		quantity: 235,
	},
	{
		garage_id: 191,
		part_id: 165,
		quantity: 248,
	},
	{
		garage_id: 191,
		part_id: 166,
		quantity: 324,
	},
	{
		garage_id: 191,
		part_id: 167,
		quantity: 177,
	},
	{
		garage_id: 191,
		part_id: 168,
		quantity: 172,
	},
	{
		garage_id: 191,
		part_id: 169,
		quantity: 7,
	},
	{
		garage_id: 191,
		part_id: 170,
		quantity: 269,
	},
	{
		garage_id: 191,
		part_id: 171,
		quantity: 201,
	},
	{
		garage_id: 191,
		part_id: 172,
		quantity: 80,
	},
	{
		garage_id: 191,
		part_id: 173,
		quantity: 223,
	},
	{
		garage_id: 191,
		part_id: 174,
		quantity: 475,
	},
	{
		garage_id: 191,
		part_id: 175,
		quantity: 53,
	},
	{
		garage_id: 191,
		part_id: 176,
		quantity: 158,
	},
	{
		garage_id: 191,
		part_id: 177,
		quantity: 412,
	},
	{
		garage_id: 191,
		part_id: 178,
		quantity: 22,
	},
	{
		garage_id: 191,
		part_id: 179,
		quantity: 152,
	},
	{
		garage_id: 191,
		part_id: 180,
		quantity: 155,
	},
	{
		garage_id: 191,
		part_id: 181,
		quantity: 277,
	},
	{
		garage_id: 191,
		part_id: 182,
		quantity: 378,
	},
	{
		garage_id: 191,
		part_id: 183,
		quantity: 166,
	},
	{
		garage_id: 191,
		part_id: 184,
		quantity: 379,
	},
	{
		garage_id: 191,
		part_id: 185,
		quantity: 1,
	},
	{
		garage_id: 191,
		part_id: 186,
		quantity: 81,
	},
	{
		garage_id: 191,
		part_id: 187,
		quantity: 428,
	},
	{
		garage_id: 191,
		part_id: 188,
		quantity: 377,
	},
	{
		garage_id: 191,
		part_id: 189,
		quantity: 35,
	},
	{
		garage_id: 191,
		part_id: 190,
		quantity: 161,
	},
	{
		garage_id: 191,
		part_id: 191,
		quantity: 455,
	},
	{
		garage_id: 191,
		part_id: 192,
		quantity: 130,
	},
	{
		garage_id: 191,
		part_id: 193,
		quantity: 172,
	},
	{
		garage_id: 191,
		part_id: 194,
		quantity: 23,
	},
	{
		garage_id: 191,
		part_id: 195,
		quantity: 115,
	},
	{
		garage_id: 191,
		part_id: 196,
		quantity: 22,
	},
	{
		garage_id: 191,
		part_id: 197,
		quantity: 287,
	},
	{
		garage_id: 191,
		part_id: 198,
		quantity: 302,
	},
	{
		garage_id: 191,
		part_id: 199,
		quantity: 72,
	},
	{
		garage_id: 191,
		part_id: 200,
		quantity: 433,
	},
	{
		garage_id: 191,
		part_id: 201,
		quantity: 407,
	},
	{
		garage_id: 191,
		part_id: 202,
		quantity: 373,
	},
	{
		garage_id: 191,
		part_id: 203,
		quantity: 353,
	},
	{
		garage_id: 191,
		part_id: 204,
		quantity: 381,
	},
	{
		garage_id: 191,
		part_id: 205,
		quantity: 106,
	},
	{
		garage_id: 191,
		part_id: 206,
		quantity: 451,
	},
	{
		garage_id: 191,
		part_id: 207,
		quantity: 118,
	},
	{
		garage_id: 191,
		part_id: 208,
		quantity: 445,
	},
	{
		garage_id: 191,
		part_id: 209,
		quantity: 52,
	},
	{
		garage_id: 191,
		part_id: 210,
		quantity: 355,
	},
	{
		garage_id: 191,
		part_id: 211,
		quantity: 317,
	},
	{
		garage_id: 191,
		part_id: 212,
		quantity: 247,
	},
	{
		garage_id: 191,
		part_id: 213,
		quantity: 51,
	},
	{
		garage_id: 191,
		part_id: 214,
		quantity: 7,
	},
	{
		garage_id: 191,
		part_id: 215,
		quantity: 389,
	},
	{
		garage_id: 191,
		part_id: 216,
		quantity: 189,
	},
	{
		garage_id: 191,
		part_id: 217,
		quantity: 472,
	},
	{
		garage_id: 191,
		part_id: 218,
		quantity: 43,
	},
	{
		garage_id: 191,
		part_id: 219,
		quantity: 475,
	},
	{
		garage_id: 191,
		part_id: 220,
		quantity: 376,
	},
	{
		garage_id: 191,
		part_id: 221,
		quantity: 367,
	},
	{
		garage_id: 191,
		part_id: 222,
		quantity: 6,
	},
	{
		garage_id: 191,
		part_id: 223,
		quantity: 274,
	},
	{
		garage_id: 191,
		part_id: 224,
		quantity: 219,
	},
	{
		garage_id: 191,
		part_id: 225,
		quantity: 320,
	},
	{
		garage_id: 191,
		part_id: 226,
		quantity: 348,
	},
	{
		garage_id: 191,
		part_id: 227,
		quantity: 10,
	},
	{
		garage_id: 191,
		part_id: 228,
		quantity: 104,
	},
	{
		garage_id: 191,
		part_id: 229,
		quantity: 114,
	},
	{
		garage_id: 191,
		part_id: 230,
		quantity: 56,
	},
	{
		garage_id: 191,
		part_id: 231,
		quantity: 354,
	},
	{
		garage_id: 191,
		part_id: 232,
		quantity: 169,
	},
	{
		garage_id: 191,
		part_id: 233,
		quantity: 241,
	},
	{
		garage_id: 191,
		part_id: 234,
		quantity: 135,
	},
	{
		garage_id: 191,
		part_id: 235,
		quantity: 257,
	},
	{
		garage_id: 191,
		part_id: 236,
		quantity: 426,
	},
	{
		garage_id: 191,
		part_id: 237,
		quantity: 355,
	},
	{
		garage_id: 191,
		part_id: 238,
		quantity: 422,
	},
	{
		garage_id: 191,
		part_id: 239,
		quantity: 85,
	},
	{
		garage_id: 191,
		part_id: 240,
		quantity: 273,
	},
	{
		garage_id: 191,
		part_id: 241,
		quantity: 484,
	},
	{
		garage_id: 191,
		part_id: 242,
		quantity: 459,
	},
	{
		garage_id: 191,
		part_id: 243,
		quantity: 12,
	},
	{
		garage_id: 191,
		part_id: 244,
		quantity: 63,
	},
	{
		garage_id: 191,
		part_id: 245,
		quantity: 329,
	},
	{
		garage_id: 191,
		part_id: 246,
		quantity: 12,
	},
	{
		garage_id: 191,
		part_id: 247,
		quantity: 350,
	},
	{
		garage_id: 191,
		part_id: 248,
		quantity: 164,
	},
	{
		garage_id: 191,
		part_id: 249,
		quantity: 174,
	},
	{
		garage_id: 191,
		part_id: 250,
		quantity: 130,
	},
	{
		garage_id: 191,
		part_id: 251,
		quantity: 132,
	},
	{
		garage_id: 191,
		part_id: 252,
		quantity: 329,
	},
	{
		garage_id: 191,
		part_id: 253,
		quantity: 131,
	},
	{
		garage_id: 191,
		part_id: 254,
		quantity: 339,
	},
	{
		garage_id: 191,
		part_id: 255,
		quantity: 247,
	},
	{
		garage_id: 191,
		part_id: 256,
		quantity: 48,
	},
	{
		garage_id: 191,
		part_id: 257,
		quantity: 233,
	},
	{
		garage_id: 191,
		part_id: 258,
		quantity: 480,
	},
	{
		garage_id: 191,
		part_id: 259,
		quantity: 49,
	},
	{
		garage_id: 191,
		part_id: 260,
		quantity: 137,
	},
	{
		garage_id: 191,
		part_id: 261,
		quantity: 225,
	},
	{
		garage_id: 191,
		part_id: 262,
		quantity: 428,
	},
	{
		garage_id: 191,
		part_id: 263,
		quantity: 129,
	},
	{
		garage_id: 191,
		part_id: 264,
		quantity: 77,
	},
	{
		garage_id: 191,
		part_id: 265,
		quantity: 202,
	},
	{
		garage_id: 191,
		part_id: 266,
		quantity: 368,
	},
	{
		garage_id: 191,
		part_id: 267,
		quantity: 186,
	},
	{
		garage_id: 191,
		part_id: 268,
		quantity: 340,
	},
	{
		garage_id: 191,
		part_id: 269,
		quantity: 203,
	},
	{
		garage_id: 191,
		part_id: 270,
		quantity: 324,
	},
	{
		garage_id: 191,
		part_id: 271,
		quantity: 26,
	},
	{
		garage_id: 191,
		part_id: 272,
		quantity: 284,
	},
	{
		garage_id: 191,
		part_id: 273,
		quantity: 10,
	},
	{
		garage_id: 191,
		part_id: 274,
		quantity: 25,
	},
	{
		garage_id: 191,
		part_id: 275,
		quantity: 289,
	},
	{
		garage_id: 191,
		part_id: 276,
		quantity: 45,
	},
	{
		garage_id: 191,
		part_id: 277,
		quantity: 365,
	},
	{
		garage_id: 191,
		part_id: 278,
		quantity: 314,
	},
	{
		garage_id: 191,
		part_id: 279,
		quantity: 121,
	},
	{
		garage_id: 191,
		part_id: 280,
		quantity: 329,
	},
	{
		garage_id: 191,
		part_id: 281,
		quantity: 246,
	},
	{
		garage_id: 191,
		part_id: 282,
		quantity: 85,
	},
	{
		garage_id: 191,
		part_id: 283,
		quantity: 176,
	},
	{
		garage_id: 191,
		part_id: 284,
		quantity: 210,
	},
	{
		garage_id: 191,
		part_id: 285,
		quantity: 327,
	},
	{
		garage_id: 191,
		part_id: 286,
		quantity: 143,
	},
	{
		garage_id: 191,
		part_id: 287,
		quantity: 322,
	},
	{
		garage_id: 191,
		part_id: 288,
		quantity: 362,
	},
	{
		garage_id: 191,
		part_id: 289,
		quantity: 389,
	},
	{
		garage_id: 191,
		part_id: 290,
		quantity: 495,
	},
	{
		garage_id: 191,
		part_id: 291,
		quantity: 360,
	},
	{
		garage_id: 191,
		part_id: 292,
		quantity: 286,
	},
	{
		garage_id: 191,
		part_id: 293,
		quantity: 353,
	},
	{
		garage_id: 191,
		part_id: 294,
		quantity: 42,
	},
	{
		garage_id: 191,
		part_id: 295,
		quantity: 119,
	},
	{
		garage_id: 191,
		part_id: 296,
		quantity: 214,
	},
	{
		garage_id: 191,
		part_id: 297,
		quantity: 305,
	},
	{
		garage_id: 191,
		part_id: 298,
		quantity: 370,
	},
	{
		garage_id: 191,
		part_id: 299,
		quantity: 485,
	},
	{
		garage_id: 191,
		part_id: 300,
		quantity: 240,
	},
	{
		garage_id: 191,
		part_id: 301,
		quantity: 209,
	},
	{
		garage_id: 191,
		part_id: 302,
		quantity: 16,
	},
	{
		garage_id: 191,
		part_id: 303,
		quantity: 90,
	},
	{
		garage_id: 191,
		part_id: 304,
		quantity: 366,
	},
	{
		garage_id: 191,
		part_id: 305,
		quantity: 191,
	},
	{
		garage_id: 191,
		part_id: 306,
		quantity: 155,
	},
	{
		garage_id: 191,
		part_id: 307,
		quantity: 451,
	},
	{
		garage_id: 191,
		part_id: 308,
		quantity: 351,
	},
	{
		garage_id: 191,
		part_id: 309,
		quantity: 381,
	},
	{
		garage_id: 191,
		part_id: 310,
		quantity: 337,
	},
	{
		garage_id: 191,
		part_id: 311,
		quantity: 220,
	},
	{
		garage_id: 191,
		part_id: 312,
		quantity: 8,
	},
	{
		garage_id: 191,
		part_id: 313,
		quantity: 143,
	},
	{
		garage_id: 191,
		part_id: 314,
		quantity: 46,
	},
	{
		garage_id: 191,
		part_id: 315,
		quantity: 16,
	},
	{
		garage_id: 191,
		part_id: 316,
		quantity: 338,
	},
	{
		garage_id: 191,
		part_id: 317,
		quantity: 55,
	},
	{
		garage_id: 191,
		part_id: 318,
		quantity: 93,
	},
	{
		garage_id: 191,
		part_id: 319,
		quantity: 427,
	},
	{
		garage_id: 191,
		part_id: 320,
		quantity: 444,
	},
	{
		garage_id: 191,
		part_id: 321,
		quantity: 389,
	},
	{
		garage_id: 191,
		part_id: 322,
		quantity: 226,
	},
	{
		garage_id: 191,
		part_id: 323,
		quantity: 253,
	},
	{
		garage_id: 191,
		part_id: 324,
		quantity: 327,
	},
	{
		garage_id: 191,
		part_id: 325,
		quantity: 330,
	},
	{
		garage_id: 191,
		part_id: 326,
		quantity: 224,
	},
	{
		garage_id: 191,
		part_id: 327,
		quantity: 323,
	},
	{
		garage_id: 191,
		part_id: 328,
		quantity: 244,
	},
	{
		garage_id: 191,
		part_id: 329,
		quantity: 93,
	},
	{
		garage_id: 191,
		part_id: 330,
		quantity: 376,
	},
	{
		garage_id: 191,
		part_id: 331,
		quantity: 440,
	},
	{
		garage_id: 191,
		part_id: 332,
		quantity: 311,
	},
	{
		garage_id: 191,
		part_id: 333,
		quantity: 202,
	},
	{
		garage_id: 191,
		part_id: 334,
		quantity: 350,
	},
	{
		garage_id: 191,
		part_id: 335,
		quantity: 116,
	},
	{
		garage_id: 191,
		part_id: 336,
		quantity: 229,
	},
	{
		garage_id: 191,
		part_id: 337,
		quantity: 389,
	},
	{
		garage_id: 191,
		part_id: 338,
		quantity: 41,
	},
	{
		garage_id: 191,
		part_id: 339,
		quantity: 197,
	},
	{
		garage_id: 191,
		part_id: 340,
		quantity: 64,
	},
	{
		garage_id: 191,
		part_id: 341,
		quantity: 293,
	},
	{
		garage_id: 191,
		part_id: 342,
		quantity: 334,
	},
	{
		garage_id: 191,
		part_id: 343,
		quantity: 297,
	},
	{
		garage_id: 191,
		part_id: 344,
		quantity: 193,
	},
	{
		garage_id: 191,
		part_id: 345,
		quantity: 308,
	},
	{
		garage_id: 191,
		part_id: 346,
		quantity: 224,
	},
	{
		garage_id: 191,
		part_id: 347,
		quantity: 91,
	},
	{
		garage_id: 191,
		part_id: 348,
		quantity: 148,
	},
	{
		garage_id: 191,
		part_id: 349,
		quantity: 436,
	},
	{
		garage_id: 191,
		part_id: 350,
		quantity: 239,
	},
	{
		garage_id: 191,
		part_id: 351,
		quantity: 100,
	},
	{
		garage_id: 191,
		part_id: 352,
		quantity: 322,
	},
	{
		garage_id: 191,
		part_id: 353,
		quantity: 492,
	},
	{
		garage_id: 191,
		part_id: 354,
		quantity: 432,
	},
	{
		garage_id: 191,
		part_id: 355,
		quantity: 439,
	},
	{
		garage_id: 191,
		part_id: 356,
		quantity: 170,
	},
	{
		garage_id: 191,
		part_id: 357,
		quantity: 340,
	},
	{
		garage_id: 191,
		part_id: 358,
		quantity: 490,
	},
	{
		garage_id: 191,
		part_id: 359,
		quantity: 463,
	},
	{
		garage_id: 191,
		part_id: 360,
		quantity: 50,
	},
	{
		garage_id: 191,
		part_id: 361,
		quantity: 491,
	},
	{
		garage_id: 191,
		part_id: 362,
		quantity: 287,
	},
	{
		garage_id: 191,
		part_id: 363,
		quantity: 152,
	},
	{
		garage_id: 191,
		part_id: 364,
		quantity: 398,
	},
	{
		garage_id: 191,
		part_id: 365,
		quantity: 7,
	},
	{
		garage_id: 191,
		part_id: 366,
		quantity: 380,
	},
	{
		garage_id: 191,
		part_id: 367,
		quantity: 231,
	},
	{
		garage_id: 191,
		part_id: 368,
		quantity: 408,
	},
	{
		garage_id: 191,
		part_id: 369,
		quantity: 240,
	},
	{
		garage_id: 191,
		part_id: 370,
		quantity: 65,
	},
	{
		garage_id: 191,
		part_id: 371,
		quantity: 404,
	},
	{
		garage_id: 191,
		part_id: 372,
		quantity: 213,
	},
	{
		garage_id: 191,
		part_id: 373,
		quantity: 244,
	},
	{
		garage_id: 191,
		part_id: 374,
		quantity: 397,
	},
	{
		garage_id: 191,
		part_id: 375,
		quantity: 388,
	},
	{
		garage_id: 191,
		part_id: 376,
		quantity: 58,
	},
	{
		garage_id: 191,
		part_id: 377,
		quantity: 39,
	},
	{
		garage_id: 191,
		part_id: 378,
		quantity: 454,
	},
	{
		garage_id: 191,
		part_id: 379,
		quantity: 471,
	},
	{
		garage_id: 191,
		part_id: 380,
		quantity: 442,
	},
	{
		garage_id: 191,
		part_id: 381,
		quantity: 261,
	},
	{
		garage_id: 191,
		part_id: 382,
		quantity: 139,
	},
	{
		garage_id: 191,
		part_id: 383,
		quantity: 435,
	},
	{
		garage_id: 191,
		part_id: 384,
		quantity: 198,
	},
	{
		garage_id: 191,
		part_id: 385,
		quantity: 467,
	},
	{
		garage_id: 191,
		part_id: 386,
		quantity: 27,
	},
	{
		garage_id: 191,
		part_id: 387,
		quantity: 190,
	},
	{
		garage_id: 191,
		part_id: 388,
		quantity: 254,
	},
	{
		garage_id: 191,
		part_id: 389,
		quantity: 216,
	},
	{
		garage_id: 191,
		part_id: 390,
		quantity: 339,
	},
	{
		garage_id: 191,
		part_id: 391,
		quantity: 106,
	},
	{
		garage_id: 191,
		part_id: 392,
		quantity: 161,
	},
	{
		garage_id: 191,
		part_id: 393,
		quantity: 250,
	},
	{
		garage_id: 191,
		part_id: 394,
		quantity: 142,
	},
	{
		garage_id: 191,
		part_id: 395,
		quantity: 308,
	},
	{
		garage_id: 191,
		part_id: 396,
		quantity: 3,
	},
	{
		garage_id: 191,
		part_id: 397,
		quantity: 43,
	},
	{
		garage_id: 191,
		part_id: 398,
		quantity: 389,
	},
	{
		garage_id: 191,
		part_id: 399,
		quantity: 209,
	},
	{
		garage_id: 192,
		part_id: 0,
		quantity: 83,
	},
	{
		garage_id: 192,
		part_id: 1,
		quantity: 220,
	},
	{
		garage_id: 192,
		part_id: 2,
		quantity: 266,
	},
	{
		garage_id: 192,
		part_id: 3,
		quantity: 274,
	},
	{
		garage_id: 192,
		part_id: 4,
		quantity: 361,
	},
	{
		garage_id: 192,
		part_id: 5,
		quantity: 493,
	},
	{
		garage_id: 192,
		part_id: 6,
		quantity: 340,
	},
	{
		garage_id: 192,
		part_id: 7,
		quantity: 338,
	},
	{
		garage_id: 192,
		part_id: 8,
		quantity: 259,
	},
	{
		garage_id: 192,
		part_id: 9,
		quantity: 327,
	},
	{
		garage_id: 192,
		part_id: 10,
		quantity: 221,
	},
	{
		garage_id: 192,
		part_id: 11,
		quantity: 185,
	},
	{
		garage_id: 192,
		part_id: 12,
		quantity: 399,
	},
	{
		garage_id: 192,
		part_id: 13,
		quantity: 300,
	},
	{
		garage_id: 192,
		part_id: 14,
		quantity: 203,
	},
	{
		garage_id: 192,
		part_id: 15,
		quantity: 288,
	},
	{
		garage_id: 192,
		part_id: 16,
		quantity: 56,
	},
	{
		garage_id: 192,
		part_id: 17,
		quantity: 86,
	},
	{
		garage_id: 192,
		part_id: 18,
		quantity: 111,
	},
	{
		garage_id: 192,
		part_id: 19,
		quantity: 290,
	},
	{
		garage_id: 192,
		part_id: 20,
		quantity: 141,
	},
	{
		garage_id: 192,
		part_id: 21,
		quantity: 125,
	},
	{
		garage_id: 192,
		part_id: 22,
		quantity: 11,
	},
	{
		garage_id: 192,
		part_id: 23,
		quantity: 50,
	},
	{
		garage_id: 192,
		part_id: 24,
		quantity: 241,
	},
	{
		garage_id: 192,
		part_id: 25,
		quantity: 78,
	},
	{
		garage_id: 192,
		part_id: 26,
		quantity: 39,
	},
	{
		garage_id: 192,
		part_id: 27,
		quantity: 140,
	},
	{
		garage_id: 192,
		part_id: 28,
		quantity: 478,
	},
	{
		garage_id: 192,
		part_id: 29,
		quantity: 441,
	},
	{
		garage_id: 192,
		part_id: 30,
		quantity: 153,
	},
	{
		garage_id: 192,
		part_id: 31,
		quantity: 280,
	},
	{
		garage_id: 192,
		part_id: 32,
		quantity: 212,
	},
	{
		garage_id: 192,
		part_id: 33,
		quantity: 222,
	},
	{
		garage_id: 192,
		part_id: 34,
		quantity: 17,
	},
	{
		garage_id: 192,
		part_id: 35,
		quantity: 372,
	},
	{
		garage_id: 192,
		part_id: 36,
		quantity: 452,
	},
	{
		garage_id: 192,
		part_id: 37,
		quantity: 271,
	},
	{
		garage_id: 192,
		part_id: 38,
		quantity: 329,
	},
	{
		garage_id: 192,
		part_id: 39,
		quantity: 118,
	},
	{
		garage_id: 192,
		part_id: 40,
		quantity: 239,
	},
	{
		garage_id: 192,
		part_id: 41,
		quantity: 20,
	},
	{
		garage_id: 192,
		part_id: 42,
		quantity: 137,
	},
	{
		garage_id: 192,
		part_id: 43,
		quantity: 345,
	},
	{
		garage_id: 192,
		part_id: 44,
		quantity: 481,
	},
	{
		garage_id: 192,
		part_id: 45,
		quantity: 125,
	},
	{
		garage_id: 192,
		part_id: 46,
		quantity: 16,
	},
	{
		garage_id: 192,
		part_id: 47,
		quantity: 153,
	},
	{
		garage_id: 192,
		part_id: 48,
		quantity: 42,
	},
	{
		garage_id: 192,
		part_id: 49,
		quantity: 191,
	},
	{
		garage_id: 192,
		part_id: 50,
		quantity: 456,
	},
	{
		garage_id: 192,
		part_id: 51,
		quantity: 54,
	},
	{
		garage_id: 192,
		part_id: 52,
		quantity: 196,
	},
	{
		garage_id: 192,
		part_id: 53,
		quantity: 483,
	},
	{
		garage_id: 192,
		part_id: 54,
		quantity: 258,
	},
	{
		garage_id: 192,
		part_id: 55,
		quantity: 456,
	},
	{
		garage_id: 192,
		part_id: 56,
		quantity: 14,
	},
	{
		garage_id: 192,
		part_id: 57,
		quantity: 443,
	},
	{
		garage_id: 192,
		part_id: 58,
		quantity: 147,
	},
	{
		garage_id: 192,
		part_id: 59,
		quantity: 276,
	},
	{
		garage_id: 192,
		part_id: 60,
		quantity: 215,
	},
	{
		garage_id: 192,
		part_id: 61,
		quantity: 474,
	},
	{
		garage_id: 192,
		part_id: 62,
		quantity: 94,
	},
	{
		garage_id: 192,
		part_id: 63,
		quantity: 426,
	},
	{
		garage_id: 192,
		part_id: 64,
		quantity: 292,
	},
	{
		garage_id: 192,
		part_id: 65,
		quantity: 256,
	},
	{
		garage_id: 192,
		part_id: 66,
		quantity: 296,
	},
	{
		garage_id: 192,
		part_id: 67,
		quantity: 457,
	},
	{
		garage_id: 192,
		part_id: 68,
		quantity: 296,
	},
	{
		garage_id: 192,
		part_id: 69,
		quantity: 285,
	},
	{
		garage_id: 192,
		part_id: 70,
		quantity: 208,
	},
	{
		garage_id: 192,
		part_id: 71,
		quantity: 324,
	},
	{
		garage_id: 192,
		part_id: 72,
		quantity: 453,
	},
	{
		garage_id: 192,
		part_id: 73,
		quantity: 327,
	},
	{
		garage_id: 192,
		part_id: 74,
		quantity: 379,
	},
	{
		garage_id: 192,
		part_id: 75,
		quantity: 63,
	},
	{
		garage_id: 192,
		part_id: 76,
		quantity: 398,
	},
	{
		garage_id: 192,
		part_id: 77,
		quantity: 435,
	},
	{
		garage_id: 192,
		part_id: 78,
		quantity: 62,
	},
	{
		garage_id: 192,
		part_id: 79,
		quantity: 21,
	},
	{
		garage_id: 192,
		part_id: 80,
		quantity: 226,
	},
	{
		garage_id: 192,
		part_id: 81,
		quantity: 214,
	},
	{
		garage_id: 192,
		part_id: 82,
		quantity: 223,
	},
	{
		garage_id: 192,
		part_id: 83,
		quantity: 126,
	},
	{
		garage_id: 192,
		part_id: 84,
		quantity: 111,
	},
	{
		garage_id: 192,
		part_id: 85,
		quantity: 26,
	},
	{
		garage_id: 192,
		part_id: 86,
		quantity: 32,
	},
	{
		garage_id: 192,
		part_id: 87,
		quantity: 196,
	},
	{
		garage_id: 192,
		part_id: 88,
		quantity: 45,
	},
	{
		garage_id: 192,
		part_id: 89,
		quantity: 35,
	},
	{
		garage_id: 192,
		part_id: 90,
		quantity: 400,
	},
	{
		garage_id: 192,
		part_id: 91,
		quantity: 500,
	},
	{
		garage_id: 192,
		part_id: 92,
		quantity: 374,
	},
	{
		garage_id: 192,
		part_id: 93,
		quantity: 307,
	},
	{
		garage_id: 192,
		part_id: 94,
		quantity: 460,
	},
	{
		garage_id: 192,
		part_id: 95,
		quantity: 425,
	},
	{
		garage_id: 192,
		part_id: 96,
		quantity: 455,
	},
	{
		garage_id: 192,
		part_id: 97,
		quantity: 319,
	},
	{
		garage_id: 192,
		part_id: 98,
		quantity: 407,
	},
	{
		garage_id: 192,
		part_id: 99,
		quantity: 373,
	},
	{
		garage_id: 192,
		part_id: 100,
		quantity: 154,
	},
	{
		garage_id: 192,
		part_id: 101,
		quantity: 157,
	},
	{
		garage_id: 192,
		part_id: 102,
		quantity: 222,
	},
	{
		garage_id: 192,
		part_id: 103,
		quantity: 371,
	},
	{
		garage_id: 192,
		part_id: 104,
		quantity: 60,
	},
	{
		garage_id: 192,
		part_id: 105,
		quantity: 145,
	},
	{
		garage_id: 192,
		part_id: 106,
		quantity: 189,
	},
	{
		garage_id: 192,
		part_id: 107,
		quantity: 198,
	},
	{
		garage_id: 192,
		part_id: 108,
		quantity: 466,
	},
	{
		garage_id: 192,
		part_id: 109,
		quantity: 447,
	},
	{
		garage_id: 192,
		part_id: 110,
		quantity: 45,
	},
	{
		garage_id: 192,
		part_id: 111,
		quantity: 254,
	},
	{
		garage_id: 192,
		part_id: 112,
		quantity: 75,
	},
	{
		garage_id: 192,
		part_id: 113,
		quantity: 414,
	},
	{
		garage_id: 192,
		part_id: 114,
		quantity: 88,
	},
	{
		garage_id: 192,
		part_id: 115,
		quantity: 50,
	},
	{
		garage_id: 192,
		part_id: 116,
		quantity: 312,
	},
	{
		garage_id: 192,
		part_id: 117,
		quantity: 194,
	},
	{
		garage_id: 192,
		part_id: 118,
		quantity: 372,
	},
	{
		garage_id: 192,
		part_id: 119,
		quantity: 266,
	},
	{
		garage_id: 192,
		part_id: 120,
		quantity: 451,
	},
	{
		garage_id: 192,
		part_id: 121,
		quantity: 473,
	},
	{
		garage_id: 192,
		part_id: 122,
		quantity: 35,
	},
	{
		garage_id: 192,
		part_id: 123,
		quantity: 331,
	},
	{
		garage_id: 192,
		part_id: 124,
		quantity: 4,
	},
	{
		garage_id: 192,
		part_id: 125,
		quantity: 377,
	},
	{
		garage_id: 192,
		part_id: 126,
		quantity: 55,
	},
	{
		garage_id: 192,
		part_id: 127,
		quantity: 409,
	},
	{
		garage_id: 192,
		part_id: 128,
		quantity: 287,
	},
	{
		garage_id: 192,
		part_id: 129,
		quantity: 256,
	},
	{
		garage_id: 192,
		part_id: 130,
		quantity: 343,
	},
	{
		garage_id: 192,
		part_id: 131,
		quantity: 304,
	},
	{
		garage_id: 192,
		part_id: 132,
		quantity: 127,
	},
	{
		garage_id: 192,
		part_id: 133,
		quantity: 339,
	},
	{
		garage_id: 192,
		part_id: 134,
		quantity: 324,
	},
	{
		garage_id: 192,
		part_id: 135,
		quantity: 217,
	},
	{
		garage_id: 192,
		part_id: 136,
		quantity: 220,
	},
	{
		garage_id: 192,
		part_id: 137,
		quantity: 261,
	},
	{
		garage_id: 192,
		part_id: 138,
		quantity: 151,
	},
	{
		garage_id: 192,
		part_id: 139,
		quantity: 409,
	},
	{
		garage_id: 192,
		part_id: 140,
		quantity: 239,
	},
	{
		garage_id: 192,
		part_id: 141,
		quantity: 233,
	},
	{
		garage_id: 192,
		part_id: 142,
		quantity: 113,
	},
	{
		garage_id: 192,
		part_id: 143,
		quantity: 483,
	},
	{
		garage_id: 192,
		part_id: 144,
		quantity: 460,
	},
	{
		garage_id: 192,
		part_id: 145,
		quantity: 497,
	},
	{
		garage_id: 192,
		part_id: 146,
		quantity: 297,
	},
	{
		garage_id: 192,
		part_id: 147,
		quantity: 400,
	},
	{
		garage_id: 192,
		part_id: 148,
		quantity: 334,
	},
	{
		garage_id: 192,
		part_id: 149,
		quantity: 219,
	},
	{
		garage_id: 192,
		part_id: 150,
		quantity: 7,
	},
	{
		garage_id: 192,
		part_id: 151,
		quantity: 12,
	},
	{
		garage_id: 192,
		part_id: 152,
		quantity: 40,
	},
	{
		garage_id: 192,
		part_id: 153,
		quantity: 414,
	},
	{
		garage_id: 192,
		part_id: 154,
		quantity: 486,
	},
	{
		garage_id: 192,
		part_id: 155,
		quantity: 88,
	},
	{
		garage_id: 192,
		part_id: 156,
		quantity: 87,
	},
	{
		garage_id: 192,
		part_id: 157,
		quantity: 89,
	},
	{
		garage_id: 192,
		part_id: 158,
		quantity: 267,
	},
	{
		garage_id: 192,
		part_id: 159,
		quantity: 386,
	},
	{
		garage_id: 192,
		part_id: 160,
		quantity: 45,
	},
	{
		garage_id: 192,
		part_id: 161,
		quantity: 385,
	},
	{
		garage_id: 192,
		part_id: 162,
		quantity: 82,
	},
	{
		garage_id: 192,
		part_id: 163,
		quantity: 312,
	},
	{
		garage_id: 192,
		part_id: 164,
		quantity: 333,
	},
	{
		garage_id: 192,
		part_id: 165,
		quantity: 294,
	},
	{
		garage_id: 192,
		part_id: 166,
		quantity: 267,
	},
	{
		garage_id: 192,
		part_id: 167,
		quantity: 4,
	},
	{
		garage_id: 192,
		part_id: 168,
		quantity: 338,
	},
	{
		garage_id: 192,
		part_id: 169,
		quantity: 396,
	},
	{
		garage_id: 192,
		part_id: 170,
		quantity: 120,
	},
	{
		garage_id: 192,
		part_id: 171,
		quantity: 241,
	},
	{
		garage_id: 192,
		part_id: 172,
		quantity: 178,
	},
	{
		garage_id: 192,
		part_id: 173,
		quantity: 234,
	},
	{
		garage_id: 192,
		part_id: 174,
		quantity: 43,
	},
	{
		garage_id: 192,
		part_id: 175,
		quantity: 388,
	},
	{
		garage_id: 192,
		part_id: 176,
		quantity: 192,
	},
	{
		garage_id: 192,
		part_id: 177,
		quantity: 15,
	},
	{
		garage_id: 192,
		part_id: 178,
		quantity: 421,
	},
	{
		garage_id: 192,
		part_id: 179,
		quantity: 433,
	},
	{
		garage_id: 192,
		part_id: 180,
		quantity: 108,
	},
	{
		garage_id: 192,
		part_id: 181,
		quantity: 56,
	},
	{
		garage_id: 192,
		part_id: 182,
		quantity: 325,
	},
	{
		garage_id: 192,
		part_id: 183,
		quantity: 91,
	},
	{
		garage_id: 192,
		part_id: 184,
		quantity: 498,
	},
	{
		garage_id: 192,
		part_id: 185,
		quantity: 499,
	},
	{
		garage_id: 192,
		part_id: 186,
		quantity: 113,
	},
	{
		garage_id: 192,
		part_id: 187,
		quantity: 210,
	},
	{
		garage_id: 192,
		part_id: 188,
		quantity: 118,
	},
	{
		garage_id: 192,
		part_id: 189,
		quantity: 283,
	},
	{
		garage_id: 192,
		part_id: 190,
		quantity: 53,
	},
	{
		garage_id: 192,
		part_id: 191,
		quantity: 108,
	},
	{
		garage_id: 192,
		part_id: 192,
		quantity: 288,
	},
	{
		garage_id: 192,
		part_id: 193,
		quantity: 373,
	},
	{
		garage_id: 192,
		part_id: 194,
		quantity: 275,
	},
	{
		garage_id: 192,
		part_id: 195,
		quantity: 137,
	},
	{
		garage_id: 192,
		part_id: 196,
		quantity: 382,
	},
	{
		garage_id: 192,
		part_id: 197,
		quantity: 377,
	},
	{
		garage_id: 192,
		part_id: 198,
		quantity: 290,
	},
	{
		garage_id: 192,
		part_id: 199,
		quantity: 149,
	},
	{
		garage_id: 192,
		part_id: 200,
		quantity: 319,
	},
	{
		garage_id: 192,
		part_id: 201,
		quantity: 299,
	},
	{
		garage_id: 192,
		part_id: 202,
		quantity: 132,
	},
	{
		garage_id: 192,
		part_id: 203,
		quantity: 297,
	},
	{
		garage_id: 192,
		part_id: 204,
		quantity: 59,
	},
	{
		garage_id: 192,
		part_id: 205,
		quantity: 117,
	},
	{
		garage_id: 192,
		part_id: 206,
		quantity: 263,
	},
	{
		garage_id: 192,
		part_id: 207,
		quantity: 88,
	},
	{
		garage_id: 192,
		part_id: 208,
		quantity: 138,
	},
	{
		garage_id: 192,
		part_id: 209,
		quantity: 271,
	},
	{
		garage_id: 192,
		part_id: 210,
		quantity: 273,
	},
	{
		garage_id: 192,
		part_id: 211,
		quantity: 105,
	},
	{
		garage_id: 192,
		part_id: 212,
		quantity: 490,
	},
	{
		garage_id: 192,
		part_id: 213,
		quantity: 487,
	},
	{
		garage_id: 192,
		part_id: 214,
		quantity: 85,
	},
	{
		garage_id: 192,
		part_id: 215,
		quantity: 229,
	},
	{
		garage_id: 192,
		part_id: 216,
		quantity: 40,
	},
	{
		garage_id: 192,
		part_id: 217,
		quantity: 86,
	},
	{
		garage_id: 192,
		part_id: 218,
		quantity: 419,
	},
	{
		garage_id: 192,
		part_id: 219,
		quantity: 311,
	},
	{
		garage_id: 192,
		part_id: 220,
		quantity: 415,
	},
	{
		garage_id: 192,
		part_id: 221,
		quantity: 392,
	},
	{
		garage_id: 192,
		part_id: 222,
		quantity: 174,
	},
	{
		garage_id: 192,
		part_id: 223,
		quantity: 279,
	},
	{
		garage_id: 192,
		part_id: 224,
		quantity: 207,
	},
	{
		garage_id: 192,
		part_id: 225,
		quantity: 217,
	},
	{
		garage_id: 192,
		part_id: 226,
		quantity: 61,
	},
	{
		garage_id: 192,
		part_id: 227,
		quantity: 351,
	},
	{
		garage_id: 192,
		part_id: 228,
		quantity: 146,
	},
	{
		garage_id: 192,
		part_id: 229,
		quantity: 470,
	},
	{
		garage_id: 192,
		part_id: 230,
		quantity: 21,
	},
	{
		garage_id: 192,
		part_id: 231,
		quantity: 11,
	},
	{
		garage_id: 192,
		part_id: 232,
		quantity: 326,
	},
	{
		garage_id: 192,
		part_id: 233,
		quantity: 376,
	},
	{
		garage_id: 192,
		part_id: 234,
		quantity: 194,
	},
	{
		garage_id: 192,
		part_id: 235,
		quantity: 234,
	},
	{
		garage_id: 192,
		part_id: 236,
		quantity: 311,
	},
	{
		garage_id: 192,
		part_id: 237,
		quantity: 216,
	},
	{
		garage_id: 192,
		part_id: 238,
		quantity: 58,
	},
	{
		garage_id: 192,
		part_id: 239,
		quantity: 162,
	},
	{
		garage_id: 192,
		part_id: 240,
		quantity: 259,
	},
	{
		garage_id: 192,
		part_id: 241,
		quantity: 269,
	},
	{
		garage_id: 192,
		part_id: 242,
		quantity: 384,
	},
	{
		garage_id: 192,
		part_id: 243,
		quantity: 123,
	},
	{
		garage_id: 192,
		part_id: 244,
		quantity: 74,
	},
	{
		garage_id: 192,
		part_id: 245,
		quantity: 321,
	},
	{
		garage_id: 192,
		part_id: 246,
		quantity: 147,
	},
	{
		garage_id: 192,
		part_id: 247,
		quantity: 100,
	},
	{
		garage_id: 192,
		part_id: 248,
		quantity: 307,
	},
	{
		garage_id: 192,
		part_id: 249,
		quantity: 302,
	},
	{
		garage_id: 192,
		part_id: 250,
		quantity: 438,
	},
	{
		garage_id: 192,
		part_id: 251,
		quantity: 287,
	},
	{
		garage_id: 192,
		part_id: 252,
		quantity: 9,
	},
	{
		garage_id: 192,
		part_id: 253,
		quantity: 275,
	},
	{
		garage_id: 192,
		part_id: 254,
		quantity: 393,
	},
	{
		garage_id: 192,
		part_id: 255,
		quantity: 267,
	},
	{
		garage_id: 192,
		part_id: 256,
		quantity: 345,
	},
	{
		garage_id: 192,
		part_id: 257,
		quantity: 160,
	},
	{
		garage_id: 192,
		part_id: 258,
		quantity: 35,
	},
	{
		garage_id: 192,
		part_id: 259,
		quantity: 143,
	},
	{
		garage_id: 192,
		part_id: 260,
		quantity: 328,
	},
	{
		garage_id: 192,
		part_id: 261,
		quantity: 225,
	},
	{
		garage_id: 192,
		part_id: 262,
		quantity: 72,
	},
	{
		garage_id: 192,
		part_id: 263,
		quantity: 231,
	},
	{
		garage_id: 192,
		part_id: 264,
		quantity: 207,
	},
	{
		garage_id: 192,
		part_id: 265,
		quantity: 127,
	},
	{
		garage_id: 192,
		part_id: 266,
		quantity: 21,
	},
	{
		garage_id: 192,
		part_id: 267,
		quantity: 355,
	},
	{
		garage_id: 192,
		part_id: 268,
		quantity: 81,
	},
	{
		garage_id: 192,
		part_id: 269,
		quantity: 310,
	},
	{
		garage_id: 192,
		part_id: 270,
		quantity: 276,
	},
	{
		garage_id: 192,
		part_id: 271,
		quantity: 166,
	},
	{
		garage_id: 192,
		part_id: 272,
		quantity: 276,
	},
	{
		garage_id: 192,
		part_id: 273,
		quantity: 124,
	},
	{
		garage_id: 192,
		part_id: 274,
		quantity: 252,
	},
	{
		garage_id: 192,
		part_id: 275,
		quantity: 460,
	},
	{
		garage_id: 192,
		part_id: 276,
		quantity: 329,
	},
	{
		garage_id: 192,
		part_id: 277,
		quantity: 442,
	},
	{
		garage_id: 192,
		part_id: 278,
		quantity: 318,
	},
	{
		garage_id: 192,
		part_id: 279,
		quantity: 307,
	},
	{
		garage_id: 192,
		part_id: 280,
		quantity: 360,
	},
	{
		garage_id: 192,
		part_id: 281,
		quantity: 207,
	},
	{
		garage_id: 192,
		part_id: 282,
		quantity: 166,
	},
	{
		garage_id: 192,
		part_id: 283,
		quantity: 154,
	},
	{
		garage_id: 192,
		part_id: 284,
		quantity: 405,
	},
	{
		garage_id: 192,
		part_id: 285,
		quantity: 231,
	},
	{
		garage_id: 192,
		part_id: 286,
		quantity: 362,
	},
	{
		garage_id: 192,
		part_id: 287,
		quantity: 311,
	},
	{
		garage_id: 192,
		part_id: 288,
		quantity: 386,
	},
	{
		garage_id: 192,
		part_id: 289,
		quantity: 291,
	},
	{
		garage_id: 192,
		part_id: 290,
		quantity: 154,
	},
	{
		garage_id: 192,
		part_id: 291,
		quantity: 88,
	},
	{
		garage_id: 192,
		part_id: 292,
		quantity: 212,
	},
	{
		garage_id: 192,
		part_id: 293,
		quantity: 65,
	},
	{
		garage_id: 192,
		part_id: 294,
		quantity: 448,
	},
	{
		garage_id: 192,
		part_id: 295,
		quantity: 130,
	},
	{
		garage_id: 192,
		part_id: 296,
		quantity: 267,
	},
	{
		garage_id: 192,
		part_id: 297,
		quantity: 16,
	},
	{
		garage_id: 192,
		part_id: 298,
		quantity: 15,
	},
	{
		garage_id: 192,
		part_id: 299,
		quantity: 54,
	},
	{
		garage_id: 192,
		part_id: 300,
		quantity: 408,
	},
	{
		garage_id: 192,
		part_id: 301,
		quantity: 201,
	},
	{
		garage_id: 192,
		part_id: 302,
		quantity: 280,
	},
	{
		garage_id: 192,
		part_id: 303,
		quantity: 242,
	},
	{
		garage_id: 192,
		part_id: 304,
		quantity: 289,
	},
	{
		garage_id: 192,
		part_id: 305,
		quantity: 495,
	},
	{
		garage_id: 192,
		part_id: 306,
		quantity: 330,
	},
	{
		garage_id: 192,
		part_id: 307,
		quantity: 413,
	},
	{
		garage_id: 192,
		part_id: 308,
		quantity: 387,
	},
	{
		garage_id: 192,
		part_id: 309,
		quantity: 194,
	},
	{
		garage_id: 192,
		part_id: 310,
		quantity: 457,
	},
	{
		garage_id: 192,
		part_id: 311,
		quantity: 397,
	},
	{
		garage_id: 192,
		part_id: 312,
		quantity: 353,
	},
	{
		garage_id: 192,
		part_id: 313,
		quantity: 237,
	},
	{
		garage_id: 192,
		part_id: 314,
		quantity: 389,
	},
	{
		garage_id: 192,
		part_id: 315,
		quantity: 490,
	},
	{
		garage_id: 192,
		part_id: 316,
		quantity: 384,
	},
	{
		garage_id: 192,
		part_id: 317,
		quantity: 143,
	},
	{
		garage_id: 192,
		part_id: 318,
		quantity: 389,
	},
	{
		garage_id: 192,
		part_id: 319,
		quantity: 492,
	},
	{
		garage_id: 192,
		part_id: 320,
		quantity: 174,
	},
	{
		garage_id: 192,
		part_id: 321,
		quantity: 320,
	},
	{
		garage_id: 192,
		part_id: 322,
		quantity: 323,
	},
	{
		garage_id: 192,
		part_id: 323,
		quantity: 345,
	},
	{
		garage_id: 192,
		part_id: 324,
		quantity: 364,
	},
	{
		garage_id: 192,
		part_id: 325,
		quantity: 366,
	},
	{
		garage_id: 192,
		part_id: 326,
		quantity: 167,
	},
	{
		garage_id: 192,
		part_id: 327,
		quantity: 435,
	},
	{
		garage_id: 192,
		part_id: 328,
		quantity: 92,
	},
	{
		garage_id: 192,
		part_id: 329,
		quantity: 290,
	},
	{
		garage_id: 192,
		part_id: 330,
		quantity: 372,
	},
	{
		garage_id: 192,
		part_id: 331,
		quantity: 413,
	},
	{
		garage_id: 192,
		part_id: 332,
		quantity: 174,
	},
	{
		garage_id: 192,
		part_id: 333,
		quantity: 491,
	},
	{
		garage_id: 192,
		part_id: 334,
		quantity: 391,
	},
	{
		garage_id: 192,
		part_id: 335,
		quantity: 242,
	},
	{
		garage_id: 192,
		part_id: 336,
		quantity: 339,
	},
	{
		garage_id: 192,
		part_id: 337,
		quantity: 62,
	},
	{
		garage_id: 192,
		part_id: 338,
		quantity: 478,
	},
	{
		garage_id: 192,
		part_id: 339,
		quantity: 62,
	},
	{
		garage_id: 192,
		part_id: 340,
		quantity: 175,
	},
	{
		garage_id: 192,
		part_id: 341,
		quantity: 82,
	},
	{
		garage_id: 192,
		part_id: 342,
		quantity: 225,
	},
	{
		garage_id: 192,
		part_id: 343,
		quantity: 427,
	},
	{
		garage_id: 192,
		part_id: 344,
		quantity: 85,
	},
	{
		garage_id: 192,
		part_id: 345,
		quantity: 109,
	},
	{
		garage_id: 192,
		part_id: 346,
		quantity: 398,
	},
	{
		garage_id: 192,
		part_id: 347,
		quantity: 444,
	},
	{
		garage_id: 192,
		part_id: 348,
		quantity: 247,
	},
	{
		garage_id: 192,
		part_id: 349,
		quantity: 73,
	},
	{
		garage_id: 192,
		part_id: 350,
		quantity: 147,
	},
	{
		garage_id: 192,
		part_id: 351,
		quantity: 397,
	},
	{
		garage_id: 192,
		part_id: 352,
		quantity: 426,
	},
	{
		garage_id: 192,
		part_id: 353,
		quantity: 89,
	},
	{
		garage_id: 192,
		part_id: 354,
		quantity: 452,
	},
	{
		garage_id: 192,
		part_id: 355,
		quantity: 462,
	},
	{
		garage_id: 192,
		part_id: 356,
		quantity: 292,
	},
	{
		garage_id: 192,
		part_id: 357,
		quantity: 158,
	},
	{
		garage_id: 192,
		part_id: 358,
		quantity: 66,
	},
	{
		garage_id: 192,
		part_id: 359,
		quantity: 27,
	},
	{
		garage_id: 192,
		part_id: 360,
		quantity: 143,
	},
	{
		garage_id: 192,
		part_id: 361,
		quantity: 460,
	},
	{
		garage_id: 192,
		part_id: 362,
		quantity: 4,
	},
	{
		garage_id: 192,
		part_id: 363,
		quantity: 262,
	},
	{
		garage_id: 192,
		part_id: 364,
		quantity: 337,
	},
	{
		garage_id: 192,
		part_id: 365,
		quantity: 397,
	},
	{
		garage_id: 192,
		part_id: 366,
		quantity: 465,
	},
	{
		garage_id: 192,
		part_id: 367,
		quantity: 172,
	},
	{
		garage_id: 192,
		part_id: 368,
		quantity: 106,
	},
	{
		garage_id: 192,
		part_id: 369,
		quantity: 314,
	},
	{
		garage_id: 192,
		part_id: 370,
		quantity: 392,
	},
	{
		garage_id: 192,
		part_id: 371,
		quantity: 230,
	},
	{
		garage_id: 192,
		part_id: 372,
		quantity: 332,
	},
	{
		garage_id: 192,
		part_id: 373,
		quantity: 203,
	},
	{
		garage_id: 192,
		part_id: 374,
		quantity: 35,
	},
	{
		garage_id: 192,
		part_id: 375,
		quantity: 122,
	},
	{
		garage_id: 192,
		part_id: 376,
		quantity: 180,
	},
	{
		garage_id: 192,
		part_id: 377,
		quantity: 284,
	},
	{
		garage_id: 192,
		part_id: 378,
		quantity: 388,
	},
	{
		garage_id: 192,
		part_id: 379,
		quantity: 229,
	},
	{
		garage_id: 192,
		part_id: 380,
		quantity: 262,
	},
	{
		garage_id: 192,
		part_id: 381,
		quantity: 89,
	},
	{
		garage_id: 192,
		part_id: 382,
		quantity: 173,
	},
	{
		garage_id: 192,
		part_id: 383,
		quantity: 344,
	},
	{
		garage_id: 192,
		part_id: 384,
		quantity: 131,
	},
	{
		garage_id: 192,
		part_id: 385,
		quantity: 91,
	},
	{
		garage_id: 192,
		part_id: 386,
		quantity: 371,
	},
	{
		garage_id: 192,
		part_id: 387,
		quantity: 100,
	},
	{
		garage_id: 192,
		part_id: 388,
		quantity: 156,
	},
	{
		garage_id: 192,
		part_id: 389,
		quantity: 73,
	},
	{
		garage_id: 192,
		part_id: 390,
		quantity: 431,
	},
	{
		garage_id: 192,
		part_id: 391,
		quantity: 378,
	},
	{
		garage_id: 192,
		part_id: 392,
		quantity: 228,
	},
	{
		garage_id: 192,
		part_id: 393,
		quantity: 219,
	},
	{
		garage_id: 192,
		part_id: 394,
		quantity: 482,
	},
	{
		garage_id: 192,
		part_id: 395,
		quantity: 353,
	},
	{
		garage_id: 192,
		part_id: 396,
		quantity: 482,
	},
	{
		garage_id: 192,
		part_id: 397,
		quantity: 454,
	},
	{
		garage_id: 192,
		part_id: 398,
		quantity: 439,
	},
	{
		garage_id: 192,
		part_id: 399,
		quantity: 478,
	},
	{
		garage_id: 193,
		part_id: 0,
		quantity: 93,
	},
	{
		garage_id: 193,
		part_id: 1,
		quantity: 458,
	},
	{
		garage_id: 193,
		part_id: 2,
		quantity: 330,
	},
	{
		garage_id: 193,
		part_id: 3,
		quantity: 472,
	},
	{
		garage_id: 193,
		part_id: 4,
		quantity: 257,
	},
	{
		garage_id: 193,
		part_id: 5,
		quantity: 66,
	},
	{
		garage_id: 193,
		part_id: 6,
		quantity: 203,
	},
	{
		garage_id: 193,
		part_id: 7,
		quantity: 182,
	},
	{
		garage_id: 193,
		part_id: 8,
		quantity: 8,
	},
	{
		garage_id: 193,
		part_id: 9,
		quantity: 458,
	},
	{
		garage_id: 193,
		part_id: 10,
		quantity: 300,
	},
	{
		garage_id: 193,
		part_id: 11,
		quantity: 365,
	},
	{
		garage_id: 193,
		part_id: 12,
		quantity: 396,
	},
	{
		garage_id: 193,
		part_id: 13,
		quantity: 396,
	},
	{
		garage_id: 193,
		part_id: 14,
		quantity: 358,
	},
	{
		garage_id: 193,
		part_id: 15,
		quantity: 177,
	},
	{
		garage_id: 193,
		part_id: 16,
		quantity: 98,
	},
	{
		garage_id: 193,
		part_id: 17,
		quantity: 321,
	},
	{
		garage_id: 193,
		part_id: 18,
		quantity: 219,
	},
	{
		garage_id: 193,
		part_id: 19,
		quantity: 162,
	},
	{
		garage_id: 193,
		part_id: 20,
		quantity: 22,
	},
	{
		garage_id: 193,
		part_id: 21,
		quantity: 40,
	},
	{
		garage_id: 193,
		part_id: 22,
		quantity: 190,
	},
	{
		garage_id: 193,
		part_id: 23,
		quantity: 458,
	},
	{
		garage_id: 193,
		part_id: 24,
		quantity: 118,
	},
	{
		garage_id: 193,
		part_id: 25,
		quantity: 330,
	},
	{
		garage_id: 193,
		part_id: 26,
		quantity: 58,
	},
	{
		garage_id: 193,
		part_id: 27,
		quantity: 247,
	},
	{
		garage_id: 193,
		part_id: 28,
		quantity: 356,
	},
	{
		garage_id: 193,
		part_id: 29,
		quantity: 432,
	},
	{
		garage_id: 193,
		part_id: 30,
		quantity: 309,
	},
	{
		garage_id: 193,
		part_id: 31,
		quantity: 31,
	},
	{
		garage_id: 193,
		part_id: 32,
		quantity: 62,
	},
	{
		garage_id: 193,
		part_id: 33,
		quantity: 341,
	},
	{
		garage_id: 193,
		part_id: 34,
		quantity: 312,
	},
	{
		garage_id: 193,
		part_id: 35,
		quantity: 133,
	},
	{
		garage_id: 193,
		part_id: 36,
		quantity: 59,
	},
	{
		garage_id: 193,
		part_id: 37,
		quantity: 151,
	},
	{
		garage_id: 193,
		part_id: 38,
		quantity: 350,
	},
	{
		garage_id: 193,
		part_id: 39,
		quantity: 390,
	},
	{
		garage_id: 193,
		part_id: 40,
		quantity: 410,
	},
	{
		garage_id: 193,
		part_id: 41,
		quantity: 287,
	},
	{
		garage_id: 193,
		part_id: 42,
		quantity: 479,
	},
	{
		garage_id: 193,
		part_id: 43,
		quantity: 466,
	},
	{
		garage_id: 193,
		part_id: 44,
		quantity: 280,
	},
	{
		garage_id: 193,
		part_id: 45,
		quantity: 323,
	},
	{
		garage_id: 193,
		part_id: 46,
		quantity: 411,
	},
	{
		garage_id: 193,
		part_id: 47,
		quantity: 360,
	},
	{
		garage_id: 193,
		part_id: 48,
		quantity: 98,
	},
	{
		garage_id: 193,
		part_id: 49,
		quantity: 283,
	},
	{
		garage_id: 193,
		part_id: 50,
		quantity: 336,
	},
	{
		garage_id: 193,
		part_id: 51,
		quantity: 42,
	},
	{
		garage_id: 193,
		part_id: 52,
		quantity: 16,
	},
	{
		garage_id: 193,
		part_id: 53,
		quantity: 393,
	},
	{
		garage_id: 193,
		part_id: 54,
		quantity: 386,
	},
	{
		garage_id: 193,
		part_id: 55,
		quantity: 430,
	},
	{
		garage_id: 193,
		part_id: 56,
		quantity: 467,
	},
	{
		garage_id: 193,
		part_id: 57,
		quantity: 314,
	},
	{
		garage_id: 193,
		part_id: 58,
		quantity: 414,
	},
	{
		garage_id: 193,
		part_id: 59,
		quantity: 166,
	},
	{
		garage_id: 193,
		part_id: 60,
		quantity: 445,
	},
	{
		garage_id: 193,
		part_id: 61,
		quantity: 220,
	},
	{
		garage_id: 193,
		part_id: 62,
		quantity: 74,
	},
	{
		garage_id: 193,
		part_id: 63,
		quantity: 124,
	},
	{
		garage_id: 193,
		part_id: 64,
		quantity: 400,
	},
	{
		garage_id: 193,
		part_id: 65,
		quantity: 47,
	},
	{
		garage_id: 193,
		part_id: 66,
		quantity: 63,
	},
	{
		garage_id: 193,
		part_id: 67,
		quantity: 23,
	},
	{
		garage_id: 193,
		part_id: 68,
		quantity: 254,
	},
	{
		garage_id: 193,
		part_id: 69,
		quantity: 139,
	},
	{
		garage_id: 193,
		part_id: 70,
		quantity: 393,
	},
	{
		garage_id: 193,
		part_id: 71,
		quantity: 182,
	},
	{
		garage_id: 193,
		part_id: 72,
		quantity: 351,
	},
	{
		garage_id: 193,
		part_id: 73,
		quantity: 391,
	},
	{
		garage_id: 193,
		part_id: 74,
		quantity: 88,
	},
	{
		garage_id: 193,
		part_id: 75,
		quantity: 349,
	},
	{
		garage_id: 193,
		part_id: 76,
		quantity: 62,
	},
	{
		garage_id: 193,
		part_id: 77,
		quantity: 457,
	},
	{
		garage_id: 193,
		part_id: 78,
		quantity: 186,
	},
	{
		garage_id: 193,
		part_id: 79,
		quantity: 234,
	},
	{
		garage_id: 193,
		part_id: 80,
		quantity: 223,
	},
	{
		garage_id: 193,
		part_id: 81,
		quantity: 181,
	},
	{
		garage_id: 193,
		part_id: 82,
		quantity: 114,
	},
	{
		garage_id: 193,
		part_id: 83,
		quantity: 487,
	},
	{
		garage_id: 193,
		part_id: 84,
		quantity: 16,
	},
	{
		garage_id: 193,
		part_id: 85,
		quantity: 239,
	},
	{
		garage_id: 193,
		part_id: 86,
		quantity: 195,
	},
	{
		garage_id: 193,
		part_id: 87,
		quantity: 225,
	},
	{
		garage_id: 193,
		part_id: 88,
		quantity: 378,
	},
	{
		garage_id: 193,
		part_id: 89,
		quantity: 444,
	},
	{
		garage_id: 193,
		part_id: 90,
		quantity: 461,
	},
	{
		garage_id: 193,
		part_id: 91,
		quantity: 313,
	},
	{
		garage_id: 193,
		part_id: 92,
		quantity: 349,
	},
	{
		garage_id: 193,
		part_id: 93,
		quantity: 317,
	},
	{
		garage_id: 193,
		part_id: 94,
		quantity: 347,
	},
	{
		garage_id: 193,
		part_id: 95,
		quantity: 284,
	},
	{
		garage_id: 193,
		part_id: 96,
		quantity: 424,
	},
	{
		garage_id: 193,
		part_id: 97,
		quantity: 341,
	},
	{
		garage_id: 193,
		part_id: 98,
		quantity: 241,
	},
	{
		garage_id: 193,
		part_id: 99,
		quantity: 414,
	},
	{
		garage_id: 193,
		part_id: 100,
		quantity: 275,
	},
	{
		garage_id: 193,
		part_id: 101,
		quantity: 175,
	},
	{
		garage_id: 193,
		part_id: 102,
		quantity: 476,
	},
	{
		garage_id: 193,
		part_id: 103,
		quantity: 406,
	},
	{
		garage_id: 193,
		part_id: 104,
		quantity: 438,
	},
	{
		garage_id: 193,
		part_id: 105,
		quantity: 166,
	},
	{
		garage_id: 193,
		part_id: 106,
		quantity: 316,
	},
	{
		garage_id: 193,
		part_id: 107,
		quantity: 360,
	},
	{
		garage_id: 193,
		part_id: 108,
		quantity: 104,
	},
	{
		garage_id: 193,
		part_id: 109,
		quantity: 436,
	},
	{
		garage_id: 193,
		part_id: 110,
		quantity: 107,
	},
	{
		garage_id: 193,
		part_id: 111,
		quantity: 457,
	},
	{
		garage_id: 193,
		part_id: 112,
		quantity: 90,
	},
	{
		garage_id: 193,
		part_id: 113,
		quantity: 497,
	},
	{
		garage_id: 193,
		part_id: 114,
		quantity: 313,
	},
	{
		garage_id: 193,
		part_id: 115,
		quantity: 68,
	},
	{
		garage_id: 193,
		part_id: 116,
		quantity: 199,
	},
	{
		garage_id: 193,
		part_id: 117,
		quantity: 362,
	},
	{
		garage_id: 193,
		part_id: 118,
		quantity: 371,
	},
	{
		garage_id: 193,
		part_id: 119,
		quantity: 77,
	},
	{
		garage_id: 193,
		part_id: 120,
		quantity: 251,
	},
	{
		garage_id: 193,
		part_id: 121,
		quantity: 319,
	},
	{
		garage_id: 193,
		part_id: 122,
		quantity: 314,
	},
	{
		garage_id: 193,
		part_id: 123,
		quantity: 119,
	},
	{
		garage_id: 193,
		part_id: 124,
		quantity: 461,
	},
	{
		garage_id: 193,
		part_id: 125,
		quantity: 324,
	},
	{
		garage_id: 193,
		part_id: 126,
		quantity: 441,
	},
	{
		garage_id: 193,
		part_id: 127,
		quantity: 204,
	},
	{
		garage_id: 193,
		part_id: 128,
		quantity: 353,
	},
	{
		garage_id: 193,
		part_id: 129,
		quantity: 437,
	},
	{
		garage_id: 193,
		part_id: 130,
		quantity: 472,
	},
	{
		garage_id: 193,
		part_id: 131,
		quantity: 165,
	},
	{
		garage_id: 193,
		part_id: 132,
		quantity: 429,
	},
	{
		garage_id: 193,
		part_id: 133,
		quantity: 380,
	},
	{
		garage_id: 193,
		part_id: 134,
		quantity: 340,
	},
	{
		garage_id: 193,
		part_id: 135,
		quantity: 439,
	},
	{
		garage_id: 193,
		part_id: 136,
		quantity: 182,
	},
	{
		garage_id: 193,
		part_id: 137,
		quantity: 371,
	},
	{
		garage_id: 193,
		part_id: 138,
		quantity: 430,
	},
	{
		garage_id: 193,
		part_id: 139,
		quantity: 51,
	},
	{
		garage_id: 193,
		part_id: 140,
		quantity: 125,
	},
	{
		garage_id: 193,
		part_id: 141,
		quantity: 443,
	},
	{
		garage_id: 193,
		part_id: 142,
		quantity: 232,
	},
	{
		garage_id: 193,
		part_id: 143,
		quantity: 495,
	},
	{
		garage_id: 193,
		part_id: 144,
		quantity: 462,
	},
	{
		garage_id: 193,
		part_id: 145,
		quantity: 336,
	},
	{
		garage_id: 193,
		part_id: 146,
		quantity: 305,
	},
	{
		garage_id: 193,
		part_id: 147,
		quantity: 493,
	},
	{
		garage_id: 193,
		part_id: 148,
		quantity: 225,
	},
	{
		garage_id: 193,
		part_id: 149,
		quantity: 439,
	},
	{
		garage_id: 193,
		part_id: 150,
		quantity: 335,
	},
	{
		garage_id: 193,
		part_id: 151,
		quantity: 417,
	},
	{
		garage_id: 193,
		part_id: 152,
		quantity: 322,
	},
	{
		garage_id: 193,
		part_id: 153,
		quantity: 204,
	},
	{
		garage_id: 193,
		part_id: 154,
		quantity: 394,
	},
	{
		garage_id: 193,
		part_id: 155,
		quantity: 243,
	},
	{
		garage_id: 193,
		part_id: 156,
		quantity: 176,
	},
	{
		garage_id: 193,
		part_id: 157,
		quantity: 465,
	},
	{
		garage_id: 193,
		part_id: 158,
		quantity: 7,
	},
	{
		garage_id: 193,
		part_id: 159,
		quantity: 346,
	},
	{
		garage_id: 193,
		part_id: 160,
		quantity: 426,
	},
	{
		garage_id: 193,
		part_id: 161,
		quantity: 440,
	},
	{
		garage_id: 193,
		part_id: 162,
		quantity: 399,
	},
	{
		garage_id: 193,
		part_id: 163,
		quantity: 374,
	},
	{
		garage_id: 193,
		part_id: 164,
		quantity: 328,
	},
	{
		garage_id: 193,
		part_id: 165,
		quantity: 89,
	},
	{
		garage_id: 193,
		part_id: 166,
		quantity: 132,
	},
	{
		garage_id: 193,
		part_id: 167,
		quantity: 38,
	},
	{
		garage_id: 193,
		part_id: 168,
		quantity: 286,
	},
	{
		garage_id: 193,
		part_id: 169,
		quantity: 167,
	},
	{
		garage_id: 193,
		part_id: 170,
		quantity: 472,
	},
	{
		garage_id: 193,
		part_id: 171,
		quantity: 45,
	},
	{
		garage_id: 193,
		part_id: 172,
		quantity: 300,
	},
	{
		garage_id: 193,
		part_id: 173,
		quantity: 486,
	},
	{
		garage_id: 193,
		part_id: 174,
		quantity: 76,
	},
	{
		garage_id: 193,
		part_id: 175,
		quantity: 274,
	},
	{
		garage_id: 193,
		part_id: 176,
		quantity: 267,
	},
	{
		garage_id: 193,
		part_id: 177,
		quantity: 3,
	},
	{
		garage_id: 193,
		part_id: 178,
		quantity: 425,
	},
	{
		garage_id: 193,
		part_id: 179,
		quantity: 88,
	},
	{
		garage_id: 193,
		part_id: 180,
		quantity: 212,
	},
	{
		garage_id: 193,
		part_id: 181,
		quantity: 417,
	},
	{
		garage_id: 193,
		part_id: 182,
		quantity: 21,
	},
	{
		garage_id: 193,
		part_id: 183,
		quantity: 375,
	},
	{
		garage_id: 193,
		part_id: 184,
		quantity: 364,
	},
	{
		garage_id: 193,
		part_id: 185,
		quantity: 332,
	},
	{
		garage_id: 193,
		part_id: 186,
		quantity: 0,
	},
	{
		garage_id: 193,
		part_id: 187,
		quantity: 188,
	},
	{
		garage_id: 193,
		part_id: 188,
		quantity: 381,
	},
	{
		garage_id: 193,
		part_id: 189,
		quantity: 443,
	},
	{
		garage_id: 193,
		part_id: 190,
		quantity: 438,
	},
	{
		garage_id: 193,
		part_id: 191,
		quantity: 104,
	},
	{
		garage_id: 193,
		part_id: 192,
		quantity: 93,
	},
	{
		garage_id: 193,
		part_id: 193,
		quantity: 222,
	},
	{
		garage_id: 193,
		part_id: 194,
		quantity: 444,
	},
	{
		garage_id: 193,
		part_id: 195,
		quantity: 166,
	},
	{
		garage_id: 193,
		part_id: 196,
		quantity: 500,
	},
	{
		garage_id: 193,
		part_id: 197,
		quantity: 242,
	},
	{
		garage_id: 193,
		part_id: 198,
		quantity: 434,
	},
	{
		garage_id: 193,
		part_id: 199,
		quantity: 486,
	},
	{
		garage_id: 193,
		part_id: 200,
		quantity: 353,
	},
	{
		garage_id: 193,
		part_id: 201,
		quantity: 158,
	},
	{
		garage_id: 193,
		part_id: 202,
		quantity: 342,
	},
	{
		garage_id: 193,
		part_id: 203,
		quantity: 346,
	},
	{
		garage_id: 193,
		part_id: 204,
		quantity: 183,
	},
	{
		garage_id: 193,
		part_id: 205,
		quantity: 307,
	},
	{
		garage_id: 193,
		part_id: 206,
		quantity: 49,
	},
	{
		garage_id: 193,
		part_id: 207,
		quantity: 207,
	},
	{
		garage_id: 193,
		part_id: 208,
		quantity: 445,
	},
	{
		garage_id: 193,
		part_id: 209,
		quantity: 307,
	},
	{
		garage_id: 193,
		part_id: 210,
		quantity: 144,
	},
	{
		garage_id: 193,
		part_id: 211,
		quantity: 160,
	},
	{
		garage_id: 193,
		part_id: 212,
		quantity: 375,
	},
	{
		garage_id: 193,
		part_id: 213,
		quantity: 141,
	},
	{
		garage_id: 193,
		part_id: 214,
		quantity: 318,
	},
	{
		garage_id: 193,
		part_id: 215,
		quantity: 59,
	},
	{
		garage_id: 193,
		part_id: 216,
		quantity: 219,
	},
	{
		garage_id: 193,
		part_id: 217,
		quantity: 95,
	},
	{
		garage_id: 193,
		part_id: 218,
		quantity: 488,
	},
	{
		garage_id: 193,
		part_id: 219,
		quantity: 118,
	},
	{
		garage_id: 193,
		part_id: 220,
		quantity: 467,
	},
	{
		garage_id: 193,
		part_id: 221,
		quantity: 134,
	},
	{
		garage_id: 193,
		part_id: 222,
		quantity: 496,
	},
	{
		garage_id: 193,
		part_id: 223,
		quantity: 425,
	},
	{
		garage_id: 193,
		part_id: 224,
		quantity: 127,
	},
	{
		garage_id: 193,
		part_id: 225,
		quantity: 64,
	},
	{
		garage_id: 193,
		part_id: 226,
		quantity: 63,
	},
	{
		garage_id: 193,
		part_id: 227,
		quantity: 250,
	},
	{
		garage_id: 193,
		part_id: 228,
		quantity: 354,
	},
	{
		garage_id: 193,
		part_id: 229,
		quantity: 244,
	},
	{
		garage_id: 193,
		part_id: 230,
		quantity: 142,
	},
	{
		garage_id: 193,
		part_id: 231,
		quantity: 78,
	},
	{
		garage_id: 193,
		part_id: 232,
		quantity: 390,
	},
	{
		garage_id: 193,
		part_id: 233,
		quantity: 314,
	},
	{
		garage_id: 193,
		part_id: 234,
		quantity: 197,
	},
	{
		garage_id: 193,
		part_id: 235,
		quantity: 169,
	},
	{
		garage_id: 193,
		part_id: 236,
		quantity: 348,
	},
	{
		garage_id: 193,
		part_id: 237,
		quantity: 374,
	},
	{
		garage_id: 193,
		part_id: 238,
		quantity: 189,
	},
	{
		garage_id: 193,
		part_id: 239,
		quantity: 175,
	},
	{
		garage_id: 193,
		part_id: 240,
		quantity: 376,
	},
	{
		garage_id: 193,
		part_id: 241,
		quantity: 192,
	},
	{
		garage_id: 193,
		part_id: 242,
		quantity: 266,
	},
	{
		garage_id: 193,
		part_id: 243,
		quantity: 248,
	},
	{
		garage_id: 193,
		part_id: 244,
		quantity: 137,
	},
	{
		garage_id: 193,
		part_id: 245,
		quantity: 283,
	},
	{
		garage_id: 193,
		part_id: 246,
		quantity: 344,
	},
	{
		garage_id: 193,
		part_id: 247,
		quantity: 409,
	},
	{
		garage_id: 193,
		part_id: 248,
		quantity: 198,
	},
	{
		garage_id: 193,
		part_id: 249,
		quantity: 281,
	},
	{
		garage_id: 193,
		part_id: 250,
		quantity: 487,
	},
	{
		garage_id: 193,
		part_id: 251,
		quantity: 492,
	},
	{
		garage_id: 193,
		part_id: 252,
		quantity: 2,
	},
	{
		garage_id: 193,
		part_id: 253,
		quantity: 109,
	},
	{
		garage_id: 193,
		part_id: 254,
		quantity: 157,
	},
	{
		garage_id: 193,
		part_id: 255,
		quantity: 368,
	},
	{
		garage_id: 193,
		part_id: 256,
		quantity: 180,
	},
	{
		garage_id: 193,
		part_id: 257,
		quantity: 31,
	},
	{
		garage_id: 193,
		part_id: 258,
		quantity: 268,
	},
	{
		garage_id: 193,
		part_id: 259,
		quantity: 128,
	},
	{
		garage_id: 193,
		part_id: 260,
		quantity: 346,
	},
	{
		garage_id: 193,
		part_id: 261,
		quantity: 170,
	},
	{
		garage_id: 193,
		part_id: 262,
		quantity: 320,
	},
	{
		garage_id: 193,
		part_id: 263,
		quantity: 363,
	},
	{
		garage_id: 193,
		part_id: 264,
		quantity: 249,
	},
	{
		garage_id: 193,
		part_id: 265,
		quantity: 102,
	},
	{
		garage_id: 193,
		part_id: 266,
		quantity: 410,
	},
	{
		garage_id: 193,
		part_id: 267,
		quantity: 116,
	},
	{
		garage_id: 193,
		part_id: 268,
		quantity: 195,
	},
	{
		garage_id: 193,
		part_id: 269,
		quantity: 228,
	},
	{
		garage_id: 193,
		part_id: 270,
		quantity: 451,
	},
	{
		garage_id: 193,
		part_id: 271,
		quantity: 227,
	},
	{
		garage_id: 193,
		part_id: 272,
		quantity: 219,
	},
	{
		garage_id: 193,
		part_id: 273,
		quantity: 311,
	},
	{
		garage_id: 193,
		part_id: 274,
		quantity: 291,
	},
	{
		garage_id: 193,
		part_id: 275,
		quantity: 364,
	},
	{
		garage_id: 193,
		part_id: 276,
		quantity: 201,
	},
	{
		garage_id: 193,
		part_id: 277,
		quantity: 346,
	},
	{
		garage_id: 193,
		part_id: 278,
		quantity: 98,
	},
	{
		garage_id: 193,
		part_id: 279,
		quantity: 102,
	},
	{
		garage_id: 193,
		part_id: 280,
		quantity: 383,
	},
	{
		garage_id: 193,
		part_id: 281,
		quantity: 352,
	},
	{
		garage_id: 193,
		part_id: 282,
		quantity: 299,
	},
	{
		garage_id: 193,
		part_id: 283,
		quantity: 145,
	},
	{
		garage_id: 193,
		part_id: 284,
		quantity: 304,
	},
	{
		garage_id: 193,
		part_id: 285,
		quantity: 165,
	},
	{
		garage_id: 193,
		part_id: 286,
		quantity: 484,
	},
	{
		garage_id: 193,
		part_id: 287,
		quantity: 92,
	},
	{
		garage_id: 193,
		part_id: 288,
		quantity: 415,
	},
	{
		garage_id: 193,
		part_id: 289,
		quantity: 399,
	},
	{
		garage_id: 193,
		part_id: 290,
		quantity: 469,
	},
	{
		garage_id: 193,
		part_id: 291,
		quantity: 378,
	},
	{
		garage_id: 193,
		part_id: 292,
		quantity: 2,
	},
	{
		garage_id: 193,
		part_id: 293,
		quantity: 252,
	},
	{
		garage_id: 193,
		part_id: 294,
		quantity: 317,
	},
	{
		garage_id: 193,
		part_id: 295,
		quantity: 75,
	},
	{
		garage_id: 193,
		part_id: 296,
		quantity: 482,
	},
	{
		garage_id: 193,
		part_id: 297,
		quantity: 376,
	},
	{
		garage_id: 193,
		part_id: 298,
		quantity: 217,
	},
	{
		garage_id: 193,
		part_id: 299,
		quantity: 13,
	},
	{
		garage_id: 193,
		part_id: 300,
		quantity: 54,
	},
	{
		garage_id: 193,
		part_id: 301,
		quantity: 175,
	},
	{
		garage_id: 193,
		part_id: 302,
		quantity: 247,
	},
	{
		garage_id: 193,
		part_id: 303,
		quantity: 76,
	},
	{
		garage_id: 193,
		part_id: 304,
		quantity: 119,
	},
	{
		garage_id: 193,
		part_id: 305,
		quantity: 122,
	},
	{
		garage_id: 193,
		part_id: 306,
		quantity: 297,
	},
	{
		garage_id: 193,
		part_id: 307,
		quantity: 260,
	},
	{
		garage_id: 193,
		part_id: 308,
		quantity: 9,
	},
	{
		garage_id: 193,
		part_id: 309,
		quantity: 62,
	},
	{
		garage_id: 193,
		part_id: 310,
		quantity: 117,
	},
	{
		garage_id: 193,
		part_id: 311,
		quantity: 388,
	},
	{
		garage_id: 193,
		part_id: 312,
		quantity: 408,
	},
	{
		garage_id: 193,
		part_id: 313,
		quantity: 141,
	},
	{
		garage_id: 193,
		part_id: 314,
		quantity: 15,
	},
	{
		garage_id: 193,
		part_id: 315,
		quantity: 36,
	},
	{
		garage_id: 193,
		part_id: 316,
		quantity: 38,
	},
	{
		garage_id: 193,
		part_id: 317,
		quantity: 188,
	},
	{
		garage_id: 193,
		part_id: 318,
		quantity: 55,
	},
	{
		garage_id: 193,
		part_id: 319,
		quantity: 83,
	},
	{
		garage_id: 193,
		part_id: 320,
		quantity: 31,
	},
	{
		garage_id: 193,
		part_id: 321,
		quantity: 343,
	},
	{
		garage_id: 193,
		part_id: 322,
		quantity: 39,
	},
	{
		garage_id: 193,
		part_id: 323,
		quantity: 208,
	},
	{
		garage_id: 193,
		part_id: 324,
		quantity: 155,
	},
	{
		garage_id: 193,
		part_id: 325,
		quantity: 199,
	},
	{
		garage_id: 193,
		part_id: 326,
		quantity: 47,
	},
	{
		garage_id: 193,
		part_id: 327,
		quantity: 100,
	},
	{
		garage_id: 193,
		part_id: 328,
		quantity: 128,
	},
	{
		garage_id: 193,
		part_id: 329,
		quantity: 156,
	},
	{
		garage_id: 193,
		part_id: 330,
		quantity: 340,
	},
	{
		garage_id: 193,
		part_id: 331,
		quantity: 232,
	},
	{
		garage_id: 193,
		part_id: 332,
		quantity: 255,
	},
	{
		garage_id: 193,
		part_id: 333,
		quantity: 322,
	},
	{
		garage_id: 193,
		part_id: 334,
		quantity: 436,
	},
	{
		garage_id: 193,
		part_id: 335,
		quantity: 51,
	},
	{
		garage_id: 193,
		part_id: 336,
		quantity: 483,
	},
	{
		garage_id: 193,
		part_id: 337,
		quantity: 487,
	},
	{
		garage_id: 193,
		part_id: 338,
		quantity: 189,
	},
	{
		garage_id: 193,
		part_id: 339,
		quantity: 24,
	},
	{
		garage_id: 193,
		part_id: 340,
		quantity: 208,
	},
	{
		garage_id: 193,
		part_id: 341,
		quantity: 143,
	},
	{
		garage_id: 193,
		part_id: 342,
		quantity: 22,
	},
	{
		garage_id: 193,
		part_id: 343,
		quantity: 499,
	},
	{
		garage_id: 193,
		part_id: 344,
		quantity: 408,
	},
	{
		garage_id: 193,
		part_id: 345,
		quantity: 230,
	},
	{
		garage_id: 193,
		part_id: 346,
		quantity: 141,
	},
	{
		garage_id: 193,
		part_id: 347,
		quantity: 414,
	},
	{
		garage_id: 193,
		part_id: 348,
		quantity: 52,
	},
	{
		garage_id: 193,
		part_id: 349,
		quantity: 173,
	},
	{
		garage_id: 193,
		part_id: 350,
		quantity: 158,
	},
	{
		garage_id: 193,
		part_id: 351,
		quantity: 177,
	},
	{
		garage_id: 193,
		part_id: 352,
		quantity: 146,
	},
	{
		garage_id: 193,
		part_id: 353,
		quantity: 241,
	},
	{
		garage_id: 193,
		part_id: 354,
		quantity: 379,
	},
	{
		garage_id: 193,
		part_id: 355,
		quantity: 413,
	},
	{
		garage_id: 193,
		part_id: 356,
		quantity: 288,
	},
	{
		garage_id: 193,
		part_id: 357,
		quantity: 481,
	},
	{
		garage_id: 193,
		part_id: 358,
		quantity: 179,
	},
	{
		garage_id: 193,
		part_id: 359,
		quantity: 432,
	},
	{
		garage_id: 193,
		part_id: 360,
		quantity: 78,
	},
	{
		garage_id: 193,
		part_id: 361,
		quantity: 489,
	},
	{
		garage_id: 193,
		part_id: 362,
		quantity: 480,
	},
	{
		garage_id: 193,
		part_id: 363,
		quantity: 454,
	},
	{
		garage_id: 193,
		part_id: 364,
		quantity: 174,
	},
	{
		garage_id: 193,
		part_id: 365,
		quantity: 417,
	},
	{
		garage_id: 193,
		part_id: 366,
		quantity: 343,
	},
	{
		garage_id: 193,
		part_id: 367,
		quantity: 328,
	},
	{
		garage_id: 193,
		part_id: 368,
		quantity: 471,
	},
	{
		garage_id: 193,
		part_id: 369,
		quantity: 38,
	},
	{
		garage_id: 193,
		part_id: 370,
		quantity: 15,
	},
	{
		garage_id: 193,
		part_id: 371,
		quantity: 439,
	},
	{
		garage_id: 193,
		part_id: 372,
		quantity: 32,
	},
	{
		garage_id: 193,
		part_id: 373,
		quantity: 497,
	},
	{
		garage_id: 193,
		part_id: 374,
		quantity: 228,
	},
	{
		garage_id: 193,
		part_id: 375,
		quantity: 467,
	},
	{
		garage_id: 193,
		part_id: 376,
		quantity: 329,
	},
	{
		garage_id: 193,
		part_id: 377,
		quantity: 54,
	},
	{
		garage_id: 193,
		part_id: 378,
		quantity: 368,
	},
	{
		garage_id: 193,
		part_id: 379,
		quantity: 328,
	},
	{
		garage_id: 193,
		part_id: 380,
		quantity: 9,
	},
	{
		garage_id: 193,
		part_id: 381,
		quantity: 407,
	},
	{
		garage_id: 193,
		part_id: 382,
		quantity: 52,
	},
	{
		garage_id: 193,
		part_id: 383,
		quantity: 77,
	},
	{
		garage_id: 193,
		part_id: 384,
		quantity: 93,
	},
	{
		garage_id: 193,
		part_id: 385,
		quantity: 160,
	},
	{
		garage_id: 193,
		part_id: 386,
		quantity: 74,
	},
	{
		garage_id: 193,
		part_id: 387,
		quantity: 143,
	},
	{
		garage_id: 193,
		part_id: 388,
		quantity: 329,
	},
	{
		garage_id: 193,
		part_id: 389,
		quantity: 43,
	},
	{
		garage_id: 193,
		part_id: 390,
		quantity: 112,
	},
	{
		garage_id: 193,
		part_id: 391,
		quantity: 212,
	},
	{
		garage_id: 193,
		part_id: 392,
		quantity: 352,
	},
	{
		garage_id: 193,
		part_id: 393,
		quantity: 28,
	},
	{
		garage_id: 193,
		part_id: 394,
		quantity: 35,
	},
	{
		garage_id: 193,
		part_id: 395,
		quantity: 350,
	},
	{
		garage_id: 193,
		part_id: 396,
		quantity: 130,
	},
	{
		garage_id: 193,
		part_id: 397,
		quantity: 338,
	},
	{
		garage_id: 193,
		part_id: 398,
		quantity: 421,
	},
	{
		garage_id: 193,
		part_id: 399,
		quantity: 187,
	},
	{
		garage_id: 194,
		part_id: 0,
		quantity: 221,
	},
	{
		garage_id: 194,
		part_id: 1,
		quantity: 312,
	},
	{
		garage_id: 194,
		part_id: 2,
		quantity: 88,
	},
	{
		garage_id: 194,
		part_id: 3,
		quantity: 235,
	},
	{
		garage_id: 194,
		part_id: 4,
		quantity: 474,
	},
	{
		garage_id: 194,
		part_id: 5,
		quantity: 208,
	},
	{
		garage_id: 194,
		part_id: 6,
		quantity: 387,
	},
	{
		garage_id: 194,
		part_id: 7,
		quantity: 136,
	},
	{
		garage_id: 194,
		part_id: 8,
		quantity: 26,
	},
	{
		garage_id: 194,
		part_id: 9,
		quantity: 424,
	},
	{
		garage_id: 194,
		part_id: 10,
		quantity: 22,
	},
	{
		garage_id: 194,
		part_id: 11,
		quantity: 382,
	},
	{
		garage_id: 194,
		part_id: 12,
		quantity: 400,
	},
	{
		garage_id: 194,
		part_id: 13,
		quantity: 219,
	},
	{
		garage_id: 194,
		part_id: 14,
		quantity: 107,
	},
	{
		garage_id: 194,
		part_id: 15,
		quantity: 137,
	},
	{
		garage_id: 194,
		part_id: 16,
		quantity: 0,
	},
	{
		garage_id: 194,
		part_id: 17,
		quantity: 163,
	},
	{
		garage_id: 194,
		part_id: 18,
		quantity: 13,
	},
	{
		garage_id: 194,
		part_id: 19,
		quantity: 445,
	},
	{
		garage_id: 194,
		part_id: 20,
		quantity: 335,
	},
	{
		garage_id: 194,
		part_id: 21,
		quantity: 299,
	},
	{
		garage_id: 194,
		part_id: 22,
		quantity: 186,
	},
	{
		garage_id: 194,
		part_id: 23,
		quantity: 242,
	},
	{
		garage_id: 194,
		part_id: 24,
		quantity: 204,
	},
	{
		garage_id: 194,
		part_id: 25,
		quantity: 37,
	},
	{
		garage_id: 194,
		part_id: 26,
		quantity: 404,
	},
	{
		garage_id: 194,
		part_id: 27,
		quantity: 12,
	},
	{
		garage_id: 194,
		part_id: 28,
		quantity: 214,
	},
	{
		garage_id: 194,
		part_id: 29,
		quantity: 178,
	},
	{
		garage_id: 194,
		part_id: 30,
		quantity: 96,
	},
	{
		garage_id: 194,
		part_id: 31,
		quantity: 496,
	},
	{
		garage_id: 194,
		part_id: 32,
		quantity: 389,
	},
	{
		garage_id: 194,
		part_id: 33,
		quantity: 178,
	},
	{
		garage_id: 194,
		part_id: 34,
		quantity: 433,
	},
	{
		garage_id: 194,
		part_id: 35,
		quantity: 2,
	},
	{
		garage_id: 194,
		part_id: 36,
		quantity: 224,
	},
	{
		garage_id: 194,
		part_id: 37,
		quantity: 312,
	},
	{
		garage_id: 194,
		part_id: 38,
		quantity: 218,
	},
	{
		garage_id: 194,
		part_id: 39,
		quantity: 22,
	},
	{
		garage_id: 194,
		part_id: 40,
		quantity: 317,
	},
	{
		garage_id: 194,
		part_id: 41,
		quantity: 122,
	},
	{
		garage_id: 194,
		part_id: 42,
		quantity: 193,
	},
	{
		garage_id: 194,
		part_id: 43,
		quantity: 470,
	},
	{
		garage_id: 194,
		part_id: 44,
		quantity: 237,
	},
	{
		garage_id: 194,
		part_id: 45,
		quantity: 119,
	},
	{
		garage_id: 194,
		part_id: 46,
		quantity: 14,
	},
	{
		garage_id: 194,
		part_id: 47,
		quantity: 445,
	},
	{
		garage_id: 194,
		part_id: 48,
		quantity: 101,
	},
	{
		garage_id: 194,
		part_id: 49,
		quantity: 321,
	},
	{
		garage_id: 194,
		part_id: 50,
		quantity: 125,
	},
	{
		garage_id: 194,
		part_id: 51,
		quantity: 104,
	},
	{
		garage_id: 194,
		part_id: 52,
		quantity: 344,
	},
	{
		garage_id: 194,
		part_id: 53,
		quantity: 214,
	},
	{
		garage_id: 194,
		part_id: 54,
		quantity: 356,
	},
	{
		garage_id: 194,
		part_id: 55,
		quantity: 148,
	},
	{
		garage_id: 194,
		part_id: 56,
		quantity: 420,
	},
	{
		garage_id: 194,
		part_id: 57,
		quantity: 87,
	},
	{
		garage_id: 194,
		part_id: 58,
		quantity: 132,
	},
	{
		garage_id: 194,
		part_id: 59,
		quantity: 408,
	},
	{
		garage_id: 194,
		part_id: 60,
		quantity: 298,
	},
	{
		garage_id: 194,
		part_id: 61,
		quantity: 190,
	},
	{
		garage_id: 194,
		part_id: 62,
		quantity: 404,
	},
	{
		garage_id: 194,
		part_id: 63,
		quantity: 293,
	},
	{
		garage_id: 194,
		part_id: 64,
		quantity: 85,
	},
	{
		garage_id: 194,
		part_id: 65,
		quantity: 410,
	},
	{
		garage_id: 194,
		part_id: 66,
		quantity: 356,
	},
	{
		garage_id: 194,
		part_id: 67,
		quantity: 97,
	},
	{
		garage_id: 194,
		part_id: 68,
		quantity: 283,
	},
	{
		garage_id: 194,
		part_id: 69,
		quantity: 117,
	},
	{
		garage_id: 194,
		part_id: 70,
		quantity: 186,
	},
	{
		garage_id: 194,
		part_id: 71,
		quantity: 270,
	},
	{
		garage_id: 194,
		part_id: 72,
		quantity: 24,
	},
	{
		garage_id: 194,
		part_id: 73,
		quantity: 210,
	},
	{
		garage_id: 194,
		part_id: 74,
		quantity: 438,
	},
	{
		garage_id: 194,
		part_id: 75,
		quantity: 20,
	},
	{
		garage_id: 194,
		part_id: 76,
		quantity: 157,
	},
	{
		garage_id: 194,
		part_id: 77,
		quantity: 328,
	},
	{
		garage_id: 194,
		part_id: 78,
		quantity: 300,
	},
	{
		garage_id: 194,
		part_id: 79,
		quantity: 120,
	},
	{
		garage_id: 194,
		part_id: 80,
		quantity: 183,
	},
	{
		garage_id: 194,
		part_id: 81,
		quantity: 87,
	},
	{
		garage_id: 194,
		part_id: 82,
		quantity: 99,
	},
	{
		garage_id: 194,
		part_id: 83,
		quantity: 442,
	},
	{
		garage_id: 194,
		part_id: 84,
		quantity: 363,
	},
	{
		garage_id: 194,
		part_id: 85,
		quantity: 497,
	},
	{
		garage_id: 194,
		part_id: 86,
		quantity: 149,
	},
	{
		garage_id: 194,
		part_id: 87,
		quantity: 144,
	},
	{
		garage_id: 194,
		part_id: 88,
		quantity: 224,
	},
	{
		garage_id: 194,
		part_id: 89,
		quantity: 238,
	},
	{
		garage_id: 194,
		part_id: 90,
		quantity: 283,
	},
	{
		garage_id: 194,
		part_id: 91,
		quantity: 431,
	},
	{
		garage_id: 194,
		part_id: 92,
		quantity: 484,
	},
	{
		garage_id: 194,
		part_id: 93,
		quantity: 149,
	},
	{
		garage_id: 194,
		part_id: 94,
		quantity: 29,
	},
	{
		garage_id: 194,
		part_id: 95,
		quantity: 107,
	},
	{
		garage_id: 194,
		part_id: 96,
		quantity: 61,
	},
	{
		garage_id: 194,
		part_id: 97,
		quantity: 240,
	},
	{
		garage_id: 194,
		part_id: 98,
		quantity: 481,
	},
	{
		garage_id: 194,
		part_id: 99,
		quantity: 187,
	},
	{
		garage_id: 194,
		part_id: 100,
		quantity: 439,
	},
	{
		garage_id: 194,
		part_id: 101,
		quantity: 189,
	},
	{
		garage_id: 194,
		part_id: 102,
		quantity: 422,
	},
	{
		garage_id: 194,
		part_id: 103,
		quantity: 89,
	},
	{
		garage_id: 194,
		part_id: 104,
		quantity: 494,
	},
	{
		garage_id: 194,
		part_id: 105,
		quantity: 384,
	},
	{
		garage_id: 194,
		part_id: 106,
		quantity: 395,
	},
	{
		garage_id: 194,
		part_id: 107,
		quantity: 317,
	},
	{
		garage_id: 194,
		part_id: 108,
		quantity: 100,
	},
	{
		garage_id: 194,
		part_id: 109,
		quantity: 460,
	},
	{
		garage_id: 194,
		part_id: 110,
		quantity: 375,
	},
	{
		garage_id: 194,
		part_id: 111,
		quantity: 211,
	},
	{
		garage_id: 194,
		part_id: 112,
		quantity: 299,
	},
	{
		garage_id: 194,
		part_id: 113,
		quantity: 66,
	},
	{
		garage_id: 194,
		part_id: 114,
		quantity: 48,
	},
	{
		garage_id: 194,
		part_id: 115,
		quantity: 284,
	},
	{
		garage_id: 194,
		part_id: 116,
		quantity: 315,
	},
	{
		garage_id: 194,
		part_id: 117,
		quantity: 500,
	},
	{
		garage_id: 194,
		part_id: 118,
		quantity: 445,
	},
	{
		garage_id: 194,
		part_id: 119,
		quantity: 277,
	},
	{
		garage_id: 194,
		part_id: 120,
		quantity: 147,
	},
	{
		garage_id: 194,
		part_id: 121,
		quantity: 14,
	},
	{
		garage_id: 194,
		part_id: 122,
		quantity: 416,
	},
	{
		garage_id: 194,
		part_id: 123,
		quantity: 173,
	},
	{
		garage_id: 194,
		part_id: 124,
		quantity: 368,
	},
	{
		garage_id: 194,
		part_id: 125,
		quantity: 11,
	},
	{
		garage_id: 194,
		part_id: 126,
		quantity: 132,
	},
	{
		garage_id: 194,
		part_id: 127,
		quantity: 108,
	},
	{
		garage_id: 194,
		part_id: 128,
		quantity: 156,
	},
	{
		garage_id: 194,
		part_id: 129,
		quantity: 163,
	},
	{
		garage_id: 194,
		part_id: 130,
		quantity: 5,
	},
	{
		garage_id: 194,
		part_id: 131,
		quantity: 399,
	},
	{
		garage_id: 194,
		part_id: 132,
		quantity: 241,
	},
	{
		garage_id: 194,
		part_id: 133,
		quantity: 448,
	},
	{
		garage_id: 194,
		part_id: 134,
		quantity: 188,
	},
	{
		garage_id: 194,
		part_id: 135,
		quantity: 459,
	},
	{
		garage_id: 194,
		part_id: 136,
		quantity: 168,
	},
	{
		garage_id: 194,
		part_id: 137,
		quantity: 293,
	},
	{
		garage_id: 194,
		part_id: 138,
		quantity: 8,
	},
	{
		garage_id: 194,
		part_id: 139,
		quantity: 83,
	},
	{
		garage_id: 194,
		part_id: 140,
		quantity: 493,
	},
	{
		garage_id: 194,
		part_id: 141,
		quantity: 132,
	},
	{
		garage_id: 194,
		part_id: 142,
		quantity: 157,
	},
	{
		garage_id: 194,
		part_id: 143,
		quantity: 285,
	},
	{
		garage_id: 194,
		part_id: 144,
		quantity: 138,
	},
	{
		garage_id: 194,
		part_id: 145,
		quantity: 97,
	},
	{
		garage_id: 194,
		part_id: 146,
		quantity: 144,
	},
	{
		garage_id: 194,
		part_id: 147,
		quantity: 162,
	},
	{
		garage_id: 194,
		part_id: 148,
		quantity: 344,
	},
	{
		garage_id: 194,
		part_id: 149,
		quantity: 329,
	},
	{
		garage_id: 194,
		part_id: 150,
		quantity: 232,
	},
	{
		garage_id: 194,
		part_id: 151,
		quantity: 56,
	},
	{
		garage_id: 194,
		part_id: 152,
		quantity: 203,
	},
	{
		garage_id: 194,
		part_id: 153,
		quantity: 261,
	},
	{
		garage_id: 194,
		part_id: 154,
		quantity: 179,
	},
	{
		garage_id: 194,
		part_id: 155,
		quantity: 13,
	},
	{
		garage_id: 194,
		part_id: 156,
		quantity: 315,
	},
	{
		garage_id: 194,
		part_id: 157,
		quantity: 151,
	},
	{
		garage_id: 194,
		part_id: 158,
		quantity: 289,
	},
	{
		garage_id: 194,
		part_id: 159,
		quantity: 473,
	},
	{
		garage_id: 194,
		part_id: 160,
		quantity: 412,
	},
	{
		garage_id: 194,
		part_id: 161,
		quantity: 389,
	},
	{
		garage_id: 194,
		part_id: 162,
		quantity: 123,
	},
	{
		garage_id: 194,
		part_id: 163,
		quantity: 493,
	},
	{
		garage_id: 194,
		part_id: 164,
		quantity: 302,
	},
	{
		garage_id: 194,
		part_id: 165,
		quantity: 306,
	},
	{
		garage_id: 194,
		part_id: 166,
		quantity: 131,
	},
	{
		garage_id: 194,
		part_id: 167,
		quantity: 31,
	},
	{
		garage_id: 194,
		part_id: 168,
		quantity: 366,
	},
	{
		garage_id: 194,
		part_id: 169,
		quantity: 314,
	},
	{
		garage_id: 194,
		part_id: 170,
		quantity: 446,
	},
	{
		garage_id: 194,
		part_id: 171,
		quantity: 201,
	},
	{
		garage_id: 194,
		part_id: 172,
		quantity: 363,
	},
	{
		garage_id: 194,
		part_id: 173,
		quantity: 247,
	},
	{
		garage_id: 194,
		part_id: 174,
		quantity: 381,
	},
	{
		garage_id: 194,
		part_id: 175,
		quantity: 423,
	},
	{
		garage_id: 194,
		part_id: 176,
		quantity: 376,
	},
	{
		garage_id: 194,
		part_id: 177,
		quantity: 305,
	},
	{
		garage_id: 194,
		part_id: 178,
		quantity: 105,
	},
	{
		garage_id: 194,
		part_id: 179,
		quantity: 156,
	},
	{
		garage_id: 194,
		part_id: 180,
		quantity: 444,
	},
	{
		garage_id: 194,
		part_id: 181,
		quantity: 157,
	},
	{
		garage_id: 194,
		part_id: 182,
		quantity: 369,
	},
	{
		garage_id: 194,
		part_id: 183,
		quantity: 176,
	},
	{
		garage_id: 194,
		part_id: 184,
		quantity: 475,
	},
	{
		garage_id: 194,
		part_id: 185,
		quantity: 356,
	},
	{
		garage_id: 194,
		part_id: 186,
		quantity: 230,
	},
	{
		garage_id: 194,
		part_id: 187,
		quantity: 98,
	},
	{
		garage_id: 194,
		part_id: 188,
		quantity: 340,
	},
	{
		garage_id: 194,
		part_id: 189,
		quantity: 181,
	},
	{
		garage_id: 194,
		part_id: 190,
		quantity: 256,
	},
	{
		garage_id: 194,
		part_id: 191,
		quantity: 263,
	},
	{
		garage_id: 194,
		part_id: 192,
		quantity: 155,
	},
	{
		garage_id: 194,
		part_id: 193,
		quantity: 286,
	},
	{
		garage_id: 194,
		part_id: 194,
		quantity: 228,
	},
	{
		garage_id: 194,
		part_id: 195,
		quantity: 181,
	},
	{
		garage_id: 194,
		part_id: 196,
		quantity: 10,
	},
	{
		garage_id: 194,
		part_id: 197,
		quantity: 495,
	},
	{
		garage_id: 194,
		part_id: 198,
		quantity: 246,
	},
	{
		garage_id: 194,
		part_id: 199,
		quantity: 116,
	},
	{
		garage_id: 194,
		part_id: 200,
		quantity: 394,
	},
	{
		garage_id: 194,
		part_id: 201,
		quantity: 481,
	},
	{
		garage_id: 194,
		part_id: 202,
		quantity: 402,
	},
	{
		garage_id: 194,
		part_id: 203,
		quantity: 419,
	},
	{
		garage_id: 194,
		part_id: 204,
		quantity: 284,
	},
	{
		garage_id: 194,
		part_id: 205,
		quantity: 221,
	},
	{
		garage_id: 194,
		part_id: 206,
		quantity: 145,
	},
	{
		garage_id: 194,
		part_id: 207,
		quantity: 432,
	},
	{
		garage_id: 194,
		part_id: 208,
		quantity: 460,
	},
	{
		garage_id: 194,
		part_id: 209,
		quantity: 76,
	},
	{
		garage_id: 194,
		part_id: 210,
		quantity: 174,
	},
	{
		garage_id: 194,
		part_id: 211,
		quantity: 318,
	},
	{
		garage_id: 194,
		part_id: 212,
		quantity: 331,
	},
	{
		garage_id: 194,
		part_id: 213,
		quantity: 153,
	},
	{
		garage_id: 194,
		part_id: 214,
		quantity: 187,
	},
	{
		garage_id: 194,
		part_id: 215,
		quantity: 342,
	},
	{
		garage_id: 194,
		part_id: 216,
		quantity: 159,
	},
	{
		garage_id: 194,
		part_id: 217,
		quantity: 360,
	},
	{
		garage_id: 194,
		part_id: 218,
		quantity: 431,
	},
	{
		garage_id: 194,
		part_id: 219,
		quantity: 287,
	},
	{
		garage_id: 194,
		part_id: 220,
		quantity: 461,
	},
	{
		garage_id: 194,
		part_id: 221,
		quantity: 431,
	},
	{
		garage_id: 194,
		part_id: 222,
		quantity: 292,
	},
	{
		garage_id: 194,
		part_id: 223,
		quantity: 143,
	},
	{
		garage_id: 194,
		part_id: 224,
		quantity: 495,
	},
	{
		garage_id: 194,
		part_id: 225,
		quantity: 193,
	},
	{
		garage_id: 194,
		part_id: 226,
		quantity: 346,
	},
	{
		garage_id: 194,
		part_id: 227,
		quantity: 169,
	},
	{
		garage_id: 194,
		part_id: 228,
		quantity: 374,
	},
	{
		garage_id: 194,
		part_id: 229,
		quantity: 492,
	},
	{
		garage_id: 194,
		part_id: 230,
		quantity: 4,
	},
	{
		garage_id: 194,
		part_id: 231,
		quantity: 480,
	},
	{
		garage_id: 194,
		part_id: 232,
		quantity: 99,
	},
	{
		garage_id: 194,
		part_id: 233,
		quantity: 190,
	},
	{
		garage_id: 194,
		part_id: 234,
		quantity: 201,
	},
	{
		garage_id: 194,
		part_id: 235,
		quantity: 214,
	},
	{
		garage_id: 194,
		part_id: 236,
		quantity: 288,
	},
	{
		garage_id: 194,
		part_id: 237,
		quantity: 194,
	},
	{
		garage_id: 194,
		part_id: 238,
		quantity: 46,
	},
	{
		garage_id: 194,
		part_id: 239,
		quantity: 254,
	},
	{
		garage_id: 194,
		part_id: 240,
		quantity: 44,
	},
	{
		garage_id: 194,
		part_id: 241,
		quantity: 253,
	},
	{
		garage_id: 194,
		part_id: 242,
		quantity: 117,
	},
	{
		garage_id: 194,
		part_id: 243,
		quantity: 125,
	},
	{
		garage_id: 194,
		part_id: 244,
		quantity: 337,
	},
	{
		garage_id: 194,
		part_id: 245,
		quantity: 215,
	},
	{
		garage_id: 194,
		part_id: 246,
		quantity: 246,
	},
	{
		garage_id: 194,
		part_id: 247,
		quantity: 223,
	},
	{
		garage_id: 194,
		part_id: 248,
		quantity: 239,
	},
	{
		garage_id: 194,
		part_id: 249,
		quantity: 141,
	},
	{
		garage_id: 194,
		part_id: 250,
		quantity: 241,
	},
	{
		garage_id: 194,
		part_id: 251,
		quantity: 478,
	},
	{
		garage_id: 194,
		part_id: 252,
		quantity: 152,
	},
	{
		garage_id: 194,
		part_id: 253,
		quantity: 73,
	},
	{
		garage_id: 194,
		part_id: 254,
		quantity: 94,
	},
	{
		garage_id: 194,
		part_id: 255,
		quantity: 169,
	},
	{
		garage_id: 194,
		part_id: 256,
		quantity: 394,
	},
	{
		garage_id: 194,
		part_id: 257,
		quantity: 417,
	},
	{
		garage_id: 194,
		part_id: 258,
		quantity: 134,
	},
	{
		garage_id: 194,
		part_id: 259,
		quantity: 113,
	},
	{
		garage_id: 194,
		part_id: 260,
		quantity: 496,
	},
	{
		garage_id: 194,
		part_id: 261,
		quantity: 424,
	},
	{
		garage_id: 194,
		part_id: 262,
		quantity: 120,
	},
	{
		garage_id: 194,
		part_id: 263,
		quantity: 434,
	},
	{
		garage_id: 194,
		part_id: 264,
		quantity: 499,
	},
	{
		garage_id: 194,
		part_id: 265,
		quantity: 181,
	},
	{
		garage_id: 194,
		part_id: 266,
		quantity: 234,
	},
	{
		garage_id: 194,
		part_id: 267,
		quantity: 244,
	},
	{
		garage_id: 194,
		part_id: 268,
		quantity: 134,
	},
	{
		garage_id: 194,
		part_id: 269,
		quantity: 487,
	},
	{
		garage_id: 194,
		part_id: 270,
		quantity: 433,
	},
	{
		garage_id: 194,
		part_id: 271,
		quantity: 187,
	},
	{
		garage_id: 194,
		part_id: 272,
		quantity: 126,
	},
	{
		garage_id: 194,
		part_id: 273,
		quantity: 299,
	},
	{
		garage_id: 194,
		part_id: 274,
		quantity: 360,
	},
	{
		garage_id: 194,
		part_id: 275,
		quantity: 226,
	},
	{
		garage_id: 194,
		part_id: 276,
		quantity: 484,
	},
	{
		garage_id: 194,
		part_id: 277,
		quantity: 223,
	},
	{
		garage_id: 194,
		part_id: 278,
		quantity: 266,
	},
	{
		garage_id: 194,
		part_id: 279,
		quantity: 425,
	},
	{
		garage_id: 194,
		part_id: 280,
		quantity: 202,
	},
	{
		garage_id: 194,
		part_id: 281,
		quantity: 156,
	},
	{
		garage_id: 194,
		part_id: 282,
		quantity: 470,
	},
	{
		garage_id: 194,
		part_id: 283,
		quantity: 389,
	},
	{
		garage_id: 194,
		part_id: 284,
		quantity: 266,
	},
	{
		garage_id: 194,
		part_id: 285,
		quantity: 392,
	},
	{
		garage_id: 194,
		part_id: 286,
		quantity: 292,
	},
	{
		garage_id: 194,
		part_id: 287,
		quantity: 456,
	},
	{
		garage_id: 194,
		part_id: 288,
		quantity: 381,
	},
	{
		garage_id: 194,
		part_id: 289,
		quantity: 79,
	},
	{
		garage_id: 194,
		part_id: 290,
		quantity: 40,
	},
	{
		garage_id: 194,
		part_id: 291,
		quantity: 302,
	},
	{
		garage_id: 194,
		part_id: 292,
		quantity: 31,
	},
	{
		garage_id: 194,
		part_id: 293,
		quantity: 302,
	},
	{
		garage_id: 194,
		part_id: 294,
		quantity: 354,
	},
	{
		garage_id: 194,
		part_id: 295,
		quantity: 35,
	},
	{
		garage_id: 194,
		part_id: 296,
		quantity: 132,
	},
	{
		garage_id: 194,
		part_id: 297,
		quantity: 36,
	},
	{
		garage_id: 194,
		part_id: 298,
		quantity: 126,
	},
	{
		garage_id: 194,
		part_id: 299,
		quantity: 167,
	},
	{
		garage_id: 194,
		part_id: 300,
		quantity: 455,
	},
	{
		garage_id: 194,
		part_id: 301,
		quantity: 370,
	},
	{
		garage_id: 194,
		part_id: 302,
		quantity: 278,
	},
	{
		garage_id: 194,
		part_id: 303,
		quantity: 480,
	},
	{
		garage_id: 194,
		part_id: 304,
		quantity: 96,
	},
	{
		garage_id: 194,
		part_id: 305,
		quantity: 204,
	},
	{
		garage_id: 194,
		part_id: 306,
		quantity: 460,
	},
	{
		garage_id: 194,
		part_id: 307,
		quantity: 172,
	},
	{
		garage_id: 194,
		part_id: 308,
		quantity: 471,
	},
	{
		garage_id: 194,
		part_id: 309,
		quantity: 226,
	},
	{
		garage_id: 194,
		part_id: 310,
		quantity: 310,
	},
	{
		garage_id: 194,
		part_id: 311,
		quantity: 391,
	},
	{
		garage_id: 194,
		part_id: 312,
		quantity: 319,
	},
	{
		garage_id: 194,
		part_id: 313,
		quantity: 456,
	},
	{
		garage_id: 194,
		part_id: 314,
		quantity: 18,
	},
	{
		garage_id: 194,
		part_id: 315,
		quantity: 467,
	},
	{
		garage_id: 194,
		part_id: 316,
		quantity: 371,
	},
	{
		garage_id: 194,
		part_id: 317,
		quantity: 314,
	},
	{
		garage_id: 194,
		part_id: 318,
		quantity: 357,
	},
	{
		garage_id: 194,
		part_id: 319,
		quantity: 38,
	},
	{
		garage_id: 194,
		part_id: 320,
		quantity: 209,
	},
	{
		garage_id: 194,
		part_id: 321,
		quantity: 15,
	},
	{
		garage_id: 194,
		part_id: 322,
		quantity: 166,
	},
	{
		garage_id: 194,
		part_id: 323,
		quantity: 213,
	},
	{
		garage_id: 194,
		part_id: 324,
		quantity: 468,
	},
	{
		garage_id: 194,
		part_id: 325,
		quantity: 50,
	},
	{
		garage_id: 194,
		part_id: 326,
		quantity: 312,
	},
	{
		garage_id: 194,
		part_id: 327,
		quantity: 156,
	},
	{
		garage_id: 194,
		part_id: 328,
		quantity: 175,
	},
	{
		garage_id: 194,
		part_id: 329,
		quantity: 250,
	},
	{
		garage_id: 194,
		part_id: 330,
		quantity: 312,
	},
	{
		garage_id: 194,
		part_id: 331,
		quantity: 179,
	},
	{
		garage_id: 194,
		part_id: 332,
		quantity: 52,
	},
	{
		garage_id: 194,
		part_id: 333,
		quantity: 27,
	},
	{
		garage_id: 194,
		part_id: 334,
		quantity: 436,
	},
	{
		garage_id: 194,
		part_id: 335,
		quantity: 407,
	},
	{
		garage_id: 194,
		part_id: 336,
		quantity: 233,
	},
	{
		garage_id: 194,
		part_id: 337,
		quantity: 295,
	},
	{
		garage_id: 194,
		part_id: 338,
		quantity: 38,
	},
	{
		garage_id: 194,
		part_id: 339,
		quantity: 188,
	},
	{
		garage_id: 194,
		part_id: 340,
		quantity: 266,
	},
	{
		garage_id: 194,
		part_id: 341,
		quantity: 414,
	},
	{
		garage_id: 194,
		part_id: 342,
		quantity: 441,
	},
	{
		garage_id: 194,
		part_id: 343,
		quantity: 6,
	},
	{
		garage_id: 194,
		part_id: 344,
		quantity: 316,
	},
	{
		garage_id: 194,
		part_id: 345,
		quantity: 367,
	},
	{
		garage_id: 194,
		part_id: 346,
		quantity: 113,
	},
	{
		garage_id: 194,
		part_id: 347,
		quantity: 359,
	},
	{
		garage_id: 194,
		part_id: 348,
		quantity: 170,
	},
	{
		garage_id: 194,
		part_id: 349,
		quantity: 447,
	},
	{
		garage_id: 194,
		part_id: 350,
		quantity: 459,
	},
	{
		garage_id: 194,
		part_id: 351,
		quantity: 417,
	},
	{
		garage_id: 194,
		part_id: 352,
		quantity: 272,
	},
	{
		garage_id: 194,
		part_id: 353,
		quantity: 426,
	},
	{
		garage_id: 194,
		part_id: 354,
		quantity: 175,
	},
	{
		garage_id: 194,
		part_id: 355,
		quantity: 107,
	},
	{
		garage_id: 194,
		part_id: 356,
		quantity: 271,
	},
	{
		garage_id: 194,
		part_id: 357,
		quantity: 47,
	},
	{
		garage_id: 194,
		part_id: 358,
		quantity: 194,
	},
	{
		garage_id: 194,
		part_id: 359,
		quantity: 49,
	},
	{
		garage_id: 194,
		part_id: 360,
		quantity: 419,
	},
	{
		garage_id: 194,
		part_id: 361,
		quantity: 90,
	},
	{
		garage_id: 194,
		part_id: 362,
		quantity: 373,
	},
	{
		garage_id: 194,
		part_id: 363,
		quantity: 429,
	},
	{
		garage_id: 194,
		part_id: 364,
		quantity: 234,
	},
	{
		garage_id: 194,
		part_id: 365,
		quantity: 367,
	},
	{
		garage_id: 194,
		part_id: 366,
		quantity: 228,
	},
	{
		garage_id: 194,
		part_id: 367,
		quantity: 348,
	},
	{
		garage_id: 194,
		part_id: 368,
		quantity: 429,
	},
	{
		garage_id: 194,
		part_id: 369,
		quantity: 291,
	},
	{
		garage_id: 194,
		part_id: 370,
		quantity: 75,
	},
	{
		garage_id: 194,
		part_id: 371,
		quantity: 34,
	},
	{
		garage_id: 194,
		part_id: 372,
		quantity: 435,
	},
	{
		garage_id: 194,
		part_id: 373,
		quantity: 169,
	},
	{
		garage_id: 194,
		part_id: 374,
		quantity: 238,
	},
	{
		garage_id: 194,
		part_id: 375,
		quantity: 228,
	},
	{
		garage_id: 194,
		part_id: 376,
		quantity: 172,
	},
	{
		garage_id: 194,
		part_id: 377,
		quantity: 433,
	},
	{
		garage_id: 194,
		part_id: 378,
		quantity: 292,
	},
	{
		garage_id: 194,
		part_id: 379,
		quantity: 372,
	},
	{
		garage_id: 194,
		part_id: 380,
		quantity: 396,
	},
	{
		garage_id: 194,
		part_id: 381,
		quantity: 446,
	},
	{
		garage_id: 194,
		part_id: 382,
		quantity: 286,
	},
	{
		garage_id: 194,
		part_id: 383,
		quantity: 437,
	},
	{
		garage_id: 194,
		part_id: 384,
		quantity: 57,
	},
	{
		garage_id: 194,
		part_id: 385,
		quantity: 497,
	},
	{
		garage_id: 194,
		part_id: 386,
		quantity: 432,
	},
	{
		garage_id: 194,
		part_id: 387,
		quantity: 0,
	},
	{
		garage_id: 194,
		part_id: 388,
		quantity: 286,
	},
	{
		garage_id: 194,
		part_id: 389,
		quantity: 149,
	},
	{
		garage_id: 194,
		part_id: 390,
		quantity: 335,
	},
	{
		garage_id: 194,
		part_id: 391,
		quantity: 278,
	},
	{
		garage_id: 194,
		part_id: 392,
		quantity: 272,
	},
	{
		garage_id: 194,
		part_id: 393,
		quantity: 398,
	},
	{
		garage_id: 194,
		part_id: 394,
		quantity: 235,
	},
	{
		garage_id: 194,
		part_id: 395,
		quantity: 338,
	},
	{
		garage_id: 194,
		part_id: 396,
		quantity: 261,
	},
	{
		garage_id: 194,
		part_id: 397,
		quantity: 296,
	},
	{
		garage_id: 194,
		part_id: 398,
		quantity: 423,
	},
	{
		garage_id: 194,
		part_id: 399,
		quantity: 256,
	},
	{
		garage_id: 195,
		part_id: 0,
		quantity: 238,
	},
	{
		garage_id: 195,
		part_id: 1,
		quantity: 5,
	},
	{
		garage_id: 195,
		part_id: 2,
		quantity: 453,
	},
	{
		garage_id: 195,
		part_id: 3,
		quantity: 262,
	},
	{
		garage_id: 195,
		part_id: 4,
		quantity: 177,
	},
	{
		garage_id: 195,
		part_id: 5,
		quantity: 408,
	},
	{
		garage_id: 195,
		part_id: 6,
		quantity: 79,
	},
	{
		garage_id: 195,
		part_id: 7,
		quantity: 282,
	},
	{
		garage_id: 195,
		part_id: 8,
		quantity: 446,
	},
	{
		garage_id: 195,
		part_id: 9,
		quantity: 463,
	},
	{
		garage_id: 195,
		part_id: 10,
		quantity: 356,
	},
	{
		garage_id: 195,
		part_id: 11,
		quantity: 389,
	},
	{
		garage_id: 195,
		part_id: 12,
		quantity: 315,
	},
	{
		garage_id: 195,
		part_id: 13,
		quantity: 201,
	},
	{
		garage_id: 195,
		part_id: 14,
		quantity: 126,
	},
	{
		garage_id: 195,
		part_id: 15,
		quantity: 243,
	},
	{
		garage_id: 195,
		part_id: 16,
		quantity: 156,
	},
	{
		garage_id: 195,
		part_id: 17,
		quantity: 206,
	},
	{
		garage_id: 195,
		part_id: 18,
		quantity: 150,
	},
	{
		garage_id: 195,
		part_id: 19,
		quantity: 63,
	},
	{
		garage_id: 195,
		part_id: 20,
		quantity: 410,
	},
	{
		garage_id: 195,
		part_id: 21,
		quantity: 193,
	},
	{
		garage_id: 195,
		part_id: 22,
		quantity: 306,
	},
	{
		garage_id: 195,
		part_id: 23,
		quantity: 499,
	},
	{
		garage_id: 195,
		part_id: 24,
		quantity: 41,
	},
	{
		garage_id: 195,
		part_id: 25,
		quantity: 204,
	},
	{
		garage_id: 195,
		part_id: 26,
		quantity: 84,
	},
	{
		garage_id: 195,
		part_id: 27,
		quantity: 162,
	},
	{
		garage_id: 195,
		part_id: 28,
		quantity: 147,
	},
	{
		garage_id: 195,
		part_id: 29,
		quantity: 190,
	},
	{
		garage_id: 195,
		part_id: 30,
		quantity: 477,
	},
	{
		garage_id: 195,
		part_id: 31,
		quantity: 372,
	},
	{
		garage_id: 195,
		part_id: 32,
		quantity: 136,
	},
	{
		garage_id: 195,
		part_id: 33,
		quantity: 127,
	},
	{
		garage_id: 195,
		part_id: 34,
		quantity: 202,
	},
	{
		garage_id: 195,
		part_id: 35,
		quantity: 326,
	},
	{
		garage_id: 195,
		part_id: 36,
		quantity: 6,
	},
	{
		garage_id: 195,
		part_id: 37,
		quantity: 201,
	},
	{
		garage_id: 195,
		part_id: 38,
		quantity: 277,
	},
	{
		garage_id: 195,
		part_id: 39,
		quantity: 126,
	},
	{
		garage_id: 195,
		part_id: 40,
		quantity: 254,
	},
	{
		garage_id: 195,
		part_id: 41,
		quantity: 58,
	},
	{
		garage_id: 195,
		part_id: 42,
		quantity: 383,
	},
	{
		garage_id: 195,
		part_id: 43,
		quantity: 359,
	},
	{
		garage_id: 195,
		part_id: 44,
		quantity: 312,
	},
	{
		garage_id: 195,
		part_id: 45,
		quantity: 264,
	},
	{
		garage_id: 195,
		part_id: 46,
		quantity: 339,
	},
	{
		garage_id: 195,
		part_id: 47,
		quantity: 110,
	},
	{
		garage_id: 195,
		part_id: 48,
		quantity: 476,
	},
	{
		garage_id: 195,
		part_id: 49,
		quantity: 430,
	},
	{
		garage_id: 195,
		part_id: 50,
		quantity: 364,
	},
	{
		garage_id: 195,
		part_id: 51,
		quantity: 230,
	},
	{
		garage_id: 195,
		part_id: 52,
		quantity: 179,
	},
	{
		garage_id: 195,
		part_id: 53,
		quantity: 186,
	},
	{
		garage_id: 195,
		part_id: 54,
		quantity: 95,
	},
	{
		garage_id: 195,
		part_id: 55,
		quantity: 22,
	},
	{
		garage_id: 195,
		part_id: 56,
		quantity: 477,
	},
	{
		garage_id: 195,
		part_id: 57,
		quantity: 500,
	},
	{
		garage_id: 195,
		part_id: 58,
		quantity: 211,
	},
	{
		garage_id: 195,
		part_id: 59,
		quantity: 286,
	},
	{
		garage_id: 195,
		part_id: 60,
		quantity: 137,
	},
	{
		garage_id: 195,
		part_id: 61,
		quantity: 99,
	},
	{
		garage_id: 195,
		part_id: 62,
		quantity: 210,
	},
	{
		garage_id: 195,
		part_id: 63,
		quantity: 163,
	},
	{
		garage_id: 195,
		part_id: 64,
		quantity: 51,
	},
	{
		garage_id: 195,
		part_id: 65,
		quantity: 197,
	},
	{
		garage_id: 195,
		part_id: 66,
		quantity: 297,
	},
	{
		garage_id: 195,
		part_id: 67,
		quantity: 53,
	},
	{
		garage_id: 195,
		part_id: 68,
		quantity: 416,
	},
	{
		garage_id: 195,
		part_id: 69,
		quantity: 5,
	},
	{
		garage_id: 195,
		part_id: 70,
		quantity: 249,
	},
	{
		garage_id: 195,
		part_id: 71,
		quantity: 97,
	},
	{
		garage_id: 195,
		part_id: 72,
		quantity: 250,
	},
	{
		garage_id: 195,
		part_id: 73,
		quantity: 400,
	},
	{
		garage_id: 195,
		part_id: 74,
		quantity: 495,
	},
	{
		garage_id: 195,
		part_id: 75,
		quantity: 64,
	},
	{
		garage_id: 195,
		part_id: 76,
		quantity: 190,
	},
	{
		garage_id: 195,
		part_id: 77,
		quantity: 172,
	},
	{
		garage_id: 195,
		part_id: 78,
		quantity: 459,
	},
	{
		garage_id: 195,
		part_id: 79,
		quantity: 325,
	},
	{
		garage_id: 195,
		part_id: 80,
		quantity: 294,
	},
	{
		garage_id: 195,
		part_id: 81,
		quantity: 65,
	},
	{
		garage_id: 195,
		part_id: 82,
		quantity: 80,
	},
	{
		garage_id: 195,
		part_id: 83,
		quantity: 97,
	},
	{
		garage_id: 195,
		part_id: 84,
		quantity: 331,
	},
	{
		garage_id: 195,
		part_id: 85,
		quantity: 297,
	},
	{
		garage_id: 195,
		part_id: 86,
		quantity: 241,
	},
	{
		garage_id: 195,
		part_id: 87,
		quantity: 97,
	},
	{
		garage_id: 195,
		part_id: 88,
		quantity: 179,
	},
	{
		garage_id: 195,
		part_id: 89,
		quantity: 53,
	},
	{
		garage_id: 195,
		part_id: 90,
		quantity: 268,
	},
	{
		garage_id: 195,
		part_id: 91,
		quantity: 243,
	},
	{
		garage_id: 195,
		part_id: 92,
		quantity: 56,
	},
	{
		garage_id: 195,
		part_id: 93,
		quantity: 41,
	},
	{
		garage_id: 195,
		part_id: 94,
		quantity: 36,
	},
	{
		garage_id: 195,
		part_id: 95,
		quantity: 360,
	},
	{
		garage_id: 195,
		part_id: 96,
		quantity: 475,
	},
	{
		garage_id: 195,
		part_id: 97,
		quantity: 434,
	},
	{
		garage_id: 195,
		part_id: 98,
		quantity: 417,
	},
	{
		garage_id: 195,
		part_id: 99,
		quantity: 212,
	},
	{
		garage_id: 195,
		part_id: 100,
		quantity: 196,
	},
	{
		garage_id: 195,
		part_id: 101,
		quantity: 179,
	},
	{
		garage_id: 195,
		part_id: 102,
		quantity: 455,
	},
	{
		garage_id: 195,
		part_id: 103,
		quantity: 385,
	},
	{
		garage_id: 195,
		part_id: 104,
		quantity: 495,
	},
	{
		garage_id: 195,
		part_id: 105,
		quantity: 221,
	},
	{
		garage_id: 195,
		part_id: 106,
		quantity: 494,
	},
	{
		garage_id: 195,
		part_id: 107,
		quantity: 276,
	},
	{
		garage_id: 195,
		part_id: 108,
		quantity: 444,
	},
	{
		garage_id: 195,
		part_id: 109,
		quantity: 255,
	},
	{
		garage_id: 195,
		part_id: 110,
		quantity: 24,
	},
	{
		garage_id: 195,
		part_id: 111,
		quantity: 147,
	},
	{
		garage_id: 195,
		part_id: 112,
		quantity: 15,
	},
	{
		garage_id: 195,
		part_id: 113,
		quantity: 125,
	},
	{
		garage_id: 195,
		part_id: 114,
		quantity: 4,
	},
	{
		garage_id: 195,
		part_id: 115,
		quantity: 200,
	},
	{
		garage_id: 195,
		part_id: 116,
		quantity: 386,
	},
	{
		garage_id: 195,
		part_id: 117,
		quantity: 262,
	},
	{
		garage_id: 195,
		part_id: 118,
		quantity: 487,
	},
	{
		garage_id: 195,
		part_id: 119,
		quantity: 470,
	},
	{
		garage_id: 195,
		part_id: 120,
		quantity: 370,
	},
	{
		garage_id: 195,
		part_id: 121,
		quantity: 12,
	},
	{
		garage_id: 195,
		part_id: 122,
		quantity: 454,
	},
	{
		garage_id: 195,
		part_id: 123,
		quantity: 142,
	},
	{
		garage_id: 195,
		part_id: 124,
		quantity: 76,
	},
	{
		garage_id: 195,
		part_id: 125,
		quantity: 43,
	},
	{
		garage_id: 195,
		part_id: 126,
		quantity: 375,
	},
	{
		garage_id: 195,
		part_id: 127,
		quantity: 450,
	},
	{
		garage_id: 195,
		part_id: 128,
		quantity: 494,
	},
	{
		garage_id: 195,
		part_id: 129,
		quantity: 277,
	},
	{
		garage_id: 195,
		part_id: 130,
		quantity: 88,
	},
	{
		garage_id: 195,
		part_id: 131,
		quantity: 441,
	},
	{
		garage_id: 195,
		part_id: 132,
		quantity: 28,
	},
	{
		garage_id: 195,
		part_id: 133,
		quantity: 477,
	},
	{
		garage_id: 195,
		part_id: 134,
		quantity: 164,
	},
	{
		garage_id: 195,
		part_id: 135,
		quantity: 237,
	},
	{
		garage_id: 195,
		part_id: 136,
		quantity: 194,
	},
	{
		garage_id: 195,
		part_id: 137,
		quantity: 16,
	},
	{
		garage_id: 195,
		part_id: 138,
		quantity: 4,
	},
	{
		garage_id: 195,
		part_id: 139,
		quantity: 323,
	},
	{
		garage_id: 195,
		part_id: 140,
		quantity: 246,
	},
	{
		garage_id: 195,
		part_id: 141,
		quantity: 306,
	},
	{
		garage_id: 195,
		part_id: 142,
		quantity: 168,
	},
	{
		garage_id: 195,
		part_id: 143,
		quantity: 10,
	},
	{
		garage_id: 195,
		part_id: 144,
		quantity: 485,
	},
	{
		garage_id: 195,
		part_id: 145,
		quantity: 22,
	},
	{
		garage_id: 195,
		part_id: 146,
		quantity: 279,
	},
	{
		garage_id: 195,
		part_id: 147,
		quantity: 140,
	},
	{
		garage_id: 195,
		part_id: 148,
		quantity: 82,
	},
	{
		garage_id: 195,
		part_id: 149,
		quantity: 205,
	},
	{
		garage_id: 195,
		part_id: 150,
		quantity: 341,
	},
	{
		garage_id: 195,
		part_id: 151,
		quantity: 212,
	},
	{
		garage_id: 195,
		part_id: 152,
		quantity: 283,
	},
	{
		garage_id: 195,
		part_id: 153,
		quantity: 119,
	},
	{
		garage_id: 195,
		part_id: 154,
		quantity: 64,
	},
	{
		garage_id: 195,
		part_id: 155,
		quantity: 118,
	},
	{
		garage_id: 195,
		part_id: 156,
		quantity: 67,
	},
	{
		garage_id: 195,
		part_id: 157,
		quantity: 204,
	},
	{
		garage_id: 195,
		part_id: 158,
		quantity: 2,
	},
	{
		garage_id: 195,
		part_id: 159,
		quantity: 459,
	},
	{
		garage_id: 195,
		part_id: 160,
		quantity: 443,
	},
	{
		garage_id: 195,
		part_id: 161,
		quantity: 15,
	},
	{
		garage_id: 195,
		part_id: 162,
		quantity: 287,
	},
	{
		garage_id: 195,
		part_id: 163,
		quantity: 235,
	},
	{
		garage_id: 195,
		part_id: 164,
		quantity: 263,
	},
	{
		garage_id: 195,
		part_id: 165,
		quantity: 301,
	},
	{
		garage_id: 195,
		part_id: 166,
		quantity: 263,
	},
	{
		garage_id: 195,
		part_id: 167,
		quantity: 462,
	},
	{
		garage_id: 195,
		part_id: 168,
		quantity: 33,
	},
	{
		garage_id: 195,
		part_id: 169,
		quantity: 12,
	},
	{
		garage_id: 195,
		part_id: 170,
		quantity: 379,
	},
	{
		garage_id: 195,
		part_id: 171,
		quantity: 247,
	},
	{
		garage_id: 195,
		part_id: 172,
		quantity: 143,
	},
	{
		garage_id: 195,
		part_id: 173,
		quantity: 209,
	},
	{
		garage_id: 195,
		part_id: 174,
		quantity: 328,
	},
	{
		garage_id: 195,
		part_id: 175,
		quantity: 331,
	},
	{
		garage_id: 195,
		part_id: 176,
		quantity: 126,
	},
	{
		garage_id: 195,
		part_id: 177,
		quantity: 442,
	},
	{
		garage_id: 195,
		part_id: 178,
		quantity: 197,
	},
	{
		garage_id: 195,
		part_id: 179,
		quantity: 417,
	},
	{
		garage_id: 195,
		part_id: 180,
		quantity: 113,
	},
	{
		garage_id: 195,
		part_id: 181,
		quantity: 81,
	},
	{
		garage_id: 195,
		part_id: 182,
		quantity: 148,
	},
	{
		garage_id: 195,
		part_id: 183,
		quantity: 135,
	},
	{
		garage_id: 195,
		part_id: 184,
		quantity: 133,
	},
	{
		garage_id: 195,
		part_id: 185,
		quantity: 314,
	},
	{
		garage_id: 195,
		part_id: 186,
		quantity: 310,
	},
	{
		garage_id: 195,
		part_id: 187,
		quantity: 377,
	},
	{
		garage_id: 195,
		part_id: 188,
		quantity: 306,
	},
	{
		garage_id: 195,
		part_id: 189,
		quantity: 17,
	},
	{
		garage_id: 195,
		part_id: 190,
		quantity: 270,
	},
	{
		garage_id: 195,
		part_id: 191,
		quantity: 463,
	},
	{
		garage_id: 195,
		part_id: 192,
		quantity: 142,
	},
	{
		garage_id: 195,
		part_id: 193,
		quantity: 127,
	},
	{
		garage_id: 195,
		part_id: 194,
		quantity: 100,
	},
	{
		garage_id: 195,
		part_id: 195,
		quantity: 433,
	},
	{
		garage_id: 195,
		part_id: 196,
		quantity: 165,
	},
	{
		garage_id: 195,
		part_id: 197,
		quantity: 226,
	},
	{
		garage_id: 195,
		part_id: 198,
		quantity: 407,
	},
	{
		garage_id: 195,
		part_id: 199,
		quantity: 194,
	},
	{
		garage_id: 195,
		part_id: 200,
		quantity: 124,
	},
	{
		garage_id: 195,
		part_id: 201,
		quantity: 340,
	},
	{
		garage_id: 195,
		part_id: 202,
		quantity: 71,
	},
	{
		garage_id: 195,
		part_id: 203,
		quantity: 109,
	},
	{
		garage_id: 195,
		part_id: 204,
		quantity: 1,
	},
	{
		garage_id: 195,
		part_id: 205,
		quantity: 312,
	},
	{
		garage_id: 195,
		part_id: 206,
		quantity: 153,
	},
	{
		garage_id: 195,
		part_id: 207,
		quantity: 389,
	},
	{
		garage_id: 195,
		part_id: 208,
		quantity: 98,
	},
	{
		garage_id: 195,
		part_id: 209,
		quantity: 395,
	},
	{
		garage_id: 195,
		part_id: 210,
		quantity: 45,
	},
	{
		garage_id: 195,
		part_id: 211,
		quantity: 266,
	},
	{
		garage_id: 195,
		part_id: 212,
		quantity: 364,
	},
	{
		garage_id: 195,
		part_id: 213,
		quantity: 212,
	},
	{
		garage_id: 195,
		part_id: 214,
		quantity: 204,
	},
	{
		garage_id: 195,
		part_id: 215,
		quantity: 203,
	},
	{
		garage_id: 195,
		part_id: 216,
		quantity: 76,
	},
	{
		garage_id: 195,
		part_id: 217,
		quantity: 54,
	},
	{
		garage_id: 195,
		part_id: 218,
		quantity: 390,
	},
	{
		garage_id: 195,
		part_id: 219,
		quantity: 131,
	},
	{
		garage_id: 195,
		part_id: 220,
		quantity: 329,
	},
	{
		garage_id: 195,
		part_id: 221,
		quantity: 481,
	},
	{
		garage_id: 195,
		part_id: 222,
		quantity: 144,
	},
	{
		garage_id: 195,
		part_id: 223,
		quantity: 383,
	},
	{
		garage_id: 195,
		part_id: 224,
		quantity: 356,
	},
	{
		garage_id: 195,
		part_id: 225,
		quantity: 141,
	},
	{
		garage_id: 195,
		part_id: 226,
		quantity: 308,
	},
	{
		garage_id: 195,
		part_id: 227,
		quantity: 429,
	},
	{
		garage_id: 195,
		part_id: 228,
		quantity: 481,
	},
	{
		garage_id: 195,
		part_id: 229,
		quantity: 54,
	},
	{
		garage_id: 195,
		part_id: 230,
		quantity: 409,
	},
	{
		garage_id: 195,
		part_id: 231,
		quantity: 75,
	},
	{
		garage_id: 195,
		part_id: 232,
		quantity: 156,
	},
	{
		garage_id: 195,
		part_id: 233,
		quantity: 132,
	},
	{
		garage_id: 195,
		part_id: 234,
		quantity: 327,
	},
	{
		garage_id: 195,
		part_id: 235,
		quantity: 305,
	},
	{
		garage_id: 195,
		part_id: 236,
		quantity: 192,
	},
	{
		garage_id: 195,
		part_id: 237,
		quantity: 480,
	},
	{
		garage_id: 195,
		part_id: 238,
		quantity: 80,
	},
	{
		garage_id: 195,
		part_id: 239,
		quantity: 376,
	},
	{
		garage_id: 195,
		part_id: 240,
		quantity: 385,
	},
	{
		garage_id: 195,
		part_id: 241,
		quantity: 196,
	},
	{
		garage_id: 195,
		part_id: 242,
		quantity: 140,
	},
	{
		garage_id: 195,
		part_id: 243,
		quantity: 319,
	},
	{
		garage_id: 195,
		part_id: 244,
		quantity: 204,
	},
	{
		garage_id: 195,
		part_id: 245,
		quantity: 90,
	},
	{
		garage_id: 195,
		part_id: 246,
		quantity: 171,
	},
	{
		garage_id: 195,
		part_id: 247,
		quantity: 313,
	},
	{
		garage_id: 195,
		part_id: 248,
		quantity: 293,
	},
	{
		garage_id: 195,
		part_id: 249,
		quantity: 457,
	},
	{
		garage_id: 195,
		part_id: 250,
		quantity: 209,
	},
	{
		garage_id: 195,
		part_id: 251,
		quantity: 105,
	},
	{
		garage_id: 195,
		part_id: 252,
		quantity: 306,
	},
	{
		garage_id: 195,
		part_id: 253,
		quantity: 231,
	},
	{
		garage_id: 195,
		part_id: 254,
		quantity: 371,
	},
	{
		garage_id: 195,
		part_id: 255,
		quantity: 169,
	},
	{
		garage_id: 195,
		part_id: 256,
		quantity: 233,
	},
	{
		garage_id: 195,
		part_id: 257,
		quantity: 216,
	},
	{
		garage_id: 195,
		part_id: 258,
		quantity: 89,
	},
	{
		garage_id: 195,
		part_id: 259,
		quantity: 239,
	},
	{
		garage_id: 195,
		part_id: 260,
		quantity: 450,
	},
	{
		garage_id: 195,
		part_id: 261,
		quantity: 280,
	},
	{
		garage_id: 195,
		part_id: 262,
		quantity: 350,
	},
	{
		garage_id: 195,
		part_id: 263,
		quantity: 207,
	},
	{
		garage_id: 195,
		part_id: 264,
		quantity: 165,
	},
	{
		garage_id: 195,
		part_id: 265,
		quantity: 123,
	},
	{
		garage_id: 195,
		part_id: 266,
		quantity: 233,
	},
	{
		garage_id: 195,
		part_id: 267,
		quantity: 268,
	},
	{
		garage_id: 195,
		part_id: 268,
		quantity: 431,
	},
	{
		garage_id: 195,
		part_id: 269,
		quantity: 428,
	},
	{
		garage_id: 195,
		part_id: 270,
		quantity: 166,
	},
	{
		garage_id: 195,
		part_id: 271,
		quantity: 63,
	},
	{
		garage_id: 195,
		part_id: 272,
		quantity: 33,
	},
	{
		garage_id: 195,
		part_id: 273,
		quantity: 389,
	},
	{
		garage_id: 195,
		part_id: 274,
		quantity: 301,
	},
	{
		garage_id: 195,
		part_id: 275,
		quantity: 460,
	},
	{
		garage_id: 195,
		part_id: 276,
		quantity: 430,
	},
	{
		garage_id: 195,
		part_id: 277,
		quantity: 177,
	},
	{
		garage_id: 195,
		part_id: 278,
		quantity: 173,
	},
	{
		garage_id: 195,
		part_id: 279,
		quantity: 283,
	},
	{
		garage_id: 195,
		part_id: 280,
		quantity: 480,
	},
	{
		garage_id: 195,
		part_id: 281,
		quantity: 270,
	},
	{
		garage_id: 195,
		part_id: 282,
		quantity: 401,
	},
	{
		garage_id: 195,
		part_id: 283,
		quantity: 207,
	},
	{
		garage_id: 195,
		part_id: 284,
		quantity: 485,
	},
	{
		garage_id: 195,
		part_id: 285,
		quantity: 461,
	},
	{
		garage_id: 195,
		part_id: 286,
		quantity: 19,
	},
	{
		garage_id: 195,
		part_id: 287,
		quantity: 78,
	},
	{
		garage_id: 195,
		part_id: 288,
		quantity: 105,
	},
	{
		garage_id: 195,
		part_id: 289,
		quantity: 338,
	},
	{
		garage_id: 195,
		part_id: 290,
		quantity: 18,
	},
	{
		garage_id: 195,
		part_id: 291,
		quantity: 95,
	},
	{
		garage_id: 195,
		part_id: 292,
		quantity: 64,
	},
	{
		garage_id: 195,
		part_id: 293,
		quantity: 422,
	},
	{
		garage_id: 195,
		part_id: 294,
		quantity: 337,
	},
	{
		garage_id: 195,
		part_id: 295,
		quantity: 293,
	},
	{
		garage_id: 195,
		part_id: 296,
		quantity: 489,
	},
	{
		garage_id: 195,
		part_id: 297,
		quantity: 238,
	},
	{
		garage_id: 195,
		part_id: 298,
		quantity: 173,
	},
	{
		garage_id: 195,
		part_id: 299,
		quantity: 294,
	},
	{
		garage_id: 195,
		part_id: 300,
		quantity: 200,
	},
	{
		garage_id: 195,
		part_id: 301,
		quantity: 464,
	},
	{
		garage_id: 195,
		part_id: 302,
		quantity: 36,
	},
	{
		garage_id: 195,
		part_id: 303,
		quantity: 71,
	},
	{
		garage_id: 195,
		part_id: 304,
		quantity: 291,
	},
	{
		garage_id: 195,
		part_id: 305,
		quantity: 415,
	},
	{
		garage_id: 195,
		part_id: 306,
		quantity: 413,
	},
	{
		garage_id: 195,
		part_id: 307,
		quantity: 401,
	},
	{
		garage_id: 195,
		part_id: 308,
		quantity: 277,
	},
	{
		garage_id: 195,
		part_id: 309,
		quantity: 229,
	},
	{
		garage_id: 195,
		part_id: 310,
		quantity: 366,
	},
	{
		garage_id: 195,
		part_id: 311,
		quantity: 166,
	},
	{
		garage_id: 195,
		part_id: 312,
		quantity: 101,
	},
	{
		garage_id: 195,
		part_id: 313,
		quantity: 5,
	},
	{
		garage_id: 195,
		part_id: 314,
		quantity: 442,
	},
	{
		garage_id: 195,
		part_id: 315,
		quantity: 39,
	},
	{
		garage_id: 195,
		part_id: 316,
		quantity: 94,
	},
	{
		garage_id: 195,
		part_id: 317,
		quantity: 387,
	},
	{
		garage_id: 195,
		part_id: 318,
		quantity: 207,
	},
	{
		garage_id: 195,
		part_id: 319,
		quantity: 424,
	},
	{
		garage_id: 195,
		part_id: 320,
		quantity: 109,
	},
	{
		garage_id: 195,
		part_id: 321,
		quantity: 184,
	},
	{
		garage_id: 195,
		part_id: 322,
		quantity: 402,
	},
	{
		garage_id: 195,
		part_id: 323,
		quantity: 120,
	},
	{
		garage_id: 195,
		part_id: 324,
		quantity: 182,
	},
	{
		garage_id: 195,
		part_id: 325,
		quantity: 196,
	},
	{
		garage_id: 195,
		part_id: 326,
		quantity: 498,
	},
	{
		garage_id: 195,
		part_id: 327,
		quantity: 56,
	},
	{
		garage_id: 195,
		part_id: 328,
		quantity: 20,
	},
	{
		garage_id: 195,
		part_id: 329,
		quantity: 158,
	},
	{
		garage_id: 195,
		part_id: 330,
		quantity: 177,
	},
	{
		garage_id: 195,
		part_id: 331,
		quantity: 422,
	},
	{
		garage_id: 195,
		part_id: 332,
		quantity: 45,
	},
	{
		garage_id: 195,
		part_id: 333,
		quantity: 481,
	},
	{
		garage_id: 195,
		part_id: 334,
		quantity: 117,
	},
	{
		garage_id: 195,
		part_id: 335,
		quantity: 240,
	},
	{
		garage_id: 195,
		part_id: 336,
		quantity: 258,
	},
	{
		garage_id: 195,
		part_id: 337,
		quantity: 369,
	},
	{
		garage_id: 195,
		part_id: 338,
		quantity: 332,
	},
	{
		garage_id: 195,
		part_id: 339,
		quantity: 409,
	},
	{
		garage_id: 195,
		part_id: 340,
		quantity: 193,
	},
	{
		garage_id: 195,
		part_id: 341,
		quantity: 16,
	},
	{
		garage_id: 195,
		part_id: 342,
		quantity: 43,
	},
	{
		garage_id: 195,
		part_id: 343,
		quantity: 144,
	},
	{
		garage_id: 195,
		part_id: 344,
		quantity: 485,
	},
	{
		garage_id: 195,
		part_id: 345,
		quantity: 486,
	},
	{
		garage_id: 195,
		part_id: 346,
		quantity: 472,
	},
	{
		garage_id: 195,
		part_id: 347,
		quantity: 238,
	},
	{
		garage_id: 195,
		part_id: 348,
		quantity: 451,
	},
	{
		garage_id: 195,
		part_id: 349,
		quantity: 147,
	},
	{
		garage_id: 195,
		part_id: 350,
		quantity: 79,
	},
	{
		garage_id: 195,
		part_id: 351,
		quantity: 356,
	},
	{
		garage_id: 195,
		part_id: 352,
		quantity: 102,
	},
	{
		garage_id: 195,
		part_id: 353,
		quantity: 273,
	},
	{
		garage_id: 195,
		part_id: 354,
		quantity: 211,
	},
	{
		garage_id: 195,
		part_id: 355,
		quantity: 66,
	},
	{
		garage_id: 195,
		part_id: 356,
		quantity: 341,
	},
	{
		garage_id: 195,
		part_id: 357,
		quantity: 434,
	},
	{
		garage_id: 195,
		part_id: 358,
		quantity: 156,
	},
	{
		garage_id: 195,
		part_id: 359,
		quantity: 291,
	},
	{
		garage_id: 195,
		part_id: 360,
		quantity: 479,
	},
	{
		garage_id: 195,
		part_id: 361,
		quantity: 486,
	},
	{
		garage_id: 195,
		part_id: 362,
		quantity: 302,
	},
	{
		garage_id: 195,
		part_id: 363,
		quantity: 41,
	},
	{
		garage_id: 195,
		part_id: 364,
		quantity: 258,
	},
	{
		garage_id: 195,
		part_id: 365,
		quantity: 54,
	},
	{
		garage_id: 195,
		part_id: 366,
		quantity: 107,
	},
	{
		garage_id: 195,
		part_id: 367,
		quantity: 32,
	},
	{
		garage_id: 195,
		part_id: 368,
		quantity: 286,
	},
	{
		garage_id: 195,
		part_id: 369,
		quantity: 452,
	},
	{
		garage_id: 195,
		part_id: 370,
		quantity: 6,
	},
	{
		garage_id: 195,
		part_id: 371,
		quantity: 59,
	},
	{
		garage_id: 195,
		part_id: 372,
		quantity: 21,
	},
	{
		garage_id: 195,
		part_id: 373,
		quantity: 488,
	},
	{
		garage_id: 195,
		part_id: 374,
		quantity: 85,
	},
	{
		garage_id: 195,
		part_id: 375,
		quantity: 5,
	},
	{
		garage_id: 195,
		part_id: 376,
		quantity: 190,
	},
	{
		garage_id: 195,
		part_id: 377,
		quantity: 153,
	},
	{
		garage_id: 195,
		part_id: 378,
		quantity: 241,
	},
	{
		garage_id: 195,
		part_id: 379,
		quantity: 172,
	},
	{
		garage_id: 195,
		part_id: 380,
		quantity: 141,
	},
	{
		garage_id: 195,
		part_id: 381,
		quantity: 92,
	},
	{
		garage_id: 195,
		part_id: 382,
		quantity: 338,
	},
	{
		garage_id: 195,
		part_id: 383,
		quantity: 327,
	},
	{
		garage_id: 195,
		part_id: 384,
		quantity: 432,
	},
	{
		garage_id: 195,
		part_id: 385,
		quantity: 373,
	},
	{
		garage_id: 195,
		part_id: 386,
		quantity: 408,
	},
	{
		garage_id: 195,
		part_id: 387,
		quantity: 484,
	},
	{
		garage_id: 195,
		part_id: 388,
		quantity: 179,
	},
	{
		garage_id: 195,
		part_id: 389,
		quantity: 113,
	},
	{
		garage_id: 195,
		part_id: 390,
		quantity: 27,
	},
	{
		garage_id: 195,
		part_id: 391,
		quantity: 412,
	},
	{
		garage_id: 195,
		part_id: 392,
		quantity: 423,
	},
	{
		garage_id: 195,
		part_id: 393,
		quantity: 390,
	},
	{
		garage_id: 195,
		part_id: 394,
		quantity: 326,
	},
	{
		garage_id: 195,
		part_id: 395,
		quantity: 199,
	},
	{
		garage_id: 195,
		part_id: 396,
		quantity: 116,
	},
	{
		garage_id: 195,
		part_id: 397,
		quantity: 31,
	},
	{
		garage_id: 195,
		part_id: 398,
		quantity: 10,
	},
	{
		garage_id: 195,
		part_id: 399,
		quantity: 472,
	},
	{
		garage_id: 196,
		part_id: 0,
		quantity: 320,
	},
	{
		garage_id: 196,
		part_id: 1,
		quantity: 377,
	},
	{
		garage_id: 196,
		part_id: 2,
		quantity: 407,
	},
	{
		garage_id: 196,
		part_id: 3,
		quantity: 266,
	},
	{
		garage_id: 196,
		part_id: 4,
		quantity: 169,
	},
	{
		garage_id: 196,
		part_id: 5,
		quantity: 206,
	},
	{
		garage_id: 196,
		part_id: 6,
		quantity: 198,
	},
	{
		garage_id: 196,
		part_id: 7,
		quantity: 180,
	},
	{
		garage_id: 196,
		part_id: 8,
		quantity: 392,
	},
	{
		garage_id: 196,
		part_id: 9,
		quantity: 165,
	},
	{
		garage_id: 196,
		part_id: 10,
		quantity: 466,
	},
	{
		garage_id: 196,
		part_id: 11,
		quantity: 193,
	},
	{
		garage_id: 196,
		part_id: 12,
		quantity: 268,
	},
	{
		garage_id: 196,
		part_id: 13,
		quantity: 146,
	},
	{
		garage_id: 196,
		part_id: 14,
		quantity: 91,
	},
	{
		garage_id: 196,
		part_id: 15,
		quantity: 3,
	},
	{
		garage_id: 196,
		part_id: 16,
		quantity: 441,
	},
	{
		garage_id: 196,
		part_id: 17,
		quantity: 252,
	},
	{
		garage_id: 196,
		part_id: 18,
		quantity: 253,
	},
	{
		garage_id: 196,
		part_id: 19,
		quantity: 323,
	},
	{
		garage_id: 196,
		part_id: 20,
		quantity: 275,
	},
	{
		garage_id: 196,
		part_id: 21,
		quantity: 41,
	},
	{
		garage_id: 196,
		part_id: 22,
		quantity: 160,
	},
	{
		garage_id: 196,
		part_id: 23,
		quantity: 188,
	},
	{
		garage_id: 196,
		part_id: 24,
		quantity: 379,
	},
	{
		garage_id: 196,
		part_id: 25,
		quantity: 1,
	},
	{
		garage_id: 196,
		part_id: 26,
		quantity: 384,
	},
	{
		garage_id: 196,
		part_id: 27,
		quantity: 471,
	},
	{
		garage_id: 196,
		part_id: 28,
		quantity: 418,
	},
	{
		garage_id: 196,
		part_id: 29,
		quantity: 261,
	},
	{
		garage_id: 196,
		part_id: 30,
		quantity: 456,
	},
	{
		garage_id: 196,
		part_id: 31,
		quantity: 292,
	},
	{
		garage_id: 196,
		part_id: 32,
		quantity: 159,
	},
	{
		garage_id: 196,
		part_id: 33,
		quantity: 455,
	},
	{
		garage_id: 196,
		part_id: 34,
		quantity: 60,
	},
	{
		garage_id: 196,
		part_id: 35,
		quantity: 125,
	},
	{
		garage_id: 196,
		part_id: 36,
		quantity: 203,
	},
	{
		garage_id: 196,
		part_id: 37,
		quantity: 401,
	},
	{
		garage_id: 196,
		part_id: 38,
		quantity: 271,
	},
	{
		garage_id: 196,
		part_id: 39,
		quantity: 51,
	},
	{
		garage_id: 196,
		part_id: 40,
		quantity: 318,
	},
	{
		garage_id: 196,
		part_id: 41,
		quantity: 20,
	},
	{
		garage_id: 196,
		part_id: 42,
		quantity: 122,
	},
	{
		garage_id: 196,
		part_id: 43,
		quantity: 277,
	},
	{
		garage_id: 196,
		part_id: 44,
		quantity: 56,
	},
	{
		garage_id: 196,
		part_id: 45,
		quantity: 391,
	},
	{
		garage_id: 196,
		part_id: 46,
		quantity: 42,
	},
	{
		garage_id: 196,
		part_id: 47,
		quantity: 182,
	},
	{
		garage_id: 196,
		part_id: 48,
		quantity: 53,
	},
	{
		garage_id: 196,
		part_id: 49,
		quantity: 437,
	},
	{
		garage_id: 196,
		part_id: 50,
		quantity: 456,
	},
	{
		garage_id: 196,
		part_id: 51,
		quantity: 332,
	},
	{
		garage_id: 196,
		part_id: 52,
		quantity: 290,
	},
	{
		garage_id: 196,
		part_id: 53,
		quantity: 146,
	},
	{
		garage_id: 196,
		part_id: 54,
		quantity: 327,
	},
	{
		garage_id: 196,
		part_id: 55,
		quantity: 109,
	},
	{
		garage_id: 196,
		part_id: 56,
		quantity: 445,
	},
	{
		garage_id: 196,
		part_id: 57,
		quantity: 479,
	},
	{
		garage_id: 196,
		part_id: 58,
		quantity: 377,
	},
	{
		garage_id: 196,
		part_id: 59,
		quantity: 334,
	},
	{
		garage_id: 196,
		part_id: 60,
		quantity: 100,
	},
	{
		garage_id: 196,
		part_id: 61,
		quantity: 108,
	},
	{
		garage_id: 196,
		part_id: 62,
		quantity: 371,
	},
	{
		garage_id: 196,
		part_id: 63,
		quantity: 483,
	},
	{
		garage_id: 196,
		part_id: 64,
		quantity: 490,
	},
	{
		garage_id: 196,
		part_id: 65,
		quantity: 301,
	},
	{
		garage_id: 196,
		part_id: 66,
		quantity: 339,
	},
	{
		garage_id: 196,
		part_id: 67,
		quantity: 394,
	},
	{
		garage_id: 196,
		part_id: 68,
		quantity: 8,
	},
	{
		garage_id: 196,
		part_id: 69,
		quantity: 318,
	},
	{
		garage_id: 196,
		part_id: 70,
		quantity: 490,
	},
	{
		garage_id: 196,
		part_id: 71,
		quantity: 246,
	},
	{
		garage_id: 196,
		part_id: 72,
		quantity: 84,
	},
	{
		garage_id: 196,
		part_id: 73,
		quantity: 354,
	},
	{
		garage_id: 196,
		part_id: 74,
		quantity: 371,
	},
	{
		garage_id: 196,
		part_id: 75,
		quantity: 131,
	},
	{
		garage_id: 196,
		part_id: 76,
		quantity: 486,
	},
	{
		garage_id: 196,
		part_id: 77,
		quantity: 82,
	},
	{
		garage_id: 196,
		part_id: 78,
		quantity: 427,
	},
	{
		garage_id: 196,
		part_id: 79,
		quantity: 286,
	},
	{
		garage_id: 196,
		part_id: 80,
		quantity: 33,
	},
	{
		garage_id: 196,
		part_id: 81,
		quantity: 224,
	},
	{
		garage_id: 196,
		part_id: 82,
		quantity: 304,
	},
	{
		garage_id: 196,
		part_id: 83,
		quantity: 480,
	},
	{
		garage_id: 196,
		part_id: 84,
		quantity: 192,
	},
	{
		garage_id: 196,
		part_id: 85,
		quantity: 340,
	},
	{
		garage_id: 196,
		part_id: 86,
		quantity: 207,
	},
	{
		garage_id: 196,
		part_id: 87,
		quantity: 241,
	},
	{
		garage_id: 196,
		part_id: 88,
		quantity: 443,
	},
	{
		garage_id: 196,
		part_id: 89,
		quantity: 412,
	},
	{
		garage_id: 196,
		part_id: 90,
		quantity: 234,
	},
	{
		garage_id: 196,
		part_id: 91,
		quantity: 14,
	},
	{
		garage_id: 196,
		part_id: 92,
		quantity: 453,
	},
	{
		garage_id: 196,
		part_id: 93,
		quantity: 3,
	},
	{
		garage_id: 196,
		part_id: 94,
		quantity: 157,
	},
	{
		garage_id: 196,
		part_id: 95,
		quantity: 450,
	},
	{
		garage_id: 196,
		part_id: 96,
		quantity: 448,
	},
	{
		garage_id: 196,
		part_id: 97,
		quantity: 128,
	},
	{
		garage_id: 196,
		part_id: 98,
		quantity: 469,
	},
	{
		garage_id: 196,
		part_id: 99,
		quantity: 332,
	},
	{
		garage_id: 196,
		part_id: 100,
		quantity: 155,
	},
	{
		garage_id: 196,
		part_id: 101,
		quantity: 25,
	},
	{
		garage_id: 196,
		part_id: 102,
		quantity: 359,
	},
	{
		garage_id: 196,
		part_id: 103,
		quantity: 430,
	},
	{
		garage_id: 196,
		part_id: 104,
		quantity: 216,
	},
	{
		garage_id: 196,
		part_id: 105,
		quantity: 423,
	},
	{
		garage_id: 196,
		part_id: 106,
		quantity: 49,
	},
	{
		garage_id: 196,
		part_id: 107,
		quantity: 263,
	},
	{
		garage_id: 196,
		part_id: 108,
		quantity: 348,
	},
	{
		garage_id: 196,
		part_id: 109,
		quantity: 178,
	},
	{
		garage_id: 196,
		part_id: 110,
		quantity: 497,
	},
	{
		garage_id: 196,
		part_id: 111,
		quantity: 372,
	},
	{
		garage_id: 196,
		part_id: 112,
		quantity: 387,
	},
	{
		garage_id: 196,
		part_id: 113,
		quantity: 276,
	},
	{
		garage_id: 196,
		part_id: 114,
		quantity: 176,
	},
	{
		garage_id: 196,
		part_id: 115,
		quantity: 429,
	},
	{
		garage_id: 196,
		part_id: 116,
		quantity: 308,
	},
	{
		garage_id: 196,
		part_id: 117,
		quantity: 481,
	},
	{
		garage_id: 196,
		part_id: 118,
		quantity: 184,
	},
	{
		garage_id: 196,
		part_id: 119,
		quantity: 114,
	},
	{
		garage_id: 196,
		part_id: 120,
		quantity: 0,
	},
	{
		garage_id: 196,
		part_id: 121,
		quantity: 18,
	},
	{
		garage_id: 196,
		part_id: 122,
		quantity: 73,
	},
	{
		garage_id: 196,
		part_id: 123,
		quantity: 86,
	},
	{
		garage_id: 196,
		part_id: 124,
		quantity: 366,
	},
	{
		garage_id: 196,
		part_id: 125,
		quantity: 426,
	},
	{
		garage_id: 196,
		part_id: 126,
		quantity: 453,
	},
	{
		garage_id: 196,
		part_id: 127,
		quantity: 148,
	},
	{
		garage_id: 196,
		part_id: 128,
		quantity: 220,
	},
	{
		garage_id: 196,
		part_id: 129,
		quantity: 487,
	},
	{
		garage_id: 196,
		part_id: 130,
		quantity: 457,
	},
	{
		garage_id: 196,
		part_id: 131,
		quantity: 211,
	},
	{
		garage_id: 196,
		part_id: 132,
		quantity: 193,
	},
	{
		garage_id: 196,
		part_id: 133,
		quantity: 333,
	},
	{
		garage_id: 196,
		part_id: 134,
		quantity: 376,
	},
	{
		garage_id: 196,
		part_id: 135,
		quantity: 485,
	},
	{
		garage_id: 196,
		part_id: 136,
		quantity: 233,
	},
	{
		garage_id: 196,
		part_id: 137,
		quantity: 442,
	},
	{
		garage_id: 196,
		part_id: 138,
		quantity: 204,
	},
	{
		garage_id: 196,
		part_id: 139,
		quantity: 298,
	},
	{
		garage_id: 196,
		part_id: 140,
		quantity: 108,
	},
	{
		garage_id: 196,
		part_id: 141,
		quantity: 392,
	},
	{
		garage_id: 196,
		part_id: 142,
		quantity: 214,
	},
	{
		garage_id: 196,
		part_id: 143,
		quantity: 169,
	},
	{
		garage_id: 196,
		part_id: 144,
		quantity: 473,
	},
	{
		garage_id: 196,
		part_id: 145,
		quantity: 106,
	},
	{
		garage_id: 196,
		part_id: 146,
		quantity: 137,
	},
	{
		garage_id: 196,
		part_id: 147,
		quantity: 463,
	},
	{
		garage_id: 196,
		part_id: 148,
		quantity: 180,
	},
	{
		garage_id: 196,
		part_id: 149,
		quantity: 360,
	},
	{
		garage_id: 196,
		part_id: 150,
		quantity: 316,
	},
	{
		garage_id: 196,
		part_id: 151,
		quantity: 152,
	},
	{
		garage_id: 196,
		part_id: 152,
		quantity: 160,
	},
	{
		garage_id: 196,
		part_id: 153,
		quantity: 487,
	},
	{
		garage_id: 196,
		part_id: 154,
		quantity: 358,
	},
	{
		garage_id: 196,
		part_id: 155,
		quantity: 319,
	},
	{
		garage_id: 196,
		part_id: 156,
		quantity: 42,
	},
	{
		garage_id: 196,
		part_id: 157,
		quantity: 137,
	},
	{
		garage_id: 196,
		part_id: 158,
		quantity: 97,
	},
	{
		garage_id: 196,
		part_id: 159,
		quantity: 219,
	},
	{
		garage_id: 196,
		part_id: 160,
		quantity: 75,
	},
	{
		garage_id: 196,
		part_id: 161,
		quantity: 130,
	},
	{
		garage_id: 196,
		part_id: 162,
		quantity: 463,
	},
	{
		garage_id: 196,
		part_id: 163,
		quantity: 363,
	},
	{
		garage_id: 196,
		part_id: 164,
		quantity: 52,
	},
	{
		garage_id: 196,
		part_id: 165,
		quantity: 463,
	},
	{
		garage_id: 196,
		part_id: 166,
		quantity: 232,
	},
	{
		garage_id: 196,
		part_id: 167,
		quantity: 450,
	},
	{
		garage_id: 196,
		part_id: 168,
		quantity: 498,
	},
	{
		garage_id: 196,
		part_id: 169,
		quantity: 204,
	},
	{
		garage_id: 196,
		part_id: 170,
		quantity: 153,
	},
	{
		garage_id: 196,
		part_id: 171,
		quantity: 161,
	},
	{
		garage_id: 196,
		part_id: 172,
		quantity: 297,
	},
	{
		garage_id: 196,
		part_id: 173,
		quantity: 219,
	},
	{
		garage_id: 196,
		part_id: 174,
		quantity: 23,
	},
	{
		garage_id: 196,
		part_id: 175,
		quantity: 484,
	},
	{
		garage_id: 196,
		part_id: 176,
		quantity: 201,
	},
	{
		garage_id: 196,
		part_id: 177,
		quantity: 178,
	},
	{
		garage_id: 196,
		part_id: 178,
		quantity: 8,
	},
	{
		garage_id: 196,
		part_id: 179,
		quantity: 130,
	},
	{
		garage_id: 196,
		part_id: 180,
		quantity: 478,
	},
	{
		garage_id: 196,
		part_id: 181,
		quantity: 337,
	},
	{
		garage_id: 196,
		part_id: 182,
		quantity: 265,
	},
	{
		garage_id: 196,
		part_id: 183,
		quantity: 132,
	},
	{
		garage_id: 196,
		part_id: 184,
		quantity: 99,
	},
	{
		garage_id: 196,
		part_id: 185,
		quantity: 23,
	},
	{
		garage_id: 196,
		part_id: 186,
		quantity: 374,
	},
	{
		garage_id: 196,
		part_id: 187,
		quantity: 265,
	},
	{
		garage_id: 196,
		part_id: 188,
		quantity: 434,
	},
	{
		garage_id: 196,
		part_id: 189,
		quantity: 107,
	},
	{
		garage_id: 196,
		part_id: 190,
		quantity: 444,
	},
	{
		garage_id: 196,
		part_id: 191,
		quantity: 266,
	},
	{
		garage_id: 196,
		part_id: 192,
		quantity: 370,
	},
	{
		garage_id: 196,
		part_id: 193,
		quantity: 408,
	},
	{
		garage_id: 196,
		part_id: 194,
		quantity: 220,
	},
	{
		garage_id: 196,
		part_id: 195,
		quantity: 289,
	},
	{
		garage_id: 196,
		part_id: 196,
		quantity: 63,
	},
	{
		garage_id: 196,
		part_id: 197,
		quantity: 90,
	},
	{
		garage_id: 196,
		part_id: 198,
		quantity: 221,
	},
	{
		garage_id: 196,
		part_id: 199,
		quantity: 342,
	},
	{
		garage_id: 196,
		part_id: 200,
		quantity: 297,
	},
	{
		garage_id: 196,
		part_id: 201,
		quantity: 324,
	},
	{
		garage_id: 196,
		part_id: 202,
		quantity: 171,
	},
	{
		garage_id: 196,
		part_id: 203,
		quantity: 446,
	},
	{
		garage_id: 196,
		part_id: 204,
		quantity: 321,
	},
	{
		garage_id: 196,
		part_id: 205,
		quantity: 405,
	},
	{
		garage_id: 196,
		part_id: 206,
		quantity: 209,
	},
	{
		garage_id: 196,
		part_id: 207,
		quantity: 40,
	},
	{
		garage_id: 196,
		part_id: 208,
		quantity: 156,
	},
	{
		garage_id: 196,
		part_id: 209,
		quantity: 80,
	},
	{
		garage_id: 196,
		part_id: 210,
		quantity: 274,
	},
	{
		garage_id: 196,
		part_id: 211,
		quantity: 73,
	},
	{
		garage_id: 196,
		part_id: 212,
		quantity: 462,
	},
	{
		garage_id: 196,
		part_id: 213,
		quantity: 226,
	},
	{
		garage_id: 196,
		part_id: 214,
		quantity: 203,
	},
	{
		garage_id: 196,
		part_id: 215,
		quantity: 279,
	},
	{
		garage_id: 196,
		part_id: 216,
		quantity: 273,
	},
	{
		garage_id: 196,
		part_id: 217,
		quantity: 481,
	},
	{
		garage_id: 196,
		part_id: 218,
		quantity: 174,
	},
	{
		garage_id: 196,
		part_id: 219,
		quantity: 122,
	},
	{
		garage_id: 196,
		part_id: 220,
		quantity: 82,
	},
	{
		garage_id: 196,
		part_id: 221,
		quantity: 310,
	},
	{
		garage_id: 196,
		part_id: 222,
		quantity: 360,
	},
	{
		garage_id: 196,
		part_id: 223,
		quantity: 27,
	},
	{
		garage_id: 196,
		part_id: 224,
		quantity: 121,
	},
	{
		garage_id: 196,
		part_id: 225,
		quantity: 277,
	},
	{
		garage_id: 196,
		part_id: 226,
		quantity: 201,
	},
	{
		garage_id: 196,
		part_id: 227,
		quantity: 334,
	},
	{
		garage_id: 196,
		part_id: 228,
		quantity: 32,
	},
	{
		garage_id: 196,
		part_id: 229,
		quantity: 51,
	},
	{
		garage_id: 196,
		part_id: 230,
		quantity: 448,
	},
	{
		garage_id: 196,
		part_id: 231,
		quantity: 176,
	},
	{
		garage_id: 196,
		part_id: 232,
		quantity: 159,
	},
	{
		garage_id: 196,
		part_id: 233,
		quantity: 26,
	},
	{
		garage_id: 196,
		part_id: 234,
		quantity: 476,
	},
	{
		garage_id: 196,
		part_id: 235,
		quantity: 392,
	},
	{
		garage_id: 196,
		part_id: 236,
		quantity: 441,
	},
	{
		garage_id: 196,
		part_id: 237,
		quantity: 194,
	},
	{
		garage_id: 196,
		part_id: 238,
		quantity: 396,
	},
	{
		garage_id: 196,
		part_id: 239,
		quantity: 70,
	},
	{
		garage_id: 196,
		part_id: 240,
		quantity: 313,
	},
	{
		garage_id: 196,
		part_id: 241,
		quantity: 175,
	},
	{
		garage_id: 196,
		part_id: 242,
		quantity: 207,
	},
	{
		garage_id: 196,
		part_id: 243,
		quantity: 161,
	},
	{
		garage_id: 196,
		part_id: 244,
		quantity: 141,
	},
	{
		garage_id: 196,
		part_id: 245,
		quantity: 161,
	},
	{
		garage_id: 196,
		part_id: 246,
		quantity: 441,
	},
	{
		garage_id: 196,
		part_id: 247,
		quantity: 389,
	},
	{
		garage_id: 196,
		part_id: 248,
		quantity: 79,
	},
	{
		garage_id: 196,
		part_id: 249,
		quantity: 197,
	},
	{
		garage_id: 196,
		part_id: 250,
		quantity: 175,
	},
	{
		garage_id: 196,
		part_id: 251,
		quantity: 91,
	},
	{
		garage_id: 196,
		part_id: 252,
		quantity: 319,
	},
	{
		garage_id: 196,
		part_id: 253,
		quantity: 33,
	},
	{
		garage_id: 196,
		part_id: 254,
		quantity: 445,
	},
	{
		garage_id: 196,
		part_id: 255,
		quantity: 199,
	},
	{
		garage_id: 196,
		part_id: 256,
		quantity: 29,
	},
	{
		garage_id: 196,
		part_id: 257,
		quantity: 267,
	},
	{
		garage_id: 196,
		part_id: 258,
		quantity: 305,
	},
	{
		garage_id: 196,
		part_id: 259,
		quantity: 339,
	},
	{
		garage_id: 196,
		part_id: 260,
		quantity: 254,
	},
	{
		garage_id: 196,
		part_id: 261,
		quantity: 446,
	},
	{
		garage_id: 196,
		part_id: 262,
		quantity: 289,
	},
	{
		garage_id: 196,
		part_id: 263,
		quantity: 474,
	},
	{
		garage_id: 196,
		part_id: 264,
		quantity: 14,
	},
	{
		garage_id: 196,
		part_id: 265,
		quantity: 85,
	},
	{
		garage_id: 196,
		part_id: 266,
		quantity: 124,
	},
	{
		garage_id: 196,
		part_id: 267,
		quantity: 145,
	},
	{
		garage_id: 196,
		part_id: 268,
		quantity: 226,
	},
	{
		garage_id: 196,
		part_id: 269,
		quantity: 197,
	},
	{
		garage_id: 196,
		part_id: 270,
		quantity: 236,
	},
	{
		garage_id: 196,
		part_id: 271,
		quantity: 414,
	},
	{
		garage_id: 196,
		part_id: 272,
		quantity: 20,
	},
	{
		garage_id: 196,
		part_id: 273,
		quantity: 307,
	},
	{
		garage_id: 196,
		part_id: 274,
		quantity: 361,
	},
	{
		garage_id: 196,
		part_id: 275,
		quantity: 22,
	},
	{
		garage_id: 196,
		part_id: 276,
		quantity: 9,
	},
	{
		garage_id: 196,
		part_id: 277,
		quantity: 377,
	},
	{
		garage_id: 196,
		part_id: 278,
		quantity: 431,
	},
	{
		garage_id: 196,
		part_id: 279,
		quantity: 321,
	},
	{
		garage_id: 196,
		part_id: 280,
		quantity: 60,
	},
	{
		garage_id: 196,
		part_id: 281,
		quantity: 288,
	},
	{
		garage_id: 196,
		part_id: 282,
		quantity: 410,
	},
	{
		garage_id: 196,
		part_id: 283,
		quantity: 45,
	},
	{
		garage_id: 196,
		part_id: 284,
		quantity: 228,
	},
	{
		garage_id: 196,
		part_id: 285,
		quantity: 312,
	},
	{
		garage_id: 196,
		part_id: 286,
		quantity: 268,
	},
	{
		garage_id: 196,
		part_id: 287,
		quantity: 158,
	},
	{
		garage_id: 196,
		part_id: 288,
		quantity: 39,
	},
	{
		garage_id: 196,
		part_id: 289,
		quantity: 329,
	},
	{
		garage_id: 196,
		part_id: 290,
		quantity: 81,
	},
	{
		garage_id: 196,
		part_id: 291,
		quantity: 389,
	},
	{
		garage_id: 196,
		part_id: 292,
		quantity: 303,
	},
	{
		garage_id: 196,
		part_id: 293,
		quantity: 393,
	},
	{
		garage_id: 196,
		part_id: 294,
		quantity: 365,
	},
	{
		garage_id: 196,
		part_id: 295,
		quantity: 4,
	},
	{
		garage_id: 196,
		part_id: 296,
		quantity: 398,
	},
	{
		garage_id: 196,
		part_id: 297,
		quantity: 206,
	},
	{
		garage_id: 196,
		part_id: 298,
		quantity: 38,
	},
	{
		garage_id: 196,
		part_id: 299,
		quantity: 346,
	},
	{
		garage_id: 196,
		part_id: 300,
		quantity: 188,
	},
	{
		garage_id: 196,
		part_id: 301,
		quantity: 333,
	},
	{
		garage_id: 196,
		part_id: 302,
		quantity: 232,
	},
	{
		garage_id: 196,
		part_id: 303,
		quantity: 4,
	},
	{
		garage_id: 196,
		part_id: 304,
		quantity: 108,
	},
	{
		garage_id: 196,
		part_id: 305,
		quantity: 424,
	},
	{
		garage_id: 196,
		part_id: 306,
		quantity: 393,
	},
	{
		garage_id: 196,
		part_id: 307,
		quantity: 218,
	},
	{
		garage_id: 196,
		part_id: 308,
		quantity: 395,
	},
	{
		garage_id: 196,
		part_id: 309,
		quantity: 248,
	},
	{
		garage_id: 196,
		part_id: 310,
		quantity: 25,
	},
	{
		garage_id: 196,
		part_id: 311,
		quantity: 274,
	},
	{
		garage_id: 196,
		part_id: 312,
		quantity: 89,
	},
	{
		garage_id: 196,
		part_id: 313,
		quantity: 132,
	},
	{
		garage_id: 196,
		part_id: 314,
		quantity: 393,
	},
	{
		garage_id: 196,
		part_id: 315,
		quantity: 336,
	},
	{
		garage_id: 196,
		part_id: 316,
		quantity: 88,
	},
	{
		garage_id: 196,
		part_id: 317,
		quantity: 105,
	},
	{
		garage_id: 196,
		part_id: 318,
		quantity: 472,
	},
	{
		garage_id: 196,
		part_id: 319,
		quantity: 222,
	},
	{
		garage_id: 196,
		part_id: 320,
		quantity: 123,
	},
	{
		garage_id: 196,
		part_id: 321,
		quantity: 435,
	},
	{
		garage_id: 196,
		part_id: 322,
		quantity: 286,
	},
	{
		garage_id: 196,
		part_id: 323,
		quantity: 77,
	},
	{
		garage_id: 196,
		part_id: 324,
		quantity: 475,
	},
	{
		garage_id: 196,
		part_id: 325,
		quantity: 303,
	},
	{
		garage_id: 196,
		part_id: 326,
		quantity: 97,
	},
	{
		garage_id: 196,
		part_id: 327,
		quantity: 335,
	},
	{
		garage_id: 196,
		part_id: 328,
		quantity: 139,
	},
	{
		garage_id: 196,
		part_id: 329,
		quantity: 335,
	},
	{
		garage_id: 196,
		part_id: 330,
		quantity: 224,
	},
	{
		garage_id: 196,
		part_id: 331,
		quantity: 438,
	},
	{
		garage_id: 196,
		part_id: 332,
		quantity: 384,
	},
	{
		garage_id: 196,
		part_id: 333,
		quantity: 301,
	},
	{
		garage_id: 196,
		part_id: 334,
		quantity: 226,
	},
	{
		garage_id: 196,
		part_id: 335,
		quantity: 44,
	},
	{
		garage_id: 196,
		part_id: 336,
		quantity: 260,
	},
	{
		garage_id: 196,
		part_id: 337,
		quantity: 288,
	},
	{
		garage_id: 196,
		part_id: 338,
		quantity: 481,
	},
	{
		garage_id: 196,
		part_id: 339,
		quantity: 378,
	},
	{
		garage_id: 196,
		part_id: 340,
		quantity: 282,
	},
	{
		garage_id: 196,
		part_id: 341,
		quantity: 211,
	},
	{
		garage_id: 196,
		part_id: 342,
		quantity: 377,
	},
	{
		garage_id: 196,
		part_id: 343,
		quantity: 378,
	},
	{
		garage_id: 196,
		part_id: 344,
		quantity: 473,
	},
	{
		garage_id: 196,
		part_id: 345,
		quantity: 36,
	},
	{
		garage_id: 196,
		part_id: 346,
		quantity: 10,
	},
	{
		garage_id: 196,
		part_id: 347,
		quantity: 340,
	},
	{
		garage_id: 196,
		part_id: 348,
		quantity: 144,
	},
	{
		garage_id: 196,
		part_id: 349,
		quantity: 72,
	},
	{
		garage_id: 196,
		part_id: 350,
		quantity: 171,
	},
	{
		garage_id: 196,
		part_id: 351,
		quantity: 165,
	},
	{
		garage_id: 196,
		part_id: 352,
		quantity: 334,
	},
	{
		garage_id: 196,
		part_id: 353,
		quantity: 270,
	},
	{
		garage_id: 196,
		part_id: 354,
		quantity: 334,
	},
	{
		garage_id: 196,
		part_id: 355,
		quantity: 214,
	},
	{
		garage_id: 196,
		part_id: 356,
		quantity: 257,
	},
	{
		garage_id: 196,
		part_id: 357,
		quantity: 262,
	},
	{
		garage_id: 196,
		part_id: 358,
		quantity: 126,
	},
	{
		garage_id: 196,
		part_id: 359,
		quantity: 371,
	},
	{
		garage_id: 196,
		part_id: 360,
		quantity: 391,
	},
	{
		garage_id: 196,
		part_id: 361,
		quantity: 154,
	},
	{
		garage_id: 196,
		part_id: 362,
		quantity: 28,
	},
	{
		garage_id: 196,
		part_id: 363,
		quantity: 373,
	},
	{
		garage_id: 196,
		part_id: 364,
		quantity: 144,
	},
	{
		garage_id: 196,
		part_id: 365,
		quantity: 84,
	},
	{
		garage_id: 196,
		part_id: 366,
		quantity: 201,
	},
	{
		garage_id: 196,
		part_id: 367,
		quantity: 322,
	},
	{
		garage_id: 196,
		part_id: 368,
		quantity: 205,
	},
	{
		garage_id: 196,
		part_id: 369,
		quantity: 163,
	},
	{
		garage_id: 196,
		part_id: 370,
		quantity: 491,
	},
	{
		garage_id: 196,
		part_id: 371,
		quantity: 252,
	},
	{
		garage_id: 196,
		part_id: 372,
		quantity: 377,
	},
	{
		garage_id: 196,
		part_id: 373,
		quantity: 465,
	},
	{
		garage_id: 196,
		part_id: 374,
		quantity: 418,
	},
	{
		garage_id: 196,
		part_id: 375,
		quantity: 231,
	},
	{
		garage_id: 196,
		part_id: 376,
		quantity: 442,
	},
	{
		garage_id: 196,
		part_id: 377,
		quantity: 350,
	},
	{
		garage_id: 196,
		part_id: 378,
		quantity: 106,
	},
	{
		garage_id: 196,
		part_id: 379,
		quantity: 108,
	},
	{
		garage_id: 196,
		part_id: 380,
		quantity: 123,
	},
	{
		garage_id: 196,
		part_id: 381,
		quantity: 363,
	},
	{
		garage_id: 196,
		part_id: 382,
		quantity: 249,
	},
	{
		garage_id: 196,
		part_id: 383,
		quantity: 7,
	},
	{
		garage_id: 196,
		part_id: 384,
		quantity: 219,
	},
	{
		garage_id: 196,
		part_id: 385,
		quantity: 306,
	},
	{
		garage_id: 196,
		part_id: 386,
		quantity: 494,
	},
	{
		garage_id: 196,
		part_id: 387,
		quantity: 373,
	},
	{
		garage_id: 196,
		part_id: 388,
		quantity: 42,
	},
	{
		garage_id: 196,
		part_id: 389,
		quantity: 185,
	},
	{
		garage_id: 196,
		part_id: 390,
		quantity: 123,
	},
	{
		garage_id: 196,
		part_id: 391,
		quantity: 369,
	},
	{
		garage_id: 196,
		part_id: 392,
		quantity: 264,
	},
	{
		garage_id: 196,
		part_id: 393,
		quantity: 363,
	},
	{
		garage_id: 196,
		part_id: 394,
		quantity: 411,
	},
	{
		garage_id: 196,
		part_id: 395,
		quantity: 455,
	},
	{
		garage_id: 196,
		part_id: 396,
		quantity: 147,
	},
	{
		garage_id: 196,
		part_id: 397,
		quantity: 451,
	},
	{
		garage_id: 196,
		part_id: 398,
		quantity: 81,
	},
	{
		garage_id: 196,
		part_id: 399,
		quantity: 378,
	},
	{
		garage_id: 197,
		part_id: 0,
		quantity: 115,
	},
	{
		garage_id: 197,
		part_id: 1,
		quantity: 269,
	},
	{
		garage_id: 197,
		part_id: 2,
		quantity: 459,
	},
	{
		garage_id: 197,
		part_id: 3,
		quantity: 204,
	},
	{
		garage_id: 197,
		part_id: 4,
		quantity: 88,
	},
	{
		garage_id: 197,
		part_id: 5,
		quantity: 375,
	},
	{
		garage_id: 197,
		part_id: 6,
		quantity: 492,
	},
	{
		garage_id: 197,
		part_id: 7,
		quantity: 466,
	},
	{
		garage_id: 197,
		part_id: 8,
		quantity: 201,
	},
	{
		garage_id: 197,
		part_id: 9,
		quantity: 316,
	},
	{
		garage_id: 197,
		part_id: 10,
		quantity: 277,
	},
	{
		garage_id: 197,
		part_id: 11,
		quantity: 461,
	},
	{
		garage_id: 197,
		part_id: 12,
		quantity: 327,
	},
	{
		garage_id: 197,
		part_id: 13,
		quantity: 171,
	},
	{
		garage_id: 197,
		part_id: 14,
		quantity: 335,
	},
	{
		garage_id: 197,
		part_id: 15,
		quantity: 35,
	},
	{
		garage_id: 197,
		part_id: 16,
		quantity: 296,
	},
	{
		garage_id: 197,
		part_id: 17,
		quantity: 164,
	},
	{
		garage_id: 197,
		part_id: 18,
		quantity: 273,
	},
	{
		garage_id: 197,
		part_id: 19,
		quantity: 188,
	},
	{
		garage_id: 197,
		part_id: 20,
		quantity: 419,
	},
	{
		garage_id: 197,
		part_id: 21,
		quantity: 358,
	},
	{
		garage_id: 197,
		part_id: 22,
		quantity: 328,
	},
	{
		garage_id: 197,
		part_id: 23,
		quantity: 488,
	},
	{
		garage_id: 197,
		part_id: 24,
		quantity: 449,
	},
	{
		garage_id: 197,
		part_id: 25,
		quantity: 212,
	},
	{
		garage_id: 197,
		part_id: 26,
		quantity: 179,
	},
	{
		garage_id: 197,
		part_id: 27,
		quantity: 249,
	},
	{
		garage_id: 197,
		part_id: 28,
		quantity: 63,
	},
	{
		garage_id: 197,
		part_id: 29,
		quantity: 18,
	},
	{
		garage_id: 197,
		part_id: 30,
		quantity: 435,
	},
	{
		garage_id: 197,
		part_id: 31,
		quantity: 426,
	},
	{
		garage_id: 197,
		part_id: 32,
		quantity: 402,
	},
	{
		garage_id: 197,
		part_id: 33,
		quantity: 120,
	},
	{
		garage_id: 197,
		part_id: 34,
		quantity: 182,
	},
	{
		garage_id: 197,
		part_id: 35,
		quantity: 500,
	},
	{
		garage_id: 197,
		part_id: 36,
		quantity: 148,
	},
	{
		garage_id: 197,
		part_id: 37,
		quantity: 370,
	},
	{
		garage_id: 197,
		part_id: 38,
		quantity: 300,
	},
	{
		garage_id: 197,
		part_id: 39,
		quantity: 500,
	},
	{
		garage_id: 197,
		part_id: 40,
		quantity: 104,
	},
	{
		garage_id: 197,
		part_id: 41,
		quantity: 198,
	},
	{
		garage_id: 197,
		part_id: 42,
		quantity: 122,
	},
	{
		garage_id: 197,
		part_id: 43,
		quantity: 175,
	},
	{
		garage_id: 197,
		part_id: 44,
		quantity: 134,
	},
	{
		garage_id: 197,
		part_id: 45,
		quantity: 383,
	},
	{
		garage_id: 197,
		part_id: 46,
		quantity: 289,
	},
	{
		garage_id: 197,
		part_id: 47,
		quantity: 166,
	},
	{
		garage_id: 197,
		part_id: 48,
		quantity: 467,
	},
	{
		garage_id: 197,
		part_id: 49,
		quantity: 239,
	},
	{
		garage_id: 197,
		part_id: 50,
		quantity: 285,
	},
	{
		garage_id: 197,
		part_id: 51,
		quantity: 259,
	},
	{
		garage_id: 197,
		part_id: 52,
		quantity: 280,
	},
	{
		garage_id: 197,
		part_id: 53,
		quantity: 249,
	},
	{
		garage_id: 197,
		part_id: 54,
		quantity: 116,
	},
	{
		garage_id: 197,
		part_id: 55,
		quantity: 70,
	},
	{
		garage_id: 197,
		part_id: 56,
		quantity: 340,
	},
	{
		garage_id: 197,
		part_id: 57,
		quantity: 223,
	},
	{
		garage_id: 197,
		part_id: 58,
		quantity: 335,
	},
	{
		garage_id: 197,
		part_id: 59,
		quantity: 352,
	},
	{
		garage_id: 197,
		part_id: 60,
		quantity: 3,
	},
	{
		garage_id: 197,
		part_id: 61,
		quantity: 481,
	},
	{
		garage_id: 197,
		part_id: 62,
		quantity: 432,
	},
	{
		garage_id: 197,
		part_id: 63,
		quantity: 241,
	},
	{
		garage_id: 197,
		part_id: 64,
		quantity: 484,
	},
	{
		garage_id: 197,
		part_id: 65,
		quantity: 280,
	},
	{
		garage_id: 197,
		part_id: 66,
		quantity: 472,
	},
	{
		garage_id: 197,
		part_id: 67,
		quantity: 309,
	},
	{
		garage_id: 197,
		part_id: 68,
		quantity: 399,
	},
	{
		garage_id: 197,
		part_id: 69,
		quantity: 288,
	},
	{
		garage_id: 197,
		part_id: 70,
		quantity: 316,
	},
	{
		garage_id: 197,
		part_id: 71,
		quantity: 470,
	},
	{
		garage_id: 197,
		part_id: 72,
		quantity: 197,
	},
	{
		garage_id: 197,
		part_id: 73,
		quantity: 471,
	},
	{
		garage_id: 197,
		part_id: 74,
		quantity: 109,
	},
	{
		garage_id: 197,
		part_id: 75,
		quantity: 133,
	},
	{
		garage_id: 197,
		part_id: 76,
		quantity: 146,
	},
	{
		garage_id: 197,
		part_id: 77,
		quantity: 163,
	},
	{
		garage_id: 197,
		part_id: 78,
		quantity: 171,
	},
	{
		garage_id: 197,
		part_id: 79,
		quantity: 402,
	},
	{
		garage_id: 197,
		part_id: 80,
		quantity: 473,
	},
	{
		garage_id: 197,
		part_id: 81,
		quantity: 30,
	},
	{
		garage_id: 197,
		part_id: 82,
		quantity: 110,
	},
	{
		garage_id: 197,
		part_id: 83,
		quantity: 150,
	},
	{
		garage_id: 197,
		part_id: 84,
		quantity: 90,
	},
	{
		garage_id: 197,
		part_id: 85,
		quantity: 430,
	},
	{
		garage_id: 197,
		part_id: 86,
		quantity: 442,
	},
	{
		garage_id: 197,
		part_id: 87,
		quantity: 418,
	},
	{
		garage_id: 197,
		part_id: 88,
		quantity: 133,
	},
	{
		garage_id: 197,
		part_id: 89,
		quantity: 269,
	},
	{
		garage_id: 197,
		part_id: 90,
		quantity: 348,
	},
	{
		garage_id: 197,
		part_id: 91,
		quantity: 356,
	},
	{
		garage_id: 197,
		part_id: 92,
		quantity: 296,
	},
	{
		garage_id: 197,
		part_id: 93,
		quantity: 262,
	},
	{
		garage_id: 197,
		part_id: 94,
		quantity: 378,
	},
	{
		garage_id: 197,
		part_id: 95,
		quantity: 83,
	},
	{
		garage_id: 197,
		part_id: 96,
		quantity: 292,
	},
	{
		garage_id: 197,
		part_id: 97,
		quantity: 143,
	},
	{
		garage_id: 197,
		part_id: 98,
		quantity: 39,
	},
	{
		garage_id: 197,
		part_id: 99,
		quantity: 500,
	},
	{
		garage_id: 197,
		part_id: 100,
		quantity: 494,
	},
	{
		garage_id: 197,
		part_id: 101,
		quantity: 50,
	},
	{
		garage_id: 197,
		part_id: 102,
		quantity: 99,
	},
	{
		garage_id: 197,
		part_id: 103,
		quantity: 82,
	},
	{
		garage_id: 197,
		part_id: 104,
		quantity: 484,
	},
	{
		garage_id: 197,
		part_id: 105,
		quantity: 75,
	},
	{
		garage_id: 197,
		part_id: 106,
		quantity: 266,
	},
	{
		garage_id: 197,
		part_id: 107,
		quantity: 411,
	},
	{
		garage_id: 197,
		part_id: 108,
		quantity: 188,
	},
	{
		garage_id: 197,
		part_id: 109,
		quantity: 425,
	},
	{
		garage_id: 197,
		part_id: 110,
		quantity: 181,
	},
	{
		garage_id: 197,
		part_id: 111,
		quantity: 110,
	},
	{
		garage_id: 197,
		part_id: 112,
		quantity: 475,
	},
	{
		garage_id: 197,
		part_id: 113,
		quantity: 172,
	},
	{
		garage_id: 197,
		part_id: 114,
		quantity: 299,
	},
	{
		garage_id: 197,
		part_id: 115,
		quantity: 274,
	},
	{
		garage_id: 197,
		part_id: 116,
		quantity: 432,
	},
	{
		garage_id: 197,
		part_id: 117,
		quantity: 339,
	},
	{
		garage_id: 197,
		part_id: 118,
		quantity: 134,
	},
	{
		garage_id: 197,
		part_id: 119,
		quantity: 223,
	},
	{
		garage_id: 197,
		part_id: 120,
		quantity: 457,
	},
	{
		garage_id: 197,
		part_id: 121,
		quantity: 438,
	},
	{
		garage_id: 197,
		part_id: 122,
		quantity: 339,
	},
	{
		garage_id: 197,
		part_id: 123,
		quantity: 386,
	},
	{
		garage_id: 197,
		part_id: 124,
		quantity: 489,
	},
	{
		garage_id: 197,
		part_id: 125,
		quantity: 34,
	},
	{
		garage_id: 197,
		part_id: 126,
		quantity: 478,
	},
	{
		garage_id: 197,
		part_id: 127,
		quantity: 301,
	},
	{
		garage_id: 197,
		part_id: 128,
		quantity: 335,
	},
	{
		garage_id: 197,
		part_id: 129,
		quantity: 179,
	},
	{
		garage_id: 197,
		part_id: 130,
		quantity: 217,
	},
	{
		garage_id: 197,
		part_id: 131,
		quantity: 305,
	},
	{
		garage_id: 197,
		part_id: 132,
		quantity: 254,
	},
	{
		garage_id: 197,
		part_id: 133,
		quantity: 429,
	},
	{
		garage_id: 197,
		part_id: 134,
		quantity: 413,
	},
	{
		garage_id: 197,
		part_id: 135,
		quantity: 83,
	},
	{
		garage_id: 197,
		part_id: 136,
		quantity: 453,
	},
	{
		garage_id: 197,
		part_id: 137,
		quantity: 245,
	},
	{
		garage_id: 197,
		part_id: 138,
		quantity: 471,
	},
	{
		garage_id: 197,
		part_id: 139,
		quantity: 366,
	},
	{
		garage_id: 197,
		part_id: 140,
		quantity: 29,
	},
	{
		garage_id: 197,
		part_id: 141,
		quantity: 308,
	},
	{
		garage_id: 197,
		part_id: 142,
		quantity: 383,
	},
	{
		garage_id: 197,
		part_id: 143,
		quantity: 62,
	},
	{
		garage_id: 197,
		part_id: 144,
		quantity: 415,
	},
	{
		garage_id: 197,
		part_id: 145,
		quantity: 446,
	},
	{
		garage_id: 197,
		part_id: 146,
		quantity: 76,
	},
	{
		garage_id: 197,
		part_id: 147,
		quantity: 435,
	},
	{
		garage_id: 197,
		part_id: 148,
		quantity: 492,
	},
	{
		garage_id: 197,
		part_id: 149,
		quantity: 136,
	},
	{
		garage_id: 197,
		part_id: 150,
		quantity: 473,
	},
	{
		garage_id: 197,
		part_id: 151,
		quantity: 243,
	},
	{
		garage_id: 197,
		part_id: 152,
		quantity: 166,
	},
	{
		garage_id: 197,
		part_id: 153,
		quantity: 374,
	},
	{
		garage_id: 197,
		part_id: 154,
		quantity: 40,
	},
	{
		garage_id: 197,
		part_id: 155,
		quantity: 452,
	},
	{
		garage_id: 197,
		part_id: 156,
		quantity: 112,
	},
	{
		garage_id: 197,
		part_id: 157,
		quantity: 128,
	},
	{
		garage_id: 197,
		part_id: 158,
		quantity: 133,
	},
	{
		garage_id: 197,
		part_id: 159,
		quantity: 30,
	},
	{
		garage_id: 197,
		part_id: 160,
		quantity: 281,
	},
	{
		garage_id: 197,
		part_id: 161,
		quantity: 266,
	},
	{
		garage_id: 197,
		part_id: 162,
		quantity: 126,
	},
	{
		garage_id: 197,
		part_id: 163,
		quantity: 17,
	},
	{
		garage_id: 197,
		part_id: 164,
		quantity: 250,
	},
	{
		garage_id: 197,
		part_id: 165,
		quantity: 451,
	},
	{
		garage_id: 197,
		part_id: 166,
		quantity: 22,
	},
	{
		garage_id: 197,
		part_id: 167,
		quantity: 408,
	},
	{
		garage_id: 197,
		part_id: 168,
		quantity: 122,
	},
	{
		garage_id: 197,
		part_id: 169,
		quantity: 326,
	},
	{
		garage_id: 197,
		part_id: 170,
		quantity: 445,
	},
	{
		garage_id: 197,
		part_id: 171,
		quantity: 226,
	},
	{
		garage_id: 197,
		part_id: 172,
		quantity: 64,
	},
	{
		garage_id: 197,
		part_id: 173,
		quantity: 374,
	},
	{
		garage_id: 197,
		part_id: 174,
		quantity: 399,
	},
	{
		garage_id: 197,
		part_id: 175,
		quantity: 480,
	},
	{
		garage_id: 197,
		part_id: 176,
		quantity: 101,
	},
	{
		garage_id: 197,
		part_id: 177,
		quantity: 310,
	},
	{
		garage_id: 197,
		part_id: 178,
		quantity: 295,
	},
	{
		garage_id: 197,
		part_id: 179,
		quantity: 277,
	},
	{
		garage_id: 197,
		part_id: 180,
		quantity: 55,
	},
	{
		garage_id: 197,
		part_id: 181,
		quantity: 494,
	},
	{
		garage_id: 197,
		part_id: 182,
		quantity: 341,
	},
	{
		garage_id: 197,
		part_id: 183,
		quantity: 171,
	},
	{
		garage_id: 197,
		part_id: 184,
		quantity: 432,
	},
	{
		garage_id: 197,
		part_id: 185,
		quantity: 296,
	},
	{
		garage_id: 197,
		part_id: 186,
		quantity: 248,
	},
	{
		garage_id: 197,
		part_id: 187,
		quantity: 117,
	},
	{
		garage_id: 197,
		part_id: 188,
		quantity: 60,
	},
	{
		garage_id: 197,
		part_id: 189,
		quantity: 311,
	},
	{
		garage_id: 197,
		part_id: 190,
		quantity: 169,
	},
	{
		garage_id: 197,
		part_id: 191,
		quantity: 125,
	},
	{
		garage_id: 197,
		part_id: 192,
		quantity: 138,
	},
	{
		garage_id: 197,
		part_id: 193,
		quantity: 461,
	},
	{
		garage_id: 197,
		part_id: 194,
		quantity: 277,
	},
	{
		garage_id: 197,
		part_id: 195,
		quantity: 180,
	},
	{
		garage_id: 197,
		part_id: 196,
		quantity: 210,
	},
	{
		garage_id: 197,
		part_id: 197,
		quantity: 47,
	},
	{
		garage_id: 197,
		part_id: 198,
		quantity: 232,
	},
	{
		garage_id: 197,
		part_id: 199,
		quantity: 440,
	},
	{
		garage_id: 197,
		part_id: 200,
		quantity: 463,
	},
	{
		garage_id: 197,
		part_id: 201,
		quantity: 450,
	},
	{
		garage_id: 197,
		part_id: 202,
		quantity: 116,
	},
	{
		garage_id: 197,
		part_id: 203,
		quantity: 250,
	},
	{
		garage_id: 197,
		part_id: 204,
		quantity: 271,
	},
	{
		garage_id: 197,
		part_id: 205,
		quantity: 293,
	},
	{
		garage_id: 197,
		part_id: 206,
		quantity: 418,
	},
	{
		garage_id: 197,
		part_id: 207,
		quantity: 319,
	},
	{
		garage_id: 197,
		part_id: 208,
		quantity: 470,
	},
	{
		garage_id: 197,
		part_id: 209,
		quantity: 416,
	},
	{
		garage_id: 197,
		part_id: 210,
		quantity: 201,
	},
	{
		garage_id: 197,
		part_id: 211,
		quantity: 139,
	},
	{
		garage_id: 197,
		part_id: 212,
		quantity: 82,
	},
	{
		garage_id: 197,
		part_id: 213,
		quantity: 50,
	},
	{
		garage_id: 197,
		part_id: 214,
		quantity: 223,
	},
	{
		garage_id: 197,
		part_id: 215,
		quantity: 344,
	},
	{
		garage_id: 197,
		part_id: 216,
		quantity: 398,
	},
	{
		garage_id: 197,
		part_id: 217,
		quantity: 17,
	},
	{
		garage_id: 197,
		part_id: 218,
		quantity: 383,
	},
	{
		garage_id: 197,
		part_id: 219,
		quantity: 170,
	},
	{
		garage_id: 197,
		part_id: 220,
		quantity: 148,
	},
	{
		garage_id: 197,
		part_id: 221,
		quantity: 324,
	},
	{
		garage_id: 197,
		part_id: 222,
		quantity: 262,
	},
	{
		garage_id: 197,
		part_id: 223,
		quantity: 259,
	},
	{
		garage_id: 197,
		part_id: 224,
		quantity: 409,
	},
	{
		garage_id: 197,
		part_id: 225,
		quantity: 368,
	},
	{
		garage_id: 197,
		part_id: 226,
		quantity: 335,
	},
	{
		garage_id: 197,
		part_id: 227,
		quantity: 78,
	},
	{
		garage_id: 197,
		part_id: 228,
		quantity: 372,
	},
	{
		garage_id: 197,
		part_id: 229,
		quantity: 426,
	},
	{
		garage_id: 197,
		part_id: 230,
		quantity: 111,
	},
	{
		garage_id: 197,
		part_id: 231,
		quantity: 207,
	},
	{
		garage_id: 197,
		part_id: 232,
		quantity: 7,
	},
	{
		garage_id: 197,
		part_id: 233,
		quantity: 80,
	},
	{
		garage_id: 197,
		part_id: 234,
		quantity: 494,
	},
	{
		garage_id: 197,
		part_id: 235,
		quantity: 77,
	},
	{
		garage_id: 197,
		part_id: 236,
		quantity: 322,
	},
	{
		garage_id: 197,
		part_id: 237,
		quantity: 415,
	},
	{
		garage_id: 197,
		part_id: 238,
		quantity: 296,
	},
	{
		garage_id: 197,
		part_id: 239,
		quantity: 418,
	},
	{
		garage_id: 197,
		part_id: 240,
		quantity: 445,
	},
	{
		garage_id: 197,
		part_id: 241,
		quantity: 431,
	},
	{
		garage_id: 197,
		part_id: 242,
		quantity: 372,
	},
	{
		garage_id: 197,
		part_id: 243,
		quantity: 293,
	},
	{
		garage_id: 197,
		part_id: 244,
		quantity: 284,
	},
	{
		garage_id: 197,
		part_id: 245,
		quantity: 101,
	},
	{
		garage_id: 197,
		part_id: 246,
		quantity: 87,
	},
	{
		garage_id: 197,
		part_id: 247,
		quantity: 245,
	},
	{
		garage_id: 197,
		part_id: 248,
		quantity: 452,
	},
	{
		garage_id: 197,
		part_id: 249,
		quantity: 194,
	},
	{
		garage_id: 197,
		part_id: 250,
		quantity: 393,
	},
	{
		garage_id: 197,
		part_id: 251,
		quantity: 30,
	},
	{
		garage_id: 197,
		part_id: 252,
		quantity: 334,
	},
	{
		garage_id: 197,
		part_id: 253,
		quantity: 62,
	},
	{
		garage_id: 197,
		part_id: 254,
		quantity: 263,
	},
	{
		garage_id: 197,
		part_id: 255,
		quantity: 209,
	},
	{
		garage_id: 197,
		part_id: 256,
		quantity: 215,
	},
	{
		garage_id: 197,
		part_id: 257,
		quantity: 70,
	},
	{
		garage_id: 197,
		part_id: 258,
		quantity: 10,
	},
	{
		garage_id: 197,
		part_id: 259,
		quantity: 368,
	},
	{
		garage_id: 197,
		part_id: 260,
		quantity: 4,
	},
	{
		garage_id: 197,
		part_id: 261,
		quantity: 71,
	},
	{
		garage_id: 197,
		part_id: 262,
		quantity: 396,
	},
	{
		garage_id: 197,
		part_id: 263,
		quantity: 306,
	},
	{
		garage_id: 197,
		part_id: 264,
		quantity: 233,
	},
	{
		garage_id: 197,
		part_id: 265,
		quantity: 304,
	},
	{
		garage_id: 197,
		part_id: 266,
		quantity: 348,
	},
	{
		garage_id: 197,
		part_id: 267,
		quantity: 110,
	},
	{
		garage_id: 197,
		part_id: 268,
		quantity: 138,
	},
	{
		garage_id: 197,
		part_id: 269,
		quantity: 278,
	},
	{
		garage_id: 197,
		part_id: 270,
		quantity: 307,
	},
	{
		garage_id: 197,
		part_id: 271,
		quantity: 388,
	},
	{
		garage_id: 197,
		part_id: 272,
		quantity: 474,
	},
	{
		garage_id: 197,
		part_id: 273,
		quantity: 416,
	},
	{
		garage_id: 197,
		part_id: 274,
		quantity: 141,
	},
	{
		garage_id: 197,
		part_id: 275,
		quantity: 193,
	},
	{
		garage_id: 197,
		part_id: 276,
		quantity: 414,
	},
	{
		garage_id: 197,
		part_id: 277,
		quantity: 455,
	},
	{
		garage_id: 197,
		part_id: 278,
		quantity: 206,
	},
	{
		garage_id: 197,
		part_id: 279,
		quantity: 338,
	},
	{
		garage_id: 197,
		part_id: 280,
		quantity: 19,
	},
	{
		garage_id: 197,
		part_id: 281,
		quantity: 34,
	},
	{
		garage_id: 197,
		part_id: 282,
		quantity: 21,
	},
	{
		garage_id: 197,
		part_id: 283,
		quantity: 351,
	},
	{
		garage_id: 197,
		part_id: 284,
		quantity: 315,
	},
	{
		garage_id: 197,
		part_id: 285,
		quantity: 177,
	},
	{
		garage_id: 197,
		part_id: 286,
		quantity: 101,
	},
	{
		garage_id: 197,
		part_id: 287,
		quantity: 62,
	},
	{
		garage_id: 197,
		part_id: 288,
		quantity: 249,
	},
	{
		garage_id: 197,
		part_id: 289,
		quantity: 82,
	},
	{
		garage_id: 197,
		part_id: 290,
		quantity: 257,
	},
	{
		garage_id: 197,
		part_id: 291,
		quantity: 364,
	},
	{
		garage_id: 197,
		part_id: 292,
		quantity: 174,
	},
	{
		garage_id: 197,
		part_id: 293,
		quantity: 436,
	},
	{
		garage_id: 197,
		part_id: 294,
		quantity: 105,
	},
	{
		garage_id: 197,
		part_id: 295,
		quantity: 299,
	},
	{
		garage_id: 197,
		part_id: 296,
		quantity: 258,
	},
	{
		garage_id: 197,
		part_id: 297,
		quantity: 374,
	},
	{
		garage_id: 197,
		part_id: 298,
		quantity: 287,
	},
	{
		garage_id: 197,
		part_id: 299,
		quantity: 306,
	},
	{
		garage_id: 197,
		part_id: 300,
		quantity: 19,
	},
	{
		garage_id: 197,
		part_id: 301,
		quantity: 389,
	},
	{
		garage_id: 197,
		part_id: 302,
		quantity: 185,
	},
	{
		garage_id: 197,
		part_id: 303,
		quantity: 321,
	},
	{
		garage_id: 197,
		part_id: 304,
		quantity: 253,
	},
	{
		garage_id: 197,
		part_id: 305,
		quantity: 303,
	},
	{
		garage_id: 197,
		part_id: 306,
		quantity: 110,
	},
	{
		garage_id: 197,
		part_id: 307,
		quantity: 17,
	},
	{
		garage_id: 197,
		part_id: 308,
		quantity: 126,
	},
	{
		garage_id: 197,
		part_id: 309,
		quantity: 383,
	},
	{
		garage_id: 197,
		part_id: 310,
		quantity: 125,
	},
	{
		garage_id: 197,
		part_id: 311,
		quantity: 206,
	},
	{
		garage_id: 197,
		part_id: 312,
		quantity: 456,
	},
	{
		garage_id: 197,
		part_id: 313,
		quantity: 399,
	},
	{
		garage_id: 197,
		part_id: 314,
		quantity: 93,
	},
	{
		garage_id: 197,
		part_id: 315,
		quantity: 484,
	},
	{
		garage_id: 197,
		part_id: 316,
		quantity: 75,
	},
	{
		garage_id: 197,
		part_id: 317,
		quantity: 12,
	},
	{
		garage_id: 197,
		part_id: 318,
		quantity: 259,
	},
	{
		garage_id: 197,
		part_id: 319,
		quantity: 269,
	},
	{
		garage_id: 197,
		part_id: 320,
		quantity: 310,
	},
	{
		garage_id: 197,
		part_id: 321,
		quantity: 491,
	},
	{
		garage_id: 197,
		part_id: 322,
		quantity: 263,
	},
	{
		garage_id: 197,
		part_id: 323,
		quantity: 10,
	},
	{
		garage_id: 197,
		part_id: 324,
		quantity: 392,
	},
	{
		garage_id: 197,
		part_id: 325,
		quantity: 91,
	},
	{
		garage_id: 197,
		part_id: 326,
		quantity: 266,
	},
	{
		garage_id: 197,
		part_id: 327,
		quantity: 251,
	},
	{
		garage_id: 197,
		part_id: 328,
		quantity: 225,
	},
	{
		garage_id: 197,
		part_id: 329,
		quantity: 186,
	},
	{
		garage_id: 197,
		part_id: 330,
		quantity: 153,
	},
	{
		garage_id: 197,
		part_id: 331,
		quantity: 4,
	},
	{
		garage_id: 197,
		part_id: 332,
		quantity: 132,
	},
	{
		garage_id: 197,
		part_id: 333,
		quantity: 239,
	},
	{
		garage_id: 197,
		part_id: 334,
		quantity: 10,
	},
	{
		garage_id: 197,
		part_id: 335,
		quantity: 206,
	},
	{
		garage_id: 197,
		part_id: 336,
		quantity: 172,
	},
	{
		garage_id: 197,
		part_id: 337,
		quantity: 248,
	},
	{
		garage_id: 197,
		part_id: 338,
		quantity: 52,
	},
	{
		garage_id: 197,
		part_id: 339,
		quantity: 96,
	},
	{
		garage_id: 197,
		part_id: 340,
		quantity: 471,
	},
	{
		garage_id: 197,
		part_id: 341,
		quantity: 195,
	},
	{
		garage_id: 197,
		part_id: 342,
		quantity: 227,
	},
	{
		garage_id: 197,
		part_id: 343,
		quantity: 157,
	},
	{
		garage_id: 197,
		part_id: 344,
		quantity: 96,
	},
	{
		garage_id: 197,
		part_id: 345,
		quantity: 103,
	},
	{
		garage_id: 197,
		part_id: 346,
		quantity: 267,
	},
	{
		garage_id: 197,
		part_id: 347,
		quantity: 204,
	},
	{
		garage_id: 197,
		part_id: 348,
		quantity: 249,
	},
	{
		garage_id: 197,
		part_id: 349,
		quantity: 497,
	},
	{
		garage_id: 197,
		part_id: 350,
		quantity: 170,
	},
	{
		garage_id: 197,
		part_id: 351,
		quantity: 378,
	},
	{
		garage_id: 197,
		part_id: 352,
		quantity: 317,
	},
	{
		garage_id: 197,
		part_id: 353,
		quantity: 45,
	},
	{
		garage_id: 197,
		part_id: 354,
		quantity: 80,
	},
	{
		garage_id: 197,
		part_id: 355,
		quantity: 30,
	},
	{
		garage_id: 197,
		part_id: 356,
		quantity: 401,
	},
	{
		garage_id: 197,
		part_id: 357,
		quantity: 212,
	},
	{
		garage_id: 197,
		part_id: 358,
		quantity: 112,
	},
	{
		garage_id: 197,
		part_id: 359,
		quantity: 152,
	},
	{
		garage_id: 197,
		part_id: 360,
		quantity: 312,
	},
	{
		garage_id: 197,
		part_id: 361,
		quantity: 305,
	},
	{
		garage_id: 197,
		part_id: 362,
		quantity: 15,
	},
	{
		garage_id: 197,
		part_id: 363,
		quantity: 153,
	},
	{
		garage_id: 197,
		part_id: 364,
		quantity: 233,
	},
	{
		garage_id: 197,
		part_id: 365,
		quantity: 330,
	},
	{
		garage_id: 197,
		part_id: 366,
		quantity: 90,
	},
	{
		garage_id: 197,
		part_id: 367,
		quantity: 368,
	},
	{
		garage_id: 197,
		part_id: 368,
		quantity: 455,
	},
	{
		garage_id: 197,
		part_id: 369,
		quantity: 194,
	},
	{
		garage_id: 197,
		part_id: 370,
		quantity: 282,
	},
	{
		garage_id: 197,
		part_id: 371,
		quantity: 376,
	},
	{
		garage_id: 197,
		part_id: 372,
		quantity: 97,
	},
	{
		garage_id: 197,
		part_id: 373,
		quantity: 180,
	},
	{
		garage_id: 197,
		part_id: 374,
		quantity: 342,
	},
	{
		garage_id: 197,
		part_id: 375,
		quantity: 388,
	},
	{
		garage_id: 197,
		part_id: 376,
		quantity: 241,
	},
	{
		garage_id: 197,
		part_id: 377,
		quantity: 496,
	},
	{
		garage_id: 197,
		part_id: 378,
		quantity: 46,
	},
	{
		garage_id: 197,
		part_id: 379,
		quantity: 332,
	},
	{
		garage_id: 197,
		part_id: 380,
		quantity: 170,
	},
	{
		garage_id: 197,
		part_id: 381,
		quantity: 22,
	},
	{
		garage_id: 197,
		part_id: 382,
		quantity: 389,
	},
	{
		garage_id: 197,
		part_id: 383,
		quantity: 254,
	},
	{
		garage_id: 197,
		part_id: 384,
		quantity: 209,
	},
	{
		garage_id: 197,
		part_id: 385,
		quantity: 121,
	},
	{
		garage_id: 197,
		part_id: 386,
		quantity: 477,
	},
	{
		garage_id: 197,
		part_id: 387,
		quantity: 246,
	},
	{
		garage_id: 197,
		part_id: 388,
		quantity: 43,
	},
	{
		garage_id: 197,
		part_id: 389,
		quantity: 156,
	},
	{
		garage_id: 197,
		part_id: 390,
		quantity: 311,
	},
	{
		garage_id: 197,
		part_id: 391,
		quantity: 308,
	},
	{
		garage_id: 197,
		part_id: 392,
		quantity: 242,
	},
	{
		garage_id: 197,
		part_id: 393,
		quantity: 207,
	},
	{
		garage_id: 197,
		part_id: 394,
		quantity: 348,
	},
	{
		garage_id: 197,
		part_id: 395,
		quantity: 160,
	},
	{
		garage_id: 197,
		part_id: 396,
		quantity: 256,
	},
	{
		garage_id: 197,
		part_id: 397,
		quantity: 492,
	},
	{
		garage_id: 197,
		part_id: 398,
		quantity: 47,
	},
	{
		garage_id: 197,
		part_id: 399,
		quantity: 84,
	},
	{
		garage_id: 198,
		part_id: 0,
		quantity: 39,
	},
	{
		garage_id: 198,
		part_id: 1,
		quantity: 99,
	},
	{
		garage_id: 198,
		part_id: 2,
		quantity: 8,
	},
	{
		garage_id: 198,
		part_id: 3,
		quantity: 362,
	},
	{
		garage_id: 198,
		part_id: 4,
		quantity: 225,
	},
	{
		garage_id: 198,
		part_id: 5,
		quantity: 377,
	},
	{
		garage_id: 198,
		part_id: 6,
		quantity: 40,
	},
	{
		garage_id: 198,
		part_id: 7,
		quantity: 310,
	},
	{
		garage_id: 198,
		part_id: 8,
		quantity: 471,
	},
	{
		garage_id: 198,
		part_id: 9,
		quantity: 241,
	},
	{
		garage_id: 198,
		part_id: 10,
		quantity: 392,
	},
	{
		garage_id: 198,
		part_id: 11,
		quantity: 499,
	},
	{
		garage_id: 198,
		part_id: 12,
		quantity: 267,
	},
	{
		garage_id: 198,
		part_id: 13,
		quantity: 117,
	},
	{
		garage_id: 198,
		part_id: 14,
		quantity: 341,
	},
	{
		garage_id: 198,
		part_id: 15,
		quantity: 225,
	},
	{
		garage_id: 198,
		part_id: 16,
		quantity: 12,
	},
	{
		garage_id: 198,
		part_id: 17,
		quantity: 420,
	},
	{
		garage_id: 198,
		part_id: 18,
		quantity: 179,
	},
	{
		garage_id: 198,
		part_id: 19,
		quantity: 53,
	},
	{
		garage_id: 198,
		part_id: 20,
		quantity: 498,
	},
	{
		garage_id: 198,
		part_id: 21,
		quantity: 290,
	},
	{
		garage_id: 198,
		part_id: 22,
		quantity: 203,
	},
	{
		garage_id: 198,
		part_id: 23,
		quantity: 404,
	},
	{
		garage_id: 198,
		part_id: 24,
		quantity: 494,
	},
	{
		garage_id: 198,
		part_id: 25,
		quantity: 444,
	},
	{
		garage_id: 198,
		part_id: 26,
		quantity: 154,
	},
	{
		garage_id: 198,
		part_id: 27,
		quantity: 95,
	},
	{
		garage_id: 198,
		part_id: 28,
		quantity: 64,
	},
	{
		garage_id: 198,
		part_id: 29,
		quantity: 42,
	},
	{
		garage_id: 198,
		part_id: 30,
		quantity: 41,
	},
	{
		garage_id: 198,
		part_id: 31,
		quantity: 41,
	},
	{
		garage_id: 198,
		part_id: 32,
		quantity: 248,
	},
	{
		garage_id: 198,
		part_id: 33,
		quantity: 78,
	},
	{
		garage_id: 198,
		part_id: 34,
		quantity: 498,
	},
	{
		garage_id: 198,
		part_id: 35,
		quantity: 264,
	},
	{
		garage_id: 198,
		part_id: 36,
		quantity: 218,
	},
	{
		garage_id: 198,
		part_id: 37,
		quantity: 17,
	},
	{
		garage_id: 198,
		part_id: 38,
		quantity: 451,
	},
	{
		garage_id: 198,
		part_id: 39,
		quantity: 99,
	},
	{
		garage_id: 198,
		part_id: 40,
		quantity: 394,
	},
	{
		garage_id: 198,
		part_id: 41,
		quantity: 220,
	},
	{
		garage_id: 198,
		part_id: 42,
		quantity: 395,
	},
	{
		garage_id: 198,
		part_id: 43,
		quantity: 424,
	},
	{
		garage_id: 198,
		part_id: 44,
		quantity: 378,
	},
	{
		garage_id: 198,
		part_id: 45,
		quantity: 255,
	},
	{
		garage_id: 198,
		part_id: 46,
		quantity: 165,
	},
	{
		garage_id: 198,
		part_id: 47,
		quantity: 420,
	},
	{
		garage_id: 198,
		part_id: 48,
		quantity: 306,
	},
	{
		garage_id: 198,
		part_id: 49,
		quantity: 16,
	},
	{
		garage_id: 198,
		part_id: 50,
		quantity: 136,
	},
	{
		garage_id: 198,
		part_id: 51,
		quantity: 42,
	},
	{
		garage_id: 198,
		part_id: 52,
		quantity: 368,
	},
	{
		garage_id: 198,
		part_id: 53,
		quantity: 325,
	},
	{
		garage_id: 198,
		part_id: 54,
		quantity: 146,
	},
	{
		garage_id: 198,
		part_id: 55,
		quantity: 229,
	},
	{
		garage_id: 198,
		part_id: 56,
		quantity: 55,
	},
	{
		garage_id: 198,
		part_id: 57,
		quantity: 346,
	},
	{
		garage_id: 198,
		part_id: 58,
		quantity: 146,
	},
	{
		garage_id: 198,
		part_id: 59,
		quantity: 213,
	},
	{
		garage_id: 198,
		part_id: 60,
		quantity: 194,
	},
	{
		garage_id: 198,
		part_id: 61,
		quantity: 69,
	},
	{
		garage_id: 198,
		part_id: 62,
		quantity: 154,
	},
	{
		garage_id: 198,
		part_id: 63,
		quantity: 114,
	},
	{
		garage_id: 198,
		part_id: 64,
		quantity: 409,
	},
	{
		garage_id: 198,
		part_id: 65,
		quantity: 473,
	},
	{
		garage_id: 198,
		part_id: 66,
		quantity: 463,
	},
	{
		garage_id: 198,
		part_id: 67,
		quantity: 129,
	},
	{
		garage_id: 198,
		part_id: 68,
		quantity: 327,
	},
	{
		garage_id: 198,
		part_id: 69,
		quantity: 496,
	},
	{
		garage_id: 198,
		part_id: 70,
		quantity: 413,
	},
	{
		garage_id: 198,
		part_id: 71,
		quantity: 136,
	},
	{
		garage_id: 198,
		part_id: 72,
		quantity: 35,
	},
	{
		garage_id: 198,
		part_id: 73,
		quantity: 461,
	},
	{
		garage_id: 198,
		part_id: 74,
		quantity: 72,
	},
	{
		garage_id: 198,
		part_id: 75,
		quantity: 11,
	},
	{
		garage_id: 198,
		part_id: 76,
		quantity: 111,
	},
	{
		garage_id: 198,
		part_id: 77,
		quantity: 445,
	},
	{
		garage_id: 198,
		part_id: 78,
		quantity: 74,
	},
	{
		garage_id: 198,
		part_id: 79,
		quantity: 272,
	},
	{
		garage_id: 198,
		part_id: 80,
		quantity: 419,
	},
	{
		garage_id: 198,
		part_id: 81,
		quantity: 271,
	},
	{
		garage_id: 198,
		part_id: 82,
		quantity: 432,
	},
	{
		garage_id: 198,
		part_id: 83,
		quantity: 422,
	},
	{
		garage_id: 198,
		part_id: 84,
		quantity: 216,
	},
	{
		garage_id: 198,
		part_id: 85,
		quantity: 76,
	},
	{
		garage_id: 198,
		part_id: 86,
		quantity: 267,
	},
	{
		garage_id: 198,
		part_id: 87,
		quantity: 378,
	},
	{
		garage_id: 198,
		part_id: 88,
		quantity: 128,
	},
	{
		garage_id: 198,
		part_id: 89,
		quantity: 479,
	},
	{
		garage_id: 198,
		part_id: 90,
		quantity: 349,
	},
	{
		garage_id: 198,
		part_id: 91,
		quantity: 6,
	},
	{
		garage_id: 198,
		part_id: 92,
		quantity: 128,
	},
	{
		garage_id: 198,
		part_id: 93,
		quantity: 451,
	},
	{
		garage_id: 198,
		part_id: 94,
		quantity: 474,
	},
	{
		garage_id: 198,
		part_id: 95,
		quantity: 341,
	},
	{
		garage_id: 198,
		part_id: 96,
		quantity: 19,
	},
	{
		garage_id: 198,
		part_id: 97,
		quantity: 37,
	},
	{
		garage_id: 198,
		part_id: 98,
		quantity: 67,
	},
	{
		garage_id: 198,
		part_id: 99,
		quantity: 458,
	},
	{
		garage_id: 198,
		part_id: 100,
		quantity: 316,
	},
	{
		garage_id: 198,
		part_id: 101,
		quantity: 329,
	},
	{
		garage_id: 198,
		part_id: 102,
		quantity: 465,
	},
	{
		garage_id: 198,
		part_id: 103,
		quantity: 392,
	},
	{
		garage_id: 198,
		part_id: 104,
		quantity: 172,
	},
	{
		garage_id: 198,
		part_id: 105,
		quantity: 273,
	},
	{
		garage_id: 198,
		part_id: 106,
		quantity: 90,
	},
	{
		garage_id: 198,
		part_id: 107,
		quantity: 379,
	},
	{
		garage_id: 198,
		part_id: 108,
		quantity: 27,
	},
	{
		garage_id: 198,
		part_id: 109,
		quantity: 70,
	},
	{
		garage_id: 198,
		part_id: 110,
		quantity: 108,
	},
	{
		garage_id: 198,
		part_id: 111,
		quantity: 273,
	},
	{
		garage_id: 198,
		part_id: 112,
		quantity: 463,
	},
	{
		garage_id: 198,
		part_id: 113,
		quantity: 362,
	},
	{
		garage_id: 198,
		part_id: 114,
		quantity: 36,
	},
	{
		garage_id: 198,
		part_id: 115,
		quantity: 230,
	},
	{
		garage_id: 198,
		part_id: 116,
		quantity: 422,
	},
	{
		garage_id: 198,
		part_id: 117,
		quantity: 160,
	},
	{
		garage_id: 198,
		part_id: 118,
		quantity: 338,
	},
	{
		garage_id: 198,
		part_id: 119,
		quantity: 161,
	},
	{
		garage_id: 198,
		part_id: 120,
		quantity: 39,
	},
	{
		garage_id: 198,
		part_id: 121,
		quantity: 190,
	},
	{
		garage_id: 198,
		part_id: 122,
		quantity: 309,
	},
	{
		garage_id: 198,
		part_id: 123,
		quantity: 49,
	},
	{
		garage_id: 198,
		part_id: 124,
		quantity: 178,
	},
	{
		garage_id: 198,
		part_id: 125,
		quantity: 235,
	},
	{
		garage_id: 198,
		part_id: 126,
		quantity: 315,
	},
	{
		garage_id: 198,
		part_id: 127,
		quantity: 323,
	},
	{
		garage_id: 198,
		part_id: 128,
		quantity: 252,
	},
	{
		garage_id: 198,
		part_id: 129,
		quantity: 138,
	},
	{
		garage_id: 198,
		part_id: 130,
		quantity: 262,
	},
	{
		garage_id: 198,
		part_id: 131,
		quantity: 4,
	},
	{
		garage_id: 198,
		part_id: 132,
		quantity: 228,
	},
	{
		garage_id: 198,
		part_id: 133,
		quantity: 81,
	},
	{
		garage_id: 198,
		part_id: 134,
		quantity: 193,
	},
	{
		garage_id: 198,
		part_id: 135,
		quantity: 334,
	},
	{
		garage_id: 198,
		part_id: 136,
		quantity: 405,
	},
	{
		garage_id: 198,
		part_id: 137,
		quantity: 59,
	},
	{
		garage_id: 198,
		part_id: 138,
		quantity: 56,
	},
	{
		garage_id: 198,
		part_id: 139,
		quantity: 253,
	},
	{
		garage_id: 198,
		part_id: 140,
		quantity: 232,
	},
	{
		garage_id: 198,
		part_id: 141,
		quantity: 470,
	},
	{
		garage_id: 198,
		part_id: 142,
		quantity: 290,
	},
	{
		garage_id: 198,
		part_id: 143,
		quantity: 202,
	},
	{
		garage_id: 198,
		part_id: 144,
		quantity: 378,
	},
	{
		garage_id: 198,
		part_id: 145,
		quantity: 192,
	},
	{
		garage_id: 198,
		part_id: 146,
		quantity: 159,
	},
	{
		garage_id: 198,
		part_id: 147,
		quantity: 473,
	},
	{
		garage_id: 198,
		part_id: 148,
		quantity: 22,
	},
	{
		garage_id: 198,
		part_id: 149,
		quantity: 321,
	},
	{
		garage_id: 198,
		part_id: 150,
		quantity: 155,
	},
	{
		garage_id: 198,
		part_id: 151,
		quantity: 428,
	},
	{
		garage_id: 198,
		part_id: 152,
		quantity: 411,
	},
	{
		garage_id: 198,
		part_id: 153,
		quantity: 288,
	},
	{
		garage_id: 198,
		part_id: 154,
		quantity: 228,
	},
	{
		garage_id: 198,
		part_id: 155,
		quantity: 250,
	},
	{
		garage_id: 198,
		part_id: 156,
		quantity: 484,
	},
	{
		garage_id: 198,
		part_id: 157,
		quantity: 153,
	},
	{
		garage_id: 198,
		part_id: 158,
		quantity: 50,
	},
	{
		garage_id: 198,
		part_id: 159,
		quantity: 152,
	},
	{
		garage_id: 198,
		part_id: 160,
		quantity: 220,
	},
	{
		garage_id: 198,
		part_id: 161,
		quantity: 491,
	},
	{
		garage_id: 198,
		part_id: 162,
		quantity: 436,
	},
	{
		garage_id: 198,
		part_id: 163,
		quantity: 401,
	},
	{
		garage_id: 198,
		part_id: 164,
		quantity: 384,
	},
	{
		garage_id: 198,
		part_id: 165,
		quantity: 144,
	},
	{
		garage_id: 198,
		part_id: 166,
		quantity: 342,
	},
	{
		garage_id: 198,
		part_id: 167,
		quantity: 448,
	},
	{
		garage_id: 198,
		part_id: 168,
		quantity: 263,
	},
	{
		garage_id: 198,
		part_id: 169,
		quantity: 294,
	},
	{
		garage_id: 198,
		part_id: 170,
		quantity: 337,
	},
	{
		garage_id: 198,
		part_id: 171,
		quantity: 289,
	},
	{
		garage_id: 198,
		part_id: 172,
		quantity: 164,
	},
	{
		garage_id: 198,
		part_id: 173,
		quantity: 374,
	},
	{
		garage_id: 198,
		part_id: 174,
		quantity: 61,
	},
	{
		garage_id: 198,
		part_id: 175,
		quantity: 191,
	},
	{
		garage_id: 198,
		part_id: 176,
		quantity: 243,
	},
	{
		garage_id: 198,
		part_id: 177,
		quantity: 364,
	},
	{
		garage_id: 198,
		part_id: 178,
		quantity: 56,
	},
	{
		garage_id: 198,
		part_id: 179,
		quantity: 208,
	},
	{
		garage_id: 198,
		part_id: 180,
		quantity: 204,
	},
	{
		garage_id: 198,
		part_id: 181,
		quantity: 64,
	},
	{
		garage_id: 198,
		part_id: 182,
		quantity: 300,
	},
	{
		garage_id: 198,
		part_id: 183,
		quantity: 303,
	},
	{
		garage_id: 198,
		part_id: 184,
		quantity: 318,
	},
	{
		garage_id: 198,
		part_id: 185,
		quantity: 305,
	},
	{
		garage_id: 198,
		part_id: 186,
		quantity: 485,
	},
	{
		garage_id: 198,
		part_id: 187,
		quantity: 49,
	},
	{
		garage_id: 198,
		part_id: 188,
		quantity: 174,
	},
	{
		garage_id: 198,
		part_id: 189,
		quantity: 218,
	},
	{
		garage_id: 198,
		part_id: 190,
		quantity: 187,
	},
	{
		garage_id: 198,
		part_id: 191,
		quantity: 296,
	},
	{
		garage_id: 198,
		part_id: 192,
		quantity: 360,
	},
	{
		garage_id: 198,
		part_id: 193,
		quantity: 190,
	},
	{
		garage_id: 198,
		part_id: 194,
		quantity: 229,
	},
	{
		garage_id: 198,
		part_id: 195,
		quantity: 355,
	},
	{
		garage_id: 198,
		part_id: 196,
		quantity: 187,
	},
	{
		garage_id: 198,
		part_id: 197,
		quantity: 442,
	},
	{
		garage_id: 198,
		part_id: 198,
		quantity: 199,
	},
	{
		garage_id: 198,
		part_id: 199,
		quantity: 473,
	},
	{
		garage_id: 198,
		part_id: 200,
		quantity: 59,
	},
	{
		garage_id: 198,
		part_id: 201,
		quantity: 141,
	},
	{
		garage_id: 198,
		part_id: 202,
		quantity: 223,
	},
	{
		garage_id: 198,
		part_id: 203,
		quantity: 340,
	},
	{
		garage_id: 198,
		part_id: 204,
		quantity: 244,
	},
	{
		garage_id: 198,
		part_id: 205,
		quantity: 312,
	},
	{
		garage_id: 198,
		part_id: 206,
		quantity: 326,
	},
	{
		garage_id: 198,
		part_id: 207,
		quantity: 480,
	},
	{
		garage_id: 198,
		part_id: 208,
		quantity: 92,
	},
	{
		garage_id: 198,
		part_id: 209,
		quantity: 213,
	},
	{
		garage_id: 198,
		part_id: 210,
		quantity: 453,
	},
	{
		garage_id: 198,
		part_id: 211,
		quantity: 39,
	},
	{
		garage_id: 198,
		part_id: 212,
		quantity: 157,
	},
	{
		garage_id: 198,
		part_id: 213,
		quantity: 269,
	},
	{
		garage_id: 198,
		part_id: 214,
		quantity: 410,
	},
	{
		garage_id: 198,
		part_id: 215,
		quantity: 395,
	},
	{
		garage_id: 198,
		part_id: 216,
		quantity: 416,
	},
	{
		garage_id: 198,
		part_id: 217,
		quantity: 286,
	},
	{
		garage_id: 198,
		part_id: 218,
		quantity: 400,
	},
	{
		garage_id: 198,
		part_id: 219,
		quantity: 13,
	},
	{
		garage_id: 198,
		part_id: 220,
		quantity: 455,
	},
	{
		garage_id: 198,
		part_id: 221,
		quantity: 331,
	},
	{
		garage_id: 198,
		part_id: 222,
		quantity: 282,
	},
	{
		garage_id: 198,
		part_id: 223,
		quantity: 191,
	},
	{
		garage_id: 198,
		part_id: 224,
		quantity: 375,
	},
	{
		garage_id: 198,
		part_id: 225,
		quantity: 412,
	},
	{
		garage_id: 198,
		part_id: 226,
		quantity: 51,
	},
	{
		garage_id: 198,
		part_id: 227,
		quantity: 265,
	},
	{
		garage_id: 198,
		part_id: 228,
		quantity: 287,
	},
	{
		garage_id: 198,
		part_id: 229,
		quantity: 81,
	},
	{
		garage_id: 198,
		part_id: 230,
		quantity: 337,
	},
	{
		garage_id: 198,
		part_id: 231,
		quantity: 402,
	},
	{
		garage_id: 198,
		part_id: 232,
		quantity: 235,
	},
	{
		garage_id: 198,
		part_id: 233,
		quantity: 444,
	},
	{
		garage_id: 198,
		part_id: 234,
		quantity: 471,
	},
	{
		garage_id: 198,
		part_id: 235,
		quantity: 23,
	},
	{
		garage_id: 198,
		part_id: 236,
		quantity: 2,
	},
	{
		garage_id: 198,
		part_id: 237,
		quantity: 29,
	},
	{
		garage_id: 198,
		part_id: 238,
		quantity: 396,
	},
	{
		garage_id: 198,
		part_id: 239,
		quantity: 321,
	},
	{
		garage_id: 198,
		part_id: 240,
		quantity: 97,
	},
	{
		garage_id: 198,
		part_id: 241,
		quantity: 194,
	},
	{
		garage_id: 198,
		part_id: 242,
		quantity: 48,
	},
	{
		garage_id: 198,
		part_id: 243,
		quantity: 447,
	},
	{
		garage_id: 198,
		part_id: 244,
		quantity: 78,
	},
	{
		garage_id: 198,
		part_id: 245,
		quantity: 56,
	},
	{
		garage_id: 198,
		part_id: 246,
		quantity: 90,
	},
	{
		garage_id: 198,
		part_id: 247,
		quantity: 187,
	},
	{
		garage_id: 198,
		part_id: 248,
		quantity: 417,
	},
	{
		garage_id: 198,
		part_id: 249,
		quantity: 300,
	},
	{
		garage_id: 198,
		part_id: 250,
		quantity: 409,
	},
	{
		garage_id: 198,
		part_id: 251,
		quantity: 455,
	},
	{
		garage_id: 198,
		part_id: 252,
		quantity: 260,
	},
	{
		garage_id: 198,
		part_id: 253,
		quantity: 475,
	},
	{
		garage_id: 198,
		part_id: 254,
		quantity: 54,
	},
	{
		garage_id: 198,
		part_id: 255,
		quantity: 318,
	},
	{
		garage_id: 198,
		part_id: 256,
		quantity: 220,
	},
	{
		garage_id: 198,
		part_id: 257,
		quantity: 396,
	},
	{
		garage_id: 198,
		part_id: 258,
		quantity: 274,
	},
	{
		garage_id: 198,
		part_id: 259,
		quantity: 229,
	},
	{
		garage_id: 198,
		part_id: 260,
		quantity: 26,
	},
	{
		garage_id: 198,
		part_id: 261,
		quantity: 180,
	},
	{
		garage_id: 198,
		part_id: 262,
		quantity: 437,
	},
	{
		garage_id: 198,
		part_id: 263,
		quantity: 42,
	},
	{
		garage_id: 198,
		part_id: 264,
		quantity: 47,
	},
	{
		garage_id: 198,
		part_id: 265,
		quantity: 55,
	},
	{
		garage_id: 198,
		part_id: 266,
		quantity: 193,
	},
	{
		garage_id: 198,
		part_id: 267,
		quantity: 494,
	},
	{
		garage_id: 198,
		part_id: 268,
		quantity: 69,
	},
	{
		garage_id: 198,
		part_id: 269,
		quantity: 264,
	},
	{
		garage_id: 198,
		part_id: 270,
		quantity: 268,
	},
	{
		garage_id: 198,
		part_id: 271,
		quantity: 344,
	},
	{
		garage_id: 198,
		part_id: 272,
		quantity: 363,
	},
	{
		garage_id: 198,
		part_id: 273,
		quantity: 385,
	},
	{
		garage_id: 198,
		part_id: 274,
		quantity: 244,
	},
	{
		garage_id: 198,
		part_id: 275,
		quantity: 329,
	},
	{
		garage_id: 198,
		part_id: 276,
		quantity: 86,
	},
	{
		garage_id: 198,
		part_id: 277,
		quantity: 278,
	},
	{
		garage_id: 198,
		part_id: 278,
		quantity: 13,
	},
	{
		garage_id: 198,
		part_id: 279,
		quantity: 419,
	},
	{
		garage_id: 198,
		part_id: 280,
		quantity: 315,
	},
	{
		garage_id: 198,
		part_id: 281,
		quantity: 429,
	},
	{
		garage_id: 198,
		part_id: 282,
		quantity: 355,
	},
	{
		garage_id: 198,
		part_id: 283,
		quantity: 160,
	},
	{
		garage_id: 198,
		part_id: 284,
		quantity: 494,
	},
	{
		garage_id: 198,
		part_id: 285,
		quantity: 56,
	},
	{
		garage_id: 198,
		part_id: 286,
		quantity: 298,
	},
	{
		garage_id: 198,
		part_id: 287,
		quantity: 427,
	},
	{
		garage_id: 198,
		part_id: 288,
		quantity: 333,
	},
	{
		garage_id: 198,
		part_id: 289,
		quantity: 7,
	},
	{
		garage_id: 198,
		part_id: 290,
		quantity: 382,
	},
	{
		garage_id: 198,
		part_id: 291,
		quantity: 405,
	},
	{
		garage_id: 198,
		part_id: 292,
		quantity: 3,
	},
	{
		garage_id: 198,
		part_id: 293,
		quantity: 95,
	},
	{
		garage_id: 198,
		part_id: 294,
		quantity: 29,
	},
	{
		garage_id: 198,
		part_id: 295,
		quantity: 153,
	},
	{
		garage_id: 198,
		part_id: 296,
		quantity: 469,
	},
	{
		garage_id: 198,
		part_id: 297,
		quantity: 386,
	},
	{
		garage_id: 198,
		part_id: 298,
		quantity: 293,
	},
	{
		garage_id: 198,
		part_id: 299,
		quantity: 382,
	},
	{
		garage_id: 198,
		part_id: 300,
		quantity: 363,
	},
	{
		garage_id: 198,
		part_id: 301,
		quantity: 62,
	},
	{
		garage_id: 198,
		part_id: 302,
		quantity: 50,
	},
	{
		garage_id: 198,
		part_id: 303,
		quantity: 319,
	},
	{
		garage_id: 198,
		part_id: 304,
		quantity: 188,
	},
	{
		garage_id: 198,
		part_id: 305,
		quantity: 352,
	},
	{
		garage_id: 198,
		part_id: 306,
		quantity: 243,
	},
	{
		garage_id: 198,
		part_id: 307,
		quantity: 224,
	},
	{
		garage_id: 198,
		part_id: 308,
		quantity: 57,
	},
	{
		garage_id: 198,
		part_id: 309,
		quantity: 124,
	},
	{
		garage_id: 198,
		part_id: 310,
		quantity: 348,
	},
	{
		garage_id: 198,
		part_id: 311,
		quantity: 211,
	},
	{
		garage_id: 198,
		part_id: 312,
		quantity: 313,
	},
	{
		garage_id: 198,
		part_id: 313,
		quantity: 222,
	},
	{
		garage_id: 198,
		part_id: 314,
		quantity: 264,
	},
	{
		garage_id: 198,
		part_id: 315,
		quantity: 136,
	},
	{
		garage_id: 198,
		part_id: 316,
		quantity: 411,
	},
	{
		garage_id: 198,
		part_id: 317,
		quantity: 419,
	},
	{
		garage_id: 198,
		part_id: 318,
		quantity: 290,
	},
	{
		garage_id: 198,
		part_id: 319,
		quantity: 435,
	},
	{
		garage_id: 198,
		part_id: 320,
		quantity: 460,
	},
	{
		garage_id: 198,
		part_id: 321,
		quantity: 420,
	},
	{
		garage_id: 198,
		part_id: 322,
		quantity: 109,
	},
	{
		garage_id: 198,
		part_id: 323,
		quantity: 94,
	},
	{
		garage_id: 198,
		part_id: 324,
		quantity: 105,
	},
	{
		garage_id: 198,
		part_id: 325,
		quantity: 41,
	},
	{
		garage_id: 198,
		part_id: 326,
		quantity: 89,
	},
	{
		garage_id: 198,
		part_id: 327,
		quantity: 29,
	},
	{
		garage_id: 198,
		part_id: 328,
		quantity: 38,
	},
	{
		garage_id: 198,
		part_id: 329,
		quantity: 19,
	},
	{
		garage_id: 198,
		part_id: 330,
		quantity: 243,
	},
	{
		garage_id: 198,
		part_id: 331,
		quantity: 398,
	},
	{
		garage_id: 198,
		part_id: 332,
		quantity: 134,
	},
	{
		garage_id: 198,
		part_id: 333,
		quantity: 324,
	},
	{
		garage_id: 198,
		part_id: 334,
		quantity: 227,
	},
	{
		garage_id: 198,
		part_id: 335,
		quantity: 255,
	},
	{
		garage_id: 198,
		part_id: 336,
		quantity: 141,
	},
	{
		garage_id: 198,
		part_id: 337,
		quantity: 256,
	},
	{
		garage_id: 198,
		part_id: 338,
		quantity: 230,
	},
	{
		garage_id: 198,
		part_id: 339,
		quantity: 279,
	},
	{
		garage_id: 198,
		part_id: 340,
		quantity: 157,
	},
	{
		garage_id: 198,
		part_id: 341,
		quantity: 64,
	},
	{
		garage_id: 198,
		part_id: 342,
		quantity: 42,
	},
	{
		garage_id: 198,
		part_id: 343,
		quantity: 126,
	},
	{
		garage_id: 198,
		part_id: 344,
		quantity: 414,
	},
	{
		garage_id: 198,
		part_id: 345,
		quantity: 403,
	},
	{
		garage_id: 198,
		part_id: 346,
		quantity: 13,
	},
	{
		garage_id: 198,
		part_id: 347,
		quantity: 306,
	},
	{
		garage_id: 198,
		part_id: 348,
		quantity: 246,
	},
	{
		garage_id: 198,
		part_id: 349,
		quantity: 337,
	},
	{
		garage_id: 198,
		part_id: 350,
		quantity: 58,
	},
	{
		garage_id: 198,
		part_id: 351,
		quantity: 437,
	},
	{
		garage_id: 198,
		part_id: 352,
		quantity: 93,
	},
	{
		garage_id: 198,
		part_id: 353,
		quantity: 337,
	},
	{
		garage_id: 198,
		part_id: 354,
		quantity: 357,
	},
	{
		garage_id: 198,
		part_id: 355,
		quantity: 44,
	},
	{
		garage_id: 198,
		part_id: 356,
		quantity: 443,
	},
	{
		garage_id: 198,
		part_id: 357,
		quantity: 200,
	},
	{
		garage_id: 198,
		part_id: 358,
		quantity: 113,
	},
	{
		garage_id: 198,
		part_id: 359,
		quantity: 294,
	},
	{
		garage_id: 198,
		part_id: 360,
		quantity: 227,
	},
	{
		garage_id: 198,
		part_id: 361,
		quantity: 207,
	},
	{
		garage_id: 198,
		part_id: 362,
		quantity: 289,
	},
	{
		garage_id: 198,
		part_id: 363,
		quantity: 326,
	},
	{
		garage_id: 198,
		part_id: 364,
		quantity: 456,
	},
	{
		garage_id: 198,
		part_id: 365,
		quantity: 271,
	},
	{
		garage_id: 198,
		part_id: 366,
		quantity: 299,
	},
	{
		garage_id: 198,
		part_id: 367,
		quantity: 58,
	},
	{
		garage_id: 198,
		part_id: 368,
		quantity: 199,
	},
	{
		garage_id: 198,
		part_id: 369,
		quantity: 64,
	},
	{
		garage_id: 198,
		part_id: 370,
		quantity: 108,
	},
	{
		garage_id: 198,
		part_id: 371,
		quantity: 109,
	},
	{
		garage_id: 198,
		part_id: 372,
		quantity: 159,
	},
	{
		garage_id: 198,
		part_id: 373,
		quantity: 155,
	},
	{
		garage_id: 198,
		part_id: 374,
		quantity: 37,
	},
	{
		garage_id: 198,
		part_id: 375,
		quantity: 281,
	},
	{
		garage_id: 198,
		part_id: 376,
		quantity: 84,
	},
	{
		garage_id: 198,
		part_id: 377,
		quantity: 167,
	},
	{
		garage_id: 198,
		part_id: 378,
		quantity: 408,
	},
	{
		garage_id: 198,
		part_id: 379,
		quantity: 367,
	},
	{
		garage_id: 198,
		part_id: 380,
		quantity: 178,
	},
	{
		garage_id: 198,
		part_id: 381,
		quantity: 107,
	},
	{
		garage_id: 198,
		part_id: 382,
		quantity: 450,
	},
	{
		garage_id: 198,
		part_id: 383,
		quantity: 129,
	},
	{
		garage_id: 198,
		part_id: 384,
		quantity: 212,
	},
	{
		garage_id: 198,
		part_id: 385,
		quantity: 98,
	},
	{
		garage_id: 198,
		part_id: 386,
		quantity: 97,
	},
	{
		garage_id: 198,
		part_id: 387,
		quantity: 60,
	},
	{
		garage_id: 198,
		part_id: 388,
		quantity: 26,
	},
	{
		garage_id: 198,
		part_id: 389,
		quantity: 483,
	},
	{
		garage_id: 198,
		part_id: 390,
		quantity: 118,
	},
	{
		garage_id: 198,
		part_id: 391,
		quantity: 60,
	},
	{
		garage_id: 198,
		part_id: 392,
		quantity: 481,
	},
	{
		garage_id: 198,
		part_id: 393,
		quantity: 85,
	},
	{
		garage_id: 198,
		part_id: 394,
		quantity: 123,
	},
	{
		garage_id: 198,
		part_id: 395,
		quantity: 122,
	},
	{
		garage_id: 198,
		part_id: 396,
		quantity: 450,
	},
	{
		garage_id: 198,
		part_id: 397,
		quantity: 212,
	},
	{
		garage_id: 198,
		part_id: 398,
		quantity: 122,
	},
	{
		garage_id: 198,
		part_id: 399,
		quantity: 191,
	},
	{
		garage_id: 199,
		part_id: 0,
		quantity: 266,
	},
	{
		garage_id: 199,
		part_id: 1,
		quantity: 403,
	},
	{
		garage_id: 199,
		part_id: 2,
		quantity: 233,
	},
	{
		garage_id: 199,
		part_id: 3,
		quantity: 266,
	},
	{
		garage_id: 199,
		part_id: 4,
		quantity: 140,
	},
	{
		garage_id: 199,
		part_id: 5,
		quantity: 228,
	},
	{
		garage_id: 199,
		part_id: 6,
		quantity: 247,
	},
	{
		garage_id: 199,
		part_id: 7,
		quantity: 85,
	},
	{
		garage_id: 199,
		part_id: 8,
		quantity: 172,
	},
	{
		garage_id: 199,
		part_id: 9,
		quantity: 459,
	},
	{
		garage_id: 199,
		part_id: 10,
		quantity: 289,
	},
	{
		garage_id: 199,
		part_id: 11,
		quantity: 438,
	},
	{
		garage_id: 199,
		part_id: 12,
		quantity: 23,
	},
	{
		garage_id: 199,
		part_id: 13,
		quantity: 273,
	},
	{
		garage_id: 199,
		part_id: 14,
		quantity: 294,
	},
	{
		garage_id: 199,
		part_id: 15,
		quantity: 3,
	},
	{
		garage_id: 199,
		part_id: 16,
		quantity: 65,
	},
	{
		garage_id: 199,
		part_id: 17,
		quantity: 28,
	},
	{
		garage_id: 199,
		part_id: 18,
		quantity: 39,
	},
	{
		garage_id: 199,
		part_id: 19,
		quantity: 230,
	},
	{
		garage_id: 199,
		part_id: 20,
		quantity: 5,
	},
	{
		garage_id: 199,
		part_id: 21,
		quantity: 459,
	},
	{
		garage_id: 199,
		part_id: 22,
		quantity: 358,
	},
	{
		garage_id: 199,
		part_id: 23,
		quantity: 264,
	},
	{
		garage_id: 199,
		part_id: 24,
		quantity: 352,
	},
	{
		garage_id: 199,
		part_id: 25,
		quantity: 351,
	},
	{
		garage_id: 199,
		part_id: 26,
		quantity: 410,
	},
	{
		garage_id: 199,
		part_id: 27,
		quantity: 20,
	},
	{
		garage_id: 199,
		part_id: 28,
		quantity: 272,
	},
	{
		garage_id: 199,
		part_id: 29,
		quantity: 312,
	},
	{
		garage_id: 199,
		part_id: 30,
		quantity: 347,
	},
	{
		garage_id: 199,
		part_id: 31,
		quantity: 126,
	},
	{
		garage_id: 199,
		part_id: 32,
		quantity: 374,
	},
	{
		garage_id: 199,
		part_id: 33,
		quantity: 110,
	},
	{
		garage_id: 199,
		part_id: 34,
		quantity: 357,
	},
	{
		garage_id: 199,
		part_id: 35,
		quantity: 233,
	},
	{
		garage_id: 199,
		part_id: 36,
		quantity: 479,
	},
	{
		garage_id: 199,
		part_id: 37,
		quantity: 196,
	},
	{
		garage_id: 199,
		part_id: 38,
		quantity: 11,
	},
	{
		garage_id: 199,
		part_id: 39,
		quantity: 60,
	},
	{
		garage_id: 199,
		part_id: 40,
		quantity: 32,
	},
	{
		garage_id: 199,
		part_id: 41,
		quantity: 414,
	},
	{
		garage_id: 199,
		part_id: 42,
		quantity: 161,
	},
	{
		garage_id: 199,
		part_id: 43,
		quantity: 1,
	},
	{
		garage_id: 199,
		part_id: 44,
		quantity: 490,
	},
	{
		garage_id: 199,
		part_id: 45,
		quantity: 156,
	},
	{
		garage_id: 199,
		part_id: 46,
		quantity: 48,
	},
	{
		garage_id: 199,
		part_id: 47,
		quantity: 213,
	},
	{
		garage_id: 199,
		part_id: 48,
		quantity: 297,
	},
	{
		garage_id: 199,
		part_id: 49,
		quantity: 61,
	},
	{
		garage_id: 199,
		part_id: 50,
		quantity: 23,
	},
	{
		garage_id: 199,
		part_id: 51,
		quantity: 442,
	},
	{
		garage_id: 199,
		part_id: 52,
		quantity: 395,
	},
	{
		garage_id: 199,
		part_id: 53,
		quantity: 298,
	},
	{
		garage_id: 199,
		part_id: 54,
		quantity: 365,
	},
	{
		garage_id: 199,
		part_id: 55,
		quantity: 468,
	},
	{
		garage_id: 199,
		part_id: 56,
		quantity: 280,
	},
	{
		garage_id: 199,
		part_id: 57,
		quantity: 95,
	},
	{
		garage_id: 199,
		part_id: 58,
		quantity: 223,
	},
	{
		garage_id: 199,
		part_id: 59,
		quantity: 262,
	},
	{
		garage_id: 199,
		part_id: 60,
		quantity: 348,
	},
	{
		garage_id: 199,
		part_id: 61,
		quantity: 84,
	},
	{
		garage_id: 199,
		part_id: 62,
		quantity: 452,
	},
	{
		garage_id: 199,
		part_id: 63,
		quantity: 46,
	},
	{
		garage_id: 199,
		part_id: 64,
		quantity: 83,
	},
	{
		garage_id: 199,
		part_id: 65,
		quantity: 38,
	},
	{
		garage_id: 199,
		part_id: 66,
		quantity: 34,
	},
	{
		garage_id: 199,
		part_id: 67,
		quantity: 155,
	},
	{
		garage_id: 199,
		part_id: 68,
		quantity: 136,
	},
	{
		garage_id: 199,
		part_id: 69,
		quantity: 476,
	},
	{
		garage_id: 199,
		part_id: 70,
		quantity: 373,
	},
	{
		garage_id: 199,
		part_id: 71,
		quantity: 386,
	},
	{
		garage_id: 199,
		part_id: 72,
		quantity: 367,
	},
	{
		garage_id: 199,
		part_id: 73,
		quantity: 446,
	},
	{
		garage_id: 199,
		part_id: 74,
		quantity: 379,
	},
	{
		garage_id: 199,
		part_id: 75,
		quantity: 117,
	},
	{
		garage_id: 199,
		part_id: 76,
		quantity: 8,
	},
	{
		garage_id: 199,
		part_id: 77,
		quantity: 376,
	},
	{
		garage_id: 199,
		part_id: 78,
		quantity: 131,
	},
	{
		garage_id: 199,
		part_id: 79,
		quantity: 467,
	},
	{
		garage_id: 199,
		part_id: 80,
		quantity: 334,
	},
	{
		garage_id: 199,
		part_id: 81,
		quantity: 77,
	},
	{
		garage_id: 199,
		part_id: 82,
		quantity: 48,
	},
	{
		garage_id: 199,
		part_id: 83,
		quantity: 273,
	},
	{
		garage_id: 199,
		part_id: 84,
		quantity: 433,
	},
	{
		garage_id: 199,
		part_id: 85,
		quantity: 258,
	},
	{
		garage_id: 199,
		part_id: 86,
		quantity: 464,
	},
	{
		garage_id: 199,
		part_id: 87,
		quantity: 25,
	},
	{
		garage_id: 199,
		part_id: 88,
		quantity: 18,
	},
	{
		garage_id: 199,
		part_id: 89,
		quantity: 349,
	},
	{
		garage_id: 199,
		part_id: 90,
		quantity: 114,
	},
	{
		garage_id: 199,
		part_id: 91,
		quantity: 362,
	},
	{
		garage_id: 199,
		part_id: 92,
		quantity: 424,
	},
	{
		garage_id: 199,
		part_id: 93,
		quantity: 370,
	},
	{
		garage_id: 199,
		part_id: 94,
		quantity: 191,
	},
	{
		garage_id: 199,
		part_id: 95,
		quantity: 140,
	},
	{
		garage_id: 199,
		part_id: 96,
		quantity: 158,
	},
	{
		garage_id: 199,
		part_id: 97,
		quantity: 121,
	},
	{
		garage_id: 199,
		part_id: 98,
		quantity: 231,
	},
	{
		garage_id: 199,
		part_id: 99,
		quantity: 461,
	},
	{
		garage_id: 199,
		part_id: 100,
		quantity: 499,
	},
	{
		garage_id: 199,
		part_id: 101,
		quantity: 126,
	},
	{
		garage_id: 199,
		part_id: 102,
		quantity: 76,
	},
	{
		garage_id: 199,
		part_id: 103,
		quantity: 214,
	},
	{
		garage_id: 199,
		part_id: 104,
		quantity: 74,
	},
	{
		garage_id: 199,
		part_id: 105,
		quantity: 184,
	},
	{
		garage_id: 199,
		part_id: 106,
		quantity: 86,
	},
	{
		garage_id: 199,
		part_id: 107,
		quantity: 376,
	},
	{
		garage_id: 199,
		part_id: 108,
		quantity: 420,
	},
	{
		garage_id: 199,
		part_id: 109,
		quantity: 278,
	},
	{
		garage_id: 199,
		part_id: 110,
		quantity: 200,
	},
	{
		garage_id: 199,
		part_id: 111,
		quantity: 232,
	},
	{
		garage_id: 199,
		part_id: 112,
		quantity: 399,
	},
	{
		garage_id: 199,
		part_id: 113,
		quantity: 11,
	},
	{
		garage_id: 199,
		part_id: 114,
		quantity: 402,
	},
	{
		garage_id: 199,
		part_id: 115,
		quantity: 152,
	},
	{
		garage_id: 199,
		part_id: 116,
		quantity: 266,
	},
	{
		garage_id: 199,
		part_id: 117,
		quantity: 361,
	},
	{
		garage_id: 199,
		part_id: 118,
		quantity: 264,
	},
	{
		garage_id: 199,
		part_id: 119,
		quantity: 69,
	},
	{
		garage_id: 199,
		part_id: 120,
		quantity: 329,
	},
	{
		garage_id: 199,
		part_id: 121,
		quantity: 211,
	},
	{
		garage_id: 199,
		part_id: 122,
		quantity: 209,
	},
	{
		garage_id: 199,
		part_id: 123,
		quantity: 473,
	},
	{
		garage_id: 199,
		part_id: 124,
		quantity: 488,
	},
	{
		garage_id: 199,
		part_id: 125,
		quantity: 434,
	},
	{
		garage_id: 199,
		part_id: 126,
		quantity: 62,
	},
	{
		garage_id: 199,
		part_id: 127,
		quantity: 119,
	},
	{
		garage_id: 199,
		part_id: 128,
		quantity: 470,
	},
	{
		garage_id: 199,
		part_id: 129,
		quantity: 458,
	},
	{
		garage_id: 199,
		part_id: 130,
		quantity: 297,
	},
	{
		garage_id: 199,
		part_id: 131,
		quantity: 377,
	},
	{
		garage_id: 199,
		part_id: 132,
		quantity: 211,
	},
	{
		garage_id: 199,
		part_id: 133,
		quantity: 193,
	},
	{
		garage_id: 199,
		part_id: 134,
		quantity: 112,
	},
	{
		garage_id: 199,
		part_id: 135,
		quantity: 383,
	},
	{
		garage_id: 199,
		part_id: 136,
		quantity: 474,
	},
	{
		garage_id: 199,
		part_id: 137,
		quantity: 97,
	},
	{
		garage_id: 199,
		part_id: 138,
		quantity: 183,
	},
	{
		garage_id: 199,
		part_id: 139,
		quantity: 221,
	},
	{
		garage_id: 199,
		part_id: 140,
		quantity: 96,
	},
	{
		garage_id: 199,
		part_id: 141,
		quantity: 353,
	},
	{
		garage_id: 199,
		part_id: 142,
		quantity: 327,
	},
	{
		garage_id: 199,
		part_id: 143,
		quantity: 404,
	},
	{
		garage_id: 199,
		part_id: 144,
		quantity: 175,
	},
	{
		garage_id: 199,
		part_id: 145,
		quantity: 98,
	},
	{
		garage_id: 199,
		part_id: 146,
		quantity: 378,
	},
	{
		garage_id: 199,
		part_id: 147,
		quantity: 320,
	},
	{
		garage_id: 199,
		part_id: 148,
		quantity: 152,
	},
	{
		garage_id: 199,
		part_id: 149,
		quantity: 135,
	},
	{
		garage_id: 199,
		part_id: 150,
		quantity: 355,
	},
	{
		garage_id: 199,
		part_id: 151,
		quantity: 266,
	},
	{
		garage_id: 199,
		part_id: 152,
		quantity: 47,
	},
	{
		garage_id: 199,
		part_id: 153,
		quantity: 101,
	},
	{
		garage_id: 199,
		part_id: 154,
		quantity: 469,
	},
	{
		garage_id: 199,
		part_id: 155,
		quantity: 155,
	},
	{
		garage_id: 199,
		part_id: 156,
		quantity: 441,
	},
	{
		garage_id: 199,
		part_id: 157,
		quantity: 120,
	},
	{
		garage_id: 199,
		part_id: 158,
		quantity: 180,
	},
	{
		garage_id: 199,
		part_id: 159,
		quantity: 1,
	},
	{
		garage_id: 199,
		part_id: 160,
		quantity: 390,
	},
	{
		garage_id: 199,
		part_id: 161,
		quantity: 159,
	},
	{
		garage_id: 199,
		part_id: 162,
		quantity: 54,
	},
	{
		garage_id: 199,
		part_id: 163,
		quantity: 148,
	},
	{
		garage_id: 199,
		part_id: 164,
		quantity: 193,
	},
	{
		garage_id: 199,
		part_id: 165,
		quantity: 485,
	},
	{
		garage_id: 199,
		part_id: 166,
		quantity: 328,
	},
	{
		garage_id: 199,
		part_id: 167,
		quantity: 239,
	},
	{
		garage_id: 199,
		part_id: 168,
		quantity: 188,
	},
	{
		garage_id: 199,
		part_id: 169,
		quantity: 81,
	},
	{
		garage_id: 199,
		part_id: 170,
		quantity: 451,
	},
	{
		garage_id: 199,
		part_id: 171,
		quantity: 230,
	},
	{
		garage_id: 199,
		part_id: 172,
		quantity: 405,
	},
	{
		garage_id: 199,
		part_id: 173,
		quantity: 43,
	},
	{
		garage_id: 199,
		part_id: 174,
		quantity: 93,
	},
	{
		garage_id: 199,
		part_id: 175,
		quantity: 277,
	},
	{
		garage_id: 199,
		part_id: 176,
		quantity: 207,
	},
	{
		garage_id: 199,
		part_id: 177,
		quantity: 276,
	},
	{
		garage_id: 199,
		part_id: 178,
		quantity: 472,
	},
	{
		garage_id: 199,
		part_id: 179,
		quantity: 242,
	},
	{
		garage_id: 199,
		part_id: 180,
		quantity: 76,
	},
	{
		garage_id: 199,
		part_id: 181,
		quantity: 184,
	},
	{
		garage_id: 199,
		part_id: 182,
		quantity: 343,
	},
	{
		garage_id: 199,
		part_id: 183,
		quantity: 108,
	},
	{
		garage_id: 199,
		part_id: 184,
		quantity: 168,
	},
	{
		garage_id: 199,
		part_id: 185,
		quantity: 433,
	},
	{
		garage_id: 199,
		part_id: 186,
		quantity: 188,
	},
	{
		garage_id: 199,
		part_id: 187,
		quantity: 344,
	},
	{
		garage_id: 199,
		part_id: 188,
		quantity: 144,
	},
	{
		garage_id: 199,
		part_id: 189,
		quantity: 401,
	},
	{
		garage_id: 199,
		part_id: 190,
		quantity: 34,
	},
	{
		garage_id: 199,
		part_id: 191,
		quantity: 106,
	},
	{
		garage_id: 199,
		part_id: 192,
		quantity: 308,
	},
	{
		garage_id: 199,
		part_id: 193,
		quantity: 395,
	},
	{
		garage_id: 199,
		part_id: 194,
		quantity: 116,
	},
	{
		garage_id: 199,
		part_id: 195,
		quantity: 169,
	},
	{
		garage_id: 199,
		part_id: 196,
		quantity: 240,
	},
	{
		garage_id: 199,
		part_id: 197,
		quantity: 109,
	},
	{
		garage_id: 199,
		part_id: 198,
		quantity: 322,
	},
	{
		garage_id: 199,
		part_id: 199,
		quantity: 7,
	},
	{
		garage_id: 199,
		part_id: 200,
		quantity: 299,
	},
	{
		garage_id: 199,
		part_id: 201,
		quantity: 369,
	},
	{
		garage_id: 199,
		part_id: 202,
		quantity: 401,
	},
	{
		garage_id: 199,
		part_id: 203,
		quantity: 7,
	},
	{
		garage_id: 199,
		part_id: 204,
		quantity: 410,
	},
	{
		garage_id: 199,
		part_id: 205,
		quantity: 219,
	},
	{
		garage_id: 199,
		part_id: 206,
		quantity: 446,
	},
	{
		garage_id: 199,
		part_id: 207,
		quantity: 116,
	},
	{
		garage_id: 199,
		part_id: 208,
		quantity: 372,
	},
	{
		garage_id: 199,
		part_id: 209,
		quantity: 177,
	},
	{
		garage_id: 199,
		part_id: 210,
		quantity: 257,
	},
	{
		garage_id: 199,
		part_id: 211,
		quantity: 337,
	},
	{
		garage_id: 199,
		part_id: 212,
		quantity: 450,
	},
	{
		garage_id: 199,
		part_id: 213,
		quantity: 442,
	},
	{
		garage_id: 199,
		part_id: 214,
		quantity: 465,
	},
	{
		garage_id: 199,
		part_id: 215,
		quantity: 17,
	},
	{
		garage_id: 199,
		part_id: 216,
		quantity: 109,
	},
	{
		garage_id: 199,
		part_id: 217,
		quantity: 168,
	},
	{
		garage_id: 199,
		part_id: 218,
		quantity: 371,
	},
	{
		garage_id: 199,
		part_id: 219,
		quantity: 380,
	},
	{
		garage_id: 199,
		part_id: 220,
		quantity: 441,
	},
	{
		garage_id: 199,
		part_id: 221,
		quantity: 162,
	},
	{
		garage_id: 199,
		part_id: 222,
		quantity: 160,
	},
	{
		garage_id: 199,
		part_id: 223,
		quantity: 14,
	},
	{
		garage_id: 199,
		part_id: 224,
		quantity: 207,
	},
	{
		garage_id: 199,
		part_id: 225,
		quantity: 408,
	},
	{
		garage_id: 199,
		part_id: 226,
		quantity: 322,
	},
	{
		garage_id: 199,
		part_id: 227,
		quantity: 279,
	},
	{
		garage_id: 199,
		part_id: 228,
		quantity: 359,
	},
	{
		garage_id: 199,
		part_id: 229,
		quantity: 369,
	},
	{
		garage_id: 199,
		part_id: 230,
		quantity: 440,
	},
	{
		garage_id: 199,
		part_id: 231,
		quantity: 278,
	},
	{
		garage_id: 199,
		part_id: 232,
		quantity: 336,
	},
	{
		garage_id: 199,
		part_id: 233,
		quantity: 327,
	},
	{
		garage_id: 199,
		part_id: 234,
		quantity: 212,
	},
	{
		garage_id: 199,
		part_id: 235,
		quantity: 50,
	},
	{
		garage_id: 199,
		part_id: 236,
		quantity: 55,
	},
	{
		garage_id: 199,
		part_id: 237,
		quantity: 467,
	},
	{
		garage_id: 199,
		part_id: 238,
		quantity: 295,
	},
	{
		garage_id: 199,
		part_id: 239,
		quantity: 405,
	},
	{
		garage_id: 199,
		part_id: 240,
		quantity: 420,
	},
	{
		garage_id: 199,
		part_id: 241,
		quantity: 350,
	},
	{
		garage_id: 199,
		part_id: 242,
		quantity: 397,
	},
	{
		garage_id: 199,
		part_id: 243,
		quantity: 62,
	},
	{
		garage_id: 199,
		part_id: 244,
		quantity: 82,
	},
	{
		garage_id: 199,
		part_id: 245,
		quantity: 345,
	},
	{
		garage_id: 199,
		part_id: 246,
		quantity: 20,
	},
	{
		garage_id: 199,
		part_id: 247,
		quantity: 434,
	},
	{
		garage_id: 199,
		part_id: 248,
		quantity: 46,
	},
	{
		garage_id: 199,
		part_id: 249,
		quantity: 56,
	},
	{
		garage_id: 199,
		part_id: 250,
		quantity: 217,
	},
	{
		garage_id: 199,
		part_id: 251,
		quantity: 333,
	},
	{
		garage_id: 199,
		part_id: 252,
		quantity: 16,
	},
	{
		garage_id: 199,
		part_id: 253,
		quantity: 288,
	},
	{
		garage_id: 199,
		part_id: 254,
		quantity: 144,
	},
	{
		garage_id: 199,
		part_id: 255,
		quantity: 153,
	},
	{
		garage_id: 199,
		part_id: 256,
		quantity: 301,
	},
	{
		garage_id: 199,
		part_id: 257,
		quantity: 400,
	},
	{
		garage_id: 199,
		part_id: 258,
		quantity: 216,
	},
	{
		garage_id: 199,
		part_id: 259,
		quantity: 99,
	},
	{
		garage_id: 199,
		part_id: 260,
		quantity: 497,
	},
	{
		garage_id: 199,
		part_id: 261,
		quantity: 465,
	},
	{
		garage_id: 199,
		part_id: 262,
		quantity: 284,
	},
	{
		garage_id: 199,
		part_id: 263,
		quantity: 394,
	},
	{
		garage_id: 199,
		part_id: 264,
		quantity: 387,
	},
	{
		garage_id: 199,
		part_id: 265,
		quantity: 244,
	},
	{
		garage_id: 199,
		part_id: 266,
		quantity: 106,
	},
	{
		garage_id: 199,
		part_id: 267,
		quantity: 385,
	},
	{
		garage_id: 199,
		part_id: 268,
		quantity: 489,
	},
	{
		garage_id: 199,
		part_id: 269,
		quantity: 254,
	},
	{
		garage_id: 199,
		part_id: 270,
		quantity: 101,
	},
	{
		garage_id: 199,
		part_id: 271,
		quantity: 247,
	},
	{
		garage_id: 199,
		part_id: 272,
		quantity: 15,
	},
	{
		garage_id: 199,
		part_id: 273,
		quantity: 179,
	},
	{
		garage_id: 199,
		part_id: 274,
		quantity: 34,
	},
	{
		garage_id: 199,
		part_id: 275,
		quantity: 53,
	},
	{
		garage_id: 199,
		part_id: 276,
		quantity: 104,
	},
	{
		garage_id: 199,
		part_id: 277,
		quantity: 64,
	},
	{
		garage_id: 199,
		part_id: 278,
		quantity: 219,
	},
	{
		garage_id: 199,
		part_id: 279,
		quantity: 320,
	},
	{
		garage_id: 199,
		part_id: 280,
		quantity: 123,
	},
	{
		garage_id: 199,
		part_id: 281,
		quantity: 151,
	},
	{
		garage_id: 199,
		part_id: 282,
		quantity: 472,
	},
	{
		garage_id: 199,
		part_id: 283,
		quantity: 182,
	},
	{
		garage_id: 199,
		part_id: 284,
		quantity: 318,
	},
	{
		garage_id: 199,
		part_id: 285,
		quantity: 456,
	},
	{
		garage_id: 199,
		part_id: 286,
		quantity: 253,
	},
	{
		garage_id: 199,
		part_id: 287,
		quantity: 309,
	},
	{
		garage_id: 199,
		part_id: 288,
		quantity: 12,
	},
	{
		garage_id: 199,
		part_id: 289,
		quantity: 428,
	},
	{
		garage_id: 199,
		part_id: 290,
		quantity: 168,
	},
	{
		garage_id: 199,
		part_id: 291,
		quantity: 437,
	},
	{
		garage_id: 199,
		part_id: 292,
		quantity: 41,
	},
	{
		garage_id: 199,
		part_id: 293,
		quantity: 130,
	},
	{
		garage_id: 199,
		part_id: 294,
		quantity: 135,
	},
	{
		garage_id: 199,
		part_id: 295,
		quantity: 369,
	},
	{
		garage_id: 199,
		part_id: 296,
		quantity: 296,
	},
	{
		garage_id: 199,
		part_id: 297,
		quantity: 136,
	},
	{
		garage_id: 199,
		part_id: 298,
		quantity: 336,
	},
	{
		garage_id: 199,
		part_id: 299,
		quantity: 433,
	},
	{
		garage_id: 199,
		part_id: 300,
		quantity: 315,
	},
	{
		garage_id: 199,
		part_id: 301,
		quantity: 149,
	},
	{
		garage_id: 199,
		part_id: 302,
		quantity: 92,
	},
	{
		garage_id: 199,
		part_id: 303,
		quantity: 193,
	},
	{
		garage_id: 199,
		part_id: 304,
		quantity: 97,
	},
	{
		garage_id: 199,
		part_id: 305,
		quantity: 400,
	},
	{
		garage_id: 199,
		part_id: 306,
		quantity: 418,
	},
	{
		garage_id: 199,
		part_id: 307,
		quantity: 163,
	},
	{
		garage_id: 199,
		part_id: 308,
		quantity: 416,
	},
	{
		garage_id: 199,
		part_id: 309,
		quantity: 319,
	},
	{
		garage_id: 199,
		part_id: 310,
		quantity: 417,
	},
	{
		garage_id: 199,
		part_id: 311,
		quantity: 230,
	},
	{
		garage_id: 199,
		part_id: 312,
		quantity: 42,
	},
	{
		garage_id: 199,
		part_id: 313,
		quantity: 304,
	},
	{
		garage_id: 199,
		part_id: 314,
		quantity: 370,
	},
	{
		garage_id: 199,
		part_id: 315,
		quantity: 388,
	},
	{
		garage_id: 199,
		part_id: 316,
		quantity: 130,
	},
	{
		garage_id: 199,
		part_id: 317,
		quantity: 289,
	},
	{
		garage_id: 199,
		part_id: 318,
		quantity: 91,
	},
	{
		garage_id: 199,
		part_id: 319,
		quantity: 252,
	},
	{
		garage_id: 199,
		part_id: 320,
		quantity: 500,
	},
	{
		garage_id: 199,
		part_id: 321,
		quantity: 388,
	},
	{
		garage_id: 199,
		part_id: 322,
		quantity: 343,
	},
	{
		garage_id: 199,
		part_id: 323,
		quantity: 224,
	},
	{
		garage_id: 199,
		part_id: 324,
		quantity: 251,
	},
	{
		garage_id: 199,
		part_id: 325,
		quantity: 351,
	},
	{
		garage_id: 199,
		part_id: 326,
		quantity: 419,
	},
	{
		garage_id: 199,
		part_id: 327,
		quantity: 174,
	},
	{
		garage_id: 199,
		part_id: 328,
		quantity: 298,
	},
	{
		garage_id: 199,
		part_id: 329,
		quantity: 180,
	},
	{
		garage_id: 199,
		part_id: 330,
		quantity: 35,
	},
	{
		garage_id: 199,
		part_id: 331,
		quantity: 171,
	},
	{
		garage_id: 199,
		part_id: 332,
		quantity: 305,
	},
	{
		garage_id: 199,
		part_id: 333,
		quantity: 433,
	},
	{
		garage_id: 199,
		part_id: 334,
		quantity: 47,
	},
	{
		garage_id: 199,
		part_id: 335,
		quantity: 441,
	},
	{
		garage_id: 199,
		part_id: 336,
		quantity: 467,
	},
	{
		garage_id: 199,
		part_id: 337,
		quantity: 98,
	},
	{
		garage_id: 199,
		part_id: 338,
		quantity: 95,
	},
	{
		garage_id: 199,
		part_id: 339,
		quantity: 7,
	},
	{
		garage_id: 199,
		part_id: 340,
		quantity: 329,
	},
	{
		garage_id: 199,
		part_id: 341,
		quantity: 363,
	},
	{
		garage_id: 199,
		part_id: 342,
		quantity: 185,
	},
	{
		garage_id: 199,
		part_id: 343,
		quantity: 307,
	},
	{
		garage_id: 199,
		part_id: 344,
		quantity: 303,
	},
	{
		garage_id: 199,
		part_id: 345,
		quantity: 259,
	},
	{
		garage_id: 199,
		part_id: 346,
		quantity: 172,
	},
	{
		garage_id: 199,
		part_id: 347,
		quantity: 426,
	},
	{
		garage_id: 199,
		part_id: 348,
		quantity: 119,
	},
	{
		garage_id: 199,
		part_id: 349,
		quantity: 344,
	},
	{
		garage_id: 199,
		part_id: 350,
		quantity: 393,
	},
	{
		garage_id: 199,
		part_id: 351,
		quantity: 355,
	},
	{
		garage_id: 199,
		part_id: 352,
		quantity: 472,
	},
	{
		garage_id: 199,
		part_id: 353,
		quantity: 434,
	},
	{
		garage_id: 199,
		part_id: 354,
		quantity: 475,
	},
	{
		garage_id: 199,
		part_id: 355,
		quantity: 237,
	},
	{
		garage_id: 199,
		part_id: 356,
		quantity: 67,
	},
	{
		garage_id: 199,
		part_id: 357,
		quantity: 491,
	},
	{
		garage_id: 199,
		part_id: 358,
		quantity: 46,
	},
	{
		garage_id: 199,
		part_id: 359,
		quantity: 383,
	},
	{
		garage_id: 199,
		part_id: 360,
		quantity: 323,
	},
	{
		garage_id: 199,
		part_id: 361,
		quantity: 189,
	},
	{
		garage_id: 199,
		part_id: 362,
		quantity: 420,
	},
	{
		garage_id: 199,
		part_id: 363,
		quantity: 51,
	},
	{
		garage_id: 199,
		part_id: 364,
		quantity: 279,
	},
	{
		garage_id: 199,
		part_id: 365,
		quantity: 30,
	},
	{
		garage_id: 199,
		part_id: 366,
		quantity: 467,
	},
	{
		garage_id: 199,
		part_id: 367,
		quantity: 385,
	},
	{
		garage_id: 199,
		part_id: 368,
		quantity: 295,
	},
	{
		garage_id: 199,
		part_id: 369,
		quantity: 473,
	},
	{
		garage_id: 199,
		part_id: 370,
		quantity: 446,
	},
	{
		garage_id: 199,
		part_id: 371,
		quantity: 430,
	},
	{
		garage_id: 199,
		part_id: 372,
		quantity: 388,
	},
	{
		garage_id: 199,
		part_id: 373,
		quantity: 260,
	},
	{
		garage_id: 199,
		part_id: 374,
		quantity: 416,
	},
	{
		garage_id: 199,
		part_id: 375,
		quantity: 425,
	},
	{
		garage_id: 199,
		part_id: 376,
		quantity: 200,
	},
	{
		garage_id: 199,
		part_id: 377,
		quantity: 133,
	},
	{
		garage_id: 199,
		part_id: 378,
		quantity: 176,
	},
	{
		garage_id: 199,
		part_id: 379,
		quantity: 379,
	},
	{
		garage_id: 199,
		part_id: 380,
		quantity: 311,
	},
	{
		garage_id: 199,
		part_id: 381,
		quantity: 0,
	},
	{
		garage_id: 199,
		part_id: 382,
		quantity: 151,
	},
	{
		garage_id: 199,
		part_id: 383,
		quantity: 50,
	},
	{
		garage_id: 199,
		part_id: 384,
		quantity: 60,
	},
	{
		garage_id: 199,
		part_id: 385,
		quantity: 34,
	},
	{
		garage_id: 199,
		part_id: 386,
		quantity: 135,
	},
	{
		garage_id: 199,
		part_id: 387,
		quantity: 113,
	},
	{
		garage_id: 199,
		part_id: 388,
		quantity: 49,
	},
	{
		garage_id: 199,
		part_id: 389,
		quantity: 316,
	},
	{
		garage_id: 199,
		part_id: 390,
		quantity: 310,
	},
	{
		garage_id: 199,
		part_id: 391,
		quantity: 335,
	},
	{
		garage_id: 199,
		part_id: 392,
		quantity: 283,
	},
	{
		garage_id: 199,
		part_id: 393,
		quantity: 26,
	},
	{
		garage_id: 199,
		part_id: 394,
		quantity: 468,
	},
	{
		garage_id: 199,
		part_id: 395,
		quantity: 302,
	},
	{
		garage_id: 199,
		part_id: 396,
		quantity: 443,
	},
	{
		garage_id: 199,
		part_id: 397,
		quantity: 286,
	},
	{
		garage_id: 199,
		part_id: 398,
		quantity: 128,
	},
	{
		garage_id: 199,
		part_id: 399,
		quantity: 432,
	},
	{
		garage_id: 200,
		part_id: 0,
		quantity: 419,
	},
	{
		garage_id: 200,
		part_id: 1,
		quantity: 216,
	},
	{
		garage_id: 200,
		part_id: 2,
		quantity: 334,
	},
	{
		garage_id: 200,
		part_id: 3,
		quantity: 236,
	},
	{
		garage_id: 200,
		part_id: 4,
		quantity: 291,
	},
	{
		garage_id: 200,
		part_id: 5,
		quantity: 451,
	},
	{
		garage_id: 200,
		part_id: 6,
		quantity: 257,
	},
	{
		garage_id: 200,
		part_id: 7,
		quantity: 405,
	},
	{
		garage_id: 200,
		part_id: 8,
		quantity: 345,
	},
	{
		garage_id: 200,
		part_id: 9,
		quantity: 411,
	},
	{
		garage_id: 200,
		part_id: 10,
		quantity: 1,
	},
	{
		garage_id: 200,
		part_id: 11,
		quantity: 394,
	},
	{
		garage_id: 200,
		part_id: 12,
		quantity: 305,
	},
	{
		garage_id: 200,
		part_id: 13,
		quantity: 53,
	},
	{
		garage_id: 200,
		part_id: 14,
		quantity: 200,
	},
	{
		garage_id: 200,
		part_id: 15,
		quantity: 336,
	},
	{
		garage_id: 200,
		part_id: 16,
		quantity: 396,
	},
	{
		garage_id: 200,
		part_id: 17,
		quantity: 467,
	},
	{
		garage_id: 200,
		part_id: 18,
		quantity: 75,
	},
	{
		garage_id: 200,
		part_id: 19,
		quantity: 393,
	},
	{
		garage_id: 200,
		part_id: 20,
		quantity: 15,
	},
	{
		garage_id: 200,
		part_id: 21,
		quantity: 74,
	},
	{
		garage_id: 200,
		part_id: 22,
		quantity: 362,
	},
	{
		garage_id: 200,
		part_id: 23,
		quantity: 110,
	},
	{
		garage_id: 200,
		part_id: 24,
		quantity: 55,
	},
	{
		garage_id: 200,
		part_id: 25,
		quantity: 103,
	},
	{
		garage_id: 200,
		part_id: 26,
		quantity: 139,
	},
	{
		garage_id: 200,
		part_id: 27,
		quantity: 368,
	},
	{
		garage_id: 200,
		part_id: 28,
		quantity: 474,
	},
	{
		garage_id: 200,
		part_id: 29,
		quantity: 350,
	},
	{
		garage_id: 200,
		part_id: 30,
		quantity: 5,
	},
	{
		garage_id: 200,
		part_id: 31,
		quantity: 93,
	},
	{
		garage_id: 200,
		part_id: 32,
		quantity: 492,
	},
	{
		garage_id: 200,
		part_id: 33,
		quantity: 135,
	},
	{
		garage_id: 200,
		part_id: 34,
		quantity: 425,
	},
	{
		garage_id: 200,
		part_id: 35,
		quantity: 309,
	},
	{
		garage_id: 200,
		part_id: 36,
		quantity: 128,
	},
	{
		garage_id: 200,
		part_id: 37,
		quantity: 27,
	},
	{
		garage_id: 200,
		part_id: 38,
		quantity: 1,
	},
	{
		garage_id: 200,
		part_id: 39,
		quantity: 368,
	},
	{
		garage_id: 200,
		part_id: 40,
		quantity: 327,
	},
	{
		garage_id: 200,
		part_id: 41,
		quantity: 324,
	},
	{
		garage_id: 200,
		part_id: 42,
		quantity: 302,
	},
	{
		garage_id: 200,
		part_id: 43,
		quantity: 53,
	},
	{
		garage_id: 200,
		part_id: 44,
		quantity: 107,
	},
	{
		garage_id: 200,
		part_id: 45,
		quantity: 354,
	},
	{
		garage_id: 200,
		part_id: 46,
		quantity: 357,
	},
	{
		garage_id: 200,
		part_id: 47,
		quantity: 469,
	},
	{
		garage_id: 200,
		part_id: 48,
		quantity: 322,
	},
	{
		garage_id: 200,
		part_id: 49,
		quantity: 4,
	},
	{
		garage_id: 200,
		part_id: 50,
		quantity: 305,
	},
	{
		garage_id: 200,
		part_id: 51,
		quantity: 331,
	},
	{
		garage_id: 200,
		part_id: 52,
		quantity: 404,
	},
	{
		garage_id: 200,
		part_id: 53,
		quantity: 80,
	},
	{
		garage_id: 200,
		part_id: 54,
		quantity: 178,
	},
	{
		garage_id: 200,
		part_id: 55,
		quantity: 417,
	},
	{
		garage_id: 200,
		part_id: 56,
		quantity: 152,
	},
	{
		garage_id: 200,
		part_id: 57,
		quantity: 95,
	},
	{
		garage_id: 200,
		part_id: 58,
		quantity: 49,
	},
	{
		garage_id: 200,
		part_id: 59,
		quantity: 396,
	},
	{
		garage_id: 200,
		part_id: 60,
		quantity: 381,
	},
	{
		garage_id: 200,
		part_id: 61,
		quantity: 73,
	},
	{
		garage_id: 200,
		part_id: 62,
		quantity: 485,
	},
	{
		garage_id: 200,
		part_id: 63,
		quantity: 1,
	},
	{
		garage_id: 200,
		part_id: 64,
		quantity: 97,
	},
	{
		garage_id: 200,
		part_id: 65,
		quantity: 82,
	},
	{
		garage_id: 200,
		part_id: 66,
		quantity: 228,
	},
	{
		garage_id: 200,
		part_id: 67,
		quantity: 307,
	},
	{
		garage_id: 200,
		part_id: 68,
		quantity: 282,
	},
	{
		garage_id: 200,
		part_id: 69,
		quantity: 1,
	},
	{
		garage_id: 200,
		part_id: 70,
		quantity: 116,
	},
	{
		garage_id: 200,
		part_id: 71,
		quantity: 422,
	},
	{
		garage_id: 200,
		part_id: 72,
		quantity: 239,
	},
	{
		garage_id: 200,
		part_id: 73,
		quantity: 258,
	},
	{
		garage_id: 200,
		part_id: 74,
		quantity: 308,
	},
	{
		garage_id: 200,
		part_id: 75,
		quantity: 55,
	},
	{
		garage_id: 200,
		part_id: 76,
		quantity: 253,
	},
	{
		garage_id: 200,
		part_id: 77,
		quantity: 378,
	},
	{
		garage_id: 200,
		part_id: 78,
		quantity: 333,
	},
	{
		garage_id: 200,
		part_id: 79,
		quantity: 360,
	},
	{
		garage_id: 200,
		part_id: 80,
		quantity: 413,
	},
	{
		garage_id: 200,
		part_id: 81,
		quantity: 151,
	},
	{
		garage_id: 200,
		part_id: 82,
		quantity: 268,
	},
	{
		garage_id: 200,
		part_id: 83,
		quantity: 496,
	},
	{
		garage_id: 200,
		part_id: 84,
		quantity: 425,
	},
	{
		garage_id: 200,
		part_id: 85,
		quantity: 163,
	},
	{
		garage_id: 200,
		part_id: 86,
		quantity: 208,
	},
	{
		garage_id: 200,
		part_id: 87,
		quantity: 71,
	},
	{
		garage_id: 200,
		part_id: 88,
		quantity: 463,
	},
	{
		garage_id: 200,
		part_id: 89,
		quantity: 118,
	},
	{
		garage_id: 200,
		part_id: 90,
		quantity: 138,
	},
	{
		garage_id: 200,
		part_id: 91,
		quantity: 33,
	},
	{
		garage_id: 200,
		part_id: 92,
		quantity: 445,
	},
	{
		garage_id: 200,
		part_id: 93,
		quantity: 303,
	},
	{
		garage_id: 200,
		part_id: 94,
		quantity: 339,
	},
	{
		garage_id: 200,
		part_id: 95,
		quantity: 481,
	},
	{
		garage_id: 200,
		part_id: 96,
		quantity: 283,
	},
	{
		garage_id: 200,
		part_id: 97,
		quantity: 108,
	},
	{
		garage_id: 200,
		part_id: 98,
		quantity: 169,
	},
	{
		garage_id: 200,
		part_id: 99,
		quantity: 34,
	},
	{
		garage_id: 200,
		part_id: 100,
		quantity: 344,
	},
	{
		garage_id: 200,
		part_id: 101,
		quantity: 96,
	},
	{
		garage_id: 200,
		part_id: 102,
		quantity: 52,
	},
	{
		garage_id: 200,
		part_id: 103,
		quantity: 79,
	},
	{
		garage_id: 200,
		part_id: 104,
		quantity: 349,
	},
	{
		garage_id: 200,
		part_id: 105,
		quantity: 379,
	},
	{
		garage_id: 200,
		part_id: 106,
		quantity: 19,
	},
	{
		garage_id: 200,
		part_id: 107,
		quantity: 3,
	},
	{
		garage_id: 200,
		part_id: 108,
		quantity: 499,
	},
	{
		garage_id: 200,
		part_id: 109,
		quantity: 335,
	},
	{
		garage_id: 200,
		part_id: 110,
		quantity: 6,
	},
	{
		garage_id: 200,
		part_id: 111,
		quantity: 261,
	},
	{
		garage_id: 200,
		part_id: 112,
		quantity: 148,
	},
	{
		garage_id: 200,
		part_id: 113,
		quantity: 98,
	},
	{
		garage_id: 200,
		part_id: 114,
		quantity: 129,
	},
	{
		garage_id: 200,
		part_id: 115,
		quantity: 454,
	},
	{
		garage_id: 200,
		part_id: 116,
		quantity: 103,
	},
	{
		garage_id: 200,
		part_id: 117,
		quantity: 235,
	},
	{
		garage_id: 200,
		part_id: 118,
		quantity: 304,
	},
	{
		garage_id: 200,
		part_id: 119,
		quantity: 323,
	},
	{
		garage_id: 200,
		part_id: 120,
		quantity: 239,
	},
	{
		garage_id: 200,
		part_id: 121,
		quantity: 470,
	},
	{
		garage_id: 200,
		part_id: 122,
		quantity: 398,
	},
	{
		garage_id: 200,
		part_id: 123,
		quantity: 483,
	},
	{
		garage_id: 200,
		part_id: 124,
		quantity: 154,
	},
	{
		garage_id: 200,
		part_id: 125,
		quantity: 243,
	},
	{
		garage_id: 200,
		part_id: 126,
		quantity: 187,
	},
	{
		garage_id: 200,
		part_id: 127,
		quantity: 381,
	},
	{
		garage_id: 200,
		part_id: 128,
		quantity: 452,
	},
	{
		garage_id: 200,
		part_id: 129,
		quantity: 235,
	},
	{
		garage_id: 200,
		part_id: 130,
		quantity: 33,
	},
	{
		garage_id: 200,
		part_id: 131,
		quantity: 134,
	},
	{
		garage_id: 200,
		part_id: 132,
		quantity: 192,
	},
	{
		garage_id: 200,
		part_id: 133,
		quantity: 38,
	},
	{
		garage_id: 200,
		part_id: 134,
		quantity: 275,
	},
	{
		garage_id: 200,
		part_id: 135,
		quantity: 361,
	},
	{
		garage_id: 200,
		part_id: 136,
		quantity: 85,
	},
	{
		garage_id: 200,
		part_id: 137,
		quantity: 345,
	},
	{
		garage_id: 200,
		part_id: 138,
		quantity: 407,
	},
	{
		garage_id: 200,
		part_id: 139,
		quantity: 413,
	},
	{
		garage_id: 200,
		part_id: 140,
		quantity: 143,
	},
	{
		garage_id: 200,
		part_id: 141,
		quantity: 368,
	},
	{
		garage_id: 200,
		part_id: 142,
		quantity: 388,
	},
	{
		garage_id: 200,
		part_id: 143,
		quantity: 376,
	},
	{
		garage_id: 200,
		part_id: 144,
		quantity: 389,
	},
	{
		garage_id: 200,
		part_id: 145,
		quantity: 56,
	},
	{
		garage_id: 200,
		part_id: 146,
		quantity: 269,
	},
	{
		garage_id: 200,
		part_id: 147,
		quantity: 8,
	},
	{
		garage_id: 200,
		part_id: 148,
		quantity: 0,
	},
	{
		garage_id: 200,
		part_id: 149,
		quantity: 95,
	},
	{
		garage_id: 200,
		part_id: 150,
		quantity: 266,
	},
	{
		garage_id: 200,
		part_id: 151,
		quantity: 70,
	},
	{
		garage_id: 200,
		part_id: 152,
		quantity: 385,
	},
	{
		garage_id: 200,
		part_id: 153,
		quantity: 139,
	},
	{
		garage_id: 200,
		part_id: 154,
		quantity: 77,
	},
	{
		garage_id: 200,
		part_id: 155,
		quantity: 124,
	},
	{
		garage_id: 200,
		part_id: 156,
		quantity: 497,
	},
	{
		garage_id: 200,
		part_id: 157,
		quantity: 163,
	},
	{
		garage_id: 200,
		part_id: 158,
		quantity: 377,
	},
	{
		garage_id: 200,
		part_id: 159,
		quantity: 12,
	},
	{
		garage_id: 200,
		part_id: 160,
		quantity: 88,
	},
	{
		garage_id: 200,
		part_id: 161,
		quantity: 453,
	},
	{
		garage_id: 200,
		part_id: 162,
		quantity: 331,
	},
	{
		garage_id: 200,
		part_id: 163,
		quantity: 431,
	},
	{
		garage_id: 200,
		part_id: 164,
		quantity: 385,
	},
	{
		garage_id: 200,
		part_id: 165,
		quantity: 102,
	},
	{
		garage_id: 200,
		part_id: 166,
		quantity: 64,
	},
	{
		garage_id: 200,
		part_id: 167,
		quantity: 407,
	},
	{
		garage_id: 200,
		part_id: 168,
		quantity: 337,
	},
	{
		garage_id: 200,
		part_id: 169,
		quantity: 449,
	},
	{
		garage_id: 200,
		part_id: 170,
		quantity: 231,
	},
	{
		garage_id: 200,
		part_id: 171,
		quantity: 237,
	},
	{
		garage_id: 200,
		part_id: 172,
		quantity: 360,
	},
	{
		garage_id: 200,
		part_id: 173,
		quantity: 458,
	},
	{
		garage_id: 200,
		part_id: 174,
		quantity: 399,
	},
	{
		garage_id: 200,
		part_id: 175,
		quantity: 64,
	},
	{
		garage_id: 200,
		part_id: 176,
		quantity: 428,
	},
	{
		garage_id: 200,
		part_id: 177,
		quantity: 182,
	},
	{
		garage_id: 200,
		part_id: 178,
		quantity: 182,
	},
	{
		garage_id: 200,
		part_id: 179,
		quantity: 171,
	},
	{
		garage_id: 200,
		part_id: 180,
		quantity: 59,
	},
	{
		garage_id: 200,
		part_id: 181,
		quantity: 74,
	},
	{
		garage_id: 200,
		part_id: 182,
		quantity: 465,
	},
	{
		garage_id: 200,
		part_id: 183,
		quantity: 201,
	},
	{
		garage_id: 200,
		part_id: 184,
		quantity: 361,
	},
	{
		garage_id: 200,
		part_id: 185,
		quantity: 102,
	},
	{
		garage_id: 200,
		part_id: 186,
		quantity: 226,
	},
	{
		garage_id: 200,
		part_id: 187,
		quantity: 439,
	},
	{
		garage_id: 200,
		part_id: 188,
		quantity: 366,
	},
	{
		garage_id: 200,
		part_id: 189,
		quantity: 493,
	},
	{
		garage_id: 200,
		part_id: 190,
		quantity: 216,
	},
	{
		garage_id: 200,
		part_id: 191,
		quantity: 181,
	},
	{
		garage_id: 200,
		part_id: 192,
		quantity: 468,
	},
	{
		garage_id: 200,
		part_id: 193,
		quantity: 285,
	},
	{
		garage_id: 200,
		part_id: 194,
		quantity: 492,
	},
	{
		garage_id: 200,
		part_id: 195,
		quantity: 369,
	},
	{
		garage_id: 200,
		part_id: 196,
		quantity: 418,
	},
	{
		garage_id: 200,
		part_id: 197,
		quantity: 94,
	},
	{
		garage_id: 200,
		part_id: 198,
		quantity: 475,
	},
	{
		garage_id: 200,
		part_id: 199,
		quantity: 427,
	},
	{
		garage_id: 200,
		part_id: 200,
		quantity: 370,
	},
	{
		garage_id: 200,
		part_id: 201,
		quantity: 231,
	},
	{
		garage_id: 200,
		part_id: 202,
		quantity: 131,
	},
	{
		garage_id: 200,
		part_id: 203,
		quantity: 27,
	},
	{
		garage_id: 200,
		part_id: 204,
		quantity: 481,
	},
	{
		garage_id: 200,
		part_id: 205,
		quantity: 340,
	},
	{
		garage_id: 200,
		part_id: 206,
		quantity: 219,
	},
	{
		garage_id: 200,
		part_id: 207,
		quantity: 293,
	},
	{
		garage_id: 200,
		part_id: 208,
		quantity: 373,
	},
	{
		garage_id: 200,
		part_id: 209,
		quantity: 146,
	},
	{
		garage_id: 200,
		part_id: 210,
		quantity: 110,
	},
	{
		garage_id: 200,
		part_id: 211,
		quantity: 176,
	},
	{
		garage_id: 200,
		part_id: 212,
		quantity: 233,
	},
	{
		garage_id: 200,
		part_id: 213,
		quantity: 293,
	},
	{
		garage_id: 200,
		part_id: 214,
		quantity: 170,
	},
	{
		garage_id: 200,
		part_id: 215,
		quantity: 386,
	},
	{
		garage_id: 200,
		part_id: 216,
		quantity: 123,
	},
	{
		garage_id: 200,
		part_id: 217,
		quantity: 457,
	},
	{
		garage_id: 200,
		part_id: 218,
		quantity: 218,
	},
	{
		garage_id: 200,
		part_id: 219,
		quantity: 94,
	},
	{
		garage_id: 200,
		part_id: 220,
		quantity: 129,
	},
	{
		garage_id: 200,
		part_id: 221,
		quantity: 231,
	},
	{
		garage_id: 200,
		part_id: 222,
		quantity: 307,
	},
	{
		garage_id: 200,
		part_id: 223,
		quantity: 134,
	},
	{
		garage_id: 200,
		part_id: 224,
		quantity: 297,
	},
	{
		garage_id: 200,
		part_id: 225,
		quantity: 321,
	},
	{
		garage_id: 200,
		part_id: 226,
		quantity: 170,
	},
	{
		garage_id: 200,
		part_id: 227,
		quantity: 270,
	},
	{
		garage_id: 200,
		part_id: 228,
		quantity: 460,
	},
	{
		garage_id: 200,
		part_id: 229,
		quantity: 8,
	},
	{
		garage_id: 200,
		part_id: 230,
		quantity: 340,
	},
	{
		garage_id: 200,
		part_id: 231,
		quantity: 342,
	},
	{
		garage_id: 200,
		part_id: 232,
		quantity: 266,
	},
	{
		garage_id: 200,
		part_id: 233,
		quantity: 43,
	},
	{
		garage_id: 200,
		part_id: 234,
		quantity: 308,
	},
	{
		garage_id: 200,
		part_id: 235,
		quantity: 156,
	},
	{
		garage_id: 200,
		part_id: 236,
		quantity: 398,
	},
	{
		garage_id: 200,
		part_id: 237,
		quantity: 146,
	},
	{
		garage_id: 200,
		part_id: 238,
		quantity: 231,
	},
	{
		garage_id: 200,
		part_id: 239,
		quantity: 179,
	},
	{
		garage_id: 200,
		part_id: 240,
		quantity: 252,
	},
	{
		garage_id: 200,
		part_id: 241,
		quantity: 193,
	},
	{
		garage_id: 200,
		part_id: 242,
		quantity: 372,
	},
	{
		garage_id: 200,
		part_id: 243,
		quantity: 191,
	},
	{
		garage_id: 200,
		part_id: 244,
		quantity: 16,
	},
	{
		garage_id: 200,
		part_id: 245,
		quantity: 47,
	},
	{
		garage_id: 200,
		part_id: 246,
		quantity: 278,
	},
	{
		garage_id: 200,
		part_id: 247,
		quantity: 443,
	},
	{
		garage_id: 200,
		part_id: 248,
		quantity: 10,
	},
	{
		garage_id: 200,
		part_id: 249,
		quantity: 477,
	},
	{
		garage_id: 200,
		part_id: 250,
		quantity: 382,
	},
	{
		garage_id: 200,
		part_id: 251,
		quantity: 263,
	},
	{
		garage_id: 200,
		part_id: 252,
		quantity: 499,
	},
	{
		garage_id: 200,
		part_id: 253,
		quantity: 145,
	},
	{
		garage_id: 200,
		part_id: 254,
		quantity: 243,
	},
	{
		garage_id: 200,
		part_id: 255,
		quantity: 110,
	},
	{
		garage_id: 200,
		part_id: 256,
		quantity: 287,
	},
	{
		garage_id: 200,
		part_id: 257,
		quantity: 410,
	},
	{
		garage_id: 200,
		part_id: 258,
		quantity: 238,
	},
	{
		garage_id: 200,
		part_id: 259,
		quantity: 29,
	},
	{
		garage_id: 200,
		part_id: 260,
		quantity: 352,
	},
	{
		garage_id: 200,
		part_id: 261,
		quantity: 406,
	},
	{
		garage_id: 200,
		part_id: 262,
		quantity: 428,
	},
	{
		garage_id: 200,
		part_id: 263,
		quantity: 294,
	},
	{
		garage_id: 200,
		part_id: 264,
		quantity: 280,
	},
	{
		garage_id: 200,
		part_id: 265,
		quantity: 139,
	},
	{
		garage_id: 200,
		part_id: 266,
		quantity: 268,
	},
	{
		garage_id: 200,
		part_id: 267,
		quantity: 109,
	},
	{
		garage_id: 200,
		part_id: 268,
		quantity: 400,
	},
	{
		garage_id: 200,
		part_id: 269,
		quantity: 162,
	},
	{
		garage_id: 200,
		part_id: 270,
		quantity: 281,
	},
	{
		garage_id: 200,
		part_id: 271,
		quantity: 137,
	},
	{
		garage_id: 200,
		part_id: 272,
		quantity: 43,
	},
	{
		garage_id: 200,
		part_id: 273,
		quantity: 87,
	},
	{
		garage_id: 200,
		part_id: 274,
		quantity: 402,
	},
	{
		garage_id: 200,
		part_id: 275,
		quantity: 280,
	},
	{
		garage_id: 200,
		part_id: 276,
		quantity: 142,
	},
	{
		garage_id: 200,
		part_id: 277,
		quantity: 27,
	},
	{
		garage_id: 200,
		part_id: 278,
		quantity: 303,
	},
	{
		garage_id: 200,
		part_id: 279,
		quantity: 487,
	},
	{
		garage_id: 200,
		part_id: 280,
		quantity: 260,
	},
	{
		garage_id: 200,
		part_id: 281,
		quantity: 34,
	},
	{
		garage_id: 200,
		part_id: 282,
		quantity: 460,
	},
	{
		garage_id: 200,
		part_id: 283,
		quantity: 319,
	},
	{
		garage_id: 200,
		part_id: 284,
		quantity: 320,
	},
	{
		garage_id: 200,
		part_id: 285,
		quantity: 140,
	},
	{
		garage_id: 200,
		part_id: 286,
		quantity: 145,
	},
	{
		garage_id: 200,
		part_id: 287,
		quantity: 142,
	},
	{
		garage_id: 200,
		part_id: 288,
		quantity: 154,
	},
	{
		garage_id: 200,
		part_id: 289,
		quantity: 427,
	},
	{
		garage_id: 200,
		part_id: 290,
		quantity: 241,
	},
	{
		garage_id: 200,
		part_id: 291,
		quantity: 76,
	},
	{
		garage_id: 200,
		part_id: 292,
		quantity: 66,
	},
	{
		garage_id: 200,
		part_id: 293,
		quantity: 452,
	},
	{
		garage_id: 200,
		part_id: 294,
		quantity: 347,
	},
	{
		garage_id: 200,
		part_id: 295,
		quantity: 392,
	},
	{
		garage_id: 200,
		part_id: 296,
		quantity: 447,
	},
	{
		garage_id: 200,
		part_id: 297,
		quantity: 85,
	},
	{
		garage_id: 200,
		part_id: 298,
		quantity: 313,
	},
	{
		garage_id: 200,
		part_id: 299,
		quantity: 192,
	},
	{
		garage_id: 200,
		part_id: 300,
		quantity: 375,
	},
	{
		garage_id: 200,
		part_id: 301,
		quantity: 131,
	},
	{
		garage_id: 200,
		part_id: 302,
		quantity: 286,
	},
	{
		garage_id: 200,
		part_id: 303,
		quantity: 436,
	},
	{
		garage_id: 200,
		part_id: 304,
		quantity: 263,
	},
	{
		garage_id: 200,
		part_id: 305,
		quantity: 282,
	},
	{
		garage_id: 200,
		part_id: 306,
		quantity: 97,
	},
	{
		garage_id: 200,
		part_id: 307,
		quantity: 328,
	},
	{
		garage_id: 200,
		part_id: 308,
		quantity: 438,
	},
	{
		garage_id: 200,
		part_id: 309,
		quantity: 420,
	},
	{
		garage_id: 200,
		part_id: 310,
		quantity: 468,
	},
	{
		garage_id: 200,
		part_id: 311,
		quantity: 6,
	},
	{
		garage_id: 200,
		part_id: 312,
		quantity: 39,
	},
	{
		garage_id: 200,
		part_id: 313,
		quantity: 320,
	},
	{
		garage_id: 200,
		part_id: 314,
		quantity: 104,
	},
	{
		garage_id: 200,
		part_id: 315,
		quantity: 288,
	},
	{
		garage_id: 200,
		part_id: 316,
		quantity: 99,
	},
	{
		garage_id: 200,
		part_id: 317,
		quantity: 164,
	},
	{
		garage_id: 200,
		part_id: 318,
		quantity: 399,
	},
	{
		garage_id: 200,
		part_id: 319,
		quantity: 338,
	},
	{
		garage_id: 200,
		part_id: 320,
		quantity: 423,
	},
	{
		garage_id: 200,
		part_id: 321,
		quantity: 0,
	},
	{
		garage_id: 200,
		part_id: 322,
		quantity: 114,
	},
	{
		garage_id: 200,
		part_id: 323,
		quantity: 71,
	},
	{
		garage_id: 200,
		part_id: 324,
		quantity: 471,
	},
	{
		garage_id: 200,
		part_id: 325,
		quantity: 230,
	},
	{
		garage_id: 200,
		part_id: 326,
		quantity: 398,
	},
	{
		garage_id: 200,
		part_id: 327,
		quantity: 176,
	},
	{
		garage_id: 200,
		part_id: 328,
		quantity: 101,
	},
	{
		garage_id: 200,
		part_id: 329,
		quantity: 410,
	},
	{
		garage_id: 200,
		part_id: 330,
		quantity: 492,
	},
	{
		garage_id: 200,
		part_id: 331,
		quantity: 465,
	},
	{
		garage_id: 200,
		part_id: 332,
		quantity: 382,
	},
	{
		garage_id: 200,
		part_id: 333,
		quantity: 160,
	},
	{
		garage_id: 200,
		part_id: 334,
		quantity: 31,
	},
	{
		garage_id: 200,
		part_id: 335,
		quantity: 256,
	},
	{
		garage_id: 200,
		part_id: 336,
		quantity: 75,
	},
	{
		garage_id: 200,
		part_id: 337,
		quantity: 14,
	},
	{
		garage_id: 200,
		part_id: 338,
		quantity: 87,
	},
	{
		garage_id: 200,
		part_id: 339,
		quantity: 33,
	},
	{
		garage_id: 200,
		part_id: 340,
		quantity: 455,
	},
	{
		garage_id: 200,
		part_id: 341,
		quantity: 403,
	},
	{
		garage_id: 200,
		part_id: 342,
		quantity: 153,
	},
	{
		garage_id: 200,
		part_id: 343,
		quantity: 17,
	},
	{
		garage_id: 200,
		part_id: 344,
		quantity: 313,
	},
	{
		garage_id: 200,
		part_id: 345,
		quantity: 356,
	},
	{
		garage_id: 200,
		part_id: 346,
		quantity: 403,
	},
	{
		garage_id: 200,
		part_id: 347,
		quantity: 349,
	},
	{
		garage_id: 200,
		part_id: 348,
		quantity: 215,
	},
	{
		garage_id: 200,
		part_id: 349,
		quantity: 333,
	},
	{
		garage_id: 200,
		part_id: 350,
		quantity: 182,
	},
	{
		garage_id: 200,
		part_id: 351,
		quantity: 270,
	},
	{
		garage_id: 200,
		part_id: 352,
		quantity: 140,
	},
	{
		garage_id: 200,
		part_id: 353,
		quantity: 368,
	},
	{
		garage_id: 200,
		part_id: 354,
		quantity: 175,
	},
	{
		garage_id: 200,
		part_id: 355,
		quantity: 221,
	},
	{
		garage_id: 200,
		part_id: 356,
		quantity: 144,
	},
	{
		garage_id: 200,
		part_id: 357,
		quantity: 403,
	},
	{
		garage_id: 200,
		part_id: 358,
		quantity: 328,
	},
	{
		garage_id: 200,
		part_id: 359,
		quantity: 171,
	},
	{
		garage_id: 200,
		part_id: 360,
		quantity: 418,
	},
	{
		garage_id: 200,
		part_id: 361,
		quantity: 179,
	},
	{
		garage_id: 200,
		part_id: 362,
		quantity: 44,
	},
	{
		garage_id: 200,
		part_id: 363,
		quantity: 0,
	},
	{
		garage_id: 200,
		part_id: 364,
		quantity: 414,
	},
	{
		garage_id: 200,
		part_id: 365,
		quantity: 345,
	},
	{
		garage_id: 200,
		part_id: 366,
		quantity: 122,
	},
	{
		garage_id: 200,
		part_id: 367,
		quantity: 377,
	},
	{
		garage_id: 200,
		part_id: 368,
		quantity: 500,
	},
	{
		garage_id: 200,
		part_id: 369,
		quantity: 277,
	},
	{
		garage_id: 200,
		part_id: 370,
		quantity: 28,
	},
	{
		garage_id: 200,
		part_id: 371,
		quantity: 419,
	},
	{
		garage_id: 200,
		part_id: 372,
		quantity: 466,
	},
	{
		garage_id: 200,
		part_id: 373,
		quantity: 235,
	},
	{
		garage_id: 200,
		part_id: 374,
		quantity: 341,
	},
	{
		garage_id: 200,
		part_id: 375,
		quantity: 97,
	},
	{
		garage_id: 200,
		part_id: 376,
		quantity: 446,
	},
	{
		garage_id: 200,
		part_id: 377,
		quantity: 225,
	},
	{
		garage_id: 200,
		part_id: 378,
		quantity: 2,
	},
	{
		garage_id: 200,
		part_id: 379,
		quantity: 84,
	},
	{
		garage_id: 200,
		part_id: 380,
		quantity: 146,
	},
	{
		garage_id: 200,
		part_id: 381,
		quantity: 242,
	},
	{
		garage_id: 200,
		part_id: 382,
		quantity: 90,
	},
	{
		garage_id: 200,
		part_id: 383,
		quantity: 255,
	},
	{
		garage_id: 200,
		part_id: 384,
		quantity: 290,
	},
	{
		garage_id: 200,
		part_id: 385,
		quantity: 130,
	},
	{
		garage_id: 200,
		part_id: 386,
		quantity: 272,
	},
	{
		garage_id: 200,
		part_id: 387,
		quantity: 182,
	},
	{
		garage_id: 200,
		part_id: 388,
		quantity: 373,
	},
	{
		garage_id: 200,
		part_id: 389,
		quantity: 307,
	},
	{
		garage_id: 200,
		part_id: 390,
		quantity: 175,
	},
	{
		garage_id: 200,
		part_id: 391,
		quantity: 213,
	},
	{
		garage_id: 200,
		part_id: 392,
		quantity: 234,
	},
	{
		garage_id: 200,
		part_id: 393,
		quantity: 196,
	},
	{
		garage_id: 200,
		part_id: 394,
		quantity: 226,
	},
	{
		garage_id: 200,
		part_id: 395,
		quantity: 210,
	},
	{
		garage_id: 200,
		part_id: 396,
		quantity: 262,
	},
	{
		garage_id: 200,
		part_id: 397,
		quantity: 496,
	},
	{
		garage_id: 200,
		part_id: 398,
		quantity: 230,
	},
	{
		garage_id: 200,
		part_id: 399,
		quantity: 96,
	},
	{
		garage_id: 201,
		part_id: 0,
		quantity: 91,
	},
	{
		garage_id: 201,
		part_id: 1,
		quantity: 75,
	},
	{
		garage_id: 201,
		part_id: 2,
		quantity: 386,
	},
	{
		garage_id: 201,
		part_id: 3,
		quantity: 129,
	},
	{
		garage_id: 201,
		part_id: 4,
		quantity: 144,
	},
	{
		garage_id: 201,
		part_id: 5,
		quantity: 246,
	},
	{
		garage_id: 201,
		part_id: 6,
		quantity: 274,
	},
	{
		garage_id: 201,
		part_id: 7,
		quantity: 259,
	},
	{
		garage_id: 201,
		part_id: 8,
		quantity: 146,
	},
	{
		garage_id: 201,
		part_id: 9,
		quantity: 136,
	},
	{
		garage_id: 201,
		part_id: 10,
		quantity: 222,
	},
	{
		garage_id: 201,
		part_id: 11,
		quantity: 496,
	},
	{
		garage_id: 201,
		part_id: 12,
		quantity: 289,
	},
	{
		garage_id: 201,
		part_id: 13,
		quantity: 499,
	},
	{
		garage_id: 201,
		part_id: 14,
		quantity: 202,
	},
	{
		garage_id: 201,
		part_id: 15,
		quantity: 406,
	},
	{
		garage_id: 201,
		part_id: 16,
		quantity: 249,
	},
	{
		garage_id: 201,
		part_id: 17,
		quantity: 114,
	},
	{
		garage_id: 201,
		part_id: 18,
		quantity: 407,
	},
	{
		garage_id: 201,
		part_id: 19,
		quantity: 11,
	},
	{
		garage_id: 201,
		part_id: 20,
		quantity: 459,
	},
	{
		garage_id: 201,
		part_id: 21,
		quantity: 336,
	},
	{
		garage_id: 201,
		part_id: 22,
		quantity: 323,
	},
	{
		garage_id: 201,
		part_id: 23,
		quantity: 377,
	},
	{
		garage_id: 201,
		part_id: 24,
		quantity: 276,
	},
	{
		garage_id: 201,
		part_id: 25,
		quantity: 407,
	},
	{
		garage_id: 201,
		part_id: 26,
		quantity: 95,
	},
	{
		garage_id: 201,
		part_id: 27,
		quantity: 29,
	},
	{
		garage_id: 201,
		part_id: 28,
		quantity: 109,
	},
	{
		garage_id: 201,
		part_id: 29,
		quantity: 340,
	},
	{
		garage_id: 201,
		part_id: 30,
		quantity: 360,
	},
	{
		garage_id: 201,
		part_id: 31,
		quantity: 54,
	},
	{
		garage_id: 201,
		part_id: 32,
		quantity: 97,
	},
	{
		garage_id: 201,
		part_id: 33,
		quantity: 356,
	},
	{
		garage_id: 201,
		part_id: 34,
		quantity: 436,
	},
	{
		garage_id: 201,
		part_id: 35,
		quantity: 23,
	},
	{
		garage_id: 201,
		part_id: 36,
		quantity: 210,
	},
	{
		garage_id: 201,
		part_id: 37,
		quantity: 474,
	},
	{
		garage_id: 201,
		part_id: 38,
		quantity: 145,
	},
	{
		garage_id: 201,
		part_id: 39,
		quantity: 468,
	},
	{
		garage_id: 201,
		part_id: 40,
		quantity: 351,
	},
	{
		garage_id: 201,
		part_id: 41,
		quantity: 451,
	},
	{
		garage_id: 201,
		part_id: 42,
		quantity: 125,
	},
	{
		garage_id: 201,
		part_id: 43,
		quantity: 147,
	},
	{
		garage_id: 201,
		part_id: 44,
		quantity: 67,
	},
	{
		garage_id: 201,
		part_id: 45,
		quantity: 252,
	},
	{
		garage_id: 201,
		part_id: 46,
		quantity: 64,
	},
	{
		garage_id: 201,
		part_id: 47,
		quantity: 488,
	},
	{
		garage_id: 201,
		part_id: 48,
		quantity: 427,
	},
	{
		garage_id: 201,
		part_id: 49,
		quantity: 367,
	},
	{
		garage_id: 201,
		part_id: 50,
		quantity: 336,
	},
	{
		garage_id: 201,
		part_id: 51,
		quantity: 84,
	},
	{
		garage_id: 201,
		part_id: 52,
		quantity: 344,
	},
	{
		garage_id: 201,
		part_id: 53,
		quantity: 420,
	},
	{
		garage_id: 201,
		part_id: 54,
		quantity: 112,
	},
	{
		garage_id: 201,
		part_id: 55,
		quantity: 432,
	},
	{
		garage_id: 201,
		part_id: 56,
		quantity: 414,
	},
	{
		garage_id: 201,
		part_id: 57,
		quantity: 177,
	},
	{
		garage_id: 201,
		part_id: 58,
		quantity: 320,
	},
	{
		garage_id: 201,
		part_id: 59,
		quantity: 169,
	},
	{
		garage_id: 201,
		part_id: 60,
		quantity: 371,
	},
	{
		garage_id: 201,
		part_id: 61,
		quantity: 214,
	},
	{
		garage_id: 201,
		part_id: 62,
		quantity: 490,
	},
	{
		garage_id: 201,
		part_id: 63,
		quantity: 379,
	},
	{
		garage_id: 201,
		part_id: 64,
		quantity: 383,
	},
	{
		garage_id: 201,
		part_id: 65,
		quantity: 445,
	},
	{
		garage_id: 201,
		part_id: 66,
		quantity: 150,
	},
	{
		garage_id: 201,
		part_id: 67,
		quantity: 207,
	},
	{
		garage_id: 201,
		part_id: 68,
		quantity: 500,
	},
	{
		garage_id: 201,
		part_id: 69,
		quantity: 13,
	},
	{
		garage_id: 201,
		part_id: 70,
		quantity: 167,
	},
	{
		garage_id: 201,
		part_id: 71,
		quantity: 61,
	},
	{
		garage_id: 201,
		part_id: 72,
		quantity: 391,
	},
	{
		garage_id: 201,
		part_id: 73,
		quantity: 480,
	},
	{
		garage_id: 201,
		part_id: 74,
		quantity: 27,
	},
	{
		garage_id: 201,
		part_id: 75,
		quantity: 421,
	},
	{
		garage_id: 201,
		part_id: 76,
		quantity: 256,
	},
	{
		garage_id: 201,
		part_id: 77,
		quantity: 150,
	},
	{
		garage_id: 201,
		part_id: 78,
		quantity: 163,
	},
	{
		garage_id: 201,
		part_id: 79,
		quantity: 123,
	},
	{
		garage_id: 201,
		part_id: 80,
		quantity: 479,
	},
	{
		garage_id: 201,
		part_id: 81,
		quantity: 236,
	},
	{
		garage_id: 201,
		part_id: 82,
		quantity: 214,
	},
	{
		garage_id: 201,
		part_id: 83,
		quantity: 462,
	},
	{
		garage_id: 201,
		part_id: 84,
		quantity: 169,
	},
	{
		garage_id: 201,
		part_id: 85,
		quantity: 180,
	},
	{
		garage_id: 201,
		part_id: 86,
		quantity: 127,
	},
	{
		garage_id: 201,
		part_id: 87,
		quantity: 316,
	},
	{
		garage_id: 201,
		part_id: 88,
		quantity: 89,
	},
	{
		garage_id: 201,
		part_id: 89,
		quantity: 241,
	},
	{
		garage_id: 201,
		part_id: 90,
		quantity: 187,
	},
	{
		garage_id: 201,
		part_id: 91,
		quantity: 113,
	},
	{
		garage_id: 201,
		part_id: 92,
		quantity: 491,
	},
	{
		garage_id: 201,
		part_id: 93,
		quantity: 323,
	},
	{
		garage_id: 201,
		part_id: 94,
		quantity: 251,
	},
	{
		garage_id: 201,
		part_id: 95,
		quantity: 183,
	},
	{
		garage_id: 201,
		part_id: 96,
		quantity: 351,
	},
	{
		garage_id: 201,
		part_id: 97,
		quantity: 355,
	},
	{
		garage_id: 201,
		part_id: 98,
		quantity: 35,
	},
	{
		garage_id: 201,
		part_id: 99,
		quantity: 422,
	},
	{
		garage_id: 201,
		part_id: 100,
		quantity: 175,
	},
	{
		garage_id: 201,
		part_id: 101,
		quantity: 140,
	},
	{
		garage_id: 201,
		part_id: 102,
		quantity: 202,
	},
	{
		garage_id: 201,
		part_id: 103,
		quantity: 478,
	},
	{
		garage_id: 201,
		part_id: 104,
		quantity: 100,
	},
	{
		garage_id: 201,
		part_id: 105,
		quantity: 373,
	},
	{
		garage_id: 201,
		part_id: 106,
		quantity: 453,
	},
	{
		garage_id: 201,
		part_id: 107,
		quantity: 278,
	},
	{
		garage_id: 201,
		part_id: 108,
		quantity: 342,
	},
	{
		garage_id: 201,
		part_id: 109,
		quantity: 443,
	},
	{
		garage_id: 201,
		part_id: 110,
		quantity: 10,
	},
	{
		garage_id: 201,
		part_id: 111,
		quantity: 99,
	},
	{
		garage_id: 201,
		part_id: 112,
		quantity: 376,
	},
	{
		garage_id: 201,
		part_id: 113,
		quantity: 236,
	},
	{
		garage_id: 201,
		part_id: 114,
		quantity: 100,
	},
	{
		garage_id: 201,
		part_id: 115,
		quantity: 338,
	},
	{
		garage_id: 201,
		part_id: 116,
		quantity: 127,
	},
	{
		garage_id: 201,
		part_id: 117,
		quantity: 70,
	},
	{
		garage_id: 201,
		part_id: 118,
		quantity: 375,
	},
	{
		garage_id: 201,
		part_id: 119,
		quantity: 281,
	},
	{
		garage_id: 201,
		part_id: 120,
		quantity: 406,
	},
	{
		garage_id: 201,
		part_id: 121,
		quantity: 264,
	},
	{
		garage_id: 201,
		part_id: 122,
		quantity: 10,
	},
	{
		garage_id: 201,
		part_id: 123,
		quantity: 3,
	},
	{
		garage_id: 201,
		part_id: 124,
		quantity: 115,
	},
	{
		garage_id: 201,
		part_id: 125,
		quantity: 116,
	},
	{
		garage_id: 201,
		part_id: 126,
		quantity: 388,
	},
	{
		garage_id: 201,
		part_id: 127,
		quantity: 82,
	},
	{
		garage_id: 201,
		part_id: 128,
		quantity: 246,
	},
	{
		garage_id: 201,
		part_id: 129,
		quantity: 343,
	},
	{
		garage_id: 201,
		part_id: 130,
		quantity: 138,
	},
	{
		garage_id: 201,
		part_id: 131,
		quantity: 371,
	},
	{
		garage_id: 201,
		part_id: 132,
		quantity: 473,
	},
	{
		garage_id: 201,
		part_id: 133,
		quantity: 68,
	},
	{
		garage_id: 201,
		part_id: 134,
		quantity: 49,
	},
	{
		garage_id: 201,
		part_id: 135,
		quantity: 413,
	},
	{
		garage_id: 201,
		part_id: 136,
		quantity: 285,
	},
	{
		garage_id: 201,
		part_id: 137,
		quantity: 10,
	},
	{
		garage_id: 201,
		part_id: 138,
		quantity: 9,
	},
	{
		garage_id: 201,
		part_id: 139,
		quantity: 308,
	},
	{
		garage_id: 201,
		part_id: 140,
		quantity: 17,
	},
	{
		garage_id: 201,
		part_id: 141,
		quantity: 389,
	},
	{
		garage_id: 201,
		part_id: 142,
		quantity: 473,
	},
	{
		garage_id: 201,
		part_id: 143,
		quantity: 269,
	},
	{
		garage_id: 201,
		part_id: 144,
		quantity: 14,
	},
	{
		garage_id: 201,
		part_id: 145,
		quantity: 140,
	},
	{
		garage_id: 201,
		part_id: 146,
		quantity: 100,
	},
	{
		garage_id: 201,
		part_id: 147,
		quantity: 161,
	},
	{
		garage_id: 201,
		part_id: 148,
		quantity: 403,
	},
	{
		garage_id: 201,
		part_id: 149,
		quantity: 43,
	},
	{
		garage_id: 201,
		part_id: 150,
		quantity: 202,
	},
	{
		garage_id: 201,
		part_id: 151,
		quantity: 478,
	},
	{
		garage_id: 201,
		part_id: 152,
		quantity: 308,
	},
	{
		garage_id: 201,
		part_id: 153,
		quantity: 170,
	},
	{
		garage_id: 201,
		part_id: 154,
		quantity: 491,
	},
	{
		garage_id: 201,
		part_id: 155,
		quantity: 449,
	},
	{
		garage_id: 201,
		part_id: 156,
		quantity: 69,
	},
	{
		garage_id: 201,
		part_id: 157,
		quantity: 402,
	},
	{
		garage_id: 201,
		part_id: 158,
		quantity: 330,
	},
	{
		garage_id: 201,
		part_id: 159,
		quantity: 67,
	},
	{
		garage_id: 201,
		part_id: 160,
		quantity: 247,
	},
	{
		garage_id: 201,
		part_id: 161,
		quantity: 233,
	},
	{
		garage_id: 201,
		part_id: 162,
		quantity: 283,
	},
	{
		garage_id: 201,
		part_id: 163,
		quantity: 295,
	},
	{
		garage_id: 201,
		part_id: 164,
		quantity: 437,
	},
	{
		garage_id: 201,
		part_id: 165,
		quantity: 259,
	},
	{
		garage_id: 201,
		part_id: 166,
		quantity: 422,
	},
	{
		garage_id: 201,
		part_id: 167,
		quantity: 276,
	},
	{
		garage_id: 201,
		part_id: 168,
		quantity: 358,
	},
	{
		garage_id: 201,
		part_id: 169,
		quantity: 8,
	},
	{
		garage_id: 201,
		part_id: 170,
		quantity: 18,
	},
	{
		garage_id: 201,
		part_id: 171,
		quantity: 488,
	},
	{
		garage_id: 201,
		part_id: 172,
		quantity: 260,
	},
	{
		garage_id: 201,
		part_id: 173,
		quantity: 177,
	},
	{
		garage_id: 201,
		part_id: 174,
		quantity: 348,
	},
	{
		garage_id: 201,
		part_id: 175,
		quantity: 304,
	},
	{
		garage_id: 201,
		part_id: 176,
		quantity: 21,
	},
	{
		garage_id: 201,
		part_id: 177,
		quantity: 296,
	},
	{
		garage_id: 201,
		part_id: 178,
		quantity: 477,
	},
	{
		garage_id: 201,
		part_id: 179,
		quantity: 297,
	},
	{
		garage_id: 201,
		part_id: 180,
		quantity: 118,
	},
	{
		garage_id: 201,
		part_id: 181,
		quantity: 387,
	},
	{
		garage_id: 201,
		part_id: 182,
		quantity: 455,
	},
	{
		garage_id: 201,
		part_id: 183,
		quantity: 93,
	},
	{
		garage_id: 201,
		part_id: 184,
		quantity: 67,
	},
	{
		garage_id: 201,
		part_id: 185,
		quantity: 124,
	},
	{
		garage_id: 201,
		part_id: 186,
		quantity: 259,
	},
	{
		garage_id: 201,
		part_id: 187,
		quantity: 481,
	},
	{
		garage_id: 201,
		part_id: 188,
		quantity: 333,
	},
	{
		garage_id: 201,
		part_id: 189,
		quantity: 123,
	},
	{
		garage_id: 201,
		part_id: 190,
		quantity: 30,
	},
	{
		garage_id: 201,
		part_id: 191,
		quantity: 208,
	},
	{
		garage_id: 201,
		part_id: 192,
		quantity: 353,
	},
	{
		garage_id: 201,
		part_id: 193,
		quantity: 294,
	},
	{
		garage_id: 201,
		part_id: 194,
		quantity: 392,
	},
	{
		garage_id: 201,
		part_id: 195,
		quantity: 491,
	},
	{
		garage_id: 201,
		part_id: 196,
		quantity: 166,
	},
	{
		garage_id: 201,
		part_id: 197,
		quantity: 191,
	},
	{
		garage_id: 201,
		part_id: 198,
		quantity: 454,
	},
	{
		garage_id: 201,
		part_id: 199,
		quantity: 388,
	},
	{
		garage_id: 201,
		part_id: 200,
		quantity: 125,
	},
	{
		garage_id: 201,
		part_id: 201,
		quantity: 373,
	},
	{
		garage_id: 201,
		part_id: 202,
		quantity: 48,
	},
	{
		garage_id: 201,
		part_id: 203,
		quantity: 228,
	},
	{
		garage_id: 201,
		part_id: 204,
		quantity: 36,
	},
	{
		garage_id: 201,
		part_id: 205,
		quantity: 416,
	},
	{
		garage_id: 201,
		part_id: 206,
		quantity: 139,
	},
	{
		garage_id: 201,
		part_id: 207,
		quantity: 311,
	},
	{
		garage_id: 201,
		part_id: 208,
		quantity: 242,
	},
	{
		garage_id: 201,
		part_id: 209,
		quantity: 406,
	},
	{
		garage_id: 201,
		part_id: 210,
		quantity: 398,
	},
	{
		garage_id: 201,
		part_id: 211,
		quantity: 201,
	},
	{
		garage_id: 201,
		part_id: 212,
		quantity: 197,
	},
	{
		garage_id: 201,
		part_id: 213,
		quantity: 299,
	},
	{
		garage_id: 201,
		part_id: 214,
		quantity: 10,
	},
	{
		garage_id: 201,
		part_id: 215,
		quantity: 97,
	},
	{
		garage_id: 201,
		part_id: 216,
		quantity: 3,
	},
	{
		garage_id: 201,
		part_id: 217,
		quantity: 129,
	},
	{
		garage_id: 201,
		part_id: 218,
		quantity: 56,
	},
	{
		garage_id: 201,
		part_id: 219,
		quantity: 33,
	},
	{
		garage_id: 201,
		part_id: 220,
		quantity: 399,
	},
	{
		garage_id: 201,
		part_id: 221,
		quantity: 65,
	},
	{
		garage_id: 201,
		part_id: 222,
		quantity: 79,
	},
	{
		garage_id: 201,
		part_id: 223,
		quantity: 216,
	},
	{
		garage_id: 201,
		part_id: 224,
		quantity: 395,
	},
	{
		garage_id: 201,
		part_id: 225,
		quantity: 125,
	},
	{
		garage_id: 201,
		part_id: 226,
		quantity: 217,
	},
	{
		garage_id: 201,
		part_id: 227,
		quantity: 85,
	},
	{
		garage_id: 201,
		part_id: 228,
		quantity: 437,
	},
	{
		garage_id: 201,
		part_id: 229,
		quantity: 91,
	},
	{
		garage_id: 201,
		part_id: 230,
		quantity: 455,
	},
	{
		garage_id: 201,
		part_id: 231,
		quantity: 288,
	},
	{
		garage_id: 201,
		part_id: 232,
		quantity: 210,
	},
	{
		garage_id: 201,
		part_id: 233,
		quantity: 206,
	},
	{
		garage_id: 201,
		part_id: 234,
		quantity: 375,
	},
	{
		garage_id: 201,
		part_id: 235,
		quantity: 228,
	},
	{
		garage_id: 201,
		part_id: 236,
		quantity: 445,
	},
	{
		garage_id: 201,
		part_id: 237,
		quantity: 98,
	},
	{
		garage_id: 201,
		part_id: 238,
		quantity: 275,
	},
	{
		garage_id: 201,
		part_id: 239,
		quantity: 371,
	},
	{
		garage_id: 201,
		part_id: 240,
		quantity: 130,
	},
	{
		garage_id: 201,
		part_id: 241,
		quantity: 270,
	},
	{
		garage_id: 201,
		part_id: 242,
		quantity: 379,
	},
	{
		garage_id: 201,
		part_id: 243,
		quantity: 250,
	},
	{
		garage_id: 201,
		part_id: 244,
		quantity: 207,
	},
	{
		garage_id: 201,
		part_id: 245,
		quantity: 348,
	},
	{
		garage_id: 201,
		part_id: 246,
		quantity: 67,
	},
	{
		garage_id: 201,
		part_id: 247,
		quantity: 58,
	},
	{
		garage_id: 201,
		part_id: 248,
		quantity: 125,
	},
	{
		garage_id: 201,
		part_id: 249,
		quantity: 253,
	},
	{
		garage_id: 201,
		part_id: 250,
		quantity: 276,
	},
	{
		garage_id: 201,
		part_id: 251,
		quantity: 392,
	},
	{
		garage_id: 201,
		part_id: 252,
		quantity: 248,
	},
	{
		garage_id: 201,
		part_id: 253,
		quantity: 72,
	},
	{
		garage_id: 201,
		part_id: 254,
		quantity: 241,
	},
	{
		garage_id: 201,
		part_id: 255,
		quantity: 152,
	},
	{
		garage_id: 201,
		part_id: 256,
		quantity: 466,
	},
	{
		garage_id: 201,
		part_id: 257,
		quantity: 493,
	},
	{
		garage_id: 201,
		part_id: 258,
		quantity: 411,
	},
	{
		garage_id: 201,
		part_id: 259,
		quantity: 3,
	},
	{
		garage_id: 201,
		part_id: 260,
		quantity: 297,
	},
	{
		garage_id: 201,
		part_id: 261,
		quantity: 104,
	},
	{
		garage_id: 201,
		part_id: 262,
		quantity: 92,
	},
	{
		garage_id: 201,
		part_id: 263,
		quantity: 198,
	},
	{
		garage_id: 201,
		part_id: 264,
		quantity: 59,
	},
	{
		garage_id: 201,
		part_id: 265,
		quantity: 50,
	},
	{
		garage_id: 201,
		part_id: 266,
		quantity: 41,
	},
	{
		garage_id: 201,
		part_id: 267,
		quantity: 107,
	},
	{
		garage_id: 201,
		part_id: 268,
		quantity: 405,
	},
	{
		garage_id: 201,
		part_id: 269,
		quantity: 440,
	},
	{
		garage_id: 201,
		part_id: 270,
		quantity: 89,
	},
	{
		garage_id: 201,
		part_id: 271,
		quantity: 246,
	},
	{
		garage_id: 201,
		part_id: 272,
		quantity: 285,
	},
	{
		garage_id: 201,
		part_id: 273,
		quantity: 353,
	},
	{
		garage_id: 201,
		part_id: 274,
		quantity: 160,
	},
	{
		garage_id: 201,
		part_id: 275,
		quantity: 485,
	},
	{
		garage_id: 201,
		part_id: 276,
		quantity: 190,
	},
	{
		garage_id: 201,
		part_id: 277,
		quantity: 66,
	},
	{
		garage_id: 201,
		part_id: 278,
		quantity: 302,
	},
	{
		garage_id: 201,
		part_id: 279,
		quantity: 355,
	},
	{
		garage_id: 201,
		part_id: 280,
		quantity: 378,
	},
	{
		garage_id: 201,
		part_id: 281,
		quantity: 414,
	},
	{
		garage_id: 201,
		part_id: 282,
		quantity: 464,
	},
	{
		garage_id: 201,
		part_id: 283,
		quantity: 458,
	},
	{
		garage_id: 201,
		part_id: 284,
		quantity: 50,
	},
	{
		garage_id: 201,
		part_id: 285,
		quantity: 69,
	},
	{
		garage_id: 201,
		part_id: 286,
		quantity: 124,
	},
	{
		garage_id: 201,
		part_id: 287,
		quantity: 217,
	},
	{
		garage_id: 201,
		part_id: 288,
		quantity: 309,
	},
	{
		garage_id: 201,
		part_id: 289,
		quantity: 408,
	},
	{
		garage_id: 201,
		part_id: 290,
		quantity: 90,
	},
	{
		garage_id: 201,
		part_id: 291,
		quantity: 251,
	},
	{
		garage_id: 201,
		part_id: 292,
		quantity: 372,
	},
	{
		garage_id: 201,
		part_id: 293,
		quantity: 271,
	},
	{
		garage_id: 201,
		part_id: 294,
		quantity: 396,
	},
	{
		garage_id: 201,
		part_id: 295,
		quantity: 309,
	},
	{
		garage_id: 201,
		part_id: 296,
		quantity: 191,
	},
	{
		garage_id: 201,
		part_id: 297,
		quantity: 204,
	},
	{
		garage_id: 201,
		part_id: 298,
		quantity: 448,
	},
	{
		garage_id: 201,
		part_id: 299,
		quantity: 264,
	},
	{
		garage_id: 201,
		part_id: 300,
		quantity: 71,
	},
	{
		garage_id: 201,
		part_id: 301,
		quantity: 373,
	},
	{
		garage_id: 201,
		part_id: 302,
		quantity: 225,
	},
	{
		garage_id: 201,
		part_id: 303,
		quantity: 431,
	},
	{
		garage_id: 201,
		part_id: 304,
		quantity: 121,
	},
	{
		garage_id: 201,
		part_id: 305,
		quantity: 138,
	},
	{
		garage_id: 201,
		part_id: 306,
		quantity: 189,
	},
	{
		garage_id: 201,
		part_id: 307,
		quantity: 374,
	},
	{
		garage_id: 201,
		part_id: 308,
		quantity: 414,
	},
	{
		garage_id: 201,
		part_id: 309,
		quantity: 277,
	},
	{
		garage_id: 201,
		part_id: 310,
		quantity: 202,
	},
	{
		garage_id: 201,
		part_id: 311,
		quantity: 308,
	},
	{
		garage_id: 201,
		part_id: 312,
		quantity: 73,
	},
	{
		garage_id: 201,
		part_id: 313,
		quantity: 313,
	},
	{
		garage_id: 201,
		part_id: 314,
		quantity: 256,
	},
	{
		garage_id: 201,
		part_id: 315,
		quantity: 184,
	},
	{
		garage_id: 201,
		part_id: 316,
		quantity: 467,
	},
	{
		garage_id: 201,
		part_id: 317,
		quantity: 114,
	},
	{
		garage_id: 201,
		part_id: 318,
		quantity: 62,
	},
	{
		garage_id: 201,
		part_id: 319,
		quantity: 39,
	},
	{
		garage_id: 201,
		part_id: 320,
		quantity: 474,
	},
	{
		garage_id: 201,
		part_id: 321,
		quantity: 487,
	},
	{
		garage_id: 201,
		part_id: 322,
		quantity: 32,
	},
	{
		garage_id: 201,
		part_id: 323,
		quantity: 282,
	},
	{
		garage_id: 201,
		part_id: 324,
		quantity: 386,
	},
	{
		garage_id: 201,
		part_id: 325,
		quantity: 489,
	},
	{
		garage_id: 201,
		part_id: 326,
		quantity: 8,
	},
	{
		garage_id: 201,
		part_id: 327,
		quantity: 45,
	},
	{
		garage_id: 201,
		part_id: 328,
		quantity: 254,
	},
	{
		garage_id: 201,
		part_id: 329,
		quantity: 236,
	},
	{
		garage_id: 201,
		part_id: 330,
		quantity: 266,
	},
	{
		garage_id: 201,
		part_id: 331,
		quantity: 449,
	},
	{
		garage_id: 201,
		part_id: 332,
		quantity: 389,
	},
	{
		garage_id: 201,
		part_id: 333,
		quantity: 259,
	},
	{
		garage_id: 201,
		part_id: 334,
		quantity: 162,
	},
	{
		garage_id: 201,
		part_id: 335,
		quantity: 345,
	},
	{
		garage_id: 201,
		part_id: 336,
		quantity: 155,
	},
	{
		garage_id: 201,
		part_id: 337,
		quantity: 55,
	},
	{
		garage_id: 201,
		part_id: 338,
		quantity: 2,
	},
	{
		garage_id: 201,
		part_id: 339,
		quantity: 190,
	},
	{
		garage_id: 201,
		part_id: 340,
		quantity: 474,
	},
	{
		garage_id: 201,
		part_id: 341,
		quantity: 473,
	},
	{
		garage_id: 201,
		part_id: 342,
		quantity: 142,
	},
	{
		garage_id: 201,
		part_id: 343,
		quantity: 172,
	},
	{
		garage_id: 201,
		part_id: 344,
		quantity: 426,
	},
	{
		garage_id: 201,
		part_id: 345,
		quantity: 39,
	},
	{
		garage_id: 201,
		part_id: 346,
		quantity: 393,
	},
	{
		garage_id: 201,
		part_id: 347,
		quantity: 415,
	},
	{
		garage_id: 201,
		part_id: 348,
		quantity: 372,
	},
	{
		garage_id: 201,
		part_id: 349,
		quantity: 205,
	},
	{
		garage_id: 201,
		part_id: 350,
		quantity: 338,
	},
	{
		garage_id: 201,
		part_id: 351,
		quantity: 51,
	},
	{
		garage_id: 201,
		part_id: 352,
		quantity: 363,
	},
	{
		garage_id: 201,
		part_id: 353,
		quantity: 4,
	},
	{
		garage_id: 201,
		part_id: 354,
		quantity: 29,
	},
	{
		garage_id: 201,
		part_id: 355,
		quantity: 309,
	},
	{
		garage_id: 201,
		part_id: 356,
		quantity: 112,
	},
	{
		garage_id: 201,
		part_id: 357,
		quantity: 42,
	},
	{
		garage_id: 201,
		part_id: 358,
		quantity: 425,
	},
	{
		garage_id: 201,
		part_id: 359,
		quantity: 253,
	},
	{
		garage_id: 201,
		part_id: 360,
		quantity: 260,
	},
	{
		garage_id: 201,
		part_id: 361,
		quantity: 171,
	},
	{
		garage_id: 201,
		part_id: 362,
		quantity: 84,
	},
	{
		garage_id: 201,
		part_id: 363,
		quantity: 231,
	},
	{
		garage_id: 201,
		part_id: 364,
		quantity: 282,
	},
	{
		garage_id: 201,
		part_id: 365,
		quantity: 290,
	},
	{
		garage_id: 201,
		part_id: 366,
		quantity: 69,
	},
	{
		garage_id: 201,
		part_id: 367,
		quantity: 18,
	},
	{
		garage_id: 201,
		part_id: 368,
		quantity: 138,
	},
	{
		garage_id: 201,
		part_id: 369,
		quantity: 300,
	},
	{
		garage_id: 201,
		part_id: 370,
		quantity: 8,
	},
	{
		garage_id: 201,
		part_id: 371,
		quantity: 184,
	},
	{
		garage_id: 201,
		part_id: 372,
		quantity: 244,
	},
	{
		garage_id: 201,
		part_id: 373,
		quantity: 156,
	},
	{
		garage_id: 201,
		part_id: 374,
		quantity: 469,
	},
	{
		garage_id: 201,
		part_id: 375,
		quantity: 349,
	},
	{
		garage_id: 201,
		part_id: 376,
		quantity: 151,
	},
	{
		garage_id: 201,
		part_id: 377,
		quantity: 416,
	},
	{
		garage_id: 201,
		part_id: 378,
		quantity: 150,
	},
	{
		garage_id: 201,
		part_id: 379,
		quantity: 400,
	},
	{
		garage_id: 201,
		part_id: 380,
		quantity: 420,
	},
	{
		garage_id: 201,
		part_id: 381,
		quantity: 270,
	},
	{
		garage_id: 201,
		part_id: 382,
		quantity: 282,
	},
	{
		garage_id: 201,
		part_id: 383,
		quantity: 270,
	},
	{
		garage_id: 201,
		part_id: 384,
		quantity: 14,
	},
	{
		garage_id: 201,
		part_id: 385,
		quantity: 318,
	},
	{
		garage_id: 201,
		part_id: 386,
		quantity: 262,
	},
	{
		garage_id: 201,
		part_id: 387,
		quantity: 3,
	},
	{
		garage_id: 201,
		part_id: 388,
		quantity: 92,
	},
	{
		garage_id: 201,
		part_id: 389,
		quantity: 303,
	},
	{
		garage_id: 201,
		part_id: 390,
		quantity: 125,
	},
	{
		garage_id: 201,
		part_id: 391,
		quantity: 94,
	},
	{
		garage_id: 201,
		part_id: 392,
		quantity: 393,
	},
	{
		garage_id: 201,
		part_id: 393,
		quantity: 8,
	},
	{
		garage_id: 201,
		part_id: 394,
		quantity: 166,
	},
	{
		garage_id: 201,
		part_id: 395,
		quantity: 39,
	},
	{
		garage_id: 201,
		part_id: 396,
		quantity: 250,
	},
	{
		garage_id: 201,
		part_id: 397,
		quantity: 215,
	},
	{
		garage_id: 201,
		part_id: 398,
		quantity: 86,
	},
	{
		garage_id: 201,
		part_id: 399,
		quantity: 483,
	},
	{
		garage_id: 202,
		part_id: 0,
		quantity: 301,
	},
	{
		garage_id: 202,
		part_id: 1,
		quantity: 443,
	},
	{
		garage_id: 202,
		part_id: 2,
		quantity: 76,
	},
	{
		garage_id: 202,
		part_id: 3,
		quantity: 371,
	},
	{
		garage_id: 202,
		part_id: 4,
		quantity: 390,
	},
	{
		garage_id: 202,
		part_id: 5,
		quantity: 254,
	},
	{
		garage_id: 202,
		part_id: 6,
		quantity: 273,
	},
	{
		garage_id: 202,
		part_id: 7,
		quantity: 125,
	},
	{
		garage_id: 202,
		part_id: 8,
		quantity: 1,
	},
	{
		garage_id: 202,
		part_id: 9,
		quantity: 460,
	},
	{
		garage_id: 202,
		part_id: 10,
		quantity: 330,
	},
	{
		garage_id: 202,
		part_id: 11,
		quantity: 150,
	},
	{
		garage_id: 202,
		part_id: 12,
		quantity: 130,
	},
	{
		garage_id: 202,
		part_id: 13,
		quantity: 407,
	},
	{
		garage_id: 202,
		part_id: 14,
		quantity: 256,
	},
	{
		garage_id: 202,
		part_id: 15,
		quantity: 287,
	},
	{
		garage_id: 202,
		part_id: 16,
		quantity: 13,
	},
	{
		garage_id: 202,
		part_id: 17,
		quantity: 435,
	},
	{
		garage_id: 202,
		part_id: 18,
		quantity: 126,
	},
	{
		garage_id: 202,
		part_id: 19,
		quantity: 196,
	},
	{
		garage_id: 202,
		part_id: 20,
		quantity: 183,
	},
	{
		garage_id: 202,
		part_id: 21,
		quantity: 134,
	},
	{
		garage_id: 202,
		part_id: 22,
		quantity: 160,
	},
	{
		garage_id: 202,
		part_id: 23,
		quantity: 402,
	},
	{
		garage_id: 202,
		part_id: 24,
		quantity: 492,
	},
	{
		garage_id: 202,
		part_id: 25,
		quantity: 370,
	},
	{
		garage_id: 202,
		part_id: 26,
		quantity: 376,
	},
	{
		garage_id: 202,
		part_id: 27,
		quantity: 260,
	},
	{
		garage_id: 202,
		part_id: 28,
		quantity: 257,
	},
	{
		garage_id: 202,
		part_id: 29,
		quantity: 323,
	},
	{
		garage_id: 202,
		part_id: 30,
		quantity: 337,
	},
	{
		garage_id: 202,
		part_id: 31,
		quantity: 407,
	},
	{
		garage_id: 202,
		part_id: 32,
		quantity: 384,
	},
	{
		garage_id: 202,
		part_id: 33,
		quantity: 23,
	},
	{
		garage_id: 202,
		part_id: 34,
		quantity: 411,
	},
	{
		garage_id: 202,
		part_id: 35,
		quantity: 75,
	},
	{
		garage_id: 202,
		part_id: 36,
		quantity: 126,
	},
	{
		garage_id: 202,
		part_id: 37,
		quantity: 411,
	},
	{
		garage_id: 202,
		part_id: 38,
		quantity: 401,
	},
	{
		garage_id: 202,
		part_id: 39,
		quantity: 192,
	},
	{
		garage_id: 202,
		part_id: 40,
		quantity: 339,
	},
	{
		garage_id: 202,
		part_id: 41,
		quantity: 4,
	},
	{
		garage_id: 202,
		part_id: 42,
		quantity: 324,
	},
	{
		garage_id: 202,
		part_id: 43,
		quantity: 187,
	},
	{
		garage_id: 202,
		part_id: 44,
		quantity: 396,
	},
	{
		garage_id: 202,
		part_id: 45,
		quantity: 89,
	},
	{
		garage_id: 202,
		part_id: 46,
		quantity: 361,
	},
	{
		garage_id: 202,
		part_id: 47,
		quantity: 160,
	},
	{
		garage_id: 202,
		part_id: 48,
		quantity: 447,
	},
	{
		garage_id: 202,
		part_id: 49,
		quantity: 230,
	},
	{
		garage_id: 202,
		part_id: 50,
		quantity: 282,
	},
	{
		garage_id: 202,
		part_id: 51,
		quantity: 260,
	},
	{
		garage_id: 202,
		part_id: 52,
		quantity: 252,
	},
	{
		garage_id: 202,
		part_id: 53,
		quantity: 193,
	},
	{
		garage_id: 202,
		part_id: 54,
		quantity: 237,
	},
	{
		garage_id: 202,
		part_id: 55,
		quantity: 205,
	},
	{
		garage_id: 202,
		part_id: 56,
		quantity: 5,
	},
	{
		garage_id: 202,
		part_id: 57,
		quantity: 93,
	},
	{
		garage_id: 202,
		part_id: 58,
		quantity: 466,
	},
	{
		garage_id: 202,
		part_id: 59,
		quantity: 238,
	},
	{
		garage_id: 202,
		part_id: 60,
		quantity: 381,
	},
	{
		garage_id: 202,
		part_id: 61,
		quantity: 466,
	},
	{
		garage_id: 202,
		part_id: 62,
		quantity: 362,
	},
	{
		garage_id: 202,
		part_id: 63,
		quantity: 120,
	},
	{
		garage_id: 202,
		part_id: 64,
		quantity: 47,
	},
	{
		garage_id: 202,
		part_id: 65,
		quantity: 291,
	},
	{
		garage_id: 202,
		part_id: 66,
		quantity: 314,
	},
	{
		garage_id: 202,
		part_id: 67,
		quantity: 56,
	},
	{
		garage_id: 202,
		part_id: 68,
		quantity: 203,
	},
	{
		garage_id: 202,
		part_id: 69,
		quantity: 413,
	},
	{
		garage_id: 202,
		part_id: 70,
		quantity: 144,
	},
	{
		garage_id: 202,
		part_id: 71,
		quantity: 344,
	},
	{
		garage_id: 202,
		part_id: 72,
		quantity: 349,
	},
	{
		garage_id: 202,
		part_id: 73,
		quantity: 156,
	},
	{
		garage_id: 202,
		part_id: 74,
		quantity: 149,
	},
	{
		garage_id: 202,
		part_id: 75,
		quantity: 247,
	},
	{
		garage_id: 202,
		part_id: 76,
		quantity: 77,
	},
	{
		garage_id: 202,
		part_id: 77,
		quantity: 149,
	},
	{
		garage_id: 202,
		part_id: 78,
		quantity: 435,
	},
	{
		garage_id: 202,
		part_id: 79,
		quantity: 352,
	},
	{
		garage_id: 202,
		part_id: 80,
		quantity: 346,
	},
	{
		garage_id: 202,
		part_id: 81,
		quantity: 136,
	},
	{
		garage_id: 202,
		part_id: 82,
		quantity: 425,
	},
	{
		garage_id: 202,
		part_id: 83,
		quantity: 95,
	},
	{
		garage_id: 202,
		part_id: 84,
		quantity: 24,
	},
	{
		garage_id: 202,
		part_id: 85,
		quantity: 97,
	},
	{
		garage_id: 202,
		part_id: 86,
		quantity: 181,
	},
	{
		garage_id: 202,
		part_id: 87,
		quantity: 486,
	},
	{
		garage_id: 202,
		part_id: 88,
		quantity: 354,
	},
	{
		garage_id: 202,
		part_id: 89,
		quantity: 10,
	},
	{
		garage_id: 202,
		part_id: 90,
		quantity: 81,
	},
	{
		garage_id: 202,
		part_id: 91,
		quantity: 49,
	},
	{
		garage_id: 202,
		part_id: 92,
		quantity: 458,
	},
	{
		garage_id: 202,
		part_id: 93,
		quantity: 308,
	},
	{
		garage_id: 202,
		part_id: 94,
		quantity: 101,
	},
	{
		garage_id: 202,
		part_id: 95,
		quantity: 61,
	},
	{
		garage_id: 202,
		part_id: 96,
		quantity: 61,
	},
	{
		garage_id: 202,
		part_id: 97,
		quantity: 238,
	},
	{
		garage_id: 202,
		part_id: 98,
		quantity: 134,
	},
	{
		garage_id: 202,
		part_id: 99,
		quantity: 82,
	},
	{
		garage_id: 202,
		part_id: 100,
		quantity: 459,
	},
	{
		garage_id: 202,
		part_id: 101,
		quantity: 375,
	},
	{
		garage_id: 202,
		part_id: 102,
		quantity: 110,
	},
	{
		garage_id: 202,
		part_id: 103,
		quantity: 229,
	},
	{
		garage_id: 202,
		part_id: 104,
		quantity: 6,
	},
	{
		garage_id: 202,
		part_id: 105,
		quantity: 358,
	},
	{
		garage_id: 202,
		part_id: 106,
		quantity: 148,
	},
	{
		garage_id: 202,
		part_id: 107,
		quantity: 390,
	},
	{
		garage_id: 202,
		part_id: 108,
		quantity: 376,
	},
	{
		garage_id: 202,
		part_id: 109,
		quantity: 479,
	},
	{
		garage_id: 202,
		part_id: 110,
		quantity: 5,
	},
	{
		garage_id: 202,
		part_id: 111,
		quantity: 19,
	},
	{
		garage_id: 202,
		part_id: 112,
		quantity: 388,
	},
	{
		garage_id: 202,
		part_id: 113,
		quantity: 302,
	},
	{
		garage_id: 202,
		part_id: 114,
		quantity: 495,
	},
	{
		garage_id: 202,
		part_id: 115,
		quantity: 365,
	},
	{
		garage_id: 202,
		part_id: 116,
		quantity: 111,
	},
	{
		garage_id: 202,
		part_id: 117,
		quantity: 10,
	},
	{
		garage_id: 202,
		part_id: 118,
		quantity: 353,
	},
	{
		garage_id: 202,
		part_id: 119,
		quantity: 327,
	},
	{
		garage_id: 202,
		part_id: 120,
		quantity: 89,
	},
	{
		garage_id: 202,
		part_id: 121,
		quantity: 222,
	},
	{
		garage_id: 202,
		part_id: 122,
		quantity: 135,
	},
	{
		garage_id: 202,
		part_id: 123,
		quantity: 286,
	},
	{
		garage_id: 202,
		part_id: 124,
		quantity: 500,
	},
	{
		garage_id: 202,
		part_id: 125,
		quantity: 240,
	},
	{
		garage_id: 202,
		part_id: 126,
		quantity: 14,
	},
	{
		garage_id: 202,
		part_id: 127,
		quantity: 325,
	},
	{
		garage_id: 202,
		part_id: 128,
		quantity: 324,
	},
	{
		garage_id: 202,
		part_id: 129,
		quantity: 110,
	},
	{
		garage_id: 202,
		part_id: 130,
		quantity: 369,
	},
	{
		garage_id: 202,
		part_id: 131,
		quantity: 328,
	},
	{
		garage_id: 202,
		part_id: 132,
		quantity: 158,
	},
	{
		garage_id: 202,
		part_id: 133,
		quantity: 20,
	},
	{
		garage_id: 202,
		part_id: 134,
		quantity: 202,
	},
	{
		garage_id: 202,
		part_id: 135,
		quantity: 78,
	},
	{
		garage_id: 202,
		part_id: 136,
		quantity: 412,
	},
	{
		garage_id: 202,
		part_id: 137,
		quantity: 58,
	},
	{
		garage_id: 202,
		part_id: 138,
		quantity: 413,
	},
	{
		garage_id: 202,
		part_id: 139,
		quantity: 214,
	},
	{
		garage_id: 202,
		part_id: 140,
		quantity: 41,
	},
	{
		garage_id: 202,
		part_id: 141,
		quantity: 471,
	},
	{
		garage_id: 202,
		part_id: 142,
		quantity: 361,
	},
	{
		garage_id: 202,
		part_id: 143,
		quantity: 90,
	},
	{
		garage_id: 202,
		part_id: 144,
		quantity: 291,
	},
	{
		garage_id: 202,
		part_id: 145,
		quantity: 414,
	},
	{
		garage_id: 202,
		part_id: 146,
		quantity: 52,
	},
	{
		garage_id: 202,
		part_id: 147,
		quantity: 41,
	},
	{
		garage_id: 202,
		part_id: 148,
		quantity: 361,
	},
	{
		garage_id: 202,
		part_id: 149,
		quantity: 132,
	},
	{
		garage_id: 202,
		part_id: 150,
		quantity: 34,
	},
	{
		garage_id: 202,
		part_id: 151,
		quantity: 298,
	},
	{
		garage_id: 202,
		part_id: 152,
		quantity: 75,
	},
	{
		garage_id: 202,
		part_id: 153,
		quantity: 65,
	},
	{
		garage_id: 202,
		part_id: 154,
		quantity: 25,
	},
	{
		garage_id: 202,
		part_id: 155,
		quantity: 275,
	},
	{
		garage_id: 202,
		part_id: 156,
		quantity: 273,
	},
	{
		garage_id: 202,
		part_id: 157,
		quantity: 207,
	},
	{
		garage_id: 202,
		part_id: 158,
		quantity: 288,
	},
	{
		garage_id: 202,
		part_id: 159,
		quantity: 432,
	},
	{
		garage_id: 202,
		part_id: 160,
		quantity: 261,
	},
	{
		garage_id: 202,
		part_id: 161,
		quantity: 255,
	},
	{
		garage_id: 202,
		part_id: 162,
		quantity: 341,
	},
	{
		garage_id: 202,
		part_id: 163,
		quantity: 302,
	},
	{
		garage_id: 202,
		part_id: 164,
		quantity: 66,
	},
	{
		garage_id: 202,
		part_id: 165,
		quantity: 325,
	},
	{
		garage_id: 202,
		part_id: 166,
		quantity: 346,
	},
	{
		garage_id: 202,
		part_id: 167,
		quantity: 435,
	},
	{
		garage_id: 202,
		part_id: 168,
		quantity: 456,
	},
	{
		garage_id: 202,
		part_id: 169,
		quantity: 459,
	},
	{
		garage_id: 202,
		part_id: 170,
		quantity: 72,
	},
	{
		garage_id: 202,
		part_id: 171,
		quantity: 105,
	},
	{
		garage_id: 202,
		part_id: 172,
		quantity: 434,
	},
	{
		garage_id: 202,
		part_id: 173,
		quantity: 339,
	},
	{
		garage_id: 202,
		part_id: 174,
		quantity: 147,
	},
	{
		garage_id: 202,
		part_id: 175,
		quantity: 286,
	},
	{
		garage_id: 202,
		part_id: 176,
		quantity: 483,
	},
	{
		garage_id: 202,
		part_id: 177,
		quantity: 298,
	},
	{
		garage_id: 202,
		part_id: 178,
		quantity: 24,
	},
	{
		garage_id: 202,
		part_id: 179,
		quantity: 446,
	},
	{
		garage_id: 202,
		part_id: 180,
		quantity: 254,
	},
	{
		garage_id: 202,
		part_id: 181,
		quantity: 51,
	},
	{
		garage_id: 202,
		part_id: 182,
		quantity: 382,
	},
	{
		garage_id: 202,
		part_id: 183,
		quantity: 267,
	},
	{
		garage_id: 202,
		part_id: 184,
		quantity: 381,
	},
	{
		garage_id: 202,
		part_id: 185,
		quantity: 33,
	},
	{
		garage_id: 202,
		part_id: 186,
		quantity: 440,
	},
	{
		garage_id: 202,
		part_id: 187,
		quantity: 399,
	},
	{
		garage_id: 202,
		part_id: 188,
		quantity: 309,
	},
	{
		garage_id: 202,
		part_id: 189,
		quantity: 474,
	},
	{
		garage_id: 202,
		part_id: 190,
		quantity: 481,
	},
	{
		garage_id: 202,
		part_id: 191,
		quantity: 63,
	},
	{
		garage_id: 202,
		part_id: 192,
		quantity: 437,
	},
	{
		garage_id: 202,
		part_id: 193,
		quantity: 221,
	},
	{
		garage_id: 202,
		part_id: 194,
		quantity: 352,
	},
	{
		garage_id: 202,
		part_id: 195,
		quantity: 276,
	},
	{
		garage_id: 202,
		part_id: 196,
		quantity: 173,
	},
	{
		garage_id: 202,
		part_id: 197,
		quantity: 40,
	},
	{
		garage_id: 202,
		part_id: 198,
		quantity: 446,
	},
	{
		garage_id: 202,
		part_id: 199,
		quantity: 314,
	},
	{
		garage_id: 202,
		part_id: 200,
		quantity: 111,
	},
	{
		garage_id: 202,
		part_id: 201,
		quantity: 75,
	},
	{
		garage_id: 202,
		part_id: 202,
		quantity: 432,
	},
	{
		garage_id: 202,
		part_id: 203,
		quantity: 346,
	},
	{
		garage_id: 202,
		part_id: 204,
		quantity: 121,
	},
	{
		garage_id: 202,
		part_id: 205,
		quantity: 347,
	},
	{
		garage_id: 202,
		part_id: 206,
		quantity: 18,
	},
	{
		garage_id: 202,
		part_id: 207,
		quantity: 477,
	},
	{
		garage_id: 202,
		part_id: 208,
		quantity: 105,
	},
	{
		garage_id: 202,
		part_id: 209,
		quantity: 364,
	},
	{
		garage_id: 202,
		part_id: 210,
		quantity: 243,
	},
	{
		garage_id: 202,
		part_id: 211,
		quantity: 132,
	},
	{
		garage_id: 202,
		part_id: 212,
		quantity: 235,
	},
	{
		garage_id: 202,
		part_id: 213,
		quantity: 91,
	},
	{
		garage_id: 202,
		part_id: 214,
		quantity: 141,
	},
	{
		garage_id: 202,
		part_id: 215,
		quantity: 246,
	},
	{
		garage_id: 202,
		part_id: 216,
		quantity: 286,
	},
	{
		garage_id: 202,
		part_id: 217,
		quantity: 350,
	},
	{
		garage_id: 202,
		part_id: 218,
		quantity: 323,
	},
	{
		garage_id: 202,
		part_id: 219,
		quantity: 112,
	},
	{
		garage_id: 202,
		part_id: 220,
		quantity: 266,
	},
	{
		garage_id: 202,
		part_id: 221,
		quantity: 279,
	},
	{
		garage_id: 202,
		part_id: 222,
		quantity: 366,
	},
	{
		garage_id: 202,
		part_id: 223,
		quantity: 401,
	},
	{
		garage_id: 202,
		part_id: 224,
		quantity: 211,
	},
	{
		garage_id: 202,
		part_id: 225,
		quantity: 269,
	},
	{
		garage_id: 202,
		part_id: 226,
		quantity: 252,
	},
	{
		garage_id: 202,
		part_id: 227,
		quantity: 474,
	},
	{
		garage_id: 202,
		part_id: 228,
		quantity: 121,
	},
	{
		garage_id: 202,
		part_id: 229,
		quantity: 278,
	},
	{
		garage_id: 202,
		part_id: 230,
		quantity: 368,
	},
	{
		garage_id: 202,
		part_id: 231,
		quantity: 364,
	},
	{
		garage_id: 202,
		part_id: 232,
		quantity: 268,
	},
	{
		garage_id: 202,
		part_id: 233,
		quantity: 347,
	},
	{
		garage_id: 202,
		part_id: 234,
		quantity: 272,
	},
	{
		garage_id: 202,
		part_id: 235,
		quantity: 264,
	},
	{
		garage_id: 202,
		part_id: 236,
		quantity: 279,
	},
	{
		garage_id: 202,
		part_id: 237,
		quantity: 482,
	},
	{
		garage_id: 202,
		part_id: 238,
		quantity: 439,
	},
	{
		garage_id: 202,
		part_id: 239,
		quantity: 110,
	},
	{
		garage_id: 202,
		part_id: 240,
		quantity: 76,
	},
	{
		garage_id: 202,
		part_id: 241,
		quantity: 201,
	},
	{
		garage_id: 202,
		part_id: 242,
		quantity: 134,
	},
	{
		garage_id: 202,
		part_id: 243,
		quantity: 456,
	},
	{
		garage_id: 202,
		part_id: 244,
		quantity: 227,
	},
	{
		garage_id: 202,
		part_id: 245,
		quantity: 253,
	},
	{
		garage_id: 202,
		part_id: 246,
		quantity: 240,
	},
	{
		garage_id: 202,
		part_id: 247,
		quantity: 253,
	},
	{
		garage_id: 202,
		part_id: 248,
		quantity: 220,
	},
	{
		garage_id: 202,
		part_id: 249,
		quantity: 256,
	},
	{
		garage_id: 202,
		part_id: 250,
		quantity: 466,
	},
	{
		garage_id: 202,
		part_id: 251,
		quantity: 132,
	},
	{
		garage_id: 202,
		part_id: 252,
		quantity: 126,
	},
	{
		garage_id: 202,
		part_id: 253,
		quantity: 305,
	},
	{
		garage_id: 202,
		part_id: 254,
		quantity: 469,
	},
	{
		garage_id: 202,
		part_id: 255,
		quantity: 404,
	},
	{
		garage_id: 202,
		part_id: 256,
		quantity: 265,
	},
	{
		garage_id: 202,
		part_id: 257,
		quantity: 23,
	},
	{
		garage_id: 202,
		part_id: 258,
		quantity: 83,
	},
	{
		garage_id: 202,
		part_id: 259,
		quantity: 288,
	},
	{
		garage_id: 202,
		part_id: 260,
		quantity: 144,
	},
	{
		garage_id: 202,
		part_id: 261,
		quantity: 472,
	},
	{
		garage_id: 202,
		part_id: 262,
		quantity: 450,
	},
	{
		garage_id: 202,
		part_id: 263,
		quantity: 298,
	},
	{
		garage_id: 202,
		part_id: 264,
		quantity: 74,
	},
	{
		garage_id: 202,
		part_id: 265,
		quantity: 260,
	},
	{
		garage_id: 202,
		part_id: 266,
		quantity: 96,
	},
	{
		garage_id: 202,
		part_id: 267,
		quantity: 30,
	},
	{
		garage_id: 202,
		part_id: 268,
		quantity: 394,
	},
	{
		garage_id: 202,
		part_id: 269,
		quantity: 349,
	},
	{
		garage_id: 202,
		part_id: 270,
		quantity: 117,
	},
	{
		garage_id: 202,
		part_id: 271,
		quantity: 246,
	},
	{
		garage_id: 202,
		part_id: 272,
		quantity: 157,
	},
	{
		garage_id: 202,
		part_id: 273,
		quantity: 322,
	},
	{
		garage_id: 202,
		part_id: 274,
		quantity: 292,
	},
	{
		garage_id: 202,
		part_id: 275,
		quantity: 174,
	},
	{
		garage_id: 202,
		part_id: 276,
		quantity: 197,
	},
	{
		garage_id: 202,
		part_id: 277,
		quantity: 209,
	},
	{
		garage_id: 202,
		part_id: 278,
		quantity: 15,
	},
	{
		garage_id: 202,
		part_id: 279,
		quantity: 257,
	},
	{
		garage_id: 202,
		part_id: 280,
		quantity: 177,
	},
	{
		garage_id: 202,
		part_id: 281,
		quantity: 417,
	},
	{
		garage_id: 202,
		part_id: 282,
		quantity: 213,
	},
	{
		garage_id: 202,
		part_id: 283,
		quantity: 15,
	},
	{
		garage_id: 202,
		part_id: 284,
		quantity: 366,
	},
	{
		garage_id: 202,
		part_id: 285,
		quantity: 199,
	},
	{
		garage_id: 202,
		part_id: 286,
		quantity: 474,
	},
	{
		garage_id: 202,
		part_id: 287,
		quantity: 250,
	},
	{
		garage_id: 202,
		part_id: 288,
		quantity: 28,
	},
	{
		garage_id: 202,
		part_id: 289,
		quantity: 11,
	},
	{
		garage_id: 202,
		part_id: 290,
		quantity: 442,
	},
	{
		garage_id: 202,
		part_id: 291,
		quantity: 294,
	},
	{
		garage_id: 202,
		part_id: 292,
		quantity: 53,
	},
	{
		garage_id: 202,
		part_id: 293,
		quantity: 1,
	},
	{
		garage_id: 202,
		part_id: 294,
		quantity: 368,
	},
	{
		garage_id: 202,
		part_id: 295,
		quantity: 295,
	},
	{
		garage_id: 202,
		part_id: 296,
		quantity: 111,
	},
	{
		garage_id: 202,
		part_id: 297,
		quantity: 10,
	},
	{
		garage_id: 202,
		part_id: 298,
		quantity: 500,
	},
	{
		garage_id: 202,
		part_id: 299,
		quantity: 94,
	},
	{
		garage_id: 202,
		part_id: 300,
		quantity: 50,
	},
	{
		garage_id: 202,
		part_id: 301,
		quantity: 18,
	},
	{
		garage_id: 202,
		part_id: 302,
		quantity: 255,
	},
	{
		garage_id: 202,
		part_id: 303,
		quantity: 274,
	},
	{
		garage_id: 202,
		part_id: 304,
		quantity: 164,
	},
	{
		garage_id: 202,
		part_id: 305,
		quantity: 369,
	},
	{
		garage_id: 202,
		part_id: 306,
		quantity: 287,
	},
	{
		garage_id: 202,
		part_id: 307,
		quantity: 169,
	},
	{
		garage_id: 202,
		part_id: 308,
		quantity: 496,
	},
	{
		garage_id: 202,
		part_id: 309,
		quantity: 402,
	},
	{
		garage_id: 202,
		part_id: 310,
		quantity: 26,
	},
	{
		garage_id: 202,
		part_id: 311,
		quantity: 456,
	},
	{
		garage_id: 202,
		part_id: 312,
		quantity: 242,
	},
	{
		garage_id: 202,
		part_id: 313,
		quantity: 280,
	},
	{
		garage_id: 202,
		part_id: 314,
		quantity: 202,
	},
	{
		garage_id: 202,
		part_id: 315,
		quantity: 127,
	},
	{
		garage_id: 202,
		part_id: 316,
		quantity: 67,
	},
	{
		garage_id: 202,
		part_id: 317,
		quantity: 311,
	},
	{
		garage_id: 202,
		part_id: 318,
		quantity: 204,
	},
	{
		garage_id: 202,
		part_id: 319,
		quantity: 419,
	},
	{
		garage_id: 202,
		part_id: 320,
		quantity: 453,
	},
	{
		garage_id: 202,
		part_id: 321,
		quantity: 128,
	},
	{
		garage_id: 202,
		part_id: 322,
		quantity: 476,
	},
	{
		garage_id: 202,
		part_id: 323,
		quantity: 218,
	},
	{
		garage_id: 202,
		part_id: 324,
		quantity: 414,
	},
	{
		garage_id: 202,
		part_id: 325,
		quantity: 438,
	},
	{
		garage_id: 202,
		part_id: 326,
		quantity: 500,
	},
	{
		garage_id: 202,
		part_id: 327,
		quantity: 111,
	},
	{
		garage_id: 202,
		part_id: 328,
		quantity: 276,
	},
	{
		garage_id: 202,
		part_id: 329,
		quantity: 353,
	},
	{
		garage_id: 202,
		part_id: 330,
		quantity: 94,
	},
	{
		garage_id: 202,
		part_id: 331,
		quantity: 337,
	},
	{
		garage_id: 202,
		part_id: 332,
		quantity: 24,
	},
	{
		garage_id: 202,
		part_id: 333,
		quantity: 95,
	},
	{
		garage_id: 202,
		part_id: 334,
		quantity: 243,
	},
	{
		garage_id: 202,
		part_id: 335,
		quantity: 338,
	},
	{
		garage_id: 202,
		part_id: 336,
		quantity: 200,
	},
	{
		garage_id: 202,
		part_id: 337,
		quantity: 83,
	},
	{
		garage_id: 202,
		part_id: 338,
		quantity: 373,
	},
	{
		garage_id: 202,
		part_id: 339,
		quantity: 347,
	},
	{
		garage_id: 202,
		part_id: 340,
		quantity: 291,
	},
	{
		garage_id: 202,
		part_id: 341,
		quantity: 470,
	},
	{
		garage_id: 202,
		part_id: 342,
		quantity: 58,
	},
	{
		garage_id: 202,
		part_id: 343,
		quantity: 500,
	},
	{
		garage_id: 202,
		part_id: 344,
		quantity: 153,
	},
	{
		garage_id: 202,
		part_id: 345,
		quantity: 393,
	},
	{
		garage_id: 202,
		part_id: 346,
		quantity: 352,
	},
	{
		garage_id: 202,
		part_id: 347,
		quantity: 345,
	},
	{
		garage_id: 202,
		part_id: 348,
		quantity: 114,
	},
	{
		garage_id: 202,
		part_id: 349,
		quantity: 208,
	},
	{
		garage_id: 202,
		part_id: 350,
		quantity: 176,
	},
	{
		garage_id: 202,
		part_id: 351,
		quantity: 15,
	},
	{
		garage_id: 202,
		part_id: 352,
		quantity: 117,
	},
	{
		garage_id: 202,
		part_id: 353,
		quantity: 187,
	},
	{
		garage_id: 202,
		part_id: 354,
		quantity: 487,
	},
	{
		garage_id: 202,
		part_id: 355,
		quantity: 105,
	},
	{
		garage_id: 202,
		part_id: 356,
		quantity: 401,
	},
	{
		garage_id: 202,
		part_id: 357,
		quantity: 16,
	},
	{
		garage_id: 202,
		part_id: 358,
		quantity: 435,
	},
	{
		garage_id: 202,
		part_id: 359,
		quantity: 39,
	},
	{
		garage_id: 202,
		part_id: 360,
		quantity: 467,
	},
	{
		garage_id: 202,
		part_id: 361,
		quantity: 32,
	},
	{
		garage_id: 202,
		part_id: 362,
		quantity: 399,
	},
	{
		garage_id: 202,
		part_id: 363,
		quantity: 105,
	},
	{
		garage_id: 202,
		part_id: 364,
		quantity: 444,
	},
	{
		garage_id: 202,
		part_id: 365,
		quantity: 43,
	},
	{
		garage_id: 202,
		part_id: 366,
		quantity: 259,
	},
	{
		garage_id: 202,
		part_id: 367,
		quantity: 110,
	},
	{
		garage_id: 202,
		part_id: 368,
		quantity: 126,
	},
	{
		garage_id: 202,
		part_id: 369,
		quantity: 478,
	},
	{
		garage_id: 202,
		part_id: 370,
		quantity: 306,
	},
	{
		garage_id: 202,
		part_id: 371,
		quantity: 22,
	},
	{
		garage_id: 202,
		part_id: 372,
		quantity: 302,
	},
	{
		garage_id: 202,
		part_id: 373,
		quantity: 400,
	},
	{
		garage_id: 202,
		part_id: 374,
		quantity: 120,
	},
	{
		garage_id: 202,
		part_id: 375,
		quantity: 104,
	},
	{
		garage_id: 202,
		part_id: 376,
		quantity: 74,
	},
	{
		garage_id: 202,
		part_id: 377,
		quantity: 447,
	},
	{
		garage_id: 202,
		part_id: 378,
		quantity: 147,
	},
	{
		garage_id: 202,
		part_id: 379,
		quantity: 22,
	},
	{
		garage_id: 202,
		part_id: 380,
		quantity: 177,
	},
	{
		garage_id: 202,
		part_id: 381,
		quantity: 377,
	},
	{
		garage_id: 202,
		part_id: 382,
		quantity: 19,
	},
	{
		garage_id: 202,
		part_id: 383,
		quantity: 241,
	},
	{
		garage_id: 202,
		part_id: 384,
		quantity: 227,
	},
	{
		garage_id: 202,
		part_id: 385,
		quantity: 104,
	},
	{
		garage_id: 202,
		part_id: 386,
		quantity: 161,
	},
	{
		garage_id: 202,
		part_id: 387,
		quantity: 465,
	},
	{
		garage_id: 202,
		part_id: 388,
		quantity: 377,
	},
	{
		garage_id: 202,
		part_id: 389,
		quantity: 168,
	},
	{
		garage_id: 202,
		part_id: 390,
		quantity: 154,
	},
	{
		garage_id: 202,
		part_id: 391,
		quantity: 277,
	},
	{
		garage_id: 202,
		part_id: 392,
		quantity: 269,
	},
	{
		garage_id: 202,
		part_id: 393,
		quantity: 464,
	},
	{
		garage_id: 202,
		part_id: 394,
		quantity: 114,
	},
	{
		garage_id: 202,
		part_id: 395,
		quantity: 316,
	},
	{
		garage_id: 202,
		part_id: 396,
		quantity: 253,
	},
	{
		garage_id: 202,
		part_id: 397,
		quantity: 26,
	},
	{
		garage_id: 202,
		part_id: 398,
		quantity: 421,
	},
	{
		garage_id: 202,
		part_id: 399,
		quantity: 320,
	},
	{
		garage_id: 203,
		part_id: 0,
		quantity: 91,
	},
	{
		garage_id: 203,
		part_id: 1,
		quantity: 433,
	},
	{
		garage_id: 203,
		part_id: 2,
		quantity: 233,
	},
	{
		garage_id: 203,
		part_id: 3,
		quantity: 436,
	},
	{
		garage_id: 203,
		part_id: 4,
		quantity: 39,
	},
	{
		garage_id: 203,
		part_id: 5,
		quantity: 66,
	},
	{
		garage_id: 203,
		part_id: 6,
		quantity: 9,
	},
	{
		garage_id: 203,
		part_id: 7,
		quantity: 495,
	},
	{
		garage_id: 203,
		part_id: 8,
		quantity: 126,
	},
	{
		garage_id: 203,
		part_id: 9,
		quantity: 243,
	},
	{
		garage_id: 203,
		part_id: 10,
		quantity: 21,
	},
	{
		garage_id: 203,
		part_id: 11,
		quantity: 25,
	},
	{
		garage_id: 203,
		part_id: 12,
		quantity: 411,
	},
	{
		garage_id: 203,
		part_id: 13,
		quantity: 111,
	},
	{
		garage_id: 203,
		part_id: 14,
		quantity: 469,
	},
	{
		garage_id: 203,
		part_id: 15,
		quantity: 142,
	},
	{
		garage_id: 203,
		part_id: 16,
		quantity: 68,
	},
	{
		garage_id: 203,
		part_id: 17,
		quantity: 380,
	},
	{
		garage_id: 203,
		part_id: 18,
		quantity: 500,
	},
	{
		garage_id: 203,
		part_id: 19,
		quantity: 430,
	},
	{
		garage_id: 203,
		part_id: 20,
		quantity: 85,
	},
	{
		garage_id: 203,
		part_id: 21,
		quantity: 105,
	},
	{
		garage_id: 203,
		part_id: 22,
		quantity: 297,
	},
	{
		garage_id: 203,
		part_id: 23,
		quantity: 473,
	},
	{
		garage_id: 203,
		part_id: 24,
		quantity: 67,
	},
	{
		garage_id: 203,
		part_id: 25,
		quantity: 210,
	},
	{
		garage_id: 203,
		part_id: 26,
		quantity: 125,
	},
	{
		garage_id: 203,
		part_id: 27,
		quantity: 499,
	},
	{
		garage_id: 203,
		part_id: 28,
		quantity: 432,
	},
	{
		garage_id: 203,
		part_id: 29,
		quantity: 397,
	},
	{
		garage_id: 203,
		part_id: 30,
		quantity: 467,
	},
	{
		garage_id: 203,
		part_id: 31,
		quantity: 371,
	},
	{
		garage_id: 203,
		part_id: 32,
		quantity: 493,
	},
	{
		garage_id: 203,
		part_id: 33,
		quantity: 229,
	},
	{
		garage_id: 203,
		part_id: 34,
		quantity: 187,
	},
	{
		garage_id: 203,
		part_id: 35,
		quantity: 155,
	},
	{
		garage_id: 203,
		part_id: 36,
		quantity: 451,
	},
	{
		garage_id: 203,
		part_id: 37,
		quantity: 463,
	},
	{
		garage_id: 203,
		part_id: 38,
		quantity: 256,
	},
	{
		garage_id: 203,
		part_id: 39,
		quantity: 112,
	},
	{
		garage_id: 203,
		part_id: 40,
		quantity: 471,
	},
	{
		garage_id: 203,
		part_id: 41,
		quantity: 198,
	},
	{
		garage_id: 203,
		part_id: 42,
		quantity: 128,
	},
	{
		garage_id: 203,
		part_id: 43,
		quantity: 44,
	},
	{
		garage_id: 203,
		part_id: 44,
		quantity: 224,
	},
	{
		garage_id: 203,
		part_id: 45,
		quantity: 227,
	},
	{
		garage_id: 203,
		part_id: 46,
		quantity: 355,
	},
	{
		garage_id: 203,
		part_id: 47,
		quantity: 113,
	},
	{
		garage_id: 203,
		part_id: 48,
		quantity: 122,
	},
	{
		garage_id: 203,
		part_id: 49,
		quantity: 457,
	},
	{
		garage_id: 203,
		part_id: 50,
		quantity: 432,
	},
	{
		garage_id: 203,
		part_id: 51,
		quantity: 156,
	},
	{
		garage_id: 203,
		part_id: 52,
		quantity: 82,
	},
	{
		garage_id: 203,
		part_id: 53,
		quantity: 19,
	},
	{
		garage_id: 203,
		part_id: 54,
		quantity: 430,
	},
	{
		garage_id: 203,
		part_id: 55,
		quantity: 35,
	},
	{
		garage_id: 203,
		part_id: 56,
		quantity: 486,
	},
	{
		garage_id: 203,
		part_id: 57,
		quantity: 443,
	},
	{
		garage_id: 203,
		part_id: 58,
		quantity: 7,
	},
	{
		garage_id: 203,
		part_id: 59,
		quantity: 437,
	},
	{
		garage_id: 203,
		part_id: 60,
		quantity: 36,
	},
	{
		garage_id: 203,
		part_id: 61,
		quantity: 349,
	},
	{
		garage_id: 203,
		part_id: 62,
		quantity: 110,
	},
	{
		garage_id: 203,
		part_id: 63,
		quantity: 210,
	},
	{
		garage_id: 203,
		part_id: 64,
		quantity: 271,
	},
	{
		garage_id: 203,
		part_id: 65,
		quantity: 336,
	},
	{
		garage_id: 203,
		part_id: 66,
		quantity: 303,
	},
	{
		garage_id: 203,
		part_id: 67,
		quantity: 407,
	},
	{
		garage_id: 203,
		part_id: 68,
		quantity: 20,
	},
	{
		garage_id: 203,
		part_id: 69,
		quantity: 143,
	},
	{
		garage_id: 203,
		part_id: 70,
		quantity: 227,
	},
	{
		garage_id: 203,
		part_id: 71,
		quantity: 38,
	},
	{
		garage_id: 203,
		part_id: 72,
		quantity: 159,
	},
	{
		garage_id: 203,
		part_id: 73,
		quantity: 135,
	},
	{
		garage_id: 203,
		part_id: 74,
		quantity: 245,
	},
	{
		garage_id: 203,
		part_id: 75,
		quantity: 91,
	},
	{
		garage_id: 203,
		part_id: 76,
		quantity: 451,
	},
	{
		garage_id: 203,
		part_id: 77,
		quantity: 235,
	},
	{
		garage_id: 203,
		part_id: 78,
		quantity: 306,
	},
	{
		garage_id: 203,
		part_id: 79,
		quantity: 464,
	},
	{
		garage_id: 203,
		part_id: 80,
		quantity: 25,
	},
	{
		garage_id: 203,
		part_id: 81,
		quantity: 109,
	},
	{
		garage_id: 203,
		part_id: 82,
		quantity: 93,
	},
	{
		garage_id: 203,
		part_id: 83,
		quantity: 320,
	},
	{
		garage_id: 203,
		part_id: 84,
		quantity: 262,
	},
	{
		garage_id: 203,
		part_id: 85,
		quantity: 189,
	},
	{
		garage_id: 203,
		part_id: 86,
		quantity: 96,
	},
	{
		garage_id: 203,
		part_id: 87,
		quantity: 418,
	},
	{
		garage_id: 203,
		part_id: 88,
		quantity: 159,
	},
	{
		garage_id: 203,
		part_id: 89,
		quantity: 4,
	},
	{
		garage_id: 203,
		part_id: 90,
		quantity: 170,
	},
	{
		garage_id: 203,
		part_id: 91,
		quantity: 396,
	},
	{
		garage_id: 203,
		part_id: 92,
		quantity: 63,
	},
	{
		garage_id: 203,
		part_id: 93,
		quantity: 441,
	},
	{
		garage_id: 203,
		part_id: 94,
		quantity: 88,
	},
	{
		garage_id: 203,
		part_id: 95,
		quantity: 62,
	},
	{
		garage_id: 203,
		part_id: 96,
		quantity: 337,
	},
	{
		garage_id: 203,
		part_id: 97,
		quantity: 448,
	},
	{
		garage_id: 203,
		part_id: 98,
		quantity: 432,
	},
	{
		garage_id: 203,
		part_id: 99,
		quantity: 42,
	},
	{
		garage_id: 203,
		part_id: 100,
		quantity: 461,
	},
	{
		garage_id: 203,
		part_id: 101,
		quantity: 85,
	},
	{
		garage_id: 203,
		part_id: 102,
		quantity: 239,
	},
	{
		garage_id: 203,
		part_id: 103,
		quantity: 336,
	},
	{
		garage_id: 203,
		part_id: 104,
		quantity: 75,
	},
	{
		garage_id: 203,
		part_id: 105,
		quantity: 430,
	},
	{
		garage_id: 203,
		part_id: 106,
		quantity: 190,
	},
	{
		garage_id: 203,
		part_id: 107,
		quantity: 327,
	},
	{
		garage_id: 203,
		part_id: 108,
		quantity: 282,
	},
	{
		garage_id: 203,
		part_id: 109,
		quantity: 308,
	},
	{
		garage_id: 203,
		part_id: 110,
		quantity: 203,
	},
	{
		garage_id: 203,
		part_id: 111,
		quantity: 290,
	},
	{
		garage_id: 203,
		part_id: 112,
		quantity: 337,
	},
	{
		garage_id: 203,
		part_id: 113,
		quantity: 279,
	},
	{
		garage_id: 203,
		part_id: 114,
		quantity: 30,
	},
	{
		garage_id: 203,
		part_id: 115,
		quantity: 477,
	},
	{
		garage_id: 203,
		part_id: 116,
		quantity: 212,
	},
	{
		garage_id: 203,
		part_id: 117,
		quantity: 368,
	},
	{
		garage_id: 203,
		part_id: 118,
		quantity: 213,
	},
	{
		garage_id: 203,
		part_id: 119,
		quantity: 490,
	},
	{
		garage_id: 203,
		part_id: 120,
		quantity: 286,
	},
	{
		garage_id: 203,
		part_id: 121,
		quantity: 239,
	},
	{
		garage_id: 203,
		part_id: 122,
		quantity: 401,
	},
	{
		garage_id: 203,
		part_id: 123,
		quantity: 253,
	},
	{
		garage_id: 203,
		part_id: 124,
		quantity: 108,
	},
	{
		garage_id: 203,
		part_id: 125,
		quantity: 176,
	},
	{
		garage_id: 203,
		part_id: 126,
		quantity: 251,
	},
	{
		garage_id: 203,
		part_id: 127,
		quantity: 140,
	},
	{
		garage_id: 203,
		part_id: 128,
		quantity: 166,
	},
	{
		garage_id: 203,
		part_id: 129,
		quantity: 127,
	},
	{
		garage_id: 203,
		part_id: 130,
		quantity: 392,
	},
	{
		garage_id: 203,
		part_id: 131,
		quantity: 407,
	},
	{
		garage_id: 203,
		part_id: 132,
		quantity: 403,
	},
	{
		garage_id: 203,
		part_id: 133,
		quantity: 39,
	},
	{
		garage_id: 203,
		part_id: 134,
		quantity: 452,
	},
	{
		garage_id: 203,
		part_id: 135,
		quantity: 397,
	},
	{
		garage_id: 203,
		part_id: 136,
		quantity: 176,
	},
	{
		garage_id: 203,
		part_id: 137,
		quantity: 159,
	},
	{
		garage_id: 203,
		part_id: 138,
		quantity: 71,
	},
	{
		garage_id: 203,
		part_id: 139,
		quantity: 186,
	},
	{
		garage_id: 203,
		part_id: 140,
		quantity: 208,
	},
	{
		garage_id: 203,
		part_id: 141,
		quantity: 49,
	},
	{
		garage_id: 203,
		part_id: 142,
		quantity: 462,
	},
	{
		garage_id: 203,
		part_id: 143,
		quantity: 311,
	},
	{
		garage_id: 203,
		part_id: 144,
		quantity: 460,
	},
	{
		garage_id: 203,
		part_id: 145,
		quantity: 94,
	},
	{
		garage_id: 203,
		part_id: 146,
		quantity: 463,
	},
	{
		garage_id: 203,
		part_id: 147,
		quantity: 185,
	},
	{
		garage_id: 203,
		part_id: 148,
		quantity: 340,
	},
	{
		garage_id: 203,
		part_id: 149,
		quantity: 121,
	},
	{
		garage_id: 203,
		part_id: 150,
		quantity: 221,
	},
	{
		garage_id: 203,
		part_id: 151,
		quantity: 32,
	},
	{
		garage_id: 203,
		part_id: 152,
		quantity: 19,
	},
	{
		garage_id: 203,
		part_id: 153,
		quantity: 457,
	},
	{
		garage_id: 203,
		part_id: 154,
		quantity: 343,
	},
	{
		garage_id: 203,
		part_id: 155,
		quantity: 403,
	},
	{
		garage_id: 203,
		part_id: 156,
		quantity: 238,
	},
	{
		garage_id: 203,
		part_id: 157,
		quantity: 216,
	},
	{
		garage_id: 203,
		part_id: 158,
		quantity: 423,
	},
	{
		garage_id: 203,
		part_id: 159,
		quantity: 372,
	},
	{
		garage_id: 203,
		part_id: 160,
		quantity: 444,
	},
	{
		garage_id: 203,
		part_id: 161,
		quantity: 460,
	},
	{
		garage_id: 203,
		part_id: 162,
		quantity: 116,
	},
	{
		garage_id: 203,
		part_id: 163,
		quantity: 99,
	},
	{
		garage_id: 203,
		part_id: 164,
		quantity: 349,
	},
	{
		garage_id: 203,
		part_id: 165,
		quantity: 226,
	},
	{
		garage_id: 203,
		part_id: 166,
		quantity: 117,
	},
	{
		garage_id: 203,
		part_id: 167,
		quantity: 53,
	},
	{
		garage_id: 203,
		part_id: 168,
		quantity: 158,
	},
	{
		garage_id: 203,
		part_id: 169,
		quantity: 323,
	},
	{
		garage_id: 203,
		part_id: 170,
		quantity: 13,
	},
	{
		garage_id: 203,
		part_id: 171,
		quantity: 271,
	},
	{
		garage_id: 203,
		part_id: 172,
		quantity: 15,
	},
	{
		garage_id: 203,
		part_id: 173,
		quantity: 155,
	},
	{
		garage_id: 203,
		part_id: 174,
		quantity: 3,
	},
	{
		garage_id: 203,
		part_id: 175,
		quantity: 267,
	},
	{
		garage_id: 203,
		part_id: 176,
		quantity: 259,
	},
	{
		garage_id: 203,
		part_id: 177,
		quantity: 151,
	},
	{
		garage_id: 203,
		part_id: 178,
		quantity: 138,
	},
	{
		garage_id: 203,
		part_id: 179,
		quantity: 188,
	},
	{
		garage_id: 203,
		part_id: 180,
		quantity: 146,
	},
	{
		garage_id: 203,
		part_id: 181,
		quantity: 321,
	},
	{
		garage_id: 203,
		part_id: 182,
		quantity: 433,
	},
	{
		garage_id: 203,
		part_id: 183,
		quantity: 472,
	},
	{
		garage_id: 203,
		part_id: 184,
		quantity: 490,
	},
	{
		garage_id: 203,
		part_id: 185,
		quantity: 382,
	},
	{
		garage_id: 203,
		part_id: 186,
		quantity: 411,
	},
	{
		garage_id: 203,
		part_id: 187,
		quantity: 95,
	},
	{
		garage_id: 203,
		part_id: 188,
		quantity: 5,
	},
	{
		garage_id: 203,
		part_id: 189,
		quantity: 112,
	},
	{
		garage_id: 203,
		part_id: 190,
		quantity: 375,
	},
	{
		garage_id: 203,
		part_id: 191,
		quantity: 341,
	},
	{
		garage_id: 203,
		part_id: 192,
		quantity: 377,
	},
	{
		garage_id: 203,
		part_id: 193,
		quantity: 296,
	},
	{
		garage_id: 203,
		part_id: 194,
		quantity: 49,
	},
	{
		garage_id: 203,
		part_id: 195,
		quantity: 414,
	},
	{
		garage_id: 203,
		part_id: 196,
		quantity: 334,
	},
	{
		garage_id: 203,
		part_id: 197,
		quantity: 291,
	},
	{
		garage_id: 203,
		part_id: 198,
		quantity: 11,
	},
	{
		garage_id: 203,
		part_id: 199,
		quantity: 327,
	},
	{
		garage_id: 203,
		part_id: 200,
		quantity: 447,
	},
	{
		garage_id: 203,
		part_id: 201,
		quantity: 284,
	},
	{
		garage_id: 203,
		part_id: 202,
		quantity: 288,
	},
	{
		garage_id: 203,
		part_id: 203,
		quantity: 243,
	},
	{
		garage_id: 203,
		part_id: 204,
		quantity: 209,
	},
	{
		garage_id: 203,
		part_id: 205,
		quantity: 256,
	},
	{
		garage_id: 203,
		part_id: 206,
		quantity: 82,
	},
	{
		garage_id: 203,
		part_id: 207,
		quantity: 459,
	},
	{
		garage_id: 203,
		part_id: 208,
		quantity: 452,
	},
	{
		garage_id: 203,
		part_id: 209,
		quantity: 143,
	},
	{
		garage_id: 203,
		part_id: 210,
		quantity: 469,
	},
	{
		garage_id: 203,
		part_id: 211,
		quantity: 83,
	},
	{
		garage_id: 203,
		part_id: 212,
		quantity: 233,
	},
	{
		garage_id: 203,
		part_id: 213,
		quantity: 429,
	},
	{
		garage_id: 203,
		part_id: 214,
		quantity: 400,
	},
	{
		garage_id: 203,
		part_id: 215,
		quantity: 117,
	},
	{
		garage_id: 203,
		part_id: 216,
		quantity: 194,
	},
	{
		garage_id: 203,
		part_id: 217,
		quantity: 468,
	},
	{
		garage_id: 203,
		part_id: 218,
		quantity: 367,
	},
	{
		garage_id: 203,
		part_id: 219,
		quantity: 110,
	},
	{
		garage_id: 203,
		part_id: 220,
		quantity: 483,
	},
	{
		garage_id: 203,
		part_id: 221,
		quantity: 427,
	},
	{
		garage_id: 203,
		part_id: 222,
		quantity: 379,
	},
	{
		garage_id: 203,
		part_id: 223,
		quantity: 346,
	},
	{
		garage_id: 203,
		part_id: 224,
		quantity: 117,
	},
	{
		garage_id: 203,
		part_id: 225,
		quantity: 81,
	},
	{
		garage_id: 203,
		part_id: 226,
		quantity: 158,
	},
	{
		garage_id: 203,
		part_id: 227,
		quantity: 310,
	},
	{
		garage_id: 203,
		part_id: 228,
		quantity: 120,
	},
	{
		garage_id: 203,
		part_id: 229,
		quantity: 372,
	},
	{
		garage_id: 203,
		part_id: 230,
		quantity: 372,
	},
	{
		garage_id: 203,
		part_id: 231,
		quantity: 99,
	},
	{
		garage_id: 203,
		part_id: 232,
		quantity: 52,
	},
	{
		garage_id: 203,
		part_id: 233,
		quantity: 360,
	},
	{
		garage_id: 203,
		part_id: 234,
		quantity: 261,
	},
	{
		garage_id: 203,
		part_id: 235,
		quantity: 285,
	},
	{
		garage_id: 203,
		part_id: 236,
		quantity: 204,
	},
	{
		garage_id: 203,
		part_id: 237,
		quantity: 486,
	},
	{
		garage_id: 203,
		part_id: 238,
		quantity: 334,
	},
	{
		garage_id: 203,
		part_id: 239,
		quantity: 58,
	},
	{
		garage_id: 203,
		part_id: 240,
		quantity: 58,
	},
	{
		garage_id: 203,
		part_id: 241,
		quantity: 340,
	},
	{
		garage_id: 203,
		part_id: 242,
		quantity: 494,
	},
	{
		garage_id: 203,
		part_id: 243,
		quantity: 295,
	},
	{
		garage_id: 203,
		part_id: 244,
		quantity: 59,
	},
	{
		garage_id: 203,
		part_id: 245,
		quantity: 368,
	},
	{
		garage_id: 203,
		part_id: 246,
		quantity: 126,
	},
	{
		garage_id: 203,
		part_id: 247,
		quantity: 312,
	},
	{
		garage_id: 203,
		part_id: 248,
		quantity: 403,
	},
	{
		garage_id: 203,
		part_id: 249,
		quantity: 62,
	},
	{
		garage_id: 203,
		part_id: 250,
		quantity: 49,
	},
	{
		garage_id: 203,
		part_id: 251,
		quantity: 271,
	},
	{
		garage_id: 203,
		part_id: 252,
		quantity: 446,
	},
	{
		garage_id: 203,
		part_id: 253,
		quantity: 344,
	},
	{
		garage_id: 203,
		part_id: 254,
		quantity: 156,
	},
	{
		garage_id: 203,
		part_id: 255,
		quantity: 281,
	},
	{
		garage_id: 203,
		part_id: 256,
		quantity: 176,
	},
	{
		garage_id: 203,
		part_id: 257,
		quantity: 183,
	},
	{
		garage_id: 203,
		part_id: 258,
		quantity: 291,
	},
	{
		garage_id: 203,
		part_id: 259,
		quantity: 138,
	},
	{
		garage_id: 203,
		part_id: 260,
		quantity: 158,
	},
	{
		garage_id: 203,
		part_id: 261,
		quantity: 158,
	},
	{
		garage_id: 203,
		part_id: 262,
		quantity: 323,
	},
	{
		garage_id: 203,
		part_id: 263,
		quantity: 26,
	},
	{
		garage_id: 203,
		part_id: 264,
		quantity: 66,
	},
	{
		garage_id: 203,
		part_id: 265,
		quantity: 239,
	},
	{
		garage_id: 203,
		part_id: 266,
		quantity: 344,
	},
	{
		garage_id: 203,
		part_id: 267,
		quantity: 37,
	},
	{
		garage_id: 203,
		part_id: 268,
		quantity: 180,
	},
	{
		garage_id: 203,
		part_id: 269,
		quantity: 390,
	},
	{
		garage_id: 203,
		part_id: 270,
		quantity: 360,
	},
	{
		garage_id: 203,
		part_id: 271,
		quantity: 372,
	},
	{
		garage_id: 203,
		part_id: 272,
		quantity: 152,
	},
	{
		garage_id: 203,
		part_id: 273,
		quantity: 286,
	},
	{
		garage_id: 203,
		part_id: 274,
		quantity: 18,
	},
	{
		garage_id: 203,
		part_id: 275,
		quantity: 311,
	},
	{
		garage_id: 203,
		part_id: 276,
		quantity: 395,
	},
	{
		garage_id: 203,
		part_id: 277,
		quantity: 418,
	},
	{
		garage_id: 203,
		part_id: 278,
		quantity: 349,
	},
	{
		garage_id: 203,
		part_id: 279,
		quantity: 427,
	},
	{
		garage_id: 203,
		part_id: 280,
		quantity: 329,
	},
	{
		garage_id: 203,
		part_id: 281,
		quantity: 280,
	},
	{
		garage_id: 203,
		part_id: 282,
		quantity: 482,
	},
	{
		garage_id: 203,
		part_id: 283,
		quantity: 26,
	},
	{
		garage_id: 203,
		part_id: 284,
		quantity: 367,
	},
	{
		garage_id: 203,
		part_id: 285,
		quantity: 189,
	},
	{
		garage_id: 203,
		part_id: 286,
		quantity: 333,
	},
	{
		garage_id: 203,
		part_id: 287,
		quantity: 339,
	},
	{
		garage_id: 203,
		part_id: 288,
		quantity: 211,
	},
	{
		garage_id: 203,
		part_id: 289,
		quantity: 43,
	},
	{
		garage_id: 203,
		part_id: 290,
		quantity: 406,
	},
	{
		garage_id: 203,
		part_id: 291,
		quantity: 418,
	},
	{
		garage_id: 203,
		part_id: 292,
		quantity: 173,
	},
	{
		garage_id: 203,
		part_id: 293,
		quantity: 495,
	},
	{
		garage_id: 203,
		part_id: 294,
		quantity: 413,
	},
	{
		garage_id: 203,
		part_id: 295,
		quantity: 498,
	},
	{
		garage_id: 203,
		part_id: 296,
		quantity: 395,
	},
	{
		garage_id: 203,
		part_id: 297,
		quantity: 43,
	},
	{
		garage_id: 203,
		part_id: 298,
		quantity: 386,
	},
	{
		garage_id: 203,
		part_id: 299,
		quantity: 297,
	},
	{
		garage_id: 203,
		part_id: 300,
		quantity: 83,
	},
	{
		garage_id: 203,
		part_id: 301,
		quantity: 106,
	},
	{
		garage_id: 203,
		part_id: 302,
		quantity: 475,
	},
	{
		garage_id: 203,
		part_id: 303,
		quantity: 61,
	},
	{
		garage_id: 203,
		part_id: 304,
		quantity: 134,
	},
	{
		garage_id: 203,
		part_id: 305,
		quantity: 154,
	},
	{
		garage_id: 203,
		part_id: 306,
		quantity: 338,
	},
	{
		garage_id: 203,
		part_id: 307,
		quantity: 115,
	},
	{
		garage_id: 203,
		part_id: 308,
		quantity: 339,
	},
	{
		garage_id: 203,
		part_id: 309,
		quantity: 217,
	},
	{
		garage_id: 203,
		part_id: 310,
		quantity: 434,
	},
	{
		garage_id: 203,
		part_id: 311,
		quantity: 251,
	},
	{
		garage_id: 203,
		part_id: 312,
		quantity: 450,
	},
	{
		garage_id: 203,
		part_id: 313,
		quantity: 72,
	},
	{
		garage_id: 203,
		part_id: 314,
		quantity: 389,
	},
	{
		garage_id: 203,
		part_id: 315,
		quantity: 219,
	},
	{
		garage_id: 203,
		part_id: 316,
		quantity: 112,
	},
	{
		garage_id: 203,
		part_id: 317,
		quantity: 322,
	},
	{
		garage_id: 203,
		part_id: 318,
		quantity: 33,
	},
	{
		garage_id: 203,
		part_id: 319,
		quantity: 227,
	},
	{
		garage_id: 203,
		part_id: 320,
		quantity: 122,
	},
	{
		garage_id: 203,
		part_id: 321,
		quantity: 92,
	},
	{
		garage_id: 203,
		part_id: 322,
		quantity: 224,
	},
	{
		garage_id: 203,
		part_id: 323,
		quantity: 387,
	},
	{
		garage_id: 203,
		part_id: 324,
		quantity: 151,
	},
	{
		garage_id: 203,
		part_id: 325,
		quantity: 360,
	},
	{
		garage_id: 203,
		part_id: 326,
		quantity: 488,
	},
	{
		garage_id: 203,
		part_id: 327,
		quantity: 159,
	},
	{
		garage_id: 203,
		part_id: 328,
		quantity: 313,
	},
	{
		garage_id: 203,
		part_id: 329,
		quantity: 418,
	},
	{
		garage_id: 203,
		part_id: 330,
		quantity: 498,
	},
	{
		garage_id: 203,
		part_id: 331,
		quantity: 224,
	},
	{
		garage_id: 203,
		part_id: 332,
		quantity: 43,
	},
	{
		garage_id: 203,
		part_id: 333,
		quantity: 296,
	},
	{
		garage_id: 203,
		part_id: 334,
		quantity: 402,
	},
	{
		garage_id: 203,
		part_id: 335,
		quantity: 138,
	},
	{
		garage_id: 203,
		part_id: 336,
		quantity: 181,
	},
	{
		garage_id: 203,
		part_id: 337,
		quantity: 213,
	},
	{
		garage_id: 203,
		part_id: 338,
		quantity: 496,
	},
	{
		garage_id: 203,
		part_id: 339,
		quantity: 379,
	},
	{
		garage_id: 203,
		part_id: 340,
		quantity: 81,
	},
	{
		garage_id: 203,
		part_id: 341,
		quantity: 498,
	},
	{
		garage_id: 203,
		part_id: 342,
		quantity: 414,
	},
	{
		garage_id: 203,
		part_id: 343,
		quantity: 291,
	},
	{
		garage_id: 203,
		part_id: 344,
		quantity: 259,
	},
	{
		garage_id: 203,
		part_id: 345,
		quantity: 480,
	},
	{
		garage_id: 203,
		part_id: 346,
		quantity: 235,
	},
	{
		garage_id: 203,
		part_id: 347,
		quantity: 220,
	},
	{
		garage_id: 203,
		part_id: 348,
		quantity: 372,
	},
	{
		garage_id: 203,
		part_id: 349,
		quantity: 112,
	},
	{
		garage_id: 203,
		part_id: 350,
		quantity: 223,
	},
	{
		garage_id: 203,
		part_id: 351,
		quantity: 78,
	},
	{
		garage_id: 203,
		part_id: 352,
		quantity: 216,
	},
	{
		garage_id: 203,
		part_id: 353,
		quantity: 208,
	},
	{
		garage_id: 203,
		part_id: 354,
		quantity: 99,
	},
	{
		garage_id: 203,
		part_id: 355,
		quantity: 36,
	},
	{
		garage_id: 203,
		part_id: 356,
		quantity: 187,
	},
	{
		garage_id: 203,
		part_id: 357,
		quantity: 260,
	},
	{
		garage_id: 203,
		part_id: 358,
		quantity: 206,
	},
	{
		garage_id: 203,
		part_id: 359,
		quantity: 134,
	},
	{
		garage_id: 203,
		part_id: 360,
		quantity: 157,
	},
	{
		garage_id: 203,
		part_id: 361,
		quantity: 163,
	},
	{
		garage_id: 203,
		part_id: 362,
		quantity: 372,
	},
	{
		garage_id: 203,
		part_id: 363,
		quantity: 388,
	},
	{
		garage_id: 203,
		part_id: 364,
		quantity: 294,
	},
	{
		garage_id: 203,
		part_id: 365,
		quantity: 404,
	},
	{
		garage_id: 203,
		part_id: 366,
		quantity: 181,
	},
	{
		garage_id: 203,
		part_id: 367,
		quantity: 8,
	},
	{
		garage_id: 203,
		part_id: 368,
		quantity: 231,
	},
	{
		garage_id: 203,
		part_id: 369,
		quantity: 363,
	},
	{
		garage_id: 203,
		part_id: 370,
		quantity: 364,
	},
	{
		garage_id: 203,
		part_id: 371,
		quantity: 339,
	},
	{
		garage_id: 203,
		part_id: 372,
		quantity: 169,
	},
	{
		garage_id: 203,
		part_id: 373,
		quantity: 410,
	},
	{
		garage_id: 203,
		part_id: 374,
		quantity: 45,
	},
	{
		garage_id: 203,
		part_id: 375,
		quantity: 162,
	},
	{
		garage_id: 203,
		part_id: 376,
		quantity: 44,
	},
	{
		garage_id: 203,
		part_id: 377,
		quantity: 176,
	},
	{
		garage_id: 203,
		part_id: 378,
		quantity: 403,
	},
	{
		garage_id: 203,
		part_id: 379,
		quantity: 332,
	},
	{
		garage_id: 203,
		part_id: 380,
		quantity: 101,
	},
	{
		garage_id: 203,
		part_id: 381,
		quantity: 498,
	},
	{
		garage_id: 203,
		part_id: 382,
		quantity: 215,
	},
	{
		garage_id: 203,
		part_id: 383,
		quantity: 8,
	},
	{
		garage_id: 203,
		part_id: 384,
		quantity: 50,
	},
	{
		garage_id: 203,
		part_id: 385,
		quantity: 8,
	},
	{
		garage_id: 203,
		part_id: 386,
		quantity: 154,
	},
	{
		garage_id: 203,
		part_id: 387,
		quantity: 440,
	},
	{
		garage_id: 203,
		part_id: 388,
		quantity: 53,
	},
	{
		garage_id: 203,
		part_id: 389,
		quantity: 285,
	},
	{
		garage_id: 203,
		part_id: 390,
		quantity: 146,
	},
	{
		garage_id: 203,
		part_id: 391,
		quantity: 5,
	},
	{
		garage_id: 203,
		part_id: 392,
		quantity: 203,
	},
	{
		garage_id: 203,
		part_id: 393,
		quantity: 205,
	},
	{
		garage_id: 203,
		part_id: 394,
		quantity: 302,
	},
	{
		garage_id: 203,
		part_id: 395,
		quantity: 160,
	},
	{
		garage_id: 203,
		part_id: 396,
		quantity: 141,
	},
	{
		garage_id: 203,
		part_id: 397,
		quantity: 154,
	},
	{
		garage_id: 203,
		part_id: 398,
		quantity: 160,
	},
	{
		garage_id: 203,
		part_id: 399,
		quantity: 463,
	},
	{
		garage_id: 204,
		part_id: 0,
		quantity: 410,
	},
	{
		garage_id: 204,
		part_id: 1,
		quantity: 11,
	},
	{
		garage_id: 204,
		part_id: 2,
		quantity: 389,
	},
	{
		garage_id: 204,
		part_id: 3,
		quantity: 162,
	},
	{
		garage_id: 204,
		part_id: 4,
		quantity: 46,
	},
	{
		garage_id: 204,
		part_id: 5,
		quantity: 277,
	},
	{
		garage_id: 204,
		part_id: 6,
		quantity: 305,
	},
	{
		garage_id: 204,
		part_id: 7,
		quantity: 359,
	},
	{
		garage_id: 204,
		part_id: 8,
		quantity: 462,
	},
	{
		garage_id: 204,
		part_id: 9,
		quantity: 203,
	},
	{
		garage_id: 204,
		part_id: 10,
		quantity: 416,
	},
	{
		garage_id: 204,
		part_id: 11,
		quantity: 190,
	},
	{
		garage_id: 204,
		part_id: 12,
		quantity: 313,
	},
	{
		garage_id: 204,
		part_id: 13,
		quantity: 463,
	},
	{
		garage_id: 204,
		part_id: 14,
		quantity: 36,
	},
	{
		garage_id: 204,
		part_id: 15,
		quantity: 126,
	},
	{
		garage_id: 204,
		part_id: 16,
		quantity: 69,
	},
	{
		garage_id: 204,
		part_id: 17,
		quantity: 251,
	},
	{
		garage_id: 204,
		part_id: 18,
		quantity: 262,
	},
	{
		garage_id: 204,
		part_id: 19,
		quantity: 453,
	},
	{
		garage_id: 204,
		part_id: 20,
		quantity: 139,
	},
	{
		garage_id: 204,
		part_id: 21,
		quantity: 414,
	},
	{
		garage_id: 204,
		part_id: 22,
		quantity: 348,
	},
	{
		garage_id: 204,
		part_id: 23,
		quantity: 464,
	},
	{
		garage_id: 204,
		part_id: 24,
		quantity: 189,
	},
	{
		garage_id: 204,
		part_id: 25,
		quantity: 432,
	},
	{
		garage_id: 204,
		part_id: 26,
		quantity: 364,
	},
	{
		garage_id: 204,
		part_id: 27,
		quantity: 69,
	},
	{
		garage_id: 204,
		part_id: 28,
		quantity: 423,
	},
	{
		garage_id: 204,
		part_id: 29,
		quantity: 386,
	},
	{
		garage_id: 204,
		part_id: 30,
		quantity: 100,
	},
	{
		garage_id: 204,
		part_id: 31,
		quantity: 11,
	},
	{
		garage_id: 204,
		part_id: 32,
		quantity: 364,
	},
	{
		garage_id: 204,
		part_id: 33,
		quantity: 478,
	},
	{
		garage_id: 204,
		part_id: 34,
		quantity: 413,
	},
	{
		garage_id: 204,
		part_id: 35,
		quantity: 107,
	},
	{
		garage_id: 204,
		part_id: 36,
		quantity: 305,
	},
	{
		garage_id: 204,
		part_id: 37,
		quantity: 5,
	},
	{
		garage_id: 204,
		part_id: 38,
		quantity: 195,
	},
	{
		garage_id: 204,
		part_id: 39,
		quantity: 264,
	},
	{
		garage_id: 204,
		part_id: 40,
		quantity: 1,
	},
	{
		garage_id: 204,
		part_id: 41,
		quantity: 81,
	},
	{
		garage_id: 204,
		part_id: 42,
		quantity: 221,
	},
	{
		garage_id: 204,
		part_id: 43,
		quantity: 341,
	},
	{
		garage_id: 204,
		part_id: 44,
		quantity: 496,
	},
	{
		garage_id: 204,
		part_id: 45,
		quantity: 135,
	},
	{
		garage_id: 204,
		part_id: 46,
		quantity: 485,
	},
	{
		garage_id: 204,
		part_id: 47,
		quantity: 389,
	},
	{
		garage_id: 204,
		part_id: 48,
		quantity: 390,
	},
	{
		garage_id: 204,
		part_id: 49,
		quantity: 307,
	},
	{
		garage_id: 204,
		part_id: 50,
		quantity: 415,
	},
	{
		garage_id: 204,
		part_id: 51,
		quantity: 490,
	},
	{
		garage_id: 204,
		part_id: 52,
		quantity: 458,
	},
	{
		garage_id: 204,
		part_id: 53,
		quantity: 8,
	},
	{
		garage_id: 204,
		part_id: 54,
		quantity: 328,
	},
	{
		garage_id: 204,
		part_id: 55,
		quantity: 193,
	},
	{
		garage_id: 204,
		part_id: 56,
		quantity: 22,
	},
	{
		garage_id: 204,
		part_id: 57,
		quantity: 383,
	},
	{
		garage_id: 204,
		part_id: 58,
		quantity: 461,
	},
	{
		garage_id: 204,
		part_id: 59,
		quantity: 150,
	},
	{
		garage_id: 204,
		part_id: 60,
		quantity: 402,
	},
	{
		garage_id: 204,
		part_id: 61,
		quantity: 289,
	},
	{
		garage_id: 204,
		part_id: 62,
		quantity: 211,
	},
	{
		garage_id: 204,
		part_id: 63,
		quantity: 174,
	},
	{
		garage_id: 204,
		part_id: 64,
		quantity: 474,
	},
	{
		garage_id: 204,
		part_id: 65,
		quantity: 375,
	},
	{
		garage_id: 204,
		part_id: 66,
		quantity: 20,
	},
	{
		garage_id: 204,
		part_id: 67,
		quantity: 199,
	},
	{
		garage_id: 204,
		part_id: 68,
		quantity: 216,
	},
	{
		garage_id: 204,
		part_id: 69,
		quantity: 423,
	},
	{
		garage_id: 204,
		part_id: 70,
		quantity: 314,
	},
	{
		garage_id: 204,
		part_id: 71,
		quantity: 483,
	},
	{
		garage_id: 204,
		part_id: 72,
		quantity: 194,
	},
	{
		garage_id: 204,
		part_id: 73,
		quantity: 336,
	},
	{
		garage_id: 204,
		part_id: 74,
		quantity: 59,
	},
	{
		garage_id: 204,
		part_id: 75,
		quantity: 291,
	},
	{
		garage_id: 204,
		part_id: 76,
		quantity: 258,
	},
	{
		garage_id: 204,
		part_id: 77,
		quantity: 61,
	},
	{
		garage_id: 204,
		part_id: 78,
		quantity: 14,
	},
	{
		garage_id: 204,
		part_id: 79,
		quantity: 45,
	},
	{
		garage_id: 204,
		part_id: 80,
		quantity: 200,
	},
	{
		garage_id: 204,
		part_id: 81,
		quantity: 287,
	},
	{
		garage_id: 204,
		part_id: 82,
		quantity: 91,
	},
	{
		garage_id: 204,
		part_id: 83,
		quantity: 110,
	},
	{
		garage_id: 204,
		part_id: 84,
		quantity: 111,
	},
	{
		garage_id: 204,
		part_id: 85,
		quantity: 130,
	},
	{
		garage_id: 204,
		part_id: 86,
		quantity: 386,
	},
	{
		garage_id: 204,
		part_id: 87,
		quantity: 248,
	},
	{
		garage_id: 204,
		part_id: 88,
		quantity: 71,
	},
	{
		garage_id: 204,
		part_id: 89,
		quantity: 21,
	},
	{
		garage_id: 204,
		part_id: 90,
		quantity: 133,
	},
	{
		garage_id: 204,
		part_id: 91,
		quantity: 92,
	},
	{
		garage_id: 204,
		part_id: 92,
		quantity: 84,
	},
	{
		garage_id: 204,
		part_id: 93,
		quantity: 198,
	},
	{
		garage_id: 204,
		part_id: 94,
		quantity: 344,
	},
	{
		garage_id: 204,
		part_id: 95,
		quantity: 177,
	},
	{
		garage_id: 204,
		part_id: 96,
		quantity: 116,
	},
	{
		garage_id: 204,
		part_id: 97,
		quantity: 48,
	},
	{
		garage_id: 204,
		part_id: 98,
		quantity: 401,
	},
	{
		garage_id: 204,
		part_id: 99,
		quantity: 422,
	},
	{
		garage_id: 204,
		part_id: 100,
		quantity: 216,
	},
	{
		garage_id: 204,
		part_id: 101,
		quantity: 182,
	},
	{
		garage_id: 204,
		part_id: 102,
		quantity: 278,
	},
	{
		garage_id: 204,
		part_id: 103,
		quantity: 399,
	},
	{
		garage_id: 204,
		part_id: 104,
		quantity: 58,
	},
	{
		garage_id: 204,
		part_id: 105,
		quantity: 187,
	},
	{
		garage_id: 204,
		part_id: 106,
		quantity: 320,
	},
	{
		garage_id: 204,
		part_id: 107,
		quantity: 362,
	},
	{
		garage_id: 204,
		part_id: 108,
		quantity: 454,
	},
	{
		garage_id: 204,
		part_id: 109,
		quantity: 412,
	},
	{
		garage_id: 204,
		part_id: 110,
		quantity: 105,
	},
	{
		garage_id: 204,
		part_id: 111,
		quantity: 69,
	},
	{
		garage_id: 204,
		part_id: 112,
		quantity: 464,
	},
	{
		garage_id: 204,
		part_id: 113,
		quantity: 65,
	},
	{
		garage_id: 204,
		part_id: 114,
		quantity: 67,
	},
	{
		garage_id: 204,
		part_id: 115,
		quantity: 319,
	},
	{
		garage_id: 204,
		part_id: 116,
		quantity: 425,
	},
	{
		garage_id: 204,
		part_id: 117,
		quantity: 46,
	},
	{
		garage_id: 204,
		part_id: 118,
		quantity: 181,
	},
	{
		garage_id: 204,
		part_id: 119,
		quantity: 118,
	},
	{
		garage_id: 204,
		part_id: 120,
		quantity: 64,
	},
	{
		garage_id: 204,
		part_id: 121,
		quantity: 177,
	},
	{
		garage_id: 204,
		part_id: 122,
		quantity: 440,
	},
	{
		garage_id: 204,
		part_id: 123,
		quantity: 358,
	},
	{
		garage_id: 204,
		part_id: 124,
		quantity: 128,
	},
	{
		garage_id: 204,
		part_id: 125,
		quantity: 119,
	},
	{
		garage_id: 204,
		part_id: 126,
		quantity: 288,
	},
	{
		garage_id: 204,
		part_id: 127,
		quantity: 295,
	},
	{
		garage_id: 204,
		part_id: 128,
		quantity: 164,
	},
	{
		garage_id: 204,
		part_id: 129,
		quantity: 274,
	},
	{
		garage_id: 204,
		part_id: 130,
		quantity: 462,
	},
	{
		garage_id: 204,
		part_id: 131,
		quantity: 358,
	},
	{
		garage_id: 204,
		part_id: 132,
		quantity: 377,
	},
	{
		garage_id: 204,
		part_id: 133,
		quantity: 49,
	},
	{
		garage_id: 204,
		part_id: 134,
		quantity: 302,
	},
	{
		garage_id: 204,
		part_id: 135,
		quantity: 142,
	},
	{
		garage_id: 204,
		part_id: 136,
		quantity: 191,
	},
	{
		garage_id: 204,
		part_id: 137,
		quantity: 74,
	},
	{
		garage_id: 204,
		part_id: 138,
		quantity: 295,
	},
	{
		garage_id: 204,
		part_id: 139,
		quantity: 394,
	},
	{
		garage_id: 204,
		part_id: 140,
		quantity: 122,
	},
	{
		garage_id: 204,
		part_id: 141,
		quantity: 70,
	},
	{
		garage_id: 204,
		part_id: 142,
		quantity: 489,
	},
	{
		garage_id: 204,
		part_id: 143,
		quantity: 202,
	},
	{
		garage_id: 204,
		part_id: 144,
		quantity: 32,
	},
	{
		garage_id: 204,
		part_id: 145,
		quantity: 138,
	},
	{
		garage_id: 204,
		part_id: 146,
		quantity: 338,
	},
	{
		garage_id: 204,
		part_id: 147,
		quantity: 260,
	},
	{
		garage_id: 204,
		part_id: 148,
		quantity: 85,
	},
	{
		garage_id: 204,
		part_id: 149,
		quantity: 125,
	},
	{
		garage_id: 204,
		part_id: 150,
		quantity: 208,
	},
	{
		garage_id: 204,
		part_id: 151,
		quantity: 114,
	},
	{
		garage_id: 204,
		part_id: 152,
		quantity: 330,
	},
	{
		garage_id: 204,
		part_id: 153,
		quantity: 469,
	},
	{
		garage_id: 204,
		part_id: 154,
		quantity: 57,
	},
	{
		garage_id: 204,
		part_id: 155,
		quantity: 425,
	},
	{
		garage_id: 204,
		part_id: 156,
		quantity: 461,
	},
	{
		garage_id: 204,
		part_id: 157,
		quantity: 8,
	},
	{
		garage_id: 204,
		part_id: 158,
		quantity: 275,
	},
	{
		garage_id: 204,
		part_id: 159,
		quantity: 333,
	},
	{
		garage_id: 204,
		part_id: 160,
		quantity: 3,
	},
	{
		garage_id: 204,
		part_id: 161,
		quantity: 407,
	},
	{
		garage_id: 204,
		part_id: 162,
		quantity: 281,
	},
	{
		garage_id: 204,
		part_id: 163,
		quantity: 329,
	},
	{
		garage_id: 204,
		part_id: 164,
		quantity: 485,
	},
	{
		garage_id: 204,
		part_id: 165,
		quantity: 291,
	},
	{
		garage_id: 204,
		part_id: 166,
		quantity: 330,
	},
	{
		garage_id: 204,
		part_id: 167,
		quantity: 466,
	},
	{
		garage_id: 204,
		part_id: 168,
		quantity: 0,
	},
	{
		garage_id: 204,
		part_id: 169,
		quantity: 480,
	},
	{
		garage_id: 204,
		part_id: 170,
		quantity: 294,
	},
	{
		garage_id: 204,
		part_id: 171,
		quantity: 231,
	},
	{
		garage_id: 204,
		part_id: 172,
		quantity: 496,
	},
	{
		garage_id: 204,
		part_id: 173,
		quantity: 449,
	},
	{
		garage_id: 204,
		part_id: 174,
		quantity: 498,
	},
	{
		garage_id: 204,
		part_id: 175,
		quantity: 50,
	},
	{
		garage_id: 204,
		part_id: 176,
		quantity: 334,
	},
	{
		garage_id: 204,
		part_id: 177,
		quantity: 477,
	},
	{
		garage_id: 204,
		part_id: 178,
		quantity: 41,
	},
	{
		garage_id: 204,
		part_id: 179,
		quantity: 199,
	},
	{
		garage_id: 204,
		part_id: 180,
		quantity: 343,
	},
	{
		garage_id: 204,
		part_id: 181,
		quantity: 5,
	},
	{
		garage_id: 204,
		part_id: 182,
		quantity: 401,
	},
	{
		garage_id: 204,
		part_id: 183,
		quantity: 493,
	},
	{
		garage_id: 204,
		part_id: 184,
		quantity: 385,
	},
	{
		garage_id: 204,
		part_id: 185,
		quantity: 152,
	},
	{
		garage_id: 204,
		part_id: 186,
		quantity: 37,
	},
	{
		garage_id: 204,
		part_id: 187,
		quantity: 51,
	},
	{
		garage_id: 204,
		part_id: 188,
		quantity: 137,
	},
	{
		garage_id: 204,
		part_id: 189,
		quantity: 82,
	},
	{
		garage_id: 204,
		part_id: 190,
		quantity: 399,
	},
	{
		garage_id: 204,
		part_id: 191,
		quantity: 172,
	},
	{
		garage_id: 204,
		part_id: 192,
		quantity: 40,
	},
	{
		garage_id: 204,
		part_id: 193,
		quantity: 165,
	},
	{
		garage_id: 204,
		part_id: 194,
		quantity: 347,
	},
	{
		garage_id: 204,
		part_id: 195,
		quantity: 20,
	},
	{
		garage_id: 204,
		part_id: 196,
		quantity: 59,
	},
	{
		garage_id: 204,
		part_id: 197,
		quantity: 315,
	},
	{
		garage_id: 204,
		part_id: 198,
		quantity: 438,
	},
	{
		garage_id: 204,
		part_id: 199,
		quantity: 423,
	},
	{
		garage_id: 204,
		part_id: 200,
		quantity: 383,
	},
	{
		garage_id: 204,
		part_id: 201,
		quantity: 82,
	},
	{
		garage_id: 204,
		part_id: 202,
		quantity: 23,
	},
	{
		garage_id: 204,
		part_id: 203,
		quantity: 38,
	},
	{
		garage_id: 204,
		part_id: 204,
		quantity: 426,
	},
	{
		garage_id: 204,
		part_id: 205,
		quantity: 421,
	},
	{
		garage_id: 204,
		part_id: 206,
		quantity: 60,
	},
	{
		garage_id: 204,
		part_id: 207,
		quantity: 427,
	},
	{
		garage_id: 204,
		part_id: 208,
		quantity: 274,
	},
	{
		garage_id: 204,
		part_id: 209,
		quantity: 376,
	},
	{
		garage_id: 204,
		part_id: 210,
		quantity: 121,
	},
	{
		garage_id: 204,
		part_id: 211,
		quantity: 46,
	},
	{
		garage_id: 204,
		part_id: 212,
		quantity: 209,
	},
	{
		garage_id: 204,
		part_id: 213,
		quantity: 198,
	},
	{
		garage_id: 204,
		part_id: 214,
		quantity: 42,
	},
	{
		garage_id: 204,
		part_id: 215,
		quantity: 210,
	},
	{
		garage_id: 204,
		part_id: 216,
		quantity: 310,
	},
	{
		garage_id: 204,
		part_id: 217,
		quantity: 11,
	},
	{
		garage_id: 204,
		part_id: 218,
		quantity: 240,
	},
	{
		garage_id: 204,
		part_id: 219,
		quantity: 28,
	},
	{
		garage_id: 204,
		part_id: 220,
		quantity: 14,
	},
	{
		garage_id: 204,
		part_id: 221,
		quantity: 350,
	},
	{
		garage_id: 204,
		part_id: 222,
		quantity: 96,
	},
	{
		garage_id: 204,
		part_id: 223,
		quantity: 290,
	},
	{
		garage_id: 204,
		part_id: 224,
		quantity: 244,
	},
	{
		garage_id: 204,
		part_id: 225,
		quantity: 386,
	},
	{
		garage_id: 204,
		part_id: 226,
		quantity: 277,
	},
	{
		garage_id: 204,
		part_id: 227,
		quantity: 354,
	},
	{
		garage_id: 204,
		part_id: 228,
		quantity: 173,
	},
	{
		garage_id: 204,
		part_id: 229,
		quantity: 184,
	},
	{
		garage_id: 204,
		part_id: 230,
		quantity: 464,
	},
	{
		garage_id: 204,
		part_id: 231,
		quantity: 437,
	},
	{
		garage_id: 204,
		part_id: 232,
		quantity: 39,
	},
	{
		garage_id: 204,
		part_id: 233,
		quantity: 46,
	},
	{
		garage_id: 204,
		part_id: 234,
		quantity: 193,
	},
	{
		garage_id: 204,
		part_id: 235,
		quantity: 318,
	},
	{
		garage_id: 204,
		part_id: 236,
		quantity: 188,
	},
	{
		garage_id: 204,
		part_id: 237,
		quantity: 40,
	},
	{
		garage_id: 204,
		part_id: 238,
		quantity: 76,
	},
	{
		garage_id: 204,
		part_id: 239,
		quantity: 388,
	},
	{
		garage_id: 204,
		part_id: 240,
		quantity: 407,
	},
	{
		garage_id: 204,
		part_id: 241,
		quantity: 427,
	},
	{
		garage_id: 204,
		part_id: 242,
		quantity: 447,
	},
	{
		garage_id: 204,
		part_id: 243,
		quantity: 93,
	},
	{
		garage_id: 204,
		part_id: 244,
		quantity: 277,
	},
	{
		garage_id: 204,
		part_id: 245,
		quantity: 212,
	},
	{
		garage_id: 204,
		part_id: 246,
		quantity: 436,
	},
	{
		garage_id: 204,
		part_id: 247,
		quantity: 37,
	},
	{
		garage_id: 204,
		part_id: 248,
		quantity: 430,
	},
	{
		garage_id: 204,
		part_id: 249,
		quantity: 57,
	},
	{
		garage_id: 204,
		part_id: 250,
		quantity: 56,
	},
	{
		garage_id: 204,
		part_id: 251,
		quantity: 347,
	},
	{
		garage_id: 204,
		part_id: 252,
		quantity: 74,
	},
	{
		garage_id: 204,
		part_id: 253,
		quantity: 438,
	},
	{
		garage_id: 204,
		part_id: 254,
		quantity: 142,
	},
	{
		garage_id: 204,
		part_id: 255,
		quantity: 173,
	},
	{
		garage_id: 204,
		part_id: 256,
		quantity: 218,
	},
	{
		garage_id: 204,
		part_id: 257,
		quantity: 169,
	},
	{
		garage_id: 204,
		part_id: 258,
		quantity: 276,
	},
	{
		garage_id: 204,
		part_id: 259,
		quantity: 2,
	},
	{
		garage_id: 204,
		part_id: 260,
		quantity: 256,
	},
	{
		garage_id: 204,
		part_id: 261,
		quantity: 359,
	},
	{
		garage_id: 204,
		part_id: 262,
		quantity: 475,
	},
	{
		garage_id: 204,
		part_id: 263,
		quantity: 279,
	},
	{
		garage_id: 204,
		part_id: 264,
		quantity: 5,
	},
	{
		garage_id: 204,
		part_id: 265,
		quantity: 351,
	},
	{
		garage_id: 204,
		part_id: 266,
		quantity: 28,
	},
	{
		garage_id: 204,
		part_id: 267,
		quantity: 94,
	},
	{
		garage_id: 204,
		part_id: 268,
		quantity: 87,
	},
	{
		garage_id: 204,
		part_id: 269,
		quantity: 388,
	},
	{
		garage_id: 204,
		part_id: 270,
		quantity: 68,
	},
	{
		garage_id: 204,
		part_id: 271,
		quantity: 377,
	},
	{
		garage_id: 204,
		part_id: 272,
		quantity: 27,
	},
	{
		garage_id: 204,
		part_id: 273,
		quantity: 334,
	},
	{
		garage_id: 204,
		part_id: 274,
		quantity: 31,
	},
	{
		garage_id: 204,
		part_id: 275,
		quantity: 197,
	},
	{
		garage_id: 204,
		part_id: 276,
		quantity: 287,
	},
	{
		garage_id: 204,
		part_id: 277,
		quantity: 271,
	},
	{
		garage_id: 204,
		part_id: 278,
		quantity: 246,
	},
	{
		garage_id: 204,
		part_id: 279,
		quantity: 144,
	},
	{
		garage_id: 204,
		part_id: 280,
		quantity: 371,
	},
	{
		garage_id: 204,
		part_id: 281,
		quantity: 293,
	},
	{
		garage_id: 204,
		part_id: 282,
		quantity: 452,
	},
	{
		garage_id: 204,
		part_id: 283,
		quantity: 160,
	},
	{
		garage_id: 204,
		part_id: 284,
		quantity: 329,
	},
	{
		garage_id: 204,
		part_id: 285,
		quantity: 343,
	},
	{
		garage_id: 204,
		part_id: 286,
		quantity: 45,
	},
	{
		garage_id: 204,
		part_id: 287,
		quantity: 298,
	},
	{
		garage_id: 204,
		part_id: 288,
		quantity: 94,
	},
	{
		garage_id: 204,
		part_id: 289,
		quantity: 483,
	},
	{
		garage_id: 204,
		part_id: 290,
		quantity: 197,
	},
	{
		garage_id: 204,
		part_id: 291,
		quantity: 76,
	},
	{
		garage_id: 204,
		part_id: 292,
		quantity: 389,
	},
	{
		garage_id: 204,
		part_id: 293,
		quantity: 134,
	},
	{
		garage_id: 204,
		part_id: 294,
		quantity: 337,
	},
	{
		garage_id: 204,
		part_id: 295,
		quantity: 123,
	},
	{
		garage_id: 204,
		part_id: 296,
		quantity: 245,
	},
	{
		garage_id: 204,
		part_id: 297,
		quantity: 230,
	},
	{
		garage_id: 204,
		part_id: 298,
		quantity: 211,
	},
	{
		garage_id: 204,
		part_id: 299,
		quantity: 378,
	},
	{
		garage_id: 204,
		part_id: 300,
		quantity: 121,
	},
	{
		garage_id: 204,
		part_id: 301,
		quantity: 26,
	},
	{
		garage_id: 204,
		part_id: 302,
		quantity: 136,
	},
	{
		garage_id: 204,
		part_id: 303,
		quantity: 241,
	},
	{
		garage_id: 204,
		part_id: 304,
		quantity: 285,
	},
	{
		garage_id: 204,
		part_id: 305,
		quantity: 190,
	},
	{
		garage_id: 204,
		part_id: 306,
		quantity: 181,
	},
	{
		garage_id: 204,
		part_id: 307,
		quantity: 303,
	},
	{
		garage_id: 204,
		part_id: 308,
		quantity: 156,
	},
	{
		garage_id: 204,
		part_id: 309,
		quantity: 388,
	},
	{
		garage_id: 204,
		part_id: 310,
		quantity: 485,
	},
	{
		garage_id: 204,
		part_id: 311,
		quantity: 463,
	},
	{
		garage_id: 204,
		part_id: 312,
		quantity: 26,
	},
	{
		garage_id: 204,
		part_id: 313,
		quantity: 449,
	},
	{
		garage_id: 204,
		part_id: 314,
		quantity: 411,
	},
	{
		garage_id: 204,
		part_id: 315,
		quantity: 145,
	},
	{
		garage_id: 204,
		part_id: 316,
		quantity: 474,
	},
	{
		garage_id: 204,
		part_id: 317,
		quantity: 330,
	},
	{
		garage_id: 204,
		part_id: 318,
		quantity: 224,
	},
	{
		garage_id: 204,
		part_id: 319,
		quantity: 414,
	},
	{
		garage_id: 204,
		part_id: 320,
		quantity: 147,
	},
	{
		garage_id: 204,
		part_id: 321,
		quantity: 466,
	},
	{
		garage_id: 204,
		part_id: 322,
		quantity: 433,
	},
	{
		garage_id: 204,
		part_id: 323,
		quantity: 372,
	},
	{
		garage_id: 204,
		part_id: 324,
		quantity: 254,
	},
	{
		garage_id: 204,
		part_id: 325,
		quantity: 386,
	},
	{
		garage_id: 204,
		part_id: 326,
		quantity: 145,
	},
	{
		garage_id: 204,
		part_id: 327,
		quantity: 35,
	},
	{
		garage_id: 204,
		part_id: 328,
		quantity: 286,
	},
	{
		garage_id: 204,
		part_id: 329,
		quantity: 498,
	},
	{
		garage_id: 204,
		part_id: 330,
		quantity: 211,
	},
	{
		garage_id: 204,
		part_id: 331,
		quantity: 61,
	},
	{
		garage_id: 204,
		part_id: 332,
		quantity: 454,
	},
	{
		garage_id: 204,
		part_id: 333,
		quantity: 271,
	},
	{
		garage_id: 204,
		part_id: 334,
		quantity: 423,
	},
	{
		garage_id: 204,
		part_id: 335,
		quantity: 299,
	},
	{
		garage_id: 204,
		part_id: 336,
		quantity: 52,
	},
	{
		garage_id: 204,
		part_id: 337,
		quantity: 126,
	},
	{
		garage_id: 204,
		part_id: 338,
		quantity: 155,
	},
	{
		garage_id: 204,
		part_id: 339,
		quantity: 498,
	},
	{
		garage_id: 204,
		part_id: 340,
		quantity: 148,
	},
	{
		garage_id: 204,
		part_id: 341,
		quantity: 234,
	},
	{
		garage_id: 204,
		part_id: 342,
		quantity: 35,
	},
	{
		garage_id: 204,
		part_id: 343,
		quantity: 325,
	},
	{
		garage_id: 204,
		part_id: 344,
		quantity: 38,
	},
	{
		garage_id: 204,
		part_id: 345,
		quantity: 259,
	},
	{
		garage_id: 204,
		part_id: 346,
		quantity: 464,
	},
	{
		garage_id: 204,
		part_id: 347,
		quantity: 323,
	},
	{
		garage_id: 204,
		part_id: 348,
		quantity: 144,
	},
	{
		garage_id: 204,
		part_id: 349,
		quantity: 330,
	},
	{
		garage_id: 204,
		part_id: 350,
		quantity: 194,
	},
	{
		garage_id: 204,
		part_id: 351,
		quantity: 456,
	},
	{
		garage_id: 204,
		part_id: 352,
		quantity: 176,
	},
	{
		garage_id: 204,
		part_id: 353,
		quantity: 1,
	},
	{
		garage_id: 204,
		part_id: 354,
		quantity: 469,
	},
	{
		garage_id: 204,
		part_id: 355,
		quantity: 413,
	},
	{
		garage_id: 204,
		part_id: 356,
		quantity: 99,
	},
	{
		garage_id: 204,
		part_id: 357,
		quantity: 40,
	},
	{
		garage_id: 204,
		part_id: 358,
		quantity: 361,
	},
	{
		garage_id: 204,
		part_id: 359,
		quantity: 391,
	},
	{
		garage_id: 204,
		part_id: 360,
		quantity: 266,
	},
	{
		garage_id: 204,
		part_id: 361,
		quantity: 322,
	},
	{
		garage_id: 204,
		part_id: 362,
		quantity: 216,
	},
	{
		garage_id: 204,
		part_id: 363,
		quantity: 428,
	},
	{
		garage_id: 204,
		part_id: 364,
		quantity: 61,
	},
	{
		garage_id: 204,
		part_id: 365,
		quantity: 458,
	},
	{
		garage_id: 204,
		part_id: 366,
		quantity: 478,
	},
	{
		garage_id: 204,
		part_id: 367,
		quantity: 261,
	},
	{
		garage_id: 204,
		part_id: 368,
		quantity: 67,
	},
	{
		garage_id: 204,
		part_id: 369,
		quantity: 483,
	},
	{
		garage_id: 204,
		part_id: 370,
		quantity: 118,
	},
	{
		garage_id: 204,
		part_id: 371,
		quantity: 214,
	},
	{
		garage_id: 204,
		part_id: 372,
		quantity: 478,
	},
	{
		garage_id: 204,
		part_id: 373,
		quantity: 206,
	},
	{
		garage_id: 204,
		part_id: 374,
		quantity: 211,
	},
	{
		garage_id: 204,
		part_id: 375,
		quantity: 379,
	},
	{
		garage_id: 204,
		part_id: 376,
		quantity: 325,
	},
	{
		garage_id: 204,
		part_id: 377,
		quantity: 354,
	},
	{
		garage_id: 204,
		part_id: 378,
		quantity: 149,
	},
	{
		garage_id: 204,
		part_id: 379,
		quantity: 202,
	},
	{
		garage_id: 204,
		part_id: 380,
		quantity: 90,
	},
	{
		garage_id: 204,
		part_id: 381,
		quantity: 85,
	},
	{
		garage_id: 204,
		part_id: 382,
		quantity: 282,
	},
	{
		garage_id: 204,
		part_id: 383,
		quantity: 217,
	},
	{
		garage_id: 204,
		part_id: 384,
		quantity: 476,
	},
	{
		garage_id: 204,
		part_id: 385,
		quantity: 336,
	},
	{
		garage_id: 204,
		part_id: 386,
		quantity: 499,
	},
	{
		garage_id: 204,
		part_id: 387,
		quantity: 395,
	},
	{
		garage_id: 204,
		part_id: 388,
		quantity: 498,
	},
	{
		garage_id: 204,
		part_id: 389,
		quantity: 222,
	},
	{
		garage_id: 204,
		part_id: 390,
		quantity: 168,
	},
	{
		garage_id: 204,
		part_id: 391,
		quantity: 188,
	},
	{
		garage_id: 204,
		part_id: 392,
		quantity: 246,
	},
	{
		garage_id: 204,
		part_id: 393,
		quantity: 326,
	},
	{
		garage_id: 204,
		part_id: 394,
		quantity: 444,
	},
	{
		garage_id: 204,
		part_id: 395,
		quantity: 341,
	},
	{
		garage_id: 204,
		part_id: 396,
		quantity: 453,
	},
	{
		garage_id: 204,
		part_id: 397,
		quantity: 180,
	},
	{
		garage_id: 204,
		part_id: 398,
		quantity: 240,
	},
	{
		garage_id: 204,
		part_id: 399,
		quantity: 235,
	},
	{
		garage_id: 205,
		part_id: 0,
		quantity: 72,
	},
	{
		garage_id: 205,
		part_id: 1,
		quantity: 463,
	},
	{
		garage_id: 205,
		part_id: 2,
		quantity: 226,
	},
	{
		garage_id: 205,
		part_id: 3,
		quantity: 300,
	},
	{
		garage_id: 205,
		part_id: 4,
		quantity: 35,
	},
	{
		garage_id: 205,
		part_id: 5,
		quantity: 456,
	},
	{
		garage_id: 205,
		part_id: 6,
		quantity: 292,
	},
	{
		garage_id: 205,
		part_id: 7,
		quantity: 1,
	},
	{
		garage_id: 205,
		part_id: 8,
		quantity: 73,
	},
	{
		garage_id: 205,
		part_id: 9,
		quantity: 499,
	},
	{
		garage_id: 205,
		part_id: 10,
		quantity: 423,
	},
	{
		garage_id: 205,
		part_id: 11,
		quantity: 309,
	},
	{
		garage_id: 205,
		part_id: 12,
		quantity: 490,
	},
	{
		garage_id: 205,
		part_id: 13,
		quantity: 309,
	},
	{
		garage_id: 205,
		part_id: 14,
		quantity: 23,
	},
	{
		garage_id: 205,
		part_id: 15,
		quantity: 415,
	},
	{
		garage_id: 205,
		part_id: 16,
		quantity: 150,
	},
	{
		garage_id: 205,
		part_id: 17,
		quantity: 396,
	},
	{
		garage_id: 205,
		part_id: 18,
		quantity: 17,
	},
	{
		garage_id: 205,
		part_id: 19,
		quantity: 353,
	},
	{
		garage_id: 205,
		part_id: 20,
		quantity: 439,
	},
	{
		garage_id: 205,
		part_id: 21,
		quantity: 176,
	},
	{
		garage_id: 205,
		part_id: 22,
		quantity: 225,
	},
	{
		garage_id: 205,
		part_id: 23,
		quantity: 470,
	},
	{
		garage_id: 205,
		part_id: 24,
		quantity: 30,
	},
	{
		garage_id: 205,
		part_id: 25,
		quantity: 106,
	},
	{
		garage_id: 205,
		part_id: 26,
		quantity: 353,
	},
	{
		garage_id: 205,
		part_id: 27,
		quantity: 78,
	},
	{
		garage_id: 205,
		part_id: 28,
		quantity: 191,
	},
	{
		garage_id: 205,
		part_id: 29,
		quantity: 392,
	},
	{
		garage_id: 205,
		part_id: 30,
		quantity: 455,
	},
	{
		garage_id: 205,
		part_id: 31,
		quantity: 452,
	},
	{
		garage_id: 205,
		part_id: 32,
		quantity: 462,
	},
	{
		garage_id: 205,
		part_id: 33,
		quantity: 319,
	},
	{
		garage_id: 205,
		part_id: 34,
		quantity: 410,
	},
	{
		garage_id: 205,
		part_id: 35,
		quantity: 332,
	},
	{
		garage_id: 205,
		part_id: 36,
		quantity: 138,
	},
	{
		garage_id: 205,
		part_id: 37,
		quantity: 85,
	},
	{
		garage_id: 205,
		part_id: 38,
		quantity: 365,
	},
	{
		garage_id: 205,
		part_id: 39,
		quantity: 178,
	},
	{
		garage_id: 205,
		part_id: 40,
		quantity: 299,
	},
	{
		garage_id: 205,
		part_id: 41,
		quantity: 297,
	},
	{
		garage_id: 205,
		part_id: 42,
		quantity: 26,
	},
	{
		garage_id: 205,
		part_id: 43,
		quantity: 272,
	},
	{
		garage_id: 205,
		part_id: 44,
		quantity: 352,
	},
	{
		garage_id: 205,
		part_id: 45,
		quantity: 39,
	},
	{
		garage_id: 205,
		part_id: 46,
		quantity: 100,
	},
	{
		garage_id: 205,
		part_id: 47,
		quantity: 4,
	},
	{
		garage_id: 205,
		part_id: 48,
		quantity: 140,
	},
	{
		garage_id: 205,
		part_id: 49,
		quantity: 24,
	},
	{
		garage_id: 205,
		part_id: 50,
		quantity: 370,
	},
	{
		garage_id: 205,
		part_id: 51,
		quantity: 9,
	},
	{
		garage_id: 205,
		part_id: 52,
		quantity: 413,
	},
	{
		garage_id: 205,
		part_id: 53,
		quantity: 330,
	},
	{
		garage_id: 205,
		part_id: 54,
		quantity: 493,
	},
	{
		garage_id: 205,
		part_id: 55,
		quantity: 408,
	},
	{
		garage_id: 205,
		part_id: 56,
		quantity: 224,
	},
	{
		garage_id: 205,
		part_id: 57,
		quantity: 234,
	},
	{
		garage_id: 205,
		part_id: 58,
		quantity: 358,
	},
	{
		garage_id: 205,
		part_id: 59,
		quantity: 205,
	},
	{
		garage_id: 205,
		part_id: 60,
		quantity: 456,
	},
	{
		garage_id: 205,
		part_id: 61,
		quantity: 419,
	},
	{
		garage_id: 205,
		part_id: 62,
		quantity: 101,
	},
	{
		garage_id: 205,
		part_id: 63,
		quantity: 192,
	},
	{
		garage_id: 205,
		part_id: 64,
		quantity: 178,
	},
	{
		garage_id: 205,
		part_id: 65,
		quantity: 230,
	},
	{
		garage_id: 205,
		part_id: 66,
		quantity: 125,
	},
	{
		garage_id: 205,
		part_id: 67,
		quantity: 354,
	},
	{
		garage_id: 205,
		part_id: 68,
		quantity: 63,
	},
	{
		garage_id: 205,
		part_id: 69,
		quantity: 204,
	},
	{
		garage_id: 205,
		part_id: 70,
		quantity: 468,
	},
	{
		garage_id: 205,
		part_id: 71,
		quantity: 370,
	},
	{
		garage_id: 205,
		part_id: 72,
		quantity: 282,
	},
	{
		garage_id: 205,
		part_id: 73,
		quantity: 348,
	},
	{
		garage_id: 205,
		part_id: 74,
		quantity: 485,
	},
	{
		garage_id: 205,
		part_id: 75,
		quantity: 115,
	},
	{
		garage_id: 205,
		part_id: 76,
		quantity: 345,
	},
	{
		garage_id: 205,
		part_id: 77,
		quantity: 312,
	},
	{
		garage_id: 205,
		part_id: 78,
		quantity: 395,
	},
	{
		garage_id: 205,
		part_id: 79,
		quantity: 492,
	},
	{
		garage_id: 205,
		part_id: 80,
		quantity: 197,
	},
	{
		garage_id: 205,
		part_id: 81,
		quantity: 116,
	},
	{
		garage_id: 205,
		part_id: 82,
		quantity: 33,
	},
	{
		garage_id: 205,
		part_id: 83,
		quantity: 176,
	},
	{
		garage_id: 205,
		part_id: 84,
		quantity: 34,
	},
	{
		garage_id: 205,
		part_id: 85,
		quantity: 138,
	},
	{
		garage_id: 205,
		part_id: 86,
		quantity: 14,
	},
	{
		garage_id: 205,
		part_id: 87,
		quantity: 177,
	},
	{
		garage_id: 205,
		part_id: 88,
		quantity: 187,
	},
	{
		garage_id: 205,
		part_id: 89,
		quantity: 130,
	},
	{
		garage_id: 205,
		part_id: 90,
		quantity: 463,
	},
	{
		garage_id: 205,
		part_id: 91,
		quantity: 316,
	},
	{
		garage_id: 205,
		part_id: 92,
		quantity: 310,
	},
	{
		garage_id: 205,
		part_id: 93,
		quantity: 88,
	},
	{
		garage_id: 205,
		part_id: 94,
		quantity: 361,
	},
	{
		garage_id: 205,
		part_id: 95,
		quantity: 458,
	},
	{
		garage_id: 205,
		part_id: 96,
		quantity: 276,
	},
	{
		garage_id: 205,
		part_id: 97,
		quantity: 241,
	},
	{
		garage_id: 205,
		part_id: 98,
		quantity: 160,
	},
	{
		garage_id: 205,
		part_id: 99,
		quantity: 369,
	},
	{
		garage_id: 205,
		part_id: 100,
		quantity: 313,
	},
	{
		garage_id: 205,
		part_id: 101,
		quantity: 431,
	},
	{
		garage_id: 205,
		part_id: 102,
		quantity: 100,
	},
	{
		garage_id: 205,
		part_id: 103,
		quantity: 461,
	},
	{
		garage_id: 205,
		part_id: 104,
		quantity: 116,
	},
	{
		garage_id: 205,
		part_id: 105,
		quantity: 244,
	},
	{
		garage_id: 205,
		part_id: 106,
		quantity: 76,
	},
	{
		garage_id: 205,
		part_id: 107,
		quantity: 5,
	},
	{
		garage_id: 205,
		part_id: 108,
		quantity: 15,
	},
	{
		garage_id: 205,
		part_id: 109,
		quantity: 190,
	},
	{
		garage_id: 205,
		part_id: 110,
		quantity: 101,
	},
	{
		garage_id: 205,
		part_id: 111,
		quantity: 454,
	},
	{
		garage_id: 205,
		part_id: 112,
		quantity: 308,
	},
	{
		garage_id: 205,
		part_id: 113,
		quantity: 220,
	},
	{
		garage_id: 205,
		part_id: 114,
		quantity: 439,
	},
	{
		garage_id: 205,
		part_id: 115,
		quantity: 495,
	},
	{
		garage_id: 205,
		part_id: 116,
		quantity: 96,
	},
	{
		garage_id: 205,
		part_id: 117,
		quantity: 318,
	},
	{
		garage_id: 205,
		part_id: 118,
		quantity: 123,
	},
	{
		garage_id: 205,
		part_id: 119,
		quantity: 396,
	},
	{
		garage_id: 205,
		part_id: 120,
		quantity: 439,
	},
	{
		garage_id: 205,
		part_id: 121,
		quantity: 362,
	},
	{
		garage_id: 205,
		part_id: 122,
		quantity: 443,
	},
	{
		garage_id: 205,
		part_id: 123,
		quantity: 487,
	},
	{
		garage_id: 205,
		part_id: 124,
		quantity: 263,
	},
	{
		garage_id: 205,
		part_id: 125,
		quantity: 287,
	},
	{
		garage_id: 205,
		part_id: 126,
		quantity: 22,
	},
	{
		garage_id: 205,
		part_id: 127,
		quantity: 206,
	},
	{
		garage_id: 205,
		part_id: 128,
		quantity: 94,
	},
	{
		garage_id: 205,
		part_id: 129,
		quantity: 121,
	},
	{
		garage_id: 205,
		part_id: 130,
		quantity: 172,
	},
	{
		garage_id: 205,
		part_id: 131,
		quantity: 341,
	},
	{
		garage_id: 205,
		part_id: 132,
		quantity: 444,
	},
	{
		garage_id: 205,
		part_id: 133,
		quantity: 416,
	},
	{
		garage_id: 205,
		part_id: 134,
		quantity: 121,
	},
	{
		garage_id: 205,
		part_id: 135,
		quantity: 341,
	},
	{
		garage_id: 205,
		part_id: 136,
		quantity: 488,
	},
	{
		garage_id: 205,
		part_id: 137,
		quantity: 395,
	},
	{
		garage_id: 205,
		part_id: 138,
		quantity: 315,
	},
	{
		garage_id: 205,
		part_id: 139,
		quantity: 496,
	},
	{
		garage_id: 205,
		part_id: 140,
		quantity: 187,
	},
	{
		garage_id: 205,
		part_id: 141,
		quantity: 256,
	},
	{
		garage_id: 205,
		part_id: 142,
		quantity: 453,
	},
	{
		garage_id: 205,
		part_id: 143,
		quantity: 252,
	},
	{
		garage_id: 205,
		part_id: 144,
		quantity: 395,
	},
	{
		garage_id: 205,
		part_id: 145,
		quantity: 431,
	},
	{
		garage_id: 205,
		part_id: 146,
		quantity: 84,
	},
	{
		garage_id: 205,
		part_id: 147,
		quantity: 326,
	},
	{
		garage_id: 205,
		part_id: 148,
		quantity: 168,
	},
	{
		garage_id: 205,
		part_id: 149,
		quantity: 18,
	},
	{
		garage_id: 205,
		part_id: 150,
		quantity: 20,
	},
	{
		garage_id: 205,
		part_id: 151,
		quantity: 398,
	},
	{
		garage_id: 205,
		part_id: 152,
		quantity: 209,
	},
	{
		garage_id: 205,
		part_id: 153,
		quantity: 426,
	},
	{
		garage_id: 205,
		part_id: 154,
		quantity: 358,
	},
	{
		garage_id: 205,
		part_id: 155,
		quantity: 360,
	},
	{
		garage_id: 205,
		part_id: 156,
		quantity: 203,
	},
	{
		garage_id: 205,
		part_id: 157,
		quantity: 71,
	},
	{
		garage_id: 205,
		part_id: 158,
		quantity: 296,
	},
	{
		garage_id: 205,
		part_id: 159,
		quantity: 313,
	},
	{
		garage_id: 205,
		part_id: 160,
		quantity: 460,
	},
	{
		garage_id: 205,
		part_id: 161,
		quantity: 142,
	},
	{
		garage_id: 205,
		part_id: 162,
		quantity: 497,
	},
	{
		garage_id: 205,
		part_id: 163,
		quantity: 190,
	},
	{
		garage_id: 205,
		part_id: 164,
		quantity: 99,
	},
	{
		garage_id: 205,
		part_id: 165,
		quantity: 453,
	},
	{
		garage_id: 205,
		part_id: 166,
		quantity: 74,
	},
	{
		garage_id: 205,
		part_id: 167,
		quantity: 399,
	},
	{
		garage_id: 205,
		part_id: 168,
		quantity: 259,
	},
	{
		garage_id: 205,
		part_id: 169,
		quantity: 210,
	},
	{
		garage_id: 205,
		part_id: 170,
		quantity: 359,
	},
	{
		garage_id: 205,
		part_id: 171,
		quantity: 137,
	},
	{
		garage_id: 205,
		part_id: 172,
		quantity: 148,
	},
	{
		garage_id: 205,
		part_id: 173,
		quantity: 171,
	},
	{
		garage_id: 205,
		part_id: 174,
		quantity: 301,
	},
	{
		garage_id: 205,
		part_id: 175,
		quantity: 66,
	},
	{
		garage_id: 205,
		part_id: 176,
		quantity: 160,
	},
	{
		garage_id: 205,
		part_id: 177,
		quantity: 263,
	},
	{
		garage_id: 205,
		part_id: 178,
		quantity: 495,
	},
	{
		garage_id: 205,
		part_id: 179,
		quantity: 84,
	},
	{
		garage_id: 205,
		part_id: 180,
		quantity: 91,
	},
	{
		garage_id: 205,
		part_id: 181,
		quantity: 107,
	},
	{
		garage_id: 205,
		part_id: 182,
		quantity: 248,
	},
	{
		garage_id: 205,
		part_id: 183,
		quantity: 105,
	},
	{
		garage_id: 205,
		part_id: 184,
		quantity: 70,
	},
	{
		garage_id: 205,
		part_id: 185,
		quantity: 330,
	},
	{
		garage_id: 205,
		part_id: 186,
		quantity: 252,
	},
	{
		garage_id: 205,
		part_id: 187,
		quantity: 493,
	},
	{
		garage_id: 205,
		part_id: 188,
		quantity: 270,
	},
	{
		garage_id: 205,
		part_id: 189,
		quantity: 407,
	},
	{
		garage_id: 205,
		part_id: 190,
		quantity: 129,
	},
	{
		garage_id: 205,
		part_id: 191,
		quantity: 438,
	},
	{
		garage_id: 205,
		part_id: 192,
		quantity: 188,
	},
	{
		garage_id: 205,
		part_id: 193,
		quantity: 42,
	},
	{
		garage_id: 205,
		part_id: 194,
		quantity: 429,
	},
	{
		garage_id: 205,
		part_id: 195,
		quantity: 37,
	},
	{
		garage_id: 205,
		part_id: 196,
		quantity: 258,
	},
	{
		garage_id: 205,
		part_id: 197,
		quantity: 40,
	},
	{
		garage_id: 205,
		part_id: 198,
		quantity: 119,
	},
	{
		garage_id: 205,
		part_id: 199,
		quantity: 160,
	},
	{
		garage_id: 205,
		part_id: 200,
		quantity: 143,
	},
	{
		garage_id: 205,
		part_id: 201,
		quantity: 34,
	},
	{
		garage_id: 205,
		part_id: 202,
		quantity: 45,
	},
	{
		garage_id: 205,
		part_id: 203,
		quantity: 446,
	},
	{
		garage_id: 205,
		part_id: 204,
		quantity: 486,
	},
	{
		garage_id: 205,
		part_id: 205,
		quantity: 248,
	},
	{
		garage_id: 205,
		part_id: 206,
		quantity: 345,
	},
	{
		garage_id: 205,
		part_id: 207,
		quantity: 379,
	},
	{
		garage_id: 205,
		part_id: 208,
		quantity: 298,
	},
	{
		garage_id: 205,
		part_id: 209,
		quantity: 133,
	},
	{
		garage_id: 205,
		part_id: 210,
		quantity: 341,
	},
	{
		garage_id: 205,
		part_id: 211,
		quantity: 329,
	},
	{
		garage_id: 205,
		part_id: 212,
		quantity: 27,
	},
	{
		garage_id: 205,
		part_id: 213,
		quantity: 338,
	},
	{
		garage_id: 205,
		part_id: 214,
		quantity: 475,
	},
	{
		garage_id: 205,
		part_id: 215,
		quantity: 308,
	},
	{
		garage_id: 205,
		part_id: 216,
		quantity: 243,
	},
	{
		garage_id: 205,
		part_id: 217,
		quantity: 349,
	},
	{
		garage_id: 205,
		part_id: 218,
		quantity: 224,
	},
	{
		garage_id: 205,
		part_id: 219,
		quantity: 90,
	},
	{
		garage_id: 205,
		part_id: 220,
		quantity: 364,
	},
	{
		garage_id: 205,
		part_id: 221,
		quantity: 235,
	},
	{
		garage_id: 205,
		part_id: 222,
		quantity: 10,
	},
	{
		garage_id: 205,
		part_id: 223,
		quantity: 235,
	},
	{
		garage_id: 205,
		part_id: 224,
		quantity: 496,
	},
	{
		garage_id: 205,
		part_id: 225,
		quantity: 356,
	},
	{
		garage_id: 205,
		part_id: 226,
		quantity: 115,
	},
	{
		garage_id: 205,
		part_id: 227,
		quantity: 224,
	},
	{
		garage_id: 205,
		part_id: 228,
		quantity: 352,
	},
	{
		garage_id: 205,
		part_id: 229,
		quantity: 392,
	},
	{
		garage_id: 205,
		part_id: 230,
		quantity: 160,
	},
	{
		garage_id: 205,
		part_id: 231,
		quantity: 212,
	},
	{
		garage_id: 205,
		part_id: 232,
		quantity: 408,
	},
	{
		garage_id: 205,
		part_id: 233,
		quantity: 61,
	},
	{
		garage_id: 205,
		part_id: 234,
		quantity: 446,
	},
	{
		garage_id: 205,
		part_id: 235,
		quantity: 440,
	},
	{
		garage_id: 205,
		part_id: 236,
		quantity: 491,
	},
	{
		garage_id: 205,
		part_id: 237,
		quantity: 186,
	},
	{
		garage_id: 205,
		part_id: 238,
		quantity: 336,
	},
	{
		garage_id: 205,
		part_id: 239,
		quantity: 17,
	},
	{
		garage_id: 205,
		part_id: 240,
		quantity: 336,
	},
	{
		garage_id: 205,
		part_id: 241,
		quantity: 32,
	},
	{
		garage_id: 205,
		part_id: 242,
		quantity: 97,
	},
	{
		garage_id: 205,
		part_id: 243,
		quantity: 86,
	},
	{
		garage_id: 205,
		part_id: 244,
		quantity: 173,
	},
	{
		garage_id: 205,
		part_id: 245,
		quantity: 76,
	},
	{
		garage_id: 205,
		part_id: 246,
		quantity: 482,
	},
	{
		garage_id: 205,
		part_id: 247,
		quantity: 319,
	},
	{
		garage_id: 205,
		part_id: 248,
		quantity: 312,
	},
	{
		garage_id: 205,
		part_id: 249,
		quantity: 495,
	},
	{
		garage_id: 205,
		part_id: 250,
		quantity: 226,
	},
	{
		garage_id: 205,
		part_id: 251,
		quantity: 305,
	},
	{
		garage_id: 205,
		part_id: 252,
		quantity: 153,
	},
	{
		garage_id: 205,
		part_id: 253,
		quantity: 474,
	},
	{
		garage_id: 205,
		part_id: 254,
		quantity: 207,
	},
	{
		garage_id: 205,
		part_id: 255,
		quantity: 281,
	},
	{
		garage_id: 205,
		part_id: 256,
		quantity: 50,
	},
	{
		garage_id: 205,
		part_id: 257,
		quantity: 196,
	},
	{
		garage_id: 205,
		part_id: 258,
		quantity: 467,
	},
	{
		garage_id: 205,
		part_id: 259,
		quantity: 321,
	},
	{
		garage_id: 205,
		part_id: 260,
		quantity: 370,
	},
	{
		garage_id: 205,
		part_id: 261,
		quantity: 64,
	},
	{
		garage_id: 205,
		part_id: 262,
		quantity: 49,
	},
	{
		garage_id: 205,
		part_id: 263,
		quantity: 439,
	},
	{
		garage_id: 205,
		part_id: 264,
		quantity: 461,
	},
	{
		garage_id: 205,
		part_id: 265,
		quantity: 94,
	},
	{
		garage_id: 205,
		part_id: 266,
		quantity: 37,
	},
	{
		garage_id: 205,
		part_id: 267,
		quantity: 190,
	},
	{
		garage_id: 205,
		part_id: 268,
		quantity: 63,
	},
	{
		garage_id: 205,
		part_id: 269,
		quantity: 440,
	},
	{
		garage_id: 205,
		part_id: 270,
		quantity: 56,
	},
	{
		garage_id: 205,
		part_id: 271,
		quantity: 158,
	},
	{
		garage_id: 205,
		part_id: 272,
		quantity: 246,
	},
	{
		garage_id: 205,
		part_id: 273,
		quantity: 438,
	},
	{
		garage_id: 205,
		part_id: 274,
		quantity: 399,
	},
	{
		garage_id: 205,
		part_id: 275,
		quantity: 436,
	},
	{
		garage_id: 205,
		part_id: 276,
		quantity: 151,
	},
	{
		garage_id: 205,
		part_id: 277,
		quantity: 363,
	},
	{
		garage_id: 205,
		part_id: 278,
		quantity: 395,
	},
	{
		garage_id: 205,
		part_id: 279,
		quantity: 258,
	},
	{
		garage_id: 205,
		part_id: 280,
		quantity: 68,
	},
	{
		garage_id: 205,
		part_id: 281,
		quantity: 459,
	},
	{
		garage_id: 205,
		part_id: 282,
		quantity: 466,
	},
	{
		garage_id: 205,
		part_id: 283,
		quantity: 216,
	},
	{
		garage_id: 205,
		part_id: 284,
		quantity: 269,
	},
	{
		garage_id: 205,
		part_id: 285,
		quantity: 132,
	},
	{
		garage_id: 205,
		part_id: 286,
		quantity: 252,
	},
	{
		garage_id: 205,
		part_id: 287,
		quantity: 222,
	},
	{
		garage_id: 205,
		part_id: 288,
		quantity: 293,
	},
	{
		garage_id: 205,
		part_id: 289,
		quantity: 256,
	},
	{
		garage_id: 205,
		part_id: 290,
		quantity: 383,
	},
	{
		garage_id: 205,
		part_id: 291,
		quantity: 169,
	},
	{
		garage_id: 205,
		part_id: 292,
		quantity: 171,
	},
	{
		garage_id: 205,
		part_id: 293,
		quantity: 447,
	},
	{
		garage_id: 205,
		part_id: 294,
		quantity: 168,
	},
	{
		garage_id: 205,
		part_id: 295,
		quantity: 75,
	},
	{
		garage_id: 205,
		part_id: 296,
		quantity: 367,
	},
	{
		garage_id: 205,
		part_id: 297,
		quantity: 64,
	},
	{
		garage_id: 205,
		part_id: 298,
		quantity: 385,
	},
	{
		garage_id: 205,
		part_id: 299,
		quantity: 492,
	},
	{
		garage_id: 205,
		part_id: 300,
		quantity: 1,
	},
	{
		garage_id: 205,
		part_id: 301,
		quantity: 329,
	},
	{
		garage_id: 205,
		part_id: 302,
		quantity: 312,
	},
	{
		garage_id: 205,
		part_id: 303,
		quantity: 206,
	},
	{
		garage_id: 205,
		part_id: 304,
		quantity: 262,
	},
	{
		garage_id: 205,
		part_id: 305,
		quantity: 451,
	},
	{
		garage_id: 205,
		part_id: 306,
		quantity: 337,
	},
	{
		garage_id: 205,
		part_id: 307,
		quantity: 460,
	},
	{
		garage_id: 205,
		part_id: 308,
		quantity: 52,
	},
	{
		garage_id: 205,
		part_id: 309,
		quantity: 113,
	},
	{
		garage_id: 205,
		part_id: 310,
		quantity: 328,
	},
	{
		garage_id: 205,
		part_id: 311,
		quantity: 13,
	},
	{
		garage_id: 205,
		part_id: 312,
		quantity: 451,
	},
	{
		garage_id: 205,
		part_id: 313,
		quantity: 390,
	},
	{
		garage_id: 205,
		part_id: 314,
		quantity: 116,
	},
	{
		garage_id: 205,
		part_id: 315,
		quantity: 330,
	},
	{
		garage_id: 205,
		part_id: 316,
		quantity: 422,
	},
	{
		garage_id: 205,
		part_id: 317,
		quantity: 284,
	},
	{
		garage_id: 205,
		part_id: 318,
		quantity: 297,
	},
	{
		garage_id: 205,
		part_id: 319,
		quantity: 127,
	},
	{
		garage_id: 205,
		part_id: 320,
		quantity: 37,
	},
	{
		garage_id: 205,
		part_id: 321,
		quantity: 419,
	},
	{
		garage_id: 205,
		part_id: 322,
		quantity: 158,
	},
	{
		garage_id: 205,
		part_id: 323,
		quantity: 195,
	},
	{
		garage_id: 205,
		part_id: 324,
		quantity: 382,
	},
	{
		garage_id: 205,
		part_id: 325,
		quantity: 280,
	},
	{
		garage_id: 205,
		part_id: 326,
		quantity: 461,
	},
	{
		garage_id: 205,
		part_id: 327,
		quantity: 116,
	},
	{
		garage_id: 205,
		part_id: 328,
		quantity: 51,
	},
	{
		garage_id: 205,
		part_id: 329,
		quantity: 95,
	},
	{
		garage_id: 205,
		part_id: 330,
		quantity: 399,
	},
	{
		garage_id: 205,
		part_id: 331,
		quantity: 325,
	},
	{
		garage_id: 205,
		part_id: 332,
		quantity: 382,
	},
	{
		garage_id: 205,
		part_id: 333,
		quantity: 270,
	},
	{
		garage_id: 205,
		part_id: 334,
		quantity: 89,
	},
	{
		garage_id: 205,
		part_id: 335,
		quantity: 30,
	},
	{
		garage_id: 205,
		part_id: 336,
		quantity: 472,
	},
	{
		garage_id: 205,
		part_id: 337,
		quantity: 159,
	},
	{
		garage_id: 205,
		part_id: 338,
		quantity: 255,
	},
	{
		garage_id: 205,
		part_id: 339,
		quantity: 5,
	},
	{
		garage_id: 205,
		part_id: 340,
		quantity: 343,
	},
	{
		garage_id: 205,
		part_id: 341,
		quantity: 292,
	},
	{
		garage_id: 205,
		part_id: 342,
		quantity: 40,
	},
	{
		garage_id: 205,
		part_id: 343,
		quantity: 458,
	},
	{
		garage_id: 205,
		part_id: 344,
		quantity: 251,
	},
	{
		garage_id: 205,
		part_id: 345,
		quantity: 189,
	},
	{
		garage_id: 205,
		part_id: 346,
		quantity: 343,
	},
	{
		garage_id: 205,
		part_id: 347,
		quantity: 43,
	},
	{
		garage_id: 205,
		part_id: 348,
		quantity: 55,
	},
	{
		garage_id: 205,
		part_id: 349,
		quantity: 93,
	},
	{
		garage_id: 205,
		part_id: 350,
		quantity: 148,
	},
	{
		garage_id: 205,
		part_id: 351,
		quantity: 138,
	},
	{
		garage_id: 205,
		part_id: 352,
		quantity: 161,
	},
	{
		garage_id: 205,
		part_id: 353,
		quantity: 386,
	},
	{
		garage_id: 205,
		part_id: 354,
		quantity: 321,
	},
	{
		garage_id: 205,
		part_id: 355,
		quantity: 347,
	},
	{
		garage_id: 205,
		part_id: 356,
		quantity: 199,
	},
	{
		garage_id: 205,
		part_id: 357,
		quantity: 405,
	},
	{
		garage_id: 205,
		part_id: 358,
		quantity: 187,
	},
	{
		garage_id: 205,
		part_id: 359,
		quantity: 278,
	},
	{
		garage_id: 205,
		part_id: 360,
		quantity: 455,
	},
	{
		garage_id: 205,
		part_id: 361,
		quantity: 210,
	},
	{
		garage_id: 205,
		part_id: 362,
		quantity: 477,
	},
	{
		garage_id: 205,
		part_id: 363,
		quantity: 277,
	},
	{
		garage_id: 205,
		part_id: 364,
		quantity: 11,
	},
	{
		garage_id: 205,
		part_id: 365,
		quantity: 143,
	},
	{
		garage_id: 205,
		part_id: 366,
		quantity: 406,
	},
	{
		garage_id: 205,
		part_id: 367,
		quantity: 166,
	},
	{
		garage_id: 205,
		part_id: 368,
		quantity: 115,
	},
	{
		garage_id: 205,
		part_id: 369,
		quantity: 343,
	},
	{
		garage_id: 205,
		part_id: 370,
		quantity: 117,
	},
	{
		garage_id: 205,
		part_id: 371,
		quantity: 212,
	},
	{
		garage_id: 205,
		part_id: 372,
		quantity: 47,
	},
	{
		garage_id: 205,
		part_id: 373,
		quantity: 384,
	},
	{
		garage_id: 205,
		part_id: 374,
		quantity: 373,
	},
	{
		garage_id: 205,
		part_id: 375,
		quantity: 72,
	},
	{
		garage_id: 205,
		part_id: 376,
		quantity: 79,
	},
	{
		garage_id: 205,
		part_id: 377,
		quantity: 308,
	},
	{
		garage_id: 205,
		part_id: 378,
		quantity: 90,
	},
	{
		garage_id: 205,
		part_id: 379,
		quantity: 42,
	},
	{
		garage_id: 205,
		part_id: 380,
		quantity: 229,
	},
	{
		garage_id: 205,
		part_id: 381,
		quantity: 65,
	},
	{
		garage_id: 205,
		part_id: 382,
		quantity: 119,
	},
	{
		garage_id: 205,
		part_id: 383,
		quantity: 170,
	},
	{
		garage_id: 205,
		part_id: 384,
		quantity: 390,
	},
	{
		garage_id: 205,
		part_id: 385,
		quantity: 371,
	},
	{
		garage_id: 205,
		part_id: 386,
		quantity: 371,
	},
	{
		garage_id: 205,
		part_id: 387,
		quantity: 158,
	},
	{
		garage_id: 205,
		part_id: 388,
		quantity: 41,
	},
	{
		garage_id: 205,
		part_id: 389,
		quantity: 140,
	},
	{
		garage_id: 205,
		part_id: 390,
		quantity: 422,
	},
	{
		garage_id: 205,
		part_id: 391,
		quantity: 397,
	},
	{
		garage_id: 205,
		part_id: 392,
		quantity: 446,
	},
	{
		garage_id: 205,
		part_id: 393,
		quantity: 116,
	},
	{
		garage_id: 205,
		part_id: 394,
		quantity: 43,
	},
	{
		garage_id: 205,
		part_id: 395,
		quantity: 438,
	},
	{
		garage_id: 205,
		part_id: 396,
		quantity: 84,
	},
	{
		garage_id: 205,
		part_id: 397,
		quantity: 61,
	},
	{
		garage_id: 205,
		part_id: 398,
		quantity: 454,
	},
	{
		garage_id: 205,
		part_id: 399,
		quantity: 102,
	},
	{
		garage_id: 206,
		part_id: 0,
		quantity: 447,
	},
	{
		garage_id: 206,
		part_id: 1,
		quantity: 335,
	},
	{
		garage_id: 206,
		part_id: 2,
		quantity: 210,
	},
	{
		garage_id: 206,
		part_id: 3,
		quantity: 87,
	},
	{
		garage_id: 206,
		part_id: 4,
		quantity: 474,
	},
	{
		garage_id: 206,
		part_id: 5,
		quantity: 350,
	},
	{
		garage_id: 206,
		part_id: 6,
		quantity: 311,
	},
	{
		garage_id: 206,
		part_id: 7,
		quantity: 224,
	},
	{
		garage_id: 206,
		part_id: 8,
		quantity: 292,
	},
	{
		garage_id: 206,
		part_id: 9,
		quantity: 416,
	},
	{
		garage_id: 206,
		part_id: 10,
		quantity: 53,
	},
	{
		garage_id: 206,
		part_id: 11,
		quantity: 356,
	},
	{
		garage_id: 206,
		part_id: 12,
		quantity: 175,
	},
	{
		garage_id: 206,
		part_id: 13,
		quantity: 232,
	},
	{
		garage_id: 206,
		part_id: 14,
		quantity: 370,
	},
	{
		garage_id: 206,
		part_id: 15,
		quantity: 286,
	},
	{
		garage_id: 206,
		part_id: 16,
		quantity: 166,
	},
	{
		garage_id: 206,
		part_id: 17,
		quantity: 375,
	},
	{
		garage_id: 206,
		part_id: 18,
		quantity: 493,
	},
	{
		garage_id: 206,
		part_id: 19,
		quantity: 476,
	},
	{
		garage_id: 206,
		part_id: 20,
		quantity: 20,
	},
	{
		garage_id: 206,
		part_id: 21,
		quantity: 67,
	},
	{
		garage_id: 206,
		part_id: 22,
		quantity: 348,
	},
	{
		garage_id: 206,
		part_id: 23,
		quantity: 262,
	},
	{
		garage_id: 206,
		part_id: 24,
		quantity: 56,
	},
	{
		garage_id: 206,
		part_id: 25,
		quantity: 80,
	},
	{
		garage_id: 206,
		part_id: 26,
		quantity: 128,
	},
	{
		garage_id: 206,
		part_id: 27,
		quantity: 154,
	},
	{
		garage_id: 206,
		part_id: 28,
		quantity: 300,
	},
	{
		garage_id: 206,
		part_id: 29,
		quantity: 212,
	},
	{
		garage_id: 206,
		part_id: 30,
		quantity: 110,
	},
	{
		garage_id: 206,
		part_id: 31,
		quantity: 371,
	},
	{
		garage_id: 206,
		part_id: 32,
		quantity: 457,
	},
	{
		garage_id: 206,
		part_id: 33,
		quantity: 244,
	},
	{
		garage_id: 206,
		part_id: 34,
		quantity: 298,
	},
	{
		garage_id: 206,
		part_id: 35,
		quantity: 224,
	},
	{
		garage_id: 206,
		part_id: 36,
		quantity: 433,
	},
	{
		garage_id: 206,
		part_id: 37,
		quantity: 273,
	},
	{
		garage_id: 206,
		part_id: 38,
		quantity: 476,
	},
	{
		garage_id: 206,
		part_id: 39,
		quantity: 57,
	},
	{
		garage_id: 206,
		part_id: 40,
		quantity: 134,
	},
	{
		garage_id: 206,
		part_id: 41,
		quantity: 64,
	},
	{
		garage_id: 206,
		part_id: 42,
		quantity: 252,
	},
	{
		garage_id: 206,
		part_id: 43,
		quantity: 327,
	},
	{
		garage_id: 206,
		part_id: 44,
		quantity: 438,
	},
	{
		garage_id: 206,
		part_id: 45,
		quantity: 251,
	},
	{
		garage_id: 206,
		part_id: 46,
		quantity: 365,
	},
	{
		garage_id: 206,
		part_id: 47,
		quantity: 217,
	},
	{
		garage_id: 206,
		part_id: 48,
		quantity: 200,
	},
	{
		garage_id: 206,
		part_id: 49,
		quantity: 436,
	},
	{
		garage_id: 206,
		part_id: 50,
		quantity: 366,
	},
	{
		garage_id: 206,
		part_id: 51,
		quantity: 102,
	},
	{
		garage_id: 206,
		part_id: 52,
		quantity: 22,
	},
	{
		garage_id: 206,
		part_id: 53,
		quantity: 141,
	},
	{
		garage_id: 206,
		part_id: 54,
		quantity: 163,
	},
	{
		garage_id: 206,
		part_id: 55,
		quantity: 139,
	},
	{
		garage_id: 206,
		part_id: 56,
		quantity: 356,
	},
	{
		garage_id: 206,
		part_id: 57,
		quantity: 218,
	},
	{
		garage_id: 206,
		part_id: 58,
		quantity: 53,
	},
	{
		garage_id: 206,
		part_id: 59,
		quantity: 178,
	},
	{
		garage_id: 206,
		part_id: 60,
		quantity: 426,
	},
	{
		garage_id: 206,
		part_id: 61,
		quantity: 430,
	},
	{
		garage_id: 206,
		part_id: 62,
		quantity: 324,
	},
	{
		garage_id: 206,
		part_id: 63,
		quantity: 351,
	},
	{
		garage_id: 206,
		part_id: 64,
		quantity: 476,
	},
	{
		garage_id: 206,
		part_id: 65,
		quantity: 183,
	},
	{
		garage_id: 206,
		part_id: 66,
		quantity: 0,
	},
	{
		garage_id: 206,
		part_id: 67,
		quantity: 373,
	},
	{
		garage_id: 206,
		part_id: 68,
		quantity: 437,
	},
	{
		garage_id: 206,
		part_id: 69,
		quantity: 200,
	},
	{
		garage_id: 206,
		part_id: 70,
		quantity: 421,
	},
	{
		garage_id: 206,
		part_id: 71,
		quantity: 250,
	},
	{
		garage_id: 206,
		part_id: 72,
		quantity: 296,
	},
	{
		garage_id: 206,
		part_id: 73,
		quantity: 309,
	},
	{
		garage_id: 206,
		part_id: 74,
		quantity: 397,
	},
	{
		garage_id: 206,
		part_id: 75,
		quantity: 335,
	},
	{
		garage_id: 206,
		part_id: 76,
		quantity: 0,
	},
	{
		garage_id: 206,
		part_id: 77,
		quantity: 27,
	},
	{
		garage_id: 206,
		part_id: 78,
		quantity: 491,
	},
	{
		garage_id: 206,
		part_id: 79,
		quantity: 213,
	},
	{
		garage_id: 206,
		part_id: 80,
		quantity: 382,
	},
	{
		garage_id: 206,
		part_id: 81,
		quantity: 251,
	},
	{
		garage_id: 206,
		part_id: 82,
		quantity: 126,
	},
	{
		garage_id: 206,
		part_id: 83,
		quantity: 89,
	},
	{
		garage_id: 206,
		part_id: 84,
		quantity: 59,
	},
	{
		garage_id: 206,
		part_id: 85,
		quantity: 296,
	},
	{
		garage_id: 206,
		part_id: 86,
		quantity: 426,
	},
	{
		garage_id: 206,
		part_id: 87,
		quantity: 65,
	},
	{
		garage_id: 206,
		part_id: 88,
		quantity: 338,
	},
	{
		garage_id: 206,
		part_id: 89,
		quantity: 202,
	},
	{
		garage_id: 206,
		part_id: 90,
		quantity: 379,
	},
	{
		garage_id: 206,
		part_id: 91,
		quantity: 329,
	},
	{
		garage_id: 206,
		part_id: 92,
		quantity: 5,
	},
	{
		garage_id: 206,
		part_id: 93,
		quantity: 388,
	},
	{
		garage_id: 206,
		part_id: 94,
		quantity: 497,
	},
	{
		garage_id: 206,
		part_id: 95,
		quantity: 114,
	},
	{
		garage_id: 206,
		part_id: 96,
		quantity: 193,
	},
	{
		garage_id: 206,
		part_id: 97,
		quantity: 1,
	},
	{
		garage_id: 206,
		part_id: 98,
		quantity: 112,
	},
	{
		garage_id: 206,
		part_id: 99,
		quantity: 471,
	},
	{
		garage_id: 206,
		part_id: 100,
		quantity: 214,
	},
	{
		garage_id: 206,
		part_id: 101,
		quantity: 104,
	},
	{
		garage_id: 206,
		part_id: 102,
		quantity: 490,
	},
	{
		garage_id: 206,
		part_id: 103,
		quantity: 163,
	},
	{
		garage_id: 206,
		part_id: 104,
		quantity: 304,
	},
	{
		garage_id: 206,
		part_id: 105,
		quantity: 162,
	},
	{
		garage_id: 206,
		part_id: 106,
		quantity: 423,
	},
	{
		garage_id: 206,
		part_id: 107,
		quantity: 294,
	},
	{
		garage_id: 206,
		part_id: 108,
		quantity: 231,
	},
	{
		garage_id: 206,
		part_id: 109,
		quantity: 385,
	},
	{
		garage_id: 206,
		part_id: 110,
		quantity: 53,
	},
	{
		garage_id: 206,
		part_id: 111,
		quantity: 82,
	},
	{
		garage_id: 206,
		part_id: 112,
		quantity: 357,
	},
	{
		garage_id: 206,
		part_id: 113,
		quantity: 139,
	},
	{
		garage_id: 206,
		part_id: 114,
		quantity: 230,
	},
	{
		garage_id: 206,
		part_id: 115,
		quantity: 140,
	},
	{
		garage_id: 206,
		part_id: 116,
		quantity: 256,
	},
	{
		garage_id: 206,
		part_id: 117,
		quantity: 107,
	},
	{
		garage_id: 206,
		part_id: 118,
		quantity: 373,
	},
	{
		garage_id: 206,
		part_id: 119,
		quantity: 344,
	},
	{
		garage_id: 206,
		part_id: 120,
		quantity: 426,
	},
	{
		garage_id: 206,
		part_id: 121,
		quantity: 139,
	},
	{
		garage_id: 206,
		part_id: 122,
		quantity: 488,
	},
	{
		garage_id: 206,
		part_id: 123,
		quantity: 347,
	},
	{
		garage_id: 206,
		part_id: 124,
		quantity: 370,
	},
	{
		garage_id: 206,
		part_id: 125,
		quantity: 219,
	},
	{
		garage_id: 206,
		part_id: 126,
		quantity: 106,
	},
	{
		garage_id: 206,
		part_id: 127,
		quantity: 192,
	},
	{
		garage_id: 206,
		part_id: 128,
		quantity: 466,
	},
	{
		garage_id: 206,
		part_id: 129,
		quantity: 48,
	},
	{
		garage_id: 206,
		part_id: 130,
		quantity: 295,
	},
	{
		garage_id: 206,
		part_id: 131,
		quantity: 195,
	},
	{
		garage_id: 206,
		part_id: 132,
		quantity: 92,
	},
	{
		garage_id: 206,
		part_id: 133,
		quantity: 12,
	},
	{
		garage_id: 206,
		part_id: 134,
		quantity: 285,
	},
	{
		garage_id: 206,
		part_id: 135,
		quantity: 409,
	},
	{
		garage_id: 206,
		part_id: 136,
		quantity: 200,
	},
	{
		garage_id: 206,
		part_id: 137,
		quantity: 127,
	},
	{
		garage_id: 206,
		part_id: 138,
		quantity: 379,
	},
	{
		garage_id: 206,
		part_id: 139,
		quantity: 309,
	},
	{
		garage_id: 206,
		part_id: 140,
		quantity: 13,
	},
	{
		garage_id: 206,
		part_id: 141,
		quantity: 82,
	},
	{
		garage_id: 206,
		part_id: 142,
		quantity: 468,
	},
	{
		garage_id: 206,
		part_id: 143,
		quantity: 417,
	},
	{
		garage_id: 206,
		part_id: 144,
		quantity: 166,
	},
	{
		garage_id: 206,
		part_id: 145,
		quantity: 285,
	},
	{
		garage_id: 206,
		part_id: 146,
		quantity: 29,
	},
	{
		garage_id: 206,
		part_id: 147,
		quantity: 195,
	},
	{
		garage_id: 206,
		part_id: 148,
		quantity: 51,
	},
	{
		garage_id: 206,
		part_id: 149,
		quantity: 277,
	},
	{
		garage_id: 206,
		part_id: 150,
		quantity: 228,
	},
	{
		garage_id: 206,
		part_id: 151,
		quantity: 35,
	},
	{
		garage_id: 206,
		part_id: 152,
		quantity: 365,
	},
	{
		garage_id: 206,
		part_id: 153,
		quantity: 411,
	},
	{
		garage_id: 206,
		part_id: 154,
		quantity: 356,
	},
	{
		garage_id: 206,
		part_id: 155,
		quantity: 369,
	},
	{
		garage_id: 206,
		part_id: 156,
		quantity: 404,
	},
	{
		garage_id: 206,
		part_id: 157,
		quantity: 214,
	},
	{
		garage_id: 206,
		part_id: 158,
		quantity: 297,
	},
	{
		garage_id: 206,
		part_id: 159,
		quantity: 486,
	},
	{
		garage_id: 206,
		part_id: 160,
		quantity: 460,
	},
	{
		garage_id: 206,
		part_id: 161,
		quantity: 245,
	},
	{
		garage_id: 206,
		part_id: 162,
		quantity: 468,
	},
	{
		garage_id: 206,
		part_id: 163,
		quantity: 376,
	},
	{
		garage_id: 206,
		part_id: 164,
		quantity: 144,
	},
	{
		garage_id: 206,
		part_id: 165,
		quantity: 231,
	},
	{
		garage_id: 206,
		part_id: 166,
		quantity: 74,
	},
	{
		garage_id: 206,
		part_id: 167,
		quantity: 234,
	},
	{
		garage_id: 206,
		part_id: 168,
		quantity: 361,
	},
	{
		garage_id: 206,
		part_id: 169,
		quantity: 293,
	},
	{
		garage_id: 206,
		part_id: 170,
		quantity: 188,
	},
	{
		garage_id: 206,
		part_id: 171,
		quantity: 442,
	},
	{
		garage_id: 206,
		part_id: 172,
		quantity: 74,
	},
	{
		garage_id: 206,
		part_id: 173,
		quantity: 8,
	},
	{
		garage_id: 206,
		part_id: 174,
		quantity: 239,
	},
	{
		garage_id: 206,
		part_id: 175,
		quantity: 321,
	},
	{
		garage_id: 206,
		part_id: 176,
		quantity: 403,
	},
	{
		garage_id: 206,
		part_id: 177,
		quantity: 405,
	},
	{
		garage_id: 206,
		part_id: 178,
		quantity: 353,
	},
	{
		garage_id: 206,
		part_id: 179,
		quantity: 342,
	},
	{
		garage_id: 206,
		part_id: 180,
		quantity: 254,
	},
	{
		garage_id: 206,
		part_id: 181,
		quantity: 99,
	},
	{
		garage_id: 206,
		part_id: 182,
		quantity: 462,
	},
	{
		garage_id: 206,
		part_id: 183,
		quantity: 252,
	},
	{
		garage_id: 206,
		part_id: 184,
		quantity: 206,
	},
	{
		garage_id: 206,
		part_id: 185,
		quantity: 430,
	},
	{
		garage_id: 206,
		part_id: 186,
		quantity: 428,
	},
	{
		garage_id: 206,
		part_id: 187,
		quantity: 484,
	},
	{
		garage_id: 206,
		part_id: 188,
		quantity: 286,
	},
	{
		garage_id: 206,
		part_id: 189,
		quantity: 274,
	},
	{
		garage_id: 206,
		part_id: 190,
		quantity: 190,
	},
	{
		garage_id: 206,
		part_id: 191,
		quantity: 312,
	},
	{
		garage_id: 206,
		part_id: 192,
		quantity: 67,
	},
	{
		garage_id: 206,
		part_id: 193,
		quantity: 354,
	},
	{
		garage_id: 206,
		part_id: 194,
		quantity: 138,
	},
	{
		garage_id: 206,
		part_id: 195,
		quantity: 163,
	},
	{
		garage_id: 206,
		part_id: 196,
		quantity: 119,
	},
	{
		garage_id: 206,
		part_id: 197,
		quantity: 390,
	},
	{
		garage_id: 206,
		part_id: 198,
		quantity: 331,
	},
	{
		garage_id: 206,
		part_id: 199,
		quantity: 150,
	},
	{
		garage_id: 206,
		part_id: 200,
		quantity: 63,
	},
	{
		garage_id: 206,
		part_id: 201,
		quantity: 119,
	},
	{
		garage_id: 206,
		part_id: 202,
		quantity: 120,
	},
	{
		garage_id: 206,
		part_id: 203,
		quantity: 473,
	},
	{
		garage_id: 206,
		part_id: 204,
		quantity: 4,
	},
	{
		garage_id: 206,
		part_id: 205,
		quantity: 296,
	},
	{
		garage_id: 206,
		part_id: 206,
		quantity: 153,
	},
	{
		garage_id: 206,
		part_id: 207,
		quantity: 131,
	},
	{
		garage_id: 206,
		part_id: 208,
		quantity: 87,
	},
	{
		garage_id: 206,
		part_id: 209,
		quantity: 219,
	},
	{
		garage_id: 206,
		part_id: 210,
		quantity: 287,
	},
	{
		garage_id: 206,
		part_id: 211,
		quantity: 240,
	},
	{
		garage_id: 206,
		part_id: 212,
		quantity: 405,
	},
	{
		garage_id: 206,
		part_id: 213,
		quantity: 368,
	},
	{
		garage_id: 206,
		part_id: 214,
		quantity: 292,
	},
	{
		garage_id: 206,
		part_id: 215,
		quantity: 460,
	},
	{
		garage_id: 206,
		part_id: 216,
		quantity: 446,
	},
	{
		garage_id: 206,
		part_id: 217,
		quantity: 182,
	},
	{
		garage_id: 206,
		part_id: 218,
		quantity: 248,
	},
	{
		garage_id: 206,
		part_id: 219,
		quantity: 66,
	},
	{
		garage_id: 206,
		part_id: 220,
		quantity: 470,
	},
	{
		garage_id: 206,
		part_id: 221,
		quantity: 218,
	},
	{
		garage_id: 206,
		part_id: 222,
		quantity: 321,
	},
	{
		garage_id: 206,
		part_id: 223,
		quantity: 290,
	},
	{
		garage_id: 206,
		part_id: 224,
		quantity: 477,
	},
	{
		garage_id: 206,
		part_id: 225,
		quantity: 376,
	},
	{
		garage_id: 206,
		part_id: 226,
		quantity: 335,
	},
	{
		garage_id: 206,
		part_id: 227,
		quantity: 15,
	},
	{
		garage_id: 206,
		part_id: 228,
		quantity: 220,
	},
	{
		garage_id: 206,
		part_id: 229,
		quantity: 284,
	},
	{
		garage_id: 206,
		part_id: 230,
		quantity: 137,
	},
	{
		garage_id: 206,
		part_id: 231,
		quantity: 299,
	},
	{
		garage_id: 206,
		part_id: 232,
		quantity: 463,
	},
	{
		garage_id: 206,
		part_id: 233,
		quantity: 254,
	},
	{
		garage_id: 206,
		part_id: 234,
		quantity: 313,
	},
	{
		garage_id: 206,
		part_id: 235,
		quantity: 55,
	},
	{
		garage_id: 206,
		part_id: 236,
		quantity: 468,
	},
	{
		garage_id: 206,
		part_id: 237,
		quantity: 425,
	},
	{
		garage_id: 206,
		part_id: 238,
		quantity: 147,
	},
	{
		garage_id: 206,
		part_id: 239,
		quantity: 286,
	},
	{
		garage_id: 206,
		part_id: 240,
		quantity: 189,
	},
	{
		garage_id: 206,
		part_id: 241,
		quantity: 147,
	},
	{
		garage_id: 206,
		part_id: 242,
		quantity: 48,
	},
	{
		garage_id: 206,
		part_id: 243,
		quantity: 103,
	},
	{
		garage_id: 206,
		part_id: 244,
		quantity: 230,
	},
	{
		garage_id: 206,
		part_id: 245,
		quantity: 327,
	},
	{
		garage_id: 206,
		part_id: 246,
		quantity: 136,
	},
	{
		garage_id: 206,
		part_id: 247,
		quantity: 179,
	},
	{
		garage_id: 206,
		part_id: 248,
		quantity: 261,
	},
	{
		garage_id: 206,
		part_id: 249,
		quantity: 282,
	},
	{
		garage_id: 206,
		part_id: 250,
		quantity: 90,
	},
	{
		garage_id: 206,
		part_id: 251,
		quantity: 388,
	},
	{
		garage_id: 206,
		part_id: 252,
		quantity: 350,
	},
	{
		garage_id: 206,
		part_id: 253,
		quantity: 6,
	},
	{
		garage_id: 206,
		part_id: 254,
		quantity: 399,
	},
	{
		garage_id: 206,
		part_id: 255,
		quantity: 260,
	},
	{
		garage_id: 206,
		part_id: 256,
		quantity: 383,
	},
	{
		garage_id: 206,
		part_id: 257,
		quantity: 179,
	},
	{
		garage_id: 206,
		part_id: 258,
		quantity: 485,
	},
	{
		garage_id: 206,
		part_id: 259,
		quantity: 424,
	},
	{
		garage_id: 206,
		part_id: 260,
		quantity: 303,
	},
	{
		garage_id: 206,
		part_id: 261,
		quantity: 187,
	},
	{
		garage_id: 206,
		part_id: 262,
		quantity: 40,
	},
	{
		garage_id: 206,
		part_id: 263,
		quantity: 62,
	},
	{
		garage_id: 206,
		part_id: 264,
		quantity: 75,
	},
	{
		garage_id: 206,
		part_id: 265,
		quantity: 64,
	},
	{
		garage_id: 206,
		part_id: 266,
		quantity: 214,
	},
	{
		garage_id: 206,
		part_id: 267,
		quantity: 334,
	},
	{
		garage_id: 206,
		part_id: 268,
		quantity: 155,
	},
	{
		garage_id: 206,
		part_id: 269,
		quantity: 257,
	},
	{
		garage_id: 206,
		part_id: 270,
		quantity: 395,
	},
	{
		garage_id: 206,
		part_id: 271,
		quantity: 11,
	},
	{
		garage_id: 206,
		part_id: 272,
		quantity: 47,
	},
	{
		garage_id: 206,
		part_id: 273,
		quantity: 377,
	},
	{
		garage_id: 206,
		part_id: 274,
		quantity: 269,
	},
	{
		garage_id: 206,
		part_id: 275,
		quantity: 465,
	},
	{
		garage_id: 206,
		part_id: 276,
		quantity: 437,
	},
	{
		garage_id: 206,
		part_id: 277,
		quantity: 85,
	},
	{
		garage_id: 206,
		part_id: 278,
		quantity: 484,
	},
	{
		garage_id: 206,
		part_id: 279,
		quantity: 73,
	},
	{
		garage_id: 206,
		part_id: 280,
		quantity: 194,
	},
	{
		garage_id: 206,
		part_id: 281,
		quantity: 90,
	},
	{
		garage_id: 206,
		part_id: 282,
		quantity: 146,
	},
	{
		garage_id: 206,
		part_id: 283,
		quantity: 476,
	},
	{
		garage_id: 206,
		part_id: 284,
		quantity: 344,
	},
	{
		garage_id: 206,
		part_id: 285,
		quantity: 119,
	},
	{
		garage_id: 206,
		part_id: 286,
		quantity: 121,
	},
	{
		garage_id: 206,
		part_id: 287,
		quantity: 320,
	},
	{
		garage_id: 206,
		part_id: 288,
		quantity: 126,
	},
	{
		garage_id: 206,
		part_id: 289,
		quantity: 435,
	},
	{
		garage_id: 206,
		part_id: 290,
		quantity: 108,
	},
	{
		garage_id: 206,
		part_id: 291,
		quantity: 356,
	},
	{
		garage_id: 206,
		part_id: 292,
		quantity: 446,
	},
	{
		garage_id: 206,
		part_id: 293,
		quantity: 159,
	},
	{
		garage_id: 206,
		part_id: 294,
		quantity: 90,
	},
	{
		garage_id: 206,
		part_id: 295,
		quantity: 277,
	},
	{
		garage_id: 206,
		part_id: 296,
		quantity: 304,
	},
	{
		garage_id: 206,
		part_id: 297,
		quantity: 331,
	},
	{
		garage_id: 206,
		part_id: 298,
		quantity: 94,
	},
	{
		garage_id: 206,
		part_id: 299,
		quantity: 420,
	},
	{
		garage_id: 206,
		part_id: 300,
		quantity: 465,
	},
	{
		garage_id: 206,
		part_id: 301,
		quantity: 427,
	},
	{
		garage_id: 206,
		part_id: 302,
		quantity: 423,
	},
	{
		garage_id: 206,
		part_id: 303,
		quantity: 166,
	},
	{
		garage_id: 206,
		part_id: 304,
		quantity: 420,
	},
	{
		garage_id: 206,
		part_id: 305,
		quantity: 499,
	},
	{
		garage_id: 206,
		part_id: 306,
		quantity: 484,
	},
	{
		garage_id: 206,
		part_id: 307,
		quantity: 195,
	},
	{
		garage_id: 206,
		part_id: 308,
		quantity: 131,
	},
	{
		garage_id: 206,
		part_id: 309,
		quantity: 470,
	},
	{
		garage_id: 206,
		part_id: 310,
		quantity: 271,
	},
	{
		garage_id: 206,
		part_id: 311,
		quantity: 50,
	},
	{
		garage_id: 206,
		part_id: 312,
		quantity: 252,
	},
	{
		garage_id: 206,
		part_id: 313,
		quantity: 125,
	},
	{
		garage_id: 206,
		part_id: 314,
		quantity: 477,
	},
	{
		garage_id: 206,
		part_id: 315,
		quantity: 81,
	},
	{
		garage_id: 206,
		part_id: 316,
		quantity: 135,
	},
	{
		garage_id: 206,
		part_id: 317,
		quantity: 445,
	},
	{
		garage_id: 206,
		part_id: 318,
		quantity: 118,
	},
	{
		garage_id: 206,
		part_id: 319,
		quantity: 210,
	},
	{
		garage_id: 206,
		part_id: 320,
		quantity: 265,
	},
	{
		garage_id: 206,
		part_id: 321,
		quantity: 237,
	},
	{
		garage_id: 206,
		part_id: 322,
		quantity: 49,
	},
	{
		garage_id: 206,
		part_id: 323,
		quantity: 399,
	},
	{
		garage_id: 206,
		part_id: 324,
		quantity: 304,
	},
	{
		garage_id: 206,
		part_id: 325,
		quantity: 430,
	},
	{
		garage_id: 206,
		part_id: 326,
		quantity: 134,
	},
	{
		garage_id: 206,
		part_id: 327,
		quantity: 305,
	},
	{
		garage_id: 206,
		part_id: 328,
		quantity: 94,
	},
	{
		garage_id: 206,
		part_id: 329,
		quantity: 111,
	},
	{
		garage_id: 206,
		part_id: 330,
		quantity: 151,
	},
	{
		garage_id: 206,
		part_id: 331,
		quantity: 299,
	},
	{
		garage_id: 206,
		part_id: 332,
		quantity: 142,
	},
	{
		garage_id: 206,
		part_id: 333,
		quantity: 258,
	},
	{
		garage_id: 206,
		part_id: 334,
		quantity: 321,
	},
	{
		garage_id: 206,
		part_id: 335,
		quantity: 65,
	},
	{
		garage_id: 206,
		part_id: 336,
		quantity: 216,
	},
	{
		garage_id: 206,
		part_id: 337,
		quantity: 3,
	},
	{
		garage_id: 206,
		part_id: 338,
		quantity: 456,
	},
	{
		garage_id: 206,
		part_id: 339,
		quantity: 357,
	},
	{
		garage_id: 206,
		part_id: 340,
		quantity: 343,
	},
	{
		garage_id: 206,
		part_id: 341,
		quantity: 300,
	},
	{
		garage_id: 206,
		part_id: 342,
		quantity: 317,
	},
	{
		garage_id: 206,
		part_id: 343,
		quantity: 406,
	},
	{
		garage_id: 206,
		part_id: 344,
		quantity: 440,
	},
	{
		garage_id: 206,
		part_id: 345,
		quantity: 469,
	},
	{
		garage_id: 206,
		part_id: 346,
		quantity: 379,
	},
	{
		garage_id: 206,
		part_id: 347,
		quantity: 60,
	},
	{
		garage_id: 206,
		part_id: 348,
		quantity: 139,
	},
	{
		garage_id: 206,
		part_id: 349,
		quantity: 71,
	},
	{
		garage_id: 206,
		part_id: 350,
		quantity: 189,
	},
	{
		garage_id: 206,
		part_id: 351,
		quantity: 214,
	},
	{
		garage_id: 206,
		part_id: 352,
		quantity: 157,
	},
	{
		garage_id: 206,
		part_id: 353,
		quantity: 184,
	},
	{
		garage_id: 206,
		part_id: 354,
		quantity: 464,
	},
	{
		garage_id: 206,
		part_id: 355,
		quantity: 426,
	},
	{
		garage_id: 206,
		part_id: 356,
		quantity: 157,
	},
	{
		garage_id: 206,
		part_id: 357,
		quantity: 298,
	},
	{
		garage_id: 206,
		part_id: 358,
		quantity: 61,
	},
	{
		garage_id: 206,
		part_id: 359,
		quantity: 252,
	},
	{
		garage_id: 206,
		part_id: 360,
		quantity: 230,
	},
	{
		garage_id: 206,
		part_id: 361,
		quantity: 444,
	},
	{
		garage_id: 206,
		part_id: 362,
		quantity: 251,
	},
	{
		garage_id: 206,
		part_id: 363,
		quantity: 183,
	},
	{
		garage_id: 206,
		part_id: 364,
		quantity: 10,
	},
	{
		garage_id: 206,
		part_id: 365,
		quantity: 308,
	},
	{
		garage_id: 206,
		part_id: 366,
		quantity: 77,
	},
	{
		garage_id: 206,
		part_id: 367,
		quantity: 92,
	},
	{
		garage_id: 206,
		part_id: 368,
		quantity: 418,
	},
	{
		garage_id: 206,
		part_id: 369,
		quantity: 135,
	},
	{
		garage_id: 206,
		part_id: 370,
		quantity: 118,
	},
	{
		garage_id: 206,
		part_id: 371,
		quantity: 437,
	},
	{
		garage_id: 206,
		part_id: 372,
		quantity: 464,
	},
	{
		garage_id: 206,
		part_id: 373,
		quantity: 345,
	},
	{
		garage_id: 206,
		part_id: 374,
		quantity: 359,
	},
	{
		garage_id: 206,
		part_id: 375,
		quantity: 364,
	},
	{
		garage_id: 206,
		part_id: 376,
		quantity: 117,
	},
	{
		garage_id: 206,
		part_id: 377,
		quantity: 3,
	},
	{
		garage_id: 206,
		part_id: 378,
		quantity: 18,
	},
	{
		garage_id: 206,
		part_id: 379,
		quantity: 459,
	},
	{
		garage_id: 206,
		part_id: 380,
		quantity: 95,
	},
	{
		garage_id: 206,
		part_id: 381,
		quantity: 195,
	},
	{
		garage_id: 206,
		part_id: 382,
		quantity: 112,
	},
	{
		garage_id: 206,
		part_id: 383,
		quantity: 262,
	},
	{
		garage_id: 206,
		part_id: 384,
		quantity: 355,
	},
	{
		garage_id: 206,
		part_id: 385,
		quantity: 496,
	},
	{
		garage_id: 206,
		part_id: 386,
		quantity: 482,
	},
	{
		garage_id: 206,
		part_id: 387,
		quantity: 235,
	},
	{
		garage_id: 206,
		part_id: 388,
		quantity: 326,
	},
	{
		garage_id: 206,
		part_id: 389,
		quantity: 304,
	},
	{
		garage_id: 206,
		part_id: 390,
		quantity: 173,
	},
	{
		garage_id: 206,
		part_id: 391,
		quantity: 374,
	},
	{
		garage_id: 206,
		part_id: 392,
		quantity: 219,
	},
	{
		garage_id: 206,
		part_id: 393,
		quantity: 185,
	},
	{
		garage_id: 206,
		part_id: 394,
		quantity: 66,
	},
	{
		garage_id: 206,
		part_id: 395,
		quantity: 298,
	},
	{
		garage_id: 206,
		part_id: 396,
		quantity: 237,
	},
	{
		garage_id: 206,
		part_id: 397,
		quantity: 252,
	},
	{
		garage_id: 206,
		part_id: 398,
		quantity: 277,
	},
	{
		garage_id: 206,
		part_id: 399,
		quantity: 374,
	},
	{
		garage_id: 207,
		part_id: 0,
		quantity: 179,
	},
	{
		garage_id: 207,
		part_id: 1,
		quantity: 229,
	},
	{
		garage_id: 207,
		part_id: 2,
		quantity: 21,
	},
	{
		garage_id: 207,
		part_id: 3,
		quantity: 497,
	},
	{
		garage_id: 207,
		part_id: 4,
		quantity: 175,
	},
	{
		garage_id: 207,
		part_id: 5,
		quantity: 413,
	},
	{
		garage_id: 207,
		part_id: 6,
		quantity: 62,
	},
	{
		garage_id: 207,
		part_id: 7,
		quantity: 56,
	},
	{
		garage_id: 207,
		part_id: 8,
		quantity: 13,
	},
	{
		garage_id: 207,
		part_id: 9,
		quantity: 36,
	},
	{
		garage_id: 207,
		part_id: 10,
		quantity: 316,
	},
	{
		garage_id: 207,
		part_id: 11,
		quantity: 159,
	},
	{
		garage_id: 207,
		part_id: 12,
		quantity: 163,
	},
	{
		garage_id: 207,
		part_id: 13,
		quantity: 425,
	},
	{
		garage_id: 207,
		part_id: 14,
		quantity: 204,
	},
	{
		garage_id: 207,
		part_id: 15,
		quantity: 468,
	},
	{
		garage_id: 207,
		part_id: 16,
		quantity: 478,
	},
	{
		garage_id: 207,
		part_id: 17,
		quantity: 458,
	},
	{
		garage_id: 207,
		part_id: 18,
		quantity: 64,
	},
	{
		garage_id: 207,
		part_id: 19,
		quantity: 40,
	},
	{
		garage_id: 207,
		part_id: 20,
		quantity: 125,
	},
	{
		garage_id: 207,
		part_id: 21,
		quantity: 404,
	},
	{
		garage_id: 207,
		part_id: 22,
		quantity: 97,
	},
	{
		garage_id: 207,
		part_id: 23,
		quantity: 500,
	},
	{
		garage_id: 207,
		part_id: 24,
		quantity: 290,
	},
	{
		garage_id: 207,
		part_id: 25,
		quantity: 394,
	},
	{
		garage_id: 207,
		part_id: 26,
		quantity: 361,
	},
	{
		garage_id: 207,
		part_id: 27,
		quantity: 129,
	},
	{
		garage_id: 207,
		part_id: 28,
		quantity: 99,
	},
	{
		garage_id: 207,
		part_id: 29,
		quantity: 499,
	},
	{
		garage_id: 207,
		part_id: 30,
		quantity: 233,
	},
	{
		garage_id: 207,
		part_id: 31,
		quantity: 252,
	},
	{
		garage_id: 207,
		part_id: 32,
		quantity: 464,
	},
	{
		garage_id: 207,
		part_id: 33,
		quantity: 189,
	},
	{
		garage_id: 207,
		part_id: 34,
		quantity: 6,
	},
	{
		garage_id: 207,
		part_id: 35,
		quantity: 310,
	},
	{
		garage_id: 207,
		part_id: 36,
		quantity: 131,
	},
	{
		garage_id: 207,
		part_id: 37,
		quantity: 409,
	},
	{
		garage_id: 207,
		part_id: 38,
		quantity: 76,
	},
	{
		garage_id: 207,
		part_id: 39,
		quantity: 473,
	},
	{
		garage_id: 207,
		part_id: 40,
		quantity: 298,
	},
	{
		garage_id: 207,
		part_id: 41,
		quantity: 137,
	},
	{
		garage_id: 207,
		part_id: 42,
		quantity: 499,
	},
	{
		garage_id: 207,
		part_id: 43,
		quantity: 83,
	},
	{
		garage_id: 207,
		part_id: 44,
		quantity: 295,
	},
	{
		garage_id: 207,
		part_id: 45,
		quantity: 133,
	},
	{
		garage_id: 207,
		part_id: 46,
		quantity: 491,
	},
	{
		garage_id: 207,
		part_id: 47,
		quantity: 405,
	},
	{
		garage_id: 207,
		part_id: 48,
		quantity: 73,
	},
	{
		garage_id: 207,
		part_id: 49,
		quantity: 442,
	},
	{
		garage_id: 207,
		part_id: 50,
		quantity: 434,
	},
	{
		garage_id: 207,
		part_id: 51,
		quantity: 180,
	},
	{
		garage_id: 207,
		part_id: 52,
		quantity: 81,
	},
	{
		garage_id: 207,
		part_id: 53,
		quantity: 50,
	},
	{
		garage_id: 207,
		part_id: 54,
		quantity: 311,
	},
	{
		garage_id: 207,
		part_id: 55,
		quantity: 236,
	},
	{
		garage_id: 207,
		part_id: 56,
		quantity: 28,
	},
	{
		garage_id: 207,
		part_id: 57,
		quantity: 160,
	},
	{
		garage_id: 207,
		part_id: 58,
		quantity: 114,
	},
	{
		garage_id: 207,
		part_id: 59,
		quantity: 354,
	},
	{
		garage_id: 207,
		part_id: 60,
		quantity: 459,
	},
	{
		garage_id: 207,
		part_id: 61,
		quantity: 30,
	},
	{
		garage_id: 207,
		part_id: 62,
		quantity: 136,
	},
	{
		garage_id: 207,
		part_id: 63,
		quantity: 304,
	},
	{
		garage_id: 207,
		part_id: 64,
		quantity: 30,
	},
	{
		garage_id: 207,
		part_id: 65,
		quantity: 322,
	},
	{
		garage_id: 207,
		part_id: 66,
		quantity: 369,
	},
	{
		garage_id: 207,
		part_id: 67,
		quantity: 42,
	},
	{
		garage_id: 207,
		part_id: 68,
		quantity: 431,
	},
	{
		garage_id: 207,
		part_id: 69,
		quantity: 178,
	},
	{
		garage_id: 207,
		part_id: 70,
		quantity: 496,
	},
	{
		garage_id: 207,
		part_id: 71,
		quantity: 484,
	},
	{
		garage_id: 207,
		part_id: 72,
		quantity: 367,
	},
	{
		garage_id: 207,
		part_id: 73,
		quantity: 339,
	},
	{
		garage_id: 207,
		part_id: 74,
		quantity: 119,
	},
	{
		garage_id: 207,
		part_id: 75,
		quantity: 459,
	},
	{
		garage_id: 207,
		part_id: 76,
		quantity: 165,
	},
	{
		garage_id: 207,
		part_id: 77,
		quantity: 326,
	},
	{
		garage_id: 207,
		part_id: 78,
		quantity: 320,
	},
	{
		garage_id: 207,
		part_id: 79,
		quantity: 381,
	},
	{
		garage_id: 207,
		part_id: 80,
		quantity: 408,
	},
	{
		garage_id: 207,
		part_id: 81,
		quantity: 303,
	},
	{
		garage_id: 207,
		part_id: 82,
		quantity: 139,
	},
	{
		garage_id: 207,
		part_id: 83,
		quantity: 345,
	},
	{
		garage_id: 207,
		part_id: 84,
		quantity: 134,
	},
	{
		garage_id: 207,
		part_id: 85,
		quantity: 464,
	},
	{
		garage_id: 207,
		part_id: 86,
		quantity: 252,
	},
	{
		garage_id: 207,
		part_id: 87,
		quantity: 486,
	},
	{
		garage_id: 207,
		part_id: 88,
		quantity: 364,
	},
	{
		garage_id: 207,
		part_id: 89,
		quantity: 67,
	},
	{
		garage_id: 207,
		part_id: 90,
		quantity: 76,
	},
	{
		garage_id: 207,
		part_id: 91,
		quantity: 189,
	},
	{
		garage_id: 207,
		part_id: 92,
		quantity: 35,
	},
	{
		garage_id: 207,
		part_id: 93,
		quantity: 388,
	},
	{
		garage_id: 207,
		part_id: 94,
		quantity: 375,
	},
	{
		garage_id: 207,
		part_id: 95,
		quantity: 341,
	},
	{
		garage_id: 207,
		part_id: 96,
		quantity: 394,
	},
	{
		garage_id: 207,
		part_id: 97,
		quantity: 255,
	},
	{
		garage_id: 207,
		part_id: 98,
		quantity: 51,
	},
	{
		garage_id: 207,
		part_id: 99,
		quantity: 101,
	},
	{
		garage_id: 207,
		part_id: 100,
		quantity: 399,
	},
	{
		garage_id: 207,
		part_id: 101,
		quantity: 491,
	},
	{
		garage_id: 207,
		part_id: 102,
		quantity: 272,
	},
	{
		garage_id: 207,
		part_id: 103,
		quantity: 218,
	},
	{
		garage_id: 207,
		part_id: 104,
		quantity: 178,
	},
	{
		garage_id: 207,
		part_id: 105,
		quantity: 361,
	},
	{
		garage_id: 207,
		part_id: 106,
		quantity: 237,
	},
	{
		garage_id: 207,
		part_id: 107,
		quantity: 70,
	},
	{
		garage_id: 207,
		part_id: 108,
		quantity: 496,
	},
	{
		garage_id: 207,
		part_id: 109,
		quantity: 255,
	},
	{
		garage_id: 207,
		part_id: 110,
		quantity: 142,
	},
	{
		garage_id: 207,
		part_id: 111,
		quantity: 413,
	},
	{
		garage_id: 207,
		part_id: 112,
		quantity: 286,
	},
	{
		garage_id: 207,
		part_id: 113,
		quantity: 173,
	},
	{
		garage_id: 207,
		part_id: 114,
		quantity: 416,
	},
	{
		garage_id: 207,
		part_id: 115,
		quantity: 229,
	},
	{
		garage_id: 207,
		part_id: 116,
		quantity: 210,
	},
	{
		garage_id: 207,
		part_id: 117,
		quantity: 241,
	},
	{
		garage_id: 207,
		part_id: 118,
		quantity: 179,
	},
	{
		garage_id: 207,
		part_id: 119,
		quantity: 148,
	},
	{
		garage_id: 207,
		part_id: 120,
		quantity: 384,
	},
	{
		garage_id: 207,
		part_id: 121,
		quantity: 433,
	},
	{
		garage_id: 207,
		part_id: 122,
		quantity: 268,
	},
	{
		garage_id: 207,
		part_id: 123,
		quantity: 87,
	},
	{
		garage_id: 207,
		part_id: 124,
		quantity: 482,
	},
	{
		garage_id: 207,
		part_id: 125,
		quantity: 278,
	},
	{
		garage_id: 207,
		part_id: 126,
		quantity: 166,
	},
	{
		garage_id: 207,
		part_id: 127,
		quantity: 465,
	},
	{
		garage_id: 207,
		part_id: 128,
		quantity: 360,
	},
	{
		garage_id: 207,
		part_id: 129,
		quantity: 218,
	},
	{
		garage_id: 207,
		part_id: 130,
		quantity: 375,
	},
	{
		garage_id: 207,
		part_id: 131,
		quantity: 20,
	},
	{
		garage_id: 207,
		part_id: 132,
		quantity: 66,
	},
	{
		garage_id: 207,
		part_id: 133,
		quantity: 476,
	},
	{
		garage_id: 207,
		part_id: 134,
		quantity: 208,
	},
	{
		garage_id: 207,
		part_id: 135,
		quantity: 436,
	},
	{
		garage_id: 207,
		part_id: 136,
		quantity: 349,
	},
	{
		garage_id: 207,
		part_id: 137,
		quantity: 82,
	},
	{
		garage_id: 207,
		part_id: 138,
		quantity: 263,
	},
	{
		garage_id: 207,
		part_id: 139,
		quantity: 218,
	},
	{
		garage_id: 207,
		part_id: 140,
		quantity: 238,
	},
	{
		garage_id: 207,
		part_id: 141,
		quantity: 215,
	},
	{
		garage_id: 207,
		part_id: 142,
		quantity: 419,
	},
	{
		garage_id: 207,
		part_id: 143,
		quantity: 12,
	},
	{
		garage_id: 207,
		part_id: 144,
		quantity: 33,
	},
	{
		garage_id: 207,
		part_id: 145,
		quantity: 159,
	},
	{
		garage_id: 207,
		part_id: 146,
		quantity: 12,
	},
	{
		garage_id: 207,
		part_id: 147,
		quantity: 221,
	},
	{
		garage_id: 207,
		part_id: 148,
		quantity: 411,
	},
	{
		garage_id: 207,
		part_id: 149,
		quantity: 309,
	},
	{
		garage_id: 207,
		part_id: 150,
		quantity: 193,
	},
	{
		garage_id: 207,
		part_id: 151,
		quantity: 272,
	},
	{
		garage_id: 207,
		part_id: 152,
		quantity: 205,
	},
	{
		garage_id: 207,
		part_id: 153,
		quantity: 71,
	},
	{
		garage_id: 207,
		part_id: 154,
		quantity: 340,
	},
	{
		garage_id: 207,
		part_id: 155,
		quantity: 231,
	},
	{
		garage_id: 207,
		part_id: 156,
		quantity: 494,
	},
	{
		garage_id: 207,
		part_id: 157,
		quantity: 177,
	},
	{
		garage_id: 207,
		part_id: 158,
		quantity: 12,
	},
	{
		garage_id: 207,
		part_id: 159,
		quantity: 208,
	},
	{
		garage_id: 207,
		part_id: 160,
		quantity: 83,
	},
	{
		garage_id: 207,
		part_id: 161,
		quantity: 37,
	},
	{
		garage_id: 207,
		part_id: 162,
		quantity: 495,
	},
	{
		garage_id: 207,
		part_id: 163,
		quantity: 331,
	},
	{
		garage_id: 207,
		part_id: 164,
		quantity: 176,
	},
	{
		garage_id: 207,
		part_id: 165,
		quantity: 18,
	},
	{
		garage_id: 207,
		part_id: 166,
		quantity: 424,
	},
	{
		garage_id: 207,
		part_id: 167,
		quantity: 476,
	},
	{
		garage_id: 207,
		part_id: 168,
		quantity: 82,
	},
	{
		garage_id: 207,
		part_id: 169,
		quantity: 19,
	},
	{
		garage_id: 207,
		part_id: 170,
		quantity: 186,
	},
	{
		garage_id: 207,
		part_id: 171,
		quantity: 184,
	},
	{
		garage_id: 207,
		part_id: 172,
		quantity: 404,
	},
	{
		garage_id: 207,
		part_id: 173,
		quantity: 57,
	},
	{
		garage_id: 207,
		part_id: 174,
		quantity: 46,
	},
	{
		garage_id: 207,
		part_id: 175,
		quantity: 378,
	},
	{
		garage_id: 207,
		part_id: 176,
		quantity: 157,
	},
	{
		garage_id: 207,
		part_id: 177,
		quantity: 102,
	},
	{
		garage_id: 207,
		part_id: 178,
		quantity: 229,
	},
	{
		garage_id: 207,
		part_id: 179,
		quantity: 44,
	},
	{
		garage_id: 207,
		part_id: 180,
		quantity: 310,
	},
	{
		garage_id: 207,
		part_id: 181,
		quantity: 319,
	},
	{
		garage_id: 207,
		part_id: 182,
		quantity: 404,
	},
	{
		garage_id: 207,
		part_id: 183,
		quantity: 78,
	},
	{
		garage_id: 207,
		part_id: 184,
		quantity: 296,
	},
	{
		garage_id: 207,
		part_id: 185,
		quantity: 139,
	},
	{
		garage_id: 207,
		part_id: 186,
		quantity: 466,
	},
	{
		garage_id: 207,
		part_id: 187,
		quantity: 414,
	},
	{
		garage_id: 207,
		part_id: 188,
		quantity: 270,
	},
	{
		garage_id: 207,
		part_id: 189,
		quantity: 83,
	},
	{
		garage_id: 207,
		part_id: 190,
		quantity: 485,
	},
	{
		garage_id: 207,
		part_id: 191,
		quantity: 473,
	},
	{
		garage_id: 207,
		part_id: 192,
		quantity: 150,
	},
	{
		garage_id: 207,
		part_id: 193,
		quantity: 84,
	},
	{
		garage_id: 207,
		part_id: 194,
		quantity: 185,
	},
	{
		garage_id: 207,
		part_id: 195,
		quantity: 134,
	},
	{
		garage_id: 207,
		part_id: 196,
		quantity: 359,
	},
	{
		garage_id: 207,
		part_id: 197,
		quantity: 476,
	},
	{
		garage_id: 207,
		part_id: 198,
		quantity: 147,
	},
	{
		garage_id: 207,
		part_id: 199,
		quantity: 307,
	},
	{
		garage_id: 207,
		part_id: 200,
		quantity: 465,
	},
	{
		garage_id: 207,
		part_id: 201,
		quantity: 239,
	},
	{
		garage_id: 207,
		part_id: 202,
		quantity: 146,
	},
	{
		garage_id: 207,
		part_id: 203,
		quantity: 471,
	},
	{
		garage_id: 207,
		part_id: 204,
		quantity: 476,
	},
	{
		garage_id: 207,
		part_id: 205,
		quantity: 165,
	},
	{
		garage_id: 207,
		part_id: 206,
		quantity: 31,
	},
	{
		garage_id: 207,
		part_id: 207,
		quantity: 286,
	},
	{
		garage_id: 207,
		part_id: 208,
		quantity: 76,
	},
	{
		garage_id: 207,
		part_id: 209,
		quantity: 133,
	},
	{
		garage_id: 207,
		part_id: 210,
		quantity: 297,
	},
	{
		garage_id: 207,
		part_id: 211,
		quantity: 412,
	},
	{
		garage_id: 207,
		part_id: 212,
		quantity: 464,
	},
	{
		garage_id: 207,
		part_id: 213,
		quantity: 182,
	},
	{
		garage_id: 207,
		part_id: 214,
		quantity: 499,
	},
	{
		garage_id: 207,
		part_id: 215,
		quantity: 354,
	},
	{
		garage_id: 207,
		part_id: 216,
		quantity: 217,
	},
	{
		garage_id: 207,
		part_id: 217,
		quantity: 7,
	},
	{
		garage_id: 207,
		part_id: 218,
		quantity: 439,
	},
	{
		garage_id: 207,
		part_id: 219,
		quantity: 466,
	},
	{
		garage_id: 207,
		part_id: 220,
		quantity: 432,
	},
	{
		garage_id: 207,
		part_id: 221,
		quantity: 253,
	},
	{
		garage_id: 207,
		part_id: 222,
		quantity: 80,
	},
	{
		garage_id: 207,
		part_id: 223,
		quantity: 96,
	},
	{
		garage_id: 207,
		part_id: 224,
		quantity: 36,
	},
	{
		garage_id: 207,
		part_id: 225,
		quantity: 476,
	},
	{
		garage_id: 207,
		part_id: 226,
		quantity: 95,
	},
	{
		garage_id: 207,
		part_id: 227,
		quantity: 419,
	},
	{
		garage_id: 207,
		part_id: 228,
		quantity: 130,
	},
	{
		garage_id: 207,
		part_id: 229,
		quantity: 244,
	},
	{
		garage_id: 207,
		part_id: 230,
		quantity: 273,
	},
	{
		garage_id: 207,
		part_id: 231,
		quantity: 448,
	},
	{
		garage_id: 207,
		part_id: 232,
		quantity: 249,
	},
	{
		garage_id: 207,
		part_id: 233,
		quantity: 324,
	},
	{
		garage_id: 207,
		part_id: 234,
		quantity: 299,
	},
	{
		garage_id: 207,
		part_id: 235,
		quantity: 449,
	},
	{
		garage_id: 207,
		part_id: 236,
		quantity: 101,
	},
	{
		garage_id: 207,
		part_id: 237,
		quantity: 381,
	},
	{
		garage_id: 207,
		part_id: 238,
		quantity: 220,
	},
	{
		garage_id: 207,
		part_id: 239,
		quantity: 405,
	},
	{
		garage_id: 207,
		part_id: 240,
		quantity: 254,
	},
	{
		garage_id: 207,
		part_id: 241,
		quantity: 322,
	},
	{
		garage_id: 207,
		part_id: 242,
		quantity: 221,
	},
	{
		garage_id: 207,
		part_id: 243,
		quantity: 206,
	},
	{
		garage_id: 207,
		part_id: 244,
		quantity: 9,
	},
	{
		garage_id: 207,
		part_id: 245,
		quantity: 344,
	},
	{
		garage_id: 207,
		part_id: 246,
		quantity: 382,
	},
	{
		garage_id: 207,
		part_id: 247,
		quantity: 73,
	},
	{
		garage_id: 207,
		part_id: 248,
		quantity: 207,
	},
	{
		garage_id: 207,
		part_id: 249,
		quantity: 360,
	},
	{
		garage_id: 207,
		part_id: 250,
		quantity: 25,
	},
	{
		garage_id: 207,
		part_id: 251,
		quantity: 95,
	},
	{
		garage_id: 207,
		part_id: 252,
		quantity: 460,
	},
	{
		garage_id: 207,
		part_id: 253,
		quantity: 370,
	},
	{
		garage_id: 207,
		part_id: 254,
		quantity: 320,
	},
	{
		garage_id: 207,
		part_id: 255,
		quantity: 334,
	},
	{
		garage_id: 207,
		part_id: 256,
		quantity: 310,
	},
	{
		garage_id: 207,
		part_id: 257,
		quantity: 155,
	},
	{
		garage_id: 207,
		part_id: 258,
		quantity: 260,
	},
	{
		garage_id: 207,
		part_id: 259,
		quantity: 423,
	},
	{
		garage_id: 207,
		part_id: 260,
		quantity: 323,
	},
	{
		garage_id: 207,
		part_id: 261,
		quantity: 81,
	},
	{
		garage_id: 207,
		part_id: 262,
		quantity: 402,
	},
	{
		garage_id: 207,
		part_id: 263,
		quantity: 438,
	},
	{
		garage_id: 207,
		part_id: 264,
		quantity: 183,
	},
	{
		garage_id: 207,
		part_id: 265,
		quantity: 447,
	},
	{
		garage_id: 207,
		part_id: 266,
		quantity: 27,
	},
	{
		garage_id: 207,
		part_id: 267,
		quantity: 36,
	},
	{
		garage_id: 207,
		part_id: 268,
		quantity: 137,
	},
	{
		garage_id: 207,
		part_id: 269,
		quantity: 235,
	},
	{
		garage_id: 207,
		part_id: 270,
		quantity: 268,
	},
	{
		garage_id: 207,
		part_id: 271,
		quantity: 389,
	},
	{
		garage_id: 207,
		part_id: 272,
		quantity: 329,
	},
	{
		garage_id: 207,
		part_id: 273,
		quantity: 51,
	},
	{
		garage_id: 207,
		part_id: 274,
		quantity: 105,
	},
	{
		garage_id: 207,
		part_id: 275,
		quantity: 458,
	},
	{
		garage_id: 207,
		part_id: 276,
		quantity: 310,
	},
	{
		garage_id: 207,
		part_id: 277,
		quantity: 84,
	},
	{
		garage_id: 207,
		part_id: 278,
		quantity: 119,
	},
	{
		garage_id: 207,
		part_id: 279,
		quantity: 125,
	},
	{
		garage_id: 207,
		part_id: 280,
		quantity: 91,
	},
	{
		garage_id: 207,
		part_id: 281,
		quantity: 365,
	},
	{
		garage_id: 207,
		part_id: 282,
		quantity: 463,
	},
	{
		garage_id: 207,
		part_id: 283,
		quantity: 476,
	},
	{
		garage_id: 207,
		part_id: 284,
		quantity: 20,
	},
	{
		garage_id: 207,
		part_id: 285,
		quantity: 310,
	},
	{
		garage_id: 207,
		part_id: 286,
		quantity: 397,
	},
	{
		garage_id: 207,
		part_id: 287,
		quantity: 115,
	},
	{
		garage_id: 207,
		part_id: 288,
		quantity: 246,
	},
	{
		garage_id: 207,
		part_id: 289,
		quantity: 353,
	},
	{
		garage_id: 207,
		part_id: 290,
		quantity: 0,
	},
	{
		garage_id: 207,
		part_id: 291,
		quantity: 402,
	},
	{
		garage_id: 207,
		part_id: 292,
		quantity: 174,
	},
	{
		garage_id: 207,
		part_id: 293,
		quantity: 251,
	},
	{
		garage_id: 207,
		part_id: 294,
		quantity: 55,
	},
	{
		garage_id: 207,
		part_id: 295,
		quantity: 219,
	},
	{
		garage_id: 207,
		part_id: 296,
		quantity: 98,
	},
	{
		garage_id: 207,
		part_id: 297,
		quantity: 375,
	},
	{
		garage_id: 207,
		part_id: 298,
		quantity: 163,
	},
	{
		garage_id: 207,
		part_id: 299,
		quantity: 193,
	},
	{
		garage_id: 207,
		part_id: 300,
		quantity: 311,
	},
	{
		garage_id: 207,
		part_id: 301,
		quantity: 223,
	},
	{
		garage_id: 207,
		part_id: 302,
		quantity: 271,
	},
	{
		garage_id: 207,
		part_id: 303,
		quantity: 467,
	},
	{
		garage_id: 207,
		part_id: 304,
		quantity: 222,
	},
	{
		garage_id: 207,
		part_id: 305,
		quantity: 203,
	},
	{
		garage_id: 207,
		part_id: 306,
		quantity: 498,
	},
	{
		garage_id: 207,
		part_id: 307,
		quantity: 306,
	},
	{
		garage_id: 207,
		part_id: 308,
		quantity: 480,
	},
	{
		garage_id: 207,
		part_id: 309,
		quantity: 341,
	},
	{
		garage_id: 207,
		part_id: 310,
		quantity: 189,
	},
	{
		garage_id: 207,
		part_id: 311,
		quantity: 72,
	},
	{
		garage_id: 207,
		part_id: 312,
		quantity: 252,
	},
	{
		garage_id: 207,
		part_id: 313,
		quantity: 11,
	},
	{
		garage_id: 207,
		part_id: 314,
		quantity: 135,
	},
	{
		garage_id: 207,
		part_id: 315,
		quantity: 8,
	},
	{
		garage_id: 207,
		part_id: 316,
		quantity: 173,
	},
	{
		garage_id: 207,
		part_id: 317,
		quantity: 479,
	},
	{
		garage_id: 207,
		part_id: 318,
		quantity: 64,
	},
	{
		garage_id: 207,
		part_id: 319,
		quantity: 366,
	},
	{
		garage_id: 207,
		part_id: 320,
		quantity: 108,
	},
	{
		garage_id: 207,
		part_id: 321,
		quantity: 308,
	},
	{
		garage_id: 207,
		part_id: 322,
		quantity: 116,
	},
	{
		garage_id: 207,
		part_id: 323,
		quantity: 81,
	},
	{
		garage_id: 207,
		part_id: 324,
		quantity: 345,
	},
	{
		garage_id: 207,
		part_id: 325,
		quantity: 216,
	},
	{
		garage_id: 207,
		part_id: 326,
		quantity: 166,
	},
	{
		garage_id: 207,
		part_id: 327,
		quantity: 494,
	},
	{
		garage_id: 207,
		part_id: 328,
		quantity: 323,
	},
	{
		garage_id: 207,
		part_id: 329,
		quantity: 105,
	},
	{
		garage_id: 207,
		part_id: 330,
		quantity: 199,
	},
	{
		garage_id: 207,
		part_id: 331,
		quantity: 477,
	},
	{
		garage_id: 207,
		part_id: 332,
		quantity: 174,
	},
	{
		garage_id: 207,
		part_id: 333,
		quantity: 156,
	},
	{
		garage_id: 207,
		part_id: 334,
		quantity: 241,
	},
	{
		garage_id: 207,
		part_id: 335,
		quantity: 138,
	},
	{
		garage_id: 207,
		part_id: 336,
		quantity: 185,
	},
	{
		garage_id: 207,
		part_id: 337,
		quantity: 331,
	},
	{
		garage_id: 207,
		part_id: 338,
		quantity: 166,
	},
	{
		garage_id: 207,
		part_id: 339,
		quantity: 223,
	},
	{
		garage_id: 207,
		part_id: 340,
		quantity: 375,
	},
	{
		garage_id: 207,
		part_id: 341,
		quantity: 123,
	},
	{
		garage_id: 207,
		part_id: 342,
		quantity: 143,
	},
	{
		garage_id: 207,
		part_id: 343,
		quantity: 370,
	},
	{
		garage_id: 207,
		part_id: 344,
		quantity: 37,
	},
	{
		garage_id: 207,
		part_id: 345,
		quantity: 198,
	},
	{
		garage_id: 207,
		part_id: 346,
		quantity: 439,
	},
	{
		garage_id: 207,
		part_id: 347,
		quantity: 354,
	},
	{
		garage_id: 207,
		part_id: 348,
		quantity: 209,
	},
	{
		garage_id: 207,
		part_id: 349,
		quantity: 130,
	},
	{
		garage_id: 207,
		part_id: 350,
		quantity: 64,
	},
	{
		garage_id: 207,
		part_id: 351,
		quantity: 70,
	},
	{
		garage_id: 207,
		part_id: 352,
		quantity: 445,
	},
	{
		garage_id: 207,
		part_id: 353,
		quantity: 426,
	},
	{
		garage_id: 207,
		part_id: 354,
		quantity: 432,
	},
	{
		garage_id: 207,
		part_id: 355,
		quantity: 350,
	},
	{
		garage_id: 207,
		part_id: 356,
		quantity: 9,
	},
	{
		garage_id: 207,
		part_id: 357,
		quantity: 125,
	},
	{
		garage_id: 207,
		part_id: 358,
		quantity: 58,
	},
	{
		garage_id: 207,
		part_id: 359,
		quantity: 69,
	},
	{
		garage_id: 207,
		part_id: 360,
		quantity: 274,
	},
	{
		garage_id: 207,
		part_id: 361,
		quantity: 6,
	},
	{
		garage_id: 207,
		part_id: 362,
		quantity: 377,
	},
	{
		garage_id: 207,
		part_id: 363,
		quantity: 4,
	},
	{
		garage_id: 207,
		part_id: 364,
		quantity: 24,
	},
	{
		garage_id: 207,
		part_id: 365,
		quantity: 267,
	},
	{
		garage_id: 207,
		part_id: 366,
		quantity: 179,
	},
	{
		garage_id: 207,
		part_id: 367,
		quantity: 121,
	},
	{
		garage_id: 207,
		part_id: 368,
		quantity: 20,
	},
	{
		garage_id: 207,
		part_id: 369,
		quantity: 417,
	},
	{
		garage_id: 207,
		part_id: 370,
		quantity: 329,
	},
	{
		garage_id: 207,
		part_id: 371,
		quantity: 11,
	},
	{
		garage_id: 207,
		part_id: 372,
		quantity: 143,
	},
	{
		garage_id: 207,
		part_id: 373,
		quantity: 211,
	},
	{
		garage_id: 207,
		part_id: 374,
		quantity: 356,
	},
	{
		garage_id: 207,
		part_id: 375,
		quantity: 462,
	},
	{
		garage_id: 207,
		part_id: 376,
		quantity: 284,
	},
	{
		garage_id: 207,
		part_id: 377,
		quantity: 87,
	},
	{
		garage_id: 207,
		part_id: 378,
		quantity: 339,
	},
	{
		garage_id: 207,
		part_id: 379,
		quantity: 230,
	},
	{
		garage_id: 207,
		part_id: 380,
		quantity: 336,
	},
	{
		garage_id: 207,
		part_id: 381,
		quantity: 390,
	},
	{
		garage_id: 207,
		part_id: 382,
		quantity: 391,
	},
	{
		garage_id: 207,
		part_id: 383,
		quantity: 252,
	},
	{
		garage_id: 207,
		part_id: 384,
		quantity: 460,
	},
	{
		garage_id: 207,
		part_id: 385,
		quantity: 195,
	},
	{
		garage_id: 207,
		part_id: 386,
		quantity: 86,
	},
	{
		garage_id: 207,
		part_id: 387,
		quantity: 384,
	},
	{
		garage_id: 207,
		part_id: 388,
		quantity: 471,
	},
	{
		garage_id: 207,
		part_id: 389,
		quantity: 380,
	},
	{
		garage_id: 207,
		part_id: 390,
		quantity: 293,
	},
	{
		garage_id: 207,
		part_id: 391,
		quantity: 414,
	},
	{
		garage_id: 207,
		part_id: 392,
		quantity: 466,
	},
	{
		garage_id: 207,
		part_id: 393,
		quantity: 34,
	},
	{
		garage_id: 207,
		part_id: 394,
		quantity: 225,
	},
	{
		garage_id: 207,
		part_id: 395,
		quantity: 302,
	},
	{
		garage_id: 207,
		part_id: 396,
		quantity: 249,
	},
	{
		garage_id: 207,
		part_id: 397,
		quantity: 146,
	},
	{
		garage_id: 207,
		part_id: 398,
		quantity: 100,
	},
	{
		garage_id: 207,
		part_id: 399,
		quantity: 267,
	},
	{
		garage_id: 208,
		part_id: 0,
		quantity: 212,
	},
	{
		garage_id: 208,
		part_id: 1,
		quantity: 441,
	},
	{
		garage_id: 208,
		part_id: 2,
		quantity: 308,
	},
	{
		garage_id: 208,
		part_id: 3,
		quantity: 378,
	},
	{
		garage_id: 208,
		part_id: 4,
		quantity: 208,
	},
	{
		garage_id: 208,
		part_id: 5,
		quantity: 417,
	},
	{
		garage_id: 208,
		part_id: 6,
		quantity: 86,
	},
	{
		garage_id: 208,
		part_id: 7,
		quantity: 149,
	},
	{
		garage_id: 208,
		part_id: 8,
		quantity: 77,
	},
	{
		garage_id: 208,
		part_id: 9,
		quantity: 144,
	},
	{
		garage_id: 208,
		part_id: 10,
		quantity: 393,
	},
	{
		garage_id: 208,
		part_id: 11,
		quantity: 284,
	},
	{
		garage_id: 208,
		part_id: 12,
		quantity: 82,
	},
	{
		garage_id: 208,
		part_id: 13,
		quantity: 340,
	},
	{
		garage_id: 208,
		part_id: 14,
		quantity: 73,
	},
	{
		garage_id: 208,
		part_id: 15,
		quantity: 2,
	},
	{
		garage_id: 208,
		part_id: 16,
		quantity: 47,
	},
	{
		garage_id: 208,
		part_id: 17,
		quantity: 241,
	},
	{
		garage_id: 208,
		part_id: 18,
		quantity: 488,
	},
	{
		garage_id: 208,
		part_id: 19,
		quantity: 162,
	},
	{
		garage_id: 208,
		part_id: 20,
		quantity: 292,
	},
	{
		garage_id: 208,
		part_id: 21,
		quantity: 406,
	},
	{
		garage_id: 208,
		part_id: 22,
		quantity: 345,
	},
	{
		garage_id: 208,
		part_id: 23,
		quantity: 268,
	},
	{
		garage_id: 208,
		part_id: 24,
		quantity: 155,
	},
	{
		garage_id: 208,
		part_id: 25,
		quantity: 389,
	},
	{
		garage_id: 208,
		part_id: 26,
		quantity: 431,
	},
	{
		garage_id: 208,
		part_id: 27,
		quantity: 240,
	},
	{
		garage_id: 208,
		part_id: 28,
		quantity: 188,
	},
	{
		garage_id: 208,
		part_id: 29,
		quantity: 44,
	},
	{
		garage_id: 208,
		part_id: 30,
		quantity: 16,
	},
	{
		garage_id: 208,
		part_id: 31,
		quantity: 320,
	},
	{
		garage_id: 208,
		part_id: 32,
		quantity: 488,
	},
	{
		garage_id: 208,
		part_id: 33,
		quantity: 489,
	},
	{
		garage_id: 208,
		part_id: 34,
		quantity: 332,
	},
	{
		garage_id: 208,
		part_id: 35,
		quantity: 257,
	},
	{
		garage_id: 208,
		part_id: 36,
		quantity: 388,
	},
	{
		garage_id: 208,
		part_id: 37,
		quantity: 499,
	},
	{
		garage_id: 208,
		part_id: 38,
		quantity: 145,
	},
	{
		garage_id: 208,
		part_id: 39,
		quantity: 217,
	},
	{
		garage_id: 208,
		part_id: 40,
		quantity: 92,
	},
	{
		garage_id: 208,
		part_id: 41,
		quantity: 175,
	},
	{
		garage_id: 208,
		part_id: 42,
		quantity: 284,
	},
	{
		garage_id: 208,
		part_id: 43,
		quantity: 119,
	},
	{
		garage_id: 208,
		part_id: 44,
		quantity: 52,
	},
	{
		garage_id: 208,
		part_id: 45,
		quantity: 30,
	},
	{
		garage_id: 208,
		part_id: 46,
		quantity: 39,
	},
	{
		garage_id: 208,
		part_id: 47,
		quantity: 233,
	},
	{
		garage_id: 208,
		part_id: 48,
		quantity: 16,
	},
	{
		garage_id: 208,
		part_id: 49,
		quantity: 38,
	},
	{
		garage_id: 208,
		part_id: 50,
		quantity: 158,
	},
	{
		garage_id: 208,
		part_id: 51,
		quantity: 371,
	},
	{
		garage_id: 208,
		part_id: 52,
		quantity: 476,
	},
	{
		garage_id: 208,
		part_id: 53,
		quantity: 450,
	},
	{
		garage_id: 208,
		part_id: 54,
		quantity: 397,
	},
	{
		garage_id: 208,
		part_id: 55,
		quantity: 79,
	},
	{
		garage_id: 208,
		part_id: 56,
		quantity: 96,
	},
	{
		garage_id: 208,
		part_id: 57,
		quantity: 320,
	},
	{
		garage_id: 208,
		part_id: 58,
		quantity: 147,
	},
	{
		garage_id: 208,
		part_id: 59,
		quantity: 122,
	},
	{
		garage_id: 208,
		part_id: 60,
		quantity: 38,
	},
	{
		garage_id: 208,
		part_id: 61,
		quantity: 366,
	},
	{
		garage_id: 208,
		part_id: 62,
		quantity: 269,
	},
	{
		garage_id: 208,
		part_id: 63,
		quantity: 110,
	},
	{
		garage_id: 208,
		part_id: 64,
		quantity: 232,
	},
	{
		garage_id: 208,
		part_id: 65,
		quantity: 29,
	},
	{
		garage_id: 208,
		part_id: 66,
		quantity: 420,
	},
	{
		garage_id: 208,
		part_id: 67,
		quantity: 391,
	},
	{
		garage_id: 208,
		part_id: 68,
		quantity: 124,
	},
	{
		garage_id: 208,
		part_id: 69,
		quantity: 135,
	},
	{
		garage_id: 208,
		part_id: 70,
		quantity: 489,
	},
	{
		garage_id: 208,
		part_id: 71,
		quantity: 137,
	},
	{
		garage_id: 208,
		part_id: 72,
		quantity: 369,
	},
	{
		garage_id: 208,
		part_id: 73,
		quantity: 440,
	},
	{
		garage_id: 208,
		part_id: 74,
		quantity: 245,
	},
	{
		garage_id: 208,
		part_id: 75,
		quantity: 390,
	},
	{
		garage_id: 208,
		part_id: 76,
		quantity: 122,
	},
	{
		garage_id: 208,
		part_id: 77,
		quantity: 207,
	},
	{
		garage_id: 208,
		part_id: 78,
		quantity: 244,
	},
	{
		garage_id: 208,
		part_id: 79,
		quantity: 347,
	},
	{
		garage_id: 208,
		part_id: 80,
		quantity: 426,
	},
	{
		garage_id: 208,
		part_id: 81,
		quantity: 246,
	},
	{
		garage_id: 208,
		part_id: 82,
		quantity: 491,
	},
	{
		garage_id: 208,
		part_id: 83,
		quantity: 413,
	},
	{
		garage_id: 208,
		part_id: 84,
		quantity: 222,
	},
	{
		garage_id: 208,
		part_id: 85,
		quantity: 382,
	},
	{
		garage_id: 208,
		part_id: 86,
		quantity: 368,
	},
	{
		garage_id: 208,
		part_id: 87,
		quantity: 85,
	},
	{
		garage_id: 208,
		part_id: 88,
		quantity: 171,
	},
	{
		garage_id: 208,
		part_id: 89,
		quantity: 420,
	},
	{
		garage_id: 208,
		part_id: 90,
		quantity: 74,
	},
	{
		garage_id: 208,
		part_id: 91,
		quantity: 276,
	},
	{
		garage_id: 208,
		part_id: 92,
		quantity: 276,
	},
	{
		garage_id: 208,
		part_id: 93,
		quantity: 52,
	},
	{
		garage_id: 208,
		part_id: 94,
		quantity: 340,
	},
	{
		garage_id: 208,
		part_id: 95,
		quantity: 415,
	},
	{
		garage_id: 208,
		part_id: 96,
		quantity: 443,
	},
	{
		garage_id: 208,
		part_id: 97,
		quantity: 444,
	},
	{
		garage_id: 208,
		part_id: 98,
		quantity: 204,
	},
	{
		garage_id: 208,
		part_id: 99,
		quantity: 74,
	},
	{
		garage_id: 208,
		part_id: 100,
		quantity: 49,
	},
	{
		garage_id: 208,
		part_id: 101,
		quantity: 16,
	},
	{
		garage_id: 208,
		part_id: 102,
		quantity: 125,
	},
	{
		garage_id: 208,
		part_id: 103,
		quantity: 436,
	},
	{
		garage_id: 208,
		part_id: 104,
		quantity: 390,
	},
	{
		garage_id: 208,
		part_id: 105,
		quantity: 414,
	},
	{
		garage_id: 208,
		part_id: 106,
		quantity: 484,
	},
	{
		garage_id: 208,
		part_id: 107,
		quantity: 384,
	},
	{
		garage_id: 208,
		part_id: 108,
		quantity: 238,
	},
	{
		garage_id: 208,
		part_id: 109,
		quantity: 261,
	},
	{
		garage_id: 208,
		part_id: 110,
		quantity: 226,
	},
	{
		garage_id: 208,
		part_id: 111,
		quantity: 158,
	},
	{
		garage_id: 208,
		part_id: 112,
		quantity: 75,
	},
	{
		garage_id: 208,
		part_id: 113,
		quantity: 463,
	},
	{
		garage_id: 208,
		part_id: 114,
		quantity: 156,
	},
	{
		garage_id: 208,
		part_id: 115,
		quantity: 323,
	},
	{
		garage_id: 208,
		part_id: 116,
		quantity: 342,
	},
	{
		garage_id: 208,
		part_id: 117,
		quantity: 268,
	},
	{
		garage_id: 208,
		part_id: 118,
		quantity: 124,
	},
	{
		garage_id: 208,
		part_id: 119,
		quantity: 314,
	},
	{
		garage_id: 208,
		part_id: 120,
		quantity: 332,
	},
	{
		garage_id: 208,
		part_id: 121,
		quantity: 419,
	},
	{
		garage_id: 208,
		part_id: 122,
		quantity: 309,
	},
	{
		garage_id: 208,
		part_id: 123,
		quantity: 226,
	},
	{
		garage_id: 208,
		part_id: 124,
		quantity: 6,
	},
	{
		garage_id: 208,
		part_id: 125,
		quantity: 141,
	},
	{
		garage_id: 208,
		part_id: 126,
		quantity: 98,
	},
	{
		garage_id: 208,
		part_id: 127,
		quantity: 259,
	},
	{
		garage_id: 208,
		part_id: 128,
		quantity: 323,
	},
	{
		garage_id: 208,
		part_id: 129,
		quantity: 372,
	},
	{
		garage_id: 208,
		part_id: 130,
		quantity: 483,
	},
	{
		garage_id: 208,
		part_id: 131,
		quantity: 414,
	},
	{
		garage_id: 208,
		part_id: 132,
		quantity: 187,
	},
	{
		garage_id: 208,
		part_id: 133,
		quantity: 294,
	},
	{
		garage_id: 208,
		part_id: 134,
		quantity: 431,
	},
	{
		garage_id: 208,
		part_id: 135,
		quantity: 351,
	},
	{
		garage_id: 208,
		part_id: 136,
		quantity: 156,
	},
	{
		garage_id: 208,
		part_id: 137,
		quantity: 6,
	},
	{
		garage_id: 208,
		part_id: 138,
		quantity: 206,
	},
	{
		garage_id: 208,
		part_id: 139,
		quantity: 404,
	},
	{
		garage_id: 208,
		part_id: 140,
		quantity: 376,
	},
	{
		garage_id: 208,
		part_id: 141,
		quantity: 121,
	},
	{
		garage_id: 208,
		part_id: 142,
		quantity: 310,
	},
	{
		garage_id: 208,
		part_id: 143,
		quantity: 360,
	},
	{
		garage_id: 208,
		part_id: 144,
		quantity: 93,
	},
	{
		garage_id: 208,
		part_id: 145,
		quantity: 263,
	},
	{
		garage_id: 208,
		part_id: 146,
		quantity: 389,
	},
	{
		garage_id: 208,
		part_id: 147,
		quantity: 140,
	},
	{
		garage_id: 208,
		part_id: 148,
		quantity: 463,
	},
	{
		garage_id: 208,
		part_id: 149,
		quantity: 318,
	},
	{
		garage_id: 208,
		part_id: 150,
		quantity: 8,
	},
	{
		garage_id: 208,
		part_id: 151,
		quantity: 215,
	},
	{
		garage_id: 208,
		part_id: 152,
		quantity: 458,
	},
	{
		garage_id: 208,
		part_id: 153,
		quantity: 459,
	},
	{
		garage_id: 208,
		part_id: 154,
		quantity: 73,
	},
	{
		garage_id: 208,
		part_id: 155,
		quantity: 398,
	},
	{
		garage_id: 208,
		part_id: 156,
		quantity: 164,
	},
	{
		garage_id: 208,
		part_id: 157,
		quantity: 346,
	},
	{
		garage_id: 208,
		part_id: 158,
		quantity: 57,
	},
	{
		garage_id: 208,
		part_id: 159,
		quantity: 319,
	},
	{
		garage_id: 208,
		part_id: 160,
		quantity: 339,
	},
	{
		garage_id: 208,
		part_id: 161,
		quantity: 406,
	},
	{
		garage_id: 208,
		part_id: 162,
		quantity: 177,
	},
	{
		garage_id: 208,
		part_id: 163,
		quantity: 458,
	},
	{
		garage_id: 208,
		part_id: 164,
		quantity: 423,
	},
	{
		garage_id: 208,
		part_id: 165,
		quantity: 276,
	},
	{
		garage_id: 208,
		part_id: 166,
		quantity: 144,
	},
	{
		garage_id: 208,
		part_id: 167,
		quantity: 253,
	},
	{
		garage_id: 208,
		part_id: 168,
		quantity: 192,
	},
	{
		garage_id: 208,
		part_id: 169,
		quantity: 458,
	},
	{
		garage_id: 208,
		part_id: 170,
		quantity: 436,
	},
	{
		garage_id: 208,
		part_id: 171,
		quantity: 209,
	},
	{
		garage_id: 208,
		part_id: 172,
		quantity: 216,
	},
	{
		garage_id: 208,
		part_id: 173,
		quantity: 206,
	},
	{
		garage_id: 208,
		part_id: 174,
		quantity: 165,
	},
	{
		garage_id: 208,
		part_id: 175,
		quantity: 165,
	},
	{
		garage_id: 208,
		part_id: 176,
		quantity: 149,
	},
	{
		garage_id: 208,
		part_id: 177,
		quantity: 424,
	},
	{
		garage_id: 208,
		part_id: 178,
		quantity: 150,
	},
	{
		garage_id: 208,
		part_id: 179,
		quantity: 367,
	},
	{
		garage_id: 208,
		part_id: 180,
		quantity: 52,
	},
	{
		garage_id: 208,
		part_id: 181,
		quantity: 343,
	},
	{
		garage_id: 208,
		part_id: 182,
		quantity: 419,
	},
	{
		garage_id: 208,
		part_id: 183,
		quantity: 468,
	},
	{
		garage_id: 208,
		part_id: 184,
		quantity: 121,
	},
	{
		garage_id: 208,
		part_id: 185,
		quantity: 21,
	},
	{
		garage_id: 208,
		part_id: 186,
		quantity: 409,
	},
	{
		garage_id: 208,
		part_id: 187,
		quantity: 445,
	},
	{
		garage_id: 208,
		part_id: 188,
		quantity: 20,
	},
	{
		garage_id: 208,
		part_id: 189,
		quantity: 275,
	},
	{
		garage_id: 208,
		part_id: 190,
		quantity: 170,
	},
	{
		garage_id: 208,
		part_id: 191,
		quantity: 424,
	},
	{
		garage_id: 208,
		part_id: 192,
		quantity: 203,
	},
	{
		garage_id: 208,
		part_id: 193,
		quantity: 454,
	},
	{
		garage_id: 208,
		part_id: 194,
		quantity: 17,
	},
	{
		garage_id: 208,
		part_id: 195,
		quantity: 152,
	},
	{
		garage_id: 208,
		part_id: 196,
		quantity: 329,
	},
	{
		garage_id: 208,
		part_id: 197,
		quantity: 356,
	},
	{
		garage_id: 208,
		part_id: 198,
		quantity: 346,
	},
	{
		garage_id: 208,
		part_id: 199,
		quantity: 386,
	},
	{
		garage_id: 208,
		part_id: 200,
		quantity: 348,
	},
	{
		garage_id: 208,
		part_id: 201,
		quantity: 171,
	},
	{
		garage_id: 208,
		part_id: 202,
		quantity: 143,
	},
	{
		garage_id: 208,
		part_id: 203,
		quantity: 146,
	},
	{
		garage_id: 208,
		part_id: 204,
		quantity: 460,
	},
	{
		garage_id: 208,
		part_id: 205,
		quantity: 253,
	},
	{
		garage_id: 208,
		part_id: 206,
		quantity: 488,
	},
	{
		garage_id: 208,
		part_id: 207,
		quantity: 421,
	},
	{
		garage_id: 208,
		part_id: 208,
		quantity: 472,
	},
	{
		garage_id: 208,
		part_id: 209,
		quantity: 241,
	},
	{
		garage_id: 208,
		part_id: 210,
		quantity: 272,
	},
	{
		garage_id: 208,
		part_id: 211,
		quantity: 68,
	},
	{
		garage_id: 208,
		part_id: 212,
		quantity: 116,
	},
	{
		garage_id: 208,
		part_id: 213,
		quantity: 249,
	},
	{
		garage_id: 208,
		part_id: 214,
		quantity: 389,
	},
	{
		garage_id: 208,
		part_id: 215,
		quantity: 364,
	},
	{
		garage_id: 208,
		part_id: 216,
		quantity: 137,
	},
	{
		garage_id: 208,
		part_id: 217,
		quantity: 45,
	},
	{
		garage_id: 208,
		part_id: 218,
		quantity: 38,
	},
	{
		garage_id: 208,
		part_id: 219,
		quantity: 96,
	},
	{
		garage_id: 208,
		part_id: 220,
		quantity: 420,
	},
	{
		garage_id: 208,
		part_id: 221,
		quantity: 173,
	},
	{
		garage_id: 208,
		part_id: 222,
		quantity: 251,
	},
	{
		garage_id: 208,
		part_id: 223,
		quantity: 310,
	},
	{
		garage_id: 208,
		part_id: 224,
		quantity: 222,
	},
	{
		garage_id: 208,
		part_id: 225,
		quantity: 424,
	},
	{
		garage_id: 208,
		part_id: 226,
		quantity: 249,
	},
	{
		garage_id: 208,
		part_id: 227,
		quantity: 103,
	},
	{
		garage_id: 208,
		part_id: 228,
		quantity: 213,
	},
	{
		garage_id: 208,
		part_id: 229,
		quantity: 393,
	},
	{
		garage_id: 208,
		part_id: 230,
		quantity: 123,
	},
	{
		garage_id: 208,
		part_id: 231,
		quantity: 242,
	},
	{
		garage_id: 208,
		part_id: 232,
		quantity: 97,
	},
	{
		garage_id: 208,
		part_id: 233,
		quantity: 305,
	},
	{
		garage_id: 208,
		part_id: 234,
		quantity: 428,
	},
	{
		garage_id: 208,
		part_id: 235,
		quantity: 314,
	},
	{
		garage_id: 208,
		part_id: 236,
		quantity: 414,
	},
	{
		garage_id: 208,
		part_id: 237,
		quantity: 290,
	},
	{
		garage_id: 208,
		part_id: 238,
		quantity: 352,
	},
	{
		garage_id: 208,
		part_id: 239,
		quantity: 416,
	},
	{
		garage_id: 208,
		part_id: 240,
		quantity: 112,
	},
	{
		garage_id: 208,
		part_id: 241,
		quantity: 169,
	},
	{
		garage_id: 208,
		part_id: 242,
		quantity: 377,
	},
	{
		garage_id: 208,
		part_id: 243,
		quantity: 176,
	},
	{
		garage_id: 208,
		part_id: 244,
		quantity: 348,
	},
	{
		garage_id: 208,
		part_id: 245,
		quantity: 354,
	},
	{
		garage_id: 208,
		part_id: 246,
		quantity: 318,
	},
	{
		garage_id: 208,
		part_id: 247,
		quantity: 160,
	},
	{
		garage_id: 208,
		part_id: 248,
		quantity: 136,
	},
	{
		garage_id: 208,
		part_id: 249,
		quantity: 335,
	},
	{
		garage_id: 208,
		part_id: 250,
		quantity: 134,
	},
	{
		garage_id: 208,
		part_id: 251,
		quantity: 428,
	},
	{
		garage_id: 208,
		part_id: 252,
		quantity: 349,
	},
	{
		garage_id: 208,
		part_id: 253,
		quantity: 207,
	},
	{
		garage_id: 208,
		part_id: 254,
		quantity: 24,
	},
	{
		garage_id: 208,
		part_id: 255,
		quantity: 98,
	},
	{
		garage_id: 208,
		part_id: 256,
		quantity: 377,
	},
	{
		garage_id: 208,
		part_id: 257,
		quantity: 66,
	},
	{
		garage_id: 208,
		part_id: 258,
		quantity: 25,
	},
	{
		garage_id: 208,
		part_id: 259,
		quantity: 448,
	},
	{
		garage_id: 208,
		part_id: 260,
		quantity: 157,
	},
	{
		garage_id: 208,
		part_id: 261,
		quantity: 157,
	},
	{
		garage_id: 208,
		part_id: 262,
		quantity: 125,
	},
	{
		garage_id: 208,
		part_id: 263,
		quantity: 28,
	},
	{
		garage_id: 208,
		part_id: 264,
		quantity: 145,
	},
	{
		garage_id: 208,
		part_id: 265,
		quantity: 116,
	},
	{
		garage_id: 208,
		part_id: 266,
		quantity: 107,
	},
	{
		garage_id: 208,
		part_id: 267,
		quantity: 29,
	},
	{
		garage_id: 208,
		part_id: 268,
		quantity: 164,
	},
	{
		garage_id: 208,
		part_id: 269,
		quantity: 98,
	},
	{
		garage_id: 208,
		part_id: 270,
		quantity: 374,
	},
	{
		garage_id: 208,
		part_id: 271,
		quantity: 440,
	},
	{
		garage_id: 208,
		part_id: 272,
		quantity: 404,
	},
	{
		garage_id: 208,
		part_id: 273,
		quantity: 354,
	},
	{
		garage_id: 208,
		part_id: 274,
		quantity: 215,
	},
	{
		garage_id: 208,
		part_id: 275,
		quantity: 297,
	},
	{
		garage_id: 208,
		part_id: 276,
		quantity: 74,
	},
	{
		garage_id: 208,
		part_id: 277,
		quantity: 92,
	},
	{
		garage_id: 208,
		part_id: 278,
		quantity: 400,
	},
	{
		garage_id: 208,
		part_id: 279,
		quantity: 5,
	},
	{
		garage_id: 208,
		part_id: 280,
		quantity: 467,
	},
	{
		garage_id: 208,
		part_id: 281,
		quantity: 392,
	},
	{
		garage_id: 208,
		part_id: 282,
		quantity: 446,
	},
	{
		garage_id: 208,
		part_id: 283,
		quantity: 474,
	},
	{
		garage_id: 208,
		part_id: 284,
		quantity: 8,
	},
	{
		garage_id: 208,
		part_id: 285,
		quantity: 434,
	},
	{
		garage_id: 208,
		part_id: 286,
		quantity: 95,
	},
	{
		garage_id: 208,
		part_id: 287,
		quantity: 171,
	},
	{
		garage_id: 208,
		part_id: 288,
		quantity: 478,
	},
	{
		garage_id: 208,
		part_id: 289,
		quantity: 284,
	},
	{
		garage_id: 208,
		part_id: 290,
		quantity: 432,
	},
	{
		garage_id: 208,
		part_id: 291,
		quantity: 73,
	},
	{
		garage_id: 208,
		part_id: 292,
		quantity: 83,
	},
	{
		garage_id: 208,
		part_id: 293,
		quantity: 39,
	},
	{
		garage_id: 208,
		part_id: 294,
		quantity: 398,
	},
	{
		garage_id: 208,
		part_id: 295,
		quantity: 162,
	},
	{
		garage_id: 208,
		part_id: 296,
		quantity: 486,
	},
	{
		garage_id: 208,
		part_id: 297,
		quantity: 86,
	},
	{
		garage_id: 208,
		part_id: 298,
		quantity: 236,
	},
	{
		garage_id: 208,
		part_id: 299,
		quantity: 29,
	},
	{
		garage_id: 208,
		part_id: 300,
		quantity: 37,
	},
	{
		garage_id: 208,
		part_id: 301,
		quantity: 9,
	},
	{
		garage_id: 208,
		part_id: 302,
		quantity: 267,
	},
	{
		garage_id: 208,
		part_id: 303,
		quantity: 41,
	},
	{
		garage_id: 208,
		part_id: 304,
		quantity: 427,
	},
	{
		garage_id: 208,
		part_id: 305,
		quantity: 189,
	},
	{
		garage_id: 208,
		part_id: 306,
		quantity: 390,
	},
	{
		garage_id: 208,
		part_id: 307,
		quantity: 186,
	},
	{
		garage_id: 208,
		part_id: 308,
		quantity: 282,
	},
	{
		garage_id: 208,
		part_id: 309,
		quantity: 398,
	},
	{
		garage_id: 208,
		part_id: 310,
		quantity: 221,
	},
	{
		garage_id: 208,
		part_id: 311,
		quantity: 214,
	},
	{
		garage_id: 208,
		part_id: 312,
		quantity: 37,
	},
	{
		garage_id: 208,
		part_id: 313,
		quantity: 153,
	},
	{
		garage_id: 208,
		part_id: 314,
		quantity: 29,
	},
	{
		garage_id: 208,
		part_id: 315,
		quantity: 389,
	},
	{
		garage_id: 208,
		part_id: 316,
		quantity: 42,
	},
	{
		garage_id: 208,
		part_id: 317,
		quantity: 159,
	},
	{
		garage_id: 208,
		part_id: 318,
		quantity: 250,
	},
	{
		garage_id: 208,
		part_id: 319,
		quantity: 147,
	},
	{
		garage_id: 208,
		part_id: 320,
		quantity: 20,
	},
	{
		garage_id: 208,
		part_id: 321,
		quantity: 152,
	},
	{
		garage_id: 208,
		part_id: 322,
		quantity: 376,
	},
	{
		garage_id: 208,
		part_id: 323,
		quantity: 471,
	},
	{
		garage_id: 208,
		part_id: 324,
		quantity: 83,
	},
	{
		garage_id: 208,
		part_id: 325,
		quantity: 369,
	},
	{
		garage_id: 208,
		part_id: 326,
		quantity: 419,
	},
	{
		garage_id: 208,
		part_id: 327,
		quantity: 215,
	},
	{
		garage_id: 208,
		part_id: 328,
		quantity: 60,
	},
	{
		garage_id: 208,
		part_id: 329,
		quantity: 19,
	},
	{
		garage_id: 208,
		part_id: 330,
		quantity: 110,
	},
	{
		garage_id: 208,
		part_id: 331,
		quantity: 136,
	},
	{
		garage_id: 208,
		part_id: 332,
		quantity: 57,
	},
	{
		garage_id: 208,
		part_id: 333,
		quantity: 497,
	},
	{
		garage_id: 208,
		part_id: 334,
		quantity: 297,
	},
	{
		garage_id: 208,
		part_id: 335,
		quantity: 82,
	},
	{
		garage_id: 208,
		part_id: 336,
		quantity: 277,
	},
	{
		garage_id: 208,
		part_id: 337,
		quantity: 170,
	},
	{
		garage_id: 208,
		part_id: 338,
		quantity: 6,
	},
	{
		garage_id: 208,
		part_id: 339,
		quantity: 350,
	},
	{
		garage_id: 208,
		part_id: 340,
		quantity: 163,
	},
	{
		garage_id: 208,
		part_id: 341,
		quantity: 49,
	},
	{
		garage_id: 208,
		part_id: 342,
		quantity: 426,
	},
	{
		garage_id: 208,
		part_id: 343,
		quantity: 1,
	},
	{
		garage_id: 208,
		part_id: 344,
		quantity: 121,
	},
	{
		garage_id: 208,
		part_id: 345,
		quantity: 276,
	},
	{
		garage_id: 208,
		part_id: 346,
		quantity: 35,
	},
	{
		garage_id: 208,
		part_id: 347,
		quantity: 490,
	},
	{
		garage_id: 208,
		part_id: 348,
		quantity: 475,
	},
	{
		garage_id: 208,
		part_id: 349,
		quantity: 114,
	},
	{
		garage_id: 208,
		part_id: 350,
		quantity: 408,
	},
	{
		garage_id: 208,
		part_id: 351,
		quantity: 351,
	},
	{
		garage_id: 208,
		part_id: 352,
		quantity: 444,
	},
	{
		garage_id: 208,
		part_id: 353,
		quantity: 380,
	},
	{
		garage_id: 208,
		part_id: 354,
		quantity: 108,
	},
	{
		garage_id: 208,
		part_id: 355,
		quantity: 199,
	},
	{
		garage_id: 208,
		part_id: 356,
		quantity: 373,
	},
	{
		garage_id: 208,
		part_id: 357,
		quantity: 281,
	},
	{
		garage_id: 208,
		part_id: 358,
		quantity: 421,
	},
	{
		garage_id: 208,
		part_id: 359,
		quantity: 165,
	},
	{
		garage_id: 208,
		part_id: 360,
		quantity: 53,
	},
	{
		garage_id: 208,
		part_id: 361,
		quantity: 114,
	},
	{
		garage_id: 208,
		part_id: 362,
		quantity: 48,
	},
	{
		garage_id: 208,
		part_id: 363,
		quantity: 277,
	},
	{
		garage_id: 208,
		part_id: 364,
		quantity: 134,
	},
	{
		garage_id: 208,
		part_id: 365,
		quantity: 88,
	},
	{
		garage_id: 208,
		part_id: 366,
		quantity: 299,
	},
	{
		garage_id: 208,
		part_id: 367,
		quantity: 165,
	},
	{
		garage_id: 208,
		part_id: 368,
		quantity: 345,
	},
	{
		garage_id: 208,
		part_id: 369,
		quantity: 203,
	},
	{
		garage_id: 208,
		part_id: 370,
		quantity: 346,
	},
	{
		garage_id: 208,
		part_id: 371,
		quantity: 495,
	},
	{
		garage_id: 208,
		part_id: 372,
		quantity: 80,
	},
	{
		garage_id: 208,
		part_id: 373,
		quantity: 284,
	},
	{
		garage_id: 208,
		part_id: 374,
		quantity: 316,
	},
	{
		garage_id: 208,
		part_id: 375,
		quantity: 298,
	},
	{
		garage_id: 208,
		part_id: 376,
		quantity: 51,
	},
	{
		garage_id: 208,
		part_id: 377,
		quantity: 347,
	},
	{
		garage_id: 208,
		part_id: 378,
		quantity: 52,
	},
	{
		garage_id: 208,
		part_id: 379,
		quantity: 217,
	},
	{
		garage_id: 208,
		part_id: 380,
		quantity: 461,
	},
	{
		garage_id: 208,
		part_id: 381,
		quantity: 341,
	},
	{
		garage_id: 208,
		part_id: 382,
		quantity: 370,
	},
	{
		garage_id: 208,
		part_id: 383,
		quantity: 491,
	},
	{
		garage_id: 208,
		part_id: 384,
		quantity: 379,
	},
	{
		garage_id: 208,
		part_id: 385,
		quantity: 134,
	},
	{
		garage_id: 208,
		part_id: 386,
		quantity: 90,
	},
	{
		garage_id: 208,
		part_id: 387,
		quantity: 195,
	},
	{
		garage_id: 208,
		part_id: 388,
		quantity: 1,
	},
	{
		garage_id: 208,
		part_id: 389,
		quantity: 401,
	},
	{
		garage_id: 208,
		part_id: 390,
		quantity: 222,
	},
	{
		garage_id: 208,
		part_id: 391,
		quantity: 69,
	},
	{
		garage_id: 208,
		part_id: 392,
		quantity: 178,
	},
	{
		garage_id: 208,
		part_id: 393,
		quantity: 33,
	},
	{
		garage_id: 208,
		part_id: 394,
		quantity: 453,
	},
	{
		garage_id: 208,
		part_id: 395,
		quantity: 248,
	},
	{
		garage_id: 208,
		part_id: 396,
		quantity: 328,
	},
	{
		garage_id: 208,
		part_id: 397,
		quantity: 329,
	},
	{
		garage_id: 208,
		part_id: 398,
		quantity: 250,
	},
	{
		garage_id: 208,
		part_id: 399,
		quantity: 208,
	},
	{
		garage_id: 209,
		part_id: 0,
		quantity: 484,
	},
	{
		garage_id: 209,
		part_id: 1,
		quantity: 313,
	},
	{
		garage_id: 209,
		part_id: 2,
		quantity: 411,
	},
	{
		garage_id: 209,
		part_id: 3,
		quantity: 465,
	},
	{
		garage_id: 209,
		part_id: 4,
		quantity: 313,
	},
	{
		garage_id: 209,
		part_id: 5,
		quantity: 262,
	},
	{
		garage_id: 209,
		part_id: 6,
		quantity: 301,
	},
	{
		garage_id: 209,
		part_id: 7,
		quantity: 461,
	},
	{
		garage_id: 209,
		part_id: 8,
		quantity: 245,
	},
	{
		garage_id: 209,
		part_id: 9,
		quantity: 30,
	},
	{
		garage_id: 209,
		part_id: 10,
		quantity: 284,
	},
	{
		garage_id: 209,
		part_id: 11,
		quantity: 123,
	},
	{
		garage_id: 209,
		part_id: 12,
		quantity: 387,
	},
	{
		garage_id: 209,
		part_id: 13,
		quantity: 382,
	},
	{
		garage_id: 209,
		part_id: 14,
		quantity: 209,
	},
	{
		garage_id: 209,
		part_id: 15,
		quantity: 290,
	},
	{
		garage_id: 209,
		part_id: 16,
		quantity: 197,
	},
	{
		garage_id: 209,
		part_id: 17,
		quantity: 19,
	},
	{
		garage_id: 209,
		part_id: 18,
		quantity: 145,
	},
	{
		garage_id: 209,
		part_id: 19,
		quantity: 83,
	},
	{
		garage_id: 209,
		part_id: 20,
		quantity: 213,
	},
	{
		garage_id: 209,
		part_id: 21,
		quantity: 424,
	},
	{
		garage_id: 209,
		part_id: 22,
		quantity: 142,
	},
	{
		garage_id: 209,
		part_id: 23,
		quantity: 419,
	},
	{
		garage_id: 209,
		part_id: 24,
		quantity: 134,
	},
	{
		garage_id: 209,
		part_id: 25,
		quantity: 205,
	},
	{
		garage_id: 209,
		part_id: 26,
		quantity: 328,
	},
	{
		garage_id: 209,
		part_id: 27,
		quantity: 28,
	},
	{
		garage_id: 209,
		part_id: 28,
		quantity: 321,
	},
	{
		garage_id: 209,
		part_id: 29,
		quantity: 457,
	},
	{
		garage_id: 209,
		part_id: 30,
		quantity: 273,
	},
	{
		garage_id: 209,
		part_id: 31,
		quantity: 325,
	},
	{
		garage_id: 209,
		part_id: 32,
		quantity: 293,
	},
	{
		garage_id: 209,
		part_id: 33,
		quantity: 265,
	},
	{
		garage_id: 209,
		part_id: 34,
		quantity: 500,
	},
	{
		garage_id: 209,
		part_id: 35,
		quantity: 427,
	},
	{
		garage_id: 209,
		part_id: 36,
		quantity: 258,
	},
	{
		garage_id: 209,
		part_id: 37,
		quantity: 242,
	},
	{
		garage_id: 209,
		part_id: 38,
		quantity: 159,
	},
	{
		garage_id: 209,
		part_id: 39,
		quantity: 240,
	},
	{
		garage_id: 209,
		part_id: 40,
		quantity: 207,
	},
	{
		garage_id: 209,
		part_id: 41,
		quantity: 440,
	},
	{
		garage_id: 209,
		part_id: 42,
		quantity: 95,
	},
	{
		garage_id: 209,
		part_id: 43,
		quantity: 226,
	},
	{
		garage_id: 209,
		part_id: 44,
		quantity: 402,
	},
	{
		garage_id: 209,
		part_id: 45,
		quantity: 246,
	},
	{
		garage_id: 209,
		part_id: 46,
		quantity: 147,
	},
	{
		garage_id: 209,
		part_id: 47,
		quantity: 397,
	},
	{
		garage_id: 209,
		part_id: 48,
		quantity: 449,
	},
	{
		garage_id: 209,
		part_id: 49,
		quantity: 393,
	},
	{
		garage_id: 209,
		part_id: 50,
		quantity: 350,
	},
	{
		garage_id: 209,
		part_id: 51,
		quantity: 425,
	},
	{
		garage_id: 209,
		part_id: 52,
		quantity: 76,
	},
	{
		garage_id: 209,
		part_id: 53,
		quantity: 176,
	},
	{
		garage_id: 209,
		part_id: 54,
		quantity: 145,
	},
	{
		garage_id: 209,
		part_id: 55,
		quantity: 15,
	},
	{
		garage_id: 209,
		part_id: 56,
		quantity: 207,
	},
	{
		garage_id: 209,
		part_id: 57,
		quantity: 41,
	},
	{
		garage_id: 209,
		part_id: 58,
		quantity: 114,
	},
	{
		garage_id: 209,
		part_id: 59,
		quantity: 281,
	},
	{
		garage_id: 209,
		part_id: 60,
		quantity: 153,
	},
	{
		garage_id: 209,
		part_id: 61,
		quantity: 103,
	},
	{
		garage_id: 209,
		part_id: 62,
		quantity: 5,
	},
	{
		garage_id: 209,
		part_id: 63,
		quantity: 375,
	},
	{
		garage_id: 209,
		part_id: 64,
		quantity: 31,
	},
	{
		garage_id: 209,
		part_id: 65,
		quantity: 463,
	},
	{
		garage_id: 209,
		part_id: 66,
		quantity: 74,
	},
	{
		garage_id: 209,
		part_id: 67,
		quantity: 418,
	},
	{
		garage_id: 209,
		part_id: 68,
		quantity: 244,
	},
	{
		garage_id: 209,
		part_id: 69,
		quantity: 122,
	},
	{
		garage_id: 209,
		part_id: 70,
		quantity: 61,
	},
	{
		garage_id: 209,
		part_id: 71,
		quantity: 62,
	},
	{
		garage_id: 209,
		part_id: 72,
		quantity: 352,
	},
	{
		garage_id: 209,
		part_id: 73,
		quantity: 368,
	},
	{
		garage_id: 209,
		part_id: 74,
		quantity: 246,
	},
	{
		garage_id: 209,
		part_id: 75,
		quantity: 273,
	},
	{
		garage_id: 209,
		part_id: 76,
		quantity: 224,
	},
	{
		garage_id: 209,
		part_id: 77,
		quantity: 314,
	},
	{
		garage_id: 209,
		part_id: 78,
		quantity: 365,
	},
	{
		garage_id: 209,
		part_id: 79,
		quantity: 1,
	},
	{
		garage_id: 209,
		part_id: 80,
		quantity: 31,
	},
	{
		garage_id: 209,
		part_id: 81,
		quantity: 294,
	},
	{
		garage_id: 209,
		part_id: 82,
		quantity: 428,
	},
	{
		garage_id: 209,
		part_id: 83,
		quantity: 63,
	},
	{
		garage_id: 209,
		part_id: 84,
		quantity: 12,
	},
	{
		garage_id: 209,
		part_id: 85,
		quantity: 206,
	},
	{
		garage_id: 209,
		part_id: 86,
		quantity: 459,
	},
	{
		garage_id: 209,
		part_id: 87,
		quantity: 416,
	},
	{
		garage_id: 209,
		part_id: 88,
		quantity: 28,
	},
	{
		garage_id: 209,
		part_id: 89,
		quantity: 94,
	},
	{
		garage_id: 209,
		part_id: 90,
		quantity: 262,
	},
	{
		garage_id: 209,
		part_id: 91,
		quantity: 473,
	},
	{
		garage_id: 209,
		part_id: 92,
		quantity: 95,
	},
	{
		garage_id: 209,
		part_id: 93,
		quantity: 171,
	},
	{
		garage_id: 209,
		part_id: 94,
		quantity: 456,
	},
	{
		garage_id: 209,
		part_id: 95,
		quantity: 394,
	},
	{
		garage_id: 209,
		part_id: 96,
		quantity: 335,
	},
	{
		garage_id: 209,
		part_id: 97,
		quantity: 56,
	},
	{
		garage_id: 209,
		part_id: 98,
		quantity: 387,
	},
	{
		garage_id: 209,
		part_id: 99,
		quantity: 146,
	},
	{
		garage_id: 209,
		part_id: 100,
		quantity: 18,
	},
	{
		garage_id: 209,
		part_id: 101,
		quantity: 127,
	},
	{
		garage_id: 209,
		part_id: 102,
		quantity: 486,
	},
	{
		garage_id: 209,
		part_id: 103,
		quantity: 371,
	},
	{
		garage_id: 209,
		part_id: 104,
		quantity: 194,
	},
	{
		garage_id: 209,
		part_id: 105,
		quantity: 383,
	},
	{
		garage_id: 209,
		part_id: 106,
		quantity: 483,
	},
	{
		garage_id: 209,
		part_id: 107,
		quantity: 156,
	},
	{
		garage_id: 209,
		part_id: 108,
		quantity: 496,
	},
	{
		garage_id: 209,
		part_id: 109,
		quantity: 357,
	},
	{
		garage_id: 209,
		part_id: 110,
		quantity: 276,
	},
	{
		garage_id: 209,
		part_id: 111,
		quantity: 332,
	},
	{
		garage_id: 209,
		part_id: 112,
		quantity: 314,
	},
	{
		garage_id: 209,
		part_id: 113,
		quantity: 33,
	},
	{
		garage_id: 209,
		part_id: 114,
		quantity: 254,
	},
	{
		garage_id: 209,
		part_id: 115,
		quantity: 408,
	},
	{
		garage_id: 209,
		part_id: 116,
		quantity: 263,
	},
	{
		garage_id: 209,
		part_id: 117,
		quantity: 175,
	},
	{
		garage_id: 209,
		part_id: 118,
		quantity: 354,
	},
	{
		garage_id: 209,
		part_id: 119,
		quantity: 468,
	},
	{
		garage_id: 209,
		part_id: 120,
		quantity: 183,
	},
	{
		garage_id: 209,
		part_id: 121,
		quantity: 477,
	},
	{
		garage_id: 209,
		part_id: 122,
		quantity: 500,
	},
	{
		garage_id: 209,
		part_id: 123,
		quantity: 315,
	},
	{
		garage_id: 209,
		part_id: 124,
		quantity: 383,
	},
	{
		garage_id: 209,
		part_id: 125,
		quantity: 174,
	},
	{
		garage_id: 209,
		part_id: 126,
		quantity: 115,
	},
	{
		garage_id: 209,
		part_id: 127,
		quantity: 429,
	},
	{
		garage_id: 209,
		part_id: 128,
		quantity: 224,
	},
	{
		garage_id: 209,
		part_id: 129,
		quantity: 250,
	},
	{
		garage_id: 209,
		part_id: 130,
		quantity: 10,
	},
	{
		garage_id: 209,
		part_id: 131,
		quantity: 154,
	},
	{
		garage_id: 209,
		part_id: 132,
		quantity: 118,
	},
	{
		garage_id: 209,
		part_id: 133,
		quantity: 374,
	},
	{
		garage_id: 209,
		part_id: 134,
		quantity: 43,
	},
	{
		garage_id: 209,
		part_id: 135,
		quantity: 166,
	},
	{
		garage_id: 209,
		part_id: 136,
		quantity: 433,
	},
	{
		garage_id: 209,
		part_id: 137,
		quantity: 85,
	},
	{
		garage_id: 209,
		part_id: 138,
		quantity: 46,
	},
	{
		garage_id: 209,
		part_id: 139,
		quantity: 364,
	},
	{
		garage_id: 209,
		part_id: 140,
		quantity: 4,
	},
	{
		garage_id: 209,
		part_id: 141,
		quantity: 7,
	},
	{
		garage_id: 209,
		part_id: 142,
		quantity: 46,
	},
	{
		garage_id: 209,
		part_id: 143,
		quantity: 94,
	},
	{
		garage_id: 209,
		part_id: 144,
		quantity: 302,
	},
	{
		garage_id: 209,
		part_id: 145,
		quantity: 19,
	},
	{
		garage_id: 209,
		part_id: 146,
		quantity: 252,
	},
	{
		garage_id: 209,
		part_id: 147,
		quantity: 471,
	},
	{
		garage_id: 209,
		part_id: 148,
		quantity: 301,
	},
	{
		garage_id: 209,
		part_id: 149,
		quantity: 39,
	},
	{
		garage_id: 209,
		part_id: 150,
		quantity: 125,
	},
	{
		garage_id: 209,
		part_id: 151,
		quantity: 290,
	},
	{
		garage_id: 209,
		part_id: 152,
		quantity: 156,
	},
	{
		garage_id: 209,
		part_id: 153,
		quantity: 458,
	},
	{
		garage_id: 209,
		part_id: 154,
		quantity: 190,
	},
	{
		garage_id: 209,
		part_id: 155,
		quantity: 18,
	},
	{
		garage_id: 209,
		part_id: 156,
		quantity: 363,
	},
	{
		garage_id: 209,
		part_id: 157,
		quantity: 16,
	},
	{
		garage_id: 209,
		part_id: 158,
		quantity: 248,
	},
	{
		garage_id: 209,
		part_id: 159,
		quantity: 372,
	},
	{
		garage_id: 209,
		part_id: 160,
		quantity: 199,
	},
	{
		garage_id: 209,
		part_id: 161,
		quantity: 406,
	},
	{
		garage_id: 209,
		part_id: 162,
		quantity: 109,
	},
	{
		garage_id: 209,
		part_id: 163,
		quantity: 39,
	},
	{
		garage_id: 209,
		part_id: 164,
		quantity: 387,
	},
	{
		garage_id: 209,
		part_id: 165,
		quantity: 385,
	},
	{
		garage_id: 209,
		part_id: 166,
		quantity: 257,
	},
	{
		garage_id: 209,
		part_id: 167,
		quantity: 466,
	},
	{
		garage_id: 209,
		part_id: 168,
		quantity: 284,
	},
	{
		garage_id: 209,
		part_id: 169,
		quantity: 76,
	},
	{
		garage_id: 209,
		part_id: 170,
		quantity: 481,
	},
	{
		garage_id: 209,
		part_id: 171,
		quantity: 271,
	},
	{
		garage_id: 209,
		part_id: 172,
		quantity: 425,
	},
	{
		garage_id: 209,
		part_id: 173,
		quantity: 382,
	},
	{
		garage_id: 209,
		part_id: 174,
		quantity: 380,
	},
	{
		garage_id: 209,
		part_id: 175,
		quantity: 93,
	},
	{
		garage_id: 209,
		part_id: 176,
		quantity: 150,
	},
	{
		garage_id: 209,
		part_id: 177,
		quantity: 218,
	},
	{
		garage_id: 209,
		part_id: 178,
		quantity: 495,
	},
	{
		garage_id: 209,
		part_id: 179,
		quantity: 56,
	},
	{
		garage_id: 209,
		part_id: 180,
		quantity: 397,
	},
	{
		garage_id: 209,
		part_id: 181,
		quantity: 232,
	},
	{
		garage_id: 209,
		part_id: 182,
		quantity: 120,
	},
	{
		garage_id: 209,
		part_id: 183,
		quantity: 137,
	},
	{
		garage_id: 209,
		part_id: 184,
		quantity: 307,
	},
	{
		garage_id: 209,
		part_id: 185,
		quantity: 3,
	},
	{
		garage_id: 209,
		part_id: 186,
		quantity: 358,
	},
	{
		garage_id: 209,
		part_id: 187,
		quantity: 296,
	},
	{
		garage_id: 209,
		part_id: 188,
		quantity: 135,
	},
	{
		garage_id: 209,
		part_id: 189,
		quantity: 334,
	},
	{
		garage_id: 209,
		part_id: 190,
		quantity: 334,
	},
	{
		garage_id: 209,
		part_id: 191,
		quantity: 393,
	},
	{
		garage_id: 209,
		part_id: 192,
		quantity: 20,
	},
	{
		garage_id: 209,
		part_id: 193,
		quantity: 299,
	},
	{
		garage_id: 209,
		part_id: 194,
		quantity: 174,
	},
	{
		garage_id: 209,
		part_id: 195,
		quantity: 151,
	},
	{
		garage_id: 209,
		part_id: 196,
		quantity: 51,
	},
	{
		garage_id: 209,
		part_id: 197,
		quantity: 230,
	},
	{
		garage_id: 209,
		part_id: 198,
		quantity: 434,
	},
	{
		garage_id: 209,
		part_id: 199,
		quantity: 21,
	},
	{
		garage_id: 209,
		part_id: 200,
		quantity: 436,
	},
	{
		garage_id: 209,
		part_id: 201,
		quantity: 76,
	},
	{
		garage_id: 209,
		part_id: 202,
		quantity: 299,
	},
	{
		garage_id: 209,
		part_id: 203,
		quantity: 303,
	},
	{
		garage_id: 209,
		part_id: 204,
		quantity: 467,
	},
	{
		garage_id: 209,
		part_id: 205,
		quantity: 236,
	},
	{
		garage_id: 209,
		part_id: 206,
		quantity: 419,
	},
	{
		garage_id: 209,
		part_id: 207,
		quantity: 110,
	},
	{
		garage_id: 209,
		part_id: 208,
		quantity: 390,
	},
	{
		garage_id: 209,
		part_id: 209,
		quantity: 200,
	},
	{
		garage_id: 209,
		part_id: 210,
		quantity: 300,
	},
	{
		garage_id: 209,
		part_id: 211,
		quantity: 68,
	},
	{
		garage_id: 209,
		part_id: 212,
		quantity: 374,
	},
	{
		garage_id: 209,
		part_id: 213,
		quantity: 497,
	},
	{
		garage_id: 209,
		part_id: 214,
		quantity: 198,
	},
	{
		garage_id: 209,
		part_id: 215,
		quantity: 246,
	},
	{
		garage_id: 209,
		part_id: 216,
		quantity: 340,
	},
	{
		garage_id: 209,
		part_id: 217,
		quantity: 391,
	},
	{
		garage_id: 209,
		part_id: 218,
		quantity: 157,
	},
	{
		garage_id: 209,
		part_id: 219,
		quantity: 277,
	},
	{
		garage_id: 209,
		part_id: 220,
		quantity: 489,
	},
	{
		garage_id: 209,
		part_id: 221,
		quantity: 304,
	},
	{
		garage_id: 209,
		part_id: 222,
		quantity: 107,
	},
	{
		garage_id: 209,
		part_id: 223,
		quantity: 396,
	},
	{
		garage_id: 209,
		part_id: 224,
		quantity: 420,
	},
	{
		garage_id: 209,
		part_id: 225,
		quantity: 497,
	},
	{
		garage_id: 209,
		part_id: 226,
		quantity: 250,
	},
	{
		garage_id: 209,
		part_id: 227,
		quantity: 258,
	},
	{
		garage_id: 209,
		part_id: 228,
		quantity: 56,
	},
	{
		garage_id: 209,
		part_id: 229,
		quantity: 58,
	},
	{
		garage_id: 209,
		part_id: 230,
		quantity: 492,
	},
	{
		garage_id: 209,
		part_id: 231,
		quantity: 67,
	},
	{
		garage_id: 209,
		part_id: 232,
		quantity: 320,
	},
	{
		garage_id: 209,
		part_id: 233,
		quantity: 87,
	},
	{
		garage_id: 209,
		part_id: 234,
		quantity: 33,
	},
	{
		garage_id: 209,
		part_id: 235,
		quantity: 285,
	},
	{
		garage_id: 209,
		part_id: 236,
		quantity: 162,
	},
	{
		garage_id: 209,
		part_id: 237,
		quantity: 395,
	},
	{
		garage_id: 209,
		part_id: 238,
		quantity: 3,
	},
	{
		garage_id: 209,
		part_id: 239,
		quantity: 379,
	},
	{
		garage_id: 209,
		part_id: 240,
		quantity: 366,
	},
	{
		garage_id: 209,
		part_id: 241,
		quantity: 107,
	},
	{
		garage_id: 209,
		part_id: 242,
		quantity: 480,
	},
	{
		garage_id: 209,
		part_id: 243,
		quantity: 435,
	},
	{
		garage_id: 209,
		part_id: 244,
		quantity: 180,
	},
	{
		garage_id: 209,
		part_id: 245,
		quantity: 322,
	},
	{
		garage_id: 209,
		part_id: 246,
		quantity: 440,
	},
	{
		garage_id: 209,
		part_id: 247,
		quantity: 440,
	},
	{
		garage_id: 209,
		part_id: 248,
		quantity: 2,
	},
	{
		garage_id: 209,
		part_id: 249,
		quantity: 500,
	},
	{
		garage_id: 209,
		part_id: 250,
		quantity: 144,
	},
	{
		garage_id: 209,
		part_id: 251,
		quantity: 181,
	},
	{
		garage_id: 209,
		part_id: 252,
		quantity: 89,
	},
	{
		garage_id: 209,
		part_id: 253,
		quantity: 269,
	},
	{
		garage_id: 209,
		part_id: 254,
		quantity: 330,
	},
	{
		garage_id: 209,
		part_id: 255,
		quantity: 186,
	},
	{
		garage_id: 209,
		part_id: 256,
		quantity: 109,
	},
	{
		garage_id: 209,
		part_id: 257,
		quantity: 180,
	},
	{
		garage_id: 209,
		part_id: 258,
		quantity: 451,
	},
	{
		garage_id: 209,
		part_id: 259,
		quantity: 282,
	},
	{
		garage_id: 209,
		part_id: 260,
		quantity: 75,
	},
	{
		garage_id: 209,
		part_id: 261,
		quantity: 25,
	},
	{
		garage_id: 209,
		part_id: 262,
		quantity: 309,
	},
	{
		garage_id: 209,
		part_id: 263,
		quantity: 359,
	},
	{
		garage_id: 209,
		part_id: 264,
		quantity: 227,
	},
	{
		garage_id: 209,
		part_id: 265,
		quantity: 180,
	},
	{
		garage_id: 209,
		part_id: 266,
		quantity: 35,
	},
	{
		garage_id: 209,
		part_id: 267,
		quantity: 270,
	},
	{
		garage_id: 209,
		part_id: 268,
		quantity: 193,
	},
	{
		garage_id: 209,
		part_id: 269,
		quantity: 143,
	},
	{
		garage_id: 209,
		part_id: 270,
		quantity: 454,
	},
	{
		garage_id: 209,
		part_id: 271,
		quantity: 63,
	},
	{
		garage_id: 209,
		part_id: 272,
		quantity: 312,
	},
	{
		garage_id: 209,
		part_id: 273,
		quantity: 5,
	},
	{
		garage_id: 209,
		part_id: 274,
		quantity: 77,
	},
	{
		garage_id: 209,
		part_id: 275,
		quantity: 44,
	},
	{
		garage_id: 209,
		part_id: 276,
		quantity: 8,
	},
	{
		garage_id: 209,
		part_id: 277,
		quantity: 109,
	},
	{
		garage_id: 209,
		part_id: 278,
		quantity: 384,
	},
	{
		garage_id: 209,
		part_id: 279,
		quantity: 80,
	},
	{
		garage_id: 209,
		part_id: 280,
		quantity: 178,
	},
	{
		garage_id: 209,
		part_id: 281,
		quantity: 359,
	},
	{
		garage_id: 209,
		part_id: 282,
		quantity: 117,
	},
	{
		garage_id: 209,
		part_id: 283,
		quantity: 218,
	},
	{
		garage_id: 209,
		part_id: 284,
		quantity: 475,
	},
	{
		garage_id: 209,
		part_id: 285,
		quantity: 157,
	},
	{
		garage_id: 209,
		part_id: 286,
		quantity: 469,
	},
	{
		garage_id: 209,
		part_id: 287,
		quantity: 199,
	},
	{
		garage_id: 209,
		part_id: 288,
		quantity: 414,
	},
	{
		garage_id: 209,
		part_id: 289,
		quantity: 65,
	},
	{
		garage_id: 209,
		part_id: 290,
		quantity: 342,
	},
	{
		garage_id: 209,
		part_id: 291,
		quantity: 169,
	},
	{
		garage_id: 209,
		part_id: 292,
		quantity: 49,
	},
	{
		garage_id: 209,
		part_id: 293,
		quantity: 87,
	},
	{
		garage_id: 209,
		part_id: 294,
		quantity: 47,
	},
	{
		garage_id: 209,
		part_id: 295,
		quantity: 81,
	},
	{
		garage_id: 209,
		part_id: 296,
		quantity: 404,
	},
	{
		garage_id: 209,
		part_id: 297,
		quantity: 147,
	},
	{
		garage_id: 209,
		part_id: 298,
		quantity: 234,
	},
	{
		garage_id: 209,
		part_id: 299,
		quantity: 394,
	},
	{
		garage_id: 209,
		part_id: 300,
		quantity: 268,
	},
	{
		garage_id: 209,
		part_id: 301,
		quantity: 244,
	},
	{
		garage_id: 209,
		part_id: 302,
		quantity: 209,
	},
	{
		garage_id: 209,
		part_id: 303,
		quantity: 177,
	},
	{
		garage_id: 209,
		part_id: 304,
		quantity: 398,
	},
	{
		garage_id: 209,
		part_id: 305,
		quantity: 357,
	},
	{
		garage_id: 209,
		part_id: 306,
		quantity: 342,
	},
	{
		garage_id: 209,
		part_id: 307,
		quantity: 240,
	},
	{
		garage_id: 209,
		part_id: 308,
		quantity: 128,
	},
	{
		garage_id: 209,
		part_id: 309,
		quantity: 41,
	},
	{
		garage_id: 209,
		part_id: 310,
		quantity: 370,
	},
	{
		garage_id: 209,
		part_id: 311,
		quantity: 243,
	},
	{
		garage_id: 209,
		part_id: 312,
		quantity: 417,
	},
	{
		garage_id: 209,
		part_id: 313,
		quantity: 261,
	},
	{
		garage_id: 209,
		part_id: 314,
		quantity: 52,
	},
	{
		garage_id: 209,
		part_id: 315,
		quantity: 448,
	},
	{
		garage_id: 209,
		part_id: 316,
		quantity: 163,
	},
	{
		garage_id: 209,
		part_id: 317,
		quantity: 338,
	},
	{
		garage_id: 209,
		part_id: 318,
		quantity: 53,
	},
	{
		garage_id: 209,
		part_id: 319,
		quantity: 273,
	},
	{
		garage_id: 209,
		part_id: 320,
		quantity: 262,
	},
	{
		garage_id: 209,
		part_id: 321,
		quantity: 498,
	},
	{
		garage_id: 209,
		part_id: 322,
		quantity: 177,
	},
	{
		garage_id: 209,
		part_id: 323,
		quantity: 233,
	},
	{
		garage_id: 209,
		part_id: 324,
		quantity: 176,
	},
	{
		garage_id: 209,
		part_id: 325,
		quantity: 455,
	},
	{
		garage_id: 209,
		part_id: 326,
		quantity: 230,
	},
	{
		garage_id: 209,
		part_id: 327,
		quantity: 223,
	},
	{
		garage_id: 209,
		part_id: 328,
		quantity: 213,
	},
	{
		garage_id: 209,
		part_id: 329,
		quantity: 92,
	},
	{
		garage_id: 209,
		part_id: 330,
		quantity: 386,
	},
	{
		garage_id: 209,
		part_id: 331,
		quantity: 412,
	},
	{
		garage_id: 209,
		part_id: 332,
		quantity: 342,
	},
	{
		garage_id: 209,
		part_id: 333,
		quantity: 26,
	},
	{
		garage_id: 209,
		part_id: 334,
		quantity: 275,
	},
	{
		garage_id: 209,
		part_id: 335,
		quantity: 319,
	},
	{
		garage_id: 209,
		part_id: 336,
		quantity: 476,
	},
	{
		garage_id: 209,
		part_id: 337,
		quantity: 240,
	},
	{
		garage_id: 209,
		part_id: 338,
		quantity: 163,
	},
	{
		garage_id: 209,
		part_id: 339,
		quantity: 312,
	},
	{
		garage_id: 209,
		part_id: 340,
		quantity: 260,
	},
	{
		garage_id: 209,
		part_id: 341,
		quantity: 200,
	},
	{
		garage_id: 209,
		part_id: 342,
		quantity: 152,
	},
	{
		garage_id: 209,
		part_id: 343,
		quantity: 253,
	},
	{
		garage_id: 209,
		part_id: 344,
		quantity: 219,
	},
	{
		garage_id: 209,
		part_id: 345,
		quantity: 39,
	},
	{
		garage_id: 209,
		part_id: 346,
		quantity: 401,
	},
	{
		garage_id: 209,
		part_id: 347,
		quantity: 346,
	},
	{
		garage_id: 209,
		part_id: 348,
		quantity: 83,
	},
	{
		garage_id: 209,
		part_id: 349,
		quantity: 29,
	},
	{
		garage_id: 209,
		part_id: 350,
		quantity: 494,
	},
	{
		garage_id: 209,
		part_id: 351,
		quantity: 283,
	},
	{
		garage_id: 209,
		part_id: 352,
		quantity: 251,
	},
	{
		garage_id: 209,
		part_id: 353,
		quantity: 410,
	},
	{
		garage_id: 209,
		part_id: 354,
		quantity: 250,
	},
	{
		garage_id: 209,
		part_id: 355,
		quantity: 264,
	},
	{
		garage_id: 209,
		part_id: 356,
		quantity: 485,
	},
	{
		garage_id: 209,
		part_id: 357,
		quantity: 442,
	},
	{
		garage_id: 209,
		part_id: 358,
		quantity: 378,
	},
	{
		garage_id: 209,
		part_id: 359,
		quantity: 127,
	},
	{
		garage_id: 209,
		part_id: 360,
		quantity: 73,
	},
	{
		garage_id: 209,
		part_id: 361,
		quantity: 373,
	},
	{
		garage_id: 209,
		part_id: 362,
		quantity: 9,
	},
	{
		garage_id: 209,
		part_id: 363,
		quantity: 292,
	},
	{
		garage_id: 209,
		part_id: 364,
		quantity: 194,
	},
	{
		garage_id: 209,
		part_id: 365,
		quantity: 110,
	},
	{
		garage_id: 209,
		part_id: 366,
		quantity: 198,
	},
	{
		garage_id: 209,
		part_id: 367,
		quantity: 406,
	},
	{
		garage_id: 209,
		part_id: 368,
		quantity: 438,
	},
	{
		garage_id: 209,
		part_id: 369,
		quantity: 438,
	},
	{
		garage_id: 209,
		part_id: 370,
		quantity: 6,
	},
	{
		garage_id: 209,
		part_id: 371,
		quantity: 236,
	},
	{
		garage_id: 209,
		part_id: 372,
		quantity: 336,
	},
	{
		garage_id: 209,
		part_id: 373,
		quantity: 450,
	},
	{
		garage_id: 209,
		part_id: 374,
		quantity: 155,
	},
	{
		garage_id: 209,
		part_id: 375,
		quantity: 116,
	},
	{
		garage_id: 209,
		part_id: 376,
		quantity: 267,
	},
	{
		garage_id: 209,
		part_id: 377,
		quantity: 233,
	},
	{
		garage_id: 209,
		part_id: 378,
		quantity: 202,
	},
	{
		garage_id: 209,
		part_id: 379,
		quantity: 276,
	},
	{
		garage_id: 209,
		part_id: 380,
		quantity: 55,
	},
	{
		garage_id: 209,
		part_id: 381,
		quantity: 7,
	},
	{
		garage_id: 209,
		part_id: 382,
		quantity: 470,
	},
	{
		garage_id: 209,
		part_id: 383,
		quantity: 275,
	},
	{
		garage_id: 209,
		part_id: 384,
		quantity: 93,
	},
	{
		garage_id: 209,
		part_id: 385,
		quantity: 191,
	},
	{
		garage_id: 209,
		part_id: 386,
		quantity: 261,
	},
	{
		garage_id: 209,
		part_id: 387,
		quantity: 21,
	},
	{
		garage_id: 209,
		part_id: 388,
		quantity: 335,
	},
	{
		garage_id: 209,
		part_id: 389,
		quantity: 53,
	},
	{
		garage_id: 209,
		part_id: 390,
		quantity: 447,
	},
	{
		garage_id: 209,
		part_id: 391,
		quantity: 224,
	},
	{
		garage_id: 209,
		part_id: 392,
		quantity: 469,
	},
	{
		garage_id: 209,
		part_id: 393,
		quantity: 156,
	},
	{
		garage_id: 209,
		part_id: 394,
		quantity: 177,
	},
	{
		garage_id: 209,
		part_id: 395,
		quantity: 344,
	},
	{
		garage_id: 209,
		part_id: 396,
		quantity: 275,
	},
	{
		garage_id: 209,
		part_id: 397,
		quantity: 41,
	},
	{
		garage_id: 209,
		part_id: 398,
		quantity: 397,
	},
	{
		garage_id: 209,
		part_id: 399,
		quantity: 298,
	},
	{
		garage_id: 210,
		part_id: 0,
		quantity: 337,
	},
	{
		garage_id: 210,
		part_id: 1,
		quantity: 342,
	},
	{
		garage_id: 210,
		part_id: 2,
		quantity: 15,
	},
	{
		garage_id: 210,
		part_id: 3,
		quantity: 384,
	},
	{
		garage_id: 210,
		part_id: 4,
		quantity: 110,
	},
	{
		garage_id: 210,
		part_id: 5,
		quantity: 364,
	},
	{
		garage_id: 210,
		part_id: 6,
		quantity: 471,
	},
	{
		garage_id: 210,
		part_id: 7,
		quantity: 85,
	},
	{
		garage_id: 210,
		part_id: 8,
		quantity: 417,
	},
	{
		garage_id: 210,
		part_id: 9,
		quantity: 318,
	},
	{
		garage_id: 210,
		part_id: 10,
		quantity: 232,
	},
	{
		garage_id: 210,
		part_id: 11,
		quantity: 138,
	},
	{
		garage_id: 210,
		part_id: 12,
		quantity: 14,
	},
	{
		garage_id: 210,
		part_id: 13,
		quantity: 431,
	},
	{
		garage_id: 210,
		part_id: 14,
		quantity: 365,
	},
	{
		garage_id: 210,
		part_id: 15,
		quantity: 470,
	},
	{
		garage_id: 210,
		part_id: 16,
		quantity: 321,
	},
	{
		garage_id: 210,
		part_id: 17,
		quantity: 280,
	},
	{
		garage_id: 210,
		part_id: 18,
		quantity: 174,
	},
	{
		garage_id: 210,
		part_id: 19,
		quantity: 402,
	},
	{
		garage_id: 210,
		part_id: 20,
		quantity: 301,
	},
	{
		garage_id: 210,
		part_id: 21,
		quantity: 487,
	},
	{
		garage_id: 210,
		part_id: 22,
		quantity: 223,
	},
	{
		garage_id: 210,
		part_id: 23,
		quantity: 234,
	},
	{
		garage_id: 210,
		part_id: 24,
		quantity: 91,
	},
	{
		garage_id: 210,
		part_id: 25,
		quantity: 435,
	},
	{
		garage_id: 210,
		part_id: 26,
		quantity: 201,
	},
	{
		garage_id: 210,
		part_id: 27,
		quantity: 71,
	},
	{
		garage_id: 210,
		part_id: 28,
		quantity: 42,
	},
	{
		garage_id: 210,
		part_id: 29,
		quantity: 73,
	},
	{
		garage_id: 210,
		part_id: 30,
		quantity: 266,
	},
	{
		garage_id: 210,
		part_id: 31,
		quantity: 278,
	},
	{
		garage_id: 210,
		part_id: 32,
		quantity: 397,
	},
	{
		garage_id: 210,
		part_id: 33,
		quantity: 489,
	},
	{
		garage_id: 210,
		part_id: 34,
		quantity: 50,
	},
	{
		garage_id: 210,
		part_id: 35,
		quantity: 176,
	},
	{
		garage_id: 210,
		part_id: 36,
		quantity: 304,
	},
	{
		garage_id: 210,
		part_id: 37,
		quantity: 283,
	},
	{
		garage_id: 210,
		part_id: 38,
		quantity: 421,
	},
	{
		garage_id: 210,
		part_id: 39,
		quantity: 328,
	},
	{
		garage_id: 210,
		part_id: 40,
		quantity: 284,
	},
	{
		garage_id: 210,
		part_id: 41,
		quantity: 451,
	},
	{
		garage_id: 210,
		part_id: 42,
		quantity: 39,
	},
	{
		garage_id: 210,
		part_id: 43,
		quantity: 468,
	},
	{
		garage_id: 210,
		part_id: 44,
		quantity: 61,
	},
	{
		garage_id: 210,
		part_id: 45,
		quantity: 443,
	},
	{
		garage_id: 210,
		part_id: 46,
		quantity: 335,
	},
	{
		garage_id: 210,
		part_id: 47,
		quantity: 312,
	},
	{
		garage_id: 210,
		part_id: 48,
		quantity: 321,
	},
	{
		garage_id: 210,
		part_id: 49,
		quantity: 498,
	},
	{
		garage_id: 210,
		part_id: 50,
		quantity: 198,
	},
	{
		garage_id: 210,
		part_id: 51,
		quantity: 175,
	},
	{
		garage_id: 210,
		part_id: 52,
		quantity: 272,
	},
	{
		garage_id: 210,
		part_id: 53,
		quantity: 458,
	},
	{
		garage_id: 210,
		part_id: 54,
		quantity: 318,
	},
	{
		garage_id: 210,
		part_id: 55,
		quantity: 135,
	},
	{
		garage_id: 210,
		part_id: 56,
		quantity: 180,
	},
	{
		garage_id: 210,
		part_id: 57,
		quantity: 196,
	},
	{
		garage_id: 210,
		part_id: 58,
		quantity: 340,
	},
	{
		garage_id: 210,
		part_id: 59,
		quantity: 488,
	},
	{
		garage_id: 210,
		part_id: 60,
		quantity: 364,
	},
	{
		garage_id: 210,
		part_id: 61,
		quantity: 78,
	},
	{
		garage_id: 210,
		part_id: 62,
		quantity: 428,
	},
	{
		garage_id: 210,
		part_id: 63,
		quantity: 413,
	},
	{
		garage_id: 210,
		part_id: 64,
		quantity: 417,
	},
	{
		garage_id: 210,
		part_id: 65,
		quantity: 435,
	},
	{
		garage_id: 210,
		part_id: 66,
		quantity: 426,
	},
	{
		garage_id: 210,
		part_id: 67,
		quantity: 487,
	},
	{
		garage_id: 210,
		part_id: 68,
		quantity: 96,
	},
	{
		garage_id: 210,
		part_id: 69,
		quantity: 480,
	},
	{
		garage_id: 210,
		part_id: 70,
		quantity: 53,
	},
	{
		garage_id: 210,
		part_id: 71,
		quantity: 74,
	},
	{
		garage_id: 210,
		part_id: 72,
		quantity: 485,
	},
	{
		garage_id: 210,
		part_id: 73,
		quantity: 56,
	},
	{
		garage_id: 210,
		part_id: 74,
		quantity: 274,
	},
	{
		garage_id: 210,
		part_id: 75,
		quantity: 458,
	},
	{
		garage_id: 210,
		part_id: 76,
		quantity: 299,
	},
	{
		garage_id: 210,
		part_id: 77,
		quantity: 377,
	},
	{
		garage_id: 210,
		part_id: 78,
		quantity: 33,
	},
	{
		garage_id: 210,
		part_id: 79,
		quantity: 147,
	},
	{
		garage_id: 210,
		part_id: 80,
		quantity: 330,
	},
	{
		garage_id: 210,
		part_id: 81,
		quantity: 454,
	},
	{
		garage_id: 210,
		part_id: 82,
		quantity: 173,
	},
	{
		garage_id: 210,
		part_id: 83,
		quantity: 472,
	},
	{
		garage_id: 210,
		part_id: 84,
		quantity: 269,
	},
	{
		garage_id: 210,
		part_id: 85,
		quantity: 278,
	},
	{
		garage_id: 210,
		part_id: 86,
		quantity: 332,
	},
	{
		garage_id: 210,
		part_id: 87,
		quantity: 204,
	},
	{
		garage_id: 210,
		part_id: 88,
		quantity: 284,
	},
	{
		garage_id: 210,
		part_id: 89,
		quantity: 351,
	},
	{
		garage_id: 210,
		part_id: 90,
		quantity: 156,
	},
	{
		garage_id: 210,
		part_id: 91,
		quantity: 75,
	},
	{
		garage_id: 210,
		part_id: 92,
		quantity: 454,
	},
	{
		garage_id: 210,
		part_id: 93,
		quantity: 126,
	},
	{
		garage_id: 210,
		part_id: 94,
		quantity: 299,
	},
	{
		garage_id: 210,
		part_id: 95,
		quantity: 114,
	},
	{
		garage_id: 210,
		part_id: 96,
		quantity: 382,
	},
	{
		garage_id: 210,
		part_id: 97,
		quantity: 73,
	},
	{
		garage_id: 210,
		part_id: 98,
		quantity: 87,
	},
	{
		garage_id: 210,
		part_id: 99,
		quantity: 72,
	},
	{
		garage_id: 210,
		part_id: 100,
		quantity: 497,
	},
	{
		garage_id: 210,
		part_id: 101,
		quantity: 7,
	},
	{
		garage_id: 210,
		part_id: 102,
		quantity: 239,
	},
	{
		garage_id: 210,
		part_id: 103,
		quantity: 135,
	},
	{
		garage_id: 210,
		part_id: 104,
		quantity: 387,
	},
	{
		garage_id: 210,
		part_id: 105,
		quantity: 327,
	},
	{
		garage_id: 210,
		part_id: 106,
		quantity: 46,
	},
	{
		garage_id: 210,
		part_id: 107,
		quantity: 415,
	},
	{
		garage_id: 210,
		part_id: 108,
		quantity: 127,
	},
	{
		garage_id: 210,
		part_id: 109,
		quantity: 1,
	},
	{
		garage_id: 210,
		part_id: 110,
		quantity: 208,
	},
	{
		garage_id: 210,
		part_id: 111,
		quantity: 244,
	},
	{
		garage_id: 210,
		part_id: 112,
		quantity: 321,
	},
	{
		garage_id: 210,
		part_id: 113,
		quantity: 30,
	},
	{
		garage_id: 210,
		part_id: 114,
		quantity: 43,
	},
	{
		garage_id: 210,
		part_id: 115,
		quantity: 115,
	},
	{
		garage_id: 210,
		part_id: 116,
		quantity: 495,
	},
	{
		garage_id: 210,
		part_id: 117,
		quantity: 489,
	},
	{
		garage_id: 210,
		part_id: 118,
		quantity: 90,
	},
	{
		garage_id: 210,
		part_id: 119,
		quantity: 344,
	},
	{
		garage_id: 210,
		part_id: 120,
		quantity: 43,
	},
	{
		garage_id: 210,
		part_id: 121,
		quantity: 337,
	},
	{
		garage_id: 210,
		part_id: 122,
		quantity: 167,
	},
	{
		garage_id: 210,
		part_id: 123,
		quantity: 272,
	},
	{
		garage_id: 210,
		part_id: 124,
		quantity: 357,
	},
	{
		garage_id: 210,
		part_id: 125,
		quantity: 53,
	},
	{
		garage_id: 210,
		part_id: 126,
		quantity: 442,
	},
	{
		garage_id: 210,
		part_id: 127,
		quantity: 489,
	},
	{
		garage_id: 210,
		part_id: 128,
		quantity: 390,
	},
	{
		garage_id: 210,
		part_id: 129,
		quantity: 156,
	},
	{
		garage_id: 210,
		part_id: 130,
		quantity: 414,
	},
	{
		garage_id: 210,
		part_id: 131,
		quantity: 193,
	},
	{
		garage_id: 210,
		part_id: 132,
		quantity: 107,
	},
	{
		garage_id: 210,
		part_id: 133,
		quantity: 136,
	},
	{
		garage_id: 210,
		part_id: 134,
		quantity: 479,
	},
	{
		garage_id: 210,
		part_id: 135,
		quantity: 210,
	},
	{
		garage_id: 210,
		part_id: 136,
		quantity: 466,
	},
	{
		garage_id: 210,
		part_id: 137,
		quantity: 394,
	},
	{
		garage_id: 210,
		part_id: 138,
		quantity: 390,
	},
	{
		garage_id: 210,
		part_id: 139,
		quantity: 411,
	},
	{
		garage_id: 210,
		part_id: 140,
		quantity: 116,
	},
	{
		garage_id: 210,
		part_id: 141,
		quantity: 308,
	},
	{
		garage_id: 210,
		part_id: 142,
		quantity: 121,
	},
	{
		garage_id: 210,
		part_id: 143,
		quantity: 474,
	},
	{
		garage_id: 210,
		part_id: 144,
		quantity: 1,
	},
	{
		garage_id: 210,
		part_id: 145,
		quantity: 489,
	},
	{
		garage_id: 210,
		part_id: 146,
		quantity: 94,
	},
	{
		garage_id: 210,
		part_id: 147,
		quantity: 490,
	},
	{
		garage_id: 210,
		part_id: 148,
		quantity: 355,
	},
	{
		garage_id: 210,
		part_id: 149,
		quantity: 189,
	},
	{
		garage_id: 210,
		part_id: 150,
		quantity: 500,
	},
	{
		garage_id: 210,
		part_id: 151,
		quantity: 120,
	},
	{
		garage_id: 210,
		part_id: 152,
		quantity: 458,
	},
	{
		garage_id: 210,
		part_id: 153,
		quantity: 78,
	},
	{
		garage_id: 210,
		part_id: 154,
		quantity: 475,
	},
	{
		garage_id: 210,
		part_id: 155,
		quantity: 33,
	},
	{
		garage_id: 210,
		part_id: 156,
		quantity: 342,
	},
	{
		garage_id: 210,
		part_id: 157,
		quantity: 217,
	},
	{
		garage_id: 210,
		part_id: 158,
		quantity: 355,
	},
	{
		garage_id: 210,
		part_id: 159,
		quantity: 242,
	},
	{
		garage_id: 210,
		part_id: 160,
		quantity: 47,
	},
	{
		garage_id: 210,
		part_id: 161,
		quantity: 421,
	},
	{
		garage_id: 210,
		part_id: 162,
		quantity: 401,
	},
	{
		garage_id: 210,
		part_id: 163,
		quantity: 268,
	},
	{
		garage_id: 210,
		part_id: 164,
		quantity: 205,
	},
	{
		garage_id: 210,
		part_id: 165,
		quantity: 192,
	},
	{
		garage_id: 210,
		part_id: 166,
		quantity: 127,
	},
	{
		garage_id: 210,
		part_id: 167,
		quantity: 78,
	},
	{
		garage_id: 210,
		part_id: 168,
		quantity: 289,
	},
	{
		garage_id: 210,
		part_id: 169,
		quantity: 415,
	},
	{
		garage_id: 210,
		part_id: 170,
		quantity: 144,
	},
	{
		garage_id: 210,
		part_id: 171,
		quantity: 344,
	},
	{
		garage_id: 210,
		part_id: 172,
		quantity: 386,
	},
	{
		garage_id: 210,
		part_id: 173,
		quantity: 132,
	},
	{
		garage_id: 210,
		part_id: 174,
		quantity: 211,
	},
	{
		garage_id: 210,
		part_id: 175,
		quantity: 68,
	},
	{
		garage_id: 210,
		part_id: 176,
		quantity: 274,
	},
	{
		garage_id: 210,
		part_id: 177,
		quantity: 362,
	},
	{
		garage_id: 210,
		part_id: 178,
		quantity: 384,
	},
	{
		garage_id: 210,
		part_id: 179,
		quantity: 236,
	},
	{
		garage_id: 210,
		part_id: 180,
		quantity: 265,
	},
	{
		garage_id: 210,
		part_id: 181,
		quantity: 51,
	},
	{
		garage_id: 210,
		part_id: 182,
		quantity: 365,
	},
	{
		garage_id: 210,
		part_id: 183,
		quantity: 372,
	},
	{
		garage_id: 210,
		part_id: 184,
		quantity: 104,
	},
	{
		garage_id: 210,
		part_id: 185,
		quantity: 332,
	},
	{
		garage_id: 210,
		part_id: 186,
		quantity: 407,
	},
	{
		garage_id: 210,
		part_id: 187,
		quantity: 356,
	},
	{
		garage_id: 210,
		part_id: 188,
		quantity: 68,
	},
	{
		garage_id: 210,
		part_id: 189,
		quantity: 236,
	},
	{
		garage_id: 210,
		part_id: 190,
		quantity: 443,
	},
	{
		garage_id: 210,
		part_id: 191,
		quantity: 6,
	},
	{
		garage_id: 210,
		part_id: 192,
		quantity: 347,
	},
	{
		garage_id: 210,
		part_id: 193,
		quantity: 319,
	},
	{
		garage_id: 210,
		part_id: 194,
		quantity: 152,
	},
	{
		garage_id: 210,
		part_id: 195,
		quantity: 268,
	},
	{
		garage_id: 210,
		part_id: 196,
		quantity: 39,
	},
	{
		garage_id: 210,
		part_id: 197,
		quantity: 358,
	},
	{
		garage_id: 210,
		part_id: 198,
		quantity: 27,
	},
	{
		garage_id: 210,
		part_id: 199,
		quantity: 397,
	},
	{
		garage_id: 210,
		part_id: 200,
		quantity: 79,
	},
	{
		garage_id: 210,
		part_id: 201,
		quantity: 188,
	},
	{
		garage_id: 210,
		part_id: 202,
		quantity: 297,
	},
	{
		garage_id: 210,
		part_id: 203,
		quantity: 473,
	},
	{
		garage_id: 210,
		part_id: 204,
		quantity: 41,
	},
	{
		garage_id: 210,
		part_id: 205,
		quantity: 447,
	},
	{
		garage_id: 210,
		part_id: 206,
		quantity: 292,
	},
	{
		garage_id: 210,
		part_id: 207,
		quantity: 341,
	},
	{
		garage_id: 210,
		part_id: 208,
		quantity: 156,
	},
	{
		garage_id: 210,
		part_id: 209,
		quantity: 308,
	},
	{
		garage_id: 210,
		part_id: 210,
		quantity: 435,
	},
	{
		garage_id: 210,
		part_id: 211,
		quantity: 40,
	},
	{
		garage_id: 210,
		part_id: 212,
		quantity: 351,
	},
	{
		garage_id: 210,
		part_id: 213,
		quantity: 173,
	},
	{
		garage_id: 210,
		part_id: 214,
		quantity: 61,
	},
	{
		garage_id: 210,
		part_id: 215,
		quantity: 220,
	},
	{
		garage_id: 210,
		part_id: 216,
		quantity: 262,
	},
	{
		garage_id: 210,
		part_id: 217,
		quantity: 283,
	},
	{
		garage_id: 210,
		part_id: 218,
		quantity: 385,
	},
	{
		garage_id: 210,
		part_id: 219,
		quantity: 441,
	},
	{
		garage_id: 210,
		part_id: 220,
		quantity: 431,
	},
	{
		garage_id: 210,
		part_id: 221,
		quantity: 284,
	},
	{
		garage_id: 210,
		part_id: 222,
		quantity: 432,
	},
	{
		garage_id: 210,
		part_id: 223,
		quantity: 374,
	},
	{
		garage_id: 210,
		part_id: 224,
		quantity: 468,
	},
	{
		garage_id: 210,
		part_id: 225,
		quantity: 236,
	},
	{
		garage_id: 210,
		part_id: 226,
		quantity: 273,
	},
	{
		garage_id: 210,
		part_id: 227,
		quantity: 331,
	},
	{
		garage_id: 210,
		part_id: 228,
		quantity: 70,
	},
	{
		garage_id: 210,
		part_id: 229,
		quantity: 353,
	},
	{
		garage_id: 210,
		part_id: 230,
		quantity: 34,
	},
	{
		garage_id: 210,
		part_id: 231,
		quantity: 352,
	},
	{
		garage_id: 210,
		part_id: 232,
		quantity: 415,
	},
	{
		garage_id: 210,
		part_id: 233,
		quantity: 432,
	},
	{
		garage_id: 210,
		part_id: 234,
		quantity: 81,
	},
	{
		garage_id: 210,
		part_id: 235,
		quantity: 216,
	},
	{
		garage_id: 210,
		part_id: 236,
		quantity: 317,
	},
	{
		garage_id: 210,
		part_id: 237,
		quantity: 327,
	},
	{
		garage_id: 210,
		part_id: 238,
		quantity: 230,
	},
	{
		garage_id: 210,
		part_id: 239,
		quantity: 27,
	},
	{
		garage_id: 210,
		part_id: 240,
		quantity: 139,
	},
	{
		garage_id: 210,
		part_id: 241,
		quantity: 197,
	},
	{
		garage_id: 210,
		part_id: 242,
		quantity: 117,
	},
	{
		garage_id: 210,
		part_id: 243,
		quantity: 250,
	},
	{
		garage_id: 210,
		part_id: 244,
		quantity: 338,
	},
	{
		garage_id: 210,
		part_id: 245,
		quantity: 314,
	},
	{
		garage_id: 210,
		part_id: 246,
		quantity: 187,
	},
	{
		garage_id: 210,
		part_id: 247,
		quantity: 325,
	},
	{
		garage_id: 210,
		part_id: 248,
		quantity: 410,
	},
	{
		garage_id: 210,
		part_id: 249,
		quantity: 379,
	},
	{
		garage_id: 210,
		part_id: 250,
		quantity: 418,
	},
	{
		garage_id: 210,
		part_id: 251,
		quantity: 489,
	},
	{
		garage_id: 210,
		part_id: 252,
		quantity: 329,
	},
	{
		garage_id: 210,
		part_id: 253,
		quantity: 483,
	},
	{
		garage_id: 210,
		part_id: 254,
		quantity: 106,
	},
	{
		garage_id: 210,
		part_id: 255,
		quantity: 260,
	},
	{
		garage_id: 210,
		part_id: 256,
		quantity: 397,
	},
	{
		garage_id: 210,
		part_id: 257,
		quantity: 403,
	},
	{
		garage_id: 210,
		part_id: 258,
		quantity: 287,
	},
	{
		garage_id: 210,
		part_id: 259,
		quantity: 71,
	},
	{
		garage_id: 210,
		part_id: 260,
		quantity: 371,
	},
	{
		garage_id: 210,
		part_id: 261,
		quantity: 13,
	},
	{
		garage_id: 210,
		part_id: 262,
		quantity: 456,
	},
	{
		garage_id: 210,
		part_id: 263,
		quantity: 309,
	},
	{
		garage_id: 210,
		part_id: 264,
		quantity: 471,
	},
	{
		garage_id: 210,
		part_id: 265,
		quantity: 192,
	},
	{
		garage_id: 210,
		part_id: 266,
		quantity: 471,
	},
	{
		garage_id: 210,
		part_id: 267,
		quantity: 340,
	},
	{
		garage_id: 210,
		part_id: 268,
		quantity: 153,
	},
	{
		garage_id: 210,
		part_id: 269,
		quantity: 1,
	},
	{
		garage_id: 210,
		part_id: 270,
		quantity: 75,
	},
	{
		garage_id: 210,
		part_id: 271,
		quantity: 93,
	},
	{
		garage_id: 210,
		part_id: 272,
		quantity: 355,
	},
	{
		garage_id: 210,
		part_id: 273,
		quantity: 52,
	},
	{
		garage_id: 210,
		part_id: 274,
		quantity: 194,
	},
	{
		garage_id: 210,
		part_id: 275,
		quantity: 122,
	},
	{
		garage_id: 210,
		part_id: 276,
		quantity: 390,
	},
	{
		garage_id: 210,
		part_id: 277,
		quantity: 407,
	},
	{
		garage_id: 210,
		part_id: 278,
		quantity: 231,
	},
	{
		garage_id: 210,
		part_id: 279,
		quantity: 397,
	},
	{
		garage_id: 210,
		part_id: 280,
		quantity: 82,
	},
	{
		garage_id: 210,
		part_id: 281,
		quantity: 334,
	},
	{
		garage_id: 210,
		part_id: 282,
		quantity: 433,
	},
	{
		garage_id: 210,
		part_id: 283,
		quantity: 35,
	},
	{
		garage_id: 210,
		part_id: 284,
		quantity: 382,
	},
	{
		garage_id: 210,
		part_id: 285,
		quantity: 19,
	},
	{
		garage_id: 210,
		part_id: 286,
		quantity: 232,
	},
	{
		garage_id: 210,
		part_id: 287,
		quantity: 20,
	},
	{
		garage_id: 210,
		part_id: 288,
		quantity: 232,
	},
	{
		garage_id: 210,
		part_id: 289,
		quantity: 393,
	},
	{
		garage_id: 210,
		part_id: 290,
		quantity: 272,
	},
	{
		garage_id: 210,
		part_id: 291,
		quantity: 448,
	},
	{
		garage_id: 210,
		part_id: 292,
		quantity: 462,
	},
	{
		garage_id: 210,
		part_id: 293,
		quantity: 28,
	},
	{
		garage_id: 210,
		part_id: 294,
		quantity: 324,
	},
	{
		garage_id: 210,
		part_id: 295,
		quantity: 221,
	},
	{
		garage_id: 210,
		part_id: 296,
		quantity: 357,
	},
	{
		garage_id: 210,
		part_id: 297,
		quantity: 238,
	},
	{
		garage_id: 210,
		part_id: 298,
		quantity: 218,
	},
	{
		garage_id: 210,
		part_id: 299,
		quantity: 467,
	},
	{
		garage_id: 210,
		part_id: 300,
		quantity: 245,
	},
	{
		garage_id: 210,
		part_id: 301,
		quantity: 28,
	},
	{
		garage_id: 210,
		part_id: 302,
		quantity: 431,
	},
	{
		garage_id: 210,
		part_id: 303,
		quantity: 44,
	},
	{
		garage_id: 210,
		part_id: 304,
		quantity: 368,
	},
	{
		garage_id: 210,
		part_id: 305,
		quantity: 148,
	},
	{
		garage_id: 210,
		part_id: 306,
		quantity: 233,
	},
	{
		garage_id: 210,
		part_id: 307,
		quantity: 325,
	},
	{
		garage_id: 210,
		part_id: 308,
		quantity: 28,
	},
	{
		garage_id: 210,
		part_id: 309,
		quantity: 500,
	},
	{
		garage_id: 210,
		part_id: 310,
		quantity: 114,
	},
	{
		garage_id: 210,
		part_id: 311,
		quantity: 307,
	},
	{
		garage_id: 210,
		part_id: 312,
		quantity: 252,
	},
	{
		garage_id: 210,
		part_id: 313,
		quantity: 16,
	},
	{
		garage_id: 210,
		part_id: 314,
		quantity: 470,
	},
	{
		garage_id: 210,
		part_id: 315,
		quantity: 370,
	},
	{
		garage_id: 210,
		part_id: 316,
		quantity: 218,
	},
	{
		garage_id: 210,
		part_id: 317,
		quantity: 314,
	},
	{
		garage_id: 210,
		part_id: 318,
		quantity: 123,
	},
	{
		garage_id: 210,
		part_id: 319,
		quantity: 10,
	},
	{
		garage_id: 210,
		part_id: 320,
		quantity: 356,
	},
	{
		garage_id: 210,
		part_id: 321,
		quantity: 423,
	},
	{
		garage_id: 210,
		part_id: 322,
		quantity: 164,
	},
	{
		garage_id: 210,
		part_id: 323,
		quantity: 412,
	},
	{
		garage_id: 210,
		part_id: 324,
		quantity: 55,
	},
	{
		garage_id: 210,
		part_id: 325,
		quantity: 328,
	},
	{
		garage_id: 210,
		part_id: 326,
		quantity: 396,
	},
	{
		garage_id: 210,
		part_id: 327,
		quantity: 121,
	},
	{
		garage_id: 210,
		part_id: 328,
		quantity: 155,
	},
	{
		garage_id: 210,
		part_id: 329,
		quantity: 104,
	},
	{
		garage_id: 210,
		part_id: 330,
		quantity: 14,
	},
	{
		garage_id: 210,
		part_id: 331,
		quantity: 486,
	},
	{
		garage_id: 210,
		part_id: 332,
		quantity: 402,
	},
	{
		garage_id: 210,
		part_id: 333,
		quantity: 253,
	},
	{
		garage_id: 210,
		part_id: 334,
		quantity: 266,
	},
	{
		garage_id: 210,
		part_id: 335,
		quantity: 431,
	},
	{
		garage_id: 210,
		part_id: 336,
		quantity: 78,
	},
	{
		garage_id: 210,
		part_id: 337,
		quantity: 472,
	},
	{
		garage_id: 210,
		part_id: 338,
		quantity: 417,
	},
	{
		garage_id: 210,
		part_id: 339,
		quantity: 252,
	},
	{
		garage_id: 210,
		part_id: 340,
		quantity: 315,
	},
	{
		garage_id: 210,
		part_id: 341,
		quantity: 297,
	},
	{
		garage_id: 210,
		part_id: 342,
		quantity: 467,
	},
	{
		garage_id: 210,
		part_id: 343,
		quantity: 59,
	},
	{
		garage_id: 210,
		part_id: 344,
		quantity: 381,
	},
	{
		garage_id: 210,
		part_id: 345,
		quantity: 113,
	},
	{
		garage_id: 210,
		part_id: 346,
		quantity: 119,
	},
	{
		garage_id: 210,
		part_id: 347,
		quantity: 336,
	},
	{
		garage_id: 210,
		part_id: 348,
		quantity: 109,
	},
	{
		garage_id: 210,
		part_id: 349,
		quantity: 460,
	},
	{
		garage_id: 210,
		part_id: 350,
		quantity: 135,
	},
	{
		garage_id: 210,
		part_id: 351,
		quantity: 203,
	},
	{
		garage_id: 210,
		part_id: 352,
		quantity: 477,
	},
	{
		garage_id: 210,
		part_id: 353,
		quantity: 456,
	},
	{
		garage_id: 210,
		part_id: 354,
		quantity: 102,
	},
	{
		garage_id: 210,
		part_id: 355,
		quantity: 118,
	},
	{
		garage_id: 210,
		part_id: 356,
		quantity: 29,
	},
	{
		garage_id: 210,
		part_id: 357,
		quantity: 206,
	},
	{
		garage_id: 210,
		part_id: 358,
		quantity: 492,
	},
	{
		garage_id: 210,
		part_id: 359,
		quantity: 328,
	},
	{
		garage_id: 210,
		part_id: 360,
		quantity: 35,
	},
	{
		garage_id: 210,
		part_id: 361,
		quantity: 122,
	},
	{
		garage_id: 210,
		part_id: 362,
		quantity: 263,
	},
	{
		garage_id: 210,
		part_id: 363,
		quantity: 343,
	},
	{
		garage_id: 210,
		part_id: 364,
		quantity: 448,
	},
	{
		garage_id: 210,
		part_id: 365,
		quantity: 125,
	},
	{
		garage_id: 210,
		part_id: 366,
		quantity: 100,
	},
	{
		garage_id: 210,
		part_id: 367,
		quantity: 494,
	},
	{
		garage_id: 210,
		part_id: 368,
		quantity: 455,
	},
	{
		garage_id: 210,
		part_id: 369,
		quantity: 145,
	},
	{
		garage_id: 210,
		part_id: 370,
		quantity: 85,
	},
	{
		garage_id: 210,
		part_id: 371,
		quantity: 244,
	},
	{
		garage_id: 210,
		part_id: 372,
		quantity: 29,
	},
	{
		garage_id: 210,
		part_id: 373,
		quantity: 310,
	},
	{
		garage_id: 210,
		part_id: 374,
		quantity: 259,
	},
	{
		garage_id: 210,
		part_id: 375,
		quantity: 385,
	},
	{
		garage_id: 210,
		part_id: 376,
		quantity: 428,
	},
	{
		garage_id: 210,
		part_id: 377,
		quantity: 13,
	},
	{
		garage_id: 210,
		part_id: 378,
		quantity: 377,
	},
	{
		garage_id: 210,
		part_id: 379,
		quantity: 453,
	},
	{
		garage_id: 210,
		part_id: 380,
		quantity: 254,
	},
	{
		garage_id: 210,
		part_id: 381,
		quantity: 242,
	},
	{
		garage_id: 210,
		part_id: 382,
		quantity: 390,
	},
	{
		garage_id: 210,
		part_id: 383,
		quantity: 137,
	},
	{
		garage_id: 210,
		part_id: 384,
		quantity: 269,
	},
	{
		garage_id: 210,
		part_id: 385,
		quantity: 187,
	},
	{
		garage_id: 210,
		part_id: 386,
		quantity: 486,
	},
	{
		garage_id: 210,
		part_id: 387,
		quantity: 136,
	},
	{
		garage_id: 210,
		part_id: 388,
		quantity: 43,
	},
	{
		garage_id: 210,
		part_id: 389,
		quantity: 314,
	},
	{
		garage_id: 210,
		part_id: 390,
		quantity: 69,
	},
	{
		garage_id: 210,
		part_id: 391,
		quantity: 116,
	},
	{
		garage_id: 210,
		part_id: 392,
		quantity: 80,
	},
	{
		garage_id: 210,
		part_id: 393,
		quantity: 278,
	},
	{
		garage_id: 210,
		part_id: 394,
		quantity: 41,
	},
	{
		garage_id: 210,
		part_id: 395,
		quantity: 259,
	},
	{
		garage_id: 210,
		part_id: 396,
		quantity: 446,
	},
	{
		garage_id: 210,
		part_id: 397,
		quantity: 452,
	},
	{
		garage_id: 210,
		part_id: 398,
		quantity: 61,
	},
	{
		garage_id: 210,
		part_id: 399,
		quantity: 186,
	},
	{
		garage_id: 211,
		part_id: 0,
		quantity: 55,
	},
	{
		garage_id: 211,
		part_id: 1,
		quantity: 19,
	},
	{
		garage_id: 211,
		part_id: 2,
		quantity: 123,
	},
	{
		garage_id: 211,
		part_id: 3,
		quantity: 278,
	},
	{
		garage_id: 211,
		part_id: 4,
		quantity: 252,
	},
	{
		garage_id: 211,
		part_id: 5,
		quantity: 450,
	},
	{
		garage_id: 211,
		part_id: 6,
		quantity: 453,
	},
	{
		garage_id: 211,
		part_id: 7,
		quantity: 451,
	},
	{
		garage_id: 211,
		part_id: 8,
		quantity: 285,
	},
	{
		garage_id: 211,
		part_id: 9,
		quantity: 345,
	},
	{
		garage_id: 211,
		part_id: 10,
		quantity: 429,
	},
	{
		garage_id: 211,
		part_id: 11,
		quantity: 211,
	},
	{
		garage_id: 211,
		part_id: 12,
		quantity: 282,
	},
	{
		garage_id: 211,
		part_id: 13,
		quantity: 286,
	},
	{
		garage_id: 211,
		part_id: 14,
		quantity: 377,
	},
	{
		garage_id: 211,
		part_id: 15,
		quantity: 360,
	},
	{
		garage_id: 211,
		part_id: 16,
		quantity: 271,
	},
	{
		garage_id: 211,
		part_id: 17,
		quantity: 43,
	},
	{
		garage_id: 211,
		part_id: 18,
		quantity: 135,
	},
	{
		garage_id: 211,
		part_id: 19,
		quantity: 45,
	},
	{
		garage_id: 211,
		part_id: 20,
		quantity: 499,
	},
	{
		garage_id: 211,
		part_id: 21,
		quantity: 476,
	},
	{
		garage_id: 211,
		part_id: 22,
		quantity: 473,
	},
	{
		garage_id: 211,
		part_id: 23,
		quantity: 3,
	},
	{
		garage_id: 211,
		part_id: 24,
		quantity: 49,
	},
	{
		garage_id: 211,
		part_id: 25,
		quantity: 166,
	},
	{
		garage_id: 211,
		part_id: 26,
		quantity: 457,
	},
	{
		garage_id: 211,
		part_id: 27,
		quantity: 500,
	},
	{
		garage_id: 211,
		part_id: 28,
		quantity: 6,
	},
	{
		garage_id: 211,
		part_id: 29,
		quantity: 498,
	},
	{
		garage_id: 211,
		part_id: 30,
		quantity: 206,
	},
	{
		garage_id: 211,
		part_id: 31,
		quantity: 107,
	},
	{
		garage_id: 211,
		part_id: 32,
		quantity: 408,
	},
	{
		garage_id: 211,
		part_id: 33,
		quantity: 53,
	},
	{
		garage_id: 211,
		part_id: 34,
		quantity: 305,
	},
	{
		garage_id: 211,
		part_id: 35,
		quantity: 468,
	},
	{
		garage_id: 211,
		part_id: 36,
		quantity: 130,
	},
	{
		garage_id: 211,
		part_id: 37,
		quantity: 87,
	},
	{
		garage_id: 211,
		part_id: 38,
		quantity: 369,
	},
	{
		garage_id: 211,
		part_id: 39,
		quantity: 195,
	},
	{
		garage_id: 211,
		part_id: 40,
		quantity: 194,
	},
	{
		garage_id: 211,
		part_id: 41,
		quantity: 417,
	},
	{
		garage_id: 211,
		part_id: 42,
		quantity: 265,
	},
	{
		garage_id: 211,
		part_id: 43,
		quantity: 419,
	},
	{
		garage_id: 211,
		part_id: 44,
		quantity: 398,
	},
	{
		garage_id: 211,
		part_id: 45,
		quantity: 288,
	},
	{
		garage_id: 211,
		part_id: 46,
		quantity: 489,
	},
	{
		garage_id: 211,
		part_id: 47,
		quantity: 434,
	},
	{
		garage_id: 211,
		part_id: 48,
		quantity: 191,
	},
	{
		garage_id: 211,
		part_id: 49,
		quantity: 176,
	},
	{
		garage_id: 211,
		part_id: 50,
		quantity: 236,
	},
	{
		garage_id: 211,
		part_id: 51,
		quantity: 431,
	},
	{
		garage_id: 211,
		part_id: 52,
		quantity: 415,
	},
	{
		garage_id: 211,
		part_id: 53,
		quantity: 362,
	},
	{
		garage_id: 211,
		part_id: 54,
		quantity: 263,
	},
	{
		garage_id: 211,
		part_id: 55,
		quantity: 335,
	},
	{
		garage_id: 211,
		part_id: 56,
		quantity: 356,
	},
	{
		garage_id: 211,
		part_id: 57,
		quantity: 400,
	},
	{
		garage_id: 211,
		part_id: 58,
		quantity: 212,
	},
	{
		garage_id: 211,
		part_id: 59,
		quantity: 461,
	},
	{
		garage_id: 211,
		part_id: 60,
		quantity: 133,
	},
	{
		garage_id: 211,
		part_id: 61,
		quantity: 414,
	},
	{
		garage_id: 211,
		part_id: 62,
		quantity: 316,
	},
	{
		garage_id: 211,
		part_id: 63,
		quantity: 158,
	},
	{
		garage_id: 211,
		part_id: 64,
		quantity: 344,
	},
	{
		garage_id: 211,
		part_id: 65,
		quantity: 349,
	},
	{
		garage_id: 211,
		part_id: 66,
		quantity: 183,
	},
	{
		garage_id: 211,
		part_id: 67,
		quantity: 9,
	},
	{
		garage_id: 211,
		part_id: 68,
		quantity: 43,
	},
	{
		garage_id: 211,
		part_id: 69,
		quantity: 296,
	},
	{
		garage_id: 211,
		part_id: 70,
		quantity: 234,
	},
	{
		garage_id: 211,
		part_id: 71,
		quantity: 289,
	},
	{
		garage_id: 211,
		part_id: 72,
		quantity: 304,
	},
	{
		garage_id: 211,
		part_id: 73,
		quantity: 240,
	},
	{
		garage_id: 211,
		part_id: 74,
		quantity: 90,
	},
	{
		garage_id: 211,
		part_id: 75,
		quantity: 183,
	},
	{
		garage_id: 211,
		part_id: 76,
		quantity: 242,
	},
	{
		garage_id: 211,
		part_id: 77,
		quantity: 62,
	},
	{
		garage_id: 211,
		part_id: 78,
		quantity: 2,
	},
	{
		garage_id: 211,
		part_id: 79,
		quantity: 488,
	},
	{
		garage_id: 211,
		part_id: 80,
		quantity: 318,
	},
	{
		garage_id: 211,
		part_id: 81,
		quantity: 328,
	},
	{
		garage_id: 211,
		part_id: 82,
		quantity: 310,
	},
	{
		garage_id: 211,
		part_id: 83,
		quantity: 237,
	},
	{
		garage_id: 211,
		part_id: 84,
		quantity: 477,
	},
	{
		garage_id: 211,
		part_id: 85,
		quantity: 156,
	},
	{
		garage_id: 211,
		part_id: 86,
		quantity: 338,
	},
	{
		garage_id: 211,
		part_id: 87,
		quantity: 234,
	},
	{
		garage_id: 211,
		part_id: 88,
		quantity: 264,
	},
	{
		garage_id: 211,
		part_id: 89,
		quantity: 312,
	},
	{
		garage_id: 211,
		part_id: 90,
		quantity: 472,
	},
	{
		garage_id: 211,
		part_id: 91,
		quantity: 439,
	},
	{
		garage_id: 211,
		part_id: 92,
		quantity: 173,
	},
	{
		garage_id: 211,
		part_id: 93,
		quantity: 170,
	},
	{
		garage_id: 211,
		part_id: 94,
		quantity: 237,
	},
	{
		garage_id: 211,
		part_id: 95,
		quantity: 40,
	},
	{
		garage_id: 211,
		part_id: 96,
		quantity: 210,
	},
	{
		garage_id: 211,
		part_id: 97,
		quantity: 121,
	},
	{
		garage_id: 211,
		part_id: 98,
		quantity: 226,
	},
	{
		garage_id: 211,
		part_id: 99,
		quantity: 6,
	},
	{
		garage_id: 211,
		part_id: 100,
		quantity: 122,
	},
	{
		garage_id: 211,
		part_id: 101,
		quantity: 313,
	},
	{
		garage_id: 211,
		part_id: 102,
		quantity: 84,
	},
	{
		garage_id: 211,
		part_id: 103,
		quantity: 466,
	},
	{
		garage_id: 211,
		part_id: 104,
		quantity: 433,
	},
	{
		garage_id: 211,
		part_id: 105,
		quantity: 465,
	},
	{
		garage_id: 211,
		part_id: 106,
		quantity: 232,
	},
	{
		garage_id: 211,
		part_id: 107,
		quantity: 197,
	},
	{
		garage_id: 211,
		part_id: 108,
		quantity: 200,
	},
	{
		garage_id: 211,
		part_id: 109,
		quantity: 355,
	},
	{
		garage_id: 211,
		part_id: 110,
		quantity: 179,
	},
	{
		garage_id: 211,
		part_id: 111,
		quantity: 210,
	},
	{
		garage_id: 211,
		part_id: 112,
		quantity: 129,
	},
	{
		garage_id: 211,
		part_id: 113,
		quantity: 65,
	},
	{
		garage_id: 211,
		part_id: 114,
		quantity: 448,
	},
	{
		garage_id: 211,
		part_id: 115,
		quantity: 455,
	},
	{
		garage_id: 211,
		part_id: 116,
		quantity: 375,
	},
	{
		garage_id: 211,
		part_id: 117,
		quantity: 122,
	},
	{
		garage_id: 211,
		part_id: 118,
		quantity: 407,
	},
	{
		garage_id: 211,
		part_id: 119,
		quantity: 311,
	},
	{
		garage_id: 211,
		part_id: 120,
		quantity: 267,
	},
	{
		garage_id: 211,
		part_id: 121,
		quantity: 249,
	},
	{
		garage_id: 211,
		part_id: 122,
		quantity: 482,
	},
	{
		garage_id: 211,
		part_id: 123,
		quantity: 215,
	},
	{
		garage_id: 211,
		part_id: 124,
		quantity: 138,
	},
	{
		garage_id: 211,
		part_id: 125,
		quantity: 133,
	},
	{
		garage_id: 211,
		part_id: 126,
		quantity: 391,
	},
	{
		garage_id: 211,
		part_id: 127,
		quantity: 441,
	},
	{
		garage_id: 211,
		part_id: 128,
		quantity: 262,
	},
	{
		garage_id: 211,
		part_id: 129,
		quantity: 187,
	},
	{
		garage_id: 211,
		part_id: 130,
		quantity: 92,
	},
	{
		garage_id: 211,
		part_id: 131,
		quantity: 76,
	},
	{
		garage_id: 211,
		part_id: 132,
		quantity: 446,
	},
	{
		garage_id: 211,
		part_id: 133,
		quantity: 128,
	},
	{
		garage_id: 211,
		part_id: 134,
		quantity: 408,
	},
	{
		garage_id: 211,
		part_id: 135,
		quantity: 192,
	},
	{
		garage_id: 211,
		part_id: 136,
		quantity: 53,
	},
	{
		garage_id: 211,
		part_id: 137,
		quantity: 15,
	},
	{
		garage_id: 211,
		part_id: 138,
		quantity: 253,
	},
	{
		garage_id: 211,
		part_id: 139,
		quantity: 460,
	},
	{
		garage_id: 211,
		part_id: 140,
		quantity: 283,
	},
	{
		garage_id: 211,
		part_id: 141,
		quantity: 145,
	},
	{
		garage_id: 211,
		part_id: 142,
		quantity: 74,
	},
	{
		garage_id: 211,
		part_id: 143,
		quantity: 158,
	},
	{
		garage_id: 211,
		part_id: 144,
		quantity: 288,
	},
	{
		garage_id: 211,
		part_id: 145,
		quantity: 328,
	},
	{
		garage_id: 211,
		part_id: 146,
		quantity: 17,
	},
	{
		garage_id: 211,
		part_id: 147,
		quantity: 314,
	},
	{
		garage_id: 211,
		part_id: 148,
		quantity: 109,
	},
	{
		garage_id: 211,
		part_id: 149,
		quantity: 87,
	},
	{
		garage_id: 211,
		part_id: 150,
		quantity: 245,
	},
	{
		garage_id: 211,
		part_id: 151,
		quantity: 236,
	},
	{
		garage_id: 211,
		part_id: 152,
		quantity: 388,
	},
	{
		garage_id: 211,
		part_id: 153,
		quantity: 259,
	},
	{
		garage_id: 211,
		part_id: 154,
		quantity: 236,
	},
	{
		garage_id: 211,
		part_id: 155,
		quantity: 473,
	},
	{
		garage_id: 211,
		part_id: 156,
		quantity: 315,
	},
	{
		garage_id: 211,
		part_id: 157,
		quantity: 377,
	},
	{
		garage_id: 211,
		part_id: 158,
		quantity: 238,
	},
	{
		garage_id: 211,
		part_id: 159,
		quantity: 379,
	},
	{
		garage_id: 211,
		part_id: 160,
		quantity: 30,
	},
	{
		garage_id: 211,
		part_id: 161,
		quantity: 349,
	},
	{
		garage_id: 211,
		part_id: 162,
		quantity: 247,
	},
	{
		garage_id: 211,
		part_id: 163,
		quantity: 281,
	},
	{
		garage_id: 211,
		part_id: 164,
		quantity: 73,
	},
	{
		garage_id: 211,
		part_id: 165,
		quantity: 366,
	},
	{
		garage_id: 211,
		part_id: 166,
		quantity: 175,
	},
	{
		garage_id: 211,
		part_id: 167,
		quantity: 390,
	},
	{
		garage_id: 211,
		part_id: 168,
		quantity: 484,
	},
	{
		garage_id: 211,
		part_id: 169,
		quantity: 154,
	},
	{
		garage_id: 211,
		part_id: 170,
		quantity: 389,
	},
	{
		garage_id: 211,
		part_id: 171,
		quantity: 331,
	},
	{
		garage_id: 211,
		part_id: 172,
		quantity: 299,
	},
	{
		garage_id: 211,
		part_id: 173,
		quantity: 174,
	},
	{
		garage_id: 211,
		part_id: 174,
		quantity: 281,
	},
	{
		garage_id: 211,
		part_id: 175,
		quantity: 156,
	},
	{
		garage_id: 211,
		part_id: 176,
		quantity: 100,
	},
	{
		garage_id: 211,
		part_id: 177,
		quantity: 439,
	},
	{
		garage_id: 211,
		part_id: 178,
		quantity: 72,
	},
	{
		garage_id: 211,
		part_id: 179,
		quantity: 177,
	},
	{
		garage_id: 211,
		part_id: 180,
		quantity: 103,
	},
	{
		garage_id: 211,
		part_id: 181,
		quantity: 462,
	},
	{
		garage_id: 211,
		part_id: 182,
		quantity: 494,
	},
	{
		garage_id: 211,
		part_id: 183,
		quantity: 349,
	},
	{
		garage_id: 211,
		part_id: 184,
		quantity: 422,
	},
	{
		garage_id: 211,
		part_id: 185,
		quantity: 75,
	},
	{
		garage_id: 211,
		part_id: 186,
		quantity: 185,
	},
	{
		garage_id: 211,
		part_id: 187,
		quantity: 373,
	},
	{
		garage_id: 211,
		part_id: 188,
		quantity: 403,
	},
	{
		garage_id: 211,
		part_id: 189,
		quantity: 456,
	},
	{
		garage_id: 211,
		part_id: 190,
		quantity: 28,
	},
	{
		garage_id: 211,
		part_id: 191,
		quantity: 120,
	},
	{
		garage_id: 211,
		part_id: 192,
		quantity: 119,
	},
	{
		garage_id: 211,
		part_id: 193,
		quantity: 272,
	},
	{
		garage_id: 211,
		part_id: 194,
		quantity: 189,
	},
	{
		garage_id: 211,
		part_id: 195,
		quantity: 479,
	},
	{
		garage_id: 211,
		part_id: 196,
		quantity: 266,
	},
	{
		garage_id: 211,
		part_id: 197,
		quantity: 211,
	},
	{
		garage_id: 211,
		part_id: 198,
		quantity: 31,
	},
	{
		garage_id: 211,
		part_id: 199,
		quantity: 405,
	},
	{
		garage_id: 211,
		part_id: 200,
		quantity: 481,
	},
	{
		garage_id: 211,
		part_id: 201,
		quantity: 42,
	},
	{
		garage_id: 211,
		part_id: 202,
		quantity: 149,
	},
	{
		garage_id: 211,
		part_id: 203,
		quantity: 305,
	},
	{
		garage_id: 211,
		part_id: 204,
		quantity: 291,
	},
	{
		garage_id: 211,
		part_id: 205,
		quantity: 220,
	},
	{
		garage_id: 211,
		part_id: 206,
		quantity: 123,
	},
	{
		garage_id: 211,
		part_id: 207,
		quantity: 238,
	},
	{
		garage_id: 211,
		part_id: 208,
		quantity: 408,
	},
	{
		garage_id: 211,
		part_id: 209,
		quantity: 160,
	},
	{
		garage_id: 211,
		part_id: 210,
		quantity: 362,
	},
	{
		garage_id: 211,
		part_id: 211,
		quantity: 366,
	},
	{
		garage_id: 211,
		part_id: 212,
		quantity: 29,
	},
	{
		garage_id: 211,
		part_id: 213,
		quantity: 285,
	},
	{
		garage_id: 211,
		part_id: 214,
		quantity: 459,
	},
	{
		garage_id: 211,
		part_id: 215,
		quantity: 247,
	},
	{
		garage_id: 211,
		part_id: 216,
		quantity: 39,
	},
	{
		garage_id: 211,
		part_id: 217,
		quantity: 268,
	},
	{
		garage_id: 211,
		part_id: 218,
		quantity: 240,
	},
	{
		garage_id: 211,
		part_id: 219,
		quantity: 345,
	},
	{
		garage_id: 211,
		part_id: 220,
		quantity: 431,
	},
	{
		garage_id: 211,
		part_id: 221,
		quantity: 33,
	},
	{
		garage_id: 211,
		part_id: 222,
		quantity: 119,
	},
	{
		garage_id: 211,
		part_id: 223,
		quantity: 349,
	},
	{
		garage_id: 211,
		part_id: 224,
		quantity: 199,
	},
	{
		garage_id: 211,
		part_id: 225,
		quantity: 137,
	},
	{
		garage_id: 211,
		part_id: 226,
		quantity: 348,
	},
	{
		garage_id: 211,
		part_id: 227,
		quantity: 117,
	},
	{
		garage_id: 211,
		part_id: 228,
		quantity: 44,
	},
	{
		garage_id: 211,
		part_id: 229,
		quantity: 303,
	},
	{
		garage_id: 211,
		part_id: 230,
		quantity: 139,
	},
	{
		garage_id: 211,
		part_id: 231,
		quantity: 73,
	},
	{
		garage_id: 211,
		part_id: 232,
		quantity: 397,
	},
	{
		garage_id: 211,
		part_id: 233,
		quantity: 315,
	},
	{
		garage_id: 211,
		part_id: 234,
		quantity: 24,
	},
	{
		garage_id: 211,
		part_id: 235,
		quantity: 290,
	},
	{
		garage_id: 211,
		part_id: 236,
		quantity: 311,
	},
	{
		garage_id: 211,
		part_id: 237,
		quantity: 269,
	},
	{
		garage_id: 211,
		part_id: 238,
		quantity: 38,
	},
	{
		garage_id: 211,
		part_id: 239,
		quantity: 332,
	},
	{
		garage_id: 211,
		part_id: 240,
		quantity: 355,
	},
	{
		garage_id: 211,
		part_id: 241,
		quantity: 162,
	},
	{
		garage_id: 211,
		part_id: 242,
		quantity: 65,
	},
	{
		garage_id: 211,
		part_id: 243,
		quantity: 251,
	},
	{
		garage_id: 211,
		part_id: 244,
		quantity: 30,
	},
	{
		garage_id: 211,
		part_id: 245,
		quantity: 189,
	},
	{
		garage_id: 211,
		part_id: 246,
		quantity: 73,
	},
	{
		garage_id: 211,
		part_id: 247,
		quantity: 145,
	},
	{
		garage_id: 211,
		part_id: 248,
		quantity: 226,
	},
	{
		garage_id: 211,
		part_id: 249,
		quantity: 340,
	},
	{
		garage_id: 211,
		part_id: 250,
		quantity: 315,
	},
	{
		garage_id: 211,
		part_id: 251,
		quantity: 350,
	},
	{
		garage_id: 211,
		part_id: 252,
		quantity: 155,
	},
	{
		garage_id: 211,
		part_id: 253,
		quantity: 428,
	},
	{
		garage_id: 211,
		part_id: 254,
		quantity: 429,
	},
	{
		garage_id: 211,
		part_id: 255,
		quantity: 362,
	},
	{
		garage_id: 211,
		part_id: 256,
		quantity: 101,
	},
	{
		garage_id: 211,
		part_id: 257,
		quantity: 500,
	},
	{
		garage_id: 211,
		part_id: 258,
		quantity: 228,
	},
	{
		garage_id: 211,
		part_id: 259,
		quantity: 40,
	},
	{
		garage_id: 211,
		part_id: 260,
		quantity: 206,
	},
	{
		garage_id: 211,
		part_id: 261,
		quantity: 161,
	},
	{
		garage_id: 211,
		part_id: 262,
		quantity: 180,
	},
	{
		garage_id: 211,
		part_id: 263,
		quantity: 69,
	},
	{
		garage_id: 211,
		part_id: 264,
		quantity: 146,
	},
	{
		garage_id: 211,
		part_id: 265,
		quantity: 133,
	},
	{
		garage_id: 211,
		part_id: 266,
		quantity: 398,
	},
	{
		garage_id: 211,
		part_id: 267,
		quantity: 334,
	},
	{
		garage_id: 211,
		part_id: 268,
		quantity: 87,
	},
	{
		garage_id: 211,
		part_id: 269,
		quantity: 375,
	},
	{
		garage_id: 211,
		part_id: 270,
		quantity: 187,
	},
	{
		garage_id: 211,
		part_id: 271,
		quantity: 1,
	},
	{
		garage_id: 211,
		part_id: 272,
		quantity: 316,
	},
	{
		garage_id: 211,
		part_id: 273,
		quantity: 40,
	},
	{
		garage_id: 211,
		part_id: 274,
		quantity: 24,
	},
	{
		garage_id: 211,
		part_id: 275,
		quantity: 9,
	},
	{
		garage_id: 211,
		part_id: 276,
		quantity: 300,
	},
	{
		garage_id: 211,
		part_id: 277,
		quantity: 176,
	},
	{
		garage_id: 211,
		part_id: 278,
		quantity: 466,
	},
	{
		garage_id: 211,
		part_id: 279,
		quantity: 320,
	},
	{
		garage_id: 211,
		part_id: 280,
		quantity: 300,
	},
	{
		garage_id: 211,
		part_id: 281,
		quantity: 424,
	},
	{
		garage_id: 211,
		part_id: 282,
		quantity: 198,
	},
	{
		garage_id: 211,
		part_id: 283,
		quantity: 255,
	},
	{
		garage_id: 211,
		part_id: 284,
		quantity: 415,
	},
	{
		garage_id: 211,
		part_id: 285,
		quantity: 450,
	},
	{
		garage_id: 211,
		part_id: 286,
		quantity: 101,
	},
	{
		garage_id: 211,
		part_id: 287,
		quantity: 40,
	},
	{
		garage_id: 211,
		part_id: 288,
		quantity: 369,
	},
	{
		garage_id: 211,
		part_id: 289,
		quantity: 61,
	},
	{
		garage_id: 211,
		part_id: 290,
		quantity: 495,
	},
	{
		garage_id: 211,
		part_id: 291,
		quantity: 347,
	},
	{
		garage_id: 211,
		part_id: 292,
		quantity: 97,
	},
	{
		garage_id: 211,
		part_id: 293,
		quantity: 199,
	},
	{
		garage_id: 211,
		part_id: 294,
		quantity: 391,
	},
	{
		garage_id: 211,
		part_id: 295,
		quantity: 144,
	},
	{
		garage_id: 211,
		part_id: 296,
		quantity: 387,
	},
	{
		garage_id: 211,
		part_id: 297,
		quantity: 458,
	},
	{
		garage_id: 211,
		part_id: 298,
		quantity: 306,
	},
	{
		garage_id: 211,
		part_id: 299,
		quantity: 189,
	},
	{
		garage_id: 211,
		part_id: 300,
		quantity: 436,
	},
	{
		garage_id: 211,
		part_id: 301,
		quantity: 334,
	},
	{
		garage_id: 211,
		part_id: 302,
		quantity: 103,
	},
	{
		garage_id: 211,
		part_id: 303,
		quantity: 488,
	},
	{
		garage_id: 211,
		part_id: 304,
		quantity: 203,
	},
	{
		garage_id: 211,
		part_id: 305,
		quantity: 198,
	},
	{
		garage_id: 211,
		part_id: 306,
		quantity: 449,
	},
	{
		garage_id: 211,
		part_id: 307,
		quantity: 345,
	},
	{
		garage_id: 211,
		part_id: 308,
		quantity: 473,
	},
	{
		garage_id: 211,
		part_id: 309,
		quantity: 24,
	},
	{
		garage_id: 211,
		part_id: 310,
		quantity: 20,
	},
	{
		garage_id: 211,
		part_id: 311,
		quantity: 385,
	},
	{
		garage_id: 211,
		part_id: 312,
		quantity: 369,
	},
	{
		garage_id: 211,
		part_id: 313,
		quantity: 32,
	},
	{
		garage_id: 211,
		part_id: 314,
		quantity: 135,
	},
	{
		garage_id: 211,
		part_id: 315,
		quantity: 263,
	},
	{
		garage_id: 211,
		part_id: 316,
		quantity: 92,
	},
	{
		garage_id: 211,
		part_id: 317,
		quantity: 393,
	},
	{
		garage_id: 211,
		part_id: 318,
		quantity: 213,
	},
	{
		garage_id: 211,
		part_id: 319,
		quantity: 400,
	},
	{
		garage_id: 211,
		part_id: 320,
		quantity: 392,
	},
	{
		garage_id: 211,
		part_id: 321,
		quantity: 339,
	},
	{
		garage_id: 211,
		part_id: 322,
		quantity: 337,
	},
	{
		garage_id: 211,
		part_id: 323,
		quantity: 284,
	},
	{
		garage_id: 211,
		part_id: 324,
		quantity: 393,
	},
	{
		garage_id: 211,
		part_id: 325,
		quantity: 424,
	},
	{
		garage_id: 211,
		part_id: 326,
		quantity: 221,
	},
	{
		garage_id: 211,
		part_id: 327,
		quantity: 497,
	},
	{
		garage_id: 211,
		part_id: 328,
		quantity: 141,
	},
	{
		garage_id: 211,
		part_id: 329,
		quantity: 458,
	},
	{
		garage_id: 211,
		part_id: 330,
		quantity: 164,
	},
	{
		garage_id: 211,
		part_id: 331,
		quantity: 154,
	},
	{
		garage_id: 211,
		part_id: 332,
		quantity: 3,
	},
	{
		garage_id: 211,
		part_id: 333,
		quantity: 212,
	},
	{
		garage_id: 211,
		part_id: 334,
		quantity: 16,
	},
	{
		garage_id: 211,
		part_id: 335,
		quantity: 233,
	},
	{
		garage_id: 211,
		part_id: 336,
		quantity: 351,
	},
	{
		garage_id: 211,
		part_id: 337,
		quantity: 488,
	},
	{
		garage_id: 211,
		part_id: 338,
		quantity: 405,
	},
	{
		garage_id: 211,
		part_id: 339,
		quantity: 99,
	},
	{
		garage_id: 211,
		part_id: 340,
		quantity: 211,
	},
	{
		garage_id: 211,
		part_id: 341,
		quantity: 6,
	},
	{
		garage_id: 211,
		part_id: 342,
		quantity: 317,
	},
	{
		garage_id: 211,
		part_id: 343,
		quantity: 174,
	},
	{
		garage_id: 211,
		part_id: 344,
		quantity: 204,
	},
	{
		garage_id: 211,
		part_id: 345,
		quantity: 231,
	},
	{
		garage_id: 211,
		part_id: 346,
		quantity: 262,
	},
	{
		garage_id: 211,
		part_id: 347,
		quantity: 49,
	},
	{
		garage_id: 211,
		part_id: 348,
		quantity: 127,
	},
	{
		garage_id: 211,
		part_id: 349,
		quantity: 221,
	},
	{
		garage_id: 211,
		part_id: 350,
		quantity: 315,
	},
	{
		garage_id: 211,
		part_id: 351,
		quantity: 102,
	},
	{
		garage_id: 211,
		part_id: 352,
		quantity: 294,
	},
	{
		garage_id: 211,
		part_id: 353,
		quantity: 300,
	},
	{
		garage_id: 211,
		part_id: 354,
		quantity: 57,
	},
	{
		garage_id: 211,
		part_id: 355,
		quantity: 416,
	},
	{
		garage_id: 211,
		part_id: 356,
		quantity: 139,
	},
	{
		garage_id: 211,
		part_id: 357,
		quantity: 245,
	},
	{
		garage_id: 211,
		part_id: 358,
		quantity: 272,
	},
	{
		garage_id: 211,
		part_id: 359,
		quantity: 293,
	},
	{
		garage_id: 211,
		part_id: 360,
		quantity: 341,
	},
	{
		garage_id: 211,
		part_id: 361,
		quantity: 119,
	},
	{
		garage_id: 211,
		part_id: 362,
		quantity: 435,
	},
	{
		garage_id: 211,
		part_id: 363,
		quantity: 257,
	},
	{
		garage_id: 211,
		part_id: 364,
		quantity: 28,
	},
	{
		garage_id: 211,
		part_id: 365,
		quantity: 432,
	},
	{
		garage_id: 211,
		part_id: 366,
		quantity: 420,
	},
	{
		garage_id: 211,
		part_id: 367,
		quantity: 50,
	},
	{
		garage_id: 211,
		part_id: 368,
		quantity: 470,
	},
	{
		garage_id: 211,
		part_id: 369,
		quantity: 160,
	},
	{
		garage_id: 211,
		part_id: 370,
		quantity: 185,
	},
	{
		garage_id: 211,
		part_id: 371,
		quantity: 397,
	},
	{
		garage_id: 211,
		part_id: 372,
		quantity: 439,
	},
	{
		garage_id: 211,
		part_id: 373,
		quantity: 363,
	},
	{
		garage_id: 211,
		part_id: 374,
		quantity: 133,
	},
	{
		garage_id: 211,
		part_id: 375,
		quantity: 128,
	},
	{
		garage_id: 211,
		part_id: 376,
		quantity: 68,
	},
	{
		garage_id: 211,
		part_id: 377,
		quantity: 460,
	},
	{
		garage_id: 211,
		part_id: 378,
		quantity: 77,
	},
	{
		garage_id: 211,
		part_id: 379,
		quantity: 271,
	},
	{
		garage_id: 211,
		part_id: 380,
		quantity: 479,
	},
	{
		garage_id: 211,
		part_id: 381,
		quantity: 405,
	},
	{
		garage_id: 211,
		part_id: 382,
		quantity: 61,
	},
	{
		garage_id: 211,
		part_id: 383,
		quantity: 295,
	},
	{
		garage_id: 211,
		part_id: 384,
		quantity: 458,
	},
	{
		garage_id: 211,
		part_id: 385,
		quantity: 301,
	},
	{
		garage_id: 211,
		part_id: 386,
		quantity: 467,
	},
	{
		garage_id: 211,
		part_id: 387,
		quantity: 46,
	},
	{
		garage_id: 211,
		part_id: 388,
		quantity: 272,
	},
	{
		garage_id: 211,
		part_id: 389,
		quantity: 30,
	},
	{
		garage_id: 211,
		part_id: 390,
		quantity: 301,
	},
	{
		garage_id: 211,
		part_id: 391,
		quantity: 138,
	},
	{
		garage_id: 211,
		part_id: 392,
		quantity: 293,
	},
	{
		garage_id: 211,
		part_id: 393,
		quantity: 35,
	},
	{
		garage_id: 211,
		part_id: 394,
		quantity: 369,
	},
	{
		garage_id: 211,
		part_id: 395,
		quantity: 171,
	},
	{
		garage_id: 211,
		part_id: 396,
		quantity: 110,
	},
	{
		garage_id: 211,
		part_id: 397,
		quantity: 344,
	},
	{
		garage_id: 211,
		part_id: 398,
		quantity: 275,
	},
	{
		garage_id: 211,
		part_id: 399,
		quantity: 396,
	},
	{
		garage_id: 212,
		part_id: 0,
		quantity: 292,
	},
	{
		garage_id: 212,
		part_id: 1,
		quantity: 288,
	},
	{
		garage_id: 212,
		part_id: 2,
		quantity: 55,
	},
	{
		garage_id: 212,
		part_id: 3,
		quantity: 286,
	},
	{
		garage_id: 212,
		part_id: 4,
		quantity: 6,
	},
	{
		garage_id: 212,
		part_id: 5,
		quantity: 83,
	},
	{
		garage_id: 212,
		part_id: 6,
		quantity: 433,
	},
	{
		garage_id: 212,
		part_id: 7,
		quantity: 21,
	},
	{
		garage_id: 212,
		part_id: 8,
		quantity: 326,
	},
	{
		garage_id: 212,
		part_id: 9,
		quantity: 477,
	},
	{
		garage_id: 212,
		part_id: 10,
		quantity: 38,
	},
	{
		garage_id: 212,
		part_id: 11,
		quantity: 341,
	},
	{
		garage_id: 212,
		part_id: 12,
		quantity: 220,
	},
	{
		garage_id: 212,
		part_id: 13,
		quantity: 70,
	},
	{
		garage_id: 212,
		part_id: 14,
		quantity: 65,
	},
	{
		garage_id: 212,
		part_id: 15,
		quantity: 321,
	},
	{
		garage_id: 212,
		part_id: 16,
		quantity: 315,
	},
	{
		garage_id: 212,
		part_id: 17,
		quantity: 483,
	},
	{
		garage_id: 212,
		part_id: 18,
		quantity: 493,
	},
	{
		garage_id: 212,
		part_id: 19,
		quantity: 51,
	},
	{
		garage_id: 212,
		part_id: 20,
		quantity: 271,
	},
	{
		garage_id: 212,
		part_id: 21,
		quantity: 316,
	},
	{
		garage_id: 212,
		part_id: 22,
		quantity: 119,
	},
	{
		garage_id: 212,
		part_id: 23,
		quantity: 272,
	},
	{
		garage_id: 212,
		part_id: 24,
		quantity: 396,
	},
	{
		garage_id: 212,
		part_id: 25,
		quantity: 301,
	},
	{
		garage_id: 212,
		part_id: 26,
		quantity: 51,
	},
	{
		garage_id: 212,
		part_id: 27,
		quantity: 88,
	},
	{
		garage_id: 212,
		part_id: 28,
		quantity: 423,
	},
	{
		garage_id: 212,
		part_id: 29,
		quantity: 30,
	},
	{
		garage_id: 212,
		part_id: 30,
		quantity: 1,
	},
	{
		garage_id: 212,
		part_id: 31,
		quantity: 105,
	},
	{
		garage_id: 212,
		part_id: 32,
		quantity: 0,
	},
	{
		garage_id: 212,
		part_id: 33,
		quantity: 99,
	},
	{
		garage_id: 212,
		part_id: 34,
		quantity: 479,
	},
	{
		garage_id: 212,
		part_id: 35,
		quantity: 317,
	},
	{
		garage_id: 212,
		part_id: 36,
		quantity: 277,
	},
	{
		garage_id: 212,
		part_id: 37,
		quantity: 318,
	},
	{
		garage_id: 212,
		part_id: 38,
		quantity: 218,
	},
	{
		garage_id: 212,
		part_id: 39,
		quantity: 162,
	},
	{
		garage_id: 212,
		part_id: 40,
		quantity: 97,
	},
	{
		garage_id: 212,
		part_id: 41,
		quantity: 372,
	},
	{
		garage_id: 212,
		part_id: 42,
		quantity: 427,
	},
	{
		garage_id: 212,
		part_id: 43,
		quantity: 479,
	},
	{
		garage_id: 212,
		part_id: 44,
		quantity: 461,
	},
	{
		garage_id: 212,
		part_id: 45,
		quantity: 84,
	},
	{
		garage_id: 212,
		part_id: 46,
		quantity: 371,
	},
	{
		garage_id: 212,
		part_id: 47,
		quantity: 286,
	},
	{
		garage_id: 212,
		part_id: 48,
		quantity: 347,
	},
	{
		garage_id: 212,
		part_id: 49,
		quantity: 210,
	},
	{
		garage_id: 212,
		part_id: 50,
		quantity: 160,
	},
	{
		garage_id: 212,
		part_id: 51,
		quantity: 338,
	},
	{
		garage_id: 212,
		part_id: 52,
		quantity: 116,
	},
	{
		garage_id: 212,
		part_id: 53,
		quantity: 365,
	},
	{
		garage_id: 212,
		part_id: 54,
		quantity: 420,
	},
	{
		garage_id: 212,
		part_id: 55,
		quantity: 307,
	},
	{
		garage_id: 212,
		part_id: 56,
		quantity: 391,
	},
	{
		garage_id: 212,
		part_id: 57,
		quantity: 97,
	},
	{
		garage_id: 212,
		part_id: 58,
		quantity: 392,
	},
	{
		garage_id: 212,
		part_id: 59,
		quantity: 50,
	},
	{
		garage_id: 212,
		part_id: 60,
		quantity: 79,
	},
	{
		garage_id: 212,
		part_id: 61,
		quantity: 158,
	},
	{
		garage_id: 212,
		part_id: 62,
		quantity: 45,
	},
	{
		garage_id: 212,
		part_id: 63,
		quantity: 48,
	},
	{
		garage_id: 212,
		part_id: 64,
		quantity: 320,
	},
	{
		garage_id: 212,
		part_id: 65,
		quantity: 442,
	},
	{
		garage_id: 212,
		part_id: 66,
		quantity: 436,
	},
	{
		garage_id: 212,
		part_id: 67,
		quantity: 143,
	},
	{
		garage_id: 212,
		part_id: 68,
		quantity: 284,
	},
	{
		garage_id: 212,
		part_id: 69,
		quantity: 256,
	},
	{
		garage_id: 212,
		part_id: 70,
		quantity: 432,
	},
	{
		garage_id: 212,
		part_id: 71,
		quantity: 495,
	},
	{
		garage_id: 212,
		part_id: 72,
		quantity: 384,
	},
	{
		garage_id: 212,
		part_id: 73,
		quantity: 270,
	},
	{
		garage_id: 212,
		part_id: 74,
		quantity: 289,
	},
	{
		garage_id: 212,
		part_id: 75,
		quantity: 297,
	},
	{
		garage_id: 212,
		part_id: 76,
		quantity: 279,
	},
	{
		garage_id: 212,
		part_id: 77,
		quantity: 266,
	},
	{
		garage_id: 212,
		part_id: 78,
		quantity: 298,
	},
	{
		garage_id: 212,
		part_id: 79,
		quantity: 477,
	},
	{
		garage_id: 212,
		part_id: 80,
		quantity: 302,
	},
	{
		garage_id: 212,
		part_id: 81,
		quantity: 109,
	},
	{
		garage_id: 212,
		part_id: 82,
		quantity: 464,
	},
	{
		garage_id: 212,
		part_id: 83,
		quantity: 486,
	},
	{
		garage_id: 212,
		part_id: 84,
		quantity: 126,
	},
	{
		garage_id: 212,
		part_id: 85,
		quantity: 57,
	},
	{
		garage_id: 212,
		part_id: 86,
		quantity: 126,
	},
	{
		garage_id: 212,
		part_id: 87,
		quantity: 415,
	},
	{
		garage_id: 212,
		part_id: 88,
		quantity: 284,
	},
	{
		garage_id: 212,
		part_id: 89,
		quantity: 222,
	},
	{
		garage_id: 212,
		part_id: 90,
		quantity: 145,
	},
	{
		garage_id: 212,
		part_id: 91,
		quantity: 107,
	},
	{
		garage_id: 212,
		part_id: 92,
		quantity: 499,
	},
	{
		garage_id: 212,
		part_id: 93,
		quantity: 132,
	},
	{
		garage_id: 212,
		part_id: 94,
		quantity: 317,
	},
	{
		garage_id: 212,
		part_id: 95,
		quantity: 437,
	},
	{
		garage_id: 212,
		part_id: 96,
		quantity: 9,
	},
	{
		garage_id: 212,
		part_id: 97,
		quantity: 265,
	},
	{
		garage_id: 212,
		part_id: 98,
		quantity: 118,
	},
	{
		garage_id: 212,
		part_id: 99,
		quantity: 331,
	},
	{
		garage_id: 212,
		part_id: 100,
		quantity: 108,
	},
	{
		garage_id: 212,
		part_id: 101,
		quantity: 57,
	},
	{
		garage_id: 212,
		part_id: 102,
		quantity: 343,
	},
	{
		garage_id: 212,
		part_id: 103,
		quantity: 448,
	},
	{
		garage_id: 212,
		part_id: 104,
		quantity: 138,
	},
	{
		garage_id: 212,
		part_id: 105,
		quantity: 309,
	},
	{
		garage_id: 212,
		part_id: 106,
		quantity: 381,
	},
	{
		garage_id: 212,
		part_id: 107,
		quantity: 393,
	},
	{
		garage_id: 212,
		part_id: 108,
		quantity: 408,
	},
	{
		garage_id: 212,
		part_id: 109,
		quantity: 343,
	},
	{
		garage_id: 212,
		part_id: 110,
		quantity: 270,
	},
	{
		garage_id: 212,
		part_id: 111,
		quantity: 409,
	},
	{
		garage_id: 212,
		part_id: 112,
		quantity: 180,
	},
	{
		garage_id: 212,
		part_id: 113,
		quantity: 169,
	},
	{
		garage_id: 212,
		part_id: 114,
		quantity: 226,
	},
	{
		garage_id: 212,
		part_id: 115,
		quantity: 401,
	},
	{
		garage_id: 212,
		part_id: 116,
		quantity: 50,
	},
	{
		garage_id: 212,
		part_id: 117,
		quantity: 165,
	},
	{
		garage_id: 212,
		part_id: 118,
		quantity: 44,
	},
	{
		garage_id: 212,
		part_id: 119,
		quantity: 283,
	},
	{
		garage_id: 212,
		part_id: 120,
		quantity: 383,
	},
	{
		garage_id: 212,
		part_id: 121,
		quantity: 342,
	},
	{
		garage_id: 212,
		part_id: 122,
		quantity: 226,
	},
	{
		garage_id: 212,
		part_id: 123,
		quantity: 54,
	},
	{
		garage_id: 212,
		part_id: 124,
		quantity: 115,
	},
	{
		garage_id: 212,
		part_id: 125,
		quantity: 243,
	},
	{
		garage_id: 212,
		part_id: 126,
		quantity: 21,
	},
	{
		garage_id: 212,
		part_id: 127,
		quantity: 414,
	},
	{
		garage_id: 212,
		part_id: 128,
		quantity: 285,
	},
	{
		garage_id: 212,
		part_id: 129,
		quantity: 465,
	},
	{
		garage_id: 212,
		part_id: 130,
		quantity: 412,
	},
	{
		garage_id: 212,
		part_id: 131,
		quantity: 265,
	},
	{
		garage_id: 212,
		part_id: 132,
		quantity: 280,
	},
	{
		garage_id: 212,
		part_id: 133,
		quantity: 265,
	},
	{
		garage_id: 212,
		part_id: 134,
		quantity: 328,
	},
	{
		garage_id: 212,
		part_id: 135,
		quantity: 209,
	},
	{
		garage_id: 212,
		part_id: 136,
		quantity: 454,
	},
	{
		garage_id: 212,
		part_id: 137,
		quantity: 310,
	},
	{
		garage_id: 212,
		part_id: 138,
		quantity: 310,
	},
	{
		garage_id: 212,
		part_id: 139,
		quantity: 87,
	},
	{
		garage_id: 212,
		part_id: 140,
		quantity: 18,
	},
	{
		garage_id: 212,
		part_id: 141,
		quantity: 94,
	},
	{
		garage_id: 212,
		part_id: 142,
		quantity: 357,
	},
	{
		garage_id: 212,
		part_id: 143,
		quantity: 242,
	},
	{
		garage_id: 212,
		part_id: 144,
		quantity: 176,
	},
	{
		garage_id: 212,
		part_id: 145,
		quantity: 4,
	},
	{
		garage_id: 212,
		part_id: 146,
		quantity: 403,
	},
	{
		garage_id: 212,
		part_id: 147,
		quantity: 328,
	},
	{
		garage_id: 212,
		part_id: 148,
		quantity: 165,
	},
	{
		garage_id: 212,
		part_id: 149,
		quantity: 108,
	},
	{
		garage_id: 212,
		part_id: 150,
		quantity: 107,
	},
	{
		garage_id: 212,
		part_id: 151,
		quantity: 367,
	},
	{
		garage_id: 212,
		part_id: 152,
		quantity: 209,
	},
	{
		garage_id: 212,
		part_id: 153,
		quantity: 31,
	},
	{
		garage_id: 212,
		part_id: 154,
		quantity: 474,
	},
	{
		garage_id: 212,
		part_id: 155,
		quantity: 225,
	},
	{
		garage_id: 212,
		part_id: 156,
		quantity: 107,
	},
	{
		garage_id: 212,
		part_id: 157,
		quantity: 65,
	},
	{
		garage_id: 212,
		part_id: 158,
		quantity: 357,
	},
	{
		garage_id: 212,
		part_id: 159,
		quantity: 387,
	},
	{
		garage_id: 212,
		part_id: 160,
		quantity: 210,
	},
	{
		garage_id: 212,
		part_id: 161,
		quantity: 416,
	},
	{
		garage_id: 212,
		part_id: 162,
		quantity: 71,
	},
	{
		garage_id: 212,
		part_id: 163,
		quantity: 307,
	},
	{
		garage_id: 212,
		part_id: 164,
		quantity: 279,
	},
	{
		garage_id: 212,
		part_id: 165,
		quantity: 219,
	},
	{
		garage_id: 212,
		part_id: 166,
		quantity: 419,
	},
	{
		garage_id: 212,
		part_id: 167,
		quantity: 424,
	},
	{
		garage_id: 212,
		part_id: 168,
		quantity: 209,
	},
	{
		garage_id: 212,
		part_id: 169,
		quantity: 448,
	},
	{
		garage_id: 212,
		part_id: 170,
		quantity: 356,
	},
	{
		garage_id: 212,
		part_id: 171,
		quantity: 310,
	},
	{
		garage_id: 212,
		part_id: 172,
		quantity: 309,
	},
	{
		garage_id: 212,
		part_id: 173,
		quantity: 214,
	},
	{
		garage_id: 212,
		part_id: 174,
		quantity: 169,
	},
	{
		garage_id: 212,
		part_id: 175,
		quantity: 308,
	},
	{
		garage_id: 212,
		part_id: 176,
		quantity: 347,
	},
	{
		garage_id: 212,
		part_id: 177,
		quantity: 277,
	},
	{
		garage_id: 212,
		part_id: 178,
		quantity: 469,
	},
	{
		garage_id: 212,
		part_id: 179,
		quantity: 378,
	},
	{
		garage_id: 212,
		part_id: 180,
		quantity: 494,
	},
	{
		garage_id: 212,
		part_id: 181,
		quantity: 15,
	},
	{
		garage_id: 212,
		part_id: 182,
		quantity: 352,
	},
	{
		garage_id: 212,
		part_id: 183,
		quantity: 277,
	},
	{
		garage_id: 212,
		part_id: 184,
		quantity: 163,
	},
	{
		garage_id: 212,
		part_id: 185,
		quantity: 129,
	},
	{
		garage_id: 212,
		part_id: 186,
		quantity: 340,
	},
	{
		garage_id: 212,
		part_id: 187,
		quantity: 239,
	},
	{
		garage_id: 212,
		part_id: 188,
		quantity: 70,
	},
	{
		garage_id: 212,
		part_id: 189,
		quantity: 401,
	},
	{
		garage_id: 212,
		part_id: 190,
		quantity: 219,
	},
	{
		garage_id: 212,
		part_id: 191,
		quantity: 221,
	},
	{
		garage_id: 212,
		part_id: 192,
		quantity: 481,
	},
	{
		garage_id: 212,
		part_id: 193,
		quantity: 69,
	},
	{
		garage_id: 212,
		part_id: 194,
		quantity: 370,
	},
	{
		garage_id: 212,
		part_id: 195,
		quantity: 475,
	},
	{
		garage_id: 212,
		part_id: 196,
		quantity: 367,
	},
	{
		garage_id: 212,
		part_id: 197,
		quantity: 356,
	},
	{
		garage_id: 212,
		part_id: 198,
		quantity: 395,
	},
	{
		garage_id: 212,
		part_id: 199,
		quantity: 385,
	},
	{
		garage_id: 212,
		part_id: 200,
		quantity: 146,
	},
	{
		garage_id: 212,
		part_id: 201,
		quantity: 409,
	},
	{
		garage_id: 212,
		part_id: 202,
		quantity: 179,
	},
	{
		garage_id: 212,
		part_id: 203,
		quantity: 328,
	},
	{
		garage_id: 212,
		part_id: 204,
		quantity: 204,
	},
	{
		garage_id: 212,
		part_id: 205,
		quantity: 242,
	},
	{
		garage_id: 212,
		part_id: 206,
		quantity: 475,
	},
	{
		garage_id: 212,
		part_id: 207,
		quantity: 258,
	},
	{
		garage_id: 212,
		part_id: 208,
		quantity: 9,
	},
	{
		garage_id: 212,
		part_id: 209,
		quantity: 490,
	},
	{
		garage_id: 212,
		part_id: 210,
		quantity: 73,
	},
	{
		garage_id: 212,
		part_id: 211,
		quantity: 94,
	},
	{
		garage_id: 212,
		part_id: 212,
		quantity: 324,
	},
	{
		garage_id: 212,
		part_id: 213,
		quantity: 39,
	},
	{
		garage_id: 212,
		part_id: 214,
		quantity: 54,
	},
	{
		garage_id: 212,
		part_id: 215,
		quantity: 201,
	},
	{
		garage_id: 212,
		part_id: 216,
		quantity: 389,
	},
	{
		garage_id: 212,
		part_id: 217,
		quantity: 319,
	},
	{
		garage_id: 212,
		part_id: 218,
		quantity: 399,
	},
	{
		garage_id: 212,
		part_id: 219,
		quantity: 350,
	},
	{
		garage_id: 212,
		part_id: 220,
		quantity: 59,
	},
	{
		garage_id: 212,
		part_id: 221,
		quantity: 196,
	},
	{
		garage_id: 212,
		part_id: 222,
		quantity: 209,
	},
	{
		garage_id: 212,
		part_id: 223,
		quantity: 387,
	},
	{
		garage_id: 212,
		part_id: 224,
		quantity: 413,
	},
	{
		garage_id: 212,
		part_id: 225,
		quantity: 299,
	},
	{
		garage_id: 212,
		part_id: 226,
		quantity: 139,
	},
	{
		garage_id: 212,
		part_id: 227,
		quantity: 45,
	},
	{
		garage_id: 212,
		part_id: 228,
		quantity: 14,
	},
	{
		garage_id: 212,
		part_id: 229,
		quantity: 255,
	},
	{
		garage_id: 212,
		part_id: 230,
		quantity: 345,
	},
	{
		garage_id: 212,
		part_id: 231,
		quantity: 167,
	},
	{
		garage_id: 212,
		part_id: 232,
		quantity: 266,
	},
	{
		garage_id: 212,
		part_id: 233,
		quantity: 260,
	},
	{
		garage_id: 212,
		part_id: 234,
		quantity: 399,
	},
	{
		garage_id: 212,
		part_id: 235,
		quantity: 128,
	},
	{
		garage_id: 212,
		part_id: 236,
		quantity: 452,
	},
	{
		garage_id: 212,
		part_id: 237,
		quantity: 107,
	},
	{
		garage_id: 212,
		part_id: 238,
		quantity: 414,
	},
	{
		garage_id: 212,
		part_id: 239,
		quantity: 480,
	},
	{
		garage_id: 212,
		part_id: 240,
		quantity: 366,
	},
	{
		garage_id: 212,
		part_id: 241,
		quantity: 335,
	},
	{
		garage_id: 212,
		part_id: 242,
		quantity: 109,
	},
	{
		garage_id: 212,
		part_id: 243,
		quantity: 416,
	},
	{
		garage_id: 212,
		part_id: 244,
		quantity: 408,
	},
	{
		garage_id: 212,
		part_id: 245,
		quantity: 237,
	},
	{
		garage_id: 212,
		part_id: 246,
		quantity: 122,
	},
	{
		garage_id: 212,
		part_id: 247,
		quantity: 279,
	},
	{
		garage_id: 212,
		part_id: 248,
		quantity: 454,
	},
	{
		garage_id: 212,
		part_id: 249,
		quantity: 121,
	},
	{
		garage_id: 212,
		part_id: 250,
		quantity: 94,
	},
	{
		garage_id: 212,
		part_id: 251,
		quantity: 357,
	},
	{
		garage_id: 212,
		part_id: 252,
		quantity: 320,
	},
	{
		garage_id: 212,
		part_id: 253,
		quantity: 77,
	},
	{
		garage_id: 212,
		part_id: 254,
		quantity: 384,
	},
	{
		garage_id: 212,
		part_id: 255,
		quantity: 354,
	},
	{
		garage_id: 212,
		part_id: 256,
		quantity: 17,
	},
	{
		garage_id: 212,
		part_id: 257,
		quantity: 70,
	},
	{
		garage_id: 212,
		part_id: 258,
		quantity: 473,
	},
	{
		garage_id: 212,
		part_id: 259,
		quantity: 1,
	},
	{
		garage_id: 212,
		part_id: 260,
		quantity: 7,
	},
	{
		garage_id: 212,
		part_id: 261,
		quantity: 125,
	},
	{
		garage_id: 212,
		part_id: 262,
		quantity: 230,
	},
	{
		garage_id: 212,
		part_id: 263,
		quantity: 260,
	},
	{
		garage_id: 212,
		part_id: 264,
		quantity: 398,
	},
	{
		garage_id: 212,
		part_id: 265,
		quantity: 245,
	},
	{
		garage_id: 212,
		part_id: 266,
		quantity: 134,
	},
	{
		garage_id: 212,
		part_id: 267,
		quantity: 335,
	},
	{
		garage_id: 212,
		part_id: 268,
		quantity: 472,
	},
	{
		garage_id: 212,
		part_id: 269,
		quantity: 354,
	},
	{
		garage_id: 212,
		part_id: 270,
		quantity: 411,
	},
	{
		garage_id: 212,
		part_id: 271,
		quantity: 62,
	},
	{
		garage_id: 212,
		part_id: 272,
		quantity: 321,
	},
	{
		garage_id: 212,
		part_id: 273,
		quantity: 99,
	},
	{
		garage_id: 212,
		part_id: 274,
		quantity: 272,
	},
	{
		garage_id: 212,
		part_id: 275,
		quantity: 413,
	},
	{
		garage_id: 212,
		part_id: 276,
		quantity: 360,
	},
	{
		garage_id: 212,
		part_id: 277,
		quantity: 159,
	},
	{
		garage_id: 212,
		part_id: 278,
		quantity: 500,
	},
	{
		garage_id: 212,
		part_id: 279,
		quantity: 272,
	},
	{
		garage_id: 212,
		part_id: 280,
		quantity: 87,
	},
	{
		garage_id: 212,
		part_id: 281,
		quantity: 444,
	},
	{
		garage_id: 212,
		part_id: 282,
		quantity: 418,
	},
	{
		garage_id: 212,
		part_id: 283,
		quantity: 256,
	},
	{
		garage_id: 212,
		part_id: 284,
		quantity: 24,
	},
	{
		garage_id: 212,
		part_id: 285,
		quantity: 496,
	},
	{
		garage_id: 212,
		part_id: 286,
		quantity: 455,
	},
	{
		garage_id: 212,
		part_id: 287,
		quantity: 247,
	},
	{
		garage_id: 212,
		part_id: 288,
		quantity: 442,
	},
	{
		garage_id: 212,
		part_id: 289,
		quantity: 127,
	},
	{
		garage_id: 212,
		part_id: 290,
		quantity: 450,
	},
	{
		garage_id: 212,
		part_id: 291,
		quantity: 445,
	},
	{
		garage_id: 212,
		part_id: 292,
		quantity: 392,
	},
	{
		garage_id: 212,
		part_id: 293,
		quantity: 334,
	},
	{
		garage_id: 212,
		part_id: 294,
		quantity: 343,
	},
	{
		garage_id: 212,
		part_id: 295,
		quantity: 426,
	},
	{
		garage_id: 212,
		part_id: 296,
		quantity: 215,
	},
	{
		garage_id: 212,
		part_id: 297,
		quantity: 133,
	},
	{
		garage_id: 212,
		part_id: 298,
		quantity: 379,
	},
	{
		garage_id: 212,
		part_id: 299,
		quantity: 177,
	},
	{
		garage_id: 212,
		part_id: 300,
		quantity: 47,
	},
	{
		garage_id: 212,
		part_id: 301,
		quantity: 59,
	},
	{
		garage_id: 212,
		part_id: 302,
		quantity: 148,
	},
	{
		garage_id: 212,
		part_id: 303,
		quantity: 101,
	},
	{
		garage_id: 212,
		part_id: 304,
		quantity: 465,
	},
	{
		garage_id: 212,
		part_id: 305,
		quantity: 458,
	},
	{
		garage_id: 212,
		part_id: 306,
		quantity: 299,
	},
	{
		garage_id: 212,
		part_id: 307,
		quantity: 264,
	},
	{
		garage_id: 212,
		part_id: 308,
		quantity: 213,
	},
	{
		garage_id: 212,
		part_id: 309,
		quantity: 230,
	},
	{
		garage_id: 212,
		part_id: 310,
		quantity: 219,
	},
	{
		garage_id: 212,
		part_id: 311,
		quantity: 193,
	},
	{
		garage_id: 212,
		part_id: 312,
		quantity: 474,
	},
	{
		garage_id: 212,
		part_id: 313,
		quantity: 497,
	},
	{
		garage_id: 212,
		part_id: 314,
		quantity: 178,
	},
	{
		garage_id: 212,
		part_id: 315,
		quantity: 51,
	},
	{
		garage_id: 212,
		part_id: 316,
		quantity: 282,
	},
	{
		garage_id: 212,
		part_id: 317,
		quantity: 130,
	},
	{
		garage_id: 212,
		part_id: 318,
		quantity: 128,
	},
	{
		garage_id: 212,
		part_id: 319,
		quantity: 277,
	},
	{
		garage_id: 212,
		part_id: 320,
		quantity: 164,
	},
	{
		garage_id: 212,
		part_id: 321,
		quantity: 420,
	},
	{
		garage_id: 212,
		part_id: 322,
		quantity: 346,
	},
	{
		garage_id: 212,
		part_id: 323,
		quantity: 182,
	},
	{
		garage_id: 212,
		part_id: 324,
		quantity: 426,
	},
	{
		garage_id: 212,
		part_id: 325,
		quantity: 449,
	},
	{
		garage_id: 212,
		part_id: 326,
		quantity: 68,
	},
	{
		garage_id: 212,
		part_id: 327,
		quantity: 71,
	},
	{
		garage_id: 212,
		part_id: 328,
		quantity: 30,
	},
	{
		garage_id: 212,
		part_id: 329,
		quantity: 218,
	},
	{
		garage_id: 212,
		part_id: 330,
		quantity: 483,
	},
	{
		garage_id: 212,
		part_id: 331,
		quantity: 72,
	},
	{
		garage_id: 212,
		part_id: 332,
		quantity: 385,
	},
	{
		garage_id: 212,
		part_id: 333,
		quantity: 12,
	},
	{
		garage_id: 212,
		part_id: 334,
		quantity: 149,
	},
	{
		garage_id: 212,
		part_id: 335,
		quantity: 374,
	},
	{
		garage_id: 212,
		part_id: 336,
		quantity: 227,
	},
	{
		garage_id: 212,
		part_id: 337,
		quantity: 229,
	},
	{
		garage_id: 212,
		part_id: 338,
		quantity: 355,
	},
	{
		garage_id: 212,
		part_id: 339,
		quantity: 71,
	},
	{
		garage_id: 212,
		part_id: 340,
		quantity: 192,
	},
	{
		garage_id: 212,
		part_id: 341,
		quantity: 222,
	},
	{
		garage_id: 212,
		part_id: 342,
		quantity: 45,
	},
	{
		garage_id: 212,
		part_id: 343,
		quantity: 250,
	},
	{
		garage_id: 212,
		part_id: 344,
		quantity: 264,
	},
	{
		garage_id: 212,
		part_id: 345,
		quantity: 212,
	},
	{
		garage_id: 212,
		part_id: 346,
		quantity: 146,
	},
	{
		garage_id: 212,
		part_id: 347,
		quantity: 7,
	},
	{
		garage_id: 212,
		part_id: 348,
		quantity: 496,
	},
	{
		garage_id: 212,
		part_id: 349,
		quantity: 259,
	},
	{
		garage_id: 212,
		part_id: 350,
		quantity: 329,
	},
	{
		garage_id: 212,
		part_id: 351,
		quantity: 160,
	},
	{
		garage_id: 212,
		part_id: 352,
		quantity: 278,
	},
	{
		garage_id: 212,
		part_id: 353,
		quantity: 3,
	},
	{
		garage_id: 212,
		part_id: 354,
		quantity: 178,
	},
	{
		garage_id: 212,
		part_id: 355,
		quantity: 229,
	},
	{
		garage_id: 212,
		part_id: 356,
		quantity: 226,
	},
	{
		garage_id: 212,
		part_id: 357,
		quantity: 408,
	},
	{
		garage_id: 212,
		part_id: 358,
		quantity: 43,
	},
	{
		garage_id: 212,
		part_id: 359,
		quantity: 180,
	},
	{
		garage_id: 212,
		part_id: 360,
		quantity: 245,
	},
	{
		garage_id: 212,
		part_id: 361,
		quantity: 162,
	},
	{
		garage_id: 212,
		part_id: 362,
		quantity: 375,
	},
	{
		garage_id: 212,
		part_id: 363,
		quantity: 322,
	},
	{
		garage_id: 212,
		part_id: 364,
		quantity: 243,
	},
	{
		garage_id: 212,
		part_id: 365,
		quantity: 287,
	},
	{
		garage_id: 212,
		part_id: 366,
		quantity: 332,
	},
	{
		garage_id: 212,
		part_id: 367,
		quantity: 321,
	},
	{
		garage_id: 212,
		part_id: 368,
		quantity: 290,
	},
	{
		garage_id: 212,
		part_id: 369,
		quantity: 187,
	},
	{
		garage_id: 212,
		part_id: 370,
		quantity: 137,
	},
	{
		garage_id: 212,
		part_id: 371,
		quantity: 60,
	},
	{
		garage_id: 212,
		part_id: 372,
		quantity: 54,
	},
	{
		garage_id: 212,
		part_id: 373,
		quantity: 322,
	},
	{
		garage_id: 212,
		part_id: 374,
		quantity: 278,
	},
	{
		garage_id: 212,
		part_id: 375,
		quantity: 235,
	},
	{
		garage_id: 212,
		part_id: 376,
		quantity: 438,
	},
	{
		garage_id: 212,
		part_id: 377,
		quantity: 436,
	},
	{
		garage_id: 212,
		part_id: 378,
		quantity: 152,
	},
	{
		garage_id: 212,
		part_id: 379,
		quantity: 416,
	},
	{
		garage_id: 212,
		part_id: 380,
		quantity: 27,
	},
	{
		garage_id: 212,
		part_id: 381,
		quantity: 360,
	},
	{
		garage_id: 212,
		part_id: 382,
		quantity: 423,
	},
	{
		garage_id: 212,
		part_id: 383,
		quantity: 204,
	},
	{
		garage_id: 212,
		part_id: 384,
		quantity: 9,
	},
	{
		garage_id: 212,
		part_id: 385,
		quantity: 58,
	},
	{
		garage_id: 212,
		part_id: 386,
		quantity: 479,
	},
	{
		garage_id: 212,
		part_id: 387,
		quantity: 323,
	},
	{
		garage_id: 212,
		part_id: 388,
		quantity: 311,
	},
	{
		garage_id: 212,
		part_id: 389,
		quantity: 331,
	},
	{
		garage_id: 212,
		part_id: 390,
		quantity: 118,
	},
	{
		garage_id: 212,
		part_id: 391,
		quantity: 210,
	},
	{
		garage_id: 212,
		part_id: 392,
		quantity: 388,
	},
	{
		garage_id: 212,
		part_id: 393,
		quantity: 117,
	},
	{
		garage_id: 212,
		part_id: 394,
		quantity: 169,
	},
	{
		garage_id: 212,
		part_id: 395,
		quantity: 413,
	},
	{
		garage_id: 212,
		part_id: 396,
		quantity: 69,
	},
	{
		garage_id: 212,
		part_id: 397,
		quantity: 328,
	},
	{
		garage_id: 212,
		part_id: 398,
		quantity: 92,
	},
	{
		garage_id: 212,
		part_id: 399,
		quantity: 448,
	},
	{
		garage_id: 213,
		part_id: 0,
		quantity: 125,
	},
	{
		garage_id: 213,
		part_id: 1,
		quantity: 191,
	},
	{
		garage_id: 213,
		part_id: 2,
		quantity: 446,
	},
	{
		garage_id: 213,
		part_id: 3,
		quantity: 81,
	},
	{
		garage_id: 213,
		part_id: 4,
		quantity: 183,
	},
	{
		garage_id: 213,
		part_id: 5,
		quantity: 391,
	},
	{
		garage_id: 213,
		part_id: 6,
		quantity: 167,
	},
	{
		garage_id: 213,
		part_id: 7,
		quantity: 382,
	},
	{
		garage_id: 213,
		part_id: 8,
		quantity: 324,
	},
	{
		garage_id: 213,
		part_id: 9,
		quantity: 462,
	},
	{
		garage_id: 213,
		part_id: 10,
		quantity: 48,
	},
	{
		garage_id: 213,
		part_id: 11,
		quantity: 429,
	},
	{
		garage_id: 213,
		part_id: 12,
		quantity: 106,
	},
	{
		garage_id: 213,
		part_id: 13,
		quantity: 489,
	},
	{
		garage_id: 213,
		part_id: 14,
		quantity: 284,
	},
	{
		garage_id: 213,
		part_id: 15,
		quantity: 235,
	},
	{
		garage_id: 213,
		part_id: 16,
		quantity: 71,
	},
	{
		garage_id: 213,
		part_id: 17,
		quantity: 472,
	},
	{
		garage_id: 213,
		part_id: 18,
		quantity: 213,
	},
	{
		garage_id: 213,
		part_id: 19,
		quantity: 204,
	},
	{
		garage_id: 213,
		part_id: 20,
		quantity: 200,
	},
	{
		garage_id: 213,
		part_id: 21,
		quantity: 148,
	},
	{
		garage_id: 213,
		part_id: 22,
		quantity: 190,
	},
	{
		garage_id: 213,
		part_id: 23,
		quantity: 493,
	},
	{
		garage_id: 213,
		part_id: 24,
		quantity: 245,
	},
	{
		garage_id: 213,
		part_id: 25,
		quantity: 126,
	},
	{
		garage_id: 213,
		part_id: 26,
		quantity: 168,
	},
	{
		garage_id: 213,
		part_id: 27,
		quantity: 273,
	},
	{
		garage_id: 213,
		part_id: 28,
		quantity: 454,
	},
	{
		garage_id: 213,
		part_id: 29,
		quantity: 143,
	},
	{
		garage_id: 213,
		part_id: 30,
		quantity: 385,
	},
	{
		garage_id: 213,
		part_id: 31,
		quantity: 391,
	},
	{
		garage_id: 213,
		part_id: 32,
		quantity: 390,
	},
	{
		garage_id: 213,
		part_id: 33,
		quantity: 187,
	},
	{
		garage_id: 213,
		part_id: 34,
		quantity: 341,
	},
	{
		garage_id: 213,
		part_id: 35,
		quantity: 121,
	},
	{
		garage_id: 213,
		part_id: 36,
		quantity: 403,
	},
	{
		garage_id: 213,
		part_id: 37,
		quantity: 428,
	},
	{
		garage_id: 213,
		part_id: 38,
		quantity: 1,
	},
	{
		garage_id: 213,
		part_id: 39,
		quantity: 386,
	},
	{
		garage_id: 213,
		part_id: 40,
		quantity: 45,
	},
	{
		garage_id: 213,
		part_id: 41,
		quantity: 294,
	},
	{
		garage_id: 213,
		part_id: 42,
		quantity: 36,
	},
	{
		garage_id: 213,
		part_id: 43,
		quantity: 113,
	},
	{
		garage_id: 213,
		part_id: 44,
		quantity: 209,
	},
	{
		garage_id: 213,
		part_id: 45,
		quantity: 109,
	},
	{
		garage_id: 213,
		part_id: 46,
		quantity: 118,
	},
	{
		garage_id: 213,
		part_id: 47,
		quantity: 447,
	},
	{
		garage_id: 213,
		part_id: 48,
		quantity: 49,
	},
	{
		garage_id: 213,
		part_id: 49,
		quantity: 209,
	},
	{
		garage_id: 213,
		part_id: 50,
		quantity: 233,
	},
	{
		garage_id: 213,
		part_id: 51,
		quantity: 297,
	},
	{
		garage_id: 213,
		part_id: 52,
		quantity: 132,
	},
	{
		garage_id: 213,
		part_id: 53,
		quantity: 445,
	},
	{
		garage_id: 213,
		part_id: 54,
		quantity: 236,
	},
	{
		garage_id: 213,
		part_id: 55,
		quantity: 253,
	},
	{
		garage_id: 213,
		part_id: 56,
		quantity: 325,
	},
	{
		garage_id: 213,
		part_id: 57,
		quantity: 367,
	},
	{
		garage_id: 213,
		part_id: 58,
		quantity: 0,
	},
	{
		garage_id: 213,
		part_id: 59,
		quantity: 231,
	},
	{
		garage_id: 213,
		part_id: 60,
		quantity: 203,
	},
	{
		garage_id: 213,
		part_id: 61,
		quantity: 281,
	},
	{
		garage_id: 213,
		part_id: 62,
		quantity: 100,
	},
	{
		garage_id: 213,
		part_id: 63,
		quantity: 198,
	},
	{
		garage_id: 213,
		part_id: 64,
		quantity: 374,
	},
	{
		garage_id: 213,
		part_id: 65,
		quantity: 396,
	},
	{
		garage_id: 213,
		part_id: 66,
		quantity: 196,
	},
	{
		garage_id: 213,
		part_id: 67,
		quantity: 204,
	},
	{
		garage_id: 213,
		part_id: 68,
		quantity: 265,
	},
	{
		garage_id: 213,
		part_id: 69,
		quantity: 144,
	},
	{
		garage_id: 213,
		part_id: 70,
		quantity: 206,
	},
	{
		garage_id: 213,
		part_id: 71,
		quantity: 178,
	},
	{
		garage_id: 213,
		part_id: 72,
		quantity: 59,
	},
	{
		garage_id: 213,
		part_id: 73,
		quantity: 489,
	},
	{
		garage_id: 213,
		part_id: 74,
		quantity: 465,
	},
	{
		garage_id: 213,
		part_id: 75,
		quantity: 303,
	},
	{
		garage_id: 213,
		part_id: 76,
		quantity: 84,
	},
	{
		garage_id: 213,
		part_id: 77,
		quantity: 413,
	},
	{
		garage_id: 213,
		part_id: 78,
		quantity: 83,
	},
	{
		garage_id: 213,
		part_id: 79,
		quantity: 94,
	},
	{
		garage_id: 213,
		part_id: 80,
		quantity: 33,
	},
	{
		garage_id: 213,
		part_id: 81,
		quantity: 441,
	},
	{
		garage_id: 213,
		part_id: 82,
		quantity: 185,
	},
	{
		garage_id: 213,
		part_id: 83,
		quantity: 238,
	},
	{
		garage_id: 213,
		part_id: 84,
		quantity: 159,
	},
	{
		garage_id: 213,
		part_id: 85,
		quantity: 273,
	},
	{
		garage_id: 213,
		part_id: 86,
		quantity: 64,
	},
	{
		garage_id: 213,
		part_id: 87,
		quantity: 284,
	},
	{
		garage_id: 213,
		part_id: 88,
		quantity: 196,
	},
	{
		garage_id: 213,
		part_id: 89,
		quantity: 73,
	},
	{
		garage_id: 213,
		part_id: 90,
		quantity: 265,
	},
	{
		garage_id: 213,
		part_id: 91,
		quantity: 466,
	},
	{
		garage_id: 213,
		part_id: 92,
		quantity: 327,
	},
	{
		garage_id: 213,
		part_id: 93,
		quantity: 304,
	},
	{
		garage_id: 213,
		part_id: 94,
		quantity: 317,
	},
	{
		garage_id: 213,
		part_id: 95,
		quantity: 114,
	},
	{
		garage_id: 213,
		part_id: 96,
		quantity: 182,
	},
	{
		garage_id: 213,
		part_id: 97,
		quantity: 278,
	},
	{
		garage_id: 213,
		part_id: 98,
		quantity: 67,
	},
	{
		garage_id: 213,
		part_id: 99,
		quantity: 287,
	},
	{
		garage_id: 213,
		part_id: 100,
		quantity: 321,
	},
	{
		garage_id: 213,
		part_id: 101,
		quantity: 50,
	},
	{
		garage_id: 213,
		part_id: 102,
		quantity: 61,
	},
	{
		garage_id: 213,
		part_id: 103,
		quantity: 489,
	},
	{
		garage_id: 213,
		part_id: 104,
		quantity: 262,
	},
	{
		garage_id: 213,
		part_id: 105,
		quantity: 234,
	},
	{
		garage_id: 213,
		part_id: 106,
		quantity: 72,
	},
	{
		garage_id: 213,
		part_id: 107,
		quantity: 288,
	},
	{
		garage_id: 213,
		part_id: 108,
		quantity: 85,
	},
	{
		garage_id: 213,
		part_id: 109,
		quantity: 100,
	},
	{
		garage_id: 213,
		part_id: 110,
		quantity: 143,
	},
	{
		garage_id: 213,
		part_id: 111,
		quantity: 285,
	},
	{
		garage_id: 213,
		part_id: 112,
		quantity: 385,
	},
	{
		garage_id: 213,
		part_id: 113,
		quantity: 439,
	},
	{
		garage_id: 213,
		part_id: 114,
		quantity: 172,
	},
	{
		garage_id: 213,
		part_id: 115,
		quantity: 41,
	},
	{
		garage_id: 213,
		part_id: 116,
		quantity: 377,
	},
	{
		garage_id: 213,
		part_id: 117,
		quantity: 429,
	},
	{
		garage_id: 213,
		part_id: 118,
		quantity: 491,
	},
	{
		garage_id: 213,
		part_id: 119,
		quantity: 352,
	},
	{
		garage_id: 213,
		part_id: 120,
		quantity: 230,
	},
	{
		garage_id: 213,
		part_id: 121,
		quantity: 36,
	},
	{
		garage_id: 213,
		part_id: 122,
		quantity: 44,
	},
	{
		garage_id: 213,
		part_id: 123,
		quantity: 259,
	},
	{
		garage_id: 213,
		part_id: 124,
		quantity: 236,
	},
	{
		garage_id: 213,
		part_id: 125,
		quantity: 352,
	},
	{
		garage_id: 213,
		part_id: 126,
		quantity: 470,
	},
	{
		garage_id: 213,
		part_id: 127,
		quantity: 132,
	},
	{
		garage_id: 213,
		part_id: 128,
		quantity: 120,
	},
	{
		garage_id: 213,
		part_id: 129,
		quantity: 208,
	},
	{
		garage_id: 213,
		part_id: 130,
		quantity: 376,
	},
	{
		garage_id: 213,
		part_id: 131,
		quantity: 357,
	},
	{
		garage_id: 213,
		part_id: 132,
		quantity: 369,
	},
	{
		garage_id: 213,
		part_id: 133,
		quantity: 128,
	},
	{
		garage_id: 213,
		part_id: 134,
		quantity: 393,
	},
	{
		garage_id: 213,
		part_id: 135,
		quantity: 138,
	},
	{
		garage_id: 213,
		part_id: 136,
		quantity: 83,
	},
	{
		garage_id: 213,
		part_id: 137,
		quantity: 28,
	},
	{
		garage_id: 213,
		part_id: 138,
		quantity: 282,
	},
	{
		garage_id: 213,
		part_id: 139,
		quantity: 207,
	},
	{
		garage_id: 213,
		part_id: 140,
		quantity: 357,
	},
	{
		garage_id: 213,
		part_id: 141,
		quantity: 419,
	},
	{
		garage_id: 213,
		part_id: 142,
		quantity: 190,
	},
	{
		garage_id: 213,
		part_id: 143,
		quantity: 422,
	},
	{
		garage_id: 213,
		part_id: 144,
		quantity: 46,
	},
	{
		garage_id: 213,
		part_id: 145,
		quantity: 488,
	},
	{
		garage_id: 213,
		part_id: 146,
		quantity: 412,
	},
	{
		garage_id: 213,
		part_id: 147,
		quantity: 460,
	},
	{
		garage_id: 213,
		part_id: 148,
		quantity: 388,
	},
	{
		garage_id: 213,
		part_id: 149,
		quantity: 355,
	},
	{
		garage_id: 213,
		part_id: 150,
		quantity: 207,
	},
	{
		garage_id: 213,
		part_id: 151,
		quantity: 232,
	},
	{
		garage_id: 213,
		part_id: 152,
		quantity: 290,
	},
	{
		garage_id: 213,
		part_id: 153,
		quantity: 450,
	},
	{
		garage_id: 213,
		part_id: 154,
		quantity: 173,
	},
	{
		garage_id: 213,
		part_id: 155,
		quantity: 438,
	},
	{
		garage_id: 213,
		part_id: 156,
		quantity: 230,
	},
	{
		garage_id: 213,
		part_id: 157,
		quantity: 320,
	},
	{
		garage_id: 213,
		part_id: 158,
		quantity: 334,
	},
	{
		garage_id: 213,
		part_id: 159,
		quantity: 14,
	},
	{
		garage_id: 213,
		part_id: 160,
		quantity: 354,
	},
	{
		garage_id: 213,
		part_id: 161,
		quantity: 279,
	},
	{
		garage_id: 213,
		part_id: 162,
		quantity: 26,
	},
	{
		garage_id: 213,
		part_id: 163,
		quantity: 495,
	},
	{
		garage_id: 213,
		part_id: 164,
		quantity: 470,
	},
	{
		garage_id: 213,
		part_id: 165,
		quantity: 40,
	},
	{
		garage_id: 213,
		part_id: 166,
		quantity: 18,
	},
	{
		garage_id: 213,
		part_id: 167,
		quantity: 162,
	},
	{
		garage_id: 213,
		part_id: 168,
		quantity: 437,
	},
	{
		garage_id: 213,
		part_id: 169,
		quantity: 401,
	},
	{
		garage_id: 213,
		part_id: 170,
		quantity: 374,
	},
	{
		garage_id: 213,
		part_id: 171,
		quantity: 308,
	},
	{
		garage_id: 213,
		part_id: 172,
		quantity: 205,
	},
	{
		garage_id: 213,
		part_id: 173,
		quantity: 82,
	},
	{
		garage_id: 213,
		part_id: 174,
		quantity: 189,
	},
	{
		garage_id: 213,
		part_id: 175,
		quantity: 438,
	},
	{
		garage_id: 213,
		part_id: 176,
		quantity: 308,
	},
	{
		garage_id: 213,
		part_id: 177,
		quantity: 18,
	},
	{
		garage_id: 213,
		part_id: 178,
		quantity: 20,
	},
	{
		garage_id: 213,
		part_id: 179,
		quantity: 438,
	},
	{
		garage_id: 213,
		part_id: 180,
		quantity: 349,
	},
	{
		garage_id: 213,
		part_id: 181,
		quantity: 383,
	},
	{
		garage_id: 213,
		part_id: 182,
		quantity: 218,
	},
	{
		garage_id: 213,
		part_id: 183,
		quantity: 327,
	},
	{
		garage_id: 213,
		part_id: 184,
		quantity: 150,
	},
	{
		garage_id: 213,
		part_id: 185,
		quantity: 225,
	},
	{
		garage_id: 213,
		part_id: 186,
		quantity: 54,
	},
	{
		garage_id: 213,
		part_id: 187,
		quantity: 360,
	},
	{
		garage_id: 213,
		part_id: 188,
		quantity: 459,
	},
	{
		garage_id: 213,
		part_id: 189,
		quantity: 175,
	},
	{
		garage_id: 213,
		part_id: 190,
		quantity: 0,
	},
	{
		garage_id: 213,
		part_id: 191,
		quantity: 178,
	},
	{
		garage_id: 213,
		part_id: 192,
		quantity: 157,
	},
	{
		garage_id: 213,
		part_id: 193,
		quantity: 404,
	},
	{
		garage_id: 213,
		part_id: 194,
		quantity: 495,
	},
	{
		garage_id: 213,
		part_id: 195,
		quantity: 137,
	},
	{
		garage_id: 213,
		part_id: 196,
		quantity: 40,
	},
	{
		garage_id: 213,
		part_id: 197,
		quantity: 31,
	},
	{
		garage_id: 213,
		part_id: 198,
		quantity: 476,
	},
	{
		garage_id: 213,
		part_id: 199,
		quantity: 347,
	},
	{
		garage_id: 213,
		part_id: 200,
		quantity: 226,
	},
	{
		garage_id: 213,
		part_id: 201,
		quantity: 295,
	},
	{
		garage_id: 213,
		part_id: 202,
		quantity: 17,
	},
	{
		garage_id: 213,
		part_id: 203,
		quantity: 96,
	},
	{
		garage_id: 213,
		part_id: 204,
		quantity: 182,
	},
	{
		garage_id: 213,
		part_id: 205,
		quantity: 320,
	},
	{
		garage_id: 213,
		part_id: 206,
		quantity: 149,
	},
	{
		garage_id: 213,
		part_id: 207,
		quantity: 337,
	},
	{
		garage_id: 213,
		part_id: 208,
		quantity: 92,
	},
	{
		garage_id: 213,
		part_id: 209,
		quantity: 39,
	},
	{
		garage_id: 213,
		part_id: 210,
		quantity: 237,
	},
	{
		garage_id: 213,
		part_id: 211,
		quantity: 252,
	},
	{
		garage_id: 213,
		part_id: 212,
		quantity: 63,
	},
	{
		garage_id: 213,
		part_id: 213,
		quantity: 392,
	},
	{
		garage_id: 213,
		part_id: 214,
		quantity: 194,
	},
	{
		garage_id: 213,
		part_id: 215,
		quantity: 178,
	},
	{
		garage_id: 213,
		part_id: 216,
		quantity: 498,
	},
	{
		garage_id: 213,
		part_id: 217,
		quantity: 434,
	},
	{
		garage_id: 213,
		part_id: 218,
		quantity: 188,
	},
	{
		garage_id: 213,
		part_id: 219,
		quantity: 284,
	},
	{
		garage_id: 213,
		part_id: 220,
		quantity: 497,
	},
	{
		garage_id: 213,
		part_id: 221,
		quantity: 445,
	},
	{
		garage_id: 213,
		part_id: 222,
		quantity: 233,
	},
	{
		garage_id: 213,
		part_id: 223,
		quantity: 445,
	},
	{
		garage_id: 213,
		part_id: 224,
		quantity: 355,
	},
	{
		garage_id: 213,
		part_id: 225,
		quantity: 199,
	},
	{
		garage_id: 213,
		part_id: 226,
		quantity: 0,
	},
	{
		garage_id: 213,
		part_id: 227,
		quantity: 136,
	},
	{
		garage_id: 213,
		part_id: 228,
		quantity: 270,
	},
	{
		garage_id: 213,
		part_id: 229,
		quantity: 366,
	},
	{
		garage_id: 213,
		part_id: 230,
		quantity: 445,
	},
	{
		garage_id: 213,
		part_id: 231,
		quantity: 210,
	},
	{
		garage_id: 213,
		part_id: 232,
		quantity: 79,
	},
	{
		garage_id: 213,
		part_id: 233,
		quantity: 198,
	},
	{
		garage_id: 213,
		part_id: 234,
		quantity: 154,
	},
	{
		garage_id: 213,
		part_id: 235,
		quantity: 95,
	},
	{
		garage_id: 213,
		part_id: 236,
		quantity: 33,
	},
	{
		garage_id: 213,
		part_id: 237,
		quantity: 54,
	},
	{
		garage_id: 213,
		part_id: 238,
		quantity: 152,
	},
	{
		garage_id: 213,
		part_id: 239,
		quantity: 72,
	},
	{
		garage_id: 213,
		part_id: 240,
		quantity: 83,
	},
	{
		garage_id: 213,
		part_id: 241,
		quantity: 166,
	},
	{
		garage_id: 213,
		part_id: 242,
		quantity: 365,
	},
	{
		garage_id: 213,
		part_id: 243,
		quantity: 302,
	},
	{
		garage_id: 213,
		part_id: 244,
		quantity: 354,
	},
	{
		garage_id: 213,
		part_id: 245,
		quantity: 487,
	},
	{
		garage_id: 213,
		part_id: 246,
		quantity: 156,
	},
	{
		garage_id: 213,
		part_id: 247,
		quantity: 34,
	},
	{
		garage_id: 213,
		part_id: 248,
		quantity: 333,
	},
	{
		garage_id: 213,
		part_id: 249,
		quantity: 80,
	},
	{
		garage_id: 213,
		part_id: 250,
		quantity: 320,
	},
	{
		garage_id: 213,
		part_id: 251,
		quantity: 412,
	},
	{
		garage_id: 213,
		part_id: 252,
		quantity: 106,
	},
	{
		garage_id: 213,
		part_id: 253,
		quantity: 10,
	},
	{
		garage_id: 213,
		part_id: 254,
		quantity: 136,
	},
	{
		garage_id: 213,
		part_id: 255,
		quantity: 418,
	},
	{
		garage_id: 213,
		part_id: 256,
		quantity: 428,
	},
	{
		garage_id: 213,
		part_id: 257,
		quantity: 237,
	},
	{
		garage_id: 213,
		part_id: 258,
		quantity: 123,
	},
	{
		garage_id: 213,
		part_id: 259,
		quantity: 128,
	},
	{
		garage_id: 213,
		part_id: 260,
		quantity: 199,
	},
	{
		garage_id: 213,
		part_id: 261,
		quantity: 4,
	},
	{
		garage_id: 213,
		part_id: 262,
		quantity: 102,
	},
	{
		garage_id: 213,
		part_id: 263,
		quantity: 204,
	},
	{
		garage_id: 213,
		part_id: 264,
		quantity: 224,
	},
	{
		garage_id: 213,
		part_id: 265,
		quantity: 212,
	},
	{
		garage_id: 213,
		part_id: 266,
		quantity: 165,
	},
	{
		garage_id: 213,
		part_id: 267,
		quantity: 434,
	},
	{
		garage_id: 213,
		part_id: 268,
		quantity: 385,
	},
	{
		garage_id: 213,
		part_id: 269,
		quantity: 159,
	},
	{
		garage_id: 213,
		part_id: 270,
		quantity: 333,
	},
	{
		garage_id: 213,
		part_id: 271,
		quantity: 179,
	},
	{
		garage_id: 213,
		part_id: 272,
		quantity: 473,
	},
	{
		garage_id: 213,
		part_id: 273,
		quantity: 441,
	},
	{
		garage_id: 213,
		part_id: 274,
		quantity: 427,
	},
	{
		garage_id: 213,
		part_id: 275,
		quantity: 379,
	},
	{
		garage_id: 213,
		part_id: 276,
		quantity: 366,
	},
	{
		garage_id: 213,
		part_id: 277,
		quantity: 407,
	},
	{
		garage_id: 213,
		part_id: 278,
		quantity: 127,
	},
	{
		garage_id: 213,
		part_id: 279,
		quantity: 157,
	},
	{
		garage_id: 213,
		part_id: 280,
		quantity: 403,
	},
	{
		garage_id: 213,
		part_id: 281,
		quantity: 77,
	},
	{
		garage_id: 213,
		part_id: 282,
		quantity: 474,
	},
	{
		garage_id: 213,
		part_id: 283,
		quantity: 258,
	},
	{
		garage_id: 213,
		part_id: 284,
		quantity: 344,
	},
	{
		garage_id: 213,
		part_id: 285,
		quantity: 14,
	},
	{
		garage_id: 213,
		part_id: 286,
		quantity: 422,
	},
	{
		garage_id: 213,
		part_id: 287,
		quantity: 393,
	},
	{
		garage_id: 213,
		part_id: 288,
		quantity: 467,
	},
	{
		garage_id: 213,
		part_id: 289,
		quantity: 358,
	},
	{
		garage_id: 213,
		part_id: 290,
		quantity: 11,
	},
	{
		garage_id: 213,
		part_id: 291,
		quantity: 480,
	},
	{
		garage_id: 213,
		part_id: 292,
		quantity: 437,
	},
	{
		garage_id: 213,
		part_id: 293,
		quantity: 91,
	},
	{
		garage_id: 213,
		part_id: 294,
		quantity: 235,
	},
	{
		garage_id: 213,
		part_id: 295,
		quantity: 59,
	},
	{
		garage_id: 213,
		part_id: 296,
		quantity: 154,
	},
	{
		garage_id: 213,
		part_id: 297,
		quantity: 202,
	},
	{
		garage_id: 213,
		part_id: 298,
		quantity: 219,
	},
	{
		garage_id: 213,
		part_id: 299,
		quantity: 336,
	},
	{
		garage_id: 213,
		part_id: 300,
		quantity: 173,
	},
	{
		garage_id: 213,
		part_id: 301,
		quantity: 497,
	},
	{
		garage_id: 213,
		part_id: 302,
		quantity: 247,
	},
	{
		garage_id: 213,
		part_id: 303,
		quantity: 499,
	},
	{
		garage_id: 213,
		part_id: 304,
		quantity: 166,
	},
	{
		garage_id: 213,
		part_id: 305,
		quantity: 273,
	},
	{
		garage_id: 213,
		part_id: 306,
		quantity: 418,
	},
	{
		garage_id: 213,
		part_id: 307,
		quantity: 400,
	},
	{
		garage_id: 213,
		part_id: 308,
		quantity: 259,
	},
	{
		garage_id: 213,
		part_id: 309,
		quantity: 58,
	},
	{
		garage_id: 213,
		part_id: 310,
		quantity: 374,
	},
	{
		garage_id: 213,
		part_id: 311,
		quantity: 62,
	},
	{
		garage_id: 213,
		part_id: 312,
		quantity: 14,
	},
	{
		garage_id: 213,
		part_id: 313,
		quantity: 444,
	},
	{
		garage_id: 213,
		part_id: 314,
		quantity: 113,
	},
	{
		garage_id: 213,
		part_id: 315,
		quantity: 250,
	},
	{
		garage_id: 213,
		part_id: 316,
		quantity: 37,
	},
	{
		garage_id: 213,
		part_id: 317,
		quantity: 368,
	},
	{
		garage_id: 213,
		part_id: 318,
		quantity: 293,
	},
	{
		garage_id: 213,
		part_id: 319,
		quantity: 80,
	},
	{
		garage_id: 213,
		part_id: 320,
		quantity: 414,
	},
	{
		garage_id: 213,
		part_id: 321,
		quantity: 465,
	},
	{
		garage_id: 213,
		part_id: 322,
		quantity: 157,
	},
	{
		garage_id: 213,
		part_id: 323,
		quantity: 136,
	},
	{
		garage_id: 213,
		part_id: 324,
		quantity: 432,
	},
	{
		garage_id: 213,
		part_id: 325,
		quantity: 90,
	},
	{
		garage_id: 213,
		part_id: 326,
		quantity: 458,
	},
	{
		garage_id: 213,
		part_id: 327,
		quantity: 207,
	},
	{
		garage_id: 213,
		part_id: 328,
		quantity: 404,
	},
	{
		garage_id: 213,
		part_id: 329,
		quantity: 124,
	},
	{
		garage_id: 213,
		part_id: 330,
		quantity: 356,
	},
	{
		garage_id: 213,
		part_id: 331,
		quantity: 496,
	},
	{
		garage_id: 213,
		part_id: 332,
		quantity: 267,
	},
	{
		garage_id: 213,
		part_id: 333,
		quantity: 0,
	},
	{
		garage_id: 213,
		part_id: 334,
		quantity: 43,
	},
	{
		garage_id: 213,
		part_id: 335,
		quantity: 232,
	},
	{
		garage_id: 213,
		part_id: 336,
		quantity: 28,
	},
	{
		garage_id: 213,
		part_id: 337,
		quantity: 206,
	},
	{
		garage_id: 213,
		part_id: 338,
		quantity: 336,
	},
	{
		garage_id: 213,
		part_id: 339,
		quantity: 337,
	},
	{
		garage_id: 213,
		part_id: 340,
		quantity: 436,
	},
	{
		garage_id: 213,
		part_id: 341,
		quantity: 166,
	},
	{
		garage_id: 213,
		part_id: 342,
		quantity: 447,
	},
	{
		garage_id: 213,
		part_id: 343,
		quantity: 220,
	},
	{
		garage_id: 213,
		part_id: 344,
		quantity: 233,
	},
	{
		garage_id: 213,
		part_id: 345,
		quantity: 48,
	},
	{
		garage_id: 213,
		part_id: 346,
		quantity: 52,
	},
	{
		garage_id: 213,
		part_id: 347,
		quantity: 40,
	},
	{
		garage_id: 213,
		part_id: 348,
		quantity: 192,
	},
	{
		garage_id: 213,
		part_id: 349,
		quantity: 408,
	},
	{
		garage_id: 213,
		part_id: 350,
		quantity: 269,
	},
	{
		garage_id: 213,
		part_id: 351,
		quantity: 242,
	},
	{
		garage_id: 213,
		part_id: 352,
		quantity: 422,
	},
	{
		garage_id: 213,
		part_id: 353,
		quantity: 18,
	},
	{
		garage_id: 213,
		part_id: 354,
		quantity: 402,
	},
	{
		garage_id: 213,
		part_id: 355,
		quantity: 394,
	},
	{
		garage_id: 213,
		part_id: 356,
		quantity: 117,
	},
	{
		garage_id: 213,
		part_id: 357,
		quantity: 468,
	},
	{
		garage_id: 213,
		part_id: 358,
		quantity: 387,
	},
	{
		garage_id: 213,
		part_id: 359,
		quantity: 392,
	},
	{
		garage_id: 213,
		part_id: 360,
		quantity: 497,
	},
	{
		garage_id: 213,
		part_id: 361,
		quantity: 194,
	},
	{
		garage_id: 213,
		part_id: 362,
		quantity: 0,
	},
	{
		garage_id: 213,
		part_id: 363,
		quantity: 219,
	},
	{
		garage_id: 213,
		part_id: 364,
		quantity: 68,
	},
	{
		garage_id: 213,
		part_id: 365,
		quantity: 322,
	},
	{
		garage_id: 213,
		part_id: 366,
		quantity: 57,
	},
	{
		garage_id: 213,
		part_id: 367,
		quantity: 316,
	},
	{
		garage_id: 213,
		part_id: 368,
		quantity: 48,
	},
	{
		garage_id: 213,
		part_id: 369,
		quantity: 252,
	},
	{
		garage_id: 213,
		part_id: 370,
		quantity: 199,
	},
	{
		garage_id: 213,
		part_id: 371,
		quantity: 234,
	},
	{
		garage_id: 213,
		part_id: 372,
		quantity: 426,
	},
	{
		garage_id: 213,
		part_id: 373,
		quantity: 251,
	},
	{
		garage_id: 213,
		part_id: 374,
		quantity: 256,
	},
	{
		garage_id: 213,
		part_id: 375,
		quantity: 477,
	},
	{
		garage_id: 213,
		part_id: 376,
		quantity: 348,
	},
	{
		garage_id: 213,
		part_id: 377,
		quantity: 402,
	},
	{
		garage_id: 213,
		part_id: 378,
		quantity: 24,
	},
	{
		garage_id: 213,
		part_id: 379,
		quantity: 9,
	},
	{
		garage_id: 213,
		part_id: 380,
		quantity: 170,
	},
	{
		garage_id: 213,
		part_id: 381,
		quantity: 438,
	},
	{
		garage_id: 213,
		part_id: 382,
		quantity: 490,
	},
	{
		garage_id: 213,
		part_id: 383,
		quantity: 114,
	},
	{
		garage_id: 213,
		part_id: 384,
		quantity: 497,
	},
	{
		garage_id: 213,
		part_id: 385,
		quantity: 337,
	},
	{
		garage_id: 213,
		part_id: 386,
		quantity: 63,
	},
	{
		garage_id: 213,
		part_id: 387,
		quantity: 352,
	},
	{
		garage_id: 213,
		part_id: 388,
		quantity: 462,
	},
	{
		garage_id: 213,
		part_id: 389,
		quantity: 472,
	},
	{
		garage_id: 213,
		part_id: 390,
		quantity: 96,
	},
	{
		garage_id: 213,
		part_id: 391,
		quantity: 143,
	},
	{
		garage_id: 213,
		part_id: 392,
		quantity: 159,
	},
	{
		garage_id: 213,
		part_id: 393,
		quantity: 315,
	},
	{
		garage_id: 213,
		part_id: 394,
		quantity: 151,
	},
	{
		garage_id: 213,
		part_id: 395,
		quantity: 486,
	},
	{
		garage_id: 213,
		part_id: 396,
		quantity: 139,
	},
	{
		garage_id: 213,
		part_id: 397,
		quantity: 61,
	},
	{
		garage_id: 213,
		part_id: 398,
		quantity: 388,
	},
	{
		garage_id: 213,
		part_id: 399,
		quantity: 201,
	},
	{
		garage_id: 214,
		part_id: 0,
		quantity: 356,
	},
	{
		garage_id: 214,
		part_id: 1,
		quantity: 463,
	},
	{
		garage_id: 214,
		part_id: 2,
		quantity: 87,
	},
	{
		garage_id: 214,
		part_id: 3,
		quantity: 80,
	},
	{
		garage_id: 214,
		part_id: 4,
		quantity: 286,
	},
	{
		garage_id: 214,
		part_id: 5,
		quantity: 440,
	},
	{
		garage_id: 214,
		part_id: 6,
		quantity: 276,
	},
	{
		garage_id: 214,
		part_id: 7,
		quantity: 362,
	},
	{
		garage_id: 214,
		part_id: 8,
		quantity: 46,
	},
	{
		garage_id: 214,
		part_id: 9,
		quantity: 210,
	},
	{
		garage_id: 214,
		part_id: 10,
		quantity: 187,
	},
	{
		garage_id: 214,
		part_id: 11,
		quantity: 294,
	},
	{
		garage_id: 214,
		part_id: 12,
		quantity: 220,
	},
	{
		garage_id: 214,
		part_id: 13,
		quantity: 26,
	},
	{
		garage_id: 214,
		part_id: 14,
		quantity: 447,
	},
	{
		garage_id: 214,
		part_id: 15,
		quantity: 173,
	},
	{
		garage_id: 214,
		part_id: 16,
		quantity: 151,
	},
	{
		garage_id: 214,
		part_id: 17,
		quantity: 471,
	},
	{
		garage_id: 214,
		part_id: 18,
		quantity: 362,
	},
	{
		garage_id: 214,
		part_id: 19,
		quantity: 125,
	},
	{
		garage_id: 214,
		part_id: 20,
		quantity: 359,
	},
	{
		garage_id: 214,
		part_id: 21,
		quantity: 440,
	},
	{
		garage_id: 214,
		part_id: 22,
		quantity: 246,
	},
	{
		garage_id: 214,
		part_id: 23,
		quantity: 249,
	},
	{
		garage_id: 214,
		part_id: 24,
		quantity: 30,
	},
	{
		garage_id: 214,
		part_id: 25,
		quantity: 339,
	},
	{
		garage_id: 214,
		part_id: 26,
		quantity: 157,
	},
	{
		garage_id: 214,
		part_id: 27,
		quantity: 168,
	},
	{
		garage_id: 214,
		part_id: 28,
		quantity: 181,
	},
	{
		garage_id: 214,
		part_id: 29,
		quantity: 1,
	},
	{
		garage_id: 214,
		part_id: 30,
		quantity: 306,
	},
	{
		garage_id: 214,
		part_id: 31,
		quantity: 196,
	},
	{
		garage_id: 214,
		part_id: 32,
		quantity: 431,
	},
	{
		garage_id: 214,
		part_id: 33,
		quantity: 150,
	},
	{
		garage_id: 214,
		part_id: 34,
		quantity: 464,
	},
	{
		garage_id: 214,
		part_id: 35,
		quantity: 369,
	},
	{
		garage_id: 214,
		part_id: 36,
		quantity: 363,
	},
	{
		garage_id: 214,
		part_id: 37,
		quantity: 499,
	},
	{
		garage_id: 214,
		part_id: 38,
		quantity: 70,
	},
	{
		garage_id: 214,
		part_id: 39,
		quantity: 102,
	},
	{
		garage_id: 214,
		part_id: 40,
		quantity: 435,
	},
	{
		garage_id: 214,
		part_id: 41,
		quantity: 76,
	},
	{
		garage_id: 214,
		part_id: 42,
		quantity: 192,
	},
	{
		garage_id: 214,
		part_id: 43,
		quantity: 283,
	},
	{
		garage_id: 214,
		part_id: 44,
		quantity: 330,
	},
	{
		garage_id: 214,
		part_id: 45,
		quantity: 165,
	},
	{
		garage_id: 214,
		part_id: 46,
		quantity: 126,
	},
	{
		garage_id: 214,
		part_id: 47,
		quantity: 153,
	},
	{
		garage_id: 214,
		part_id: 48,
		quantity: 260,
	},
	{
		garage_id: 214,
		part_id: 49,
		quantity: 484,
	},
	{
		garage_id: 214,
		part_id: 50,
		quantity: 377,
	},
	{
		garage_id: 214,
		part_id: 51,
		quantity: 265,
	},
	{
		garage_id: 214,
		part_id: 52,
		quantity: 255,
	},
	{
		garage_id: 214,
		part_id: 53,
		quantity: 450,
	},
	{
		garage_id: 214,
		part_id: 54,
		quantity: 191,
	},
	{
		garage_id: 214,
		part_id: 55,
		quantity: 268,
	},
	{
		garage_id: 214,
		part_id: 56,
		quantity: 186,
	},
	{
		garage_id: 214,
		part_id: 57,
		quantity: 356,
	},
	{
		garage_id: 214,
		part_id: 58,
		quantity: 99,
	},
	{
		garage_id: 214,
		part_id: 59,
		quantity: 134,
	},
	{
		garage_id: 214,
		part_id: 60,
		quantity: 40,
	},
	{
		garage_id: 214,
		part_id: 61,
		quantity: 189,
	},
	{
		garage_id: 214,
		part_id: 62,
		quantity: 88,
	},
	{
		garage_id: 214,
		part_id: 63,
		quantity: 153,
	},
	{
		garage_id: 214,
		part_id: 64,
		quantity: 425,
	},
	{
		garage_id: 214,
		part_id: 65,
		quantity: 283,
	},
	{
		garage_id: 214,
		part_id: 66,
		quantity: 38,
	},
	{
		garage_id: 214,
		part_id: 67,
		quantity: 216,
	},
	{
		garage_id: 214,
		part_id: 68,
		quantity: 435,
	},
	{
		garage_id: 214,
		part_id: 69,
		quantity: 24,
	},
	{
		garage_id: 214,
		part_id: 70,
		quantity: 152,
	},
	{
		garage_id: 214,
		part_id: 71,
		quantity: 63,
	},
	{
		garage_id: 214,
		part_id: 72,
		quantity: 257,
	},
	{
		garage_id: 214,
		part_id: 73,
		quantity: 269,
	},
	{
		garage_id: 214,
		part_id: 74,
		quantity: 136,
	},
	{
		garage_id: 214,
		part_id: 75,
		quantity: 273,
	},
	{
		garage_id: 214,
		part_id: 76,
		quantity: 122,
	},
	{
		garage_id: 214,
		part_id: 77,
		quantity: 256,
	},
	{
		garage_id: 214,
		part_id: 78,
		quantity: 418,
	},
	{
		garage_id: 214,
		part_id: 79,
		quantity: 67,
	},
	{
		garage_id: 214,
		part_id: 80,
		quantity: 14,
	},
	{
		garage_id: 214,
		part_id: 81,
		quantity: 383,
	},
	{
		garage_id: 214,
		part_id: 82,
		quantity: 206,
	},
	{
		garage_id: 214,
		part_id: 83,
		quantity: 51,
	},
	{
		garage_id: 214,
		part_id: 84,
		quantity: 228,
	},
	{
		garage_id: 214,
		part_id: 85,
		quantity: 389,
	},
	{
		garage_id: 214,
		part_id: 86,
		quantity: 210,
	},
	{
		garage_id: 214,
		part_id: 87,
		quantity: 20,
	},
	{
		garage_id: 214,
		part_id: 88,
		quantity: 418,
	},
	{
		garage_id: 214,
		part_id: 89,
		quantity: 163,
	},
	{
		garage_id: 214,
		part_id: 90,
		quantity: 480,
	},
	{
		garage_id: 214,
		part_id: 91,
		quantity: 358,
	},
	{
		garage_id: 214,
		part_id: 92,
		quantity: 408,
	},
	{
		garage_id: 214,
		part_id: 93,
		quantity: 99,
	},
	{
		garage_id: 214,
		part_id: 94,
		quantity: 421,
	},
	{
		garage_id: 214,
		part_id: 95,
		quantity: 362,
	},
	{
		garage_id: 214,
		part_id: 96,
		quantity: 98,
	},
	{
		garage_id: 214,
		part_id: 97,
		quantity: 190,
	},
	{
		garage_id: 214,
		part_id: 98,
		quantity: 4,
	},
	{
		garage_id: 214,
		part_id: 99,
		quantity: 263,
	},
	{
		garage_id: 214,
		part_id: 100,
		quantity: 333,
	},
	{
		garage_id: 214,
		part_id: 101,
		quantity: 422,
	},
	{
		garage_id: 214,
		part_id: 102,
		quantity: 84,
	},
	{
		garage_id: 214,
		part_id: 103,
		quantity: 94,
	},
	{
		garage_id: 214,
		part_id: 104,
		quantity: 362,
	},
	{
		garage_id: 214,
		part_id: 105,
		quantity: 328,
	},
	{
		garage_id: 214,
		part_id: 106,
		quantity: 439,
	},
	{
		garage_id: 214,
		part_id: 107,
		quantity: 54,
	},
	{
		garage_id: 214,
		part_id: 108,
		quantity: 70,
	},
	{
		garage_id: 214,
		part_id: 109,
		quantity: 145,
	},
	{
		garage_id: 214,
		part_id: 110,
		quantity: 191,
	},
	{
		garage_id: 214,
		part_id: 111,
		quantity: 451,
	},
	{
		garage_id: 214,
		part_id: 112,
		quantity: 354,
	},
	{
		garage_id: 214,
		part_id: 113,
		quantity: 92,
	},
	{
		garage_id: 214,
		part_id: 114,
		quantity: 313,
	},
	{
		garage_id: 214,
		part_id: 115,
		quantity: 203,
	},
	{
		garage_id: 214,
		part_id: 116,
		quantity: 170,
	},
	{
		garage_id: 214,
		part_id: 117,
		quantity: 137,
	},
	{
		garage_id: 214,
		part_id: 118,
		quantity: 132,
	},
	{
		garage_id: 214,
		part_id: 119,
		quantity: 417,
	},
	{
		garage_id: 214,
		part_id: 120,
		quantity: 156,
	},
	{
		garage_id: 214,
		part_id: 121,
		quantity: 408,
	},
	{
		garage_id: 214,
		part_id: 122,
		quantity: 75,
	},
	{
		garage_id: 214,
		part_id: 123,
		quantity: 8,
	},
	{
		garage_id: 214,
		part_id: 124,
		quantity: 474,
	},
	{
		garage_id: 214,
		part_id: 125,
		quantity: 424,
	},
	{
		garage_id: 214,
		part_id: 126,
		quantity: 171,
	},
	{
		garage_id: 214,
		part_id: 127,
		quantity: 205,
	},
	{
		garage_id: 214,
		part_id: 128,
		quantity: 255,
	},
	{
		garage_id: 214,
		part_id: 129,
		quantity: 500,
	},
	{
		garage_id: 214,
		part_id: 130,
		quantity: 19,
	},
	{
		garage_id: 214,
		part_id: 131,
		quantity: 490,
	},
	{
		garage_id: 214,
		part_id: 132,
		quantity: 401,
	},
	{
		garage_id: 214,
		part_id: 133,
		quantity: 212,
	},
	{
		garage_id: 214,
		part_id: 134,
		quantity: 353,
	},
	{
		garage_id: 214,
		part_id: 135,
		quantity: 78,
	},
	{
		garage_id: 214,
		part_id: 136,
		quantity: 158,
	},
	{
		garage_id: 214,
		part_id: 137,
		quantity: 349,
	},
	{
		garage_id: 214,
		part_id: 138,
		quantity: 215,
	},
	{
		garage_id: 214,
		part_id: 139,
		quantity: 396,
	},
	{
		garage_id: 214,
		part_id: 140,
		quantity: 165,
	},
	{
		garage_id: 214,
		part_id: 141,
		quantity: 51,
	},
	{
		garage_id: 214,
		part_id: 142,
		quantity: 139,
	},
	{
		garage_id: 214,
		part_id: 143,
		quantity: 217,
	},
	{
		garage_id: 214,
		part_id: 144,
		quantity: 249,
	},
	{
		garage_id: 214,
		part_id: 145,
		quantity: 382,
	},
	{
		garage_id: 214,
		part_id: 146,
		quantity: 105,
	},
	{
		garage_id: 214,
		part_id: 147,
		quantity: 450,
	},
	{
		garage_id: 214,
		part_id: 148,
		quantity: 283,
	},
	{
		garage_id: 214,
		part_id: 149,
		quantity: 418,
	},
	{
		garage_id: 214,
		part_id: 150,
		quantity: 350,
	},
	{
		garage_id: 214,
		part_id: 151,
		quantity: 278,
	},
	{
		garage_id: 214,
		part_id: 152,
		quantity: 173,
	},
	{
		garage_id: 214,
		part_id: 153,
		quantity: 360,
	},
	{
		garage_id: 214,
		part_id: 154,
		quantity: 484,
	},
	{
		garage_id: 214,
		part_id: 155,
		quantity: 212,
	},
	{
		garage_id: 214,
		part_id: 156,
		quantity: 279,
	},
	{
		garage_id: 214,
		part_id: 157,
		quantity: 442,
	},
	{
		garage_id: 214,
		part_id: 158,
		quantity: 202,
	},
	{
		garage_id: 214,
		part_id: 159,
		quantity: 224,
	},
	{
		garage_id: 214,
		part_id: 160,
		quantity: 307,
	},
	{
		garage_id: 214,
		part_id: 161,
		quantity: 368,
	},
	{
		garage_id: 214,
		part_id: 162,
		quantity: 326,
	},
	{
		garage_id: 214,
		part_id: 163,
		quantity: 367,
	},
	{
		garage_id: 214,
		part_id: 164,
		quantity: 35,
	},
	{
		garage_id: 214,
		part_id: 165,
		quantity: 131,
	},
	{
		garage_id: 214,
		part_id: 166,
		quantity: 283,
	},
	{
		garage_id: 214,
		part_id: 167,
		quantity: 197,
	},
	{
		garage_id: 214,
		part_id: 168,
		quantity: 116,
	},
	{
		garage_id: 214,
		part_id: 169,
		quantity: 472,
	},
	{
		garage_id: 214,
		part_id: 170,
		quantity: 186,
	},
	{
		garage_id: 214,
		part_id: 171,
		quantity: 209,
	},
	{
		garage_id: 214,
		part_id: 172,
		quantity: 362,
	},
	{
		garage_id: 214,
		part_id: 173,
		quantity: 86,
	},
	{
		garage_id: 214,
		part_id: 174,
		quantity: 448,
	},
	{
		garage_id: 214,
		part_id: 175,
		quantity: 116,
	},
	{
		garage_id: 214,
		part_id: 176,
		quantity: 461,
	},
	{
		garage_id: 214,
		part_id: 177,
		quantity: 403,
	},
	{
		garage_id: 214,
		part_id: 178,
		quantity: 19,
	},
	{
		garage_id: 214,
		part_id: 179,
		quantity: 406,
	},
	{
		garage_id: 214,
		part_id: 180,
		quantity: 3,
	},
	{
		garage_id: 214,
		part_id: 181,
		quantity: 133,
	},
	{
		garage_id: 214,
		part_id: 182,
		quantity: 438,
	},
	{
		garage_id: 214,
		part_id: 183,
		quantity: 90,
	},
	{
		garage_id: 214,
		part_id: 184,
		quantity: 458,
	},
	{
		garage_id: 214,
		part_id: 185,
		quantity: 117,
	},
	{
		garage_id: 214,
		part_id: 186,
		quantity: 169,
	},
	{
		garage_id: 214,
		part_id: 187,
		quantity: 58,
	},
	{
		garage_id: 214,
		part_id: 188,
		quantity: 341,
	},
	{
		garage_id: 214,
		part_id: 189,
		quantity: 79,
	},
	{
		garage_id: 214,
		part_id: 190,
		quantity: 395,
	},
	{
		garage_id: 214,
		part_id: 191,
		quantity: 23,
	},
	{
		garage_id: 214,
		part_id: 192,
		quantity: 207,
	},
	{
		garage_id: 214,
		part_id: 193,
		quantity: 331,
	},
	{
		garage_id: 214,
		part_id: 194,
		quantity: 89,
	},
	{
		garage_id: 214,
		part_id: 195,
		quantity: 233,
	},
	{
		garage_id: 214,
		part_id: 196,
		quantity: 157,
	},
	{
		garage_id: 214,
		part_id: 197,
		quantity: 288,
	},
	{
		garage_id: 214,
		part_id: 198,
		quantity: 190,
	},
	{
		garage_id: 214,
		part_id: 199,
		quantity: 229,
	},
	{
		garage_id: 214,
		part_id: 200,
		quantity: 270,
	},
	{
		garage_id: 214,
		part_id: 201,
		quantity: 83,
	},
	{
		garage_id: 214,
		part_id: 202,
		quantity: 4,
	},
	{
		garage_id: 214,
		part_id: 203,
		quantity: 86,
	},
	{
		garage_id: 214,
		part_id: 204,
		quantity: 233,
	},
	{
		garage_id: 214,
		part_id: 205,
		quantity: 396,
	},
	{
		garage_id: 214,
		part_id: 206,
		quantity: 317,
	},
	{
		garage_id: 214,
		part_id: 207,
		quantity: 464,
	},
	{
		garage_id: 214,
		part_id: 208,
		quantity: 412,
	},
	{
		garage_id: 214,
		part_id: 209,
		quantity: 47,
	},
	{
		garage_id: 214,
		part_id: 210,
		quantity: 46,
	},
	{
		garage_id: 214,
		part_id: 211,
		quantity: 127,
	},
	{
		garage_id: 214,
		part_id: 212,
		quantity: 134,
	},
	{
		garage_id: 214,
		part_id: 213,
		quantity: 92,
	},
	{
		garage_id: 214,
		part_id: 214,
		quantity: 217,
	},
	{
		garage_id: 214,
		part_id: 215,
		quantity: 409,
	},
	{
		garage_id: 214,
		part_id: 216,
		quantity: 116,
	},
	{
		garage_id: 214,
		part_id: 217,
		quantity: 38,
	},
	{
		garage_id: 214,
		part_id: 218,
		quantity: 460,
	},
	{
		garage_id: 214,
		part_id: 219,
		quantity: 105,
	},
	{
		garage_id: 214,
		part_id: 220,
		quantity: 482,
	},
	{
		garage_id: 214,
		part_id: 221,
		quantity: 404,
	},
	{
		garage_id: 214,
		part_id: 222,
		quantity: 212,
	},
	{
		garage_id: 214,
		part_id: 223,
		quantity: 79,
	},
	{
		garage_id: 214,
		part_id: 224,
		quantity: 30,
	},
	{
		garage_id: 214,
		part_id: 225,
		quantity: 256,
	},
	{
		garage_id: 214,
		part_id: 226,
		quantity: 85,
	},
	{
		garage_id: 214,
		part_id: 227,
		quantity: 265,
	},
	{
		garage_id: 214,
		part_id: 228,
		quantity: 377,
	},
	{
		garage_id: 214,
		part_id: 229,
		quantity: 213,
	},
	{
		garage_id: 214,
		part_id: 230,
		quantity: 6,
	},
	{
		garage_id: 214,
		part_id: 231,
		quantity: 173,
	},
	{
		garage_id: 214,
		part_id: 232,
		quantity: 132,
	},
	{
		garage_id: 214,
		part_id: 233,
		quantity: 320,
	},
	{
		garage_id: 214,
		part_id: 234,
		quantity: 351,
	},
	{
		garage_id: 214,
		part_id: 235,
		quantity: 296,
	},
	{
		garage_id: 214,
		part_id: 236,
		quantity: 238,
	},
	{
		garage_id: 214,
		part_id: 237,
		quantity: 376,
	},
	{
		garage_id: 214,
		part_id: 238,
		quantity: 65,
	},
	{
		garage_id: 214,
		part_id: 239,
		quantity: 81,
	},
	{
		garage_id: 214,
		part_id: 240,
		quantity: 77,
	},
	{
		garage_id: 214,
		part_id: 241,
		quantity: 412,
	},
	{
		garage_id: 214,
		part_id: 242,
		quantity: 233,
	},
	{
		garage_id: 214,
		part_id: 243,
		quantity: 443,
	},
	{
		garage_id: 214,
		part_id: 244,
		quantity: 237,
	},
	{
		garage_id: 214,
		part_id: 245,
		quantity: 255,
	},
	{
		garage_id: 214,
		part_id: 246,
		quantity: 80,
	},
	{
		garage_id: 214,
		part_id: 247,
		quantity: 380,
	},
	{
		garage_id: 214,
		part_id: 248,
		quantity: 192,
	},
	{
		garage_id: 214,
		part_id: 249,
		quantity: 111,
	},
	{
		garage_id: 214,
		part_id: 250,
		quantity: 148,
	},
	{
		garage_id: 214,
		part_id: 251,
		quantity: 277,
	},
	{
		garage_id: 214,
		part_id: 252,
		quantity: 90,
	},
	{
		garage_id: 214,
		part_id: 253,
		quantity: 406,
	},
	{
		garage_id: 214,
		part_id: 254,
		quantity: 425,
	},
	{
		garage_id: 214,
		part_id: 255,
		quantity: 246,
	},
	{
		garage_id: 214,
		part_id: 256,
		quantity: 248,
	},
	{
		garage_id: 214,
		part_id: 257,
		quantity: 413,
	},
	{
		garage_id: 214,
		part_id: 258,
		quantity: 476,
	},
	{
		garage_id: 214,
		part_id: 259,
		quantity: 403,
	},
	{
		garage_id: 214,
		part_id: 260,
		quantity: 192,
	},
	{
		garage_id: 214,
		part_id: 261,
		quantity: 453,
	},
	{
		garage_id: 214,
		part_id: 262,
		quantity: 157,
	},
	{
		garage_id: 214,
		part_id: 263,
		quantity: 373,
	},
	{
		garage_id: 214,
		part_id: 264,
		quantity: 409,
	},
	{
		garage_id: 214,
		part_id: 265,
		quantity: 497,
	},
	{
		garage_id: 214,
		part_id: 266,
		quantity: 10,
	},
	{
		garage_id: 214,
		part_id: 267,
		quantity: 139,
	},
	{
		garage_id: 214,
		part_id: 268,
		quantity: 324,
	},
	{
		garage_id: 214,
		part_id: 269,
		quantity: 318,
	},
	{
		garage_id: 214,
		part_id: 270,
		quantity: 220,
	},
	{
		garage_id: 214,
		part_id: 271,
		quantity: 322,
	},
	{
		garage_id: 214,
		part_id: 272,
		quantity: 291,
	},
	{
		garage_id: 214,
		part_id: 273,
		quantity: 417,
	},
	{
		garage_id: 214,
		part_id: 274,
		quantity: 377,
	},
	{
		garage_id: 214,
		part_id: 275,
		quantity: 492,
	},
	{
		garage_id: 214,
		part_id: 276,
		quantity: 188,
	},
	{
		garage_id: 214,
		part_id: 277,
		quantity: 360,
	},
	{
		garage_id: 214,
		part_id: 278,
		quantity: 314,
	},
	{
		garage_id: 214,
		part_id: 279,
		quantity: 188,
	},
	{
		garage_id: 214,
		part_id: 280,
		quantity: 234,
	},
	{
		garage_id: 214,
		part_id: 281,
		quantity: 52,
	},
	{
		garage_id: 214,
		part_id: 282,
		quantity: 148,
	},
	{
		garage_id: 214,
		part_id: 283,
		quantity: 203,
	},
	{
		garage_id: 214,
		part_id: 284,
		quantity: 422,
	},
	{
		garage_id: 214,
		part_id: 285,
		quantity: 109,
	},
	{
		garage_id: 214,
		part_id: 286,
		quantity: 263,
	},
	{
		garage_id: 214,
		part_id: 287,
		quantity: 135,
	},
	{
		garage_id: 214,
		part_id: 288,
		quantity: 345,
	},
	{
		garage_id: 214,
		part_id: 289,
		quantity: 341,
	},
	{
		garage_id: 214,
		part_id: 290,
		quantity: 344,
	},
	{
		garage_id: 214,
		part_id: 291,
		quantity: 282,
	},
	{
		garage_id: 214,
		part_id: 292,
		quantity: 111,
	},
	{
		garage_id: 214,
		part_id: 293,
		quantity: 438,
	},
	{
		garage_id: 214,
		part_id: 294,
		quantity: 449,
	},
	{
		garage_id: 214,
		part_id: 295,
		quantity: 465,
	},
	{
		garage_id: 214,
		part_id: 296,
		quantity: 5,
	},
	{
		garage_id: 214,
		part_id: 297,
		quantity: 319,
	},
	{
		garage_id: 214,
		part_id: 298,
		quantity: 230,
	},
	{
		garage_id: 214,
		part_id: 299,
		quantity: 180,
	},
	{
		garage_id: 214,
		part_id: 300,
		quantity: 478,
	},
	{
		garage_id: 214,
		part_id: 301,
		quantity: 228,
	},
	{
		garage_id: 214,
		part_id: 302,
		quantity: 489,
	},
	{
		garage_id: 214,
		part_id: 303,
		quantity: 361,
	},
	{
		garage_id: 214,
		part_id: 304,
		quantity: 266,
	},
	{
		garage_id: 214,
		part_id: 305,
		quantity: 3,
	},
	{
		garage_id: 214,
		part_id: 306,
		quantity: 212,
	},
	{
		garage_id: 214,
		part_id: 307,
		quantity: 104,
	},
	{
		garage_id: 214,
		part_id: 308,
		quantity: 469,
	},
	{
		garage_id: 214,
		part_id: 309,
		quantity: 40,
	},
	{
		garage_id: 214,
		part_id: 310,
		quantity: 244,
	},
	{
		garage_id: 214,
		part_id: 311,
		quantity: 281,
	},
	{
		garage_id: 214,
		part_id: 312,
		quantity: 310,
	},
	{
		garage_id: 214,
		part_id: 313,
		quantity: 246,
	},
	{
		garage_id: 214,
		part_id: 314,
		quantity: 270,
	},
	{
		garage_id: 214,
		part_id: 315,
		quantity: 112,
	},
	{
		garage_id: 214,
		part_id: 316,
		quantity: 353,
	},
	{
		garage_id: 214,
		part_id: 317,
		quantity: 471,
	},
	{
		garage_id: 214,
		part_id: 318,
		quantity: 169,
	},
	{
		garage_id: 214,
		part_id: 319,
		quantity: 220,
	},
	{
		garage_id: 214,
		part_id: 320,
		quantity: 102,
	},
	{
		garage_id: 214,
		part_id: 321,
		quantity: 294,
	},
	{
		garage_id: 214,
		part_id: 322,
		quantity: 84,
	},
	{
		garage_id: 214,
		part_id: 323,
		quantity: 150,
	},
	{
		garage_id: 214,
		part_id: 324,
		quantity: 71,
	},
	{
		garage_id: 214,
		part_id: 325,
		quantity: 299,
	},
	{
		garage_id: 214,
		part_id: 326,
		quantity: 462,
	},
	{
		garage_id: 214,
		part_id: 327,
		quantity: 329,
	},
	{
		garage_id: 214,
		part_id: 328,
		quantity: 33,
	},
	{
		garage_id: 214,
		part_id: 329,
		quantity: 363,
	},
	{
		garage_id: 214,
		part_id: 330,
		quantity: 113,
	},
	{
		garage_id: 214,
		part_id: 331,
		quantity: 495,
	},
	{
		garage_id: 214,
		part_id: 332,
		quantity: 264,
	},
	{
		garage_id: 214,
		part_id: 333,
		quantity: 254,
	},
	{
		garage_id: 214,
		part_id: 334,
		quantity: 340,
	},
	{
		garage_id: 214,
		part_id: 335,
		quantity: 499,
	},
	{
		garage_id: 214,
		part_id: 336,
		quantity: 152,
	},
	{
		garage_id: 214,
		part_id: 337,
		quantity: 48,
	},
	{
		garage_id: 214,
		part_id: 338,
		quantity: 51,
	},
	{
		garage_id: 214,
		part_id: 339,
		quantity: 371,
	},
	{
		garage_id: 214,
		part_id: 340,
		quantity: 203,
	},
	{
		garage_id: 214,
		part_id: 341,
		quantity: 184,
	},
	{
		garage_id: 214,
		part_id: 342,
		quantity: 201,
	},
	{
		garage_id: 214,
		part_id: 343,
		quantity: 11,
	},
	{
		garage_id: 214,
		part_id: 344,
		quantity: 261,
	},
	{
		garage_id: 214,
		part_id: 345,
		quantity: 314,
	},
	{
		garage_id: 214,
		part_id: 346,
		quantity: 22,
	},
	{
		garage_id: 214,
		part_id: 347,
		quantity: 402,
	},
	{
		garage_id: 214,
		part_id: 348,
		quantity: 402,
	},
	{
		garage_id: 214,
		part_id: 349,
		quantity: 175,
	},
	{
		garage_id: 214,
		part_id: 350,
		quantity: 397,
	},
	{
		garage_id: 214,
		part_id: 351,
		quantity: 379,
	},
	{
		garage_id: 214,
		part_id: 352,
		quantity: 62,
	},
	{
		garage_id: 214,
		part_id: 353,
		quantity: 6,
	},
	{
		garage_id: 214,
		part_id: 354,
		quantity: 153,
	},
	{
		garage_id: 214,
		part_id: 355,
		quantity: 298,
	},
	{
		garage_id: 214,
		part_id: 356,
		quantity: 428,
	},
	{
		garage_id: 214,
		part_id: 357,
		quantity: 38,
	},
	{
		garage_id: 214,
		part_id: 358,
		quantity: 435,
	},
	{
		garage_id: 214,
		part_id: 359,
		quantity: 112,
	},
	{
		garage_id: 214,
		part_id: 360,
		quantity: 60,
	},
	{
		garage_id: 214,
		part_id: 361,
		quantity: 492,
	},
	{
		garage_id: 214,
		part_id: 362,
		quantity: 95,
	},
	{
		garage_id: 214,
		part_id: 363,
		quantity: 419,
	},
	{
		garage_id: 214,
		part_id: 364,
		quantity: 198,
	},
	{
		garage_id: 214,
		part_id: 365,
		quantity: 237,
	},
	{
		garage_id: 214,
		part_id: 366,
		quantity: 221,
	},
	{
		garage_id: 214,
		part_id: 367,
		quantity: 397,
	},
	{
		garage_id: 214,
		part_id: 368,
		quantity: 36,
	},
	{
		garage_id: 214,
		part_id: 369,
		quantity: 384,
	},
	{
		garage_id: 214,
		part_id: 370,
		quantity: 84,
	},
	{
		garage_id: 214,
		part_id: 371,
		quantity: 299,
	},
	{
		garage_id: 214,
		part_id: 372,
		quantity: 255,
	},
	{
		garage_id: 214,
		part_id: 373,
		quantity: 179,
	},
	{
		garage_id: 214,
		part_id: 374,
		quantity: 361,
	},
	{
		garage_id: 214,
		part_id: 375,
		quantity: 138,
	},
	{
		garage_id: 214,
		part_id: 376,
		quantity: 127,
	},
	{
		garage_id: 214,
		part_id: 377,
		quantity: 209,
	},
	{
		garage_id: 214,
		part_id: 378,
		quantity: 67,
	},
	{
		garage_id: 214,
		part_id: 379,
		quantity: 453,
	},
	{
		garage_id: 214,
		part_id: 380,
		quantity: 3,
	},
	{
		garage_id: 214,
		part_id: 381,
		quantity: 115,
	},
	{
		garage_id: 214,
		part_id: 382,
		quantity: 459,
	},
	{
		garage_id: 214,
		part_id: 383,
		quantity: 205,
	},
	{
		garage_id: 214,
		part_id: 384,
		quantity: 367,
	},
	{
		garage_id: 214,
		part_id: 385,
		quantity: 413,
	},
	{
		garage_id: 214,
		part_id: 386,
		quantity: 106,
	},
	{
		garage_id: 214,
		part_id: 387,
		quantity: 182,
	},
	{
		garage_id: 214,
		part_id: 388,
		quantity: 407,
	},
	{
		garage_id: 214,
		part_id: 389,
		quantity: 218,
	},
	{
		garage_id: 214,
		part_id: 390,
		quantity: 169,
	},
	{
		garage_id: 214,
		part_id: 391,
		quantity: 386,
	},
	{
		garage_id: 214,
		part_id: 392,
		quantity: 135,
	},
	{
		garage_id: 214,
		part_id: 393,
		quantity: 363,
	},
	{
		garage_id: 214,
		part_id: 394,
		quantity: 457,
	},
	{
		garage_id: 214,
		part_id: 395,
		quantity: 75,
	},
	{
		garage_id: 214,
		part_id: 396,
		quantity: 68,
	},
	{
		garage_id: 214,
		part_id: 397,
		quantity: 371,
	},
	{
		garage_id: 214,
		part_id: 398,
		quantity: 142,
	},
	{
		garage_id: 214,
		part_id: 399,
		quantity: 491,
	},
	{
		garage_id: 215,
		part_id: 0,
		quantity: 288,
	},
	{
		garage_id: 215,
		part_id: 1,
		quantity: 452,
	},
	{
		garage_id: 215,
		part_id: 2,
		quantity: 16,
	},
	{
		garage_id: 215,
		part_id: 3,
		quantity: 163,
	},
	{
		garage_id: 215,
		part_id: 4,
		quantity: 7,
	},
	{
		garage_id: 215,
		part_id: 5,
		quantity: 490,
	},
	{
		garage_id: 215,
		part_id: 6,
		quantity: 298,
	},
	{
		garage_id: 215,
		part_id: 7,
		quantity: 209,
	},
	{
		garage_id: 215,
		part_id: 8,
		quantity: 339,
	},
	{
		garage_id: 215,
		part_id: 9,
		quantity: 368,
	},
	{
		garage_id: 215,
		part_id: 10,
		quantity: 440,
	},
	{
		garage_id: 215,
		part_id: 11,
		quantity: 429,
	},
	{
		garage_id: 215,
		part_id: 12,
		quantity: 31,
	},
	{
		garage_id: 215,
		part_id: 13,
		quantity: 424,
	},
	{
		garage_id: 215,
		part_id: 14,
		quantity: 24,
	},
	{
		garage_id: 215,
		part_id: 15,
		quantity: 435,
	},
	{
		garage_id: 215,
		part_id: 16,
		quantity: 72,
	},
	{
		garage_id: 215,
		part_id: 17,
		quantity: 202,
	},
	{
		garage_id: 215,
		part_id: 18,
		quantity: 322,
	},
	{
		garage_id: 215,
		part_id: 19,
		quantity: 183,
	},
	{
		garage_id: 215,
		part_id: 20,
		quantity: 51,
	},
	{
		garage_id: 215,
		part_id: 21,
		quantity: 74,
	},
	{
		garage_id: 215,
		part_id: 22,
		quantity: 51,
	},
	{
		garage_id: 215,
		part_id: 23,
		quantity: 84,
	},
	{
		garage_id: 215,
		part_id: 24,
		quantity: 500,
	},
	{
		garage_id: 215,
		part_id: 25,
		quantity: 405,
	},
	{
		garage_id: 215,
		part_id: 26,
		quantity: 455,
	},
	{
		garage_id: 215,
		part_id: 27,
		quantity: 106,
	},
	{
		garage_id: 215,
		part_id: 28,
		quantity: 20,
	},
	{
		garage_id: 215,
		part_id: 29,
		quantity: 279,
	},
	{
		garage_id: 215,
		part_id: 30,
		quantity: 423,
	},
	{
		garage_id: 215,
		part_id: 31,
		quantity: 21,
	},
	{
		garage_id: 215,
		part_id: 32,
		quantity: 372,
	},
	{
		garage_id: 215,
		part_id: 33,
		quantity: 398,
	},
	{
		garage_id: 215,
		part_id: 34,
		quantity: 343,
	},
	{
		garage_id: 215,
		part_id: 35,
		quantity: 343,
	},
	{
		garage_id: 215,
		part_id: 36,
		quantity: 46,
	},
	{
		garage_id: 215,
		part_id: 37,
		quantity: 310,
	},
	{
		garage_id: 215,
		part_id: 38,
		quantity: 213,
	},
	{
		garage_id: 215,
		part_id: 39,
		quantity: 290,
	},
	{
		garage_id: 215,
		part_id: 40,
		quantity: 474,
	},
	{
		garage_id: 215,
		part_id: 41,
		quantity: 352,
	},
	{
		garage_id: 215,
		part_id: 42,
		quantity: 476,
	},
	{
		garage_id: 215,
		part_id: 43,
		quantity: 256,
	},
	{
		garage_id: 215,
		part_id: 44,
		quantity: 364,
	},
	{
		garage_id: 215,
		part_id: 45,
		quantity: 277,
	},
	{
		garage_id: 215,
		part_id: 46,
		quantity: 44,
	},
	{
		garage_id: 215,
		part_id: 47,
		quantity: 102,
	},
	{
		garage_id: 215,
		part_id: 48,
		quantity: 447,
	},
	{
		garage_id: 215,
		part_id: 49,
		quantity: 365,
	},
	{
		garage_id: 215,
		part_id: 50,
		quantity: 484,
	},
	{
		garage_id: 215,
		part_id: 51,
		quantity: 476,
	},
	{
		garage_id: 215,
		part_id: 52,
		quantity: 320,
	},
	{
		garage_id: 215,
		part_id: 53,
		quantity: 220,
	},
	{
		garage_id: 215,
		part_id: 54,
		quantity: 451,
	},
	{
		garage_id: 215,
		part_id: 55,
		quantity: 195,
	},
	{
		garage_id: 215,
		part_id: 56,
		quantity: 469,
	},
	{
		garage_id: 215,
		part_id: 57,
		quantity: 161,
	},
	{
		garage_id: 215,
		part_id: 58,
		quantity: 322,
	},
	{
		garage_id: 215,
		part_id: 59,
		quantity: 74,
	},
	{
		garage_id: 215,
		part_id: 60,
		quantity: 386,
	},
	{
		garage_id: 215,
		part_id: 61,
		quantity: 485,
	},
	{
		garage_id: 215,
		part_id: 62,
		quantity: 78,
	},
	{
		garage_id: 215,
		part_id: 63,
		quantity: 282,
	},
	{
		garage_id: 215,
		part_id: 64,
		quantity: 117,
	},
	{
		garage_id: 215,
		part_id: 65,
		quantity: 386,
	},
	{
		garage_id: 215,
		part_id: 66,
		quantity: 375,
	},
	{
		garage_id: 215,
		part_id: 67,
		quantity: 334,
	},
	{
		garage_id: 215,
		part_id: 68,
		quantity: 373,
	},
	{
		garage_id: 215,
		part_id: 69,
		quantity: 234,
	},
	{
		garage_id: 215,
		part_id: 70,
		quantity: 395,
	},
	{
		garage_id: 215,
		part_id: 71,
		quantity: 456,
	},
	{
		garage_id: 215,
		part_id: 72,
		quantity: 292,
	},
	{
		garage_id: 215,
		part_id: 73,
		quantity: 206,
	},
	{
		garage_id: 215,
		part_id: 74,
		quantity: 288,
	},
	{
		garage_id: 215,
		part_id: 75,
		quantity: 329,
	},
	{
		garage_id: 215,
		part_id: 76,
		quantity: 80,
	},
	{
		garage_id: 215,
		part_id: 77,
		quantity: 337,
	},
	{
		garage_id: 215,
		part_id: 78,
		quantity: 359,
	},
	{
		garage_id: 215,
		part_id: 79,
		quantity: 323,
	},
	{
		garage_id: 215,
		part_id: 80,
		quantity: 340,
	},
	{
		garage_id: 215,
		part_id: 81,
		quantity: 431,
	},
	{
		garage_id: 215,
		part_id: 82,
		quantity: 247,
	},
	{
		garage_id: 215,
		part_id: 83,
		quantity: 13,
	},
	{
		garage_id: 215,
		part_id: 84,
		quantity: 71,
	},
	{
		garage_id: 215,
		part_id: 85,
		quantity: 377,
	},
	{
		garage_id: 215,
		part_id: 86,
		quantity: 301,
	},
	{
		garage_id: 215,
		part_id: 87,
		quantity: 225,
	},
	{
		garage_id: 215,
		part_id: 88,
		quantity: 52,
	},
	{
		garage_id: 215,
		part_id: 89,
		quantity: 41,
	},
	{
		garage_id: 215,
		part_id: 90,
		quantity: 215,
	},
	{
		garage_id: 215,
		part_id: 91,
		quantity: 448,
	},
	{
		garage_id: 215,
		part_id: 92,
		quantity: 276,
	},
	{
		garage_id: 215,
		part_id: 93,
		quantity: 426,
	},
	{
		garage_id: 215,
		part_id: 94,
		quantity: 360,
	},
	{
		garage_id: 215,
		part_id: 95,
		quantity: 353,
	},
	{
		garage_id: 215,
		part_id: 96,
		quantity: 245,
	},
	{
		garage_id: 215,
		part_id: 97,
		quantity: 66,
	},
	{
		garage_id: 215,
		part_id: 98,
		quantity: 309,
	},
	{
		garage_id: 215,
		part_id: 99,
		quantity: 453,
	},
	{
		garage_id: 215,
		part_id: 100,
		quantity: 339,
	},
	{
		garage_id: 215,
		part_id: 101,
		quantity: 207,
	},
	{
		garage_id: 215,
		part_id: 102,
		quantity: 411,
	},
	{
		garage_id: 215,
		part_id: 103,
		quantity: 28,
	},
	{
		garage_id: 215,
		part_id: 104,
		quantity: 459,
	},
	{
		garage_id: 215,
		part_id: 105,
		quantity: 371,
	},
	{
		garage_id: 215,
		part_id: 106,
		quantity: 109,
	},
	{
		garage_id: 215,
		part_id: 107,
		quantity: 441,
	},
	{
		garage_id: 215,
		part_id: 108,
		quantity: 115,
	},
	{
		garage_id: 215,
		part_id: 109,
		quantity: 74,
	},
	{
		garage_id: 215,
		part_id: 110,
		quantity: 280,
	},
	{
		garage_id: 215,
		part_id: 111,
		quantity: 459,
	},
	{
		garage_id: 215,
		part_id: 112,
		quantity: 236,
	},
	{
		garage_id: 215,
		part_id: 113,
		quantity: 148,
	},
	{
		garage_id: 215,
		part_id: 114,
		quantity: 208,
	},
	{
		garage_id: 215,
		part_id: 115,
		quantity: 71,
	},
	{
		garage_id: 215,
		part_id: 116,
		quantity: 438,
	},
	{
		garage_id: 215,
		part_id: 117,
		quantity: 63,
	},
	{
		garage_id: 215,
		part_id: 118,
		quantity: 499,
	},
	{
		garage_id: 215,
		part_id: 119,
		quantity: 367,
	},
	{
		garage_id: 215,
		part_id: 120,
		quantity: 240,
	},
	{
		garage_id: 215,
		part_id: 121,
		quantity: 344,
	},
	{
		garage_id: 215,
		part_id: 122,
		quantity: 458,
	},
	{
		garage_id: 215,
		part_id: 123,
		quantity: 451,
	},
	{
		garage_id: 215,
		part_id: 124,
		quantity: 85,
	},
	{
		garage_id: 215,
		part_id: 125,
		quantity: 227,
	},
	{
		garage_id: 215,
		part_id: 126,
		quantity: 422,
	},
	{
		garage_id: 215,
		part_id: 127,
		quantity: 35,
	},
	{
		garage_id: 215,
		part_id: 128,
		quantity: 339,
	},
	{
		garage_id: 215,
		part_id: 129,
		quantity: 144,
	},
	{
		garage_id: 215,
		part_id: 130,
		quantity: 497,
	},
	{
		garage_id: 215,
		part_id: 131,
		quantity: 4,
	},
	{
		garage_id: 215,
		part_id: 132,
		quantity: 459,
	},
	{
		garage_id: 215,
		part_id: 133,
		quantity: 40,
	},
	{
		garage_id: 215,
		part_id: 134,
		quantity: 198,
	},
	{
		garage_id: 215,
		part_id: 135,
		quantity: 265,
	},
	{
		garage_id: 215,
		part_id: 136,
		quantity: 27,
	},
	{
		garage_id: 215,
		part_id: 137,
		quantity: 52,
	},
	{
		garage_id: 215,
		part_id: 138,
		quantity: 337,
	},
	{
		garage_id: 215,
		part_id: 139,
		quantity: 402,
	},
	{
		garage_id: 215,
		part_id: 140,
		quantity: 101,
	},
	{
		garage_id: 215,
		part_id: 141,
		quantity: 280,
	},
	{
		garage_id: 215,
		part_id: 142,
		quantity: 8,
	},
	{
		garage_id: 215,
		part_id: 143,
		quantity: 413,
	},
	{
		garage_id: 215,
		part_id: 144,
		quantity: 487,
	},
	{
		garage_id: 215,
		part_id: 145,
		quantity: 379,
	},
	{
		garage_id: 215,
		part_id: 146,
		quantity: 347,
	},
	{
		garage_id: 215,
		part_id: 147,
		quantity: 226,
	},
	{
		garage_id: 215,
		part_id: 148,
		quantity: 201,
	},
	{
		garage_id: 215,
		part_id: 149,
		quantity: 495,
	},
	{
		garage_id: 215,
		part_id: 150,
		quantity: 246,
	},
	{
		garage_id: 215,
		part_id: 151,
		quantity: 360,
	},
	{
		garage_id: 215,
		part_id: 152,
		quantity: 442,
	},
	{
		garage_id: 215,
		part_id: 153,
		quantity: 447,
	},
	{
		garage_id: 215,
		part_id: 154,
		quantity: 20,
	},
	{
		garage_id: 215,
		part_id: 155,
		quantity: 57,
	},
	{
		garage_id: 215,
		part_id: 156,
		quantity: 216,
	},
	{
		garage_id: 215,
		part_id: 157,
		quantity: 248,
	},
	{
		garage_id: 215,
		part_id: 158,
		quantity: 63,
	},
	{
		garage_id: 215,
		part_id: 159,
		quantity: 13,
	},
	{
		garage_id: 215,
		part_id: 160,
		quantity: 24,
	},
	{
		garage_id: 215,
		part_id: 161,
		quantity: 378,
	},
	{
		garage_id: 215,
		part_id: 162,
		quantity: 418,
	},
	{
		garage_id: 215,
		part_id: 163,
		quantity: 244,
	},
	{
		garage_id: 215,
		part_id: 164,
		quantity: 24,
	},
	{
		garage_id: 215,
		part_id: 165,
		quantity: 337,
	},
	{
		garage_id: 215,
		part_id: 166,
		quantity: 403,
	},
	{
		garage_id: 215,
		part_id: 167,
		quantity: 439,
	},
	{
		garage_id: 215,
		part_id: 168,
		quantity: 304,
	},
	{
		garage_id: 215,
		part_id: 169,
		quantity: 469,
	},
	{
		garage_id: 215,
		part_id: 170,
		quantity: 187,
	},
	{
		garage_id: 215,
		part_id: 171,
		quantity: 158,
	},
	{
		garage_id: 215,
		part_id: 172,
		quantity: 102,
	},
	{
		garage_id: 215,
		part_id: 173,
		quantity: 264,
	},
	{
		garage_id: 215,
		part_id: 174,
		quantity: 403,
	},
	{
		garage_id: 215,
		part_id: 175,
		quantity: 363,
	},
	{
		garage_id: 215,
		part_id: 176,
		quantity: 82,
	},
	{
		garage_id: 215,
		part_id: 177,
		quantity: 272,
	},
	{
		garage_id: 215,
		part_id: 178,
		quantity: 451,
	},
	{
		garage_id: 215,
		part_id: 179,
		quantity: 185,
	},
	{
		garage_id: 215,
		part_id: 180,
		quantity: 299,
	},
	{
		garage_id: 215,
		part_id: 181,
		quantity: 433,
	},
	{
		garage_id: 215,
		part_id: 182,
		quantity: 148,
	},
	{
		garage_id: 215,
		part_id: 183,
		quantity: 434,
	},
	{
		garage_id: 215,
		part_id: 184,
		quantity: 434,
	},
	{
		garage_id: 215,
		part_id: 185,
		quantity: 433,
	},
	{
		garage_id: 215,
		part_id: 186,
		quantity: 49,
	},
	{
		garage_id: 215,
		part_id: 187,
		quantity: 455,
	},
	{
		garage_id: 215,
		part_id: 188,
		quantity: 94,
	},
	{
		garage_id: 215,
		part_id: 189,
		quantity: 363,
	},
	{
		garage_id: 215,
		part_id: 190,
		quantity: 91,
	},
	{
		garage_id: 215,
		part_id: 191,
		quantity: 380,
	},
	{
		garage_id: 215,
		part_id: 192,
		quantity: 234,
	},
	{
		garage_id: 215,
		part_id: 193,
		quantity: 18,
	},
	{
		garage_id: 215,
		part_id: 194,
		quantity: 472,
	},
	{
		garage_id: 215,
		part_id: 195,
		quantity: 418,
	},
	{
		garage_id: 215,
		part_id: 196,
		quantity: 293,
	},
	{
		garage_id: 215,
		part_id: 197,
		quantity: 179,
	},
	{
		garage_id: 215,
		part_id: 198,
		quantity: 446,
	},
	{
		garage_id: 215,
		part_id: 199,
		quantity: 390,
	},
	{
		garage_id: 215,
		part_id: 200,
		quantity: 353,
	},
	{
		garage_id: 215,
		part_id: 201,
		quantity: 368,
	},
	{
		garage_id: 215,
		part_id: 202,
		quantity: 232,
	},
	{
		garage_id: 215,
		part_id: 203,
		quantity: 420,
	},
	{
		garage_id: 215,
		part_id: 204,
		quantity: 275,
	},
	{
		garage_id: 215,
		part_id: 205,
		quantity: 224,
	},
	{
		garage_id: 215,
		part_id: 206,
		quantity: 413,
	},
	{
		garage_id: 215,
		part_id: 207,
		quantity: 472,
	},
	{
		garage_id: 215,
		part_id: 208,
		quantity: 99,
	},
	{
		garage_id: 215,
		part_id: 209,
		quantity: 130,
	},
	{
		garage_id: 215,
		part_id: 210,
		quantity: 200,
	},
	{
		garage_id: 215,
		part_id: 211,
		quantity: 324,
	},
	{
		garage_id: 215,
		part_id: 212,
		quantity: 186,
	},
	{
		garage_id: 215,
		part_id: 213,
		quantity: 180,
	},
	{
		garage_id: 215,
		part_id: 214,
		quantity: 160,
	},
	{
		garage_id: 215,
		part_id: 215,
		quantity: 147,
	},
	{
		garage_id: 215,
		part_id: 216,
		quantity: 397,
	},
	{
		garage_id: 215,
		part_id: 217,
		quantity: 113,
	},
	{
		garage_id: 215,
		part_id: 218,
		quantity: 358,
	},
	{
		garage_id: 215,
		part_id: 219,
		quantity: 143,
	},
	{
		garage_id: 215,
		part_id: 220,
		quantity: 418,
	},
	{
		garage_id: 215,
		part_id: 221,
		quantity: 45,
	},
	{
		garage_id: 215,
		part_id: 222,
		quantity: 183,
	},
	{
		garage_id: 215,
		part_id: 223,
		quantity: 59,
	},
	{
		garage_id: 215,
		part_id: 224,
		quantity: 187,
	},
	{
		garage_id: 215,
		part_id: 225,
		quantity: 480,
	},
	{
		garage_id: 215,
		part_id: 226,
		quantity: 373,
	},
	{
		garage_id: 215,
		part_id: 227,
		quantity: 254,
	},
	{
		garage_id: 215,
		part_id: 228,
		quantity: 435,
	},
	{
		garage_id: 215,
		part_id: 229,
		quantity: 294,
	},
	{
		garage_id: 215,
		part_id: 230,
		quantity: 128,
	},
	{
		garage_id: 215,
		part_id: 231,
		quantity: 99,
	},
	{
		garage_id: 215,
		part_id: 232,
		quantity: 258,
	},
	{
		garage_id: 215,
		part_id: 233,
		quantity: 297,
	},
	{
		garage_id: 215,
		part_id: 234,
		quantity: 122,
	},
	{
		garage_id: 215,
		part_id: 235,
		quantity: 272,
	},
	{
		garage_id: 215,
		part_id: 236,
		quantity: 359,
	},
	{
		garage_id: 215,
		part_id: 237,
		quantity: 211,
	},
	{
		garage_id: 215,
		part_id: 238,
		quantity: 147,
	},
	{
		garage_id: 215,
		part_id: 239,
		quantity: 199,
	},
	{
		garage_id: 215,
		part_id: 240,
		quantity: 164,
	},
	{
		garage_id: 215,
		part_id: 241,
		quantity: 382,
	},
	{
		garage_id: 215,
		part_id: 242,
		quantity: 65,
	},
	{
		garage_id: 215,
		part_id: 243,
		quantity: 447,
	},
	{
		garage_id: 215,
		part_id: 244,
		quantity: 144,
	},
	{
		garage_id: 215,
		part_id: 245,
		quantity: 275,
	},
	{
		garage_id: 215,
		part_id: 246,
		quantity: 232,
	},
	{
		garage_id: 215,
		part_id: 247,
		quantity: 27,
	},
	{
		garage_id: 215,
		part_id: 248,
		quantity: 97,
	},
	{
		garage_id: 215,
		part_id: 249,
		quantity: 393,
	},
	{
		garage_id: 215,
		part_id: 250,
		quantity: 316,
	},
	{
		garage_id: 215,
		part_id: 251,
		quantity: 164,
	},
	{
		garage_id: 215,
		part_id: 252,
		quantity: 397,
	},
	{
		garage_id: 215,
		part_id: 253,
		quantity: 135,
	},
	{
		garage_id: 215,
		part_id: 254,
		quantity: 399,
	},
	{
		garage_id: 215,
		part_id: 255,
		quantity: 336,
	},
	{
		garage_id: 215,
		part_id: 256,
		quantity: 95,
	},
	{
		garage_id: 215,
		part_id: 257,
		quantity: 421,
	},
	{
		garage_id: 215,
		part_id: 258,
		quantity: 499,
	},
	{
		garage_id: 215,
		part_id: 259,
		quantity: 113,
	},
	{
		garage_id: 215,
		part_id: 260,
		quantity: 281,
	},
	{
		garage_id: 215,
		part_id: 261,
		quantity: 430,
	},
	{
		garage_id: 215,
		part_id: 262,
		quantity: 77,
	},
	{
		garage_id: 215,
		part_id: 263,
		quantity: 87,
	},
	{
		garage_id: 215,
		part_id: 264,
		quantity: 46,
	},
	{
		garage_id: 215,
		part_id: 265,
		quantity: 255,
	},
	{
		garage_id: 215,
		part_id: 266,
		quantity: 67,
	},
	{
		garage_id: 215,
		part_id: 267,
		quantity: 35,
	},
	{
		garage_id: 215,
		part_id: 268,
		quantity: 384,
	},
	{
		garage_id: 215,
		part_id: 269,
		quantity: 272,
	},
	{
		garage_id: 215,
		part_id: 270,
		quantity: 103,
	},
	{
		garage_id: 215,
		part_id: 271,
		quantity: 481,
	},
	{
		garage_id: 215,
		part_id: 272,
		quantity: 385,
	},
	{
		garage_id: 215,
		part_id: 273,
		quantity: 307,
	},
	{
		garage_id: 215,
		part_id: 274,
		quantity: 193,
	},
	{
		garage_id: 215,
		part_id: 275,
		quantity: 367,
	},
	{
		garage_id: 215,
		part_id: 276,
		quantity: 5,
	},
	{
		garage_id: 215,
		part_id: 277,
		quantity: 307,
	},
	{
		garage_id: 215,
		part_id: 278,
		quantity: 274,
	},
	{
		garage_id: 215,
		part_id: 279,
		quantity: 239,
	},
	{
		garage_id: 215,
		part_id: 280,
		quantity: 368,
	},
	{
		garage_id: 215,
		part_id: 281,
		quantity: 260,
	},
	{
		garage_id: 215,
		part_id: 282,
		quantity: 105,
	},
	{
		garage_id: 215,
		part_id: 283,
		quantity: 206,
	},
	{
		garage_id: 215,
		part_id: 284,
		quantity: 282,
	},
	{
		garage_id: 215,
		part_id: 285,
		quantity: 101,
	},
	{
		garage_id: 215,
		part_id: 286,
		quantity: 110,
	},
	{
		garage_id: 215,
		part_id: 287,
		quantity: 157,
	},
	{
		garage_id: 215,
		part_id: 288,
		quantity: 271,
	},
	{
		garage_id: 215,
		part_id: 289,
		quantity: 351,
	},
	{
		garage_id: 215,
		part_id: 290,
		quantity: 168,
	},
	{
		garage_id: 215,
		part_id: 291,
		quantity: 20,
	},
	{
		garage_id: 215,
		part_id: 292,
		quantity: 30,
	},
	{
		garage_id: 215,
		part_id: 293,
		quantity: 26,
	},
	{
		garage_id: 215,
		part_id: 294,
		quantity: 138,
	},
	{
		garage_id: 215,
		part_id: 295,
		quantity: 261,
	},
	{
		garage_id: 215,
		part_id: 296,
		quantity: 453,
	},
	{
		garage_id: 215,
		part_id: 297,
		quantity: 108,
	},
	{
		garage_id: 215,
		part_id: 298,
		quantity: 326,
	},
	{
		garage_id: 215,
		part_id: 299,
		quantity: 175,
	},
	{
		garage_id: 215,
		part_id: 300,
		quantity: 12,
	},
	{
		garage_id: 215,
		part_id: 301,
		quantity: 169,
	},
	{
		garage_id: 215,
		part_id: 302,
		quantity: 189,
	},
	{
		garage_id: 215,
		part_id: 303,
		quantity: 374,
	},
	{
		garage_id: 215,
		part_id: 304,
		quantity: 468,
	},
	{
		garage_id: 215,
		part_id: 305,
		quantity: 288,
	},
	{
		garage_id: 215,
		part_id: 306,
		quantity: 122,
	},
	{
		garage_id: 215,
		part_id: 307,
		quantity: 480,
	},
	{
		garage_id: 215,
		part_id: 308,
		quantity: 211,
	},
	{
		garage_id: 215,
		part_id: 309,
		quantity: 134,
	},
	{
		garage_id: 215,
		part_id: 310,
		quantity: 366,
	},
	{
		garage_id: 215,
		part_id: 311,
		quantity: 387,
	},
	{
		garage_id: 215,
		part_id: 312,
		quantity: 390,
	},
	{
		garage_id: 215,
		part_id: 313,
		quantity: 166,
	},
	{
		garage_id: 215,
		part_id: 314,
		quantity: 236,
	},
	{
		garage_id: 215,
		part_id: 315,
		quantity: 460,
	},
	{
		garage_id: 215,
		part_id: 316,
		quantity: 452,
	},
	{
		garage_id: 215,
		part_id: 317,
		quantity: 25,
	},
	{
		garage_id: 215,
		part_id: 318,
		quantity: 486,
	},
	{
		garage_id: 215,
		part_id: 319,
		quantity: 65,
	},
	{
		garage_id: 215,
		part_id: 320,
		quantity: 314,
	},
	{
		garage_id: 215,
		part_id: 321,
		quantity: 447,
	},
	{
		garage_id: 215,
		part_id: 322,
		quantity: 63,
	},
	{
		garage_id: 215,
		part_id: 323,
		quantity: 397,
	},
	{
		garage_id: 215,
		part_id: 324,
		quantity: 24,
	},
	{
		garage_id: 215,
		part_id: 325,
		quantity: 289,
	},
	{
		garage_id: 215,
		part_id: 326,
		quantity: 165,
	},
	{
		garage_id: 215,
		part_id: 327,
		quantity: 275,
	},
	{
		garage_id: 215,
		part_id: 328,
		quantity: 421,
	},
	{
		garage_id: 215,
		part_id: 329,
		quantity: 82,
	},
	{
		garage_id: 215,
		part_id: 330,
		quantity: 19,
	},
	{
		garage_id: 215,
		part_id: 331,
		quantity: 420,
	},
	{
		garage_id: 215,
		part_id: 332,
		quantity: 451,
	},
	{
		garage_id: 215,
		part_id: 333,
		quantity: 481,
	},
	{
		garage_id: 215,
		part_id: 334,
		quantity: 471,
	},
	{
		garage_id: 215,
		part_id: 335,
		quantity: 125,
	},
	{
		garage_id: 215,
		part_id: 336,
		quantity: 440,
	},
	{
		garage_id: 215,
		part_id: 337,
		quantity: 21,
	},
	{
		garage_id: 215,
		part_id: 338,
		quantity: 208,
	},
	{
		garage_id: 215,
		part_id: 339,
		quantity: 322,
	},
	{
		garage_id: 215,
		part_id: 340,
		quantity: 67,
	},
	{
		garage_id: 215,
		part_id: 341,
		quantity: 360,
	},
	{
		garage_id: 215,
		part_id: 342,
		quantity: 7,
	},
	{
		garage_id: 215,
		part_id: 343,
		quantity: 21,
	},
	{
		garage_id: 215,
		part_id: 344,
		quantity: 328,
	},
	{
		garage_id: 215,
		part_id: 345,
		quantity: 76,
	},
	{
		garage_id: 215,
		part_id: 346,
		quantity: 174,
	},
	{
		garage_id: 215,
		part_id: 347,
		quantity: 389,
	},
	{
		garage_id: 215,
		part_id: 348,
		quantity: 243,
	},
	{
		garage_id: 215,
		part_id: 349,
		quantity: 115,
	},
	{
		garage_id: 215,
		part_id: 350,
		quantity: 81,
	},
	{
		garage_id: 215,
		part_id: 351,
		quantity: 292,
	},
	{
		garage_id: 215,
		part_id: 352,
		quantity: 60,
	},
	{
		garage_id: 215,
		part_id: 353,
		quantity: 84,
	},
	{
		garage_id: 215,
		part_id: 354,
		quantity: 164,
	},
	{
		garage_id: 215,
		part_id: 355,
		quantity: 411,
	},
	{
		garage_id: 215,
		part_id: 356,
		quantity: 487,
	},
	{
		garage_id: 215,
		part_id: 357,
		quantity: 219,
	},
	{
		garage_id: 215,
		part_id: 358,
		quantity: 78,
	},
	{
		garage_id: 215,
		part_id: 359,
		quantity: 455,
	},
	{
		garage_id: 215,
		part_id: 360,
		quantity: 293,
	},
	{
		garage_id: 215,
		part_id: 361,
		quantity: 264,
	},
	{
		garage_id: 215,
		part_id: 362,
		quantity: 336,
	},
	{
		garage_id: 215,
		part_id: 363,
		quantity: 203,
	},
	{
		garage_id: 215,
		part_id: 364,
		quantity: 18,
	},
	{
		garage_id: 215,
		part_id: 365,
		quantity: 160,
	},
	{
		garage_id: 215,
		part_id: 366,
		quantity: 266,
	},
	{
		garage_id: 215,
		part_id: 367,
		quantity: 212,
	},
	{
		garage_id: 215,
		part_id: 368,
		quantity: 29,
	},
	{
		garage_id: 215,
		part_id: 369,
		quantity: 127,
	},
	{
		garage_id: 215,
		part_id: 370,
		quantity: 82,
	},
	{
		garage_id: 215,
		part_id: 371,
		quantity: 281,
	},
	{
		garage_id: 215,
		part_id: 372,
		quantity: 408,
	},
	{
		garage_id: 215,
		part_id: 373,
		quantity: 35,
	},
	{
		garage_id: 215,
		part_id: 374,
		quantity: 229,
	},
	{
		garage_id: 215,
		part_id: 375,
		quantity: 5,
	},
	{
		garage_id: 215,
		part_id: 376,
		quantity: 309,
	},
	{
		garage_id: 215,
		part_id: 377,
		quantity: 411,
	},
	{
		garage_id: 215,
		part_id: 378,
		quantity: 137,
	},
	{
		garage_id: 215,
		part_id: 379,
		quantity: 79,
	},
	{
		garage_id: 215,
		part_id: 380,
		quantity: 435,
	},
	{
		garage_id: 215,
		part_id: 381,
		quantity: 351,
	},
	{
		garage_id: 215,
		part_id: 382,
		quantity: 290,
	},
	{
		garage_id: 215,
		part_id: 383,
		quantity: 303,
	},
	{
		garage_id: 215,
		part_id: 384,
		quantity: 53,
	},
	{
		garage_id: 215,
		part_id: 385,
		quantity: 203,
	},
	{
		garage_id: 215,
		part_id: 386,
		quantity: 362,
	},
	{
		garage_id: 215,
		part_id: 387,
		quantity: 307,
	},
	{
		garage_id: 215,
		part_id: 388,
		quantity: 131,
	},
	{
		garage_id: 215,
		part_id: 389,
		quantity: 454,
	},
	{
		garage_id: 215,
		part_id: 390,
		quantity: 139,
	},
	{
		garage_id: 215,
		part_id: 391,
		quantity: 288,
	},
	{
		garage_id: 215,
		part_id: 392,
		quantity: 390,
	},
	{
		garage_id: 215,
		part_id: 393,
		quantity: 290,
	},
	{
		garage_id: 215,
		part_id: 394,
		quantity: 381,
	},
	{
		garage_id: 215,
		part_id: 395,
		quantity: 180,
	},
	{
		garage_id: 215,
		part_id: 396,
		quantity: 45,
	},
	{
		garage_id: 215,
		part_id: 397,
		quantity: 210,
	},
	{
		garage_id: 215,
		part_id: 398,
		quantity: 135,
	},
	{
		garage_id: 215,
		part_id: 399,
		quantity: 299,
	},
	{
		garage_id: 216,
		part_id: 0,
		quantity: 302,
	},
	{
		garage_id: 216,
		part_id: 1,
		quantity: 16,
	},
	{
		garage_id: 216,
		part_id: 2,
		quantity: 441,
	},
	{
		garage_id: 216,
		part_id: 3,
		quantity: 103,
	},
	{
		garage_id: 216,
		part_id: 4,
		quantity: 190,
	},
	{
		garage_id: 216,
		part_id: 5,
		quantity: 244,
	},
	{
		garage_id: 216,
		part_id: 6,
		quantity: 346,
	},
	{
		garage_id: 216,
		part_id: 7,
		quantity: 93,
	},
	{
		garage_id: 216,
		part_id: 8,
		quantity: 287,
	},
	{
		garage_id: 216,
		part_id: 9,
		quantity: 278,
	},
	{
		garage_id: 216,
		part_id: 10,
		quantity: 140,
	},
	{
		garage_id: 216,
		part_id: 11,
		quantity: 157,
	},
	{
		garage_id: 216,
		part_id: 12,
		quantity: 9,
	},
	{
		garage_id: 216,
		part_id: 13,
		quantity: 393,
	},
	{
		garage_id: 216,
		part_id: 14,
		quantity: 340,
	},
	{
		garage_id: 216,
		part_id: 15,
		quantity: 488,
	},
	{
		garage_id: 216,
		part_id: 16,
		quantity: 208,
	},
	{
		garage_id: 216,
		part_id: 17,
		quantity: 444,
	},
	{
		garage_id: 216,
		part_id: 18,
		quantity: 38,
	},
	{
		garage_id: 216,
		part_id: 19,
		quantity: 308,
	},
	{
		garage_id: 216,
		part_id: 20,
		quantity: 34,
	},
	{
		garage_id: 216,
		part_id: 21,
		quantity: 230,
	},
	{
		garage_id: 216,
		part_id: 22,
		quantity: 423,
	},
	{
		garage_id: 216,
		part_id: 23,
		quantity: 278,
	},
	{
		garage_id: 216,
		part_id: 24,
		quantity: 259,
	},
	{
		garage_id: 216,
		part_id: 25,
		quantity: 439,
	},
	{
		garage_id: 216,
		part_id: 26,
		quantity: 100,
	},
	{
		garage_id: 216,
		part_id: 27,
		quantity: 458,
	},
	{
		garage_id: 216,
		part_id: 28,
		quantity: 254,
	},
	{
		garage_id: 216,
		part_id: 29,
		quantity: 143,
	},
	{
		garage_id: 216,
		part_id: 30,
		quantity: 300,
	},
	{
		garage_id: 216,
		part_id: 31,
		quantity: 341,
	},
	{
		garage_id: 216,
		part_id: 32,
		quantity: 243,
	},
	{
		garage_id: 216,
		part_id: 33,
		quantity: 341,
	},
	{
		garage_id: 216,
		part_id: 34,
		quantity: 412,
	},
	{
		garage_id: 216,
		part_id: 35,
		quantity: 180,
	},
	{
		garage_id: 216,
		part_id: 36,
		quantity: 169,
	},
	{
		garage_id: 216,
		part_id: 37,
		quantity: 443,
	},
	{
		garage_id: 216,
		part_id: 38,
		quantity: 4,
	},
	{
		garage_id: 216,
		part_id: 39,
		quantity: 195,
	},
	{
		garage_id: 216,
		part_id: 40,
		quantity: 136,
	},
	{
		garage_id: 216,
		part_id: 41,
		quantity: 329,
	},
	{
		garage_id: 216,
		part_id: 42,
		quantity: 194,
	},
	{
		garage_id: 216,
		part_id: 43,
		quantity: 131,
	},
	{
		garage_id: 216,
		part_id: 44,
		quantity: 442,
	},
	{
		garage_id: 216,
		part_id: 45,
		quantity: 383,
	},
	{
		garage_id: 216,
		part_id: 46,
		quantity: 449,
	},
	{
		garage_id: 216,
		part_id: 47,
		quantity: 272,
	},
	{
		garage_id: 216,
		part_id: 48,
		quantity: 203,
	},
	{
		garage_id: 216,
		part_id: 49,
		quantity: 404,
	},
	{
		garage_id: 216,
		part_id: 50,
		quantity: 170,
	},
	{
		garage_id: 216,
		part_id: 51,
		quantity: 242,
	},
	{
		garage_id: 216,
		part_id: 52,
		quantity: 267,
	},
	{
		garage_id: 216,
		part_id: 53,
		quantity: 122,
	},
	{
		garage_id: 216,
		part_id: 54,
		quantity: 128,
	},
	{
		garage_id: 216,
		part_id: 55,
		quantity: 202,
	},
	{
		garage_id: 216,
		part_id: 56,
		quantity: 456,
	},
	{
		garage_id: 216,
		part_id: 57,
		quantity: 178,
	},
	{
		garage_id: 216,
		part_id: 58,
		quantity: 380,
	},
	{
		garage_id: 216,
		part_id: 59,
		quantity: 161,
	},
	{
		garage_id: 216,
		part_id: 60,
		quantity: 64,
	},
	{
		garage_id: 216,
		part_id: 61,
		quantity: 263,
	},
	{
		garage_id: 216,
		part_id: 62,
		quantity: 246,
	},
	{
		garage_id: 216,
		part_id: 63,
		quantity: 47,
	},
	{
		garage_id: 216,
		part_id: 64,
		quantity: 413,
	},
	{
		garage_id: 216,
		part_id: 65,
		quantity: 31,
	},
	{
		garage_id: 216,
		part_id: 66,
		quantity: 146,
	},
	{
		garage_id: 216,
		part_id: 67,
		quantity: 104,
	},
	{
		garage_id: 216,
		part_id: 68,
		quantity: 135,
	},
	{
		garage_id: 216,
		part_id: 69,
		quantity: 469,
	},
	{
		garage_id: 216,
		part_id: 70,
		quantity: 354,
	},
	{
		garage_id: 216,
		part_id: 71,
		quantity: 185,
	},
	{
		garage_id: 216,
		part_id: 72,
		quantity: 377,
	},
	{
		garage_id: 216,
		part_id: 73,
		quantity: 435,
	},
	{
		garage_id: 216,
		part_id: 74,
		quantity: 354,
	},
	{
		garage_id: 216,
		part_id: 75,
		quantity: 24,
	},
	{
		garage_id: 216,
		part_id: 76,
		quantity: 478,
	},
	{
		garage_id: 216,
		part_id: 77,
		quantity: 330,
	},
	{
		garage_id: 216,
		part_id: 78,
		quantity: 11,
	},
	{
		garage_id: 216,
		part_id: 79,
		quantity: 78,
	},
	{
		garage_id: 216,
		part_id: 80,
		quantity: 18,
	},
	{
		garage_id: 216,
		part_id: 81,
		quantity: 154,
	},
	{
		garage_id: 216,
		part_id: 82,
		quantity: 167,
	},
	{
		garage_id: 216,
		part_id: 83,
		quantity: 306,
	},
	{
		garage_id: 216,
		part_id: 84,
		quantity: 192,
	},
	{
		garage_id: 216,
		part_id: 85,
		quantity: 54,
	},
	{
		garage_id: 216,
		part_id: 86,
		quantity: 166,
	},
	{
		garage_id: 216,
		part_id: 87,
		quantity: 34,
	},
	{
		garage_id: 216,
		part_id: 88,
		quantity: 436,
	},
	{
		garage_id: 216,
		part_id: 89,
		quantity: 461,
	},
	{
		garage_id: 216,
		part_id: 90,
		quantity: 238,
	},
	{
		garage_id: 216,
		part_id: 91,
		quantity: 326,
	},
	{
		garage_id: 216,
		part_id: 92,
		quantity: 218,
	},
	{
		garage_id: 216,
		part_id: 93,
		quantity: 20,
	},
	{
		garage_id: 216,
		part_id: 94,
		quantity: 82,
	},
	{
		garage_id: 216,
		part_id: 95,
		quantity: 445,
	},
	{
		garage_id: 216,
		part_id: 96,
		quantity: 286,
	},
	{
		garage_id: 216,
		part_id: 97,
		quantity: 292,
	},
	{
		garage_id: 216,
		part_id: 98,
		quantity: 221,
	},
	{
		garage_id: 216,
		part_id: 99,
		quantity: 76,
	},
	{
		garage_id: 216,
		part_id: 100,
		quantity: 495,
	},
	{
		garage_id: 216,
		part_id: 101,
		quantity: 141,
	},
	{
		garage_id: 216,
		part_id: 102,
		quantity: 402,
	},
	{
		garage_id: 216,
		part_id: 103,
		quantity: 418,
	},
	{
		garage_id: 216,
		part_id: 104,
		quantity: 113,
	},
	{
		garage_id: 216,
		part_id: 105,
		quantity: 165,
	},
	{
		garage_id: 216,
		part_id: 106,
		quantity: 49,
	},
	{
		garage_id: 216,
		part_id: 107,
		quantity: 252,
	},
	{
		garage_id: 216,
		part_id: 108,
		quantity: 73,
	},
	{
		garage_id: 216,
		part_id: 109,
		quantity: 315,
	},
	{
		garage_id: 216,
		part_id: 110,
		quantity: 147,
	},
	{
		garage_id: 216,
		part_id: 111,
		quantity: 223,
	},
	{
		garage_id: 216,
		part_id: 112,
		quantity: 225,
	},
	{
		garage_id: 216,
		part_id: 113,
		quantity: 88,
	},
	{
		garage_id: 216,
		part_id: 114,
		quantity: 76,
	},
	{
		garage_id: 216,
		part_id: 115,
		quantity: 439,
	},
	{
		garage_id: 216,
		part_id: 116,
		quantity: 36,
	},
	{
		garage_id: 216,
		part_id: 117,
		quantity: 189,
	},
	{
		garage_id: 216,
		part_id: 118,
		quantity: 457,
	},
	{
		garage_id: 216,
		part_id: 119,
		quantity: 334,
	},
	{
		garage_id: 216,
		part_id: 120,
		quantity: 467,
	},
	{
		garage_id: 216,
		part_id: 121,
		quantity: 201,
	},
	{
		garage_id: 216,
		part_id: 122,
		quantity: 463,
	},
	{
		garage_id: 216,
		part_id: 123,
		quantity: 484,
	},
	{
		garage_id: 216,
		part_id: 124,
		quantity: 28,
	},
	{
		garage_id: 216,
		part_id: 125,
		quantity: 425,
	},
	{
		garage_id: 216,
		part_id: 126,
		quantity: 208,
	},
	{
		garage_id: 216,
		part_id: 127,
		quantity: 477,
	},
	{
		garage_id: 216,
		part_id: 128,
		quantity: 437,
	},
	{
		garage_id: 216,
		part_id: 129,
		quantity: 416,
	},
	{
		garage_id: 216,
		part_id: 130,
		quantity: 184,
	},
	{
		garage_id: 216,
		part_id: 131,
		quantity: 449,
	},
	{
		garage_id: 216,
		part_id: 132,
		quantity: 488,
	},
	{
		garage_id: 216,
		part_id: 133,
		quantity: 160,
	},
	{
		garage_id: 216,
		part_id: 134,
		quantity: 173,
	},
	{
		garage_id: 216,
		part_id: 135,
		quantity: 441,
	},
	{
		garage_id: 216,
		part_id: 136,
		quantity: 361,
	},
	{
		garage_id: 216,
		part_id: 137,
		quantity: 177,
	},
	{
		garage_id: 216,
		part_id: 138,
		quantity: 500,
	},
	{
		garage_id: 216,
		part_id: 139,
		quantity: 99,
	},
	{
		garage_id: 216,
		part_id: 140,
		quantity: 306,
	},
	{
		garage_id: 216,
		part_id: 141,
		quantity: 16,
	},
	{
		garage_id: 216,
		part_id: 142,
		quantity: 468,
	},
	{
		garage_id: 216,
		part_id: 143,
		quantity: 210,
	},
	{
		garage_id: 216,
		part_id: 144,
		quantity: 369,
	},
	{
		garage_id: 216,
		part_id: 145,
		quantity: 138,
	},
	{
		garage_id: 216,
		part_id: 146,
		quantity: 263,
	},
	{
		garage_id: 216,
		part_id: 147,
		quantity: 40,
	},
	{
		garage_id: 216,
		part_id: 148,
		quantity: 179,
	},
	{
		garage_id: 216,
		part_id: 149,
		quantity: 292,
	},
	{
		garage_id: 216,
		part_id: 150,
		quantity: 325,
	},
	{
		garage_id: 216,
		part_id: 151,
		quantity: 376,
	},
	{
		garage_id: 216,
		part_id: 152,
		quantity: 210,
	},
	{
		garage_id: 216,
		part_id: 153,
		quantity: 488,
	},
	{
		garage_id: 216,
		part_id: 154,
		quantity: 216,
	},
	{
		garage_id: 216,
		part_id: 155,
		quantity: 164,
	},
	{
		garage_id: 216,
		part_id: 156,
		quantity: 140,
	},
	{
		garage_id: 216,
		part_id: 157,
		quantity: 212,
	},
	{
		garage_id: 216,
		part_id: 158,
		quantity: 110,
	},
	{
		garage_id: 216,
		part_id: 159,
		quantity: 256,
	},
	{
		garage_id: 216,
		part_id: 160,
		quantity: 343,
	},
	{
		garage_id: 216,
		part_id: 161,
		quantity: 315,
	},
	{
		garage_id: 216,
		part_id: 162,
		quantity: 217,
	},
	{
		garage_id: 216,
		part_id: 163,
		quantity: 224,
	},
	{
		garage_id: 216,
		part_id: 164,
		quantity: 260,
	},
	{
		garage_id: 216,
		part_id: 165,
		quantity: 337,
	},
	{
		garage_id: 216,
		part_id: 166,
		quantity: 408,
	},
	{
		garage_id: 216,
		part_id: 167,
		quantity: 119,
	},
	{
		garage_id: 216,
		part_id: 168,
		quantity: 86,
	},
	{
		garage_id: 216,
		part_id: 169,
		quantity: 406,
	},
	{
		garage_id: 216,
		part_id: 170,
		quantity: 122,
	},
	{
		garage_id: 216,
		part_id: 171,
		quantity: 264,
	},
	{
		garage_id: 216,
		part_id: 172,
		quantity: 129,
	},
	{
		garage_id: 216,
		part_id: 173,
		quantity: 419,
	},
	{
		garage_id: 216,
		part_id: 174,
		quantity: 433,
	},
	{
		garage_id: 216,
		part_id: 175,
		quantity: 16,
	},
	{
		garage_id: 216,
		part_id: 176,
		quantity: 437,
	},
	{
		garage_id: 216,
		part_id: 177,
		quantity: 448,
	},
	{
		garage_id: 216,
		part_id: 178,
		quantity: 466,
	},
	{
		garage_id: 216,
		part_id: 179,
		quantity: 304,
	},
	{
		garage_id: 216,
		part_id: 180,
		quantity: 120,
	},
	{
		garage_id: 216,
		part_id: 181,
		quantity: 473,
	},
	{
		garage_id: 216,
		part_id: 182,
		quantity: 166,
	},
	{
		garage_id: 216,
		part_id: 183,
		quantity: 97,
	},
	{
		garage_id: 216,
		part_id: 184,
		quantity: 441,
	},
	{
		garage_id: 216,
		part_id: 185,
		quantity: 220,
	},
	{
		garage_id: 216,
		part_id: 186,
		quantity: 99,
	},
	{
		garage_id: 216,
		part_id: 187,
		quantity: 482,
	},
	{
		garage_id: 216,
		part_id: 188,
		quantity: 164,
	},
	{
		garage_id: 216,
		part_id: 189,
		quantity: 448,
	},
	{
		garage_id: 216,
		part_id: 190,
		quantity: 64,
	},
	{
		garage_id: 216,
		part_id: 191,
		quantity: 211,
	},
	{
		garage_id: 216,
		part_id: 192,
		quantity: 230,
	},
	{
		garage_id: 216,
		part_id: 193,
		quantity: 93,
	},
	{
		garage_id: 216,
		part_id: 194,
		quantity: 232,
	},
	{
		garage_id: 216,
		part_id: 195,
		quantity: 299,
	},
	{
		garage_id: 216,
		part_id: 196,
		quantity: 199,
	},
	{
		garage_id: 216,
		part_id: 197,
		quantity: 167,
	},
	{
		garage_id: 216,
		part_id: 198,
		quantity: 391,
	},
	{
		garage_id: 216,
		part_id: 199,
		quantity: 282,
	},
	{
		garage_id: 216,
		part_id: 200,
		quantity: 305,
	},
	{
		garage_id: 216,
		part_id: 201,
		quantity: 405,
	},
	{
		garage_id: 216,
		part_id: 202,
		quantity: 171,
	},
	{
		garage_id: 216,
		part_id: 203,
		quantity: 498,
	},
	{
		garage_id: 216,
		part_id: 204,
		quantity: 156,
	},
	{
		garage_id: 216,
		part_id: 205,
		quantity: 219,
	},
	{
		garage_id: 216,
		part_id: 206,
		quantity: 169,
	},
	{
		garage_id: 216,
		part_id: 207,
		quantity: 283,
	},
	{
		garage_id: 216,
		part_id: 208,
		quantity: 245,
	},
	{
		garage_id: 216,
		part_id: 209,
		quantity: 426,
	},
	{
		garage_id: 216,
		part_id: 210,
		quantity: 445,
	},
	{
		garage_id: 216,
		part_id: 211,
		quantity: 385,
	},
	{
		garage_id: 216,
		part_id: 212,
		quantity: 108,
	},
	{
		garage_id: 216,
		part_id: 213,
		quantity: 418,
	},
	{
		garage_id: 216,
		part_id: 214,
		quantity: 390,
	},
	{
		garage_id: 216,
		part_id: 215,
		quantity: 22,
	},
	{
		garage_id: 216,
		part_id: 216,
		quantity: 494,
	},
	{
		garage_id: 216,
		part_id: 217,
		quantity: 286,
	},
	{
		garage_id: 216,
		part_id: 218,
		quantity: 361,
	},
	{
		garage_id: 216,
		part_id: 219,
		quantity: 269,
	},
	{
		garage_id: 216,
		part_id: 220,
		quantity: 360,
	},
	{
		garage_id: 216,
		part_id: 221,
		quantity: 302,
	},
	{
		garage_id: 216,
		part_id: 222,
		quantity: 174,
	},
	{
		garage_id: 216,
		part_id: 223,
		quantity: 55,
	},
	{
		garage_id: 216,
		part_id: 224,
		quantity: 117,
	},
	{
		garage_id: 216,
		part_id: 225,
		quantity: 368,
	},
	{
		garage_id: 216,
		part_id: 226,
		quantity: 248,
	},
	{
		garage_id: 216,
		part_id: 227,
		quantity: 480,
	},
	{
		garage_id: 216,
		part_id: 228,
		quantity: 450,
	},
	{
		garage_id: 216,
		part_id: 229,
		quantity: 71,
	},
	{
		garage_id: 216,
		part_id: 230,
		quantity: 226,
	},
	{
		garage_id: 216,
		part_id: 231,
		quantity: 386,
	},
	{
		garage_id: 216,
		part_id: 232,
		quantity: 467,
	},
	{
		garage_id: 216,
		part_id: 233,
		quantity: 132,
	},
	{
		garage_id: 216,
		part_id: 234,
		quantity: 494,
	},
	{
		garage_id: 216,
		part_id: 235,
		quantity: 325,
	},
	{
		garage_id: 216,
		part_id: 236,
		quantity: 427,
	},
	{
		garage_id: 216,
		part_id: 237,
		quantity: 381,
	},
	{
		garage_id: 216,
		part_id: 238,
		quantity: 261,
	},
	{
		garage_id: 216,
		part_id: 239,
		quantity: 460,
	},
	{
		garage_id: 216,
		part_id: 240,
		quantity: 209,
	},
	{
		garage_id: 216,
		part_id: 241,
		quantity: 238,
	},
	{
		garage_id: 216,
		part_id: 242,
		quantity: 331,
	},
	{
		garage_id: 216,
		part_id: 243,
		quantity: 63,
	},
	{
		garage_id: 216,
		part_id: 244,
		quantity: 174,
	},
	{
		garage_id: 216,
		part_id: 245,
		quantity: 212,
	},
	{
		garage_id: 216,
		part_id: 246,
		quantity: 71,
	},
	{
		garage_id: 216,
		part_id: 247,
		quantity: 382,
	},
	{
		garage_id: 216,
		part_id: 248,
		quantity: 432,
	},
	{
		garage_id: 216,
		part_id: 249,
		quantity: 346,
	},
	{
		garage_id: 216,
		part_id: 250,
		quantity: 387,
	},
	{
		garage_id: 216,
		part_id: 251,
		quantity: 111,
	},
	{
		garage_id: 216,
		part_id: 252,
		quantity: 465,
	},
	{
		garage_id: 216,
		part_id: 253,
		quantity: 337,
	},
	{
		garage_id: 216,
		part_id: 254,
		quantity: 110,
	},
	{
		garage_id: 216,
		part_id: 255,
		quantity: 374,
	},
	{
		garage_id: 216,
		part_id: 256,
		quantity: 420,
	},
	{
		garage_id: 216,
		part_id: 257,
		quantity: 326,
	},
	{
		garage_id: 216,
		part_id: 258,
		quantity: 142,
	},
	{
		garage_id: 216,
		part_id: 259,
		quantity: 237,
	},
	{
		garage_id: 216,
		part_id: 260,
		quantity: 131,
	},
	{
		garage_id: 216,
		part_id: 261,
		quantity: 8,
	},
	{
		garage_id: 216,
		part_id: 262,
		quantity: 311,
	},
	{
		garage_id: 216,
		part_id: 263,
		quantity: 192,
	},
	{
		garage_id: 216,
		part_id: 264,
		quantity: 40,
	},
	{
		garage_id: 216,
		part_id: 265,
		quantity: 432,
	},
	{
		garage_id: 216,
		part_id: 266,
		quantity: 95,
	},
	{
		garage_id: 216,
		part_id: 267,
		quantity: 0,
	},
	{
		garage_id: 216,
		part_id: 268,
		quantity: 92,
	},
	{
		garage_id: 216,
		part_id: 269,
		quantity: 136,
	},
	{
		garage_id: 216,
		part_id: 270,
		quantity: 433,
	},
	{
		garage_id: 216,
		part_id: 271,
		quantity: 192,
	},
	{
		garage_id: 216,
		part_id: 272,
		quantity: 188,
	},
	{
		garage_id: 216,
		part_id: 273,
		quantity: 231,
	},
	{
		garage_id: 216,
		part_id: 274,
		quantity: 95,
	},
	{
		garage_id: 216,
		part_id: 275,
		quantity: 363,
	},
	{
		garage_id: 216,
		part_id: 276,
		quantity: 215,
	},
	{
		garage_id: 216,
		part_id: 277,
		quantity: 232,
	},
	{
		garage_id: 216,
		part_id: 278,
		quantity: 37,
	},
	{
		garage_id: 216,
		part_id: 279,
		quantity: 89,
	},
	{
		garage_id: 216,
		part_id: 280,
		quantity: 7,
	},
	{
		garage_id: 216,
		part_id: 281,
		quantity: 206,
	},
	{
		garage_id: 216,
		part_id: 282,
		quantity: 42,
	},
	{
		garage_id: 216,
		part_id: 283,
		quantity: 180,
	},
	{
		garage_id: 216,
		part_id: 284,
		quantity: 139,
	},
	{
		garage_id: 216,
		part_id: 285,
		quantity: 6,
	},
	{
		garage_id: 216,
		part_id: 286,
		quantity: 29,
	},
	{
		garage_id: 216,
		part_id: 287,
		quantity: 383,
	},
	{
		garage_id: 216,
		part_id: 288,
		quantity: 173,
	},
	{
		garage_id: 216,
		part_id: 289,
		quantity: 496,
	},
	{
		garage_id: 216,
		part_id: 290,
		quantity: 236,
	},
	{
		garage_id: 216,
		part_id: 291,
		quantity: 407,
	},
	{
		garage_id: 216,
		part_id: 292,
		quantity: 165,
	},
	{
		garage_id: 216,
		part_id: 293,
		quantity: 80,
	},
	{
		garage_id: 216,
		part_id: 294,
		quantity: 257,
	},
	{
		garage_id: 216,
		part_id: 295,
		quantity: 109,
	},
	{
		garage_id: 216,
		part_id: 296,
		quantity: 43,
	},
	{
		garage_id: 216,
		part_id: 297,
		quantity: 368,
	},
	{
		garage_id: 216,
		part_id: 298,
		quantity: 303,
	},
	{
		garage_id: 216,
		part_id: 299,
		quantity: 357,
	},
	{
		garage_id: 216,
		part_id: 300,
		quantity: 329,
	},
	{
		garage_id: 216,
		part_id: 301,
		quantity: 278,
	},
	{
		garage_id: 216,
		part_id: 302,
		quantity: 238,
	},
	{
		garage_id: 216,
		part_id: 303,
		quantity: 267,
	},
	{
		garage_id: 216,
		part_id: 304,
		quantity: 143,
	},
	{
		garage_id: 216,
		part_id: 305,
		quantity: 375,
	},
	{
		garage_id: 216,
		part_id: 306,
		quantity: 500,
	},
	{
		garage_id: 216,
		part_id: 307,
		quantity: 119,
	},
	{
		garage_id: 216,
		part_id: 308,
		quantity: 287,
	},
	{
		garage_id: 216,
		part_id: 309,
		quantity: 451,
	},
	{
		garage_id: 216,
		part_id: 310,
		quantity: 77,
	},
	{
		garage_id: 216,
		part_id: 311,
		quantity: 104,
	},
	{
		garage_id: 216,
		part_id: 312,
		quantity: 406,
	},
	{
		garage_id: 216,
		part_id: 313,
		quantity: 106,
	},
	{
		garage_id: 216,
		part_id: 314,
		quantity: 429,
	},
	{
		garage_id: 216,
		part_id: 315,
		quantity: 3,
	},
	{
		garage_id: 216,
		part_id: 316,
		quantity: 431,
	},
	{
		garage_id: 216,
		part_id: 317,
		quantity: 57,
	},
	{
		garage_id: 216,
		part_id: 318,
		quantity: 126,
	},
	{
		garage_id: 216,
		part_id: 319,
		quantity: 415,
	},
	{
		garage_id: 216,
		part_id: 320,
		quantity: 330,
	},
	{
		garage_id: 216,
		part_id: 321,
		quantity: 426,
	},
	{
		garage_id: 216,
		part_id: 322,
		quantity: 443,
	},
	{
		garage_id: 216,
		part_id: 323,
		quantity: 410,
	},
	{
		garage_id: 216,
		part_id: 324,
		quantity: 401,
	},
	{
		garage_id: 216,
		part_id: 325,
		quantity: 168,
	},
	{
		garage_id: 216,
		part_id: 326,
		quantity: 298,
	},
	{
		garage_id: 216,
		part_id: 327,
		quantity: 359,
	},
	{
		garage_id: 216,
		part_id: 328,
		quantity: 233,
	},
	{
		garage_id: 216,
		part_id: 329,
		quantity: 424,
	},
	{
		garage_id: 216,
		part_id: 330,
		quantity: 155,
	},
	{
		garage_id: 216,
		part_id: 331,
		quantity: 144,
	},
	{
		garage_id: 216,
		part_id: 332,
		quantity: 299,
	},
	{
		garage_id: 216,
		part_id: 333,
		quantity: 127,
	},
	{
		garage_id: 216,
		part_id: 334,
		quantity: 481,
	},
	{
		garage_id: 216,
		part_id: 335,
		quantity: 247,
	},
	{
		garage_id: 216,
		part_id: 336,
		quantity: 424,
	},
	{
		garage_id: 216,
		part_id: 337,
		quantity: 62,
	},
	{
		garage_id: 216,
		part_id: 338,
		quantity: 120,
	},
	{
		garage_id: 216,
		part_id: 339,
		quantity: 336,
	},
	{
		garage_id: 216,
		part_id: 340,
		quantity: 33,
	},
	{
		garage_id: 216,
		part_id: 341,
		quantity: 399,
	},
	{
		garage_id: 216,
		part_id: 342,
		quantity: 479,
	},
	{
		garage_id: 216,
		part_id: 343,
		quantity: 231,
	},
	{
		garage_id: 216,
		part_id: 344,
		quantity: 466,
	},
	{
		garage_id: 216,
		part_id: 345,
		quantity: 446,
	},
	{
		garage_id: 216,
		part_id: 346,
		quantity: 386,
	},
	{
		garage_id: 216,
		part_id: 347,
		quantity: 371,
	},
	{
		garage_id: 216,
		part_id: 348,
		quantity: 432,
	},
	{
		garage_id: 216,
		part_id: 349,
		quantity: 227,
	},
	{
		garage_id: 216,
		part_id: 350,
		quantity: 378,
	},
	{
		garage_id: 216,
		part_id: 351,
		quantity: 435,
	},
	{
		garage_id: 216,
		part_id: 352,
		quantity: 442,
	},
	{
		garage_id: 216,
		part_id: 353,
		quantity: 80,
	},
	{
		garage_id: 216,
		part_id: 354,
		quantity: 249,
	},
	{
		garage_id: 216,
		part_id: 355,
		quantity: 37,
	},
	{
		garage_id: 216,
		part_id: 356,
		quantity: 271,
	},
	{
		garage_id: 216,
		part_id: 357,
		quantity: 381,
	},
	{
		garage_id: 216,
		part_id: 358,
		quantity: 152,
	},
	{
		garage_id: 216,
		part_id: 359,
		quantity: 180,
	},
	{
		garage_id: 216,
		part_id: 360,
		quantity: 79,
	},
	{
		garage_id: 216,
		part_id: 361,
		quantity: 107,
	},
	{
		garage_id: 216,
		part_id: 362,
		quantity: 42,
	},
	{
		garage_id: 216,
		part_id: 363,
		quantity: 20,
	},
	{
		garage_id: 216,
		part_id: 364,
		quantity: 408,
	},
	{
		garage_id: 216,
		part_id: 365,
		quantity: 422,
	},
	{
		garage_id: 216,
		part_id: 366,
		quantity: 64,
	},
	{
		garage_id: 216,
		part_id: 367,
		quantity: 106,
	},
	{
		garage_id: 216,
		part_id: 368,
		quantity: 370,
	},
	{
		garage_id: 216,
		part_id: 369,
		quantity: 213,
	},
	{
		garage_id: 216,
		part_id: 370,
		quantity: 236,
	},
	{
		garage_id: 216,
		part_id: 371,
		quantity: 273,
	},
	{
		garage_id: 216,
		part_id: 372,
		quantity: 492,
	},
	{
		garage_id: 216,
		part_id: 373,
		quantity: 332,
	},
	{
		garage_id: 216,
		part_id: 374,
		quantity: 56,
	},
	{
		garage_id: 216,
		part_id: 375,
		quantity: 485,
	},
	{
		garage_id: 216,
		part_id: 376,
		quantity: 312,
	},
	{
		garage_id: 216,
		part_id: 377,
		quantity: 107,
	},
	{
		garage_id: 216,
		part_id: 378,
		quantity: 234,
	},
	{
		garage_id: 216,
		part_id: 379,
		quantity: 54,
	},
	{
		garage_id: 216,
		part_id: 380,
		quantity: 186,
	},
	{
		garage_id: 216,
		part_id: 381,
		quantity: 492,
	},
	{
		garage_id: 216,
		part_id: 382,
		quantity: 271,
	},
	{
		garage_id: 216,
		part_id: 383,
		quantity: 19,
	},
	{
		garage_id: 216,
		part_id: 384,
		quantity: 483,
	},
	{
		garage_id: 216,
		part_id: 385,
		quantity: 317,
	},
	{
		garage_id: 216,
		part_id: 386,
		quantity: 391,
	},
	{
		garage_id: 216,
		part_id: 387,
		quantity: 441,
	},
	{
		garage_id: 216,
		part_id: 388,
		quantity: 365,
	},
	{
		garage_id: 216,
		part_id: 389,
		quantity: 476,
	},
	{
		garage_id: 216,
		part_id: 390,
		quantity: 418,
	},
	{
		garage_id: 216,
		part_id: 391,
		quantity: 154,
	},
	{
		garage_id: 216,
		part_id: 392,
		quantity: 23,
	},
	{
		garage_id: 216,
		part_id: 393,
		quantity: 173,
	},
	{
		garage_id: 216,
		part_id: 394,
		quantity: 374,
	},
	{
		garage_id: 216,
		part_id: 395,
		quantity: 422,
	},
	{
		garage_id: 216,
		part_id: 396,
		quantity: 245,
	},
	{
		garage_id: 216,
		part_id: 397,
		quantity: 73,
	},
	{
		garage_id: 216,
		part_id: 398,
		quantity: 377,
	},
	{
		garage_id: 216,
		part_id: 399,
		quantity: 48,
	},
	{
		garage_id: 217,
		part_id: 0,
		quantity: 279,
	},
	{
		garage_id: 217,
		part_id: 1,
		quantity: 272,
	},
	{
		garage_id: 217,
		part_id: 2,
		quantity: 89,
	},
	{
		garage_id: 217,
		part_id: 3,
		quantity: 223,
	},
	{
		garage_id: 217,
		part_id: 4,
		quantity: 5,
	},
	{
		garage_id: 217,
		part_id: 5,
		quantity: 104,
	},
	{
		garage_id: 217,
		part_id: 6,
		quantity: 73,
	},
	{
		garage_id: 217,
		part_id: 7,
		quantity: 341,
	},
	{
		garage_id: 217,
		part_id: 8,
		quantity: 458,
	},
	{
		garage_id: 217,
		part_id: 9,
		quantity: 249,
	},
	{
		garage_id: 217,
		part_id: 10,
		quantity: 70,
	},
	{
		garage_id: 217,
		part_id: 11,
		quantity: 446,
	},
	{
		garage_id: 217,
		part_id: 12,
		quantity: 132,
	},
	{
		garage_id: 217,
		part_id: 13,
		quantity: 272,
	},
	{
		garage_id: 217,
		part_id: 14,
		quantity: 408,
	},
	{
		garage_id: 217,
		part_id: 15,
		quantity: 393,
	},
	{
		garage_id: 217,
		part_id: 16,
		quantity: 225,
	},
	{
		garage_id: 217,
		part_id: 17,
		quantity: 471,
	},
	{
		garage_id: 217,
		part_id: 18,
		quantity: 333,
	},
	{
		garage_id: 217,
		part_id: 19,
		quantity: 173,
	},
	{
		garage_id: 217,
		part_id: 20,
		quantity: 163,
	},
	{
		garage_id: 217,
		part_id: 21,
		quantity: 123,
	},
	{
		garage_id: 217,
		part_id: 22,
		quantity: 493,
	},
	{
		garage_id: 217,
		part_id: 23,
		quantity: 391,
	},
	{
		garage_id: 217,
		part_id: 24,
		quantity: 138,
	},
	{
		garage_id: 217,
		part_id: 25,
		quantity: 300,
	},
	{
		garage_id: 217,
		part_id: 26,
		quantity: 465,
	},
	{
		garage_id: 217,
		part_id: 27,
		quantity: 173,
	},
	{
		garage_id: 217,
		part_id: 28,
		quantity: 314,
	},
	{
		garage_id: 217,
		part_id: 29,
		quantity: 49,
	},
	{
		garage_id: 217,
		part_id: 30,
		quantity: 121,
	},
	{
		garage_id: 217,
		part_id: 31,
		quantity: 217,
	},
	{
		garage_id: 217,
		part_id: 32,
		quantity: 204,
	},
	{
		garage_id: 217,
		part_id: 33,
		quantity: 394,
	},
	{
		garage_id: 217,
		part_id: 34,
		quantity: 309,
	},
	{
		garage_id: 217,
		part_id: 35,
		quantity: 163,
	},
	{
		garage_id: 217,
		part_id: 36,
		quantity: 113,
	},
	{
		garage_id: 217,
		part_id: 37,
		quantity: 54,
	},
	{
		garage_id: 217,
		part_id: 38,
		quantity: 332,
	},
	{
		garage_id: 217,
		part_id: 39,
		quantity: 319,
	},
	{
		garage_id: 217,
		part_id: 40,
		quantity: 440,
	},
	{
		garage_id: 217,
		part_id: 41,
		quantity: 414,
	},
	{
		garage_id: 217,
		part_id: 42,
		quantity: 9,
	},
	{
		garage_id: 217,
		part_id: 43,
		quantity: 349,
	},
	{
		garage_id: 217,
		part_id: 44,
		quantity: 18,
	},
	{
		garage_id: 217,
		part_id: 45,
		quantity: 165,
	},
	{
		garage_id: 217,
		part_id: 46,
		quantity: 218,
	},
	{
		garage_id: 217,
		part_id: 47,
		quantity: 375,
	},
	{
		garage_id: 217,
		part_id: 48,
		quantity: 390,
	},
	{
		garage_id: 217,
		part_id: 49,
		quantity: 245,
	},
	{
		garage_id: 217,
		part_id: 50,
		quantity: 269,
	},
	{
		garage_id: 217,
		part_id: 51,
		quantity: 86,
	},
	{
		garage_id: 217,
		part_id: 52,
		quantity: 224,
	},
	{
		garage_id: 217,
		part_id: 53,
		quantity: 108,
	},
	{
		garage_id: 217,
		part_id: 54,
		quantity: 40,
	},
	{
		garage_id: 217,
		part_id: 55,
		quantity: 475,
	},
	{
		garage_id: 217,
		part_id: 56,
		quantity: 58,
	},
	{
		garage_id: 217,
		part_id: 57,
		quantity: 414,
	},
	{
		garage_id: 217,
		part_id: 58,
		quantity: 69,
	},
	{
		garage_id: 217,
		part_id: 59,
		quantity: 7,
	},
	{
		garage_id: 217,
		part_id: 60,
		quantity: 54,
	},
	{
		garage_id: 217,
		part_id: 61,
		quantity: 188,
	},
	{
		garage_id: 217,
		part_id: 62,
		quantity: 25,
	},
	{
		garage_id: 217,
		part_id: 63,
		quantity: 165,
	},
	{
		garage_id: 217,
		part_id: 64,
		quantity: 127,
	},
	{
		garage_id: 217,
		part_id: 65,
		quantity: 281,
	},
	{
		garage_id: 217,
		part_id: 66,
		quantity: 214,
	},
	{
		garage_id: 217,
		part_id: 67,
		quantity: 242,
	},
	{
		garage_id: 217,
		part_id: 68,
		quantity: 496,
	},
	{
		garage_id: 217,
		part_id: 69,
		quantity: 465,
	},
	{
		garage_id: 217,
		part_id: 70,
		quantity: 134,
	},
	{
		garage_id: 217,
		part_id: 71,
		quantity: 322,
	},
	{
		garage_id: 217,
		part_id: 72,
		quantity: 220,
	},
	{
		garage_id: 217,
		part_id: 73,
		quantity: 15,
	},
	{
		garage_id: 217,
		part_id: 74,
		quantity: 385,
	},
	{
		garage_id: 217,
		part_id: 75,
		quantity: 394,
	},
	{
		garage_id: 217,
		part_id: 76,
		quantity: 10,
	},
	{
		garage_id: 217,
		part_id: 77,
		quantity: 54,
	},
	{
		garage_id: 217,
		part_id: 78,
		quantity: 314,
	},
	{
		garage_id: 217,
		part_id: 79,
		quantity: 256,
	},
	{
		garage_id: 217,
		part_id: 80,
		quantity: 22,
	},
	{
		garage_id: 217,
		part_id: 81,
		quantity: 347,
	},
	{
		garage_id: 217,
		part_id: 82,
		quantity: 368,
	},
	{
		garage_id: 217,
		part_id: 83,
		quantity: 416,
	},
	{
		garage_id: 217,
		part_id: 84,
		quantity: 390,
	},
	{
		garage_id: 217,
		part_id: 85,
		quantity: 49,
	},
	{
		garage_id: 217,
		part_id: 86,
		quantity: 213,
	},
	{
		garage_id: 217,
		part_id: 87,
		quantity: 163,
	},
	{
		garage_id: 217,
		part_id: 88,
		quantity: 0,
	},
	{
		garage_id: 217,
		part_id: 89,
		quantity: 234,
	},
	{
		garage_id: 217,
		part_id: 90,
		quantity: 445,
	},
	{
		garage_id: 217,
		part_id: 91,
		quantity: 427,
	},
	{
		garage_id: 217,
		part_id: 92,
		quantity: 370,
	},
	{
		garage_id: 217,
		part_id: 93,
		quantity: 158,
	},
	{
		garage_id: 217,
		part_id: 94,
		quantity: 453,
	},
	{
		garage_id: 217,
		part_id: 95,
		quantity: 203,
	},
	{
		garage_id: 217,
		part_id: 96,
		quantity: 333,
	},
	{
		garage_id: 217,
		part_id: 97,
		quantity: 257,
	},
	{
		garage_id: 217,
		part_id: 98,
		quantity: 474,
	},
	{
		garage_id: 217,
		part_id: 99,
		quantity: 370,
	},
	{
		garage_id: 217,
		part_id: 100,
		quantity: 9,
	},
	{
		garage_id: 217,
		part_id: 101,
		quantity: 355,
	},
	{
		garage_id: 217,
		part_id: 102,
		quantity: 469,
	},
	{
		garage_id: 217,
		part_id: 103,
		quantity: 482,
	},
	{
		garage_id: 217,
		part_id: 104,
		quantity: 243,
	},
	{
		garage_id: 217,
		part_id: 105,
		quantity: 395,
	},
	{
		garage_id: 217,
		part_id: 106,
		quantity: 347,
	},
	{
		garage_id: 217,
		part_id: 107,
		quantity: 132,
	},
	{
		garage_id: 217,
		part_id: 108,
		quantity: 362,
	},
	{
		garage_id: 217,
		part_id: 109,
		quantity: 442,
	},
	{
		garage_id: 217,
		part_id: 110,
		quantity: 351,
	},
	{
		garage_id: 217,
		part_id: 111,
		quantity: 257,
	},
	{
		garage_id: 217,
		part_id: 112,
		quantity: 404,
	},
	{
		garage_id: 217,
		part_id: 113,
		quantity: 448,
	},
	{
		garage_id: 217,
		part_id: 114,
		quantity: 465,
	},
	{
		garage_id: 217,
		part_id: 115,
		quantity: 261,
	},
	{
		garage_id: 217,
		part_id: 116,
		quantity: 82,
	},
	{
		garage_id: 217,
		part_id: 117,
		quantity: 304,
	},
	{
		garage_id: 217,
		part_id: 118,
		quantity: 209,
	},
	{
		garage_id: 217,
		part_id: 119,
		quantity: 488,
	},
	{
		garage_id: 217,
		part_id: 120,
		quantity: 234,
	},
	{
		garage_id: 217,
		part_id: 121,
		quantity: 489,
	},
	{
		garage_id: 217,
		part_id: 122,
		quantity: 490,
	},
	{
		garage_id: 217,
		part_id: 123,
		quantity: 383,
	},
	{
		garage_id: 217,
		part_id: 124,
		quantity: 266,
	},
	{
		garage_id: 217,
		part_id: 125,
		quantity: 420,
	},
	{
		garage_id: 217,
		part_id: 126,
		quantity: 86,
	},
	{
		garage_id: 217,
		part_id: 127,
		quantity: 475,
	},
	{
		garage_id: 217,
		part_id: 128,
		quantity: 24,
	},
	{
		garage_id: 217,
		part_id: 129,
		quantity: 4,
	},
	{
		garage_id: 217,
		part_id: 130,
		quantity: 319,
	},
	{
		garage_id: 217,
		part_id: 131,
		quantity: 306,
	},
	{
		garage_id: 217,
		part_id: 132,
		quantity: 109,
	},
	{
		garage_id: 217,
		part_id: 133,
		quantity: 269,
	},
	{
		garage_id: 217,
		part_id: 134,
		quantity: 81,
	},
	{
		garage_id: 217,
		part_id: 135,
		quantity: 164,
	},
	{
		garage_id: 217,
		part_id: 136,
		quantity: 218,
	},
	{
		garage_id: 217,
		part_id: 137,
		quantity: 438,
	},
	{
		garage_id: 217,
		part_id: 138,
		quantity: 244,
	},
	{
		garage_id: 217,
		part_id: 139,
		quantity: 198,
	},
	{
		garage_id: 217,
		part_id: 140,
		quantity: 308,
	},
	{
		garage_id: 217,
		part_id: 141,
		quantity: 389,
	},
	{
		garage_id: 217,
		part_id: 142,
		quantity: 191,
	},
	{
		garage_id: 217,
		part_id: 143,
		quantity: 341,
	},
	{
		garage_id: 217,
		part_id: 144,
		quantity: 313,
	},
	{
		garage_id: 217,
		part_id: 145,
		quantity: 378,
	},
	{
		garage_id: 217,
		part_id: 146,
		quantity: 269,
	},
	{
		garage_id: 217,
		part_id: 147,
		quantity: 332,
	},
	{
		garage_id: 217,
		part_id: 148,
		quantity: 463,
	},
	{
		garage_id: 217,
		part_id: 149,
		quantity: 1,
	},
	{
		garage_id: 217,
		part_id: 150,
		quantity: 380,
	},
	{
		garage_id: 217,
		part_id: 151,
		quantity: 187,
	},
	{
		garage_id: 217,
		part_id: 152,
		quantity: 261,
	},
	{
		garage_id: 217,
		part_id: 153,
		quantity: 373,
	},
	{
		garage_id: 217,
		part_id: 154,
		quantity: 173,
	},
	{
		garage_id: 217,
		part_id: 155,
		quantity: 133,
	},
	{
		garage_id: 217,
		part_id: 156,
		quantity: 458,
	},
	{
		garage_id: 217,
		part_id: 157,
		quantity: 334,
	},
	{
		garage_id: 217,
		part_id: 158,
		quantity: 435,
	},
	{
		garage_id: 217,
		part_id: 159,
		quantity: 216,
	},
	{
		garage_id: 217,
		part_id: 160,
		quantity: 185,
	},
	{
		garage_id: 217,
		part_id: 161,
		quantity: 217,
	},
	{
		garage_id: 217,
		part_id: 162,
		quantity: 152,
	},
	{
		garage_id: 217,
		part_id: 163,
		quantity: 290,
	},
	{
		garage_id: 217,
		part_id: 164,
		quantity: 64,
	},
	{
		garage_id: 217,
		part_id: 165,
		quantity: 492,
	},
	{
		garage_id: 217,
		part_id: 166,
		quantity: 298,
	},
	{
		garage_id: 217,
		part_id: 167,
		quantity: 464,
	},
	{
		garage_id: 217,
		part_id: 168,
		quantity: 477,
	},
	{
		garage_id: 217,
		part_id: 169,
		quantity: 9,
	},
	{
		garage_id: 217,
		part_id: 170,
		quantity: 342,
	},
	{
		garage_id: 217,
		part_id: 171,
		quantity: 472,
	},
	{
		garage_id: 217,
		part_id: 172,
		quantity: 393,
	},
	{
		garage_id: 217,
		part_id: 173,
		quantity: 423,
	},
	{
		garage_id: 217,
		part_id: 174,
		quantity: 319,
	},
	{
		garage_id: 217,
		part_id: 175,
		quantity: 390,
	},
	{
		garage_id: 217,
		part_id: 176,
		quantity: 140,
	},
	{
		garage_id: 217,
		part_id: 177,
		quantity: 455,
	},
	{
		garage_id: 217,
		part_id: 178,
		quantity: 364,
	},
	{
		garage_id: 217,
		part_id: 179,
		quantity: 401,
	},
	{
		garage_id: 217,
		part_id: 180,
		quantity: 245,
	},
	{
		garage_id: 217,
		part_id: 181,
		quantity: 160,
	},
	{
		garage_id: 217,
		part_id: 182,
		quantity: 120,
	},
	{
		garage_id: 217,
		part_id: 183,
		quantity: 313,
	},
	{
		garage_id: 217,
		part_id: 184,
		quantity: 85,
	},
	{
		garage_id: 217,
		part_id: 185,
		quantity: 138,
	},
	{
		garage_id: 217,
		part_id: 186,
		quantity: 95,
	},
	{
		garage_id: 217,
		part_id: 187,
		quantity: 425,
	},
	{
		garage_id: 217,
		part_id: 188,
		quantity: 119,
	},
	{
		garage_id: 217,
		part_id: 189,
		quantity: 244,
	},
	{
		garage_id: 217,
		part_id: 190,
		quantity: 322,
	},
	{
		garage_id: 217,
		part_id: 191,
		quantity: 341,
	},
	{
		garage_id: 217,
		part_id: 192,
		quantity: 239,
	},
	{
		garage_id: 217,
		part_id: 193,
		quantity: 443,
	},
	{
		garage_id: 217,
		part_id: 194,
		quantity: 251,
	},
	{
		garage_id: 217,
		part_id: 195,
		quantity: 463,
	},
	{
		garage_id: 217,
		part_id: 196,
		quantity: 264,
	},
	{
		garage_id: 217,
		part_id: 197,
		quantity: 162,
	},
	{
		garage_id: 217,
		part_id: 198,
		quantity: 378,
	},
	{
		garage_id: 217,
		part_id: 199,
		quantity: 425,
	},
	{
		garage_id: 217,
		part_id: 200,
		quantity: 175,
	},
	{
		garage_id: 217,
		part_id: 201,
		quantity: 440,
	},
	{
		garage_id: 217,
		part_id: 202,
		quantity: 133,
	},
	{
		garage_id: 217,
		part_id: 203,
		quantity: 385,
	},
	{
		garage_id: 217,
		part_id: 204,
		quantity: 356,
	},
	{
		garage_id: 217,
		part_id: 205,
		quantity: 439,
	},
	{
		garage_id: 217,
		part_id: 206,
		quantity: 173,
	},
	{
		garage_id: 217,
		part_id: 207,
		quantity: 323,
	},
	{
		garage_id: 217,
		part_id: 208,
		quantity: 312,
	},
	{
		garage_id: 217,
		part_id: 209,
		quantity: 233,
	},
	{
		garage_id: 217,
		part_id: 210,
		quantity: 208,
	},
	{
		garage_id: 217,
		part_id: 211,
		quantity: 319,
	},
	{
		garage_id: 217,
		part_id: 212,
		quantity: 126,
	},
	{
		garage_id: 217,
		part_id: 213,
		quantity: 297,
	},
	{
		garage_id: 217,
		part_id: 214,
		quantity: 441,
	},
	{
		garage_id: 217,
		part_id: 215,
		quantity: 284,
	},
	{
		garage_id: 217,
		part_id: 216,
		quantity: 223,
	},
	{
		garage_id: 217,
		part_id: 217,
		quantity: 139,
	},
	{
		garage_id: 217,
		part_id: 218,
		quantity: 95,
	},
	{
		garage_id: 217,
		part_id: 219,
		quantity: 173,
	},
	{
		garage_id: 217,
		part_id: 220,
		quantity: 71,
	},
	{
		garage_id: 217,
		part_id: 221,
		quantity: 47,
	},
	{
		garage_id: 217,
		part_id: 222,
		quantity: 263,
	},
	{
		garage_id: 217,
		part_id: 223,
		quantity: 274,
	},
	{
		garage_id: 217,
		part_id: 224,
		quantity: 51,
	},
	{
		garage_id: 217,
		part_id: 225,
		quantity: 324,
	},
	{
		garage_id: 217,
		part_id: 226,
		quantity: 475,
	},
	{
		garage_id: 217,
		part_id: 227,
		quantity: 18,
	},
	{
		garage_id: 217,
		part_id: 228,
		quantity: 94,
	},
	{
		garage_id: 217,
		part_id: 229,
		quantity: 151,
	},
	{
		garage_id: 217,
		part_id: 230,
		quantity: 71,
	},
	{
		garage_id: 217,
		part_id: 231,
		quantity: 342,
	},
	{
		garage_id: 217,
		part_id: 232,
		quantity: 282,
	},
	{
		garage_id: 217,
		part_id: 233,
		quantity: 101,
	},
	{
		garage_id: 217,
		part_id: 234,
		quantity: 220,
	},
	{
		garage_id: 217,
		part_id: 235,
		quantity: 448,
	},
	{
		garage_id: 217,
		part_id: 236,
		quantity: 48,
	},
	{
		garage_id: 217,
		part_id: 237,
		quantity: 100,
	},
	{
		garage_id: 217,
		part_id: 238,
		quantity: 2,
	},
	{
		garage_id: 217,
		part_id: 239,
		quantity: 308,
	},
	{
		garage_id: 217,
		part_id: 240,
		quantity: 137,
	},
	{
		garage_id: 217,
		part_id: 241,
		quantity: 128,
	},
	{
		garage_id: 217,
		part_id: 242,
		quantity: 27,
	},
	{
		garage_id: 217,
		part_id: 243,
		quantity: 153,
	},
	{
		garage_id: 217,
		part_id: 244,
		quantity: 222,
	},
	{
		garage_id: 217,
		part_id: 245,
		quantity: 41,
	},
	{
		garage_id: 217,
		part_id: 246,
		quantity: 239,
	},
	{
		garage_id: 217,
		part_id: 247,
		quantity: 106,
	},
	{
		garage_id: 217,
		part_id: 248,
		quantity: 197,
	},
	{
		garage_id: 217,
		part_id: 249,
		quantity: 96,
	},
	{
		garage_id: 217,
		part_id: 250,
		quantity: 245,
	},
	{
		garage_id: 217,
		part_id: 251,
		quantity: 237,
	},
	{
		garage_id: 217,
		part_id: 252,
		quantity: 464,
	},
	{
		garage_id: 217,
		part_id: 253,
		quantity: 355,
	},
	{
		garage_id: 217,
		part_id: 254,
		quantity: 111,
	},
	{
		garage_id: 217,
		part_id: 255,
		quantity: 320,
	},
	{
		garage_id: 217,
		part_id: 256,
		quantity: 309,
	},
	{
		garage_id: 217,
		part_id: 257,
		quantity: 227,
	},
	{
		garage_id: 217,
		part_id: 258,
		quantity: 168,
	},
	{
		garage_id: 217,
		part_id: 259,
		quantity: 244,
	},
	{
		garage_id: 217,
		part_id: 260,
		quantity: 30,
	},
	{
		garage_id: 217,
		part_id: 261,
		quantity: 150,
	},
	{
		garage_id: 217,
		part_id: 262,
		quantity: 9,
	},
	{
		garage_id: 217,
		part_id: 263,
		quantity: 104,
	},
	{
		garage_id: 217,
		part_id: 264,
		quantity: 9,
	},
	{
		garage_id: 217,
		part_id: 265,
		quantity: 165,
	},
	{
		garage_id: 217,
		part_id: 266,
		quantity: 30,
	},
	{
		garage_id: 217,
		part_id: 267,
		quantity: 405,
	},
	{
		garage_id: 217,
		part_id: 268,
		quantity: 492,
	},
	{
		garage_id: 217,
		part_id: 269,
		quantity: 221,
	},
	{
		garage_id: 217,
		part_id: 270,
		quantity: 465,
	},
	{
		garage_id: 217,
		part_id: 271,
		quantity: 81,
	},
	{
		garage_id: 217,
		part_id: 272,
		quantity: 242,
	},
	{
		garage_id: 217,
		part_id: 273,
		quantity: 334,
	},
	{
		garage_id: 217,
		part_id: 274,
		quantity: 221,
	},
	{
		garage_id: 217,
		part_id: 275,
		quantity: 390,
	},
	{
		garage_id: 217,
		part_id: 276,
		quantity: 194,
	},
	{
		garage_id: 217,
		part_id: 277,
		quantity: 30,
	},
	{
		garage_id: 217,
		part_id: 278,
		quantity: 446,
	},
	{
		garage_id: 217,
		part_id: 279,
		quantity: 407,
	},
	{
		garage_id: 217,
		part_id: 280,
		quantity: 6,
	},
	{
		garage_id: 217,
		part_id: 281,
		quantity: 473,
	},
	{
		garage_id: 217,
		part_id: 282,
		quantity: 34,
	},
	{
		garage_id: 217,
		part_id: 283,
		quantity: 226,
	},
	{
		garage_id: 217,
		part_id: 284,
		quantity: 376,
	},
	{
		garage_id: 217,
		part_id: 285,
		quantity: 284,
	},
	{
		garage_id: 217,
		part_id: 286,
		quantity: 279,
	},
	{
		garage_id: 217,
		part_id: 287,
		quantity: 295,
	},
	{
		garage_id: 217,
		part_id: 288,
		quantity: 239,
	},
	{
		garage_id: 217,
		part_id: 289,
		quantity: 431,
	},
	{
		garage_id: 217,
		part_id: 290,
		quantity: 372,
	},
	{
		garage_id: 217,
		part_id: 291,
		quantity: 195,
	},
	{
		garage_id: 217,
		part_id: 292,
		quantity: 199,
	},
	{
		garage_id: 217,
		part_id: 293,
		quantity: 181,
	},
	{
		garage_id: 217,
		part_id: 294,
		quantity: 43,
	},
	{
		garage_id: 217,
		part_id: 295,
		quantity: 83,
	},
	{
		garage_id: 217,
		part_id: 296,
		quantity: 136,
	},
	{
		garage_id: 217,
		part_id: 297,
		quantity: 346,
	},
	{
		garage_id: 217,
		part_id: 298,
		quantity: 8,
	},
	{
		garage_id: 217,
		part_id: 299,
		quantity: 443,
	},
	{
		garage_id: 217,
		part_id: 300,
		quantity: 461,
	},
	{
		garage_id: 217,
		part_id: 301,
		quantity: 312,
	},
	{
		garage_id: 217,
		part_id: 302,
		quantity: 364,
	},
	{
		garage_id: 217,
		part_id: 303,
		quantity: 96,
	},
	{
		garage_id: 217,
		part_id: 304,
		quantity: 392,
	},
	{
		garage_id: 217,
		part_id: 305,
		quantity: 159,
	},
	{
		garage_id: 217,
		part_id: 306,
		quantity: 393,
	},
	{
		garage_id: 217,
		part_id: 307,
		quantity: 324,
	},
	{
		garage_id: 217,
		part_id: 308,
		quantity: 373,
	},
	{
		garage_id: 217,
		part_id: 309,
		quantity: 64,
	},
	{
		garage_id: 217,
		part_id: 310,
		quantity: 67,
	},
	{
		garage_id: 217,
		part_id: 311,
		quantity: 34,
	},
	{
		garage_id: 217,
		part_id: 312,
		quantity: 410,
	},
	{
		garage_id: 217,
		part_id: 313,
		quantity: 126,
	},
	{
		garage_id: 217,
		part_id: 314,
		quantity: 240,
	},
	{
		garage_id: 217,
		part_id: 315,
		quantity: 47,
	},
	{
		garage_id: 217,
		part_id: 316,
		quantity: 136,
	},
	{
		garage_id: 217,
		part_id: 317,
		quantity: 262,
	},
	{
		garage_id: 217,
		part_id: 318,
		quantity: 161,
	},
	{
		garage_id: 217,
		part_id: 319,
		quantity: 75,
	},
	{
		garage_id: 217,
		part_id: 320,
		quantity: 262,
	},
	{
		garage_id: 217,
		part_id: 321,
		quantity: 414,
	},
	{
		garage_id: 217,
		part_id: 322,
		quantity: 43,
	},
	{
		garage_id: 217,
		part_id: 323,
		quantity: 228,
	},
	{
		garage_id: 217,
		part_id: 324,
		quantity: 69,
	},
	{
		garage_id: 217,
		part_id: 325,
		quantity: 389,
	},
	{
		garage_id: 217,
		part_id: 326,
		quantity: 35,
	},
	{
		garage_id: 217,
		part_id: 327,
		quantity: 92,
	},
	{
		garage_id: 217,
		part_id: 328,
		quantity: 12,
	},
	{
		garage_id: 217,
		part_id: 329,
		quantity: 187,
	},
	{
		garage_id: 217,
		part_id: 330,
		quantity: 487,
	},
	{
		garage_id: 217,
		part_id: 331,
		quantity: 416,
	},
	{
		garage_id: 217,
		part_id: 332,
		quantity: 360,
	},
	{
		garage_id: 217,
		part_id: 333,
		quantity: 336,
	},
	{
		garage_id: 217,
		part_id: 334,
		quantity: 15,
	},
	{
		garage_id: 217,
		part_id: 335,
		quantity: 270,
	},
	{
		garage_id: 217,
		part_id: 336,
		quantity: 115,
	},
	{
		garage_id: 217,
		part_id: 337,
		quantity: 123,
	},
	{
		garage_id: 217,
		part_id: 338,
		quantity: 8,
	},
	{
		garage_id: 217,
		part_id: 339,
		quantity: 89,
	},
	{
		garage_id: 217,
		part_id: 340,
		quantity: 167,
	},
	{
		garage_id: 217,
		part_id: 341,
		quantity: 343,
	},
	{
		garage_id: 217,
		part_id: 342,
		quantity: 129,
	},
	{
		garage_id: 217,
		part_id: 343,
		quantity: 278,
	},
	{
		garage_id: 217,
		part_id: 344,
		quantity: 223,
	},
	{
		garage_id: 217,
		part_id: 345,
		quantity: 102,
	},
	{
		garage_id: 217,
		part_id: 346,
		quantity: 434,
	},
	{
		garage_id: 217,
		part_id: 347,
		quantity: 168,
	},
	{
		garage_id: 217,
		part_id: 348,
		quantity: 172,
	},
	{
		garage_id: 217,
		part_id: 349,
		quantity: 237,
	},
	{
		garage_id: 217,
		part_id: 350,
		quantity: 464,
	},
	{
		garage_id: 217,
		part_id: 351,
		quantity: 497,
	},
	{
		garage_id: 217,
		part_id: 352,
		quantity: 433,
	},
	{
		garage_id: 217,
		part_id: 353,
		quantity: 416,
	},
	{
		garage_id: 217,
		part_id: 354,
		quantity: 109,
	},
	{
		garage_id: 217,
		part_id: 355,
		quantity: 471,
	},
	{
		garage_id: 217,
		part_id: 356,
		quantity: 332,
	},
	{
		garage_id: 217,
		part_id: 357,
		quantity: 354,
	},
	{
		garage_id: 217,
		part_id: 358,
		quantity: 6,
	},
	{
		garage_id: 217,
		part_id: 359,
		quantity: 224,
	},
	{
		garage_id: 217,
		part_id: 360,
		quantity: 154,
	},
	{
		garage_id: 217,
		part_id: 361,
		quantity: 411,
	},
	{
		garage_id: 217,
		part_id: 362,
		quantity: 500,
	},
	{
		garage_id: 217,
		part_id: 363,
		quantity: 377,
	},
	{
		garage_id: 217,
		part_id: 364,
		quantity: 133,
	},
	{
		garage_id: 217,
		part_id: 365,
		quantity: 474,
	},
	{
		garage_id: 217,
		part_id: 366,
		quantity: 57,
	},
	{
		garage_id: 217,
		part_id: 367,
		quantity: 498,
	},
	{
		garage_id: 217,
		part_id: 368,
		quantity: 56,
	},
	{
		garage_id: 217,
		part_id: 369,
		quantity: 217,
	},
	{
		garage_id: 217,
		part_id: 370,
		quantity: 422,
	},
	{
		garage_id: 217,
		part_id: 371,
		quantity: 420,
	},
	{
		garage_id: 217,
		part_id: 372,
		quantity: 199,
	},
	{
		garage_id: 217,
		part_id: 373,
		quantity: 134,
	},
	{
		garage_id: 217,
		part_id: 374,
		quantity: 335,
	},
	{
		garage_id: 217,
		part_id: 375,
		quantity: 402,
	},
	{
		garage_id: 217,
		part_id: 376,
		quantity: 336,
	},
	{
		garage_id: 217,
		part_id: 377,
		quantity: 264,
	},
	{
		garage_id: 217,
		part_id: 378,
		quantity: 17,
	},
	{
		garage_id: 217,
		part_id: 379,
		quantity: 159,
	},
	{
		garage_id: 217,
		part_id: 380,
		quantity: 348,
	},
	{
		garage_id: 217,
		part_id: 381,
		quantity: 469,
	},
	{
		garage_id: 217,
		part_id: 382,
		quantity: 487,
	},
	{
		garage_id: 217,
		part_id: 383,
		quantity: 267,
	},
	{
		garage_id: 217,
		part_id: 384,
		quantity: 303,
	},
	{
		garage_id: 217,
		part_id: 385,
		quantity: 228,
	},
	{
		garage_id: 217,
		part_id: 386,
		quantity: 174,
	},
	{
		garage_id: 217,
		part_id: 387,
		quantity: 223,
	},
	{
		garage_id: 217,
		part_id: 388,
		quantity: 429,
	},
	{
		garage_id: 217,
		part_id: 389,
		quantity: 281,
	},
	{
		garage_id: 217,
		part_id: 390,
		quantity: 490,
	},
	{
		garage_id: 217,
		part_id: 391,
		quantity: 435,
	},
	{
		garage_id: 217,
		part_id: 392,
		quantity: 269,
	},
	{
		garage_id: 217,
		part_id: 393,
		quantity: 37,
	},
	{
		garage_id: 217,
		part_id: 394,
		quantity: 36,
	},
	{
		garage_id: 217,
		part_id: 395,
		quantity: 487,
	},
	{
		garage_id: 217,
		part_id: 396,
		quantity: 0,
	},
	{
		garage_id: 217,
		part_id: 397,
		quantity: 305,
	},
	{
		garage_id: 217,
		part_id: 398,
		quantity: 218,
	},
	{
		garage_id: 217,
		part_id: 399,
		quantity: 371,
	},
	{
		garage_id: 218,
		part_id: 0,
		quantity: 89,
	},
	{
		garage_id: 218,
		part_id: 1,
		quantity: 72,
	},
	{
		garage_id: 218,
		part_id: 2,
		quantity: 253,
	},
	{
		garage_id: 218,
		part_id: 3,
		quantity: 111,
	},
	{
		garage_id: 218,
		part_id: 4,
		quantity: 108,
	},
	{
		garage_id: 218,
		part_id: 5,
		quantity: 401,
	},
	{
		garage_id: 218,
		part_id: 6,
		quantity: 223,
	},
	{
		garage_id: 218,
		part_id: 7,
		quantity: 487,
	},
	{
		garage_id: 218,
		part_id: 8,
		quantity: 463,
	},
	{
		garage_id: 218,
		part_id: 9,
		quantity: 115,
	},
	{
		garage_id: 218,
		part_id: 10,
		quantity: 130,
	},
	{
		garage_id: 218,
		part_id: 11,
		quantity: 230,
	},
	{
		garage_id: 218,
		part_id: 12,
		quantity: 115,
	},
	{
		garage_id: 218,
		part_id: 13,
		quantity: 234,
	},
	{
		garage_id: 218,
		part_id: 14,
		quantity: 47,
	},
	{
		garage_id: 218,
		part_id: 15,
		quantity: 39,
	},
	{
		garage_id: 218,
		part_id: 16,
		quantity: 145,
	},
	{
		garage_id: 218,
		part_id: 17,
		quantity: 164,
	},
	{
		garage_id: 218,
		part_id: 18,
		quantity: 43,
	},
	{
		garage_id: 218,
		part_id: 19,
		quantity: 337,
	},
	{
		garage_id: 218,
		part_id: 20,
		quantity: 337,
	},
	{
		garage_id: 218,
		part_id: 21,
		quantity: 316,
	},
	{
		garage_id: 218,
		part_id: 22,
		quantity: 82,
	},
	{
		garage_id: 218,
		part_id: 23,
		quantity: 315,
	},
	{
		garage_id: 218,
		part_id: 24,
		quantity: 128,
	},
	{
		garage_id: 218,
		part_id: 25,
		quantity: 339,
	},
	{
		garage_id: 218,
		part_id: 26,
		quantity: 315,
	},
	{
		garage_id: 218,
		part_id: 27,
		quantity: 406,
	},
	{
		garage_id: 218,
		part_id: 28,
		quantity: 207,
	},
	{
		garage_id: 218,
		part_id: 29,
		quantity: 88,
	},
	{
		garage_id: 218,
		part_id: 30,
		quantity: 44,
	},
	{
		garage_id: 218,
		part_id: 31,
		quantity: 120,
	},
	{
		garage_id: 218,
		part_id: 32,
		quantity: 245,
	},
	{
		garage_id: 218,
		part_id: 33,
		quantity: 412,
	},
	{
		garage_id: 218,
		part_id: 34,
		quantity: 270,
	},
	{
		garage_id: 218,
		part_id: 35,
		quantity: 110,
	},
	{
		garage_id: 218,
		part_id: 36,
		quantity: 198,
	},
	{
		garage_id: 218,
		part_id: 37,
		quantity: 294,
	},
	{
		garage_id: 218,
		part_id: 38,
		quantity: 26,
	},
	{
		garage_id: 218,
		part_id: 39,
		quantity: 225,
	},
	{
		garage_id: 218,
		part_id: 40,
		quantity: 136,
	},
	{
		garage_id: 218,
		part_id: 41,
		quantity: 160,
	},
	{
		garage_id: 218,
		part_id: 42,
		quantity: 365,
	},
	{
		garage_id: 218,
		part_id: 43,
		quantity: 230,
	},
	{
		garage_id: 218,
		part_id: 44,
		quantity: 25,
	},
	{
		garage_id: 218,
		part_id: 45,
		quantity: 296,
	},
	{
		garage_id: 218,
		part_id: 46,
		quantity: 71,
	},
	{
		garage_id: 218,
		part_id: 47,
		quantity: 412,
	},
	{
		garage_id: 218,
		part_id: 48,
		quantity: 389,
	},
	{
		garage_id: 218,
		part_id: 49,
		quantity: 195,
	},
	{
		garage_id: 218,
		part_id: 50,
		quantity: 196,
	},
	{
		garage_id: 218,
		part_id: 51,
		quantity: 83,
	},
	{
		garage_id: 218,
		part_id: 52,
		quantity: 476,
	},
	{
		garage_id: 218,
		part_id: 53,
		quantity: 12,
	},
	{
		garage_id: 218,
		part_id: 54,
		quantity: 213,
	},
	{
		garage_id: 218,
		part_id: 55,
		quantity: 96,
	},
	{
		garage_id: 218,
		part_id: 56,
		quantity: 432,
	},
	{
		garage_id: 218,
		part_id: 57,
		quantity: 80,
	},
	{
		garage_id: 218,
		part_id: 58,
		quantity: 353,
	},
	{
		garage_id: 218,
		part_id: 59,
		quantity: 155,
	},
	{
		garage_id: 218,
		part_id: 60,
		quantity: 488,
	},
	{
		garage_id: 218,
		part_id: 61,
		quantity: 424,
	},
	{
		garage_id: 218,
		part_id: 62,
		quantity: 207,
	},
	{
		garage_id: 218,
		part_id: 63,
		quantity: 114,
	},
	{
		garage_id: 218,
		part_id: 64,
		quantity: 493,
	},
	{
		garage_id: 218,
		part_id: 65,
		quantity: 135,
	},
	{
		garage_id: 218,
		part_id: 66,
		quantity: 54,
	},
	{
		garage_id: 218,
		part_id: 67,
		quantity: 345,
	},
	{
		garage_id: 218,
		part_id: 68,
		quantity: 171,
	},
	{
		garage_id: 218,
		part_id: 69,
		quantity: 244,
	},
	{
		garage_id: 218,
		part_id: 70,
		quantity: 483,
	},
	{
		garage_id: 218,
		part_id: 71,
		quantity: 352,
	},
	{
		garage_id: 218,
		part_id: 72,
		quantity: 187,
	},
	{
		garage_id: 218,
		part_id: 73,
		quantity: 53,
	},
	{
		garage_id: 218,
		part_id: 74,
		quantity: 245,
	},
	{
		garage_id: 218,
		part_id: 75,
		quantity: 365,
	},
	{
		garage_id: 218,
		part_id: 76,
		quantity: 230,
	},
	{
		garage_id: 218,
		part_id: 77,
		quantity: 220,
	},
	{
		garage_id: 218,
		part_id: 78,
		quantity: 292,
	},
	{
		garage_id: 218,
		part_id: 79,
		quantity: 75,
	},
	{
		garage_id: 218,
		part_id: 80,
		quantity: 333,
	},
	{
		garage_id: 218,
		part_id: 81,
		quantity: 116,
	},
	{
		garage_id: 218,
		part_id: 82,
		quantity: 101,
	},
	{
		garage_id: 218,
		part_id: 83,
		quantity: 25,
	},
	{
		garage_id: 218,
		part_id: 84,
		quantity: 216,
	},
	{
		garage_id: 218,
		part_id: 85,
		quantity: 250,
	},
	{
		garage_id: 218,
		part_id: 86,
		quantity: 69,
	},
	{
		garage_id: 218,
		part_id: 87,
		quantity: 245,
	},
	{
		garage_id: 218,
		part_id: 88,
		quantity: 34,
	},
	{
		garage_id: 218,
		part_id: 89,
		quantity: 475,
	},
	{
		garage_id: 218,
		part_id: 90,
		quantity: 135,
	},
	{
		garage_id: 218,
		part_id: 91,
		quantity: 3,
	},
	{
		garage_id: 218,
		part_id: 92,
		quantity: 485,
	},
	{
		garage_id: 218,
		part_id: 93,
		quantity: 241,
	},
	{
		garage_id: 218,
		part_id: 94,
		quantity: 236,
	},
	{
		garage_id: 218,
		part_id: 95,
		quantity: 76,
	},
	{
		garage_id: 218,
		part_id: 96,
		quantity: 156,
	},
	{
		garage_id: 218,
		part_id: 97,
		quantity: 218,
	},
	{
		garage_id: 218,
		part_id: 98,
		quantity: 391,
	},
	{
		garage_id: 218,
		part_id: 99,
		quantity: 274,
	},
	{
		garage_id: 218,
		part_id: 100,
		quantity: 129,
	},
	{
		garage_id: 218,
		part_id: 101,
		quantity: 1,
	},
	{
		garage_id: 218,
		part_id: 102,
		quantity: 287,
	},
	{
		garage_id: 218,
		part_id: 103,
		quantity: 371,
	},
	{
		garage_id: 218,
		part_id: 104,
		quantity: 368,
	},
	{
		garage_id: 218,
		part_id: 105,
		quantity: 139,
	},
	{
		garage_id: 218,
		part_id: 106,
		quantity: 294,
	},
	{
		garage_id: 218,
		part_id: 107,
		quantity: 155,
	},
	{
		garage_id: 218,
		part_id: 108,
		quantity: 463,
	},
	{
		garage_id: 218,
		part_id: 109,
		quantity: 174,
	},
	{
		garage_id: 218,
		part_id: 110,
		quantity: 422,
	},
	{
		garage_id: 218,
		part_id: 111,
		quantity: 190,
	},
	{
		garage_id: 218,
		part_id: 112,
		quantity: 41,
	},
	{
		garage_id: 218,
		part_id: 113,
		quantity: 218,
	},
	{
		garage_id: 218,
		part_id: 114,
		quantity: 344,
	},
	{
		garage_id: 218,
		part_id: 115,
		quantity: 235,
	},
	{
		garage_id: 218,
		part_id: 116,
		quantity: 171,
	},
	{
		garage_id: 218,
		part_id: 117,
		quantity: 110,
	},
	{
		garage_id: 218,
		part_id: 118,
		quantity: 345,
	},
	{
		garage_id: 218,
		part_id: 119,
		quantity: 1,
	},
	{
		garage_id: 218,
		part_id: 120,
		quantity: 347,
	},
	{
		garage_id: 218,
		part_id: 121,
		quantity: 336,
	},
	{
		garage_id: 218,
		part_id: 122,
		quantity: 410,
	},
	{
		garage_id: 218,
		part_id: 123,
		quantity: 429,
	},
	{
		garage_id: 218,
		part_id: 124,
		quantity: 150,
	},
	{
		garage_id: 218,
		part_id: 125,
		quantity: 159,
	},
	{
		garage_id: 218,
		part_id: 126,
		quantity: 122,
	},
	{
		garage_id: 218,
		part_id: 127,
		quantity: 434,
	},
	{
		garage_id: 218,
		part_id: 128,
		quantity: 314,
	},
	{
		garage_id: 218,
		part_id: 129,
		quantity: 69,
	},
	{
		garage_id: 218,
		part_id: 130,
		quantity: 281,
	},
	{
		garage_id: 218,
		part_id: 131,
		quantity: 320,
	},
	{
		garage_id: 218,
		part_id: 132,
		quantity: 184,
	},
	{
		garage_id: 218,
		part_id: 133,
		quantity: 151,
	},
	{
		garage_id: 218,
		part_id: 134,
		quantity: 88,
	},
	{
		garage_id: 218,
		part_id: 135,
		quantity: 379,
	},
	{
		garage_id: 218,
		part_id: 136,
		quantity: 457,
	},
	{
		garage_id: 218,
		part_id: 137,
		quantity: 262,
	},
	{
		garage_id: 218,
		part_id: 138,
		quantity: 421,
	},
	{
		garage_id: 218,
		part_id: 139,
		quantity: 366,
	},
	{
		garage_id: 218,
		part_id: 140,
		quantity: 211,
	},
	{
		garage_id: 218,
		part_id: 141,
		quantity: 176,
	},
	{
		garage_id: 218,
		part_id: 142,
		quantity: 478,
	},
	{
		garage_id: 218,
		part_id: 143,
		quantity: 121,
	},
	{
		garage_id: 218,
		part_id: 144,
		quantity: 462,
	},
	{
		garage_id: 218,
		part_id: 145,
		quantity: 272,
	},
	{
		garage_id: 218,
		part_id: 146,
		quantity: 110,
	},
	{
		garage_id: 218,
		part_id: 147,
		quantity: 417,
	},
	{
		garage_id: 218,
		part_id: 148,
		quantity: 286,
	},
	{
		garage_id: 218,
		part_id: 149,
		quantity: 39,
	},
	{
		garage_id: 218,
		part_id: 150,
		quantity: 374,
	},
	{
		garage_id: 218,
		part_id: 151,
		quantity: 283,
	},
	{
		garage_id: 218,
		part_id: 152,
		quantity: 80,
	},
	{
		garage_id: 218,
		part_id: 153,
		quantity: 238,
	},
	{
		garage_id: 218,
		part_id: 154,
		quantity: 301,
	},
	{
		garage_id: 218,
		part_id: 155,
		quantity: 177,
	},
	{
		garage_id: 218,
		part_id: 156,
		quantity: 130,
	},
	{
		garage_id: 218,
		part_id: 157,
		quantity: 328,
	},
	{
		garage_id: 218,
		part_id: 158,
		quantity: 236,
	},
	{
		garage_id: 218,
		part_id: 159,
		quantity: 169,
	},
	{
		garage_id: 218,
		part_id: 160,
		quantity: 200,
	},
	{
		garage_id: 218,
		part_id: 161,
		quantity: 36,
	},
	{
		garage_id: 218,
		part_id: 162,
		quantity: 244,
	},
	{
		garage_id: 218,
		part_id: 163,
		quantity: 194,
	},
	{
		garage_id: 218,
		part_id: 164,
		quantity: 491,
	},
	{
		garage_id: 218,
		part_id: 165,
		quantity: 302,
	},
	{
		garage_id: 218,
		part_id: 166,
		quantity: 98,
	},
	{
		garage_id: 218,
		part_id: 167,
		quantity: 316,
	},
	{
		garage_id: 218,
		part_id: 168,
		quantity: 287,
	},
	{
		garage_id: 218,
		part_id: 169,
		quantity: 276,
	},
	{
		garage_id: 218,
		part_id: 170,
		quantity: 5,
	},
	{
		garage_id: 218,
		part_id: 171,
		quantity: 348,
	},
	{
		garage_id: 218,
		part_id: 172,
		quantity: 171,
	},
	{
		garage_id: 218,
		part_id: 173,
		quantity: 259,
	},
	{
		garage_id: 218,
		part_id: 174,
		quantity: 462,
	},
	{
		garage_id: 218,
		part_id: 175,
		quantity: 246,
	},
	{
		garage_id: 218,
		part_id: 176,
		quantity: 282,
	},
	{
		garage_id: 218,
		part_id: 177,
		quantity: 47,
	},
	{
		garage_id: 218,
		part_id: 178,
		quantity: 31,
	},
	{
		garage_id: 218,
		part_id: 179,
		quantity: 337,
	},
	{
		garage_id: 218,
		part_id: 180,
		quantity: 104,
	},
	{
		garage_id: 218,
		part_id: 181,
		quantity: 51,
	},
	{
		garage_id: 218,
		part_id: 182,
		quantity: 289,
	},
	{
		garage_id: 218,
		part_id: 183,
		quantity: 489,
	},
	{
		garage_id: 218,
		part_id: 184,
		quantity: 344,
	},
	{
		garage_id: 218,
		part_id: 185,
		quantity: 494,
	},
	{
		garage_id: 218,
		part_id: 186,
		quantity: 272,
	},
	{
		garage_id: 218,
		part_id: 187,
		quantity: 347,
	},
	{
		garage_id: 218,
		part_id: 188,
		quantity: 98,
	},
	{
		garage_id: 218,
		part_id: 189,
		quantity: 211,
	},
	{
		garage_id: 218,
		part_id: 190,
		quantity: 101,
	},
	{
		garage_id: 218,
		part_id: 191,
		quantity: 217,
	},
	{
		garage_id: 218,
		part_id: 192,
		quantity: 309,
	},
	{
		garage_id: 218,
		part_id: 193,
		quantity: 27,
	},
	{
		garage_id: 218,
		part_id: 194,
		quantity: 356,
	},
	{
		garage_id: 218,
		part_id: 195,
		quantity: 77,
	},
	{
		garage_id: 218,
		part_id: 196,
		quantity: 330,
	},
	{
		garage_id: 218,
		part_id: 197,
		quantity: 259,
	},
	{
		garage_id: 218,
		part_id: 198,
		quantity: 21,
	},
	{
		garage_id: 218,
		part_id: 199,
		quantity: 149,
	},
	{
		garage_id: 218,
		part_id: 200,
		quantity: 285,
	},
	{
		garage_id: 218,
		part_id: 201,
		quantity: 137,
	},
	{
		garage_id: 218,
		part_id: 202,
		quantity: 180,
	},
	{
		garage_id: 218,
		part_id: 203,
		quantity: 273,
	},
	{
		garage_id: 218,
		part_id: 204,
		quantity: 117,
	},
	{
		garage_id: 218,
		part_id: 205,
		quantity: 107,
	},
	{
		garage_id: 218,
		part_id: 206,
		quantity: 416,
	},
	{
		garage_id: 218,
		part_id: 207,
		quantity: 434,
	},
	{
		garage_id: 218,
		part_id: 208,
		quantity: 300,
	},
	{
		garage_id: 218,
		part_id: 209,
		quantity: 180,
	},
	{
		garage_id: 218,
		part_id: 210,
		quantity: 255,
	},
	{
		garage_id: 218,
		part_id: 211,
		quantity: 207,
	},
	{
		garage_id: 218,
		part_id: 212,
		quantity: 289,
	},
	{
		garage_id: 218,
		part_id: 213,
		quantity: 467,
	},
	{
		garage_id: 218,
		part_id: 214,
		quantity: 429,
	},
	{
		garage_id: 218,
		part_id: 215,
		quantity: 370,
	},
	{
		garage_id: 218,
		part_id: 216,
		quantity: 108,
	},
	{
		garage_id: 218,
		part_id: 217,
		quantity: 235,
	},
	{
		garage_id: 218,
		part_id: 218,
		quantity: 138,
	},
	{
		garage_id: 218,
		part_id: 219,
		quantity: 301,
	},
	{
		garage_id: 218,
		part_id: 220,
		quantity: 340,
	},
	{
		garage_id: 218,
		part_id: 221,
		quantity: 164,
	},
	{
		garage_id: 218,
		part_id: 222,
		quantity: 306,
	},
	{
		garage_id: 218,
		part_id: 223,
		quantity: 418,
	},
	{
		garage_id: 218,
		part_id: 224,
		quantity: 207,
	},
	{
		garage_id: 218,
		part_id: 225,
		quantity: 31,
	},
	{
		garage_id: 218,
		part_id: 226,
		quantity: 320,
	},
	{
		garage_id: 218,
		part_id: 227,
		quantity: 118,
	},
	{
		garage_id: 218,
		part_id: 228,
		quantity: 387,
	},
	{
		garage_id: 218,
		part_id: 229,
		quantity: 412,
	},
	{
		garage_id: 218,
		part_id: 230,
		quantity: 292,
	},
	{
		garage_id: 218,
		part_id: 231,
		quantity: 296,
	},
	{
		garage_id: 218,
		part_id: 232,
		quantity: 448,
	},
	{
		garage_id: 218,
		part_id: 233,
		quantity: 229,
	},
	{
		garage_id: 218,
		part_id: 234,
		quantity: 141,
	},
	{
		garage_id: 218,
		part_id: 235,
		quantity: 330,
	},
	{
		garage_id: 218,
		part_id: 236,
		quantity: 372,
	},
	{
		garage_id: 218,
		part_id: 237,
		quantity: 240,
	},
	{
		garage_id: 218,
		part_id: 238,
		quantity: 209,
	},
	{
		garage_id: 218,
		part_id: 239,
		quantity: 294,
	},
	{
		garage_id: 218,
		part_id: 240,
		quantity: 378,
	},
	{
		garage_id: 218,
		part_id: 241,
		quantity: 94,
	},
	{
		garage_id: 218,
		part_id: 242,
		quantity: 355,
	},
	{
		garage_id: 218,
		part_id: 243,
		quantity: 21,
	},
	{
		garage_id: 218,
		part_id: 244,
		quantity: 294,
	},
	{
		garage_id: 218,
		part_id: 245,
		quantity: 449,
	},
	{
		garage_id: 218,
		part_id: 246,
		quantity: 205,
	},
	{
		garage_id: 218,
		part_id: 247,
		quantity: 110,
	},
	{
		garage_id: 218,
		part_id: 248,
		quantity: 496,
	},
	{
		garage_id: 218,
		part_id: 249,
		quantity: 129,
	},
	{
		garage_id: 218,
		part_id: 250,
		quantity: 225,
	},
	{
		garage_id: 218,
		part_id: 251,
		quantity: 130,
	},
	{
		garage_id: 218,
		part_id: 252,
		quantity: 452,
	},
	{
		garage_id: 218,
		part_id: 253,
		quantity: 87,
	},
	{
		garage_id: 218,
		part_id: 254,
		quantity: 329,
	},
	{
		garage_id: 218,
		part_id: 255,
		quantity: 441,
	},
	{
		garage_id: 218,
		part_id: 256,
		quantity: 252,
	},
	{
		garage_id: 218,
		part_id: 257,
		quantity: 429,
	},
	{
		garage_id: 218,
		part_id: 258,
		quantity: 78,
	},
	{
		garage_id: 218,
		part_id: 259,
		quantity: 18,
	},
	{
		garage_id: 218,
		part_id: 260,
		quantity: 414,
	},
	{
		garage_id: 218,
		part_id: 261,
		quantity: 52,
	},
	{
		garage_id: 218,
		part_id: 262,
		quantity: 482,
	},
	{
		garage_id: 218,
		part_id: 263,
		quantity: 58,
	},
	{
		garage_id: 218,
		part_id: 264,
		quantity: 180,
	},
	{
		garage_id: 218,
		part_id: 265,
		quantity: 113,
	},
	{
		garage_id: 218,
		part_id: 266,
		quantity: 204,
	},
	{
		garage_id: 218,
		part_id: 267,
		quantity: 42,
	},
	{
		garage_id: 218,
		part_id: 268,
		quantity: 454,
	},
	{
		garage_id: 218,
		part_id: 269,
		quantity: 401,
	},
	{
		garage_id: 218,
		part_id: 270,
		quantity: 13,
	},
	{
		garage_id: 218,
		part_id: 271,
		quantity: 34,
	},
	{
		garage_id: 218,
		part_id: 272,
		quantity: 423,
	},
	{
		garage_id: 218,
		part_id: 273,
		quantity: 483,
	},
	{
		garage_id: 218,
		part_id: 274,
		quantity: 14,
	},
	{
		garage_id: 218,
		part_id: 275,
		quantity: 105,
	},
	{
		garage_id: 218,
		part_id: 276,
		quantity: 298,
	},
	{
		garage_id: 218,
		part_id: 277,
		quantity: 168,
	},
	{
		garage_id: 218,
		part_id: 278,
		quantity: 344,
	},
	{
		garage_id: 218,
		part_id: 279,
		quantity: 359,
	},
	{
		garage_id: 218,
		part_id: 280,
		quantity: 221,
	},
	{
		garage_id: 218,
		part_id: 281,
		quantity: 394,
	},
	{
		garage_id: 218,
		part_id: 282,
		quantity: 80,
	},
	{
		garage_id: 218,
		part_id: 283,
		quantity: 270,
	},
	{
		garage_id: 218,
		part_id: 284,
		quantity: 211,
	},
	{
		garage_id: 218,
		part_id: 285,
		quantity: 334,
	},
	{
		garage_id: 218,
		part_id: 286,
		quantity: 247,
	},
	{
		garage_id: 218,
		part_id: 287,
		quantity: 225,
	},
	{
		garage_id: 218,
		part_id: 288,
		quantity: 427,
	},
	{
		garage_id: 218,
		part_id: 289,
		quantity: 47,
	},
	{
		garage_id: 218,
		part_id: 290,
		quantity: 176,
	},
	{
		garage_id: 218,
		part_id: 291,
		quantity: 432,
	},
	{
		garage_id: 218,
		part_id: 292,
		quantity: 154,
	},
	{
		garage_id: 218,
		part_id: 293,
		quantity: 305,
	},
	{
		garage_id: 218,
		part_id: 294,
		quantity: 57,
	},
	{
		garage_id: 218,
		part_id: 295,
		quantity: 73,
	},
	{
		garage_id: 218,
		part_id: 296,
		quantity: 258,
	},
	{
		garage_id: 218,
		part_id: 297,
		quantity: 207,
	},
	{
		garage_id: 218,
		part_id: 298,
		quantity: 163,
	},
	{
		garage_id: 218,
		part_id: 299,
		quantity: 221,
	},
	{
		garage_id: 218,
		part_id: 300,
		quantity: 349,
	},
	{
		garage_id: 218,
		part_id: 301,
		quantity: 336,
	},
	{
		garage_id: 218,
		part_id: 302,
		quantity: 240,
	},
	{
		garage_id: 218,
		part_id: 303,
		quantity: 493,
	},
	{
		garage_id: 218,
		part_id: 304,
		quantity: 111,
	},
	{
		garage_id: 218,
		part_id: 305,
		quantity: 483,
	},
	{
		garage_id: 218,
		part_id: 306,
		quantity: 425,
	},
	{
		garage_id: 218,
		part_id: 307,
		quantity: 495,
	},
	{
		garage_id: 218,
		part_id: 308,
		quantity: 268,
	},
	{
		garage_id: 218,
		part_id: 309,
		quantity: 186,
	},
	{
		garage_id: 218,
		part_id: 310,
		quantity: 96,
	},
	{
		garage_id: 218,
		part_id: 311,
		quantity: 104,
	},
	{
		garage_id: 218,
		part_id: 312,
		quantity: 39,
	},
	{
		garage_id: 218,
		part_id: 313,
		quantity: 113,
	},
	{
		garage_id: 218,
		part_id: 314,
		quantity: 463,
	},
	{
		garage_id: 218,
		part_id: 315,
		quantity: 459,
	},
	{
		garage_id: 218,
		part_id: 316,
		quantity: 227,
	},
	{
		garage_id: 218,
		part_id: 317,
		quantity: 248,
	},
	{
		garage_id: 218,
		part_id: 318,
		quantity: 89,
	},
	{
		garage_id: 218,
		part_id: 319,
		quantity: 82,
	},
	{
		garage_id: 218,
		part_id: 320,
		quantity: 211,
	},
	{
		garage_id: 218,
		part_id: 321,
		quantity: 112,
	},
	{
		garage_id: 218,
		part_id: 322,
		quantity: 2,
	},
	{
		garage_id: 218,
		part_id: 323,
		quantity: 99,
	},
	{
		garage_id: 218,
		part_id: 324,
		quantity: 307,
	},
	{
		garage_id: 218,
		part_id: 325,
		quantity: 436,
	},
	{
		garage_id: 218,
		part_id: 326,
		quantity: 120,
	},
	{
		garage_id: 218,
		part_id: 327,
		quantity: 131,
	},
	{
		garage_id: 218,
		part_id: 328,
		quantity: 422,
	},
	{
		garage_id: 218,
		part_id: 329,
		quantity: 452,
	},
	{
		garage_id: 218,
		part_id: 330,
		quantity: 5,
	},
	{
		garage_id: 218,
		part_id: 331,
		quantity: 239,
	},
	{
		garage_id: 218,
		part_id: 332,
		quantity: 110,
	},
	{
		garage_id: 218,
		part_id: 333,
		quantity: 248,
	},
	{
		garage_id: 218,
		part_id: 334,
		quantity: 110,
	},
	{
		garage_id: 218,
		part_id: 335,
		quantity: 344,
	},
	{
		garage_id: 218,
		part_id: 336,
		quantity: 175,
	},
	{
		garage_id: 218,
		part_id: 337,
		quantity: 260,
	},
	{
		garage_id: 218,
		part_id: 338,
		quantity: 477,
	},
	{
		garage_id: 218,
		part_id: 339,
		quantity: 443,
	},
	{
		garage_id: 218,
		part_id: 340,
		quantity: 117,
	},
	{
		garage_id: 218,
		part_id: 341,
		quantity: 494,
	},
	{
		garage_id: 218,
		part_id: 342,
		quantity: 284,
	},
	{
		garage_id: 218,
		part_id: 343,
		quantity: 432,
	},
	{
		garage_id: 218,
		part_id: 344,
		quantity: 421,
	},
	{
		garage_id: 218,
		part_id: 345,
		quantity: 321,
	},
	{
		garage_id: 218,
		part_id: 346,
		quantity: 344,
	},
	{
		garage_id: 218,
		part_id: 347,
		quantity: 491,
	},
	{
		garage_id: 218,
		part_id: 348,
		quantity: 190,
	},
	{
		garage_id: 218,
		part_id: 349,
		quantity: 496,
	},
	{
		garage_id: 218,
		part_id: 350,
		quantity: 92,
	},
	{
		garage_id: 218,
		part_id: 351,
		quantity: 367,
	},
	{
		garage_id: 218,
		part_id: 352,
		quantity: 428,
	},
	{
		garage_id: 218,
		part_id: 353,
		quantity: 137,
	},
	{
		garage_id: 218,
		part_id: 354,
		quantity: 7,
	},
	{
		garage_id: 218,
		part_id: 355,
		quantity: 216,
	},
	{
		garage_id: 218,
		part_id: 356,
		quantity: 470,
	},
	{
		garage_id: 218,
		part_id: 357,
		quantity: 78,
	},
	{
		garage_id: 218,
		part_id: 358,
		quantity: 134,
	},
	{
		garage_id: 218,
		part_id: 359,
		quantity: 163,
	},
	{
		garage_id: 218,
		part_id: 360,
		quantity: 464,
	},
	{
		garage_id: 218,
		part_id: 361,
		quantity: 57,
	},
	{
		garage_id: 218,
		part_id: 362,
		quantity: 159,
	},
	{
		garage_id: 218,
		part_id: 363,
		quantity: 281,
	},
	{
		garage_id: 218,
		part_id: 364,
		quantity: 420,
	},
	{
		garage_id: 218,
		part_id: 365,
		quantity: 393,
	},
	{
		garage_id: 218,
		part_id: 366,
		quantity: 16,
	},
	{
		garage_id: 218,
		part_id: 367,
		quantity: 217,
	},
	{
		garage_id: 218,
		part_id: 368,
		quantity: 279,
	},
	{
		garage_id: 218,
		part_id: 369,
		quantity: 75,
	},
	{
		garage_id: 218,
		part_id: 370,
		quantity: 492,
	},
	{
		garage_id: 218,
		part_id: 371,
		quantity: 336,
	},
	{
		garage_id: 218,
		part_id: 372,
		quantity: 178,
	},
	{
		garage_id: 218,
		part_id: 373,
		quantity: 463,
	},
	{
		garage_id: 218,
		part_id: 374,
		quantity: 191,
	},
	{
		garage_id: 218,
		part_id: 375,
		quantity: 359,
	},
	{
		garage_id: 218,
		part_id: 376,
		quantity: 71,
	},
	{
		garage_id: 218,
		part_id: 377,
		quantity: 147,
	},
	{
		garage_id: 218,
		part_id: 378,
		quantity: 373,
	},
	{
		garage_id: 218,
		part_id: 379,
		quantity: 258,
	},
	{
		garage_id: 218,
		part_id: 380,
		quantity: 208,
	},
	{
		garage_id: 218,
		part_id: 381,
		quantity: 64,
	},
	{
		garage_id: 218,
		part_id: 382,
		quantity: 414,
	},
	{
		garage_id: 218,
		part_id: 383,
		quantity: 382,
	},
	{
		garage_id: 218,
		part_id: 384,
		quantity: 123,
	},
	{
		garage_id: 218,
		part_id: 385,
		quantity: 462,
	},
	{
		garage_id: 218,
		part_id: 386,
		quantity: 191,
	},
	{
		garage_id: 218,
		part_id: 387,
		quantity: 313,
	},
	{
		garage_id: 218,
		part_id: 388,
		quantity: 112,
	},
	{
		garage_id: 218,
		part_id: 389,
		quantity: 495,
	},
	{
		garage_id: 218,
		part_id: 390,
		quantity: 313,
	},
	{
		garage_id: 218,
		part_id: 391,
		quantity: 25,
	},
	{
		garage_id: 218,
		part_id: 392,
		quantity: 266,
	},
	{
		garage_id: 218,
		part_id: 393,
		quantity: 419,
	},
	{
		garage_id: 218,
		part_id: 394,
		quantity: 180,
	},
	{
		garage_id: 218,
		part_id: 395,
		quantity: 351,
	},
	{
		garage_id: 218,
		part_id: 396,
		quantity: 101,
	},
	{
		garage_id: 218,
		part_id: 397,
		quantity: 165,
	},
	{
		garage_id: 218,
		part_id: 398,
		quantity: 251,
	},
	{
		garage_id: 218,
		part_id: 399,
		quantity: 48,
	},
	{
		garage_id: 219,
		part_id: 0,
		quantity: 468,
	},
	{
		garage_id: 219,
		part_id: 1,
		quantity: 151,
	},
	{
		garage_id: 219,
		part_id: 2,
		quantity: 11,
	},
	{
		garage_id: 219,
		part_id: 3,
		quantity: 18,
	},
	{
		garage_id: 219,
		part_id: 4,
		quantity: 289,
	},
	{
		garage_id: 219,
		part_id: 5,
		quantity: 194,
	},
	{
		garage_id: 219,
		part_id: 6,
		quantity: 150,
	},
	{
		garage_id: 219,
		part_id: 7,
		quantity: 461,
	},
	{
		garage_id: 219,
		part_id: 8,
		quantity: 243,
	},
	{
		garage_id: 219,
		part_id: 9,
		quantity: 495,
	},
	{
		garage_id: 219,
		part_id: 10,
		quantity: 149,
	},
	{
		garage_id: 219,
		part_id: 11,
		quantity: 118,
	},
	{
		garage_id: 219,
		part_id: 12,
		quantity: 359,
	},
	{
		garage_id: 219,
		part_id: 13,
		quantity: 137,
	},
	{
		garage_id: 219,
		part_id: 14,
		quantity: 53,
	},
	{
		garage_id: 219,
		part_id: 15,
		quantity: 229,
	},
	{
		garage_id: 219,
		part_id: 16,
		quantity: 57,
	},
	{
		garage_id: 219,
		part_id: 17,
		quantity: 111,
	},
	{
		garage_id: 219,
		part_id: 18,
		quantity: 238,
	},
	{
		garage_id: 219,
		part_id: 19,
		quantity: 232,
	},
	{
		garage_id: 219,
		part_id: 20,
		quantity: 447,
	},
	{
		garage_id: 219,
		part_id: 21,
		quantity: 103,
	},
	{
		garage_id: 219,
		part_id: 22,
		quantity: 64,
	},
	{
		garage_id: 219,
		part_id: 23,
		quantity: 388,
	},
	{
		garage_id: 219,
		part_id: 24,
		quantity: 189,
	},
	{
		garage_id: 219,
		part_id: 25,
		quantity: 48,
	},
	{
		garage_id: 219,
		part_id: 26,
		quantity: 482,
	},
	{
		garage_id: 219,
		part_id: 27,
		quantity: 446,
	},
	{
		garage_id: 219,
		part_id: 28,
		quantity: 113,
	},
	{
		garage_id: 219,
		part_id: 29,
		quantity: 434,
	},
	{
		garage_id: 219,
		part_id: 30,
		quantity: 160,
	},
	{
		garage_id: 219,
		part_id: 31,
		quantity: 172,
	},
	{
		garage_id: 219,
		part_id: 32,
		quantity: 279,
	},
	{
		garage_id: 219,
		part_id: 33,
		quantity: 447,
	},
	{
		garage_id: 219,
		part_id: 34,
		quantity: 221,
	},
	{
		garage_id: 219,
		part_id: 35,
		quantity: 413,
	},
	{
		garage_id: 219,
		part_id: 36,
		quantity: 129,
	},
	{
		garage_id: 219,
		part_id: 37,
		quantity: 413,
	},
	{
		garage_id: 219,
		part_id: 38,
		quantity: 396,
	},
	{
		garage_id: 219,
		part_id: 39,
		quantity: 383,
	},
	{
		garage_id: 219,
		part_id: 40,
		quantity: 442,
	},
	{
		garage_id: 219,
		part_id: 41,
		quantity: 207,
	},
	{
		garage_id: 219,
		part_id: 42,
		quantity: 364,
	},
	{
		garage_id: 219,
		part_id: 43,
		quantity: 185,
	},
	{
		garage_id: 219,
		part_id: 44,
		quantity: 220,
	},
	{
		garage_id: 219,
		part_id: 45,
		quantity: 469,
	},
	{
		garage_id: 219,
		part_id: 46,
		quantity: 448,
	},
	{
		garage_id: 219,
		part_id: 47,
		quantity: 243,
	},
	{
		garage_id: 219,
		part_id: 48,
		quantity: 476,
	},
	{
		garage_id: 219,
		part_id: 49,
		quantity: 321,
	},
	{
		garage_id: 219,
		part_id: 50,
		quantity: 253,
	},
	{
		garage_id: 219,
		part_id: 51,
		quantity: 290,
	},
	{
		garage_id: 219,
		part_id: 52,
		quantity: 107,
	},
	{
		garage_id: 219,
		part_id: 53,
		quantity: 328,
	},
	{
		garage_id: 219,
		part_id: 54,
		quantity: 266,
	},
	{
		garage_id: 219,
		part_id: 55,
		quantity: 229,
	},
	{
		garage_id: 219,
		part_id: 56,
		quantity: 194,
	},
	{
		garage_id: 219,
		part_id: 57,
		quantity: 90,
	},
	{
		garage_id: 219,
		part_id: 58,
		quantity: 166,
	},
	{
		garage_id: 219,
		part_id: 59,
		quantity: 497,
	},
	{
		garage_id: 219,
		part_id: 60,
		quantity: 420,
	},
	{
		garage_id: 219,
		part_id: 61,
		quantity: 20,
	},
	{
		garage_id: 219,
		part_id: 62,
		quantity: 242,
	},
	{
		garage_id: 219,
		part_id: 63,
		quantity: 18,
	},
	{
		garage_id: 219,
		part_id: 64,
		quantity: 223,
	},
	{
		garage_id: 219,
		part_id: 65,
		quantity: 38,
	},
	{
		garage_id: 219,
		part_id: 66,
		quantity: 400,
	},
	{
		garage_id: 219,
		part_id: 67,
		quantity: 115,
	},
	{
		garage_id: 219,
		part_id: 68,
		quantity: 426,
	},
	{
		garage_id: 219,
		part_id: 69,
		quantity: 338,
	},
	{
		garage_id: 219,
		part_id: 70,
		quantity: 230,
	},
	{
		garage_id: 219,
		part_id: 71,
		quantity: 280,
	},
	{
		garage_id: 219,
		part_id: 72,
		quantity: 158,
	},
	{
		garage_id: 219,
		part_id: 73,
		quantity: 432,
	},
	{
		garage_id: 219,
		part_id: 74,
		quantity: 486,
	},
	{
		garage_id: 219,
		part_id: 75,
		quantity: 81,
	},
	{
		garage_id: 219,
		part_id: 76,
		quantity: 233,
	},
	{
		garage_id: 219,
		part_id: 77,
		quantity: 207,
	},
	{
		garage_id: 219,
		part_id: 78,
		quantity: 94,
	},
	{
		garage_id: 219,
		part_id: 79,
		quantity: 313,
	},
	{
		garage_id: 219,
		part_id: 80,
		quantity: 0,
	},
	{
		garage_id: 219,
		part_id: 81,
		quantity: 488,
	},
	{
		garage_id: 219,
		part_id: 82,
		quantity: 331,
	},
	{
		garage_id: 219,
		part_id: 83,
		quantity: 328,
	},
	{
		garage_id: 219,
		part_id: 84,
		quantity: 258,
	},
	{
		garage_id: 219,
		part_id: 85,
		quantity: 87,
	},
	{
		garage_id: 219,
		part_id: 86,
		quantity: 104,
	},
	{
		garage_id: 219,
		part_id: 87,
		quantity: 237,
	},
	{
		garage_id: 219,
		part_id: 88,
		quantity: 78,
	},
	{
		garage_id: 219,
		part_id: 89,
		quantity: 116,
	},
	{
		garage_id: 219,
		part_id: 90,
		quantity: 191,
	},
	{
		garage_id: 219,
		part_id: 91,
		quantity: 311,
	},
	{
		garage_id: 219,
		part_id: 92,
		quantity: 296,
	},
	{
		garage_id: 219,
		part_id: 93,
		quantity: 221,
	},
	{
		garage_id: 219,
		part_id: 94,
		quantity: 405,
	},
	{
		garage_id: 219,
		part_id: 95,
		quantity: 224,
	},
	{
		garage_id: 219,
		part_id: 96,
		quantity: 380,
	},
	{
		garage_id: 219,
		part_id: 97,
		quantity: 233,
	},
	{
		garage_id: 219,
		part_id: 98,
		quantity: 176,
	},
	{
		garage_id: 219,
		part_id: 99,
		quantity: 108,
	},
	{
		garage_id: 219,
		part_id: 100,
		quantity: 146,
	},
	{
		garage_id: 219,
		part_id: 101,
		quantity: 107,
	},
	{
		garage_id: 219,
		part_id: 102,
		quantity: 206,
	},
	{
		garage_id: 219,
		part_id: 103,
		quantity: 96,
	},
	{
		garage_id: 219,
		part_id: 104,
		quantity: 290,
	},
	{
		garage_id: 219,
		part_id: 105,
		quantity: 148,
	},
	{
		garage_id: 219,
		part_id: 106,
		quantity: 344,
	},
	{
		garage_id: 219,
		part_id: 107,
		quantity: 88,
	},
	{
		garage_id: 219,
		part_id: 108,
		quantity: 289,
	},
	{
		garage_id: 219,
		part_id: 109,
		quantity: 58,
	},
	{
		garage_id: 219,
		part_id: 110,
		quantity: 17,
	},
	{
		garage_id: 219,
		part_id: 111,
		quantity: 196,
	},
	{
		garage_id: 219,
		part_id: 112,
		quantity: 491,
	},
	{
		garage_id: 219,
		part_id: 113,
		quantity: 222,
	},
	{
		garage_id: 219,
		part_id: 114,
		quantity: 469,
	},
	{
		garage_id: 219,
		part_id: 115,
		quantity: 395,
	},
	{
		garage_id: 219,
		part_id: 116,
		quantity: 319,
	},
	{
		garage_id: 219,
		part_id: 117,
		quantity: 297,
	},
	{
		garage_id: 219,
		part_id: 118,
		quantity: 330,
	},
	{
		garage_id: 219,
		part_id: 119,
		quantity: 334,
	},
	{
		garage_id: 219,
		part_id: 120,
		quantity: 2,
	},
	{
		garage_id: 219,
		part_id: 121,
		quantity: 339,
	},
	{
		garage_id: 219,
		part_id: 122,
		quantity: 7,
	},
	{
		garage_id: 219,
		part_id: 123,
		quantity: 305,
	},
	{
		garage_id: 219,
		part_id: 124,
		quantity: 66,
	},
	{
		garage_id: 219,
		part_id: 125,
		quantity: 203,
	},
	{
		garage_id: 219,
		part_id: 126,
		quantity: 0,
	},
	{
		garage_id: 219,
		part_id: 127,
		quantity: 21,
	},
	{
		garage_id: 219,
		part_id: 128,
		quantity: 417,
	},
	{
		garage_id: 219,
		part_id: 129,
		quantity: 337,
	},
	{
		garage_id: 219,
		part_id: 130,
		quantity: 172,
	},
	{
		garage_id: 219,
		part_id: 131,
		quantity: 393,
	},
	{
		garage_id: 219,
		part_id: 132,
		quantity: 119,
	},
	{
		garage_id: 219,
		part_id: 133,
		quantity: 415,
	},
	{
		garage_id: 219,
		part_id: 134,
		quantity: 480,
	},
	{
		garage_id: 219,
		part_id: 135,
		quantity: 464,
	},
	{
		garage_id: 219,
		part_id: 136,
		quantity: 157,
	},
	{
		garage_id: 219,
		part_id: 137,
		quantity: 494,
	},
	{
		garage_id: 219,
		part_id: 138,
		quantity: 313,
	},
	{
		garage_id: 219,
		part_id: 139,
		quantity: 469,
	},
	{
		garage_id: 219,
		part_id: 140,
		quantity: 24,
	},
	{
		garage_id: 219,
		part_id: 141,
		quantity: 497,
	},
	{
		garage_id: 219,
		part_id: 142,
		quantity: 212,
	},
	{
		garage_id: 219,
		part_id: 143,
		quantity: 481,
	},
	{
		garage_id: 219,
		part_id: 144,
		quantity: 23,
	},
	{
		garage_id: 219,
		part_id: 145,
		quantity: 65,
	},
	{
		garage_id: 219,
		part_id: 146,
		quantity: 363,
	},
	{
		garage_id: 219,
		part_id: 147,
		quantity: 1,
	},
	{
		garage_id: 219,
		part_id: 148,
		quantity: 315,
	},
	{
		garage_id: 219,
		part_id: 149,
		quantity: 130,
	},
	{
		garage_id: 219,
		part_id: 150,
		quantity: 411,
	},
	{
		garage_id: 219,
		part_id: 151,
		quantity: 225,
	},
	{
		garage_id: 219,
		part_id: 152,
		quantity: 90,
	},
	{
		garage_id: 219,
		part_id: 153,
		quantity: 478,
	},
	{
		garage_id: 219,
		part_id: 154,
		quantity: 115,
	},
	{
		garage_id: 219,
		part_id: 155,
		quantity: 40,
	},
	{
		garage_id: 219,
		part_id: 156,
		quantity: 414,
	},
	{
		garage_id: 219,
		part_id: 157,
		quantity: 32,
	},
	{
		garage_id: 219,
		part_id: 158,
		quantity: 205,
	},
	{
		garage_id: 219,
		part_id: 159,
		quantity: 326,
	},
	{
		garage_id: 219,
		part_id: 160,
		quantity: 218,
	},
	{
		garage_id: 219,
		part_id: 161,
		quantity: 76,
	},
	{
		garage_id: 219,
		part_id: 162,
		quantity: 255,
	},
	{
		garage_id: 219,
		part_id: 163,
		quantity: 430,
	},
	{
		garage_id: 219,
		part_id: 164,
		quantity: 320,
	},
	{
		garage_id: 219,
		part_id: 165,
		quantity: 54,
	},
	{
		garage_id: 219,
		part_id: 166,
		quantity: 213,
	},
	{
		garage_id: 219,
		part_id: 167,
		quantity: 10,
	},
	{
		garage_id: 219,
		part_id: 168,
		quantity: 451,
	},
	{
		garage_id: 219,
		part_id: 169,
		quantity: 353,
	},
	{
		garage_id: 219,
		part_id: 170,
		quantity: 289,
	},
	{
		garage_id: 219,
		part_id: 171,
		quantity: 300,
	},
	{
		garage_id: 219,
		part_id: 172,
		quantity: 33,
	},
	{
		garage_id: 219,
		part_id: 173,
		quantity: 262,
	},
	{
		garage_id: 219,
		part_id: 174,
		quantity: 72,
	},
	{
		garage_id: 219,
		part_id: 175,
		quantity: 61,
	},
	{
		garage_id: 219,
		part_id: 176,
		quantity: 483,
	},
	{
		garage_id: 219,
		part_id: 177,
		quantity: 70,
	},
	{
		garage_id: 219,
		part_id: 178,
		quantity: 313,
	},
	{
		garage_id: 219,
		part_id: 179,
		quantity: 145,
	},
	{
		garage_id: 219,
		part_id: 180,
		quantity: 421,
	},
	{
		garage_id: 219,
		part_id: 181,
		quantity: 210,
	},
	{
		garage_id: 219,
		part_id: 182,
		quantity: 500,
	},
	{
		garage_id: 219,
		part_id: 183,
		quantity: 52,
	},
	{
		garage_id: 219,
		part_id: 184,
		quantity: 347,
	},
	{
		garage_id: 219,
		part_id: 185,
		quantity: 334,
	},
	{
		garage_id: 219,
		part_id: 186,
		quantity: 449,
	},
	{
		garage_id: 219,
		part_id: 187,
		quantity: 420,
	},
	{
		garage_id: 219,
		part_id: 188,
		quantity: 214,
	},
	{
		garage_id: 219,
		part_id: 189,
		quantity: 115,
	},
	{
		garage_id: 219,
		part_id: 190,
		quantity: 371,
	},
	{
		garage_id: 219,
		part_id: 191,
		quantity: 427,
	},
	{
		garage_id: 219,
		part_id: 192,
		quantity: 78,
	},
	{
		garage_id: 219,
		part_id: 193,
		quantity: 60,
	},
	{
		garage_id: 219,
		part_id: 194,
		quantity: 235,
	},
	{
		garage_id: 219,
		part_id: 195,
		quantity: 425,
	},
	{
		garage_id: 219,
		part_id: 196,
		quantity: 390,
	},
	{
		garage_id: 219,
		part_id: 197,
		quantity: 462,
	},
	{
		garage_id: 219,
		part_id: 198,
		quantity: 158,
	},
	{
		garage_id: 219,
		part_id: 199,
		quantity: 27,
	},
	{
		garage_id: 219,
		part_id: 200,
		quantity: 171,
	},
	{
		garage_id: 219,
		part_id: 201,
		quantity: 425,
	},
	{
		garage_id: 219,
		part_id: 202,
		quantity: 352,
	},
	{
		garage_id: 219,
		part_id: 203,
		quantity: 23,
	},
	{
		garage_id: 219,
		part_id: 204,
		quantity: 188,
	},
	{
		garage_id: 219,
		part_id: 205,
		quantity: 4,
	},
	{
		garage_id: 219,
		part_id: 206,
		quantity: 94,
	},
	{
		garage_id: 219,
		part_id: 207,
		quantity: 269,
	},
	{
		garage_id: 219,
		part_id: 208,
		quantity: 161,
	},
	{
		garage_id: 219,
		part_id: 209,
		quantity: 284,
	},
	{
		garage_id: 219,
		part_id: 210,
		quantity: 235,
	},
	{
		garage_id: 219,
		part_id: 211,
		quantity: 462,
	},
	{
		garage_id: 219,
		part_id: 212,
		quantity: 500,
	},
	{
		garage_id: 219,
		part_id: 213,
		quantity: 480,
	},
	{
		garage_id: 219,
		part_id: 214,
		quantity: 252,
	},
	{
		garage_id: 219,
		part_id: 215,
		quantity: 133,
	},
	{
		garage_id: 219,
		part_id: 216,
		quantity: 266,
	},
	{
		garage_id: 219,
		part_id: 217,
		quantity: 397,
	},
	{
		garage_id: 219,
		part_id: 218,
		quantity: 466,
	},
	{
		garage_id: 219,
		part_id: 219,
		quantity: 140,
	},
	{
		garage_id: 219,
		part_id: 220,
		quantity: 201,
	},
	{
		garage_id: 219,
		part_id: 221,
		quantity: 44,
	},
	{
		garage_id: 219,
		part_id: 222,
		quantity: 163,
	},
	{
		garage_id: 219,
		part_id: 223,
		quantity: 456,
	},
	{
		garage_id: 219,
		part_id: 224,
		quantity: 289,
	},
	{
		garage_id: 219,
		part_id: 225,
		quantity: 315,
	},
	{
		garage_id: 219,
		part_id: 226,
		quantity: 407,
	},
	{
		garage_id: 219,
		part_id: 227,
		quantity: 358,
	},
	{
		garage_id: 219,
		part_id: 228,
		quantity: 251,
	},
	{
		garage_id: 219,
		part_id: 229,
		quantity: 103,
	},
	{
		garage_id: 219,
		part_id: 230,
		quantity: 488,
	},
	{
		garage_id: 219,
		part_id: 231,
		quantity: 156,
	},
	{
		garage_id: 219,
		part_id: 232,
		quantity: 13,
	},
	{
		garage_id: 219,
		part_id: 233,
		quantity: 87,
	},
	{
		garage_id: 219,
		part_id: 234,
		quantity: 278,
	},
	{
		garage_id: 219,
		part_id: 235,
		quantity: 296,
	},
	{
		garage_id: 219,
		part_id: 236,
		quantity: 355,
	},
	{
		garage_id: 219,
		part_id: 237,
		quantity: 77,
	},
	{
		garage_id: 219,
		part_id: 238,
		quantity: 384,
	},
	{
		garage_id: 219,
		part_id: 239,
		quantity: 103,
	},
	{
		garage_id: 219,
		part_id: 240,
		quantity: 21,
	},
	{
		garage_id: 219,
		part_id: 241,
		quantity: 408,
	},
	{
		garage_id: 219,
		part_id: 242,
		quantity: 368,
	},
	{
		garage_id: 219,
		part_id: 243,
		quantity: 117,
	},
	{
		garage_id: 219,
		part_id: 244,
		quantity: 365,
	},
	{
		garage_id: 219,
		part_id: 245,
		quantity: 376,
	},
	{
		garage_id: 219,
		part_id: 246,
		quantity: 264,
	},
	{
		garage_id: 219,
		part_id: 247,
		quantity: 306,
	},
	{
		garage_id: 219,
		part_id: 248,
		quantity: 80,
	},
	{
		garage_id: 219,
		part_id: 249,
		quantity: 463,
	},
	{
		garage_id: 219,
		part_id: 250,
		quantity: 360,
	},
	{
		garage_id: 219,
		part_id: 251,
		quantity: 385,
	},
	{
		garage_id: 219,
		part_id: 252,
		quantity: 410,
	},
	{
		garage_id: 219,
		part_id: 253,
		quantity: 79,
	},
	{
		garage_id: 219,
		part_id: 254,
		quantity: 366,
	},
	{
		garage_id: 219,
		part_id: 255,
		quantity: 417,
	},
	{
		garage_id: 219,
		part_id: 256,
		quantity: 252,
	},
	{
		garage_id: 219,
		part_id: 257,
		quantity: 103,
	},
	{
		garage_id: 219,
		part_id: 258,
		quantity: 386,
	},
	{
		garage_id: 219,
		part_id: 259,
		quantity: 86,
	},
	{
		garage_id: 219,
		part_id: 260,
		quantity: 47,
	},
	{
		garage_id: 219,
		part_id: 261,
		quantity: 100,
	},
	{
		garage_id: 219,
		part_id: 262,
		quantity: 384,
	},
	{
		garage_id: 219,
		part_id: 263,
		quantity: 332,
	},
	{
		garage_id: 219,
		part_id: 264,
		quantity: 355,
	},
	{
		garage_id: 219,
		part_id: 265,
		quantity: 368,
	},
	{
		garage_id: 219,
		part_id: 266,
		quantity: 264,
	},
	{
		garage_id: 219,
		part_id: 267,
		quantity: 233,
	},
	{
		garage_id: 219,
		part_id: 268,
		quantity: 155,
	},
	{
		garage_id: 219,
		part_id: 269,
		quantity: 293,
	},
	{
		garage_id: 219,
		part_id: 270,
		quantity: 372,
	},
	{
		garage_id: 219,
		part_id: 271,
		quantity: 112,
	},
	{
		garage_id: 219,
		part_id: 272,
		quantity: 11,
	},
	{
		garage_id: 219,
		part_id: 273,
		quantity: 197,
	},
	{
		garage_id: 219,
		part_id: 274,
		quantity: 400,
	},
	{
		garage_id: 219,
		part_id: 275,
		quantity: 494,
	},
	{
		garage_id: 219,
		part_id: 276,
		quantity: 165,
	},
	{
		garage_id: 219,
		part_id: 277,
		quantity: 165,
	},
	{
		garage_id: 219,
		part_id: 278,
		quantity: 300,
	},
	{
		garage_id: 219,
		part_id: 279,
		quantity: 459,
	},
	{
		garage_id: 219,
		part_id: 280,
		quantity: 90,
	},
	{
		garage_id: 219,
		part_id: 281,
		quantity: 438,
	},
	{
		garage_id: 219,
		part_id: 282,
		quantity: 53,
	},
	{
		garage_id: 219,
		part_id: 283,
		quantity: 78,
	},
	{
		garage_id: 219,
		part_id: 284,
		quantity: 393,
	},
	{
		garage_id: 219,
		part_id: 285,
		quantity: 171,
	},
	{
		garage_id: 219,
		part_id: 286,
		quantity: 59,
	},
	{
		garage_id: 219,
		part_id: 287,
		quantity: 65,
	},
	{
		garage_id: 219,
		part_id: 288,
		quantity: 215,
	},
	{
		garage_id: 219,
		part_id: 289,
		quantity: 242,
	},
	{
		garage_id: 219,
		part_id: 290,
		quantity: 419,
	},
	{
		garage_id: 219,
		part_id: 291,
		quantity: 419,
	},
	{
		garage_id: 219,
		part_id: 292,
		quantity: 380,
	},
	{
		garage_id: 219,
		part_id: 293,
		quantity: 139,
	},
	{
		garage_id: 219,
		part_id: 294,
		quantity: 271,
	},
	{
		garage_id: 219,
		part_id: 295,
		quantity: 333,
	},
	{
		garage_id: 219,
		part_id: 296,
		quantity: 488,
	},
	{
		garage_id: 219,
		part_id: 297,
		quantity: 95,
	},
	{
		garage_id: 219,
		part_id: 298,
		quantity: 408,
	},
	{
		garage_id: 219,
		part_id: 299,
		quantity: 45,
	},
	{
		garage_id: 219,
		part_id: 300,
		quantity: 373,
	},
	{
		garage_id: 219,
		part_id: 301,
		quantity: 390,
	},
	{
		garage_id: 219,
		part_id: 302,
		quantity: 409,
	},
	{
		garage_id: 219,
		part_id: 303,
		quantity: 338,
	},
	{
		garage_id: 219,
		part_id: 304,
		quantity: 252,
	},
	{
		garage_id: 219,
		part_id: 305,
		quantity: 452,
	},
	{
		garage_id: 219,
		part_id: 306,
		quantity: 256,
	},
	{
		garage_id: 219,
		part_id: 307,
		quantity: 135,
	},
	{
		garage_id: 219,
		part_id: 308,
		quantity: 111,
	},
	{
		garage_id: 219,
		part_id: 309,
		quantity: 492,
	},
	{
		garage_id: 219,
		part_id: 310,
		quantity: 287,
	},
	{
		garage_id: 219,
		part_id: 311,
		quantity: 467,
	},
	{
		garage_id: 219,
		part_id: 312,
		quantity: 115,
	},
	{
		garage_id: 219,
		part_id: 313,
		quantity: 252,
	},
	{
		garage_id: 219,
		part_id: 314,
		quantity: 387,
	},
	{
		garage_id: 219,
		part_id: 315,
		quantity: 44,
	},
	{
		garage_id: 219,
		part_id: 316,
		quantity: 236,
	},
	{
		garage_id: 219,
		part_id: 317,
		quantity: 210,
	},
	{
		garage_id: 219,
		part_id: 318,
		quantity: 130,
	},
	{
		garage_id: 219,
		part_id: 319,
		quantity: 353,
	},
	{
		garage_id: 219,
		part_id: 320,
		quantity: 404,
	},
	{
		garage_id: 219,
		part_id: 321,
		quantity: 241,
	},
	{
		garage_id: 219,
		part_id: 322,
		quantity: 46,
	},
	{
		garage_id: 219,
		part_id: 323,
		quantity: 142,
	},
	{
		garage_id: 219,
		part_id: 324,
		quantity: 21,
	},
	{
		garage_id: 219,
		part_id: 325,
		quantity: 167,
	},
	{
		garage_id: 219,
		part_id: 326,
		quantity: 198,
	},
	{
		garage_id: 219,
		part_id: 327,
		quantity: 365,
	},
	{
		garage_id: 219,
		part_id: 328,
		quantity: 454,
	},
	{
		garage_id: 219,
		part_id: 329,
		quantity: 231,
	},
	{
		garage_id: 219,
		part_id: 330,
		quantity: 52,
	},
	{
		garage_id: 219,
		part_id: 331,
		quantity: 308,
	},
	{
		garage_id: 219,
		part_id: 332,
		quantity: 231,
	},
	{
		garage_id: 219,
		part_id: 333,
		quantity: 454,
	},
	{
		garage_id: 219,
		part_id: 334,
		quantity: 106,
	},
	{
		garage_id: 219,
		part_id: 335,
		quantity: 161,
	},
	{
		garage_id: 219,
		part_id: 336,
		quantity: 134,
	},
	{
		garage_id: 219,
		part_id: 337,
		quantity: 331,
	},
	{
		garage_id: 219,
		part_id: 338,
		quantity: 40,
	},
	{
		garage_id: 219,
		part_id: 339,
		quantity: 44,
	},
	{
		garage_id: 219,
		part_id: 340,
		quantity: 27,
	},
	{
		garage_id: 219,
		part_id: 341,
		quantity: 5,
	},
	{
		garage_id: 219,
		part_id: 342,
		quantity: 370,
	},
	{
		garage_id: 219,
		part_id: 343,
		quantity: 161,
	},
	{
		garage_id: 219,
		part_id: 344,
		quantity: 70,
	},
	{
		garage_id: 219,
		part_id: 345,
		quantity: 359,
	},
	{
		garage_id: 219,
		part_id: 346,
		quantity: 140,
	},
	{
		garage_id: 219,
		part_id: 347,
		quantity: 456,
	},
	{
		garage_id: 219,
		part_id: 348,
		quantity: 486,
	},
	{
		garage_id: 219,
		part_id: 349,
		quantity: 480,
	},
	{
		garage_id: 219,
		part_id: 350,
		quantity: 104,
	},
	{
		garage_id: 219,
		part_id: 351,
		quantity: 481,
	},
	{
		garage_id: 219,
		part_id: 352,
		quantity: 376,
	},
	{
		garage_id: 219,
		part_id: 353,
		quantity: 380,
	},
	{
		garage_id: 219,
		part_id: 354,
		quantity: 198,
	},
	{
		garage_id: 219,
		part_id: 355,
		quantity: 234,
	},
	{
		garage_id: 219,
		part_id: 356,
		quantity: 180,
	},
	{
		garage_id: 219,
		part_id: 357,
		quantity: 357,
	},
	{
		garage_id: 219,
		part_id: 358,
		quantity: 133,
	},
	{
		garage_id: 219,
		part_id: 359,
		quantity: 239,
	},
	{
		garage_id: 219,
		part_id: 360,
		quantity: 305,
	},
	{
		garage_id: 219,
		part_id: 361,
		quantity: 283,
	},
	{
		garage_id: 219,
		part_id: 362,
		quantity: 170,
	},
	{
		garage_id: 219,
		part_id: 363,
		quantity: 385,
	},
	{
		garage_id: 219,
		part_id: 364,
		quantity: 55,
	},
	{
		garage_id: 219,
		part_id: 365,
		quantity: 426,
	},
	{
		garage_id: 219,
		part_id: 366,
		quantity: 336,
	},
	{
		garage_id: 219,
		part_id: 367,
		quantity: 439,
	},
	{
		garage_id: 219,
		part_id: 368,
		quantity: 207,
	},
	{
		garage_id: 219,
		part_id: 369,
		quantity: 67,
	},
	{
		garage_id: 219,
		part_id: 370,
		quantity: 483,
	},
	{
		garage_id: 219,
		part_id: 371,
		quantity: 178,
	},
	{
		garage_id: 219,
		part_id: 372,
		quantity: 277,
	},
	{
		garage_id: 219,
		part_id: 373,
		quantity: 114,
	},
	{
		garage_id: 219,
		part_id: 374,
		quantity: 37,
	},
	{
		garage_id: 219,
		part_id: 375,
		quantity: 38,
	},
	{
		garage_id: 219,
		part_id: 376,
		quantity: 361,
	},
	{
		garage_id: 219,
		part_id: 377,
		quantity: 228,
	},
	{
		garage_id: 219,
		part_id: 378,
		quantity: 375,
	},
	{
		garage_id: 219,
		part_id: 379,
		quantity: 474,
	},
	{
		garage_id: 219,
		part_id: 380,
		quantity: 158,
	},
	{
		garage_id: 219,
		part_id: 381,
		quantity: 251,
	},
	{
		garage_id: 219,
		part_id: 382,
		quantity: 333,
	},
	{
		garage_id: 219,
		part_id: 383,
		quantity: 15,
	},
	{
		garage_id: 219,
		part_id: 384,
		quantity: 31,
	},
	{
		garage_id: 219,
		part_id: 385,
		quantity: 94,
	},
	{
		garage_id: 219,
		part_id: 386,
		quantity: 223,
	},
	{
		garage_id: 219,
		part_id: 387,
		quantity: 190,
	},
	{
		garage_id: 219,
		part_id: 388,
		quantity: 403,
	},
	{
		garage_id: 219,
		part_id: 389,
		quantity: 379,
	},
	{
		garage_id: 219,
		part_id: 390,
		quantity: 174,
	},
	{
		garage_id: 219,
		part_id: 391,
		quantity: 75,
	},
	{
		garage_id: 219,
		part_id: 392,
		quantity: 107,
	},
	{
		garage_id: 219,
		part_id: 393,
		quantity: 21,
	},
	{
		garage_id: 219,
		part_id: 394,
		quantity: 460,
	},
	{
		garage_id: 219,
		part_id: 395,
		quantity: 289,
	},
	{
		garage_id: 219,
		part_id: 396,
		quantity: 402,
	},
	{
		garage_id: 219,
		part_id: 397,
		quantity: 343,
	},
	{
		garage_id: 219,
		part_id: 398,
		quantity: 16,
	},
	{
		garage_id: 219,
		part_id: 399,
		quantity: 60,
	},
	{
		garage_id: 220,
		part_id: 0,
		quantity: 131,
	},
	{
		garage_id: 220,
		part_id: 1,
		quantity: 488,
	},
	{
		garage_id: 220,
		part_id: 2,
		quantity: 229,
	},
	{
		garage_id: 220,
		part_id: 3,
		quantity: 236,
	},
	{
		garage_id: 220,
		part_id: 4,
		quantity: 99,
	},
	{
		garage_id: 220,
		part_id: 5,
		quantity: 247,
	},
	{
		garage_id: 220,
		part_id: 6,
		quantity: 236,
	},
	{
		garage_id: 220,
		part_id: 7,
		quantity: 470,
	},
	{
		garage_id: 220,
		part_id: 8,
		quantity: 374,
	},
	{
		garage_id: 220,
		part_id: 9,
		quantity: 435,
	},
	{
		garage_id: 220,
		part_id: 10,
		quantity: 289,
	},
	{
		garage_id: 220,
		part_id: 11,
		quantity: 461,
	},
	{
		garage_id: 220,
		part_id: 12,
		quantity: 269,
	},
	{
		garage_id: 220,
		part_id: 13,
		quantity: 277,
	},
	{
		garage_id: 220,
		part_id: 14,
		quantity: 471,
	},
	{
		garage_id: 220,
		part_id: 15,
		quantity: 38,
	},
	{
		garage_id: 220,
		part_id: 16,
		quantity: 495,
	},
	{
		garage_id: 220,
		part_id: 17,
		quantity: 167,
	},
	{
		garage_id: 220,
		part_id: 18,
		quantity: 349,
	},
	{
		garage_id: 220,
		part_id: 19,
		quantity: 181,
	},
	{
		garage_id: 220,
		part_id: 20,
		quantity: 246,
	},
	{
		garage_id: 220,
		part_id: 21,
		quantity: 388,
	},
	{
		garage_id: 220,
		part_id: 22,
		quantity: 39,
	},
	{
		garage_id: 220,
		part_id: 23,
		quantity: 429,
	},
	{
		garage_id: 220,
		part_id: 24,
		quantity: 194,
	},
	{
		garage_id: 220,
		part_id: 25,
		quantity: 470,
	},
	{
		garage_id: 220,
		part_id: 26,
		quantity: 4,
	},
	{
		garage_id: 220,
		part_id: 27,
		quantity: 28,
	},
	{
		garage_id: 220,
		part_id: 28,
		quantity: 428,
	},
	{
		garage_id: 220,
		part_id: 29,
		quantity: 233,
	},
	{
		garage_id: 220,
		part_id: 30,
		quantity: 58,
	},
	{
		garage_id: 220,
		part_id: 31,
		quantity: 143,
	},
	{
		garage_id: 220,
		part_id: 32,
		quantity: 236,
	},
	{
		garage_id: 220,
		part_id: 33,
		quantity: 309,
	},
	{
		garage_id: 220,
		part_id: 34,
		quantity: 169,
	},
	{
		garage_id: 220,
		part_id: 35,
		quantity: 198,
	},
	{
		garage_id: 220,
		part_id: 36,
		quantity: 158,
	},
	{
		garage_id: 220,
		part_id: 37,
		quantity: 234,
	},
	{
		garage_id: 220,
		part_id: 38,
		quantity: 22,
	},
	{
		garage_id: 220,
		part_id: 39,
		quantity: 229,
	},
	{
		garage_id: 220,
		part_id: 40,
		quantity: 304,
	},
	{
		garage_id: 220,
		part_id: 41,
		quantity: 36,
	},
	{
		garage_id: 220,
		part_id: 42,
		quantity: 37,
	},
	{
		garage_id: 220,
		part_id: 43,
		quantity: 463,
	},
	{
		garage_id: 220,
		part_id: 44,
		quantity: 226,
	},
	{
		garage_id: 220,
		part_id: 45,
		quantity: 471,
	},
	{
		garage_id: 220,
		part_id: 46,
		quantity: 7,
	},
	{
		garage_id: 220,
		part_id: 47,
		quantity: 190,
	},
	{
		garage_id: 220,
		part_id: 48,
		quantity: 241,
	},
	{
		garage_id: 220,
		part_id: 49,
		quantity: 183,
	},
	{
		garage_id: 220,
		part_id: 50,
		quantity: 305,
	},
	{
		garage_id: 220,
		part_id: 51,
		quantity: 304,
	},
	{
		garage_id: 220,
		part_id: 52,
		quantity: 42,
	},
	{
		garage_id: 220,
		part_id: 53,
		quantity: 272,
	},
	{
		garage_id: 220,
		part_id: 54,
		quantity: 348,
	},
	{
		garage_id: 220,
		part_id: 55,
		quantity: 432,
	},
	{
		garage_id: 220,
		part_id: 56,
		quantity: 5,
	},
	{
		garage_id: 220,
		part_id: 57,
		quantity: 357,
	},
	{
		garage_id: 220,
		part_id: 58,
		quantity: 218,
	},
	{
		garage_id: 220,
		part_id: 59,
		quantity: 497,
	},
	{
		garage_id: 220,
		part_id: 60,
		quantity: 203,
	},
	{
		garage_id: 220,
		part_id: 61,
		quantity: 428,
	},
	{
		garage_id: 220,
		part_id: 62,
		quantity: 490,
	},
	{
		garage_id: 220,
		part_id: 63,
		quantity: 420,
	},
	{
		garage_id: 220,
		part_id: 64,
		quantity: 474,
	},
	{
		garage_id: 220,
		part_id: 65,
		quantity: 393,
	},
	{
		garage_id: 220,
		part_id: 66,
		quantity: 96,
	},
	{
		garage_id: 220,
		part_id: 67,
		quantity: 263,
	},
	{
		garage_id: 220,
		part_id: 68,
		quantity: 347,
	},
	{
		garage_id: 220,
		part_id: 69,
		quantity: 289,
	},
	{
		garage_id: 220,
		part_id: 70,
		quantity: 27,
	},
	{
		garage_id: 220,
		part_id: 71,
		quantity: 87,
	},
	{
		garage_id: 220,
		part_id: 72,
		quantity: 167,
	},
	{
		garage_id: 220,
		part_id: 73,
		quantity: 3,
	},
	{
		garage_id: 220,
		part_id: 74,
		quantity: 309,
	},
	{
		garage_id: 220,
		part_id: 75,
		quantity: 480,
	},
	{
		garage_id: 220,
		part_id: 76,
		quantity: 351,
	},
	{
		garage_id: 220,
		part_id: 77,
		quantity: 218,
	},
	{
		garage_id: 220,
		part_id: 78,
		quantity: 366,
	},
	{
		garage_id: 220,
		part_id: 79,
		quantity: 96,
	},
	{
		garage_id: 220,
		part_id: 80,
		quantity: 296,
	},
	{
		garage_id: 220,
		part_id: 81,
		quantity: 54,
	},
	{
		garage_id: 220,
		part_id: 82,
		quantity: 335,
	},
	{
		garage_id: 220,
		part_id: 83,
		quantity: 241,
	},
	{
		garage_id: 220,
		part_id: 84,
		quantity: 447,
	},
	{
		garage_id: 220,
		part_id: 85,
		quantity: 409,
	},
	{
		garage_id: 220,
		part_id: 86,
		quantity: 186,
	},
	{
		garage_id: 220,
		part_id: 87,
		quantity: 357,
	},
	{
		garage_id: 220,
		part_id: 88,
		quantity: 163,
	},
	{
		garage_id: 220,
		part_id: 89,
		quantity: 178,
	},
	{
		garage_id: 220,
		part_id: 90,
		quantity: 85,
	},
	{
		garage_id: 220,
		part_id: 91,
		quantity: 266,
	},
	{
		garage_id: 220,
		part_id: 92,
		quantity: 94,
	},
	{
		garage_id: 220,
		part_id: 93,
		quantity: 253,
	},
	{
		garage_id: 220,
		part_id: 94,
		quantity: 479,
	},
	{
		garage_id: 220,
		part_id: 95,
		quantity: 68,
	},
	{
		garage_id: 220,
		part_id: 96,
		quantity: 324,
	},
	{
		garage_id: 220,
		part_id: 97,
		quantity: 362,
	},
	{
		garage_id: 220,
		part_id: 98,
		quantity: 168,
	},
	{
		garage_id: 220,
		part_id: 99,
		quantity: 196,
	},
	{
		garage_id: 220,
		part_id: 100,
		quantity: 221,
	},
	{
		garage_id: 220,
		part_id: 101,
		quantity: 95,
	},
	{
		garage_id: 220,
		part_id: 102,
		quantity: 112,
	},
	{
		garage_id: 220,
		part_id: 103,
		quantity: 296,
	},
	{
		garage_id: 220,
		part_id: 104,
		quantity: 1,
	},
	{
		garage_id: 220,
		part_id: 105,
		quantity: 440,
	},
	{
		garage_id: 220,
		part_id: 106,
		quantity: 163,
	},
	{
		garage_id: 220,
		part_id: 107,
		quantity: 72,
	},
	{
		garage_id: 220,
		part_id: 108,
		quantity: 114,
	},
	{
		garage_id: 220,
		part_id: 109,
		quantity: 439,
	},
	{
		garage_id: 220,
		part_id: 110,
		quantity: 473,
	},
	{
		garage_id: 220,
		part_id: 111,
		quantity: 71,
	},
	{
		garage_id: 220,
		part_id: 112,
		quantity: 279,
	},
	{
		garage_id: 220,
		part_id: 113,
		quantity: 209,
	},
	{
		garage_id: 220,
		part_id: 114,
		quantity: 116,
	},
	{
		garage_id: 220,
		part_id: 115,
		quantity: 489,
	},
	{
		garage_id: 220,
		part_id: 116,
		quantity: 420,
	},
	{
		garage_id: 220,
		part_id: 117,
		quantity: 352,
	},
	{
		garage_id: 220,
		part_id: 118,
		quantity: 31,
	},
	{
		garage_id: 220,
		part_id: 119,
		quantity: 72,
	},
	{
		garage_id: 220,
		part_id: 120,
		quantity: 390,
	},
	{
		garage_id: 220,
		part_id: 121,
		quantity: 337,
	},
	{
		garage_id: 220,
		part_id: 122,
		quantity: 243,
	},
	{
		garage_id: 220,
		part_id: 123,
		quantity: 303,
	},
	{
		garage_id: 220,
		part_id: 124,
		quantity: 500,
	},
	{
		garage_id: 220,
		part_id: 125,
		quantity: 438,
	},
	{
		garage_id: 220,
		part_id: 126,
		quantity: 209,
	},
	{
		garage_id: 220,
		part_id: 127,
		quantity: 60,
	},
	{
		garage_id: 220,
		part_id: 128,
		quantity: 371,
	},
	{
		garage_id: 220,
		part_id: 129,
		quantity: 168,
	},
	{
		garage_id: 220,
		part_id: 130,
		quantity: 60,
	},
	{
		garage_id: 220,
		part_id: 131,
		quantity: 34,
	},
	{
		garage_id: 220,
		part_id: 132,
		quantity: 173,
	},
	{
		garage_id: 220,
		part_id: 133,
		quantity: 80,
	},
	{
		garage_id: 220,
		part_id: 134,
		quantity: 429,
	},
	{
		garage_id: 220,
		part_id: 135,
		quantity: 29,
	},
	{
		garage_id: 220,
		part_id: 136,
		quantity: 246,
	},
	{
		garage_id: 220,
		part_id: 137,
		quantity: 185,
	},
	{
		garage_id: 220,
		part_id: 138,
		quantity: 274,
	},
	{
		garage_id: 220,
		part_id: 139,
		quantity: 4,
	},
	{
		garage_id: 220,
		part_id: 140,
		quantity: 416,
	},
	{
		garage_id: 220,
		part_id: 141,
		quantity: 206,
	},
	{
		garage_id: 220,
		part_id: 142,
		quantity: 460,
	},
	{
		garage_id: 220,
		part_id: 143,
		quantity: 208,
	},
	{
		garage_id: 220,
		part_id: 144,
		quantity: 191,
	},
	{
		garage_id: 220,
		part_id: 145,
		quantity: 140,
	},
	{
		garage_id: 220,
		part_id: 146,
		quantity: 73,
	},
	{
		garage_id: 220,
		part_id: 147,
		quantity: 162,
	},
	{
		garage_id: 220,
		part_id: 148,
		quantity: 114,
	},
	{
		garage_id: 220,
		part_id: 149,
		quantity: 49,
	},
	{
		garage_id: 220,
		part_id: 150,
		quantity: 317,
	},
	{
		garage_id: 220,
		part_id: 151,
		quantity: 435,
	},
	{
		garage_id: 220,
		part_id: 152,
		quantity: 106,
	},
	{
		garage_id: 220,
		part_id: 153,
		quantity: 18,
	},
	{
		garage_id: 220,
		part_id: 154,
		quantity: 161,
	},
	{
		garage_id: 220,
		part_id: 155,
		quantity: 269,
	},
	{
		garage_id: 220,
		part_id: 156,
		quantity: 139,
	},
	{
		garage_id: 220,
		part_id: 157,
		quantity: 348,
	},
	{
		garage_id: 220,
		part_id: 158,
		quantity: 10,
	},
	{
		garage_id: 220,
		part_id: 159,
		quantity: 329,
	},
	{
		garage_id: 220,
		part_id: 160,
		quantity: 258,
	},
	{
		garage_id: 220,
		part_id: 161,
		quantity: 170,
	},
	{
		garage_id: 220,
		part_id: 162,
		quantity: 368,
	},
	{
		garage_id: 220,
		part_id: 163,
		quantity: 471,
	},
	{
		garage_id: 220,
		part_id: 164,
		quantity: 448,
	},
	{
		garage_id: 220,
		part_id: 165,
		quantity: 9,
	},
	{
		garage_id: 220,
		part_id: 166,
		quantity: 135,
	},
	{
		garage_id: 220,
		part_id: 167,
		quantity: 47,
	},
	{
		garage_id: 220,
		part_id: 168,
		quantity: 238,
	},
	{
		garage_id: 220,
		part_id: 169,
		quantity: 102,
	},
	{
		garage_id: 220,
		part_id: 170,
		quantity: 160,
	},
	{
		garage_id: 220,
		part_id: 171,
		quantity: 268,
	},
	{
		garage_id: 220,
		part_id: 172,
		quantity: 237,
	},
	{
		garage_id: 220,
		part_id: 173,
		quantity: 218,
	},
	{
		garage_id: 220,
		part_id: 174,
		quantity: 368,
	},
	{
		garage_id: 220,
		part_id: 175,
		quantity: 137,
	},
	{
		garage_id: 220,
		part_id: 176,
		quantity: 407,
	},
	{
		garage_id: 220,
		part_id: 177,
		quantity: 181,
	},
	{
		garage_id: 220,
		part_id: 178,
		quantity: 305,
	},
	{
		garage_id: 220,
		part_id: 179,
		quantity: 380,
	},
	{
		garage_id: 220,
		part_id: 180,
		quantity: 439,
	},
	{
		garage_id: 220,
		part_id: 181,
		quantity: 145,
	},
	{
		garage_id: 220,
		part_id: 182,
		quantity: 359,
	},
	{
		garage_id: 220,
		part_id: 183,
		quantity: 267,
	},
	{
		garage_id: 220,
		part_id: 184,
		quantity: 459,
	},
	{
		garage_id: 220,
		part_id: 185,
		quantity: 167,
	},
	{
		garage_id: 220,
		part_id: 186,
		quantity: 70,
	},
	{
		garage_id: 220,
		part_id: 187,
		quantity: 190,
	},
	{
		garage_id: 220,
		part_id: 188,
		quantity: 423,
	},
	{
		garage_id: 220,
		part_id: 189,
		quantity: 306,
	},
	{
		garage_id: 220,
		part_id: 190,
		quantity: 61,
	},
	{
		garage_id: 220,
		part_id: 191,
		quantity: 142,
	},
	{
		garage_id: 220,
		part_id: 192,
		quantity: 157,
	},
	{
		garage_id: 220,
		part_id: 193,
		quantity: 354,
	},
	{
		garage_id: 220,
		part_id: 194,
		quantity: 174,
	},
	{
		garage_id: 220,
		part_id: 195,
		quantity: 277,
	},
	{
		garage_id: 220,
		part_id: 196,
		quantity: 7,
	},
	{
		garage_id: 220,
		part_id: 197,
		quantity: 453,
	},
	{
		garage_id: 220,
		part_id: 198,
		quantity: 294,
	},
	{
		garage_id: 220,
		part_id: 199,
		quantity: 121,
	},
	{
		garage_id: 220,
		part_id: 200,
		quantity: 326,
	},
	{
		garage_id: 220,
		part_id: 201,
		quantity: 288,
	},
	{
		garage_id: 220,
		part_id: 202,
		quantity: 252,
	},
	{
		garage_id: 220,
		part_id: 203,
		quantity: 389,
	},
	{
		garage_id: 220,
		part_id: 204,
		quantity: 179,
	},
	{
		garage_id: 220,
		part_id: 205,
		quantity: 274,
	},
	{
		garage_id: 220,
		part_id: 206,
		quantity: 453,
	},
	{
		garage_id: 220,
		part_id: 207,
		quantity: 183,
	},
	{
		garage_id: 220,
		part_id: 208,
		quantity: 427,
	},
	{
		garage_id: 220,
		part_id: 209,
		quantity: 111,
	},
	{
		garage_id: 220,
		part_id: 210,
		quantity: 450,
	},
	{
		garage_id: 220,
		part_id: 211,
		quantity: 482,
	},
	{
		garage_id: 220,
		part_id: 212,
		quantity: 321,
	},
	{
		garage_id: 220,
		part_id: 213,
		quantity: 0,
	},
	{
		garage_id: 220,
		part_id: 214,
		quantity: 251,
	},
	{
		garage_id: 220,
		part_id: 215,
		quantity: 173,
	},
	{
		garage_id: 220,
		part_id: 216,
		quantity: 9,
	},
	{
		garage_id: 220,
		part_id: 217,
		quantity: 100,
	},
	{
		garage_id: 220,
		part_id: 218,
		quantity: 164,
	},
	{
		garage_id: 220,
		part_id: 219,
		quantity: 438,
	},
	{
		garage_id: 220,
		part_id: 220,
		quantity: 262,
	},
	{
		garage_id: 220,
		part_id: 221,
		quantity: 163,
	},
	{
		garage_id: 220,
		part_id: 222,
		quantity: 186,
	},
	{
		garage_id: 220,
		part_id: 223,
		quantity: 443,
	},
	{
		garage_id: 220,
		part_id: 224,
		quantity: 53,
	},
	{
		garage_id: 220,
		part_id: 225,
		quantity: 398,
	},
	{
		garage_id: 220,
		part_id: 226,
		quantity: 4,
	},
	{
		garage_id: 220,
		part_id: 227,
		quantity: 382,
	},
	{
		garage_id: 220,
		part_id: 228,
		quantity: 332,
	},
	{
		garage_id: 220,
		part_id: 229,
		quantity: 76,
	},
	{
		garage_id: 220,
		part_id: 230,
		quantity: 334,
	},
	{
		garage_id: 220,
		part_id: 231,
		quantity: 454,
	},
	{
		garage_id: 220,
		part_id: 232,
		quantity: 268,
	},
	{
		garage_id: 220,
		part_id: 233,
		quantity: 280,
	},
	{
		garage_id: 220,
		part_id: 234,
		quantity: 483,
	},
	{
		garage_id: 220,
		part_id: 235,
		quantity: 299,
	},
	{
		garage_id: 220,
		part_id: 236,
		quantity: 271,
	},
	{
		garage_id: 220,
		part_id: 237,
		quantity: 350,
	},
	{
		garage_id: 220,
		part_id: 238,
		quantity: 115,
	},
	{
		garage_id: 220,
		part_id: 239,
		quantity: 254,
	},
	{
		garage_id: 220,
		part_id: 240,
		quantity: 90,
	},
	{
		garage_id: 220,
		part_id: 241,
		quantity: 232,
	},
	{
		garage_id: 220,
		part_id: 242,
		quantity: 432,
	},
	{
		garage_id: 220,
		part_id: 243,
		quantity: 172,
	},
	{
		garage_id: 220,
		part_id: 244,
		quantity: 481,
	},
	{
		garage_id: 220,
		part_id: 245,
		quantity: 247,
	},
	{
		garage_id: 220,
		part_id: 246,
		quantity: 102,
	},
	{
		garage_id: 220,
		part_id: 247,
		quantity: 94,
	},
	{
		garage_id: 220,
		part_id: 248,
		quantity: 179,
	},
	{
		garage_id: 220,
		part_id: 249,
		quantity: 83,
	},
	{
		garage_id: 220,
		part_id: 250,
		quantity: 126,
	},
	{
		garage_id: 220,
		part_id: 251,
		quantity: 417,
	},
	{
		garage_id: 220,
		part_id: 252,
		quantity: 135,
	},
	{
		garage_id: 220,
		part_id: 253,
		quantity: 89,
	},
	{
		garage_id: 220,
		part_id: 254,
		quantity: 295,
	},
	{
		garage_id: 220,
		part_id: 255,
		quantity: 322,
	},
	{
		garage_id: 220,
		part_id: 256,
		quantity: 379,
	},
	{
		garage_id: 220,
		part_id: 257,
		quantity: 228,
	},
	{
		garage_id: 220,
		part_id: 258,
		quantity: 446,
	},
	{
		garage_id: 220,
		part_id: 259,
		quantity: 285,
	},
	{
		garage_id: 220,
		part_id: 260,
		quantity: 333,
	},
	{
		garage_id: 220,
		part_id: 261,
		quantity: 391,
	},
	{
		garage_id: 220,
		part_id: 262,
		quantity: 60,
	},
	{
		garage_id: 220,
		part_id: 263,
		quantity: 422,
	},
	{
		garage_id: 220,
		part_id: 264,
		quantity: 245,
	},
	{
		garage_id: 220,
		part_id: 265,
		quantity: 425,
	},
	{
		garage_id: 220,
		part_id: 266,
		quantity: 165,
	},
	{
		garage_id: 220,
		part_id: 267,
		quantity: 182,
	},
	{
		garage_id: 220,
		part_id: 268,
		quantity: 430,
	},
	{
		garage_id: 220,
		part_id: 269,
		quantity: 10,
	},
	{
		garage_id: 220,
		part_id: 270,
		quantity: 130,
	},
	{
		garage_id: 220,
		part_id: 271,
		quantity: 222,
	},
	{
		garage_id: 220,
		part_id: 272,
		quantity: 359,
	},
	{
		garage_id: 220,
		part_id: 273,
		quantity: 452,
	},
	{
		garage_id: 220,
		part_id: 274,
		quantity: 54,
	},
	{
		garage_id: 220,
		part_id: 275,
		quantity: 86,
	},
	{
		garage_id: 220,
		part_id: 276,
		quantity: 496,
	},
	{
		garage_id: 220,
		part_id: 277,
		quantity: 108,
	},
	{
		garage_id: 220,
		part_id: 278,
		quantity: 49,
	},
	{
		garage_id: 220,
		part_id: 279,
		quantity: 2,
	},
	{
		garage_id: 220,
		part_id: 280,
		quantity: 122,
	},
	{
		garage_id: 220,
		part_id: 281,
		quantity: 461,
	},
	{
		garage_id: 220,
		part_id: 282,
		quantity: 358,
	},
	{
		garage_id: 220,
		part_id: 283,
		quantity: 45,
	},
	{
		garage_id: 220,
		part_id: 284,
		quantity: 293,
	},
	{
		garage_id: 220,
		part_id: 285,
		quantity: 0,
	},
	{
		garage_id: 220,
		part_id: 286,
		quantity: 346,
	},
	{
		garage_id: 220,
		part_id: 287,
		quantity: 25,
	},
	{
		garage_id: 220,
		part_id: 288,
		quantity: 23,
	},
	{
		garage_id: 220,
		part_id: 289,
		quantity: 353,
	},
	{
		garage_id: 220,
		part_id: 290,
		quantity: 209,
	},
	{
		garage_id: 220,
		part_id: 291,
		quantity: 151,
	},
	{
		garage_id: 220,
		part_id: 292,
		quantity: 282,
	},
	{
		garage_id: 220,
		part_id: 293,
		quantity: 462,
	},
	{
		garage_id: 220,
		part_id: 294,
		quantity: 104,
	},
	{
		garage_id: 220,
		part_id: 295,
		quantity: 354,
	},
	{
		garage_id: 220,
		part_id: 296,
		quantity: 325,
	},
	{
		garage_id: 220,
		part_id: 297,
		quantity: 108,
	},
	{
		garage_id: 220,
		part_id: 298,
		quantity: 24,
	},
	{
		garage_id: 220,
		part_id: 299,
		quantity: 254,
	},
	{
		garage_id: 220,
		part_id: 300,
		quantity: 421,
	},
	{
		garage_id: 220,
		part_id: 301,
		quantity: 271,
	},
	{
		garage_id: 220,
		part_id: 302,
		quantity: 171,
	},
	{
		garage_id: 220,
		part_id: 303,
		quantity: 180,
	},
	{
		garage_id: 220,
		part_id: 304,
		quantity: 332,
	},
	{
		garage_id: 220,
		part_id: 305,
		quantity: 364,
	},
	{
		garage_id: 220,
		part_id: 306,
		quantity: 11,
	},
	{
		garage_id: 220,
		part_id: 307,
		quantity: 59,
	},
	{
		garage_id: 220,
		part_id: 308,
		quantity: 436,
	},
	{
		garage_id: 220,
		part_id: 309,
		quantity: 8,
	},
	{
		garage_id: 220,
		part_id: 310,
		quantity: 355,
	},
	{
		garage_id: 220,
		part_id: 311,
		quantity: 153,
	},
	{
		garage_id: 220,
		part_id: 312,
		quantity: 120,
	},
	{
		garage_id: 220,
		part_id: 313,
		quantity: 420,
	},
	{
		garage_id: 220,
		part_id: 314,
		quantity: 160,
	},
	{
		garage_id: 220,
		part_id: 315,
		quantity: 347,
	},
	{
		garage_id: 220,
		part_id: 316,
		quantity: 275,
	},
	{
		garage_id: 220,
		part_id: 317,
		quantity: 276,
	},
	{
		garage_id: 220,
		part_id: 318,
		quantity: 413,
	},
	{
		garage_id: 220,
		part_id: 319,
		quantity: 432,
	},
	{
		garage_id: 220,
		part_id: 320,
		quantity: 51,
	},
	{
		garage_id: 220,
		part_id: 321,
		quantity: 257,
	},
	{
		garage_id: 220,
		part_id: 322,
		quantity: 150,
	},
	{
		garage_id: 220,
		part_id: 323,
		quantity: 496,
	},
	{
		garage_id: 220,
		part_id: 324,
		quantity: 133,
	},
	{
		garage_id: 220,
		part_id: 325,
		quantity: 453,
	},
	{
		garage_id: 220,
		part_id: 326,
		quantity: 451,
	},
	{
		garage_id: 220,
		part_id: 327,
		quantity: 317,
	},
	{
		garage_id: 220,
		part_id: 328,
		quantity: 99,
	},
	{
		garage_id: 220,
		part_id: 329,
		quantity: 122,
	},
	{
		garage_id: 220,
		part_id: 330,
		quantity: 115,
	},
	{
		garage_id: 220,
		part_id: 331,
		quantity: 405,
	},
	{
		garage_id: 220,
		part_id: 332,
		quantity: 0,
	},
	{
		garage_id: 220,
		part_id: 333,
		quantity: 35,
	},
	{
		garage_id: 220,
		part_id: 334,
		quantity: 299,
	},
	{
		garage_id: 220,
		part_id: 335,
		quantity: 365,
	},
	{
		garage_id: 220,
		part_id: 336,
		quantity: 418,
	},
	{
		garage_id: 220,
		part_id: 337,
		quantity: 430,
	},
	{
		garage_id: 220,
		part_id: 338,
		quantity: 163,
	},
	{
		garage_id: 220,
		part_id: 339,
		quantity: 412,
	},
	{
		garage_id: 220,
		part_id: 340,
		quantity: 67,
	},
	{
		garage_id: 220,
		part_id: 341,
		quantity: 190,
	},
	{
		garage_id: 220,
		part_id: 342,
		quantity: 394,
	},
	{
		garage_id: 220,
		part_id: 343,
		quantity: 17,
	},
	{
		garage_id: 220,
		part_id: 344,
		quantity: 256,
	},
	{
		garage_id: 220,
		part_id: 345,
		quantity: 105,
	},
	{
		garage_id: 220,
		part_id: 346,
		quantity: 462,
	},
	{
		garage_id: 220,
		part_id: 347,
		quantity: 211,
	},
	{
		garage_id: 220,
		part_id: 348,
		quantity: 212,
	},
	{
		garage_id: 220,
		part_id: 349,
		quantity: 484,
	},
	{
		garage_id: 220,
		part_id: 350,
		quantity: 324,
	},
	{
		garage_id: 220,
		part_id: 351,
		quantity: 69,
	},
	{
		garage_id: 220,
		part_id: 352,
		quantity: 175,
	},
	{
		garage_id: 220,
		part_id: 353,
		quantity: 364,
	},
	{
		garage_id: 220,
		part_id: 354,
		quantity: 234,
	},
	{
		garage_id: 220,
		part_id: 355,
		quantity: 109,
	},
	{
		garage_id: 220,
		part_id: 356,
		quantity: 96,
	},
	{
		garage_id: 220,
		part_id: 357,
		quantity: 194,
	},
	{
		garage_id: 220,
		part_id: 358,
		quantity: 141,
	},
	{
		garage_id: 220,
		part_id: 359,
		quantity: 164,
	},
	{
		garage_id: 220,
		part_id: 360,
		quantity: 357,
	},
	{
		garage_id: 220,
		part_id: 361,
		quantity: 276,
	},
	{
		garage_id: 220,
		part_id: 362,
		quantity: 69,
	},
	{
		garage_id: 220,
		part_id: 363,
		quantity: 413,
	},
	{
		garage_id: 220,
		part_id: 364,
		quantity: 357,
	},
	{
		garage_id: 220,
		part_id: 365,
		quantity: 418,
	},
	{
		garage_id: 220,
		part_id: 366,
		quantity: 438,
	},
	{
		garage_id: 220,
		part_id: 367,
		quantity: 62,
	},
	{
		garage_id: 220,
		part_id: 368,
		quantity: 269,
	},
	{
		garage_id: 220,
		part_id: 369,
		quantity: 243,
	},
	{
		garage_id: 220,
		part_id: 370,
		quantity: 264,
	},
	{
		garage_id: 220,
		part_id: 371,
		quantity: 198,
	},
	{
		garage_id: 220,
		part_id: 372,
		quantity: 168,
	},
	{
		garage_id: 220,
		part_id: 373,
		quantity: 352,
	},
	{
		garage_id: 220,
		part_id: 374,
		quantity: 488,
	},
	{
		garage_id: 220,
		part_id: 375,
		quantity: 237,
	},
	{
		garage_id: 220,
		part_id: 376,
		quantity: 267,
	},
	{
		garage_id: 220,
		part_id: 377,
		quantity: 101,
	},
	{
		garage_id: 220,
		part_id: 378,
		quantity: 137,
	},
	{
		garage_id: 220,
		part_id: 379,
		quantity: 453,
	},
	{
		garage_id: 220,
		part_id: 380,
		quantity: 458,
	},
	{
		garage_id: 220,
		part_id: 381,
		quantity: 146,
	},
	{
		garage_id: 220,
		part_id: 382,
		quantity: 486,
	},
	{
		garage_id: 220,
		part_id: 383,
		quantity: 6,
	},
	{
		garage_id: 220,
		part_id: 384,
		quantity: 438,
	},
	{
		garage_id: 220,
		part_id: 385,
		quantity: 298,
	},
	{
		garage_id: 220,
		part_id: 386,
		quantity: 360,
	},
	{
		garage_id: 220,
		part_id: 387,
		quantity: 117,
	},
	{
		garage_id: 220,
		part_id: 388,
		quantity: 280,
	},
	{
		garage_id: 220,
		part_id: 389,
		quantity: 108,
	},
	{
		garage_id: 220,
		part_id: 390,
		quantity: 164,
	},
	{
		garage_id: 220,
		part_id: 391,
		quantity: 68,
	},
	{
		garage_id: 220,
		part_id: 392,
		quantity: 74,
	},
	{
		garage_id: 220,
		part_id: 393,
		quantity: 105,
	},
	{
		garage_id: 220,
		part_id: 394,
		quantity: 7,
	},
	{
		garage_id: 220,
		part_id: 395,
		quantity: 7,
	},
	{
		garage_id: 220,
		part_id: 396,
		quantity: 360,
	},
	{
		garage_id: 220,
		part_id: 397,
		quantity: 255,
	},
	{
		garage_id: 220,
		part_id: 398,
		quantity: 49,
	},
	{
		garage_id: 220,
		part_id: 399,
		quantity: 412,
	},
	{
		garage_id: 221,
		part_id: 0,
		quantity: 73,
	},
	{
		garage_id: 221,
		part_id: 1,
		quantity: 450,
	},
	{
		garage_id: 221,
		part_id: 2,
		quantity: 113,
	},
	{
		garage_id: 221,
		part_id: 3,
		quantity: 451,
	},
	{
		garage_id: 221,
		part_id: 4,
		quantity: 284,
	},
	{
		garage_id: 221,
		part_id: 5,
		quantity: 376,
	},
	{
		garage_id: 221,
		part_id: 6,
		quantity: 295,
	},
	{
		garage_id: 221,
		part_id: 7,
		quantity: 337,
	},
	{
		garage_id: 221,
		part_id: 8,
		quantity: 310,
	},
	{
		garage_id: 221,
		part_id: 9,
		quantity: 476,
	},
	{
		garage_id: 221,
		part_id: 10,
		quantity: 276,
	},
	{
		garage_id: 221,
		part_id: 11,
		quantity: 283,
	},
	{
		garage_id: 221,
		part_id: 12,
		quantity: 60,
	},
	{
		garage_id: 221,
		part_id: 13,
		quantity: 330,
	},
	{
		garage_id: 221,
		part_id: 14,
		quantity: 108,
	},
	{
		garage_id: 221,
		part_id: 15,
		quantity: 421,
	},
	{
		garage_id: 221,
		part_id: 16,
		quantity: 382,
	},
	{
		garage_id: 221,
		part_id: 17,
		quantity: 292,
	},
	{
		garage_id: 221,
		part_id: 18,
		quantity: 154,
	},
	{
		garage_id: 221,
		part_id: 19,
		quantity: 394,
	},
	{
		garage_id: 221,
		part_id: 20,
		quantity: 200,
	},
	{
		garage_id: 221,
		part_id: 21,
		quantity: 474,
	},
	{
		garage_id: 221,
		part_id: 22,
		quantity: 262,
	},
	{
		garage_id: 221,
		part_id: 23,
		quantity: 487,
	},
	{
		garage_id: 221,
		part_id: 24,
		quantity: 453,
	},
	{
		garage_id: 221,
		part_id: 25,
		quantity: 274,
	},
	{
		garage_id: 221,
		part_id: 26,
		quantity: 273,
	},
	{
		garage_id: 221,
		part_id: 27,
		quantity: 175,
	},
	{
		garage_id: 221,
		part_id: 28,
		quantity: 352,
	},
	{
		garage_id: 221,
		part_id: 29,
		quantity: 461,
	},
	{
		garage_id: 221,
		part_id: 30,
		quantity: 302,
	},
	{
		garage_id: 221,
		part_id: 31,
		quantity: 190,
	},
	{
		garage_id: 221,
		part_id: 32,
		quantity: 223,
	},
	{
		garage_id: 221,
		part_id: 33,
		quantity: 311,
	},
	{
		garage_id: 221,
		part_id: 34,
		quantity: 348,
	},
	{
		garage_id: 221,
		part_id: 35,
		quantity: 363,
	},
	{
		garage_id: 221,
		part_id: 36,
		quantity: 491,
	},
	{
		garage_id: 221,
		part_id: 37,
		quantity: 10,
	},
	{
		garage_id: 221,
		part_id: 38,
		quantity: 159,
	},
	{
		garage_id: 221,
		part_id: 39,
		quantity: 366,
	},
	{
		garage_id: 221,
		part_id: 40,
		quantity: 233,
	},
	{
		garage_id: 221,
		part_id: 41,
		quantity: 294,
	},
	{
		garage_id: 221,
		part_id: 42,
		quantity: 449,
	},
	{
		garage_id: 221,
		part_id: 43,
		quantity: 406,
	},
	{
		garage_id: 221,
		part_id: 44,
		quantity: 495,
	},
	{
		garage_id: 221,
		part_id: 45,
		quantity: 357,
	},
	{
		garage_id: 221,
		part_id: 46,
		quantity: 66,
	},
	{
		garage_id: 221,
		part_id: 47,
		quantity: 262,
	},
	{
		garage_id: 221,
		part_id: 48,
		quantity: 53,
	},
	{
		garage_id: 221,
		part_id: 49,
		quantity: 336,
	},
	{
		garage_id: 221,
		part_id: 50,
		quantity: 81,
	},
	{
		garage_id: 221,
		part_id: 51,
		quantity: 162,
	},
	{
		garage_id: 221,
		part_id: 52,
		quantity: 93,
	},
	{
		garage_id: 221,
		part_id: 53,
		quantity: 1,
	},
	{
		garage_id: 221,
		part_id: 54,
		quantity: 397,
	},
	{
		garage_id: 221,
		part_id: 55,
		quantity: 15,
	},
	{
		garage_id: 221,
		part_id: 56,
		quantity: 369,
	},
	{
		garage_id: 221,
		part_id: 57,
		quantity: 386,
	},
	{
		garage_id: 221,
		part_id: 58,
		quantity: 315,
	},
	{
		garage_id: 221,
		part_id: 59,
		quantity: 50,
	},
	{
		garage_id: 221,
		part_id: 60,
		quantity: 414,
	},
	{
		garage_id: 221,
		part_id: 61,
		quantity: 17,
	},
	{
		garage_id: 221,
		part_id: 62,
		quantity: 445,
	},
	{
		garage_id: 221,
		part_id: 63,
		quantity: 371,
	},
	{
		garage_id: 221,
		part_id: 64,
		quantity: 269,
	},
	{
		garage_id: 221,
		part_id: 65,
		quantity: 473,
	},
	{
		garage_id: 221,
		part_id: 66,
		quantity: 132,
	},
	{
		garage_id: 221,
		part_id: 67,
		quantity: 181,
	},
	{
		garage_id: 221,
		part_id: 68,
		quantity: 420,
	},
	{
		garage_id: 221,
		part_id: 69,
		quantity: 156,
	},
	{
		garage_id: 221,
		part_id: 70,
		quantity: 108,
	},
	{
		garage_id: 221,
		part_id: 71,
		quantity: 162,
	},
	{
		garage_id: 221,
		part_id: 72,
		quantity: 134,
	},
	{
		garage_id: 221,
		part_id: 73,
		quantity: 463,
	},
	{
		garage_id: 221,
		part_id: 74,
		quantity: 53,
	},
	{
		garage_id: 221,
		part_id: 75,
		quantity: 394,
	},
	{
		garage_id: 221,
		part_id: 76,
		quantity: 91,
	},
	{
		garage_id: 221,
		part_id: 77,
		quantity: 280,
	},
	{
		garage_id: 221,
		part_id: 78,
		quantity: 313,
	},
	{
		garage_id: 221,
		part_id: 79,
		quantity: 349,
	},
	{
		garage_id: 221,
		part_id: 80,
		quantity: 189,
	},
	{
		garage_id: 221,
		part_id: 81,
		quantity: 393,
	},
	{
		garage_id: 221,
		part_id: 82,
		quantity: 461,
	},
	{
		garage_id: 221,
		part_id: 83,
		quantity: 372,
	},
	{
		garage_id: 221,
		part_id: 84,
		quantity: 15,
	},
	{
		garage_id: 221,
		part_id: 85,
		quantity: 375,
	},
	{
		garage_id: 221,
		part_id: 86,
		quantity: 437,
	},
	{
		garage_id: 221,
		part_id: 87,
		quantity: 243,
	},
	{
		garage_id: 221,
		part_id: 88,
		quantity: 458,
	},
	{
		garage_id: 221,
		part_id: 89,
		quantity: 222,
	},
	{
		garage_id: 221,
		part_id: 90,
		quantity: 221,
	},
	{
		garage_id: 221,
		part_id: 91,
		quantity: 289,
	},
	{
		garage_id: 221,
		part_id: 92,
		quantity: 141,
	},
	{
		garage_id: 221,
		part_id: 93,
		quantity: 62,
	},
	{
		garage_id: 221,
		part_id: 94,
		quantity: 430,
	},
	{
		garage_id: 221,
		part_id: 95,
		quantity: 430,
	},
	{
		garage_id: 221,
		part_id: 96,
		quantity: 357,
	},
	{
		garage_id: 221,
		part_id: 97,
		quantity: 62,
	},
	{
		garage_id: 221,
		part_id: 98,
		quantity: 215,
	},
	{
		garage_id: 221,
		part_id: 99,
		quantity: 7,
	},
	{
		garage_id: 221,
		part_id: 100,
		quantity: 169,
	},
	{
		garage_id: 221,
		part_id: 101,
		quantity: 10,
	},
	{
		garage_id: 221,
		part_id: 102,
		quantity: 365,
	},
	{
		garage_id: 221,
		part_id: 103,
		quantity: 207,
	},
	{
		garage_id: 221,
		part_id: 104,
		quantity: 339,
	},
	{
		garage_id: 221,
		part_id: 105,
		quantity: 47,
	},
	{
		garage_id: 221,
		part_id: 106,
		quantity: 418,
	},
	{
		garage_id: 221,
		part_id: 107,
		quantity: 394,
	},
	{
		garage_id: 221,
		part_id: 108,
		quantity: 295,
	},
	{
		garage_id: 221,
		part_id: 109,
		quantity: 306,
	},
	{
		garage_id: 221,
		part_id: 110,
		quantity: 34,
	},
	{
		garage_id: 221,
		part_id: 111,
		quantity: 143,
	},
	{
		garage_id: 221,
		part_id: 112,
		quantity: 56,
	},
	{
		garage_id: 221,
		part_id: 113,
		quantity: 467,
	},
	{
		garage_id: 221,
		part_id: 114,
		quantity: 277,
	},
	{
		garage_id: 221,
		part_id: 115,
		quantity: 131,
	},
	{
		garage_id: 221,
		part_id: 116,
		quantity: 383,
	},
	{
		garage_id: 221,
		part_id: 117,
		quantity: 393,
	},
	{
		garage_id: 221,
		part_id: 118,
		quantity: 237,
	},
	{
		garage_id: 221,
		part_id: 119,
		quantity: 288,
	},
	{
		garage_id: 221,
		part_id: 120,
		quantity: 205,
	},
	{
		garage_id: 221,
		part_id: 121,
		quantity: 444,
	},
	{
		garage_id: 221,
		part_id: 122,
		quantity: 34,
	},
	{
		garage_id: 221,
		part_id: 123,
		quantity: 308,
	},
	{
		garage_id: 221,
		part_id: 124,
		quantity: 285,
	},
	{
		garage_id: 221,
		part_id: 125,
		quantity: 200,
	},
	{
		garage_id: 221,
		part_id: 126,
		quantity: 331,
	},
	{
		garage_id: 221,
		part_id: 127,
		quantity: 40,
	},
	{
		garage_id: 221,
		part_id: 128,
		quantity: 140,
	},
	{
		garage_id: 221,
		part_id: 129,
		quantity: 133,
	},
	{
		garage_id: 221,
		part_id: 130,
		quantity: 453,
	},
	{
		garage_id: 221,
		part_id: 131,
		quantity: 99,
	},
	{
		garage_id: 221,
		part_id: 132,
		quantity: 414,
	},
	{
		garage_id: 221,
		part_id: 133,
		quantity: 15,
	},
	{
		garage_id: 221,
		part_id: 134,
		quantity: 41,
	},
	{
		garage_id: 221,
		part_id: 135,
		quantity: 252,
	},
	{
		garage_id: 221,
		part_id: 136,
		quantity: 400,
	},
	{
		garage_id: 221,
		part_id: 137,
		quantity: 228,
	},
	{
		garage_id: 221,
		part_id: 138,
		quantity: 217,
	},
	{
		garage_id: 221,
		part_id: 139,
		quantity: 64,
	},
	{
		garage_id: 221,
		part_id: 140,
		quantity: 382,
	},
	{
		garage_id: 221,
		part_id: 141,
		quantity: 383,
	},
	{
		garage_id: 221,
		part_id: 142,
		quantity: 198,
	},
	{
		garage_id: 221,
		part_id: 143,
		quantity: 289,
	},
	{
		garage_id: 221,
		part_id: 144,
		quantity: 236,
	},
	{
		garage_id: 221,
		part_id: 145,
		quantity: 114,
	},
	{
		garage_id: 221,
		part_id: 146,
		quantity: 456,
	},
	{
		garage_id: 221,
		part_id: 147,
		quantity: 78,
	},
	{
		garage_id: 221,
		part_id: 148,
		quantity: 73,
	},
	{
		garage_id: 221,
		part_id: 149,
		quantity: 288,
	},
	{
		garage_id: 221,
		part_id: 150,
		quantity: 365,
	},
	{
		garage_id: 221,
		part_id: 151,
		quantity: 486,
	},
	{
		garage_id: 221,
		part_id: 152,
		quantity: 71,
	},
	{
		garage_id: 221,
		part_id: 153,
		quantity: 308,
	},
	{
		garage_id: 221,
		part_id: 154,
		quantity: 431,
	},
	{
		garage_id: 221,
		part_id: 155,
		quantity: 317,
	},
	{
		garage_id: 221,
		part_id: 156,
		quantity: 98,
	},
	{
		garage_id: 221,
		part_id: 157,
		quantity: 444,
	},
	{
		garage_id: 221,
		part_id: 158,
		quantity: 30,
	},
	{
		garage_id: 221,
		part_id: 159,
		quantity: 33,
	},
	{
		garage_id: 221,
		part_id: 160,
		quantity: 95,
	},
	{
		garage_id: 221,
		part_id: 161,
		quantity: 435,
	},
	{
		garage_id: 221,
		part_id: 162,
		quantity: 98,
	},
	{
		garage_id: 221,
		part_id: 163,
		quantity: 366,
	},
	{
		garage_id: 221,
		part_id: 164,
		quantity: 491,
	},
	{
		garage_id: 221,
		part_id: 165,
		quantity: 466,
	},
	{
		garage_id: 221,
		part_id: 166,
		quantity: 301,
	},
	{
		garage_id: 221,
		part_id: 167,
		quantity: 328,
	},
	{
		garage_id: 221,
		part_id: 168,
		quantity: 426,
	},
	{
		garage_id: 221,
		part_id: 169,
		quantity: 235,
	},
	{
		garage_id: 221,
		part_id: 170,
		quantity: 25,
	},
	{
		garage_id: 221,
		part_id: 171,
		quantity: 138,
	},
	{
		garage_id: 221,
		part_id: 172,
		quantity: 407,
	},
	{
		garage_id: 221,
		part_id: 173,
		quantity: 77,
	},
	{
		garage_id: 221,
		part_id: 174,
		quantity: 316,
	},
	{
		garage_id: 221,
		part_id: 175,
		quantity: 227,
	},
	{
		garage_id: 221,
		part_id: 176,
		quantity: 451,
	},
	{
		garage_id: 221,
		part_id: 177,
		quantity: 335,
	},
	{
		garage_id: 221,
		part_id: 178,
		quantity: 130,
	},
	{
		garage_id: 221,
		part_id: 179,
		quantity: 172,
	},
	{
		garage_id: 221,
		part_id: 180,
		quantity: 495,
	},
	{
		garage_id: 221,
		part_id: 181,
		quantity: 477,
	},
	{
		garage_id: 221,
		part_id: 182,
		quantity: 479,
	},
	{
		garage_id: 221,
		part_id: 183,
		quantity: 394,
	},
	{
		garage_id: 221,
		part_id: 184,
		quantity: 386,
	},
	{
		garage_id: 221,
		part_id: 185,
		quantity: 125,
	},
	{
		garage_id: 221,
		part_id: 186,
		quantity: 353,
	},
	{
		garage_id: 221,
		part_id: 187,
		quantity: 341,
	},
	{
		garage_id: 221,
		part_id: 188,
		quantity: 315,
	},
	{
		garage_id: 221,
		part_id: 189,
		quantity: 443,
	},
	{
		garage_id: 221,
		part_id: 190,
		quantity: 192,
	},
	{
		garage_id: 221,
		part_id: 191,
		quantity: 395,
	},
	{
		garage_id: 221,
		part_id: 192,
		quantity: 348,
	},
	{
		garage_id: 221,
		part_id: 193,
		quantity: 419,
	},
	{
		garage_id: 221,
		part_id: 194,
		quantity: 478,
	},
	{
		garage_id: 221,
		part_id: 195,
		quantity: 381,
	},
	{
		garage_id: 221,
		part_id: 196,
		quantity: 45,
	},
	{
		garage_id: 221,
		part_id: 197,
		quantity: 223,
	},
	{
		garage_id: 221,
		part_id: 198,
		quantity: 54,
	},
	{
		garage_id: 221,
		part_id: 199,
		quantity: 48,
	},
	{
		garage_id: 221,
		part_id: 200,
		quantity: 464,
	},
	{
		garage_id: 221,
		part_id: 201,
		quantity: 403,
	},
	{
		garage_id: 221,
		part_id: 202,
		quantity: 125,
	},
	{
		garage_id: 221,
		part_id: 203,
		quantity: 218,
	},
	{
		garage_id: 221,
		part_id: 204,
		quantity: 138,
	},
	{
		garage_id: 221,
		part_id: 205,
		quantity: 55,
	},
	{
		garage_id: 221,
		part_id: 206,
		quantity: 494,
	},
	{
		garage_id: 221,
		part_id: 207,
		quantity: 339,
	},
	{
		garage_id: 221,
		part_id: 208,
		quantity: 369,
	},
	{
		garage_id: 221,
		part_id: 209,
		quantity: 19,
	},
	{
		garage_id: 221,
		part_id: 210,
		quantity: 20,
	},
	{
		garage_id: 221,
		part_id: 211,
		quantity: 476,
	},
	{
		garage_id: 221,
		part_id: 212,
		quantity: 262,
	},
	{
		garage_id: 221,
		part_id: 213,
		quantity: 386,
	},
	{
		garage_id: 221,
		part_id: 214,
		quantity: 4,
	},
	{
		garage_id: 221,
		part_id: 215,
		quantity: 71,
	},
	{
		garage_id: 221,
		part_id: 216,
		quantity: 307,
	},
	{
		garage_id: 221,
		part_id: 217,
		quantity: 306,
	},
	{
		garage_id: 221,
		part_id: 218,
		quantity: 409,
	},
	{
		garage_id: 221,
		part_id: 219,
		quantity: 337,
	},
	{
		garage_id: 221,
		part_id: 220,
		quantity: 327,
	},
	{
		garage_id: 221,
		part_id: 221,
		quantity: 293,
	},
	{
		garage_id: 221,
		part_id: 222,
		quantity: 350,
	},
	{
		garage_id: 221,
		part_id: 223,
		quantity: 288,
	},
	{
		garage_id: 221,
		part_id: 224,
		quantity: 242,
	},
	{
		garage_id: 221,
		part_id: 225,
		quantity: 92,
	},
	{
		garage_id: 221,
		part_id: 226,
		quantity: 145,
	},
	{
		garage_id: 221,
		part_id: 227,
		quantity: 245,
	},
	{
		garage_id: 221,
		part_id: 228,
		quantity: 379,
	},
	{
		garage_id: 221,
		part_id: 229,
		quantity: 312,
	},
	{
		garage_id: 221,
		part_id: 230,
		quantity: 355,
	},
	{
		garage_id: 221,
		part_id: 231,
		quantity: 8,
	},
	{
		garage_id: 221,
		part_id: 232,
		quantity: 457,
	},
	{
		garage_id: 221,
		part_id: 233,
		quantity: 400,
	},
	{
		garage_id: 221,
		part_id: 234,
		quantity: 348,
	},
	{
		garage_id: 221,
		part_id: 235,
		quantity: 377,
	},
	{
		garage_id: 221,
		part_id: 236,
		quantity: 438,
	},
	{
		garage_id: 221,
		part_id: 237,
		quantity: 383,
	},
	{
		garage_id: 221,
		part_id: 238,
		quantity: 271,
	},
	{
		garage_id: 221,
		part_id: 239,
		quantity: 474,
	},
	{
		garage_id: 221,
		part_id: 240,
		quantity: 300,
	},
	{
		garage_id: 221,
		part_id: 241,
		quantity: 53,
	},
	{
		garage_id: 221,
		part_id: 242,
		quantity: 59,
	},
	{
		garage_id: 221,
		part_id: 243,
		quantity: 420,
	},
	{
		garage_id: 221,
		part_id: 244,
		quantity: 44,
	},
	{
		garage_id: 221,
		part_id: 245,
		quantity: 449,
	},
	{
		garage_id: 221,
		part_id: 246,
		quantity: 208,
	},
	{
		garage_id: 221,
		part_id: 247,
		quantity: 89,
	},
	{
		garage_id: 221,
		part_id: 248,
		quantity: 379,
	},
	{
		garage_id: 221,
		part_id: 249,
		quantity: 70,
	},
	{
		garage_id: 221,
		part_id: 250,
		quantity: 114,
	},
	{
		garage_id: 221,
		part_id: 251,
		quantity: 404,
	},
	{
		garage_id: 221,
		part_id: 252,
		quantity: 257,
	},
	{
		garage_id: 221,
		part_id: 253,
		quantity: 404,
	},
	{
		garage_id: 221,
		part_id: 254,
		quantity: 455,
	},
	{
		garage_id: 221,
		part_id: 255,
		quantity: 48,
	},
	{
		garage_id: 221,
		part_id: 256,
		quantity: 165,
	},
	{
		garage_id: 221,
		part_id: 257,
		quantity: 48,
	},
	{
		garage_id: 221,
		part_id: 258,
		quantity: 49,
	},
	{
		garage_id: 221,
		part_id: 259,
		quantity: 364,
	},
	{
		garage_id: 221,
		part_id: 260,
		quantity: 63,
	},
	{
		garage_id: 221,
		part_id: 261,
		quantity: 120,
	},
	{
		garage_id: 221,
		part_id: 262,
		quantity: 416,
	},
	{
		garage_id: 221,
		part_id: 263,
		quantity: 343,
	},
	{
		garage_id: 221,
		part_id: 264,
		quantity: 338,
	},
	{
		garage_id: 221,
		part_id: 265,
		quantity: 22,
	},
	{
		garage_id: 221,
		part_id: 266,
		quantity: 419,
	},
	{
		garage_id: 221,
		part_id: 267,
		quantity: 113,
	},
	{
		garage_id: 221,
		part_id: 268,
		quantity: 285,
	},
	{
		garage_id: 221,
		part_id: 269,
		quantity: 122,
	},
	{
		garage_id: 221,
		part_id: 270,
		quantity: 29,
	},
	{
		garage_id: 221,
		part_id: 271,
		quantity: 120,
	},
	{
		garage_id: 221,
		part_id: 272,
		quantity: 396,
	},
	{
		garage_id: 221,
		part_id: 273,
		quantity: 430,
	},
	{
		garage_id: 221,
		part_id: 274,
		quantity: 341,
	},
	{
		garage_id: 221,
		part_id: 275,
		quantity: 7,
	},
	{
		garage_id: 221,
		part_id: 276,
		quantity: 495,
	},
	{
		garage_id: 221,
		part_id: 277,
		quantity: 439,
	},
	{
		garage_id: 221,
		part_id: 278,
		quantity: 403,
	},
	{
		garage_id: 221,
		part_id: 279,
		quantity: 276,
	},
	{
		garage_id: 221,
		part_id: 280,
		quantity: 139,
	},
	{
		garage_id: 221,
		part_id: 281,
		quantity: 251,
	},
	{
		garage_id: 221,
		part_id: 282,
		quantity: 101,
	},
	{
		garage_id: 221,
		part_id: 283,
		quantity: 74,
	},
	{
		garage_id: 221,
		part_id: 284,
		quantity: 51,
	},
	{
		garage_id: 221,
		part_id: 285,
		quantity: 211,
	},
	{
		garage_id: 221,
		part_id: 286,
		quantity: 196,
	},
	{
		garage_id: 221,
		part_id: 287,
		quantity: 493,
	},
	{
		garage_id: 221,
		part_id: 288,
		quantity: 148,
	},
	{
		garage_id: 221,
		part_id: 289,
		quantity: 479,
	},
	{
		garage_id: 221,
		part_id: 290,
		quantity: 408,
	},
	{
		garage_id: 221,
		part_id: 291,
		quantity: 331,
	},
	{
		garage_id: 221,
		part_id: 292,
		quantity: 408,
	},
	{
		garage_id: 221,
		part_id: 293,
		quantity: 115,
	},
	{
		garage_id: 221,
		part_id: 294,
		quantity: 62,
	},
	{
		garage_id: 221,
		part_id: 295,
		quantity: 208,
	},
	{
		garage_id: 221,
		part_id: 296,
		quantity: 375,
	},
	{
		garage_id: 221,
		part_id: 297,
		quantity: 430,
	},
	{
		garage_id: 221,
		part_id: 298,
		quantity: 151,
	},
	{
		garage_id: 221,
		part_id: 299,
		quantity: 360,
	},
	{
		garage_id: 221,
		part_id: 300,
		quantity: 429,
	},
	{
		garage_id: 221,
		part_id: 301,
		quantity: 159,
	},
	{
		garage_id: 221,
		part_id: 302,
		quantity: 264,
	},
	{
		garage_id: 221,
		part_id: 303,
		quantity: 95,
	},
	{
		garage_id: 221,
		part_id: 304,
		quantity: 62,
	},
	{
		garage_id: 221,
		part_id: 305,
		quantity: 82,
	},
	{
		garage_id: 221,
		part_id: 306,
		quantity: 224,
	},
	{
		garage_id: 221,
		part_id: 307,
		quantity: 462,
	},
	{
		garage_id: 221,
		part_id: 308,
		quantity: 267,
	},
	{
		garage_id: 221,
		part_id: 309,
		quantity: 287,
	},
	{
		garage_id: 221,
		part_id: 310,
		quantity: 462,
	},
	{
		garage_id: 221,
		part_id: 311,
		quantity: 178,
	},
	{
		garage_id: 221,
		part_id: 312,
		quantity: 402,
	},
	{
		garage_id: 221,
		part_id: 313,
		quantity: 153,
	},
	{
		garage_id: 221,
		part_id: 314,
		quantity: 51,
	},
	{
		garage_id: 221,
		part_id: 315,
		quantity: 143,
	},
	{
		garage_id: 221,
		part_id: 316,
		quantity: 458,
	},
	{
		garage_id: 221,
		part_id: 317,
		quantity: 49,
	},
	{
		garage_id: 221,
		part_id: 318,
		quantity: 420,
	},
	{
		garage_id: 221,
		part_id: 319,
		quantity: 126,
	},
	{
		garage_id: 221,
		part_id: 320,
		quantity: 385,
	},
	{
		garage_id: 221,
		part_id: 321,
		quantity: 246,
	},
	{
		garage_id: 221,
		part_id: 322,
		quantity: 340,
	},
	{
		garage_id: 221,
		part_id: 323,
		quantity: 169,
	},
	{
		garage_id: 221,
		part_id: 324,
		quantity: 146,
	},
	{
		garage_id: 221,
		part_id: 325,
		quantity: 171,
	},
	{
		garage_id: 221,
		part_id: 326,
		quantity: 353,
	},
	{
		garage_id: 221,
		part_id: 327,
		quantity: 350,
	},
	{
		garage_id: 221,
		part_id: 328,
		quantity: 108,
	},
	{
		garage_id: 221,
		part_id: 329,
		quantity: 343,
	},
	{
		garage_id: 221,
		part_id: 330,
		quantity: 232,
	},
	{
		garage_id: 221,
		part_id: 331,
		quantity: 16,
	},
	{
		garage_id: 221,
		part_id: 332,
		quantity: 0,
	},
	{
		garage_id: 221,
		part_id: 333,
		quantity: 328,
	},
	{
		garage_id: 221,
		part_id: 334,
		quantity: 449,
	},
	{
		garage_id: 221,
		part_id: 335,
		quantity: 432,
	},
	{
		garage_id: 221,
		part_id: 336,
		quantity: 107,
	},
	{
		garage_id: 221,
		part_id: 337,
		quantity: 459,
	},
	{
		garage_id: 221,
		part_id: 338,
		quantity: 444,
	},
	{
		garage_id: 221,
		part_id: 339,
		quantity: 251,
	},
	{
		garage_id: 221,
		part_id: 340,
		quantity: 434,
	},
	{
		garage_id: 221,
		part_id: 341,
		quantity: 449,
	},
	{
		garage_id: 221,
		part_id: 342,
		quantity: 80,
	},
	{
		garage_id: 221,
		part_id: 343,
		quantity: 344,
	},
	{
		garage_id: 221,
		part_id: 344,
		quantity: 429,
	},
	{
		garage_id: 221,
		part_id: 345,
		quantity: 90,
	},
	{
		garage_id: 221,
		part_id: 346,
		quantity: 377,
	},
	{
		garage_id: 221,
		part_id: 347,
		quantity: 239,
	},
	{
		garage_id: 221,
		part_id: 348,
		quantity: 168,
	},
	{
		garage_id: 221,
		part_id: 349,
		quantity: 190,
	},
	{
		garage_id: 221,
		part_id: 350,
		quantity: 115,
	},
	{
		garage_id: 221,
		part_id: 351,
		quantity: 238,
	},
	{
		garage_id: 221,
		part_id: 352,
		quantity: 495,
	},
	{
		garage_id: 221,
		part_id: 353,
		quantity: 100,
	},
	{
		garage_id: 221,
		part_id: 354,
		quantity: 155,
	},
	{
		garage_id: 221,
		part_id: 355,
		quantity: 357,
	},
	{
		garage_id: 221,
		part_id: 356,
		quantity: 9,
	},
	{
		garage_id: 221,
		part_id: 357,
		quantity: 70,
	},
	{
		garage_id: 221,
		part_id: 358,
		quantity: 326,
	},
	{
		garage_id: 221,
		part_id: 359,
		quantity: 138,
	},
	{
		garage_id: 221,
		part_id: 360,
		quantity: 295,
	},
	{
		garage_id: 221,
		part_id: 361,
		quantity: 477,
	},
	{
		garage_id: 221,
		part_id: 362,
		quantity: 387,
	},
	{
		garage_id: 221,
		part_id: 363,
		quantity: 4,
	},
	{
		garage_id: 221,
		part_id: 364,
		quantity: 494,
	},
	{
		garage_id: 221,
		part_id: 365,
		quantity: 352,
	},
	{
		garage_id: 221,
		part_id: 366,
		quantity: 452,
	},
	{
		garage_id: 221,
		part_id: 367,
		quantity: 128,
	},
	{
		garage_id: 221,
		part_id: 368,
		quantity: 56,
	},
	{
		garage_id: 221,
		part_id: 369,
		quantity: 326,
	},
	{
		garage_id: 221,
		part_id: 370,
		quantity: 284,
	},
	{
		garage_id: 221,
		part_id: 371,
		quantity: 451,
	},
	{
		garage_id: 221,
		part_id: 372,
		quantity: 91,
	},
	{
		garage_id: 221,
		part_id: 373,
		quantity: 65,
	},
	{
		garage_id: 221,
		part_id: 374,
		quantity: 358,
	},
	{
		garage_id: 221,
		part_id: 375,
		quantity: 88,
	},
	{
		garage_id: 221,
		part_id: 376,
		quantity: 48,
	},
	{
		garage_id: 221,
		part_id: 377,
		quantity: 116,
	},
	{
		garage_id: 221,
		part_id: 378,
		quantity: 486,
	},
	{
		garage_id: 221,
		part_id: 379,
		quantity: 14,
	},
	{
		garage_id: 221,
		part_id: 380,
		quantity: 210,
	},
	{
		garage_id: 221,
		part_id: 381,
		quantity: 1,
	},
	{
		garage_id: 221,
		part_id: 382,
		quantity: 124,
	},
	{
		garage_id: 221,
		part_id: 383,
		quantity: 367,
	},
	{
		garage_id: 221,
		part_id: 384,
		quantity: 497,
	},
	{
		garage_id: 221,
		part_id: 385,
		quantity: 416,
	},
	{
		garage_id: 221,
		part_id: 386,
		quantity: 301,
	},
	{
		garage_id: 221,
		part_id: 387,
		quantity: 223,
	},
	{
		garage_id: 221,
		part_id: 388,
		quantity: 82,
	},
	{
		garage_id: 221,
		part_id: 389,
		quantity: 448,
	},
	{
		garage_id: 221,
		part_id: 390,
		quantity: 48,
	},
	{
		garage_id: 221,
		part_id: 391,
		quantity: 8,
	},
	{
		garage_id: 221,
		part_id: 392,
		quantity: 428,
	},
	{
		garage_id: 221,
		part_id: 393,
		quantity: 90,
	},
	{
		garage_id: 221,
		part_id: 394,
		quantity: 50,
	},
	{
		garage_id: 221,
		part_id: 395,
		quantity: 424,
	},
	{
		garage_id: 221,
		part_id: 396,
		quantity: 423,
	},
	{
		garage_id: 221,
		part_id: 397,
		quantity: 307,
	},
	{
		garage_id: 221,
		part_id: 398,
		quantity: 337,
	},
	{
		garage_id: 221,
		part_id: 399,
		quantity: 455,
	},
	{
		garage_id: 222,
		part_id: 0,
		quantity: 363,
	},
	{
		garage_id: 222,
		part_id: 1,
		quantity: 470,
	},
	{
		garage_id: 222,
		part_id: 2,
		quantity: 13,
	},
	{
		garage_id: 222,
		part_id: 3,
		quantity: 19,
	},
	{
		garage_id: 222,
		part_id: 4,
		quantity: 11,
	},
	{
		garage_id: 222,
		part_id: 5,
		quantity: 438,
	},
	{
		garage_id: 222,
		part_id: 6,
		quantity: 384,
	},
	{
		garage_id: 222,
		part_id: 7,
		quantity: 57,
	},
	{
		garage_id: 222,
		part_id: 8,
		quantity: 409,
	},
	{
		garage_id: 222,
		part_id: 9,
		quantity: 151,
	},
	{
		garage_id: 222,
		part_id: 10,
		quantity: 33,
	},
	{
		garage_id: 222,
		part_id: 11,
		quantity: 483,
	},
	{
		garage_id: 222,
		part_id: 12,
		quantity: 273,
	},
	{
		garage_id: 222,
		part_id: 13,
		quantity: 4,
	},
	{
		garage_id: 222,
		part_id: 14,
		quantity: 176,
	},
	{
		garage_id: 222,
		part_id: 15,
		quantity: 354,
	},
	{
		garage_id: 222,
		part_id: 16,
		quantity: 112,
	},
	{
		garage_id: 222,
		part_id: 17,
		quantity: 239,
	},
	{
		garage_id: 222,
		part_id: 18,
		quantity: 69,
	},
	{
		garage_id: 222,
		part_id: 19,
		quantity: 380,
	},
	{
		garage_id: 222,
		part_id: 20,
		quantity: 16,
	},
	{
		garage_id: 222,
		part_id: 21,
		quantity: 190,
	},
	{
		garage_id: 222,
		part_id: 22,
		quantity: 296,
	},
	{
		garage_id: 222,
		part_id: 23,
		quantity: 192,
	},
	{
		garage_id: 222,
		part_id: 24,
		quantity: 498,
	},
	{
		garage_id: 222,
		part_id: 25,
		quantity: 229,
	},
	{
		garage_id: 222,
		part_id: 26,
		quantity: 60,
	},
	{
		garage_id: 222,
		part_id: 27,
		quantity: 297,
	},
	{
		garage_id: 222,
		part_id: 28,
		quantity: 64,
	},
	{
		garage_id: 222,
		part_id: 29,
		quantity: 71,
	},
	{
		garage_id: 222,
		part_id: 30,
		quantity: 498,
	},
	{
		garage_id: 222,
		part_id: 31,
		quantity: 15,
	},
	{
		garage_id: 222,
		part_id: 32,
		quantity: 332,
	},
	{
		garage_id: 222,
		part_id: 33,
		quantity: 177,
	},
	{
		garage_id: 222,
		part_id: 34,
		quantity: 382,
	},
	{
		garage_id: 222,
		part_id: 35,
		quantity: 122,
	},
	{
		garage_id: 222,
		part_id: 36,
		quantity: 481,
	},
	{
		garage_id: 222,
		part_id: 37,
		quantity: 107,
	},
	{
		garage_id: 222,
		part_id: 38,
		quantity: 11,
	},
	{
		garage_id: 222,
		part_id: 39,
		quantity: 37,
	},
	{
		garage_id: 222,
		part_id: 40,
		quantity: 179,
	},
	{
		garage_id: 222,
		part_id: 41,
		quantity: 94,
	},
	{
		garage_id: 222,
		part_id: 42,
		quantity: 276,
	},
	{
		garage_id: 222,
		part_id: 43,
		quantity: 292,
	},
	{
		garage_id: 222,
		part_id: 44,
		quantity: 466,
	},
	{
		garage_id: 222,
		part_id: 45,
		quantity: 291,
	},
	{
		garage_id: 222,
		part_id: 46,
		quantity: 414,
	},
	{
		garage_id: 222,
		part_id: 47,
		quantity: 1,
	},
	{
		garage_id: 222,
		part_id: 48,
		quantity: 267,
	},
	{
		garage_id: 222,
		part_id: 49,
		quantity: 197,
	},
	{
		garage_id: 222,
		part_id: 50,
		quantity: 240,
	},
	{
		garage_id: 222,
		part_id: 51,
		quantity: 403,
	},
	{
		garage_id: 222,
		part_id: 52,
		quantity: 277,
	},
	{
		garage_id: 222,
		part_id: 53,
		quantity: 156,
	},
	{
		garage_id: 222,
		part_id: 54,
		quantity: 119,
	},
	{
		garage_id: 222,
		part_id: 55,
		quantity: 255,
	},
	{
		garage_id: 222,
		part_id: 56,
		quantity: 117,
	},
	{
		garage_id: 222,
		part_id: 57,
		quantity: 187,
	},
	{
		garage_id: 222,
		part_id: 58,
		quantity: 148,
	},
	{
		garage_id: 222,
		part_id: 59,
		quantity: 195,
	},
	{
		garage_id: 222,
		part_id: 60,
		quantity: 367,
	},
	{
		garage_id: 222,
		part_id: 61,
		quantity: 19,
	},
	{
		garage_id: 222,
		part_id: 62,
		quantity: 84,
	},
	{
		garage_id: 222,
		part_id: 63,
		quantity: 105,
	},
	{
		garage_id: 222,
		part_id: 64,
		quantity: 99,
	},
	{
		garage_id: 222,
		part_id: 65,
		quantity: 164,
	},
	{
		garage_id: 222,
		part_id: 66,
		quantity: 103,
	},
	{
		garage_id: 222,
		part_id: 67,
		quantity: 113,
	},
	{
		garage_id: 222,
		part_id: 68,
		quantity: 258,
	},
	{
		garage_id: 222,
		part_id: 69,
		quantity: 127,
	},
	{
		garage_id: 222,
		part_id: 70,
		quantity: 274,
	},
	{
		garage_id: 222,
		part_id: 71,
		quantity: 235,
	},
	{
		garage_id: 222,
		part_id: 72,
		quantity: 148,
	},
	{
		garage_id: 222,
		part_id: 73,
		quantity: 78,
	},
	{
		garage_id: 222,
		part_id: 74,
		quantity: 35,
	},
	{
		garage_id: 222,
		part_id: 75,
		quantity: 154,
	},
	{
		garage_id: 222,
		part_id: 76,
		quantity: 98,
	},
	{
		garage_id: 222,
		part_id: 77,
		quantity: 192,
	},
	{
		garage_id: 222,
		part_id: 78,
		quantity: 434,
	},
	{
		garage_id: 222,
		part_id: 79,
		quantity: 387,
	},
	{
		garage_id: 222,
		part_id: 80,
		quantity: 460,
	},
	{
		garage_id: 222,
		part_id: 81,
		quantity: 350,
	},
	{
		garage_id: 222,
		part_id: 82,
		quantity: 460,
	},
	{
		garage_id: 222,
		part_id: 83,
		quantity: 232,
	},
	{
		garage_id: 222,
		part_id: 84,
		quantity: 419,
	},
	{
		garage_id: 222,
		part_id: 85,
		quantity: 426,
	},
	{
		garage_id: 222,
		part_id: 86,
		quantity: 134,
	},
	{
		garage_id: 222,
		part_id: 87,
		quantity: 478,
	},
	{
		garage_id: 222,
		part_id: 88,
		quantity: 480,
	},
	{
		garage_id: 222,
		part_id: 89,
		quantity: 264,
	},
	{
		garage_id: 222,
		part_id: 90,
		quantity: 338,
	},
	{
		garage_id: 222,
		part_id: 91,
		quantity: 215,
	},
	{
		garage_id: 222,
		part_id: 92,
		quantity: 183,
	},
	{
		garage_id: 222,
		part_id: 93,
		quantity: 279,
	},
	{
		garage_id: 222,
		part_id: 94,
		quantity: 251,
	},
	{
		garage_id: 222,
		part_id: 95,
		quantity: 129,
	},
	{
		garage_id: 222,
		part_id: 96,
		quantity: 286,
	},
	{
		garage_id: 222,
		part_id: 97,
		quantity: 167,
	},
	{
		garage_id: 222,
		part_id: 98,
		quantity: 466,
	},
	{
		garage_id: 222,
		part_id: 99,
		quantity: 238,
	},
	{
		garage_id: 222,
		part_id: 100,
		quantity: 136,
	},
	{
		garage_id: 222,
		part_id: 101,
		quantity: 210,
	},
	{
		garage_id: 222,
		part_id: 102,
		quantity: 91,
	},
	{
		garage_id: 222,
		part_id: 103,
		quantity: 364,
	},
	{
		garage_id: 222,
		part_id: 104,
		quantity: 191,
	},
	{
		garage_id: 222,
		part_id: 105,
		quantity: 414,
	},
	{
		garage_id: 222,
		part_id: 106,
		quantity: 436,
	},
	{
		garage_id: 222,
		part_id: 107,
		quantity: 229,
	},
	{
		garage_id: 222,
		part_id: 108,
		quantity: 357,
	},
	{
		garage_id: 222,
		part_id: 109,
		quantity: 294,
	},
	{
		garage_id: 222,
		part_id: 110,
		quantity: 294,
	},
	{
		garage_id: 222,
		part_id: 111,
		quantity: 308,
	},
	{
		garage_id: 222,
		part_id: 112,
		quantity: 379,
	},
	{
		garage_id: 222,
		part_id: 113,
		quantity: 247,
	},
	{
		garage_id: 222,
		part_id: 114,
		quantity: 211,
	},
	{
		garage_id: 222,
		part_id: 115,
		quantity: 473,
	},
	{
		garage_id: 222,
		part_id: 116,
		quantity: 422,
	},
	{
		garage_id: 222,
		part_id: 117,
		quantity: 404,
	},
	{
		garage_id: 222,
		part_id: 118,
		quantity: 60,
	},
	{
		garage_id: 222,
		part_id: 119,
		quantity: 430,
	},
	{
		garage_id: 222,
		part_id: 120,
		quantity: 394,
	},
	{
		garage_id: 222,
		part_id: 121,
		quantity: 26,
	},
	{
		garage_id: 222,
		part_id: 122,
		quantity: 398,
	},
	{
		garage_id: 222,
		part_id: 123,
		quantity: 312,
	},
	{
		garage_id: 222,
		part_id: 124,
		quantity: 25,
	},
	{
		garage_id: 222,
		part_id: 125,
		quantity: 150,
	},
	{
		garage_id: 222,
		part_id: 126,
		quantity: 348,
	},
	{
		garage_id: 222,
		part_id: 127,
		quantity: 282,
	},
	{
		garage_id: 222,
		part_id: 128,
		quantity: 296,
	},
	{
		garage_id: 222,
		part_id: 129,
		quantity: 235,
	},
	{
		garage_id: 222,
		part_id: 130,
		quantity: 266,
	},
	{
		garage_id: 222,
		part_id: 131,
		quantity: 271,
	},
	{
		garage_id: 222,
		part_id: 132,
		quantity: 410,
	},
	{
		garage_id: 222,
		part_id: 133,
		quantity: 107,
	},
	{
		garage_id: 222,
		part_id: 134,
		quantity: 351,
	},
	{
		garage_id: 222,
		part_id: 135,
		quantity: 0,
	},
	{
		garage_id: 222,
		part_id: 136,
		quantity: 125,
	},
	{
		garage_id: 222,
		part_id: 137,
		quantity: 152,
	},
	{
		garage_id: 222,
		part_id: 138,
		quantity: 356,
	},
	{
		garage_id: 222,
		part_id: 139,
		quantity: 234,
	},
	{
		garage_id: 222,
		part_id: 140,
		quantity: 278,
	},
	{
		garage_id: 222,
		part_id: 141,
		quantity: 126,
	},
	{
		garage_id: 222,
		part_id: 142,
		quantity: 20,
	},
	{
		garage_id: 222,
		part_id: 143,
		quantity: 310,
	},
	{
		garage_id: 222,
		part_id: 144,
		quantity: 46,
	},
	{
		garage_id: 222,
		part_id: 145,
		quantity: 38,
	},
	{
		garage_id: 222,
		part_id: 146,
		quantity: 465,
	},
	{
		garage_id: 222,
		part_id: 147,
		quantity: 94,
	},
	{
		garage_id: 222,
		part_id: 148,
		quantity: 51,
	},
	{
		garage_id: 222,
		part_id: 149,
		quantity: 286,
	},
	{
		garage_id: 222,
		part_id: 150,
		quantity: 385,
	},
	{
		garage_id: 222,
		part_id: 151,
		quantity: 62,
	},
	{
		garage_id: 222,
		part_id: 152,
		quantity: 139,
	},
	{
		garage_id: 222,
		part_id: 153,
		quantity: 98,
	},
	{
		garage_id: 222,
		part_id: 154,
		quantity: 462,
	},
	{
		garage_id: 222,
		part_id: 155,
		quantity: 330,
	},
	{
		garage_id: 222,
		part_id: 156,
		quantity: 228,
	},
	{
		garage_id: 222,
		part_id: 157,
		quantity: 328,
	},
	{
		garage_id: 222,
		part_id: 158,
		quantity: 465,
	},
	{
		garage_id: 222,
		part_id: 159,
		quantity: 254,
	},
	{
		garage_id: 222,
		part_id: 160,
		quantity: 94,
	},
	{
		garage_id: 222,
		part_id: 161,
		quantity: 316,
	},
	{
		garage_id: 222,
		part_id: 162,
		quantity: 46,
	},
	{
		garage_id: 222,
		part_id: 163,
		quantity: 394,
	},
	{
		garage_id: 222,
		part_id: 164,
		quantity: 208,
	},
	{
		garage_id: 222,
		part_id: 165,
		quantity: 114,
	},
	{
		garage_id: 222,
		part_id: 166,
		quantity: 361,
	},
	{
		garage_id: 222,
		part_id: 167,
		quantity: 340,
	},
	{
		garage_id: 222,
		part_id: 168,
		quantity: 84,
	},
	{
		garage_id: 222,
		part_id: 169,
		quantity: 457,
	},
	{
		garage_id: 222,
		part_id: 170,
		quantity: 480,
	},
	{
		garage_id: 222,
		part_id: 171,
		quantity: 358,
	},
	{
		garage_id: 222,
		part_id: 172,
		quantity: 462,
	},
	{
		garage_id: 222,
		part_id: 173,
		quantity: 355,
	},
	{
		garage_id: 222,
		part_id: 174,
		quantity: 113,
	},
	{
		garage_id: 222,
		part_id: 175,
		quantity: 8,
	},
	{
		garage_id: 222,
		part_id: 176,
		quantity: 30,
	},
	{
		garage_id: 222,
		part_id: 177,
		quantity: 333,
	},
	{
		garage_id: 222,
		part_id: 178,
		quantity: 0,
	},
	{
		garage_id: 222,
		part_id: 179,
		quantity: 126,
	},
	{
		garage_id: 222,
		part_id: 180,
		quantity: 491,
	},
	{
		garage_id: 222,
		part_id: 181,
		quantity: 444,
	},
	{
		garage_id: 222,
		part_id: 182,
		quantity: 40,
	},
	{
		garage_id: 222,
		part_id: 183,
		quantity: 441,
	},
	{
		garage_id: 222,
		part_id: 184,
		quantity: 492,
	},
	{
		garage_id: 222,
		part_id: 185,
		quantity: 287,
	},
	{
		garage_id: 222,
		part_id: 186,
		quantity: 287,
	},
	{
		garage_id: 222,
		part_id: 187,
		quantity: 310,
	},
	{
		garage_id: 222,
		part_id: 188,
		quantity: 8,
	},
	{
		garage_id: 222,
		part_id: 189,
		quantity: 231,
	},
	{
		garage_id: 222,
		part_id: 190,
		quantity: 497,
	},
	{
		garage_id: 222,
		part_id: 191,
		quantity: 383,
	},
	{
		garage_id: 222,
		part_id: 192,
		quantity: 279,
	},
	{
		garage_id: 222,
		part_id: 193,
		quantity: 58,
	},
	{
		garage_id: 222,
		part_id: 194,
		quantity: 339,
	},
	{
		garage_id: 222,
		part_id: 195,
		quantity: 70,
	},
	{
		garage_id: 222,
		part_id: 196,
		quantity: 160,
	},
	{
		garage_id: 222,
		part_id: 197,
		quantity: 346,
	},
	{
		garage_id: 222,
		part_id: 198,
		quantity: 464,
	},
	{
		garage_id: 222,
		part_id: 199,
		quantity: 67,
	},
	{
		garage_id: 222,
		part_id: 200,
		quantity: 258,
	},
	{
		garage_id: 222,
		part_id: 201,
		quantity: 238,
	},
	{
		garage_id: 222,
		part_id: 202,
		quantity: 195,
	},
	{
		garage_id: 222,
		part_id: 203,
		quantity: 4,
	},
	{
		garage_id: 222,
		part_id: 204,
		quantity: 485,
	},
	{
		garage_id: 222,
		part_id: 205,
		quantity: 286,
	},
	{
		garage_id: 222,
		part_id: 206,
		quantity: 142,
	},
	{
		garage_id: 222,
		part_id: 207,
		quantity: 444,
	},
	{
		garage_id: 222,
		part_id: 208,
		quantity: 234,
	},
	{
		garage_id: 222,
		part_id: 209,
		quantity: 71,
	},
	{
		garage_id: 222,
		part_id: 210,
		quantity: 327,
	},
	{
		garage_id: 222,
		part_id: 211,
		quantity: 89,
	},
	{
		garage_id: 222,
		part_id: 212,
		quantity: 115,
	},
	{
		garage_id: 222,
		part_id: 213,
		quantity: 76,
	},
	{
		garage_id: 222,
		part_id: 214,
		quantity: 300,
	},
	{
		garage_id: 222,
		part_id: 215,
		quantity: 113,
	},
	{
		garage_id: 222,
		part_id: 216,
		quantity: 346,
	},
	{
		garage_id: 222,
		part_id: 217,
		quantity: 120,
	},
	{
		garage_id: 222,
		part_id: 218,
		quantity: 126,
	},
	{
		garage_id: 222,
		part_id: 219,
		quantity: 265,
	},
	{
		garage_id: 222,
		part_id: 220,
		quantity: 344,
	},
	{
		garage_id: 222,
		part_id: 221,
		quantity: 83,
	},
	{
		garage_id: 222,
		part_id: 222,
		quantity: 201,
	},
	{
		garage_id: 222,
		part_id: 223,
		quantity: 483,
	},
	{
		garage_id: 222,
		part_id: 224,
		quantity: 183,
	},
	{
		garage_id: 222,
		part_id: 225,
		quantity: 126,
	},
	{
		garage_id: 222,
		part_id: 226,
		quantity: 219,
	},
	{
		garage_id: 222,
		part_id: 227,
		quantity: 99,
	},
	{
		garage_id: 222,
		part_id: 228,
		quantity: 352,
	},
	{
		garage_id: 222,
		part_id: 229,
		quantity: 81,
	},
	{
		garage_id: 222,
		part_id: 230,
		quantity: 434,
	},
	{
		garage_id: 222,
		part_id: 231,
		quantity: 220,
	},
	{
		garage_id: 222,
		part_id: 232,
		quantity: 114,
	},
	{
		garage_id: 222,
		part_id: 233,
		quantity: 326,
	},
	{
		garage_id: 222,
		part_id: 234,
		quantity: 371,
	},
	{
		garage_id: 222,
		part_id: 235,
		quantity: 98,
	},
	{
		garage_id: 222,
		part_id: 236,
		quantity: 276,
	},
	{
		garage_id: 222,
		part_id: 237,
		quantity: 229,
	},
	{
		garage_id: 222,
		part_id: 238,
		quantity: 175,
	},
	{
		garage_id: 222,
		part_id: 239,
		quantity: 480,
	},
	{
		garage_id: 222,
		part_id: 240,
		quantity: 76,
	},
	{
		garage_id: 222,
		part_id: 241,
		quantity: 108,
	},
	{
		garage_id: 222,
		part_id: 242,
		quantity: 254,
	},
	{
		garage_id: 222,
		part_id: 243,
		quantity: 214,
	},
	{
		garage_id: 222,
		part_id: 244,
		quantity: 441,
	},
	{
		garage_id: 222,
		part_id: 245,
		quantity: 207,
	},
	{
		garage_id: 222,
		part_id: 246,
		quantity: 428,
	},
	{
		garage_id: 222,
		part_id: 247,
		quantity: 200,
	},
	{
		garage_id: 222,
		part_id: 248,
		quantity: 451,
	},
	{
		garage_id: 222,
		part_id: 249,
		quantity: 32,
	},
	{
		garage_id: 222,
		part_id: 250,
		quantity: 416,
	},
	{
		garage_id: 222,
		part_id: 251,
		quantity: 148,
	},
	{
		garage_id: 222,
		part_id: 252,
		quantity: 487,
	},
	{
		garage_id: 222,
		part_id: 253,
		quantity: 97,
	},
	{
		garage_id: 222,
		part_id: 254,
		quantity: 487,
	},
	{
		garage_id: 222,
		part_id: 255,
		quantity: 359,
	},
	{
		garage_id: 222,
		part_id: 256,
		quantity: 4,
	},
	{
		garage_id: 222,
		part_id: 257,
		quantity: 75,
	},
	{
		garage_id: 222,
		part_id: 258,
		quantity: 213,
	},
	{
		garage_id: 222,
		part_id: 259,
		quantity: 431,
	},
	{
		garage_id: 222,
		part_id: 260,
		quantity: 243,
	},
	{
		garage_id: 222,
		part_id: 261,
		quantity: 23,
	},
	{
		garage_id: 222,
		part_id: 262,
		quantity: 282,
	},
	{
		garage_id: 222,
		part_id: 263,
		quantity: 45,
	},
	{
		garage_id: 222,
		part_id: 264,
		quantity: 353,
	},
	{
		garage_id: 222,
		part_id: 265,
		quantity: 365,
	},
	{
		garage_id: 222,
		part_id: 266,
		quantity: 387,
	},
	{
		garage_id: 222,
		part_id: 267,
		quantity: 483,
	},
	{
		garage_id: 222,
		part_id: 268,
		quantity: 131,
	},
	{
		garage_id: 222,
		part_id: 269,
		quantity: 9,
	},
	{
		garage_id: 222,
		part_id: 270,
		quantity: 207,
	},
	{
		garage_id: 222,
		part_id: 271,
		quantity: 489,
	},
	{
		garage_id: 222,
		part_id: 272,
		quantity: 321,
	},
	{
		garage_id: 222,
		part_id: 273,
		quantity: 474,
	},
	{
		garage_id: 222,
		part_id: 274,
		quantity: 156,
	},
	{
		garage_id: 222,
		part_id: 275,
		quantity: 44,
	},
	{
		garage_id: 222,
		part_id: 276,
		quantity: 432,
	},
	{
		garage_id: 222,
		part_id: 277,
		quantity: 75,
	},
	{
		garage_id: 222,
		part_id: 278,
		quantity: 376,
	},
	{
		garage_id: 222,
		part_id: 279,
		quantity: 41,
	},
	{
		garage_id: 222,
		part_id: 280,
		quantity: 455,
	},
	{
		garage_id: 222,
		part_id: 281,
		quantity: 440,
	},
	{
		garage_id: 222,
		part_id: 282,
		quantity: 480,
	},
	{
		garage_id: 222,
		part_id: 283,
		quantity: 205,
	},
	{
		garage_id: 222,
		part_id: 284,
		quantity: 171,
	},
	{
		garage_id: 222,
		part_id: 285,
		quantity: 72,
	},
	{
		garage_id: 222,
		part_id: 286,
		quantity: 191,
	},
	{
		garage_id: 222,
		part_id: 287,
		quantity: 341,
	},
	{
		garage_id: 222,
		part_id: 288,
		quantity: 239,
	},
	{
		garage_id: 222,
		part_id: 289,
		quantity: 495,
	},
	{
		garage_id: 222,
		part_id: 290,
		quantity: 259,
	},
	{
		garage_id: 222,
		part_id: 291,
		quantity: 459,
	},
	{
		garage_id: 222,
		part_id: 292,
		quantity: 349,
	},
	{
		garage_id: 222,
		part_id: 293,
		quantity: 148,
	},
	{
		garage_id: 222,
		part_id: 294,
		quantity: 321,
	},
	{
		garage_id: 222,
		part_id: 295,
		quantity: 29,
	},
	{
		garage_id: 222,
		part_id: 296,
		quantity: 478,
	},
	{
		garage_id: 222,
		part_id: 297,
		quantity: 278,
	},
	{
		garage_id: 222,
		part_id: 298,
		quantity: 446,
	},
	{
		garage_id: 222,
		part_id: 299,
		quantity: 480,
	},
	{
		garage_id: 222,
		part_id: 300,
		quantity: 56,
	},
	{
		garage_id: 222,
		part_id: 301,
		quantity: 10,
	},
	{
		garage_id: 222,
		part_id: 302,
		quantity: 246,
	},
	{
		garage_id: 222,
		part_id: 303,
		quantity: 397,
	},
	{
		garage_id: 222,
		part_id: 304,
		quantity: 78,
	},
	{
		garage_id: 222,
		part_id: 305,
		quantity: 347,
	},
	{
		garage_id: 222,
		part_id: 306,
		quantity: 30,
	},
	{
		garage_id: 222,
		part_id: 307,
		quantity: 436,
	},
	{
		garage_id: 222,
		part_id: 308,
		quantity: 407,
	},
	{
		garage_id: 222,
		part_id: 309,
		quantity: 419,
	},
	{
		garage_id: 222,
		part_id: 310,
		quantity: 308,
	},
	{
		garage_id: 222,
		part_id: 311,
		quantity: 377,
	},
	{
		garage_id: 222,
		part_id: 312,
		quantity: 198,
	},
	{
		garage_id: 222,
		part_id: 313,
		quantity: 14,
	},
	{
		garage_id: 222,
		part_id: 314,
		quantity: 189,
	},
	{
		garage_id: 222,
		part_id: 315,
		quantity: 214,
	},
	{
		garage_id: 222,
		part_id: 316,
		quantity: 320,
	},
	{
		garage_id: 222,
		part_id: 317,
		quantity: 212,
	},
	{
		garage_id: 222,
		part_id: 318,
		quantity: 266,
	},
	{
		garage_id: 222,
		part_id: 319,
		quantity: 274,
	},
	{
		garage_id: 222,
		part_id: 320,
		quantity: 232,
	},
	{
		garage_id: 222,
		part_id: 321,
		quantity: 333,
	},
	{
		garage_id: 222,
		part_id: 322,
		quantity: 477,
	},
	{
		garage_id: 222,
		part_id: 323,
		quantity: 271,
	},
	{
		garage_id: 222,
		part_id: 324,
		quantity: 384,
	},
	{
		garage_id: 222,
		part_id: 325,
		quantity: 74,
	},
	{
		garage_id: 222,
		part_id: 326,
		quantity: 393,
	},
	{
		garage_id: 222,
		part_id: 327,
		quantity: 361,
	},
	{
		garage_id: 222,
		part_id: 328,
		quantity: 181,
	},
	{
		garage_id: 222,
		part_id: 329,
		quantity: 67,
	},
	{
		garage_id: 222,
		part_id: 330,
		quantity: 140,
	},
	{
		garage_id: 222,
		part_id: 331,
		quantity: 37,
	},
	{
		garage_id: 222,
		part_id: 332,
		quantity: 270,
	},
	{
		garage_id: 222,
		part_id: 333,
		quantity: 190,
	},
	{
		garage_id: 222,
		part_id: 334,
		quantity: 402,
	},
	{
		garage_id: 222,
		part_id: 335,
		quantity: 454,
	},
	{
		garage_id: 222,
		part_id: 336,
		quantity: 482,
	},
	{
		garage_id: 222,
		part_id: 337,
		quantity: 77,
	},
	{
		garage_id: 222,
		part_id: 338,
		quantity: 354,
	},
	{
		garage_id: 222,
		part_id: 339,
		quantity: 483,
	},
	{
		garage_id: 222,
		part_id: 340,
		quantity: 336,
	},
	{
		garage_id: 222,
		part_id: 341,
		quantity: 464,
	},
	{
		garage_id: 222,
		part_id: 342,
		quantity: 124,
	},
	{
		garage_id: 222,
		part_id: 343,
		quantity: 192,
	},
	{
		garage_id: 222,
		part_id: 344,
		quantity: 298,
	},
	{
		garage_id: 222,
		part_id: 345,
		quantity: 309,
	},
	{
		garage_id: 222,
		part_id: 346,
		quantity: 267,
	},
	{
		garage_id: 222,
		part_id: 347,
		quantity: 299,
	},
	{
		garage_id: 222,
		part_id: 348,
		quantity: 74,
	},
	{
		garage_id: 222,
		part_id: 349,
		quantity: 120,
	},
	{
		garage_id: 222,
		part_id: 350,
		quantity: 278,
	},
	{
		garage_id: 222,
		part_id: 351,
		quantity: 362,
	},
	{
		garage_id: 222,
		part_id: 352,
		quantity: 477,
	},
	{
		garage_id: 222,
		part_id: 353,
		quantity: 122,
	},
	{
		garage_id: 222,
		part_id: 354,
		quantity: 277,
	},
	{
		garage_id: 222,
		part_id: 355,
		quantity: 90,
	},
	{
		garage_id: 222,
		part_id: 356,
		quantity: 405,
	},
	{
		garage_id: 222,
		part_id: 357,
		quantity: 375,
	},
	{
		garage_id: 222,
		part_id: 358,
		quantity: 23,
	},
	{
		garage_id: 222,
		part_id: 359,
		quantity: 117,
	},
	{
		garage_id: 222,
		part_id: 360,
		quantity: 207,
	},
	{
		garage_id: 222,
		part_id: 361,
		quantity: 458,
	},
	{
		garage_id: 222,
		part_id: 362,
		quantity: 462,
	},
	{
		garage_id: 222,
		part_id: 363,
		quantity: 262,
	},
	{
		garage_id: 222,
		part_id: 364,
		quantity: 447,
	},
	{
		garage_id: 222,
		part_id: 365,
		quantity: 171,
	},
	{
		garage_id: 222,
		part_id: 366,
		quantity: 107,
	},
	{
		garage_id: 222,
		part_id: 367,
		quantity: 346,
	},
	{
		garage_id: 222,
		part_id: 368,
		quantity: 243,
	},
	{
		garage_id: 222,
		part_id: 369,
		quantity: 485,
	},
	{
		garage_id: 222,
		part_id: 370,
		quantity: 319,
	},
	{
		garage_id: 222,
		part_id: 371,
		quantity: 35,
	},
	{
		garage_id: 222,
		part_id: 372,
		quantity: 308,
	},
	{
		garage_id: 222,
		part_id: 373,
		quantity: 8,
	},
	{
		garage_id: 222,
		part_id: 374,
		quantity: 433,
	},
	{
		garage_id: 222,
		part_id: 375,
		quantity: 156,
	},
	{
		garage_id: 222,
		part_id: 376,
		quantity: 217,
	},
	{
		garage_id: 222,
		part_id: 377,
		quantity: 162,
	},
	{
		garage_id: 222,
		part_id: 378,
		quantity: 257,
	},
	{
		garage_id: 222,
		part_id: 379,
		quantity: 131,
	},
	{
		garage_id: 222,
		part_id: 380,
		quantity: 110,
	},
	{
		garage_id: 222,
		part_id: 381,
		quantity: 143,
	},
	{
		garage_id: 222,
		part_id: 382,
		quantity: 431,
	},
	{
		garage_id: 222,
		part_id: 383,
		quantity: 334,
	},
	{
		garage_id: 222,
		part_id: 384,
		quantity: 449,
	},
	{
		garage_id: 222,
		part_id: 385,
		quantity: 286,
	},
	{
		garage_id: 222,
		part_id: 386,
		quantity: 261,
	},
	{
		garage_id: 222,
		part_id: 387,
		quantity: 118,
	},
	{
		garage_id: 222,
		part_id: 388,
		quantity: 431,
	},
	{
		garage_id: 222,
		part_id: 389,
		quantity: 17,
	},
	{
		garage_id: 222,
		part_id: 390,
		quantity: 101,
	},
	{
		garage_id: 222,
		part_id: 391,
		quantity: 175,
	},
	{
		garage_id: 222,
		part_id: 392,
		quantity: 190,
	},
	{
		garage_id: 222,
		part_id: 393,
		quantity: 6,
	},
	{
		garage_id: 222,
		part_id: 394,
		quantity: 310,
	},
	{
		garage_id: 222,
		part_id: 395,
		quantity: 399,
	},
	{
		garage_id: 222,
		part_id: 396,
		quantity: 328,
	},
	{
		garage_id: 222,
		part_id: 397,
		quantity: 162,
	},
	{
		garage_id: 222,
		part_id: 398,
		quantity: 237,
	},
	{
		garage_id: 222,
		part_id: 399,
		quantity: 146,
	},
	{
		garage_id: 223,
		part_id: 0,
		quantity: 488,
	},
	{
		garage_id: 223,
		part_id: 1,
		quantity: 321,
	},
	{
		garage_id: 223,
		part_id: 2,
		quantity: 267,
	},
	{
		garage_id: 223,
		part_id: 3,
		quantity: 161,
	},
	{
		garage_id: 223,
		part_id: 4,
		quantity: 388,
	},
	{
		garage_id: 223,
		part_id: 5,
		quantity: 361,
	},
	{
		garage_id: 223,
		part_id: 6,
		quantity: 366,
	},
	{
		garage_id: 223,
		part_id: 7,
		quantity: 161,
	},
	{
		garage_id: 223,
		part_id: 8,
		quantity: 469,
	},
	{
		garage_id: 223,
		part_id: 9,
		quantity: 453,
	},
	{
		garage_id: 223,
		part_id: 10,
		quantity: 293,
	},
	{
		garage_id: 223,
		part_id: 11,
		quantity: 120,
	},
	{
		garage_id: 223,
		part_id: 12,
		quantity: 422,
	},
	{
		garage_id: 223,
		part_id: 13,
		quantity: 400,
	},
	{
		garage_id: 223,
		part_id: 14,
		quantity: 460,
	},
	{
		garage_id: 223,
		part_id: 15,
		quantity: 89,
	},
	{
		garage_id: 223,
		part_id: 16,
		quantity: 14,
	},
	{
		garage_id: 223,
		part_id: 17,
		quantity: 39,
	},
	{
		garage_id: 223,
		part_id: 18,
		quantity: 357,
	},
	{
		garage_id: 223,
		part_id: 19,
		quantity: 458,
	},
	{
		garage_id: 223,
		part_id: 20,
		quantity: 30,
	},
	{
		garage_id: 223,
		part_id: 21,
		quantity: 125,
	},
	{
		garage_id: 223,
		part_id: 22,
		quantity: 440,
	},
	{
		garage_id: 223,
		part_id: 23,
		quantity: 38,
	},
	{
		garage_id: 223,
		part_id: 24,
		quantity: 195,
	},
	{
		garage_id: 223,
		part_id: 25,
		quantity: 141,
	},
	{
		garage_id: 223,
		part_id: 26,
		quantity: 438,
	},
	{
		garage_id: 223,
		part_id: 27,
		quantity: 152,
	},
	{
		garage_id: 223,
		part_id: 28,
		quantity: 79,
	},
	{
		garage_id: 223,
		part_id: 29,
		quantity: 274,
	},
	{
		garage_id: 223,
		part_id: 30,
		quantity: 66,
	},
	{
		garage_id: 223,
		part_id: 31,
		quantity: 273,
	},
	{
		garage_id: 223,
		part_id: 32,
		quantity: 98,
	},
	{
		garage_id: 223,
		part_id: 33,
		quantity: 56,
	},
	{
		garage_id: 223,
		part_id: 34,
		quantity: 23,
	},
	{
		garage_id: 223,
		part_id: 35,
		quantity: 148,
	},
	{
		garage_id: 223,
		part_id: 36,
		quantity: 230,
	},
	{
		garage_id: 223,
		part_id: 37,
		quantity: 424,
	},
	{
		garage_id: 223,
		part_id: 38,
		quantity: 473,
	},
	{
		garage_id: 223,
		part_id: 39,
		quantity: 405,
	},
	{
		garage_id: 223,
		part_id: 40,
		quantity: 220,
	},
	{
		garage_id: 223,
		part_id: 41,
		quantity: 343,
	},
	{
		garage_id: 223,
		part_id: 42,
		quantity: 288,
	},
	{
		garage_id: 223,
		part_id: 43,
		quantity: 415,
	},
	{
		garage_id: 223,
		part_id: 44,
		quantity: 340,
	},
	{
		garage_id: 223,
		part_id: 45,
		quantity: 449,
	},
	{
		garage_id: 223,
		part_id: 46,
		quantity: 112,
	},
	{
		garage_id: 223,
		part_id: 47,
		quantity: 222,
	},
	{
		garage_id: 223,
		part_id: 48,
		quantity: 445,
	},
	{
		garage_id: 223,
		part_id: 49,
		quantity: 430,
	},
	{
		garage_id: 223,
		part_id: 50,
		quantity: 111,
	},
	{
		garage_id: 223,
		part_id: 51,
		quantity: 359,
	},
	{
		garage_id: 223,
		part_id: 52,
		quantity: 305,
	},
	{
		garage_id: 223,
		part_id: 53,
		quantity: 123,
	},
	{
		garage_id: 223,
		part_id: 54,
		quantity: 367,
	},
	{
		garage_id: 223,
		part_id: 55,
		quantity: 405,
	},
	{
		garage_id: 223,
		part_id: 56,
		quantity: 15,
	},
	{
		garage_id: 223,
		part_id: 57,
		quantity: 288,
	},
	{
		garage_id: 223,
		part_id: 58,
		quantity: 89,
	},
	{
		garage_id: 223,
		part_id: 59,
		quantity: 283,
	},
	{
		garage_id: 223,
		part_id: 60,
		quantity: 154,
	},
	{
		garage_id: 223,
		part_id: 61,
		quantity: 461,
	},
	{
		garage_id: 223,
		part_id: 62,
		quantity: 488,
	},
	{
		garage_id: 223,
		part_id: 63,
		quantity: 348,
	},
	{
		garage_id: 223,
		part_id: 64,
		quantity: 495,
	},
	{
		garage_id: 223,
		part_id: 65,
		quantity: 38,
	},
	{
		garage_id: 223,
		part_id: 66,
		quantity: 388,
	},
	{
		garage_id: 223,
		part_id: 67,
		quantity: 44,
	},
	{
		garage_id: 223,
		part_id: 68,
		quantity: 429,
	},
	{
		garage_id: 223,
		part_id: 69,
		quantity: 360,
	},
	{
		garage_id: 223,
		part_id: 70,
		quantity: 216,
	},
	{
		garage_id: 223,
		part_id: 71,
		quantity: 190,
	},
	{
		garage_id: 223,
		part_id: 72,
		quantity: 203,
	},
	{
		garage_id: 223,
		part_id: 73,
		quantity: 472,
	},
	{
		garage_id: 223,
		part_id: 74,
		quantity: 186,
	},
	{
		garage_id: 223,
		part_id: 75,
		quantity: 363,
	},
	{
		garage_id: 223,
		part_id: 76,
		quantity: 392,
	},
	{
		garage_id: 223,
		part_id: 77,
		quantity: 313,
	},
	{
		garage_id: 223,
		part_id: 78,
		quantity: 264,
	},
	{
		garage_id: 223,
		part_id: 79,
		quantity: 283,
	},
	{
		garage_id: 223,
		part_id: 80,
		quantity: 269,
	},
	{
		garage_id: 223,
		part_id: 81,
		quantity: 336,
	},
	{
		garage_id: 223,
		part_id: 82,
		quantity: 321,
	},
	{
		garage_id: 223,
		part_id: 83,
		quantity: 0,
	},
	{
		garage_id: 223,
		part_id: 84,
		quantity: 165,
	},
	{
		garage_id: 223,
		part_id: 85,
		quantity: 138,
	},
	{
		garage_id: 223,
		part_id: 86,
		quantity: 14,
	},
	{
		garage_id: 223,
		part_id: 87,
		quantity: 306,
	},
	{
		garage_id: 223,
		part_id: 88,
		quantity: 405,
	},
	{
		garage_id: 223,
		part_id: 89,
		quantity: 285,
	},
	{
		garage_id: 223,
		part_id: 90,
		quantity: 373,
	},
	{
		garage_id: 223,
		part_id: 91,
		quantity: 43,
	},
	{
		garage_id: 223,
		part_id: 92,
		quantity: 48,
	},
	{
		garage_id: 223,
		part_id: 93,
		quantity: 102,
	},
	{
		garage_id: 223,
		part_id: 94,
		quantity: 285,
	},
	{
		garage_id: 223,
		part_id: 95,
		quantity: 98,
	},
	{
		garage_id: 223,
		part_id: 96,
		quantity: 30,
	},
	{
		garage_id: 223,
		part_id: 97,
		quantity: 361,
	},
	{
		garage_id: 223,
		part_id: 98,
		quantity: 90,
	},
	{
		garage_id: 223,
		part_id: 99,
		quantity: 494,
	},
	{
		garage_id: 223,
		part_id: 100,
		quantity: 53,
	},
	{
		garage_id: 223,
		part_id: 101,
		quantity: 205,
	},
	{
		garage_id: 223,
		part_id: 102,
		quantity: 20,
	},
	{
		garage_id: 223,
		part_id: 103,
		quantity: 429,
	},
	{
		garage_id: 223,
		part_id: 104,
		quantity: 172,
	},
	{
		garage_id: 223,
		part_id: 105,
		quantity: 42,
	},
	{
		garage_id: 223,
		part_id: 106,
		quantity: 372,
	},
	{
		garage_id: 223,
		part_id: 107,
		quantity: 230,
	},
	{
		garage_id: 223,
		part_id: 108,
		quantity: 290,
	},
	{
		garage_id: 223,
		part_id: 109,
		quantity: 401,
	},
	{
		garage_id: 223,
		part_id: 110,
		quantity: 274,
	},
	{
		garage_id: 223,
		part_id: 111,
		quantity: 360,
	},
	{
		garage_id: 223,
		part_id: 112,
		quantity: 36,
	},
	{
		garage_id: 223,
		part_id: 113,
		quantity: 364,
	},
	{
		garage_id: 223,
		part_id: 114,
		quantity: 232,
	},
	{
		garage_id: 223,
		part_id: 115,
		quantity: 147,
	},
	{
		garage_id: 223,
		part_id: 116,
		quantity: 32,
	},
	{
		garage_id: 223,
		part_id: 117,
		quantity: 96,
	},
	{
		garage_id: 223,
		part_id: 118,
		quantity: 161,
	},
	{
		garage_id: 223,
		part_id: 119,
		quantity: 208,
	},
	{
		garage_id: 223,
		part_id: 120,
		quantity: 424,
	},
	{
		garage_id: 223,
		part_id: 121,
		quantity: 187,
	},
	{
		garage_id: 223,
		part_id: 122,
		quantity: 118,
	},
	{
		garage_id: 223,
		part_id: 123,
		quantity: 57,
	},
	{
		garage_id: 223,
		part_id: 124,
		quantity: 462,
	},
	{
		garage_id: 223,
		part_id: 125,
		quantity: 348,
	},
	{
		garage_id: 223,
		part_id: 126,
		quantity: 455,
	},
	{
		garage_id: 223,
		part_id: 127,
		quantity: 37,
	},
	{
		garage_id: 223,
		part_id: 128,
		quantity: 150,
	},
	{
		garage_id: 223,
		part_id: 129,
		quantity: 471,
	},
	{
		garage_id: 223,
		part_id: 130,
		quantity: 447,
	},
	{
		garage_id: 223,
		part_id: 131,
		quantity: 148,
	},
	{
		garage_id: 223,
		part_id: 132,
		quantity: 241,
	},
	{
		garage_id: 223,
		part_id: 133,
		quantity: 228,
	},
	{
		garage_id: 223,
		part_id: 134,
		quantity: 231,
	},
	{
		garage_id: 223,
		part_id: 135,
		quantity: 6,
	},
	{
		garage_id: 223,
		part_id: 136,
		quantity: 268,
	},
	{
		garage_id: 223,
		part_id: 137,
		quantity: 10,
	},
	{
		garage_id: 223,
		part_id: 138,
		quantity: 99,
	},
	{
		garage_id: 223,
		part_id: 139,
		quantity: 107,
	},
	{
		garage_id: 223,
		part_id: 140,
		quantity: 361,
	},
	{
		garage_id: 223,
		part_id: 141,
		quantity: 389,
	},
	{
		garage_id: 223,
		part_id: 142,
		quantity: 146,
	},
	{
		garage_id: 223,
		part_id: 143,
		quantity: 185,
	},
	{
		garage_id: 223,
		part_id: 144,
		quantity: 457,
	},
	{
		garage_id: 223,
		part_id: 145,
		quantity: 312,
	},
	{
		garage_id: 223,
		part_id: 146,
		quantity: 128,
	},
	{
		garage_id: 223,
		part_id: 147,
		quantity: 397,
	},
	{
		garage_id: 223,
		part_id: 148,
		quantity: 455,
	},
	{
		garage_id: 223,
		part_id: 149,
		quantity: 45,
	},
	{
		garage_id: 223,
		part_id: 150,
		quantity: 308,
	},
	{
		garage_id: 223,
		part_id: 151,
		quantity: 258,
	},
	{
		garage_id: 223,
		part_id: 152,
		quantity: 41,
	},
	{
		garage_id: 223,
		part_id: 153,
		quantity: 118,
	},
	{
		garage_id: 223,
		part_id: 154,
		quantity: 154,
	},
	{
		garage_id: 223,
		part_id: 155,
		quantity: 222,
	},
	{
		garage_id: 223,
		part_id: 156,
		quantity: 433,
	},
	{
		garage_id: 223,
		part_id: 157,
		quantity: 334,
	},
	{
		garage_id: 223,
		part_id: 158,
		quantity: 422,
	},
	{
		garage_id: 223,
		part_id: 159,
		quantity: 47,
	},
	{
		garage_id: 223,
		part_id: 160,
		quantity: 444,
	},
	{
		garage_id: 223,
		part_id: 161,
		quantity: 104,
	},
	{
		garage_id: 223,
		part_id: 162,
		quantity: 91,
	},
	{
		garage_id: 223,
		part_id: 163,
		quantity: 74,
	},
	{
		garage_id: 223,
		part_id: 164,
		quantity: 193,
	},
	{
		garage_id: 223,
		part_id: 165,
		quantity: 213,
	},
	{
		garage_id: 223,
		part_id: 166,
		quantity: 89,
	},
	{
		garage_id: 223,
		part_id: 167,
		quantity: 272,
	},
	{
		garage_id: 223,
		part_id: 168,
		quantity: 192,
	},
	{
		garage_id: 223,
		part_id: 169,
		quantity: 280,
	},
	{
		garage_id: 223,
		part_id: 170,
		quantity: 374,
	},
	{
		garage_id: 223,
		part_id: 171,
		quantity: 187,
	},
	{
		garage_id: 223,
		part_id: 172,
		quantity: 20,
	},
	{
		garage_id: 223,
		part_id: 173,
		quantity: 288,
	},
	{
		garage_id: 223,
		part_id: 174,
		quantity: 178,
	},
	{
		garage_id: 223,
		part_id: 175,
		quantity: 184,
	},
	{
		garage_id: 223,
		part_id: 176,
		quantity: 168,
	},
	{
		garage_id: 223,
		part_id: 177,
		quantity: 255,
	},
	{
		garage_id: 223,
		part_id: 178,
		quantity: 338,
	},
	{
		garage_id: 223,
		part_id: 179,
		quantity: 40,
	},
	{
		garage_id: 223,
		part_id: 180,
		quantity: 135,
	},
	{
		garage_id: 223,
		part_id: 181,
		quantity: 49,
	},
	{
		garage_id: 223,
		part_id: 182,
		quantity: 497,
	},
	{
		garage_id: 223,
		part_id: 183,
		quantity: 466,
	},
	{
		garage_id: 223,
		part_id: 184,
		quantity: 118,
	},
	{
		garage_id: 223,
		part_id: 185,
		quantity: 392,
	},
	{
		garage_id: 223,
		part_id: 186,
		quantity: 9,
	},
	{
		garage_id: 223,
		part_id: 187,
		quantity: 262,
	},
	{
		garage_id: 223,
		part_id: 188,
		quantity: 260,
	},
	{
		garage_id: 223,
		part_id: 189,
		quantity: 272,
	},
	{
		garage_id: 223,
		part_id: 190,
		quantity: 266,
	},
	{
		garage_id: 223,
		part_id: 191,
		quantity: 104,
	},
	{
		garage_id: 223,
		part_id: 192,
		quantity: 176,
	},
	{
		garage_id: 223,
		part_id: 193,
		quantity: 466,
	},
	{
		garage_id: 223,
		part_id: 194,
		quantity: 375,
	},
	{
		garage_id: 223,
		part_id: 195,
		quantity: 374,
	},
	{
		garage_id: 223,
		part_id: 196,
		quantity: 26,
	},
	{
		garage_id: 223,
		part_id: 197,
		quantity: 417,
	},
	{
		garage_id: 223,
		part_id: 198,
		quantity: 3,
	},
	{
		garage_id: 223,
		part_id: 199,
		quantity: 364,
	},
	{
		garage_id: 223,
		part_id: 200,
		quantity: 500,
	},
	{
		garage_id: 223,
		part_id: 201,
		quantity: 198,
	},
	{
		garage_id: 223,
		part_id: 202,
		quantity: 180,
	},
	{
		garage_id: 223,
		part_id: 203,
		quantity: 264,
	},
	{
		garage_id: 223,
		part_id: 204,
		quantity: 101,
	},
	{
		garage_id: 223,
		part_id: 205,
		quantity: 135,
	},
	{
		garage_id: 223,
		part_id: 206,
		quantity: 250,
	},
	{
		garage_id: 223,
		part_id: 207,
		quantity: 380,
	},
	{
		garage_id: 223,
		part_id: 208,
		quantity: 183,
	},
	{
		garage_id: 223,
		part_id: 209,
		quantity: 458,
	},
	{
		garage_id: 223,
		part_id: 210,
		quantity: 452,
	},
	{
		garage_id: 223,
		part_id: 211,
		quantity: 205,
	},
	{
		garage_id: 223,
		part_id: 212,
		quantity: 223,
	},
	{
		garage_id: 223,
		part_id: 213,
		quantity: 309,
	},
	{
		garage_id: 223,
		part_id: 214,
		quantity: 41,
	},
	{
		garage_id: 223,
		part_id: 215,
		quantity: 30,
	},
	{
		garage_id: 223,
		part_id: 216,
		quantity: 260,
	},
	{
		garage_id: 223,
		part_id: 217,
		quantity: 159,
	},
	{
		garage_id: 223,
		part_id: 218,
		quantity: 254,
	},
	{
		garage_id: 223,
		part_id: 219,
		quantity: 42,
	},
	{
		garage_id: 223,
		part_id: 220,
		quantity: 41,
	},
	{
		garage_id: 223,
		part_id: 221,
		quantity: 238,
	},
	{
		garage_id: 223,
		part_id: 222,
		quantity: 493,
	},
	{
		garage_id: 223,
		part_id: 223,
		quantity: 37,
	},
	{
		garage_id: 223,
		part_id: 224,
		quantity: 238,
	},
	{
		garage_id: 223,
		part_id: 225,
		quantity: 410,
	},
	{
		garage_id: 223,
		part_id: 226,
		quantity: 445,
	},
	{
		garage_id: 223,
		part_id: 227,
		quantity: 108,
	},
	{
		garage_id: 223,
		part_id: 228,
		quantity: 391,
	},
	{
		garage_id: 223,
		part_id: 229,
		quantity: 499,
	},
	{
		garage_id: 223,
		part_id: 230,
		quantity: 215,
	},
	{
		garage_id: 223,
		part_id: 231,
		quantity: 19,
	},
	{
		garage_id: 223,
		part_id: 232,
		quantity: 363,
	},
	{
		garage_id: 223,
		part_id: 233,
		quantity: 94,
	},
	{
		garage_id: 223,
		part_id: 234,
		quantity: 300,
	},
	{
		garage_id: 223,
		part_id: 235,
		quantity: 296,
	},
	{
		garage_id: 223,
		part_id: 236,
		quantity: 428,
	},
	{
		garage_id: 223,
		part_id: 237,
		quantity: 90,
	},
	{
		garage_id: 223,
		part_id: 238,
		quantity: 171,
	},
	{
		garage_id: 223,
		part_id: 239,
		quantity: 330,
	},
	{
		garage_id: 223,
		part_id: 240,
		quantity: 383,
	},
	{
		garage_id: 223,
		part_id: 241,
		quantity: 128,
	},
	{
		garage_id: 223,
		part_id: 242,
		quantity: 458,
	},
	{
		garage_id: 223,
		part_id: 243,
		quantity: 376,
	},
	{
		garage_id: 223,
		part_id: 244,
		quantity: 364,
	},
	{
		garage_id: 223,
		part_id: 245,
		quantity: 30,
	},
	{
		garage_id: 223,
		part_id: 246,
		quantity: 141,
	},
	{
		garage_id: 223,
		part_id: 247,
		quantity: 269,
	},
	{
		garage_id: 223,
		part_id: 248,
		quantity: 464,
	},
	{
		garage_id: 223,
		part_id: 249,
		quantity: 30,
	},
	{
		garage_id: 223,
		part_id: 250,
		quantity: 333,
	},
	{
		garage_id: 223,
		part_id: 251,
		quantity: 89,
	},
	{
		garage_id: 223,
		part_id: 252,
		quantity: 13,
	},
	{
		garage_id: 223,
		part_id: 253,
		quantity: 168,
	},
	{
		garage_id: 223,
		part_id: 254,
		quantity: 444,
	},
	{
		garage_id: 223,
		part_id: 255,
		quantity: 102,
	},
	{
		garage_id: 223,
		part_id: 256,
		quantity: 158,
	},
	{
		garage_id: 223,
		part_id: 257,
		quantity: 53,
	},
	{
		garage_id: 223,
		part_id: 258,
		quantity: 410,
	},
	{
		garage_id: 223,
		part_id: 259,
		quantity: 299,
	},
	{
		garage_id: 223,
		part_id: 260,
		quantity: 488,
	},
	{
		garage_id: 223,
		part_id: 261,
		quantity: 192,
	},
	{
		garage_id: 223,
		part_id: 262,
		quantity: 489,
	},
	{
		garage_id: 223,
		part_id: 263,
		quantity: 35,
	},
	{
		garage_id: 223,
		part_id: 264,
		quantity: 27,
	},
	{
		garage_id: 223,
		part_id: 265,
		quantity: 466,
	},
	{
		garage_id: 223,
		part_id: 266,
		quantity: 138,
	},
	{
		garage_id: 223,
		part_id: 267,
		quantity: 145,
	},
	{
		garage_id: 223,
		part_id: 268,
		quantity: 263,
	},
	{
		garage_id: 223,
		part_id: 269,
		quantity: 106,
	},
	{
		garage_id: 223,
		part_id: 270,
		quantity: 314,
	},
	{
		garage_id: 223,
		part_id: 271,
		quantity: 430,
	},
	{
		garage_id: 223,
		part_id: 272,
		quantity: 237,
	},
	{
		garage_id: 223,
		part_id: 273,
		quantity: 333,
	},
	{
		garage_id: 223,
		part_id: 274,
		quantity: 19,
	},
	{
		garage_id: 223,
		part_id: 275,
		quantity: 379,
	},
	{
		garage_id: 223,
		part_id: 276,
		quantity: 33,
	},
	{
		garage_id: 223,
		part_id: 277,
		quantity: 316,
	},
	{
		garage_id: 223,
		part_id: 278,
		quantity: 108,
	},
	{
		garage_id: 223,
		part_id: 279,
		quantity: 65,
	},
	{
		garage_id: 223,
		part_id: 280,
		quantity: 449,
	},
	{
		garage_id: 223,
		part_id: 281,
		quantity: 407,
	},
	{
		garage_id: 223,
		part_id: 282,
		quantity: 94,
	},
	{
		garage_id: 223,
		part_id: 283,
		quantity: 465,
	},
	{
		garage_id: 223,
		part_id: 284,
		quantity: 238,
	},
	{
		garage_id: 223,
		part_id: 285,
		quantity: 431,
	},
	{
		garage_id: 223,
		part_id: 286,
		quantity: 5,
	},
	{
		garage_id: 223,
		part_id: 287,
		quantity: 338,
	},
	{
		garage_id: 223,
		part_id: 288,
		quantity: 304,
	},
	{
		garage_id: 223,
		part_id: 289,
		quantity: 346,
	},
	{
		garage_id: 223,
		part_id: 290,
		quantity: 99,
	},
	{
		garage_id: 223,
		part_id: 291,
		quantity: 119,
	},
	{
		garage_id: 223,
		part_id: 292,
		quantity: 357,
	},
	{
		garage_id: 223,
		part_id: 293,
		quantity: 146,
	},
	{
		garage_id: 223,
		part_id: 294,
		quantity: 488,
	},
	{
		garage_id: 223,
		part_id: 295,
		quantity: 417,
	},
	{
		garage_id: 223,
		part_id: 296,
		quantity: 76,
	},
	{
		garage_id: 223,
		part_id: 297,
		quantity: 7,
	},
	{
		garage_id: 223,
		part_id: 298,
		quantity: 442,
	},
	{
		garage_id: 223,
		part_id: 299,
		quantity: 221,
	},
	{
		garage_id: 223,
		part_id: 300,
		quantity: 13,
	},
	{
		garage_id: 223,
		part_id: 301,
		quantity: 51,
	},
	{
		garage_id: 223,
		part_id: 302,
		quantity: 496,
	},
	{
		garage_id: 223,
		part_id: 303,
		quantity: 94,
	},
	{
		garage_id: 223,
		part_id: 304,
		quantity: 449,
	},
	{
		garage_id: 223,
		part_id: 305,
		quantity: 11,
	},
	{
		garage_id: 223,
		part_id: 306,
		quantity: 438,
	},
	{
		garage_id: 223,
		part_id: 307,
		quantity: 302,
	},
	{
		garage_id: 223,
		part_id: 308,
		quantity: 13,
	},
	{
		garage_id: 223,
		part_id: 309,
		quantity: 67,
	},
	{
		garage_id: 223,
		part_id: 310,
		quantity: 10,
	},
	{
		garage_id: 223,
		part_id: 311,
		quantity: 165,
	},
	{
		garage_id: 223,
		part_id: 312,
		quantity: 420,
	},
	{
		garage_id: 223,
		part_id: 313,
		quantity: 464,
	},
	{
		garage_id: 223,
		part_id: 314,
		quantity: 347,
	},
	{
		garage_id: 223,
		part_id: 315,
		quantity: 330,
	},
	{
		garage_id: 223,
		part_id: 316,
		quantity: 275,
	},
	{
		garage_id: 223,
		part_id: 317,
		quantity: 435,
	},
	{
		garage_id: 223,
		part_id: 318,
		quantity: 406,
	},
	{
		garage_id: 223,
		part_id: 319,
		quantity: 478,
	},
	{
		garage_id: 223,
		part_id: 320,
		quantity: 341,
	},
	{
		garage_id: 223,
		part_id: 321,
		quantity: 111,
	},
	{
		garage_id: 223,
		part_id: 322,
		quantity: 465,
	},
	{
		garage_id: 223,
		part_id: 323,
		quantity: 320,
	},
	{
		garage_id: 223,
		part_id: 324,
		quantity: 146,
	},
	{
		garage_id: 223,
		part_id: 325,
		quantity: 78,
	},
	{
		garage_id: 223,
		part_id: 326,
		quantity: 363,
	},
	{
		garage_id: 223,
		part_id: 327,
		quantity: 281,
	},
	{
		garage_id: 223,
		part_id: 328,
		quantity: 375,
	},
	{
		garage_id: 223,
		part_id: 329,
		quantity: 53,
	},
	{
		garage_id: 223,
		part_id: 330,
		quantity: 129,
	},
	{
		garage_id: 223,
		part_id: 331,
		quantity: 207,
	},
	{
		garage_id: 223,
		part_id: 332,
		quantity: 417,
	},
	{
		garage_id: 223,
		part_id: 333,
		quantity: 145,
	},
	{
		garage_id: 223,
		part_id: 334,
		quantity: 35,
	},
	{
		garage_id: 223,
		part_id: 335,
		quantity: 84,
	},
	{
		garage_id: 223,
		part_id: 336,
		quantity: 331,
	},
	{
		garage_id: 223,
		part_id: 337,
		quantity: 93,
	},
	{
		garage_id: 223,
		part_id: 338,
		quantity: 453,
	},
	{
		garage_id: 223,
		part_id: 339,
		quantity: 41,
	},
	{
		garage_id: 223,
		part_id: 340,
		quantity: 29,
	},
	{
		garage_id: 223,
		part_id: 341,
		quantity: 204,
	},
	{
		garage_id: 223,
		part_id: 342,
		quantity: 115,
	},
	{
		garage_id: 223,
		part_id: 343,
		quantity: 58,
	},
	{
		garage_id: 223,
		part_id: 344,
		quantity: 209,
	},
	{
		garage_id: 223,
		part_id: 345,
		quantity: 480,
	},
	{
		garage_id: 223,
		part_id: 346,
		quantity: 347,
	},
	{
		garage_id: 223,
		part_id: 347,
		quantity: 386,
	},
	{
		garage_id: 223,
		part_id: 348,
		quantity: 442,
	},
	{
		garage_id: 223,
		part_id: 349,
		quantity: 125,
	},
	{
		garage_id: 223,
		part_id: 350,
		quantity: 425,
	},
	{
		garage_id: 223,
		part_id: 351,
		quantity: 246,
	},
	{
		garage_id: 223,
		part_id: 352,
		quantity: 186,
	},
	{
		garage_id: 223,
		part_id: 353,
		quantity: 143,
	},
	{
		garage_id: 223,
		part_id: 354,
		quantity: 77,
	},
	{
		garage_id: 223,
		part_id: 355,
		quantity: 171,
	},
	{
		garage_id: 223,
		part_id: 356,
		quantity: 315,
	},
	{
		garage_id: 223,
		part_id: 357,
		quantity: 462,
	},
	{
		garage_id: 223,
		part_id: 358,
		quantity: 296,
	},
	{
		garage_id: 223,
		part_id: 359,
		quantity: 124,
	},
	{
		garage_id: 223,
		part_id: 360,
		quantity: 450,
	},
	{
		garage_id: 223,
		part_id: 361,
		quantity: 59,
	},
	{
		garage_id: 223,
		part_id: 362,
		quantity: 247,
	},
	{
		garage_id: 223,
		part_id: 363,
		quantity: 25,
	},
	{
		garage_id: 223,
		part_id: 364,
		quantity: 271,
	},
	{
		garage_id: 223,
		part_id: 365,
		quantity: 148,
	},
	{
		garage_id: 223,
		part_id: 366,
		quantity: 187,
	},
	{
		garage_id: 223,
		part_id: 367,
		quantity: 49,
	},
	{
		garage_id: 223,
		part_id: 368,
		quantity: 79,
	},
	{
		garage_id: 223,
		part_id: 369,
		quantity: 163,
	},
	{
		garage_id: 223,
		part_id: 370,
		quantity: 56,
	},
	{
		garage_id: 223,
		part_id: 371,
		quantity: 300,
	},
	{
		garage_id: 223,
		part_id: 372,
		quantity: 425,
	},
	{
		garage_id: 223,
		part_id: 373,
		quantity: 291,
	},
	{
		garage_id: 223,
		part_id: 374,
		quantity: 363,
	},
	{
		garage_id: 223,
		part_id: 375,
		quantity: 375,
	},
	{
		garage_id: 223,
		part_id: 376,
		quantity: 468,
	},
	{
		garage_id: 223,
		part_id: 377,
		quantity: 35,
	},
	{
		garage_id: 223,
		part_id: 378,
		quantity: 146,
	},
	{
		garage_id: 223,
		part_id: 379,
		quantity: 132,
	},
	{
		garage_id: 223,
		part_id: 380,
		quantity: 157,
	},
	{
		garage_id: 223,
		part_id: 381,
		quantity: 329,
	},
	{
		garage_id: 223,
		part_id: 382,
		quantity: 463,
	},
	{
		garage_id: 223,
		part_id: 383,
		quantity: 210,
	},
	{
		garage_id: 223,
		part_id: 384,
		quantity: 316,
	},
	{
		garage_id: 223,
		part_id: 385,
		quantity: 102,
	},
	{
		garage_id: 223,
		part_id: 386,
		quantity: 272,
	},
	{
		garage_id: 223,
		part_id: 387,
		quantity: 188,
	},
	{
		garage_id: 223,
		part_id: 388,
		quantity: 465,
	},
	{
		garage_id: 223,
		part_id: 389,
		quantity: 107,
	},
	{
		garage_id: 223,
		part_id: 390,
		quantity: 468,
	},
	{
		garage_id: 223,
		part_id: 391,
		quantity: 199,
	},
	{
		garage_id: 223,
		part_id: 392,
		quantity: 6,
	},
	{
		garage_id: 223,
		part_id: 393,
		quantity: 479,
	},
	{
		garage_id: 223,
		part_id: 394,
		quantity: 388,
	},
	{
		garage_id: 223,
		part_id: 395,
		quantity: 197,
	},
	{
		garage_id: 223,
		part_id: 396,
		quantity: 430,
	},
	{
		garage_id: 223,
		part_id: 397,
		quantity: 376,
	},
	{
		garage_id: 223,
		part_id: 398,
		quantity: 434,
	},
	{
		garage_id: 223,
		part_id: 399,
		quantity: 332,
	},
	{
		garage_id: 224,
		part_id: 0,
		quantity: 72,
	},
	{
		garage_id: 224,
		part_id: 1,
		quantity: 223,
	},
	{
		garage_id: 224,
		part_id: 2,
		quantity: 215,
	},
	{
		garage_id: 224,
		part_id: 3,
		quantity: 465,
	},
	{
		garage_id: 224,
		part_id: 4,
		quantity: 362,
	},
	{
		garage_id: 224,
		part_id: 5,
		quantity: 416,
	},
	{
		garage_id: 224,
		part_id: 6,
		quantity: 86,
	},
	{
		garage_id: 224,
		part_id: 7,
		quantity: 80,
	},
	{
		garage_id: 224,
		part_id: 8,
		quantity: 162,
	},
	{
		garage_id: 224,
		part_id: 9,
		quantity: 287,
	},
	{
		garage_id: 224,
		part_id: 10,
		quantity: 416,
	},
	{
		garage_id: 224,
		part_id: 11,
		quantity: 387,
	},
	{
		garage_id: 224,
		part_id: 12,
		quantity: 7,
	},
	{
		garage_id: 224,
		part_id: 13,
		quantity: 15,
	},
	{
		garage_id: 224,
		part_id: 14,
		quantity: 57,
	},
	{
		garage_id: 224,
		part_id: 15,
		quantity: 162,
	},
	{
		garage_id: 224,
		part_id: 16,
		quantity: 67,
	},
	{
		garage_id: 224,
		part_id: 17,
		quantity: 48,
	},
	{
		garage_id: 224,
		part_id: 18,
		quantity: 262,
	},
	{
		garage_id: 224,
		part_id: 19,
		quantity: 55,
	},
	{
		garage_id: 224,
		part_id: 20,
		quantity: 375,
	},
	{
		garage_id: 224,
		part_id: 21,
		quantity: 93,
	},
	{
		garage_id: 224,
		part_id: 22,
		quantity: 175,
	},
	{
		garage_id: 224,
		part_id: 23,
		quantity: 151,
	},
	{
		garage_id: 224,
		part_id: 24,
		quantity: 378,
	},
	{
		garage_id: 224,
		part_id: 25,
		quantity: 400,
	},
	{
		garage_id: 224,
		part_id: 26,
		quantity: 190,
	},
	{
		garage_id: 224,
		part_id: 27,
		quantity: 137,
	},
	{
		garage_id: 224,
		part_id: 28,
		quantity: 486,
	},
	{
		garage_id: 224,
		part_id: 29,
		quantity: 290,
	},
	{
		garage_id: 224,
		part_id: 30,
		quantity: 364,
	},
	{
		garage_id: 224,
		part_id: 31,
		quantity: 468,
	},
	{
		garage_id: 224,
		part_id: 32,
		quantity: 295,
	},
	{
		garage_id: 224,
		part_id: 33,
		quantity: 192,
	},
	{
		garage_id: 224,
		part_id: 34,
		quantity: 371,
	},
	{
		garage_id: 224,
		part_id: 35,
		quantity: 324,
	},
	{
		garage_id: 224,
		part_id: 36,
		quantity: 480,
	},
	{
		garage_id: 224,
		part_id: 37,
		quantity: 59,
	},
	{
		garage_id: 224,
		part_id: 38,
		quantity: 138,
	},
	{
		garage_id: 224,
		part_id: 39,
		quantity: 449,
	},
	{
		garage_id: 224,
		part_id: 40,
		quantity: 15,
	},
	{
		garage_id: 224,
		part_id: 41,
		quantity: 457,
	},
	{
		garage_id: 224,
		part_id: 42,
		quantity: 139,
	},
	{
		garage_id: 224,
		part_id: 43,
		quantity: 267,
	},
	{
		garage_id: 224,
		part_id: 44,
		quantity: 139,
	},
	{
		garage_id: 224,
		part_id: 45,
		quantity: 51,
	},
	{
		garage_id: 224,
		part_id: 46,
		quantity: 182,
	},
	{
		garage_id: 224,
		part_id: 47,
		quantity: 364,
	},
	{
		garage_id: 224,
		part_id: 48,
		quantity: 325,
	},
	{
		garage_id: 224,
		part_id: 49,
		quantity: 459,
	},
	{
		garage_id: 224,
		part_id: 50,
		quantity: 450,
	},
	{
		garage_id: 224,
		part_id: 51,
		quantity: 100,
	},
	{
		garage_id: 224,
		part_id: 52,
		quantity: 375,
	},
	{
		garage_id: 224,
		part_id: 53,
		quantity: 2,
	},
	{
		garage_id: 224,
		part_id: 54,
		quantity: 7,
	},
	{
		garage_id: 224,
		part_id: 55,
		quantity: 99,
	},
	{
		garage_id: 224,
		part_id: 56,
		quantity: 125,
	},
	{
		garage_id: 224,
		part_id: 57,
		quantity: 250,
	},
	{
		garage_id: 224,
		part_id: 58,
		quantity: 213,
	},
	{
		garage_id: 224,
		part_id: 59,
		quantity: 308,
	},
	{
		garage_id: 224,
		part_id: 60,
		quantity: 401,
	},
	{
		garage_id: 224,
		part_id: 61,
		quantity: 68,
	},
	{
		garage_id: 224,
		part_id: 62,
		quantity: 404,
	},
	{
		garage_id: 224,
		part_id: 63,
		quantity: 311,
	},
	{
		garage_id: 224,
		part_id: 64,
		quantity: 139,
	},
	{
		garage_id: 224,
		part_id: 65,
		quantity: 330,
	},
	{
		garage_id: 224,
		part_id: 66,
		quantity: 386,
	},
	{
		garage_id: 224,
		part_id: 67,
		quantity: 147,
	},
	{
		garage_id: 224,
		part_id: 68,
		quantity: 300,
	},
	{
		garage_id: 224,
		part_id: 69,
		quantity: 186,
	},
	{
		garage_id: 224,
		part_id: 70,
		quantity: 428,
	},
	{
		garage_id: 224,
		part_id: 71,
		quantity: 12,
	},
	{
		garage_id: 224,
		part_id: 72,
		quantity: 205,
	},
	{
		garage_id: 224,
		part_id: 73,
		quantity: 401,
	},
	{
		garage_id: 224,
		part_id: 74,
		quantity: 293,
	},
	{
		garage_id: 224,
		part_id: 75,
		quantity: 166,
	},
	{
		garage_id: 224,
		part_id: 76,
		quantity: 498,
	},
	{
		garage_id: 224,
		part_id: 77,
		quantity: 492,
	},
	{
		garage_id: 224,
		part_id: 78,
		quantity: 262,
	},
	{
		garage_id: 224,
		part_id: 79,
		quantity: 409,
	},
	{
		garage_id: 224,
		part_id: 80,
		quantity: 423,
	},
	{
		garage_id: 224,
		part_id: 81,
		quantity: 269,
	},
	{
		garage_id: 224,
		part_id: 82,
		quantity: 254,
	},
	{
		garage_id: 224,
		part_id: 83,
		quantity: 298,
	},
	{
		garage_id: 224,
		part_id: 84,
		quantity: 288,
	},
	{
		garage_id: 224,
		part_id: 85,
		quantity: 486,
	},
	{
		garage_id: 224,
		part_id: 86,
		quantity: 240,
	},
	{
		garage_id: 224,
		part_id: 87,
		quantity: 66,
	},
	{
		garage_id: 224,
		part_id: 88,
		quantity: 95,
	},
	{
		garage_id: 224,
		part_id: 89,
		quantity: 151,
	},
	{
		garage_id: 224,
		part_id: 90,
		quantity: 75,
	},
	{
		garage_id: 224,
		part_id: 91,
		quantity: 115,
	},
	{
		garage_id: 224,
		part_id: 92,
		quantity: 298,
	},
	{
		garage_id: 224,
		part_id: 93,
		quantity: 99,
	},
	{
		garage_id: 224,
		part_id: 94,
		quantity: 314,
	},
	{
		garage_id: 224,
		part_id: 95,
		quantity: 418,
	},
	{
		garage_id: 224,
		part_id: 96,
		quantity: 354,
	},
	{
		garage_id: 224,
		part_id: 97,
		quantity: 440,
	},
	{
		garage_id: 224,
		part_id: 98,
		quantity: 233,
	},
	{
		garage_id: 224,
		part_id: 99,
		quantity: 343,
	},
	{
		garage_id: 224,
		part_id: 100,
		quantity: 266,
	},
	{
		garage_id: 224,
		part_id: 101,
		quantity: 26,
	},
	{
		garage_id: 224,
		part_id: 102,
		quantity: 87,
	},
	{
		garage_id: 224,
		part_id: 103,
		quantity: 119,
	},
	{
		garage_id: 224,
		part_id: 104,
		quantity: 22,
	},
	{
		garage_id: 224,
		part_id: 105,
		quantity: 17,
	},
	{
		garage_id: 224,
		part_id: 106,
		quantity: 418,
	},
	{
		garage_id: 224,
		part_id: 107,
		quantity: 101,
	},
	{
		garage_id: 224,
		part_id: 108,
		quantity: 399,
	},
	{
		garage_id: 224,
		part_id: 109,
		quantity: 397,
	},
	{
		garage_id: 224,
		part_id: 110,
		quantity: 432,
	},
	{
		garage_id: 224,
		part_id: 111,
		quantity: 454,
	},
	{
		garage_id: 224,
		part_id: 112,
		quantity: 289,
	},
	{
		garage_id: 224,
		part_id: 113,
		quantity: 302,
	},
	{
		garage_id: 224,
		part_id: 114,
		quantity: 181,
	},
	{
		garage_id: 224,
		part_id: 115,
		quantity: 48,
	},
	{
		garage_id: 224,
		part_id: 116,
		quantity: 128,
	},
	{
		garage_id: 224,
		part_id: 117,
		quantity: 13,
	},
	{
		garage_id: 224,
		part_id: 118,
		quantity: 255,
	},
	{
		garage_id: 224,
		part_id: 119,
		quantity: 263,
	},
	{
		garage_id: 224,
		part_id: 120,
		quantity: 273,
	},
	{
		garage_id: 224,
		part_id: 121,
		quantity: 453,
	},
	{
		garage_id: 224,
		part_id: 122,
		quantity: 491,
	},
	{
		garage_id: 224,
		part_id: 123,
		quantity: 283,
	},
	{
		garage_id: 224,
		part_id: 124,
		quantity: 201,
	},
	{
		garage_id: 224,
		part_id: 125,
		quantity: 484,
	},
	{
		garage_id: 224,
		part_id: 126,
		quantity: 375,
	},
	{
		garage_id: 224,
		part_id: 127,
		quantity: 275,
	},
	{
		garage_id: 224,
		part_id: 128,
		quantity: 404,
	},
	{
		garage_id: 224,
		part_id: 129,
		quantity: 55,
	},
	{
		garage_id: 224,
		part_id: 130,
		quantity: 373,
	},
	{
		garage_id: 224,
		part_id: 131,
		quantity: 129,
	},
	{
		garage_id: 224,
		part_id: 132,
		quantity: 491,
	},
	{
		garage_id: 224,
		part_id: 133,
		quantity: 55,
	},
	{
		garage_id: 224,
		part_id: 134,
		quantity: 481,
	},
	{
		garage_id: 224,
		part_id: 135,
		quantity: 11,
	},
	{
		garage_id: 224,
		part_id: 136,
		quantity: 267,
	},
	{
		garage_id: 224,
		part_id: 137,
		quantity: 286,
	},
	{
		garage_id: 224,
		part_id: 138,
		quantity: 265,
	},
	{
		garage_id: 224,
		part_id: 139,
		quantity: 387,
	},
	{
		garage_id: 224,
		part_id: 140,
		quantity: 215,
	},
	{
		garage_id: 224,
		part_id: 141,
		quantity: 265,
	},
	{
		garage_id: 224,
		part_id: 142,
		quantity: 322,
	},
	{
		garage_id: 224,
		part_id: 143,
		quantity: 425,
	},
	{
		garage_id: 224,
		part_id: 144,
		quantity: 453,
	},
	{
		garage_id: 224,
		part_id: 145,
		quantity: 242,
	},
	{
		garage_id: 224,
		part_id: 146,
		quantity: 68,
	},
	{
		garage_id: 224,
		part_id: 147,
		quantity: 104,
	},
	{
		garage_id: 224,
		part_id: 148,
		quantity: 298,
	},
	{
		garage_id: 224,
		part_id: 149,
		quantity: 359,
	},
	{
		garage_id: 224,
		part_id: 150,
		quantity: 12,
	},
	{
		garage_id: 224,
		part_id: 151,
		quantity: 154,
	},
	{
		garage_id: 224,
		part_id: 152,
		quantity: 128,
	},
	{
		garage_id: 224,
		part_id: 153,
		quantity: 374,
	},
	{
		garage_id: 224,
		part_id: 154,
		quantity: 94,
	},
	{
		garage_id: 224,
		part_id: 155,
		quantity: 234,
	},
	{
		garage_id: 224,
		part_id: 156,
		quantity: 344,
	},
	{
		garage_id: 224,
		part_id: 157,
		quantity: 310,
	},
	{
		garage_id: 224,
		part_id: 158,
		quantity: 246,
	},
	{
		garage_id: 224,
		part_id: 159,
		quantity: 71,
	},
	{
		garage_id: 224,
		part_id: 160,
		quantity: 445,
	},
	{
		garage_id: 224,
		part_id: 161,
		quantity: 479,
	},
	{
		garage_id: 224,
		part_id: 162,
		quantity: 32,
	},
	{
		garage_id: 224,
		part_id: 163,
		quantity: 257,
	},
	{
		garage_id: 224,
		part_id: 164,
		quantity: 109,
	},
	{
		garage_id: 224,
		part_id: 165,
		quantity: 382,
	},
	{
		garage_id: 224,
		part_id: 166,
		quantity: 202,
	},
	{
		garage_id: 224,
		part_id: 167,
		quantity: 233,
	},
	{
		garage_id: 224,
		part_id: 168,
		quantity: 363,
	},
	{
		garage_id: 224,
		part_id: 169,
		quantity: 121,
	},
	{
		garage_id: 224,
		part_id: 170,
		quantity: 360,
	},
	{
		garage_id: 224,
		part_id: 171,
		quantity: 235,
	},
	{
		garage_id: 224,
		part_id: 172,
		quantity: 62,
	},
	{
		garage_id: 224,
		part_id: 173,
		quantity: 118,
	},
	{
		garage_id: 224,
		part_id: 174,
		quantity: 258,
	},
	{
		garage_id: 224,
		part_id: 175,
		quantity: 428,
	},
	{
		garage_id: 224,
		part_id: 176,
		quantity: 187,
	},
	{
		garage_id: 224,
		part_id: 177,
		quantity: 275,
	},
	{
		garage_id: 224,
		part_id: 178,
		quantity: 75,
	},
	{
		garage_id: 224,
		part_id: 179,
		quantity: 413,
	},
	{
		garage_id: 224,
		part_id: 180,
		quantity: 423,
	},
	{
		garage_id: 224,
		part_id: 181,
		quantity: 169,
	},
	{
		garage_id: 224,
		part_id: 182,
		quantity: 244,
	},
	{
		garage_id: 224,
		part_id: 183,
		quantity: 219,
	},
	{
		garage_id: 224,
		part_id: 184,
		quantity: 50,
	},
	{
		garage_id: 224,
		part_id: 185,
		quantity: 20,
	},
	{
		garage_id: 224,
		part_id: 186,
		quantity: 386,
	},
	{
		garage_id: 224,
		part_id: 187,
		quantity: 420,
	},
	{
		garage_id: 224,
		part_id: 188,
		quantity: 168,
	},
	{
		garage_id: 224,
		part_id: 189,
		quantity: 369,
	},
	{
		garage_id: 224,
		part_id: 190,
		quantity: 274,
	},
	{
		garage_id: 224,
		part_id: 191,
		quantity: 268,
	},
	{
		garage_id: 224,
		part_id: 192,
		quantity: 91,
	},
	{
		garage_id: 224,
		part_id: 193,
		quantity: 441,
	},
	{
		garage_id: 224,
		part_id: 194,
		quantity: 37,
	},
	{
		garage_id: 224,
		part_id: 195,
		quantity: 103,
	},
	{
		garage_id: 224,
		part_id: 196,
		quantity: 500,
	},
	{
		garage_id: 224,
		part_id: 197,
		quantity: 215,
	},
	{
		garage_id: 224,
		part_id: 198,
		quantity: 140,
	},
	{
		garage_id: 224,
		part_id: 199,
		quantity: 408,
	},
	{
		garage_id: 224,
		part_id: 200,
		quantity: 365,
	},
	{
		garage_id: 224,
		part_id: 201,
		quantity: 143,
	},
	{
		garage_id: 224,
		part_id: 202,
		quantity: 42,
	},
	{
		garage_id: 224,
		part_id: 203,
		quantity: 92,
	},
	{
		garage_id: 224,
		part_id: 204,
		quantity: 434,
	},
	{
		garage_id: 224,
		part_id: 205,
		quantity: 290,
	},
	{
		garage_id: 224,
		part_id: 206,
		quantity: 362,
	},
	{
		garage_id: 224,
		part_id: 207,
		quantity: 132,
	},
	{
		garage_id: 224,
		part_id: 208,
		quantity: 151,
	},
	{
		garage_id: 224,
		part_id: 209,
		quantity: 283,
	},
	{
		garage_id: 224,
		part_id: 210,
		quantity: 261,
	},
	{
		garage_id: 224,
		part_id: 211,
		quantity: 407,
	},
	{
		garage_id: 224,
		part_id: 212,
		quantity: 350,
	},
	{
		garage_id: 224,
		part_id: 213,
		quantity: 225,
	},
	{
		garage_id: 224,
		part_id: 214,
		quantity: 95,
	},
	{
		garage_id: 224,
		part_id: 215,
		quantity: 273,
	},
	{
		garage_id: 224,
		part_id: 216,
		quantity: 344,
	},
	{
		garage_id: 224,
		part_id: 217,
		quantity: 369,
	},
	{
		garage_id: 224,
		part_id: 218,
		quantity: 394,
	},
	{
		garage_id: 224,
		part_id: 219,
		quantity: 267,
	},
	{
		garage_id: 224,
		part_id: 220,
		quantity: 302,
	},
	{
		garage_id: 224,
		part_id: 221,
		quantity: 220,
	},
	{
		garage_id: 224,
		part_id: 222,
		quantity: 418,
	},
	{
		garage_id: 224,
		part_id: 223,
		quantity: 60,
	},
	{
		garage_id: 224,
		part_id: 224,
		quantity: 61,
	},
	{
		garage_id: 224,
		part_id: 225,
		quantity: 153,
	},
	{
		garage_id: 224,
		part_id: 226,
		quantity: 399,
	},
	{
		garage_id: 224,
		part_id: 227,
		quantity: 62,
	},
	{
		garage_id: 224,
		part_id: 228,
		quantity: 61,
	},
	{
		garage_id: 224,
		part_id: 229,
		quantity: 381,
	},
	{
		garage_id: 224,
		part_id: 230,
		quantity: 394,
	},
	{
		garage_id: 224,
		part_id: 231,
		quantity: 178,
	},
	{
		garage_id: 224,
		part_id: 232,
		quantity: 440,
	},
	{
		garage_id: 224,
		part_id: 233,
		quantity: 31,
	},
	{
		garage_id: 224,
		part_id: 234,
		quantity: 66,
	},
	{
		garage_id: 224,
		part_id: 235,
		quantity: 433,
	},
	{
		garage_id: 224,
		part_id: 236,
		quantity: 254,
	},
	{
		garage_id: 224,
		part_id: 237,
		quantity: 49,
	},
	{
		garage_id: 224,
		part_id: 238,
		quantity: 55,
	},
	{
		garage_id: 224,
		part_id: 239,
		quantity: 141,
	},
	{
		garage_id: 224,
		part_id: 240,
		quantity: 257,
	},
	{
		garage_id: 224,
		part_id: 241,
		quantity: 151,
	},
	{
		garage_id: 224,
		part_id: 242,
		quantity: 189,
	},
	{
		garage_id: 224,
		part_id: 243,
		quantity: 32,
	},
	{
		garage_id: 224,
		part_id: 244,
		quantity: 216,
	},
	{
		garage_id: 224,
		part_id: 245,
		quantity: 350,
	},
	{
		garage_id: 224,
		part_id: 246,
		quantity: 40,
	},
	{
		garage_id: 224,
		part_id: 247,
		quantity: 452,
	},
	{
		garage_id: 224,
		part_id: 248,
		quantity: 150,
	},
	{
		garage_id: 224,
		part_id: 249,
		quantity: 412,
	},
	{
		garage_id: 224,
		part_id: 250,
		quantity: 404,
	},
	{
		garage_id: 224,
		part_id: 251,
		quantity: 148,
	},
	{
		garage_id: 224,
		part_id: 252,
		quantity: 25,
	},
	{
		garage_id: 224,
		part_id: 253,
		quantity: 1,
	},
	{
		garage_id: 224,
		part_id: 254,
		quantity: 38,
	},
	{
		garage_id: 224,
		part_id: 255,
		quantity: 391,
	},
	{
		garage_id: 224,
		part_id: 256,
		quantity: 174,
	},
	{
		garage_id: 224,
		part_id: 257,
		quantity: 107,
	},
	{
		garage_id: 224,
		part_id: 258,
		quantity: 291,
	},
	{
		garage_id: 224,
		part_id: 259,
		quantity: 40,
	},
	{
		garage_id: 224,
		part_id: 260,
		quantity: 40,
	},
	{
		garage_id: 224,
		part_id: 261,
		quantity: 237,
	},
	{
		garage_id: 224,
		part_id: 262,
		quantity: 47,
	},
	{
		garage_id: 224,
		part_id: 263,
		quantity: 101,
	},
	{
		garage_id: 224,
		part_id: 264,
		quantity: 99,
	},
	{
		garage_id: 224,
		part_id: 265,
		quantity: 12,
	},
	{
		garage_id: 224,
		part_id: 266,
		quantity: 4,
	},
	{
		garage_id: 224,
		part_id: 267,
		quantity: 56,
	},
	{
		garage_id: 224,
		part_id: 268,
		quantity: 90,
	},
	{
		garage_id: 224,
		part_id: 269,
		quantity: 308,
	},
	{
		garage_id: 224,
		part_id: 270,
		quantity: 428,
	},
	{
		garage_id: 224,
		part_id: 271,
		quantity: 401,
	},
	{
		garage_id: 224,
		part_id: 272,
		quantity: 327,
	},
	{
		garage_id: 224,
		part_id: 273,
		quantity: 319,
	},
	{
		garage_id: 224,
		part_id: 274,
		quantity: 78,
	},
	{
		garage_id: 224,
		part_id: 275,
		quantity: 300,
	},
	{
		garage_id: 224,
		part_id: 276,
		quantity: 89,
	},
	{
		garage_id: 224,
		part_id: 277,
		quantity: 334,
	},
	{
		garage_id: 224,
		part_id: 278,
		quantity: 218,
	},
	{
		garage_id: 224,
		part_id: 279,
		quantity: 430,
	},
	{
		garage_id: 224,
		part_id: 280,
		quantity: 101,
	},
	{
		garage_id: 224,
		part_id: 281,
		quantity: 490,
	},
	{
		garage_id: 224,
		part_id: 282,
		quantity: 482,
	},
	{
		garage_id: 224,
		part_id: 283,
		quantity: 278,
	},
	{
		garage_id: 224,
		part_id: 284,
		quantity: 263,
	},
	{
		garage_id: 224,
		part_id: 285,
		quantity: 19,
	},
	{
		garage_id: 224,
		part_id: 286,
		quantity: 32,
	},
	{
		garage_id: 224,
		part_id: 287,
		quantity: 500,
	},
	{
		garage_id: 224,
		part_id: 288,
		quantity: 323,
	},
	{
		garage_id: 224,
		part_id: 289,
		quantity: 238,
	},
	{
		garage_id: 224,
		part_id: 290,
		quantity: 410,
	},
	{
		garage_id: 224,
		part_id: 291,
		quantity: 110,
	},
	{
		garage_id: 224,
		part_id: 292,
		quantity: 149,
	},
	{
		garage_id: 224,
		part_id: 293,
		quantity: 288,
	},
	{
		garage_id: 224,
		part_id: 294,
		quantity: 126,
	},
	{
		garage_id: 224,
		part_id: 295,
		quantity: 262,
	},
	{
		garage_id: 224,
		part_id: 296,
		quantity: 64,
	},
	{
		garage_id: 224,
		part_id: 297,
		quantity: 497,
	},
	{
		garage_id: 224,
		part_id: 298,
		quantity: 430,
	},
	{
		garage_id: 224,
		part_id: 299,
		quantity: 235,
	},
	{
		garage_id: 224,
		part_id: 300,
		quantity: 495,
	},
	{
		garage_id: 224,
		part_id: 301,
		quantity: 107,
	},
	{
		garage_id: 224,
		part_id: 302,
		quantity: 264,
	},
	{
		garage_id: 224,
		part_id: 303,
		quantity: 430,
	},
	{
		garage_id: 224,
		part_id: 304,
		quantity: 124,
	},
	{
		garage_id: 224,
		part_id: 305,
		quantity: 244,
	},
	{
		garage_id: 224,
		part_id: 306,
		quantity: 409,
	},
	{
		garage_id: 224,
		part_id: 307,
		quantity: 197,
	},
	{
		garage_id: 224,
		part_id: 308,
		quantity: 173,
	},
	{
		garage_id: 224,
		part_id: 309,
		quantity: 488,
	},
	{
		garage_id: 224,
		part_id: 310,
		quantity: 167,
	},
	{
		garage_id: 224,
		part_id: 311,
		quantity: 366,
	},
	{
		garage_id: 224,
		part_id: 312,
		quantity: 76,
	},
	{
		garage_id: 224,
		part_id: 313,
		quantity: 465,
	},
	{
		garage_id: 224,
		part_id: 314,
		quantity: 443,
	},
	{
		garage_id: 224,
		part_id: 315,
		quantity: 72,
	},
	{
		garage_id: 224,
		part_id: 316,
		quantity: 128,
	},
	{
		garage_id: 224,
		part_id: 317,
		quantity: 88,
	},
	{
		garage_id: 224,
		part_id: 318,
		quantity: 314,
	},
	{
		garage_id: 224,
		part_id: 319,
		quantity: 163,
	},
	{
		garage_id: 224,
		part_id: 320,
		quantity: 85,
	},
	{
		garage_id: 224,
		part_id: 321,
		quantity: 369,
	},
	{
		garage_id: 224,
		part_id: 322,
		quantity: 397,
	},
	{
		garage_id: 224,
		part_id: 323,
		quantity: 185,
	},
	{
		garage_id: 224,
		part_id: 324,
		quantity: 292,
	},
	{
		garage_id: 224,
		part_id: 325,
		quantity: 334,
	},
	{
		garage_id: 224,
		part_id: 326,
		quantity: 25,
	},
	{
		garage_id: 224,
		part_id: 327,
		quantity: 199,
	},
	{
		garage_id: 224,
		part_id: 328,
		quantity: 299,
	},
	{
		garage_id: 224,
		part_id: 329,
		quantity: 117,
	},
	{
		garage_id: 224,
		part_id: 330,
		quantity: 494,
	},
	{
		garage_id: 224,
		part_id: 331,
		quantity: 420,
	},
	{
		garage_id: 224,
		part_id: 332,
		quantity: 214,
	},
	{
		garage_id: 224,
		part_id: 333,
		quantity: 175,
	},
	{
		garage_id: 224,
		part_id: 334,
		quantity: 88,
	},
	{
		garage_id: 224,
		part_id: 335,
		quantity: 210,
	},
	{
		garage_id: 224,
		part_id: 336,
		quantity: 245,
	},
	{
		garage_id: 224,
		part_id: 337,
		quantity: 227,
	},
	{
		garage_id: 224,
		part_id: 338,
		quantity: 247,
	},
	{
		garage_id: 224,
		part_id: 339,
		quantity: 492,
	},
	{
		garage_id: 224,
		part_id: 340,
		quantity: 175,
	},
	{
		garage_id: 224,
		part_id: 341,
		quantity: 106,
	},
	{
		garage_id: 224,
		part_id: 342,
		quantity: 417,
	},
	{
		garage_id: 224,
		part_id: 343,
		quantity: 75,
	},
	{
		garage_id: 224,
		part_id: 344,
		quantity: 31,
	},
	{
		garage_id: 224,
		part_id: 345,
		quantity: 362,
	},
	{
		garage_id: 224,
		part_id: 346,
		quantity: 468,
	},
	{
		garage_id: 224,
		part_id: 347,
		quantity: 298,
	},
	{
		garage_id: 224,
		part_id: 348,
		quantity: 72,
	},
	{
		garage_id: 224,
		part_id: 349,
		quantity: 86,
	},
	{
		garage_id: 224,
		part_id: 350,
		quantity: 192,
	},
	{
		garage_id: 224,
		part_id: 351,
		quantity: 195,
	},
	{
		garage_id: 224,
		part_id: 352,
		quantity: 295,
	},
	{
		garage_id: 224,
		part_id: 353,
		quantity: 403,
	},
	{
		garage_id: 224,
		part_id: 354,
		quantity: 58,
	},
	{
		garage_id: 224,
		part_id: 355,
		quantity: 142,
	},
	{
		garage_id: 224,
		part_id: 356,
		quantity: 453,
	},
	{
		garage_id: 224,
		part_id: 357,
		quantity: 464,
	},
	{
		garage_id: 224,
		part_id: 358,
		quantity: 55,
	},
	{
		garage_id: 224,
		part_id: 359,
		quantity: 305,
	},
	{
		garage_id: 224,
		part_id: 360,
		quantity: 316,
	},
	{
		garage_id: 224,
		part_id: 361,
		quantity: 377,
	},
	{
		garage_id: 224,
		part_id: 362,
		quantity: 81,
	},
	{
		garage_id: 224,
		part_id: 363,
		quantity: 391,
	},
	{
		garage_id: 224,
		part_id: 364,
		quantity: 255,
	},
	{
		garage_id: 224,
		part_id: 365,
		quantity: 61,
	},
	{
		garage_id: 224,
		part_id: 366,
		quantity: 276,
	},
	{
		garage_id: 224,
		part_id: 367,
		quantity: 27,
	},
	{
		garage_id: 224,
		part_id: 368,
		quantity: 162,
	},
	{
		garage_id: 224,
		part_id: 369,
		quantity: 256,
	},
	{
		garage_id: 224,
		part_id: 370,
		quantity: 407,
	},
	{
		garage_id: 224,
		part_id: 371,
		quantity: 372,
	},
	{
		garage_id: 224,
		part_id: 372,
		quantity: 316,
	},
	{
		garage_id: 224,
		part_id: 373,
		quantity: 366,
	},
	{
		garage_id: 224,
		part_id: 374,
		quantity: 81,
	},
	{
		garage_id: 224,
		part_id: 375,
		quantity: 227,
	},
	{
		garage_id: 224,
		part_id: 376,
		quantity: 194,
	},
	{
		garage_id: 224,
		part_id: 377,
		quantity: 442,
	},
	{
		garage_id: 224,
		part_id: 378,
		quantity: 419,
	},
	{
		garage_id: 224,
		part_id: 379,
		quantity: 482,
	},
	{
		garage_id: 224,
		part_id: 380,
		quantity: 268,
	},
	{
		garage_id: 224,
		part_id: 381,
		quantity: 104,
	},
	{
		garage_id: 224,
		part_id: 382,
		quantity: 135,
	},
	{
		garage_id: 224,
		part_id: 383,
		quantity: 276,
	},
	{
		garage_id: 224,
		part_id: 384,
		quantity: 258,
	},
	{
		garage_id: 224,
		part_id: 385,
		quantity: 144,
	},
	{
		garage_id: 224,
		part_id: 386,
		quantity: 292,
	},
	{
		garage_id: 224,
		part_id: 387,
		quantity: 314,
	},
	{
		garage_id: 224,
		part_id: 388,
		quantity: 29,
	},
	{
		garage_id: 224,
		part_id: 389,
		quantity: 479,
	},
	{
		garage_id: 224,
		part_id: 390,
		quantity: 324,
	},
	{
		garage_id: 224,
		part_id: 391,
		quantity: 283,
	},
	{
		garage_id: 224,
		part_id: 392,
		quantity: 445,
	},
	{
		garage_id: 224,
		part_id: 393,
		quantity: 134,
	},
	{
		garage_id: 224,
		part_id: 394,
		quantity: 447,
	},
	{
		garage_id: 224,
		part_id: 395,
		quantity: 327,
	},
	{
		garage_id: 224,
		part_id: 396,
		quantity: 76,
	},
	{
		garage_id: 224,
		part_id: 397,
		quantity: 71,
	},
	{
		garage_id: 224,
		part_id: 398,
		quantity: 431,
	},
	{
		garage_id: 224,
		part_id: 399,
		quantity: 134,
	},
	{
		garage_id: 225,
		part_id: 0,
		quantity: 86,
	},
	{
		garage_id: 225,
		part_id: 1,
		quantity: 21,
	},
	{
		garage_id: 225,
		part_id: 2,
		quantity: 476,
	},
	{
		garage_id: 225,
		part_id: 3,
		quantity: 445,
	},
	{
		garage_id: 225,
		part_id: 4,
		quantity: 94,
	},
	{
		garage_id: 225,
		part_id: 5,
		quantity: 201,
	},
	{
		garage_id: 225,
		part_id: 6,
		quantity: 347,
	},
	{
		garage_id: 225,
		part_id: 7,
		quantity: 300,
	},
	{
		garage_id: 225,
		part_id: 8,
		quantity: 152,
	},
	{
		garage_id: 225,
		part_id: 9,
		quantity: 351,
	},
	{
		garage_id: 225,
		part_id: 10,
		quantity: 245,
	},
	{
		garage_id: 225,
		part_id: 11,
		quantity: 165,
	},
	{
		garage_id: 225,
		part_id: 12,
		quantity: 165,
	},
	{
		garage_id: 225,
		part_id: 13,
		quantity: 490,
	},
	{
		garage_id: 225,
		part_id: 14,
		quantity: 91,
	},
	{
		garage_id: 225,
		part_id: 15,
		quantity: 29,
	},
	{
		garage_id: 225,
		part_id: 16,
		quantity: 360,
	},
	{
		garage_id: 225,
		part_id: 17,
		quantity: 338,
	},
	{
		garage_id: 225,
		part_id: 18,
		quantity: 200,
	},
	{
		garage_id: 225,
		part_id: 19,
		quantity: 407,
	},
	{
		garage_id: 225,
		part_id: 20,
		quantity: 197,
	},
	{
		garage_id: 225,
		part_id: 21,
		quantity: 0,
	},
	{
		garage_id: 225,
		part_id: 22,
		quantity: 289,
	},
	{
		garage_id: 225,
		part_id: 23,
		quantity: 426,
	},
	{
		garage_id: 225,
		part_id: 24,
		quantity: 268,
	},
	{
		garage_id: 225,
		part_id: 25,
		quantity: 171,
	},
	{
		garage_id: 225,
		part_id: 26,
		quantity: 166,
	},
	{
		garage_id: 225,
		part_id: 27,
		quantity: 266,
	},
	{
		garage_id: 225,
		part_id: 28,
		quantity: 28,
	},
	{
		garage_id: 225,
		part_id: 29,
		quantity: 61,
	},
	{
		garage_id: 225,
		part_id: 30,
		quantity: 492,
	},
	{
		garage_id: 225,
		part_id: 31,
		quantity: 474,
	},
	{
		garage_id: 225,
		part_id: 32,
		quantity: 347,
	},
	{
		garage_id: 225,
		part_id: 33,
		quantity: 73,
	},
	{
		garage_id: 225,
		part_id: 34,
		quantity: 272,
	},
	{
		garage_id: 225,
		part_id: 35,
		quantity: 289,
	},
	{
		garage_id: 225,
		part_id: 36,
		quantity: 392,
	},
	{
		garage_id: 225,
		part_id: 37,
		quantity: 476,
	},
	{
		garage_id: 225,
		part_id: 38,
		quantity: 71,
	},
	{
		garage_id: 225,
		part_id: 39,
		quantity: 60,
	},
	{
		garage_id: 225,
		part_id: 40,
		quantity: 423,
	},
	{
		garage_id: 225,
		part_id: 41,
		quantity: 363,
	},
	{
		garage_id: 225,
		part_id: 42,
		quantity: 119,
	},
	{
		garage_id: 225,
		part_id: 43,
		quantity: 296,
	},
	{
		garage_id: 225,
		part_id: 44,
		quantity: 340,
	},
	{
		garage_id: 225,
		part_id: 45,
		quantity: 376,
	},
	{
		garage_id: 225,
		part_id: 46,
		quantity: 185,
	},
	{
		garage_id: 225,
		part_id: 47,
		quantity: 285,
	},
	{
		garage_id: 225,
		part_id: 48,
		quantity: 75,
	},
	{
		garage_id: 225,
		part_id: 49,
		quantity: 155,
	},
	{
		garage_id: 225,
		part_id: 50,
		quantity: 408,
	},
	{
		garage_id: 225,
		part_id: 51,
		quantity: 462,
	},
	{
		garage_id: 225,
		part_id: 52,
		quantity: 275,
	},
	{
		garage_id: 225,
		part_id: 53,
		quantity: 144,
	},
	{
		garage_id: 225,
		part_id: 54,
		quantity: 167,
	},
	{
		garage_id: 225,
		part_id: 55,
		quantity: 313,
	},
	{
		garage_id: 225,
		part_id: 56,
		quantity: 410,
	},
	{
		garage_id: 225,
		part_id: 57,
		quantity: 80,
	},
	{
		garage_id: 225,
		part_id: 58,
		quantity: 163,
	},
	{
		garage_id: 225,
		part_id: 59,
		quantity: 134,
	},
	{
		garage_id: 225,
		part_id: 60,
		quantity: 201,
	},
	{
		garage_id: 225,
		part_id: 61,
		quantity: 282,
	},
	{
		garage_id: 225,
		part_id: 62,
		quantity: 372,
	},
	{
		garage_id: 225,
		part_id: 63,
		quantity: 279,
	},
	{
		garage_id: 225,
		part_id: 64,
		quantity: 227,
	},
	{
		garage_id: 225,
		part_id: 65,
		quantity: 73,
	},
	{
		garage_id: 225,
		part_id: 66,
		quantity: 385,
	},
	{
		garage_id: 225,
		part_id: 67,
		quantity: 461,
	},
	{
		garage_id: 225,
		part_id: 68,
		quantity: 490,
	},
	{
		garage_id: 225,
		part_id: 69,
		quantity: 85,
	},
	{
		garage_id: 225,
		part_id: 70,
		quantity: 209,
	},
	{
		garage_id: 225,
		part_id: 71,
		quantity: 280,
	},
	{
		garage_id: 225,
		part_id: 72,
		quantity: 207,
	},
	{
		garage_id: 225,
		part_id: 73,
		quantity: 91,
	},
	{
		garage_id: 225,
		part_id: 74,
		quantity: 198,
	},
	{
		garage_id: 225,
		part_id: 75,
		quantity: 489,
	},
	{
		garage_id: 225,
		part_id: 76,
		quantity: 38,
	},
	{
		garage_id: 225,
		part_id: 77,
		quantity: 278,
	},
	{
		garage_id: 225,
		part_id: 78,
		quantity: 221,
	},
	{
		garage_id: 225,
		part_id: 79,
		quantity: 210,
	},
	{
		garage_id: 225,
		part_id: 80,
		quantity: 172,
	},
	{
		garage_id: 225,
		part_id: 81,
		quantity: 90,
	},
	{
		garage_id: 225,
		part_id: 82,
		quantity: 52,
	},
	{
		garage_id: 225,
		part_id: 83,
		quantity: 12,
	},
	{
		garage_id: 225,
		part_id: 84,
		quantity: 96,
	},
	{
		garage_id: 225,
		part_id: 85,
		quantity: 5,
	},
	{
		garage_id: 225,
		part_id: 86,
		quantity: 166,
	},
	{
		garage_id: 225,
		part_id: 87,
		quantity: 144,
	},
	{
		garage_id: 225,
		part_id: 88,
		quantity: 403,
	},
	{
		garage_id: 225,
		part_id: 89,
		quantity: 438,
	},
	{
		garage_id: 225,
		part_id: 90,
		quantity: 49,
	},
	{
		garage_id: 225,
		part_id: 91,
		quantity: 240,
	},
	{
		garage_id: 225,
		part_id: 92,
		quantity: 412,
	},
	{
		garage_id: 225,
		part_id: 93,
		quantity: 238,
	},
	{
		garage_id: 225,
		part_id: 94,
		quantity: 133,
	},
	{
		garage_id: 225,
		part_id: 95,
		quantity: 12,
	},
	{
		garage_id: 225,
		part_id: 96,
		quantity: 31,
	},
	{
		garage_id: 225,
		part_id: 97,
		quantity: 324,
	},
	{
		garage_id: 225,
		part_id: 98,
		quantity: 143,
	},
	{
		garage_id: 225,
		part_id: 99,
		quantity: 477,
	},
	{
		garage_id: 225,
		part_id: 100,
		quantity: 250,
	},
	{
		garage_id: 225,
		part_id: 101,
		quantity: 98,
	},
	{
		garage_id: 225,
		part_id: 102,
		quantity: 94,
	},
	{
		garage_id: 225,
		part_id: 103,
		quantity: 310,
	},
	{
		garage_id: 225,
		part_id: 104,
		quantity: 186,
	},
	{
		garage_id: 225,
		part_id: 105,
		quantity: 34,
	},
	{
		garage_id: 225,
		part_id: 106,
		quantity: 124,
	},
	{
		garage_id: 225,
		part_id: 107,
		quantity: 287,
	},
	{
		garage_id: 225,
		part_id: 108,
		quantity: 263,
	},
	{
		garage_id: 225,
		part_id: 109,
		quantity: 336,
	},
	{
		garage_id: 225,
		part_id: 110,
		quantity: 269,
	},
	{
		garage_id: 225,
		part_id: 111,
		quantity: 239,
	},
	{
		garage_id: 225,
		part_id: 112,
		quantity: 183,
	},
	{
		garage_id: 225,
		part_id: 113,
		quantity: 209,
	},
	{
		garage_id: 225,
		part_id: 114,
		quantity: 417,
	},
	{
		garage_id: 225,
		part_id: 115,
		quantity: 185,
	},
	{
		garage_id: 225,
		part_id: 116,
		quantity: 103,
	},
	{
		garage_id: 225,
		part_id: 117,
		quantity: 320,
	},
	{
		garage_id: 225,
		part_id: 118,
		quantity: 60,
	},
	{
		garage_id: 225,
		part_id: 119,
		quantity: 62,
	},
	{
		garage_id: 225,
		part_id: 120,
		quantity: 263,
	},
	{
		garage_id: 225,
		part_id: 121,
		quantity: 78,
	},
	{
		garage_id: 225,
		part_id: 122,
		quantity: 319,
	},
	{
		garage_id: 225,
		part_id: 123,
		quantity: 365,
	},
	{
		garage_id: 225,
		part_id: 124,
		quantity: 116,
	},
	{
		garage_id: 225,
		part_id: 125,
		quantity: 268,
	},
	{
		garage_id: 225,
		part_id: 126,
		quantity: 233,
	},
	{
		garage_id: 225,
		part_id: 127,
		quantity: 106,
	},
	{
		garage_id: 225,
		part_id: 128,
		quantity: 105,
	},
	{
		garage_id: 225,
		part_id: 129,
		quantity: 273,
	},
	{
		garage_id: 225,
		part_id: 130,
		quantity: 22,
	},
	{
		garage_id: 225,
		part_id: 131,
		quantity: 192,
	},
	{
		garage_id: 225,
		part_id: 132,
		quantity: 259,
	},
	{
		garage_id: 225,
		part_id: 133,
		quantity: 376,
	},
	{
		garage_id: 225,
		part_id: 134,
		quantity: 108,
	},
	{
		garage_id: 225,
		part_id: 135,
		quantity: 18,
	},
	{
		garage_id: 225,
		part_id: 136,
		quantity: 56,
	},
	{
		garage_id: 225,
		part_id: 137,
		quantity: 364,
	},
	{
		garage_id: 225,
		part_id: 138,
		quantity: 275,
	},
	{
		garage_id: 225,
		part_id: 139,
		quantity: 54,
	},
	{
		garage_id: 225,
		part_id: 140,
		quantity: 316,
	},
	{
		garage_id: 225,
		part_id: 141,
		quantity: 255,
	},
	{
		garage_id: 225,
		part_id: 142,
		quantity: 151,
	},
	{
		garage_id: 225,
		part_id: 143,
		quantity: 399,
	},
	{
		garage_id: 225,
		part_id: 144,
		quantity: 331,
	},
	{
		garage_id: 225,
		part_id: 145,
		quantity: 73,
	},
	{
		garage_id: 225,
		part_id: 146,
		quantity: 476,
	},
	{
		garage_id: 225,
		part_id: 147,
		quantity: 139,
	},
	{
		garage_id: 225,
		part_id: 148,
		quantity: 170,
	},
	{
		garage_id: 225,
		part_id: 149,
		quantity: 115,
	},
	{
		garage_id: 225,
		part_id: 150,
		quantity: 252,
	},
	{
		garage_id: 225,
		part_id: 151,
		quantity: 191,
	},
	{
		garage_id: 225,
		part_id: 152,
		quantity: 303,
	},
	{
		garage_id: 225,
		part_id: 153,
		quantity: 370,
	},
	{
		garage_id: 225,
		part_id: 154,
		quantity: 378,
	},
	{
		garage_id: 225,
		part_id: 155,
		quantity: 358,
	},
	{
		garage_id: 225,
		part_id: 156,
		quantity: 80,
	},
	{
		garage_id: 225,
		part_id: 157,
		quantity: 309,
	},
	{
		garage_id: 225,
		part_id: 158,
		quantity: 124,
	},
	{
		garage_id: 225,
		part_id: 159,
		quantity: 311,
	},
	{
		garage_id: 225,
		part_id: 160,
		quantity: 382,
	},
	{
		garage_id: 225,
		part_id: 161,
		quantity: 447,
	},
	{
		garage_id: 225,
		part_id: 162,
		quantity: 145,
	},
	{
		garage_id: 225,
		part_id: 163,
		quantity: 15,
	},
	{
		garage_id: 225,
		part_id: 164,
		quantity: 215,
	},
	{
		garage_id: 225,
		part_id: 165,
		quantity: 32,
	},
	{
		garage_id: 225,
		part_id: 166,
		quantity: 397,
	},
	{
		garage_id: 225,
		part_id: 167,
		quantity: 400,
	},
	{
		garage_id: 225,
		part_id: 168,
		quantity: 376,
	},
	{
		garage_id: 225,
		part_id: 169,
		quantity: 87,
	},
	{
		garage_id: 225,
		part_id: 170,
		quantity: 277,
	},
	{
		garage_id: 225,
		part_id: 171,
		quantity: 107,
	},
	{
		garage_id: 225,
		part_id: 172,
		quantity: 325,
	},
	{
		garage_id: 225,
		part_id: 173,
		quantity: 256,
	},
	{
		garage_id: 225,
		part_id: 174,
		quantity: 357,
	},
	{
		garage_id: 225,
		part_id: 175,
		quantity: 218,
	},
	{
		garage_id: 225,
		part_id: 176,
		quantity: 342,
	},
	{
		garage_id: 225,
		part_id: 177,
		quantity: 427,
	},
	{
		garage_id: 225,
		part_id: 178,
		quantity: 8,
	},
	{
		garage_id: 225,
		part_id: 179,
		quantity: 11,
	},
	{
		garage_id: 225,
		part_id: 180,
		quantity: 278,
	},
	{
		garage_id: 225,
		part_id: 181,
		quantity: 200,
	},
	{
		garage_id: 225,
		part_id: 182,
		quantity: 381,
	},
	{
		garage_id: 225,
		part_id: 183,
		quantity: 71,
	},
	{
		garage_id: 225,
		part_id: 184,
		quantity: 302,
	},
	{
		garage_id: 225,
		part_id: 185,
		quantity: 226,
	},
	{
		garage_id: 225,
		part_id: 186,
		quantity: 471,
	},
	{
		garage_id: 225,
		part_id: 187,
		quantity: 169,
	},
	{
		garage_id: 225,
		part_id: 188,
		quantity: 288,
	},
	{
		garage_id: 225,
		part_id: 189,
		quantity: 198,
	},
	{
		garage_id: 225,
		part_id: 190,
		quantity: 227,
	},
	{
		garage_id: 225,
		part_id: 191,
		quantity: 30,
	},
	{
		garage_id: 225,
		part_id: 192,
		quantity: 309,
	},
	{
		garage_id: 225,
		part_id: 193,
		quantity: 380,
	},
	{
		garage_id: 225,
		part_id: 194,
		quantity: 452,
	},
	{
		garage_id: 225,
		part_id: 195,
		quantity: 421,
	},
	{
		garage_id: 225,
		part_id: 196,
		quantity: 378,
	},
	{
		garage_id: 225,
		part_id: 197,
		quantity: 421,
	},
	{
		garage_id: 225,
		part_id: 198,
		quantity: 207,
	},
	{
		garage_id: 225,
		part_id: 199,
		quantity: 415,
	},
	{
		garage_id: 225,
		part_id: 200,
		quantity: 233,
	},
	{
		garage_id: 225,
		part_id: 201,
		quantity: 278,
	},
	{
		garage_id: 225,
		part_id: 202,
		quantity: 292,
	},
	{
		garage_id: 225,
		part_id: 203,
		quantity: 129,
	},
	{
		garage_id: 225,
		part_id: 204,
		quantity: 341,
	},
	{
		garage_id: 225,
		part_id: 205,
		quantity: 291,
	},
	{
		garage_id: 225,
		part_id: 206,
		quantity: 382,
	},
	{
		garage_id: 225,
		part_id: 207,
		quantity: 370,
	},
	{
		garage_id: 225,
		part_id: 208,
		quantity: 25,
	},
	{
		garage_id: 225,
		part_id: 209,
		quantity: 147,
	},
	{
		garage_id: 225,
		part_id: 210,
		quantity: 317,
	},
	{
		garage_id: 225,
		part_id: 211,
		quantity: 144,
	},
	{
		garage_id: 225,
		part_id: 212,
		quantity: 18,
	},
	{
		garage_id: 225,
		part_id: 213,
		quantity: 213,
	},
	{
		garage_id: 225,
		part_id: 214,
		quantity: 279,
	},
	{
		garage_id: 225,
		part_id: 215,
		quantity: 142,
	},
	{
		garage_id: 225,
		part_id: 216,
		quantity: 298,
	},
	{
		garage_id: 225,
		part_id: 217,
		quantity: 401,
	},
	{
		garage_id: 225,
		part_id: 218,
		quantity: 41,
	},
	{
		garage_id: 225,
		part_id: 219,
		quantity: 91,
	},
	{
		garage_id: 225,
		part_id: 220,
		quantity: 276,
	},
	{
		garage_id: 225,
		part_id: 221,
		quantity: 319,
	},
	{
		garage_id: 225,
		part_id: 222,
		quantity: 14,
	},
	{
		garage_id: 225,
		part_id: 223,
		quantity: 376,
	},
	{
		garage_id: 225,
		part_id: 224,
		quantity: 463,
	},
	{
		garage_id: 225,
		part_id: 225,
		quantity: 182,
	},
	{
		garage_id: 225,
		part_id: 226,
		quantity: 99,
	},
	{
		garage_id: 225,
		part_id: 227,
		quantity: 402,
	},
	{
		garage_id: 225,
		part_id: 228,
		quantity: 265,
	},
	{
		garage_id: 225,
		part_id: 229,
		quantity: 429,
	},
	{
		garage_id: 225,
		part_id: 230,
		quantity: 249,
	},
	{
		garage_id: 225,
		part_id: 231,
		quantity: 264,
	},
	{
		garage_id: 225,
		part_id: 232,
		quantity: 0,
	},
	{
		garage_id: 225,
		part_id: 233,
		quantity: 244,
	},
	{
		garage_id: 225,
		part_id: 234,
		quantity: 290,
	},
	{
		garage_id: 225,
		part_id: 235,
		quantity: 82,
	},
	{
		garage_id: 225,
		part_id: 236,
		quantity: 407,
	},
	{
		garage_id: 225,
		part_id: 237,
		quantity: 59,
	},
	{
		garage_id: 225,
		part_id: 238,
		quantity: 104,
	},
	{
		garage_id: 225,
		part_id: 239,
		quantity: 42,
	},
	{
		garage_id: 225,
		part_id: 240,
		quantity: 147,
	},
	{
		garage_id: 225,
		part_id: 241,
		quantity: 164,
	},
	{
		garage_id: 225,
		part_id: 242,
		quantity: 253,
	},
	{
		garage_id: 225,
		part_id: 243,
		quantity: 118,
	},
	{
		garage_id: 225,
		part_id: 244,
		quantity: 44,
	},
	{
		garage_id: 225,
		part_id: 245,
		quantity: 131,
	},
	{
		garage_id: 225,
		part_id: 246,
		quantity: 307,
	},
	{
		garage_id: 225,
		part_id: 247,
		quantity: 29,
	},
	{
		garage_id: 225,
		part_id: 248,
		quantity: 464,
	},
	{
		garage_id: 225,
		part_id: 249,
		quantity: 390,
	},
	{
		garage_id: 225,
		part_id: 250,
		quantity: 89,
	},
	{
		garage_id: 225,
		part_id: 251,
		quantity: 137,
	},
	{
		garage_id: 225,
		part_id: 252,
		quantity: 459,
	},
	{
		garage_id: 225,
		part_id: 253,
		quantity: 230,
	},
	{
		garage_id: 225,
		part_id: 254,
		quantity: 277,
	},
	{
		garage_id: 225,
		part_id: 255,
		quantity: 69,
	},
	{
		garage_id: 225,
		part_id: 256,
		quantity: 337,
	},
	{
		garage_id: 225,
		part_id: 257,
		quantity: 235,
	},
	{
		garage_id: 225,
		part_id: 258,
		quantity: 187,
	},
	{
		garage_id: 225,
		part_id: 259,
		quantity: 426,
	},
	{
		garage_id: 225,
		part_id: 260,
		quantity: 162,
	},
	{
		garage_id: 225,
		part_id: 261,
		quantity: 217,
	},
	{
		garage_id: 225,
		part_id: 262,
		quantity: 137,
	},
	{
		garage_id: 225,
		part_id: 263,
		quantity: 374,
	},
	{
		garage_id: 225,
		part_id: 264,
		quantity: 199,
	},
	{
		garage_id: 225,
		part_id: 265,
		quantity: 460,
	},
	{
		garage_id: 225,
		part_id: 266,
		quantity: 250,
	},
	{
		garage_id: 225,
		part_id: 267,
		quantity: 305,
	},
	{
		garage_id: 225,
		part_id: 268,
		quantity: 4,
	},
	{
		garage_id: 225,
		part_id: 269,
		quantity: 163,
	},
	{
		garage_id: 225,
		part_id: 270,
		quantity: 170,
	},
	{
		garage_id: 225,
		part_id: 271,
		quantity: 187,
	},
	{
		garage_id: 225,
		part_id: 272,
		quantity: 279,
	},
	{
		garage_id: 225,
		part_id: 273,
		quantity: 269,
	},
	{
		garage_id: 225,
		part_id: 274,
		quantity: 385,
	},
	{
		garage_id: 225,
		part_id: 275,
		quantity: 170,
	},
	{
		garage_id: 225,
		part_id: 276,
		quantity: 15,
	},
	{
		garage_id: 225,
		part_id: 277,
		quantity: 220,
	},
	{
		garage_id: 225,
		part_id: 278,
		quantity: 183,
	},
	{
		garage_id: 225,
		part_id: 279,
		quantity: 443,
	},
	{
		garage_id: 225,
		part_id: 280,
		quantity: 493,
	},
	{
		garage_id: 225,
		part_id: 281,
		quantity: 27,
	},
	{
		garage_id: 225,
		part_id: 282,
		quantity: 30,
	},
	{
		garage_id: 225,
		part_id: 283,
		quantity: 57,
	},
	{
		garage_id: 225,
		part_id: 284,
		quantity: 452,
	},
	{
		garage_id: 225,
		part_id: 285,
		quantity: 101,
	},
	{
		garage_id: 225,
		part_id: 286,
		quantity: 213,
	},
	{
		garage_id: 225,
		part_id: 287,
		quantity: 163,
	},
	{
		garage_id: 225,
		part_id: 288,
		quantity: 183,
	},
	{
		garage_id: 225,
		part_id: 289,
		quantity: 243,
	},
	{
		garage_id: 225,
		part_id: 290,
		quantity: 446,
	},
	{
		garage_id: 225,
		part_id: 291,
		quantity: 355,
	},
	{
		garage_id: 225,
		part_id: 292,
		quantity: 454,
	},
	{
		garage_id: 225,
		part_id: 293,
		quantity: 287,
	},
	{
		garage_id: 225,
		part_id: 294,
		quantity: 122,
	},
	{
		garage_id: 225,
		part_id: 295,
		quantity: 241,
	},
	{
		garage_id: 225,
		part_id: 296,
		quantity: 496,
	},
	{
		garage_id: 225,
		part_id: 297,
		quantity: 396,
	},
	{
		garage_id: 225,
		part_id: 298,
		quantity: 187,
	},
	{
		garage_id: 225,
		part_id: 299,
		quantity: 473,
	},
	{
		garage_id: 225,
		part_id: 300,
		quantity: 378,
	},
	{
		garage_id: 225,
		part_id: 301,
		quantity: 114,
	},
	{
		garage_id: 225,
		part_id: 302,
		quantity: 61,
	},
	{
		garage_id: 225,
		part_id: 303,
		quantity: 462,
	},
	{
		garage_id: 225,
		part_id: 304,
		quantity: 436,
	},
	{
		garage_id: 225,
		part_id: 305,
		quantity: 376,
	},
	{
		garage_id: 225,
		part_id: 306,
		quantity: 15,
	},
	{
		garage_id: 225,
		part_id: 307,
		quantity: 231,
	},
	{
		garage_id: 225,
		part_id: 308,
		quantity: 479,
	},
	{
		garage_id: 225,
		part_id: 309,
		quantity: 50,
	},
	{
		garage_id: 225,
		part_id: 310,
		quantity: 483,
	},
	{
		garage_id: 225,
		part_id: 311,
		quantity: 353,
	},
	{
		garage_id: 225,
		part_id: 312,
		quantity: 169,
	},
	{
		garage_id: 225,
		part_id: 313,
		quantity: 411,
	},
	{
		garage_id: 225,
		part_id: 314,
		quantity: 454,
	},
	{
		garage_id: 225,
		part_id: 315,
		quantity: 34,
	},
	{
		garage_id: 225,
		part_id: 316,
		quantity: 476,
	},
	{
		garage_id: 225,
		part_id: 317,
		quantity: 338,
	},
	{
		garage_id: 225,
		part_id: 318,
		quantity: 472,
	},
	{
		garage_id: 225,
		part_id: 319,
		quantity: 92,
	},
	{
		garage_id: 225,
		part_id: 320,
		quantity: 182,
	},
	{
		garage_id: 225,
		part_id: 321,
		quantity: 73,
	},
	{
		garage_id: 225,
		part_id: 322,
		quantity: 22,
	},
	{
		garage_id: 225,
		part_id: 323,
		quantity: 406,
	},
	{
		garage_id: 225,
		part_id: 324,
		quantity: 263,
	},
	{
		garage_id: 225,
		part_id: 325,
		quantity: 226,
	},
	{
		garage_id: 225,
		part_id: 326,
		quantity: 179,
	},
	{
		garage_id: 225,
		part_id: 327,
		quantity: 331,
	},
	{
		garage_id: 225,
		part_id: 328,
		quantity: 58,
	},
	{
		garage_id: 225,
		part_id: 329,
		quantity: 5,
	},
	{
		garage_id: 225,
		part_id: 330,
		quantity: 329,
	},
	{
		garage_id: 225,
		part_id: 331,
		quantity: 94,
	},
	{
		garage_id: 225,
		part_id: 332,
		quantity: 247,
	},
	{
		garage_id: 225,
		part_id: 333,
		quantity: 69,
	},
	{
		garage_id: 225,
		part_id: 334,
		quantity: 218,
	},
	{
		garage_id: 225,
		part_id: 335,
		quantity: 446,
	},
	{
		garage_id: 225,
		part_id: 336,
		quantity: 202,
	},
	{
		garage_id: 225,
		part_id: 337,
		quantity: 35,
	},
	{
		garage_id: 225,
		part_id: 338,
		quantity: 401,
	},
	{
		garage_id: 225,
		part_id: 339,
		quantity: 292,
	},
	{
		garage_id: 225,
		part_id: 340,
		quantity: 480,
	},
	{
		garage_id: 225,
		part_id: 341,
		quantity: 91,
	},
	{
		garage_id: 225,
		part_id: 342,
		quantity: 79,
	},
	{
		garage_id: 225,
		part_id: 343,
		quantity: 91,
	},
	{
		garage_id: 225,
		part_id: 344,
		quantity: 286,
	},
	{
		garage_id: 225,
		part_id: 345,
		quantity: 394,
	},
	{
		garage_id: 225,
		part_id: 346,
		quantity: 122,
	},
	{
		garage_id: 225,
		part_id: 347,
		quantity: 55,
	},
	{
		garage_id: 225,
		part_id: 348,
		quantity: 28,
	},
	{
		garage_id: 225,
		part_id: 349,
		quantity: 261,
	},
	{
		garage_id: 225,
		part_id: 350,
		quantity: 227,
	},
	{
		garage_id: 225,
		part_id: 351,
		quantity: 76,
	},
	{
		garage_id: 225,
		part_id: 352,
		quantity: 6,
	},
	{
		garage_id: 225,
		part_id: 353,
		quantity: 468,
	},
	{
		garage_id: 225,
		part_id: 354,
		quantity: 32,
	},
	{
		garage_id: 225,
		part_id: 355,
		quantity: 42,
	},
	{
		garage_id: 225,
		part_id: 356,
		quantity: 475,
	},
	{
		garage_id: 225,
		part_id: 357,
		quantity: 438,
	},
	{
		garage_id: 225,
		part_id: 358,
		quantity: 488,
	},
	{
		garage_id: 225,
		part_id: 359,
		quantity: 265,
	},
	{
		garage_id: 225,
		part_id: 360,
		quantity: 101,
	},
	{
		garage_id: 225,
		part_id: 361,
		quantity: 352,
	},
	{
		garage_id: 225,
		part_id: 362,
		quantity: 32,
	},
	{
		garage_id: 225,
		part_id: 363,
		quantity: 188,
	},
	{
		garage_id: 225,
		part_id: 364,
		quantity: 150,
	},
	{
		garage_id: 225,
		part_id: 365,
		quantity: 51,
	},
	{
		garage_id: 225,
		part_id: 366,
		quantity: 91,
	},
	{
		garage_id: 225,
		part_id: 367,
		quantity: 21,
	},
	{
		garage_id: 225,
		part_id: 368,
		quantity: 212,
	},
	{
		garage_id: 225,
		part_id: 369,
		quantity: 426,
	},
	{
		garage_id: 225,
		part_id: 370,
		quantity: 177,
	},
	{
		garage_id: 225,
		part_id: 371,
		quantity: 454,
	},
	{
		garage_id: 225,
		part_id: 372,
		quantity: 430,
	},
	{
		garage_id: 225,
		part_id: 373,
		quantity: 109,
	},
	{
		garage_id: 225,
		part_id: 374,
		quantity: 85,
	},
	{
		garage_id: 225,
		part_id: 375,
		quantity: 276,
	},
	{
		garage_id: 225,
		part_id: 376,
		quantity: 269,
	},
	{
		garage_id: 225,
		part_id: 377,
		quantity: 481,
	},
	{
		garage_id: 225,
		part_id: 378,
		quantity: 413,
	},
	{
		garage_id: 225,
		part_id: 379,
		quantity: 426,
	},
	{
		garage_id: 225,
		part_id: 380,
		quantity: 137,
	},
	{
		garage_id: 225,
		part_id: 381,
		quantity: 197,
	},
	{
		garage_id: 225,
		part_id: 382,
		quantity: 478,
	},
	{
		garage_id: 225,
		part_id: 383,
		quantity: 318,
	},
	{
		garage_id: 225,
		part_id: 384,
		quantity: 258,
	},
	{
		garage_id: 225,
		part_id: 385,
		quantity: 315,
	},
	{
		garage_id: 225,
		part_id: 386,
		quantity: 289,
	},
	{
		garage_id: 225,
		part_id: 387,
		quantity: 103,
	},
	{
		garage_id: 225,
		part_id: 388,
		quantity: 129,
	},
	{
		garage_id: 225,
		part_id: 389,
		quantity: 70,
	},
	{
		garage_id: 225,
		part_id: 390,
		quantity: 468,
	},
	{
		garage_id: 225,
		part_id: 391,
		quantity: 0,
	},
	{
		garage_id: 225,
		part_id: 392,
		quantity: 349,
	},
	{
		garage_id: 225,
		part_id: 393,
		quantity: 285,
	},
	{
		garage_id: 225,
		part_id: 394,
		quantity: 257,
	},
	{
		garage_id: 225,
		part_id: 395,
		quantity: 456,
	},
	{
		garage_id: 225,
		part_id: 396,
		quantity: 425,
	},
	{
		garage_id: 225,
		part_id: 397,
		quantity: 461,
	},
	{
		garage_id: 225,
		part_id: 398,
		quantity: 264,
	},
	{
		garage_id: 225,
		part_id: 399,
		quantity: 155,
	},
	{
		garage_id: 226,
		part_id: 0,
		quantity: 65,
	},
	{
		garage_id: 226,
		part_id: 1,
		quantity: 3,
	},
	{
		garage_id: 226,
		part_id: 2,
		quantity: 166,
	},
	{
		garage_id: 226,
		part_id: 3,
		quantity: 388,
	},
	{
		garage_id: 226,
		part_id: 4,
		quantity: 130,
	},
	{
		garage_id: 226,
		part_id: 5,
		quantity: 379,
	},
	{
		garage_id: 226,
		part_id: 6,
		quantity: 229,
	},
	{
		garage_id: 226,
		part_id: 7,
		quantity: 291,
	},
	{
		garage_id: 226,
		part_id: 8,
		quantity: 160,
	},
	{
		garage_id: 226,
		part_id: 9,
		quantity: 107,
	},
	{
		garage_id: 226,
		part_id: 10,
		quantity: 337,
	},
	{
		garage_id: 226,
		part_id: 11,
		quantity: 249,
	},
	{
		garage_id: 226,
		part_id: 12,
		quantity: 388,
	},
	{
		garage_id: 226,
		part_id: 13,
		quantity: 303,
	},
	{
		garage_id: 226,
		part_id: 14,
		quantity: 211,
	},
	{
		garage_id: 226,
		part_id: 15,
		quantity: 177,
	},
	{
		garage_id: 226,
		part_id: 16,
		quantity: 99,
	},
	{
		garage_id: 226,
		part_id: 17,
		quantity: 316,
	},
	{
		garage_id: 226,
		part_id: 18,
		quantity: 36,
	},
	{
		garage_id: 226,
		part_id: 19,
		quantity: 403,
	},
	{
		garage_id: 226,
		part_id: 20,
		quantity: 418,
	},
	{
		garage_id: 226,
		part_id: 21,
		quantity: 471,
	},
	{
		garage_id: 226,
		part_id: 22,
		quantity: 152,
	},
	{
		garage_id: 226,
		part_id: 23,
		quantity: 149,
	},
	{
		garage_id: 226,
		part_id: 24,
		quantity: 398,
	},
	{
		garage_id: 226,
		part_id: 25,
		quantity: 318,
	},
	{
		garage_id: 226,
		part_id: 26,
		quantity: 163,
	},
	{
		garage_id: 226,
		part_id: 27,
		quantity: 414,
	},
	{
		garage_id: 226,
		part_id: 28,
		quantity: 45,
	},
	{
		garage_id: 226,
		part_id: 29,
		quantity: 30,
	},
	{
		garage_id: 226,
		part_id: 30,
		quantity: 439,
	},
	{
		garage_id: 226,
		part_id: 31,
		quantity: 380,
	},
	{
		garage_id: 226,
		part_id: 32,
		quantity: 227,
	},
	{
		garage_id: 226,
		part_id: 33,
		quantity: 417,
	},
	{
		garage_id: 226,
		part_id: 34,
		quantity: 403,
	},
	{
		garage_id: 226,
		part_id: 35,
		quantity: 144,
	},
	{
		garage_id: 226,
		part_id: 36,
		quantity: 91,
	},
	{
		garage_id: 226,
		part_id: 37,
		quantity: 143,
	},
	{
		garage_id: 226,
		part_id: 38,
		quantity: 500,
	},
	{
		garage_id: 226,
		part_id: 39,
		quantity: 348,
	},
	{
		garage_id: 226,
		part_id: 40,
		quantity: 233,
	},
	{
		garage_id: 226,
		part_id: 41,
		quantity: 454,
	},
	{
		garage_id: 226,
		part_id: 42,
		quantity: 41,
	},
	{
		garage_id: 226,
		part_id: 43,
		quantity: 409,
	},
	{
		garage_id: 226,
		part_id: 44,
		quantity: 179,
	},
	{
		garage_id: 226,
		part_id: 45,
		quantity: 255,
	},
	{
		garage_id: 226,
		part_id: 46,
		quantity: 411,
	},
	{
		garage_id: 226,
		part_id: 47,
		quantity: 437,
	},
	{
		garage_id: 226,
		part_id: 48,
		quantity: 495,
	},
	{
		garage_id: 226,
		part_id: 49,
		quantity: 346,
	},
	{
		garage_id: 226,
		part_id: 50,
		quantity: 230,
	},
	{
		garage_id: 226,
		part_id: 51,
		quantity: 55,
	},
	{
		garage_id: 226,
		part_id: 52,
		quantity: 365,
	},
	{
		garage_id: 226,
		part_id: 53,
		quantity: 410,
	},
	{
		garage_id: 226,
		part_id: 54,
		quantity: 295,
	},
	{
		garage_id: 226,
		part_id: 55,
		quantity: 58,
	},
	{
		garage_id: 226,
		part_id: 56,
		quantity: 156,
	},
	{
		garage_id: 226,
		part_id: 57,
		quantity: 476,
	},
	{
		garage_id: 226,
		part_id: 58,
		quantity: 202,
	},
	{
		garage_id: 226,
		part_id: 59,
		quantity: 179,
	},
	{
		garage_id: 226,
		part_id: 60,
		quantity: 353,
	},
	{
		garage_id: 226,
		part_id: 61,
		quantity: 493,
	},
	{
		garage_id: 226,
		part_id: 62,
		quantity: 321,
	},
	{
		garage_id: 226,
		part_id: 63,
		quantity: 51,
	},
	{
		garage_id: 226,
		part_id: 64,
		quantity: 73,
	},
	{
		garage_id: 226,
		part_id: 65,
		quantity: 364,
	},
	{
		garage_id: 226,
		part_id: 66,
		quantity: 22,
	},
	{
		garage_id: 226,
		part_id: 67,
		quantity: 121,
	},
	{
		garage_id: 226,
		part_id: 68,
		quantity: 262,
	},
	{
		garage_id: 226,
		part_id: 69,
		quantity: 242,
	},
	{
		garage_id: 226,
		part_id: 70,
		quantity: 339,
	},
	{
		garage_id: 226,
		part_id: 71,
		quantity: 66,
	},
	{
		garage_id: 226,
		part_id: 72,
		quantity: 398,
	},
	{
		garage_id: 226,
		part_id: 73,
		quantity: 180,
	},
	{
		garage_id: 226,
		part_id: 74,
		quantity: 446,
	},
	{
		garage_id: 226,
		part_id: 75,
		quantity: 76,
	},
	{
		garage_id: 226,
		part_id: 76,
		quantity: 278,
	},
	{
		garage_id: 226,
		part_id: 77,
		quantity: 204,
	},
	{
		garage_id: 226,
		part_id: 78,
		quantity: 174,
	},
	{
		garage_id: 226,
		part_id: 79,
		quantity: 106,
	},
	{
		garage_id: 226,
		part_id: 80,
		quantity: 168,
	},
	{
		garage_id: 226,
		part_id: 81,
		quantity: 498,
	},
	{
		garage_id: 226,
		part_id: 82,
		quantity: 420,
	},
	{
		garage_id: 226,
		part_id: 83,
		quantity: 353,
	},
	{
		garage_id: 226,
		part_id: 84,
		quantity: 153,
	},
	{
		garage_id: 226,
		part_id: 85,
		quantity: 493,
	},
	{
		garage_id: 226,
		part_id: 86,
		quantity: 38,
	},
	{
		garage_id: 226,
		part_id: 87,
		quantity: 258,
	},
	{
		garage_id: 226,
		part_id: 88,
		quantity: 263,
	},
	{
		garage_id: 226,
		part_id: 89,
		quantity: 426,
	},
	{
		garage_id: 226,
		part_id: 90,
		quantity: 212,
	},
	{
		garage_id: 226,
		part_id: 91,
		quantity: 57,
	},
	{
		garage_id: 226,
		part_id: 92,
		quantity: 374,
	},
	{
		garage_id: 226,
		part_id: 93,
		quantity: 331,
	},
	{
		garage_id: 226,
		part_id: 94,
		quantity: 396,
	},
	{
		garage_id: 226,
		part_id: 95,
		quantity: 344,
	},
	{
		garage_id: 226,
		part_id: 96,
		quantity: 218,
	},
	{
		garage_id: 226,
		part_id: 97,
		quantity: 49,
	},
	{
		garage_id: 226,
		part_id: 98,
		quantity: 202,
	},
	{
		garage_id: 226,
		part_id: 99,
		quantity: 323,
	},
	{
		garage_id: 226,
		part_id: 100,
		quantity: 223,
	},
	{
		garage_id: 226,
		part_id: 101,
		quantity: 402,
	},
	{
		garage_id: 226,
		part_id: 102,
		quantity: 473,
	},
	{
		garage_id: 226,
		part_id: 103,
		quantity: 78,
	},
	{
		garage_id: 226,
		part_id: 104,
		quantity: 257,
	},
	{
		garage_id: 226,
		part_id: 105,
		quantity: 332,
	},
	{
		garage_id: 226,
		part_id: 106,
		quantity: 68,
	},
	{
		garage_id: 226,
		part_id: 107,
		quantity: 474,
	},
	{
		garage_id: 226,
		part_id: 108,
		quantity: 181,
	},
	{
		garage_id: 226,
		part_id: 109,
		quantity: 70,
	},
	{
		garage_id: 226,
		part_id: 110,
		quantity: 18,
	},
	{
		garage_id: 226,
		part_id: 111,
		quantity: 432,
	},
	{
		garage_id: 226,
		part_id: 112,
		quantity: 235,
	},
	{
		garage_id: 226,
		part_id: 113,
		quantity: 224,
	},
	{
		garage_id: 226,
		part_id: 114,
		quantity: 32,
	},
	{
		garage_id: 226,
		part_id: 115,
		quantity: 119,
	},
	{
		garage_id: 226,
		part_id: 116,
		quantity: 409,
	},
	{
		garage_id: 226,
		part_id: 117,
		quantity: 317,
	},
	{
		garage_id: 226,
		part_id: 118,
		quantity: 214,
	},
	{
		garage_id: 226,
		part_id: 119,
		quantity: 191,
	},
	{
		garage_id: 226,
		part_id: 120,
		quantity: 445,
	},
	{
		garage_id: 226,
		part_id: 121,
		quantity: 180,
	},
	{
		garage_id: 226,
		part_id: 122,
		quantity: 128,
	},
	{
		garage_id: 226,
		part_id: 123,
		quantity: 262,
	},
	{
		garage_id: 226,
		part_id: 124,
		quantity: 276,
	},
	{
		garage_id: 226,
		part_id: 125,
		quantity: 224,
	},
	{
		garage_id: 226,
		part_id: 126,
		quantity: 158,
	},
	{
		garage_id: 226,
		part_id: 127,
		quantity: 145,
	},
	{
		garage_id: 226,
		part_id: 128,
		quantity: 264,
	},
	{
		garage_id: 226,
		part_id: 129,
		quantity: 34,
	},
	{
		garage_id: 226,
		part_id: 130,
		quantity: 441,
	},
	{
		garage_id: 226,
		part_id: 131,
		quantity: 208,
	},
	{
		garage_id: 226,
		part_id: 132,
		quantity: 188,
	},
	{
		garage_id: 226,
		part_id: 133,
		quantity: 140,
	},
	{
		garage_id: 226,
		part_id: 134,
		quantity: 99,
	},
	{
		garage_id: 226,
		part_id: 135,
		quantity: 69,
	},
	{
		garage_id: 226,
		part_id: 136,
		quantity: 333,
	},
	{
		garage_id: 226,
		part_id: 137,
		quantity: 324,
	},
	{
		garage_id: 226,
		part_id: 138,
		quantity: 262,
	},
	{
		garage_id: 226,
		part_id: 139,
		quantity: 33,
	},
	{
		garage_id: 226,
		part_id: 140,
		quantity: 383,
	},
	{
		garage_id: 226,
		part_id: 141,
		quantity: 449,
	},
	{
		garage_id: 226,
		part_id: 142,
		quantity: 325,
	},
	{
		garage_id: 226,
		part_id: 143,
		quantity: 465,
	},
	{
		garage_id: 226,
		part_id: 144,
		quantity: 372,
	},
	{
		garage_id: 226,
		part_id: 145,
		quantity: 296,
	},
	{
		garage_id: 226,
		part_id: 146,
		quantity: 222,
	},
	{
		garage_id: 226,
		part_id: 147,
		quantity: 279,
	},
	{
		garage_id: 226,
		part_id: 148,
		quantity: 138,
	},
	{
		garage_id: 226,
		part_id: 149,
		quantity: 444,
	},
	{
		garage_id: 226,
		part_id: 150,
		quantity: 31,
	},
	{
		garage_id: 226,
		part_id: 151,
		quantity: 417,
	},
	{
		garage_id: 226,
		part_id: 152,
		quantity: 471,
	},
	{
		garage_id: 226,
		part_id: 153,
		quantity: 469,
	},
	{
		garage_id: 226,
		part_id: 154,
		quantity: 474,
	},
	{
		garage_id: 226,
		part_id: 155,
		quantity: 85,
	},
	{
		garage_id: 226,
		part_id: 156,
		quantity: 225,
	},
	{
		garage_id: 226,
		part_id: 157,
		quantity: 122,
	},
	{
		garage_id: 226,
		part_id: 158,
		quantity: 344,
	},
	{
		garage_id: 226,
		part_id: 159,
		quantity: 275,
	},
	{
		garage_id: 226,
		part_id: 160,
		quantity: 215,
	},
	{
		garage_id: 226,
		part_id: 161,
		quantity: 100,
	},
	{
		garage_id: 226,
		part_id: 162,
		quantity: 388,
	},
	{
		garage_id: 226,
		part_id: 163,
		quantity: 275,
	},
	{
		garage_id: 226,
		part_id: 164,
		quantity: 37,
	},
	{
		garage_id: 226,
		part_id: 165,
		quantity: 352,
	},
	{
		garage_id: 226,
		part_id: 166,
		quantity: 134,
	},
	{
		garage_id: 226,
		part_id: 167,
		quantity: 294,
	},
	{
		garage_id: 226,
		part_id: 168,
		quantity: 61,
	},
	{
		garage_id: 226,
		part_id: 169,
		quantity: 47,
	},
	{
		garage_id: 226,
		part_id: 170,
		quantity: 68,
	},
	{
		garage_id: 226,
		part_id: 171,
		quantity: 190,
	},
	{
		garage_id: 226,
		part_id: 172,
		quantity: 479,
	},
	{
		garage_id: 226,
		part_id: 173,
		quantity: 172,
	},
	{
		garage_id: 226,
		part_id: 174,
		quantity: 44,
	},
	{
		garage_id: 226,
		part_id: 175,
		quantity: 380,
	},
	{
		garage_id: 226,
		part_id: 176,
		quantity: 232,
	},
	{
		garage_id: 226,
		part_id: 177,
		quantity: 396,
	},
	{
		garage_id: 226,
		part_id: 178,
		quantity: 336,
	},
	{
		garage_id: 226,
		part_id: 179,
		quantity: 351,
	},
	{
		garage_id: 226,
		part_id: 180,
		quantity: 318,
	},
	{
		garage_id: 226,
		part_id: 181,
		quantity: 292,
	},
	{
		garage_id: 226,
		part_id: 182,
		quantity: 146,
	},
	{
		garage_id: 226,
		part_id: 183,
		quantity: 279,
	},
	{
		garage_id: 226,
		part_id: 184,
		quantity: 453,
	},
	{
		garage_id: 226,
		part_id: 185,
		quantity: 244,
	},
	{
		garage_id: 226,
		part_id: 186,
		quantity: 321,
	},
	{
		garage_id: 226,
		part_id: 187,
		quantity: 134,
	},
	{
		garage_id: 226,
		part_id: 188,
		quantity: 304,
	},
	{
		garage_id: 226,
		part_id: 189,
		quantity: 113,
	},
	{
		garage_id: 226,
		part_id: 190,
		quantity: 162,
	},
	{
		garage_id: 226,
		part_id: 191,
		quantity: 243,
	},
	{
		garage_id: 226,
		part_id: 192,
		quantity: 384,
	},
	{
		garage_id: 226,
		part_id: 193,
		quantity: 326,
	},
	{
		garage_id: 226,
		part_id: 194,
		quantity: 167,
	},
	{
		garage_id: 226,
		part_id: 195,
		quantity: 212,
	},
	{
		garage_id: 226,
		part_id: 196,
		quantity: 28,
	},
	{
		garage_id: 226,
		part_id: 197,
		quantity: 176,
	},
	{
		garage_id: 226,
		part_id: 198,
		quantity: 277,
	},
	{
		garage_id: 226,
		part_id: 199,
		quantity: 493,
	},
	{
		garage_id: 226,
		part_id: 200,
		quantity: 83,
	},
	{
		garage_id: 226,
		part_id: 201,
		quantity: 480,
	},
	{
		garage_id: 226,
		part_id: 202,
		quantity: 31,
	},
	{
		garage_id: 226,
		part_id: 203,
		quantity: 404,
	},
	{
		garage_id: 226,
		part_id: 204,
		quantity: 89,
	},
	{
		garage_id: 226,
		part_id: 205,
		quantity: 389,
	},
	{
		garage_id: 226,
		part_id: 206,
		quantity: 398,
	},
	{
		garage_id: 226,
		part_id: 207,
		quantity: 34,
	},
	{
		garage_id: 226,
		part_id: 208,
		quantity: 288,
	},
	{
		garage_id: 226,
		part_id: 209,
		quantity: 305,
	},
	{
		garage_id: 226,
		part_id: 210,
		quantity: 167,
	},
	{
		garage_id: 226,
		part_id: 211,
		quantity: 38,
	},
	{
		garage_id: 226,
		part_id: 212,
		quantity: 348,
	},
	{
		garage_id: 226,
		part_id: 213,
		quantity: 66,
	},
	{
		garage_id: 226,
		part_id: 214,
		quantity: 229,
	},
	{
		garage_id: 226,
		part_id: 215,
		quantity: 488,
	},
	{
		garage_id: 226,
		part_id: 216,
		quantity: 374,
	},
	{
		garage_id: 226,
		part_id: 217,
		quantity: 289,
	},
	{
		garage_id: 226,
		part_id: 218,
		quantity: 70,
	},
	{
		garage_id: 226,
		part_id: 219,
		quantity: 97,
	},
	{
		garage_id: 226,
		part_id: 220,
		quantity: 227,
	},
	{
		garage_id: 226,
		part_id: 221,
		quantity: 183,
	},
	{
		garage_id: 226,
		part_id: 222,
		quantity: 27,
	},
	{
		garage_id: 226,
		part_id: 223,
		quantity: 450,
	},
	{
		garage_id: 226,
		part_id: 224,
		quantity: 189,
	},
	{
		garage_id: 226,
		part_id: 225,
		quantity: 261,
	},
	{
		garage_id: 226,
		part_id: 226,
		quantity: 190,
	},
	{
		garage_id: 226,
		part_id: 227,
		quantity: 153,
	},
	{
		garage_id: 226,
		part_id: 228,
		quantity: 47,
	},
	{
		garage_id: 226,
		part_id: 229,
		quantity: 43,
	},
	{
		garage_id: 226,
		part_id: 230,
		quantity: 139,
	},
	{
		garage_id: 226,
		part_id: 231,
		quantity: 126,
	},
	{
		garage_id: 226,
		part_id: 232,
		quantity: 63,
	},
	{
		garage_id: 226,
		part_id: 233,
		quantity: 101,
	},
	{
		garage_id: 226,
		part_id: 234,
		quantity: 170,
	},
	{
		garage_id: 226,
		part_id: 235,
		quantity: 157,
	},
	{
		garage_id: 226,
		part_id: 236,
		quantity: 78,
	},
	{
		garage_id: 226,
		part_id: 237,
		quantity: 499,
	},
	{
		garage_id: 226,
		part_id: 238,
		quantity: 239,
	},
	{
		garage_id: 226,
		part_id: 239,
		quantity: 220,
	},
	{
		garage_id: 226,
		part_id: 240,
		quantity: 141,
	},
	{
		garage_id: 226,
		part_id: 241,
		quantity: 166,
	},
	{
		garage_id: 226,
		part_id: 242,
		quantity: 11,
	},
	{
		garage_id: 226,
		part_id: 243,
		quantity: 234,
	},
	{
		garage_id: 226,
		part_id: 244,
		quantity: 387,
	},
	{
		garage_id: 226,
		part_id: 245,
		quantity: 441,
	},
	{
		garage_id: 226,
		part_id: 246,
		quantity: 55,
	},
	{
		garage_id: 226,
		part_id: 247,
		quantity: 148,
	},
	{
		garage_id: 226,
		part_id: 248,
		quantity: 47,
	},
	{
		garage_id: 226,
		part_id: 249,
		quantity: 349,
	},
	{
		garage_id: 226,
		part_id: 250,
		quantity: 139,
	},
	{
		garage_id: 226,
		part_id: 251,
		quantity: 315,
	},
	{
		garage_id: 226,
		part_id: 252,
		quantity: 125,
	},
	{
		garage_id: 226,
		part_id: 253,
		quantity: 431,
	},
	{
		garage_id: 226,
		part_id: 254,
		quantity: 78,
	},
	{
		garage_id: 226,
		part_id: 255,
		quantity: 393,
	},
	{
		garage_id: 226,
		part_id: 256,
		quantity: 384,
	},
	{
		garage_id: 226,
		part_id: 257,
		quantity: 175,
	},
	{
		garage_id: 226,
		part_id: 258,
		quantity: 369,
	},
	{
		garage_id: 226,
		part_id: 259,
		quantity: 480,
	},
	{
		garage_id: 226,
		part_id: 260,
		quantity: 262,
	},
	{
		garage_id: 226,
		part_id: 261,
		quantity: 234,
	},
	{
		garage_id: 226,
		part_id: 262,
		quantity: 95,
	},
	{
		garage_id: 226,
		part_id: 263,
		quantity: 204,
	},
	{
		garage_id: 226,
		part_id: 264,
		quantity: 213,
	},
	{
		garage_id: 226,
		part_id: 265,
		quantity: 469,
	},
	{
		garage_id: 226,
		part_id: 266,
		quantity: 380,
	},
	{
		garage_id: 226,
		part_id: 267,
		quantity: 185,
	},
	{
		garage_id: 226,
		part_id: 268,
		quantity: 469,
	},
	{
		garage_id: 226,
		part_id: 269,
		quantity: 181,
	},
	{
		garage_id: 226,
		part_id: 270,
		quantity: 211,
	},
	{
		garage_id: 226,
		part_id: 271,
		quantity: 304,
	},
	{
		garage_id: 226,
		part_id: 272,
		quantity: 133,
	},
	{
		garage_id: 226,
		part_id: 273,
		quantity: 281,
	},
	{
		garage_id: 226,
		part_id: 274,
		quantity: 0,
	},
	{
		garage_id: 226,
		part_id: 275,
		quantity: 412,
	},
	{
		garage_id: 226,
		part_id: 276,
		quantity: 326,
	},
	{
		garage_id: 226,
		part_id: 277,
		quantity: 367,
	},
	{
		garage_id: 226,
		part_id: 278,
		quantity: 128,
	},
	{
		garage_id: 226,
		part_id: 279,
		quantity: 79,
	},
	{
		garage_id: 226,
		part_id: 280,
		quantity: 130,
	},
	{
		garage_id: 226,
		part_id: 281,
		quantity: 313,
	},
	{
		garage_id: 226,
		part_id: 282,
		quantity: 78,
	},
	{
		garage_id: 226,
		part_id: 283,
		quantity: 299,
	},
	{
		garage_id: 226,
		part_id: 284,
		quantity: 298,
	},
	{
		garage_id: 226,
		part_id: 285,
		quantity: 191,
	},
	{
		garage_id: 226,
		part_id: 286,
		quantity: 68,
	},
	{
		garage_id: 226,
		part_id: 287,
		quantity: 24,
	},
	{
		garage_id: 226,
		part_id: 288,
		quantity: 20,
	},
	{
		garage_id: 226,
		part_id: 289,
		quantity: 407,
	},
	{
		garage_id: 226,
		part_id: 290,
		quantity: 120,
	},
	{
		garage_id: 226,
		part_id: 291,
		quantity: 257,
	},
	{
		garage_id: 226,
		part_id: 292,
		quantity: 32,
	},
	{
		garage_id: 226,
		part_id: 293,
		quantity: 246,
	},
	{
		garage_id: 226,
		part_id: 294,
		quantity: 455,
	},
	{
		garage_id: 226,
		part_id: 295,
		quantity: 435,
	},
	{
		garage_id: 226,
		part_id: 296,
		quantity: 263,
	},
	{
		garage_id: 226,
		part_id: 297,
		quantity: 478,
	},
	{
		garage_id: 226,
		part_id: 298,
		quantity: 463,
	},
	{
		garage_id: 226,
		part_id: 299,
		quantity: 11,
	},
	{
		garage_id: 226,
		part_id: 300,
		quantity: 338,
	},
	{
		garage_id: 226,
		part_id: 301,
		quantity: 179,
	},
	{
		garage_id: 226,
		part_id: 302,
		quantity: 304,
	},
	{
		garage_id: 226,
		part_id: 303,
		quantity: 492,
	},
	{
		garage_id: 226,
		part_id: 304,
		quantity: 472,
	},
	{
		garage_id: 226,
		part_id: 305,
		quantity: 322,
	},
	{
		garage_id: 226,
		part_id: 306,
		quantity: 320,
	},
	{
		garage_id: 226,
		part_id: 307,
		quantity: 492,
	},
	{
		garage_id: 226,
		part_id: 308,
		quantity: 6,
	},
	{
		garage_id: 226,
		part_id: 309,
		quantity: 458,
	},
	{
		garage_id: 226,
		part_id: 310,
		quantity: 429,
	},
	{
		garage_id: 226,
		part_id: 311,
		quantity: 49,
	},
	{
		garage_id: 226,
		part_id: 312,
		quantity: 175,
	},
	{
		garage_id: 226,
		part_id: 313,
		quantity: 278,
	},
	{
		garage_id: 226,
		part_id: 314,
		quantity: 72,
	},
	{
		garage_id: 226,
		part_id: 315,
		quantity: 236,
	},
	{
		garage_id: 226,
		part_id: 316,
		quantity: 257,
	},
	{
		garage_id: 226,
		part_id: 317,
		quantity: 380,
	},
	{
		garage_id: 226,
		part_id: 318,
		quantity: 466,
	},
	{
		garage_id: 226,
		part_id: 319,
		quantity: 172,
	},
	{
		garage_id: 226,
		part_id: 320,
		quantity: 143,
	},
	{
		garage_id: 226,
		part_id: 321,
		quantity: 297,
	},
	{
		garage_id: 226,
		part_id: 322,
		quantity: 315,
	},
	{
		garage_id: 226,
		part_id: 323,
		quantity: 69,
	},
	{
		garage_id: 226,
		part_id: 324,
		quantity: 325,
	},
	{
		garage_id: 226,
		part_id: 325,
		quantity: 208,
	},
	{
		garage_id: 226,
		part_id: 326,
		quantity: 154,
	},
	{
		garage_id: 226,
		part_id: 327,
		quantity: 383,
	},
	{
		garage_id: 226,
		part_id: 328,
		quantity: 161,
	},
	{
		garage_id: 226,
		part_id: 329,
		quantity: 74,
	},
	{
		garage_id: 226,
		part_id: 330,
		quantity: 426,
	},
	{
		garage_id: 226,
		part_id: 331,
		quantity: 370,
	},
	{
		garage_id: 226,
		part_id: 332,
		quantity: 466,
	},
	{
		garage_id: 226,
		part_id: 333,
		quantity: 92,
	},
	{
		garage_id: 226,
		part_id: 334,
		quantity: 172,
	},
	{
		garage_id: 226,
		part_id: 335,
		quantity: 380,
	},
	{
		garage_id: 226,
		part_id: 336,
		quantity: 196,
	},
	{
		garage_id: 226,
		part_id: 337,
		quantity: 337,
	},
	{
		garage_id: 226,
		part_id: 338,
		quantity: 18,
	},
	{
		garage_id: 226,
		part_id: 339,
		quantity: 148,
	},
	{
		garage_id: 226,
		part_id: 340,
		quantity: 71,
	},
	{
		garage_id: 226,
		part_id: 341,
		quantity: 177,
	},
	{
		garage_id: 226,
		part_id: 342,
		quantity: 55,
	},
	{
		garage_id: 226,
		part_id: 343,
		quantity: 10,
	},
	{
		garage_id: 226,
		part_id: 344,
		quantity: 451,
	},
	{
		garage_id: 226,
		part_id: 345,
		quantity: 195,
	},
	{
		garage_id: 226,
		part_id: 346,
		quantity: 51,
	},
	{
		garage_id: 226,
		part_id: 347,
		quantity: 14,
	},
	{
		garage_id: 226,
		part_id: 348,
		quantity: 335,
	},
	{
		garage_id: 226,
		part_id: 349,
		quantity: 144,
	},
	{
		garage_id: 226,
		part_id: 350,
		quantity: 438,
	},
	{
		garage_id: 226,
		part_id: 351,
		quantity: 424,
	},
	{
		garage_id: 226,
		part_id: 352,
		quantity: 60,
	},
	{
		garage_id: 226,
		part_id: 353,
		quantity: 359,
	},
	{
		garage_id: 226,
		part_id: 354,
		quantity: 67,
	},
	{
		garage_id: 226,
		part_id: 355,
		quantity: 181,
	},
	{
		garage_id: 226,
		part_id: 356,
		quantity: 446,
	},
	{
		garage_id: 226,
		part_id: 357,
		quantity: 20,
	},
	{
		garage_id: 226,
		part_id: 358,
		quantity: 268,
	},
	{
		garage_id: 226,
		part_id: 359,
		quantity: 161,
	},
	{
		garage_id: 226,
		part_id: 360,
		quantity: 391,
	},
	{
		garage_id: 226,
		part_id: 361,
		quantity: 449,
	},
	{
		garage_id: 226,
		part_id: 362,
		quantity: 205,
	},
	{
		garage_id: 226,
		part_id: 363,
		quantity: 14,
	},
	{
		garage_id: 226,
		part_id: 364,
		quantity: 113,
	},
	{
		garage_id: 226,
		part_id: 365,
		quantity: 281,
	},
	{
		garage_id: 226,
		part_id: 366,
		quantity: 321,
	},
	{
		garage_id: 226,
		part_id: 367,
		quantity: 493,
	},
	{
		garage_id: 226,
		part_id: 368,
		quantity: 106,
	},
	{
		garage_id: 226,
		part_id: 369,
		quantity: 387,
	},
	{
		garage_id: 226,
		part_id: 370,
		quantity: 278,
	},
	{
		garage_id: 226,
		part_id: 371,
		quantity: 461,
	},
	{
		garage_id: 226,
		part_id: 372,
		quantity: 151,
	},
	{
		garage_id: 226,
		part_id: 373,
		quantity: 437,
	},
	{
		garage_id: 226,
		part_id: 374,
		quantity: 234,
	},
	{
		garage_id: 226,
		part_id: 375,
		quantity: 169,
	},
	{
		garage_id: 226,
		part_id: 376,
		quantity: 313,
	},
	{
		garage_id: 226,
		part_id: 377,
		quantity: 155,
	},
	{
		garage_id: 226,
		part_id: 378,
		quantity: 103,
	},
	{
		garage_id: 226,
		part_id: 379,
		quantity: 35,
	},
	{
		garage_id: 226,
		part_id: 380,
		quantity: 16,
	},
	{
		garage_id: 226,
		part_id: 381,
		quantity: 391,
	},
	{
		garage_id: 226,
		part_id: 382,
		quantity: 17,
	},
	{
		garage_id: 226,
		part_id: 383,
		quantity: 99,
	},
	{
		garage_id: 226,
		part_id: 384,
		quantity: 8,
	},
	{
		garage_id: 226,
		part_id: 385,
		quantity: 89,
	},
	{
		garage_id: 226,
		part_id: 386,
		quantity: 455,
	},
	{
		garage_id: 226,
		part_id: 387,
		quantity: 191,
	},
	{
		garage_id: 226,
		part_id: 388,
		quantity: 161,
	},
	{
		garage_id: 226,
		part_id: 389,
		quantity: 470,
	},
	{
		garage_id: 226,
		part_id: 390,
		quantity: 28,
	},
	{
		garage_id: 226,
		part_id: 391,
		quantity: 72,
	},
	{
		garage_id: 226,
		part_id: 392,
		quantity: 408,
	},
	{
		garage_id: 226,
		part_id: 393,
		quantity: 463,
	},
	{
		garage_id: 226,
		part_id: 394,
		quantity: 437,
	},
	{
		garage_id: 226,
		part_id: 395,
		quantity: 82,
	},
	{
		garage_id: 226,
		part_id: 396,
		quantity: 421,
	},
	{
		garage_id: 226,
		part_id: 397,
		quantity: 282,
	},
	{
		garage_id: 226,
		part_id: 398,
		quantity: 379,
	},
	{
		garage_id: 226,
		part_id: 399,
		quantity: 324,
	},
	{
		garage_id: 227,
		part_id: 0,
		quantity: 223,
	},
	{
		garage_id: 227,
		part_id: 1,
		quantity: 354,
	},
	{
		garage_id: 227,
		part_id: 2,
		quantity: 285,
	},
	{
		garage_id: 227,
		part_id: 3,
		quantity: 134,
	},
	{
		garage_id: 227,
		part_id: 4,
		quantity: 355,
	},
	{
		garage_id: 227,
		part_id: 5,
		quantity: 147,
	},
	{
		garage_id: 227,
		part_id: 6,
		quantity: 232,
	},
	{
		garage_id: 227,
		part_id: 7,
		quantity: 0,
	},
	{
		garage_id: 227,
		part_id: 8,
		quantity: 160,
	},
	{
		garage_id: 227,
		part_id: 9,
		quantity: 285,
	},
	{
		garage_id: 227,
		part_id: 10,
		quantity: 20,
	},
	{
		garage_id: 227,
		part_id: 11,
		quantity: 100,
	},
	{
		garage_id: 227,
		part_id: 12,
		quantity: 381,
	},
	{
		garage_id: 227,
		part_id: 13,
		quantity: 161,
	},
	{
		garage_id: 227,
		part_id: 14,
		quantity: 279,
	},
	{
		garage_id: 227,
		part_id: 15,
		quantity: 253,
	},
	{
		garage_id: 227,
		part_id: 16,
		quantity: 28,
	},
	{
		garage_id: 227,
		part_id: 17,
		quantity: 449,
	},
	{
		garage_id: 227,
		part_id: 18,
		quantity: 241,
	},
	{
		garage_id: 227,
		part_id: 19,
		quantity: 468,
	},
	{
		garage_id: 227,
		part_id: 20,
		quantity: 371,
	},
	{
		garage_id: 227,
		part_id: 21,
		quantity: 368,
	},
	{
		garage_id: 227,
		part_id: 22,
		quantity: 88,
	},
	{
		garage_id: 227,
		part_id: 23,
		quantity: 312,
	},
	{
		garage_id: 227,
		part_id: 24,
		quantity: 18,
	},
	{
		garage_id: 227,
		part_id: 25,
		quantity: 31,
	},
	{
		garage_id: 227,
		part_id: 26,
		quantity: 419,
	},
	{
		garage_id: 227,
		part_id: 27,
		quantity: 283,
	},
	{
		garage_id: 227,
		part_id: 28,
		quantity: 347,
	},
	{
		garage_id: 227,
		part_id: 29,
		quantity: 230,
	},
	{
		garage_id: 227,
		part_id: 30,
		quantity: 299,
	},
	{
		garage_id: 227,
		part_id: 31,
		quantity: 272,
	},
	{
		garage_id: 227,
		part_id: 32,
		quantity: 345,
	},
	{
		garage_id: 227,
		part_id: 33,
		quantity: 189,
	},
	{
		garage_id: 227,
		part_id: 34,
		quantity: 266,
	},
	{
		garage_id: 227,
		part_id: 35,
		quantity: 400,
	},
	{
		garage_id: 227,
		part_id: 36,
		quantity: 321,
	},
	{
		garage_id: 227,
		part_id: 37,
		quantity: 96,
	},
	{
		garage_id: 227,
		part_id: 38,
		quantity: 184,
	},
	{
		garage_id: 227,
		part_id: 39,
		quantity: 3,
	},
	{
		garage_id: 227,
		part_id: 40,
		quantity: 461,
	},
	{
		garage_id: 227,
		part_id: 41,
		quantity: 188,
	},
	{
		garage_id: 227,
		part_id: 42,
		quantity: 49,
	},
	{
		garage_id: 227,
		part_id: 43,
		quantity: 304,
	},
	{
		garage_id: 227,
		part_id: 44,
		quantity: 42,
	},
	{
		garage_id: 227,
		part_id: 45,
		quantity: 187,
	},
	{
		garage_id: 227,
		part_id: 46,
		quantity: 494,
	},
	{
		garage_id: 227,
		part_id: 47,
		quantity: 6,
	},
	{
		garage_id: 227,
		part_id: 48,
		quantity: 135,
	},
	{
		garage_id: 227,
		part_id: 49,
		quantity: 313,
	},
	{
		garage_id: 227,
		part_id: 50,
		quantity: 406,
	},
	{
		garage_id: 227,
		part_id: 51,
		quantity: 162,
	},
	{
		garage_id: 227,
		part_id: 52,
		quantity: 317,
	},
	{
		garage_id: 227,
		part_id: 53,
		quantity: 62,
	},
	{
		garage_id: 227,
		part_id: 54,
		quantity: 99,
	},
	{
		garage_id: 227,
		part_id: 55,
		quantity: 29,
	},
	{
		garage_id: 227,
		part_id: 56,
		quantity: 290,
	},
	{
		garage_id: 227,
		part_id: 57,
		quantity: 334,
	},
	{
		garage_id: 227,
		part_id: 58,
		quantity: 210,
	},
	{
		garage_id: 227,
		part_id: 59,
		quantity: 79,
	},
	{
		garage_id: 227,
		part_id: 60,
		quantity: 427,
	},
	{
		garage_id: 227,
		part_id: 61,
		quantity: 354,
	},
	{
		garage_id: 227,
		part_id: 62,
		quantity: 308,
	},
	{
		garage_id: 227,
		part_id: 63,
		quantity: 77,
	},
	{
		garage_id: 227,
		part_id: 64,
		quantity: 107,
	},
	{
		garage_id: 227,
		part_id: 65,
		quantity: 155,
	},
	{
		garage_id: 227,
		part_id: 66,
		quantity: 490,
	},
	{
		garage_id: 227,
		part_id: 67,
		quantity: 461,
	},
	{
		garage_id: 227,
		part_id: 68,
		quantity: 450,
	},
	{
		garage_id: 227,
		part_id: 69,
		quantity: 317,
	},
	{
		garage_id: 227,
		part_id: 70,
		quantity: 248,
	},
	{
		garage_id: 227,
		part_id: 71,
		quantity: 403,
	},
	{
		garage_id: 227,
		part_id: 72,
		quantity: 353,
	},
	{
		garage_id: 227,
		part_id: 73,
		quantity: 477,
	},
	{
		garage_id: 227,
		part_id: 74,
		quantity: 391,
	},
	{
		garage_id: 227,
		part_id: 75,
		quantity: 156,
	},
	{
		garage_id: 227,
		part_id: 76,
		quantity: 295,
	},
	{
		garage_id: 227,
		part_id: 77,
		quantity: 44,
	},
	{
		garage_id: 227,
		part_id: 78,
		quantity: 119,
	},
	{
		garage_id: 227,
		part_id: 79,
		quantity: 185,
	},
	{
		garage_id: 227,
		part_id: 80,
		quantity: 253,
	},
	{
		garage_id: 227,
		part_id: 81,
		quantity: 66,
	},
	{
		garage_id: 227,
		part_id: 82,
		quantity: 306,
	},
	{
		garage_id: 227,
		part_id: 83,
		quantity: 138,
	},
	{
		garage_id: 227,
		part_id: 84,
		quantity: 296,
	},
	{
		garage_id: 227,
		part_id: 85,
		quantity: 203,
	},
	{
		garage_id: 227,
		part_id: 86,
		quantity: 79,
	},
	{
		garage_id: 227,
		part_id: 87,
		quantity: 337,
	},
	{
		garage_id: 227,
		part_id: 88,
		quantity: 193,
	},
	{
		garage_id: 227,
		part_id: 89,
		quantity: 13,
	},
	{
		garage_id: 227,
		part_id: 90,
		quantity: 373,
	},
	{
		garage_id: 227,
		part_id: 91,
		quantity: 488,
	},
	{
		garage_id: 227,
		part_id: 92,
		quantity: 27,
	},
	{
		garage_id: 227,
		part_id: 93,
		quantity: 35,
	},
	{
		garage_id: 227,
		part_id: 94,
		quantity: 417,
	},
	{
		garage_id: 227,
		part_id: 95,
		quantity: 2,
	},
	{
		garage_id: 227,
		part_id: 96,
		quantity: 405,
	},
	{
		garage_id: 227,
		part_id: 97,
		quantity: 169,
	},
	{
		garage_id: 227,
		part_id: 98,
		quantity: 432,
	},
	{
		garage_id: 227,
		part_id: 99,
		quantity: 243,
	},
	{
		garage_id: 227,
		part_id: 100,
		quantity: 234,
	},
	{
		garage_id: 227,
		part_id: 101,
		quantity: 109,
	},
	{
		garage_id: 227,
		part_id: 102,
		quantity: 398,
	},
	{
		garage_id: 227,
		part_id: 103,
		quantity: 88,
	},
	{
		garage_id: 227,
		part_id: 104,
		quantity: 226,
	},
	{
		garage_id: 227,
		part_id: 105,
		quantity: 366,
	},
	{
		garage_id: 227,
		part_id: 106,
		quantity: 196,
	},
	{
		garage_id: 227,
		part_id: 107,
		quantity: 451,
	},
	{
		garage_id: 227,
		part_id: 108,
		quantity: 150,
	},
	{
		garage_id: 227,
		part_id: 109,
		quantity: 464,
	},
	{
		garage_id: 227,
		part_id: 110,
		quantity: 77,
	},
	{
		garage_id: 227,
		part_id: 111,
		quantity: 475,
	},
	{
		garage_id: 227,
		part_id: 112,
		quantity: 426,
	},
	{
		garage_id: 227,
		part_id: 113,
		quantity: 333,
	},
	{
		garage_id: 227,
		part_id: 114,
		quantity: 184,
	},
	{
		garage_id: 227,
		part_id: 115,
		quantity: 328,
	},
	{
		garage_id: 227,
		part_id: 116,
		quantity: 215,
	},
	{
		garage_id: 227,
		part_id: 117,
		quantity: 203,
	},
	{
		garage_id: 227,
		part_id: 118,
		quantity: 464,
	},
	{
		garage_id: 227,
		part_id: 119,
		quantity: 89,
	},
	{
		garage_id: 227,
		part_id: 120,
		quantity: 409,
	},
	{
		garage_id: 227,
		part_id: 121,
		quantity: 150,
	},
	{
		garage_id: 227,
		part_id: 122,
		quantity: 364,
	},
	{
		garage_id: 227,
		part_id: 123,
		quantity: 428,
	},
	{
		garage_id: 227,
		part_id: 124,
		quantity: 71,
	},
	{
		garage_id: 227,
		part_id: 125,
		quantity: 42,
	},
	{
		garage_id: 227,
		part_id: 126,
		quantity: 206,
	},
	{
		garage_id: 227,
		part_id: 127,
		quantity: 80,
	},
	{
		garage_id: 227,
		part_id: 128,
		quantity: 221,
	},
	{
		garage_id: 227,
		part_id: 129,
		quantity: 92,
	},
	{
		garage_id: 227,
		part_id: 130,
		quantity: 265,
	},
	{
		garage_id: 227,
		part_id: 131,
		quantity: 373,
	},
	{
		garage_id: 227,
		part_id: 132,
		quantity: 357,
	},
	{
		garage_id: 227,
		part_id: 133,
		quantity: 26,
	},
	{
		garage_id: 227,
		part_id: 134,
		quantity: 299,
	},
	{
		garage_id: 227,
		part_id: 135,
		quantity: 500,
	},
	{
		garage_id: 227,
		part_id: 136,
		quantity: 250,
	},
	{
		garage_id: 227,
		part_id: 137,
		quantity: 284,
	},
	{
		garage_id: 227,
		part_id: 138,
		quantity: 314,
	},
	{
		garage_id: 227,
		part_id: 139,
		quantity: 230,
	},
	{
		garage_id: 227,
		part_id: 140,
		quantity: 296,
	},
	{
		garage_id: 227,
		part_id: 141,
		quantity: 468,
	},
	{
		garage_id: 227,
		part_id: 142,
		quantity: 444,
	},
	{
		garage_id: 227,
		part_id: 143,
		quantity: 499,
	},
	{
		garage_id: 227,
		part_id: 144,
		quantity: 197,
	},
	{
		garage_id: 227,
		part_id: 145,
		quantity: 442,
	},
	{
		garage_id: 227,
		part_id: 146,
		quantity: 110,
	},
	{
		garage_id: 227,
		part_id: 147,
		quantity: 495,
	},
	{
		garage_id: 227,
		part_id: 148,
		quantity: 280,
	},
	{
		garage_id: 227,
		part_id: 149,
		quantity: 43,
	},
	{
		garage_id: 227,
		part_id: 150,
		quantity: 308,
	},
	{
		garage_id: 227,
		part_id: 151,
		quantity: 376,
	},
	{
		garage_id: 227,
		part_id: 152,
		quantity: 155,
	},
	{
		garage_id: 227,
		part_id: 153,
		quantity: 366,
	},
	{
		garage_id: 227,
		part_id: 154,
		quantity: 459,
	},
	{
		garage_id: 227,
		part_id: 155,
		quantity: 278,
	},
	{
		garage_id: 227,
		part_id: 156,
		quantity: 437,
	},
	{
		garage_id: 227,
		part_id: 157,
		quantity: 211,
	},
	{
		garage_id: 227,
		part_id: 158,
		quantity: 4,
	},
	{
		garage_id: 227,
		part_id: 159,
		quantity: 471,
	},
	{
		garage_id: 227,
		part_id: 160,
		quantity: 192,
	},
	{
		garage_id: 227,
		part_id: 161,
		quantity: 163,
	},
	{
		garage_id: 227,
		part_id: 162,
		quantity: 36,
	},
	{
		garage_id: 227,
		part_id: 163,
		quantity: 85,
	},
	{
		garage_id: 227,
		part_id: 164,
		quantity: 146,
	},
	{
		garage_id: 227,
		part_id: 165,
		quantity: 129,
	},
	{
		garage_id: 227,
		part_id: 166,
		quantity: 494,
	},
	{
		garage_id: 227,
		part_id: 167,
		quantity: 233,
	},
	{
		garage_id: 227,
		part_id: 168,
		quantity: 169,
	},
	{
		garage_id: 227,
		part_id: 169,
		quantity: 169,
	},
	{
		garage_id: 227,
		part_id: 170,
		quantity: 434,
	},
	{
		garage_id: 227,
		part_id: 171,
		quantity: 341,
	},
	{
		garage_id: 227,
		part_id: 172,
		quantity: 400,
	},
	{
		garage_id: 227,
		part_id: 173,
		quantity: 292,
	},
	{
		garage_id: 227,
		part_id: 174,
		quantity: 299,
	},
	{
		garage_id: 227,
		part_id: 175,
		quantity: 380,
	},
	{
		garage_id: 227,
		part_id: 176,
		quantity: 454,
	},
	{
		garage_id: 227,
		part_id: 177,
		quantity: 281,
	},
	{
		garage_id: 227,
		part_id: 178,
		quantity: 335,
	},
	{
		garage_id: 227,
		part_id: 179,
		quantity: 341,
	},
	{
		garage_id: 227,
		part_id: 180,
		quantity: 422,
	},
	{
		garage_id: 227,
		part_id: 181,
		quantity: 180,
	},
	{
		garage_id: 227,
		part_id: 182,
		quantity: 467,
	},
	{
		garage_id: 227,
		part_id: 183,
		quantity: 72,
	},
	{
		garage_id: 227,
		part_id: 184,
		quantity: 7,
	},
	{
		garage_id: 227,
		part_id: 185,
		quantity: 6,
	},
	{
		garage_id: 227,
		part_id: 186,
		quantity: 265,
	},
	{
		garage_id: 227,
		part_id: 187,
		quantity: 173,
	},
	{
		garage_id: 227,
		part_id: 188,
		quantity: 293,
	},
	{
		garage_id: 227,
		part_id: 189,
		quantity: 55,
	},
	{
		garage_id: 227,
		part_id: 190,
		quantity: 77,
	},
	{
		garage_id: 227,
		part_id: 191,
		quantity: 421,
	},
	{
		garage_id: 227,
		part_id: 192,
		quantity: 347,
	},
	{
		garage_id: 227,
		part_id: 193,
		quantity: 22,
	},
	{
		garage_id: 227,
		part_id: 194,
		quantity: 290,
	},
	{
		garage_id: 227,
		part_id: 195,
		quantity: 100,
	},
	{
		garage_id: 227,
		part_id: 196,
		quantity: 289,
	},
	{
		garage_id: 227,
		part_id: 197,
		quantity: 183,
	},
	{
		garage_id: 227,
		part_id: 198,
		quantity: 51,
	},
	{
		garage_id: 227,
		part_id: 199,
		quantity: 171,
	},
	{
		garage_id: 227,
		part_id: 200,
		quantity: 340,
	},
	{
		garage_id: 227,
		part_id: 201,
		quantity: 314,
	},
	{
		garage_id: 227,
		part_id: 202,
		quantity: 75,
	},
	{
		garage_id: 227,
		part_id: 203,
		quantity: 363,
	},
	{
		garage_id: 227,
		part_id: 204,
		quantity: 376,
	},
	{
		garage_id: 227,
		part_id: 205,
		quantity: 382,
	},
	{
		garage_id: 227,
		part_id: 206,
		quantity: 239,
	},
	{
		garage_id: 227,
		part_id: 207,
		quantity: 326,
	},
	{
		garage_id: 227,
		part_id: 208,
		quantity: 337,
	},
	{
		garage_id: 227,
		part_id: 209,
		quantity: 476,
	},
	{
		garage_id: 227,
		part_id: 210,
		quantity: 141,
	},
	{
		garage_id: 227,
		part_id: 211,
		quantity: 191,
	},
	{
		garage_id: 227,
		part_id: 212,
		quantity: 9,
	},
	{
		garage_id: 227,
		part_id: 213,
		quantity: 450,
	},
	{
		garage_id: 227,
		part_id: 214,
		quantity: 364,
	},
	{
		garage_id: 227,
		part_id: 215,
		quantity: 344,
	},
	{
		garage_id: 227,
		part_id: 216,
		quantity: 353,
	},
	{
		garage_id: 227,
		part_id: 217,
		quantity: 143,
	},
	{
		garage_id: 227,
		part_id: 218,
		quantity: 32,
	},
	{
		garage_id: 227,
		part_id: 219,
		quantity: 200,
	},
	{
		garage_id: 227,
		part_id: 220,
		quantity: 499,
	},
	{
		garage_id: 227,
		part_id: 221,
		quantity: 240,
	},
	{
		garage_id: 227,
		part_id: 222,
		quantity: 118,
	},
	{
		garage_id: 227,
		part_id: 223,
		quantity: 423,
	},
	{
		garage_id: 227,
		part_id: 224,
		quantity: 332,
	},
	{
		garage_id: 227,
		part_id: 225,
		quantity: 362,
	},
	{
		garage_id: 227,
		part_id: 226,
		quantity: 450,
	},
	{
		garage_id: 227,
		part_id: 227,
		quantity: 32,
	},
	{
		garage_id: 227,
		part_id: 228,
		quantity: 101,
	},
	{
		garage_id: 227,
		part_id: 229,
		quantity: 89,
	},
	{
		garage_id: 227,
		part_id: 230,
		quantity: 312,
	},
	{
		garage_id: 227,
		part_id: 231,
		quantity: 353,
	},
	{
		garage_id: 227,
		part_id: 232,
		quantity: 47,
	},
	{
		garage_id: 227,
		part_id: 233,
		quantity: 34,
	},
	{
		garage_id: 227,
		part_id: 234,
		quantity: 123,
	},
	{
		garage_id: 227,
		part_id: 235,
		quantity: 368,
	},
	{
		garage_id: 227,
		part_id: 236,
		quantity: 391,
	},
	{
		garage_id: 227,
		part_id: 237,
		quantity: 427,
	},
	{
		garage_id: 227,
		part_id: 238,
		quantity: 402,
	},
	{
		garage_id: 227,
		part_id: 239,
		quantity: 362,
	},
	{
		garage_id: 227,
		part_id: 240,
		quantity: 166,
	},
	{
		garage_id: 227,
		part_id: 241,
		quantity: 297,
	},
	{
		garage_id: 227,
		part_id: 242,
		quantity: 23,
	},
	{
		garage_id: 227,
		part_id: 243,
		quantity: 421,
	},
	{
		garage_id: 227,
		part_id: 244,
		quantity: 388,
	},
	{
		garage_id: 227,
		part_id: 245,
		quantity: 451,
	},
	{
		garage_id: 227,
		part_id: 246,
		quantity: 401,
	},
	{
		garage_id: 227,
		part_id: 247,
		quantity: 233,
	},
	{
		garage_id: 227,
		part_id: 248,
		quantity: 346,
	},
	{
		garage_id: 227,
		part_id: 249,
		quantity: 461,
	},
	{
		garage_id: 227,
		part_id: 250,
		quantity: 5,
	},
	{
		garage_id: 227,
		part_id: 251,
		quantity: 332,
	},
	{
		garage_id: 227,
		part_id: 252,
		quantity: 42,
	},
	{
		garage_id: 227,
		part_id: 253,
		quantity: 267,
	},
	{
		garage_id: 227,
		part_id: 254,
		quantity: 389,
	},
	{
		garage_id: 227,
		part_id: 255,
		quantity: 208,
	},
	{
		garage_id: 227,
		part_id: 256,
		quantity: 372,
	},
	{
		garage_id: 227,
		part_id: 257,
		quantity: 27,
	},
	{
		garage_id: 227,
		part_id: 258,
		quantity: 266,
	},
	{
		garage_id: 227,
		part_id: 259,
		quantity: 483,
	},
	{
		garage_id: 227,
		part_id: 260,
		quantity: 115,
	},
	{
		garage_id: 227,
		part_id: 261,
		quantity: 63,
	},
	{
		garage_id: 227,
		part_id: 262,
		quantity: 263,
	},
	{
		garage_id: 227,
		part_id: 263,
		quantity: 416,
	},
	{
		garage_id: 227,
		part_id: 264,
		quantity: 124,
	},
	{
		garage_id: 227,
		part_id: 265,
		quantity: 276,
	},
	{
		garage_id: 227,
		part_id: 266,
		quantity: 218,
	},
	{
		garage_id: 227,
		part_id: 267,
		quantity: 486,
	},
	{
		garage_id: 227,
		part_id: 268,
		quantity: 269,
	},
	{
		garage_id: 227,
		part_id: 269,
		quantity: 434,
	},
	{
		garage_id: 227,
		part_id: 270,
		quantity: 32,
	},
	{
		garage_id: 227,
		part_id: 271,
		quantity: 92,
	},
	{
		garage_id: 227,
		part_id: 272,
		quantity: 137,
	},
	{
		garage_id: 227,
		part_id: 273,
		quantity: 444,
	},
	{
		garage_id: 227,
		part_id: 274,
		quantity: 306,
	},
	{
		garage_id: 227,
		part_id: 275,
		quantity: 289,
	},
	{
		garage_id: 227,
		part_id: 276,
		quantity: 260,
	},
	{
		garage_id: 227,
		part_id: 277,
		quantity: 100,
	},
	{
		garage_id: 227,
		part_id: 278,
		quantity: 427,
	},
	{
		garage_id: 227,
		part_id: 279,
		quantity: 360,
	},
	{
		garage_id: 227,
		part_id: 280,
		quantity: 77,
	},
	{
		garage_id: 227,
		part_id: 281,
		quantity: 216,
	},
	{
		garage_id: 227,
		part_id: 282,
		quantity: 457,
	},
	{
		garage_id: 227,
		part_id: 283,
		quantity: 71,
	},
	{
		garage_id: 227,
		part_id: 284,
		quantity: 381,
	},
	{
		garage_id: 227,
		part_id: 285,
		quantity: 78,
	},
	{
		garage_id: 227,
		part_id: 286,
		quantity: 416,
	},
	{
		garage_id: 227,
		part_id: 287,
		quantity: 237,
	},
	{
		garage_id: 227,
		part_id: 288,
		quantity: 71,
	},
	{
		garage_id: 227,
		part_id: 289,
		quantity: 218,
	},
	{
		garage_id: 227,
		part_id: 290,
		quantity: 378,
	},
	{
		garage_id: 227,
		part_id: 291,
		quantity: 401,
	},
	{
		garage_id: 227,
		part_id: 292,
		quantity: 267,
	},
	{
		garage_id: 227,
		part_id: 293,
		quantity: 62,
	},
	{
		garage_id: 227,
		part_id: 294,
		quantity: 189,
	},
	{
		garage_id: 227,
		part_id: 295,
		quantity: 292,
	},
	{
		garage_id: 227,
		part_id: 296,
		quantity: 439,
	},
	{
		garage_id: 227,
		part_id: 297,
		quantity: 303,
	},
	{
		garage_id: 227,
		part_id: 298,
		quantity: 406,
	},
	{
		garage_id: 227,
		part_id: 299,
		quantity: 316,
	},
	{
		garage_id: 227,
		part_id: 300,
		quantity: 362,
	},
	{
		garage_id: 227,
		part_id: 301,
		quantity: 178,
	},
	{
		garage_id: 227,
		part_id: 302,
		quantity: 218,
	},
	{
		garage_id: 227,
		part_id: 303,
		quantity: 227,
	},
	{
		garage_id: 227,
		part_id: 304,
		quantity: 19,
	},
	{
		garage_id: 227,
		part_id: 305,
		quantity: 75,
	},
	{
		garage_id: 227,
		part_id: 306,
		quantity: 341,
	},
	{
		garage_id: 227,
		part_id: 307,
		quantity: 139,
	},
	{
		garage_id: 227,
		part_id: 308,
		quantity: 275,
	},
	{
		garage_id: 227,
		part_id: 309,
		quantity: 67,
	},
	{
		garage_id: 227,
		part_id: 310,
		quantity: 338,
	},
	{
		garage_id: 227,
		part_id: 311,
		quantity: 454,
	},
	{
		garage_id: 227,
		part_id: 312,
		quantity: 115,
	},
	{
		garage_id: 227,
		part_id: 313,
		quantity: 142,
	},
	{
		garage_id: 227,
		part_id: 314,
		quantity: 194,
	},
	{
		garage_id: 227,
		part_id: 315,
		quantity: 40,
	},
	{
		garage_id: 227,
		part_id: 316,
		quantity: 310,
	},
	{
		garage_id: 227,
		part_id: 317,
		quantity: 151,
	},
	{
		garage_id: 227,
		part_id: 318,
		quantity: 465,
	},
	{
		garage_id: 227,
		part_id: 319,
		quantity: 6,
	},
	{
		garage_id: 227,
		part_id: 320,
		quantity: 33,
	},
	{
		garage_id: 227,
		part_id: 321,
		quantity: 292,
	},
	{
		garage_id: 227,
		part_id: 322,
		quantity: 409,
	},
	{
		garage_id: 227,
		part_id: 323,
		quantity: 293,
	},
	{
		garage_id: 227,
		part_id: 324,
		quantity: 438,
	},
	{
		garage_id: 227,
		part_id: 325,
		quantity: 208,
	},
	{
		garage_id: 227,
		part_id: 326,
		quantity: 125,
	},
	{
		garage_id: 227,
		part_id: 327,
		quantity: 23,
	},
	{
		garage_id: 227,
		part_id: 328,
		quantity: 473,
	},
	{
		garage_id: 227,
		part_id: 329,
		quantity: 468,
	},
	{
		garage_id: 227,
		part_id: 330,
		quantity: 411,
	},
	{
		garage_id: 227,
		part_id: 331,
		quantity: 66,
	},
	{
		garage_id: 227,
		part_id: 332,
		quantity: 270,
	},
	{
		garage_id: 227,
		part_id: 333,
		quantity: 257,
	},
	{
		garage_id: 227,
		part_id: 334,
		quantity: 413,
	},
	{
		garage_id: 227,
		part_id: 335,
		quantity: 32,
	},
	{
		garage_id: 227,
		part_id: 336,
		quantity: 387,
	},
	{
		garage_id: 227,
		part_id: 337,
		quantity: 173,
	},
	{
		garage_id: 227,
		part_id: 338,
		quantity: 284,
	},
	{
		garage_id: 227,
		part_id: 339,
		quantity: 486,
	},
	{
		garage_id: 227,
		part_id: 340,
		quantity: 106,
	},
	{
		garage_id: 227,
		part_id: 341,
		quantity: 428,
	},
	{
		garage_id: 227,
		part_id: 342,
		quantity: 384,
	},
	{
		garage_id: 227,
		part_id: 343,
		quantity: 147,
	},
	{
		garage_id: 227,
		part_id: 344,
		quantity: 220,
	},
	{
		garage_id: 227,
		part_id: 345,
		quantity: 174,
	},
	{
		garage_id: 227,
		part_id: 346,
		quantity: 495,
	},
	{
		garage_id: 227,
		part_id: 347,
		quantity: 242,
	},
	{
		garage_id: 227,
		part_id: 348,
		quantity: 196,
	},
	{
		garage_id: 227,
		part_id: 349,
		quantity: 265,
	},
	{
		garage_id: 227,
		part_id: 350,
		quantity: 124,
	},
	{
		garage_id: 227,
		part_id: 351,
		quantity: 394,
	},
	{
		garage_id: 227,
		part_id: 352,
		quantity: 8,
	},
	{
		garage_id: 227,
		part_id: 353,
		quantity: 312,
	},
	{
		garage_id: 227,
		part_id: 354,
		quantity: 139,
	},
	{
		garage_id: 227,
		part_id: 355,
		quantity: 321,
	},
	{
		garage_id: 227,
		part_id: 356,
		quantity: 424,
	},
	{
		garage_id: 227,
		part_id: 357,
		quantity: 457,
	},
	{
		garage_id: 227,
		part_id: 358,
		quantity: 403,
	},
	{
		garage_id: 227,
		part_id: 359,
		quantity: 332,
	},
	{
		garage_id: 227,
		part_id: 360,
		quantity: 428,
	},
	{
		garage_id: 227,
		part_id: 361,
		quantity: 340,
	},
	{
		garage_id: 227,
		part_id: 362,
		quantity: 237,
	},
	{
		garage_id: 227,
		part_id: 363,
		quantity: 332,
	},
	{
		garage_id: 227,
		part_id: 364,
		quantity: 242,
	},
	{
		garage_id: 227,
		part_id: 365,
		quantity: 251,
	},
	{
		garage_id: 227,
		part_id: 366,
		quantity: 161,
	},
	{
		garage_id: 227,
		part_id: 367,
		quantity: 61,
	},
	{
		garage_id: 227,
		part_id: 368,
		quantity: 185,
	},
	{
		garage_id: 227,
		part_id: 369,
		quantity: 0,
	},
	{
		garage_id: 227,
		part_id: 370,
		quantity: 261,
	},
	{
		garage_id: 227,
		part_id: 371,
		quantity: 334,
	},
	{
		garage_id: 227,
		part_id: 372,
		quantity: 352,
	},
	{
		garage_id: 227,
		part_id: 373,
		quantity: 483,
	},
	{
		garage_id: 227,
		part_id: 374,
		quantity: 142,
	},
	{
		garage_id: 227,
		part_id: 375,
		quantity: 374,
	},
	{
		garage_id: 227,
		part_id: 376,
		quantity: 264,
	},
	{
		garage_id: 227,
		part_id: 377,
		quantity: 183,
	},
	{
		garage_id: 227,
		part_id: 378,
		quantity: 180,
	},
	{
		garage_id: 227,
		part_id: 379,
		quantity: 75,
	},
	{
		garage_id: 227,
		part_id: 380,
		quantity: 423,
	},
	{
		garage_id: 227,
		part_id: 381,
		quantity: 88,
	},
	{
		garage_id: 227,
		part_id: 382,
		quantity: 330,
	},
	{
		garage_id: 227,
		part_id: 383,
		quantity: 108,
	},
	{
		garage_id: 227,
		part_id: 384,
		quantity: 221,
	},
	{
		garage_id: 227,
		part_id: 385,
		quantity: 61,
	},
	{
		garage_id: 227,
		part_id: 386,
		quantity: 189,
	},
	{
		garage_id: 227,
		part_id: 387,
		quantity: 58,
	},
	{
		garage_id: 227,
		part_id: 388,
		quantity: 486,
	},
	{
		garage_id: 227,
		part_id: 389,
		quantity: 395,
	},
	{
		garage_id: 227,
		part_id: 390,
		quantity: 368,
	},
	{
		garage_id: 227,
		part_id: 391,
		quantity: 495,
	},
	{
		garage_id: 227,
		part_id: 392,
		quantity: 148,
	},
	{
		garage_id: 227,
		part_id: 393,
		quantity: 24,
	},
	{
		garage_id: 227,
		part_id: 394,
		quantity: 292,
	},
	{
		garage_id: 227,
		part_id: 395,
		quantity: 250,
	},
	{
		garage_id: 227,
		part_id: 396,
		quantity: 303,
	},
	{
		garage_id: 227,
		part_id: 397,
		quantity: 404,
	},
	{
		garage_id: 227,
		part_id: 398,
		quantity: 93,
	},
	{
		garage_id: 227,
		part_id: 399,
		quantity: 275,
	},
	{
		garage_id: 228,
		part_id: 0,
		quantity: 355,
	},
	{
		garage_id: 228,
		part_id: 1,
		quantity: 113,
	},
	{
		garage_id: 228,
		part_id: 2,
		quantity: 463,
	},
	{
		garage_id: 228,
		part_id: 3,
		quantity: 416,
	},
	{
		garage_id: 228,
		part_id: 4,
		quantity: 316,
	},
	{
		garage_id: 228,
		part_id: 5,
		quantity: 406,
	},
	{
		garage_id: 228,
		part_id: 6,
		quantity: 119,
	},
	{
		garage_id: 228,
		part_id: 7,
		quantity: 349,
	},
	{
		garage_id: 228,
		part_id: 8,
		quantity: 360,
	},
	{
		garage_id: 228,
		part_id: 9,
		quantity: 253,
	},
	{
		garage_id: 228,
		part_id: 10,
		quantity: 330,
	},
	{
		garage_id: 228,
		part_id: 11,
		quantity: 114,
	},
	{
		garage_id: 228,
		part_id: 12,
		quantity: 450,
	},
	{
		garage_id: 228,
		part_id: 13,
		quantity: 406,
	},
	{
		garage_id: 228,
		part_id: 14,
		quantity: 112,
	},
	{
		garage_id: 228,
		part_id: 15,
		quantity: 146,
	},
	{
		garage_id: 228,
		part_id: 16,
		quantity: 440,
	},
	{
		garage_id: 228,
		part_id: 17,
		quantity: 166,
	},
	{
		garage_id: 228,
		part_id: 18,
		quantity: 288,
	},
	{
		garage_id: 228,
		part_id: 19,
		quantity: 160,
	},
	{
		garage_id: 228,
		part_id: 20,
		quantity: 211,
	},
	{
		garage_id: 228,
		part_id: 21,
		quantity: 229,
	},
	{
		garage_id: 228,
		part_id: 22,
		quantity: 414,
	},
	{
		garage_id: 228,
		part_id: 23,
		quantity: 257,
	},
	{
		garage_id: 228,
		part_id: 24,
		quantity: 364,
	},
	{
		garage_id: 228,
		part_id: 25,
		quantity: 74,
	},
	{
		garage_id: 228,
		part_id: 26,
		quantity: 296,
	},
	{
		garage_id: 228,
		part_id: 27,
		quantity: 399,
	},
	{
		garage_id: 228,
		part_id: 28,
		quantity: 187,
	},
	{
		garage_id: 228,
		part_id: 29,
		quantity: 448,
	},
	{
		garage_id: 228,
		part_id: 30,
		quantity: 92,
	},
	{
		garage_id: 228,
		part_id: 31,
		quantity: 87,
	},
	{
		garage_id: 228,
		part_id: 32,
		quantity: 107,
	},
	{
		garage_id: 228,
		part_id: 33,
		quantity: 108,
	},
	{
		garage_id: 228,
		part_id: 34,
		quantity: 231,
	},
	{
		garage_id: 228,
		part_id: 35,
		quantity: 465,
	},
	{
		garage_id: 228,
		part_id: 36,
		quantity: 421,
	},
	{
		garage_id: 228,
		part_id: 37,
		quantity: 250,
	},
	{
		garage_id: 228,
		part_id: 38,
		quantity: 42,
	},
	{
		garage_id: 228,
		part_id: 39,
		quantity: 452,
	},
	{
		garage_id: 228,
		part_id: 40,
		quantity: 339,
	},
	{
		garage_id: 228,
		part_id: 41,
		quantity: 21,
	},
	{
		garage_id: 228,
		part_id: 42,
		quantity: 221,
	},
	{
		garage_id: 228,
		part_id: 43,
		quantity: 95,
	},
	{
		garage_id: 228,
		part_id: 44,
		quantity: 339,
	},
	{
		garage_id: 228,
		part_id: 45,
		quantity: 285,
	},
	{
		garage_id: 228,
		part_id: 46,
		quantity: 234,
	},
	{
		garage_id: 228,
		part_id: 47,
		quantity: 306,
	},
	{
		garage_id: 228,
		part_id: 48,
		quantity: 164,
	},
	{
		garage_id: 228,
		part_id: 49,
		quantity: 459,
	},
	{
		garage_id: 228,
		part_id: 50,
		quantity: 443,
	},
	{
		garage_id: 228,
		part_id: 51,
		quantity: 174,
	},
	{
		garage_id: 228,
		part_id: 52,
		quantity: 479,
	},
	{
		garage_id: 228,
		part_id: 53,
		quantity: 358,
	},
	{
		garage_id: 228,
		part_id: 54,
		quantity: 11,
	},
	{
		garage_id: 228,
		part_id: 55,
		quantity: 195,
	},
	{
		garage_id: 228,
		part_id: 56,
		quantity: 255,
	},
	{
		garage_id: 228,
		part_id: 57,
		quantity: 430,
	},
	{
		garage_id: 228,
		part_id: 58,
		quantity: 99,
	},
	{
		garage_id: 228,
		part_id: 59,
		quantity: 83,
	},
	{
		garage_id: 228,
		part_id: 60,
		quantity: 477,
	},
	{
		garage_id: 228,
		part_id: 61,
		quantity: 9,
	},
	{
		garage_id: 228,
		part_id: 62,
		quantity: 395,
	},
	{
		garage_id: 228,
		part_id: 63,
		quantity: 236,
	},
	{
		garage_id: 228,
		part_id: 64,
		quantity: 240,
	},
	{
		garage_id: 228,
		part_id: 65,
		quantity: 278,
	},
	{
		garage_id: 228,
		part_id: 66,
		quantity: 274,
	},
	{
		garage_id: 228,
		part_id: 67,
		quantity: 102,
	},
	{
		garage_id: 228,
		part_id: 68,
		quantity: 384,
	},
	{
		garage_id: 228,
		part_id: 69,
		quantity: 459,
	},
	{
		garage_id: 228,
		part_id: 70,
		quantity: 307,
	},
	{
		garage_id: 228,
		part_id: 71,
		quantity: 385,
	},
	{
		garage_id: 228,
		part_id: 72,
		quantity: 218,
	},
	{
		garage_id: 228,
		part_id: 73,
		quantity: 387,
	},
	{
		garage_id: 228,
		part_id: 74,
		quantity: 243,
	},
	{
		garage_id: 228,
		part_id: 75,
		quantity: 225,
	},
	{
		garage_id: 228,
		part_id: 76,
		quantity: 166,
	},
	{
		garage_id: 228,
		part_id: 77,
		quantity: 316,
	},
	{
		garage_id: 228,
		part_id: 78,
		quantity: 254,
	},
	{
		garage_id: 228,
		part_id: 79,
		quantity: 399,
	},
	{
		garage_id: 228,
		part_id: 80,
		quantity: 491,
	},
	{
		garage_id: 228,
		part_id: 81,
		quantity: 269,
	},
	{
		garage_id: 228,
		part_id: 82,
		quantity: 137,
	},
	{
		garage_id: 228,
		part_id: 83,
		quantity: 91,
	},
	{
		garage_id: 228,
		part_id: 84,
		quantity: 182,
	},
	{
		garage_id: 228,
		part_id: 85,
		quantity: 29,
	},
	{
		garage_id: 228,
		part_id: 86,
		quantity: 404,
	},
	{
		garage_id: 228,
		part_id: 87,
		quantity: 455,
	},
	{
		garage_id: 228,
		part_id: 88,
		quantity: 4,
	},
	{
		garage_id: 228,
		part_id: 89,
		quantity: 289,
	},
	{
		garage_id: 228,
		part_id: 90,
		quantity: 137,
	},
	{
		garage_id: 228,
		part_id: 91,
		quantity: 144,
	},
	{
		garage_id: 228,
		part_id: 92,
		quantity: 17,
	},
	{
		garage_id: 228,
		part_id: 93,
		quantity: 347,
	},
	{
		garage_id: 228,
		part_id: 94,
		quantity: 136,
	},
	{
		garage_id: 228,
		part_id: 95,
		quantity: 291,
	},
	{
		garage_id: 228,
		part_id: 96,
		quantity: 396,
	},
	{
		garage_id: 228,
		part_id: 97,
		quantity: 34,
	},
	{
		garage_id: 228,
		part_id: 98,
		quantity: 330,
	},
	{
		garage_id: 228,
		part_id: 99,
		quantity: 298,
	},
	{
		garage_id: 228,
		part_id: 100,
		quantity: 455,
	},
	{
		garage_id: 228,
		part_id: 101,
		quantity: 28,
	},
	{
		garage_id: 228,
		part_id: 102,
		quantity: 133,
	},
	{
		garage_id: 228,
		part_id: 103,
		quantity: 421,
	},
	{
		garage_id: 228,
		part_id: 104,
		quantity: 308,
	},
	{
		garage_id: 228,
		part_id: 105,
		quantity: 403,
	},
	{
		garage_id: 228,
		part_id: 106,
		quantity: 55,
	},
	{
		garage_id: 228,
		part_id: 107,
		quantity: 210,
	},
	{
		garage_id: 228,
		part_id: 108,
		quantity: 257,
	},
	{
		garage_id: 228,
		part_id: 109,
		quantity: 234,
	},
	{
		garage_id: 228,
		part_id: 110,
		quantity: 493,
	},
	{
		garage_id: 228,
		part_id: 111,
		quantity: 261,
	},
	{
		garage_id: 228,
		part_id: 112,
		quantity: 13,
	},
	{
		garage_id: 228,
		part_id: 113,
		quantity: 178,
	},
	{
		garage_id: 228,
		part_id: 114,
		quantity: 438,
	},
	{
		garage_id: 228,
		part_id: 115,
		quantity: 282,
	},
	{
		garage_id: 228,
		part_id: 116,
		quantity: 126,
	},
	{
		garage_id: 228,
		part_id: 117,
		quantity: 131,
	},
	{
		garage_id: 228,
		part_id: 118,
		quantity: 195,
	},
	{
		garage_id: 228,
		part_id: 119,
		quantity: 489,
	},
	{
		garage_id: 228,
		part_id: 120,
		quantity: 301,
	},
	{
		garage_id: 228,
		part_id: 121,
		quantity: 90,
	},
	{
		garage_id: 228,
		part_id: 122,
		quantity: 300,
	},
	{
		garage_id: 228,
		part_id: 123,
		quantity: 429,
	},
	{
		garage_id: 228,
		part_id: 124,
		quantity: 296,
	},
	{
		garage_id: 228,
		part_id: 125,
		quantity: 363,
	},
	{
		garage_id: 228,
		part_id: 126,
		quantity: 26,
	},
	{
		garage_id: 228,
		part_id: 127,
		quantity: 159,
	},
	{
		garage_id: 228,
		part_id: 128,
		quantity: 119,
	},
	{
		garage_id: 228,
		part_id: 129,
		quantity: 208,
	},
	{
		garage_id: 228,
		part_id: 130,
		quantity: 500,
	},
	{
		garage_id: 228,
		part_id: 131,
		quantity: 374,
	},
	{
		garage_id: 228,
		part_id: 132,
		quantity: 360,
	},
	{
		garage_id: 228,
		part_id: 133,
		quantity: 10,
	},
	{
		garage_id: 228,
		part_id: 134,
		quantity: 136,
	},
	{
		garage_id: 228,
		part_id: 135,
		quantity: 164,
	},
	{
		garage_id: 228,
		part_id: 136,
		quantity: 169,
	},
	{
		garage_id: 228,
		part_id: 137,
		quantity: 63,
	},
	{
		garage_id: 228,
		part_id: 138,
		quantity: 386,
	},
	{
		garage_id: 228,
		part_id: 139,
		quantity: 19,
	},
	{
		garage_id: 228,
		part_id: 140,
		quantity: 494,
	},
	{
		garage_id: 228,
		part_id: 141,
		quantity: 392,
	},
	{
		garage_id: 228,
		part_id: 142,
		quantity: 148,
	},
	{
		garage_id: 228,
		part_id: 143,
		quantity: 188,
	},
	{
		garage_id: 228,
		part_id: 144,
		quantity: 411,
	},
	{
		garage_id: 228,
		part_id: 145,
		quantity: 311,
	},
	{
		garage_id: 228,
		part_id: 146,
		quantity: 312,
	},
	{
		garage_id: 228,
		part_id: 147,
		quantity: 306,
	},
	{
		garage_id: 228,
		part_id: 148,
		quantity: 369,
	},
	{
		garage_id: 228,
		part_id: 149,
		quantity: 302,
	},
	{
		garage_id: 228,
		part_id: 150,
		quantity: 389,
	},
	{
		garage_id: 228,
		part_id: 151,
		quantity: 379,
	},
	{
		garage_id: 228,
		part_id: 152,
		quantity: 275,
	},
	{
		garage_id: 228,
		part_id: 153,
		quantity: 249,
	},
	{
		garage_id: 228,
		part_id: 154,
		quantity: 72,
	},
	{
		garage_id: 228,
		part_id: 155,
		quantity: 264,
	},
	{
		garage_id: 228,
		part_id: 156,
		quantity: 1,
	},
	{
		garage_id: 228,
		part_id: 157,
		quantity: 65,
	},
	{
		garage_id: 228,
		part_id: 158,
		quantity: 479,
	},
	{
		garage_id: 228,
		part_id: 159,
		quantity: 176,
	},
	{
		garage_id: 228,
		part_id: 160,
		quantity: 55,
	},
	{
		garage_id: 228,
		part_id: 161,
		quantity: 424,
	},
	{
		garage_id: 228,
		part_id: 162,
		quantity: 90,
	},
	{
		garage_id: 228,
		part_id: 163,
		quantity: 314,
	},
	{
		garage_id: 228,
		part_id: 164,
		quantity: 212,
	},
	{
		garage_id: 228,
		part_id: 165,
		quantity: 475,
	},
	{
		garage_id: 228,
		part_id: 166,
		quantity: 24,
	},
	{
		garage_id: 228,
		part_id: 167,
		quantity: 63,
	},
	{
		garage_id: 228,
		part_id: 168,
		quantity: 123,
	},
	{
		garage_id: 228,
		part_id: 169,
		quantity: 85,
	},
	{
		garage_id: 228,
		part_id: 170,
		quantity: 453,
	},
	{
		garage_id: 228,
		part_id: 171,
		quantity: 154,
	},
	{
		garage_id: 228,
		part_id: 172,
		quantity: 218,
	},
	{
		garage_id: 228,
		part_id: 173,
		quantity: 243,
	},
	{
		garage_id: 228,
		part_id: 174,
		quantity: 203,
	},
	{
		garage_id: 228,
		part_id: 175,
		quantity: 283,
	},
	{
		garage_id: 228,
		part_id: 176,
		quantity: 30,
	},
	{
		garage_id: 228,
		part_id: 177,
		quantity: 205,
	},
	{
		garage_id: 228,
		part_id: 178,
		quantity: 327,
	},
	{
		garage_id: 228,
		part_id: 179,
		quantity: 290,
	},
	{
		garage_id: 228,
		part_id: 180,
		quantity: 82,
	},
	{
		garage_id: 228,
		part_id: 181,
		quantity: 157,
	},
	{
		garage_id: 228,
		part_id: 182,
		quantity: 425,
	},
	{
		garage_id: 228,
		part_id: 183,
		quantity: 244,
	},
	{
		garage_id: 228,
		part_id: 184,
		quantity: 293,
	},
	{
		garage_id: 228,
		part_id: 185,
		quantity: 304,
	},
	{
		garage_id: 228,
		part_id: 186,
		quantity: 265,
	},
	{
		garage_id: 228,
		part_id: 187,
		quantity: 184,
	},
	{
		garage_id: 228,
		part_id: 188,
		quantity: 264,
	},
	{
		garage_id: 228,
		part_id: 189,
		quantity: 158,
	},
	{
		garage_id: 228,
		part_id: 190,
		quantity: 3,
	},
	{
		garage_id: 228,
		part_id: 191,
		quantity: 201,
	},
	{
		garage_id: 228,
		part_id: 192,
		quantity: 131,
	},
	{
		garage_id: 228,
		part_id: 193,
		quantity: 399,
	},
	{
		garage_id: 228,
		part_id: 194,
		quantity: 437,
	},
	{
		garage_id: 228,
		part_id: 195,
		quantity: 421,
	},
	{
		garage_id: 228,
		part_id: 196,
		quantity: 458,
	},
	{
		garage_id: 228,
		part_id: 197,
		quantity: 27,
	},
	{
		garage_id: 228,
		part_id: 198,
		quantity: 81,
	},
	{
		garage_id: 228,
		part_id: 199,
		quantity: 45,
	},
	{
		garage_id: 228,
		part_id: 200,
		quantity: 103,
	},
	{
		garage_id: 228,
		part_id: 201,
		quantity: 228,
	},
	{
		garage_id: 228,
		part_id: 202,
		quantity: 144,
	},
	{
		garage_id: 228,
		part_id: 203,
		quantity: 453,
	},
	{
		garage_id: 228,
		part_id: 204,
		quantity: 118,
	},
	{
		garage_id: 228,
		part_id: 205,
		quantity: 319,
	},
	{
		garage_id: 228,
		part_id: 206,
		quantity: 172,
	},
	{
		garage_id: 228,
		part_id: 207,
		quantity: 169,
	},
	{
		garage_id: 228,
		part_id: 208,
		quantity: 27,
	},
	{
		garage_id: 228,
		part_id: 209,
		quantity: 500,
	},
	{
		garage_id: 228,
		part_id: 210,
		quantity: 409,
	},
	{
		garage_id: 228,
		part_id: 211,
		quantity: 15,
	},
	{
		garage_id: 228,
		part_id: 212,
		quantity: 350,
	},
	{
		garage_id: 228,
		part_id: 213,
		quantity: 248,
	},
	{
		garage_id: 228,
		part_id: 214,
		quantity: 54,
	},
	{
		garage_id: 228,
		part_id: 215,
		quantity: 172,
	},
	{
		garage_id: 228,
		part_id: 216,
		quantity: 246,
	},
	{
		garage_id: 228,
		part_id: 217,
		quantity: 401,
	},
	{
		garage_id: 228,
		part_id: 218,
		quantity: 437,
	},
	{
		garage_id: 228,
		part_id: 219,
		quantity: 298,
	},
	{
		garage_id: 228,
		part_id: 220,
		quantity: 327,
	},
	{
		garage_id: 228,
		part_id: 221,
		quantity: 208,
	},
	{
		garage_id: 228,
		part_id: 222,
		quantity: 237,
	},
	{
		garage_id: 228,
		part_id: 223,
		quantity: 61,
	},
	{
		garage_id: 228,
		part_id: 224,
		quantity: 74,
	},
	{
		garage_id: 228,
		part_id: 225,
		quantity: 491,
	},
	{
		garage_id: 228,
		part_id: 226,
		quantity: 265,
	},
	{
		garage_id: 228,
		part_id: 227,
		quantity: 34,
	},
	{
		garage_id: 228,
		part_id: 228,
		quantity: 207,
	},
	{
		garage_id: 228,
		part_id: 229,
		quantity: 52,
	},
	{
		garage_id: 228,
		part_id: 230,
		quantity: 29,
	},
	{
		garage_id: 228,
		part_id: 231,
		quantity: 129,
	},
	{
		garage_id: 228,
		part_id: 232,
		quantity: 214,
	},
	{
		garage_id: 228,
		part_id: 233,
		quantity: 26,
	},
	{
		garage_id: 228,
		part_id: 234,
		quantity: 345,
	},
	{
		garage_id: 228,
		part_id: 235,
		quantity: 428,
	},
	{
		garage_id: 228,
		part_id: 236,
		quantity: 44,
	},
	{
		garage_id: 228,
		part_id: 237,
		quantity: 360,
	},
	{
		garage_id: 228,
		part_id: 238,
		quantity: 485,
	},
	{
		garage_id: 228,
		part_id: 239,
		quantity: 308,
	},
	{
		garage_id: 228,
		part_id: 240,
		quantity: 231,
	},
	{
		garage_id: 228,
		part_id: 241,
		quantity: 269,
	},
	{
		garage_id: 228,
		part_id: 242,
		quantity: 419,
	},
	{
		garage_id: 228,
		part_id: 243,
		quantity: 58,
	},
	{
		garage_id: 228,
		part_id: 244,
		quantity: 347,
	},
	{
		garage_id: 228,
		part_id: 245,
		quantity: 126,
	},
	{
		garage_id: 228,
		part_id: 246,
		quantity: 148,
	},
	{
		garage_id: 228,
		part_id: 247,
		quantity: 290,
	},
	{
		garage_id: 228,
		part_id: 248,
		quantity: 48,
	},
	{
		garage_id: 228,
		part_id: 249,
		quantity: 389,
	},
	{
		garage_id: 228,
		part_id: 250,
		quantity: 448,
	},
	{
		garage_id: 228,
		part_id: 251,
		quantity: 166,
	},
	{
		garage_id: 228,
		part_id: 252,
		quantity: 41,
	},
	{
		garage_id: 228,
		part_id: 253,
		quantity: 344,
	},
	{
		garage_id: 228,
		part_id: 254,
		quantity: 346,
	},
	{
		garage_id: 228,
		part_id: 255,
		quantity: 374,
	},
	{
		garage_id: 228,
		part_id: 256,
		quantity: 207,
	},
	{
		garage_id: 228,
		part_id: 257,
		quantity: 192,
	},
	{
		garage_id: 228,
		part_id: 258,
		quantity: 159,
	},
	{
		garage_id: 228,
		part_id: 259,
		quantity: 158,
	},
	{
		garage_id: 228,
		part_id: 260,
		quantity: 395,
	},
	{
		garage_id: 228,
		part_id: 261,
		quantity: 206,
	},
	{
		garage_id: 228,
		part_id: 262,
		quantity: 86,
	},
	{
		garage_id: 228,
		part_id: 263,
		quantity: 328,
	},
	{
		garage_id: 228,
		part_id: 264,
		quantity: 87,
	},
	{
		garage_id: 228,
		part_id: 265,
		quantity: 232,
	},
	{
		garage_id: 228,
		part_id: 266,
		quantity: 63,
	},
	{
		garage_id: 228,
		part_id: 267,
		quantity: 407,
	},
	{
		garage_id: 228,
		part_id: 268,
		quantity: 448,
	},
	{
		garage_id: 228,
		part_id: 269,
		quantity: 489,
	},
	{
		garage_id: 228,
		part_id: 270,
		quantity: 372,
	},
	{
		garage_id: 228,
		part_id: 271,
		quantity: 335,
	},
	{
		garage_id: 228,
		part_id: 272,
		quantity: 215,
	},
	{
		garage_id: 228,
		part_id: 273,
		quantity: 281,
	},
	{
		garage_id: 228,
		part_id: 274,
		quantity: 475,
	},
	{
		garage_id: 228,
		part_id: 275,
		quantity: 291,
	},
	{
		garage_id: 228,
		part_id: 276,
		quantity: 406,
	},
	{
		garage_id: 228,
		part_id: 277,
		quantity: 64,
	},
	{
		garage_id: 228,
		part_id: 278,
		quantity: 95,
	},
	{
		garage_id: 228,
		part_id: 279,
		quantity: 292,
	},
	{
		garage_id: 228,
		part_id: 280,
		quantity: 373,
	},
	{
		garage_id: 228,
		part_id: 281,
		quantity: 98,
	},
	{
		garage_id: 228,
		part_id: 282,
		quantity: 448,
	},
	{
		garage_id: 228,
		part_id: 283,
		quantity: 3,
	},
	{
		garage_id: 228,
		part_id: 284,
		quantity: 444,
	},
	{
		garage_id: 228,
		part_id: 285,
		quantity: 214,
	},
	{
		garage_id: 228,
		part_id: 286,
		quantity: 407,
	},
	{
		garage_id: 228,
		part_id: 287,
		quantity: 270,
	},
	{
		garage_id: 228,
		part_id: 288,
		quantity: 375,
	},
	{
		garage_id: 228,
		part_id: 289,
		quantity: 335,
	},
	{
		garage_id: 228,
		part_id: 290,
		quantity: 64,
	},
	{
		garage_id: 228,
		part_id: 291,
		quantity: 64,
	},
	{
		garage_id: 228,
		part_id: 292,
		quantity: 9,
	},
	{
		garage_id: 228,
		part_id: 293,
		quantity: 62,
	},
	{
		garage_id: 228,
		part_id: 294,
		quantity: 149,
	},
	{
		garage_id: 228,
		part_id: 295,
		quantity: 499,
	},
	{
		garage_id: 228,
		part_id: 296,
		quantity: 283,
	},
	{
		garage_id: 228,
		part_id: 297,
		quantity: 155,
	},
	{
		garage_id: 228,
		part_id: 298,
		quantity: 336,
	},
	{
		garage_id: 228,
		part_id: 299,
		quantity: 439,
	},
	{
		garage_id: 228,
		part_id: 300,
		quantity: 320,
	},
	{
		garage_id: 228,
		part_id: 301,
		quantity: 493,
	},
	{
		garage_id: 228,
		part_id: 302,
		quantity: 337,
	},
	{
		garage_id: 228,
		part_id: 303,
		quantity: 66,
	},
	{
		garage_id: 228,
		part_id: 304,
		quantity: 156,
	},
	{
		garage_id: 228,
		part_id: 305,
		quantity: 52,
	},
	{
		garage_id: 228,
		part_id: 306,
		quantity: 111,
	},
	{
		garage_id: 228,
		part_id: 307,
		quantity: 294,
	},
	{
		garage_id: 228,
		part_id: 308,
		quantity: 190,
	},
	{
		garage_id: 228,
		part_id: 309,
		quantity: 49,
	},
	{
		garage_id: 228,
		part_id: 310,
		quantity: 255,
	},
	{
		garage_id: 228,
		part_id: 311,
		quantity: 418,
	},
	{
		garage_id: 228,
		part_id: 312,
		quantity: 447,
	},
	{
		garage_id: 228,
		part_id: 313,
		quantity: 342,
	},
	{
		garage_id: 228,
		part_id: 314,
		quantity: 375,
	},
	{
		garage_id: 228,
		part_id: 315,
		quantity: 189,
	},
	{
		garage_id: 228,
		part_id: 316,
		quantity: 404,
	},
	{
		garage_id: 228,
		part_id: 317,
		quantity: 52,
	},
	{
		garage_id: 228,
		part_id: 318,
		quantity: 356,
	},
	{
		garage_id: 228,
		part_id: 319,
		quantity: 473,
	},
	{
		garage_id: 228,
		part_id: 320,
		quantity: 367,
	},
	{
		garage_id: 228,
		part_id: 321,
		quantity: 148,
	},
	{
		garage_id: 228,
		part_id: 322,
		quantity: 240,
	},
	{
		garage_id: 228,
		part_id: 323,
		quantity: 356,
	},
	{
		garage_id: 228,
		part_id: 324,
		quantity: 368,
	},
	{
		garage_id: 228,
		part_id: 325,
		quantity: 223,
	},
	{
		garage_id: 228,
		part_id: 326,
		quantity: 26,
	},
	{
		garage_id: 228,
		part_id: 327,
		quantity: 248,
	},
	{
		garage_id: 228,
		part_id: 328,
		quantity: 241,
	},
	{
		garage_id: 228,
		part_id: 329,
		quantity: 55,
	},
	{
		garage_id: 228,
		part_id: 330,
		quantity: 280,
	},
	{
		garage_id: 228,
		part_id: 331,
		quantity: 464,
	},
	{
		garage_id: 228,
		part_id: 332,
		quantity: 62,
	},
	{
		garage_id: 228,
		part_id: 333,
		quantity: 127,
	},
	{
		garage_id: 228,
		part_id: 334,
		quantity: 309,
	},
	{
		garage_id: 228,
		part_id: 335,
		quantity: 0,
	},
	{
		garage_id: 228,
		part_id: 336,
		quantity: 202,
	},
	{
		garage_id: 228,
		part_id: 337,
		quantity: 231,
	},
	{
		garage_id: 228,
		part_id: 338,
		quantity: 86,
	},
	{
		garage_id: 228,
		part_id: 339,
		quantity: 275,
	},
	{
		garage_id: 228,
		part_id: 340,
		quantity: 381,
	},
	{
		garage_id: 228,
		part_id: 341,
		quantity: 282,
	},
	{
		garage_id: 228,
		part_id: 342,
		quantity: 336,
	},
	{
		garage_id: 228,
		part_id: 343,
		quantity: 80,
	},
	{
		garage_id: 228,
		part_id: 344,
		quantity: 195,
	},
	{
		garage_id: 228,
		part_id: 345,
		quantity: 245,
	},
	{
		garage_id: 228,
		part_id: 346,
		quantity: 473,
	},
	{
		garage_id: 228,
		part_id: 347,
		quantity: 8,
	},
	{
		garage_id: 228,
		part_id: 348,
		quantity: 134,
	},
	{
		garage_id: 228,
		part_id: 349,
		quantity: 23,
	},
	{
		garage_id: 228,
		part_id: 350,
		quantity: 235,
	},
	{
		garage_id: 228,
		part_id: 351,
		quantity: 397,
	},
	{
		garage_id: 228,
		part_id: 352,
		quantity: 423,
	},
	{
		garage_id: 228,
		part_id: 353,
		quantity: 403,
	},
	{
		garage_id: 228,
		part_id: 354,
		quantity: 250,
	},
	{
		garage_id: 228,
		part_id: 355,
		quantity: 497,
	},
	{
		garage_id: 228,
		part_id: 356,
		quantity: 408,
	},
	{
		garage_id: 228,
		part_id: 357,
		quantity: 357,
	},
	{
		garage_id: 228,
		part_id: 358,
		quantity: 200,
	},
	{
		garage_id: 228,
		part_id: 359,
		quantity: 192,
	},
	{
		garage_id: 228,
		part_id: 360,
		quantity: 481,
	},
	{
		garage_id: 228,
		part_id: 361,
		quantity: 270,
	},
	{
		garage_id: 228,
		part_id: 362,
		quantity: 427,
	},
	{
		garage_id: 228,
		part_id: 363,
		quantity: 431,
	},
	{
		garage_id: 228,
		part_id: 364,
		quantity: 371,
	},
	{
		garage_id: 228,
		part_id: 365,
		quantity: 241,
	},
	{
		garage_id: 228,
		part_id: 366,
		quantity: 465,
	},
	{
		garage_id: 228,
		part_id: 367,
		quantity: 85,
	},
	{
		garage_id: 228,
		part_id: 368,
		quantity: 90,
	},
	{
		garage_id: 228,
		part_id: 369,
		quantity: 284,
	},
	{
		garage_id: 228,
		part_id: 370,
		quantity: 377,
	},
	{
		garage_id: 228,
		part_id: 371,
		quantity: 323,
	},
	{
		garage_id: 228,
		part_id: 372,
		quantity: 330,
	},
	{
		garage_id: 228,
		part_id: 373,
		quantity: 344,
	},
	{
		garage_id: 228,
		part_id: 374,
		quantity: 306,
	},
	{
		garage_id: 228,
		part_id: 375,
		quantity: 124,
	},
	{
		garage_id: 228,
		part_id: 376,
		quantity: 174,
	},
	{
		garage_id: 228,
		part_id: 377,
		quantity: 89,
	},
	{
		garage_id: 228,
		part_id: 378,
		quantity: 395,
	},
	{
		garage_id: 228,
		part_id: 379,
		quantity: 80,
	},
	{
		garage_id: 228,
		part_id: 380,
		quantity: 162,
	},
	{
		garage_id: 228,
		part_id: 381,
		quantity: 416,
	},
	{
		garage_id: 228,
		part_id: 382,
		quantity: 301,
	},
	{
		garage_id: 228,
		part_id: 383,
		quantity: 340,
	},
	{
		garage_id: 228,
		part_id: 384,
		quantity: 56,
	},
	{
		garage_id: 228,
		part_id: 385,
		quantity: 2,
	},
	{
		garage_id: 228,
		part_id: 386,
		quantity: 20,
	},
	{
		garage_id: 228,
		part_id: 387,
		quantity: 204,
	},
	{
		garage_id: 228,
		part_id: 388,
		quantity: 238,
	},
	{
		garage_id: 228,
		part_id: 389,
		quantity: 291,
	},
	{
		garage_id: 228,
		part_id: 390,
		quantity: 151,
	},
	{
		garage_id: 228,
		part_id: 391,
		quantity: 262,
	},
	{
		garage_id: 228,
		part_id: 392,
		quantity: 304,
	},
	{
		garage_id: 228,
		part_id: 393,
		quantity: 113,
	},
	{
		garage_id: 228,
		part_id: 394,
		quantity: 426,
	},
	{
		garage_id: 228,
		part_id: 395,
		quantity: 157,
	},
	{
		garage_id: 228,
		part_id: 396,
		quantity: 377,
	},
	{
		garage_id: 228,
		part_id: 397,
		quantity: 375,
	},
	{
		garage_id: 228,
		part_id: 398,
		quantity: 451,
	},
	{
		garage_id: 228,
		part_id: 399,
		quantity: 9,
	},
	{
		garage_id: 229,
		part_id: 0,
		quantity: 351,
	},
	{
		garage_id: 229,
		part_id: 1,
		quantity: 112,
	},
	{
		garage_id: 229,
		part_id: 2,
		quantity: 489,
	},
	{
		garage_id: 229,
		part_id: 3,
		quantity: 95,
	},
	{
		garage_id: 229,
		part_id: 4,
		quantity: 201,
	},
	{
		garage_id: 229,
		part_id: 5,
		quantity: 223,
	},
	{
		garage_id: 229,
		part_id: 6,
		quantity: 267,
	},
	{
		garage_id: 229,
		part_id: 7,
		quantity: 76,
	},
	{
		garage_id: 229,
		part_id: 8,
		quantity: 11,
	},
	{
		garage_id: 229,
		part_id: 9,
		quantity: 487,
	},
	{
		garage_id: 229,
		part_id: 10,
		quantity: 431,
	},
	{
		garage_id: 229,
		part_id: 11,
		quantity: 140,
	},
	{
		garage_id: 229,
		part_id: 12,
		quantity: 372,
	},
	{
		garage_id: 229,
		part_id: 13,
		quantity: 424,
	},
	{
		garage_id: 229,
		part_id: 14,
		quantity: 14,
	},
	{
		garage_id: 229,
		part_id: 15,
		quantity: 392,
	},
	{
		garage_id: 229,
		part_id: 16,
		quantity: 175,
	},
	{
		garage_id: 229,
		part_id: 17,
		quantity: 496,
	},
	{
		garage_id: 229,
		part_id: 18,
		quantity: 94,
	},
	{
		garage_id: 229,
		part_id: 19,
		quantity: 328,
	},
	{
		garage_id: 229,
		part_id: 20,
		quantity: 472,
	},
	{
		garage_id: 229,
		part_id: 21,
		quantity: 418,
	},
	{
		garage_id: 229,
		part_id: 22,
		quantity: 446,
	},
	{
		garage_id: 229,
		part_id: 23,
		quantity: 397,
	},
	{
		garage_id: 229,
		part_id: 24,
		quantity: 302,
	},
	{
		garage_id: 229,
		part_id: 25,
		quantity: 459,
	},
	{
		garage_id: 229,
		part_id: 26,
		quantity: 264,
	},
	{
		garage_id: 229,
		part_id: 27,
		quantity: 91,
	},
	{
		garage_id: 229,
		part_id: 28,
		quantity: 463,
	},
	{
		garage_id: 229,
		part_id: 29,
		quantity: 221,
	},
	{
		garage_id: 229,
		part_id: 30,
		quantity: 408,
	},
	{
		garage_id: 229,
		part_id: 31,
		quantity: 52,
	},
	{
		garage_id: 229,
		part_id: 32,
		quantity: 217,
	},
	{
		garage_id: 229,
		part_id: 33,
		quantity: 232,
	},
	{
		garage_id: 229,
		part_id: 34,
		quantity: 187,
	},
	{
		garage_id: 229,
		part_id: 35,
		quantity: 314,
	},
	{
		garage_id: 229,
		part_id: 36,
		quantity: 431,
	},
	{
		garage_id: 229,
		part_id: 37,
		quantity: 1,
	},
	{
		garage_id: 229,
		part_id: 38,
		quantity: 125,
	},
	{
		garage_id: 229,
		part_id: 39,
		quantity: 448,
	},
	{
		garage_id: 229,
		part_id: 40,
		quantity: 376,
	},
	{
		garage_id: 229,
		part_id: 41,
		quantity: 77,
	},
	{
		garage_id: 229,
		part_id: 42,
		quantity: 353,
	},
	{
		garage_id: 229,
		part_id: 43,
		quantity: 283,
	},
	{
		garage_id: 229,
		part_id: 44,
		quantity: 369,
	},
	{
		garage_id: 229,
		part_id: 45,
		quantity: 381,
	},
	{
		garage_id: 229,
		part_id: 46,
		quantity: 483,
	},
	{
		garage_id: 229,
		part_id: 47,
		quantity: 265,
	},
	{
		garage_id: 229,
		part_id: 48,
		quantity: 346,
	},
	{
		garage_id: 229,
		part_id: 49,
		quantity: 193,
	},
	{
		garage_id: 229,
		part_id: 50,
		quantity: 258,
	},
	{
		garage_id: 229,
		part_id: 51,
		quantity: 445,
	},
	{
		garage_id: 229,
		part_id: 52,
		quantity: 105,
	},
	{
		garage_id: 229,
		part_id: 53,
		quantity: 125,
	},
	{
		garage_id: 229,
		part_id: 54,
		quantity: 101,
	},
	{
		garage_id: 229,
		part_id: 55,
		quantity: 331,
	},
	{
		garage_id: 229,
		part_id: 56,
		quantity: 155,
	},
	{
		garage_id: 229,
		part_id: 57,
		quantity: 244,
	},
	{
		garage_id: 229,
		part_id: 58,
		quantity: 204,
	},
	{
		garage_id: 229,
		part_id: 59,
		quantity: 25,
	},
	{
		garage_id: 229,
		part_id: 60,
		quantity: 302,
	},
	{
		garage_id: 229,
		part_id: 61,
		quantity: 214,
	},
	{
		garage_id: 229,
		part_id: 62,
		quantity: 160,
	},
	{
		garage_id: 229,
		part_id: 63,
		quantity: 207,
	},
	{
		garage_id: 229,
		part_id: 64,
		quantity: 108,
	},
	{
		garage_id: 229,
		part_id: 65,
		quantity: 343,
	},
	{
		garage_id: 229,
		part_id: 66,
		quantity: 86,
	},
	{
		garage_id: 229,
		part_id: 67,
		quantity: 104,
	},
	{
		garage_id: 229,
		part_id: 68,
		quantity: 193,
	},
	{
		garage_id: 229,
		part_id: 69,
		quantity: 227,
	},
	{
		garage_id: 229,
		part_id: 70,
		quantity: 333,
	},
	{
		garage_id: 229,
		part_id: 71,
		quantity: 245,
	},
	{
		garage_id: 229,
		part_id: 72,
		quantity: 288,
	},
	{
		garage_id: 229,
		part_id: 73,
		quantity: 283,
	},
	{
		garage_id: 229,
		part_id: 74,
		quantity: 57,
	},
	{
		garage_id: 229,
		part_id: 75,
		quantity: 225,
	},
	{
		garage_id: 229,
		part_id: 76,
		quantity: 450,
	},
	{
		garage_id: 229,
		part_id: 77,
		quantity: 491,
	},
	{
		garage_id: 229,
		part_id: 78,
		quantity: 377,
	},
	{
		garage_id: 229,
		part_id: 79,
		quantity: 0,
	},
	{
		garage_id: 229,
		part_id: 80,
		quantity: 404,
	},
	{
		garage_id: 229,
		part_id: 81,
		quantity: 50,
	},
	{
		garage_id: 229,
		part_id: 82,
		quantity: 363,
	},
	{
		garage_id: 229,
		part_id: 83,
		quantity: 302,
	},
	{
		garage_id: 229,
		part_id: 84,
		quantity: 42,
	},
	{
		garage_id: 229,
		part_id: 85,
		quantity: 345,
	},
	{
		garage_id: 229,
		part_id: 86,
		quantity: 367,
	},
	{
		garage_id: 229,
		part_id: 87,
		quantity: 201,
	},
	{
		garage_id: 229,
		part_id: 88,
		quantity: 128,
	},
	{
		garage_id: 229,
		part_id: 89,
		quantity: 418,
	},
	{
		garage_id: 229,
		part_id: 90,
		quantity: 452,
	},
	{
		garage_id: 229,
		part_id: 91,
		quantity: 430,
	},
	{
		garage_id: 229,
		part_id: 92,
		quantity: 143,
	},
	{
		garage_id: 229,
		part_id: 93,
		quantity: 486,
	},
	{
		garage_id: 229,
		part_id: 94,
		quantity: 26,
	},
	{
		garage_id: 229,
		part_id: 95,
		quantity: 292,
	},
	{
		garage_id: 229,
		part_id: 96,
		quantity: 121,
	},
	{
		garage_id: 229,
		part_id: 97,
		quantity: 262,
	},
	{
		garage_id: 229,
		part_id: 98,
		quantity: 116,
	},
	{
		garage_id: 229,
		part_id: 99,
		quantity: 21,
	},
	{
		garage_id: 229,
		part_id: 100,
		quantity: 251,
	},
	{
		garage_id: 229,
		part_id: 101,
		quantity: 195,
	},
	{
		garage_id: 229,
		part_id: 102,
		quantity: 145,
	},
	{
		garage_id: 229,
		part_id: 103,
		quantity: 7,
	},
	{
		garage_id: 229,
		part_id: 104,
		quantity: 387,
	},
	{
		garage_id: 229,
		part_id: 105,
		quantity: 129,
	},
	{
		garage_id: 229,
		part_id: 106,
		quantity: 96,
	},
	{
		garage_id: 229,
		part_id: 107,
		quantity: 220,
	},
	{
		garage_id: 229,
		part_id: 108,
		quantity: 316,
	},
	{
		garage_id: 229,
		part_id: 109,
		quantity: 194,
	},
	{
		garage_id: 229,
		part_id: 110,
		quantity: 451,
	},
	{
		garage_id: 229,
		part_id: 111,
		quantity: 288,
	},
	{
		garage_id: 229,
		part_id: 112,
		quantity: 306,
	},
	{
		garage_id: 229,
		part_id: 113,
		quantity: 178,
	},
	{
		garage_id: 229,
		part_id: 114,
		quantity: 95,
	},
	{
		garage_id: 229,
		part_id: 115,
		quantity: 443,
	},
	{
		garage_id: 229,
		part_id: 116,
		quantity: 38,
	},
	{
		garage_id: 229,
		part_id: 117,
		quantity: 363,
	},
	{
		garage_id: 229,
		part_id: 118,
		quantity: 119,
	},
	{
		garage_id: 229,
		part_id: 119,
		quantity: 390,
	},
	{
		garage_id: 229,
		part_id: 120,
		quantity: 345,
	},
	{
		garage_id: 229,
		part_id: 121,
		quantity: 129,
	},
	{
		garage_id: 229,
		part_id: 122,
		quantity: 247,
	},
	{
		garage_id: 229,
		part_id: 123,
		quantity: 302,
	},
	{
		garage_id: 229,
		part_id: 124,
		quantity: 271,
	},
	{
		garage_id: 229,
		part_id: 125,
		quantity: 406,
	},
	{
		garage_id: 229,
		part_id: 126,
		quantity: 269,
	},
	{
		garage_id: 229,
		part_id: 127,
		quantity: 202,
	},
	{
		garage_id: 229,
		part_id: 128,
		quantity: 364,
	},
	{
		garage_id: 229,
		part_id: 129,
		quantity: 375,
	},
	{
		garage_id: 229,
		part_id: 130,
		quantity: 255,
	},
	{
		garage_id: 229,
		part_id: 131,
		quantity: 173,
	},
	{
		garage_id: 229,
		part_id: 132,
		quantity: 479,
	},
	{
		garage_id: 229,
		part_id: 133,
		quantity: 422,
	},
	{
		garage_id: 229,
		part_id: 134,
		quantity: 493,
	},
	{
		garage_id: 229,
		part_id: 135,
		quantity: 298,
	},
	{
		garage_id: 229,
		part_id: 136,
		quantity: 211,
	},
	{
		garage_id: 229,
		part_id: 137,
		quantity: 256,
	},
	{
		garage_id: 229,
		part_id: 138,
		quantity: 13,
	},
	{
		garage_id: 229,
		part_id: 139,
		quantity: 477,
	},
	{
		garage_id: 229,
		part_id: 140,
		quantity: 151,
	},
	{
		garage_id: 229,
		part_id: 141,
		quantity: 406,
	},
	{
		garage_id: 229,
		part_id: 142,
		quantity: 392,
	},
	{
		garage_id: 229,
		part_id: 143,
		quantity: 102,
	},
	{
		garage_id: 229,
		part_id: 144,
		quantity: 276,
	},
	{
		garage_id: 229,
		part_id: 145,
		quantity: 439,
	},
	{
		garage_id: 229,
		part_id: 146,
		quantity: 31,
	},
	{
		garage_id: 229,
		part_id: 147,
		quantity: 386,
	},
	{
		garage_id: 229,
		part_id: 148,
		quantity: 131,
	},
	{
		garage_id: 229,
		part_id: 149,
		quantity: 54,
	},
	{
		garage_id: 229,
		part_id: 150,
		quantity: 45,
	},
	{
		garage_id: 229,
		part_id: 151,
		quantity: 389,
	},
	{
		garage_id: 229,
		part_id: 152,
		quantity: 142,
	},
	{
		garage_id: 229,
		part_id: 153,
		quantity: 229,
	},
	{
		garage_id: 229,
		part_id: 154,
		quantity: 310,
	},
	{
		garage_id: 229,
		part_id: 155,
		quantity: 156,
	},
	{
		garage_id: 229,
		part_id: 156,
		quantity: 164,
	},
	{
		garage_id: 229,
		part_id: 157,
		quantity: 305,
	},
	{
		garage_id: 229,
		part_id: 158,
		quantity: 296,
	},
	{
		garage_id: 229,
		part_id: 159,
		quantity: 136,
	},
	{
		garage_id: 229,
		part_id: 160,
		quantity: 186,
	},
	{
		garage_id: 229,
		part_id: 161,
		quantity: 25,
	},
	{
		garage_id: 229,
		part_id: 162,
		quantity: 225,
	},
	{
		garage_id: 229,
		part_id: 163,
		quantity: 213,
	},
	{
		garage_id: 229,
		part_id: 164,
		quantity: 242,
	},
	{
		garage_id: 229,
		part_id: 165,
		quantity: 354,
	},
	{
		garage_id: 229,
		part_id: 166,
		quantity: 487,
	},
	{
		garage_id: 229,
		part_id: 167,
		quantity: 159,
	},
	{
		garage_id: 229,
		part_id: 168,
		quantity: 443,
	},
	{
		garage_id: 229,
		part_id: 169,
		quantity: 159,
	},
	{
		garage_id: 229,
		part_id: 170,
		quantity: 261,
	},
	{
		garage_id: 229,
		part_id: 171,
		quantity: 180,
	},
	{
		garage_id: 229,
		part_id: 172,
		quantity: 60,
	},
	{
		garage_id: 229,
		part_id: 173,
		quantity: 149,
	},
	{
		garage_id: 229,
		part_id: 174,
		quantity: 9,
	},
	{
		garage_id: 229,
		part_id: 175,
		quantity: 133,
	},
	{
		garage_id: 229,
		part_id: 176,
		quantity: 466,
	},
	{
		garage_id: 229,
		part_id: 177,
		quantity: 412,
	},
	{
		garage_id: 229,
		part_id: 178,
		quantity: 160,
	},
	{
		garage_id: 229,
		part_id: 179,
		quantity: 496,
	},
	{
		garage_id: 229,
		part_id: 180,
		quantity: 20,
	},
	{
		garage_id: 229,
		part_id: 181,
		quantity: 48,
	},
	{
		garage_id: 229,
		part_id: 182,
		quantity: 362,
	},
	{
		garage_id: 229,
		part_id: 183,
		quantity: 112,
	},
	{
		garage_id: 229,
		part_id: 184,
		quantity: 468,
	},
	{
		garage_id: 229,
		part_id: 185,
		quantity: 399,
	},
	{
		garage_id: 229,
		part_id: 186,
		quantity: 465,
	},
	{
		garage_id: 229,
		part_id: 187,
		quantity: 396,
	},
	{
		garage_id: 229,
		part_id: 188,
		quantity: 113,
	},
	{
		garage_id: 229,
		part_id: 189,
		quantity: 275,
	},
	{
		garage_id: 229,
		part_id: 190,
		quantity: 304,
	},
	{
		garage_id: 229,
		part_id: 191,
		quantity: 234,
	},
	{
		garage_id: 229,
		part_id: 192,
		quantity: 491,
	},
	{
		garage_id: 229,
		part_id: 193,
		quantity: 454,
	},
	{
		garage_id: 229,
		part_id: 194,
		quantity: 316,
	},
	{
		garage_id: 229,
		part_id: 195,
		quantity: 28,
	},
	{
		garage_id: 229,
		part_id: 196,
		quantity: 437,
	},
	{
		garage_id: 229,
		part_id: 197,
		quantity: 146,
	},
	{
		garage_id: 229,
		part_id: 198,
		quantity: 339,
	},
	{
		garage_id: 229,
		part_id: 199,
		quantity: 280,
	},
	{
		garage_id: 229,
		part_id: 200,
		quantity: 462,
	},
	{
		garage_id: 229,
		part_id: 201,
		quantity: 138,
	},
	{
		garage_id: 229,
		part_id: 202,
		quantity: 164,
	},
	{
		garage_id: 229,
		part_id: 203,
		quantity: 224,
	},
	{
		garage_id: 229,
		part_id: 204,
		quantity: 290,
	},
	{
		garage_id: 229,
		part_id: 205,
		quantity: 61,
	},
	{
		garage_id: 229,
		part_id: 206,
		quantity: 199,
	},
	{
		garage_id: 229,
		part_id: 207,
		quantity: 220,
	},
	{
		garage_id: 229,
		part_id: 208,
		quantity: 240,
	},
	{
		garage_id: 229,
		part_id: 209,
		quantity: 9,
	},
	{
		garage_id: 229,
		part_id: 210,
		quantity: 397,
	},
	{
		garage_id: 229,
		part_id: 211,
		quantity: 178,
	},
	{
		garage_id: 229,
		part_id: 212,
		quantity: 5,
	},
	{
		garage_id: 229,
		part_id: 213,
		quantity: 123,
	},
	{
		garage_id: 229,
		part_id: 214,
		quantity: 167,
	},
	{
		garage_id: 229,
		part_id: 215,
		quantity: 335,
	},
	{
		garage_id: 229,
		part_id: 216,
		quantity: 352,
	},
	{
		garage_id: 229,
		part_id: 217,
		quantity: 121,
	},
	{
		garage_id: 229,
		part_id: 218,
		quantity: 109,
	},
	{
		garage_id: 229,
		part_id: 219,
		quantity: 382,
	},
	{
		garage_id: 229,
		part_id: 220,
		quantity: 176,
	},
	{
		garage_id: 229,
		part_id: 221,
		quantity: 474,
	},
	{
		garage_id: 229,
		part_id: 222,
		quantity: 453,
	},
	{
		garage_id: 229,
		part_id: 223,
		quantity: 356,
	},
	{
		garage_id: 229,
		part_id: 224,
		quantity: 130,
	},
	{
		garage_id: 229,
		part_id: 225,
		quantity: 114,
	},
	{
		garage_id: 229,
		part_id: 226,
		quantity: 18,
	},
	{
		garage_id: 229,
		part_id: 227,
		quantity: 259,
	},
	{
		garage_id: 229,
		part_id: 228,
		quantity: 410,
	},
	{
		garage_id: 229,
		part_id: 229,
		quantity: 353,
	},
	{
		garage_id: 229,
		part_id: 230,
		quantity: 267,
	},
	{
		garage_id: 229,
		part_id: 231,
		quantity: 111,
	},
	{
		garage_id: 229,
		part_id: 232,
		quantity: 468,
	},
	{
		garage_id: 229,
		part_id: 233,
		quantity: 213,
	},
	{
		garage_id: 229,
		part_id: 234,
		quantity: 455,
	},
	{
		garage_id: 229,
		part_id: 235,
		quantity: 147,
	},
	{
		garage_id: 229,
		part_id: 236,
		quantity: 449,
	},
	{
		garage_id: 229,
		part_id: 237,
		quantity: 56,
	},
	{
		garage_id: 229,
		part_id: 238,
		quantity: 333,
	},
	{
		garage_id: 229,
		part_id: 239,
		quantity: 346,
	},
	{
		garage_id: 229,
		part_id: 240,
		quantity: 433,
	},
	{
		garage_id: 229,
		part_id: 241,
		quantity: 159,
	},
	{
		garage_id: 229,
		part_id: 242,
		quantity: 314,
	},
	{
		garage_id: 229,
		part_id: 243,
		quantity: 79,
	},
	{
		garage_id: 229,
		part_id: 244,
		quantity: 281,
	},
	{
		garage_id: 229,
		part_id: 245,
		quantity: 366,
	},
	{
		garage_id: 229,
		part_id: 246,
		quantity: 111,
	},
	{
		garage_id: 229,
		part_id: 247,
		quantity: 168,
	},
	{
		garage_id: 229,
		part_id: 248,
		quantity: 160,
	},
	{
		garage_id: 229,
		part_id: 249,
		quantity: 279,
	},
	{
		garage_id: 229,
		part_id: 250,
		quantity: 89,
	},
	{
		garage_id: 229,
		part_id: 251,
		quantity: 223,
	},
	{
		garage_id: 229,
		part_id: 252,
		quantity: 104,
	},
	{
		garage_id: 229,
		part_id: 253,
		quantity: 405,
	},
	{
		garage_id: 229,
		part_id: 254,
		quantity: 206,
	},
	{
		garage_id: 229,
		part_id: 255,
		quantity: 437,
	},
	{
		garage_id: 229,
		part_id: 256,
		quantity: 9,
	},
	{
		garage_id: 229,
		part_id: 257,
		quantity: 471,
	},
	{
		garage_id: 229,
		part_id: 258,
		quantity: 488,
	},
	{
		garage_id: 229,
		part_id: 259,
		quantity: 251,
	},
	{
		garage_id: 229,
		part_id: 260,
		quantity: 57,
	},
	{
		garage_id: 229,
		part_id: 261,
		quantity: 410,
	},
	{
		garage_id: 229,
		part_id: 262,
		quantity: 127,
	},
	{
		garage_id: 229,
		part_id: 263,
		quantity: 235,
	},
	{
		garage_id: 229,
		part_id: 264,
		quantity: 482,
	},
	{
		garage_id: 229,
		part_id: 265,
		quantity: 107,
	},
	{
		garage_id: 229,
		part_id: 266,
		quantity: 83,
	},
	{
		garage_id: 229,
		part_id: 267,
		quantity: 415,
	},
	{
		garage_id: 229,
		part_id: 268,
		quantity: 219,
	},
	{
		garage_id: 229,
		part_id: 269,
		quantity: 6,
	},
	{
		garage_id: 229,
		part_id: 270,
		quantity: 330,
	},
	{
		garage_id: 229,
		part_id: 271,
		quantity: 104,
	},
	{
		garage_id: 229,
		part_id: 272,
		quantity: 343,
	},
	{
		garage_id: 229,
		part_id: 273,
		quantity: 453,
	},
	{
		garage_id: 229,
		part_id: 274,
		quantity: 50,
	},
	{
		garage_id: 229,
		part_id: 275,
		quantity: 315,
	},
	{
		garage_id: 229,
		part_id: 276,
		quantity: 252,
	},
	{
		garage_id: 229,
		part_id: 277,
		quantity: 475,
	},
	{
		garage_id: 229,
		part_id: 278,
		quantity: 493,
	},
	{
		garage_id: 229,
		part_id: 279,
		quantity: 450,
	},
	{
		garage_id: 229,
		part_id: 280,
		quantity: 33,
	},
	{
		garage_id: 229,
		part_id: 281,
		quantity: 239,
	},
	{
		garage_id: 229,
		part_id: 282,
		quantity: 359,
	},
	{
		garage_id: 229,
		part_id: 283,
		quantity: 77,
	},
	{
		garage_id: 229,
		part_id: 284,
		quantity: 39,
	},
	{
		garage_id: 229,
		part_id: 285,
		quantity: 95,
	},
	{
		garage_id: 229,
		part_id: 286,
		quantity: 432,
	},
	{
		garage_id: 229,
		part_id: 287,
		quantity: 263,
	},
	{
		garage_id: 229,
		part_id: 288,
		quantity: 124,
	},
	{
		garage_id: 229,
		part_id: 289,
		quantity: 400,
	},
	{
		garage_id: 229,
		part_id: 290,
		quantity: 60,
	},
	{
		garage_id: 229,
		part_id: 291,
		quantity: 297,
	},
	{
		garage_id: 229,
		part_id: 292,
		quantity: 5,
	},
	{
		garage_id: 229,
		part_id: 293,
		quantity: 218,
	},
	{
		garage_id: 229,
		part_id: 294,
		quantity: 440,
	},
	{
		garage_id: 229,
		part_id: 295,
		quantity: 119,
	},
	{
		garage_id: 229,
		part_id: 296,
		quantity: 240,
	},
	{
		garage_id: 229,
		part_id: 297,
		quantity: 311,
	},
	{
		garage_id: 229,
		part_id: 298,
		quantity: 300,
	},
	{
		garage_id: 229,
		part_id: 299,
		quantity: 216,
	},
	{
		garage_id: 229,
		part_id: 300,
		quantity: 364,
	},
	{
		garage_id: 229,
		part_id: 301,
		quantity: 202,
	},
	{
		garage_id: 229,
		part_id: 302,
		quantity: 38,
	},
	{
		garage_id: 229,
		part_id: 303,
		quantity: 238,
	},
	{
		garage_id: 229,
		part_id: 304,
		quantity: 452,
	},
	{
		garage_id: 229,
		part_id: 305,
		quantity: 452,
	},
	{
		garage_id: 229,
		part_id: 306,
		quantity: 415,
	},
	{
		garage_id: 229,
		part_id: 307,
		quantity: 88,
	},
	{
		garage_id: 229,
		part_id: 308,
		quantity: 59,
	},
	{
		garage_id: 229,
		part_id: 309,
		quantity: 458,
	},
	{
		garage_id: 229,
		part_id: 310,
		quantity: 160,
	},
	{
		garage_id: 229,
		part_id: 311,
		quantity: 395,
	},
	{
		garage_id: 229,
		part_id: 312,
		quantity: 28,
	},
	{
		garage_id: 229,
		part_id: 313,
		quantity: 15,
	},
	{
		garage_id: 229,
		part_id: 314,
		quantity: 60,
	},
	{
		garage_id: 229,
		part_id: 315,
		quantity: 276,
	},
	{
		garage_id: 229,
		part_id: 316,
		quantity: 150,
	},
	{
		garage_id: 229,
		part_id: 317,
		quantity: 479,
	},
	{
		garage_id: 229,
		part_id: 318,
		quantity: 76,
	},
	{
		garage_id: 229,
		part_id: 319,
		quantity: 495,
	},
	{
		garage_id: 229,
		part_id: 320,
		quantity: 350,
	},
	{
		garage_id: 229,
		part_id: 321,
		quantity: 351,
	},
	{
		garage_id: 229,
		part_id: 322,
		quantity: 49,
	},
	{
		garage_id: 229,
		part_id: 323,
		quantity: 445,
	},
	{
		garage_id: 229,
		part_id: 324,
		quantity: 57,
	},
	{
		garage_id: 229,
		part_id: 325,
		quantity: 229,
	},
	{
		garage_id: 229,
		part_id: 326,
		quantity: 424,
	},
	{
		garage_id: 229,
		part_id: 327,
		quantity: 229,
	},
	{
		garage_id: 229,
		part_id: 328,
		quantity: 132,
	},
	{
		garage_id: 229,
		part_id: 329,
		quantity: 297,
	},
	{
		garage_id: 229,
		part_id: 330,
		quantity: 286,
	},
	{
		garage_id: 229,
		part_id: 331,
		quantity: 261,
	},
	{
		garage_id: 229,
		part_id: 332,
		quantity: 127,
	},
	{
		garage_id: 229,
		part_id: 333,
		quantity: 237,
	},
	{
		garage_id: 229,
		part_id: 334,
		quantity: 79,
	},
	{
		garage_id: 229,
		part_id: 335,
		quantity: 186,
	},
	{
		garage_id: 229,
		part_id: 336,
		quantity: 453,
	},
	{
		garage_id: 229,
		part_id: 337,
		quantity: 73,
	},
	{
		garage_id: 229,
		part_id: 338,
		quantity: 159,
	},
	{
		garage_id: 229,
		part_id: 339,
		quantity: 116,
	},
	{
		garage_id: 229,
		part_id: 340,
		quantity: 400,
	},
	{
		garage_id: 229,
		part_id: 341,
		quantity: 363,
	},
	{
		garage_id: 229,
		part_id: 342,
		quantity: 488,
	},
	{
		garage_id: 229,
		part_id: 343,
		quantity: 374,
	},
	{
		garage_id: 229,
		part_id: 344,
		quantity: 283,
	},
	{
		garage_id: 229,
		part_id: 345,
		quantity: 179,
	},
	{
		garage_id: 229,
		part_id: 346,
		quantity: 474,
	},
	{
		garage_id: 229,
		part_id: 347,
		quantity: 228,
	},
	{
		garage_id: 229,
		part_id: 348,
		quantity: 483,
	},
	{
		garage_id: 229,
		part_id: 349,
		quantity: 93,
	},
	{
		garage_id: 229,
		part_id: 350,
		quantity: 403,
	},
	{
		garage_id: 229,
		part_id: 351,
		quantity: 319,
	},
	{
		garage_id: 229,
		part_id: 352,
		quantity: 7,
	},
	{
		garage_id: 229,
		part_id: 353,
		quantity: 106,
	},
	{
		garage_id: 229,
		part_id: 354,
		quantity: 17,
	},
	{
		garage_id: 229,
		part_id: 355,
		quantity: 264,
	},
	{
		garage_id: 229,
		part_id: 356,
		quantity: 488,
	},
	{
		garage_id: 229,
		part_id: 357,
		quantity: 475,
	},
	{
		garage_id: 229,
		part_id: 358,
		quantity: 69,
	},
	{
		garage_id: 229,
		part_id: 359,
		quantity: 166,
	},
	{
		garage_id: 229,
		part_id: 360,
		quantity: 118,
	},
	{
		garage_id: 229,
		part_id: 361,
		quantity: 257,
	},
	{
		garage_id: 229,
		part_id: 362,
		quantity: 481,
	},
	{
		garage_id: 229,
		part_id: 363,
		quantity: 80,
	},
	{
		garage_id: 229,
		part_id: 364,
		quantity: 27,
	},
	{
		garage_id: 229,
		part_id: 365,
		quantity: 64,
	},
	{
		garage_id: 229,
		part_id: 366,
		quantity: 111,
	},
	{
		garage_id: 229,
		part_id: 367,
		quantity: 264,
	},
	{
		garage_id: 229,
		part_id: 368,
		quantity: 165,
	},
	{
		garage_id: 229,
		part_id: 369,
		quantity: 270,
	},
	{
		garage_id: 229,
		part_id: 370,
		quantity: 36,
	},
	{
		garage_id: 229,
		part_id: 371,
		quantity: 384,
	},
	{
		garage_id: 229,
		part_id: 372,
		quantity: 444,
	},
	{
		garage_id: 229,
		part_id: 373,
		quantity: 446,
	},
	{
		garage_id: 229,
		part_id: 374,
		quantity: 210,
	},
	{
		garage_id: 229,
		part_id: 375,
		quantity: 3,
	},
	{
		garage_id: 229,
		part_id: 376,
		quantity: 429,
	},
	{
		garage_id: 229,
		part_id: 377,
		quantity: 451,
	},
	{
		garage_id: 229,
		part_id: 378,
		quantity: 77,
	},
	{
		garage_id: 229,
		part_id: 379,
		quantity: 102,
	},
	{
		garage_id: 229,
		part_id: 380,
		quantity: 395,
	},
	{
		garage_id: 229,
		part_id: 381,
		quantity: 497,
	},
	{
		garage_id: 229,
		part_id: 382,
		quantity: 473,
	},
	{
		garage_id: 229,
		part_id: 383,
		quantity: 53,
	},
	{
		garage_id: 229,
		part_id: 384,
		quantity: 389,
	},
	{
		garage_id: 229,
		part_id: 385,
		quantity: 172,
	},
	{
		garage_id: 229,
		part_id: 386,
		quantity: 290,
	},
	{
		garage_id: 229,
		part_id: 387,
		quantity: 462,
	},
	{
		garage_id: 229,
		part_id: 388,
		quantity: 131,
	},
	{
		garage_id: 229,
		part_id: 389,
		quantity: 463,
	},
	{
		garage_id: 229,
		part_id: 390,
		quantity: 117,
	},
	{
		garage_id: 229,
		part_id: 391,
		quantity: 25,
	},
	{
		garage_id: 229,
		part_id: 392,
		quantity: 303,
	},
	{
		garage_id: 229,
		part_id: 393,
		quantity: 389,
	},
	{
		garage_id: 229,
		part_id: 394,
		quantity: 52,
	},
	{
		garage_id: 229,
		part_id: 395,
		quantity: 316,
	},
	{
		garage_id: 229,
		part_id: 396,
		quantity: 131,
	},
	{
		garage_id: 229,
		part_id: 397,
		quantity: 493,
	},
	{
		garage_id: 229,
		part_id: 398,
		quantity: 489,
	},
	{
		garage_id: 229,
		part_id: 399,
		quantity: 289,
	},
	{
		garage_id: 230,
		part_id: 0,
		quantity: 210,
	},
	{
		garage_id: 230,
		part_id: 1,
		quantity: 423,
	},
	{
		garage_id: 230,
		part_id: 2,
		quantity: 434,
	},
	{
		garage_id: 230,
		part_id: 3,
		quantity: 274,
	},
	{
		garage_id: 230,
		part_id: 4,
		quantity: 342,
	},
	{
		garage_id: 230,
		part_id: 5,
		quantity: 377,
	},
	{
		garage_id: 230,
		part_id: 6,
		quantity: 112,
	},
	{
		garage_id: 230,
		part_id: 7,
		quantity: 178,
	},
	{
		garage_id: 230,
		part_id: 8,
		quantity: 467,
	},
	{
		garage_id: 230,
		part_id: 9,
		quantity: 249,
	},
	{
		garage_id: 230,
		part_id: 10,
		quantity: 306,
	},
	{
		garage_id: 230,
		part_id: 11,
		quantity: 36,
	},
	{
		garage_id: 230,
		part_id: 12,
		quantity: 465,
	},
	{
		garage_id: 230,
		part_id: 13,
		quantity: 493,
	},
	{
		garage_id: 230,
		part_id: 14,
		quantity: 89,
	},
	{
		garage_id: 230,
		part_id: 15,
		quantity: 401,
	},
	{
		garage_id: 230,
		part_id: 16,
		quantity: 318,
	},
	{
		garage_id: 230,
		part_id: 17,
		quantity: 87,
	},
	{
		garage_id: 230,
		part_id: 18,
		quantity: 126,
	},
	{
		garage_id: 230,
		part_id: 19,
		quantity: 179,
	},
	{
		garage_id: 230,
		part_id: 20,
		quantity: 276,
	},
	{
		garage_id: 230,
		part_id: 21,
		quantity: 406,
	},
	{
		garage_id: 230,
		part_id: 22,
		quantity: 182,
	},
	{
		garage_id: 230,
		part_id: 23,
		quantity: 80,
	},
	{
		garage_id: 230,
		part_id: 24,
		quantity: 290,
	},
	{
		garage_id: 230,
		part_id: 25,
		quantity: 99,
	},
	{
		garage_id: 230,
		part_id: 26,
		quantity: 36,
	},
	{
		garage_id: 230,
		part_id: 27,
		quantity: 394,
	},
	{
		garage_id: 230,
		part_id: 28,
		quantity: 65,
	},
	{
		garage_id: 230,
		part_id: 29,
		quantity: 185,
	},
	{
		garage_id: 230,
		part_id: 30,
		quantity: 215,
	},
	{
		garage_id: 230,
		part_id: 31,
		quantity: 231,
	},
	{
		garage_id: 230,
		part_id: 32,
		quantity: 17,
	},
	{
		garage_id: 230,
		part_id: 33,
		quantity: 478,
	},
	{
		garage_id: 230,
		part_id: 34,
		quantity: 486,
	},
	{
		garage_id: 230,
		part_id: 35,
		quantity: 236,
	},
	{
		garage_id: 230,
		part_id: 36,
		quantity: 80,
	},
	{
		garage_id: 230,
		part_id: 37,
		quantity: 35,
	},
	{
		garage_id: 230,
		part_id: 38,
		quantity: 295,
	},
	{
		garage_id: 230,
		part_id: 39,
		quantity: 254,
	},
	{
		garage_id: 230,
		part_id: 40,
		quantity: 5,
	},
	{
		garage_id: 230,
		part_id: 41,
		quantity: 133,
	},
	{
		garage_id: 230,
		part_id: 42,
		quantity: 306,
	},
	{
		garage_id: 230,
		part_id: 43,
		quantity: 172,
	},
	{
		garage_id: 230,
		part_id: 44,
		quantity: 129,
	},
	{
		garage_id: 230,
		part_id: 45,
		quantity: 492,
	},
	{
		garage_id: 230,
		part_id: 46,
		quantity: 11,
	},
	{
		garage_id: 230,
		part_id: 47,
		quantity: 134,
	},
	{
		garage_id: 230,
		part_id: 48,
		quantity: 58,
	},
	{
		garage_id: 230,
		part_id: 49,
		quantity: 11,
	},
	{
		garage_id: 230,
		part_id: 50,
		quantity: 350,
	},
	{
		garage_id: 230,
		part_id: 51,
		quantity: 303,
	},
	{
		garage_id: 230,
		part_id: 52,
		quantity: 159,
	},
	{
		garage_id: 230,
		part_id: 53,
		quantity: 136,
	},
	{
		garage_id: 230,
		part_id: 54,
		quantity: 70,
	},
	{
		garage_id: 230,
		part_id: 55,
		quantity: 132,
	},
	{
		garage_id: 230,
		part_id: 56,
		quantity: 471,
	},
	{
		garage_id: 230,
		part_id: 57,
		quantity: 213,
	},
	{
		garage_id: 230,
		part_id: 58,
		quantity: 340,
	},
	{
		garage_id: 230,
		part_id: 59,
		quantity: 366,
	},
	{
		garage_id: 230,
		part_id: 60,
		quantity: 76,
	},
	{
		garage_id: 230,
		part_id: 61,
		quantity: 69,
	},
	{
		garage_id: 230,
		part_id: 62,
		quantity: 417,
	},
	{
		garage_id: 230,
		part_id: 63,
		quantity: 397,
	},
	{
		garage_id: 230,
		part_id: 64,
		quantity: 103,
	},
	{
		garage_id: 230,
		part_id: 65,
		quantity: 207,
	},
	{
		garage_id: 230,
		part_id: 66,
		quantity: 474,
	},
	{
		garage_id: 230,
		part_id: 67,
		quantity: 312,
	},
	{
		garage_id: 230,
		part_id: 68,
		quantity: 179,
	},
	{
		garage_id: 230,
		part_id: 69,
		quantity: 310,
	},
	{
		garage_id: 230,
		part_id: 70,
		quantity: 427,
	},
	{
		garage_id: 230,
		part_id: 71,
		quantity: 145,
	},
	{
		garage_id: 230,
		part_id: 72,
		quantity: 260,
	},
	{
		garage_id: 230,
		part_id: 73,
		quantity: 33,
	},
	{
		garage_id: 230,
		part_id: 74,
		quantity: 383,
	},
	{
		garage_id: 230,
		part_id: 75,
		quantity: 241,
	},
	{
		garage_id: 230,
		part_id: 76,
		quantity: 464,
	},
	{
		garage_id: 230,
		part_id: 77,
		quantity: 469,
	},
	{
		garage_id: 230,
		part_id: 78,
		quantity: 55,
	},
	{
		garage_id: 230,
		part_id: 79,
		quantity: 66,
	},
	{
		garage_id: 230,
		part_id: 80,
		quantity: 106,
	},
	{
		garage_id: 230,
		part_id: 81,
		quantity: 334,
	},
	{
		garage_id: 230,
		part_id: 82,
		quantity: 435,
	},
	{
		garage_id: 230,
		part_id: 83,
		quantity: 13,
	},
	{
		garage_id: 230,
		part_id: 84,
		quantity: 198,
	},
	{
		garage_id: 230,
		part_id: 85,
		quantity: 436,
	},
	{
		garage_id: 230,
		part_id: 86,
		quantity: 28,
	},
	{
		garage_id: 230,
		part_id: 87,
		quantity: 140,
	},
	{
		garage_id: 230,
		part_id: 88,
		quantity: 7,
	},
	{
		garage_id: 230,
		part_id: 89,
		quantity: 352,
	},
	{
		garage_id: 230,
		part_id: 90,
		quantity: 193,
	},
	{
		garage_id: 230,
		part_id: 91,
		quantity: 413,
	},
	{
		garage_id: 230,
		part_id: 92,
		quantity: 387,
	},
	{
		garage_id: 230,
		part_id: 93,
		quantity: 462,
	},
	{
		garage_id: 230,
		part_id: 94,
		quantity: 377,
	},
	{
		garage_id: 230,
		part_id: 95,
		quantity: 306,
	},
	{
		garage_id: 230,
		part_id: 96,
		quantity: 210,
	},
	{
		garage_id: 230,
		part_id: 97,
		quantity: 494,
	},
	{
		garage_id: 230,
		part_id: 98,
		quantity: 281,
	},
	{
		garage_id: 230,
		part_id: 99,
		quantity: 177,
	},
	{
		garage_id: 230,
		part_id: 100,
		quantity: 334,
	},
	{
		garage_id: 230,
		part_id: 101,
		quantity: 357,
	},
	{
		garage_id: 230,
		part_id: 102,
		quantity: 26,
	},
	{
		garage_id: 230,
		part_id: 103,
		quantity: 22,
	},
	{
		garage_id: 230,
		part_id: 104,
		quantity: 481,
	},
	{
		garage_id: 230,
		part_id: 105,
		quantity: 77,
	},
	{
		garage_id: 230,
		part_id: 106,
		quantity: 374,
	},
	{
		garage_id: 230,
		part_id: 107,
		quantity: 194,
	},
	{
		garage_id: 230,
		part_id: 108,
		quantity: 22,
	},
	{
		garage_id: 230,
		part_id: 109,
		quantity: 203,
	},
	{
		garage_id: 230,
		part_id: 110,
		quantity: 212,
	},
	{
		garage_id: 230,
		part_id: 111,
		quantity: 98,
	},
	{
		garage_id: 230,
		part_id: 112,
		quantity: 389,
	},
	{
		garage_id: 230,
		part_id: 113,
		quantity: 252,
	},
	{
		garage_id: 230,
		part_id: 114,
		quantity: 331,
	},
	{
		garage_id: 230,
		part_id: 115,
		quantity: 124,
	},
	{
		garage_id: 230,
		part_id: 116,
		quantity: 203,
	},
	{
		garage_id: 230,
		part_id: 117,
		quantity: 54,
	},
	{
		garage_id: 230,
		part_id: 118,
		quantity: 195,
	},
	{
		garage_id: 230,
		part_id: 119,
		quantity: 428,
	},
	{
		garage_id: 230,
		part_id: 120,
		quantity: 300,
	},
	{
		garage_id: 230,
		part_id: 121,
		quantity: 244,
	},
	{
		garage_id: 230,
		part_id: 122,
		quantity: 233,
	},
	{
		garage_id: 230,
		part_id: 123,
		quantity: 157,
	},
	{
		garage_id: 230,
		part_id: 124,
		quantity: 256,
	},
	{
		garage_id: 230,
		part_id: 125,
		quantity: 139,
	},
	{
		garage_id: 230,
		part_id: 126,
		quantity: 308,
	},
	{
		garage_id: 230,
		part_id: 127,
		quantity: 72,
	},
	{
		garage_id: 230,
		part_id: 128,
		quantity: 73,
	},
	{
		garage_id: 230,
		part_id: 129,
		quantity: 272,
	},
	{
		garage_id: 230,
		part_id: 130,
		quantity: 207,
	},
	{
		garage_id: 230,
		part_id: 131,
		quantity: 147,
	},
	{
		garage_id: 230,
		part_id: 132,
		quantity: 248,
	},
	{
		garage_id: 230,
		part_id: 133,
		quantity: 291,
	},
	{
		garage_id: 230,
		part_id: 134,
		quantity: 28,
	},
	{
		garage_id: 230,
		part_id: 135,
		quantity: 432,
	},
	{
		garage_id: 230,
		part_id: 136,
		quantity: 328,
	},
	{
		garage_id: 230,
		part_id: 137,
		quantity: 171,
	},
	{
		garage_id: 230,
		part_id: 138,
		quantity: 449,
	},
	{
		garage_id: 230,
		part_id: 139,
		quantity: 463,
	},
	{
		garage_id: 230,
		part_id: 140,
		quantity: 219,
	},
	{
		garage_id: 230,
		part_id: 141,
		quantity: 66,
	},
	{
		garage_id: 230,
		part_id: 142,
		quantity: 306,
	},
	{
		garage_id: 230,
		part_id: 143,
		quantity: 13,
	},
	{
		garage_id: 230,
		part_id: 144,
		quantity: 416,
	},
	{
		garage_id: 230,
		part_id: 145,
		quantity: 352,
	},
	{
		garage_id: 230,
		part_id: 146,
		quantity: 115,
	},
	{
		garage_id: 230,
		part_id: 147,
		quantity: 210,
	},
	{
		garage_id: 230,
		part_id: 148,
		quantity: 234,
	},
	{
		garage_id: 230,
		part_id: 149,
		quantity: 298,
	},
	{
		garage_id: 230,
		part_id: 150,
		quantity: 362,
	},
	{
		garage_id: 230,
		part_id: 151,
		quantity: 293,
	},
	{
		garage_id: 230,
		part_id: 152,
		quantity: 216,
	},
	{
		garage_id: 230,
		part_id: 153,
		quantity: 155,
	},
	{
		garage_id: 230,
		part_id: 154,
		quantity: 93,
	},
	{
		garage_id: 230,
		part_id: 155,
		quantity: 215,
	},
	{
		garage_id: 230,
		part_id: 156,
		quantity: 216,
	},
	{
		garage_id: 230,
		part_id: 157,
		quantity: 93,
	},
	{
		garage_id: 230,
		part_id: 158,
		quantity: 448,
	},
	{
		garage_id: 230,
		part_id: 159,
		quantity: 389,
	},
	{
		garage_id: 230,
		part_id: 160,
		quantity: 142,
	},
	{
		garage_id: 230,
		part_id: 161,
		quantity: 210,
	},
	{
		garage_id: 230,
		part_id: 162,
		quantity: 347,
	},
	{
		garage_id: 230,
		part_id: 163,
		quantity: 340,
	},
	{
		garage_id: 230,
		part_id: 164,
		quantity: 126,
	},
	{
		garage_id: 230,
		part_id: 165,
		quantity: 415,
	},
	{
		garage_id: 230,
		part_id: 166,
		quantity: 154,
	},
	{
		garage_id: 230,
		part_id: 167,
		quantity: 37,
	},
	{
		garage_id: 230,
		part_id: 168,
		quantity: 428,
	},
	{
		garage_id: 230,
		part_id: 169,
		quantity: 147,
	},
	{
		garage_id: 230,
		part_id: 170,
		quantity: 216,
	},
	{
		garage_id: 230,
		part_id: 171,
		quantity: 121,
	},
	{
		garage_id: 230,
		part_id: 172,
		quantity: 156,
	},
	{
		garage_id: 230,
		part_id: 173,
		quantity: 327,
	},
	{
		garage_id: 230,
		part_id: 174,
		quantity: 207,
	},
	{
		garage_id: 230,
		part_id: 175,
		quantity: 417,
	},
	{
		garage_id: 230,
		part_id: 176,
		quantity: 131,
	},
	{
		garage_id: 230,
		part_id: 177,
		quantity: 238,
	},
	{
		garage_id: 230,
		part_id: 178,
		quantity: 121,
	},
	{
		garage_id: 230,
		part_id: 179,
		quantity: 148,
	},
	{
		garage_id: 230,
		part_id: 180,
		quantity: 242,
	},
	{
		garage_id: 230,
		part_id: 181,
		quantity: 144,
	},
	{
		garage_id: 230,
		part_id: 182,
		quantity: 476,
	},
	{
		garage_id: 230,
		part_id: 183,
		quantity: 11,
	},
	{
		garage_id: 230,
		part_id: 184,
		quantity: 381,
	},
	{
		garage_id: 230,
		part_id: 185,
		quantity: 58,
	},
	{
		garage_id: 230,
		part_id: 186,
		quantity: 372,
	},
	{
		garage_id: 230,
		part_id: 187,
		quantity: 470,
	},
	{
		garage_id: 230,
		part_id: 188,
		quantity: 250,
	},
	{
		garage_id: 230,
		part_id: 189,
		quantity: 94,
	},
	{
		garage_id: 230,
		part_id: 190,
		quantity: 74,
	},
	{
		garage_id: 230,
		part_id: 191,
		quantity: 297,
	},
	{
		garage_id: 230,
		part_id: 192,
		quantity: 489,
	},
	{
		garage_id: 230,
		part_id: 193,
		quantity: 235,
	},
	{
		garage_id: 230,
		part_id: 194,
		quantity: 133,
	},
	{
		garage_id: 230,
		part_id: 195,
		quantity: 136,
	},
	{
		garage_id: 230,
		part_id: 196,
		quantity: 70,
	},
	{
		garage_id: 230,
		part_id: 197,
		quantity: 431,
	},
	{
		garage_id: 230,
		part_id: 198,
		quantity: 159,
	},
	{
		garage_id: 230,
		part_id: 199,
		quantity: 17,
	},
	{
		garage_id: 230,
		part_id: 200,
		quantity: 296,
	},
	{
		garage_id: 230,
		part_id: 201,
		quantity: 236,
	},
	{
		garage_id: 230,
		part_id: 202,
		quantity: 25,
	},
	{
		garage_id: 230,
		part_id: 203,
		quantity: 404,
	},
	{
		garage_id: 230,
		part_id: 204,
		quantity: 422,
	},
	{
		garage_id: 230,
		part_id: 205,
		quantity: 353,
	},
	{
		garage_id: 230,
		part_id: 206,
		quantity: 444,
	},
	{
		garage_id: 230,
		part_id: 207,
		quantity: 167,
	},
	{
		garage_id: 230,
		part_id: 208,
		quantity: 278,
	},
	{
		garage_id: 230,
		part_id: 209,
		quantity: 469,
	},
	{
		garage_id: 230,
		part_id: 210,
		quantity: 31,
	},
	{
		garage_id: 230,
		part_id: 211,
		quantity: 82,
	},
	{
		garage_id: 230,
		part_id: 212,
		quantity: 94,
	},
	{
		garage_id: 230,
		part_id: 213,
		quantity: 79,
	},
	{
		garage_id: 230,
		part_id: 214,
		quantity: 410,
	},
	{
		garage_id: 230,
		part_id: 215,
		quantity: 50,
	},
	{
		garage_id: 230,
		part_id: 216,
		quantity: 399,
	},
	{
		garage_id: 230,
		part_id: 217,
		quantity: 47,
	},
	{
		garage_id: 230,
		part_id: 218,
		quantity: 96,
	},
	{
		garage_id: 230,
		part_id: 219,
		quantity: 118,
	},
	{
		garage_id: 230,
		part_id: 220,
		quantity: 389,
	},
	{
		garage_id: 230,
		part_id: 221,
		quantity: 84,
	},
	{
		garage_id: 230,
		part_id: 222,
		quantity: 281,
	},
	{
		garage_id: 230,
		part_id: 223,
		quantity: 466,
	},
	{
		garage_id: 230,
		part_id: 224,
		quantity: 274,
	},
	{
		garage_id: 230,
		part_id: 225,
		quantity: 166,
	},
	{
		garage_id: 230,
		part_id: 226,
		quantity: 336,
	},
	{
		garage_id: 230,
		part_id: 227,
		quantity: 500,
	},
	{
		garage_id: 230,
		part_id: 228,
		quantity: 415,
	},
	{
		garage_id: 230,
		part_id: 229,
		quantity: 300,
	},
	{
		garage_id: 230,
		part_id: 230,
		quantity: 22,
	},
	{
		garage_id: 230,
		part_id: 231,
		quantity: 147,
	},
	{
		garage_id: 230,
		part_id: 232,
		quantity: 199,
	},
	{
		garage_id: 230,
		part_id: 233,
		quantity: 157,
	},
	{
		garage_id: 230,
		part_id: 234,
		quantity: 103,
	},
	{
		garage_id: 230,
		part_id: 235,
		quantity: 468,
	},
	{
		garage_id: 230,
		part_id: 236,
		quantity: 67,
	},
	{
		garage_id: 230,
		part_id: 237,
		quantity: 9,
	},
	{
		garage_id: 230,
		part_id: 238,
		quantity: 495,
	},
	{
		garage_id: 230,
		part_id: 239,
		quantity: 492,
	},
	{
		garage_id: 230,
		part_id: 240,
		quantity: 345,
	},
	{
		garage_id: 230,
		part_id: 241,
		quantity: 354,
	},
	{
		garage_id: 230,
		part_id: 242,
		quantity: 422,
	},
	{
		garage_id: 230,
		part_id: 243,
		quantity: 97,
	},
	{
		garage_id: 230,
		part_id: 244,
		quantity: 475,
	},
	{
		garage_id: 230,
		part_id: 245,
		quantity: 488,
	},
	{
		garage_id: 230,
		part_id: 246,
		quantity: 106,
	},
	{
		garage_id: 230,
		part_id: 247,
		quantity: 55,
	},
	{
		garage_id: 230,
		part_id: 248,
		quantity: 269,
	},
	{
		garage_id: 230,
		part_id: 249,
		quantity: 273,
	},
	{
		garage_id: 230,
		part_id: 250,
		quantity: 317,
	},
	{
		garage_id: 230,
		part_id: 251,
		quantity: 195,
	},
	{
		garage_id: 230,
		part_id: 252,
		quantity: 424,
	},
	{
		garage_id: 230,
		part_id: 253,
		quantity: 215,
	},
	{
		garage_id: 230,
		part_id: 254,
		quantity: 213,
	},
	{
		garage_id: 230,
		part_id: 255,
		quantity: 245,
	},
	{
		garage_id: 230,
		part_id: 256,
		quantity: 69,
	},
	{
		garage_id: 230,
		part_id: 257,
		quantity: 355,
	},
	{
		garage_id: 230,
		part_id: 258,
		quantity: 357,
	},
	{
		garage_id: 230,
		part_id: 259,
		quantity: 69,
	},
	{
		garage_id: 230,
		part_id: 260,
		quantity: 474,
	},
	{
		garage_id: 230,
		part_id: 261,
		quantity: 298,
	},
	{
		garage_id: 230,
		part_id: 262,
		quantity: 80,
	},
	{
		garage_id: 230,
		part_id: 263,
		quantity: 149,
	},
	{
		garage_id: 230,
		part_id: 264,
		quantity: 409,
	},
	{
		garage_id: 230,
		part_id: 265,
		quantity: 192,
	},
	{
		garage_id: 230,
		part_id: 266,
		quantity: 303,
	},
	{
		garage_id: 230,
		part_id: 267,
		quantity: 391,
	},
	{
		garage_id: 230,
		part_id: 268,
		quantity: 345,
	},
	{
		garage_id: 230,
		part_id: 269,
		quantity: 478,
	},
	{
		garage_id: 230,
		part_id: 270,
		quantity: 260,
	},
	{
		garage_id: 230,
		part_id: 271,
		quantity: 435,
	},
	{
		garage_id: 230,
		part_id: 272,
		quantity: 114,
	},
	{
		garage_id: 230,
		part_id: 273,
		quantity: 437,
	},
	{
		garage_id: 230,
		part_id: 274,
		quantity: 300,
	},
	{
		garage_id: 230,
		part_id: 275,
		quantity: 284,
	},
	{
		garage_id: 230,
		part_id: 276,
		quantity: 103,
	},
	{
		garage_id: 230,
		part_id: 277,
		quantity: 340,
	},
	{
		garage_id: 230,
		part_id: 278,
		quantity: 483,
	},
	{
		garage_id: 230,
		part_id: 279,
		quantity: 143,
	},
	{
		garage_id: 230,
		part_id: 280,
		quantity: 385,
	},
	{
		garage_id: 230,
		part_id: 281,
		quantity: 331,
	},
	{
		garage_id: 230,
		part_id: 282,
		quantity: 311,
	},
	{
		garage_id: 230,
		part_id: 283,
		quantity: 59,
	},
	{
		garage_id: 230,
		part_id: 284,
		quantity: 444,
	},
	{
		garage_id: 230,
		part_id: 285,
		quantity: 25,
	},
	{
		garage_id: 230,
		part_id: 286,
		quantity: 409,
	},
	{
		garage_id: 230,
		part_id: 287,
		quantity: 256,
	},
	{
		garage_id: 230,
		part_id: 288,
		quantity: 226,
	},
	{
		garage_id: 230,
		part_id: 289,
		quantity: 74,
	},
	{
		garage_id: 230,
		part_id: 290,
		quantity: 452,
	},
	{
		garage_id: 230,
		part_id: 291,
		quantity: 54,
	},
	{
		garage_id: 230,
		part_id: 292,
		quantity: 439,
	},
	{
		garage_id: 230,
		part_id: 293,
		quantity: 58,
	},
	{
		garage_id: 230,
		part_id: 294,
		quantity: 118,
	},
	{
		garage_id: 230,
		part_id: 295,
		quantity: 476,
	},
	{
		garage_id: 230,
		part_id: 296,
		quantity: 301,
	},
	{
		garage_id: 230,
		part_id: 297,
		quantity: 280,
	},
	{
		garage_id: 230,
		part_id: 298,
		quantity: 416,
	},
	{
		garage_id: 230,
		part_id: 299,
		quantity: 163,
	},
	{
		garage_id: 230,
		part_id: 300,
		quantity: 53,
	},
	{
		garage_id: 230,
		part_id: 301,
		quantity: 162,
	},
	{
		garage_id: 230,
		part_id: 302,
		quantity: 136,
	},
	{
		garage_id: 230,
		part_id: 303,
		quantity: 405,
	},
	{
		garage_id: 230,
		part_id: 304,
		quantity: 294,
	},
	{
		garage_id: 230,
		part_id: 305,
		quantity: 379,
	},
	{
		garage_id: 230,
		part_id: 306,
		quantity: 229,
	},
	{
		garage_id: 230,
		part_id: 307,
		quantity: 24,
	},
	{
		garage_id: 230,
		part_id: 308,
		quantity: 358,
	},
	{
		garage_id: 230,
		part_id: 309,
		quantity: 411,
	},
	{
		garage_id: 230,
		part_id: 310,
		quantity: 264,
	},
	{
		garage_id: 230,
		part_id: 311,
		quantity: 454,
	},
	{
		garage_id: 230,
		part_id: 312,
		quantity: 292,
	},
	{
		garage_id: 230,
		part_id: 313,
		quantity: 429,
	},
	{
		garage_id: 230,
		part_id: 314,
		quantity: 299,
	},
	{
		garage_id: 230,
		part_id: 315,
		quantity: 362,
	},
	{
		garage_id: 230,
		part_id: 316,
		quantity: 278,
	},
	{
		garage_id: 230,
		part_id: 317,
		quantity: 486,
	},
	{
		garage_id: 230,
		part_id: 318,
		quantity: 174,
	},
	{
		garage_id: 230,
		part_id: 319,
		quantity: 9,
	},
	{
		garage_id: 230,
		part_id: 320,
		quantity: 53,
	},
	{
		garage_id: 230,
		part_id: 321,
		quantity: 57,
	},
	{
		garage_id: 230,
		part_id: 322,
		quantity: 389,
	},
	{
		garage_id: 230,
		part_id: 323,
		quantity: 311,
	},
	{
		garage_id: 230,
		part_id: 324,
		quantity: 429,
	},
	{
		garage_id: 230,
		part_id: 325,
		quantity: 490,
	},
	{
		garage_id: 230,
		part_id: 326,
		quantity: 343,
	},
	{
		garage_id: 230,
		part_id: 327,
		quantity: 476,
	},
	{
		garage_id: 230,
		part_id: 328,
		quantity: 341,
	},
	{
		garage_id: 230,
		part_id: 329,
		quantity: 293,
	},
	{
		garage_id: 230,
		part_id: 330,
		quantity: 57,
	},
	{
		garage_id: 230,
		part_id: 331,
		quantity: 441,
	},
	{
		garage_id: 230,
		part_id: 332,
		quantity: 350,
	},
	{
		garage_id: 230,
		part_id: 333,
		quantity: 93,
	},
	{
		garage_id: 230,
		part_id: 334,
		quantity: 450,
	},
	{
		garage_id: 230,
		part_id: 335,
		quantity: 83,
	},
	{
		garage_id: 230,
		part_id: 336,
		quantity: 392,
	},
	{
		garage_id: 230,
		part_id: 337,
		quantity: 482,
	},
	{
		garage_id: 230,
		part_id: 338,
		quantity: 198,
	},
	{
		garage_id: 230,
		part_id: 339,
		quantity: 211,
	},
	{
		garage_id: 230,
		part_id: 340,
		quantity: 196,
	},
	{
		garage_id: 230,
		part_id: 341,
		quantity: 222,
	},
	{
		garage_id: 230,
		part_id: 342,
		quantity: 49,
	},
	{
		garage_id: 230,
		part_id: 343,
		quantity: 392,
	},
	{
		garage_id: 230,
		part_id: 344,
		quantity: 210,
	},
	{
		garage_id: 230,
		part_id: 345,
		quantity: 54,
	},
	{
		garage_id: 230,
		part_id: 346,
		quantity: 54,
	},
	{
		garage_id: 230,
		part_id: 347,
		quantity: 293,
	},
	{
		garage_id: 230,
		part_id: 348,
		quantity: 108,
	},
	{
		garage_id: 230,
		part_id: 349,
		quantity: 159,
	},
	{
		garage_id: 230,
		part_id: 350,
		quantity: 91,
	},
	{
		garage_id: 230,
		part_id: 351,
		quantity: 226,
	},
	{
		garage_id: 230,
		part_id: 352,
		quantity: 447,
	},
	{
		garage_id: 230,
		part_id: 353,
		quantity: 160,
	},
	{
		garage_id: 230,
		part_id: 354,
		quantity: 206,
	},
	{
		garage_id: 230,
		part_id: 355,
		quantity: 335,
	},
	{
		garage_id: 230,
		part_id: 356,
		quantity: 389,
	},
	{
		garage_id: 230,
		part_id: 357,
		quantity: 289,
	},
	{
		garage_id: 230,
		part_id: 358,
		quantity: 352,
	},
	{
		garage_id: 230,
		part_id: 359,
		quantity: 301,
	},
	{
		garage_id: 230,
		part_id: 360,
		quantity: 20,
	},
	{
		garage_id: 230,
		part_id: 361,
		quantity: 267,
	},
	{
		garage_id: 230,
		part_id: 362,
		quantity: 279,
	},
	{
		garage_id: 230,
		part_id: 363,
		quantity: 454,
	},
	{
		garage_id: 230,
		part_id: 364,
		quantity: 312,
	},
	{
		garage_id: 230,
		part_id: 365,
		quantity: 1,
	},
	{
		garage_id: 230,
		part_id: 366,
		quantity: 111,
	},
	{
		garage_id: 230,
		part_id: 367,
		quantity: 84,
	},
	{
		garage_id: 230,
		part_id: 368,
		quantity: 467,
	},
	{
		garage_id: 230,
		part_id: 369,
		quantity: 145,
	},
	{
		garage_id: 230,
		part_id: 370,
		quantity: 363,
	},
	{
		garage_id: 230,
		part_id: 371,
		quantity: 13,
	},
	{
		garage_id: 230,
		part_id: 372,
		quantity: 326,
	},
	{
		garage_id: 230,
		part_id: 373,
		quantity: 177,
	},
	{
		garage_id: 230,
		part_id: 374,
		quantity: 232,
	},
	{
		garage_id: 230,
		part_id: 375,
		quantity: 253,
	},
	{
		garage_id: 230,
		part_id: 376,
		quantity: 79,
	},
	{
		garage_id: 230,
		part_id: 377,
		quantity: 28,
	},
	{
		garage_id: 230,
		part_id: 378,
		quantity: 175,
	},
	{
		garage_id: 230,
		part_id: 379,
		quantity: 242,
	},
	{
		garage_id: 230,
		part_id: 380,
		quantity: 330,
	},
	{
		garage_id: 230,
		part_id: 381,
		quantity: 415,
	},
	{
		garage_id: 230,
		part_id: 382,
		quantity: 247,
	},
	{
		garage_id: 230,
		part_id: 383,
		quantity: 320,
	},
	{
		garage_id: 230,
		part_id: 384,
		quantity: 267,
	},
	{
		garage_id: 230,
		part_id: 385,
		quantity: 414,
	},
	{
		garage_id: 230,
		part_id: 386,
		quantity: 389,
	},
	{
		garage_id: 230,
		part_id: 387,
		quantity: 480,
	},
	{
		garage_id: 230,
		part_id: 388,
		quantity: 30,
	},
	{
		garage_id: 230,
		part_id: 389,
		quantity: 126,
	},
	{
		garage_id: 230,
		part_id: 390,
		quantity: 29,
	},
	{
		garage_id: 230,
		part_id: 391,
		quantity: 484,
	},
	{
		garage_id: 230,
		part_id: 392,
		quantity: 171,
	},
	{
		garage_id: 230,
		part_id: 393,
		quantity: 10,
	},
	{
		garage_id: 230,
		part_id: 394,
		quantity: 379,
	},
	{
		garage_id: 230,
		part_id: 395,
		quantity: 121,
	},
	{
		garage_id: 230,
		part_id: 396,
		quantity: 84,
	},
	{
		garage_id: 230,
		part_id: 397,
		quantity: 165,
	},
	{
		garage_id: 230,
		part_id: 398,
		quantity: 427,
	},
	{
		garage_id: 230,
		part_id: 399,
		quantity: 315,
	},
	{
		garage_id: 231,
		part_id: 0,
		quantity: 368,
	},
	{
		garage_id: 231,
		part_id: 1,
		quantity: 117,
	},
	{
		garage_id: 231,
		part_id: 2,
		quantity: 438,
	},
	{
		garage_id: 231,
		part_id: 3,
		quantity: 156,
	},
	{
		garage_id: 231,
		part_id: 4,
		quantity: 492,
	},
	{
		garage_id: 231,
		part_id: 5,
		quantity: 185,
	},
	{
		garage_id: 231,
		part_id: 6,
		quantity: 340,
	},
	{
		garage_id: 231,
		part_id: 7,
		quantity: 366,
	},
	{
		garage_id: 231,
		part_id: 8,
		quantity: 203,
	},
	{
		garage_id: 231,
		part_id: 9,
		quantity: 87,
	},
	{
		garage_id: 231,
		part_id: 10,
		quantity: 160,
	},
	{
		garage_id: 231,
		part_id: 11,
		quantity: 498,
	},
	{
		garage_id: 231,
		part_id: 12,
		quantity: 20,
	},
	{
		garage_id: 231,
		part_id: 13,
		quantity: 99,
	},
	{
		garage_id: 231,
		part_id: 14,
		quantity: 276,
	},
	{
		garage_id: 231,
		part_id: 15,
		quantity: 352,
	},
	{
		garage_id: 231,
		part_id: 16,
		quantity: 26,
	},
	{
		garage_id: 231,
		part_id: 17,
		quantity: 334,
	},
	{
		garage_id: 231,
		part_id: 18,
		quantity: 407,
	},
	{
		garage_id: 231,
		part_id: 19,
		quantity: 349,
	},
	{
		garage_id: 231,
		part_id: 20,
		quantity: 285,
	},
	{
		garage_id: 231,
		part_id: 21,
		quantity: 161,
	},
	{
		garage_id: 231,
		part_id: 22,
		quantity: 51,
	},
	{
		garage_id: 231,
		part_id: 23,
		quantity: 42,
	},
	{
		garage_id: 231,
		part_id: 24,
		quantity: 326,
	},
	{
		garage_id: 231,
		part_id: 25,
		quantity: 194,
	},
	{
		garage_id: 231,
		part_id: 26,
		quantity: 239,
	},
	{
		garage_id: 231,
		part_id: 27,
		quantity: 454,
	},
	{
		garage_id: 231,
		part_id: 28,
		quantity: 367,
	},
	{
		garage_id: 231,
		part_id: 29,
		quantity: 354,
	},
	{
		garage_id: 231,
		part_id: 30,
		quantity: 444,
	},
	{
		garage_id: 231,
		part_id: 31,
		quantity: 102,
	},
	{
		garage_id: 231,
		part_id: 32,
		quantity: 97,
	},
	{
		garage_id: 231,
		part_id: 33,
		quantity: 484,
	},
	{
		garage_id: 231,
		part_id: 34,
		quantity: 404,
	},
	{
		garage_id: 231,
		part_id: 35,
		quantity: 471,
	},
	{
		garage_id: 231,
		part_id: 36,
		quantity: 79,
	},
	{
		garage_id: 231,
		part_id: 37,
		quantity: 360,
	},
	{
		garage_id: 231,
		part_id: 38,
		quantity: 32,
	},
	{
		garage_id: 231,
		part_id: 39,
		quantity: 398,
	},
	{
		garage_id: 231,
		part_id: 40,
		quantity: 363,
	},
	{
		garage_id: 231,
		part_id: 41,
		quantity: 434,
	},
	{
		garage_id: 231,
		part_id: 42,
		quantity: 344,
	},
	{
		garage_id: 231,
		part_id: 43,
		quantity: 331,
	},
	{
		garage_id: 231,
		part_id: 44,
		quantity: 441,
	},
	{
		garage_id: 231,
		part_id: 45,
		quantity: 322,
	},
	{
		garage_id: 231,
		part_id: 46,
		quantity: 125,
	},
	{
		garage_id: 231,
		part_id: 47,
		quantity: 67,
	},
	{
		garage_id: 231,
		part_id: 48,
		quantity: 260,
	},
	{
		garage_id: 231,
		part_id: 49,
		quantity: 331,
	},
	{
		garage_id: 231,
		part_id: 50,
		quantity: 303,
	},
	{
		garage_id: 231,
		part_id: 51,
		quantity: 135,
	},
	{
		garage_id: 231,
		part_id: 52,
		quantity: 297,
	},
	{
		garage_id: 231,
		part_id: 53,
		quantity: 130,
	},
	{
		garage_id: 231,
		part_id: 54,
		quantity: 99,
	},
	{
		garage_id: 231,
		part_id: 55,
		quantity: 383,
	},
	{
		garage_id: 231,
		part_id: 56,
		quantity: 194,
	},
	{
		garage_id: 231,
		part_id: 57,
		quantity: 352,
	},
	{
		garage_id: 231,
		part_id: 58,
		quantity: 104,
	},
	{
		garage_id: 231,
		part_id: 59,
		quantity: 452,
	},
	{
		garage_id: 231,
		part_id: 60,
		quantity: 275,
	},
	{
		garage_id: 231,
		part_id: 61,
		quantity: 178,
	},
	{
		garage_id: 231,
		part_id: 62,
		quantity: 299,
	},
	{
		garage_id: 231,
		part_id: 63,
		quantity: 159,
	},
	{
		garage_id: 231,
		part_id: 64,
		quantity: 65,
	},
	{
		garage_id: 231,
		part_id: 65,
		quantity: 407,
	},
	{
		garage_id: 231,
		part_id: 66,
		quantity: 333,
	},
	{
		garage_id: 231,
		part_id: 67,
		quantity: 326,
	},
	{
		garage_id: 231,
		part_id: 68,
		quantity: 149,
	},
	{
		garage_id: 231,
		part_id: 69,
		quantity: 493,
	},
	{
		garage_id: 231,
		part_id: 70,
		quantity: 405,
	},
	{
		garage_id: 231,
		part_id: 71,
		quantity: 57,
	},
	{
		garage_id: 231,
		part_id: 72,
		quantity: 493,
	},
	{
		garage_id: 231,
		part_id: 73,
		quantity: 323,
	},
	{
		garage_id: 231,
		part_id: 74,
		quantity: 273,
	},
	{
		garage_id: 231,
		part_id: 75,
		quantity: 102,
	},
	{
		garage_id: 231,
		part_id: 76,
		quantity: 283,
	},
	{
		garage_id: 231,
		part_id: 77,
		quantity: 56,
	},
	{
		garage_id: 231,
		part_id: 78,
		quantity: 138,
	},
	{
		garage_id: 231,
		part_id: 79,
		quantity: 109,
	},
	{
		garage_id: 231,
		part_id: 80,
		quantity: 266,
	},
	{
		garage_id: 231,
		part_id: 81,
		quantity: 128,
	},
	{
		garage_id: 231,
		part_id: 82,
		quantity: 249,
	},
	{
		garage_id: 231,
		part_id: 83,
		quantity: 140,
	},
	{
		garage_id: 231,
		part_id: 84,
		quantity: 1,
	},
	{
		garage_id: 231,
		part_id: 85,
		quantity: 302,
	},
	{
		garage_id: 231,
		part_id: 86,
		quantity: 372,
	},
	{
		garage_id: 231,
		part_id: 87,
		quantity: 444,
	},
	{
		garage_id: 231,
		part_id: 88,
		quantity: 215,
	},
	{
		garage_id: 231,
		part_id: 89,
		quantity: 23,
	},
	{
		garage_id: 231,
		part_id: 90,
		quantity: 50,
	},
	{
		garage_id: 231,
		part_id: 91,
		quantity: 487,
	},
	{
		garage_id: 231,
		part_id: 92,
		quantity: 293,
	},
	{
		garage_id: 231,
		part_id: 93,
		quantity: 371,
	},
	{
		garage_id: 231,
		part_id: 94,
		quantity: 198,
	},
	{
		garage_id: 231,
		part_id: 95,
		quantity: 210,
	},
	{
		garage_id: 231,
		part_id: 96,
		quantity: 102,
	},
	{
		garage_id: 231,
		part_id: 97,
		quantity: 408,
	},
	{
		garage_id: 231,
		part_id: 98,
		quantity: 62,
	},
	{
		garage_id: 231,
		part_id: 99,
		quantity: 287,
	},
	{
		garage_id: 231,
		part_id: 100,
		quantity: 425,
	},
	{
		garage_id: 231,
		part_id: 101,
		quantity: 122,
	},
	{
		garage_id: 231,
		part_id: 102,
		quantity: 202,
	},
	{
		garage_id: 231,
		part_id: 103,
		quantity: 147,
	},
	{
		garage_id: 231,
		part_id: 104,
		quantity: 232,
	},
	{
		garage_id: 231,
		part_id: 105,
		quantity: 132,
	},
	{
		garage_id: 231,
		part_id: 106,
		quantity: 379,
	},
	{
		garage_id: 231,
		part_id: 107,
		quantity: 210,
	},
	{
		garage_id: 231,
		part_id: 108,
		quantity: 467,
	},
	{
		garage_id: 231,
		part_id: 109,
		quantity: 90,
	},
	{
		garage_id: 231,
		part_id: 110,
		quantity: 63,
	},
	{
		garage_id: 231,
		part_id: 111,
		quantity: 494,
	},
	{
		garage_id: 231,
		part_id: 112,
		quantity: 382,
	},
	{
		garage_id: 231,
		part_id: 113,
		quantity: 385,
	},
	{
		garage_id: 231,
		part_id: 114,
		quantity: 312,
	},
	{
		garage_id: 231,
		part_id: 115,
		quantity: 274,
	},
	{
		garage_id: 231,
		part_id: 116,
		quantity: 85,
	},
	{
		garage_id: 231,
		part_id: 117,
		quantity: 286,
	},
	{
		garage_id: 231,
		part_id: 118,
		quantity: 329,
	},
	{
		garage_id: 231,
		part_id: 119,
		quantity: 406,
	},
	{
		garage_id: 231,
		part_id: 120,
		quantity: 224,
	},
	{
		garage_id: 231,
		part_id: 121,
		quantity: 53,
	},
	{
		garage_id: 231,
		part_id: 122,
		quantity: 416,
	},
	{
		garage_id: 231,
		part_id: 123,
		quantity: 303,
	},
	{
		garage_id: 231,
		part_id: 124,
		quantity: 306,
	},
	{
		garage_id: 231,
		part_id: 125,
		quantity: 464,
	},
	{
		garage_id: 231,
		part_id: 126,
		quantity: 439,
	},
	{
		garage_id: 231,
		part_id: 127,
		quantity: 159,
	},
	{
		garage_id: 231,
		part_id: 128,
		quantity: 12,
	},
	{
		garage_id: 231,
		part_id: 129,
		quantity: 332,
	},
	{
		garage_id: 231,
		part_id: 130,
		quantity: 315,
	},
	{
		garage_id: 231,
		part_id: 131,
		quantity: 62,
	},
	{
		garage_id: 231,
		part_id: 132,
		quantity: 29,
	},
	{
		garage_id: 231,
		part_id: 133,
		quantity: 198,
	},
	{
		garage_id: 231,
		part_id: 134,
		quantity: 54,
	},
	{
		garage_id: 231,
		part_id: 135,
		quantity: 168,
	},
	{
		garage_id: 231,
		part_id: 136,
		quantity: 500,
	},
	{
		garage_id: 231,
		part_id: 137,
		quantity: 214,
	},
	{
		garage_id: 231,
		part_id: 138,
		quantity: 55,
	},
	{
		garage_id: 231,
		part_id: 139,
		quantity: 419,
	},
	{
		garage_id: 231,
		part_id: 140,
		quantity: 125,
	},
	{
		garage_id: 231,
		part_id: 141,
		quantity: 140,
	},
	{
		garage_id: 231,
		part_id: 142,
		quantity: 106,
	},
	{
		garage_id: 231,
		part_id: 143,
		quantity: 393,
	},
	{
		garage_id: 231,
		part_id: 144,
		quantity: 70,
	},
	{
		garage_id: 231,
		part_id: 145,
		quantity: 172,
	},
	{
		garage_id: 231,
		part_id: 146,
		quantity: 262,
	},
	{
		garage_id: 231,
		part_id: 147,
		quantity: 276,
	},
	{
		garage_id: 231,
		part_id: 148,
		quantity: 29,
	},
	{
		garage_id: 231,
		part_id: 149,
		quantity: 105,
	},
	{
		garage_id: 231,
		part_id: 150,
		quantity: 73,
	},
	{
		garage_id: 231,
		part_id: 151,
		quantity: 114,
	},
	{
		garage_id: 231,
		part_id: 152,
		quantity: 77,
	},
	{
		garage_id: 231,
		part_id: 153,
		quantity: 470,
	},
	{
		garage_id: 231,
		part_id: 154,
		quantity: 190,
	},
	{
		garage_id: 231,
		part_id: 155,
		quantity: 372,
	},
	{
		garage_id: 231,
		part_id: 156,
		quantity: 12,
	},
	{
		garage_id: 231,
		part_id: 157,
		quantity: 213,
	},
	{
		garage_id: 231,
		part_id: 158,
		quantity: 174,
	},
	{
		garage_id: 231,
		part_id: 159,
		quantity: 93,
	},
	{
		garage_id: 231,
		part_id: 160,
		quantity: 455,
	},
	{
		garage_id: 231,
		part_id: 161,
		quantity: 82,
	},
	{
		garage_id: 231,
		part_id: 162,
		quantity: 31,
	},
	{
		garage_id: 231,
		part_id: 163,
		quantity: 411,
	},
	{
		garage_id: 231,
		part_id: 164,
		quantity: 35,
	},
	{
		garage_id: 231,
		part_id: 165,
		quantity: 401,
	},
	{
		garage_id: 231,
		part_id: 166,
		quantity: 376,
	},
	{
		garage_id: 231,
		part_id: 167,
		quantity: 497,
	},
	{
		garage_id: 231,
		part_id: 168,
		quantity: 407,
	},
	{
		garage_id: 231,
		part_id: 169,
		quantity: 416,
	},
	{
		garage_id: 231,
		part_id: 170,
		quantity: 262,
	},
	{
		garage_id: 231,
		part_id: 171,
		quantity: 335,
	},
	{
		garage_id: 231,
		part_id: 172,
		quantity: 291,
	},
	{
		garage_id: 231,
		part_id: 173,
		quantity: 343,
	},
	{
		garage_id: 231,
		part_id: 174,
		quantity: 87,
	},
	{
		garage_id: 231,
		part_id: 175,
		quantity: 344,
	},
	{
		garage_id: 231,
		part_id: 176,
		quantity: 270,
	},
	{
		garage_id: 231,
		part_id: 177,
		quantity: 61,
	},
	{
		garage_id: 231,
		part_id: 178,
		quantity: 35,
	},
	{
		garage_id: 231,
		part_id: 179,
		quantity: 481,
	},
	{
		garage_id: 231,
		part_id: 180,
		quantity: 46,
	},
	{
		garage_id: 231,
		part_id: 181,
		quantity: 185,
	},
	{
		garage_id: 231,
		part_id: 182,
		quantity: 219,
	},
	{
		garage_id: 231,
		part_id: 183,
		quantity: 252,
	},
	{
		garage_id: 231,
		part_id: 184,
		quantity: 221,
	},
	{
		garage_id: 231,
		part_id: 185,
		quantity: 432,
	},
	{
		garage_id: 231,
		part_id: 186,
		quantity: 415,
	},
	{
		garage_id: 231,
		part_id: 187,
		quantity: 215,
	},
	{
		garage_id: 231,
		part_id: 188,
		quantity: 478,
	},
	{
		garage_id: 231,
		part_id: 189,
		quantity: 256,
	},
	{
		garage_id: 231,
		part_id: 190,
		quantity: 114,
	},
	{
		garage_id: 231,
		part_id: 191,
		quantity: 444,
	},
	{
		garage_id: 231,
		part_id: 192,
		quantity: 31,
	},
	{
		garage_id: 231,
		part_id: 193,
		quantity: 476,
	},
	{
		garage_id: 231,
		part_id: 194,
		quantity: 299,
	},
	{
		garage_id: 231,
		part_id: 195,
		quantity: 415,
	},
	{
		garage_id: 231,
		part_id: 196,
		quantity: 319,
	},
	{
		garage_id: 231,
		part_id: 197,
		quantity: 372,
	},
	{
		garage_id: 231,
		part_id: 198,
		quantity: 71,
	},
	{
		garage_id: 231,
		part_id: 199,
		quantity: 422,
	},
	{
		garage_id: 231,
		part_id: 200,
		quantity: 166,
	},
	{
		garage_id: 231,
		part_id: 201,
		quantity: 252,
	},
	{
		garage_id: 231,
		part_id: 202,
		quantity: 311,
	},
	{
		garage_id: 231,
		part_id: 203,
		quantity: 386,
	},
	{
		garage_id: 231,
		part_id: 204,
		quantity: 375,
	},
	{
		garage_id: 231,
		part_id: 205,
		quantity: 451,
	},
	{
		garage_id: 231,
		part_id: 206,
		quantity: 309,
	},
	{
		garage_id: 231,
		part_id: 207,
		quantity: 430,
	},
	{
		garage_id: 231,
		part_id: 208,
		quantity: 227,
	},
	{
		garage_id: 231,
		part_id: 209,
		quantity: 77,
	},
	{
		garage_id: 231,
		part_id: 210,
		quantity: 240,
	},
	{
		garage_id: 231,
		part_id: 211,
		quantity: 426,
	},
	{
		garage_id: 231,
		part_id: 212,
		quantity: 65,
	},
	{
		garage_id: 231,
		part_id: 213,
		quantity: 204,
	},
	{
		garage_id: 231,
		part_id: 214,
		quantity: 94,
	},
	{
		garage_id: 231,
		part_id: 215,
		quantity: 116,
	},
	{
		garage_id: 231,
		part_id: 216,
		quantity: 346,
	},
	{
		garage_id: 231,
		part_id: 217,
		quantity: 369,
	},
	{
		garage_id: 231,
		part_id: 218,
		quantity: 490,
	},
	{
		garage_id: 231,
		part_id: 219,
		quantity: 71,
	},
	{
		garage_id: 231,
		part_id: 220,
		quantity: 54,
	},
	{
		garage_id: 231,
		part_id: 221,
		quantity: 302,
	},
	{
		garage_id: 231,
		part_id: 222,
		quantity: 484,
	},
	{
		garage_id: 231,
		part_id: 223,
		quantity: 476,
	},
	{
		garage_id: 231,
		part_id: 224,
		quantity: 425,
	},
	{
		garage_id: 231,
		part_id: 225,
		quantity: 377,
	},
	{
		garage_id: 231,
		part_id: 226,
		quantity: 490,
	},
	{
		garage_id: 231,
		part_id: 227,
		quantity: 323,
	},
	{
		garage_id: 231,
		part_id: 228,
		quantity: 371,
	},
	{
		garage_id: 231,
		part_id: 229,
		quantity: 382,
	},
	{
		garage_id: 231,
		part_id: 230,
		quantity: 167,
	},
	{
		garage_id: 231,
		part_id: 231,
		quantity: 117,
	},
	{
		garage_id: 231,
		part_id: 232,
		quantity: 390,
	},
	{
		garage_id: 231,
		part_id: 233,
		quantity: 457,
	},
	{
		garage_id: 231,
		part_id: 234,
		quantity: 258,
	},
	{
		garage_id: 231,
		part_id: 235,
		quantity: 21,
	},
	{
		garage_id: 231,
		part_id: 236,
		quantity: 371,
	},
	{
		garage_id: 231,
		part_id: 237,
		quantity: 432,
	},
	{
		garage_id: 231,
		part_id: 238,
		quantity: 286,
	},
	{
		garage_id: 231,
		part_id: 239,
		quantity: 309,
	},
	{
		garage_id: 231,
		part_id: 240,
		quantity: 364,
	},
	{
		garage_id: 231,
		part_id: 241,
		quantity: 438,
	},
	{
		garage_id: 231,
		part_id: 242,
		quantity: 86,
	},
	{
		garage_id: 231,
		part_id: 243,
		quantity: 2,
	},
	{
		garage_id: 231,
		part_id: 244,
		quantity: 134,
	},
	{
		garage_id: 231,
		part_id: 245,
		quantity: 119,
	},
	{
		garage_id: 231,
		part_id: 246,
		quantity: 108,
	},
	{
		garage_id: 231,
		part_id: 247,
		quantity: 258,
	},
	{
		garage_id: 231,
		part_id: 248,
		quantity: 347,
	},
	{
		garage_id: 231,
		part_id: 249,
		quantity: 68,
	},
	{
		garage_id: 231,
		part_id: 250,
		quantity: 157,
	},
	{
		garage_id: 231,
		part_id: 251,
		quantity: 255,
	},
	{
		garage_id: 231,
		part_id: 252,
		quantity: 193,
	},
	{
		garage_id: 231,
		part_id: 253,
		quantity: 421,
	},
	{
		garage_id: 231,
		part_id: 254,
		quantity: 406,
	},
	{
		garage_id: 231,
		part_id: 255,
		quantity: 302,
	},
	{
		garage_id: 231,
		part_id: 256,
		quantity: 273,
	},
	{
		garage_id: 231,
		part_id: 257,
		quantity: 306,
	},
	{
		garage_id: 231,
		part_id: 258,
		quantity: 235,
	},
	{
		garage_id: 231,
		part_id: 259,
		quantity: 445,
	},
	{
		garage_id: 231,
		part_id: 260,
		quantity: 155,
	},
	{
		garage_id: 231,
		part_id: 261,
		quantity: 63,
	},
	{
		garage_id: 231,
		part_id: 262,
		quantity: 103,
	},
	{
		garage_id: 231,
		part_id: 263,
		quantity: 420,
	},
	{
		garage_id: 231,
		part_id: 264,
		quantity: 301,
	},
	{
		garage_id: 231,
		part_id: 265,
		quantity: 207,
	},
	{
		garage_id: 231,
		part_id: 266,
		quantity: 158,
	},
	{
		garage_id: 231,
		part_id: 267,
		quantity: 116,
	},
	{
		garage_id: 231,
		part_id: 268,
		quantity: 305,
	},
	{
		garage_id: 231,
		part_id: 269,
		quantity: 409,
	},
	{
		garage_id: 231,
		part_id: 270,
		quantity: 232,
	},
	{
		garage_id: 231,
		part_id: 271,
		quantity: 240,
	},
	{
		garage_id: 231,
		part_id: 272,
		quantity: 51,
	},
	{
		garage_id: 231,
		part_id: 273,
		quantity: 242,
	},
	{
		garage_id: 231,
		part_id: 274,
		quantity: 15,
	},
	{
		garage_id: 231,
		part_id: 275,
		quantity: 16,
	},
	{
		garage_id: 231,
		part_id: 276,
		quantity: 111,
	},
	{
		garage_id: 231,
		part_id: 277,
		quantity: 450,
	},
	{
		garage_id: 231,
		part_id: 278,
		quantity: 36,
	},
	{
		garage_id: 231,
		part_id: 279,
		quantity: 43,
	},
	{
		garage_id: 231,
		part_id: 280,
		quantity: 299,
	},
	{
		garage_id: 231,
		part_id: 281,
		quantity: 415,
	},
	{
		garage_id: 231,
		part_id: 282,
		quantity: 153,
	},
	{
		garage_id: 231,
		part_id: 283,
		quantity: 5,
	},
	{
		garage_id: 231,
		part_id: 284,
		quantity: 139,
	},
	{
		garage_id: 231,
		part_id: 285,
		quantity: 33,
	},
	{
		garage_id: 231,
		part_id: 286,
		quantity: 189,
	},
	{
		garage_id: 231,
		part_id: 287,
		quantity: 327,
	},
	{
		garage_id: 231,
		part_id: 288,
		quantity: 28,
	},
	{
		garage_id: 231,
		part_id: 289,
		quantity: 122,
	},
	{
		garage_id: 231,
		part_id: 290,
		quantity: 435,
	},
	{
		garage_id: 231,
		part_id: 291,
		quantity: 358,
	},
	{
		garage_id: 231,
		part_id: 292,
		quantity: 389,
	},
	{
		garage_id: 231,
		part_id: 293,
		quantity: 275,
	},
	{
		garage_id: 231,
		part_id: 294,
		quantity: 60,
	},
	{
		garage_id: 231,
		part_id: 295,
		quantity: 93,
	},
	{
		garage_id: 231,
		part_id: 296,
		quantity: 460,
	},
	{
		garage_id: 231,
		part_id: 297,
		quantity: 338,
	},
	{
		garage_id: 231,
		part_id: 298,
		quantity: 161,
	},
	{
		garage_id: 231,
		part_id: 299,
		quantity: 61,
	},
	{
		garage_id: 231,
		part_id: 300,
		quantity: 44,
	},
	{
		garage_id: 231,
		part_id: 301,
		quantity: 200,
	},
	{
		garage_id: 231,
		part_id: 302,
		quantity: 157,
	},
	{
		garage_id: 231,
		part_id: 303,
		quantity: 24,
	},
	{
		garage_id: 231,
		part_id: 304,
		quantity: 157,
	},
	{
		garage_id: 231,
		part_id: 305,
		quantity: 468,
	},
	{
		garage_id: 231,
		part_id: 306,
		quantity: 256,
	},
	{
		garage_id: 231,
		part_id: 307,
		quantity: 300,
	},
	{
		garage_id: 231,
		part_id: 308,
		quantity: 218,
	},
	{
		garage_id: 231,
		part_id: 309,
		quantity: 62,
	},
	{
		garage_id: 231,
		part_id: 310,
		quantity: 266,
	},
	{
		garage_id: 231,
		part_id: 311,
		quantity: 342,
	},
	{
		garage_id: 231,
		part_id: 312,
		quantity: 483,
	},
	{
		garage_id: 231,
		part_id: 313,
		quantity: 399,
	},
	{
		garage_id: 231,
		part_id: 314,
		quantity: 297,
	},
	{
		garage_id: 231,
		part_id: 315,
		quantity: 104,
	},
	{
		garage_id: 231,
		part_id: 316,
		quantity: 142,
	},
	{
		garage_id: 231,
		part_id: 317,
		quantity: 325,
	},
	{
		garage_id: 231,
		part_id: 318,
		quantity: 449,
	},
	{
		garage_id: 231,
		part_id: 319,
		quantity: 293,
	},
	{
		garage_id: 231,
		part_id: 320,
		quantity: 282,
	},
	{
		garage_id: 231,
		part_id: 321,
		quantity: 408,
	},
	{
		garage_id: 231,
		part_id: 322,
		quantity: 41,
	},
	{
		garage_id: 231,
		part_id: 323,
		quantity: 456,
	},
	{
		garage_id: 231,
		part_id: 324,
		quantity: 283,
	},
	{
		garage_id: 231,
		part_id: 325,
		quantity: 145,
	},
	{
		garage_id: 231,
		part_id: 326,
		quantity: 185,
	},
	{
		garage_id: 231,
		part_id: 327,
		quantity: 116,
	},
	{
		garage_id: 231,
		part_id: 328,
		quantity: 189,
	},
	{
		garage_id: 231,
		part_id: 329,
		quantity: 71,
	},
	{
		garage_id: 231,
		part_id: 330,
		quantity: 126,
	},
	{
		garage_id: 231,
		part_id: 331,
		quantity: 193,
	},
	{
		garage_id: 231,
		part_id: 332,
		quantity: 373,
	},
	{
		garage_id: 231,
		part_id: 333,
		quantity: 366,
	},
	{
		garage_id: 231,
		part_id: 334,
		quantity: 359,
	},
	{
		garage_id: 231,
		part_id: 335,
		quantity: 416,
	},
	{
		garage_id: 231,
		part_id: 336,
		quantity: 456,
	},
	{
		garage_id: 231,
		part_id: 337,
		quantity: 21,
	},
	{
		garage_id: 231,
		part_id: 338,
		quantity: 181,
	},
	{
		garage_id: 231,
		part_id: 339,
		quantity: 407,
	},
	{
		garage_id: 231,
		part_id: 340,
		quantity: 248,
	},
	{
		garage_id: 231,
		part_id: 341,
		quantity: 127,
	},
	{
		garage_id: 231,
		part_id: 342,
		quantity: 198,
	},
	{
		garage_id: 231,
		part_id: 343,
		quantity: 54,
	},
	{
		garage_id: 231,
		part_id: 344,
		quantity: 33,
	},
	{
		garage_id: 231,
		part_id: 345,
		quantity: 259,
	},
	{
		garage_id: 231,
		part_id: 346,
		quantity: 330,
	},
	{
		garage_id: 231,
		part_id: 347,
		quantity: 418,
	},
	{
		garage_id: 231,
		part_id: 348,
		quantity: 490,
	},
	{
		garage_id: 231,
		part_id: 349,
		quantity: 45,
	},
	{
		garage_id: 231,
		part_id: 350,
		quantity: 289,
	},
	{
		garage_id: 231,
		part_id: 351,
		quantity: 353,
	},
	{
		garage_id: 231,
		part_id: 352,
		quantity: 62,
	},
	{
		garage_id: 231,
		part_id: 353,
		quantity: 8,
	},
	{
		garage_id: 231,
		part_id: 354,
		quantity: 154,
	},
	{
		garage_id: 231,
		part_id: 355,
		quantity: 396,
	},
	{
		garage_id: 231,
		part_id: 356,
		quantity: 483,
	},
	{
		garage_id: 231,
		part_id: 357,
		quantity: 231,
	},
	{
		garage_id: 231,
		part_id: 358,
		quantity: 197,
	},
	{
		garage_id: 231,
		part_id: 359,
		quantity: 320,
	},
	{
		garage_id: 231,
		part_id: 360,
		quantity: 125,
	},
	{
		garage_id: 231,
		part_id: 361,
		quantity: 340,
	},
	{
		garage_id: 231,
		part_id: 362,
		quantity: 185,
	},
	{
		garage_id: 231,
		part_id: 363,
		quantity: 270,
	},
	{
		garage_id: 231,
		part_id: 364,
		quantity: 489,
	},
	{
		garage_id: 231,
		part_id: 365,
		quantity: 256,
	},
	{
		garage_id: 231,
		part_id: 366,
		quantity: 149,
	},
	{
		garage_id: 231,
		part_id: 367,
		quantity: 86,
	},
	{
		garage_id: 231,
		part_id: 368,
		quantity: 338,
	},
	{
		garage_id: 231,
		part_id: 369,
		quantity: 215,
	},
	{
		garage_id: 231,
		part_id: 370,
		quantity: 41,
	},
	{
		garage_id: 231,
		part_id: 371,
		quantity: 475,
	},
	{
		garage_id: 231,
		part_id: 372,
		quantity: 256,
	},
	{
		garage_id: 231,
		part_id: 373,
		quantity: 370,
	},
	{
		garage_id: 231,
		part_id: 374,
		quantity: 232,
	},
	{
		garage_id: 231,
		part_id: 375,
		quantity: 498,
	},
	{
		garage_id: 231,
		part_id: 376,
		quantity: 339,
	},
	{
		garage_id: 231,
		part_id: 377,
		quantity: 242,
	},
	{
		garage_id: 231,
		part_id: 378,
		quantity: 496,
	},
	{
		garage_id: 231,
		part_id: 379,
		quantity: 405,
	},
	{
		garage_id: 231,
		part_id: 380,
		quantity: 335,
	},
	{
		garage_id: 231,
		part_id: 381,
		quantity: 496,
	},
	{
		garage_id: 231,
		part_id: 382,
		quantity: 371,
	},
	{
		garage_id: 231,
		part_id: 383,
		quantity: 386,
	},
	{
		garage_id: 231,
		part_id: 384,
		quantity: 190,
	},
	{
		garage_id: 231,
		part_id: 385,
		quantity: 68,
	},
	{
		garage_id: 231,
		part_id: 386,
		quantity: 369,
	},
	{
		garage_id: 231,
		part_id: 387,
		quantity: 138,
	},
	{
		garage_id: 231,
		part_id: 388,
		quantity: 195,
	},
	{
		garage_id: 231,
		part_id: 389,
		quantity: 251,
	},
	{
		garage_id: 231,
		part_id: 390,
		quantity: 141,
	},
	{
		garage_id: 231,
		part_id: 391,
		quantity: 355,
	},
	{
		garage_id: 231,
		part_id: 392,
		quantity: 401,
	},
	{
		garage_id: 231,
		part_id: 393,
		quantity: 209,
	},
	{
		garage_id: 231,
		part_id: 394,
		quantity: 248,
	},
	{
		garage_id: 231,
		part_id: 395,
		quantity: 350,
	},
	{
		garage_id: 231,
		part_id: 396,
		quantity: 139,
	},
	{
		garage_id: 231,
		part_id: 397,
		quantity: 323,
	},
	{
		garage_id: 231,
		part_id: 398,
		quantity: 17,
	},
	{
		garage_id: 231,
		part_id: 399,
		quantity: 280,
	},
	{
		garage_id: 232,
		part_id: 0,
		quantity: 451,
	},
	{
		garage_id: 232,
		part_id: 1,
		quantity: 493,
	},
	{
		garage_id: 232,
		part_id: 2,
		quantity: 480,
	},
	{
		garage_id: 232,
		part_id: 3,
		quantity: 159,
	},
	{
		garage_id: 232,
		part_id: 4,
		quantity: 319,
	},
	{
		garage_id: 232,
		part_id: 5,
		quantity: 480,
	},
	{
		garage_id: 232,
		part_id: 6,
		quantity: 201,
	},
	{
		garage_id: 232,
		part_id: 7,
		quantity: 160,
	},
	{
		garage_id: 232,
		part_id: 8,
		quantity: 327,
	},
	{
		garage_id: 232,
		part_id: 9,
		quantity: 260,
	},
	{
		garage_id: 232,
		part_id: 10,
		quantity: 351,
	},
	{
		garage_id: 232,
		part_id: 11,
		quantity: 194,
	},
	{
		garage_id: 232,
		part_id: 12,
		quantity: 138,
	},
	{
		garage_id: 232,
		part_id: 13,
		quantity: 441,
	},
	{
		garage_id: 232,
		part_id: 14,
		quantity: 241,
	},
	{
		garage_id: 232,
		part_id: 15,
		quantity: 454,
	},
	{
		garage_id: 232,
		part_id: 16,
		quantity: 169,
	},
	{
		garage_id: 232,
		part_id: 17,
		quantity: 264,
	},
	{
		garage_id: 232,
		part_id: 18,
		quantity: 413,
	},
	{
		garage_id: 232,
		part_id: 19,
		quantity: 12,
	},
	{
		garage_id: 232,
		part_id: 20,
		quantity: 20,
	},
	{
		garage_id: 232,
		part_id: 21,
		quantity: 334,
	},
	{
		garage_id: 232,
		part_id: 22,
		quantity: 488,
	},
	{
		garage_id: 232,
		part_id: 23,
		quantity: 82,
	},
	{
		garage_id: 232,
		part_id: 24,
		quantity: 195,
	},
	{
		garage_id: 232,
		part_id: 25,
		quantity: 478,
	},
	{
		garage_id: 232,
		part_id: 26,
		quantity: 435,
	},
	{
		garage_id: 232,
		part_id: 27,
		quantity: 339,
	},
	{
		garage_id: 232,
		part_id: 28,
		quantity: 459,
	},
	{
		garage_id: 232,
		part_id: 29,
		quantity: 167,
	},
	{
		garage_id: 232,
		part_id: 30,
		quantity: 465,
	},
	{
		garage_id: 232,
		part_id: 31,
		quantity: 305,
	},
	{
		garage_id: 232,
		part_id: 32,
		quantity: 359,
	},
	{
		garage_id: 232,
		part_id: 33,
		quantity: 222,
	},
	{
		garage_id: 232,
		part_id: 34,
		quantity: 484,
	},
	{
		garage_id: 232,
		part_id: 35,
		quantity: 359,
	},
	{
		garage_id: 232,
		part_id: 36,
		quantity: 273,
	},
	{
		garage_id: 232,
		part_id: 37,
		quantity: 149,
	},
	{
		garage_id: 232,
		part_id: 38,
		quantity: 300,
	},
	{
		garage_id: 232,
		part_id: 39,
		quantity: 25,
	},
	{
		garage_id: 232,
		part_id: 40,
		quantity: 43,
	},
	{
		garage_id: 232,
		part_id: 41,
		quantity: 18,
	},
	{
		garage_id: 232,
		part_id: 42,
		quantity: 24,
	},
	{
		garage_id: 232,
		part_id: 43,
		quantity: 476,
	},
	{
		garage_id: 232,
		part_id: 44,
		quantity: 332,
	},
	{
		garage_id: 232,
		part_id: 45,
		quantity: 469,
	},
	{
		garage_id: 232,
		part_id: 46,
		quantity: 190,
	},
	{
		garage_id: 232,
		part_id: 47,
		quantity: 186,
	},
	{
		garage_id: 232,
		part_id: 48,
		quantity: 267,
	},
	{
		garage_id: 232,
		part_id: 49,
		quantity: 64,
	},
	{
		garage_id: 232,
		part_id: 50,
		quantity: 331,
	},
	{
		garage_id: 232,
		part_id: 51,
		quantity: 120,
	},
	{
		garage_id: 232,
		part_id: 52,
		quantity: 327,
	},
	{
		garage_id: 232,
		part_id: 53,
		quantity: 449,
	},
	{
		garage_id: 232,
		part_id: 54,
		quantity: 269,
	},
	{
		garage_id: 232,
		part_id: 55,
		quantity: 500,
	},
	{
		garage_id: 232,
		part_id: 56,
		quantity: 186,
	},
	{
		garage_id: 232,
		part_id: 57,
		quantity: 497,
	},
	{
		garage_id: 232,
		part_id: 58,
		quantity: 424,
	},
	{
		garage_id: 232,
		part_id: 59,
		quantity: 52,
	},
	{
		garage_id: 232,
		part_id: 60,
		quantity: 222,
	},
	{
		garage_id: 232,
		part_id: 61,
		quantity: 110,
	},
	{
		garage_id: 232,
		part_id: 62,
		quantity: 476,
	},
	{
		garage_id: 232,
		part_id: 63,
		quantity: 276,
	},
	{
		garage_id: 232,
		part_id: 64,
		quantity: 164,
	},
	{
		garage_id: 232,
		part_id: 65,
		quantity: 69,
	},
	{
		garage_id: 232,
		part_id: 66,
		quantity: 204,
	},
	{
		garage_id: 232,
		part_id: 67,
		quantity: 365,
	},
	{
		garage_id: 232,
		part_id: 68,
		quantity: 46,
	},
	{
		garage_id: 232,
		part_id: 69,
		quantity: 295,
	},
	{
		garage_id: 232,
		part_id: 70,
		quantity: 455,
	},
	{
		garage_id: 232,
		part_id: 71,
		quantity: 105,
	},
	{
		garage_id: 232,
		part_id: 72,
		quantity: 245,
	},
	{
		garage_id: 232,
		part_id: 73,
		quantity: 434,
	},
	{
		garage_id: 232,
		part_id: 74,
		quantity: 378,
	},
	{
		garage_id: 232,
		part_id: 75,
		quantity: 349,
	},
	{
		garage_id: 232,
		part_id: 76,
		quantity: 409,
	},
	{
		garage_id: 232,
		part_id: 77,
		quantity: 20,
	},
	{
		garage_id: 232,
		part_id: 78,
		quantity: 326,
	},
	{
		garage_id: 232,
		part_id: 79,
		quantity: 175,
	},
	{
		garage_id: 232,
		part_id: 80,
		quantity: 218,
	},
	{
		garage_id: 232,
		part_id: 81,
		quantity: 351,
	},
	{
		garage_id: 232,
		part_id: 82,
		quantity: 201,
	},
	{
		garage_id: 232,
		part_id: 83,
		quantity: 388,
	},
	{
		garage_id: 232,
		part_id: 84,
		quantity: 38,
	},
	{
		garage_id: 232,
		part_id: 85,
		quantity: 290,
	},
	{
		garage_id: 232,
		part_id: 86,
		quantity: 176,
	},
	{
		garage_id: 232,
		part_id: 87,
		quantity: 13,
	},
	{
		garage_id: 232,
		part_id: 88,
		quantity: 17,
	},
	{
		garage_id: 232,
		part_id: 89,
		quantity: 268,
	},
	{
		garage_id: 232,
		part_id: 90,
		quantity: 43,
	},
	{
		garage_id: 232,
		part_id: 91,
		quantity: 55,
	},
	{
		garage_id: 232,
		part_id: 92,
		quantity: 176,
	},
	{
		garage_id: 232,
		part_id: 93,
		quantity: 150,
	},
	{
		garage_id: 232,
		part_id: 94,
		quantity: 414,
	},
	{
		garage_id: 232,
		part_id: 95,
		quantity: 70,
	},
	{
		garage_id: 232,
		part_id: 96,
		quantity: 258,
	},
	{
		garage_id: 232,
		part_id: 97,
		quantity: 165,
	},
	{
		garage_id: 232,
		part_id: 98,
		quantity: 101,
	},
	{
		garage_id: 232,
		part_id: 99,
		quantity: 304,
	},
	{
		garage_id: 232,
		part_id: 100,
		quantity: 363,
	},
	{
		garage_id: 232,
		part_id: 101,
		quantity: 1,
	},
	{
		garage_id: 232,
		part_id: 102,
		quantity: 325,
	},
	{
		garage_id: 232,
		part_id: 103,
		quantity: 206,
	},
	{
		garage_id: 232,
		part_id: 104,
		quantity: 164,
	},
	{
		garage_id: 232,
		part_id: 105,
		quantity: 325,
	},
	{
		garage_id: 232,
		part_id: 106,
		quantity: 429,
	},
	{
		garage_id: 232,
		part_id: 107,
		quantity: 413,
	},
	{
		garage_id: 232,
		part_id: 108,
		quantity: 483,
	},
	{
		garage_id: 232,
		part_id: 109,
		quantity: 197,
	},
	{
		garage_id: 232,
		part_id: 110,
		quantity: 415,
	},
	{
		garage_id: 232,
		part_id: 111,
		quantity: 166,
	},
	{
		garage_id: 232,
		part_id: 112,
		quantity: 313,
	},
	{
		garage_id: 232,
		part_id: 113,
		quantity: 30,
	},
	{
		garage_id: 232,
		part_id: 114,
		quantity: 164,
	},
	{
		garage_id: 232,
		part_id: 115,
		quantity: 192,
	},
	{
		garage_id: 232,
		part_id: 116,
		quantity: 255,
	},
	{
		garage_id: 232,
		part_id: 117,
		quantity: 47,
	},
	{
		garage_id: 232,
		part_id: 118,
		quantity: 451,
	},
	{
		garage_id: 232,
		part_id: 119,
		quantity: 480,
	},
	{
		garage_id: 232,
		part_id: 120,
		quantity: 483,
	},
	{
		garage_id: 232,
		part_id: 121,
		quantity: 327,
	},
	{
		garage_id: 232,
		part_id: 122,
		quantity: 352,
	},
	{
		garage_id: 232,
		part_id: 123,
		quantity: 200,
	},
	{
		garage_id: 232,
		part_id: 124,
		quantity: 287,
	},
	{
		garage_id: 232,
		part_id: 125,
		quantity: 162,
	},
	{
		garage_id: 232,
		part_id: 126,
		quantity: 6,
	},
	{
		garage_id: 232,
		part_id: 127,
		quantity: 330,
	},
	{
		garage_id: 232,
		part_id: 128,
		quantity: 159,
	},
	{
		garage_id: 232,
		part_id: 129,
		quantity: 42,
	},
	{
		garage_id: 232,
		part_id: 130,
		quantity: 381,
	},
	{
		garage_id: 232,
		part_id: 131,
		quantity: 141,
	},
	{
		garage_id: 232,
		part_id: 132,
		quantity: 291,
	},
	{
		garage_id: 232,
		part_id: 133,
		quantity: 91,
	},
	{
		garage_id: 232,
		part_id: 134,
		quantity: 268,
	},
	{
		garage_id: 232,
		part_id: 135,
		quantity: 235,
	},
	{
		garage_id: 232,
		part_id: 136,
		quantity: 205,
	},
	{
		garage_id: 232,
		part_id: 137,
		quantity: 44,
	},
	{
		garage_id: 232,
		part_id: 138,
		quantity: 1,
	},
	{
		garage_id: 232,
		part_id: 139,
		quantity: 137,
	},
	{
		garage_id: 232,
		part_id: 140,
		quantity: 201,
	},
	{
		garage_id: 232,
		part_id: 141,
		quantity: 145,
	},
	{
		garage_id: 232,
		part_id: 142,
		quantity: 55,
	},
	{
		garage_id: 232,
		part_id: 143,
		quantity: 192,
	},
	{
		garage_id: 232,
		part_id: 144,
		quantity: 121,
	},
	{
		garage_id: 232,
		part_id: 145,
		quantity: 369,
	},
	{
		garage_id: 232,
		part_id: 146,
		quantity: 480,
	},
	{
		garage_id: 232,
		part_id: 147,
		quantity: 376,
	},
	{
		garage_id: 232,
		part_id: 148,
		quantity: 424,
	},
	{
		garage_id: 232,
		part_id: 149,
		quantity: 319,
	},
	{
		garage_id: 232,
		part_id: 150,
		quantity: 330,
	},
	{
		garage_id: 232,
		part_id: 151,
		quantity: 179,
	},
	{
		garage_id: 232,
		part_id: 152,
		quantity: 67,
	},
	{
		garage_id: 232,
		part_id: 153,
		quantity: 310,
	},
	{
		garage_id: 232,
		part_id: 154,
		quantity: 146,
	},
	{
		garage_id: 232,
		part_id: 155,
		quantity: 18,
	},
	{
		garage_id: 232,
		part_id: 156,
		quantity: 160,
	},
	{
		garage_id: 232,
		part_id: 157,
		quantity: 173,
	},
	{
		garage_id: 232,
		part_id: 158,
		quantity: 138,
	},
	{
		garage_id: 232,
		part_id: 159,
		quantity: 138,
	},
	{
		garage_id: 232,
		part_id: 160,
		quantity: 345,
	},
	{
		garage_id: 232,
		part_id: 161,
		quantity: 236,
	},
	{
		garage_id: 232,
		part_id: 162,
		quantity: 450,
	},
	{
		garage_id: 232,
		part_id: 163,
		quantity: 92,
	},
	{
		garage_id: 232,
		part_id: 164,
		quantity: 25,
	},
	{
		garage_id: 232,
		part_id: 165,
		quantity: 319,
	},
	{
		garage_id: 232,
		part_id: 166,
		quantity: 266,
	},
	{
		garage_id: 232,
		part_id: 167,
		quantity: 42,
	},
	{
		garage_id: 232,
		part_id: 168,
		quantity: 320,
	},
	{
		garage_id: 232,
		part_id: 169,
		quantity: 15,
	},
	{
		garage_id: 232,
		part_id: 170,
		quantity: 457,
	},
	{
		garage_id: 232,
		part_id: 171,
		quantity: 324,
	},
	{
		garage_id: 232,
		part_id: 172,
		quantity: 83,
	},
	{
		garage_id: 232,
		part_id: 173,
		quantity: 121,
	},
	{
		garage_id: 232,
		part_id: 174,
		quantity: 450,
	},
	{
		garage_id: 232,
		part_id: 175,
		quantity: 299,
	},
	{
		garage_id: 232,
		part_id: 176,
		quantity: 241,
	},
	{
		garage_id: 232,
		part_id: 177,
		quantity: 467,
	},
	{
		garage_id: 232,
		part_id: 178,
		quantity: 331,
	},
	{
		garage_id: 232,
		part_id: 179,
		quantity: 163,
	},
	{
		garage_id: 232,
		part_id: 180,
		quantity: 460,
	},
	{
		garage_id: 232,
		part_id: 181,
		quantity: 91,
	},
	{
		garage_id: 232,
		part_id: 182,
		quantity: 195,
	},
	{
		garage_id: 232,
		part_id: 183,
		quantity: 334,
	},
	{
		garage_id: 232,
		part_id: 184,
		quantity: 393,
	},
	{
		garage_id: 232,
		part_id: 185,
		quantity: 186,
	},
	{
		garage_id: 232,
		part_id: 186,
		quantity: 82,
	},
	{
		garage_id: 232,
		part_id: 187,
		quantity: 116,
	},
	{
		garage_id: 232,
		part_id: 188,
		quantity: 4,
	},
	{
		garage_id: 232,
		part_id: 189,
		quantity: 200,
	},
	{
		garage_id: 232,
		part_id: 190,
		quantity: 168,
	},
	{
		garage_id: 232,
		part_id: 191,
		quantity: 139,
	},
	{
		garage_id: 232,
		part_id: 192,
		quantity: 106,
	},
	{
		garage_id: 232,
		part_id: 193,
		quantity: 137,
	},
	{
		garage_id: 232,
		part_id: 194,
		quantity: 32,
	},
	{
		garage_id: 232,
		part_id: 195,
		quantity: 490,
	},
	{
		garage_id: 232,
		part_id: 196,
		quantity: 371,
	},
	{
		garage_id: 232,
		part_id: 197,
		quantity: 422,
	},
	{
		garage_id: 232,
		part_id: 198,
		quantity: 37,
	},
	{
		garage_id: 232,
		part_id: 199,
		quantity: 35,
	},
	{
		garage_id: 232,
		part_id: 200,
		quantity: 49,
	},
	{
		garage_id: 232,
		part_id: 201,
		quantity: 295,
	},
	{
		garage_id: 232,
		part_id: 202,
		quantity: 206,
	},
	{
		garage_id: 232,
		part_id: 203,
		quantity: 392,
	},
	{
		garage_id: 232,
		part_id: 204,
		quantity: 445,
	},
	{
		garage_id: 232,
		part_id: 205,
		quantity: 285,
	},
	{
		garage_id: 232,
		part_id: 206,
		quantity: 97,
	},
	{
		garage_id: 232,
		part_id: 207,
		quantity: 470,
	},
	{
		garage_id: 232,
		part_id: 208,
		quantity: 277,
	},
	{
		garage_id: 232,
		part_id: 209,
		quantity: 242,
	},
	{
		garage_id: 232,
		part_id: 210,
		quantity: 220,
	},
	{
		garage_id: 232,
		part_id: 211,
		quantity: 354,
	},
	{
		garage_id: 232,
		part_id: 212,
		quantity: 350,
	},
	{
		garage_id: 232,
		part_id: 213,
		quantity: 62,
	},
	{
		garage_id: 232,
		part_id: 214,
		quantity: 476,
	},
	{
		garage_id: 232,
		part_id: 215,
		quantity: 104,
	},
	{
		garage_id: 232,
		part_id: 216,
		quantity: 150,
	},
	{
		garage_id: 232,
		part_id: 217,
		quantity: 105,
	},
	{
		garage_id: 232,
		part_id: 218,
		quantity: 234,
	},
	{
		garage_id: 232,
		part_id: 219,
		quantity: 106,
	},
	{
		garage_id: 232,
		part_id: 220,
		quantity: 310,
	},
	{
		garage_id: 232,
		part_id: 221,
		quantity: 195,
	},
	{
		garage_id: 232,
		part_id: 222,
		quantity: 343,
	},
	{
		garage_id: 232,
		part_id: 223,
		quantity: 204,
	},
	{
		garage_id: 232,
		part_id: 224,
		quantity: 194,
	},
	{
		garage_id: 232,
		part_id: 225,
		quantity: 98,
	},
	{
		garage_id: 232,
		part_id: 226,
		quantity: 189,
	},
	{
		garage_id: 232,
		part_id: 227,
		quantity: 407,
	},
	{
		garage_id: 232,
		part_id: 228,
		quantity: 316,
	},
	{
		garage_id: 232,
		part_id: 229,
		quantity: 18,
	},
	{
		garage_id: 232,
		part_id: 230,
		quantity: 208,
	},
	{
		garage_id: 232,
		part_id: 231,
		quantity: 223,
	},
	{
		garage_id: 232,
		part_id: 232,
		quantity: 234,
	},
	{
		garage_id: 232,
		part_id: 233,
		quantity: 419,
	},
	{
		garage_id: 232,
		part_id: 234,
		quantity: 301,
	},
	{
		garage_id: 232,
		part_id: 235,
		quantity: 449,
	},
	{
		garage_id: 232,
		part_id: 236,
		quantity: 355,
	},
	{
		garage_id: 232,
		part_id: 237,
		quantity: 306,
	},
	{
		garage_id: 232,
		part_id: 238,
		quantity: 287,
	},
	{
		garage_id: 232,
		part_id: 239,
		quantity: 117,
	},
	{
		garage_id: 232,
		part_id: 240,
		quantity: 448,
	},
	{
		garage_id: 232,
		part_id: 241,
		quantity: 369,
	},
	{
		garage_id: 232,
		part_id: 242,
		quantity: 38,
	},
	{
		garage_id: 232,
		part_id: 243,
		quantity: 241,
	},
	{
		garage_id: 232,
		part_id: 244,
		quantity: 52,
	},
	{
		garage_id: 232,
		part_id: 245,
		quantity: 126,
	},
	{
		garage_id: 232,
		part_id: 246,
		quantity: 171,
	},
	{
		garage_id: 232,
		part_id: 247,
		quantity: 469,
	},
	{
		garage_id: 232,
		part_id: 248,
		quantity: 157,
	},
	{
		garage_id: 232,
		part_id: 249,
		quantity: 137,
	},
	{
		garage_id: 232,
		part_id: 250,
		quantity: 395,
	},
	{
		garage_id: 232,
		part_id: 251,
		quantity: 18,
	},
	{
		garage_id: 232,
		part_id: 252,
		quantity: 268,
	},
	{
		garage_id: 232,
		part_id: 253,
		quantity: 37,
	},
	{
		garage_id: 232,
		part_id: 254,
		quantity: 360,
	},
	{
		garage_id: 232,
		part_id: 255,
		quantity: 315,
	},
	{
		garage_id: 232,
		part_id: 256,
		quantity: 313,
	},
	{
		garage_id: 232,
		part_id: 257,
		quantity: 205,
	},
	{
		garage_id: 232,
		part_id: 258,
		quantity: 172,
	},
	{
		garage_id: 232,
		part_id: 259,
		quantity: 173,
	},
	{
		garage_id: 232,
		part_id: 260,
		quantity: 408,
	},
	{
		garage_id: 232,
		part_id: 261,
		quantity: 383,
	},
	{
		garage_id: 232,
		part_id: 262,
		quantity: 452,
	},
	{
		garage_id: 232,
		part_id: 263,
		quantity: 417,
	},
	{
		garage_id: 232,
		part_id: 264,
		quantity: 190,
	},
	{
		garage_id: 232,
		part_id: 265,
		quantity: 401,
	},
	{
		garage_id: 232,
		part_id: 266,
		quantity: 109,
	},
	{
		garage_id: 232,
		part_id: 267,
		quantity: 149,
	},
	{
		garage_id: 232,
		part_id: 268,
		quantity: 195,
	},
	{
		garage_id: 232,
		part_id: 269,
		quantity: 150,
	},
	{
		garage_id: 232,
		part_id: 270,
		quantity: 376,
	},
	{
		garage_id: 232,
		part_id: 271,
		quantity: 153,
	},
	{
		garage_id: 232,
		part_id: 272,
		quantity: 397,
	},
	{
		garage_id: 232,
		part_id: 273,
		quantity: 447,
	},
	{
		garage_id: 232,
		part_id: 274,
		quantity: 238,
	},
	{
		garage_id: 232,
		part_id: 275,
		quantity: 104,
	},
	{
		garage_id: 232,
		part_id: 276,
		quantity: 444,
	},
	{
		garage_id: 232,
		part_id: 277,
		quantity: 91,
	},
	{
		garage_id: 232,
		part_id: 278,
		quantity: 119,
	},
	{
		garage_id: 232,
		part_id: 279,
		quantity: 261,
	},
	{
		garage_id: 232,
		part_id: 280,
		quantity: 85,
	},
	{
		garage_id: 232,
		part_id: 281,
		quantity: 231,
	},
	{
		garage_id: 232,
		part_id: 282,
		quantity: 78,
	},
	{
		garage_id: 232,
		part_id: 283,
		quantity: 370,
	},
	{
		garage_id: 232,
		part_id: 284,
		quantity: 87,
	},
	{
		garage_id: 232,
		part_id: 285,
		quantity: 114,
	},
	{
		garage_id: 232,
		part_id: 286,
		quantity: 299,
	},
	{
		garage_id: 232,
		part_id: 287,
		quantity: 189,
	},
	{
		garage_id: 232,
		part_id: 288,
		quantity: 281,
	},
	{
		garage_id: 232,
		part_id: 289,
		quantity: 364,
	},
	{
		garage_id: 232,
		part_id: 290,
		quantity: 393,
	},
	{
		garage_id: 232,
		part_id: 291,
		quantity: 410,
	},
	{
		garage_id: 232,
		part_id: 292,
		quantity: 396,
	},
	{
		garage_id: 232,
		part_id: 293,
		quantity: 304,
	},
	{
		garage_id: 232,
		part_id: 294,
		quantity: 162,
	},
	{
		garage_id: 232,
		part_id: 295,
		quantity: 77,
	},
	{
		garage_id: 232,
		part_id: 296,
		quantity: 397,
	},
	{
		garage_id: 232,
		part_id: 297,
		quantity: 372,
	},
	{
		garage_id: 232,
		part_id: 298,
		quantity: 60,
	},
	{
		garage_id: 232,
		part_id: 299,
		quantity: 263,
	},
	{
		garage_id: 232,
		part_id: 300,
		quantity: 92,
	},
	{
		garage_id: 232,
		part_id: 301,
		quantity: 192,
	},
	{
		garage_id: 232,
		part_id: 302,
		quantity: 122,
	},
	{
		garage_id: 232,
		part_id: 303,
		quantity: 347,
	},
	{
		garage_id: 232,
		part_id: 304,
		quantity: 249,
	},
	{
		garage_id: 232,
		part_id: 305,
		quantity: 240,
	},
	{
		garage_id: 232,
		part_id: 306,
		quantity: 176,
	},
	{
		garage_id: 232,
		part_id: 307,
		quantity: 443,
	},
	{
		garage_id: 232,
		part_id: 308,
		quantity: 224,
	},
	{
		garage_id: 232,
		part_id: 309,
		quantity: 99,
	},
	{
		garage_id: 232,
		part_id: 310,
		quantity: 317,
	},
	{
		garage_id: 232,
		part_id: 311,
		quantity: 396,
	},
	{
		garage_id: 232,
		part_id: 312,
		quantity: 292,
	},
	{
		garage_id: 232,
		part_id: 313,
		quantity: 52,
	},
	{
		garage_id: 232,
		part_id: 314,
		quantity: 396,
	},
	{
		garage_id: 232,
		part_id: 315,
		quantity: 38,
	},
	{
		garage_id: 232,
		part_id: 316,
		quantity: 430,
	},
	{
		garage_id: 232,
		part_id: 317,
		quantity: 114,
	},
	{
		garage_id: 232,
		part_id: 318,
		quantity: 100,
	},
	{
		garage_id: 232,
		part_id: 319,
		quantity: 455,
	},
	{
		garage_id: 232,
		part_id: 320,
		quantity: 312,
	},
	{
		garage_id: 232,
		part_id: 321,
		quantity: 148,
	},
	{
		garage_id: 232,
		part_id: 322,
		quantity: 443,
	},
	{
		garage_id: 232,
		part_id: 323,
		quantity: 483,
	},
	{
		garage_id: 232,
		part_id: 324,
		quantity: 276,
	},
	{
		garage_id: 232,
		part_id: 325,
		quantity: 88,
	},
	{
		garage_id: 232,
		part_id: 326,
		quantity: 74,
	},
	{
		garage_id: 232,
		part_id: 327,
		quantity: 153,
	},
	{
		garage_id: 232,
		part_id: 328,
		quantity: 147,
	},
	{
		garage_id: 232,
		part_id: 329,
		quantity: 273,
	},
	{
		garage_id: 232,
		part_id: 330,
		quantity: 134,
	},
	{
		garage_id: 232,
		part_id: 331,
		quantity: 108,
	},
	{
		garage_id: 232,
		part_id: 332,
		quantity: 28,
	},
	{
		garage_id: 232,
		part_id: 333,
		quantity: 457,
	},
	{
		garage_id: 232,
		part_id: 334,
		quantity: 441,
	},
	{
		garage_id: 232,
		part_id: 335,
		quantity: 54,
	},
	{
		garage_id: 232,
		part_id: 336,
		quantity: 436,
	},
	{
		garage_id: 232,
		part_id: 337,
		quantity: 79,
	},
	{
		garage_id: 232,
		part_id: 338,
		quantity: 166,
	},
	{
		garage_id: 232,
		part_id: 339,
		quantity: 215,
	},
	{
		garage_id: 232,
		part_id: 340,
		quantity: 12,
	},
	{
		garage_id: 232,
		part_id: 341,
		quantity: 369,
	},
	{
		garage_id: 232,
		part_id: 342,
		quantity: 20,
	},
	{
		garage_id: 232,
		part_id: 343,
		quantity: 344,
	},
	{
		garage_id: 232,
		part_id: 344,
		quantity: 325,
	},
	{
		garage_id: 232,
		part_id: 345,
		quantity: 24,
	},
	{
		garage_id: 232,
		part_id: 346,
		quantity: 440,
	},
	{
		garage_id: 232,
		part_id: 347,
		quantity: 61,
	},
	{
		garage_id: 232,
		part_id: 348,
		quantity: 410,
	},
	{
		garage_id: 232,
		part_id: 349,
		quantity: 239,
	},
	{
		garage_id: 232,
		part_id: 350,
		quantity: 89,
	},
	{
		garage_id: 232,
		part_id: 351,
		quantity: 472,
	},
	{
		garage_id: 232,
		part_id: 352,
		quantity: 402,
	},
	{
		garage_id: 232,
		part_id: 353,
		quantity: 428,
	},
	{
		garage_id: 232,
		part_id: 354,
		quantity: 31,
	},
	{
		garage_id: 232,
		part_id: 355,
		quantity: 391,
	},
	{
		garage_id: 232,
		part_id: 356,
		quantity: 35,
	},
	{
		garage_id: 232,
		part_id: 357,
		quantity: 426,
	},
	{
		garage_id: 232,
		part_id: 358,
		quantity: 200,
	},
	{
		garage_id: 232,
		part_id: 359,
		quantity: 278,
	},
	{
		garage_id: 232,
		part_id: 360,
		quantity: 199,
	},
	{
		garage_id: 232,
		part_id: 361,
		quantity: 401,
	},
	{
		garage_id: 232,
		part_id: 362,
		quantity: 239,
	},
	{
		garage_id: 232,
		part_id: 363,
		quantity: 293,
	},
	{
		garage_id: 232,
		part_id: 364,
		quantity: 444,
	},
	{
		garage_id: 232,
		part_id: 365,
		quantity: 244,
	},
	{
		garage_id: 232,
		part_id: 366,
		quantity: 77,
	},
	{
		garage_id: 232,
		part_id: 367,
		quantity: 364,
	},
	{
		garage_id: 232,
		part_id: 368,
		quantity: 435,
	},
	{
		garage_id: 232,
		part_id: 369,
		quantity: 88,
	},
	{
		garage_id: 232,
		part_id: 370,
		quantity: 417,
	},
	{
		garage_id: 232,
		part_id: 371,
		quantity: 248,
	},
	{
		garage_id: 232,
		part_id: 372,
		quantity: 212,
	},
	{
		garage_id: 232,
		part_id: 373,
		quantity: 160,
	},
	{
		garage_id: 232,
		part_id: 374,
		quantity: 276,
	},
	{
		garage_id: 232,
		part_id: 375,
		quantity: 166,
	},
	{
		garage_id: 232,
		part_id: 376,
		quantity: 93,
	},
	{
		garage_id: 232,
		part_id: 377,
		quantity: 36,
	},
	{
		garage_id: 232,
		part_id: 378,
		quantity: 283,
	},
	{
		garage_id: 232,
		part_id: 379,
		quantity: 22,
	},
	{
		garage_id: 232,
		part_id: 380,
		quantity: 266,
	},
	{
		garage_id: 232,
		part_id: 381,
		quantity: 114,
	},
	{
		garage_id: 232,
		part_id: 382,
		quantity: 83,
	},
	{
		garage_id: 232,
		part_id: 383,
		quantity: 445,
	},
	{
		garage_id: 232,
		part_id: 384,
		quantity: 140,
	},
	{
		garage_id: 232,
		part_id: 385,
		quantity: 329,
	},
	{
		garage_id: 232,
		part_id: 386,
		quantity: 280,
	},
	{
		garage_id: 232,
		part_id: 387,
		quantity: 303,
	},
	{
		garage_id: 232,
		part_id: 388,
		quantity: 272,
	},
	{
		garage_id: 232,
		part_id: 389,
		quantity: 223,
	},
	{
		garage_id: 232,
		part_id: 390,
		quantity: 286,
	},
	{
		garage_id: 232,
		part_id: 391,
		quantity: 71,
	},
	{
		garage_id: 232,
		part_id: 392,
		quantity: 441,
	},
	{
		garage_id: 232,
		part_id: 393,
		quantity: 202,
	},
	{
		garage_id: 232,
		part_id: 394,
		quantity: 98,
	},
	{
		garage_id: 232,
		part_id: 395,
		quantity: 435,
	},
	{
		garage_id: 232,
		part_id: 396,
		quantity: 149,
	},
	{
		garage_id: 232,
		part_id: 397,
		quantity: 294,
	},
	{
		garage_id: 232,
		part_id: 398,
		quantity: 148,
	},
	{
		garage_id: 232,
		part_id: 399,
		quantity: 294,
	},
	{
		garage_id: 233,
		part_id: 0,
		quantity: 207,
	},
	{
		garage_id: 233,
		part_id: 1,
		quantity: 247,
	},
	{
		garage_id: 233,
		part_id: 2,
		quantity: 210,
	},
	{
		garage_id: 233,
		part_id: 3,
		quantity: 38,
	},
	{
		garage_id: 233,
		part_id: 4,
		quantity: 258,
	},
	{
		garage_id: 233,
		part_id: 5,
		quantity: 383,
	},
	{
		garage_id: 233,
		part_id: 6,
		quantity: 481,
	},
	{
		garage_id: 233,
		part_id: 7,
		quantity: 313,
	},
	{
		garage_id: 233,
		part_id: 8,
		quantity: 290,
	},
	{
		garage_id: 233,
		part_id: 9,
		quantity: 330,
	},
	{
		garage_id: 233,
		part_id: 10,
		quantity: 99,
	},
	{
		garage_id: 233,
		part_id: 11,
		quantity: 434,
	},
	{
		garage_id: 233,
		part_id: 12,
		quantity: 6,
	},
	{
		garage_id: 233,
		part_id: 13,
		quantity: 62,
	},
	{
		garage_id: 233,
		part_id: 14,
		quantity: 209,
	},
	{
		garage_id: 233,
		part_id: 15,
		quantity: 119,
	},
	{
		garage_id: 233,
		part_id: 16,
		quantity: 472,
	},
	{
		garage_id: 233,
		part_id: 17,
		quantity: 202,
	},
	{
		garage_id: 233,
		part_id: 18,
		quantity: 236,
	},
	{
		garage_id: 233,
		part_id: 19,
		quantity: 281,
	},
	{
		garage_id: 233,
		part_id: 20,
		quantity: 360,
	},
	{
		garage_id: 233,
		part_id: 21,
		quantity: 425,
	},
	{
		garage_id: 233,
		part_id: 22,
		quantity: 197,
	},
	{
		garage_id: 233,
		part_id: 23,
		quantity: 191,
	},
	{
		garage_id: 233,
		part_id: 24,
		quantity: 81,
	},
	{
		garage_id: 233,
		part_id: 25,
		quantity: 245,
	},
	{
		garage_id: 233,
		part_id: 26,
		quantity: 352,
	},
	{
		garage_id: 233,
		part_id: 27,
		quantity: 149,
	},
	{
		garage_id: 233,
		part_id: 28,
		quantity: 138,
	},
	{
		garage_id: 233,
		part_id: 29,
		quantity: 354,
	},
	{
		garage_id: 233,
		part_id: 30,
		quantity: 85,
	},
	{
		garage_id: 233,
		part_id: 31,
		quantity: 473,
	},
	{
		garage_id: 233,
		part_id: 32,
		quantity: 252,
	},
	{
		garage_id: 233,
		part_id: 33,
		quantity: 285,
	},
	{
		garage_id: 233,
		part_id: 34,
		quantity: 19,
	},
	{
		garage_id: 233,
		part_id: 35,
		quantity: 213,
	},
	{
		garage_id: 233,
		part_id: 36,
		quantity: 459,
	},
	{
		garage_id: 233,
		part_id: 37,
		quantity: 96,
	},
	{
		garage_id: 233,
		part_id: 38,
		quantity: 449,
	},
	{
		garage_id: 233,
		part_id: 39,
		quantity: 181,
	},
	{
		garage_id: 233,
		part_id: 40,
		quantity: 262,
	},
	{
		garage_id: 233,
		part_id: 41,
		quantity: 434,
	},
	{
		garage_id: 233,
		part_id: 42,
		quantity: 266,
	},
	{
		garage_id: 233,
		part_id: 43,
		quantity: 381,
	},
	{
		garage_id: 233,
		part_id: 44,
		quantity: 311,
	},
	{
		garage_id: 233,
		part_id: 45,
		quantity: 269,
	},
	{
		garage_id: 233,
		part_id: 46,
		quantity: 420,
	},
	{
		garage_id: 233,
		part_id: 47,
		quantity: 120,
	},
	{
		garage_id: 233,
		part_id: 48,
		quantity: 461,
	},
	{
		garage_id: 233,
		part_id: 49,
		quantity: 222,
	},
	{
		garage_id: 233,
		part_id: 50,
		quantity: 62,
	},
	{
		garage_id: 233,
		part_id: 51,
		quantity: 93,
	},
	{
		garage_id: 233,
		part_id: 52,
		quantity: 402,
	},
	{
		garage_id: 233,
		part_id: 53,
		quantity: 190,
	},
	{
		garage_id: 233,
		part_id: 54,
		quantity: 320,
	},
	{
		garage_id: 233,
		part_id: 55,
		quantity: 198,
	},
	{
		garage_id: 233,
		part_id: 56,
		quantity: 396,
	},
	{
		garage_id: 233,
		part_id: 57,
		quantity: 379,
	},
	{
		garage_id: 233,
		part_id: 58,
		quantity: 317,
	},
	{
		garage_id: 233,
		part_id: 59,
		quantity: 431,
	},
	{
		garage_id: 233,
		part_id: 60,
		quantity: 458,
	},
	{
		garage_id: 233,
		part_id: 61,
		quantity: 233,
	},
	{
		garage_id: 233,
		part_id: 62,
		quantity: 460,
	},
	{
		garage_id: 233,
		part_id: 63,
		quantity: 459,
	},
	{
		garage_id: 233,
		part_id: 64,
		quantity: 143,
	},
	{
		garage_id: 233,
		part_id: 65,
		quantity: 220,
	},
	{
		garage_id: 233,
		part_id: 66,
		quantity: 324,
	},
	{
		garage_id: 233,
		part_id: 67,
		quantity: 330,
	},
	{
		garage_id: 233,
		part_id: 68,
		quantity: 199,
	},
	{
		garage_id: 233,
		part_id: 69,
		quantity: 404,
	},
	{
		garage_id: 233,
		part_id: 70,
		quantity: 388,
	},
	{
		garage_id: 233,
		part_id: 71,
		quantity: 369,
	},
	{
		garage_id: 233,
		part_id: 72,
		quantity: 76,
	},
	{
		garage_id: 233,
		part_id: 73,
		quantity: 257,
	},
	{
		garage_id: 233,
		part_id: 74,
		quantity: 82,
	},
	{
		garage_id: 233,
		part_id: 75,
		quantity: 445,
	},
	{
		garage_id: 233,
		part_id: 76,
		quantity: 335,
	},
	{
		garage_id: 233,
		part_id: 77,
		quantity: 352,
	},
	{
		garage_id: 233,
		part_id: 78,
		quantity: 344,
	},
	{
		garage_id: 233,
		part_id: 79,
		quantity: 398,
	},
	{
		garage_id: 233,
		part_id: 80,
		quantity: 270,
	},
	{
		garage_id: 233,
		part_id: 81,
		quantity: 395,
	},
	{
		garage_id: 233,
		part_id: 82,
		quantity: 474,
	},
	{
		garage_id: 233,
		part_id: 83,
		quantity: 383,
	},
	{
		garage_id: 233,
		part_id: 84,
		quantity: 299,
	},
	{
		garage_id: 233,
		part_id: 85,
		quantity: 472,
	},
	{
		garage_id: 233,
		part_id: 86,
		quantity: 142,
	},
	{
		garage_id: 233,
		part_id: 87,
		quantity: 473,
	},
	{
		garage_id: 233,
		part_id: 88,
		quantity: 294,
	},
	{
		garage_id: 233,
		part_id: 89,
		quantity: 484,
	},
	{
		garage_id: 233,
		part_id: 90,
		quantity: 120,
	},
	{
		garage_id: 233,
		part_id: 91,
		quantity: 146,
	},
	{
		garage_id: 233,
		part_id: 92,
		quantity: 423,
	},
	{
		garage_id: 233,
		part_id: 93,
		quantity: 154,
	},
	{
		garage_id: 233,
		part_id: 94,
		quantity: 210,
	},
	{
		garage_id: 233,
		part_id: 95,
		quantity: 348,
	},
	{
		garage_id: 233,
		part_id: 96,
		quantity: 432,
	},
	{
		garage_id: 233,
		part_id: 97,
		quantity: 251,
	},
	{
		garage_id: 233,
		part_id: 98,
		quantity: 17,
	},
	{
		garage_id: 233,
		part_id: 99,
		quantity: 52,
	},
	{
		garage_id: 233,
		part_id: 100,
		quantity: 371,
	},
	{
		garage_id: 233,
		part_id: 101,
		quantity: 187,
	},
	{
		garage_id: 233,
		part_id: 102,
		quantity: 197,
	},
	{
		garage_id: 233,
		part_id: 103,
		quantity: 142,
	},
	{
		garage_id: 233,
		part_id: 104,
		quantity: 28,
	},
	{
		garage_id: 233,
		part_id: 105,
		quantity: 243,
	},
	{
		garage_id: 233,
		part_id: 106,
		quantity: 290,
	},
	{
		garage_id: 233,
		part_id: 107,
		quantity: 158,
	},
	{
		garage_id: 233,
		part_id: 108,
		quantity: 475,
	},
	{
		garage_id: 233,
		part_id: 109,
		quantity: 30,
	},
	{
		garage_id: 233,
		part_id: 110,
		quantity: 486,
	},
	{
		garage_id: 233,
		part_id: 111,
		quantity: 304,
	},
	{
		garage_id: 233,
		part_id: 112,
		quantity: 193,
	},
	{
		garage_id: 233,
		part_id: 113,
		quantity: 201,
	},
	{
		garage_id: 233,
		part_id: 114,
		quantity: 285,
	},
	{
		garage_id: 233,
		part_id: 115,
		quantity: 14,
	},
	{
		garage_id: 233,
		part_id: 116,
		quantity: 46,
	},
	{
		garage_id: 233,
		part_id: 117,
		quantity: 328,
	},
	{
		garage_id: 233,
		part_id: 118,
		quantity: 43,
	},
	{
		garage_id: 233,
		part_id: 119,
		quantity: 378,
	},
	{
		garage_id: 233,
		part_id: 120,
		quantity: 347,
	},
	{
		garage_id: 233,
		part_id: 121,
		quantity: 330,
	},
	{
		garage_id: 233,
		part_id: 122,
		quantity: 83,
	},
	{
		garage_id: 233,
		part_id: 123,
		quantity: 271,
	},
	{
		garage_id: 233,
		part_id: 124,
		quantity: 6,
	},
	{
		garage_id: 233,
		part_id: 125,
		quantity: 492,
	},
	{
		garage_id: 233,
		part_id: 126,
		quantity: 271,
	},
	{
		garage_id: 233,
		part_id: 127,
		quantity: 64,
	},
	{
		garage_id: 233,
		part_id: 128,
		quantity: 20,
	},
	{
		garage_id: 233,
		part_id: 129,
		quantity: 410,
	},
	{
		garage_id: 233,
		part_id: 130,
		quantity: 63,
	},
	{
		garage_id: 233,
		part_id: 131,
		quantity: 271,
	},
	{
		garage_id: 233,
		part_id: 132,
		quantity: 1,
	},
	{
		garage_id: 233,
		part_id: 133,
		quantity: 126,
	},
	{
		garage_id: 233,
		part_id: 134,
		quantity: 330,
	},
	{
		garage_id: 233,
		part_id: 135,
		quantity: 370,
	},
	{
		garage_id: 233,
		part_id: 136,
		quantity: 345,
	},
	{
		garage_id: 233,
		part_id: 137,
		quantity: 263,
	},
	{
		garage_id: 233,
		part_id: 138,
		quantity: 144,
	},
	{
		garage_id: 233,
		part_id: 139,
		quantity: 230,
	},
	{
		garage_id: 233,
		part_id: 140,
		quantity: 328,
	},
	{
		garage_id: 233,
		part_id: 141,
		quantity: 455,
	},
	{
		garage_id: 233,
		part_id: 142,
		quantity: 90,
	},
	{
		garage_id: 233,
		part_id: 143,
		quantity: 444,
	},
	{
		garage_id: 233,
		part_id: 144,
		quantity: 281,
	},
	{
		garage_id: 233,
		part_id: 145,
		quantity: 427,
	},
	{
		garage_id: 233,
		part_id: 146,
		quantity: 117,
	},
	{
		garage_id: 233,
		part_id: 147,
		quantity: 77,
	},
	{
		garage_id: 233,
		part_id: 148,
		quantity: 261,
	},
	{
		garage_id: 233,
		part_id: 149,
		quantity: 155,
	},
	{
		garage_id: 233,
		part_id: 150,
		quantity: 191,
	},
	{
		garage_id: 233,
		part_id: 151,
		quantity: 125,
	},
	{
		garage_id: 233,
		part_id: 152,
		quantity: 137,
	},
	{
		garage_id: 233,
		part_id: 153,
		quantity: 499,
	},
	{
		garage_id: 233,
		part_id: 154,
		quantity: 386,
	},
	{
		garage_id: 233,
		part_id: 155,
		quantity: 109,
	},
	{
		garage_id: 233,
		part_id: 156,
		quantity: 307,
	},
	{
		garage_id: 233,
		part_id: 157,
		quantity: 267,
	},
	{
		garage_id: 233,
		part_id: 158,
		quantity: 494,
	},
	{
		garage_id: 233,
		part_id: 159,
		quantity: 355,
	},
	{
		garage_id: 233,
		part_id: 160,
		quantity: 215,
	},
	{
		garage_id: 233,
		part_id: 161,
		quantity: 21,
	},
	{
		garage_id: 233,
		part_id: 162,
		quantity: 129,
	},
	{
		garage_id: 233,
		part_id: 163,
		quantity: 210,
	},
	{
		garage_id: 233,
		part_id: 164,
		quantity: 198,
	},
	{
		garage_id: 233,
		part_id: 165,
		quantity: 349,
	},
	{
		garage_id: 233,
		part_id: 166,
		quantity: 244,
	},
	{
		garage_id: 233,
		part_id: 167,
		quantity: 160,
	},
	{
		garage_id: 233,
		part_id: 168,
		quantity: 5,
	},
	{
		garage_id: 233,
		part_id: 169,
		quantity: 396,
	},
	{
		garage_id: 233,
		part_id: 170,
		quantity: 331,
	},
	{
		garage_id: 233,
		part_id: 171,
		quantity: 325,
	},
	{
		garage_id: 233,
		part_id: 172,
		quantity: 361,
	},
	{
		garage_id: 233,
		part_id: 173,
		quantity: 238,
	},
	{
		garage_id: 233,
		part_id: 174,
		quantity: 294,
	},
	{
		garage_id: 233,
		part_id: 175,
		quantity: 311,
	},
	{
		garage_id: 233,
		part_id: 176,
		quantity: 94,
	},
	{
		garage_id: 233,
		part_id: 177,
		quantity: 475,
	},
	{
		garage_id: 233,
		part_id: 178,
		quantity: 76,
	},
	{
		garage_id: 233,
		part_id: 179,
		quantity: 229,
	},
	{
		garage_id: 233,
		part_id: 180,
		quantity: 363,
	},
	{
		garage_id: 233,
		part_id: 181,
		quantity: 217,
	},
	{
		garage_id: 233,
		part_id: 182,
		quantity: 179,
	},
	{
		garage_id: 233,
		part_id: 183,
		quantity: 295,
	},
	{
		garage_id: 233,
		part_id: 184,
		quantity: 298,
	},
	{
		garage_id: 233,
		part_id: 185,
		quantity: 257,
	},
	{
		garage_id: 233,
		part_id: 186,
		quantity: 122,
	},
	{
		garage_id: 233,
		part_id: 187,
		quantity: 57,
	},
	{
		garage_id: 233,
		part_id: 188,
		quantity: 454,
	},
	{
		garage_id: 233,
		part_id: 189,
		quantity: 217,
	},
	{
		garage_id: 233,
		part_id: 190,
		quantity: 34,
	},
	{
		garage_id: 233,
		part_id: 191,
		quantity: 282,
	},
	{
		garage_id: 233,
		part_id: 192,
		quantity: 296,
	},
	{
		garage_id: 233,
		part_id: 193,
		quantity: 389,
	},
	{
		garage_id: 233,
		part_id: 194,
		quantity: 42,
	},
	{
		garage_id: 233,
		part_id: 195,
		quantity: 207,
	},
	{
		garage_id: 233,
		part_id: 196,
		quantity: 38,
	},
	{
		garage_id: 233,
		part_id: 197,
		quantity: 168,
	},
	{
		garage_id: 233,
		part_id: 198,
		quantity: 329,
	},
	{
		garage_id: 233,
		part_id: 199,
		quantity: 410,
	},
	{
		garage_id: 233,
		part_id: 200,
		quantity: 488,
	},
	{
		garage_id: 233,
		part_id: 201,
		quantity: 343,
	},
	{
		garage_id: 233,
		part_id: 202,
		quantity: 303,
	},
	{
		garage_id: 233,
		part_id: 203,
		quantity: 301,
	},
	{
		garage_id: 233,
		part_id: 204,
		quantity: 178,
	},
	{
		garage_id: 233,
		part_id: 205,
		quantity: 327,
	},
	{
		garage_id: 233,
		part_id: 206,
		quantity: 475,
	},
	{
		garage_id: 233,
		part_id: 207,
		quantity: 422,
	},
	{
		garage_id: 233,
		part_id: 208,
		quantity: 49,
	},
	{
		garage_id: 233,
		part_id: 209,
		quantity: 342,
	},
	{
		garage_id: 233,
		part_id: 210,
		quantity: 309,
	},
	{
		garage_id: 233,
		part_id: 211,
		quantity: 170,
	},
	{
		garage_id: 233,
		part_id: 212,
		quantity: 288,
	},
	{
		garage_id: 233,
		part_id: 213,
		quantity: 286,
	},
	{
		garage_id: 233,
		part_id: 214,
		quantity: 264,
	},
	{
		garage_id: 233,
		part_id: 215,
		quantity: 353,
	},
	{
		garage_id: 233,
		part_id: 216,
		quantity: 264,
	},
	{
		garage_id: 233,
		part_id: 217,
		quantity: 252,
	},
	{
		garage_id: 233,
		part_id: 218,
		quantity: 96,
	},
	{
		garage_id: 233,
		part_id: 219,
		quantity: 457,
	},
	{
		garage_id: 233,
		part_id: 220,
		quantity: 348,
	},
	{
		garage_id: 233,
		part_id: 221,
		quantity: 336,
	},
	{
		garage_id: 233,
		part_id: 222,
		quantity: 282,
	},
	{
		garage_id: 233,
		part_id: 223,
		quantity: 283,
	},
	{
		garage_id: 233,
		part_id: 224,
		quantity: 396,
	},
	{
		garage_id: 233,
		part_id: 225,
		quantity: 143,
	},
	{
		garage_id: 233,
		part_id: 226,
		quantity: 175,
	},
	{
		garage_id: 233,
		part_id: 227,
		quantity: 72,
	},
	{
		garage_id: 233,
		part_id: 228,
		quantity: 293,
	},
	{
		garage_id: 233,
		part_id: 229,
		quantity: 344,
	},
	{
		garage_id: 233,
		part_id: 230,
		quantity: 188,
	},
	{
		garage_id: 233,
		part_id: 231,
		quantity: 169,
	},
	{
		garage_id: 233,
		part_id: 232,
		quantity: 2,
	},
	{
		garage_id: 233,
		part_id: 233,
		quantity: 185,
	},
	{
		garage_id: 233,
		part_id: 234,
		quantity: 170,
	},
	{
		garage_id: 233,
		part_id: 235,
		quantity: 81,
	},
	{
		garage_id: 233,
		part_id: 236,
		quantity: 263,
	},
	{
		garage_id: 233,
		part_id: 237,
		quantity: 376,
	},
	{
		garage_id: 233,
		part_id: 238,
		quantity: 492,
	},
	{
		garage_id: 233,
		part_id: 239,
		quantity: 490,
	},
	{
		garage_id: 233,
		part_id: 240,
		quantity: 283,
	},
	{
		garage_id: 233,
		part_id: 241,
		quantity: 183,
	},
	{
		garage_id: 233,
		part_id: 242,
		quantity: 295,
	},
	{
		garage_id: 233,
		part_id: 243,
		quantity: 13,
	},
	{
		garage_id: 233,
		part_id: 244,
		quantity: 263,
	},
	{
		garage_id: 233,
		part_id: 245,
		quantity: 140,
	},
	{
		garage_id: 233,
		part_id: 246,
		quantity: 180,
	},
	{
		garage_id: 233,
		part_id: 247,
		quantity: 145,
	},
	{
		garage_id: 233,
		part_id: 248,
		quantity: 408,
	},
	{
		garage_id: 233,
		part_id: 249,
		quantity: 325,
	},
	{
		garage_id: 233,
		part_id: 250,
		quantity: 401,
	},
	{
		garage_id: 233,
		part_id: 251,
		quantity: 232,
	},
	{
		garage_id: 233,
		part_id: 252,
		quantity: 255,
	},
	{
		garage_id: 233,
		part_id: 253,
		quantity: 366,
	},
	{
		garage_id: 233,
		part_id: 254,
		quantity: 395,
	},
	{
		garage_id: 233,
		part_id: 255,
		quantity: 366,
	},
	{
		garage_id: 233,
		part_id: 256,
		quantity: 270,
	},
	{
		garage_id: 233,
		part_id: 257,
		quantity: 173,
	},
	{
		garage_id: 233,
		part_id: 258,
		quantity: 455,
	},
	{
		garage_id: 233,
		part_id: 259,
		quantity: 273,
	},
	{
		garage_id: 233,
		part_id: 260,
		quantity: 451,
	},
	{
		garage_id: 233,
		part_id: 261,
		quantity: 16,
	},
	{
		garage_id: 233,
		part_id: 262,
		quantity: 40,
	},
	{
		garage_id: 233,
		part_id: 263,
		quantity: 354,
	},
	{
		garage_id: 233,
		part_id: 264,
		quantity: 323,
	},
	{
		garage_id: 233,
		part_id: 265,
		quantity: 361,
	},
	{
		garage_id: 233,
		part_id: 266,
		quantity: 262,
	},
	{
		garage_id: 233,
		part_id: 267,
		quantity: 380,
	},
	{
		garage_id: 233,
		part_id: 268,
		quantity: 127,
	},
	{
		garage_id: 233,
		part_id: 269,
		quantity: 357,
	},
	{
		garage_id: 233,
		part_id: 270,
		quantity: 277,
	},
	{
		garage_id: 233,
		part_id: 271,
		quantity: 202,
	},
	{
		garage_id: 233,
		part_id: 272,
		quantity: 457,
	},
	{
		garage_id: 233,
		part_id: 273,
		quantity: 223,
	},
	{
		garage_id: 233,
		part_id: 274,
		quantity: 344,
	},
	{
		garage_id: 233,
		part_id: 275,
		quantity: 211,
	},
	{
		garage_id: 233,
		part_id: 276,
		quantity: 151,
	},
	{
		garage_id: 233,
		part_id: 277,
		quantity: 187,
	},
	{
		garage_id: 233,
		part_id: 278,
		quantity: 299,
	},
	{
		garage_id: 233,
		part_id: 279,
		quantity: 406,
	},
	{
		garage_id: 233,
		part_id: 280,
		quantity: 324,
	},
	{
		garage_id: 233,
		part_id: 281,
		quantity: 271,
	},
	{
		garage_id: 233,
		part_id: 282,
		quantity: 50,
	},
	{
		garage_id: 233,
		part_id: 283,
		quantity: 51,
	},
	{
		garage_id: 233,
		part_id: 284,
		quantity: 270,
	},
	{
		garage_id: 233,
		part_id: 285,
		quantity: 219,
	},
	{
		garage_id: 233,
		part_id: 286,
		quantity: 242,
	},
	{
		garage_id: 233,
		part_id: 287,
		quantity: 205,
	},
	{
		garage_id: 233,
		part_id: 288,
		quantity: 51,
	},
	{
		garage_id: 233,
		part_id: 289,
		quantity: 90,
	},
	{
		garage_id: 233,
		part_id: 290,
		quantity: 283,
	},
	{
		garage_id: 233,
		part_id: 291,
		quantity: 245,
	},
	{
		garage_id: 233,
		part_id: 292,
		quantity: 114,
	},
	{
		garage_id: 233,
		part_id: 293,
		quantity: 11,
	},
	{
		garage_id: 233,
		part_id: 294,
		quantity: 188,
	},
	{
		garage_id: 233,
		part_id: 295,
		quantity: 485,
	},
	{
		garage_id: 233,
		part_id: 296,
		quantity: 339,
	},
	{
		garage_id: 233,
		part_id: 297,
		quantity: 341,
	},
	{
		garage_id: 233,
		part_id: 298,
		quantity: 224,
	},
	{
		garage_id: 233,
		part_id: 299,
		quantity: 39,
	},
	{
		garage_id: 233,
		part_id: 300,
		quantity: 212,
	},
	{
		garage_id: 233,
		part_id: 301,
		quantity: 144,
	},
	{
		garage_id: 233,
		part_id: 302,
		quantity: 434,
	},
	{
		garage_id: 233,
		part_id: 303,
		quantity: 464,
	},
	{
		garage_id: 233,
		part_id: 304,
		quantity: 26,
	},
	{
		garage_id: 233,
		part_id: 305,
		quantity: 283,
	},
	{
		garage_id: 233,
		part_id: 306,
		quantity: 409,
	},
	{
		garage_id: 233,
		part_id: 307,
		quantity: 388,
	},
	{
		garage_id: 233,
		part_id: 308,
		quantity: 320,
	},
	{
		garage_id: 233,
		part_id: 309,
		quantity: 20,
	},
	{
		garage_id: 233,
		part_id: 310,
		quantity: 458,
	},
	{
		garage_id: 233,
		part_id: 311,
		quantity: 480,
	},
	{
		garage_id: 233,
		part_id: 312,
		quantity: 299,
	},
	{
		garage_id: 233,
		part_id: 313,
		quantity: 25,
	},
	{
		garage_id: 233,
		part_id: 314,
		quantity: 488,
	},
	{
		garage_id: 233,
		part_id: 315,
		quantity: 395,
	},
	{
		garage_id: 233,
		part_id: 316,
		quantity: 109,
	},
	{
		garage_id: 233,
		part_id: 317,
		quantity: 249,
	},
	{
		garage_id: 233,
		part_id: 318,
		quantity: 18,
	},
	{
		garage_id: 233,
		part_id: 319,
		quantity: 456,
	},
	{
		garage_id: 233,
		part_id: 320,
		quantity: 54,
	},
	{
		garage_id: 233,
		part_id: 321,
		quantity: 449,
	},
	{
		garage_id: 233,
		part_id: 322,
		quantity: 348,
	},
	{
		garage_id: 233,
		part_id: 323,
		quantity: 51,
	},
	{
		garage_id: 233,
		part_id: 324,
		quantity: 382,
	},
	{
		garage_id: 233,
		part_id: 325,
		quantity: 420,
	},
	{
		garage_id: 233,
		part_id: 326,
		quantity: 167,
	},
	{
		garage_id: 233,
		part_id: 327,
		quantity: 482,
	},
	{
		garage_id: 233,
		part_id: 328,
		quantity: 11,
	},
	{
		garage_id: 233,
		part_id: 329,
		quantity: 153,
	},
	{
		garage_id: 233,
		part_id: 330,
		quantity: 297,
	},
	{
		garage_id: 233,
		part_id: 331,
		quantity: 51,
	},
	{
		garage_id: 233,
		part_id: 332,
		quantity: 67,
	},
	{
		garage_id: 233,
		part_id: 333,
		quantity: 169,
	},
	{
		garage_id: 233,
		part_id: 334,
		quantity: 141,
	},
	{
		garage_id: 233,
		part_id: 335,
		quantity: 398,
	},
	{
		garage_id: 233,
		part_id: 336,
		quantity: 80,
	},
	{
		garage_id: 233,
		part_id: 337,
		quantity: 367,
	},
	{
		garage_id: 233,
		part_id: 338,
		quantity: 185,
	},
	{
		garage_id: 233,
		part_id: 339,
		quantity: 369,
	},
	{
		garage_id: 233,
		part_id: 340,
		quantity: 262,
	},
	{
		garage_id: 233,
		part_id: 341,
		quantity: 405,
	},
	{
		garage_id: 233,
		part_id: 342,
		quantity: 72,
	},
	{
		garage_id: 233,
		part_id: 343,
		quantity: 90,
	},
	{
		garage_id: 233,
		part_id: 344,
		quantity: 28,
	},
	{
		garage_id: 233,
		part_id: 345,
		quantity: 122,
	},
	{
		garage_id: 233,
		part_id: 346,
		quantity: 427,
	},
	{
		garage_id: 233,
		part_id: 347,
		quantity: 151,
	},
	{
		garage_id: 233,
		part_id: 348,
		quantity: 211,
	},
	{
		garage_id: 233,
		part_id: 349,
		quantity: 30,
	},
	{
		garage_id: 233,
		part_id: 350,
		quantity: 51,
	},
	{
		garage_id: 233,
		part_id: 351,
		quantity: 437,
	},
	{
		garage_id: 233,
		part_id: 352,
		quantity: 120,
	},
	{
		garage_id: 233,
		part_id: 353,
		quantity: 232,
	},
	{
		garage_id: 233,
		part_id: 354,
		quantity: 358,
	},
	{
		garage_id: 233,
		part_id: 355,
		quantity: 20,
	},
	{
		garage_id: 233,
		part_id: 356,
		quantity: 332,
	},
	{
		garage_id: 233,
		part_id: 357,
		quantity: 54,
	},
	{
		garage_id: 233,
		part_id: 358,
		quantity: 164,
	},
	{
		garage_id: 233,
		part_id: 359,
		quantity: 164,
	},
	{
		garage_id: 233,
		part_id: 360,
		quantity: 163,
	},
	{
		garage_id: 233,
		part_id: 361,
		quantity: 56,
	},
	{
		garage_id: 233,
		part_id: 362,
		quantity: 359,
	},
	{
		garage_id: 233,
		part_id: 363,
		quantity: 481,
	},
	{
		garage_id: 233,
		part_id: 364,
		quantity: 182,
	},
	{
		garage_id: 233,
		part_id: 365,
		quantity: 77,
	},
	{
		garage_id: 233,
		part_id: 366,
		quantity: 224,
	},
	{
		garage_id: 233,
		part_id: 367,
		quantity: 195,
	},
	{
		garage_id: 233,
		part_id: 368,
		quantity: 109,
	},
	{
		garage_id: 233,
		part_id: 369,
		quantity: 236,
	},
	{
		garage_id: 233,
		part_id: 370,
		quantity: 321,
	},
	{
		garage_id: 233,
		part_id: 371,
		quantity: 98,
	},
	{
		garage_id: 233,
		part_id: 372,
		quantity: 460,
	},
	{
		garage_id: 233,
		part_id: 373,
		quantity: 194,
	},
	{
		garage_id: 233,
		part_id: 374,
		quantity: 496,
	},
	{
		garage_id: 233,
		part_id: 375,
		quantity: 312,
	},
	{
		garage_id: 233,
		part_id: 376,
		quantity: 89,
	},
	{
		garage_id: 233,
		part_id: 377,
		quantity: 114,
	},
	{
		garage_id: 233,
		part_id: 378,
		quantity: 470,
	},
	{
		garage_id: 233,
		part_id: 379,
		quantity: 12,
	},
	{
		garage_id: 233,
		part_id: 380,
		quantity: 100,
	},
	{
		garage_id: 233,
		part_id: 381,
		quantity: 279,
	},
	{
		garage_id: 233,
		part_id: 382,
		quantity: 425,
	},
	{
		garage_id: 233,
		part_id: 383,
		quantity: 214,
	},
	{
		garage_id: 233,
		part_id: 384,
		quantity: 435,
	},
	{
		garage_id: 233,
		part_id: 385,
		quantity: 147,
	},
	{
		garage_id: 233,
		part_id: 386,
		quantity: 400,
	},
	{
		garage_id: 233,
		part_id: 387,
		quantity: 430,
	},
	{
		garage_id: 233,
		part_id: 388,
		quantity: 299,
	},
	{
		garage_id: 233,
		part_id: 389,
		quantity: 135,
	},
	{
		garage_id: 233,
		part_id: 390,
		quantity: 175,
	},
	{
		garage_id: 233,
		part_id: 391,
		quantity: 383,
	},
	{
		garage_id: 233,
		part_id: 392,
		quantity: 64,
	},
	{
		garage_id: 233,
		part_id: 393,
		quantity: 113,
	},
	{
		garage_id: 233,
		part_id: 394,
		quantity: 3,
	},
	{
		garage_id: 233,
		part_id: 395,
		quantity: 298,
	},
	{
		garage_id: 233,
		part_id: 396,
		quantity: 450,
	},
	{
		garage_id: 233,
		part_id: 397,
		quantity: 311,
	},
	{
		garage_id: 233,
		part_id: 398,
		quantity: 307,
	},
	{
		garage_id: 233,
		part_id: 399,
		quantity: 133,
	},
	{
		garage_id: 234,
		part_id: 0,
		quantity: 304,
	},
	{
		garage_id: 234,
		part_id: 1,
		quantity: 402,
	},
	{
		garage_id: 234,
		part_id: 2,
		quantity: 435,
	},
	{
		garage_id: 234,
		part_id: 3,
		quantity: 199,
	},
	{
		garage_id: 234,
		part_id: 4,
		quantity: 6,
	},
	{
		garage_id: 234,
		part_id: 5,
		quantity: 372,
	},
	{
		garage_id: 234,
		part_id: 6,
		quantity: 56,
	},
	{
		garage_id: 234,
		part_id: 7,
		quantity: 139,
	},
	{
		garage_id: 234,
		part_id: 8,
		quantity: 354,
	},
	{
		garage_id: 234,
		part_id: 9,
		quantity: 375,
	},
	{
		garage_id: 234,
		part_id: 10,
		quantity: 318,
	},
	{
		garage_id: 234,
		part_id: 11,
		quantity: 198,
	},
	{
		garage_id: 234,
		part_id: 12,
		quantity: 456,
	},
	{
		garage_id: 234,
		part_id: 13,
		quantity: 253,
	},
	{
		garage_id: 234,
		part_id: 14,
		quantity: 500,
	},
	{
		garage_id: 234,
		part_id: 15,
		quantity: 112,
	},
	{
		garage_id: 234,
		part_id: 16,
		quantity: 380,
	},
	{
		garage_id: 234,
		part_id: 17,
		quantity: 226,
	},
	{
		garage_id: 234,
		part_id: 18,
		quantity: 337,
	},
	{
		garage_id: 234,
		part_id: 19,
		quantity: 177,
	},
	{
		garage_id: 234,
		part_id: 20,
		quantity: 9,
	},
	{
		garage_id: 234,
		part_id: 21,
		quantity: 40,
	},
	{
		garage_id: 234,
		part_id: 22,
		quantity: 386,
	},
	{
		garage_id: 234,
		part_id: 23,
		quantity: 330,
	},
	{
		garage_id: 234,
		part_id: 24,
		quantity: 347,
	},
	{
		garage_id: 234,
		part_id: 25,
		quantity: 205,
	},
	{
		garage_id: 234,
		part_id: 26,
		quantity: 309,
	},
	{
		garage_id: 234,
		part_id: 27,
		quantity: 190,
	},
	{
		garage_id: 234,
		part_id: 28,
		quantity: 126,
	},
	{
		garage_id: 234,
		part_id: 29,
		quantity: 237,
	},
	{
		garage_id: 234,
		part_id: 30,
		quantity: 300,
	},
	{
		garage_id: 234,
		part_id: 31,
		quantity: 12,
	},
	{
		garage_id: 234,
		part_id: 32,
		quantity: 188,
	},
	{
		garage_id: 234,
		part_id: 33,
		quantity: 340,
	},
	{
		garage_id: 234,
		part_id: 34,
		quantity: 8,
	},
	{
		garage_id: 234,
		part_id: 35,
		quantity: 443,
	},
	{
		garage_id: 234,
		part_id: 36,
		quantity: 396,
	},
	{
		garage_id: 234,
		part_id: 37,
		quantity: 230,
	},
	{
		garage_id: 234,
		part_id: 38,
		quantity: 28,
	},
	{
		garage_id: 234,
		part_id: 39,
		quantity: 255,
	},
	{
		garage_id: 234,
		part_id: 40,
		quantity: 454,
	},
	{
		garage_id: 234,
		part_id: 41,
		quantity: 176,
	},
	{
		garage_id: 234,
		part_id: 42,
		quantity: 490,
	},
	{
		garage_id: 234,
		part_id: 43,
		quantity: 328,
	},
	{
		garage_id: 234,
		part_id: 44,
		quantity: 296,
	},
	{
		garage_id: 234,
		part_id: 45,
		quantity: 28,
	},
	{
		garage_id: 234,
		part_id: 46,
		quantity: 222,
	},
	{
		garage_id: 234,
		part_id: 47,
		quantity: 393,
	},
	{
		garage_id: 234,
		part_id: 48,
		quantity: 181,
	},
	{
		garage_id: 234,
		part_id: 49,
		quantity: 348,
	},
	{
		garage_id: 234,
		part_id: 50,
		quantity: 250,
	},
	{
		garage_id: 234,
		part_id: 51,
		quantity: 275,
	},
	{
		garage_id: 234,
		part_id: 52,
		quantity: 54,
	},
	{
		garage_id: 234,
		part_id: 53,
		quantity: 134,
	},
	{
		garage_id: 234,
		part_id: 54,
		quantity: 474,
	},
	{
		garage_id: 234,
		part_id: 55,
		quantity: 396,
	},
	{
		garage_id: 234,
		part_id: 56,
		quantity: 499,
	},
	{
		garage_id: 234,
		part_id: 57,
		quantity: 113,
	},
	{
		garage_id: 234,
		part_id: 58,
		quantity: 89,
	},
	{
		garage_id: 234,
		part_id: 59,
		quantity: 2,
	},
	{
		garage_id: 234,
		part_id: 60,
		quantity: 8,
	},
	{
		garage_id: 234,
		part_id: 61,
		quantity: 120,
	},
	{
		garage_id: 234,
		part_id: 62,
		quantity: 13,
	},
	{
		garage_id: 234,
		part_id: 63,
		quantity: 104,
	},
	{
		garage_id: 234,
		part_id: 64,
		quantity: 452,
	},
	{
		garage_id: 234,
		part_id: 65,
		quantity: 453,
	},
	{
		garage_id: 234,
		part_id: 66,
		quantity: 208,
	},
	{
		garage_id: 234,
		part_id: 67,
		quantity: 441,
	},
	{
		garage_id: 234,
		part_id: 68,
		quantity: 348,
	},
	{
		garage_id: 234,
		part_id: 69,
		quantity: 414,
	},
	{
		garage_id: 234,
		part_id: 70,
		quantity: 421,
	},
	{
		garage_id: 234,
		part_id: 71,
		quantity: 51,
	},
	{
		garage_id: 234,
		part_id: 72,
		quantity: 152,
	},
	{
		garage_id: 234,
		part_id: 73,
		quantity: 498,
	},
	{
		garage_id: 234,
		part_id: 74,
		quantity: 163,
	},
	{
		garage_id: 234,
		part_id: 75,
		quantity: 268,
	},
	{
		garage_id: 234,
		part_id: 76,
		quantity: 83,
	},
	{
		garage_id: 234,
		part_id: 77,
		quantity: 362,
	},
	{
		garage_id: 234,
		part_id: 78,
		quantity: 263,
	},
	{
		garage_id: 234,
		part_id: 79,
		quantity: 483,
	},
	{
		garage_id: 234,
		part_id: 80,
		quantity: 69,
	},
	{
		garage_id: 234,
		part_id: 81,
		quantity: 306,
	},
	{
		garage_id: 234,
		part_id: 82,
		quantity: 78,
	},
	{
		garage_id: 234,
		part_id: 83,
		quantity: 407,
	},
	{
		garage_id: 234,
		part_id: 84,
		quantity: 309,
	},
	{
		garage_id: 234,
		part_id: 85,
		quantity: 67,
	},
	{
		garage_id: 234,
		part_id: 86,
		quantity: 257,
	},
	{
		garage_id: 234,
		part_id: 87,
		quantity: 419,
	},
	{
		garage_id: 234,
		part_id: 88,
		quantity: 258,
	},
	{
		garage_id: 234,
		part_id: 89,
		quantity: 342,
	},
	{
		garage_id: 234,
		part_id: 90,
		quantity: 67,
	},
	{
		garage_id: 234,
		part_id: 91,
		quantity: 79,
	},
	{
		garage_id: 234,
		part_id: 92,
		quantity: 470,
	},
	{
		garage_id: 234,
		part_id: 93,
		quantity: 153,
	},
	{
		garage_id: 234,
		part_id: 94,
		quantity: 230,
	},
	{
		garage_id: 234,
		part_id: 95,
		quantity: 341,
	},
	{
		garage_id: 234,
		part_id: 96,
		quantity: 285,
	},
	{
		garage_id: 234,
		part_id: 97,
		quantity: 500,
	},
	{
		garage_id: 234,
		part_id: 98,
		quantity: 250,
	},
	{
		garage_id: 234,
		part_id: 99,
		quantity: 40,
	},
	{
		garage_id: 234,
		part_id: 100,
		quantity: 23,
	},
	{
		garage_id: 234,
		part_id: 101,
		quantity: 470,
	},
	{
		garage_id: 234,
		part_id: 102,
		quantity: 81,
	},
	{
		garage_id: 234,
		part_id: 103,
		quantity: 50,
	},
	{
		garage_id: 234,
		part_id: 104,
		quantity: 107,
	},
	{
		garage_id: 234,
		part_id: 105,
		quantity: 311,
	},
	{
		garage_id: 234,
		part_id: 106,
		quantity: 430,
	},
	{
		garage_id: 234,
		part_id: 107,
		quantity: 31,
	},
	{
		garage_id: 234,
		part_id: 108,
		quantity: 79,
	},
	{
		garage_id: 234,
		part_id: 109,
		quantity: 378,
	},
	{
		garage_id: 234,
		part_id: 110,
		quantity: 156,
	},
	{
		garage_id: 234,
		part_id: 111,
		quantity: 393,
	},
	{
		garage_id: 234,
		part_id: 112,
		quantity: 358,
	},
	{
		garage_id: 234,
		part_id: 113,
		quantity: 427,
	},
	{
		garage_id: 234,
		part_id: 114,
		quantity: 47,
	},
	{
		garage_id: 234,
		part_id: 115,
		quantity: 174,
	},
	{
		garage_id: 234,
		part_id: 116,
		quantity: 166,
	},
	{
		garage_id: 234,
		part_id: 117,
		quantity: 477,
	},
	{
		garage_id: 234,
		part_id: 118,
		quantity: 198,
	},
	{
		garage_id: 234,
		part_id: 119,
		quantity: 150,
	},
	{
		garage_id: 234,
		part_id: 120,
		quantity: 54,
	},
	{
		garage_id: 234,
		part_id: 121,
		quantity: 43,
	},
	{
		garage_id: 234,
		part_id: 122,
		quantity: 283,
	},
	{
		garage_id: 234,
		part_id: 123,
		quantity: 139,
	},
	{
		garage_id: 234,
		part_id: 124,
		quantity: 429,
	},
	{
		garage_id: 234,
		part_id: 125,
		quantity: 498,
	},
	{
		garage_id: 234,
		part_id: 126,
		quantity: 216,
	},
	{
		garage_id: 234,
		part_id: 127,
		quantity: 177,
	},
	{
		garage_id: 234,
		part_id: 128,
		quantity: 198,
	},
	{
		garage_id: 234,
		part_id: 129,
		quantity: 471,
	},
	{
		garage_id: 234,
		part_id: 130,
		quantity: 433,
	},
	{
		garage_id: 234,
		part_id: 131,
		quantity: 478,
	},
	{
		garage_id: 234,
		part_id: 132,
		quantity: 265,
	},
	{
		garage_id: 234,
		part_id: 133,
		quantity: 162,
	},
	{
		garage_id: 234,
		part_id: 134,
		quantity: 231,
	},
	{
		garage_id: 234,
		part_id: 135,
		quantity: 162,
	},
	{
		garage_id: 234,
		part_id: 136,
		quantity: 313,
	},
	{
		garage_id: 234,
		part_id: 137,
		quantity: 264,
	},
	{
		garage_id: 234,
		part_id: 138,
		quantity: 11,
	},
	{
		garage_id: 234,
		part_id: 139,
		quantity: 140,
	},
	{
		garage_id: 234,
		part_id: 140,
		quantity: 251,
	},
	{
		garage_id: 234,
		part_id: 141,
		quantity: 25,
	},
	{
		garage_id: 234,
		part_id: 142,
		quantity: 279,
	},
	{
		garage_id: 234,
		part_id: 143,
		quantity: 325,
	},
	{
		garage_id: 234,
		part_id: 144,
		quantity: 414,
	},
	{
		garage_id: 234,
		part_id: 145,
		quantity: 165,
	},
	{
		garage_id: 234,
		part_id: 146,
		quantity: 309,
	},
	{
		garage_id: 234,
		part_id: 147,
		quantity: 55,
	},
	{
		garage_id: 234,
		part_id: 148,
		quantity: 388,
	},
	{
		garage_id: 234,
		part_id: 149,
		quantity: 109,
	},
	{
		garage_id: 234,
		part_id: 150,
		quantity: 481,
	},
	{
		garage_id: 234,
		part_id: 151,
		quantity: 396,
	},
	{
		garage_id: 234,
		part_id: 152,
		quantity: 456,
	},
	{
		garage_id: 234,
		part_id: 153,
		quantity: 293,
	},
	{
		garage_id: 234,
		part_id: 154,
		quantity: 465,
	},
	{
		garage_id: 234,
		part_id: 155,
		quantity: 159,
	},
	{
		garage_id: 234,
		part_id: 156,
		quantity: 25,
	},
	{
		garage_id: 234,
		part_id: 157,
		quantity: 187,
	},
	{
		garage_id: 234,
		part_id: 158,
		quantity: 25,
	},
	{
		garage_id: 234,
		part_id: 159,
		quantity: 409,
	},
	{
		garage_id: 234,
		part_id: 160,
		quantity: 324,
	},
	{
		garage_id: 234,
		part_id: 161,
		quantity: 228,
	},
	{
		garage_id: 234,
		part_id: 162,
		quantity: 54,
	},
	{
		garage_id: 234,
		part_id: 163,
		quantity: 37,
	},
	{
		garage_id: 234,
		part_id: 164,
		quantity: 57,
	},
	{
		garage_id: 234,
		part_id: 165,
		quantity: 260,
	},
	{
		garage_id: 234,
		part_id: 166,
		quantity: 199,
	},
	{
		garage_id: 234,
		part_id: 167,
		quantity: 141,
	},
	{
		garage_id: 234,
		part_id: 168,
		quantity: 482,
	},
	{
		garage_id: 234,
		part_id: 169,
		quantity: 494,
	},
	{
		garage_id: 234,
		part_id: 170,
		quantity: 41,
	},
	{
		garage_id: 234,
		part_id: 171,
		quantity: 15,
	},
	{
		garage_id: 234,
		part_id: 172,
		quantity: 220,
	},
	{
		garage_id: 234,
		part_id: 173,
		quantity: 140,
	},
	{
		garage_id: 234,
		part_id: 174,
		quantity: 338,
	},
	{
		garage_id: 234,
		part_id: 175,
		quantity: 167,
	},
	{
		garage_id: 234,
		part_id: 176,
		quantity: 453,
	},
	{
		garage_id: 234,
		part_id: 177,
		quantity: 400,
	},
	{
		garage_id: 234,
		part_id: 178,
		quantity: 290,
	},
	{
		garage_id: 234,
		part_id: 179,
		quantity: 31,
	},
	{
		garage_id: 234,
		part_id: 180,
		quantity: 323,
	},
	{
		garage_id: 234,
		part_id: 181,
		quantity: 299,
	},
	{
		garage_id: 234,
		part_id: 182,
		quantity: 390,
	},
	{
		garage_id: 234,
		part_id: 183,
		quantity: 260,
	},
	{
		garage_id: 234,
		part_id: 184,
		quantity: 219,
	},
	{
		garage_id: 234,
		part_id: 185,
		quantity: 114,
	},
	{
		garage_id: 234,
		part_id: 186,
		quantity: 378,
	},
	{
		garage_id: 234,
		part_id: 187,
		quantity: 103,
	},
	{
		garage_id: 234,
		part_id: 188,
		quantity: 156,
	},
	{
		garage_id: 234,
		part_id: 189,
		quantity: 16,
	},
	{
		garage_id: 234,
		part_id: 190,
		quantity: 78,
	},
	{
		garage_id: 234,
		part_id: 191,
		quantity: 300,
	},
	{
		garage_id: 234,
		part_id: 192,
		quantity: 136,
	},
	{
		garage_id: 234,
		part_id: 193,
		quantity: 243,
	},
	{
		garage_id: 234,
		part_id: 194,
		quantity: 144,
	},
	{
		garage_id: 234,
		part_id: 195,
		quantity: 374,
	},
	{
		garage_id: 234,
		part_id: 196,
		quantity: 449,
	},
	{
		garage_id: 234,
		part_id: 197,
		quantity: 389,
	},
	{
		garage_id: 234,
		part_id: 198,
		quantity: 464,
	},
	{
		garage_id: 234,
		part_id: 199,
		quantity: 271,
	},
	{
		garage_id: 234,
		part_id: 200,
		quantity: 99,
	},
	{
		garage_id: 234,
		part_id: 201,
		quantity: 480,
	},
	{
		garage_id: 234,
		part_id: 202,
		quantity: 475,
	},
	{
		garage_id: 234,
		part_id: 203,
		quantity: 23,
	},
	{
		garage_id: 234,
		part_id: 204,
		quantity: 269,
	},
	{
		garage_id: 234,
		part_id: 205,
		quantity: 241,
	},
	{
		garage_id: 234,
		part_id: 206,
		quantity: 456,
	},
	{
		garage_id: 234,
		part_id: 207,
		quantity: 164,
	},
	{
		garage_id: 234,
		part_id: 208,
		quantity: 497,
	},
	{
		garage_id: 234,
		part_id: 209,
		quantity: 208,
	},
	{
		garage_id: 234,
		part_id: 210,
		quantity: 235,
	},
	{
		garage_id: 234,
		part_id: 211,
		quantity: 370,
	},
	{
		garage_id: 234,
		part_id: 212,
		quantity: 225,
	},
	{
		garage_id: 234,
		part_id: 213,
		quantity: 407,
	},
	{
		garage_id: 234,
		part_id: 214,
		quantity: 170,
	},
	{
		garage_id: 234,
		part_id: 215,
		quantity: 345,
	},
	{
		garage_id: 234,
		part_id: 216,
		quantity: 449,
	},
	{
		garage_id: 234,
		part_id: 217,
		quantity: 10,
	},
	{
		garage_id: 234,
		part_id: 218,
		quantity: 450,
	},
	{
		garage_id: 234,
		part_id: 219,
		quantity: 500,
	},
	{
		garage_id: 234,
		part_id: 220,
		quantity: 230,
	},
	{
		garage_id: 234,
		part_id: 221,
		quantity: 203,
	},
	{
		garage_id: 234,
		part_id: 222,
		quantity: 227,
	},
	{
		garage_id: 234,
		part_id: 223,
		quantity: 206,
	},
	{
		garage_id: 234,
		part_id: 224,
		quantity: 220,
	},
	{
		garage_id: 234,
		part_id: 225,
		quantity: 100,
	},
	{
		garage_id: 234,
		part_id: 226,
		quantity: 199,
	},
	{
		garage_id: 234,
		part_id: 227,
		quantity: 308,
	},
	{
		garage_id: 234,
		part_id: 228,
		quantity: 198,
	},
	{
		garage_id: 234,
		part_id: 229,
		quantity: 22,
	},
	{
		garage_id: 234,
		part_id: 230,
		quantity: 330,
	},
	{
		garage_id: 234,
		part_id: 231,
		quantity: 75,
	},
	{
		garage_id: 234,
		part_id: 232,
		quantity: 378,
	},
	{
		garage_id: 234,
		part_id: 233,
		quantity: 76,
	},
	{
		garage_id: 234,
		part_id: 234,
		quantity: 140,
	},
	{
		garage_id: 234,
		part_id: 235,
		quantity: 183,
	},
	{
		garage_id: 234,
		part_id: 236,
		quantity: 308,
	},
	{
		garage_id: 234,
		part_id: 237,
		quantity: 401,
	},
	{
		garage_id: 234,
		part_id: 238,
		quantity: 330,
	},
	{
		garage_id: 234,
		part_id: 239,
		quantity: 36,
	},
	{
		garage_id: 234,
		part_id: 240,
		quantity: 491,
	},
	{
		garage_id: 234,
		part_id: 241,
		quantity: 60,
	},
	{
		garage_id: 234,
		part_id: 242,
		quantity: 31,
	},
	{
		garage_id: 234,
		part_id: 243,
		quantity: 357,
	},
	{
		garage_id: 234,
		part_id: 244,
		quantity: 67,
	},
	{
		garage_id: 234,
		part_id: 245,
		quantity: 176,
	},
	{
		garage_id: 234,
		part_id: 246,
		quantity: 287,
	},
	{
		garage_id: 234,
		part_id: 247,
		quantity: 192,
	},
	{
		garage_id: 234,
		part_id: 248,
		quantity: 371,
	},
	{
		garage_id: 234,
		part_id: 249,
		quantity: 322,
	},
	{
		garage_id: 234,
		part_id: 250,
		quantity: 33,
	},
	{
		garage_id: 234,
		part_id: 251,
		quantity: 170,
	},
	{
		garage_id: 234,
		part_id: 252,
		quantity: 14,
	},
	{
		garage_id: 234,
		part_id: 253,
		quantity: 426,
	},
	{
		garage_id: 234,
		part_id: 254,
		quantity: 57,
	},
	{
		garage_id: 234,
		part_id: 255,
		quantity: 163,
	},
	{
		garage_id: 234,
		part_id: 256,
		quantity: 117,
	},
	{
		garage_id: 234,
		part_id: 257,
		quantity: 169,
	},
	{
		garage_id: 234,
		part_id: 258,
		quantity: 126,
	},
	{
		garage_id: 234,
		part_id: 259,
		quantity: 159,
	},
	{
		garage_id: 234,
		part_id: 260,
		quantity: 90,
	},
	{
		garage_id: 234,
		part_id: 261,
		quantity: 434,
	},
	{
		garage_id: 234,
		part_id: 262,
		quantity: 461,
	},
	{
		garage_id: 234,
		part_id: 263,
		quantity: 310,
	},
	{
		garage_id: 234,
		part_id: 264,
		quantity: 177,
	},
	{
		garage_id: 234,
		part_id: 265,
		quantity: 0,
	},
	{
		garage_id: 234,
		part_id: 266,
		quantity: 41,
	},
	{
		garage_id: 234,
		part_id: 267,
		quantity: 496,
	},
	{
		garage_id: 234,
		part_id: 268,
		quantity: 218,
	},
	{
		garage_id: 234,
		part_id: 269,
		quantity: 58,
	},
	{
		garage_id: 234,
		part_id: 270,
		quantity: 434,
	},
	{
		garage_id: 234,
		part_id: 271,
		quantity: 59,
	},
	{
		garage_id: 234,
		part_id: 272,
		quantity: 356,
	},
	{
		garage_id: 234,
		part_id: 273,
		quantity: 37,
	},
	{
		garage_id: 234,
		part_id: 274,
		quantity: 421,
	},
	{
		garage_id: 234,
		part_id: 275,
		quantity: 441,
	},
	{
		garage_id: 234,
		part_id: 276,
		quantity: 208,
	},
	{
		garage_id: 234,
		part_id: 277,
		quantity: 261,
	},
	{
		garage_id: 234,
		part_id: 278,
		quantity: 3,
	},
	{
		garage_id: 234,
		part_id: 279,
		quantity: 362,
	},
	{
		garage_id: 234,
		part_id: 280,
		quantity: 218,
	},
	{
		garage_id: 234,
		part_id: 281,
		quantity: 409,
	},
	{
		garage_id: 234,
		part_id: 282,
		quantity: 26,
	},
	{
		garage_id: 234,
		part_id: 283,
		quantity: 436,
	},
	{
		garage_id: 234,
		part_id: 284,
		quantity: 59,
	},
	{
		garage_id: 234,
		part_id: 285,
		quantity: 273,
	},
	{
		garage_id: 234,
		part_id: 286,
		quantity: 40,
	},
	{
		garage_id: 234,
		part_id: 287,
		quantity: 133,
	},
	{
		garage_id: 234,
		part_id: 288,
		quantity: 378,
	},
	{
		garage_id: 234,
		part_id: 289,
		quantity: 206,
	},
	{
		garage_id: 234,
		part_id: 290,
		quantity: 234,
	},
	{
		garage_id: 234,
		part_id: 291,
		quantity: 206,
	},
	{
		garage_id: 234,
		part_id: 292,
		quantity: 218,
	},
	{
		garage_id: 234,
		part_id: 293,
		quantity: 58,
	},
	{
		garage_id: 234,
		part_id: 294,
		quantity: 160,
	},
	{
		garage_id: 234,
		part_id: 295,
		quantity: 284,
	},
	{
		garage_id: 234,
		part_id: 296,
		quantity: 450,
	},
	{
		garage_id: 234,
		part_id: 297,
		quantity: 458,
	},
	{
		garage_id: 234,
		part_id: 298,
		quantity: 353,
	},
	{
		garage_id: 234,
		part_id: 299,
		quantity: 483,
	},
	{
		garage_id: 234,
		part_id: 300,
		quantity: 137,
	},
	{
		garage_id: 234,
		part_id: 301,
		quantity: 364,
	},
	{
		garage_id: 234,
		part_id: 302,
		quantity: 69,
	},
	{
		garage_id: 234,
		part_id: 303,
		quantity: 83,
	},
	{
		garage_id: 234,
		part_id: 304,
		quantity: 348,
	},
	{
		garage_id: 234,
		part_id: 305,
		quantity: 43,
	},
	{
		garage_id: 234,
		part_id: 306,
		quantity: 123,
	},
	{
		garage_id: 234,
		part_id: 307,
		quantity: 75,
	},
	{
		garage_id: 234,
		part_id: 308,
		quantity: 69,
	},
	{
		garage_id: 234,
		part_id: 309,
		quantity: 493,
	},
	{
		garage_id: 234,
		part_id: 310,
		quantity: 448,
	},
	{
		garage_id: 234,
		part_id: 311,
		quantity: 50,
	},
	{
		garage_id: 234,
		part_id: 312,
		quantity: 496,
	},
	{
		garage_id: 234,
		part_id: 313,
		quantity: 375,
	},
	{
		garage_id: 234,
		part_id: 314,
		quantity: 64,
	},
	{
		garage_id: 234,
		part_id: 315,
		quantity: 205,
	},
	{
		garage_id: 234,
		part_id: 316,
		quantity: 30,
	},
	{
		garage_id: 234,
		part_id: 317,
		quantity: 26,
	},
	{
		garage_id: 234,
		part_id: 318,
		quantity: 396,
	},
	{
		garage_id: 234,
		part_id: 319,
		quantity: 259,
	},
	{
		garage_id: 234,
		part_id: 320,
		quantity: 109,
	},
	{
		garage_id: 234,
		part_id: 321,
		quantity: 86,
	},
	{
		garage_id: 234,
		part_id: 322,
		quantity: 437,
	},
	{
		garage_id: 234,
		part_id: 323,
		quantity: 173,
	},
	{
		garage_id: 234,
		part_id: 324,
		quantity: 129,
	},
	{
		garage_id: 234,
		part_id: 325,
		quantity: 180,
	},
	{
		garage_id: 234,
		part_id: 326,
		quantity: 494,
	},
	{
		garage_id: 234,
		part_id: 327,
		quantity: 412,
	},
	{
		garage_id: 234,
		part_id: 328,
		quantity: 378,
	},
	{
		garage_id: 234,
		part_id: 329,
		quantity: 230,
	},
	{
		garage_id: 234,
		part_id: 330,
		quantity: 254,
	},
	{
		garage_id: 234,
		part_id: 331,
		quantity: 340,
	},
	{
		garage_id: 234,
		part_id: 332,
		quantity: 468,
	},
	{
		garage_id: 234,
		part_id: 333,
		quantity: 96,
	},
	{
		garage_id: 234,
		part_id: 334,
		quantity: 423,
	},
	{
		garage_id: 234,
		part_id: 335,
		quantity: 136,
	},
	{
		garage_id: 234,
		part_id: 336,
		quantity: 223,
	},
	{
		garage_id: 234,
		part_id: 337,
		quantity: 485,
	},
	{
		garage_id: 234,
		part_id: 338,
		quantity: 472,
	},
	{
		garage_id: 234,
		part_id: 339,
		quantity: 218,
	},
	{
		garage_id: 234,
		part_id: 340,
		quantity: 289,
	},
	{
		garage_id: 234,
		part_id: 341,
		quantity: 111,
	},
	{
		garage_id: 234,
		part_id: 342,
		quantity: 61,
	},
	{
		garage_id: 234,
		part_id: 343,
		quantity: 118,
	},
	{
		garage_id: 234,
		part_id: 344,
		quantity: 167,
	},
	{
		garage_id: 234,
		part_id: 345,
		quantity: 488,
	},
	{
		garage_id: 234,
		part_id: 346,
		quantity: 416,
	},
	{
		garage_id: 234,
		part_id: 347,
		quantity: 495,
	},
	{
		garage_id: 234,
		part_id: 348,
		quantity: 186,
	},
	{
		garage_id: 234,
		part_id: 349,
		quantity: 11,
	},
	{
		garage_id: 234,
		part_id: 350,
		quantity: 296,
	},
	{
		garage_id: 234,
		part_id: 351,
		quantity: 253,
	},
	{
		garage_id: 234,
		part_id: 352,
		quantity: 428,
	},
	{
		garage_id: 234,
		part_id: 353,
		quantity: 425,
	},
	{
		garage_id: 234,
		part_id: 354,
		quantity: 457,
	},
	{
		garage_id: 234,
		part_id: 355,
		quantity: 296,
	},
	{
		garage_id: 234,
		part_id: 356,
		quantity: 251,
	},
	{
		garage_id: 234,
		part_id: 357,
		quantity: 55,
	},
	{
		garage_id: 234,
		part_id: 358,
		quantity: 43,
	},
	{
		garage_id: 234,
		part_id: 359,
		quantity: 112,
	},
	{
		garage_id: 234,
		part_id: 360,
		quantity: 129,
	},
	{
		garage_id: 234,
		part_id: 361,
		quantity: 4,
	},
	{
		garage_id: 234,
		part_id: 362,
		quantity: 355,
	},
	{
		garage_id: 234,
		part_id: 363,
		quantity: 55,
	},
	{
		garage_id: 234,
		part_id: 364,
		quantity: 216,
	},
	{
		garage_id: 234,
		part_id: 365,
		quantity: 344,
	},
	{
		garage_id: 234,
		part_id: 366,
		quantity: 69,
	},
	{
		garage_id: 234,
		part_id: 367,
		quantity: 260,
	},
	{
		garage_id: 234,
		part_id: 368,
		quantity: 269,
	},
	{
		garage_id: 234,
		part_id: 369,
		quantity: 480,
	},
	{
		garage_id: 234,
		part_id: 370,
		quantity: 415,
	},
	{
		garage_id: 234,
		part_id: 371,
		quantity: 424,
	},
	{
		garage_id: 234,
		part_id: 372,
		quantity: 147,
	},
	{
		garage_id: 234,
		part_id: 373,
		quantity: 306,
	},
	{
		garage_id: 234,
		part_id: 374,
		quantity: 426,
	},
	{
		garage_id: 234,
		part_id: 375,
		quantity: 169,
	},
	{
		garage_id: 234,
		part_id: 376,
		quantity: 487,
	},
	{
		garage_id: 234,
		part_id: 377,
		quantity: 357,
	},
	{
		garage_id: 234,
		part_id: 378,
		quantity: 250,
	},
	{
		garage_id: 234,
		part_id: 379,
		quantity: 251,
	},
	{
		garage_id: 234,
		part_id: 380,
		quantity: 313,
	},
	{
		garage_id: 234,
		part_id: 381,
		quantity: 7,
	},
	{
		garage_id: 234,
		part_id: 382,
		quantity: 480,
	},
	{
		garage_id: 234,
		part_id: 383,
		quantity: 216,
	},
	{
		garage_id: 234,
		part_id: 384,
		quantity: 405,
	},
	{
		garage_id: 234,
		part_id: 385,
		quantity: 227,
	},
	{
		garage_id: 234,
		part_id: 386,
		quantity: 262,
	},
	{
		garage_id: 234,
		part_id: 387,
		quantity: 51,
	},
	{
		garage_id: 234,
		part_id: 388,
		quantity: 301,
	},
	{
		garage_id: 234,
		part_id: 389,
		quantity: 115,
	},
	{
		garage_id: 234,
		part_id: 390,
		quantity: 82,
	},
	{
		garage_id: 234,
		part_id: 391,
		quantity: 238,
	},
	{
		garage_id: 234,
		part_id: 392,
		quantity: 411,
	},
	{
		garage_id: 234,
		part_id: 393,
		quantity: 9,
	},
	{
		garage_id: 234,
		part_id: 394,
		quantity: 367,
	},
	{
		garage_id: 234,
		part_id: 395,
		quantity: 122,
	},
	{
		garage_id: 234,
		part_id: 396,
		quantity: 497,
	},
	{
		garage_id: 234,
		part_id: 397,
		quantity: 382,
	},
	{
		garage_id: 234,
		part_id: 398,
		quantity: 460,
	},
	{
		garage_id: 234,
		part_id: 399,
		quantity: 263,
	},
	{
		garage_id: 235,
		part_id: 0,
		quantity: 463,
	},
	{
		garage_id: 235,
		part_id: 1,
		quantity: 392,
	},
	{
		garage_id: 235,
		part_id: 2,
		quantity: 398,
	},
	{
		garage_id: 235,
		part_id: 3,
		quantity: 479,
	},
	{
		garage_id: 235,
		part_id: 4,
		quantity: 211,
	},
	{
		garage_id: 235,
		part_id: 5,
		quantity: 377,
	},
	{
		garage_id: 235,
		part_id: 6,
		quantity: 330,
	},
	{
		garage_id: 235,
		part_id: 7,
		quantity: 309,
	},
	{
		garage_id: 235,
		part_id: 8,
		quantity: 465,
	},
	{
		garage_id: 235,
		part_id: 9,
		quantity: 389,
	},
	{
		garage_id: 235,
		part_id: 10,
		quantity: 79,
	},
	{
		garage_id: 235,
		part_id: 11,
		quantity: 77,
	},
	{
		garage_id: 235,
		part_id: 12,
		quantity: 85,
	},
	{
		garage_id: 235,
		part_id: 13,
		quantity: 41,
	},
	{
		garage_id: 235,
		part_id: 14,
		quantity: 274,
	},
	{
		garage_id: 235,
		part_id: 15,
		quantity: 97,
	},
	{
		garage_id: 235,
		part_id: 16,
		quantity: 6,
	},
	{
		garage_id: 235,
		part_id: 17,
		quantity: 255,
	},
	{
		garage_id: 235,
		part_id: 18,
		quantity: 59,
	},
	{
		garage_id: 235,
		part_id: 19,
		quantity: 196,
	},
	{
		garage_id: 235,
		part_id: 20,
		quantity: 267,
	},
	{
		garage_id: 235,
		part_id: 21,
		quantity: 172,
	},
	{
		garage_id: 235,
		part_id: 22,
		quantity: 226,
	},
	{
		garage_id: 235,
		part_id: 23,
		quantity: 48,
	},
	{
		garage_id: 235,
		part_id: 24,
		quantity: 452,
	},
	{
		garage_id: 235,
		part_id: 25,
		quantity: 28,
	},
	{
		garage_id: 235,
		part_id: 26,
		quantity: 368,
	},
	{
		garage_id: 235,
		part_id: 27,
		quantity: 396,
	},
	{
		garage_id: 235,
		part_id: 28,
		quantity: 26,
	},
	{
		garage_id: 235,
		part_id: 29,
		quantity: 292,
	},
	{
		garage_id: 235,
		part_id: 30,
		quantity: 30,
	},
	{
		garage_id: 235,
		part_id: 31,
		quantity: 384,
	},
	{
		garage_id: 235,
		part_id: 32,
		quantity: 403,
	},
	{
		garage_id: 235,
		part_id: 33,
		quantity: 439,
	},
	{
		garage_id: 235,
		part_id: 34,
		quantity: 87,
	},
	{
		garage_id: 235,
		part_id: 35,
		quantity: 407,
	},
	{
		garage_id: 235,
		part_id: 36,
		quantity: 171,
	},
	{
		garage_id: 235,
		part_id: 37,
		quantity: 282,
	},
	{
		garage_id: 235,
		part_id: 38,
		quantity: 72,
	},
	{
		garage_id: 235,
		part_id: 39,
		quantity: 325,
	},
	{
		garage_id: 235,
		part_id: 40,
		quantity: 7,
	},
	{
		garage_id: 235,
		part_id: 41,
		quantity: 46,
	},
	{
		garage_id: 235,
		part_id: 42,
		quantity: 404,
	},
	{
		garage_id: 235,
		part_id: 43,
		quantity: 327,
	},
	{
		garage_id: 235,
		part_id: 44,
		quantity: 131,
	},
	{
		garage_id: 235,
		part_id: 45,
		quantity: 263,
	},
	{
		garage_id: 235,
		part_id: 46,
		quantity: 100,
	},
	{
		garage_id: 235,
		part_id: 47,
		quantity: 51,
	},
	{
		garage_id: 235,
		part_id: 48,
		quantity: 232,
	},
	{
		garage_id: 235,
		part_id: 49,
		quantity: 36,
	},
	{
		garage_id: 235,
		part_id: 50,
		quantity: 248,
	},
	{
		garage_id: 235,
		part_id: 51,
		quantity: 433,
	},
	{
		garage_id: 235,
		part_id: 52,
		quantity: 213,
	},
	{
		garage_id: 235,
		part_id: 53,
		quantity: 30,
	},
	{
		garage_id: 235,
		part_id: 54,
		quantity: 64,
	},
	{
		garage_id: 235,
		part_id: 55,
		quantity: 456,
	},
	{
		garage_id: 235,
		part_id: 56,
		quantity: 367,
	},
	{
		garage_id: 235,
		part_id: 57,
		quantity: 142,
	},
	{
		garage_id: 235,
		part_id: 58,
		quantity: 474,
	},
	{
		garage_id: 235,
		part_id: 59,
		quantity: 276,
	},
	{
		garage_id: 235,
		part_id: 60,
		quantity: 227,
	},
	{
		garage_id: 235,
		part_id: 61,
		quantity: 207,
	},
	{
		garage_id: 235,
		part_id: 62,
		quantity: 101,
	},
	{
		garage_id: 235,
		part_id: 63,
		quantity: 401,
	},
	{
		garage_id: 235,
		part_id: 64,
		quantity: 373,
	},
	{
		garage_id: 235,
		part_id: 65,
		quantity: 355,
	},
	{
		garage_id: 235,
		part_id: 66,
		quantity: 72,
	},
	{
		garage_id: 235,
		part_id: 67,
		quantity: 170,
	},
	{
		garage_id: 235,
		part_id: 68,
		quantity: 323,
	},
	{
		garage_id: 235,
		part_id: 69,
		quantity: 150,
	},
	{
		garage_id: 235,
		part_id: 70,
		quantity: 58,
	},
	{
		garage_id: 235,
		part_id: 71,
		quantity: 190,
	},
	{
		garage_id: 235,
		part_id: 72,
		quantity: 250,
	},
	{
		garage_id: 235,
		part_id: 73,
		quantity: 77,
	},
	{
		garage_id: 235,
		part_id: 74,
		quantity: 14,
	},
	{
		garage_id: 235,
		part_id: 75,
		quantity: 428,
	},
	{
		garage_id: 235,
		part_id: 76,
		quantity: 118,
	},
	{
		garage_id: 235,
		part_id: 77,
		quantity: 85,
	},
	{
		garage_id: 235,
		part_id: 78,
		quantity: 90,
	},
	{
		garage_id: 235,
		part_id: 79,
		quantity: 200,
	},
	{
		garage_id: 235,
		part_id: 80,
		quantity: 71,
	},
	{
		garage_id: 235,
		part_id: 81,
		quantity: 163,
	},
	{
		garage_id: 235,
		part_id: 82,
		quantity: 360,
	},
	{
		garage_id: 235,
		part_id: 83,
		quantity: 152,
	},
	{
		garage_id: 235,
		part_id: 84,
		quantity: 72,
	},
	{
		garage_id: 235,
		part_id: 85,
		quantity: 288,
	},
	{
		garage_id: 235,
		part_id: 86,
		quantity: 424,
	},
	{
		garage_id: 235,
		part_id: 87,
		quantity: 377,
	},
	{
		garage_id: 235,
		part_id: 88,
		quantity: 252,
	},
	{
		garage_id: 235,
		part_id: 89,
		quantity: 0,
	},
	{
		garage_id: 235,
		part_id: 90,
		quantity: 446,
	},
	{
		garage_id: 235,
		part_id: 91,
		quantity: 473,
	},
	{
		garage_id: 235,
		part_id: 92,
		quantity: 272,
	},
	{
		garage_id: 235,
		part_id: 93,
		quantity: 235,
	},
	{
		garage_id: 235,
		part_id: 94,
		quantity: 133,
	},
	{
		garage_id: 235,
		part_id: 95,
		quantity: 233,
	},
	{
		garage_id: 235,
		part_id: 96,
		quantity: 430,
	},
	{
		garage_id: 235,
		part_id: 97,
		quantity: 66,
	},
	{
		garage_id: 235,
		part_id: 98,
		quantity: 479,
	},
	{
		garage_id: 235,
		part_id: 99,
		quantity: 125,
	},
	{
		garage_id: 235,
		part_id: 100,
		quantity: 281,
	},
	{
		garage_id: 235,
		part_id: 101,
		quantity: 352,
	},
	{
		garage_id: 235,
		part_id: 102,
		quantity: 2,
	},
	{
		garage_id: 235,
		part_id: 103,
		quantity: 181,
	},
	{
		garage_id: 235,
		part_id: 104,
		quantity: 423,
	},
	{
		garage_id: 235,
		part_id: 105,
		quantity: 44,
	},
	{
		garage_id: 235,
		part_id: 106,
		quantity: 30,
	},
	{
		garage_id: 235,
		part_id: 107,
		quantity: 428,
	},
	{
		garage_id: 235,
		part_id: 108,
		quantity: 262,
	},
	{
		garage_id: 235,
		part_id: 109,
		quantity: 206,
	},
	{
		garage_id: 235,
		part_id: 110,
		quantity: 137,
	},
	{
		garage_id: 235,
		part_id: 111,
		quantity: 111,
	},
	{
		garage_id: 235,
		part_id: 112,
		quantity: 329,
	},
	{
		garage_id: 235,
		part_id: 113,
		quantity: 436,
	},
	{
		garage_id: 235,
		part_id: 114,
		quantity: 230,
	},
	{
		garage_id: 235,
		part_id: 115,
		quantity: 303,
	},
	{
		garage_id: 235,
		part_id: 116,
		quantity: 156,
	},
	{
		garage_id: 235,
		part_id: 117,
		quantity: 482,
	},
	{
		garage_id: 235,
		part_id: 118,
		quantity: 154,
	},
	{
		garage_id: 235,
		part_id: 119,
		quantity: 233,
	},
	{
		garage_id: 235,
		part_id: 120,
		quantity: 500,
	},
	{
		garage_id: 235,
		part_id: 121,
		quantity: 414,
	},
	{
		garage_id: 235,
		part_id: 122,
		quantity: 387,
	},
	{
		garage_id: 235,
		part_id: 123,
		quantity: 170,
	},
	{
		garage_id: 235,
		part_id: 124,
		quantity: 377,
	},
	{
		garage_id: 235,
		part_id: 125,
		quantity: 105,
	},
	{
		garage_id: 235,
		part_id: 126,
		quantity: 407,
	},
	{
		garage_id: 235,
		part_id: 127,
		quantity: 105,
	},
	{
		garage_id: 235,
		part_id: 128,
		quantity: 296,
	},
	{
		garage_id: 235,
		part_id: 129,
		quantity: 272,
	},
	{
		garage_id: 235,
		part_id: 130,
		quantity: 59,
	},
	{
		garage_id: 235,
		part_id: 131,
		quantity: 242,
	},
	{
		garage_id: 235,
		part_id: 132,
		quantity: 453,
	},
	{
		garage_id: 235,
		part_id: 133,
		quantity: 61,
	},
	{
		garage_id: 235,
		part_id: 134,
		quantity: 126,
	},
	{
		garage_id: 235,
		part_id: 135,
		quantity: 417,
	},
	{
		garage_id: 235,
		part_id: 136,
		quantity: 344,
	},
	{
		garage_id: 235,
		part_id: 137,
		quantity: 53,
	},
	{
		garage_id: 235,
		part_id: 138,
		quantity: 21,
	},
	{
		garage_id: 235,
		part_id: 139,
		quantity: 40,
	},
	{
		garage_id: 235,
		part_id: 140,
		quantity: 395,
	},
	{
		garage_id: 235,
		part_id: 141,
		quantity: 296,
	},
	{
		garage_id: 235,
		part_id: 142,
		quantity: 255,
	},
	{
		garage_id: 235,
		part_id: 143,
		quantity: 175,
	},
	{
		garage_id: 235,
		part_id: 144,
		quantity: 182,
	},
	{
		garage_id: 235,
		part_id: 145,
		quantity: 266,
	},
	{
		garage_id: 235,
		part_id: 146,
		quantity: 233,
	},
	{
		garage_id: 235,
		part_id: 147,
		quantity: 470,
	},
	{
		garage_id: 235,
		part_id: 148,
		quantity: 102,
	},
	{
		garage_id: 235,
		part_id: 149,
		quantity: 198,
	},
	{
		garage_id: 235,
		part_id: 150,
		quantity: 20,
	},
	{
		garage_id: 235,
		part_id: 151,
		quantity: 182,
	},
	{
		garage_id: 235,
		part_id: 152,
		quantity: 436,
	},
	{
		garage_id: 235,
		part_id: 153,
		quantity: 137,
	},
	{
		garage_id: 235,
		part_id: 154,
		quantity: 187,
	},
	{
		garage_id: 235,
		part_id: 155,
		quantity: 258,
	},
	{
		garage_id: 235,
		part_id: 156,
		quantity: 95,
	},
	{
		garage_id: 235,
		part_id: 157,
		quantity: 275,
	},
	{
		garage_id: 235,
		part_id: 158,
		quantity: 153,
	},
	{
		garage_id: 235,
		part_id: 159,
		quantity: 135,
	},
	{
		garage_id: 235,
		part_id: 160,
		quantity: 64,
	},
	{
		garage_id: 235,
		part_id: 161,
		quantity: 345,
	},
	{
		garage_id: 235,
		part_id: 162,
		quantity: 399,
	},
	{
		garage_id: 235,
		part_id: 163,
		quantity: 271,
	},
	{
		garage_id: 235,
		part_id: 164,
		quantity: 419,
	},
	{
		garage_id: 235,
		part_id: 165,
		quantity: 258,
	},
	{
		garage_id: 235,
		part_id: 166,
		quantity: 214,
	},
	{
		garage_id: 235,
		part_id: 167,
		quantity: 437,
	},
	{
		garage_id: 235,
		part_id: 168,
		quantity: 209,
	},
	{
		garage_id: 235,
		part_id: 169,
		quantity: 482,
	},
	{
		garage_id: 235,
		part_id: 170,
		quantity: 126,
	},
	{
		garage_id: 235,
		part_id: 171,
		quantity: 486,
	},
	{
		garage_id: 235,
		part_id: 172,
		quantity: 158,
	},
	{
		garage_id: 235,
		part_id: 173,
		quantity: 107,
	},
	{
		garage_id: 235,
		part_id: 174,
		quantity: 241,
	},
	{
		garage_id: 235,
		part_id: 175,
		quantity: 312,
	},
	{
		garage_id: 235,
		part_id: 176,
		quantity: 459,
	},
	{
		garage_id: 235,
		part_id: 177,
		quantity: 54,
	},
	{
		garage_id: 235,
		part_id: 178,
		quantity: 213,
	},
	{
		garage_id: 235,
		part_id: 179,
		quantity: 269,
	},
	{
		garage_id: 235,
		part_id: 180,
		quantity: 417,
	},
	{
		garage_id: 235,
		part_id: 181,
		quantity: 430,
	},
	{
		garage_id: 235,
		part_id: 182,
		quantity: 36,
	},
	{
		garage_id: 235,
		part_id: 183,
		quantity: 141,
	},
	{
		garage_id: 235,
		part_id: 184,
		quantity: 71,
	},
	{
		garage_id: 235,
		part_id: 185,
		quantity: 443,
	},
	{
		garage_id: 235,
		part_id: 186,
		quantity: 434,
	},
	{
		garage_id: 235,
		part_id: 187,
		quantity: 345,
	},
	{
		garage_id: 235,
		part_id: 188,
		quantity: 407,
	},
	{
		garage_id: 235,
		part_id: 189,
		quantity: 114,
	},
	{
		garage_id: 235,
		part_id: 190,
		quantity: 166,
	},
	{
		garage_id: 235,
		part_id: 191,
		quantity: 408,
	},
	{
		garage_id: 235,
		part_id: 192,
		quantity: 320,
	},
	{
		garage_id: 235,
		part_id: 193,
		quantity: 442,
	},
	{
		garage_id: 235,
		part_id: 194,
		quantity: 59,
	},
	{
		garage_id: 235,
		part_id: 195,
		quantity: 413,
	},
	{
		garage_id: 235,
		part_id: 196,
		quantity: 325,
	},
	{
		garage_id: 235,
		part_id: 197,
		quantity: 208,
	},
	{
		garage_id: 235,
		part_id: 198,
		quantity: 366,
	},
	{
		garage_id: 235,
		part_id: 199,
		quantity: 35,
	},
	{
		garage_id: 235,
		part_id: 200,
		quantity: 296,
	},
	{
		garage_id: 235,
		part_id: 201,
		quantity: 104,
	},
	{
		garage_id: 235,
		part_id: 202,
		quantity: 461,
	},
	{
		garage_id: 235,
		part_id: 203,
		quantity: 431,
	},
	{
		garage_id: 235,
		part_id: 204,
		quantity: 165,
	},
	{
		garage_id: 235,
		part_id: 205,
		quantity: 145,
	},
	{
		garage_id: 235,
		part_id: 206,
		quantity: 69,
	},
	{
		garage_id: 235,
		part_id: 207,
		quantity: 265,
	},
	{
		garage_id: 235,
		part_id: 208,
		quantity: 410,
	},
	{
		garage_id: 235,
		part_id: 209,
		quantity: 352,
	},
	{
		garage_id: 235,
		part_id: 210,
		quantity: 293,
	},
	{
		garage_id: 235,
		part_id: 211,
		quantity: 107,
	},
	{
		garage_id: 235,
		part_id: 212,
		quantity: 27,
	},
	{
		garage_id: 235,
		part_id: 213,
		quantity: 472,
	},
	{
		garage_id: 235,
		part_id: 214,
		quantity: 0,
	},
	{
		garage_id: 235,
		part_id: 215,
		quantity: 389,
	},
	{
		garage_id: 235,
		part_id: 216,
		quantity: 255,
	},
	{
		garage_id: 235,
		part_id: 217,
		quantity: 322,
	},
	{
		garage_id: 235,
		part_id: 218,
		quantity: 91,
	},
	{
		garage_id: 235,
		part_id: 219,
		quantity: 496,
	},
	{
		garage_id: 235,
		part_id: 220,
		quantity: 62,
	},
	{
		garage_id: 235,
		part_id: 221,
		quantity: 245,
	},
	{
		garage_id: 235,
		part_id: 222,
		quantity: 292,
	},
	{
		garage_id: 235,
		part_id: 223,
		quantity: 284,
	},
	{
		garage_id: 235,
		part_id: 224,
		quantity: 332,
	},
	{
		garage_id: 235,
		part_id: 225,
		quantity: 196,
	},
	{
		garage_id: 235,
		part_id: 226,
		quantity: 111,
	},
	{
		garage_id: 235,
		part_id: 227,
		quantity: 473,
	},
	{
		garage_id: 235,
		part_id: 228,
		quantity: 252,
	},
	{
		garage_id: 235,
		part_id: 229,
		quantity: 217,
	},
	{
		garage_id: 235,
		part_id: 230,
		quantity: 384,
	},
	{
		garage_id: 235,
		part_id: 231,
		quantity: 244,
	},
	{
		garage_id: 235,
		part_id: 232,
		quantity: 423,
	},
	{
		garage_id: 235,
		part_id: 233,
		quantity: 418,
	},
	{
		garage_id: 235,
		part_id: 234,
		quantity: 93,
	},
	{
		garage_id: 235,
		part_id: 235,
		quantity: 395,
	},
	{
		garage_id: 235,
		part_id: 236,
		quantity: 2,
	},
	{
		garage_id: 235,
		part_id: 237,
		quantity: 358,
	},
	{
		garage_id: 235,
		part_id: 238,
		quantity: 330,
	},
	{
		garage_id: 235,
		part_id: 239,
		quantity: 442,
	},
	{
		garage_id: 235,
		part_id: 240,
		quantity: 266,
	},
	{
		garage_id: 235,
		part_id: 241,
		quantity: 355,
	},
	{
		garage_id: 235,
		part_id: 242,
		quantity: 117,
	},
	{
		garage_id: 235,
		part_id: 243,
		quantity: 326,
	},
	{
		garage_id: 235,
		part_id: 244,
		quantity: 28,
	},
	{
		garage_id: 235,
		part_id: 245,
		quantity: 71,
	},
	{
		garage_id: 235,
		part_id: 246,
		quantity: 466,
	},
	{
		garage_id: 235,
		part_id: 247,
		quantity: 484,
	},
	{
		garage_id: 235,
		part_id: 248,
		quantity: 150,
	},
	{
		garage_id: 235,
		part_id: 249,
		quantity: 135,
	},
	{
		garage_id: 235,
		part_id: 250,
		quantity: 124,
	},
	{
		garage_id: 235,
		part_id: 251,
		quantity: 189,
	},
	{
		garage_id: 235,
		part_id: 252,
		quantity: 36,
	},
	{
		garage_id: 235,
		part_id: 253,
		quantity: 205,
	},
	{
		garage_id: 235,
		part_id: 254,
		quantity: 267,
	},
	{
		garage_id: 235,
		part_id: 255,
		quantity: 342,
	},
	{
		garage_id: 235,
		part_id: 256,
		quantity: 349,
	},
	{
		garage_id: 235,
		part_id: 257,
		quantity: 241,
	},
	{
		garage_id: 235,
		part_id: 258,
		quantity: 177,
	},
	{
		garage_id: 235,
		part_id: 259,
		quantity: 296,
	},
	{
		garage_id: 235,
		part_id: 260,
		quantity: 193,
	},
	{
		garage_id: 235,
		part_id: 261,
		quantity: 354,
	},
	{
		garage_id: 235,
		part_id: 262,
		quantity: 282,
	},
	{
		garage_id: 235,
		part_id: 263,
		quantity: 436,
	},
	{
		garage_id: 235,
		part_id: 264,
		quantity: 426,
	},
	{
		garage_id: 235,
		part_id: 265,
		quantity: 54,
	},
	{
		garage_id: 235,
		part_id: 266,
		quantity: 429,
	},
	{
		garage_id: 235,
		part_id: 267,
		quantity: 228,
	},
	{
		garage_id: 235,
		part_id: 268,
		quantity: 112,
	},
	{
		garage_id: 235,
		part_id: 269,
		quantity: 329,
	},
	{
		garage_id: 235,
		part_id: 270,
		quantity: 183,
	},
	{
		garage_id: 235,
		part_id: 271,
		quantity: 277,
	},
	{
		garage_id: 235,
		part_id: 272,
		quantity: 442,
	},
	{
		garage_id: 235,
		part_id: 273,
		quantity: 417,
	},
	{
		garage_id: 235,
		part_id: 274,
		quantity: 12,
	},
	{
		garage_id: 235,
		part_id: 275,
		quantity: 186,
	},
	{
		garage_id: 235,
		part_id: 276,
		quantity: 184,
	},
	{
		garage_id: 235,
		part_id: 277,
		quantity: 405,
	},
	{
		garage_id: 235,
		part_id: 278,
		quantity: 321,
	},
	{
		garage_id: 235,
		part_id: 279,
		quantity: 184,
	},
	{
		garage_id: 235,
		part_id: 280,
		quantity: 459,
	},
	{
		garage_id: 235,
		part_id: 281,
		quantity: 425,
	},
	{
		garage_id: 235,
		part_id: 282,
		quantity: 125,
	},
	{
		garage_id: 235,
		part_id: 283,
		quantity: 449,
	},
	{
		garage_id: 235,
		part_id: 284,
		quantity: 172,
	},
	{
		garage_id: 235,
		part_id: 285,
		quantity: 129,
	},
	{
		garage_id: 235,
		part_id: 286,
		quantity: 143,
	},
	{
		garage_id: 235,
		part_id: 287,
		quantity: 138,
	},
	{
		garage_id: 235,
		part_id: 288,
		quantity: 52,
	},
	{
		garage_id: 235,
		part_id: 289,
		quantity: 92,
	},
	{
		garage_id: 235,
		part_id: 290,
		quantity: 94,
	},
	{
		garage_id: 235,
		part_id: 291,
		quantity: 329,
	},
	{
		garage_id: 235,
		part_id: 292,
		quantity: 328,
	},
	{
		garage_id: 235,
		part_id: 293,
		quantity: 320,
	},
	{
		garage_id: 235,
		part_id: 294,
		quantity: 382,
	},
	{
		garage_id: 235,
		part_id: 295,
		quantity: 309,
	},
	{
		garage_id: 235,
		part_id: 296,
		quantity: 355,
	},
	{
		garage_id: 235,
		part_id: 297,
		quantity: 9,
	},
	{
		garage_id: 235,
		part_id: 298,
		quantity: 209,
	},
	{
		garage_id: 235,
		part_id: 299,
		quantity: 0,
	},
	{
		garage_id: 235,
		part_id: 300,
		quantity: 131,
	},
	{
		garage_id: 235,
		part_id: 301,
		quantity: 332,
	},
	{
		garage_id: 235,
		part_id: 302,
		quantity: 106,
	},
	{
		garage_id: 235,
		part_id: 303,
		quantity: 168,
	},
	{
		garage_id: 235,
		part_id: 304,
		quantity: 75,
	},
	{
		garage_id: 235,
		part_id: 305,
		quantity: 20,
	},
	{
		garage_id: 235,
		part_id: 306,
		quantity: 374,
	},
	{
		garage_id: 235,
		part_id: 307,
		quantity: 450,
	},
	{
		garage_id: 235,
		part_id: 308,
		quantity: 347,
	},
	{
		garage_id: 235,
		part_id: 309,
		quantity: 423,
	},
	{
		garage_id: 235,
		part_id: 310,
		quantity: 280,
	},
	{
		garage_id: 235,
		part_id: 311,
		quantity: 427,
	},
	{
		garage_id: 235,
		part_id: 312,
		quantity: 278,
	},
	{
		garage_id: 235,
		part_id: 313,
		quantity: 50,
	},
	{
		garage_id: 235,
		part_id: 314,
		quantity: 294,
	},
	{
		garage_id: 235,
		part_id: 315,
		quantity: 92,
	},
	{
		garage_id: 235,
		part_id: 316,
		quantity: 384,
	},
	{
		garage_id: 235,
		part_id: 317,
		quantity: 440,
	},
	{
		garage_id: 235,
		part_id: 318,
		quantity: 157,
	},
	{
		garage_id: 235,
		part_id: 319,
		quantity: 410,
	},
	{
		garage_id: 235,
		part_id: 320,
		quantity: 219,
	},
	{
		garage_id: 235,
		part_id: 321,
		quantity: 98,
	},
	{
		garage_id: 235,
		part_id: 322,
		quantity: 15,
	},
	{
		garage_id: 235,
		part_id: 323,
		quantity: 222,
	},
	{
		garage_id: 235,
		part_id: 324,
		quantity: 260,
	},
	{
		garage_id: 235,
		part_id: 325,
		quantity: 362,
	},
	{
		garage_id: 235,
		part_id: 326,
		quantity: 308,
	},
	{
		garage_id: 235,
		part_id: 327,
		quantity: 328,
	},
	{
		garage_id: 235,
		part_id: 328,
		quantity: 443,
	},
	{
		garage_id: 235,
		part_id: 329,
		quantity: 317,
	},
	{
		garage_id: 235,
		part_id: 330,
		quantity: 463,
	},
	{
		garage_id: 235,
		part_id: 331,
		quantity: 455,
	},
	{
		garage_id: 235,
		part_id: 332,
		quantity: 377,
	},
	{
		garage_id: 235,
		part_id: 333,
		quantity: 461,
	},
	{
		garage_id: 235,
		part_id: 334,
		quantity: 488,
	},
	{
		garage_id: 235,
		part_id: 335,
		quantity: 199,
	},
	{
		garage_id: 235,
		part_id: 336,
		quantity: 59,
	},
	{
		garage_id: 235,
		part_id: 337,
		quantity: 251,
	},
	{
		garage_id: 235,
		part_id: 338,
		quantity: 385,
	},
	{
		garage_id: 235,
		part_id: 339,
		quantity: 419,
	},
	{
		garage_id: 235,
		part_id: 340,
		quantity: 340,
	},
	{
		garage_id: 235,
		part_id: 341,
		quantity: 23,
	},
	{
		garage_id: 235,
		part_id: 342,
		quantity: 216,
	},
	{
		garage_id: 235,
		part_id: 343,
		quantity: 346,
	},
	{
		garage_id: 235,
		part_id: 344,
		quantity: 428,
	},
	{
		garage_id: 235,
		part_id: 345,
		quantity: 331,
	},
	{
		garage_id: 235,
		part_id: 346,
		quantity: 57,
	},
	{
		garage_id: 235,
		part_id: 347,
		quantity: 351,
	},
	{
		garage_id: 235,
		part_id: 348,
		quantity: 455,
	},
	{
		garage_id: 235,
		part_id: 349,
		quantity: 130,
	},
	{
		garage_id: 235,
		part_id: 350,
		quantity: 209,
	},
	{
		garage_id: 235,
		part_id: 351,
		quantity: 159,
	},
	{
		garage_id: 235,
		part_id: 352,
		quantity: 80,
	},
	{
		garage_id: 235,
		part_id: 353,
		quantity: 410,
	},
	{
		garage_id: 235,
		part_id: 354,
		quantity: 395,
	},
	{
		garage_id: 235,
		part_id: 355,
		quantity: 270,
	},
	{
		garage_id: 235,
		part_id: 356,
		quantity: 133,
	},
	{
		garage_id: 235,
		part_id: 357,
		quantity: 371,
	},
	{
		garage_id: 235,
		part_id: 358,
		quantity: 443,
	},
	{
		garage_id: 235,
		part_id: 359,
		quantity: 453,
	},
	{
		garage_id: 235,
		part_id: 360,
		quantity: 261,
	},
	{
		garage_id: 235,
		part_id: 361,
		quantity: 159,
	},
	{
		garage_id: 235,
		part_id: 362,
		quantity: 49,
	},
	{
		garage_id: 235,
		part_id: 363,
		quantity: 352,
	},
	{
		garage_id: 235,
		part_id: 364,
		quantity: 454,
	},
	{
		garage_id: 235,
		part_id: 365,
		quantity: 116,
	},
	{
		garage_id: 235,
		part_id: 366,
		quantity: 245,
	},
	{
		garage_id: 235,
		part_id: 367,
		quantity: 208,
	},
	{
		garage_id: 235,
		part_id: 368,
		quantity: 248,
	},
	{
		garage_id: 235,
		part_id: 369,
		quantity: 467,
	},
	{
		garage_id: 235,
		part_id: 370,
		quantity: 217,
	},
	{
		garage_id: 235,
		part_id: 371,
		quantity: 385,
	},
	{
		garage_id: 235,
		part_id: 372,
		quantity: 311,
	},
	{
		garage_id: 235,
		part_id: 373,
		quantity: 421,
	},
	{
		garage_id: 235,
		part_id: 374,
		quantity: 385,
	},
	{
		garage_id: 235,
		part_id: 375,
		quantity: 461,
	},
	{
		garage_id: 235,
		part_id: 376,
		quantity: 402,
	},
	{
		garage_id: 235,
		part_id: 377,
		quantity: 481,
	},
	{
		garage_id: 235,
		part_id: 378,
		quantity: 271,
	},
	{
		garage_id: 235,
		part_id: 379,
		quantity: 424,
	},
	{
		garage_id: 235,
		part_id: 380,
		quantity: 482,
	},
	{
		garage_id: 235,
		part_id: 381,
		quantity: 361,
	},
	{
		garage_id: 235,
		part_id: 382,
		quantity: 153,
	},
	{
		garage_id: 235,
		part_id: 383,
		quantity: 294,
	},
	{
		garage_id: 235,
		part_id: 384,
		quantity: 235,
	},
	{
		garage_id: 235,
		part_id: 385,
		quantity: 306,
	},
	{
		garage_id: 235,
		part_id: 386,
		quantity: 23,
	},
	{
		garage_id: 235,
		part_id: 387,
		quantity: 143,
	},
	{
		garage_id: 235,
		part_id: 388,
		quantity: 418,
	},
	{
		garage_id: 235,
		part_id: 389,
		quantity: 156,
	},
	{
		garage_id: 235,
		part_id: 390,
		quantity: 439,
	},
	{
		garage_id: 235,
		part_id: 391,
		quantity: 395,
	},
	{
		garage_id: 235,
		part_id: 392,
		quantity: 369,
	},
	{
		garage_id: 235,
		part_id: 393,
		quantity: 151,
	},
	{
		garage_id: 235,
		part_id: 394,
		quantity: 248,
	},
	{
		garage_id: 235,
		part_id: 395,
		quantity: 371,
	},
	{
		garage_id: 235,
		part_id: 396,
		quantity: 217,
	},
	{
		garage_id: 235,
		part_id: 397,
		quantity: 424,
	},
	{
		garage_id: 235,
		part_id: 398,
		quantity: 23,
	},
	{
		garage_id: 235,
		part_id: 399,
		quantity: 48,
	},
	{
		garage_id: 236,
		part_id: 0,
		quantity: 203,
	},
	{
		garage_id: 236,
		part_id: 1,
		quantity: 363,
	},
	{
		garage_id: 236,
		part_id: 2,
		quantity: 200,
	},
	{
		garage_id: 236,
		part_id: 3,
		quantity: 222,
	},
	{
		garage_id: 236,
		part_id: 4,
		quantity: 364,
	},
	{
		garage_id: 236,
		part_id: 5,
		quantity: 365,
	},
	{
		garage_id: 236,
		part_id: 6,
		quantity: 415,
	},
	{
		garage_id: 236,
		part_id: 7,
		quantity: 183,
	},
	{
		garage_id: 236,
		part_id: 8,
		quantity: 301,
	},
	{
		garage_id: 236,
		part_id: 9,
		quantity: 264,
	},
	{
		garage_id: 236,
		part_id: 10,
		quantity: 142,
	},
	{
		garage_id: 236,
		part_id: 11,
		quantity: 32,
	},
	{
		garage_id: 236,
		part_id: 12,
		quantity: 312,
	},
	{
		garage_id: 236,
		part_id: 13,
		quantity: 276,
	},
	{
		garage_id: 236,
		part_id: 14,
		quantity: 416,
	},
	{
		garage_id: 236,
		part_id: 15,
		quantity: 45,
	},
	{
		garage_id: 236,
		part_id: 16,
		quantity: 496,
	},
	{
		garage_id: 236,
		part_id: 17,
		quantity: 43,
	},
	{
		garage_id: 236,
		part_id: 18,
		quantity: 146,
	},
	{
		garage_id: 236,
		part_id: 19,
		quantity: 273,
	},
	{
		garage_id: 236,
		part_id: 20,
		quantity: 359,
	},
	{
		garage_id: 236,
		part_id: 21,
		quantity: 489,
	},
	{
		garage_id: 236,
		part_id: 22,
		quantity: 492,
	},
	{
		garage_id: 236,
		part_id: 23,
		quantity: 116,
	},
	{
		garage_id: 236,
		part_id: 24,
		quantity: 272,
	},
	{
		garage_id: 236,
		part_id: 25,
		quantity: 230,
	},
	{
		garage_id: 236,
		part_id: 26,
		quantity: 144,
	},
	{
		garage_id: 236,
		part_id: 27,
		quantity: 426,
	},
	{
		garage_id: 236,
		part_id: 28,
		quantity: 13,
	},
	{
		garage_id: 236,
		part_id: 29,
		quantity: 220,
	},
	{
		garage_id: 236,
		part_id: 30,
		quantity: 361,
	},
	{
		garage_id: 236,
		part_id: 31,
		quantity: 275,
	},
	{
		garage_id: 236,
		part_id: 32,
		quantity: 168,
	},
	{
		garage_id: 236,
		part_id: 33,
		quantity: 459,
	},
	{
		garage_id: 236,
		part_id: 34,
		quantity: 467,
	},
	{
		garage_id: 236,
		part_id: 35,
		quantity: 140,
	},
	{
		garage_id: 236,
		part_id: 36,
		quantity: 361,
	},
	{
		garage_id: 236,
		part_id: 37,
		quantity: 269,
	},
	{
		garage_id: 236,
		part_id: 38,
		quantity: 424,
	},
	{
		garage_id: 236,
		part_id: 39,
		quantity: 365,
	},
	{
		garage_id: 236,
		part_id: 40,
		quantity: 456,
	},
	{
		garage_id: 236,
		part_id: 41,
		quantity: 270,
	},
	{
		garage_id: 236,
		part_id: 42,
		quantity: 254,
	},
	{
		garage_id: 236,
		part_id: 43,
		quantity: 272,
	},
	{
		garage_id: 236,
		part_id: 44,
		quantity: 350,
	},
	{
		garage_id: 236,
		part_id: 45,
		quantity: 38,
	},
	{
		garage_id: 236,
		part_id: 46,
		quantity: 440,
	},
	{
		garage_id: 236,
		part_id: 47,
		quantity: 461,
	},
	{
		garage_id: 236,
		part_id: 48,
		quantity: 334,
	},
	{
		garage_id: 236,
		part_id: 49,
		quantity: 487,
	},
	{
		garage_id: 236,
		part_id: 50,
		quantity: 410,
	},
	{
		garage_id: 236,
		part_id: 51,
		quantity: 344,
	},
	{
		garage_id: 236,
		part_id: 52,
		quantity: 198,
	},
	{
		garage_id: 236,
		part_id: 53,
		quantity: 11,
	},
	{
		garage_id: 236,
		part_id: 54,
		quantity: 348,
	},
	{
		garage_id: 236,
		part_id: 55,
		quantity: 0,
	},
	{
		garage_id: 236,
		part_id: 56,
		quantity: 351,
	},
	{
		garage_id: 236,
		part_id: 57,
		quantity: 246,
	},
	{
		garage_id: 236,
		part_id: 58,
		quantity: 392,
	},
	{
		garage_id: 236,
		part_id: 59,
		quantity: 276,
	},
	{
		garage_id: 236,
		part_id: 60,
		quantity: 475,
	},
	{
		garage_id: 236,
		part_id: 61,
		quantity: 40,
	},
	{
		garage_id: 236,
		part_id: 62,
		quantity: 225,
	},
	{
		garage_id: 236,
		part_id: 63,
		quantity: 408,
	},
	{
		garage_id: 236,
		part_id: 64,
		quantity: 110,
	},
	{
		garage_id: 236,
		part_id: 65,
		quantity: 321,
	},
	{
		garage_id: 236,
		part_id: 66,
		quantity: 18,
	},
	{
		garage_id: 236,
		part_id: 67,
		quantity: 54,
	},
	{
		garage_id: 236,
		part_id: 68,
		quantity: 307,
	},
	{
		garage_id: 236,
		part_id: 69,
		quantity: 465,
	},
	{
		garage_id: 236,
		part_id: 70,
		quantity: 64,
	},
	{
		garage_id: 236,
		part_id: 71,
		quantity: 124,
	},
	{
		garage_id: 236,
		part_id: 72,
		quantity: 336,
	},
	{
		garage_id: 236,
		part_id: 73,
		quantity: 262,
	},
	{
		garage_id: 236,
		part_id: 74,
		quantity: 117,
	},
	{
		garage_id: 236,
		part_id: 75,
		quantity: 335,
	},
	{
		garage_id: 236,
		part_id: 76,
		quantity: 301,
	},
	{
		garage_id: 236,
		part_id: 77,
		quantity: 244,
	},
	{
		garage_id: 236,
		part_id: 78,
		quantity: 466,
	},
	{
		garage_id: 236,
		part_id: 79,
		quantity: 116,
	},
	{
		garage_id: 236,
		part_id: 80,
		quantity: 19,
	},
	{
		garage_id: 236,
		part_id: 81,
		quantity: 200,
	},
	{
		garage_id: 236,
		part_id: 82,
		quantity: 264,
	},
	{
		garage_id: 236,
		part_id: 83,
		quantity: 470,
	},
	{
		garage_id: 236,
		part_id: 84,
		quantity: 19,
	},
	{
		garage_id: 236,
		part_id: 85,
		quantity: 492,
	},
	{
		garage_id: 236,
		part_id: 86,
		quantity: 476,
	},
	{
		garage_id: 236,
		part_id: 87,
		quantity: 288,
	},
	{
		garage_id: 236,
		part_id: 88,
		quantity: 500,
	},
	{
		garage_id: 236,
		part_id: 89,
		quantity: 444,
	},
	{
		garage_id: 236,
		part_id: 90,
		quantity: 145,
	},
	{
		garage_id: 236,
		part_id: 91,
		quantity: 488,
	},
	{
		garage_id: 236,
		part_id: 92,
		quantity: 412,
	},
	{
		garage_id: 236,
		part_id: 93,
		quantity: 385,
	},
	{
		garage_id: 236,
		part_id: 94,
		quantity: 491,
	},
	{
		garage_id: 236,
		part_id: 95,
		quantity: 414,
	},
	{
		garage_id: 236,
		part_id: 96,
		quantity: 261,
	},
	{
		garage_id: 236,
		part_id: 97,
		quantity: 440,
	},
	{
		garage_id: 236,
		part_id: 98,
		quantity: 337,
	},
	{
		garage_id: 236,
		part_id: 99,
		quantity: 8,
	},
	{
		garage_id: 236,
		part_id: 100,
		quantity: 101,
	},
	{
		garage_id: 236,
		part_id: 101,
		quantity: 22,
	},
	{
		garage_id: 236,
		part_id: 102,
		quantity: 355,
	},
	{
		garage_id: 236,
		part_id: 103,
		quantity: 327,
	},
	{
		garage_id: 236,
		part_id: 104,
		quantity: 447,
	},
	{
		garage_id: 236,
		part_id: 105,
		quantity: 124,
	},
	{
		garage_id: 236,
		part_id: 106,
		quantity: 228,
	},
	{
		garage_id: 236,
		part_id: 107,
		quantity: 363,
	},
	{
		garage_id: 236,
		part_id: 108,
		quantity: 42,
	},
	{
		garage_id: 236,
		part_id: 109,
		quantity: 251,
	},
	{
		garage_id: 236,
		part_id: 110,
		quantity: 159,
	},
	{
		garage_id: 236,
		part_id: 111,
		quantity: 163,
	},
	{
		garage_id: 236,
		part_id: 112,
		quantity: 172,
	},
	{
		garage_id: 236,
		part_id: 113,
		quantity: 59,
	},
	{
		garage_id: 236,
		part_id: 114,
		quantity: 81,
	},
	{
		garage_id: 236,
		part_id: 115,
		quantity: 400,
	},
	{
		garage_id: 236,
		part_id: 116,
		quantity: 345,
	},
	{
		garage_id: 236,
		part_id: 117,
		quantity: 155,
	},
	{
		garage_id: 236,
		part_id: 118,
		quantity: 309,
	},
	{
		garage_id: 236,
		part_id: 119,
		quantity: 203,
	},
	{
		garage_id: 236,
		part_id: 120,
		quantity: 115,
	},
	{
		garage_id: 236,
		part_id: 121,
		quantity: 161,
	},
	{
		garage_id: 236,
		part_id: 122,
		quantity: 160,
	},
	{
		garage_id: 236,
		part_id: 123,
		quantity: 253,
	},
	{
		garage_id: 236,
		part_id: 124,
		quantity: 379,
	},
	{
		garage_id: 236,
		part_id: 125,
		quantity: 42,
	},
	{
		garage_id: 236,
		part_id: 126,
		quantity: 108,
	},
	{
		garage_id: 236,
		part_id: 127,
		quantity: 311,
	},
	{
		garage_id: 236,
		part_id: 128,
		quantity: 191,
	},
	{
		garage_id: 236,
		part_id: 129,
		quantity: 456,
	},
	{
		garage_id: 236,
		part_id: 130,
		quantity: 290,
	},
	{
		garage_id: 236,
		part_id: 131,
		quantity: 455,
	},
	{
		garage_id: 236,
		part_id: 132,
		quantity: 75,
	},
	{
		garage_id: 236,
		part_id: 133,
		quantity: 62,
	},
	{
		garage_id: 236,
		part_id: 134,
		quantity: 375,
	},
	{
		garage_id: 236,
		part_id: 135,
		quantity: 396,
	},
	{
		garage_id: 236,
		part_id: 136,
		quantity: 349,
	},
	{
		garage_id: 236,
		part_id: 137,
		quantity: 418,
	},
	{
		garage_id: 236,
		part_id: 138,
		quantity: 322,
	},
	{
		garage_id: 236,
		part_id: 139,
		quantity: 44,
	},
	{
		garage_id: 236,
		part_id: 140,
		quantity: 485,
	},
	{
		garage_id: 236,
		part_id: 141,
		quantity: 99,
	},
	{
		garage_id: 236,
		part_id: 142,
		quantity: 428,
	},
	{
		garage_id: 236,
		part_id: 143,
		quantity: 80,
	},
	{
		garage_id: 236,
		part_id: 144,
		quantity: 472,
	},
	{
		garage_id: 236,
		part_id: 145,
		quantity: 173,
	},
	{
		garage_id: 236,
		part_id: 146,
		quantity: 28,
	},
	{
		garage_id: 236,
		part_id: 147,
		quantity: 352,
	},
	{
		garage_id: 236,
		part_id: 148,
		quantity: 337,
	},
	{
		garage_id: 236,
		part_id: 149,
		quantity: 268,
	},
	{
		garage_id: 236,
		part_id: 150,
		quantity: 294,
	},
	{
		garage_id: 236,
		part_id: 151,
		quantity: 211,
	},
	{
		garage_id: 236,
		part_id: 152,
		quantity: 428,
	},
	{
		garage_id: 236,
		part_id: 153,
		quantity: 280,
	},
	{
		garage_id: 236,
		part_id: 154,
		quantity: 17,
	},
	{
		garage_id: 236,
		part_id: 155,
		quantity: 343,
	},
	{
		garage_id: 236,
		part_id: 156,
		quantity: 358,
	},
	{
		garage_id: 236,
		part_id: 157,
		quantity: 24,
	},
	{
		garage_id: 236,
		part_id: 158,
		quantity: 345,
	},
	{
		garage_id: 236,
		part_id: 159,
		quantity: 54,
	},
	{
		garage_id: 236,
		part_id: 160,
		quantity: 295,
	},
	{
		garage_id: 236,
		part_id: 161,
		quantity: 298,
	},
	{
		garage_id: 236,
		part_id: 162,
		quantity: 175,
	},
	{
		garage_id: 236,
		part_id: 163,
		quantity: 340,
	},
	{
		garage_id: 236,
		part_id: 164,
		quantity: 266,
	},
	{
		garage_id: 236,
		part_id: 165,
		quantity: 181,
	},
	{
		garage_id: 236,
		part_id: 166,
		quantity: 487,
	},
	{
		garage_id: 236,
		part_id: 167,
		quantity: 86,
	},
	{
		garage_id: 236,
		part_id: 168,
		quantity: 194,
	},
	{
		garage_id: 236,
		part_id: 169,
		quantity: 67,
	},
	{
		garage_id: 236,
		part_id: 170,
		quantity: 272,
	},
	{
		garage_id: 236,
		part_id: 171,
		quantity: 380,
	},
	{
		garage_id: 236,
		part_id: 172,
		quantity: 481,
	},
	{
		garage_id: 236,
		part_id: 173,
		quantity: 145,
	},
	{
		garage_id: 236,
		part_id: 174,
		quantity: 118,
	},
	{
		garage_id: 236,
		part_id: 175,
		quantity: 455,
	},
	{
		garage_id: 236,
		part_id: 176,
		quantity: 484,
	},
	{
		garage_id: 236,
		part_id: 177,
		quantity: 235,
	},
	{
		garage_id: 236,
		part_id: 178,
		quantity: 49,
	},
	{
		garage_id: 236,
		part_id: 179,
		quantity: 103,
	},
	{
		garage_id: 236,
		part_id: 180,
		quantity: 475,
	},
	{
		garage_id: 236,
		part_id: 181,
		quantity: 70,
	},
	{
		garage_id: 236,
		part_id: 182,
		quantity: 182,
	},
	{
		garage_id: 236,
		part_id: 183,
		quantity: 139,
	},
	{
		garage_id: 236,
		part_id: 184,
		quantity: 151,
	},
	{
		garage_id: 236,
		part_id: 185,
		quantity: 122,
	},
	{
		garage_id: 236,
		part_id: 186,
		quantity: 34,
	},
	{
		garage_id: 236,
		part_id: 187,
		quantity: 57,
	},
	{
		garage_id: 236,
		part_id: 188,
		quantity: 500,
	},
	{
		garage_id: 236,
		part_id: 189,
		quantity: 89,
	},
	{
		garage_id: 236,
		part_id: 190,
		quantity: 336,
	},
	{
		garage_id: 236,
		part_id: 191,
		quantity: 392,
	},
	{
		garage_id: 236,
		part_id: 192,
		quantity: 366,
	},
	{
		garage_id: 236,
		part_id: 193,
		quantity: 53,
	},
	{
		garage_id: 236,
		part_id: 194,
		quantity: 123,
	},
	{
		garage_id: 236,
		part_id: 195,
		quantity: 86,
	},
	{
		garage_id: 236,
		part_id: 196,
		quantity: 479,
	},
	{
		garage_id: 236,
		part_id: 197,
		quantity: 77,
	},
	{
		garage_id: 236,
		part_id: 198,
		quantity: 120,
	},
	{
		garage_id: 236,
		part_id: 199,
		quantity: 241,
	},
	{
		garage_id: 236,
		part_id: 200,
		quantity: 141,
	},
	{
		garage_id: 236,
		part_id: 201,
		quantity: 32,
	},
	{
		garage_id: 236,
		part_id: 202,
		quantity: 404,
	},
	{
		garage_id: 236,
		part_id: 203,
		quantity: 460,
	},
	{
		garage_id: 236,
		part_id: 204,
		quantity: 317,
	},
	{
		garage_id: 236,
		part_id: 205,
		quantity: 419,
	},
	{
		garage_id: 236,
		part_id: 206,
		quantity: 129,
	},
	{
		garage_id: 236,
		part_id: 207,
		quantity: 347,
	},
	{
		garage_id: 236,
		part_id: 208,
		quantity: 94,
	},
	{
		garage_id: 236,
		part_id: 209,
		quantity: 229,
	},
	{
		garage_id: 236,
		part_id: 210,
		quantity: 265,
	},
	{
		garage_id: 236,
		part_id: 211,
		quantity: 123,
	},
	{
		garage_id: 236,
		part_id: 212,
		quantity: 44,
	},
	{
		garage_id: 236,
		part_id: 213,
		quantity: 350,
	},
	{
		garage_id: 236,
		part_id: 214,
		quantity: 284,
	},
	{
		garage_id: 236,
		part_id: 215,
		quantity: 203,
	},
	{
		garage_id: 236,
		part_id: 216,
		quantity: 386,
	},
	{
		garage_id: 236,
		part_id: 217,
		quantity: 288,
	},
	{
		garage_id: 236,
		part_id: 218,
		quantity: 282,
	},
	{
		garage_id: 236,
		part_id: 219,
		quantity: 362,
	},
	{
		garage_id: 236,
		part_id: 220,
		quantity: 366,
	},
	{
		garage_id: 236,
		part_id: 221,
		quantity: 446,
	},
	{
		garage_id: 236,
		part_id: 222,
		quantity: 147,
	},
	{
		garage_id: 236,
		part_id: 223,
		quantity: 487,
	},
	{
		garage_id: 236,
		part_id: 224,
		quantity: 46,
	},
	{
		garage_id: 236,
		part_id: 225,
		quantity: 162,
	},
	{
		garage_id: 236,
		part_id: 226,
		quantity: 277,
	},
	{
		garage_id: 236,
		part_id: 227,
		quantity: 382,
	},
	{
		garage_id: 236,
		part_id: 228,
		quantity: 266,
	},
	{
		garage_id: 236,
		part_id: 229,
		quantity: 117,
	},
	{
		garage_id: 236,
		part_id: 230,
		quantity: 304,
	},
	{
		garage_id: 236,
		part_id: 231,
		quantity: 267,
	},
	{
		garage_id: 236,
		part_id: 232,
		quantity: 4,
	},
	{
		garage_id: 236,
		part_id: 233,
		quantity: 402,
	},
	{
		garage_id: 236,
		part_id: 234,
		quantity: 96,
	},
	{
		garage_id: 236,
		part_id: 235,
		quantity: 475,
	},
	{
		garage_id: 236,
		part_id: 236,
		quantity: 205,
	},
	{
		garage_id: 236,
		part_id: 237,
		quantity: 201,
	},
	{
		garage_id: 236,
		part_id: 238,
		quantity: 65,
	},
	{
		garage_id: 236,
		part_id: 239,
		quantity: 438,
	},
	{
		garage_id: 236,
		part_id: 240,
		quantity: 396,
	},
	{
		garage_id: 236,
		part_id: 241,
		quantity: 140,
	},
	{
		garage_id: 236,
		part_id: 242,
		quantity: 389,
	},
	{
		garage_id: 236,
		part_id: 243,
		quantity: 221,
	},
	{
		garage_id: 236,
		part_id: 244,
		quantity: 75,
	},
	{
		garage_id: 236,
		part_id: 245,
		quantity: 240,
	},
	{
		garage_id: 236,
		part_id: 246,
		quantity: 118,
	},
	{
		garage_id: 236,
		part_id: 247,
		quantity: 301,
	},
	{
		garage_id: 236,
		part_id: 248,
		quantity: 137,
	},
	{
		garage_id: 236,
		part_id: 249,
		quantity: 6,
	},
	{
		garage_id: 236,
		part_id: 250,
		quantity: 11,
	},
	{
		garage_id: 236,
		part_id: 251,
		quantity: 97,
	},
	{
		garage_id: 236,
		part_id: 252,
		quantity: 391,
	},
	{
		garage_id: 236,
		part_id: 253,
		quantity: 184,
	},
	{
		garage_id: 236,
		part_id: 254,
		quantity: 132,
	},
	{
		garage_id: 236,
		part_id: 255,
		quantity: 192,
	},
	{
		garage_id: 236,
		part_id: 256,
		quantity: 346,
	},
	{
		garage_id: 236,
		part_id: 257,
		quantity: 110,
	},
	{
		garage_id: 236,
		part_id: 258,
		quantity: 491,
	},
	{
		garage_id: 236,
		part_id: 259,
		quantity: 481,
	},
	{
		garage_id: 236,
		part_id: 260,
		quantity: 329,
	},
	{
		garage_id: 236,
		part_id: 261,
		quantity: 276,
	},
	{
		garage_id: 236,
		part_id: 262,
		quantity: 139,
	},
	{
		garage_id: 236,
		part_id: 263,
		quantity: 499,
	},
	{
		garage_id: 236,
		part_id: 264,
		quantity: 466,
	},
	{
		garage_id: 236,
		part_id: 265,
		quantity: 69,
	},
	{
		garage_id: 236,
		part_id: 266,
		quantity: 336,
	},
	{
		garage_id: 236,
		part_id: 267,
		quantity: 492,
	},
	{
		garage_id: 236,
		part_id: 268,
		quantity: 101,
	},
	{
		garage_id: 236,
		part_id: 269,
		quantity: 330,
	},
	{
		garage_id: 236,
		part_id: 270,
		quantity: 152,
	},
	{
		garage_id: 236,
		part_id: 271,
		quantity: 145,
	},
	{
		garage_id: 236,
		part_id: 272,
		quantity: 406,
	},
	{
		garage_id: 236,
		part_id: 273,
		quantity: 424,
	},
	{
		garage_id: 236,
		part_id: 274,
		quantity: 327,
	},
	{
		garage_id: 236,
		part_id: 275,
		quantity: 110,
	},
	{
		garage_id: 236,
		part_id: 276,
		quantity: 106,
	},
	{
		garage_id: 236,
		part_id: 277,
		quantity: 452,
	},
	{
		garage_id: 236,
		part_id: 278,
		quantity: 466,
	},
	{
		garage_id: 236,
		part_id: 279,
		quantity: 21,
	},
	{
		garage_id: 236,
		part_id: 280,
		quantity: 414,
	},
	{
		garage_id: 236,
		part_id: 281,
		quantity: 99,
	},
	{
		garage_id: 236,
		part_id: 282,
		quantity: 118,
	},
	{
		garage_id: 236,
		part_id: 283,
		quantity: 232,
	},
	{
		garage_id: 236,
		part_id: 284,
		quantity: 130,
	},
	{
		garage_id: 236,
		part_id: 285,
		quantity: 116,
	},
	{
		garage_id: 236,
		part_id: 286,
		quantity: 427,
	},
	{
		garage_id: 236,
		part_id: 287,
		quantity: 3,
	},
	{
		garage_id: 236,
		part_id: 288,
		quantity: 446,
	},
	{
		garage_id: 236,
		part_id: 289,
		quantity: 277,
	},
	{
		garage_id: 236,
		part_id: 290,
		quantity: 333,
	},
	{
		garage_id: 236,
		part_id: 291,
		quantity: 439,
	},
	{
		garage_id: 236,
		part_id: 292,
		quantity: 203,
	},
	{
		garage_id: 236,
		part_id: 293,
		quantity: 217,
	},
	{
		garage_id: 236,
		part_id: 294,
		quantity: 253,
	},
	{
		garage_id: 236,
		part_id: 295,
		quantity: 499,
	},
	{
		garage_id: 236,
		part_id: 296,
		quantity: 467,
	},
	{
		garage_id: 236,
		part_id: 297,
		quantity: 359,
	},
	{
		garage_id: 236,
		part_id: 298,
		quantity: 314,
	},
	{
		garage_id: 236,
		part_id: 299,
		quantity: 323,
	},
	{
		garage_id: 236,
		part_id: 300,
		quantity: 324,
	},
	{
		garage_id: 236,
		part_id: 301,
		quantity: 266,
	},
	{
		garage_id: 236,
		part_id: 302,
		quantity: 408,
	},
	{
		garage_id: 236,
		part_id: 303,
		quantity: 165,
	},
	{
		garage_id: 236,
		part_id: 304,
		quantity: 10,
	},
	{
		garage_id: 236,
		part_id: 305,
		quantity: 456,
	},
	{
		garage_id: 236,
		part_id: 306,
		quantity: 390,
	},
	{
		garage_id: 236,
		part_id: 307,
		quantity: 10,
	},
	{
		garage_id: 236,
		part_id: 308,
		quantity: 488,
	},
	{
		garage_id: 236,
		part_id: 309,
		quantity: 433,
	},
	{
		garage_id: 236,
		part_id: 310,
		quantity: 235,
	},
	{
		garage_id: 236,
		part_id: 311,
		quantity: 235,
	},
	{
		garage_id: 236,
		part_id: 312,
		quantity: 492,
	},
	{
		garage_id: 236,
		part_id: 313,
		quantity: 157,
	},
	{
		garage_id: 236,
		part_id: 314,
		quantity: 177,
	},
	{
		garage_id: 236,
		part_id: 315,
		quantity: 153,
	},
	{
		garage_id: 236,
		part_id: 316,
		quantity: 397,
	},
	{
		garage_id: 236,
		part_id: 317,
		quantity: 190,
	},
	{
		garage_id: 236,
		part_id: 318,
		quantity: 387,
	},
	{
		garage_id: 236,
		part_id: 319,
		quantity: 33,
	},
	{
		garage_id: 236,
		part_id: 320,
		quantity: 420,
	},
	{
		garage_id: 236,
		part_id: 321,
		quantity: 39,
	},
	{
		garage_id: 236,
		part_id: 322,
		quantity: 141,
	},
	{
		garage_id: 236,
		part_id: 323,
		quantity: 385,
	},
	{
		garage_id: 236,
		part_id: 324,
		quantity: 405,
	},
	{
		garage_id: 236,
		part_id: 325,
		quantity: 333,
	},
	{
		garage_id: 236,
		part_id: 326,
		quantity: 60,
	},
	{
		garage_id: 236,
		part_id: 327,
		quantity: 374,
	},
	{
		garage_id: 236,
		part_id: 328,
		quantity: 489,
	},
	{
		garage_id: 236,
		part_id: 329,
		quantity: 146,
	},
	{
		garage_id: 236,
		part_id: 330,
		quantity: 86,
	},
	{
		garage_id: 236,
		part_id: 331,
		quantity: 116,
	},
	{
		garage_id: 236,
		part_id: 332,
		quantity: 366,
	},
	{
		garage_id: 236,
		part_id: 333,
		quantity: 121,
	},
	{
		garage_id: 236,
		part_id: 334,
		quantity: 193,
	},
	{
		garage_id: 236,
		part_id: 335,
		quantity: 11,
	},
	{
		garage_id: 236,
		part_id: 336,
		quantity: 334,
	},
	{
		garage_id: 236,
		part_id: 337,
		quantity: 90,
	},
	{
		garage_id: 236,
		part_id: 338,
		quantity: 37,
	},
	{
		garage_id: 236,
		part_id: 339,
		quantity: 92,
	},
	{
		garage_id: 236,
		part_id: 340,
		quantity: 259,
	},
	{
		garage_id: 236,
		part_id: 341,
		quantity: 33,
	},
	{
		garage_id: 236,
		part_id: 342,
		quantity: 359,
	},
	{
		garage_id: 236,
		part_id: 343,
		quantity: 150,
	},
	{
		garage_id: 236,
		part_id: 344,
		quantity: 363,
	},
	{
		garage_id: 236,
		part_id: 345,
		quantity: 425,
	},
	{
		garage_id: 236,
		part_id: 346,
		quantity: 354,
	},
	{
		garage_id: 236,
		part_id: 347,
		quantity: 496,
	},
	{
		garage_id: 236,
		part_id: 348,
		quantity: 291,
	},
	{
		garage_id: 236,
		part_id: 349,
		quantity: 403,
	},
	{
		garage_id: 236,
		part_id: 350,
		quantity: 126,
	},
	{
		garage_id: 236,
		part_id: 351,
		quantity: 296,
	},
	{
		garage_id: 236,
		part_id: 352,
		quantity: 345,
	},
	{
		garage_id: 236,
		part_id: 353,
		quantity: 100,
	},
	{
		garage_id: 236,
		part_id: 354,
		quantity: 358,
	},
	{
		garage_id: 236,
		part_id: 355,
		quantity: 442,
	},
	{
		garage_id: 236,
		part_id: 356,
		quantity: 363,
	},
	{
		garage_id: 236,
		part_id: 357,
		quantity: 463,
	},
	{
		garage_id: 236,
		part_id: 358,
		quantity: 260,
	},
	{
		garage_id: 236,
		part_id: 359,
		quantity: 0,
	},
	{
		garage_id: 236,
		part_id: 360,
		quantity: 288,
	},
	{
		garage_id: 236,
		part_id: 361,
		quantity: 215,
	},
	{
		garage_id: 236,
		part_id: 362,
		quantity: 116,
	},
	{
		garage_id: 236,
		part_id: 363,
		quantity: 156,
	},
	{
		garage_id: 236,
		part_id: 364,
		quantity: 87,
	},
	{
		garage_id: 236,
		part_id: 365,
		quantity: 456,
	},
	{
		garage_id: 236,
		part_id: 366,
		quantity: 383,
	},
	{
		garage_id: 236,
		part_id: 367,
		quantity: 1,
	},
	{
		garage_id: 236,
		part_id: 368,
		quantity: 306,
	},
	{
		garage_id: 236,
		part_id: 369,
		quantity: 270,
	},
	{
		garage_id: 236,
		part_id: 370,
		quantity: 283,
	},
	{
		garage_id: 236,
		part_id: 371,
		quantity: 137,
	},
	{
		garage_id: 236,
		part_id: 372,
		quantity: 411,
	},
	{
		garage_id: 236,
		part_id: 373,
		quantity: 362,
	},
	{
		garage_id: 236,
		part_id: 374,
		quantity: 21,
	},
	{
		garage_id: 236,
		part_id: 375,
		quantity: 399,
	},
	{
		garage_id: 236,
		part_id: 376,
		quantity: 230,
	},
	{
		garage_id: 236,
		part_id: 377,
		quantity: 189,
	},
	{
		garage_id: 236,
		part_id: 378,
		quantity: 305,
	},
	{
		garage_id: 236,
		part_id: 379,
		quantity: 354,
	},
	{
		garage_id: 236,
		part_id: 380,
		quantity: 38,
	},
	{
		garage_id: 236,
		part_id: 381,
		quantity: 194,
	},
	{
		garage_id: 236,
		part_id: 382,
		quantity: 375,
	},
	{
		garage_id: 236,
		part_id: 383,
		quantity: 312,
	},
	{
		garage_id: 236,
		part_id: 384,
		quantity: 302,
	},
	{
		garage_id: 236,
		part_id: 385,
		quantity: 411,
	},
	{
		garage_id: 236,
		part_id: 386,
		quantity: 494,
	},
	{
		garage_id: 236,
		part_id: 387,
		quantity: 341,
	},
	{
		garage_id: 236,
		part_id: 388,
		quantity: 468,
	},
	{
		garage_id: 236,
		part_id: 389,
		quantity: 496,
	},
	{
		garage_id: 236,
		part_id: 390,
		quantity: 87,
	},
	{
		garage_id: 236,
		part_id: 391,
		quantity: 284,
	},
	{
		garage_id: 236,
		part_id: 392,
		quantity: 398,
	},
	{
		garage_id: 236,
		part_id: 393,
		quantity: 44,
	},
	{
		garage_id: 236,
		part_id: 394,
		quantity: 226,
	},
	{
		garage_id: 236,
		part_id: 395,
		quantity: 20,
	},
	{
		garage_id: 236,
		part_id: 396,
		quantity: 43,
	},
	{
		garage_id: 236,
		part_id: 397,
		quantity: 312,
	},
	{
		garage_id: 236,
		part_id: 398,
		quantity: 302,
	},
	{
		garage_id: 236,
		part_id: 399,
		quantity: 142,
	},
	{
		garage_id: 237,
		part_id: 0,
		quantity: 500,
	},
	{
		garage_id: 237,
		part_id: 1,
		quantity: 411,
	},
	{
		garage_id: 237,
		part_id: 2,
		quantity: 249,
	},
	{
		garage_id: 237,
		part_id: 3,
		quantity: 124,
	},
	{
		garage_id: 237,
		part_id: 4,
		quantity: 370,
	},
	{
		garage_id: 237,
		part_id: 5,
		quantity: 341,
	},
	{
		garage_id: 237,
		part_id: 6,
		quantity: 341,
	},
	{
		garage_id: 237,
		part_id: 7,
		quantity: 390,
	},
	{
		garage_id: 237,
		part_id: 8,
		quantity: 477,
	},
	{
		garage_id: 237,
		part_id: 9,
		quantity: 52,
	},
	{
		garage_id: 237,
		part_id: 10,
		quantity: 276,
	},
	{
		garage_id: 237,
		part_id: 11,
		quantity: 242,
	},
	{
		garage_id: 237,
		part_id: 12,
		quantity: 284,
	},
	{
		garage_id: 237,
		part_id: 13,
		quantity: 113,
	},
	{
		garage_id: 237,
		part_id: 14,
		quantity: 24,
	},
	{
		garage_id: 237,
		part_id: 15,
		quantity: 313,
	},
	{
		garage_id: 237,
		part_id: 16,
		quantity: 192,
	},
	{
		garage_id: 237,
		part_id: 17,
		quantity: 365,
	},
	{
		garage_id: 237,
		part_id: 18,
		quantity: 416,
	},
	{
		garage_id: 237,
		part_id: 19,
		quantity: 381,
	},
	{
		garage_id: 237,
		part_id: 20,
		quantity: 183,
	},
	{
		garage_id: 237,
		part_id: 21,
		quantity: 389,
	},
	{
		garage_id: 237,
		part_id: 22,
		quantity: 353,
	},
	{
		garage_id: 237,
		part_id: 23,
		quantity: 401,
	},
	{
		garage_id: 237,
		part_id: 24,
		quantity: 1,
	},
	{
		garage_id: 237,
		part_id: 25,
		quantity: 402,
	},
	{
		garage_id: 237,
		part_id: 26,
		quantity: 437,
	},
	{
		garage_id: 237,
		part_id: 27,
		quantity: 342,
	},
	{
		garage_id: 237,
		part_id: 28,
		quantity: 191,
	},
	{
		garage_id: 237,
		part_id: 29,
		quantity: 498,
	},
	{
		garage_id: 237,
		part_id: 30,
		quantity: 452,
	},
	{
		garage_id: 237,
		part_id: 31,
		quantity: 208,
	},
	{
		garage_id: 237,
		part_id: 32,
		quantity: 144,
	},
	{
		garage_id: 237,
		part_id: 33,
		quantity: 173,
	},
	{
		garage_id: 237,
		part_id: 34,
		quantity: 158,
	},
	{
		garage_id: 237,
		part_id: 35,
		quantity: 387,
	},
	{
		garage_id: 237,
		part_id: 36,
		quantity: 311,
	},
	{
		garage_id: 237,
		part_id: 37,
		quantity: 445,
	},
	{
		garage_id: 237,
		part_id: 38,
		quantity: 73,
	},
	{
		garage_id: 237,
		part_id: 39,
		quantity: 418,
	},
	{
		garage_id: 237,
		part_id: 40,
		quantity: 379,
	},
	{
		garage_id: 237,
		part_id: 41,
		quantity: 330,
	},
	{
		garage_id: 237,
		part_id: 42,
		quantity: 50,
	},
	{
		garage_id: 237,
		part_id: 43,
		quantity: 144,
	},
	{
		garage_id: 237,
		part_id: 44,
		quantity: 459,
	},
	{
		garage_id: 237,
		part_id: 45,
		quantity: 183,
	},
	{
		garage_id: 237,
		part_id: 46,
		quantity: 486,
	},
	{
		garage_id: 237,
		part_id: 47,
		quantity: 353,
	},
	{
		garage_id: 237,
		part_id: 48,
		quantity: 479,
	},
	{
		garage_id: 237,
		part_id: 49,
		quantity: 60,
	},
	{
		garage_id: 237,
		part_id: 50,
		quantity: 167,
	},
	{
		garage_id: 237,
		part_id: 51,
		quantity: 261,
	},
	{
		garage_id: 237,
		part_id: 52,
		quantity: 318,
	},
	{
		garage_id: 237,
		part_id: 53,
		quantity: 274,
	},
	{
		garage_id: 237,
		part_id: 54,
		quantity: 102,
	},
	{
		garage_id: 237,
		part_id: 55,
		quantity: 0,
	},
	{
		garage_id: 237,
		part_id: 56,
		quantity: 197,
	},
	{
		garage_id: 237,
		part_id: 57,
		quantity: 61,
	},
	{
		garage_id: 237,
		part_id: 58,
		quantity: 298,
	},
	{
		garage_id: 237,
		part_id: 59,
		quantity: 180,
	},
	{
		garage_id: 237,
		part_id: 60,
		quantity: 256,
	},
	{
		garage_id: 237,
		part_id: 61,
		quantity: 265,
	},
	{
		garage_id: 237,
		part_id: 62,
		quantity: 259,
	},
	{
		garage_id: 237,
		part_id: 63,
		quantity: 174,
	},
	{
		garage_id: 237,
		part_id: 64,
		quantity: 27,
	},
	{
		garage_id: 237,
		part_id: 65,
		quantity: 155,
	},
	{
		garage_id: 237,
		part_id: 66,
		quantity: 465,
	},
	{
		garage_id: 237,
		part_id: 67,
		quantity: 74,
	},
	{
		garage_id: 237,
		part_id: 68,
		quantity: 489,
	},
	{
		garage_id: 237,
		part_id: 69,
		quantity: 343,
	},
	{
		garage_id: 237,
		part_id: 70,
		quantity: 237,
	},
	{
		garage_id: 237,
		part_id: 71,
		quantity: 137,
	},
	{
		garage_id: 237,
		part_id: 72,
		quantity: 465,
	},
	{
		garage_id: 237,
		part_id: 73,
		quantity: 396,
	},
	{
		garage_id: 237,
		part_id: 74,
		quantity: 193,
	},
	{
		garage_id: 237,
		part_id: 75,
		quantity: 27,
	},
	{
		garage_id: 237,
		part_id: 76,
		quantity: 301,
	},
	{
		garage_id: 237,
		part_id: 77,
		quantity: 495,
	},
	{
		garage_id: 237,
		part_id: 78,
		quantity: 87,
	},
	{
		garage_id: 237,
		part_id: 79,
		quantity: 125,
	},
	{
		garage_id: 237,
		part_id: 80,
		quantity: 75,
	},
	{
		garage_id: 237,
		part_id: 81,
		quantity: 373,
	},
	{
		garage_id: 237,
		part_id: 82,
		quantity: 398,
	},
	{
		garage_id: 237,
		part_id: 83,
		quantity: 334,
	},
	{
		garage_id: 237,
		part_id: 84,
		quantity: 377,
	},
	{
		garage_id: 237,
		part_id: 85,
		quantity: 482,
	},
	{
		garage_id: 237,
		part_id: 86,
		quantity: 126,
	},
	{
		garage_id: 237,
		part_id: 87,
		quantity: 225,
	},
	{
		garage_id: 237,
		part_id: 88,
		quantity: 91,
	},
	{
		garage_id: 237,
		part_id: 89,
		quantity: 242,
	},
	{
		garage_id: 237,
		part_id: 90,
		quantity: 445,
	},
	{
		garage_id: 237,
		part_id: 91,
		quantity: 121,
	},
	{
		garage_id: 237,
		part_id: 92,
		quantity: 323,
	},
	{
		garage_id: 237,
		part_id: 93,
		quantity: 362,
	},
	{
		garage_id: 237,
		part_id: 94,
		quantity: 64,
	},
	{
		garage_id: 237,
		part_id: 95,
		quantity: 265,
	},
	{
		garage_id: 237,
		part_id: 96,
		quantity: 283,
	},
	{
		garage_id: 237,
		part_id: 97,
		quantity: 349,
	},
	{
		garage_id: 237,
		part_id: 98,
		quantity: 495,
	},
	{
		garage_id: 237,
		part_id: 99,
		quantity: 44,
	},
	{
		garage_id: 237,
		part_id: 100,
		quantity: 446,
	},
	{
		garage_id: 237,
		part_id: 101,
		quantity: 77,
	},
	{
		garage_id: 237,
		part_id: 102,
		quantity: 208,
	},
	{
		garage_id: 237,
		part_id: 103,
		quantity: 321,
	},
	{
		garage_id: 237,
		part_id: 104,
		quantity: 60,
	},
	{
		garage_id: 237,
		part_id: 105,
		quantity: 416,
	},
	{
		garage_id: 237,
		part_id: 106,
		quantity: 180,
	},
	{
		garage_id: 237,
		part_id: 107,
		quantity: 42,
	},
	{
		garage_id: 237,
		part_id: 108,
		quantity: 82,
	},
	{
		garage_id: 237,
		part_id: 109,
		quantity: 56,
	},
	{
		garage_id: 237,
		part_id: 110,
		quantity: 81,
	},
	{
		garage_id: 237,
		part_id: 111,
		quantity: 487,
	},
	{
		garage_id: 237,
		part_id: 112,
		quantity: 98,
	},
	{
		garage_id: 237,
		part_id: 113,
		quantity: 424,
	},
	{
		garage_id: 237,
		part_id: 114,
		quantity: 288,
	},
	{
		garage_id: 237,
		part_id: 115,
		quantity: 484,
	},
	{
		garage_id: 237,
		part_id: 116,
		quantity: 325,
	},
	{
		garage_id: 237,
		part_id: 117,
		quantity: 226,
	},
	{
		garage_id: 237,
		part_id: 118,
		quantity: 388,
	},
	{
		garage_id: 237,
		part_id: 119,
		quantity: 157,
	},
	{
		garage_id: 237,
		part_id: 120,
		quantity: 487,
	},
	{
		garage_id: 237,
		part_id: 121,
		quantity: 298,
	},
	{
		garage_id: 237,
		part_id: 122,
		quantity: 259,
	},
	{
		garage_id: 237,
		part_id: 123,
		quantity: 91,
	},
	{
		garage_id: 237,
		part_id: 124,
		quantity: 322,
	},
	{
		garage_id: 237,
		part_id: 125,
		quantity: 469,
	},
	{
		garage_id: 237,
		part_id: 126,
		quantity: 41,
	},
	{
		garage_id: 237,
		part_id: 127,
		quantity: 291,
	},
	{
		garage_id: 237,
		part_id: 128,
		quantity: 340,
	},
	{
		garage_id: 237,
		part_id: 129,
		quantity: 281,
	},
	{
		garage_id: 237,
		part_id: 130,
		quantity: 258,
	},
	{
		garage_id: 237,
		part_id: 131,
		quantity: 125,
	},
	{
		garage_id: 237,
		part_id: 132,
		quantity: 229,
	},
	{
		garage_id: 237,
		part_id: 133,
		quantity: 250,
	},
	{
		garage_id: 237,
		part_id: 134,
		quantity: 488,
	},
	{
		garage_id: 237,
		part_id: 135,
		quantity: 171,
	},
	{
		garage_id: 237,
		part_id: 136,
		quantity: 215,
	},
	{
		garage_id: 237,
		part_id: 137,
		quantity: 294,
	},
	{
		garage_id: 237,
		part_id: 138,
		quantity: 105,
	},
	{
		garage_id: 237,
		part_id: 139,
		quantity: 353,
	},
	{
		garage_id: 237,
		part_id: 140,
		quantity: 165,
	},
	{
		garage_id: 237,
		part_id: 141,
		quantity: 22,
	},
	{
		garage_id: 237,
		part_id: 142,
		quantity: 45,
	},
	{
		garage_id: 237,
		part_id: 143,
		quantity: 212,
	},
	{
		garage_id: 237,
		part_id: 144,
		quantity: 368,
	},
	{
		garage_id: 237,
		part_id: 145,
		quantity: 347,
	},
	{
		garage_id: 237,
		part_id: 146,
		quantity: 229,
	},
	{
		garage_id: 237,
		part_id: 147,
		quantity: 486,
	},
	{
		garage_id: 237,
		part_id: 148,
		quantity: 244,
	},
	{
		garage_id: 237,
		part_id: 149,
		quantity: 58,
	},
	{
		garage_id: 237,
		part_id: 150,
		quantity: 22,
	},
	{
		garage_id: 237,
		part_id: 151,
		quantity: 237,
	},
	{
		garage_id: 237,
		part_id: 152,
		quantity: 193,
	},
	{
		garage_id: 237,
		part_id: 153,
		quantity: 283,
	},
	{
		garage_id: 237,
		part_id: 154,
		quantity: 5,
	},
	{
		garage_id: 237,
		part_id: 155,
		quantity: 2,
	},
	{
		garage_id: 237,
		part_id: 156,
		quantity: 376,
	},
	{
		garage_id: 237,
		part_id: 157,
		quantity: 422,
	},
	{
		garage_id: 237,
		part_id: 158,
		quantity: 235,
	},
	{
		garage_id: 237,
		part_id: 159,
		quantity: 403,
	},
	{
		garage_id: 237,
		part_id: 160,
		quantity: 290,
	},
	{
		garage_id: 237,
		part_id: 161,
		quantity: 387,
	},
	{
		garage_id: 237,
		part_id: 162,
		quantity: 241,
	},
	{
		garage_id: 237,
		part_id: 163,
		quantity: 449,
	},
	{
		garage_id: 237,
		part_id: 164,
		quantity: 149,
	},
	{
		garage_id: 237,
		part_id: 165,
		quantity: 497,
	},
	{
		garage_id: 237,
		part_id: 166,
		quantity: 230,
	},
	{
		garage_id: 237,
		part_id: 167,
		quantity: 246,
	},
	{
		garage_id: 237,
		part_id: 168,
		quantity: 135,
	},
	{
		garage_id: 237,
		part_id: 169,
		quantity: 242,
	},
	{
		garage_id: 237,
		part_id: 170,
		quantity: 161,
	},
	{
		garage_id: 237,
		part_id: 171,
		quantity: 282,
	},
	{
		garage_id: 237,
		part_id: 172,
		quantity: 342,
	},
	{
		garage_id: 237,
		part_id: 173,
		quantity: 432,
	},
	{
		garage_id: 237,
		part_id: 174,
		quantity: 69,
	},
	{
		garage_id: 237,
		part_id: 175,
		quantity: 121,
	},
	{
		garage_id: 237,
		part_id: 176,
		quantity: 21,
	},
	{
		garage_id: 237,
		part_id: 177,
		quantity: 478,
	},
	{
		garage_id: 237,
		part_id: 178,
		quantity: 191,
	},
	{
		garage_id: 237,
		part_id: 179,
		quantity: 368,
	},
	{
		garage_id: 237,
		part_id: 180,
		quantity: 32,
	},
	{
		garage_id: 237,
		part_id: 181,
		quantity: 186,
	},
	{
		garage_id: 237,
		part_id: 182,
		quantity: 27,
	},
	{
		garage_id: 237,
		part_id: 183,
		quantity: 175,
	},
	{
		garage_id: 237,
		part_id: 184,
		quantity: 240,
	},
	{
		garage_id: 237,
		part_id: 185,
		quantity: 47,
	},
	{
		garage_id: 237,
		part_id: 186,
		quantity: 371,
	},
	{
		garage_id: 237,
		part_id: 187,
		quantity: 428,
	},
	{
		garage_id: 237,
		part_id: 188,
		quantity: 325,
	},
	{
		garage_id: 237,
		part_id: 189,
		quantity: 381,
	},
	{
		garage_id: 237,
		part_id: 190,
		quantity: 212,
	},
	{
		garage_id: 237,
		part_id: 191,
		quantity: 377,
	},
	{
		garage_id: 237,
		part_id: 192,
		quantity: 59,
	},
	{
		garage_id: 237,
		part_id: 193,
		quantity: 296,
	},
	{
		garage_id: 237,
		part_id: 194,
		quantity: 156,
	},
	{
		garage_id: 237,
		part_id: 195,
		quantity: 118,
	},
	{
		garage_id: 237,
		part_id: 196,
		quantity: 321,
	},
	{
		garage_id: 237,
		part_id: 197,
		quantity: 160,
	},
	{
		garage_id: 237,
		part_id: 198,
		quantity: 156,
	},
	{
		garage_id: 237,
		part_id: 199,
		quantity: 51,
	},
	{
		garage_id: 237,
		part_id: 200,
		quantity: 87,
	},
	{
		garage_id: 237,
		part_id: 201,
		quantity: 60,
	},
	{
		garage_id: 237,
		part_id: 202,
		quantity: 330,
	},
	{
		garage_id: 237,
		part_id: 203,
		quantity: 104,
	},
	{
		garage_id: 237,
		part_id: 204,
		quantity: 448,
	},
	{
		garage_id: 237,
		part_id: 205,
		quantity: 9,
	},
	{
		garage_id: 237,
		part_id: 206,
		quantity: 16,
	},
	{
		garage_id: 237,
		part_id: 207,
		quantity: 413,
	},
	{
		garage_id: 237,
		part_id: 208,
		quantity: 67,
	},
	{
		garage_id: 237,
		part_id: 209,
		quantity: 499,
	},
	{
		garage_id: 237,
		part_id: 210,
		quantity: 485,
	},
	{
		garage_id: 237,
		part_id: 211,
		quantity: 66,
	},
	{
		garage_id: 237,
		part_id: 212,
		quantity: 15,
	},
	{
		garage_id: 237,
		part_id: 213,
		quantity: 465,
	},
	{
		garage_id: 237,
		part_id: 214,
		quantity: 172,
	},
	{
		garage_id: 237,
		part_id: 215,
		quantity: 278,
	},
	{
		garage_id: 237,
		part_id: 216,
		quantity: 464,
	},
	{
		garage_id: 237,
		part_id: 217,
		quantity: 10,
	},
	{
		garage_id: 237,
		part_id: 218,
		quantity: 102,
	},
	{
		garage_id: 237,
		part_id: 219,
		quantity: 456,
	},
	{
		garage_id: 237,
		part_id: 220,
		quantity: 34,
	},
	{
		garage_id: 237,
		part_id: 221,
		quantity: 48,
	},
	{
		garage_id: 237,
		part_id: 222,
		quantity: 203,
	},
	{
		garage_id: 237,
		part_id: 223,
		quantity: 15,
	},
	{
		garage_id: 237,
		part_id: 224,
		quantity: 6,
	},
	{
		garage_id: 237,
		part_id: 225,
		quantity: 11,
	},
	{
		garage_id: 237,
		part_id: 226,
		quantity: 388,
	},
	{
		garage_id: 237,
		part_id: 227,
		quantity: 19,
	},
	{
		garage_id: 237,
		part_id: 228,
		quantity: 429,
	},
	{
		garage_id: 237,
		part_id: 229,
		quantity: 485,
	},
	{
		garage_id: 237,
		part_id: 230,
		quantity: 224,
	},
	{
		garage_id: 237,
		part_id: 231,
		quantity: 341,
	},
	{
		garage_id: 237,
		part_id: 232,
		quantity: 83,
	},
	{
		garage_id: 237,
		part_id: 233,
		quantity: 244,
	},
	{
		garage_id: 237,
		part_id: 234,
		quantity: 334,
	},
	{
		garage_id: 237,
		part_id: 235,
		quantity: 147,
	},
	{
		garage_id: 237,
		part_id: 236,
		quantity: 473,
	},
	{
		garage_id: 237,
		part_id: 237,
		quantity: 162,
	},
	{
		garage_id: 237,
		part_id: 238,
		quantity: 446,
	},
	{
		garage_id: 237,
		part_id: 239,
		quantity: 265,
	},
	{
		garage_id: 237,
		part_id: 240,
		quantity: 8,
	},
	{
		garage_id: 237,
		part_id: 241,
		quantity: 427,
	},
	{
		garage_id: 237,
		part_id: 242,
		quantity: 95,
	},
	{
		garage_id: 237,
		part_id: 243,
		quantity: 457,
	},
	{
		garage_id: 237,
		part_id: 244,
		quantity: 297,
	},
	{
		garage_id: 237,
		part_id: 245,
		quantity: 353,
	},
	{
		garage_id: 237,
		part_id: 246,
		quantity: 448,
	},
	{
		garage_id: 237,
		part_id: 247,
		quantity: 336,
	},
	{
		garage_id: 237,
		part_id: 248,
		quantity: 248,
	},
	{
		garage_id: 237,
		part_id: 249,
		quantity: 343,
	},
	{
		garage_id: 237,
		part_id: 250,
		quantity: 47,
	},
	{
		garage_id: 237,
		part_id: 251,
		quantity: 48,
	},
	{
		garage_id: 237,
		part_id: 252,
		quantity: 155,
	},
	{
		garage_id: 237,
		part_id: 253,
		quantity: 18,
	},
	{
		garage_id: 237,
		part_id: 254,
		quantity: 255,
	},
	{
		garage_id: 237,
		part_id: 255,
		quantity: 41,
	},
	{
		garage_id: 237,
		part_id: 256,
		quantity: 0,
	},
	{
		garage_id: 237,
		part_id: 257,
		quantity: 7,
	},
	{
		garage_id: 237,
		part_id: 258,
		quantity: 456,
	},
	{
		garage_id: 237,
		part_id: 259,
		quantity: 479,
	},
	{
		garage_id: 237,
		part_id: 260,
		quantity: 179,
	},
	{
		garage_id: 237,
		part_id: 261,
		quantity: 386,
	},
	{
		garage_id: 237,
		part_id: 262,
		quantity: 323,
	},
	{
		garage_id: 237,
		part_id: 263,
		quantity: 201,
	},
	{
		garage_id: 237,
		part_id: 264,
		quantity: 247,
	},
	{
		garage_id: 237,
		part_id: 265,
		quantity: 214,
	},
	{
		garage_id: 237,
		part_id: 266,
		quantity: 62,
	},
	{
		garage_id: 237,
		part_id: 267,
		quantity: 104,
	},
	{
		garage_id: 237,
		part_id: 268,
		quantity: 195,
	},
	{
		garage_id: 237,
		part_id: 269,
		quantity: 71,
	},
	{
		garage_id: 237,
		part_id: 270,
		quantity: 76,
	},
	{
		garage_id: 237,
		part_id: 271,
		quantity: 471,
	},
	{
		garage_id: 237,
		part_id: 272,
		quantity: 255,
	},
	{
		garage_id: 237,
		part_id: 273,
		quantity: 18,
	},
	{
		garage_id: 237,
		part_id: 274,
		quantity: 208,
	},
	{
		garage_id: 237,
		part_id: 275,
		quantity: 473,
	},
	{
		garage_id: 237,
		part_id: 276,
		quantity: 195,
	},
	{
		garage_id: 237,
		part_id: 277,
		quantity: 225,
	},
	{
		garage_id: 237,
		part_id: 278,
		quantity: 378,
	},
	{
		garage_id: 237,
		part_id: 279,
		quantity: 349,
	},
	{
		garage_id: 237,
		part_id: 280,
		quantity: 398,
	},
	{
		garage_id: 237,
		part_id: 281,
		quantity: 371,
	},
	{
		garage_id: 237,
		part_id: 282,
		quantity: 87,
	},
	{
		garage_id: 237,
		part_id: 283,
		quantity: 281,
	},
	{
		garage_id: 237,
		part_id: 284,
		quantity: 122,
	},
	{
		garage_id: 237,
		part_id: 285,
		quantity: 54,
	},
	{
		garage_id: 237,
		part_id: 286,
		quantity: 165,
	},
	{
		garage_id: 237,
		part_id: 287,
		quantity: 309,
	},
	{
		garage_id: 237,
		part_id: 288,
		quantity: 6,
	},
	{
		garage_id: 237,
		part_id: 289,
		quantity: 287,
	},
	{
		garage_id: 237,
		part_id: 290,
		quantity: 15,
	},
	{
		garage_id: 237,
		part_id: 291,
		quantity: 313,
	},
	{
		garage_id: 237,
		part_id: 292,
		quantity: 37,
	},
	{
		garage_id: 237,
		part_id: 293,
		quantity: 448,
	},
	{
		garage_id: 237,
		part_id: 294,
		quantity: 36,
	},
	{
		garage_id: 237,
		part_id: 295,
		quantity: 94,
	},
	{
		garage_id: 237,
		part_id: 296,
		quantity: 487,
	},
	{
		garage_id: 237,
		part_id: 297,
		quantity: 31,
	},
	{
		garage_id: 237,
		part_id: 298,
		quantity: 42,
	},
	{
		garage_id: 237,
		part_id: 299,
		quantity: 222,
	},
	{
		garage_id: 237,
		part_id: 300,
		quantity: 434,
	},
	{
		garage_id: 237,
		part_id: 301,
		quantity: 252,
	},
	{
		garage_id: 237,
		part_id: 302,
		quantity: 358,
	},
	{
		garage_id: 237,
		part_id: 303,
		quantity: 36,
	},
	{
		garage_id: 237,
		part_id: 304,
		quantity: 426,
	},
	{
		garage_id: 237,
		part_id: 305,
		quantity: 59,
	},
	{
		garage_id: 237,
		part_id: 306,
		quantity: 498,
	},
	{
		garage_id: 237,
		part_id: 307,
		quantity: 118,
	},
	{
		garage_id: 237,
		part_id: 308,
		quantity: 190,
	},
	{
		garage_id: 237,
		part_id: 309,
		quantity: 489,
	},
	{
		garage_id: 237,
		part_id: 310,
		quantity: 406,
	},
	{
		garage_id: 237,
		part_id: 311,
		quantity: 15,
	},
	{
		garage_id: 237,
		part_id: 312,
		quantity: 298,
	},
	{
		garage_id: 237,
		part_id: 313,
		quantity: 240,
	},
	{
		garage_id: 237,
		part_id: 314,
		quantity: 472,
	},
	{
		garage_id: 237,
		part_id: 315,
		quantity: 147,
	},
	{
		garage_id: 237,
		part_id: 316,
		quantity: 340,
	},
	{
		garage_id: 237,
		part_id: 317,
		quantity: 384,
	},
	{
		garage_id: 237,
		part_id: 318,
		quantity: 85,
	},
	{
		garage_id: 237,
		part_id: 319,
		quantity: 141,
	},
	{
		garage_id: 237,
		part_id: 320,
		quantity: 306,
	},
	{
		garage_id: 237,
		part_id: 321,
		quantity: 403,
	},
	{
		garage_id: 237,
		part_id: 322,
		quantity: 476,
	},
	{
		garage_id: 237,
		part_id: 323,
		quantity: 437,
	},
	{
		garage_id: 237,
		part_id: 324,
		quantity: 206,
	},
	{
		garage_id: 237,
		part_id: 325,
		quantity: 408,
	},
	{
		garage_id: 237,
		part_id: 326,
		quantity: 404,
	},
	{
		garage_id: 237,
		part_id: 327,
		quantity: 435,
	},
	{
		garage_id: 237,
		part_id: 328,
		quantity: 212,
	},
	{
		garage_id: 237,
		part_id: 329,
		quantity: 318,
	},
	{
		garage_id: 237,
		part_id: 330,
		quantity: 292,
	},
	{
		garage_id: 237,
		part_id: 331,
		quantity: 368,
	},
	{
		garage_id: 237,
		part_id: 332,
		quantity: 414,
	},
	{
		garage_id: 237,
		part_id: 333,
		quantity: 403,
	},
	{
		garage_id: 237,
		part_id: 334,
		quantity: 484,
	},
	{
		garage_id: 237,
		part_id: 335,
		quantity: 255,
	},
	{
		garage_id: 237,
		part_id: 336,
		quantity: 285,
	},
	{
		garage_id: 237,
		part_id: 337,
		quantity: 400,
	},
	{
		garage_id: 237,
		part_id: 338,
		quantity: 90,
	},
	{
		garage_id: 237,
		part_id: 339,
		quantity: 39,
	},
	{
		garage_id: 237,
		part_id: 340,
		quantity: 117,
	},
	{
		garage_id: 237,
		part_id: 341,
		quantity: 380,
	},
	{
		garage_id: 237,
		part_id: 342,
		quantity: 184,
	},
	{
		garage_id: 237,
		part_id: 343,
		quantity: 419,
	},
	{
		garage_id: 237,
		part_id: 344,
		quantity: 126,
	},
	{
		garage_id: 237,
		part_id: 345,
		quantity: 471,
	},
	{
		garage_id: 237,
		part_id: 346,
		quantity: 84,
	},
	{
		garage_id: 237,
		part_id: 347,
		quantity: 46,
	},
	{
		garage_id: 237,
		part_id: 348,
		quantity: 184,
	},
	{
		garage_id: 237,
		part_id: 349,
		quantity: 64,
	},
	{
		garage_id: 237,
		part_id: 350,
		quantity: 498,
	},
	{
		garage_id: 237,
		part_id: 351,
		quantity: 213,
	},
	{
		garage_id: 237,
		part_id: 352,
		quantity: 487,
	},
	{
		garage_id: 237,
		part_id: 353,
		quantity: 310,
	},
	{
		garage_id: 237,
		part_id: 354,
		quantity: 1,
	},
	{
		garage_id: 237,
		part_id: 355,
		quantity: 420,
	},
	{
		garage_id: 237,
		part_id: 356,
		quantity: 28,
	},
	{
		garage_id: 237,
		part_id: 357,
		quantity: 322,
	},
	{
		garage_id: 237,
		part_id: 358,
		quantity: 276,
	},
	{
		garage_id: 237,
		part_id: 359,
		quantity: 93,
	},
	{
		garage_id: 237,
		part_id: 360,
		quantity: 64,
	},
	{
		garage_id: 237,
		part_id: 361,
		quantity: 471,
	},
	{
		garage_id: 237,
		part_id: 362,
		quantity: 281,
	},
	{
		garage_id: 237,
		part_id: 363,
		quantity: 37,
	},
	{
		garage_id: 237,
		part_id: 364,
		quantity: 378,
	},
	{
		garage_id: 237,
		part_id: 365,
		quantity: 318,
	},
	{
		garage_id: 237,
		part_id: 366,
		quantity: 178,
	},
	{
		garage_id: 237,
		part_id: 367,
		quantity: 319,
	},
	{
		garage_id: 237,
		part_id: 368,
		quantity: 130,
	},
	{
		garage_id: 237,
		part_id: 369,
		quantity: 424,
	},
	{
		garage_id: 237,
		part_id: 370,
		quantity: 431,
	},
	{
		garage_id: 237,
		part_id: 371,
		quantity: 52,
	},
	{
		garage_id: 237,
		part_id: 372,
		quantity: 67,
	},
	{
		garage_id: 237,
		part_id: 373,
		quantity: 246,
	},
	{
		garage_id: 237,
		part_id: 374,
		quantity: 167,
	},
	{
		garage_id: 237,
		part_id: 375,
		quantity: 152,
	},
	{
		garage_id: 237,
		part_id: 376,
		quantity: 167,
	},
	{
		garage_id: 237,
		part_id: 377,
		quantity: 300,
	},
	{
		garage_id: 237,
		part_id: 378,
		quantity: 117,
	},
	{
		garage_id: 237,
		part_id: 379,
		quantity: 403,
	},
	{
		garage_id: 237,
		part_id: 380,
		quantity: 326,
	},
	{
		garage_id: 237,
		part_id: 381,
		quantity: 228,
	},
	{
		garage_id: 237,
		part_id: 382,
		quantity: 210,
	},
	{
		garage_id: 237,
		part_id: 383,
		quantity: 369,
	},
	{
		garage_id: 237,
		part_id: 384,
		quantity: 403,
	},
	{
		garage_id: 237,
		part_id: 385,
		quantity: 255,
	},
	{
		garage_id: 237,
		part_id: 386,
		quantity: 191,
	},
	{
		garage_id: 237,
		part_id: 387,
		quantity: 364,
	},
	{
		garage_id: 237,
		part_id: 388,
		quantity: 335,
	},
	{
		garage_id: 237,
		part_id: 389,
		quantity: 157,
	},
	{
		garage_id: 237,
		part_id: 390,
		quantity: 5,
	},
	{
		garage_id: 237,
		part_id: 391,
		quantity: 247,
	},
	{
		garage_id: 237,
		part_id: 392,
		quantity: 295,
	},
	{
		garage_id: 237,
		part_id: 393,
		quantity: 292,
	},
	{
		garage_id: 237,
		part_id: 394,
		quantity: 408,
	},
	{
		garage_id: 237,
		part_id: 395,
		quantity: 244,
	},
	{
		garage_id: 237,
		part_id: 396,
		quantity: 351,
	},
	{
		garage_id: 237,
		part_id: 397,
		quantity: 394,
	},
	{
		garage_id: 237,
		part_id: 398,
		quantity: 406,
	},
	{
		garage_id: 237,
		part_id: 399,
		quantity: 342,
	},
	{
		garage_id: 238,
		part_id: 0,
		quantity: 130,
	},
	{
		garage_id: 238,
		part_id: 1,
		quantity: 434,
	},
	{
		garage_id: 238,
		part_id: 2,
		quantity: 417,
	},
	{
		garage_id: 238,
		part_id: 3,
		quantity: 486,
	},
	{
		garage_id: 238,
		part_id: 4,
		quantity: 347,
	},
	{
		garage_id: 238,
		part_id: 5,
		quantity: 37,
	},
	{
		garage_id: 238,
		part_id: 6,
		quantity: 350,
	},
	{
		garage_id: 238,
		part_id: 7,
		quantity: 412,
	},
	{
		garage_id: 238,
		part_id: 8,
		quantity: 56,
	},
	{
		garage_id: 238,
		part_id: 9,
		quantity: 52,
	},
	{
		garage_id: 238,
		part_id: 10,
		quantity: 103,
	},
	{
		garage_id: 238,
		part_id: 11,
		quantity: 208,
	},
	{
		garage_id: 238,
		part_id: 12,
		quantity: 252,
	},
	{
		garage_id: 238,
		part_id: 13,
		quantity: 496,
	},
	{
		garage_id: 238,
		part_id: 14,
		quantity: 322,
	},
	{
		garage_id: 238,
		part_id: 15,
		quantity: 425,
	},
	{
		garage_id: 238,
		part_id: 16,
		quantity: 340,
	},
	{
		garage_id: 238,
		part_id: 17,
		quantity: 217,
	},
	{
		garage_id: 238,
		part_id: 18,
		quantity: 436,
	},
	{
		garage_id: 238,
		part_id: 19,
		quantity: 40,
	},
	{
		garage_id: 238,
		part_id: 20,
		quantity: 130,
	},
	{
		garage_id: 238,
		part_id: 21,
		quantity: 277,
	},
	{
		garage_id: 238,
		part_id: 22,
		quantity: 401,
	},
	{
		garage_id: 238,
		part_id: 23,
		quantity: 306,
	},
	{
		garage_id: 238,
		part_id: 24,
		quantity: 212,
	},
	{
		garage_id: 238,
		part_id: 25,
		quantity: 301,
	},
	{
		garage_id: 238,
		part_id: 26,
		quantity: 20,
	},
	{
		garage_id: 238,
		part_id: 27,
		quantity: 414,
	},
	{
		garage_id: 238,
		part_id: 28,
		quantity: 275,
	},
	{
		garage_id: 238,
		part_id: 29,
		quantity: 333,
	},
	{
		garage_id: 238,
		part_id: 30,
		quantity: 500,
	},
	{
		garage_id: 238,
		part_id: 31,
		quantity: 48,
	},
	{
		garage_id: 238,
		part_id: 32,
		quantity: 169,
	},
	{
		garage_id: 238,
		part_id: 33,
		quantity: 86,
	},
	{
		garage_id: 238,
		part_id: 34,
		quantity: 445,
	},
	{
		garage_id: 238,
		part_id: 35,
		quantity: 111,
	},
	{
		garage_id: 238,
		part_id: 36,
		quantity: 321,
	},
	{
		garage_id: 238,
		part_id: 37,
		quantity: 122,
	},
	{
		garage_id: 238,
		part_id: 38,
		quantity: 91,
	},
	{
		garage_id: 238,
		part_id: 39,
		quantity: 413,
	},
	{
		garage_id: 238,
		part_id: 40,
		quantity: 262,
	},
	{
		garage_id: 238,
		part_id: 41,
		quantity: 470,
	},
	{
		garage_id: 238,
		part_id: 42,
		quantity: 305,
	},
	{
		garage_id: 238,
		part_id: 43,
		quantity: 363,
	},
	{
		garage_id: 238,
		part_id: 44,
		quantity: 174,
	},
	{
		garage_id: 238,
		part_id: 45,
		quantity: 498,
	},
	{
		garage_id: 238,
		part_id: 46,
		quantity: 428,
	},
	{
		garage_id: 238,
		part_id: 47,
		quantity: 449,
	},
	{
		garage_id: 238,
		part_id: 48,
		quantity: 206,
	},
	{
		garage_id: 238,
		part_id: 49,
		quantity: 493,
	},
	{
		garage_id: 238,
		part_id: 50,
		quantity: 411,
	},
	{
		garage_id: 238,
		part_id: 51,
		quantity: 147,
	},
	{
		garage_id: 238,
		part_id: 52,
		quantity: 388,
	},
	{
		garage_id: 238,
		part_id: 53,
		quantity: 158,
	},
	{
		garage_id: 238,
		part_id: 54,
		quantity: 376,
	},
	{
		garage_id: 238,
		part_id: 55,
		quantity: 135,
	},
	{
		garage_id: 238,
		part_id: 56,
		quantity: 391,
	},
	{
		garage_id: 238,
		part_id: 57,
		quantity: 240,
	},
	{
		garage_id: 238,
		part_id: 58,
		quantity: 441,
	},
	{
		garage_id: 238,
		part_id: 59,
		quantity: 428,
	},
	{
		garage_id: 238,
		part_id: 60,
		quantity: 206,
	},
	{
		garage_id: 238,
		part_id: 61,
		quantity: 369,
	},
	{
		garage_id: 238,
		part_id: 62,
		quantity: 180,
	},
	{
		garage_id: 238,
		part_id: 63,
		quantity: 404,
	},
	{
		garage_id: 238,
		part_id: 64,
		quantity: 192,
	},
	{
		garage_id: 238,
		part_id: 65,
		quantity: 107,
	},
	{
		garage_id: 238,
		part_id: 66,
		quantity: 65,
	},
	{
		garage_id: 238,
		part_id: 67,
		quantity: 212,
	},
	{
		garage_id: 238,
		part_id: 68,
		quantity: 442,
	},
	{
		garage_id: 238,
		part_id: 69,
		quantity: 456,
	},
	{
		garage_id: 238,
		part_id: 70,
		quantity: 146,
	},
	{
		garage_id: 238,
		part_id: 71,
		quantity: 45,
	},
	{
		garage_id: 238,
		part_id: 72,
		quantity: 329,
	},
	{
		garage_id: 238,
		part_id: 73,
		quantity: 441,
	},
	{
		garage_id: 238,
		part_id: 74,
		quantity: 463,
	},
	{
		garage_id: 238,
		part_id: 75,
		quantity: 175,
	},
	{
		garage_id: 238,
		part_id: 76,
		quantity: 318,
	},
	{
		garage_id: 238,
		part_id: 77,
		quantity: 465,
	},
	{
		garage_id: 238,
		part_id: 78,
		quantity: 247,
	},
	{
		garage_id: 238,
		part_id: 79,
		quantity: 24,
	},
	{
		garage_id: 238,
		part_id: 80,
		quantity: 30,
	},
	{
		garage_id: 238,
		part_id: 81,
		quantity: 436,
	},
	{
		garage_id: 238,
		part_id: 82,
		quantity: 209,
	},
	{
		garage_id: 238,
		part_id: 83,
		quantity: 315,
	},
	{
		garage_id: 238,
		part_id: 84,
		quantity: 100,
	},
	{
		garage_id: 238,
		part_id: 85,
		quantity: 415,
	},
	{
		garage_id: 238,
		part_id: 86,
		quantity: 265,
	},
	{
		garage_id: 238,
		part_id: 87,
		quantity: 230,
	},
	{
		garage_id: 238,
		part_id: 88,
		quantity: 311,
	},
	{
		garage_id: 238,
		part_id: 89,
		quantity: 182,
	},
	{
		garage_id: 238,
		part_id: 90,
		quantity: 227,
	},
	{
		garage_id: 238,
		part_id: 91,
		quantity: 41,
	},
	{
		garage_id: 238,
		part_id: 92,
		quantity: 101,
	},
	{
		garage_id: 238,
		part_id: 93,
		quantity: 319,
	},
	{
		garage_id: 238,
		part_id: 94,
		quantity: 8,
	},
	{
		garage_id: 238,
		part_id: 95,
		quantity: 180,
	},
	{
		garage_id: 238,
		part_id: 96,
		quantity: 330,
	},
	{
		garage_id: 238,
		part_id: 97,
		quantity: 40,
	},
	{
		garage_id: 238,
		part_id: 98,
		quantity: 211,
	},
	{
		garage_id: 238,
		part_id: 99,
		quantity: 383,
	},
	{
		garage_id: 238,
		part_id: 100,
		quantity: 350,
	},
	{
		garage_id: 238,
		part_id: 101,
		quantity: 117,
	},
	{
		garage_id: 238,
		part_id: 102,
		quantity: 362,
	},
	{
		garage_id: 238,
		part_id: 103,
		quantity: 98,
	},
	{
		garage_id: 238,
		part_id: 104,
		quantity: 178,
	},
	{
		garage_id: 238,
		part_id: 105,
		quantity: 305,
	},
	{
		garage_id: 238,
		part_id: 106,
		quantity: 464,
	},
	{
		garage_id: 238,
		part_id: 107,
		quantity: 280,
	},
	{
		garage_id: 238,
		part_id: 108,
		quantity: 58,
	},
	{
		garage_id: 238,
		part_id: 109,
		quantity: 91,
	},
	{
		garage_id: 238,
		part_id: 110,
		quantity: 259,
	},
	{
		garage_id: 238,
		part_id: 111,
		quantity: 433,
	},
	{
		garage_id: 238,
		part_id: 112,
		quantity: 416,
	},
	{
		garage_id: 238,
		part_id: 113,
		quantity: 92,
	},
	{
		garage_id: 238,
		part_id: 114,
		quantity: 213,
	},
	{
		garage_id: 238,
		part_id: 115,
		quantity: 288,
	},
	{
		garage_id: 238,
		part_id: 116,
		quantity: 248,
	},
	{
		garage_id: 238,
		part_id: 117,
		quantity: 438,
	},
	{
		garage_id: 238,
		part_id: 118,
		quantity: 345,
	},
	{
		garage_id: 238,
		part_id: 119,
		quantity: 247,
	},
	{
		garage_id: 238,
		part_id: 120,
		quantity: 52,
	},
	{
		garage_id: 238,
		part_id: 121,
		quantity: 1,
	},
	{
		garage_id: 238,
		part_id: 122,
		quantity: 283,
	},
	{
		garage_id: 238,
		part_id: 123,
		quantity: 262,
	},
	{
		garage_id: 238,
		part_id: 124,
		quantity: 26,
	},
	{
		garage_id: 238,
		part_id: 125,
		quantity: 470,
	},
	{
		garage_id: 238,
		part_id: 126,
		quantity: 21,
	},
	{
		garage_id: 238,
		part_id: 127,
		quantity: 449,
	},
	{
		garage_id: 238,
		part_id: 128,
		quantity: 496,
	},
	{
		garage_id: 238,
		part_id: 129,
		quantity: 235,
	},
	{
		garage_id: 238,
		part_id: 130,
		quantity: 19,
	},
	{
		garage_id: 238,
		part_id: 131,
		quantity: 86,
	},
	{
		garage_id: 238,
		part_id: 132,
		quantity: 85,
	},
	{
		garage_id: 238,
		part_id: 133,
		quantity: 73,
	},
	{
		garage_id: 238,
		part_id: 134,
		quantity: 472,
	},
	{
		garage_id: 238,
		part_id: 135,
		quantity: 397,
	},
	{
		garage_id: 238,
		part_id: 136,
		quantity: 112,
	},
	{
		garage_id: 238,
		part_id: 137,
		quantity: 176,
	},
	{
		garage_id: 238,
		part_id: 138,
		quantity: 443,
	},
	{
		garage_id: 238,
		part_id: 139,
		quantity: 287,
	},
	{
		garage_id: 238,
		part_id: 140,
		quantity: 467,
	},
	{
		garage_id: 238,
		part_id: 141,
		quantity: 99,
	},
	{
		garage_id: 238,
		part_id: 142,
		quantity: 178,
	},
	{
		garage_id: 238,
		part_id: 143,
		quantity: 469,
	},
	{
		garage_id: 238,
		part_id: 144,
		quantity: 462,
	},
	{
		garage_id: 238,
		part_id: 145,
		quantity: 198,
	},
	{
		garage_id: 238,
		part_id: 146,
		quantity: 64,
	},
	{
		garage_id: 238,
		part_id: 147,
		quantity: 10,
	},
	{
		garage_id: 238,
		part_id: 148,
		quantity: 128,
	},
	{
		garage_id: 238,
		part_id: 149,
		quantity: 78,
	},
	{
		garage_id: 238,
		part_id: 150,
		quantity: 33,
	},
	{
		garage_id: 238,
		part_id: 151,
		quantity: 445,
	},
	{
		garage_id: 238,
		part_id: 152,
		quantity: 29,
	},
	{
		garage_id: 238,
		part_id: 153,
		quantity: 487,
	},
	{
		garage_id: 238,
		part_id: 154,
		quantity: 103,
	},
	{
		garage_id: 238,
		part_id: 155,
		quantity: 304,
	},
	{
		garage_id: 238,
		part_id: 156,
		quantity: 267,
	},
	{
		garage_id: 238,
		part_id: 157,
		quantity: 321,
	},
	{
		garage_id: 238,
		part_id: 158,
		quantity: 458,
	},
	{
		garage_id: 238,
		part_id: 159,
		quantity: 35,
	},
	{
		garage_id: 238,
		part_id: 160,
		quantity: 209,
	},
	{
		garage_id: 238,
		part_id: 161,
		quantity: 172,
	},
	{
		garage_id: 238,
		part_id: 162,
		quantity: 135,
	},
	{
		garage_id: 238,
		part_id: 163,
		quantity: 433,
	},
	{
		garage_id: 238,
		part_id: 164,
		quantity: 416,
	},
	{
		garage_id: 238,
		part_id: 165,
		quantity: 392,
	},
	{
		garage_id: 238,
		part_id: 166,
		quantity: 304,
	},
	{
		garage_id: 238,
		part_id: 167,
		quantity: 331,
	},
	{
		garage_id: 238,
		part_id: 168,
		quantity: 302,
	},
	{
		garage_id: 238,
		part_id: 169,
		quantity: 273,
	},
	{
		garage_id: 238,
		part_id: 170,
		quantity: 447,
	},
	{
		garage_id: 238,
		part_id: 171,
		quantity: 17,
	},
	{
		garage_id: 238,
		part_id: 172,
		quantity: 417,
	},
	{
		garage_id: 238,
		part_id: 173,
		quantity: 247,
	},
	{
		garage_id: 238,
		part_id: 174,
		quantity: 251,
	},
	{
		garage_id: 238,
		part_id: 175,
		quantity: 263,
	},
	{
		garage_id: 238,
		part_id: 176,
		quantity: 449,
	},
	{
		garage_id: 238,
		part_id: 177,
		quantity: 199,
	},
	{
		garage_id: 238,
		part_id: 178,
		quantity: 174,
	},
	{
		garage_id: 238,
		part_id: 179,
		quantity: 354,
	},
	{
		garage_id: 238,
		part_id: 180,
		quantity: 118,
	},
	{
		garage_id: 238,
		part_id: 181,
		quantity: 473,
	},
	{
		garage_id: 238,
		part_id: 182,
		quantity: 311,
	},
	{
		garage_id: 238,
		part_id: 183,
		quantity: 394,
	},
	{
		garage_id: 238,
		part_id: 184,
		quantity: 122,
	},
	{
		garage_id: 238,
		part_id: 185,
		quantity: 169,
	},
	{
		garage_id: 238,
		part_id: 186,
		quantity: 135,
	},
	{
		garage_id: 238,
		part_id: 187,
		quantity: 400,
	},
	{
		garage_id: 238,
		part_id: 188,
		quantity: 421,
	},
	{
		garage_id: 238,
		part_id: 189,
		quantity: 326,
	},
	{
		garage_id: 238,
		part_id: 190,
		quantity: 71,
	},
	{
		garage_id: 238,
		part_id: 191,
		quantity: 109,
	},
	{
		garage_id: 238,
		part_id: 192,
		quantity: 144,
	},
	{
		garage_id: 238,
		part_id: 193,
		quantity: 58,
	},
	{
		garage_id: 238,
		part_id: 194,
		quantity: 98,
	},
	{
		garage_id: 238,
		part_id: 195,
		quantity: 249,
	},
	{
		garage_id: 238,
		part_id: 196,
		quantity: 247,
	},
	{
		garage_id: 238,
		part_id: 197,
		quantity: 106,
	},
	{
		garage_id: 238,
		part_id: 198,
		quantity: 485,
	},
	{
		garage_id: 238,
		part_id: 199,
		quantity: 266,
	},
	{
		garage_id: 238,
		part_id: 200,
		quantity: 255,
	},
	{
		garage_id: 238,
		part_id: 201,
		quantity: 297,
	},
	{
		garage_id: 238,
		part_id: 202,
		quantity: 181,
	},
	{
		garage_id: 238,
		part_id: 203,
		quantity: 80,
	},
	{
		garage_id: 238,
		part_id: 204,
		quantity: 189,
	},
	{
		garage_id: 238,
		part_id: 205,
		quantity: 392,
	},
	{
		garage_id: 238,
		part_id: 206,
		quantity: 469,
	},
	{
		garage_id: 238,
		part_id: 207,
		quantity: 417,
	},
	{
		garage_id: 238,
		part_id: 208,
		quantity: 330,
	},
	{
		garage_id: 238,
		part_id: 209,
		quantity: 428,
	},
	{
		garage_id: 238,
		part_id: 210,
		quantity: 216,
	},
	{
		garage_id: 238,
		part_id: 211,
		quantity: 403,
	},
	{
		garage_id: 238,
		part_id: 212,
		quantity: 177,
	},
	{
		garage_id: 238,
		part_id: 213,
		quantity: 207,
	},
	{
		garage_id: 238,
		part_id: 214,
		quantity: 0,
	},
	{
		garage_id: 238,
		part_id: 215,
		quantity: 302,
	},
	{
		garage_id: 238,
		part_id: 216,
		quantity: 59,
	},
	{
		garage_id: 238,
		part_id: 217,
		quantity: 412,
	},
	{
		garage_id: 238,
		part_id: 218,
		quantity: 318,
	},
	{
		garage_id: 238,
		part_id: 219,
		quantity: 406,
	},
	{
		garage_id: 238,
		part_id: 220,
		quantity: 428,
	},
	{
		garage_id: 238,
		part_id: 221,
		quantity: 175,
	},
	{
		garage_id: 238,
		part_id: 222,
		quantity: 274,
	},
	{
		garage_id: 238,
		part_id: 223,
		quantity: 109,
	},
	{
		garage_id: 238,
		part_id: 224,
		quantity: 234,
	},
	{
		garage_id: 238,
		part_id: 225,
		quantity: 107,
	},
	{
		garage_id: 238,
		part_id: 226,
		quantity: 38,
	},
	{
		garage_id: 238,
		part_id: 227,
		quantity: 145,
	},
	{
		garage_id: 238,
		part_id: 228,
		quantity: 251,
	},
	{
		garage_id: 238,
		part_id: 229,
		quantity: 352,
	},
	{
		garage_id: 238,
		part_id: 230,
		quantity: 361,
	},
	{
		garage_id: 238,
		part_id: 231,
		quantity: 405,
	},
	{
		garage_id: 238,
		part_id: 232,
		quantity: 58,
	},
	{
		garage_id: 238,
		part_id: 233,
		quantity: 472,
	},
	{
		garage_id: 238,
		part_id: 234,
		quantity: 289,
	},
	{
		garage_id: 238,
		part_id: 235,
		quantity: 63,
	},
	{
		garage_id: 238,
		part_id: 236,
		quantity: 34,
	},
	{
		garage_id: 238,
		part_id: 237,
		quantity: 95,
	},
	{
		garage_id: 238,
		part_id: 238,
		quantity: 276,
	},
	{
		garage_id: 238,
		part_id: 239,
		quantity: 55,
	},
	{
		garage_id: 238,
		part_id: 240,
		quantity: 235,
	},
	{
		garage_id: 238,
		part_id: 241,
		quantity: 391,
	},
	{
		garage_id: 238,
		part_id: 242,
		quantity: 216,
	},
	{
		garage_id: 238,
		part_id: 243,
		quantity: 70,
	},
	{
		garage_id: 238,
		part_id: 244,
		quantity: 451,
	},
	{
		garage_id: 238,
		part_id: 245,
		quantity: 385,
	},
	{
		garage_id: 238,
		part_id: 246,
		quantity: 202,
	},
	{
		garage_id: 238,
		part_id: 247,
		quantity: 420,
	},
	{
		garage_id: 238,
		part_id: 248,
		quantity: 221,
	},
	{
		garage_id: 238,
		part_id: 249,
		quantity: 377,
	},
	{
		garage_id: 238,
		part_id: 250,
		quantity: 302,
	},
	{
		garage_id: 238,
		part_id: 251,
		quantity: 493,
	},
	{
		garage_id: 238,
		part_id: 252,
		quantity: 240,
	},
	{
		garage_id: 238,
		part_id: 253,
		quantity: 79,
	},
	{
		garage_id: 238,
		part_id: 254,
		quantity: 330,
	},
	{
		garage_id: 238,
		part_id: 255,
		quantity: 120,
	},
	{
		garage_id: 238,
		part_id: 256,
		quantity: 489,
	},
	{
		garage_id: 238,
		part_id: 257,
		quantity: 102,
	},
	{
		garage_id: 238,
		part_id: 258,
		quantity: 110,
	},
	{
		garage_id: 238,
		part_id: 259,
		quantity: 432,
	},
	{
		garage_id: 238,
		part_id: 260,
		quantity: 350,
	},
	{
		garage_id: 238,
		part_id: 261,
		quantity: 356,
	},
	{
		garage_id: 238,
		part_id: 262,
		quantity: 222,
	},
	{
		garage_id: 238,
		part_id: 263,
		quantity: 113,
	},
	{
		garage_id: 238,
		part_id: 264,
		quantity: 319,
	},
	{
		garage_id: 238,
		part_id: 265,
		quantity: 466,
	},
	{
		garage_id: 238,
		part_id: 266,
		quantity: 88,
	},
	{
		garage_id: 238,
		part_id: 267,
		quantity: 3,
	},
	{
		garage_id: 238,
		part_id: 268,
		quantity: 106,
	},
	{
		garage_id: 238,
		part_id: 269,
		quantity: 279,
	},
	{
		garage_id: 238,
		part_id: 270,
		quantity: 335,
	},
	{
		garage_id: 238,
		part_id: 271,
		quantity: 140,
	},
	{
		garage_id: 238,
		part_id: 272,
		quantity: 240,
	},
	{
		garage_id: 238,
		part_id: 273,
		quantity: 250,
	},
	{
		garage_id: 238,
		part_id: 274,
		quantity: 109,
	},
	{
		garage_id: 238,
		part_id: 275,
		quantity: 300,
	},
	{
		garage_id: 238,
		part_id: 276,
		quantity: 225,
	},
	{
		garage_id: 238,
		part_id: 277,
		quantity: 4,
	},
	{
		garage_id: 238,
		part_id: 278,
		quantity: 193,
	},
	{
		garage_id: 238,
		part_id: 279,
		quantity: 344,
	},
	{
		garage_id: 238,
		part_id: 280,
		quantity: 328,
	},
	{
		garage_id: 238,
		part_id: 281,
		quantity: 71,
	},
	{
		garage_id: 238,
		part_id: 282,
		quantity: 165,
	},
	{
		garage_id: 238,
		part_id: 283,
		quantity: 135,
	},
	{
		garage_id: 238,
		part_id: 284,
		quantity: 438,
	},
	{
		garage_id: 238,
		part_id: 285,
		quantity: 67,
	},
	{
		garage_id: 238,
		part_id: 286,
		quantity: 282,
	},
	{
		garage_id: 238,
		part_id: 287,
		quantity: 59,
	},
	{
		garage_id: 238,
		part_id: 288,
		quantity: 376,
	},
	{
		garage_id: 238,
		part_id: 289,
		quantity: 364,
	},
	{
		garage_id: 238,
		part_id: 290,
		quantity: 179,
	},
	{
		garage_id: 238,
		part_id: 291,
		quantity: 20,
	},
	{
		garage_id: 238,
		part_id: 292,
		quantity: 294,
	},
	{
		garage_id: 238,
		part_id: 293,
		quantity: 470,
	},
	{
		garage_id: 238,
		part_id: 294,
		quantity: 251,
	},
	{
		garage_id: 238,
		part_id: 295,
		quantity: 129,
	},
	{
		garage_id: 238,
		part_id: 296,
		quantity: 359,
	},
	{
		garage_id: 238,
		part_id: 297,
		quantity: 54,
	},
	{
		garage_id: 238,
		part_id: 298,
		quantity: 461,
	},
	{
		garage_id: 238,
		part_id: 299,
		quantity: 243,
	},
	{
		garage_id: 238,
		part_id: 300,
		quantity: 255,
	},
	{
		garage_id: 238,
		part_id: 301,
		quantity: 435,
	},
	{
		garage_id: 238,
		part_id: 302,
		quantity: 24,
	},
	{
		garage_id: 238,
		part_id: 303,
		quantity: 28,
	},
	{
		garage_id: 238,
		part_id: 304,
		quantity: 123,
	},
	{
		garage_id: 238,
		part_id: 305,
		quantity: 433,
	},
	{
		garage_id: 238,
		part_id: 306,
		quantity: 490,
	},
	{
		garage_id: 238,
		part_id: 307,
		quantity: 450,
	},
	{
		garage_id: 238,
		part_id: 308,
		quantity: 293,
	},
	{
		garage_id: 238,
		part_id: 309,
		quantity: 181,
	},
	{
		garage_id: 238,
		part_id: 310,
		quantity: 113,
	},
	{
		garage_id: 238,
		part_id: 311,
		quantity: 422,
	},
	{
		garage_id: 238,
		part_id: 312,
		quantity: 84,
	},
	{
		garage_id: 238,
		part_id: 313,
		quantity: 181,
	},
	{
		garage_id: 238,
		part_id: 314,
		quantity: 72,
	},
	{
		garage_id: 238,
		part_id: 315,
		quantity: 242,
	},
	{
		garage_id: 238,
		part_id: 316,
		quantity: 447,
	},
	{
		garage_id: 238,
		part_id: 317,
		quantity: 144,
	},
	{
		garage_id: 238,
		part_id: 318,
		quantity: 423,
	},
	{
		garage_id: 238,
		part_id: 319,
		quantity: 396,
	},
	{
		garage_id: 238,
		part_id: 320,
		quantity: 2,
	},
	{
		garage_id: 238,
		part_id: 321,
		quantity: 278,
	},
	{
		garage_id: 238,
		part_id: 322,
		quantity: 143,
	},
	{
		garage_id: 238,
		part_id: 323,
		quantity: 356,
	},
	{
		garage_id: 238,
		part_id: 324,
		quantity: 413,
	},
	{
		garage_id: 238,
		part_id: 325,
		quantity: 390,
	},
	{
		garage_id: 238,
		part_id: 326,
		quantity: 64,
	},
	{
		garage_id: 238,
		part_id: 327,
		quantity: 220,
	},
	{
		garage_id: 238,
		part_id: 328,
		quantity: 41,
	},
	{
		garage_id: 238,
		part_id: 329,
		quantity: 272,
	},
	{
		garage_id: 238,
		part_id: 330,
		quantity: 336,
	},
	{
		garage_id: 238,
		part_id: 331,
		quantity: 471,
	},
	{
		garage_id: 238,
		part_id: 332,
		quantity: 348,
	},
	{
		garage_id: 238,
		part_id: 333,
		quantity: 204,
	},
	{
		garage_id: 238,
		part_id: 334,
		quantity: 119,
	},
	{
		garage_id: 238,
		part_id: 335,
		quantity: 123,
	},
	{
		garage_id: 238,
		part_id: 336,
		quantity: 98,
	},
	{
		garage_id: 238,
		part_id: 337,
		quantity: 374,
	},
	{
		garage_id: 238,
		part_id: 338,
		quantity: 209,
	},
	{
		garage_id: 238,
		part_id: 339,
		quantity: 194,
	},
	{
		garage_id: 238,
		part_id: 340,
		quantity: 397,
	},
	{
		garage_id: 238,
		part_id: 341,
		quantity: 342,
	},
	{
		garage_id: 238,
		part_id: 342,
		quantity: 396,
	},
	{
		garage_id: 238,
		part_id: 343,
		quantity: 426,
	},
	{
		garage_id: 238,
		part_id: 344,
		quantity: 211,
	},
	{
		garage_id: 238,
		part_id: 345,
		quantity: 264,
	},
	{
		garage_id: 238,
		part_id: 346,
		quantity: 428,
	},
	{
		garage_id: 238,
		part_id: 347,
		quantity: 185,
	},
	{
		garage_id: 238,
		part_id: 348,
		quantity: 360,
	},
	{
		garage_id: 238,
		part_id: 349,
		quantity: 329,
	},
	{
		garage_id: 238,
		part_id: 350,
		quantity: 176,
	},
	{
		garage_id: 238,
		part_id: 351,
		quantity: 199,
	},
	{
		garage_id: 238,
		part_id: 352,
		quantity: 49,
	},
	{
		garage_id: 238,
		part_id: 353,
		quantity: 327,
	},
	{
		garage_id: 238,
		part_id: 354,
		quantity: 484,
	},
	{
		garage_id: 238,
		part_id: 355,
		quantity: 63,
	},
	{
		garage_id: 238,
		part_id: 356,
		quantity: 336,
	},
	{
		garage_id: 238,
		part_id: 357,
		quantity: 371,
	},
	{
		garage_id: 238,
		part_id: 358,
		quantity: 377,
	},
	{
		garage_id: 238,
		part_id: 359,
		quantity: 189,
	},
	{
		garage_id: 238,
		part_id: 360,
		quantity: 224,
	},
	{
		garage_id: 238,
		part_id: 361,
		quantity: 254,
	},
	{
		garage_id: 238,
		part_id: 362,
		quantity: 276,
	},
	{
		garage_id: 238,
		part_id: 363,
		quantity: 202,
	},
	{
		garage_id: 238,
		part_id: 364,
		quantity: 93,
	},
	{
		garage_id: 238,
		part_id: 365,
		quantity: 482,
	},
	{
		garage_id: 238,
		part_id: 366,
		quantity: 166,
	},
	{
		garage_id: 238,
		part_id: 367,
		quantity: 392,
	},
	{
		garage_id: 238,
		part_id: 368,
		quantity: 180,
	},
	{
		garage_id: 238,
		part_id: 369,
		quantity: 122,
	},
	{
		garage_id: 238,
		part_id: 370,
		quantity: 351,
	},
	{
		garage_id: 238,
		part_id: 371,
		quantity: 467,
	},
	{
		garage_id: 238,
		part_id: 372,
		quantity: 473,
	},
	{
		garage_id: 238,
		part_id: 373,
		quantity: 344,
	},
	{
		garage_id: 238,
		part_id: 374,
		quantity: 396,
	},
	{
		garage_id: 238,
		part_id: 375,
		quantity: 352,
	},
	{
		garage_id: 238,
		part_id: 376,
		quantity: 124,
	},
	{
		garage_id: 238,
		part_id: 377,
		quantity: 499,
	},
	{
		garage_id: 238,
		part_id: 378,
		quantity: 395,
	},
	{
		garage_id: 238,
		part_id: 379,
		quantity: 212,
	},
	{
		garage_id: 238,
		part_id: 380,
		quantity: 248,
	},
	{
		garage_id: 238,
		part_id: 381,
		quantity: 500,
	},
	{
		garage_id: 238,
		part_id: 382,
		quantity: 7,
	},
	{
		garage_id: 238,
		part_id: 383,
		quantity: 309,
	},
	{
		garage_id: 238,
		part_id: 384,
		quantity: 84,
	},
	{
		garage_id: 238,
		part_id: 385,
		quantity: 360,
	},
	{
		garage_id: 238,
		part_id: 386,
		quantity: 286,
	},
	{
		garage_id: 238,
		part_id: 387,
		quantity: 324,
	},
	{
		garage_id: 238,
		part_id: 388,
		quantity: 53,
	},
	{
		garage_id: 238,
		part_id: 389,
		quantity: 95,
	},
	{
		garage_id: 238,
		part_id: 390,
		quantity: 304,
	},
	{
		garage_id: 238,
		part_id: 391,
		quantity: 325,
	},
	{
		garage_id: 238,
		part_id: 392,
		quantity: 365,
	},
	{
		garage_id: 238,
		part_id: 393,
		quantity: 216,
	},
	{
		garage_id: 238,
		part_id: 394,
		quantity: 265,
	},
	{
		garage_id: 238,
		part_id: 395,
		quantity: 171,
	},
	{
		garage_id: 238,
		part_id: 396,
		quantity: 481,
	},
	{
		garage_id: 238,
		part_id: 397,
		quantity: 249,
	},
	{
		garage_id: 238,
		part_id: 398,
		quantity: 167,
	},
	{
		garage_id: 238,
		part_id: 399,
		quantity: 328,
	},
	{
		garage_id: 239,
		part_id: 0,
		quantity: 302,
	},
	{
		garage_id: 239,
		part_id: 1,
		quantity: 276,
	},
	{
		garage_id: 239,
		part_id: 2,
		quantity: 261,
	},
	{
		garage_id: 239,
		part_id: 3,
		quantity: 209,
	},
	{
		garage_id: 239,
		part_id: 4,
		quantity: 324,
	},
	{
		garage_id: 239,
		part_id: 5,
		quantity: 233,
	},
	{
		garage_id: 239,
		part_id: 6,
		quantity: 182,
	},
	{
		garage_id: 239,
		part_id: 7,
		quantity: 460,
	},
	{
		garage_id: 239,
		part_id: 8,
		quantity: 191,
	},
	{
		garage_id: 239,
		part_id: 9,
		quantity: 433,
	},
	{
		garage_id: 239,
		part_id: 10,
		quantity: 247,
	},
	{
		garage_id: 239,
		part_id: 11,
		quantity: 13,
	},
	{
		garage_id: 239,
		part_id: 12,
		quantity: 50,
	},
	{
		garage_id: 239,
		part_id: 13,
		quantity: 237,
	},
	{
		garage_id: 239,
		part_id: 14,
		quantity: 483,
	},
	{
		garage_id: 239,
		part_id: 15,
		quantity: 29,
	},
	{
		garage_id: 239,
		part_id: 16,
		quantity: 374,
	},
	{
		garage_id: 239,
		part_id: 17,
		quantity: 469,
	},
	{
		garage_id: 239,
		part_id: 18,
		quantity: 101,
	},
	{
		garage_id: 239,
		part_id: 19,
		quantity: 201,
	},
	{
		garage_id: 239,
		part_id: 20,
		quantity: 449,
	},
	{
		garage_id: 239,
		part_id: 21,
		quantity: 357,
	},
	{
		garage_id: 239,
		part_id: 22,
		quantity: 230,
	},
	{
		garage_id: 239,
		part_id: 23,
		quantity: 98,
	},
	{
		garage_id: 239,
		part_id: 24,
		quantity: 38,
	},
	{
		garage_id: 239,
		part_id: 25,
		quantity: 471,
	},
	{
		garage_id: 239,
		part_id: 26,
		quantity: 475,
	},
	{
		garage_id: 239,
		part_id: 27,
		quantity: 27,
	},
	{
		garage_id: 239,
		part_id: 28,
		quantity: 265,
	},
	{
		garage_id: 239,
		part_id: 29,
		quantity: 14,
	},
	{
		garage_id: 239,
		part_id: 30,
		quantity: 428,
	},
	{
		garage_id: 239,
		part_id: 31,
		quantity: 299,
	},
	{
		garage_id: 239,
		part_id: 32,
		quantity: 317,
	},
	{
		garage_id: 239,
		part_id: 33,
		quantity: 366,
	},
	{
		garage_id: 239,
		part_id: 34,
		quantity: 156,
	},
	{
		garage_id: 239,
		part_id: 35,
		quantity: 473,
	},
	{
		garage_id: 239,
		part_id: 36,
		quantity: 448,
	},
	{
		garage_id: 239,
		part_id: 37,
		quantity: 494,
	},
	{
		garage_id: 239,
		part_id: 38,
		quantity: 429,
	},
	{
		garage_id: 239,
		part_id: 39,
		quantity: 402,
	},
	{
		garage_id: 239,
		part_id: 40,
		quantity: 357,
	},
	{
		garage_id: 239,
		part_id: 41,
		quantity: 482,
	},
	{
		garage_id: 239,
		part_id: 42,
		quantity: 253,
	},
	{
		garage_id: 239,
		part_id: 43,
		quantity: 475,
	},
	{
		garage_id: 239,
		part_id: 44,
		quantity: 275,
	},
	{
		garage_id: 239,
		part_id: 45,
		quantity: 248,
	},
	{
		garage_id: 239,
		part_id: 46,
		quantity: 350,
	},
	{
		garage_id: 239,
		part_id: 47,
		quantity: 352,
	},
	{
		garage_id: 239,
		part_id: 48,
		quantity: 480,
	},
	{
		garage_id: 239,
		part_id: 49,
		quantity: 446,
	},
	{
		garage_id: 239,
		part_id: 50,
		quantity: 381,
	},
	{
		garage_id: 239,
		part_id: 51,
		quantity: 418,
	},
	{
		garage_id: 239,
		part_id: 52,
		quantity: 101,
	},
	{
		garage_id: 239,
		part_id: 53,
		quantity: 82,
	},
	{
		garage_id: 239,
		part_id: 54,
		quantity: 479,
	},
	{
		garage_id: 239,
		part_id: 55,
		quantity: 403,
	},
	{
		garage_id: 239,
		part_id: 56,
		quantity: 69,
	},
	{
		garage_id: 239,
		part_id: 57,
		quantity: 119,
	},
	{
		garage_id: 239,
		part_id: 58,
		quantity: 462,
	},
	{
		garage_id: 239,
		part_id: 59,
		quantity: 183,
	},
	{
		garage_id: 239,
		part_id: 60,
		quantity: 129,
	},
	{
		garage_id: 239,
		part_id: 61,
		quantity: 217,
	},
	{
		garage_id: 239,
		part_id: 62,
		quantity: 63,
	},
	{
		garage_id: 239,
		part_id: 63,
		quantity: 108,
	},
	{
		garage_id: 239,
		part_id: 64,
		quantity: 159,
	},
	{
		garage_id: 239,
		part_id: 65,
		quantity: 321,
	},
	{
		garage_id: 239,
		part_id: 66,
		quantity: 387,
	},
	{
		garage_id: 239,
		part_id: 67,
		quantity: 75,
	},
	{
		garage_id: 239,
		part_id: 68,
		quantity: 50,
	},
	{
		garage_id: 239,
		part_id: 69,
		quantity: 89,
	},
	{
		garage_id: 239,
		part_id: 70,
		quantity: 396,
	},
	{
		garage_id: 239,
		part_id: 71,
		quantity: 383,
	},
	{
		garage_id: 239,
		part_id: 72,
		quantity: 338,
	},
	{
		garage_id: 239,
		part_id: 73,
		quantity: 257,
	},
	{
		garage_id: 239,
		part_id: 74,
		quantity: 476,
	},
	{
		garage_id: 239,
		part_id: 75,
		quantity: 36,
	},
	{
		garage_id: 239,
		part_id: 76,
		quantity: 0,
	},
	{
		garage_id: 239,
		part_id: 77,
		quantity: 474,
	},
	{
		garage_id: 239,
		part_id: 78,
		quantity: 139,
	},
	{
		garage_id: 239,
		part_id: 79,
		quantity: 3,
	},
	{
		garage_id: 239,
		part_id: 80,
		quantity: 153,
	},
	{
		garage_id: 239,
		part_id: 81,
		quantity: 87,
	},
	{
		garage_id: 239,
		part_id: 82,
		quantity: 336,
	},
	{
		garage_id: 239,
		part_id: 83,
		quantity: 339,
	},
	{
		garage_id: 239,
		part_id: 84,
		quantity: 204,
	},
	{
		garage_id: 239,
		part_id: 85,
		quantity: 392,
	},
	{
		garage_id: 239,
		part_id: 86,
		quantity: 268,
	},
	{
		garage_id: 239,
		part_id: 87,
		quantity: 107,
	},
	{
		garage_id: 239,
		part_id: 88,
		quantity: 482,
	},
	{
		garage_id: 239,
		part_id: 89,
		quantity: 358,
	},
	{
		garage_id: 239,
		part_id: 90,
		quantity: 440,
	},
	{
		garage_id: 239,
		part_id: 91,
		quantity: 353,
	},
	{
		garage_id: 239,
		part_id: 92,
		quantity: 247,
	},
	{
		garage_id: 239,
		part_id: 93,
		quantity: 463,
	},
	{
		garage_id: 239,
		part_id: 94,
		quantity: 252,
	},
	{
		garage_id: 239,
		part_id: 95,
		quantity: 86,
	},
	{
		garage_id: 239,
		part_id: 96,
		quantity: 179,
	},
	{
		garage_id: 239,
		part_id: 97,
		quantity: 217,
	},
	{
		garage_id: 239,
		part_id: 98,
		quantity: 347,
	},
	{
		garage_id: 239,
		part_id: 99,
		quantity: 224,
	},
	{
		garage_id: 239,
		part_id: 100,
		quantity: 85,
	},
	{
		garage_id: 239,
		part_id: 101,
		quantity: 391,
	},
	{
		garage_id: 239,
		part_id: 102,
		quantity: 405,
	},
	{
		garage_id: 239,
		part_id: 103,
		quantity: 129,
	},
	{
		garage_id: 239,
		part_id: 104,
		quantity: 356,
	},
	{
		garage_id: 239,
		part_id: 105,
		quantity: 314,
	},
	{
		garage_id: 239,
		part_id: 106,
		quantity: 75,
	},
	{
		garage_id: 239,
		part_id: 107,
		quantity: 309,
	},
	{
		garage_id: 239,
		part_id: 108,
		quantity: 498,
	},
	{
		garage_id: 239,
		part_id: 109,
		quantity: 68,
	},
	{
		garage_id: 239,
		part_id: 110,
		quantity: 263,
	},
	{
		garage_id: 239,
		part_id: 111,
		quantity: 340,
	},
	{
		garage_id: 239,
		part_id: 112,
		quantity: 486,
	},
	{
		garage_id: 239,
		part_id: 113,
		quantity: 191,
	},
	{
		garage_id: 239,
		part_id: 114,
		quantity: 379,
	},
	{
		garage_id: 239,
		part_id: 115,
		quantity: 330,
	},
	{
		garage_id: 239,
		part_id: 116,
		quantity: 178,
	},
	{
		garage_id: 239,
		part_id: 117,
		quantity: 347,
	},
	{
		garage_id: 239,
		part_id: 118,
		quantity: 45,
	},
	{
		garage_id: 239,
		part_id: 119,
		quantity: 342,
	},
	{
		garage_id: 239,
		part_id: 120,
		quantity: 338,
	},
	{
		garage_id: 239,
		part_id: 121,
		quantity: 361,
	},
	{
		garage_id: 239,
		part_id: 122,
		quantity: 190,
	},
	{
		garage_id: 239,
		part_id: 123,
		quantity: 26,
	},
	{
		garage_id: 239,
		part_id: 124,
		quantity: 253,
	},
	{
		garage_id: 239,
		part_id: 125,
		quantity: 185,
	},
	{
		garage_id: 239,
		part_id: 126,
		quantity: 389,
	},
	{
		garage_id: 239,
		part_id: 127,
		quantity: 197,
	},
	{
		garage_id: 239,
		part_id: 128,
		quantity: 43,
	},
	{
		garage_id: 239,
		part_id: 129,
		quantity: 355,
	},
	{
		garage_id: 239,
		part_id: 130,
		quantity: 64,
	},
	{
		garage_id: 239,
		part_id: 131,
		quantity: 7,
	},
	{
		garage_id: 239,
		part_id: 132,
		quantity: 145,
	},
	{
		garage_id: 239,
		part_id: 133,
		quantity: 372,
	},
	{
		garage_id: 239,
		part_id: 134,
		quantity: 371,
	},
	{
		garage_id: 239,
		part_id: 135,
		quantity: 500,
	},
	{
		garage_id: 239,
		part_id: 136,
		quantity: 236,
	},
	{
		garage_id: 239,
		part_id: 137,
		quantity: 199,
	},
	{
		garage_id: 239,
		part_id: 138,
		quantity: 277,
	},
	{
		garage_id: 239,
		part_id: 139,
		quantity: 459,
	},
	{
		garage_id: 239,
		part_id: 140,
		quantity: 195,
	},
	{
		garage_id: 239,
		part_id: 141,
		quantity: 199,
	},
	{
		garage_id: 239,
		part_id: 142,
		quantity: 397,
	},
	{
		garage_id: 239,
		part_id: 143,
		quantity: 157,
	},
	{
		garage_id: 239,
		part_id: 144,
		quantity: 288,
	},
	{
		garage_id: 239,
		part_id: 145,
		quantity: 162,
	},
	{
		garage_id: 239,
		part_id: 146,
		quantity: 390,
	},
	{
		garage_id: 239,
		part_id: 147,
		quantity: 491,
	},
	{
		garage_id: 239,
		part_id: 148,
		quantity: 368,
	},
	{
		garage_id: 239,
		part_id: 149,
		quantity: 461,
	},
	{
		garage_id: 239,
		part_id: 150,
		quantity: 261,
	},
	{
		garage_id: 239,
		part_id: 151,
		quantity: 296,
	},
	{
		garage_id: 239,
		part_id: 152,
		quantity: 19,
	},
	{
		garage_id: 239,
		part_id: 153,
		quantity: 356,
	},
	{
		garage_id: 239,
		part_id: 154,
		quantity: 491,
	},
	{
		garage_id: 239,
		part_id: 155,
		quantity: 56,
	},
	{
		garage_id: 239,
		part_id: 156,
		quantity: 105,
	},
	{
		garage_id: 239,
		part_id: 157,
		quantity: 253,
	},
	{
		garage_id: 239,
		part_id: 158,
		quantity: 239,
	},
	{
		garage_id: 239,
		part_id: 159,
		quantity: 204,
	},
	{
		garage_id: 239,
		part_id: 160,
		quantity: 405,
	},
	{
		garage_id: 239,
		part_id: 161,
		quantity: 298,
	},
	{
		garage_id: 239,
		part_id: 162,
		quantity: 471,
	},
	{
		garage_id: 239,
		part_id: 163,
		quantity: 406,
	},
	{
		garage_id: 239,
		part_id: 164,
		quantity: 482,
	},
	{
		garage_id: 239,
		part_id: 165,
		quantity: 199,
	},
	{
		garage_id: 239,
		part_id: 166,
		quantity: 129,
	},
	{
		garage_id: 239,
		part_id: 167,
		quantity: 406,
	},
	{
		garage_id: 239,
		part_id: 168,
		quantity: 488,
	},
	{
		garage_id: 239,
		part_id: 169,
		quantity: 19,
	},
	{
		garage_id: 239,
		part_id: 170,
		quantity: 432,
	},
	{
		garage_id: 239,
		part_id: 171,
		quantity: 497,
	},
	{
		garage_id: 239,
		part_id: 172,
		quantity: 367,
	},
	{
		garage_id: 239,
		part_id: 173,
		quantity: 150,
	},
	{
		garage_id: 239,
		part_id: 174,
		quantity: 301,
	},
	{
		garage_id: 239,
		part_id: 175,
		quantity: 297,
	},
	{
		garage_id: 239,
		part_id: 176,
		quantity: 105,
	},
	{
		garage_id: 239,
		part_id: 177,
		quantity: 83,
	},
	{
		garage_id: 239,
		part_id: 178,
		quantity: 284,
	},
	{
		garage_id: 239,
		part_id: 179,
		quantity: 316,
	},
	{
		garage_id: 239,
		part_id: 180,
		quantity: 383,
	},
	{
		garage_id: 239,
		part_id: 181,
		quantity: 90,
	},
	{
		garage_id: 239,
		part_id: 182,
		quantity: 217,
	},
	{
		garage_id: 239,
		part_id: 183,
		quantity: 82,
	},
	{
		garage_id: 239,
		part_id: 184,
		quantity: 151,
	},
	{
		garage_id: 239,
		part_id: 185,
		quantity: 223,
	},
	{
		garage_id: 239,
		part_id: 186,
		quantity: 6,
	},
	{
		garage_id: 239,
		part_id: 187,
		quantity: 33,
	},
	{
		garage_id: 239,
		part_id: 188,
		quantity: 333,
	},
	{
		garage_id: 239,
		part_id: 189,
		quantity: 322,
	},
	{
		garage_id: 239,
		part_id: 190,
		quantity: 70,
	},
	{
		garage_id: 239,
		part_id: 191,
		quantity: 475,
	},
	{
		garage_id: 239,
		part_id: 192,
		quantity: 500,
	},
	{
		garage_id: 239,
		part_id: 193,
		quantity: 111,
	},
	{
		garage_id: 239,
		part_id: 194,
		quantity: 427,
	},
	{
		garage_id: 239,
		part_id: 195,
		quantity: 158,
	},
	{
		garage_id: 239,
		part_id: 196,
		quantity: 30,
	},
	{
		garage_id: 239,
		part_id: 197,
		quantity: 12,
	},
	{
		garage_id: 239,
		part_id: 198,
		quantity: 307,
	},
	{
		garage_id: 239,
		part_id: 199,
		quantity: 310,
	},
	{
		garage_id: 239,
		part_id: 200,
		quantity: 41,
	},
	{
		garage_id: 239,
		part_id: 201,
		quantity: 166,
	},
	{
		garage_id: 239,
		part_id: 202,
		quantity: 489,
	},
	{
		garage_id: 239,
		part_id: 203,
		quantity: 31,
	},
	{
		garage_id: 239,
		part_id: 204,
		quantity: 468,
	},
	{
		garage_id: 239,
		part_id: 205,
		quantity: 484,
	},
	{
		garage_id: 239,
		part_id: 206,
		quantity: 94,
	},
	{
		garage_id: 239,
		part_id: 207,
		quantity: 283,
	},
	{
		garage_id: 239,
		part_id: 208,
		quantity: 478,
	},
	{
		garage_id: 239,
		part_id: 209,
		quantity: 464,
	},
	{
		garage_id: 239,
		part_id: 210,
		quantity: 70,
	},
	{
		garage_id: 239,
		part_id: 211,
		quantity: 206,
	},
	{
		garage_id: 239,
		part_id: 212,
		quantity: 399,
	},
	{
		garage_id: 239,
		part_id: 213,
		quantity: 379,
	},
	{
		garage_id: 239,
		part_id: 214,
		quantity: 213,
	},
	{
		garage_id: 239,
		part_id: 215,
		quantity: 84,
	},
	{
		garage_id: 239,
		part_id: 216,
		quantity: 422,
	},
	{
		garage_id: 239,
		part_id: 217,
		quantity: 62,
	},
	{
		garage_id: 239,
		part_id: 218,
		quantity: 256,
	},
	{
		garage_id: 239,
		part_id: 219,
		quantity: 41,
	},
	{
		garage_id: 239,
		part_id: 220,
		quantity: 143,
	},
	{
		garage_id: 239,
		part_id: 221,
		quantity: 344,
	},
	{
		garage_id: 239,
		part_id: 222,
		quantity: 140,
	},
	{
		garage_id: 239,
		part_id: 223,
		quantity: 404,
	},
	{
		garage_id: 239,
		part_id: 224,
		quantity: 117,
	},
	{
		garage_id: 239,
		part_id: 225,
		quantity: 345,
	},
	{
		garage_id: 239,
		part_id: 226,
		quantity: 152,
	},
	{
		garage_id: 239,
		part_id: 227,
		quantity: 347,
	},
	{
		garage_id: 239,
		part_id: 228,
		quantity: 494,
	},
	{
		garage_id: 239,
		part_id: 229,
		quantity: 260,
	},
	{
		garage_id: 239,
		part_id: 230,
		quantity: 79,
	},
	{
		garage_id: 239,
		part_id: 231,
		quantity: 184,
	},
	{
		garage_id: 239,
		part_id: 232,
		quantity: 194,
	},
	{
		garage_id: 239,
		part_id: 233,
		quantity: 130,
	},
	{
		garage_id: 239,
		part_id: 234,
		quantity: 484,
	},
	{
		garage_id: 239,
		part_id: 235,
		quantity: 405,
	},
	{
		garage_id: 239,
		part_id: 236,
		quantity: 290,
	},
	{
		garage_id: 239,
		part_id: 237,
		quantity: 293,
	},
	{
		garage_id: 239,
		part_id: 238,
		quantity: 496,
	},
	{
		garage_id: 239,
		part_id: 239,
		quantity: 253,
	},
	{
		garage_id: 239,
		part_id: 240,
		quantity: 56,
	},
	{
		garage_id: 239,
		part_id: 241,
		quantity: 10,
	},
	{
		garage_id: 239,
		part_id: 242,
		quantity: 101,
	},
	{
		garage_id: 239,
		part_id: 243,
		quantity: 321,
	},
	{
		garage_id: 239,
		part_id: 244,
		quantity: 435,
	},
	{
		garage_id: 239,
		part_id: 245,
		quantity: 476,
	},
	{
		garage_id: 239,
		part_id: 246,
		quantity: 139,
	},
	{
		garage_id: 239,
		part_id: 247,
		quantity: 123,
	},
	{
		garage_id: 239,
		part_id: 248,
		quantity: 471,
	},
	{
		garage_id: 239,
		part_id: 249,
		quantity: 12,
	},
	{
		garage_id: 239,
		part_id: 250,
		quantity: 204,
	},
	{
		garage_id: 239,
		part_id: 251,
		quantity: 2,
	},
	{
		garage_id: 239,
		part_id: 252,
		quantity: 409,
	},
	{
		garage_id: 239,
		part_id: 253,
		quantity: 469,
	},
	{
		garage_id: 239,
		part_id: 254,
		quantity: 423,
	},
	{
		garage_id: 239,
		part_id: 255,
		quantity: 349,
	},
	{
		garage_id: 239,
		part_id: 256,
		quantity: 278,
	},
	{
		garage_id: 239,
		part_id: 257,
		quantity: 497,
	},
	{
		garage_id: 239,
		part_id: 258,
		quantity: 167,
	},
	{
		garage_id: 239,
		part_id: 259,
		quantity: 85,
	},
	{
		garage_id: 239,
		part_id: 260,
		quantity: 373,
	},
	{
		garage_id: 239,
		part_id: 261,
		quantity: 298,
	},
	{
		garage_id: 239,
		part_id: 262,
		quantity: 353,
	},
	{
		garage_id: 239,
		part_id: 263,
		quantity: 72,
	},
	{
		garage_id: 239,
		part_id: 264,
		quantity: 105,
	},
	{
		garage_id: 239,
		part_id: 265,
		quantity: 408,
	},
	{
		garage_id: 239,
		part_id: 266,
		quantity: 304,
	},
	{
		garage_id: 239,
		part_id: 267,
		quantity: 466,
	},
	{
		garage_id: 239,
		part_id: 268,
		quantity: 409,
	},
	{
		garage_id: 239,
		part_id: 269,
		quantity: 299,
	},
	{
		garage_id: 239,
		part_id: 270,
		quantity: 285,
	},
	{
		garage_id: 239,
		part_id: 271,
		quantity: 185,
	},
	{
		garage_id: 239,
		part_id: 272,
		quantity: 65,
	},
	{
		garage_id: 239,
		part_id: 273,
		quantity: 369,
	},
	{
		garage_id: 239,
		part_id: 274,
		quantity: 304,
	},
	{
		garage_id: 239,
		part_id: 275,
		quantity: 50,
	},
	{
		garage_id: 239,
		part_id: 276,
		quantity: 436,
	},
	{
		garage_id: 239,
		part_id: 277,
		quantity: 35,
	},
	{
		garage_id: 239,
		part_id: 278,
		quantity: 194,
	},
	{
		garage_id: 239,
		part_id: 279,
		quantity: 202,
	},
	{
		garage_id: 239,
		part_id: 280,
		quantity: 250,
	},
	{
		garage_id: 239,
		part_id: 281,
		quantity: 444,
	},
	{
		garage_id: 239,
		part_id: 282,
		quantity: 300,
	},
	{
		garage_id: 239,
		part_id: 283,
		quantity: 70,
	},
	{
		garage_id: 239,
		part_id: 284,
		quantity: 465,
	},
	{
		garage_id: 239,
		part_id: 285,
		quantity: 238,
	},
	{
		garage_id: 239,
		part_id: 286,
		quantity: 62,
	},
	{
		garage_id: 239,
		part_id: 287,
		quantity: 292,
	},
	{
		garage_id: 239,
		part_id: 288,
		quantity: 179,
	},
	{
		garage_id: 239,
		part_id: 289,
		quantity: 356,
	},
	{
		garage_id: 239,
		part_id: 290,
		quantity: 300,
	},
	{
		garage_id: 239,
		part_id: 291,
		quantity: 51,
	},
	{
		garage_id: 239,
		part_id: 292,
		quantity: 144,
	},
	{
		garage_id: 239,
		part_id: 293,
		quantity: 410,
	},
	{
		garage_id: 239,
		part_id: 294,
		quantity: 56,
	},
	{
		garage_id: 239,
		part_id: 295,
		quantity: 465,
	},
	{
		garage_id: 239,
		part_id: 296,
		quantity: 466,
	},
	{
		garage_id: 239,
		part_id: 297,
		quantity: 95,
	},
	{
		garage_id: 239,
		part_id: 298,
		quantity: 316,
	},
	{
		garage_id: 239,
		part_id: 299,
		quantity: 203,
	},
	{
		garage_id: 239,
		part_id: 300,
		quantity: 277,
	},
	{
		garage_id: 239,
		part_id: 301,
		quantity: 2,
	},
	{
		garage_id: 239,
		part_id: 302,
		quantity: 322,
	},
	{
		garage_id: 239,
		part_id: 303,
		quantity: 264,
	},
	{
		garage_id: 239,
		part_id: 304,
		quantity: 103,
	},
	{
		garage_id: 239,
		part_id: 305,
		quantity: 135,
	},
	{
		garage_id: 239,
		part_id: 306,
		quantity: 72,
	},
	{
		garage_id: 239,
		part_id: 307,
		quantity: 140,
	},
	{
		garage_id: 239,
		part_id: 308,
		quantity: 395,
	},
	{
		garage_id: 239,
		part_id: 309,
		quantity: 471,
	},
	{
		garage_id: 239,
		part_id: 310,
		quantity: 492,
	},
	{
		garage_id: 239,
		part_id: 311,
		quantity: 157,
	},
	{
		garage_id: 239,
		part_id: 312,
		quantity: 39,
	},
	{
		garage_id: 239,
		part_id: 313,
		quantity: 14,
	},
	{
		garage_id: 239,
		part_id: 314,
		quantity: 38,
	},
	{
		garage_id: 239,
		part_id: 315,
		quantity: 320,
	},
	{
		garage_id: 239,
		part_id: 316,
		quantity: 54,
	},
	{
		garage_id: 239,
		part_id: 317,
		quantity: 400,
	},
	{
		garage_id: 239,
		part_id: 318,
		quantity: 171,
	},
	{
		garage_id: 239,
		part_id: 319,
		quantity: 129,
	},
	{
		garage_id: 239,
		part_id: 320,
		quantity: 152,
	},
	{
		garage_id: 239,
		part_id: 321,
		quantity: 491,
	},
	{
		garage_id: 239,
		part_id: 322,
		quantity: 284,
	},
	{
		garage_id: 239,
		part_id: 323,
		quantity: 121,
	},
	{
		garage_id: 239,
		part_id: 324,
		quantity: 122,
	},
	{
		garage_id: 239,
		part_id: 325,
		quantity: 419,
	},
	{
		garage_id: 239,
		part_id: 326,
		quantity: 485,
	},
	{
		garage_id: 239,
		part_id: 327,
		quantity: 88,
	},
	{
		garage_id: 239,
		part_id: 328,
		quantity: 10,
	},
	{
		garage_id: 239,
		part_id: 329,
		quantity: 83,
	},
	{
		garage_id: 239,
		part_id: 330,
		quantity: 345,
	},
	{
		garage_id: 239,
		part_id: 331,
		quantity: 2,
	},
	{
		garage_id: 239,
		part_id: 332,
		quantity: 154,
	},
	{
		garage_id: 239,
		part_id: 333,
		quantity: 245,
	},
	{
		garage_id: 239,
		part_id: 334,
		quantity: 251,
	},
	{
		garage_id: 239,
		part_id: 335,
		quantity: 365,
	},
	{
		garage_id: 239,
		part_id: 336,
		quantity: 410,
	},
	{
		garage_id: 239,
		part_id: 337,
		quantity: 20,
	},
	{
		garage_id: 239,
		part_id: 338,
		quantity: 418,
	},
	{
		garage_id: 239,
		part_id: 339,
		quantity: 242,
	},
	{
		garage_id: 239,
		part_id: 340,
		quantity: 408,
	},
	{
		garage_id: 239,
		part_id: 341,
		quantity: 393,
	},
	{
		garage_id: 239,
		part_id: 342,
		quantity: 44,
	},
	{
		garage_id: 239,
		part_id: 343,
		quantity: 1,
	},
	{
		garage_id: 239,
		part_id: 344,
		quantity: 207,
	},
	{
		garage_id: 239,
		part_id: 345,
		quantity: 252,
	},
	{
		garage_id: 239,
		part_id: 346,
		quantity: 355,
	},
	{
		garage_id: 239,
		part_id: 347,
		quantity: 480,
	},
	{
		garage_id: 239,
		part_id: 348,
		quantity: 316,
	},
	{
		garage_id: 239,
		part_id: 349,
		quantity: 31,
	},
	{
		garage_id: 239,
		part_id: 350,
		quantity: 123,
	},
	{
		garage_id: 239,
		part_id: 351,
		quantity: 319,
	},
	{
		garage_id: 239,
		part_id: 352,
		quantity: 287,
	},
	{
		garage_id: 239,
		part_id: 353,
		quantity: 440,
	},
	{
		garage_id: 239,
		part_id: 354,
		quantity: 174,
	},
	{
		garage_id: 239,
		part_id: 355,
		quantity: 371,
	},
	{
		garage_id: 239,
		part_id: 356,
		quantity: 127,
	},
	{
		garage_id: 239,
		part_id: 357,
		quantity: 376,
	},
	{
		garage_id: 239,
		part_id: 358,
		quantity: 228,
	},
	{
		garage_id: 239,
		part_id: 359,
		quantity: 446,
	},
	{
		garage_id: 239,
		part_id: 360,
		quantity: 367,
	},
	{
		garage_id: 239,
		part_id: 361,
		quantity: 225,
	},
	{
		garage_id: 239,
		part_id: 362,
		quantity: 293,
	},
	{
		garage_id: 239,
		part_id: 363,
		quantity: 332,
	},
	{
		garage_id: 239,
		part_id: 364,
		quantity: 257,
	},
	{
		garage_id: 239,
		part_id: 365,
		quantity: 330,
	},
	{
		garage_id: 239,
		part_id: 366,
		quantity: 126,
	},
	{
		garage_id: 239,
		part_id: 367,
		quantity: 74,
	},
	{
		garage_id: 239,
		part_id: 368,
		quantity: 33,
	},
	{
		garage_id: 239,
		part_id: 369,
		quantity: 97,
	},
	{
		garage_id: 239,
		part_id: 370,
		quantity: 235,
	},
	{
		garage_id: 239,
		part_id: 371,
		quantity: 10,
	},
	{
		garage_id: 239,
		part_id: 372,
		quantity: 217,
	},
	{
		garage_id: 239,
		part_id: 373,
		quantity: 0,
	},
	{
		garage_id: 239,
		part_id: 374,
		quantity: 120,
	},
	{
		garage_id: 239,
		part_id: 375,
		quantity: 222,
	},
	{
		garage_id: 239,
		part_id: 376,
		quantity: 90,
	},
	{
		garage_id: 239,
		part_id: 377,
		quantity: 339,
	},
	{
		garage_id: 239,
		part_id: 378,
		quantity: 40,
	},
	{
		garage_id: 239,
		part_id: 379,
		quantity: 282,
	},
	{
		garage_id: 239,
		part_id: 380,
		quantity: 264,
	},
	{
		garage_id: 239,
		part_id: 381,
		quantity: 159,
	},
	{
		garage_id: 239,
		part_id: 382,
		quantity: 247,
	},
	{
		garage_id: 239,
		part_id: 383,
		quantity: 319,
	},
	{
		garage_id: 239,
		part_id: 384,
		quantity: 344,
	},
	{
		garage_id: 239,
		part_id: 385,
		quantity: 425,
	},
	{
		garage_id: 239,
		part_id: 386,
		quantity: 271,
	},
	{
		garage_id: 239,
		part_id: 387,
		quantity: 413,
	},
	{
		garage_id: 239,
		part_id: 388,
		quantity: 478,
	},
	{
		garage_id: 239,
		part_id: 389,
		quantity: 474,
	},
	{
		garage_id: 239,
		part_id: 390,
		quantity: 382,
	},
	{
		garage_id: 239,
		part_id: 391,
		quantity: 11,
	},
	{
		garage_id: 239,
		part_id: 392,
		quantity: 165,
	},
	{
		garage_id: 239,
		part_id: 393,
		quantity: 403,
	},
	{
		garage_id: 239,
		part_id: 394,
		quantity: 95,
	},
	{
		garage_id: 239,
		part_id: 395,
		quantity: 315,
	},
	{
		garage_id: 239,
		part_id: 396,
		quantity: 470,
	},
	{
		garage_id: 239,
		part_id: 397,
		quantity: 66,
	},
	{
		garage_id: 239,
		part_id: 398,
		quantity: 78,
	},
	{
		garage_id: 239,
		part_id: 399,
		quantity: 317,
	},
	{
		garage_id: 240,
		part_id: 0,
		quantity: 453,
	},
	{
		garage_id: 240,
		part_id: 1,
		quantity: 178,
	},
	{
		garage_id: 240,
		part_id: 2,
		quantity: 147,
	},
	{
		garage_id: 240,
		part_id: 3,
		quantity: 292,
	},
	{
		garage_id: 240,
		part_id: 4,
		quantity: 187,
	},
	{
		garage_id: 240,
		part_id: 5,
		quantity: 249,
	},
	{
		garage_id: 240,
		part_id: 6,
		quantity: 493,
	},
	{
		garage_id: 240,
		part_id: 7,
		quantity: 109,
	},
	{
		garage_id: 240,
		part_id: 8,
		quantity: 326,
	},
	{
		garage_id: 240,
		part_id: 9,
		quantity: 325,
	},
	{
		garage_id: 240,
		part_id: 10,
		quantity: 100,
	},
	{
		garage_id: 240,
		part_id: 11,
		quantity: 356,
	},
	{
		garage_id: 240,
		part_id: 12,
		quantity: 169,
	},
	{
		garage_id: 240,
		part_id: 13,
		quantity: 212,
	},
	{
		garage_id: 240,
		part_id: 14,
		quantity: 379,
	},
	{
		garage_id: 240,
		part_id: 15,
		quantity: 86,
	},
	{
		garage_id: 240,
		part_id: 16,
		quantity: 328,
	},
	{
		garage_id: 240,
		part_id: 17,
		quantity: 104,
	},
	{
		garage_id: 240,
		part_id: 18,
		quantity: 316,
	},
	{
		garage_id: 240,
		part_id: 19,
		quantity: 106,
	},
	{
		garage_id: 240,
		part_id: 20,
		quantity: 345,
	},
	{
		garage_id: 240,
		part_id: 21,
		quantity: 85,
	},
	{
		garage_id: 240,
		part_id: 22,
		quantity: 370,
	},
	{
		garage_id: 240,
		part_id: 23,
		quantity: 138,
	},
	{
		garage_id: 240,
		part_id: 24,
		quantity: 164,
	},
	{
		garage_id: 240,
		part_id: 25,
		quantity: 100,
	},
	{
		garage_id: 240,
		part_id: 26,
		quantity: 352,
	},
	{
		garage_id: 240,
		part_id: 27,
		quantity: 192,
	},
	{
		garage_id: 240,
		part_id: 28,
		quantity: 193,
	},
	{
		garage_id: 240,
		part_id: 29,
		quantity: 434,
	},
	{
		garage_id: 240,
		part_id: 30,
		quantity: 106,
	},
	{
		garage_id: 240,
		part_id: 31,
		quantity: 273,
	},
	{
		garage_id: 240,
		part_id: 32,
		quantity: 426,
	},
	{
		garage_id: 240,
		part_id: 33,
		quantity: 240,
	},
	{
		garage_id: 240,
		part_id: 34,
		quantity: 90,
	},
	{
		garage_id: 240,
		part_id: 35,
		quantity: 92,
	},
	{
		garage_id: 240,
		part_id: 36,
		quantity: 427,
	},
	{
		garage_id: 240,
		part_id: 37,
		quantity: 61,
	},
	{
		garage_id: 240,
		part_id: 38,
		quantity: 61,
	},
	{
		garage_id: 240,
		part_id: 39,
		quantity: 252,
	},
	{
		garage_id: 240,
		part_id: 40,
		quantity: 142,
	},
	{
		garage_id: 240,
		part_id: 41,
		quantity: 28,
	},
	{
		garage_id: 240,
		part_id: 42,
		quantity: 476,
	},
	{
		garage_id: 240,
		part_id: 43,
		quantity: 301,
	},
	{
		garage_id: 240,
		part_id: 44,
		quantity: 256,
	},
	{
		garage_id: 240,
		part_id: 45,
		quantity: 104,
	},
	{
		garage_id: 240,
		part_id: 46,
		quantity: 20,
	},
	{
		garage_id: 240,
		part_id: 47,
		quantity: 396,
	},
	{
		garage_id: 240,
		part_id: 48,
		quantity: 344,
	},
	{
		garage_id: 240,
		part_id: 49,
		quantity: 250,
	},
	{
		garage_id: 240,
		part_id: 50,
		quantity: 174,
	},
	{
		garage_id: 240,
		part_id: 51,
		quantity: 273,
	},
	{
		garage_id: 240,
		part_id: 52,
		quantity: 497,
	},
	{
		garage_id: 240,
		part_id: 53,
		quantity: 126,
	},
	{
		garage_id: 240,
		part_id: 54,
		quantity: 489,
	},
	{
		garage_id: 240,
		part_id: 55,
		quantity: 117,
	},
	{
		garage_id: 240,
		part_id: 56,
		quantity: 361,
	},
	{
		garage_id: 240,
		part_id: 57,
		quantity: 58,
	},
	{
		garage_id: 240,
		part_id: 58,
		quantity: 256,
	},
	{
		garage_id: 240,
		part_id: 59,
		quantity: 134,
	},
	{
		garage_id: 240,
		part_id: 60,
		quantity: 148,
	},
	{
		garage_id: 240,
		part_id: 61,
		quantity: 425,
	},
	{
		garage_id: 240,
		part_id: 62,
		quantity: 422,
	},
	{
		garage_id: 240,
		part_id: 63,
		quantity: 186,
	},
	{
		garage_id: 240,
		part_id: 64,
		quantity: 221,
	},
	{
		garage_id: 240,
		part_id: 65,
		quantity: 148,
	},
	{
		garage_id: 240,
		part_id: 66,
		quantity: 394,
	},
	{
		garage_id: 240,
		part_id: 67,
		quantity: 218,
	},
	{
		garage_id: 240,
		part_id: 68,
		quantity: 20,
	},
	{
		garage_id: 240,
		part_id: 69,
		quantity: 459,
	},
	{
		garage_id: 240,
		part_id: 70,
		quantity: 165,
	},
	{
		garage_id: 240,
		part_id: 71,
		quantity: 298,
	},
	{
		garage_id: 240,
		part_id: 72,
		quantity: 470,
	},
	{
		garage_id: 240,
		part_id: 73,
		quantity: 9,
	},
	{
		garage_id: 240,
		part_id: 74,
		quantity: 289,
	},
	{
		garage_id: 240,
		part_id: 75,
		quantity: 165,
	},
	{
		garage_id: 240,
		part_id: 76,
		quantity: 69,
	},
	{
		garage_id: 240,
		part_id: 77,
		quantity: 382,
	},
	{
		garage_id: 240,
		part_id: 78,
		quantity: 465,
	},
	{
		garage_id: 240,
		part_id: 79,
		quantity: 362,
	},
	{
		garage_id: 240,
		part_id: 80,
		quantity: 76,
	},
	{
		garage_id: 240,
		part_id: 81,
		quantity: 183,
	},
	{
		garage_id: 240,
		part_id: 82,
		quantity: 401,
	},
	{
		garage_id: 240,
		part_id: 83,
		quantity: 110,
	},
	{
		garage_id: 240,
		part_id: 84,
		quantity: 257,
	},
	{
		garage_id: 240,
		part_id: 85,
		quantity: 332,
	},
	{
		garage_id: 240,
		part_id: 86,
		quantity: 249,
	},
	{
		garage_id: 240,
		part_id: 87,
		quantity: 335,
	},
	{
		garage_id: 240,
		part_id: 88,
		quantity: 22,
	},
	{
		garage_id: 240,
		part_id: 89,
		quantity: 202,
	},
	{
		garage_id: 240,
		part_id: 90,
		quantity: 467,
	},
	{
		garage_id: 240,
		part_id: 91,
		quantity: 414,
	},
	{
		garage_id: 240,
		part_id: 92,
		quantity: 426,
	},
	{
		garage_id: 240,
		part_id: 93,
		quantity: 122,
	},
	{
		garage_id: 240,
		part_id: 94,
		quantity: 327,
	},
	{
		garage_id: 240,
		part_id: 95,
		quantity: 16,
	},
	{
		garage_id: 240,
		part_id: 96,
		quantity: 308,
	},
	{
		garage_id: 240,
		part_id: 97,
		quantity: 450,
	},
	{
		garage_id: 240,
		part_id: 98,
		quantity: 415,
	},
	{
		garage_id: 240,
		part_id: 99,
		quantity: 462,
	},
	{
		garage_id: 240,
		part_id: 100,
		quantity: 111,
	},
	{
		garage_id: 240,
		part_id: 101,
		quantity: 386,
	},
	{
		garage_id: 240,
		part_id: 102,
		quantity: 283,
	},
	{
		garage_id: 240,
		part_id: 103,
		quantity: 15,
	},
	{
		garage_id: 240,
		part_id: 104,
		quantity: 261,
	},
	{
		garage_id: 240,
		part_id: 105,
		quantity: 319,
	},
	{
		garage_id: 240,
		part_id: 106,
		quantity: 161,
	},
	{
		garage_id: 240,
		part_id: 107,
		quantity: 250,
	},
	{
		garage_id: 240,
		part_id: 108,
		quantity: 166,
	},
	{
		garage_id: 240,
		part_id: 109,
		quantity: 355,
	},
	{
		garage_id: 240,
		part_id: 110,
		quantity: 199,
	},
	{
		garage_id: 240,
		part_id: 111,
		quantity: 443,
	},
	{
		garage_id: 240,
		part_id: 112,
		quantity: 246,
	},
	{
		garage_id: 240,
		part_id: 113,
		quantity: 500,
	},
	{
		garage_id: 240,
		part_id: 114,
		quantity: 17,
	},
	{
		garage_id: 240,
		part_id: 115,
		quantity: 87,
	},
	{
		garage_id: 240,
		part_id: 116,
		quantity: 95,
	},
	{
		garage_id: 240,
		part_id: 117,
		quantity: 491,
	},
	{
		garage_id: 240,
		part_id: 118,
		quantity: 343,
	},
	{
		garage_id: 240,
		part_id: 119,
		quantity: 246,
	},
	{
		garage_id: 240,
		part_id: 120,
		quantity: 27,
	},
	{
		garage_id: 240,
		part_id: 121,
		quantity: 412,
	},
	{
		garage_id: 240,
		part_id: 122,
		quantity: 433,
	},
	{
		garage_id: 240,
		part_id: 123,
		quantity: 99,
	},
	{
		garage_id: 240,
		part_id: 124,
		quantity: 407,
	},
	{
		garage_id: 240,
		part_id: 125,
		quantity: 305,
	},
	{
		garage_id: 240,
		part_id: 126,
		quantity: 369,
	},
	{
		garage_id: 240,
		part_id: 127,
		quantity: 156,
	},
	{
		garage_id: 240,
		part_id: 128,
		quantity: 416,
	},
	{
		garage_id: 240,
		part_id: 129,
		quantity: 447,
	},
	{
		garage_id: 240,
		part_id: 130,
		quantity: 183,
	},
	{
		garage_id: 240,
		part_id: 131,
		quantity: 303,
	},
	{
		garage_id: 240,
		part_id: 132,
		quantity: 209,
	},
	{
		garage_id: 240,
		part_id: 133,
		quantity: 134,
	},
	{
		garage_id: 240,
		part_id: 134,
		quantity: 97,
	},
	{
		garage_id: 240,
		part_id: 135,
		quantity: 206,
	},
	{
		garage_id: 240,
		part_id: 136,
		quantity: 334,
	},
	{
		garage_id: 240,
		part_id: 137,
		quantity: 137,
	},
	{
		garage_id: 240,
		part_id: 138,
		quantity: 199,
	},
	{
		garage_id: 240,
		part_id: 139,
		quantity: 468,
	},
	{
		garage_id: 240,
		part_id: 140,
		quantity: 10,
	},
	{
		garage_id: 240,
		part_id: 141,
		quantity: 237,
	},
	{
		garage_id: 240,
		part_id: 142,
		quantity: 348,
	},
	{
		garage_id: 240,
		part_id: 143,
		quantity: 388,
	},
	{
		garage_id: 240,
		part_id: 144,
		quantity: 282,
	},
	{
		garage_id: 240,
		part_id: 145,
		quantity: 303,
	},
	{
		garage_id: 240,
		part_id: 146,
		quantity: 352,
	},
	{
		garage_id: 240,
		part_id: 147,
		quantity: 430,
	},
	{
		garage_id: 240,
		part_id: 148,
		quantity: 409,
	},
	{
		garage_id: 240,
		part_id: 149,
		quantity: 120,
	},
	{
		garage_id: 240,
		part_id: 150,
		quantity: 190,
	},
	{
		garage_id: 240,
		part_id: 151,
		quantity: 490,
	},
	{
		garage_id: 240,
		part_id: 152,
		quantity: 134,
	},
	{
		garage_id: 240,
		part_id: 153,
		quantity: 399,
	},
	{
		garage_id: 240,
		part_id: 154,
		quantity: 92,
	},
	{
		garage_id: 240,
		part_id: 155,
		quantity: 160,
	},
	{
		garage_id: 240,
		part_id: 156,
		quantity: 411,
	},
	{
		garage_id: 240,
		part_id: 157,
		quantity: 280,
	},
	{
		garage_id: 240,
		part_id: 158,
		quantity: 51,
	},
	{
		garage_id: 240,
		part_id: 159,
		quantity: 356,
	},
	{
		garage_id: 240,
		part_id: 160,
		quantity: 66,
	},
	{
		garage_id: 240,
		part_id: 161,
		quantity: 22,
	},
	{
		garage_id: 240,
		part_id: 162,
		quantity: 482,
	},
	{
		garage_id: 240,
		part_id: 163,
		quantity: 266,
	},
	{
		garage_id: 240,
		part_id: 164,
		quantity: 64,
	},
	{
		garage_id: 240,
		part_id: 165,
		quantity: 332,
	},
	{
		garage_id: 240,
		part_id: 166,
		quantity: 49,
	},
	{
		garage_id: 240,
		part_id: 167,
		quantity: 108,
	},
	{
		garage_id: 240,
		part_id: 168,
		quantity: 76,
	},
	{
		garage_id: 240,
		part_id: 169,
		quantity: 307,
	},
	{
		garage_id: 240,
		part_id: 170,
		quantity: 62,
	},
	{
		garage_id: 240,
		part_id: 171,
		quantity: 98,
	},
	{
		garage_id: 240,
		part_id: 172,
		quantity: 231,
	},
	{
		garage_id: 240,
		part_id: 173,
		quantity: 203,
	},
	{
		garage_id: 240,
		part_id: 174,
		quantity: 440,
	},
	{
		garage_id: 240,
		part_id: 175,
		quantity: 0,
	},
	{
		garage_id: 240,
		part_id: 176,
		quantity: 256,
	},
	{
		garage_id: 240,
		part_id: 177,
		quantity: 255,
	},
	{
		garage_id: 240,
		part_id: 178,
		quantity: 26,
	},
	{
		garage_id: 240,
		part_id: 179,
		quantity: 384,
	},
	{
		garage_id: 240,
		part_id: 180,
		quantity: 291,
	},
	{
		garage_id: 240,
		part_id: 181,
		quantity: 262,
	},
	{
		garage_id: 240,
		part_id: 182,
		quantity: 414,
	},
	{
		garage_id: 240,
		part_id: 183,
		quantity: 393,
	},
	{
		garage_id: 240,
		part_id: 184,
		quantity: 445,
	},
	{
		garage_id: 240,
		part_id: 185,
		quantity: 166,
	},
	{
		garage_id: 240,
		part_id: 186,
		quantity: 19,
	},
	{
		garage_id: 240,
		part_id: 187,
		quantity: 59,
	},
	{
		garage_id: 240,
		part_id: 188,
		quantity: 313,
	},
	{
		garage_id: 240,
		part_id: 189,
		quantity: 222,
	},
	{
		garage_id: 240,
		part_id: 190,
		quantity: 410,
	},
	{
		garage_id: 240,
		part_id: 191,
		quantity: 400,
	},
	{
		garage_id: 240,
		part_id: 192,
		quantity: 268,
	},
	{
		garage_id: 240,
		part_id: 193,
		quantity: 208,
	},
	{
		garage_id: 240,
		part_id: 194,
		quantity: 151,
	},
	{
		garage_id: 240,
		part_id: 195,
		quantity: 467,
	},
	{
		garage_id: 240,
		part_id: 196,
		quantity: 122,
	},
	{
		garage_id: 240,
		part_id: 197,
		quantity: 431,
	},
	{
		garage_id: 240,
		part_id: 198,
		quantity: 149,
	},
	{
		garage_id: 240,
		part_id: 199,
		quantity: 361,
	},
	{
		garage_id: 240,
		part_id: 200,
		quantity: 273,
	},
	{
		garage_id: 240,
		part_id: 201,
		quantity: 25,
	},
	{
		garage_id: 240,
		part_id: 202,
		quantity: 485,
	},
	{
		garage_id: 240,
		part_id: 203,
		quantity: 274,
	},
	{
		garage_id: 240,
		part_id: 204,
		quantity: 415,
	},
	{
		garage_id: 240,
		part_id: 205,
		quantity: 400,
	},
	{
		garage_id: 240,
		part_id: 206,
		quantity: 363,
	},
	{
		garage_id: 240,
		part_id: 207,
		quantity: 314,
	},
	{
		garage_id: 240,
		part_id: 208,
		quantity: 350,
	},
	{
		garage_id: 240,
		part_id: 209,
		quantity: 422,
	},
	{
		garage_id: 240,
		part_id: 210,
		quantity: 348,
	},
	{
		garage_id: 240,
		part_id: 211,
		quantity: 10,
	},
	{
		garage_id: 240,
		part_id: 212,
		quantity: 273,
	},
	{
		garage_id: 240,
		part_id: 213,
		quantity: 121,
	},
	{
		garage_id: 240,
		part_id: 214,
		quantity: 474,
	},
	{
		garage_id: 240,
		part_id: 215,
		quantity: 391,
	},
	{
		garage_id: 240,
		part_id: 216,
		quantity: 336,
	},
	{
		garage_id: 240,
		part_id: 217,
		quantity: 394,
	},
	{
		garage_id: 240,
		part_id: 218,
		quantity: 44,
	},
	{
		garage_id: 240,
		part_id: 219,
		quantity: 428,
	},
	{
		garage_id: 240,
		part_id: 220,
		quantity: 11,
	},
	{
		garage_id: 240,
		part_id: 221,
		quantity: 186,
	},
	{
		garage_id: 240,
		part_id: 222,
		quantity: 58,
	},
	{
		garage_id: 240,
		part_id: 223,
		quantity: 471,
	},
	{
		garage_id: 240,
		part_id: 224,
		quantity: 22,
	},
	{
		garage_id: 240,
		part_id: 225,
		quantity: 41,
	},
	{
		garage_id: 240,
		part_id: 226,
		quantity: 162,
	},
	{
		garage_id: 240,
		part_id: 227,
		quantity: 44,
	},
	{
		garage_id: 240,
		part_id: 228,
		quantity: 420,
	},
	{
		garage_id: 240,
		part_id: 229,
		quantity: 328,
	},
	{
		garage_id: 240,
		part_id: 230,
		quantity: 50,
	},
	{
		garage_id: 240,
		part_id: 231,
		quantity: 25,
	},
	{
		garage_id: 240,
		part_id: 232,
		quantity: 201,
	},
	{
		garage_id: 240,
		part_id: 233,
		quantity: 485,
	},
	{
		garage_id: 240,
		part_id: 234,
		quantity: 409,
	},
	{
		garage_id: 240,
		part_id: 235,
		quantity: 409,
	},
	{
		garage_id: 240,
		part_id: 236,
		quantity: 477,
	},
	{
		garage_id: 240,
		part_id: 237,
		quantity: 40,
	},
	{
		garage_id: 240,
		part_id: 238,
		quantity: 387,
	},
	{
		garage_id: 240,
		part_id: 239,
		quantity: 400,
	},
	{
		garage_id: 240,
		part_id: 240,
		quantity: 420,
	},
	{
		garage_id: 240,
		part_id: 241,
		quantity: 6,
	},
	{
		garage_id: 240,
		part_id: 242,
		quantity: 2,
	},
	{
		garage_id: 240,
		part_id: 243,
		quantity: 266,
	},
	{
		garage_id: 240,
		part_id: 244,
		quantity: 61,
	},
	{
		garage_id: 240,
		part_id: 245,
		quantity: 299,
	},
	{
		garage_id: 240,
		part_id: 246,
		quantity: 248,
	},
	{
		garage_id: 240,
		part_id: 247,
		quantity: 209,
	},
	{
		garage_id: 240,
		part_id: 248,
		quantity: 214,
	},
	{
		garage_id: 240,
		part_id: 249,
		quantity: 470,
	},
	{
		garage_id: 240,
		part_id: 250,
		quantity: 25,
	},
	{
		garage_id: 240,
		part_id: 251,
		quantity: 144,
	},
	{
		garage_id: 240,
		part_id: 252,
		quantity: 299,
	},
	{
		garage_id: 240,
		part_id: 253,
		quantity: 57,
	},
	{
		garage_id: 240,
		part_id: 254,
		quantity: 428,
	},
	{
		garage_id: 240,
		part_id: 255,
		quantity: 393,
	},
	{
		garage_id: 240,
		part_id: 256,
		quantity: 382,
	},
	{
		garage_id: 240,
		part_id: 257,
		quantity: 296,
	},
	{
		garage_id: 240,
		part_id: 258,
		quantity: 60,
	},
	{
		garage_id: 240,
		part_id: 259,
		quantity: 109,
	},
	{
		garage_id: 240,
		part_id: 260,
		quantity: 70,
	},
	{
		garage_id: 240,
		part_id: 261,
		quantity: 337,
	},
	{
		garage_id: 240,
		part_id: 262,
		quantity: 38,
	},
	{
		garage_id: 240,
		part_id: 263,
		quantity: 161,
	},
	{
		garage_id: 240,
		part_id: 264,
		quantity: 383,
	},
	{
		garage_id: 240,
		part_id: 265,
		quantity: 328,
	},
	{
		garage_id: 240,
		part_id: 266,
		quantity: 367,
	},
	{
		garage_id: 240,
		part_id: 267,
		quantity: 369,
	},
	{
		garage_id: 240,
		part_id: 268,
		quantity: 447,
	},
	{
		garage_id: 240,
		part_id: 269,
		quantity: 148,
	},
	{
		garage_id: 240,
		part_id: 270,
		quantity: 329,
	},
	{
		garage_id: 240,
		part_id: 271,
		quantity: 366,
	},
	{
		garage_id: 240,
		part_id: 272,
		quantity: 435,
	},
	{
		garage_id: 240,
		part_id: 273,
		quantity: 476,
	},
	{
		garage_id: 240,
		part_id: 274,
		quantity: 184,
	},
	{
		garage_id: 240,
		part_id: 275,
		quantity: 154,
	},
	{
		garage_id: 240,
		part_id: 276,
		quantity: 483,
	},
	{
		garage_id: 240,
		part_id: 277,
		quantity: 121,
	},
	{
		garage_id: 240,
		part_id: 278,
		quantity: 124,
	},
	{
		garage_id: 240,
		part_id: 279,
		quantity: 165,
	},
	{
		garage_id: 240,
		part_id: 280,
		quantity: 177,
	},
	{
		garage_id: 240,
		part_id: 281,
		quantity: 461,
	},
	{
		garage_id: 240,
		part_id: 282,
		quantity: 232,
	},
	{
		garage_id: 240,
		part_id: 283,
		quantity: 98,
	},
	{
		garage_id: 240,
		part_id: 284,
		quantity: 40,
	},
	{
		garage_id: 240,
		part_id: 285,
		quantity: 80,
	},
	{
		garage_id: 240,
		part_id: 286,
		quantity: 406,
	},
	{
		garage_id: 240,
		part_id: 287,
		quantity: 363,
	},
	{
		garage_id: 240,
		part_id: 288,
		quantity: 422,
	},
	{
		garage_id: 240,
		part_id: 289,
		quantity: 68,
	},
	{
		garage_id: 240,
		part_id: 290,
		quantity: 466,
	},
	{
		garage_id: 240,
		part_id: 291,
		quantity: 372,
	},
	{
		garage_id: 240,
		part_id: 292,
		quantity: 36,
	},
	{
		garage_id: 240,
		part_id: 293,
		quantity: 139,
	},
	{
		garage_id: 240,
		part_id: 294,
		quantity: 339,
	},
	{
		garage_id: 240,
		part_id: 295,
		quantity: 402,
	},
	{
		garage_id: 240,
		part_id: 296,
		quantity: 85,
	},
	{
		garage_id: 240,
		part_id: 297,
		quantity: 91,
	},
	{
		garage_id: 240,
		part_id: 298,
		quantity: 417,
	},
	{
		garage_id: 240,
		part_id: 299,
		quantity: 68,
	},
	{
		garage_id: 240,
		part_id: 300,
		quantity: 403,
	},
	{
		garage_id: 240,
		part_id: 301,
		quantity: 183,
	},
	{
		garage_id: 240,
		part_id: 302,
		quantity: 460,
	},
	{
		garage_id: 240,
		part_id: 303,
		quantity: 484,
	},
	{
		garage_id: 240,
		part_id: 304,
		quantity: 346,
	},
	{
		garage_id: 240,
		part_id: 305,
		quantity: 42,
	},
	{
		garage_id: 240,
		part_id: 306,
		quantity: 326,
	},
	{
		garage_id: 240,
		part_id: 307,
		quantity: 159,
	},
	{
		garage_id: 240,
		part_id: 308,
		quantity: 376,
	},
	{
		garage_id: 240,
		part_id: 309,
		quantity: 13,
	},
	{
		garage_id: 240,
		part_id: 310,
		quantity: 273,
	},
	{
		garage_id: 240,
		part_id: 311,
		quantity: 259,
	},
	{
		garage_id: 240,
		part_id: 312,
		quantity: 438,
	},
	{
		garage_id: 240,
		part_id: 313,
		quantity: 498,
	},
	{
		garage_id: 240,
		part_id: 314,
		quantity: 60,
	},
	{
		garage_id: 240,
		part_id: 315,
		quantity: 276,
	},
	{
		garage_id: 240,
		part_id: 316,
		quantity: 338,
	},
	{
		garage_id: 240,
		part_id: 317,
		quantity: 355,
	},
	{
		garage_id: 240,
		part_id: 318,
		quantity: 14,
	},
	{
		garage_id: 240,
		part_id: 319,
		quantity: 428,
	},
	{
		garage_id: 240,
		part_id: 320,
		quantity: 72,
	},
	{
		garage_id: 240,
		part_id: 321,
		quantity: 306,
	},
	{
		garage_id: 240,
		part_id: 322,
		quantity: 255,
	},
	{
		garage_id: 240,
		part_id: 323,
		quantity: 396,
	},
	{
		garage_id: 240,
		part_id: 324,
		quantity: 278,
	},
	{
		garage_id: 240,
		part_id: 325,
		quantity: 497,
	},
	{
		garage_id: 240,
		part_id: 326,
		quantity: 179,
	},
	{
		garage_id: 240,
		part_id: 327,
		quantity: 239,
	},
	{
		garage_id: 240,
		part_id: 328,
		quantity: 145,
	},
	{
		garage_id: 240,
		part_id: 329,
		quantity: 493,
	},
	{
		garage_id: 240,
		part_id: 330,
		quantity: 434,
	},
	{
		garage_id: 240,
		part_id: 331,
		quantity: 328,
	},
	{
		garage_id: 240,
		part_id: 332,
		quantity: 435,
	},
	{
		garage_id: 240,
		part_id: 333,
		quantity: 81,
	},
	{
		garage_id: 240,
		part_id: 334,
		quantity: 396,
	},
	{
		garage_id: 240,
		part_id: 335,
		quantity: 46,
	},
	{
		garage_id: 240,
		part_id: 336,
		quantity: 185,
	},
	{
		garage_id: 240,
		part_id: 337,
		quantity: 413,
	},
	{
		garage_id: 240,
		part_id: 338,
		quantity: 310,
	},
	{
		garage_id: 240,
		part_id: 339,
		quantity: 68,
	},
	{
		garage_id: 240,
		part_id: 340,
		quantity: 399,
	},
	{
		garage_id: 240,
		part_id: 341,
		quantity: 397,
	},
	{
		garage_id: 240,
		part_id: 342,
		quantity: 6,
	},
	{
		garage_id: 240,
		part_id: 343,
		quantity: 479,
	},
	{
		garage_id: 240,
		part_id: 344,
		quantity: 52,
	},
	{
		garage_id: 240,
		part_id: 345,
		quantity: 236,
	},
	{
		garage_id: 240,
		part_id: 346,
		quantity: 170,
	},
	{
		garage_id: 240,
		part_id: 347,
		quantity: 219,
	},
	{
		garage_id: 240,
		part_id: 348,
		quantity: 156,
	},
	{
		garage_id: 240,
		part_id: 349,
		quantity: 470,
	},
	{
		garage_id: 240,
		part_id: 350,
		quantity: 349,
	},
	{
		garage_id: 240,
		part_id: 351,
		quantity: 248,
	},
	{
		garage_id: 240,
		part_id: 352,
		quantity: 204,
	},
	{
		garage_id: 240,
		part_id: 353,
		quantity: 149,
	},
	{
		garage_id: 240,
		part_id: 354,
		quantity: 75,
	},
	{
		garage_id: 240,
		part_id: 355,
		quantity: 161,
	},
	{
		garage_id: 240,
		part_id: 356,
		quantity: 172,
	},
	{
		garage_id: 240,
		part_id: 357,
		quantity: 451,
	},
	{
		garage_id: 240,
		part_id: 358,
		quantity: 21,
	},
	{
		garage_id: 240,
		part_id: 359,
		quantity: 415,
	},
	{
		garage_id: 240,
		part_id: 360,
		quantity: 241,
	},
	{
		garage_id: 240,
		part_id: 361,
		quantity: 46,
	},
	{
		garage_id: 240,
		part_id: 362,
		quantity: 479,
	},
	{
		garage_id: 240,
		part_id: 363,
		quantity: 382,
	},
	{
		garage_id: 240,
		part_id: 364,
		quantity: 410,
	},
	{
		garage_id: 240,
		part_id: 365,
		quantity: 207,
	},
	{
		garage_id: 240,
		part_id: 366,
		quantity: 168,
	},
	{
		garage_id: 240,
		part_id: 367,
		quantity: 21,
	},
	{
		garage_id: 240,
		part_id: 368,
		quantity: 63,
	},
	{
		garage_id: 240,
		part_id: 369,
		quantity: 375,
	},
	{
		garage_id: 240,
		part_id: 370,
		quantity: 7,
	},
	{
		garage_id: 240,
		part_id: 371,
		quantity: 190,
	},
	{
		garage_id: 240,
		part_id: 372,
		quantity: 463,
	},
	{
		garage_id: 240,
		part_id: 373,
		quantity: 406,
	},
	{
		garage_id: 240,
		part_id: 374,
		quantity: 268,
	},
	{
		garage_id: 240,
		part_id: 375,
		quantity: 462,
	},
	{
		garage_id: 240,
		part_id: 376,
		quantity: 479,
	},
	{
		garage_id: 240,
		part_id: 377,
		quantity: 204,
	},
	{
		garage_id: 240,
		part_id: 378,
		quantity: 244,
	},
	{
		garage_id: 240,
		part_id: 379,
		quantity: 25,
	},
	{
		garage_id: 240,
		part_id: 380,
		quantity: 184,
	},
	{
		garage_id: 240,
		part_id: 381,
		quantity: 259,
	},
	{
		garage_id: 240,
		part_id: 382,
		quantity: 9,
	},
	{
		garage_id: 240,
		part_id: 383,
		quantity: 379,
	},
	{
		garage_id: 240,
		part_id: 384,
		quantity: 342,
	},
	{
		garage_id: 240,
		part_id: 385,
		quantity: 291,
	},
	{
		garage_id: 240,
		part_id: 386,
		quantity: 357,
	},
	{
		garage_id: 240,
		part_id: 387,
		quantity: 352,
	},
	{
		garage_id: 240,
		part_id: 388,
		quantity: 136,
	},
	{
		garage_id: 240,
		part_id: 389,
		quantity: 58,
	},
	{
		garage_id: 240,
		part_id: 390,
		quantity: 350,
	},
	{
		garage_id: 240,
		part_id: 391,
		quantity: 454,
	},
	{
		garage_id: 240,
		part_id: 392,
		quantity: 285,
	},
	{
		garage_id: 240,
		part_id: 393,
		quantity: 166,
	},
	{
		garage_id: 240,
		part_id: 394,
		quantity: 208,
	},
	{
		garage_id: 240,
		part_id: 395,
		quantity: 36,
	},
	{
		garage_id: 240,
		part_id: 396,
		quantity: 255,
	},
	{
		garage_id: 240,
		part_id: 397,
		quantity: 17,
	},
	{
		garage_id: 240,
		part_id: 398,
		quantity: 41,
	},
	{
		garage_id: 240,
		part_id: 399,
		quantity: 286,
	},
	{
		garage_id: 241,
		part_id: 0,
		quantity: 201,
	},
	{
		garage_id: 241,
		part_id: 1,
		quantity: 193,
	},
	{
		garage_id: 241,
		part_id: 2,
		quantity: 190,
	},
	{
		garage_id: 241,
		part_id: 3,
		quantity: 3,
	},
	{
		garage_id: 241,
		part_id: 4,
		quantity: 205,
	},
	{
		garage_id: 241,
		part_id: 5,
		quantity: 240,
	},
	{
		garage_id: 241,
		part_id: 6,
		quantity: 326,
	},
	{
		garage_id: 241,
		part_id: 7,
		quantity: 425,
	},
	{
		garage_id: 241,
		part_id: 8,
		quantity: 372,
	},
	{
		garage_id: 241,
		part_id: 9,
		quantity: 427,
	},
	{
		garage_id: 241,
		part_id: 10,
		quantity: 105,
	},
	{
		garage_id: 241,
		part_id: 11,
		quantity: 221,
	},
	{
		garage_id: 241,
		part_id: 12,
		quantity: 497,
	},
	{
		garage_id: 241,
		part_id: 13,
		quantity: 289,
	},
	{
		garage_id: 241,
		part_id: 14,
		quantity: 13,
	},
	{
		garage_id: 241,
		part_id: 15,
		quantity: 54,
	},
	{
		garage_id: 241,
		part_id: 16,
		quantity: 133,
	},
	{
		garage_id: 241,
		part_id: 17,
		quantity: 162,
	},
	{
		garage_id: 241,
		part_id: 18,
		quantity: 484,
	},
	{
		garage_id: 241,
		part_id: 19,
		quantity: 336,
	},
	{
		garage_id: 241,
		part_id: 20,
		quantity: 412,
	},
	{
		garage_id: 241,
		part_id: 21,
		quantity: 122,
	},
	{
		garage_id: 241,
		part_id: 22,
		quantity: 177,
	},
	{
		garage_id: 241,
		part_id: 23,
		quantity: 449,
	},
	{
		garage_id: 241,
		part_id: 24,
		quantity: 443,
	},
	{
		garage_id: 241,
		part_id: 25,
		quantity: 303,
	},
	{
		garage_id: 241,
		part_id: 26,
		quantity: 61,
	},
	{
		garage_id: 241,
		part_id: 27,
		quantity: 199,
	},
	{
		garage_id: 241,
		part_id: 28,
		quantity: 149,
	},
	{
		garage_id: 241,
		part_id: 29,
		quantity: 120,
	},
	{
		garage_id: 241,
		part_id: 30,
		quantity: 342,
	},
	{
		garage_id: 241,
		part_id: 31,
		quantity: 85,
	},
	{
		garage_id: 241,
		part_id: 32,
		quantity: 51,
	},
	{
		garage_id: 241,
		part_id: 33,
		quantity: 374,
	},
	{
		garage_id: 241,
		part_id: 34,
		quantity: 41,
	},
	{
		garage_id: 241,
		part_id: 35,
		quantity: 292,
	},
	{
		garage_id: 241,
		part_id: 36,
		quantity: 274,
	},
	{
		garage_id: 241,
		part_id: 37,
		quantity: 323,
	},
	{
		garage_id: 241,
		part_id: 38,
		quantity: 322,
	},
	{
		garage_id: 241,
		part_id: 39,
		quantity: 196,
	},
	{
		garage_id: 241,
		part_id: 40,
		quantity: 120,
	},
	{
		garage_id: 241,
		part_id: 41,
		quantity: 27,
	},
	{
		garage_id: 241,
		part_id: 42,
		quantity: 280,
	},
	{
		garage_id: 241,
		part_id: 43,
		quantity: 391,
	},
	{
		garage_id: 241,
		part_id: 44,
		quantity: 169,
	},
	{
		garage_id: 241,
		part_id: 45,
		quantity: 381,
	},
	{
		garage_id: 241,
		part_id: 46,
		quantity: 195,
	},
	{
		garage_id: 241,
		part_id: 47,
		quantity: 486,
	},
	{
		garage_id: 241,
		part_id: 48,
		quantity: 75,
	},
	{
		garage_id: 241,
		part_id: 49,
		quantity: 318,
	},
	{
		garage_id: 241,
		part_id: 50,
		quantity: 93,
	},
	{
		garage_id: 241,
		part_id: 51,
		quantity: 153,
	},
	{
		garage_id: 241,
		part_id: 52,
		quantity: 85,
	},
	{
		garage_id: 241,
		part_id: 53,
		quantity: 125,
	},
	{
		garage_id: 241,
		part_id: 54,
		quantity: 217,
	},
	{
		garage_id: 241,
		part_id: 55,
		quantity: 286,
	},
	{
		garage_id: 241,
		part_id: 56,
		quantity: 275,
	},
	{
		garage_id: 241,
		part_id: 57,
		quantity: 73,
	},
	{
		garage_id: 241,
		part_id: 58,
		quantity: 414,
	},
	{
		garage_id: 241,
		part_id: 59,
		quantity: 428,
	},
	{
		garage_id: 241,
		part_id: 60,
		quantity: 203,
	},
	{
		garage_id: 241,
		part_id: 61,
		quantity: 57,
	},
	{
		garage_id: 241,
		part_id: 62,
		quantity: 491,
	},
	{
		garage_id: 241,
		part_id: 63,
		quantity: 169,
	},
	{
		garage_id: 241,
		part_id: 64,
		quantity: 79,
	},
	{
		garage_id: 241,
		part_id: 65,
		quantity: 116,
	},
	{
		garage_id: 241,
		part_id: 66,
		quantity: 367,
	},
	{
		garage_id: 241,
		part_id: 67,
		quantity: 478,
	},
	{
		garage_id: 241,
		part_id: 68,
		quantity: 391,
	},
	{
		garage_id: 241,
		part_id: 69,
		quantity: 121,
	},
	{
		garage_id: 241,
		part_id: 70,
		quantity: 148,
	},
	{
		garage_id: 241,
		part_id: 71,
		quantity: 430,
	},
	{
		garage_id: 241,
		part_id: 72,
		quantity: 119,
	},
	{
		garage_id: 241,
		part_id: 73,
		quantity: 382,
	},
	{
		garage_id: 241,
		part_id: 74,
		quantity: 31,
	},
	{
		garage_id: 241,
		part_id: 75,
		quantity: 56,
	},
	{
		garage_id: 241,
		part_id: 76,
		quantity: 391,
	},
	{
		garage_id: 241,
		part_id: 77,
		quantity: 82,
	},
	{
		garage_id: 241,
		part_id: 78,
		quantity: 469,
	},
	{
		garage_id: 241,
		part_id: 79,
		quantity: 6,
	},
	{
		garage_id: 241,
		part_id: 80,
		quantity: 460,
	},
	{
		garage_id: 241,
		part_id: 81,
		quantity: 33,
	},
	{
		garage_id: 241,
		part_id: 82,
		quantity: 72,
	},
	{
		garage_id: 241,
		part_id: 83,
		quantity: 283,
	},
	{
		garage_id: 241,
		part_id: 84,
		quantity: 433,
	},
	{
		garage_id: 241,
		part_id: 85,
		quantity: 329,
	},
	{
		garage_id: 241,
		part_id: 86,
		quantity: 130,
	},
	{
		garage_id: 241,
		part_id: 87,
		quantity: 401,
	},
	{
		garage_id: 241,
		part_id: 88,
		quantity: 171,
	},
	{
		garage_id: 241,
		part_id: 89,
		quantity: 2,
	},
	{
		garage_id: 241,
		part_id: 90,
		quantity: 256,
	},
	{
		garage_id: 241,
		part_id: 91,
		quantity: 48,
	},
	{
		garage_id: 241,
		part_id: 92,
		quantity: 224,
	},
	{
		garage_id: 241,
		part_id: 93,
		quantity: 251,
	},
	{
		garage_id: 241,
		part_id: 94,
		quantity: 94,
	},
	{
		garage_id: 241,
		part_id: 95,
		quantity: 280,
	},
	{
		garage_id: 241,
		part_id: 96,
		quantity: 338,
	},
	{
		garage_id: 241,
		part_id: 97,
		quantity: 177,
	},
	{
		garage_id: 241,
		part_id: 98,
		quantity: 291,
	},
	{
		garage_id: 241,
		part_id: 99,
		quantity: 67,
	},
	{
		garage_id: 241,
		part_id: 100,
		quantity: 26,
	},
	{
		garage_id: 241,
		part_id: 101,
		quantity: 340,
	},
	{
		garage_id: 241,
		part_id: 102,
		quantity: 424,
	},
	{
		garage_id: 241,
		part_id: 103,
		quantity: 466,
	},
	{
		garage_id: 241,
		part_id: 104,
		quantity: 310,
	},
	{
		garage_id: 241,
		part_id: 105,
		quantity: 74,
	},
	{
		garage_id: 241,
		part_id: 106,
		quantity: 484,
	},
	{
		garage_id: 241,
		part_id: 107,
		quantity: 165,
	},
	{
		garage_id: 241,
		part_id: 108,
		quantity: 65,
	},
	{
		garage_id: 241,
		part_id: 109,
		quantity: 423,
	},
	{
		garage_id: 241,
		part_id: 110,
		quantity: 322,
	},
	{
		garage_id: 241,
		part_id: 111,
		quantity: 244,
	},
	{
		garage_id: 241,
		part_id: 112,
		quantity: 369,
	},
	{
		garage_id: 241,
		part_id: 113,
		quantity: 130,
	},
	{
		garage_id: 241,
		part_id: 114,
		quantity: 77,
	},
	{
		garage_id: 241,
		part_id: 115,
		quantity: 145,
	},
	{
		garage_id: 241,
		part_id: 116,
		quantity: 166,
	},
	{
		garage_id: 241,
		part_id: 117,
		quantity: 445,
	},
	{
		garage_id: 241,
		part_id: 118,
		quantity: 117,
	},
	{
		garage_id: 241,
		part_id: 119,
		quantity: 108,
	},
	{
		garage_id: 241,
		part_id: 120,
		quantity: 219,
	},
	{
		garage_id: 241,
		part_id: 121,
		quantity: 77,
	},
	{
		garage_id: 241,
		part_id: 122,
		quantity: 271,
	},
	{
		garage_id: 241,
		part_id: 123,
		quantity: 378,
	},
	{
		garage_id: 241,
		part_id: 124,
		quantity: 4,
	},
	{
		garage_id: 241,
		part_id: 125,
		quantity: 368,
	},
	{
		garage_id: 241,
		part_id: 126,
		quantity: 397,
	},
	{
		garage_id: 241,
		part_id: 127,
		quantity: 33,
	},
	{
		garage_id: 241,
		part_id: 128,
		quantity: 60,
	},
	{
		garage_id: 241,
		part_id: 129,
		quantity: 176,
	},
	{
		garage_id: 241,
		part_id: 130,
		quantity: 389,
	},
	{
		garage_id: 241,
		part_id: 131,
		quantity: 466,
	},
	{
		garage_id: 241,
		part_id: 132,
		quantity: 153,
	},
	{
		garage_id: 241,
		part_id: 133,
		quantity: 482,
	},
	{
		garage_id: 241,
		part_id: 134,
		quantity: 192,
	},
	{
		garage_id: 241,
		part_id: 135,
		quantity: 255,
	},
	{
		garage_id: 241,
		part_id: 136,
		quantity: 255,
	},
	{
		garage_id: 241,
		part_id: 137,
		quantity: 157,
	},
	{
		garage_id: 241,
		part_id: 138,
		quantity: 390,
	},
	{
		garage_id: 241,
		part_id: 139,
		quantity: 109,
	},
	{
		garage_id: 241,
		part_id: 140,
		quantity: 113,
	},
	{
		garage_id: 241,
		part_id: 141,
		quantity: 433,
	},
	{
		garage_id: 241,
		part_id: 142,
		quantity: 239,
	},
	{
		garage_id: 241,
		part_id: 143,
		quantity: 229,
	},
	{
		garage_id: 241,
		part_id: 144,
		quantity: 189,
	},
	{
		garage_id: 241,
		part_id: 145,
		quantity: 192,
	},
	{
		garage_id: 241,
		part_id: 146,
		quantity: 370,
	},
	{
		garage_id: 241,
		part_id: 147,
		quantity: 409,
	},
	{
		garage_id: 241,
		part_id: 148,
		quantity: 59,
	},
	{
		garage_id: 241,
		part_id: 149,
		quantity: 158,
	},
	{
		garage_id: 241,
		part_id: 150,
		quantity: 292,
	},
	{
		garage_id: 241,
		part_id: 151,
		quantity: 86,
	},
	{
		garage_id: 241,
		part_id: 152,
		quantity: 93,
	},
	{
		garage_id: 241,
		part_id: 153,
		quantity: 368,
	},
	{
		garage_id: 241,
		part_id: 154,
		quantity: 28,
	},
	{
		garage_id: 241,
		part_id: 155,
		quantity: 383,
	},
	{
		garage_id: 241,
		part_id: 156,
		quantity: 37,
	},
	{
		garage_id: 241,
		part_id: 157,
		quantity: 480,
	},
	{
		garage_id: 241,
		part_id: 158,
		quantity: 275,
	},
	{
		garage_id: 241,
		part_id: 159,
		quantity: 343,
	},
	{
		garage_id: 241,
		part_id: 160,
		quantity: 74,
	},
	{
		garage_id: 241,
		part_id: 161,
		quantity: 322,
	},
	{
		garage_id: 241,
		part_id: 162,
		quantity: 316,
	},
	{
		garage_id: 241,
		part_id: 163,
		quantity: 12,
	},
	{
		garage_id: 241,
		part_id: 164,
		quantity: 417,
	},
	{
		garage_id: 241,
		part_id: 165,
		quantity: 27,
	},
	{
		garage_id: 241,
		part_id: 166,
		quantity: 147,
	},
	{
		garage_id: 241,
		part_id: 167,
		quantity: 221,
	},
	{
		garage_id: 241,
		part_id: 168,
		quantity: 277,
	},
	{
		garage_id: 241,
		part_id: 169,
		quantity: 326,
	},
	{
		garage_id: 241,
		part_id: 170,
		quantity: 77,
	},
	{
		garage_id: 241,
		part_id: 171,
		quantity: 317,
	},
	{
		garage_id: 241,
		part_id: 172,
		quantity: 26,
	},
	{
		garage_id: 241,
		part_id: 173,
		quantity: 291,
	},
	{
		garage_id: 241,
		part_id: 174,
		quantity: 377,
	},
	{
		garage_id: 241,
		part_id: 175,
		quantity: 475,
	},
	{
		garage_id: 241,
		part_id: 176,
		quantity: 43,
	},
	{
		garage_id: 241,
		part_id: 177,
		quantity: 419,
	},
	{
		garage_id: 241,
		part_id: 178,
		quantity: 361,
	},
	{
		garage_id: 241,
		part_id: 179,
		quantity: 384,
	},
	{
		garage_id: 241,
		part_id: 180,
		quantity: 439,
	},
	{
		garage_id: 241,
		part_id: 181,
		quantity: 220,
	},
	{
		garage_id: 241,
		part_id: 182,
		quantity: 21,
	},
	{
		garage_id: 241,
		part_id: 183,
		quantity: 239,
	},
	{
		garage_id: 241,
		part_id: 184,
		quantity: 101,
	},
	{
		garage_id: 241,
		part_id: 185,
		quantity: 455,
	},
	{
		garage_id: 241,
		part_id: 186,
		quantity: 308,
	},
	{
		garage_id: 241,
		part_id: 187,
		quantity: 227,
	},
	{
		garage_id: 241,
		part_id: 188,
		quantity: 135,
	},
	{
		garage_id: 241,
		part_id: 189,
		quantity: 262,
	},
	{
		garage_id: 241,
		part_id: 190,
		quantity: 17,
	},
	{
		garage_id: 241,
		part_id: 191,
		quantity: 224,
	},
	{
		garage_id: 241,
		part_id: 192,
		quantity: 424,
	},
	{
		garage_id: 241,
		part_id: 193,
		quantity: 194,
	},
	{
		garage_id: 241,
		part_id: 194,
		quantity: 73,
	},
	{
		garage_id: 241,
		part_id: 195,
		quantity: 15,
	},
	{
		garage_id: 241,
		part_id: 196,
		quantity: 301,
	},
	{
		garage_id: 241,
		part_id: 197,
		quantity: 76,
	},
	{
		garage_id: 241,
		part_id: 198,
		quantity: 287,
	},
	{
		garage_id: 241,
		part_id: 199,
		quantity: 400,
	},
	{
		garage_id: 241,
		part_id: 200,
		quantity: 260,
	},
	{
		garage_id: 241,
		part_id: 201,
		quantity: 410,
	},
	{
		garage_id: 241,
		part_id: 202,
		quantity: 88,
	},
	{
		garage_id: 241,
		part_id: 203,
		quantity: 323,
	},
	{
		garage_id: 241,
		part_id: 204,
		quantity: 133,
	},
	{
		garage_id: 241,
		part_id: 205,
		quantity: 469,
	},
	{
		garage_id: 241,
		part_id: 206,
		quantity: 498,
	},
	{
		garage_id: 241,
		part_id: 207,
		quantity: 14,
	},
	{
		garage_id: 241,
		part_id: 208,
		quantity: 110,
	},
	{
		garage_id: 241,
		part_id: 209,
		quantity: 30,
	},
	{
		garage_id: 241,
		part_id: 210,
		quantity: 233,
	},
	{
		garage_id: 241,
		part_id: 211,
		quantity: 121,
	},
	{
		garage_id: 241,
		part_id: 212,
		quantity: 123,
	},
	{
		garage_id: 241,
		part_id: 213,
		quantity: 256,
	},
	{
		garage_id: 241,
		part_id: 214,
		quantity: 259,
	},
	{
		garage_id: 241,
		part_id: 215,
		quantity: 415,
	},
	{
		garage_id: 241,
		part_id: 216,
		quantity: 252,
	},
	{
		garage_id: 241,
		part_id: 217,
		quantity: 480,
	},
	{
		garage_id: 241,
		part_id: 218,
		quantity: 474,
	},
	{
		garage_id: 241,
		part_id: 219,
		quantity: 122,
	},
	{
		garage_id: 241,
		part_id: 220,
		quantity: 35,
	},
	{
		garage_id: 241,
		part_id: 221,
		quantity: 482,
	},
	{
		garage_id: 241,
		part_id: 222,
		quantity: 209,
	},
	{
		garage_id: 241,
		part_id: 223,
		quantity: 247,
	},
	{
		garage_id: 241,
		part_id: 224,
		quantity: 247,
	},
	{
		garage_id: 241,
		part_id: 225,
		quantity: 414,
	},
	{
		garage_id: 241,
		part_id: 226,
		quantity: 219,
	},
	{
		garage_id: 241,
		part_id: 227,
		quantity: 82,
	},
	{
		garage_id: 241,
		part_id: 228,
		quantity: 57,
	},
	{
		garage_id: 241,
		part_id: 229,
		quantity: 498,
	},
	{
		garage_id: 241,
		part_id: 230,
		quantity: 403,
	},
	{
		garage_id: 241,
		part_id: 231,
		quantity: 451,
	},
	{
		garage_id: 241,
		part_id: 232,
		quantity: 16,
	},
	{
		garage_id: 241,
		part_id: 233,
		quantity: 373,
	},
	{
		garage_id: 241,
		part_id: 234,
		quantity: 186,
	},
	{
		garage_id: 241,
		part_id: 235,
		quantity: 270,
	},
	{
		garage_id: 241,
		part_id: 236,
		quantity: 48,
	},
	{
		garage_id: 241,
		part_id: 237,
		quantity: 206,
	},
	{
		garage_id: 241,
		part_id: 238,
		quantity: 350,
	},
	{
		garage_id: 241,
		part_id: 239,
		quantity: 286,
	},
	{
		garage_id: 241,
		part_id: 240,
		quantity: 224,
	},
	{
		garage_id: 241,
		part_id: 241,
		quantity: 29,
	},
	{
		garage_id: 241,
		part_id: 242,
		quantity: 247,
	},
	{
		garage_id: 241,
		part_id: 243,
		quantity: 399,
	},
	{
		garage_id: 241,
		part_id: 244,
		quantity: 454,
	},
	{
		garage_id: 241,
		part_id: 245,
		quantity: 17,
	},
	{
		garage_id: 241,
		part_id: 246,
		quantity: 248,
	},
	{
		garage_id: 241,
		part_id: 247,
		quantity: 66,
	},
	{
		garage_id: 241,
		part_id: 248,
		quantity: 333,
	},
	{
		garage_id: 241,
		part_id: 249,
		quantity: 60,
	},
	{
		garage_id: 241,
		part_id: 250,
		quantity: 443,
	},
	{
		garage_id: 241,
		part_id: 251,
		quantity: 372,
	},
	{
		garage_id: 241,
		part_id: 252,
		quantity: 389,
	},
	{
		garage_id: 241,
		part_id: 253,
		quantity: 217,
	},
	{
		garage_id: 241,
		part_id: 254,
		quantity: 457,
	},
	{
		garage_id: 241,
		part_id: 255,
		quantity: 469,
	},
	{
		garage_id: 241,
		part_id: 256,
		quantity: 462,
	},
	{
		garage_id: 241,
		part_id: 257,
		quantity: 125,
	},
	{
		garage_id: 241,
		part_id: 258,
		quantity: 169,
	},
	{
		garage_id: 241,
		part_id: 259,
		quantity: 78,
	},
	{
		garage_id: 241,
		part_id: 260,
		quantity: 358,
	},
	{
		garage_id: 241,
		part_id: 261,
		quantity: 411,
	},
	{
		garage_id: 241,
		part_id: 262,
		quantity: 4,
	},
	{
		garage_id: 241,
		part_id: 263,
		quantity: 385,
	},
	{
		garage_id: 241,
		part_id: 264,
		quantity: 204,
	},
	{
		garage_id: 241,
		part_id: 265,
		quantity: 257,
	},
	{
		garage_id: 241,
		part_id: 266,
		quantity: 349,
	},
	{
		garage_id: 241,
		part_id: 267,
		quantity: 145,
	},
	{
		garage_id: 241,
		part_id: 268,
		quantity: 285,
	},
	{
		garage_id: 241,
		part_id: 269,
		quantity: 246,
	},
	{
		garage_id: 241,
		part_id: 270,
		quantity: 233,
	},
	{
		garage_id: 241,
		part_id: 271,
		quantity: 368,
	},
	{
		garage_id: 241,
		part_id: 272,
		quantity: 270,
	},
	{
		garage_id: 241,
		part_id: 273,
		quantity: 222,
	},
	{
		garage_id: 241,
		part_id: 274,
		quantity: 467,
	},
	{
		garage_id: 241,
		part_id: 275,
		quantity: 367,
	},
	{
		garage_id: 241,
		part_id: 276,
		quantity: 435,
	},
	{
		garage_id: 241,
		part_id: 277,
		quantity: 101,
	},
	{
		garage_id: 241,
		part_id: 278,
		quantity: 462,
	},
	{
		garage_id: 241,
		part_id: 279,
		quantity: 156,
	},
	{
		garage_id: 241,
		part_id: 280,
		quantity: 0,
	},
	{
		garage_id: 241,
		part_id: 281,
		quantity: 343,
	},
	{
		garage_id: 241,
		part_id: 282,
		quantity: 41,
	},
	{
		garage_id: 241,
		part_id: 283,
		quantity: 269,
	},
	{
		garage_id: 241,
		part_id: 284,
		quantity: 354,
	},
	{
		garage_id: 241,
		part_id: 285,
		quantity: 282,
	},
	{
		garage_id: 241,
		part_id: 286,
		quantity: 343,
	},
	{
		garage_id: 241,
		part_id: 287,
		quantity: 345,
	},
	{
		garage_id: 241,
		part_id: 288,
		quantity: 385,
	},
	{
		garage_id: 241,
		part_id: 289,
		quantity: 130,
	},
	{
		garage_id: 241,
		part_id: 290,
		quantity: 291,
	},
	{
		garage_id: 241,
		part_id: 291,
		quantity: 88,
	},
	{
		garage_id: 241,
		part_id: 292,
		quantity: 356,
	},
	{
		garage_id: 241,
		part_id: 293,
		quantity: 33,
	},
	{
		garage_id: 241,
		part_id: 294,
		quantity: 74,
	},
	{
		garage_id: 241,
		part_id: 295,
		quantity: 247,
	},
	{
		garage_id: 241,
		part_id: 296,
		quantity: 404,
	},
	{
		garage_id: 241,
		part_id: 297,
		quantity: 172,
	},
	{
		garage_id: 241,
		part_id: 298,
		quantity: 428,
	},
	{
		garage_id: 241,
		part_id: 299,
		quantity: 115,
	},
	{
		garage_id: 241,
		part_id: 300,
		quantity: 80,
	},
	{
		garage_id: 241,
		part_id: 301,
		quantity: 96,
	},
	{
		garage_id: 241,
		part_id: 302,
		quantity: 460,
	},
	{
		garage_id: 241,
		part_id: 303,
		quantity: 108,
	},
	{
		garage_id: 241,
		part_id: 304,
		quantity: 308,
	},
	{
		garage_id: 241,
		part_id: 305,
		quantity: 275,
	},
	{
		garage_id: 241,
		part_id: 306,
		quantity: 160,
	},
	{
		garage_id: 241,
		part_id: 307,
		quantity: 142,
	},
	{
		garage_id: 241,
		part_id: 308,
		quantity: 356,
	},
	{
		garage_id: 241,
		part_id: 309,
		quantity: 419,
	},
	{
		garage_id: 241,
		part_id: 310,
		quantity: 100,
	},
	{
		garage_id: 241,
		part_id: 311,
		quantity: 224,
	},
	{
		garage_id: 241,
		part_id: 312,
		quantity: 210,
	},
	{
		garage_id: 241,
		part_id: 313,
		quantity: 364,
	},
	{
		garage_id: 241,
		part_id: 314,
		quantity: 376,
	},
	{
		garage_id: 241,
		part_id: 315,
		quantity: 12,
	},
	{
		garage_id: 241,
		part_id: 316,
		quantity: 429,
	},
	{
		garage_id: 241,
		part_id: 317,
		quantity: 470,
	},
	{
		garage_id: 241,
		part_id: 318,
		quantity: 391,
	},
	{
		garage_id: 241,
		part_id: 319,
		quantity: 47,
	},
	{
		garage_id: 241,
		part_id: 320,
		quantity: 435,
	},
	{
		garage_id: 241,
		part_id: 321,
		quantity: 50,
	},
	{
		garage_id: 241,
		part_id: 322,
		quantity: 150,
	},
	{
		garage_id: 241,
		part_id: 323,
		quantity: 470,
	},
	{
		garage_id: 241,
		part_id: 324,
		quantity: 7,
	},
	{
		garage_id: 241,
		part_id: 325,
		quantity: 399,
	},
	{
		garage_id: 241,
		part_id: 326,
		quantity: 307,
	},
	{
		garage_id: 241,
		part_id: 327,
		quantity: 255,
	},
	{
		garage_id: 241,
		part_id: 328,
		quantity: 55,
	},
	{
		garage_id: 241,
		part_id: 329,
		quantity: 64,
	},
	{
		garage_id: 241,
		part_id: 330,
		quantity: 249,
	},
	{
		garage_id: 241,
		part_id: 331,
		quantity: 54,
	},
	{
		garage_id: 241,
		part_id: 332,
		quantity: 21,
	},
	{
		garage_id: 241,
		part_id: 333,
		quantity: 260,
	},
	{
		garage_id: 241,
		part_id: 334,
		quantity: 356,
	},
	{
		garage_id: 241,
		part_id: 335,
		quantity: 360,
	},
	{
		garage_id: 241,
		part_id: 336,
		quantity: 96,
	},
	{
		garage_id: 241,
		part_id: 337,
		quantity: 442,
	},
	{
		garage_id: 241,
		part_id: 338,
		quantity: 29,
	},
	{
		garage_id: 241,
		part_id: 339,
		quantity: 482,
	},
	{
		garage_id: 241,
		part_id: 340,
		quantity: 95,
	},
	{
		garage_id: 241,
		part_id: 341,
		quantity: 433,
	},
	{
		garage_id: 241,
		part_id: 342,
		quantity: 196,
	},
	{
		garage_id: 241,
		part_id: 343,
		quantity: 190,
	},
	{
		garage_id: 241,
		part_id: 344,
		quantity: 171,
	},
	{
		garage_id: 241,
		part_id: 345,
		quantity: 273,
	},
	{
		garage_id: 241,
		part_id: 346,
		quantity: 92,
	},
	{
		garage_id: 241,
		part_id: 347,
		quantity: 144,
	},
	{
		garage_id: 241,
		part_id: 348,
		quantity: 53,
	},
	{
		garage_id: 241,
		part_id: 349,
		quantity: 389,
	},
	{
		garage_id: 241,
		part_id: 350,
		quantity: 155,
	},
	{
		garage_id: 241,
		part_id: 351,
		quantity: 326,
	},
	{
		garage_id: 241,
		part_id: 352,
		quantity: 27,
	},
	{
		garage_id: 241,
		part_id: 353,
		quantity: 272,
	},
	{
		garage_id: 241,
		part_id: 354,
		quantity: 407,
	},
	{
		garage_id: 241,
		part_id: 355,
		quantity: 268,
	},
	{
		garage_id: 241,
		part_id: 356,
		quantity: 9,
	},
	{
		garage_id: 241,
		part_id: 357,
		quantity: 261,
	},
	{
		garage_id: 241,
		part_id: 358,
		quantity: 219,
	},
	{
		garage_id: 241,
		part_id: 359,
		quantity: 68,
	},
	{
		garage_id: 241,
		part_id: 360,
		quantity: 226,
	},
	{
		garage_id: 241,
		part_id: 361,
		quantity: 495,
	},
	{
		garage_id: 241,
		part_id: 362,
		quantity: 253,
	},
	{
		garage_id: 241,
		part_id: 363,
		quantity: 22,
	},
	{
		garage_id: 241,
		part_id: 364,
		quantity: 294,
	},
	{
		garage_id: 241,
		part_id: 365,
		quantity: 132,
	},
	{
		garage_id: 241,
		part_id: 366,
		quantity: 444,
	},
	{
		garage_id: 241,
		part_id: 367,
		quantity: 461,
	},
	{
		garage_id: 241,
		part_id: 368,
		quantity: 370,
	},
	{
		garage_id: 241,
		part_id: 369,
		quantity: 323,
	},
	{
		garage_id: 241,
		part_id: 370,
		quantity: 289,
	},
	{
		garage_id: 241,
		part_id: 371,
		quantity: 101,
	},
	{
		garage_id: 241,
		part_id: 372,
		quantity: 360,
	},
	{
		garage_id: 241,
		part_id: 373,
		quantity: 360,
	},
	{
		garage_id: 241,
		part_id: 374,
		quantity: 112,
	},
	{
		garage_id: 241,
		part_id: 375,
		quantity: 482,
	},
	{
		garage_id: 241,
		part_id: 376,
		quantity: 0,
	},
	{
		garage_id: 241,
		part_id: 377,
		quantity: 385,
	},
	{
		garage_id: 241,
		part_id: 378,
		quantity: 221,
	},
	{
		garage_id: 241,
		part_id: 379,
		quantity: 389,
	},
	{
		garage_id: 241,
		part_id: 380,
		quantity: 48,
	},
	{
		garage_id: 241,
		part_id: 381,
		quantity: 162,
	},
	{
		garage_id: 241,
		part_id: 382,
		quantity: 108,
	},
	{
		garage_id: 241,
		part_id: 383,
		quantity: 38,
	},
	{
		garage_id: 241,
		part_id: 384,
		quantity: 38,
	},
	{
		garage_id: 241,
		part_id: 385,
		quantity: 94,
	},
	{
		garage_id: 241,
		part_id: 386,
		quantity: 461,
	},
	{
		garage_id: 241,
		part_id: 387,
		quantity: 147,
	},
	{
		garage_id: 241,
		part_id: 388,
		quantity: 232,
	},
	{
		garage_id: 241,
		part_id: 389,
		quantity: 156,
	},
	{
		garage_id: 241,
		part_id: 390,
		quantity: 156,
	},
	{
		garage_id: 241,
		part_id: 391,
		quantity: 114,
	},
	{
		garage_id: 241,
		part_id: 392,
		quantity: 131,
	},
	{
		garage_id: 241,
		part_id: 393,
		quantity: 121,
	},
	{
		garage_id: 241,
		part_id: 394,
		quantity: 461,
	},
	{
		garage_id: 241,
		part_id: 395,
		quantity: 0,
	},
	{
		garage_id: 241,
		part_id: 396,
		quantity: 320,
	},
	{
		garage_id: 241,
		part_id: 397,
		quantity: 360,
	},
	{
		garage_id: 241,
		part_id: 398,
		quantity: 88,
	},
	{
		garage_id: 241,
		part_id: 399,
		quantity: 193,
	},
	{
		garage_id: 242,
		part_id: 0,
		quantity: 59,
	},
	{
		garage_id: 242,
		part_id: 1,
		quantity: 338,
	},
	{
		garage_id: 242,
		part_id: 2,
		quantity: 142,
	},
	{
		garage_id: 242,
		part_id: 3,
		quantity: 493,
	},
	{
		garage_id: 242,
		part_id: 4,
		quantity: 87,
	},
	{
		garage_id: 242,
		part_id: 5,
		quantity: 63,
	},
	{
		garage_id: 242,
		part_id: 6,
		quantity: 269,
	},
	{
		garage_id: 242,
		part_id: 7,
		quantity: 165,
	},
	{
		garage_id: 242,
		part_id: 8,
		quantity: 476,
	},
	{
		garage_id: 242,
		part_id: 9,
		quantity: 355,
	},
	{
		garage_id: 242,
		part_id: 10,
		quantity: 322,
	},
	{
		garage_id: 242,
		part_id: 11,
		quantity: 443,
	},
	{
		garage_id: 242,
		part_id: 12,
		quantity: 60,
	},
	{
		garage_id: 242,
		part_id: 13,
		quantity: 158,
	},
	{
		garage_id: 242,
		part_id: 14,
		quantity: 472,
	},
	{
		garage_id: 242,
		part_id: 15,
		quantity: 138,
	},
	{
		garage_id: 242,
		part_id: 16,
		quantity: 410,
	},
	{
		garage_id: 242,
		part_id: 17,
		quantity: 132,
	},
	{
		garage_id: 242,
		part_id: 18,
		quantity: 46,
	},
	{
		garage_id: 242,
		part_id: 19,
		quantity: 386,
	},
	{
		garage_id: 242,
		part_id: 20,
		quantity: 466,
	},
	{
		garage_id: 242,
		part_id: 21,
		quantity: 390,
	},
	{
		garage_id: 242,
		part_id: 22,
		quantity: 378,
	},
	{
		garage_id: 242,
		part_id: 23,
		quantity: 288,
	},
	{
		garage_id: 242,
		part_id: 24,
		quantity: 409,
	},
	{
		garage_id: 242,
		part_id: 25,
		quantity: 45,
	},
	{
		garage_id: 242,
		part_id: 26,
		quantity: 134,
	},
	{
		garage_id: 242,
		part_id: 27,
		quantity: 254,
	},
	{
		garage_id: 242,
		part_id: 28,
		quantity: 285,
	},
	{
		garage_id: 242,
		part_id: 29,
		quantity: 232,
	},
	{
		garage_id: 242,
		part_id: 30,
		quantity: 104,
	},
	{
		garage_id: 242,
		part_id: 31,
		quantity: 185,
	},
	{
		garage_id: 242,
		part_id: 32,
		quantity: 111,
	},
	{
		garage_id: 242,
		part_id: 33,
		quantity: 476,
	},
	{
		garage_id: 242,
		part_id: 34,
		quantity: 9,
	},
	{
		garage_id: 242,
		part_id: 35,
		quantity: 132,
	},
	{
		garage_id: 242,
		part_id: 36,
		quantity: 89,
	},
	{
		garage_id: 242,
		part_id: 37,
		quantity: 94,
	},
	{
		garage_id: 242,
		part_id: 38,
		quantity: 342,
	},
	{
		garage_id: 242,
		part_id: 39,
		quantity: 279,
	},
	{
		garage_id: 242,
		part_id: 40,
		quantity: 73,
	},
	{
		garage_id: 242,
		part_id: 41,
		quantity: 375,
	},
	{
		garage_id: 242,
		part_id: 42,
		quantity: 334,
	},
	{
		garage_id: 242,
		part_id: 43,
		quantity: 128,
	},
	{
		garage_id: 242,
		part_id: 44,
		quantity: 130,
	},
	{
		garage_id: 242,
		part_id: 45,
		quantity: 267,
	},
	{
		garage_id: 242,
		part_id: 46,
		quantity: 255,
	},
	{
		garage_id: 242,
		part_id: 47,
		quantity: 93,
	},
	{
		garage_id: 242,
		part_id: 48,
		quantity: 166,
	},
	{
		garage_id: 242,
		part_id: 49,
		quantity: 5,
	},
	{
		garage_id: 242,
		part_id: 50,
		quantity: 71,
	},
	{
		garage_id: 242,
		part_id: 51,
		quantity: 468,
	},
	{
		garage_id: 242,
		part_id: 52,
		quantity: 337,
	},
	{
		garage_id: 242,
		part_id: 53,
		quantity: 309,
	},
	{
		garage_id: 242,
		part_id: 54,
		quantity: 130,
	},
	{
		garage_id: 242,
		part_id: 55,
		quantity: 222,
	},
	{
		garage_id: 242,
		part_id: 56,
		quantity: 73,
	},
	{
		garage_id: 242,
		part_id: 57,
		quantity: 133,
	},
	{
		garage_id: 242,
		part_id: 58,
		quantity: 215,
	},
	{
		garage_id: 242,
		part_id: 59,
		quantity: 203,
	},
	{
		garage_id: 242,
		part_id: 60,
		quantity: 52,
	},
	{
		garage_id: 242,
		part_id: 61,
		quantity: 12,
	},
	{
		garage_id: 242,
		part_id: 62,
		quantity: 386,
	},
	{
		garage_id: 242,
		part_id: 63,
		quantity: 265,
	},
	{
		garage_id: 242,
		part_id: 64,
		quantity: 368,
	},
	{
		garage_id: 242,
		part_id: 65,
		quantity: 0,
	},
	{
		garage_id: 242,
		part_id: 66,
		quantity: 229,
	},
	{
		garage_id: 242,
		part_id: 67,
		quantity: 209,
	},
	{
		garage_id: 242,
		part_id: 68,
		quantity: 489,
	},
	{
		garage_id: 242,
		part_id: 69,
		quantity: 47,
	},
	{
		garage_id: 242,
		part_id: 70,
		quantity: 169,
	},
	{
		garage_id: 242,
		part_id: 71,
		quantity: 204,
	},
	{
		garage_id: 242,
		part_id: 72,
		quantity: 17,
	},
	{
		garage_id: 242,
		part_id: 73,
		quantity: 113,
	},
	{
		garage_id: 242,
		part_id: 74,
		quantity: 363,
	},
	{
		garage_id: 242,
		part_id: 75,
		quantity: 394,
	},
	{
		garage_id: 242,
		part_id: 76,
		quantity: 377,
	},
	{
		garage_id: 242,
		part_id: 77,
		quantity: 265,
	},
	{
		garage_id: 242,
		part_id: 78,
		quantity: 110,
	},
	{
		garage_id: 242,
		part_id: 79,
		quantity: 129,
	},
	{
		garage_id: 242,
		part_id: 80,
		quantity: 40,
	},
	{
		garage_id: 242,
		part_id: 81,
		quantity: 142,
	},
	{
		garage_id: 242,
		part_id: 82,
		quantity: 175,
	},
	{
		garage_id: 242,
		part_id: 83,
		quantity: 24,
	},
	{
		garage_id: 242,
		part_id: 84,
		quantity: 402,
	},
	{
		garage_id: 242,
		part_id: 85,
		quantity: 154,
	},
	{
		garage_id: 242,
		part_id: 86,
		quantity: 124,
	},
	{
		garage_id: 242,
		part_id: 87,
		quantity: 223,
	},
	{
		garage_id: 242,
		part_id: 88,
		quantity: 13,
	},
	{
		garage_id: 242,
		part_id: 89,
		quantity: 105,
	},
	{
		garage_id: 242,
		part_id: 90,
		quantity: 31,
	},
	{
		garage_id: 242,
		part_id: 91,
		quantity: 113,
	},
	{
		garage_id: 242,
		part_id: 92,
		quantity: 14,
	},
	{
		garage_id: 242,
		part_id: 93,
		quantity: 255,
	},
	{
		garage_id: 242,
		part_id: 94,
		quantity: 128,
	},
	{
		garage_id: 242,
		part_id: 95,
		quantity: 161,
	},
	{
		garage_id: 242,
		part_id: 96,
		quantity: 306,
	},
	{
		garage_id: 242,
		part_id: 97,
		quantity: 228,
	},
	{
		garage_id: 242,
		part_id: 98,
		quantity: 353,
	},
	{
		garage_id: 242,
		part_id: 99,
		quantity: 270,
	},
	{
		garage_id: 242,
		part_id: 100,
		quantity: 28,
	},
	{
		garage_id: 242,
		part_id: 101,
		quantity: 12,
	},
	{
		garage_id: 242,
		part_id: 102,
		quantity: 113,
	},
	{
		garage_id: 242,
		part_id: 103,
		quantity: 224,
	},
	{
		garage_id: 242,
		part_id: 104,
		quantity: 347,
	},
	{
		garage_id: 242,
		part_id: 105,
		quantity: 245,
	},
	{
		garage_id: 242,
		part_id: 106,
		quantity: 220,
	},
	{
		garage_id: 242,
		part_id: 107,
		quantity: 159,
	},
	{
		garage_id: 242,
		part_id: 108,
		quantity: 119,
	},
	{
		garage_id: 242,
		part_id: 109,
		quantity: 45,
	},
	{
		garage_id: 242,
		part_id: 110,
		quantity: 483,
	},
	{
		garage_id: 242,
		part_id: 111,
		quantity: 231,
	},
	{
		garage_id: 242,
		part_id: 112,
		quantity: 53,
	},
	{
		garage_id: 242,
		part_id: 113,
		quantity: 401,
	},
	{
		garage_id: 242,
		part_id: 114,
		quantity: 429,
	},
	{
		garage_id: 242,
		part_id: 115,
		quantity: 465,
	},
	{
		garage_id: 242,
		part_id: 116,
		quantity: 187,
	},
	{
		garage_id: 242,
		part_id: 117,
		quantity: 30,
	},
	{
		garage_id: 242,
		part_id: 118,
		quantity: 474,
	},
	{
		garage_id: 242,
		part_id: 119,
		quantity: 178,
	},
	{
		garage_id: 242,
		part_id: 120,
		quantity: 65,
	},
	{
		garage_id: 242,
		part_id: 121,
		quantity: 293,
	},
	{
		garage_id: 242,
		part_id: 122,
		quantity: 74,
	},
	{
		garage_id: 242,
		part_id: 123,
		quantity: 478,
	},
	{
		garage_id: 242,
		part_id: 124,
		quantity: 419,
	},
	{
		garage_id: 242,
		part_id: 125,
		quantity: 496,
	},
	{
		garage_id: 242,
		part_id: 126,
		quantity: 466,
	},
	{
		garage_id: 242,
		part_id: 127,
		quantity: 256,
	},
	{
		garage_id: 242,
		part_id: 128,
		quantity: 191,
	},
	{
		garage_id: 242,
		part_id: 129,
		quantity: 17,
	},
	{
		garage_id: 242,
		part_id: 130,
		quantity: 162,
	},
	{
		garage_id: 242,
		part_id: 131,
		quantity: 364,
	},
	{
		garage_id: 242,
		part_id: 132,
		quantity: 144,
	},
	{
		garage_id: 242,
		part_id: 133,
		quantity: 388,
	},
	{
		garage_id: 242,
		part_id: 134,
		quantity: 227,
	},
	{
		garage_id: 242,
		part_id: 135,
		quantity: 82,
	},
	{
		garage_id: 242,
		part_id: 136,
		quantity: 192,
	},
	{
		garage_id: 242,
		part_id: 137,
		quantity: 324,
	},
	{
		garage_id: 242,
		part_id: 138,
		quantity: 20,
	},
	{
		garage_id: 242,
		part_id: 139,
		quantity: 314,
	},
	{
		garage_id: 242,
		part_id: 140,
		quantity: 467,
	},
	{
		garage_id: 242,
		part_id: 141,
		quantity: 364,
	},
	{
		garage_id: 242,
		part_id: 142,
		quantity: 228,
	},
	{
		garage_id: 242,
		part_id: 143,
		quantity: 50,
	},
	{
		garage_id: 242,
		part_id: 144,
		quantity: 321,
	},
	{
		garage_id: 242,
		part_id: 145,
		quantity: 2,
	},
	{
		garage_id: 242,
		part_id: 146,
		quantity: 273,
	},
	{
		garage_id: 242,
		part_id: 147,
		quantity: 246,
	},
	{
		garage_id: 242,
		part_id: 148,
		quantity: 287,
	},
	{
		garage_id: 242,
		part_id: 149,
		quantity: 144,
	},
	{
		garage_id: 242,
		part_id: 150,
		quantity: 34,
	},
	{
		garage_id: 242,
		part_id: 151,
		quantity: 149,
	},
	{
		garage_id: 242,
		part_id: 152,
		quantity: 448,
	},
	{
		garage_id: 242,
		part_id: 153,
		quantity: 255,
	},
	{
		garage_id: 242,
		part_id: 154,
		quantity: 226,
	},
	{
		garage_id: 242,
		part_id: 155,
		quantity: 432,
	},
	{
		garage_id: 242,
		part_id: 156,
		quantity: 28,
	},
	{
		garage_id: 242,
		part_id: 157,
		quantity: 221,
	},
	{
		garage_id: 242,
		part_id: 158,
		quantity: 416,
	},
	{
		garage_id: 242,
		part_id: 159,
		quantity: 361,
	},
	{
		garage_id: 242,
		part_id: 160,
		quantity: 86,
	},
	{
		garage_id: 242,
		part_id: 161,
		quantity: 214,
	},
	{
		garage_id: 242,
		part_id: 162,
		quantity: 442,
	},
	{
		garage_id: 242,
		part_id: 163,
		quantity: 39,
	},
	{
		garage_id: 242,
		part_id: 164,
		quantity: 478,
	},
	{
		garage_id: 242,
		part_id: 165,
		quantity: 169,
	},
	{
		garage_id: 242,
		part_id: 166,
		quantity: 470,
	},
	{
		garage_id: 242,
		part_id: 167,
		quantity: 264,
	},
	{
		garage_id: 242,
		part_id: 168,
		quantity: 63,
	},
	{
		garage_id: 242,
		part_id: 169,
		quantity: 343,
	},
	{
		garage_id: 242,
		part_id: 170,
		quantity: 487,
	},
	{
		garage_id: 242,
		part_id: 171,
		quantity: 58,
	},
	{
		garage_id: 242,
		part_id: 172,
		quantity: 79,
	},
	{
		garage_id: 242,
		part_id: 173,
		quantity: 108,
	},
	{
		garage_id: 242,
		part_id: 174,
		quantity: 88,
	},
	{
		garage_id: 242,
		part_id: 175,
		quantity: 69,
	},
	{
		garage_id: 242,
		part_id: 176,
		quantity: 117,
	},
	{
		garage_id: 242,
		part_id: 177,
		quantity: 220,
	},
	{
		garage_id: 242,
		part_id: 178,
		quantity: 137,
	},
	{
		garage_id: 242,
		part_id: 179,
		quantity: 128,
	},
	{
		garage_id: 242,
		part_id: 180,
		quantity: 175,
	},
	{
		garage_id: 242,
		part_id: 181,
		quantity: 500,
	},
	{
		garage_id: 242,
		part_id: 182,
		quantity: 416,
	},
	{
		garage_id: 242,
		part_id: 183,
		quantity: 136,
	},
	{
		garage_id: 242,
		part_id: 184,
		quantity: 427,
	},
	{
		garage_id: 242,
		part_id: 185,
		quantity: 319,
	},
	{
		garage_id: 242,
		part_id: 186,
		quantity: 321,
	},
	{
		garage_id: 242,
		part_id: 187,
		quantity: 381,
	},
	{
		garage_id: 242,
		part_id: 188,
		quantity: 228,
	},
	{
		garage_id: 242,
		part_id: 189,
		quantity: 231,
	},
	{
		garage_id: 242,
		part_id: 190,
		quantity: 270,
	},
	{
		garage_id: 242,
		part_id: 191,
		quantity: 93,
	},
	{
		garage_id: 242,
		part_id: 192,
		quantity: 105,
	},
	{
		garage_id: 242,
		part_id: 193,
		quantity: 428,
	},
	{
		garage_id: 242,
		part_id: 194,
		quantity: 7,
	},
	{
		garage_id: 242,
		part_id: 195,
		quantity: 54,
	},
	{
		garage_id: 242,
		part_id: 196,
		quantity: 77,
	},
	{
		garage_id: 242,
		part_id: 197,
		quantity: 121,
	},
	{
		garage_id: 242,
		part_id: 198,
		quantity: 435,
	},
	{
		garage_id: 242,
		part_id: 199,
		quantity: 170,
	},
	{
		garage_id: 242,
		part_id: 200,
		quantity: 111,
	},
	{
		garage_id: 242,
		part_id: 201,
		quantity: 492,
	},
	{
		garage_id: 242,
		part_id: 202,
		quantity: 306,
	},
	{
		garage_id: 242,
		part_id: 203,
		quantity: 182,
	},
	{
		garage_id: 242,
		part_id: 204,
		quantity: 85,
	},
	{
		garage_id: 242,
		part_id: 205,
		quantity: 140,
	},
	{
		garage_id: 242,
		part_id: 206,
		quantity: 306,
	},
	{
		garage_id: 242,
		part_id: 207,
		quantity: 418,
	},
	{
		garage_id: 242,
		part_id: 208,
		quantity: 458,
	},
	{
		garage_id: 242,
		part_id: 209,
		quantity: 431,
	},
	{
		garage_id: 242,
		part_id: 210,
		quantity: 67,
	},
	{
		garage_id: 242,
		part_id: 211,
		quantity: 47,
	},
	{
		garage_id: 242,
		part_id: 212,
		quantity: 129,
	},
	{
		garage_id: 242,
		part_id: 213,
		quantity: 300,
	},
	{
		garage_id: 242,
		part_id: 214,
		quantity: 384,
	},
	{
		garage_id: 242,
		part_id: 215,
		quantity: 169,
	},
	{
		garage_id: 242,
		part_id: 216,
		quantity: 35,
	},
	{
		garage_id: 242,
		part_id: 217,
		quantity: 128,
	},
	{
		garage_id: 242,
		part_id: 218,
		quantity: 266,
	},
	{
		garage_id: 242,
		part_id: 219,
		quantity: 425,
	},
	{
		garage_id: 242,
		part_id: 220,
		quantity: 426,
	},
	{
		garage_id: 242,
		part_id: 221,
		quantity: 327,
	},
	{
		garage_id: 242,
		part_id: 222,
		quantity: 354,
	},
	{
		garage_id: 242,
		part_id: 223,
		quantity: 326,
	},
	{
		garage_id: 242,
		part_id: 224,
		quantity: 69,
	},
	{
		garage_id: 242,
		part_id: 225,
		quantity: 332,
	},
	{
		garage_id: 242,
		part_id: 226,
		quantity: 477,
	},
	{
		garage_id: 242,
		part_id: 227,
		quantity: 246,
	},
	{
		garage_id: 242,
		part_id: 228,
		quantity: 437,
	},
	{
		garage_id: 242,
		part_id: 229,
		quantity: 390,
	},
	{
		garage_id: 242,
		part_id: 230,
		quantity: 178,
	},
	{
		garage_id: 242,
		part_id: 231,
		quantity: 402,
	},
	{
		garage_id: 242,
		part_id: 232,
		quantity: 415,
	},
	{
		garage_id: 242,
		part_id: 233,
		quantity: 333,
	},
	{
		garage_id: 242,
		part_id: 234,
		quantity: 14,
	},
	{
		garage_id: 242,
		part_id: 235,
		quantity: 491,
	},
	{
		garage_id: 242,
		part_id: 236,
		quantity: 210,
	},
	{
		garage_id: 242,
		part_id: 237,
		quantity: 142,
	},
	{
		garage_id: 242,
		part_id: 238,
		quantity: 462,
	},
	{
		garage_id: 242,
		part_id: 239,
		quantity: 412,
	},
	{
		garage_id: 242,
		part_id: 240,
		quantity: 39,
	},
	{
		garage_id: 242,
		part_id: 241,
		quantity: 432,
	},
	{
		garage_id: 242,
		part_id: 242,
		quantity: 17,
	},
	{
		garage_id: 242,
		part_id: 243,
		quantity: 382,
	},
	{
		garage_id: 242,
		part_id: 244,
		quantity: 270,
	},
	{
		garage_id: 242,
		part_id: 245,
		quantity: 279,
	},
	{
		garage_id: 242,
		part_id: 246,
		quantity: 203,
	},
	{
		garage_id: 242,
		part_id: 247,
		quantity: 139,
	},
	{
		garage_id: 242,
		part_id: 248,
		quantity: 99,
	},
	{
		garage_id: 242,
		part_id: 249,
		quantity: 2,
	},
	{
		garage_id: 242,
		part_id: 250,
		quantity: 142,
	},
	{
		garage_id: 242,
		part_id: 251,
		quantity: 290,
	},
	{
		garage_id: 242,
		part_id: 252,
		quantity: 220,
	},
	{
		garage_id: 242,
		part_id: 253,
		quantity: 369,
	},
	{
		garage_id: 242,
		part_id: 254,
		quantity: 379,
	},
	{
		garage_id: 242,
		part_id: 255,
		quantity: 236,
	},
	{
		garage_id: 242,
		part_id: 256,
		quantity: 73,
	},
	{
		garage_id: 242,
		part_id: 257,
		quantity: 341,
	},
	{
		garage_id: 242,
		part_id: 258,
		quantity: 300,
	},
	{
		garage_id: 242,
		part_id: 259,
		quantity: 485,
	},
	{
		garage_id: 242,
		part_id: 260,
		quantity: 256,
	},
	{
		garage_id: 242,
		part_id: 261,
		quantity: 469,
	},
	{
		garage_id: 242,
		part_id: 262,
		quantity: 310,
	},
	{
		garage_id: 242,
		part_id: 263,
		quantity: 142,
	},
	{
		garage_id: 242,
		part_id: 264,
		quantity: 462,
	},
	{
		garage_id: 242,
		part_id: 265,
		quantity: 86,
	},
	{
		garage_id: 242,
		part_id: 266,
		quantity: 70,
	},
	{
		garage_id: 242,
		part_id: 267,
		quantity: 0,
	},
	{
		garage_id: 242,
		part_id: 268,
		quantity: 28,
	},
	{
		garage_id: 242,
		part_id: 269,
		quantity: 66,
	},
	{
		garage_id: 242,
		part_id: 270,
		quantity: 70,
	},
	{
		garage_id: 242,
		part_id: 271,
		quantity: 427,
	},
	{
		garage_id: 242,
		part_id: 272,
		quantity: 187,
	},
	{
		garage_id: 242,
		part_id: 273,
		quantity: 186,
	},
	{
		garage_id: 242,
		part_id: 274,
		quantity: 484,
	},
	{
		garage_id: 242,
		part_id: 275,
		quantity: 305,
	},
	{
		garage_id: 242,
		part_id: 276,
		quantity: 409,
	},
	{
		garage_id: 242,
		part_id: 277,
		quantity: 459,
	},
	{
		garage_id: 242,
		part_id: 278,
		quantity: 49,
	},
	{
		garage_id: 242,
		part_id: 279,
		quantity: 308,
	},
	{
		garage_id: 242,
		part_id: 280,
		quantity: 377,
	},
	{
		garage_id: 242,
		part_id: 281,
		quantity: 157,
	},
	{
		garage_id: 242,
		part_id: 282,
		quantity: 215,
	},
	{
		garage_id: 242,
		part_id: 283,
		quantity: 74,
	},
	{
		garage_id: 242,
		part_id: 284,
		quantity: 421,
	},
	{
		garage_id: 242,
		part_id: 285,
		quantity: 229,
	},
	{
		garage_id: 242,
		part_id: 286,
		quantity: 444,
	},
	{
		garage_id: 242,
		part_id: 287,
		quantity: 398,
	},
	{
		garage_id: 242,
		part_id: 288,
		quantity: 393,
	},
	{
		garage_id: 242,
		part_id: 289,
		quantity: 446,
	},
	{
		garage_id: 242,
		part_id: 290,
		quantity: 323,
	},
	{
		garage_id: 242,
		part_id: 291,
		quantity: 293,
	},
	{
		garage_id: 242,
		part_id: 292,
		quantity: 134,
	},
	{
		garage_id: 242,
		part_id: 293,
		quantity: 153,
	},
	{
		garage_id: 242,
		part_id: 294,
		quantity: 153,
	},
	{
		garage_id: 242,
		part_id: 295,
		quantity: 147,
	},
	{
		garage_id: 242,
		part_id: 296,
		quantity: 397,
	},
	{
		garage_id: 242,
		part_id: 297,
		quantity: 192,
	},
	{
		garage_id: 242,
		part_id: 298,
		quantity: 366,
	},
	{
		garage_id: 242,
		part_id: 299,
		quantity: 378,
	},
	{
		garage_id: 242,
		part_id: 300,
		quantity: 470,
	},
	{
		garage_id: 242,
		part_id: 301,
		quantity: 258,
	},
	{
		garage_id: 242,
		part_id: 302,
		quantity: 185,
	},
	{
		garage_id: 242,
		part_id: 303,
		quantity: 41,
	},
	{
		garage_id: 242,
		part_id: 304,
		quantity: 330,
	},
	{
		garage_id: 242,
		part_id: 305,
		quantity: 240,
	},
	{
		garage_id: 242,
		part_id: 306,
		quantity: 170,
	},
	{
		garage_id: 242,
		part_id: 307,
		quantity: 382,
	},
	{
		garage_id: 242,
		part_id: 308,
		quantity: 423,
	},
	{
		garage_id: 242,
		part_id: 309,
		quantity: 428,
	},
	{
		garage_id: 242,
		part_id: 310,
		quantity: 132,
	},
	{
		garage_id: 242,
		part_id: 311,
		quantity: 293,
	},
	{
		garage_id: 242,
		part_id: 312,
		quantity: 180,
	},
	{
		garage_id: 242,
		part_id: 313,
		quantity: 405,
	},
	{
		garage_id: 242,
		part_id: 314,
		quantity: 405,
	},
	{
		garage_id: 242,
		part_id: 315,
		quantity: 300,
	},
	{
		garage_id: 242,
		part_id: 316,
		quantity: 200,
	},
	{
		garage_id: 242,
		part_id: 317,
		quantity: 211,
	},
	{
		garage_id: 242,
		part_id: 318,
		quantity: 210,
	},
	{
		garage_id: 242,
		part_id: 319,
		quantity: 241,
	},
	{
		garage_id: 242,
		part_id: 320,
		quantity: 159,
	},
	{
		garage_id: 242,
		part_id: 321,
		quantity: 412,
	},
	{
		garage_id: 242,
		part_id: 322,
		quantity: 432,
	},
	{
		garage_id: 242,
		part_id: 323,
		quantity: 116,
	},
	{
		garage_id: 242,
		part_id: 324,
		quantity: 117,
	},
	{
		garage_id: 242,
		part_id: 325,
		quantity: 136,
	},
	{
		garage_id: 242,
		part_id: 326,
		quantity: 117,
	},
	{
		garage_id: 242,
		part_id: 327,
		quantity: 120,
	},
	{
		garage_id: 242,
		part_id: 328,
		quantity: 269,
	},
	{
		garage_id: 242,
		part_id: 329,
		quantity: 312,
	},
	{
		garage_id: 242,
		part_id: 330,
		quantity: 227,
	},
	{
		garage_id: 242,
		part_id: 331,
		quantity: 206,
	},
	{
		garage_id: 242,
		part_id: 332,
		quantity: 13,
	},
	{
		garage_id: 242,
		part_id: 333,
		quantity: 305,
	},
	{
		garage_id: 242,
		part_id: 334,
		quantity: 258,
	},
	{
		garage_id: 242,
		part_id: 335,
		quantity: 37,
	},
	{
		garage_id: 242,
		part_id: 336,
		quantity: 407,
	},
	{
		garage_id: 242,
		part_id: 337,
		quantity: 487,
	},
	{
		garage_id: 242,
		part_id: 338,
		quantity: 79,
	},
	{
		garage_id: 242,
		part_id: 339,
		quantity: 318,
	},
	{
		garage_id: 242,
		part_id: 340,
		quantity: 236,
	},
	{
		garage_id: 242,
		part_id: 341,
		quantity: 250,
	},
	{
		garage_id: 242,
		part_id: 342,
		quantity: 494,
	},
	{
		garage_id: 242,
		part_id: 343,
		quantity: 171,
	},
	{
		garage_id: 242,
		part_id: 344,
		quantity: 335,
	},
	{
		garage_id: 242,
		part_id: 345,
		quantity: 77,
	},
	{
		garage_id: 242,
		part_id: 346,
		quantity: 365,
	},
	{
		garage_id: 242,
		part_id: 347,
		quantity: 43,
	},
	{
		garage_id: 242,
		part_id: 348,
		quantity: 234,
	},
	{
		garage_id: 242,
		part_id: 349,
		quantity: 184,
	},
	{
		garage_id: 242,
		part_id: 350,
		quantity: 391,
	},
	{
		garage_id: 242,
		part_id: 351,
		quantity: 66,
	},
	{
		garage_id: 242,
		part_id: 352,
		quantity: 221,
	},
	{
		garage_id: 242,
		part_id: 353,
		quantity: 409,
	},
	{
		garage_id: 242,
		part_id: 354,
		quantity: 219,
	},
	{
		garage_id: 242,
		part_id: 355,
		quantity: 469,
	},
	{
		garage_id: 242,
		part_id: 356,
		quantity: 333,
	},
	{
		garage_id: 242,
		part_id: 357,
		quantity: 215,
	},
	{
		garage_id: 242,
		part_id: 358,
		quantity: 477,
	},
	{
		garage_id: 242,
		part_id: 359,
		quantity: 282,
	},
	{
		garage_id: 242,
		part_id: 360,
		quantity: 182,
	},
	{
		garage_id: 242,
		part_id: 361,
		quantity: 411,
	},
	{
		garage_id: 242,
		part_id: 362,
		quantity: 398,
	},
	{
		garage_id: 242,
		part_id: 363,
		quantity: 275,
	},
	{
		garage_id: 242,
		part_id: 364,
		quantity: 180,
	},
	{
		garage_id: 242,
		part_id: 365,
		quantity: 293,
	},
	{
		garage_id: 242,
		part_id: 366,
		quantity: 74,
	},
	{
		garage_id: 242,
		part_id: 367,
		quantity: 221,
	},
	{
		garage_id: 242,
		part_id: 368,
		quantity: 183,
	},
	{
		garage_id: 242,
		part_id: 369,
		quantity: 282,
	},
	{
		garage_id: 242,
		part_id: 370,
		quantity: 227,
	},
	{
		garage_id: 242,
		part_id: 371,
		quantity: 349,
	},
	{
		garage_id: 242,
		part_id: 372,
		quantity: 82,
	},
	{
		garage_id: 242,
		part_id: 373,
		quantity: 341,
	},
	{
		garage_id: 242,
		part_id: 374,
		quantity: 45,
	},
	{
		garage_id: 242,
		part_id: 375,
		quantity: 456,
	},
	{
		garage_id: 242,
		part_id: 376,
		quantity: 270,
	},
	{
		garage_id: 242,
		part_id: 377,
		quantity: 47,
	},
	{
		garage_id: 242,
		part_id: 378,
		quantity: 350,
	},
	{
		garage_id: 242,
		part_id: 379,
		quantity: 234,
	},
	{
		garage_id: 242,
		part_id: 380,
		quantity: 471,
	},
	{
		garage_id: 242,
		part_id: 381,
		quantity: 75,
	},
	{
		garage_id: 242,
		part_id: 382,
		quantity: 46,
	},
	{
		garage_id: 242,
		part_id: 383,
		quantity: 216,
	},
	{
		garage_id: 242,
		part_id: 384,
		quantity: 14,
	},
	{
		garage_id: 242,
		part_id: 385,
		quantity: 491,
	},
	{
		garage_id: 242,
		part_id: 386,
		quantity: 406,
	},
	{
		garage_id: 242,
		part_id: 387,
		quantity: 76,
	},
	{
		garage_id: 242,
		part_id: 388,
		quantity: 374,
	},
	{
		garage_id: 242,
		part_id: 389,
		quantity: 233,
	},
	{
		garage_id: 242,
		part_id: 390,
		quantity: 204,
	},
	{
		garage_id: 242,
		part_id: 391,
		quantity: 202,
	},
	{
		garage_id: 242,
		part_id: 392,
		quantity: 155,
	},
	{
		garage_id: 242,
		part_id: 393,
		quantity: 45,
	},
	{
		garage_id: 242,
		part_id: 394,
		quantity: 474,
	},
	{
		garage_id: 242,
		part_id: 395,
		quantity: 497,
	},
	{
		garage_id: 242,
		part_id: 396,
		quantity: 17,
	},
	{
		garage_id: 242,
		part_id: 397,
		quantity: 11,
	},
	{
		garage_id: 242,
		part_id: 398,
		quantity: 184,
	},
	{
		garage_id: 242,
		part_id: 399,
		quantity: 277,
	},
	{
		garage_id: 243,
		part_id: 0,
		quantity: 186,
	},
	{
		garage_id: 243,
		part_id: 1,
		quantity: 70,
	},
	{
		garage_id: 243,
		part_id: 2,
		quantity: 210,
	},
	{
		garage_id: 243,
		part_id: 3,
		quantity: 269,
	},
	{
		garage_id: 243,
		part_id: 4,
		quantity: 467,
	},
	{
		garage_id: 243,
		part_id: 5,
		quantity: 364,
	},
	{
		garage_id: 243,
		part_id: 6,
		quantity: 393,
	},
	{
		garage_id: 243,
		part_id: 7,
		quantity: 96,
	},
	{
		garage_id: 243,
		part_id: 8,
		quantity: 278,
	},
	{
		garage_id: 243,
		part_id: 9,
		quantity: 318,
	},
	{
		garage_id: 243,
		part_id: 10,
		quantity: 480,
	},
	{
		garage_id: 243,
		part_id: 11,
		quantity: 162,
	},
	{
		garage_id: 243,
		part_id: 12,
		quantity: 20,
	},
	{
		garage_id: 243,
		part_id: 13,
		quantity: 353,
	},
	{
		garage_id: 243,
		part_id: 14,
		quantity: 406,
	},
	{
		garage_id: 243,
		part_id: 15,
		quantity: 239,
	},
	{
		garage_id: 243,
		part_id: 16,
		quantity: 424,
	},
	{
		garage_id: 243,
		part_id: 17,
		quantity: 54,
	},
	{
		garage_id: 243,
		part_id: 18,
		quantity: 420,
	},
	{
		garage_id: 243,
		part_id: 19,
		quantity: 54,
	},
	{
		garage_id: 243,
		part_id: 20,
		quantity: 3,
	},
	{
		garage_id: 243,
		part_id: 21,
		quantity: 319,
	},
	{
		garage_id: 243,
		part_id: 22,
		quantity: 307,
	},
	{
		garage_id: 243,
		part_id: 23,
		quantity: 268,
	},
	{
		garage_id: 243,
		part_id: 24,
		quantity: 117,
	},
	{
		garage_id: 243,
		part_id: 25,
		quantity: 252,
	},
	{
		garage_id: 243,
		part_id: 26,
		quantity: 316,
	},
	{
		garage_id: 243,
		part_id: 27,
		quantity: 182,
	},
	{
		garage_id: 243,
		part_id: 28,
		quantity: 110,
	},
	{
		garage_id: 243,
		part_id: 29,
		quantity: 108,
	},
	{
		garage_id: 243,
		part_id: 30,
		quantity: 233,
	},
	{
		garage_id: 243,
		part_id: 31,
		quantity: 468,
	},
	{
		garage_id: 243,
		part_id: 32,
		quantity: 266,
	},
	{
		garage_id: 243,
		part_id: 33,
		quantity: 255,
	},
	{
		garage_id: 243,
		part_id: 34,
		quantity: 175,
	},
	{
		garage_id: 243,
		part_id: 35,
		quantity: 486,
	},
	{
		garage_id: 243,
		part_id: 36,
		quantity: 396,
	},
	{
		garage_id: 243,
		part_id: 37,
		quantity: 204,
	},
	{
		garage_id: 243,
		part_id: 38,
		quantity: 377,
	},
	{
		garage_id: 243,
		part_id: 39,
		quantity: 84,
	},
	{
		garage_id: 243,
		part_id: 40,
		quantity: 152,
	},
	{
		garage_id: 243,
		part_id: 41,
		quantity: 244,
	},
	{
		garage_id: 243,
		part_id: 42,
		quantity: 300,
	},
	{
		garage_id: 243,
		part_id: 43,
		quantity: 129,
	},
	{
		garage_id: 243,
		part_id: 44,
		quantity: 354,
	},
	{
		garage_id: 243,
		part_id: 45,
		quantity: 170,
	},
	{
		garage_id: 243,
		part_id: 46,
		quantity: 330,
	},
	{
		garage_id: 243,
		part_id: 47,
		quantity: 275,
	},
	{
		garage_id: 243,
		part_id: 48,
		quantity: 268,
	},
	{
		garage_id: 243,
		part_id: 49,
		quantity: 106,
	},
	{
		garage_id: 243,
		part_id: 50,
		quantity: 422,
	},
	{
		garage_id: 243,
		part_id: 51,
		quantity: 26,
	},
	{
		garage_id: 243,
		part_id: 52,
		quantity: 270,
	},
	{
		garage_id: 243,
		part_id: 53,
		quantity: 380,
	},
	{
		garage_id: 243,
		part_id: 54,
		quantity: 158,
	},
	{
		garage_id: 243,
		part_id: 55,
		quantity: 76,
	},
	{
		garage_id: 243,
		part_id: 56,
		quantity: 148,
	},
	{
		garage_id: 243,
		part_id: 57,
		quantity: 32,
	},
	{
		garage_id: 243,
		part_id: 58,
		quantity: 446,
	},
	{
		garage_id: 243,
		part_id: 59,
		quantity: 233,
	},
	{
		garage_id: 243,
		part_id: 60,
		quantity: 132,
	},
	{
		garage_id: 243,
		part_id: 61,
		quantity: 338,
	},
	{
		garage_id: 243,
		part_id: 62,
		quantity: 59,
	},
	{
		garage_id: 243,
		part_id: 63,
		quantity: 483,
	},
	{
		garage_id: 243,
		part_id: 64,
		quantity: 289,
	},
	{
		garage_id: 243,
		part_id: 65,
		quantity: 328,
	},
	{
		garage_id: 243,
		part_id: 66,
		quantity: 24,
	},
	{
		garage_id: 243,
		part_id: 67,
		quantity: 302,
	},
	{
		garage_id: 243,
		part_id: 68,
		quantity: 297,
	},
	{
		garage_id: 243,
		part_id: 69,
		quantity: 394,
	},
	{
		garage_id: 243,
		part_id: 70,
		quantity: 100,
	},
	{
		garage_id: 243,
		part_id: 71,
		quantity: 421,
	},
	{
		garage_id: 243,
		part_id: 72,
		quantity: 332,
	},
	{
		garage_id: 243,
		part_id: 73,
		quantity: 199,
	},
	{
		garage_id: 243,
		part_id: 74,
		quantity: 105,
	},
	{
		garage_id: 243,
		part_id: 75,
		quantity: 253,
	},
	{
		garage_id: 243,
		part_id: 76,
		quantity: 216,
	},
	{
		garage_id: 243,
		part_id: 77,
		quantity: 42,
	},
	{
		garage_id: 243,
		part_id: 78,
		quantity: 104,
	},
	{
		garage_id: 243,
		part_id: 79,
		quantity: 398,
	},
	{
		garage_id: 243,
		part_id: 80,
		quantity: 102,
	},
	{
		garage_id: 243,
		part_id: 81,
		quantity: 411,
	},
	{
		garage_id: 243,
		part_id: 82,
		quantity: 126,
	},
	{
		garage_id: 243,
		part_id: 83,
		quantity: 497,
	},
	{
		garage_id: 243,
		part_id: 84,
		quantity: 272,
	},
	{
		garage_id: 243,
		part_id: 85,
		quantity: 391,
	},
	{
		garage_id: 243,
		part_id: 86,
		quantity: 62,
	},
	{
		garage_id: 243,
		part_id: 87,
		quantity: 314,
	},
	{
		garage_id: 243,
		part_id: 88,
		quantity: 471,
	},
	{
		garage_id: 243,
		part_id: 89,
		quantity: 326,
	},
	{
		garage_id: 243,
		part_id: 90,
		quantity: 260,
	},
	{
		garage_id: 243,
		part_id: 91,
		quantity: 450,
	},
	{
		garage_id: 243,
		part_id: 92,
		quantity: 378,
	},
	{
		garage_id: 243,
		part_id: 93,
		quantity: 1,
	},
	{
		garage_id: 243,
		part_id: 94,
		quantity: 4,
	},
	{
		garage_id: 243,
		part_id: 95,
		quantity: 499,
	},
	{
		garage_id: 243,
		part_id: 96,
		quantity: 486,
	},
	{
		garage_id: 243,
		part_id: 97,
		quantity: 342,
	},
	{
		garage_id: 243,
		part_id: 98,
		quantity: 233,
	},
	{
		garage_id: 243,
		part_id: 99,
		quantity: 243,
	},
	{
		garage_id: 243,
		part_id: 100,
		quantity: 498,
	},
	{
		garage_id: 243,
		part_id: 101,
		quantity: 417,
	},
	{
		garage_id: 243,
		part_id: 102,
		quantity: 491,
	},
	{
		garage_id: 243,
		part_id: 103,
		quantity: 462,
	},
	{
		garage_id: 243,
		part_id: 104,
		quantity: 271,
	},
	{
		garage_id: 243,
		part_id: 105,
		quantity: 177,
	},
	{
		garage_id: 243,
		part_id: 106,
		quantity: 52,
	},
	{
		garage_id: 243,
		part_id: 107,
		quantity: 160,
	},
	{
		garage_id: 243,
		part_id: 108,
		quantity: 218,
	},
	{
		garage_id: 243,
		part_id: 109,
		quantity: 27,
	},
	{
		garage_id: 243,
		part_id: 110,
		quantity: 389,
	},
	{
		garage_id: 243,
		part_id: 111,
		quantity: 468,
	},
	{
		garage_id: 243,
		part_id: 112,
		quantity: 445,
	},
	{
		garage_id: 243,
		part_id: 113,
		quantity: 479,
	},
	{
		garage_id: 243,
		part_id: 114,
		quantity: 3,
	},
	{
		garage_id: 243,
		part_id: 115,
		quantity: 274,
	},
	{
		garage_id: 243,
		part_id: 116,
		quantity: 484,
	},
	{
		garage_id: 243,
		part_id: 117,
		quantity: 224,
	},
	{
		garage_id: 243,
		part_id: 118,
		quantity: 236,
	},
	{
		garage_id: 243,
		part_id: 119,
		quantity: 244,
	},
	{
		garage_id: 243,
		part_id: 120,
		quantity: 16,
	},
	{
		garage_id: 243,
		part_id: 121,
		quantity: 380,
	},
	{
		garage_id: 243,
		part_id: 122,
		quantity: 307,
	},
	{
		garage_id: 243,
		part_id: 123,
		quantity: 348,
	},
	{
		garage_id: 243,
		part_id: 124,
		quantity: 336,
	},
	{
		garage_id: 243,
		part_id: 125,
		quantity: 397,
	},
	{
		garage_id: 243,
		part_id: 126,
		quantity: 70,
	},
	{
		garage_id: 243,
		part_id: 127,
		quantity: 27,
	},
	{
		garage_id: 243,
		part_id: 128,
		quantity: 142,
	},
	{
		garage_id: 243,
		part_id: 129,
		quantity: 288,
	},
	{
		garage_id: 243,
		part_id: 130,
		quantity: 364,
	},
	{
		garage_id: 243,
		part_id: 131,
		quantity: 197,
	},
	{
		garage_id: 243,
		part_id: 132,
		quantity: 8,
	},
	{
		garage_id: 243,
		part_id: 133,
		quantity: 305,
	},
	{
		garage_id: 243,
		part_id: 134,
		quantity: 72,
	},
	{
		garage_id: 243,
		part_id: 135,
		quantity: 149,
	},
	{
		garage_id: 243,
		part_id: 136,
		quantity: 142,
	},
	{
		garage_id: 243,
		part_id: 137,
		quantity: 351,
	},
	{
		garage_id: 243,
		part_id: 138,
		quantity: 0,
	},
	{
		garage_id: 243,
		part_id: 139,
		quantity: 409,
	},
	{
		garage_id: 243,
		part_id: 140,
		quantity: 58,
	},
	{
		garage_id: 243,
		part_id: 141,
		quantity: 39,
	},
	{
		garage_id: 243,
		part_id: 142,
		quantity: 67,
	},
	{
		garage_id: 243,
		part_id: 143,
		quantity: 257,
	},
	{
		garage_id: 243,
		part_id: 144,
		quantity: 360,
	},
	{
		garage_id: 243,
		part_id: 145,
		quantity: 335,
	},
	{
		garage_id: 243,
		part_id: 146,
		quantity: 468,
	},
	{
		garage_id: 243,
		part_id: 147,
		quantity: 64,
	},
	{
		garage_id: 243,
		part_id: 148,
		quantity: 494,
	},
	{
		garage_id: 243,
		part_id: 149,
		quantity: 282,
	},
	{
		garage_id: 243,
		part_id: 150,
		quantity: 88,
	},
	{
		garage_id: 243,
		part_id: 151,
		quantity: 257,
	},
	{
		garage_id: 243,
		part_id: 152,
		quantity: 190,
	},
	{
		garage_id: 243,
		part_id: 153,
		quantity: 496,
	},
	{
		garage_id: 243,
		part_id: 154,
		quantity: 320,
	},
	{
		garage_id: 243,
		part_id: 155,
		quantity: 409,
	},
	{
		garage_id: 243,
		part_id: 156,
		quantity: 129,
	},
	{
		garage_id: 243,
		part_id: 157,
		quantity: 414,
	},
	{
		garage_id: 243,
		part_id: 158,
		quantity: 81,
	},
	{
		garage_id: 243,
		part_id: 159,
		quantity: 371,
	},
	{
		garage_id: 243,
		part_id: 160,
		quantity: 473,
	},
	{
		garage_id: 243,
		part_id: 161,
		quantity: 166,
	},
	{
		garage_id: 243,
		part_id: 162,
		quantity: 109,
	},
	{
		garage_id: 243,
		part_id: 163,
		quantity: 325,
	},
	{
		garage_id: 243,
		part_id: 164,
		quantity: 467,
	},
	{
		garage_id: 243,
		part_id: 165,
		quantity: 75,
	},
	{
		garage_id: 243,
		part_id: 166,
		quantity: 407,
	},
	{
		garage_id: 243,
		part_id: 167,
		quantity: 453,
	},
	{
		garage_id: 243,
		part_id: 168,
		quantity: 497,
	},
	{
		garage_id: 243,
		part_id: 169,
		quantity: 257,
	},
	{
		garage_id: 243,
		part_id: 170,
		quantity: 123,
	},
	{
		garage_id: 243,
		part_id: 171,
		quantity: 413,
	},
	{
		garage_id: 243,
		part_id: 172,
		quantity: 168,
	},
	{
		garage_id: 243,
		part_id: 173,
		quantity: 210,
	},
	{
		garage_id: 243,
		part_id: 174,
		quantity: 338,
	},
	{
		garage_id: 243,
		part_id: 175,
		quantity: 236,
	},
	{
		garage_id: 243,
		part_id: 176,
		quantity: 169,
	},
	{
		garage_id: 243,
		part_id: 177,
		quantity: 192,
	},
	{
		garage_id: 243,
		part_id: 178,
		quantity: 306,
	},
	{
		garage_id: 243,
		part_id: 179,
		quantity: 473,
	},
	{
		garage_id: 243,
		part_id: 180,
		quantity: 378,
	},
	{
		garage_id: 243,
		part_id: 181,
		quantity: 141,
	},
	{
		garage_id: 243,
		part_id: 182,
		quantity: 183,
	},
	{
		garage_id: 243,
		part_id: 183,
		quantity: 78,
	},
	{
		garage_id: 243,
		part_id: 184,
		quantity: 494,
	},
	{
		garage_id: 243,
		part_id: 185,
		quantity: 52,
	},
	{
		garage_id: 243,
		part_id: 186,
		quantity: 8,
	},
	{
		garage_id: 243,
		part_id: 187,
		quantity: 393,
	},
	{
		garage_id: 243,
		part_id: 188,
		quantity: 360,
	},
	{
		garage_id: 243,
		part_id: 189,
		quantity: 258,
	},
	{
		garage_id: 243,
		part_id: 190,
		quantity: 23,
	},
	{
		garage_id: 243,
		part_id: 191,
		quantity: 181,
	},
	{
		garage_id: 243,
		part_id: 192,
		quantity: 336,
	},
	{
		garage_id: 243,
		part_id: 193,
		quantity: 444,
	},
	{
		garage_id: 243,
		part_id: 194,
		quantity: 138,
	},
	{
		garage_id: 243,
		part_id: 195,
		quantity: 65,
	},
	{
		garage_id: 243,
		part_id: 196,
		quantity: 391,
	},
	{
		garage_id: 243,
		part_id: 197,
		quantity: 359,
	},
	{
		garage_id: 243,
		part_id: 198,
		quantity: 415,
	},
	{
		garage_id: 243,
		part_id: 199,
		quantity: 252,
	},
	{
		garage_id: 243,
		part_id: 200,
		quantity: 238,
	},
	{
		garage_id: 243,
		part_id: 201,
		quantity: 8,
	},
	{
		garage_id: 243,
		part_id: 202,
		quantity: 395,
	},
	{
		garage_id: 243,
		part_id: 203,
		quantity: 128,
	},
	{
		garage_id: 243,
		part_id: 204,
		quantity: 459,
	},
	{
		garage_id: 243,
		part_id: 205,
		quantity: 186,
	},
	{
		garage_id: 243,
		part_id: 206,
		quantity: 315,
	},
	{
		garage_id: 243,
		part_id: 207,
		quantity: 222,
	},
	{
		garage_id: 243,
		part_id: 208,
		quantity: 245,
	},
	{
		garage_id: 243,
		part_id: 209,
		quantity: 187,
	},
	{
		garage_id: 243,
		part_id: 210,
		quantity: 61,
	},
	{
		garage_id: 243,
		part_id: 211,
		quantity: 363,
	},
	{
		garage_id: 243,
		part_id: 212,
		quantity: 197,
	},
	{
		garage_id: 243,
		part_id: 213,
		quantity: 18,
	},
	{
		garage_id: 243,
		part_id: 214,
		quantity: 26,
	},
	{
		garage_id: 243,
		part_id: 215,
		quantity: 498,
	},
	{
		garage_id: 243,
		part_id: 216,
		quantity: 131,
	},
	{
		garage_id: 243,
		part_id: 217,
		quantity: 343,
	},
	{
		garage_id: 243,
		part_id: 218,
		quantity: 172,
	},
	{
		garage_id: 243,
		part_id: 219,
		quantity: 299,
	},
	{
		garage_id: 243,
		part_id: 220,
		quantity: 407,
	},
	{
		garage_id: 243,
		part_id: 221,
		quantity: 167,
	},
	{
		garage_id: 243,
		part_id: 222,
		quantity: 463,
	},
	{
		garage_id: 243,
		part_id: 223,
		quantity: 385,
	},
	{
		garage_id: 243,
		part_id: 224,
		quantity: 88,
	},
	{
		garage_id: 243,
		part_id: 225,
		quantity: 239,
	},
	{
		garage_id: 243,
		part_id: 226,
		quantity: 61,
	},
	{
		garage_id: 243,
		part_id: 227,
		quantity: 376,
	},
	{
		garage_id: 243,
		part_id: 228,
		quantity: 23,
	},
	{
		garage_id: 243,
		part_id: 229,
		quantity: 177,
	},
	{
		garage_id: 243,
		part_id: 230,
		quantity: 454,
	},
	{
		garage_id: 243,
		part_id: 231,
		quantity: 0,
	},
	{
		garage_id: 243,
		part_id: 232,
		quantity: 332,
	},
	{
		garage_id: 243,
		part_id: 233,
		quantity: 26,
	},
	{
		garage_id: 243,
		part_id: 234,
		quantity: 73,
	},
	{
		garage_id: 243,
		part_id: 235,
		quantity: 221,
	},
	{
		garage_id: 243,
		part_id: 236,
		quantity: 344,
	},
	{
		garage_id: 243,
		part_id: 237,
		quantity: 170,
	},
	{
		garage_id: 243,
		part_id: 238,
		quantity: 432,
	},
	{
		garage_id: 243,
		part_id: 239,
		quantity: 292,
	},
	{
		garage_id: 243,
		part_id: 240,
		quantity: 418,
	},
	{
		garage_id: 243,
		part_id: 241,
		quantity: 136,
	},
	{
		garage_id: 243,
		part_id: 242,
		quantity: 442,
	},
	{
		garage_id: 243,
		part_id: 243,
		quantity: 297,
	},
	{
		garage_id: 243,
		part_id: 244,
		quantity: 477,
	},
	{
		garage_id: 243,
		part_id: 245,
		quantity: 95,
	},
	{
		garage_id: 243,
		part_id: 246,
		quantity: 197,
	},
	{
		garage_id: 243,
		part_id: 247,
		quantity: 244,
	},
	{
		garage_id: 243,
		part_id: 248,
		quantity: 253,
	},
	{
		garage_id: 243,
		part_id: 249,
		quantity: 330,
	},
	{
		garage_id: 243,
		part_id: 250,
		quantity: 398,
	},
	{
		garage_id: 243,
		part_id: 251,
		quantity: 222,
	},
	{
		garage_id: 243,
		part_id: 252,
		quantity: 83,
	},
	{
		garage_id: 243,
		part_id: 253,
		quantity: 465,
	},
	{
		garage_id: 243,
		part_id: 254,
		quantity: 323,
	},
	{
		garage_id: 243,
		part_id: 255,
		quantity: 196,
	},
	{
		garage_id: 243,
		part_id: 256,
		quantity: 298,
	},
	{
		garage_id: 243,
		part_id: 257,
		quantity: 470,
	},
	{
		garage_id: 243,
		part_id: 258,
		quantity: 444,
	},
	{
		garage_id: 243,
		part_id: 259,
		quantity: 211,
	},
	{
		garage_id: 243,
		part_id: 260,
		quantity: 268,
	},
	{
		garage_id: 243,
		part_id: 261,
		quantity: 86,
	},
	{
		garage_id: 243,
		part_id: 262,
		quantity: 216,
	},
	{
		garage_id: 243,
		part_id: 263,
		quantity: 297,
	},
	{
		garage_id: 243,
		part_id: 264,
		quantity: 304,
	},
	{
		garage_id: 243,
		part_id: 265,
		quantity: 468,
	},
	{
		garage_id: 243,
		part_id: 266,
		quantity: 243,
	},
	{
		garage_id: 243,
		part_id: 267,
		quantity: 206,
	},
	{
		garage_id: 243,
		part_id: 268,
		quantity: 437,
	},
	{
		garage_id: 243,
		part_id: 269,
		quantity: 201,
	},
	{
		garage_id: 243,
		part_id: 270,
		quantity: 499,
	},
	{
		garage_id: 243,
		part_id: 271,
		quantity: 404,
	},
	{
		garage_id: 243,
		part_id: 272,
		quantity: 267,
	},
	{
		garage_id: 243,
		part_id: 273,
		quantity: 127,
	},
	{
		garage_id: 243,
		part_id: 274,
		quantity: 407,
	},
	{
		garage_id: 243,
		part_id: 275,
		quantity: 420,
	},
	{
		garage_id: 243,
		part_id: 276,
		quantity: 152,
	},
	{
		garage_id: 243,
		part_id: 277,
		quantity: 398,
	},
	{
		garage_id: 243,
		part_id: 278,
		quantity: 359,
	},
	{
		garage_id: 243,
		part_id: 279,
		quantity: 179,
	},
	{
		garage_id: 243,
		part_id: 280,
		quantity: 468,
	},
	{
		garage_id: 243,
		part_id: 281,
		quantity: 170,
	},
	{
		garage_id: 243,
		part_id: 282,
		quantity: 421,
	},
	{
		garage_id: 243,
		part_id: 283,
		quantity: 490,
	},
	{
		garage_id: 243,
		part_id: 284,
		quantity: 380,
	},
	{
		garage_id: 243,
		part_id: 285,
		quantity: 324,
	},
	{
		garage_id: 243,
		part_id: 286,
		quantity: 179,
	},
	{
		garage_id: 243,
		part_id: 287,
		quantity: 337,
	},
	{
		garage_id: 243,
		part_id: 288,
		quantity: 226,
	},
	{
		garage_id: 243,
		part_id: 289,
		quantity: 293,
	},
	{
		garage_id: 243,
		part_id: 290,
		quantity: 117,
	},
	{
		garage_id: 243,
		part_id: 291,
		quantity: 173,
	},
	{
		garage_id: 243,
		part_id: 292,
		quantity: 257,
	},
	{
		garage_id: 243,
		part_id: 293,
		quantity: 395,
	},
	{
		garage_id: 243,
		part_id: 294,
		quantity: 329,
	},
	{
		garage_id: 243,
		part_id: 295,
		quantity: 183,
	},
	{
		garage_id: 243,
		part_id: 296,
		quantity: 497,
	},
	{
		garage_id: 243,
		part_id: 297,
		quantity: 72,
	},
	{
		garage_id: 243,
		part_id: 298,
		quantity: 473,
	},
	{
		garage_id: 243,
		part_id: 299,
		quantity: 30,
	},
	{
		garage_id: 243,
		part_id: 300,
		quantity: 117,
	},
	{
		garage_id: 243,
		part_id: 301,
		quantity: 181,
	},
	{
		garage_id: 243,
		part_id: 302,
		quantity: 178,
	},
	{
		garage_id: 243,
		part_id: 303,
		quantity: 297,
	},
	{
		garage_id: 243,
		part_id: 304,
		quantity: 89,
	},
	{
		garage_id: 243,
		part_id: 305,
		quantity: 159,
	},
	{
		garage_id: 243,
		part_id: 306,
		quantity: 430,
	},
	{
		garage_id: 243,
		part_id: 307,
		quantity: 419,
	},
	{
		garage_id: 243,
		part_id: 308,
		quantity: 366,
	},
	{
		garage_id: 243,
		part_id: 309,
		quantity: 287,
	},
	{
		garage_id: 243,
		part_id: 310,
		quantity: 445,
	},
	{
		garage_id: 243,
		part_id: 311,
		quantity: 319,
	},
	{
		garage_id: 243,
		part_id: 312,
		quantity: 486,
	},
	{
		garage_id: 243,
		part_id: 313,
		quantity: 9,
	},
	{
		garage_id: 243,
		part_id: 314,
		quantity: 97,
	},
	{
		garage_id: 243,
		part_id: 315,
		quantity: 144,
	},
	{
		garage_id: 243,
		part_id: 316,
		quantity: 468,
	},
	{
		garage_id: 243,
		part_id: 317,
		quantity: 14,
	},
	{
		garage_id: 243,
		part_id: 318,
		quantity: 494,
	},
	{
		garage_id: 243,
		part_id: 319,
		quantity: 24,
	},
	{
		garage_id: 243,
		part_id: 320,
		quantity: 248,
	},
	{
		garage_id: 243,
		part_id: 321,
		quantity: 384,
	},
	{
		garage_id: 243,
		part_id: 322,
		quantity: 410,
	},
	{
		garage_id: 243,
		part_id: 323,
		quantity: 137,
	},
	{
		garage_id: 243,
		part_id: 324,
		quantity: 144,
	},
	{
		garage_id: 243,
		part_id: 325,
		quantity: 20,
	},
	{
		garage_id: 243,
		part_id: 326,
		quantity: 9,
	},
	{
		garage_id: 243,
		part_id: 327,
		quantity: 338,
	},
	{
		garage_id: 243,
		part_id: 328,
		quantity: 20,
	},
	{
		garage_id: 243,
		part_id: 329,
		quantity: 494,
	},
	{
		garage_id: 243,
		part_id: 330,
		quantity: 435,
	},
	{
		garage_id: 243,
		part_id: 331,
		quantity: 213,
	},
	{
		garage_id: 243,
		part_id: 332,
		quantity: 255,
	},
	{
		garage_id: 243,
		part_id: 333,
		quantity: 92,
	},
	{
		garage_id: 243,
		part_id: 334,
		quantity: 284,
	},
	{
		garage_id: 243,
		part_id: 335,
		quantity: 193,
	},
	{
		garage_id: 243,
		part_id: 336,
		quantity: 413,
	},
	{
		garage_id: 243,
		part_id: 337,
		quantity: 163,
	},
	{
		garage_id: 243,
		part_id: 338,
		quantity: 143,
	},
	{
		garage_id: 243,
		part_id: 339,
		quantity: 251,
	},
	{
		garage_id: 243,
		part_id: 340,
		quantity: 314,
	},
	{
		garage_id: 243,
		part_id: 341,
		quantity: 6,
	},
	{
		garage_id: 243,
		part_id: 342,
		quantity: 292,
	},
	{
		garage_id: 243,
		part_id: 343,
		quantity: 182,
	},
	{
		garage_id: 243,
		part_id: 344,
		quantity: 203,
	},
	{
		garage_id: 243,
		part_id: 345,
		quantity: 153,
	},
	{
		garage_id: 243,
		part_id: 346,
		quantity: 290,
	},
	{
		garage_id: 243,
		part_id: 347,
		quantity: 123,
	},
	{
		garage_id: 243,
		part_id: 348,
		quantity: 356,
	},
	{
		garage_id: 243,
		part_id: 349,
		quantity: 366,
	},
	{
		garage_id: 243,
		part_id: 350,
		quantity: 59,
	},
	{
		garage_id: 243,
		part_id: 351,
		quantity: 466,
	},
	{
		garage_id: 243,
		part_id: 352,
		quantity: 382,
	},
	{
		garage_id: 243,
		part_id: 353,
		quantity: 411,
	},
	{
		garage_id: 243,
		part_id: 354,
		quantity: 397,
	},
	{
		garage_id: 243,
		part_id: 355,
		quantity: 301,
	},
	{
		garage_id: 243,
		part_id: 356,
		quantity: 8,
	},
	{
		garage_id: 243,
		part_id: 357,
		quantity: 158,
	},
	{
		garage_id: 243,
		part_id: 358,
		quantity: 156,
	},
	{
		garage_id: 243,
		part_id: 359,
		quantity: 294,
	},
	{
		garage_id: 243,
		part_id: 360,
		quantity: 153,
	},
	{
		garage_id: 243,
		part_id: 361,
		quantity: 320,
	},
	{
		garage_id: 243,
		part_id: 362,
		quantity: 486,
	},
	{
		garage_id: 243,
		part_id: 363,
		quantity: 18,
	},
	{
		garage_id: 243,
		part_id: 364,
		quantity: 175,
	},
	{
		garage_id: 243,
		part_id: 365,
		quantity: 189,
	},
	{
		garage_id: 243,
		part_id: 366,
		quantity: 23,
	},
	{
		garage_id: 243,
		part_id: 367,
		quantity: 17,
	},
	{
		garage_id: 243,
		part_id: 368,
		quantity: 32,
	},
	{
		garage_id: 243,
		part_id: 369,
		quantity: 19,
	},
	{
		garage_id: 243,
		part_id: 370,
		quantity: 96,
	},
	{
		garage_id: 243,
		part_id: 371,
		quantity: 496,
	},
	{
		garage_id: 243,
		part_id: 372,
		quantity: 392,
	},
	{
		garage_id: 243,
		part_id: 373,
		quantity: 321,
	},
	{
		garage_id: 243,
		part_id: 374,
		quantity: 324,
	},
	{
		garage_id: 243,
		part_id: 375,
		quantity: 144,
	},
	{
		garage_id: 243,
		part_id: 376,
		quantity: 354,
	},
	{
		garage_id: 243,
		part_id: 377,
		quantity: 113,
	},
	{
		garage_id: 243,
		part_id: 378,
		quantity: 305,
	},
	{
		garage_id: 243,
		part_id: 379,
		quantity: 335,
	},
	{
		garage_id: 243,
		part_id: 380,
		quantity: 116,
	},
	{
		garage_id: 243,
		part_id: 381,
		quantity: 35,
	},
	{
		garage_id: 243,
		part_id: 382,
		quantity: 156,
	},
	{
		garage_id: 243,
		part_id: 383,
		quantity: 58,
	},
	{
		garage_id: 243,
		part_id: 384,
		quantity: 161,
	},
	{
		garage_id: 243,
		part_id: 385,
		quantity: 403,
	},
	{
		garage_id: 243,
		part_id: 386,
		quantity: 298,
	},
	{
		garage_id: 243,
		part_id: 387,
		quantity: 370,
	},
	{
		garage_id: 243,
		part_id: 388,
		quantity: 412,
	},
	{
		garage_id: 243,
		part_id: 389,
		quantity: 262,
	},
	{
		garage_id: 243,
		part_id: 390,
		quantity: 404,
	},
	{
		garage_id: 243,
		part_id: 391,
		quantity: 52,
	},
	{
		garage_id: 243,
		part_id: 392,
		quantity: 17,
	},
	{
		garage_id: 243,
		part_id: 393,
		quantity: 354,
	},
	{
		garage_id: 243,
		part_id: 394,
		quantity: 343,
	},
	{
		garage_id: 243,
		part_id: 395,
		quantity: 151,
	},
	{
		garage_id: 243,
		part_id: 396,
		quantity: 432,
	},
	{
		garage_id: 243,
		part_id: 397,
		quantity: 63,
	},
	{
		garage_id: 243,
		part_id: 398,
		quantity: 377,
	},
	{
		garage_id: 243,
		part_id: 399,
		quantity: 467,
	},
	{
		garage_id: 244,
		part_id: 0,
		quantity: 101,
	},
	{
		garage_id: 244,
		part_id: 1,
		quantity: 152,
	},
	{
		garage_id: 244,
		part_id: 2,
		quantity: 131,
	},
	{
		garage_id: 244,
		part_id: 3,
		quantity: 234,
	},
	{
		garage_id: 244,
		part_id: 4,
		quantity: 132,
	},
	{
		garage_id: 244,
		part_id: 5,
		quantity: 198,
	},
	{
		garage_id: 244,
		part_id: 6,
		quantity: 326,
	},
	{
		garage_id: 244,
		part_id: 7,
		quantity: 269,
	},
	{
		garage_id: 244,
		part_id: 8,
		quantity: 480,
	},
	{
		garage_id: 244,
		part_id: 9,
		quantity: 101,
	},
	{
		garage_id: 244,
		part_id: 10,
		quantity: 498,
	},
	{
		garage_id: 244,
		part_id: 11,
		quantity: 97,
	},
	{
		garage_id: 244,
		part_id: 12,
		quantity: 83,
	},
	{
		garage_id: 244,
		part_id: 13,
		quantity: 317,
	},
	{
		garage_id: 244,
		part_id: 14,
		quantity: 413,
	},
	{
		garage_id: 244,
		part_id: 15,
		quantity: 464,
	},
	{
		garage_id: 244,
		part_id: 16,
		quantity: 269,
	},
	{
		garage_id: 244,
		part_id: 17,
		quantity: 284,
	},
	{
		garage_id: 244,
		part_id: 18,
		quantity: 225,
	},
	{
		garage_id: 244,
		part_id: 19,
		quantity: 248,
	},
	{
		garage_id: 244,
		part_id: 20,
		quantity: 217,
	},
	{
		garage_id: 244,
		part_id: 21,
		quantity: 168,
	},
	{
		garage_id: 244,
		part_id: 22,
		quantity: 137,
	},
	{
		garage_id: 244,
		part_id: 23,
		quantity: 65,
	},
	{
		garage_id: 244,
		part_id: 24,
		quantity: 250,
	},
	{
		garage_id: 244,
		part_id: 25,
		quantity: 478,
	},
	{
		garage_id: 244,
		part_id: 26,
		quantity: 275,
	},
	{
		garage_id: 244,
		part_id: 27,
		quantity: 464,
	},
	{
		garage_id: 244,
		part_id: 28,
		quantity: 190,
	},
	{
		garage_id: 244,
		part_id: 29,
		quantity: 190,
	},
	{
		garage_id: 244,
		part_id: 30,
		quantity: 474,
	},
	{
		garage_id: 244,
		part_id: 31,
		quantity: 389,
	},
	{
		garage_id: 244,
		part_id: 32,
		quantity: 468,
	},
	{
		garage_id: 244,
		part_id: 33,
		quantity: 357,
	},
	{
		garage_id: 244,
		part_id: 34,
		quantity: 440,
	},
	{
		garage_id: 244,
		part_id: 35,
		quantity: 63,
	},
	{
		garage_id: 244,
		part_id: 36,
		quantity: 499,
	},
	{
		garage_id: 244,
		part_id: 37,
		quantity: 333,
	},
	{
		garage_id: 244,
		part_id: 38,
		quantity: 119,
	},
	{
		garage_id: 244,
		part_id: 39,
		quantity: 336,
	},
	{
		garage_id: 244,
		part_id: 40,
		quantity: 118,
	},
	{
		garage_id: 244,
		part_id: 41,
		quantity: 432,
	},
	{
		garage_id: 244,
		part_id: 42,
		quantity: 426,
	},
	{
		garage_id: 244,
		part_id: 43,
		quantity: 157,
	},
	{
		garage_id: 244,
		part_id: 44,
		quantity: 90,
	},
	{
		garage_id: 244,
		part_id: 45,
		quantity: 97,
	},
	{
		garage_id: 244,
		part_id: 46,
		quantity: 274,
	},
	{
		garage_id: 244,
		part_id: 47,
		quantity: 335,
	},
	{
		garage_id: 244,
		part_id: 48,
		quantity: 462,
	},
	{
		garage_id: 244,
		part_id: 49,
		quantity: 168,
	},
	{
		garage_id: 244,
		part_id: 50,
		quantity: 320,
	},
	{
		garage_id: 244,
		part_id: 51,
		quantity: 363,
	},
	{
		garage_id: 244,
		part_id: 52,
		quantity: 106,
	},
	{
		garage_id: 244,
		part_id: 53,
		quantity: 360,
	},
	{
		garage_id: 244,
		part_id: 54,
		quantity: 46,
	},
	{
		garage_id: 244,
		part_id: 55,
		quantity: 42,
	},
	{
		garage_id: 244,
		part_id: 56,
		quantity: 475,
	},
	{
		garage_id: 244,
		part_id: 57,
		quantity: 143,
	},
	{
		garage_id: 244,
		part_id: 58,
		quantity: 327,
	},
	{
		garage_id: 244,
		part_id: 59,
		quantity: 32,
	},
	{
		garage_id: 244,
		part_id: 60,
		quantity: 31,
	},
	{
		garage_id: 244,
		part_id: 61,
		quantity: 421,
	},
	{
		garage_id: 244,
		part_id: 62,
		quantity: 5,
	},
	{
		garage_id: 244,
		part_id: 63,
		quantity: 202,
	},
	{
		garage_id: 244,
		part_id: 64,
		quantity: 109,
	},
	{
		garage_id: 244,
		part_id: 65,
		quantity: 277,
	},
	{
		garage_id: 244,
		part_id: 66,
		quantity: 80,
	},
	{
		garage_id: 244,
		part_id: 67,
		quantity: 287,
	},
	{
		garage_id: 244,
		part_id: 68,
		quantity: 377,
	},
	{
		garage_id: 244,
		part_id: 69,
		quantity: 42,
	},
	{
		garage_id: 244,
		part_id: 70,
		quantity: 274,
	},
	{
		garage_id: 244,
		part_id: 71,
		quantity: 32,
	},
	{
		garage_id: 244,
		part_id: 72,
		quantity: 306,
	},
	{
		garage_id: 244,
		part_id: 73,
		quantity: 363,
	},
	{
		garage_id: 244,
		part_id: 74,
		quantity: 420,
	},
	{
		garage_id: 244,
		part_id: 75,
		quantity: 392,
	},
	{
		garage_id: 244,
		part_id: 76,
		quantity: 485,
	},
	{
		garage_id: 244,
		part_id: 77,
		quantity: 123,
	},
	{
		garage_id: 244,
		part_id: 78,
		quantity: 203,
	},
	{
		garage_id: 244,
		part_id: 79,
		quantity: 410,
	},
	{
		garage_id: 244,
		part_id: 80,
		quantity: 102,
	},
	{
		garage_id: 244,
		part_id: 81,
		quantity: 346,
	},
	{
		garage_id: 244,
		part_id: 82,
		quantity: 177,
	},
	{
		garage_id: 244,
		part_id: 83,
		quantity: 277,
	},
	{
		garage_id: 244,
		part_id: 84,
		quantity: 390,
	},
	{
		garage_id: 244,
		part_id: 85,
		quantity: 198,
	},
	{
		garage_id: 244,
		part_id: 86,
		quantity: 432,
	},
	{
		garage_id: 244,
		part_id: 87,
		quantity: 54,
	},
	{
		garage_id: 244,
		part_id: 88,
		quantity: 86,
	},
	{
		garage_id: 244,
		part_id: 89,
		quantity: 331,
	},
	{
		garage_id: 244,
		part_id: 90,
		quantity: 326,
	},
	{
		garage_id: 244,
		part_id: 91,
		quantity: 198,
	},
	{
		garage_id: 244,
		part_id: 92,
		quantity: 403,
	},
	{
		garage_id: 244,
		part_id: 93,
		quantity: 405,
	},
	{
		garage_id: 244,
		part_id: 94,
		quantity: 215,
	},
	{
		garage_id: 244,
		part_id: 95,
		quantity: 277,
	},
	{
		garage_id: 244,
		part_id: 96,
		quantity: 41,
	},
	{
		garage_id: 244,
		part_id: 97,
		quantity: 400,
	},
	{
		garage_id: 244,
		part_id: 98,
		quantity: 202,
	},
	{
		garage_id: 244,
		part_id: 99,
		quantity: 240,
	},
	{
		garage_id: 244,
		part_id: 100,
		quantity: 262,
	},
	{
		garage_id: 244,
		part_id: 101,
		quantity: 6,
	},
	{
		garage_id: 244,
		part_id: 102,
		quantity: 141,
	},
	{
		garage_id: 244,
		part_id: 103,
		quantity: 493,
	},
	{
		garage_id: 244,
		part_id: 104,
		quantity: 148,
	},
	{
		garage_id: 244,
		part_id: 105,
		quantity: 213,
	},
	{
		garage_id: 244,
		part_id: 106,
		quantity: 110,
	},
	{
		garage_id: 244,
		part_id: 107,
		quantity: 447,
	},
	{
		garage_id: 244,
		part_id: 108,
		quantity: 159,
	},
	{
		garage_id: 244,
		part_id: 109,
		quantity: 20,
	},
	{
		garage_id: 244,
		part_id: 110,
		quantity: 465,
	},
	{
		garage_id: 244,
		part_id: 111,
		quantity: 232,
	},
	{
		garage_id: 244,
		part_id: 112,
		quantity: 43,
	},
	{
		garage_id: 244,
		part_id: 113,
		quantity: 142,
	},
	{
		garage_id: 244,
		part_id: 114,
		quantity: 43,
	},
	{
		garage_id: 244,
		part_id: 115,
		quantity: 484,
	},
	{
		garage_id: 244,
		part_id: 116,
		quantity: 475,
	},
	{
		garage_id: 244,
		part_id: 117,
		quantity: 389,
	},
	{
		garage_id: 244,
		part_id: 118,
		quantity: 4,
	},
	{
		garage_id: 244,
		part_id: 119,
		quantity: 34,
	},
	{
		garage_id: 244,
		part_id: 120,
		quantity: 144,
	},
	{
		garage_id: 244,
		part_id: 121,
		quantity: 395,
	},
	{
		garage_id: 244,
		part_id: 122,
		quantity: 500,
	},
	{
		garage_id: 244,
		part_id: 123,
		quantity: 311,
	},
	{
		garage_id: 244,
		part_id: 124,
		quantity: 247,
	},
	{
		garage_id: 244,
		part_id: 125,
		quantity: 493,
	},
	{
		garage_id: 244,
		part_id: 126,
		quantity: 146,
	},
	{
		garage_id: 244,
		part_id: 127,
		quantity: 281,
	},
	{
		garage_id: 244,
		part_id: 128,
		quantity: 477,
	},
	{
		garage_id: 244,
		part_id: 129,
		quantity: 293,
	},
	{
		garage_id: 244,
		part_id: 130,
		quantity: 32,
	},
	{
		garage_id: 244,
		part_id: 131,
		quantity: 206,
	},
	{
		garage_id: 244,
		part_id: 132,
		quantity: 315,
	},
	{
		garage_id: 244,
		part_id: 133,
		quantity: 381,
	},
	{
		garage_id: 244,
		part_id: 134,
		quantity: 364,
	},
	{
		garage_id: 244,
		part_id: 135,
		quantity: 448,
	},
	{
		garage_id: 244,
		part_id: 136,
		quantity: 235,
	},
	{
		garage_id: 244,
		part_id: 137,
		quantity: 203,
	},
	{
		garage_id: 244,
		part_id: 138,
		quantity: 30,
	},
	{
		garage_id: 244,
		part_id: 139,
		quantity: 343,
	},
	{
		garage_id: 244,
		part_id: 140,
		quantity: 372,
	},
	{
		garage_id: 244,
		part_id: 141,
		quantity: 181,
	},
	{
		garage_id: 244,
		part_id: 142,
		quantity: 125,
	},
	{
		garage_id: 244,
		part_id: 143,
		quantity: 339,
	},
	{
		garage_id: 244,
		part_id: 144,
		quantity: 179,
	},
	{
		garage_id: 244,
		part_id: 145,
		quantity: 119,
	},
	{
		garage_id: 244,
		part_id: 146,
		quantity: 305,
	},
	{
		garage_id: 244,
		part_id: 147,
		quantity: 412,
	},
	{
		garage_id: 244,
		part_id: 148,
		quantity: 336,
	},
	{
		garage_id: 244,
		part_id: 149,
		quantity: 461,
	},
	{
		garage_id: 244,
		part_id: 150,
		quantity: 1,
	},
	{
		garage_id: 244,
		part_id: 151,
		quantity: 365,
	},
	{
		garage_id: 244,
		part_id: 152,
		quantity: 304,
	},
	{
		garage_id: 244,
		part_id: 153,
		quantity: 10,
	},
	{
		garage_id: 244,
		part_id: 154,
		quantity: 334,
	},
	{
		garage_id: 244,
		part_id: 155,
		quantity: 367,
	},
	{
		garage_id: 244,
		part_id: 156,
		quantity: 388,
	},
	{
		garage_id: 244,
		part_id: 157,
		quantity: 283,
	},
	{
		garage_id: 244,
		part_id: 158,
		quantity: 436,
	},
	{
		garage_id: 244,
		part_id: 159,
		quantity: 198,
	},
	{
		garage_id: 244,
		part_id: 160,
		quantity: 346,
	},
	{
		garage_id: 244,
		part_id: 161,
		quantity: 43,
	},
	{
		garage_id: 244,
		part_id: 162,
		quantity: 218,
	},
	{
		garage_id: 244,
		part_id: 163,
		quantity: 219,
	},
	{
		garage_id: 244,
		part_id: 164,
		quantity: 377,
	},
	{
		garage_id: 244,
		part_id: 165,
		quantity: 289,
	},
	{
		garage_id: 244,
		part_id: 166,
		quantity: 108,
	},
	{
		garage_id: 244,
		part_id: 167,
		quantity: 454,
	},
	{
		garage_id: 244,
		part_id: 168,
		quantity: 96,
	},
	{
		garage_id: 244,
		part_id: 169,
		quantity: 148,
	},
	{
		garage_id: 244,
		part_id: 170,
		quantity: 455,
	},
	{
		garage_id: 244,
		part_id: 171,
		quantity: 301,
	},
	{
		garage_id: 244,
		part_id: 172,
		quantity: 268,
	},
	{
		garage_id: 244,
		part_id: 173,
		quantity: 458,
	},
	{
		garage_id: 244,
		part_id: 174,
		quantity: 260,
	},
	{
		garage_id: 244,
		part_id: 175,
		quantity: 134,
	},
	{
		garage_id: 244,
		part_id: 176,
		quantity: 386,
	},
	{
		garage_id: 244,
		part_id: 177,
		quantity: 124,
	},
	{
		garage_id: 244,
		part_id: 178,
		quantity: 363,
	},
	{
		garage_id: 244,
		part_id: 179,
		quantity: 204,
	},
	{
		garage_id: 244,
		part_id: 180,
		quantity: 269,
	},
	{
		garage_id: 244,
		part_id: 181,
		quantity: 377,
	},
	{
		garage_id: 244,
		part_id: 182,
		quantity: 273,
	},
	{
		garage_id: 244,
		part_id: 183,
		quantity: 247,
	},
	{
		garage_id: 244,
		part_id: 184,
		quantity: 181,
	},
	{
		garage_id: 244,
		part_id: 185,
		quantity: 197,
	},
	{
		garage_id: 244,
		part_id: 186,
		quantity: 328,
	},
	{
		garage_id: 244,
		part_id: 187,
		quantity: 124,
	},
	{
		garage_id: 244,
		part_id: 188,
		quantity: 199,
	},
	{
		garage_id: 244,
		part_id: 189,
		quantity: 446,
	},
	{
		garage_id: 244,
		part_id: 190,
		quantity: 317,
	},
	{
		garage_id: 244,
		part_id: 191,
		quantity: 234,
	},
	{
		garage_id: 244,
		part_id: 192,
		quantity: 349,
	},
	{
		garage_id: 244,
		part_id: 193,
		quantity: 212,
	},
	{
		garage_id: 244,
		part_id: 194,
		quantity: 436,
	},
	{
		garage_id: 244,
		part_id: 195,
		quantity: 139,
	},
	{
		garage_id: 244,
		part_id: 196,
		quantity: 291,
	},
	{
		garage_id: 244,
		part_id: 197,
		quantity: 122,
	},
	{
		garage_id: 244,
		part_id: 198,
		quantity: 452,
	},
	{
		garage_id: 244,
		part_id: 199,
		quantity: 311,
	},
	{
		garage_id: 244,
		part_id: 200,
		quantity: 391,
	},
	{
		garage_id: 244,
		part_id: 201,
		quantity: 307,
	},
	{
		garage_id: 244,
		part_id: 202,
		quantity: 460,
	},
	{
		garage_id: 244,
		part_id: 203,
		quantity: 294,
	},
	{
		garage_id: 244,
		part_id: 204,
		quantity: 193,
	},
	{
		garage_id: 244,
		part_id: 205,
		quantity: 294,
	},
	{
		garage_id: 244,
		part_id: 206,
		quantity: 240,
	},
	{
		garage_id: 244,
		part_id: 207,
		quantity: 284,
	},
	{
		garage_id: 244,
		part_id: 208,
		quantity: 425,
	},
	{
		garage_id: 244,
		part_id: 209,
		quantity: 256,
	},
	{
		garage_id: 244,
		part_id: 210,
		quantity: 471,
	},
	{
		garage_id: 244,
		part_id: 211,
		quantity: 78,
	},
	{
		garage_id: 244,
		part_id: 212,
		quantity: 44,
	},
	{
		garage_id: 244,
		part_id: 213,
		quantity: 479,
	},
	{
		garage_id: 244,
		part_id: 214,
		quantity: 354,
	},
	{
		garage_id: 244,
		part_id: 215,
		quantity: 142,
	},
	{
		garage_id: 244,
		part_id: 216,
		quantity: 124,
	},
	{
		garage_id: 244,
		part_id: 217,
		quantity: 305,
	},
	{
		garage_id: 244,
		part_id: 218,
		quantity: 490,
	},
	{
		garage_id: 244,
		part_id: 219,
		quantity: 61,
	},
	{
		garage_id: 244,
		part_id: 220,
		quantity: 423,
	},
	{
		garage_id: 244,
		part_id: 221,
		quantity: 7,
	},
	{
		garage_id: 244,
		part_id: 222,
		quantity: 105,
	},
	{
		garage_id: 244,
		part_id: 223,
		quantity: 478,
	},
	{
		garage_id: 244,
		part_id: 224,
		quantity: 261,
	},
	{
		garage_id: 244,
		part_id: 225,
		quantity: 314,
	},
	{
		garage_id: 244,
		part_id: 226,
		quantity: 19,
	},
	{
		garage_id: 244,
		part_id: 227,
		quantity: 2,
	},
	{
		garage_id: 244,
		part_id: 228,
		quantity: 448,
	},
	{
		garage_id: 244,
		part_id: 229,
		quantity: 467,
	},
	{
		garage_id: 244,
		part_id: 230,
		quantity: 188,
	},
	{
		garage_id: 244,
		part_id: 231,
		quantity: 141,
	},
	{
		garage_id: 244,
		part_id: 232,
		quantity: 158,
	},
	{
		garage_id: 244,
		part_id: 233,
		quantity: 476,
	},
	{
		garage_id: 244,
		part_id: 234,
		quantity: 246,
	},
	{
		garage_id: 244,
		part_id: 235,
		quantity: 446,
	},
	{
		garage_id: 244,
		part_id: 236,
		quantity: 77,
	},
	{
		garage_id: 244,
		part_id: 237,
		quantity: 29,
	},
	{
		garage_id: 244,
		part_id: 238,
		quantity: 336,
	},
	{
		garage_id: 244,
		part_id: 239,
		quantity: 120,
	},
	{
		garage_id: 244,
		part_id: 240,
		quantity: 464,
	},
	{
		garage_id: 244,
		part_id: 241,
		quantity: 245,
	},
	{
		garage_id: 244,
		part_id: 242,
		quantity: 138,
	},
	{
		garage_id: 244,
		part_id: 243,
		quantity: 294,
	},
	{
		garage_id: 244,
		part_id: 244,
		quantity: 333,
	},
	{
		garage_id: 244,
		part_id: 245,
		quantity: 250,
	},
	{
		garage_id: 244,
		part_id: 246,
		quantity: 128,
	},
	{
		garage_id: 244,
		part_id: 247,
		quantity: 494,
	},
	{
		garage_id: 244,
		part_id: 248,
		quantity: 382,
	},
	{
		garage_id: 244,
		part_id: 249,
		quantity: 479,
	},
	{
		garage_id: 244,
		part_id: 250,
		quantity: 484,
	},
	{
		garage_id: 244,
		part_id: 251,
		quantity: 69,
	},
	{
		garage_id: 244,
		part_id: 252,
		quantity: 80,
	},
	{
		garage_id: 244,
		part_id: 253,
		quantity: 488,
	},
	{
		garage_id: 244,
		part_id: 254,
		quantity: 321,
	},
	{
		garage_id: 244,
		part_id: 255,
		quantity: 83,
	},
	{
		garage_id: 244,
		part_id: 256,
		quantity: 95,
	},
	{
		garage_id: 244,
		part_id: 257,
		quantity: 273,
	},
	{
		garage_id: 244,
		part_id: 258,
		quantity: 294,
	},
	{
		garage_id: 244,
		part_id: 259,
		quantity: 87,
	},
	{
		garage_id: 244,
		part_id: 260,
		quantity: 315,
	},
	{
		garage_id: 244,
		part_id: 261,
		quantity: 393,
	},
	{
		garage_id: 244,
		part_id: 262,
		quantity: 293,
	},
	{
		garage_id: 244,
		part_id: 263,
		quantity: 8,
	},
	{
		garage_id: 244,
		part_id: 264,
		quantity: 161,
	},
	{
		garage_id: 244,
		part_id: 265,
		quantity: 433,
	},
	{
		garage_id: 244,
		part_id: 266,
		quantity: 262,
	},
	{
		garage_id: 244,
		part_id: 267,
		quantity: 85,
	},
	{
		garage_id: 244,
		part_id: 268,
		quantity: 285,
	},
	{
		garage_id: 244,
		part_id: 269,
		quantity: 139,
	},
	{
		garage_id: 244,
		part_id: 270,
		quantity: 88,
	},
	{
		garage_id: 244,
		part_id: 271,
		quantity: 415,
	},
	{
		garage_id: 244,
		part_id: 272,
		quantity: 333,
	},
	{
		garage_id: 244,
		part_id: 273,
		quantity: 282,
	},
	{
		garage_id: 244,
		part_id: 274,
		quantity: 387,
	},
	{
		garage_id: 244,
		part_id: 275,
		quantity: 125,
	},
	{
		garage_id: 244,
		part_id: 276,
		quantity: 317,
	},
	{
		garage_id: 244,
		part_id: 277,
		quantity: 22,
	},
	{
		garage_id: 244,
		part_id: 278,
		quantity: 105,
	},
	{
		garage_id: 244,
		part_id: 279,
		quantity: 127,
	},
	{
		garage_id: 244,
		part_id: 280,
		quantity: 152,
	},
	{
		garage_id: 244,
		part_id: 281,
		quantity: 401,
	},
	{
		garage_id: 244,
		part_id: 282,
		quantity: 214,
	},
	{
		garage_id: 244,
		part_id: 283,
		quantity: 218,
	},
	{
		garage_id: 244,
		part_id: 284,
		quantity: 78,
	},
	{
		garage_id: 244,
		part_id: 285,
		quantity: 454,
	},
	{
		garage_id: 244,
		part_id: 286,
		quantity: 461,
	},
	{
		garage_id: 244,
		part_id: 287,
		quantity: 117,
	},
	{
		garage_id: 244,
		part_id: 288,
		quantity: 271,
	},
	{
		garage_id: 244,
		part_id: 289,
		quantity: 236,
	},
	{
		garage_id: 244,
		part_id: 290,
		quantity: 65,
	},
	{
		garage_id: 244,
		part_id: 291,
		quantity: 416,
	},
	{
		garage_id: 244,
		part_id: 292,
		quantity: 304,
	},
	{
		garage_id: 244,
		part_id: 293,
		quantity: 40,
	},
	{
		garage_id: 244,
		part_id: 294,
		quantity: 15,
	},
	{
		garage_id: 244,
		part_id: 295,
		quantity: 380,
	},
	{
		garage_id: 244,
		part_id: 296,
		quantity: 431,
	},
	{
		garage_id: 244,
		part_id: 297,
		quantity: 189,
	},
	{
		garage_id: 244,
		part_id: 298,
		quantity: 21,
	},
	{
		garage_id: 244,
		part_id: 299,
		quantity: 28,
	},
	{
		garage_id: 244,
		part_id: 300,
		quantity: 373,
	},
	{
		garage_id: 244,
		part_id: 301,
		quantity: 107,
	},
	{
		garage_id: 244,
		part_id: 302,
		quantity: 274,
	},
	{
		garage_id: 244,
		part_id: 303,
		quantity: 470,
	},
	{
		garage_id: 244,
		part_id: 304,
		quantity: 72,
	},
	{
		garage_id: 244,
		part_id: 305,
		quantity: 378,
	},
	{
		garage_id: 244,
		part_id: 306,
		quantity: 235,
	},
	{
		garage_id: 244,
		part_id: 307,
		quantity: 243,
	},
	{
		garage_id: 244,
		part_id: 308,
		quantity: 455,
	},
	{
		garage_id: 244,
		part_id: 309,
		quantity: 354,
	},
	{
		garage_id: 244,
		part_id: 310,
		quantity: 202,
	},
	{
		garage_id: 244,
		part_id: 311,
		quantity: 116,
	},
	{
		garage_id: 244,
		part_id: 312,
		quantity: 488,
	},
	{
		garage_id: 244,
		part_id: 313,
		quantity: 499,
	},
	{
		garage_id: 244,
		part_id: 314,
		quantity: 61,
	},
	{
		garage_id: 244,
		part_id: 315,
		quantity: 489,
	},
	{
		garage_id: 244,
		part_id: 316,
		quantity: 351,
	},
	{
		garage_id: 244,
		part_id: 317,
		quantity: 96,
	},
	{
		garage_id: 244,
		part_id: 318,
		quantity: 118,
	},
	{
		garage_id: 244,
		part_id: 319,
		quantity: 243,
	},
	{
		garage_id: 244,
		part_id: 320,
		quantity: 151,
	},
	{
		garage_id: 244,
		part_id: 321,
		quantity: 470,
	},
	{
		garage_id: 244,
		part_id: 322,
		quantity: 318,
	},
	{
		garage_id: 244,
		part_id: 323,
		quantity: 167,
	},
	{
		garage_id: 244,
		part_id: 324,
		quantity: 194,
	},
	{
		garage_id: 244,
		part_id: 325,
		quantity: 312,
	},
	{
		garage_id: 244,
		part_id: 326,
		quantity: 282,
	},
	{
		garage_id: 244,
		part_id: 327,
		quantity: 207,
	},
	{
		garage_id: 244,
		part_id: 328,
		quantity: 316,
	},
	{
		garage_id: 244,
		part_id: 329,
		quantity: 414,
	},
	{
		garage_id: 244,
		part_id: 330,
		quantity: 229,
	},
	{
		garage_id: 244,
		part_id: 331,
		quantity: 88,
	},
	{
		garage_id: 244,
		part_id: 332,
		quantity: 15,
	},
	{
		garage_id: 244,
		part_id: 333,
		quantity: 21,
	},
	{
		garage_id: 244,
		part_id: 334,
		quantity: 220,
	},
	{
		garage_id: 244,
		part_id: 335,
		quantity: 16,
	},
	{
		garage_id: 244,
		part_id: 336,
		quantity: 394,
	},
	{
		garage_id: 244,
		part_id: 337,
		quantity: 190,
	},
	{
		garage_id: 244,
		part_id: 338,
		quantity: 37,
	},
	{
		garage_id: 244,
		part_id: 339,
		quantity: 326,
	},
	{
		garage_id: 244,
		part_id: 340,
		quantity: 239,
	},
	{
		garage_id: 244,
		part_id: 341,
		quantity: 422,
	},
	{
		garage_id: 244,
		part_id: 342,
		quantity: 75,
	},
	{
		garage_id: 244,
		part_id: 343,
		quantity: 239,
	},
	{
		garage_id: 244,
		part_id: 344,
		quantity: 275,
	},
	{
		garage_id: 244,
		part_id: 345,
		quantity: 325,
	},
	{
		garage_id: 244,
		part_id: 346,
		quantity: 341,
	},
	{
		garage_id: 244,
		part_id: 347,
		quantity: 7,
	},
	{
		garage_id: 244,
		part_id: 348,
		quantity: 380,
	},
	{
		garage_id: 244,
		part_id: 349,
		quantity: 134,
	},
	{
		garage_id: 244,
		part_id: 350,
		quantity: 81,
	},
	{
		garage_id: 244,
		part_id: 351,
		quantity: 311,
	},
	{
		garage_id: 244,
		part_id: 352,
		quantity: 91,
	},
	{
		garage_id: 244,
		part_id: 353,
		quantity: 115,
	},
	{
		garage_id: 244,
		part_id: 354,
		quantity: 189,
	},
	{
		garage_id: 244,
		part_id: 355,
		quantity: 32,
	},
	{
		garage_id: 244,
		part_id: 356,
		quantity: 359,
	},
	{
		garage_id: 244,
		part_id: 357,
		quantity: 147,
	},
	{
		garage_id: 244,
		part_id: 358,
		quantity: 386,
	},
	{
		garage_id: 244,
		part_id: 359,
		quantity: 148,
	},
	{
		garage_id: 244,
		part_id: 360,
		quantity: 116,
	},
	{
		garage_id: 244,
		part_id: 361,
		quantity: 145,
	},
	{
		garage_id: 244,
		part_id: 362,
		quantity: 114,
	},
	{
		garage_id: 244,
		part_id: 363,
		quantity: 266,
	},
	{
		garage_id: 244,
		part_id: 364,
		quantity: 76,
	},
	{
		garage_id: 244,
		part_id: 365,
		quantity: 414,
	},
	{
		garage_id: 244,
		part_id: 366,
		quantity: 237,
	},
	{
		garage_id: 244,
		part_id: 367,
		quantity: 376,
	},
	{
		garage_id: 244,
		part_id: 368,
		quantity: 382,
	},
	{
		garage_id: 244,
		part_id: 369,
		quantity: 2,
	},
	{
		garage_id: 244,
		part_id: 370,
		quantity: 244,
	},
	{
		garage_id: 244,
		part_id: 371,
		quantity: 213,
	},
	{
		garage_id: 244,
		part_id: 372,
		quantity: 382,
	},
	{
		garage_id: 244,
		part_id: 373,
		quantity: 29,
	},
	{
		garage_id: 244,
		part_id: 374,
		quantity: 437,
	},
	{
		garage_id: 244,
		part_id: 375,
		quantity: 138,
	},
	{
		garage_id: 244,
		part_id: 376,
		quantity: 475,
	},
	{
		garage_id: 244,
		part_id: 377,
		quantity: 267,
	},
	{
		garage_id: 244,
		part_id: 378,
		quantity: 107,
	},
	{
		garage_id: 244,
		part_id: 379,
		quantity: 215,
	},
	{
		garage_id: 244,
		part_id: 380,
		quantity: 93,
	},
	{
		garage_id: 244,
		part_id: 381,
		quantity: 121,
	},
	{
		garage_id: 244,
		part_id: 382,
		quantity: 22,
	},
	{
		garage_id: 244,
		part_id: 383,
		quantity: 351,
	},
	{
		garage_id: 244,
		part_id: 384,
		quantity: 291,
	},
	{
		garage_id: 244,
		part_id: 385,
		quantity: 242,
	},
	{
		garage_id: 244,
		part_id: 386,
		quantity: 108,
	},
	{
		garage_id: 244,
		part_id: 387,
		quantity: 467,
	},
	{
		garage_id: 244,
		part_id: 388,
		quantity: 304,
	},
	{
		garage_id: 244,
		part_id: 389,
		quantity: 225,
	},
	{
		garage_id: 244,
		part_id: 390,
		quantity: 262,
	},
	{
		garage_id: 244,
		part_id: 391,
		quantity: 435,
	},
	{
		garage_id: 244,
		part_id: 392,
		quantity: 189,
	},
	{
		garage_id: 244,
		part_id: 393,
		quantity: 145,
	},
	{
		garage_id: 244,
		part_id: 394,
		quantity: 99,
	},
	{
		garage_id: 244,
		part_id: 395,
		quantity: 85,
	},
	{
		garage_id: 244,
		part_id: 396,
		quantity: 208,
	},
	{
		garage_id: 244,
		part_id: 397,
		quantity: 311,
	},
	{
		garage_id: 244,
		part_id: 398,
		quantity: 350,
	},
	{
		garage_id: 244,
		part_id: 399,
		quantity: 9,
	},
	{
		garage_id: 245,
		part_id: 0,
		quantity: 481,
	},
	{
		garage_id: 245,
		part_id: 1,
		quantity: 363,
	},
	{
		garage_id: 245,
		part_id: 2,
		quantity: 69,
	},
	{
		garage_id: 245,
		part_id: 3,
		quantity: 490,
	},
	{
		garage_id: 245,
		part_id: 4,
		quantity: 403,
	},
	{
		garage_id: 245,
		part_id: 5,
		quantity: 479,
	},
	{
		garage_id: 245,
		part_id: 6,
		quantity: 129,
	},
	{
		garage_id: 245,
		part_id: 7,
		quantity: 470,
	},
	{
		garage_id: 245,
		part_id: 8,
		quantity: 186,
	},
	{
		garage_id: 245,
		part_id: 9,
		quantity: 310,
	},
	{
		garage_id: 245,
		part_id: 10,
		quantity: 41,
	},
	{
		garage_id: 245,
		part_id: 11,
		quantity: 33,
	},
	{
		garage_id: 245,
		part_id: 12,
		quantity: 162,
	},
	{
		garage_id: 245,
		part_id: 13,
		quantity: 0,
	},
	{
		garage_id: 245,
		part_id: 14,
		quantity: 495,
	},
	{
		garage_id: 245,
		part_id: 15,
		quantity: 454,
	},
	{
		garage_id: 245,
		part_id: 16,
		quantity: 100,
	},
	{
		garage_id: 245,
		part_id: 17,
		quantity: 321,
	},
	{
		garage_id: 245,
		part_id: 18,
		quantity: 127,
	},
	{
		garage_id: 245,
		part_id: 19,
		quantity: 176,
	},
	{
		garage_id: 245,
		part_id: 20,
		quantity: 173,
	},
	{
		garage_id: 245,
		part_id: 21,
		quantity: 137,
	},
	{
		garage_id: 245,
		part_id: 22,
		quantity: 252,
	},
	{
		garage_id: 245,
		part_id: 23,
		quantity: 273,
	},
	{
		garage_id: 245,
		part_id: 24,
		quantity: 62,
	},
	{
		garage_id: 245,
		part_id: 25,
		quantity: 286,
	},
	{
		garage_id: 245,
		part_id: 26,
		quantity: 277,
	},
	{
		garage_id: 245,
		part_id: 27,
		quantity: 84,
	},
	{
		garage_id: 245,
		part_id: 28,
		quantity: 335,
	},
	{
		garage_id: 245,
		part_id: 29,
		quantity: 454,
	},
	{
		garage_id: 245,
		part_id: 30,
		quantity: 175,
	},
	{
		garage_id: 245,
		part_id: 31,
		quantity: 479,
	},
	{
		garage_id: 245,
		part_id: 32,
		quantity: 219,
	},
	{
		garage_id: 245,
		part_id: 33,
		quantity: 76,
	},
	{
		garage_id: 245,
		part_id: 34,
		quantity: 435,
	},
	{
		garage_id: 245,
		part_id: 35,
		quantity: 182,
	},
	{
		garage_id: 245,
		part_id: 36,
		quantity: 146,
	},
	{
		garage_id: 245,
		part_id: 37,
		quantity: 20,
	},
	{
		garage_id: 245,
		part_id: 38,
		quantity: 79,
	},
	{
		garage_id: 245,
		part_id: 39,
		quantity: 173,
	},
	{
		garage_id: 245,
		part_id: 40,
		quantity: 421,
	},
	{
		garage_id: 245,
		part_id: 41,
		quantity: 129,
	},
	{
		garage_id: 245,
		part_id: 42,
		quantity: 414,
	},
	{
		garage_id: 245,
		part_id: 43,
		quantity: 278,
	},
	{
		garage_id: 245,
		part_id: 44,
		quantity: 329,
	},
	{
		garage_id: 245,
		part_id: 45,
		quantity: 281,
	},
	{
		garage_id: 245,
		part_id: 46,
		quantity: 113,
	},
	{
		garage_id: 245,
		part_id: 47,
		quantity: 129,
	},
	{
		garage_id: 245,
		part_id: 48,
		quantity: 260,
	},
	{
		garage_id: 245,
		part_id: 49,
		quantity: 201,
	},
	{
		garage_id: 245,
		part_id: 50,
		quantity: 396,
	},
	{
		garage_id: 245,
		part_id: 51,
		quantity: 442,
	},
	{
		garage_id: 245,
		part_id: 52,
		quantity: 484,
	},
	{
		garage_id: 245,
		part_id: 53,
		quantity: 277,
	},
	{
		garage_id: 245,
		part_id: 54,
		quantity: 58,
	},
	{
		garage_id: 245,
		part_id: 55,
		quantity: 72,
	},
	{
		garage_id: 245,
		part_id: 56,
		quantity: 325,
	},
	{
		garage_id: 245,
		part_id: 57,
		quantity: 348,
	},
	{
		garage_id: 245,
		part_id: 58,
		quantity: 470,
	},
	{
		garage_id: 245,
		part_id: 59,
		quantity: 251,
	},
	{
		garage_id: 245,
		part_id: 60,
		quantity: 278,
	},
	{
		garage_id: 245,
		part_id: 61,
		quantity: 8,
	},
	{
		garage_id: 245,
		part_id: 62,
		quantity: 239,
	},
	{
		garage_id: 245,
		part_id: 63,
		quantity: 161,
	},
	{
		garage_id: 245,
		part_id: 64,
		quantity: 160,
	},
	{
		garage_id: 245,
		part_id: 65,
		quantity: 315,
	},
	{
		garage_id: 245,
		part_id: 66,
		quantity: 300,
	},
	{
		garage_id: 245,
		part_id: 67,
		quantity: 305,
	},
	{
		garage_id: 245,
		part_id: 68,
		quantity: 232,
	},
	{
		garage_id: 245,
		part_id: 69,
		quantity: 39,
	},
	{
		garage_id: 245,
		part_id: 70,
		quantity: 210,
	},
	{
		garage_id: 245,
		part_id: 71,
		quantity: 103,
	},
	{
		garage_id: 245,
		part_id: 72,
		quantity: 8,
	},
	{
		garage_id: 245,
		part_id: 73,
		quantity: 266,
	},
	{
		garage_id: 245,
		part_id: 74,
		quantity: 70,
	},
	{
		garage_id: 245,
		part_id: 75,
		quantity: 421,
	},
	{
		garage_id: 245,
		part_id: 76,
		quantity: 44,
	},
	{
		garage_id: 245,
		part_id: 77,
		quantity: 165,
	},
	{
		garage_id: 245,
		part_id: 78,
		quantity: 8,
	},
	{
		garage_id: 245,
		part_id: 79,
		quantity: 103,
	},
	{
		garage_id: 245,
		part_id: 80,
		quantity: 57,
	},
	{
		garage_id: 245,
		part_id: 81,
		quantity: 187,
	},
	{
		garage_id: 245,
		part_id: 82,
		quantity: 12,
	},
	{
		garage_id: 245,
		part_id: 83,
		quantity: 314,
	},
	{
		garage_id: 245,
		part_id: 84,
		quantity: 310,
	},
	{
		garage_id: 245,
		part_id: 85,
		quantity: 499,
	},
	{
		garage_id: 245,
		part_id: 86,
		quantity: 170,
	},
	{
		garage_id: 245,
		part_id: 87,
		quantity: 77,
	},
	{
		garage_id: 245,
		part_id: 88,
		quantity: 436,
	},
	{
		garage_id: 245,
		part_id: 89,
		quantity: 342,
	},
	{
		garage_id: 245,
		part_id: 90,
		quantity: 151,
	},
	{
		garage_id: 245,
		part_id: 91,
		quantity: 331,
	},
	{
		garage_id: 245,
		part_id: 92,
		quantity: 283,
	},
	{
		garage_id: 245,
		part_id: 93,
		quantity: 308,
	},
	{
		garage_id: 245,
		part_id: 94,
		quantity: 157,
	},
	{
		garage_id: 245,
		part_id: 95,
		quantity: 166,
	},
	{
		garage_id: 245,
		part_id: 96,
		quantity: 94,
	},
	{
		garage_id: 245,
		part_id: 97,
		quantity: 442,
	},
	{
		garage_id: 245,
		part_id: 98,
		quantity: 82,
	},
	{
		garage_id: 245,
		part_id: 99,
		quantity: 204,
	},
	{
		garage_id: 245,
		part_id: 100,
		quantity: 217,
	},
	{
		garage_id: 245,
		part_id: 101,
		quantity: 249,
	},
	{
		garage_id: 245,
		part_id: 102,
		quantity: 344,
	},
	{
		garage_id: 245,
		part_id: 103,
		quantity: 206,
	},
	{
		garage_id: 245,
		part_id: 104,
		quantity: 314,
	},
	{
		garage_id: 245,
		part_id: 105,
		quantity: 218,
	},
	{
		garage_id: 245,
		part_id: 106,
		quantity: 210,
	},
	{
		garage_id: 245,
		part_id: 107,
		quantity: 9,
	},
	{
		garage_id: 245,
		part_id: 108,
		quantity: 441,
	},
	{
		garage_id: 245,
		part_id: 109,
		quantity: 314,
	},
	{
		garage_id: 245,
		part_id: 110,
		quantity: 351,
	},
	{
		garage_id: 245,
		part_id: 111,
		quantity: 170,
	},
	{
		garage_id: 245,
		part_id: 112,
		quantity: 286,
	},
	{
		garage_id: 245,
		part_id: 113,
		quantity: 313,
	},
	{
		garage_id: 245,
		part_id: 114,
		quantity: 287,
	},
	{
		garage_id: 245,
		part_id: 115,
		quantity: 189,
	},
	{
		garage_id: 245,
		part_id: 116,
		quantity: 177,
	},
	{
		garage_id: 245,
		part_id: 117,
		quantity: 12,
	},
	{
		garage_id: 245,
		part_id: 118,
		quantity: 77,
	},
	{
		garage_id: 245,
		part_id: 119,
		quantity: 325,
	},
	{
		garage_id: 245,
		part_id: 120,
		quantity: 218,
	},
	{
		garage_id: 245,
		part_id: 121,
		quantity: 372,
	},
	{
		garage_id: 245,
		part_id: 122,
		quantity: 269,
	},
	{
		garage_id: 245,
		part_id: 123,
		quantity: 347,
	},
	{
		garage_id: 245,
		part_id: 124,
		quantity: 346,
	},
	{
		garage_id: 245,
		part_id: 125,
		quantity: 235,
	},
	{
		garage_id: 245,
		part_id: 126,
		quantity: 23,
	},
	{
		garage_id: 245,
		part_id: 127,
		quantity: 247,
	},
	{
		garage_id: 245,
		part_id: 128,
		quantity: 195,
	},
	{
		garage_id: 245,
		part_id: 129,
		quantity: 76,
	},
	{
		garage_id: 245,
		part_id: 130,
		quantity: 4,
	},
	{
		garage_id: 245,
		part_id: 131,
		quantity: 61,
	},
	{
		garage_id: 245,
		part_id: 132,
		quantity: 500,
	},
	{
		garage_id: 245,
		part_id: 133,
		quantity: 77,
	},
	{
		garage_id: 245,
		part_id: 134,
		quantity: 388,
	},
	{
		garage_id: 245,
		part_id: 135,
		quantity: 420,
	},
	{
		garage_id: 245,
		part_id: 136,
		quantity: 180,
	},
	{
		garage_id: 245,
		part_id: 137,
		quantity: 294,
	},
	{
		garage_id: 245,
		part_id: 138,
		quantity: 184,
	},
	{
		garage_id: 245,
		part_id: 139,
		quantity: 258,
	},
	{
		garage_id: 245,
		part_id: 140,
		quantity: 41,
	},
	{
		garage_id: 245,
		part_id: 141,
		quantity: 328,
	},
	{
		garage_id: 245,
		part_id: 142,
		quantity: 10,
	},
	{
		garage_id: 245,
		part_id: 143,
		quantity: 235,
	},
	{
		garage_id: 245,
		part_id: 144,
		quantity: 61,
	},
	{
		garage_id: 245,
		part_id: 145,
		quantity: 67,
	},
	{
		garage_id: 245,
		part_id: 146,
		quantity: 268,
	},
	{
		garage_id: 245,
		part_id: 147,
		quantity: 94,
	},
	{
		garage_id: 245,
		part_id: 148,
		quantity: 203,
	},
	{
		garage_id: 245,
		part_id: 149,
		quantity: 41,
	},
	{
		garage_id: 245,
		part_id: 150,
		quantity: 401,
	},
	{
		garage_id: 245,
		part_id: 151,
		quantity: 40,
	},
	{
		garage_id: 245,
		part_id: 152,
		quantity: 119,
	},
	{
		garage_id: 245,
		part_id: 153,
		quantity: 345,
	},
	{
		garage_id: 245,
		part_id: 154,
		quantity: 215,
	},
	{
		garage_id: 245,
		part_id: 155,
		quantity: 478,
	},
	{
		garage_id: 245,
		part_id: 156,
		quantity: 347,
	},
	{
		garage_id: 245,
		part_id: 157,
		quantity: 149,
	},
	{
		garage_id: 245,
		part_id: 158,
		quantity: 66,
	},
	{
		garage_id: 245,
		part_id: 159,
		quantity: 40,
	},
	{
		garage_id: 245,
		part_id: 160,
		quantity: 72,
	},
	{
		garage_id: 245,
		part_id: 161,
		quantity: 33,
	},
	{
		garage_id: 245,
		part_id: 162,
		quantity: 300,
	},
	{
		garage_id: 245,
		part_id: 163,
		quantity: 252,
	},
	{
		garage_id: 245,
		part_id: 164,
		quantity: 452,
	},
	{
		garage_id: 245,
		part_id: 165,
		quantity: 414,
	},
	{
		garage_id: 245,
		part_id: 166,
		quantity: 489,
	},
	{
		garage_id: 245,
		part_id: 167,
		quantity: 372,
	},
	{
		garage_id: 245,
		part_id: 168,
		quantity: 214,
	},
	{
		garage_id: 245,
		part_id: 169,
		quantity: 22,
	},
	{
		garage_id: 245,
		part_id: 170,
		quantity: 319,
	},
	{
		garage_id: 245,
		part_id: 171,
		quantity: 48,
	},
	{
		garage_id: 245,
		part_id: 172,
		quantity: 169,
	},
	{
		garage_id: 245,
		part_id: 173,
		quantity: 388,
	},
	{
		garage_id: 245,
		part_id: 174,
		quantity: 303,
	},
	{
		garage_id: 245,
		part_id: 175,
		quantity: 185,
	},
	{
		garage_id: 245,
		part_id: 176,
		quantity: 106,
	},
	{
		garage_id: 245,
		part_id: 177,
		quantity: 446,
	},
	{
		garage_id: 245,
		part_id: 178,
		quantity: 44,
	},
	{
		garage_id: 245,
		part_id: 179,
		quantity: 80,
	},
	{
		garage_id: 245,
		part_id: 180,
		quantity: 228,
	},
	{
		garage_id: 245,
		part_id: 181,
		quantity: 243,
	},
	{
		garage_id: 245,
		part_id: 182,
		quantity: 184,
	},
	{
		garage_id: 245,
		part_id: 183,
		quantity: 248,
	},
	{
		garage_id: 245,
		part_id: 184,
		quantity: 255,
	},
	{
		garage_id: 245,
		part_id: 185,
		quantity: 310,
	},
	{
		garage_id: 245,
		part_id: 186,
		quantity: 412,
	},
	{
		garage_id: 245,
		part_id: 187,
		quantity: 130,
	},
	{
		garage_id: 245,
		part_id: 188,
		quantity: 365,
	},
	{
		garage_id: 245,
		part_id: 189,
		quantity: 189,
	},
	{
		garage_id: 245,
		part_id: 190,
		quantity: 106,
	},
	{
		garage_id: 245,
		part_id: 191,
		quantity: 44,
	},
	{
		garage_id: 245,
		part_id: 192,
		quantity: 441,
	},
	{
		garage_id: 245,
		part_id: 193,
		quantity: 498,
	},
	{
		garage_id: 245,
		part_id: 194,
		quantity: 277,
	},
	{
		garage_id: 245,
		part_id: 195,
		quantity: 489,
	},
	{
		garage_id: 245,
		part_id: 196,
		quantity: 397,
	},
	{
		garage_id: 245,
		part_id: 197,
		quantity: 1,
	},
	{
		garage_id: 245,
		part_id: 198,
		quantity: 99,
	},
	{
		garage_id: 245,
		part_id: 199,
		quantity: 393,
	},
	{
		garage_id: 245,
		part_id: 200,
		quantity: 391,
	},
	{
		garage_id: 245,
		part_id: 201,
		quantity: 352,
	},
	{
		garage_id: 245,
		part_id: 202,
		quantity: 56,
	},
	{
		garage_id: 245,
		part_id: 203,
		quantity: 315,
	},
	{
		garage_id: 245,
		part_id: 204,
		quantity: 292,
	},
	{
		garage_id: 245,
		part_id: 205,
		quantity: 89,
	},
	{
		garage_id: 245,
		part_id: 206,
		quantity: 344,
	},
	{
		garage_id: 245,
		part_id: 207,
		quantity: 49,
	},
	{
		garage_id: 245,
		part_id: 208,
		quantity: 89,
	},
	{
		garage_id: 245,
		part_id: 209,
		quantity: 460,
	},
	{
		garage_id: 245,
		part_id: 210,
		quantity: 2,
	},
	{
		garage_id: 245,
		part_id: 211,
		quantity: 422,
	},
	{
		garage_id: 245,
		part_id: 212,
		quantity: 67,
	},
	{
		garage_id: 245,
		part_id: 213,
		quantity: 295,
	},
	{
		garage_id: 245,
		part_id: 214,
		quantity: 191,
	},
	{
		garage_id: 245,
		part_id: 215,
		quantity: 361,
	},
	{
		garage_id: 245,
		part_id: 216,
		quantity: 478,
	},
	{
		garage_id: 245,
		part_id: 217,
		quantity: 321,
	},
	{
		garage_id: 245,
		part_id: 218,
		quantity: 102,
	},
	{
		garage_id: 245,
		part_id: 219,
		quantity: 294,
	},
	{
		garage_id: 245,
		part_id: 220,
		quantity: 482,
	},
	{
		garage_id: 245,
		part_id: 221,
		quantity: 186,
	},
	{
		garage_id: 245,
		part_id: 222,
		quantity: 500,
	},
	{
		garage_id: 245,
		part_id: 223,
		quantity: 92,
	},
	{
		garage_id: 245,
		part_id: 224,
		quantity: 380,
	},
	{
		garage_id: 245,
		part_id: 225,
		quantity: 274,
	},
	{
		garage_id: 245,
		part_id: 226,
		quantity: 413,
	},
	{
		garage_id: 245,
		part_id: 227,
		quantity: 15,
	},
	{
		garage_id: 245,
		part_id: 228,
		quantity: 212,
	},
	{
		garage_id: 245,
		part_id: 229,
		quantity: 154,
	},
	{
		garage_id: 245,
		part_id: 230,
		quantity: 98,
	},
	{
		garage_id: 245,
		part_id: 231,
		quantity: 361,
	},
	{
		garage_id: 245,
		part_id: 232,
		quantity: 269,
	},
	{
		garage_id: 245,
		part_id: 233,
		quantity: 284,
	},
	{
		garage_id: 245,
		part_id: 234,
		quantity: 178,
	},
	{
		garage_id: 245,
		part_id: 235,
		quantity: 199,
	},
	{
		garage_id: 245,
		part_id: 236,
		quantity: 134,
	},
	{
		garage_id: 245,
		part_id: 237,
		quantity: 404,
	},
	{
		garage_id: 245,
		part_id: 238,
		quantity: 175,
	},
	{
		garage_id: 245,
		part_id: 239,
		quantity: 437,
	},
	{
		garage_id: 245,
		part_id: 240,
		quantity: 298,
	},
	{
		garage_id: 245,
		part_id: 241,
		quantity: 496,
	},
	{
		garage_id: 245,
		part_id: 242,
		quantity: 392,
	},
	{
		garage_id: 245,
		part_id: 243,
		quantity: 140,
	},
	{
		garage_id: 245,
		part_id: 244,
		quantity: 445,
	},
	{
		garage_id: 245,
		part_id: 245,
		quantity: 428,
	},
	{
		garage_id: 245,
		part_id: 246,
		quantity: 199,
	},
	{
		garage_id: 245,
		part_id: 247,
		quantity: 234,
	},
	{
		garage_id: 245,
		part_id: 248,
		quantity: 104,
	},
	{
		garage_id: 245,
		part_id: 249,
		quantity: 20,
	},
	{
		garage_id: 245,
		part_id: 250,
		quantity: 391,
	},
	{
		garage_id: 245,
		part_id: 251,
		quantity: 44,
	},
	{
		garage_id: 245,
		part_id: 252,
		quantity: 29,
	},
	{
		garage_id: 245,
		part_id: 253,
		quantity: 468,
	},
	{
		garage_id: 245,
		part_id: 254,
		quantity: 182,
	},
	{
		garage_id: 245,
		part_id: 255,
		quantity: 213,
	},
	{
		garage_id: 245,
		part_id: 256,
		quantity: 49,
	},
	{
		garage_id: 245,
		part_id: 257,
		quantity: 490,
	},
	{
		garage_id: 245,
		part_id: 258,
		quantity: 48,
	},
	{
		garage_id: 245,
		part_id: 259,
		quantity: 356,
	},
	{
		garage_id: 245,
		part_id: 260,
		quantity: 238,
	},
	{
		garage_id: 245,
		part_id: 261,
		quantity: 370,
	},
	{
		garage_id: 245,
		part_id: 262,
		quantity: 256,
	},
	{
		garage_id: 245,
		part_id: 263,
		quantity: 185,
	},
	{
		garage_id: 245,
		part_id: 264,
		quantity: 72,
	},
	{
		garage_id: 245,
		part_id: 265,
		quantity: 479,
	},
	{
		garage_id: 245,
		part_id: 266,
		quantity: 312,
	},
	{
		garage_id: 245,
		part_id: 267,
		quantity: 195,
	},
	{
		garage_id: 245,
		part_id: 268,
		quantity: 216,
	},
	{
		garage_id: 245,
		part_id: 269,
		quantity: 130,
	},
	{
		garage_id: 245,
		part_id: 270,
		quantity: 107,
	},
	{
		garage_id: 245,
		part_id: 271,
		quantity: 326,
	},
	{
		garage_id: 245,
		part_id: 272,
		quantity: 417,
	},
	{
		garage_id: 245,
		part_id: 273,
		quantity: 210,
	},
	{
		garage_id: 245,
		part_id: 274,
		quantity: 407,
	},
	{
		garage_id: 245,
		part_id: 275,
		quantity: 462,
	},
	{
		garage_id: 245,
		part_id: 276,
		quantity: 294,
	},
	{
		garage_id: 245,
		part_id: 277,
		quantity: 52,
	},
	{
		garage_id: 245,
		part_id: 278,
		quantity: 48,
	},
	{
		garage_id: 245,
		part_id: 279,
		quantity: 262,
	},
	{
		garage_id: 245,
		part_id: 280,
		quantity: 500,
	},
	{
		garage_id: 245,
		part_id: 281,
		quantity: 415,
	},
	{
		garage_id: 245,
		part_id: 282,
		quantity: 269,
	},
	{
		garage_id: 245,
		part_id: 283,
		quantity: 264,
	},
	{
		garage_id: 245,
		part_id: 284,
		quantity: 216,
	},
	{
		garage_id: 245,
		part_id: 285,
		quantity: 107,
	},
	{
		garage_id: 245,
		part_id: 286,
		quantity: 220,
	},
	{
		garage_id: 245,
		part_id: 287,
		quantity: 0,
	},
	{
		garage_id: 245,
		part_id: 288,
		quantity: 402,
	},
	{
		garage_id: 245,
		part_id: 289,
		quantity: 93,
	},
	{
		garage_id: 245,
		part_id: 290,
		quantity: 440,
	},
	{
		garage_id: 245,
		part_id: 291,
		quantity: 322,
	},
	{
		garage_id: 245,
		part_id: 292,
		quantity: 479,
	},
	{
		garage_id: 245,
		part_id: 293,
		quantity: 324,
	},
	{
		garage_id: 245,
		part_id: 294,
		quantity: 305,
	},
	{
		garage_id: 245,
		part_id: 295,
		quantity: 333,
	},
	{
		garage_id: 245,
		part_id: 296,
		quantity: 287,
	},
	{
		garage_id: 245,
		part_id: 297,
		quantity: 17,
	},
	{
		garage_id: 245,
		part_id: 298,
		quantity: 203,
	},
	{
		garage_id: 245,
		part_id: 299,
		quantity: 91,
	},
	{
		garage_id: 245,
		part_id: 300,
		quantity: 317,
	},
	{
		garage_id: 245,
		part_id: 301,
		quantity: 198,
	},
	{
		garage_id: 245,
		part_id: 302,
		quantity: 242,
	},
	{
		garage_id: 245,
		part_id: 303,
		quantity: 87,
	},
	{
		garage_id: 245,
		part_id: 304,
		quantity: 50,
	},
	{
		garage_id: 245,
		part_id: 305,
		quantity: 38,
	},
	{
		garage_id: 245,
		part_id: 306,
		quantity: 290,
	},
	{
		garage_id: 245,
		part_id: 307,
		quantity: 74,
	},
	{
		garage_id: 245,
		part_id: 308,
		quantity: 256,
	},
	{
		garage_id: 245,
		part_id: 309,
		quantity: 307,
	},
	{
		garage_id: 245,
		part_id: 310,
		quantity: 446,
	},
	{
		garage_id: 245,
		part_id: 311,
		quantity: 335,
	},
	{
		garage_id: 245,
		part_id: 312,
		quantity: 163,
	},
	{
		garage_id: 245,
		part_id: 313,
		quantity: 89,
	},
	{
		garage_id: 245,
		part_id: 314,
		quantity: 243,
	},
	{
		garage_id: 245,
		part_id: 315,
		quantity: 448,
	},
	{
		garage_id: 245,
		part_id: 316,
		quantity: 228,
	},
	{
		garage_id: 245,
		part_id: 317,
		quantity: 131,
	},
	{
		garage_id: 245,
		part_id: 318,
		quantity: 447,
	},
	{
		garage_id: 245,
		part_id: 319,
		quantity: 254,
	},
	{
		garage_id: 245,
		part_id: 320,
		quantity: 133,
	},
	{
		garage_id: 245,
		part_id: 321,
		quantity: 258,
	},
	{
		garage_id: 245,
		part_id: 322,
		quantity: 451,
	},
	{
		garage_id: 245,
		part_id: 323,
		quantity: 481,
	},
	{
		garage_id: 245,
		part_id: 324,
		quantity: 328,
	},
	{
		garage_id: 245,
		part_id: 325,
		quantity: 384,
	},
	{
		garage_id: 245,
		part_id: 326,
		quantity: 149,
	},
	{
		garage_id: 245,
		part_id: 327,
		quantity: 318,
	},
	{
		garage_id: 245,
		part_id: 328,
		quantity: 460,
	},
	{
		garage_id: 245,
		part_id: 329,
		quantity: 199,
	},
	{
		garage_id: 245,
		part_id: 330,
		quantity: 134,
	},
	{
		garage_id: 245,
		part_id: 331,
		quantity: 386,
	},
	{
		garage_id: 245,
		part_id: 332,
		quantity: 121,
	},
	{
		garage_id: 245,
		part_id: 333,
		quantity: 53,
	},
	{
		garage_id: 245,
		part_id: 334,
		quantity: 343,
	},
	{
		garage_id: 245,
		part_id: 335,
		quantity: 63,
	},
	{
		garage_id: 245,
		part_id: 336,
		quantity: 117,
	},
	{
		garage_id: 245,
		part_id: 337,
		quantity: 165,
	},
	{
		garage_id: 245,
		part_id: 338,
		quantity: 12,
	},
	{
		garage_id: 245,
		part_id: 339,
		quantity: 50,
	},
	{
		garage_id: 245,
		part_id: 340,
		quantity: 52,
	},
	{
		garage_id: 245,
		part_id: 341,
		quantity: 386,
	},
	{
		garage_id: 245,
		part_id: 342,
		quantity: 338,
	},
	{
		garage_id: 245,
		part_id: 343,
		quantity: 499,
	},
	{
		garage_id: 245,
		part_id: 344,
		quantity: 239,
	},
	{
		garage_id: 245,
		part_id: 345,
		quantity: 347,
	},
	{
		garage_id: 245,
		part_id: 346,
		quantity: 401,
	},
	{
		garage_id: 245,
		part_id: 347,
		quantity: 135,
	},
	{
		garage_id: 245,
		part_id: 348,
		quantity: 467,
	},
	{
		garage_id: 245,
		part_id: 349,
		quantity: 232,
	},
	{
		garage_id: 245,
		part_id: 350,
		quantity: 118,
	},
	{
		garage_id: 245,
		part_id: 351,
		quantity: 112,
	},
	{
		garage_id: 245,
		part_id: 352,
		quantity: 252,
	},
	{
		garage_id: 245,
		part_id: 353,
		quantity: 122,
	},
	{
		garage_id: 245,
		part_id: 354,
		quantity: 299,
	},
	{
		garage_id: 245,
		part_id: 355,
		quantity: 399,
	},
	{
		garage_id: 245,
		part_id: 356,
		quantity: 459,
	},
	{
		garage_id: 245,
		part_id: 357,
		quantity: 363,
	},
	{
		garage_id: 245,
		part_id: 358,
		quantity: 370,
	},
	{
		garage_id: 245,
		part_id: 359,
		quantity: 35,
	},
	{
		garage_id: 245,
		part_id: 360,
		quantity: 370,
	},
	{
		garage_id: 245,
		part_id: 361,
		quantity: 67,
	},
	{
		garage_id: 245,
		part_id: 362,
		quantity: 238,
	},
	{
		garage_id: 245,
		part_id: 363,
		quantity: 401,
	},
	{
		garage_id: 245,
		part_id: 364,
		quantity: 146,
	},
	{
		garage_id: 245,
		part_id: 365,
		quantity: 239,
	},
	{
		garage_id: 245,
		part_id: 366,
		quantity: 277,
	},
	{
		garage_id: 245,
		part_id: 367,
		quantity: 316,
	},
	{
		garage_id: 245,
		part_id: 368,
		quantity: 171,
	},
	{
		garage_id: 245,
		part_id: 369,
		quantity: 204,
	},
	{
		garage_id: 245,
		part_id: 370,
		quantity: 303,
	},
	{
		garage_id: 245,
		part_id: 371,
		quantity: 466,
	},
	{
		garage_id: 245,
		part_id: 372,
		quantity: 99,
	},
	{
		garage_id: 245,
		part_id: 373,
		quantity: 410,
	},
	{
		garage_id: 245,
		part_id: 374,
		quantity: 54,
	},
	{
		garage_id: 245,
		part_id: 375,
		quantity: 119,
	},
	{
		garage_id: 245,
		part_id: 376,
		quantity: 70,
	},
	{
		garage_id: 245,
		part_id: 377,
		quantity: 24,
	},
	{
		garage_id: 245,
		part_id: 378,
		quantity: 121,
	},
	{
		garage_id: 245,
		part_id: 379,
		quantity: 206,
	},
	{
		garage_id: 245,
		part_id: 380,
		quantity: 65,
	},
	{
		garage_id: 245,
		part_id: 381,
		quantity: 407,
	},
	{
		garage_id: 245,
		part_id: 382,
		quantity: 400,
	},
	{
		garage_id: 245,
		part_id: 383,
		quantity: 411,
	},
	{
		garage_id: 245,
		part_id: 384,
		quantity: 166,
	},
	{
		garage_id: 245,
		part_id: 385,
		quantity: 426,
	},
	{
		garage_id: 245,
		part_id: 386,
		quantity: 67,
	},
	{
		garage_id: 245,
		part_id: 387,
		quantity: 102,
	},
	{
		garage_id: 245,
		part_id: 388,
		quantity: 246,
	},
	{
		garage_id: 245,
		part_id: 389,
		quantity: 264,
	},
	{
		garage_id: 245,
		part_id: 390,
		quantity: 285,
	},
	{
		garage_id: 245,
		part_id: 391,
		quantity: 337,
	},
	{
		garage_id: 245,
		part_id: 392,
		quantity: 453,
	},
	{
		garage_id: 245,
		part_id: 393,
		quantity: 376,
	},
	{
		garage_id: 245,
		part_id: 394,
		quantity: 77,
	},
	{
		garage_id: 245,
		part_id: 395,
		quantity: 399,
	},
	{
		garage_id: 245,
		part_id: 396,
		quantity: 40,
	},
	{
		garage_id: 245,
		part_id: 397,
		quantity: 413,
	},
	{
		garage_id: 245,
		part_id: 398,
		quantity: 31,
	},
	{
		garage_id: 245,
		part_id: 399,
		quantity: 72,
	},
	{
		garage_id: 246,
		part_id: 0,
		quantity: 105,
	},
	{
		garage_id: 246,
		part_id: 1,
		quantity: 302,
	},
	{
		garage_id: 246,
		part_id: 2,
		quantity: 252,
	},
	{
		garage_id: 246,
		part_id: 3,
		quantity: 84,
	},
	{
		garage_id: 246,
		part_id: 4,
		quantity: 300,
	},
	{
		garage_id: 246,
		part_id: 5,
		quantity: 309,
	},
	{
		garage_id: 246,
		part_id: 6,
		quantity: 297,
	},
	{
		garage_id: 246,
		part_id: 7,
		quantity: 42,
	},
	{
		garage_id: 246,
		part_id: 8,
		quantity: 455,
	},
	{
		garage_id: 246,
		part_id: 9,
		quantity: 448,
	},
	{
		garage_id: 246,
		part_id: 10,
		quantity: 337,
	},
	{
		garage_id: 246,
		part_id: 11,
		quantity: 283,
	},
	{
		garage_id: 246,
		part_id: 12,
		quantity: 472,
	},
	{
		garage_id: 246,
		part_id: 13,
		quantity: 352,
	},
	{
		garage_id: 246,
		part_id: 14,
		quantity: 411,
	},
	{
		garage_id: 246,
		part_id: 15,
		quantity: 281,
	},
	{
		garage_id: 246,
		part_id: 16,
		quantity: 23,
	},
	{
		garage_id: 246,
		part_id: 17,
		quantity: 209,
	},
	{
		garage_id: 246,
		part_id: 18,
		quantity: 460,
	},
	{
		garage_id: 246,
		part_id: 19,
		quantity: 434,
	},
	{
		garage_id: 246,
		part_id: 20,
		quantity: 314,
	},
	{
		garage_id: 246,
		part_id: 21,
		quantity: 172,
	},
	{
		garage_id: 246,
		part_id: 22,
		quantity: 284,
	},
	{
		garage_id: 246,
		part_id: 23,
		quantity: 300,
	},
	{
		garage_id: 246,
		part_id: 24,
		quantity: 60,
	},
	{
		garage_id: 246,
		part_id: 25,
		quantity: 104,
	},
	{
		garage_id: 246,
		part_id: 26,
		quantity: 384,
	},
	{
		garage_id: 246,
		part_id: 27,
		quantity: 52,
	},
	{
		garage_id: 246,
		part_id: 28,
		quantity: 229,
	},
	{
		garage_id: 246,
		part_id: 29,
		quantity: 226,
	},
	{
		garage_id: 246,
		part_id: 30,
		quantity: 180,
	},
	{
		garage_id: 246,
		part_id: 31,
		quantity: 27,
	},
	{
		garage_id: 246,
		part_id: 32,
		quantity: 113,
	},
	{
		garage_id: 246,
		part_id: 33,
		quantity: 259,
	},
	{
		garage_id: 246,
		part_id: 34,
		quantity: 109,
	},
	{
		garage_id: 246,
		part_id: 35,
		quantity: 92,
	},
	{
		garage_id: 246,
		part_id: 36,
		quantity: 206,
	},
	{
		garage_id: 246,
		part_id: 37,
		quantity: 148,
	},
	{
		garage_id: 246,
		part_id: 38,
		quantity: 215,
	},
	{
		garage_id: 246,
		part_id: 39,
		quantity: 246,
	},
	{
		garage_id: 246,
		part_id: 40,
		quantity: 350,
	},
	{
		garage_id: 246,
		part_id: 41,
		quantity: 324,
	},
	{
		garage_id: 246,
		part_id: 42,
		quantity: 17,
	},
	{
		garage_id: 246,
		part_id: 43,
		quantity: 490,
	},
	{
		garage_id: 246,
		part_id: 44,
		quantity: 447,
	},
	{
		garage_id: 246,
		part_id: 45,
		quantity: 148,
	},
	{
		garage_id: 246,
		part_id: 46,
		quantity: 338,
	},
	{
		garage_id: 246,
		part_id: 47,
		quantity: 62,
	},
	{
		garage_id: 246,
		part_id: 48,
		quantity: 412,
	},
	{
		garage_id: 246,
		part_id: 49,
		quantity: 275,
	},
	{
		garage_id: 246,
		part_id: 50,
		quantity: 355,
	},
	{
		garage_id: 246,
		part_id: 51,
		quantity: 125,
	},
	{
		garage_id: 246,
		part_id: 52,
		quantity: 323,
	},
	{
		garage_id: 246,
		part_id: 53,
		quantity: 144,
	},
	{
		garage_id: 246,
		part_id: 54,
		quantity: 306,
	},
	{
		garage_id: 246,
		part_id: 55,
		quantity: 22,
	},
	{
		garage_id: 246,
		part_id: 56,
		quantity: 3,
	},
	{
		garage_id: 246,
		part_id: 57,
		quantity: 192,
	},
	{
		garage_id: 246,
		part_id: 58,
		quantity: 401,
	},
	{
		garage_id: 246,
		part_id: 59,
		quantity: 216,
	},
	{
		garage_id: 246,
		part_id: 60,
		quantity: 455,
	},
	{
		garage_id: 246,
		part_id: 61,
		quantity: 341,
	},
	{
		garage_id: 246,
		part_id: 62,
		quantity: 201,
	},
	{
		garage_id: 246,
		part_id: 63,
		quantity: 480,
	},
	{
		garage_id: 246,
		part_id: 64,
		quantity: 309,
	},
	{
		garage_id: 246,
		part_id: 65,
		quantity: 195,
	},
	{
		garage_id: 246,
		part_id: 66,
		quantity: 478,
	},
	{
		garage_id: 246,
		part_id: 67,
		quantity: 430,
	},
	{
		garage_id: 246,
		part_id: 68,
		quantity: 430,
	},
	{
		garage_id: 246,
		part_id: 69,
		quantity: 458,
	},
	{
		garage_id: 246,
		part_id: 70,
		quantity: 419,
	},
	{
		garage_id: 246,
		part_id: 71,
		quantity: 347,
	},
	{
		garage_id: 246,
		part_id: 72,
		quantity: 207,
	},
	{
		garage_id: 246,
		part_id: 73,
		quantity: 321,
	},
	{
		garage_id: 246,
		part_id: 74,
		quantity: 84,
	},
	{
		garage_id: 246,
		part_id: 75,
		quantity: 221,
	},
	{
		garage_id: 246,
		part_id: 76,
		quantity: 250,
	},
	{
		garage_id: 246,
		part_id: 77,
		quantity: 402,
	},
	{
		garage_id: 246,
		part_id: 78,
		quantity: 83,
	},
	{
		garage_id: 246,
		part_id: 79,
		quantity: 12,
	},
	{
		garage_id: 246,
		part_id: 80,
		quantity: 200,
	},
	{
		garage_id: 246,
		part_id: 81,
		quantity: 469,
	},
	{
		garage_id: 246,
		part_id: 82,
		quantity: 431,
	},
	{
		garage_id: 246,
		part_id: 83,
		quantity: 129,
	},
	{
		garage_id: 246,
		part_id: 84,
		quantity: 65,
	},
	{
		garage_id: 246,
		part_id: 85,
		quantity: 214,
	},
	{
		garage_id: 246,
		part_id: 86,
		quantity: 13,
	},
	{
		garage_id: 246,
		part_id: 87,
		quantity: 65,
	},
	{
		garage_id: 246,
		part_id: 88,
		quantity: 371,
	},
	{
		garage_id: 246,
		part_id: 89,
		quantity: 73,
	},
	{
		garage_id: 246,
		part_id: 90,
		quantity: 103,
	},
	{
		garage_id: 246,
		part_id: 91,
		quantity: 309,
	},
	{
		garage_id: 246,
		part_id: 92,
		quantity: 139,
	},
	{
		garage_id: 246,
		part_id: 93,
		quantity: 387,
	},
	{
		garage_id: 246,
		part_id: 94,
		quantity: 454,
	},
	{
		garage_id: 246,
		part_id: 95,
		quantity: 412,
	},
	{
		garage_id: 246,
		part_id: 96,
		quantity: 498,
	},
	{
		garage_id: 246,
		part_id: 97,
		quantity: 378,
	},
	{
		garage_id: 246,
		part_id: 98,
		quantity: 33,
	},
	{
		garage_id: 246,
		part_id: 99,
		quantity: 418,
	},
	{
		garage_id: 246,
		part_id: 100,
		quantity: 326,
	},
	{
		garage_id: 246,
		part_id: 101,
		quantity: 138,
	},
	{
		garage_id: 246,
		part_id: 102,
		quantity: 423,
	},
	{
		garage_id: 246,
		part_id: 103,
		quantity: 248,
	},
	{
		garage_id: 246,
		part_id: 104,
		quantity: 301,
	},
	{
		garage_id: 246,
		part_id: 105,
		quantity: 42,
	},
	{
		garage_id: 246,
		part_id: 106,
		quantity: 63,
	},
	{
		garage_id: 246,
		part_id: 107,
		quantity: 108,
	},
	{
		garage_id: 246,
		part_id: 108,
		quantity: 74,
	},
	{
		garage_id: 246,
		part_id: 109,
		quantity: 410,
	},
	{
		garage_id: 246,
		part_id: 110,
		quantity: 207,
	},
	{
		garage_id: 246,
		part_id: 111,
		quantity: 406,
	},
	{
		garage_id: 246,
		part_id: 112,
		quantity: 247,
	},
	{
		garage_id: 246,
		part_id: 113,
		quantity: 469,
	},
	{
		garage_id: 246,
		part_id: 114,
		quantity: 96,
	},
	{
		garage_id: 246,
		part_id: 115,
		quantity: 31,
	},
	{
		garage_id: 246,
		part_id: 116,
		quantity: 434,
	},
	{
		garage_id: 246,
		part_id: 117,
		quantity: 416,
	},
	{
		garage_id: 246,
		part_id: 118,
		quantity: 6,
	},
	{
		garage_id: 246,
		part_id: 119,
		quantity: 281,
	},
	{
		garage_id: 246,
		part_id: 120,
		quantity: 239,
	},
	{
		garage_id: 246,
		part_id: 121,
		quantity: 463,
	},
	{
		garage_id: 246,
		part_id: 122,
		quantity: 257,
	},
	{
		garage_id: 246,
		part_id: 123,
		quantity: 66,
	},
	{
		garage_id: 246,
		part_id: 124,
		quantity: 461,
	},
	{
		garage_id: 246,
		part_id: 125,
		quantity: 325,
	},
	{
		garage_id: 246,
		part_id: 126,
		quantity: 237,
	},
	{
		garage_id: 246,
		part_id: 127,
		quantity: 132,
	},
	{
		garage_id: 246,
		part_id: 128,
		quantity: 134,
	},
	{
		garage_id: 246,
		part_id: 129,
		quantity: 388,
	},
	{
		garage_id: 246,
		part_id: 130,
		quantity: 232,
	},
	{
		garage_id: 246,
		part_id: 131,
		quantity: 109,
	},
	{
		garage_id: 246,
		part_id: 132,
		quantity: 474,
	},
	{
		garage_id: 246,
		part_id: 133,
		quantity: 266,
	},
	{
		garage_id: 246,
		part_id: 134,
		quantity: 115,
	},
	{
		garage_id: 246,
		part_id: 135,
		quantity: 369,
	},
	{
		garage_id: 246,
		part_id: 136,
		quantity: 406,
	},
	{
		garage_id: 246,
		part_id: 137,
		quantity: 55,
	},
	{
		garage_id: 246,
		part_id: 138,
		quantity: 479,
	},
	{
		garage_id: 246,
		part_id: 139,
		quantity: 124,
	},
	{
		garage_id: 246,
		part_id: 140,
		quantity: 303,
	},
	{
		garage_id: 246,
		part_id: 141,
		quantity: 7,
	},
	{
		garage_id: 246,
		part_id: 142,
		quantity: 320,
	},
	{
		garage_id: 246,
		part_id: 143,
		quantity: 450,
	},
	{
		garage_id: 246,
		part_id: 144,
		quantity: 422,
	},
	{
		garage_id: 246,
		part_id: 145,
		quantity: 56,
	},
	{
		garage_id: 246,
		part_id: 146,
		quantity: 212,
	},
	{
		garage_id: 246,
		part_id: 147,
		quantity: 27,
	},
	{
		garage_id: 246,
		part_id: 148,
		quantity: 50,
	},
	{
		garage_id: 246,
		part_id: 149,
		quantity: 331,
	},
	{
		garage_id: 246,
		part_id: 150,
		quantity: 465,
	},
	{
		garage_id: 246,
		part_id: 151,
		quantity: 13,
	},
	{
		garage_id: 246,
		part_id: 152,
		quantity: 464,
	},
	{
		garage_id: 246,
		part_id: 153,
		quantity: 490,
	},
	{
		garage_id: 246,
		part_id: 154,
		quantity: 378,
	},
	{
		garage_id: 246,
		part_id: 155,
		quantity: 162,
	},
	{
		garage_id: 246,
		part_id: 156,
		quantity: 287,
	},
	{
		garage_id: 246,
		part_id: 157,
		quantity: 45,
	},
	{
		garage_id: 246,
		part_id: 158,
		quantity: 499,
	},
	{
		garage_id: 246,
		part_id: 159,
		quantity: 344,
	},
	{
		garage_id: 246,
		part_id: 160,
		quantity: 405,
	},
	{
		garage_id: 246,
		part_id: 161,
		quantity: 260,
	},
	{
		garage_id: 246,
		part_id: 162,
		quantity: 237,
	},
	{
		garage_id: 246,
		part_id: 163,
		quantity: 118,
	},
	{
		garage_id: 246,
		part_id: 164,
		quantity: 8,
	},
	{
		garage_id: 246,
		part_id: 165,
		quantity: 378,
	},
	{
		garage_id: 246,
		part_id: 166,
		quantity: 219,
	},
	{
		garage_id: 246,
		part_id: 167,
		quantity: 331,
	},
	{
		garage_id: 246,
		part_id: 168,
		quantity: 69,
	},
	{
		garage_id: 246,
		part_id: 169,
		quantity: 324,
	},
	{
		garage_id: 246,
		part_id: 170,
		quantity: 240,
	},
	{
		garage_id: 246,
		part_id: 171,
		quantity: 82,
	},
	{
		garage_id: 246,
		part_id: 172,
		quantity: 322,
	},
	{
		garage_id: 246,
		part_id: 173,
		quantity: 52,
	},
	{
		garage_id: 246,
		part_id: 174,
		quantity: 489,
	},
	{
		garage_id: 246,
		part_id: 175,
		quantity: 127,
	},
	{
		garage_id: 246,
		part_id: 176,
		quantity: 440,
	},
	{
		garage_id: 246,
		part_id: 177,
		quantity: 457,
	},
	{
		garage_id: 246,
		part_id: 178,
		quantity: 484,
	},
	{
		garage_id: 246,
		part_id: 179,
		quantity: 429,
	},
	{
		garage_id: 246,
		part_id: 180,
		quantity: 481,
	},
	{
		garage_id: 246,
		part_id: 181,
		quantity: 6,
	},
	{
		garage_id: 246,
		part_id: 182,
		quantity: 139,
	},
	{
		garage_id: 246,
		part_id: 183,
		quantity: 182,
	},
	{
		garage_id: 246,
		part_id: 184,
		quantity: 78,
	},
	{
		garage_id: 246,
		part_id: 185,
		quantity: 85,
	},
	{
		garage_id: 246,
		part_id: 186,
		quantity: 32,
	},
	{
		garage_id: 246,
		part_id: 187,
		quantity: 138,
	},
	{
		garage_id: 246,
		part_id: 188,
		quantity: 176,
	},
	{
		garage_id: 246,
		part_id: 189,
		quantity: 401,
	},
	{
		garage_id: 246,
		part_id: 190,
		quantity: 41,
	},
	{
		garage_id: 246,
		part_id: 191,
		quantity: 354,
	},
	{
		garage_id: 246,
		part_id: 192,
		quantity: 12,
	},
	{
		garage_id: 246,
		part_id: 193,
		quantity: 380,
	},
	{
		garage_id: 246,
		part_id: 194,
		quantity: 365,
	},
	{
		garage_id: 246,
		part_id: 195,
		quantity: 403,
	},
	{
		garage_id: 246,
		part_id: 196,
		quantity: 395,
	},
	{
		garage_id: 246,
		part_id: 197,
		quantity: 381,
	},
	{
		garage_id: 246,
		part_id: 198,
		quantity: 127,
	},
	{
		garage_id: 246,
		part_id: 199,
		quantity: 0,
	},
	{
		garage_id: 246,
		part_id: 200,
		quantity: 330,
	},
	{
		garage_id: 246,
		part_id: 201,
		quantity: 330,
	},
	{
		garage_id: 246,
		part_id: 202,
		quantity: 37,
	},
	{
		garage_id: 246,
		part_id: 203,
		quantity: 65,
	},
	{
		garage_id: 246,
		part_id: 204,
		quantity: 242,
	},
	{
		garage_id: 246,
		part_id: 205,
		quantity: 312,
	},
	{
		garage_id: 246,
		part_id: 206,
		quantity: 369,
	},
	{
		garage_id: 246,
		part_id: 207,
		quantity: 289,
	},
	{
		garage_id: 246,
		part_id: 208,
		quantity: 307,
	},
	{
		garage_id: 246,
		part_id: 209,
		quantity: 202,
	},
	{
		garage_id: 246,
		part_id: 210,
		quantity: 460,
	},
	{
		garage_id: 246,
		part_id: 211,
		quantity: 438,
	},
	{
		garage_id: 246,
		part_id: 212,
		quantity: 460,
	},
	{
		garage_id: 246,
		part_id: 213,
		quantity: 174,
	},
	{
		garage_id: 246,
		part_id: 214,
		quantity: 62,
	},
	{
		garage_id: 246,
		part_id: 215,
		quantity: 407,
	},
	{
		garage_id: 246,
		part_id: 216,
		quantity: 175,
	},
	{
		garage_id: 246,
		part_id: 217,
		quantity: 412,
	},
	{
		garage_id: 246,
		part_id: 218,
		quantity: 114,
	},
	{
		garage_id: 246,
		part_id: 219,
		quantity: 82,
	},
	{
		garage_id: 246,
		part_id: 220,
		quantity: 173,
	},
	{
		garage_id: 246,
		part_id: 221,
		quantity: 373,
	},
	{
		garage_id: 246,
		part_id: 222,
		quantity: 150,
	},
	{
		garage_id: 246,
		part_id: 223,
		quantity: 252,
	},
	{
		garage_id: 246,
		part_id: 224,
		quantity: 19,
	},
	{
		garage_id: 246,
		part_id: 225,
		quantity: 27,
	},
	{
		garage_id: 246,
		part_id: 226,
		quantity: 35,
	},
	{
		garage_id: 246,
		part_id: 227,
		quantity: 177,
	},
	{
		garage_id: 246,
		part_id: 228,
		quantity: 257,
	},
	{
		garage_id: 246,
		part_id: 229,
		quantity: 295,
	},
	{
		garage_id: 246,
		part_id: 230,
		quantity: 381,
	},
	{
		garage_id: 246,
		part_id: 231,
		quantity: 327,
	},
	{
		garage_id: 246,
		part_id: 232,
		quantity: 267,
	},
	{
		garage_id: 246,
		part_id: 233,
		quantity: 34,
	},
	{
		garage_id: 246,
		part_id: 234,
		quantity: 199,
	},
	{
		garage_id: 246,
		part_id: 235,
		quantity: 497,
	},
	{
		garage_id: 246,
		part_id: 236,
		quantity: 89,
	},
	{
		garage_id: 246,
		part_id: 237,
		quantity: 318,
	},
	{
		garage_id: 246,
		part_id: 238,
		quantity: 235,
	},
	{
		garage_id: 246,
		part_id: 239,
		quantity: 37,
	},
	{
		garage_id: 246,
		part_id: 240,
		quantity: 400,
	},
	{
		garage_id: 246,
		part_id: 241,
		quantity: 129,
	},
	{
		garage_id: 246,
		part_id: 242,
		quantity: 215,
	},
	{
		garage_id: 246,
		part_id: 243,
		quantity: 239,
	},
	{
		garage_id: 246,
		part_id: 244,
		quantity: 302,
	},
	{
		garage_id: 246,
		part_id: 245,
		quantity: 279,
	},
	{
		garage_id: 246,
		part_id: 246,
		quantity: 471,
	},
	{
		garage_id: 246,
		part_id: 247,
		quantity: 68,
	},
	{
		garage_id: 246,
		part_id: 248,
		quantity: 329,
	},
	{
		garage_id: 246,
		part_id: 249,
		quantity: 71,
	},
	{
		garage_id: 246,
		part_id: 250,
		quantity: 33,
	},
	{
		garage_id: 246,
		part_id: 251,
		quantity: 297,
	},
	{
		garage_id: 246,
		part_id: 252,
		quantity: 140,
	},
	{
		garage_id: 246,
		part_id: 253,
		quantity: 134,
	},
	{
		garage_id: 246,
		part_id: 254,
		quantity: 294,
	},
	{
		garage_id: 246,
		part_id: 255,
		quantity: 251,
	},
	{
		garage_id: 246,
		part_id: 256,
		quantity: 320,
	},
	{
		garage_id: 246,
		part_id: 257,
		quantity: 333,
	},
	{
		garage_id: 246,
		part_id: 258,
		quantity: 219,
	},
	{
		garage_id: 246,
		part_id: 259,
		quantity: 116,
	},
	{
		garage_id: 246,
		part_id: 260,
		quantity: 94,
	},
	{
		garage_id: 246,
		part_id: 261,
		quantity: 87,
	},
	{
		garage_id: 246,
		part_id: 262,
		quantity: 334,
	},
	{
		garage_id: 246,
		part_id: 263,
		quantity: 276,
	},
	{
		garage_id: 246,
		part_id: 264,
		quantity: 452,
	},
	{
		garage_id: 246,
		part_id: 265,
		quantity: 13,
	},
	{
		garage_id: 246,
		part_id: 266,
		quantity: 39,
	},
	{
		garage_id: 246,
		part_id: 267,
		quantity: 479,
	},
	{
		garage_id: 246,
		part_id: 268,
		quantity: 29,
	},
	{
		garage_id: 246,
		part_id: 269,
		quantity: 76,
	},
	{
		garage_id: 246,
		part_id: 270,
		quantity: 34,
	},
	{
		garage_id: 246,
		part_id: 271,
		quantity: 166,
	},
	{
		garage_id: 246,
		part_id: 272,
		quantity: 326,
	},
	{
		garage_id: 246,
		part_id: 273,
		quantity: 333,
	},
	{
		garage_id: 246,
		part_id: 274,
		quantity: 312,
	},
	{
		garage_id: 246,
		part_id: 275,
		quantity: 389,
	},
	{
		garage_id: 246,
		part_id: 276,
		quantity: 75,
	},
	{
		garage_id: 246,
		part_id: 277,
		quantity: 163,
	},
	{
		garage_id: 246,
		part_id: 278,
		quantity: 349,
	},
	{
		garage_id: 246,
		part_id: 279,
		quantity: 424,
	},
	{
		garage_id: 246,
		part_id: 280,
		quantity: 476,
	},
	{
		garage_id: 246,
		part_id: 281,
		quantity: 117,
	},
	{
		garage_id: 246,
		part_id: 282,
		quantity: 488,
	},
	{
		garage_id: 246,
		part_id: 283,
		quantity: 438,
	},
	{
		garage_id: 246,
		part_id: 284,
		quantity: 464,
	},
	{
		garage_id: 246,
		part_id: 285,
		quantity: 197,
	},
	{
		garage_id: 246,
		part_id: 286,
		quantity: 31,
	},
	{
		garage_id: 246,
		part_id: 287,
		quantity: 237,
	},
	{
		garage_id: 246,
		part_id: 288,
		quantity: 66,
	},
	{
		garage_id: 246,
		part_id: 289,
		quantity: 21,
	},
	{
		garage_id: 246,
		part_id: 290,
		quantity: 202,
	},
	{
		garage_id: 246,
		part_id: 291,
		quantity: 478,
	},
	{
		garage_id: 246,
		part_id: 292,
		quantity: 255,
	},
	{
		garage_id: 246,
		part_id: 293,
		quantity: 384,
	},
	{
		garage_id: 246,
		part_id: 294,
		quantity: 457,
	},
	{
		garage_id: 246,
		part_id: 295,
		quantity: 106,
	},
	{
		garage_id: 246,
		part_id: 296,
		quantity: 242,
	},
	{
		garage_id: 246,
		part_id: 297,
		quantity: 40,
	},
	{
		garage_id: 246,
		part_id: 298,
		quantity: 240,
	},
	{
		garage_id: 246,
		part_id: 299,
		quantity: 412,
	},
	{
		garage_id: 246,
		part_id: 300,
		quantity: 480,
	},
	{
		garage_id: 246,
		part_id: 301,
		quantity: 248,
	},
	{
		garage_id: 246,
		part_id: 302,
		quantity: 370,
	},
	{
		garage_id: 246,
		part_id: 303,
		quantity: 286,
	},
	{
		garage_id: 246,
		part_id: 304,
		quantity: 121,
	},
	{
		garage_id: 246,
		part_id: 305,
		quantity: 485,
	},
	{
		garage_id: 246,
		part_id: 306,
		quantity: 457,
	},
	{
		garage_id: 246,
		part_id: 307,
		quantity: 366,
	},
	{
		garage_id: 246,
		part_id: 308,
		quantity: 450,
	},
	{
		garage_id: 246,
		part_id: 309,
		quantity: 376,
	},
	{
		garage_id: 246,
		part_id: 310,
		quantity: 458,
	},
	{
		garage_id: 246,
		part_id: 311,
		quantity: 190,
	},
	{
		garage_id: 246,
		part_id: 312,
		quantity: 61,
	},
	{
		garage_id: 246,
		part_id: 313,
		quantity: 355,
	},
	{
		garage_id: 246,
		part_id: 314,
		quantity: 436,
	},
	{
		garage_id: 246,
		part_id: 315,
		quantity: 0,
	},
	{
		garage_id: 246,
		part_id: 316,
		quantity: 287,
	},
	{
		garage_id: 246,
		part_id: 317,
		quantity: 439,
	},
	{
		garage_id: 246,
		part_id: 318,
		quantity: 449,
	},
	{
		garage_id: 246,
		part_id: 319,
		quantity: 258,
	},
	{
		garage_id: 246,
		part_id: 320,
		quantity: 313,
	},
	{
		garage_id: 246,
		part_id: 321,
		quantity: 176,
	},
	{
		garage_id: 246,
		part_id: 322,
		quantity: 429,
	},
	{
		garage_id: 246,
		part_id: 323,
		quantity: 468,
	},
	{
		garage_id: 246,
		part_id: 324,
		quantity: 500,
	},
	{
		garage_id: 246,
		part_id: 325,
		quantity: 285,
	},
	{
		garage_id: 246,
		part_id: 326,
		quantity: 123,
	},
	{
		garage_id: 246,
		part_id: 327,
		quantity: 241,
	},
	{
		garage_id: 246,
		part_id: 328,
		quantity: 54,
	},
	{
		garage_id: 246,
		part_id: 329,
		quantity: 50,
	},
	{
		garage_id: 246,
		part_id: 330,
		quantity: 389,
	},
	{
		garage_id: 246,
		part_id: 331,
		quantity: 407,
	},
	{
		garage_id: 246,
		part_id: 332,
		quantity: 82,
	},
	{
		garage_id: 246,
		part_id: 333,
		quantity: 287,
	},
	{
		garage_id: 246,
		part_id: 334,
		quantity: 154,
	},
	{
		garage_id: 246,
		part_id: 335,
		quantity: 212,
	},
	{
		garage_id: 246,
		part_id: 336,
		quantity: 238,
	},
	{
		garage_id: 246,
		part_id: 337,
		quantity: 164,
	},
	{
		garage_id: 246,
		part_id: 338,
		quantity: 61,
	},
	{
		garage_id: 246,
		part_id: 339,
		quantity: 283,
	},
	{
		garage_id: 246,
		part_id: 340,
		quantity: 422,
	},
	{
		garage_id: 246,
		part_id: 341,
		quantity: 382,
	},
	{
		garage_id: 246,
		part_id: 342,
		quantity: 191,
	},
	{
		garage_id: 246,
		part_id: 343,
		quantity: 86,
	},
	{
		garage_id: 246,
		part_id: 344,
		quantity: 170,
	},
	{
		garage_id: 246,
		part_id: 345,
		quantity: 211,
	},
	{
		garage_id: 246,
		part_id: 346,
		quantity: 488,
	},
	{
		garage_id: 246,
		part_id: 347,
		quantity: 371,
	},
	{
		garage_id: 246,
		part_id: 348,
		quantity: 228,
	},
	{
		garage_id: 246,
		part_id: 349,
		quantity: 470,
	},
	{
		garage_id: 246,
		part_id: 350,
		quantity: 368,
	},
	{
		garage_id: 246,
		part_id: 351,
		quantity: 45,
	},
	{
		garage_id: 246,
		part_id: 352,
		quantity: 64,
	},
	{
		garage_id: 246,
		part_id: 353,
		quantity: 307,
	},
	{
		garage_id: 246,
		part_id: 354,
		quantity: 369,
	},
	{
		garage_id: 246,
		part_id: 355,
		quantity: 193,
	},
	{
		garage_id: 246,
		part_id: 356,
		quantity: 346,
	},
	{
		garage_id: 246,
		part_id: 357,
		quantity: 183,
	},
	{
		garage_id: 246,
		part_id: 358,
		quantity: 472,
	},
	{
		garage_id: 246,
		part_id: 359,
		quantity: 445,
	},
	{
		garage_id: 246,
		part_id: 360,
		quantity: 80,
	},
	{
		garage_id: 246,
		part_id: 361,
		quantity: 402,
	},
	{
		garage_id: 246,
		part_id: 362,
		quantity: 157,
	},
	{
		garage_id: 246,
		part_id: 363,
		quantity: 387,
	},
	{
		garage_id: 246,
		part_id: 364,
		quantity: 176,
	},
	{
		garage_id: 246,
		part_id: 365,
		quantity: 30,
	},
	{
		garage_id: 246,
		part_id: 366,
		quantity: 189,
	},
	{
		garage_id: 246,
		part_id: 367,
		quantity: 302,
	},
	{
		garage_id: 246,
		part_id: 368,
		quantity: 1,
	},
	{
		garage_id: 246,
		part_id: 369,
		quantity: 240,
	},
	{
		garage_id: 246,
		part_id: 370,
		quantity: 391,
	},
	{
		garage_id: 246,
		part_id: 371,
		quantity: 241,
	},
	{
		garage_id: 246,
		part_id: 372,
		quantity: 401,
	},
	{
		garage_id: 246,
		part_id: 373,
		quantity: 433,
	},
	{
		garage_id: 246,
		part_id: 374,
		quantity: 301,
	},
	{
		garage_id: 246,
		part_id: 375,
		quantity: 340,
	},
	{
		garage_id: 246,
		part_id: 376,
		quantity: 184,
	},
	{
		garage_id: 246,
		part_id: 377,
		quantity: 447,
	},
	{
		garage_id: 246,
		part_id: 378,
		quantity: 248,
	},
	{
		garage_id: 246,
		part_id: 379,
		quantity: 177,
	},
	{
		garage_id: 246,
		part_id: 380,
		quantity: 127,
	},
	{
		garage_id: 246,
		part_id: 381,
		quantity: 174,
	},
	{
		garage_id: 246,
		part_id: 382,
		quantity: 53,
	},
	{
		garage_id: 246,
		part_id: 383,
		quantity: 51,
	},
	{
		garage_id: 246,
		part_id: 384,
		quantity: 83,
	},
	{
		garage_id: 246,
		part_id: 385,
		quantity: 199,
	},
	{
		garage_id: 246,
		part_id: 386,
		quantity: 190,
	},
	{
		garage_id: 246,
		part_id: 387,
		quantity: 171,
	},
	{
		garage_id: 246,
		part_id: 388,
		quantity: 213,
	},
	{
		garage_id: 246,
		part_id: 389,
		quantity: 323,
	},
	{
		garage_id: 246,
		part_id: 390,
		quantity: 7,
	},
	{
		garage_id: 246,
		part_id: 391,
		quantity: 105,
	},
	{
		garage_id: 246,
		part_id: 392,
		quantity: 21,
	},
	{
		garage_id: 246,
		part_id: 393,
		quantity: 117,
	},
	{
		garage_id: 246,
		part_id: 394,
		quantity: 374,
	},
	{
		garage_id: 246,
		part_id: 395,
		quantity: 191,
	},
	{
		garage_id: 246,
		part_id: 396,
		quantity: 331,
	},
	{
		garage_id: 246,
		part_id: 397,
		quantity: 224,
	},
	{
		garage_id: 246,
		part_id: 398,
		quantity: 206,
	},
	{
		garage_id: 246,
		part_id: 399,
		quantity: 312,
	},
	{
		garage_id: 247,
		part_id: 0,
		quantity: 72,
	},
	{
		garage_id: 247,
		part_id: 1,
		quantity: 360,
	},
	{
		garage_id: 247,
		part_id: 2,
		quantity: 146,
	},
	{
		garage_id: 247,
		part_id: 3,
		quantity: 454,
	},
	{
		garage_id: 247,
		part_id: 4,
		quantity: 393,
	},
	{
		garage_id: 247,
		part_id: 5,
		quantity: 477,
	},
	{
		garage_id: 247,
		part_id: 6,
		quantity: 313,
	},
	{
		garage_id: 247,
		part_id: 7,
		quantity: 171,
	},
	{
		garage_id: 247,
		part_id: 8,
		quantity: 413,
	},
	{
		garage_id: 247,
		part_id: 9,
		quantity: 239,
	},
	{
		garage_id: 247,
		part_id: 10,
		quantity: 445,
	},
	{
		garage_id: 247,
		part_id: 11,
		quantity: 157,
	},
	{
		garage_id: 247,
		part_id: 12,
		quantity: 485,
	},
	{
		garage_id: 247,
		part_id: 13,
		quantity: 86,
	},
	{
		garage_id: 247,
		part_id: 14,
		quantity: 383,
	},
	{
		garage_id: 247,
		part_id: 15,
		quantity: 182,
	},
	{
		garage_id: 247,
		part_id: 16,
		quantity: 477,
	},
	{
		garage_id: 247,
		part_id: 17,
		quantity: 373,
	},
	{
		garage_id: 247,
		part_id: 18,
		quantity: 236,
	},
	{
		garage_id: 247,
		part_id: 19,
		quantity: 270,
	},
	{
		garage_id: 247,
		part_id: 20,
		quantity: 84,
	},
	{
		garage_id: 247,
		part_id: 21,
		quantity: 476,
	},
	{
		garage_id: 247,
		part_id: 22,
		quantity: 35,
	},
	{
		garage_id: 247,
		part_id: 23,
		quantity: 9,
	},
	{
		garage_id: 247,
		part_id: 24,
		quantity: 404,
	},
	{
		garage_id: 247,
		part_id: 25,
		quantity: 41,
	},
	{
		garage_id: 247,
		part_id: 26,
		quantity: 220,
	},
	{
		garage_id: 247,
		part_id: 27,
		quantity: 410,
	},
	{
		garage_id: 247,
		part_id: 28,
		quantity: 283,
	},
	{
		garage_id: 247,
		part_id: 29,
		quantity: 115,
	},
	{
		garage_id: 247,
		part_id: 30,
		quantity: 156,
	},
	{
		garage_id: 247,
		part_id: 31,
		quantity: 54,
	},
	{
		garage_id: 247,
		part_id: 32,
		quantity: 267,
	},
	{
		garage_id: 247,
		part_id: 33,
		quantity: 41,
	},
	{
		garage_id: 247,
		part_id: 34,
		quantity: 323,
	},
	{
		garage_id: 247,
		part_id: 35,
		quantity: 381,
	},
	{
		garage_id: 247,
		part_id: 36,
		quantity: 421,
	},
	{
		garage_id: 247,
		part_id: 37,
		quantity: 431,
	},
	{
		garage_id: 247,
		part_id: 38,
		quantity: 101,
	},
	{
		garage_id: 247,
		part_id: 39,
		quantity: 478,
	},
	{
		garage_id: 247,
		part_id: 40,
		quantity: 313,
	},
	{
		garage_id: 247,
		part_id: 41,
		quantity: 327,
	},
	{
		garage_id: 247,
		part_id: 42,
		quantity: 262,
	},
	{
		garage_id: 247,
		part_id: 43,
		quantity: 239,
	},
	{
		garage_id: 247,
		part_id: 44,
		quantity: 297,
	},
	{
		garage_id: 247,
		part_id: 45,
		quantity: 393,
	},
	{
		garage_id: 247,
		part_id: 46,
		quantity: 196,
	},
	{
		garage_id: 247,
		part_id: 47,
		quantity: 131,
	},
	{
		garage_id: 247,
		part_id: 48,
		quantity: 129,
	},
	{
		garage_id: 247,
		part_id: 49,
		quantity: 326,
	},
	{
		garage_id: 247,
		part_id: 50,
		quantity: 322,
	},
	{
		garage_id: 247,
		part_id: 51,
		quantity: 427,
	},
	{
		garage_id: 247,
		part_id: 52,
		quantity: 380,
	},
	{
		garage_id: 247,
		part_id: 53,
		quantity: 25,
	},
	{
		garage_id: 247,
		part_id: 54,
		quantity: 62,
	},
	{
		garage_id: 247,
		part_id: 55,
		quantity: 253,
	},
	{
		garage_id: 247,
		part_id: 56,
		quantity: 264,
	},
	{
		garage_id: 247,
		part_id: 57,
		quantity: 174,
	},
	{
		garage_id: 247,
		part_id: 58,
		quantity: 240,
	},
	{
		garage_id: 247,
		part_id: 59,
		quantity: 498,
	},
	{
		garage_id: 247,
		part_id: 60,
		quantity: 260,
	},
	{
		garage_id: 247,
		part_id: 61,
		quantity: 339,
	},
	{
		garage_id: 247,
		part_id: 62,
		quantity: 33,
	},
	{
		garage_id: 247,
		part_id: 63,
		quantity: 42,
	},
	{
		garage_id: 247,
		part_id: 64,
		quantity: 87,
	},
	{
		garage_id: 247,
		part_id: 65,
		quantity: 296,
	},
	{
		garage_id: 247,
		part_id: 66,
		quantity: 423,
	},
	{
		garage_id: 247,
		part_id: 67,
		quantity: 254,
	},
	{
		garage_id: 247,
		part_id: 68,
		quantity: 65,
	},
	{
		garage_id: 247,
		part_id: 69,
		quantity: 48,
	},
	{
		garage_id: 247,
		part_id: 70,
		quantity: 226,
	},
	{
		garage_id: 247,
		part_id: 71,
		quantity: 200,
	},
	{
		garage_id: 247,
		part_id: 72,
		quantity: 462,
	},
	{
		garage_id: 247,
		part_id: 73,
		quantity: 68,
	},
	{
		garage_id: 247,
		part_id: 74,
		quantity: 31,
	},
	{
		garage_id: 247,
		part_id: 75,
		quantity: 494,
	},
	{
		garage_id: 247,
		part_id: 76,
		quantity: 272,
	},
	{
		garage_id: 247,
		part_id: 77,
		quantity: 309,
	},
	{
		garage_id: 247,
		part_id: 78,
		quantity: 89,
	},
	{
		garage_id: 247,
		part_id: 79,
		quantity: 434,
	},
	{
		garage_id: 247,
		part_id: 80,
		quantity: 469,
	},
	{
		garage_id: 247,
		part_id: 81,
		quantity: 161,
	},
	{
		garage_id: 247,
		part_id: 82,
		quantity: 196,
	},
	{
		garage_id: 247,
		part_id: 83,
		quantity: 335,
	},
	{
		garage_id: 247,
		part_id: 84,
		quantity: 220,
	},
	{
		garage_id: 247,
		part_id: 85,
		quantity: 151,
	},
	{
		garage_id: 247,
		part_id: 86,
		quantity: 364,
	},
	{
		garage_id: 247,
		part_id: 87,
		quantity: 293,
	},
	{
		garage_id: 247,
		part_id: 88,
		quantity: 210,
	},
	{
		garage_id: 247,
		part_id: 89,
		quantity: 119,
	},
	{
		garage_id: 247,
		part_id: 90,
		quantity: 67,
	},
	{
		garage_id: 247,
		part_id: 91,
		quantity: 276,
	},
	{
		garage_id: 247,
		part_id: 92,
		quantity: 293,
	},
	{
		garage_id: 247,
		part_id: 93,
		quantity: 169,
	},
	{
		garage_id: 247,
		part_id: 94,
		quantity: 297,
	},
	{
		garage_id: 247,
		part_id: 95,
		quantity: 268,
	},
	{
		garage_id: 247,
		part_id: 96,
		quantity: 95,
	},
	{
		garage_id: 247,
		part_id: 97,
		quantity: 475,
	},
	{
		garage_id: 247,
		part_id: 98,
		quantity: 225,
	},
	{
		garage_id: 247,
		part_id: 99,
		quantity: 280,
	},
	{
		garage_id: 247,
		part_id: 100,
		quantity: 357,
	},
	{
		garage_id: 247,
		part_id: 101,
		quantity: 9,
	},
	{
		garage_id: 247,
		part_id: 102,
		quantity: 436,
	},
	{
		garage_id: 247,
		part_id: 103,
		quantity: 288,
	},
	{
		garage_id: 247,
		part_id: 104,
		quantity: 466,
	},
	{
		garage_id: 247,
		part_id: 105,
		quantity: 369,
	},
	{
		garage_id: 247,
		part_id: 106,
		quantity: 316,
	},
	{
		garage_id: 247,
		part_id: 107,
		quantity: 426,
	},
	{
		garage_id: 247,
		part_id: 108,
		quantity: 190,
	},
	{
		garage_id: 247,
		part_id: 109,
		quantity: 164,
	},
	{
		garage_id: 247,
		part_id: 110,
		quantity: 285,
	},
	{
		garage_id: 247,
		part_id: 111,
		quantity: 447,
	},
	{
		garage_id: 247,
		part_id: 112,
		quantity: 259,
	},
	{
		garage_id: 247,
		part_id: 113,
		quantity: 199,
	},
	{
		garage_id: 247,
		part_id: 114,
		quantity: 53,
	},
	{
		garage_id: 247,
		part_id: 115,
		quantity: 135,
	},
	{
		garage_id: 247,
		part_id: 116,
		quantity: 153,
	},
	{
		garage_id: 247,
		part_id: 117,
		quantity: 459,
	},
	{
		garage_id: 247,
		part_id: 118,
		quantity: 304,
	},
	{
		garage_id: 247,
		part_id: 119,
		quantity: 70,
	},
	{
		garage_id: 247,
		part_id: 120,
		quantity: 310,
	},
	{
		garage_id: 247,
		part_id: 121,
		quantity: 477,
	},
	{
		garage_id: 247,
		part_id: 122,
		quantity: 395,
	},
	{
		garage_id: 247,
		part_id: 123,
		quantity: 196,
	},
	{
		garage_id: 247,
		part_id: 124,
		quantity: 179,
	},
	{
		garage_id: 247,
		part_id: 125,
		quantity: 67,
	},
	{
		garage_id: 247,
		part_id: 126,
		quantity: 48,
	},
	{
		garage_id: 247,
		part_id: 127,
		quantity: 370,
	},
	{
		garage_id: 247,
		part_id: 128,
		quantity: 177,
	},
	{
		garage_id: 247,
		part_id: 129,
		quantity: 497,
	},
	{
		garage_id: 247,
		part_id: 130,
		quantity: 177,
	},
	{
		garage_id: 247,
		part_id: 131,
		quantity: 129,
	},
	{
		garage_id: 247,
		part_id: 132,
		quantity: 457,
	},
	{
		garage_id: 247,
		part_id: 133,
		quantity: 332,
	},
	{
		garage_id: 247,
		part_id: 134,
		quantity: 205,
	},
	{
		garage_id: 247,
		part_id: 135,
		quantity: 415,
	},
	{
		garage_id: 247,
		part_id: 136,
		quantity: 42,
	},
	{
		garage_id: 247,
		part_id: 137,
		quantity: 333,
	},
	{
		garage_id: 247,
		part_id: 138,
		quantity: 464,
	},
	{
		garage_id: 247,
		part_id: 139,
		quantity: 307,
	},
	{
		garage_id: 247,
		part_id: 140,
		quantity: 120,
	},
	{
		garage_id: 247,
		part_id: 141,
		quantity: 231,
	},
	{
		garage_id: 247,
		part_id: 142,
		quantity: 318,
	},
	{
		garage_id: 247,
		part_id: 143,
		quantity: 117,
	},
	{
		garage_id: 247,
		part_id: 144,
		quantity: 61,
	},
	{
		garage_id: 247,
		part_id: 145,
		quantity: 54,
	},
	{
		garage_id: 247,
		part_id: 146,
		quantity: 444,
	},
	{
		garage_id: 247,
		part_id: 147,
		quantity: 360,
	},
	{
		garage_id: 247,
		part_id: 148,
		quantity: 6,
	},
	{
		garage_id: 247,
		part_id: 149,
		quantity: 208,
	},
	{
		garage_id: 247,
		part_id: 150,
		quantity: 26,
	},
	{
		garage_id: 247,
		part_id: 151,
		quantity: 16,
	},
	{
		garage_id: 247,
		part_id: 152,
		quantity: 292,
	},
	{
		garage_id: 247,
		part_id: 153,
		quantity: 155,
	},
	{
		garage_id: 247,
		part_id: 154,
		quantity: 378,
	},
	{
		garage_id: 247,
		part_id: 155,
		quantity: 372,
	},
	{
		garage_id: 247,
		part_id: 156,
		quantity: 211,
	},
	{
		garage_id: 247,
		part_id: 157,
		quantity: 355,
	},
	{
		garage_id: 247,
		part_id: 158,
		quantity: 56,
	},
	{
		garage_id: 247,
		part_id: 159,
		quantity: 136,
	},
	{
		garage_id: 247,
		part_id: 160,
		quantity: 150,
	},
	{
		garage_id: 247,
		part_id: 161,
		quantity: 74,
	},
	{
		garage_id: 247,
		part_id: 162,
		quantity: 105,
	},
	{
		garage_id: 247,
		part_id: 163,
		quantity: 197,
	},
	{
		garage_id: 247,
		part_id: 164,
		quantity: 171,
	},
	{
		garage_id: 247,
		part_id: 165,
		quantity: 362,
	},
	{
		garage_id: 247,
		part_id: 166,
		quantity: 303,
	},
	{
		garage_id: 247,
		part_id: 167,
		quantity: 332,
	},
	{
		garage_id: 247,
		part_id: 168,
		quantity: 485,
	},
	{
		garage_id: 247,
		part_id: 169,
		quantity: 311,
	},
	{
		garage_id: 247,
		part_id: 170,
		quantity: 2,
	},
	{
		garage_id: 247,
		part_id: 171,
		quantity: 266,
	},
	{
		garage_id: 247,
		part_id: 172,
		quantity: 255,
	},
	{
		garage_id: 247,
		part_id: 173,
		quantity: 235,
	},
	{
		garage_id: 247,
		part_id: 174,
		quantity: 231,
	},
	{
		garage_id: 247,
		part_id: 175,
		quantity: 64,
	},
	{
		garage_id: 247,
		part_id: 176,
		quantity: 359,
	},
	{
		garage_id: 247,
		part_id: 177,
		quantity: 480,
	},
	{
		garage_id: 247,
		part_id: 178,
		quantity: 146,
	},
	{
		garage_id: 247,
		part_id: 179,
		quantity: 482,
	},
	{
		garage_id: 247,
		part_id: 180,
		quantity: 102,
	},
	{
		garage_id: 247,
		part_id: 181,
		quantity: 182,
	},
	{
		garage_id: 247,
		part_id: 182,
		quantity: 205,
	},
	{
		garage_id: 247,
		part_id: 183,
		quantity: 140,
	},
	{
		garage_id: 247,
		part_id: 184,
		quantity: 86,
	},
	{
		garage_id: 247,
		part_id: 185,
		quantity: 403,
	},
	{
		garage_id: 247,
		part_id: 186,
		quantity: 239,
	},
	{
		garage_id: 247,
		part_id: 187,
		quantity: 170,
	},
	{
		garage_id: 247,
		part_id: 188,
		quantity: 179,
	},
	{
		garage_id: 247,
		part_id: 189,
		quantity: 16,
	},
	{
		garage_id: 247,
		part_id: 190,
		quantity: 254,
	},
	{
		garage_id: 247,
		part_id: 191,
		quantity: 174,
	},
	{
		garage_id: 247,
		part_id: 192,
		quantity: 46,
	},
	{
		garage_id: 247,
		part_id: 193,
		quantity: 339,
	},
	{
		garage_id: 247,
		part_id: 194,
		quantity: 214,
	},
	{
		garage_id: 247,
		part_id: 195,
		quantity: 274,
	},
	{
		garage_id: 247,
		part_id: 196,
		quantity: 335,
	},
	{
		garage_id: 247,
		part_id: 197,
		quantity: 172,
	},
	{
		garage_id: 247,
		part_id: 198,
		quantity: 102,
	},
	{
		garage_id: 247,
		part_id: 199,
		quantity: 310,
	},
	{
		garage_id: 247,
		part_id: 200,
		quantity: 210,
	},
	{
		garage_id: 247,
		part_id: 201,
		quantity: 279,
	},
	{
		garage_id: 247,
		part_id: 202,
		quantity: 91,
	},
	{
		garage_id: 247,
		part_id: 203,
		quantity: 253,
	},
	{
		garage_id: 247,
		part_id: 204,
		quantity: 354,
	},
	{
		garage_id: 247,
		part_id: 205,
		quantity: 129,
	},
	{
		garage_id: 247,
		part_id: 206,
		quantity: 340,
	},
	{
		garage_id: 247,
		part_id: 207,
		quantity: 289,
	},
	{
		garage_id: 247,
		part_id: 208,
		quantity: 364,
	},
	{
		garage_id: 247,
		part_id: 209,
		quantity: 175,
	},
	{
		garage_id: 247,
		part_id: 210,
		quantity: 7,
	},
	{
		garage_id: 247,
		part_id: 211,
		quantity: 174,
	},
	{
		garage_id: 247,
		part_id: 212,
		quantity: 457,
	},
	{
		garage_id: 247,
		part_id: 213,
		quantity: 372,
	},
	{
		garage_id: 247,
		part_id: 214,
		quantity: 327,
	},
	{
		garage_id: 247,
		part_id: 215,
		quantity: 351,
	},
	{
		garage_id: 247,
		part_id: 216,
		quantity: 183,
	},
	{
		garage_id: 247,
		part_id: 217,
		quantity: 488,
	},
	{
		garage_id: 247,
		part_id: 218,
		quantity: 343,
	},
	{
		garage_id: 247,
		part_id: 219,
		quantity: 45,
	},
	{
		garage_id: 247,
		part_id: 220,
		quantity: 94,
	},
	{
		garage_id: 247,
		part_id: 221,
		quantity: 168,
	},
	{
		garage_id: 247,
		part_id: 222,
		quantity: 23,
	},
	{
		garage_id: 247,
		part_id: 223,
		quantity: 302,
	},
	{
		garage_id: 247,
		part_id: 224,
		quantity: 85,
	},
	{
		garage_id: 247,
		part_id: 225,
		quantity: 229,
	},
	{
		garage_id: 247,
		part_id: 226,
		quantity: 247,
	},
	{
		garage_id: 247,
		part_id: 227,
		quantity: 212,
	},
	{
		garage_id: 247,
		part_id: 228,
		quantity: 281,
	},
	{
		garage_id: 247,
		part_id: 229,
		quantity: 220,
	},
	{
		garage_id: 247,
		part_id: 230,
		quantity: 292,
	},
	{
		garage_id: 247,
		part_id: 231,
		quantity: 443,
	},
	{
		garage_id: 247,
		part_id: 232,
		quantity: 55,
	},
	{
		garage_id: 247,
		part_id: 233,
		quantity: 492,
	},
	{
		garage_id: 247,
		part_id: 234,
		quantity: 443,
	},
	{
		garage_id: 247,
		part_id: 235,
		quantity: 435,
	},
	{
		garage_id: 247,
		part_id: 236,
		quantity: 2,
	},
	{
		garage_id: 247,
		part_id: 237,
		quantity: 26,
	},
	{
		garage_id: 247,
		part_id: 238,
		quantity: 29,
	},
	{
		garage_id: 247,
		part_id: 239,
		quantity: 353,
	},
	{
		garage_id: 247,
		part_id: 240,
		quantity: 90,
	},
	{
		garage_id: 247,
		part_id: 241,
		quantity: 453,
	},
	{
		garage_id: 247,
		part_id: 242,
		quantity: 439,
	},
	{
		garage_id: 247,
		part_id: 243,
		quantity: 442,
	},
	{
		garage_id: 247,
		part_id: 244,
		quantity: 289,
	},
	{
		garage_id: 247,
		part_id: 245,
		quantity: 212,
	},
	{
		garage_id: 247,
		part_id: 246,
		quantity: 470,
	},
	{
		garage_id: 247,
		part_id: 247,
		quantity: 399,
	},
	{
		garage_id: 247,
		part_id: 248,
		quantity: 432,
	},
	{
		garage_id: 247,
		part_id: 249,
		quantity: 90,
	},
	{
		garage_id: 247,
		part_id: 250,
		quantity: 403,
	},
	{
		garage_id: 247,
		part_id: 251,
		quantity: 153,
	},
	{
		garage_id: 247,
		part_id: 252,
		quantity: 22,
	},
	{
		garage_id: 247,
		part_id: 253,
		quantity: 466,
	},
	{
		garage_id: 247,
		part_id: 254,
		quantity: 266,
	},
	{
		garage_id: 247,
		part_id: 255,
		quantity: 367,
	},
	{
		garage_id: 247,
		part_id: 256,
		quantity: 427,
	},
	{
		garage_id: 247,
		part_id: 257,
		quantity: 237,
	},
	{
		garage_id: 247,
		part_id: 258,
		quantity: 119,
	},
	{
		garage_id: 247,
		part_id: 259,
		quantity: 200,
	},
	{
		garage_id: 247,
		part_id: 260,
		quantity: 159,
	},
	{
		garage_id: 247,
		part_id: 261,
		quantity: 297,
	},
	{
		garage_id: 247,
		part_id: 262,
		quantity: 249,
	},
	{
		garage_id: 247,
		part_id: 263,
		quantity: 341,
	},
	{
		garage_id: 247,
		part_id: 264,
		quantity: 364,
	},
	{
		garage_id: 247,
		part_id: 265,
		quantity: 388,
	},
	{
		garage_id: 247,
		part_id: 266,
		quantity: 39,
	},
	{
		garage_id: 247,
		part_id: 267,
		quantity: 41,
	},
	{
		garage_id: 247,
		part_id: 268,
		quantity: 286,
	},
	{
		garage_id: 247,
		part_id: 269,
		quantity: 216,
	},
	{
		garage_id: 247,
		part_id: 270,
		quantity: 96,
	},
	{
		garage_id: 247,
		part_id: 271,
		quantity: 53,
	},
	{
		garage_id: 247,
		part_id: 272,
		quantity: 143,
	},
	{
		garage_id: 247,
		part_id: 273,
		quantity: 396,
	},
	{
		garage_id: 247,
		part_id: 274,
		quantity: 75,
	},
	{
		garage_id: 247,
		part_id: 275,
		quantity: 15,
	},
	{
		garage_id: 247,
		part_id: 276,
		quantity: 128,
	},
	{
		garage_id: 247,
		part_id: 277,
		quantity: 92,
	},
	{
		garage_id: 247,
		part_id: 278,
		quantity: 499,
	},
	{
		garage_id: 247,
		part_id: 279,
		quantity: 184,
	},
	{
		garage_id: 247,
		part_id: 280,
		quantity: 310,
	},
	{
		garage_id: 247,
		part_id: 281,
		quantity: 322,
	},
	{
		garage_id: 247,
		part_id: 282,
		quantity: 256,
	},
	{
		garage_id: 247,
		part_id: 283,
		quantity: 309,
	},
	{
		garage_id: 247,
		part_id: 284,
		quantity: 159,
	},
	{
		garage_id: 247,
		part_id: 285,
		quantity: 303,
	},
	{
		garage_id: 247,
		part_id: 286,
		quantity: 370,
	},
	{
		garage_id: 247,
		part_id: 287,
		quantity: 237,
	},
	{
		garage_id: 247,
		part_id: 288,
		quantity: 27,
	},
	{
		garage_id: 247,
		part_id: 289,
		quantity: 396,
	},
	{
		garage_id: 247,
		part_id: 290,
		quantity: 412,
	},
	{
		garage_id: 247,
		part_id: 291,
		quantity: 27,
	},
	{
		garage_id: 247,
		part_id: 292,
		quantity: 281,
	},
	{
		garage_id: 247,
		part_id: 293,
		quantity: 51,
	},
	{
		garage_id: 247,
		part_id: 294,
		quantity: 388,
	},
	{
		garage_id: 247,
		part_id: 295,
		quantity: 356,
	},
	{
		garage_id: 247,
		part_id: 296,
		quantity: 23,
	},
	{
		garage_id: 247,
		part_id: 297,
		quantity: 49,
	},
	{
		garage_id: 247,
		part_id: 298,
		quantity: 282,
	},
	{
		garage_id: 247,
		part_id: 299,
		quantity: 105,
	},
	{
		garage_id: 247,
		part_id: 300,
		quantity: 33,
	},
	{
		garage_id: 247,
		part_id: 301,
		quantity: 303,
	},
	{
		garage_id: 247,
		part_id: 302,
		quantity: 303,
	},
	{
		garage_id: 247,
		part_id: 303,
		quantity: 462,
	},
	{
		garage_id: 247,
		part_id: 304,
		quantity: 78,
	},
	{
		garage_id: 247,
		part_id: 305,
		quantity: 173,
	},
	{
		garage_id: 247,
		part_id: 306,
		quantity: 22,
	},
	{
		garage_id: 247,
		part_id: 307,
		quantity: 238,
	},
	{
		garage_id: 247,
		part_id: 308,
		quantity: 372,
	},
	{
		garage_id: 247,
		part_id: 309,
		quantity: 409,
	},
	{
		garage_id: 247,
		part_id: 310,
		quantity: 256,
	},
	{
		garage_id: 247,
		part_id: 311,
		quantity: 111,
	},
	{
		garage_id: 247,
		part_id: 312,
		quantity: 198,
	},
	{
		garage_id: 247,
		part_id: 313,
		quantity: 376,
	},
	{
		garage_id: 247,
		part_id: 314,
		quantity: 207,
	},
	{
		garage_id: 247,
		part_id: 315,
		quantity: 329,
	},
	{
		garage_id: 247,
		part_id: 316,
		quantity: 227,
	},
	{
		garage_id: 247,
		part_id: 317,
		quantity: 290,
	},
	{
		garage_id: 247,
		part_id: 318,
		quantity: 365,
	},
	{
		garage_id: 247,
		part_id: 319,
		quantity: 491,
	},
	{
		garage_id: 247,
		part_id: 320,
		quantity: 209,
	},
	{
		garage_id: 247,
		part_id: 321,
		quantity: 477,
	},
	{
		garage_id: 247,
		part_id: 322,
		quantity: 322,
	},
	{
		garage_id: 247,
		part_id: 323,
		quantity: 206,
	},
	{
		garage_id: 247,
		part_id: 324,
		quantity: 77,
	},
	{
		garage_id: 247,
		part_id: 325,
		quantity: 425,
	},
	{
		garage_id: 247,
		part_id: 326,
		quantity: 78,
	},
	{
		garage_id: 247,
		part_id: 327,
		quantity: 460,
	},
	{
		garage_id: 247,
		part_id: 328,
		quantity: 329,
	},
	{
		garage_id: 247,
		part_id: 329,
		quantity: 64,
	},
	{
		garage_id: 247,
		part_id: 330,
		quantity: 335,
	},
	{
		garage_id: 247,
		part_id: 331,
		quantity: 494,
	},
	{
		garage_id: 247,
		part_id: 332,
		quantity: 292,
	},
	{
		garage_id: 247,
		part_id: 333,
		quantity: 84,
	},
	{
		garage_id: 247,
		part_id: 334,
		quantity: 315,
	},
	{
		garage_id: 247,
		part_id: 335,
		quantity: 224,
	},
	{
		garage_id: 247,
		part_id: 336,
		quantity: 491,
	},
	{
		garage_id: 247,
		part_id: 337,
		quantity: 48,
	},
	{
		garage_id: 247,
		part_id: 338,
		quantity: 380,
	},
	{
		garage_id: 247,
		part_id: 339,
		quantity: 161,
	},
	{
		garage_id: 247,
		part_id: 340,
		quantity: 3,
	},
	{
		garage_id: 247,
		part_id: 341,
		quantity: 83,
	},
	{
		garage_id: 247,
		part_id: 342,
		quantity: 309,
	},
	{
		garage_id: 247,
		part_id: 343,
		quantity: 442,
	},
	{
		garage_id: 247,
		part_id: 344,
		quantity: 339,
	},
	{
		garage_id: 247,
		part_id: 345,
		quantity: 109,
	},
	{
		garage_id: 247,
		part_id: 346,
		quantity: 368,
	},
	{
		garage_id: 247,
		part_id: 347,
		quantity: 438,
	},
	{
		garage_id: 247,
		part_id: 348,
		quantity: 64,
	},
	{
		garage_id: 247,
		part_id: 349,
		quantity: 389,
	},
	{
		garage_id: 247,
		part_id: 350,
		quantity: 193,
	},
	{
		garage_id: 247,
		part_id: 351,
		quantity: 168,
	},
	{
		garage_id: 247,
		part_id: 352,
		quantity: 320,
	},
	{
		garage_id: 247,
		part_id: 353,
		quantity: 499,
	},
	{
		garage_id: 247,
		part_id: 354,
		quantity: 463,
	},
	{
		garage_id: 247,
		part_id: 355,
		quantity: 226,
	},
	{
		garage_id: 247,
		part_id: 356,
		quantity: 126,
	},
	{
		garage_id: 247,
		part_id: 357,
		quantity: 241,
	},
	{
		garage_id: 247,
		part_id: 358,
		quantity: 154,
	},
	{
		garage_id: 247,
		part_id: 359,
		quantity: 447,
	},
	{
		garage_id: 247,
		part_id: 360,
		quantity: 220,
	},
	{
		garage_id: 247,
		part_id: 361,
		quantity: 306,
	},
	{
		garage_id: 247,
		part_id: 362,
		quantity: 57,
	},
	{
		garage_id: 247,
		part_id: 363,
		quantity: 375,
	},
	{
		garage_id: 247,
		part_id: 364,
		quantity: 94,
	},
	{
		garage_id: 247,
		part_id: 365,
		quantity: 14,
	},
	{
		garage_id: 247,
		part_id: 366,
		quantity: 389,
	},
	{
		garage_id: 247,
		part_id: 367,
		quantity: 213,
	},
	{
		garage_id: 247,
		part_id: 368,
		quantity: 375,
	},
	{
		garage_id: 247,
		part_id: 369,
		quantity: 195,
	},
	{
		garage_id: 247,
		part_id: 370,
		quantity: 337,
	},
	{
		garage_id: 247,
		part_id: 371,
		quantity: 491,
	},
	{
		garage_id: 247,
		part_id: 372,
		quantity: 337,
	},
	{
		garage_id: 247,
		part_id: 373,
		quantity: 292,
	},
	{
		garage_id: 247,
		part_id: 374,
		quantity: 374,
	},
	{
		garage_id: 247,
		part_id: 375,
		quantity: 422,
	},
	{
		garage_id: 247,
		part_id: 376,
		quantity: 154,
	},
	{
		garage_id: 247,
		part_id: 377,
		quantity: 136,
	},
	{
		garage_id: 247,
		part_id: 378,
		quantity: 24,
	},
	{
		garage_id: 247,
		part_id: 379,
		quantity: 63,
	},
	{
		garage_id: 247,
		part_id: 380,
		quantity: 254,
	},
	{
		garage_id: 247,
		part_id: 381,
		quantity: 464,
	},
	{
		garage_id: 247,
		part_id: 382,
		quantity: 159,
	},
	{
		garage_id: 247,
		part_id: 383,
		quantity: 171,
	},
	{
		garage_id: 247,
		part_id: 384,
		quantity: 293,
	},
	{
		garage_id: 247,
		part_id: 385,
		quantity: 393,
	},
	{
		garage_id: 247,
		part_id: 386,
		quantity: 342,
	},
	{
		garage_id: 247,
		part_id: 387,
		quantity: 260,
	},
	{
		garage_id: 247,
		part_id: 388,
		quantity: 367,
	},
	{
		garage_id: 247,
		part_id: 389,
		quantity: 93,
	},
	{
		garage_id: 247,
		part_id: 390,
		quantity: 144,
	},
	{
		garage_id: 247,
		part_id: 391,
		quantity: 439,
	},
	{
		garage_id: 247,
		part_id: 392,
		quantity: 402,
	},
	{
		garage_id: 247,
		part_id: 393,
		quantity: 362,
	},
	{
		garage_id: 247,
		part_id: 394,
		quantity: 471,
	},
	{
		garage_id: 247,
		part_id: 395,
		quantity: 4,
	},
	{
		garage_id: 247,
		part_id: 396,
		quantity: 62,
	},
	{
		garage_id: 247,
		part_id: 397,
		quantity: 74,
	},
	{
		garage_id: 247,
		part_id: 398,
		quantity: 143,
	},
	{
		garage_id: 247,
		part_id: 399,
		quantity: 495,
	},
	{
		garage_id: 248,
		part_id: 0,
		quantity: 124,
	},
	{
		garage_id: 248,
		part_id: 1,
		quantity: 391,
	},
	{
		garage_id: 248,
		part_id: 2,
		quantity: 266,
	},
	{
		garage_id: 248,
		part_id: 3,
		quantity: 297,
	},
	{
		garage_id: 248,
		part_id: 4,
		quantity: 383,
	},
	{
		garage_id: 248,
		part_id: 5,
		quantity: 213,
	},
	{
		garage_id: 248,
		part_id: 6,
		quantity: 386,
	},
	{
		garage_id: 248,
		part_id: 7,
		quantity: 413,
	},
	{
		garage_id: 248,
		part_id: 8,
		quantity: 5,
	},
	{
		garage_id: 248,
		part_id: 9,
		quantity: 397,
	},
	{
		garage_id: 248,
		part_id: 10,
		quantity: 77,
	},
	{
		garage_id: 248,
		part_id: 11,
		quantity: 59,
	},
	{
		garage_id: 248,
		part_id: 12,
		quantity: 432,
	},
	{
		garage_id: 248,
		part_id: 13,
		quantity: 88,
	},
	{
		garage_id: 248,
		part_id: 14,
		quantity: 315,
	},
	{
		garage_id: 248,
		part_id: 15,
		quantity: 373,
	},
	{
		garage_id: 248,
		part_id: 16,
		quantity: 443,
	},
	{
		garage_id: 248,
		part_id: 17,
		quantity: 270,
	},
	{
		garage_id: 248,
		part_id: 18,
		quantity: 279,
	},
	{
		garage_id: 248,
		part_id: 19,
		quantity: 400,
	},
	{
		garage_id: 248,
		part_id: 20,
		quantity: 14,
	},
	{
		garage_id: 248,
		part_id: 21,
		quantity: 319,
	},
	{
		garage_id: 248,
		part_id: 22,
		quantity: 496,
	},
	{
		garage_id: 248,
		part_id: 23,
		quantity: 117,
	},
	{
		garage_id: 248,
		part_id: 24,
		quantity: 137,
	},
	{
		garage_id: 248,
		part_id: 25,
		quantity: 244,
	},
	{
		garage_id: 248,
		part_id: 26,
		quantity: 495,
	},
	{
		garage_id: 248,
		part_id: 27,
		quantity: 175,
	},
	{
		garage_id: 248,
		part_id: 28,
		quantity: 282,
	},
	{
		garage_id: 248,
		part_id: 29,
		quantity: 493,
	},
	{
		garage_id: 248,
		part_id: 30,
		quantity: 441,
	},
	{
		garage_id: 248,
		part_id: 31,
		quantity: 122,
	},
	{
		garage_id: 248,
		part_id: 32,
		quantity: 493,
	},
	{
		garage_id: 248,
		part_id: 33,
		quantity: 317,
	},
	{
		garage_id: 248,
		part_id: 34,
		quantity: 154,
	},
	{
		garage_id: 248,
		part_id: 35,
		quantity: 416,
	},
	{
		garage_id: 248,
		part_id: 36,
		quantity: 202,
	},
	{
		garage_id: 248,
		part_id: 37,
		quantity: 49,
	},
	{
		garage_id: 248,
		part_id: 38,
		quantity: 184,
	},
	{
		garage_id: 248,
		part_id: 39,
		quantity: 497,
	},
	{
		garage_id: 248,
		part_id: 40,
		quantity: 171,
	},
	{
		garage_id: 248,
		part_id: 41,
		quantity: 404,
	},
	{
		garage_id: 248,
		part_id: 42,
		quantity: 353,
	},
	{
		garage_id: 248,
		part_id: 43,
		quantity: 201,
	},
	{
		garage_id: 248,
		part_id: 44,
		quantity: 158,
	},
	{
		garage_id: 248,
		part_id: 45,
		quantity: 91,
	},
	{
		garage_id: 248,
		part_id: 46,
		quantity: 84,
	},
	{
		garage_id: 248,
		part_id: 47,
		quantity: 90,
	},
	{
		garage_id: 248,
		part_id: 48,
		quantity: 389,
	},
	{
		garage_id: 248,
		part_id: 49,
		quantity: 260,
	},
	{
		garage_id: 248,
		part_id: 50,
		quantity: 416,
	},
	{
		garage_id: 248,
		part_id: 51,
		quantity: 411,
	},
	{
		garage_id: 248,
		part_id: 52,
		quantity: 487,
	},
	{
		garage_id: 248,
		part_id: 53,
		quantity: 177,
	},
	{
		garage_id: 248,
		part_id: 54,
		quantity: 219,
	},
	{
		garage_id: 248,
		part_id: 55,
		quantity: 490,
	},
	{
		garage_id: 248,
		part_id: 56,
		quantity: 114,
	},
	{
		garage_id: 248,
		part_id: 57,
		quantity: 6,
	},
	{
		garage_id: 248,
		part_id: 58,
		quantity: 270,
	},
	{
		garage_id: 248,
		part_id: 59,
		quantity: 392,
	},
	{
		garage_id: 248,
		part_id: 60,
		quantity: 258,
	},
	{
		garage_id: 248,
		part_id: 61,
		quantity: 459,
	},
	{
		garage_id: 248,
		part_id: 62,
		quantity: 56,
	},
	{
		garage_id: 248,
		part_id: 63,
		quantity: 467,
	},
	{
		garage_id: 248,
		part_id: 64,
		quantity: 266,
	},
	{
		garage_id: 248,
		part_id: 65,
		quantity: 204,
	},
	{
		garage_id: 248,
		part_id: 66,
		quantity: 374,
	},
	{
		garage_id: 248,
		part_id: 67,
		quantity: 190,
	},
	{
		garage_id: 248,
		part_id: 68,
		quantity: 34,
	},
	{
		garage_id: 248,
		part_id: 69,
		quantity: 340,
	},
	{
		garage_id: 248,
		part_id: 70,
		quantity: 272,
	},
	{
		garage_id: 248,
		part_id: 71,
		quantity: 327,
	},
	{
		garage_id: 248,
		part_id: 72,
		quantity: 282,
	},
	{
		garage_id: 248,
		part_id: 73,
		quantity: 367,
	},
	{
		garage_id: 248,
		part_id: 74,
		quantity: 465,
	},
	{
		garage_id: 248,
		part_id: 75,
		quantity: 326,
	},
	{
		garage_id: 248,
		part_id: 76,
		quantity: 271,
	},
	{
		garage_id: 248,
		part_id: 77,
		quantity: 245,
	},
	{
		garage_id: 248,
		part_id: 78,
		quantity: 414,
	},
	{
		garage_id: 248,
		part_id: 79,
		quantity: 246,
	},
	{
		garage_id: 248,
		part_id: 80,
		quantity: 180,
	},
	{
		garage_id: 248,
		part_id: 81,
		quantity: 370,
	},
	{
		garage_id: 248,
		part_id: 82,
		quantity: 468,
	},
	{
		garage_id: 248,
		part_id: 83,
		quantity: 19,
	},
	{
		garage_id: 248,
		part_id: 84,
		quantity: 288,
	},
	{
		garage_id: 248,
		part_id: 85,
		quantity: 283,
	},
	{
		garage_id: 248,
		part_id: 86,
		quantity: 66,
	},
	{
		garage_id: 248,
		part_id: 87,
		quantity: 288,
	},
	{
		garage_id: 248,
		part_id: 88,
		quantity: 84,
	},
	{
		garage_id: 248,
		part_id: 89,
		quantity: 64,
	},
	{
		garage_id: 248,
		part_id: 90,
		quantity: 209,
	},
	{
		garage_id: 248,
		part_id: 91,
		quantity: 145,
	},
	{
		garage_id: 248,
		part_id: 92,
		quantity: 395,
	},
	{
		garage_id: 248,
		part_id: 93,
		quantity: 497,
	},
	{
		garage_id: 248,
		part_id: 94,
		quantity: 137,
	},
	{
		garage_id: 248,
		part_id: 95,
		quantity: 382,
	},
	{
		garage_id: 248,
		part_id: 96,
		quantity: 371,
	},
	{
		garage_id: 248,
		part_id: 97,
		quantity: 135,
	},
	{
		garage_id: 248,
		part_id: 98,
		quantity: 361,
	},
	{
		garage_id: 248,
		part_id: 99,
		quantity: 64,
	},
	{
		garage_id: 248,
		part_id: 100,
		quantity: 227,
	},
	{
		garage_id: 248,
		part_id: 101,
		quantity: 369,
	},
	{
		garage_id: 248,
		part_id: 102,
		quantity: 491,
	},
	{
		garage_id: 248,
		part_id: 103,
		quantity: 482,
	},
	{
		garage_id: 248,
		part_id: 104,
		quantity: 229,
	},
	{
		garage_id: 248,
		part_id: 105,
		quantity: 13,
	},
	{
		garage_id: 248,
		part_id: 106,
		quantity: 452,
	},
	{
		garage_id: 248,
		part_id: 107,
		quantity: 91,
	},
	{
		garage_id: 248,
		part_id: 108,
		quantity: 276,
	},
	{
		garage_id: 248,
		part_id: 109,
		quantity: 450,
	},
	{
		garage_id: 248,
		part_id: 110,
		quantity: 74,
	},
	{
		garage_id: 248,
		part_id: 111,
		quantity: 435,
	},
	{
		garage_id: 248,
		part_id: 112,
		quantity: 70,
	},
	{
		garage_id: 248,
		part_id: 113,
		quantity: 269,
	},
	{
		garage_id: 248,
		part_id: 114,
		quantity: 321,
	},
	{
		garage_id: 248,
		part_id: 115,
		quantity: 26,
	},
	{
		garage_id: 248,
		part_id: 116,
		quantity: 481,
	},
	{
		garage_id: 248,
		part_id: 117,
		quantity: 149,
	},
	{
		garage_id: 248,
		part_id: 118,
		quantity: 181,
	},
	{
		garage_id: 248,
		part_id: 119,
		quantity: 428,
	},
	{
		garage_id: 248,
		part_id: 120,
		quantity: 308,
	},
	{
		garage_id: 248,
		part_id: 121,
		quantity: 403,
	},
	{
		garage_id: 248,
		part_id: 122,
		quantity: 221,
	},
	{
		garage_id: 248,
		part_id: 123,
		quantity: 458,
	},
	{
		garage_id: 248,
		part_id: 124,
		quantity: 322,
	},
	{
		garage_id: 248,
		part_id: 125,
		quantity: 63,
	},
	{
		garage_id: 248,
		part_id: 126,
		quantity: 440,
	},
	{
		garage_id: 248,
		part_id: 127,
		quantity: 420,
	},
	{
		garage_id: 248,
		part_id: 128,
		quantity: 21,
	},
	{
		garage_id: 248,
		part_id: 129,
		quantity: 438,
	},
	{
		garage_id: 248,
		part_id: 130,
		quantity: 18,
	},
	{
		garage_id: 248,
		part_id: 131,
		quantity: 301,
	},
	{
		garage_id: 248,
		part_id: 132,
		quantity: 94,
	},
	{
		garage_id: 248,
		part_id: 133,
		quantity: 326,
	},
	{
		garage_id: 248,
		part_id: 134,
		quantity: 115,
	},
	{
		garage_id: 248,
		part_id: 135,
		quantity: 208,
	},
	{
		garage_id: 248,
		part_id: 136,
		quantity: 466,
	},
	{
		garage_id: 248,
		part_id: 137,
		quantity: 255,
	},
	{
		garage_id: 248,
		part_id: 138,
		quantity: 412,
	},
	{
		garage_id: 248,
		part_id: 139,
		quantity: 70,
	},
	{
		garage_id: 248,
		part_id: 140,
		quantity: 479,
	},
	{
		garage_id: 248,
		part_id: 141,
		quantity: 247,
	},
	{
		garage_id: 248,
		part_id: 142,
		quantity: 79,
	},
	{
		garage_id: 248,
		part_id: 143,
		quantity: 257,
	},
	{
		garage_id: 248,
		part_id: 144,
		quantity: 327,
	},
	{
		garage_id: 248,
		part_id: 145,
		quantity: 415,
	},
	{
		garage_id: 248,
		part_id: 146,
		quantity: 337,
	},
	{
		garage_id: 248,
		part_id: 147,
		quantity: 54,
	},
	{
		garage_id: 248,
		part_id: 148,
		quantity: 433,
	},
	{
		garage_id: 248,
		part_id: 149,
		quantity: 410,
	},
	{
		garage_id: 248,
		part_id: 150,
		quantity: 439,
	},
	{
		garage_id: 248,
		part_id: 151,
		quantity: 492,
	},
	{
		garage_id: 248,
		part_id: 152,
		quantity: 335,
	},
	{
		garage_id: 248,
		part_id: 153,
		quantity: 74,
	},
	{
		garage_id: 248,
		part_id: 154,
		quantity: 40,
	},
	{
		garage_id: 248,
		part_id: 155,
		quantity: 18,
	},
	{
		garage_id: 248,
		part_id: 156,
		quantity: 266,
	},
	{
		garage_id: 248,
		part_id: 157,
		quantity: 215,
	},
	{
		garage_id: 248,
		part_id: 158,
		quantity: 129,
	},
	{
		garage_id: 248,
		part_id: 159,
		quantity: 145,
	},
	{
		garage_id: 248,
		part_id: 160,
		quantity: 404,
	},
	{
		garage_id: 248,
		part_id: 161,
		quantity: 389,
	},
	{
		garage_id: 248,
		part_id: 162,
		quantity: 138,
	},
	{
		garage_id: 248,
		part_id: 163,
		quantity: 460,
	},
	{
		garage_id: 248,
		part_id: 164,
		quantity: 88,
	},
	{
		garage_id: 248,
		part_id: 165,
		quantity: 485,
	},
	{
		garage_id: 248,
		part_id: 166,
		quantity: 320,
	},
	{
		garage_id: 248,
		part_id: 167,
		quantity: 176,
	},
	{
		garage_id: 248,
		part_id: 168,
		quantity: 141,
	},
	{
		garage_id: 248,
		part_id: 169,
		quantity: 368,
	},
	{
		garage_id: 248,
		part_id: 170,
		quantity: 398,
	},
	{
		garage_id: 248,
		part_id: 171,
		quantity: 179,
	},
	{
		garage_id: 248,
		part_id: 172,
		quantity: 447,
	},
	{
		garage_id: 248,
		part_id: 173,
		quantity: 170,
	},
	{
		garage_id: 248,
		part_id: 174,
		quantity: 429,
	},
	{
		garage_id: 248,
		part_id: 175,
		quantity: 145,
	},
	{
		garage_id: 248,
		part_id: 176,
		quantity: 124,
	},
	{
		garage_id: 248,
		part_id: 177,
		quantity: 198,
	},
	{
		garage_id: 248,
		part_id: 178,
		quantity: 320,
	},
	{
		garage_id: 248,
		part_id: 179,
		quantity: 492,
	},
	{
		garage_id: 248,
		part_id: 180,
		quantity: 381,
	},
	{
		garage_id: 248,
		part_id: 181,
		quantity: 330,
	},
	{
		garage_id: 248,
		part_id: 182,
		quantity: 450,
	},
	{
		garage_id: 248,
		part_id: 183,
		quantity: 105,
	},
	{
		garage_id: 248,
		part_id: 184,
		quantity: 344,
	},
	{
		garage_id: 248,
		part_id: 185,
		quantity: 46,
	},
	{
		garage_id: 248,
		part_id: 186,
		quantity: 384,
	},
	{
		garage_id: 248,
		part_id: 187,
		quantity: 345,
	},
	{
		garage_id: 248,
		part_id: 188,
		quantity: 109,
	},
	{
		garage_id: 248,
		part_id: 189,
		quantity: 399,
	},
	{
		garage_id: 248,
		part_id: 190,
		quantity: 292,
	},
	{
		garage_id: 248,
		part_id: 191,
		quantity: 181,
	},
	{
		garage_id: 248,
		part_id: 192,
		quantity: 295,
	},
	{
		garage_id: 248,
		part_id: 193,
		quantity: 26,
	},
	{
		garage_id: 248,
		part_id: 194,
		quantity: 17,
	},
	{
		garage_id: 248,
		part_id: 195,
		quantity: 468,
	},
	{
		garage_id: 248,
		part_id: 196,
		quantity: 56,
	},
	{
		garage_id: 248,
		part_id: 197,
		quantity: 64,
	},
	{
		garage_id: 248,
		part_id: 198,
		quantity: 484,
	},
	{
		garage_id: 248,
		part_id: 199,
		quantity: 335,
	},
	{
		garage_id: 248,
		part_id: 200,
		quantity: 489,
	},
	{
		garage_id: 248,
		part_id: 201,
		quantity: 106,
	},
	{
		garage_id: 248,
		part_id: 202,
		quantity: 371,
	},
	{
		garage_id: 248,
		part_id: 203,
		quantity: 425,
	},
	{
		garage_id: 248,
		part_id: 204,
		quantity: 177,
	},
	{
		garage_id: 248,
		part_id: 205,
		quantity: 10,
	},
	{
		garage_id: 248,
		part_id: 206,
		quantity: 43,
	},
	{
		garage_id: 248,
		part_id: 207,
		quantity: 289,
	},
	{
		garage_id: 248,
		part_id: 208,
		quantity: 319,
	},
	{
		garage_id: 248,
		part_id: 209,
		quantity: 217,
	},
	{
		garage_id: 248,
		part_id: 210,
		quantity: 74,
	},
	{
		garage_id: 248,
		part_id: 211,
		quantity: 468,
	},
	{
		garage_id: 248,
		part_id: 212,
		quantity: 210,
	},
	{
		garage_id: 248,
		part_id: 213,
		quantity: 175,
	},
	{
		garage_id: 248,
		part_id: 214,
		quantity: 432,
	},
	{
		garage_id: 248,
		part_id: 215,
		quantity: 35,
	},
	{
		garage_id: 248,
		part_id: 216,
		quantity: 353,
	},
	{
		garage_id: 248,
		part_id: 217,
		quantity: 85,
	},
	{
		garage_id: 248,
		part_id: 218,
		quantity: 394,
	},
	{
		garage_id: 248,
		part_id: 219,
		quantity: 471,
	},
	{
		garage_id: 248,
		part_id: 220,
		quantity: 368,
	},
	{
		garage_id: 248,
		part_id: 221,
		quantity: 277,
	},
	{
		garage_id: 248,
		part_id: 222,
		quantity: 252,
	},
	{
		garage_id: 248,
		part_id: 223,
		quantity: 323,
	},
	{
		garage_id: 248,
		part_id: 224,
		quantity: 300,
	},
	{
		garage_id: 248,
		part_id: 225,
		quantity: 105,
	},
	{
		garage_id: 248,
		part_id: 226,
		quantity: 253,
	},
	{
		garage_id: 248,
		part_id: 227,
		quantity: 203,
	},
	{
		garage_id: 248,
		part_id: 228,
		quantity: 129,
	},
	{
		garage_id: 248,
		part_id: 229,
		quantity: 117,
	},
	{
		garage_id: 248,
		part_id: 230,
		quantity: 359,
	},
	{
		garage_id: 248,
		part_id: 231,
		quantity: 135,
	},
	{
		garage_id: 248,
		part_id: 232,
		quantity: 307,
	},
	{
		garage_id: 248,
		part_id: 233,
		quantity: 263,
	},
	{
		garage_id: 248,
		part_id: 234,
		quantity: 362,
	},
	{
		garage_id: 248,
		part_id: 235,
		quantity: 50,
	},
	{
		garage_id: 248,
		part_id: 236,
		quantity: 434,
	},
	{
		garage_id: 248,
		part_id: 237,
		quantity: 309,
	},
	{
		garage_id: 248,
		part_id: 238,
		quantity: 458,
	},
	{
		garage_id: 248,
		part_id: 239,
		quantity: 160,
	},
	{
		garage_id: 248,
		part_id: 240,
		quantity: 288,
	},
	{
		garage_id: 248,
		part_id: 241,
		quantity: 195,
	},
	{
		garage_id: 248,
		part_id: 242,
		quantity: 491,
	},
	{
		garage_id: 248,
		part_id: 243,
		quantity: 389,
	},
	{
		garage_id: 248,
		part_id: 244,
		quantity: 194,
	},
	{
		garage_id: 248,
		part_id: 245,
		quantity: 182,
	},
	{
		garage_id: 248,
		part_id: 246,
		quantity: 327,
	},
	{
		garage_id: 248,
		part_id: 247,
		quantity: 292,
	},
	{
		garage_id: 248,
		part_id: 248,
		quantity: 52,
	},
	{
		garage_id: 248,
		part_id: 249,
		quantity: 22,
	},
	{
		garage_id: 248,
		part_id: 250,
		quantity: 450,
	},
	{
		garage_id: 248,
		part_id: 251,
		quantity: 19,
	},
	{
		garage_id: 248,
		part_id: 252,
		quantity: 297,
	},
	{
		garage_id: 248,
		part_id: 253,
		quantity: 98,
	},
	{
		garage_id: 248,
		part_id: 254,
		quantity: 59,
	},
	{
		garage_id: 248,
		part_id: 255,
		quantity: 384,
	},
	{
		garage_id: 248,
		part_id: 256,
		quantity: 348,
	},
	{
		garage_id: 248,
		part_id: 257,
		quantity: 450,
	},
	{
		garage_id: 248,
		part_id: 258,
		quantity: 335,
	},
	{
		garage_id: 248,
		part_id: 259,
		quantity: 111,
	},
	{
		garage_id: 248,
		part_id: 260,
		quantity: 291,
	},
	{
		garage_id: 248,
		part_id: 261,
		quantity: 9,
	},
	{
		garage_id: 248,
		part_id: 262,
		quantity: 411,
	},
	{
		garage_id: 248,
		part_id: 263,
		quantity: 298,
	},
	{
		garage_id: 248,
		part_id: 264,
		quantity: 162,
	},
	{
		garage_id: 248,
		part_id: 265,
		quantity: 57,
	},
	{
		garage_id: 248,
		part_id: 266,
		quantity: 93,
	},
	{
		garage_id: 248,
		part_id: 267,
		quantity: 168,
	},
	{
		garage_id: 248,
		part_id: 268,
		quantity: 75,
	},
	{
		garage_id: 248,
		part_id: 269,
		quantity: 262,
	},
	{
		garage_id: 248,
		part_id: 270,
		quantity: 364,
	},
	{
		garage_id: 248,
		part_id: 271,
		quantity: 195,
	},
	{
		garage_id: 248,
		part_id: 272,
		quantity: 452,
	},
	{
		garage_id: 248,
		part_id: 273,
		quantity: 72,
	},
	{
		garage_id: 248,
		part_id: 274,
		quantity: 2,
	},
	{
		garage_id: 248,
		part_id: 275,
		quantity: 67,
	},
	{
		garage_id: 248,
		part_id: 276,
		quantity: 44,
	},
	{
		garage_id: 248,
		part_id: 277,
		quantity: 74,
	},
	{
		garage_id: 248,
		part_id: 278,
		quantity: 86,
	},
	{
		garage_id: 248,
		part_id: 279,
		quantity: 317,
	},
	{
		garage_id: 248,
		part_id: 280,
		quantity: 25,
	},
	{
		garage_id: 248,
		part_id: 281,
		quantity: 253,
	},
	{
		garage_id: 248,
		part_id: 282,
		quantity: 10,
	},
	{
		garage_id: 248,
		part_id: 283,
		quantity: 383,
	},
	{
		garage_id: 248,
		part_id: 284,
		quantity: 269,
	},
	{
		garage_id: 248,
		part_id: 285,
		quantity: 164,
	},
	{
		garage_id: 248,
		part_id: 286,
		quantity: 192,
	},
	{
		garage_id: 248,
		part_id: 287,
		quantity: 57,
	},
	{
		garage_id: 248,
		part_id: 288,
		quantity: 101,
	},
	{
		garage_id: 248,
		part_id: 289,
		quantity: 342,
	},
	{
		garage_id: 248,
		part_id: 290,
		quantity: 177,
	},
	{
		garage_id: 248,
		part_id: 291,
		quantity: 433,
	},
	{
		garage_id: 248,
		part_id: 292,
		quantity: 112,
	},
	{
		garage_id: 248,
		part_id: 293,
		quantity: 384,
	},
	{
		garage_id: 248,
		part_id: 294,
		quantity: 478,
	},
	{
		garage_id: 248,
		part_id: 295,
		quantity: 489,
	},
	{
		garage_id: 248,
		part_id: 296,
		quantity: 499,
	},
	{
		garage_id: 248,
		part_id: 297,
		quantity: 55,
	},
	{
		garage_id: 248,
		part_id: 298,
		quantity: 226,
	},
	{
		garage_id: 248,
		part_id: 299,
		quantity: 85,
	},
	{
		garage_id: 248,
		part_id: 300,
		quantity: 118,
	},
	{
		garage_id: 248,
		part_id: 301,
		quantity: 298,
	},
	{
		garage_id: 248,
		part_id: 302,
		quantity: 164,
	},
	{
		garage_id: 248,
		part_id: 303,
		quantity: 434,
	},
	{
		garage_id: 248,
		part_id: 304,
		quantity: 251,
	},
	{
		garage_id: 248,
		part_id: 305,
		quantity: 2,
	},
	{
		garage_id: 248,
		part_id: 306,
		quantity: 435,
	},
	{
		garage_id: 248,
		part_id: 307,
		quantity: 40,
	},
	{
		garage_id: 248,
		part_id: 308,
		quantity: 220,
	},
	{
		garage_id: 248,
		part_id: 309,
		quantity: 148,
	},
	{
		garage_id: 248,
		part_id: 310,
		quantity: 450,
	},
	{
		garage_id: 248,
		part_id: 311,
		quantity: 137,
	},
	{
		garage_id: 248,
		part_id: 312,
		quantity: 24,
	},
	{
		garage_id: 248,
		part_id: 313,
		quantity: 227,
	},
	{
		garage_id: 248,
		part_id: 314,
		quantity: 91,
	},
	{
		garage_id: 248,
		part_id: 315,
		quantity: 365,
	},
	{
		garage_id: 248,
		part_id: 316,
		quantity: 169,
	},
	{
		garage_id: 248,
		part_id: 317,
		quantity: 74,
	},
	{
		garage_id: 248,
		part_id: 318,
		quantity: 266,
	},
	{
		garage_id: 248,
		part_id: 319,
		quantity: 331,
	},
	{
		garage_id: 248,
		part_id: 320,
		quantity: 259,
	},
	{
		garage_id: 248,
		part_id: 321,
		quantity: 496,
	},
	{
		garage_id: 248,
		part_id: 322,
		quantity: 67,
	},
	{
		garage_id: 248,
		part_id: 323,
		quantity: 278,
	},
	{
		garage_id: 248,
		part_id: 324,
		quantity: 337,
	},
	{
		garage_id: 248,
		part_id: 325,
		quantity: 362,
	},
	{
		garage_id: 248,
		part_id: 326,
		quantity: 216,
	},
	{
		garage_id: 248,
		part_id: 327,
		quantity: 7,
	},
	{
		garage_id: 248,
		part_id: 328,
		quantity: 70,
	},
	{
		garage_id: 248,
		part_id: 329,
		quantity: 165,
	},
	{
		garage_id: 248,
		part_id: 330,
		quantity: 489,
	},
	{
		garage_id: 248,
		part_id: 331,
		quantity: 382,
	},
	{
		garage_id: 248,
		part_id: 332,
		quantity: 98,
	},
	{
		garage_id: 248,
		part_id: 333,
		quantity: 466,
	},
	{
		garage_id: 248,
		part_id: 334,
		quantity: 431,
	},
	{
		garage_id: 248,
		part_id: 335,
		quantity: 440,
	},
	{
		garage_id: 248,
		part_id: 336,
		quantity: 471,
	},
	{
		garage_id: 248,
		part_id: 337,
		quantity: 414,
	},
	{
		garage_id: 248,
		part_id: 338,
		quantity: 388,
	},
	{
		garage_id: 248,
		part_id: 339,
		quantity: 493,
	},
	{
		garage_id: 248,
		part_id: 340,
		quantity: 174,
	},
	{
		garage_id: 248,
		part_id: 341,
		quantity: 245,
	},
	{
		garage_id: 248,
		part_id: 342,
		quantity: 250,
	},
	{
		garage_id: 248,
		part_id: 343,
		quantity: 270,
	},
	{
		garage_id: 248,
		part_id: 344,
		quantity: 32,
	},
	{
		garage_id: 248,
		part_id: 345,
		quantity: 484,
	},
	{
		garage_id: 248,
		part_id: 346,
		quantity: 474,
	},
	{
		garage_id: 248,
		part_id: 347,
		quantity: 129,
	},
	{
		garage_id: 248,
		part_id: 348,
		quantity: 445,
	},
	{
		garage_id: 248,
		part_id: 349,
		quantity: 202,
	},
	{
		garage_id: 248,
		part_id: 350,
		quantity: 7,
	},
	{
		garage_id: 248,
		part_id: 351,
		quantity: 97,
	},
	{
		garage_id: 248,
		part_id: 352,
		quantity: 480,
	},
	{
		garage_id: 248,
		part_id: 353,
		quantity: 9,
	},
	{
		garage_id: 248,
		part_id: 354,
		quantity: 283,
	},
	{
		garage_id: 248,
		part_id: 355,
		quantity: 107,
	},
	{
		garage_id: 248,
		part_id: 356,
		quantity: 364,
	},
	{
		garage_id: 248,
		part_id: 357,
		quantity: 461,
	},
	{
		garage_id: 248,
		part_id: 358,
		quantity: 306,
	},
	{
		garage_id: 248,
		part_id: 359,
		quantity: 487,
	},
	{
		garage_id: 248,
		part_id: 360,
		quantity: 141,
	},
	{
		garage_id: 248,
		part_id: 361,
		quantity: 240,
	},
	{
		garage_id: 248,
		part_id: 362,
		quantity: 374,
	},
	{
		garage_id: 248,
		part_id: 363,
		quantity: 260,
	},
	{
		garage_id: 248,
		part_id: 364,
		quantity: 299,
	},
	{
		garage_id: 248,
		part_id: 365,
		quantity: 424,
	},
	{
		garage_id: 248,
		part_id: 366,
		quantity: 88,
	},
	{
		garage_id: 248,
		part_id: 367,
		quantity: 238,
	},
	{
		garage_id: 248,
		part_id: 368,
		quantity: 110,
	},
	{
		garage_id: 248,
		part_id: 369,
		quantity: 249,
	},
	{
		garage_id: 248,
		part_id: 370,
		quantity: 252,
	},
	{
		garage_id: 248,
		part_id: 371,
		quantity: 386,
	},
	{
		garage_id: 248,
		part_id: 372,
		quantity: 139,
	},
	{
		garage_id: 248,
		part_id: 373,
		quantity: 47,
	},
	{
		garage_id: 248,
		part_id: 374,
		quantity: 26,
	},
	{
		garage_id: 248,
		part_id: 375,
		quantity: 241,
	},
	{
		garage_id: 248,
		part_id: 376,
		quantity: 220,
	},
	{
		garage_id: 248,
		part_id: 377,
		quantity: 349,
	},
	{
		garage_id: 248,
		part_id: 378,
		quantity: 487,
	},
	{
		garage_id: 248,
		part_id: 379,
		quantity: 117,
	},
	{
		garage_id: 248,
		part_id: 380,
		quantity: 55,
	},
	{
		garage_id: 248,
		part_id: 381,
		quantity: 31,
	},
	{
		garage_id: 248,
		part_id: 382,
		quantity: 11,
	},
	{
		garage_id: 248,
		part_id: 383,
		quantity: 279,
	},
	{
		garage_id: 248,
		part_id: 384,
		quantity: 177,
	},
	{
		garage_id: 248,
		part_id: 385,
		quantity: 400,
	},
	{
		garage_id: 248,
		part_id: 386,
		quantity: 352,
	},
	{
		garage_id: 248,
		part_id: 387,
		quantity: 456,
	},
	{
		garage_id: 248,
		part_id: 388,
		quantity: 382,
	},
	{
		garage_id: 248,
		part_id: 389,
		quantity: 134,
	},
	{
		garage_id: 248,
		part_id: 390,
		quantity: 57,
	},
	{
		garage_id: 248,
		part_id: 391,
		quantity: 230,
	},
	{
		garage_id: 248,
		part_id: 392,
		quantity: 212,
	},
	{
		garage_id: 248,
		part_id: 393,
		quantity: 392,
	},
	{
		garage_id: 248,
		part_id: 394,
		quantity: 272,
	},
	{
		garage_id: 248,
		part_id: 395,
		quantity: 208,
	},
	{
		garage_id: 248,
		part_id: 396,
		quantity: 448,
	},
	{
		garage_id: 248,
		part_id: 397,
		quantity: 367,
	},
	{
		garage_id: 248,
		part_id: 398,
		quantity: 293,
	},
	{
		garage_id: 248,
		part_id: 399,
		quantity: 63,
	},
	{
		garage_id: 249,
		part_id: 0,
		quantity: 306,
	},
	{
		garage_id: 249,
		part_id: 1,
		quantity: 343,
	},
	{
		garage_id: 249,
		part_id: 2,
		quantity: 280,
	},
	{
		garage_id: 249,
		part_id: 3,
		quantity: 157,
	},
	{
		garage_id: 249,
		part_id: 4,
		quantity: 379,
	},
	{
		garage_id: 249,
		part_id: 5,
		quantity: 345,
	},
	{
		garage_id: 249,
		part_id: 6,
		quantity: 185,
	},
	{
		garage_id: 249,
		part_id: 7,
		quantity: 456,
	},
	{
		garage_id: 249,
		part_id: 8,
		quantity: 306,
	},
	{
		garage_id: 249,
		part_id: 9,
		quantity: 451,
	},
	{
		garage_id: 249,
		part_id: 10,
		quantity: 131,
	},
	{
		garage_id: 249,
		part_id: 11,
		quantity: 387,
	},
	{
		garage_id: 249,
		part_id: 12,
		quantity: 419,
	},
	{
		garage_id: 249,
		part_id: 13,
		quantity: 173,
	},
	{
		garage_id: 249,
		part_id: 14,
		quantity: 363,
	},
	{
		garage_id: 249,
		part_id: 15,
		quantity: 299,
	},
	{
		garage_id: 249,
		part_id: 16,
		quantity: 249,
	},
	{
		garage_id: 249,
		part_id: 17,
		quantity: 168,
	},
	{
		garage_id: 249,
		part_id: 18,
		quantity: 88,
	},
	{
		garage_id: 249,
		part_id: 19,
		quantity: 431,
	},
	{
		garage_id: 249,
		part_id: 20,
		quantity: 434,
	},
	{
		garage_id: 249,
		part_id: 21,
		quantity: 304,
	},
	{
		garage_id: 249,
		part_id: 22,
		quantity: 465,
	},
	{
		garage_id: 249,
		part_id: 23,
		quantity: 253,
	},
	{
		garage_id: 249,
		part_id: 24,
		quantity: 240,
	},
	{
		garage_id: 249,
		part_id: 25,
		quantity: 33,
	},
	{
		garage_id: 249,
		part_id: 26,
		quantity: 166,
	},
	{
		garage_id: 249,
		part_id: 27,
		quantity: 496,
	},
	{
		garage_id: 249,
		part_id: 28,
		quantity: 466,
	},
	{
		garage_id: 249,
		part_id: 29,
		quantity: 398,
	},
	{
		garage_id: 249,
		part_id: 30,
		quantity: 28,
	},
	{
		garage_id: 249,
		part_id: 31,
		quantity: 59,
	},
	{
		garage_id: 249,
		part_id: 32,
		quantity: 248,
	},
	{
		garage_id: 249,
		part_id: 33,
		quantity: 419,
	},
	{
		garage_id: 249,
		part_id: 34,
		quantity: 92,
	},
	{
		garage_id: 249,
		part_id: 35,
		quantity: 246,
	},
	{
		garage_id: 249,
		part_id: 36,
		quantity: 47,
	},
	{
		garage_id: 249,
		part_id: 37,
		quantity: 372,
	},
	{
		garage_id: 249,
		part_id: 38,
		quantity: 228,
	},
	{
		garage_id: 249,
		part_id: 39,
		quantity: 126,
	},
	{
		garage_id: 249,
		part_id: 40,
		quantity: 3,
	},
	{
		garage_id: 249,
		part_id: 41,
		quantity: 18,
	},
	{
		garage_id: 249,
		part_id: 42,
		quantity: 214,
	},
	{
		garage_id: 249,
		part_id: 43,
		quantity: 118,
	},
	{
		garage_id: 249,
		part_id: 44,
		quantity: 483,
	},
	{
		garage_id: 249,
		part_id: 45,
		quantity: 270,
	},
	{
		garage_id: 249,
		part_id: 46,
		quantity: 311,
	},
	{
		garage_id: 249,
		part_id: 47,
		quantity: 115,
	},
	{
		garage_id: 249,
		part_id: 48,
		quantity: 301,
	},
	{
		garage_id: 249,
		part_id: 49,
		quantity: 186,
	},
	{
		garage_id: 249,
		part_id: 50,
		quantity: 484,
	},
	{
		garage_id: 249,
		part_id: 51,
		quantity: 369,
	},
	{
		garage_id: 249,
		part_id: 52,
		quantity: 61,
	},
	{
		garage_id: 249,
		part_id: 53,
		quantity: 444,
	},
	{
		garage_id: 249,
		part_id: 54,
		quantity: 144,
	},
	{
		garage_id: 249,
		part_id: 55,
		quantity: 294,
	},
	{
		garage_id: 249,
		part_id: 56,
		quantity: 455,
	},
	{
		garage_id: 249,
		part_id: 57,
		quantity: 118,
	},
	{
		garage_id: 249,
		part_id: 58,
		quantity: 294,
	},
	{
		garage_id: 249,
		part_id: 59,
		quantity: 353,
	},
	{
		garage_id: 249,
		part_id: 60,
		quantity: 312,
	},
	{
		garage_id: 249,
		part_id: 61,
		quantity: 274,
	},
	{
		garage_id: 249,
		part_id: 62,
		quantity: 420,
	},
	{
		garage_id: 249,
		part_id: 63,
		quantity: 353,
	},
	{
		garage_id: 249,
		part_id: 64,
		quantity: 228,
	},
	{
		garage_id: 249,
		part_id: 65,
		quantity: 170,
	},
	{
		garage_id: 249,
		part_id: 66,
		quantity: 433,
	},
	{
		garage_id: 249,
		part_id: 67,
		quantity: 121,
	},
	{
		garage_id: 249,
		part_id: 68,
		quantity: 237,
	},
	{
		garage_id: 249,
		part_id: 69,
		quantity: 69,
	},
	{
		garage_id: 249,
		part_id: 70,
		quantity: 439,
	},
	{
		garage_id: 249,
		part_id: 71,
		quantity: 126,
	},
	{
		garage_id: 249,
		part_id: 72,
		quantity: 58,
	},
	{
		garage_id: 249,
		part_id: 73,
		quantity: 499,
	},
	{
		garage_id: 249,
		part_id: 74,
		quantity: 411,
	},
	{
		garage_id: 249,
		part_id: 75,
		quantity: 427,
	},
	{
		garage_id: 249,
		part_id: 76,
		quantity: 208,
	},
	{
		garage_id: 249,
		part_id: 77,
		quantity: 103,
	},
	{
		garage_id: 249,
		part_id: 78,
		quantity: 214,
	},
	{
		garage_id: 249,
		part_id: 79,
		quantity: 254,
	},
	{
		garage_id: 249,
		part_id: 80,
		quantity: 0,
	},
	{
		garage_id: 249,
		part_id: 81,
		quantity: 158,
	},
	{
		garage_id: 249,
		part_id: 82,
		quantity: 194,
	},
	{
		garage_id: 249,
		part_id: 83,
		quantity: 6,
	},
	{
		garage_id: 249,
		part_id: 84,
		quantity: 358,
	},
	{
		garage_id: 249,
		part_id: 85,
		quantity: 18,
	},
	{
		garage_id: 249,
		part_id: 86,
		quantity: 499,
	},
	{
		garage_id: 249,
		part_id: 87,
		quantity: 326,
	},
	{
		garage_id: 249,
		part_id: 88,
		quantity: 460,
	},
	{
		garage_id: 249,
		part_id: 89,
		quantity: 132,
	},
	{
		garage_id: 249,
		part_id: 90,
		quantity: 187,
	},
	{
		garage_id: 249,
		part_id: 91,
		quantity: 148,
	},
	{
		garage_id: 249,
		part_id: 92,
		quantity: 83,
	},
	{
		garage_id: 249,
		part_id: 93,
		quantity: 224,
	},
	{
		garage_id: 249,
		part_id: 94,
		quantity: 244,
	},
	{
		garage_id: 249,
		part_id: 95,
		quantity: 321,
	},
	{
		garage_id: 249,
		part_id: 96,
		quantity: 252,
	},
	{
		garage_id: 249,
		part_id: 97,
		quantity: 429,
	},
	{
		garage_id: 249,
		part_id: 98,
		quantity: 41,
	},
	{
		garage_id: 249,
		part_id: 99,
		quantity: 296,
	},
	{
		garage_id: 249,
		part_id: 100,
		quantity: 21,
	},
	{
		garage_id: 249,
		part_id: 101,
		quantity: 267,
	},
	{
		garage_id: 249,
		part_id: 102,
		quantity: 36,
	},
	{
		garage_id: 249,
		part_id: 103,
		quantity: 30,
	},
	{
		garage_id: 249,
		part_id: 104,
		quantity: 336,
	},
	{
		garage_id: 249,
		part_id: 105,
		quantity: 225,
	},
	{
		garage_id: 249,
		part_id: 106,
		quantity: 77,
	},
	{
		garage_id: 249,
		part_id: 107,
		quantity: 76,
	},
	{
		garage_id: 249,
		part_id: 108,
		quantity: 303,
	},
	{
		garage_id: 249,
		part_id: 109,
		quantity: 453,
	},
	{
		garage_id: 249,
		part_id: 110,
		quantity: 159,
	},
	{
		garage_id: 249,
		part_id: 111,
		quantity: 156,
	},
	{
		garage_id: 249,
		part_id: 112,
		quantity: 439,
	},
	{
		garage_id: 249,
		part_id: 113,
		quantity: 137,
	},
	{
		garage_id: 249,
		part_id: 114,
		quantity: 334,
	},
	{
		garage_id: 249,
		part_id: 115,
		quantity: 115,
	},
	{
		garage_id: 249,
		part_id: 116,
		quantity: 284,
	},
	{
		garage_id: 249,
		part_id: 117,
		quantity: 251,
	},
	{
		garage_id: 249,
		part_id: 118,
		quantity: 442,
	},
	{
		garage_id: 249,
		part_id: 119,
		quantity: 494,
	},
	{
		garage_id: 249,
		part_id: 120,
		quantity: 380,
	},
	{
		garage_id: 249,
		part_id: 121,
		quantity: 255,
	},
	{
		garage_id: 249,
		part_id: 122,
		quantity: 101,
	},
	{
		garage_id: 249,
		part_id: 123,
		quantity: 21,
	},
	{
		garage_id: 249,
		part_id: 124,
		quantity: 174,
	},
	{
		garage_id: 249,
		part_id: 125,
		quantity: 167,
	},
	{
		garage_id: 249,
		part_id: 126,
		quantity: 273,
	},
	{
		garage_id: 249,
		part_id: 127,
		quantity: 84,
	},
	{
		garage_id: 249,
		part_id: 128,
		quantity: 499,
	},
	{
		garage_id: 249,
		part_id: 129,
		quantity: 445,
	},
	{
		garage_id: 249,
		part_id: 130,
		quantity: 355,
	},
	{
		garage_id: 249,
		part_id: 131,
		quantity: 441,
	},
	{
		garage_id: 249,
		part_id: 132,
		quantity: 458,
	},
	{
		garage_id: 249,
		part_id: 133,
		quantity: 402,
	},
	{
		garage_id: 249,
		part_id: 134,
		quantity: 11,
	},
	{
		garage_id: 249,
		part_id: 135,
		quantity: 420,
	},
	{
		garage_id: 249,
		part_id: 136,
		quantity: 500,
	},
	{
		garage_id: 249,
		part_id: 137,
		quantity: 377,
	},
	{
		garage_id: 249,
		part_id: 138,
		quantity: 203,
	},
	{
		garage_id: 249,
		part_id: 139,
		quantity: 329,
	},
	{
		garage_id: 249,
		part_id: 140,
		quantity: 202,
	},
	{
		garage_id: 249,
		part_id: 141,
		quantity: 291,
	},
	{
		garage_id: 249,
		part_id: 142,
		quantity: 486,
	},
	{
		garage_id: 249,
		part_id: 143,
		quantity: 132,
	},
	{
		garage_id: 249,
		part_id: 144,
		quantity: 168,
	},
	{
		garage_id: 249,
		part_id: 145,
		quantity: 362,
	},
	{
		garage_id: 249,
		part_id: 146,
		quantity: 212,
	},
	{
		garage_id: 249,
		part_id: 147,
		quantity: 367,
	},
	{
		garage_id: 249,
		part_id: 148,
		quantity: 253,
	},
	{
		garage_id: 249,
		part_id: 149,
		quantity: 461,
	},
	{
		garage_id: 249,
		part_id: 150,
		quantity: 488,
	},
	{
		garage_id: 249,
		part_id: 151,
		quantity: 125,
	},
	{
		garage_id: 249,
		part_id: 152,
		quantity: 314,
	},
	{
		garage_id: 249,
		part_id: 153,
		quantity: 387,
	},
	{
		garage_id: 249,
		part_id: 154,
		quantity: 320,
	},
	{
		garage_id: 249,
		part_id: 155,
		quantity: 240,
	},
	{
		garage_id: 249,
		part_id: 156,
		quantity: 49,
	},
	{
		garage_id: 249,
		part_id: 157,
		quantity: 44,
	},
	{
		garage_id: 249,
		part_id: 158,
		quantity: 196,
	},
	{
		garage_id: 249,
		part_id: 159,
		quantity: 48,
	},
	{
		garage_id: 249,
		part_id: 160,
		quantity: 483,
	},
	{
		garage_id: 249,
		part_id: 161,
		quantity: 84,
	},
	{
		garage_id: 249,
		part_id: 162,
		quantity: 494,
	},
	{
		garage_id: 249,
		part_id: 163,
		quantity: 29,
	},
	{
		garage_id: 249,
		part_id: 164,
		quantity: 495,
	},
	{
		garage_id: 249,
		part_id: 165,
		quantity: 98,
	},
	{
		garage_id: 249,
		part_id: 166,
		quantity: 193,
	},
	{
		garage_id: 249,
		part_id: 167,
		quantity: 144,
	},
	{
		garage_id: 249,
		part_id: 168,
		quantity: 279,
	},
	{
		garage_id: 249,
		part_id: 169,
		quantity: 250,
	},
	{
		garage_id: 249,
		part_id: 170,
		quantity: 196,
	},
	{
		garage_id: 249,
		part_id: 171,
		quantity: 330,
	},
	{
		garage_id: 249,
		part_id: 172,
		quantity: 68,
	},
	{
		garage_id: 249,
		part_id: 173,
		quantity: 474,
	},
	{
		garage_id: 249,
		part_id: 174,
		quantity: 460,
	},
	{
		garage_id: 249,
		part_id: 175,
		quantity: 153,
	},
	{
		garage_id: 249,
		part_id: 176,
		quantity: 415,
	},
	{
		garage_id: 249,
		part_id: 177,
		quantity: 443,
	},
	{
		garage_id: 249,
		part_id: 178,
		quantity: 226,
	},
	{
		garage_id: 249,
		part_id: 179,
		quantity: 247,
	},
	{
		garage_id: 249,
		part_id: 180,
		quantity: 393,
	},
	{
		garage_id: 249,
		part_id: 181,
		quantity: 433,
	},
	{
		garage_id: 249,
		part_id: 182,
		quantity: 433,
	},
	{
		garage_id: 249,
		part_id: 183,
		quantity: 450,
	},
	{
		garage_id: 249,
		part_id: 184,
		quantity: 214,
	},
	{
		garage_id: 249,
		part_id: 185,
		quantity: 85,
	},
	{
		garage_id: 249,
		part_id: 186,
		quantity: 247,
	},
	{
		garage_id: 249,
		part_id: 187,
		quantity: 21,
	},
	{
		garage_id: 249,
		part_id: 188,
		quantity: 128,
	},
	{
		garage_id: 249,
		part_id: 189,
		quantity: 361,
	},
	{
		garage_id: 249,
		part_id: 190,
		quantity: 371,
	},
	{
		garage_id: 249,
		part_id: 191,
		quantity: 427,
	},
	{
		garage_id: 249,
		part_id: 192,
		quantity: 167,
	},
	{
		garage_id: 249,
		part_id: 193,
		quantity: 461,
	},
	{
		garage_id: 249,
		part_id: 194,
		quantity: 278,
	},
	{
		garage_id: 249,
		part_id: 195,
		quantity: 459,
	},
	{
		garage_id: 249,
		part_id: 196,
		quantity: 331,
	},
	{
		garage_id: 249,
		part_id: 197,
		quantity: 57,
	},
	{
		garage_id: 249,
		part_id: 198,
		quantity: 200,
	},
	{
		garage_id: 249,
		part_id: 199,
		quantity: 422,
	},
	{
		garage_id: 249,
		part_id: 200,
		quantity: 328,
	},
	{
		garage_id: 249,
		part_id: 201,
		quantity: 381,
	},
	{
		garage_id: 249,
		part_id: 202,
		quantity: 397,
	},
	{
		garage_id: 249,
		part_id: 203,
		quantity: 343,
	},
	{
		garage_id: 249,
		part_id: 204,
		quantity: 111,
	},
	{
		garage_id: 249,
		part_id: 205,
		quantity: 451,
	},
	{
		garage_id: 249,
		part_id: 206,
		quantity: 154,
	},
	{
		garage_id: 249,
		part_id: 207,
		quantity: 87,
	},
	{
		garage_id: 249,
		part_id: 208,
		quantity: 361,
	},
	{
		garage_id: 249,
		part_id: 209,
		quantity: 276,
	},
	{
		garage_id: 249,
		part_id: 210,
		quantity: 9,
	},
	{
		garage_id: 249,
		part_id: 211,
		quantity: 174,
	},
	{
		garage_id: 249,
		part_id: 212,
		quantity: 100,
	},
	{
		garage_id: 249,
		part_id: 213,
		quantity: 141,
	},
	{
		garage_id: 249,
		part_id: 214,
		quantity: 173,
	},
	{
		garage_id: 249,
		part_id: 215,
		quantity: 136,
	},
	{
		garage_id: 249,
		part_id: 216,
		quantity: 359,
	},
	{
		garage_id: 249,
		part_id: 217,
		quantity: 403,
	},
	{
		garage_id: 249,
		part_id: 218,
		quantity: 493,
	},
	{
		garage_id: 249,
		part_id: 219,
		quantity: 157,
	},
	{
		garage_id: 249,
		part_id: 220,
		quantity: 93,
	},
	{
		garage_id: 249,
		part_id: 221,
		quantity: 208,
	},
	{
		garage_id: 249,
		part_id: 222,
		quantity: 28,
	},
	{
		garage_id: 249,
		part_id: 223,
		quantity: 90,
	},
	{
		garage_id: 249,
		part_id: 224,
		quantity: 469,
	},
	{
		garage_id: 249,
		part_id: 225,
		quantity: 412,
	},
	{
		garage_id: 249,
		part_id: 226,
		quantity: 62,
	},
	{
		garage_id: 249,
		part_id: 227,
		quantity: 368,
	},
	{
		garage_id: 249,
		part_id: 228,
		quantity: 24,
	},
	{
		garage_id: 249,
		part_id: 229,
		quantity: 249,
	},
	{
		garage_id: 249,
		part_id: 230,
		quantity: 416,
	},
	{
		garage_id: 249,
		part_id: 231,
		quantity: 435,
	},
	{
		garage_id: 249,
		part_id: 232,
		quantity: 49,
	},
	{
		garage_id: 249,
		part_id: 233,
		quantity: 400,
	},
	{
		garage_id: 249,
		part_id: 234,
		quantity: 362,
	},
	{
		garage_id: 249,
		part_id: 235,
		quantity: 84,
	},
	{
		garage_id: 249,
		part_id: 236,
		quantity: 91,
	},
	{
		garage_id: 249,
		part_id: 237,
		quantity: 17,
	},
	{
		garage_id: 249,
		part_id: 238,
		quantity: 36,
	},
	{
		garage_id: 249,
		part_id: 239,
		quantity: 143,
	},
	{
		garage_id: 249,
		part_id: 240,
		quantity: 412,
	},
	{
		garage_id: 249,
		part_id: 241,
		quantity: 164,
	},
	{
		garage_id: 249,
		part_id: 242,
		quantity: 344,
	},
	{
		garage_id: 249,
		part_id: 243,
		quantity: 412,
	},
	{
		garage_id: 249,
		part_id: 244,
		quantity: 154,
	},
	{
		garage_id: 249,
		part_id: 245,
		quantity: 11,
	},
	{
		garage_id: 249,
		part_id: 246,
		quantity: 352,
	},
	{
		garage_id: 249,
		part_id: 247,
		quantity: 192,
	},
	{
		garage_id: 249,
		part_id: 248,
		quantity: 244,
	},
	{
		garage_id: 249,
		part_id: 249,
		quantity: 396,
	},
	{
		garage_id: 249,
		part_id: 250,
		quantity: 157,
	},
	{
		garage_id: 249,
		part_id: 251,
		quantity: 173,
	},
	{
		garage_id: 249,
		part_id: 252,
		quantity: 358,
	},
	{
		garage_id: 249,
		part_id: 253,
		quantity: 75,
	},
	{
		garage_id: 249,
		part_id: 254,
		quantity: 224,
	},
	{
		garage_id: 249,
		part_id: 255,
		quantity: 27,
	},
	{
		garage_id: 249,
		part_id: 256,
		quantity: 493,
	},
	{
		garage_id: 249,
		part_id: 257,
		quantity: 480,
	},
	{
		garage_id: 249,
		part_id: 258,
		quantity: 197,
	},
	{
		garage_id: 249,
		part_id: 259,
		quantity: 289,
	},
	{
		garage_id: 249,
		part_id: 260,
		quantity: 46,
	},
	{
		garage_id: 249,
		part_id: 261,
		quantity: 374,
	},
	{
		garage_id: 249,
		part_id: 262,
		quantity: 191,
	},
	{
		garage_id: 249,
		part_id: 263,
		quantity: 225,
	},
	{
		garage_id: 249,
		part_id: 264,
		quantity: 349,
	},
	{
		garage_id: 249,
		part_id: 265,
		quantity: 35,
	},
	{
		garage_id: 249,
		part_id: 266,
		quantity: 485,
	},
	{
		garage_id: 249,
		part_id: 267,
		quantity: 84,
	},
	{
		garage_id: 249,
		part_id: 268,
		quantity: 335,
	},
	{
		garage_id: 249,
		part_id: 269,
		quantity: 389,
	},
	{
		garage_id: 249,
		part_id: 270,
		quantity: 230,
	},
	{
		garage_id: 249,
		part_id: 271,
		quantity: 159,
	},
	{
		garage_id: 249,
		part_id: 272,
		quantity: 140,
	},
	{
		garage_id: 249,
		part_id: 273,
		quantity: 391,
	},
	{
		garage_id: 249,
		part_id: 274,
		quantity: 45,
	},
	{
		garage_id: 249,
		part_id: 275,
		quantity: 236,
	},
	{
		garage_id: 249,
		part_id: 276,
		quantity: 445,
	},
	{
		garage_id: 249,
		part_id: 277,
		quantity: 58,
	},
	{
		garage_id: 249,
		part_id: 278,
		quantity: 203,
	},
	{
		garage_id: 249,
		part_id: 279,
		quantity: 213,
	},
	{
		garage_id: 249,
		part_id: 280,
		quantity: 392,
	},
	{
		garage_id: 249,
		part_id: 281,
		quantity: 152,
	},
	{
		garage_id: 249,
		part_id: 282,
		quantity: 370,
	},
	{
		garage_id: 249,
		part_id: 283,
		quantity: 273,
	},
	{
		garage_id: 249,
		part_id: 284,
		quantity: 354,
	},
	{
		garage_id: 249,
		part_id: 285,
		quantity: 445,
	},
	{
		garage_id: 249,
		part_id: 286,
		quantity: 450,
	},
	{
		garage_id: 249,
		part_id: 287,
		quantity: 103,
	},
	{
		garage_id: 249,
		part_id: 288,
		quantity: 203,
	},
	{
		garage_id: 249,
		part_id: 289,
		quantity: 303,
	},
	{
		garage_id: 249,
		part_id: 290,
		quantity: 360,
	},
	{
		garage_id: 249,
		part_id: 291,
		quantity: 169,
	},
	{
		garage_id: 249,
		part_id: 292,
		quantity: 184,
	},
	{
		garage_id: 249,
		part_id: 293,
		quantity: 164,
	},
	{
		garage_id: 249,
		part_id: 294,
		quantity: 30,
	},
	{
		garage_id: 249,
		part_id: 295,
		quantity: 315,
	},
	{
		garage_id: 249,
		part_id: 296,
		quantity: 167,
	},
	{
		garage_id: 249,
		part_id: 297,
		quantity: 400,
	},
	{
		garage_id: 249,
		part_id: 298,
		quantity: 400,
	},
	{
		garage_id: 249,
		part_id: 299,
		quantity: 95,
	},
	{
		garage_id: 249,
		part_id: 300,
		quantity: 165,
	},
	{
		garage_id: 249,
		part_id: 301,
		quantity: 456,
	},
	{
		garage_id: 249,
		part_id: 302,
		quantity: 370,
	},
	{
		garage_id: 249,
		part_id: 303,
		quantity: 55,
	},
	{
		garage_id: 249,
		part_id: 304,
		quantity: 90,
	},
	{
		garage_id: 249,
		part_id: 305,
		quantity: 384,
	},
	{
		garage_id: 249,
		part_id: 306,
		quantity: 51,
	},
	{
		garage_id: 249,
		part_id: 307,
		quantity: 111,
	},
	{
		garage_id: 249,
		part_id: 308,
		quantity: 0,
	},
	{
		garage_id: 249,
		part_id: 309,
		quantity: 418,
	},
	{
		garage_id: 249,
		part_id: 310,
		quantity: 36,
	},
	{
		garage_id: 249,
		part_id: 311,
		quantity: 282,
	},
	{
		garage_id: 249,
		part_id: 312,
		quantity: 398,
	},
	{
		garage_id: 249,
		part_id: 313,
		quantity: 461,
	},
	{
		garage_id: 249,
		part_id: 314,
		quantity: 298,
	},
	{
		garage_id: 249,
		part_id: 315,
		quantity: 425,
	},
	{
		garage_id: 249,
		part_id: 316,
		quantity: 227,
	},
	{
		garage_id: 249,
		part_id: 317,
		quantity: 330,
	},
	{
		garage_id: 249,
		part_id: 318,
		quantity: 437,
	},
	{
		garage_id: 249,
		part_id: 319,
		quantity: 141,
	},
	{
		garage_id: 249,
		part_id: 320,
		quantity: 117,
	},
	{
		garage_id: 249,
		part_id: 321,
		quantity: 144,
	},
	{
		garage_id: 249,
		part_id: 322,
		quantity: 475,
	},
	{
		garage_id: 249,
		part_id: 323,
		quantity: 60,
	},
	{
		garage_id: 249,
		part_id: 324,
		quantity: 465,
	},
	{
		garage_id: 249,
		part_id: 325,
		quantity: 433,
	},
	{
		garage_id: 249,
		part_id: 326,
		quantity: 329,
	},
	{
		garage_id: 249,
		part_id: 327,
		quantity: 36,
	},
	{
		garage_id: 249,
		part_id: 328,
		quantity: 124,
	},
	{
		garage_id: 249,
		part_id: 329,
		quantity: 94,
	},
	{
		garage_id: 249,
		part_id: 330,
		quantity: 325,
	},
	{
		garage_id: 249,
		part_id: 331,
		quantity: 494,
	},
	{
		garage_id: 249,
		part_id: 332,
		quantity: 421,
	},
	{
		garage_id: 249,
		part_id: 333,
		quantity: 170,
	},
	{
		garage_id: 249,
		part_id: 334,
		quantity: 8,
	},
	{
		garage_id: 249,
		part_id: 335,
		quantity: 2,
	},
	{
		garage_id: 249,
		part_id: 336,
		quantity: 313,
	},
	{
		garage_id: 249,
		part_id: 337,
		quantity: 466,
	},
	{
		garage_id: 249,
		part_id: 338,
		quantity: 375,
	},
	{
		garage_id: 249,
		part_id: 339,
		quantity: 192,
	},
	{
		garage_id: 249,
		part_id: 340,
		quantity: 312,
	},
	{
		garage_id: 249,
		part_id: 341,
		quantity: 101,
	},
	{
		garage_id: 249,
		part_id: 342,
		quantity: 52,
	},
	{
		garage_id: 249,
		part_id: 343,
		quantity: 172,
	},
	{
		garage_id: 249,
		part_id: 344,
		quantity: 300,
	},
	{
		garage_id: 249,
		part_id: 345,
		quantity: 408,
	},
	{
		garage_id: 249,
		part_id: 346,
		quantity: 244,
	},
	{
		garage_id: 249,
		part_id: 347,
		quantity: 84,
	},
	{
		garage_id: 249,
		part_id: 348,
		quantity: 19,
	},
	{
		garage_id: 249,
		part_id: 349,
		quantity: 432,
	},
	{
		garage_id: 249,
		part_id: 350,
		quantity: 477,
	},
	{
		garage_id: 249,
		part_id: 351,
		quantity: 233,
	},
	{
		garage_id: 249,
		part_id: 352,
		quantity: 315,
	},
	{
		garage_id: 249,
		part_id: 353,
		quantity: 416,
	},
	{
		garage_id: 249,
		part_id: 354,
		quantity: 468,
	},
	{
		garage_id: 249,
		part_id: 355,
		quantity: 140,
	},
	{
		garage_id: 249,
		part_id: 356,
		quantity: 323,
	},
	{
		garage_id: 249,
		part_id: 357,
		quantity: 99,
	},
	{
		garage_id: 249,
		part_id: 358,
		quantity: 47,
	},
	{
		garage_id: 249,
		part_id: 359,
		quantity: 115,
	},
	{
		garage_id: 249,
		part_id: 360,
		quantity: 321,
	},
	{
		garage_id: 249,
		part_id: 361,
		quantity: 85,
	},
	{
		garage_id: 249,
		part_id: 362,
		quantity: 480,
	},
	{
		garage_id: 249,
		part_id: 363,
		quantity: 320,
	},
	{
		garage_id: 249,
		part_id: 364,
		quantity: 248,
	},
	{
		garage_id: 249,
		part_id: 365,
		quantity: 79,
	},
	{
		garage_id: 249,
		part_id: 366,
		quantity: 268,
	},
	{
		garage_id: 249,
		part_id: 367,
		quantity: 261,
	},
	{
		garage_id: 249,
		part_id: 368,
		quantity: 13,
	},
	{
		garage_id: 249,
		part_id: 369,
		quantity: 442,
	},
	{
		garage_id: 249,
		part_id: 370,
		quantity: 423,
	},
	{
		garage_id: 249,
		part_id: 371,
		quantity: 247,
	},
	{
		garage_id: 249,
		part_id: 372,
		quantity: 210,
	},
	{
		garage_id: 249,
		part_id: 373,
		quantity: 494,
	},
	{
		garage_id: 249,
		part_id: 374,
		quantity: 477,
	},
	{
		garage_id: 249,
		part_id: 375,
		quantity: 158,
	},
	{
		garage_id: 249,
		part_id: 376,
		quantity: 86,
	},
	{
		garage_id: 249,
		part_id: 377,
		quantity: 340,
	},
	{
		garage_id: 249,
		part_id: 378,
		quantity: 175,
	},
	{
		garage_id: 249,
		part_id: 379,
		quantity: 329,
	},
	{
		garage_id: 249,
		part_id: 380,
		quantity: 84,
	},
	{
		garage_id: 249,
		part_id: 381,
		quantity: 398,
	},
	{
		garage_id: 249,
		part_id: 382,
		quantity: 95,
	},
	{
		garage_id: 249,
		part_id: 383,
		quantity: 1,
	},
	{
		garage_id: 249,
		part_id: 384,
		quantity: 306,
	},
	{
		garage_id: 249,
		part_id: 385,
		quantity: 146,
	},
	{
		garage_id: 249,
		part_id: 386,
		quantity: 452,
	},
	{
		garage_id: 249,
		part_id: 387,
		quantity: 290,
	},
	{
		garage_id: 249,
		part_id: 388,
		quantity: 226,
	},
	{
		garage_id: 249,
		part_id: 389,
		quantity: 180,
	},
	{
		garage_id: 249,
		part_id: 390,
		quantity: 121,
	},
	{
		garage_id: 249,
		part_id: 391,
		quantity: 105,
	},
	{
		garage_id: 249,
		part_id: 392,
		quantity: 343,
	},
	{
		garage_id: 249,
		part_id: 393,
		quantity: 101,
	},
	{
		garage_id: 249,
		part_id: 394,
		quantity: 228,
	},
	{
		garage_id: 249,
		part_id: 395,
		quantity: 160,
	},
	{
		garage_id: 249,
		part_id: 396,
		quantity: 26,
	},
	{
		garage_id: 249,
		part_id: 397,
		quantity: 415,
	},
	{
		garage_id: 249,
		part_id: 398,
		quantity: 183,
	},
	{
		garage_id: 249,
		part_id: 399,
		quantity: 406,
	},
];
