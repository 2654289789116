import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Select } from 'antd';
import QuicksightChart from '../components/QuicksightChart';
import { ArrowLeft } from '../components/Icons';
import { quicksightData } from '../../data/quicksightData';

// FIXME: Only pass required data to components
function Dashboard() {
	const [selectedImage, setSelectedImage] = useState();
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const mappedOptions = quicksightData.map((item) => {
			const { vin, component } = item;

			return {
				label: `${vin} - ${component}`,
				value: component,
			};
		});

		setOptions(mappedOptions);
		setSelectedImage(mappedOptions[0].value);
		// eslint-disable-next-line
	}, []);

	const handleChange = (value) => {
		setSelectedImage(value);
	};

	if (!options.length) {
		return null;
	}

	// Separate select for device/component
	return (
		<div className='dashboard'>
			<div className='dashboard__nav-link'>
				<NavLink to='/' className='link'>
					<ArrowLeft />
					Return to customer service overview
				</NavLink>
			</div>
			<header className='dashboard__header'>
				<h2 className='dashboard__heading'>
					Component Failure Prediction Dashboard
				</h2>
			</header>
			<div className='dashboard__content'>
				<div className='dashboard__legend'>
					<h4 className='dashboard__select-heading'>
						Select VIN & component
					</h4>
					<Select
						defaultValue={options[0].label}
						style={{
							width: 280,
						}}
						onChange={handleChange}
						options={options}
						className='dashboard__select'
					/>
				</div>

				<div className='dashboard__fleet-monitor app__content'>
					<QuicksightChart imageName={selectedImage} />
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
