export const garageAvailability = [
	{
		garage_id: 0,
		date: '21/10/2021',
		availability_flag: true,
	},
	{
		garage_id: 1,
		date: '18/10/2022',
		availability_flag: false,
	},
	{
		garage_id: 2,
		date: '17/04/2021',
		availability_flag: false,
	},
	{
		garage_id: 3,
		date: '24/01/2022',
		availability_flag: true,
	},
	{
		garage_id: 4,
		date: '11/05/2021',
		availability_flag: false,
	},
	{
		garage_id: 5,
		date: '16/12/2021',
		availability_flag: true,
	},
	{
		garage_id: 6,
		date: '16/01/2022',
		availability_flag: false,
	},
	{
		garage_id: 7,
		date: '07/01/2021',
		availability_flag: false,
	},
	{
		garage_id: 8,
		date: '04/12/2021',
		availability_flag: false,
	},
	{
		garage_id: 9,
		date: '05/04/2020',
		availability_flag: false,
	},
	{
		garage_id: 10,
		date: '02/09/2021',
		availability_flag: true,
	},
	{
		garage_id: 11,
		date: '08/04/2022',
		availability_flag: false,
	},
	{
		garage_id: 12,
		date: '25/04/2020',
		availability_flag: true,
	},
	{
		garage_id: 13,
		date: '15/06/2020',
		availability_flag: false,
	},
	{
		garage_id: 14,
		date: '02/05/2021',
		availability_flag: true,
	},
	{
		garage_id: 15,
		date: '28/01/2022',
		availability_flag: true,
	},
	{
		garage_id: 16,
		date: '17/12/2020',
		availability_flag: false,
	},
	{
		garage_id: 17,
		date: '07/11/2020',
		availability_flag: false,
	},
	{
		garage_id: 18,
		date: '30/08/2020',
		availability_flag: false,
	},
	{
		garage_id: 19,
		date: '29/06/2020',
		availability_flag: false,
	},
	{
		garage_id: 20,
		date: '12/06/2020',
		availability_flag: false,
	},
	{
		garage_id: 21,
		date: '04/10/2021',
		availability_flag: true,
	},
	{
		garage_id: 22,
		date: '08/07/2022',
		availability_flag: true,
	},
	{
		garage_id: 23,
		date: '02/08/2021',
		availability_flag: false,
	},
	{
		garage_id: 24,
		date: '01/03/2022',
		availability_flag: false,
	},
	{
		garage_id: 25,
		date: '16/11/2020',
		availability_flag: false,
	},
	{
		garage_id: 26,
		date: '23/05/2020',
		availability_flag: false,
	},
	{
		garage_id: 27,
		date: '23/07/2020',
		availability_flag: false,
	},
	{
		garage_id: 28,
		date: '12/04/2022',
		availability_flag: true,
	},
	{
		garage_id: 29,
		date: '30/08/2021',
		availability_flag: false,
	},
	{
		garage_id: 30,
		date: '04/04/2022',
		availability_flag: true,
	},
	{
		garage_id: 31,
		date: '24/11/2021',
		availability_flag: true,
	},
	{
		garage_id: 32,
		date: '30/03/2021',
		availability_flag: true,
	},
	{
		garage_id: 33,
		date: '15/06/2020',
		availability_flag: false,
	},
	{
		garage_id: 34,
		date: '05/02/2021',
		availability_flag: false,
	},
	{
		garage_id: 35,
		date: '11/11/2021',
		availability_flag: true,
	},
	{
		garage_id: 36,
		date: '16/08/2022',
		availability_flag: true,
	},
	{
		garage_id: 37,
		date: '21/07/2021',
		availability_flag: true,
	},
	{
		garage_id: 38,
		date: '04/12/2021',
		availability_flag: false,
	},
	{
		garage_id: 39,
		date: '15/08/2020',
		availability_flag: true,
	},
	{
		garage_id: 40,
		date: '17/07/2021',
		availability_flag: true,
	},
	{
		garage_id: 41,
		date: '15/07/2021',
		availability_flag: true,
	},
	{
		garage_id: 42,
		date: '12/01/2022',
		availability_flag: false,
	},
	{
		garage_id: 43,
		date: '06/06/2020',
		availability_flag: false,
	},
	{
		garage_id: 44,
		date: '19/07/2021',
		availability_flag: false,
	},
	{
		garage_id: 45,
		date: '13/07/2022',
		availability_flag: false,
	},
	{
		garage_id: 46,
		date: '10/08/2020',
		availability_flag: false,
	},
	{
		garage_id: 47,
		date: '13/01/2020',
		availability_flag: false,
	},
	{
		garage_id: 48,
		date: '04/07/2022',
		availability_flag: true,
	},
	{
		garage_id: 49,
		date: '13/01/2021',
		availability_flag: true,
	},
	{
		garage_id: 50,
		date: '02/11/2020',
		availability_flag: true,
	},
	{
		garage_id: 51,
		date: '02/01/2021',
		availability_flag: false,
	},
	{
		garage_id: 52,
		date: '05/10/2020',
		availability_flag: true,
	},
	{
		garage_id: 53,
		date: '15/04/2021',
		availability_flag: true,
	},
	{
		garage_id: 54,
		date: '21/05/2020',
		availability_flag: false,
	},
	{
		garage_id: 55,
		date: '19/09/2021',
		availability_flag: false,
	},
	{
		garage_id: 56,
		date: '30/09/2020',
		availability_flag: true,
	},
	{
		garage_id: 57,
		date: '13/06/2020',
		availability_flag: true,
	},
	{
		garage_id: 58,
		date: '20/01/2021',
		availability_flag: false,
	},
	{
		garage_id: 59,
		date: '06/02/2022',
		availability_flag: true,
	},
	{
		garage_id: 60,
		date: '06/10/2021',
		availability_flag: true,
	},
	{
		garage_id: 61,
		date: '11/11/2020',
		availability_flag: true,
	},
	{
		garage_id: 62,
		date: '12/03/2022',
		availability_flag: false,
	},
	{
		garage_id: 63,
		date: '09/05/2022',
		availability_flag: true,
	},
	{
		garage_id: 64,
		date: '25/05/2020',
		availability_flag: false,
	},
	{
		garage_id: 65,
		date: '07/01/2020',
		availability_flag: false,
	},
	{
		garage_id: 66,
		date: '02/07/2020',
		availability_flag: false,
	},
	{
		garage_id: 67,
		date: '10/07/2020',
		availability_flag: true,
	},
	{
		garage_id: 68,
		date: '20/01/2021',
		availability_flag: true,
	},
	{
		garage_id: 69,
		date: '04/07/2021',
		availability_flag: false,
	},
	{
		garage_id: 70,
		date: '21/02/2022',
		availability_flag: true,
	},
	{
		garage_id: 71,
		date: '30/08/2020',
		availability_flag: false,
	},
	{
		garage_id: 72,
		date: '27/11/2021',
		availability_flag: true,
	},
	{
		garage_id: 73,
		date: '06/05/2020',
		availability_flag: false,
	},
	{
		garage_id: 74,
		date: '17/11/2020',
		availability_flag: true,
	},
	{
		garage_id: 75,
		date: '08/08/2021',
		availability_flag: false,
	},
	{
		garage_id: 76,
		date: '09/03/2022',
		availability_flag: true,
	},
	{
		garage_id: 77,
		date: '18/06/2022',
		availability_flag: true,
	},
	{
		garage_id: 78,
		date: '01/06/2020',
		availability_flag: false,
	},
	{
		garage_id: 79,
		date: '06/07/2021',
		availability_flag: true,
	},
	{
		garage_id: 80,
		date: '25/01/2022',
		availability_flag: true,
	},
	{
		garage_id: 81,
		date: '10/04/2020',
		availability_flag: true,
	},
	{
		garage_id: 82,
		date: '28/11/2022',
		availability_flag: true,
	},
	{
		garage_id: 83,
		date: '14/08/2022',
		availability_flag: false,
	},
	{
		garage_id: 84,
		date: '07/06/2022',
		availability_flag: false,
	},
	{
		garage_id: 85,
		date: '18/08/2021',
		availability_flag: true,
	},
	{
		garage_id: 86,
		date: '08/09/2020',
		availability_flag: false,
	},
	{
		garage_id: 87,
		date: '13/04/2020',
		availability_flag: true,
	},
	{
		garage_id: 88,
		date: '25/11/2022',
		availability_flag: false,
	},
	{
		garage_id: 89,
		date: '16/10/2022',
		availability_flag: false,
	},
	{
		garage_id: 90,
		date: '15/11/2020',
		availability_flag: true,
	},
	{
		garage_id: 91,
		date: '25/01/2022',
		availability_flag: false,
	},
	{
		garage_id: 92,
		date: '19/12/2021',
		availability_flag: true,
	},
	{
		garage_id: 93,
		date: '31/03/2020',
		availability_flag: true,
	},
	{
		garage_id: 94,
		date: '27/02/2022',
		availability_flag: true,
	},
	{
		garage_id: 95,
		date: '09/06/2021',
		availability_flag: false,
	},
	{
		garage_id: 96,
		date: '21/08/2020',
		availability_flag: false,
	},
	{
		garage_id: 97,
		date: '06/11/2021',
		availability_flag: false,
	},
	{
		garage_id: 98,
		date: '19/09/2021',
		availability_flag: false,
	},
	{
		garage_id: 99,
		date: '22/12/2022',
		availability_flag: false,
	},
	{
		garage_id: 100,
		date: '11/04/2021',
		availability_flag: false,
	},
	{
		garage_id: 101,
		date: '19/06/2022',
		availability_flag: true,
	},
	{
		garage_id: 102,
		date: '02/08/2021',
		availability_flag: true,
	},
	{
		garage_id: 103,
		date: '15/04/2022',
		availability_flag: false,
	},
	{
		garage_id: 104,
		date: '07/11/2022',
		availability_flag: true,
	},
	{
		garage_id: 105,
		date: '17/11/2020',
		availability_flag: true,
	},
	{
		garage_id: 106,
		date: '01/07/2022',
		availability_flag: true,
	},
	{
		garage_id: 107,
		date: '12/05/2022',
		availability_flag: false,
	},
	{
		garage_id: 108,
		date: '09/11/2021',
		availability_flag: true,
	},
	{
		garage_id: 109,
		date: '30/10/2022',
		availability_flag: true,
	},
	{
		garage_id: 110,
		date: '05/01/2021',
		availability_flag: false,
	},
	{
		garage_id: 111,
		date: '09/12/2021',
		availability_flag: true,
	},
	{
		garage_id: 112,
		date: '30/04/2020',
		availability_flag: true,
	},
	{
		garage_id: 113,
		date: '04/07/2020',
		availability_flag: false,
	},
	{
		garage_id: 114,
		date: '22/02/2020',
		availability_flag: false,
	},
	{
		garage_id: 115,
		date: '07/09/2022',
		availability_flag: false,
	},
	{
		garage_id: 116,
		date: '26/04/2021',
		availability_flag: true,
	},
	{
		garage_id: 117,
		date: '25/01/2020',
		availability_flag: true,
	},
	{
		garage_id: 118,
		date: '06/07/2021',
		availability_flag: false,
	},
	{
		garage_id: 119,
		date: '09/07/2020',
		availability_flag: false,
	},
	{
		garage_id: 120,
		date: '26/01/2021',
		availability_flag: false,
	},
	{
		garage_id: 121,
		date: '24/03/2022',
		availability_flag: true,
	},
	{
		garage_id: 122,
		date: '16/01/2021',
		availability_flag: true,
	},
	{
		garage_id: 123,
		date: '25/10/2021',
		availability_flag: true,
	},
	{
		garage_id: 124,
		date: '28/01/2022',
		availability_flag: false,
	},
	{
		garage_id: 125,
		date: '10/03/2022',
		availability_flag: true,
	},
	{
		garage_id: 126,
		date: '19/09/2021',
		availability_flag: false,
	},
	{
		garage_id: 127,
		date: '29/12/2021',
		availability_flag: true,
	},
	{
		garage_id: 128,
		date: '05/02/2020',
		availability_flag: false,
	},
	{
		garage_id: 129,
		date: '07/02/2020',
		availability_flag: false,
	},
	{
		garage_id: 130,
		date: '07/06/2020',
		availability_flag: true,
	},
	{
		garage_id: 131,
		date: '26/01/2022',
		availability_flag: true,
	},
	{
		garage_id: 132,
		date: '24/09/2021',
		availability_flag: true,
	},
	{
		garage_id: 133,
		date: '17/10/2021',
		availability_flag: false,
	},
	{
		garage_id: 134,
		date: '04/12/2022',
		availability_flag: false,
	},
	{
		garage_id: 135,
		date: '02/07/2020',
		availability_flag: true,
	},
	{
		garage_id: 136,
		date: '18/07/2022',
		availability_flag: false,
	},
	{
		garage_id: 137,
		date: '22/04/2020',
		availability_flag: true,
	},
	{
		garage_id: 138,
		date: '19/12/2020',
		availability_flag: false,
	},
	{
		garage_id: 139,
		date: '12/12/2022',
		availability_flag: true,
	},
	{
		garage_id: 140,
		date: '30/09/2020',
		availability_flag: false,
	},
	{
		garage_id: 141,
		date: '17/02/2021',
		availability_flag: false,
	},
	{
		garage_id: 142,
		date: '19/04/2020',
		availability_flag: false,
	},
	{
		garage_id: 143,
		date: '24/07/2021',
		availability_flag: true,
	},
	{
		garage_id: 144,
		date: '17/09/2022',
		availability_flag: false,
	},
	{
		garage_id: 145,
		date: '10/03/2020',
		availability_flag: true,
	},
	{
		garage_id: 146,
		date: '25/01/2021',
		availability_flag: false,
	},
	{
		garage_id: 147,
		date: '28/06/2022',
		availability_flag: false,
	},
	{
		garage_id: 148,
		date: '17/03/2020',
		availability_flag: false,
	},
	{
		garage_id: 149,
		date: '04/10/2021',
		availability_flag: true,
	},
	{
		garage_id: 150,
		date: '30/05/2020',
		availability_flag: false,
	},
	{
		garage_id: 151,
		date: '12/11/2020',
		availability_flag: true,
	},
	{
		garage_id: 152,
		date: '04/08/2022',
		availability_flag: false,
	},
	{
		garage_id: 153,
		date: '13/05/2021',
		availability_flag: false,
	},
	{
		garage_id: 154,
		date: '02/12/2021',
		availability_flag: false,
	},
	{
		garage_id: 155,
		date: '01/11/2022',
		availability_flag: true,
	},
	{
		garage_id: 156,
		date: '04/06/2020',
		availability_flag: true,
	},
	{
		garage_id: 157,
		date: '06/08/2021',
		availability_flag: false,
	},
	{
		garage_id: 158,
		date: '28/12/2022',
		availability_flag: false,
	},
	{
		garage_id: 159,
		date: '03/10/2020',
		availability_flag: false,
	},
	{
		garage_id: 160,
		date: '17/01/2020',
		availability_flag: false,
	},
	{
		garage_id: 161,
		date: '01/06/2020',
		availability_flag: false,
	},
	{
		garage_id: 162,
		date: '25/06/2022',
		availability_flag: false,
	},
	{
		garage_id: 163,
		date: '25/12/2021',
		availability_flag: false,
	},
	{
		garage_id: 164,
		date: '03/02/2020',
		availability_flag: false,
	},
	{
		garage_id: 165,
		date: '05/01/2020',
		availability_flag: true,
	},
	{
		garage_id: 166,
		date: '27/04/2020',
		availability_flag: true,
	},
	{
		garage_id: 167,
		date: '02/06/2022',
		availability_flag: true,
	},
	{
		garage_id: 168,
		date: '16/09/2020',
		availability_flag: true,
	},
	{
		garage_id: 169,
		date: '01/02/2020',
		availability_flag: true,
	},
	{
		garage_id: 170,
		date: '13/07/2021',
		availability_flag: false,
	},
	{
		garage_id: 171,
		date: '30/08/2022',
		availability_flag: false,
	},
	{
		garage_id: 172,
		date: '29/10/2021',
		availability_flag: false,
	},
	{
		garage_id: 173,
		date: '04/03/2022',
		availability_flag: false,
	},
	{
		garage_id: 174,
		date: '29/11/2022',
		availability_flag: true,
	},
	{
		garage_id: 175,
		date: '25/06/2021',
		availability_flag: true,
	},
	{
		garage_id: 176,
		date: '03/02/2022',
		availability_flag: false,
	},
	{
		garage_id: 177,
		date: '11/11/2020',
		availability_flag: false,
	},
	{
		garage_id: 178,
		date: '07/12/2021',
		availability_flag: true,
	},
	{
		garage_id: 179,
		date: '29/11/2022',
		availability_flag: false,
	},
	{
		garage_id: 180,
		date: '28/05/2022',
		availability_flag: false,
	},
	{
		garage_id: 181,
		date: '09/11/2020',
		availability_flag: false,
	},
	{
		garage_id: 182,
		date: '21/12/2022',
		availability_flag: true,
	},
	{
		garage_id: 183,
		date: '02/01/2021',
		availability_flag: false,
	},
	{
		garage_id: 184,
		date: '10/03/2022',
		availability_flag: true,
	},
	{
		garage_id: 185,
		date: '13/11/2021',
		availability_flag: true,
	},
	{
		garage_id: 186,
		date: '21/07/2020',
		availability_flag: true,
	},
	{
		garage_id: 187,
		date: '23/03/2021',
		availability_flag: false,
	},
	{
		garage_id: 188,
		date: '26/07/2020',
		availability_flag: false,
	},
	{
		garage_id: 189,
		date: '08/08/2022',
		availability_flag: false,
	},
	{
		garage_id: 190,
		date: '18/05/2020',
		availability_flag: false,
	},
	{
		garage_id: 191,
		date: '05/01/2020',
		availability_flag: false,
	},
	{
		garage_id: 192,
		date: '29/08/2020',
		availability_flag: true,
	},
	{
		garage_id: 193,
		date: '15/10/2022',
		availability_flag: false,
	},
	{
		garage_id: 194,
		date: '13/10/2022',
		availability_flag: false,
	},
	{
		garage_id: 195,
		date: '14/11/2022',
		availability_flag: true,
	},
	{
		garage_id: 196,
		date: '16/02/2020',
		availability_flag: false,
	},
	{
		garage_id: 197,
		date: '26/04/2020',
		availability_flag: true,
	},
	{
		garage_id: 198,
		date: '28/03/2022',
		availability_flag: true,
	},
	{
		garage_id: 199,
		date: '30/08/2020',
		availability_flag: false,
	},
	{
		garage_id: 200,
		date: '09/12/2020',
		availability_flag: true,
	},
	{
		garage_id: 201,
		date: '26/12/2022',
		availability_flag: false,
	},
	{
		garage_id: 202,
		date: '23/04/2021',
		availability_flag: false,
	},
	{
		garage_id: 203,
		date: '16/08/2021',
		availability_flag: true,
	},
	{
		garage_id: 204,
		date: '25/01/2022',
		availability_flag: false,
	},
	{
		garage_id: 205,
		date: '01/01/2022',
		availability_flag: true,
	},
	{
		garage_id: 206,
		date: '17/10/2021',
		availability_flag: true,
	},
	{
		garage_id: 207,
		date: '27/09/2022',
		availability_flag: false,
	},
	{
		garage_id: 208,
		date: '15/08/2022',
		availability_flag: true,
	},
	{
		garage_id: 209,
		date: '12/10/2021',
		availability_flag: false,
	},
	{
		garage_id: 210,
		date: '11/12/2022',
		availability_flag: false,
	},
	{
		garage_id: 211,
		date: '25/06/2022',
		availability_flag: true,
	},
	{
		garage_id: 212,
		date: '23/07/2021',
		availability_flag: true,
	},
	{
		garage_id: 213,
		date: '31/12/2021',
		availability_flag: true,
	},
	{
		garage_id: 214,
		date: '15/12/2021',
		availability_flag: false,
	},
	{
		garage_id: 215,
		date: '24/03/2022',
		availability_flag: true,
	},
	{
		garage_id: 216,
		date: '21/02/2022',
		availability_flag: false,
	},
	{
		garage_id: 217,
		date: '03/08/2021',
		availability_flag: true,
	},
	{
		garage_id: 218,
		date: '24/03/2022',
		availability_flag: false,
	},
	{
		garage_id: 219,
		date: '01/10/2020',
		availability_flag: false,
	},
	{
		garage_id: 220,
		date: '14/05/2020',
		availability_flag: false,
	},
	{
		garage_id: 221,
		date: '24/05/2021',
		availability_flag: false,
	},
	{
		garage_id: 222,
		date: '23/05/2020',
		availability_flag: true,
	},
	{
		garage_id: 223,
		date: '14/09/2020',
		availability_flag: false,
	},
	{
		garage_id: 224,
		date: '21/10/2020',
		availability_flag: true,
	},
	{
		garage_id: 225,
		date: '27/10/2021',
		availability_flag: true,
	},
	{
		garage_id: 226,
		date: '16/09/2022',
		availability_flag: true,
	},
	{
		garage_id: 227,
		date: '29/04/2021',
		availability_flag: true,
	},
	{
		garage_id: 228,
		date: '11/07/2021',
		availability_flag: true,
	},
	{
		garage_id: 229,
		date: '16/09/2022',
		availability_flag: true,
	},
	{
		garage_id: 230,
		date: '18/05/2022',
		availability_flag: true,
	},
	{
		garage_id: 231,
		date: '30/07/2022',
		availability_flag: false,
	},
	{
		garage_id: 232,
		date: '18/09/2022',
		availability_flag: true,
	},
	{
		garage_id: 233,
		date: '23/08/2022',
		availability_flag: false,
	},
	{
		garage_id: 234,
		date: '06/11/2022',
		availability_flag: false,
	},
	{
		garage_id: 235,
		date: '01/03/2021',
		availability_flag: false,
	},
	{
		garage_id: 236,
		date: '15/05/2022',
		availability_flag: false,
	},
	{
		garage_id: 237,
		date: '20/05/2021',
		availability_flag: true,
	},
	{
		garage_id: 238,
		date: '11/06/2022',
		availability_flag: false,
	},
	{
		garage_id: 239,
		date: '01/10/2022',
		availability_flag: true,
	},
	{
		garage_id: 240,
		date: '02/06/2021',
		availability_flag: true,
	},
	{
		garage_id: 241,
		date: '04/03/2020',
		availability_flag: true,
	},
	{
		garage_id: 242,
		date: '10/08/2021',
		availability_flag: false,
	},
	{
		garage_id: 243,
		date: '24/08/2021',
		availability_flag: true,
	},
	{
		garage_id: 244,
		date: '10/10/2020',
		availability_flag: false,
	},
	{
		garage_id: 245,
		date: '28/03/2020',
		availability_flag: true,
	},
	{
		garage_id: 246,
		date: '09/05/2022',
		availability_flag: false,
	},
	{
		garage_id: 247,
		date: '10/01/2020',
		availability_flag: true,
	},
	{
		garage_id: 248,
		date: '11/03/2020',
		availability_flag: true,
	},
	{
		garage_id: 249,
		date: '06/02/2020',
		availability_flag: true,
	},
];
