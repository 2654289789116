// Image dimensions 1760 x 689
// Image dimensions 1760 x 580

export const quicksightData = [
	{
		vin: '5LUSTJ8S82LAWP6U7',
		component: 'Z1F14BGY',
	},
	{
		vin: '7NNG3W8R26JPC53EJ',
		component: 'S1F13H80',
	},
	{
		vin: '95HTMZH10JKY0VXNM',
		component: 'S1F135TN',
	},
	{
		vin: '9ZM7V7W150T05XGMS',
		component: 'S1F03YZM',
	},
	{
		vin: 'H14YJENE6CG973KV3',
		component: 'S1F0BVK1',
	},
	{
		vin: 'J9Y71JXX6VMB4GX85',
		component: 'W1F1CJ1K',
	},
	{
		vin: 'S5026DRT9WMAPKL64',
		component: 'W1F1B0KF',
	},
	{
		vin: 'SY5400K6XF8TERVVK',
		component: 'S1F0S4EG',
	},
];
