import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { withAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import Dashboard from './Dashboard';
import ServiceOverview from './ServiceOverview';
import VehicleDetail from './VehicleDetail';
import { carDetails, carHealth, customerDetails, carModel } from '../../data';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../../aws-exports';

Amplify.configure(awsExports);

function App({ signOut }) {
	const [pageData, setPageData] = useState([]);

	// FIXME: currently we are assuming these values will always be here as we are using
	// mock data, we will need to add checks + data sanitisation once we're finally
	// using real data. We will also need to make sure data isn't overridden when we merge
	// information e.g. description
	useEffect(() => {
		// Merge car details and car health and car model
		const mergedCarInfo = carDetails
			.map((detailsItem) => {
				const { vin, model_id } = detailsItem;

				const carHealthItem = carHealth.find(
					(healthItem) => vin === healthItem.vin
				);

				const carModelItem = carModel.find(
					(modelItem) => model_id === modelItem.model_id
				);

				return {
					vin,
					model_id,
					key: vin,
					...detailsItem,
					...carHealthItem,
					...carModelItem,
				};
			})
			.sort((itemA, itemB) => itemA.time_period - itemB.time_period);

		// Merge new car info object with customer details
		if (mergedCarInfo.length) {
			const mergedCarCustomerInfo = mergedCarInfo.map((carInfoItem) => {
				const { customer_id } = carInfoItem;
				const customerDetailsItem = customerDetails.find(
					(detailsItem) => customer_id === detailsItem.customer_id
				);

				return {
					customer_id,
					...carInfoItem,
					...customerDetailsItem,
				};
			});

			if (mergedCarCustomerInfo) {
				setPageData(mergedCarCustomerInfo);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setPageData, customerDetails, carDetails, carHealth]);

	return (
		<div className='app'>
			<Authenticator hideSignUp={true} />
			<div className='app__authentication-bar'>
				<button
					onClick={signOut}
					className='app__logout-button button button--secondary'
				>
					Log out
				</button>
			</div>
			<Routes>
				<Route
					exact
					path='/'
					element={<ServiceOverview pageData={pageData} />}
				/>
				<Route
					path='dashboard'
					element={<Dashboard pageData={pageData} />}
				/>
				<Route
					path='vehicle-detail/:vin'
					element={<VehicleDetail pageData={pageData} />}
				/>
			</Routes>
		</div>
	);
}

export default withAuthenticator(App);
