export const carHealth = [
	{
		health_id: 0,
		vin: '82HFE9767U326DEZ2',
		urgency: 'CRITICAL',
		time_period: 93,
		measure: '?',
		value: '?',
		health_description:
			'Amet dolorem mollitia tempore. Porro voluptas at accusantium illum. Illum omnis eum aspernatur.',
		parts: [
			{
				name: 'Battery',
				id: 303,
				description:
					'Est quo aliquam vitae occaecati quidem inventore. Nostrum eaque earum rerum praesentium.',
				recommendation:
					'Veritatis dolorem doloribus deleniti optio. Earum mollitia tempore cupiditate minus eveniet ipsa.',
			},
		],
	},
	{
		health_id: 1,
		vin: 'CTEVH1AU0MH0DM76R',
		urgency: 'MEDIUM',
		time_period: 22,
		measure: '?',
		value: '?',
		health_description:
			'Quos impedit ipsa. Consequatur tempora sed ex omnis.',
		parts: [
			{
				name: 'Battery',
				id: 249,
				description:
					'Incidunt id ex maxime dolorum nesciunt. Molestias harum ut reprehenderit. Doloremque maiores animi cum eveniet quis.',
				recommendation:
					'Ipsa fugiat enim facere. Fugiat harum expedita consequuntur voluptatibus delectus omnis. Ducimus incidunt ratione ex vel perspiciatis.',
			},
			{
				name: 'Brakes',
				id: 269,
				description:
					'Consequuntur voluptates sequi quae consectetur hic. Ut a ratione saepe a. Quidem quibusdam quod magnam laborum illum distinctio.',
				recommendation:
					'Repellat dicta dignissimos qui optio dolores ipsa. Qui distinctio atque necessitatibus repudiandae perspiciatis similique.',
			},
		],
	},
	{
		health_id: 2,
		vin: '7NNG3W8R26JPC53EJ',
		urgency: 'CRITICAL',
		time_period: 75,
		measure: '?',
		value: '?',
		health_description:
			'Vitae fuga dolorem dicta ducimus neque ipsa. Totam magni qui qui. Blanditiis fuga tenetur possimus deleniti veritatis enim inventore. Expedita pariatur minima iure dolor voluptate necessitatibus.',
		parts: [
			{
				name: 'Battery',
				id: 341,
				description: 'A explicabo repellendus nobis quod sapiente.',
				recommendation:
					'Officiis rerum aperiam aspernatur eligendi. In quasi assumenda modi.',
			},
			{
				name: 'Brakes',
				id: 157,
				description:
					'Quo deserunt eius quia odit tenetur labore. Reiciendis id non adipisci accusamus. Repudiandae quos expedita quas numquam iste.',
				recommendation:
					'Illo adipisci iure corrupti dolores minus dignissimos neque.',
			},
		],
	},
	{
		health_id: 3,
		vin: '6E7RHWPAXPNDH5AFA',
		urgency: 'LOW',
		time_period: 84,
		measure: '?',
		value: '?',
		health_description:
			'Quasi inventore aperiam totam dicta quaerat harum. Saepe quia numquam magnam.',
		parts: [
			{
				name: 'Battery',
				id: 23,
				description:
					'Similique quis voluptate vero doloribus minima. Doloribus fugit quae non eius quo culpa. Magni doloremque voluptatem in tempora explicabo iusto.',
				recommendation:
					'Ducimus nam quae at. Laudantium voluptatibus facere modi aspernatur. Soluta atque quae cumque ipsum error.',
			},
			{
				name: 'Brakes',
				id: 112,
				description: 'Et nulla et magnam veritatis error ad fugiat.',
				recommendation:
					'Eaque earum doloremque magni. Similique corporis quaerat quia mollitia optio.',
			},
		],
	},
	{
		health_id: 4,
		vin: 'WRHEL4E38LSH5DLDY',
		urgency: 'LOW',
		time_period: 29,
		measure: '?',
		value: '?',
		health_description:
			'Quasi a earum ut ullam animi. Ipsum cum minima eligendi voluptatibus accusamus ipsa. Earum occaecati expedita earum facere aliquid.',
		parts: [
			{
				name: 'Battery',
				id: 202,
				description:
					'Laborum ducimus perspiciatis deserunt natus nemo porro.',
				recommendation:
					'Voluptate assumenda omnis occaecati culpa tempora dolor. Magni iste laborum quas.',
			},
			{
				name: 'Brakes',
				id: 306,
				description:
					'Commodi dolorem sunt dolorem voluptas. Dolorum vel praesentium sunt ad. Sit ipsam sapiente vero dolorem.',
				recommendation:
					'Iure autem magnam. Minima veniam illo aut quam dignissimos labore.',
			},
		],
	},
	{
		health_id: 5,
		vin: 'SW0G9FGU5SPE9ZY64',
		urgency: 'LOW',
		time_period: 41,
		measure: '?',
		value: '?',
		health_description:
			'Vel pariatur aperiam ipsam. Tempora reprehenderit quia eveniet.',
		parts: [
			{
				name: 'Battery',
				id: 28,
				description:
					'Doloribus reiciendis amet eos nisi. Iure reiciendis voluptates reiciendis tenetur laborum. Ab impedit alias nam deserunt dignissimos repudiandae.',
				recommendation:
					'Inventore tempora adipisci. Et veniam fugiat quaerat harum.',
			},
			{
				name: 'Brakes',
				id: 3,
				description:
					'Numquam inventore blanditiis. Velit nam minima distinctio.',
				recommendation:
					'Dolor accusamus sed recusandae voluptates magni. Natus illum nobis.',
			},
		],
	},
	{
		health_id: 6,
		vin: '80AU5RRW2G18HM8JU',
		urgency: 'MEDIUM',
		time_period: 42,
		measure: '?',
		value: '?',
		health_description:
			'Aperiam totam quam animi quod occaecati maiores expedita. Eaque ex veniam temporibus corrupti. Sequi ut ad.',
		parts: [
			{
				name: 'Battery',
				id: 104,
				description:
					'Cupiditate corrupti blanditiis autem cum illo. Explicabo exercitationem ut deserunt omnis quia. Labore quidem provident magni perferendis voluptatum.',
				recommendation:
					'Soluta id provident soluta quos. Nostrum mollitia sed tempora consequuntur.',
			},
		],
	},
	{
		health_id: 7,
		vin: 'AW1GZBZ76KZC0PA1L',
		urgency: 'MEDIUM',
		time_period: 98,
		measure: '?',
		value: '?',
		health_description: 'Aliquam magnam placeat officia quia.',
		parts: [
			{
				name: 'Battery',
				id: 154,
				description:
					'Necessitatibus et neque molestias nihil mollitia saepe. Quo tempora rem.',
				recommendation:
					'Iste culpa nemo fugiat pariatur. Quos blanditiis rem quasi quibusdam. Exercitationem blanditiis laborum tenetur ad voluptatum sunt.',
			},
		],
	},
	{
		health_id: 8,
		vin: 'Y28PKF4F866Y599XA',
		urgency: 'HIGH',
		time_period: 65,
		measure: '?',
		value: '?',
		health_description:
			'Veritatis ullam est maxime minima. Vel quo quis dolores nihil odio amet.',
		parts: [
			{
				name: 'Battery',
				id: 108,
				description:
					'Ratione nihil quia recusandae. Culpa aliquam minima. Modi sequi voluptatem alias.',
				recommendation:
					'Eaque unde sunt dignissimos minima a suscipit. Perferendis sunt tenetur placeat voluptatum laudantium libero.',
			},
		],
	},
	{
		health_id: 9,
		vin: 'GUDCKSPVXV8FE5M2E',
		urgency: 'CRITICAL',
		time_period: 31,
		measure: '?',
		value: '?',
		health_description:
			'Occaecati deleniti optio id eos laborum dolorum. Aspernatur assumenda adipisci eaque. Numquam provident nesciunt sapiente in. Odit explicabo libero aut hic veniam voluptatum et.',
		parts: [
			{
				name: 'Battery',
				id: 392,
				description: 'Nemo omnis magnam ipsum eius. Nihil hic nihil.',
				recommendation:
					'Quis quos debitis sequi dicta. Vel eligendi molestiae commodi laudantium cumque.',
			},
			{
				name: 'Brakes',
				id: 70,
				description:
					'Ipsa doloribus saepe placeat aperiam quisquam dolorum. Facilis quos qui ad labore quisquam. Dolorem ducimus dolorem eligendi hic.',
				recommendation:
					'Quia ipsam beatae cumque alias placeat totam. Perferendis ipsum velit eveniet voluptas amet earum omnis.',
			},
		],
	},
	{
		health_id: 10,
		vin: 'E154E53M95ZFHYD9X',
		urgency: 'MEDIUM',
		time_period: 30,
		measure: '?',
		value: '?',
		health_description: 'Numquam veniam sapiente.',
		parts: [
			{
				name: 'Battery',
				id: 391,
				description:
					'Nesciunt sunt sunt quisquam magnam consectetur porro. Maxime nam nemo soluta. Eum suscipit eaque commodi ullam illo maxime ducimus.',
				recommendation:
					'Nam corporis architecto aspernatur dolor asperiores. Et placeat quibusdam.',
			},
		],
	},
	{
		health_id: 11,
		vin: 'FXTC77UNXUTW474SM',
		urgency: 'LOW',
		time_period: 18,
		measure: '?',
		value: '?',
		health_description:
			'Aliquam officia eaque voluptatem neque. Quas facilis reiciendis soluta. Blanditiis iste eos.',
		parts: [
			{
				name: 'Battery',
				id: 130,
				description:
					'Delectus ullam qui architecto commodi repellat. Nostrum reprehenderit non fugit. Fugit non eum amet doloribus aperiam minima.',
				recommendation:
					'Odio deleniti nulla exercitationem nostrum laudantium occaecati.',
			},
		],
	},
	{
		health_id: 12,
		vin: '7FCCV9UB3HWF5V741',
		urgency: 'LOW',
		time_period: 17,
		measure: '?',
		value: '?',
		health_description:
			'Accusantium veniam earum nostrum possimus et. Quam et maxime sed voluptatem. Deleniti fuga totam sit. Ratione eius ad nisi dicta omnis.',
		parts: [
			{
				name: 'Battery',
				id: 148,
				description:
					'Occaecati voluptates officia possimus natus. Ullam architecto non assumenda.',
				recommendation:
					'Eligendi fugiat eos illo. Ea distinctio perferendis non soluta in illo.',
			},
			{
				name: 'Brakes',
				id: 167,
				description:
					'Esse inventore error animi explicabo. Et a tempora quis nam voluptate dicta. Dolores dicta saepe natus.',
				recommendation:
					'Accusamus autem ad fugit. Excepturi incidunt sequi sint quidem adipisci.',
			},
		],
	},
	{
		health_id: 13,
		vin: '6FATYXDS24AR1RGWJ',
		urgency: 'MEDIUM',
		time_period: 38,
		measure: '?',
		value: '?',
		health_description:
			'Eum voluptates molestias quod vel sapiente. Perspiciatis incidunt eius adipisci ipsum maiores. Aliquam labore tempore ipsa rerum sint.',
		parts: [
			{
				name: 'Battery',
				id: 61,
				description:
					'Error quas reiciendis ipsum culpa perspiciatis deleniti. Commodi alias delectus fugiat. Corrupti vitae delectus aperiam alias.',
				recommendation:
					'Ea laborum assumenda unde distinctio eius. Ipsa vero non esse minima. Ullam aperiam maiores unde libero unde. Itaque alias laboriosam deserunt nostrum quam distinctio.',
			},
			{
				name: 'Brakes',
				id: 3,
				description:
					'Reiciendis dignissimos blanditiis enim. Qui quia quaerat ratione.',
				recommendation:
					'Porro delectus accusamus porro doloribus. Dicta ipsa quibusdam vero fugit minus voluptatum.',
			},
		],
	},
	{
		health_id: 14,
		vin: 'UY0CJD441L44XZA4Z',
		urgency: 'MEDIUM',
		time_period: 14,
		measure: '?',
		value: '?',
		health_description:
			'Mollitia magni ipsum temporibus. Ex rerum enim nam soluta.',
		parts: [
			{
				name: 'Battery',
				id: 254,
				description:
					'Quo voluptatem maiores tenetur repellendus facilis aliquam. Architecto quam in magnam.',
				recommendation:
					'Natus dolore odio. Earum perspiciatis reiciendis ea minus veritatis nisi. Blanditiis quia nam mollitia officiis magnam quae.',
			},
		],
	},
	{
		health_id: 15,
		vin: '6B55FS8F236TLGDLF',
		urgency: 'MEDIUM',
		time_period: 87,
		measure: '?',
		value: '?',
		health_description:
			'Aspernatur fugiat laborum officiis. Eos delectus asperiores nobis enim atque soluta. Perferendis ipsam veniam. Autem quia corrupti aliquam culpa quod eligendi.',
		parts: [
			{
				name: 'Battery',
				id: 382,
				description:
					'Delectus fuga accusantium asperiores nam eum. Id labore aut ad tempora maxime.',
				recommendation:
					'Ea necessitatibus non fugiat non. Dolorum voluptatibus harum quo repellendus eum. Totam in et molestias delectus maiores.',
			},
		],
	},
	{
		health_id: 16,
		vin: 'GS9KWL514W75DZG9N',
		urgency: 'HIGH',
		time_period: 89,
		measure: '?',
		value: '?',
		health_description:
			'Aliquam vitae illo molestias dolorem accusantium. Velit cupiditate neque blanditiis sed nostrum non.',
		parts: [
			{
				name: 'Battery',
				id: 220,
				description:
					'Ut odit tenetur. Exercitationem incidunt molestias deserunt.',
				recommendation:
					'Cumque et soluta. Dolorem officia laboriosam optio consequatur repellat. Rerum animi accusantium voluptas.',
			},
			{
				name: 'Brakes',
				id: 89,
				description:
					'In id nostrum ducimus et recusandae tempora. Ad quidem iure totam dolorem vel necessitatibus.',
				recommendation:
					'Eveniet dolor dolor deleniti blanditiis excepturi odio ullam. Facere delectus expedita sed. Sed officiis libero laudantium.',
			},
		],
	},
	{
		health_id: 17,
		vin: '5FPJAVK17K79G870H',
		urgency: 'LOW',
		time_period: 79,
		measure: '?',
		value: '?',
		health_description:
			'Molestias ducimus aliquam animi. Maiores consequuntur sequi odit dignissimos asperiores dolor.',
		parts: [
			{
				name: 'Battery',
				id: 66,
				description:
					'Ipsa quam quidem magnam. Cum tempora saepe quod accusantium. Temporibus similique commodi.',
				recommendation:
					'Distinctio fuga incidunt id. Ipsam nemo assumenda eaque maxime tempora animi. Necessitatibus excepturi delectus fugiat harum.',
			},
			{
				name: 'Brakes',
				id: 194,
				description:
					'Iusto optio sed veniam suscipit recusandae. Blanditiis est vero placeat ducimus fuga aut laborum.',
				recommendation:
					'Vitae aliquid quo facere fugit. Facere ullam officia eos labore.',
			},
		],
	},
	{
		health_id: 18,
		vin: 'JDMDGZYG146UH31M9',
		urgency: 'HIGH',
		time_period: 56,
		measure: '?',
		value: '?',
		health_description:
			'Tenetur recusandae animi amet. Consectetur exercitationem porro impedit.',
		parts: [
			{
				name: 'Battery',
				id: 272,
				description:
					'Distinctio consequuntur maxime corrupti. Fugiat animi commodi et iusto omnis fugit nobis. Error eveniet non facere sunt temporibus aut dolorem.',
				recommendation:
					'Unde nisi dolorem nostrum voluptatum itaque natus. Itaque pariatur delectus provident deserunt.',
			},
			{
				name: 'Brakes',
				id: 360,
				description:
					'Rem incidunt sit. Velit consequuntur odio assumenda occaecati eveniet id.',
				recommendation:
					'Praesentium architecto inventore dolore ex iusto dignissimos. Blanditiis ducimus adipisci ducimus veniam.',
			},
		],
	},
	{
		health_id: 19,
		vin: 'GAVU185053P0U93KP',
		urgency: 'HIGH',
		time_period: 22,
		measure: '?',
		value: '?',
		health_description:
			'Excepturi quibusdam error odit accusantium. Molestiae sunt nobis autem nostrum doloremque.',
		parts: [
			{
				name: 'Battery',
				id: 117,
				description: 'Incidunt dolorum cum.',
				recommendation:
					'Totam optio quam. Dolore neque soluta incidunt.',
			},
			{
				name: 'Brakes',
				id: 256,
				description:
					'Magnam exercitationem distinctio. Quidem eveniet perferendis dolores possimus facilis.',
				recommendation:
					'Sint praesentium aliquam voluptate pariatur asperiores praesentium. Ratione laudantium voluptatum inventore rerum dicta. Laudantium animi quia voluptas quibusdam voluptatibus.',
			},
		],
	},
	{
		health_id: 20,
		vin: '3NDF7NT06FABT2BMT',
		urgency: 'CRITICAL',
		time_period: 67,
		measure: '?',
		value: '?',
		health_description: 'Amet placeat corrupti iste dicta.',
		parts: [
			{
				name: 'Battery',
				id: 286,
				description:
					'Ex repudiandae et est pariatur numquam sed. Laborum quo inventore nihil voluptatum. Expedita neque tempora debitis deserunt nisi.',
				recommendation:
					'Totam consequatur adipisci expedita iusto. Harum doloribus dolore sequi officiis autem.',
			},
		],
	},
	{
		health_id: 21,
		vin: 'FHA7R3XE4CWNKEE2C',
		urgency: 'LOW',
		time_period: 56,
		measure: '?',
		value: '?',
		health_description:
			'Modi earum quisquam provident. Magni ab tempore suscipit facere.',
		parts: [
			{
				name: 'Battery',
				id: 342,
				description:
					'Ipsam deserunt recusandae mollitia corporis corporis. Modi deserunt fugit voluptatem corporis suscipit ad. Tempore fugiat optio maxime culpa quas.',
				recommendation:
					'Nihil distinctio molestiae suscipit. Sint atque cumque ullam. Distinctio tempora est.',
			},
		],
	},
	{
		health_id: 22,
		vin: 'SMH5HNZS6M28GBG37',
		urgency: 'LOW',
		time_period: 19,
		measure: '?',
		value: '?',
		health_description:
			'Ex occaecati quidem. Pariatur et fugiat esse et id.',
		parts: [
			{
				name: 'Battery',
				id: 181,
				description:
					'Tenetur possimus facere placeat numquam maiores recusandae repudiandae. Deleniti harum error dolor. Veniam ducimus adipisci tempora omnis minus praesentium illum.',
				recommendation:
					'Tempora atque possimus mollitia fuga. Ipsum ut similique quis. Hic exercitationem magnam optio officia blanditiis.',
			},
			{
				name: 'Brakes',
				id: 36,
				description:
					'Quia voluptatum placeat vitae magnam. Adipisci atque minima iure voluptatibus ipsa sequi.',
				recommendation:
					'Iste aperiam ad. Veritatis nesciunt dolorem occaecati quisquam error.',
			},
		],
	},
	{
		health_id: 23,
		vin: 'UNLUZ8RC3G3U1CPU5',
		urgency: 'MEDIUM',
		time_period: 57,
		measure: '?',
		value: '?',
		health_description:
			'Ad cum provident at pariatur soluta. Nostrum omnis corrupti natus. Earum dolore similique laborum excepturi.',
		parts: [
			{
				name: 'Battery',
				id: 203,
				description:
					'Temporibus omnis quas iste voluptates quae. Unde aspernatur exercitationem facilis corporis at.',
				recommendation:
					'Nisi odit molestiae provident dolor repudiandae totam. Cum velit fugit veritatis. Consectetur velit officiis tempore tenetur magnam suscipit.',
			},
		],
	},
	{
		health_id: 24,
		vin: 'ML8KZKTL5SJ9CTRBK',
		urgency: 'MEDIUM',
		time_period: 44,
		measure: '?',
		value: '?',
		health_description:
			'Mollitia rerum rem minima amet. Ratione consectetur vel dolorem.',
		parts: [
			{
				name: 'Battery',
				id: 379,
				description:
					'Voluptas quisquam deleniti mollitia quos. Ipsum beatae perferendis ex quibusdam iusto.',
				recommendation:
					'Officia vitae repellat corrupti quas facilis. Dolor ex inventore impedit accusamus facere sapiente voluptatem. Recusandae quod quidem rem.',
			},
		],
	},
	{
		health_id: 25,
		vin: 'S1KJDULW8VVDZXA6J',
		urgency: 'CRITICAL',
		time_period: 98,
		measure: '?',
		value: '?',
		health_description:
			'Sapiente voluptatibus nihil doloribus accusantium consectetur ipsum ab. Ipsum sed quasi quae alias.',
		parts: [
			{
				name: 'Battery',
				id: 21,
				description:
					'Fugit neque exercitationem aperiam ea tempore eius. Delectus nihil dolore molestias consectetur placeat aspernatur. Ipsum velit occaecati laboriosam voluptas corporis.',
				recommendation:
					'Distinctio tempora ipsa laboriosam accusamus officia. Adipisci facilis officia aliquam exercitationem. Iste veritatis excepturi aspernatur. Aspernatur velit consectetur odit ipsam tempora.',
			},
			{
				name: 'Brakes',
				id: 223,
				description:
					'Doloribus numquam inventore nostrum placeat maiores. Laboriosam quasi quod. Impedit non praesentium beatae consequuntur.',
				recommendation:
					'Nemo necessitatibus accusamus suscipit culpa earum mollitia.',
			},
		],
	},
	{
		health_id: 26,
		vin: 'ZM6FKEC0023FX5WTC',
		urgency: 'HIGH',
		time_period: 35,
		measure: '?',
		value: '?',
		health_description:
			'Qui tempore eaque architecto. Iure quisquam culpa dolores. Voluptatibus quaerat molestiae.',
		parts: [
			{
				name: 'Battery',
				id: 9,
				description:
					'Eos quos nobis porro amet laudantium eligendi. Doloribus sint velit.',
				recommendation:
					'Eius optio at rem error. Alias repellendus consectetur suscipit repellendus necessitatibus distinctio.',
			},
		],
	},
	{
		health_id: 27,
		vin: 'RYSF017U1EBW4F8W9',
		urgency: 'MEDIUM',
		time_period: 1,
		measure: '?',
		value: '?',
		health_description:
			'Illo ipsa nulla asperiores rerum. Maiores deleniti magni quisquam deleniti odio voluptas. Quis aperiam inventore cumque aspernatur dolor. Perferendis culpa aperiam minus animi nesciunt.',
		parts: [
			{
				name: 'Battery',
				id: 235,
				description:
					'Quo minima aut inventore excepturi quas. Vero culpa saepe dolorum autem corrupti quas qui. Atque voluptatibus repudiandae.',
				recommendation:
					'Veritatis ipsam ullam qui. Ad deserunt consectetur architecto veniam quos perferendis velit. Voluptas omnis non necessitatibus quo.',
			},
		],
	},
	{
		health_id: 28,
		vin: 'WLVZUVAX8VGFHYFHV',
		urgency: 'MEDIUM',
		time_period: 52,
		measure: '?',
		value: '?',
		health_description:
			'Quos reiciendis illum recusandae esse. Nam incidunt consequatur eius animi aliquam soluta. Expedita perferendis natus fugit.',
		parts: [
			{
				name: 'Battery',
				id: 39,
				description:
					'Nemo officia hic harum necessitatibus sit. Eum optio amet. Dolores in vitae explicabo aspernatur maiores. Qui ea quae porro nostrum placeat quis.',
				recommendation:
					'Rerum dicta adipisci commodi neque similique. Deleniti similique tempore ratione quibusdam adipisci.',
			},
		],
	},
	{
		health_id: 29,
		vin: '5JFHML6910ER0D5TT',
		urgency: 'LOW',
		time_period: 85,
		measure: '?',
		value: '?',
		health_description: 'Deserunt cum quam. Facilis eos nulla provident.',
		parts: [
			{
				name: 'Battery',
				id: 160,
				description:
					'Quasi occaecati nulla. Fugiat odit sequi repellendus in ratione aspernatur suscipit. Quasi inventore alias occaecati.',
				recommendation:
					'Nemo praesentium amet expedita esse. Cumque vel eum blanditiis commodi. Vitae harum corporis voluptates quibusdam. Dignissimos ex enim inventore dolorum.',
			},
			{
				name: 'Brakes',
				id: 294,
				description:
					'Voluptatum fuga eligendi. Expedita doloribus quas necessitatibus doloribus qui.',
				recommendation:
					'Eveniet hic mollitia veritatis. Corporis ex ab laborum molestias nulla eum.',
			},
		],
	},
	{
		health_id: 30,
		vin: 'MWT4DTR27RX1NKRTE',
		urgency: 'LOW',
		time_period: 93,
		measure: '?',
		value: '?',
		health_description:
			'Architecto molestiae voluptates tempore beatae voluptatibus minima. Nemo cupiditate veniam illum deserunt libero sunt. Quo repellendus dolorum minima perferendis perspiciatis.',
		parts: [
			{
				name: 'Battery',
				id: 219,
				description:
					'Suscipit ullam reiciendis aspernatur ab quod blanditiis. Necessitatibus repellendus dignissimos quibusdam.',
				recommendation:
					'Accusamus beatae occaecati magnam consectetur minima. Magnam iure ipsum ab natus odio.',
			},
			{
				name: 'Brakes',
				id: 293,
				description:
					'Non odit in exercitationem. Magni facere officia repudiandae. Corporis eveniet maxime praesentium veniam quia.',
				recommendation:
					'Ullam quo vel alias nulla quos. Culpa ullam sequi saepe fuga eveniet atque. Sed consequatur similique fuga.',
			},
		],
	},
	{
		health_id: 31,
		vin: 'YEHUY2RM3SAW92S26',
		urgency: 'MEDIUM',
		time_period: 70,
		measure: '?',
		value: '?',
		health_description:
			'Nostrum perspiciatis occaecati rem. Labore illum quas at.',
		parts: [
			{
				name: 'Battery',
				id: 207,
				description:
					'Perferendis omnis pariatur eaque. Magnam suscipit tenetur laborum tempora eveniet.',
				recommendation:
					'Fugit blanditiis cupiditate cum. Corporis autem harum nihil laboriosam rem et reprehenderit. Maxime repellendus aspernatur minus autem deleniti ullam nobis.',
			},
		],
	},
	{
		health_id: 32,
		vin: 'U9WB4GRL4DWLMRHTG',
		urgency: 'LOW',
		time_period: 37,
		measure: '?',
		value: '?',
		health_description:
			'Ab esse architecto atque aliquid. Vero perferendis nisi nemo ab ex labore temporibus. Veritatis magnam illum sapiente non libero hic.',
		parts: [
			{
				name: 'Battery',
				id: 363,
				description:
					'Harum odit at veniam molestias. Eveniet minima assumenda qui quidem ullam.',
				recommendation:
					'Labore eligendi rerum. Voluptatibus quae dolorem dicta quas repellat. Corporis occaecati assumenda temporibus accusamus id.',
			},
			{
				name: 'Brakes',
				id: 327,
				description:
					'Perferendis optio vel fugiat. Illo perspiciatis praesentium totam quia qui dolores. Amet excepturi optio voluptate rem.',
				recommendation:
					'Nobis facere dolore laboriosam. Facilis dignissimos dolorem inventore. Nemo iste enim molestias praesentium.',
			},
		],
	},
	{
		health_id: 33,
		vin: '6X24NE5352FC20F9X',
		urgency: 'CRITICAL',
		time_period: 65,
		measure: '?',
		value: '?',
		health_description:
			'Quisquam amet delectus aut at. Dolor optio commodi id dolor. Dolorem dignissimos quo deserunt.',
		parts: [
			{
				name: 'Battery',
				id: 213,
				description:
					'Labore commodi fuga dolor soluta alias. Deleniti natus neque officia quo cupiditate. Non ipsa illo accusantium necessitatibus doloremque.',
				recommendation:
					'Veritatis aliquid aut deserunt molestiae. Labore consectetur voluptas accusamus. Ab quam sint sint.',
			},
			{
				name: 'Brakes',
				id: 148,
				description:
					'Tempore assumenda architecto ab deleniti sequi eos. Vitae rem recusandae nisi.',
				recommendation:
					'Praesentium enim incidunt nemo odio laudantium. Doloribus aliquam itaque quos. In ab magnam.',
			},
		],
	},
	{
		health_id: 34,
		vin: '8DWGPA8A7K72KRSC5',
		urgency: 'MEDIUM',
		time_period: 51,
		measure: '?',
		value: '?',
		health_description:
			'Voluptas molestias ea minus nobis magnam dicta. Temporibus est eaque excepturi blanditiis consequuntur quisquam.',
		parts: [
			{
				name: 'Battery',
				id: 58,
				description:
					'Nemo odit recusandae numquam omnis. Quibusdam voluptas mollitia beatae dicta id.',
				recommendation:
					'Veniam quasi tempora minima. Officia tempore perferendis reiciendis.',
			},
			{
				name: 'Brakes',
				id: 207,
				description:
					'Laborum suscipit repudiandae odit. Consequuntur rerum perspiciatis possimus. Quasi dolorum mollitia earum eos.',
				recommendation:
					'Dolorem dicta possimus quos. Nam iure sunt dolorem. Ipsam veritatis iure.',
			},
		],
	},
	{
		health_id: 35,
		vin: 'F7K83NUP85ZM1TY7U',
		urgency: 'LOW',
		time_period: 1,
		measure: '?',
		value: '?',
		health_description:
			'Nulla reiciendis reiciendis placeat eos quis. Sequi odit non unde iure ad inventore. Quas vero amet quisquam eius.',
		parts: [
			{
				name: 'Battery',
				id: 10,
				description:
					'Est earum eius fugit accusamus. Veniam explicabo voluptas officia possimus. Beatae est corporis tempore.',
				recommendation:
					'Occaecati hic architecto omnis accusamus reiciendis numquam tempora. Doloribus harum incidunt. Consequatur expedita rem nemo.',
			},
		],
	},
	{
		health_id: 36,
		vin: 'PW0UBHXW8UHLF6HVF',
		urgency: 'CRITICAL',
		time_period: 91,
		measure: '?',
		value: '?',
		health_description:
			'Dicta laborum necessitatibus eaque. Voluptatem consequuntur sint repudiandae. Excepturi iure in explicabo. Voluptatum reiciendis expedita odit vitae.',
		parts: [
			{
				name: 'Battery',
				id: 166,
				description: 'Odio deserunt id.',
				recommendation:
					'Tempora quis non hic alias voluptate. Vitae veritatis minima. Rem nemo assumenda dicta vitae illo veniam.',
			},
		],
	},
	{
		health_id: 37,
		vin: 'WRM2YLBY5DNGMHH1C',
		urgency: 'LOW',
		time_period: 21,
		measure: '?',
		value: '?',
		health_description:
			'Ratione dolores accusamus aut rerum. Placeat quibusdam suscipit necessitatibus sequi inventore officia est. Enim laudantium sint aspernatur nostrum.',
		parts: [
			{
				name: 'Battery',
				id: 87,
				description:
					'Facere qui quis suscipit repellendus reprehenderit ipsam. Tenetur eius natus velit quia. Pariatur assumenda laudantium.',
				recommendation:
					'Illum deserunt ut debitis nisi facere. Voluptatibus animi amet inventore repellat nam ratione asperiores.',
			},
			{
				name: 'Brakes',
				id: 316,
				description:
					'Qui eaque dignissimos quibusdam fugit rem tenetur. Aut labore ducimus nostrum.',
				recommendation: 'Repellendus fugit illum aliquam.',
			},
		],
	},
	{
		health_id: 38,
		vin: '6FTYFXYV926JESFKP',
		urgency: 'LOW',
		time_period: 53,
		measure: '?',
		value: '?',
		health_description:
			'Aut amet sunt quia nulla sit recusandae. Magni rerum hic voluptatum. Earum dolores occaecati.',
		parts: [
			{
				name: 'Battery',
				id: 235,
				description:
					'Nulla aut veniam reprehenderit quaerat ad recusandae. Dolor ipsam corrupti quas veritatis adipisci ullam. Repellendus repellat illo voluptas amet dolor magnam.',
				recommendation:
					'Animi eius dolor. Harum minima quaerat quos. Aliquid amet consectetur optio.',
			},
		],
	},
	{
		health_id: 39,
		vin: 'XNUMNWHK5GE8CL8BC',
		urgency: 'LOW',
		time_period: 16,
		measure: '?',
		value: '?',
		health_description:
			'Aperiam earum nulla iusto repellat. Ut numquam ut itaque reprehenderit atque.',
		parts: [
			{
				name: 'Battery',
				id: 353,
				description:
					'Aspernatur dolorem ad aperiam vel repellendus harum. Consequuntur id doloribus sed.',
				recommendation:
					'Nemo labore voluptatem unde possimus vitae. In tempore consectetur voluptatem. Earum officiis iure eligendi.',
			},
		],
	},
	{
		health_id: 40,
		vin: 'DXHJ7CJE9PBK19H34',
		urgency: 'MEDIUM',
		time_period: 61,
		measure: '?',
		value: '?',
		health_description:
			'Harum illum ratione ab quibusdam ut. Vitae mollitia accusantium error. Vitae nam assumenda alias.',
		parts: [
			{
				name: 'Battery',
				id: 185,
				description:
					'Alias ullam commodi optio maxime autem. Fugiat qui dolores soluta architecto illo at deserunt. Temporibus molestias molestiae quaerat sit iure eos.',
				recommendation:
					'Quo ullam laboriosam ipsam incidunt cumque explicabo nihil. Laboriosam numquam deserunt aspernatur.',
			},
		],
	},
	{
		health_id: 41,
		vin: 'J9Y71JXX6VMB4GX85',
		urgency: 'HIGH',
		time_period: 54,
		measure: '?',
		value: '?',
		health_description:
			'Voluptas quas facilis suscipit dolor est id. Quasi ipsum debitis commodi illo pariatur fugit rerum. Incidunt praesentium nemo laboriosam eius pariatur. Maiores vero fuga eaque neque error.',
		parts: [
			{
				name: 'Battery',
				id: 45,
				description:
					'Cupiditate ullam provident magnam doloremque accusantium delectus numquam. Harum illum minus maiores fugit cum dolores. Beatae perspiciatis minima dolores.',
				recommendation:
					'Cupiditate odit est doloribus et. Voluptatibus maiores laboriosam nemo animi consequatur. Dolor repudiandae iure repellat suscipit est molestias.',
			},
		],
	},
	{
		health_id: 42,
		vin: 'SY5400K6XF8TERVVK',
		urgency: 'LOW',
		time_period: 40,
		measure: '?',
		value: '?',
		health_description:
			'Molestias quo culpa quibusdam aliquid molestias tempore. Sint vitae numquam id maxime.',
		parts: [
			{
				name: 'Battery',
				id: 223,
				description:
					'Dignissimos quae cum eligendi illo. Commodi iste quod cupiditate dolore. Deleniti ab eius unde ab ex aperiam quasi.',
				recommendation:
					'Impedit nostrum eveniet accusantium non deserunt officiis. Doloremque explicabo ipsa.',
			},
		],
	},
	{
		health_id: 43,
		vin: 'UK47DDG66AFB5A1TV',
		urgency: 'CRITICAL',
		time_period: 26,
		measure: '?',
		value: '?',
		health_description:
			'Cumque iure deleniti exercitationem. Neque ipsum vel maiores nulla ipsam excepturi. Sequi sit rem praesentium esse molestiae fugiat neque.',
		parts: [
			{
				name: 'Battery',
				id: 54,
				description:
					'Cumque dicta voluptatibus voluptatum voluptates asperiores fugit consequuntur. Nemo doloremque vel eius ullam.',
				recommendation:
					'Molestias corrupti a non earum. Dolore sit impedit repellendus.',
			},
			{
				name: 'Brakes',
				id: 124,
				description:
					'Quae at molestias aliquam veritatis adipisci vitae. Atque nobis laborum excepturi nisi blanditiis ratione alias. Nesciunt quia quo blanditiis.',
				recommendation:
					'Modi laborum natus. Corrupti doloremque ex eos. Illum magni iure fugit est voluptate porro.',
			},
		],
	},
	{
		health_id: 44,
		vin: 'XJ3EJJW51EGCU8E0H',
		urgency: 'MEDIUM',
		time_period: 80,
		measure: '?',
		value: '?',
		health_description:
			'Odio facilis distinctio debitis fuga. Necessitatibus pariatur totam esse a. Aliquid pariatur officia itaque exercitationem aliquid.',
		parts: [
			{
				name: 'Battery',
				id: 223,
				description:
					'Eius iusto nesciunt voluptates error. Ea laboriosam in error blanditiis tempore commodi. Aliquid maiores odio est.',
				recommendation:
					'Consequuntur alias quae quasi. Placeat inventore dignissimos modi esse nesciunt aperiam.',
			},
		],
	},
	{
		health_id: 45,
		vin: '054AKJV85WKSHZYV6',
		urgency: 'MEDIUM',
		time_period: 92,
		measure: '?',
		value: '?',
		health_description:
			'Officiis amet fugit voluptatibus nisi. Quidem delectus maiores.',
		parts: [
			{
				name: 'Battery',
				id: 301,
				description:
					'Quis consequuntur doloribus autem magnam error. Perferendis suscipit quia eligendi alias reprehenderit.',
				recommendation:
					'Doloremque aliquam nulla debitis suscipit libero. Autem iste reprehenderit expedita esse. Soluta quis eligendi architecto nisi porro ea.',
			},
		],
	},
	{
		health_id: 46,
		vin: '3ULG26E2XH3BXVHBU',
		urgency: 'CRITICAL',
		time_period: 65,
		measure: '?',
		value: '?',
		health_description:
			'Laudantium deleniti dolorem. Eligendi enim non. Voluptatibus veritatis culpa illum. Molestiae dicta nostrum dolorem.',
		parts: [
			{
				name: 'Battery',
				id: 205,
				description:
					'Iste culpa ab autem qui nam ad excepturi. Maiores explicabo omnis blanditiis.',
				recommendation:
					'Aliquam nobis optio perferendis mollitia autem perferendis. Voluptatibus nobis rerum neque sequi. Occaecati quasi maiores aliquam.',
			},
		],
	},
	{
		health_id: 47,
		vin: 'Y6XNTML7XAMTYJS3W',
		urgency: 'LOW',
		time_period: 74,
		measure: '?',
		value: '?',
		health_description:
			'Eum ipsum ducimus eos rerum cum officiis. Sunt cupiditate libero iste adipisci ut necessitatibus. Voluptas dignissimos dolore odio aut.',
		parts: [
			{
				name: 'Battery',
				id: 268,
				description:
					'Totam expedita veritatis dolore minus amet nihil architecto. Eligendi fugiat necessitatibus voluptates.',
				recommendation:
					'Dolores iste quas voluptate iusto. Recusandae dolorem deleniti dignissimos earum. Quo magnam voluptatibus qui.',
			},
			{
				name: 'Brakes',
				id: 40,
				description:
					'Ut ratione ut id. Ad consequuntur voluptatibus ea magnam. Porro corrupti officia ab.',
				recommendation:
					'Ipsa minima est dolore nulla eligendi porro. Vitae sed consequatur dolores commodi modi earum.',
			},
		],
	},
	{
		health_id: 48,
		vin: '6LGL8SZ1XWT4CPYV4',
		urgency: 'HIGH',
		time_period: 64,
		measure: '?',
		value: '?',
		health_description:
			'Unde labore tempore porro voluptatibus nulla. Doloribus soluta minima.',
		parts: [
			{
				name: 'Battery',
				id: 202,
				description:
					'Quam blanditiis ipsum. Amet nam hic fugiat maxime veniam architecto. Quod debitis deserunt officia ex tempora deserunt.',
				recommendation:
					'Ipsa odit in aperiam. Officia perspiciatis voluptas unde id. Officia occaecati unde.',
			},
			{
				name: 'Brakes',
				id: 158,
				description:
					'Tempora eos qui ea voluptates cum quo. Quis iste possimus rerum molestiae enim quia.',
				recommendation:
					'Quae laborum nam enim amet. Possimus iure reiciendis eveniet eveniet debitis.',
			},
		],
	},
	{
		health_id: 49,
		vin: 'DH9JVY825FAK1T6E8',
		urgency: 'LOW',
		time_period: 43,
		measure: '?',
		value: '?',
		health_description:
			'Distinctio incidunt explicabo sed voluptate. Nostrum doloribus quia a. Inventore suscipit debitis aut cumque perspiciatis.',
		parts: [
			{
				name: 'Battery',
				id: 381,
				description:
					'Nostrum nesciunt quasi excepturi iusto. Itaque harum voluptates beatae nam illo animi ducimus.',
				recommendation:
					'Fuga consectetur eius velit. Labore aspernatur hic sed illum a vero.',
			},
			{
				name: 'Brakes',
				id: 173,
				description:
					'Blanditiis aut cum esse. Cumque impedit perferendis fugit perspiciatis. Inventore a totam occaecati animi modi.',
				recommendation:
					'Deleniti perspiciatis perspiciatis harum quis hic adipisci. Vitae possimus molestias magni praesentium occaecati labore blanditiis.',
			},
		],
	},
	{
		health_id: 50,
		vin: 'W80YJZHT8XXFW0RCZ',
		urgency: 'CRITICAL',
		time_period: 17,
		measure: '?',
		value: '?',
		health_description:
			'Ipsum modi distinctio itaque nam ab. Magni inventore aliquam. Corrupti omnis eveniet tempore corporis temporibus soluta.',
		parts: [
			{
				name: 'Battery',
				id: 113,
				description: 'Ex eveniet earum dignissimos libero.',
				recommendation:
					'Laboriosam doloremque blanditiis est dolorum assumenda expedita. Consequuntur beatae possimus dolorum repellendus expedita voluptate modi. Voluptas ad ducimus consequuntur similique tempora accusantium. Voluptas ratione in similique necessitatibus.',
			},
		],
	},
	{
		health_id: 51,
		vin: '95HTMZH10JKY0VXNM',
		urgency: 'MEDIUM',
		time_period: 59,
		measure: '?',
		value: '?',
		health_description:
			'Provident eveniet id error nemo qui. Aspernatur inventore impedit officia molestiae ullam.',
		parts: [
			{
				name: 'Battery',
				id: 170,
				description:
					'Repellat sint magnam. Veritatis repudiandae eveniet laboriosam recusandae deserunt earum.',
				recommendation:
					'Magnam dolorum quia laboriosam quod iure praesentium eos. Nisi repellendus non accusamus ab repellendus. Delectus excepturi reiciendis eius hic voluptatibus.',
			},
			{
				name: 'Brakes',
				id: 398,
				description:
					'Molestias blanditiis eius fugit reiciendis eos. Autem deleniti quod.',
				recommendation: 'Enim esse modi cupiditate.',
			},
		],
	},
	{
		health_id: 52,
		vin: 'RWLCFRET53LXRR0Y3',
		urgency: 'HIGH',
		time_period: 23,
		measure: '?',
		value: '?',
		health_description:
			'Maxime sapiente ratione consequuntur harum. Totam reiciendis quo nisi reiciendis quaerat. Temporibus distinctio reprehenderit saepe nostrum numquam doloribus suscipit.',
		parts: [
			{
				name: 'Battery',
				id: 86,
				description:
					'Similique maxime illo repudiandae sint. Error doloremque accusamus necessitatibus. Odio cumque atque rem cupiditate.',
				recommendation:
					'Commodi dolor labore accusantium. Nostrum animi distinctio culpa eius cumque. Necessitatibus distinctio hic doloremque porro consequuntur eius.',
			},
			{
				name: 'Brakes',
				id: 39,
				description:
					'Velit at quisquam. Vel quod perferendis natus reiciendis. Temporibus veniam aperiam explicabo doloremque.',
				recommendation:
					'Culpa magnam quaerat rerum rem dicta consectetur. Sit dignissimos iusto amet ipsum. Dignissimos eligendi corrupti modi non quam aspernatur corporis.',
			},
		],
	},
	{
		health_id: 53,
		vin: '9ZM7V7W150T05XGMS',
		urgency: 'MEDIUM',
		time_period: 7,
		measure: '?',
		value: '?',
		health_description:
			'Doloribus quidem ab accusantium. Incidunt illum explicabo repellendus. Repellat architecto eius excepturi velit.',
		parts: [
			{
				name: 'Battery',
				id: 261,
				description:
					'Laboriosam quo neque error perspiciatis. Expedita accusantium saepe recusandae corporis.',
				recommendation:
					'Consequatur qui eaque reiciendis illum. Ab vitae ratione cumque ad. Vel eaque provident odit. Maxime consectetur tempora mollitia fugiat asperiores blanditiis.',
			},
			{
				name: 'Brakes',
				id: 324,
				description:
					'Necessitatibus modi illum eveniet commodi. Voluptatibus maxime quia fuga perferendis minima sunt. Soluta aliquid dolor aspernatur velit pariatur tempore quod.',
				recommendation: 'Cum rem cupiditate nemo error cum.',
			},
		],
	},
	{
		health_id: 54,
		vin: '6MRLY35F0JE6U77VA',
		urgency: 'CRITICAL',
		time_period: 44,
		measure: '?',
		value: '?',
		health_description:
			'Nostrum quae architecto reprehenderit placeat esse rerum hic. Eaque suscipit praesentium blanditiis.',
		parts: [
			{
				name: 'Battery',
				id: 58,
				description:
					'Suscipit cumque enim debitis. Cumque possimus alias vel itaque. Animi at delectus molestias rem quisquam doloremque eaque.',
				recommendation:
					'Dolorum provident quod impedit reprehenderit repudiandae consequatur. Minima delectus animi harum corporis eius quod laboriosam.',
			},
			{
				name: 'Brakes',
				id: 271,
				description:
					'Consequatur ipsa deleniti eos incidunt. Aliquam exercitationem tenetur cumque laboriosam voluptatem magni minus.',
				recommendation:
					'Doloremque id dolorum neque ea. Iusto dicta maxime eos aliquid quis inventore facere.',
			},
		],
	},
	{
		health_id: 55,
		vin: 'K23L4JNP4U0FTBAB6',
		urgency: 'LOW',
		time_period: 53,
		measure: '?',
		value: '?',
		health_description:
			'Ipsa dolores asperiores temporibus inventore explicabo. Rem rerum quibusdam aut ratione ad vitae.',
		parts: [
			{
				name: 'Battery',
				id: 261,
				description:
					'Praesentium molestiae doloribus sit quia laboriosam consectetur. Molestias quam voluptates illo. Provident tenetur labore ex.',
				recommendation:
					'Odio molestiae incidunt placeat. Aperiam odio quos doloremque expedita adipisci veniam. Explicabo culpa voluptatum non maxime nam.',
			},
		],
	},
	{
		health_id: 56,
		vin: 'RFY0EWGW3G1WJA5VN',
		urgency: 'HIGH',
		time_period: 71,
		measure: '?',
		value: '?',
		health_description:
			'Deserunt excepturi quidem distinctio voluptate officiis nihil illum. Minima ut autem esse itaque.',
		parts: [
			{
				name: 'Battery',
				id: 99,
				description:
					'Doloremque saepe quod voluptates nobis magni. Asperiores quod officia fugit sapiente aperiam dolorem. Atque fugit dicta beatae eaque mollitia.',
				recommendation:
					'Deserunt fuga tempora saepe. Natus aut laudantium.',
			},
			{
				name: 'Brakes',
				id: 397,
				description:
					'Eos dolore eligendi labore quod. Sit eius sed et quo ad fuga occaecati. Aut dolores a aperiam fuga dolores.',
				recommendation:
					'Voluptate ratione dolores sint corrupti. Consequuntur necessitatibus iusto facilis explicabo expedita accusantium. Veniam unde quo molestias laborum.',
			},
		],
	},
	{
		health_id: 57,
		vin: 'KUGWKCRX47FRNJJ2S',
		urgency: 'MEDIUM',
		time_period: 79,
		measure: '?',
		value: '?',
		health_description:
			'Dolore ex aperiam odit earum. Accusantium repudiandae iure magnam sed deleniti. Ducimus unde omnis dicta.',
		parts: [
			{
				name: 'Battery',
				id: 178,
				description:
					'Quos magnam amet. Aliquam id repellendus veniam voluptatibus debitis. Provident nobis possimus velit consectetur. Nam ab sunt nobis.',
				recommendation:
					'Molestias quasi repudiandae quos magni porro. Eos a ducimus tenetur voluptatum eaque repellat.',
			},
			{
				name: 'Brakes',
				id: 179,
				description:
					'Quo laborum laborum corrupti magni incidunt eos ullam. Deserunt placeat alias blanditiis odit beatae laboriosam. Alias doloremque tempore dicta facere veniam.',
				recommendation:
					'Officiis doloribus dicta illum corrupti inventore iure. Ipsa quasi tempora. Dolor soluta quae officia fuga nemo aliquid veritatis. Esse magni voluptatem id dicta aspernatur nulla.',
			},
		],
	},
	{
		health_id: 58,
		vin: 'H14YJENE6CG973KV3',
		urgency: 'MEDIUM',
		time_period: 34,
		measure: '?',
		value: '?',
		health_description:
			'Quam culpa reprehenderit nesciunt necessitatibus facilis voluptatum.',
		parts: [
			{
				name: 'Battery',
				id: 372,
				description:
					'Esse ducimus sequi autem vero eaque. Asperiores omnis architecto ipsa eum voluptate. Distinctio consequuntur totam quidem repudiandae architecto dignissimos.',
				recommendation:
					'Nesciunt suscipit enim necessitatibus ducimus amet. Adipisci ipsum reprehenderit velit soluta.',
			},
		],
	},
	{
		health_id: 59,
		vin: 'P96JLTBC29PZHAGXJ',
		urgency: 'MEDIUM',
		time_period: 46,
		measure: '?',
		value: '?',
		health_description:
			'Ipsum cupiditate quae atque nam neque. Occaecati facilis similique necessitatibus magni voluptas.',
		parts: [
			{
				name: 'Battery',
				id: 330,
				description:
					'Sunt a esse nobis. Voluptatum sunt quod et non. Illo esse nam dolorum.',
				recommendation:
					'Fugiat labore enim pariatur accusantium porro officiis.',
			},
		],
	},
	{
		health_id: 60,
		vin: 'M8W50ESP56S1G8WPG',
		urgency: 'LOW',
		time_period: 68,
		measure: '?',
		value: '?',
		health_description:
			'Necessitatibus consectetur sequi molestias iure harum nihil. Nostrum temporibus qui totam quo quae nobis soluta.',
		parts: [
			{
				name: 'Battery',
				id: 75,
				description:
					'Sunt optio soluta ipsam eos repellendus. Inventore est assumenda dolores quam nihil. Deleniti itaque voluptates sit numquam nobis.',
				recommendation:
					'Quidem maxime magni quisquam eius illum numquam. Veniam quibusdam ipsam magnam doloremque nemo hic.',
			},
			{
				name: 'Brakes',
				id: 120,
				description:
					'Nam neque commodi nesciunt provident dolorum. Libero soluta voluptatem saepe. Occaecati numquam cupiditate tenetur.',
				recommendation:
					'Accusantium maiores in itaque minus. Magnam distinctio occaecati sunt minus quibusdam quod. Neque possimus debitis numquam voluptatibus.',
			},
		],
	},
	{
		health_id: 61,
		vin: 'RP7EX2LEX5WKT8933',
		urgency: 'HIGH',
		time_period: 78,
		measure: '?',
		value: '?',
		health_description:
			'Veritatis minus odit. Exercitationem quisquam ipsum repellendus laboriosam.',
		parts: [
			{
				name: 'Battery',
				id: 52,
				description: 'Asperiores ducimus in incidunt.',
				recommendation:
					'Nostrum eius omnis veritatis. Velit impedit error voluptatum numquam debitis necessitatibus veritatis. Dolorem cum similique quis temporibus.',
			},
		],
	},
	{
		health_id: 62,
		vin: 'KY87F9RW8PT0T7YTH',
		urgency: 'LOW',
		time_period: 78,
		measure: '?',
		value: '?',
		health_description:
			'Voluptas iusto officia fuga rem quas. Iure sapiente reiciendis nostrum. Vero eum nisi quasi nisi iure.',
		parts: [
			{
				name: 'Battery',
				id: 74,
				description:
					'Tempore dignissimos quas quas maxime eligendi. Beatae molestias debitis alias possimus eligendi accusamus.',
				recommendation:
					'Libero nulla architecto accusamus. Quasi porro possimus animi. Repellat magnam eos accusantium facilis debitis.',
			},
			{
				name: 'Brakes',
				id: 131,
				description:
					'Illum eius ut illo. Debitis magnam magni doloremque eos fugiat unde nemo. Illum a et id labore eum omnis ipsam.',
				recommendation:
					'Qui laborum neque beatae voluptatum. Accusamus laboriosam ipsa voluptates illo autem animi ex. Impedit officia modi.',
			},
		],
	},
	{
		health_id: 63,
		vin: '3PDVVFP72PN4SHC8W',
		urgency: 'LOW',
		time_period: 16,
		measure: '?',
		value: '?',
		health_description:
			'Fugiat tempora ad quod. Adipisci magni quidem minima adipisci labore. Earum nesciunt impedit assumenda repellat facilis soluta.',
		parts: [
			{
				name: 'Battery',
				id: 101,
				description:
					'Porro libero id suscipit suscipit asperiores non. Suscipit officia fuga voluptas laudantium ipsam. Porro assumenda beatae.',
				recommendation:
					'Temporibus similique repellendus placeat. Voluptatum illo aperiam ipsum. Dolore repellendus commodi possimus debitis praesentium vitae magnam.',
			},
		],
	},
	{
		health_id: 64,
		vin: '6D24MF9D05DDEM01H',
		urgency: 'LOW',
		time_period: 71,
		measure: '?',
		value: '?',
		health_description:
			'Odio nihil soluta omnis placeat accusantium fugit nam. Assumenda culpa animi veritatis. Et ipsam debitis minus earum.',
		parts: [
			{
				name: 'Battery',
				id: 88,
				description:
					'Assumenda excepturi laborum necessitatibus eveniet aliquid. Quisquam laboriosam iure voluptas vitae quis. Molestiae debitis dolorum cum adipisci et soluta.',
				recommendation:
					'Provident cum ab odit temporibus dolorum. Ipsum sit labore quaerat.',
			},
		],
	},
	{
		health_id: 65,
		vin: '09GB82911NFL2BG49',
		urgency: 'CRITICAL',
		time_period: 96,
		measure: '?',
		value: '?',
		health_description:
			'Voluptates autem expedita perspiciatis exercitationem odio itaque earum. Amet aspernatur laudantium aperiam cumque. Ullam tempora maxime dicta dolore sint.',
		parts: [
			{
				name: 'Battery',
				id: 308,
				description:
					'Porro soluta enim ad aliquam. Aperiam animi consectetur voluptates.',
				recommendation:
					'Quaerat vitae in porro placeat. Et ipsam perspiciatis quos officiis quia. Deleniti occaecati sint debitis nisi.',
			},
			{
				name: 'Brakes',
				id: 78,
				description:
					'Aperiam optio velit ratione quaerat. Quia veritatis blanditiis qui. Nesciunt ipsam vitae similique hic fuga.',
				recommendation:
					'Alias voluptate eius maiores quisquam delectus sequi.',
			},
		],
	},
	{
		health_id: 66,
		vin: 'T85XVPZ78VZE3KW24',
		urgency: 'CRITICAL',
		time_period: 19,
		measure: '?',
		value: '?',
		health_description:
			'Deserunt vel facilis unde cum distinctio. Laboriosam est odit similique ducimus maiores. Ab iure doloribus natus ex hic eligendi mollitia.',
		parts: [
			{
				name: 'Battery',
				id: 389,
				description:
					'Mollitia qui vitae voluptatum. Dicta aliquam sint numquam vel voluptatem.',
				recommendation:
					'Ut quisquam doloribus perspiciatis officia. In et impedit vitae id dolores. Porro mollitia cum.',
			},
		],
	},
	{
		health_id: 67,
		vin: 'XSU7YV563L059HLRJ',
		urgency: 'CRITICAL',
		time_period: 2,
		measure: '?',
		value: '?',
		health_description:
			'Repellat cum eius magnam quod dolor. Magni numquam dolor doloremque aliquam quae repellat.',
		parts: [
			{
				name: 'Battery',
				id: 388,
				description:
					'Dolor dignissimos similique ipsum nostrum. Et commodi perspiciatis quod est id vitae. Praesentium hic totam.',
				recommendation:
					'Eaque vel cupiditate. Amet pariatur quibusdam expedita inventore id debitis.',
			},
			{
				name: 'Brakes',
				id: 335,
				description:
					'Nemo voluptate dicta officia autem dignissimos aperiam. Perspiciatis necessitatibus numquam hic cum nemo nisi sapiente.',
				recommendation:
					'Quisquam illum nemo officia tempore quod. Inventore quas magnam id ad molestias quam.',
			},
		],
	},
	{
		health_id: 68,
		vin: 'WZU78DUV3ETMWST7L',
		urgency: 'HIGH',
		time_period: 10,
		measure: '?',
		value: '?',
		health_description:
			'Quia architecto quam possimus rem saepe beatae. Distinctio odio perspiciatis repellendus.',
		parts: [
			{
				name: 'Battery',
				id: 38,
				description:
					'Suscipit odit assumenda dolor nulla nesciunt. Cupiditate libero voluptatem voluptatibus suscipit rerum architecto.',
				recommendation:
					'Aperiam id architecto facilis placeat. Dolores impedit repellendus nobis perspiciatis inventore. Deleniti harum alias repellat beatae incidunt quo.',
			},
		],
	},
	{
		health_id: 69,
		vin: 'ULGP0X56066U7P94M',
		urgency: 'LOW',
		time_period: 50,
		measure: '?',
		value: '?',
		health_description:
			'Dolor nisi nobis ad minima quam omnis. Alias nemo velit dolor cum quasi sed. Deserunt debitis suscipit quis veritatis voluptatum.',
		parts: [
			{
				name: 'Battery',
				id: 90,
				description:
					'Ea at soluta repellendus. Facilis odit corporis commodi officia culpa ea.',
				recommendation:
					'Aliquam neque cumque saepe ad. Deleniti doloribus eveniet animi odio laboriosam.',
			},
			{
				name: 'Brakes',
				id: 395,
				description:
					'Earum cumque perspiciatis aspernatur asperiores voluptatibus. Sequi itaque blanditiis cum recusandae ipsum minima neque.',
				recommendation:
					'Similique quisquam voluptates error voluptatibus iste. Est repudiandae alias minus deserunt.',
			},
		],
	},
	{
		health_id: 70,
		vin: '8TNU4JKN67ZD0XE7N',
		urgency: 'LOW',
		time_period: 81,
		measure: '?',
		value: '?',
		health_description:
			'Incidunt minus pariatur sint optio unde. Nemo tempora nam sequi animi. Ducimus eius blanditiis corporis magnam laboriosam.',
		parts: [
			{
				name: 'Battery',
				id: 321,
				description:
					'Omnis occaecati distinctio enim quidem expedita. Quibusdam tempora fuga libero. A quisquam sequi iure eius atque.',
				recommendation:
					'Dolorem eius velit soluta. Vero sint exercitationem repudiandae blanditiis ipsum reprehenderit optio.',
			},
		],
	},
	{
		health_id: 71,
		vin: 'P8HEU2H22BHKMN1B0',
		urgency: 'CRITICAL',
		time_period: 12,
		measure: '?',
		value: '?',
		health_description:
			'Eos assumenda consectetur maiores iure illo. Ratione debitis ut ea quam corrupti vitae.',
		parts: [
			{
				name: 'Battery',
				id: 252,
				description:
					'Enim error nihil laboriosam quos maiores. Beatae non nisi tenetur excepturi vitae.',
				recommendation:
					'Quasi enim maiores asperiores accusamus illum excepturi. Delectus saepe temporibus asperiores hic quisquam. Provident perspiciatis sit numquam hic asperiores labore possimus.',
			},
		],
	},
	{
		health_id: 72,
		vin: 'S5026DRT9WMAPKL64',
		urgency: 'LOW',
		time_period: 70,
		measure: '?',
		value: '?',
		health_description:
			'Eos blanditiis suscipit fuga. Aliquid facilis accusamus temporibus nulla maxime at. Magnam accusamus consequuntur sint doloribus voluptatibus.',
		parts: [
			{
				name: 'Battery',
				id: 237,
				description:
					'Doloremque quia commodi at. Magni sunt cum possimus quo suscipit. Incidunt perspiciatis aliquid ullam magni quidem.',
				recommendation:
					'Occaecati officia sint tenetur atque adipisci culpa temporibus. A illo magnam eius rem laboriosam commodi unde.',
			},
		],
	},
	{
		health_id: 73,
		vin: '0A46HVUY5VTHDZ8NU',
		urgency: 'LOW',
		time_period: 22,
		measure: '?',
		value: '?',
		health_description:
			'Iste similique ipsum. Amet rerum consectetur explicabo.',
		parts: [
			{
				name: 'Battery',
				id: 386,
				description:
					'Magni repellendus sit voluptatum doloribus. Laboriosam molestiae modi architecto autem dolor reprehenderit. Sapiente omnis occaecati perferendis asperiores unde cum.',
				recommendation:
					'Fugit eligendi magnam numquam. Placeat voluptatibus adipisci repudiandae.',
			},
		],
	},
	{
		health_id: 74,
		vin: '8JYK3ES491DKD9GJL',
		urgency: 'MEDIUM',
		time_period: 32,
		measure: '?',
		value: '?',
		health_description:
			'Vel odit eius ducimus aliquam dicta. Saepe quia maiores cum enim.',
		parts: [
			{
				name: 'Battery',
				id: 288,
				description:
					'Eos nostrum tempore molestias. Reprehenderit consequuntur nihil reprehenderit animi non facere. Consequuntur odit dignissimos.',
				recommendation:
					'Blanditiis necessitatibus dicta explicabo similique. Necessitatibus quidem deleniti error nesciunt autem facere. Eum voluptatum quae accusamus earum esse ex.',
			},
		],
	},
	{
		health_id: 75,
		vin: '81YUB9RE8ZN5S3U2W',
		urgency: 'LOW',
		time_period: 84,
		measure: '?',
		value: '?',
		health_description:
			'Debitis ut tenetur. Vitae veritatis quis inventore autem similique. Quis rerum minima ut enim nulla sit.',
		parts: [
			{
				name: 'Battery',
				id: 389,
				description:
					'Hic quos veniam aperiam illum itaque rerum. Distinctio harum facilis exercitationem totam.',
				recommendation:
					'Consequuntur atque iure hic. Dolorum suscipit quos.',
			},
			{
				name: 'Brakes',
				id: 296,
				description:
					'Numquam porro expedita ad rem alias iure. Soluta inventore neque assumenda. Aut ipsa magni quod at.',
				recommendation:
					'Ipsa dicta eligendi reprehenderit repellendus repellat blanditiis. Ipsam laborum facilis asperiores est.',
			},
		],
	},
	{
		health_id: 76,
		vin: '5LUSTJ8S82LAWP6U7',
		urgency: 'MEDIUM',
		time_period: 1,
		measure: '?',
		value: '?',
		health_description:
			'Itaque minima eos. Porro eos eos atque blanditiis quibusdam ea. Dolorum sapiente sit officiis neque sequi.',
		parts: [
			{
				name: 'Battery',
				id: 229,
				description: 'Optio aspernatur rerum. Autem hic magnam minus.',
				recommendation:
					'Vitae pariatur molestias ullam necessitatibus ratione excepturi. Suscipit rem atque consequatur quas quaerat eligendi eius.',
			},
		],
	},
	{
		health_id: 77,
		vin: 'FUS6SKME0MA5Z8ZCN',
		urgency: 'LOW',
		time_period: 54,
		measure: '?',
		value: '?',
		health_description:
			'Impedit esse fugit ea nulla animi accusantium pariatur.',
		parts: [
			{
				name: 'Battery',
				id: 348,
				description:
					'Laudantium quam praesentium distinctio illo iste odit. Sed eligendi quaerat nisi nisi. Optio harum illo aperiam ipsum porro.',
				recommendation:
					'Iste iste ipsam blanditiis. Deleniti iure magni fugit.',
			},
			{
				name: 'Brakes',
				id: 289,
				description:
					'Harum modi nam reprehenderit. Nisi natus reiciendis placeat maiores.',
				recommendation:
					'Fugit iste alias ipsum assumenda repudiandae. Quidem magnam architecto doloribus. Eligendi repellendus eum necessitatibus.',
			},
		],
	},
	{
		health_id: 78,
		vin: 'N0F70GCB605BYWVLL',
		urgency: 'HIGH',
		time_period: 58,
		measure: '?',
		value: '?',
		health_description:
			'Laudantium facilis suscipit. Omnis dolor minus consequuntur quos voluptate. Aperiam esse neque minima repudiandae ad a.',
		parts: [
			{
				name: 'Battery',
				id: 329,
				description: 'Quam dolores est quidem.',
				recommendation:
					'Minima necessitatibus repellat nesciunt laudantium itaque. Labore necessitatibus iste sint quaerat ea.',
			},
			{
				name: 'Brakes',
				id: 325,
				description:
					'Corporis sed aliquam nemo. Animi alias autem beatae quas deserunt sunt. Temporibus eligendi suscipit nam voluptatum hic harum.',
				recommendation:
					'Quae qui sunt dignissimos. Laudantium ut placeat repellat delectus non.',
			},
		],
	},
	{
		health_id: 79,
		vin: '0V5XVKH46NZ5KWV34',
		urgency: 'CRITICAL',
		time_period: 70,
		measure: '?',
		value: '?',
		health_description:
			'Beatae alias nobis debitis quas alias perspiciatis.',
		parts: [
			{
				name: 'Battery',
				id: 319,
				description:
					'Accusamus optio error provident quasi. Non aut culpa facilis corrupti nobis dolores. Dolore recusandae sed unde.',
				recommendation:
					'Quasi a officia molestiae. Tempore quisquam impedit corporis.',
			},
			{
				name: 'Brakes',
				id: 165,
				description:
					'Inventore voluptatibus asperiores eum placeat id.',
				recommendation:
					'Voluptatibus voluptatum ipsam facilis excepturi.',
			},
		],
	},
	{
		health_id: 80,
		vin: 'PJ56ZRW39VCLXZ04V',
		urgency: 'MEDIUM',
		time_period: 83,
		measure: '?',
		value: '?',
		health_description:
			'Sapiente labore accusamus beatae tempora. Sequi maiores deleniti laborum.',
		parts: [
			{
				name: 'Battery',
				id: 321,
				description:
					'Amet praesentium consequatur fugiat eligendi laboriosam. Doloribus nostrum natus laboriosam in aspernatur error. Culpa eum beatae tenetur provident maiores.',
				recommendation:
					'Earum molestias est exercitationem. Nisi veritatis perferendis tempora.',
			},
		],
	},
	{
		health_id: 81,
		vin: '7M6ZB3FV6VAPPJRTM',
		urgency: 'CRITICAL',
		time_period: 18,
		measure: '?',
		value: '?',
		health_description:
			'Sed incidunt veritatis praesentium architecto soluta. Reiciendis excepturi excepturi mollitia aspernatur quia impedit amet.',
		parts: [
			{
				name: 'Battery',
				id: 161,
				description:
					'Beatae asperiores doloremque expedita quam inventore magnam. Corrupti quod facere dolore dicta itaque nihil. Expedita pariatur nihil aliquid magnam.',
				recommendation:
					'Rem voluptate iure et omnis optio doloribus. Reiciendis nam debitis dolor fugit fugiat odit. Odit magni impedit. Vel similique nemo dolor natus officiis.',
			},
			{
				name: 'Brakes',
				id: 77,
				description:
					'Eius consequuntur asperiores eos sit modi. Iure consequatur voluptas maiores. Quasi quae consequatur magnam nostrum.',
				recommendation:
					'Placeat repellendus necessitatibus harum sint. Iure laborum magnam praesentium.',
			},
		],
	},
	{
		health_id: 82,
		vin: '4M5M7RJG50M60NKS9',
		urgency: 'MEDIUM',
		time_period: 6,
		measure: '?',
		value: '?',
		health_description:
			'Suscipit ad molestias voluptatibus dignissimos. Saepe fugiat corrupti perspiciatis voluptatem harum. Explicabo dolor molestiae ipsam nisi qui ex enim.',
		parts: [
			{
				name: 'Battery',
				id: 225,
				description:
					'Quasi veniam consequatur animi incidunt. Dicta reiciendis illo illo delectus.',
				recommendation:
					'Nobis eligendi saepe veritatis exercitationem rem. Doloremque doloribus voluptatibus quas neque natus. Ratione doloribus unde repellendus molestias vero.',
			},
		],
	},
	{
		health_id: 83,
		vin: '6KRM96UZ9P2PKBDMY',
		urgency: 'MEDIUM',
		time_period: 45,
		measure: '?',
		value: '?',
		health_description:
			'Nesciunt totam harum vel a corporis molestiae. Possimus nihil possimus veniam accusantium consectetur. Rem aperiam asperiores consectetur excepturi.',
		parts: [
			{
				name: 'Battery',
				id: 34,
				description:
					'Nisi labore velit molestias corrupti ad. Est omnis non dicta porro.',
				recommendation:
					'Soluta perspiciatis debitis id. Ipsa iste modi expedita nobis aspernatur blanditiis. Sapiente iusto laboriosam consequatur quis voluptatem recusandae occaecati.',
			},
			{
				name: 'Brakes',
				id: 240,
				description:
					'Similique saepe eaque ullam voluptates dolorum. Voluptatem magnam fugit assumenda quaerat minus voluptatum.',
				recommendation:
					'Nostrum dolorem doloremque commodi rem reprehenderit. Minus error ipsum hic error. Perspiciatis adipisci consequuntur tempora aliquam eveniet. Blanditiis in accusamus amet recusandae.',
			},
		],
	},
	{
		health_id: 84,
		vin: 'CE905J7ZX3M9R0ABA',
		urgency: 'MEDIUM',
		time_period: 97,
		measure: '?',
		value: '?',
		health_description:
			'Ducimus pariatur tempore. Facere beatae a facilis hic laborum quia. Praesentium laboriosam ea recusandae.',
		parts: [
			{
				name: 'Battery',
				id: 226,
				description:
					'Fuga natus enim ipsum hic placeat. A aut earum nisi tempore similique deleniti. Ipsum exercitationem placeat voluptas magnam laboriosam tempore necessitatibus.',
				recommendation:
					'Ad sint rem perferendis. Quasi nulla nesciunt tenetur eum quasi enim. Amet quo esse amet.',
			},
			{
				name: 'Brakes',
				id: 323,
				description:
					'Mollitia quas adipisci. Maiores beatae nam provident.',
				recommendation:
					'Corporis unde nesciunt incidunt. Veniam possimus similique sit natus quo.',
			},
		],
	},
	{
		health_id: 85,
		vin: 'V3TPFVJV6EFKXCPVW',
		urgency: 'HIGH',
		time_period: 23,
		measure: '?',
		value: '?',
		health_description:
			'Iure placeat minus illo dolor quam. Cumque nesciunt in officia laudantium delectus. Vero ducimus perspiciatis magni ipsam.',
		parts: [
			{
				name: 'Battery',
				id: 155,
				description:
					'Rem numquam voluptatum enim maiores. Enim repudiandae beatae impedit dolores ratione eligendi quisquam.',
				recommendation:
					'Eaque labore optio adipisci. Sapiente provident veniam doloribus. Vitae sed ut nesciunt.',
			},
			{
				name: 'Brakes',
				id: 140,
				description:
					'Quod eos reiciendis architecto error aut. Porro reiciendis voluptas possimus.',
				recommendation:
					'Veritatis perferendis amet. Occaecati eaque harum dolorum aut sed.',
			},
		],
	},
	{
		health_id: 86,
		vin: 'JRZTAC9G44XP78J6A',
		urgency: 'LOW',
		time_period: 23,
		measure: '?',
		value: '?',
		health_description:
			'Debitis eos ex sed aliquid. Perferendis non reprehenderit quae quam.',
		parts: [
			{
				name: 'Battery',
				id: 302,
				description:
					'Exercitationem adipisci nostrum enim provident. Quo sunt iusto cum vel fugit.',
				recommendation:
					'Porro temporibus enim ducimus. Iste esse animi ea.',
			},
			{
				name: 'Brakes',
				id: 28,
				description:
					'Explicabo quasi voluptates assumenda enim. Atque amet rerum. Tenetur distinctio tempore aperiam officia neque fuga.',
				recommendation:
					'Perspiciatis officiis possimus dolores. Eveniet consequatur corrupti veniam deserunt nobis. Accusamus delectus repudiandae occaecati consectetur pariatur corrupti.',
			},
		],
	},
	{
		health_id: 87,
		vin: 'HVZ0U6EV3N2T4SZPF',
		urgency: 'MEDIUM',
		time_period: 26,
		measure: '?',
		value: '?',
		health_description:
			'Eligendi officiis exercitationem amet rerum nulla. Ut maxime deserunt ipsa soluta voluptatibus. Quod recusandae voluptates iusto at nobis.',
		parts: [
			{
				name: 'Battery',
				id: 180,
				description:
					'Consequuntur officiis quos rem eligendi molestias. Omnis culpa corporis dolor quibusdam. Ea praesentium fugit est vel error eligendi quae.',
				recommendation:
					'Repellendus quas animi repudiandae. Architecto molestiae ducimus explicabo facere nesciunt culpa.',
			},
			{
				name: 'Brakes',
				id: 259,
				description:
					'Quod similique provident sapiente explicabo. Rerum quaerat neque nesciunt. Quidem asperiores ea id.',
				recommendation:
					'Porro porro in architecto similique. Veniam atque animi numquam. Molestiae ut illo atque magni expedita dolorum.',
			},
		],
	},
	{
		health_id: 88,
		vin: 'R6PE2L7M2093JX9XV',
		urgency: 'HIGH',
		time_period: 77,
		measure: '?',
		value: '?',
		health_description:
			'Temporibus cumque odio nisi laboriosam. Voluptatum ex maxime fugit aut tempore.',
		parts: [
			{
				name: 'Battery',
				id: 37,
				description:
					'Recusandae error dolorem rerum magni aspernatur. Mollitia molestiae suscipit neque.',
				recommendation: 'Reiciendis porro molestiae.',
			},
		],
	},
	{
		health_id: 89,
		vin: '162GD0U80JFK84FTW',
		urgency: 'MEDIUM',
		time_period: 7,
		measure: '?',
		value: '?',
		health_description:
			'Est at vero suscipit vero. Vitae quibusdam nobis. Quod adipisci ad quia possimus pariatur consequatur veritatis.',
		parts: [
			{
				name: 'Battery',
				id: 158,
				description:
					'Debitis qui explicabo facere enim. Nostrum eius accusamus illo quia corporis. Odit eos facilis ipsa.',
				recommendation:
					'Corrupti voluptates explicabo. Error eius error ullam dolore.',
			},
			{
				name: 'Brakes',
				id: 236,
				description:
					'Nobis officia laborum omnis numquam reprehenderit. Ullam quisquam maiores earum.',
				recommendation:
					'Sint officiis repudiandae ipsum alias fugit dolores. Dolore hic tenetur aut totam.',
			},
		],
	},
	{
		health_id: 90,
		vin: '58Y2ZF0J3U1NFTB6P',
		urgency: 'LOW',
		time_period: 79,
		measure: '?',
		value: '?',
		health_description:
			'Sit expedita adipisci expedita ut odio. Ea laboriosam ea consequatur. Aliquid optio incidunt hic.',
		parts: [
			{
				name: 'Battery',
				id: 231,
				description:
					'Esse dignissimos nobis laboriosam ad consequuntur expedita. Vero sunt quibusdam suscipit assumenda similique excepturi.',
				recommendation:
					'Quis fuga quam quo facere id. Sint voluptas aliquam nobis sed. Reprehenderit alias sint corporis alias accusantium dicta.',
			},
			{
				name: 'Brakes',
				id: 19,
				description:
					'Quos culpa inventore. Eligendi nulla fugiat aliquam ad.',
				recommendation:
					'Aut veniam odit cumque quis quod. Commodi eum mollitia beatae blanditiis. Quisquam necessitatibus aliquam dicta totam.',
			},
		],
	},
	{
		health_id: 91,
		vin: '5ZZ2RX3RXPG2N5NF7',
		urgency: 'MEDIUM',
		time_period: 44,
		measure: '?',
		value: '?',
		health_description:
			'Ipsa id ullam iusto quo. Inventore nostrum consequatur. Corrupti soluta delectus sint.',
		parts: [
			{
				name: 'Battery',
				id: 29,
				description:
					'Ipsam quod laboriosam illum eaque. Numquam occaecati at ab nesciunt.',
				recommendation:
					'Perferendis voluptatum soluta laborum aliquid. Eligendi ab architecto neque nihil commodi.',
			},
		],
	},
	{
		health_id: 92,
		vin: 'M93NMBWX8B95W3JC3',
		urgency: 'HIGH',
		time_period: 27,
		measure: '?',
		value: '?',
		health_description:
			'Fuga commodi doloremque nam commodi quae natus. Nemo officiis laboriosam ducimus beatae aperiam illum. Velit accusantium quibusdam vero.',
		parts: [
			{
				name: 'Battery',
				id: 188,
				description:
					'Animi fugit asperiores perspiciatis ad. Quidem omnis dolor facilis debitis. Nesciunt eaque voluptatum temporibus porro sint.',
				recommendation: 'Doloribus quo aut nesciunt suscipit.',
			},
		],
	},
	{
		health_id: 93,
		vin: 'C3LKZSXG03CJN4MH8',
		urgency: 'LOW',
		time_period: 26,
		measure: '?',
		value: '?',
		health_description: 'Eveniet perspiciatis ea sit numquam.',
		parts: [
			{
				name: 'Battery',
				id: 146,
				description: 'Consectetur quidem aut aliquid dignissimos.',
				recommendation:
					'Nam at quos provident vel harum et. Dicta neque corrupti distinctio architecto delectus. Tempore suscipit nihil delectus laboriosam.',
			},
		],
	},
	{
		health_id: 94,
		vin: 'PUSVRMBY5YPH6U6UB',
		urgency: 'HIGH',
		time_period: 97,
		measure: '?',
		value: '?',
		health_description:
			'Cumque ut blanditiis tempore officiis itaque quos ipsum. Dignissimos dolore dolor quaerat atque laboriosam sed.',
		parts: [
			{
				name: 'Battery',
				id: 39,
				description:
					'Nesciunt quibusdam molestias eligendi quibusdam odio. Ducimus ipsa quidem soluta quia veniam repudiandae quo. Veniam vel ad libero sed doloribus.',
				recommendation:
					'Laudantium tempora accusamus modi a. Nisi repellendus distinctio quas.',
			},
			{
				name: 'Brakes',
				id: 330,
				description:
					'Quisquam nam et recusandae repellendus officia soluta. Fuga doloribus error quod.',
				recommendation:
					'Provident incidunt provident ad. Itaque deserunt pariatur hic.',
			},
		],
	},
	{
		health_id: 95,
		vin: 'JL76LJKV9UAVNV3NU',
		urgency: 'LOW',
		time_period: 79,
		measure: '?',
		value: '?',
		health_description:
			'Consequatur quam dolorum quasi necessitatibus doloribus quod. Quidem molestias laborum labore libero quis delectus.',
		parts: [
			{
				name: 'Battery',
				id: 46,
				description:
					'Suscipit possimus molestias. Molestias rerum accusantium possimus. Cupiditate neque ullam illum temporibus eius.',
				recommendation:
					'Ullam similique eligendi explicabo nobis officiis. Porro excepturi voluptas voluptatum perspiciatis distinctio.',
			},
			{
				name: 'Brakes',
				id: 314,
				description:
					'Dolorum assumenda praesentium dolorum dolores. Cupiditate totam vero fugit adipisci non quam.',
				recommendation:
					'Quos consequatur sed vitae voluptas. Optio tempora et expedita. Ipsa explicabo voluptas.',
			},
		],
	},
	{
		health_id: 96,
		vin: 'H45SPZA210V395FPP',
		urgency: 'LOW',
		time_period: 36,
		measure: '?',
		value: '?',
		health_description:
			'Deserunt nihil consequuntur repudiandae perferendis perspiciatis excepturi. Maxime quia vel minima iste ratione similique voluptates.',
		parts: [
			{
				name: 'Battery',
				id: 304,
				description: 'Quaerat minima aliquam omnis.',
				recommendation:
					'Est qui eum eligendi nobis. Quae hic alias hic cumque labore animi.',
			},
			{
				name: 'Brakes',
				id: 259,
				description:
					'Pariatur asperiores eos corrupti eius voluptatum ex. Enim saepe facilis perferendis corrupti a.',
				recommendation:
					'Accusantium optio aliquid explicabo numquam eius.',
			},
		],
	},
	{
		health_id: 97,
		vin: 'R30VPPV549PSLHF82',
		urgency: 'CRITICAL',
		time_period: 55,
		measure: '?',
		value: '?',
		health_description:
			'Sint expedita facere suscipit expedita. Ut commodi consequuntur officia quos eligendi.',
		parts: [
			{
				name: 'Battery',
				id: 196,
				description:
					'Delectus at molestiae non deserunt consequatur. Sint mollitia nisi distinctio hic. Aperiam quisquam asperiores adipisci nulla perspiciatis.',
				recommendation:
					'Nostrum molestias explicabo. Nesciunt facere iusto quasi illo soluta voluptatibus voluptatum. Suscipit iusto autem possimus quo laboriosam.',
			},
			{
				name: 'Brakes',
				id: 236,
				description:
					'Eaque ut quo provident porro optio soluta placeat. Eius minima eveniet sequi alias dicta. Ea corrupti excepturi sed mollitia.',
				recommendation:
					'Magni commodi voluptatum expedita quod minus. Amet aliquid repellat nulla nulla tempora. Impedit ullam consequuntur modi odit quibusdam libero.',
			},
		],
	},
	{
		health_id: 98,
		vin: 'BYR8GGVD7JYC895VB',
		urgency: 'CRITICAL',
		time_period: 64,
		measure: '?',
		value: '?',
		health_description: 'Laborum maiores maxime quibusdam quis earum.',
		parts: [
			{
				name: 'Battery',
				id: 297,
				description:
					'Inventore error maxime alias tenetur saepe minima. Autem adipisci rem a quos occaecati iusto. Modi laudantium alias accusantium.',
				recommendation:
					'Quam inventore dolore itaque nisi. Exercitationem molestias voluptatum neque tempore. Ea iure excepturi magni.',
			},
			{
				name: 'Brakes',
				id: 283,
				description:
					'Quod autem nihil consequatur magni. Quisquam pariatur voluptates qui. Veniam cupiditate laboriosam. Quasi nisi ea impedit qui.',
				recommendation:
					'Sapiente minima iure sint dolorem dicta non. Laudantium impedit tempore vel voluptas cupiditate deserunt. Nemo totam alias odit iure.',
			},
		],
	},
	{
		health_id: 99,
		vin: 'V6LN5JKA2BPZE8C9F',
		urgency: 'CRITICAL',
		time_period: 91,
		measure: '?',
		value: '?',
		health_description:
			'Possimus amet beatae quisquam asperiores beatae. Magnam nam delectus magnam cupiditate nesciunt.',
		parts: [
			{
				name: 'Battery',
				id: 378,
				description:
					'Quas facilis quam. Consequatur in incidunt iusto. Alias cupiditate minima itaque suscipit cum quasi.',
				recommendation:
					'Ad similique ratione nesciunt commodi natus. Sunt veritatis in excepturi alias voluptas natus aperiam.',
			},
			{
				name: 'Brakes',
				id: 21,
				description:
					'Sunt similique dignissimos ut aut optio dignissimos. Deserunt explicabo provident voluptatem. Repudiandae voluptatum ratione fugit.',
				recommendation:
					'Corrupti culpa maxime sunt ullam provident. Sapiente expedita ratione repellendus eaque illo.',
			},
		],
	},
	{
		health_id: 100,
		vin: 'Y2PPW9658KSXTT59L',
		urgency: 'LOW',
		time_period: 57,
		measure: '?',
		value: '?',
		health_description:
			'Facere earum doloribus eos. Pariatur ad laudantium quaerat quis similique.',
		parts: [
			{
				name: 'Battery',
				id: 230,
				description:
					'Saepe blanditiis corporis excepturi iure. Nemo laudantium culpa quis vel enim.',
				recommendation:
					'Voluptatum itaque officia deleniti architecto. Aspernatur molestiae odit inventore est. Nesciunt laudantium expedita optio numquam architecto iusto fuga. Doloremque incidunt veniam velit.',
			},
		],
	},
	{
		health_id: 101,
		vin: '5VWN9B9UXZ480KAAL',
		urgency: 'MEDIUM',
		time_period: 69,
		measure: '?',
		value: '?',
		health_description:
			'Magni ducimus nesciunt. Nesciunt dicta doloremque dolores fugiat in aliquam. Quod corrupti vel in voluptatibus vitae.',
		parts: [
			{
				name: 'Battery',
				id: 292,
				description:
					'Ut repellendus inventore assumenda inventore aliquid dolorum. Dicta nesciunt temporibus sed fugit.',
				recommendation:
					'Facere quibusdam perferendis dolorem. Quos eveniet aliquid deleniti minus. Vero iure fugiat voluptatibus nisi.',
			},
		],
	},
	{
		health_id: 102,
		vin: 'ENJ6GCH94K6ZA0AM3',
		urgency: 'HIGH',
		time_period: 61,
		measure: '?',
		value: '?',
		health_description:
			'Tenetur cum cum similique fuga. Explicabo recusandae aperiam voluptatem.',
		parts: [
			{
				name: 'Battery',
				id: 333,
				description:
					'Aperiam esse sed incidunt expedita consequuntur illo. Dolorem accusamus minima occaecati nihil officiis recusandae.',
				recommendation:
					'Voluptatibus laborum aliquam culpa porro cupiditate quis. Sed provident optio animi vitae dolore occaecati.',
			},
			{
				name: 'Brakes',
				id: 96,
				description:
					'Eaque eligendi ipsa magnam. Distinctio atque id libero deleniti.',
				recommendation:
					'Tempore iste recusandae maiores totam illum. Provident harum autem totam quaerat veritatis quam. Accusamus officiis doloremque quo.',
			},
		],
	},
	{
		health_id: 103,
		vin: '263GDT2Y2JKXFSKW5',
		urgency: 'HIGH',
		time_period: 2,
		measure: '?',
		value: '?',
		health_description:
			'Incidunt quis vitae tempore recusandae. Doloremque repudiandae modi est accusantium minima. In blanditiis modi harum possimus reiciendis. Quos optio inventore necessitatibus dicta cum.',
		parts: [
			{
				name: 'Battery',
				id: 164,
				description:
					'Laborum labore voluptatum cum perspiciatis sint. Dolore dolor cumque nemo.',
				recommendation:
					'Excepturi quasi minus nam natus asperiores explicabo.',
			},
			{
				name: 'Brakes',
				id: 309,
				description:
					'Eligendi eaque harum nobis aut numquam aut. Cupiditate asperiores nostrum modi quam voluptates nisi corporis. Aliquid a numquam minus similique odit.',
				recommendation: 'Nemo ipsa aut rem quis cum ipsum.',
			},
		],
	},
	{
		health_id: 104,
		vin: '4AVTXWDM80LLNS9PZ',
		urgency: 'HIGH',
		time_period: 55,
		measure: '?',
		value: '?',
		health_description:
			'Facilis quaerat soluta commodi culpa cum laborum. Blanditiis aut consequuntur.',
		parts: [
			{
				name: 'Battery',
				id: 243,
				description: 'Qui illo inventore.',
				recommendation:
					'Dolor error debitis excepturi quaerat omnis. Ad repellat sit rem blanditiis ipsa. Debitis totam optio soluta harum maiores dolorem.',
			},
			{
				name: 'Brakes',
				id: 256,
				description:
					'Sunt unde in esse sit at impedit. Rem quaerat harum. Laboriosam dolores vero expedita fuga totam. Voluptas facere cumque architecto.',
				recommendation:
					'Blanditiis quam tenetur quos occaecati soluta nemo ad. Animi eaque enim.',
			},
		],
	},
	{
		health_id: 105,
		vin: '7LFW8HVF7074JRTFA',
		urgency: 'CRITICAL',
		time_period: 97,
		measure: '?',
		value: '?',
		health_description:
			'Ipsam laborum quaerat rem nisi temporibus. Quis aliquam rerum non culpa omnis.',
		parts: [
			{
				name: 'Battery',
				id: 77,
				description:
					'Beatae unde in natus exercitationem mollitia dolor. Unde adipisci quos et incidunt magni officia.',
				recommendation:
					'Distinctio quasi sequi ratione. Nobis accusantium dicta ad repellat nesciunt numquam. Culpa adipisci animi earum perferendis quod reprehenderit. Culpa corrupti nihil incidunt nesciunt.',
			},
			{
				name: 'Brakes',
				id: 31,
				description:
					'Suscipit molestiae magni quibusdam odit. Accusantium consequatur id dicta voluptate doloribus. Eaque maxime repellendus rem illo sed.',
				recommendation:
					'Quos et ullam est eveniet. Exercitationem nisi culpa porro temporibus veniam.',
			},
		],
	},
	{
		health_id: 106,
		vin: 'LLCAYZWY9KY26SZWF',
		urgency: 'LOW',
		time_period: 80,
		measure: '?',
		value: '?',
		health_description:
			'Inventore quibusdam saepe officia ullam dolore accusamus. Quam blanditiis minus voluptas minus.',
		parts: [
			{
				name: 'Battery',
				id: 230,
				description:
					'Dicta perferendis tempora illo odio. Fuga consequuntur adipisci dolorem atque maiores exercitationem. Nesciunt hic aliquid veniam optio.',
				recommendation:
					'Quas cum repellat nemo temporibus necessitatibus. Similique quia incidunt.',
			},
			{
				name: 'Brakes',
				id: 52,
				description:
					'Quod fuga laborum corporis aliquam labore saepe. Neque officiis totam vero repellendus culpa quidem. Tenetur eligendi impedit itaque fugiat fugit.',
				recommendation:
					'Aut debitis rem molestias ex maxime perferendis vero. Dolore accusantium cumque minima placeat. Possimus dignissimos vero mollitia id deleniti. Commodi nulla quas sapiente explicabo similique.',
			},
		],
	},
	{
		health_id: 107,
		vin: 'DN44JYXJ817U9GPSP',
		urgency: 'CRITICAL',
		time_period: 87,
		measure: '?',
		value: '?',
		health_description: 'Veniam voluptates omnis velit suscipit suscipit.',
		parts: [
			{
				name: 'Battery',
				id: 175,
				description:
					'Ipsum repudiandae voluptate illo maiores. Veniam molestiae expedita perspiciatis repellendus nobis. Rem ullam officiis omnis qui.',
				recommendation:
					'Consequatur voluptas totam iste debitis vitae illo architecto. Rem earum est a cumque.',
			},
		],
	},
	{
		health_id: 108,
		vin: '3S4CPJ5R9ZVH897SP',
		urgency: 'MEDIUM',
		time_period: 38,
		measure: '?',
		value: '?',
		health_description:
			'Asperiores minus rerum quasi ducimus. Assumenda quibusdam consequuntur illo ducimus. Quasi saepe qui odit perferendis.',
		parts: [
			{
				name: 'Battery',
				id: 365,
				description:
					'Quidem sequi voluptates non similique voluptate minima tenetur. Dolorum ad sunt tempora fugiat suscipit occaecati.',
				recommendation:
					'Quasi non unde fugiat. Nostrum quo recusandae beatae repudiandae quisquam qui.',
			},
		],
	},
	{
		health_id: 109,
		vin: 'MP0CZSZ35339MXW0Z',
		urgency: 'LOW',
		time_period: 80,
		measure: '?',
		value: '?',
		health_description:
			'Labore libero quas ad. Tenetur explicabo rem libero unde maiores.',
		parts: [
			{
				name: 'Battery',
				id: 43,
				description:
					'Fugit inventore eum rerum cupiditate suscipit. Aut fugit quia quam tempore repellendus.',
				recommendation:
					'Officia assumenda totam molestiae error. Ad ut ipsum voluptate iste. At cumque est impedit debitis maxime.',
			},
			{
				name: 'Brakes',
				id: 258,
				description:
					'Voluptatibus quo debitis at laboriosam voluptatibus. Esse ipsum iste.',
				recommendation:
					'Officiis non assumenda distinctio recusandae eligendi eius. Porro numquam atque dicta error. Deleniti ratione eveniet.',
			},
		],
	},
	{
		health_id: 110,
		vin: '9LLASKMZ0ZYKX2PM8',
		urgency: 'HIGH',
		time_period: 78,
		measure: '?',
		value: '?',
		health_description:
			'Non reiciendis nam non id. Soluta quod aspernatur sequi omnis ab. Rem perspiciatis cum nam cupiditate hic exercitationem.',
		parts: [
			{
				name: 'Battery',
				id: 330,
				description:
					'Quibusdam architecto minima provident. Sequi commodi odit explicabo doloremque aliquid dolore. Neque doloribus magni necessitatibus aut in aspernatur. Repellendus minima autem occaecati minus officiis.',
				recommendation:
					'Quam mollitia soluta distinctio eveniet. Officiis eos esse occaecati neque fuga dolorem. Aspernatur quae cumque ipsa mollitia.',
			},
		],
	},
	{
		health_id: 111,
		vin: 'TK2XGE4X9AR0F38C2',
		urgency: 'CRITICAL',
		time_period: 29,
		measure: '?',
		value: '?',
		health_description:
			'Molestiae beatae maxime adipisci veniam. Illum saepe voluptatem nulla earum rem cupiditate. Iure neque voluptatem eveniet nemo.',
		parts: [
			{
				name: 'Battery',
				id: 88,
				description:
					'Incidunt veritatis in commodi a repellat eligendi enim. Esse natus quia. Nulla accusantium dolore sapiente id quas eaque.',
				recommendation:
					'Amet et dolorem totam dolorem a distinctio temporibus. Animi quaerat itaque inventore. Quas esse natus assumenda minima quasi suscipit.',
			},
			{
				name: 'Brakes',
				id: 20,
				description:
					'Ipsa labore officiis omnis ipsum voluptas voluptates. Hic corporis quibusdam minima ex placeat accusantium. Quidem laborum quae sed eos nam.',
				recommendation:
					'Libero commodi doloremque officiis ullam quibusdam incidunt. Omnis alias facilis fuga tenetur deleniti temporibus.',
			},
		],
	},
	{
		health_id: 112,
		vin: 'VLT0TDTZ6X49DLXRL',
		urgency: 'CRITICAL',
		time_period: 26,
		measure: '?',
		value: '?',
		health_description:
			'Nostrum quia maiores. Quas quo pariatur rem porro omnis minima.',
		parts: [
			{
				name: 'Battery',
				id: 126,
				description:
					'Deserunt quam aperiam amet. Dolorum saepe laborum optio saepe.',
				recommendation:
					'Sunt error totam ipsa vitae qui non. Ab deserunt commodi.',
			},
			{
				name: 'Brakes',
				id: 362,
				description:
					'Tempora asperiores repellat saepe praesentium reiciendis. Totam porro ratione vitae quos amet quae. Eius suscipit fugit sequi.',
				recommendation:
					'Cupiditate rerum numquam maiores nihil excepturi quis.',
			},
		],
	},
	{
		health_id: 113,
		vin: 'BWMNGXCF1HKEKJDYL',
		urgency: 'MEDIUM',
		time_period: 35,
		measure: '?',
		value: '?',
		health_description:
			'Id accusantium ipsum animi nihil quia facilis. Iste incidunt consectetur distinctio. Ullam odio alias ipsum consectetur velit optio.',
		parts: [
			{
				name: 'Battery',
				id: 224,
				description:
					'Expedita minus recusandae eos minus officia ipsum. Voluptatum dicta sunt dolorum dolorem sunt deserunt. Velit vel magni nemo.',
				recommendation: 'Accusantium atque nisi.',
			},
			{
				name: 'Brakes',
				id: 268,
				description:
					'Nam repellat illum optio assumenda. Iure veniam delectus sint commodi. Quia dicta dolore nemo fugiat dolorem.',
				recommendation:
					'Doloremque saepe quibusdam. Quaerat explicabo expedita possimus unde.',
			},
		],
	},
	{
		health_id: 114,
		vin: 'XNHJ8RSN80J3J6NVG',
		urgency: 'MEDIUM',
		time_period: 79,
		measure: '?',
		value: '?',
		health_description:
			'Inventore eos incidunt delectus dolorum explicabo. Commodi sed deserunt est repellendus adipisci non beatae. Suscipit ad placeat. Ex quis repudiandae dicta magni.',
		parts: [
			{
				name: 'Battery',
				id: 267,
				description:
					'Ea excepturi animi reiciendis. Dolor quidem adipisci consequatur rem a perferendis ipsam.',
				recommendation:
					'Eveniet fuga quis suscipit quasi rem. Quisquam cupiditate reprehenderit quis deserunt nobis. Ex quaerat doloribus maiores unde debitis nesciunt veritatis.',
			},
		],
	},
	{
		health_id: 115,
		vin: 'XDCHH97F7F4EE47TM',
		urgency: 'CRITICAL',
		time_period: 67,
		measure: '?',
		value: '?',
		health_description:
			'Enim quis accusantium. Necessitatibus nam cum consequuntur. Corporis totam odio pariatur ab.',
		parts: [
			{
				name: 'Battery',
				id: 312,
				description:
					'Odio consectetur similique cum. Cupiditate illo provident aspernatur autem.',
				recommendation:
					'Maiores quo ipsam officia. Quae vero molestiae reiciendis perspiciatis. Explicabo iusto vel incidunt ratione dolorum.',
			},
		],
	},
	{
		health_id: 116,
		vin: 'X791ATXX3CJLJ46E3',
		urgency: 'MEDIUM',
		time_period: 87,
		measure: '?',
		value: '?',
		health_description:
			'Harum cumque et voluptas minus nisi. Sit natus aut corporis. Optio consectetur quaerat ipsa.',
		parts: [
			{
				name: 'Battery',
				id: 81,
				description:
					'Eveniet provident quia accusantium amet placeat ipsum.',
				recommendation:
					'Ut possimus nesciunt repellat. Iure tempore itaque. Ullam temporibus quasi repellendus magni facere consequuntur.',
			},
			{
				name: 'Brakes',
				id: 186,
				description:
					'Illum alias minus nemo incidunt. Nemo delectus enim deleniti eius dolores. Vitae non corporis excepturi eos explicabo necessitatibus sint.',
				recommendation:
					'Recusandae at quam consequatur sunt quia esse debitis. Itaque omnis facere deleniti temporibus reiciendis rerum. Non eveniet consequatur quibusdam fugit mollitia.',
			},
		],
	},
	{
		health_id: 117,
		vin: 'BTB3SYBJ631K7MJWY',
		urgency: 'HIGH',
		time_period: 29,
		measure: '?',
		value: '?',
		health_description:
			'Dolores id illum facilis maxime saepe. Ducimus cumque voluptas nostrum accusantium. Similique quaerat sequi dolor dignissimos at.',
		parts: [
			{
				name: 'Battery',
				id: 190,
				description:
					'Fugit quis sit illum itaque illum reprehenderit. Excepturi animi veniam eum quis sequi. Quibusdam quaerat nulla aspernatur neque quisquam accusantium.',
				recommendation:
					'Aspernatur porro adipisci voluptatem. Quo recusandae explicabo ullam.',
			},
		],
	},
	{
		health_id: 118,
		vin: '9ZWHC8MA3WGB1MJCB',
		urgency: 'MEDIUM',
		time_period: 34,
		measure: '?',
		value: '?',
		health_description:
			'Veritatis iure excepturi non error voluptas. Nobis commodi numquam quaerat possimus veniam.',
		parts: [
			{
				name: 'Battery',
				id: 144,
				description: 'Labore quod sint consequuntur.',
				recommendation:
					'Officiis earum minima voluptatem ratione eaque. Earum repellat ratione mollitia repellat saepe fugit odio. Hic quisquam molestias.',
			},
		],
	},
	{
		health_id: 119,
		vin: 'SUZL6S7B39XLF1GRF',
		urgency: 'MEDIUM',
		time_period: 97,
		measure: '?',
		value: '?',
		health_description:
			'Voluptas iusto voluptatibus fugit facilis at. Deserunt fugiat corporis a eum.',
		parts: [
			{
				name: 'Battery',
				id: 198,
				description:
					'Reprehenderit nisi natus vero dolores error. Sit voluptates nobis laboriosam explicabo quisquam perferendis dolores. Iusto architecto unde ipsa occaecati. Labore rerum a perferendis itaque nihil error.',
				recommendation:
					'Voluptate deserunt molestias accusamus inventore id. Mollitia autem fugit commodi.',
			},
			{
				name: 'Brakes',
				id: 209,
				description:
					'Necessitatibus perspiciatis id. Molestiae repellendus quis vitae nemo rerum.',
				recommendation:
					'Eveniet assumenda incidunt saepe saepe necessitatibus velit repellendus. Nisi ad officia aut voluptatibus fuga soluta.',
			},
		],
	},
	{
		health_id: 120,
		vin: 'VHMK1GPF948WKASZK',
		urgency: 'CRITICAL',
		time_period: 41,
		measure: '?',
		value: '?',
		health_description:
			'Assumenda maiores facere esse doloremque provident. Soluta consequatur fuga ex quis.',
		parts: [
			{
				name: 'Battery',
				id: 396,
				description:
					'Nostrum corrupti quos tempore. Et recusandae numquam assumenda.',
				recommendation:
					'Recusandae saepe minima temporibus eveniet consequatur rem deserunt. Debitis cupiditate beatae ab iusto adipisci.',
			},
		],
	},
	{
		health_id: 121,
		vin: '8JPEE9X04WPTXDF6V',
		urgency: 'CRITICAL',
		time_period: 41,
		measure: '?',
		value: '?',
		health_description: 'Aut beatae placeat.',
		parts: [
			{
				name: 'Battery',
				id: 173,
				description:
					'Quidem quibusdam exercitationem provident animi. Perferendis assumenda repellendus quidem.',
				recommendation:
					'Dignissimos temporibus aliquid vitae quibusdam magni.',
			},
		],
	},
	{
		health_id: 122,
		vin: 'P584ZC0A0LV8DY6ZV',
		urgency: 'LOW',
		time_period: 23,
		measure: '?',
		value: '?',
		health_description:
			'Nam dolores voluptas similique iure magni tenetur. Voluptatibus dolor reiciendis omnis illum praesentium.',
		parts: [
			{
				name: 'Battery',
				id: 347,
				description:
					'Quisquam consectetur nobis tenetur. Maiores consequuntur officiis voluptatem vero dolorum laborum. Aspernatur cumque itaque iste asperiores numquam.',
				recommendation:
					'Eum iure delectus dolor nobis fugiat facere. Id praesentium hic quasi veniam dicta.',
			},
			{
				name: 'Brakes',
				id: 306,
				description:
					'Vitae tenetur consequuntur quasi adipisci minima. Facere quae aut veritatis.',
				recommendation:
					'Quia tenetur modi maiores ullam ex. Fuga deserunt vel quibusdam dolores perspiciatis. Cum soluta itaque impedit nisi possimus numquam.',
			},
		],
	},
	{
		health_id: 123,
		vin: '4W9ZTW4W7K2SG1D53',
		urgency: 'LOW',
		time_period: 71,
		measure: '?',
		value: '?',
		health_description:
			'Qui nostrum architecto saepe veniam hic expedita. Quod asperiores rem ipsa eius deleniti odit ducimus.',
		parts: [
			{
				name: 'Battery',
				id: 26,
				description:
					'Magni consequatur sunt eveniet ullam ratione. Harum hic consectetur esse nisi rem delectus consequatur. Iusto similique quo praesentium ab labore minima. Aperiam asperiores esse dicta.',
				recommendation:
					'Nesciunt in distinctio corrupti. Odit eum minima assumenda omnis eaque corporis.',
			},
			{
				name: 'Brakes',
				id: 323,
				description: 'Non a nihil.',
				recommendation:
					'Fugit nobis culpa reiciendis nostrum nisi iste. Placeat quod reiciendis accusantium soluta dolores fuga. Ea ducimus illum libero.',
			},
		],
	},
	{
		health_id: 124,
		vin: 'UN545X3J6TB9TPRVR',
		urgency: 'CRITICAL',
		time_period: 3,
		measure: '?',
		value: '?',
		health_description: 'Odit eligendi nulla atque.',
		parts: [
			{
				name: 'Battery',
				id: 331,
				description:
					'Laboriosam sequi illo laborum recusandae nostrum numquam eligendi. Doloribus facere cumque blanditiis.',
				recommendation:
					'Dolore placeat delectus quo neque quae necessitatibus error. Iure totam veritatis aliquam ad reprehenderit. Culpa facere voluptatibus odit quidem alias. Ipsa voluptatum placeat nesciunt officiis nulla fugiat.',
			},
		],
	},
	{
		health_id: 125,
		vin: 'R69N8BS93E12KWURE',
		urgency: 'MEDIUM',
		time_period: 58,
		measure: '?',
		value: '?',
		health_description:
			'Provident corrupti eaque similique aut provident nobis ratione. Eligendi cum corrupti earum molestias aperiam.',
		parts: [
			{
				name: 'Battery',
				id: 171,
				description:
					'Repellat reprehenderit omnis exercitationem hic. Rerum modi illo quidem cum. Incidunt voluptatum praesentium.',
				recommendation:
					'Debitis atque dolore vero commodi id deserunt. Laudantium unde quis tempore enim molestias.',
			},
			{
				name: 'Brakes',
				id: 33,
				description:
					'Nostrum ducimus mollitia exercitationem. Illum nesciunt nobis sequi. Voluptas officia aut natus veritatis. Debitis voluptates tempore officia voluptates qui laboriosam.',
				recommendation:
					'Perferendis et corrupti soluta. Laboriosam modi aut fuga quos reiciendis. Reiciendis maiores corporis odit rerum aliquid.',
			},
		],
	},
	{
		health_id: 126,
		vin: 'FWN0HC833T2FD573V',
		urgency: 'MEDIUM',
		time_period: 32,
		measure: '?',
		value: '?',
		health_description:
			'Ab sequi veritatis mollitia. Deserunt pariatur accusantium aperiam delectus maiores.',
		parts: [
			{
				name: 'Battery',
				id: 168,
				description:
					'Adipisci tenetur quo cupiditate. Aliquid tempore veritatis nemo autem nisi soluta.',
				recommendation:
					'Laborum hic culpa optio quam sed. Porro ipsa placeat voluptates.',
			},
		],
	},
	{
		health_id: 127,
		vin: '4F3SVED469ZJELLFK',
		urgency: 'LOW',
		time_period: 31,
		measure: '?',
		value: '?',
		health_description:
			'Sit adipisci deleniti eaque ex modi beatae. Velit tempora totam assumenda. Repudiandae quis expedita aliquam. Est reprehenderit fugit perferendis atque nostrum.',
		parts: [
			{
				name: 'Battery',
				id: 48,
				description:
					'Dignissimos voluptatibus error voluptatibus ullam. Vitae molestiae ducimus accusantium alias quisquam.',
				recommendation:
					'Fugit excepturi magni quisquam exercitationem repellendus placeat. Ullam voluptatibus sed perspiciatis recusandae. Eos ullam recusandae sed.',
			},
			{
				name: 'Brakes',
				id: 285,
				description:
					'Porro perferendis alias similique impedit cumque. Assumenda culpa ab tenetur officiis esse provident. Animi inventore provident sint.',
				recommendation:
					'Autem soluta fuga enim atque debitis. Architecto dolor ut quibusdam quae maxime.',
			},
		],
	},
	{
		health_id: 128,
		vin: '0ETKH4BU6Y4NRLTT0',
		urgency: 'LOW',
		time_period: 82,
		measure: '?',
		value: '?',
		health_description:
			'Aspernatur laboriosam hic quo. Iste enim occaecati inventore reiciendis. Ea illo eius quod quae modi quia.',
		parts: [
			{
				name: 'Battery',
				id: 347,
				description:
					'Officia fugiat omnis voluptate. Laboriosam molestias pariatur commodi.',
				recommendation:
					'Corporis vel quasi nobis quae natus. Animi animi dolorem sed. Qui iste placeat quis.',
			},
			{
				name: 'Brakes',
				id: 197,
				description:
					'Aut totam molestias quibusdam. Eos cupiditate a accusantium tempora repudiandae.',
				recommendation:
					'Iste possimus deserunt nulla sint quis. Quis quam beatae incidunt corporis. Ipsam fuga reiciendis ex consequatur alias odit.',
			},
		],
	},
	{
		health_id: 129,
		vin: 'KRBXFEK00W4TT9RFG',
		urgency: 'LOW',
		time_period: 43,
		measure: '?',
		value: '?',
		health_description:
			'Quisquam adipisci amet aut veniam architecto. Illo et dignissimos consectetur recusandae.',
		parts: [
			{
				name: 'Battery',
				id: 145,
				description:
					'Saepe tenetur non provident autem. Necessitatibus optio repellat adipisci blanditiis.',
				recommendation:
					'Dolores at necessitatibus error iusto odit. Veritatis dicta autem delectus id enim.',
			},
			{
				name: 'Brakes',
				id: 129,
				description:
					'Nobis et tempora a. Error expedita corporis atque numquam. Sed odit aliquid excepturi inventore fugiat.',
				recommendation:
					'Earum consequuntur natus dolor impedit tempore. Culpa neque minus.',
			},
		],
	},
	{
		health_id: 130,
		vin: 'DM6VP6C46HR35CDXD',
		urgency: 'HIGH',
		time_period: 7,
		measure: '?',
		value: '?',
		health_description:
			'Laborum debitis reiciendis at inventore molestias nostrum.',
		parts: [
			{
				name: 'Battery',
				id: 370,
				description:
					'Ex sapiente occaecati suscipit ipsum repellat. Illum eveniet consequuntur id distinctio molestias ea.',
				recommendation:
					'Laboriosam odit in ullam est. Dolore dolor fuga illum necessitatibus.',
			},
			{
				name: 'Brakes',
				id: 336,
				description:
					'Perferendis incidunt nam quisquam commodi. Non nostrum voluptatem sequi culpa.',
				recommendation:
					'Ipsum laborum vel. Provident dignissimos aperiam odit nihil autem. Officia molestias quibusdam perferendis nobis vero quaerat.',
			},
		],
	},
	{
		health_id: 131,
		vin: 'MK1DC8XF0DSFLJRWN',
		urgency: 'HIGH',
		time_period: 1,
		measure: '?',
		value: '?',
		health_description:
			'Officia totam illo quaerat. Dolorem quos molestiae enim tempore in.',
		parts: [
			{
				name: 'Battery',
				id: 308,
				description:
					'Qui minima maiores quos esse consequuntur. Libero molestiae ipsam veritatis unde.',
				recommendation: 'Nemo nisi qui. Id odit sequi nulla.',
			},
		],
	},
	{
		health_id: 132,
		vin: 'KGPZYW7X3LDPLT3E0',
		urgency: 'LOW',
		time_period: 61,
		measure: '?',
		value: '?',
		health_description:
			'Asperiores ullam eos. Sapiente voluptate perspiciatis a cum ratione.',
		parts: [
			{
				name: 'Battery',
				id: 76,
				description: 'Quaerat reiciendis maiores quod.',
				recommendation:
					'Aperiam illum corporis neque vitae suscipit. At non fuga.',
			},
			{
				name: 'Brakes',
				id: 170,
				description:
					'Repudiandae qui natus ducimus deserunt aliquid enim. Aliquid cumque tenetur facilis. Quam dicta aliquid quos vero ipsam.',
				recommendation:
					'Libero voluptatibus laboriosam voluptatum. Sequi laboriosam mollitia nulla accusamus.',
			},
		],
	},
	{
		health_id: 133,
		vin: '2GTJ0CME5RFYMGYYW',
		urgency: 'MEDIUM',
		time_period: 14,
		measure: '?',
		value: '?',
		health_description:
			'Dolore corrupti sed fuga dolorem. Repellendus quasi velit fuga quo. Ipsum odit dicta accusantium quidem.',
		parts: [
			{
				name: 'Battery',
				id: 41,
				description: 'Explicabo autem delectus maiores.',
				recommendation:
					'Eos vero neque. Consectetur possimus sed quasi culpa. Ullam doloribus debitis asperiores voluptatum.',
			},
			{
				name: 'Brakes',
				id: 298,
				description:
					'Ipsa quos ab nisi omnis libero porro. Aut dolore et quas.',
				recommendation:
					'Eius cupiditate dolore ad quia natus veniam. Commodi quod quae repudiandae nihil ullam.',
			},
		],
	},
	{
		health_id: 134,
		vin: 'ANA9YB430541T9E50',
		urgency: 'LOW',
		time_period: 52,
		measure: '?',
		value: '?',
		health_description:
			'Delectus voluptate culpa eveniet saepe quos suscipit. Soluta dignissimos pariatur quisquam. Dignissimos iste laboriosam eius.',
		parts: [
			{
				name: 'Battery',
				id: 339,
				description:
					'Labore aliquam cumque voluptate adipisci. Blanditiis reprehenderit adipisci voluptatum. Molestias nesciunt repellat veritatis velit voluptatum.',
				recommendation:
					'Illum et sapiente alias eligendi corporis. Accusamus modi quasi sint dolore iusto.',
			},
			{
				name: 'Brakes',
				id: 72,
				description:
					'Harum distinctio earum dolorem doloribus debitis autem. Odio sapiente minima adipisci omnis.',
				recommendation:
					'Autem molestiae ad sunt provident aut autem. Iure laudantium optio enim.',
			},
		],
	},
	{
		health_id: 135,
		vin: '1DZWP4XY421T12HJ2',
		urgency: 'HIGH',
		time_period: 96,
		measure: '?',
		value: '?',
		health_description:
			'Ipsa ullam iste natus labore sint. Nulla nam eaque cumque ut adipisci nemo laboriosam.',
		parts: [
			{
				name: 'Battery',
				id: 179,
				description:
					'Eveniet quas itaque fugit delectus fuga dolorum. Similique veniam labore quis iure. Autem et occaecati doloribus tempora illum. Facere cupiditate quo eius.',
				recommendation:
					'Molestias quidem magnam repellendus. Architecto debitis doloribus porro dicta reprehenderit earum. Aliquam porro iure.',
			},
		],
	},
	{
		health_id: 136,
		vin: 'ZUB770FC2GNHS6PSW',
		urgency: 'MEDIUM',
		time_period: 6,
		measure: '?',
		value: '?',
		health_description:
			'Vero minus eius qui facilis esse praesentium. A maxime illum doloremque occaecati. Magni asperiores maxime. Debitis minima explicabo eveniet debitis.',
		parts: [
			{
				name: 'Battery',
				id: 158,
				description:
					'Sed quia at totam recusandae repellat rerum nulla. Illo possimus sequi omnis ea voluptatibus iste.',
				recommendation:
					'Excepturi aliquid deleniti odit. Quae commodi illum corporis molestiae tenetur ducimus.',
			},
		],
	},
	{
		health_id: 137,
		vin: 'FEK636SR8RX41A6YU',
		urgency: 'HIGH',
		time_period: 10,
		measure: '?',
		value: '?',
		health_description:
			'Pariatur sunt laboriosam repellendus molestias iure. Exercitationem at consequatur. Necessitatibus laudantium consequuntur mollitia ducimus rerum tempora.',
		parts: [
			{
				name: 'Battery',
				id: 335,
				description:
					'Necessitatibus quam aliquam dolorum fuga. Fuga voluptatem alias placeat voluptas nesciunt nemo saepe. Deserunt architecto nobis molestiae.',
				recommendation:
					'Optio optio quibusdam quidem ut. Praesentium voluptates pariatur provident aperiam libero reiciendis pariatur. Explicabo quam voluptatibus dignissimos. Nihil tempora quia illo non eius itaque cum.',
			},
			{
				name: 'Brakes',
				id: 357,
				description:
					'Officiis culpa adipisci. Blanditiis accusamus quos in reprehenderit aut mollitia. Quo perspiciatis quam enim eum laboriosam. Possimus recusandae vitae accusantium.',
				recommendation:
					'Est itaque amet iusto consequatur. Amet cum ratione voluptatem rerum atque dolor.',
			},
		],
	},
	{
		health_id: 138,
		vin: 'M73EDXH3X5H3BL2D0',
		urgency: 'MEDIUM',
		time_period: 71,
		measure: '?',
		value: '?',
		health_description:
			'Nam pariatur praesentium non corporis animi. Ex expedita eum laboriosam molestias blanditiis minus.',
		parts: [
			{
				name: 'Battery',
				id: 339,
				description:
					'Consequatur voluptatum molestias nam hic cumque corrupti. Sequi minima quia similique.',
				recommendation:
					'Aliquid nisi distinctio ad tenetur. Veniam earum cupiditate laboriosam consectetur aspernatur repudiandae.',
			},
			{
				name: 'Brakes',
				id: 200,
				description:
					'Dolores id iste labore ducimus voluptatibus. Error voluptatibus harum sed sequi doloremque.',
				recommendation:
					'Iusto distinctio architecto qui. Inventore similique quod harum quae veniam. Tempora hic ducimus cum perspiciatis repellat officia odio.',
			},
		],
	},
	{
		health_id: 139,
		vin: 'S5KAFRMR396ZN47UE',
		urgency: 'HIGH',
		time_period: 90,
		measure: '?',
		value: '?',
		health_description:
			'In velit voluptate quaerat. Maxime aspernatur exercitationem aut hic repellat optio nam. Officia beatae iusto corrupti quis blanditiis. Eaque ex expedita.',
		parts: [
			{
				name: 'Battery',
				id: 66,
				description:
					'Dolorem quia cumque ab dolore voluptatum. Nisi voluptate ratione.',
				recommendation:
					'Dignissimos illo beatae ex est dolore ea mollitia. Temporibus officia quod magni. Reprehenderit porro debitis magnam quas cupiditate.',
			},
			{
				name: 'Brakes',
				id: 304,
				description:
					'Soluta commodi optio. Blanditiis quo voluptates enim minus omnis laborum.',
				recommendation:
					'Saepe dolorem laborum quo delectus repellat facere. Doloremque velit impedit quibusdam tenetur ab. Tempora illum sapiente perspiciatis quisquam aliquam eius. Modi maxime odio dolorem enim illum.',
			},
		],
	},
	{
		health_id: 140,
		vin: '5MSL2HVY4ENRUBR6J',
		urgency: 'MEDIUM',
		time_period: 98,
		measure: '?',
		value: '?',
		health_description:
			'Deleniti aliquam incidunt ratione. Voluptate neque omnis animi rerum voluptatem excepturi. Amet doloribus exercitationem eos.',
		parts: [
			{
				name: 'Battery',
				id: 362,
				description:
					'At voluptatem rerum repellat excepturi. Minus dolorem atque perferendis officiis. Vel animi tenetur sapiente neque illum.',
				recommendation:
					'Nam modi voluptate asperiores distinctio. Dicta nisi minus qui ullam.',
			},
			{
				name: 'Brakes',
				id: 43,
				description:
					'Error natus exercitationem quod distinctio. Libero doloremque nulla vel esse laboriosam.',
				recommendation:
					'Quam tempora quod sint odit deserunt. Quis mollitia repudiandae quibusdam voluptatem.',
			},
		],
	},
	{
		health_id: 141,
		vin: 'F56H0R0R2LP761KVP',
		urgency: 'MEDIUM',
		time_period: 30,
		measure: '?',
		value: '?',
		health_description:
			'Minus eligendi impedit nihil. Perspiciatis aliquid labore aperiam error ratione.',
		parts: [
			{
				name: 'Battery',
				id: 158,
				description: 'Accusamus blanditiis excepturi minima.',
				recommendation: 'Perferendis sequi possimus repudiandae.',
			},
			{
				name: 'Brakes',
				id: 286,
				description:
					'Dolore delectus quia modi omnis dolore. Voluptas provident voluptates aliquid.',
				recommendation:
					'Corporis et blanditiis unde earum officiis accusamus debitis. Minima non quo aliquam. Nesciunt doloribus consectetur.',
			},
		],
	},
	{
		health_id: 142,
		vin: 'H796BUVZ9T726N6LR',
		urgency: 'MEDIUM',
		time_period: 21,
		measure: '?',
		value: '?',
		health_description:
			'Reprehenderit ducimus expedita. Aspernatur mollitia rem corporis. Ea reprehenderit numquam nulla sunt pariatur aliquid.',
		parts: [
			{
				name: 'Battery',
				id: 192,
				description:
					'Reiciendis corrupti nihil aliquid. Iure porro sunt iure accusamus.',
				recommendation:
					'Ratione magni asperiores similique. Pariatur ad facilis sequi dolore illo.',
			},
			{
				name: 'Brakes',
				id: 329,
				description:
					'Facilis quibusdam quis debitis eligendi quasi. Est aut alias. Reprehenderit dolorum natus quod aliquid quisquam.',
				recommendation: 'Odio eveniet consequuntur harum provident.',
			},
		],
	},
	{
		health_id: 143,
		vin: '1JTR6FAR3A9HKHY05',
		urgency: 'MEDIUM',
		time_period: 60,
		measure: '?',
		value: '?',
		health_description:
			'Exercitationem architecto sed nobis in laudantium. Officia reprehenderit error voluptates dolorem perferendis ullam. Quae perferendis tempora doloribus vel praesentium.',
		parts: [
			{
				name: 'Battery',
				id: 168,
				description:
					'Voluptates voluptatibus provident esse perspiciatis dolore quam sed. Neque corrupti quod.',
				recommendation:
					'Fuga minus ipsam nesciunt odit. Eaque maxime optio soluta.',
			},
		],
	},
	{
		health_id: 144,
		vin: 'AUHTKX5P9GFYCW79K',
		urgency: 'CRITICAL',
		time_period: 81,
		measure: '?',
		value: '?',
		health_description:
			'Maxime ea consequuntur rem consequuntur nesciunt. Natus quasi quidem repellendus voluptates velit. Fugiat delectus nulla impedit consectetur.',
		parts: [
			{
				name: 'Battery',
				id: 65,
				description:
					'Quos vero fuga quas aut. Nihil cupiditate nam laudantium.',
				recommendation:
					'Occaecati aut alias iste natus asperiores. Debitis sint odit modi eaque dolores ratione.',
			},
			{
				name: 'Brakes',
				id: 343,
				description:
					'Culpa laudantium blanditiis ipsa deserunt dolor sunt fuga. Voluptatem dicta ullam pariatur voluptatum. Delectus quos magnam.',
				recommendation:
					'Illo dolores expedita libero quidem quod. Consequuntur delectus voluptas perferendis assumenda minus. Enim quo nesciunt eveniet. Soluta nam reprehenderit veniam adipisci.',
			},
		],
	},
	{
		health_id: 145,
		vin: '1SMRMVMU09KLCXLBS',
		urgency: 'HIGH',
		time_period: 97,
		measure: '?',
		value: '?',
		health_description:
			'Minus earum distinctio ex illum asperiores. Animi accusamus mollitia eaque. Beatae adipisci doloremque ratione eum nostrum corrupti.',
		parts: [
			{
				name: 'Battery',
				id: 359,
				description:
					'Quas corporis doloribus reprehenderit distinctio deleniti eveniet similique. At sit commodi vitae dignissimos.',
				recommendation:
					'Iste libero tempore repudiandae. Minus aspernatur quod ab ratione cum. Ducimus consequatur nemo quaerat. Dicta reiciendis nemo in iste nisi.',
			},
			{
				name: 'Brakes',
				id: 378,
				description:
					'Officiis repudiandae delectus ex commodi. Quod perspiciatis dolores at ipsam praesentium.',
				recommendation:
					'Assumenda asperiores similique. Nisi quam saepe illum aperiam praesentium quas.',
			},
		],
	},
	{
		health_id: 146,
		vin: 'LJYFLRHS4P8CB2WDV',
		urgency: 'HIGH',
		time_period: 74,
		measure: '?',
		value: '?',
		health_description:
			'Quasi aliquam dicta. Hic architecto magnam voluptate nisi ad esse.',
		parts: [
			{
				name: 'Battery',
				id: 350,
				description:
					'Saepe mollitia nostrum non error temporibus. Eligendi nostrum ipsum ad vel nesciunt.',
				recommendation:
					'Enim commodi ullam animi corporis mollitia quas nihil. Omnis similique explicabo nostrum ab placeat.',
			},
			{
				name: 'Brakes',
				id: 269,
				description:
					'Natus vero ipsam non veniam odio corporis. Atque cumque iusto placeat libero eaque ratione. Vitae quisquam nam magnam.',
				recommendation:
					'Tempore placeat ipsum modi fugit dolore fugit. Debitis ipsam eos eligendi iure quos. Natus vero minima quo. Perspiciatis corrupti laudantium magnam.',
			},
		],
	},
	{
		health_id: 147,
		vin: 'J5TY9KFG90UR5VXSZ',
		urgency: 'CRITICAL',
		time_period: 63,
		measure: '?',
		value: '?',
		health_description:
			'Dolores numquam blanditiis assumenda. Autem placeat eligendi optio alias veritatis quia. Aperiam sunt autem sunt libero numquam accusantium.',
		parts: [
			{
				name: 'Battery',
				id: 47,
				description:
					'Quaerat pariatur aut sapiente optio corporis dolor recusandae. Enim expedita quidem similique ducimus suscipit nihil. Quia ut animi eius.',
				recommendation:
					'Ut perferendis non atque. Fuga maxime illum veritatis possimus. Deserunt neque aspernatur at quo qui minus.',
			},
			{
				name: 'Brakes',
				id: 330,
				description:
					'Voluptate quas voluptates voluptates natus similique sed recusandae. Inventore quasi repellendus error. Aliquam provident cumque cum laudantium iste.',
				recommendation:
					'Voluptas dolorum sunt enim tempora dolorem. Ab earum aliquid. Quae eligendi ullam fugit.',
			},
		],
	},
	{
		health_id: 148,
		vin: 'T3P67FNA03SMU717G',
		urgency: 'MEDIUM',
		time_period: 42,
		measure: '?',
		value: '?',
		health_description:
			'Aperiam quo tempora debitis modi. Assumenda earum incidunt delectus placeat. Eaque accusantium sunt molestias. Nostrum vel magni perspiciatis accusamus impedit nulla.',
		parts: [
			{
				name: 'Battery',
				id: 343,
				description:
					'Veniam dolorem repudiandae molestias quo. Error ipsam animi quos.',
				recommendation:
					'Accusamus dolores repellat totam architecto commodi. Sequi nemo temporibus assumenda ab dolorum.',
			},
			{
				name: 'Brakes',
				id: 216,
				description:
					'Repellendus sint dolores autem tempora error impedit. Aperiam itaque natus doloremque voluptate facere eaque.',
				recommendation:
					'Modi quo reprehenderit in. Expedita tempora nostrum illum.',
			},
		],
	},
	{
		health_id: 149,
		vin: 'EZEVPSWZX0N2X7JT6',
		urgency: 'MEDIUM',
		time_period: 51,
		measure: '?',
		value: '?',
		health_description:
			'Quisquam necessitatibus expedita laboriosam sit. Similique non amet dolor.',
		parts: [
			{
				name: 'Battery',
				id: 260,
				description:
					'Incidunt quo architecto porro aliquid dolore eveniet. Enim corporis soluta magni ullam. Illum nihil eum suscipit cupiditate ducimus vel ipsam.',
				recommendation:
					'Deserunt consectetur corporis veniam. Nemo ipsum nemo vero excepturi ducimus tenetur. Blanditiis nesciunt eligendi inventore doloribus.',
			},
			{
				name: 'Brakes',
				id: 185,
				description:
					'Placeat odio illum sequi exercitationem. Dolor est qui consequuntur eos error dolorum. Debitis ducimus sed rem officiis deleniti.',
				recommendation:
					'Quisquam harum dignissimos magnam. Sunt ipsum reiciendis odit soluta aut veniam.',
			},
		],
	},
	{
		health_id: 150,
		vin: '80NKMWDX1NX7VVL5K',
		urgency: 'HIGH',
		time_period: 64,
		measure: '?',
		value: '?',
		health_description:
			'At laborum accusamus sequi. Eligendi consequatur iste blanditiis.',
		parts: [
			{
				name: 'Battery',
				id: 9,
				description:
					'Cum tenetur eaque iusto fuga. Tenetur veritatis delectus vel.',
				recommendation:
					'Modi possimus quos ipsam alias odio earum. Sint laudantium vitae quo iste.',
			},
		],
	},
	{
		health_id: 151,
		vin: '382M7AFB4AMUWESBB',
		urgency: 'CRITICAL',
		time_period: 76,
		measure: '?',
		value: '?',
		health_description:
			'Vel error ratione dolorem. Quisquam minus assumenda eos magnam ut sequi et.',
		parts: [
			{
				name: 'Battery',
				id: 185,
				description: 'Tenetur aut consequatur.',
				recommendation:
					'Voluptate recusandae suscipit ad asperiores. Nihil temporibus nam vero. Numquam consequuntur maiores.',
			},
		],
	},
	{
		health_id: 152,
		vin: 'US2CVUR15DDH57BPL',
		urgency: 'MEDIUM',
		time_period: 56,
		measure: '?',
		value: '?',
		health_description:
			'Sequi perspiciatis a ea minima itaque sint. Dignissimos impedit tenetur vitae reiciendis incidunt.',
		parts: [
			{
				name: 'Battery',
				id: 158,
				description:
					'Aliquam facere ad. Vitae nostrum magnam repellendus perspiciatis quis.',
				recommendation:
					'Delectus asperiores cumque autem recusandae delectus. Vero natus fugit.',
			},
		],
	},
	{
		health_id: 153,
		vin: 'CW8GYLRS38NNWBK69',
		urgency: 'MEDIUM',
		time_period: 80,
		measure: '?',
		value: '?',
		health_description:
			'Rem quam impedit ipsam aliquam velit. Quo expedita necessitatibus veritatis accusantium porro quod. Ratione distinctio autem quae quasi.',
		parts: [
			{
				name: 'Battery',
				id: 92,
				description:
					'Dolores accusantium cum architecto doloremque vitae similique. Consectetur ex molestiae enim inventore ex.',
				recommendation:
					'Ducimus a debitis sunt. Dolore repudiandae odio.',
			},
		],
	},
	{
		health_id: 154,
		vin: 'L8FK8BP05ZRT9RUBY',
		urgency: 'MEDIUM',
		time_period: 41,
		measure: '?',
		value: '?',
		health_description:
			'Autem dolores laudantium. Unde fuga quibusdam pariatur quae nihil. Id possimus distinctio nam tempora.',
		parts: [
			{
				name: 'Battery',
				id: 109,
				description:
					'Quaerat explicabo accusantium natus cupiditate dolores. Tempore aut modi officia accusantium aperiam voluptas. Aspernatur cupiditate porro consequatur sit.',
				recommendation:
					'Nobis consectetur id eligendi. Expedita corrupti corrupti eius. Hic nobis veniam quae ipsa quae et.',
			},
		],
	},
	{
		health_id: 155,
		vin: 'BAYJ9BLV04N1Y9CRZ',
		urgency: 'LOW',
		time_period: 32,
		measure: '?',
		value: '?',
		health_description:
			'Quia autem iste id. Quibusdam reprehenderit sequi iusto nobis. Illum ex quae aliquam.',
		parts: [
			{
				name: 'Battery',
				id: 174,
				description:
					'Aperiam saepe sunt blanditiis. Quisquam ut est. Porro qui assumenda delectus ad tempora provident.',
				recommendation:
					'Consequatur sequi illo aspernatur aspernatur dicta. Tenetur soluta nostrum fugiat consectetur iure accusamus.',
			},
		],
	},
	{
		health_id: 156,
		vin: 'YTYTVYA662EJ3HEJA',
		urgency: 'CRITICAL',
		time_period: 15,
		measure: '?',
		value: '?',
		health_description:
			'Id velit facilis sequi fugit. Soluta facere architecto reprehenderit neque iste et.',
		parts: [
			{
				name: 'Battery',
				id: 392,
				description:
					'Tempore soluta voluptas culpa. Sequi magni iste maxime temporibus.',
				recommendation:
					'Explicabo labore cum explicabo optio. Repellat aliquam corrupti blanditiis culpa minus aperiam. Hic deleniti incidunt dicta dolorem expedita quam distinctio. Quod laborum ipsum dolores harum voluptatibus eligendi.',
			},
			{
				name: 'Brakes',
				id: 248,
				description:
					'Soluta quo modi incidunt qui illo. Quas unde culpa placeat saepe itaque nam. Accusantium iste veritatis deleniti fugiat. Quisquam reiciendis sapiente magni.',
				recommendation:
					'Tenetur tenetur facere tenetur odit aliquam facere. Cum voluptatibus id ullam doloribus animi officia repellendus. Reprehenderit adipisci consequatur ratione ut repellat nulla nam. Quaerat corrupti blanditiis sapiente corrupti.',
			},
		],
	},
	{
		health_id: 157,
		vin: 'WYZ88HP37VDU7FK3V',
		urgency: 'MEDIUM',
		time_period: 62,
		measure: '?',
		value: '?',
		health_description:
			'Veniam ipsam laboriosam harum totam. Perferendis maiores dolore eligendi praesentium a. Adipisci doloremque iusto. Labore inventore fuga ipsam doloremque harum.',
		parts: [
			{
				name: 'Battery',
				id: 98,
				description:
					'Distinctio quos modi alias. Facilis nulla nam assumenda quod. Commodi cumque quasi rerum.',
				recommendation:
					'Harum id eligendi fuga nisi. Dolorum corrupti eaque suscipit reiciendis dolorem. Reiciendis magni rem praesentium ea quo beatae.',
			},
			{
				name: 'Brakes',
				id: 115,
				description:
					'Facere libero officiis sint. Quod similique esse dolorem error.',
				recommendation:
					'Dolores similique vero et temporibus. Provident repellendus quo corporis recusandae delectus. Quis cupiditate ratione modi nemo harum.',
			},
		],
	},
	{
		health_id: 158,
		vin: 'GN6V8FDN7UA9DD4NH',
		urgency: 'LOW',
		time_period: 27,
		measure: '?',
		value: '?',
		health_description:
			'Non unde debitis incidunt repellat nobis. Molestiae aspernatur error architecto vel quia exercitationem nobis.',
		parts: [
			{
				name: 'Battery',
				id: 70,
				description:
					'Hic aliquid at natus ipsam recusandae. Aliquid esse tenetur magnam maiores dicta aspernatur.',
				recommendation:
					'Sapiente ipsum illum ullam dolores non. Illum nam dolorum quae quasi nihil magni. Ratione eaque tempore quos ducimus modi animi. Pariatur recusandae ducimus vitae saepe eaque perspiciatis.',
			},
			{
				name: 'Brakes',
				id: 79,
				description:
					'Rem nisi ea officia nisi veritatis. Tempora ipsa vero optio.',
				recommendation:
					'Doloremque illo labore aut cupiditate. Rem aspernatur vel placeat ipsa nemo accusantium.',
			},
		],
	},
	{
		health_id: 159,
		vin: 'ALJJHZ797T4H99F0L',
		urgency: 'CRITICAL',
		time_period: 28,
		measure: '?',
		value: '?',
		health_description:
			'Delectus reiciendis architecto rem rerum suscipit quibusdam sit. Sequi fugit deleniti atque. Dolor ratione dignissimos nulla magnam ducimus soluta.',
		parts: [
			{
				name: 'Battery',
				id: 39,
				description:
					'Reprehenderit maiores consequatur autem necessitatibus. Placeat fugiat rerum ut expedita id. Facilis veniam dignissimos a facilis dolore ab assumenda.',
				recommendation:
					'Laudantium magnam repudiandae eveniet. Vel id cumque aliquam maiores.',
			},
			{
				name: 'Brakes',
				id: 151,
				description:
					'Maxime ut necessitatibus ex labore deleniti. Nisi repellendus nobis iure consectetur.',
				recommendation:
					'Quidem neque facilis distinctio esse assumenda. Amet labore officia quas alias dolor sunt.',
			},
		],
	},
	{
		health_id: 160,
		vin: 'FSY0HTRV956SZHPWY',
		urgency: 'MEDIUM',
		time_period: 81,
		measure: '?',
		value: '?',
		health_description:
			'Accusantium inventore perspiciatis officia eius facere. Laboriosam similique nihil voluptas adipisci voluptatum qui. Magnam porro autem animi.',
		parts: [
			{
				name: 'Battery',
				id: 51,
				description: 'Esse adipisci assumenda ipsa.',
				recommendation: 'Eum quidem consequuntur fugit.',
			},
			{
				name: 'Brakes',
				id: 259,
				description: 'Qui dignissimos illum dignissimos ab fuga.',
				recommendation:
					'Sapiente quisquam ipsam vitae amet assumenda aliquam. Blanditiis assumenda pariatur laudantium itaque quisquam.',
			},
		],
	},
	{
		health_id: 161,
		vin: 'L16WN5SD1UDYHLJM9',
		urgency: 'HIGH',
		time_period: 64,
		measure: '?',
		value: '?',
		health_description:
			'Eius fugiat debitis natus voluptatum molestiae. Eum eos ut unde vitae velit beatae. Aspernatur esse odio quis itaque nesciunt quod soluta. Qui itaque tenetur voluptatem ipsa hic.',
		parts: [
			{
				name: 'Battery',
				id: 394,
				description:
					'Illo iste neque dolore nam qui repellat. Perspiciatis pariatur mollitia inventore facere.',
				recommendation:
					'Tempora eaque totam eveniet. Alias eveniet dolorum sequi provident repellendus.',
			},
			{
				name: 'Brakes',
				id: 276,
				description:
					'Minima dolore eos aspernatur soluta ipsa. Corporis earum voluptas quisquam quibusdam. Repellendus reprehenderit beatae dolorem.',
				recommendation:
					'Hic sunt ullam magnam perferendis magnam quibusdam. Neque nulla consequuntur minima cum maxime.',
			},
		],
	},
	{
		health_id: 162,
		vin: 'YM437W283AVW1UC9K',
		urgency: 'LOW',
		time_period: 59,
		measure: '?',
		value: '?',
		health_description:
			'Illo earum accusamus cumque ipsum perspiciatis ea. Ducimus voluptatum optio harum.',
		parts: [
			{
				name: 'Battery',
				id: 378,
				description:
					'Perspiciatis cumque optio dignissimos. Necessitatibus omnis nemo eius. Saepe praesentium sapiente mollitia ex minima.',
				recommendation:
					'Quaerat quam facere velit assumenda nulla. Ab maxime blanditiis hic cumque quam.',
			},
		],
	},
	{
		health_id: 163,
		vin: 'AH6PG6PA54SKEU671',
		urgency: 'LOW',
		time_period: 58,
		measure: '?',
		value: '?',
		health_description:
			'Ipsum eaque ipsum quaerat. Veniam nulla ducimus impedit dolores earum fugit.',
		parts: [
			{
				name: 'Battery',
				id: 269,
				description:
					'Illum velit perferendis neque illum quidem. Maiores velit animi omnis voluptatem minima nam. Saepe sed quam ullam corporis.',
				recommendation:
					'Nemo tempore asperiores voluptatibus cum fuga quis. Itaque laboriosam illo consequuntur in.',
			},
			{
				name: 'Brakes',
				id: 19,
				description:
					'Quam quibusdam libero praesentium repellat. Quas pariatur deserunt architecto fugit laudantium blanditiis. Porro culpa a accusantium minus quidem.',
				recommendation:
					'Sequi distinctio eligendi. Laborum recusandae in minus earum vero libero est. A tenetur suscipit.',
			},
		],
	},
	{
		health_id: 164,
		vin: 'DX59EV110M8U9X5ER',
		urgency: 'MEDIUM',
		time_period: 2,
		measure: '?',
		value: '?',
		health_description:
			'Dolorum alias a quam. Culpa magnam odio. Quaerat repellat similique commodi itaque illo. Architecto corporis nemo eligendi ducimus.',
		parts: [
			{
				name: 'Battery',
				id: 338,
				description:
					'Earum inventore libero illum. Molestiae in aspernatur. Ad vel debitis maiores sapiente nisi. Molestias recusandae quas.',
				recommendation:
					'Quam aliquam tempora nostrum. Repellat quibusdam temporibus quod nulla amet officia. Quam ratione ipsum at.',
			},
		],
	},
	{
		health_id: 165,
		vin: '677LPK9R49051AVND',
		urgency: 'MEDIUM',
		time_period: 36,
		measure: '?',
		value: '?',
		health_description:
			'Asperiores dolorum odio omnis. Rem corporis impedit iusto architecto alias.',
		parts: [
			{
				name: 'Battery',
				id: 172,
				description:
					'Delectus in officiis id. Blanditiis voluptatum minima porro optio accusamus dignissimos. Dignissimos qui numquam asperiores similique vitae eum. Est delectus aperiam voluptatum voluptates libero a.',
				recommendation:
					'Ut asperiores voluptates nulla mollitia. Occaecati consequuntur architecto voluptate quas dolor at.',
			},
		],
	},
	{
		health_id: 166,
		vin: '0TVD67982RNUL9FH6',
		urgency: 'LOW',
		time_period: 71,
		measure: '?',
		value: '?',
		health_description:
			'Quam nulla occaecati. Vel perferendis soluta fugit.',
		parts: [
			{
				name: 'Battery',
				id: 392,
				description:
					'Odio molestiae qui. In aliquam tempore placeat occaecati illo eligendi.',
				recommendation:
					'Rem mollitia incidunt deserunt ea alias magnam. Modi pariatur possimus modi dolorum blanditiis. Culpa dicta neque possimus consequatur officiis aliquam.',
			},
			{
				name: 'Brakes',
				id: 316,
				description:
					'Veritatis tempore placeat vel porro culpa ipsa repellat. Alias dolore vitae iste neque placeat. Perferendis magnam at ullam excepturi praesentium.',
				recommendation:
					'Officiis consequatur eos ex labore. Sed sint reprehenderit ducimus sed rerum. Commodi illum sapiente necessitatibus aliquam.',
			},
		],
	},
	{
		health_id: 167,
		vin: 'FJ2KDZCS3J4FYR8FY',
		urgency: 'HIGH',
		time_period: 13,
		measure: '?',
		value: '?',
		health_description:
			'Sint atque sed dolorum nobis impedit temporibus odit. Qui a neque. Hic modi perferendis adipisci architecto totam dicta.',
		parts: [
			{
				name: 'Battery',
				id: 67,
				description:
					'Praesentium cum cupiditate cumque facere repudiandae sint. Suscipit earum eos libero. Veniam laboriosam voluptas itaque officia.',
				recommendation:
					'Facilis alias amet eum quis incidunt. Dolorem quasi nam sequi.',
			},
			{
				name: 'Brakes',
				id: 305,
				description:
					'Pariatur reiciendis repellat voluptas praesentium ad magnam. Harum neque porro laboriosam atque iure provident in. Dolore harum natus odio.',
				recommendation:
					'Eaque delectus a hic nobis. Vitae distinctio aperiam laboriosam asperiores neque. Quis molestias esse veritatis beatae tempore dolores. Consequatur in minima commodi perspiciatis deserunt labore.',
			},
		],
	},
	{
		health_id: 168,
		vin: '51PL9RTP4BX5251GD',
		urgency: 'HIGH',
		time_period: 15,
		measure: '?',
		value: '?',
		health_description:
			'Quod accusamus facere excepturi assumenda. Officia aspernatur repellat distinctio sunt voluptates ullam.',
		parts: [
			{
				name: 'Battery',
				id: 192,
				description:
					'Quae deleniti eius consequatur officia sapiente quis pariatur. Qui praesentium consequatur asperiores asperiores quos. Quis magnam delectus doloremque facere assumenda.',
				recommendation:
					'Numquam ratione eaque soluta doloremque quia. Iure temporibus distinctio enim commodi molestiae.',
			},
			{
				name: 'Brakes',
				id: 78,
				description:
					'Saepe odit repellat hic enim nobis voluptatum. Laudantium nesciunt beatae odit. Cupiditate placeat voluptates dolore doloremque.',
				recommendation: 'Distinctio dolorum qui quo at.',
			},
		],
	},
	{
		health_id: 169,
		vin: '34SZJZTU0S60H6K6Z',
		urgency: 'CRITICAL',
		time_period: 89,
		measure: '?',
		value: '?',
		health_description:
			'Esse ad velit rem cumque natus. Nihil aut laborum dignissimos ullam veritatis.',
		parts: [
			{
				name: 'Battery',
				id: 83,
				description:
					'Sapiente perspiciatis blanditiis unde voluptas. Quaerat reiciendis deleniti sunt.',
				recommendation:
					'Cumque eum aut mollitia optio. Culpa iste laborum harum doloremque consequatur. Repellendus quod iusto iure quae.',
			},
			{
				name: 'Brakes',
				id: 92,
				description:
					'Atque cumque impedit. Earum autem voluptatem eius voluptate accusamus in. Eaque iure recusandae.',
				recommendation:
					'Unde deserunt iusto fuga. Veritatis quae autem voluptate accusantium.',
			},
		],
	},
	{
		health_id: 170,
		vin: 'D0LS5KUFX4F6U8W4K',
		urgency: 'MEDIUM',
		time_period: 87,
		measure: '?',
		value: '?',
		health_description:
			'Totam in odit ab eaque. Maxime voluptatibus autem fuga nemo doloribus. Quo nesciunt illum optio eaque quis dolores.',
		parts: [
			{
				name: 'Battery',
				id: 354,
				description:
					'Facilis quas dicta nulla aperiam itaque corporis tenetur. Ex aspernatur harum necessitatibus aliquid reprehenderit.',
				recommendation:
					'Pariatur ullam dolores deserunt numquam nulla. Deserunt atque veniam ex.',
			},
			{
				name: 'Brakes',
				id: 394,
				description:
					'Libero beatae unde similique corrupti unde tempore reiciendis. Dicta sequi eius ea ipsa.',
				recommendation:
					'Aliquam distinctio quos ab harum dolore. Ea odio nostrum quisquam libero corporis.',
			},
		],
	},
	{
		health_id: 171,
		vin: 'B8191AYN5GAP9H82M',
		urgency: 'HIGH',
		time_period: 60,
		measure: '?',
		value: '?',
		health_description:
			'Nesciunt pariatur tempora quod. Laboriosam suscipit in architecto possimus blanditiis error. Saepe aliquam accusantium libero.',
		parts: [
			{
				name: 'Battery',
				id: 319,
				description:
					'Placeat praesentium mollitia magnam quo hic amet. Sint molestiae nobis ea.',
				recommendation:
					'Necessitatibus odit facere facilis nobis neque.',
			},
			{
				name: 'Brakes',
				id: 84,
				description: 'Possimus laboriosam ex ducimus ullam itaque.',
				recommendation:
					'Molestias necessitatibus eius cumque voluptatum numquam quisquam. Natus maiores atque praesentium accusantium dolor iure. Repellendus voluptates aliquam sit.',
			},
		],
	},
	{
		health_id: 172,
		vin: 'UH5G5YWZ2P4YAPAG7',
		urgency: 'CRITICAL',
		time_period: 22,
		measure: '?',
		value: '?',
		health_description:
			'Occaecati fugit facilis fugiat. Illo incidunt dolores temporibus blanditiis quisquam adipisci.',
		parts: [
			{
				name: 'Battery',
				id: 369,
				description:
					'Tenetur ea rerum ducimus. Vero dolor nostrum odit nihil sequi. Distinctio iure possimus.',
				recommendation:
					'Debitis fuga commodi. Quod tempora deserunt laudantium labore tempore aspernatur.',
			},
			{
				name: 'Brakes',
				id: 224,
				description:
					'Rem adipisci sequi eveniet voluptatibus esse nulla accusamus. Natus aliquid perferendis laudantium.',
				recommendation:
					'Ratione modi iure accusamus vero. Omnis vero molestias culpa nemo molestiae accusantium. Dolorem dolore pariatur sint recusandae dolores quisquam optio.',
			},
		],
	},
	{
		health_id: 173,
		vin: '8EZ131F39XPR0B3TE',
		urgency: 'HIGH',
		time_period: 63,
		measure: '?',
		value: '?',
		health_description:
			'Quae aperiam quae voluptatum magnam. Libero nobis dicta laboriosam quae.',
		parts: [
			{
				name: 'Battery',
				id: 22,
				description:
					'Soluta delectus necessitatibus unde. Cumque cupiditate perspiciatis distinctio ratione. Error voluptates architecto.',
				recommendation:
					'Tempore reprehenderit enim sed facilis unde culpa.',
			},
		],
	},
	{
		health_id: 174,
		vin: 'LFTLH5P87Z4BE3S5W',
		urgency: 'MEDIUM',
		time_period: 90,
		measure: '?',
		value: '?',
		health_description:
			'Quia perspiciatis eaque quasi. Maiores esse ducimus aut eveniet vel eius. Eos dicta pariatur nostrum ut nobis.',
		parts: [
			{
				name: 'Battery',
				id: 210,
				description:
					'Perferendis molestias rerum. Harum temporibus totam.',
				recommendation:
					'Laboriosam fugiat eius delectus enim aut quidem. Sunt repellat numquam.',
			},
			{
				name: 'Brakes',
				id: 186,
				description:
					'Optio recusandae vitae officia consequatur molestias. Ex ad voluptatibus provident id iste. Tempora qui quos magni.',
				recommendation:
					'Nam sequi porro suscipit. Beatae ullam sunt id eaque sequi expedita magni. Eligendi dignissimos aperiam dolores.',
			},
		],
	},
	{
		health_id: 175,
		vin: 'JKL6W1PC2JKFWPYCF',
		urgency: 'CRITICAL',
		time_period: 97,
		measure: '?',
		value: '?',
		health_description:
			'Pariatur fugit doloribus sapiente. Quo ipsam asperiores quia ex tenetur eaque. Doloribus accusantium sequi quo.',
		parts: [
			{
				name: 'Battery',
				id: 346,
				description:
					'Nulla possimus voluptas aspernatur. Architecto ipsum animi et exercitationem fugiat soluta. Veniam soluta doloribus natus.',
				recommendation:
					'Earum incidunt cumque quaerat. Itaque error similique expedita temporibus perspiciatis corporis sequi. Quasi laudantium ea veritatis.',
			},
			{
				name: 'Brakes',
				id: 368,
				description:
					'Laudantium sapiente velit culpa. Alias dolorum amet minus.',
				recommendation:
					'Omnis ipsum voluptatum eligendi perferendis est. Repudiandae reprehenderit cumque modi. Fugiat autem eius suscipit hic.',
			},
		],
	},
	{
		health_id: 176,
		vin: '01T2EN1MX1RK7DFTY',
		urgency: 'CRITICAL',
		time_period: 75,
		measure: '?',
		value: '?',
		health_description:
			'Laudantium tenetur beatae mollitia voluptatem. Rem corporis iusto incidunt tenetur quaerat magnam dolorum. Voluptatibus eos qui aliquid porro quae. In ducimus ducimus tenetur necessitatibus cum.',
		parts: [
			{
				name: 'Battery',
				id: 121,
				description:
					'Sed quia ea nulla ipsa beatae magnam. Provident odit fuga incidunt.',
				recommendation:
					'Harum consectetur ipsam culpa assumenda. Distinctio quos officia minus facere.',
			},
		],
	},
	{
		health_id: 177,
		vin: '40H63ETPXW67Z9S54',
		urgency: 'CRITICAL',
		time_period: 74,
		measure: '?',
		value: '?',
		health_description:
			'Id velit quasi occaecati aliquid nemo. Asperiores magni dolorem commodi ea soluta quo. Repellendus cumque quas minima quaerat. Officia nostrum perferendis illo at corporis aperiam.',
		parts: [
			{
				name: 'Battery',
				id: 227,
				description:
					'Accusantium omnis a error iste nulla. Illum dolores dolore iure maiores porro adipisci. Similique earum ut ullam illo laboriosam velit deserunt.',
				recommendation:
					'Accusamus ipsa ipsum eaque. Adipisci qui totam officia.',
			},
			{
				name: 'Brakes',
				id: 312,
				description:
					'In reiciendis praesentium unde laudantium libero provident. Sint repellat optio quos blanditiis enim laudantium. Quisquam ullam laboriosam asperiores deserunt cum.',
				recommendation:
					'Eos molestias iste facilis rerum. Veritatis maiores architecto iusto molestias libero porro mollitia. Soluta animi eos molestiae.',
			},
		],
	},
	{
		health_id: 178,
		vin: 'U9FJGLSL9LVHE5C97',
		urgency: 'HIGH',
		time_period: 25,
		measure: '?',
		value: '?',
		health_description:
			'Harum harum beatae saepe. Error necessitatibus odio blanditiis. Perferendis officia corrupti ullam pariatur voluptatibus veritatis placeat.',
		parts: [
			{
				name: 'Battery',
				id: 145,
				description:
					'Corporis exercitationem a. Porro possimus eveniet explicabo recusandae minus velit.',
				recommendation:
					'Impedit reprehenderit deserunt dolorum. Facere ea nesciunt suscipit. Laborum ab repellat excepturi.',
			},
			{
				name: 'Brakes',
				id: 385,
				description:
					'Dignissimos accusantium ut earum fugit cum corporis. Optio corrupti nihil doloribus praesentium. Dolor quaerat ad optio voluptatem ratione magni.',
				recommendation:
					'Corrupti cum pariatur velit. Vitae qui non ab voluptate. Voluptates tempora eligendi aperiam fugit rerum.',
			},
		],
	},
	{
		health_id: 179,
		vin: '07AVWLT8XNDVKWG86',
		urgency: 'MEDIUM',
		time_period: 48,
		measure: '?',
		value: '?',
		health_description:
			'Eum sint ea delectus. Molestias eligendi libero consectetur numquam quis. Quia illo laborum maxime. Repellat rem similique.',
		parts: [
			{
				name: 'Battery',
				id: 383,
				description:
					'Fugit omnis laboriosam recusandae. Voluptas numquam nam repellendus ducimus fugiat. Animi magnam quod placeat nostrum.',
				recommendation:
					'Delectus a facilis repellendus fugiat. Temporibus dicta minus ab sit. Accusantium deleniti at possimus.',
			},
		],
	},
	{
		health_id: 180,
		vin: 'AK3DL06FXNSZ4KGB2',
		urgency: 'CRITICAL',
		time_period: 43,
		measure: '?',
		value: '?',
		health_description:
			'Ut aperiam quibusdam vero voluptatem nostrum natus. Beatae ipsam odit mollitia eius veritatis ullam.',
		parts: [
			{
				name: 'Battery',
				id: 229,
				description:
					'Velit non repudiandae recusandae. Hic cum nihil impedit pariatur quis. Blanditiis quasi dolores. Quod culpa pariatur ullam quos.',
				recommendation:
					'Reiciendis earum nisi dicta. Officia architecto dolore nobis.',
			},
			{
				name: 'Brakes',
				id: 119,
				description:
					'Veniam dolores quasi dolorem laborum perspiciatis. Aliquid quis sit modi. Vero ea fugiat esse dignissimos.',
				recommendation:
					'Nam porro doloribus. Vero mollitia facilis culpa nobis ad explicabo.',
			},
		],
	},
	{
		health_id: 181,
		vin: 'SVF7W798014E9CSP6',
		urgency: 'LOW',
		time_period: 38,
		measure: '?',
		value: '?',
		health_description: 'Dolores vero nam non optio. Quasi ullam iure at.',
		parts: [
			{
				name: 'Battery',
				id: 273,
				description: 'Ex odio ex non.',
				recommendation:
					'Minima ipsa rerum nisi ut fugiat explicabo. Distinctio enim necessitatibus nulla facilis in soluta. Laboriosam eligendi vero aliquid eum. Alias temporibus ipsum ipsa odit ad laboriosam.',
			},
			{
				name: 'Brakes',
				id: 122,
				description:
					'Tenetur ducimus dicta quibusdam voluptatum. Quia quae dolor dolore autem.',
				recommendation:
					'Quidem enim explicabo quia eos dolores asperiores. Laborum tempora voluptatibus ut recusandae. Pariatur quisquam quod consequatur.',
			},
		],
	},
	{
		health_id: 182,
		vin: 'G525CV961M83W4AUS',
		urgency: 'MEDIUM',
		time_period: 41,
		measure: '?',
		value: '?',
		health_description:
			'Sapiente neque repudiandae doloribus harum hic. Eaque illum similique quidem nulla esse.',
		parts: [
			{
				name: 'Battery',
				id: 158,
				description:
					'Tenetur aliquam placeat tempore quidem esse similique modi. Quae numquam quo provident.',
				recommendation: 'Ullam officiis nemo unde. Odio quis facilis.',
			},
			{
				name: 'Brakes',
				id: 240,
				description:
					'Nulla id corporis molestiae voluptas magni commodi. Unde nihil id expedita dolorum suscipit. Praesentium numquam in occaecati maiores atque occaecati.',
				recommendation:
					'Cupiditate ullam rem quasi. Porro dolorem sed itaque. Enim ipsa nobis exercitationem provident vel quae. Voluptatum dolor cumque distinctio nam consectetur iusto.',
			},
			{
				name: 'Engine',
				id: 130,
				description:
					'Porro nemo est porro molestiae. Facilis a officiis molestiae aliquam fuga.',
				recommendation:
					'Facilis cumque facilis illo tenetur. Natus nesciunt iste velit. Atque ab quas odio omnis.',
			},
		],
	},
	{
		health_id: 183,
		vin: 'Y2RUBNDB3HHV03FJY',
		urgency: 'LOW',
		time_period: 45,
		measure: '?',
		value: '?',
		health_description:
			'Fugiat harum reprehenderit magni hic quam eaque. Veniam corporis porro ullam nostrum reiciendis. Aliquid sed odit recusandae dignissimos corrupti distinctio.',
		parts: [
			{
				name: 'Battery',
				id: 99,
				description:
					'Totam rem earum doloribus officiis ipsa repudiandae. Sapiente libero laudantium fugiat eveniet autem nemo alias. Et eos optio beatae culpa repudiandae eius.',
				recommendation:
					'Ad ipsa provident odit possimus. Quis minima at accusantium nostrum. Laudantium id qui.',
			},
			{
				name: 'Brakes',
				id: 188,
				description:
					'In aperiam harum molestias qui pariatur. Quasi dolorum nostrum illum tempora suscipit.',
				recommendation:
					'Porro mollitia earum voluptatem alias soluta consequatur. Voluptate quae voluptates magnam quo.',
			},
		],
	},
	{
		health_id: 184,
		vin: 'TX46HR9T2CF2RNXYN',
		urgency: 'LOW',
		time_period: 88,
		measure: '?',
		value: '?',
		health_description:
			'Quibusdam libero esse facere excepturi dolores voluptate optio. Aliquid nemo placeat quaerat. Ipsam non perferendis accusamus reprehenderit.',
		parts: [
			{
				name: 'Battery',
				id: 347,
				description:
					'Possimus praesentium cum ipsa vel. Molestiae sapiente neque ratione perspiciatis ipsa. Ut adipisci a provident voluptatem.',
				recommendation:
					'Repudiandae reprehenderit molestiae sit amet. Impedit veritatis facilis earum corporis.',
			},
			{
				name: 'Brakes',
				id: 292,
				description:
					'Culpa laudantium occaecati architecto. Natus aperiam doloribus at. Earum rem dolorum.',
				recommendation:
					'Eveniet at sint at rem magni. Debitis molestias unde illum.',
			},
		],
	},
	{
		health_id: 185,
		vin: 'ZLRHB2LE92H4XNEA7',
		urgency: 'CRITICAL',
		time_period: 40,
		measure: '?',
		value: '?',
		health_description:
			'Accusantium quisquam quibusdam earum dicta magnam expedita. Accusantium debitis natus.',
		parts: [
			{
				name: 'Battery',
				id: 225,
				description:
					'Repellendus modi nesciunt esse nobis. Unde saepe ea laudantium esse nemo.',
				recommendation:
					'Eaque veritatis dolores recusandae animi quo explicabo ea. Molestiae numquam at. Laboriosam repellat similique suscipit soluta facilis pariatur et.',
			},
			{
				name: 'Brakes',
				id: 236,
				description:
					'Non consequuntur suscipit necessitatibus ad ab reprehenderit. Nemo officia dolorum reprehenderit quos aperiam. Fuga perspiciatis quisquam nulla tempore beatae.',
				recommendation:
					'Modi quisquam dolorem qui. Pariatur aliquam minus commodi tempore. Laborum omnis repellendus laborum.',
			},
			{
				name: 'Steering',
				id: 129,
				description:
					'Porro nemo est porro molestiae. Facilis a officiis molestiae aliquam fuga.',
				recommendation:
					'Facilis cumque facilis illo tenetur. Natus nesciunt iste velit. Atque ab quas odio omnis.',
			},
		],
	},
	{
		health_id: 186,
		vin: 'FFH4ERNB3BFLN2N0C',
		urgency: 'CRITICAL',
		time_period: 97,
		measure: '?',
		value: '?',
		health_description:
			'Voluptatum nobis eveniet. Necessitatibus enim quam placeat repudiandae maiores. Suscipit inventore expedita libero.',
		parts: [
			{
				name: 'Battery',
				id: 393,
				description: 'Commodi officia sunt vel.',
				recommendation:
					'Animi consectetur iusto consectetur sit. Possimus quas praesentium fugit culpa natus vel. Nihil veritatis error ad labore molestiae vitae.',
			},
		],
	},
	{
		health_id: 187,
		vin: 'YKBY364E5E2X1MC9M',
		urgency: 'HIGH',
		time_period: 17,
		measure: '?',
		value: '?',
		health_description:
			'Neque sit autem tempora molestiae illum inventore.',
		parts: [
			{
				name: 'Battery',
				id: 144,
				description:
					'Ratione odit similique est modi repellat et. Temporibus ratione quia labore veritatis.',
				recommendation:
					'Numquam illo eius repellendus corrupti error tenetur.',
			},
			{
				name: 'Brakes',
				id: 398,
				description:
					'Quidem quisquam tenetur voluptates. Adipisci porro aliquid deleniti voluptatibus iste.',
				recommendation:
					'Reiciendis porro beatae. Molestias corporis amet a optio quasi. Sequi facere dolores illum necessitatibus tenetur quas esse.',
			},
		],
	},
	{
		health_id: 188,
		vin: 'BL42008P65PS7MKL9',
		urgency: 'HIGH',
		time_period: 88,
		measure: '?',
		value: '?',
		health_description:
			'Nisi dolore non recusandae consequatur hic. Ullam unde suscipit neque. Quae quis voluptatum eius possimus ipsam reiciendis.',
		parts: [
			{
				name: 'Battery',
				id: 195,
				description:
					'Cum repudiandae recusandae amet. Temporibus reprehenderit distinctio dolor.',
				recommendation:
					'Aliquam animi amet dolores reprehenderit optio cumque. Delectus soluta inventore sed illo aspernatur voluptatibus.',
			},
			{
				name: 'Brakes',
				id: 257,
				description:
					'Quos occaecati totam reprehenderit velit amet quibusdam voluptatum. Optio reiciendis nisi sit consequatur.',
				recommendation:
					'Facilis aspernatur tenetur aspernatur consectetur atque. Aperiam quos temporibus quasi distinctio. Fugit harum maxime. Porro doloremque sunt consequatur.',
			},
		],
	},
	{
		health_id: 189,
		vin: 'AUYLBNEB6RKJ9B1S3',
		urgency: 'HIGH',
		time_period: 57,
		measure: '?',
		value: '?',
		health_description:
			'Sapiente quia modi animi. Laboriosam facere veritatis. Sint vero repellendus cumque iusto nisi aliquid.',
		parts: [
			{
				name: 'Battery',
				id: 270,
				description:
					'Modi dolor unde maxime cupiditate recusandae. Eius ratione quidem dolorem. Nemo unde nihil omnis facere esse eos deleniti.',
				recommendation:
					'Praesentium soluta temporibus illo voluptatibus laudantium sed. Libero molestiae quae voluptate.',
			},
		],
	},
	{
		health_id: 190,
		vin: 'BLE7Y0M52FNLA6MV1',
		urgency: 'MEDIUM',
		time_period: 2,
		measure: '?',
		value: '?',
		health_description:
			'Cumque suscipit earum dolore repellendus consectetur. Molestiae veniam exercitationem laborum perspiciatis nesciunt repudiandae.',
		parts: [
			{
				name: 'Battery',
				id: 214,
				description:
					'Quae culpa quasi sint sit repellat. Magnam magni inventore culpa hic quae.',
				recommendation:
					'Ut culpa perferendis rerum corrupti explicabo. Ex provident tenetur dicta.',
			},
		],
	},
	{
		health_id: 191,
		vin: 'GDF5NG7114RVTATYR',
		urgency: 'HIGH',
		time_period: 96,
		measure: '?',
		value: '?',
		health_description:
			'Vel quasi dignissimos perspiciatis dicta incidunt dolores. Facere maiores ipsum fuga voluptate libero.',
		parts: [
			{
				name: 'Battery',
				id: 82,
				description:
					'Ea modi praesentium modi provident libero sequi. Illo asperiores magnam. Eos excepturi voluptatem assumenda nemo. Necessitatibus modi ipsam consequatur.',
				recommendation:
					'Saepe quasi veritatis veritatis ea sapiente tenetur. Quidem iure corporis magni enim temporibus.',
			},
			{
				name: 'Brakes',
				id: 102,
				description:
					'Distinctio corporis doloremque nulla consequuntur in magni autem.',
				recommendation:
					'Culpa reiciendis autem nam. Placeat dolor enim vitae odio excepturi rem.',
			},
		],
	},
	{
		health_id: 192,
		vin: 'NR7XEAV593J2LG75M',
		urgency: 'LOW',
		time_period: 62,
		measure: '?',
		value: '?',
		health_description:
			'Quae odit quidem et sint perferendis veniam iusto. Mollitia tenetur itaque nisi ea quaerat dolor. Vitae architecto animi tenetur laborum doloribus repellendus.',
		parts: [
			{
				name: 'Battery',
				id: 309,
				description:
					'Adipisci nam inventore eligendi error. Incidunt nisi quos necessitatibus distinctio. Ipsa suscipit minima.',
				recommendation:
					'Qui quam ipsa blanditiis suscipit maxime autem. Minus eius nobis occaecati. Dolorem eveniet et quasi maiores.',
			},
		],
	},
	{
		health_id: 193,
		vin: 'ARA56MX2XUAUA47MD',
		urgency: 'HIGH',
		time_period: 88,
		measure: '?',
		value: '?',
		health_description:
			'Quas voluptas expedita vitae impedit minima cupiditate reprehenderit. Accusamus vitae quia ipsa tenetur voluptas sit.',
		parts: [
			{
				name: 'Battery',
				id: 70,
				description:
					'Dolorum blanditiis adipisci aut ipsam. Aliquid iusto reprehenderit odio deserunt quae. Ipsa temporibus quibusdam sed quas.',
				recommendation:
					'Sint saepe libero porro pariatur. Distinctio ipsa culpa.',
			},
			{
				name: 'Brakes',
				id: 128,
				description:
					'Porro nemo est porro molestiae. Facilis a officiis molestiae aliquam fuga.',
				recommendation:
					'Facilis cumque facilis illo tenetur. Natus nesciunt iste velit. Atque ab quas odio omnis.',
			},
			{
				name: 'Steering',
				id: 129,
				description:
					'Porro nemo est porro molestiae. Facilis a officiis molestiae aliquam fuga.',
				recommendation:
					'Facilis cumque facilis illo tenetur. Natus nesciunt iste velit. Atque ab quas odio omnis.',
			},
		],
	},
	{
		health_id: 194,
		vin: 'CTGJKF740SY04P1U6',
		urgency: 'CRITICAL',
		time_period: 9,
		measure: '?',
		value: '?',
		health_description:
			'Maxime sapiente ex iure quae. Consequatur tempore deserunt quidem.',
		parts: [
			{
				name: 'Battery',
				id: 26,
				description:
					'Eaque neque quisquam ut dolores qui. Quas reiciendis commodi hic officia cupiditate deserunt.',
				recommendation:
					'Nesciunt corrupti a ex quam. Aspernatur porro excepturi illo recusandae. Quia natus culpa recusandae recusandae. Est quia blanditiis deleniti.',
			},
			{
				name: 'Brakes',
				id: 328,
				description:
					'Totam illum quo placeat. Neque magnam cumque impedit.',
				recommendation: 'Omnis non maiores illum est voluptatibus.',
			},
		],
	},
	{
		health_id: 195,
		vin: 'JXC72DAJ06X8KSXYG',
		urgency: 'HIGH',
		time_period: 68,
		measure: '?',
		value: '?',
		health_description:
			'Dolore tempora mollitia alias velit laborum maiores culpa. Dicta ipsam perferendis. Rem doloribus at cum.',
		parts: [
			{
				name: 'Battery',
				id: 246,
				description:
					'Voluptates eius sapiente corporis voluptates. Est occaecati animi laudantium optio ipsum repudiandae. Est enim sed cupiditate voluptas ipsa ab.',
				recommendation:
					'Perspiciatis exercitationem modi. Maiores quae ullam quia fugit saepe recusandae. Quam asperiores suscipit dolore.',
			},
		],
	},
	{
		health_id: 196,
		vin: '6RBPRPBV43GVWGEH4',
		urgency: 'MEDIUM',
		time_period: 81,
		measure: '?',
		value: '?',
		health_description:
			'Maiores aspernatur nam magnam. Totam aperiam esse est odio aliquam quas. Deserunt magnam praesentium rerum expedita hic velit.',
		parts: [
			{
				name: 'Battery',
				id: 190,
				description:
					'Eum nisi ad. Aspernatur dolor totam quod delectus quod eos.',
				recommendation:
					'Sed quis architecto hic eveniet adipisci perferendis. Consequatur animi recusandae natus praesentium nihil quasi.',
			},
			{
				name: 'Brakes',
				id: 283,
				description:
					'Provident optio eos labore vero. Nobis possimus nulla dicta.',
				recommendation:
					'Harum ea maxime impedit repellendus. Cum sapiente eveniet quidem maxime. Id eius laboriosam asperiores incidunt.',
			},
		],
	},
	{
		health_id: 197,
		vin: '076NTE4R8TXFXK6S3',
		urgency: 'MEDIUM',
		time_period: 17,
		measure: '?',
		value: '?',
		health_description:
			'Illo quas provident pariatur perspiciatis molestiae nemo expedita. Culpa id magni illum laborum facilis. Ad ab voluptas soluta distinctio.',
		parts: [
			{
				name: 'Battery',
				id: 52,
				description: 'Omnis commodi ducimus expedita a excepturi.',
				recommendation:
					'Quasi reiciendis sunt quisquam eveniet ex repellat. Beatae ipsa occaecati aperiam corrupti cumque.',
			},
			{
				name: 'Brakes',
				id: 364,
				description:
					'Ab dolor incidunt deleniti. Deleniti asperiores debitis rem esse. Labore quidem blanditiis aut.',
				recommendation:
					'Atque libero at ad laboriosam fuga ullam. Quae nam quis accusantium quam.',
			},
		],
	},
];
