export const garageDetails = [
	{
		garage_id: 0,
		name: ' Caropedia',
		location: { lat: 51.14657, lng: 0.2726 },
		address: '148 Silverdale Road\nTunbridge Wells\nTN4 9HU',
	},
	{
		garage_id: 1,
		name: ' Starlight AutoMall',
		location: { lat: 52.71608, lng: -2.10506 },
		address: '5 Henny Close\nPenkridge\nST19 5TD',
	},
	{
		garage_id: 2,
		name: ' Motor Getawayz',
		location: { lat: 51.56531, lng: -0.14718 },
		address:
			'Unit 207\nMakepeace Mansions\nMakepeace Avenue\nLondon\nN6 6ET',
	},
	{
		garage_id: 3,
		name: ' SuperWheels',
		location: { lat: 51.26536, lng: -0.72257 },
		address: '12 Jubilee Drive\nAsh Vale\nGU12 5JN',
	},
	{
		garage_id: 4,
		name: ' Superior Care Auto Center',
		location: { lat: 51.76461, lng: -5.00855 },
		address: 'Coal Pit Farm\nJohnston\nSA62 3PD',
	},
	{
		garage_id: 5,
		name: ' AutoNation Inc.',
		location: { lat: 53.32468, lng: -2.39778 },
		address: '7 Steadings Rise\nMere\nWA16 0WB',
	},
	{
		garage_id: 6,
		name: ' TruBlue Autos',
		location: { lat: 51.43159, lng: -0.06409 },
		address: '8 Springfield Rise\nLondon\nSE26 6JT',
	},
	{
		garage_id: 7,
		name: ' Wheeler Dealer Enterprises',
		location: { lat: 54.88978, lng: -2.87686 },
		address: '3 Lilac Square\nScotby\nCA4 8BB',
	},
	{
		garage_id: 8,
		name: ' Wonderland Auto Mart',
		location: { lat: 50.36859, lng: -4.14442 },
		address: '31 Princess Street\nPlymouth\nPL1 2RG',
	},
	{
		garage_id: 9,
		name: ' CarMatcher',
		location: { lat: 50.63728, lng: -1.18206 },
		address: '4 Hollybrooke Close\nShanklin\nPO37 7PD',
	},
	{
		garage_id: 10,
		name: ' Car Conveyors',
		location: { lat: 50.8779, lng: 0.56228 },
		address: '5 Blackthorn Close\nSt. Leonards-On-Sea\nTN37 7XR',
	},
	{
		garage_id: 11,
		name: ' Blusky Auto�s',
		location: { lat: 51.61462, lng: -0.33147 },
		address: '1 Brockhurst Close\nStanmore\nHA7 3NP',
	},
	{
		garage_id: 12,
		name: ' Auto Mart',
		location: { lat: 53.30045, lng: -1.10657 },
		address: '27 Featherston Avenue\nWorksop\nS80 2NX',
	},
	{
		garage_id: 13,
		name: ' Car Express',
		location: { lat: 51.55707, lng: -2.57647 },
		address: '10 Tockington Lane\nAlmondsbury\nBS32 4EA',
	},
	{
		garage_id: 14,
		name: ' Car Rentals',
		location: { lat: 52.58293, lng: -1.12358 },
		address: '25 Hansen Court\nWigston\nLE18 2BR',
	},
	{
		garage_id: 15,
		name: ' Square One Auto',
		location: { lat: 50.85345, lng: 0.55532 },
		address:
			'First Floor Unit No 2 And Top Floors\n5 The Uplands\nSt Leonards-On-Sea\nTN38 0HL',
	},
	{
		garage_id: 16,
		name: ' Carzlync',
		location: { lat: 53.53179, lng: -2.42277 },
		address: '34 Cleggs Lane\nLittle Hulton\nM38 9WT',
	},
	{
		garage_id: 17,
		name: ' Choice Auto Mart',
		location: { lat: 53.60836, lng: -2.15 },
		address: '100 Durham Street\nRochdale\nOL11 1LS',
	},
	{
		garage_id: 18,
		name: ' Carster',
		location: { lat: 50.75605, lng: 0.27301 },
		address: 'Unit 24\nKepplestone\nStaveley Road\nEastbourne\nBN20 7JZ',
	},
	{
		garage_id: 19,
		name: ' Old City Motors',
		location: { lat: 51.65772, lng: -0.04925 },
		address: '23 Brick Lane\nEnfield\nEN3 5BD',
	},
	{
		garage_id: 20,
		name: ' Used Car Station',
		location: { lat: 53.33598, lng: -1.51191 },
		address: 'Unit 8\nSandiron House\n453 Abbey Lane\nSheffield\nS7 2QZ',
	},
	{
		garage_id: 21,
		name: ' AutoGem',
		location: { lat: 52.05499, lng: -2.71885 },
		address: 'Premises At\nLittle Berrington Street\nHereford\nHR4 0BS',
	},
	{
		garage_id: 22,
		name: ' Motorhub',
		location: { lat: 51.61981, lng: -3.66299 },
		address: '18 Garnwen Terrace\nMaesteg\nCF34 0ET',
	},
	{
		garage_id: 23,
		name: ' Motorific',
		location: { lat: 53.30042, lng: -4.23702 },
		address: '3 Ffordd Deg\nLlanbedrgoch\nLL76 8TQ',
	},
	{
		garage_id: 24,
		name: ' Royal Motors',
		location: { lat: 52.24086, lng: -4.25826 },
		address: '6 Cylch Aeron\nAberaeron\nSA46 0DN',
	},
	{
		garage_id: 25,
		name: ' Carry Haven',
		location: { lat: 50.30209, lng: -5.19215 },
		address: 'Beacon View\nGoonbell\nTR5 0PQ',
	},
	{
		garage_id: 26,
		name: ' Pacific Auto Sales',
		location: { lat: 54.32267, lng: -2.79838 },
		address: 'Foxwell\nUnderbarrow Road\nUnderbarrow\nLA8 8HB',
	},
	{
		garage_id: 27,
		name: ' Four Tires and a Bumper',
		location: { lat: 50.99976, lng: 0.39615 },
		address: 'Little Tott\nEtchingham Road\nBurwash\nTN19 7BG',
	},
	{
		garage_id: 28,
		name: ' Ride for Life Auto',
		location: { lat: 51.42534, lng: -0.33741 },
		address: '20 Elleray Road\nTeddington\nTW11 0HG',
	},
	{
		garage_id: 29,
		name: ' Car Roma',
		location: { lat: 50.92131, lng: -1.47012 },
		address:
			'Unit 111\nClover Nooke\nOld Redbridge Road\nSouthampton\nSO15 0NH',
	},
	{
		garage_id: 30,
		name: ' Top Tier Motorcars',
		location: { lat: 51.08529, lng: -4.06424 },
		address: '28 Fair View\nBarnstaple\nEX31 1JS',
	},
	{
		garage_id: 31,
		name: ' CaRides Automotive',
		location: { lat: 52.24075, lng: 0.69694 },
		address: '104 West Road\nBury St Edmunds\nIP33 3LJ',
	},
	{
		garage_id: 32,
		name: ' Bestway motors',
		location: { lat: 52.91522, lng: -1.47509 },
		address: '31 Melbourne Street\nDerby\nDE1 2GF',
	},
	{
		garage_id: 33,
		name: ' Car planet Cars',
		location: { lat: 51.7385, lng: 0.31554 },
		address: 'Hoddy Dod Hall\nSpains Hall Road\nWillingale\nCM5 0QD',
	},
	{
		garage_id: 34,
		name: ' SmartMart Motors',
		location: { lat: 52.86763, lng: -2.85908 },
		address: '1 Lawn Cottages\nKenwick Springs\nSY12 0JA',
	},
	{
		garage_id: 35,
		name: ' Car Octane',
		location: { lat: 52.93262, lng: 1.21367 },
		address: '2 Juniper Grove\nSheringham\nNR26 8LX',
	},
	{
		garage_id: 36,
		name: ' CarMix Auto',
		location: { lat: 51.55734, lng: -1.79534 },
		address: '8 Marlborough Street\nSwindon\nSN1 5HL',
	},
	{
		garage_id: 37,
		name: ' Buyright Inc',
		location: { lat: 50.8552, lng: -0.22613 },
		address: '29 Thornhill Rise\nPortslade\nBN41 2YP',
	},
	{
		garage_id: 38,
		name: ' Auto Sales',
		location: { lat: 54.6669, lng: -1.45186 },
		address: '1 Townend\nSedgefield\nTS21 3NW',
	},
	{
		garage_id: 39,
		name: ' Down2 Earth Autos',
		location: { lat: 55.01692, lng: -1.65284 },
		address: '75 Fawdon Park Road\nNewcastle Upon Tyne\nNE3 2PQ',
	},
	{
		garage_id: 40,
		name: ' FineCar Gallery',
		location: { lat: 51.43959, lng: -1.04505 },
		address: '20 Palmera Avenue\nCalcot\nRG31 7DZ',
	},
	{
		garage_id: 41,
		name: ' Silverline Cars',
		location: { lat: 51.52267, lng: -3.51199 },
		address: '6 Brook Vale\nPencoed\nCF35 6LS',
	},
	{
		garage_id: 42,
		name: ' Car Supermarket',
		location: { lat: 53.8298, lng: -3.0069 },
		address: 'Unit 2\nEdgefield Court\nAvenue Road\nBlackpool\nFY3 7SY',
	},
	{
		garage_id: 43,
		name: ' Next Car for You Inc.',
		location: { lat: 54.58494, lng: -1.32343 },
		address: '136 Norton Avenue\nStockton-On-Tees\nTS20 2JX',
	},
	{
		garage_id: 44,
		name: ' Car Paramount',
		location: { lat: 52.70737, lng: -2.73925 },
		address: '11 Monkmoor Road\nShrewsbury\nSY2 5AG',
	},
	{
		garage_id: 45,
		name: ' Car Crusade',
		location: { lat: 51.50819, lng: -0.27793 },
		address: 'Unit 1\n319 Uxbridge Road\nActon\nW3 9QU',
	},
	{
		garage_id: 46,
		name: ' The Car Site',
		location: { lat: 52.11122, lng: -0.50535 },
		address: '118 Williamson Road\nKempston\nMK42 7HR',
	},
	{
		garage_id: 47,
		name: ' Wide World of Cars',
		location: { lat: 55.02497, lng: -1.55378 },
		address: '34 Crossley Terrace\nPalmersville\nNE12 9EQ',
	},
	{
		garage_id: 48,
		name: ' Dart Motors',
		location: { lat: 53.60186, lng: -0.68506 },
		address: '85 Ferry Road West\nScunthorpe\nDN15 8UG',
	},
	{
		garage_id: 49,
		name: ' RallyRides',
		location: { lat: 50.93403, lng: -1.19693 },
		address: '1 Hazel Close\nWaltham Chase\nSO32 2GZ',
	},
	{
		garage_id: 50,
		name: ' PickaRIde',
		location: { lat: 52.26045, lng: -1.53986 },
		address: '16 Duke Of York Avenue\nBishops Tachbrook\nCV33 9WH',
	},
	{
		garage_id: 51,
		name: ' Dream Auto Sales Inc',
		location: { lat: 53.81691, lng: -1.7835 },
		address: '31 Parsons Road\nBradford\nBD9 4AY',
	},
	{
		garage_id: 52,
		name: ' Car Ride',
		location: { lat: 52.33566, lng: -1.9264 },
		address: 'Lower Park Farm\nStorrage Lane\nAlvechurch\nB48 7ER',
	},
	{
		garage_id: 53,
		name: ' Flying Phoenix',
		location: { lat: 53.7418, lng: -2.48226 },
		address: 'Islington Motel\nGreat Bolton Street\nBlackburn\nBB2 3PX',
	},
	{
		garage_id: 54,
		name: ' DriveForce Motors',
		location: { lat: 50.94895, lng: -2.71538 },
		address: 'First Floor Maisonette\n17 South Street\nMontacute\nTA15 6XD',
	},
	{
		garage_id: 55,
		name: ' First Choice Motor',
		location: { lat: 51.67607, lng: -0.39276 },
		address: '88 Bushey Mill Lane\nWatford\nWD24 7QQ',
	},
	{
		garage_id: 56,
		name: ' The Car Masters',
		location: { lat: 51.63816, lng: -3.36041 },
		address: '5 Augustus Street\nYnysybwl\nCF37 3LH',
	},
	{
		garage_id: 57,
		name: ' Motors Caretakers',
		location: { lat: 51.61818, lng: 0.00535 },
		address: '10 Chadwick Avenue\nLondon\nE4 6NE',
	},
	{
		garage_id: 58,
		name: ' Good Rides',
		location: { lat: 52.33943, lng: -0.81932 },
		address: 'The Cedars\nHannington Lane\nWalgrave\nNN6 9QG',
	},
	{
		garage_id: 59,
		name: ' Cars Auto Sale Inc',
		location: { lat: 52.44017, lng: -1.82521 },
		address: '114 Greenwood Avenue\nBirmingham\nB27 7RB',
	},
	{
		garage_id: 60,
		name: ' Best Deal Cars',
		location: { lat: 51.47389, lng: 0.34467 },
		address: '3 Sorrell Court\nGrays\nRM17 6UT',
	},
	{
		garage_id: 61,
		name: ' Car King Autos',
		location: { lat: 52.14981, lng: -3.41321 },
		address: '65 Garth Road\nBuilth Wells\nLD2 3NH',
	},
	{
		garage_id: 62,
		name: ' Above The Rest Auto',
		location: { lat: 53.84493, lng: -0.43797 },
		address: '7 The Old Stables\nBeverley\nHU17 8DN',
	},
	{
		garage_id: 63,
		name: ' Auto Emporium',
		location: { lat: 53.23011, lng: -3.42945 },
		address: '3 Nant Y Patrick\nSt Asaph\nLL17 0BN',
	},
	{
		garage_id: 64,
		name: ' Car Rally',
		location: { lat: 52.55966, lng: -2.01683 },
		address: '1 Delville Close\nWednesbury\nWS10 9EF',
	},
	{
		garage_id: 65,
		name: ' Bloom tires',
		location: { lat: 51.56412, lng: 0.10608 },
		address: '43 Ashgrove Road\nIlford\nIG3 9XF',
	},
	{
		garage_id: 66,
		name: ' Freedom Auto Group',
		location: { lat: 52.73455, lng: -2.47492 },
		address: '9 Home Farm\nPreston\nTF6 6EH',
	},
	{
		garage_id: 67,
		name: ' Select iCar',
		location: { lat: 52.47648, lng: 1.74761 },
		address: 'Unit 6\nThe Hollies\nStanley Street\nLowestoft\nNR32 2DF',
	},
	{
		garage_id: 68,
		name: ' Top Knotch Auto Sales',
		location: { lat: 51.98992, lng: -4.97514 },
		address: '8 Tlysfan\nFishguard\nSA65 9HS',
	},
	{
		garage_id: 69,
		name: ' Low Rider Lovers',
		location: { lat: 51.77974, lng: -1.38091 },
		address: '1 Merton Close\nEynsham\nOX29 4QE',
	},
	{
		garage_id: 70,
		name: ' My Kinda Car',
		location: { lat: 52.65818, lng: -1.56937 },
		address: '1 Manor Drive\nAustrey\nCV9 3FJ',
	},
	{
		garage_id: 71,
		name: ' Carorama',
		location: { lat: 51.76011, lng: -3.40712 },
		address: '38 Pontycapel Road\nCefn Coed\nCF48 2PY',
	},
	{
		garage_id: 72,
		name: ' Loyal Motors',
		location: { lat: 52.56115, lng: -1.91266 },
		address: '225 Beacon Road\nGreat Barr\nB43 7DB',
	},
	{
		garage_id: 73,
		name: ' PrimeRides',
		location: { lat: 51.59278, lng: -0.16862 },
		address: '6 Park Road\nLondon\nN2 8EU',
	},
	{
		garage_id: 74,
		name: ' SureWheels',
		location: { lat: 52.61628, lng: -1.64609 },
		address: '1 Litton\nWilnecote\nB77 4JB',
	},
	{
		garage_id: 75,
		name: ' Open Road Auto Group',
		location: { lat: 52.70258, lng: 0.97985 },
		address: '6 Dereham Road\nSwanton Morley\nNR20 4LU',
	},
	{
		garage_id: 76,
		name: ' All About Auto',
		location: { lat: 51.10821, lng: -0.88352 },
		address: '3 Selborne Way\nWhitehill\nGU35 9BQ',
	},
	{
		garage_id: 77,
		name: ' 4 Wheel Drive',
		location: { lat: 54.03032, lng: -2.80316 },
		address: '27 Lindbergh Avenue\nLancaster\nLA1 5FR',
	},
	{
		garage_id: 78,
		name: ' Carlux',
		location: { lat: 53.40745, lng: -4.35452 },
		address: 'Llwyn Ceris\nBethesda Street\nAmlwch\nLL68 9BA',
	},
	{
		garage_id: 79,
		name: ' AutoSight Motors',
		location: { lat: 51.82455, lng: -0.35395 },
		address: '4 The Appleyard\nHarpenden\nAL5 4FS',
	},
	{
		garage_id: 80,
		name: ' Car o�clock',
		location: { lat: 53.70876, lng: -1.25826 },
		address:
			'Apartment 8\nGrange Court\nFerrybridge Road\nKnottingley\nWF11 8JZ',
	},
	{
		garage_id: 81,
		name: ' Trust Your Ride',
		location: { lat: 52.61258, lng: 1.2668 },
		address: '16 Sunningdale\nNorwich\nNR4 6AQ',
	},
	{
		garage_id: 82,
		name: ' Exotic Motor World',
		location: { lat: 51.56779, lng: 0.22405 },
		address: '8 Maynards\nHornchurch\nRM11 3JS',
	},
	{
		garage_id: 83,
		name: ' Car Maverick',
		location: { lat: 51.3529, lng: -0.03812 },
		address: '199 Selsdon Park Road\nSouth Croydon\nCR2 8JH',
	},
	{
		garage_id: 84,
		name: ' Quality Autos',
		location: { lat: 53.763, lng: -1.86102 },
		address: '2 Alma Cottages\nQueensbury\nBD13 2BX',
	},
	{
		garage_id: 85,
		name: ' Tested & Trusted auto',
		location: { lat: 51.16425, lng: -0.59047 },
		address: 'Stilemans House\nHascombe Road\nGodalming\nGU8 4AB',
	},
	{
		garage_id: 86,
		name: ' Cartastic',
		location: { lat: 51.43463, lng: 0.00625 },
		address: '123 Crutchley Road\nLondon\nSE6 1QJ',
	},
	{
		garage_id: 87,
		name: ' Car Worldwide',
		location: { lat: 51.24864, lng: -0.06711 },
		address: '90 High Street\nGodstone\nRH9 8DP',
	},
	{
		garage_id: 88,
		name: ' Alpha Auto',
		location: { lat: 51.68305, lng: -4.26131 },
		address: 'Gors Bungalow\nCliffe Terrace\nBurry Port\nSA16 0HN',
	},
	{
		garage_id: 89,
		name: ' Blue Light Auto',
		location: { lat: 50.37256, lng: -4.14508 },
		address: '114 Cornwall Street\nPlymouth\nPL1 1NF',
	},
	{
		garage_id: 90,
		name: ' Start n� Go',
		location: { lat: 51.50604, lng: -0.05376 },
		address: 'Unit 14\n4 New Crane Place\nLondon\nE1W 3TS',
	},
	{
		garage_id: 91,
		name: ' Car Care Your way',
		location: { lat: 53.60863, lng: -1.45889 },
		address: '13 Bleakley Avenue\nNotton\nWF4 2NT',
	},
	{
		garage_id: 92,
		name: ' DriveHome Autos',
		location: { lat: 51.47195, lng: -3.18634 },
		address: '171 Clare Road\nCardiff\nCF11 6QR',
	},
	{
		garage_id: 93,
		name: ' Car Connection',
		location: { lat: 52.90612, lng: -2.82851 },
		address: 'Balmer Heath Cottage\nThe Balmer\nSY12 0PR',
	},
	{
		garage_id: 94,
		name: ' Carscape',
		location: { lat: 51.63433, lng: -3.00462 },
		address: '227 James Prosser Way\nLlantarnam\nNP44 3FN',
	},
	{
		garage_id: 95,
		name: ' Quick Auto Lease',
		location: { lat: 53.67247, lng: -0.33826 },
		address: '16 Gatehouse Road\nGoxhill\nDN19 7HP',
	},
	{
		garage_id: 96,
		name: ' Precision Automotive and Diesel',
		location: { lat: 52.41944, lng: -1.7694 },
		address: '25 Shustoke Road\nSolihull\nB91 2NR',
	},
	{
		garage_id: 97,
		name: ' Universal Motors Group',
		location: { lat: 52.18471, lng: -2.48335 },
		address: 'Apartment 19\nEnderby House\nLinton\nHR7 4QJ',
	},
	{
		garage_id: 98,
		name: ' Car Rodeo',
		location: { lat: 51.0825, lng: -4.05934 },
		address: '12 Boutport Street\nBarnstaple\nEX31 1RW',
	},
	{
		garage_id: 99,
		name: ' Valley Stream Used Cars',
		location: { lat: 54.51992, lng: -1.36388 },
		address: '41 Lingfield Drive\nEaglescliffe\nTS16 0NU',
	},
	{
		garage_id: 100,
		name: ' Victory Lane Motors',
		location: { lat: 53.83064, lng: -2.42583 },
		address: '2 Nightingale Close\nWhalley\nBB7 9XB',
	},
	{
		garage_id: 101,
		name: ' Daily Deal Cars',
		location: { lat: 51.30478, lng: -0.73473 },
		address: '4 The Sett\nFrimley Green\nGU16 6BN',
	},
	{
		garage_id: 102,
		name: ' Pinwheel Cars',
		location: { lat: 52.6098, lng: 1.72098 },
		address: '5 Quay Mill Walk\nGreat Yarmouth\nNR30 1JG',
	},
	{
		garage_id: 103,
		name: ' Carsy',
		location: { lat: 50.34187, lng: -4.74866 },
		address: 'Cuddra Farm Cottage\nSt. Austell\nPL25 3RQ',
	},
	{
		garage_id: 104,
		name: ' Car Synergist',
		location: { lat: 54.59089, lng: -1.35599 },
		address: '9 Harvest Drive\nStockton-On-Tees\nTS19 8GQ',
	},
	{
		garage_id: 105,
		name: ' Universal Auto Group',
		location: { lat: 53.44572, lng: -0.80867 },
		address: 'River View\nMain Street\nWest Stockwith\nDN10 4HB',
	},
	{
		garage_id: 106,
		name: ' Stellar Cars',
		location: { lat: 52.68703, lng: -1.93395 },
		address: '93 Rugeley Road\nChase Terrace\nWS7 1AB',
	},
	{
		garage_id: 107,
		name: ' FeelUp Auto',
		location: { lat: 51.34578, lng: -0.05517 },
		address: '11 Kingsway Avenue\nSouth Croydon\nCR2 8NF',
	},
	{
		garage_id: 108,
		name: ' Motor Mania',
		location: { lat: 51.56963, lng: 0.42191 },
		address: '30 High Road\nLaindon\nSS15 6NR',
	},
	{
		garage_id: 109,
		name: ' FanFaire Autos',
		location: { lat: 51.66403, lng: -3.50673 },
		address: '13 Heol Tyle Du\nTreorchy\nCF42 6TA',
	},
	{
		garage_id: 110,
		name: ' MultiCar Auto',
		location: { lat: 53.53352, lng: -1.15315 },
		address: '60 Grove Avenue\nDoncaster\nDN5 9AY',
	},
	{
		garage_id: 111,
		name: ' Car Cabanna',
		location: { lat: 51.64801, lng: -0.18339 },
		address: '14 Whitegates\n100 Station Road\nNew Barnet\nEN5 1QB',
	},
	{
		garage_id: 112,
		name: ' Peachy Rides',
		location: { lat: 52.67722, lng: 1.24062 },
		address: 'Old Keepers Lodge\nHall Lane\nDrayton\nNR8 6HH',
	},
	{
		garage_id: 113,
		name: ' Amigo Motors',
		location: { lat: 51.44741, lng: 0.27581 },
		address: '67 Kingfisher Drive\nGreenhithe\nDA9 9RT',
	},
	{
		garage_id: 114,
		name: ' Almighty Auto',
		location: { lat: 53.40964, lng: -2.15125 },
		address: '10 Rectory Green\nStockport\nSK1 4BS',
	},
	{
		garage_id: 115,
		name: ' Carhut',
		location: { lat: 50.8931, lng: -1.61303 },
		address: 'Bilberry Cottage\nNewtown Road\nMinstead\nSO43 7GD',
	},
	{
		garage_id: 116,
		name: ' New Wave Auto',
		location: { lat: 50.20295, lng: -5.50065 },
		address: 'Whistlers Rest\nHalsetown Road\nSt Ives\nTR26 3AQ',
	},
	{
		garage_id: 117,
		name: ' GotCars',
		location: { lat: 50.87251, lng: -2.46058 },
		address: 'Manorling\nGlanvilles Wootton\nDT9 5QA',
	},
	{
		garage_id: 118,
		name: ' Auto Plaza World',
		location: { lat: 50.45359, lng: -3.56756 },
		address: '70 Preston Down Road\nPreston\nTQ3 1DR',
	},
	{
		garage_id: 119,
		name: ' Blue Ridge Auto',
		location: { lat: 51.45227, lng: 0.10789 },
		address: '46 Tyrrell Avenue\nWelling\nDA16 2BU',
	},
	{
		garage_id: 120,
		name: ' Deals4Wheels Auto',
		location: { lat: 54.51896, lng: -1.47066 },
		address: '6 Water View\nMiddleton St George\nDL2 1JQ',
	},
	{
		garage_id: 121,
		name: ' Vent Bike Tech',
		location: { lat: 52.23709, lng: -1.79987 },
		address: 'The Old Post Office\nChurch Lane\nAston Cantlow\nB95 6JB',
	},
	{
		garage_id: 122,
		name: ' PitStop Auto Gallery',
		location: { lat: 52.27574, lng: -1.91715 },
		address: '20 Ettingley Close\nRedditch\nB98 7UF',
	},
	{
		garage_id: 123,
		name: ' Cruise Pre-owned',
		location: { lat: 52.53396, lng: 1.26432 },
		address: 'Low Meadows\nCargate Lane\nSaxlingham Thorpe\nNR15 1TU',
	},
	{
		garage_id: 124,
		name: ' Car Squad',
		location: { lat: 53.64271, lng: -1.84716 },
		address: '7 Willow Grove\nGolcar\nHD7 4RX',
	},
	{
		garage_id: 125,
		name: ' House Of Cars',
		location: { lat: 51.18624, lng: -2.19569 },
		address: 'Dry Hill House\nDry Hill\nCrockerton\nBA12 8AT',
	},
	{
		garage_id: 126,
		name: ' Deals on Wheels',
		location: { lat: 53.41476, lng: -2.93789 },
		address: '13 Prospect Vale\nLiverpool\nL6 8PE',
	},
	{
		garage_id: 127,
		name: ' Car Oasis',
		location: { lat: 51.34823, lng: -0.09232 },
		address: 'The Garage At\n40 Mayfield Road\nSouth Croydon\nCR2 0BE',
	},
	{
		garage_id: 128,
		name: ' Peak Performance Auto',
		location: { lat: 51.41226, lng: -0.42553 },
		address: '52 Laytons Lane\nSunbury-On-Thames\nTW16 6LR',
	},
	{
		garage_id: 129,
		name: ' Auto Accord',
		location: { lat: 52.38047, lng: 1.08711 },
		address: '9 Lewis Drive\nRoydon\nIP22 5SL',
	},
	{
		garage_id: 130,
		name: ' Dream motors',
		location: { lat: 50.1439, lng: -5.30672 },
		address: 'Chava Cottage\nNancegollan\nTR13 0AJ',
	},
	{
		garage_id: 131,
		name: ' Auto Services Gate',
		location: { lat: 54.59224, lng: -1.7448 },
		address: 'Todkin Cottage\nHilton\nDL2 3JA',
	},
	{
		garage_id: 132,
		name: ' Buy Your Ride',
		location: { lat: 52.08908, lng: -0.26714 },
		address: '8A Shortmead Street\nBiggleswade\nSG18 0AP',
	},
	{
		garage_id: 133,
		name: ' Ride Away Auto Sales',
		location: { lat: 51.99256, lng: -0.7169 },
		address: '3 Laburnam Grove\nBletchley\nMK2 2JW',
	},
	{
		garage_id: 134,
		name: ' Auto Trader',
		location: { lat: 51.76005, lng: -2.37026 },
		address: 'Vicarage Cottage\nFrampton On Severn\nGL2 7EQ',
	},
	{
		garage_id: 135,
		name: ' MightyRides',
		location: { lat: 51.49097, lng: -0.15317 },
		address: 'Ebury Galleries\n200 Ebury Street\nLondon\nSW1W 8UN',
	},
	{
		garage_id: 136,
		name: ' Firehouse Classic Cars',
		location: { lat: 51.51579, lng: -0.15132 },
		address: 'Unit 4\n95 Wigmore Street\nLondon\nW1U 1DL',
	},
	{
		garage_id: 137,
		name: ' General Motors',
		location: { lat: 51.30063, lng: -0.48995 },
		address: 'Barn House\nWhite Horse Lane\nRipley\nGU23 6BJ',
	},
	{
		garage_id: 138,
		name: ' Planet Motors',
		location: { lat: 52.52348, lng: -2.05707 },
		address: '109 Victoria Road\nTipton\nDY4 8SS',
	},
	{
		garage_id: 139,
		name: ' Trust�N�Ride',
		location: { lat: 51.58659, lng: 0.06534 },
		address: '22 Monkswood Gardens\nIlford\nIG5 0DG',
	},
	{
		garage_id: 140,
		name: ' Car City',
		location: { lat: 51.01514, lng: -0.9365 },
		address: 'Tilmore Farmhouse\nHarrow Lane\nSteep\nGU32 2HT',
	},
	{
		garage_id: 141,
		name: ' Car Xpress',
		location: { lat: 51.24858, lng: -0.74234 },
		address: '6 Chestnut Court\nNorth Lane\nAldershot\nGU12 4XH',
	},
	{
		garage_id: 142,
		name: ' Surely Car Junction',
		location: { lat: 51.84986, lng: -5.08914 },
		address: '9 Pilgrims Way\nRoch\nSA62 6AJ',
	},
	{
		garage_id: 143,
		name: ' Dream Wheels Auto Sales',
		location: { lat: 51.47791, lng: -0.0099 },
		address: 'Greenwich Police Station\n31 Royal Hill\nLondon\nSE10 8RR',
	},
	{
		garage_id: 144,
		name: ' Turbo Auto Sales',
		location: { lat: 50.9104, lng: -2.8895 },
		address: 'Applegarth\nDowlish Wake\nTA19 0NY',
	},
	{
		garage_id: 145,
		name: ' Family Wheels',
		location: { lat: 50.41227, lng: -4.08552 },
		address: '2 Pethill Close\nPlymouth\nPL6 8NL',
	},
	{
		garage_id: 146,
		name: ' Good Goals Auto',
		location: { lat: 50.76714, lng: -1.89177 },
		address: '1352 Wimborne Road\nBournemouth\nBH10 7AP',
	},
	{
		garage_id: 147,
		name: ' Car Logistic',
		location: { lat: 51.76467, lng: -0.24924 },
		address: '9 Tiger Moth Way\nHatfield\nAL10 9LT',
	},
	{
		garage_id: 148,
		name: ' Target Auto Centre',
		location: { lat: 51.62315, lng: -3.90898 },
		address: '19 Crymlyn Street\nPort Tennant\nSA1 8NX',
	},
	{
		garage_id: 149,
		name: ' The Drive Fantasy',
		location: { lat: 55.69124, lng: -2.09089 },
		address: 'Mattilees Cottage\nDuddo\nBerwick-Upon-Tweed\nTD15 2NR',
	},
	{
		garage_id: 150,
		name: ' Good Gears',
		location: { lat: 51.39422, lng: -0.2211 },
		address: '31 Glenthorpe Road\nMorden\nSM4 4JP',
	},
	{
		garage_id: 151,
		name: ' Great Motors Co.',
		location: { lat: 52.52613, lng: -1.98451 },
		address: '7 Kiniths Crescent\nWest Bromwich\nB71 4BX',
	},
	{
		garage_id: 152,
		name: ' Bluebird Autos',
		location: { lat: 53.28468, lng: -1.67316 },
		address: '26 Glebe Park\nEyam\nS32 5RH',
	},
	{
		garage_id: 153,
		name: ' Fine Car Gallery',
		location: { lat: 52.45667, lng: -2.15599 },
		address: '2 Hemmings Close\nStourbridge\nDY8 3TH',
	},
	{
		garage_id: 154,
		name: ' Highline Motors',
		location: { lat: 53.75209, lng: -1.53252 },
		address: '15 Newhall Garth\nLeeds\nLS10 3QX',
	},
	{
		garage_id: 155,
		name: ' Prestige Motor Sales Inc.',
		location: { lat: 52.36447, lng: -2.0453 },
		address: '5 Marlbrook Rise\nMarlbrook\nB61 0HW',
	},
	{
		garage_id: 156,
		name: ' CROWN Auto Group',
		location: { lat: 54.32542, lng: -2.48702 },
		address: 'The Barn\nDowbiggin\nLA10 5LR',
	},
	{
		garage_id: 157,
		name: ' Budget Wheelz',
		location: { lat: 50.58207, lng: -3.47704 },
		address: '67 Old Town Street\nDawlish\nEX7 9AR',
	},
	{
		garage_id: 158,
		name: ' Safe n� Speedy',
		location: { lat: 53.18108, lng: -0.00326 },
		address: '2 Sandy Lane\nAsgarby\nPE23 4HY',
	},
	{
		garage_id: 159,
		name: ' Liberty Auto',
		location: { lat: 54.9294, lng: -1.78408 },
		address: '2 School House\nHugar Road\nHigh Spen\nNE39 2BQ',
	},
	{
		garage_id: 160,
		name: ' CarNova',
		location: { lat: 50.15676, lng: -5.08897 },
		address: '32 Trescobeas Road\nFalmouth\nTR11 2JG',
	},
	{
		garage_id: 161,
		name: ' Car Aisle',
		location: { lat: 51.35513, lng: 0.38108 },
		address: 'Gravel Hill Shaw\nLeywood Road\nMeopham\nDA13 0UH',
	},
	{
		garage_id: 162,
		name: ' Windsor Car Dealers',
		location: { lat: 53.43982, lng: -1.26724 },
		address: '30 Moor Lane North\nRavenfield\nS65 4LZ',
	},
	{
		garage_id: 163,
		name: ' Carz One',
		location: { lat: 51.81247, lng: -2.16563 },
		address: 'South Bank\nMill Lane\nCranham\nGL4 8HU',
	},
	{
		garage_id: 164,
		name: ' Car Magnet',
		location: { lat: 53.39572, lng: -2.97821 },
		address: 'Unit 4 New Bird Street\nLiverpool\nL1 0DN',
	},
	{
		garage_id: 165,
		name: ' Car Empire',
		location: { lat: 50.74302, lng: -1.67033 },
		address: '11 Wavendon Avenue\nBarton On Sea\nBH25 7LP',
	},
	{
		garage_id: 166,
		name: ' Hightail Cars',
		location: { lat: 51.51522, lng: -0.104 },
		address: 'Unit D\n1 Fleet Place\nLondon\nEC4M 7RD',
	},
	{
		garage_id: 167,
		name: ' AutoJoy',
		location: { lat: 51.48268, lng: -0.966 },
		address: '6 Grove Mews\nEmmer Green\nRG4 8LA',
	},
	{
		garage_id: 168,
		name: ' Motorcar Classics',
		location: { lat: 52.9881, lng: -2.16071 },
		address: '25 Highfield Drive\nStoke-On-Trent\nST3 2BU',
	},
	{
		garage_id: 169,
		name: ' Everyday Autos',
		location: { lat: 52.49262, lng: -1.91291 },
		address: 'Hockley Delivery Office\n23 Hockley Hill\nHockley\nB18 5AP',
	},
	{
		garage_id: 170,
		name: ' Drive 4Ward',
		location: { lat: 51.49148, lng: 0.15103 },
		address:
			'Unit 1\nMary Slessor House\nPicardy Street\nBelvedere\nDA17 5QG',
	},
	{
		garage_id: 171,
		name: ' Alpha Betta Cars',
		location: { lat: 53.50512, lng: -2.01605 },
		address: '6 Calico Crescent\nCarrbrook\nSK15 3FJ',
	},
	{
		garage_id: 172,
		name: ' MainPlace Motors',
		location: { lat: 51.33086, lng: -0.26061 },
		address:
			'Unit 4\nCharles Stuart House\n28 Church Street\nEpsom\nKT17 4QB',
	},
	{
		garage_id: 173,
		name: ' Car Mainstay',
		location: { lat: 52.24611, lng: -0.96278 },
		address: 'Northamptonshire Country Centre\nDuston\nNN5 4BB',
	},
	{
		garage_id: 174,
		name: ' Five Star Automotive',
		location: { lat: 50.6838, lng: -2.11464 },
		address: '1B Westport Road\nWareham\nBH20 4PR',
	},
	{
		garage_id: 175,
		name: ' MasterKey Motors',
		location: { lat: 51.54841, lng: -0.00298 },
		address: '148 Leyton Road\nLondon\nE15 1DR',
	},
	{
		garage_id: 176,
		name: ' Cottage Cars',
		location: { lat: 51.57491, lng: 0.5619 },
		address: '3 Warwick Close\nBenfleet\nSS7 4JB',
	},
	{
		garage_id: 177,
		name: ' Car Turismo',
		location: { lat: 51.75426, lng: 0.08933 },
		address: '198 Shawbridge\nHarlow\nCM19 4NT',
	},
	{
		garage_id: 178,
		name: ' BrightSpot Autos',
		location: { lat: 52.04956, lng: -0.68147 },
		address: '16 Panama Lane\nBrooklands\nMK10 7JY',
	},
	{
		garage_id: 179,
		name: ' TruPath Motors',
		location: { lat: 53.3874, lng: -2.66015 },
		address: '59 Meeting Lane\nPenketh\nWA5 2QU',
	},
	{
		garage_id: 180,
		name: ' We Buy Motors',
		location: { lat: 52.70187, lng: 1.70286 },
		address: '22 Long Beach Estate\nHemsby\nNR29 4JD',
	},
	{
		garage_id: 181,
		name: ' Cargenics',
		location: { lat: 52.93919, lng: -1.43461 },
		address: '5 Chinley Road\nChaddesden\nDE21 4PU',
	},
	{
		garage_id: 182,
		name: ' Rolling Wheels Motors',
		location: { lat: 52.916, lng: -2.65331 },
		address: 'Rosewood\nHeathwood Road\nHigher Heath\nSY13 2HH',
	},
	{
		garage_id: 183,
		name: ' Car o�clock',
		location: { lat: 50.23387, lng: -5.22643 },
		address: '51A Fore Street\nRedruth\nTR15 2AF',
	},
	{
		garage_id: 184,
		name: ' Carzilla',
		location: { lat: 52.08294, lng: -2.63725 },
		address: 'Golden Dale\nWhitestone\nHR1 3SB',
	},
	{
		garage_id: 185,
		name: ' Auto Trader Company',
		location: { lat: 52.89585, lng: -1.44013 },
		address: '7 Griffin Close\nAlvaston\nDE24 8XG',
	},
	{
		garage_id: 186,
		name: ' Next Ride',
		location: { lat: 53.88256, lng: -3.01305 },
		address: '10 Cookson Road\nThornton-Cleveleys\nFY5 2RL',
	},
	{
		garage_id: 187,
		name: ' Carology',
		location: { lat: 53.45529, lng: -2.14393 },
		address: '45 Ashwood Avenue\nDenton\nM34 2PA',
	},
	{
		garage_id: 188,
		name: ' Sky Motors',
		location: { lat: 53.37995, lng: -2.94592 },
		address: '37 Lucerne Street\nLiverpool\nL17 8XT',
	},
	{
		garage_id: 189,
		name: ' Landmark Motors',
		location: { lat: 51.44116, lng: 0.50524 },
		address: 'Perry Hill Farmhouse\nPerry Hill\nCliffe\nME3 7TX',
	},
	{
		garage_id: 190,
		name: ' Motors Corp',
		location: { lat: 53.29154, lng: -4.22219 },
		address: '1 Helens Crescent\nPentraeth\nLL75 8DJ',
	},
	{
		garage_id: 191,
		name: ' Car Castle',
		location: { lat: 54.06957, lng: -2.27688 },
		address: '3 Howsons Yard\nSettle\nBD24 9EG',
	},
	{
		garage_id: 192,
		name: ' Motorway',
		location: { lat: 51.55124, lng: -0.08659 },
		address: 'Unit B\n4 Ferntower Road\nLondon\nN5 2JG',
	},
	{
		garage_id: 193,
		name: ' Car Classic',
		location: { lat: 55.33215, lng: -1.58815 },
		address: '12 Albert Street\nAmble\nNE65 0LT',
	},
	{
		garage_id: 194,
		name: ' Four-Wheel deal',
		location: { lat: 51.61223, lng: -0.31958 },
		address: '11 Salmond Close\nStanmore\nHA7 3SR',
	},
	{
		garage_id: 195,
		name: ' Car Valley Group',
		location: { lat: 50.98308, lng: -0.64984 },
		address: 'South Dean Barn\nSouth Lane\nTillington\nGU28 0RF',
	},
	{
		garage_id: 196,
		name: ' House of Cars � McKnight',
		location: { lat: 52.45715, lng: -4.01063 },
		address: 'Hendre Gwyn\nDole\nLlandre\nSY24 5AE',
	},
	{
		garage_id: 197,
		name: ' Sunrise Auto Sales',
		location: { lat: 54.48375, lng: -1.23163 },
		address: '9 Leconfield\nSeamer\nTS9 5PP',
	},
	{
		garage_id: 198,
		name: ' Suregood Autos',
		location: { lat: 51.58981, lng: -0.56423 },
		address: '22 Bull Lane\nChalfont St Peter\nSL9 8RA',
	},
	{
		garage_id: 199,
		name: ' Car Deals Direct',
		location: { lat: 51.68611, lng: -0.31568 },
		address: '5 Broadlands\nHillside Road\nRadlett\nWD7 7BX',
	},
	{
		garage_id: 200,
		name: ' Steals n� Deals',
		location: { lat: 50.85517, lng: -0.28988 },
		address: 'Old Erringham Hall\nSteyning Road\nShoreham-By-Sea\nBN43 5FD',
	},
	{
		garage_id: 201,
		name: ' A1 Motor Mart',
		location: { lat: 51.44142, lng: -2.36667 },
		address: 'The Beeches\nNimlet\nCold Ashton\nSN14 8JX',
	},
	{
		garage_id: 202,
		name: ' The Car Land',
		location: { lat: 52.19709, lng: -4.29031 },
		address: 'Pantcefn\nLlanarth\nSA47 0QY',
	},
	{
		garage_id: 203,
		name: ' Wonder Automotive',
		location: { lat: 51.52498, lng: -0.19481 },
		address: '48A Oakington Road\nLondon\nW9 2DH',
	},
	{
		garage_id: 204,
		name: ' Cars Centre',
		location: { lat: 52.32623, lng: -2.07193 },
		address: 'Unit 3\n28 Rock Hill\nBromsgrove\nB61 7LJ',
	},
	{
		garage_id: 205,
		name: ' Seasoned Motors',
		location: { lat: 53.80729, lng: -3.01359 },
		address: '86 Lancaster Road\nBlackpool\nFY3 9ST',
	},
	{
		garage_id: 206,
		name: ' One Stop Automative',
		location: { lat: 52.95761, lng: -1.30011 },
		address: '6 Nelper Crescent\nIlkeston\nDE7 4DU',
	},
	{
		garage_id: 207,
		name: ' Driving Force',
		location: { lat: 52.2536, lng: -2.46178 },
		address: 'Rose Furlong\nHolly Bush Lane\nClifton On Teme\nWR6 6HQ',
	},
	{
		garage_id: 208,
		name: ' Adopt a Car',
		location: { lat: 54.96485, lng: -2.47248 },
		address: '3 Stoney Rigg Close\nHaltwhistle\nNE49 0JT',
	},
	{
		garage_id: 209,
		name: ' Fair&Square Motors',
		location: { lat: 51.74617, lng: -0.83459 },
		address: 'Orchard Farm House\nMeadle\nHP17 9UD',
	},
	{
		garage_id: 210,
		name: ' Ace Motor Co',
		location: { lat: 50.7949, lng: -1.18867 },
		address: '63 Wootton Road\nLee-On-The-Solent\nPO13 9HA',
	},
	{
		garage_id: 211,
		name: ' Car Galleria',
		location: { lat: 53.62735, lng: -2.22346 },
		address: '4 Four Lanes Way\nRochdale\nOL11 5TL',
	},
	{
		garage_id: 212,
		name: ' Greenway Automotive',
		location: { lat: 51.38105, lng: -0.30836 },
		address: '22 Mandeville Drive\nSurbiton\nKT6 5DT',
	},
	{
		garage_id: 213,
		name: ' Highway Motors',
		location: { lat: 50.3157, lng: -4.01657 },
		address: '11 Perches Close\nMembland\nPL8 1HZ',
	},
	{
		garage_id: 214,
		name: ' Bay Auto Group',
		location: { lat: 53.47216, lng: -2.88689 },
		address: '57 Cawthorne Avenue\nLiverpool\nL32 3XF',
	},
	{
		garage_id: 215,
		name: ' CarMax',
		location: { lat: 53.49709, lng: -2.4476 },
		address: '7 Corless Fold\nAstley\nM29 7QR',
	},
	{
		garage_id: 216,
		name: ' Inter Cars',
		location: { lat: 52.07297, lng: -0.77087 },
		address: '1 Shepherds Hey\nOakridge Park\nMK14 6GH',
	},
	{
		garage_id: 217,
		name: ' Auto Xperts',
		location: { lat: 51.59188, lng: -3.47346 },
		address: '5 Etna Terrace\nGilfach Goch\nCF39 8SU',
	},
	{
		garage_id: 218,
		name: ' Ride Time',
		location: { lat: 51.04129, lng: 0.64212 },
		address: '21 Maytham Road\nRolvenden Layne\nTN17 4NS',
	},
	{
		garage_id: 219,
		name: ' Greatway Cars',
		location: { lat: 51.5608, lng: 0.07367 },
		address: '61 Ley Street\nIlford\nIG1 4BG',
	},
	{
		garage_id: 220,
		name: ' Carporium',
		location: { lat: 51.72387, lng: -0.83274 },
		address: '5 Town Farm Barns\nPrinces Risborough\nHP27 9AD',
	},
	{
		garage_id: 221,
		name: ' Valley Road Motors',
		location: { lat: 51.40022, lng: -3.2675 },
		address: 'Unit 40\nGlan Y Mor\nY Rhodfa\nBarry\nCF63 4BB',
	},
	{
		garage_id: 222,
		name: ' Easy Choice Motors',
		location: { lat: 54.29478, lng: -3.39658 },
		address: 'Pine View\nBootle Station\nLA19 5XD',
	},
	{
		garage_id: 223,
		name: ' Clutch Auto Sales',
		location: { lat: 51.7156, lng: -4.73287 },
		address: 'Beaconing View\nWooden\nSaundersfoot\nSA69 9DY',
	},
	{
		garage_id: 224,
		name: ' Road Runners',
		location: { lat: 54.11853, lng: -1.18561 },
		address: '25A Galtres Drive\nEasingwold\nYO61 3DJ',
	},
	{
		garage_id: 225,
		name: ' Car Cardinal',
		location: { lat: 51.40129, lng: -0.12884 },
		address: '58 Glamorgan Close\nMitcham\nCR4 1XH',
	},
	{
		garage_id: 226,
		name: ' Car4Everyone',
		location: { lat: 52.4037, lng: -1.58034 },
		address:
			'Unit 1\nJoseph Holt House\nJames Green Road\nCoventry\nCV4 9SR',
	},
	{
		garage_id: 227,
		name: ' Car For Life',
		location: { lat: 51.58719, lng: -0.27961 },
		address: '98 Berkeley Road\nLondon\nNW9 9DG',
	},
	{
		garage_id: 228,
		name: ' Journey Starters',
		location: { lat: 53.71614, lng: -1.46578 },
		address: '468A Aberford Road\nStanley\nWF3 4AG',
	},
	{
		garage_id: 229,
		name: ' Car Corp',
		location: { lat: 51.51021, lng: -2.62257 },
		address: '56 Standfast Road\nBristol\nBS10 7HJ',
	},
	{
		garage_id: 230,
		name: ' Top Wheelz Automotive',
		location: { lat: 54.85942, lng: -1.8085 },
		address: 'Unit 4\n15 Front Street\nLeadgate\nDH8 7SA',
	},
	{
		garage_id: 231,
		name: ' DrivingForce Motors',
		location: { lat: 52.49488, lng: -1.80726 },
		address: '173 Stechford Road\nBirmingham\nB34 6AS',
	},
	{
		garage_id: 232,
		name: ' Hot Wheels',
		location: { lat: 51.86883, lng: 0.98913 },
		address: 'Birds Farm\nSchool Lane\nElmstead\nCO7 7EY',
	},
	{
		garage_id: 233,
		name: ' Creative Drive Cars',
		location: { lat: 50.83505, lng: -0.23737 },
		address: '38 The Green\nSouthwick\nBN42 4FR',
	},
	{
		garage_id: 234,
		name: ' August Luxury Motorcars',
		location: { lat: 55.0216, lng: -1.65409 },
		address: '20 Newstead Road\nNewcastle Upon Tyne\nNE13 9AX',
	},
	{
		garage_id: 235,
		name: ' KingCrown Motors',
		location: { lat: 55.0005, lng: -1.57966 },
		address: '80 Benton Road\nNewcastle Upon Tyne\nNE7 7DX',
	},
	{
		garage_id: 236,
		name: ' Drive for Ward',
		location: { lat: 53.62998, lng: -2.65825 },
		address: '85 Pear Tree Avenue\nCoppull\nPR7 4NL',
	},
	{
		garage_id: 237,
		name: ' Prestige Cars Kent',
		location: { lat: 51.48488, lng: -0.16394 },
		address: '28 Cheyne Court\nLondon\nSW3 5TR',
	},
	{
		garage_id: 238,
		name: ' Revolution Auto Group',
		location: { lat: 52.70651, lng: -1.68903 },
		address: 'Lilac Cottage\nSchool Lane\nEdingale\nB79 9JJ',
	},
	{
		garage_id: 239,
		name: ' Car Locus Limited',
		location: { lat: 51.51192, lng: -0.31681 },
		address: 'Unit 7\nEltham Court\nBerwick Close\nLondon\nW13 8BL',
	},
	{
		garage_id: 240,
		name: ' Auto Trade Corporation',
		location: { lat: 51.52346, lng: -0.11535 },
		address: 'B1 Unit B\n4 Brownlow Mews\nLondon\nWC1N 2LD',
	},
	{
		garage_id: 241,
		name: ' FourSquare Motors',
		location: { lat: 51.45484, lng: -2.58686 },
		address: 'Kings Orchard\n1 Queen Street\nSt Philips\nBS2 0HQ',
	},
	{
		garage_id: 242,
		name: ' Deals for Wheels',
		location: { lat: 53.65056, lng: -2.64668 },
		address: '16 Heathrow Place\nChorley\nPR7 2QX',
	},
	{
		garage_id: 243,
		name: ' Exotic Automotive Group',
		location: { lat: 51.96855, lng: 0.38314 },
		address: 'Great Clarks Farm Cottage\nLittle Sampford\nCB10 2QJ',
	},
	{
		garage_id: 244,
		name: ' Ideal Choice Cars',
		location: { lat: 52.42122, lng: -1.53097 },
		address: '25 Christchurch Road\nCoventry\nCV6 1JU',
	},
	{
		garage_id: 245,
		name: ' Galaxy Autos',
		location: { lat: 51.5536, lng: 0.01691 },
		address: '24 Vansittart Road\nLondon\nE7 0AS',
	},
	{
		garage_id: 246,
		name: ' Alpha Rider',
		location: { lat: 51.52706, lng: -0.17174 },
		address: '120 Lisson Grove\nLondon\nNW8 8LB',
	},
	{
		garage_id: 247,
		name: ' AutoTown',
		location: { lat: 50.82785, lng: -4.51635 },
		address: 'The Danes\nBowden\nStratton\nEX23 9BJ',
	},
	{
		garage_id: 248,
		name: ' Wheel City Auto',
		location: { lat: 51.42873, lng: -0.15894 },
		address: '203 Franciscan Road\nLondon\nSW17 8HJ',
	},
	{
		garage_id: 249,
		name: ' Auto Town',
		location: { lat: 52.60158, lng: -0.26394 },
		address: '20 Council Street\nWalton\nPE4 6AQ',
	},
];
