import React from 'react';
import CarVisualisation from './CarVisualisation';

function RecommendationTab({ carDetails, parts: tabName }) {
	const {
		service_report,
		create_date,
		health_description,
		parts: carParts,
		email,
	} = carDetails;

	let description = health_description;
	let recommendation = service_report;

	if (tabName !== 'all') {
		const carPart = carParts.find(
			(item) => item.name.toLowerCase() === tabName
		);

		if (carPart) {
			description = carPart.description;
			recommendation = carPart.recommendation;
		}
	}

	return (
		<div className='recommendation-tab'>
			<p className='recommendation-tab__description'>{description}</p>
			<div className='recommendation-tab__image'>
				<CarVisualisation
					lastServiced={create_date}
					tabName={tabName}
				/>
			</div>

			<div className='recommendation-tab__recommendation'>
				<h3 className='recommendation-tab__recommendation-title'>
					More offerings
				</h3>
				<div className='recommendation-tab__recommendation-content'>
					<p className='recommendation-tab__recommendation-text'>
						{recommendation}
					</p>
					<a
						href={`mailto: ${email}`}
						className='recommendation-tab__email-link button button--secondary'
					>
						Send customer email
					</a>
				</div>
			</div>
		</div>
	);
}

export default RecommendationTab;
