import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as L from 'leaflet';
import { mapMarker } from '../../consts';

const centerUK = [54.8133, -4.1883];

const LeafIcon = L.Icon.extend({
	options: {},
});

const iconLow = new LeafIcon({
	iconUrl: mapMarker,
	iconSize: [30, 35],
	className: 'live-fleet-map__icon--low',
});

function LiveFleetMap({ pageData }) {
	if (!pageData) {
		return null;
	}

	// NOTE: Important! Always set the map container height explicitly
	return (
		<div className='live-fleet-map'>
			<h3 className='live-fleet-map__header'>Live fleet monitor</h3>

			<div
				className='live-fleet-monitor'
				style={{ height: '400px', width: '100%' }}
			>
				<MapContainer
					center={centerUK}
					zoom={5}
					attributionControl={false}
					position='bottomright'
				>
					<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
					{pageData.map((item, index) => {
						const {
							location,
							urgency,
							vin,
							customer_name,
							car_make,
							car_model,
						} = item;

						return (
							<Marker
								position={[location.lat, location.lng]}
								icon={iconLow}
								key={`${vin}${index}`}
							>
								<Popup>
									<div className='live-fleet-map__popup'>
										<p className='live-fleet-map__popup-customer'>
											{customer_name}
										</p>
										<p className='live-fleet-map__popup-car'>
											{car_make}, {car_model}
										</p>
										<p className='live-fleet-map__popup-urgency'>
											Service urgency:{' '}
											<strong>{urgency}</strong>
										</p>
									</div>
								</Popup>
							</Marker>
						);
					})}
				</MapContainer>
			</div>
		</div>
	);
}

export default LiveFleetMap;
