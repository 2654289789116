import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './react/pages/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);

// TODO: Set up Webpack build
// TODO: Tidy up codebase / assets
// TODO: Move JS to its own folder
// TODO: Complete todos
