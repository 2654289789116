import React from 'react';
import {
	S1F0BVK1,
	S1F0S4EG,
	S1F03YZM,
	S1F13H80,
	S1F135TN,
	W1F1B0KF,
	W1F1CJ1K,
	Z1F14BGY,
} from '../../consts';

function QuicksightChart({ imageName }) {
	let quicksightImage;

	switch (imageName) {
		case 'S1F0BVK1':
			quicksightImage = S1F0BVK1;
			break;
		case 'S1F0S4EG':
			quicksightImage = S1F0S4EG;
			break;
		case 'S1F03YZM':
			quicksightImage = S1F03YZM;
			break;
		case 'S1F13H80':
			quicksightImage = S1F13H80;
			break;
		case 'S1F135TN':
			quicksightImage = S1F135TN;
			break;
		case 'W1F1B0KF':
			quicksightImage = W1F1B0KF;
			break;
		case 'W1F1CJ1K':
			quicksightImage = W1F1CJ1K;
			break;
		case 'Z1F14BGY':
			quicksightImage = Z1F14BGY;
			break;
		default:
			quicksightImage = S1F0BVK1;
	}

	return (
		<div className='quicksight-chart'>
			<img
				src={quicksightImage}
				alt='Quicksight chart'
				className='quicksight-chart__image'
				width={1299}
				height={429}
			/>
		</div>
	);
}

export default QuicksightChart;
