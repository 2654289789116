import React, { useEffect, useState } from 'react';
import { Calendar, Select } from 'antd';
import {
	garageAvailability,
	garageDetails,
	garageStock,
	timeSlots,
} from '../../data';
import { getDistanceFromLatLonInKm } from '../../utils';

function BookingWidget({ bookingDetails }) {
	const { location: customerLocation, parts } = bookingDetails;
	const [availableGarages, setAvailableGarages] = useState([]);
	const [selectedGarage, setSelectedGarage] = useState({});
	const [locationOptions, setLocationOptions] = useState([]);
	const [timeSlotOptions, setTimeSlotOptions] = useState([]);

	useEffect(() => {
		let nearbyGarages = [];

		// Find garages within a certain range - 500km
		if (customerLocation && parts) {
			garageDetails.forEach((garage) => {
				const distance = getDistanceFromLatLonInKm(
					customerLocation.lat,
					customerLocation.lng,
					garage.location.lat,
					garage.location.lng
				);

				if (distance < 500) {
					nearbyGarages.push({ ...garage, distance: distance });
				}
			});

			// Merge nearby garageDetails with availability and stock and filter
			// out unavailable garages and those where parts are unavailable of
			// out of stock and sort by distance
			const garages = nearbyGarages
				.map((garage) => {
					const { garage_id } = garage;
					const availabilityInfo = garageAvailability.find(
						(item) => garage_id === item.garage_id
					);
					const stockInfo = garageStock.find(
						(item) => garage_id === item.garage_id
					);

					return {
						garage_id,
						...garage,
						...availabilityInfo,
						...stockInfo,
					};
				})
				.filter((garage) => garage.availability_flag)
				.filter((garage) => {
					// FIXME: Not checking if ALL parts required in stock, just if any are
					// FIXME: Part_id check is using !== instead
					// of === because part_ids don't exist in mock data
					const hasParts = parts.some(
						(item) =>
							item.id !== garage.part_id && garage.quantity > 0
					);

					return hasParts;
				})
				.sort((itemA, itemB) => itemA.distance - itemB.distance);

			setAvailableGarages(garages);
			setSelectedGarage(garages[0]);
		}
		// eslint-disable-next-line
	}, [setAvailableGarages, setSelectedGarage, customerLocation]);

	useEffect(() => {
		if (availableGarages.length) {
			setLocationOptions(
				availableGarages.map((item) => {
					return { value: item.garage_id, label: item.name };
				})
			);

			setTimeSlotOptions(
				timeSlots.map((item) => {
					return {
						value: item.time,
						label: item.time,
						disabled: !item.available,
					};
				})
			);
		}
	}, [availableGarages]);

	const handleChange = (value) => {
		const garage = availableGarages.find(
			(item) => item.garage_id === value
		);

		setSelectedGarage(garage);
	};

	if (
		!customerLocation ||
		!locationOptions.length ||
		!timeSlotOptions.length
	) {
		return null;
	}

	return (
		<div className='booking-widget'>
			<h4 className='booking-widget__header'>
				Service centre availability
			</h4>

			<Select
				defaultValue={`${locationOptions[0].label} (nearest)`}
				style={{
					width: 200,
				}}
				onChange={handleChange}
				options={locationOptions}
			/>

			<div className='booking-widget__calendar'>
				<Calendar fullscreen={false} />
				<div className='booking-widget__book'>
					<Select
						defaultValue={timeSlotOptions[0].label}
						style={{
							width: 100,
						}}
						options={timeSlotOptions}
					/>
					<button className='booking-widget__book-button'>
						Book slot
					</button>
				</div>
			</div>

			<div className='booking-widget__parts'>
				<h4 className='booking-widget__parts-header'>
					Parts required for service
				</h4>
				<p className='booking-widget__parts-address'>
					{selectedGarage.address}
				</p>
				<div className='booking-widget__stock'>
					<p className='booking-widget__stock-location'>In stock</p>
					<button className='booking-widget__stock-reserve-button button button--secondary'>
						Reserve parts
					</button>
				</div>
			</div>
		</div>
	);
}

export default BookingWidget;
