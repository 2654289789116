import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { carHealth } from '../../data';
import { getDataByUrgency } from '../../utils';
import {
	CRITICAL_URGENCY,
	HIGH_URGENCY,
	MEDIUM_URGENCY,
	BASE_HEX,
	CRITICAL_HEX,
	HIGH_HEX,
	MEDIUM_HEX,
} from '../../consts';

function ServiceUrgencyChart() {
	const criticalUrgencyChart = useRef();
	const highUrgencyChart = useRef();
	const mediumUrgencyChart = useRef();
	const totalFleetCount = carHealth.length;

	const createCharts = (chartRef, urgency) => {
		let chartColor;

		switch (urgency) {
			case CRITICAL_URGENCY:
				chartColor = CRITICAL_HEX;
				break;
			case HIGH_URGENCY:
				chartColor = HIGH_HEX;
				break;
			case MEDIUM_URGENCY:
				chartColor = MEDIUM_HEX;
				break;
			default:
				chartColor = BASE_HEX;
		}

		if (chartRef) {
			new Chart(chartRef.current, {
				type: 'doughnut',
				data: {
					datasets: [
						{
							data: [
								getDataByUrgency(urgency, carHealth).length,
								totalFleetCount,
							],
							backgroundColor: [chartColor, BASE_HEX],
							hoverOffset: 0,
						},
					],
				},
				options: {
					datasets: {
						doughnut: {
							borderAlign: 'inner',
							borderJoinStyle: 'round',
							borderRadius: 100,
							borderWidth: 0,
							cutout: '85%',
							spacing: 5,
						},
					},
					plugins: {
						tooltip: {
							enabled: false,
						},
					},
				},
			});
		}
	};

	useEffect(() => {
		createCharts(criticalUrgencyChart, CRITICAL_URGENCY);
		createCharts(highUrgencyChart, HIGH_URGENCY);
		createCharts(mediumUrgencyChart, MEDIUM_URGENCY);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='service-urgency-chart'>
			<h3 className='service-urgency-chart__header'>Service urgency</h3>

			<div className='service-urgency-chart__items'>
				<div className='service-urgency-chart__item service-urgency-chart__item--critical'>
					<canvas
						ref={criticalUrgencyChart}
						id='critical-urgency-chart'
						className='service-urgency-chart__chart'
					></canvas>
					<div className='service-urgency-chart__count'>
						<p className='service-urgency-chart__count-number'>
							{getDataByUrgency(CRITICAL_URGENCY, carHealth)
								.length || 0}
						</p>
					</div>

					<p className='service-urgency-chart__status'>Critical</p>
				</div>
				<div className='service-urgency-chart__item service-urgency-chart__item--high'>
					<canvas
						ref={highUrgencyChart}
						id='high-urgency-chart'
						className='service-urgency-chart__chart'
					></canvas>

					<div className='service-urgency-chart__count'>
						<p className='service-urgency-chart__count-number'>
							{getDataByUrgency(HIGH_URGENCY, carHealth).length ||
								0}
						</p>
					</div>

					<p className='service-urgency-chart__status'>High</p>
				</div>
				<div className='service-urgency-chart__item service-urgency-chart__item--medium'>
					<canvas
						ref={mediumUrgencyChart}
						id='medium-urgency-chart'
						className='service-urgency-chart__chart'
					></canvas>
					<div className='service-urgency-chart__count'>
						<p className='service-urgency-chart__count-number'>
							{getDataByUrgency(MEDIUM_URGENCY, carHealth)
								.length || 0}
						</p>
					</div>

					<p className='service-urgency-chart__status'>Medium</p>
				</div>
			</div>
		</div>
	);
}

export default ServiceUrgencyChart;
