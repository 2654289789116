import React from 'react';

function VehicleDetailHeader({ customerDetails }) {
	const { customer_name, car_model, car_make, phone_number, year } =
		customerDetails;

	return (
		<div className='vehicle-detail-header'>
			<div className='vehicle-detail-header__details'>
				<h2 className='vehicle-detail-header__customer'>
					{customer_name}
				</h2>
				<h3 className='vehicle-detail-header__model'>
					{car_make}, {car_model} {year}
				</h3>
			</div>

			<div className='vehicle-detail-header__contact'>
				<a
					href={`tel:${phone_number}`}
					className='button button--primary'
				>
					Contact customer
				</a>
			</div>
		</div>
	);
}

export default VehicleDetailHeader;
