export const carDetails = [
	{
		vin: '82HFE9767U326DEZ2',
		customer_id: 0,
		model_id: 3,
		service_report:
			'Iusto autem nostrum illo eum temporibus. Non accusantium quos dolorum reiciendis. Vitae quaerat quibusdam. Dolorem consectetur repellendus.',
	},
	{
		vin: 'CTEVH1AU0MH0DM76R',
		customer_id: 0,
		model_id: 0,
		service_report:
			'Iste amet mollitia qui fugiat nemo deleniti voluptatibus. Neque iure ab eius.',
	},
	{
		vin: '7NNG3W8R26JPC53EJ',
		customer_id: 0,
		model_id: 8,
		service_report:
			'Ullam architecto dolorum temporibus quas quos. Veritatis illo a cupiditate incidunt quasi.',
	},
	{
		vin: '6E7RHWPAXPNDH5AFA',
		customer_id: 1,
		model_id: 7,
		service_report:
			'Provident iste recusandae maiores consequuntur sapiente nobis.',
	},
	{
		vin: 'WRHEL4E38LSH5DLDY',
		customer_id: 2,
		model_id: 7,
		service_report:
			'Molestias quidem repudiandae deleniti saepe odit. Illum ipsum suscipit.',
	},
	{
		vin: 'SW0G9FGU5SPE9ZY64',
		customer_id: 2,
		model_id: 4,
		service_report:
			'Laboriosam excepturi ducimus nemo. Commodi soluta quasi esse tenetur quisquam. Tenetur doloribus assumenda ex.',
	},
	{
		vin: '80AU5RRW2G18HM8JU',
		customer_id: 2,
		model_id: 3,
		service_report:
			'Provident necessitatibus accusamus dolore repellat similique iste laudantium. Aspernatur laborum ad adipisci illum iste animi. Esse consectetur tenetur praesentium a at hic.',
	},
	{
		vin: 'AW1GZBZ76KZC0PA1L',
		customer_id: 3,
		model_id: 17,
		service_report:
			'Quis occaecati nihil distinctio ipsum. Ea quidem iste rerum itaque sint ipsa. Ab eius est velit.',
	},
	{
		vin: 'Y28PKF4F866Y599XA',
		customer_id: 3,
		model_id: 2,
		service_report:
			'Perspiciatis pariatur doloremque aspernatur quis quis aliquid. Debitis neque in repudiandae quas. Totam voluptates tempore eaque sit blanditiis. Error eligendi modi.',
	},
	{
		vin: 'GUDCKSPVXV8FE5M2E',
		customer_id: 3,
		model_id: 18,
		service_report:
			'Esse sit ipsa reiciendis sapiente molestiae voluptatibus harum.',
	},
	{
		vin: 'E154E53M95ZFHYD9X',
		customer_id: 4,
		model_id: 13,
		service_report:
			'Cum amet nisi sed tempora. Repellendus quaerat error labore rerum autem. Laborum voluptatibus labore.',
	},
	{
		vin: 'FXTC77UNXUTW474SM',
		customer_id: 5,
		model_id: 1,
		service_report:
			'Facere perferendis expedita exercitationem corrupti natus iste. Harum nemo eos excepturi.',
	},
	{
		vin: '7FCCV9UB3HWF5V741',
		customer_id: 6,
		model_id: 0,
		service_report: 'Neque non ducimus placeat minus.',
	},
	{
		vin: '6FATYXDS24AR1RGWJ',
		customer_id: 6,
		model_id: 2,
		service_report:
			'Accusamus inventore quam quas delectus sint. Architecto maxime ut quos labore numquam. Sunt tempora ipsa fugiat aliquam omnis.',
	},
	{
		vin: 'UY0CJD441L44XZA4Z',
		customer_id: 6,
		model_id: 6,
		service_report:
			'Tempora illo fugiat libero nihil sunt repellat. Architecto quos animi ipsa dolores facere. Mollitia in deleniti recusandae.',
	},
	{
		vin: '6B55FS8F236TLGDLF',
		customer_id: 7,
		model_id: 7,
		service_report:
			'Vitae officiis cumque dicta doloremque placeat quod. Ratione dolor nihil. Dolorum fugiat ratione repellendus suscipit.',
	},
	{
		vin: 'GS9KWL514W75DZG9N',
		customer_id: 7,
		model_id: 16,
		service_report:
			'Voluptates ea dolorem totam id exercitationem consectetur. Ipsa sunt eum consequuntur esse consequatur. Doloribus quaerat aliquid quos similique eaque.',
	},
	{
		vin: '5FPJAVK17K79G870H',
		customer_id: 8,
		model_id: 19,
		service_report:
			'Excepturi minus quod excepturi autem vitae occaecati. Quos ducimus facere vitae temporibus minus ab. Voluptate illum voluptatibus voluptas facilis.',
	},
	{
		vin: 'JDMDGZYG146UH31M9',
		customer_id: 8,
		model_id: 0,
		service_report: 'Nam voluptas incidunt minus vero molestias ad.',
	},
	{
		vin: 'GAVU185053P0U93KP',
		customer_id: 8,
		model_id: 17,
		service_report:
			'Dolorem nihil consectetur. Dolorum asperiores laboriosam temporibus.',
	},
	{
		vin: '3NDF7NT06FABT2BMT',
		customer_id: 9,
		model_id: 6,
		service_report:
			'Voluptas temporibus nam vitae ullam veritatis quod optio. Repellat natus quod cum suscipit libero repudiandae.',
	},
	{
		vin: 'FHA7R3XE4CWNKEE2C',
		customer_id: 9,
		model_id: 17,
		service_report:
			'Reiciendis rem necessitatibus reiciendis cupiditate. Labore pariatur hic error.',
	},
	{
		vin: 'SMH5HNZS6M28GBG37',
		customer_id: 9,
		model_id: 13,
		service_report:
			'Doloremque quis molestiae. Ab a eveniet praesentium debitis laborum excepturi. Corrupti accusantium perspiciatis quas.',
	},
	{
		vin: 'UNLUZ8RC3G3U1CPU5',
		customer_id: 10,
		model_id: 7,
		service_report: 'Quam laudantium error veniam.',
	},
	{
		vin: 'ML8KZKTL5SJ9CTRBK',
		customer_id: 10,
		model_id: 14,
		service_report:
			'Eius libero consequuntur sint architecto sit. Illo consectetur iure officiis accusantium.',
	},
	{
		vin: 'S1KJDULW8VVDZXA6J',
		customer_id: 10,
		model_id: 18,
		service_report:
			'Cupiditate perspiciatis necessitatibus ducimus cupiditate amet. Animi modi quisquam eos molestiae vel.',
	},
	{
		vin: 'ZM6FKEC0023FX5WTC',
		customer_id: 11,
		model_id: 8,
		service_report:
			'Accusamus voluptas magni laudantium temporibus. Dolor modi officia incidunt recusandae sint excepturi.',
	},
	{
		vin: 'RYSF017U1EBW4F8W9',
		customer_id: 11,
		model_id: 0,
		service_report:
			'Dolorem illo asperiores qui. Exercitationem provident numquam itaque atque aliquid autem. Libero voluptatem voluptate pariatur adipisci consectetur. Nemo aliquid sed culpa.',
	},
	{
		vin: 'WLVZUVAX8VGFHYFHV',
		customer_id: 11,
		model_id: 5,
		service_report:
			'Dolores provident voluptas ipsum. Rerum nemo sed. Nesciunt magni nihil aut modi vel tenetur.',
	},
	{
		vin: '5JFHML6910ER0D5TT',
		customer_id: 12,
		model_id: 13,
		service_report:
			'Aspernatur sapiente reiciendis officia. Eligendi cumque accusantium ratione. Rerum itaque possimus beatae recusandae est.',
	},
	{
		vin: 'MWT4DTR27RX1NKRTE',
		customer_id: 13,
		model_id: 10,
		service_report:
			'Natus iusto dolores illum harum. Nobis maxime molestias possimus debitis officiis.',
	},
	{
		vin: 'YEHUY2RM3SAW92S26',
		customer_id: 13,
		model_id: 8,
		service_report:
			'Doloremque unde ipsa ratione laboriosam sunt ducimus. Odio deserunt sunt similique numquam. Maxime cum voluptate fugiat labore excepturi illo.',
	},
	{
		vin: 'U9WB4GRL4DWLMRHTG',
		customer_id: 13,
		model_id: 4,
		service_report:
			'Tempora sint officia rerum nostrum. Aliquid rem earum modi accusantium error eos.',
	},
	{
		vin: '6X24NE5352FC20F9X',
		customer_id: 14,
		model_id: 6,
		service_report:
			'Vero occaecati quam necessitatibus ab aperiam. Laboriosam labore consectetur soluta illo possimus. Commodi ea corrupti alias. Quos doloremque nisi assumenda vero ullam.',
	},
	{
		vin: '8DWGPA8A7K72KRSC5',
		customer_id: 14,
		model_id: 10,
		service_report:
			'Totam perspiciatis corporis dolores. Quibusdam delectus delectus est. Ea vero eveniet nemo. Soluta odio maxime.',
	},
	{
		vin: 'F7K83NUP85ZM1TY7U',
		customer_id: 15,
		model_id: 3,
		service_report:
			'Reiciendis dolor ex minus qui ipsum esse culpa. Odit rerum vitae officia eum impedit impedit.',
	},
	{
		vin: 'PW0UBHXW8UHLF6HVF',
		customer_id: 16,
		model_id: 2,
		service_report:
			'Reiciendis assumenda explicabo sint accusantium cumque inventore. Qui iure quod illo. Aliquam dicta non.',
	},
	{
		vin: 'WRM2YLBY5DNGMHH1C',
		customer_id: 16,
		model_id: 12,
		service_report: 'Alias voluptas animi error iste architecto sapiente.',
	},
	{
		vin: '6FTYFXYV926JESFKP',
		customer_id: 17,
		model_id: 3,
		service_report:
			'Corporis magnam dolorum qui eveniet. Omnis amet ullam debitis dolorum.',
	},
	{
		vin: 'XNUMNWHK5GE8CL8BC',
		customer_id: 17,
		model_id: 11,
		service_report:
			'Laudantium corporis provident aperiam inventore veniam repellat eaque. Dolore deserunt veritatis placeat id accusantium eum. Qui perferendis sint ratione porro.',
	},
	{
		vin: 'DXHJ7CJE9PBK19H34',
		customer_id: 18,
		model_id: 11,
		service_report:
			'Iusto possimus ex dicta repudiandae repudiandae atque. Doloribus vel doloremque excepturi occaecati.',
	},
	{
		vin: 'J9Y71JXX6VMB4GX85',
		customer_id: 18,
		model_id: 19,
		service_report: 'Debitis dolorem est. Corrupti quidem ut accusantium.',
	},
	{
		vin: 'SY5400K6XF8TERVVK',
		customer_id: 19,
		model_id: 8,
		service_report:
			'Fugiat quaerat itaque animi dolorem aliquid totam. Aliquid dolorum consequatur atque adipisci provident. Veniam eveniet similique amet nesciunt vitae.',
	},
	{
		vin: 'UK47DDG66AFB5A1TV',
		customer_id: 19,
		model_id: 1,
		service_report: 'Corrupti tempora repellendus perferendis veniam.',
	},
	{
		vin: 'XJ3EJJW51EGCU8E0H',
		customer_id: 20,
		model_id: 14,
		service_report:
			'Architecto nisi culpa repellat sequi. Eum magni repellat velit. Asperiores at dolorum voluptate voluptate exercitationem accusantium.',
	},
	{
		vin: '054AKJV85WKSHZYV6',
		customer_id: 21,
		model_id: 17,
		service_report:
			'Nostrum ducimus quis architecto quae. Beatae porro similique et libero commodi ut.',
	},
	{
		vin: '3ULG26E2XH3BXVHBU',
		customer_id: 22,
		model_id: 3,
		service_report:
			'Cupiditate maiores officiis voluptatem illum. Saepe inventore quia. Sapiente asperiores pariatur modi.',
	},
	{
		vin: 'Y6XNTML7XAMTYJS3W',
		customer_id: 22,
		model_id: 12,
		service_report:
			'Dolores consectetur soluta a ipsa asperiores. Enim amet non. Magnam facere officiis nisi itaque aspernatur.',
	},
	{
		vin: '6LGL8SZ1XWT4CPYV4',
		customer_id: 23,
		model_id: 2,
		service_report:
			'Possimus in totam magnam quia. Alias aliquid totam quisquam in atque amet dolor.',
	},
	{
		vin: 'DH9JVY825FAK1T6E8',
		customer_id: 23,
		model_id: 17,
		service_report:
			'Rerum minima nam modi dignissimos eligendi voluptate. Omnis harum doloribus minima illum magni.',
	},
	{
		vin: 'W80YJZHT8XXFW0RCZ',
		customer_id: 24,
		model_id: 9,
		service_report:
			'At repellendus explicabo in nisi voluptatibus. Alias nihil ex id nam quis fugit debitis. Accusamus veniam quaerat.',
	},
	{
		vin: '95HTMZH10JKY0VXNM',
		customer_id: 25,
		model_id: 19,
		service_report:
			'Quam voluptatem beatae. Ipsum odio itaque explicabo eligendi.',
	},
	{
		vin: 'RWLCFRET53LXRR0Y3',
		customer_id: 26,
		model_id: 11,
		service_report:
			'Dolore quam quos vero dolore quisquam nobis. Quis nesciunt voluptates fuga odit expedita eligendi. Dignissimos itaque aspernatur harum ea alias ipsa.',
	},
	{
		vin: '9ZM7V7W150T05XGMS',
		customer_id: 27,
		model_id: 18,
		service_report:
			'Quam ipsum architecto natus quis tempora assumenda. Explicabo aliquid pariatur recusandae reprehenderit. Voluptatum dolorum quibusdam magnam totam.',
	},
	{
		vin: '6MRLY35F0JE6U77VA',
		customer_id: 27,
		model_id: 6,
		service_report:
			'Nihil corporis quaerat consequatur minus. Incidunt quis distinctio quo provident laboriosam. Accusantium dicta fugiat porro laboriosam.',
	},
	{
		vin: 'K23L4JNP4U0FTBAB6',
		customer_id: 27,
		model_id: 2,
		service_report:
			'Saepe autem eligendi laboriosam. Accusamus aut ea animi. Veritatis enim eum omnis voluptatum eos. Magni quia alias.',
	},
	{
		vin: 'RFY0EWGW3G1WJA5VN',
		customer_id: 28,
		model_id: 1,
		service_report:
			'Ullam sapiente necessitatibus libero occaecati dolores magni consequatur. Atque dolor dicta recusandae eligendi minima. Adipisci neque enim est.',
	},
	{
		vin: 'KUGWKCRX47FRNJJ2S',
		customer_id: 28,
		model_id: 7,
		service_report:
			'Nulla dolorem accusantium porro quae. Ipsa necessitatibus vitae impedit. Impedit harum maiores libero deserunt. Maxime reiciendis culpa excepturi.',
	},
	{
		vin: 'H14YJENE6CG973KV3',
		customer_id: 28,
		model_id: 9,
		service_report:
			'Occaecati animi cumque dolorum consectetur tempore. Aliquam fugit iusto sequi voluptate. Delectus aspernatur fuga natus.',
	},
	{
		vin: 'P96JLTBC29PZHAGXJ',
		customer_id: 29,
		model_id: 2,
		service_report: 'Aut maxime occaecati quo magni.',
	},
	{
		vin: 'M8W50ESP56S1G8WPG',
		customer_id: 29,
		model_id: 7,
		service_report: 'Architecto illo aliquam molestiae beatae debitis.',
	},
	{
		vin: 'RP7EX2LEX5WKT8933',
		customer_id: 29,
		model_id: 3,
		service_report:
			'Laborum mollitia quo laborum. Alias nam reiciendis impedit eveniet labore nostrum repudiandae. Esse culpa corporis reiciendis ea. Corrupti modi saepe deleniti architecto sit doloremque.',
	},
	{
		vin: 'KY87F9RW8PT0T7YTH',
		customer_id: 30,
		model_id: 12,
		service_report:
			'Facilis dolorem asperiores saepe autem. Adipisci corrupti perspiciatis occaecati repellat ullam ratione.',
	},
	{
		vin: '3PDVVFP72PN4SHC8W',
		customer_id: 30,
		model_id: 8,
		service_report:
			'Pariatur illum fugit ad iste. Sit necessitatibus quia rerum. Rem nulla quasi sed fugiat omnis nobis suscipit. Illum mollitia quod enim voluptas dolore aspernatur.',
	},
	{
		vin: '6D24MF9D05DDEM01H',
		customer_id: 31,
		model_id: 14,
		service_report:
			'Quidem maiores beatae similique sequi. Est veniam exercitationem assumenda.',
	},
	{
		vin: '09GB82911NFL2BG49',
		customer_id: 31,
		model_id: 11,
		service_report:
			'Et blanditiis soluta pariatur. Est aliquam facere a quia.',
	},
	{
		vin: 'T85XVPZ78VZE3KW24',
		customer_id: 31,
		model_id: 5,
		service_report: 'Iste eos repellendus itaque distinctio accusantium.',
	},
	{
		vin: 'XSU7YV563L059HLRJ',
		customer_id: 32,
		model_id: 11,
		service_report:
			'Repellat explicabo tenetur earum labore. Itaque optio iusto eaque.',
	},
	{
		vin: 'WZU78DUV3ETMWST7L',
		customer_id: 32,
		model_id: 11,
		service_report: 'Quas ad dolor voluptatibus ipsum ea occaecati.',
	},
	{
		vin: 'ULGP0X56066U7P94M',
		customer_id: 33,
		model_id: 6,
		service_report:
			'Ipsum nobis dolorem non provident hic voluptas. Enim delectus occaecati vitae architecto rerum accusantium cumque. Expedita velit praesentium nam expedita unde id. Placeat alias ad modi.',
	},
	{
		vin: '8TNU4JKN67ZD0XE7N',
		customer_id: 33,
		model_id: 8,
		service_report:
			'Eos ratione cumque dolorem alias ut. Aperiam ipsam veritatis. Iure error natus quod odio repellendus.',
	},
	{
		vin: 'P8HEU2H22BHKMN1B0',
		customer_id: 34,
		model_id: 2,
		service_report:
			'Magni maxime iusto tempore doloribus. Ad amet tempora reiciendis. Quidem ipsam veniam. Officiis et tenetur nisi repellat.',
	},
	{
		vin: 'S5026DRT9WMAPKL64',
		customer_id: 34,
		model_id: 19,
		service_report: 'Minus beatae suscipit ipsam nemo quis.',
	},
	{
		vin: '0A46HVUY5VTHDZ8NU',
		customer_id: 34,
		model_id: 5,
		service_report: 'Eius ad ullam.',
	},
	{
		vin: '8JYK3ES491DKD9GJL',
		customer_id: 35,
		model_id: 17,
		service_report:
			'Eos velit repellat ex nihil. Eius doloremque pariatur maxime optio quas voluptates.',
	},
	{
		vin: '81YUB9RE8ZN5S3U2W',
		customer_id: 35,
		model_id: 7,
		service_report:
			'Velit dolorem voluptates laboriosam perspiciatis repellendus. Facere tenetur soluta quidem est. Nesciunt harum illo expedita quaerat quaerat vero nostrum.',
	},
	{
		vin: '5LUSTJ8S82LAWP6U7',
		customer_id: 36,
		model_id: 5,
		service_report:
			'Aut iste vero sit quis. Earum libero quam ut. Recusandae repellat reprehenderit sapiente fugiat.',
	},
	{
		vin: 'FUS6SKME0MA5Z8ZCN',
		customer_id: 36,
		model_id: 14,
		service_report:
			'Saepe corrupti laboriosam repellat mollitia. Ea aspernatur unde dolores. Dicta commodi quisquam totam hic vel inventore.',
	},
	{
		vin: 'N0F70GCB605BYWVLL',
		customer_id: 36,
		model_id: 12,
		service_report:
			'Nam aut sit repudiandae iure commodi. Harum excepturi hic repellat. Nostrum vel fuga ea esse dignissimos nihil.',
	},
	{
		vin: '0V5XVKH46NZ5KWV34',
		customer_id: 37,
		model_id: 8,
		service_report:
			'Accusamus vel optio occaecati eveniet laborum sed. Nobis dolores optio labore saepe. Quam voluptates id quibusdam. Facere commodi rerum officiis.',
	},
	{
		vin: 'PJ56ZRW39VCLXZ04V',
		customer_id: 37,
		model_id: 17,
		service_report:
			'Numquam cupiditate voluptates. Aliquid aspernatur vero. Pariatur quibusdam assumenda eveniet.',
	},
	{
		vin: '7M6ZB3FV6VAPPJRTM',
		customer_id: 37,
		model_id: 7,
		service_report:
			'Architecto nesciunt deserunt ducimus dolorum quo. Occaecati aspernatur veniam sequi a perferendis nam consequuntur.',
	},
	{
		vin: '4M5M7RJG50M60NKS9',
		customer_id: 38,
		model_id: 10,
		service_report:
			'Sequi pariatur fugiat nemo amet perspiciatis veniam. Sequi laboriosam deserunt ut asperiores ea ea.',
	},
	{
		vin: '6KRM96UZ9P2PKBDMY',
		customer_id: 39,
		model_id: 1,
		service_report:
			'Perspiciatis velit iure occaecati occaecati impedit tempore. Voluptas quam architecto consequuntur voluptates quo.',
	},
	{
		vin: 'CE905J7ZX3M9R0ABA',
		customer_id: 39,
		model_id: 7,
		service_report:
			'Consequatur consequuntur illum quibusdam minima voluptates reprehenderit. Laboriosam aperiam occaecati voluptate. Repudiandae culpa impedit quisquam porro.',
	},
	{
		vin: 'V3TPFVJV6EFKXCPVW',
		customer_id: 39,
		model_id: 1,
		service_report:
			'Dolores magni et numquam nihil impedit iure earum. Corporis quos pariatur quae quasi. Voluptate consequuntur tempore corrupti.',
	},
	{
		vin: 'JRZTAC9G44XP78J6A',
		customer_id: 40,
		model_id: 10,
		service_report:
			'Tenetur cupiditate suscipit dolores nesciunt non numquam. Sapiente cumque delectus delectus nostrum quis. Error maxime quis dolorum ullam.',
	},
	{
		vin: 'HVZ0U6EV3N2T4SZPF',
		customer_id: 41,
		model_id: 12,
		service_report:
			'Quisquam quas ipsa beatae voluptas blanditiis. Quos dolore aliquid ducimus non. Ad natus laboriosam sapiente et expedita perferendis. Quo tenetur praesentium cumque.',
	},
	{
		vin: 'R6PE2L7M2093JX9XV',
		customer_id: 41,
		model_id: 8,
		service_report:
			'Laboriosam non cupiditate explicabo atque corrupti quae. Veritatis veritatis iusto blanditiis debitis. Hic ad corporis eum error modi.',
	},
	{
		vin: '162GD0U80JFK84FTW',
		customer_id: 41,
		model_id: 2,
		service_report: 'Numquam exercitationem nulla necessitatibus.',
	},
	{
		vin: '58Y2ZF0J3U1NFTB6P',
		customer_id: 42,
		model_id: 6,
		service_report:
			'Quo quam voluptas recusandae deserunt rerum voluptate. Et expedita natus officia corporis quam earum assumenda. Laboriosam dolor hic ullam.',
	},
	{
		vin: '5ZZ2RX3RXPG2N5NF7',
		customer_id: 42,
		model_id: 18,
		service_report: 'Quae incidunt vero hic officiis.',
	},
	{
		vin: 'M93NMBWX8B95W3JC3',
		customer_id: 42,
		model_id: 10,
		service_report:
			'Aut facilis nam quas. Dignissimos adipisci accusamus aliquam. Vero adipisci veniam praesentium neque cumque.',
	},
	{
		vin: 'C3LKZSXG03CJN4MH8',
		customer_id: 43,
		model_id: 6,
		service_report:
			'Consequuntur iure vitae saepe cupiditate beatae suscipit. Minima assumenda odio dolorem incidunt explicabo ullam.',
	},
	{
		vin: 'PUSVRMBY5YPH6U6UB',
		customer_id: 44,
		model_id: 15,
		service_report:
			'Corrupti a consequatur aperiam ipsum ad. Consequuntur iusto repudiandae architecto placeat. Laudantium praesentium quis distinctio.',
	},
	{
		vin: 'JL76LJKV9UAVNV3NU',
		customer_id: 45,
		model_id: 12,
		service_report:
			'Architecto numquam quas explicabo at quae. Vero suscipit adipisci. Nulla porro quisquam quae.',
	},
	{
		vin: 'H45SPZA210V395FPP',
		customer_id: 45,
		model_id: 14,
		service_report:
			'Soluta dignissimos aliquid distinctio ab iste. Dolores alias quia minus.',
	},
	{
		vin: 'R30VPPV549PSLHF82',
		customer_id: 45,
		model_id: 4,
		service_report: 'Pariatur placeat soluta odit deserunt.',
	},
	{
		vin: 'BYR8GGVD7JYC895VB',
		customer_id: 46,
		model_id: 8,
		service_report:
			'Rem nemo voluptatem quisquam consequuntur vero. Sint beatae impedit. Dolore in tempore incidunt harum nam.',
	},
	{
		vin: 'V6LN5JKA2BPZE8C9F',
		customer_id: 46,
		model_id: 4,
		service_report:
			'Dolorum similique ducimus. Tenetur aperiam accusantium aut hic.',
	},
	{
		vin: 'Y2PPW9658KSXTT59L',
		customer_id: 47,
		model_id: 7,
		service_report:
			'A placeat enim ratione ex ad eveniet. Laudantium error neque impedit. Maiores nesciunt eos blanditiis. Et accusantium non.',
	},
	{
		vin: '5VWN9B9UXZ480KAAL',
		customer_id: 48,
		model_id: 17,
		service_report:
			'Nemo provident atque quaerat deserunt ex cum ex. Praesentium possimus consequatur recusandae. Vel eveniet saepe deserunt iste ipsam esse.',
	},
	{
		vin: 'ENJ6GCH94K6ZA0AM3',
		customer_id: 48,
		model_id: 17,
		service_report:
			'Qui veritatis sed esse voluptatum. Vel quia perspiciatis nisi. Quo assumenda totam.',
	},
	{
		vin: '263GDT2Y2JKXFSKW5',
		customer_id: 49,
		model_id: 8,
		service_report:
			'Eum placeat rerum iure provident mollitia. Illo dolore voluptatem eius.',
	},
	{
		vin: '4AVTXWDM80LLNS9PZ',
		customer_id: 49,
		model_id: 18,
		service_report:
			'Voluptas accusantium tenetur. Rem modi reiciendis ab quis iusto. Inventore reiciendis sed est tempore debitis voluptatibus. Facilis praesentium vero quisquam optio.',
	},
	{
		vin: '7LFW8HVF7074JRTFA',
		customer_id: 50,
		model_id: 13,
		service_report:
			'Sunt aliquid qui explicabo illum eum libero non. Autem deleniti ipsum nihil. Dolore provident quis iusto voluptas ipsam dolorum.',
	},
	{
		vin: 'LLCAYZWY9KY26SZWF',
		customer_id: 50,
		model_id: 18,
		service_report:
			'Voluptatum a necessitatibus tempora. Voluptas necessitatibus tenetur aliquam quidem soluta.',
	},
	{
		vin: 'DN44JYXJ817U9GPSP',
		customer_id: 51,
		model_id: 12,
		service_report:
			'Blanditiis dignissimos fugiat maxime. Aperiam totam deserunt itaque perspiciatis architecto.',
	},
	{
		vin: '3S4CPJ5R9ZVH897SP',
		customer_id: 52,
		model_id: 11,
		service_report:
			'Suscipit nesciunt quam ab voluptates libero hic. Qui quibusdam quas nemo. Neque similique nisi iure odio ut possimus.',
	},
	{
		vin: 'MP0CZSZ35339MXW0Z',
		customer_id: 52,
		model_id: 7,
		service_report: 'Distinctio perspiciatis quibusdam sunt perspiciatis.',
	},
	{
		vin: '9LLASKMZ0ZYKX2PM8',
		customer_id: 53,
		model_id: 4,
		service_report:
			'Facilis odit velit similique maiores maiores autem. Quod voluptas iusto possimus amet.',
	},
	{
		vin: 'TK2XGE4X9AR0F38C2',
		customer_id: 54,
		model_id: 16,
		service_report:
			'Officia iusto libero. Nulla voluptatem sed velit officiis. Quos est placeat odit dolorum.',
	},
	{
		vin: 'VLT0TDTZ6X49DLXRL',
		customer_id: 54,
		model_id: 15,
		service_report:
			'Beatae vitae iste neque repudiandae. Consequatur odit unde eligendi doloremque quisquam.',
	},
	{
		vin: 'BWMNGXCF1HKEKJDYL',
		customer_id: 55,
		model_id: 2,
		service_report:
			'Autem hic vel quasi dolorum neque ullam. Ullam maiores veritatis odio similique. Iure non quos.',
	},
	{
		vin: 'XNHJ8RSN80J3J6NVG',
		customer_id: 55,
		model_id: 1,
		service_report:
			'Magni et magni dolore perferendis ex. Asperiores labore est reprehenderit voluptatibus perspiciatis laborum. Animi ipsam pariatur rem saepe.',
	},
	{
		vin: 'XDCHH97F7F4EE47TM',
		customer_id: 55,
		model_id: 3,
		service_report:
			'Atque ullam odio deserunt omnis odit. Laudantium modi harum. Corrupti deserunt quae eum.',
	},
	{
		vin: 'X791ATXX3CJLJ46E3',
		customer_id: 56,
		model_id: 4,
		service_report: 'Temporibus illum nesciunt excepturi veritatis.',
	},
	{
		vin: 'BTB3SYBJ631K7MJWY',
		customer_id: 56,
		model_id: 5,
		service_report:
			'Quas dolore quisquam. Ut placeat hic deserunt. Dolores possimus quae repellat eligendi ipsum ea.',
	},
	{
		vin: '9ZWHC8MA3WGB1MJCB',
		customer_id: 56,
		model_id: 13,
		service_report: 'Consequuntur consectetur eaque laborum eos.',
	},
	{
		vin: 'SUZL6S7B39XLF1GRF',
		customer_id: 57,
		model_id: 19,
		service_report:
			'Suscipit nesciunt quae et exercitationem iste. Totam corrupti aliquam. Iusto asperiores in est. Natus reiciendis reiciendis beatae cum mollitia.',
	},
	{
		vin: 'VHMK1GPF948WKASZK',
		customer_id: 58,
		model_id: 2,
		service_report:
			'At odio labore quasi quos sapiente eveniet ipsa. Aut corrupti sit temporibus molestiae dolores.',
	},
	{
		vin: '8JPEE9X04WPTXDF6V',
		customer_id: 58,
		model_id: 12,
		service_report:
			'Facilis eos in. Dolorum a ducimus fuga repellat excepturi. Amet voluptas distinctio ex ab libero. Non perferendis ullam dolor officia laborum similique.',
	},
	{
		vin: 'P584ZC0A0LV8DY6ZV',
		customer_id: 58,
		model_id: 12,
		service_report:
			'Quam aperiam perspiciatis illum adipisci debitis quia. Id fugiat optio reprehenderit consequuntur.',
	},
	{
		vin: '4W9ZTW4W7K2SG1D53',
		customer_id: 59,
		model_id: 19,
		service_report:
			'Accusantium veniam delectus aperiam ipsam veniam perspiciatis. Aut quo alias officia totam ad. Vero perspiciatis cupiditate animi.',
	},
	{
		vin: 'UN545X3J6TB9TPRVR',
		customer_id: 59,
		model_id: 14,
		service_report:
			'Ducimus nostrum itaque nobis tempora. Fugit quas mollitia deserunt. Quidem repellendus nihil odio saepe. Culpa ullam quos non beatae sed dolore laboriosam.',
	},
	{
		vin: 'R69N8BS93E12KWURE',
		customer_id: 59,
		model_id: 16,
		service_report: 'Ullam aliquam animi nam. Magni officiis ipsam eius.',
	},
	{
		vin: 'FWN0HC833T2FD573V',
		customer_id: 60,
		model_id: 8,
		service_report:
			'Et nulla eum. Modi ipsam cumque molestias corporis id corrupti. Rerum quibusdam ad aspernatur voluptatum in nemo.',
	},
	{
		vin: '4F3SVED469ZJELLFK',
		customer_id: 60,
		model_id: 17,
		service_report: 'Ea provident facilis neque consequatur.',
	},
	{
		vin: '0ETKH4BU6Y4NRLTT0',
		customer_id: 61,
		model_id: 0,
		service_report:
			'Illum sapiente impedit possimus accusantium incidunt rerum. Ipsam possimus ducimus repellendus exercitationem.',
	},
	{
		vin: 'KRBXFEK00W4TT9RFG',
		customer_id: 62,
		model_id: 3,
		service_report:
			'Dolor mollitia possimus iusto molestias modi accusamus. Repellat praesentium earum. Laboriosam itaque sint voluptatibus assumenda temporibus.',
	},
	{
		vin: 'DM6VP6C46HR35CDXD',
		customer_id: 62,
		model_id: 17,
		service_report:
			'Non veritatis corrupti esse id aperiam nisi. Recusandae corporis id quaerat provident quidem ducimus. Ad exercitationem sint asperiores maiores incidunt.',
	},
	{
		vin: 'MK1DC8XF0DSFLJRWN',
		customer_id: 63,
		model_id: 8,
		service_report:
			'Quas id maiores recusandae asperiores saepe. Fugiat voluptatum voluptas neque facilis aperiam. Nesciunt deleniti commodi cupiditate quis ea nam.',
	},
	{
		vin: 'KGPZYW7X3LDPLT3E0',
		customer_id: 63,
		model_id: 10,
		service_report:
			'Voluptatem perferendis earum saepe recusandae. Nemo assumenda nulla perspiciatis. Accusantium ipsam ut blanditiis a maiores quam.',
	},
	{
		vin: '2GTJ0CME5RFYMGYYW',
		customer_id: 64,
		model_id: 3,
		service_report:
			'Sit aut cupiditate laboriosam officiis quam amet. Animi dolorem incidunt molestias fuga. Nisi iste corrupti tenetur impedit pariatur.',
	},
	{
		vin: 'ANA9YB430541T9E50',
		customer_id: 64,
		model_id: 9,
		service_report:
			'At unde necessitatibus a. Amet autem nam facere quas accusamus aliquid. Pariatur natus cum quis occaecati provident.',
	},
	{
		vin: '1DZWP4XY421T12HJ2',
		customer_id: 65,
		model_id: 13,
		service_report:
			'Adipisci accusamus perferendis nulla. Facilis numquam nobis eaque itaque possimus optio. Odit sit in nisi quibusdam iste. Ducimus quis expedita fugit perspiciatis.',
	},
	{
		vin: 'ZUB770FC2GNHS6PSW',
		customer_id: 65,
		model_id: 5,
		service_report:
			'Pariatur cumque eligendi illo beatae. Ipsam aliquam recusandae perspiciatis facilis. Dolores vel voluptatem quisquam sit. Recusandae hic nemo quos non fugit.',
	},
	{
		vin: 'FEK636SR8RX41A6YU',
		customer_id: 66,
		model_id: 14,
		service_report:
			'Quisquam a minus exercitationem quisquam repellat. Error omnis reiciendis maxime nostrum hic magni.',
	},
	{
		vin: 'M73EDXH3X5H3BL2D0',
		customer_id: 66,
		model_id: 0,
		service_report:
			'Nihil alias animi veritatis reiciendis. Id eligendi aliquid explicabo ducimus ex natus.',
	},
	{
		vin: 'S5KAFRMR396ZN47UE',
		customer_id: 67,
		model_id: 8,
		service_report:
			'Ipsa delectus fugiat doloribus corporis ipsam nihil. Temporibus at vitae tenetur. Fuga possimus architecto modi illum incidunt maiores.',
	},
	{
		vin: '5MSL2HVY4ENRUBR6J',
		customer_id: 67,
		model_id: 16,
		service_report:
			'Nisi repellendus asperiores explicabo nemo blanditiis. Amet omnis quam dicta eius. Ab quis suscipit corporis similique eaque expedita.',
	},
	{
		vin: 'F56H0R0R2LP761KVP',
		customer_id: 68,
		model_id: 5,
		service_report:
			'Ab sed eos repudiandae. Maxime sunt laborum recusandae sint quaerat consequuntur. Sequi rem iusto placeat.',
	},
	{
		vin: 'H796BUVZ9T726N6LR',
		customer_id: 68,
		model_id: 16,
		service_report:
			'Eius esse a cupiditate dolorum iste aut. Ullam placeat voluptates sunt aspernatur inventore itaque. Excepturi aliquid earum reiciendis numquam tempore incidunt. Nulla magnam officiis iste voluptatibus minima.',
	},
	{
		vin: '1JTR6FAR3A9HKHY05',
		customer_id: 69,
		model_id: 3,
		service_report:
			'Eos eveniet ducimus iste eos architecto. A ullam unde consequatur vel. Impedit tempora a eveniet nobis.',
	},
	{
		vin: 'AUHTKX5P9GFYCW79K',
		customer_id: 70,
		model_id: 9,
		service_report:
			'Ex voluptatibus voluptates neque dolorum tempore maiores. Beatae saepe sapiente.',
	},
	{
		vin: '1SMRMVMU09KLCXLBS',
		customer_id: 70,
		model_id: 16,
		service_report:
			'Ratione laboriosam doloribus quaerat pariatur. Blanditiis suscipit voluptatem illum rem consequatur. Praesentium dolores tempore vitae.',
	},
	{
		vin: 'LJYFLRHS4P8CB2WDV',
		customer_id: 70,
		model_id: 19,
		service_report:
			'Omnis excepturi dolorum. Sint voluptas aut. Consectetur consectetur odio.',
	},
	{
		vin: 'J5TY9KFG90UR5VXSZ',
		customer_id: 71,
		model_id: 6,
		service_report:
			'Dolorum distinctio ipsam perferendis repellat. Architecto impedit officiis eligendi itaque soluta in.',
	},
	{
		vin: 'T3P67FNA03SMU717G',
		customer_id: 71,
		model_id: 4,
		service_report:
			'Consectetur asperiores molestiae accusamus. Sed nam laborum quis aliquid hic aspernatur.',
	},
	{
		vin: 'EZEVPSWZX0N2X7JT6',
		customer_id: 72,
		model_id: 11,
		service_report:
			'Numquam magni maiores fugiat explicabo eveniet. Aspernatur ut cumque sequi perspiciatis.',
	},
	{
		vin: '80NKMWDX1NX7VVL5K',
		customer_id: 72,
		model_id: 5,
		service_report:
			'Consequuntur vel totam minima molestiae inventore. Tenetur et veritatis.',
	},
	{
		vin: '382M7AFB4AMUWESBB',
		customer_id: 73,
		model_id: 17,
		service_report:
			'Nihil quaerat minima est modi tempora. Voluptatibus ipsum alias culpa doloribus pariatur saepe.',
	},
	{
		vin: 'US2CVUR15DDH57BPL',
		customer_id: 73,
		model_id: 16,
		service_report:
			'Molestiae sint itaque recusandae quidem cumque quia ullam. Sint velit minima voluptatem consectetur.',
	},
	{
		vin: 'CW8GYLRS38NNWBK69',
		customer_id: 74,
		model_id: 0,
		service_report:
			'Molestias consequatur velit praesentium quod delectus corporis. Qui voluptatem quam corrupti magni totam ipsum. Rerum ipsa aspernatur quia illum.',
	},
	{
		vin: 'L8FK8BP05ZRT9RUBY',
		customer_id: 74,
		model_id: 19,
		service_report:
			'Reprehenderit eaque odio repellendus quidem. Laboriosam exercitationem incidunt deserunt officiis similique. Libero quae alias ducimus distinctio minus provident.',
	},
	{
		vin: 'BAYJ9BLV04N1Y9CRZ',
		customer_id: 75,
		model_id: 10,
		service_report:
			'Aperiam commodi iure quia pariatur. Cumque exercitationem unde ipsum. Est cum ducimus.',
	},
	{
		vin: 'YTYTVYA662EJ3HEJA',
		customer_id: 75,
		model_id: 15,
		service_report:
			'Exercitationem enim illum nobis ratione placeat. Recusandae tempora voluptatem officiis eos aut labore nam.',
	},
	{
		vin: 'WYZ88HP37VDU7FK3V',
		customer_id: 76,
		model_id: 0,
		service_report:
			'Ratione in quos eligendi sit itaque. Hic ratione soluta fugit ab vitae eius.',
	},
	{
		vin: 'GN6V8FDN7UA9DD4NH',
		customer_id: 76,
		model_id: 3,
		service_report:
			'Libero dolorem deleniti debitis sed vitae. Eligendi nobis explicabo iure.',
	},
	{
		vin: 'ALJJHZ797T4H99F0L',
		customer_id: 77,
		model_id: 11,
		service_report:
			'Ab sed tempora. Perspiciatis ea est saepe perspiciatis. Vero in adipisci impedit odio.',
	},
	{
		vin: 'FSY0HTRV956SZHPWY',
		customer_id: 77,
		model_id: 9,
		service_report:
			'Dignissimos assumenda velit aliquid similique doloribus. Adipisci blanditiis quam magnam at quis corporis. Dolorum incidunt numquam eum suscipit. Alias perferendis veniam facilis.',
	},
	{
		vin: 'L16WN5SD1UDYHLJM9',
		customer_id: 77,
		model_id: 7,
		service_report:
			'Itaque saepe deserunt illo accusantium. Laudantium maiores itaque voluptatum unde facilis inventore.',
	},
	{
		vin: 'YM437W283AVW1UC9K',
		customer_id: 78,
		model_id: 1,
		service_report:
			'Iure totam pariatur eos vero excepturi. Nostrum fuga facilis tempora eveniet officiis. Harum natus occaecati ipsam error facilis suscipit saepe. Nam numquam velit porro.',
	},
	{
		vin: 'AH6PG6PA54SKEU671',
		customer_id: 78,
		model_id: 7,
		service_report:
			'Corporis ut velit tempora quo officia. Cum quis hic et soluta nostrum quae.',
	},
	{
		vin: 'DX59EV110M8U9X5ER',
		customer_id: 78,
		model_id: 18,
		service_report:
			'Rerum fugiat eos eos corrupti. Aliquam rem consequatur occaecati quisquam.',
	},
	{
		vin: '677LPK9R49051AVND',
		customer_id: 79,
		model_id: 2,
		service_report:
			'Dicta maiores quo consequuntur veritatis deleniti quas. Cupiditate vel ratione qui eligendi.',
	},
	{
		vin: '0TVD67982RNUL9FH6',
		customer_id: 79,
		model_id: 2,
		service_report:
			'Qui cupiditate dicta. Doloremque officia recusandae ad dolorum debitis.',
	},
	{
		vin: 'FJ2KDZCS3J4FYR8FY',
		customer_id: 80,
		model_id: 15,
		service_report:
			'Temporibus facere rerum cum laboriosam et necessitatibus. Eligendi repellendus non.',
	},
	{
		vin: '51PL9RTP4BX5251GD',
		customer_id: 80,
		model_id: 2,
		service_report:
			'Consequatur tenetur dolores culpa. Illum assumenda tempore assumenda vitae.',
	},
	{
		vin: '34SZJZTU0S60H6K6Z',
		customer_id: 80,
		model_id: 17,
		service_report:
			'Reiciendis qui quas doloremque reiciendis. Labore dicta nobis placeat inventore debitis.',
	},
	{
		vin: 'D0LS5KUFX4F6U8W4K',
		customer_id: 81,
		model_id: 4,
		service_report:
			'Molestias assumenda laudantium enim consectetur quisquam. Ad cumque assumenda possimus. Magnam voluptatibus aliquam enim similique.',
	},
	{
		vin: 'B8191AYN5GAP9H82M',
		customer_id: 82,
		model_id: 4,
		service_report:
			'Cum minima nobis. Molestias minus architecto fugit impedit repellat culpa. Repudiandae autem nemo nihil.',
	},
	{
		vin: 'UH5G5YWZ2P4YAPAG7',
		customer_id: 82,
		model_id: 15,
		service_report:
			'Architecto dolor eum vitae. Atque vero doloremque inventore similique. Laudantium voluptatibus voluptate quae possimus architecto.',
	},
	{
		vin: '8EZ131F39XPR0B3TE',
		customer_id: 83,
		model_id: 17,
		service_report:
			'Accusamus omnis porro temporibus. Nulla suscipit recusandae laboriosam. Eum sint beatae.',
	},
	{
		vin: 'LFTLH5P87Z4BE3S5W',
		customer_id: 84,
		model_id: 5,
		service_report:
			'Ad suscipit dolorum veritatis. Nihil inventore vero asperiores tempora.',
	},
	{
		vin: 'JKL6W1PC2JKFWPYCF',
		customer_id: 85,
		model_id: 8,
		service_report:
			'Exercitationem repudiandae libero expedita provident facere. Corrupti asperiores fugiat quia blanditiis ad deserunt. Vero magnam asperiores officiis sequi quia quisquam.',
	},
	{
		vin: '01T2EN1MX1RK7DFTY',
		customer_id: 85,
		model_id: 16,
		service_report:
			'Voluptate voluptas veritatis quis nemo fugiat. Animi id iure accusamus nisi. Illo nisi saepe id placeat.',
	},
	{
		vin: '40H63ETPXW67Z9S54',
		customer_id: 86,
		model_id: 19,
		service_report:
			'Voluptates dolorum porro animi. Autem perspiciatis minima ab ad libero totam.',
	},
	{
		vin: 'U9FJGLSL9LVHE5C97',
		customer_id: 86,
		model_id: 13,
		service_report:
			'Nam dicta delectus. Dolor adipisci quibusdam excepturi modi.',
	},
	{
		vin: '07AVWLT8XNDVKWG86',
		customer_id: 86,
		model_id: 6,
		service_report:
			'Eligendi possimus possimus mollitia numquam. Doloribus ex quasi sapiente. Delectus minima suscipit totam saepe consequuntur fugiat.',
	},
	{
		vin: 'AK3DL06FXNSZ4KGB2',
		customer_id: 87,
		model_id: 17,
		service_report:
			'Explicabo voluptatum beatae nulla beatae. Veritatis quos vitae ullam maxime cumque quibusdam. Libero natus dignissimos quae deserunt soluta.',
	},
	{
		vin: 'SVF7W798014E9CSP6',
		customer_id: 88,
		model_id: 6,
		service_report:
			'Veritatis minus adipisci possimus. Ducimus facilis est in reiciendis maxime.',
	},
	{
		vin: 'G525CV961M83W4AUS',
		customer_id: 88,
		model_id: 9,
		service_report:
			'Atque doloremque quisquam consectetur id. Itaque exercitationem cumque ipsam. Alias ea maiores a aut quibusdam.',
	},
	{
		vin: 'Y2RUBNDB3HHV03FJY',
		customer_id: 89,
		model_id: 12,
		service_report:
			'Reiciendis fuga debitis beatae est. Praesentium tempora asperiores molestiae blanditiis.',
	},
	{
		vin: 'TX46HR9T2CF2RNXYN',
		customer_id: 90,
		model_id: 11,
		service_report:
			'Totam similique soluta asperiores maiores cumque. Quos exercitationem quae.',
	},
	{
		vin: 'ZLRHB2LE92H4XNEA7',
		customer_id: 91,
		model_id: 14,
		service_report:
			'Voluptas tenetur cupiditate id voluptas fugit. Unde velit voluptas voluptatibus libero.',
	},
	{
		vin: 'FFH4ERNB3BFLN2N0C',
		customer_id: 92,
		model_id: 16,
		service_report:
			'Rem dicta soluta perspiciatis odio. Eligendi excepturi necessitatibus perferendis ratione adipisci.',
	},
	{
		vin: 'YKBY364E5E2X1MC9M',
		customer_id: 93,
		model_id: 14,
		service_report: 'Sed at veritatis. Corrupti quod vero voluptas.',
	},
	{
		vin: 'BL42008P65PS7MKL9',
		customer_id: 93,
		model_id: 3,
		service_report:
			'Iusto eaque commodi aut similique vitae ipsam sit. Hic minus modi omnis soluta. Eveniet perspiciatis iste voluptatem nesciunt ea quos non.',
	},
	{
		vin: 'AUYLBNEB6RKJ9B1S3',
		customer_id: 93,
		model_id: 7,
		service_report:
			'Placeat tenetur alias beatae deleniti rem. Sequi quidem veniam dolores repellendus.',
	},
	{
		vin: 'BLE7Y0M52FNLA6MV1',
		customer_id: 94,
		model_id: 7,
		service_report:
			'Natus deleniti quia labore iure. Porro cupiditate laborum. Ex optio excepturi quae esse debitis veritatis. Eligendi quibusdam incidunt eveniet consequuntur.',
	},
	{
		vin: 'GDF5NG7114RVTATYR',
		customer_id: 95,
		model_id: 2,
		service_report:
			'Sapiente praesentium explicabo ipsa labore cumque ab. Beatae vitae quia.',
	},
	{
		vin: 'NR7XEAV593J2LG75M',
		customer_id: 96,
		model_id: 10,
		service_report:
			'Dignissimos esse neque quos possimus eum delectus. Perferendis repellendus quae mollitia reprehenderit in.',
	},
	{
		vin: 'ARA56MX2XUAUA47MD',
		customer_id: 97,
		model_id: 0,
		service_report:
			'Laborum at veritatis sequi amet ipsam iure vitae. Commodi quae eligendi omnis. Illo asperiores qui aliquam earum voluptatum veniam.',
	},
	{
		vin: 'CTGJKF740SY04P1U6',
		customer_id: 97,
		model_id: 18,
		service_report:
			'Nam at ullam enim numquam. Et expedita corrupti voluptatum repellendus. Dicta distinctio cumque modi iusto facere illo.',
	},
	{
		vin: 'JXC72DAJ06X8KSXYG',
		customer_id: 97,
		model_id: 17,
		service_report:
			'Voluptatibus commodi dignissimos ullam impedit eveniet earum. Veritatis iusto repudiandae exercitationem nam sunt quas. Saepe sint vero vero.',
	},
	{
		vin: '6RBPRPBV43GVWGEH4',
		customer_id: 98,
		model_id: 7,
		service_report:
			'Eius ut vel laborum nemo blanditiis impedit asperiores. Ducimus fugit officiis voluptas voluptatum sequi. Animi nemo facilis esse quasi.',
	},
	{
		vin: '076NTE4R8TXFXK6S3',
		customer_id: 99,
		model_id: 18,
		service_report:
			'Distinctio quasi quo maiores molestias officiis excepturi. Dolores voluptatum pariatur voluptas.',
	},
];
