import React from 'react';
import { Tabs } from 'antd';
import RecommendationTab from './RecommendationTab';

function RecommendedUpgrades({ carDetails }) {
	const items = [
		{
			key: '1',
			label: `All`,
			children: (
				<RecommendationTab carDetails={carDetails} parts={'all'} />
			),
		},
		{
			key: '2',
			label: `Brakes`,
			children: (
				<RecommendationTab carDetails={carDetails} parts={'brakes'} />
			),
		},
		{
			key: '3',
			label: `Engine`,
			children: (
				<RecommendationTab carDetails={carDetails} parts={'engine'} />
			),
		},
		{
			key: '4',
			label: `Battery`,
			children: (
				<RecommendationTab carDetails={carDetails} parts={'battery'} />
			),
		},
		{
			key: '5',
			label: `Steering`,
			children: (
				<RecommendationTab carDetails={carDetails} parts={'steering'} />
			),
		},
	];

	if (!carDetails) {
		return null;
	}

	return (
		<div className='recommended-upgrades'>
			<div className='recommended-updgrades__tabs'>
				<Tabs defaultActiveKey='1' items={items} animated={false} />
			</div>
		</div>
	);
}

export default RecommendedUpgrades;
